namespace h5_vm {
   export class VmMigrationSelectNetworkPageService {

      public static $inject = [
         "i18nService",
         "wizardPageService",
         "defaultUriSchemeUtil",
         "dataServiceUtil",
         "MigrateSelectNetworkPageModel",
         "NetworkPageViewModes",
      ];

      constructor(
            private i18nService: any,
            private wizardPageService: any,
            private defaultUriSchemeUtil: any,
            private dataServiceUtil: any,
            private MigrateSelectNetworkPageModel: any,
            private NetworkPageViewModes: any
      ) {
      }

      public build(wizardScope: any) {
         return {
            title: this.i18nService.getString("VmUi", "MigrationWizard.networkPage.title"),
            description: this.i18nService.getString("VmUi", "MigrationWizard.networkPage.description"),
            contentUrl: "vm-ui/resources/vm/views/migrationWizard/pages/migrateSelectNetworkPage.html",
            headerUrl: "vm-ui/resources/vm/views/migrationWizard/pages/migratePagesCustomHeader.html",
            disableNext: () => {
               return this.disableNext(wizardScope);
            },
            onCommit: () => {
               return this.onCommit(wizardScope);
            }
         };
      }

      private disableNext(wizardScope: any) {
         const pageModel = wizardScope.migrationWizardManager.networkPageModel;
         return pageModel.getSelectNetworkPageIsValidating();
      }

      private onCommit(wizardScope: any) {
         const networkProps = {
            name: "name",
            key: "key",
            dvsUuid: "dvsUuid",
            opaqueNetworkType: "summary/opaqueNetworkType",
            opaqueNetworkId: "summary/opaqueNetworkId"

         };

         const pageModel = wizardScope.migrationWizardManager.networkPageModel;
         const viewMode = pageModel.getSelectNetworkPageViewMode();
         if (viewMode === this.NetworkPageViewModes.BASIC) {
            if (!this.hasValidDestinationNetwork(pageModel,
                     pageModel.getBasicNetworkMatches())) {
               this.wizardPageService.markPageIncompleteWithError(
                     wizardScope.wizardConfig,
                     this.i18nService.getString(
                           "Common", "vns.error.matchingIncompleteBasic")
               );
               return false;
            }
         } else {
            if (!this.hasValidDestinationNetwork(pageModel,
                     pageModel.getAdvancedNetworkMatches())) {
               this.wizardPageService.markPageIncompleteWithError(
                     wizardScope.wizardConfig,
                     this.i18nService.getString(
                           "Common", "vns.error.matchingIncompleteAdvanced")
               );
               return false;
            }
         }

         wizardScope.wizardConfig.validationBanner.messages = [];
         this.wizardPageService.markPageComplete(wizardScope.wizardConfig, wizardScope.attributes.creationType);

         const networkMatches = wizardScope.migrationWizardManager.getNetworkMatches();

         const destinationNetworkIds = _.uniq(_.map(networkMatches, (nwMatch: any) => {
            return this.defaultUriSchemeUtil.getVsphereObjectId(nwMatch.destinationNetwork);
         }));

         return this.dataServiceUtil.getPropertiesForObjects(destinationNetworkIds,
               [networkProps.name, networkProps.key, networkProps.dvsUuid,
                  networkProps.opaqueNetworkType, networkProps.opaqueNetworkId])
               .then((result: any) => this.onNetworkPropertiesReturn(
                     wizardScope, result));
      }

      private onNetworkPropertiesReturn(wizardScope: any, result: any) {
         const networkMatches = wizardScope.migrationWizardManager.getNetworkMatches();
         _.forEach(networkMatches, (nwMatch: any) => {
            const networkId = this.defaultUriSchemeUtil
                  .getVsphereObjectId(nwMatch.destinationNetwork);
            const networkData = result[networkId];
            if (networkData) {
               nwMatch.destinationSwitchUuid = networkData.dvsUuid;
               nwMatch.destinationNetworkName = networkData.name;
               nwMatch.destinationPortgroupKey = networkData.key;
               nwMatch.destinationOpaqueId = networkData["summary/opaqueNetworkId"];
               nwMatch.destinationOpaqueType = networkData["summary/opaqueNetworkType"];
            }
         });

         return true;
      }

      private hasValidDestinationNetwork(pageModel: any, matches: any) {
         if (matches.length <= 0) {
            return true;
         }
         if (pageModel.getSelectNetworkPageViewMode() === this.NetworkPageViewModes.BASIC
               && !pageModel.isBasicPageValid) {
            return false;
         } else if (pageModel.getSelectNetworkPageViewMode() === this.NetworkPageViewModes.ADVANCED
               && !pageModel.isAdvancedPageValid) {
            return false;
         }
         let isValid = true;
         _.each(matches, (match: any) => {
            if (!match.destinationNetwork && match.sourceNetwork) {
               isValid = false;
            }
         });
         return isValid;
      }

   } // class

   angular.module("com.vmware.vsphere.client.vm").service(
         "vmMigrationSelectNetworkPageService", VmMigrationSelectNetworkPageService);

} // namespace
