/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
(function () {
   'use strict';
   angular.module('com.vmware.vsphere.client.cluster')
         .controller('VmHaPortletController', VmHaPortletController);

   VmHaPortletController.$inject = ['$scope', 'dataService', 'i18nService',
         'vuiConstants', 'columnRenderersRegistry', '$element'];

   function VmHaPortletController($scope, dataService, i18nService, vuiConstants,
         columnRenderersRegistry, $element) {
      var self = this;
      var vmId = $scope._route.objectId;
      var iconTextRenderer = columnRenderersRegistry.getColumnRenderer('object-link');

      self.vmId = [vmId];
      self.getViewData = getViewData;
      self.liveRefreshProperties = ["runtime.dasVmProtection",
         "cluster",
         "dasVmSettings"];
      self.haProtectionLabel = getString('failureResponseView.haProtection.label');
      self.gridOptions = getGridOptions();

      getViewData();

      function getViewData() {
         dataService.getData(vmId,
               'com.vmware.vsphere.client.clusterui.model.services.VmFailureResponsesData',
               {queryName: 'VmHaPortletController.' + getViewData.name})
               .then(function(haData) {
                  self.haProtection = getHaProtectionInfo(haData.dasVmProtection);
                  self.gridOptions.data = haData.mergedItems;
                  self.signPostParams = buildSignPostData(haData);
               });
      }

      function getHaProtectionInfo(dasVmProtection) {
         var result = {};

         if (dasVmProtection === null) {
            result.icon = 'vsphere-icon-status-unknown';
            result.label = getString('failureResponseView.naStatus');
            result.description = getString('failureResponseView.tooltip.naStatus.descr');
         } else if (dasVmProtection.dasProtected) {
            result.icon = 'vsphere-icon-status-ok';
            result.label = getString('failureResponseView.protectedStatus');
            result.description = getString('failureResponseView.tooltip.protectedStatus.descr');
         } else {
            result.icon = 'vsphere-icon-status-error';
            result.label = getString('failureResponseView.unprotectedStatus');
            result.description = getString('failureResponseView.tooltip.unprotectedStatus.descr');
         }

         result.description = '<span class="cluster-tooltip-content-wrapper">' +
               result.description + '</span>';

         return result;
      }

      function getGridOptions() {
         return {
            selectionMode: vuiConstants.grid.selectionMode.NONE,
            sortMode: vuiConstants.grid.sortMode.NONE,
            searchable: false,
            resizable: false,
            selectedItems: [],
            data: [],
            pageConfig: {
               hidePager: true
            },
            columnDefs: getColumnDefs(),
            dataBound: function() {
               $element.find('[vui-datagrid] .k-grid-content').css('height', '');
            },
            height: '100%'
         };
      }

      function getColumnDefs() {
         return [
            {
               displayName: getString('ha.config.vmResponse.failure.column'),
               field: 'failure'
            },
            {
               displayName: getString('ha.config.vmResponse.failureResponse.column'),
               field: 'failureResponse',
               template: function(dataItem) {
                  var cellData = {
                     text: dataItem.failureResponse,
                     title: dataItem.details
                  };
                  var icon = getIconClass(dataItem.failureResponseType);
                  return iconTextRenderer(
                        [undefined, 'text', icon, undefined, undefined, 'title'], cellData);
               }
            }
         ];
      }

      function getIconClass(failureType) {
         if (failureType === 'responseDisabled') {
            return 'vsphere-icon-status-error';
         } else if (failureType === 'responseProtected') {
            return 'vsphere-icon-status-ok';
         } else if (failureType === 'responseWarnOnly') {
            return 'vsphere-icon-status-warning';
         }
         return '';
      }

      function buildSignPostData(haData) {
         var haProtection = getHaProtectionInfo(haData.dasVmProtection);

         return {
            title: haProtection.label,
            message: haProtection.description
         };
      }

      function getString(key) {
         return i18nService.getString('ClusterUi', key);
      }
   }
})();
