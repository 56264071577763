module cluster_ui {

   import IController = angular.IController;

   class AdditionalOptionsPageController implements IController {

      public static $inject = ["$scope", "drsConstants",
         "i18nService", "additionalOptionsPageService"];

      private pageModel: any;
      private drsPolicies: any;
      private CPU_OVERCOMMITMENT_MAX: string;
      private CPU_OVERCOMMITMENT_MIN: string;
      private cpuOverCommitmentSignPostParams: any;
      private advancedOptionsBuilder: any;

      constructor(private $scope: any,
                  private drsConstants: any,
                  private i18nService: any,
                  private additionalOptionsPageService: AdditionalOptionsPageService) {


         this.CPU_OVERCOMMITMENT_MIN =
               this.drsConstants.drsPolicies.CPU_OVERCOMMITMENT_MIN;
         this.CPU_OVERCOMMITMENT_MAX =
               this.drsConstants.drsPolicies.CPU_OVERCOMMITMENT_MAX;

         this.pageModel =
               $scope.modalOptions.dialogData.manager.getAdditionalOptionsPageModel();

         this.drsPolicies = this.pageModel.drsPolicies;

         this.cpuOverCommitmentSignPostParams =
               this.additionalOptionsPageService.getCpuOverCommitmentSignPostParams();

         this.advancedOptionsBuilder =
               $scope.modalOptions.dialogData.manager.getFormData().advancedOptionsBuilder;
      }

      private normalizeCpuOvercommitment(event: any): void {

         if (event.target.value === "" ||
               event.target.value < this.CPU_OVERCOMMITMENT_MIN) {
            this.drsPolicies.cpuOvercommitmentValue =
                  this.CPU_OVERCOMMITMENT_MIN;
         }

         if (event.target.value > this.CPU_OVERCOMMITMENT_MAX) {
            this.drsPolicies.cpuOvercommitmentValue =
                  this.CPU_OVERCOMMITMENT_MAX;
         }
      }

      private getString(key: string): string {
         return this.i18nService.getString("ClusterUi", key);
      }
   }

   angular.module("com.vmware.vsphere.client.cluster")
         .controller("AdditionalOptionsPageController", AdditionalOptionsPageController);
}

