module ds_cluster_ui {

   class AddSdrsVmRuleMemberController {

      public static $inject = ["$scope", "i18nService"];
      public config: any;

      constructor(private $scope: any, private i18nService: any) {

         let dsClusterId: string = $scope.modalOptions.dialogData.objectId;
         let dialogData: any = $scope.modalOptions.dialogData;
         let selectedVms: string[] =
               this.getSelectedVmsManagedObjectRefs(dialogData.selectedVms);
         let selectorConfig: any = {
            objectType: "VirtualMachine",
            listViewId: "vsphere.core.vm.members.list"
         };

         let filter: string = "dsClusterVmsExceptSecondaryVmsExcludeVmsFilter";

         // use different filter if there are no members to be excluded because
         // dsClusterVmsExceptSecondaryVmsExcludeVmsFilter cannot be instantiated with an empty param
         if (selectedVms.length === 0) {
            filter = "dsClusterVmsExceptSecondaryVmsFilter";
         }

         this.config = {
            contextObject: dsClusterId,
            multipleSelect: true,
            listTabConfig: {
               listConfig: [{
                  label: i18nService.getString("Common", "typeResource.vmPlural"),
                  listViewId: selectorConfig.listViewId,
                  dataModels: [selectorConfig.objectType],
                  filterId: filter,
                  filterParams: selectedVms
               }]
            },
            onSelectionChanged: function (newSelectedItems: any) {
               dialogData.selectedItems = newSelectedItems;
            }
         };
      }

      private getSelectedVmsManagedObjectRefs(selectedVms: any[]): string[] {
         return _.map(selectedVms, (selectedVm: any): string => selectedVm.id);
      }
   }

   angular.module("com.vmware.vsphere.client.dsCluster")
         .controller("AddSdrsVmRuleMemberController", AddSdrsVmRuleMemberController);
}

