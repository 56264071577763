namespace network_ui {

   import IRootScopeService = angular.IRootScopeService;

   export class IscsiPortBindingDetailsDialogService {

      public static $inject = ["$rootScope", "clarityModalService", "i18nService"];

      constructor(private $rootScope: IRootScopeService,
                  private clarityModalService: any,
                  private i18nService: any) {
      }

      public show(hostId: string, infoItem: IscsiPortInfoItem): void {

         if (!hostId || !infoItem) {
            return;
         }

         let modalScope: any = this.$rootScope.$new();

         modalScope.modalOptions = {
            contentUrl: "network-ui/resources/network/views/host/iscsi/details/IscsiPortBindingDetailsDialog.html",
            title: this.i18nService.getString("H5NetworkUi", "portbinding.detailsDialog.title", infoItem.vnicDevice),
            size: "lg",
            dialogData: {
               hostId: hostId,
               iscsiPortBindingItem: infoItem
            }
         };

         this.clarityModalService.openPopUp(modalScope);
      }
   }

   angular.module("com.vmware.vsphere.client.network")
         .service("iscsiPortBindingDetailsDialogService", IscsiPortBindingDetailsDialogService);
}
