angular.module('com.vmware.vsphere.client.host').factory('HostAdder', [
   'mutationService',
   'defaultUriSchemeUtil',
   'managedEntityConstants',
   'dataService',
   'hostnameParser',
   'lockdownModeStrings',
   'addHostWizardPageModelGetter',
   'licenseCommonUtil',
   'connectInfoParser',
   'i18nService',
   'addHostWizardConstants',
   'vscSchedulingHelper',
   function(mutationService,
         defaultUriSchemeUtil,
         managedEntityConstants,
         dataService,
         hostnameParser,
         lockdownModeStrings,
         addHostWizardPageModelGetter,
         licenseCommonUtil,
         connectInfoParser,
         i18nService,
         addHostWizardConstants,
         vscSchedulingHelper) {
      return function HostAdder() {
         var state = {};

         var manager = {
            getNameLocationPageModel: addHostWizardPageModelGetter.getNameLocationPageModel.bind(this, state),
            getConnectionSettingsPageModel: addHostWizardPageModelGetter.getConnectionSettingsPageModel.bind(this, state),
            getHostSummaryPageModel: addHostWizardPageModelGetter.getHostSummaryPageModel.bind(this, state),
            getAssignLicensePageModel: addHostWizardPageModelGetter.getAssignLicensePageModel.bind(this, state),
            getLockdownModePageModel: addHostWizardPageModelGetter.getLockdownModePageModel.bind(this, state),
            getVmLocationPageModel: addHostWizardPageModelGetter.getVmLocationPageModel.bind(this, state),
            getResourceLocationPageModel: addHostWizardPageModelGetter.getResourceLocationPageModel.bind(this, state),
            getResourcePoolInput: addHostWizardPageModelGetter.getResourcePoolInput.bind(this, state),
            getReadyToCompletePageModel: addHostWizardPageModelGetter.getReadyToCompletePageModel.bind(this, state),
            isHostnameValid: addHostWizardPageModelGetter.isHostnameValid.bind(this, state),
            isUsernameValid: addHostWizardPageModelGetter.isUsernameValid.bind(this, state),
            getHostAlreadyManagedMessage: addHostWizardPageModelGetter.getHostAlreadyManagedMessage.bind(this, state),
            getStateLoadedFlag: addHostWizardPageModelGetter.getStateLoadedFlag.bind(this, state),
            getDestinationUid: addHostWizardPageModelGetter.getDestinationUid.bind(this, state),
            getScheduleTaskPageModel: addHostWizardPageModelGetter.getScheduleTaskPageModel.bind(this, state),

            getLastAssetAssignLicenseValidationResult: function() {
               return state.lastAssetAssignLicenseValidationResult;
            },

            getErrorInResponse: function() {
               return state.errorInResponse;
            },

            setStateLoadedFlag: function(value) {
               state.loadedFlag = value;
            },

            setDatacenter: function(dc) {
               state.dc = dc;
            },

            setDestination: function(destination) {
               state.destination = {
                  ref: destination.ref,
                  name: destination.name,
                  icon: destination.icon,
                  drsState: destination.drsState
               };
            },

            setDestinationUid: function(destinationUid) {
               state.destinationUid = destinationUid;
            },

            isDestinationCluster: function() {
               return state.destination && state.destination.ref.type === managedEntityConstants.CLUSTER;
            },

            isDestinationDrsEnabledCluster: function() {
               return this.isDestinationCluster() && state.destination.drsState;
            },

            setOperationName: function(name) {
               state.operationName = name;
            },

            setHostname: function(hostname) {
               state.userEnteredHostname = hostname;
               var parsedHostname = hostnameParser.parse(hostname);
               if (parsedHostname) {
                  state.hostname = parsedHostname.hostname;
                  state.port = parsedHostname.port;
               } else {
                  state.hostname = null;
                  state.port = null;
               }
            },

            setHostUid: function(hostUid) {
               state.hostUid = hostUid;
            },

            setReconnectHost: function(reconnectHost) {
               state.reconnectHost = reconnectHost;
            },

            setUserSelectedLicense: function(license) {
               state.userSelectedLicense = license;
            },

            setConnectInfo: function(connectInfo) {
               _.extend(state, connectInfoParser.parse(connectInfo));
            },

            setConnectionSettings: function(settings) {
               state.username = settings.username;
               state.password = settings.password;
            },

            setSslThumbprint: function(thumbprint) {
               state.sslThumbprint = thumbprint;
            },

            setVmLocation: function(vmLocation) {
               state.vmLocation = {
                  ref: vmLocation.ref,
                  displayName: vmLocation.displayName
               };
            },

            setResourceLocationType: function(resourceLocationType) {
               state.resourceLocationType = resourceLocationType;
            },

            setResourceLocationName: function(resourceLocationName) {
               state.resourceLocationName = resourceLocationName;
            },

            setLockdownMode: function(lockdownMode) {
              state.lockdownMode = lockdownMode;
            },

            isReconnectHost: function() {
               return state.reconnectHost;
            },

            setScheduleTaskPageModel: function(scheduleTaskPageModel) {
               state.scheduleTaskPageModel = scheduleTaskPageModel;
            },

            /**
             * Requests all licenses for a vCenter instance and saves the query so it can be used in @function validateLicense.
             */
            prepareLicenseValidation: function (model) {
               state.preFetchedLicenses = dataService
                     .getProperties(model.rootFolder, ['licenses'], { skipErrorInterceptor: true })
                     .catch(function() {
                        // Return null to notify that no licenses were retrieved successfully.
                        return null;
                     });
            },
            validateLicense: function (model) {
               state.lastAssetAssignLicenseValidationResult = null;

               return state.preFetchedLicenses.then(function (licenseData) {
                  var licenses = licenseData ? licenseData.licenses : null;
                  return mutationService.validateSpec(
                     "com.vmware.license.client.cis.management.ds.specs.AssetAssignLicenseConnectInfoValidateSpec",
                     {
                        connectInfo: model.connectInfo,
                        scope: {
                           id: model.serverGuid
                        },
                        licenses: licenses
                     }
                  ).then(
                     function (response) {
                        if (response && response.result) {
                           var validationResult = response.result;
                           licenseCommonUtil.normalizeValidationResult(
                              validationResult
                           );
                           state.lastAssetAssignLicenseValidationResult = validationResult;

                           return validationResult;
                        } else {
                           state.errorInResponse = response.error;
                        }
                     }
                  );
               });
            },

            validateConnection: function() {
               var objectId;
               if (state.reconnectHost) {
                  objectId = state.hostUid;
               } else {
                  objectId = defaultUriSchemeUtil.getVsphereObjectId(state.destination.ref);
               }
               return mutationService.validate(
                     objectId,
                     'com.vmware.vsphere.client.host.add.HostConnectionSpec',
                     buildHostConnectionSpec()
               );
            },

            submit: function() {
               if (!state.vmLocation || state.vmLocation.ref.type === managedEntityConstants.FOLDER) {
                  addHost();
               } else {
                  dataService.getProperties(defaultUriSchemeUtil.getVsphereObjectId(state.vmLocation.ref), ['vmFolder']).then(function(data) {
                     state.vmLocation.ref = data.vmFolder;
                     addHost();
                  });
               }
            }
         };

         function addHost() {
            if (state.reconnectHost) {
               mutationService.apply(state.hostUid,
                     'com.vmware.vsphere.client.host.connection.api.HostConnectionStateSpec',
                     buildHostConnectionStateSpec(),
                     state.operationName
               );
            } else {
               if (!!state.scheduleTaskPageModel) {
                  mutationService.add(
                     'com.vmware.vise.data.scheduling.ScheduledMutationOperationSpec',
                     buildHostAddScheduledTaskSpec());
               } else {
                  mutationService.add(
                     'com.vmware.vsphere.client.host.add.HostAddSpec',
                     buildHostAddSpec(),
                     state.operationName);
               }
            }
         }

         function buildHostConnectionSpec() {
            var spec = {
               hostName: state.hostname,
               hostPort: state.port,
               userName: state.username,
               password: state.password,
               sslThumbprint: state.sslThumbprint
            };

            return spec;
         }

         function buildHostAddSpec() {
            var licenseKey = null;
            var hasEmbeddedOemLicense = state.userSelectedLicense.embeddedOem &&
                  state.userSelectedLicense.embeddedOem.toLowerCase() === "true";
            if (!hasEmbeddedOemLicense &&
                  !_.isEmpty(state.userSelectedLicense.serialKeys)) {
               licenseKey = state.userSelectedLicense.serialKeys[0];
            }

            var spec = _.extend(buildHostConnectionSpec(), {
               container: state.destination.ref,
               licenseKey: licenseKey
            });

            if (state.vmLocation) {
               spec.vmFolder = state.vmLocation.ref;
            }

            if (state.hostAccessManagerAvailable) {
               spec.lockdownMode = lockdownModeStrings.forSpec(state.lockdownMode);
               spec.enableLockDownMode = false;
            } else {
               spec.enableLockDownMode = state.lockdownMode === 'normal';
            }

            if (state.resourceLocationType === addHostWizardConstants.RESOURCE_LOCATION_NEW) {
               spec.newRpName = state.resourceLocationName;
            }

            return spec;
         }

         function buildHostAddScheduledTaskSpec() {

            var hostSpec = buildHostAddSpec();
            var mutationSpec = state.scheduleTaskPageModel;

            mutationSpec.spec = hostSpec;
            mutationSpec.spec._type = 'com.vmware.vsphere.client.host.add.HostAddSpec';
            mutationSpec.schedulingSpec.recurrence =
               vscSchedulingHelper.formatRecurrenceData(mutationSpec.schedulingSpec.recurrence);
            return mutationSpec;
         }

         /**
          * Builds a <code>HostConnectionStateSpec</code> for requesting host reconnection.
          */
         function buildHostConnectionStateSpec() {
            var connectSpec = _.extend(buildHostConnectionSpec(), {
               _type: 'com.vmware.vim.binding.vim.host.ConnectSpec',
               force: true
            });

            if (state.vmLocation) {
               connectSpec.vmFolder = state.vmLocation.ref;
            }

            var spec = {
               connectionOpType: 'connect',
               connectSpec: connectSpec
            };

            return spec;
         }

         return manager;
      };
   }
]);
