/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
/**
 * @name com.vmware.vsphere.client.cluster:drsConstants
 * @module com.vmware.vsphere.client.cluster
 *
 * @description
 *    The `drsConstants` service holds cluster DRS related constants.
 *
 *    List of available constants:
 *
 *    - automationLevel (DrsConfigInfo.DrsBehavior enum values)
 *       - **`MANUAL`** : VC suggests migration recommendations
 *       - **`PARTIALLY_AUTOMATED`** : VC automatically places VMs but suggests
 *             migration recommendations
 *       - **`FULLY_AUTOMATED`** : VMs are automatically placed and migrated
 *    - migrationThreshold (DrsConfigInfo.vmotionRate inverse values)
 *       - **`MIN`** : minimum value that can be set
 *       - **`MAX`** : maximum value
 *       - **`RANGE`** : Valid range of values for the migration threshold
 *   - dpmAutomationLevel (DpmConfigInfo_DpmBehavior enum values)
 *       - **`DISABLED`**: DPM is disabled
 *       - **`MANUAL`** : VC should generate recommendations for host power operations,
 *                but should not execute the recommendations automatically.
 *       - **`AUTOMATED`** VC should generate recommendations for host power operations,
 *                and should execute the recommendations automatically.
 *   - dpmThreshold (DpmConfigInfo.hostPowerActionRate inverse values)
 *       - **`MIN`** : minimum value that can be set
 *       - **`MAX`** : maximum value
 *       - **`RANGE`** : Valid range of values for the dpm threshold
 *   - faults (ClusterComputeResource.drsFaults)
 *       - **`DRSFAULT_POWERONVM`** : vm placement fault when VM is powered on
 *       - **`DRSFAULT_INCREASECAP`** : fault when trying to power on host to increase capacity
 *       - **`DRSFAULT_ENTERSTANDBY`** : fault when trying to power off host to decrease capacity
 *       - **`DRSFAULT_INSUFFICIENT_RESOURCES`** : fault when trying to reconfigure a VM's resources
 */
angular.module('com.vmware.vsphere.client.cluster').constant('drsConstants', {
   automationLevel: {
      MANUAL: 'manual',
      PARTIALLY_AUTOMATED: 'partiallyAutomated',
      FULLY_AUTOMATED: 'fullyAutomated'
   },
   AUTOMATION_LEVEL_DISABLED: 'disabled',
   migrationThreshold: {
      MIN: 1,
      MAX: 5,
      RANGE: [1, 2, 3, 4, 5]
   },
   dpmAutomationLevel: {
      DISABLED: '',
      MANUAL: 'manual',
      AUTOMATED: 'automated'
   },
   dpmThreshold: {
      MIN: 1,
      MAX: 5,
      RANGE: [1, 2, 3, 4, 5]
   },
   clusterRecommendationSpecOperation: {
      APPLY: 'apply',
      CANCEL: 'cancel',
      REFRESH: 'refresh'
   },
   faults: {
      DRSFAULT_POWERONVM: 'powerOnVm',
      DRSFAULT_INCREASECAP: 'increaseCapacity',
      DRSFAULT_ENTERSTANDBY: 'enterStandby',
      DRSFAULT_INSUFFICIENT_RESOURCES: 'unreservedCapacity'
   },
   drsPolicies: {
      EVEN_DISTRIBUTION_OPTION: 'TryBalanceVmsPerHost',
      EVEN_DISTRIBUTION_VALUE: '1',
      ALL_CONSUMED_MEMORY_METRIC_OPTION: 'PercentIdleMBInMemDemand',
      ALL_CONSUMED_MEMORY_METRIC_VALUE: '100',
      CPU_OVERCOMMITMENT_OPTION: 'MaxVcpusPerCore',
      CPU_OVERCOMMITMENT_MIN: 0,
      CPU_OVERCOMMITMENT_MAX: 500
   }
});
