/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace dvs_ui {

   import IPromise = angular.IPromise;
   export class DvsPortEditDialogPages {

      public static $inject = [
         "i18nService",
         "dvsPortEditVlanPageValidator",
         "dvsPortEditPropertiesPageValidator",
         "dvpgTrafficShapingPolicyService",
         "dvpgFailoverPolicyPageService",
         "vuiConstants"
      ];

      constructor(private i18nService: any,
                  private dvsPortEditVlanPageValidator: any,
                  private dvsPortEditPropertiesPageValidator: any,
                  private dvpgTrafficShapingPolicyService: any,
                  private dvpgFailoverPolicyPageService: any,
                  private vuiConstants: any) {
      }

      /**
       * Creates an array of page definitions for the Edit Port Settings dialog
       */
      public createPages(dialogScope: any): any[] {

         let pages: any[] = [];

         let propertiesPage: any = {
            title: this.i18nService.getString("NetworkUi", "port.properties.title"),
            contentUrl: "dvs-ui/resources/dvs/ports/edit/views/dvsPortEditPropertiesPage.html",
            onCommit: this.buildPropertiesPageCommitCallback(dialogScope)
         };

         let securityPage: any = {
            title: this.i18nService.getString("NetworkUi", "dvpg.policy.security.title"),
            contentUrl: "dvs-ui/resources/dvs/ports/edit/views/dvsPortEditSecurityPage.html",
            onCommit: (): boolean => {
               return true;
            }
         };

         let vlanPage: any = {
            title: this.i18nService.getString("NetworkUi", "dvpg.policy.vlan.title"),
            contentUrl: "dvs-ui/resources/dvs/ports/edit/views/dvsPortEditVlanPage.html",
            onCommit: this.buildVlanPageCommitCallback(dialogScope)
         };

         let trafficShapingPage: any = {
            title: this.i18nService.getString("NetworkUi", "dvpg.policy.shaping.title"),
            contentUrl: "dvs-ui/resources/dvs/ports/edit/views/dvsPortEditTrafficShapingPage.html",
            onCommit: this.buildTrafficShapingPageCommitCallback(dialogScope)
         };

         let failoverPage: any = {
            title: this.i18nService.getString("NetworkUi", "dvpg.policy.failover.title"),
            contentUrl: "dvs-ui/resources/dvs/ports/edit/views/dvsPortEditFailoverPage.html",
            onCommit: this.buildTeamingAndFailoverCommitCallback(dialogScope)
         };

         let monitoringPage: any = {
            title: this.i18nService.getString("NetworkUi", "dvpg.policy.monitoring.title"),
            contentUrl: "dvs-ui/resources/dvs/ports/edit/views/dvsPortEditMonitoringPage.html",
            onCommit: (): boolean => {
               return true;
            }
         };

         let miscellaneousPage: any = {
            title: this.i18nService.getString("NetworkUi", "dvpg.policy.misc.title"),
            contentUrl: "dvs-ui/resources/dvs/ports/edit/views/dvsPortEditMiscellaneousPage.html",
            onCommit: (): boolean => {
               return true;
            }
         };

         pages.push(propertiesPage);
         pages.push(vlanPage);

         if (!dialogScope.model.isUplink) {
            pages.splice(1, 0, securityPage, trafficShapingPage);
            pages.push(failoverPage);
         }

         if (dialogScope.model.monitoringPage
               && dialogScope.model.monitoringPage.monitoringPolicy
               && dialogScope.model.monitoringPage.monitoringPolicy.ipfixSupported) {
            pages.push(monitoringPage);
         }

         pages.push(miscellaneousPage);

         return pages;
      }

      private buildPropertiesPageCommitCallback(dialogScope: any): Function {
         return (): boolean => {
            if (!dialogScope.model.propertiesPage) {
               return true;
            }

            let errors: string[] =  this.dvsPortEditPropertiesPageValidator
                  .getErrors(dialogScope.model.propertiesPage);

            if (errors && errors.length > 0) {
               this.showDialogErrors(dialogScope, errors);
               return false;
            }

            return true;
         };
      }

      private buildVlanPageCommitCallback(dialogScope: any): Function {
         return (): boolean | IPromise<boolean> => {
            if (!dialogScope.model.vlanPage
                  || !dialogScope.model.vlanPage.overrideOptions
                  || !dialogScope.model.vlanPage.overrideOptions.overrideVlan) {
               return true;
            }

            return this.dvsPortEditVlanPageValidator.getValidationError(
                  dialogScope.model.portDvsUrn, dialogScope.model.vlanPage.vlanPolicy)
                  .then((errors: string[]) => {
                     if (errors && errors.length > 0) {
                        this.showDialogErrors(dialogScope, errors);
                        return false;
                     }

                     return true;
                  });
         };
      }

      private buildTrafficShapingPageCommitCallback(dialogScope: any): Function {
         return (): boolean => {
            if (!dialogScope.model.trafficShapingPage
                  || dialogScope.model.trafficShapingPage.shouldSkipValidation()) {
               return true;
            }

            let errors: string[] = this.dvpgTrafficShapingPolicyService.getErrorMessages(
                  dialogScope.model.trafficShapingPage.trafficShapingPolicy.inShapingPolicy,
                  dialogScope.model.trafficShapingPage.trafficShapingPolicy.outShapingPolicy);

            if (errors && errors.length > 0) {
               this.showDialogErrors(dialogScope, errors);
               return false;
            }

            return true;
         };
      }

      private buildTeamingAndFailoverCommitCallback(dialogScope: any): Function {
         return (): boolean => {
            if (!dialogScope.model.failoverPolicyPage
                  || dialogScope.model.failoverPolicyPage.shouldSkipValidation()) {
               return true;
            }

            if (this.dvpgFailoverPolicyPageService.shouldShowErrorWarningDialog(
                        dialogScope.model.failoverPolicyPage.failoverOrder.connectees,
                        dialogScope.model.failoverPolicyPage.failoverPolicy.loadBalancing,
                        dialogScope.model.failoverPolicyPage.failoverPolicy.beaconProbing,
                        dialogScope.model.failoverPolicyPage.overrideOptions)) {
               return this.dvpgFailoverPolicyPageService.showErrorWarningDialog(
                     dialogScope.model.failoverPolicyPage.failoverOrder.connectees,
                     dialogScope.model.failoverPolicyPage.failoverPolicy.loadBalancing,
                     dialogScope.model.failoverPolicyPage.failoverPolicy.beaconProbing,
                     dialogScope.model.failoverPolicyPage.overrideOptions);
            }

            return true;
         };
      }

      private showDialogErrors(dialogScope: any, errors: string[]): void {
         dialogScope.dialogOptions.validationBanner.messages = _.map(
               errors,
               (message: string) => {
                  return {
                     type: this.vuiConstants.validationBanner.type.ERROR,
                     text: message
                  };
               });
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .service("dvsPortEditDialogPages", DvsPortEditDialogPages);
}