/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
(function () {
   'use strict';
   angular.module('com.vmware.vsphere.client.storage')
      .service('datastoreTypePageService', datastoreTypePageService);

   datastoreTypePageService.$inject = [
      'i18nService',
      'vuiConstants',
      'datastoreConstants',
      'wizardPageService',
      '$log'];

   function datastoreTypePageService(i18nService, vuiConstants, datastoreConstants, wizardPageService, $log) {
      return {
         build: function (wizardScope, flowIds) {
            return {
               title: i18nService.getString('StorageUi', 'addDatastoreWizard.datastoreTypePage.title'),
               description: i18nService.getString('StorageUi', 'addDatastoreWizard.datastoreTypePage.description'),
               contentUrl: 'storage-ui/resources/storage/views/wizard/createDatastore/pages/DatastoreTypePage.html',
               decisionOptions: {
                  flowIds: flowIds,
                  selectedFlowId: datastoreConstants.datastoreTypes.VMFS
               },
               defaultState: vuiConstants.wizard.pageState.SKIPPED,
               onCommit: function() {
                  var selectedType = wizardScope.commonWizardManager.getDatastoreType();
                  switch (selectedType){
                     case datastoreConstants.datastoreTypes.VMFS:
                        wizardScope.activeWizardManager = wizardScope.vmfsWizardManager;
                        break;
                     case datastoreConstants.datastoreTypes.NFS:
                     case datastoreConstants.datastoreTypes.NFS3:
                        wizardScope.activeWizardManager = wizardScope.nfsWizardManager;
                        break;
                     case datastoreConstants.datastoreTypes.VVOL:
                        wizardScope.activeWizardManager = wizardScope.vvolWizardManager;
                        break;
                     default:
                        $log.warn('No activeWizardManager was found for ' + selectedType);
                  }

                  wizardPageService.markPageComplete(wizardScope.wizardConfig, selectedType);
                  return true;
               }
            };
         }
      };
   }
})();
