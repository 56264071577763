namespace h5_vm {
   import CustomizeGosSpecData = com.vmware.vsphere.client.h5.vm.data.CustomizeGosSpecData;
   export interface RecommendedPreselectionData {
      computeResourceId: string;
      folderId: string;
   }

   export interface RecommendedPreselections {
      createWorkflow: RecommendedPreselectionData;
      cloneWorkflow: RecommendedPreselectionData;
      templateWorkflow: RecommendedPreselectionData;
      defaultFolderId: string;
   }

   export class ProvisioningWizardViewData {
      private _viewData: any = {};
      private readonly SCALEOUT_CONFIGURATION_TYPE: string = "com.vmware.vcenter.ovf.ScaleOutParams";
      private readonly NETWORK_MAPPING_PARAMS_TYPE: string = "com.vmware.vcenter.ovf.NetworkMappingParams";
      private readonly IP_ALLOCATION_PARAMS_TYPE: string = "com.vmware.vcenter.ovf.IpAllocationParams";
      private readonly USER_PROPERTY_PARAM_TYPE: string = "com.vmware.vcenter.ovf.PropertyParams";
      private readonly VSERVICE_PROPERTY_PARAM_TYPE: string = "com.vmware.vcenter.ovf.VServiceParams";

      public setSourceObjectName(name: string): void {
         this._viewData.sourceObjectName = name;
      }

      public getSourceObjectName(): string {
         return this._viewData.sourceObjectName;
      }

      public setSourceObjectId(id: string): void {
         this._viewData.sourceObjectId = id;
      }

      public getSourceObjectId(): string {
         return this._viewData.sourceObjectId;
      }

      public setVmConnectedState(state: string): void {
         this._viewData.vmConnectedState = state;
      }

      public getVmConnectedState(): string {
         return this._viewData.vmConnectedState;
      }

      public setStorageProfiles(profiles: any): void {
         this._viewData.storageProfiles = profiles;
      }

      public getStorageProfiles(): any {
         return this._viewData.storageProfiles;
      }

      public setStorageNameMap(storageIdToNameMap: {[id: string]: string}): void {
         this._viewData.storageIdToNameMap = storageIdToNameMap;
      }

      public getStorageNameMap(): {[id: string]: string} {
         return this._viewData.storageIdToNameMap;
      }

      public setDiskFormatLabel(diskFormatLabel: string): void {
         this._viewData.diskFormatLabel = diskFormatLabel;
      }

      public getDiskFormatLabel(): string {
         return this._viewData.diskFormatLabel;
      }

      public setCustomizationSpecName(name: string): void {
         this._viewData.cuztomizationSpecName = name;
      }

      public getCustomizationSpecName(): string {
         return this._viewData.cuztomizationSpecName;
      }

      public setDatastoreObjectName(name: string): void {
         this._viewData.datastoreObjectName = name;
      }

      public getDatastoreObjectName(): string {
         return this._viewData.datastoreObjectName;
      }

      public setGuestOsType(type: string): void {
         this._viewData.guestOsType = type;
      }

      public getGuestOsType(): string {
         return this._viewData.guestOsType;
      }

      public setStorageLocatorItemsData(storage: any): void {
         this._viewData.storageLocatorItemsData = storage;
      }

      public getStorageLocatorItemsData(): any {
         return this._viewData.storageLocatorItemsData;
      }

      public setNeedToInputComputerName(need: boolean): void {
         this._viewData.needInputComputerName = need;
      }

      public getNeedToInputComputerName(): boolean {
         return !!this._viewData.needInputComputerName;
      }

      public setNICsToCustomize(nicsToCustomize: any): void {
         this._viewData.nicsToCustomize = nicsToCustomize;
      }

      public getNICsToCustomize(): any {
         return this._viewData.nicsToCustomize;
      }

      public setDatacenterId(id: string): void {
         this._viewData.datacenterId = id;
      }

      public getDatacenterId(): string {
         return this._viewData.datacenterId;
      }

      public setVCenterId(id: string): void {
         this._viewData.vCenterId = id;
      }

      public getVCenterId(): string {
         return this._viewData.vCenterId;
      }

      public setSelectedTemplate(template: any): void {
         this._viewData.selectedTemplate = template;
      }

      public getSelectedTemplate(): any {
         return this._viewData.selectedTemplate;
      }

      public setUrlTemplate(urlTemplate: string): void {
         this._viewData.urlTemplate = urlTemplate;
      }

      public getUrlTemplate(): string {
         return this._viewData.urlTemplate;
      }

      public setTemplateSourceType(sourceType: string): void {
         this._viewData.sourceType = sourceType;
      }

      public getTemplateSourceType(): string {
         return this._viewData.sourceType;
      }

      public setResourcePoolDeploymentResponse(response: any): void {
         this._viewData.resourcePoolDeploymentResponse = response;
      }

      public getResourcePoolDeploymentResponse(): any {
         return this._viewData.resourcePoolDeploymentResponse;
      }

      public setDeployFromLibraryCommonContext(context: any): void {
         this._viewData.commonContext = context;
      }

      public getDeployFromLibraryCommonContext(): any {
         return this._viewData.commonContext;
      }

      public getSclaeoutConfigurationFromLibraryCommonContext(): any {
         return _.find(this._viewData.commonContext, (item: any) => {
            return (_.isEqual(item._type, this.SCALEOUT_CONFIGURATION_TYPE));
         });
      }

      public getVserviceContextFromLibraryCommonContext(): any {
         return _.find(this._viewData.commonContext, (item: any) => {
            return (_.isEqual(item._type, this.VSERVICE_PROPERTY_PARAM_TYPE));
         });
      }

      public getDatastoreMappingParamsFromLibraryCommonContext(): any {
         let datastoreMappingParams = _.find(this._viewData.commonContext, (item: any) => {
            return item._type === "com.vmware.vcenter.ovf.DatastoreMappingParams";
         });
         return datastoreMappingParams;
      }

      public getNetworkMappingParamsFromLibraryCommonContext(): any {
         let networkMappingParams = _.find(this._viewData.commonContext, (item: any) => {
            return item._type === this.NETWORK_MAPPING_PARAMS_TYPE;
         });
         return networkMappingParams;
      }

      public getIpAllocationParamsFromLibraryCommonContext(): any {
         let ipAllocationParams = _.find(this._viewData.commonContext, (item: any) => {
            return item._type === this.IP_ALLOCATION_PARAMS_TYPE;
         });
         return ipAllocationParams;
      }

      public getCustomizeTemplateFromLibraryCommonContext(): any {
         let customizeTemplates = _.find(this._viewData.commonContext, (item: any) => {
            return item._type === this.USER_PROPERTY_PARAM_TYPE;
         });
         return customizeTemplates;
      }

      public setCustomizeTemplateCommonContext(newCustomizeTemplateData: any): any {
         let customizeTemplates = _.find(this._viewData.commonContext, (item: any) => {
            return item._type === this.USER_PROPERTY_PARAM_TYPE;
         });
         let index: any = this._viewData.commonContext.indexOf(customizeTemplates);
         if (index > 1) {
            this._viewData.commonContext.splice(index, 1);
            this._viewData.commonContext.push(newCustomizeTemplateData);
         }
      }

      public setCustomizeGosSpecData(customizeGosSpecData: CustomizeGosSpecData): void {
         this._viewData.customizeGosSpecData = customizeGosSpecData;
      }

      public getCustomizeGosSpecData(): CustomizeGosSpecData {
         return this._viewData.customizeGosSpecData;
      }

      setDeployOvfSession(ovfSession: any): void {
         this._viewData.ovfSession = ovfSession;
      }

      getDeployOvfSession(): any {
         return this._viewData.ovfSession;
      }

      setTemplateId(templateId: any): void {
         this._viewData.templateId = templateId;
      }

      getTemplateId(): any {
         return this._viewData.templateId;
      }

      getSslThumbprint(): any {
         return this._viewData.sslThumbprint;
      }

      setSslThumbprint(sslThumbprint: any): void {
         this._viewData.sslThumbprint = sslThumbprint;
      }

      setRecommendedFolderAndComputeResource(recommendedPreselections: RecommendedPreselections) {
         this._viewData.recommendedPreselections = recommendedPreselections;
      }

      getRecommendedFolderAndComputeResource(): RecommendedPreselections {
         return this._viewData.recommendedPreselections;
      }
   }

   angular.module("com.vmware.vsphere.client.vm")
         .factory("ProvisioningWizardViewData", () => ProvisioningWizardViewData);
}
