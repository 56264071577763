namespace h5_vm {

   import GuestOsDescriptor = com.vmware.vim.binding.vim.vm.GuestOsDescriptor;
   import VmConfigContext = com.vmware.vsphere.client.vm.config.VmConfigContext;
   import VirtualDeviceOption = com.vmware.vim.binding.vim.vm.device.VirtualDeviceOption;
   import VirtualPCIPassthrough = com.vmware.vim.binding.vim.vm.device.VirtualPCIPassthrough;
   import PciPassthroughInfo = com.vmware.vim.binding.vim.vm.PciPassthroughInfo;
   import PciDevice = com.vmware.vim.binding.vim.host.PciDevice;

   export class VmHwPciDeviceSetup {

      public static setup(fixtures: any, virtualMachineDevicesHelpers: any,
                          i18nService: any,
                          vmVersion: number = 11,
                          powerState: string, modifyDevice: boolean, mode: VmWorkflowMode, shared: boolean = false): VmHwPciDevice {

         let fix = fixtures.vmHardwareConfigData();

         let ctx: VmConfigContext = fix.vmConfigContext;
         let guest = ctx.environment.configOption.guestOSDescriptor[0];
         let environment = ctx.environment;
         environment.powerState = powerState;
         let privileges = ctx.privileges;

         if (!modifyDevice) {
            privileges = _.without(ctx.privileges, Privileges.VM_EDITDEVICE_PRIVILEGE);
         }

         environment.configTarget.pciPassthrough = VmHwPciDeviceSetup.getPciPassthoughFixture();

         let pciDeviceFixture = shared ? VmHwPciDeviceSetup.getPciSharedFixture() : VmHwPciDeviceSetup.getPciDeviceFixture();

         ctx.config.hardware.device.push(pciDeviceFixture);

         let virtualMachineDevices = virtualMachineDevicesHelpers.buildVirtualMachineDevices(ctx);
         let inflatedDevice: VmHwPciDevice = virtualMachineDevices.getVirtualDevice(13001);
         let vm: VmConfig = new VmConfig(ctx, guest, privileges, mode, i18nService);
         inflatedDevice.attachVmConfig(vm);
         inflatedDevice.removeCallback = VmHwPciDeviceSetup.removeCallback;
         return inflatedDevice;
      }

      private static removeCallback(inflatedDevice: EditableVirtualDeviceClass): void {
         inflatedDevice.markForRemoval();
      }

      private static getPciSharedFixture(): VirtualPCIPassthrough {

         let retVal: any = {
            "_type": "com.vmware.vim.binding.vim.vm.device.VirtualPCIPassthrough",
            "key": 13001,
            "deviceInfo": {
               "_type": "com.vmware.vim.binding.vim.Description",
               "label": "PCI device 0",
               "summary": "NVIDIA GRID vGPU profile1"
            },
            "backing": {
               "_type": "com.vmware.vim.binding.vim.vm.device.VirtualPCIPassthrough$VmiopBackingInfo",
               "vgpu": "profile1"
            },
            "connectable": null,
            "slotInfo": null,
            "controllerKey": 100,
            "unitNumber": 18
         };

         return retVal;
      }

      private static getPciDeviceFixture(): any {

         let retVal: any = {
            "_type": "com.vmware.vim.binding.vim.vm.device.VirtualPCIPassthrough",
            "key": 13001,
            "deviceInfo": {
               "_type": "com.vmware.vim.binding.vim.Description",
               "label": "PCI device 1",
               "summary": ""
            },
            "backing": {
               "_type": "com.vmware.vim.binding.vim.vm.device.VirtualPCIPassthrough$DeviceBackingInfo",
               "deviceName": "",
               "useAutoDetect": null,
               "id": "0000:00:1d.1",
               "deviceId": "3a35",
               "systemId": "4f881b30-0eab-995c-46a9-e83935aed97e",
               "vendorId": -32634
            },
            "connectable": null,
            "slotInfo": null,
            "controllerKey": 100,
            "unitNumber": 19
         };

         return retVal;
      }

      public static getPciPassthoughFixture(): PciPassthroughInfo[] {

         let device1: PciPassthroughInfo = {
            _type: "com.vmware.vim.binding.vim.vm.PciPassthroughInfo",
            name: "",
            // "configurationTag": null,
            pciDevice: {
               _type: "com.vmware.vim.binding.vim.host.PciDevice",
               id: "0000:00:1d.1",
               classId: 3075,
               bus: 0,
               slot: 29,
               function: 1,
               vendorId: -32634,
               subVendorId: 4156,
               vendorName: "Intel Corporation",
               deviceId: 14901, // 14901 = 0x3A35
               subDeviceId: 13069,
               // "parentBridge": null,
               deviceName: "82801JI (ICH10 Family) USB UHCI Controller #2"
            } as PciDevice,
            systemId: "4f881b30-0eab-995c-46a9-e83935aed97e"
         } as PciPassthroughInfo;

         let device2: PciPassthroughInfo = {
            "_type": "com.vmware.vim.binding.vim.vm.PciPassthroughInfo",
            "name": "",
            // "configurationTag": null,
            "pciDevice": {
               "_type": "com.vmware.vim.binding.vim.host.PciDevice",
               "id": "0000:00:1d.2",
               "classId": 3075,
               "bus": 0,
               "slot": 29,
               "function": 2,
               "vendorId": -32634,
               "subVendorId": 4156,
               "vendorName": "Intel Corporation",
               "deviceId": 14902,
               "subDeviceId": 13069,
               // "parentBridge": null,
               "deviceName": "82801JI (ICH10 Family) USB UHCI Controller #3"
            } as PciDevice,
            "systemId": "4f881b30-0eab-995c-46a9-e83935aed97e"
         } as PciPassthroughInfo;

         let retVal: PciPassthroughInfo[] = [];
         retVal.push(device1, device2);
         return retVal;
      }

      private static getPciSharedFixtureOld(): any {
         let retVal: any =
         {
            "_type": "com.vmware.vim.binding.vim.vm.device.VirtualPCIPassthrough",
            "key": 13001,
            "deviceInfo": {
               "_type": "com.vmware.vim.binding.vim.Description",
               "label": "PCI device 0",
               "summary": "NVIDIA GRID vGPU 1"
            },
            "backing": {
               "_type": "com.vmware.vim.binding.vim.vm.device.VirtualPCIPassthrough$VmiopBackingInfo",
               "vgpu": "1"
            },
            "connectable": null,
            "slotInfo": null,
            "controllerKey": 100,
            "unitNumber": 18
         };
         return retVal;
      }
   }
}
