angular.module('com.vmware.vsphere.client.vm').service('vmMigrationFinishPageService', [
   'i18nService',
   'wizardPageService',
   function(i18nService, wizardPageService) {
      return {
         build: function(wizardScope) {
            return {
               title: i18nService.getString('VmUi', 'MigrationWizard.readyPage.title'),
               description: i18nService.getString('VmUi', 'MigrationWizard.readyPage.description'),
               contentUrl: 'vm-ui/resources/vm/views/migrationWizard/pages/migrateFinishPage.html',
               headerUrl: 'vm-ui/resources/vm/views/migrationWizard/pages/migratePagesCustomHeader.html',

               onCommit: function() {

                  var finishPageState = wizardScope.migrationWizardManager.getFinishPageState();
                  if (finishPageState.recommendationService.hasFaults()) {
                     wizardPageService.markPageIncompleteWithError(
                           wizardScope.wizardConfig,
                           i18nService.getString('VmUi', 'MigrationWizard.readyPage.error.sdrsFaults')
                     );
                     return false;
                  }

                  if (finishPageState.xdrsRecommendationService
                        && finishPageState.xdrsRecommendationService.hasFaults()) {
                     var message = finishPageState.xdrsRecommendationService.getFaultsReason();
                     wizardPageService.markPageIncompleteWithError(
                           wizardScope.wizardConfig,
                           i18nService.getString('VmUi', 'MigrationWizard.readyPage.error.xdrs.drsFault',
                                 message)
                     );
                     return false;
                  }

                  if (hasInitErrors(finishPageState)) {
                     wizardPageService.markPageIncompleteWithError(
                           wizardScope.wizardConfig,
                           i18nService.getString('VmUi', 'MigrationWizard.readyPage.error.invalid')
                     );
                     return false;
                  }

                  wizardScope.migrationWizardManager.submit();
                  wizardPageService.markPageComplete(wizardScope.wizardConfig, wizardScope.migrationWizardManager.getSelectedMode());
                  return true;
               },

               finishReady: function () {
                  // If the page is loading ... disable the Finish button
                  return !wizardScope.wizardConfig.loading;
               }
            };

            function hasInitErrors(finishPageState) {
               if (finishPageState.xdrsRecommendationService) {
                  return finishPageState.xdrsRecommendationService.hasInitErrors();
               }

               return finishPageState.recommendationService
                     && finishPageState.recommendationService.hasInitErrors();
            }
         }
      };
   }]
);
