module ds_cluster_ui {

   class RemoveSdrsVmOverridesController {

      private objectId: string;
      private selectedVms: any;
      private INTRA_VM_AFFINITY_CLUSTER_DEFAULT = "ClusterDefault";

      public static $inject = ["$scope", "storageVmOverridesService",
         "dsClusterConstants"];

      constructor(private $scope: any,
                  private storageVmOverridesService: any,
                  private dsClusterConstants: any) {

         this.objectId = $scope._route.objectId;
         this.selectedVms = $scope.modalOptions.dialogData.context;
         this.$scope.modalOptions.alerts = [];

         $scope.modalOptions.onSubmit = () => {
            this.$scope.modalOptions.isAlertClosed = true;
            this.$scope.modalOptions.alerts = [];
            let spec = this.getRemoveSpec();
            this.storageVmOverridesService.validateVmOverride(this.objectId, spec)
                  .then((response: any) => {
                     if (response) {
                        let errors: any[] = response.result;
                        if (errors && errors.length > 0) {
                           let alert: Object = this.storageVmOverridesService
                                 .getVmOverrideErrorMessages(errors);
                           this.$scope.modalOptions.isAlertClosed = false;
                           this.$scope.modalOptions.alerts = [alert];
                        } else {
                           this.storageVmOverridesService.removeVmOverride(this.objectId,
                               spec);
                           this.$scope.closeModal();
                        }
                     }
                  });
         };
      }

      private getRemoveSpec(): Object {
         let operation: string = this.dsClusterConstants.vmOverridesOperationType.REMOVE;
         return {
            vmOverrides: _.map (this.selectedVms, (selectedVm: any) => {
               return {
                  vmSor: selectedVm.vm.provider,
                  intraVmAffinity: this.INTRA_VM_AFFINITY_CLUSTER_DEFAULT
               };
            }),
            operation: operation
         };
      }
   }

   angular.module("com.vmware.vsphere.client.dsCluster")
         .controller("RemoveSdrsVmOverridesController", RemoveSdrsVmOverridesController);
}
