angular.module('com.vmware.vsphere.client.vm').factory('VirtualMachineSpecBuilder', [
   '$q',
   'dataService',
   'vmDeviceInfoService',
   'defaultUriSchemeUtil',
   'creationTypeConstants',
   'cpuService',
   'storageProfileService',
   'managedEntityConstants',
   'diskProvisioningService',
   'podSelectionSpecService',
   'authorizationService',
   'virtualDiskPmemService',
   'pmemUtilService',
   'storageSelectorConstants',
   'deviceOperationConstants',
   'deviceClassLineageService',
   'deviceTypeConstants',
   function ($q,
             dataService,
             vmDeviceInfoService,
             defaultUriSchemeUtil,
             creationTypeConstants,
             cpuService,
             storageProfileService,
             managedEntityConstants,
             diskProvisioningService,
             podSelectionSpecService,
             authorizationService,
             virtualDiskPmemService,
             pmemUtilService,
             storageSelectorConstants,
             deviceOperationConstants,
             deviceClassLineageService,
             deviceTypeConstants) {
      var VirtualMachineSpecBuilder = function () {
         var vmParams = {};
         var _vmConfigEnvironmentPromise;
         // Vm config option promise indexed by vm version, has the following
         // format { vmVersion: , promise: }. This is needed because this promise is not
         // nulled when vm version is changed and we want to make sure that every time
         // when we return the promise it contains the config option for the exact vm
         // version it is requested
         var _vmConfigOptionByVmVersion;
         var _latestGosId;
         var _targetPrivilegesPromise;
         var _vmAdvancedStorageDataPromise;
         var _vmStoragePageDataPromise;

         /**
          * The privileges listed below are retrieved for New vm wizard
          * and are used to disable the `Add new device` menu or to disable some of
          * the properties in the customize hardware page
          *
          * "VirtualMachine.Config.AddRemoveDevice" - for `CD/DVD Drive`, `Network Adapter`,
          * `SCSI Controller`, `USB Controller` and `NVMe Controller`
          * "VirtualMachine.Config.HostUSBDevice" - for `Host USB Device`
          * "VirtualMachine.Config.AddNewDisk" - for `Hard Disk`
          * "VirtualMachine.Config.RawDevice" - for `RDM Disk`
          * "VirtualMachine.Config.AddExistingDisk" - for `Existing Hard Disk`
          *
          * VirtualMachine.Config.SwapPlacement - `Swap file location` in Advanced section in Vm Options tab
          * VirtualMachine.Config.Settings - `Latency Sensitivity` in Advanced section in Vm Options tab
          * VirtualMachine.Config.Resource - `Scheduling Affinity` in CPU section
          * VirtualMachine.Config.AdvancedConfig - VM options advanced config
          */
         var REQUIRED_PRIVILEGES_CREATE = [
            h5_vm.Privileges.VM_ADDREMOVEDEVICE_PRIVILEGE,
            h5_vm.Privileges.VM_ADDNEWDISK_PRIVILEGE,
            h5_vm.Privileges.VM_ADDEXISTINGDISK_PRIVILEGE,
            h5_vm.Privileges.VM_RAWDEVICE_PRIVILEGE,
            h5_vm.Privileges.VM_CONFIG_SWAPFILEPLACEMENT,
            h5_vm.Privileges.VM_RESOURCE_PRIVILEGE,
            h5_vm.Privileges.VM_CONFIG_SETTINGS,
            h5_vm.Privileges.VM_HOST_USB_DEVICE,
            h5_vm.Privileges.STORAGE_PROFILE_VIEW,
            h5_vm.Privileges.VM_CONFIG_ADVANCEDCONFIG,
            h5_vm.Privileges.CRYPTOGRAPHER_ENCRYPT,
            h5_vm.Privileges.CRYPTOGRAPHER_ENCRYPT_NEW_VM,
         ];

         /**
          * The privileges listed below are retrieved for Clone vm wizard
          * and are used to disable the `Add new device` menu or to disable some of
          * the properties in the customize hardware page
          *
          * "VirtualMachine.Config.AddRemoveDevice" - for `CD/DVD Drive`, `Network Adapter`,
          * `SCSI Controller`, `USB Controller` and `NVMe Controller`
          * "VirtualMachine.Config.HostUSBDevice" - for `Host USB Device`
          * "VirtualMachine.Config.AddNewDisk" - for `Hard Disk`
          * "VirtualMachine.Config.RawDevice" - for `RDM Disk`
          * "VirtualMachine.Config.AddExistingDisk" - for `Existing Hard Disk`
          *
          * VirtualMachine.Config.SwapPlacement - `Swap file location` in Advanced section in Vm Options tab
          * VirtualMachine.Config.Settings - `Settings`, `Debugging and statistics`, `Latency Sensitivity`,
          * `Firmware`, `Boot Delay`, `Force BIOS setup`, `Failed Boot Recovery`,
          * `Tools Upgrades`, `Time`, `Run VMware Tools Scripts`, `Standby response`,
          * `CPUID Mask`, `Hardware virtualization`, `Performance Counters`, `CPU/MMU Virtualization`
          * VirtualMachine.Config.Resource - `Reservation`, `Limit`, `Shares`,
          * `Scheduling Affinity`, `Limit - IOPs`
          * VirtualMachine.Config.EditDevice - `select settings type`, `Number of displays`,
          * `Total video memory`, `3D Graphics`, `3D Renderer`, `3D Memory`, `Virtual Device Node`,
          * `Adapter Type`, `DirectPath I/O`, `MAC Address`, `Change Type`, `SCSI Bus Sharing`,
          * `Shares`, `Limit - IOPs`, `Virtual flash read cache`, `Disk Mode`
          * VirtualMachine.Interact.DeviceConnection - `Connect At Power On` for CD/DVD drive or Network
          * VirtualMachine.Interact.SetCDMedia - select type, `Device Mode` for CD/DVD Drive
          * Network.Assign - Select network
          * VirtualMachine.Config.DiskExtend - Hard disk capacity
          * Datastore.AllocateSpace - Hard disk capacity
          * VirtualMachine.Config.Memory - `Memory`, `Memory Hot Plug`
          * VirtualMachine.Config.CPUCount - `CPU count`, `Cores per socket`, `CPU Hot Plug`
          * VirtualMachine.Config.RemoveDisk - remove disk (not RAW)
          * VirtualMachine.Interact.PowerOn - `Power on virtual machine after creation` property in select clone options
          * VirtualMachine.Config.MksControl - for Customize HW > VM Options > VMRC options settings
          * VirtualMachine.Config.AdvancedConfig - VM options advanced config
          */
         var REQUIRED_PRIVILEGES_CLONE = [
            h5_vm.Privileges.VM_ADDREMOVEDEVICE_PRIVILEGE,
            h5_vm.Privileges.VM_ADDNEWDISK_PRIVILEGE,
            h5_vm.Privileges.VM_ADDEXISTINGDISK_PRIVILEGE,
            h5_vm.Privileges.VM_RAWDEVICE_PRIVILEGE,
            h5_vm.Privileges.VM_CONFIG_SWAPFILEPLACEMENT,
            h5_vm.Privileges.VM_RESOURCE_PRIVILEGE,
            h5_vm.Privileges.VM_CONFIG_SETTINGS,
            h5_vm.Privileges.VM_HOST_USB_DEVICE,
            h5_vm.Privileges.VM_CONFIG_CPU_COUNT,
            h5_vm.Privileges.VM_CONFIG_MEMORY,
            h5_vm.Privileges.VM_DISKEXTEND_PRIVILEGE,
            h5_vm.Privileges.DATASTORE_ALLOCATESPACE_PRIVILEGE,
            h5_vm.Privileges.VM_REMOVEDISK_PRIVILEGE,
            h5_vm.Privileges.VM_EDITDEVICE_PRIVILEGE,
            h5_vm.Privileges.NETWORK_ASSIGN,
            h5_vm.Privileges.VM_INTERACT_DEVICE_CONNECTION,
            h5_vm.Privileges.VM_INTERACT_SET_CD_MEDIA,
            h5_vm.Privileges.VM_INTERACT_POWER_ON,
            h5_vm.Privileges.VM_CONFIG_MKSCONTROL,
            h5_vm.Privileges.VM_CONFIG_ADVANCEDCONFIG];

         var setCreationType = function (creationType) {
            if (vmParams.creationType !== creationType) {
               _targetPrivilegesPromise = undefined;
            }

            if (vmParams.creationType === creationType) {
               return;
            }

            vmParams.creationType = creationType;
            if (creationType === creationTypeConstants.DEPLOY_VM_FROM_TEMPLATE
               || creationType === creationTypeConstants.DEPLOY_VM_FROM_VAPP) {
               resetStorage();
            }
         };

         var getCreationType = function () {
            return vmParams.creationType;
         };

         var setTargetInformation = function (targetUid, datacenterUid, folderUid, locationName) {

            // if the selected folder has changed, retrieve the privileges again
            if (vmParams.targetInformation && vmParams.targetInformation.folderUid !== folderUid) {
               _targetPrivilegesPromise = undefined;
            }

            // if the selected datacenter has changed, retrieve the storageData again
            var previousRootFolder = vmParams.targetInformation ?
                  defaultUriSchemeUtil.getRootFolderFromVsphereObjectId(
                        vmParams.targetInformation.datacenterUid) : undefined;
            var rootFolder = defaultUriSchemeUtil.getRootFolderFromVsphereObjectId(datacenterUid);
            var isVcChanged = previousRootFolder !== rootFolder;

            vmParams.targetInformation = {
               targetUid: targetUid,
               datacenterUid: datacenterUid,
               folderUid: folderUid,
               name: locationName
            };

            if (isVcChanged) {
               _vmStoragePageDataPromise = undefined;
               getStoragePageData();
            }
         };

         var getTargetInformation = function () {
            return vmParams.targetInformation;
         };

         var setVmId = function (id) {
            var isVmIdChanged = id !== vmParams.vmId;
            vmParams.vmId = id;
            if (isVmIdChanged) {
               _vmAdvancedStorageDataPromise = undefined;
               getAdvancedStoragePageData();
            }
         };

         var getVmId = function () {
            return vmParams.vmId;
         };

         var setName = function (name) {
            vmParams.name = name;
         };

         var getName = function () {
            return vmParams.name;
         };

         var setHasEncryptNewVmPrivilege = function (hasEncryptNewVmPrivilege) {
            vmParams.hasEncryptNewVmPrivilege = hasEncryptNewVmPrivilege;
         };

         var getHasEncryptNewVmPrivilege = function () {
            return vmParams.hasEncryptNewVmPrivilege;
         };

         var setHasManageEncryptionPolicyPrivilege = function (hasManageEncryptionPolicyPrivilege) {
            vmParams.hasManageEncryptionPolicyPrivilege = hasManageEncryptionPolicyPrivilege;
         };

         var getHasManageEncryptionPolicyPrivilege = function () {
            return vmParams.hasManageEncryptionPolicyPrivilege;
         };

         var setTemplateName = function(name) {
            vmParams.templateName = name;
         };

         var getTemplateName = function () {
            return vmParams.templateName;
         };

         var setRecreateVm = function (recreateVm) {
            vmParams.recreateVm = recreateVm;
         };

         var getRecreateVm = function () {
            return vmParams.recreateVm;
         };

         var setComputeResourceId = function (newComputeResourceId) {
            if (newComputeResourceId !== vmParams.computeResourceId) {
               if (vmParams.computeResourceId) {
                  vmParams.recreateVm = true;
               }
               _vmConfigEnvironmentPromise = undefined;
               _vmConfigOptionByVmVersion = undefined;
               _targetPrivilegesPromise = undefined;
            }

            vmParams.computeResourceId = newComputeResourceId;
         };

         var getComputeResourceId = function () {
            return vmParams.computeResourceId;
         };

         /**
          * When the #getComputeResourceId returns a ResourcePool or VApp, this
          * method will return the ClusterComputeResource or the ComputeResource where
          * these are located. Otherwise it will have the value of 'undefined'.
          *
          * @returns {string|undefined}
          */
         var getComputeResourceOwnerId = function () {
            return vmParams.computeResourceOwnerId;
         };

         /**
          * @param {string|undefined} ownerId
          */
         var setComputeResourceOwnerId = function (ownerId) {
            vmParams.computeResourceOwnerId = ownerId;
         };

         var setComputeResourceName = function (name) {
            vmParams.computeResourceName = name;
         };

         var getComputeResourceName = function () {
            return vmParams.computeResourceName;
         };

         var setResourcePool = function (resourcePool) {
            vmParams.resourcePool = resourcePool;
         };

         var getResourcePool = function () {
            return vmParams.resourcePool;
         };

         var setVmOriginalConfigSpec = function (spec) {
            vmParams.originalConfigSpec = spec;
         };

         var setIsResourcePoolInVapp = function (isRpInVapp) {
            vmParams.isRpInVapp = isRpInVapp;
         };

         var isResourcePoolInVapp = function () {
            return vmParams.isRpInVapp;
         };

         var getVmOriginalConfigSpec = function () {
            return vmParams.originalConfigSpec;
         };

         var setVmConfigSpec = function (spec) {
            vmParams.configSpec = spec;
         };

         var getSourceVmStorageConfig = function () {
            if (!vmParams.sourceVmStorageConfig) {
               return null;
            }

            return vmParams.sourceVmStorageConfig[0];
         };

         var getSourceVmStorageConfigDisk = function (diskKey) {
            if (!getSourceVmStorageConfig()) {
               return null;
            }

            return _.find(getSourceVmStorageConfig().vmDisks, function(disk) {
               return disk.key === diskKey;
            });
         };

         var setSourceVmStorageConfig = function (sourceVmStorageConfig) {
            vmParams.sourceVmStorageConfig = sourceVmStorageConfig;
         };

         var getVmConfigSpec = function () {
            return vmParams.configSpec;
         };

         var getStorageObject = function () {
            return vmParams.storageObject;
         };

         var getDisableSdrs = function () {
            return vmParams.disableSdrs;
         };

         var setDisableSdrs = function (disableSdrs) {
            vmParams.disableSdrs = disableSdrs;
         };

         var getVmVersion = function () {
            return vmParams.vmVersion;
         };

         var setVmVersion = function (version) {
            if (vmParams.vmVersion !== version) {
               setRecreateVm(true);
               _vmConfigEnvironmentPromise = null;
            }
            vmParams.vmVersion = version;
         };

         var getScheduledVmVersion = function () {
            return vmParams.scheduledVmVersion;
         };

         var setScheduledVmVersion = function (version) {
            vmParams.scheduledVmVersion = version;
         };

         var getScheduledData = function () {
            return vmParams.scheduledData;
         };

         var setScheduledData = function (data) {
            vmParams.scheduledData = data;
         };

         var getGosFamily = function () {
            return vmParams.gosFamily;
         };

         var getGosVersion = function () {
            return vmParams.gosVersion;
         };

         var getGosName = function () {
            return vmParams.gosName;
         };

         var setGuestOsId = function (id) {
            vmParams.guestOsId = id;
         };

         var getGuestOsId = function () {
            return vmParams.guestOsId;
         };

         var setScheduledGuestOsId = function (id) {
            vmParams.scheduledGuestOsId = id;
         };

         var getScheduledGuestOsId = function () {
            return vmParams.scheduledGuestOsId;
         };

         var setScheduledGuestName = function (name) {
            vmParams.scheduledGuestName = name;
         };

         var getScheduledGuestName = function () {
            return vmParams.scheduledGuestName;
         };

         var setGosName = function (gosName) {
            vmParams.gosName = gosName;
         };

         var setGosFamilyAndVersion = function (familyAndVersion) {
            var family = familyAndVersion.family;
            var version = familyAndVersion.version;

            vmParams.gosFamily = family;

            var newVersionId = version ? version.id : undefined;
            var preservedVersionId = vmParams.gosVersion
                  ? vmParams.gosVersion.id
                  : undefined;
            if (newVersionId !== preservedVersionId) {
               setRecreateVm(true);
            }
            vmParams.gosVersion = version;
         };

         var getVbsEnabled = function () {
            return vmParams.vbsEnabled;
         };
         var setVbsEnabled = function (vbsEnabled) {
            vmParams.vbsEnabled = vbsEnabled;
         };

         var getVbsSupported = function () {
            return vmParams.vbsSupported;
         };
         var setVbsSupported = function (vbsSupported) {
            vmParams.vbsSupported = vbsSupported;
         };

         var setVirtualMachineDevices = function (virtualMachineDevices) {
            vmParams.virtualMachineDevices = virtualMachineDevices;
         };

         var getVirtualMachineDevices = function () {
            return vmParams.virtualMachineDevices;
         };

         var setCloneOptions = function (options) {
            vmParams.cloneOptions = options;
         };

         var shouldRetrieveGosDescriptor = function() {
            return vmParams.gosVersion.id !== _latestGosId;
         };

         var retrieveGosDescriptor = function() {
            var hostRef = null;
            var computeResourceRef =
                  defaultUriSchemeUtil.getManagedObjectReference(vmParams.computeResourceId);
            if (computeResourceRef.type === managedEntityConstants.HOST) {
               hostRef = computeResourceRef;
            }

            var envBrowserId = defaultUriSchemeUtil.getVsphereObjectId(vmParams.envBrowserRef);

            var params = [{
               propertyName: 'vmConfigGuestOsDescriptor',
               parameterType: 'com.vmware.vsphere.client.h5.vm.model.provisioning.VmConfigEnvironmentPerPoolParams',
               parameter: {
                  _type: 'com.vmware.vsphere.client.h5.vm.model.provisioning.VmConfigEnvironmentPerPoolParams',
                  key: getVmVersion(),
                  hostRef: hostRef,
                  gosId: getGosVersion() ? getGosVersion().id : getGuestOsId()
               }
            }];
            return dataService.getProperties(
                        envBrowserId,
                        ['vmConfigGuestOsDescriptor'],
                        {
                           propertyParams: params
                        }
                  ).then(function(guestOsDescriptor) {
               _latestGosId = guestOsDescriptor.vmConfigGuestOsDescriptor.id;
               return guestOsDescriptor;
            });

         };

         var setCustomPcName = function (name) {
            var LINUX_PREP = 'com.vmware.vim.binding.vim.vm.customization.LinuxPrep';
            var SYS_PREP = 'com.vmware.vim.binding.vim.vm.customization.Sysprep';
            var customizationSpec = getCustomizationSpec();
            var nameCustomization = {
               _type: 'com.vmware.vim.binding.vim.vm.customization.FixedName',
               name: name
            };
            if (customizationSpec.identity._type === LINUX_PREP) {
               customizationSpec.identity.hostName = nameCustomization;
            } else if (customizationSpec.identity._type === SYS_PREP) {
               customizationSpec.identity.userData.computerName = nameCustomization;
            }
            setCustomizationSpec(customizationSpec);
         };

         var setCustomIpv4ForNIC = function (index, nic) {
            var customizationSpec = getCustomizationSpec();
            customizationSpec.nicSettingMap[index].adapter.gateway = [];
            if (nic.ipv4.defaultGateway) {
               customizationSpec.nicSettingMap[index].adapter.gateway.push(nic.ipv4.defaultGateway);
            }
            if (nic.ipv4.alternateGateway) {
               customizationSpec.nicSettingMap[index].adapter.gateway.push(nic.ipv4.alternateGateway);
            }
            customizationSpec.nicSettingMap[index].adapter.subnetMask = nic.ipv4.subnetMask;
            customizationSpec.nicSettingMap[index].adapter.ip = {
               _type: 'com.vmware.vim.binding.vim.vm.customization.FixedIp',
               ipAddress: nic.ipv4.address
            };

            setCustomizationSpec(customizationSpec);
         };

         var setCustomIpv6ForNIC = function (index, nic) {
            var customizationSpec = getCustomizationSpec();
            customizationSpec.nicSettingMap[index].adapter.ipV6Spec.ip = [{
               _type: 'com.vmware.vim.binding.vim.vm.customization.FixedIpV6',
               ipAddress: nic.ipv6.address,
               subnetMask: nic.ipv6.subnetPrefixLength
            }];

            customizationSpec.nicSettingMap[index].adapter.ipV6Spec.gateway = nic.ipv6.gateway;

            setCustomizationSpec(customizationSpec);
         };

         var setCustomizationSpec = function (spec) {
            vmParams.customizationSpec = spec;
         };

         var getCustomizationSpec = function () {
            return vmParams.customizationSpec;
         };

         var setCustomizationSpecInfo = function (info) {
            vmParams.CustomizationSpecInfo = info;
         };

         var getCustomizationSpecInfo = function () {
            return vmParams.CustomizationSpecInfo;
         };

         var getCloneOptions = function () {
            return vmParams.cloneOptions;
         };

         var setSelectedStorageProfile = function (profile) {
            if (profile && profile.id) {
               profile.profileId = profile.id;
            }
            vmParams.selectedStorageProfile = profile;
         };

         var getSelectedStorageProfile = function () {
            return vmParams.selectedStorageProfile;
         };

         var setSelectedReplicationGroupInfo = function (replicationGroupInfo) {
            vmParams.selectedReplicationGroupInfo = replicationGroupInfo;
         };

         var getSelectedReplicationGroupInfo = function () {
            return vmParams.selectedReplicationGroupInfo;
         };

         var setSelectedStorageProfileForDisks = function (profile) {
            if (profile && profile.id) {
               profile.profileId = profile.id;
            }
            vmParams.selectedStorageProfileForDisks = profile;
         };

         var getSelectedStorageProfileForDisks = function () {
            // If there is a specifically set storage profile for disks, then return it.
            if (vmParams.selectedStorageProfileForDisks) {
               return vmParams.selectedStorageProfileForDisks;
            }

            // else, return the overall storage profile.
            return getSelectedStorageProfile();
         };

         var storageObjectIsPod = function (storageObject) {
            storageObject = storageObject || getStorageObject();
            if (!storageObject) {
               return false;
            }
            return storageObject.storageRef &&
               storageObject.storageRef.type === managedEntityConstants.STORAGE_POD;
         };

         var storageObjectIsStandaloneDs = function (storageObject) {
            storageObject = storageObject || getStorageObject();
            if (!storageObject) {
               return false;
            }
            return !storageObjectIsPod(storageObject)
               || (storageObject.parentStoragePod && vmParams.disableSdrs);
         };

         var setStorageObject = function (storageObject) {
            if (storageObject && getStorageObject()
               && getStorageObject().storageRef.value !== storageObject.storageRef.value) {
               setRecreateVm(true);
            }

            vmParams.storageObject = storageObject;
         };

         var setStorageObjectAndDiskFormat = function (storageObject, diskFormat) {
            setStorageObject(storageObject);
            vmParams.diskFormat = diskFormat;
         };

         var getDiskFormat = function () {
            return vmParams.diskFormat;
         };

         // TODO: proynovd: WHY are the existing disks accessable by referance from "this"
         // queried through the DataService?
         var _cachedVmDevicesPromise;

         function getVirtualDisks() {
            if (!_cachedVmDevicesPromise) {
               _cachedVmDevicesPromise = dataService.getProperties(getVmId(), 'virtualDisks');
            }

            return _cachedVmDevicesPromise;
         }

         /**
          * @param diskDevice - the device spec of the virtualDisk
          * @returns the same object with an additional property 'profile' containing
          *       the selected storage profile for the virtualDisk.
          */
         var appendStorageProfileToDisk = function (diskDevice) {
            var profile = getSelectedStorageProfile();
            if (getVirtualMachineDevices()
                  && getVirtualMachineDevices().getVirtualDevice(diskDevice.key)) {
               profile = getVirtualMachineDevices().getVirtualDevice(diskDevice.key).getProfile();
            }

            diskDevice.profile = profile;
            return diskDevice;
         };

         /**
          * @param diskDevice - the device spec of the virtualDisk
          * @returns array of profile spec
          */
         var makeStorageProfile = function (diskDevice) {
            if (vmParams.containsSpbmIssues) {
               return [];
            }
            var profileId = diskDevice.profile && diskDevice.profile.profileId;
            var replicationGroup;
            // get the disks configuration as it was set in the select storage page
            // and use the calculated profile there
            var storageVirtualDisks = getVmDisksStorage()[getVmId()];
            var disk = _.find(storageVirtualDisks, function(disk) {
               return disk.key === diskDevice.key;
            });
            if (disk) {
               profileId = disk.profile && disk.profile.id;
               replicationGroup = disk.replicationGroup;
            }
            return [storageProfileService.makeProfile(profileId, replicationGroup)];
         };

         /**
          * Updates the profile of virtual disks that already exist like in the clone case
          * with the profiles selected in the storage page
          * @param diskDevices - a list of disk changes for already existing disks
          */
         var appendStorageProfileToExistingDisks = function (diskDevices) {
            _.each(diskDevices, function(diskDevice) {
               var profileId = getSelectedStorageProfile() && getSelectedStorageProfile().id;
               var rgInfo = getSelectedReplicationGroupInfo();
               // get the disks configuration as it was set in the select storage page
               // and use the calculated profile there
               var storageVirtualDisks = getVmDisksStorage()[getVmId()];
               var disk = _.find(storageVirtualDisks, function(disk) {
                  return disk.key === diskDevice.getKey();
               });
               if (disk) {
                  profileId = disk.profile && disk.profile.id;
                  rgInfo = disk.replicationGroup;
               }
               // if the disk is new either use the selected profile on the storage page
               // or the selected for the disk

               diskDevice.setProfile(
                     storageProfileService.makeProfile(profileId, rgInfo));
            });
         };

         var getReplicationGroupForDisk = function (diskDevice) {
            var rgInfo = getSelectedReplicationGroupInfo();
            if (getVirtualMachineDevices() &&
                  getVirtualMachineDevices().getVirtualDevice(diskDevice.key)) {
               rgInfo = getVirtualMachineDevices().getVirtualDevice(
                     diskDevice.key).getCurrentReplicationGroup();
            }
            return rgInfo;
         };

         var getStorageBaselineId = function () {
            if (!getStorageSelectorState()) {
               return;
            } else {
               return getStorageSelectorState().storageBaselineId;
            }
         };

         var filterVirtualDisksBeforeMakingThemDiskLocators = function (virtualDisks, newDisks) {
            if (getIsAdvancedStorageMode()) {
               var storageSelectorDisks =
                  getStorageSelectorState().vmStorageConfigInAdvancedMode[0].vmDisks;
               return virtualDiskPmemService.filterVirtualDisksToDiskLocatorsForPmemAdvanced(
                  virtualDisks, storageSelectorDisks);
            } else {
               var storageBaselineId = getStorageBaselineId();
               return virtualDiskPmemService.filterVirtualDisksToDiskLocatorsForPmem(
                  virtualDisks, storageBaselineId, newDisks);
            }
         };

         var applyPmemRulesOnVirtualDiskBacking = function (backing, diskKey, diskFormat) {
            if (getIsAdvancedStorageMode()) {
               var storageSelectorDisks =
                  getStorageSelectorState().vmStorageConfigInAdvancedMode[0].vmDisks;
               return virtualDiskPmemService.applyPmemRulesOnVirtualDiskBackingAdvanced(
                  diskKey, backing, storageSelectorDisks);
            } else {
               var storageBaselineId = getStorageBaselineId();
               return virtualDiskPmemService.applyPmemRulesOnVirtualDiskBacking(
                  backing, storageBaselineId, diskFormat);
            }
         };

         var applyPmemRulesOnVirtualDiskBackingForSDRS = function (podSelectionSpec, diskFormat) {
            if (!podSelectionSpec || !podSelectionSpec.initialVmConfig) {
               return;
            }
            // There is a separate locator for the VMX file, but the user might have
            // placed only a single disk on SDRS cluster, so find the locator that has
            // non-empty disk array.
            var disksPodLocator = _.find(podSelectionSpec.initialVmConfig, function (diskPodLocator) {
               return (diskPodLocator.disk && diskPodLocator.disk.length);
            });
            if (!disksPodLocator) {
               return;
            }

            if (getIsAdvancedStorageMode()) {
               var storageSelectorDisks =
                  getStorageSelectorState().vmStorageConfigInAdvancedMode[0].vmDisks;
               _.forEach(disksPodLocator.disk, function (disk) {
                  disk.diskBackingInfo = virtualDiskPmemService.applyPmemRulesOnVirtualDiskBackingAdvanced(
                     disk.diskId, disk.diskBackingInfo, storageSelectorDisks);
               });
            } else {
               var storageBaselineId = getStorageBaselineId();
               _.forEach(disksPodLocator.disk, function (disk) {
                  disk.diskBackingInfo = virtualDiskPmemService.applyPmemRulesOnVirtualDiskBacking(
                     disk.diskBackingInfo, storageBaselineId, diskFormat);
               });
            }
         };

         var getPmemDiskDeviceChanges = function (virtualDisks) {
            var result = [];
            var virtualDiskConvertedToDeviceChange;
            if (getIsAdvancedStorageMode()) {
               var storageSelectorDisks =
                  getStorageSelectorState().vmStorageConfigInAdvancedMode[0].vmDisks;
               _.forEach(virtualDisks, function (originalVirtualDisk) {
                  virtualDiskConvertedToDeviceChange =
                     virtualDiskPmemService.convertVirtualDiskToDeviceChangeForPmemAdvanced(
                        originalVirtualDisk, storageSelectorDisks);
                  if (virtualDiskConvertedToDeviceChange) {
                     result.push(virtualDiskConvertedToDeviceChange);
                  }
               });
               return result;
            } else {
               var storageBaselineId = getStorageBaselineId();
               var diskStorageProfile = getSelectedStorageProfileForDisks();
               _.forEach(virtualDisks, function (originalVirtualDisk) {
                  virtualDiskConvertedToDeviceChange =
                     virtualDiskPmemService.convertVirtualDiskToDeviceChangeForPmem(
                        originalVirtualDisk, storageBaselineId, diskStorageProfile);
                  if (virtualDiskConvertedToDeviceChange) {
                     result.push(virtualDiskConvertedToDeviceChange);
                  }
               });
            }

            return result;
         };

         var getBackingInfoForDisk = function (diskDevice, diskFormat) {
            if (!diskFormat || !diskFormat.type || !diskDevice) {
               return;
            }
            if (pmemUtilService.isPmemDisk(diskDevice)) {
               return diskDevice.backing;
           }
           if (diskFormat.type === 'sameAsSource') {
               return null;
           } else {
               return diskProvisioningService.getBackingInfo(diskFormat.type);
            }
         };

         var getRelocationSpecInfoForClone = function (diskFormat) {
            return getVirtualDisks().then(function (properties) {
               var virtualDisks = properties['virtualDisks'];
               var virtualDisksApplicableToDiskLocator =
                  filterVirtualDisksBeforeMakingThemDiskLocators(virtualDisks);
               var keepExistingProfileAssignments = !getIsAdvancedStorageMode()
                  && getStorageSelectorState()
                  && getStorageSelectorState().basicModeState
                  && getStorageSelectorState().basicModeState.profilesData
                  && getStorageSelectorState().basicModeState.profilesData.selectedProfile
                  && getStorageSelectorState().basicModeState.profilesData.selectedProfile
                     .keepExistingProfileAssignments;

               // build disk locators only for the datastores that are not placed on storage pod
               var filteredDisks = _.filter(virtualDisksApplicableToDiskLocator, function (diskDevice) {
                  if (!getIsAdvancedStorageMode()) {
                     return storageObjectIsStandaloneDs();
                  }
                  var storageSelectorDisks =
                        getStorageSelectorState().vmStorageConfigInAdvancedMode[0].vmDisks;
                  var storageSelectorDisk = _.find(storageSelectorDisks, function (disk) {
                     return disk.key === diskDevice.key;
                  });
                  return storageObjectIsStandaloneDs(storageSelectorDisk.storageObj);
               });

               var diskRelocationSpecs = _.map(filteredDisks, function (diskDevice) {
                  return buildDiskLocator(diskDevice, diskFormat);
               });
               // As we don't want to send relocates for the disks placed on storage pods
               diskRelocationSpecs = _.filter(diskRelocationSpecs, function (disk) {
                  return disk.datastore !== null;
               });

               var relocationSpecInfo =  {
                  deviceChange: getPmemDiskDeviceChanges(virtualDisks),
                  disk: diskRelocationSpecs
               };

               // If we are in basic mode and keeping the existing storage profiles,
               // then use the source VM's profile
               var vmHomeProfileId = keepExistingProfileAssignments
                  ? getSourceVmStorageConfig().vmHome.storageProfile.id
                  : getSelectedStorageProfile().profileId;
               var vmHomeProfile = [storageProfileService.makeProfile(
                  vmHomeProfileId,
                  getSelectedReplicationGroupInfo())];
               relocationSpecInfo.profile = vmParams.containsSpbmIssues
                  ? []
                  : vmHomeProfile;
               return relocationSpecInfo;
            });
         };

         var buildDiskLocator = function (diskDevice, diskFormat) {
            diskFormat = diskFormat || vmParams.diskFormat;
            var datastoreRef = storageObjectIsStandaloneDs() ?
                  getStorageObject().storageRef
                  : null;
            if (getIsAdvancedStorageMode()) {
               var storageSelectorDisks =
                     getStorageSelectorState().vmStorageConfigInAdvancedMode[0].vmDisks;
               var storageSelectorDisk = _.find(storageSelectorDisks, function (disk) {
                  return disk.key === diskDevice.key;
               });
               datastoreRef = storageObjectIsStandaloneDs(storageSelectorDisk.storageObj)
                     ? storageSelectorDisk.storageObj.storageRef
                     : null;
               diskFormat = storageSelectorDisk.diskFormat;
            }
            var diskBacking = getBackingInfoForDisk(diskDevice, diskFormat);
            diskBacking = applyPmemRulesOnVirtualDiskBacking(diskBacking, diskDevice.key, diskFormat);
            var diskRelocationSpec = {
               '_type': 'com.vmware.vim.binding.vim.vm.RelocateSpec$DiskLocator',
               'datastore': datastoreRef,
               'diskBackingInfo': diskBacking,
               'diskId': diskDevice.key
            };
            diskRelocationSpec.profile = makeStorageProfile(diskDevice);

            return diskRelocationSpec;
         };

         var setStorageForDisk = function (storageObj, disk) {
            vmParams.storageDiskList = vmParams.storageDiskList || {};

            if (!storageObj) {
               delete vmParams.storageDiskList[disk.key];
               return;
            }

            vmParams.storageDiskList[disk.key] = {
               diskKey: disk.key,
               diskBacking: disk.backing,
               dsId: storageObj.id,
               dsName: storageObj.name,
               dsObject: storageObj.storageObject || storageObj // in case we pass storage pod
            };
         };

         function getStorageDiskList() {
            return vmParams.storageDiskList;
         }

         var getStorageForDisk = function (diskKey) {
            if (vmParams.storageDiskList && vmParams.storageDiskList[diskKey]) {
               return vmParams.storageDiskList[diskKey];
            }
            return null;
         };

         var getStorageNameForDisk = function (diskKey) {
            if (vmParams.storageDiskList && vmParams.storageDiskList[diskKey]) {
               return vmParams.storageDiskList[diskKey].dsName;
            }
            return null;
         };

         var getStorageNameAndIdForDisk = function (diskKey) {
            if (vmParams.storageDiskList && vmParams.storageDiskList[diskKey]) {

               return {
                  name: vmParams.storageDiskList[diskKey].dsName,
                  id: vmParams.storageDiskList[diskKey].dsId
               };
            }
            return null;
         };

         var getVmCreateSpec = function () {
            var resPoolMoRef = defaultUriSchemeUtil.getManagedObjectReference(getResourcePool().id);

            var parentType = 'folder';
            var folderMoRef;
            // The folder should populated only if the parent type is folder.
            // In VApp case the folder must not be set.
            if (resPoolMoRef.type === managedEntityConstants.V_APP || isResourcePoolInVapp()) {
               parentType = 'vapp';
               folderMoRef = null;
            } else {
               folderMoRef = defaultUriSchemeUtil.getManagedObjectReference(
                  getTargetInformation().folderUid);
            }

            // This holds the compute resource selected on the compute resource page,
            // can be cluster, host, resource pool or vapp (or maybe none of these?).
            var computeResMoRef =
               defaultUriSchemeUtil.getManagedObjectReference(getComputeResourceId());
            var hostRef = null;
            if (computeResMoRef.type === managedEntityConstants.HOST) {
               hostRef = computeResMoRef;
            }
            var deviceChanges = getVirtualMachineDevices().getDeviceChanges();

            var vmCreateSpec = {
               parentType: parentType,
               folder: folderMoRef,
               pool: resPoolMoRef,
               host: hostRef,
               spec: {
                  config: angular.merge({
                     _type: "com.vmware.vim.binding.vim.vm.ConfigInfo",
                     name: getName(),
                     guestId: getGosVersion().id,
                     alternateGuestName: getGosName() ? getGosName() : null
                  }, getVmConfigSpec().config),
                  deviceChange: deviceChanges,
                  npivWwnOp: getVmConfigSpec().config.npivWwnOp,
                  cpuFeatureMask: cpuService.createCpuIdInfoDelta(
                     [], getVmConfigSpec().config.cpuFeatureMask)
               }
            };

            var storageProfile = getSelectedStorageProfile();
            if (storageProfile && !vmParams.containsSpbmIssues) {
               vmCreateSpec.spec.profile = [
                  storageProfileService.makeProfile(storageProfile.id, getSelectedReplicationGroupInfo())];
            } else {
               vmCreateSpec.spec.profile = [];
            }

            diskProvisioningService.setBackingDatastore(
               deviceChanges,
               getStorageObject(),
               vmParams.storageDiskList
            );


            vmCreateSpec.podSelectionSpec = podSelectionSpecService.createSpec(
               getNewVirtualDisks(deviceChanges),
               getStorageObject(),
               vmParams.storageDiskList);

            return vmCreateSpec;
         };

         var getDeployVmtxSpec = function () {
            var clusterValueField = null;
            var resourcePoolValueField = null;
            var hostValueField = null;
            var folderValueField = null;
            var folderUri = null;
            var gosCustomizationSpecName = null;

            var computeResMoRef =
                  defaultUriSchemeUtil.getManagedObjectReference(getComputeResourceId());
            switch (computeResMoRef.type) {
               case managedEntityConstants.CLUSTER:
                  clusterValueField = computeResMoRef.value;
                  break;
               case managedEntityConstants.HOST:
                  hostValueField = computeResMoRef.value;
                  break;
               case managedEntityConstants.RESOURCE_POOL:
                  resourcePoolValueField = computeResMoRef.value;
                  break;
               case managedEntityConstants.V_APP:
                  // NOTE: Setting V_APP value as a resource pool.
                  resourcePoolValueField = computeResMoRef.value;
                  break;
               default:
                  break;
            }
            var targetInformation = getTargetInformation();
            if (targetInformation && targetInformation.folderUid) {
               folderUri = targetInformation.folderUid;
            }
            if (folderUri) {
               folderValueField =
                     defaultUriSchemeUtil.getManagedObjectReference(folderUri).value;
            }
            if (getCustomizationSpecName()) {
               gosCustomizationSpecName = getCustomizationSpecName();
            }





            return _.extend({
               name: getName(),
               libraryItemUri: getLibraryItemId(),
               powerOn: getCloneOptions().powerOn,
               guestCustomizationSpec: {name: gosCustomizationSpecName},
               placementSpec: {
                  folder: folderValueField,
                  cluster: clusterValueField,
                  resourcePool: resourcePoolValueField,
                  host: hostValueField
               },
               hardwareCustomization: getHardwareCustomizationSpec()
            }, getVmtxStorageSpecSettings());
         };

         var getHardwareCustomizationSpec = function () {

            var hardwareCustomizationSpec;

            if (getCustomizeCloneHardware() === true) {
               var deviceChanges = getVirtualMachineDevices().getDeviceChanges();
               var cpuUpdate = {
                  numCpus: getVmConfigSpec().hardware.numCPU,
                  numCoresPerSocket:  getVmConfigSpec().hardware.numCoresPerSocket
               };
               var memoryUpdate = {
                  memory: getVmConfigSpec().hardware.memoryMB
               };
               var originalConfig = getVmOriginalConfigSpec();
               var originalVDisks = _.filter(originalConfig.hardware.device, function (deviceChange) {
                  return vmDeviceInfoService.isDeviceSubclassOf(deviceChange, 'VirtualDisk');
               });

               var updatedHarddisk = _.filter(deviceChanges, function(deviceSpec) {
                  if (deviceClassLineageService.isClassNameSubclassOf(
                        deviceSpec.device._type, deviceTypeConstants.VIRTUALDISK) && deviceSpec.operation === deviceOperationConstants.EDIT) {
                     //return true;
                     var orig = _.find(originalVDisks, function(origDevice){ return origDevice.key === deviceSpec.device.key; });
                     if(orig.capacityInBytes !== deviceSpec.device.capacityInBytes){
                        return true;
                     }
                  }
                  return false;
               });

               var deletedHarddisk = _.filter(deviceChanges, function(deviceSpec) {
                  if (deviceClassLineageService.isClassNameSubclassOf(
                        deviceSpec.device._type, deviceTypeConstants.VIRTUALDISK) && deviceSpec.operation === deviceOperationConstants.REMOVE) {
                     return true;
                  }
                  return false;
               });

               var disksToUpdate = {};
               _.each(updatedHarddisk, function (deviceSpec) {
                  disksToUpdate[deviceSpec.device.key] = {
                     capacity: deviceSpec.device.capacityInBytes
                  };
               });

               var disksToRemove = [];
               _.each(deletedHarddisk, function (deviceSpec) {
                  disksToRemove.push(deviceSpec.device.key);
               });

               var nics = {};
               var virtualMachineDevices = getVirtualMachineDevices();
               _.each(getUpdatedNetwork(), function (deviceSpec) {
                  var deviceNetwork =
                     virtualMachineDevices.getVirtualDevice(deviceSpec.device.key).network;
                  nics[deviceSpec.device.key] = {
                     network: defaultUriSchemeUtil
                        .getVsphereObjectId(deviceNetwork.network
                           ? deviceNetwork.network.network
                           : deviceNetwork.portgroup)
                  };
               });

               hardwareCustomizationSpec = {
                  cpuUpdate: cpuUpdate,
                  memoryUpdate: memoryUpdate,
                  disksToUpdate: disksToUpdate,
                  disksToRemove: disksToRemove,
                  nics: nics
               };

            }
            return hardwareCustomizationSpec;
         };

         var getUpdatedNetwork = function () {
            var deviceChanges = getVirtualMachineDevices().getDeviceChanges();
            return _.filter(deviceChanges, function(deviceSpec) {
               if (!deviceClassLineageService.isClassNameSubclassOf(
                     deviceSpec.device._type, deviceTypeConstants.VIRTUALDISK) && deviceSpec.operation === deviceOperationConstants.EDIT) {
                  return true;
               }
               return false;
            });
         };

         var getVmRecommendationsSpec = function () {
            return vmParams.recommendationSpec;
         };

         var setVmRecommendationsSpec = function (recommendationSpec) {
            vmParams.recommendationSpec = recommendationSpec;
         };

         var setCustomizeCloneHardware = function (customizeHardware) {
            vmParams.customizeHardware = customizeHardware;
         };

         var getCustomizeCloneHardware = function () {
            return vmParams.customizeHardware;
         };

         /**
          * If a network adapter is reassigned to a different network, remove it's data
          * from the configSpec's deviceChange property and move it to the relocationSpec's deviceChange
          * @param configSpec - the VmConfigSpec
          * @param relocationSpec
          */
         var moveReassignedNetworkAdapters = function(configSpec, relocationSpec) {
            if (!configSpec || _.isEmpty(configSpec.deviceChange)) {
               return;
            }

            relocationSpec.deviceChange = _.isEmpty(relocationSpec.deviceChange)
                  ? []
                  : relocationSpec.deviceChange;

            configSpec.deviceChange = _.filter(configSpec.deviceChange, function(deviceSpec) {
               if (!deviceClassLineageService.isClassNameSubclassOf(
                     deviceSpec.device._type, deviceTypeConstants.ETHERNET)) {
                  return true;
               }
               if (deviceSpec.operation !== "edit") {
                  return true;
               }
               relocationSpec.deviceChange.push(deviceSpec);
               return false;
            });

         };

         var getVmCloneSpec = function () {
            return getRelocationSpecInfoForClone(vmParams.diskFormat).then(function (relocationSpec) {
               return getVirtualDisks().then(function (properties) {
                  var hostRef = null;
                  var computeResourceRef =
                     defaultUriSchemeUtil.getManagedObjectReference(vmParams.computeResourceId);
                  if (computeResourceRef.type === managedEntityConstants.HOST) {
                     hostRef = computeResourceRef;
                  }

                  var resPoolRef = null;
                  if (vmParams.resourcePool.id) {
                     resPoolRef =
                        defaultUriSchemeUtil.getManagedObjectReference(vmParams.resourcePool.id);
                  }

                  var datastoreRef = !storageObjectIsPod() ?
                     getStorageObject().storageRef :
                     null;

                  var vmDevices = getVirtualMachineDevices();

                  var vmCloneSpec = {
                     vm: defaultUriSchemeUtil.getManagedObjectReference(getVmId()),
                     name: getName(),
                     folder: defaultUriSchemeUtil.getManagedObjectReference(getTargetInformation().folderUid),
                     cloneSpec: {
                        '_type': 'com.vmware.vim.binding.vim.vm.CloneSpec',
                        location: {
                           '_type': 'com.vmware.vim.binding.vim.vm.RelocateSpec',
                           host: hostRef,
                           pool: resPoolRef,
                           datastore: datastoreRef,
                           profile: relocationSpec.profile,
                           deviceChange: relocationSpec.deviceChange
                        },
                        template: isTargetATemplate()
                     }
                  };

                  var deviceChanges = [];
                  if (vmDevices) {
                     appendStorageProfileToExistingDisks(
                           vmDevices.existingDevicesOfType(deviceTypeConstants.VIRTUALDISK));
                     deviceChanges = vmDevices.getDeviceChanges();
                  }
                  if (getCustomizeCloneHardware() === true) {
                     var originalConfig = getVmOriginalConfigSpec();
                     var originalCpuFeatureMask = originalConfig.cpuFeatureMask !== null ?
                           originalConfig.cpuFeatureMask : [];
                     var config = getVmConfigSpec();
                     vmCloneSpec.vmSpec = {
                        _type: 'com.vmware.vsphere.client.vm.VmConfigSpec',
                        originalConfig: originalConfig,
                        config: config,
                        deviceChange: deviceChanges,
                        npivWwnOp: config.npivWwnOp,
                        cpuFeatureMask: cpuService.createCpuIdInfoDelta(
                              originalCpuFeatureMask, config.cpuFeatureMask),
                        profile: []
                     };

                     var storageProfile = getSelectedStorageProfile();
                     if (storageProfile && !vmParams.containsSpbmIssues) {
                        vmCloneSpec.vmSpec.profile.push(
                              storageProfileService.makeProfile(
                                    storageProfile.id, getSelectedReplicationGroupInfo()));
                     }

                     if (deviceChanges) {
                        diskProvisioningService.setBackingDatastore(
                           deviceChanges,
                           getStorageObject(),
                           vmParams.storageDiskList);
                     }
                  }

                  moveReassignedNetworkAdapters(vmCloneSpec.vmSpec, relocationSpec);
                  vmCloneSpec.cloneSpec.location.deviceChange = relocationSpec.deviceChange;

                  var cloneOptions = getCloneOptions();
                  if (cloneOptions) {
                     vmCloneSpec.cloneSpec.powerOn = cloneOptions.powerOn;

                     var customizationSpec = getCustomizationSpec();
                     if (cloneOptions.customizeOs && customizationSpec) {
                        vmCloneSpec.cloneSpec.customization = customizationSpec;
                     }
                  }

                  // Only send out relocate information if we have disks relocated on standalone DSs
                  if (relocationSpec && relocationSpec.disk) {
                     _.extend(vmCloneSpec.cloneSpec.location, relocationSpec);
                  }

                  var originalVirtualDisks = properties['virtualDisks'];
                  var filteredOriginalDisks =
                     filterVirtualDisksBeforeMakingThemDiskLocators(originalVirtualDisks);
                  var newVirtualDisks = getNewVirtualDisks(deviceChanges);
                  var filteredNewVirtualDisks =
                     filterVirtualDisksBeforeMakingThemDiskLocators(newVirtualDisks, true);
                  var storageVirtualDisks = getVmDisksStorage()[getVmId()];
                  vmCloneSpec.podSelectionSpec = podSelectionSpecService.createSpecForClone(
                     filteredOriginalDisks,
                     filteredNewVirtualDisks,
                     getStorageObject(),
                     vmParams.diskFormat,
                     vmParams.storageDiskList,
                     storageVirtualDisks);
                  applyPmemRulesOnVirtualDiskBackingForSDRS(vmCloneSpec.podSelectionSpec, vmParams.diskFormat);
                  buildVappProperties(vmCloneSpec.cloneSpec);
                  return vmCloneSpec;
               });
            });
         };

         var buildVappProperties = function(cloneSpec) {
            var vappPropertyDescriptors = getVappPropertyDescriptors();
            if (!_.isEmpty(vappPropertyDescriptors)) {
               var propertySpecs = [];
               _.each(vappPropertyDescriptors, function(descriptor) {
                  var propertySpec = {
                     _type: "com.vmware.vim.binding.vim.vApp.PropertySpec",
                     operation: "edit",
                     info: descriptor
                  };
                  propertySpecs.push(propertySpec);
               }.bind(this));
               if (!cloneSpec.config) {
                  cloneSpec.config = {
                     _type: 'com.vmware.vim.binding.vim.vm.ConfigSpec'
                  };
               }
               cloneSpec.config.vAppConfig = {
                  _type: "com.vmware.vim.binding.vim.vApp.VmConfigSpec",
                     property: propertySpecs
               };
            }

         };

         var hasConfigurableVappProperties = function() {
            var vappPropertyDescriptors = getVappPropertyDescriptors();

            if (_.isEmpty(vappPropertyDescriptors)) {
               return false;
            }
            var hasConfigurable = _.some(vappPropertyDescriptors, function(descriptor) {
               return descriptor.userConfigurable && descriptor.type !== "expression";
            });

            return hasConfigurable;
         };

         var getNewVirtualDisks = function (deviceChanges) {
            if (deviceChanges) {
               var newVirtualDisksChanges = _.filter(deviceChanges, function (deviceChange) {
                  return vmDeviceInfoService.isDeviceSubclassOf(deviceChange.device, "VirtualDisk")
                     && deviceChange.device.key < 0;
               });

               return _.map(newVirtualDisksChanges, function (deviceChange) {
                  return appendStorageProfileToDisk(deviceChange.device);
               });
            }
            return [];
         };

         var isTargetATemplate = function () {
            return _.include([
               creationTypeConstants.CLONE_TEMPLATE_TO_TEMPLATE,
               creationTypeConstants.CLONE_VM_TO_TEMPLATE
            ], getCreationType());
         };

         function queryConfigOptionByPoolItnernal(vmVersion, useCache) {
            if (useCache && _vmConfigOptionByVmVersion &&
               _vmConfigOptionByVmVersion.vmVersion === vmVersion) {
               return _vmConfigOptionByVmVersion.promise;
            }

            _vmConfigOptionByVmVersion = {
               vmVersion: vmVersion
            };

            var hostRef = null;
            var computeResourceRef =
               defaultUriSchemeUtil.getManagedObjectReference(vmParams.computeResourceId);
            if (computeResourceRef.type === managedEntityConstants.HOST) {
               hostRef = computeResourceRef;
            }

            var params = [{
               propertyName: 'vmConfigOptionByPool',
               parameterType: 'com.vmware.vsphere.client.h5.vm.model.provisioning.GuestOsVersionParams',
               parameter: {
                  _type: 'com.vmware.vsphere.client.h5.vm.model.provisioning.GuestOsVersionParams',
                  key: vmVersion,
                  host: hostRef
               }
            }];

            _vmConfigOptionByVmVersion.promise =
               dataService.getProperties(
                  vmParams.resourcePool.id,
                  ['vmConfigOptionByPool'],
                  {
                     propertyParams: params
                  }
               ).then(function (configOption) {
                  return configOption;
               });
            return _vmConfigOptionByVmVersion.promise;
         }

         var queryConfigOptionByPool = function (vmVersion) {
            // get the vm config option from the server always, this function is called
            // whenever the vm compatibility has changed, so we need to re-request the
            // vm config option for the new vm version
            return queryConfigOptionByPoolItnernal(vmVersion, false/*useCache*/);
         };

         var getConfigOptionByPool = function () {
            // get the vm config option from cache if available
            return queryConfigOptionByPoolItnernal(getVmVersion(), true/*useCache*/);
         };

         var getVmConfigEnvironmentForGos = function(gosVersion) {
            getVmConfigEnvironmentForVmVersion(getVmVersion(), gosVersion.id);
         };

         var getVmConfigEnvironmentForVmVersion = function (vmVersion, gosVersion) {
            if (_vmConfigEnvironmentPromise) {
               return _vmConfigEnvironmentPromise;
            }

            var hostRef = null;
            var computeResourceRef =
               defaultUriSchemeUtil.getManagedObjectReference(vmParams.computeResourceId);
            if (computeResourceRef.type === managedEntityConstants.HOST) {
               hostRef = computeResourceRef;
            }

            var gosId = getCreationType() === creationTypeConstants.CREATE_FROM_SCRATCH ?
                  (getGosVersion() ? getGosVersion().id : null) : getGuestOsId();
            gosId = gosId || gosVersion;
            _latestGosId = gosId;

            var params = [{
               propertyName: 'vmConfigEnvironmentPerPool',
               parameterType: 'com.vmware.vsphere.client.h5.vm.model.provisioning.VmConfigEnvironmentPerPoolParams',
               parameter: {
                  _type: 'com.vmware.vsphere.client.h5.vm.model.provisioning.VmConfigEnvironmentPerPoolParams',
                  key: vmVersion,
                  hostRef: hostRef,
                  gosId: gosId
               }
            }];
            _vmConfigEnvironmentPromise =
                  dataService.getProperties(
                        vmParams.resourcePool.id,
                        ['vmConfigEnvironmentPerPool'],
                        {
                           propertyParams: params
                        }
                  ).then(function(vmConfigEnvironment) {
                     vmParams.envBrowserRef =
                           vmConfigEnvironment.vmConfigEnvironmentPerPool.envBrowserRef;
                     return vmConfigEnvironment;
                  });

            return _vmConfigEnvironmentPromise;
         };

         var getVmConfigEnvironment = function () {
            return getVmConfigEnvironmentForVmVersion(getVmVersion());
         };

         var getDiskChanges = function () {
            return filterChangesByType('VirtualDisk');
         };

         var getTpmChanges = function () {
            return filterChangesByType('VirtualTPM');
         };

         var getScsiControllerChanges = function () {
            return filterChangesByType('VirtualSCSIController');
         };

         var filterChangesByType = function (deviceType) {
            return _.filter(getVirtualMachineDevices().getDeviceChanges(), function (deviceChange) {
               return vmDeviceInfoService.isDeviceSubclassOf(deviceChange.device, deviceType);
            });
         };

         var getFilterNetworksObjectId = function () {
            return vmParams.filterNetworksObjectId;
         };

         var setFilterNetworksObjectId = function (objectId) {
            vmParams.filterNetworksObjectId = objectId;
         };

         var getRegisterVmData = function () {
            return vmParams.registerVmData;
         };

         var setRegisterVmData = function (data) {
            vmParams.registerVmData = data;
         };

         var isXvc = function () {
            var sourceVmId = getVmId();
            var targetInformation = getTargetInformation();
            if (!sourceVmId || !targetInformation || !targetInformation.targetUid) {
               return false;
            }

            var sourceVmSeverGuid = defaultUriSchemeUtil
               .getPartsFromVsphereObjectId(sourceVmId).serverGuid;
            var targetSeverGuid = defaultUriSchemeUtil
               .getPartsFromVsphereObjectId(targetInformation.targetUid).serverGuid;
            return sourceVmSeverGuid !== targetSeverGuid;
         };

         function setIsAdvancedStorageMode(isAdvanced) {
            vmParams.isAdvancedStorageMode = isAdvanced;
         }

         function getIsAdvancedStorageMode() {
            return vmParams.isAdvancedStorageMode;
         }

         function getVmDisksStorage() {
            return vmParams.vmDisksStorage;
         }

         function setVmDisksStorage(storageForDisks) {
            vmParams.vmDisksStorage = storageForDisks;
         }

         function getStorageSelectorState() {
            return vmParams.storageSelectorState;
         }

         function setStorageSelectorState(state) {
            vmParams.storageSelectorState = state;
         }

         function resetStorage () {
            vmParams.storageSelectorState = undefined;
         }

         function getStorageSelectorStatePerDisk(diskKey) {
            var disks = getStorageSelectorState().vmStorageConfigInAdvancedMode[0].vmDisks;
            return _.find(disks, function (disk) {
               return disk.key === diskKey;
            });
         }

         function getProvisioningTypePerDisk(diskKey) {
            var diskFormat;
            if (getIsAdvancedStorageMode()) {
               var disk = getStorageSelectorStatePerDisk(diskKey);
               diskFormat = disk.diskFormat;
            } else {
               diskFormat = getDiskFormat();
            }

            return diskFormat ? diskFormat.name : undefined;
         }

         function containsSpbmIssues(value) {
            vmParams.containsSpbmIssues = value;
         }

         function getLibraryItemId() {
            return vmParams.libraryItemId;
         }

         function setLibraryItemId(value) {
            vmParams.libraryItemId = value;
         }

         function getVmtxLibraryItemId() {
            return vmParams.vmtxLibraryItemId;
         }

         function setVmtxLibraryItemId(value) {
            vmParams.vmtxLibraryItemId = value;
         }

         function setVmtxStorageSpecSettings(settings) {
            vmParams.vmtxStorageSpecSettings = settings;
         }

         function getVmtxStorageSpecSettings() {
            return vmParams.vmtxStorageSpecSettings;
         }

         function getCustomizationSpecName() {
            return vmParams.customizationSpecName;
         }

         function setCustomizationSpecName(specName) {
            vmParams.customizationSpecName = specName;
         }

         function getPrivileges() {
            if(_targetPrivilegesPromise) {
               return _targetPrivilegesPromise;
            }

            var targetId = getTargetInformation().folderUid;
            var requiredPrivileges = REQUIRED_PRIVILEGES_CREATE;

            if (getCreationType() !== creationTypeConstants.CREATE_FROM_SCRATCH) {
               requiredPrivileges = REQUIRED_PRIVILEGES_CLONE;
            } else {
               if (isResourcePoolInVapp()) {
                  targetId = getComputeResourceId();
               }
            }

            _targetPrivilegesPromise = authorizationService.checkGrantedPrivileges(
                  targetId, requiredPrivileges).then(function (privileges) {
               var filteredPrivileges = [];
               _.each(privileges, function (isPrivilegeAvailable, index) {
                  if (isPrivilegeAvailable) {
                     filteredPrivileges.push(requiredPrivileges[index]);
                  }
               });

               return filteredPrivileges;
            });

            return _targetPrivilegesPromise;
         }

         function getAdvancedStoragePageData() {
            if (_vmAdvancedStorageDataPromise) {
               return _vmAdvancedStorageDataPromise;
            }

            var vmId = getVmId();
            if (vmId) {
               _vmAdvancedStorageDataPromise = dataService.getProperties(
                     vmId, ['advancedStoragePageData'], { skipLoadingNotification: true });
            }
            return _vmAdvancedStorageDataPromise;
         }

         function getStoragePageData() {
            if (_vmStoragePageDataPromise) {
               return _vmStoragePageDataPromise;
            }

            var targetInformation = getTargetInformation();
            var rootFolder = defaultUriSchemeUtil.getRootFolderFromVsphereObjectId(
                  targetInformation.datacenterUid);
            if (rootFolder) {
               _vmStoragePageDataPromise = dataService.getProperties(
                     rootFolder, ['vmStoragePageData', 'h5DefaultKmipClusterStatus',
                     'grantedPrivileges']).then(function(response) {
                        return {
                           vmStoragePageData: response.vmStoragePageData ?
                              _.extend(response.vmStoragePageData, {
                                 h5DefaultKmipClusterStatus: response.h5DefaultKmipClusterStatus,
                                 hasProfileViewPrivilege: response.grantedPrivileges ?
                                    _.contains(response.grantedPrivileges, 'StorageProfile.View') :
                                    undefined
                           }) : undefined };
               });
            }
            return _vmStoragePageDataPromise;
         }

         function setVappPropertyDescriptors(vappPropertyDescriptors) {
            vmParams.vappPropertyDescriptors = vappPropertyDescriptors;
         }

         function getVappPropertyDescriptors() {
            return vmParams.vappPropertyDescriptors;
         }

         return {
            setCreationType: setCreationType,
            getCreationType: getCreationType,
            setVmId: setVmId,
            getVmId: getVmId,
            setName: setName,
            getName: getName,
            setHasEncryptNewVmPrivilege: setHasEncryptNewVmPrivilege,
            getHasEncryptNewVmPrivilege: getHasEncryptNewVmPrivilege,
            setHasManageEncryptionPolicyPrivilege: setHasManageEncryptionPolicyPrivilege,
            getHasManageEncryptionPolicyPrivilege: getHasManageEncryptionPolicyPrivilege,
            getTemplateName: getTemplateName,
            setTemplateName: setTemplateName,
            setTargetInformation: setTargetInformation,
            getTargetInformation: getTargetInformation,
            setRecreateVm: setRecreateVm,
            getRecreateVm: getRecreateVm,
            setComputeResourceId: setComputeResourceId,
            getComputeResourceId: getComputeResourceId,
            getComputeResourceOwnerId: getComputeResourceOwnerId,
            setComputeResourceOwnerId: setComputeResourceOwnerId,
            setComputeResourceName: setComputeResourceName,
            getComputeResourceName: getComputeResourceName,
            getResourcePool: getResourcePool,
            setResourcePool: setResourcePool,
            isResourcePoolInVapp: isResourcePoolInVapp,
            setIsResourcePoolInVapp: setIsResourcePoolInVapp,
            getVmOriginalConfigSpec: getVmOriginalConfigSpec,
            setVmOriginalConfigSpec: setVmOriginalConfigSpec,
            getSourceVmStorageConfig: getSourceVmStorageConfig,
            setSourceVmStorageConfig: setSourceVmStorageConfig,
            getVmConfigSpec: getVmConfigSpec,
            setVmConfigSpec: setVmConfigSpec,
            getStorageObject: getStorageObject,
            getDisableSdrs: getDisableSdrs,
            setDisableSdrs: setDisableSdrs,
            getVmVersion: getVmVersion,
            setVmVersion: setVmVersion,
            getScheduledVmVersion: getScheduledVmVersion,
            setScheduledVmVersion: setScheduledVmVersion,
            getScheduledData: getScheduledData,
            setScheduledData: setScheduledData,
            getGosFamily: getGosFamily,
            getGosVersion: getGosVersion,
            getGosName: getGosName,
            setGosName: setGosName,
            setGuestOsId: setGuestOsId,
            getGuestOsId: getGuestOsId,
            setScheduledGuestOsId: setScheduledGuestOsId,
            getScheduledGuestOsId: getScheduledGuestOsId,
            setScheduledGuestName: setScheduledGuestName,
            getScheduledGuestName: getScheduledGuestName,
            setGosFamilyAndVersion: setGosFamilyAndVersion,
            setCustomPcName: setCustomPcName,
            setCustomIpv4ForNIC: setCustomIpv4ForNIC,
            setCustomIpv6ForNIC: setCustomIpv6ForNIC,
            setCustomizationSpec: setCustomizationSpec,
            getCustomizationSpec: getCustomizationSpec,
            setCustomizationSpecInfo: setCustomizationSpecInfo,
            getCustomizationSpecInfo: getCustomizationSpecInfo,
            getVirtualMachineDevices: getVirtualMachineDevices,
            setVirtualMachineDevices: setVirtualMachineDevices,
            setCloneOptions: setCloneOptions,
            getCloneOptions: getCloneOptions,
            setSelectedStorageProfile: setSelectedStorageProfile,
            getSelectedStorageProfile: getSelectedStorageProfile,
            getSelectedReplicationGroupInfo: getSelectedReplicationGroupInfo,
            setSelectedReplicationGroupInfo: setSelectedReplicationGroupInfo,
            setSelectedStorageProfileForDisks: setSelectedStorageProfileForDisks,
            getSelectedStorageProfileForDisks: getSelectedStorageProfileForDisks,
            storageObjectIsPod: storageObjectIsPod,
            storageObjectIsStandaloneDs: storageObjectIsStandaloneDs,
            setStorageObjectAndDiskFormat: setStorageObjectAndDiskFormat,
            setStorageObject: setStorageObject,
            getDiskFormat: getDiskFormat,
            getVmCreateSpec: getVmCreateSpec,
            getVmRecommendationsSpec: getVmRecommendationsSpec,
            setVmRecommendationsSpec: setVmRecommendationsSpec,
            getCustomizeCloneHardware: getCustomizeCloneHardware,
            setCustomizeCloneHardware: setCustomizeCloneHardware,
            getVmCloneSpec: getVmCloneSpec,
            getVmConfigEnvironmentForVmVersion: getVmConfigEnvironmentForVmVersion,
            getVmConfigEnvironment: getVmConfigEnvironment,
            getConfigOptionByPool: getConfigOptionByPool,
            queryConfigOptionByPool: queryConfigOptionByPool,
            setStorageForDisk: setStorageForDisk,
            getStorageDiskList: getStorageDiskList,
            getStorageForDisk: getStorageForDisk,
            getStorageNameForDisk: getStorageNameForDisk,
            getDiskChanges: getDiskChanges,
            getScsiControllerChanges: getScsiControllerChanges,
            getTpmChanges: getTpmChanges,
            getStorageNameAndIdForDisk: getStorageNameAndIdForDisk,
            getFilterNetworksObjectId: getFilterNetworksObjectId,
            setFilterNetworksObjectId: setFilterNetworksObjectId,
            getRegisterVmData: getRegisterVmData,
            setRegisterVmData: setRegisterVmData,
            isXvc: isXvc,
            setIsAdvancedStorageMode: setIsAdvancedStorageMode,
            getIsAdvancedStorageMode: getIsAdvancedStorageMode,
            getVmDisksStorage: getVmDisksStorage,
            setVmDisksStorage: setVmDisksStorage,
            getStorageSelectorState: getStorageSelectorState,
            setStorageSelectorState: setStorageSelectorState,
            resetStorage: resetStorage,
            getStorageSelectorStatePerDisk: getStorageSelectorStatePerDisk,
            getProvisioningTypePerDisk: getProvisioningTypePerDisk,
            containsSpbmIssues: containsSpbmIssues,
            shouldRetrieveGosDescriptor: shouldRetrieveGosDescriptor,
            retrieveGosDescriptor: retrieveGosDescriptor,
            getVmConfigEnvironmentForGos: getVmConfigEnvironmentForGos,
            getPrivileges: getPrivileges,
            getAdvancedStoragePageData: getAdvancedStoragePageData,
            getStoragePageData: getStoragePageData,
            setVbsEnabled: setVbsEnabled,
            getVbsEnabled: getVbsEnabled,
            setVbsSupported: setVbsSupported,
            getVbsSupported: getVbsSupported,
            getDeployVmtxSpec: getDeployVmtxSpec,
            getLibraryItemId: getLibraryItemId,
            setLibraryItemId: setLibraryItemId,
            getVmtxLibraryItemId: getVmtxLibraryItemId,
            setVmtxLibraryItemId: setVmtxLibraryItemId,
            setVmtxStorageSpecSettings: setVmtxStorageSpecSettings,
            getVmtxStorageSpecSettings: getVmtxStorageSpecSettings,
            setCustomizationSpecName: setCustomizationSpecName,
            getCustomizationSpecName: getCustomizationSpecName,
            getUpdatedNetwork: getUpdatedNetwork,
            setVappPropertyDescriptors: setVappPropertyDescriptors,
            getVappPropertyDescriptors: getVappPropertyDescriptors,
            hasConfigurableVappProperties: hasConfigurableVappProperties
         };
      };
      return VirtualMachineSpecBuilder;
   }]);
