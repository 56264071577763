/* Copyright 2018 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace h5_client {
   /**
    * Implements some stock expressions that can be used to construct more complex
    * validation expressions.
    */
   export class DragAndDropStockExpressionsService {
      public static $inject: string[] = [
         "defaultUriSchemeUtil",
         "managedEntityConstants"
      ];

      private readonly FAILED_STATUS: string = "__FAILED__";
      private readonly NOT_FOUND_STATUS: string = "__NOT_FOUND__";

      private readonly DC_FOLDER_VALUE: string = "group-d";
      private readonly HOST_FOLDER_VALUE: string = "group-h";
      private readonly VM_FOLDER_VALUE: string = "group-v";

      public constructor(
            private defaultUriSchemeUtil: any,
            private managedEntityConstants: any,
      ) {
      }

      public isTargetAccessible(dropTargetData: any): boolean {
         // spriteCssClass comes form the data object maintained by the tree,
         // and primaryIconId comes from the data kept in the object navigator
         const objectIconId: string = dropTargetData.spriteCssClass ?
               dropTargetData.spriteCssClass : dropTargetData.primaryIconId;
         switch (objectIconId) {
            case "vsphere-icon-host-maintenance":
            case "vsphere-icon-host-disconnected":
            case "vsphere-icon-datastore-inaccessible":
            case "vsphere-icon-datastore-error":
            case "vsphere-icon-datastore-maintenance":
               return false;
            default:
               return true;
         }
      }

      public isVmFolder(folderId: string): boolean {
         const folderRef: any =
               this.defaultUriSchemeUtil.getManagedObjectReference(folderId);

         const folderType: string = folderRef.type;
         const folderValue: string = folderRef.value;

         return (folderType === this.managedEntityConstants.FOLDER &&
               folderValue.indexOf(this.VM_FOLDER_VALUE) !== -1);
      }

      public isDatacenterFolder(folderId: string): boolean {
         const folderRef: any =
               this.defaultUriSchemeUtil.getManagedObjectReference(folderId);

         const folderType: string = folderRef.type;
         const folderValue: string = folderRef.value;

         return (folderType === this.managedEntityConstants.FOLDER &&
               folderValue.indexOf(this.DC_FOLDER_VALUE) !== -1);
      }

      public isHostFolder(folderId: string): boolean {
         const folderRef: any =
               this.defaultUriSchemeUtil.getManagedObjectReference(folderId);
         const folderType: string = folderRef.type;
         const folderValue: string = folderRef.value;

         return (folderType === this.managedEntityConstants.FOLDER
               && folderValue.indexOf(this.HOST_FOLDER_VALUE) !== -1);
      }

      public isWithinSameDatacenter(dropTargetData: any, dragObjectsData: any): boolean {
         const result = this.hasCommonParent(
               dropTargetData, dragObjectsData, "Datacenter");

         if (result === undefined) {
            return true;
         }

         return result;
      }

      public isVmFolderWithinSameDatacenter(
            dropTargetData: any, dragObjectsData: any): boolean {
         if (!this.isVmFolder(dropTargetData.id)) {
            return false;
         }

         return this.isWithinSameDatacenter(dropTargetData, dragObjectsData);
      }

      /**
       * Checks if the direct parent of some of the drag objects is the drop target
       * or if the direct parent of the drop target is in the drag objects
       *
       * @param dropTargetData
       * @param dragObjectsData
       * @returns {boolean}
       */
      public hasDirectParent(dropTargetData: any, dragObjectsData: any): boolean {
         if (!dropTargetData || !dragObjectsData) {
            return false;
         }

         const dragParentIds = _.map(dragObjectsData, (dragObjectData: any) => {
            return this.getDirectParent(dragObjectData);
         });
         if (_.contains(dragParentIds, this.FAILED_STATUS)) {
            return false;
         }
         const targetIsParent = _.some(dragParentIds, (dragParentId: any) => {
            return dropTargetData.id === dragParentId;
         });

         const targetParentId = this.getDirectParent(dropTargetData);
         if (targetParentId === this.FAILED_STATUS) {
            return false;
         }
         const dragObjIsParent = _.some(dragObjectsData, (dragObjectData: any) => {
            return dragObjectData.id === targetParentId;
         });

         return targetIsParent || dragObjIsParent;
      }

      /**
       * Checks if all the drag objects and the drop target have common first parent
       * of the selected type
       *
       * @param dropTargetData
       * @param dragObjectsData
       * @param parentType
       * @returns {any}
       * returns true if parent is found
       * returns false if parent is not found but the whole chain is traversed
       * returns undefined if parent is not found but not the whole chain is traversed
       * due to some error
       */
      public hasCommonParent(
            dropTargetData: any,
            dragObjectsData: any,
            parentType: string): boolean | undefined {
         const targetParentId: string =
               this.getFirstParentOfType(dropTargetData, parentType);
         switch (targetParentId) {
            case this.FAILED_STATUS:
               return undefined;
            case this.NOT_FOUND_STATUS:
               return false;
            default:
               break;
         }

         const dragParentIds = _.map(dragObjectsData, (dragObjectData: any) => {
            return this.getFirstParentOfType(dragObjectData, parentType);
         });
         if (_.contains(dragParentIds, this.FAILED_STATUS)) {
            return undefined;
         }

         const isSameParent: boolean = _.every(dragParentIds, (dragParentId: string) => {
            return dragParentId === targetParentId;
         });
         return isSameParent;
      }

      public isWithinSameClusterOrHost(
            dropTargetData: any, dragObjectsData: any): boolean {

         const sameCluster = this.hasCommonParent(dropTargetData,
               dragObjectsData, "ClusterComputeResource");
         if (sameCluster) {
            return true;
         }

         const sameHost = this.hasCommonParent(dropTargetData,
               dragObjectsData, "HostSystem");
         if ((sameHost === undefined) || (sameCluster === undefined)) {
            return true;
         }

         return sameHost;
      }

      public isWithinSameStandaloneHost(
            dropTargetData: any, dragObjectsData: any): boolean {
         if (!this.isStandaloneHost(dropTargetData)) {
            return false;
         }

         return this.isWithinSameClusterOrHost(dropTargetData, dragObjectsData);
      }

      public isInVmFolderWithinSameDatacenter(dropTargetData: any, dragObjectsData: any): boolean {
         const dragParentIds = _.map(dragObjectsData, (dragObjectData: any): string => {
            return this.getDirectParent(dragObjectData);
         });

         // If dragged items are from outside of a tree, e.g. from a grid, they
         // do not possess parents so their status is failed. That's the only way
         // to detect if dragged objects are not from a tree.
         if (_.contains(dragParentIds, this.FAILED_STATUS)) {
            return true;
         }

         return <boolean>this.hasCommonParent(dropTargetData, dragObjectsData, "Datacenter")
               && _.every(dragParentIds, (dragParentId: string): boolean => {
                  return this.isVmFolder(dragParentId);
               });
      }

      /**
       * Checks if the dropTarget is a standalone host or not
       *
       * @returns 'false' only if the parent hierarchy can be traversed and a cluster
       *    is found in it. Otherwise return true. That is - return 'false' only
       *    if we are certain it IS a part of a cluster. If we cannot decide -
       *    consider it standalone. The latter logic is applicable when the
       *    dropTargetData comes from the ObjectNavigator which does not provide
       *    hierarchical information.
       */
      private isStandaloneHost(dropTargetData: any): boolean {
         const targetParentId: string =
               this.getFirstParentOfType(dropTargetData, "ClusterComputeResource");

         switch (targetParentId) {
            case this.FAILED_STATUS:
               // Could not traverse the parent hierarchy.
               // Consider this a standalone host.
               return true;
            case this.NOT_FOUND_STATUS:
               // No cluster parent - this is a standalone host for sure
               return true;
            default:
               // Must be a valid cluster id - so this is NOT a standalone host
               return false;
         }
      }

      private getFirstParentOfType(targetData: any, parentType: string): string {
         if (!targetData) {
            return this.FAILED_STATUS;
         }

         while (targetData) {
            const entityType = this.defaultUriSchemeUtil.getEntityType(targetData.id);
            if (!entityType) {
               return this.FAILED_STATUS;
            }

            if (entityType === parentType) {
               return targetData.id;
            }

            if (!targetData.parentNode) {
               return this.FAILED_STATUS;
            }

            targetData = targetData.parentNode();
         }

         return this.NOT_FOUND_STATUS;
      }

      private getDirectParent(targetData: any): string {
         if (!targetData || !targetData.parentNode) {
            return this.FAILED_STATUS;
         }
         const parent = targetData.parentNode();
         if (!parent || !parent.id) {
            return this.FAILED_STATUS;
         }

         return parent.id;
      }

   }

   angular.module("com.vmware.vsphere.client.commonModule")
         .service("dragAndDropStockExpressionsService",
               DragAndDropStockExpressionsService);
}
