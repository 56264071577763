/* Copyright 2018 VMware, Inc. All rights reserved. -- VMware Confidential */

module host_ui {

   export class HostWizardScheduleTaskPageModel {
      /**
       * [page specific]
       * Keeps the info about if the page is validating at the moment or no.
       * Used to determine if the spinner in the compatibility result is displayed
       * or no
       * Used to determine if the next button of the wizard is displayed or no
       */
      public isValidating: boolean;
      /**
       * Schedule Task Form Data
       */
      public mutationSpec: any;

      /**
       * [page specific]
       *
       * Keeps the schedule task state between different visualization of the page
       * during the wizard lifetime.
       */
      public isScheduledTaskFlow: boolean;

      public validatePage(): Object {
         return {};
      }

      public submitPage(): any {
         return {
            invalidateNextPages: false
         };
      }

      public resetForm(): void{}

   } // class

   let hostWizardScheduleTaskPageModelService: Function = function () {
      return HostWizardScheduleTaskPageModel;
   };

   angular.module("com.vmware.vsphere.client.host")
         .service("hostWizardScheduleTaskPageModel",
            hostWizardScheduleTaskPageModelService);
} // module
