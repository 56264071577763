/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace dvs_ui {

   export class DvpgDetailsComponent {

      public bindings: any;
      public controller: any;
      public templateUrl: string;

      constructor() {
         this.bindings = {};
         this.controller = DvpgDetailsController;
         this.templateUrl = "dvs-ui/resources/dvs/dvpg/summary/components/dvpgDetailsComponentTemplate.html";
      }
   }

   class DvpgDetailsController {

      static $inject = [
         "i18nService",
         "$rootScope",
         "dataService",
         "defaultUriSchemeUtil",
         "navigation"
      ];

      static SERVER_OBJECT_VIEWS_EXTENSION: string =
            "vsphere.core.inventory.serverObjectViewsExtension";

      static NRPS_FOR_DVS_RELATION: string =
            "nrpsForDvs";

      private _data: any; /* DvPortgroupDetailsPortletData */

      public loadingData: boolean;

      public liveRefreshProperties: string[];

      public dvpgUri: string;

      constructor(public i18nService: any,
                  private $rootScope: any,
                  private dataService: any,
                  private defaultUriSchemeUtil: any,
                  private navigation: any) {
      }

      protected $onInit() {
         this.requestData();
      }

      public refresh(): void {
         this.requestData();
      }

      public get switchName(): string {
         return this._data && this._data.switchName;
      }

      public get switchIconId(): string {
         return this._data && this._data.switchIconId;
      }

      public get numHosts(): number {
         return this._data && this._data.numHosts;
      }

      public get numVms(): number {
         return this._data && this._data.numVms;
      }

      public get ipPoolName(): string {
         return this._data &&
               (this._data.ipPoolName || this.i18nService.getString("NetworkUi", "NetworkUtil.noData"));
      }

      public get nrpName(): string {
         return this._data &&
               (this._data.nrpName || this.i18nService.getString("NetworkUi", "NetworkUtil.noData"));
      }

      public get isUplink(): string {
         return this._data && this._data.isUplink;
      }

      public get showNrpIcon(): boolean {
         return this._data && this._data.nrpName;
      }

      public get showDvs(): boolean {
         return this._data && this._data.showDvsData;
      }

      public get showNrp(): boolean {
         return this._data && this._data.showNrp;
      }

      public navigateToDvs(): void {
         let dvsUrn: string = this.defaultUriSchemeUtil.getVsphereObjectId(this._data.switchMor);
         this.navigation.navigateToViewAndObject(
               DvpgDetailsController.SERVER_OBJECT_VIEWS_EXTENSION, dvsUrn);
      }

      public navigateToNrp(): void {
         let nrpUrn: string = this.defaultUriSchemeUtil.getVsphereObjectId(
               this._data.nrpMor).replace("vmomi", "nrp");

         let dvsUrn: string =
               this.defaultUriSchemeUtil.getVsphereObjectId(this._data.switchMor);

         this.navigation.navigateToRelatedObject(
               dvsUrn,
               DvpgDetailsController.NRPS_FOR_DVS_RELATION,
               nrpUrn);
      }

      private requestData(): void {
         let self: DvpgDetailsController = this;
         this.dvpgUri = this.$rootScope._route.objectId;

         self.loadingData = true;
         self.dataService.getProperties(this.dvpgUri, ["dvpg:dvPortgroupDetails"])
               .then(function (res: any) {
                  self._data = res["dvpg:dvPortgroupDetails"];
                  self.loadingData = false;
               });

         self.liveRefreshProperties = [
            "vm", "summary", "host"
         ];
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .component("dvpgDetails", new DvpgDetailsComponent());
}
