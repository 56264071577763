namespace storage_ui {

   import IComponentController = angular.IComponentController;

   export class StorageDevicesMasterViewController implements IComponentController {

      public static $inject = ["i18nService", "$q"];

      public readonly detailsViewData: any;

      private readonly tabs: any[] = [{
         name: this.i18nService.getString("StorageUi", "storage.devices.propertiesTab.header"),
         templateUrl: "storage-ui/resources/storage/views/host/devices/GeneralStorageDeviceDetailsView.html",
         uid: "storage.devices.propertiesTab"
      }, {
         name: this.i18nService.getString("StorageUi", "storage.devices.pathsTab.header"),
         templateUrl: "storage-ui/resources/storage/views/host/devices/StorageDevicesPathsDetailsView.html",
         uid: "storage.devices.pathsTab"
      }, {
         name: this.i18nService.getString("StorageUi", "storage.devices.properties.partitionDetailsHeader"),
         templateUrl: "storage-ui/resources/storage/views/host/devices/StorageDevicesPartitionDetailsView.html",
         uid: "storage.devices.partitionDetails",
         metadata: {
            relevantFor: "isScsiDisk"
         }
      }];

      constructor(private i18nService: any, private $q: ng.IQService) {
         this.detailsViewData = {
            tabsPromise: this.$q.when(this.tabs)
         };
      }

   }

   angular.module("com.vmware.vsphere.client.storage")
         .controller("StorageDevicesMasterViewController",
               StorageDevicesMasterViewController);
}
