namespace h5_vm {
   import IQService = angular.IQService;
   import VmHardwareBaseService = h5_vm.VmHardwareBaseService;
   import VmConfigEnvironmentData = com.vmware.vsphere.client.h5.vm.data.VmConfigEnvironmentData;

   export class VmHardwareCreateService {
      static $inject: string[] = [
         "vmFactoryService",
         "authorizationService",
         "vmHardwareVersionService",
         "vmNetworkService",
         "i18nService",
         "$q"
      ];

      constructor(private vmFactoryService: any,
            private authorizationService: any,
            private vmHardwareVersionService: any,
            private vmNetworkService: any,
            private i18nService: any,
            private $q: any) {

      }

      getVmNetworkInfo(networkProviderId: string, vmNetworkDevices: any[]): ng.IPromise<any> {
         return this.vmNetworkService.getVmNetworkInfo(
               networkProviderId,
               vmNetworkDevices
         );
      }

      getVmNetworkInfoForCreate(vmParams: any, virtualMachineDevices: any): ng.IPromise<any> {
         let networkProviderId: string = vmParams.getComputeResourceId();
         let vmNetworkDevices: any =
               virtualMachineDevices.newDevicesOfType(VmHardwareBaseService.VIRTUAL_ETHERNET_CARD);
         return this.getVmNetworkInfo(networkProviderId, vmNetworkDevices);
      }

      getAndFormatData(vmParams: any, wizardViewData: any): ng.IPromise<any> {
         return vmParams.getVmConfigEnvironment().then((poolProperties: any) => {
            let vmConfigEnvironment: VmConfigEnvironmentData = poolProperties.vmConfigEnvironmentPerPool;
            let virtualMachineDevices: any = vmParams.getVirtualMachineDevices();
            const scheduledTaskData = vmParams.getScheduledData();
            const isScheduledTask: boolean = !_.isEmpty(scheduledTaskData) && scheduledTaskData.useScheduledTaskData;
            if (vmParams.getRecreateVm() || !vmParams.getVmConfigSpec()) {
               virtualMachineDevices = this.vmFactoryService.buildVirtualDevicesForCreate(
                     vmConfigEnvironment, isScheduledTask);
            }
            let promises: any = {
               networkInfoForCreate: this.getVmNetworkInfoForCreate(vmParams, virtualMachineDevices),
               folderRequiredPrivileges: vmParams.getPrivileges(),
               retrieveGosDescriptor: this.$q.when()
            };

            if (vmParams.shouldRetrieveGosDescriptor()) {
               promises.retrieveGosDescriptor = vmParams.retrieveGosDescriptor();
            }

            return this.$q.all(promises).then((result: { networkInfoForCreate: any, folderRequiredPrivileges: any, retrieveGosDescriptor: any }) => {
               if (!_.isEmpty(result.retrieveGosDescriptor)) {
                  vmConfigEnvironment.configOption.guestOSDescriptor[0] =
                        result.retrieveGosDescriptor.vmConfigGuestOsDescriptor;
               }

               let grantedFolderPrivileges: any = result.folderRequiredPrivileges;
               if (vmParams.getRecreateVm() || !vmParams.getVmConfigSpec()) {
                  let vmData: any = this.vmFactoryService.buildNewVM(vmConfigEnvironment,
                        vmParams, virtualMachineDevices, grantedFolderPrivileges);
                  let vmConfigSpec: any = vmData.vmConfigSpec;
                  vmParams.setVirtualMachineDevices(vmData.virtualMachineDevices);
                  vmParams.setVmConfigSpec(vmConfigSpec);
                  vmParams.setRecreateVm(false);

                  // Still need to assign resourcePool after fetching environment,
                  // see CustomizeHardwareProvisioningPageMediator#onEnvironmentLoaded in Flex
                  vmConfigEnvironment.configTarget.resourcePool = vmParams.getResourcePool().runtime;
               }

               let vmConfigSpecConfig: any = vmParams.getVmConfigSpec().config;
               let hardwareConfigData: any = {
                  originalConfig: angular.copy(vmConfigSpecConfig),
                  selectedStorageProfile: vmParams.getSelectedStorageProfile(),
                  selectedReplicationGroupInfo: vmParams.getSelectedReplicationGroupInfo(),
                  storageProfiles: wizardViewData.getStorageProfiles(),
                  vmVersion: this.i18nService.getString(
                        "VmUi", "VmHardware.HWVersion.vmVersionLabel",
                        this.vmHardwareVersionService.getLongText(vmConfigSpecConfig.version)
                  ),
                  vmConfigContext: {
                     environment: vmConfigEnvironment,
                     config: vmConfigSpecConfig,
                     privileges: grantedFolderPrivileges
                  },
                  guestOsFamilyKey: poolProperties.guestOsFamilyKey,
                  virtualMachineDevices: vmParams.getVirtualMachineDevices(),
                  filterNetworksObjId: vmParams.getFilterNetworksObjectId(),
                  networkProviderId: result.networkInfoForCreate.networkProviderId,
                  availableNetworks: result.networkInfoForCreate.availableNetworks
               };

               return hardwareConfigData;
            });
         });
      }

   }

   angular.module("com.vmware.vsphere.client.vm")
         .service("vmHardwareCreateService", VmHardwareCreateService);
}

