module cluster_ui {

   import IComponentController = angular.IComponentController;

   export class MonitorResourceAllocationViewController implements IComponentController {

      public objectsToWatchIds: Array<string>;
      public liveRefreshProperties: string[];
      public gridOptions: any = {};
      public reservationTitle: string;
      private reservationType: string = "cpu";
      private objectId: string;

      public static $inject = ["$scope", "dataService", "i18nService",
         "vxPropertyViewService", "vuiConstants", "columnRenderersRegistry",
         "defaultUriSchemeUtil", "resourceAllocationService", "$q",
         "capacityFormatterService"];

      constructor(private $scope: any, private dataService: any, private i18nService: any,
                  private vxPropertyViewService: any, private vuiConstants: any,
                  private columnRenderersRegistry: any, private defaultUriSchemeUtil: any,
                  private resourceAllocationService: ResourceAllocationService,
                  private $q: any, private capacityFormatterService: any) {
         this.objectId = $scope._route ? $scope._route.objectId : null;
         let liveRefreshProperties =
               this.$scope._view.contentSpec.metadata.liveRefreshProperties;
         if (liveRefreshProperties) {
            this.liveRefreshProperties = liveRefreshProperties
                  .split(',')
                  .map((prop: string): string => prop.trim());
         }
         $scope.loadingData = false;
         $scope.groupedMeters = [];
         $scope.maxCapacityValue = "";
         $scope.reservationData = {};

         this.reservationType = this.resourceAllocationService.getReservationTypeByModel(
               this.$scope._view.contentSpec.metadata.modelClass);

         this.reservationTitle = (this.reservationType === "cpu") ?
               this.i18nService.getString("ClusterUi", "resourceReservation.cpuTitle") :
               this.i18nService.getString("ClusterUi", "resourceReservation.memoryTitle");

         this.gridOptions = this.getGridOptions();
         this.getData();
      }

      public getData(): void {
         if (this.$scope.loadingData) {
            return;
         }

         this.$scope.loadingData = true;
         this.dataService.getData(this.objectId,
               this.$scope._view.contentSpec.metadata.modelClass)
               .then((data: any): void => {
                  this.$scope.groupedMeters = this.getGroupedMeters(data);
                  this.$scope.maxCapacityValue =
                        this.resourceAllocationService.getMaxCapacityValue(data);
                  this.$scope.reservationData = this.getReservationData(data);

                  data.reservationUsers = _.map(data.reservationUsers, (currentUser: any) => {
                     if (!currentUser.sharesInfo) {
                        currentUser.sharesInfo = {
                           level: "",
                           shares: 0
                        };
                     }
                     return currentUser;
                  });

                  this.gridOptions.data = data.reservationUsers;
                  if (data && data.resourcePool) {
                     this.objectsToWatchIds =
                           [this.objectId,
                              this.defaultUriSchemeUtil
                                    .getVsphereObjectId(data.resourcePool.provider)];
                  }
                  this.$scope.loadingData = false;
               })
               .catch((error: any) => {
                  this.$scope.loadingData = false;
                  this.$q.reject(error);
               });
      }

      private getReservationData(data: any): any {
         let builder: any = this.vxPropertyViewService.createPropertyViewBuilder();
         let sectionBuilder: any = builder
               .category("")
               .section("");

         if (data.totalCapacityFormatted !== undefined) {
            //available only to cluster
            sectionBuilder.propertyBuilder()
                  .keyBuilder()
                  .text(this.i18nService.getString("ClusterUi", "capacity.clusterTotalCapacity"))
                  .exit()
                  .valueBuilder()
                  .text(data.totalCapacityFormatted);
         }
         if (data.hostTotalCapacityFormatted !== undefined) {
            //available only to host
            sectionBuilder.propertyBuilder()
                  .keyBuilder()
                  .text(this.i18nService.getString("ClusterUi", "capacity.hostTotalCapacity"))
                  .exit()
                  .valueBuilder()
                  .text(data.hostTotalCapacityFormatted);
         }
         if (data.reservationCapacityFormatted !== undefined) {
            //available only to cluster and host
            sectionBuilder.propertyBuilder()
                  .keyBuilder()
                  .text(this.i18nService.getString("ClusterUi", "capacity.Total"))
                  .exit()
                  .valueBuilder()
                  .text(data.reservationCapacityFormatted);
         }
         if (data.rpReservationCapacityFormatted !== undefined) {
            //available only to resource pool and vApp
            sectionBuilder.propertyBuilder()
                  .keyBuilder()
                  .text(this.i18nService.getString("ClusterUi", "capacity.rpTotalCapacity"))
                  .exit()
                  .valueBuilder()
                  .text(data.rpReservationCapacityFormatted);
         }
         if (data.configuredReservationFormatted !== undefined) {
            //available only to resource pool and vApp
            sectionBuilder.propertyBuilder()
                  .keyBuilder()
                  .text(this.i18nService.getString("ClusterUi", "reservation.Configured"))
                  .exit()
                  .valueBuilder()
                  .text(data.configuredReservationFormatted);
         }
         sectionBuilder.propertyBuilder()
               .keyBuilder()
               .text(this.i18nService.getString("ClusterUi", "capacity.Reserved"))
               .icon("vx-property-view-key-legend-icon monitor-resource-reservation-grid-icon-color-light-blue")
               .exit()
               .valueBuilder()
               .text(data.reservedCapacityFormatted);
         sectionBuilder.propertyBuilder()
               .keyBuilder()
               .text(this.i18nService.getString("ClusterUi", "capacity.Available"))
               .icon("vx-property-view-key-legend-icon monitor-resource-reservation-grid-icon-color-white")
               .exit()
               .valueBuilder()
               .text(data.availableCapacityFormatted);
         if (data.expandableReservation !== undefined) {
            //available only to resource pool and vApp
            sectionBuilder.propertyBuilder()
                  .keyBuilder()
                  .text(this.i18nService.getString("ClusterUi", "reservationType"))
                  .exit()
                  .valueBuilder()
                  .text(data.expandableReservation ?
                        this.i18nService.getString("ClusterUi", "reservationType.expandable") :
                        this.i18nService.getString("ClusterUi", "reservationType.fixed"));
         }

         return builder.build();
      }

      private getGroupedMeters(data: any): Object[] {
         return [{
            colorClass: "monitor-resource-reservation-icon-color-light-blue",
            value: data.activePercentage,
            label: this.capacityFormatterService.format(
                  data.reservedCapacityFormatted,
                  this.resourceAllocationService.getMaxCapacityValue(data),
                  data.activePercentage)
         }, {
            colorClass: "monitor-resource-reservation-icon-color-light-gray",
            value: 100 - data.activePercentage,
            label: this.capacityFormatterService.format(
                  data.availableCapacityFormatted,
                  this.resourceAllocationService.getMaxCapacityValue(data),
                  100 - data.activePercentage)
         }];
      }

      private getGridOptions(): Object {
         return {
            searchable: false,
            resizable: true,
            sortOrder: [{
               field: "name",
               dir: "asc"
            }],
            data: [],
            selectionMode: this.vuiConstants.grid.selectionMode.NONE,
            columnDefs: this.getColumnDefs(),
            height: "100%",
            pageConfig: {
               hidePager: true
            }
         };
      }

      private getColumnDefs(): Object[] {
         return [
            {
               displayName: this.i18nService.getString("ClusterUi", "nameColumnHeader"),
               field: "name",
               type: "string",
               template: (data: any): string => {
                  let objData = data || {};
                  let renderer: any = this.columnRenderersRegistry.getColumnRenderer("object-name");

                  objData.uid = this.defaultUriSchemeUtil.getVsphereObjectId(objData.provider);

                  return renderer(["uid", "primaryIconId", "name", "labelIds"], objData);
               }
            }, {
               displayName: this.i18nService.getString("ClusterUi",
                     "reservationColumnHeader." + this.reservationType),
               //possible properties reservationColumnHeader.cpu or reservationColumnHeader.memory
               field: "reservation",
               type: "number",
               template: "<span title=\"#:reservation#\">#:reservation#</span>"
            }, {
               displayName: this.i18nService.getString("ClusterUi",
                     "limitColumnHeader." + this.reservationType),
               //possible properties limitColumnHeader.cpu or limitColumnHeader.memory
               field: "limit",
               type: "number",
               template: (data: any): string => {
                  let objData: any = data || {};
                  return objData.limit !== -1 ? objData.limit :
                        this.i18nService.getString("ClusterUi", "limit.unlimited");
               },
               sortable: this.resourceAllocationService.sortReservationUsersLimit
            }, {
               displayName: this.i18nService.getString("ClusterUi", "reservationTypeColumnHeader"),
               field: "expandableReservation",
               type: "boolean",
               template: (data: any): string => {
                  let objData: any = data || {};
                  return objData.expandableReservation ?
                        this.i18nService.getString("ClusterUi", "reservationType.expandable") :
                        this.i18nService.getString("ClusterUi", "reservationType.fixed");
               }
            }, {
               displayName: this.i18nService.getString("ClusterUi", "storage.shareLevelColumn"),
               field: "sharesInfo.level",
               type: "string",
               sortable: this.resourceAllocationService.sortSharesLevels,
               template: (data: any) => {
                  let result: string = "";
                  if (data.sharesInfo.level) {
                     let level: string = data.sharesInfo.level;
                     result = this.resourceAllocationService.getSharesValueTextByLevel(level);
                  }
                  return result;
               }
            }, {
               displayName: this.i18nService.getString("ClusterUi", "storage.sharesValueColumn"),
               field: "sharesInfo.shares",
               type: "number",
               sortable: true,
               template: (dataItem: any) => {
                  // this is done in order to display the 0 value
                  return dataItem.sharesInfo.shares.toString();
               }
            }, {
               displayName: this.i18nService.getString("ClusterUi", "cpuMemAllocation.sharesPercentColumn"),
               field: "sharesInfoValuePercentFormatted",
               type: "string",
               sortable: true
            }
         ];
      }
   }

   angular.module("com.vmware.vsphere.client.cluster")
         .controller("MonitorResourceAllocationViewController",
               MonitorResourceAllocationViewController);
}
