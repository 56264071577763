/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
(function() {
   'use strict';

   angular.module('com.vmware.vsphere.client.storage').directive('datastorePartitionConfig', datastorePartitionConfig);

   datastorePartitionConfig.$inject = ['datastoreConstants', 'i18nService'];

   function datastorePartitionConfig (datastoreConstants, i18nService) {
      var directive = {
         replace: true,
         scope: {
            labels: '<',
            selectedOption: '=',
            onSelectedOptionChanged: '&',
            options: '=',
            diskPartitionInfo: '=',
            dsInfo: '<?',
            blockSizeOptions: '<?',
            configurationError: '=',
            manager: '<?'
         },
         templateUrl: 'storage-ui/resources/storage/directives/partitionConfig/datastorePartitionConfig.html',
         link: link
      };
      var bindOnSelectionChanged;
      var cachedValue = new CachedValue();
      return directive;

      function link (scope, element) {
         bindOnSelectionChanged = !!scope.onSelectedOptionChanged;
         scope.values = {};
         populateDefaultOption();
         initSelection();
         scope.$watch('selectedOption',
            function(option) {
               setMaxSize(option);
               setSize(option);
            });

         function setMaxSize (option) {
            if (option && !option.isSelectConfigurationOption) {
               var maxSizeInGb = option.maxDatastoreSize / datastoreConstants.BYTES_TO_GB;
               // Round maxSize to two decimal places.
               scope.maxSize = Math.round(maxSizeInGb * 100) / 100;
            } else {
               scope.maxSize = undefined;
            }
         }

         function setSize (option) {
            if (option && option.sizeToAdd){
               scope.size = option.sizeToAdd;
            } else {
               scope.size = scope.maxSize;
            }
            if (cachedValue.isSame(scope.size)) {
               onSizeChanged(scope.size);
            }
         }

         scope.onSliderSizeChanged = function(sliderSize) {
            scope.size = sliderSize;
         };

         scope.$watch('size', onSizeChanged);

         function onSizeChanged(newValue) {
            // newValue becomes null when the number from the number input field is
            // deleted. In this case we do *not* want to set the size to the max one.
            if (newValue > scope.maxSize || newValue === undefined) {
               scope.size = scope.maxSize;
            }

            // update slider
            scope.sliderSize = Math.round(scope.size);

            scope.selectedOption.sizeToAdd = scope.size;
            if (bindOnSelectionChanged) {
               scope.onSelectedOptionChanged({
                     selectedOption: scope.selectedOption
                  }
               );
            }
         }

         function populateDefaultOption () {
            var items = scope.options.slice();
            items.splice(0, 0, {
               //adding dummy <select configuration> option
               optionTitle: i18nService.getString('StorageUi', 'vmfsDatastore.options.selectConfiguration'),
               isSelectConfigurationOption: true
            });
            scope.options = items;
         }

         /*
          *  If the selection was persisted - restore size value,
          *  otherwise - sets the default one
          */
         function initSelection () {
            //restore previous selection
            if (scope.selectedOption) {
               scope.size = scope.selectedOption.sizeToAdd;
            } else {
               // preselect the "please select" option
               var preselectOptionIndex = 0;
               if (scope.options.length === 2) {
                  // If only one available option is selected - preselect it.
                  preselectOptionIndex = 1;
               }
               scope.selectedOption = scope.options[preselectOptionIndex];
            }
         }
      }

      function CachedValue() {
         var cachedValue;

         return {
            isSame: function(value) {
               var isSame = value && cachedValue === value;
               cachedValue = value;
               return isSame;
            }
         };
      }
   }
})();
