module cluster_ui {

   import IController = angular.IController;
   import IPromise = angular.IPromise;

   class RestoreResourcePoolTreeController implements IController {

      public static $inject = ["$scope", "$element", "mutationService",
         "restoreResourcePoolTreeService","clarityConstants", "i18nService",
         "resourceTypesConstants"];

      public objectId: any;
      public fileInput: any;
      public file: any;

      constructor(private $scope: any, private $element: any,
                  private mutationService: any,
                  private restoreResourcePoolTreeService: RestoreResourcePoolTreeService,
                  private clarityConstants: any, private i18nService: any,
                  private resourceTypesConstants: any) {

         this.objectId = this.$scope.modalOptions.objectId;
         this.fileInput = $element.find("input[type='file']");
         this.fileInput.on("change", this.onFileInputChange.bind(this));

         this.$scope.modalOptions.alerts = [];
         this.$scope.modalOptions.isAlertClosed = true;
         this.$scope.modalOptions.onSubmit = () => this.onSubmit();
      }

      private onFileInputChange(event: any): void {
         if (event.target.files && event.target.files.length) {
            this.setFileToUpload(event.target.files[0]);
         }
      }

      private setFileToUpload(file: any): void {
         this.file = file;
      }

      private onSubmit() {
         this.$scope.modalOptions.alerts = [];
         this.$scope.modalOptions.isAlertClosed = true;

         this.restoreResourcePoolTreeService.readFileContent(this.file)
            .then((fileContent: any) => {
               let byteArray = new Int8Array(fileContent);
               let normalArray = Array.prototype.slice.call(byteArray);
               this.restoreResourcePoolTreeService
                  .validateSnapshotFile(this.objectId, normalArray)
                  .then((response: any) => {
                     if (response.result.validationResultType !==
                           this.resourceTypesConstants.resultValidationType.SUCCESS) {
                        let alert: string = this.restoreResourcePoolTreeService
                              .getValidationResultType(response.result.validationResultType);
                        this.$scope.modalOptions.alerts = [{
                           text: alert,
                           type: this.clarityConstants.notifications.type.ERROR
                        }];
                        this.$scope.modalOptions.isAlertClosed = false;
                     } else {
                        this.restoreResourcePoolTreeService
                              .applyMutationOperation(this.objectId, normalArray);
                        this.$scope.closeModal();
                     }
               });
         });
      }
   }

   angular.module("com.vmware.vsphere.client.cluster")
         .controller("RestoreResourcePoolTreeController", RestoreResourcePoolTreeController);
}
