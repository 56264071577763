/* Copyright 2018 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace dvs_ui {

   import IPromise = angular.IPromise;
   import IQService = angular.IQService;
   import IDeferred = angular.IDeferred;

   export class UnassingUplinkDialogService {

      static $inject = [
         "i18nService",
         "clarityModalService"
      ];

      constructor(private i18nService: any,
                  private clarityModalService: any) {}

      private modalOptions: any;

      public show(pnic: string,
                  okHandler: (applyToAll: boolean) => void): void {

         const dialogData: any = {
            applyToAll: false,
            applyToAllLabel: this.i18nService.getString(
                  "DvsUi", "unassignUplinkDialog.applyToAllLabel"),
            pnicName: pnic
         };
         this.modalOptions = {
            title: this.i18nService.getString("DvsUi", "unassignUplinkDialog.title"),
            subTitle: {text: pnic},
            defaultButton: "submit",
            saveButtonLabel: this.i18nService.getString("DvsUi", "unassignUplinkDialog.unassignBtn"),
            alerts: [],
            dialogData: dialogData,
            contentTemplate: "dvs-ui/resources/dvs/addhost/components/unassignUplink/unassignUplinkDialog.html",
            submit: (): boolean => {
               okHandler(dialogData.applyToAll);
               return true;
            }
         };
         this.clarityModalService.openConfirmationModal(this.modalOptions);
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .service("unassingUplinkDialogService", UnassingUplinkDialogService);
}
