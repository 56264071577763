/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
/*
 * Service which handles the wizard showing and wiring the Add networking wizard steps.
 */
(function () {
   'use strict';
   angular.module('com.vmware.vsphere.client.network')
         .service('addHostNetworkingWizardService', addHostNetworkingWizardService);

   addHostNetworkingWizardService.$inject = [
      '$q',
      'vuiWizardService',
      'i18nService',
      'dataService',
      '$rootScope',
      'mutationService',
      'addNetworkingWizardConstants',
      'addNetworkingSpecificationFactory',
      'addNetworkingPages',
      'addNetworkingFlowManager',
      'networkUiConstants',
      'wizardValidationService'];

   function addHostNetworkingWizardService($q,
                                           vuiWizardService,
                                           i18nService,
                                           dataService,
                                           $rootScope,
                                           mutationService,
                                           addNetworkingWizardConstants,
                                           addNetworkingSpecificationFactory,
                                           addNetworkingPages,
                                           addNetworkingFlowManager,
                                           networkUiConstants,
                                           wizardValidationService) {

      return {
         showWizard: function (hostId, selectedSwitch, connectionType) {
            var STANDARD_SWITCH_HOST =
                  "com.vmware.vsphere.client.h5.network.host.wizard.addnetworking.model.HostStandardSwitchData";
            var wizardScope = $rootScope.$new();
            wizardScope.addNetworkingWizardConstants = addNetworkingWizardConstants;

            wizardScope.hostId = hostId;
            wizardScope.preselectedSwitch = selectedSwitch;

            wizardScope.hostCapability = {};
            // different flags for ipv4 and ipv6 so that both pages can be refreshed
            // when changing netstack property
            wizardScope.hostVnicIpRoutingInfo = {
               isIpv4Set: false,
               isIpv6Set: false,
               value: null
            };

            //default value which is assigned only on Add networking wizard
            //initialization and its not part of reseting defaults
            wizardScope.selectConnectionTypePageModel = {};
            wizardScope.selectConnectionTypePageModel.connectionType =
                  connectionType ? connectionType : addNetworkingWizardConstants.connectionType.VMKERNEL;


            wizardScope.wizardConfig = {
               cssClass: 'add-networking-wizard',
               loading: true,
               updateLoadingState: function(loading) {
                  this.loading = loading;
               }
            };
            wizardScope.wizardConfig.title =
                  i18nService.getString('NetworkUi', 'AddNetworkingWizard.title');
            wizardScope.wizardConfig.onFinish = onAddHostNetworkingComplete;

            wizardScope.wizardConfig.pages = addNetworkingPages.createWizardPages(wizardScope);
            wizardValidationService.initWizardValidation(wizardScope);

            addNetworkingFlowManager.initFlowManager(wizardScope);

            // Show the wizard
            vuiWizardService({
               scope: wizardScope,
               configObjectName: 'wizardConfig'
            }).show();

            wizardScope.$watch(function () {
               return wizardScope.selectConnectionTypePageModel.connectionType;
            }, function () {
               assignDefaults(wizardScope);
               wizardScope.selectTargetNetworkPageModel.retrieveAllSwitches =
                     wizardScope.selectConnectionTypePageModel.connectionType
                     === addNetworkingWizardConstants.connectionType.PNIC;
            });

            wizardScope.$watch(function () {
               return wizardScope.selectTargetNetworkPageModel.targetType;
            }, function (newValue, oldValue) {
               if (newValue !== oldValue) {
                  assignVSwitchSpecDefaults(wizardScope);
               }
            });

            wizardScope.$watch(function () {
               return wizardScope.addNetworkingSpec.selectedStandardSwitch;
            }, function () {
               assignVSwitchSpecDefaults(wizardScope);
            });

            dataService.getData(hostId, STANDARD_SWITCH_HOST).then(function (properties) {
               wizardScope.wizardConfig.iconClass = 'host-ui-icon-default';
               wizardScope.wizardConfig.title = i18nService.getString(
                     'NetworkUi', 'AddNetworkingWizard.updatedTitle', properties.name);
               wizardScope.hostCapability.switchAutoScaleSupported = properties.switchAutoScaleSupported;
               wizardScope.hostCapability.availablePortsNumber = properties.availablePortsNumber;
               wizardScope.wizardConfig.loading = false;
            });

            function onAddHostNetworkingComplete() {

               var specPromise;
               switch (wizardScope.selectConnectionTypePageModel.connectionType) {
                  case addNetworkingWizardConstants.connectionType.VMKERNEL:
                     specPromise = addNetworkingSpecificationFactory
                           .createNewVnicSpec(wizardScope.addNetworkingSpec,
                                 wizardScope.vSwitchSpec,
                                 wizardScope.portPropertiesPageModel.ipSettingsType,
                                 wizardScope.selectTargetNetworkPageModel.targetType,
                                 wizardScope.bindPnicPageModel);
                     break;

                  case addNetworkingWizardConstants.connectionType.VM_PORTGROUP:
                     specPromise = addNetworkingSpecificationFactory.createNewPortgroupSpec(
                           wizardScope.addNetworkingSpec,
                           wizardScope.vSwitchSpec,
                           wizardScope.selectTargetNetworkPageModel.targetType);
                     break;
                  case addNetworkingWizardConstants.connectionType.PNIC:
                     if (wizardScope.selectTargetNetworkPageModel.targetType
                           === addNetworkingWizardConstants.targetType.NEW_STANDARD_SWITCH) {
                        specPromise = addNetworkingSpecificationFactory.createNewPnicToNewStandardSwitchSpec(wizardScope.vSwitchSpec);
                     } else {
                        if (wizardScope.addNetworkingSpec.selectedStandardSwitch.type
                              === networkUiConstants.switchType.DISTRIBUTED) {
                           specPromise = addNetworkingSpecificationFactory.createNewPnicToDistributedSwitchSpec(
                                 wizardScope.addPnicToDvsPageModel.portMapping.uplinkPortMapping,
                                 wizardScope.addNetworkingSpec.selectedStandardSwitch);
                        } else {
                           specPromise = addNetworkingSpecificationFactory.createNewPnicToExistingStandardSwitchSpec(
                                 wizardScope.addNetworkingSpec,
                                 wizardScope.vSwitchSpec);
                        }
                     }
                     break;
               }

               $q.when(specPromise).then(function (spec) {
                  mutationService.apply(wizardScope.hostId, spec._type, spec);
               });
               return true;
            }
         }
      };

      function assignDefaults(wizardScope) {
         wizardScope.vSwitchSpec = {};

         wizardScope.addNetworkingSpec = {};
         // IPv4 Defaults
         // DHCP is enabled by default

         wizardScope.addNetworkingSpec.dhcpEnabled = true;
         wizardScope.addNetworkingSpec.ipv4Address = "";
         wizardScope.addNetworkingSpec.subnetMask = "";
         wizardScope.addNetworkingSpec.overrideGateway = false;
         wizardScope.addNetworkingSpec.ipv4DefaultGateway = "";

         // IPv6 Defaults
         wizardScope.addNetworkingSpec.ipv6DhcpEnabled = false;
         wizardScope.addNetworkingSpec.routerAdvertisementEnabled = false;
         wizardScope.addNetworkingSpec.ipv6Addresses = [];
         wizardScope.addNetworkingSpec.overrideIpv6Gateway = false;
         wizardScope.addNetworkingSpec.ipv6DefaultGateway = "";

         // common flag for IPv4 and IPv6 default gateway
         wizardScope.addNetworkingSpec.isGatewayFeatureSupported = false;

         //init page models
         wizardScope.selectTargetNetworkPageModel = {};

         wizardScope.selectTargetNetworkPageModel.isOpaqueNetworkSelected = false;

         wizardScope.selectTargetNetworkPageModel.targetType =
               (wizardScope.selectConnectionTypePageModel.connectionType
               === addNetworkingWizardConstants.connectionType.VMKERNEL)
                     ? addNetworkingWizardConstants.targetType.EXISTING_NETWORK
                     : addNetworkingWizardConstants.targetType.EXISTING_STANDARD_SWITCH;

         wizardScope.addPnicPageModel = {};
         wizardScope.addPnicPageModel.isInitialized = false;

         wizardScope.addPnicToDvsPageModel = {};
         wizardScope.addPnicToDvsPageModel.isInitialized = false;

         wizardScope.connectionSettingsPageModel = {};
         wizardScope.connectionSettingsPageModel.vlanIds = null;
         wizardScope.connectionSettingsPageModel.isInitialized = false;

         wizardScope.ipv4SettingsPageModel = {};
         wizardScope.ipv4SettingsPageModel.validateIpAddress = {};
         wizardScope.ipv4SettingsPageModel.validateSubnetMask = {};
         wizardScope.ipv4SettingsPageModel.validateIpv4DefaultGateway = {};
         wizardScope.ipv6SettingsPageModel = {};
         wizardScope.ipv6SettingsPageModel.validateIpv6DefaultGateway = {};

         wizardScope.portPropertiesPageModel = {
            ipSettingsType: addNetworkingWizardConstants.ipSettingsMode.IP_V4
         };

         wizardScope.bindPnicPageModel = {
            bindToPnic: false,
            selectedPnic: null,
            availablePnicsData: null,
            onPnicSelectionChange : function(selectedPnic) {
               this.selectedPnic = selectedPnic;
            },
            onBindToPnicChange: function(bindToPnic) {
               this.bindToPnic = bindToPnic;
            },
            onAvailablePnicsDataChange: function(availablePnicsData) {
               this.availablePnicsData = availablePnicsData;
            }
         };
      }

      function assignVSwitchSpecDefaults(wizardScope) {
         if (wizardScope.vSwitchSpec.pnics) {
            wizardScope.vSwitchSpec.pnics = null;
         }
         if (wizardScope.vSwitchSpec.pnicsDetails) {
            wizardScope.vSwitchSpec.pnicsDetails = null;
         }

         wizardScope.addPnicPageModel.isInitialized = false;
         wizardScope.addPnicToDvsPageModel.isInitialized = false;
      }

   }
})();
