/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace dvs_ui {

   import DvsEditPvlanData =
         com.vmware.vsphere.client.h5.network.dvs.pvlan.model.DvsEditPvlanData;
   import DvsConfigSpec = com.vmware.vsphere.client.dvs.api.spec.DvsConfigSpec;
   import Dictionary = _.Dictionary;
   import DvsEditPvlanDialogConstants = dvs_ui.DvsEditPvlanDialogConstants;

   export class DvsEditPvlanDialogService {
      public static $inject = [
         "dataService",
         "mutationService",
         "i18nService",
         "clarityModalService",
         "clarityConstants",
         "vuiConstants",
         "vimEntityEscapingService"
      ];

      public i18n: any;

      constructor(private dataService: any,
                  private mutationService: any,
                  private i18nService: any,
                  private clarityModalService: any,
                  private clarityConstants: any,
                  private vuiConstants: any,
                  private vimEntityEscapingService: any) {
         this.i18n = this.i18nService.getString;
      }

      public show(dvsUrn: string) {
         let modalOptions: any = {
            title: this.i18n("DvsUi", "PrivateVlanPage.titleFormat"),
            defaultButton: "submit",
            size: "lg",
            alerts: [],
            dialogData: {},
            contentTemplate: "dvs-ui/resources/dvs/pvlan/edit/dvsEditPvlanDialog.html",
            onSubmit: onSubmit.bind(this)
         };

         let dvsPromise: any = this.dataService
               .getData(dvsUrn, new DvsEditPvlanData()._type);

         dvsPromise.then((dataServiceResponse: any) => {
            if (dataServiceResponse) {
               modalOptions.subTitle = this.vimEntityEscapingService
                     .unescapeSpecialCharacters(dataServiceResponse.name);
               modalOptions.dialogData.pageModel =
                     new DvsEditPvlanDialogModel(dataServiceResponse);
            }
         });

         this.clarityModalService.openOkCancelModal(modalOptions);

         function onSubmit(this: DvsEditPvlanDialogService): boolean {

            let errorMessage: string = "";
            _.forEach(modalOptions.dialogData.pageModel.pvlanMaps,
                  (pageModelPvlan: PvlanMapModel): void  => {
                     if (pageModelPvlan.hasErrors()) {
                        errorMessage =
                              this.i18n("DvsUi", "PrivateVlanPage.errorSummary");
                        return;
                     }
            });

            if (errorMessage) {
               modalOptions.alerts = [{
                  type: this.clarityConstants.notifications.type.ERROR,
                  text: errorMessage
               }];
               return false;
            }

            let dvsConfigSpec:DvsConfigSpec | null =
                  modalOptions.dialogData.pageModel.buildSpec();
            if (dvsConfigSpec !== null) {
               this.mutationService.apply(dvsUrn, dvsConfigSpec._type, dvsConfigSpec);
            }

            return true;
         }
      }

      public createPrimaryVlanIdDatagridOptions(
            pvlanMaps: Dictionary<PvlanMapModel>,
            callbacks: any): any {
         let promiscuousPvlanMaps: PvlanMapModel[] =
               this.filterPromiscuousPvlanMaps(pvlanMaps);
         return {
            columnDefs: this.createPrimaryVlanIdColumnDefinitions(),
            sortMode: this.vuiConstants.grid.sortMode.SINGLE,
            selectionMode: this.vuiConstants.grid.selectionMode.MULTI,
            showCheckboxesOnMultiSelection: false,
            selectedItems: [],
            data: promiscuousPvlanMaps,
            pvlanMaps: pvlanMaps,
            resizable: true,
            height: "100%",
            onChange: callbacks.selectPrimaryIdRow,
            validateDuplicatePvlanMaps: callbacks.validateDuplicatePvlanMaps,
            updatePvlanIdValue: callbacks.updatePvlanIdValue,
            pageConfig: {
               hidePager: true
            },
            actionBarOptions: {
               actions: [
                  {
                     id: DvsEditPvlanDialogConstants.ADD,
                     enabled: true,
                     iconClass: "vsphere-icon-toolbar-add",
                     onClick: callbacks.addPromiscuousPvlanMap
                  },
                  {
                     id: DvsEditPvlanDialogConstants.REMOVE,
                     enabled: false,
                     iconClass: "vsphere-icon-toolbar-remove",
                     onClick: callbacks.removePrimaryVlanId
                  },
               ]
            }
         };
      }

      private createPrimaryVlanIdColumnDefinitions(): any {
         return [{
            width: "50px",
            template: this.createPrimaryValidationCellTemplate.bind(this)
         }, {
            displayName: this.i18n("DvsUi", "PrivateVlanView.list.primary.header"),
            type: "number",
            field: "primaryId.val",
            sortable: true,
            template: this.createPrimaryIdCellTemplate
         }];
      }

      public createSecondaryVlanIdDatagridOptions(
            pvlanMaps: Dictionary<PvlanMapModel>,
            selectedPrimaryIdKey: number,
            callbacks: any): any {
         let filteredPvlanMaps: PvlanMapModel[] =
               this.filterPvlanMapsByPrimaryId(pvlanMaps, selectedPrimaryIdKey);
         let sortedPvlanMaps: PvlanMapModel[] = this.sortPvlanMaps(filteredPvlanMaps);
         return {
            columnDefs: this.createSecondaryVlanIdColumnDefinitions(),
            data: sortedPvlanMaps,
            sortMode: this.vuiConstants.grid.sortMode.SINGLE,
            selectionMode: this.vuiConstants.grid.selectionMode.MULTI,
            showCheckboxesOnMultiSelection: false,
            selectedItems: [],
            resizable: true,
            height: "100%",
            onChange: callbacks.selectSecondaryIdRow,
            validateDuplicatePvlanMaps: callbacks.validateDuplicatePvlanMaps,
            typeOptions: [
               {
                  value: PvlanType.isolated,
                  label: this.i18nService.getString("DvsUi", "PrivateVlanPage.type.isolated")
               }, {
                  value: PvlanType.community,
                  label: this.i18nService.getString("DvsUi", "PrivateVlanPage.type.community")
               }
            ],
            pvlanMaps: pvlanMaps,
            pvlanMap: pvlanMaps[selectedPrimaryIdKey],
            localizePvlanType: this.localizePvlanType.bind(this),
            updatePvlanIdValue: callbacks.updatePvlanIdValue,
            updatePvlanType: callbacks.updatePvlanType,
            pageConfig: {
               hidePager: true
            },
            actionBarOptions: {
               actions: [
                  {
                     id: DvsEditPvlanDialogConstants.ADD,
                     enabled: true,
                     iconClass: "vsphere-icon-toolbar-add",
                     onClick: callbacks.addCommunityPvlanMap
                  },
                  {
                     id: DvsEditPvlanDialogConstants.REMOVE,
                     enabled: false,
                     iconClass: "vsphere-icon-toolbar-remove",
                     onClick: callbacks.removeSecondaryVlanId
                  },
               ]
            }
         };
      }

      private createSecondaryVlanIdColumnDefinitions(): any {
         return [{
            width: "50px",
            template: this.createSecondaryValidationCellTemplate.bind(this)
         }, {
            displayName: this.i18n("DvsUi", "PrivateVlanView.list.secondary.header"),
            type: "number",
            field: "secondaryId.val",
            sortable: true,
            template: this.createSecondaryIdCellTemplate
         }, {
            displayName: this.i18n("DvsUi", "PrivateVlanView.list.type.header"),
            type: "string",
            field: "type",
            sortable: true,
            template: this.createPvlanTypeCellTemplate.bind(this)
         }];
      }

      public localizePvlanType(pvlanType: string): string {
         if (pvlanType !== PvlanType.promiscuous &&
               pvlanType !== PvlanType.isolated &&
               pvlanType !== PvlanType.community) {
            return "";
         }

         return this.i18n("DvsUi", "PrivateVlanPage.type." + pvlanType);
      }

      public filterPromiscuousPvlanMaps(pvlanMaps: Dictionary<PvlanMapModel>): PvlanMapModel[] {
         return _.where(_.values(pvlanMaps), {type: PvlanType.promiscuous});
      }

      public filterPvlanMapsByPrimaryId(pvlanMaps: Dictionary<PvlanMapModel>,
                                        selectedPrimaryIdKey: number): PvlanMapModel[] {
         return _.filter(_.values(pvlanMaps), (pvlanMap: PvlanMapModel): boolean => {
            return pvlanMap.primaryId.key === selectedPrimaryIdKey;
         });
      }

      public getFirstAvailableKey(nums: number[]): number {
         for (let i: number = 0; i < nums.length; i++) {
            if ((i + 1) !== nums[i]) {
               return i + 1;
            }
         }

         return nums.length + 1;
      }

      public getKeys(pvlanIds: Dictionary<PvlanIdModel>): number[] {
         let keys: number[] = [];
         for (let key in pvlanIds) {
            keys.push(Number(key));
         }
         return keys;
      }

      public getNextAvailableValue(nums: number[], base: number): number {
         nums.sort(function(a, b){return a - b;});

         let index: number = _.indexOf(nums, base + 1);
         let nextNumber: number = base + 1;

         if (index !== -1) {
            for (let i: number = index; i < nums.length; i++) {
               if (nextNumber !== nums[i]) {
                  return nextNumber;
               }
               nextNumber++;
            }
         }

         if (nextNumber <= DvsEditPvlanDialogConstants.PVLAN_ID_VALUE_MAX) {
            return nextNumber;
         }

         // traversing nums for the first available value
         nextNumber = this.getFirstAvailableKey(nums);

         return nextNumber <= DvsEditPvlanDialogConstants.PVLAN_ID_VALUE_MAX ?
               nextNumber : DvsEditPvlanDialogConstants.PVLAN_ID_VALUE_MIN;
      }

      public removePvlanIdFromData(pvlanMaps: PvlanMapModel[],
                           mapModel: PvlanMapModel): PvlanMapModel[] {
         if(!mapModel || !mapModel.secondaryId) {
            return pvlanMaps;
         }

         let pvlanMap: PvlanMapModel =
               _.find(pvlanMaps, (pvlanMap: PvlanMapModel): boolean => {
                  return pvlanMap.secondaryId.key === mapModel.secondaryId.key;
               });

         return _.without(pvlanMaps, pvlanMap);
      }

      public hasPromiscuousPvlanMap(pvlanMaps: PvlanMapModel[]): boolean {
         if (!pvlanMaps) {
            return false;
         }

         for (let i: number = 0; i < pvlanMaps.length; i++) {
            if (pvlanMaps[i].type === PvlanType.promiscuous) {
               return true;
            }
         }

         return false;
      }

      private sortPvlanMaps(pvlanMaps: PvlanMapModel[]): PvlanMapModel[] {
         return _.sortBy(pvlanMaps, (pvlanMap: PvlanMapModel): number => {
            if (pvlanMap.type === PvlanType.promiscuous) {
               return 0;
            }
            return pvlanMap.secondaryId.val;
         });
      }

      private createPrimaryValidationCellTemplate(dataItem: PvlanMapModel): string {
         const duplicateErrorText: string =
               this.i18n("DvsUi", "PrivateVlanPage.id.duplicate");
         const secondaryErrorText: string =
               this.i18n("DvsUi", "PrivateVlanPage.entries.errors");

         let hasDuplicateError: string = "datagridOptions.pvlanMaps[" +
               dataItem.secondaryId.key + "].errors.duplicate";
         let hasSecondaryDuplicateError: string = "datagridOptions.pvlanMaps[" +
               dataItem.secondaryId.key + "].errors.secondaryDuplicate";
         let hasSecondaryIsolatedError: string = "datagridOptions.pvlanMaps[" +
               dataItem.secondaryId.key + "].errors.secondaryIsolated";
         let hasErrors: string = "datagridOptions.pvlanMaps[" +
               dataItem.secondaryId.key + "].hasErrors()";

         return `
            <div class="icon-container" ng-if="${hasErrors}">
               <a role="tooltip" aria-haspopup="true" class="tooltip tooltip-sm tooltip-bottom-right">
                  <clr-icon shape="error" class="is-error" size="24"></clr-icon>
                  <span class="tooltip-content">
                     <div ng-if="${hasDuplicateError}">
                        ${duplicateErrorText}
                     </div>
                     <div ng-if="${hasSecondaryDuplicateError} || ${hasSecondaryIsolatedError}">
                        ${secondaryErrorText}
                     </div>
                  </span>
               </a>
            </div>
         `;
      }

      private createSecondaryValidationCellTemplate(dataItem: PvlanMapModel): string {
         const duplicateErrorText: string =
               this.i18n("DvsUi", "PrivateVlanPage.id.duplicate");
         const isolatedErrorText: string =
               this.i18n("DvsUi", "PrivateVlanPage.isolated.duplicate");

         let isPromiscuous: boolean = dataItem.type === PvlanType.promiscuous;
         let hasDuplicateError: string = "datagridOptions.pvlanMaps[" +
               dataItem.secondaryId.key + "].errors.duplicate";
         let hasIsolatedError: string = "datagridOptions.pvlanMaps[" +
               dataItem.secondaryId.key + "].errors.isolated";
         let hasErrors: string = "datagridOptions.pvlanMaps[" +
               dataItem.secondaryId.key + "].hasErrors()";

         return `
            <div class="icon-container" ng-if="!${isPromiscuous} && ${hasErrors}">
               <a role="tooltip" aria-haspopup="true" class="tooltip tooltip-md tooltip-bottom-right">
                  <clr-icon shape="error" class="is-error" size="24"></clr-icon>
                  <span class="tooltip-content">
                     <div ng-if="${hasDuplicateError}">
                        ${duplicateErrorText}
                     </div>
                     <div ng-if="${hasIsolatedError}">
                        ${isolatedErrorText}
                     </div>
                  </span>
               </a>
            </div>
         `;
      }

      private createPrimaryIdCellTemplate(dataItem: PvlanMapModel): string {
         return `
            <div ng-if="datagridOptions.selectedItems[0] === dataItem">
               <input type="number"
                   ng-model="dataItem.primaryId.val"
                   vx-focus-and-select
                   ng-change="datagridOptions.updatePvlanIdValue(dataItem.primaryId)"
                   ng-blur="datagridOptions.validateDuplicatePvlanMaps()"/>
            </div>
            <div ng-if="datagridOptions.selectedItems[0] !== dataItem">{{dataItem.primaryId.val}}</div>
         `;
      }

      private createSecondaryIdCellTemplate(dataItem: PvlanMapModel): string {
         if (dataItem.type === PvlanType.promiscuous) {
            return "{{ datagridOptions.pvlanMap.secondaryId.val }}";
         }
         return `
            <div ng-if="datagridOptions.selectedItems[0] === dataItem">
            <input type="number"
                   ng-model="dataItem.secondaryId.val"
                   vx-focus-and-select
                   ng-change="datagridOptions.updatePvlanIdValue(dataItem.secondaryId)"
                   ng-blur="datagridOptions.validateDuplicatePvlanMaps()"/>
            </div>
            <div ng-if="datagridOptions.selectedItems[0] !== dataItem">{{dataItem.secondaryId.val}}</div>
         `;
      }

      private createPvlanTypeCellTemplate(dataItem: PvlanMapModel): string {
         if (dataItem.type === PvlanType.promiscuous) {
            return this.localizePvlanType(PvlanType.promiscuous);
         }

         return `
            <div class='select' ng-if="datagridOptions.selectedItems[0] === dataItem"> 
               <select 
                       ng-options="item.value as item.label for item in datagridOptions.typeOptions"
                       ng-change="datagridOptions.updatePvlanType(dataItem)"
                       ng-model="dataItem.type">
               </select> 
            </div>
            <div ng-if="datagridOptions.selectedItems[0] !== dataItem">{{datagridOptions.localizePvlanType(dataItem.type)}}</div>
         `;
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .service("dvsEditPvlanDialogService", DvsEditPvlanDialogService);
}
