/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace h5_vm {

export class SelectMigrationTypePageModel {
   /**
    * [input]
    *
    * The VMs being migrated.
    */
   public vms: Array<string> = [];

   /**
    * [output]
    *
    * Flag indicating if cross vc migration is possible.
    */
   public isXvcMigrationPossible: boolean;

   /**
    * [page specific]
    *
    * Cache of the responce of the properties request to the vc
    */
   public cachedProperties: {[vmId: string]: any};
} // class
} // module
