namespace storage_ui {
   import IController = angular.IController;
   import ProtocolEndpoint$PEType = com.vmware.vim.binding.vim.host.ProtocolEndpoint$PEType;

   class DatastoreProtocolEndpointsMasterDetailsViewController implements IController {
      public static $inject = ["i18nService", "$q"];
      public readonly detailsViewData: any;

      constructor(private i18nService: any,
                  private $q: ng.IQService) {
         this.detailsViewData = {
            tabsPromise: this.$q.when([
               {
                  name: this.i18nService.getString("StorageUi", "storage.pe.propertiesTab.header"),
                  templateUrl: "storage-ui/resources/storage/views/datastore/protocolEndpoints/properties/ProtocolEndpointsGeneral.html",
                  uid: "datastore.pe.generalTab"
               },
               {
                  name: this.i18nService.getString("StorageUi", "storage.pe.hostsTab.header"),
                  templateUrl: "storage-ui/resources/storage/views/datastore/protocolEndpoints/hostMountData/ProtocolEndpointsHostMountData.html",
                  uid: "datastore.pe.hostTab",
                  metadata: {
                     relevantFor: "isScsiPE"
                  }
               }
            ])
         };
      }
   }

   angular.module("com.vmware.vsphere.client.storage")
         .controller("DatastoreProtocolEndpointsMasterDetailsViewController",
               DatastoreProtocolEndpointsMasterDetailsViewController);
}
