/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */

import DvsUpdateWizardPages = dvs_ui.DvsUpgradeWizardPages;

namespace dvs_ui {

   export class DvsUpgradeWizardPages {

      public static $inject = [
            "i18nService",
            "dvsUpgradeWizardManager",
            "dvsUpgradeWizardConstants",
            "simpleValidationCommit",
            "vuiConstants",
            "dvsHostCompatibilityPageValidator",
            "dvsPrerequisitesPageValidator",
            "dvsLagPageValidator"];

      constructor(private i18nService: any,
                  private wizardManager: DvsUpgradeWizardManager,
                  private wizardConstants: any,
                  private simpleValidationCommit: any,
                  private vuiConstants: any,
                  private dvsHostCompatibilityPageValidator: any,
                  private dvsPrerequisitesPageValidator: any,
                  private dvsLagPageValidator: any) {
      }

      /**
       * Creates an array of page definitions for the Upgrade Distributed Switch wizard.
       */
      public createPages(wizardScope: DvsUpgradeWizardScope): WizardPage[] {

         let selectDvsVersionPage: WizardPage = {
            id: this.wizardConstants.pages.DVS_SELECT_VERSION_PAGE,
            title: this.i18nService.getString("DvsUi",
                  "SelectDvsVersionPage.title"),
            description: this.i18nService.getString("DvsUi",
                  "SelectDvsVersionPage.description"),
            contentUrl: "dvs-ui/resources/dvs/upgrade/views/dvsSelectVersionPage.html",
            onCommit: this.simpleValidationCommit(() => {
               this.wizardManager.updateExplicitUpgradesPages(wizardScope);
               return [];
            }, wizardScope.wizardConfig),
            validationParams: ["model.selectedVersion"]
         };

         let hostCompatibilityPage: WizardPage = {
            id: this.wizardConstants.pages.DVS_HOST_COMPATIBILITY_PAGE,
            title: this.i18nService.getString("DvsUi",
                  "DvsHostCompatibilityPage.title"),
            description: this.i18nService.getString("DvsUi",
                  "DvsHostCompatibilityPage.description"),
            contentUrl: "dvs-ui/resources/dvs/upgrade/views/" +
                  "dvsHostCompatibilityPage.html",
            onCommit: this.simpleValidationCommit(() => {
               return this.dvsHostCompatibilityPageValidator.getValidationError(
                     wizardScope);
            }, wizardScope.wizardConfig),
            validationParams: []
         };

         let niocPrerequisitesPage: WizardPage = {
            id: this.wizardConstants.pages.DVS_NIOC_PREREQUISITES_PAGE,
            title: this.i18nService.getString("DvsUi",
                  "DvsNiocPrerequisitesPage.title"),
            description: this.i18nService.getString("DvsUi",
                  "DvsNiocPrerequisitesPage.description"),
            contentUrl: "dvs-ui/resources/dvs/upgrade/views/" +
                  "dvsNiocPrerequisitesPage.html",
            defaultState: this.vuiConstants.wizard.pageState.SKIPPED,
            onCommit: this.simpleValidationCommit(() => {

               if (!wizardScope.model.isExplicitNiocUpgradeEnabled) {
                  return true;
               }

               return this.dvsPrerequisitesPageValidator.getValidationResult(
                     wizardScope.model.niocPrerequisiteChecks,
                     wizardScope.model);
            }, wizardScope.wizardConfig),
            validationParams: []
         };

         let migrateSharesPage: WizardPage = {
            id: this.wizardConstants.pages.DVS_NIOC_MIGRATE_SHARES_PAGE,
            title: this.i18nService.getString("DvsUi",
                  "DvsUpgradeNiocWizard.migrateShares.title"),
            description: this.i18nService.getString("DvsUi",
                  "DvsUpgradeNiocWizard.migrateShares.description"),
            contentUrl: "dvs-ui/resources/dvs/niocupgrade/views/" +
                  "dvsUpgradeNiocSharesPage.html",
            defaultState: this.vuiConstants.wizard.pageState.SKIPPED,
            onCommit: this.simpleValidationCommit(() => {
               return [];
            }, wizardScope.wizardConfig),
            validationParams: ["model.migrateShares"]
         };

         let lacpPrerequisitesPage: WizardPage = {
            id: this.wizardConstants.pages.DVS_LACP_PREREQUISITES_PAGE,
            title: this.i18nService.getString("DvsUi",
                  "DvsLacpPrerequisitesPage.title"),
            description: this.i18nService.getString("DvsUi",
                  "DvsLacpPrerequisitesPage.description"),
            contentUrl: "dvs-ui/resources/dvs/upgrade/views/" +
                  "dvsLacpPrerequisitesPage.html",
            defaultState: this.vuiConstants.wizard.pageState.SKIPPED,
            onCommit: this.simpleValidationCommit(() => {


               if (!wizardScope.model.isExplicitLacpUpgradeEnabled) {
                  return true;
               }

               return this.dvsPrerequisitesPageValidator.getValidationResult(
                     wizardScope.model.lacpPrerequisiteChecks,
                     wizardScope.model);
            }, wizardScope.wizardConfig),
            validationParams: []
         };

         let lacpLagPage: WizardPage = {
            id: this.wizardConstants.pages.DVS_LACP_CREATE_LAG_PAGE,
            title: this.i18nService.getString("DvsUi",
                  "DvsUpgradeLacpWizard.createLag.title"),
            description: this.i18nService.getString("DvsUi",
                  "DvsUpgradeLacpWizard.createLag.description"),
            contentUrl: "dvs-ui/resources/dvs/lacpupgrade/views/" +
                  "dvsUpgradeLacpLagPage.html",
            defaultState: this.vuiConstants.wizard.pageState.SKIPPED,
            onCommit: this.simpleValidationCommit(() => {
               return this.dvsLagPageValidator.getValidationError(wizardScope);
            }, wizardScope.wizardConfig),
            validationParams: ["model.lag.name"]
         };

         let summaryPage: WizardPage = {
            id: this.wizardConstants.pages.DVS_READY_TO_COMPLETE_PAGE,
            title: this.i18nService.getString("DvsUi",
                  "DvsUpgradeWizard.summary.title"),
            description: this.i18nService.getString("DvsUi",
                  "DvsUpgradeWizard.summary.description"),
            contentUrl: "dvs-ui/resources/dvs/upgrade/views/" +
                  "dvsUpgradeReadyToCompletePage.html",
            onCommit: this.simpleValidationCommit(function () {
               return [];
            }, wizardScope.wizardConfig),
            validationParams: []
         };

         let pages: WizardPage[] = [
               selectDvsVersionPage,
               hostCompatibilityPage,
               niocPrerequisitesPage,
               migrateSharesPage,
               lacpPrerequisitesPage,
               lacpLagPage,
               summaryPage
         ];

         return pages;
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .service("dvsUpgradeWizardPages", DvsUpgradeWizardPages);
}
