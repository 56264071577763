/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace dvs_ui {

   import IRootScopeService = angular.IRootScopeService;
   import DvsAddSpanSessionWizardData =
         com.vmware.vsphere.client.h5.network.dvs.portmirroring.model.wizard.DvsAddSpanSessionWizardData;
   import DvsAddSpanSessionSpec =
         com.vmware.vsphere.client.h5.network.dvs.portmirroring.model.wizard.DvsAddSpanSessionSpec;

   export class DvsAddSpanSessionWizardService {

      public static $inject = [
            "$rootScope",
            "i18nService",
            "vxValidatorFactory",
            "vuiWizardService",
            "wizardValidationService",
            "pageStateManagementService",
            "mutationService",
            "dataService",
            "dvsAddSpanSessionWizardPages",
            "dvsAddSpanSessionWizardSpecFactory",
            "dvsAddSpanSessionWizardFlowManager"
      ];

      public static DATA_PROPERTY: string =
            "dvswitchportmirroring:addSpanSessionWizardData";

      constructor(private $rootScope: IRootScopeService,
                  private i18nService: any,
                  private vxValidatorFactory: any,
                  private vuiWizardService: any,
                  private wizardValidationService: any,
                  private pageStateManagementService: any,
                  private mutationService: any,
                  private dataService: any,
                  private wizardPages: DvsAddSpanSessionWizardPages,
                  private specFactory: DvsAddSpanSessionWizardSpecFactory,
                  private flowManager: DvsAddSpanSessionWizardFlowManager) {
      }

      /**
       * Shows the Add Port Mirroring Session wizard.
       */
      public show(dvsUrn: string): void {
         let wizardScope: DvsAddSpanSessionWizardScope =
               this.$rootScope.$new() as DvsAddSpanSessionWizardScope;

         wizardScope.busy = false;

         wizardScope.model = new DvsAddSpanSessionWizardModel();
         wizardScope.model.dvsUrn = dvsUrn;

         wizardScope.wizardConfig = this.createWizardConfig(wizardScope.model);
         wizardScope.wizardConfig.pages = this.wizardPages.createPages(wizardScope);
         wizardScope.wizardConfig.loading = true;

         this.wizardValidationService.initWizardValidation(wizardScope);
         this.flowManager.init(wizardScope);

         this.vuiWizardService({
            scope: wizardScope,
            configObjectName: "wizardConfig"
         }).show();

         this.requestData(wizardScope);
      }

      private requestData(wizardScope: DvsAddSpanSessionWizardScope): void {
         this.dataService.getProperties(
               wizardScope.model.dvsUrn,
               [DvsAddSpanSessionWizardService.DATA_PROPERTY])
               .then((response: any): void => {
                  let wizardData: DvsAddSpanSessionWizardData =
                        response[DvsAddSpanSessionWizardService.DATA_PROPERTY];

                  if (wizardData) {
                     this.updateWizardModel(wizardScope, wizardData);
                     this.updatePageModels(wizardScope, wizardData);
                     this.initPageValidators(wizardScope.model);

                     wizardScope.wizardConfig.title = this.i18nService
                           .getString("DvsUi", "AddSpanSessionWizard.titleFormat",
                                 wizardData.dvsName);
                     wizardScope.wizardConfig.loading = false;
                  }
               });
      }

      private createWizardConfig(wizardModel: DvsAddSpanSessionWizardModel): WizardConfig {
         let wizardConfig: WizardConfig = new WizardConfig();

         wizardConfig.title =
               this.i18nService.getString("DvsUi", "AddSpanSessionWizard.initialTitle");
         wizardConfig.loadingMessage =
               this.i18nService.getString("CommonUi", "wizard.loading");
         wizardConfig.cssClass = "add-span-session";

         wizardConfig.onFinish = (): boolean => {
            let addSpanSessionSpec: DvsAddSpanSessionSpec =
                  this.specFactory.buildSpec(wizardModel);

            this.mutationService.apply(
                  wizardModel.dvsUrn,
                  addSpanSessionSpec._type,
                  addSpanSessionSpec);

            return true;
         };

         return wizardConfig;
      }

      private updateWizardModel(wizardScope: DvsAddSpanSessionWizardScope,
            wizardData: DvsAddSpanSessionWizardData): void {

         wizardScope.model.sessionType =
               DvsSpanSessionConstants.SESSION_TYPE.DV_PORT_MIRROR;

         // Select session page
         wizardScope.model.supportedSessionTypes = [];

         if (wizardData.dvPortMirrorSupported) {
            wizardScope.model.supportedSessionTypes.push(
                  DvsSpanSessionConstants.SESSION_TYPE.DV_PORT_MIRROR);
         }

         if (wizardData.remoteSourceSupported) {
            wizardScope.model.supportedSessionTypes.push(
                  DvsSpanSessionConstants.SESSION_TYPE.REMOTE_MIRROR_SOURCE);
         }

         if (wizardData.remoteDestSupported) {
            wizardScope.model.supportedSessionTypes.push(
                  DvsSpanSessionConstants.SESSION_TYPE.REMOTE_MIRROR_DEST);
         }

         wizardScope.model.supportedSessionTypes.push(
               DvsSpanSessionConstants.SESSION_TYPE.L3_SOURCE);
      }

      private updatePageModels(wizardScope: DvsAddSpanSessionWizardScope,
            wizardData: DvsAddSpanSessionWizardData): void {
         // Set session name for the properties page model
         wizardScope.model.propertiesPageModel.sessionName =
               wizardScope.model.retrievedSessionName = wizardData.sessionName;

         wizardScope.model.propertiesPageModel.erspanSupported =
               wizardData.erspanSupported;

         wizardScope.model.propertiesPageModel.isTcpIpStackSupported =
               wizardData.mirrorNetstackSupported;
      }

      private initPageValidators(wizardModel: DvsAddSpanSessionWizardModel): void {
         wizardModel.pageValidators[DvsSpanSessionConstants.PAGE_ID.EDIT_PROPERIES] =
               new DvsSpanSessionPageValidator(
                     wizardModel.propertiesPageModel,
                     this.vxValidatorFactory.create());

         wizardModel.pageValidators[DvsSpanSessionConstants.PAGE_ID.SELECT_DESTINATION_ADDRESSES] =
               new DvsSpanSessionPageValidator(
                     wizardModel.selectDestinationsPageModel.ipAddressesListModel,
                     this.vxValidatorFactory.create());

         wizardModel.pageValidators[DvsSpanSessionConstants.PAGE_ID.SELECT_SOURCE_VLANS] =
               new DvsSpanSessionPageValidator(
                     wizardModel.selectSourcesPageModel.vlanIdListModel,
                     this.vxValidatorFactory.create());
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .service("dvsAddSpanSessionWizardService", DvsAddSpanSessionWizardService);
}
