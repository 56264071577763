angular.module('com.vmware.vsphere.client.vm').service('migrateVmWizardService', [
   'vuiService',
   'mutationService',
   'clarityModalService',
   'defaultUriSchemeUtil',
   'i18nService',
   '$rootScope',
   '$timeout',
   'vmMigrationSelectMigrationTypePageService',
   'vmMigrationSelectComputeResourcePageService',
   'vmMigrationSelectStoragePageService',
   'vmMigrationSelectPriorityPageService',
   'vmMigrationFinishPageService',
   'vuiConstants',
   'migrationTypeConstants',
   'vmFtConstants',
   'configurationService',
   'wizardPageService',
   '$q',
   'MigrationWizardManager',
   'vmMigrationSelectNetworkPageService',
   'vmMigrationSelectComputeResourceTreePageService',
   'vmMigrationSelectFolderPageService',
   'actionConfirmationService',
   'managedByMessageBuilderService',
   'vmMigrationScheduleTaskPageService',
   'migrateVmScheduledDataToModelMapper',
   function (
      vuiService,
      mutationService,
      clarityModalService,
      defaultUriSchemeUtil,
      i18nService,
      $rootScope,
      $timeout,
      vmMigrationSelectMigrationTypePageService,
      vmMigrationSelectComputeResourcePageService,
      vmMigrationSelectStoragePageService,
      vmMigrationSelectPriorityPageService,
      vmMigrationFinishPageService,
      vuiConstants,
      migrationTypeConstants,
      vmFtConstants,
      configurationService,
      wizardPageService,
      $q,
      MigrationWizardManager,
      vmMigrationSelectNetworkPageService,
      vmMigrationSelectComputeResourceTreePageService,
      vmMigrationSelectFolderPageService,
      actionConfirmationService,
      managedByMessageBuilderService,
      vmMigrationScheduleTaskPageService,
      migrateVmScheduledDataToModelMapper
   ) {

      return {
         showWizard: function (vmIds, context, actionEval) {
            var destinationTarget = null;
            if (context && context.dropTarget) {
               destinationTarget = context.dropTarget;
            }
            var migrationType = null;
            if (context && context.migrationType) {
               migrationType = context.migrationType;
            }

            return validateWorkflow(vmIds, actionEval, context).then(function (validateWorkflowResult) {
               if (!validateWorkflowResult.continue) {
                  return undefined;
               }
               vmIds = validateWorkflowResult.availableVms;
               var vmProperties = validateWorkflowResult.vmProperties;
               // All vms are verified to be with the same power state
               // so just take the first one
               var isHotMigration = vmProperties[vmIds[0]].powerState === 'poweredOn';

               var isNetworkSelectionRequired = checkIfNetworkSelectionRequired(
                     vmProperties, validateWorkflowResult.hasNetworkAdapter) ;

               var wizardScope = $rootScope.$new();
               wizardScope.locationSpec = {};
               wizardScope.migrationWizardManager = new MigrationWizardManager(
                     wizardScope, vmProperties);
               wizardScope.migrationWizardManager.isNetworkSelectionRequired =
                     isNetworkSelectionRequired;
               // TODO - vaivanov - figure out who uses this and remove it once the
               // dependent component supports multiple vm migration.
               wizardScope.vmId = vmIds[0];
               wizardScope.vmName = vmProperties[vmIds[0]].name;

               // Used by SelectMigrationTypePage. Needs to be removed when page
               // starts supporting multiple vm case.
               var hostData = vmProperties[vmIds[0]].host;
               wizardScope.attributes = {
                  creationType: migrationTypeConstants.MIGRATE_RESOURCE_ONLY,
                  hostData: hostData
               };
               wizardScope.destinationTarget = destinationTarget;
               if (migrationType) {
                  wizardScope.migrationWizardManager.setSelectedMode(migrationType);
               }
               var flowIds = [
                  migrationTypeConstants.MIGRATE_RESOURCE_ONLY,
                  migrationTypeConstants.MIGRATE_STORAGE_ONLY,
                  migrationTypeConstants.MIGRATE_RESOURCE_AND_STORAGE
               ];

               var selectMigrationTypePage = vmMigrationSelectMigrationTypePageService
                     .build(wizardScope, flowIds);
               var computeResourcePage = vmMigrationSelectComputeResourcePageService
                     .build(wizardScope);
               var computeResourceTreePage = vmMigrationSelectComputeResourceTreePageService
                     .build(wizardScope);
               var selectFolderPage = vmMigrationSelectFolderPageService
                     .build(wizardScope);
               var selectPriorityPage = vmMigrationSelectPriorityPageService
                     .build(wizardScope);

               var storagePage = vmMigrationSelectStoragePageService
                     .build(wizardScope);

               var migrateFinishPage = vmMigrationFinishPageService
                     .build(wizardScope);

               var selectNetworkPage = vmMigrationSelectNetworkPageService
                     .build(wizardScope);


               var flows = [
                  {
                     id: migrationTypeConstants.MIGRATE_RESOURCE_ONLY,
                     pages: buildMigrateComputeResourcePages()
                  },
                  {
                     id: migrationTypeConstants.MIGRATE_STORAGE_ONLY,
                     pages: buildMigrateStoragePages()
                  },
                  {
                     id: migrationTypeConstants.MIGRATE_RESOURCE_AND_STORAGE,
                     pages: buildMigrateComputeResourceAndStoragePages()
                  }
               ];

               var pages;

               var wizardTitleSingle = 'MigrationWizard.title.single';
               if (actionEval && actionEval.additionalData
                     && actionEval.additionalData.isScheduledTask === true) {
                  var scheduleTaskPage = vmMigrationScheduleTaskPageService
                     .build(wizardScope, actionEval.additionalData
                        .schedulingData.schedulingSpec);
                  wizardScope.migrationWizardManager.vmWizardScheduleTaskPageModel
                        .mutationSpec = actionEval.additionalData.schedulingData;
                  wizardScope.migrationWizardManager.vmWizardScheduleTaskPageModel
                        .isScheduledTaskFlow = actionEval.additionalData.isScheduledTask;

                  pages = [
                     scheduleTaskPage,
                     selectMigrationTypePage
                  ];

                  if (actionEval.additionalData.schedulingData.scheduleTaskOperationMode === 1) { // Edit
                     wizardTitleSingle = 'ScheduledTask.Edit.' + wizardTitleSingle;
                  } else {
                     wizardTitleSingle = 'ScheduledTask.' + wizardTitleSingle;
                  }

               } else {
                  pages = [
                     selectMigrationTypePage
                  ];
               }

               var wizardTitle = vmIds.length === 1 ?
                     i18nService.getString(
                           'VmUi', wizardTitleSingle,
                           vmProperties[vmIds[0]].name) :
                     i18nService.getString(
                           'VmUi', 'MigrationWizard.Title.Multi',
                           vmIds.length);

               wizardScope.wizardConfig = {
                  title: wizardTitle,
                  pages: wizardPageService.initializePageStates(pages),
                  cssClass: 'migrate-vm-wizard',
                  flows: wizardPageService.initializeFlowStates(flows)
               };

               var schedulingData = actionEval && actionEval.additionalData &&
                     actionEval.additionalData.schedulingData;
               migrateVmScheduledDataToModelMapper.applyDataToModels(
                     wizardScope.migrationWizardManager,
                     schedulingData
               ).then(function () {
                  vuiService.createWizard({
                     scope: wizardScope,
                     configObjectName: 'wizardConfig'
                  }).show();
               });

               wizardScope.$watch(function() {
                  return wizardScope.migrationWizardManager.computeResourcePageModel.computeResource;
               }, function(newValue) {
                  showFolderPageIfNeeded(wizardScope.wizardConfig.flows,
                        migrationTypeConstants.MIGRATE_RESOURCE_ONLY, newValue);
               });

               wizardScope.$watch(function() {
                  return wizardScope.migrationWizardManager.computeResourceTreePageModel.resource;
               }, function(newValue) {
                  showFolderPageIfNeeded(wizardScope.wizardConfig.flows,
                        migrationTypeConstants.MIGRATE_RESOURCE_AND_STORAGE, newValue);
               });

               return wizardScope;

               function showFolderPageIfNeeded(flows, flowId, newItem) {
                  if (!newItem) {
                     return;
                  }

                  var flow = _.find(flows, function(flow) {
                     return flow.id === flowId;
                  });
                  var pages = flow.pages;

                  if (!isVcChanged(newItem)) {
                     wizardPageService.markPageSkipped(
                           pages,
                           i18nService.getString('VmUi', 'MigrationWizard.selectFolderPage.Title')
                     );
                  } else {
                     wizardPageService.markPageDisabled(
                           pages,
                           i18nService.getString('VmUi', 'MigrationWizard.selectFolderPage.Title')
                     );
                  }
               }

               function checkIfNetworkSelectionRequired(vmProperties, hasNetworkAdapter) {
                  // If none of the migrated VMs have virtual network adapter, don't show the
                  // network selection page.
                  // If a secondary FT VM is being migrated, don't show network selection page.
                  if (!hasNetworkAdapter || hasSecondaryVms(vmProperties)) {
                     return false;
                  }

                  // If there is no secondary FT VM within the ones being migrated, and at
                  // least one of them has a virtual network adapter, show the network
                  // selection page.
                  return true;
               }

               function hasSecondaryVms(vmProperties) {
                  var PROP_VM_FTSTATE = 'runtime/faultToleranceState';
                  var PROP_VM_FTINFO = 'config/ftInfo';

                  return _.some(vmProperties, function(singleVmProperies) {
                     return (singleVmProperies[PROP_VM_FTSTATE] !== vmFtConstants.NOT_CONFIGURED &&
                     singleVmProperies[PROP_VM_FTINFO] &&
                     singleVmProperies[PROP_VM_FTINFO].role > 1);
                  });
               }

               function isVcChanged(newItem) {
                  var sourceServerId = defaultUriSchemeUtil.getPartsFromVsphereObjectId(vmIds[0]).serverGuid;
                  var destinationSeverId = defaultUriSchemeUtil.getPartsFromVsphereObjectId(newItem).serverGuid;
                  return sourceServerId !== destinationSeverId;
               }

               function buildMigrateComputeResourcePages() {
                  var migrateResourcePages = [computeResourcePage];

                  selectFolderPage.defaultState = vuiConstants.wizard.pageState.SKIPPED;
                  migrateResourcePages.push(selectFolderPage);

                  if (isNetworkSelectionRequired) {
                     migrateResourcePages.push(selectNetworkPage);
                  }

                  if (isHotMigration) {
                     migrateResourcePages.push(selectPriorityPage);
                  }

                  migrateResourcePages.push(migrateFinishPage);

                  return migrateResourcePages;
               }

               function buildMigrateStoragePages() {
                  return [
                     storagePage,
                     migrateFinishPage
                  ];
               }
               function buildMigrateComputeResourceAndStoragePages () {
                  var migrateComputeResourceAndStoragePages = [];
                  migrateComputeResourceAndStoragePages.push(computeResourceTreePage);
                  migrateComputeResourceAndStoragePages.push(storagePage);

                  selectFolderPage.defaultState = vuiConstants.wizard.pageState.SKIPPED;
                  migrateComputeResourceAndStoragePages.push(selectFolderPage);

                  if (isNetworkSelectionRequired) {
                     migrateComputeResourceAndStoragePages.push(selectNetworkPage);
                  }
                  if (isHotMigration) {
                     migrateComputeResourceAndStoragePages.push(selectPriorityPage);
                  }
                  migrateComputeResourceAndStoragePages.push(migrateFinishPage);

                  return migrateComputeResourceAndStoragePages;
               }
            });
         }
      };

      function validateWorkflow(vmIds, actionEval, context) {
         return mutationService.validateSpec(
               "com.vmware.vsphere.client.h5.vm.model.migration.VmMigrationFlowValidationSpec",
               {vms: vmIds})
               .then(function(validationResult) {
                  return handleErrorsAndWarnings(validationResult, actionEval, context);
               });
      }

      function handleErrorsAndWarnings(validation, actionEval, context) {
         // TODO - vaivanov - Add support for showing multiple errors and warnings.
         // Currently validation can generate up to 1 error and up to 1 warning.
         // So there is no need for UI that can display multiple errors and warnings.
         // The only case not covered here is when we have 1 warning and 1 error.
         // The can happen if user chooses to migrate at least 1 FT vm along with some
         // other VMs with different power state. In this case we show only the error
         // for VMs in different power state without the FT warning.
         var deferred = $q.defer();
         var errors = validation.error ?
               [i18nService.getString("VmUi", "ValidateVmsView.ErrorGeneric")] :
               validation.result.errors;
         if (!_.isEmpty(errors)) {
            var errorOptions = {
               title: i18nService.getString("VmUi", "ValidateVmsView.Title"),
               message: validation.result.errors[0],
               icon: 'vui-icon32-error',
               submit: function() {
                  deferred.resolve({
                     continue: false,
                     vmProperties: {},
                     availableVms: []
                  });
               },
               hideCancelButton: true
            };
            clarityModalService.openConfirmationModal(errorOptions);
         } else if (!_.isEmpty(validation.result.warnings)) {
            // Here we set the availability of the action of the VMs, which are filtered out
            // from the warning, to 'false' in order to have the correct count in
            // the message from the action framework
            _.each(actionEval.evaluationStatuses, function(status, vmId) {
               status.available = _.contains(validation.result.availableVms, vmId);
            });
            var warningOptions = {
               title: i18nService.getString("VmUi", "ValidateVmsView.Title"),
               message: validation.result.warnings[0],
               icon: 'icon-warning-32',
               saveButtonLabel: i18nService.getString("VmUi", "ValidateVmsView.Continue"),
               submit: function() {
                  confirmIfRequired(actionEval, validation.result.availableVms, context,
                        validation.result.vmProperties).then(function() {
                     deferred.resolve({
                        continue: true,
                        vmProperties: validation.result.vmProperties,
                        availableVms: validation.result.availableVms,
                        hasNetworkAdapter: validation.result.hasNetworkAdapter
                     });
                  });
                  return true;
               },
               onCancel: function() {
                  deferred.resolve({
                     continue: false,
                     vmProperties: {},
                     availableVms: []
                  });
                  return true;
               }
            };
            clarityModalService.openConfirmationModal(warningOptions);
         } else {
            confirmIfRequired(actionEval, validation.result.availableVms, context,
                  validation.result.vmProperties).then(function() {
               deferred.resolve({
                  continue: true,
                  vmProperties: validation.result.vmProperties,
                  availableVms: validation.result.availableVms,
                  hasNetworkAdapter: validation.result.hasNetworkAdapter
               });
            });
         }

         return deferred.promise;
      }

      function confirmIfRequired(actionEval, vmIds, context, vmProperties) {
         if (!actionEval || (context && context.dropTarget)) {
            return $q.when();
         }
         var deferred = $q.defer();

         actionEval.action.confirmationTextSome = null;
         actionEval.action.confirmationTextAll = null;

         var withProceed = Object.keys(actionEval.evaluationStatuses).length === 1;
         managedByMessageBuilderService.buildWarningMessage(vmIds, withProceed, vmProperties)
               .then(function(managedByInfoMessage) {
                  if (managedByInfoMessage) {
                     actionEval.action.confirmationText = managedByInfoMessage;
                     managedByInfoMessage += "\n\n";
                     actionEval.action.confirmationTextSome = managedByInfoMessage +
                           i18nService.getString("Common", "defaultConfirmation.someOK");
                     actionEval.action.confirmationTextAll = managedByInfoMessage +
                           i18nService.getString("Common", "defaultConfirmation.allOK");
                  }

                  actionConfirmationService.confirmIfRequired(actionEval, function() {
                     deferred.resolve();
                  }, true);
         });
         return deferred.promise;
      }
   }]);
