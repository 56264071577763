module ds_cluster_ui {

   export class DsClusterServicesAndResourcesService {

      public static $inject = ["vxPropertyViewService", "i18nService"];
      private static MIN_CAPACITY_VALUE: string = "0 ";

      constructor(private vxPropertyViewService: any, private i18nService: any) { }

      public buildResourcesPropertyViewData(data: any): Object {
         var builder = this.vxPropertyViewService.createPropertyViewBuilder();
         var sectionBuilder = builder
               .category("")
               .section("");

         sectionBuilder.propertyBuilder()
               .keyBuilder()
               .text(this.i18nService.getString("DsClusterUi", "dscluster.resources.used"))
               .icon("ds-cluster-used-resources-legend vx-property-view-key-legend-icon")
               .exit()
               .valueBuilder()
               .text(data.formattedUsedSpace);
         sectionBuilder.propertyBuilder()
               .keyBuilder()
               .text(this.i18nService.getString("DsClusterUi", "dscluster.resources.freeSpace"))
               .icon("ds-cluster-free-resources-legend vx-property-view-key-legend-icon")
               .exit()
               .valueBuilder()
               .text(data.formattedFreeSpace);
         sectionBuilder.property(this.i18nService.getString("DsClusterUi",
               "dscluster.resources.capacity"),
               data.formattedCapacity);
         sectionBuilder.property(this.i18nService.getString("DsClusterUi",
               "dscluster.resources.datastores"),
               data.datastoreCount);
         sectionBuilder.property(this.i18nService.getString("DsClusterUi",
               "dscluster.resources.largestFreeSpace"),
               data.formattedDsLargestFreeSpace);

         return builder.build();
      }

      public getMinCapacityLabel(formattedCapacity: string): string {
         return DsClusterServicesAndResourcesService.MIN_CAPACITY_VALUE +
               this.getCapacityUnit(formattedCapacity);
      }

      private getCapacityUnit(formattedCapacity: string): string {
         let capacityParts: string[] = formattedCapacity.split(" ");
         return capacityParts[1];
      }

      public buildServicesPropertyViewData(data: any, objId: string, navigator: string): Object {
         let builder: any = this.vxPropertyViewService.createPropertyViewBuilder();

         let recommendationlinkObj: any = {
            extensionId: "vsphere.core.dsCluster.monitor.sdrs.recommendations",
            objectId: objId,
            navigator: navigator
         };
         let faultslinkObj: any = {
            extensionId: "vsphere.core.dscluster.monitor.sdrs.faults.masterDetailsView",
            objectId: objId,
            navigator: navigator
         };

         let sectionBuilder: any = builder
               .category("")
               .section("");

         sectionBuilder.property(this.i18nService.getString("DsClusterUi",
               "sdrs.config.automationLabel"),
               data.automationLevelText);
         sectionBuilder.property(this.i18nService.getString("DsClusterUi",
               "dscluster.services.iometrics"),
               data.ioMetricInclusion);
         sectionBuilder.property(this.i18nService.getString("DsClusterUi",
               "dscluster.services.spaceThreshold"),
               data.spaceThreshold);
         sectionBuilder.property(this.i18nService.getString("DsClusterUi",
               "dscluster.services.ioThreshold"),
               data.ioLatencyThreshold);
         sectionBuilder.propertyBuilder()
               .keyBuilder()
               .text(this.i18nService.getString("DsClusterUi",
                     "dscluster.services.recommendationsLabel"))
               .exit()
               .valueBuilder()
               .text(data.recommendationsCount)
               .link(recommendationlinkObj);
         sectionBuilder.propertyBuilder()
               .keyBuilder()
               .text(this.i18nService.getString("DsClusterUi",
                     "dscluster.services.drsFaultsLabel"))
               .exit()
               .valueBuilder()
               .text(data.faultsCount)
               .link(faultslinkObj);

         return builder.build();
      }
   }

   angular.module("com.vmware.vsphere.client.dsCluster")
         .service("dsClusterServicesAndResourcesService", DsClusterServicesAndResourcesService);
}
