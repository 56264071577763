/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace dvs_ui {

   import IRootScopeService = angular.IRootScopeService;
   import DvPortgroupCreateSpec = com.vmware.vsphere.client.dvportgroup.DvPortgroupCreateSpec;

   export class DvpgCreateWizardService {

      public static $inject = [
            "$rootScope",
            "i18nService",
            "vuiWizardService",
            "mutationService",
            "dvpgCreateWizardSpecFactory",
            "dvpgCreateWizardConstants",
            "dvpgCreateWizardFlowManager",
            "dvpgCreateWizardPages"
      ];

      constructor(private $rootScope: IRootScopeService,
                  private i18nService: any,
                  private vuiWizardService: any,
                  private mutationService: any,
                  private specFactory: DvpgCreateWizardSpecFactory,
                  private wizardConstants: any,
                  private dvpgCreateWizardFlowManager: DvpgCreateWizardFlowManager,
                  private wizardPages: DvpgCreateWizardPages) {
      }

      /**
       * Shows the New Distributed Port Group wizard.
       */
      public show(objectId: string): void {

         let wizardScope: any = this.$rootScope.$new();

         wizardScope.model = this.initWizardDataModel(objectId);
         wizardScope.flags = this.initWizardFlags();

         wizardScope.wizardConfig = {
            title: this.i18nService.getString("DvsUi", "dvpg.create.title"),
            loadingMessage: this.i18nService.getString("CommonUi", "wizard.loading"),
            onFinish: ():boolean => {
               let spec: DvPortgroupCreateSpec =
                     this.specFactory.buildDvpgCreateSpec(wizardScope.model);

               this.mutationService.add(spec._type, spec);
               return true;
            }
         };

         wizardScope.wizardConfig.pages = this.wizardPages.createPages(wizardScope);

         this.dvpgCreateWizardFlowManager.init(wizardScope);

         wizardScope.wizardConfig.cssClass = "dvpg-create-wizard";
         this.vuiWizardService({
               scope: wizardScope,
               configObjectName: "wizardConfig"
         }).show();
      }

      private initWizardDataModel(contextObjId: string): DvpgCreateWizardModel {
         let model: DvpgCreateWizardModel = new DvpgCreateWizardModel();
         model.dvsId = contextObjId;
         model.contextObjId = contextObjId;
         model.name = this.i18nService.getString(
               "DvsUi", "dvpg.create.nameAndLocation.defaultName");
         model.isPolicyConfigurationEnabled = false;
         return model;
      }

      private initWizardFlags(): DvpgCreateWizardFlags {
         let flags: DvpgCreateWizardFlags = new DvpgCreateWizardFlags();
         flags.isNameAndLocationPageInitialized = false;
         flags.isGeneralPropertiesPageInitialized = false;
         return flags;
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .service("dvpgCreateWizardService", DvpgCreateWizardService);
}
