(function() {
   'use strict';

   angular
      .module('com.vmware.vsphere.client.vm')
      .controller('VmDatastoreLocationBrowserController',
         VmDatastoreLocationBrowserController);

   VmDatastoreLocationBrowserController.$inject = [
      '$scope',
      'i18nService'
   ];

   function VmDatastoreLocationBrowserController(
      $scope,
      i18nService
   ) {

      var self = this;
      var i18n = i18nService.getString.bind(i18nService, 'Common');
      $scope.i18n = i18nService.getString;

      self.descriptionText = i18n('StorageLocatorControl.SelectDatastoreDesc');
   }
})();
