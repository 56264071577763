/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
(function () {
   'use strict';
   angular.module('com.vmware.vsphere.client.storage')
         .controller('VmfsPartitionConfigurationPageController', [
            '$scope',
            'i18nService',
            '$q',
            function ($scope, i18nService) {
               var self = this;

               self.getString = i18nService.getString;

               self.configurationItems = [];

               self.selectedConfigurationItem =
                     $scope.vmfsWizardManager.getSelectedVmfsDatastoreOptionItem();

               self.labels = {
                  comboBox: i18nService.getString('StorageUi', 'increaseDatastoreWizard.partitionConfigurationPage.partitionConfiguration'),
                  slider: i18nService.getString('StorageUi', 'addDatastoreWizard.vmfsPartitionConfigPage.datastoreSize'),
                  gb: i18nService.getString('StorageUi', 'increaseDatastoreWizard.partitionConfigurationPage.gb')
               };

               self.isDataLoaded = false;
               self.configurationError = null;

               $scope.wizardConfig.loading = true;
               $scope.vmfsWizardManager.requestVmfsCreateOptionItems().then(
                     function (result) {
                        // Note that the directive is modifying its 'options' field,
                        // passed by its clients, adding the default option in its linking phase.
                        // This means that once the directive is initialized with a given set
                        // of options, it adds the default one. If, however, this set is changed
                        // afterwards, the default one is not added. This is why we have
                        // ng-if="ctrl.isDataLoaded" for the directive in our view.
                        // This way we only initialize the directive once we have the data.
                        self.configurationItems = result.optionItems;
                        self.diskPartitionInfo = result.diskPartitionInfo;
                        self.vmfsBlockSizeOptions = result.blockSizeOptions;
                        self.isDataLoaded = true;
                        if (!self.configurationItems.length) {
                           self.configurationError = i18nService.getString('StorageUi', 'storage.wizards.vmfsDiskError');
                        }
                        if (self.selectedConfigurationItem) {
                           self.selectedConfigurationItem = self.configurationItems.find(function(data) {
                              return data.id && _.isEqual(data.id, self.selectedConfigurationItem.id);
                           });
                        }
                        $scope.wizardConfig.loading = false;
                     }, $scope.removeLoadingAndShowError);

               self.onSelectedOptionChanged = function(configurationItem) {
                  if (configurationItem ===
                        $scope.vmfsWizardManager.getSelectedVmfsDatastoreOptionItem()
                        && configurationItem
                        && configurationItem.sizeToAdd === $scope.vmfsWizardManager.getDatastoreSizeInGb()) {
                     return;
                  }

                  if (!configurationItem ||
                        configurationItem.isSelectConfigurationOption === true) {
                     $scope.vmfsWizardManager.setSelectedVmfsDatastoreOptionItem(null);
                  } else {
                     $scope.vmfsWizardManager.setSelectedVmfsDatastoreOptionItem(configurationItem);
                     // Current function gets fired by the directive when either
                     // (1) partition configuration option item or
                     // (2) datastore size get changed.
                     // Note that the updated datastore size is passed in as configurationItem.sizeToAdd.
                     $scope.vmfsWizardManager.setDatastoreSizeInGb(configurationItem.sizeToAdd);
                  }
               };

            }
         ]);
})();
