namespace h5_vm {
   import VmDisplayMode = h5_vm.VmDisplayMode;

   export class VmHardwareVirtualDiskLocationController
         implements ng.IComponentController {
      public static $inject: string[] = [
         'vmHardwareVirtualDiskLocationService',
         'i18nService'];

      vmStorageBrowserMetadata: VmStorageBrowserMetadata;
      vmStorageProfileMetadata: VmStorageProfileMetadata;
      displayMode: VmDisplayMode;
      onChange: Function;
      vmId: string;
      showStoragePods: boolean;
      diskLocationError: boolean;
      datastore: any;
      selectedStorage: any;

      private diskLocationErrorConfig: any;
      private incorrectDatastore: any;
      private locationOptions: any;
      private selected: any;
      private prevSelection: any;
      private isCreateMode: boolean;

      constructor(private vmHardwareVirtualDiskLocationService:
                     VmHardwareVirtualDiskLocationService,
                  private i18nService: any) {
      }

      $onInit() {
         this.setLocationContext(this.selectedStorage);
      }

      $onChanges(changes: any) {
      }

      public getErrorStyle() {
         return this.vmHardwareVirtualDiskLocationService
               .getErrorStyle(this.diskLocationErrorConfig);

      }

      onLocationChange = (newValue: any, oldValue: any) => {
         this.prevSelection = oldValue;
         this.vmHardwareVirtualDiskLocationService.onLocationChange(
               this.selected,
               this.vmStorageBrowserMetadata,
               this.vmStorageProfileMetadata,
               this.vmId,
               this.showStoragePods
         ).then((result: any) => {

            if (this.vmHardwareVirtualDiskLocationService
                        .isDifferent(this.selected, result.selectedStorageItem)) {

               this.setErrorMessage(result);
               const locationOptionInfo =
                  this.vmHardwareVirtualDiskLocationService
                     .buildLocationOptions(result.selectedStorageItem);

               this.locationOptions = locationOptionInfo.options;
               this.selected = result.selectedStorageItem;
               this.onChange({
                  selectedStorageProfile: result.selectedStorageProfile,
                  selectedStorageItem: result.selectedStorageItem
               });
            }
         }, () => {
            this.selected = this.prevSelection;
         });
      };

      setErrorMessage = (result: any) => {
         let message = this.vmHardwareVirtualDiskLocationService.getErrorMessage(result, this.datastore,
            this.vmStorageBrowserMetadata.storageData.storageLocatorItemsData);
         this.diskLocationError = !!message;
         this.diskLocationErrorConfig = {
            message: message,
            isVisible: this.diskLocationError
         };
      };

      private setLocationContext(selectedStorage?: any) {
         const locationOptionInfo = this.vmHardwareVirtualDiskLocationService
               .buildLocationOptions(selectedStorage);
         this.locationOptions = locationOptionInfo.options;
         this.selected = locationOptionInfo.selection;
         this.prevSelection = this.selected;
         this.isCreateMode = this.displayMode === VmDisplayMode.CreateMode;
         this.diskLocationErrorConfig = {
            message:  this.i18nService.getString('VmUi',
               'DiskConfig.RDMUnsupported'),
            isVisible: this.diskLocationError
         };
      }
   }

   export class VmHardwareVirtualDiskLocation implements ng.IComponentOptions {
      bindings: any;
      controller: any;
      templateUrl: string;

      constructor() {
         /**
          * onChange                   on-change callback
          * displayMode                Display mode of location [Create/Read]
          * vmStorageBrowserMetadata   data used by the storage-locator
          * vmStorageProfileMetadata   data used to show the storage
          *                            profiles (policies)
          */
         this.bindings = {
            onChange: '&',
            displayMode: '<',
            ariaLabel: '<',
            vmStorageBrowserMetadata: '<',
            vmStorageProfileMetadata: '<',
            vmId: '<',
            showStoragePods: '<',
            diskLocationError: '=',
            datastore: '<',
            selectedStorage: '<'
         };
         this.controller = VmHardwareVirtualDiskLocationController;
         this.templateUrl = 'vm-ui/resources/vm/views/components/' +
               'vmHardwareVirtualDiskLocation/vm-hardware-virtual-disk-location.html';
      }

   }

   angular.module('com.vmware.vsphere.client.vm')
         .component('vmHardwareVirtualDiskLocation', new VmHardwareVirtualDiskLocation());
}
