/**
 * Created by vmware on 1/13/17.
 */
namespace h5_vm {
   import CpuIdInfo = com.vmware.vim.binding.vim.host.CpuIdInfo;
   import ConfigSpec$CpuIdInfoSpec = com.vmware.vim.binding.vim.vm.ConfigSpec$CpuIdInfoSpec;
   import ArrayUpdateSpec$Operation = com.vmware.vim.binding.vim.option.ArrayUpdateSpec$Operation;
   import CpuIdMaskConstants = h5_vm.CpuIdMaskConstants;
   import VmConfigContext = com.vmware.vsphere.client.vm.config.VmConfigContext;
   export class CpuService {
      public static $inject: string[] = ['i18nService', '$injector'];

      private readonly NX_FLAG_NOTFOUND: string = "notfound";
      private readonly EXPOSE_NX_FLAG_TO_GUEST: string;
      private readonly HIDE_NX_FLAG_FROM_GUEST: string;
      private readonly KEEP_NX_FLAG_ADVANCED: string;
      private readonly cpuIdMaskSettings: Array<string>;
      private readonly cpuIdMaskSettingsWithAdvanced: Array<string>;
      private readonly REG_EXP_ILLEGAL_CHARACTERS: RegExp;
      static readonly NX_LEVEL: string = "-2147483647";

      constructor(private i18nService: any, private $injector: any) {
         this.EXPOSE_NX_FLAG_TO_GUEST = this.i18nService.getString("VmUi", "CpuIdMask.exposed");
         this.HIDE_NX_FLAG_FROM_GUEST = this.i18nService.getString("VmUi", "CpuIdMask.hidden");
         this.KEEP_NX_FLAG_ADVANCED = this.i18nService.getString("VmUi", "CpuIdMask.advanced");
         this.cpuIdMaskSettings = [this.HIDE_NX_FLAG_FROM_GUEST, this.EXPOSE_NX_FLAG_TO_GUEST];
         this.cpuIdMaskSettingsWithAdvanced = [this.HIDE_NX_FLAG_FROM_GUEST, this.EXPOSE_NX_FLAG_TO_GUEST, this.KEEP_NX_FLAG_ADVANCED];
         this.REG_EXP_ILLEGAL_CHARACTERS = /[^-01TFXHR]/g;
      }

      public getCpuIdMaskSettingOptions(withAdvanced: boolean = false): Array<string> {
         return !withAdvanced ? this.cpuIdMaskSettings : this.cpuIdMaskSettingsWithAdvanced;
      }

      public getCpuIdMaskSettingForVm(vmConfigContext: VmConfigContext) {
         return this.isCpuIdMaskExposed(vmConfigContext) ? this.EXPOSE_NX_FLAG_TO_GUEST : this.HIDE_NX_FLAG_FROM_GUEST;
      }

      public getCpuIdMaskFlagSelection(flag: string) {
         switch (flag) {
            case CpuIdMaskConstants.NXFLAG_EXPOSED:
               return this.EXPOSE_NX_FLAG_TO_GUEST;
            case CpuIdMaskConstants.NXFLAG_HIDDEN:
               return this.HIDE_NX_FLAG_FROM_GUEST;
            case CpuIdMaskConstants.NXFLAG_ADVANCED:
               break;
            default:
               break;
         }
         return this.KEEP_NX_FLAG_ADVANCED;
      }

      public determineCpuIdMaskFlag(vmConfigContext:any): string {
         var advancedCpuIdMaskService = this.$injector.get('vscAdvancedCpuIdMaskService');
         return advancedCpuIdMaskService.determineNxFlagStateFromConfigContext(vmConfigContext);
      }

      public isCpuIdMaskExposed(vmConfigContext:any):boolean {
         let mask:CpuIdInfo, edxRegisterValue:string;
         if (this.hasCpuFeatureMaskInfo(vmConfigContext)) {
            mask = vmConfigContext.config.cpuFeatureMask[0];
         } else {
            mask = vmConfigContext.environment.configOption.guestOSDescriptor[0].cpuFeatureMask[0];
         }

         if (mask.edx) {
            edxRegisterValue = this.stripIllegalCharacters(mask.edx);
            return edxRegisterValue[CpuIdMaskConstants.NX_CHAR_POS] === CpuIdMaskConstants.CHAR_NX_SHOW;
         }
         return false;
      }

      public updateCpuIdMaskSetting(selectedSetting:string,
                                    vmConfigContext: VmConfigContext):void {
         let flagValue: string;
         switch (selectedSetting) {
            case this.EXPOSE_NX_FLAG_TO_GUEST:
               flagValue = CpuIdMaskConstants.CHAR_NX_SHOW;
               break;
            case this.HIDE_NX_FLAG_FROM_GUEST:
               flagValue = CpuIdMaskConstants.CHAR_NX_HIDE;
               break;
            default:
               flagValue = '-';
         }

         this.updateMaskForVendor(vmConfigContext, flagValue, CpuIdMaskConstants.VENDOR_AMD);
         this.updateMaskForVendor(vmConfigContext, flagValue, CpuIdMaskConstants.VENDOR_OTHER);
      }

      private updateMaskForVendor(vmConfigContext: VmConfigContext, flagValue: string,
                                  vendor:any) {
         let cpuIdInfo:any;
         if (this.hasCpuFeatureMaskInfo(vmConfigContext)) {
            cpuIdInfo = this.getCpuIdInfoForVendor(vendor, vmConfigContext);
         }
         cpuIdInfo = cpuIdInfo || this.createCpuIdInfoForVendor(vendor,vmConfigContext);
         cpuIdInfo.edx = cpuIdInfo.edx || CpuIdMaskConstants.REG_USE_GOS;
         cpuIdInfo.edx = this.updateRegisterWithFlag(cpuIdInfo.edx, flagValue);
      }

      private getKeyForCpuIdInfo(cpuIdInfo: CpuIdInfo): string {
         let level: string = cpuIdInfo.level.toString();
         let vendor: string = cpuIdInfo.vendor || '';
         return level + vendor;
      }

      private isItemPresent(map:{[key: string]: CpuIdInfo}, key:string): boolean {
         if(map[key]) {
            return true;
         }
         return false;
      }

      public createCpuIdInfoDelta(originalCpuFeatureMaskValues:CpuIdInfo[], currentCpuFeatureMaskValues:CpuIdInfo[]):ConfigSpec$CpuIdInfoSpec[] | null {
         let cpuIdInfoDelta:ConfigSpec$CpuIdInfoSpec[] = [];
         let originalItemsMap:{[key: string]: CpuIdInfo} = {};
         let cpuIdInfoSpec:ConfigSpec$CpuIdInfoSpec;

         _.each(originalCpuFeatureMaskValues, (originalItem: CpuIdInfo) => {
            originalItemsMap[this.getKeyForCpuIdInfo(originalItem)] = originalItem;
         });

         let itemsToRemoveOrEdit: CpuIdInfo[] = this.filterModifiedItems(currentCpuFeatureMaskValues, originalItemsMap);
         _.each(itemsToRemoveOrEdit, (cpuIdInfo:CpuIdInfo) => {
            if (!this.isCpuIdInfoEmpty(cpuIdInfo)) {
               cpuIdInfoSpec = this.createCpuIdInfoSpecForOperation(cpuIdInfo,CpuIdMaskConstants.OPERATION_EDIT);
            } else {
               cpuIdInfoSpec = this.createCpuIdInfoSpecForOperation(cpuIdInfo,CpuIdMaskConstants.OPERATION_REMOVE);
            }
            cpuIdInfoDelta.push(cpuIdInfoSpec);
         });

         let itemsToAdd:CpuIdInfo[] = _.difference(currentCpuFeatureMaskValues, itemsToRemoveOrEdit);
         _.each(itemsToAdd, (cpuIdInfo: CpuIdInfo) => {
            if (!this.isCpuIdInfoEmpty(cpuIdInfo)) {
               cpuIdInfoSpec = this.createCpuIdInfoSpecForOperation(cpuIdInfo,CpuIdMaskConstants.OPERATION_ADD);
               cpuIdInfoDelta.push(cpuIdInfoSpec);
            }
         });
         return cpuIdInfoDelta;
      }

      private filterModifiedItems(modifiedItems: com.vmware.vim.binding.vim.host.CpuIdInfo[], originalItemsMap: {[id: string]: any}) {
         if (_.isEmpty(modifiedItems)) {
            return [];
         }
         return modifiedItems.filter((modifiedItem: CpuIdInfo) => {
            if (!this.isItemPresent(originalItemsMap, this.getKeyForCpuIdInfo(modifiedItem))) {
               return false;
            }

            let originalItem: CpuIdInfo = originalItemsMap[this.getKeyForCpuIdInfo(modifiedItem)];
            return !_.isEqual(modifiedItem, originalItem);
         });
      }

      private createCpuIdInfoSpecForOperation(cpuIdInfo: CpuIdInfo, operation:ArrayUpdateSpec$Operation): ConfigSpec$CpuIdInfoSpec {
         let cpuIdInfoSpec: ConfigSpec$CpuIdInfoSpec = new ConfigSpec$CpuIdInfoSpec();
         cpuIdInfoSpec.info = new CpuIdInfo();
         cpuIdInfoSpec.info._type = cpuIdInfo._type;
         cpuIdInfoSpec.info.vendor = cpuIdInfo.vendor;
         cpuIdInfoSpec.info.eax = this.padForVmodl(cpuIdInfo.eax);
         cpuIdInfoSpec.info.ebx = this.padForVmodl(cpuIdInfo.ebx);
         cpuIdInfoSpec.info.ecx = this.padForVmodl(cpuIdInfo.ecx);
         cpuIdInfoSpec.info.edx = this.padForVmodl(cpuIdInfo.edx);
         cpuIdInfoSpec.info.level = cpuIdInfo.level;
         cpuIdInfoSpec.operation = operation;
         return cpuIdInfoSpec;
      }

      private isCpuIdInfoEmpty(cpuIdInfo: CpuIdInfo): boolean {
         if (_.isEmpty(cpuIdInfo.eax) &&
               _.isEmpty(cpuIdInfo.ebx) &&
               _.isEmpty(cpuIdInfo.ecx) &&
               _.isEmpty(cpuIdInfo.edx)) {
            return true;
         }
         return false;
      }

      private updateRegisterWithFlag(registerValue: string, flagValue: string): string {
         const strippedValue:string = this.stripIllegalCharacters(registerValue);
         const leftValue:string = strippedValue.substring(0, CpuIdMaskConstants.NX_CHAR_POS);
         const rightValue:string = strippedValue.substring(CpuIdMaskConstants.NX_CHAR_POS+1, strippedValue.length);
         return this.padForVmodl(leftValue + flagValue + rightValue);
      }

      private getCpuIdInfoForVendor(vendor: any, vmConfigContext: VmConfigContext): any {
         let cpuIdInfo: CpuIdInfo = _.find(vmConfigContext.config.cpuFeatureMask, (cpuIdInfo: CpuIdInfo) => {
            if (vendor === CpuIdMaskConstants.VENDOR_AMD) {
               return cpuIdInfo.vendor === CpuIdMaskConstants.VENDOR_AMD;
            } else {
               return cpuIdInfo.vendor !== CpuIdMaskConstants.VENDOR_AMD;
            }
         });
         return cpuIdInfo;
      }

      private hasCpuFeatureMaskInfo(vmConfigContext: VmConfigContext): boolean {
         if(vmConfigContext.config.cpuFeatureMask && vmConfigContext.config.cpuFeatureMask.length > 0) {
            return true;
         }
         return false;
      }

      private createCpuIdInfoForVendor(vendor: string, vmConfigContext: VmConfigContext) {
         let cpuIdInfo:CpuIdInfo =  new CpuIdInfo();
         cpuIdInfo.eax = CpuIdMaskConstants.REG_USE_GOS;
         cpuIdInfo.ebx = CpuIdMaskConstants.REG_USE_GOS;
         cpuIdInfo.ecx = CpuIdMaskConstants.REG_USE_GOS;
         cpuIdInfo.edx = CpuIdMaskConstants.REG_USE_GOS;
         cpuIdInfo.level = CpuIdMaskConstants.NX_LEVEL;
         if(vendor === CpuIdMaskConstants.VENDOR_AMD) {
            cpuIdInfo.vendor = vendor;
         }

         cpuIdInfo._type = CpuIdMaskConstants.CPUIDINFO_TYPE;
         vmConfigContext.config.cpuFeatureMask.push(cpuIdInfo);
         return cpuIdInfo;
      }

      private stripIllegalCharacters(registerValue: string):string {
         if (_.isEmpty(registerValue)) {
            return '';
         }
         let strippedRegisterValue:string = registerValue.toUpperCase();
         return strippedRegisterValue.replace(this.REG_EXP_ILLEGAL_CHARACTERS,'');
      }

      private padForVmodl(registerValue: string):any {
         if(_.isEmpty(registerValue)) {
            return '';
         }
         var strippedValue = this.stripIllegalCharacters(registerValue);
         return strippedValue.substr(0,4) + " " + strippedValue.substr(4,4) + " " +
               strippedValue.substr(8,4) + " " + strippedValue.substr(12,4) + " " +
               strippedValue.substr(16,4) + " " + strippedValue.substr(20,4) + " " +
               strippedValue.substr(24,4) + " " + strippedValue.substr(28,4);
      }

   }

   angular.module('com.vmware.vsphere.client.vm').service('cpuService', CpuService);
}
