/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace dvs_ui {

   import DvPortgroupModel = com.vmware.vsphere.client.h5.network.dvs.addhost.model.DvPortgroupModel;
   export class AssignUplinkComponent {

      public bindings: any;
      public controller: any;
      public templateUrl: string;

      constructor() {
         this.bindings = {
            config: "<"
         };

         this.controller = AssignUplinkController;
         this.templateUrl = 'dvs-ui/resources/dvs/addhost/components/assignUplink/assignUplinkTemplate.html';
      }
   }

   export class AssignUplinkComponentConfig {

      public uplinkPortgroups: DvPortgroupModel[];

      public uplinkMappings: [string /*port*/, string | undefined /*pnic*/, boolean /*isLagPort*/][];

      public selectedUplinkPortgroup: DvPortgroupModel | undefined;

      public selectedUplink: string | undefined;

      /**
       * The names of the lags to differentiate them from lag ports.
       */
      public lagNames: string[];
   }

   export class ApplyToAllSignPostConfig {

      public applyToAllSignPostTitle: string;

      public applyToAllSingPostMessage: string;

      public applyToAllSignPostIcon: string;
   }

   class AssignUplinkController {

      static $inject = [
         "vuiConstants",
         "i18nService",
         "columnRenderersRegistry"
      ];

      public config: AssignUplinkComponentConfig;

      public datagridOptions: any;

      public preselectComparator = (item: any) => this.uplinkSelectedByDefault === item;

      private uplinkSelectedByDefault: [string, string | undefined];

      constructor(private vuiConstants: any,
                  private i18nService: any,
                  private columnRenderersRegistry: any) {
      }

      public $onInit() {
         let self: AssignUplinkController = this;

         let _selectedItems: any[] = [];

         this.datagridOptions = {
            height: "100%",
            pageConfig: {
               hidePager: false
            },
            columnDefs: this.buildColumnDefinitions(),
            sortMode: this.vuiConstants.grid.sortMode.SINGLE,
            selectionMode: this.vuiConstants.grid.selectionMode.SINGLE,
            data: this.getData(),
            get selectedItems() {
               return _selectedItems;
            },
            set selectedItems(selectedItems: any[]) {
               _selectedItems = selectedItems;
               if (_selectedItems && _selectedItems.length === 1) {
                  self.config.selectedUplink = _selectedItems[0][0];
               } else {
                  self.config.selectedUplink = undefined;
               }
            },
            resizable: true
         };

         this.assignDefaults();
      }

      private getData(): [string | undefined, string | undefined][] {
         let data: [string, string | undefined][] = [];
         data.push.apply(data, this.config.uplinkMappings);
         //push the auto-assign item
         data.push([DvsAddHostWizardConstants.AUTO_ASSIGN, undefined]);
         return data;
      }

      private assignDefaults(): void {
         if (this.config.selectedUplinkPortgroup === undefined) {
            this.config.selectedUplinkPortgroup = this.config.uplinkPortgroups[0];
         }
         if (this.config.selectedUplink !== undefined) {
            this.uplinkSelectedByDefault = _.find(this.datagridOptions.data,
                  (item: [string, string | undefined]): boolean => item[0] === this.config.selectedUplink);
         } else {
            //select first free uplink
            this.uplinkSelectedByDefault = _.find(this.datagridOptions.data,
                  (item: [string, string | undefined]): boolean => item[1] === undefined);
         }
      }

      private buildColumnDefinitions() {
         let textRenderer: any =
               this.columnRenderersRegistry.getColumnRenderer("text");
         let iconTextRenderer: any =
               this.columnRenderersRegistry.getColumnRenderer("icon-text");
         let itemToDisplayItem = (item: [string, string | undefined, boolean]) => {
            let uplinkLabel = item[0];
            let pnicLabel = item[1];
            if (uplinkLabel === DvsAddHostWizardConstants.AUTO_ASSIGN) {
               //auto-assign
               uplinkLabel = this.i18nService.getString("DvsUi", "SelectPhysicalAdaptersPage.autoAssign");
               pnicLabel = "";
            } else if (pnicLabel === undefined) {
               // no mapped pnic
               pnicLabel = this.i18nService.getString("DvsUi", "SelectPhysicalAdaptersPage.noUplink");
            } // Otherwise the entry is a LAG.

            let displayItem = {
               uplink: item[0],
               uplinkDisplayValue: uplinkLabel,
               pnicDisplayValue: pnicLabel,
               icon: "vui-icon" /* Fake icon, used only to indent label */
            };
            return displayItem;
         };
         return [{
            displayName: this.i18nService.getString(
                  "DvsUi", "UplinkPortMappingAssignDialog.uplinkNameHeader"),
            width: "150px",
            template: (item: [string /*port*/, string | undefined /*pnic*/, boolean /*isLagPort*/]): string => {
               let isIndented: boolean = item[2];

               if (isIndented) {
                  return iconTextRenderer(["icon", "uplinkDisplayValue"],
                        itemToDisplayItem(item));
               } else if (item[1] === "") {
                  // A LAG.
                  let displayItem: any = itemToDisplayItem(item);
                  return `<span class='dvs-assign-uplink-dialog-lag-group'>${displayItem.uplinkDisplayValue}</span>`;
               } else {
                  return textRenderer(["uplinkDisplayValue"], itemToDisplayItem(item));
               }
            }
         }, {
            displayName: this.i18nService.getString(
                  "DvsUi", "UplinkPortMappingAssignDialog.assignedAdapterHeader"),
            width: "150px",
            template: (item: [string, string | undefined, boolean]): string => {
               return textRenderer(["pnicDisplayValue"], itemToDisplayItem(item));
            }
         }];
      }

   }

   angular.module("com.vmware.vsphere.client.dvs")
         .component("assignUplink", new AssignUplinkComponent());
}
