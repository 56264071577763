/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace dvs_ui {

   export class DvsAddSpanSessionWizardModel {
      private _sessionType: string;

      public retrievedSessionName: string;

      public dvsUrn: string;

      public supportedSessionTypes: string[];

      public pageValidators: { [pageId: string]: DvsSpanSessionPageValidator } = {};

      public propertiesPageModel: DvsSpanSessionPropertiesPageModel =
            new DvsSpanSessionPropertiesPageModel();

      public selectSourcesPageModel: DvsSpanSessionSelectSourcesPageModel =
            new DvsSpanSessionSelectSourcesPageModel();

      public selectDestinationsPageModel: DvsSpanSessionSelectDestinationsPageModel =
            new DvsSpanSessionSelectDestinationsPageModel();

      public get sessionType(): string {
         return this._sessionType;
      }

      /**
       * Resets the page models upon change.
       *
       * @param value The new session type
       */
      public set sessionType(value: string) {
         if (value === this._sessionType) {
            return;
         }
         this._sessionType = value;

         // properties page
         this.propertiesPageModel.sessionName = this.retrievedSessionName;
         this.propertiesPageModel.enabled = false;
         this.propertiesPageModel.mirroredPacketLengthEnabled = false;
         this.propertiesPageModel.mirroredPacketLength = 60;
         this.propertiesPageModel.samplingRate = 1;
         this.propertiesPageModel.description = "";

         switch (this.sessionType) {
            case DvsSpanSessionConstants.SESSION_TYPE.L3_SOURCE: {
               this.propertiesPageModel.encapsulationType =
                     DvsSpanSessionConstants.ENCAPSULATION_TYPE.GRE;
               this.propertiesPageModel.sessionId = 0;
               this.propertiesPageModel.tcpIpStack =
                     DvsSpanSessionConstants.TCP_IP_STACK.DEFAULT_STACK;
               break;
            }
            case DvsSpanSessionConstants.SESSION_TYPE.REMOTE_MIRROR_SOURCE: {
               this.propertiesPageModel.preserveOriginalVlan = true;
               this.propertiesPageModel.encapsulationVlanId = 1;
               this.propertiesPageModel.normalTrafficAllowed = false;
               break;
            }
            case DvsSpanSessionConstants.SESSION_TYPE.DV_PORT_MIRROR: {
               this.propertiesPageModel.normalTrafficAllowed = false;
               break;
            }
            case DvsSpanSessionConstants.SESSION_TYPE.REMOTE_MIRROR_DEST: {
               this.propertiesPageModel.normalTrafficAllowed = false;
               break;
            }
         }

         this.selectSourcesPageModel.portsListModel.ports = [];
         delete this.selectSourcesPageModel.portsListModel["cachedData"];

         this.selectSourcesPageModel.vlanIdListModel.values = [];
         delete this.selectSourcesPageModel.vlanIdListModel["cachedData"];

         this.selectDestinationsPageModel.portsListModel.ports = [];
         delete this.selectDestinationsPageModel.portsListModel["cachedData"];

         this.selectDestinationsPageModel.ipAddressesListModel.values = [];
         delete this.selectDestinationsPageModel.ipAddressesListModel["cachedData"];
      }
   }
}
