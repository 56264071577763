/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
(function () {
   'use strict';
   angular.module('com.vmware.vsphere.client.storage')
      .controller('NfsNameAndConfigurationPageController', [
         '$scope', 'datastoreConstants', 'vuiConstants', 'i18nService', '$timeout',
         function($scope, datastoreConstants, vuiConstants, i18nService, $timeout) {
            var self = this;

            self.datastoreNameMaxLength = datastoreConstants.maxNameLengthForNfsDatastore;

            self.serverNameTooltipError = '';
            self.serverNameTooltipValidity = 'valid';

            self.isNfs4_1 = function() {
               return $scope.nfsWizardManager.getNfsVersion() === datastoreConstants.nfsVersions.NFS_4_1;
            };

            if ($scope.commonWizardManager.getDatastoreName()) {
               self.datastoreName = $scope.commonWizardManager.getDatastoreName();
            } else {
               // Show loading indicator while retrieving data
               $scope.wizardConfig.loading = true;

               $scope.commonWizardManager.getUniqueDatastoreName().then(function (uniqueName) {
                  self.datastoreName = uniqueName;
                  $scope.commonWizardManager.setDatastoreName(uniqueName);
               }).finally(function() {
                  $scope.wizardConfig.loading = false;
               });
            }

            initializePageData();

            // Workaround which resizes the datagrid after the action bar creation
            $timeout(function () {
               var gridElement = $("div.nfs-servers-grid .k-grid");
               if (gridElement) {
                  var kendoGrid = gridElement.data("kendoGrid");
                  if (kendoGrid) {
                     kendoGrid.resize();
                  }
               }
            }, 0);

            function initializePageData() {
               self.isReadOnly =
                  $scope.nfsWizardManager.getSelectedAccessMode() === datastoreConstants.accessModes.readOnly;
               self.folderName = $scope.nfsWizardManager.getFolderName();
               self.serverName = $scope.nfsWizardManager.getServerName();
               populateServerNamesGrid();
            }

            function populateServerNamesGrid() {
               self.datagridOptions = {
                  height: '100%',
                  pageConfig: {
                     hidePager: false
                  },
                  columnDefs: getColumnDefs(),
                  sortMode: vuiConstants.grid.sortMode.SINGLE,
                  selectionMode: vuiConstants.grid.selectionMode.SINGLE,
                  selectedItems: [],
                  resizable: true,
                  data: _.map($scope.nfsWizardManager.getServerNamesArray(), wrap),
                  actionBarOptions: { actions: [getDeleteAction()] },
                  columnMenu: {
                     sortable: false, // this will hide sort menu items
                     messages: {
                        columns: i18nService.getString('CommonUi', 'listview.showColumnsMenu'),
                        filter: i18nService.getString('CommonUi', 'listview.filterMenu')
                     }
                  }
               };
            }

            self.addServer = function() {
               var errorMessage = $scope.nfsWizardManager.validateServerNames();
               if (!errorMessage) {
                  $scope.nfsWizardManager.addServerNamesAction();

                  self.serverName = ''; //clear the input
                  $("input#serverField").focus(); // focus the field

                  self.datagridOptions.data = _.map($scope.nfsWizardManager.getServerNamesArray(), wrap);
               }

               updateServerNameTooltipError(errorMessage);
            };

            self.addServerIfSpace = function(event) {
               if (event.keyCode === 32) {
                  self.addServer();
               }
            };

            function updateServerNameTooltipError(errorMessage) {
               if (errorMessage) {
                  self.serverNameTooltipError = errorMessage;
                  self.serverNameTooltipValidity = 'invalid';
               } else {
                  self.serverNameTooltipError = '';
                  self.serverNameTooltipValidity = 'valid';
               }
            }

            function wrap(serverName) {
               return { 'serverName': serverName };
            }

            function unwrap(serverNameGridRow) {
               return serverNameGridRow.serverName;
            }

            function getColumnDefs() {
               return [{
                  displayName: i18nService.getString('StorageUi', 'addDatastoreWizard.nfsSettingsPage.serverColumn'),
                  field: 'serverName',
                  template: '#:serverName#'
               }];
            }

            function getDeleteAction() {
               return {
                  tooltipText: i18nService.getString('StorageUi', 'addDatastoreWizard.nfsSettingsPage.server.remove'),
                  label: i18nService.getString('StorageUi', 'addDatastoreWizard.nfsSettingsPage.server.remove'),
                  iconClass: 'vx-icon-removeIcon',
                  enabled: true,
                  onClick: function(){
                     self.datagridOptions.data = _.filter(self.datagridOptions.data, function(item){
                        return item.serverName !== self.datagridOptions.selectedItems[0].serverName;
                     });
                     $scope.nfsWizardManager.setServerNamesArray(_.map(self.datagridOptions.data, unwrap));
                  }
               };
            }

            $scope.$watch(
               function (){
                  return self.datastoreName;
               },
               function (name) {
                  $scope.commonWizardManager.setDatastoreName(name);
               });

            $scope.$watch(
               function(){
                  return self.folderName;
               },
               function(name) {
                  $scope.nfsWizardManager.setFolderName(name);
               });

            $scope.$watch(
               function(){
                  return self.serverName;
               },
               function(newName, oldName) {
                  if (newName !== oldName) {
                     $scope.nfsWizardManager.setServerName(newName);
                     updateServerNameTooltipError(null);
                  }
               });

            $scope.$watch(
               function() {
                  return self.datagridOptions.selectedItems;
               },
               function(items) {
                  var newState = items.length > 0;
                  self.datagridOptions.actionBarOptions.actions[0].enabled = newState;
               });

            $scope.$watch(function(){
               return self.isReadOnly;
            }, function(newValue){
               var accessMode = newValue ? datastoreConstants.accessModes.readOnly : datastoreConstants.accessModes.readWrite;
               $scope.nfsWizardManager.setSelectedAccessMode(accessMode);
            });

            if ($scope.nfsWizardManager.isNfsVersion41()) {
               $scope.$watch(function() {
                  return $scope.nfsWizardManager.getServerNamesArray().join(',');
               }, function(newValue, oldValue) {
                  if (newValue !== oldValue && newValue) {
                     self.datagridOptions.data = _.map($scope.nfsWizardManager.getServerNamesArray(), wrap);
                  }
               });

            }
         }
      ]);
})();
