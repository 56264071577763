namespace h5_vm {
   import IScope = angular.IScope;
   import IQService = angular.IQService;
   import Privileges = h5_vm.Privileges;
   export class VmHardwareVirtualDiskSharesService {
      static $inject: string[] = [
         'vmHardwareUtil'];

      constructor(private vmHardwareUtil: any) {
      }

      public isEditSharesDisabled(privileges: any, createMode: any, diskSharesSupported: any) {
         return !this.hasPrivilegesToEditShares(privileges, createMode) || !diskSharesSupported;
      }

      private hasPrivilegesToEditShares(privileges: any, skipPrivilegeCheck: boolean = false) {
         if(skipPrivilegeCheck) {
            return true;
         }

         return this.vmHardwareUtil.checkPrivileges(privileges, [Privileges.VM_RESOURCE_PRIVILEGE, Privileges.VM_EDITDEVICE_PRIVILEGE]);
      }
   }

   angular.module('com.vmware.vsphere.client.vm')
         .service('vmHardwareVirtualDiskSharesService', VmHardwareVirtualDiskSharesService);
}
