module ds_cluster_ui {

   import IController = angular.IController;

   class AddVmAntiAffinityRuleController implements IController {

      private objectId: string;
      private actionSpecs: any[];
      private datagridActionBarServiceCacheObject: any = null;
      public vmAffinityGridOptions: any;

      static $inject = ["$rootScope", "$scope", "i18nService",
         "datagridActionBarService", "vuiConstants", "columnRenderersRegistry"];

      constructor($rootScope: any, private $scope: any, private i18nService: any,
            private datagridActionBarService: any, private vuiConstants: any,
            private columnRenderersRegistry: any) {
         this.objectId = $rootScope._route.objectId;

         $scope.$watch("$ctrl.vmAffinityGridOptions.selectedItems", () => {
            this.refreshActionBar();
         });
      }

      public $onChanges(changes: any): void {
         if (typeof changes.spec !== "undefined") {
            this.vmAffinityGridOptions =
                  this.getVmAffinityGridOptions(changes.spec.currentValue);

            this.actionSpecs = [{
               actionId: "vsphere.core.dscluster.actions.rule.members.add",
               getActionInvocationContext: () => {
                  return {
                     selectedMembers: changes.spec.currentValue.ruleMembersData,
                     dialogSubTitleObject: {
                        objectId: this.objectId
                     },
                     submitCallback: (dialogOptions: any) => {
                        if (dialogOptions.dialogData.selectedItems !== undefined) {
                           this.vmAffinityGridOptions.data = this.vmAffinityGridOptions
                                 .data.concat(dialogOptions.dialogData.selectedItems);
                           changes.spec.currentValue.ruleMembersData =
                                 this.vmAffinityGridOptions.data;
                        }
                        return true;
                     }
                  };
               }
            }, {
               actionId: "vsphere.core.dscluster.actions.rule.members.remove",
               getActionInvocationContext: () => {
                  return {
                     membersToRemove: this.vmAffinityGridOptions.selectedItems,
                     confirmCallback: () => {
                        this.vmAffinityGridOptions.data =
                              changes.spec.currentValue.ruleMembersData =
                              _.filter(
                                    this.vmAffinityGridOptions.data,
                                    (gridItem: any) => {
                                       return _.every(
                                             this.vmAffinityGridOptions.selectedItems,
                                             (selectedItem: any) => {
                                                return gridItem.id !== selectedItem.id;
                                             });
                                    });
                        return true;
                     }
                  };
               },
               isActionAvailable: (actionDef: any) => {
                  return actionDef.available &&
                        this.vmAffinityGridOptions.selectedItems.length > 0;
               }
            }];

            this.createActionBar();
         }
      }

      private createActionBar(): void {
         this.datagridActionBarService
               .updateActionBar(this.vmAffinityGridOptions, [this.objectId],
                     this.actionSpecs)
               .then((value: any) => {
                  this.datagridActionBarServiceCacheObject = value;
               });
      }

      private refreshActionBar(): void {
         if (!this.datagridActionBarServiceCacheObject) {
            return;
         }

         this.datagridActionBarService.updateActionBar(
               this.vmAffinityGridOptions, [this.objectId], this.actionSpecs,
               this.datagridActionBarServiceCacheObject
         );
      }

      private getVmAffinityGridOptions(spec: any): Object {
         return {
            actionBarOptions: {
               actions: []
            },
            searchable: false,
            resizable: true,
            data: spec.selectedRuleDetails,
            selectionMode: this.vuiConstants.grid.selectionMode.MULTI,
            showCheckboxesOnMultiSelection: false,
            selectedItems: [],
            columnDefs: [{
               displayName: this.i18nService.getString(
                     "Common", "sdrs.rules.config.vmNameHeaderText"),
               field: "name",
               type: "string",
               template: "<span class=\"object\" title=\"#:name#\"><i class=\"#:icon#\")></i>#:name#</span>"
            }, {
               displayName: this.i18nService.getString(
                     "DsClusterUi", "sdrs.rules.config.vmDatastoreHeaderText"),
               field: "rawData.datastoreName",
               template: this.datastoreNameRenderer.bind(this)
            }],
            height: "100%",
            pageConfig: {
               hidePager: true
            }
         };
      }

      private datastoreNameRenderer(data: any): string {
         let objectLinkRenderer: any =
               this.columnRenderersRegistry.getColumnRenderer("datastore-name");

         return objectLinkRenderer([], data.rawData);
      }
   }

   class AddVmAntiAffinityRuleComponent implements ng.IComponentOptions {
      public controller: any;
      public templateUrl: string;
      public bindings: any;

      constructor() {
         this.controller = AddVmAntiAffinityRuleController;
         this.templateUrl =
               "ds-cluster-ui/resources/ds-cluster/views/settings/configuration/rules/" +
               "addVmAntiAffinityRuleComponent.html";
         this.bindings = {
            spec: "<"
         };
      }
   }

   angular.module("com.vmware.vsphere.client.dsCluster").component(
         "addVmAntiAffinityRuleComponent", new AddVmAntiAffinityRuleComponent());
}
