(function() {
   'use strict';

   /**
    * Service that provides cluster HCI capability information.
    */
   angular.module('com.vmware.vsphere.client.host')
      .service('hciCapabilityService', hciCapabilityService);

   hciCapabilityService.$inject = ['dataService', "defaultUriSchemeUtil"];

   function hciCapabilityService(dataService, defaultUriSchemeUtil) {
      var hciSupportMap = {};

      return {
         checkHCISupported: checkHCISupported
      };

      function checkHCISupported(clusterId) {
         var clusterRef =
               defaultUriSchemeUtil.getManagedObjectReference(clusterId);

         if (!hciSupportMap[clusterRef.serverGuid]) {
            var serviceInstanceRef = {
               "value": "ServiceInstance",
               "type": "ServiceInstance",
               "serverGuid": clusterRef.serverGuid
            };
            var serviceInstanceId =
                  defaultUriSchemeUtil.getVsphereObjectId(serviceInstanceRef);

            hciSupportMap[clusterRef.serverGuid] = dataService.getProperties(
                  serviceInstanceId, ["capability.hciWorkflowSupported"])
                  .then(function (data) {
                     return data ? !!data["capability.hciWorkflowSupported"] : false;
                  });
         }

         return hciSupportMap[clusterRef.serverGuid];
      }
   }
})();
