/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace dvs_ui {

   import DvsAddHostHierarchicalListItem = com.vmware.vsphere.client.h5.network.dvs.addhost.hierarchicalList.DvsAddHostHierarchicalListItem;
   import DvsAddHostHierarchicalListVnicItem = com.vmware.vsphere.client.h5.network.dvs.addhost.hierarchicalList.DvsAddHostHierarchicalListVnicItem;
   export class DvsSelectVirtualAdaptersPageService {

      static $inject = [
         "i18nService",
         "columnRenderersRegistry"
      ];

      constructor(private i18nService: any,
                  private columnRenderersRegistry: any) {
      }

      /**
       * Returns the actionBarOptions object for the select virtual adapters page.
       *
       * @param assignPortgroupHandler
       *    The handler for the assign port group button.
       *
       * @param resetChangesHandler
       *    The handler for the reset changes button.
       */
      public getActionBarOptions(assignPortgroupHandler: () => void,
                                 resetChangesHandler: () => void,
                                 viewSettingsHandler: () => void): any {
         return {
            actions: [{
               id: "assign-portgroup-action",
               label: this.i18nService.getString(
                     "DvsUi", "SelectVirtualAdaptersPage.assignPortGroupButtonLabel"),
               tooltipText: this.i18nService.getString(
                     "DvsUi", "SelectVirtualAdaptersPage.assignPortGroupButtonToolTipLabel"),
               // Disabled initially, only enabled when selection occurs.
               enabled: false,
               iconClass: "network-lib-ui-icon-dvPortGroup",
               onClick: assignPortgroupHandler
            }, {
               id: "reset-changes-action",
               label: this.i18nService.getString(
                     "DvsUi", "SelectVirtualAdaptersPage.revertVmkernelButtonLabel"),
               tooltipText: this.i18nService.getString(
                     "DvsUi", "SelectVirtualAdaptersPage.revertVmkernelButtonTooltipLabel"),
               // Disabled initially, only enabled when selection occurs.
               enabled: false,
               iconClass: "network-lib-ui-icon-undo",
               onClick: resetChangesHandler
            }, {
               id: "view-settings-action",
               label: this.i18nService.getString(
                     "DvsUi", "SelectVirtualAdaptersPage.viewSelectedVnicDetailsButtonLabel"),
               tooltipText: this.i18nService.getString(
                     "DvsUi", "SelectVirtualAdaptersPage.viewSelectedVnicDetailsButtonToolTipLabel"),
               // Disabled initially, only enabled when selection occurs.
               enabled: false,
               iconClass: "network-lib-ui-icon-info",
               onClick: viewSettingsHandler
            }]
         };
      }

      /**
       * Reeturns the column definitions for the select virtual adapters page.
       */
      public getColumnDefs(): any[] {
         let iconTextRenderer: any =
               this.columnRenderersRegistry.getColumnRenderer("icon-text");
         let textRenderer: any =
               this.columnRenderersRegistry.getColumnRenderer("text");
         return [{
            field: "label",
            title: this.i18nService.getString(
                  "DvsUi", "SelectVirtualAdaptersPage.hostColumnHeader"),
            // The width is calculated so that it can accommodate a host name
            // that is an IPv6 address, e.g. "2001:0db8:0a0b:12f0:0000:0000:0000:0001".
            width: "260px",
            template: (dataItem: any) => {
               return iconTextRenderer(["icon", "label"], {
                  icon: dataItem.iconClass,
                  label: dataItem.label
               });
            }
         }, {
            field: "inUseBySwitch",
            title: this.i18nService.getString(
                  "DvsUi", "SelectVirtualAdaptersPage.switchColumnHeader"),
            // Calculated so that it can accommodate "Inc Use by Switch" in English
            // locale. Might be trimmed in other locales.
            width: "114px",
            template: (dataItem: DvsAddHostHierarchicalListItem) => {
               return textRenderer(["inUseBySwitch"],
                     dataItem, true /*unescapeCharacters*/);
            }
         }, {
            field: "sourcePortgroup",
            title: this.i18nService.getString(
                  "DvsUi", "SelectVirtualAdaptersPage.sourcePortgroupColumnHeader"),
            // Calculated so that it can accommodate "Source port group" in English
            // locale. The maximum column width so that other column names are not
            // trimmed in English. Typically the port group is the longes identifier
            // (longer than the switch).
            // Might be trimmed in non-English other locales.
            width: "140px",
            template: (dataItem: DvsAddHostHierarchicalListItem) => {
               return textRenderer(["sourcePortgroup"],
                     dataItem, true /*unescapeCharacters*/);
            }
         }, {
            field: "destinationPortgroup",
            title: this.i18nService.getString(
                  "DvsUi", "SelectVirtualAdaptersPage.destPortgroupColumnHeader"),
            template: (dataItem: DvsAddHostHierarchicalListItem) => {
               return textRenderer(["destinationPortgroup"],
                     dataItem, true /*unescapeCharacters*/);
            }
         }];
      }

      /**
       * Returns the schema for the hierarchical grid data model in the
       * select virtual adapters page.
       */
      public getTreeListSchema(): any {
         return {
            model: {
               id: "id",
               expanded: true
            }
         };
      };

      /**
       * Returns true if the provided virtual adapter is assigned to the dvs.
       *
       * @param pnicItem
       *    The item to check.
       */
      public isVnicAssigned(item: DvsAddHostHierarchicalListItem): boolean {
         let vnicItem: DvsAddHostHierarchicalListVnicItem =
               item as DvsAddHostHierarchicalListVnicItem;
         return vnicItem && vnicItem.parentId === vnicItem.onThisSwitchItemId;
      }

      /**
       * Returns true if the provided virtual adapter is modified throughout the
       * lifetime of the wizard session.
       *
       * @param vnicItemWrapper
       *    The item to check.
       */
      public isVnicModified(vnicItemWrapper: DvsAddHostTreeListItemWrapper): boolean {
         let originalVnicItem: DvsAddHostHierarchicalListVnicItem =
               vnicItemWrapper.originalItem as DvsAddHostHierarchicalListVnicItem;
         let updatedItem: DvsAddHostHierarchicalListItem =
               vnicItemWrapper as DvsAddHostHierarchicalListItem;
         let updatedVnicItem: DvsAddHostHierarchicalListVnicItem =
               updatedItem as DvsAddHostHierarchicalListVnicItem;
         return originalVnicItem.parentId !== updatedVnicItem.parentId ||
                     originalVnicItem.label !== updatedVnicItem.label;
      }

      /**
       * Returns the number of updated virtual adapters in the wizard.
       *
       * @param treeListItems
       *    The array of all items on the Select virtual adapters page.
       */
      public getNumberOfUpdatedVirtualAdapters(treeListItems: DvsAddHostTreeListItemWrapper[]): number {
         let reassginedAdapters: DvsAddHostTreeListItemWrapper[] =
               _.filter(treeListItems, (treeListItem: DvsAddHostTreeListItemWrapper) => {
                  return treeListItem.type ===
                        DvsAddHostWizardConstants.nicListItemType.VNIC_TYPE &&
                        this.isVnicModified(treeListItem);
               });
         return reassginedAdapters.length;
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .service("dvsSelectVirtualAdaptersPageService",
               DvsSelectVirtualAdaptersPageService);
}
