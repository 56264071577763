/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace dvs_ui {
   import DvsPortTrafficShapingPolicyModel = com.vmware.vsphere.client.h5.network.dvs.ports.model.DvsPortTrafficShapingPolicyModel;

   export class DvsPortEditTrafficShapingPageModel {
      public overrideOptions: any;

      public trafficShapingPolicy: DvsPortTrafficShapingPolicyModel;

      public isTrafficShapingPageChanged: Function;

      public shouldSkipValidation: Function;

      constructor(trafficShapingPolicy: DvsPortTrafficShapingPolicyModel) {
         if (trafficShapingPolicy) {
            this.trafficShapingPolicy = trafficShapingPolicy;
            this.overrideOptions = {
               inShapingPolicy: trafficShapingPolicy.inShapingOverrided,
               outShapingPolicy: trafficShapingPolicy.outShapingOverrided
            };

            this.isTrafficShapingPageChanged =
                  this.isTrafficShapingPageChangedFunction.bind(this);
            this.shouldSkipValidation =
                  this.shouldSkipValidationFunction.bind(this);
         }
      }

      public isTrafficShapingPageChangedFunction(
            initialData: DvsPortTrafficShapingPolicyModel): boolean {

         if (!initialData) {
            return false;
         }

         return initialData.inShapingOverrided !== this.overrideOptions.inShapingPolicy
               || initialData.outShapingOverrided !== this.overrideOptions.outShapingPolicy
               || !_.isEqual(initialData, this.trafficShapingPolicy);
      }

      public shouldSkipValidationFunction(): boolean {
         return !this.overrideOptions || !this.trafficShapingPolicy
               || (!this.overrideOptions.inShapingPolicy
               && !this.overrideOptions.outShapingPolicy);
      }
   }
}