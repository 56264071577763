namespace h5_vm {

   export class ComputerNameOption {

      public readonly value: string;

      private static readonly ENTER_NAME_VALUE: string = "enterName";
      private static readonly USE_VM_NAME_VALUE: string = "useVmName";
      private static readonly DEPLOY_WIZARD_VALUE: string = "deployWizard";
      private static readonly GENERATE_NAME_VALUE: string = "generateName";

      public static readonly ENTER_NAME: ComputerNameOption =
            new ComputerNameOption(ComputerNameOption.ENTER_NAME_VALUE);
      public static readonly USE_VM_NAME: ComputerNameOption =
            new ComputerNameOption(ComputerNameOption.USE_VM_NAME_VALUE);
      public static readonly DEPLOY_WIZARD: ComputerNameOption =
            new ComputerNameOption(ComputerNameOption.DEPLOY_WIZARD_VALUE);
      public static readonly GENERATE_NAME: ComputerNameOption =
            new ComputerNameOption(ComputerNameOption.GENERATE_NAME_VALUE);

      constructor(value: string) {
         this.value = value;
      }
   }
}
