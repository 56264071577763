/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace dvs_ui {

   export class DvsEditSettingsGeneralPageComponent {

      public bindings: any;
      public controller: any;
      public templateUrl: string;

      constructor() {
         this.bindings = {
            pageModel: "<"
         };

         this.controller = DvsEditSettingsGeneralPageComponentController;

         this.templateUrl = "dvs-ui/resources/dvs/settings/edit/pages/" +
               "dvsEditSettingsGeneralPageComponentTemplate.html";
      }
   }

   class DvsEditSettingsGeneralPageComponentController {

      static $inject = [
            "i18nService",
            "$scope",
            "dvsEditSettingsGeneralPageValidator",
            "clarityModalService",
            "clarityConstants"
      ];

      public pageModel: DvsEditSettingsGeneralPageModel;
      public niocSelectOptions: any;

      private _descriptionValidator: any;
      private _nameValidator: any;
      private _numUplinksValidator: any;

      constructor(public i18nService: any,
                  private $scope: any,
                  private validator: DvsEditSettingsGeneralPageValidator,
                  private clarityModalService: any,
                  private clarityConstants: any) {
         this._descriptionValidator = {
            message: null,
            isVisible: false
         };

         this._nameValidator = {
            message: null,
            isVisible: false
         };

         this._numUplinksValidator = {
            message: null,
            isVisible: false
         };

         this.niocSelectOptions = [{
            label: this.i18nService.getString("NetworkUi", "DvsConfigPage.resmgmt.enabled"),
            value: true
         }, {
            label: this.i18nService.getString("NetworkUi", "DvsConfigPage.resmgmt.disabled"),
            value: false
         }];
      }

      public get editUplinkNamesAvailable(): boolean {
         return !this.numUplinksValidator.isVisible && this.pageModel.hasModifyPrivilege;
      }

      public get descriptionValidator(): any {
         return this._descriptionValidator;
      }

      public onDescriptionChange(): void {
         let message: string = this.validator.getDescriptionError(this.pageModel.description);
         this.descriptionValidator.message = message;
         this.descriptionValidator.isVisible = message !== null;
      }

      public get nameValidator(): any {
         return this._nameValidator;
      }

      public onNameChange(): void {
         let message: string = this.validator.getNameError(this.pageModel.name);
         this.nameValidator.message = message;
         this.nameValidator.isVisible = message !== null;
      }

      public get numUplinksValidator(): any {
         return this._numUplinksValidator;
      }

      public onNumUplinksChange(): void {
         let message: string = this.validator.getNumberOfUplinksError(
               this.pageModel.numUplinks, this.pageModel.maxNumUplinks);
         this.numUplinksValidator.message = message;
         this.numUplinksValidator.isVisible = message !== null;
      }

      public onEditUplinkNamesClick(): void {
         this.pageModel.updateUplinkPortNames();

         let dialogData: any = {
            uplinks: [],
            onUplinkNameChange: (): void => {
               this.onUplinkNameChange(dialogData.uplinks);
            }
         };

         _.forEach(this.pageModel.uplinkPortNames, (uplink: string, index: number): void => {
            dialogData.uplinks.push({
               name: uplink,
               index: index + 1,
               validator: {
                  message: null,
                  isVisible: false
               }
            });
         });

         let modalOptions = {
            title: this.i18nService.getString("NetworkUi", "UplinkNamingDialog.title"),
            defaultButton: "submit",
            size: "sm",
            alerts: [],
            dialogData: dialogData,
            contentTemplate: "dvs-ui/resources/dvs/settings/edit/pages/editUplinkNamesDialogTemplate.html",
            onSubmit: () => {
               let alerts: any = this.onEditUplinkNamesDialogSubmitted(dialogData.uplinks);

               modalOptions.alerts = alerts;

               return !alerts || alerts.length === 0;
            }
         };

         this.clarityModalService.openOkCancelModal(modalOptions);
      }

      private onUplinkNameChange(allUplinks: any): void {
         _.forEach(allUplinks, (uplink: any): void => {
            let message: string = this.validator.getUplinkNameError(uplink.name);
            uplink.validator.message = message;
            uplink.validator.isVisible = message !== null;
         });

         let uplinksByName: any = [];

         _.forEach(allUplinks, (uplinkData: any): void => {
            if (!uplinksByName[uplinkData.name]) {
               uplinksByName[uplinkData.name] = [];
            }
            uplinksByName[uplinkData.name].push(uplinkData);
         });

         for (let name in uplinksByName) {
            _.forEach(uplinksByName[name], (uplink: any):void => {
               uplink.validator.message = (uplinksByName[name].length > 1)
                     ? this.i18nService.getString("NetworkUi", "UplinkNamingDialog.duplicate")
                     : uplink.validator.message;
               uplink.validator.isVisible = uplink.validator.message !== null;
            });
         }
      }

      private onEditUplinkNamesDialogSubmitted(uplinks: any): any {
         let alerts: any = [];

         let hasInvalidUplink: boolean = _.some(uplinks, function (uplink: any): boolean {
            return uplink.validator.isVisible;
         });

         if (hasInvalidUplink) {
            alerts.push({
               text: this.i18nService.getString("NetworkUi", "UplinkNamingDialog.error.validationSummary"),
               type: this.clarityConstants.notifications.type.ERROR
            });
         } else {
            this.pageModel.uplinkPortNames = _.map(uplinks, function (uplink: any): string {
               return uplink.name;
            });
         }

         return alerts;
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .component("dvsEditSettingsGeneralPage",
               new DvsEditSettingsGeneralPageComponent());
}
