namespace storage_ui {
   angular.module("com.vmware.vsphere.client.storage").constant("layoutAreaChartConstants", (function () {
      let BAR_HEIGHT = 100;
      let LABEL_CONFIG = {
         "color": "#333",
         "cursor": "default",
         "fontSize": "12px",
         "fontFamily": "\"ProximaNova\",\"Avenir Next\",\"Helvetica Neue\",Arial,sans-serif,\"Avenir Next\",\"Helvetica Neue\",Arial,sans-serif",
         "pointerEvents": "none",
         "whiteSpace": "nowrap"
      };
      return {
         FREE_PARTITION_COLOR: "rgb(218, 218, 218)",
         SAME_DATASTORE_PARTITION_COLOR: "rgb(57, 105, 145)",
         BAR_HEIGHT: BAR_HEIGHT,
         HIGHCHARTS_CONFIG: {
            "tooltip": {
               "headerFormat": "",
               "style": LABEL_CONFIG
            },
            "chart": {
               "type": "area",
               "margin": [10, -10, 10, -10],
               "spacing": [0, 0, 0, 0]
            },
            "plotOptions": {
               "series": {
                  "animation": false,
                  "states": {
                     "hover": {
                        "enabled": false
                     }
                  }
               },
               "area": {
                  "lineWidth": 0,
                  "fillOpacity": 1
               }
            },
            "xAxis": {visible: false},
            "yAxis": {visible: false, max: BAR_HEIGHT},
            "title": {text: ""},
            "subTitle": {text: ""},
            "credits": {enabled: false},
            "legend": {enabled: false}
         },
         LABEL_CONFIG: LABEL_CONFIG
      };
   }()));
}
