angular.module('com.vmware.vsphere.client.vm').service('creationTypeConstants', function () {
      var constants = {
         CREATE_FROM_SCRATCH: 'createVmFromScratch',
         CLONE_VM_TO_TEMPLATE: 'cloneVmToTemplate',
         CLONE_VM_TO_VM: 'cloneVmToVm',
         CLONE_TEMPLATE_TO_VM: 'cloneTemplateToVm',
         CLONE_TEMPLATE_TO_TEMPLATE: 'cloneTemplateToTemplate',
         CONVERT_TEMPLATE_TO_VM: 'convertTemplateToVm',
         DEPLOY_VM_FROM_TEMPLATE: 'deployVmFromTemplate',
         DEPLOY_VM_FROM_VAPP: 'deployVmFromVapp',
         DEPLOY_VM_FROM_ANY_TEMPLATE: 'deployVmFromAnyTemplate',
         DEPLOY_VM_FROM_VMTX: 'deployVmFromVmtx',
         DEPLOY_VM_FROM_OVF: 'deployVmFromOvf',
         REGISTER_VM: 'registerVm',
         CLONE_AS_VMTEMPLATE_TO_LIBRARY: 'cloneVmToLibrary',
         CLONE_VAPP_AS_VMTEMPLATE_TO_LIBRARY: 'cloneVappToLibrary',
         CLONE_AS_OVFTEMPLATE_TO_LIBRARY: 'cloneAsOvfToLibrary'
      };
      return _.extend(constants, {
         isCloning: function (creationType) {
            return _.include(
               [
                  constants.CLONE_VM_TO_TEMPLATE,
                  constants.CLONE_VM_TO_VM,
                  constants.CLONE_TEMPLATE_TO_VM,
                  constants.CLONE_TEMPLATE_TO_TEMPLATE
               ],
               creationType);
         },
         isCloningFromATemplate: function (creationType) {
            return _.include([
                  constants.CLONE_TEMPLATE_TO_VM,
                  constants.CLONE_TEMPLATE_TO_TEMPLATE
               ],
               creationType);
         },
         isCloningToATemplate: function (creationType) {
            return _.include([
                  constants.CLONE_VM_TO_TEMPLATE,
                  constants.CLONE_TEMPLATE_TO_TEMPLATE
               ],
               creationType);
         },
         isRegisterVm: function(creationType) {
            return creationType === constants.REGISTER_VM;
         },
         isDeployVmFromTemplate: function (creationType) {
            return creationType === constants.DEPLOY_VM_FROM_TEMPLATE;
         },
         isDeployVmFromVapp: function (creationType) {
            return creationType === constants.DEPLOY_VM_FROM_VAPP;
         },
         isDeployVmFromVmtxTemplate: function (creationType) {
            return creationType === constants.DEPLOY_VM_FROM_VMTX;
         },
         isCloningVmToLibrary: function(creationType) {
            return creationType === constants.CLONE_AS_VMTEMPLATE_TO_LIBRARY;
         }
      });
   }
);
