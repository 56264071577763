/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace dvs_ui {

   export class DvsPortsSerivce {

      private i18n: Function;

      private readonly EMPTY_PROPERTY_VALUE: string;

      static $inject = [
         "i18nService",
         "columnRenderersRegistry",
         "networkUtil",
         "vxPropertyViewService"
      ];

      constructor(private i18nService: any,
                  private columnRenderersRegistry: any,
                  private networkUtil: any,
                  private vxPropertyViewService: any) {
         this.i18n = this.i18nService.getString;
         this.EMPTY_PROPERTY_VALUE = this.i18n("DvsUi", "PortList.noData");
      }

      public getColumnDefinitions (): any[] {
         return [{
            displayName: this.i18n("DvsUi", "PortList.keyHeaderText"),
            field: "portId",
            width: "50px",
            template: (dataItem: any) => {
               return this.columnRenderersRegistry.getColumnRenderer("text")
               (["portId"], dataItem, false);
            },
            sortable: (item1: any, item2: any):any => {
               return this.networkUtil.compareNumericAndNonNumericValues(
                     item1, item2, "portId");
            }
         },
            {
               displayName: this.i18n("DvsUi", "PortList.nameHeaderText"),
               field: "name",
               width: "90px",
               template: (dataItem: any) => {
                  return dataItem.name ?
                        this.columnRenderersRegistry.getColumnRenderer("text")
                        (["name"], dataItem, false)
                        : this.EMPTY_PROPERTY_VALUE;
               }
            },
            {
               displayName: this.i18n("DvsUi", "PortList.connecteeHeaderText"),
               field: "connectee",
               width: "90px",
               template: (dataItem: any) => {
                  if (!dataItem.connectedEntity) {
                     dataItem.formattedName = this.EMPTY_PROPERTY_VALUE;
                  } else if (dataItem.connectedEntityType === DvsPortsConstants.VM_VNIC) {
                     dataItem.formattedName = dataItem.connectedEntity;
                  } else {
                     dataItem.formattedName = `${dataItem.host} - ${dataItem.connectedEntity}`;
                  }

                  return this.columnRenderersRegistry.getColumnRenderer("text")
                  (["formattedName"], dataItem, false);
               },
               sortable: (item1: any, item2: any) => {
                  return item1.formattedName.localeCompare(item2.formattedName);
               }
            },
            {
               displayName: this.i18n("DvsUi", "PortList.macHeaderText"),
               field: "mac",
               width: "90px",
               template: (dataItem: any) => {
                  return dataItem.mac ?
                        this.columnRenderersRegistry.getColumnRenderer("text")
                        (["mac"], dataItem, false)
                        : this.EMPTY_PROPERTY_VALUE;
               }
            },
            {
               displayName: this.i18n("DvsUi", "PortList.pgHeaderText"),
               field: "portgroup",
               width: "90px",
               template: (dataItem: any) => {
                  return dataItem.portgroup ?
                        this.columnRenderersRegistry.getColumnRenderer("text")
                        (["portgroup"], dataItem, false)
                        : this.EMPTY_PROPERTY_VALUE;
               }
            },
            {
               displayName: this.i18n("DvsUi", "PortList.stateHeaderText"),
               field: "state",
               width: "90px",
               template: (dataItem: any) => {
                  let stateObj: any = {
                     icon: "",
                     text: this.EMPTY_PROPERTY_VALUE
                  };

                  switch (dataItem.state) {
                     case "LINK_UP": {
                        stateObj.text = this.i18n("DvsUi", "PortState.linkUp");
                        stateObj.icon = "network-lib-ui-icon-dvsPortConnectedLf";
                        break;
                     }
                     case "LINK_DOWN": {
                        stateObj.text = this.i18n("DvsUi", "PortState.linkDown");
                        stateObj.icon = "network-lib-ui-icon-dvsPortNotconnectedLf";
                        break;
                     }
                     case "BLOCKED": {
                        stateObj.text = this.i18n("DvsUi", "PortState.blocked");
                        stateObj.icon = "network-lib-ui-icon-dvsPortBlockedLf";
                        break;
                     }
                  }

                  return this.columnRenderersRegistry.getColumnRenderer("icon-text")
                  (["icon", "text"], stateObj, false);
               }
            },
            {
               displayName: this.i18n("DvsUi", "PortList.vlanIdHeaderText"),
               field: "vlan",
               width: "90px",
               template: (dataItem: any) => {
                  return dataItem.vlan ?
                        this.columnRenderersRegistry.getColumnRenderer("text")
                        (["vlan"], dataItem, false)
                        : this.EMPTY_PROPERTY_VALUE;
               }
            }];
      }

      public buildDvsPortNetworkConnectionPropertiesView(dvsPortDetailsData: any): any {
         if (dvsPortDetailsData) {
            let builder = this.vxPropertyViewService.createPropertyViewBuilder();
            this.buildDvsPortNetworkConnectionCategory(builder, dvsPortDetailsData);

            return builder.build();
         }
      }

      public assignLiveRefreshProperties(isPortgroupContext: boolean): string[] {
         return isPortgroupContext ? DvsPortsConstants.LIVE_REFRESH_PROPERTIES : [];
      }

      private buildDvsPortNetworkConnectionCategory(
            builder: any, dvsPortDetailsData: any) {
         let generalCategory: any = builder.category("general");
         let generalSection: any = generalCategory.section("generalSection");

         generalSection.property(this.i18n(
               "DvsUi", "PortDetails.portId"), dvsPortDetailsData.portId);

         if (dvsPortDetailsData.name) {
            generalSection.property(this.i18n(
                  "DvsUi", "PortDetails.name"), dvsPortDetailsData.name);
         }

         if (dvsPortDetailsData.description) {
            generalSection.property(this.i18n(
                  "DvsUi", "PortDetails.description"), dvsPortDetailsData.description);
         }

         if (dvsPortDetailsData.lagName) {
            generalSection.property(this.i18n(
                  "DvsUi", "PortDetails.lagName"), dvsPortDetailsData.lagName);
         }

         if (dvsPortDetailsData.connectedEntity) {
            let connecteeObj: any = {
               icon: "",
               text: this.EMPTY_PROPERTY_VALUE
            };

            switch (dvsPortDetailsData.connectedEntityType) {
               case DvsPortsConstants.VMK_VNIC: {
                  connecteeObj.text =
                        `${dvsPortDetailsData.host} - ${dvsPortDetailsData.connectedEntity}`;
                  connecteeObj.icon = "network-lib-ui-icon-virtualAdapter";
                  break;
               }
               case DvsPortsConstants.VM_VNIC: {
                  connecteeObj.text = `${dvsPortDetailsData.connectedEntity}`;
                  connecteeObj.icon = "vsphere-icon-vm";
                  break;
               }
               case DvsPortsConstants.PNIC: {
                  connecteeObj.text =
                        `${dvsPortDetailsData.host} - ${dvsPortDetailsData.connectedEntity}`;
                  connecteeObj.icon = "network-lib-ui-icon-physicalAdapter";
                  break;
               }
            }

            this.addPropertyValueWithIcon(
                  generalSection,
                  this.i18n("DvsUi", "PortDetails.connectee"),
                  connecteeObj);
         }

         let stateObj: any = {
            icon: "",
            text: this.EMPTY_PROPERTY_VALUE
         };

         switch (dvsPortDetailsData.state) {
            case "LINK_UP": {
               stateObj.text = this.i18n("DvsUi", "PortState.linkUp");
               stateObj.icon = "network-lib-ui-icon-dvsPortConnectedLf";
               break;
            }
            case "LINK_DOWN": {
               stateObj.text = this.i18n("DvsUi", "PortState.linkDown");
               stateObj.icon = "network-lib-ui-icon-dvsPortNotconnectedLf";
               break;
            }
            case "BLOCKED": {
               stateObj.text = this.i18n("DvsUi", "PortState.blocked");
               stateObj.icon = "network-lib-ui-icon-dvsPortBlockedLf";
               break;
            }
         }

         this.addPropertyValueWithIcon(
               generalSection,
               this.i18n("DvsUi", "PortDetails.state"),
               stateObj);

         if (dvsPortDetailsData.mac) {
            generalSection.property(this.i18n("DvsUi", "PortDetails.mac"),
                  dvsPortDetailsData.mac);
         }

      }

      private addPropertyValueWithIcon(section: any, propertyName: String, propValue: any) {
         return section.propertyBuilder()
               .keyBuilder().text(propertyName)
               .exit()
               .valueBuilder()
               .icon(propValue.icon)
               .text(propValue.text)
               .exit()
               .exit();
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .service("dvsPortsService", DvsPortsSerivce);
}
