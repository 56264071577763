/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace dvs_ui {

   import DvPortgroupPortProperties = com.vmware.vsphere.client.dvpg.api.DvPortgroupPortProperties;

   export class DvsPortEditDialogModel {
      public portId: string;

      public portDvsUrn: string;

      public configVersion: string;

      public isUplink: boolean;

      public isLag: boolean;

      public propertiesPage: DvsPortEditPropertiesPageModel;

      public securityPage: DvsPortEditSecurityPageModel;

      public vlanPage: DvsPortEditVlanPageModel;

      public trafficShapingPage: DvsPortEditTrafficShapingPageModel;

      public failoverPolicyPage: DvsPortEditFailoverPageModel;

      public monitoringPage: DvsPortEditMonitoringPageModel;

      public miscellaneousPage: DvsPortEditMiscellaneousPageModel;

      public dvPortgroupPortProperties: DvPortgroupPortProperties;
   }
}