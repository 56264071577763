namespace h5_vm {
   import Privileges = h5_vm.Privileges;
   export class VmHardwareVirtualDiskIopsService {
      static $inject: string[] = [
         "i18nService",
         "vmHardwareUtil"
      ];

      constructor(private i18nService: any,
                  private vmHardwareUtil: any) {
      }

      getIopsLimit(device: any) {
         const iopsLimit = device.getCurrentDevice().storageIOAllocation.limit;
         return iopsLimit === -1 ? this.i18nService.getString('VmUi', 'DiskConfig.Unlimited') : iopsLimit;
      }

      isEditIopsLimitDisabled(privileges: any, createMode: boolean) {
         return !this.hasPrivilegeToEditIopsLimit(privileges, createMode);
      }

      hasPrivilegeToEditIopsLimit(privileges: any, skipPrivilegeCheck: boolean = false) {
         if(skipPrivilegeCheck)  {
            return true;
         }

         return this.vmHardwareUtil.checkPrivileges(privileges, [Privileges.VM_RESOURCE_PRIVILEGE, Privileges.VM_EDITDEVICE_PRIVILEGE]);
      }
   }

   angular.module('com.vmware.vsphere.client.vm')
         .service('vmHardwareVirtualDiskIopsService', VmHardwareVirtualDiskIopsService);
}
