/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace h5_vm {

   export class SelectPriorityPageComponent {
      public templateUrl: string;
      public controller: any;
      public controllerAs: string;
      public bindings: any;

      constructor() {
         this.templateUrl = "vm-ui/resources/vm/views/migrationWizard/pages/selectPriorityPageComponent.html";
         this.controller = SelectPriorityPageController;
         this.controllerAs = "ctrl";
         this.bindings = {
            migrationWizardManager: "<",
            pageModel: "<",
         };
      }
   }

   export class SelectPriorityPageController {

      public static $inject: string[] = [
         "i18nService",
         "$element",
         "$timeout"
      ];

      public pageModel: SelectPriorityPageModel;
      public migrationWizardManager: MigrationWizardManager;
      public i18n: any;

      constructor(
            private i18nService: any,
            private $element: any,
            private $timeout: any
      ) {
         this.i18n = i18nService.getString;
      }

      public $onInit() {
         if (!this.pageModel.priority) {
            // Initial value for the priority.
            this.pageModel.priority = this.pageModel.initialPriority || "highPriority";
         }
         this.$timeout(() => {
            let firstInput = this.$element[0].querySelector(
                  "#selectPriorityPageComponent input[value=highPriority]");
            if (this.pageModel.priority === "lowPriority") {
               firstInput = this.$element[0].querySelector(
                     "#selectPriorityPageComponent input[value=lowPriority]");
            }
            if (firstInput) {
               firstInput.focus();
            }
         }, 0);
      }
   } // class

   angular.module("com.vmware.vsphere.client.vm").component(
         "selectPriorityPageComponent",
         new SelectPriorityPageComponent());

} // namespace
