angular.module('com.vmware.vsphere.client.vm')
.factory('vmRecommendationsService', ['dataService', 'actionDialogService', 'mutationService', 'i18nService',
   'defaultUriSchemeUtil', '$q', 'logService',
   function (dataService, actionDialogService, mutationService, i18nService,
         defaultUriSchemeUtil, $q, logService) {

      var log = logService('vmRecommendationsService');

      var fetchRecommendations = function (response) {
         if (angular.isUndefined(response) || angular.isUndefined(response[0])) {
            return $q.reject();
         }

         var recommendations = response[0].result ? response[0].result.recommendations : null;
         if (recommendations === null || recommendations.length === 0) {
            return $q.reject();
         }

         var targetHostsIds = collectTargetHostsIds(recommendations);
         var promises = _.map(targetHostsIds, function(target) {
            return dataService.getProperties(target, ['name']);
         });

         var targetVmIds = collectTargetVmIds(recommendations);
         var vmPromises = _.map(targetVmIds, function(target) {
            return dataService.getProperties(target, ['name', 'primaryIconId']);
         });

         var hostNameById = {};

         return $q.all(vmPromises).then(function (result) {
            var vmInfoById = {};
            _.each(result, function (info) {
               vmInfoById[info.id] = info;
            });

            return $q.all(promises).then(function (res) {
               _.each(res, function (x) {
                  hostNameById[x.id] = x.name;
               });
               var data = createResults(recommendations, vmInfoById, hostNameById);
               return data;
            });
         });
      };

      var applyRecommendations = function (objectId, keys) {
         var spec = {
            "operation": "apply",
            "keys": keys
         };
         var propertyObjectType = 'com.vmware.vsphere.client.cluster.services.recommendations.ClusterRecommendationSpec';
         return mutationService.apply(objectId, propertyObjectType, spec);
      };

      var applyRecommendation = function (objectId, recommendation) {
         var propertyObjectType = 'com.vmware.vim.binding.vim.cluster.Recommendation';
         return mutationService.apply(objectId, propertyObjectType, recommendation);
      };

      function collectTargetVmIds(recommendations) {
         var vmIds = [];
         _.each(recommendations, function(recommendation) {
            _.each(recommendation.action, function(action) {
               var target = defaultUriSchemeUtil.getVsphereObjectId(action.target);
               vmIds.push(target);
            });
         });
         return vmIds;
      }


      function collectTargetHostsIds(recommendations) {
         if (recommendations === null || recommendations.length === 0) {
            return [];
         }
         return _.reduce(recommendations, function(aux, i) {
            if (i.action === null || i.action.length === 0) {
               return aux;
            }

            var mapper = function (action) {
               if (action._type === 'com.vmware.vim.binding.vim.cluster.InitialPlacementAction') {
                  return defaultUriSchemeUtil.getVsphereObjectId(action.targetHost);
               } else if (action._type === 'com.vmware.vim.binding.vim.cluster.MigrationAction') {
                  return [defaultUriSchemeUtil.getVsphereObjectId(action.drsMigration.source),
                          defaultUriSchemeUtil.getVsphereObjectId(action.drsMigration.destination)];
               } else {
                  log.error('Unknown action type: ', action);
               }
            };
            var targetHostsIds = _.chain(i.action).map(mapper).flatten().value();
            return aux.concat(targetHostsIds);
         }, []);
      }

      function ratingToPriority(starRating) {
         var maxStarRatingDrsAssignsToRecommendations = 5;
         return (maxStarRatingDrsAssignsToRecommendations - starRating) + 1;
      }

      function createResults(recommendations, vmInfoById, hostNameById) {
         return _.map(recommendations, function(recommendation, i) {
            var reasonTxt = i18nService.getString('VmUi', 'RecommendationListItemData.recommendation',
            i + 1, recommendation.reasonText);
            var info = {
               reasonText: reasonTxt,
               priority: ratingToPriority(recommendation.rating),
               actions: createActions(recommendation.action, vmInfoById, hostNameById),
               key: recommendation.key,
               targetClusterId: defaultUriSchemeUtil.getVsphereObjectId(recommendation.target)
            };
            return info;
         });
      }

      function createActions(actions, vmInfoById, hostNameById) {
         return _.map(actions, function (action) {
            var vmId, vmInfo, recommendationText;
            if (action._type === 'com.vmware.vim.binding.vim.cluster.InitialPlacementAction') {
               var hostId = defaultUriSchemeUtil.getVsphereObjectId(action.targetHost);
               vmId = defaultUriSchemeUtil.getVsphereObjectId(action.target);
               vmInfo = vmInfoById[vmId];
               recommendationText = i18nService.getEscapedString('VmUi',
               'RecommendationListItemData.initialPlacement', vmInfo.name, hostNameById[hostId]);
               return {
                  text: recommendationText,
                  icon: vmInfo.primaryIconId
               };
            } else if (action._type === 'com.vmware.vim.binding.vim.cluster.MigrationAction') {
               var sourceHostId = defaultUriSchemeUtil.getVsphereObjectId(action.drsMigration.source);
               var destinationHostId = defaultUriSchemeUtil.getVsphereObjectId(action.drsMigration.destination);
                vmId = defaultUriSchemeUtil.getVsphereObjectId(action.target);
               var key = 'RecommendationListItemData.migration';
               var sourceHostName = hostNameById[sourceHostId];
               var destinationHostName = hostNameById[destinationHostId];
               vmInfo = vmInfoById[vmId];
               recommendationText = i18nService.getEscapedString('VmUi', key, vmInfo.name, sourceHostName,
                     destinationHostName);
               return {
                  text: recommendationText,
                  icon: vmInfo.primaryIconId
               };
            } else {
               log.error('Unknown action type: ', action);
            }
         });
      }

      return {
         fetchRecommendations: fetchRecommendations,
         applyRecommendations: applyRecommendations,
         applyRecommendation: applyRecommendation
      };
   }]);
