/* Copyright 2018 VMware, Inc. All rights reserved. -- VMware Confidential */

/*
 * This ts file has to be loaded first
 */
namespace h5_vapp {
   import ActionEvaluation = com.vmware.vise.actionsfw.ActionEvaluation;

   declare let h5: any;
   declare const angular: any;

   h5 = h5 || {};
   h5.vcextension = {
      angularModule: angular.module("com.vmware.vsphere.client.vcextension", ["com.vmware.platform.ui"])
   };

   function initModule(agencyOperationManager: any) {
      // Put initialization code here, e.g. action invokers, column renderers, shortcut
      // handlers, drag'n'drop rules, etc.

      h5.actions["com.vmware.eam.actions.agencyIssue.resolve"] =
            (actionEval: ActionEvaluation, availableTargets: string[], context: any) => {
         agencyOperationManager.resolveIssue(context.agencyId, context.issueKey);
      };

      h5.actions["com.vmware.eam.actions.agency.deleteAgency"] =
            (actionEval: ActionEvaluation, availableTargets: string[], context: any) => {
         agencyOperationManager.deleteAgency(availableTargets, context);
      };

      h5.actions["com.vmware.eam.actions.agency.resolveAllIssues"] =
            (actionEval: ActionEvaluation, availableTargets: string[], context: any) => {
         agencyOperationManager.resolveAllIssues(availableTargets, context);
      };
   }

   angular.module("com.vmware.vsphere.client.vcextension")
         .run(["agencyOperationManagerService", initModule]);

}
