/* Copyright 2015 VMware, Inc. All rights reserved. -- VMware Confidential */
/*
 * Datacenter service
 */
angular
   .module('com.vmware.vsphere.client.folder')
   .service('datacenterService', datacenterService);

datacenterService.$inject = ['mutationService', 'defaultUriSchemeUtil'];

function datacenterService(mutationService, defaultUriSchemeUtil) {
   var MUTATION_ADD_DATACENTER_SPEC = 'com.vmware.vsphere.client.datacenter.DatacenterInfo';
   this.createDatacenter = function(name, moRef) {
      var datacenterCreationSpec = {
         name: name,
         parent: defaultUriSchemeUtil.getManagedObjectReference(moRef)
      };
      mutationService.add(MUTATION_ADD_DATACENTER_SPEC, datacenterCreationSpec);
   };
}
