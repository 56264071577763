/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace dvs_ui {

   export class NrpNavigatorService {

      static readonly UNDERSCORE_DELIMITER: string = "_";

      static readonly COLON_DELIMITER: string = ":";

      public getNrpKey(objectId: string): string {
         if (!this.isObjectIdValid(objectId)) {
            return "";
         }

         return objectId.substring(objectId.lastIndexOf(
               NrpNavigatorService.COLON_DELIMITER) + 1);
      }

      public getDvsUrn(objectId: string): string {
         if (!this.isObjectIdValid(objectId)) {
            return "";
         }

         let switchIdentifier: string = objectId.split(
               NrpNavigatorService.COLON_DELIMITER)[3];

         if (!this.isSwitchIdentifierValid(switchIdentifier)) {
            return "";
         }

         let serverUid: string = switchIdentifier.split(
               NrpNavigatorService.UNDERSCORE_DELIMITER)[0];
         let switchKey: string = switchIdentifier.split(
               NrpNavigatorService.UNDERSCORE_DELIMITER)[1];

         return `urn:vmomi:VmwareDistributedVirtualSwitch:${switchKey}:${serverUid}`;
      }

      private isObjectIdValid(objectId: string): boolean {
         return !!objectId && objectId.split(
               NrpNavigatorService.COLON_DELIMITER).length === 5;
      }

      private isSwitchIdentifierValid(switchIdentifier: string): boolean {
         return !!switchIdentifier && switchIdentifier.split(
               NrpNavigatorService.UNDERSCORE_DELIMITER).length === 2;
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .service("nrpNavigatorService", NrpNavigatorService);
}