namespace h5_client {
   export module common_module_ui {
      import ContentLibraryConstants = h5_client.common_module_ui.ContentLibraryConstants;
      import LibraryDataModel = h5_client.common_module_ui.LibraryDataModel;

      /**
       * class to build the data to be passed on to vAPI REST endpoints for Add New Content Library.
       */
      export class AddLibraryDataBuilder {
         static $inject = ['defaultUriSchemeUtil'];

         constructor(private defaultUriSchemeUtil: any) {}

         private libraryData: LibraryDataModel | null;

         /**
          * the first method to call in the addLibraryDataBuilder method chain
          * resets all data.
          * @returns {h5_client.common_module_ui.AddLibraryDataBuilder}
          */
         reset = (): AddLibraryDataBuilder => {
            this.libraryData = null;
            return this;
         };

         /**
          * set the data for a specific LibraryOperation.
          * @param data
          * @returns {h5_client.common_module_ui.AddLibraryDataBuilder}
          */
         setData = (data: LibraryDataModel): AddLibraryDataBuilder => {
            this.libraryData = data;
            return this;
         };

         /**
          * finally build the model to be passed on to $http to invoke the vAPI call.
          * last call in the method chain.
          * @returns
          * {
          *    {
          *       create_spec:
          *       {
          *          storage_backings:
          *             {
          *                datastore_id,
          *                type: string
          *             }[],
          *             name,
          *             type,
          *             server_guid: (string|string),
          *             version: string,
          *             id: string,
          *             description
          *        }
          *     }
          * }
          */
         buildModel = (): any => {
            if (!this.libraryData || !this.libraryData.datastore) {
               return {};
            }
            let json = {
               create_spec: {
                  storage_backings: [{
                     datastore_id: this.defaultUriSchemeUtil.getPartsFromVsphereObjectId(this.libraryData.datastore.id).value,
                     type: "DATASTORE"
                  }],
                  name: this.libraryData.name,
                  type: this.libraryData.type,
                  server_guid: this.libraryData.vcService.serviceGuid,
                  version: "string",
                  id: "string",
                  description: this.libraryData.description,
               }
            };

            if (this.libraryData.isPublished) {
               this.buildPublishInfo(this.libraryData, json.create_spec);
            }

            if (this.libraryData.type === ContentLibraryConstants.LIBRARYTYPE_SUBSCRIBED) {
               let isSubscriptionAuthenticated: boolean = !!this.libraryData.subscriptionPassword &&
                     this.libraryData.subscriptionPassword.length > 0;
               angular.extend(json.create_spec, {subscription_info: {
                     on_demand: this.libraryData.syncStrategy !== ContentLibraryConstants.SYNC_STRATEGY_IMMEDIATE,
                     authentication_method: isSubscriptionAuthenticated ?
                           ContentLibraryConstants.CREATE_LIBRARY_AUTHENTICATION_BASIC :
                           ContentLibraryConstants.CREATE_LIBRARY_AUTHENTICATION_NONE,
                     ssl_thumbprint: this.libraryData.sslThumbprint ? this.libraryData.sslThumbprint : "",
                     automatic_sync_enabled: true,
                     user_name: isSubscriptionAuthenticated ?
                           ContentLibraryConstants.CREATE_LIBRARY_DEFAULT_USER :
                           "",
                     subscription_url: this.libraryData.subscriptionUrl
               }});
               if (isSubscriptionAuthenticated) {
                  angular.merge(json.create_spec, {subscription_info: {
                     password: this.libraryData.subscriptionPassword
                  }});
               }
            }
            return json;
         };

         private buildPublishInfo = (libraryData: LibraryDataModel, spec: any): any => {
            let authMethod: string = ContentLibraryConstants.CREATE_LIBRARY_AUTHENTICATION_NONE;
            if (libraryData.isAuthenticationEnabled) {
               authMethod = ContentLibraryConstants.CREATE_LIBRARY_AUTHENTICATION_BASIC;
            }

            angular.extend(spec, {publish_info: {
               persist_json_enabled: false,
               authentication_method: authMethod,
               publish_url: "",
               current_password: "",
               published: true,
               password: libraryData.password,
               user_name: libraryData.userName
            }});

            if(libraryData.isStreamingOptimized) {
               angular.extend(spec, {optimization_info: {
                  optimize_remote_publishing: libraryData.isStreamingOptimized
               }});
            }
         }
      }



      angular.module('com.vmware.vsphere.client.commonModule').service('addLibraryDataBuilder', AddLibraryDataBuilder);
   }
}
