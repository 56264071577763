namespace ds_cluster_ui {

   export class DsAutomationComponent {
      public bindings: any;
      public controller: any;
      public templateUrl: string;

      constructor() {
         this.bindings = {
            model: "=",
            isAutofocusDisabled: "="
         };

         this.controller = DsAutomationComponentController;
         this.templateUrl = "ds-cluster-ui/resources/ds-cluster/components/dsAutomation.html";
      }
   }

   class DsAutomationComponentController {

      public static $inject = ["vuiConstants", "i18nService",
         "columnRenderersRegistry", "storageDrsAutomationService"];

      public model: DsClusterModel;
      public signPostParams: Object;
      public automationOverrides: any;
      public automationLevels: Object[];
      public automationOverridesOptions: Object[];
      public i18n: any;
      public isAutofocusDisabled: boolean;

      constructor(private vuiConstants: any, private i18nService: any,
                  private columnRenderersRegistry: any,
                  private storageDrsAutomationService: StorageDrsAutomationService) {
         this.i18n = this.i18nService.getString;
         this.buildSignPostParams();
         this.automationLevels = storageDrsAutomationService.getSdrsAutomationLevels(
               i18nService.getString("DsClusterUi", "sdrs.config.automationLevelLabel"));
         this.automationOverridesOptions =
               storageDrsAutomationService.getSdrsAutomationOverridesOptions();
      }

      private buildSignPostParams(): void {
         this.signPostParams = {
            spaceLoadBalanceAutomationMode: {
               message: this.i18nService.getString("DsClusterUi",
                     "sdrs.config.automationLevel.spaceBalanceDescriptionLabel"),
               title: this.i18nService.getString("Common", "help")
            },
            ioLoadBalanceAutomationMode: {
               message: this.i18nService.getString("DsClusterUi",
                     "sdrs.config.automationLevel.ioBalanceDescriptionLabel"),
               title: this.i18nService.getString("Common", "help")
            },
            ruleEnforcementAutomationMode: {
               message: this.i18nService.getString("DsClusterUi",
                     "sdrs.config.automationLevel.ruleEnforcementDescriptionLabel"),
               title: this.i18nService.getString("Common", "help")
            },
            policyEnforcementAutomationMode: {
               message: this.i18nService.getString("DsClusterUi",
                     "sdrs.config.automationLevel.policyEnforcementDesciprionLabel"),
               title: this.i18nService.getString("Common", "help")
            },
            vmEvacuationAutomationMode: {
               message: this.i18nService.getString("DsClusterUi",
                     "sdrs.config.automationLevel.vmEvacuationDescriptionLabel"),
               title: this.i18nService.getString("Common", "help")
            }
         };
      }
   }

   angular.module("com.vmware.vsphere.client.dsCluster")
         .component("dsAutomation",
               new DsAutomationComponent());
}
