/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace network_ui {

   export class VmMigrateWizardPages {

      public static $inject = [
            "i18nService",
            "vmMigrateWizardConstants",
            "vmMigrateSelectNetworksPageValidator",
            "vmMigrateSelectVmsPageValidator",
            "simpleValidationCommit"
      ];

      constructor(private i18nService: any,
                  private wizardConstants: any,
                  private selectNetworksPageValidator: VmMigrateSelectNetworksPageValidator,
                  private selectVmsPageValidator: VmMigrateSelectVmsPageValidator,
                  private simpleValidationCommit: any) {
      }

      /**
       * Creates an array of page definitions for the Migrate VM networking wizard.
       */
      public createPages(wizardScope: VmMigrateWizardScope): WizardPage[] {

         let selectNetworksPage: WizardPage = {
            id: this.wizardConstants.pages.VM_SELECT_NETWORKS_PAGE,
            title: this.i18nService.getString(
                  "NetworkUi", "SelectSourceDestinationNetworkPage.title"),
            description: this.i18nService.getString(
                  "NetworkUi", "SelectSourceDestinationNetworkPage.description"),
            contentUrl: "network-ui/resources/network/views/vm/migrate/views/" +
                  "vmMigrateSelectNetworksPage.html",
            onCommit: this.simpleValidationCommit(() => {
               return this.selectNetworksPageValidator.getValidationError(wizardScope);
            }, wizardScope.wizardConfig),
            validationParams: []
         };

         let selectVmsPage: WizardPage = {
            id: this.wizardConstants.pages.VM_SELECT_VMS_TO_MIGRATE_PAGE,
            title: this.i18nService.getString(
                  "NetworkUi", "SelectVmsToMigratePage.title"),
            description: this.i18nService.getString(
                  "NetworkUi", "SelectVmsToMigratePage.description"),
            contentUrl: "network-ui/resources/network/views/vm/migrate/views/" +
                  "vmMigrateSelectVmsPage.html",
            onCommit: this.simpleValidationCommit(() => {
               return this.selectVmsPageValidator.getValidationError(wizardScope);
            }, wizardScope.wizardConfig),
            validationParams: ["flags.isSelectVmsPageInitialized"]
         };

         let readyToCompletePage: WizardPage = {
            id: this.wizardConstants.pages.VM_MIGRATE_READY_TO_COMPLETE_PAGE,
            title: this.i18nService.getString("H5NetworkUi",
                  "vmMigrateWizard.readyToCompletePage.title"),
            description: this.i18nService.getString("H5NetworkUi",
                  "vmMigrateWizard.readyToCompletePage.description"),
            contentUrl: "network-ui/resources/network/views/vm/migrate/views/" +
                  "vmMigrateReadyToCompletePage.html",
            onCommit: (): boolean => {
               return true;
            },
            validationParams: []
         };

         return [selectNetworksPage, selectVmsPage, readyToCompletePage];
      }
   }

   angular.module("com.vmware.vsphere.client.network")
         .service("vmMigrateWizardPages", VmMigrateWizardPages);
}
