module h5_vm {

   class FtStorageSelectorDialogController implements angular.IController {
      static $inject = [
         "$scope",
         "ftVmService"
      ];
      private gridOptions: any;

      constructor(private $scope: any, private ftVmService: any) {
         this.gridOptions = this.ftVmService.getSelectDatastoreGridOptions(
               $scope.modalOptions.dialogData.datastores);

         $scope.$watch("ctrl.gridOptions.selectedItems", (newSelection: any) => {
            if (newSelection[0] && newSelection[0] !== null) {
               this.$scope.modalOptions.dialogData.isDatastoreSelected = true;
               this.$scope.modalOptions.dialogData.selectedDatastore = newSelection[0];
            } else {
               this.$scope.modalOptions.dialogData.isDatastoreSelected = false;
            }
         });
      }
   }

   angular.module("com.vmware.vsphere.client.vm")
         .controller("FtStorageSelectorDialogController", FtStorageSelectorDialogController);
}

