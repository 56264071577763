module ds_cluster_ui {

   export interface SdrsDetailsViewScope extends angular.IScope {
      openHelpLink: Function;
   }

   export class DsClusterConnectivityDetailsController {
      public detailsViewGridOptions: any;

      public static $inject = ["$scope", "i18nService", "helpService", "vuiConstants",
         "dsClusterConectivityService"];

      constructor(private $scope: SdrsDetailsViewScope, private i18nService: any,
                  private helpService: any, private vuiConstants: any,
                  private dsClusterConectivityService: any) {
         this.detailsViewGridOptions = this.dsClusterConectivityService
               .getDetailsViewGridOptions();

         $scope.$watch("masterDetailsViewContext.selectedItems", (newValue: any) => {
            if (newValue && newValue.length > 0) {
               this.setDetailsViewGridData(newValue);
            }
         });
      }

      private setDetailsViewGridData(cluster: any): void {
         this.detailsViewGridOptions.data = this.dsClusterConectivityService
               .getHostFromCluster(cluster[0][0]);
      }
   }

   angular.module("com.vmware.vsphere.client.dsCluster")
         .controller("DsClusterConnectivityDetailsController",
               DsClusterConnectivityDetailsController);
}
