/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace dvs_ui {

   export class PnicSettingsDialogService {

      static $inject = [
         "i18nService",
         "clarityModalService"
      ];

      constructor(private i18nService: any,
                  private clarityModalService: any) {
      }

      public show(hostId: string,
                  pnicDevice: string,
                  hostname: string): void {

         let templateUrl = "dvs-ui/resources/dvs/addhost/services/pnicSettingsDialog.html";
         let title = this.i18nService.getString(
               "DvsUi", "SelectPhysicalAdaptersPage.pnicPopupTitle", pnicDevice, hostname);
         let dialogData = {
            title: title,
            hostId: hostId,
            pnicDevice: pnicDevice,
         };

         this.clarityModalService.openModal(
               undefined, [], dialogData, templateUrl);
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .service("pnicSettingsDialogService", PnicSettingsDialogService);
}
