/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace dvs_ui {

   import IPromise = angular.IPromise;
   import DvsPortMirroringSessionData =
         com.vmware.vsphere.client.h5.network.dvs.portmirroring.model.DvsPortMirroringSessionData;
   import DvsPortMirroringData =
         com.vmware.vsphere.client.h5.network.dvs.portmirroring.model.DvsPortMirroringData;

   export class DvsPortMirroringComponent {

      public controller: any;
      public bindings: any;
      public templateUrl: string;

      constructor() {
         this.bindings = {
            portMirrorSessions: "<"
         };
         this.controller = DvsPortMirroringController;
         this.templateUrl =
               "dvs-ui/resources/dvs/portmirroring/dvsPortMirroringTemplate.html";
      }
   }

   class DvsPortMirroringController {

      static $inject = [
            "$q",
            "$rootScope",
            "$scope",
            "i18nService",
            "vuiConstants",
            "actionToolbarFactory",
            "columnRenderersRegistry",
            "networkUiConstants",
            "dvsPortMirroringComponentService"
      ];

      public datagridOptions: any;

      public headerOptions: any;

      public splitterOptions: any;

      public portMirrorSessions: DvsPortMirroringSessionData[];

      public portMirrorLoading: boolean;

      public showNoItemSelected: boolean;

      public portMirrorDetailsHeader: string;

      public liveRefreshProperties: any;

      private _selectedDvsPromise: IPromise<DvsPortMirroringData>;

      private _actionPromise: IPromise<any>;

      private _actionAvailabilityById: {[id: string]: boolean} = {};

      private dSwitchUri: string;

      private evaluationContext: any;

      constructor(private $q: any,
                  private $rootScope: any,
                  private $scope: any,
                  private i18nService: any,
                  private vuiConstants: any,
                  private actionToolbarFactory: any,
                  private columnRenderersRegistry: any,
                  private networkUiConstants: any,
                  private dvsPortMirroringComponentService: DvsPortMirroringComponentService) {

         this.createPortMirrorDatagridOptions(this.portMirrorSessions);
      }

      protected $onInit(): void {
         this.showNoItemSelected = true;
         this.refresh();
      }

      public $onChanges(): void {
         this.datagridOptions.data = this.portMirrorSessions ?
               this.portMirrorSessions : [];
      }

      public refresh() {
         this.$scope.portMirrorLoading = true;

         this.dSwitchUri = this.$rootScope._route.objectId;

         this.headerOptions = {
            title: this.i18nService.getString("DvsUi", "dvs.portMirroringView.title"),
            objectId: this.dSwitchUri,
            actions: null
         };

         this.splitterOptions = {
            orientation: this.vuiConstants.splitter.orientation.VERTICAL,
            panes: [
               {
                  min: "150px",
                  size: "45%"
               }, {
                  min: "250px",
                  size: "55%"
               }
            ]
         };

         let dvsPromise: IPromise<DvsPortMirroringData> =
               this.dvsPortMirroringComponentService
                     .getDvsPortMirroringData(this.dSwitchUri);

         this._selectedDvsPromise = dvsPromise;

         this.evaluationContext = {};

         let actionIds: string[] = [
               this.networkUiConstants.actions.ADD_SPAN_SESSION,
               this.vuiConstants.actions.SEPARATOR,
               this.networkUiConstants.actions.EDIT_SPAN_SESSION,
               this.networkUiConstants.actions.REMOVE_SPAN_SESSION
         ];

         let actionPromise: IPromise<any> =
               this.actionToolbarFactory.createActionbarOptions(
                     [this.$rootScope._route.objectId],
                     actionIds,
                     this.evaluationContext);

         this._actionPromise = actionPromise;

         this.$q.all([dvsPromise, actionPromise])
               .then((results: any[]): void => {
                  if (this._selectedDvsPromise === dvsPromise) {
                     let dvsPortMirroringData: DvsPortMirroringData = results[0];

                     if (dvsPortMirroringData) {
                        this.portMirrorSessions =
                              dvsPortMirroringData.portMirroringSessionsData ?
                                    dvsPortMirroringData.portMirroringSessionsData : [];
                        this.createPortMirrorDatagridOptions(this.portMirrorSessions);
                     }
                  }

                  if (this._actionPromise === actionPromise) {
                     this.datagridOptions.actionBarOptions = results[1];
                     if (this.datagridOptions.actionBarOptions) {
                        _.forEach(this.datagridOptions.actionBarOptions.actions,
                              (action: any) => {
                                 if (action !== this.vuiConstants.actions.SEPARATOR) {
                                    this._actionAvailabilityById[action.actionId] =
                                          action.enabled;
                                 }
                              });
                     }
                  }
               })
               .finally((): void => {
                  this.$scope.portMirrorLoading = false;
               });

         this.liveRefreshProperties = [
            "config.vspanSession"
         ];
      }

      private createPortMirrorDatagridOptions(portMirrorSessions: any): void {
         this.datagridOptions = {
            columnDefs: this.createColumnDefinitions(),
            sortMode: this.vuiConstants.grid.sortMode.SINGLE,
            selectionMode: this.vuiConstants.grid.selectionMode.SINGLE,
            selectedItems: [],
            data: portMirrorSessions,
            resizable: true,
            height: "100%",
            pageConfig: {
               hidePager: true
            },
            actionBarOptions: {
               actions: []
            },
            onChange: this.onSelectionChange.bind(this)
         };
      }

      private createColumnDefinitions(): any {

         return [{
               displayName: this.i18nService.getString(
                     "DvsUi", "dvs.portMirroring.grid.sessionName.columnHeader"),
               field: "name",
               width: "150px",
               template: ((data: any) => {
                  return this.columnRenderersRegistry.getColumnRenderer("text")
                  (["name"], data, true /*unescapeCharacters*/);
               })
            },
            {
               displayName: this.i18nService.getString(
                     "DvsUi", "dvs.portMirroring.grid.type.columnHeader"),
               field: "sessionType",
               width: "150px"
            },
            {
               displayName: this.i18nService.getString(
                     "DvsUi", "dvs.portMirroring.grid.status.columnHeader"),
               field: "enabled",
               width: "150px"
            }];
      }

      public onSelectionChange(items: any[]): void {
         if (items && items.length > 0) {
            this.$scope.selectedSession = items[0];
            this.evaluationContext.selectedSession = items[0];

            this.portMirrorDetailsHeader = this.i18nService.getString("DvsUi",
                  "SpanSessionDetailsView.title",
                  items[0].name);
         } else {
            this.$scope.selectedSession = null;
            this.evaluationContext.selectedSession = null;
         }

         this.showNoItemSelected = this.$scope.selectedSession === null;

         this.toggleActionsAvailability();
      }

      // Toggles the enabled/disabled flag of actions that depend on some item's
      // selection (e.g. Edit, Remove, etc..)
      private toggleActionsAvailability() {
         if (this.datagridOptions.actionBarOptions &&
               this.datagridOptions.actionBarOptions.actions) {
            this.datagridOptions.actionBarOptions.actions.forEach((action: any): void => {
               if (action.actionId === this.networkUiConstants.actions.ADD_SPAN_SESSION) {
                  action.enabled = !!this._actionAvailabilityById[action.actionId];
               } else if (action.actionId === this.networkUiConstants.actions.EDIT_SPAN_SESSION) {
                  action.enabled = !!this._actionAvailabilityById[action.actionId]
                        && this.$scope.selectedSession !== null
                        && !this.$scope.selectedSession.isLegacySession;
               } else if (action.actionId === this.networkUiConstants.actions.REMOVE_SPAN_SESSION) {
                  action.enabled = !!this._actionAvailabilityById[action.actionId]
                        && this.$scope.selectedSession !== null;
               }
            });
         }
      }

      public preselectComparator = (item: any): boolean => {
         return !!this.$scope.selectedSession &&
               this.$scope.selectedSession.key === item.key;
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .component("dvsPortMirroringView", new DvsPortMirroringComponent());
}
