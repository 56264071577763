
namespace h5_vm {

   class VmHardwareScsiCtrlController implements ng.IComponentController {
      static $inject: string[] = [
         "i18nService",
         "deviceClassLineageService",
         "VmController",
         "vmHardwareScsiControllerService"
      ];

      inflatedDevice:                        any;
      vmConfigContext:                       any;
      virtualMachineDevices:                 any;
      selectedGuestOs:                       any;
      removeCallback:                   Function;
      device:                                any;
      deviceName:                         String;
      scsiTypeList:                          any;
      busSharingList:                        any;
      unsupportedWarning:                    string | undefined;
      properties:                            any;
      createMode:                            boolean;
      expanded:                              boolean;

      constructor(private i18nService: any,
                  private deviceClassLineageService: any,
                  private VmController: any,
                  private vmHardwareScsiControllerService: any) {
      }

      public i18n(key: string, bundle: string = "VmUi"): string {
         return this.i18nService.getString(bundle, key);
      }

      public LBL_TYPE:string = this.i18n('SCSIControllerPage.ChangeType');
      public LBL_SHARING:string = this.i18n('SCSIControllerPage.SCSIBusSharing');

      $onInit() {
         this.device = this.inflatedDevice.getCurrentDevice();
         let deviceOptions: any = this.vmConfigContext.environment.configOption.hardwareOptions.virtualDeviceOption;
         let vmController: any = new this.VmController(
               this.device,
               deviceOptions
         );
         this.deviceName = this.getSCSIName(this.device._type);
         this.scsiTypeList = this.getSCSITypeList(deviceOptions);
         this.busSharingList = _.map(vmController.sharingOptions(), (unformattedSharingType) => {
            return this.createSharingListItem(unformattedSharingType);
         });
         this.unsupportedWarning = this.getUnsupportedWarning(
               this.inflatedDevice.getCurrentDevice()._type);
         this.properties = {
            selectedScsiType: {
               name: this.deviceName,
               id: this.device._type
            },
            selectedBusSharing: this.createSharingListItem(this.device.sharedBus)
         };
      }

      onScsiControllerTypeChange() {
         const scsiTypeId = this.properties.selectedScsiType.id;
         this.deviceName = this.getSCSIName(scsiTypeId);
         this.inflatedDevice.getCurrentDevice()._type = scsiTypeId;
         this.unsupportedWarning = this.getUnsupportedWarning(
               this.inflatedDevice.getCurrentDevice()._type);
      };

      onScsiControllerRemove() {
         if (this.isRemoveScsiControllerEnabled() && this.removeCallback) {
            this.removeCallback()(this.inflatedDevice);
         }
      };

      onScsiBusSharingChange() {
         this.inflatedDevice.getCurrentDevice().sharedBus = this.properties.selectedBusSharing.id;
      };

      isRemoveScsiControllerEnabled() {

         if (this.createMode) {
            return !this.isControllerInUse();
         }

         if (this.isControllerInUse() || !this.vmHardwareScsiControllerService.hasAddRemoveDevicePrivilege(this.vmConfigContext.privileges)) {
            return false;
         }

         return !this.isVmInSuspendedState();
      };

      isEditScsiControllerDisabled() {
         return this.vmHardwareScsiControllerService.isEditScsiControllerDisabled(this.vmConfigContext.environment.powerState, this.vmConfigContext.privileges, this.inflatedDevice.isNew());
      }

      isVmInPoweredOnState() {
         if (this.vmConfigContext.environment.powerState === "poweredOn") {
            return true;
         } else {
            return false;
         }
      };

      isVmInSuspendedState() {
         if (this.vmConfigContext.environment.powerState === "suspended") {
            return true;
         } else {
            return false;
         }
      };

      getUnsupportedWarning(selectedControllerType: any) {
         let index: number = _.findIndex(this.selectedGuestOs.supportedDiskControllerList, function(current: any) {
            return current.typeClass === selectedControllerType;
         });

         if (index < 0) {
            return this.i18n("SCSIControllerConfig.NotRecommended");
         } else {
            return undefined;
         }
      }

      private getSCSITypeList(deviceOptions: any) {
         const scsiTypeOptionList = _.select(deviceOptions, (diskController: any) => {
            return this.deviceClassLineageService.isClassNameSubclassOf(diskController.type.name, "VirtualSCSIController");
         });

         const scsiTypeList = _.map(scsiTypeOptionList, (scsiTypeOption: any) => {
            return {
               name: this.getSCSIName(scsiTypeOption.type.name),
               id: scsiTypeOption.type.typeClass
            };
         });

         return scsiTypeList;
      }

      private getSCSIName(type: any) {
         let scsiControllerName: any = _.last(type.split("."));
         return this.i18n("SCSIControllerConfig." + scsiControllerName);
      }

      private createSharingListItem(type: any) {
         return {
            name: this.i18n("SCSIControllerConfig." + type),
            id: type
         };
      }

      private isControllerInUse() {
         let devices: any = this.virtualMachineDevices.getDevicesAttachedToControllerKey(this.inflatedDevice.getKey());
         return devices.length > 0;
      }

   }

   export class VmHardwareScsiController implements ng.IComponentOptions {

      bindings: any;
      controller: any;
      templateUrl: string;

      constructor() {
         this.bindings = {
            inflatedDevice: "<",
            createMode: "<",
            vmConfigContext: "<",
            virtualMachineDevices: "<",
            selectedGuestOs: "<",
            removeCallback: "&",
            expanded: "="
         };

         this.controller = VmHardwareScsiCtrlController;
         this.templateUrl = "vm-ui/resources/vm/views/settings/vmHardwareSettings/vmHardwareScsiController/" +
               "vmHardwareScsiController.html";
      }
   }

   angular.module("com.vmware.vsphere.client.vm")
         .component("vmHardwareScsiController", new VmHardwareScsiController());
}
