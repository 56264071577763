/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace dvs_ui {

   import DvpgTrafficFilterModel = com.vmware.vsphere.client.h5.network.dvportgroup.trafficfilter.DvpgTrafficFilterModel;

   export class DvpgEditTrafficFilterComponent {

      public bindings: any;
      public controller: any;
      public templateUrl: string;

      constructor() {
         this.bindings = {
            model: "<"
         };

         this.controller = DvpgEditTrafficFilterContoller;

         this.templateUrl = "dvs-ui/resources/dvs/dvpg/trafficfilter/edit/" +
               "dvpgEditTrafficFilterTemplate.html";
      }
   }

   export class DvpgEditTrafficFilterContoller {

      static $inject = ["i18nService", "$sce"];

      public model: DvpgEditTrafficFilterModel;
      public i18n: any;
      public enableOverrideInfoText: string;

      constructor(private i18nService: any,
                  private $sce: any) {
         this.i18n = this.i18nService;
         this.enableOverrideInfoText = this.$sce.trustAsHtml(this.i18nService.getString(
               "DvsUi", "trafficFilter.ruleDialog.enableOverrideInfoText"));
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .component("dvpgEditTrafficFilter", new DvpgEditTrafficFilterComponent());
}

