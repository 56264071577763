(function() {
   angular.module('com.vmware.vsphere.client.commonModule')
      .factory('associableObjectTypesService', associableObjectTypesService);

   function associableObjectTypesService() {
      var objectTypes = {
         get: get,
         getTypeDataForAttributes: getTypeDataForAttributes
      };

      return objectTypes;

      function get() {
         return {
            Folder: { displayName: 'folder', selection: true, associableType: 'Folder', associableTypeString: 'Folder' },
            Cluster: { displayName: 'cluster', selection: true, associableType: 'ClusterComputeResource', associableTypeString: 'ClusterComputeResource' },
            Datacenter: { displayName: 'datacenter', selection: true, associableType: 'Datacenter', associableTypeString: 'Datacenter' },
            Datastore: { displayName: 'datastore', selection: true, associableType: 'Datastore', associableTypeString: 'Datastore' },
            DatastoreCluster: { displayName: 'storagePod', selection: true, associableType: 'StoragePod', associableTypeString: 'StoragePod' },
            DistributedPortGroup: { displayName: 'dvpg', selection: true , associableType: 'DistributedVirtualPortgroup', associableTypeString: 'DistributedVirtualPortgroup' },
            DistributedSwitch: { displayName: 'dvs', selection: true , associableType: 'DistributedVirtualSwitch', associableTypeString: 'DistributedVirtualSwitch', associatedTypes: ['VmwareDistributedVirtualSwitch'] },
            Host: { displayName: 'host', selection: true, associableType: 'HostSystem', associableTypeString: 'HostSystem' },
            Library: { displayName: 'library', selection: true, associableType: 'com.vmware.content.Library', associableTypeString: 'Library' },
            LibraryItem: { displayName: 'libraryItem', selection: true, associableType: 'com.vmware.content.library.Item', associableTypeString: 'LibraryItem' },
            Network: { displayName: 'network', selection: true, associableType: 'HostNetwork', associableTypeString: 'HostNetwork', associatedTypes: ['Network', 'OpaqueNetwork'] },
            ResourcePool: { displayName: 'rp', selection: true, associableType: 'ResourcePool', associableTypeString: 'ResourcePool' },
            vApp: { displayName: 'vapp', selection: true, associableType: 'VirtualApp', associableTypeString: 'VirtualApp' },
            VirtualMachine: { displayName: 'vm', selection: true, associableType: 'VirtualMachine', associableTypeString: 'VirtualMachine' }         };
      }

      function getTypeDataForAttributes() {
         return {
            Folder: { displayName: 'folder', associableType: 'Folder', associableTypeString: 'Folder' },
            Cluster: { displayName: 'cluster', associableType: 'ClusterComputeResource', associableTypeString: 'ClusterComputeResource' },
            Datacenter: { displayName: 'datacenter', associableType: 'Datacenter', associableTypeString: 'Datacenter' },
            Datastore: { displayName: 'datastore', associableType: 'Datastore', associableTypeString: 'Datastore' },
            DatastoreCluster: { displayName: 'storagePod', associableType: 'StoragePod', associableTypeString: 'StoragePod' },
            DistributedPortGroup: { displayName: 'dvpg' , associableType: 'DistributedVirtualPortgroup', associableTypeString: 'DistributedVirtualPortgroup' },
            DistributedSwitch: { displayName: 'dvs' , associableType: 'DistributedVirtualSwitch', associableTypeString: 'DistributedVirtualSwitch'},
            Host: { displayName: 'host', associableType: 'HostSystem', associableTypeString: 'HostSystem' },
            Network: { displayName: 'network', associableType: 'HostNetwork', associableTypeString: 'Network'},
            OpaqueNetwork: { displayName: 'opaqueNetwork', associableType: 'OpaqueNetwork', associableTypeString: 'OpaqueNetwork'},
            ResourcePool: { displayName: 'rp', associableType: 'ResourcePool', associableTypeString: 'ResourcePool' },
            vApp: { displayName: 'vapp', associableType: 'VirtualApp', associableTypeString: 'VirtualApp' },
            VirtualMachine: { displayName: 'vm', associableType: 'VirtualMachine', associableTypeString: 'VirtualMachine' }
         };
      }
   }
})();
