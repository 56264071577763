/* Copyright 2015 VMware, Inc. All rights reserved. -- VMware Confidential */
(function () {
   'use strict';
   angular.module('com.vmware.vsphere.client.host').controller('DefaultVmCompatibilityController', DefaultVmCompatibilityController);

   DefaultVmCompatibilityController.$inject = ['$scope', 'i18nService', 'dataService', 'defaultUriSchemeUtil'];

   function DefaultVmCompatibilityController($scope, i18nService, dataService, defaultUriSchemeUtil) {
      var self = this;

      $scope.i18nService = i18nService;

      self.objectsToWatch = [$scope._route.objectId];
      // in case of host - if in cluster - cluster's configurationEx else hosts
      // in case of datacenter - configuration
      self.liveRefreshProperties = ['configurationEx', 'configuration'];

      function setValue(defaultHardwareVersionKey, parent) {
         var defaultHardwareString;
         if (defaultHardwareVersionKey) {
            defaultHardwareString = i18nService.getString(
                  'VmUi', 'VmHardware.HWVersion.short.' + defaultHardwareVersionKey);
         } else {
            defaultHardwareString = i18nService.getString(
                  'VmUi', 'defaultVmHwVersionView.default');
         }

         if (parent) {
            // Watch parent compute resource for changes in case current object is host
            self.objectsToWatch.push(defaultUriSchemeUtil.getVsphereObjectId(parent));

            if (parent.type === 'ClusterComputeResource') {
               defaultHardwareString = i18nService.getString(
                     'VmUi', 'defaultVmHwVersionView.compatibilityHostInCluster',
                     defaultHardwareString);
            }
         }
         self.defaultVmHwVersion = defaultHardwareString;
      }

      self.headerOptions = {
         title: i18nService.getString('VmUi', 'defaultVmHardwareVersion')
      };

      var contentSpec = $scope._view.contentSpec;
      var defaultHardwareVersionProperty;
      var propertiesToRequest = [];

      if (contentSpec && contentSpec.metadata) {
         if (contentSpec.metadata.editActionId) {
            self.headerOptions.actions = [{
               actionUid: contentSpec.metadata.editActionId,
               customLabel: i18nService.getString('Common', 'settingsView.defaultEditActionLabel')
            }];
         }
         if (contentSpec.metadata.defaultHardwareVersionProperty) {
            defaultHardwareVersionProperty = contentSpec.metadata.defaultHardwareVersionProperty;
            propertiesToRequest.push(defaultHardwareVersionProperty);
         }
         if (!!contentSpec.metadata.watchParentForChanges) {
            propertiesToRequest.push('parent');
         }
      }

      if (propertiesToRequest.length > 0 && defaultHardwareVersionProperty) {
         self.fetchViewData = function() {
            dataService.getProperties($scope._route.objectId, propertiesToRequest)
               .then(function(propertiesData) {
                  setValue(propertiesData[defaultHardwareVersionProperty], propertiesData.parent);
               });
         };

         self.fetchViewData();
      }

   }
})();
