/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
(function () {
   'use strict';
   angular
         .module('com.vmware.vsphere.client.dvs')
         .controller('DvPortgroupFailoverPolicyPageController',
               DvPortgroupFailoverPolicyPageController);

   DvPortgroupFailoverPolicyPageController.$inject = [
         '$scope',
         'dvpgFailoverPolicyPageService'
   ];

   function DvPortgroupFailoverPolicyPageController(
         $scope,
         dvpgFailoverPolicyPageService) {

      $scope.manager.commitPageCallbacks.commitFailoverPolicyPage = onCommit;

      $scope.failoverOrder = dvpgFailoverPolicyPageService.createFailoverOrderSettings(
            $scope.manager.dvPortgroupData.failoverOrder);

      var initialFailoverPolicyData = getInitialPolicyData();
      var initialFailoverOrderData = getInitialFailoverOrderData();

      function onCommit() {

         $scope.manager.pageDirtyFlags.isFailoverPolicyPageDirty =
               hasTeamingPolicyChanged(initialFailoverPolicyData,
                     $scope.manager.dvPortgroupData) ||
               dvpgFailoverPolicyPageService.hasFailoverOrderChanged(
                     initialFailoverOrderData,
                     $scope.manager.dvPortgroupData.failoverOrder.activeUplinkPortNames,
                     $scope.manager.dvPortgroupData.failoverOrder.standbyUplinkPortNames);

         if ($scope.manager.pageDirtyFlags.isFailoverPolicyPageDirty &&
               dvpgFailoverPolicyPageService.shouldShowErrorWarningDialog(
                     $scope.failoverOrder.connectees,
                     $scope.manager.dvPortgroupData.loadBalancing,
                     $scope.manager.dvPortgroupData.beaconProbing)) {

            return dvpgFailoverPolicyPageService.showErrorWarningDialog(
                  $scope.failoverOrder.connectees,
                  $scope.manager.dvPortgroupData.loadBalancing,
                  $scope.manager.dvPortgroupData.beaconProbing);
         }

         return true;
      }

      function hasTeamingPolicyChanged(initialPolicy, actualPolicy) {
         return initialPolicy.loadBalancing !== actualPolicy.loadBalancing ||
            initialPolicy.beaconProbing !== actualPolicy.beaconProbing ||
            initialPolicy.notifySwitches !== actualPolicy.notifySwitches ||
            initialPolicy.rollingOrder !== actualPolicy.rollingOrder;
      }

      function getInitialFailoverOrderData () {
         return {
            connectees: {
               active: $scope.initialData.failoverOrder.activeUplinkPortNames,
               standby: $scope.initialData.failoverOrder.standbyUplinkPortNames,
               unused: $scope.initialData.failoverOrder.unusedUplinkPortNames
            }
         };
      }

      function getInitialPolicyData() {
         return {
            loadBalancing: $scope.initialData.loadBalancing,
            beaconProbing: $scope.initialData.beaconProbing,
            notifySwitches: $scope.initialData.notifySwitches,
            rollingOrder: $scope.initialData.rollingOrder
         };
      }

      return this;
   }
})();
