module hostprofile_ui {

   import IPromise = angular.IPromise;

   class EditHostCustomizationsController implements angular.IController {

      public static $inject = ["$scope", "clarityConstants"];

      public objectId: string;
      public getValidationErrors: Function;
      public getClientValidationErrors: Function;
      public submit: Function;
      public showGenericErrors: Function;

      constructor(private $scope: any, private clarityConstants: any) {
         this.$scope.modalOptions.onSubmit = this.onSubmit.bind(this);
         this.objectId = this.$scope.modalOptions.dialogData.availableTargets[0];
         this.showGenericErrors = this.setGenericErrors.bind(this);
      }

      public setGenericErrors(errorMessages: string[]): void {
         this.$scope.modalOptions.alerts = _.map(errorMessages,
               (message: string) => {
                  return {
                     text: message,
                     type: this.clarityConstants.notifications.type.ERROR
                  };
               });
      }

      private onSubmit(): IPromise<boolean> {
         return this.getValidationErrors().then((result: any) => {
            if (result === true) {
               return this.submit();
            }

            if (_.isArray(result)) {
               this.setGenericErrors(result);
            }

            return false;
         }, () => false);
      }
   }

   angular.module("com.vmware.vsphere.client.hostprofile")
         .controller("EditHostCustomizationsController", EditHostCustomizationsController);
}
