/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */

/**
 * Sets pages visibility of the Dvs Edit Span Session Dialog
 */
namespace dvs_ui {

   import IPromise = angular.IPromise;

   export class DvsEditSpanSessionDialogPages {

      public static $inject = [
         "vuiConstants",
         "i18nService",
         "dvsSpanSessionPropertiesValidator"
      ];

      constructor(private vuiConstants: any,
                  private i18nService: any,
                  private propertiesValidator: DvsSpanSessionPropertiesValidator) {
      }

      public createPageDefinitions(dialogScope: any, sessionType: string): any[] {
         let pages: any[] = [];

         pages.push({
            title: this.i18nService.getString(
                  "DvsUi", "EditSpanSessionDialog.propertiesPage.title"),
            contentUrl: "dvs-ui/resources/dvs/portmirroring/edit/views/dvsEditSpanSessionPropertiesPage.html",
            onCommit: this.buildPropertiesPageCommitCallback(dialogScope)
         });

         switch (sessionType) {
            case DvsSpanSessionConstants.SESSION_TYPE.L3_SOURCE:
               pages.push({
                  title: this.i18nService.getString(
                        "DvsUi", "EditSpanSessionDialog.sourcesPage.title"),
                  contentUrl: "dvs-ui/resources/dvs/portmirroring/edit/views/dvsEditSpanSessionSelectSourcePortsPage.html",
                  onCommit: (): boolean => {
                     return true;
                  }
               });
               pages.push({
                  title: this.i18nService.getString(
                        "DvsUi", "EditSpanSessionDialog.destinationsPage.title"),
                  contentUrl: "dvs-ui/resources/dvs/portmirroring/edit/views/dvsEditSpanSessionSelectDestinationAddressesPage.html",
                  onCommit: this.buildDestinationAddressesPageCommitCallback(dialogScope)
               });
               break;
            case DvsSpanSessionConstants.SESSION_TYPE.REMOTE_MIRROR_SOURCE :
               pages.push({
                  title: this.i18nService.getString(
                        "DvsUi", "EditSpanSessionDialog.sourcesPage.title"),
                  contentUrl: "dvs-ui/resources/dvs/portmirroring/edit/views/dvsEditSpanSessionSelectSourcePortsPage.html",
                  onCommit: (): boolean => {
                     return true;
                  }
               });
               pages.push({
                  title: this.i18nService.getString(
                        "DvsUi", "EditSpanSessionDialog.destinationsPage.title"),
                  contentUrl: "dvs-ui/resources/dvs/portmirroring/edit/views/dvsEditSpanSessionSelectDestinationUplinksPage.html",
                  onCommit: (): boolean => {
                     return true;
                  }
               });
               break;
            case DvsSpanSessionConstants.SESSION_TYPE.REMOTE_MIRROR_DEST:
               pages.push({
                  title: this.i18nService.getString(
                        "DvsUi", "EditSpanSessionDialog.sourcesPage.title"),
                  contentUrl: "dvs-ui/resources/dvs/portmirroring/edit/views/dvsEditSpanSessionSelectSourceVlansPage.html",
                  onCommit: this.buildSourceVlansPageCommitCallback(dialogScope)
               });

               pages.push({
                  title: this.i18nService.getString(
                        "DvsUi", "EditSpanSessionDialog.destinationsPage.title"),
                  contentUrl: "dvs-ui/resources/dvs/portmirroring/edit/views/dvsEditSpanSessionSelectDestinationPortsPage.html",
                  onCommit: (): boolean => {
                     return true;
                  }
               });
               break;
            case DvsSpanSessionConstants.SESSION_TYPE.DV_PORT_MIRROR:
               pages.push({
                  title: this.i18nService.getString(
                        "DvsUi", "EditSpanSessionDialog.sourcesPage.title"),
                  contentUrl: "dvs-ui/resources/dvs/portmirroring/edit/views/dvsEditSpanSessionSelectSourcePortsPage.html",
                  onCommit: this.buildPortsPageCommitCallback(
                        dialogScope,
                        "SpanSession.error.alreadyUsedDestPort",
                        "SpanSession.error.alreadyUsedDestPorts")
               });

               pages.push({
                  title: this.i18nService.getString(
                        "DvsUi", "EditSpanSessionDialog.destinationsPage.title"),
                  contentUrl: "dvs-ui/resources/dvs/portmirroring/edit/views/dvsEditSpanSessionSelectDestinationPortsPage.html",
                  onCommit: this.buildPortsPageCommitCallback(
                        dialogScope,
                        "SpanSession.error.alreadyUsedSourcePort",
                        "SpanSession.error.alreadyUsedSourcePorts")
               });
               break;
         }

         return pages;
      }

      private buildPropertiesPageCommitCallback(dialogScope: any): Function {
         return (): boolean | IPromise<boolean> => {
            dialogScope.model.pageValidators
                  ["editPropertiesPage"].validate();

            let pageModel: DvsSpanSessionPropertiesPageModel =
                  dialogScope.model.propertiesPageModel;
            let errors: string[] = pageModel.errors
                  ? pageModel.errors.slice()
                  : [];

            let descriptionError: string | null =
                  this.propertiesValidator.getDescriptionError(
                        dialogScope.model.propertiesPageModel.description);

            if (descriptionError) {
               errors.push(descriptionError);
            }

            if (errors.length > 0) {
               this.showDialogErrors(dialogScope, errors);
               return false;
            }

            if (dialogScope.model.originalData.sessionName
                  !== dialogScope.model.propertiesPageModel.sessionName) {
               dialogScope.busy = true;
               return this.propertiesValidator.getNameServerError(
                     dialogScope.model.dvsUrn,
                     dialogScope.model.propertiesPageModel.sessionName)
                     .then((error: string): boolean => {
                        this.showDialogErrors(dialogScope, [error]);
                        dialogScope.busy = false;
                        return !error;
                     });
            }

            return true;
         };
      }

      private buildDestinationAddressesPageCommitCallback(dialogScope: any): Function {
         return (): boolean => {
            dialogScope.model.pageValidators
                  [DvsSpanSessionConstants.PAGE_ID.SELECT_DESTINATION_ADDRESSES].validate();

            let pageModel: DvsSpanSessionSelectDestinationsPageModel =
                  dialogScope.model.selectDestinationsPageModel;
            if (pageModel.ipAddressesListModel.errors
                  && pageModel.ipAddressesListModel.errors.length) {
               this.showDialogErrors(dialogScope, pageModel.ipAddressesListModel.errors);
               return false;
            }

            return true;
         };
      }

      private buildSourceVlansPageCommitCallback(dialogScope: any): Function {
         return (): boolean => {
            dialogScope.model.pageValidators
                  [DvsSpanSessionConstants.PAGE_ID.SELECT_SOURCE_VLANS].validate();

            let pageModel: DvsSpanSessionSelectSourcesPageModel =
                  dialogScope.model.selectSourcesPageModel;

            if (pageModel.vlanIdListModel.errors
                  && pageModel.vlanIdListModel.errors.length) {
               this.showDialogErrors(dialogScope, pageModel.vlanIdListModel.errors);
               return false;
            }

            return true;
         };
      }

      private buildPortsPageCommitCallback(dialogScope: any,
            singleEntityMessage: string, multipleEntitiesMessage: string): Function {
         return (): boolean => {
            let sourcePorts: string[] =
                  dialogScope.model.selectSourcesPageModel.portsListModel.ports
                        .map((port: PortData): string => { return port.id; });
            let destinationPorts: string[] =
                  dialogScope.model.selectDestinationsPageModel.portsListModel.ports
                        .map((port: PortData): string => { return port.id; });

            let usedPorts: string[] = _.intersection(sourcePorts, destinationPorts);

            if (usedPorts.length === 1) {
               this.showDialogErrors(dialogScope, [
                  this.i18nService.getString("DvsUi",
                        singleEntityMessage,
                        usedPorts[0])
               ]);
               return false;
            } else if (usedPorts.length > 1) {
               this.showDialogErrors(dialogScope, [
                  this.i18nService.getString("DvsUi",
                        multipleEntitiesMessage,
                        usedPorts.join(", "))
               ]);
               return false;
            }

            return true;
         };
      }

      private showDialogErrors(dialogScope: any, errors: string[]): void {
         dialogScope.dialogOptions.validationBanner.messages = _.map(
               errors,
               (message: string): any => {
                  return {
                     type: this.vuiConstants.validationBanner.type.ERROR,
                     text: message
                  };
               });
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .service("dvsEditSpanSessionDialogPages", DvsEditSpanSessionDialogPages);
}
