(function() {
   /**
    * Directive for adding Affinity and Antiaffinity rule.
    */
   'use strict';
   angular.module('com.vmware.vsphere.client.cluster').directive(
         'addAffinityRule', addAffinityRule);

   addAffinityRule.$inject = ['$rootScope', 'i18nService', 'vuiConstants',
         'datagridActionBarService', 'clusterRulesService',
         'clusterRulesConstants'];

   function addAffinityRule($rootScope, i18nService, vuiConstants,
         datagridActionBarService, clusterRulesService, clusterRulesConstants) {

      return {
         restrict: 'AE',
         templateUrl: 'cluster-ui/resources/cluster/views/settings/rules/addAffinityRule.html',
         scope: {
            spec: '='
         },
         controller: ['$scope', function($scope) {

            var objectId = $rootScope._route.objectId;

            $scope.descriptionTitle = i18nService.getString('Common', 'rules.config.description');
            $scope.gridTitle = i18nService.getString('ClusterUi', 'rules.members.list.name');
            $scope.spec.vmAffinityData = [];

            if ($scope.spec.members && $scope.spec.members.length > 0) {
               clusterRulesService.getAffinityRulesMembersData($scope.spec).then(function(membersData) {
                  $scope.spec.vmAffinityData = membersData;
                  $scope.vmAffinityGridOptions.data = membersData;
               });
            }

            $scope.vmAffinityGridOptions = getVmAffinityGridOptions();

            var actionSpecs = [{
               actionId: 'vsphere.core.cluster.actions.rule.members.add',
               getActionInvocationContext: function() {
                  return {
                     selectedMembers: $scope.spec.vmAffinityData,
                     dialogSubTitleObject: {
                        objectId: objectId
                     },
                     submitCallback: function(dialogOptions) {
                        if (dialogOptions.dialogData.selectedItems !== undefined) {
                           $scope.vmAffinityGridOptions.data = $scope.vmAffinityGridOptions
                                 .data.concat(dialogOptions.dialogData.selectedItems);
                           $scope.spec.vmAffinityData = $scope.vmAffinityGridOptions.data;
                        }
                        return true;
                     }
                  };
               }
            }, {
               actionId: 'vsphere.core.cluster.actions.rule.members.remove',
               getActionInvocationContext: function() {
                  return {
                     isExistingRule: false,
                     rule: null,
                     membersToRemove: $scope.vmAffinityGridOptions.selectedItems,
                     confirmCallback: function() {
                        $scope.vmAffinityGridOptions.data = $scope.spec.vmAffinityData =
                              _.filter(
                                    $scope.vmAffinityGridOptions.data,
                                    function(gridItem) {
                                       return _.every(
                                             $scope.vmAffinityGridOptions.selectedItems,
                                             function(selectedItem) {
                                                return gridItem.id !== selectedItem.id;
                                             });
                                    });
                        return true;
                     }
                  };
               },
               isActionAvailable: function(actionDef) {
                  return actionDef.available && $scope.vmAffinityGridOptions.selectedItems.length > 0;
               }
            }];
            var datagridActionBarServiceCacheObject = null;

            $scope.$watch('vmAffinityGridOptions.selectedItems', function() {
               refreshActionBar();
            });

            $scope.$watch('spec.ruleType', function() {
               $scope.descriptionLabel = getRuleDescription();
            });

            createActionBar();

            function createActionBar() {
               datagridActionBarService
                     .updateActionBar($scope.vmAffinityGridOptions, [objectId], actionSpecs)
                     .then(function(value) {
                        datagridActionBarServiceCacheObject = value;
                     });
            }

            function refreshActionBar() {
               if (!datagridActionBarServiceCacheObject) {
                  return;
               }

               datagridActionBarService.updateActionBar(
                     $scope.vmAffinityGridOptions, [objectId], actionSpecs, datagridActionBarServiceCacheObject
               );
            }

            function getVmAffinityGridOptions() {
               var options = {
                  actionBarOptions: {
                     actions: []
                  },
                  searchable: false,
                  resizable: false,
                  data: $scope.spec.vmAffinityData,
                  selectionMode: vuiConstants.grid.selectionMode.MULTI,
                  showCheckboxesOnMultiSelection: false,
                  selectedItems: [],
                  columnDefs: [{
                     displayName: i18nService.getString('ClusterUi', 'rules.members.list.name'),
                     field: 'name',
                     type: 'string',
                     template: '<span class="object" title="#:name#"><i class="#:icon#")></i>#:name#</span>'
                  }],
                  height: '100%',
                  pageConfig: {
                     hidePager: true
                  }
               };

               return options;
            }

            function getRuleDescription() {
               if ($scope.spec.ruleType === clusterRulesConstants.ruleSpec.VM_AFFINITY_RULE) {
                  return i18nService.getString('ClusterUi',
                        'rules.config.type.vmAffinity.desc');
               } else {
                  return i18nService.getString('ClusterUi',
                        'rules.config.type.vmAntiAffinity.desc');
               }
            }
         }]
      };
   }
})();
