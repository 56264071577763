angular.module('com.vmware.vsphere.client.vm').factory('VirtualCdrom', [
   'EditableVirtualDevice',
   function(EditableVirtualDevice) {
      var VirtualCdrom = function(device) {
         EditableVirtualDevice.call(this, device);
      };

      return VirtualCdrom;
   }
]);
