/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace dvs_ui {

   import ManagedObjectReference = com.vmware.vim.binding.vmodl.ManagedObjectReference;
   export class VmVnicSettingsDialogService {

      static $inject = [
         "clarityModalService"
      ];

      constructor(private clarityModalService: any) {
      }

      public show(vmName: string,
                  vmRef: ManagedObjectReference,
                  vmVnicKey: number | undefined): void {

         let templateUrl = "dvs-ui/resources/dvs/addhost/services/vmVnicSettingsDialog.html";
         let dialogData = {
            title: vmName,
            vmVnicKey: vmVnicKey,
            vmRef: vmRef
         };

         this.clarityModalService.openModal(
               undefined, [], dialogData, templateUrl);
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .service("vmVnicSettingsDialogService", VmVnicSettingsDialogService);
}
