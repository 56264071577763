angular.module('com.vmware.vsphere.client.vm').service('datastoreService', [
   'defaultUriSchemeUtil',
   function (defaultUriSchemeUtil) {
      return {
         findDatastoreFromVmxName: findDatastoreFromVmxName,
         findDatastore: findDatastore
      };

      function findDatastoreFromVmxName(configInfo, configTarget) {
         if (!configInfo.files.vmPathName) {
            return null;
         }

         var datastoreName = getDsNameFromFilePath(configInfo.files.vmPathName);
         var datastoreInfo = _.find(configTarget.datastore, function (datastoreInfo) {
            return datastoreName === datastoreInfo.name;
         });
         if (!datastoreInfo) {
            return null;
         }
         return datastoreInfo;
      }

      function findDatastore(dsId, configTarget) {
         var datastore = _.find(configTarget.datastore, function (dsInfo) {
            return dsId === defaultUriSchemeUtil.getVsphereObjectId(dsInfo.datastore.datastore);
         });

         return datastore || null;
      }

      // ------- Private -------

      function getDsNameFromFilePath(filePath) {
         if (!filePath) {
            return "";
         }
         var firstBracket = filePath.indexOf('[');
         var secondBracket = filePath.indexOf(']');
         if (firstBracket === -1 || secondBracket === -1) {
            return '';
         }
         return filePath.substring(firstBracket + 1, secondBracket);
      }
   }]);
