namespace h5_client {
   export module common_module_ui {
      import ContentLibraryConstants  = h5_client.common_module_ui.ContentLibraryConstants;

      export class LibraryDataModel {
         name: string = "";
         type: string = ContentLibraryConstants.LIBRARYTYPE_LOCAL;
         description: string = "";

         isPublished: boolean = false;
         isAuthenticationEnabled: boolean = false;
         userName: string = ContentLibraryConstants.CREATE_LIBRARY_DEFAULT_USER;
         password: string = "";
         isStreamingOptimized: boolean;
         syncStrategy: string = ContentLibraryConstants.SYNC_STRATEGY_IMMEDIATE;
         subscriptionUrl: string = "";
         isSubscriptionAuthenticated: boolean = false;
         subscriptionPassword: string = "";
         sslThumbprint: string = "";

         vcService: any = {
            name: ""
         };

         datastore: any = {
            id: ""
         };

         //if this Library exists, its id
         id: string = "";
      }
   }
}
