(function() {
   'use strict';

   /**
    * @name com.vmware.vsphere.client.host:hostServiceStatus
    *
    * @description
    *    Represents vim.host.Service.Policy enum values.
    *
    *    List of available constants
    *
    *    - **`ON`** : Service should be started when the host starts up.
    *    - **`OFF`** : Service should not be started when the host starts up.
    *    - **`AUTOMATIC`** : Service should run if and only if it has open firewall ports.
    */
   angular
         .module('com.vmware.vsphere.client.host')
         .constant('vmStartupShutdownConstants', {
            category: {
               MANUAL: 'Manual',
               AUTOMATIC: 'Automatic',
               AUTOMATIC_ORDERED: 'Automatic Ordered'
            },
            state: {
               UP: 'up',
               DOWN: 'down',
               EDIT: 'edit'
            },
            startAction: {
               NONE: 'none',
               POWER_ON_LOWERCASE: 'poweron',
               POWER_ON_API_VALUE: 'powerOn'
            },
            heartbeat: {
               YES: 'yes',
               NO: 'no',
               SYSTEM_DEFAULT: 'systemDefault'
            },
            SYSTEM_DEFAULT: 'systemDefault',
            USER_DEFINED: 'userDefined',
            SYSTEM_DEFAULT_DELAY: -1,
            MAX_ALLOWED_DELAY:65535
         });
})();
