(function() {
   /**
    * Directive for HA heartbeat datastores.
    */
   'use strict';
   angular.module('com.vmware.vsphere.client.cluster')
      .directive('heartbeatDatastores', heartbeatDatastores);

   heartbeatDatastores.$inject = ['i18nService', 'heartbeatDatastoresService',
      'defaultUriSchemeUtil'];

   function heartbeatDatastores(i18nService, heartbeatDatastoresService,
         defaultUriSchemeUtil) {
      return {
         restrict: 'E',
         templateUrl: 'cluster-ui/resources/cluster/views/settings/services/ha/heartbeatDatastores.html',
         scope: {
            data: '=',
            isEditMode: '=',
            datastoresGridLabel: '='
         },
         controller: ['$scope', '$element', function($scope, $element) {
            var DS_KENDO_GRID_SELECTOR = 'div.heartbeat-datastores div[kendo-grid]';
            var dsKendoGrid;

            $scope.hostsGridLabel = getString('ha.config.ds.host.mounting.datastores');

            $scope.datastoresGridOptions =
               heartbeatDatastoresService.getDatastoreGridOptions($scope.isEditMode);
            $scope.hostsGridOptions = heartbeatDatastoresService.getHostGridOptions(
                  $scope.isEditMode, hostsGridDataBound);

            //region Event handlers

            // listener for scope.data changed event in order to bind the
            // heartbeat datastores to the ds gird
            $scope.$watch('data', function(data) {
               var dsData = !!data ? data : [];
               $scope.datastoresGridOptions.data = dsData;

               if ($scope.isEditMode) {
                  // add checkbox click handler
                  dsKendoGrid = $element.find(DS_KENDO_GRID_SELECTOR);
                  dsKendoGrid.on('click', '.row-checkbox', checkboxClickHandler);
               }
            });

            // listener for datastore selection changed event in order to get the hosts
            // attached to that datastore
            $scope.$watch(
               function() {
                  return $scope.datastoresGridOptions.selectedItems;
               },
               selectedDatastoreChangeHandler);

            $scope.$on('$destroy', function(){
               if ($scope.isEditMode) {
                  dsKendoGrid.off('click', '.row-checkbox', checkboxClickHandler);
               }
            });

            function checkboxClickHandler(event) {
               var checkedRow = $(event.target).closest('tr');
               var kendoDataItem = dsKendoGrid.data('kendoGrid').dataItem(checkedRow);

               var selectedDsUid =
                     defaultUriSchemeUtil.getVsphereObjectId(kendoDataItem.provider);

               for (var i = 0; i < $scope.data.length; i++) {
                  var candidateDsData = $scope.data[i];
                  var candidateDsUid =
                        defaultUriSchemeUtil.getVsphereObjectId(candidateDsData.provider);
                  if (_.isEqual(candidateDsUid, selectedDsUid)) {
                     candidateDsData.isPreferred = event.target.checked;
                     break;
                  }
               }
            }

            function hostsGridDataBound() {
               $element.find('[vui-datagrid] .k-grid-content').css('height', '');
               kendo.resize($element);
            }

            //endregion

            function getString(key) {
               return i18nService.getString('ClusterUi', key);
            }

            //region Hosts grid functions


            function selectedDatastoreChangeHandler(selectedDsItems) {
               if (selectedDsItems.length === 0) {
                  $scope.hostsGridOptions.data = [];
                  return;
               }

               var selectedDsItem = selectedDsItems[0];
               heartbeatDatastoresService
                     .getDatastoreHosts(selectedDsItem.accessibleAndMountedHosts)
                     .then(function(hostsData) {
                        $scope.hostsGridOptions.data = hostsData;
                     });
            }
            //endregion
         }]
      };
   }
})();
