/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace dvs_ui {

   import IRootScopeService = angular.IRootScopeService;

   export class DvsEditSettingsDialogService {

      public static $inject = [
         "$rootScope",
         "vxMultiPageDialogService",
         "i18nService",
         "dataService",
         "mutationService",
         "authorizationService",
         "networkUiConstants",
         "dvsEditSettingsGeneralPageValidator",
         "dvsEditSettingsAdvancedPageValidator",
         "dvsEditSettingsDialogManagerFactory"
      ];

      public static DATA_PROPERTY: string = "dvsedit:settingsdata";

      public static DVS_MODIFY_PRIVILEGE: string = "DVSwitch.Modify";

      public static DVS_RES_MGMT_PRIVILEGE: string = "DVSwitch.ResourceManagement";

      constructor(private $rootScope: IRootScopeService,
                  private vxMultiPageDialogService: any,
                  private i18nService: any,
                  private dataService: any,
                  private mutationService: any,
                  private authorizationService: any,
                  private networkUiConstants: any,
                  private generalPageValidator: DvsEditSettingsGeneralPageValidator,
                  private advancedPageValidator: DvsEditSettingsAdvancedPageValidator,
                  private managerFactory: DvsEditSettingsDialogManagerFactory) {
      }

      /**
       * Shows the Edit DVS settings dialog.
       */
      public show(dvsUrn: string): void {
         let dialogScope: any = this.$rootScope.$new();

         let dialogManager: DvsEditSettingsDialogManager =
               this.managerFactory.createManager(dialogScope);

         dialogScope.dvsUrn = dvsUrn;

         dialogScope.generalPageModel = new DvsEditSettingsGeneralPageModel();

         dialogScope.advancedPageModel = new DvsEditSettingsAdvancedPageModel();

         dialogScope.dialogOptions = this.createDialogOptions();

         dialogScope.dialogOptions.pages = this.createPages(dialogManager);
         dialogScope.dialogOptions.confirmOptions = {
            onClick: () => {
               return dialogManager.submitDialog();
            }
         };

         let options: any = {
            scope: dialogScope,
            configObjectName: "dialogOptions"
         };

         this.vxMultiPageDialogService(options);

         this.requestData(dialogScope);

         this.requestPrivileges(dialogScope);
      }

      private requestData(dialogScope: any): void {
         this.dataService.getProperties(
               dialogScope.dvsUrn, [DvsEditSettingsDialogService.DATA_PROPERTY])
               .then((result: any) => {
                        if (result && result[DvsEditSettingsDialogService.DATA_PROPERTY]) {
                           let data: any = result[DvsEditSettingsDialogService.DATA_PROPERTY];

                           let trimmedName: string = data.name.trim();
                           let escapedName: string = trimmedName.replace(/%25/g, "%")
                                 .replace(/%2f/g, "\/").replace(/%5c/g, "\\");

                           data.name = escapedName;
                           dialogScope.generalPageModel.name = data.name;
                           dialogScope.generalPageModel.description = data.description;
                           dialogScope.generalPageModel.maxNumUplinks = data.maxNumUplinks;
                           dialogScope.generalPageModel.uplinkPortNames = data.uplinkPortNames;
                           dialogScope.generalPageModel.isNiocEnabled = data.isNiocEnabled;
                           dialogScope.generalPageModel.isNiocSupported = data.isNiocSupported;

                           dialogScope.advancedPageModel.mtu = data.mtu;
                           dialogScope.advancedPageModel.multicastFilteringMode =
                                 data.multicastFilteringMode;
                           dialogScope.advancedPageModel.multicastSnoopingSupported =
                                 data.multicastSnoopingSupported;

                           dialogScope.advancedPageModel.lldpSupported = data.lldpSupported;
                           if (data.ldpOperation !== this.networkUiConstants.LinkDiscoveryOperation.DISABLED) {
                              dialogScope.advancedPageModel.ldpProtocol = data.ldpProtocol;
                              dialogScope.advancedPageModel.ldpOperation = data.ldpOperation;
                           } else {
                              dialogScope.advancedPageModel.ldpProtocol =
                                    DvsEditSettingsAdvancedPageModel.LDP_PROTOCOL_DISABLED;
                              dialogScope.advancedPageModel.ldpOperation =
                                    this.networkUiConstants.LinkDiscoveryOperation.LISTEN;
                           }

                           dialogScope.advancedPageModel.contactName = data.contactName;
                           dialogScope.advancedPageModel.contactDetails = data.contactDetails;

                           dialogScope.originalData = data;
                           dialogScope.originalData.initialLdpProtocol =
                                 dialogScope.advancedPageModel.ldpProtocol;
                           dialogScope.originalData.initialLdpOperation =
                                 dialogScope.advancedPageModel.ldpOperation;

                           dialogScope.dialogOptions.title =
                                 this.i18nService.getString("NetworkUi", "DvsConfigPage.titleFormat", data.name);
                           dialogScope.dialogOptions.loading = false;
                        }
                     }
               );
      }

      private requestPrivileges(dialogScope: any): void {
         let privileges: string[] = [
            DvsEditSettingsDialogService.DVS_MODIFY_PRIVILEGE,
            DvsEditSettingsDialogService.DVS_RES_MGMT_PRIVILEGE
         ];

         this.authorizationService.checkPrivileges(dialogScope.dvsUrn, privileges)
               .then((result: any) => {
                  if (result) {
                     dialogScope.generalPageModel.hasModifyPrivilege = result[0];
                     dialogScope.generalPageModel.hasNiocPrivilege = result[1];
                     dialogScope.advancedPageModel.hasModifyPrivilege = result[0];
                  }
               });
      }

      private createDialogOptions(): any {
         return {
            title: this.i18nService.getString("NetworkUi", "DvsConfigPage.initialTitle"),
            iconClass: "network-lib-ui-icon-dvsEdit",
            show: true,
            loading: true,
            loadingMessage: this.i18nService.getString("CommonUi", "wizard.loading"),
            validationBanner: {},
            rejectOptions: {
               rejectOnEsc: true
            },
            resizable: true,
            draggable: true,
            maximizable: true
         };
      }

      private createPages(dialogManager: DvsEditSettingsDialogManager): any[] {
         let pages: any = [];

         // General page
         pages.push({
               title: this.i18nService.getString(
                     "NetworkUi", "DvsConfigPage.general.title"),
               contentUrl: "dvs-ui/resources/dvs/settings/edit/pages/dvsEditSettingsGeneralPage.html",
               onCommit: () => {
                  let errors: string[] =
                        this.generalPageValidator.getAllErrors(dialogManager.dialogScope.generalPageModel);

                  if (errors && errors.length > 0) {
                     dialogManager.showDialogErrors(errors);
                     return false;
                  } else {
                     return this.generalPageValidator.getNameServerError(
                           dialogManager.dialogScope.dvsUrn,
                           dialogManager.dialogScope.generalPageModel.name)
                                 .then(function (error: any) {
                                    if (error) {
                                       dialogManager.showDialogErrors([error]);
                                       return false;
                                    }

                                    dialogManager.dialogScope.generalPageModel
                                          .updateUplinkPortNames();
                                    return true;
                                 });
                  }
               }
            });

         // Advanced page
         pages.push({
            title: this.i18nService.getString(
                  "NetworkUi", "DvsConfigPage.advanced.title"),
            contentUrl: "dvs-ui/resources/dvs/settings/edit/pages/dvsEditSettingsAdvancedPage.html",
            onCommit: () => {
               let errors: string[] =
                     this.advancedPageValidator.getAllErrors(dialogManager.dialogScope.advancedPageModel);

               if (errors && errors.length > 0) {
                  dialogManager.showDialogErrors(errors);
                  return false;
               }

               return true;
            }
         });

         return pages;
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .service("dvsEditSettingsDialogService", DvsEditSettingsDialogService);
}
