module cluster_ui {

   export class DpmAutomationPageService {

      public static $inject = ["i18nService", "dpmThresholdService"];

      public constructor(private i18nService: any, private dpmThresholdService: any) {}


      public getDpmAutomationLevelSignPostParams(): any {
         let description: string =
               this.getString("drs.config.dpm.description");

         let overridesLabel: string =
               this.getString("drs.config.dpm.overridesLabel");

         let message: string = description + " " + overridesLabel;

         return {
            title: this.getString("help", "Common"),
            message: message
         };
      }

      public getDpmAutomationLevelValuesSignPostParams(dpmAutomationLevelEnabled: boolean, automationLevelId: string): any {

         let automationLevelValueDescriptions: any =
               this.getAutomationLevelValueDescriptions(automationLevelId);

         let message: string =
               dpmAutomationLevelEnabled ? automationLevelValueDescriptions :
                     this.getString("drs.config.dpm.disabledDescription");
         return {
            title: this.getString("help", "Common"),
            message: message
         };
      }

      public getAutomationLevelValueDescriptions(automationLevelId: string): string {
         let automationLevelValues: any = {
            "manual": this.getString("drs.config.dpm.automationLevel.manualDescription"),
            "automated": this.getString("drs.config.dpm.automationLevel.automatedDescription"),
         };
         return automationLevelValues[automationLevelId];
      }

      public getDpmThresholdDescription(dpmThreshold: any): string {
         return this.dpmThresholdService.getText(true, dpmThreshold);
      }

      public getSliderOptions(dragHandleTitle: string) {
         return {
            value: this.dpmThresholdService.DEFAULT,
            min: this.dpmThresholdService.MIN,
            max: this.dpmThresholdService.MAX,
            minLabel: this.dpmThresholdService.MIN_LABEL,
            maxLabel: this.dpmThresholdService.MAX_LABEL,
            enabled: true,
            width: 'auto',
            dragHandleTitle: dragHandleTitle
         };
      }

      private getString(key: string, bundle?: string): string {
         return bundle ? this.i18nService.getString(bundle, key) :
               this.i18nService.getString("ClusterUi", key);
      }

   }

   angular.module("com.vmware.vsphere.client.cluster")
         .service("dpmAutomationPageService", DpmAutomationPageService);
}



