(function () {
   'use strict';
   angular.module('com.vmware.vsphere.client.vm')
      .service('VmProvisioningNetworkPageModel', VmProvisioningNetworkPageModel);

   VmProvisioningNetworkPageModel.$inject = [
      'mutationService',
      'dataService',
      'defaultUriSchemeUtil',
      'i18nService',
      'wizardPageService'
   ];

   function VmProvisioningNetworkPageModel(
         mutationService,
         dataService,
         defaultUriSchemeUtil,
         i18nService,
         wizardPageService) {

      var NETWORK_VALIDATION_SPEC_NAME = 'com.vmware.vsphere.client.provisioning.spec.NetworkConfigValidationSpec';
      var NETWORK_MAPPINGS_VALIDATION_SPEC_NAME = 'com.vmware.vsphere.client.provisioning.spec.NetworkMappingsValidationSpec';
      var NETWORK_MAPPINGS_SPEC_NAME = 'com.vmware.vsphere.client.provisioning.spec.NetworkMappingsSpec';
      var NETWORK_MAPPING_SPEC_NAME = "com.vmware.vsphere.client.provisioning.spec.NetworkMapping";
      var IP_ALLOCATION_VALIDATION_SPEC_NAME = 'com.vmware.vsphere.client.provisioning.spec.IpAllocationValidationSpec';

      var networkMappingsForNetworkParams;

      return function (wizardViewData, virtualMachineSpecBuilder, wizardConfig) {

         return {
            getNetworkMappingParams: function () {
               var commonContext = wizardViewData.getDeployFromLibraryCommonContext();
               getNetworkMappings(commonContext).then(function (result) {
                  if (result.networkMappingsForNetworkParams) {
                     networkMappingsForNetworkParams = result.networkMappingsForNetworkParams.networkMappings;
                  }
               });
               return getNetworkMappingParams(commonContext);
            },
            getIpAllocationParams: function () {
               var commonContext = wizardViewData.getDeployFromLibraryCommonContext();
               return getIpAllocationParams(commonContext);
            },
            validatePage: function () {
               wizardPageService.clearErrors(wizardConfig);
               wizardPageService.showLoadingIndicator(wizardConfig);
               var commonContext = wizardViewData.getDeployFromLibraryCommonContext();
               var networkConfigValidationSpec = buildNetworkConfigValidationSpec(commonContext);

               return mutationService.validate(virtualMachineSpecBuilder.getComputeResourceId(),
                     NETWORK_VALIDATION_SPEC_NAME,
                     networkConfigValidationSpec).then(function (result) {

                  wizardPageService.hideLoadingIndicator(wizardConfig);
                  if (result.error) {
                     var errorMessage = getIpPoolValidationErrorMessage(commonContext);
                     if (errorMessage) {
                        result.error = errorMessage;
                     }
                     return result;
                  }
               });
            },
            submitPage: function () {
               return {
                  invalidateNextPages: false,
                  wizardFlow: virtualMachineSpecBuilder.getCreationType()
               };
            }
         };

         function getNetworkMappingParams(commonContext) {
            if (commonContext) {
               var networkMappingParams = _.find(commonContext, function (item) {
                  return item._type === 'com.vmware.vcenter.ovf.NetworkMappingParams';
               });
               return networkMappingParams ? networkMappingParams : {};
            }
         }

         function getIpAllocationParams(commonContext) {
            if (commonContext) {
               var ipAllocationParams = _.find(commonContext, function (item) {
                  return item._type === "com.vmware.vcenter.ovf.IpAllocationParams";
               });
               return ipAllocationParams ? ipAllocationParams : {};
            }
         }

         function buildNetworkConfigValidationSpec(commonContext) {
            return {
               _type: NETWORK_VALIDATION_SPEC_NAME,
               networkMappingsValidationSpec: buildNetworkMappingsValidationSpec(commonContext),
               ipAllocationValidationSpec: buildIpAllocationValidationSpec(commonContext)
            };
         }

         function buildNetworkMappingsValidationSpec(commonContext) {
            return {
               _type: NETWORK_MAPPINGS_VALIDATION_SPEC_NAME,
               commonContext: commonContext,
               mappingSpec: {
                  _type: NETWORK_MAPPINGS_SPEC_NAME,
                  networkMappings: buildNetworkMappingParams(commonContext)
               }
            };
         }

         function buildNetworkMappingParams(commonContext) {
            var sourceNetworks = getNetworkMappingParams(commonContext).sourceNetworks;
            return _.map(sourceNetworks, function (sourceNetwork) {
               var targetNetworkRef;
               var targetNetwork = findTargetNewtorkForUri(sourceNetwork.target);
               if (targetNetwork){
                  targetNetworkRef = targetNetwork.targetNetworkRef;
               }
               return {
                  _type: NETWORK_MAPPING_SPEC_NAME,
                  sourceNetworkName: sourceNetwork.name,
                  sourceNetworkDescription: sourceNetwork.description,
                  targetNetworkUri: sourceNetwork.target,
                  targetNetworkRef: targetNetworkRef
               };
            });
         }

         function buildIpAllocationValidationSpec(commonContext) {
            var ipAllocationParams = getIpAllocationParams(commonContext);
            return {
               _type: IP_ALLOCATION_VALIDATION_SPEC_NAME,
               commonContext: commonContext,
               ovfIpAllocationParams: !_.isEmpty(ipAllocationParams) ? ipAllocationParams : null
            };
         }

         function getNetworkMappings(commonContext) {
            var provisioningUri = defaultUriSchemeUtil.createUri(
                  "com.vmware.vsphere.client.provisioning.Context", "root");
            return dataService.getProperties(provisioningUri, ['networkMappingsForNetworkParams'],
                  {
                     propertyParams: [{
                        propertyName: 'networkMappingsForNetworkParams',
                        parameterType: 'com.vmware.vcenter.ovf.NetworkMappingParams',
                        parameter: getNetworkMappingParams(commonContext)
                     }]
                  });
         }

         function findTargetNewtorkForUri(uri) {
            return _.find(networkMappingsForNetworkParams, function (item) {
               return (item.targetNetworkUri === uri);
            });
         }

         function getIpPoolValidationErrorMessage(commonContext) {
            var ipAllocationParams = getIpAllocationParams(commonContext);
            if(!_.isEmpty(ipAllocationParams)) {
               if (ipAllocationParams.ipProtocol.name === 'IPV4') {
                  return i18nService.getString('ProvisioningUiLib', 'IpAllocationControl.noIpv4RangeWarning');
               } else {
                  return i18nService.getString('ProvisioningUiLib', 'IpAllocationControl.noIpv6RangeWarning');
               }
            }
         }
      };
   }
}());
