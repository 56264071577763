/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace dvs_ui {

   export class DvsImportComponent {

      public bindings: any;
      public controller: any;
      public templateUrl: string;

      constructor() {
         this.bindings = {
            wizardModel: "<"
         };

         this.controller = DvsImportComponentController;

         this.templateUrl =
               "dvs-ui/resources/dvs/import/dvsImportComponentTemplate.html";
      }
   }

   export class DvpgRestoreComponent {

      public bindings: any;
      public controller: any;
      public templateUrl: string;

      constructor() {
         this.bindings = {
            wizardModel: "<"
         };

         this.controller = DvsImportComponentController;

         this.templateUrl =
               "dvs-ui/resources/dvs/import/dvpgRestoreComponentTemplate.html";
      }
   }

   class DvsImportComponentController {

      static $inject = [
         "i18nService",
         "exportDvsConstants",
         "$element",
         "$timeout"
      ];

      public wizardModel: DvsImportWizardModel;

      private _fileReader: FileReader;

      private hintLabel: string = "";

      private readonly FILE_INPUT_SELECTOR: string =
            ".import-dvs-upload-file-input";

      constructor(private i18nService: any,
                  private exportDvsConstants: any,
                  private $element: any,
                  private $timeout: any) {
      }

      public $onInit() {
         let ctrl = this;

         this.hintLabel = this.wizardModel.isDvpgConfig ?
               this.i18nService.getString(
                     "DvsUi", "dvpg.import.filePage.hintLabel") :
               this.i18nService.getString(
                     "DvsUi", "dvs.import.wizard.filePage.hintLabel");
         let dvsImportFileInput =
               this.$element.find(this.FILE_INPUT_SELECTOR);
         dvsImportFileInput.on("change", function (this: any, event: any) {
            if (event.target.files && event.target.files.length) {
               ctrl.setImportFile(event.target.files[0]);
            }
         });
      }

      private setImportFile(file: File) {
         // Clear out any previous configuration data until the new file
         // is completely read from the file system.
         this.wizardModel.configurationPageModel.configurationData = "";
         this._fileReader = new FileReader();
         if (this._fileReader && file) {
            this._fileReader.readAsArrayBuffer(file);
            this._fileReader.onload = this.onLoad.bind(this);
            this.wizardModel.configurationPageModel.fileLocation = file.name;
         }
      }

      private onLoad() {
         let rawData = this._fileReader.result;
         let byteArray: any = new Uint8Array(rawData);
         this.saveByteArray(byteArray);
      }

      private saveByteArray(byteArray: Uint8Array) {
         if (this.wizardModel.isRestoreAction && this.wizardModel.isDvpgConfig) {
            if (this.wizardModel.configurationPageModel.restoreType ===
                  this.exportDvsConstants.configurationRestoreTypes.FROM_FILE) {
               this.wizardModel.configurationPageModel.restoreDvpgConfigurationData =
                     [].slice.call(byteArray);
            }
         } else {
            let binaryString =
                  this.arrayBufferToString(byteArray);
            binaryString = window.btoa(binaryString);
            this.wizardModel.configurationPageModel.configurationData =
                  binaryString;
         }
      }

      public onBrowseButtonClicked() {
         let fileInput = this.$element.find(this.FILE_INPUT_SELECTOR);
         this.$timeout(function () {
            fileInput.click();
         });
      }

      /**
       * Converts a Uint8Array to a string in chunks so that
       * RangeError is prevented when Uint8Array is of a large size.
       *
       * @param {Uint8Array} byteArray
       *
       * @returns {string}
       */
      public arrayBufferToString(byteArray: Uint8Array): string {

         let length: number = byteArray.length;
         let result: string = "";

         // Chunk size less than the allowed maximum number of arguments in
         // JavaScript but big enough to achieve a good performance.
         let chunkSize: number = Math.pow(2, 16) - 1;

         for (let i: number = 0; i < length; i += chunkSize) {

            if (i + chunkSize > length) {
               chunkSize = length - i;
            }

            // Without dividing the byte array into chunks,
            // the String.fromCharCode.apply method throws
            // "Maximum call stack size exceeded" error when the file size is
            // big enough.
            result += String.fromCharCode.apply(
                  null, byteArray.subarray(i, i + chunkSize));
         }

         return result;

      }

   }

   angular.module("com.vmware.vsphere.client.dvs")
         .component("dvsImportComponent",
               new DvsImportComponent());

   angular.module("com.vmware.vsphere.client.dvs")
         .component("dvpgRestoreComponent",
               new DvpgRestoreComponent());
}
