/* Copyright 2018 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace h5_vm {
   export class VmStorageConfig {
      vmId: string;
      vmHome: VmComponentStorageConfig;
      vmDisks: VmComponentStorageConfig[];

      public getVmHomeProfile(): { id: string; } | undefined {
         return this.vmHome && this.vmHome.storageProfile;
      }

      public getVmHomeStorage(): any {
         return this.vmHome && this.vmHome.storageObj;
      }

      public getReplicationGroupAssignments(): any[] {
         let result: any[] = [];
         if (this.vmHome && this.vmHome.replicationGroup) {
            result.push(this.vmHome.replicationGroup);
         }
         _.forEach(this.vmDisks, function(diskConfig: VmComponentStorageConfig) {
            if (diskConfig && diskConfig.replicationGroup) {
               result.push(diskConfig.replicationGroup);
            }
         });
         return result;
      }
   }
}
