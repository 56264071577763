namespace storage_ui {

   import IComponentController = angular.IComponentController;

   export class ProtocolEndpointsDatastoreListController
         implements IComponentController {

      public static $inject = ["navigation", "vuiConstants", "$timeout"];

      private selectedProtocolEndpoints: HostProtocolItem[];
      private filterParams: string[] = [];
      private hostId: string;

      constructor(navigation: any, private vuiConstants: any, private $timeout: any) {
         this.hostId = navigation.getRoute().objectId;
      }

      public $onChanges(): void {
         this.filterParams = [];
         if (this.selectedProtocolEndpoints
               && this.selectedProtocolEndpoints.length === 1) {

            // Needed because nothing watches the filterParams array in the list-view.
            // That's why we first destroy the whole list view with
            // "this.filterParams = []" and then trigger the re-rendering of the
            // whole list-view by assigning an array to filterParams in the
            // next browser cycle.
            this.$timeout(() => {
               this.filterParams = [this.selectedProtocolEndpoints[0].uuid];
            }, 0);
         }
      }
   }

   angular.module("com.vmware.vsphere.client.storage")
         .component("protocolEndpointsDatastoresList", {
            templateUrl: "storage-ui/resources/storage/views/host/protocolEndpoints/ProtocolEndpointsDatastoresListComponent.html",
            bindings: {
               selectedProtocolEndpoints: "<"
            },
            controller: ProtocolEndpointsDatastoreListController
         });
}
