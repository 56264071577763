namespace dvs_ui {

   import IPromise = angular.IPromise;
   import ValidationResult = com.vmware.vise.core.model.ValidationResult;
   import DvsSpanSessionNameSpec =
         com.vmware.vsphere.client.h5.network.dvs.portmirroring.model.wizard.DvsSpanSessionNameSpec;

   class IntegerInterval {
      private _beginning: number;
      private _end: number;

      constructor(beginning: number, end: number) {
         this._beginning = Math.floor(beginning);
         this._end = Math.floor(end);
      }

      public get beginning(): number {
         return this._beginning;
      }

      public get end(): number {
         return this._end;
      }
   }

   export class DvsSpanSessionPropertiesValidator {

      public static $inject = [
         "i18nService",
         "mutationService"
      ];

      public static readonly NAME_MAX_LENGTH: number = 80;

      public static readonly SESSION_ID_INTERVAL: IntegerInterval =
            new IntegerInterval(0, 1023);

      public static readonly PACKET_LENGTH_INTERVAL: IntegerInterval =
            new IntegerInterval(60, 9000);

      public static readonly SAMPLING_RATE_INTERVAL: IntegerInterval =
            new IntegerInterval(1, 65535);

      public static readonly ENCAPSULATION_VLAN_INTERVAL: IntegerInterval =
            new IntegerInterval(1, 4094);

      public static readonly DESCRIPTION_MAX_LENGHT: number = 255;

      constructor(protected i18nService: any,
                  protected mutationService: any) {
      }

      public getNameError(name: string): string|null {
         let trimmedName: string = name ? name.trim() : "";
         let escapedName: string = trimmedName.replace(/%/g, "%25")
               .replace(/\//g, "%2f").replace(/\\/g, "%5c");

         if (escapedName.length === 0) {
            return this.i18nService.getString("DvsUi",
                  "SpanSession.error.blankName");
         } else if (escapedName.length > DvsSpanSessionPropertiesValidator.NAME_MAX_LENGTH) {
            return this.i18nService.getString("DvsUi",
                  "SpanSession.error.tooLongName");
         }

         return null;
      }

      public getNameServerError(urn: string, name: string): IPromise<string|null> {
         let spec: DvsSpanSessionNameSpec = new DvsSpanSessionNameSpec();
         spec.name = name;
         return this.mutationService.validate(urn, spec._type, spec)
               .then((validationResult: ValidationResult): string|null => {
                  if (validationResult.result) {
                     return validationResult.result;
                  }

                  return null;
               }, (): string|null => {
                  return this.i18nService.getString("DvsUi", "networkConnectionError");
               });
      }

      public getSessionIdError(sessionId: number): string|null {
         if (!this.isInteger(sessionId) || !this.isWithinInterval(sessionId,
               DvsSpanSessionPropertiesValidator.SESSION_ID_INTERVAL)) {
            return this.i18nService.getString("DvsUi", "SpanSession.error.sessionId");
         }

         return null;
      }

      public getPacketLengthError(packetLength: number): string|null {
         if (!this.isInteger(packetLength) || !this.isWithinInterval(packetLength,
               DvsSpanSessionPropertiesValidator.PACKET_LENGTH_INTERVAL)) {
            return this.i18nService.getString("DvsUi", "SpanSession.error.packetLength");
         }

         return null;
      }

      public getSamplingRateError(samplingRate: number): string|null {
         if (!this.isInteger(samplingRate) || !this.isWithinInterval(samplingRate,
                     DvsSpanSessionPropertiesValidator.SAMPLING_RATE_INTERVAL)) {
            return this.i18nService.getString("DvsUi", "SpanSession.error.samplingRate");
         }

         return null;
      }

      public getEncapsulationVlanIdError(encapsulationVlanId: number): string|null {
         if (!this.isInteger(encapsulationVlanId) || !this.isWithinInterval(encapsulationVlanId,
                     DvsSpanSessionPropertiesValidator.ENCAPSULATION_VLAN_INTERVAL)) {
            return this.i18nService.getString("DvsUi", "SpanSession.error.encapsulationVlan");
         }

         return null;
      }

      public getDescriptionError(description: string): string|null {
         if (description &&
               description.length > DvsSpanSessionPropertiesValidator.DESCRIPTION_MAX_LENGHT) {
            return this.i18nService.getString(
                  "DvsUi", "SpanSession.error.description");
         }

         return null;
      }

      private isWithinInterval(value: number, interval: IntegerInterval): boolean {
         return value >= interval.beginning && value <= interval.end;
      }

      private isInteger(value: number): boolean {
         return value % 1 === 0;
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .service("dvsSpanSessionPropertiesValidator", DvsSpanSessionPropertiesValidator);
}