module ds_cluster_ui {

   export interface SdrsDetailsViewScope extends angular.IScope {
      openHelpLink: Function;
   }

   export class DsClusterFaultsDetailsViewController {
      public faultDetailsTitle: string;
      public detailsViewGridOptions: any;
      public drsTroubleshootHelpId: string;

      public static $inject = ["$scope", "i18nService", "drsFaultsViewService",
            "dsClusterHelpConstants", "helpService", "sdrsFaultsService"];

      constructor(private $scope: SdrsDetailsViewScope, private i18nService: any,
            private drsFaultsViewService: any, private dsClusterHelpConstants: any,
            private helpService: any, private sdrsFaultsService: SdrsFaultsService) {
         this.detailsViewGridOptions =
               this.drsFaultsViewService.getDetailsViewGridOptions();
         this.faultDetailsTitle = i18nService.getString(
               "Common", "drs.faultControl.details.sdrsState.headerLabel");
         this.drsTroubleshootHelpId =
               this.dsClusterHelpConstants.SDRS_FAULT_TROUBLESHOOT_GUIDE_ID;

         $scope.$watch("masterDetailsViewContext.selectedItems", (newValue: any) => {
            if (newValue && newValue.length > 0) {
               this.setDetailsViewGridData(newValue);
            }
         });

         $scope.openHelpLink = (contextId: string): void => {
            this.helpService.showHelpPage(contextId);
         };
      }

      private setDetailsViewGridData(faultData: any): void {
         let data: any[] = [];

         faultData[0].forEach((fault: any) => {
            fault.details.forEach((detail: any) => {
               let gridItem: any = this.sdrsFaultsService.getFaultDetailsData(detail);

               if (gridItem) {
                  data.push(gridItem);
               }
            });
         });

         this.detailsViewGridOptions.data = data;
      }
   }

   angular.module("com.vmware.vsphere.client.dsCluster")
         .controller("DsClusterFaultsDetailsViewController",
               DsClusterFaultsDetailsViewController);
}
