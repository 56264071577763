/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace spbm_ui {

   declare let h5: any;

   h5 = h5 || {};
   h5.spbm = {
      angularModule: angular.module('com.vmware.vsphere.client.spbm', ['ngCookies'])
   };

   angular.module('com.vmware.vsphere.client.spbm')
         .run(['columnRenderersRegistry', 'i18nService',
            function (columnRenderersRegistry: any, i18nService: any) {
               columnRenderersRegistry.registerColumnRenderers({
                  /**
                   * Vcenter name column - icon-text renderer.
                   *
                   * @param  {array} props Array of property names, where 1st property is
                   *    vcenter name. Other properties are ignored.
                   * @param  {object} data  Map of values, contains the specificType.
                   */
                  'storage-policy-vc-name': function (props: any[], data: any) {
                     let iconTextRenderer = columnRenderersRegistry.getColumnRenderer('icon-text');
                     return iconTextRenderer(
                           ['vcIcon', 'vcName'], {
                              vcIcon: 'vsphere-icon-vcenter',
                              vcName: data[props[0]]
                           });
                  }
               });

               columnRenderersRegistry.registerColumnRenderers({
                  /**
                   * Text renderer that localizes lineOfService values.
                   *
                   * @param  {array} props Array of property names, where 1st property is
                   *    lineOfService name. Other properties are ignored.
                   * @param  {object} data  Map of values, contains the unlocalized
                   *    lineOfService value.
                   */
                  "spbm-localized-line-of-service": function (props: any[], data: any) {
                     let localizedLineOfService: string = "";
                     if (data[props[0]]) {
                        let resourceKey: string = "lineOfService." + data[props[0]];
                        localizedLineOfService = i18nService.getString("SpbmUi", resourceKey);
                        // If resource key is not localized, return the raw lineOfService value
                        if (!localizedLineOfService || localizedLineOfService === resourceKey) {
                           localizedLineOfService = data[props[0]];
                        }
                     }

                     let textRenderer = columnRenderersRegistry.getColumnRenderer("text");
                     return textRenderer(["localizedLineOfService"],
                           { localizedLineOfService: localizedLineOfService });
                  }
               });

               columnRenderersRegistry.registerColumnRenderers({
                  /**
                   * VM policies compliance status column - icon-text renderer.
                   *
                   * @param  {array} props Array of property names, where 1st property is
                   *    compliance status.
                   * @param  {object} data  Map of values, contains the specificType.
                   */
                  'storage-policy-compliance-status': function (props: any[], data: any) {
                     let complianceStatus = data[props[0]];
                     let iconClass = "";
                     let complianceStatusKey = "";
                     switch (complianceStatus) {
                        case 'compliant':
                           complianceStatusKey = "complianceStatus.compliant";
                           iconClass = 'vsphere-icon-status-ok';
                           break;
                        case 'nonCompliant':
                           complianceStatusKey = "complianceStatus.nonCompliant";
                           iconClass = 'vsphere-icon-status-error';
                           break;
                        case 'outOfDate':
                           complianceStatusKey = "complianceStatus.outOfDate";
                           iconClass = 'vsphere-icon-status-error';
                           break;
                        case 'unknown':
                           complianceStatusKey = "complianceStatus.unknown";
                           iconClass = 'vui-icon-datagrid-status-unknown';
                           break;
                        case 'notApplicable':
                           complianceStatusKey = "complianceStatus.notApplicable";
                           iconClass = 'vui-icon-datagrid-status-notApplicable';
                           break;
                     }

                     let label = complianceStatusKey === "" ? complianceStatusKey
                           : i18nService.getString("SpbmUi", complianceStatusKey);

                     let iconTextRenderer = columnRenderersRegistry.getColumnRenderer('icon-text');
                     return iconTextRenderer(
                           ["complianceStatusIcon", "complianceStatusText"], {
                              complianceStatusIcon: iconClass,
                              complianceStatusText: label
                           });
                  }
               });

               columnRenderersRegistry.registerColumnRenderers({
                  /**
                   * VM name column - renders VM name as link to the Manage > Profiles view.
                   */
                  "spbm-compliance-vm-name": function (props: any[], data: any) {
                     let objectNameRenderer = columnRenderersRegistry.getColumnRenderer('object-name');
                     let config = {
                        navigatable: true,
                        extensionName: "vsphere.core.vm.manage.settings.spbmPolicies"
                     };
                     return objectNameRenderer(props, data, config);
                  }
               });
            }])
         .run(['checkVmRollupComplianceService', 'i18nService',
               function (checkVmRollupComplianceService: any, i18nService: any) {
            h5.actions['vsphere.core.vm.actions.checkVmRollupComplianceAction'] =
                  function (actionEval: any, availableTargets: any[]) {
                     if (!availableTargets || !availableTargets.length) {
                        return;
                     }
                     checkVmRollupComplianceService.invokeAction(availableTargets);
                  };
         }])
         .run(["spbmManageVmStorageProfilesActionService",
               function(spbmManageVmStorageProfilesActionService: any) {

            h5.actions["vsphere.core.pbm.storage.manageVmStorageProfilesAction"] =
                  function(actionEval: any, availableTargets: any[]) {
                     if (!availableTargets || !availableTargets.length) {
                        return;
                     }
                     spbmManageVmStorageProfilesActionService.openDialog(availableTargets);
                  };
         }])
         .run(['spbmReapplyStoragePolicyActionService',
               function (spbmReapplyStoragePolicyActionService: SpbmReapplyStoragePolicyActionService) {
            h5.actions["vsphere.core.pbm.storage.reapplyProfileForVms"] =
                  function (actionEval: any, availableTargets: any[]) {
                     if (!availableTargets || !availableTargets.length) {
                        return;
                     }
                     spbmReapplyStoragePolicyActionService.openDialogForVm(availableTargets);
                  };
         }])
         .run(['spbmReapplyStoragePolicyActionService',
               function (spbmReapplyStoragePolicyActionService: SpbmReapplyStoragePolicyActionService) {

            h5.actions["vsphere.core.pbm.storage.reapplyProfile"] =
                  function (actionEval: any, availableTargets: any[], context: any[]) {
                     if (!availableTargets || !availableTargets.length || !context || !context.length) {
                        return;
                     }
                     spbmReapplyStoragePolicyActionService.openDialogForProfile(context[0]);
                  };
         }])
         .run(['drpEditPolicyModalService', function (drpEditPolicyModalService: any) {
            h5.actions["vsphere.core.datastore.drp.edit"] =
                  (actionEval: any, availableTargets: any[]) => {

               if (!availableTargets || !availableTargets.length) {
                  return;
               }
               drpEditPolicyModalService.openModal(availableTargets[0]);
            };
         }])
         .run(['spbmFcdProfileService', function (spbmFcdProfileService: SpbmFcdProfileService) {
            h5.actions["vsphere.core.datastore.fcdNamespaceProfile.edit"] = (actionEval: any, availableTargets: any[]) => {
               if (!availableTargets || !availableTargets.length) {
                  return;
               }
               spbmFcdProfileService.updateFcdNamespaceProfile(availableTargets[0]);
            };
         }])
         .run(['spbmProfileCheckComplianceActionService',
               function (spbmProfileCheckComplianceActionService: any) {

            h5.actions["vsphere.core.pbm.storage.checkProfileComplianceActionPrivate"] =
                  (actionEval: any, availableTargets: any[], context: any) => {

               if (!context || !context.length) {
                  return;
               }

               spbmProfileCheckComplianceActionService.checkProfilesCompliance(context);
            };
         }])
         .run(['spbmRemoveProfileActionService', function (spbmRemoveProfileActionService: any) {
            h5.actions["vsphere.core.pbm.storage.removeProfileAction"] =
                  (actionEval:any, availableTargets:any[], context:any) => {
               if (!context || !context.length) {
                  return;
               }

               spbmRemoveProfileActionService.removeStoragePolicies(context);
            };
         }])
         .run(['spbmCreateProfileActionService', function (spbmCreateProfileActionService: any) {
            h5.actions["vsphere.core.pbm.storage.createStorageProfileAction"] =
                  (actionEval:any, availableTargets:any[], context: any) => {
               spbmCreateProfileActionService.createProfile();
            };

            h5.actions["vsphere.core.pbm.storage.editProfileAction"] =
                  (actionEval:any, availableTargets:any[], context: any)=>{
               if (!context) {
                  return;
               }

               spbmCreateProfileActionService.editProfile(context);
            };

            h5.actions["vsphere.core.pbm.storage.cloneProfileAction"] =
                  (actionEval:any, availableTargets:any[], context: any) => {
               if (!context) {
                  return;
               }

               spbmCreateProfileActionService.cloneProfile(context);
            };
         }])
         .run(['spbmDspActionService', function (spbmDspActionService: SpbmDspActionService) {
            h5.actions["vsphere.core.pbm.storage.createDspAction"] =
                  (actionEval:any, availableTargets:any[], context:any) => {
                     spbmDspActionService.createProfile();
                  };
            h5.actions["vsphere.core.pbm.storage.editDspAction"] =
                  (actionEval:any, availableTargets:any[], context:any) => {
                     if (context && context.length === 1) {
                        spbmDspActionService.editProfile(context[0]);
                     }
                  };
            h5.actions["vsphere.core.pbm.storage.cloneDspAction"] =
                  (actionEval:any, availableTargets:any[], context:any) => {
                     if (context && context.length === 1) {
                        spbmDspActionService.cloneProfile(context[0]);
                     }
                  };
            h5.actions["vsphere.core.pbm.storage.removeDspAction"] =
                  (actionEval:any, availableTargets:any[], context:any) => {
                     spbmDspActionService.removeProfiles(context);
                  };
         }])
         .run(['spbmResetDefaultVsanProfileActionService',
               function (spbmResetDefaultVsanProfileActionService: any) {
            h5.actions["vsphere.core.pbm.storage.resetVsanDefaultProfileAction"] =
                  (actionEval:any, availableTargets:any[], context:any) => {
               if (!context) {
                    return;
               }

               spbmResetDefaultVsanProfileActionService.resetDefaultVsanProfile(context);
            };
         }]);
}
