/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
(function () {
   'use strict';

   /**
    * @ngdoc service
    * @name com.vmware.vsphere.client.storage:storageUtil
    * @module com.vmware.vsphere.client.storage
    *
    * @description
    *    The `storageUtil` service holds storage related utility functions.
    *
    */
   angular.module('com.vmware.vsphere.client.storage')
         .factory('storageUtil', [
            'i18nService',
            'managedEntityConstants',
            'hostStorageConstants',
            'datastoreConstants',
            'clarityModalService',
            '$sce',
            function (i18nService, managedEntityConstants, hostStorageConstants, datastoreConstants, clarityModalService, $sce) {

            var BLOCK_SIZE_DEFAULT = 512;
            var VSTORAGE_SUPPORTED = "vstoragesupported";
            var VSTORAGE_UNSUPPORTED = "vstorageunsupported";

            return {
               compareNumericValues: compareNumericValues,
               formatDeviceName: formatDeviceName,
               formatLunNumber: formatLunNumber,
               lbaToBytes: lbaToBytes,
               formatVStorageSupport: formatVStorageSupport,
               formatSSDValue: formatSSDValue,
               formatVmfsLabel: formatVmfsLabel,
               formatDiskPartitionType: formatDiskPartitionType,
               getMaxDatastoreSizeForVmfsOption: getMaxDatastoreSizeForVmfsOption,
               getRescanWarning: getRescanWarning,
               getStorageArrayTypePolicyName: getStorageArrayTypePolicyName,
               formatPathSelectionPolicyLabel: formatPathSelectionPolicyLabel,
               getPathSelectionPolicyNameByKey: getPathSelectionPolicyNameByKey,
               isFixedPolicy: isFixedPolicy,
               isFreePartition: isFreePartition,
               formatPathSelectionPolicyName: formatPathSelectionPolicyName,
               is4kDevice: is4kDevice,
               getScsiPeTransport: getScsiPeTransport,
               getPEOperationalStateString: getPEOperationalStateString,
               showErrorDialog: showErrorDialog,
               getSectorFormatByScsiDiskType: getSectorFormatByScsiDiskType
            };

            function compareNumericValues (item1, item2, field) {
               var firstValue = Number(item1[field]);
               var secondValue = Number(item2[field]);

               if (isNaN(firstValue) && isNaN(secondValue)) {
                  return 0;
               }
               if (isNaN(firstValue)) {
                  return -1;
               }
               if (isNaN(secondValue)) {
                  return 1;
               }

               if (firstValue < secondValue) {
                  return -1;
               }
               if (firstValue > secondValue) {
                  return 1;
               }
               return 0;
            }

            /**
             * Given a ScsiDisk returns its display name if it's available, otherwise
             * returns it's canonical name.
             *
             * @param disk
             *       The ScsiDisk.
             *
             * @return
             *       The name of the device.
             */
            function formatDeviceName (disk) {
               if (disk.displayName) {
                  return disk.displayName;
               }

               return disk.canonicalName;
            }

            function formatLunNumber (lunNumber) {
               if (lunNumber === null || lunNumber === undefined || lunNumber === -1) {
                  return '';
               }
               return lunNumber;
            }

            function lbaToBytes (lba) {
               var blockSize = lba.blockSize || BLOCK_SIZE_DEFAULT;
               return lba.block * blockSize;
            }

            function formatVStorageSupport (vStorageSupport) {
               // The VStorageSupport is optional, so for legacy versions Not Supported
               // status is shown.
               if (!vStorageSupport || vStorageSupport.toLowerCase() === VSTORAGE_UNSUPPORTED) {
                  return i18nService.getString('StorageUi', 'datastore.vStorage.notSupported');
               }
               if (vStorageSupport.toLowerCase() === VSTORAGE_SUPPORTED) {
                  return i18nService.getString('StorageUi', 'datastore.vStorage.supported');
               }
               return i18nService.getString('StorageUi', 'datastore.vStorage.unknown');
            }

            function formatSSDValue (ssd) {
               if (ssd === true) {
                  return i18nService.getString('StorageUi', 'disk.ssd');
               }
               return i18nService.getString('StorageUi', 'disk.nonSSD');
            }

            /**
             * Extracts the VMFS label property of a given ScsiDiskInfo.
             *
             * @param scsiDiskInfo
             *       The ScsiDiskInfo which VMFS label will be extracted.
             *
             * @return
             *       The VMFS label property of the ScsiDiskInfo.
             */
            function formatVmfsLabel (scsiDiskInfo) {
               if (scsiDiskInfo.vmfsLabel && scsiDiskInfo.vmfsLabel !== '' &&
                     scsiDiskInfo.unresolvedExtent) {

                  if (scsiDiskInfo.unresolvedExtent.isHeadExtent === true) {
                     // If this is a head extent we should append '(head)' postfix
                     // to the VMFS label.
                     return i18nService.getString('StorageUi', 'headVmfsLabel.format', scsiDiskInfo.vmfsLabel);
                  } else {
                     return scsiDiskInfo.vmfsLabel;
                  }
               }
               return '';
            }

            /**
             * Given a disk partition type, returns
             * its corresponding localized string.
             *
             * @param type
             *          The disk partition type.
             *
             * @return
             *          Localized string representing the disk partition type.
             */
            function formatDiskPartitionType (partitionType) {
               var result = i18nService.getString('StorageUi', 'partitionFormat.unknown');
               if (partitionType === 'gpt') {
                  result = i18nService.getString('StorageUi', 'partitionFormat.gpt');
               } else if (partitionType === 'mbr') {
                  result = i18nService.getString('StorageUi', 'partitionFormat.mbr');
               }

               return result;
            }

            /**
             * Calculates number of disk blocks in a given block range.
             *
             * @param blockRange - The DiskPartitionInfo_BlockRange.
             * @return The number of blocks in the block range.
             */
            function getNumberOfBlocksForBlockRange (blockRange) {
               return blockRange.end.block - blockRange.start.block + 1;
            }

            /**
             * Calculates the size of a given block range.
             * @param range - The DiskPartitionInfo_BlockRange.
             * @return The size of the block range in bytes.
             */
            function getSizeOfBlockRange (range) {
               var blockSize = BLOCK_SIZE_DEFAULT;
               if (range.start.blockSize) {
                  blockSize = range.start.blockSize;
               }
               var numberOfBlocks = getNumberOfBlocksForBlockRange(range);
               return numberOfBlocks * blockSize;
            }

            /**
             * Computes the total capacity of all partitions in a given VmfsDatastoreOption.
             * This is the actual maximum size of the datastore.
             *
             * @param option
             *        The VmfsDatastoreOption.
             *
             * @return
             *        The sum of all partition sizes, or -1 if the option is invalid.
             */
            function getMaxDatastoreSizeForVmfsOption (option) {
               var result = 0;
               if (option && option.info) {
                  if (option.info._type === 'com.vmware.vim.binding.vim.host.VmfsDatastoreOption$SingleExtentInfo'
                        || option.info._type === 'com.vmware.vim.binding.vim.host.VmfsDatastoreOption$AllExtentInfo') {
                     // We have a single extent - compute its size.
                     result = getSizeOfBlockRange(option.info.vmfsExtent);
                  } else if (option.info._type === 'com.vmware.vim.binding.vim.host.VmfsDatastoreOption$MultipleExtentInfo') {
                     // We have multiple extents, so we need to compute their sum.
                     angular.forEach(option.info.vmfsExtent.length, function (value) {
                        result += getSizeOfBlockRange(value);
                     });
                  } else {
                     // Invalid vmfs option.
                     result = -1;
                  }
               }

               return result;
            }

            /**
             * Given array of storage array type policy descriptions and a policy key
             * extract the name of the policy.
             *
             * @param storageArrayTypePolicy
             *       LogicalUnitStorageArrayTypePolicy instance holding the storage array
             *       type policy key.
             *
             * @param storageArrayTypePolicies
             *       Array of StorageArrayTypePolicyOption.
             *
             * @return
             *       The localized policy name.
             */
            function getStorageArrayTypePolicyName(
                  storageArrayTypePolicy, storageArrayTypePolicies) {

               if (!storageArrayTypePolicy || !storageArrayTypePolicy.policy) {
                  return i18nService.getString('StorageUi', 'informationNotAccessible');
               }
               var policyKey = storageArrayTypePolicy.policy;

               var storageArrayTypePolicyOption = _.find(storageArrayTypePolicies,
                  function(policy) {
                     return policy.policy && policy.policy.key === policyKey;
                  });

               if (storageArrayTypePolicyOption) {
                  return storageArrayTypePolicyOption.policy.label || policyKey;
               }
               return policyKey;
            }

            /**
             * Given array of path selection policy descriptions and a LogicalUnitPolicy
             * instance returns the formatted name of the policy.
             *
             * If this is a fixed storage policy, the preferred path name will be included
             * in the policy name.
             *
             * @param logicalUnitPolicy
             *       LogicalUnitPolicy instance.
             * @param pathSelectionPoliciesOptions
             *       Array of PathSelectionPolicyOption.
             * @return
             *       The localized policy name.
             */
            function formatPathSelectionPolicyName(
                  logicalUnitPolicy, pathSelectionPoliciesOptions) {

               if (!logicalUnitPolicy || !logicalUnitPolicy.policy) {
                  return i18nService.getString('StorageUi', 'informationNotAccessible');
               }

               var policyName = getPathSelectionPolicyNameByKey(
                     logicalUnitPolicy.policy, pathSelectionPoliciesOptions);

               if (isFixedPolicy(logicalUnitPolicy.policy) && logicalUnitPolicy.prefer) {
                  return i18nService.getString(
                        'StorageUi', 'connectivity.policyWithpreferredPath',
                        policyName, logicalUnitPolicy.prefer);
               } else {
                  return policyName;
               }
            }

            /**
            * Checks if the givens ScsiDisk is of type SoftwareEmulated4k.
            *
            * @param scsiDisk
            *       ScsiDisk instance.
            * @return
            *       true if the given scsiDisk type is of
             *      type SoftwareEmulated4k and false otherwise.
            */
            function is4kDevice(scsiDisk) {
               return scsiDisk.scsiDiskType === datastoreConstants.scsiDiskType.SOFTWARE_EMULATED_4K;
            }

            /**
             * Given array of path selection policy descriptions and a policy key
             * extract the name of the policy.
             * @param policyKey
             *       Path selection policy key.
             * @param pathSelectionPOlicies
             *       Array of PathSelectionPolicyOption.
             * @return
             *       The localized policy name.
             */
            function getPathSelectionPolicyNameByKey(policyKey, pathSelectionPoliciesOptions) {

               if (!pathSelectionPoliciesOptions || !pathSelectionPoliciesOptions.length) {
                  return formatPathSelectionPolicyLabel(policyKey);
               }

               var policyOption = _.find(pathSelectionPoliciesOptions,
                  function(option) {
                     return option.policy.key === policyKey;
                  });

               if (policyOption) {
                  return policyOption.policy.label || formatPathSelectionPolicyLabel(policyKey);
               }

               return policyKey;
            }

            /**
             * Return the display name for a given path selection policy key.
             *
             * @param key
             *    The path selection policy key.
             *
             * @return
             *    Display name of the path selection policy.
             */
            function formatPathSelectionPolicyLabel(key) {
               switch (key) {
                  case hostStorageConstants.storagePolicies.FIXED_PATH:
                  case hostStorageConstants.storagePolicies.LEGACY_FIXED_PATH:
                     return i18nService.getString(
                           "StorageUi", "multipathing.policy.VMW_PSP_FIXED");
                  case hostStorageConstants.storagePolicies.ROUND_ROBIN:
                  case hostStorageConstants.storagePolicies.LEGACY_ROUND_ROBIN:
                     return i18nService.getString(
                           "StorageUi", "multipathing.policy.VMW_PSP_RR");
                  case hostStorageConstants.storagePolicies.MOST_RECENTLY_USED_PATH:
                  case hostStorageConstants.storagePolicies.LEGACY_MOST_RECENTLY_USED_PATH:
                     return i18nService.getString(
                           "StorageUi", "multipathing.policy.VMW_PSP_MRU");
                  case hostStorageConstants.storagePolicies.UNKNOWN:
                     return i18nService.getString(
                           "StorageUi", "multipathing.policy.VMW_PSP_UNKN");
               }
               return key;
            }

            /**
             * Checks if a given path selection policy is fixed.
             *
             * @param policyKey
             *    Then policy's key.
             * @return
             *    True if the path selection policy is fixed.
             *
             */
            function isFixedPolicy(policyKey) {
               return policyKey === hostStorageConstants.storagePolicies.FIXED_PATH ||
                  policyKey === hostStorageConstants.storagePolicies.LEGACY_FIXED_PATH;
            }


            /**
             * Checks is the partition.partitionType passed is a free partition that can be used
             * to create/increase vmfs datastore
             **/
            function isFreePartition (partitionType) {
               return partitionType === '0x00' || partitionType === 'none';
            }

            function getRescanWarning (entityType) {
               var rescanWarningsToEntityTypeMap = {};
               rescanWarningsToEntityTypeMap[managedEntityConstants.CLUSTER] = 'storage.adapters.rescanForm.warningCluster';
               rescanWarningsToEntityTypeMap[managedEntityConstants.FOLDER] = 'storage.adapters.rescanForm.warningFolder';
               rescanWarningsToEntityTypeMap[managedEntityConstants.DATACENTER] = 'storage.adapters.rescanForm.warningDatacenter';
               rescanWarningsToEntityTypeMap[managedEntityConstants.HOST] = 'storage.adapters.rescanForm.warningHosts';

               if (rescanWarningsToEntityTypeMap.hasOwnProperty(entityType)) {
                  return i18nService.getString('StorageUi', rescanWarningsToEntityTypeMap[entityType]);
               }
            }

            /**
             * Get the transport for a SCSI Protocol Endpoint
             */
            function getScsiPeTransport(protocolEndpoint){
                  var transportString = "";
                  if (protocolEndpoint && protocolEndpoint.transport) {
                     var transportArray = protocolEndpoint.transport;
                     _.forEach(transportArray, function(transport, transportIndex) {
                        var transportKey = "storage.adapters.devices.transport." + transport;
                        var transportValue = i18nService.getString("StorageUi", transportKey);
                        // Since the i18nService returns the key if no value is found
                        // this check is made in order not to add a prefix to the transport value
                        // if such is not found
                        if (transportValue === transportKey) {
                           transportValue = transport;
                        }

                        if (transportString) {
                           transportString += ", ";
                        }
                        transportString += transportValue;
                     });
                  }
                  // Set default value if transport could not be determined.
                  if (!transportString) {
                     transportString = i18nService.getString("StorageUi",
                        "storage.adapters.devices.transport.UnknownTransport");
                  }
                  return transportString;
               }

               function getSectorFormatByScsiDiskType(scsiDiskType){
                  switch (scsiDiskType) {
                     case datastoreConstants.scsiDiskType.NATIVE_512:
                        return i18nService.getString(
                              "StorageUi", "vmfsProperties.scsiDisk.scsiDiskType.native512");
                     case datastoreConstants.scsiDiskType.EMULATED_512:
                        return i18nService.getString(
                              "StorageUi", "vmfsProperties.scsiDisk.scsiDiskType.emulated512");
                     case datastoreConstants.scsiDiskType.NATIVE_4K:
                        return i18nService.getString(
                              "StorageUi", "vmfsProperties.scsiDisk.scsiDiskType.native4k");
                     case datastoreConstants.scsiDiskType.SOFTWARE_EMULATED_4K:
                        return i18nService.getString(
                              "StorageUi", "vmfsProperties.scsiDisk.scsiDiskType.softwareEmulated4k");
                     case datastoreConstants.scsiDiskType.UNKNOWN:
                        return i18nService.getString(
                              "StorageUi", "vmfsProperties.scsiDisk.scsiDiskType.unknown");
                  }
                  return i18nService.getString(
                        "StorageUi", "vmfsProperties.scsiDisk.scsiDiskType.unknown");
               }

               function getPEOperationalStateString(data) {
                  if (deviceStateMatches(data, "OK")) {
                     return i18nService.getString("StorageUi", "storage.pe.opState.accessible");
                  }
                  return i18nService.getString("StorageUi", "storage.pe.opState.notAccessible");
               }


               function deviceStateMatches(data, state) {
                  if (!data || !data.underlyingLun || !data.underlyingLun.operationalState) {
                     return false;
                  }
                  if (data.underlyingLun.operationalState.indexOf(state) === -1) {
                     return false;
                  }
                  return true;
               }

               /**
                * Shows a clarity error confirmation dialog with the provided message as
                * content. The message could contain html elements. By default all html will be sanitized.
                * If you trust the html set the trustHtml function parameter to true.
                *
                * @param errorMessage
                * @param trustHtml
                */
               function showErrorDialog(errorMessage, trustHtml) {
                  var modalOptions = {
                     title: i18nService.getString("StorageUi", "fileOperation.error.title"),
                     hideCancelButton: true,
                     icon: "icon-warning-32",
                     submit: function() {
                        return true;
                     },
                     preserveNewlines: true
                  };

                  if (trustHtml) {
                     modalOptions.contentTemplate = "storage-ui/resources/storage/views/manage/files/FileUploadErrorView.html";
                     modalOptions.contentTemplateContext = {
                        errorMessage: $sce.trustAsHtml(errorMessage)
                     };
                  } else {
                     modalOptions.message = errorMessage;
                  }

                  clarityModalService.openConfirmationModal(modalOptions);
               }
            }]);
})();
