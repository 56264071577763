(function () {
   'use strict';

   /**
    * Service that provides DRS automation levels information. Each level info
    * contains id, label and description of DRS behavior.
    */
   angular.module('com.vmware.vsphere.client.cluster')
      .service('drsAutomationLevelService', drsAutomationLevelService);

   drsAutomationLevelService.$inject = ['i18nService', 'drsConstants'];

   function drsAutomationLevelService(i18nService, drsConstants) {
      return {
         DEFAULT_LEVEL: buildLevel(drsConstants.automationLevel.FULLY_AUTOMATED),
         getLevels: getLevels,
         getLevelByKey: getLevelByKey,
         getLabel: getLabel
      };

      function getLevels() {
         var automationLevels = Object.keys(drsConstants.automationLevel).map(function (key) {
            var automationLevelKey = drsConstants.automationLevel[key];
            var automationLevel = buildLevel(automationLevelKey);
            return automationLevel;
         });

         return automationLevels;
      }

      function getLevelByKey(automationLevelKey) {
         var level = buildLevel(automationLevelKey);
         return level;
      }

      function buildLevel(automationLevelKey) {
         return {
            id: automationLevelKey,
            label: getLabel(automationLevelKey),
            description: getDescription(automationLevelKey)
         };
      }

      function getLabel(automationLevelKey) {
         var automationLevelTextKey;
         switch (automationLevelKey) {
            case drsConstants.automationLevel.MANUAL:
               automationLevelTextKey = 'drs.config.automationLevel.manual';
               break;
            case drsConstants.automationLevel.PARTIALLY_AUTOMATED:
               automationLevelTextKey = 'drs.config.automationLevel.partialauto';
               break;
            case drsConstants.automationLevel.FULLY_AUTOMATED:
               automationLevelTextKey = 'drs.config.automationLevel.fullauto';
               break;
            default:
               return '';
         }
         return i18nService.getString('ClusterUi', automationLevelTextKey);
      }

      function getDescription(automationLevelKey) {
         var automationLevelDescriptionKey;
         switch (automationLevelKey) {
            case drsConstants.automationLevel.MANUAL:
               automationLevelDescriptionKey = 'drs.config.automationLevel.manualDescription';
               break;
            case drsConstants.automationLevel.PARTIALLY_AUTOMATED:
               automationLevelDescriptionKey = 'drs.config.automationLevel.partialautoDescription';
               break;
            case drsConstants.automationLevel.FULLY_AUTOMATED:
               automationLevelDescriptionKey = 'drs.config.automationLevel.fullautoDescription';
               break;
            default:
               return '';
         }
         return i18nService.getString('ClusterUi', automationLevelDescriptionKey);
      }
   }
})();