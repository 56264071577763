/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */

(function() {
   'use strict';
   angular.module('com.vmware.vsphere.client.storage').controller(
         'GeneralStorageDeviceDetailsViewController', GeneralStorageDeviceDetailsViewController);

   GeneralStorageDeviceDetailsViewController.$inject = [
      'i18nService', '$scope', 'vxPropertyViewService', 'actionsService',
      '$q', 'navigation', 'multipathingPoliciesService'];

   function GeneralStorageDeviceDetailsViewController (
         i18nService, $scope, vxPropertyViewService, actionsService, $q, navigation, multipathingPoliciesService) {

      var self = this;

      var i18n = i18nService.getString.bind(null, 'StorageUi');

      self.hostId = navigation.getRoute().objectId;
      self.cache = {};
      self.hostPolicyOptions = {};

      $scope.$watch(function() {
         return $scope.masterDetailsViewContext.selectedItem;
      }, function(newValue, oldValue) {
         if (newValue !== oldValue && newValue) {
            requestMultipathingInfo();
         }
      });

      requestMultipathingInfo();

      function getSelectedItem () {
         return $scope.masterDetailsViewContext.selectedItem;
      }

      function getSelectedDeviceIdentifier() {
         var selectedItem = getSelectedItem();
         return selectedItem && selectedItem.identifier;
      }

      function requestMultipathingInfo() {
         var deviceId = getSelectedDeviceIdentifier();
         if (!deviceId) {
            return;
         }

         // We should hide edit multipathing section when:
         // - the context host is disconnected
         // - for devices owned by the HPP plugin (see PR 2222384 for more details).
         if (!$scope.masterDetailsViewContext.isHostConnected ||
               getSelectedItem().ownerPlugin === "HPP") {
            buildPropertyView(null);
            return;
         }

         var hostId = self.hostId;
         var cacheId = hostId + '-' + deviceId;

         if (self.cache.hasOwnProperty(cacheId)) {
            buildPropertyView(self.cache[cacheId]);
         } else {
            self.loading = true;
            self.propertyViewData = null;
         }

         // Request host policy options only once
         var requestHostPolicies = false;
         if (!self.hostPolicyOptions.hasOwnProperty(self.hostId)) {
            requestHostPolicies = true;
         }

         var onEditInvocation = function(actionDef) {
            var selectedDevice = getSelectedItem();
            if (selectedDevice) {
               actionsService.invokeAction(actionDef, {
                  deviceId: selectedDevice.identifier,
                  name: selectedDevice.identifier
               });
            }
         };

         multipathingPoliciesService.requestPolicies(
            self.hostId, getSelectedDeviceIdentifier(),
            requestHostPolicies, onEditInvocation.bind(self))
            .then(
               function(responses) {
                  self.cache[cacheId] = responses.multipathingInfo;
                  if (requestHostPolicies && responses.hostPolicyOptions) {
                     self.hostPolicyOptions[self.hostId] = responses.hostPolicyOptions;
                  }
                  if (hostId === self.hostId && deviceId === getSelectedDeviceIdentifier()) {
                     buildPropertyView(responses.multipathingInfo);
                  }
               },
               function () {
                  if (hostId === self.hostId && deviceId === getSelectedDeviceIdentifier()) {
                     buildPropertyView(null);
                  }
               })
            .finally(function() {
               if (hostId === self.hostId && deviceId === getSelectedDeviceIdentifier()) {
                  self.loading = false;
               }
            });
      }

      function buildPropertyView (deviceMultipathingProps) {
         var gridItem = getSelectedItem();
         if (!gridItem) {
            return;
         }

         var builder = vxPropertyViewService.createPropertyViewBuilder();
         var categoryBuilder = builder.category(
            i18n('storage.devices.properties.generalHeader'), 0);
         categoryBuilder.section('generalSection')
            .title(i18n('storage.devices.properties.generalHeader'))
            .property(
               i18n('storage.adapters.devices.name'),
               gridItem.name)
            .property(
               i18n('storage.adapters.devices.identifier'),
               gridItem.identifier)
            .property(
               i18n('storage.adapters.devices.type'),
               gridItem.type)
            .property(
               i18n('storage.devices.properties.location'),
               gridItem.devicePath)
            .property(
               i18n('storage.adapters.devices.capacity'),
               gridItem.localizedCapacity)
            .property(
               i18n('storage.adapters.devices.driveType'),
               gridItem.localizedDriveType)
            .property(
               i18n('storage.adapters.devices.hardwareAcc'),
               gridItem.hardwareAcceleration)
            .property(
               i18n('storage.adapters.devices.transport'),
               gridItem.transport)
            .property(
               i18n('storage.adapters.devices.owner'),
               gridItem.ownerPlugin)
            .property(
               i18n('storage.adapters.devices.sectorFormat'),
               gridItem.sectorFormat);
         if (deviceMultipathingProps) {
            multipathingPoliciesService.buildMulthipathingSection(
              categoryBuilder,
               i18n('storage.devices.properties.multipathingPoliciesHeader'),
               deviceMultipathingProps,
               self.hostPolicyOptions[self.hostId],
               deviceMultipathingProps.editAction);
         }

         self.propertyViewData = builder.build();
      }
   }
})();
