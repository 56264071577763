namespace storage_ui {

   import InternetScsiHba$IscsiIpv6Address = storage_ui.InternetScsiHba$IscsiIpv6Address;

   export class HbaNetworkingUtil {

      public readonly IPV6_LINK_LOCAL_PREFIX_LENGTH = 64;
      public static $inject = ["StorageAdapterNetworkSettingsConstants"];

      public constructor(private constants: any) {

      }

      createIpv6(addr: string|null): InternetScsiHba$IscsiIpv6Address {
         let address = new InternetScsiHba$IscsiIpv6Address();
         address.address = addr;
         address.prefixLength = this.IPV6_LINK_LOCAL_PREFIX_LENGTH;
         address.origin = this.constants.AddressConfigurationType.Static;
         address.operation = this.constants.IPv6Settings.OPERATION.ADD;
         return address;
      }

      public getLinkLocalIPv6Address(ipProperties: InternetScsiHba$IPProperties): string {
         let address = this.getLinkLocalIPv6(ipProperties);
         return address && address.address ? address.address : "";
      }

      public getLinkLocalIPv6(ipProperties: InternetScsiHba$IPProperties): InternetScsiHba$IscsiIpv6Address|null {
         if (!ipProperties || !ipProperties.ipv6properties) {
            return null;
         } else {
            let address: InternetScsiHba$IscsiIpv6Address = _.find(ipProperties.ipv6properties.iscsiIpv6Address,
                  (address: InternetScsiHba$IscsiIpv6Address)=> {
                     return (address.origin !== this.constants.AddressConfigurationType.Other
                     && this.isLinkLocalIpv6Address(address.address, address.prefixLength));
                  });
            return address || null;
         }
      }

      /**
       * Checks if IPv6 address is a link local address i.e. of the type fe80::/10
       * (fe80:: through febf::)
       *
       * @param address
       *    IPv6 address
       * @param prefixLength
       *    IPv6 prefix length - link-local addresses have a prefix length of 64
       * @return
       *    Returns true if the address is a link local one
       */
      public isLinkLocalIpv6Address(address: string|null, prefixLength: number = 64): boolean {
         if (!address ||
               prefixLength !== this.IPV6_LINK_LOCAL_PREFIX_LENGTH ||
               address.length < 4) {
            return false;
         }

         let addressBegining = parseInt(address.substr(0, 4), 16);
         return (addressBegining & 0xffc0) === 0xfe80;
      }

      /**
       * Gets the IPv6 addresses of certain type
       *
       * @param hba
       *    The HBA config
       *
       * @param addressType
       *    The address type to filter
       *
       * @param skipLinkLocal
       *    Indicates, whether to skip linl-local addresses. Default is false.
       *
       * @return
       *    The filtered IPv6 addresses
       */
      public getIPv6AddressesOfType(ipProperties: InternetScsiHba$IPProperties, addressType: string,
                                    skipLinkLocal: boolean): InternetScsiHba$IscsiIpv6Address[] {
         let addressesList: InternetScsiHba$IscsiIpv6Address[] = [];
         if (!ipProperties || !ipProperties.ipv6properties || !ipProperties.ipv6properties.iscsiIpv6Address.length) {
            return addressesList;
         }
         return _.filter(ipProperties.ipv6properties.iscsiIpv6Address, (address: InternetScsiHba$IscsiIpv6Address)=> {
            return address.origin === addressType
                  && !(skipLinkLocal && this.isLinkLocalIpv6Address(address.address, address.prefixLength));
         });

      }

   }

   angular.module("com.vmware.vsphere.client.storage")
         .service("hbaNetworkingUtil", HbaNetworkingUtil);

}
