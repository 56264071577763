namespace h5_vm {

   import GosBasePageModel = h5_vm.GosBasePageModel;

   export class GosWorkgroupDomainPageModel extends GosBasePageModel {
      networkOption: WorkgroupDomainTypeOption;
      workgroup: string;
      domain: string;
      username: string;
      password: string;
      confirmPassword: string;
      isDomainPasswordChanged: boolean;
   }
}

