angular.module('com.vmware.vsphere.client.host').factory('VuiWizardHelper', [
   '$rootScope', 'wizardPageService', 'hostVuiService', 'defaultUriSchemeUtil',
      'managedEntityConstants', 'dataService', 'addHostWizardConstants',
      'i18nService',
   function($rootScope, wizardPageService, hostVuiService, defaultUriSchemeUtil,
         managedEntityConstants, dataService, addHostWizardConstants, i18nService){
      return function VuiWizardHelper(title) {
         var wizardConfig;

         return {
            markPageComplete: function(flow) {
               wizardPageService.clearErrors(wizardConfig);
               return wizardPageService.markPageComplete(wizardConfig, flow);
            },
            markPageIncompleteWithError: function(message) {
               return wizardPageService.markPageIncompleteWithError(wizardConfig, message);
            },
            markPageIncompleteWithErrors: function(messages) {
               return wizardPageService.markPageIncompleteWithErrors(wizardConfig, messages);
            },
            clearErrors: function() {
               return wizardPageService.clearErrors(wizardConfig);
            },
            showLoadingIndicator: function() {
               return wizardPageService.showLoadingIndicator(wizardConfig);
            },
            hideLoadingIndicator: function() {
               return wizardPageService.hideLoadingIndicator(wizardConfig);
            },
            show: function(pages, manager, cssClass){
               wizardConfig = {
                  title: title,
                  pages: wizardPageService.initializePageStates(pages),
                  cssClass: cssClass,
                  resizeMinHeight: 500,
                  onFinish: function () {
                     manager.submit();
                     return true;
                  }
               };

               var wizardScope = $rootScope.$new();
               wizardScope.manager = manager;
               wizardScope.wizardConfig = wizardConfig;

               hostVuiService.createWizard({
                  scope: wizardScope,
                  configObjectName: 'wizardConfig'
               }).show();

               this.showLoadingIndicator();
               var self = this;
               fetchWizardData(manager).then(function() {
                  self.hideLoadingIndicator();
               });
            }
         };

         function fetchWizardData(hostAdder) {
            var destinationUid = hostAdder.getDestinationUid();
            var destinationRef = defaultUriSchemeUtil.getPartsFromVsphereObjectId(destinationUid);
            var propertiesToFetch;
            if (destinationRef.type === managedEntityConstants.DATACENTER) {
               propertiesToFetch = ['name', 'primaryIconId'];
            } else {
               propertiesToFetch = ['name', 'primaryIconId', 'dc', 'configuration.drsConfig.enabled'];
            }
            return dataService.getProperties(destinationUid, propertiesToFetch).then(function(properties) {
               if (destinationRef.type !== managedEntityConstants.DATACENTER) {
                  hostAdder.setDatacenter(properties.dc);
               } else {
                  hostAdder.setDatacenter(destinationRef);
               }

               hostAdder.setDestination({
                  ref: destinationRef,
                  name: properties.name,
                  icon: properties.primaryIconId,
                  drsState: properties["configuration.drsConfig.enabled"]
               });

               if (hostAdder.isDestinationDrsEnabledCluster()) {
                  hostAdder.setResourceLocationType(addHostWizardConstants.RESOURCE_LOCATION_ROOT);
               }
               reEvaluatePagesVisibility(hostAdder);
               hostAdder.setStateLoadedFlag(true);
            });
         }

         function reEvaluatePagesVisibility(hostAdder){
            _.each(wizardConfig.pages, function(page) {
               changePageVisibility(page,
                     i18nService.getString('HostUi', 'addHostWizard.assignLicensePage.title'),
                     assignLicenseAndLockdownPageCondition(hostAdder));
               changePageVisibility(page,
                     i18nService.getString('HostUi', 'addHostWizard.lockDownPage.title'),
                     assignLicenseAndLockdownPageCondition(hostAdder));
               changePageVisibility(page,
                     i18nService.getString('HostUi', 'addHostWizard.vmLocationPage.title'),
                     assignVmLocationPageCondition(hostAdder));
               changePageVisibility(page,
                     i18nService.getString('HostUi', 'addHostWizard.drsClusterRpPage.title'),
                     assignResourcePoolPageCondition(hostAdder));
            });
         }

         function changePageVisibility(page, title, condition) {
            if(condition && page.title === title){
                  wizardPageService.markPageSkipped(wizardConfig.pages, title);
            }
         }

         function assignLicenseAndLockdownPageCondition(hostAdder) {
            return hostAdder.isReconnectHost();
         }

         function assignVmLocationPageCondition(hostAdder) {
            return hostAdder.isDestinationCluster() && !hostAdder.isReconnectHost();
         }

         function assignResourcePoolPageCondition(hostAdder) {
            return !hostAdder.isDestinationDrsEnabledCluster() ||
                  hostAdder.isReconnectHost();
         }
      };
   }]
);
