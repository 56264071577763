angular.module('com.vmware.vsphere.client.vm').service('vmProvisioningNetworkPageService', [
   'i18nService',
   'vuiConstants',
   'VmProvisioningNetworkPageModel',
   'vmVuiWizardPageBuilder',
   function (i18nService, vuiConstants, VmProvisioningNetworkPageModel, vmVuiWizardPageBuilder) {
      return {
         build: function (wizardScope) {

            var networkPageModel = new VmProvisioningNetworkPageModel(
                  wizardScope.wizardViewData,
                  wizardScope.vmParams,
                  wizardScope.config
            );
            wizardScope.networkPageModel = networkPageModel;

            return vmVuiWizardPageBuilder.buildVuiWizardPage(wizardScope.config, {
               title: i18nService.getString('ProvisioningUiLib', 'SelectNetworksPage.title'),
               description: i18nService.getString('ProvisioningUiLib', 'SelectNetworksPage.description'),
               contentUrl: 'vm-ui/resources/vm/views/createVmWizard/vmProvisioningNetworkPage.html',
               model: networkPageModel,
               defaultState: vuiConstants.wizard.pageState.SKIPPED
            });
         }
      };
   }
]);
