/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace network_ui {

   export class VmMigrateWizardModel {
      contextObjId: string;
      datacenterId: string;
      sourceNetwork: any;
      destinationNetwork: any;
      destinationNetworkData: any;
      vmListItems: any[];
      summaryData: any;
   }
}
