(function() {
   'use strict';

   angular.module('com.vmware.vsphere.client.host')
         .service("hostGraphicsService", hostGraphicsService);

   hostGraphicsService.$inject = [
      'i18nService',
      'bytesFormatter',
      'vuiConstants',
      'defaultUriSchemeUtil',
      'dataService',
      'columnRenderersRegistry'];

   function hostGraphicsService(i18nService,
                                bytesFormatter,
                                vuiConstants,
                                defaultUriSchemeUtil,
                                dataService,
                                columnRenderersRegistry) {
      return {
         getTypeLabel: getTypeLabel,
         getPolicyLabel: getPolicyLabel,
         getGridGraphicsOptions: getGridGraphicsOptions,
         getVmInfoAndUpdateGridData: getVmInfoAndUpdateGridData,
         getGridVmsOptions: getGridVmsOptions,
         getHostSettingsHeaderOptions: getHostSettingsHeaderOptions
      };

      function getTypeLabel(typeKey) {
         var typeLabel;
         switch (typeKey) {
            case 'shared':
               typeLabel = i18nService.getString('HostUi',
                  'settings.graphics.type.default.shared');
               break;
            case 'sharedDirect':
               typeLabel = i18nService.getString('HostUi',
                  'settings.graphics.type.default.sharedDirect');
               break;
            default:
               typeLabel = "";
               break;
         }
         return typeLabel;
      }

      function getPolicyLabel(policyKey) {
         var policyLabel;
         switch (policyKey) {
            case 'performance':
               policyLabel = i18nService.getString('HostUi',
                  'settings.graphics.hostConfigView.performancePolicy');
               break;
            case 'consolidation':
               policyLabel = i18nService.getString('HostUi',
                  'settings.graphics.hostConfigView.consolidationPolicy');
               break;
            default:
               policyLabel = "";
               break;
         }
         return policyLabel;
      }

      function getTypeMessage(type) {
         var messages = {
            "basic": i18nService.getString('HostUi',
               'settings.graphics.type.default.basic'),
            "shared": i18nService.getString('HostUi',
               'settings.graphics.type.default.shared'),
            "sharedDirect": i18nService.getString('HostUi',
               'settings.graphics.type.default.sharedDirect'),
            "direct": i18nService.getString('HostUi',
               'settings.graphics.type.default.direct')
         };
         return messages[type];
      }

      function getColumnGraphicsDefs() {
         return [
            {
               displayName: i18nService.getString('HostUi',
                  'settings.graphics.devicesView.nameColumn'),
               field: 'deviceName',
               type: 'string',
               editable: false
            },
            {
               displayName: i18nService.getString('HostUi',
                  'vmdpConfig.deviceId.label'),
               field: 'pciId',
               type: 'string',
               editable: false
            },
            {
               displayName: i18nService.getString('HostUi',
                  'settings.graphics.devicesView.vendorColumn'),
               field: 'vendor',
               type: 'string',
               editable: false
            },
            {
               displayName: i18nService.getString('HostUi',
                  'settings.graphics.devicesView.activeTypeColumn'),
               field: 'defaultType',
               type: 'string',
               editable: false,
               template: function(data) {
                  return getTypeMessage(data.defaultType);
               }
            },
            {
               displayName: i18nService.getString('HostUi',
                  'settings.graphics.devicesView.configuredTypeColumn'),
               field: 'configuredType',
               type: 'string',
               editable: false,
               template: function(data) {
                  if (data.configuredType && data.configuredType !== '') {
                     return getTypeMessage(data.configuredType);
                  } else {
                     return i18nService.getString('HostUi', 'settings.graphics.type.na');
                  }
               }
            },
            {
               displayName: i18nService.getString('HostUi',
                  'settings.graphics.devicesView.memoryColumn'),
               field: 'memory',
               type: 'string',
               editable: false,
               template: function(data) {
                  return bytesFormatter.bytesFormat(data.memory);
               }
            }
         ];
      }

      function getColumnVmsDefs() {
         return [
            {
               displayName: i18nService.getString('HostUi',
                  'settings.graphics.devicesView.nameColumn'),
               field: 'name',
               type: 'string',
               editable: false
            },
            {
               displayName: i18nService.getString('HostUi',
                  'stateHeaderText'),
               field: 'powerState',
               type: 'string',
               editable: false
            },
            {
               displayName: i18nService.getString('HostUi',
                  'statusHeaderText'),
               field: 'overallStatus',
               type: 'string',
               editable: false,
               template: iconTextRenderer
            },
            {
               displayName: i18nService.getString('VmUi',
                  'provisionedSpaceHeaderText'),
               field: 'usedSpace',
               type: 'string',
               editable: false
            },
            {
               displayName: i18nService.getString('VmUi',
                  'usedSpaceHeaderText'),
               field: 'provisionedSpace',
               type: 'string',
               editable: false
            },
            {
               displayName: i18nService.getString('VmUi',
                  'hostCPUHeaderText'),
               field: 'overallCpuUsage',
               type: 'string',
               editable: false
            },
            {
               displayName: i18nService.getString('VmUi',
                  'hostMemHeaderText'),
               field: 'hostMemoryUsage',
               type: 'string',
               editable: false
            }
         ];
      }

      function iconTextRenderer(data) {
         var renderer = columnRenderersRegistry.getColumnRenderer('icon-text');
         var iconTextParameter = getIconAndLabel(data.overallStatus);
         var renderedColumnValue = renderer(['icon', 'text'], iconTextParameter);
         return renderedColumnValue;
      }

      function getGridGraphicsOptions(callback) {
         return {
            selectionMode: vuiConstants.grid.selectionMode.SINGLE,
            sortMode: vuiConstants.grid.sortMode.SINGLE,
            resizable: true,
            selectedItems: [],
            data: [],
            columnDefs: getColumnGraphicsDefs(),
            actionBarOptions: {
               actions: [getEditGraphicDeviceAction(callback)]
            },
            height: 'calc(100% - 12px)'
         };
      }

      function getVmInfoAndUpdateGridData(selectedVms, callback) {
         var selectedVm = selectedVms[0];
         if (selectedVm && selectedVm.vms && selectedVm.vms[0]) {
            var vmObjects = [];
            var vmDetailsInfo = [];
            _.each(selectedVm.vms, function(vm) {
               vmObjects.push(defaultUriSchemeUtil.getVsphereObjectId(vm));
            });

            dataService.getPropertiesForObjects(
               vmObjects,
               [
                  'parent',
                  'name',
                  'runtime.powerState',
                  'overallStatus',
                  'summary.storage.committed',
                  'summary.storage.uncommitted',
                  'summary.quickStats.overallCpuUsage',
                  'summary.quickStats.hostMemoryUsage',
                  'storage.perDatastoreUsage'
               ]).then(function(data) {
               _.each(vmObjects, function(vm) {
                  vmDetailsInfo.push(data[vm]);
               });
               callback(portVmInfo(vmDetailsInfo));
            });

         } else {
            callback([]);
         }
      }

      function portVmInfo(vms) {
         var objectToReturn = [];
         _.each(vms, function(vm) {
            objectToReturn.push({
               name: vm.name,
               powerState: i18nService.getString('Common', 'powerState.' + vm['runtime.powerState']),
               overallStatus: vm.overallStatus,
               usedSpace: bytesFormatter.bytesFormat(getVmCommittedSpace(vm)),
               provisionedSpace: bytesFormatter.bytesFormat(getTotalProvisionedSpace(vm)),
               overallCpuUsage: vm['summary.quickStats.overallCpuUsage'] + " " +
               i18nService.getString('CommonUi', 'megaHertz.label'),
               hostMemoryUsage: vm['summary.quickStats.hostMemoryUsage'] + " " +
               i18nService.getString('CommonUi', 'megaByte.label')
            });
         });
         return objectToReturn;
      }

      function getVmCommittedSpace(vm){
         var datastoreType = 'Datastore';
         if(vm.parent.type === datastoreType && vm['storage.perDatastoreUsage'] !== null){
            var virtualMachineUsageOnDatastore = vm['storage.perDatastoreUsage'];
            var dsUsage = _.find(virtualMachineUsageOnDatastore, function(item) {
               return item.datastore === vm.parent;
            });

            return dsUsage.committed;
         }
         return vm['summary.storage.committed'];
      }

      function getVmUncommittedSpace(vm) {
         var datastoreType = 'Datastore';
         if(vm.parent.type === datastoreType && vm['storage.perDatastoreUsage'] !== null){
            var virtualMachineUsageOnDatastore = vm['storage.perDatastoreUsage'];
            var dsUsage = _.find(virtualMachineUsageOnDatastore, function(item) {
               return item.datastore === vm.parent;
            });

            return dsUsage.uncommitted;
         }
         return vm['summary.storage.uncommitted'];
      }

      function getTotalProvisionedSpace(vm) {
         return getVmCommittedSpace(vm) + getVmUncommittedSpace(vm);
      }


      function getIconAndLabel(status) {
         var iconClass, label;
         switch (status) {
            case 'green':
               iconClass = 'vsphere-icon-status-ok';
               label = getLabelTextForStatus(status);
               break;
            case 'yellow':
               iconClass = 'vsphere-icon-status-warning';
               label = getLabelTextForStatus(status);
               break;
            case 'red':
               iconClass = 'vsphere-icon-status-error';
               label = getLabelTextForStatus(status);
               break;
            case 'grey':
               iconClass = 'vsphere-icon-status-unknown';
               label = getLabelTextForStatus(status);
               break;
            default:
               iconClass = 'vsphere-icon-status-unknown';
               label = getLabelTextForStatus(status);
               break;
         }

         var iconAndText = {
            icon: iconClass,
            text: label
         };

         return iconAndText;
      }

      function getLabelTextForStatus(status) {
         var labelText = "";
         if (status) {
            var propertyKey = "entityStatus." + status;
            labelText = i18nService.getString('Common', propertyKey);
         }

         return labelText;
      }

      function getGridVmsOptions() {
         return {
            selectionMode: vuiConstants.grid.selectionMode.SINGLE,
            sortMode: vuiConstants.grid.sortMode.SINGLE,
            resizable: true,
            selectedItems: [],
            data: [],
            columnDefs: getColumnVmsDefs(),
            height: 'calc(100% - 10px)'
         };
      }

      function getEditGraphicDeviceAction(callback) {
         return {
            actionId: 'editGraphicDevice',
               tooltipText: i18nService.getString('HostUi',
            'settings.graphics.devicesView.button.edit.tooltip'),
            iconClass: 'vx-icon-edit16x16',
            enabled: false,
            onClick: callback
         };
      }

      function getHostSettingsHeaderOptions(hostId) {
         return {
            title: i18nService.getString('HostUi', 'settings.graphics.editHostConfigForm.title'),
            objectId: hostId,
            actions: [{
               actionUid: 'vsphere.core.host.actions.editHostGraphics',
               customLabel: i18nService.getString('HostUi', 'settings.graphics.hostConfigView.edit.label')
            }]
         };
      }
   }
}());
