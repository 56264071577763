module ds_cluster_ui {

   class AddVmOverridesController {

      public static $inject = ["$scope"];
      private selectedVms: any;
      private spec: any;
      constructor(private $scope: any) {
         this.selectedVms = $scope.manager.getSelectedVms();
         this.spec = $scope.manager.getSpec();
      }

   }

   angular.module("com.vmware.vsphere.client.dsCluster")
         .controller("AddVmOverridesController", AddVmOverridesController);
}

