namespace h5_vm {
   import ValidationResult = com.vmware.vise.core.model.ValidationResult;
   import RegisterVmCompatibilityCheckSpec = com.vmware.vsphere.client.h5.vm.data.RegisterVmCompatibilityCheckSpec;
   export class RegisterVmCompatibilityCheckService {
      public static $inject: string[] = [
         'defaultUriSchemeUtil',
         "mutationService"
      ];

      private spec: RegisterVmCompatibilityCheckSpec;

      constructor(private defaultUriSchemeUtil: any, private mutationService: any) {
      }

      validate(targetId: string, datastoreId: string, isVmTemplate: boolean): angular.IPromise<ValidationResult> {
         this.spec = new RegisterVmCompatibilityCheckSpec();
         this.spec.targetRef = this.defaultUriSchemeUtil.getManagedObjectReference(targetId);
         this.spec.datastoreRef = this.defaultUriSchemeUtil.getManagedObjectReference(datastoreId);
         this.spec.isTemplate = isVmTemplate;

         return this.mutationService.validate(targetId, this.spec._type, this.spec);
      }
   }

   angular.module('com.vmware.vsphere.client.vm')
         .service('registerVmCompatibilityCheckService',
               RegisterVmCompatibilityCheckService);
}
