/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace dvs_ui {

   import DvsNetworkResourcePoolsOverviewData =
         com.vmware.vsphere.client.h5.network.dvs.resmgmt.nrp.model.DvsNetworkResourcePoolsOverviewData;

   export class DvsNrpOverviewComponent {

      public bindings: any;
      public controller: any;
      public templateUrl: string;

      constructor() {
         this.bindings = {
            data: "<"
         };

         this.controller = DvsNrpOverviewComponentController;

         this.templateUrl =
               "dvs-ui/resources/dvs/resmgmt/nrp/views/dvsNrpOverviewComponentTemplate.html";
      }
   }

   class DvsNrpOverviewComponentController {
      static readonly $inject = [
         "i18nService",
         "vxPropertyViewService",
         "numberFormatterService"
      ];

      public data: DvsNetworkResourcePoolsOverviewData;

      public propertyViewData: any;

      constructor(private i18nService: any,
                  private vxPropertyViewService: any,
                  private numberFormatterService: any) {
      }

      public $onChanges(): void {
         if (this.data) {
            this.buildPropertyViewData();
         }
      }

      private buildPropertyViewData(): void {
         let builder: any = this.vxPropertyViewService.createPropertyViewBuilder();

         let generalCategory: any = builder.category("general");
         let generalSection: any = generalCategory.section("generalSection");

         const NO_DATA: string =
               this.i18nService.getString("DvsUi", "networkResourcePools.noData");

         let capacity: string = (this.data.capacity && this.data.capacity > 0)
               ? this.numberFormatterService.formatBandwidth(
                     this.data.capacity, BandwidthUnit.MBITPS, BandwidthUnit.AUTO, 2, false)
               : NO_DATA;
         generalSection.property(
               this.i18nService.getString(
                     "DvsUi",
                     "networkResourcePools.summary.totalBandwidth"),
               capacity);

         let vmTrafficReservation: string = (this.data.vmTrafficReservation && this.data.vmTrafficReservation > 0)
               ? this.numberFormatterService.formatBandwidth(
                     this.data.vmTrafficReservation, BandwidthUnit.MBITPS, BandwidthUnit.AUTO)
               : NO_DATA;
         generalSection.property(
               this.i18nService.getString(
                     "DvsUi",
                     "networkResourcePools.summary.vmTrafficReservation"),
               vmTrafficReservation);

         this.propertyViewData = builder.build();
      }
   }

   // in sync with format.ts in ng-next-app
   enum BandwidthUnit { BITPS, KBITPS, MBITPS, GBITPS, TBITPS, AUTO }

   angular.module("com.vmware.vsphere.client.dvs")
         .component("dvsNrpOverview", new DvsNrpOverviewComponent());
}