module h5_vm {

   export class FtVmTurnOnManager {
      static $inject = [];
      private targetUid: string;
      private vmName: string;
      private compatibleDatastores: any;
      private compatibleMetaDatastores: any;
      private compatibleConfigDatastores: any;
      private selectedDatastore: any;
      private devices: any;
      private datacenter: any;
      private selectedHost: any;
      private noHostsCompatible: boolean;
      private isAdvancedMode: boolean;
      private selectedDatastores: {[key: string]: any};
      private numberOfVmFiles: number;

      public setTargetUid(targetUid: string): void {
         this.targetUid = targetUid;
      }

      public getTargetUid(): string {
         return this.targetUid;
      }

      public setVmName(vmName: string): void {
         this.vmName = vmName;
      }

      public getVmName(): string {
         return this.vmName;
      }

      public setMetaCompatibleDatastores(compatibleMetaDatastores: any): void {
         this.compatibleMetaDatastores = compatibleMetaDatastores || [];
      }

      public getMetaCompatibleDatastores(): any {
         return this.compatibleMetaDatastores;
      }

      public setConfigCompatibleDatastores(compatibleConfigDatastores: any): void {
         this.compatibleConfigDatastores = compatibleConfigDatastores || [];
      }

      public getConfigCompatibleDatastores(): any {
         return this.compatibleConfigDatastores;
      }

      public setCompatibleDatastores(compatibleDatastores: any): void {
         this.compatibleDatastores = compatibleDatastores || [];
      }

      public getCompatibleDatastores(): any {
         return this.compatibleDatastores;
      }

      public setSelectedDatastore(selectedDatastore: any): void {
         this.selectedDatastore = selectedDatastore;
      }

      public getSelectedDatastore(): any {
         return this.selectedDatastore;
      }

      public setDevices(devices: any): void {
         this.devices = devices;
      }

      public getDevices(): any {
         return this.devices;
      }

      public setDatacenter(datacenter: any): void {
         this.datacenter = datacenter;
      }

      public getDatacenter(): any {
         return this.datacenter;
      }

      public setSelectedHost(selectedHost: any): void {
         this.selectedHost = selectedHost;
      }

      public getSelectedHost(): any {
         return this.selectedHost;
      }

      public setNoHostsCompatible(noHostsCompatible: boolean): void{
         this.noHostsCompatible = noHostsCompatible;
      }

      public getNoHostsCompatible(): boolean {
         return this.noHostsCompatible;
      }

      public setIsAdvancedMode(isAdvancedMode: boolean): void {
         this.isAdvancedMode = isAdvancedMode;
      }

      public getIsAdvancedMode(): boolean {
         return this.isAdvancedMode;
      }

      public setSelectedDatastores(selectedDatastores: {[key: string]: any}): void {
         this.selectedDatastores = selectedDatastores;
      }

      public getSelectedDatastores(): {[key: string]: any} {
         return this.selectedDatastores;
      }

      public validateSelectedDatastores(): boolean {
         return _.size(this.selectedDatastores) === this.numberOfVmFiles;
      }

      public setNumberOfVmFiles(vmFiles: number): void {
         this.numberOfVmFiles = vmFiles;
      }
   }
   angular.module('com.vmware.vsphere.client.vm')
      .factory('FtVmTurnOnManager',
         [
            function () {
               return Function.prototype.bind.apply(
                  FtVmTurnOnManager,
                  [undefined]
               );
            }
         ]);
}
