module hostprofile_ui {

   import HostCustomizationImportException = com.vmware.vsphere.client.hostprofile.data.HostCustomizationImportException;
   import ValidationResult = com.vmware.vise.core.model.ValidationResult;
   import IPromise = angular.IPromise;
   import IDeferred = angular.IDeferred;
   import IQService = angular.IQService;
   import ManagedObjectReference = com.vmware.vim.binding.vmodl.ManagedObjectReference;

   export interface HostCustomizationsErrorsService {
      isPropertyRequiredAndEmpty(hostCustomizatinItem: HostCustomizationItem): boolean;
      getValidationErrors(validationData: any): IPromise<CustomizationValidationResult>;
      getClientValidationErrors(validationData: any): string[];
      getCustomizationsErrorMessages(error: any): Array<string>;
      getCustomizationsImportErrorAndWarningMessages(error: any):
            CustomizationsImportErrorAndWarningMessages;
      getParamErrorsMap(hostCustomizations: HostCustomizationItem[],
            errors: HostCustomizationError[]): any;
      showSkipValidationDialog(validationData: any, invalidHosts: Array<any>):
            IPromise<CustomizationValidationResult>;
   }

   class HostCustomizationsErrorsServiceImpl implements HostCustomizationsErrorsService {

      static $inject = [
         "defaultUriSchemeUtil",
         "mutationService",
         "$q",
         "i18nService",
         "HostProfileConstants",
         "clarityModalService"
      ];

      private KEY_SPLITTER: string = "/";

      constructor(private defaultUriSchemeUtil: any,
            private mutationService: any,
            private $q: IQService,
            private i18nService: any,
            private hostProfileConstants: any,
            private clarityModalService: any) {
      }

      public isPropertyRequiredAndEmpty(hostCustomizationItem: HostCustomizationItem): boolean {
         return hostCustomizationItem.isRequired && hostCustomizationItem.value === "";
      }

      public getValidationErrors(validationData: any): IPromise<CustomizationValidationResult> {
         let emptyRequiredPropertiesErrors: HostCustomizationError[] =
               this.getEmptyRequiredPropertiesErrors(
                     validationData.deferredHostSettingsSpec.currentHostCustomizations);

         if (emptyRequiredPropertiesErrors.length > 0) {
            return this.$q.reject({
               paramErrors: emptyRequiredPropertiesErrors,
               genericErrors: [this.i18nService.getString(
                     "HostProfileUi", "hostCustomizationPage.validation.error")],
               invalidHosts: []
            });
         } else {
            return this.serverValidateHostCustomizations(validationData);
         }
      }

      public getClientValidationErrors(validationData: any): string[] {
         let emptyRequiredPropertiesErrors: HostCustomizationError[] =
               this.getEmptyRequiredPropertiesErrors(
                     validationData.deferredHostSettingsSpec.currentHostCustomizations);

         let result: string[] = [];

         if (emptyRequiredPropertiesErrors.length > 0) {
            result = [this.i18nService.getString(
                  "HostProfileUi", "hostCustomizationPage.validation.error")];
         }

         return result;
      }

      public getParamErrorsMap(hostCustomizations: HostCustomizationItem[],
            errors: HostCustomizationError[]): any {
         let errorsPerCustomizationMap: any = {};

         _.each(errors, (error: HostCustomizationError) => {
            errorsPerCustomizationMap[this.buildErrorMapKey(error)] = error.message;
         });

         return errorsPerCustomizationMap;
      }

      private buildErrorMapKey(error: HostCustomizationError): string {
         let key: string = "";

         if (error.hostName) {
            key += error.hostName + this.KEY_SPLITTER;
         }

         if (error.propertyPath) {
            key += error.propertyPath.profilePath + this.KEY_SPLITTER +
                  error.propertyPath.policyId + this.KEY_SPLITTER +
                  error.propertyPath.parameterId;
         }

         return key;
      }

      private getEmptyRequiredPropertiesErrors(hostCustomizations: Array<HostCustomizationItem>):
            HostCustomizationError[] {
         return _.map(_.filter(hostCustomizations, (item: HostCustomizationItem) => {
            return this.isPropertyRequiredAndEmpty(item);
         }), (item: HostCustomizationItem) => {
            return {
               hostName: item.hostName,
               propertyPath: item.inputPath,
               message: this.i18nService.getString(
                     "HostProfileUi", "hostCustomizations.requiredFieldName.error", item.name)
            };
         });
      }

      public getCustomizationsErrorMessages(error: any): Array<string> {
         let errors: Array<any> = this.flatErrorsList([error]);
         let messages: Array<string> = this.getCommonCustomizationErrorMessages(errors);

         return messages;
      }

      public getCustomizationsImportErrorAndWarningMessages(error: any):
            CustomizationsImportErrorAndWarningMessages {

         let errors: Array<any> = this.flatErrorsList([error]);

         let hostCustomizationImportException: HostCustomizationImportException | undefined =
               _.find(errors, (error: any) =>
                     error && (_.has(error, "errorMessages") || _.has(error, "warningMessages"))
               );

         if (!hostCustomizationImportException) {
            return {
               errorMessages: [],
               warningMessages: []
            };
         }

         return {
            errorMessages: _.map(
                  hostCustomizationImportException.errorMessages,
                  (errorMessage: any) => errorMessage.message
            ),
            warningMessages: _.map(
                  hostCustomizationImportException.warningMessages,
                  (warningMessage: any) => warningMessage.message
            )
         };
      }

      private serverValidateHostCustomizations(validationData: any):
            IPromise<CustomizationValidationResult> {
         if (!validationData.deferredHostSettingsSpec ||
               _.isEmpty(validationData.deferredHostSettingsSpec.forceValidationOnHosts)) {
            return this.$q.resolve({});
         }

         let selectedHostProfileMor: any = validationData.hostProfileId;
         let hostProfileUid: string = this.defaultUriSchemeUtil.createVmomiUri(
               selectedHostProfileMor.type,
               selectedHostProfileMor.value,
               selectedHostProfileMor.serverGuid
         );

         return this.mutationService.validate(
               hostProfileUid,
               "com.vmware.vsphere.client.hostprofile.data.h5.ValidateHostCustomizationsSpec",
               validationData.deferredHostSettingsSpec,
               validationData.deferredHostSettingsSpec.currentHostCustomizations
         ).then(this.handleValidationResult.bind(this));
      }

      private extractGenericErrors(allErrors: any[]): any[] {
         return _.filter(allErrors, (error: any) => {
            return !error.path;
         });
      }

      private extractParameterRelatedErrors(allErrors: any[]): any[] {
         return _.filter(allErrors, (error: any) => {
            return !_.isUndefined(error.path) && !_.isNull(error.path);
         });
      }

      private handleValidationResult(validationResult: ValidationResult):
            IPromise<CustomizationValidationResult> {
         if (validationResult && (validationResult.error ||
               this.hasValidationResultMessage(validationResult))) {
            let genericErrors: string[] = [];
            let invalidHosts: string[] = [];

            if (validationResult.error) {
               let error: any = validationResult.error;

               invalidHosts = _.filter(error.exceptions, (exception: any) => {
                  return exception["faultCause"] ?
                        exception["faultCause"]._type === this.hostProfileConstants.INVALID_PROFILE_REFERENCE_HOST : false;
               }).map(exception => {
                  return this.defaultUriSchemeUtil.getVsphereObjectId(exception["faultCause"].host);
               });

               genericErrors = this.getCustomizationsErrorMessages(error);
            }

            let paramErrors: Array<HostCustomizationError> = [];

            _.each(_.keys(validationResult.result), (hostKey: any) => {
               let errors: any = validationResult.result[hostKey].errors;

               if (errors) {
                  let extractedParamErrors: any[] = this.extractParameterRelatedErrors(errors);

                  paramErrors =
                        paramErrors.concat(_.map(extractedParamErrors, (error: any) => {
                     return {
                        hostName: hostKey,
                        propertyPath: error.path,
                        message: error.message.message
                     };
                  }));

                  genericErrors = genericErrors.concat(this.extractGenericErrors(errors));
               }
            });

            if (paramErrors.length > 0) {
               genericErrors.push(this.i18nService.getString(
                     "HostProfileUi", "hostCustomizationPage.validation.error"));
            }

            return this.$q.reject({
               genericErrors: genericErrors,
               paramErrors : paramErrors,
               invalidHosts: invalidHosts
            });
         } else {
            return this.$q.resolve({});
         }
      }

      private hasValidationResultMessage(validationResult: ValidationResult): boolean {
         if (validationResult && validationResult.result) {
            return _.some(_.keys(validationResult.result),
                  (key: any) => validationResult.result[key].status === "error");
         }
         return false;
      }

      public showSkipValidationDialog(validationData: any, invalidHosts: Array<any>):
            IPromise<CustomizationValidationResult> {
         let invalidHostsModified: Array<any> = _.map(invalidHosts, (invalidHost: ManagedObjectReference) => {
            let hostCustomizationForInvalidHost: Array<any> = _.filter(
                  validationData.deferredHostSettingsSpec.currentHostCustomizations,
                  (customization: HostCustomizationItem) => {
                     return this.defaultUriSchemeUtil.getVsphereObjectId(customization["hostMor"]) === invalidHost;
                  });
            let name: string = "";
            if (hostCustomizationForInvalidHost && hostCustomizationForInvalidHost.length > 0) {
               name = hostCustomizationForInvalidHost[0].hostName;
            }

            return {
               id: invalidHost,
               name: name,
               checked: true
            };
         });

         let skipValidationDeferred: IDeferred<CustomizationValidationResult> = this.$q.defer();

         var modalOptions = {
            title: this.i18nService.getString("HostProfileUi", "skipHostValidation.title"),
            contentTemplate:
                  "hostprofile-ui/resources/hostprofile/attach/hostCustomizationSkipValidationDialog.html",
            dialogData: {
               validationData: {
                  invalidHosts: invalidHostsModified,
                  deferredHostSettingsSpec: validationData.deferredHostSettingsSpec
               }
            },
            onSubmit: () => {
               skipValidationDeferred.resolve(
                     this.serverValidateHostCustomizations(validationData));
               return true;
            },
            onCancel: () => {
               //remove any changes made while dialog was open
               let invalidHostsMor: Array<any> = _.map(invalidHosts, (invalidHost) => {
                  return this.defaultUriSchemeUtil.getManagedObjectReference(invalidHost);
               });

               if (validationData.deferredHostSettingsSpec.forceValidationOnHosts) {
                  validationData.deferredHostSettingsSpec.forceValidationOnHosts =
                        _.uniq(_.union(validationData.deferredHostSettingsSpec.forceValidationOnHosts,
                              invalidHostsMor), false,
                              (host: any) => {
                                 return host.value;
                              });
               } else {
                  validationData.deferredHostSettingsSpec.forceValidationOnHosts = invalidHostsMor;
               }

               validationData.deferredHostSettingsSpec.skipValidationOnHosts = [];
               skipValidationDeferred.reject({
                  genericErrors: [],
                  paramErrors : [],
                  invalidHosts: []
               });
            }
         };

         this.clarityModalService.openOkCancelModal(modalOptions);

         return skipValidationDeferred.promise;
      }

      // Traverse the errors in the tree using DFS (the tree consists of non-leaf nodes
      // of type CompositeException and leaf nodes of type != CompositeException) and
      // return only the leaf nodes - the actual exceptions
      private flatErrorsList(errors: Array<any>): Array<any> {
         errors = errors.concat();

         let result: Array<any> = [];
         while (errors.length > 0) {
            let error: any = errors.shift();
            if (!error) {
               continue;
            }

            if (error._type === this.hostProfileConstants.COMPOSITE_EXCEPTION) {
               Array.prototype.unshift.apply(errors, error.exceptions);
            } else {
               result.push(error);
            }
         }

         return result;
      }

      private getCommonCustomizationErrorMessages(errors: Array<any>): Array<string> {
         return _.chain(errors)
               .filter(exception => {
                  let message = exception.message;
                  if (!message) {
                     message = exception.localizedMessage;
                  }
                  return message &&
                        !(exception._type === this.hostProfileConstants.HOST_CUSTOMIZATION_IMPORT_EXCEPTION) &&
                        !message.startsWith(this.hostProfileConstants.BAD_REQUEST_EXCEPTION);
               })
               .map(exception => exception.message ? exception.message : exception.localizedMessage)
               .value();
      }
   }

   angular.module("com.vmware.vsphere.client.hostprofile")
         .service("hostCustomizationsErrorsService", HostCustomizationsErrorsServiceImpl);
}
