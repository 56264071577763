/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace dvs_ui {

   export class DvpgSelectPortgroupsPageComponent {

      public bindings: any;
      public controller: any;
      public templateUrl: string;

      constructor() {
         this.bindings = {
            wizardModel: "<"
         };

         this.controller = DvpgSelectPortgroupsPageComponentController;

         this.templateUrl = "dvs-ui/resources/dvs/dvpg/manage/views/" +
               "dvpgSelectPortgroupsPageTemplate.html";
      }
   }

   class DvpgSelectPortgroupsPageComponentController {

      static $inject = ["i18nService"];

      public wizardModel: DvpgManageWizardModel;

      private config: any;

      private isInitializing: boolean = true;

      constructor(private i18nService: any) {
      }

      $onInit(): void {
         this.config = {
            contextObject: this.wizardModel.parentId,
            multipleSelect: true,
            listTabConfig: {
               label: this.i18nService
                     .getString("DvsUi", "dvpg.manage.portGroupList.tabs.filter"),
               listConfig: [{
                  label: "Select distributed portgroups",
                  listViewId: "vsphere.core.dvs.dvpg.manage.list",
                  dataModels: ['DistributedVirtualPortgroup'],
                  filterId: "dvpgObjectsFilter",
                  filterParams: []
               }]
            },
            selectedObjects: _.map(this.wizardModel.selectedPortgroups,
                  (portgroup: any): string => {
                     return portgroup.id;
                  }),
            onSelectionChanged: (newSelectedItems: any[]): void => {
               // Avoid wizardModel.selectedPortgroups reassignment when the component
               // is being initialized with a non-empty array of selected portgroups.
               // This occurs in a wizard context, when the user has already completed
               // the current page and comes back to the same page later.
               // In this case the wizardModel.selectedPortgroups reassignment must be
               // skipped until the previous selection is fully restored,
               // because otherwise it would mark the page as INCOMPLETE.
               //
               // Do not skip the wizardModel.selectedPortgroups reassignment when
               // the #onSelectionChanged method is triggered by a user's click (isInitializing = false).
               if (!this.isInitializing || _.isEmpty(this.wizardModel.selectedPortgroups)) {
                  this.isInitializing = false;
                  this.wizardModel.selectedPortgroups = newSelectedItems;
               } else if (newSelectedItems.length ===
                     this.wizardModel.selectedPortgroups.length) {
                  this.isInitializing = false;
               }
            }
         };
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .component("dvpgSelectPortgroupsPage", new DvpgSelectPortgroupsPageComponent());
}
