/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace network_ui {

   import IController = angular.IController;
   import PnicBindingDataItem = com.vmware.vsphere.client.h5.network.host.wizard.addnetworking.model.PnicBindingDataItem;

   export interface BindToPhysicalAdapterModel {
      hostId: string;
      opaqueNetworkRef?: ManagedObjectReference;
      opaqueNetworkId?: string;
      opaqueNetworkType?: string;
      bindToPnicEnabled: boolean;
      selectedPnicForBinding: string;
      availablePnicsData: PnicBindingDataItem[] | null;
      onPnicSelectionChange: Function;
      onBindToPnicChange: Function;
      onAvailablePnicsDataChange: Function;
      onLoadingStateUpdate: Function;
   }

   export class BindToPhysicalAdapterComponent {

      public bindings: any;
      public controller: any;
      public templateUrl: string;

      constructor() {
         this.bindings = {
            hostId: "<",
            opaqueNetworkRef: "<?",
            opaqueNetworkId: "<?",
            opaqueNetworkType: "<?",
            bindToPnicEnabled: "<",
            selectedPnicForBinding: "<",
            availablePnicsData: "<",
            onPnicSelectionChange: "&",
            onBindToPnicChange: "&",
            onAvailablePnicsDataChange: "&",
            onLoadingStateUpdate: "&"
         };

         this.controller = BindToPhysicalAdapterController;

         this.templateUrl = "network-ui/resources/network/views/host/addnetworking/pages/BindToPhysicalAdapterComponent.html";
      }
   }

   class BindToPhysicalAdapterController implements IController {

      public static $inject = [
            "i18nService",
            "vuiConstants",
            "columnRenderersRegistry",
            "dataService",
            "physicalAdaptersListService",
            "physicalAdapterDetailsViewService",
            "$element",
            "bindToPhysicalAdapterService"];

      // Bindings
      public hostId: string;

      public opaqueNetworkRef: ManagedObjectReference | null;

      public opaqueNetworkId: string | null;

      public opaqueNetworkType: string | null;

      public bindToPnicEnabled: boolean;

      public selectedPnicForBinding: string;

      public availablePnicsData: PnicBindingDataItem[];

      public onPnicSelectionChange: (data: {selectedPnic: string | null}) => void;

      public onBindToPnicChange: (data: {bindToPnic: boolean}) => void;

      public onAvailablePnicsDataChange: (data: {availablePnicsData: PnicBindingDataItem[]}) => void;

      public onLoadingStateUpdate: (data: {loading: boolean}) => void;

      // Other public fields
      public i18n: Function;

      public datagridOptions: any;

      public noItemsSelectedMessage: string;

      public bindToPnic: boolean;

      public initPnicSelection: boolean;

      public detailsData: any;

      // Private fields
      private pnicsDetailsData: any = {};

      constructor(
            private i18nService: any,
            private vuiConstants: any,
            private columnRenderersRegistry: any,
            private dataService: any,
            private physicalAdaptersListService: any,
            private physicalAdapterDetailsViewService: any,
            private $element: any,
            private bindToPhysicalAdapterService: any) {
         this.i18n = this.i18nService.getString;
      }

      public $onInit() {
         this.noItemsSelectedMessage = this.i18nService
               .getString("NetworkUi", "PhysicalNicBindingEditor.detailsPrompt");

         this.datagridOptions = {
            height: "100%",
            pageConfig: {
               hidePager: true
            },
            columnDefs: this.getColumnDefinitions(),
            selectionMode: this.vuiConstants.grid.selectionMode.SINGLE,
            data: [],
            resizable: true,
            selectedItems: [],
            onChange: this.onSelectionChange.bind(this),
            selectedPnic: null,
            onBindedPnicChange: () => {
               this.selectPnicRow();
            }
         };

         this.bindToPnic = this.bindToPnicEnabled;
         this.datagridOptions.selectedPnic = this.selectedPnicForBinding;

         if (this.availablePnicsData) {
            this.datagridOptions.data = this.availablePnicsData;
         }

         this.initPnicSelection = true;
      }

      private selectPnic(pnicDevice: any) {
         this.datagridOptions.selectedPnic = pnicDevice;
         this.onPnicSelectionChange({
            selectedPnic: pnicDevice
         });
      }

      private selectPnicRow() {
         if (!this.datagridOptions.selectedPnic) {
            return;
         }

         let grid = this.$element.find('[kendo-grid]').data("kendoGrid");

         if (!grid || !grid.items) {
            return;
         }

         grid.items().each((index: number, row: any) => {
            if (grid.dataItem(row).device === this.datagridOptions.selectedPnic) {
               grid.select(row);
            }
         });
      }

      public $onChanges(changes: any) {
         if (this.bindToPnic
               && changes.availablePnicsData
               && changes.availablePnicsData.currentValue
               && !changes.availablePnicsData.isFirstChange()) {
            this.datagridOptions.data = changes.availablePnicsData.currentValue;
            this.updatePnicSelection();
         }
      }

      public bindToPnicChangeHandler() {
         this.onBindToPnicChange({
            bindToPnic: this.bindToPnic
         });

         if (this.bindToPnic) {

            if (this.availablePnicsData) {
               // Make the selected pnic to be the first one by default
               // or the already chosen one.
               // Also trigger the callback to update the selected Pnic on wizard's scope.
               this.updatePnicSelection();
            } else {
               this.onLoadingStateUpdate({
                  loading: true
               });

               if(this.opaqueNetworkRef) {
                  this.bindToPhysicalAdapterService.getDataByOpaqueNetworkRef(
                        this.hostId, this.opaqueNetworkRef)
                        .then((data: PnicBindingDataItem[]) => {
                           this.updateAvailablePnicsData(data);
                        }).finally(() => {
                     this.onLoadingStateUpdate({
                        loading: false
                     });
                  });
               } else if (this.opaqueNetworkId && this.opaqueNetworkType) {
                  this.bindToPhysicalAdapterService.getDataByOpaqueNetworkSpec(
                        this.hostId, this.opaqueNetworkId, this.opaqueNetworkType)
                        .then((data: PnicBindingDataItem[]) => {
                           this.updateAvailablePnicsData(data);
                        }).finally(() => {
                     this.onLoadingStateUpdate({
                        loading: false
                     });
                  });
               }
            }
         }
      }

      public onSelectionChange(selectedItems: PnicBindingDataItem[]) {
         let selectedPnic: any = (selectedItems && selectedItems.length > 0) ?
               selectedItems[0].device : null;

         if (selectedPnic === null) {
            this.detailsData = null;
         } else {
            this.selectPnic(selectedPnic);

            if (this.pnicsDetailsData[selectedPnic]) {
               this.detailsData = this.physicalAdapterDetailsViewService.build(
                     this.pnicsDetailsData[selectedPnic]);
            } else {
               this.physicalAdaptersListService
                     .getPhysicalAdapterDetailsItem(this.hostId, selectedPnic)
                     .then((result: any) => {
                        this.pnicsDetailsData[selectedPnic] = result;
                        this.detailsData = this.physicalAdapterDetailsViewService.build(
                              this.pnicsDetailsData[selectedPnic]);
                     });
            }
         }

         if (this.initPnicSelection) {
            this.initPnicSelection = false;
            this.selectPnicRow();
         }
      }

      private updatePnicSelection() {
         let pnicDevice: string | null = null;

         if (this.selectedPnicForBinding) {
            pnicDevice = this.selectedPnicForBinding;
         } else if (this.availablePnicsData && this.availablePnicsData.length > 0) {
            pnicDevice = this.availablePnicsData[0].device;
         }

         this.selectPnic(pnicDevice);
         this.selectPnicRow();
      }

      private updateAvailablePnicsData(data: PnicBindingDataItem[]) {
         this.onAvailablePnicsDataChange({
            availablePnicsData: data
         });
         this.datagridOptions.data = data;

         if (data && data.length) {
            this.selectPnic(data[0].device);
            this.selectPnicRow();
         }
      }

      private getColumnDefinitions() {
         let iconTextRenderer = this.columnRenderersRegistry.getColumnRenderer("icon-text");

         return [
            {
               width: "35px",
               template: (pnic: PnicBindingDataItem) => {
                  return `<input type="radio" ng-model="datagridOptions.selectedPnic" 
                                 ng-change="datagridOptions.onBindedPnicChange()" 
                                 name="bindPnic" value="${pnic.device}">`;
               }
            },
            {
               displayName: this.i18nService.getString("NetworkUi", "PhysicalNicBindingEditor.pnicColumnHeader"),
               field: "device",
               template: (pnic: PnicBindingDataItem) => {
                  let pnicItem = {
                     name: pnic.device,
                     icon: "network-lib-ui-icon-physicalAdapter"
                  };

                  return iconTextRenderer(
                        ["icon", "name"], pnicItem);
               }
            }
         ];
      }
   }

   angular.module("com.vmware.vsphere.client.network")
         .component("bindToPhysicalAdapterComponent", new BindToPhysicalAdapterComponent());
}