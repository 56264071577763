/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
(function() {
   'use strict';
   angular.module('com.vmware.vsphere.client.storage').factory('IncreaseDatastoreCapacityWizardManager', [
      'i18nService', 'dataService', 'bytesFilter', 'hostStorageService', 'storageUtil', '$q',
      function(i18nService, dataService, bytesFilter, hostStorageService, storageUtil, $q) {
         return function IncreaseDatastoreCapacityWizardManager () {
            var DETAILS_DATA_PROP = 'datastore:increaseDatastoreDetailsData';
            var state = {
               options: {},
               diskPartitionInfos: {}
            };
            var manager = {
               fetchVmfsDatastoreOptions: fetchVmfsDatastoreOptions,
               getReadyToCompleteModel: getReadyToCompleteModel,
               getDatastoreInfo: getDatastoreInfo,
               getDatastoreRef: getDatastoreRef,
               getDevices: getDevices,
               getOptionsForSelectedDevice: getOptionsForSelectedDevice,
               getDiskPartitionInfoForSelectedDevice: getDiskPartitionInfoForSelectedDevice,
               getSelectedOption: getSelectedOption,
               setSelectedOption: setSelectedOption,
               getSelectedDevice: getSelectedDevice,
               loadDatastoreInfo: loadDatastoreInfo,
               loadDevices: loadDevices,
               setDatastoreRef: setDatastoreRef,
               setSelectedDevice: setSelectedDevice,
               buildSpec: buildSpec
            };

            function loadDatastoreInfo () {
               return dataService.getProperties(state.datastoreRef, [DETAILS_DATA_PROP]).then(
                  function(result) {
                     state = _.extend(state, {
                        datastoreInfo: result[DETAILS_DATA_PROP]
                     });
                     return true;
                  }
               );
            }

            function getReadyToCompleteModel () {
               var vmfsDatastoreOption = state.selectedOption.vmfsDatastoreOption;

               return {
                  datastoreName: state.datastoreInfo.datastoreName,
                  capacityIncrease: getCapacityIncrease(state.selectedOption),
                  futureSize: calculateFutureSize(state.datastoreInfo, state.selectedOption.sizeToAdd),
                  formattedDeviceName: storageUtil.formatDeviceName(state.selectedDevice.disk),
                  partitionFormat: storageUtil.formatDiskPartitionType(
                     vmfsDatastoreOption.spec.partition.partitionFormat
                  ),
                  maximumFileSize: bytesFilter(state.datastoreInfo.maxFileSizeInB, 'B', 'Auto', 2),
                  vmfsVersion: state.datastoreInfo.vmfs.version,
                  blockSize: i18nService.getString(
                     'StorageUi',
                     'vmfsDatastore.blockSize.format', state.datastoreInfo.vmfs.blockSizeMb
                  )
               };
            }

            function setDatastoreRef (ref) {
               state.datastoreRef = ref;
            }

            function getDatastoreInfo(){
               return state.datastoreInfo;
            }

            function getDatastoreRef () {
               return state.datastoreRef;
            }

            function loadDevices () {
               return hostStorageService.retrieveDatastoreStorageDevices(state.datastoreRef).then(
                  function(result) {
                     state.connectedHost = result.connectedHost;
                     state.devices = result.scsiDiskInfos;
                  });
            }

            function getDevices () {
               return state.devices;
            }

            function getSelectedDevice () {
               return state.selectedDevice;
            }

            function setSelectedDevice (selectedDevice) {
               state.selectedDevice = selectedDevice;
            }

            function buildSpec () {
               return {
                  _type: 'com.vmware.vsphere.client.storage.DatastoreIncreaseSpec',
                  host: state.connectedHost,
                  disk: state.selectedDevice,
                  sizeToAdd: state.selectedOption.sizeToAdd * Math.pow(1024, 3),
                  increaseOption: state.selectedOption.vmfsDatastoreOption
               };
            }

            /**
             * Fetches VmfsDatastoreOptions before revealing the ReadyToCompletePage.
             */
            function fetchVmfsDatastoreOptions () {
               return hostStorageService.retrieveDatastoreIncreaseOptions(state.datastoreRef, state.connectedHost, state.selectedDevice).then(
                  function(result) {
                     state.options = state.options || {};
                     var vmfsDatastoreOptions = result['optionItems'];
                     // Each time the user chooses a device at "Select Device" page,
                     // and navigates to "Specify configuration" page, the capacity increase options
                     // are getting cached in state.options[selectedDevice.uuid]
                     //
                     // This way options for each device will be loaded only once
                     state.options[state.selectedDevice.disk.uuid] = vmfsDatastoreOptions;
                     state.diskPartitionInfos[state.selectedDevice.disk.uuid] = result['partitionInfo'];
                     return true;
                  }
               );
            }

            function getDiskPartitionInfoForSelectedDevice () {
               return state.selectedDevice ? state.diskPartitionInfos[state.selectedDevice.disk.uuid] : undefined;
            }

            function getOptionsForSelectedDevice () {
               return state.selectedDevice ? state.options[state.selectedDevice.disk.uuid] : undefined;
            }

            function getCapacityIncrease (selectedVmfsDatastoreOption) {
               var capacity = selectedVmfsDatastoreOption.sizeToAdd;
               return bytesFilter(capacity, 'GB', 'Auto', 2);

            }

            function getSelectedOption () {
               return state.selectedOption;
            }

            function setSelectedOption (selected) {
               state.selectedOption = selected;
            }

            function calculateFutureSize (datastoreInfo, sizeToAddGB) {
               var initialCapacityBytes = datastoreInfo.vmfs.capacity;
               var increaseBytes = sizeToAddGB * Math.pow(1024, 3);
               return bytesFilter(initialCapacityBytes + increaseBytes, 'B', 'Auto', 2);
            }

            return manager;
         };
      }]);
})();
