(function() {
   'use strict';
   angular.module('com.vmware.vsphere.client.cluster')
         .controller('FailuresAndResponsesPageController', FailuresAndResponsesPageController);

   FailuresAndResponsesPageController.$inject = ['$scope', 'i18nService'];

   function FailuresAndResponsesPageController($scope, i18nService) {
      var self = this;
      var haManager = $scope.modalOptions.dialogData.manager;

      self.pageModel = haManager.getFailuresAndResponsesModel();
      self.enableHostMonitoringLabel = getString('ha.config.hostmonitoring.enablefeature');
      self.hostMonitoringPopupParams = {
         title: i18nService.getString('Common', 'help'),
         message: getString('ha.config.hostmonitoring.description')
      };
      self.supportedFailureConditionsText = getSupportedFailureConditionsText();

      function getSupportedFailureConditionsText() {
         var supportedConditionsText;
         if (self.pageModel.storagePDLSupported) {
            if (self.pageModel.storageAPDSupported) {
               supportedConditionsText = getString(
                     'ha.config.failures.responses.host.failure.all.desc');
            } else {
               supportedConditionsText = getString(
                     'ha.config.failures.responses.host.failure.pdl.desc');
            }
         } else {
            if (self.pageModel.storageAPDSupported) {
               supportedConditionsText = getString(
                     'ha.config.failures.responses.host.failure.apd.desc');
            } else {
               supportedConditionsText = getString(
                     'ha.config.failures.responses.host.failure.none.desc');
            }
         }
         return supportedConditionsText;
      }

      function getString(key) {
         return i18nService.getString('ClusterUi', key);
      }
   }
})();
