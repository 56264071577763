/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
/*
 * Service to validate that the host name is with the correct format
 */
(function () {
   'use strict';
   angular.module('com.vmware.vsphere.client.host').service('addHostLockdownModePageService', addHostLockdownModePageService);

   addHostLockdownModePageService.$inject = ['i18nService'];

   function addHostLockdownModePageService(i18nService) {
      return {
         build: function (vuiWizardHelper) {
            return {
               title: i18nService.getString('HostUi', 'addHostWizard.lockDownPage.title'),
               description: i18nService.getString('HostUi', 'addHostWizard.lockDownPage.description'),
               contentUrl: 'host-ui/resources/host/views/addHostWizard/pages/addHostLockdownModePage.html',
               onCommit: function () {
                     vuiWizardHelper.markPageComplete();
                     return true;
               }
            };
         }
      };
   }
})();
