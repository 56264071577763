/* Copyright 2018 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace folder_ui {

   export class MoveFolderComponent {

      public bindings: any;
      public controller: any;
      public templateUrl: string;

      constructor() {
         this.bindings = {
            model: "<"
         };

         this.controller = MoveFolderComponentController;

         this.templateUrl =
               "folder-ui/resources/folder/views/folder/move/moveFolderComponentTemplate.html";
      }
   }

   class MoveFolderComponentController {

      public static $inject = ["defaultUriSchemeUtil", "managedEntityConstants"];

      public model: any;
      public folderId: string;
      public onLocationSelected: Function;

      public isDatacenterFolder: boolean = false;
      public isNetworkFolder: boolean = false;
      public isHostFolder: boolean = false;
      public isVmFolder: boolean = false;
      public isDatastoreFolder: boolean = false;

      public static readonly DATACENTER_FOLDER_VALUE: string = "group-d";
      public static readonly VM_FOLDER_VALUE: string = "group-v";
      public static readonly NETWORK_FOLDER_VALUE: string = "group-n";
      public static readonly HOST_FOLDER_VALUE: string = "group-h";
      public static readonly DATASTORE_FOLDER_VALUE: string = "group-s";

      constructor(private defaultUriSchemeUtil: any,
                  private managedEntityConstants: any) {}

      $onInit(): void {
         this.folderId = this.getFolderId();

         this.updateFolderType();

         this.onLocationSelected = (objectReference: any) => {
            this.model.targetEntity = objectReference;
         };
      }

      private updateFolderType(): void {
         if (this.isDcFolder(this.folderId)) {
            this.isDatacenterFolder = true;
         } else if (this.folderId.indexOf(MoveFolderComponentController.VM_FOLDER_VALUE) > 0) {
            this.isVmFolder = true;
         } else if (this.folderId.indexOf(MoveFolderComponentController.NETWORK_FOLDER_VALUE) > 0) {
            this.isNetworkFolder = true;
         } else if (this.folderId.indexOf(MoveFolderComponentController.HOST_FOLDER_VALUE) > 0) {
            this.isHostFolder = true;
         } else if (this.folderId.indexOf(MoveFolderComponentController.DATASTORE_FOLDER_VALUE) > 0) {
            this.isDatastoreFolder = true;
         }
      }

      private getFolderId() {
         // Assume that if there are 1+ objects of the same type
         // (folders of the same type or datacenters)
         // The move operation should not be available otherwise.
         const objId = this.model.objectIds[0];
         const objRef = this.defaultUriSchemeUtil.getManagedObjectReference(objId);
         if (objRef.type === this.managedEntityConstants.FOLDER
               && !this.isDcFolder(objId)) {
            return objId;
         }
         // For datacenters and datacenter folder the folderId should point to the root
         return this.defaultUriSchemeUtil.getRootFolder(objRef.serverGuid);
      }

      private isDcFolder(folderId: string): boolean {
         const folderRef = this.defaultUriSchemeUtil.getManagedObjectReference(folderId);
         return folderRef.value
               .indexOf(MoveFolderComponentController.DATACENTER_FOLDER_VALUE) === 0;
      }
   }

   angular.module("com.vmware.vsphere.client.folder")
         .component("moveFolderComponent", new MoveFolderComponent());

}

