/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace network_ui {
   export class EditVirtualSwitchTeamingAndFailoverPageComponent {

      public bindings: any;
      public templateUrl: string;
      public controller: any;

      constructor() {
         this.bindings = {
            pageModel: "<"
         };

         this.templateUrl = "network-ui/resources/network/views/host/vswitch/edit/" +
               "views/editVirtualSwitchTeamingAndFailoverPageComponent.html";

         this.controller = EditVirtualSwitchTeamingAndFailoverPageComponentController;
      }
   }

   class EditVirtualSwitchTeamingAndFailoverPageComponentController {

      static $inject = [
         "$scope",
         "networkUtil",
         "i18nService",
         "networkUiConstants",
         "editVirtualSwitchDialogValidator",
         "portFailoverPolicyPageService"
      ];

      public pageModel: TeamingAndFailoverPageModel;
      private loadBalancingOptions: any[];
      private failureDetectionOptions: any[];
      private notifySwitchesOptions: any[];
      private rollingOrderOptions: any[];
      private failoverOrder: any;
      private datagridHeaderOptions: any;
      private failoverOrderActions: any;
      private noItemsSelectedMessage: any;
      private validators: any;
      private signPostConfig: any;
      private signPostIcon: string;

      constructor(private $scope: any,
                  private networkUtil: any,
                  private i18nService: any,
                  private networkUiConstants: any,
                  private editVirtualSwitchDialogValidator: EditVirtualSwitchDialogValidator,
                  private portFailoverPolicyPageService: any) {
      }

      $onInit(): void {
         this.loadBalancingOptions = this.networkUtil.createNicTeamingPolicyOptions(
               this.pageModel.nicTeamingPolicy);

         this.validators = this.getValidators();

         this.failureDetectionOptions = this.getFailureDetectionOptions();

         this.notifySwitchesOptions = this.getNotifySwitchesOptions();

         this.rollingOrderOptions = this.getRollingOrderOptions();

         this.failoverOrder = {
            connectees: this.pageModel.pnics,
            connecteesDetails: this.pageModel.pnicDetails,
            labels: {
               active: this.networkUtil.getString("FailoverOrderEditor.active"),
               standby: this.networkUtil.getString("FailoverOrderEditor.standby"),
               unused: this.networkUtil.getString("FailoverOrderEditor.unused")
            }
         };

         this.datagridHeaderOptions = {
            title: this.networkUtil.getString("PortFailoverPolicyPage.orderLabel")
         };


         this.noItemsSelectedMessage = this.networkUtil.getString(
               "FailoverOrderEditor.detailsPrompt");

         this.failoverOrderActions = [
            this.networkUiConstants.failoverOrderActions.MOVE_UP,
            this.networkUiConstants.failoverOrderActions.MOVE_DOWN
         ];

         this.signPostConfig = {
            message: null,
            title: this.networkUtil.getString(
                  "PortFailoverPolicyPage.lb.loadbalance_ip.popup.title"),
            class: "edit-standard-vswitch-signpost"
         };

         this.initWatch();
      }

      private initWatch() {
         this.$scope.$watchCollection(() => {
            let objectsToWatch = [
               this.pageModel.checkBeacon,
               this.pageModel.loadBalancing
            ];
            if (this.pageModel.pnics) {
               objectsToWatch.push(this.pageModel.pnics.active.length);
               objectsToWatch.push(this.pageModel.pnics.standby.length);
               objectsToWatch.push(this.pageModel.pnics.unused.length);
            }

            return objectsToWatch;
         }, () => {
            this.updateSignPostConfig();
         });
      }

      private updateSignPostConfig() {
         let messsage: any[] = [];

         if (this.pageModel.loadBalancing ===
               this.networkUiConstants.LoadBalancingPolicy.IP) {

            let warnings: any[] =
                  this.editVirtualSwitchDialogValidator.getIpHashWarnings(this.pageModel);
            let infos: any[] =
                  this.editVirtualSwitchDialogValidator.getLoadBalancingInfoMessages();

            messsage = messsage.concat(warnings);
            messsage = messsage.concat(infos);
            this.signPostIcon = warnings.length > 0 ? "exclamation-triangle" : "info-circle";

         }
         this.signPostConfig.message =
               this.portFailoverPolicyPageService.formatMessages(messsage);
      }

      private onFailureDetectionChange() {
         this.validators.failureDetection.isVisible = !this.editVirtualSwitchDialogValidator
               .isFailureDetectionValid(this.pageModel.checkBeacon, this.pageModel.pnics);
      }

      private getValidators(): any {
         let validators: any = new Object();
         validators.failureDetection = {
            message: this.i18nService.getString("NetworkUi", "PortPolicyData.failover.error.beaconProbingSingleNic"),
            isVisible: false
         };

         return validators;
      }

      private getFailureDetectionOptions(): any[] {
         return [{
            label: this.i18nService.getString("NetworkUi", "PortFailoverPolicyView.linkStatus"),
            val: false
         }, {
            label: this.i18nService.getString("NetworkUi", "PortFailoverPolicyView.beaconProbing"),
            val: true
         }];
      }

      private getNotifySwitchesOptions(): any[] {
         return [{
            label: this.i18nService.getString("NetworkUi", "PortFailoverPolicyView.notifyOff"),
            val: false
         }, {
            label: this.i18nService.getString("NetworkUi", "PortFailoverPolicyView.notifyOn"),
            val: true
         }];
      }

      private getRollingOrderOptions(): any[] {
         return [{
            label: this.i18nService.getString("NetworkUi", "PortFailoverPolicyView.failbackOff"),
            val: true
         }, {
            label: this.i18nService.getString("NetworkUi", "PortFailoverPolicyView.failbackOn"),
            val: false
         }];
      }
   }

   angular.module("com.vmware.vsphere.client.network")
         .component("editVirtualSwitchTeamingAndFailoverPage",
               new EditVirtualSwitchTeamingAndFailoverPageComponent());
}
