namespace h5_vm {
   import IComponentController = angular.IComponentController;
   import IComponentOptions =  angular.IComponentOptions;
   import FlagInfo = com.vmware.vim.binding.vim.vm.FlagInfo;
   import GuestOsDescriptor = com.vmware.vim.binding.vim.vm.GuestOsDescriptor;

   export class VBS implements IComponentOptions {
      public bindings: any;
      public controller: any;
      public templateUrl: string;

      constructor() {
         this.bindings = {
            configFlags: '<',
            guestOsDescriptor: '<',
            onVbsUpdate: '&',
            visible: '<',
            vmPoweredOn: '<'
         };
         this.controller = VbsController;
         this.templateUrl = 'vm-ui/resources/vm/views/settings/vmOptions/vbs/vbs.html';
      }
   }

   export class VbsController implements IComponentController {
      //Component input
      public configFlags : FlagInfo;
      public guestOsDescriptor : GuestOsDescriptor;
      public onVbsUpdate: Function;
      public visible: boolean;
      public vmPoweredOn: boolean;

      static $inject = [
         'i18nService', 'featureFlagsService'
      ];

      /**
       * this is the view viewModel, which will reflect changes from the view
       */
      // public viewModel: VBSViewModel;
      public i18n: Function;
      public vbsWillTurnOn: boolean;
      public vbsWillTurnOff: boolean;

      constructor(private i18nService: any, private featureFlagsService: any) {
         this.i18n = i18nService.getString;
      }

      /**
       * any view changes will trigger this, update the parent
       * with the updated viewModel immediately.
       */
      public onPropertyChanged = () => {
         this.onVbsUpdate({vbsEnabled: this.configFlags.vbsEnabled});
         if (this.configFlags.vbsEnabled) {
             this.vbsWillTurnOn = true;
             this.vbsWillTurnOff = false;
         } else {
             this.vbsWillTurnOn = false;
             this.vbsWillTurnOff = true;
         }
      }

      /**
       * build the passed on vmConfigContext to be used
       * by the view.
       */
      public $onInit = () => {
         if (!this.featureFlagsService.hardwareV14_vbsEnabled ||
             !this.featureFlagsService.hardwareV14_vbsEnabled() ||
             !this.guestOsDescriptor.vbsSupported ||
             !this.guestOsDescriptor.vbsSupported.supported) {
            this.visible = false;
         }
      }

      public isDisabled = () => {
         return this.vmPoweredOn;
      }
   }
   angular
         .module('com.vmware.vsphere.client.vm')
         .component('vbs', new VBS());
}
