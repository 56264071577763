namespace storage_ui {

   export class StorageProviderCertificateDetails {

      public propertyViewData: any;

      public storageProviderListItem: StorageProviderListItem;

      public static $inject = ["storageProvidersItemBuilderService"];

      constructor(private storageProvidersItemBuilderService: StorageProvidersItemBuilderService) {
      }

      public $onChanges() {
         this.refresh();
      }

      public refresh() {
         if (!this.storageProviderListItem) {
            return;
         }

         this.propertyViewData = null;

         this.storageProvidersItemBuilderService.getStorageProviderCertificateDetails(
               this.storageProviderListItem).then((certificateProperties) => {
            this.propertyViewData = certificateProperties;
         });
      }
   }

   angular.module("com.vmware.vsphere.client.storage")
         .component("storageProviderCertificateDetails", {
            template: "<vx-property-view data=\"$ctrl.propertyViewData\"></vx-property-view>",
            bindings: {
               storageProviderListItem: "<"
            },
            controller: StorageProviderCertificateDetails
         });
}
