namespace h5_vm {
   import StoragePlacementResult = com.vmware.vim.binding.vim.storageDrs.StoragePlacementResult;
   import Action = com.vmware.vim.binding.vim.cluster.Action;
   import ManagedObjectReference = com.vmware.vim.binding.vmodl.ManagedObjectReference;

   declare var kendo: {
      template(template: string): any;
   };

   export class VmPlacementFaultViewController {
      public static $inject = [
         "$q",
         "$scope",
         "vuiConstants",
         "i18nService"];

      // Input bindings
      public faults: any[];

      // View bindings
      public gridOptions: any;

      constructor(
            private $q: angular.IQService,
            private $scope: angular.IScope,
            private vuiConstants: any,
            private i18nService: any) {
      }

      $onInit(): void {
         this.init();
      }

      private init() {
         let MISSING_VALUE: string = this.i18nService.getString(
               "VmUi", "drs.faults.missingObjectName");
         this.gridOptions = {
            columnDefs: [{
               displayName: this.i18nService.getString(
                     "Common", "drs.faultView.vmColumn"),
               field: "vmName",
               width: "25%",
               template: kendo.template(
                  "#if(vmName){#" +
                  "   <span class='vsphere-icon-vm'></span>#:vmName#" +
                  "#}else{#" +
                      MISSING_VALUE +
                  "#}#")
            }, {
               displayName: this.i18nService.getString(
                     "Common", "drs.faultView.hostColumn"),
               field: "hostName",
               width: "25%",
               template: kendo.template(
                     "#if(hostName){#" +
                     "   <span class='vsphere-icon-host'></span>#:hostName#" +
                     "#}else{#" +
                         MISSING_VALUE +
                     "#}#")
            }, {
               displayName: this.i18nService.getString(
                     "Common", "drs.faultView.descriptionColumn"),
               field: "message",
               width: "50%",
               template: "#:message#"
            }],
            sortMode: this.vuiConstants.grid.sortMode.SINGLE,
            selectionMode: this.vuiConstants.grid.selectionMode.SINGLE,
            selectedItems: [],
            data: this.faults,
            resizable: true,
            searchable: false,
            height: 350
         };
      }
   }

   export class VmPlacementFaultView implements angular.IComponentOptions {
      public controller: any;
      public templateUrl: string;
      public bindings: any;

      constructor() {
         this.controller = VmPlacementFaultViewController;
         this.templateUrl =
               "vm-ui/resources/vm/views/migrationWizard/components/" +
               "vmPlacementRecommendation/vmPlacementFaultView.html";
         this.bindings = {
            faults: "<"
         };
      }
   }

   angular
         .module("com.vmware.vsphere.client.vm")
         .component("vmPlacementFaultView", new VmPlacementFaultView());
}

