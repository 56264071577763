namespace storage_ui {

   import IComponentController = angular.IComponentController;

   export class DatastoreFileBrowserFooterController implements IComponentController {

      static $inject = ["i18nService", "bytesFilter"];

      constructor(public i18nService: any, bytesFilter: any) {}

      $onInit() {}
   }

   angular.module("com.vmware.vsphere.client.storage")
         .component("datastoreFileBrowserFooter",
               <IComponentOptions>{
                  controller: DatastoreFileBrowserFooterController,
                  bindings: {
                     showUploads: "<",
                     isUploadRunning: "<",
                     uploadedItems: "<",
                     enqueuedItems: "<",
                     uploadedItemsSizeInB: "<",
                     enqueuedItemsSizeInB: "<",
                     onCancelUploadClicked: "&"
                  },
                  templateUrl: "storage-ui/resources/storage/views/manage/files/footer/DatastoreFileBrowserFooterComponent.html"
               });
}