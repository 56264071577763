/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace dvs_ui {
   import IPromise = angular.IPromise;
   import DvsHostsData = com.vmware.vsphere.client.dvs.api.host.DvsHostsData;

   export class DvsRectifyHostsOutOfSyncService {

      private static readonly DVS_HOSTS_DATA_PROP: string = "dvs:dvsOutOfSyncHostsData";

      public static $inject = [
         "clarityModalService",
         "i18nService",
         "dataService",
         "mutationService",
         "clarityConstants"
      ];

      constructor(
            private clarityModalService: any,
            private i18nService: any,
            private dataService: any,
            private mutationService: any,
            private clarityConstants: any) {
      }

      public show(entityId: string): void {

         let modalOptions:any = {
            title: this.i18nService.getString("DvsUi", "OutOfSyncDvsHost.rectifyDetails"),
            subTitle: {
               objectId: entityId
            },
            size: "lg",
            submitButtonTitle: this.i18nService.getString("DvsUi", "OutOfSyncDvsHost.rectifyButton"),
            defaultButton: "submit",
            alerts: [],
            dialogData: {
               hosts: [],
               loading: true,
               selectedHosts: null
            },
            onSubmit: () => {
               if (!modalOptions.dialogData.selectedHosts.length) {

                  modalOptions.alerts = [{
                     text: this.i18nService.getString("DvsUi",
                           "OutOfSyncDvsHost.message.noHostSelected"),
                     type: this.clarityConstants.notifications.type.ERROR
                  }];

                  return false;
               }

               let DVS_RECTIFY_HOST_SPEC: string =
                     "com.vmware.vsphere.client.h5.network.dvs.summary.spec.DvsRectifyOutOfSyncHostSpec";

               let rectifyOutOfSyncHost = {
                  _type: DVS_RECTIFY_HOST_SPEC,
                  hosts: _.pluck(modalOptions.dialogData.selectedHosts, "hostRef")
               };

               this.mutationService.apply(entityId,
                     DVS_RECTIFY_HOST_SPEC,
                     rectifyOutOfSyncHost);

               return true;
            },
            contentTemplate: "dvs-ui/resources/dvs/summary/views/dvsRectifyHostsOutOfSync.html",
            modalClass: "rectify-out-of-sync-hosts-modal"
         };


         let dvsHostsPromise: IPromise<any> = this.dataService
               .getProperties(entityId,
                     [DvsRectifyHostsOutOfSyncService.DVS_HOSTS_DATA_PROP]);

         dvsHostsPromise.then((dataServiceResponse: any) => {
            let data: DvsHostsData = dataServiceResponse[DvsRectifyHostsOutOfSyncService.DVS_HOSTS_DATA_PROP];
            if (data) {
               modalOptions.dialogData.hosts = data.dvsHosts;
            }
         }).finally(() => {
            modalOptions.dialogData.loading = false;
         });

         this.clarityModalService.openOkCancelModal(modalOptions);
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .service("dvsRectifyHostsOutOfSyncService", DvsRectifyHostsOutOfSyncService);
}
