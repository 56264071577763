/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace dvs_ui {

   export class DvsCreateWizardPages {

      public static $inject = [
            "i18nService",
            "dvsCreateWizardConstants",
            "simpleValidationCommit",
            "dvsCommonPropertiesValidator",
            "dvpgPropertiesService"
      ];

      constructor(private i18nService: any,
                  private dvsCreateWizardConstants: any,
                  private simpleValidationCommit: any,
                  private validator: DvsCommonPropertiesValidator,
                  private dvpgPropertiesService: any) {
      }

      /**
       * Creates an array of page definitions for the Dvs create wizard.
       */
      public createPages(wizardScope: DvsCreateWizardScope): WizardPage[] {

         let selectNameAndLocationPage: WizardPage = {
            id: this.dvsCreateWizardConstants.page.DVS_SELECT_NAME_AND_LOCATION_PAGE,
            title: this.i18nService.getString("DvsUi", "DvsNameAndLocationPage.title"),
            description: this.i18nService.getString("DvsUi",
                  "DvsNameAndLocationPage.description"),
            contentUrl: "dvs-ui/resources/dvs/create/views/dvsNameAndLocationPage.html",
            onCommit: this.simpleValidationCommit((): string[] => {
               let nameError: string =
                     this.validator.getNameError(
                           wizardScope.model.uniqueDvsName);

               if (!nameError) {
                  return this.validator.getNameServerError(
                        wizardScope.model.parentEntityUrn,
                        wizardScope.model.uniqueDvsName)
                        .then(function (error: string) {
                           return error ? [error] : [];
                        });
               } else {
                  return [nameError];
               }
            }, wizardScope.wizardConfig),
            validationParams: ["model.uniqueDvsName"]
         };

         let selectVersionPage: WizardPage = {
            id: this.dvsCreateWizardConstants.page.DVS_SELECT_VERSION_PAGE,
            title: this.i18nService.getString("DvsUi", "DvsVersionPage.title"),
            description: this.i18nService.getString("DvsUi",
                  "DvsVersionPage.description"),
            contentUrl: "dvs-ui/resources/dvs/create/views/dvsVersionPage.html",
            onCommit: this.simpleValidationCommit(function () {
               return [];
            }, wizardScope.wizardConfig),
            validationParams: ["model.selectedVersion"]
         };

         let configureSettingsPage: WizardPage = {
            id: this.dvsCreateWizardConstants.page.DVS_CONFIGURE_SETTINGS_PAGE,
            title: this.i18nService.getString("DvsUi", "DvsConfigureSettingsPage.title"),
            description: this.i18nService.getString("DvsUi",
                  "DvsConfigureSettingsPage.description"),
            contentUrl: "dvs-ui/resources/dvs/create/views/dvsConfigureSettingsPage.html",
            onCommit: this.simpleValidationCommit((): string[] => {
               let errors: string[] = [];

               let numUplinksError: string =
                     this.validator.getNumberOfUplinksError(
                           wizardScope.model.numberOfUplinks);

               if (numUplinksError) {
                  errors.push(numUplinksError);
               }

               if (wizardScope.model.createDefaultPortgroup) {
                  let portgroupNameError: string =
                        this.dvpgPropertiesService.getInvalidNameError(
                              wizardScope.model.defaultPortgroupName);

                  if (portgroupNameError) {
                     errors.push(portgroupNameError);
                  }
               }

               if (errors.length > 0) {
                  return errors;
               } else {
                  if (wizardScope.model.createDefaultPortgroup) {
                     return this.dvpgPropertiesService.getDuplicateOrTooLongNameError(
                           wizardScope.model.parentEntityUrn,
                           wizardScope.model.defaultPortgroupName)
                           .then((errors: string[]): any => {
                              return errors;
                           });
                  } else {
                     return [];
                  }
               }
            }, wizardScope.wizardConfig),
            validationParams: ["model.numberOfUplinks",
                  "model.createDefaultPortgroup",
                  "model.defaultPortgroupName"]
         };

         let readyToCompletePage: WizardPage = {
            id: this.dvsCreateWizardConstants.page.DVS_READY_TO_COMPLETE_PAGE,
            title: this.i18nService.getString("DvsUi",
                  "DvsReadyToCompletePage.title"),
            description: this.i18nService.getString("DvsUi",
                  "DvsReadyToCompletePage.description"),
            contentUrl: "dvs-ui/resources/dvs/create/views/dvsReadyToCompletePage.html",
            onCommit: (): boolean => {
               return true;
            },
            validationParams: []
         };

         return [selectNameAndLocationPage, selectVersionPage, configureSettingsPage,
               readyToCompletePage];
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .service("dvsCreateWizardPages", DvsCreateWizardPages);
}
