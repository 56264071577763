/* Copyright 2015 VMware, Inc. All rights reserved. -- VMware Confidential */
/*
 * Factory that contains the settings views header options to be used with vx-settings-block-header directive
 * and settings views option to be used with vx-gridview-settings or vx-stackview-settings directive for each host data-driven settings
 * extension id.
 */
(function() {
   'use strict';
   angular.module('com.vmware.vsphere.client.host').factory('HostDataDrivenSettingsConfigFactory', HostDataDrivenSettingsConfigFactory);

   var i18n, timeService, qService;
   HostDataDrivenSettingsConfigFactory.$inject = ['i18nService', 'timeFormatterService', '$q'];

   function HostDataDrivenSettingsConfigFactory(i18nService, timeFormatterService, $q) {
      i18n = i18nService;
      timeService = timeFormatterService;
      qService = $q;
      return {
         'vsphere.core.host.manage.settings.agentVmSettingsView': {
            headerOptions: {
               title: i18nService.getString('HostUi', 'settings.agentVm.title'),
               actions: [{ actionUid:'vsphere.core.host.actions.editAgentVmSettings',
                           customLabel: i18nService.getString('HostUi', 'settings.edit')
                        }]
            },
            settingsViewOptions: {
               propertyName: 'agentVmSettings',
               liveRefreshProperties: [
                     'summary.config.agentVmNetwork',
                     'summary.config.agentVmDatastore',
                     'config.fileSystemVolume.mountInfo'
               ]
            }
         },
         'vsphere.core.host.manage.settings.memoryConfigView': {
            headerOptions: {
               title: i18nService.getString('HostUi', 'settings.memoryConfig.title'),
               actions: []
            },
            settingsViewOptions: {
               propertyName: 'memoryConfig',
               liveRefreshEnabled: false
            }
         },
         'vsphere.core.host.manage.settings.powerConfigHardwareView': {
            headerOptions: {
               title: i18nService.getString('HostUi', 'settings.powerConfigHardware.title'),
               actions: [{ actionUid:'vsphere.core.host.actions.editPowerConfigHardware',
                           customLabel: i18nService.getString('HostUi', 'settings.edit')
                        }]
            },
            settingsViewOptions: {
               propertyName: 'powerConfigHardware',
               delayRefreshTimeInMsecsOnObjectUpdate: 1000,
               liveRefreshProperties: [
                  'config.powerSystemInfo.currentPolicy.name',
                  'hardware.cpuPowerManagementInfo.currentPolicy',
                  'config.powerSystemInfo.currentPolicy.description',
                  'config.powerSystemInfo.currentPolicy.shortName',
                  'config.powerSystemInfo.currentPolicy.key'
               ]
            }
         },
         'vsphere.core.host.manage.settings.powerConfigSoftwareView': {
            headerOptions: {
               title: i18nService.getString('HostUi', 'powerConfigSoftware.powerManagementSettings'),
               actions: [{ actionUid:'vsphere.core.host.actions.editPowerConfigSoftware',
                           customLabel: i18nService.getString('HostUi', 'settings.edit')
               }]
            },
            settingsViewOptions: {
               propertyName: 'powerConfigSoftware',
               liveRefreshProperties: [
                  'config.ipmi.bmcMacAddress',
                  'config.ipmi.bmcIpAddress',
                  'config.ipmi.login'
               ]
            }
         },
         'vsphere.core.host.manage.settings.certificateView': {
            headerOptions: {
               title: i18nService.getString('HostUi', 'settings.certificate.title'),
               actions: [{ actionUid:'vsphere.core.host.actions.refreshCertificate',
                           customLabel: i18nService.getString('HostUi',
                                 'settings.certificate.refresh.label')
               }, { actionUid:'vsphere.core.host.actions.refreshCaCertificate',
                     customLabel: i18nService.getString('HostUi',
                           'settings.certificate.refreshCA.label')
               }]
            },
            settingsViewOptions: {
               propertyName: 'certificateConfig',
               supportedKey: 'isCertificateManagerSupported',
               unsupportedMessage: i18nService.getString('HostUi', 'certificate.unsupported.msg'),
               liveRefreshProperties: [
                  "config.certificate"
               ],
               formatValueCallback: formatCertificateValue

            }
         },
         'vsphere.core.host.manage.settings.advancedSystemSettingsView': {
            headerOptions: {
               title: i18nService.getString('HostUi', 'advancedSettings.view.title'),
               actions: [{ actionUid:'vsphere.core.host.actions.editAdvancedSystemSettings',
                  customLabel: i18nService.getString('HostUi', 'settings.edit')
               }]
            },
            settingsViewOptions: {
               type: 'grid',
               propertyName: 'advancedSettings',
               liveRefreshProperties: [
                  "config.option"
               ]
            }
         }
      };
   }

   /**
    * Property value format function - in some cases the property value is not a string and it requires some custom
    * formatting.
    * @param propName
    *    Name of the property.
    * @param contentValue
    *    Raw value of the property - might be a string or an object.
    */
   function formatCertificateValue(propName, contentValue) {
      if (propName === 'certificateStatus') {
         return qService.when(formatCertificateStatusValue(contentValue));
      }

      if (propName === 'certificateValidFrom' ||
            propName === 'certificateValidTo') {
         return qService.when(contentValue);
      }

      // for all the properties that there is no need for custom formatting - just return the raw content value
      return qService.when(contentValue);
   }

   function formatCertificateStatusValue(statusKey) {
      var nonNullStatusKey = statusKey || 'unknown';
      return i18n.getString('HostUi', 'certificate.status.' + nonNullStatusKey);
   }
})();
