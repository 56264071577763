namespace h5_vm {
   export interface Tab {
      iconClass: string;
      tooltipText: string;
      contentUrl: string;
   }

   export interface AlertMessage {
      text: string;
      type: string;
   }

   export class MoveVmViewComponent {

      public bindings: any;
      public controller: any;
      public templateUrl: string;

      constructor() {
         this.bindings = {
            objectIds: "<", // the selected objects for which the move will be done
            isTemplate: "<", // true if the selection contains Vm templates
            datacenter: "<", // the datacenter of the first vm, will be used only
                             // for move of a single vm
            virtualCenter: "<", // the VC of the VM. Used for root of the move Vm dialog
                                // in environment with many VCs we should display only
                                // the one from that all selected vms are.
                                // the move to action should not be available for selection
                                // of multiple vms from different VCs
            onSubmit: "=", // the method that is called by the clarity modal service
                           // when `Ok` is pressed
            submitDisabled: "=", // property of the modal dialog that show whether or not
                                 // `OK` button should be disabled
            alerts: "=", // property of the modal dialog, when initialized with data
                         // an alert will be shown
            closeModal: "<" // method of the modal dialog, when invoked the modal closes
         };
         this.controller = MoveVmController;
         this.templateUrl = "vm-ui/resources/vm/services/wizards/moveVm/moveVmViewComponent.html";
      }
   }
   export class MoveVmController {
      public static $inject: string[] = ["moveVmService", "i18nService", "vuiConstants",
         "$element", "$timeout"];

      public objectIds: string[];
      public isTemplate: boolean;
      public datacenter: string;
      public virtualCenter: string;
      public onSubmit: Function;
      public submitDisabled: boolean = true;
      public closeModal: Function;
      public alerts: AlertMessage[];
      public loading: boolean = false;
      public treeAriaDescription: string;
      public tabName: string;
      public treeHtml: string;
      public loadingLabel: string;

      private _i18nService: any;
      private _moveVmService: MoveVmService;

      private _targetId: string;

      /**
       * Builds the initial state of the modal dialog content
       * - sets the `OK` button as disabled
       * - initializes the onSubmit method that will be called after `OK` is pressed
       * @param moveVmService
       * @param i18nService
       * @param $element
       * @param $timeout
       */
      public constructor(moveVmService: MoveVmService, i18nService: any,
            private vuiConstants: any, private $element: any, private $timeout: any) {
         this._moveVmService = moveVmService;
         this._i18nService = i18nService;

         this.loadingLabel = this._i18nService.getString("CommonUi", "wizard.loading");
         this.treeAriaDescription = this._i18nService.getString("VmUi", "MoveToFolderDialog.treeDescription");
         this.tabName = this._i18nService.getString("VmUi", "actionMoveVmInventoryFolder");
      }

      $onInit() {
         // mark the `OK` button as disabled
         this.submitDisabled = true;
         this.treeHtml = "vm_folders_tree.html";
         if (this.objectIds.length === 1) {
            this.treeHtml = "vm_folders_in_datacenter_tree.html";
         }

         // initializes the onSubmit method that will be called after `OK` is pressed
         // it closes the modal and applies the move operation
         this.onSubmit = () => {
            this.submitDisabled = true;
            this.loading = true;
            this._moveVmService
                  .validateMove(this.objectIds, this._targetId).then((error: string) => {
               if (!error) {
                  this._moveVmService.applyMove(this.objectIds, this._targetId);
                  this.closeModal();
               } else {
                  let alert: AlertMessage = <AlertMessage> {
                     type: this.vuiConstants.validationBanner.type.ERROR,
                     text: error
                  };
                  this.alerts = [alert];
               }
               this.loading = false;
               this.submitDisabled = false;
            });
         };

         this.$timeout(() => {
            const firstTab = this.$element[0].querySelector("button");
            if (firstTab) {
               firstTab.focus();
            }
         }, 0);
      }

      /**
       * Invoked when the selection in the tree is changed.
       * Marks the `OK` button as enabled, because now we have a selection in the tree
       * And saves the selected object id
       * @param objectId
       */
      public onLocationSelected(objectId: string) {
         this.submitDisabled = false;
         this._targetId = objectId;
      }
   }

   angular.module("com.vmware.vsphere.client.vm")
         .component("moveVmView", new MoveVmViewComponent());
}
