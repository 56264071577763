/* Copyright 2016-2017 VMware, Inc. All rights reserved. -- VMware Confidential */
(function() {
   'use strict';
   angular.module('com.vmware.vsphere.client.commonModule')
      .controller('CategoriesController', CategoriesController);

   CategoriesController.$inject = ['categoryService', 'categoryActionService', 'i18nService', '$scope', 'vuiConstants'];

   function CategoriesController (categoryService, categoryActionService, i18nService, $scope, vuiConstants) {
      var self = this;

      function addCategory () {
         categoryActionService.addEditCategory(self.datagridOptions.data);
      }

      function deleteCategory() {
         var item = self.datagridOptions.selectedItems[0];
         categoryActionService.deleteCategory(item.managedObjectReference);
      }

      function editCategory() {
         categoryActionService.addEditCategory(self.datagridOptions.data, self.datagridOptions.selectedItems[0]);
      }

      var addAction = {
         id: 'add-category-action',
         iconClass: 'vsphere-icon-toolbar-add',
         tooltipText: i18nService.getString('CommonModuleUi', 'add.category'),
         enabled: true,
         onClick: addCategory
      };
      var editAction = {
         id: 'edit-tag-action',
         iconClass: 'vsphere-icon-toolbar-edit',
         tooltipText: i18nService.getString('CommonModuleUi', 'edit.category'),
         enabled: true,
         onClick : editCategory
      };
      var deleteAction = {
         id: 'delete-category-action',
         iconClass: 'vsphere-icon-toolbar-remove',
         tooltipText: i18nService.getString('CommonModuleUi', 'categoryAction.remove.desc'),
         enabled: false,
         onClick: deleteCategory
      };

      self.datagridOptions = {
         actionBarOptions: {
            actions: [
               addAction,
               editAction,
               deleteAction
            ]
         },
         columnDefs: [
            {
               displayName: i18nService.getString('Common', 'tag.dashboard.categories.name'),
               field: 'categoryName',
               sortable: true,
               searchable: false,
               template: function(dataItem) {
                  return dataGridTemplate(dataItem,'categoryName');
               }
            },
            {
               displayName: i18nService.getString('Common', 'tag.dashboard.categories.desc'),
               field: 'categoryDescription',
               sortable: true,
               searchable: false,
               template: function(dataItem) {
                  return dataGridTemplate(dataItem,'categoryDescription');
               }
            },
            {
               displayName: i18nService.getString('Common', 'tag.dashboard.categories.cardinality'),
               field: 'multipleCardinality',
               sortable: true,
               searchable: false,
               template: function(dataItem) {
                  return dataGridTemplate(dataItem,'multipleCardinality');
               }
            },
            {
               displayName: i18nService.getString('Common', 'tag.dashboard.categories.associable'),
               field: 'displayAssociableEntityTypesString',
               sortable: true,
               searchable: false,
               template: function(dataItem) {
                  return dataGridTemplate(dataItem,'displayAssociableEntityTypesString');
               }
            }
         ],
         sortOrder: [{
            field: 'categoryName',
            dir: 'asc'
         }],
         height: '100%',
         resizable: true,
         selectionMode: vuiConstants.grid.selectionMode.SINGLE,
         selectedItems: [],
         data: []
      };

      function transform (categories) {
         return _.map(categories, function(category) {
            if (category.associableEntityTypes.length === 0) {
               category.displayAssociableEntityTypesString = i18nService.getString('Common', 'tag.dashboard.categories.associable.all');
            } else {
               category.displayAssociableEntityTypesString = lookupEntityNames(category.associableEntityTypes).join(', ');
            }
            return category;
         });
      }

      function lookupEntityNames (entityTypes) {
         return _.map(entityTypes, function(entityType) {
            return i18nService.getString('Common', 'typeResource.entityType.' + entityType + 'Plural');
         });
      }

      function refreshCategories () {
         categoryService.getAll().then(function(data) {
            self.datagridOptions.data = transform(data);
         });
      }

      /**
       *  Generate html template for datagrid
       */
      function dataGridTemplate (dataItem, fieldName) {
         var ele = document.createElement("span");
         ele.setAttribute("ng-non-bindable", "ng-non-bindable");
         ele.title = dataItem[fieldName];
         ele.textContent = dataItem[fieldName];
         return ele.outerHTML;
      }

      $scope.$watch('ctrl.datagridOptions.selectedItems', function (selectedItems) {
         var selected = selectedItems.length > 0;
         deleteAction.enabled = selected;
         editAction.enabled = selected;
      });

      $scope.$on('refreshCategories', function(){
         refreshCategories();
      });

      refreshCategories();
   }
})();
