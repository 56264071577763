/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace network_ui {
   import NetStackConfigItem = com.vmware.vsphere.client.h5.network.host.netstack.model.NetStackConfigItem;
   import NetStackReconfigureSpec$DnsReconfigureSpec = com.vmware.vsphere.client.h5.network.host.netstack.model.NetStackReconfigureSpec$DnsReconfigureSpec;

   export class NetStackEditDnsConfigPageModel {
      public vnicsWithDhcpEnabled: string[];
      public isDhcpEnabled: boolean;
      public selectedVnic: string;
      public hostName: string;
      public domainName: string;
      public preferredDns: string;
      public alternateDns: string;
      public searchDomains: string;
      public isDefaultNetStack: boolean;
      public isIpv6Enabled: boolean;

      public constructor(data: NetStackConfigItem) {
         this.isDhcpEnabled = data.isDhcpEnabled;
         this.vnicsWithDhcpEnabled = data.vnicsWithDhcpEnabled;
         this.selectedVnic = data.selectedVnic;
         this.hostName = data.hostName;
         this.domainName = data.domainName;
         this.preferredDns = data.preferredDns;
         this.alternateDns = data.alternateDns;
         this.searchDomains = data.searchDomains;
         this.isIpv6Enabled = data.isIpv6Enabled;
         this.isDefaultNetStack = data.isDefaultNetStack;
      }

      public buildSpec(originalData: NetStackEditDnsConfigPageModel): NetStackReconfigureSpec$DnsReconfigureSpec | null {
         let spec: NetStackReconfigureSpec$DnsReconfigureSpec =
               new NetStackReconfigureSpec$DnsReconfigureSpec();

         if (originalData.isDhcpEnabled !== this.isDhcpEnabled) {
            spec.isDhcpEnabled = this.isDhcpEnabled;
         }

         if (this.isDhcpEnabled) {
            if (originalData.selectedVnic !== this.selectedVnic) {
               spec.selectedVnic = this.selectedVnic;
            }
         } else {
            if (originalData.hostName !== this.hostName) {
               spec.hostName = this.hostName;
            }

            if (originalData.domainName !== this.domainName) {
               spec.domainName = this.domainName;
            }

            if (originalData.preferredDns !== this.preferredDns) {
               spec.preferredDns = this.preferredDns;
            }

            if (originalData.alternateDns !== this.alternateDns) {
               spec.alternateDns = this.alternateDns;
            }

            if (originalData.searchDomains !== this.searchDomains) {
               spec.searchDomains = this.searchDomains;
            }
         }

         return this.hasChanges(spec) ? spec : null;
      }

      private hasChanges(spec: NetStackReconfigureSpec$DnsReconfigureSpec): boolean {
         let keys: string[] = Object.keys(spec);

         // true if the object has any keys other than _type
         return keys.length > 1;
      }
   }
}
