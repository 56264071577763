namespace spbm_ui {

   export class CapabilityProfilesCapacityPortletViewController {

      public static $inject = ["navigation"];

      public datastoreId: string;

      constructor(private navigation: any) {
         this.datastoreId = this.navigation.getRoute().objectId;
      }
   }

   angular.module("com.vmware.vsphere.client.spbm").controller(
         "CapabilityProfilesCapacityPortletViewController",
         CapabilityProfilesCapacityPortletViewController);
}
