namespace h5_vm {

   import GosBasePageModel = h5_vm.GosBasePageModel;

   export class GosWindowsLicensePageModel extends GosBasePageModel {
      productKey: string;
      isIncludeServerLicenseInfo: boolean;
      isServerLicenseModePerServer: boolean;
      maxConnections: number;
   }
}
