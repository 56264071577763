module cluster_ui {

   import IController = angular.IController;

   class SelectClusterVmOverrideMemberController implements IController {

      public static $inject = ["$scope", "i18nService"];
      public config: any;

      constructor(private $scope: any, private i18nService: any) {

         let clusterId: string = $scope.manager.getClusterId();
         let selectedVms: string[] = $scope.manager.getSelectedVms();
         let selectorConfig: any = {
            objectType: "VirtualMachine",
            listViewId: "vsphere.core.vm.list"
         };

         let filter: string = "clusterVmsExceptOverriddenVmsFilter";

         this.config = {
            contextObject: clusterId,
            multipleSelect: true,
            listTabConfig: {
               listConfig: [{
                  label: i18nService.getString("Common", "typeResource.vmPlural"),
                  listViewId: selectorConfig.listViewId,
                  dataModels: [selectorConfig.objectType],
                  filterId: filter
               }]
            },
            selectedObjects: _.map(selectedVms, (vm: any) => {
               return vm.id;
            }),
            onSelectionChanged: function (newSelectedItems: any) {
               $scope.manager.setSelectedVms(newSelectedItems);
            }
         };
      }

      $onInit() {
         setTimeout(function () {
            $("div.cluster-add-vm-override-member-container [vx-object-selector] div.filter-input input").focus();
         }, 200);
      }

   }

   angular.module("com.vmware.vsphere.client.cluster")
         .controller("SelectClusterVmOverrideMemberController",
               SelectClusterVmOverrideMemberController);
}

