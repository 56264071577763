(function () {
   'use strict';

   angular.module('com.vmware.vsphere.client.network').service(
         'virtualSwitchListService', virtualSwitchListService);

   virtualSwitchListService.$inject = [
         'vuiConstants',
         'columnRenderersRegistry',
         'i18nService',
         'networkUtil'];

   function virtualSwitchListService(
         vuiConstants,
         columnRenderersRegistry,
         i18nService,
         networkUtil) {

      return {
         createDatagridOptions: createDatagridOptions
      };

      function createDatagridOptions(
            virtualSwitchItems, showsIssuesColumn, onSelectionChange) {

         return {
            columnDefs: virtualSwitchColumnDefs(showsIssuesColumn),
            sortMode: vuiConstants.grid.sortMode.SINGLE,
            selectionMode: vuiConstants.grid.selectionMode.SINGLE,
            selectedItems: [],
            data: virtualSwitchItems,
            resizable: true,
            height: '100%',
            pageConfig: {
               hidePager: true
            },
            onChange: onSelectionChange
         };
      }

      function virtualSwitchColumnDefs(showsIssuesColumn) {
         return [{
            displayName: i18nService.getString(
                  'NetworkUi', 'HostSwitchList.column.switch'),
            field: 'name',
            width: '150px',
            template: objectNameRendererFactory('name',
                  {
                     distributedSwitch: 'vsphere-icon-dv-switch',
                     standardSwitch: 'network-lib-ui-icon-vswitch'
                  })
         }, {
            displayName: i18nService.getString(
                  'NetworkUi', 'HostSwitchList.column.discoveredIssues'),
            field: 'issueType',
            width: '150px',
            visible: showsIssuesColumn,
            template: issueTypeRendererFactory('issueType',
                  {
                     alert: 'vsphere-icon-status-error',
                     warning: 'vsphere-icon-status-warning',
                     none: null
                  }, {
                     alert: i18nService.getString(
                           'NetworkUi', 'HostSwitchList.column.status.alert'),
                     warning: i18nService.getString(
                           'NetworkUi', 'HostSwitchList.column.status.warning'),
                     none: i18nService.getString(
                           'NetworkUi', 'HostSwitchList.column.status.noIssues')
                  })
         }];
      }

      function objectNameRendererFactory(field, iconMapping) {
         return (function (data) {
            var iconName = iconMapping[data['type']] || null;
            var objectLinkRenderer =
                  columnRenderersRegistry.getColumnRenderer('object-link');
            return objectLinkRenderer([null, field, iconName], data);
         });
      }

      function issueTypeRendererFactory(field, iconMapping, labelMapping) {
         return (function (data) {
            var issueIcon = iconMapping[data[field]] || null;
            var issueLabel = labelMapping[data[field]] || null;
            var objectLinkRenderer =
                  columnRenderersRegistry.getColumnRenderer('object-link');
            return objectLinkRenderer([null, 'text', issueIcon], {'text': issueLabel});
         });
      }
   }
})();