namespace h5_vm {

import IPromise = angular.IPromise;
   import ManagedObjectReference = com.vmware.vim.binding.vmodl.ManagedObjectReference;
   import VmHardwareUtil = h5_vm.VmHardwareUtil;
   import VmConfigContext = com.vmware.vsphere.client.vm.config.VmConfigContext;
   import VirtualEthernetCard = com.vmware.vim.binding.vim.vm.device.VirtualEthernetCard;
   import VirtualDeviceOption = com.vmware.vim.binding.vim.vm.device.VirtualDeviceOption;
   import VirtualEthernetCardOption = com.vmware.vim.binding.vim.vm.device.VirtualEthernetCardOption;
   import VirtualDeviceOption$ConnectOption = com.vmware.vim.binding.vim.vm.device.VirtualDeviceOption$ConnectOption;

   export class VmNetworkService {
   public static $inject: string[] = [
         'dataService',
         'defaultUriSchemeUtil',
         'managedEntityConstants',
         'networkSelectorPrefetchingService',
         'networkSelectorDialogService',
         'vmHardwareUtil'];
   public static NETWORK_PREFETCH_COUNT:number = 10;

   private _filterSpec:any;

   public static RELATION_RESOURCE_POOL:string = 'rp';

   public static HOST:string = 'host';
   constructor(
         private dataService:any,
         private defaultUriSchemeUtil:any,
         private managedEntityConstants:any,
         private networkSelectorPrefetchingService:any,
         private networkSelectorDialogService:any,
         private vmHardwareUtil: h5_vm.VmHardwareUtil) {

   }

   public getVmNetworkInfo(networkProviderId: string, virtualNetworkDevices: any[]):IPromise<Array<any>> {
      var type = this.defaultUriSchemeUtil.getEntityType(networkProviderId);
      if (type === this.managedEntityConstants.RESOURCE_POOL ||
            type === this.managedEntityConstants.V_APP) {
         return this.dataService.getPropertiesByRelation(networkProviderId, VmNetworkService.RELATION_RESOURCE_POOL,
               this.managedEntityConstants.ANY_COMPUTE_RESOURCE, [VmNetworkService.HOST])
               .then((result: Object) => {
                  networkProviderId = this.getNetworkProviderForResourcePoolAndVirtualApp(result);
                  return this.fetchNetworksForProvider(networkProviderId, virtualNetworkDevices);
               }
            );
      } else {
         return this.fetchNetworksForProvider(networkProviderId, virtualNetworkDevices);
      }
   }

   public getSelectedNetwork(networkId: string, availableNetworks: Array<any>): any {
         const network = _.find(availableNetworks, function(nw) {
            return nw.id === networkId;
         });

         if (!network) {
            return availableNetworks[0];
         } else {
            return network;
         }
      }

   public getSelectedNetworkTypeForDevice(device: any,
                                           recommendedAdapterType: any,
                                           adapterTypes: Array<any>,
                                           hardwareDeviceOptions: Array<any>): any {
      var selectedNetworkType = recommendedAdapterType || adapterTypes[0];
      if (device) {
         _.each(hardwareDeviceOptions, function(hardwareSupportedType) {
            if (device._type === hardwareSupportedType.type.name) {
               selectedNetworkType = hardwareSupportedType;
            }
         });
      }
      return selectedNetworkType;
   }

   public isNetworkSelectDisabled(privileges: Array<string>, skipPrivilegeCheck: boolean = false) {
      if (skipPrivilegeCheck) {
         return false;
      }

      var hasPrivilege = this.vmHardwareUtil.checkPrivileges(privileges, [h5_vm.Privileges.VM_EDITDEVICE_PRIVILEGE]);
      return !hasPrivilege;
   }

   public isConnectedDisabled(privileges: Array<string>, skipPrivilegeCheck: boolean = false) {
      if (skipPrivilegeCheck) {
         return false;
      }

      var hasPrivilege = this.vmHardwareUtil.checkPrivileges(privileges, ['VirtualMachine.Interact.DeviceConnection']);
      return !hasPrivilege;
   }

   public isConnectedAtPowerOnDisabled(privileges: Array<string>, skipPrivilegeCheck: boolean = false) {
      if (skipPrivilegeCheck) {
         return false;
      }

      var hasPrivilege = this.vmHardwareUtil.checkPrivileges(privileges, ['VirtualMachine.Interact.DeviceConnection']);
      return !hasPrivilege;
   }

   public isMacAddressDisabled(powerState: string, isNew: boolean, privileges: Array<string>, skipPrivilegeCheck: boolean = false) {
      const validate = () => {
         return powerState !== 'poweredOff' && !isNew;
      };

      if (skipPrivilegeCheck) {
         return validate();
      }

      var hasPrivilege = this.vmHardwareUtil.checkPrivileges(privileges, ['VirtualMachine.Config.EditDevice']);
      return !hasPrivilege || validate();
   }

   public isNetworkAdapterDisabled(isNew: boolean, privileges: Array<string>, skipPrivilegeCheck: boolean = false) {
      const validate = () => {
         return !isNew;
      };

      if (skipPrivilegeCheck) {
         return validate();
      }

      var hasPrivilege = this.vmHardwareUtil.checkPrivileges(privileges, ['VirtualMachine.Config.EditDevice']);
      return !hasPrivilege || validate();
   }

   private getNetworkProviderForResourcePoolAndVirtualApp(computeResourceData: Object): string {
         let networkProvider: ManagedObjectReference;
         let networkProviderId: string;
         let computeResource: any = _.values(computeResourceData)[0];
         // If the compute resource data contains only one host, this means that the
         // validated resource pool or vApp are under either:
         //    1. A standalone host;
         //    2. A single-host DRS cluster;
         // No matter of the case, this single host will be used as a network context.
         if(computeResource.host && computeResource.host.length === 1) {
            networkProvider = computeResource.host[0];
            networkProviderId = this.defaultUriSchemeUtil.getVsphereObjectId(networkProvider);
         }
         // Otherwise, the validated resource pool or vApp are under multi-host DRS
         // cluster, which should be used as network context.
         else{
            networkProviderId = _.keys(computeResourceData)[0];
         }
      return networkProviderId;
   }

   private fetchNetworksForProvider(networkProviderId: string, virtualNetworkDevices: any[]):IPromise<Array<any>> {
      let vmNetworks = this.getUniqueVmNetworks(virtualNetworkDevices);
      return this.networkSelectorPrefetchingService.prefetchNetworks(
            networkProviderId, this.getVmNetworkSelectorFilterSpec(),
            vmNetworks, VmNetworkService.NETWORK_PREFETCH_COUNT
      ).then((networkInfo:any) => {
         return {
            availableNetworks: networkInfo.recentNetworks,
            networkProviderId:networkProviderId
         };
      });
   }

   public getVmNetworkSelectorFilterSpec():any {
      if (!this._filterSpec) {
         this._filterSpec = this.networkSelectorDialogService.createNetworkSelectorFilterSpec(
               true/*includeStandardNetworks*/,
               true/*includeOpaqueNetworks*/,
               true/*includeDistributedPortGroups*/,
               false/*includeUplinkPortGroups*/,
               true/*includeOpaqueNetworkTypes*/);
      }
      return this._filterSpec;
   }

   private getUniqueVmNetworks(virtualNetworkDevices:Array<any>):Array<{id:string, name:string}> {
      let vmNetworks:Array<any> = [];
      let networkIds:Array<string> = [];
      _.forEach(virtualNetworkDevices, (virtualNetworkDevice: any) => {
         let networkId:string = virtualNetworkDevice.getNetworkId();
         if (networkId && networkIds.indexOf(networkId) < 0) {
            vmNetworks.push(this.defaultUriSchemeUtil.getManagedObjectReference(networkId));
            networkIds.push(networkId);
         }
      });
      return vmNetworks;
   }

   public isUptCompatibilityVisible(option:any): boolean {

      if (!option) {
         return true;
      }

      let uptCompatibilityEnabledSupported = false;

      if (option.uptCompatibilityEnabled) {
         uptCompatibilityEnabledSupported = option.uptCompatibilityEnabled.supported;
      }

      let vmDirectPathGen2Supported:boolean = option.vmDirectPathGen2Supported;
      let retVal:boolean = (uptCompatibilityEnabledSupported && vmDirectPathGen2Supported);

      return retVal;
   }


   public getUptCompatibility(device:any, deviceOption:any) {

      if (!device) {
         return false;
      }

      let value:boolean = device.uptCompatibilityEnabled;
      if (angular.isDefined(value)) {
         return value;
      } else {
         if (deviceOption) {
            return deviceOption.uptCompatibilityEnabled.defaultValue;
         } else {
            return false;
         }
      }
   }

   public setUptCompatibility(device: any, value: boolean) {
      if (device) {
         device.uptCompatibilityEnabled = value;
      }
   }

   public isEditUptCompatibilityDisabled(vmConfigContext: any, isNewDevice:boolean):boolean {
      if (isNewDevice) {
         return false;
      }

      var hasPrivilege = h5_vm.VmHardwareUtil.userHasModifyDevicePermission(vmConfigContext);

      if(hasPrivilege === false) {
         return true;
      }
      return false;
   }

   public isNotConnectable(device: VirtualEthernetCard, vm: VmConfigContext): boolean {
      const option: VirtualEthernetCardOption = <VirtualEthernetCardOption> this.vmHardwareUtil.getDeviceOption(vm, device._type);
      if (!option) {
         return true;
      }
      const connectOption: VirtualDeviceOption$ConnectOption = option.connectOption;
      if (!connectOption) {
         return true;
      }
      return false;
   }

   }

angular
      .module('com.vmware.vsphere.client.vm')
      .service('vmNetworkService', VmNetworkService);

}
