module cluster_ui {

   import IController = angular.IController;

   class OverrideOptionsComboboxController implements IController {

      public value: any;
      public recommendedValuesExpanded: boolean;
      public ngModelController: ng.INgModelController;
      public recommendedValues: any[];
      public valueChangedCallback: Function;

      static $inject = ["$scope", "$element", "$document", "vxValueInUnitsService"];

      constructor(private $scope: any, private $element: any, private $document: any,
            private vxValueInUnitsService: any) {
         $scope.$watch("$ctrl.recommendedValues", () => {
            this.highlightValue();
         });
      }

      public $onInit(): void {
         this.ngModelController.$render = this.modelValueChanged.bind(this);
      }

      public $postLink(): void {
         this.installDocumentClickEvent();
      }

      public updateModelFromField(): void {
         this.ngModelController.$setViewValue(this.value);
      }

      public onBlurValueChanged(): void {
         if (this.valueChangedCallback &&
               (typeof this.valueChangedCallback === "function")) {
            this.valueChangedCallback({value: this.value});
         }
      }

      public setModelValue(value: string): void {
         this.ngModelController.$setViewValue(value);
         this.modelValueChanged();
         this.highlightValue();
      }

      public clickDropdownButton(): void {
         this.recommendedValuesExpanded = !this.recommendedValuesExpanded;
         this.vxValueInUnitsService.onRecommendedValuesExpandedChange(this.$element);
      }

      private modelValueChanged(): void {
         this.value = this.ngModelController.$viewValue;
      }

      private installDocumentClickEvent(): void {
         let dismissDropdown: Function = (e: any) => {
            // close recommended values dropdown when clicking outside the current element
            if ((this.$element !== e.target && !this.$element[0].contains(e.target))) {
               this.$scope.$apply(() => {
                  this.recommendedValuesExpanded = false;
               });
            }
         };

         this.$document.on("click", dismissDropdown);
         this.$scope.$on("$destroy", () => {
            this.$document.off("click", dismissDropdown);
         });
      }

      private highlightValue(): void {
         let itemHighlighted: boolean = false;
         _.each(this.recommendedValues, (recommendation: any) => {
            if (recommendation.value === this.ngModelController.$viewValue
                  && !itemHighlighted) {
               recommendation.isHighlighted = true;
               itemHighlighted = true;
            } else {
               recommendation.isHighlighted = false;
            }
         });
      }
   }

   class OverrideOptionsComboboxComponent implements ng.IComponentOptions {
      public controller: any;
      public templateUrl: string;
      public bindings: any;
      public require: any;

      constructor() {
         this.controller = OverrideOptionsComboboxController;
         this.templateUrl =
               "cluster-ui/resources/cluster/views/settings/configuration/overrides/" +
               "overrideOptionsCombobox.html";
         this.bindings = {
            valueChangedCallback: "&",
            isDisabled: "<",
            recommendedValues: "<"
         };
         this.require = {
            ngModelController: "ngModel"
         };
      }
   }

   angular.module("com.vmware.vsphere.client.cluster").component(
         "overrideOptionsCombobox", new OverrideOptionsComboboxComponent());
}
