namespace h5_client {
    module common_module_ui {

        import IComponentController = angular.IComponentController;

        export class VersionInformationController implements IComponentController {

            private readonly ABOUT_INFO_PROP: string = "aboutInfo";

            public static $inject = [
                "$scope",
                "dataService"
            ];

            /*
             * Version Number of VC
             */
            private version: string;

            /*
             * Build Number of VC
             */
            private build: string;

            public constructor(
                    private $scope: any,
                    private dataService: any) {

                let objectId: string = this.$scope._route.objectId ||
                        this.$scope._vxViewNodeData.node.contentSpec.metadata.objectId;

                this.dataService.getProperties(objectId, [ this.ABOUT_INFO_PROP ]).then((response: any) => {
                    this.version = response.aboutInfo.version;
                    this.build = response.aboutInfo.build;
                });
            }

        }

        angular.module("com.vmware.vsphere.client.commonModule")
                .controller("VersionInformationController", VersionInformationController);

    }
}
