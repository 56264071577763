namespace h5_vm {

import ValidationResult = com.vmware.vise.core.model.ValidationResult;
import IQService = angular.IQService;

// duplicated in ng-next-app
export interface SingleVmCompatibilityData {
   icon: string;
   message: string;
   contents: Array<CompatibilityNode>;
   advancedCompatibilityContents: AdvancedCompatibilityContents;
}

export interface AdvancedCompatibilityContents {
   hostMap: {
      [hostId: string]: {
         errors: Array<string>,
         warnings: Array<string>,
         infos: Array<string>,
         props: {
            icon: string,
            name: string
         }
      }
   };
   vmId: string;
   vmProps: {
      icon: string,
      name: string
   };
}

export interface CompatibilityNode {
   icon: string;
   message: string;
   contents: Array<CompatibilityNode>;
}

export class CompatibilityResultsPresenter {

   static $inject: string[] = [
      "i18nService",
      "dataService",
      "$q",
      "defaultUriSchemeUtil",
      "managedEntityConstants"
   ];

   public NO_HOST_REF: any;
   public NO_HOST_ID: string;
   public NO_HOST_NAME: string;
   public NO_HOST_ICON: string;
   public NO_VM_ID: string;
   public NO_VM_NAME: string;
   public NO_VM_ICON: string;

   constructor (
      private i18nService: any,
      private dataService: any,
      private $q: IQService,
      private defaultUriSchemeUtil: any,
      private managedEntityConstants: any) {

      this.NO_HOST_REF = {
            value: "withoutHost",
            type: "HostSystem",
            serverGuid: "1-2-3-4-5"
         };
      this.NO_HOST_ID = this.defaultUriSchemeUtil.getVsphereObjectId(this.NO_HOST_REF);
      this.NO_HOST_NAME = this.i18nService.getString("VmUi",
               "MigrationWizard.advancedCompatibilityDialog.noHostName");
      this.NO_HOST_ICON = "vsphere-icon-host";
      this.NO_VM_ID = "withoutVm";
      this.NO_VM_NAME = this.i18nService.getString("VmUi",
               "MigrationWizard.advancedCompatibilityDialog.noVmName");
      this.NO_VM_ICON = "vsphere-icon-vm";
   }

   /**
    * @param includeSuccessContent
    *    Whether or not to include content corresponding to success case (i.e no
    *    errors or warnings). The default value is 'false' and means that if no
    *    errors or warnings are there to be displayed - empty content array will
    *    be returned. If the value is 'true' - the result will contain one item
    *    that can be visualized and will signify the success of the validation
    *    operation.
    *
    * @returns An array structure of elements to be visualized.
    */
   public formatValidationResults(
         validationResultsArray: Array<ValidationResult>,
         includeSuccessContent: boolean = false): IPromise<Array<SingleVmCompatibilityData> > {
      let affectedObjects: any = this.getHostsWithErrorsAffectedObjects(validationResultsArray);

      let baseProps: {[id: string]: {[prop: string]: string}} = {};
      let noHostIdx = affectedObjects.hostIds.indexOf(this.NO_HOST_ID);
      if (noHostIdx > -1) {
         baseProps[this.NO_HOST_ID] = {
            name: this.NO_HOST_NAME,
            primaryIconId: this.NO_HOST_ICON
         };
         // removing the no host element before calling the dataservice
         affectedObjects.hostIds.splice(noHostIdx, 1);
      }

      let noVmIdx = affectedObjects.vmIds.indexOf(this.NO_VM_ID);
      if (noVmIdx > -1) {
         baseProps[this.NO_VM_ID] = {
            name: this.NO_VM_NAME,
            primaryIconId: this.NO_VM_ICON
         };
         affectedObjects.vmIds.splice(noVmIdx, 1);
      }

      if ((affectedObjects.hostIds.length === 0) &&
            (affectedObjects.vmIds.length === 0)) {
         return this.$q.when(
               this.formatValidationResultsInternal(
                     validationResultsArray, _.extend({}, baseProps),
                     includeSuccessContent));
      }

      let props: string[] = ["primaryIconId", "name"];
      return this.$q.all([
         this.dataService.getPropertiesForObjects(affectedObjects.hostIds, props),
         this.dataService.getPropertiesForObjects(affectedObjects.vmIds, props)
      ]).then((values: any[]) => {
         return this.formatValidationResultsInternal(
               validationResultsArray, _.extend({}, values[0], values[1], baseProps),
               includeSuccessContent);
      });
   }

   private getHostsWithErrorsAffectedObjects(validationResultsArray: Array<ValidationResult>): any {
      let hosts: {[hostId: string]: any} = {};
      let vms: {[vmId: string]: any} = {};

      _.each(validationResultsArray, (validationResult: any) => {
         if (validationResult.error) {
            if (validationResult.entity
               && validationResult.entity.type === this.managedEntityConstants.VIRTUAL_MACHINE) {
               vms[this.defaultUriSchemeUtil.getVsphereObjectId(validationResult.entity)] = null;
            }
            return;
         }
         _.each(validationResult.result, (singleResult: any) => {
            if (singleResult.error || singleResult.warning || singleResult.info) {
               if (singleResult.host) {
                  hosts[this.defaultUriSchemeUtil.getVsphereObjectId(
                        singleResult.host)] = null;
               }

               let vmRef = singleResult.vm;
               if (!vmRef && validationResult.entity
                     && validationResult.entity.type === this.managedEntityConstants.VIRTUAL_MACHINE) {
                  vmRef = validationResult.entity;
               }

               if (vmRef && this.defaultUriSchemeUtil.getVsphereObjectId(vmRef)) {
                  vms[this.defaultUriSchemeUtil.getVsphereObjectId(vmRef)] = null;
               } else {
                  vms[this.NO_VM_ID] = null;
               }
            }
         });
      });
      return {hostIds: Object.keys(hosts), vmIds:  Object.keys(vms)};
   }

   private formatValidationResultsInternal(
         validationResultsArray: Array<ValidationResult>,
         propertyValuesMap: {[moid: string]: any},
         includeSuccessContent: boolean): Array<SingleVmCompatibilityData> {
      let compatItems: Array<SingleVmCompatibilityData> = [];
      _.each(validationResultsArray, (validationResult: ValidationResult) => {
         let displayItem: SingleVmCompatibilityData | null = this.formatSingleValidationResult(
               validationResult, propertyValuesMap);
         if (displayItem !== null) {
            compatItems.push(displayItem);
         }
      });

      if (_.isEmpty(compatItems) && includeSuccessContent) {
         let successItem: SingleVmCompatibilityData = {
            icon: "vsphere-icon-status-ok",
            message: this.i18nService.getString(
                  "VmUi", "MigrationCompatibilityResultsView.Success"),
            contents: [],
            advancedCompatibilityContents: {
               hostMap: {},
               vmId: this.NO_VM_ID,
               vmProps: {
                  icon: this.NO_VM_ICON,
                  name: this.NO_VM_NAME
               }
            }
         } as SingleVmCompatibilityData;
         successItem.advancedCompatibilityContents.hostMap[this.NO_HOST_ID] = {
            errors: [],
            warnings: [],
            infos: [],
            props: {
               icon: this.NO_HOST_ICON,
               name: this.NO_HOST_NAME
            }
         };

         compatItems.push(successItem);
      }
      return compatItems;
   }

   private formatSingleValidationResult(validationResult: ValidationResult,
                                        propertyValuesMap: {[moid: string]: any}): SingleVmCompatibilityData|null {
      let compatibilityResults: any = validationResult.result;

      if (validationResult.error) {
         return this.handleInitErrors(validationResult, propertyValuesMap);
      }

      let resultsWithMessagesToShow: any = _.filter(compatibilityResults, (result: any) => {
         return (result.error && result.error.length > 0) ||
               (result.warning && result.warning.length > 0) ||
               (result.info && result.info.length > 0);
      });

      if (resultsWithMessagesToShow.length === 0) {
         return null;
      }

      let messageContent: Array<any> = this.buildBasicCompatibilityContents(
            propertyValuesMap, resultsWithMessagesToShow);

      let vmData = this.buildAdvancedCompatibilityContents(validationResult,
            propertyValuesMap, resultsWithMessagesToShow);

      const vmId = this.defaultUriSchemeUtil.getVsphereObjectId(validationResult.entity) ?
            this.defaultUriSchemeUtil.getVsphereObjectId(validationResult.entity) : this.NO_VM_ID;
      let objectProps: any = propertyValuesMap[vmId];
      return {
         icon: objectProps.primaryIconId,
         message: objectProps.name,
         contents: messageContent,
         advancedCompatibilityContents: vmData
      };
   }

   private handleInitErrors(validationResult: ValidationResult,
                            propertyValuesMap: {[moid: string]: any}): SingleVmCompatibilityData {
      let vmId = this.defaultUriSchemeUtil.getVsphereObjectId(validationResult.entity) ?
            this.defaultUriSchemeUtil.getVsphereObjectId(validationResult.entity) : this.NO_VM_ID;
      let vmName = propertyValuesMap[vmId] && propertyValuesMap[vmId].name ?
            propertyValuesMap[vmId].name : this.NO_VM_NAME;
      let vmIcon = propertyValuesMap[vmId] && propertyValuesMap[vmId].primaryIconId ?
            propertyValuesMap[vmId].primaryIconId : this.NO_VM_ICON;

      let response: SingleVmCompatibilityData = {
         icon: "vsphere-icon-status-error",
         message: this.extractErrorMessage(validationResult.error),
         contents: [],
         advancedCompatibilityContents: {
            hostMap: {},
            vmId: vmId,
            vmProps: {
               name: vmName,
               icon: vmIcon
            }
         }
      };

      response.advancedCompatibilityContents.hostMap[this.NO_HOST_ID] = {
         errors: [
            validationResult.error.message || validationResult.error.localizedMessage
         ],
         warnings: [],
         infos: [],
         props: {
            name: this.NO_HOST_NAME,
            icon: this.NO_HOST_ICON
         }
      };

      return response;
   }

   private buildBasicCompatibilityContents(propertyValuesMap: {[moid: string]: any},
                                         resultsWithMessagesToShow: any): Array<any> {
      let messageContent: Array<any> = [];
      _.each(resultsWithMessagesToShow, (compatibilityResult: any) => {
         let hostRef: any = compatibilityResult.host;

         let errorItems: Array<any> = _.map(compatibilityResult.error, (msg: any) => {
            return {
               icon: "vsphere-icon-status-error",
               message: msg.message || msg.localizedMessage,
               contents: []
            };
         });

         let warningItems: Array<any> = _.map(compatibilityResult.warning, (msg: any) => {
            return {
               icon: "vsphere-icon-status-warning",
               message: msg.message || msg.localizedMessage,
               contents: []
            };
         });

         let infoItems: Array<any> = _.map(compatibilityResult.info, (msg: any) => {
            return {
               icon: "vsphere-icon-help-info-hover",
               message: msg.message || msg.localizedMessage,
               contents: []
            };
         });

         if (hostRef &&
               this.defaultUriSchemeUtil.getVsphereObjectId(hostRef) !== this.NO_HOST_ID) {
            let hostProps: any = propertyValuesMap[
                  this.defaultUriSchemeUtil.getVsphereObjectId(hostRef)];

            messageContent.push({
               icon: hostProps.primaryIconId,
               message: hostProps.name,
               contents: errorItems.concat(warningItems).concat(infoItems)
            });
         } else {
            messageContent = messageContent.concat(errorItems).concat(warningItems)
                  .concat(infoItems);
         }
      });

      return messageContent;
   }

   private buildAdvancedCompatibilityContents(validationResult: ValidationResult,
                                          propertyValuesMap: {[moid: string]: any},
                                          resultsWithMessagesToShow: any): any {
      let vmMessageContentData: any = {};
      _.each(resultsWithMessagesToShow, (compatibilityResult: any) => {
         let hostRef: any = compatibilityResult.host;
         let errors: Array<string> = _.map(compatibilityResult.error, (msg: any) => {
            return msg.message || msg.localizedMessage;
         });
         let warnings: Array<string> = _.map(compatibilityResult.warning, (msg: any) => {
            return msg.message || msg.localizedMessage;
         });
         let infos: Array<string> = _.map(compatibilityResult.info, (msg: any) => {
            return msg.message || msg.localizedMessage;
         });

         let hostId = this.NO_HOST_ID;
         let props = {
            name: this.NO_HOST_NAME,
            icon: this.NO_HOST_ICON
         };
         if (hostRef) {
            hostId = this.defaultUriSchemeUtil.getVsphereObjectId(hostRef);
            props = {
               name: propertyValuesMap[hostId].name,
               icon: propertyValuesMap[hostId].primaryIconId
            };
         }
         if (!vmMessageContentData[hostId]) {
            vmMessageContentData[hostId] = {
               errors: [],
               warnings: [],
               infos: [],
               props: props
            };
         }
         vmMessageContentData[hostId].errors = vmMessageContentData[hostId].errors.concat(errors);
         vmMessageContentData[hostId].warnings = vmMessageContentData[hostId].warnings.concat(warnings);
         vmMessageContentData[hostId].infos = vmMessageContentData[hostId].infos.concat(infos);
      });

      let vmData: any = {};
      let vmId: string = this.defaultUriSchemeUtil.getVsphereObjectId(validationResult.entity);
      vmData.hostMap = vmMessageContentData;
      vmData.vmId = vmId;
      if (vmId) {
         vmData.vmProps = {
            name: propertyValuesMap[vmId].name,
            icon: propertyValuesMap[vmId].primaryIconId
         };
      } else {
         vmData.vmProps = {
            name: this.NO_VM_NAME,
            primaryIconId: this.NO_VM_ICON
         };
      }

      return vmData;
   }

   private extractErrorMessage(
         error: { message?: string, localizedMessage?: string, reason?: string }): string {
      let errorMessage = error.message || error.localizedMessage;
      if (!errorMessage) {
         errorMessage = error.reason ?
               this.i18nService.getString("VmUi", "compatibilityCheck.genericError.reason", error.reason) :
               this.i18nService.getString("VmUi", "compatibilityCheck.genericError");
      }
      return errorMessage || "";
   };

} // class CompatibilityResultsPresenter

angular.module("com.vmware.vsphere.client.vm").service(
   "compatibilityResultsPresenter", CompatibilityResultsPresenter);

} // namespace
