/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace network_lib_ui {

   export class TrafficShapingPolicyValidator {

      static $inject = [
            "i18nService",
            "networkUtil",
            "networkUiConstants"
      ];

      constructor(private i18nService: any,
                  private networkUtil: any,
                  private networkUiConstants: any) {
      }

      /**
       * Returns error message when the given value is invalid traffic shaping input or null.
       * @param value
       *         The value to be validated
       * @param setting {networkUiConstants.TrafficShapingSetting}
       *
       * @returns {null | string}
       */
      public getTrafficShapingErrorMessage(value: number, setting: string): string | null {

         this.validateAttributeName(setting);

         let maxValue: number = this.networkUiConstants.MAX_SAFE_INTEGER;
         let isValuePositiveInteger: boolean =
               this.networkUtil.isPositiveInteger(value) && value > 0;
         let isValueSafeInteger: boolean = value <= maxValue;
         // Define the correct message:
         let message = null;

         if (isValueSafeInteger === false) {
            switch (setting) {
               case this.networkUiConstants.TrafficShapingSetting.AVERAGE_BANDWIDTH:
                  message = this.i18nService.getString("H5NetworkUi",
                        "VSwitchTrafficShapingPage.averageBandwidth.errorMessage.exceeding",
                        maxValue);
                  break;

               case this.networkUiConstants.TrafficShapingSetting.PEAK_BANDWIDTH:
                  message = this.i18nService.getString("H5NetworkUi",
                        "VSwitchTrafficShapingPage.peakBandwidth.errorMessage.exceeding",
                        maxValue);
                  break;

               case this.networkUiConstants.TrafficShapingSetting.BURST_SIZE:
                  message = this.i18nService.getString("H5NetworkUi",
                        "VSwitchTrafficShapingPage.burstSize.errorMessage.exceeding",
                        maxValue);
                  break;
            }
         } else if (isValuePositiveInteger === false) {
            switch (setting) {
               case this.networkUiConstants.TrafficShapingSetting.AVERAGE_BANDWIDTH:
                  message = this.i18nService.getString("H5NetworkUi",
                        "VSwitchTrafficShapingPage.averageBandwidth.errorMessage");
                  break;

               case this.networkUiConstants.TrafficShapingSetting.PEAK_BANDWIDTH:
                  message = this.i18nService.getString("H5NetworkUi",
                        "VSwitchTrafficShapingPage.peakBandwidth.errorMessage");
                  break;

               case this.networkUiConstants.TrafficShapingSetting.BURST_SIZE:
                  message = this.i18nService.getString("H5NetworkUi",
                        "VSwitchTrafficShapingPage.burstSize.errorMessage");
                  break;
            }
         }

         return message;
      }

      private validateAttributeName(attributeName: string): void {
         if (attributeName !== this.networkUiConstants.TrafficShapingSetting.AVERAGE_BANDWIDTH &&
               attributeName !== this.networkUiConstants.TrafficShapingSetting.PEAK_BANDWIDTH &&
               attributeName !== this.networkUiConstants.TrafficShapingSetting.BURST_SIZE) {

            throw new Error("Attribute name must be networkUiConstants.TrafficShapingSetting");
         }
      }
   }

   angular.module("com.vmware.vsphere.client.networkLibUi")
         .service("trafficShapingPolicyValidator", TrafficShapingPolicyValidator);
}
