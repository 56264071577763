namespace storage_ui {

   import IComponentController = angular.IComponentController;

   export class DeviceBackingMasterViewController implements IComponentController {

      public static $inject = ["i18nService", "$q"];

      public readonly detailsViewData:any;

      private readonly tabs: any[] = [{
         name: this.i18nService.getString("StorageUi", "storage.devices.detailsView.header"),
         templateUrl: "storage-ui/resources/storage/views/manage/backing/DeviceBackingDetailsView.html",
         uid: "storage.deviceBacking.detailsTab"
      }];

      constructor(private i18nService: any, private $q: ng.IQService) {
         this.detailsViewData = {
            tabsPromise: this.$q.when(this.tabs)
         };
      }
   }

   angular.module("com.vmware.vsphere.client.storage")
         .controller("DeviceBackingMasterViewController",
               DeviceBackingMasterViewController);
}
