module ds_cluster_ui {

   export class SdrsFaultsService {

      public static $inject: string[] = ["i18nService", "drsFaultsViewConstants",
         "drsFaultsViewService", "iconService", "feedbackService", "resourceUtil"];

      constructor(private i18nService: any, private drsFaultsViewConstants: any,
                  private drsFaultsViewService: any, private iconService: any,
                  private feedbackService: any, private resourceUtil: any) {}

      public getFaultMasterData(fault: any): any {
         let target: any = {};

         if (fault.target) {
            if (fault.target.name !== null || fault.target.name !== "") {
               let interpolationItem: any = {};

               interpolationItem[this.drsFaultsViewConstants.ID_KEY] = fault.target.provider;
               interpolationItem[this.drsFaultsViewConstants.NAME_KEY] = fault.target.name;
               var interpolations = [interpolationItem];

               target[this.drsFaultsViewConstants.TEXT_KEY] =
                     this.drsFaultsViewService.interpolateWithLinks("{0}", interpolations);
               target[this.drsFaultsViewConstants.ICON_KEY] =
                     this.iconService.getByObjectRef(fault.target.provider);
            } else {
               target[this.drsFaultsViewConstants.TEXT_KEY] =
                     this.i18nService.getString(
                           "DsClusterUi", "sdrs.faults.missingObjectNameLabel");
            }
         }

         let gridItem: any = {};
         gridItem[this.drsFaultsViewConstants.LOCALIZED_REASON] =
               this.getDrsMigrationErrorLocalization(fault.reason);
         gridItem[this.drsFaultsViewConstants.REASON] = fault.reason;
         gridItem[this.drsFaultsViewConstants.TARGET_KEY] =
               target[this.drsFaultsViewConstants.TEXT_KEY] ? target : "";
         gridItem[this.drsFaultsViewConstants.DETAILS_KEY] = fault.details;
         gridItem[this.drsFaultsViewConstants.ICON_KEY] = "vsphere-icon-status-error";

         return gridItem;
      }

      public getMaintenaceModeFaultDetailsData(data: any): any {
         let gridItems: Array<{ [key: string]: any }> = [];

         for (let description of data.description) {
            let gridItem: { [key: string]: any } = {};
            gridItem[this.drsFaultsViewConstants.DESCRIPTION_KEY] = description;
            gridItem[this.drsFaultsViewConstants.HOST_NAME_KEY] =
                  data.hostName + this.resourceUtil.getTaggingLabelString(data.hostLabelIds);
            gridItem[this.drsFaultsViewConstants.HOST_ICON_KEY] = data.hostIcon;
            gridItem[this.drsFaultsViewConstants.VM_NAME_KEY] =
                  data.vmName + this.resourceUtil.getTaggingLabelString(data.vmLabelIds);
            gridItem[this.drsFaultsViewConstants.VM_ICON_KEY] = data.vmIcon;
            gridItem[this.drsFaultsViewConstants.DISK_NAME_KEY] = data.diskName;

            gridItems.push(gridItem);
         }

         return gridItems;
      }

      public getFaultDetailsData(details: any): any {
         let gridItem: any = null;

         if (details.preventedRecommendation.targetVmdk !== null) {
            gridItem = this.buildMigrateVmdkFaultItem(details);
         } else {
            gridItem = this.buildMigrateVmFaultItem(details);
         }

         return gridItem;
      }

      private getDrsMigrationErrorLocalization(reason: string): string {
         return this.i18nService.getString("Common", "drs.migration.error." + reason);
      }

      private buildMigrateVmdkFaultItem(details: any) {
         let preventedRecommendation: string = this.drsFaultsViewService.interpolateWithLinks(
               this.i18nService.getString("DsClusterUi", "sdrs.faults.migrateVmdk"),
               [this.getVmdkObject(details), this.getVmObject(details),
                  this.getVmdsObject(details)]);

         return this.buildGridItem(details, preventedRecommendation);
      }

      private buildMigrateVmFaultItem(details: any) {
         let preventedRecommendation: string = "";

         if (details.preventedRecommendation.targetVm !== null) {
            preventedRecommendation = this.drsFaultsViewService.interpolateWithLinks(
                  this.i18nService.getString("DsClusterUi", "sdrs.faults.migrateVm"),
                  [this.getVmObject(details)]);
         }

         return this.buildGridItem(details, preventedRecommendation);
      }

      private buildGridItem(details: any, preventedRecommendation: any): any {
         let gridItem: any = {};
         gridItem[this.drsFaultsViewConstants.REASON] = this.buildReasonLink(
               "vsphere-icon-status-error", details.faultDetailMessage, details.helpId);
         gridItem[this.drsFaultsViewConstants.PREVENTED_RECOMMENDATION] =
               preventedRecommendation;
         gridItem[this.drsFaultsViewConstants.PREVENTED_RECOMMENDATION_ICON_CLASS] =
               details.preventedRecommendationIcon;

         return gridItem;
      }

      private buildReasonLink(icon: string, reason: string, helpId: string): string {
         return '<a ng-click="$parent.$parent.openHelpLink(\'' + helpId + '\')">' +
               '<span class="'+icon+'"></span>' + reason + '</a>';
      }

      private getVmdkObject(details: any): string {
         let targetVmdkName: string = details.preventedRecommendation.targetVmdk;
         if (targetVmdkName === null) {
            return this.i18nService.getString(
                  "DsClusterUi", "sdrs.faults.missingObjectNameLabel");
         } else {
            return targetVmdkName;
         }
      }

      private getVmdsObject(details: any): any {
         let targetVmds: any = details.preventedRecommendation.targetVmds;
         let targetVmObject: any = {};

         if (targetVmds.name === null) {
            return this.i18nService.getString(
                  "DsClusterUi", "sdrs.faults.missingObjectNameLabel");
         } else {
            targetVmObject[this.drsFaultsViewConstants.ID_KEY] = targetVmds.provider;
            targetVmObject[this.drsFaultsViewConstants.NAME_KEY] = targetVmds.name;

            return targetVmObject;
         }
      }

      private getVmObject(details: any): any {
         let targetVm: any = details.preventedRecommendation.targetVm;
         let targetVmObject: any = {};

         if (targetVm.name === null) {
            return this.i18nService.getString(
                  "DsClusterUi", "sdrs.faults.missingObjectNameLabel");
         } else {
            targetVmObject[this.drsFaultsViewConstants.ID_KEY] = targetVm.provider;
            targetVmObject[this.drsFaultsViewConstants.NAME_KEY] = targetVm.name;

            return targetVmObject;
         }
      }
   }

   angular.module("com.vmware.vsphere.client.dsCluster")
         .service("sdrsFaultsService", SdrsFaultsService);
}