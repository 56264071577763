namespace h5_vm {

import LocationSpecPair = com.vmware.vsphere.client.vm.migration.LocationSpecPair;
import Recommendation = com.vmware.vim.binding.vim.cluster.Recommendation;
import LocationSpec = com.vmware.vsphere.client.vm.migration.LocationSpec;
import VmMigrationCancelRecommendationsSpec = com.vmware.vsphere.client.h5.vm.model.migration.VmMigrationCancelRecommendationsSpec;
import ManagedObjectReference = com.vmware.vim.binding.vmodl.ManagedObjectReference;

let defaultUriSchemeUtil: any;
let mutationService: any;
let datastoreRecommendationService: any;
let $q: any;

export class VmStorageRecommendationsService {

    private _vmIds: string[];
    private _recommendationsByPod: {[storagePodId: string]: any[]};
    private _faultsByVm: {[vmId: string]: any[]};
    private _selectedRecommendations: {[storagePodId: string]: {[vmId: string]: any}};
    // Holds all recommendation keys retrieved that were last retrieved and might later
    // be canceled.
    private _recommendationsToCancel: string[];
    private _hasInitErrors: boolean;

    constructor() {
        this._vmIds = [];
        this._recommendationsByPod = {};
        this._faultsByVm = {};
        this._selectedRecommendations = {};
        this._recommendationsToCancel = [];
        this._hasInitErrors = false;
    }

   /**
    * @param locationSpecPairs
    *    Pass empty array in order to reset the internally kept recommendations info.
    */
    public load(locationSpecPairs: LocationSpecPair[]): IPromise<void> {
        if (_.isEmpty(locationSpecPairs)) {
            return $q.when([]).then(() => {
              this.parseResponse([]);
              this.autoselectRecommendation();
           });
        }

        // Cancel any previously generated recommendations
        this.attachCancelRecommendations(locationSpecPairs);

        let specTypeUrl: string = new LocationSpecPair()._type;
        this._vmIds = _.map(locationSpecPairs,
              (spec: LocationSpecPair) => defaultUriSchemeUtil.getVsphereObjectId(spec.entity));
        return mutationService.validateMultiSpec(
            specTypeUrl,
            locationSpecPairs
        ).then((res: any) => {
            this.parseResponse(res);
            this.autoselectRecommendation();
        });
    }

    public hasRecommendations(): boolean {
        return Object.keys(this._recommendationsByPod).length > 0;
    }

    public hasFaults(): boolean {
        return Object.keys(this._faultsByVm).length > 0;
    }

    public hasFaultsForVm(vmId: string): boolean {
        return !_.isEmpty(this._faultsByVm[vmId]);
    }

    public getFaultsForVm(vmId: string): Array<any> {
        return this._faultsByVm[vmId];
    }

    public hasDiskRecommendations(vmId: string, vmDisk: any): boolean {
        let disk: any = vmDisk || {};
        let podUrn: string = disk.parentStoragePodUrn ?
              disk.parentStoragePodUrn : disk.storageUrn;

        if (!this._recommendationsByPod[podUrn]) {
            return false;
        }

        let found = _.find(this._recommendationsByPod[podUrn],
              (recommendation: any) => this.isRecommendationForVm(vmId, recommendation));

        return !!found;
    }

    public getDiskRecommendations(vmId: string, vmDisk: any): any {
        let disk: any = vmDisk || {};
        let podUrn: string = disk.parentStoragePodUrn ?
              disk.parentStoragePodUrn : disk.storageUrn;
        if (!this._recommendationsByPod[podUrn]) {
            return null;
        }

        return _.filter(this._recommendationsByPod[podUrn],
              (recommendation: any) => this.isRecommendationForVm(vmId, recommendation));
    }

    public getAllDiskRecommendationsAsArray(): any {
        let result: any = [];
        _.each(this._recommendationsByPod, (recommendations: any) => {
            result = result.concat(recommendations);
        });

        return result;
    }

    public getSelectedRecommendationsByVm(): any {
        let recommendationsByVm: {[vmId: string]: any[]} = {};
        _.each(this._selectedRecommendations, (perPodRecommendations: any) => {
            _.each(this._vmIds, (vmId: string) => {
                if (!recommendationsByVm[vmId]) {
                    recommendationsByVm[vmId] = [];
                }
                let vmRecommendation = perPodRecommendations[vmId];
                if (vmRecommendation) {
                    recommendationsByVm[vmId].push(vmRecommendation);
                }
            });
        });

        return recommendationsByVm;
    }

    public setSelectedRecommendation(vmId: string, disk: any,
                                     recommendation: any): void {
        let storagePodId = disk.parentStoragePodUrn ?
              disk.parentStoragePodUrn : disk.storageUrn;
        this._selectedRecommendations[storagePodId][vmId] = recommendation.spec;
    }

    public getSelectedRecommendation(vmId: string, disk: any): any {
        let storagePodId = disk.parentStoragePodUrn ?
              disk.parentStoragePodUrn : disk.storageUrn;
        if (this._selectedRecommendations[storagePodId]) {
            return this._selectedRecommendations[storagePodId][vmId];
        } else {
            return undefined;
        }
    }

    public cancelRecommendations(excludeSelected: boolean) {
       let cancelRecommendations: string[] = this.getRecommendationToCancel(excludeSelected);

       if (!_.isEmpty(cancelRecommendations)) {
          let cancelSpec: VmMigrationCancelRecommendationsSpec
               = new VmMigrationCancelRecommendationsSpec();
          cancelSpec.keys = cancelRecommendations;
          let rootFolder: string = defaultUriSchemeUtil
                .getRootFolderFromVsphereObjectId(this._vmIds[0]);
          mutationService.apply(rootFolder, cancelSpec._type, cancelSpec);
          this._recommendationsToCancel = [];
       }
    }

    private attachCancelRecommendations(
          locationsSpecPairs: LocationSpecPair[],
          excludeSelected: boolean = false): void {
       let recommendationKeys: string[] = this.getRecommendationToCancel(excludeSelected);
       if (!_.isEmpty(locationsSpecPairs) && !_.isEmpty(recommendationKeys)) {
          // Put all recommendations that need to be canceled into the first pair.
          let pair: LocationSpecPair = locationsSpecPairs[0];
          pair.spec.cancelRecommendations = recommendationKeys;
       }
    };

    private getRecommendationToCancel(excludeSelected: boolean = false): string[] {
       let keys: string[] = this._recommendationsToCancel;
       if (excludeSelected) {
          let selectedKeys: string[] = [];
          _.each(this._selectedRecommendations,
                (selectedByVm: {[vmId: string]: Recommendation}) => {
             _.each(selectedByVm, (selected: Recommendation) => {
                selectedKeys.push(selected.key);
             });
          });
          keys = _.difference(keys, selectedKeys);
       }
       return keys;
    }

    private parseResponse(res: any): void {
        this._recommendationsByPod = {};
        this._faultsByVm = {};
        this._hasInitErrors = false;

        _.each(res, (response: any) => {

            if (!response) {
                return;
            }

            let vmId: string = defaultUriSchemeUtil.getVsphereObjectId(response.entity);
            _.each(response.result, (outerResult: any) => {
                if (outerResult.result && outerResult.result.recommendations) {
                    let recs = outerResult.result.recommendations;
                    this._recommendationsToCancel = [];
                    _.each(recs, (item: Recommendation) => {
                        this._recommendationsToCancel.push(item.key);
                        let storagePodId = defaultUriSchemeUtil
                            .getVsphereObjectId(item.target);
                        if (!this._recommendationsByPod[storagePodId]) {
                            this._recommendationsByPod[storagePodId] = [];
                        }

                        this._recommendationsByPod[storagePodId].push(item);
                    });
                }

                if (outerResult.result && outerResult.result.drsFault) {
                    _.each(outerResult.result.drsFault.faultsByVm, (fault: any) => {
                        let vmId = defaultUriSchemeUtil.getVsphereObjectId(fault.vm);
                        if (!this._faultsByVm[vmId]) {
                            this._faultsByVm[vmId] = [];
                        }
                        this._faultsByVm[vmId] = this._faultsByVm[vmId].concat(fault.fault);
                    });
                }

                if (outerResult.error) {
                    if (!this._faultsByVm[vmId]) {
                        this._faultsByVm[vmId] = [];
                    }
                    this._faultsByVm[vmId] = this._faultsByVm[vmId].concat(response.result[0].error);
                }
            });

            if (response.error) {
               this._hasInitErrors = true;
            }

        });
    }

    public getTopRecommendations(): {[storagePodId: string]: {[vmId: string]: any}} {
        let topRecommendations: {[storagePodId: string]: {[vmId: string]: any}} = {};

        _.each(this._recommendationsByPod,  (recommendations: any, storagePodId: string) => {
           topRecommendations[storagePodId] = {};
            _.each(this._vmIds, (vmId: string) => {
                let topRecommendation: any =
                      this.findTopRecommendationForVm(vmId, recommendations);
                if (topRecommendation) {
                    topRecommendations[storagePodId][vmId] = topRecommendation;
                }
            });
        });

        return topRecommendations;
    }

   public hasInitErrors(): boolean {
      return this._hasInitErrors;
   }

    private  autoselectRecommendation(): void {
        this._selectedRecommendations = this.getTopRecommendations();
    }

    private findTopRecommendationForVm(vmId: string, allRecommendations: any) {
        let vmRecommendations: Array<any> = _.filter(allRecommendations,
              (recommendation: any) => this.isRecommendationForVm(vmId, recommendation));
        return datastoreRecommendationService
              .findTopRecommendation(vmRecommendations);
    }

    private isRecommendationForVm(vmId: string, recommendation: any): boolean {
        if (!recommendation || _.isEmpty(recommendation.action)) {
            return false;
        }
        let found: any = _.find(recommendation.action,
              (action: any) => vmId === defaultUriSchemeUtil.getVsphereObjectId(action.vm));
        return !!found;
    }
} // class

angular.module("com.vmware.vsphere.client.vm").service("VmStorageRecommendationsService", [
    "defaultUriSchemeUtil",
    "mutationService",
    "datastoreRecommendationService",
    "$q",
    function (
        _defaultUriSchemeUtil: any,
        _mutationService: any,
        _datastoreRecommendationService: any,
        _q: any
    ) {

        defaultUriSchemeUtil = _defaultUriSchemeUtil;
        mutationService = _mutationService;
        datastoreRecommendationService = _datastoreRecommendationService;
        $q = _q;

        return VmStorageRecommendationsService;
    }
]);

} // module
