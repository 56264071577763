namespace h5_vm {
   import IPromise = angular.IPromise;
   import VmProvisioningPageModel = h5_vm.VmProvisioningPageModel;

   class CloneVmLibraryItemPageModelFactory {
      public static $inject = ["i18nService"];

      constructor(i18nService: any) {
         return (wizardScope: any, contentLibraryItem: any) => {
            return new CloneVmLibraryItemPageModel(
                  wizardScope,
                  contentLibraryItem,
                  i18nService);
         };
      }
   }

   export class CloneVmLibraryItemPageModel implements VmProvisioningPageModel<any> {

      static $inject = [];

      constructor(
         public wizardScope: any,
         public contentLibraryItem: any,
         public i18nService: any) { }

      public resetForm(): void { }

      public validatePage(): any {
         if (!this.wizardScope.cloneVmLibraryItemPageModel.contentLibraryItem) {
            return {
               error: this.i18nService.getString(
                     "ProvisioningUiLib", "LibraryItemPage.error.noLibraryItemSelected")
            };
         }
         return { };
      }

      public submitPage(validationResult: any): any {
         return { };
      }
   }

   angular.module("com.vmware.vsphere.client.vm")
         .service("cloneVmLibraryItemPageModel",
               CloneVmLibraryItemPageModelFactory);
}
