/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace dvs_ui {

   import IRootScopeService = angular.IRootScopeService;
   import UpgradeNiocDvsData = com.vmware.vsphere.client.h5.network.dvs.upgrade.model.UpgradeNiocDvsData;

   export class DvsUpgradeNiocWizardService {

      public static $inject = [
            "$rootScope",
            "i18nService",
            "vuiWizardService",
            "mutationService",
            "dataService",
            "dvsUpgradeNiocWizardSpecFactory",
            "dvsUpgradeNiocWizardConstants",
            "dvsUpgradeNiocWizardManager",
            "dvsUpgradeNiocWizardPages",
            "wizardValidationService"
      ];

      constructor(private $rootScope: IRootScopeService,
                  private i18nService: any,
                  private vuiWizardService: any,
                  private mutationService: any,
                  private dataService: any,
                  private specFactory: DvsUpgradeNiocWizardSpecFactory,
                  private wizardConstants: any,
                  private wizardManager: DvsUpgradeNiocWizardManager,
                  private wizardPages: DvsUpgradeNiocWizardPages,
                  private wizardValidationService: any) {
      }

      /**
       * Shows the Upgrade Network I/O Control wizard.
       */
      public show(objectId: string): void {

         let wizardScope: any = this.$rootScope.$new();

         wizardScope.model = new DvsUpgradeNiocWizardModel();
         wizardScope.model.dvsId = objectId;

         wizardScope.wizardConfig = {
            title: this.i18nService.getString(
                    "DvsUi", "DvsUpgradeNiocWizard.initialTitle"),
            loadingMessage: this.i18nService.getString("CommonUi", "wizard.loading"),
            cssClass: "dvs-upgrade-nioc-wizard",
            onFinish: ():boolean => {
               let spec: any =
                     this.specFactory.buildDvsNiocUpgradeSpec(wizardScope.model);

               this.mutationService.apply(
                     wizardScope.model.dvsId,
                     spec._type,
                     spec);
               return true;
            }
         };

         wizardScope.wizardConfig.pages = this.wizardPages.createPages(wizardScope);

         this.wizardValidationService.initWizardValidation(wizardScope);
         this.wizardManager.init(wizardScope);

         this.requestProperties(wizardScope);
      }

      private requestProperties(wizardScope: any): void {
         wizardScope.wizardConfig.loading = true;

         this.dataService.getProperties(
               wizardScope.model.dvsId, [this.wizardConstants.properties
                    .DVS_UPGRADE_NIOC_WIZARD_DATA])
               .then((response: any): void => {
                  let wizardData: UpgradeNiocDvsData =
                      response[this.wizardConstants.properties.
                            DVS_UPGRADE_NIOC_WIZARD_DATA];
                  wizardScope.model.dvsName = wizardData.dvsName;
                  wizardScope.model.prerequisiteChecks =
                        wizardData.prerequisiteCheckResults;
                  wizardScope.model.niocUpgradeStatistics =
                        wizardData.niocUpgradeStatistics;
                  wizardScope.wizardConfig.title = this.i18nService.getString(
                        "DvsUi", "DvsUpgradeNiocWizard.titleFormat",
                        wizardScope.model.dvsName);

                  if (!wizardScope.model.niocUpgradeStatistics ||
                        wizardScope.model.niocUpgradeStatistics
                              .userDefinedNrpCount === 0) {
                     this.wizardPages.skipPage(wizardScope.wizardConfig.pages,
                           this.wizardConstants.pages.DVS_NIOC_MIGRATE_SHARES_PAGE);
                  }

                  wizardScope.wizardConfig.loading = false;

                  this.vuiWizardService({
                     scope: wizardScope,
                     configObjectName: "wizardConfig"
                  }).show();
               });
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .service("dvsUpgradeNiocWizardService", DvsUpgradeNiocWizardService);
}
