namespace h5_vapp {
   import IPromise = angular.IPromise;
   import IQService = angular.IQService;

   import ManagedEntityMoveSpec = com.vmware.vsphere.client.mixed.ManagedEntityMoveSpec;
   import ManagedObjectReference = com.vmware.vim.binding.vmodl.ManagedObjectReference;
   import ValidateMoveVappSpec = com.vmware.vsphere.client.h5.vapp.data.ValidateMoveVappSpec;

   export class MoveVAppService {
      static $inject: string[] = [
         "mutationService",
         "defaultUriSchemeUtil",
         "i18nService",
         "$q",
         "nameAndFolderValidatorService",
         "managedEntityConstants",
         "clarityModalService",
         "dataService"
      ];

      private readonly PROPERTY_OWNER: string = "owner";
      private readonly PROPERTY_DATACENTER: string = "dc";
      private readonly PROPERTY_HOST: string = "host";
      private readonly PROPERTY_RESOURCE_POOL: string = "resourcePool";

      private _mutationService: any;
      private _defaultUriSchemeUtil: any;
      private _i18nService: any;
      private _q: IQService;
      private _nameAndFolderValidatorService: any;
      private _managedEntityConstants: any;
      private _clarityModalService: any;
      private _dataService: any;

      constructor(mutationService: any,
            defaultUriSchemeUtil: any,
            i18nService: any,
            $q: IQService,
            nameAndFolderValidatorService: any,
            managedEntityConstants: any,
            clarityModalService: any,
            dataService: any
      ) {
         this._mutationService = mutationService;
         this._defaultUriSchemeUtil = defaultUriSchemeUtil;
         this._i18nService = i18nService;
         this._q = $q;
         this._nameAndFolderValidatorService = nameAndFolderValidatorService;
         this._managedEntityConstants = managedEntityConstants;
         this._clarityModalService = clarityModalService;
         this._dataService = dataService;
      }

      /**
       * If a drop target is supplied invokes the move action for the provided virtual
       * apps to the target. If the drop target is not supplied
       * then opens a wizard from which the drop target can be selected
       * @param vAppIds
       * @param target
       */
      public moveVApps(vAppIds: string[], target?: DestinationTarget): void {
         if (target && target.dropTarget) {
            this.applyMove(vAppIds, target.dropTarget);
            return;
         }

         this.requestProperties(vAppIds).then((response: {[prop: string]: any}) => {
            this.showDialog(vAppIds, response);
         });
      }

      /**
       * Builds a ManagedEntityMoveSpec that is used for the apply of the move action
       * @param virtualApps
       * @returns {ManagedEntityMoveSpec}
       */
      private buildMoveSpec(virtualApps: string[]): ManagedEntityMoveSpec {
         let entities: ManagedObjectReference[] =
               this._defaultUriSchemeUtil.getManagedObjectReferences(virtualApps);
         let moveSpec: ManagedEntityMoveSpec = new ManagedEntityMoveSpec();
         moveSpec.entities = entities;

         return moveSpec;
      }

      /**
       * Invokes the mutation service's apply operation to initiate the move entities
       * operation for the supplied virtual apps to the target. If the selected target is
       * HostSystem or ClusterComputeResource it's resource pool will be requested as the
       * move operation is executed over it.
       * @param virtualAppIds
       * @param targetId
       */
      public applyMove(virtualAppIds: string[], targetId: string): void {
         const moveSpec: ManagedEntityMoveSpec = this.buildMoveSpec(virtualAppIds);
         const entityType: string = this._defaultUriSchemeUtil.getEntityType(targetId);
         if (entityType === this._managedEntityConstants.HOST ||
               entityType === this._managedEntityConstants.CLUSTER) {
            this._dataService.getPropertiesForObjects(
                  [targetId], [this.PROPERTY_RESOURCE_POOL]).
            then((properties: { [prop: string]: any }) => {
               targetId = this._defaultUriSchemeUtil.getVsphereObjectId(
                     properties[targetId][this.PROPERTY_RESOURCE_POOL]);
               this._mutationService.apply(targetId, moveSpec._type, moveSpec);
            });
         } else if (entityType === this._managedEntityConstants.DATACENTER) {
            this._dataService.getProperties(targetId, ["vmFolder"]).
            then((properties: {[prop: string]: any}) => {
               targetId = this._defaultUriSchemeUtil.getVsphereObjectId(
                     properties["vmFolder"]);
               this._mutationService.apply(targetId, moveSpec._type, moveSpec);
            });
         } else {
            this._mutationService.apply(targetId, moveSpec._type, moveSpec);
         }
      }

      /**
       * Validates if the given virtual apps can be moved to the supplied target.
       * 1. Checks if the targets is of allowed type (Datacenter, VM Folder)
       * 2. Invokes the backend for additional validation
       * @param virtualAppIds
       * @param targetId
       * @returns {any}
       */
      public validateMove(virtualAppIds: string[], targetId: string): IPromise<string> {
         const targetType: string = this._defaultUriSchemeUtil.getEntityType(targetId);
         switch (targetType) {
            case this._managedEntityConstants.FOLDER:
               if (this._nameAndFolderValidatorService.isVmFolder(targetId)) {
                  return this.retrieveValidationMessage(virtualAppIds, targetId);
               }
               break;
            case this._managedEntityConstants.DATACENTER:
            case this._managedEntityConstants.CLUSTER:
            case this._managedEntityConstants.HOST:
            case this._managedEntityConstants.RESOURCE_POOL:
            case this._managedEntityConstants.V_APP:
               return this.retrieveValidationMessage(virtualAppIds, targetId);
            default:
               break;
         }

         // if the target is not VM Folder, return error
         return this._q.when(
               this._i18nService.getString("Common", "moveTo.incorrectTargetTypeError"));
      }

      /**
       * Ask the backend for validation if the given virtual machines
       * can be moved to the supplied target.
       *
       * @param virtualAppIds
       * @param targetId
       * @returns {any}
       */
      private retrieveValidationMessage(virtualAppIds: string[], targetId: string): IPromise<string> {
         let entities: ManagedObjectReference[] =
               this._defaultUriSchemeUtil.getManagedObjectReferences(virtualAppIds);
         let moveSpec: ValidateMoveVappSpec = new ValidateMoveVappSpec();
         moveSpec.entities = entities;

         if (virtualAppIds.indexOf(targetId) !== -1) {
             return this._q.when(
                 this._i18nService.getString("Common", "moveTo.targetValidationError"));
         }
         return this._mutationService
               .validate(targetId, moveSpec._type, moveSpec)
               .then((result: any) => {
                  if (result.error) {
                     return result.error;
                  }
                  return result.result;
               });
      }

      /**
       * Opens a clarity modal from which a drop target can be selected for the move
       * operation.
       * @param virtualAppIds
       * @param properties
       */
      private showDialog(virtualAppIds: string[], dialogData: any): void {
         let subTitleObject: any = this.buildSubtitle(virtualAppIds);
         let modalOptions: any = {
            title: this._i18nService.getString("VAppUi", "moveVappDialog.Title"),
            subTitle: subTitleObject,
            defaultButton: "submit",
            dialogData: {
               objectIds: virtualAppIds,
               datacenter: this._defaultUriSchemeUtil.getVsphereObjectId(dialogData.datacenter),
               rpOwner: this._defaultUriSchemeUtil.getVsphereObjectId(dialogData.owner)
            },
            contentTemplate: "vapp-ui/resources/vapp/views/moveVapp/moveVappDialog.html"
         };
         this._clarityModalService.openOkCancelModal(modalOptions);
      }

      /**
       * Builds the subtitle object that is sent to the clarity modal service
       * If the virtual app is only one, sets the objectId property of the
       * subtitle object, and the clarityModalService will retrieve and display its name
       * If the virtual apps are more than one, builds a text, that is set as a property
       * to the subtitle object and will directly be displayed in the dialog
       * @param virtualAppIds
       * @returns {any}
       */
      private buildSubtitle(virtualAppIds: string[]): any {
         let subTitleObject: any = {};
         if (virtualAppIds.length === 1) {
            subTitleObject.objectId = virtualAppIds[0];
            return subTitleObject;
         }

         subTitleObject.text = this._i18nService.getString(
               "Common", "contextMenu.title.nObjects", virtualAppIds.length);
         return subTitleObject;
      }

      /**
       *  Requests the host, owner and datacenter of the vApp so they can be used as tree
       *  roots in the dialog.
       *  In move folder: The datacenter is used as tree.
       *  In move host and cluster: The root compute resource is the root of the tree.
       *     We can have vApp in Standalone host or DRS Cluster. The compute resource of
       *     the cluster is the cluster itself but for a standalone host we have special
       *     compute resource so we have to use the host of the vApp as tree root.
       *
       * @param vappIds
       * @returns {IPromise<T>}
       */
      private requestProperties(vappIds: string[]): IPromise<{ [prop: string]: any }> {
         let deferred = this._q.defer();
         let response: any = {};
         this._dataService.getPropertiesForObjects(
               vappIds,
               [this.PROPERTY_OWNER, this.PROPERTY_DATACENTER, this.PROPERTY_HOST])
               .then((properties: { [prop: string]: any }) => {
                  response.datacenter = properties[vappIds[0]][this.PROPERTY_DATACENTER];

                  let owner: string = this._defaultUriSchemeUtil.getVsphereObjectId(
                        properties[vappIds[0]][this.PROPERTY_OWNER]);
                  let isSingleOwner = _.every(properties, (prop: { [prop: string]: any }) => {
                     return owner === this._defaultUriSchemeUtil.getVsphereObjectId(prop[this.PROPERTY_OWNER]);
                  });

                  if (isSingleOwner) {
                     let ownerType: string = properties[vappIds[0]][this.PROPERTY_OWNER].type;
                     if (ownerType === this._managedEntityConstants.COMPUTE_RESOURCE) {
                        response.owner = properties[vappIds[0]][this.PROPERTY_HOST];
                     } else {
                        response.owner = properties[vappIds[0]][this.PROPERTY_OWNER];
                     }
                  } else {
                     response.owner = null;
                  }

                  deferred.resolve(response);
               });
         return deferred.promise;
      }

   }

   angular.module("com.vmware.vsphere.client.vapp")
         .service("moveVAppService", MoveVAppService);
}
