(function () {
   'use strict';

   angular.module('com.vmware.vsphere.client.vm').service('deviceOrganizer', deviceOrganizer);

   deviceOrganizer.$inject = ['vmDeviceInfoService'];

   var DEFAULT_DEVICE = 'VirtualDevice';
   var ORDERED_DEVICES_TO_GROUP_BY = [
      'VirtualDisk',
      'VirtualEthernetCard',
      'VirtualCdrom',
      'VirtualFloppy',
      'VirtualVideoCard',
      'VirtualNVDIMM',
      'VirtualSCSIController',
      'VirtualController',
      'InputDevice',
      DEFAULT_DEVICE
   ];

   function deviceOrganizer(vmDeviceInfoService) {
      return {
         groupAndSortDevices: function(rawDevices) {
            var sortedDevices = rawDevices.sort(function(device1, device2) {
               return computeSortId(device1) - computeSortId(device2);
            });
            return mapDevicesByType(sortedDevices, ORDERED_DEVICES_TO_GROUP_BY);
         }
      };

      function mapDevicesByType(rawDevices, superTypesToGroupBy) {
         return _.groupBy(rawDevices, function(rawDevice){
            var matchedSuperType = _.find(superTypesToGroupBy, function(superType){
               return vmDeviceInfoService.isDeviceSubclassOf(rawDevice, superType);
            });

            return matchedSuperType ? matchedSuperType : DEFAULT_DEVICE;
         });
      }

      function computeDeviceNumber(rawDevice) {
         var extractNumberRegex = /[0-9]+$/;
         var numberPostfix = extractNumberRegex.exec(rawDevice.deviceInfo.label);
         return numberPostfix ? parseInt(numberPostfix, 10) : 0;
      }

      //TODO: Fix to support correct sorting of new devices.
      function computeSortId (rawDevice) {
         // magic numbers only used to give relative ordering
         if (vmDeviceInfoService.isDeviceSubclassOf(rawDevice, 'VirtualDisk')) {
            return 10000 + computeDeviceNumber(rawDevice);
         } else if (vmDeviceInfoService.isDeviceSubclassOf(rawDevice, 'VirtualSCSIController')) {
            return 15000 + computeDeviceNumber(rawDevice);
         } else if (vmDeviceInfoService.isDeviceSubclassOf(rawDevice, 'VirtualEthernetCard')) {
            return 20000 + computeDeviceNumber(rawDevice);
         } else if (vmDeviceInfoService.isDeviceSubclassOf(rawDevice, 'VirtualCdrom')) {
            return 30000 + computeDeviceNumber(rawDevice);
         } else if (vmDeviceInfoService.isDeviceSubclassOf(rawDevice, 'VirtualFloppy')) {
            return 40000 + computeDeviceNumber(rawDevice);
         } else if (vmDeviceInfoService.isDeviceSubclassOf(rawDevice, 'VirtualUSBController') ||
               vmDeviceInfoService.isDeviceSubclassOf(rawDevice, 'VirtualUSBXHCIController')) {
            return 55000 + computeDeviceNumber(rawDevice);
         } else if (vmDeviceInfoService.isDeviceSubclassOf(rawDevice, 'VirtualUSB')) {
            return 60000 + computeDeviceNumber(rawDevice);
         } else if (vmDeviceInfoService.isDeviceSubclassOf(rawDevice, 'VirtualVideoCard')) {
            return 70000 + computeDeviceNumber(rawDevice);
         } else if (vmDeviceInfoService.isDeviceSubclassOf(rawDevice, 'VirtualNVDIMM')) {
            return 75000 + computeDeviceNumber(rawDevice);
         } else if (vmDeviceInfoService.isDeviceSubclassOf(rawDevice, 'VirtualSCSIPassthrough')) {
            return 80000 + computeDeviceNumber(rawDevice);
         } else if (vmDeviceInfoService.isDeviceSubclassOf(rawDevice, 'VirtualPCIPassthrough')) {
            return 90000 + computeDeviceNumber(rawDevice);
         } else { // all other VirtualDevices we sort by key instead of label
            return 100000 + rawDevice.key;
         }
      }
   }
})();
