module cluster_ui {

   class EditDrsDialogController {

      private loading: boolean;
      private is65VcOrLater: boolean;
      private objectId: string;
      private formData: any;

      public static $inject = ["$scope", "dataService", "vcService", "$q",
            "drsConstants"];

      constructor(private $scope: any,
                  private dataService: any,
                  private vcService: any,
                  private $q: any,
                  private drsConstants: any) {
         this.objectId = $scope.modalOptions.dialogData.objectId;
         this.$scope.modalOptions.dialogData.manager.setObjectId(this.objectId);
         this.$scope.modalOptions.dialogData.manager.setDrsDialogOptions(this.$scope.modalOptions);
         this.$scope.modalOptions.isAlertClosed = true;
         this.getViewData();
      }

      public getSelectedPageUrl(): string {
         let selectedPage: any = _.find(this.$scope.pages.categories, (page: any) => {
            return page.id === this.$scope.selectedCategoryId;
         });

         if (selectedPage) {
            return selectedPage.contentUrl;
         }

         return "";
      }

      private getViewData(): void {
         this.setPageBusy(true);

         let promises: Array<any> = [];

         let vcVersionPromise: any =
               this.vcService.is65VcOrLater(this.objectId)
                     .then((is65VcOrLater: any) => {
                        this.is65VcOrLater = is65VcOrLater;

                        this.$scope.pages = {
                           categories: this.$scope.modalOptions.dialogData.manager.getPageDefinitions(false)
                        };

                        this.$scope.selectedCategoryId = this.$scope.pages.categories[0].id;
                        this.$scope.selectCategory = (categoryId: any) => {
                           this.$scope.selectedCategoryId = categoryId;
                        };
                     });

         let requestDataPromise: any =
               this.dataService
                     .getData(this.objectId,
                           'com.vmware.vsphere.client.clusterui.model.services.DrsConfigData')
                     .then((drsData: any) => {

                        _.extend(this.$scope.modalOptions.dialogData.editDrsData, drsData);

                        this.setPageBusy(false);
                     });
         promises.push(vcVersionPromise);
         promises.push(requestDataPromise);


         this.$q.all(promises).then(()=> {
            let drsData: any =
                  this.$scope.modalOptions.dialogData.editDrsData;
            this.$scope.modalOptions.dialogData.manager.setFormData(drsData, this.is65VcOrLater, false);
            this.formData = this.$scope.modalOptions.dialogData.manager.getFormData();
            this.addWatchers();
         });
      }

      private addWatchers(): void {
         this.$scope.$watch('ctrl.formData.drsPolicies.allConsumedMemoryMetric',
               this.allConsumedMemoryMetricChanged.bind(this));
         this.$scope.$watch(() => {
            let allConsumedMemoryMetricOption: any = this.formData
                  .advancedOptionsBuilder.getOptionByKey(
                        this.drsConstants.drsPolicies
                              .ALL_CONSUMED_MEMORY_METRIC_OPTION);
            return allConsumedMemoryMetricOption &&
                  allConsumedMemoryMetricOption.value;
         }, (optionValue: string) => {
            this.formData.drsPolicies.allConsumedMemoryMetric =
                  !!optionValue && parseInt(optionValue) > 0;
         });
      }

      private allConsumedMemoryMetricChanged(allConsumedMemoryMetric: boolean): void {
         let allConsumedMemoryMetricOption =
               this.formData.advancedOptionsBuilder.getOptionByKey(
                     this.drsConstants.drsPolicies.ALL_CONSUMED_MEMORY_METRIC_OPTION);
         if (!allConsumedMemoryMetric) {
            if (allConsumedMemoryMetricOption) {
               this.formData.advancedOptionsBuilder
                     .removeOption(allConsumedMemoryMetricOption.id);
            }
         } else if (!allConsumedMemoryMetricOption) {
            this.formData.advancedOptionsBuilder.addOption({
               key: this.drsConstants.drsPolicies.ALL_CONSUMED_MEMORY_METRIC_OPTION,
               value: this.drsConstants.drsPolicies.ALL_CONSUMED_MEMORY_METRIC_VALUE
            });
         }
      }

      private setPageBusy(busy: boolean): void {
         this.loading = busy;
         this.$scope.modalOptions.submitDisabled = busy;

      }
   }

   angular.module("com.vmware.vsphere.client.cluster")
         .controller("EditDrsDialogController", EditDrsDialogController);
}
