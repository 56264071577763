/** Copyright 2016 VMware, Inc.  All rights reserved. -- VMware Confidential*/
(function () {
   'use strict';
   angular.module('com.vmware.vsphere.client.cluster')
         .controller('FtPortletController', FtPortletController);

   FtPortletController.$inject = ['$scope', 'ftPortletService', 'defaultUriSchemeUtil',
      'i18nService'];

   function FtPortletController($scope, ftPortletService, defaultUriSchemeUtil,
                                i18nService) {
      var self = this;
      var vmId = $scope._route.objectId;

      self.vmId = [vmId];
      self.liveRefreshProperties = [
         "runtime.faultToleranceState",
         "runtime.powerState",
         "runtime.dasVmProtection",
         "config.ftInfo",
         "config.files.ftMetadataDirectory",
         "config.flags.faultToleranceType"
      ];

      self.getData = function () {
         var promise = ftPortletService.requestData(vmId);
         promise.then(function (ftData) {
            self.ftData = ftData['ftportlet:ftdata'];
            var logBandwidth = parseInt(self.ftData.logBandwidth) || 0;

            if (logBandwidth > 0) {
               self.logBandwidth = i18nService.getString('VmUi',
                     'vmFTView.logBandwidthFormat', logBandwidth);
            } else {
               self.logBandwidth = i18nService.getString('VmUi',
                     'vmFTView.notAvailable');
            }

            if (self.ftData.secondaryHost) {
               self.hostRef = defaultUriSchemeUtil.getVsphereObjectId(self.ftData.secondaryHost);
            }
         });
      };
      self.getData();
   }
})();
