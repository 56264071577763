/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace dvs_ui {

   import IPromise = angular.IPromise;
   import DvsPortMirroringSessionData =
         com.vmware.vsphere.client.h5.network.dvs.portmirroring.model.DvsPortMirroringSessionData;

   export class DvsPortMirroringDetailsComponent {

      public controller: any;
      public bindings: any;
      public templateUrl: string;

      constructor() {
         this.bindings = {
            selectedSession: "<"
         };
         this.controller = DvsPortMirroringDetailsController;
         this.templateUrl =
               "dvs-ui/resources/dvs/portmirroring/dvsPortMirroringDetailsViewTemplate.html";
      }
   }

   class DvsPortMirroringDetailsController {

      static $inject = [
         "$rootScope",
         "$scope",
         "i18nService",
         "dvsPortMirroringDetailsViewService"
      ];

      public i18n: Function;

      public dSwitchUri: string;

      public selectedSession: string;

      public isLegacySession: boolean;

      public showDestinationsView: boolean;

      public isRemoteSourceSession: boolean;

      public isEncapsulatedSession: boolean;

      public isRemoteDestinationSession: boolean;

      public liveRefreshProperties: string[];

      private _selectedSessionPromise: IPromise<DvsPortMirroringSessionData>;

      private selectedTabIndex: number;

      constructor(private $rootScope: any,
                  private $scope: any,
                  private i18nService: any,
                  private dvsPortMirroringDetailsViewService: any) {

         this.i18n = this.i18nService.getString;

         this.isLegacySession = false;
         this.isRemoteDestinationSession = false;
         this.isRemoteSourceSession = false;
         this.isEncapsulatedSession = false;
         this.showDestinationsView = false;

         this.selectedTabIndex = 0;

         this.$scope.onSourcesViewRefreshed = (): void => {
            this.$scope.shouldRefreshSourcesView = false;
         };

         this.$scope.onDestinationsViewRefreshed = (): void => {
            this.$scope.shouldRefreshDestinationsView = false;
         };

         this.$scope.onTabSelected = (index: number): void => {
            this.$scope.isSourcesTabLoaded = index === 1
                  || !!this.$scope.isSourcesTabLoaded;
            this.$scope.isDestinationsTabLoaded = index === 2
                  || this.$scope.isDestinationsTabLoaded;

            this.selectedTabIndex = index;
         };
      }

      public $onChanges(): void {
            this.$scope.portMirrorLoading = true;
            this.refresh(this.selectedSession);
      }

      public refresh(selectedSession: string) {

         this.$scope.portMirrorLoading = true;

         this.dSwitchUri = this.$rootScope._route.objectId;

         let requestPromise: IPromise<DvsPortMirroringSessionData> =
               this.dvsPortMirroringDetailsViewService
                     .getDvsPortMirrorSessionDetails(
                     this.dSwitchUri, selectedSession);

         this._selectedSessionPromise = requestPromise;

         this.$scope.isSourcesTabLoaded = this.selectedTabIndex === 1;
         this.$scope.isDestinationsTabLoaded = this.selectedTabIndex === 2;

         requestPromise.then((results: any) => {
            if (this._selectedSessionPromise === requestPromise) {
               let detailsData: DvsPortMirroringSessionData = results;
               this.$scope.dvsPortMirrorSessionData =
                     this.dvsPortMirroringDetailsViewService
                           .buildDvsPortMirrorSessionPropertiesView(detailsData);

               if (detailsData) {

                  this.isLegacySession = detailsData.isLegacySession;

                  this.isRemoteSourceSession = detailsData.isRemoteSourceSession;

                  this.isEncapsulatedSession = detailsData.isEncapsulatedSession;

                  this.isRemoteDestinationSession =
                        detailsData.isRemoteDestinationSession;

                  this.showDestinationsView =
                        detailsData.isRemoteDestinationSession ||
                        detailsData.isDvPortMirrorSession;

                  this.$scope.shouldRefreshSourcesView = true;
                  this.$scope.shouldRefreshDestinationsView = true;

                  this.$scope.selectedSession = selectedSession;
               }

               this.$scope.portMirrorLoading = false;
            }
         });

         this.liveRefreshProperties = ["config.vspanSession"];
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .component("dvsPortMirroringDetailsView", new DvsPortMirroringDetailsComponent());
}
