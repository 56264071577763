namespace h5_vm {

   declare const angular: ng.IAngularStatic;
   import VirtualEthernetCard = com.vmware.vim.binding.vim.vm.device.VirtualEthernetCard;

   class StandbyOptionsController implements ng.IComponentController {

      static $inject: string[] = [
         'i18nService',
         'vmStandbyOptionsService',
         'vmDeviceInfoService'
      ];

      onChange: Function;
      options: any;
      networkDevices: any;
      expandedDevices: any;
      originalWakeOnLanValues: any;
      wakeOnLanValues: any;
      expanded: boolean;

      public vmWorkflowMode: VmWorkflowMode;
      private vmConfigContext: any;
      private i18n: Function;
      private standbyOptions: Array<{
         id: string,
         label: string,
         value: string
      }>;
      private standbyValue: string;

      constructor(private i18nService: any,
                  private vmStandbyOptionsService: any,
                  private vmDeviceInfoService: any) {
         this.i18n = (key: string, bundle: string ='VmUi', ...args : any[]) => i18nService.getString(bundle, key, ...args);

         this.vmStandbyOptionsService = vmStandbyOptionsService;
         this.vmDeviceInfoService = vmDeviceInfoService;
      }

      $onInit() {
         this.standbyValue = this.options ? (this.options.standbyAction || 'checkpoint') : 'checkpoint';
         this.standbyOptions = [
            {
               id: 'suspend',
               label: this.i18n('VmStandbyPage.Suspend'),
               value: 'powerOnSuspend'
            },
            {
               id: 'leaveOn',
               label: this.i18n('VmStandbyPage.LeaveOn'),
               value: 'checkpoint'
            }
         ];

         this.setExpandedDevices();
         this.setOriginalWakeOnLanValues();
         if (this.vmWorkflowMode === VmWorkflowMode.CreateMode) {
            this.setWakeOnLanValues({
               forceTrue: true
            }); // set all the values to true
         } else {
            this.setWakeOnLanValues({});
         }
      }

      onStandbyOptionsChange = () => {
         this.onChange({
            standbyAction: this.standbyValue
         });

         this.setWakeOnLanValues({});
      };

      onWakeOnLanChange = (device:VirtualEthernetCard) => {
         // since the wakeOnLan values are in a separate object, we need to
         // actually modify the real device's values when applicable
         if (this.standbyValue !== 'powerOnSuspend') {
            device.wakeOnLanEnabled = !device.wakeOnLanEnabled;
         }
      };

      isWakeOnLanVisible = (): boolean => {
         let guestOsSupported = false;
         let capabilitiesSupported = false;
         if (!_.isEmpty(this.vmConfigContext)) {
            guestOsSupported = this.vmConfigContext.selectedGuestOs &&
                  this.vmConfigContext.selectedGuestOs.supportsWakeOnLan;
            capabilitiesSupported = this.vmConfigContext.environment &&
                  this.vmConfigContext.environment.configOption &&
                  this.vmConfigContext.environment.configOption.capabilities &&
                  this.vmConfigContext.environment.configOption.capabilities.s1AcpiManagementSupported;
         }

         return guestOsSupported && capabilitiesSupported;
      };

      private getErrorString(device:VirtualEthernetCard): string {
         let osName = this.vmConfigContext.selectedGuestOs ? this.vmConfigContext.selectedGuestOs.fullName : '';
         let type = this.vmDeviceInfoService.getEthernetCardLabel(device._type);

         return this.i18n('VmStandbyPage.WakeUnsupported', 'VmUi', osName, type);
      }

      private setExpandedDevices() {
         this.expandedDevices = {};

         for (let i = 0; i < this.networkDevices.length; i++) {
            let device = this.networkDevices[i];
            let currentDevice = device.getCurrentDevice();

            // need to tie the device to a key otherwise ng-repeat will
            // add a $$hashKey attribute to the devices which messes with
            // our internal diffing function on unit tests
            this.expandedDevices[currentDevice.key] = currentDevice;
         }
      }

      // save the state of the original devices so that we can determine if
      // wakeOnLanEnabled was originally true or false
      private setOriginalWakeOnLanValues() {
         this.originalWakeOnLanValues = {};

         angular.forEach(this.expandedDevices, ((device:VirtualEthernetCard) => {
            // if we are in the create workflow, values are defaulted to true
            this.originalWakeOnLanValues[device.key] =
               this.vmWorkflowMode === VmWorkflowMode.CreateMode ? true : device.wakeOnLanEnabled;
         }));
      }

      // update the wakeOnLan values when the standby mode changes
      private setWakeOnLanValues(options:any) {
         this.wakeOnLanValues = {};

         angular.forEach(this.expandedDevices, ((device:VirtualEthernetCard) => {
            // if standbyValue is 'powerOnSuspend', then all wakeOnLan is automatically disabled
            if (options.forceTrue) {
               this.wakeOnLanValues[device.key] = true;
            } else {
               this.wakeOnLanValues[device.key] = this.standbyValue === 'powerOnSuspend' ? false : device.wakeOnLanEnabled;
            }
         }));
      }

      private getDeviceLabel(device:VirtualEthernetCard): string {
         let suffix = '';
         let backing:any = device.backing;

         if (backing && backing._type === 'com.vmware.vim.binding.vim.vm.device.VirtualEthernetCard$NetworkBackingInfo') {
            let deviceName = backing.deviceName;
            suffix = deviceName ? ' (' + deviceName + ')' : ''; // deviceName can be empty apparently
         } else if (backing && backing._type === 'com.vmware.vim.binding.vim.vm.device.VirtualEthernetCard$DistributedVirtualPortBackingInfo') {
            let info = this.findPortgroupInfo(backing);

            suffix = info ? ' (' + info.portgroupName + ')' : '';
         } else if (backing && backing._type === 'com.vmware.vim.binding.vim.vm.device.VirtualEthernetCard$OpaqueNetworkBackingInfo') {
            let network = this.findOpaqueInfo(backing);

            suffix = network ? ' (' + network.name + ')' : '';
         }

         return device.deviceInfo.label + suffix;
      }

      private findPortgroupInfo(portGroup:any): any {
         return this.vmStandbyOptionsService.findPortgroupInfo(portGroup, this.vmConfigContext);
      }

      private findOpaqueInfo(backing:any): any {
         return this.vmStandbyOptionsService.findOpaqueInfo(backing, this.vmConfigContext);
      }

      private isTypeSupported(device:VirtualEthernetCard): boolean {
         return this.vmStandbyOptionsService.isTypeSupported(device, this.vmConfigContext);
      }

      private isEditSettingsDisabled(): boolean {
         return this.vmStandbyOptionsService.isEditSettingsDisabled(this.vmConfigContext.privileges, this.vmWorkflowMode);
      }

      private isEditWakeOnLanDisabled(device:VirtualEthernetCard): boolean {
         return this.vmStandbyOptionsService.isEditWakeOnLanDisabled(device, {
            vmConfigContext: this.vmConfigContext,
            originalWakeOnLanValues: this.originalWakeOnLanValues,
            standbyValue: this.standbyValue,
            vmWorkflowMode: this.vmWorkflowMode
         });
      }
   }

   class StandbyOptions implements ng.IComponentOptions {
      bindings:    any;
      controller:  any;
      templateUrl: string;

      constructor() {
         this.bindings = {
            vmWorkflowMode: '<',
            onChange:   '&',
            networkDevices: '<',
            vmConfigContext: '<',
            options:  '<',
            expanded: '='
         };
         this.controller = StandbyOptionsController;
         this.templateUrl = 'vm-ui/resources/vm/views/settings/vmOptions/standbyOptions/standby-options.html';
      }
   }

   angular.module('com.vmware.vsphere.client.vm')
      .component('standbyOptions', new StandbyOptions());
}
