namespace h5_vm {
import VmRemovalSpec = com.vmware.vsphere.client.vm.VmRemovalSpec;

export class VmRemovalService {
   static $inject: string[] = ["mutationService"];

   constructor(private mutationService:any) {
   }

   public unregisterVms(vmIds:string[]) {
      let unregisterSpec:VmRemovalSpec = VmRemovalService.buildRemovalSpec(false);
      this.mutationService.removeOnMultiEntity(vmIds,
            unregisterSpec._type, unregisterSpec);
   }

   public deleteVms(vmIds:string[]) {
      let deleteSpec:VmRemovalSpec = VmRemovalService.buildRemovalSpec(true);
      this.mutationService.removeOnMultiEntity(vmIds, deleteSpec._type, deleteSpec);
   }

   private static buildRemovalSpec(deleteFromDisk: boolean):VmRemovalSpec {
      let removalSpec:VmRemovalSpec = new VmRemovalSpec();
      removalSpec.deleteFromDisk = deleteFromDisk;
      return removalSpec;
   }
}

angular.module("com.vmware.vsphere.client.vm")
      .service("vmRemovalService", VmRemovalService);
}
