(function() {
   "use strict";

   /**
    * Factory to create objects needed to either render or update host services that
    * can be enabled on VMkernel adapters.
    */
   angular
         .module('com.vmware.vsphere.client.network')
         .factory('hostVnicServiceFactory', hostVnicServiceFactory);

   hostVnicServiceFactory.$inject = ['i18nService', 'hostVnicServiceConstants'];

   function hostVnicServiceFactory(i18nService, hostVnicServiceConstants) {

      return {
         getServiceDescriptions: getServiceDescriptions,
         getServiceSettings: getServiceSettings,
         pickServiceFeatures: pickServiceFeatures,
         pickServiceSettings: pickServiceSettings
      };

      /**
       * Returns array of objects that represent host vnic service.
       *
       * @param hostVnicFeatures
       *    Feature flags describing host services that can be enabled on vnics.
       *    Object with hostVnicServiceConstants.featureFlags keys and boolean values.
       *
       *    Example:
       *    ```js
       *       var hostVnicFeatures = {
       *          isVmotionConfigurable: true,
       *          isProvisioningConfigurable: true
       *          // ...
       *       }
       *    ```
       * @param hostVnicSettings
       *    Object with key:value pairs like <serviceKey: boolean>.
       *    Service keys are described in hostVnicServiceConstants.keys.
       *    The boolean value of the pair describes if the service is enabled on a vnic.
       *
       *    Example:
       *    ```js
       *       var hostVnicSettings = {
       *          isVmotionEnabled: true,
       *          isProvisioningNfcTrafficEnabled: true
       *          // ...
       *       }
       *    ```
       * @return
       *
       *    Example:
       *
       *    result[0] = {
       *       includeInLayout: true, // See hostVnicServiceConstants.featureFlags.
       *       isEnabled: false,      // See hostVnicServiceConstants.featureFlags.
       *       label: 'vMotion',
       *       summaryLabel: 'vMotion summary',
       *       tooltip: 'vMotion description'
       *    }
       *
       * @see hostVnicServiceConstants.keys
       * @see hostVnicServiceConstants.featureFlags
       */
      function getServiceDescriptions(hostVnicFeatures, hostVnicSettings) {
         var keys = hostVnicServiceConstants.keys;
         var flags = hostVnicServiceConstants.featureFlags;
         var features = hostVnicFeatures || {};
         var settings = hostVnicSettings || {};
         var isDefaultStack = !features[flags.TCP_IP_STACK] || !!features[flags.TCP_IP_STACK_DEFAULT];
         var isVmotionEnabled = isDefaultStack && !!features[flags.VMOTION_CONFIGURABLE];
         var isProvisioningEnabled = isDefaultStack && !!features[flags.PROVISIONING_CONFIGURABLE];

         var services = {};
         services[keys.VMOTION] = {
            includeInLayout: true,
            isEnabled: isVmotionEnabled,
            label: getString('VnicPortPropertiesPage.vMotion'),
            summaryLabel: getString('AddNetworkingWizard.summary.vmotion'),
            tooltip: getServiceTooltipPerStack(isVmotionEnabled,
                  !!settings[keys.VMOTION], isDefaultStack)
         };
         services[keys.PROVISIONING] = {
            includeInLayout: !!features[flags.PROVISIONING],
            isEnabled: isProvisioningEnabled,
            label: getString('VnicPortPropertiesPage.provisioningNfcTraffic'),
            summaryLabel: getString('AddNetworkingWizard.summary.provisioningNfcTraffic'),
            tooltip:  getServiceTooltipPerStack(isProvisioningEnabled,
                  !!settings[keys.PROVISIONING], isDefaultStack)
         };
         services[keys.FT_LOGGING] = {
            includeInLayout: true,
            isEnabled: isDefaultStack,
            label: getString('VnicPortPropertiesPage.ftLogging'),
            summaryLabel: getString('AddNetworkingWizard.summary.ftLogging'),
            tooltip: getServiceTooltip(isDefaultStack, !!settings[keys.FT_LOGGING])
         };
         services[keys.MANAGEMENT] = {
            includeInLayout: true,
            isEnabled: isDefaultStack,
            label: getString('VnicPortPropertiesPage.mngTraffic'),
            summaryLabel: getString('AddNetworkingWizard.summary.managementTraffic'),
            tooltip: getServiceTooltip(isDefaultStack, !!settings[keys.MANAGEMENT])
         };
         services[keys.VR] = {
            includeInLayout: !!features[flags.VR],
            isEnabled: isDefaultStack,
            label: getString('VnicPortPropertiesPage.vRTraffic'),
            summaryLabel: getString('AddNetworkingWizard.summary.vRTraffic'),
            tooltip: getServiceTooltip(isDefaultStack, !!settings[keys.VR])
         };
         services[keys.VR_NFC] = {
            includeInLayout: !!features[flags.VR_NFC],
            isEnabled: isDefaultStack,
            label: getString('VnicPortPropertiesPage.vrNfcTraffic'),
            summaryLabel: getString('AddNetworkingWizard.summary.vrNfcTraffic'),
            tooltip: getServiceTooltip(isDefaultStack, !!settings[keys.VR_NFC])
         };
         services[keys.VSAN] =  {
            includeInLayout: !!features[flags.VSAN],
            isEnabled: isDefaultStack,
            label: getString('VnicPortPropertiesPage.vsan'),
            summaryLabel: getString('AddNetworkingWizard.summary.vsan'),
            tooltip: getServiceTooltip(isDefaultStack, !!settings[keys.VSAN])
         };

         return services;
      }

      /**
       * Returns flags that describe services are enabled on host vnic.
       * Only settings for the supported services are included in the result.
       *
       * @param hostVnicFeatures
       *    Feature flags describing host services that can be enabled on vnics.
       *    Object with hostVnicServiceConstants.featureFlags keys and boolean values.
       *
       *    Example:
       *    ```js
       *       var hostVnicFeatures = {
       *          isProvisioningNfcTrafficSupported: true,
       *          isVrTrafficSupported: true,
       *          isVsanSupported: true
       *          // ...
       *       }
       *    ```
       * @return
       *    Example:
       *    ```js
       *       var result = {
       *          isVmotionEnabled: false,  // default, always available
       *          isProvisioningNfcTrafficEnabled: false,
       *          isVRTrafficEnabled: false,
       *          isVsanEnabled: false
       *          // ...
       *       }
       *    ```
       *
       * @see hostVnicServiceConstants.keys
       * @see hostVnicServiceConstants.featureFlags
       */
      function getServiceSettings(hostVnicFeatures) {
         var keys = hostVnicServiceConstants.keys;
         var flags = hostVnicServiceConstants.featureFlags;

         var settings = {};
         settings[keys.FT_LOGGING] = false;
         settings[keys.MANAGEMENT] = false;

         // defaults
         if (!!!hostVnicFeatures) {
            return settings;
         }

         // conditional
         if (!!hostVnicFeatures[flags.PROVISIONING] &&
               !!hostVnicFeatures[flags.TCP_IP_STACK_PROVISIONING]) {
            settings[keys.PROVISIONING] = true;
         } else if (!!hostVnicFeatures[flags.PROVISIONING]) {
            settings[keys.PROVISIONING] = false;
         }

         if (!!hostVnicFeatures[flags.TCP_IP_STACK_VMOTION]) {
            settings[keys.VMOTION] = true;
         } else {
            settings[keys.VMOTION] = false;
         }

         if (!!hostVnicFeatures[flags.VR]) {
            settings[keys.VR] = false;
         }

         if (!!hostVnicFeatures[flags.VR_NFC]) {
            settings[keys.VR_NFC] = false;
         }

         if (!!hostVnicFeatures[flags.VSAN]) {
            settings[keys.VSAN] = false;
         }

         return settings;
      }

      /**
       * Returns an object with service settings picked from the provided data.
       *
       * @see hostVnicServiceConstants.keys
       */
      function pickServiceSettings(vmkernelSettingsSpec) {
         var keys = hostVnicServiceConstants.keys;

         return _.pick(
               vmkernelSettingsSpec,
               keys.VMOTION,
               keys.PROVISIONING,
               keys.FT_LOGGING,
               keys.MANAGEMENT,
               keys.VR,
               keys.VR_NFC,
               keys.VSAN);
      }

      /**
       * Returns an object with service features picked from the provided data.
       *
       * @see hostVnicServiceConstants.featureFlags
       */
      function pickServiceFeatures(data) {
         var flags = hostVnicServiceConstants.featureFlags;

         return _.pick(
               data,
               flags.VMOTION_CONFIGURABLE,
               flags.PROVISIONING_CONFIGURABLE,
               flags.PROVISIONING,
               flags.FT_LOGGING,
               flags.VR,
               flags.VR_NFC,
               flags.VSAN,
               flags.TCP_IP_STACK,
               flags.TCP_IP_STACK_DEFAULT,
               flags.TCP_IP_STACK_PROVISIONING,
               flags.TCP_IP_STACK_VMOTION);
      }

      function getServiceTooltip(isConfigurable, isOn) {
         var isDefaultStackSelected = false;
         return getServiceTooltipPerStack(isConfigurable, isOn, isDefaultStackSelected);
      }

      function getServiceTooltipPerStack(isConfigurable, isOn, isDefaultStackSelected) {
         if (isConfigurable === false) {
            var uncheckedServiceTooltip = (isDefaultStackSelected)
                  ? getString('VnicPortPropertiesPage.cannotBeEnabledOnDefaultStackServiceTooltip')
                  : getString('VnicPortPropertiesPage.disabledUnCheckedServiceTooltip');

            return (!!isOn)
                  ? getString('VnicPortPropertiesPage.disabledCheckedServiceTooltip')
                  : uncheckedServiceTooltip;
         }

         return '';
      }

      function getString(key) {
         return i18nService.getString('NetworkUi', key);
      }
   }
})();