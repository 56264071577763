namespace h5_vm {
   import GuestOsDescriptor = com.vmware.vim.binding.vim.vm.GuestOsDescriptor;

   export class VmHardwareMemoryService {
      static $inject: string[] = [
         'vmHardwareUtil'
      ];

      constructor(private vmHardwareUtil: any) {
      }

      getReservationMaxLimit(memoryUsage: any,
                             configuredMemory: number,
                             memoryAllocation: any,
                             vmPowerState: any) {
         var configuredReservation = 0;
         var configuredLimit = -1;

         if (memoryAllocation) {
            if (memoryAllocation.reservation) {
               configuredReservation = memoryAllocation.reservation;
            }
            if (memoryAllocation.hasOwnProperty("limit")) {
               configuredLimit = memoryAllocation.limit;
            }
         }
         var vmPoweredOn = this.vmHardwareUtil.isVmPoweredOn(vmPowerState);
         var min = vmPoweredOn ? configuredReservation : 0;

         var maxUsage = this.vmHardwareUtil.bytesToMB(memoryUsage.maxUsage);
         var minUpperLimit = 0;
         var reservationMaxLimit = 0;

         if (!vmPoweredOn) {
            minUpperLimit = Math.max(maxUsage, configuredReservation);
         } else {
            if (memoryUsage.unreservedForVm !== -1) {
               minUpperLimit = this.vmHardwareUtil.bytesToMB(memoryUsage.unreservedForVm);
            }
            minUpperLimit += min;
         }
         // do not reserve more than configured memory (no need?)
         if (minUpperLimit > configuredMemory) {
            minUpperLimit = configuredMemory;
         }

         if (configuredLimit !== -1) {
            reservationMaxLimit = Math.min(configuredLimit, minUpperLimit);
         } else {
            reservationMaxLimit = minUpperLimit;
         }
         return reservationMaxLimit;
      }

      getMinMemoryMB(config: any, environment: any) {
         if (this.vmHardwareUtil.isVmPoweredOn(environment.powerState) && config.memoryHotAddEnabled) {
            return config.hardware.memoryMB;
         }
         return environment.configOption.hardwareOptions.memoryMB.min;
      }

      getMaxMemoryMB(config: any, environment: any) {
         if (this.vmHardwareUtil.isVmPoweredOn(environment.powerState) && config.memoryHotAddEnabled) {
            return config.hotPlugMemoryLimit;
         }
         return environment.configOption.hardwareOptions.memoryMB.max;
      }

      isMemoryDisabled(config: any, environment: any, privileges: string[], skipPrivilegeCheck: boolean = false) {

         const validate = () => {
            if (this.getMinMemoryMB(config, environment) >= this.getMaxMemoryMB(config, environment)) {
               return true;
            } else {
               return !config.memoryHotAddEnabled && this.vmHardwareUtil.isVmPoweredOn(environment.powerState);
            }
         };

         if (skipPrivilegeCheck) {
            return validate();
         } else {
            const hasPrivilege = this.vmHardwareUtil.checkPrivileges(privileges, ['VirtualMachine.Config.Memory']);
            return !hasPrivilege || validate();
         }
      }

      isReservationDisabled(config: any, privileges: string[], skipPrivilegeCheck: boolean = false) {

         const validate = () => {
            return config.memoryReservationLockedToMax;
         };

         if (skipPrivilegeCheck) {
            return validate();
         } else {
            const hasPrivilege = this.vmHardwareUtil.checkPrivileges(privileges, ['VirtualMachine.Config.Resource']);
            return !hasPrivilege || validate();
         }
      }

      isMaxReservationDisabled(rtInfo: any = {}, privileges: string[], capabilities: any = {}, skipPrivilegeCheck: boolean = false) {
         const validate = () => {
           return rtInfo.faultToleranceState === 'enabled' || !capabilities.memoryReservationLockSupported;
         };

         if (skipPrivilegeCheck) {
            return validate();
         } else {
            const hasPrivilege = this.vmHardwareUtil.checkPrivileges(privileges, ['VirtualMachine.Config.Resource']);
            return !hasPrivilege || validate();
         }
      }

      isLimitDisabled(privileges: string[], skipPrivilegeCheck: boolean = false) {

         if (skipPrivilegeCheck) {
            return false;
         } else {
            const hasPrivilege = this.vmHardwareUtil.checkPrivileges(privileges, ['VirtualMachine.Config.Resource']);
            return !hasPrivilege;
         }
      }

      isSharesDisabled(privileges: string[], skipPrivilegeCheck: boolean = false) {

         if (skipPrivilegeCheck) {
            return false;
         } else {
            const hasPrivilege = this.vmHardwareUtil.checkPrivileges(privileges, ['VirtualMachine.Config.Resource']);
            return !hasPrivilege;
         }
      }

      isMemoryHotPlugDisabled(environment: any, privileges: string[],
            selectedGuestOs: GuestOsDescriptor, skipPrivilegeCheck: boolean = false) {
         const validate = () => {
            const doesNotSupportMemoryHotAdd = !selectedGuestOs.supportsMemoryHotAdd;
            return this.vmHardwareUtil.isVmPoweredOn(environment.powerState) || doesNotSupportMemoryHotAdd;
         };

         if (skipPrivilegeCheck) {
            return validate();
         } else {
            const hasPrivilege = this.vmHardwareUtil.checkPrivileges(privileges, ['VirtualMachine.Config.Memory']);
            return !hasPrivilege || validate();
         }
      }
   }

   angular.module('com.vmware.vsphere.client.vm')
      .service('vmHardwareMemoryService', VmHardwareMemoryService);
}
