/* Copyright 2015 Vmware, Inc. All rights reserved. -- Vmware Confidential */
angular.module('com.vmware.vsphere.client.vm').factory('vmDeviceInfoService', [
   'i18nService',
   'deviceClassLineageService',
   'defaultUriSchemeUtil',
   'managedEntityConstants',
   function (
      i18nService,
      deviceClassLineageService,
      defaultUriSchemeUtil ,
      managedEntityConstants
   ) {
      // ----- Public API -------

      return {
         isDeviceSubclassOf: isDeviceSubclassOf,
         isDeviceInstanceOf: isDeviceInstanceOf,
         getEthernetCardLabel: getEthernetCardLabel,
         getScsiControllerLabel: getScsiControllerLabel,
         getStorageObjectLabel: getStorageObjectLabel,
         optionForDevice: optionForDevice,
         backingOptionForDevice: backingOptionForDevice,
         availableControllersForDevice: availableControllersForDevice,
         findCdromControllerNodes: findCdromControllerNodes,
         findHardDiskControllerNodes: findHardDiskControllerNodes,
         getControllerAndNodeLabelText: getControllerAndNodeLabelText,
         buildAvailableNetworkList: buildAvailableNetworkList,
         getNetworkInfo: getNetworkInfo,
         buildNetworkDeviceBacking: buildNetworkDeviceBacking,
         getDeviceEthernetCard: getDeviceEthernetCard
      };

      function isDeviceSubclassOf(rawDevice, className) {
         if(!rawDevice._type) {return false;}
         var simpleDeviceClassName = getSimpleDeviceClassName(rawDevice);
         return deviceClassLineageService.isClassNameSubclassOf(simpleDeviceClassName, className);
      }

      function isDeviceInstanceOf(rawDevice, className) {
         if(!rawDevice._type) {return false;}
         return getSimpleDeviceClassName(rawDevice) === getSimpleClassNameFromDeviceType(className);
      }

      function getDeviceEthernetCard(device) {
         return {
            name: device._type,
            typeClass: device._type,
            wsdlName: getSimpleClassNameFromDeviceType(device._type)
         };
      }

      function getEthernetCardLabel(deviceType) {
         var label;
         var getString = i18nService.getString;

         switch (getSimpleClassNameFromDeviceType(deviceType)) {
            case 'VirtualE1000':
               label = getString('VmUi', "NetworkConfig.E1000");
               break;
            case 'VirtualPCNet32':
               label = getString('VmUi', "NetworkConfig.PCNet32");
               break;
            case 'VirtualVmxnet3':
               label = getString('VmUi', "NetworkConfig.VirtualVmxnet3");
               break;
            case 'VirtualVmxnet3Vrdma':
               label = getString('VmUi', "NetworkConfig.VirtualVmxnet3Vrdma");
               break;
            case 'VirtualVmxnet2':
               label = getString('VmUi', "NetworkConfig.VirtualVmxnet2");
               break;
            case 'VirtualVmxnet':
               label = getString('VmUi', "NetworkConfig.VirtualVmxnet");
               break;
            case 'VirtualE1000e':
               label = getString('VmUi', "NetworkConfig.E1000e");
               break;
            case 'VirtualSriovEthernetCard':
               label = getString('VmUi', "NetworkConfig.Sriov");
               break;
            default:
               label = getString('VmUi', "NetworkConfig.Unknown");
         }

         return label;
      }

      function getScsiControllerLabel (controller) {
         return i18nService.getString('VmUi', 'SCSIControllerConfig.' + getSimpleDeviceClassName(controller));
      }

      function getStorageObjectLabel (storageObject, datastoreName,
            storageObjectIsStandaloneDS, storageNameMap) {
         if (!datastoreName) {
            if(storageObjectIsStandaloneDS && storageObject.name) {
               return storageObject.name;
            }

            return i18nService.getString('VmUi', 'ProvisioningWizardSummaryPage.GettingData');
         }
         var dsClusterName;
         var recommendedText;

         // If the storageObject is a cluster, then the datastoreName is the name of
         // the recommended datastore.
         if (storageObject.storageRef.type === managedEntityConstants.STORAGE_POD) {
            recommendedText = i18nService.getString('VmUi', 'ProvisioningWizardSummaryPage.Recommended');
            dsClusterName = storageObject.name;

            return i18nService
                  .getString('VmUi', 'ProvisioningWizardSummaryPage.PodAndDsRecommended',
                        dsClusterName, datastoreName, recommendedText, '');
         }

         // If the storageObject is datastore with a parent pod.
         if (!storageObjectIsStandaloneDS) {
            var storagePodUrn = defaultUriSchemeUtil
               .getVsphereObjectId(storageObject.parentStoragePod);
            dsClusterName = storageNameMap[storagePodUrn];

            return i18nService
                  .getString('VmUi', 'ProvisioningWizardSummaryPage.PodAndDsRecommended',
                        dsClusterName, datastoreName, '', '');
         }

         return datastoreName;
      }

      function optionForDevice (vmConfigContext, inflatedDevice, connectedInflatedDevices) {
         // Every device has a set of options that can be configured (disk mode, etc)
         var device = inflatedDevice.getCurrentDevice();

         // Finding the correct options set sometimes also requires knowing the controller
         //   type this device lives on (SATA, SCSI, IDE...) which is not information that
         //   lives directly on the device, so first we must find the controller. (Controllers
         //   are themselves a type of device so are included in the list of connected
         //   devices)/
         var inflatedController = _.find(connectedInflatedDevices, function (inflatedCandidate) {
            var candidate = inflatedCandidate.getCurrentDevice();
            return candidate.key === device.controllerKey;
         });

         if (!inflatedController) {
            throw new Error('Could not find controller with device id ' + device.controllerKey);
         }

         // Now that we know the device and controller, we can find the option set for that type
         var hardwareOptions = vmConfigContext.environment.configOption.hardwareOptions;
         return _.find(hardwareOptions.virtualDeviceOption, function (option) {
            return (option.type.name === device._type) &&
               (option.controllerType && isDeviceSubclassOf(inflatedController.getCurrentDevice(), option.controllerType.name));
         });
      }

      function backingOptionForDevice (vmConfigContext, inflatedDevice, allInflatedDevices) {
         var deviceOption = optionForDevice(vmConfigContext, inflatedDevice, allInflatedDevices);

         return _.find(deviceOption.backingOption, function (option) {
            return option.type.name === inflatedDevice.getCurrentDevice().backing._type;
         });
      }

      function availableControllersForDevice (inflatedDevice, allInflatedDevices) {
         var allowedTypes;
         if (inflatedDevice.isOfType('VirtualCdrom')) {
            allowedTypes = ['VirtualIDEController', 'VirtualSATAController'];
         } else if (inflatedDevice.isOfType('VirtualDisk')) {
            allowedTypes = ['VirtualIDEController', 'VirtualSATAController', 'VirtualSCSIController', 'VirtualNVMEController'];
         } else {
            throw new Error("Don't know what controllers are allowed for the device with type " + inflatedDevice.getCurrentDevice()._type);
         }

         return _.filter(allInflatedDevices, function (possibleInflatedController) {
            return isDeviceSubclassOfAny(possibleInflatedController, allowedTypes);
         });
      }

      function findCdromControllerNodes(controller, virtualDeviceOptions) {
         var keyRegex = /num\w+Cdrom/;
         return findControllerNodes(controller, virtualDeviceOptions, keyRegex);
      }

      function findHardDiskControllerNodes(controller, virtualDeviceOptions) {
         var keyRegex = /num\w+Disk/;
         return findControllerNodes(controller, virtualDeviceOptions, keyRegex);
      }

      function findControllerNodes(controller, virtualDeviceOptions, keyRegex) {
         var controllerOption = _.find(virtualDeviceOptions, function (option) {
            return option.type.typeClass === controller._type;
         });

         var numDeviceKey = _.find(_.keys(controllerOption), function (key) {
            return key.match(keyRegex);
         });

         var max = controllerOption[numDeviceKey].max;
         var nodes = [];
         for (var i = 0; i < max; i++) {
            nodes.push({
               controllerTypePrefix: displayPrefixForController(controller),
               controllerBusNumber: controller.busNumber,
               deviceUnitNumber: i
            });
         }

         return nodes;
      }

      function getControllerAndNodeLabelText(controllerType, controllerBusNumber, deviceUnitNumber) {
         var simpleDeviceClassName = getSimpleClassNameFromDeviceType(controllerType);

         var localizationKeySuffix = 'Ide';
         if (deviceClassLineageService.isClassNameSubclassOf(simpleDeviceClassName, 'VirtualSCSIController')) {
            localizationKeySuffix = 'Scsi';
         } else if (deviceClassLineageService.isClassNameSubclassOf(simpleDeviceClassName, 'VirtualSATAController')) {
            localizationKeySuffix = 'Sata';
         } else if (deviceClassLineageService.isClassNameSubclassOf(simpleDeviceClassName, 'VirtualNVMEController')) {
            localizationKeySuffix = 'Nvme';
         }

         return i18nService.getString('VmUi', 'ProvisioningWizardSummaryPage.VdNode' + localizationKeySuffix, controllerBusNumber, deviceUnitNumber);
      }

      function displayPrefixForController(controller) {
         if (isDeviceSubclassOf(controller, 'VirtualIDEController')) {
            return 'IDE';
         } else if (isDeviceSubclassOf(controller, 'VirtualSCSIController')) {
            return 'SCSI';
         } else if (isDeviceSubclassOf(controller, 'VirtualNVMEController')) {
            return 'NVME';
         } else if (isDeviceSubclassOf(controller, 'VirtualSATAController')) {
            return 'SATA';
         } else {
            throw new Error("Don't know how to render controller type prefix for a ", controller._type);
         }
      }

      function buildAvailableNetworkList(configTarget) {

         var availableNetworks = getAvailableNetworks(configTarget);

         _.each(availableNetworks, function(network) {
            var networkName = '', networkDetails = '';
            if (network) {
               if (network.portgroupName) {
                  networkName = network.portgroupName;
                  if (network.switchName) {
                     networkDetails = network.switchName;
                  }
               } else if (network.network) {
                  networkName = network.network.name;
                  if (network.network.opaqueNetworkType) {
                     networkDetails = network.network.opaqueNetworkType;
                  }
               }
            }
            if (networkDetails) {
               network.displayName = i18nService.getString('VmUi', 'VmHardwareView.networkDisplayName', networkName, networkDetails);
            } else {
               network.displayName = networkName;
            }
         });
         return availableNetworks;
      }

      function getNetworkInfo(configTarget, networkId) {

         var availableNetworks = getAvailableNetworks(configTarget);

         var moid;

         var result = _.find(availableNetworks, function(nw) {
            var type = nw._type;
            switch(type) {
               case h5_vm.NetworkType.STD_NETWORK:
               case h5_vm.NetworkType.NSX_NETWORK:
                  moid = nw.network.network; break;
               case h5_vm.NetworkType.DVSWITCH:
                  moid = nw.distributedVirtualSwitch; break;
               case h5_vm.NetworkType.DVPORGGROUP:
                  moid = nw.portgroup; break;
            }
            return networkId === defaultUriSchemeUtil.getVsphereObjectId(moid);
         });
         return result;
      }

      function buildNetworkDeviceBacking(network) {

         var backing;
         if (!network) {
            backing = {
               _type: h5_vm.NetBackingType.STD_NETWORK,
               deviceName: '',
               network: null
            };
            return backing;
         }

         switch(network._type) {
            case h5_vm.NetworkType.NSX_NETWORK:
               backing = {
                  _type: h5_vm.NetBackingType.NSX_NETWORK,
                  opaqueNetworkId: network.network.opaqueNetworkId,
                  opaqueNetworkType: network.network.opaqueNetworkType
               };
               break;
            case h5_vm.NetworkType.DVPORGGROUP:
               backing = {
                  _type: h5_vm.NetBackingType.DVPORGGROUP,
                  port: {
                     _type: 'com.vmware.vim.binding.vim.dvs.PortConnection',
                     portgroupKey: network.portgroupKey,
                     switchUuid: network.switchUuid
                  }
               };
               break;
            case h5_vm.NetworkType.STD_NETWORK:
               backing = {
                  _type: h5_vm.NetBackingType.STD_NETWORK,
                  deviceName: network.name,
                  network: angular.extend(network.network.network, {
                     'type': 'Network'
                  })
               };
               break;
         }

         return backing;
      }

      // ------ Private API -----

      function getSimpleClassNameFromDeviceType(fullyQualifiedClassName) {
         var index = fullyQualifiedClassName.lastIndexOf(".");
         if (index >= 0) {
            return fullyQualifiedClassName.substr(index + 1);
         }
         return fullyQualifiedClassName;
      }

      function isDeviceSubclassOfAny(inflatedDevice, types) {
         return _.any(types, function(type) {
            return isDeviceSubclassOf(inflatedDevice.getCurrentDevice(), type);
         });
      }

      function getSimpleDeviceClassName(rawDevice) {
         return getSimpleClassNameFromDeviceType(rawDevice._type);
      }

      function getAvailableNetworks(configTarget) {
         var portgroupTargets = _.filter(configTarget.distributedVirtualPortgroup, function (portgroupInfo) {
            return !portgroupInfo.uplinkPortgroup;
         });
         var targetNetworks = configTarget.network || [];
         var availableNetworks = targetNetworks.concat(portgroupTargets);

         var nsx = configTarget.opaqueNetwork || [];
         availableNetworks = availableNetworks.concat(nsx);

         return availableNetworks;
      }
   }]);
