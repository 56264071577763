/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
(function () {
   'use strict';

   angular
         .module('com.vmware.vsphere.client.licenseLibUi')
         .service('licenseTreeListUtil', licenseTreeListUtil);

   licenseTreeListUtil.$inject = ['i18nService', 'licenseCommonUtil'];

   function licenseTreeListUtil(i18nService, licenseCommonUtil) {

      function createLicenseTreeListItems(validationResult /*AssetAssignLicenseValidationResult*/) {
            var assignableLicenses = licenseCommonUtil.getAssignableLicenses(
               validationResult
         );

         var items = _.flatten(_.map(assignableLicenses, function(license) {
            return angular.extend(
                  createLicenseItem(license, validationResult), {
                     parentId: null
                  });
         }));

         var allItems = [];
         while (items.length > 0) {
            var item = items.shift();

            if (item._children) {
               /* jshint loopfunc: true */
               _.each(item._children, function (child) {
                  child.parentId = item.id;
               });

               Array.prototype.push.apply(items, item._children);
               delete item._children;
            }

            allItems.push(item);
         }

         return allItems;
      }

      function createLicenseItem(license /*License*/,
            validationResult /*AssetAssignLicenseValidationResult*/) {
         var assetAssignedLicenseUri =
               licenseCommonUtil.getLicenseUri(validationResult.assets[0].license);

         var icon = (license.uri === assetAssignedLicenseUri) ?
               "vx-icon-licenseAssigned" : "vx-icon-license";
         var licenseKey = (license.isEvaluation || _.isEmpty(license.serialKeys)) ?
               '--' : license.serialKeys[0];

         var licenseGridItem = {
            id: license.uri,
            _license: license,
            name: license.name,
            icon: icon,
            licenseKey: licenseKey
         };

         var productGridItems =
               _.map(license.productRestrictions, function(productRestriction) {
                  return createProductItem(
                        license,
                        productRestriction.product,
                        validationResult
                  );
               });

         if (_.isEmpty(productGridItems)) {
            var usage = createLicenseItemUsage(
                  license,
                  null,
                  null,
                  validationResult.aggregatedUsages
            );

            productGridItems = [{
               id: license.uri,
               _license: license,
               product: '--',
               capacity: '--',
               usage: usage
            }];
         }

         licenseGridItem = angular.extend(licenseGridItem, productGridItems.shift());
         if (!licenseGridItem._children) {
            licenseGridItem._children = [];
         }
         licenseGridItem._children = licenseGridItem._children.concat(productGridItems);

         return licenseGridItem;
      }

      function createProductItem(license /*License*/,
            product /*Product*/,
            validationResult /*AssetAssignLicenseValidationResult*/) {

         var productGridItem = {
            id: (license.uri + ' _ ' + product.uri),
            _license: license,
            product: product.formattedName
         };

         var costUnitGridItems = _.map(product.costUnits, function(costUnit) {
            return createCostUnitItem(
                  license,
                  product,
                  costUnit,
                  validationResult
            );
         });

         if (_.isEmpty(costUnitGridItems)) {
            var usage = createLicenseItemUsage(
                  license,
                  product,
                  null,
                  validationResult.aggregatedUsages
            );

            costUnitGridItems = [{
               id: (license.uri + ' _ ' + product.uri),
               _license: license,
               capacity: '--',
               usage: usage
            }];
         }

         productGridItem = angular.extend(productGridItem, costUnitGridItems.shift());
         productGridItem._children = costUnitGridItems;

         return productGridItem;
      }

      function createCostUnitItem(license /*License*/,
            product /*Product*/,
            costUnit /*CostUnit*/,
            validationResult /*AssetAssignLicenseValidationResult*/) {

         var usage = createLicenseItemUsage(
               license,
               product,
               costUnit,
               validationResult.aggregatedUsages
         );

         var costUnitGridItem = {
            id: (license.uri + ' _ ' + product.uri + ' _ ' + costUnit.id),
            _license: license,
            capacity: costUnit.valueFormat.replace(/%s/g, costUnit.limit),
            usage: usage
         };

         return costUnitGridItem;
      }

      function createLicenseItemUsage(license /*License*/,
            product /*Product*/,
            costUnit /*CostUnit*/,
            assignLicenseAggregatedUsages/*AssignLicenseAggregatedUsages*/) {

         var initialUsageObject = {
            value: NaN,
            label: "--",
            indicateUsageChange: false,
            tooltip: null
         };

         var licenseRef = license.uri;
         var productRef = null;
         var costUnitId = null;

         if (product) {
            productRef = product.uri;

            if (costUnit) {
               costUnitId = costUnit.id;

               initialUsageObject.value = costUnit.usage;
               initialUsageObject.label =
                     costUnit.valueFormat.replace(/%s/g, costUnit.usage);
            }
         }

         var aggregatedCostUnitUsage = licenseCommonUtil.getAggregatedCostUnitUsage(
               licenseRef,
               productRef,
               costUnitId,
               assignLicenseAggregatedUsages);

         var usage = {
            current: angular.merge({}, initialUsageObject),
            selected: createSelectedDeselectedUsageObject(
                  initialUsageObject,
                  aggregatedCostUnitUsage,
                  true
            ),
            deselected: createSelectedDeselectedUsageObject(
                  initialUsageObject,
                  aggregatedCostUnitUsage,
                  false
            )
         };
         usage.current = usage.deselected;

         return usage;
      }

      function createSelectedDeselectedUsageObject(initialUsageObject,
            aggregatedCostUnitUsage /*AssignLicenseAggregatedCostUnitUsage*/,
            selected /*boolean*/) {

         if (!aggregatedCostUnitUsage) {
            return angular.merge({}, initialUsageObject);
         }

         var isUsageCalculationComplete = aggregatedCostUnitUsage.aggregationComplete;
         var value = null;
         if (selected) {
            value = aggregatedCostUnitUsage.usageWithAggregatedAssetUsage;
         } else {
            value = aggregatedCostUnitUsage.usageWithoutAggregatedAssetUsage;
            isUsageCalculationComplete = true;
         }
         var valueFormat = aggregatedCostUnitUsage.costUnit.valueFormat;
         var label = valueFormat.replace(/%s/g, value);

         var indicateUsageChange = true;
         var tooltip = null;

         var usageDifferenceValue = value - initialUsageObject.value;
         var usageDifferenceLabel = valueFormat.replace(/%s/g, usageDifferenceValue);

         if (usageDifferenceValue < 0) {
            tooltip = i18nService.getString(
                  'LicenseLibUi',
                  'assignLicense.usageRemoved',
                  initialUsageObject.label
            );
         } else if (usageDifferenceValue === 0 && !isUsageCalculationComplete) {
            tooltip = i18nService.getString(
                  'LicenseLibUi',
                  'assignLicense.unavailableUsageDataForAllAssets',
                  initialUsageObject.label
            );
         } else if (usageDifferenceValue > 0) {
            if (isUsageCalculationComplete) {
               tooltip = i18nService.getString(
                     'LicenseLibUi',
                     'assignLicense.usageAdded',
                     usageDifferenceLabel,
                     initialUsageObject.label
               );
            } else {
               tooltip = i18nService.getString(
                     'LicenseLibUi',
                     'assignLicense.unavailableUsageDataForSomeAssets',
                     usageDifferenceLabel,
                     initialUsageObject.label
               );
            }
         } else {
            indicateUsageChange = false;
         }

         return {
            value: value,
            label: label,
            indicateUsageChange: indicateUsageChange,
            tooltip: tooltip
         };
      }

      return {
         createLicenseTreeListItems: createLicenseTreeListItems
      };
   }
}());
