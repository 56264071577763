/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace dvs_ui {

   import DvPortgroupPortProperties = com.vmware.vsphere.client.dvpg.api.DvPortgroupPortProperties;
   export class DvPortgroupAdvancedPageComponent {

      public bindings: any;
      public controller: any;
      public templateUrl: string;

      constructor() {
         this.bindings = {
            model: "<",
            initialModel: "<",
            pageDirtyFlags: "<",
            commitCallbacks: "<"
         };

         this.controller = DvPortgroupAdvancedPageController;
         this.templateUrl = "dvs-ui/resources/dvs/dvpg/edit/pages/" +
               "dvPortgroupAdvancedPageTemplate.html";
      }
   }

   class DvPortgroupAdvancedPageController {

      static $inject = [
         "i18nService",
         "authorizationService",
         "navigation"
      ];

      public model: DvPortgroupPortProperties;

      public initialModel: DvPortgroupPortProperties;

      public hasPolicyPrivilege: boolean = true;

      public pageDirtyFlags: any;

      public commitCallbacks: any;

      private static readonly PRIVILEDGE_POLICY_OP: string = "DVPortgroup.PolicyOp";

      constructor(private i18nService: any,
                  private authorizationService: any,
                  private navigation: any) {
      }

      $onInit(): void {
         let dvportGroupUrn: string = this.navigation.getRoute().objectId;

         this.commitCallbacks.commitAdvancedPage = () => this.onCommit();

         this.authorizationService.checkPrivilege(dvportGroupUrn,
               DvPortgroupAdvancedPageController.PRIVILEDGE_POLICY_OP).then((isAuthorized: any) => {
            this.hasPolicyPrivilege = isAuthorized;
         });
      }

      private onCommit(): boolean {
         this.pageDirtyFlags.isAdvancedPageDirty =
               this.hasConfigureResetAtDisconnectChanged()
               || this.havePortPoliciesChanged();

         return true;
      }

      private hasConfigureResetAtDisconnectChanged() {
         return this.model.portConfigResetAtDisconnect
               !== this.initialModel.portConfigResetAtDisconnect;
      }

      private havePortPoliciesChanged() {
         if (!this.hasPolicyPrivilege) {
            return false;
         }

         return !_.isEqual(this.initialModel, this.model);
      }
   }

   angular.module("com.vmware.vsphere.client.dvs").component(
         "dvPortgroupAdvancedPage", new DvPortgroupAdvancedPageComponent());
}