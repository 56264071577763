/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace dvs_ui {

   export class NrpNavigatorPortgroupsViewComponent {

      public bindings: any;
      public controller: any;
      public template: string;

      constructor() {
         this.bindings = {};
         this.controller = NrpNavigatorViewPortgroupsController;
         this.template = `<nrp-portgroups-list [dvs-urn]="$ctrl.dvsUrn"
                                               [selected-nrp-key]="$ctrl.nrpKey"
                                               class="fill-parent dvs-nrp-navigator-view"></nrp-portgroups-list>`;
      }
   }

   class NrpNavigatorViewPortgroupsController {
      static readonly $inject = [
         "$rootScope",
         "nrpNavigatorService"
      ];

      public nrpKey: string;

      public dvsUrn: string;

      constructor(private $rootScope: any,
                  private nrpNavigatorService: NrpNavigatorService) {
         const objectId: string = this.$rootScope._route.objectId;

         this.nrpKey = this.nrpNavigatorService.getNrpKey(objectId);
         this.dvsUrn = this.nrpNavigatorService.getDvsUrn(objectId);
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .component("nrpNavigatorPortgroupsView", new NrpNavigatorPortgroupsViewComponent());
}