/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
(function() {
   'use strict';

   angular
         .module('com.vmware.vsphere.client.networkLibUi')
         .service('standardPortgroupPoliciesViewService', standardPortgroupPoliciesViewService);

   standardPortgroupPoliciesViewService.$inject = [
      'i18nService',
      'vxPropertyViewService',
      'networkUtil'
   ];

   function standardPortgroupPoliciesViewService (
         i18nService,
         vxPropertyViewService,
         networkUtil) {

      return {
         build: build
      };

      function build(portPolicy, builder) {
         if (!portPolicy) {
            return {};

         }

         var policiesBuilder = builder || vxPropertyViewService.createPropertyViewBuilder();
         var policiesCategory = policiesBuilder
               .category('policies')
               .title(i18nService.getString('NetworkUi', 'PortgroupSettingsView.policy'));

         networkUtil.fillPoliciesCategory(policiesCategory, portPolicy);

         if (!builder) {
            return policiesBuilder.build();
         }
      }
   }
})();
