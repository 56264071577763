namespace h5_vm {
   import VmCustomizationImportValidationResult =
         com.vmware.vsphere.client.vm.customizations.VmCustomizationImportValidationResult;
   import VmCustomizationImportSpec =
         com.vmware.vsphere.client.vm.customizations.VmCustomizationImportSpec;
   import VmCustomizationImportValidationSpec =
         com.vmware.vsphere.client.vm.customizations.VmCustomizationImportValidationSpec;
   import CustomizationSpecInfo = com.vmware.vim.binding.vim.CustomizationSpecInfo;
   import ManagedObjectReference = com.vmware.vim.binding.vmodl.ManagedObjectReference;
   import OperationResult = com.vmware.vise.core.model.OperationResult;

   export class ImportGosSpecService {
      private readonly IMPORT_GUEST_OS_SPEC_PRIVILEGES = [
         "VirtualMachine.Provisioning.ModifyCustSpecs",
         "VirtualMachine.Provisioning.ReadCustSpecs"];
      // Max size of 10 MB
      private readonly MAX_FILE_SIZE_BYTES: number = 10485760;

      private reader: FileReader | undefined;
      private xmlContent: string;
      private log: any;

      public static $inject = ["i18nService",
         "mutationService",
         "clarityModalService",
         "authorizationService",
         "defaultUriSchemeUtil",
         "gosSpecValidationService",
         "logService",
         "$q"];

      public constructor(private i18nService: any,
            private mutationService: any,
            private clarityModalService: any,
            private authorizationService: any,
            private defaultUriSchemeUtil: any,
            private gosSpecValidationService: GosSpecValidationService,
            private logService: any,
            private $q: angular.IQService) {

         this.log = logService("importGosSpecService");
      }

      private deferredSpecImport: angular.IDeferred<any>;

      public openImportModal(gosCustomizationSpecs: CustomizationSpecInfoCustom[]): angular.IPromise<any> {

         this.deferredSpecImport = this.$q.defer();

         let modalOptions: any = {
            title: this.i18nService.getString("VmUi", "guestOsCustomization.import"),
            contentTemplate: "vm-ui/resources/vm/guest-os-customization/import/import-gos-spec.view.html",
            defaultButton: "submit",
            dialogData: {
               allCustomizationSpecs: gosCustomizationSpecs
            },
            onCancel: (): void => {
               if (this.reader) {
                  this.reader.abort();
               }
               this.deferredSpecImport.reject();
            }
         };

         this.clarityModalService.openOkCancelModal(modalOptions);
         return this.deferredSpecImport.promise;
      }

      public validateForm(serviceGuid: string, file: any,
            name: string, gosCustomizationSpecs: CustomizationSpecInfoCustom[],
            vCenterName: string,
            customizationSpecManager: ManagedObjectReference): angular.IPromise<GosCustomizationImportValidationResult> {

         let errors: string[] = [];

         const nameErrorMessage = this.gosSpecValidationService
               .validateName(name, true, gosCustomizationSpecs, vCenterName);
         if (nameErrorMessage) {
            errors.push(nameErrorMessage);
         }

         let fileErrorMessage = this.validateFileMetadata(file);
         if (fileErrorMessage) {
            errors.push(fileErrorMessage);
         }

         let validationResult: GosCustomizationImportValidationResult = {
            result: null,
            errors: errors
         };

         if (errors.length > 0) {
            return this.$q.when(validationResult);
         }

         let requests: any = {
            privilegeCheck: this.checkVCenterPrivileges(serviceGuid),
            fileCheck: this.validateFile(customizationSpecManager)
         };

         return this.$q.all(requests).then((responses: any) => {
            let privilegeError: string = responses.privilegeCheck;
            if (privilegeError) {
               errors.push(privilegeError);
            }

            if (responses.fileCheck) {
               let fileErrors: string[] = responses.fileCheck.errors;
               if (fileErrors.length > 0) {
                  validationResult.errors = errors.concat(fileErrors);
               }

               if (errors.length === 0) {
                  return responses.fileCheck;
               }
            }


            return validationResult;
         });

      }

      public loadFile(file: File): angular.IPromise<string> {
         const deferredValidation = this.$q.defer();
         this.reader = new FileReader();

         this.reader.readAsText(file);

         this.reader.onload = (): void => {
            if (!this.reader) {
               return;
            }
            this.xmlContent = this.reader.result;
            deferredValidation.resolve(this.xmlContent);
         };

         this.reader.onerror = (): void => {
            let error: string =
                  this.i18nService.getString("VmUi", "importCustomizationSpec.errorImporting");
            deferredValidation.reject(error);
         };

         return deferredValidation.promise;
      }

      public retrieveNameAndDescription(xmlContent: string): any {
         let xmlDoc: any;
         try {
            xmlDoc = jQuery.parseXML(xmlContent);
         } catch (error) {
            this.log.log(error);
         }
         return {
            name: xmlDoc ? $(xmlDoc).find("info name").text() : "",
            description: xmlDoc ? $(xmlDoc).find("info description").text() : ""
         };
      }

      private validateFile(customizationSpecManager: ManagedObjectReference):
         angular.IPromise<GosCustomizationImportValidationResult> {
            let deferredValidation: any = this.$q.defer();
            if (this.xmlContent) {
               this.onFileLoaded(customizationSpecManager, deferredValidation);
            } else {
               let error: string =
                     this.i18nService.getString("VmUi", "importCustomizationSpec.errorImporting");
               let validationResult: GosCustomizationImportValidationResult = {
                  result: null,
                  errors: [error]
               };
               deferredValidation.resolve(validationResult);
            }

            return deferredValidation.promise;
         };

      private onFileLoaded(customizationSpecManager: ManagedObjectReference, deferredValidation: any): void {
         let validationResult: GosCustomizationImportValidationResult = {
            result: null,
            errors: []
         };

         let specXml: string = this.xmlContent;
         let validationSpec: VmCustomizationImportValidationSpec =
               new VmCustomizationImportValidationSpec();
         validationSpec.customizationSpecManager = customizationSpecManager;
         validationSpec.customizationSpecText = specXml;

         let custSpecManagerId: string =
               this.defaultUriSchemeUtil.getVsphereObjectId(customizationSpecManager);
         let validationPromise: angular.IPromise<any> = this.mutationService.validate(
               custSpecManagerId, validationSpec._type, validationSpec);

         validationPromise.then((response: any) => {
            if (!response || !response.result || response.error) {
               let error: string =
                     this.i18nService.getString("VmUi", "importCustomizationSpec.errorImporting");
               validationResult.errors = [error];
            } else {
               validationResult.result = response.result;
            }
            deferredValidation.resolve(validationResult);
         }).catch(() => {
            let error: string =
                  this.i18nService.getString("VmUi", "importCustomizationSpec.errorImporting");
            validationResult.errors = [error];
            deferredValidation.resolve(validationResult);
         });
      };

      public validateFileMetadata(file: any): string {
         if (!file) {
            return this.i18nService.getString("VmUi", "guestOsCustomization.import.noFileSelected");
         }

         if (!file.size || (file.size && file.size === 0)) {
            return this.i18nService.getString("VmUi", "guestOsCustomization.import.emptyFile");
         }

         if (file.size > this.MAX_FILE_SIZE_BYTES) {
            return this.i18nService.getString("VmUi", "guestOsCustomization.import.maxSizeExceededError");
         }

         return "";
      }

      private checkVCenterPrivileges(vcServerGuid: string): IPromise<string> {
         let vcRefId: string = this.defaultUriSchemeUtil.getRootFolder(vcServerGuid);
         return this.authorizationService.checkGrantedPrivileges(vcRefId, this.IMPORT_GUEST_OS_SPEC_PRIVILEGES)
               .then((allPrivStatuses: boolean[]) => {
                  let notValid: boolean = _.some(allPrivStatuses, (privilege: boolean) => {
                     return !privilege;
                  });

                  if (notValid) {
                     return this.i18nService.getString(
                           "VmUi", "customizationSpec.nameAndOsPage.noPrivileges");
                  }

                  return "";
               });
      }

      /**
       * From the response from the server a VmCustomizationImportSpec is created
       * changing it's name and description with the ones provided from the form
       * A mutation add operation is called with the new spec
       *
       * If the result's validationSucceeded flag is false
       */
      public parseValidationResponse(response: VmCustomizationImportValidationResult,
            customizationSpecManager: ManagedObjectReference,
            name: string, description: string) {
         let info: CustomizationSpecInfo = response.customizationSpecItem.info;
         info.name = name;
         info.description = description;
         let importSpec: VmCustomizationImportSpec = new VmCustomizationImportSpec();
         importSpec.customizationSpecManager = customizationSpecManager;
         importSpec.customizationSpecItem = response.customizationSpecItem;
         let operation: string = this.i18nService.getString("VmUi", "importRequest.Operation");
         if (response.validationSucceeded) {
            this.addSpec(importSpec, operation, false);
         } else {
            this.confirmChangeKey(importSpec, operation);
         }
      };

      /**
       * Displays an Ok/Cancel dialog that confirms the import and informs the user that
       * they should reset their password from th edit dialog
       */
      private confirmChangeKey(importSpec: VmCustomizationImportSpec, operation: string) {
         let modalOptions: any = {
            title: this.i18nService.getString("VmUi", "importCustomizationSpec.invalidKey.title"),
            message: this.i18nService.getString("VmUi", "importCustomizationSpec.invalidKey.text"),
            saveButtonLabel: this.i18nService.getString("Common", "alert.continue"),
            icon: null,
            clarityIcon: {
               shape: "warning",
               class: "is-warning",
               size: 48
            },
            submit: () => {
               this.addSpec(importSpec, operation, true);
            },
            onCancel: (): void => {
               this.deferredSpecImport.reject();
            },
            preserveNewlines: true
         };
         this.clarityModalService.openConfirmationModal(modalOptions);
      }

      /**
       * Calls the mutation operation that adds the imported spec
       * Refreshes the grid and sets the new item for preselection
       * If invokeEdit is set to true, the edit window will be open for the
       * newly imported spec
       */
      private addSpec(importSpec: VmCustomizationImportSpec,
            operation: string, invokeEdit: boolean): void {
         this.mutationService.add(importSpec._type, importSpec, operation)
               .then((result: OperationResult) => {
                  let response: GosCustomizationImportPromise = {
                     result: result,
                     invokeEdit: invokeEdit
                  };
                  this.deferredSpecImport.resolve(response);
               })
               .catch(() => {
                  this.deferredSpecImport.reject();
               });
      }
   }

   angular.module("com.vmware.vsphere.client.vm")
         .service("importGosSpecService", ImportGosSpecService);
}
