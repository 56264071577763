module cluster_ui {

   export class ClusterGuestMemoryUtilizationPortletController
         implements angular.IComponentController {

      public meters: any[] = [];
      public groupedMeters: any[] = [];
      public maxCapacityValue: string = "";
      public resourcesData: any = {};

      public static $inject = ["$scope", "dataService", "clusterUtilizationService",
         "$controller"];

      constructor(protected $scope: angular.IScope, private dataService: any,
                  protected clusterUtilizationService: ClusterUtilizationService,
                  public $controller: angular.IControllerService) {
         angular.extend(this, $controller("GenericPortletController", {$scope: $scope}));

         $scope.$watch("data", (data: any) => {
            if (data) {
               this.meters = this.clusterUtilizationService.getGuestMemoryMetersData(data);
               this.maxCapacityValue = data.guestMemorySizeFormatted;
               this.resourcesData =
                     this.clusterUtilizationService.buildGuestMemoryPropertyViewData(data);
               this.groupedMeters =
                     this.clusterUtilizationService.getGuestMemoryGroupedMetersData(data);
            }
         });
      }
   }

   angular.module("com.vmware.vsphere.client.cluster")
         .controller("ClusterGuestMemoryUtilizationPortletController",
               ClusterGuestMemoryUtilizationPortletController);
}
