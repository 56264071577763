/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace dvs_ui {

   import IPromise = angular.IPromise;
   import DvsHealthCheckDetailsData =
         com.vmware.vsphere.client.h5.network.dvs.healthcheck.model.DvsHealthCheckDetailsData;
   import DvsHealthCheckDetailsItemData =
         com.vmware.vsphere.client.h5.network.dvs.healthcheck.model.DvsHealthCheckDetailsItemData;

   export class DvsHealthCheckMonitorDetailsViewComponent {

      public bindings: any;
      public templateUrl: string;
      public controller: any;

      constructor() {
         this.bindings = {
            hostName: "<"
         };
         this.templateUrl =
               "dvs-ui/resources/dvs/healthcheck/monitor/components/dvsHealthCheckMonitorDetailsViewTemplate.html";
         this.controller = DvsHealthCheckMonitorDetailsViewController;
      }
   }

   class DvsHealthCheckMonitorDetailsViewController {

      static $inject = ["i18nService",
         "$rootScope",
         "$scope",
         "vuiConstants",
         "dataService"];

      public static readonly HEALTH_CHECK_DETAILS_DATA_PROPERTY: string =
            "dvsHealthCheckMonitor:dvsHealthCheckDetailsData";

      public hostName: string;

      public healthCheckDetailsData: DvsHealthCheckDetailsData;

      public i18n: any;

      public dvsUrn: string;

      public liveRefreshProperties: string[];

      public constructor(private i18nService: any,
                         private $rootScope: any,
                         private $scope: any,
                         private vuiConstants: any,
                         private dataService: any) {
         this.i18n = this.i18nService.getString;
      }

      public $onChanges(): void {
         if (!this.healthCheckDetailsData) {
            this.refresh(this.hostName);
         } else {
            this.$scope.healthCheckDetailsLoading = true;
            this.filterDataForHost(this.hostName);
            this.$scope.healthCheckDetailsLoading = false;
         }
      }

      public refresh(hostName: string): void {
         this.dvsUrn = this.$rootScope._route.objectId;
         this.$scope.healthCheckDetailsLoading = true;

         let requestPromise: IPromise<DvsHealthCheckDetailsData> =
               this.dataService.getProperties(this.dvsUrn,
                     [DvsHealthCheckMonitorDetailsViewController
                           .HEALTH_CHECK_DETAILS_DATA_PROPERTY]);

         requestPromise
               .then((healthCheckDetailsDataResult: any): void => {
                  if (healthCheckDetailsDataResult &&
                        healthCheckDetailsDataResult[
                              DvsHealthCheckMonitorDetailsViewController
                                    .HEALTH_CHECK_DETAILS_DATA_PROPERTY]) {

                     this.healthCheckDetailsData =
                           healthCheckDetailsDataResult[
                                 DvsHealthCheckMonitorDetailsViewController
                                       .HEALTH_CHECK_DETAILS_DATA_PROPERTY];

                     this.filterDataForHost(hostName);
                  }

               }).finally(() => {
            this.$scope.healthCheckDetailsLoading = false;
         });

         this.liveRefreshProperties = [
            "runtime"
         ];
      }

      private filterDataForHost(hostName: string) {

         let filteredHealthCheckVlanData: Array<DvsHealthCheckDetailsItemData> =
               new Array<DvsHealthCheckDetailsItemData>();

         let filteredHealthCheckMtuData: Array<DvsHealthCheckDetailsItemData> =
               new Array<DvsHealthCheckDetailsItemData>();

         for (let dataItem of this.healthCheckDetailsData.detailsItemsData) {

            if (dataItem !== null) {
               if (dataItem.hostName === hostName && dataItem.vlanStatus !== null) {
                  filteredHealthCheckVlanData.push(dataItem);
               }

               if (dataItem.hostName === hostName && dataItem.mtuStatus !== null) {
                  filteredHealthCheckMtuData.push(dataItem);
               }

               if (dataItem.hostName === hostName && dataItem.teamingStatus !== null) {
                  this.$scope.healthCheckIpHashData = dataItem;
               }
            }  else {
               this.$scope.healthCheckIpHashData = null;
            }
         }

         this.$scope.healthCheckVlanItemsData = filteredHealthCheckVlanData ?
               filteredHealthCheckVlanData : [];

         this.$scope.healthCheckMtuItemsData = filteredHealthCheckMtuData ?
               filteredHealthCheckMtuData : [];
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .component("dvsHealthCheckMonitorDetailsView",
               new DvsHealthCheckMonitorDetailsViewComponent());
}