/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
(function () {
   'use strict';

   /**
    *  Helper for opening an IPv6 address modal dialog.
    */
   angular
         .module('com.vmware.vsphere.client.network')
         .service('ipv6DialogService', ipv6DialogService);

   ipv6DialogService.$inject = [
         '$rootScope',
         'clarityModalService',
         'clarityConstants',
         'i18nService',
         'networkUiConstants',
         'ipParserService'];

   function ipv6DialogService($rootScope,
         clarityModalService,
         clarityConstants,
         i18nService,
         networkUiConstants,
         ipParserService) {

      // ======================= Public API ==========================

      return {
         openIpv6AddressesDialog: openIpv6AddressesDialog
      };

      //======================= Public Functions ==========================

      /**
       * Opens an OK/Cancel modal dialog in the application.
       */
      function openIpv6AddressesDialog(title, ipv6Details, usedAddressesInfo, okHandler) {
         var ipv6Address = {
            address: (ipv6Details && ipv6Details.address)
                  ? ipv6Details.address
                  : '',
            prefixLength: (ipv6Details && ipv6Details.prefixLength)
                  ? ipv6Details.prefixLength
                  : networkUiConstants.Ipv6PrefixLength.DEFAULT,
            defaultGateway: (ipv6Details && ipv6Details.defaultGateway)
                  ? ipv6Details.defaultGateway
                  : i18nService.getString('NetworkUi', 'NetworkUtil.noData')
         };

         var modalOptions = {
            title: title,
            defaultButton: 'submit',
            size: 'lg',
            alerts: [],
            dialogData: {
               newIpv6Address: ipv6Address
            },
            contentTemplate: 'network-ui/resources/network/views/host/ipv6Address/' +
                  'ipv6Dialog.html',
            onSubmit: onSubmit
         };

         clarityModalService.openOkCancelModal(modalOptions);

         function onSubmit() {
            var error;
            var hasIpAddressChanged = ipv6Details &&
                  ipv6Details.address !== modalOptions.dialogData.newIpv6Address.address;

            if (hasIpAddressChanged) {
               error = getIpv6ValidationError(
                     modalOptions.dialogData.newIpv6Address.address, usedAddressesInfo);
            }

            if (!error) {
               error = getPrefixLengthValidationError(
                     modalOptions.dialogData.newIpv6Address.prefixLength);
            }

            if (error) {
               showError(error, modalOptions);
               return false;
            }

            if (okHandler) {
               okHandler(modalOptions.dialogData.newIpv6Address);
            }

            return true;
         }
      }

      function getIpv6ValidationError(ipAddress, usedAddressesInfo) {
         var DEFAULT_UNICAST_ROUTE_ADDRESS = "::";
         var LOOPBACK_ADDRESS = "::1";

         if (!ipParserService.isIpv6AddressValid(ipAddress)
               || ipParserService.equalIpv6Addresses(ipAddress,
                     DEFAULT_UNICAST_ROUTE_ADDRESS)
               || ipParserService.equalIpv6Addresses(ipAddress,
                     LOOPBACK_ADDRESS)) {
            return i18nService.getString(
                  'NetworkUi',
                  'Ipv6AddressPage.invalidIpAddressError');
         } else if (someIpv6(ipAddress, usedAddressesInfo.vmknicAddresses)) {
            return i18nService.getString(
                  'NetworkUi',
                  'Ipv6AddressPage.duplicateIpv6AddressNewVnicError',
                  ipAddress);
         } else {
            var vmknicWithSameIpv6 = getHostVmknicWithDuplicateIpv6(ipAddress,
                  usedAddressesInfo.hostAddresses);
            // There is nic with the same IPv6 on the same host
            if (vmknicWithSameIpv6 !== null) {
               return i18nService.getString(
                     'NetworkUi',
                     'Ipv6AddressPage.duplicateIpv6AddressDvsError',
                     ipAddress,
                     vmknicWithSameIpv6);
            }
         }
      }

      function getPrefixLengthValidationError (prefixLength) {
         if (!ipParserService.isSubnetPrefixValid(prefixLength)) {
            return i18nService.getString(
                  'NetworkUi',
                  'Ipv6AddressPage.invalidPrefixError');
         }
      }

      function showError(errorMessage, modalOptions) {
         modalOptions.alerts = [{
            text: errorMessage,
            type: clarityConstants.notifications.type.ERROR
         }];
      }

      function getHostVmknicWithDuplicateIpv6(ipv6Address, hostAddresses) {
         var vmknicWithSameIp = null;
         _.forEach(hostAddresses, function (vmknicAddresses, vmknic) {
            if (someIpv6(ipv6Address, vmknicAddresses)) {
               vmknicWithSameIp = vmknic;
            }
         });

         return vmknicWithSameIp;
      }

      function someIpv6(ipv6Address, vmknicAddresses) {
         return _.some(vmknicAddresses, function (address) {
            return ipParserService.equalIpv6Addresses(address, ipv6Address);
         });
      }
   }
})();
