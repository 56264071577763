module ds_cluster_ui {

   import IComponentController = angular.IComponentController;

   class DsClusterServicesPortletController implements IComponentController {

      public static $inject = ["dataService", "$rootScope", "dsClusterServicesAndResourcesService",
         "i18nService", "storageDrsDataRetrieval", "vxPropertyViewService", "$scope",
         "dsClusterConstants"];

      public objectId: string;
      public resourcesData: Object;
      public liveRefreshProperties: Array<string>;
      private sdrsEnabled: boolean;

      constructor(private dataService: any, private $rootScope: RootScopeRoute,
                  private dsClusterServicesAndResourcesService: DsClusterServicesAndResourcesService,
                  private i18nService: any, private storageDrsDataRetrieval: any,
                  private vxPropertyViewService: any, private $scope: any,
                  private dsClusterConstants: any) {
         this.objectId = this.$rootScope._route.objectId;
         this.liveRefreshProperties = [
            this.dsClusterConstants.liveRefreshProperties.STORAGE_DRS_CONFIG,
            this.dsClusterConstants.liveRefreshProperties.FAULT,
            this.dsClusterConstants.liveRefreshProperties.RECOMMENDATION
         ];
         this.getData();
      }

      public getData(): void {
         this.storageDrsDataRetrieval.retrievePortletData(this.objectId).then(
               (sdrsViewModelData: any) => {
                  this.sdrsEnabled = sdrsViewModelData.enabled;
                  this.resourcesData = this.dsClusterServicesAndResourcesService
                        .buildServicesPropertyViewData(sdrsViewModelData, this.objectId,
                              this.$scope._route.navigator);
               }
         );
      }
   }

   angular.module("com.vmware.vsphere.client.dsCluster")
         .controller("DsClusterServicesPortletController",
               DsClusterServicesPortletController);
}
