/* Copyright 2018 VMware, Inc. All rights reserved. -- VMware Confidential */
module host_ui {
   /**
    * A "builder" for the ScheduleTaskPageService.
    */
   export class AddHostScheduleTaskPageService {
      static $inject: string[] = [
         "i18nService",
         "wizardPageService"
      ];

      constructor(private i18nService: any,
                  private wizardPageService: any) {
      }

      public build(hostAdder: any, vuiWizardHelper: any) {
         return {
            title: this.i18nService.getString("HostUi", "addHostWizard.scheduleTaskPage.title"),
            contentUrl: "host-ui/resources/host/views/addHostWizard/scheduleTaskWizard/"
                  + "scheduleTaskPage/hostWizardScheduleTaskPage.html",
            onCommit: () => {
               return this.onCommit(hostAdder, vuiWizardHelper);
            }
         };
      }

      private onCommit(hostAdder: any, vuiWizardHelper: any): boolean {
         let recurrence: any = hostAdder.getScheduleTaskPageModel.mutationSpec.
               schedulingSpec.recurrence;
         let isFormStatusValid = hostAdder.getScheduleTaskPageModel.mutationSpec.
               schedulingSpec.isFormStatusValid;
         if (recurrence && isFormStatusValid) {
            hostAdder.setScheduleTaskPageModel(hostAdder.getScheduleTaskPageModel.mutationSpec);
            vuiWizardHelper.markPageComplete();
            return true;
         } else {
            vuiWizardHelper.markPageIncompleteWithError(this.i18nService.getString("HostUi",
                  "addHostWizard.scheduleTaskPage.invalidHostSchedulerError"));
            return false;
         }
      }
   } // class

   angular.module("com.vmware.vsphere.client.host").service(
         "addHostScheduleTaskPageService",
         AddHostScheduleTaskPageService);

} // namespace
