(function () {
   'use strict';

   /**
    * Service that provides DPM automation level related information.
    */
   angular.module('com.vmware.vsphere.client.cluster')
         .service('dpmAutomationLevelService', dpmAutomationLevelService);

   dpmAutomationLevelService.$inject = ['i18nService', 'drsConstants'];

   function dpmAutomationLevelService(i18nService, drsConstants) {

      return {
         getText: getText,
         getDescription: getDescription,
         getAutomationLevels: getAutomationLevels
      };

      function getAutomationLevels() {
         return Object.keys(drsConstants.dpmAutomationLevel).map(function (key){
             return buildAutomationLevel(key);
         });
      }

      function buildAutomationLevel(key) {
         var dpmEnabled = true;
         var automationLevel = drsConstants.dpmAutomationLevel[key];
         if (automationLevel === '') {
            dpmEnabled = false;
         }

         return {
            id: automationLevel,
            label: getText(dpmEnabled, automationLevel),
            description: getDescription(dpmEnabled, automationLevel)
         };
      }

      function getText(dpmEnabled, automationLevelKey) {
         if(!dpmEnabled) {
            return getString('drs.config.dpm.disabled');
         }

         switch (automationLevelKey) {
            case drsConstants.dpmAutomationLevel.MANUAL:
                  return getString('drs.config.dpm.automationLevel.manual');
            case drsConstants.dpmAutomationLevel.AUTOMATED:
               return getString('drs.config.dpm.automationLevel.automated');
            default:
               return '';
         }
      }

      function getDescription(dpmEnabled, automationLevelKey) {
         if(!dpmEnabled) {
            return getString('drs.config.dpm.disabledDescription');
         }

         switch (automationLevelKey) {
            case drsConstants.dpmAutomationLevel.MANUAL:
               return getString('drs.config.dpm.automationLevel.manualDescription');
            case drsConstants.dpmAutomationLevel.AUTOMATED:
               return getString('drs.config.dpm.automationLevel.automatedDescription');
            default:
               return '';
         }
      }

      function getString(key) {
         return i18nService.getString('ClusterUi', key);
      }
   }
})();
