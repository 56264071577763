(function() {
   'use strict';
   angular.module('com.vmware.vsphere.client.host')
         .controller('authJoinDomainController',
               AuthJoinDomainController);

   AuthJoinDomainController.$inject = [
      '$scope',
      'i18nService',
      'authConstants',
      'dataService',
      'hostDataValidationService',
      'hostAuthService',
      'ipParserService'];

   function AuthJoinDomainController($scope,
         i18nService,
         authConstants,
         dataService,
         hostDataValidationService,
         hostAuthService,
         ipParserService) {
      var self = this;
      self.settingHeader = {
         title: i18nService.getString('HostUi', 'authJoinDomain.activeDirAuthChoicesBlockTitle')
      };

      var hostId = $scope.modalOptions.dialogData.availableTargets[0];
      $scope.loginType = authConstants.loginType;
      $scope.isProxyAuthenticationSupported = true;
      $scope.domainInfo = {
         domain: "",
         loginType: authConstants.loginType.LOGIN_USING_CREDENTIAL,
         username: "",
         password: "",
         ipAddress: ""
      };

      $scope.modalOptions.onSubmit = onSubmit;

      function onSubmit() {
         $scope.modalOptions.isAlertClosed = true;

         validateDomainInfo($scope.domainInfo);
         if ($scope.modalOptions.alerts.length === 0) {
            hostAuthService.joinDomain(hostId, $scope.domainInfo);
            return true;
         } else {
            $scope.modalOptions.isAlertClosed = false;
         }
      }

      fetchViewData();

      function fetchViewData() {
         dataService.getProperties(
               hostId,
               ["isProxyAuthenticationSupported"])
               .then(function(data) {
                  $scope.isProxyAuthenticationSupported = data.isProxyAuthenticationSupported;
               });
      }

      function validateDomainInfo(domainInfo) {
         $scope.modalOptions.alerts = [];
         validateDomainName(domainInfo.domain);

         if (domainInfo.loginType === authConstants.loginType.LOGIN_USING_CREDENTIAL) {
            validateUserName(domainInfo.username);
         } else {
            validateProxyIpAddress(domainInfo.ipAddress);
         }
      }

      function validateUserName(username) {
         if (!username) {
            $scope.modalOptions.alerts =
                  hostAuthService.addAlertMessage('authJoinDomain.error.usernameRequired',
                        $scope.modalOptions.alerts);
         }
      }

      function validateDomainName(domain) {
         if (!domain) {
            $scope.modalOptions.alerts =
                  hostAuthService.addAlertMessage('authJoinDomain.error.domainRequired',
                        $scope.modalOptions.alerts);
         } else  if (!hostDataValidationService.isDomainValid(domain)) {
            $scope.modalOptions.alerts =
                  hostAuthService.addAlertMessage('authJoinDomain.error.domainInvalid',
                        $scope.modalOptions.alerts);
         }
      }

      function validateProxyIpAddress(proxyIpAddress) {
         if (!proxyIpAddress) {
            $scope.modalOptions.alerts =
                  hostAuthService.addAlertMessage('authJoinDomain.error.camServerRequired',
                        $scope.modalOptions.alerts);
         } else if (!ipParserService.isAddressValid(proxyIpAddress)) {
            $scope.modalOptions.alerts =
                  hostAuthService.addAlertMessage('authJoinDomain.error.camServerInvalid',
                        $scope.modalOptions.alerts);
         }
      }
   }
})();
