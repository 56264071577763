namespace h5_vm {

   export class GosType {

      public readonly value: string;

      private static readonly WINDOWS_VALUE: string = "windows";
      private static readonly WINDOWS_CUSTOM_SYSPREP_VALUE: string =
            "windowsWithCustomSysPrep";
      private static readonly LINUX_VALUE: string = "linux";

      public static readonly WINDOWS: GosType = new GosType(GosType.WINDOWS_VALUE);

      public static readonly WINDOWS_CUSTOM_SYSPREP: GosType
            = new GosType(GosType.WINDOWS_CUSTOM_SYSPREP_VALUE);

      public static readonly LINUX: GosType = new GosType(GosType.LINUX_VALUE);

      /**
       * Used as default return value in fromString when invalid input is provided
       * @type {h5_vm.GosType}
       */
      public static readonly NONE: GosType = new GosType("");

      constructor(value: string) {
         this.value = value;
      }
   }
}
