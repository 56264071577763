/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
(function () {
   'use strict';

   angular
         .module('com.vmware.vsphere.client.network')
         .controller('PortGroupPropertiesPageController',
               PortGroupPropertiesPageController);

   PortGroupPropertiesPageController.$inject = [
      '$scope',
      '$q',
      'networkUtil',
      'i18nService',
      'portGroupPropertiesPageService',
      'editPortgroupService'];

   function PortGroupPropertiesPageController(
         $scope,
         $q,
         networkUtil,
         i18nService,
         portGroupPropertiesPageService,
         editPortgroupService) {

      $scope.nameValidator = {
         message: null,
         isVisible: false
      };

      $scope.onNameChange = function () {
         var message = portGroupPropertiesPageService.getInvalidNameError(
               $scope.manager.portgroupData.properties.portgroupName);
         setErrorTooltip(message);
      };

      $scope.manager.commitPageCallbacks.commitPortgroupPage = onCommit;

      var initialData = $scope.initialData.properties;

      function onCommit() {
         $scope.manager.pageDirtyFlags.isPorpertiesPageDirty =
               editPortgroupService.havePortgroupPropertiesChanged(initialData,
                     $scope.manager.portgroupData.properties);
         if (!$scope.manager.pageDirtyFlags.isPorpertiesPageDirty) {
            return true;
         }

         return validateName().then(function (error) {
            var errors = [];

            if (error) {
               errors.push(error);
               setErrorTooltip(error);
            }

            if (!networkUtil.isVlanIdValid($scope.manager.portgroupData.properties.portgroupVlanId)) {
               error = i18nService.getString('NetworkUi', 'VnicData.invalidVlanId');
               errors.push(error);
            }

            if (errors.length > 0) {
               $scope.manager.showDialogErrors(errors);

            }

            return errors.length === 0;
         });
      }

      function validateName() {
         var error = portGroupPropertiesPageService.getInvalidNameError(
               $scope.manager.portgroupData.properties.portgroupName);

         if (error) {
            return $q.resolve(error);
         }

         var props = $scope.manager.portgroupData.properties;

         if (props.oldPortgroupName === props.portgroupName) {
            return $q.resolve(null);
         }

         return portGroupPropertiesPageService.getDuplicateNameError(
               $scope.manager.portgroupData.hostId,
               $scope.manager.portgroupData.properties.portgroupName);
      }

      function setErrorTooltip(message) {
         $scope.nameValidator = {
            message: message,
            isVisible: message !== null
         };
      }

      return this;
   }
})();
