module ds_cluster_ui {

   export class StorageDrsAutomationService {

      public static $inject = ["dsClusterConstants", "storageDrsLocalizationService"];

      constructor(private dsClusterConstants: any,
            private storageDrsLocalizationService: any) { }

      public getSdrsAutomationLevels(sectionLabel: string): Object[] {
         return [{
            id: this.dsClusterConstants.SDRS_MANUAL,
            label: this.storageDrsLocalizationService
                  .getAutomationLevelTextLocalization(
                        this.dsClusterConstants.SDRS_MANUAL),
            description: this.storageDrsLocalizationService
                  .getAutomationLevelDescriptionLocalization(
                        this.dsClusterConstants.SDRS_MANUAL),
            sectionLabel: sectionLabel
         }, {
            id: this.dsClusterConstants.SDRS_FULLAUTO,
            label: this.storageDrsLocalizationService
                  .getAutomationLevelTextLocalization(
                        this.dsClusterConstants.SDRS_FULLAUTO),
            description: this.storageDrsLocalizationService
                  .getAutomationLevelDescriptionLocalization(
                        this.dsClusterConstants.SDRS_FULLAUTO),
            sectionLabel: sectionLabel
         }];
      }

      public getSdrsAutomationOverridesOptions(): Object[] {
         return [{
            id: null,
            label: this.storageDrsLocalizationService
                  .getAutomationLevelTextLocalization(null)
         }, {
            id: this.dsClusterConstants.SDRS_MANUAL,
            label: this.storageDrsLocalizationService
                  .getAutomationLevelTextLocalization(
                        this.dsClusterConstants.SDRS_MANUAL)
         }, {
            id: this.dsClusterConstants.SDRS_FULLAUTO,
            label: this.storageDrsLocalizationService
                  .getAutomationLevelTextLocalization(
                        this.dsClusterConstants.SDRS_FULLAUTO)
         }];
      }
   }

   angular.module("com.vmware.vsphere.client.dsCluster")
         .service("storageDrsAutomationService", StorageDrsAutomationService);
}
