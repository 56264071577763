module cluster_ui {

   import IController = angular.IController;

   class DrsCpuUtilizationComponentController implements IController {
      static $inject = ["$rootScope", "i18nService", "defaultUriSchemeUtil"];

      constructor(private $rootScope: any, private i18nService: any,
                  private defaultUriSchemeUtil: any) { }

      public noCpuOnVms: string =
            this.getString("drs.utilization.histogram.barNoCpuUsageLabel");
      public signPostParams: any = {};

      public goToSelectedHostLink(host: any): void {
         let selectedHostObjectId: string =
               this.defaultUriSchemeUtil.getVsphereObjectId(host.provider);
         this.$rootScope._navigateToObject(selectedHostObjectId);
      }

      public buildSignPostData(vm: any): void {
         let templateUrl: string =
               "cluster-ui/resources/cluster/views/monitor/drs/cpuVmBarTooltipContent.html";

         this.signPostParams = {
            templateUrl: templateUrl,
            title: vm.name,
            model: vm,
            width: 300
         };
      }

      public getString(key: string, ...params: any[]  ): string {
         return this.i18nService.getString("ClusterUi", key, params);
      }
   }

   class DrsCpuUtilizationComponent implements ng.IComponentOptions {
      public controller: any;
      public templateUrl: string;
      public bindings: any;

      constructor() {
         this.controller = DrsCpuUtilizationComponentController;
         this.templateUrl =
               "cluster-ui/resources/cluster/views/monitor/drs/drsCpuUtilizationComponent.html";
         this.bindings = {
            data: "<"
         };
      }
   }

   angular.module("com.vmware.vsphere.client.cluster").component(
         "drsCpuUtilizationComponent", new DrsCpuUtilizationComponent());
}
