/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace spbm_ui {

   import VmStorageProfilesData = com.vmware.vsphere.client.h5.spbm.data.VmStorageProfilesData;

   export class VmStoragePoliciesViewController {

      public i18n: any;

      public vmId: string;

      public watchedObjects: string[];

      public storagePolicies: VmStorageProfilesData[];

      public hasSpbmViewPrivilege: boolean;

      public loading = false;

      public masterDetailsViewContext: any;
      public detailsViewData:any;

      public headerOptions:any;

      public static $inject = ["navigation", "dataService", "i18nService", "$q"];

      constructor(private navigation: any,
                  private dataService: any,
                  private i18nService: any,
                  private $q: any) {

         this.vmId = this.navigation.getRoute().objectId;
         this.watchedObjects = [this.vmId];
         this.i18n = this.i18nService.getString;

         this.headerOptions = {
            objectId: this.vmId,
            actions: [{
               actionUid: "vsphere.core.pbm.storage.manageVmStorageProfilesAction",
               customLabel: this.i18nService.getString("SpbmUi", "manageVmProfiles.dialogTitle")
            }]
         };

         this.masterDetailsViewContext = {};

         this.detailsViewData = {
            tabsPromise: this.$q.when([
               {
                  name: this.i18nService.getString("SpbmUi", "h5.complianceFailuresList.complianceFailures"),
                  templateUrl: "spbm-ui/resources/spbm/views/vm/configurePolicies/VmComplianceFailuresView.html",
                  uid: "complianceFailures"
               }
            ])
         };
      }

      $onInit() {
         this.refresh();
      }

      public refresh = (): void => {
         this.loading = true;
         this.dataService.getProperties(this.vmId, ["storagePoliciesData"]).then((result: any) => {
            this.hasSpbmViewPrivilege = result.storagePoliciesData.hasSpbmViewPrivilege;
            this.storagePolicies = result.storagePoliciesData.profilesDataList;
            this.masterDetailsViewContext.storagePolicies = this.storagePolicies;
         })
         .finally(()=>{
            this.loading = false;
         });
      }
   }

   angular.module("com.vmware.vsphere.client.spbm")
         .component("vmStoragePolicies", {
            templateUrl: "spbm-ui/resources/spbm/views/vm/configurePolicies/VmStoragePoliciesView.html",
            controller: VmStoragePoliciesViewController
         });
}
