namespace h5_vm {
   class NvmeControllerService {
      public static $inject: string[] = ['deviceService', 'deviceClassLineageService', 'i18nService', 'deviceTypeConstants'];

      constructor(private deviceService: any,
                  private deviceClassLineageService: any,
                  private i18nService: any,
                  private deviceTypeConstants: any) {
      }

      public makeDefaultNVMeController(guestOsDescriptor: any,
                                       deviceOptions: any,
                                       virtualMachineDevices: any,
                                       powerState: string): {device: {_type: string, key: number}} {
         let supportedDiskControllerList: Array<any> = guestOsDescriptor.supportedDiskControllerList;
         let nvmeSupportIndex: number = this.getNVMeSupport(supportedDiskControllerList);
         if (nvmeSupportIndex < 0) {
            throw new Error(this.i18nService.getString('VmUi', 'VmDeviceManager.DeviceNotSupported'));
         }
         if(powerState === "poweredOn" && !this.getNVMeSupportOnPowerState(deviceOptions)) {
            throw new Error(this.i18nService.getString('VmUi', 'VmDeviceManager.HotAddNotSupported'));
         }
         let deviceControllerType: string = supportedDiskControllerList[nvmeSupportIndex];

         let availableBusNumber: number = this.findAvailableBusNumber(virtualMachineDevices);
         if (availableBusNumber >= 0) {
            var device = this.deviceService.makeController(deviceOptions, deviceControllerType, availableBusNumber);
            return {
               device: device
            };
         }
         else {
            throw new Error(this.i18nService.getString('VmUi','VmDeviceManager.DeviceLimitReached'));
         }
      }

      private getNVMeSupportOnPowerState(deviceOptions: any) : boolean {
          let nvmeDeviceOption : any = deviceOptions.find(function (this : any, deviceOption: any) {
              return deviceOption.type.name === "com.vmware.vim.binding.vim.vm.device.VirtualNVMEController";
          }, this);
          return nvmeDeviceOption.plugAndPlay;
      }

      private getNVMeSupport(supportedDiskControllerList: Array<any>): number {
         let index: number = _.findIndex(supportedDiskControllerList, (current: any) => {
            return this.deviceClassLineageService.isClassNameSubclassOf(current.typeClass, this.deviceTypeConstants.NVME_CONTROLLER);
         });
         return index;
      }

      private findAvailableBusNumber(virtualMachineDevices: any): number {
         let allNvmeControllers= virtualMachineDevices.existingDevicesOfType(this.deviceTypeConstants.NVME_CONTROLLER);
         allNvmeControllers = allNvmeControllers.concat(virtualMachineDevices.newDevicesOfType(this.deviceTypeConstants.NVME_CONTROLLER));
         for (let busNumber = 0; busNumber <= 3; busNumber++) {
            if (!this.findController(busNumber, allNvmeControllers)) {
               return busNumber;
            }
         }
         return -1;
      }

      private findController(busNumber: number, allNvmeControllers: Array<any>): number {
         return _.find(allNvmeControllers, (controller) => {
            return controller.getCurrentDevice().busNumber === busNumber;
         });
      }
   }

   angular.module('com.vmware.vsphere.client.vm')
         .service('nvmeControllerService', NvmeControllerService);
}