namespace h5_vm {

   import VirtualDiskOption$CompatibilityMode = com.vmware.vim.binding.vim.vm.device.VirtualDiskOption$CompatibilityMode;
   import VmSharingInfo  = h5_vm.VmSharingInfo;
   import VmSharesContext = h5_vm.VmSharesContext;
   import VmWorkflowMode = h5_vm.VmWorkflowMode;
   import VmDisplayMode = h5_vm.VmDisplayMode;
   import VmStorageProfileMetadata = h5_vm.VmStorageProfileMetadata;
   import VmStorageBrowserMetadata = h5_vm.VmStorageBrowserMetadata;
   import VmStorageProfile = h5_vm.VmStorageProfile;
   import IQService = angular.IQService;
   import VmHardwareRdmVirtualDiskService = vm_ui.VmHardwareRdmVirtualDiskService;
   import VirtualDisk = com.vmware.vim.binding.vim.vm.device.VirtualDisk;
   import StorageResourceManager$IOAllocationInfo = com.vmware.vim.binding.vim.StorageResourceManager$IOAllocationInfo;
   import SharesInfo = com.vmware.vim.binding.vim.SharesInfo;

   class VmHardwareRdmVirtualDiskCtrl implements ng.IComponentController {
      static $inject: string[] = [
         '$q',
         '$rootScope',
         '$scope',
         '$timeout',
         'i18nService',
         'virtualDiskSettingsFormService',
         'storageProfileService',
         'vmHardwareRdmVirtualDiskService',
         'vmHardwareVirtualDiskService',
         'datastoreService',
         'vmDeviceInfoService',
         'defaultUriSchemeUtil',
         'basicDiskUtilService',
         'vmCryptUtilService'
      ];

      inflatedDevice:                        any;
      vmStorageId:                           any;
      displayMode:                           VmDisplayMode;
      vmWorkflowMode:                        VmWorkflowMode;
      vmConfigContext:                       any;
      virtualMachineDevices:                 any;
      getStorageForDisk:                     any;
      vmAssignmentsProperties:               any;
      computeResourceId:                      string;
      removeCallback:                        Function;
      storageProfiles:                       any;
      isVmEncrypted:                         any;
      selectedStorageProfile:                any;
      sharesContext:                         VmSharesContext;
      deviceLabel:                           string;
      locationLabel:                         string;
      diskModeLabel:                         string;
      cacheReservationLabel:                 string;
      compatibilityModeLabel:                string;
      diskFile:                              string;
      displayDiskFile:                       boolean;
      sharingLabel:                          string;
      sharesLabel:                           string;
      removeLabel:                           string;
      revertRemovalLabel:                    string;
      iopsLimitLabel:                        string;
      diskFileLabel:                         string;
      deviceRemovedLabel:                    string;
      deleteFilesLabel:                      string;
      storageProfileLabel:                   string;
      deviceNodeLabel:                       string;
      physicalLunLabel:                      string;
      physicalLunInfo:                       any;
      destroyDisk:                           boolean;
      compatibilityModeInfo:                 any;
      diskModeInfo:                          any;
      iopsLimit:                             string | number;
      flashCacheInfo:                        any;
      vmId:                                  string;
      formKey:                               any;
      [key: string]:                         any;
      expanded:                              boolean;
      setStorageForDisk:                     Function;
      isGroupingHardDisks:                   boolean;
      selectedStorage:                       any;

      public i18n:                           Function;
      private isCreateMode:                  boolean;
      private settingsForm:                  any;
      private capacity:                      any;
      private vmStorageProfileMetadata:      VmStorageProfileMetadata;
      private sharingInfo:                   VmSharingInfo;
      private vmStorageBrowserMetadata:      VmStorageBrowserMetadata;
      private deviceNodeInfo:                any;
      private diskLocationError:             boolean = false;
      private diskProfileErrorConfig:        any;
      private isDiskProfileValid:            boolean = true;
      private isComponentInitiailized:       boolean = false;

      private storageLocatorItemsData: any;

      private readonly VIRTUAL_COMPATIBILITY_MODE: VirtualDiskOption$CompatibilityMode = "virtualMode";
      private readonly PHYSICAL_COMPATIBILITY_MODE: VirtualDiskOption$CompatibilityMode = "physicalMode";

      private readonly DELETE_FILE_FROM_DISK_OPERATION: string = "destroy";

      constructor(private $q: IQService,
                  private $rootScope: any,
                  private $scope: any,
                  private $timeout: any,
                  private i18nService: any,
                  private virtualDiskSettingsFormService: any,
                  private storageProfileService: any,
                  private vmHardwareRdmVirtualDiskService: VmHardwareRdmVirtualDiskService,
                  private vmHardwareVirtualDiskService: VmHardwareVirtualDiskService,
                  private datastoreService: any,
                  private vmDeviceInfoService: any,
                  private defaultUriSchemeUtil: any,
                  private basicDiskUtilService: any,
                  private vmCryptUtilService: VmCryptUtilService) {
         this.i18n = (key: string, bundle: string = "VmUi") =>
               i18nService.getString(bundle, key);
         this.subscribeToDeviceEvents();
      }

      isNewDevice() {
         // in clone workflow for some reason this call is done before onInit
         // of the current component is completed, as a result since
         // DiskLocationError is not yet evaluated the vm-hardware-virtual-disk-location
         // component does not show a validation error even there is such. So to
         // prevent this we are waiting this onInit to complete and then to allow
         // the location component to be rendered
         if (!this.isComponentInitiailized) {
            return false;
         }

         return this.inflatedDevice.isNew();
      }

      $onInit() {
         this.buildSettingsForm().then(() => {

            this.setStorageProfileMetadata();
            this.setSharingInfo();
            this.setLocationBrowserMetadata();
            this.setSharesInfo();
            this.setDeviceNodeInfo();
            this.setCompatibilityMode();
            this.setDiskMode();
            this.setIopsLimit();
            this.setPhysicalLunInfo();
            this.setDiskFile();
            this.setFlashCacheInfo();
            this.setSelectedStorage();
            this.checkDiskLocationError();
            this.setupExpandChangeListener();

            this.deviceLabel = this.inflatedDevice.getCurrentDevice().deviceInfo.label;
            this.locationLabel = this.i18n("DiskPage.Location");
            this.diskFileLabel = this.i18n("DiskPage.DiskFile");
            this.sharingLabel = this.i18n("DiskPage.Sharing");
            this.sharesLabel = this.i18n("DiskPage.Shares");
            this.revertRemovalLabel = this.i18n("DiskPage.revertRemoval");
            this.removeLabel = this.i18n("DiskPage.remove");
            this.cacheReservationLabel = this.i18n("DiskPage.CacheReservation");
            this.iopsLimitLabel = this.i18n("DiskPage.LimitIOPs");
            this.deviceRemovedLabel = this.i18n("RemoveDevice.DeviceRemoved");
            this.deleteFilesLabel = this.i18n("DeviceDeletedControl.DeleteFiles");
            this.storageProfileLabel = this.i18n("DiskPage.Profile");
            this.physicalLunLabel = this.i18n("DiskPage.PhysicalLUN");
            this.diskModeLabel = this.i18n("DiskPage.DiskMode");
            this.compatibilityModeLabel = this.i18n("DiskPage.CompatibilityMode");
            this.deviceNodeLabel = this.i18n("DiskPage.VirtualDeviceNode");

            this.isCreateMode = (this.displayMode === VmDisplayMode.CreateMode);

            this.diskProfileErrorConfig = {
               message: this.i18n('DiskConfig.ErrorCrypt2RDM'),
               isVisible: false
            };
            if (this.vmStorageProfileMetadata) {
               this.onStorageProfileChange(this.vmStorageProfileMetadata.selectedProfile);
            } else {
               this.checkEncryptionWarning();
            }

            this.isComponentInitiailized = true;

            this.destroyDisk = this.inflatedDevice.getDiskFileOperation() ===
                  this.DELETE_FILE_FROM_DISK_OPERATION;
         });
      }

      private deviceEventCallback(event: any, device: any): void {
         if (!device) {
            return;
         }

         const type: string = device.deviceType;

         if (device.deviceKey === this.inflatedDevice.getKey()
               && this.inflatedDevice.isNew()) {
            this.$rootScope.$broadcast(this.vmHardwareVirtualDiskService.DISKS_UPDATED);
         }

         if (!this.vmHardwareRdmVirtualDiskService.isDiskController(type)) {
            return;
         }

         this.rebuildControllersDropdown();
      }

      private subscribeToDeviceEvents(): void {
         const callback = this.deviceEventCallback.bind(this);
         this.$scope.$on('deviceAdded', callback);
         this.$scope.$on('deviceRemoved', callback);
      }

      private rebuildControllersDropdown(): void {
         const devices = this.virtualMachineDevices.getAllDevicesNotMarkedForRemoval();
         const controllers = this.virtualDiskSettingsFormService.buildControllersDropdown(this.inflatedDevice, devices);
         this.settingsForm.controllers = controllers;
      }

      $onChanges(changes: any) {
         if (changes.vmConfigContext && changes.vmConfigContext.currentValue
               && this.vmConfigContext.vmProfile) {
            this.checkEncryptionWarning();
         }
      }

      get shouldDisplayStorageProfile(): boolean {
         return this.expanded && (this.inflatedDevice.isNew()
               || this.vmWorkflowMode === VmWorkflowMode.UpdateMode);
      }

      get shouldDisplayPhysicalLun(): boolean {
         return this.expanded && !!(this.physicalLunInfo && this.physicalLunInfo.name);
      }

      get shouldDisplayCompatibilityMode(): boolean {
         return this.expanded && !this.inflatedDevice.isCreatedFromExistingDisk();
      }

      get shouldDisplayDiskMode(): boolean {
         return this.expanded &&
               this.inflatedDevice.getCurrentDevice().backing.compatibilityMode === this.VIRTUAL_COMPATIBILITY_MODE;
      }

      get shouldDisplayFlashCache(): boolean {
         return this.expanded && this.flashCacheInfo && this.flashCacheInfo.vFlashSupportedOnHost;
      }

      get isCompatibilityModeEditable(): boolean {
         return this.inflatedDevice.isNew();
      }

      get isFlashCacheReservationDisabled(): boolean {
         return this.inflatedDevice.getCurrentDevice().backing.compatibilityMode === this.PHYSICAL_COMPATIBILITY_MODE
               || this.vmHardwareRdmVirtualDiskService.isEditFlashReadCacheDisabled(this.vmConfigContext.privileges, this.isNewDevice());
      }

      isEditIopsLimitDisabled() {
         return this.isSharesDisabled();
      }

      isSharesDisabled() {
         return this.vmHardwareRdmVirtualDiskService.isSharesOrLimitDisabled(this.vmConfigContext.privileges, this.isNewDevice());
      }

      isEncryptionProfileApplied() {
         return this.inflatedDevice.isEncryptionProfileApplied();
      }

      onLocationChange(selectedStorageProfile: VmStorageProfile, selectedStorageItem: any) {
         this.updateSelectedProfile(selectedStorageProfile);
         this.inflatedDevice.updateFileName("[" + selectedStorageItem.name + "]");
         this.inflatedDevice.updateDiskProfile(selectedStorageProfile);
         this.setStorageForDisk({
            storageInfo: selectedStorageItem,
            device: this.inflatedDevice.getCurrentDevice()
         });
         this.inflatedDevice.updateDatastoreMor(selectedStorageItem.storageObject.storageRef);
      }

      onDiskDistroyStatusChange() {
         this.vmHardwareRdmVirtualDiskService.onDiskDistroyStatusChange(
               this.destroyDisk,
               this.inflatedDevice);
      }

      isRemoveDisabled(): boolean {
         if (this.vmWorkflowMode === VmWorkflowMode.UpdateMode) {
            return this.vmHardwareRdmVirtualDiskService.isRemoveRdmDiskDisabled(this.vmConfigContext.privileges);
         }

         return false;
      }

      onRemove(): void {
         if (!this.isRemoveDisabled() && this.removeCallback) {
            this.removeCallback()(this.inflatedDevice);
         }
      };

      markedForRemoval(): boolean {
         return this.inflatedDevice.isMarkedForRemoval();
      }

      revertRemoval(): void {
         this.inflatedDevice.revertRemoval();
      }

      errorMessages(): any {
         return {};
      }

      validators(): Array<any> {
         return [
            {
               name: "non-zero",
               validate: (value: number) => value !== 0,
               message: this.i18n("DiskConfig.ErrorCapacityZero")
            }
         ];
      }

      private hasError(): boolean {
         let formName: string = this.getFormName();
         if (!_.isEmpty(this[formName])) {
            this.updateLocationValidity(formName);
            return this[formName].$invalid;
         }
         return false;
      }

      private expandedChanged(expanded: boolean) {
         if (this.expanded === expanded) {
            return;
         }

         this.expanded = expanded;

         if (this.expanded && this.isGroupingHardDisks) {
            //broadcast to all the other hard disks
            this.$rootScope.$broadcast(this.vmHardwareVirtualDiskService.DISK_STACK_EXPANDED, {
               deviceKey: this.inflatedDevice.getKey()
            });
         }
      }

      private setupExpandChangeListener() {
         this.$rootScope.$on(this.vmHardwareVirtualDiskService.DISK_STACK_EXPANDED, (event: any, key: any) => {
            if (this.inflatedDevice.getKey() === key.deviceKey) {
               //nothing to do, just return
               return;
            }
            this.expanded = false;
         });
      }

      private updateLocationValidity(formName: any) {
         this[formName].$setValidity('disk-location', !this.diskLocationError);
         this.updateFormValidity(this[formName]);
      }

      private isVmPoweredOn(): boolean {
         return this.vmHardwareRdmVirtualDiskService.isPoweredOn(this.vmConfigContext);
      }

      private isDiskModeDisabled(): boolean {
         return this.vmHardwareRdmVirtualDiskService
               .isDiskModeDisabled(this.inflatedDevice, this.vmWorkflowMode, this.vmConfigContext);
      }

      private getFormName(): string {
         this.formKey = this.inflatedDevice.getKey().toString().replace("-", "__");
         return `vmHardwareRdmVirtualDisk${this.formKey}`;
      }

      private onCompatibilityModeChange(compatiblityModeOption: VirtualDiskOption$CompatibilityMode) {
         this.inflatedDevice.updateCompatibilityMode(compatiblityModeOption);
      }

      private onStorageProfileChange(selectedProfile: any) {
         this.updateSelectedProfile(selectedProfile);
         this.inflatedDevice.updateDiskProfile(selectedProfile);
         this.checkEncryptionWarning();
         this.$rootScope.$broadcast(this.vmHardwareVirtualDiskService.DISKS_UPDATED);
      }

      private onSharingChange(sharing: any) {
         this.inflatedDevice.updateSharing(sharing.value);
      }

      private onFlashCacheReservationChange(reservationInMB: any) {
         this.inflatedDevice.updateFlashCacheReservation(reservationInMB);
      }

      private ensureIOAllocation(): void {
         let disk:VirtualDisk = this.inflatedDevice.getCurrentDevice();

         if (!disk.storageIOAllocation) {
            disk.storageIOAllocation = new StorageResourceManager$IOAllocationInfo();
         }

         if (!disk.storageIOAllocation.shares) {
            disk.storageIOAllocation.shares = new SharesInfo();
         }
         return;
      }

      private onSharesChange(shares: SharesInfo) {
         this.ensureIOAllocation();
         this.inflatedDevice.updateShares(shares);
      }

      private onIopsLimitChange(limit: any) {
         this.ensureIOAllocation();
         this.inflatedDevice.updateStorageIOAllocation(limit);
      }

      private onDiskControllerChange(selectedController: any,
                                     selectedControllerNode: any) {
         if (selectedControllerNode) {
            this.inflatedDevice.updateControllerAndNodeKeys(selectedController.getKey(), selectedControllerNode.key);
         }
      }

      private onDiskModeChange(diskMode: any) {
         this.inflatedDevice.updateDiskMode(diskMode);
      }

      private hasChanged(): boolean {
         return this.inflatedDevice.hasChanged();
      }

      private setFlashCacheInfo() {
         this.flashCacheInfo = this.vmHardwareRdmVirtualDiskService.getFlashCacheReservationInfo(
               this.inflatedDevice.getCurrentDevice(),
               this.vmConfigContext.environment.hostCapability.vFlashSupported);
      }

      private setSelectedStorage() {
         const device = this.inflatedDevice.getCurrentDevice();
         if (this.getStorageForDisk && this.inflatedDevice.isNew()) {
            const selectedStorageForDisk = this.getStorageForDisk({ diskKey: device.key });
            if (selectedStorageForDisk) {
               this.selectedStorage = {
                  key: selectedStorageForDisk.diskKey,
                  id: selectedStorageForDisk.dsId,
                  name: selectedStorageForDisk.dsName,
                  storageObject: selectedStorageForDisk.dsObject
               };
            }
         }
      }

      private setStorageProfileMetadata() {
         if (!this.settingsForm) {
            this.buildSettingsForm();
         }

         // set default if not set
         if (this.settingsForm.storageProfile && !this.settingsForm.storageProfile.selection) {
            this.settingsForm.storageProfile.options.some((option: any) => {
               if (option.id === "defaultId") {
                  this.settingsForm.storageProfile.selection = option;
                  return true;
               }
               return false;
            });
         }

         if (this.settingsForm.storageProfile) {
            this.vmStorageProfileMetadata = new VmStorageProfileMetadata(
                  this.settingsForm.storageProfile.options,
                  this.settingsForm.storageProfile.selection);
         }
      }

      private updateSelectedProfile(selectedProfile: any) {
         // TODO: ajaffer, what is a better way other than creating a new object?
         this.vmStorageProfileMetadata
            = new VmStorageProfileMetadata(
               this.vmStorageProfileMetadata.storageProfiles,
               selectedProfile);
      }

      private checkEncryptionWarning() {
         this.updateDiskProfileValidity(!this.isEncryptionProfileApplied());
      };

      private updateDiskProfileValidity(isValid: boolean) {
         this.isDiskProfileValid = isValid;
         if (this.diskProfileErrorConfig) {
            this.diskProfileErrorConfig.isVisible = !isValid;
         }

         let form = this[this.getFormName()];
         if (form) {
            var diskProfileElem = form['storage-profile'];
            if (diskProfileElem) {
               diskProfileElem.$invalid = !isValid;
            }
            form.$setValidity('diskProfile', isValid);
            this.updateFormValidity(form);
         }
      };

      private updateFormValidity(form: any) {
         form.$invalid = !this.isDiskProfileValid || !!this.diskLocationError;
      }

      private setIopsLimit() {
         this.iopsLimit = this.vmHardwareRdmVirtualDiskService.getIopsLimit(this.inflatedDevice);
      }

      private setLocationBrowserMetadata() {
         let storageData: any = {
            storageLocatorItemsData: this.storageLocatorItemsData
         };

         this.vmStorageBrowserMetadata = new VmStorageBrowserMetadata(
            storageData);
      }

      private checkDiskLocationError() {
         let datastoreInfo = this.datastoreService.findDatastore(
               this.vmStorageId,
               this.vmConfigContext.environment.configTarget);
         if(datastoreInfo === null) { //assume storage pod
            let rdmSupportedDatastore = _.filter(this.vmConfigContext.environment.configTarget.datastore, (ds: any) => {
               return ds.capability.rawDiskMappingsSupported;
            });

            let disksInStorage = _.filter(this.storageLocatorItemsData.datastoreItems, (ds: any) => {
               return this.defaultUriSchemeUtil.getVsphereObjectId(ds.parentStoragePod) === this.vmStorageId;
            });

            datastoreInfo = rdmSupportedDatastore[ _.findLastIndex(rdmSupportedDatastore, {
               name: disksInStorage[0].name
            })];
         }

         if(this.inflatedDevice.getCurrentDevice().key > 0 ||
               (datastoreInfo &&
                     datastoreInfo.capability.rawDiskMappingsSupported &&
                     this.inflatedDevice.getCurrentDevice().key < 0)) {
            this.diskLocationError = false;
         } else {
            this.diskLocationError = true;
         }
      }

      private setCompatibilityMode() {
         this.compatibilityModeInfo = this.vmHardwareRdmVirtualDiskService
               .getCompatibilityModeInfo(
                     this.vmConfigContext,
                     this.inflatedDevice,
                     this.virtualMachineDevices.getAllDevicesNotMarkedForRemoval());
      }

      private setDiskMode() {
         this.diskModeInfo = this.vmHardwareRdmVirtualDiskService
               .getDiskModeInfo(
                     this.vmConfigContext,
                     this.inflatedDevice,
                     this.virtualMachineDevices.getAllDevicesNotMarkedForRemoval());
      }

      private setSharingInfo() {
         if (!this.settingsForm) {
            this.buildSettingsForm();
         }

         this.sharingInfo = {
            sharingOptions:  this.settingsForm.sharing.options,
            selectedSharing: this.settingsForm.sharing.selection,
            enableSharing:   this.vmHardwareRdmVirtualDiskService
                                 .enableSharing(this.vmConfigContext, this.inflatedDevice)
         };
      }

      private setSharesInfo() {
         if (!this.settingsForm) {
            this.buildSettingsForm();
         }

         this.sharesContext = {
            sharesId:                 this.getFormName(),
            shareMin:                 this.settingsForm.shares.min,
            shareMax:                 this.settingsForm.shares.max,
            sharesInfo:               {
               level:                 this.settingsForm.shares.value.level,
               shares:                this.settingsForm.shares.value.shares,
            },
            sharesLevelPresetOptions: this.settingsForm.shares.presetOptions,
            showRecommendations:      false
         };
      }

      private setDeviceNodeInfo() {
         const formKey = this.inflatedDevice.getKey().toString().replace("-", "__");
         const controllerSelectorName = "editVirtualDeviceController" + formKey;
         const controllerNodeSelectorName = "editVirtualDeviceNode" + formKey;

         this.deviceNodeInfo = {
            inflatedDevice:             this.inflatedDevice,
            virtualMachineDevices:      this.virtualMachineDevices,
            controllerNodeSelectorName: controllerNodeSelectorName,
            controllerSelectorName:     controllerSelectorName,
            virtualDiskSettingsForm:    this.settingsForm,
            vmConfigContext:            this.vmConfigContext
         };
      }

      private setPhysicalLunInfo() {
         this.physicalLunInfo = {
            name: this.inflatedDevice.getCurrentDevice().backing.deviceName
         };
      }

      private setDiskFile() {
         let device = this.inflatedDevice.getCurrentDevice();
         if (device && device.backing && device.backing.fileName) {
            this.displayDiskFile = this.expanded && this.vmWorkflowMode !== VmWorkflowMode.CloneMode;
            this.diskFile = device.backing.fileName;
         }
      }

      private getDiskText() {
         if (!this.capacity) {
            return '';
         }

         let diskSize = this.basicDiskUtilService.determineDisplayDiskSize(this.capacity.valueInMB);
         return diskSize ?
               (this.basicDiskUtilService.roundToPrecision(diskSize.size, 2).toString() + ' ' + diskSize.unitText ) :
               '';
      };

      private getSelectedVirtualNodeText() {
         if (this.deviceNodeInfo && this.deviceNodeInfo.virtualDiskSettingsForm &&
               this.deviceNodeInfo.virtualDiskSettingsForm.controllers) {

            let rawController = this.deviceNodeInfo.virtualDiskSettingsForm.controllers.selection.getCurrentDevice();

            if (rawController) {
               return this.vmDeviceInfoService.getControllerAndNodeLabelText(
                     rawController._type, rawController.busNumber, this.inflatedDevice.getCurrentDevice().unitNumber);
            }
         }
         return '';
      }

      private buildSettingsForm() {
         return this.$q.all(this.getAllBackendStorageCallPromises()).then((results: any) => {

            this.storageProfiles = results.storageProfilesPromiseResult;
            this.vmAssignmentsProperties = results.vmAssignmentsPromiseResult || {};

            if (this.vmWorkflowMode === VmWorkflowMode.UpdateMode
                  && this.vmAssignmentsProperties
                  && this.vmAssignmentsProperties.vmStorageProfileAssignments
                  && this.storageProfiles
                  && !this.inflatedDevice.isNew()) {

               let profile: any = this.storageProfileService.makeProfileFromDevice(
                     this.inflatedDevice.getCurrentDeviceSpec().device,
                     this.storageProfiles,
                     this.vmAssignmentsProperties.vmStorageProfileAssignments);
               this.inflatedDevice.setOriginalProfile(profile);
            }
            this.diskLocationError = false;
            this.settingsForm = this.vmHardwareRdmVirtualDiskService.buildSettingsForm(
               this.vmAssignmentsProperties.vmStorageProfileAssignments,
               this.vmWorkflowMode,
               this.vmConfigContext,
               this.inflatedDevice,
               this.vmStorageId,
               this.selectedStorageProfile,
               this.storageProfiles,
               this.virtualMachineDevices
            );

            this.capacity = {
               validUnits:          this.settingsForm.capacity.validUnits,
               maxInMB:             this.settingsForm.capacity.maxInMB,
               minInMB:             this.settingsForm.capacity.minInMB,
               valueInMB:           this.settingsForm.capacity.valueInMB,
               memoryUnitPreferred: this.settingsForm.capacity.memoryUnitPreferred.label
            };
         });
      }

      private getAllBackendStorageCallPromises(): any {
         if (this.vmAssignmentsProperties && this.vmWorkflowMode === VmWorkflowMode.UpdateMode) {
            return this.vmHardwareVirtualDiskService.getAllBackendStorageCallPromises
            (this.$q, this.vmAssignmentsProperties, this.storageProfiles);
         }
         return {
            storageProfilesPromiseResult: this.storageProfiles,
            vmAssignmentsPromiseResult: this.vmAssignmentsProperties
         };
      }

      public isExpandable() {
         if (!this.settingsForm) {
            return false;
         } else {
            return true;
         }
      };
   }

   export class VmHardwareRdmVirtualDisk implements ng.IComponentOptions {

      bindings: any;
      controller: any;
      templateUrl: string;

      constructor() {
         /**
          * vmWorkflowMode        Workflow mode [Create/Update]
          * displayMode           Display mode of Virtual RDM Disk [Create/Read/Update]
          * inflatedDevice        Virtual Disk
          * vmConfigContext       Virtual Machine configuration's representative object
          * virtualMachineDevices Wrapper service for virtual machine devices
          */
         this.bindings = {
            vmWorkflowMode: "<",
            displayMode: "<",
            inflatedDevice: "<",
            vmStorageId: "@",
            vmConfigContext: "<",
            virtualMachineDevices: "<",
            getStorageForDisk: "&",
            storageProfiles: "<",
            isVmEncrypted: "<",
            selectedStorageProfile: "<",
            storageLocatorItemsData: "<",
            vmAssignmentsProperties: "<",
            computeResourceId: '<',
            removeCallback: "&",
            vmId: "<",
            showStoragePods: "<",
            expanded: "=",
            vmStorageObject: "<",
            setStorageForDisk: "&",
            isGroupingHardDisks: "<",
         };
         this.controller = VmHardwareRdmVirtualDiskCtrl;
         this.templateUrl = "vm-ui/resources/vm/views/settings/vmHardwareSettings/" +
               "vmHardwareRDMVirtualDisk/vm-hardware-rdm-virtual-disk.html";
      }
   }

   angular.module("com.vmware.vsphere.client.vm")
      .component("vmHardwareRdmVirtualDisk", new VmHardwareRdmVirtualDisk());
}
