/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
(function () {
   'use strict';
   angular.module('com.vmware.vsphere.client.storage').controller(
         'HardwareAccelerationViewController', HardwareAccelerationViewController);

   HardwareAccelerationViewController.$inject = [
      '$scope',
      'vuiConstants',
      'i18nService',
      'dataService',
      'columnRenderersRegistry',
      'defaultUriSchemeUtil'
   ];

   function HardwareAccelerationViewController($scope, vuiConstants, i18nService, dataService, columnRenderersRegistry, defaultUriSchemeUtil) {
      var hostHardwareAccelerationProperty = 'storage:hostHardwareAccelerationForDatastore';
      var vStorageSupportLocalizationKeyMap = {
         'vStorageSupported': 'datastore.vStorage.supported',
         'vStorageUnsupported': 'datastore.vStorage.notSupported',
         'vStorageUnknown': 'datastore.vStorage.unknown',
         'SUPPORTED_ON_ALL_HOSTS': 'datastore.manage.hardwareAcceleration.vStorageSupportedOnAll',
         'SUPPORTED_ON_SOME_HOSTS': 'datastore.manage.hardwareAcceleration.vStorageSupportedOnSome',
         'NOT_SUPPORTED_ON_ANY_HOST': 'datastore.manage.hardwareAcceleration.vStorageSupportedNotSupported'
      };
      var self = this;
      self.datastoreId = $scope._route.objectId;
      self.hostConnectivityLoading = false;

      self.liveRefreshProperties = [
         'host'
      ];

      self.datagridOptions = {
         height: '100%',
         columnDefs: getColumnDefinitions(),
         sortMode: vuiConstants.grid.sortMode.SINGLE,
         selectionMode: vuiConstants.grid.selectionMode.SINGLE,
         data: [],
         selectedItems: [],
         resizable: false
      };

      self.headerOptions = {title: ''};

      self.refresh = function () {
         self.hostConnectivityLoading = true;
         dataService.getProperties(self.datastoreId, [hostHardwareAccelerationProperty])
               .then(function (response) {
                  if (response && response[hostHardwareAccelerationProperty]) {
                     self.datagridOptions.data = response[hostHardwareAccelerationProperty].hostHardwareAccelerationItems;
                     var localizationKey = vStorageSupportLocalizationKeyMap[response[hostHardwareAccelerationProperty].summary];
                     self.headerOptions.title = i18nService.getString('StorageUi', localizationKey);
                  }
               })
               .finally(function () {
                  self.hostConnectivityLoading = false;
               });
      };

      self.refresh();

      function getColumnDefinitions() {
         return [
            {
               displayName: i18nService.getString('StorageUi', 'datastore.manage.hardwareAcceleration.list.columnHeader.hostName'),
               field: 'hostName',
               width: '150px',
               template: function (dataItem) {
                  var objectLinkRenderer = columnRenderersRegistry.getColumnRenderer('object-link');
                  var modifiedData = {
                     hostName: dataItem.hostName,
                     hostId: defaultUriSchemeUtil.getVsphereObjectId(dataItem.hostRef)
                  };
                  return objectLinkRenderer(['hostId', 'hostName', 'vsphere-icon-host'], modifiedData);
               }
            },
            {
               displayName: i18nService.getString('StorageUi', 'datastore.manage.hardwareAcceleration.list.columnHeader.hardwareAcceleration'),
               field: 'hardwareAccelerationSupport',
               width: '150px',
               template: function (dataItem) {
                  var localizationKey = vStorageSupportLocalizationKeyMap[dataItem.hardwareAccelerationSupport];
                  return i18nService.getString('StorageUi', localizationKey);
               }
            }
         ];
      }
   }
})();