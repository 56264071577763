namespace h5_vm {
   import IController = angular.IComponentController;

   class VmProvisioningCustomizeTemplateController implements IController {
      static $inject: string[] = ["$scope", "vmProvisioningCustomizeTemplatePageModel"];

      private customizeTemplateParams: any;
      private ipAllocationPolicy: any;
      private pageModel: any;
      private form: any;

      constructor(private $scope: any, private vmProvisioningCustomizeTemplatePageModel: any) {
         this.pageModel = this.$scope.vmProvisioningCustomizeTemplatePageModel;

         this.pageModel.wizardViewData = this.$scope.wizardViewData;
         this.pageModel.vmParams = this.$scope.vmParams;
         this.$scope.vuiWizard.currentPage.disableNext = () => {
            return !this.form.valid;
         };
      }

      public $onInit() {
         this.customizeTemplateParams = this.pageModel.customizeTemplateParams;
         this.ipAllocationPolicy = this.pageModel.ipAllocationPolicyParams;
         this.form = this.pageModel.form;
      }

      onStatusChange(componentStatus: any): void {
         this.form.valid = componentStatus.valid;
         this.form.customizeTemplateParams = componentStatus.result;
      }
   }

   angular.module("com.vmware.vsphere.client.vm")
      .controller("vmProvisioningCustomizeTemplateController", VmProvisioningCustomizeTemplateController);
}
