/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace dvs_ui {

   import DvsSystemTrafficViewData =
         com.vmware.vsphere.client.h5.network.dvs.resmgmt.systemtraffic.model.DvsSystemTrafficViewData;

   export class DvsSystemTrafficViewComponent {

      public bindings: any;
      public controller: any;
      public templateUrl: string;

      constructor() {
         this.bindings = {};

         this.controller = DvsSystemTrafficViewComponentController;

         this.templateUrl =
               "dvs-ui/resources/dvs/resmgmt/systemtraffic/views/dvsSystemTrafficViewComponentTemplate.html";
      }
   }

   class DvsSystemTrafficViewComponentController {
      static $inject = [
            "$rootScope",
            "i18nService",
            "dataService",
            "vxPropertyViewService"
      ];

      static readonly DATA_PROPERTY: string =
            "dvsresmgmt:systemTrafficViewData";

      public dvsUrn: string;

      public data: DvsSystemTrafficViewData;

      public loading: boolean;

      public watchForObjects: string[];

      public liveRefreshProperties: string[];

      public alertText: string | null;

      constructor(private $rootScope: any,
                  private i18nService: any,
                  private dataService: any,
                  private vxPropertyViewService: any) {
         this.dvsUrn = $rootScope._route.objectId;
         this.watchForObjects = [this.dvsUrn];
         this.liveRefreshProperties = [
               "config.networkResourceManagementEnabled",
               "config.networkResourceManagementVersion",
               "config.infrastructureTrafficResourceConfig"
         ];

         this.data = new DvsSystemTrafficViewData();

         this.loading = false;
      }

      public $onInit(): void {
         this.loading = true;
         this.requestData();
      }

      public requestData(): void {
         this.dataService.getProperties(
               this.dvsUrn,
               [DvsSystemTrafficViewComponentController.DATA_PROPERTY])
               .then((response: any): void => {
                  if (response) {
                     this.data =
                           response[DvsSystemTrafficViewComponentController.DATA_PROPERTY];

                     this.alertText = null;
                     if (this.data) {
                        this.setAlertText();
                     }
                     this.loading = false;
                  }
               });
      }

      private setAlertText(): void {
         if (!this.data.overviewData || !this.data.meterData) {
            return;
         }

         if (this.data.overviewData.numPnics > 0) {
            let allowedReservation: number =
                  this.data.meterData.capacity * (this.data.meterData.ratio / 100);
            if (this.data.meterData.reserved > allowedReservation) {
               this.alertText =
                     this.i18nService.getString("DvsUi",
                           "systemTrafficView.error.reservationExceedsMaximum",
                           this.data.meterData.ratio);
            }
         } else {
            if (this.data.meterData.reserved > 0) {
               this.alertText =
                     this.i18nService.getString("DvsUi",
                           "systemTrafficView.error.cannotGuaranteeReservation");
            }
         }
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .component("dvsSystemTrafficView", new DvsSystemTrafficViewComponent());
}