/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace dvs_ui {

   import ProductSpec = com.vmware.vim.binding.vim.dvs.ProductSpec;
   import HostCompatibilityListItem = com.vmware.vsphere.client.h5.network.dvs.upgrade.model.HostCompatibilityListItem;
   import DvsResControlUpgradeStatistics = com.vmware.vsphere.client.dvs.api.spec.DvsResControlUpgradeStatistics;
   import ValidationCheckResult = com.vmware.vsphere.client.dvs.api.featureupgrade.ValidationCheckResult;
   import LacpGroupConfig = com.vmware.vim.binding.vim.dvs.VmwareDistributedVirtualSwitch$LacpGroupConfig;

   export class DvsUpgradeWizardModel {
      dvsId: string | null;
      dvsName: string;

      supportedVersionSpecs: ProductSpec[]; // contains all supported product spec
      supportedVersions: string[]; // contains only product spec versions used for upgrade
      selectedVersion: string = "";
      currentVersion: string = "";

      currentNiocVersion: number;
      niocUpgradeStatistics: DvsResControlUpgradeStatistics;
      niocPrerequisiteChecks: ValidationCheckResult[];
      migrateShares: boolean = false;

      isLacpConfigured: boolean;
      currentLacpVersion: string;
      lacpPrerequisiteChecks: ValidationCheckResult[];
      prerequisiteWarningShown: boolean = false;
      lag: LacpGroupConfig;

      isCompatibilityDataRetrievedForVersion: string = "";
      hostCompatibilityData: HostCompatibilityListItem[];

      isExplicitNiocUpgradeSupported: boolean = false;
      isExplicitLacpUpgradeSupported: boolean = false;

      isExplicitLacpUpgradeEnabled: boolean = true;
      isExplicitNiocUpgradeEnabled: boolean = true;
   }
}
