/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */

(function() {
   angular
      .module('com.vmware.vsphere.client.vm')
      .controller('snapshotDialogController', snapshotDialogController);

   snapshotDialogController.$inject = ['$scope', 'snapshotService', 'i18nService'];


   function snapshotDialogController($scope, snapshotService, i18nService) {
      var self = this;
      self.isMemoryDisabled = snapshotService.isTakingSnapshotWithMemoryDisabled($scope.modalOptions.dialogData);
      self.isQuiesceEnabled = snapshotService.isQuiesceEnabled($scope.modalOptions.dialogData) && self.isMemoryDisabled;
      self.includeMemory = snapshotService.isSnapshotTakenWithMemoryByDefault($scope.modalOptions.dialogData);
      self.quiesce = false;
      self.snapshotName = i18nService.getString('vmuiSnapshot', 'SnapshotManagerView.DefaultSnapshotName') + ' ' +
            new Date().toLocaleString();

      self.enableDisableMemoryQuiesce = function() {
         self.isQuiesceEnabled = snapshotService.isQuiesceEnabled($scope.modalOptions.dialogData) && !self.includeMemory;
         self.isMemoryDisabled = snapshotService.isTakingSnapshotWithMemoryDisabled($scope.modalOptions.dialogData) || self.quiesce;
      };

      self.createSnapshot = function() {
         var vmId = $scope.modalOptions.dialogData.objectId;
         snapshotService.takeSnapshot(vmId, self.snapshotName, self.snapshotDescription, self.includeMemory, self.quiesce);
         $scope.closeModal();
      };

      $scope.handleKeypress = function($event) {
         if ($event.which === 13) {
            $event.stopPropagation();
         }
      };
   }
})();
