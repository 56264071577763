/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */

/**
 * Manages the page states of the DVS Add and Manage Hosts wizard.
 */
namespace dvs_ui {

   export class DvsAddSpanSessionWizardFlowManager {

      public static $inject = [
         "pageStateManagementService",
         "vuiConstants"];

      public pages: WizardPage[];

      constructor(private pageStateManagementService: any,
                  private vuiConstants: any) {
      }

      public init(wizardScope: DvsAddSpanSessionWizardScope): void {
         this.pages = wizardScope.wizardConfig.pages;

         this.pageStateManagementService
               .registerWizardForDefaultStateManagementStrategy(wizardScope);

         wizardScope.$watchGroup([
                  "model.sessionType"],
               (newValues: any[]) => {
                  this.updatePagesVisibility.apply(this, newValues);
               });
      }

      private updatePagesVisibility(sessionType: string) {
         let isSourcePortsPageVisible: boolean =
               sessionType === DvsSpanSessionConstants.SESSION_TYPE.L3_SOURCE
                     || sessionType === DvsSpanSessionConstants.SESSION_TYPE.REMOTE_MIRROR_SOURCE
                     || sessionType === DvsSpanSessionConstants.SESSION_TYPE.DV_PORT_MIRROR;
         this.togglePageVisibility(DvsSpanSessionConstants.PAGE_ID.SELECT_SOURCE_PORTS,
               isSourcePortsPageVisible);

         let isSourceVlansPageVisible: boolean =
               sessionType === DvsSpanSessionConstants.SESSION_TYPE.REMOTE_MIRROR_DEST;
         this.togglePageVisibility(DvsSpanSessionConstants.PAGE_ID.SELECT_SOURCE_VLANS,
               isSourceVlansPageVisible);

         let isDestAddressesPageVisible: boolean =
               sessionType === DvsSpanSessionConstants.SESSION_TYPE.L3_SOURCE;
         this.togglePageVisibility(DvsSpanSessionConstants.PAGE_ID.SELECT_DESTINATION_ADDRESSES,
               isDestAddressesPageVisible);

         let isDestPortsPageVisible: boolean =
               sessionType === DvsSpanSessionConstants.SESSION_TYPE.DV_PORT_MIRROR
                     || sessionType === DvsSpanSessionConstants.SESSION_TYPE.REMOTE_MIRROR_DEST;
         this.togglePageVisibility(DvsSpanSessionConstants.PAGE_ID.SELECT_DESTINATION_PORTS,
               isDestPortsPageVisible);

         let isDestUplinksPageVisible: boolean =
               sessionType === DvsSpanSessionConstants.SESSION_TYPE.REMOTE_MIRROR_SOURCE;
         this.togglePageVisibility(DvsSpanSessionConstants.PAGE_ID.SELECT_DESTINATION_UPLINKS,
               isDestUplinksPageVisible);
      }

      private togglePageVisibility(pageId: string, isVisible: boolean) {
         let page = _.findWhere(this.pages, {id: pageId});
         if (isVisible) {
            // change the state to DISABLED only if the page is currently invisible.
            if (page.state === this.vuiConstants.wizard.pageState.SKIPPED) {
               page.state = this.vuiConstants.wizard.pageState.DISABLED;
            }
         } else {
            page.state = this.vuiConstants.wizard.pageState.SKIPPED;
         }
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .service("dvsAddSpanSessionWizardFlowManager", DvsAddSpanSessionWizardFlowManager);
}
