(function () {
   'use strict';

   angular.module('com.vmware.vsphere.client.vm').service('contentLibraryService', contentLibraryService);

   contentLibraryService.$inject = ['dataService', 'mutationService', 'defaultUriSchemeUtil', '$q', 'sourceTypeConstants', '$injector'];

   function contentLibraryService(dataService, mutationService, defaultUriSchemeUtil, $q, sourceTypeConstants, $injector) {
      var RESOURCE_POOL_VALIDATION_SPEC_CLASS = 'com.vmware.vsphere.client.provisioning.spec.DeployOnResourcePoolValidationSpec';
      var RESOURCE_POOL_FILEINFO_VALIDATION_SPEC_CLASS = 'com.vmware.vsphere.client.provisioning.spec.FileInfoValidationSpec';
      var NETWORK_VALIDATION_SPEC_CLASS = 'com.vmware.vsphere.client.provisioning.spec.NetworkConfigValidationSpec';
      var DEPLOY_VM_SPEC_CLASS = 'com.vmware.vsphere.client.provisioning.spec.DeployOnResourcePoolSpec';
      var STORAGE_VALIDATIONSPEC_CLASS = 'com.vmware.vsphere.client.provisioning.ovf.spec.StorageValidationSpec';
      var MANAGEDOBJECT_CLASS = 'com.vmware.vim.binding.vmodl.ManagedObjectReference';
      var LIBRARY_ITEM_BY_ITEM_ID = "contentLibraryItemByItemId";
      var LIBRARY_ITEM_BY_DATASTORE = "contentLibraryItemByDatastorePath";
      var LIBARAY_ITEM_DUMMY_ID = "urn:vapi:com.vmware.content.library.Item:6a0aea7f-2222-2222-2222-b7cf1ad937f3:6a0aea7f-2222-2222-2222-b7cf1ad937f3";
      var RESOURCE_POOL_DEPLOY_SESSION = 'com.vmware.vsphere.client.provisioning.ovf.impl.ResourcePoolDeploySession';
      var VM_CUSTOMIZATION_SPECIFICATION = 'com.vmware.vsphere.client.vm.VmCustomizationSpecification';
      var MESSAGE_VALIDATION_SPEC = 'com.vmware.vsphere.client.provisioning.spec.MessagesValidationSpec';
      return {
         getAllTemplates: getAllTemplates,
         getTemplateData: getTemplateData,
         deployVmFromLibraryTemplate: deployVmFromLibraryTemplate,
         getAndValidateResourcePool: getAndValidateResourcePool,
         validateComputeResourceSelection: validateComputeResourceSelection,
         getResourcePoolForComputeResource: getResourcePoolForComputeResource,
         validateStorageSelection: validateStorageSelection,
         getContentLibraryItemWithIso:getContentLibraryItemWithIso,
         getContentLibraryItemByDatastorePath:getContentLibraryItemByDatastorePath,
         getXmlFormattedCustomizationSpec: getXmlFormattedCustomizationSpec,

         // Constants
         SYSTEM_DISK_GROUP_ID: 'ngcArtificialSystemDiskGroup',
         SYSTEM_DISK_GROUP_LABEL: 'All other disks'
      };

      function getAllTemplates(hostId) {
         var serverGuid = defaultUriSchemeUtil.getPartsFromVsphereObjectId(hostId).serverGuid;
         var rootFolder = defaultUriSchemeUtil.createVmomiUri('Folder', 'group-d1', serverGuid);
         return dataService.getProperties(rootFolder, ['libraryTemplates']);
      }

      function getTemplateData(templateId) {
         return dataService.getProperties(templateId, [
            'com.vmware.content.library.ItemModel/name',
            'com.vmware.content.library.ItemModel/libraryName',
            'com.vmware.content.type.ovf.OvfTemplate/vmTemplate/osType',
            'com.vmware.content.type.ovf.OvfTemplate/vmTemplate/osDescription'
         ]);
      }

      function deployVmFromLibraryTemplate(computeResourceId, vmFolderId, vmName, deploymentContext, inOvfMode, localDeploySession) {
         var computeResource = defaultUriSchemeUtil.getManagedObjectReference(computeResourceId);
         var vmFolder = null;
         if (vmFolderId) {
            vmFolder = defaultUriSchemeUtil.getManagedObjectReference(vmFolderId);
         }


         if (computeResource.type === "ResourcePool" || computeResource.type === "VirtualApp") {
            dataService.getProperties(computeResourceId, ['computeResource'])
               .then(function(result) {
                  return dataService.getProperties(defaultUriSchemeUtil.getVsphereObjectId(result.computeResource), ['network']);
               })
               .then(function() {
                  deployVm(computeResource, vmFolder, computeResource, vmName, deploymentContext, inOvfMode, localDeploySession);
               });
         } else {
            dataService.getProperties(computeResourceId, ['network'])
               .then(function() {
                  return getResourcePoolForComputeResource(computeResourceId);
               })
               .then(function(result) {
                  deployVm(result.resourcePool, vmFolder, computeResource, vmName, deploymentContext, inOvfMode, localDeploySession);
               });
         }
      }

      function getAndValidateResourcePool(computeResourceId, vmFolderId, vmName, templateId, sourceType, localDeploySession) {
         var computeResource = defaultUriSchemeUtil.getManagedObjectReference(computeResourceId);
         var vmFolder = null;
         if (vmFolderId) {
            vmFolder = defaultUriSchemeUtil.getManagedObjectReference(vmFolderId);
         }

         if(computeResource.type === "ResourcePool" || computeResource.type === "VirtualApp"){
            return validateResourcePoolDeployment(computeResource, vmFolder, computeResource, vmName, templateId, sourceType, localDeploySession);
         } else {
            return getResourcePoolForComputeResource(computeResourceId)
               .then(function(response) {
                  return validateResourcePoolDeployment(response.resourcePool, vmFolder, computeResource, vmName, templateId, sourceType, localDeploySession);
               });
         }
      }

      function validateComputeResourceSelection(targetId){
         var deployOnResourcePoolSpec = {
            name: null,
            inOvfMode: false,
            inVmMode: true,
            parameters: null,
            vmFolder: null,
            host: null,
            resourcePool: null
         };
         return mutationService.validate(targetId, DEPLOY_VM_SPEC_CLASS, deployOnResourcePoolSpec);
      }

      function validateResourcePoolDeployment(resourcePool, vmFolder, computeResource, newVmName, templateId, sourceType, localDeploySession) {
         if (_.isEmpty(sourceType)) {
            sourceType = 'CONTENT_LIBRARY_SOURCE';
         }
         var isLocalOvfDeployment = sourceType === sourceTypeConstants.SOURCE_TYPE_PUSH;
         var deployOnResourcePoolValidationSpec = createDeploymentSpec(computeResource, newVmName, templateId, resourcePool, vmFolder, sourceType);

         if(computeResource.type === "HostSystem"){
            deployOnResourcePoolValidationSpec.host = _.extend({_type: MANAGEDOBJECT_CLASS}, computeResource);
         }

         var resourcePoolId = defaultUriSchemeUtil.getVsphereObjectId(resourcePool);
         if (!isLocalOvfDeployment) {
            return  deployOnResourcePoolValidation(resourcePoolId, deployOnResourcePoolValidationSpec);
         }
         return validateLocalDeploymentOnResourcePool(localDeploySession, deployOnResourcePoolValidationSpec, resourcePool);
      }

      function validateLocalDeploymentOnResourcePool(localDeploySession, deployOnResourcePoolValidationSpec, resourcePool) {
         var pushContentType = localDeploySession ? sourceTypeConstants.PUSH_TYPE_OVF : undefined;
         var pushOvfOption = localDeploySession ? buildOvfOption(localDeploySession) : undefined;
         deployOnResourcePoolValidationSpec.pushContentType = pushContentType;
         deployOnResourcePoolValidationSpec.pushOvfOption = pushOvfOption;
         var encodedDescriptorName = encodeURIComponent(localDeploySession.descriptor.name);
         deployOnResourcePoolValidationSpec.template = 'file:///' + encodedDescriptorName;
         var fileInfoValidaitionSpec = {
            deploymentSpec: deployOnResourcePoolValidationSpec
         };
         var resourcePoolId = defaultUriSchemeUtil.getVsphereObjectId(resourcePool);
         var sessionId = localDeploySession.sessionId;
         return mutationService.validate(
               resourcePoolId,
               RESOURCE_POOL_FILEINFO_VALIDATION_SPEC_CLASS,
               fileInfoValidaitionSpec)
            .then(function (response) {
               var fileInfos = response.result;
               var workflowManagerService = $injector.get('vscOvfWorkflowProxyService');
               uploadFiles(workflowManagerService, fileInfos, sessionId, deployOnResourcePoolValidationSpec);
               return workflowManagerService.uploadDescriptorWithAccessories$(sessionId)
                     .then(function() {
                        var session = workflowManagerService.getSession(sessionId);
                        // if there are .msgs files to be uploaded we need to upload those first
                        // before retrieving the OVF detailes information
                        if (session && !_.isEmpty(session.messages)) {
                           return validateMessages(resourcePoolId, sessionId, deployOnResourcePoolValidationSpec);
                        }
                        return  deployOnResourcePoolValidation(resourcePoolId, deployOnResourcePoolValidationSpec);
                     });
            });
      }

      function deployOnResourcePoolValidation(resourcePoolId, deployOnResourcePoolValidationSpec) {
         return mutationService.validate(
               resourcePoolId,
               RESOURCE_POOL_VALIDATION_SPEC_CLASS,
               deployOnResourcePoolValidationSpec);
      }

      function validateMessages(resourcePoolId, sessionId, deployOnResourcePoolValidationSpec) {
         var messagesValidationSpec = {
            commonContext: deployOnResourcePoolValidationSpec.commonContext,
            sourceType: deployOnResourcePoolValidationSpec.sourceType
         };

         return mutationService.validate(
               resourcePoolId,
               MESSAGE_VALIDATION_SPEC,
               messagesValidationSpec)
               .then(function (response) {
                  if (response && response.result) {
                     var fileInfos = response.result;
                     var workflowManagerService = $injector.get('vscOvfWorkflowProxyService');
                     uploadFiles(workflowManagerService, fileInfos, sessionId, deployOnResourcePoolValidationSpec);

                     return workflowManagerService.uploadMessages$(sessionId).then(function() {
                        return  deployOnResourcePoolValidation(resourcePoolId, deployOnResourcePoolValidationSpec);
                     });
                  }
                  return $q.reject();
               });
      }

      function uploadFiles(workflowManagerService, fileInfos, sessionId, deployOnResourcePoolValidationSpec) {

         deployOnResourcePoolValidationSpec.commonContext = fileInfos.newContext;
         var descriptorUri = fileInfos.descriptorURI;
         workflowManagerService.mapDescriptorUrl(sessionId, descriptorUri);

         var deployOvfSessionId = _.find(fileInfos.newContext, function(item) {
            return item._type === RESOURCE_POOL_DEPLOY_SESSION;
         });
         deployOvfSessionId = (deployOvfSessionId && deployOvfSessionId.sessionId) ? deployOvfSessionId.sessionId : '';

         workflowManagerService.mapDeployOvfSessionId(sessionId, deployOvfSessionId);

         var manifestUri = fileInfos.manifestURI;
         if (manifestUri && manifestUri.length !== 0) {
            workflowManagerService.mapManifestUrl(sessionId, manifestUri);
         }

         var certificateUri = fileInfos.certificateURI;
         if (certificateUri && certificateUri.length !== 0) {
            workflowManagerService.mapCertificateUrl(sessionId, certificateUri);
         }

         var messagesUri = fileInfos.messagesURI;
         if (messagesUri && !_.isEmpty(messagesUri)) {
            workflowManagerService.mapMessageUrls(sessionId, messagesUri);
         }
      }

      function createDeploymentSpec(computeResource, newVmName, templateId, resourcePool, vmFolder, sourceType) {
         return {
            commonContext: [{
               _type: 'com.vmware.vsphere.client.provisioning.workflow.Workflow',
               id: 'deployVmOnResourcePool',
               contextObject: computeResource
            }],
            name: newVmName,
            template: templateId,
            provisioningTarget: _.extend({_type: MANAGEDOBJECT_CLASS}, resourcePool),
            folder: vmFolder,
            sourceType: sourceType
         };
      }

      function buildOvfOption(localDeploySession) {
         if (!localDeploySession) {
            return sourceTypeConstants.PUSH_OVF_OPTION_NONE;
         }

         if (localDeploySession.certificate && localDeploySession.manifest) {
            return sourceTypeConstants.PUSH_OVF_OPTION_MFCERT;
         }

         if (localDeploySession.manifest) {
            return sourceTypeConstants.PUSH_OVF_OPTION_MF;
         }

         return sourceTypeConstants.PUSH_OVF_OPTION_NONE;
      }

      function validateNetworkConfig(resourcePool, deploymentContext, targetNetwork) {
         var networkConfigValidationSpec = {
            ipAllocationValidationSpec: {
               commonContext: deploymentContext,
               ovfIpAllocationParams: deploymentContext[3]
            },
            networkMappingsValidationSpec: {
               commonContext: deploymentContext,
               mappingSpec: {
                  networkMappings: [{
                     targetNetworkRef: targetNetwork
                  }]
               }
            }
         };

         var resourcePoolId = defaultUriSchemeUtil.getVsphereObjectId(resourcePool);
         return mutationService.validate(resourcePoolId, NETWORK_VALIDATION_SPEC_CLASS, networkConfigValidationSpec);
      }

      function validateStorageSelection(
            resourcePoolId, diskGroups, commonContext) {
         var DATASTORE_MAPPING_PARAMS_TYPE =
            'com.vmware.vcenter.ovf.DatastoreMappingParams';

         // We need the DatastoreMappingParams info only. The other info is irrelevant
         // and slows down validation due to transferring the info for thousands of
         // networks for example.
         var relevantCommonContext =
            extractRelevantFromCommomContext(commonContext, [DATASTORE_MAPPING_PARAMS_TYPE]);
         var storageValidationSpec = buildStorageValidationSpec(
               diskGroups, relevantCommonContext);

         return mutationService
            .validate(resourcePoolId, STORAGE_VALIDATIONSPEC_CLASS, storageValidationSpec)
            .then(function(response) {
            if (response.result && response.result.commonContext) {
               // Restore all the properties from the original common context,
               // overriding only the relevant property.
               response.result.commonContext = injectRelevantIntoCommomContext(
                  commonContext,
                  response.result.commonContext,
                  [DATASTORE_MAPPING_PARAMS_TYPE]);
            }
            return response;
         });
      }

      function isContextItemRelevant(contextItem, relevantTypes) {
         return _.find(relevantTypes, function(relevantType) {
            return relevantType === contextItem._type;
         });
      }

      function extractRelevantFromCommomContext(commonContext, relevantTypes) {
         return _.filter(commonContext, function(contextItem) {
            return isContextItemRelevant(contextItem, relevantTypes);
         });
      }

      function injectRelevantIntoCommomContext(commonContext, relevantContext, relevantTypes) {
         return _.map(commonContext, function(contextItem) {
            if (isContextItemRelevant(contextItem, relevantTypes)) {
               return _.find(relevantContext, function(relevantItem) {
                  return relevantItem._type === contextItem._type;
               });
            }
            return contextItem;
         });
      }

      function deployVm(resourcePool, vmFolder, computeResource, newVmName, deploymentContext, inOvfMode, localDeploySession) {
         var deployOnResourcePoolSpec = {
            name: newVmName,
            inOvfMode: _.isUndefined(inOvfMode) ? null : inOvfMode,
            inVmMode: true,
            parameters: deploymentContext,
            vmFolder: vmFolder,
            host: (computeResource.type === 'HostSystem') ? computeResource : null,
            resourcePool: resourcePool
         };

         mutationService.add(DEPLOY_VM_SPEC_CLASS, deployOnResourcePoolSpec).then(function(response) {
             if (localDeploySession) {
                if (response && response.result) {
                   var workflowManagerService = $injector.get('vscOvfWorkflowProxyService');
                   var sessionId = localDeploySession.sessionId;
                   var urls = [];
                   _.each(response.result.fileByUri, function(url, file) {
                      urls.push(url);
                   });

                   if (urls.length > 0) {
                      workflowManagerService.mapOtherUrls(sessionId, urls);
                      workflowManagerService.uploadOtherFiles$(sessionId);
                   }
                }
             }
         });
      }

      function getResourcePoolForComputeResource(computeResourceId) {
         var computeResourceType = defaultUriSchemeUtil.getEntityType(computeResourceId);
         var computeResource = defaultUriSchemeUtil.getManagedObjectReference(computeResourceId);
         if (computeResourceType === "ResourcePool" || computeResourceType === "VirtualApp") {
            return $q.when({resourcePool: computeResource});
         }
         return dataService.getProperties(computeResourceId, ['resourcePool']);
      }

      function buildStorageValidationSpec(diskGroups, commonContext) {
         _.each(diskGroups, function (diskGroup) {
            var newProvisionigType = convertProvisioningTypeForContentLibrary(
                  diskGroup.targetProvisioningType);
            diskGroup.targetProvisioningType = newProvisionigType || 'thick';
         });

         return {
            commonContext: commonContext,
            diskGroups: diskGroups
         };
      }

      function convertProvisioningTypeForContentLibrary(provisioningType){
         if (provisioningType === 'flat') {
            return 'thick';
         } else if (provisioningType === 'thick') {
            return 'eagerZeroedThick';
         }
         return provisioningType;
      }

      function getContentLibraryItemWithIso(contentLibraryIsoItem){
         return dataService.getProperties(contentLibraryIsoItem.id, [LIBRARY_ITEM_BY_ITEM_ID]);
      }

      function getContentLibraryItemByDatastorePath(datastorePath){
         return dataService.getProperties(LIBARAY_ITEM_DUMMY_ID , [LIBRARY_ITEM_BY_DATASTORE], {
            propertyParams: [{
               propertyName: LIBRARY_ITEM_BY_DATASTORE,
               parameterType: "java.lang.String",
               parameter: datastorePath
            }]
         });
      }

      function getXmlFormattedCustomizationSpec(vcenterFolderId, info, spec) {
         return mutationService.validate(
               vcenterFolderId,
               VM_CUSTOMIZATION_SPECIFICATION,
               {
                  info: info,
                  spec: spec
               });
      }
   }
}());
