namespace h5_vm {

   export class CrudGridController {
      public static $inject = ["$scope", "vuiConstants"];

      configuration: CrudGridConfiguration<any>;
      datagridOptions: any;

      constructor(private $scope: any,
                  private vuiConstants: any) {
      }

      $onInit(): void {
         this.datagridOptions = this.getGridOptions();
         this.updateActionBar();

         this.$scope.$watch(() => {
            return this.datagridOptions.selectedItems;
         }, (newSelection?: Array<any>,
             oldSelection?: Array<any>) => {
            this.onSelectionChanged.call(this, newSelection, oldSelection);
         });
      }

      private onSelectionChanged(newSelection?: Array<any>,
                                 oldSelection?: Array<any>): void {
         // old and new selection can be same objects (as in data), but different
         // references
         if (JSON.stringify(newSelection) === JSON.stringify(oldSelection)) {
            return;
         }

         if (!newSelection || newSelection.length === 0 || newSelection.length > 1) {
            this.updateActionBar();
         } else {
            this.updateActionBar(newSelection[0]);
         }
      }

      private updateActionBar(selectedObject?: any): void {
         this.datagridOptions.actionBarOptions =
               this.datagridOptions.actionBarOptions || {};
         this.datagridOptions.actionBarOptions.actions = [
            {
               enabled: true,
               onClick: (event: any, action: any) => {
                  this.onAddInternal();
               },
               iconClass: "vx-icon-addIcon",
               label: this.configuration.addLabel,
               tooltipText: this.configuration.addTooltip
            },
            {
               enabled: !!selectedObject,
               onClick: (event: any, action: any) => {
                  this.onEditInternal(selectedObject);
               },
               iconClass: "vx-icon-edit16x16",
               label: this.configuration.editLabel,
               tooltipText: this.configuration.editTooltip
            },
            {
               enabled: !!selectedObject,
               onClick: (event: any, action: any) => {
                  this.onDeleteInternal(selectedObject);
               },
               iconClass: "vx-icon-deleteIcon",
               label: this.configuration.deleteLabel,
               tooltipText: this.configuration.deleteTooltip
            }
         ];
      }

      private onAddInternal(): void {
         this.configuration.onAdd();
         this.refreshGrid();
      }

      private onEditInternal(selectedObject: any): void {
         this.configuration.onEdit(selectedObject);
         this.refreshGrid();
      }

      private onDeleteInternal(selectedObject: any): void {
         this.configuration.onDelete(selectedObject);
         this.refreshGrid();
      }

      private getGridOptions(): any {
         return {
            sortMode: this.vuiConstants.grid.sortMode.SINGLE,
            selectionMode: this.vuiConstants.grid.selectionMode.SINGLE,
            searchable: false,
            resizable: false,
            reorderable: false,
            data: this.configuration.data,
            columnDefs: this.configuration.columnDefinitions,
            selectedItems: [],
            height: this.configuration.height
         };
      }

      private refreshGrid() {
         this.datagridOptions.data = this.configuration.data.slice();
      }

   }

   export class CrudGridComponent implements angular.IComponentOptions {
      public controller: any;
      public templateUrl: string;
      public bindings: any;

      constructor() {
         this.controller = CrudGridController;
         this.templateUrl =
               "vm-ui/resources/vm/guest-os-customization/common/components/crud-grid/crud-grid.component.html";
         this.bindings = {
            configuration: "<"
         };
      }
   }

   angular
         .module("com.vmware.vsphere.client.vm")
         .component("crudGrid", new CrudGridComponent());
}



