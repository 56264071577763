angular.
module('com.vmware.vsphere.client.host').
controller('HostHardwareController', [
      '$scope', '$controller', 'featureFlagsService','dataService',
function($scope, $controller, featureFlagsService, dataService) {
   angular.extend(this, $controller('GenericPortletController', {$scope: $scope}));

   this.liveRefreshProperties = ['runtime.connectionState', 'datastore'];


    $scope.navigateToDatastores = function() {
        $scope._navigate('vsphere.core.host.relatedDatastoresTab', $scope._route.objectId);
    };

    $scope.navigateToNetworks = function() {
        $scope._navigate('vsphere.core.host.relatedNetworksTab', $scope._route.objectId);
    };

    var pmemData = this.pmemData = {
       enabled: false,
       loading: false,
       pmemUsageText: undefined,
       pmemSizeText: undefined
    };

    var closeDataWatch = $scope.$watch("data", function(newData) {
       // Data model is of type HostHardwareData which is an IdentifiableQueryBinding.
       // Dataservice will populate provider property with the ManagedObjectReference
       // of the HostSystem. This can leveraged to see when data is loaded.
       if (!newData.hasOwnProperty("provider")) {
          return;
       }
       if (isPmemFeatureEnabled() && newData.hasPmemStorage) {
          loadPmemData();
       }
    });

    $scope.$on('$destroy', function() {
       closeDataWatch();
    });

    function loadPmemData() {
       pmemData.enabled = true;
       pmemData.loading = true;
       dataService.getData(
             $scope._route.objectId,
             "com.vmware.vsphere.client.host.model.HostPmemData"
       ).then(function(hostPmemData /* HostPmemData */) {
          pmemData.pmemSizeText = hostPmemData.pmemSizeText;
          pmemData.pmemUsageText = hostPmemData.pmemUsageText;
          pmemData.pmemUsagePercentage = hostPmemData.pmemUsagePercentage;
          pmemData.loading = false;
       }, function() {
          pmemData.loading = false;
       });
    }

    function isPmemFeatureEnabled() {
       return (typeof featureFlagsService.PMemEnabled === "function") ?
             featureFlagsService.PMemEnabled() : false;
    }
}]);
