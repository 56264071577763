namespace h5_vm {
   import VmConfigContext = com.vmware.vsphere.client.vm.config.VmConfigContext;

   export class VmHardwareSerialPortService {
      static $inject: string[] = [ "i18nService", "clarityModalService", "defaultUriSchemeUtil" ];
      i18n: Function;

      constructor(private i18nService: any, private clarityModalService: any, private defaultUriSchemeUtil:any) {
         this.i18n = (key: string, bundle: string = "VmUi") => i18nService.getString(bundle, key);
      }

      public openFileBrowserModal(vmConfigContext: VmConfigContext, onSubmitCallback: (filePath: string) => any): void {
         const dsBrowserId:string = this.defaultUriSchemeUtil.getVsphereObjectId(
               vmConfigContext.environment.datastoreBrowser);
         const modalOptions: any = {
            title: this.i18n("SerialPortPage.FileBrowser.Title"),
            submitButtonTitle: this.i18n("SerialPortPage.FileBrowser.Save"),
            contentTemplate: "vm-ui/resources/vm/views/settings/vmHardwareSettings/vmHardwareSerialPort/vm-hardware-serial-port-file-browser.html",
            defaultButton: "submit",
            size: "lg",
            onSubmit: onSubmitCallback,
            onCancel: () => { },
            dialogData: {
               vmId: dsBrowserId,
               vmConfigContext: vmConfigContext
            }
         };

         this.clarityModalService.openOkCancelModal(modalOptions);
      }
   }

   angular.module("com.vmware.vsphere.client.vm")
         .service("vmHardwareSerialPortService", VmHardwareSerialPortService);
}
