/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace dvs_ui {

   export class DvsPropertiesComponent {

      public bindings: any;
      public controller: any;
      public templateUrl: string;

      constructor() {
         this.bindings = {};
         this.controller = DvsPropertiesController;
         this.templateUrl = "dvs-ui/resources/dvs/settings/dvsPropertiesTemplate.html";
      }
   }

   class DvsPropertiesController {

      static $inject = [
         "$rootScope",
         "i18nService",
         "networkUtil"
      ];

      private _actions: any;

      constructor(private $rootScope: any,
                  private i18nService: any,
                  private networkUtil: any) {
         this._actions = [{
               actionUid: "vsphere.core.dvs.editSettings",
               customLabel: this.i18nService.getString(
                     "DvsUi", "DvsPropertiesView.edit.label")
            }];
      }

      public get actions(): any {
         return this._actions;
      }

      public get dvsUri(): string {
         let currentRouteId: string = this.$rootScope._route.objectId;

         // Ignore if the route object is not a distributed switch
         if (this.networkUtil.isDistributedSwitch(currentRouteId)) {
            return currentRouteId;
         }
         return "";
      }

      public get title(): string {
         return this.i18nService.getString("NetworkUi", "VSwitchPropertiesView.title");
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
      .component("dvsProperties", new DvsPropertiesComponent());
}
