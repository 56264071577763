/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */

(function() {
   'use strict';

   angular
         .module('com.vmware.vsphere.client.vm')
         .controller('VmInstallToolsController', VmInstallToolsController);

   VmInstallToolsController.$inject = ['$scope', 'i18nService', 'mutationService',
      'managedByMessageBuilderService'];

   function VmInstallToolsController($scope, i18nService, mutationService, managedByMessageBuilderService) {
      var self = this;
      var i18n = _.bind(i18nService.getString, i18nService, 'VmUi');
      self.loading = true;
      managedByMessageBuilderService
            .buildWarningMessage([$scope.modalOptions.dialogData.objectId])
            .then(function(managedByMessage) {
               self.loading = false;
               self.header = i18n('VmToolsPopupForm.header');
               self.managedByMessage = managedByMessage;
               self.instruction = i18n('VmToolsPopupForm.singleInstall.instruction');
               self.mount = i18n('VmToolsPopupForm.singleInstall.mount');
               self.cancel = i18nService.getString('Common', 'cancelButtonLabel');
            });

      $scope.onSubmit = mountInstaller;

      function mountInstaller() {
         var objectId = $scope.modalOptions.dialogData.objectId;
         var propertyObjectType = 'com.vmware.vsphere.client.vm.tools.VmToolsInstallerSpec';
         var vmToolsInstallerSpec = {
            operation: 'mountInstaller'
         };
         mutationService.apply(objectId, propertyObjectType, vmToolsInstallerSpec);
         $scope.closeModal();

         return true;
      }
   }
})();
