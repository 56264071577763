(function() {
   'use strict';
   angular.module('com.vmware.vsphere.client.cluster')
         .controller('AdmissionControlFailoverHostsSelectorDialogController',
               AdmissionControlFailoverHostsSelectorDialogController);

   AdmissionControlFailoverHostsSelectorDialogController.$inject = ['$scope',
         'i18nService'];

   function AdmissionControlFailoverHostsSelectorDialogController($scope, i18nService) {

      var dialogData = $scope.modalOptions.dialogData;
      dialogData.selectedItems = [];

      var filter = "clusterObjectsExcludeObjectsFilter";

      //use different filter if there are no hosts to be excluded because
      //clusterObjectsExcludeObjectsFilter cannot be instantiated with an empty param
      if (dialogData.dedicatedFailoverHostIds.length === 0) {
         filter = "clusterObjectsFilter";
      }

      dialogData.objectSelectorConfig = this.config = {
         contextObject: dialogData.clusterId,
         multipleSelect: true,
         listTabConfig: {
            listConfig: [{
               label: i18nService.getString('Common', 'typeResource.hostPlural'),
               listViewId: "vsphere.core.host.list",
               dataModels: ["HostSystem"],
               filterId: filter,
               filterParams: dialogData.dedicatedFailoverHostIds
            }]
         },
         onSelectionChanged: function(selectedItems) {
            dialogData.selectedItems = selectedItems;
         }
      };
   }
})();
