/**
 * @name com.vmware.vsphere.client.cluster:evcConstants
 * @module com.vmware.vsphere.client.cluster
 *
 * @description
 *    The `evcConstants` service holds cluster EVC related constants.
 *
 *    List of available constants:
 *
 *    - cpuVendor
 *       - **`UNKNOWN`** : Unknown CPU vendor
 *       - **`AMD`** : AMD CPU vendor
 *       - **`INTEL`** : Intel CPU vendor
 */
angular.module('com.vmware.vsphere.client.cluster').constant('evcConstants', {
   cpuVendor : {
      UNKNOWN : 'unknown',
      AMD : 'amd',
      INTEL : 'intel'
   }
});
