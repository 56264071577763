module ds_cluster_ui {

   export class AddVmToVmOverridesManager {

      private selectedVms: any[];
      private sdrsAutomationLevel: any;
      private defaultSdrsAutomationLevel: boolean;
      private keepVmdkTogether: string;
      private targetUid: string;
      private clusterName: string;
      private spec: any;

      public static $inject = [];

      constructor() {
      }

      public setSpec(spec: any) {
         this.spec = spec;
      }

      public getSpec() {
         return this.spec;
      }

      public setClusterName(clusterName: string) {
         this.clusterName = clusterName;
      }

      public getClusterName() {
         return this.clusterName;
      }

      public setTargetUid(targetUid: string) {
         this.targetUid = targetUid;
      }

      public getTargetUid() {
         return this.targetUid;
      }

      public setSelectedVms(selectedVms: any[]): void {
         this.selectedVms = selectedVms;
      }

      public getSelectedVms(): any[] {
         return this.selectedVms;
      }

      public setSdrsAutomationLevel(sdrsAutomationLevel: any): void {
         this.sdrsAutomationLevel = sdrsAutomationLevel;
      }

      public getSdrdsAutomationLevel(): any {
         return this.sdrsAutomationLevel;
      }

      public setDefaultSdrsAutomationLevel(defaultSdrsAutomationLevel: boolean): void {
         this.defaultSdrsAutomationLevel = defaultSdrsAutomationLevel;
      }

      public isDefaultSdrsAutomationLevelSelected(): boolean {
         return this.defaultSdrsAutomationLevel;
      }

      public setKeepVmdkTogether(keepVmdkTogether: string): void {
         this.keepVmdkTogether = keepVmdkTogether;
      }

      public getKeepVmdkTogether(): string {
         return this.keepVmdkTogether;
      }

   }

   angular.module("com.vmware.vsphere.client.dsCluster")
         .factory("AddVmToVmOverridesManager",
               [
                  function () {
                     return Function.prototype.bind.apply(
                           AddVmToVmOverridesManager,
                           [undefined]
                     );
                  }
               ]);
}
