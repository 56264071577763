namespace h5_vm {

import SelectionValidationResult = h5_vm.SelectionValidationResult;
import CalculateProvisioningCostSpec = com.vmware.vsphere.client.spbm.spec.CalculateProvisioningCostSpec;
import ProfileId = com.vmware.vim.binding.pbm.profile.ProfileId;
import VsanCreateCostData = com.vmware.vsphere.client.vsancommon.data.VsanCreateCostData;

declare const angular: angular.IAngularStatic;

export interface ValidationResult {
   compatibilityMessages: any[];
   hasSuccess: boolean;
}

interface MethodFault {
   cause: any;
   faultCause: MethodFault | null;
   faultMessage: Array<{
      _type: string;
      key: string;
      arg: Array<any> | null;
      message: string | null;
   }> | null;
   message: string | null;
   localizedMessage: string | null;
   stackTrace: Array<any> | null;
}

export class VmMigrateStorageSelectionValidationService {

   static $inject = [
      "authorizationService",
      "compatibilityCheckService",
      "i18nService",
      "migrationService",
      "compatibilityResultsPresenter",
      "$q",
      "defaultUriSchemeUtil",
      "managedEntityConstants",
      "dataService",
      "bytesFilter",
      "storageProfileService",
      "spbmReplicationGroupInfoService"
   ];

   constructor(private authorizationService: any,
         private compatibilityCheckService: any,
         private i18nService: any,
         private migrationService: MigrationService,
         private compatibilityResultsPresenter: any,
         private $q: angular.IQService,
         private defaultUriSchemeUtil: any,
         private managedEntityConstants: any,
         private dataService: any,
         private bytesFilter: any,
         private storageProfileService: any,
         private spbmReplicationGroupInfoService: any) {
   }

   public validateSelection(validationModel: h5_vm.MigrationWizardDataModel):
         angular.IPromise<ValidationResult> {
      return this.$q.all({
            compatibilityArray: this.checkCompatibility(validationModel),
            privilegesMap: this.checkPrivileges(validationModel),
            placementMap: this.checkVmPlacement(validationModel),
            provisioningCostMap: this.requestProvisioningCost(validationModel)
      }).then((result: any) => {
         // Here we iterate the compatibility array
         // on each vm we get the errors and warnings from the other maps
         // finally we call the formatter with the new data

         let compatibilitySuccessMap: { [key: string]: boolean; } =
               this.getSuccessByVm(result.compatibilityArray);

         let replicationGroupMap = this.checkReplicationGroupAssignments(validationModel);

         _.each(result.compatibilityArray, (validationResult: any) => {
            if (validationResult.result) {
               this.addToValidationResult(result.privilegesMap, validationResult);
               this.addToValidationResult(result.placementMap, validationResult);
               this.addToValidationResult(result.provisioningCostMap, validationResult);
               this.addToValidationResult(replicationGroupMap, validationResult);
            }
         });

         return this.compatibilityResultsPresenter.formatValidationResults(
               result.compatibilityArray).then((compatibilityMessages: any) => {
            if (compatibilityMessages.length > 0) {
               return {
                  compatibilityMessages: compatibilityMessages,
                  hasSuccess: this.hasSuccess(compatibilitySuccessMap,
                        result.privilegesMap.successMap, result.placementMap.successMap,
                        result.provisioningCostMap.successMap,
                        replicationGroupMap.successMap)
               };
            }

            return {
               compatibilityMessages: [{
                  icon: "vsphere-icon-status-ok",
                  message: this.i18nService.getString(
                        "VmUi", "ProvisioningWizard.CompatibilityChecksSucceeded"),
                  contents: []
               }],
               hasSuccess: true
            };
         });
      });
   }

   private addToValidationResult(resultMap: {compatibilityData: any, successMap: any },
                                 validationResult: any): void {
      let vmId = this.defaultUriSchemeUtil.getVsphereObjectId(validationResult.entity);
      let currentResult = {
         error: _.map(resultMap.compatibilityData[vmId].errors, (error: any) => {
            return {
               message: error.message,
               localizedMessage: error.message
            };
         }),
         warning: _.map(resultMap.compatibilityData[vmId].warnings, (warning: any) => {
            return {
               message: warning.message,
               localizedMessage: warning.message
            };
         }),
         info: _.map(resultMap.compatibilityData[vmId].infos, (info: any) => {
            return {
               message: info.message,
               localizedMessage: info.message
            };
         }),
         host: this.compatibilityResultsPresenter.NO_HOST_REF,
         vm: validationResult.entity
      };
      if (!currentResult.error.length && !currentResult.warning.length
            && !currentResult.info.length) {
         return;
      }
      validationResult.result.push(currentResult);
   }

   private hasSuccess(...args: any[]): boolean {
      let vms = arguments[0];
      _.each(arguments, (successMap: any) => {
         if (successMap !== vms) {
            _.each(vms, (state: boolean, vmId: string) => {
               vms[vmId] = vms[vmId] && successMap[vmId];
            });
         }
      });

      let result = true;
      _.each(vms, (vmState: boolean) => {
         result = result && vmState;
      });

      return result;
   }

   private checkPrivileges(validationModel: MigrationWizardDataModel): IPromise<SelectionValidationResult> {
      let storageIds: Array<string> = [];

      _.each(validationModel.virtualDisksByVm, (vmDisks: Array<any>) => {
         _.each(vmDisks, (vmDisk: { storage: string }) => {
            if (!!vmDisk.storage && storageIds.indexOf(vmDisk.storage) === -1) {
               storageIds.push(vmDisk.storage);
            }
         });
      });

      return this.authorizationService.checkPrivilegesForMultipleObjects(
            storageIds, ["Datastore.AllocateSpace"]
      ).then((privilegesMap: { [storageId: string]: { [privilege: string]: boolean } }) => {
         let vmCompatibilityMap: { [vmId: string]: any } = {};
         let successMap: { [vmId: string]: boolean } = {};

         _.each(validationModel.virtualDisksByVm, (vmDisks: Array<any>, vmId: string) => {
            vmCompatibilityMap[vmId] = {
               errors: [],
               warnings: [],
               infos: []
            };
            _.each(vmDisks, (vmDisk: { storage: string }) => {
               // For PMEM storage is optional. We need to skip those disks.
               if (!vmDisk.storage) {
                  return;
               }
               if (!privilegesMap[vmDisk.storage]["Datastore.AllocateSpace"]) {
                  vmCompatibilityMap[vmId].errors = [{
                     icon: "vsphere-icon-status-error",
                     message: this.i18nService.getString(
                           "VmUi", "SelectDatastoreProvisioningPage.NoPermissionOnDatastore"),
                     contents: []
                  }];
                  successMap[vmId] = false;
               } else {
                  successMap[vmId] = true;
               }
            });
         });
         return {
            compatibilityData: vmCompatibilityMap,
            successMap: successMap
         };
      });
   }

   private checkCompatibility(validationModel: MigrationWizardDataModel): IPromise<SelectionValidationResult> {
      return this.migrationService.validateStorage(validationModel).then((validationResultsArray: any[]) => {
         return validationResultsArray;
      });
   }

   private checkVmPlacement(validationModel: MigrationWizardDataModel): angular.IPromise<SelectionValidationResult> {
      let assignmentsWithProfiles: Array<{ storageId: string, profile: any }> = [];
      // The map contains the index in the assignmentsWithProfiles of each of the vm disks.
      // The index is undefined if the disk is not added to assignmentsWithProfiles.
      // The map used for mapping the result to the corresponding vm disk.
      let diskResultIndexMap: { [vmId: string]: any } = {};
      let vmCompatibilityMap: { [vmId: string]: any } = {};
      let successMap: { [vmId: string]: boolean } = {};

      // Init the maps and inspect the disks for profile info.
      _.each(validationModel.virtualDisksByVm, (vmDisks: Array<any>, vmId: string) => {
         diskResultIndexMap[vmId] = {};
         successMap[vmId] = true;
         vmCompatibilityMap[vmId] = {
            errors: [],
            warnings: [],
            infos: []
         };
         _.each(vmDisks, (disk: any) => {
            if (disk.profile) {
               diskResultIndexMap[vmId][disk.key] = assignmentsWithProfiles.length;
               assignmentsWithProfiles.push({
                  storageId: disk.storage,
                  profile: disk.profile
               });
            }
         });
      });

      if (assignmentsWithProfiles.length === 0) {
         return this.$q.resolve({
            compatibilityData: vmCompatibilityMap,
            successMap: successMap
         });
      }

      return this.compatibilityCheckService.validateVmPlacementForMultipleVmsNoFilter(assignmentsWithProfiles)
            .then((result: any) => {
                  return this.formatVmPlacementResults(result, validationModel, diskResultIndexMap);
            });
   }

   private checkReplicationGroupAssignments(
         validationModel: MigrationWizardDataModel): any {

      let vmCompatibilityMap: { [vmId: string]: any } = {};
      let successMap: { [vmId: string]: boolean } = {};

      _.each(validationModel.virtualDisksByVm, (vmDisks: Array<VirtualDiskRecord>, vmId: string) => {
         vmCompatibilityMap[vmId] = {
            errors: [],
            warnings: [],
            infos: []
         };

         let vmRgAssignments: any[] = [];
         vmDisks.forEach((vmDisk: VirtualDiskRecord) => {
            if (vmDisk.replicationGroup) {
               vmRgAssignments.push(vmDisk.replicationGroup);
            }
         });

         let validationError: string|null = this.spbmReplicationGroupInfoService
               .validateVmReplicationGroupAssignments(vmRgAssignments);

         if (validationError) {
            vmCompatibilityMap[vmId].errors = [{
               icon: "vsphere-icon-status-error",
               message: validationError,
               contents: []
            }];
            successMap[vmId] = false;
         } else {
            successMap[vmId] = true;
         }
      });

      return {
         compatibilityData: vmCompatibilityMap,
         successMap: successMap
      };
   }

   private formatVmPlacementResults(result: any,
                                    validationModel: MigrationWizardDataModel,
                                    diskResultIndexMap: { [vmId: string]: any }
                                    ): { compatibilityData: any, successMap: any } {
      let vmCompatibilityMap: { [vmId: string]: any } = {};
      let successMap: { [vmId: string]: boolean } = {};

      _.each(validationModel.virtualDisksByVm, (vmDisks: Array<any>, vmId: string) => {
         vmCompatibilityMap[vmId] = {
            errors: [],
            warnings: [],
            infos: []
         };
         let spbmPolicyWarning: string =
               this.i18nService.getString("VmUi", "SelectDatastoreProvisioningPage.SpbmPolicyWarning");
         let spbmPolicyWarningAdded: boolean = false;
         _.each(vmDisks, (disk: any) => {
            let idx = diskResultIndexMap[vmId][disk.key];
            if (idx === undefined) {
               return;
            }

            _.each(result.errors[idx], (error: MethodFault) => {
               if (!error) {
                  return;
               }

               let errorMessage: string | null = error.localizedMessage || error.message;
               if (!errorMessage && error.faultMessage && error.faultMessage.length > 0) {
                  errorMessage = error.faultMessage[0].message;
               }
               if (errorMessage) {
                  let containsError = _.any(vmCompatibilityMap[vmId].errors,
                        (errorObj: CompatibilityNode) => {
                           return errorObj.message === errorMessage;
                        });
                  if (!containsError) {
                     vmCompatibilityMap[vmId].errors.push(
                           {
                              icon: "vsphere-icon-status-error",
                              message: errorMessage,
                              contents: []
                           }
                     );
                  }
                  return;
               }
            });

            // In case the server returns a generic message (e.g. VSAN policy message on VC 6.0),
            // we return only that message. In case it returns a more specific message,
            // we return the specific message
            _.each(result.warnings[idx], (warning: MethodFault) => {
               if (!warning) {
                  return;
               }

               let warningObj = {
                  icon: "vsphere-icon-status-warning",
                  message: spbmPolicyWarning,
                  contents: []
               };

               let warningMessage: string | null = warning.localizedMessage;
               if (!warningMessage && warning.faultMessage &&
                     warning.faultMessage.length > 0) {
                  warningMessage = warning.faultMessage[0].message;
               }
               if (warningMessage) {
                  let containsWarning = _.any(vmCompatibilityMap[vmId].warnings,
                        (warning: any) => {
                           return warning.message === warningMessage;
                        });
                  if (!containsWarning) {
                     warningObj.message = warningMessage;
                     vmCompatibilityMap[vmId].warnings.push(warningObj);
                  }
                  return;
               }
               if (!spbmPolicyWarningAdded) {
                  spbmPolicyWarningAdded = true;
                  vmCompatibilityMap[vmId].warnings.push(warningObj);
               }
            });
         });
         successMap[vmId] = !vmCompatibilityMap[vmId].errors.length;
      });

      return {
         compatibilityData: vmCompatibilityMap,
         successMap: successMap
      };
   }

   private requestProvisioningCost(validationModel: h5_vm.MigrationWizardDataModel) {
      const vsanStorageType = "vsan";
      let vitrualDisksByVm: {[key: string]: Array<VirtualDiskRecord>} =
            validationModel.virtualDisksByVm;
      let firstVmDisks = vitrualDisksByVm[validationModel.vms[0]];
      let vcId = this.defaultUriSchemeUtil.getRootFolderFromVsphereObjectId(firstVmDisks[0].storage);
      let specs: CalculateProvisioningCostSpec[] = [];
      let containsVsan: boolean = false;

      _.each(vitrualDisksByVm, (singleVmDisks: Array<VirtualDiskRecord>) => {
         _.each(singleVmDisks, (singleDisk: VirtualDiskRecord) => {
            containsVsan = containsVsan || singleDisk.storageType === vsanStorageType;
            let spec: CalculateProvisioningCostSpec = this.createCalculateCostSpec(singleDisk);
            specs.push(spec);
         });
      });

      if (!containsVsan) {
         return this.$q.when(this.formatDiskCostSpec([], vitrualDisksByVm));
      }

      return this.dataService.getProperties(vcId, ["spbmCreateCostData"],
            {
               propertyParams: [{
                  propertyName: "spbmCreateCostData",
                  parameterType: "[Lcom.vmware.vsphere.client.spbm.spec.CalculateProvisioningCostSpec;",
                  parameter: specs
               }]
            }).then((response: any) => {
         return this.formatDiskCostSpec(response.spbmCreateCostData, vitrualDisksByVm);
      });
   }


   private createCalculateCostSpec(disk: VirtualDiskRecord): CalculateProvisioningCostSpec {
      let spec: CalculateProvisioningCostSpec = new CalculateProvisioningCostSpec();
      // Note: The config file has no capacityInBytes
      spec.sizeInBytes = disk.capacityInBytes ? disk.capacityInBytes : 1;
      if (disk.profile && disk.profile.id !== this.storageProfileService.DEFAULT_ID) {
         spec.profileId = new ProfileId();
         spec.profileId.uniqueId = disk.profile.id;
      }
      spec.storageContainer = this.defaultUriSchemeUtil.getManagedObjectReference(
            disk.storage);
      return spec;
   }

   private formatDiskCostSpec(response: VsanCreateCostData[],
                              vitrualDisksByVm: {[key: string]: Array<VirtualDiskRecord>}): any {
      if (!response || !response.length) {
         let compMap: { [vmId: string]: any } = {};
         let successMap: { [vmId: string]: boolean } = {};
         _.each(vitrualDisksByVm, (singleVmDisks: Array<VirtualDiskRecord>,
                                   vmId: string) => {
            compMap[vmId] = {
               errors: [],
               warnings: [],
               infos: []
            };
            successMap[vmId] = true;
         });

         return {
            compatibilityData: compMap,
            successMap: successMap
         };
      }

      let idx: number = 0;
      let vmCompatibilityMap: { [vmId: string]: any } = {};
      let successMap: { [vmId: string]: boolean } = {};
      let showConsumptionMap: { [vmId: string]: boolean } = {};

      _.each(vitrualDisksByVm, (singleVmDisks: Array<VirtualDiskRecord>, vmId: string) => {
         let vmDataUsage: number = 0;
         let vmSsdUsage: number = 0;
         vmCompatibilityMap[vmId] = {
            errors: [],
            warnings: [],
            infos: []
         };
         successMap[vmId] = true;
         _.each(singleVmDisks, (singleDisk) => {
            let costData: VsanCreateCostData = response[idx];
            idx++;
            let policySatisfiability: any = costData.cost;
            let diskCapacityInBytes = costData.size;

            if (policySatisfiability && policySatisfiability.reason
                  && policySatisfiability.reason.message) {
               if (!policySatisfiability.isSatisfiable) {
                  // add warning
                  if (!this.containsMessage(vmCompatibilityMap[vmId].warnings,
                              policySatisfiability.reason.message)) {
                     vmCompatibilityMap[vmId].warnings.push({
                        icon: "vsphere-icon-status-warning",
                        message: policySatisfiability.reason.message,
                        contents: []
                     });
                  }
                  showConsumptionMap[vmId] = false;
               } else {
                  // add info
                  if (!this.containsMessage(vmCompatibilityMap[vmId].infos,
                              policySatisfiability.reason.message)) {
                     vmCompatibilityMap[vmId].infos.push({
                        icon: "vsphere-icon-help-info-hover",
                        message: policySatisfiability.reason.message,
                        contents: []
                     });
                  }

                  if (showConsumptionMap[vmId] === undefined) {
                     showConsumptionMap[vmId] = true;
                  }
               }
            }

            if (policySatisfiability && policySatisfiability.isSatisfiable &&
                  policySatisfiability.cost) {
               let policyCost: any = policySatisfiability.cost;
               // We are interested in the projected capacity of the disk when it
               // is fully provisioned.
               vmDataUsage += diskCapacityInBytes *
                     policyCost.diskSpaceToAddressSpaceRatio;
               vmSsdUsage += policyCost.currentFlashReadCacheSize +
                     policyCost.changeFlashReadCacheSize;
               if (showConsumptionMap[vmId] === undefined) {
                  showConsumptionMap[vmId] = true;
               }
            }
         });
         if (!showConsumptionMap[vmId]) {
            return;
         }
         vmCompatibilityMap[vmId].infos.push({
            icon: "vsphere-icon-help-info-hover",
            message: this.i18nService.getString(
                  "VmUi", "whatIf.vsanConsumption",
                  this.bytesFilter(vmDataUsage),
                  this.bytesFilter(vmSsdUsage)),
            contents: []
         });
      });

      return {
         compatibilityData: vmCompatibilityMap,
         successMap: successMap
      };
   }

   private containsMessage(messageArray: any, message: string) {
      return _.any(messageArray, (messageObj: any) => {
               return messageObj.message === message;
            });
   }

   private getSuccessByVm(validationResultsArray: any): { [key: string]: boolean; } {
      let successCountByVmId: { [vmId: string]: number } = {};
      _.each(validationResultsArray, (validationResult: any) => {
         let vmId: string = this.defaultUriSchemeUtil.getVsphereObjectId(validationResult.entity);
         _.each(validationResult.result, (result: any) => {
            if (!successCountByVmId[vmId]) {
               successCountByVmId[vmId] = 0;
            }
            if (!result.error) {
               successCountByVmId[vmId]++;
            }
         });
         if (!validationResult.result && validationResult.error) {
            if (!successCountByVmId[vmId]) {
               successCountByVmId[vmId] = 0;
            }
         }
      });

      let succesByVmId: { [vmId: string]: boolean } = {};
      _.each(successCountByVmId, (successCount: number, vmId: string) => {
         succesByVmId[vmId] = successCount >= 1;
      });
      return succesByVmId;
   }
} // class VmMigrateStorageSelectionValidationService

angular.module("com.vmware.vsphere.client.vm").service(
      "vmMigrateStorageSelectionValidationService",
      VmMigrateStorageSelectionValidationService);

} // module
