namespace storage_ui {

   import IComponentController = angular.IComponentController;
   class StorageAdapterEditAuthenticationChapSection implements IComponentController {

      public static $inject = [
         "i18nService",
         "storageAdapterConstants",
         "storageAdapterEditAuthenticationValidationService"];

      public i18n: Function;
      public name: string;
      public iscsiName: string;
      public secret: string;
      public disabled: boolean;
      public useInitiatorName: boolean;
      public isSoftwareBased: boolean;
      public validator: any /*VxValidator*/;

      constructor(i18nService: any,
                  private constants: any,
                  private validatorService: any) {

         this.i18n = i18nService.getString;
      }

      public onCheckboxChange(): void {
         if (!this.useInitiatorName) {
            this.name = "";
         } else {
            this.name = this.iscsiName;
         }
      }

      private getMaxSecretInputLength(): number {
         return this.isSoftwareBased
               ? this.constants.constraints.SW_CHAP_SECRET_MAX
               : this.constants.constraints.HW_CHAP_SECRET_MAX;
      }

      private getMaxNameInputLength(): number {
         return this.isSoftwareBased
               ? this.constants.constraints.SW_CHAP_NAME_MAX
               : this.constants.constraints.HW_CHAP_NAME_MAX;
      }

      private isValidSecret = () => {
         return this.validatorService.isValidSecret(this.secret, this.isSoftwareBased);
      };

      private isValidName = () => {
         return this.validatorService.isValidName(this.name, this.isSoftwareBased);
      }

   }

   angular.module("com.vmware.vsphere.client.storage")
         .component("storageAdapterEditAuthenticationChapSection", {
            templateUrl: "storage-ui/resources/storage/views/host/adapters/authentication/StorageAdapterEditAuthenticationChapSection.html",
            bindings: {
               disabled: "=ngDisabled",
               header: "<",
               iscsiName: "<",
               name: "=",
               secret: "=",
               useInitiatorName: "=",
               isSoftwareBased: "<",
               validator: "<"
            },
            controller: StorageAdapterEditAuthenticationChapSection
         });
}
