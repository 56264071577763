/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace dvs_ui {

   import VspanSessionEncapType =
         com.vmware.vim.binding.vim.dvs.VmwareDistributedVirtualSwitch$VspanSessionEncapType;

   export class DvsSpanSessionPropertiesPageComponent {

      public bindings: any;
      public controller: any;
      public templateUrl: string;

      constructor() {
         this.bindings = {
            pageModel: "<",
            validator: "<",
            sessionType: "<",
            editMode: "<",
            loading: "<"
         };

         this.controller = DvsSpanSessionPropertiesPageComponentController;

         this.templateUrl = "dvs-ui/resources/dvs/portmirroring/common/components/" +
               "dvsSpanSessionPropertiesPageComponentTemplate.html";
      }
   }

   class DvsSpanSessionPropertiesPageComponentController {

      static $inject = [
            "i18nService",
            "vxValidatorFactory",
            "dvsSpanSessionPropertiesValidator"
      ];

      public pageModel: DvsSpanSessionPropertiesPageModel;

      public validator: DvsSpanSessionPageValidator;

      public sessionType: string;

      public editMode: boolean;

      public loading: boolean;

      public sessionStatusOptions: DropdownOption<boolean>[];

      public normalTrafficAllowedOptions: DropdownOption<boolean>[];

      public encapsulationTypeOptions: DropdownOption<VspanSessionEncapType>[];

      public tcpIpNetStackOptions: DropdownOption<string>[];

      public validationFunctions: { [property: string]: Function };

      private isTcpIpStackInVspanSessionSupported: boolean;

      public getString: (key: string) => string;

      constructor(private i18nService: any,
                  private vxValidatorFactory: any,
                  private propertiesValidator: DvsSpanSessionPropertiesValidator) {
         this.getString = (key: string): string => {
            return i18nService.getString("DvsUi", key);
         };
      }

      public $onInit(): void {
         // create a new vxValidator so that validation callbacks from previous instances
         // of this page are not called and can be GC-ed
         this.validator.vxValidator = this.vxValidatorFactory.create();

         this.initDropdownOptions();
         this.initValidationFunctions();
      }

      public $onDestroy(): void {
         delete this.validator["vxValidator"];
      }

      public get sessionTypeLabel(): string {
         switch (this.sessionType) {
            case DvsSpanSessionConstants.SESSION_TYPE.L3_SOURCE: {
               return this.getString("span.session.type.encapsulatedRemoteMirrorSource");
            }
            case DvsSpanSessionConstants.SESSION_TYPE.REMOTE_MIRROR_SOURCE: {
               return this.getString("span.session.type.remoteMirrorSource");
            }
            case DvsSpanSessionConstants.SESSION_TYPE.DV_PORT_MIRROR: {
               return this.getString("span.session.type.dvPortMirror");
            }
            case DvsSpanSessionConstants.SESSION_TYPE.REMOTE_MIRROR_DEST: {
               return this.getString("span.session.type.remoteMirrorDest");
            }
            default:
               return this.getString("AddSpanSessionWizard.noData");
         }
      }

      public get sessionIdEnabled(): boolean {
         return this.pageModel.encapsulationType !==
               DvsSpanSessionConstants.ENCAPSULATION_TYPE.GRE;
      }

      public get encapsulationTypeEnabled(): boolean {
         return !this.editMode;
      }

      public get sessionIdAvailable(): boolean {
         return !this.editMode || this.pageModel.encapsulationType !==
               DvsSpanSessionConstants.ENCAPSULATION_TYPE.GRE;
      }

      public get encapsulationOptionsAvailable(): boolean {
         return this.sessionType ===
               DvsSpanSessionConstants.SESSION_TYPE.L3_SOURCE
               && this.pageModel.erspanSupported;
      }

      public get vlanOptionsAvailable(): boolean {
         return this.sessionType ===
               DvsSpanSessionConstants.SESSION_TYPE.REMOTE_MIRROR_SOURCE;
      }

      public get normalIoOptionsAvailable(): boolean {
         return this.sessionType !==
               DvsSpanSessionConstants.SESSION_TYPE.L3_SOURCE;
      }

      private initDropdownOptions(): void {
         this.sessionStatusOptions = [{
            value: false,
            label: this.getString("AddSpanSessionWizard.propertiesPage.status.disabled")
         }, {
            value: true,
            label: this.getString("AddSpanSessionWizard.propertiesPage.status.enabled")
         }];

         this.encapsulationTypeOptions = [{
            value: DvsSpanSessionConstants.ENCAPSULATION_TYPE.GRE,
            label: this.getString("SpanSessionEncapsulation.type.gre")
         }, {
            value: DvsSpanSessionConstants.ENCAPSULATION_TYPE.ERSPAN2,
            label: this.getString("SpanSessionEncapsulation.type.erspan2")
         }, {
            value: DvsSpanSessionConstants.ENCAPSULATION_TYPE.ERSPAN3,
            label: this.getString("SpanSessionEncapsulation.type.erspan3")
         }];

         this.isTcpIpStackInVspanSessionSupported = this.pageModel.isTcpIpStackSupported &&
            this.sessionType === DvsSpanSessionConstants.SESSION_TYPE.L3_SOURCE;

         if (this.isTcpIpStackInVspanSessionSupported) {
            this.tcpIpNetStackOptions = [{
               value: DvsSpanSessionConstants.TCP_IP_STACK.DEFAULT_STACK,
               label: this.getString("spanSession.tcpip.stack.default")
            }, {
               value: DvsSpanSessionConstants.TCP_IP_STACK.MIRROR_STACK,
               label: this.getString("spanSession.tcpip.stack.mirror")
            }];
         }

         this.normalTrafficAllowedOptions = [{
            value: false,
            label: this.getString("AddSpanSessionWizard.propertiesPage.normalTrafficAllowed.off")
         }, {
            value: true,
            label: this.getString("AddSpanSessionWizard.propertiesPage.normalTrafficAllowed.on")
         }];
      }

      private initValidationFunctions(): void {
         this.validationFunctions = {};

         this.validationFunctions["sessionName"] =
               this.getSessionNameValidationFunction();

         this.validationFunctions["sessionId"] =
               this.getSessionIdValidationFunction();

         this.validationFunctions["mirroredPacketLength"] =
               this.getPacketLengthValidationFunction();

         this.validationFunctions["samplingRate"] =
               this.getSamplingRateValidationFunction();

         this.validationFunctions["encapsulationVlanId"] =
               this.getEncapsulationVlanIdValidationFunction();
      }

      private getSessionNameValidationFunction(): Function {
         return (): string[] => {
            let error: string|null =
                  this.propertiesValidator.getNameError(
                        this.pageModel.sessionName);
            return error ? [error] : [];
         };
      }

      private getSessionIdValidationFunction(): Function {
         return (): string[] => {
            if (this.pageModel.encapsulationType ===
                  DvsSpanSessionConstants.ENCAPSULATION_TYPE.GRE) {
               return [];
            }

            let error: string|null =
                  this.propertiesValidator.getSessionIdError(
                        this.pageModel.sessionId);
            return error ? [error] : [];
         };
      }

      private getPacketLengthValidationFunction(): Function {
         return (): string[] => {
            if (!this.pageModel.mirroredPacketLengthEnabled) {
               return [];
            }

            let error: string|null =
                  this.propertiesValidator.getPacketLengthError(this.pageModel.mirroredPacketLength);
            return error ? [error] : [];
         };
      }

      private getSamplingRateValidationFunction(): Function {
         return (): string[] => {
            let error: string|null =
                  this.propertiesValidator.getSamplingRateError(this.pageModel.samplingRate);
            return error ? [error] : [];
         };
      }

      private getEncapsulationVlanIdValidationFunction(): Function {
         return (): string[] => {
            let error: string|null =
                  this.propertiesValidator.getEncapsulationVlanIdError(
                        this.pageModel.encapsulationVlanId);
            return error ? [error] : [];
         };
      }
   }

   interface DropdownOption<T> {
      value: T;
      label: string;
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .component("dvsSpanSessionPropertiesPage",
               new DvsSpanSessionPropertiesPageComponent());
}
