namespace h5_vm {
   export class DeviceClassLineageService {
      static $inject = [];

      private readonly virtualDeviceClassMap: any;
      private deviceTypeLookUp: any;
      private loggedMissingDeviceTypeWarnings: any;

      constructor() {
         this.virtualDeviceClassMap = this.getVirtualDeviceClassMap();
         this.deviceTypeLookUp = {};
         this.collectTypes(this.virtualDeviceClassMap, null);
         this.loggedMissingDeviceTypeWarnings = [];
      }

      /**
       * Checks if a given device class name is a sub class of another
       *
       * @param subClassName : fully qualified class name of the sub type
       * @param superClassName : fully qualified class name of the super type
       * @returns {boolean}
       */
      public isClassNameSubclassOf(subClassName: string, superClassName: string): boolean {
         subClassName = this.getSimpleClassName(subClassName);
         superClassName = this.getSimpleClassName(superClassName);
         var deviceType = this.deviceTypeLookUp[subClassName];

         if (!deviceType) {
            if (!this.loggedMissingDeviceTypeWarnings[subClassName]) {
               console.warn("Unable to find device class for " + subClassName);
               this.loggedMissingDeviceTypeWarnings[subClassName] = true;
            }
            return false;
         }

         while (true) {
            if (deviceType.type === superClassName) {
               return true;
            }
            if (!deviceType.parent) {
               return false;
            }
            deviceType = deviceType.parent;
         }
      }

      /**
       * Gives the type of VirtualController for a given controller's type class
       *
       * @param controllerTypeClass : fully qualified class name of the virtual controller
       * @returns {string | null} : 1) The type of the current controller if its parent's type is "VirtualController"
       *                            2) Else, traverses the parent hierarchy levels until it finds the controller whose
       *                               parent's type is "VirtualController" and returns that controller's type.
       *                            3) Null if it cannot find such a controller.
       */
      public getVirtualControllerType(controllerTypeClass: string): string | null {
         var simpleClassName = this.getSimpleClassName(controllerTypeClass);
         var controller = this.deviceTypeLookUp[simpleClassName];

         while(controller) {
            if(controller.parent && controller.parent.type === "VirtualController") {
               return controller.type;
            } else {
               controller = controller.parent;
            }
         }
         return null;
      }

      private getSimpleClassName(fullyQualifiedClassName: string) {
         var index = fullyQualifiedClassName.lastIndexOf(".");
         if (index >= 0) {
            return fullyQualifiedClassName.substr(index + 1);
         }
         return fullyQualifiedClassName;
      }

      private collectTypes(arr: any[], parent: any) {
         arr.forEach((typeObj) => {
            typeObj.parent = parent;
            this.deviceTypeLookUp[typeObj.type] = typeObj;
            if (typeObj.children) {
               this.collectTypes(typeObj.children, typeObj);
            }
         });
      }

      private getVirtualDeviceClassMap() {
         return [
            {
               type: 'VirtualDevice',
               children: [
                  {
                     type: 'VirtualCdrom'
                  },
                  {
                     type: 'VirtualNVDIMM'
                  },
                  {
                     type: 'VirtualController',
                     children: [
                        {
                           type: 'VirtualIDEController'
                        },
                        {
                           type: 'VirtualNVMEController'
                        },
                        {
                           type: 'VirtualNVDIMMController'
                        },
                        {
                           type: 'VirtualPCIController'
                        },
                        {
                           type: 'VirtualPS2Controller'
                        },
                        {
                           type: 'VirtualSATAController',
                           children: [
                              {
                                 type: 'VirtualAHCIController'
                              }
                           ]
                        },
                        {
                           type: 'VirtualSCSIController',
                           children: [
                              {
                                 type: 'ParaVirtualSCSIController'
                              },
                              {
                                 type: 'VirtualBusLogicController'
                              },
                              {
                                 type: 'VirtualLsiLogicController'
                              },
                              {
                                 type: 'VirtualLsiLogicSASController'
                              }
                           ]
                        },
                        {
                           type: 'VirtualSIOController'
                        },
                        {
                           type: 'VirtualUSBController'
                        },
                        {
                           type: 'VirtualUSBXHCIController'
                        }
                     ]
                  },
                  {
                     type: 'VirtualDisk'
                  },
                  {
                     type: 'VirtualEthernetCard',
                     children: [
                        {
                           type: 'VirtualE1000'
                        },
                        {
                           type: 'VirtualE1000e'
                        },
                        {
                           type: 'VirtualPCNet32'
                        },
                        {
                           type: 'VirtualSriovEthernetCard'
                        },
                        {
                           type: 'VirtualVmxnet',
                           children: [
                              {
                                 type: 'VirtualVmxnet2'
                              },
                              {
                                 type: 'VirtualVmxnet3',
                                 children: [
                                    {
                                       type: 'VirtualVmxnet3Vrdma'
                                    }
                                 ]
                              }
                           ]
                        }
                     ]
                  },
                  {
                     type: 'InputDevice',
                     children: [
                        {
                           type: 'VirtualKeyboard'
                        },
                        {
                           type: 'VirtualPointingDevice'
                        }
                     ]
                  },
                  {
                     type: 'VirtualFloppy'
                  },
                  {
                     type: 'VirtualVideoCard'
                  },
                  {
                     type: 'VirtualVMCIDevice'
                  },
                  {
                     type: 'VirtualVMIROM'
                  },
                  {
                     type: 'VirtualParallelPort'
                  },
                  {
                     type: 'VirtualPCIPassthrough'
                  },
                  {
                     type: 'VirtualSCSIPassthrough'
                  },
                  {
                     type: 'VirtualSerialPort'
                  },
                  {
                     type: 'VirtualSoundCard',
                     children: [
                        {
                           type: 'VirtualEnsoniq1371'
                        },
                        {
                           type: 'VirtualHdAudioCard'
                        },
                        {
                           type: 'VirtualSoundBlaster16'
                        }
                     ]
                  },
                  {
                     type: 'VirtualUSB'
                  },
                  {
                     type: 'VirtualTPM'
                  },
               ]
            }];
      }
   }

   angular.module('com.vmware.vsphere.client.vm')
         .service('deviceClassLineageService', DeviceClassLineageService);
}
