module common_module_ui {

   import IController = angular.IController;

   class ObjectUtilizationMeterGroupController implements IController {

      public minValue: string;

      public $onChanges(changes: any) {
         let maxValue: string = (typeof changes.maxValue !== "undefined") ?
               changes.maxValue.currentValue : "";
         let groupedMeters: any[] = (typeof changes.groupedMeters !== "undefined") ?
               changes.groupedMeters.currentValue : [];

         if (maxValue && maxValue !== "") {
            this.minValue = this.getMinValue(maxValue);
         }

         if (groupedMeters && groupedMeters.length > 0) {
            // find biggest grouped meter
            let maxGroupedMeter: any = _.max(groupedMeters, (groupMeter) => {
               return parseFloat(groupMeter.value);
            });

            let groupedMeterValues: any[] = _.pluck(groupedMeters, "value");
            let offset: number =
                  100 - _.reduce(groupedMeterValues, (total: any, num: any) => {
                     return total + parseFloat(num);
                  }, 0);

            if (offset !== 100 && offset !== 0) {
               // add difference to the biggest grouped meter
               _.each(changes.groupedMeters.currentValue, (groupedMeter: any) => {
                  if (_.isEqual(maxGroupedMeter, groupedMeter)) {
                     groupedMeter.value = parseFloat(maxGroupedMeter.value) + offset;
                  }
               });
            }
         }
      }

      private getMinValue(maxValue: string): string {
         let minValue: string = "";

         if (maxValue.length > 0) {
            minValue = "0 " + maxValue.split(" ")[1];
         }

         return minValue;
      }
   }

   class ObjectUtilizationMeterGroup implements ng.IComponentOptions {
      public controller: any;
      public templateUrl: string;
      public bindings: any;

      constructor() {
         this.controller = ObjectUtilizationMeterGroupController;
         this.templateUrl =
               "common-module-ui/resources/common-module/views/utilization/objectUtilizationMeterGroup.html";
         this.bindings = {
            groupedMeters: "<",
            meters: "<",
            maxValue: "<",
            pointers: "<"
         };
      }
   }

   angular.module("com.vmware.vsphere.client.commonModule").component(
         "objectUtilizationMeterGroup", new ObjectUtilizationMeterGroup());
}
