/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace dvs_ui {

   export class VnicSettingsDialogService {

      static $inject = [
         "i18nService",
         "clarityModalService"
      ];

      constructor(private i18nService: any,
                  private clarityModalService: any) {
      }

      public show(hostId: string,
                  vnicDevice: string,
                  hostname: string): void {

         let templateUrl = "dvs-ui/resources/dvs/addhost/services/vnicSettingsDialog.html";
         let title = this.i18nService.getString(
               "DvsUi", "SelectVirtualAdaptersPage.vnicPopupTitle", vnicDevice, hostname);
         let dialogData = {
            title: title,
            hostId: hostId,
            vnicDevice: vnicDevice,
         };

         this.clarityModalService.openModal(
               undefined, [], dialogData, templateUrl);
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .service("vnicSettingsDialogService", VnicSettingsDialogService);
}
