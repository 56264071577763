/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
(function () {
   'use strict';

   angular
         .module('com.vmware.vsphere.client.network')
         .service('selectTargetNetworkPageValidator',
               SelectTargetNetworkPageValidator);

   SelectTargetNetworkPageValidator.$inject = [
      'i18nService',
      'dataService',
      'networkUtil',
      'addNetworkingWizardConstants'];

   function SelectTargetNetworkPageValidator(i18nService,
                                             dataService,
                                             networkUtil,
                                             addNetworkingWizardConstants) {
      var standardSwitchProperty = "host:uniqueStandardSwitchName";

      this.getValidationErrors = function (wizardScope) {
         var errors = [];
         if (wizardScope.selectTargetNetworkPageModel.targetType
               === addNetworkingWizardConstants.targetType.EXISTING_NETWORK) {
            if (!wizardScope.addNetworkingSpec.selectedNetwork) {
               errors.push(
                     i18nService.getString('H5NetworkUi',
                           'AddNetworkingWizard.conTargetPage.noSelectedTargetError'));
            }
         } else if (wizardScope.selectTargetNetworkPageModel.targetType
               === addNetworkingWizardConstants.targetType.EXISTING_STANDARD_SWITCH) {
            if (!wizardScope.addNetworkingSpec.selectedStandardSwitch) {
               errors.push(
                     i18nService.getString('NetworkUi',
                           'AddNetworkingWizard.conTargetPage.noSwitchTargetError'));
            }
         } else if (wizardScope.selectTargetNetworkPageModel.targetType
               === addNetworkingWizardConstants.targetType.NEW_STANDARD_SWITCH) {
            if (!networkUtil.isMtuValid(wizardScope.vSwitchSpec.mtu)) {
               errors.push(i18nService.getString('H5NetworkUi',
                     'AddNetworkingWizard.conPropPage.mtu.validationErrorMessage'));
            }
            if (wizardScope.hostCapability.switchAutoScaleSupported === false && !_isValidNumPorts(
                        wizardScope.hostCapability,
                        wizardScope.vSwitchSpec.numPorts)) {

               errors.push(i18nService.getString('NetworkUi',
                     'AddNetworkingWizard.conTargetPage.availablePortsExceeded',
                     wizardScope.hostCapability.availablePortsNumber));
            }
            if (errors.length !== 0) {
               return errors;
            } else {
               //this isn't validation we just have to get an unique name for the
               //new standard switch and pass it to the next pages
               return dataService.getProperties(wizardScope.hostId,
                     [standardSwitchProperty])
                     .then(function (result) {
                        wizardScope.vSwitchSpec.name = result[standardSwitchProperty];
                        return [];
                     });
            }
         }
         return errors;
      };
   }

   function _isValidNumPorts(hostCapability, numPorts) {
      return typeof numPorts !== 'undefined' && hostCapability.availablePortsNumber > numPorts;
   }
})();