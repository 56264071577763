/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace dvs_ui {

   export class DvpgCloneTrafficRulesValidator {

      public static $inject = [
         "i18nService",
         "dvpgTrafficFilterConstants",
         "authorizationService",
         "$q"
      ];

      private privilegeToCheck: string;

      constructor(private i18nService: any,
                  private dvpgTrafficFilterConstants: any,
                  private authorizationService: any,
                  private $q: any) {
         this.privilegeToCheck =
               this.dvpgTrafficFilterConstants.privileges.DVPG_MODIFY;
      }

      public getValidationError(model: DvpgCloneTrafficRulesModel): any {
         let errors: string[] = [];

         if (!model.selectedPortgroups || model.selectedPortgroups.length === 0) {
            errors.push(this.i18nService.getString(
                  "DvsUi", "trafficFilter.cloneRulesDialog.noPortGroupsSelected"));
            return this.$q.when(errors);
         } else {
            return this.getNoPrivilegesValidationError(model);
         }
      }

      private getNoPrivilegesValidationError(model: DvpgCloneTrafficRulesModel): any {
         let dvPortgroupIds: string[] = _.map(model.selectedPortgroups, (pg) => {
            return pg.id;
         });

         return this.authorizationService
               .checkPrivilegesForMultipleObjects(dvPortgroupIds,
                     [this.privilegeToCheck])
               .then((result: any) => {
                  return this.buildNoPrivilegesValidationMessages(
                        result, model.selectedPortgroups);
               });
      }

      private buildNoPrivilegesValidationMessages(
            privilegesById: any, selectedPortgroups: any[]): string[] {
         let validationMessages: string[] = [];

         Object.keys(privilegesById).forEach((id: string) => {
            if (privilegesById[id][this.privilegeToCheck] === false) {
               let dvpgName: string =
                     this.getPortgroupName(id, selectedPortgroups);

               if (dvpgName !== "") {
                  validationMessages.push(this.i18nService.getString("DvsUi",
                        "trafficFilter.cloneRulesDialog.noPrivilegesFormat", dvpgName));
               }
            }
         });

         return validationMessages;
      }

      private getPortgroupName(id: string, portgroups: any[]): string {
         for (let i = 0; i < portgroups.length; i++) {
            if (id === portgroups[i].id) {
               return portgroups[i].name;
            }
         }
         return "";
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .service("dvpgCloneTrafficRulesValidator", DvpgCloneTrafficRulesValidator);
}