namespace storage_ui {
   export class IscsiAdvancedOptions {
      public data: any[];
      public adapterKey: any;
      public adapterItem: any;
      public loading: boolean;
      public hostId: string;
      public hba: any;

      private readonly STORAGE_BUS_ADAPTER_PROPERTY = "hostBusAdapter";

      private readonly ADVANCED_SETTINGS_PROPERTY = ".internetScsiAdvancedSettings";

      public static $inject = ["dataService", "navigation", "i18nService", "advancedOptionConstants"];

      constructor(private dataService: any,
                  private navigation: any,
                  private i18nService: any,
                  private advancedOptionConstants:any) {
      }

      public $onChanges() {
         this.adapterKey = this.adapterItem.key;
         if (this.adapterKey) {
            this.requestData();
         }
      }

      private requestData() {
         this.loading = true;
         this.hostId = this.navigation.getRoute().objectId;

         let propertyName = this.adapterKey + this.ADVANCED_SETTINGS_PROPERTY;
         this.dataService.getProperties(
               this.hostId, [propertyName, this.STORAGE_BUS_ADAPTER_PROPERTY], {
                  propertyParams: [
                     {
                        propertyName: this.STORAGE_BUS_ADAPTER_PROPERTY,
                        parameterType: "java.lang.String",
                        parameter: this.adapterKey
                     }
                  ]
               })
               .then((response:any) => {
                  if (response) {
                     this.data =  response[propertyName] ? this.parseResponse(response[propertyName]) : [];
                     this.hba = response[this.STORAGE_BUS_ADAPTER_PROPERTY];
                  } else {
                     this.data = [];
                  }
               }).finally(() => {
            this.loading = false;
         });
      }

      public parseResponse(responseData: any) {
         return _.map(responseData, (item: any) => {
            let optionProperty = this.fetchPropery(
                  this.advancedOptionConstants.optionProperties.OPTION_PROPERTY_NAME, item.properties);
            let descriptionProperty = this.fetchPropery(
                  this.advancedOptionConstants.optionProperties.DESCRIPTION_PROPERTY_NAME, item.properties);
            let valueProperty = this.fetchPropery(
                  this.advancedOptionConstants.optionProperties.VALUE_PROPERTY_NAME, item.properties);
            let value = "";

            if (valueProperty && item.tag && item.tag.optionValueType) {
               value = this.getValueOfType(valueProperty.value, item.tag.optionValueType);
            }

            return {
               option: (optionProperty && optionProperty.value) || "",
               description: (descriptionProperty && descriptionProperty.value) || "",
               value: value
            };
         });
      }

      public fetchPropery(propertyName: string, properties: any[]): any {
         return _.select(properties, function (prop: any) {
            return prop.name === propertyName;
         })[0];
      }

      public getValueOfType(value: any, type: string) {
         if (type === this.advancedOptionConstants.optionTypes.STRING_OPTION_TYPE) {
            return value.name;
         }
         return value;
      }
   }

   angular.module("com.vmware.vsphere.client.storage")
         .component("iscsiAdvancedOptions", {
            templateUrl: "storage-ui/resources/storage/views/host/adapters/advanced/IscsiAdvancedOptions.html",
            bindings: {
               adapterItem: "<"
            },
            controller: IscsiAdvancedOptions
         });
}
