/**
 * @name com.vmware.vsphere.client.vm:upgradeVmHardwareConstants
 * @module ccom.vmware.vsphere.client.vm
 *
 * @description
 *    The `upgradeVmHardwareConstants` service holds VM compatibility constants.
 */
angular.module('com.vmware.vsphere.client.vm').constant('upgradeVmHardwareConstants', {
   hardwareUpgradePolicy : {
      never: "never",
      onSoftPowerOff: "onSoftPowerOff",
      always: "always"
   }
});
