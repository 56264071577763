/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace dvs_ui {

   angular.module("com.vmware.vsphere.client.dvs").constant(
         "dvsUpgradeNiocWizardConstants", {
      pages: {
         DVS_NIOC_OVERVIEW_PAGE: "niocOverviewPage",
         DVS_NIOC_VALIDATION_PAGE: "niocValidatePrerequisitesPage",
         DVS_NIOC_MIGRATE_SHARES_PAGE: "niocLagPage",
         DVS_NIOC_SUMMARY_PAGE: "niocReadyToCompletePage"
      },
      properties: {
         DVS_UPGRADE_NIOC_WIZARD_DATA: "niocupgrade:wizardData"
      },
      validationResult: {
         FAIL: "fail",
         WARNING: "warning",
         PASS: "pass"
      },
      spec: {
         MUTATION_SPEC_TYPE: "com.vmware.vsphere.client.dvs.specs.DvsResControlUpgradeSpec"
      }
   });
}
