namespace storage_ui {

   import IComponentOptions = angular.IComponentOptions;
   import ProtocolEndpoint$PEType = com.vmware.vim.binding.vim.host.ProtocolEndpoint$PEType;

   export interface DatastoreProtocolEndpointListItem {
      name: String;
      type: String;
      storageArray: String;
      identifier: String;
      server: String;
      folder: String;
      isScsiPE: boolean;
   }

   class ProtocolEndpoints implements angular.IComponentController {
      static $inject = ["i18nService", "dataService", "datastoreConstants", "vuiConstants"];
      private datagridOptions: any;
      private datastoreRef: any;
      private selectedItem: DatastoreProtocolEndpointListItem[];
      private readonly PROPERTY_NAME = "datastoreProtocolEndpointsData";

      constructor(private i18nService: any,
                  private dataService: any,
                  private datastoreConstants: any,
                  private vuiConstants: any) {
      }

      $onInit() {
         this.initDatagrid();
         this.dataService.getProperties(this.datastoreRef, this.PROPERTY_NAME).then(
               (response: {[key: string]: string})=> {
                  this.datagridOptions.data = _.map(response[this.PROPERTY_NAME], this.formatRawData);
               });
      }

      private initDatagrid() {
         this.datagridOptions = {
            resizable: true,
            height: "100%",
            columnDefs: this.getColumnDefs(),
            selectionMode: this.vuiConstants.grid.selectionMode.SINGLE,
            onChange: ()=> {
               let selection = this.datagridOptions.selectedItems;
               if (!selection || !selection.length) {
                  this.selectedItem = [];
               } else {
                  this.selectedItem = selection;
               }
            },
            selectedItems:[]
         };
      }

      formatRawData = (rawData: any): DatastoreProtocolEndpointListItem => {
         let result = <DatastoreProtocolEndpointListItem>{};
         result.storageArray = rawData.storageArray || "";
         result.identifier = rawData.uuid || "";
         result.isScsiPE = this.datastoreConstants.protocolEndpoint.type.block === rawData.peType;
         switch (rawData.peType) {
            case this.datastoreConstants.protocolEndpoint.type.block:
               result.name = rawData.deviceId || "";
               result.type = this.i18nService.getString("StorageUi", "storage.pe.type.block");
               break;
            case this.datastoreConstants.protocolEndpoint.type.nas:
               result.name = this.i18nService.getString("StorageUi", "storage.pe.nas.format",
                     rawData.nfsServer || "",
                     rawData.nfsDir || "");
               result.type = this.i18nService.getString("StorageUi", "storage.pe.type.nas");
               result.folder = rawData.nfsDir;
               result.server = rawData.nfsServer;
               break;
            default:
               break;
         }
         return result;
      };

      private getColumnDefs(): any[] {
         return [
            {
               displayName: this.i18nService.getString("StorageUi", "storage.pe.name"),
               field: "name",
               width: "300px",
               template: "#:name#"
            },
            {
               displayName: this.i18nService.getString("StorageUi", "storage.pe.type"),
               field: "type",
               width: "80px",
               template: "#:type#"
            },
            {
               displayName: this.i18nService.getString("StorageUi", "storage.pe.array"),
               field: "array",
               template: "#:storageArray#"
            }
         ];
      }
   }

   angular.module("com.vmware.vsphere.client.storage").component(
         "protocolEndpoints", <IComponentOptions>{
            controller: ProtocolEndpoints,
            templateUrl: "storage-ui/resources/storage/views/datastore/protocolEndpoints/ProtocolEndpointsComponent.html",
            bindings: {
               datastoreRef: "<",
               selectedItem: '='
            }
         });

}
