/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace network_ui {

   export class NetStackEditDialogPages {

      public static $inject = [
         "i18nService",
         "vuiConstants",
         "networkUiConstants",
         "netStackEditDnsConfigPageValidator",
         "netStackEditRoutingPageValidator",
         "netStackEditAdvancedPageValidator"
      ];

      constructor(private i18nService: any,
                  private vuiConstants: any,
                  private networkUiConstants: any,
                  private dnsConfigPageValidator: NetStackEditDnsConfigPageValidator,
                  private routingPageValidator: NetStackEditRoutingPageValidator,
                  private advancedPageValidator: NetStackEditAdvancedPageValidator) {

      }

      public createPages(dialogScope: any): any[] {
         let pages: any[] = [];

         if (dialogScope.netStackKey === this.networkUiConstants.TcpIpStack.DEFAULT_NET_STACK_KEY) {
            pages.push({
               title: this.i18nService.getString(
                     "NetworkUi", "EditDnsAndRoutingView.dnsSettingsView"),
               contentUrl: "network-ui/resources/network/views/host/netstack/edit/pages/netStackEditDnsConfigPage.html",
               onCommit: this.buildDnsConfigPageOnCommit(dialogScope)
            });
         }

         pages.push({
            title: this.i18nService.getString("NetworkUi", "EditDnsAndRoutingView.routingSettingsView"),
            contentUrl: "network-ui/resources/network/views/host/netstack/edit/pages/netStackEditRoutingPage.html",
            onCommit: this.buildRoutingPageOnCommit(dialogScope)
         }, {
            title: this.i18nService.getString("NetworkUi", "EditDnsAndRoutingView.name"),
            contentUrl: "network-ui/resources/network/views/host/netstack/edit/pages/netStackEditNamePage.html"
         });

         if (dialogScope.multipleNetworkStackInstanceSupported) {
            pages.push({
               title: this.i18nService.getString("NetworkUi", "EditDnsAndRoutingView.advancedProperties"),
               contentUrl: "network-ui/resources/network/views/host/netstack/edit/pages/netStackEditAdvancedPage.html",
               onCommit: this.buildAdvancedPageOnCommit(dialogScope)
            });
         }

         return pages;
      }

      private buildDnsConfigPageOnCommit(dialogScope: any): () => boolean {
         return (): boolean => {
            let dialogOptions: any = dialogScope.dialogOptions;
            let dnsConfigPageModel: NetStackEditDnsConfigPageModel =
                  dialogScope.dnsConfigPageModel;

            dialogOptions.validationBanner.messages = [];

            if (dnsConfigPageModel.isDhcpEnabled) {
               // No available virtual adapters validation message
               let noVnicsValidationMessage: string | null =
                     this.dnsConfigPageValidator
                           .getNoVirtualNicsValidationMessage(
                                 dnsConfigPageModel.vnicsWithDhcpEnabled);
               this.updateValidationBanner(noVnicsValidationMessage,
                     dialogOptions.validationBanner.messages);

               // No selected virtual adapter validation message
               let noSelectedVnicErrorMessage: string | null =
                     this.dnsConfigPageValidator
                           .getNoVnicSelectedValidationMessage(
                                 dnsConfigPageModel.vnicsWithDhcpEnabled,
                                 dnsConfigPageModel.selectedVnic);
               this.updateValidationBanner(noSelectedVnicErrorMessage,
                     dialogOptions.validationBanner.messages);
            } else {
               // Host name and domain name validation only for the default netStack
               if (dnsConfigPageModel.isDefaultNetStack) {
                  // Host name validation
                  let hostNameErrorMessage: string | null =
                        this.dnsConfigPageValidator
                              .getHostNameValidationMessage(dnsConfigPageModel.hostName);
                  this.updateValidationBanner(hostNameErrorMessage,
                        dialogOptions.validationBanner.messages);

                  // Domain name validation
                  let domainNameErrorMessage: string | null =
                        this.dnsConfigPageValidator
                              .getDomainNameValidationMessage(dnsConfigPageModel.domainName);
                  this.updateValidationBanner(domainNameErrorMessage,
                        dialogOptions.validationBanner.messages);
               }

               // Search domains validation
               let searchDomainsErrorMessage: string | null =
                     this.dnsConfigPageValidator
                           .getSearchDomainsValidationMessage(dnsConfigPageModel.searchDomains);
               this.updateValidationBanner(searchDomainsErrorMessage,
                     dialogOptions.validationBanner.messages);

               // Preferred DNS validation
               let preferredDnsErrorMessage: string | null =
                     this.dnsConfigPageValidator
                           .getPreferredDnsValidationMessage(dnsConfigPageModel.preferredDns,
                                 dnsConfigPageModel.isIpv6Enabled);
               this.updateValidationBanner(preferredDnsErrorMessage,
                     dialogOptions.validationBanner.messages);

               // Alternate DNS validation
               let alternateDnsErrorMessage: string | null =
                     this.dnsConfigPageValidator
                           .getAlternateDnsValidationMessage(dnsConfigPageModel.alternateDns,
                                 dnsConfigPageModel.isIpv6Enabled);
               this.updateValidationBanner(alternateDnsErrorMessage,
                     dialogOptions.validationBanner.messages);
            }

            return dialogOptions.validationBanner.messages.length === 0;
         };
      }

      private buildRoutingPageOnCommit(dialogScope: any): () => boolean {
         return (): boolean => {
            let dialogOptions: any = dialogScope.dialogOptions;
            let routingPageModel: NetStackEditRoutingPageModel =
                  dialogScope.routingPageModel;

            dialogOptions.validationBanner.messages = [];

            // IPv4 default gateway validation
            let defaultGatewayValidationMessage: string | null =
                  this.routingPageValidator.getIpv4GatewayValidationMessage(
                        routingPageModel.defaultGateway);

            if (defaultGatewayValidationMessage) {
               this.updateValidationBanner(defaultGatewayValidationMessage,
                     dialogOptions.validationBanner.messages);
            }

            // IPv6 default gateway validation
            let ipv6DefaultGatewayValidationMessage: string | null =
                  this.routingPageValidator.getIpv6GatewayValidationMessage(
                        routingPageModel.ipV6DefaultGateway);

            if (ipv6DefaultGatewayValidationMessage) {
               let error: string = this.i18nService
                     .getString("NetworkUi", "DnsAndRoutingPage.ipv4VmkernelGateway.error");
               this.updateValidationBanner(ipv6DefaultGatewayValidationMessage,
                     dialogOptions.validationBanner.messages);
            }

            return dialogOptions.validationBanner.messages.length === 0;
         };
      }

      private buildAdvancedPageOnCommit(dialogScope: any): () => boolean {
         return (): boolean => {
            let dialogOptions: any = dialogScope.dialogOptions;
            let advancedPageModel: NetStackEditAdvancedPageModel =
                  dialogScope.advancedPageModel;

            dialogOptions.validationBanner.messages = [];

            // max number of connections validation
            let maxNumberOfConnectionsValidationMessage: string | null =
                  this.advancedPageValidator.getMaxNumberOfConnectionsValidationMessage(
                        advancedPageModel.maxNumberOfConnections);

            if (maxNumberOfConnectionsValidationMessage) {
               this.updateValidationBanner(maxNumberOfConnectionsValidationMessage,
                     dialogOptions.validationBanner.messages);
            }

            return dialogOptions.validationBanner.messages.length === 0;
         };
      }

      private updateValidationBanner(message: string | null, messages: any[]): void {
         if (message) {
            messages.push({
               type: this.vuiConstants.validationBanner.type.ERROR,
               text: message
            });
         }
      }
   }

   angular.module("com.vmware.vsphere.client.network")
         .service("netStackEditDialogPages", NetStackEditDialogPages);
}
