/* Copyright 2015 VMware, Inc. All rights reserved. -- VMware Confidential */
/*
 * Datacenter service
 */
angular
   .module('com.vmware.vsphere.client.folder')
   .service('folderService', folderService);

folderService.$inject = ['mutationService', 'defaultUriSchemeUtil'];

function folderService (mutationService, defaultUriSchemeUtil) {
   var MUTATION_ADD_FOLDER_SPEC = 'com.vmware.vsphere.client.folder.FolderInfo';
   var MUTATION_REMOVE_FOLDER_SPEC = 'com.vmware.vsphere.client.folder.FolderRemovalSpec';

   this.createFolder = function(name, moRef, folderType) {
      var folderCreationSpec = {
         name: name,
         parent: defaultUriSchemeUtil.getManagedObjectReference(moRef),
         folderType: folderType
      };
      mutationService.add(MUTATION_ADD_FOLDER_SPEC, folderCreationSpec);
   };

   this.removeFolder = function(objectId) {
      //deleteFromDisk is changed from true to false, this was removing vm from disks too. this fix is for bug 2265349
      var folderRemovalSpec = {
         deleteFromDisk: false
      };
      mutationService.remove(objectId, MUTATION_REMOVE_FOLDER_SPEC, folderRemovalSpec);
   };
}
