angular.module('com.vmware.vsphere.client.vm').service('vmMigrationSelectPriorityPageService', [
   'i18nService',
   'vuiConstants',
   'wizardPageService',
   function (i18nService, vuiConstants, wizardPageService) {
      return {
         build: function (wizardScope) {

            return {
               title: i18nService.getString('VmUi', 'MigrationWizard.priorityPage.title'),
               description: i18nService.getString('VmUi', 'MigrationWizard.priorityPage.description'),
               contentUrl: 'vm-ui/resources/vm/views/migrationWizard/pages/selectPriorityPage.html',
               headerUrl: 'vm-ui/resources/vm/views/migrationWizard/pages/migratePagesCustomHeader.html',
               onCommit: function () {
                  wizardPageService.markPageComplete(wizardScope.wizardConfig, wizardScope.attributes.creationType);
                  return true;
               }
            };
         }
      };
   }]
);
