/* Copyright 2015 VMware, Inc. All rights reserved. -- VMware Confidential */

/*
 * Controller for the 'Edit notes' action.
 */
angular.module('com.vmware.vsphere.client.commonModule')
      .controller('NotesController',
            ['$scope', 'dataService', 'actionsService', 'i18nService',
     function($scope, dataService, actionsService, i18nService) {
   var self = this;
   var objectId = $scope._route.objectId;
   var notesPropName, editNotesActionId;
   var liveRefreshProperties = [];
   if ($scope.portlet._view.contentSpec.metadata) {
      notesPropName = $scope.portlet._view.contentSpec.metadata.notesPropName;
      editNotesActionId = $scope.portlet._view.contentSpec.metadata.editNotesActionId;
      liveRefreshProperties.push(notesPropName);
   }

   self.watchForObjects = [objectId];
   self.getNotes = getNotes;
   self.liveRefreshProperties = liveRefreshProperties;

   function getNotes() {
      if (!notesPropName) {
         throw new Error("Unknown notes property name");
      }

      dataService
         .getProperties(objectId, [notesPropName])
         .then(function(data) {
            self.notes = data[notesPropName];
         });
   }

   /**
    * Create a footer link in portlet for the 'edit notes' action.
    */
   function createLink() {
      if (!editNotesActionId) {
         return;
      }

      // TODO this is a work-around to avoid an ActionServiceController exception when using actionService.getAction
      // (case where the EditNotes action is being evaluated and filtered out at the same time)
      actionsService.getActions([$scope._route.objectId], [editNotesActionId]
            ).then(function(res) {
               if (res) {
                  createFooterLink(res);
               }
            });
   }

   function createFooterLink(actionDataArray) {
      var linkLabel = i18nService.getString('CommonModuleUi', 'notes.editLabel');
      var enabled = actionDataArray && actionDataArray.length > 0 && actionDataArray[0].available;

      $scope.portlet.footerLinks = [{
         enabled: enabled,
         label: linkLabel,
         onClick: function($event) {
            var target = $event.currentTarget;
            actionsService.invokeAction(actionDataArray[0], {focusTarget: target});
         }
      }];
   }

   getNotes();
   createLink();
}]);
