/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace dvs_ui {

   import IPromise = angular.IPromise;
   import HostCompatibilityListItem = com.vmware.vsphere.client.h5.network.dvs.upgrade.model.HostCompatibilityListItem;

   export class DvsHostCompatibilityListComponent {

      public bindings: any;
      public controller: any;
      public templateUrl: string;

      constructor() {
         this.bindings = {
            hostCompatibilityData: "<"
         };

         this.controller = DvsHostCompatibilityListController;
         this.templateUrl =
               "dvs-ui/resources/dvs/upgrade/components/dvsHostCompatibilityListTemplate.html";
      }
   }

   class DvsHostCompatibilityListController {

      static $inject = [
         "vuiConstants",
         "i18nService",
         "columnRenderersRegistry",
         "dvsHostCompatibilityService"
      ];

      public hostCompatibilityData: HostCompatibilityListItem[];

      private datagridOptions: any;
      private datagridDescription: any;
      private splitterOptions: any;

      private iconTextRenderer: any;

      private compatibilityIssuesHeader: string;
      private hostCompatibilityIssues: string[];
      private hasCompatibilityError: boolean = false;

      constructor(private vuiConstants: any,
                  private i18nService: any,
                  private columnRenderersRegistry: any,
                  private dvsHostCompatibilityService: any) {
         this.datagridDescription = this.i18nService.getString(
               "DvsUi", "DvsHostCompatibilityList.compatibilityIssuesHeader");
         this.datagridOptions = {
            height: "100%",
            pageConfig: {
               hidePager: true
            },
            columnDefs: this.buildColumnDefinitions(),
            sortMode: this.vuiConstants.grid.sortMode.SINGLE,
            selectionMode: this.vuiConstants.grid.selectionMode.SINGLE,
            selectedItems: [],
            resizable: true,
            data: this.hostCompatibilityData,
            actionBarOptions: {
               actions: []
            },
            onChange: this.onSelectionChange.bind(this)
         };

         this.compatibilityIssuesHeader = this.i18nService.getString(
               "DvsUi", "DvsHostCompatibilityList.compatibilityIssuesHeader");
      }

      public $onInit() {

         this.splitterOptions = {
            orientation: this.vuiConstants.splitter.orientation.VERTICAL,
            panes: [{size: '70%', min: '120px'}, {size: '30%', min: '50px'}]
         };

         this.iconTextRenderer =
               this.columnRenderersRegistry.getColumnRenderer('icon-text');
      }

      public $onChanges(): void {
         this.datagridOptions.data = this.hostCompatibilityData ?
               this.hostCompatibilityData : [];
      }

      private setOverallCompatibilityStatus(): void {
         if (!this.hostCompatibilityData || this.hostCompatibilityData.length === 0) {
            this.hostCompatibilityIssues = [this.i18nService.getString(
                  "DvsUi", "DvsHostCompatibilityPage.noHosts")];
            this.hasCompatibilityError = false;
         } else if (this.dvsHostCompatibilityService.isIncompatibleHostAvailable(
               this.hostCompatibilityData)) {
            this.hostCompatibilityIssues = [this.i18nService.getString(
                  "DvsUi", "DvsHostCompatibilityPage.incompatibleHostsPresent")];
            this.hasCompatibilityError = true;
         } else {
            this.hostCompatibilityIssues = [this.i18nService.getString(
                  "DvsUi", "DvsHostCompatibilityPage.allHostsCompatible")];
            this.hasCompatibilityError = false;
         }
      }

      private buildColumnDefinitions() {
         return [{
            displayName: this.i18nService.getString(
                  "DvsUi", "DvsHostCompatibilityList.hostColumnHeader"),
            field: "hostName",
            width: "300px",
            template: (item: HostCompatibilityListItem): string => {
               return this.iconTextRenderer(
                     ["hostIcon", "hostName"], {
                        hostName: item.hostName,
                        hostIcon: item.hostIcon
                     });
            }
         }, {
            displayName: this.i18nService.getString(
                  "DvsUi", "DvsHostCompatibilityList.compatibilityColumnHeader"),
            width: "100%",
            template: (item: HostCompatibilityListItem): string =>  {
               let isHostCompatible = this.isHostCompatible(item);

               let compatibilityStatus: string = isHostCompatible
                     ? this.i18nService.getString(
                           "DvsUi", "DvsHostCompatibilityList.compatibleText",
                           item.hostName)
                     : this.i18nService.getString(
                           "DvsUi", "DvsHostCompatibilityList.incompatibleText");

               return isHostCompatible ?
                     `<span><span class='vsphere-icon-status-ok'></span>` +
                     `<span class='vertical-align-cell'>${compatibilityStatus}</span></span>` :
                     `<span><clr-icon shape='error' size='24' ` +
                     `class='is-error dvs-host-compatibility-error'></clr-icon>` +
                     `<span class='vertical-align-cell'>${compatibilityStatus}</span></span>`;
            }
         }];
      }

      private isHostCompatible(item: HostCompatibilityListItem): boolean {
         return item.compatibilityErrors === null ||
               item.compatibilityErrors.length === 0;
      }

      public onSelectionChange(items: HostCompatibilityListItem[]): void {
         if (items && items.length > 0) {
            if (this.isHostCompatible(items[0])) {
               this.hostCompatibilityIssues = [this.i18nService.getString("DvsUi",
                     "DvsHostCompatibilityList.compatibleHost", items[0].hostName)];
               this.hasCompatibilityError = false;
            } else {
               this.hostCompatibilityIssues = items[0].compatibilityErrors;
               this.hasCompatibilityError = true;
            }
         } else {
            this.setOverallCompatibilityStatus();
         }
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .component("dvsHostCompatibilityList", new DvsHostCompatibilityListComponent());
}
