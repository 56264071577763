namespace h5_vm {
   import VmConfigContext = com.vmware.vsphere.client.vm.config.VmConfigContext;

   class VmHardwareVirtualDiskComponent implements ng.IComponentOptions {

      public templateUrl: string = "vm-ui/resources/vm/views/settings/vmHardwareSettings/vmHardwareVirtualDisk/vmHardwareVirtualDisk.html";
      public controllerAs = 'ctrl';
      public controller: any = VmHardwareVirtualDiskController;

      public bindings: any = {
         inflatedDevice: '<',
         selectedStorageProfile: '<',
         selectedDiskFormat: '<',
         storageBaselineId: '<',
         getAdvancedConfigurationForDisk: '&',
         getStorageForDisk: '&',
         storageProfiles: '<',
         isVmEncrypted: '<',
         createMode: '<',
         cloneMode: '<',
         deployVmtxMode: '<',
         virtualMachineDevices: '<',
         setStorageForDisk: '&',
         vmConfigContext: '<',
         vmStorageId: '@',
         vmAssignmentsProperties: '<',
         computeResourceId: '<',
         vmHomeReplicationGroup: '<',
         storageLocatorItemsData: '<',
         removeCallback: '&',
         vmId: '<',
         showStoragePods: '<',
         expanded: '=',
         keepExistingProfileAssignments: '<',
         isGroupingHardDisks: '<'
      };
   }

   class VmHardwareVirtualDiskController implements ng.IComponentController {

      public static $inject: string[] =
            [
               '$q',
               '$scope',
               '$rootScope',
               '$element',
               'i18nService',
               'virtualDiskSettingsFormService',
               'diskProvisioningService',
               'vmDatastoreLocationBrowserService',
               'defaultUriSchemeUtil',
               'datastoreService',
               'authorizationService',
               'VirtualDisk',
               'vmHardwareVirtualDiskService',
               'vmDeviceInfoService',
               'managedEntityConstants',
               'deviceClassLineageService',
               '$timeout',
               'diskBackingInfoConstants',
               'storageProfileService',
               'spbmReplicationGroupInfoService',
               'deviceTypeConstants',
               'virtualDiskPmemService',
               'vmCryptUtilService',
               'basicDiskUtilService'
            ];

      constructor(private $q: any,
                  private $scope: any,
                  private $rootScope: any,
                  private $element: any,
                  private i18nService: any,
                  private virtualDiskSettingsFormService: any,
                  private diskProvisioningService: any,
                  private vmDatastoreLocationBrowserService: any,
                  private defaultUriSchemeUtil: any,
                  private datastoreService: any,
                  private authorizationService: any,
                  private VirtualDisk: any,
                  private vmHardwareVirtualDiskService: any,
                  private vmDeviceInfoService: any,
                  private managedEntityConstants: any,
                  private deviceClassLineageService: any,
                  private $timeout: any,
                  private diskBackingInfoConstants: any,
                  private storageProfileService: any,
                  private spbmReplicationGroupInfoService: any,
                  private deviceTypeConstants: any,
                  private virtualDiskPmemService: any,
                  private vmCryptUtilService: any,
                  private basicDiskUtilService: any) {
         return;
      }

      // BEGIN COMPONENT BUNDINGS
      private inflatedDevice: any;
      private selectedStorageProfile: any;
      private selectedDiskFormat: any;
      private storageBaselineId: any;
      private getAdvancedConfigurationForDisk: any;
      private getStorageForDisk: any;
      private storageProfiles: any;
      private isVmEncrypted: boolean;
      private createMode: boolean;
      private cloneMode: boolean;
      private deployVmtxMode: boolean;
      private virtualMachineDevices: any;
      private setStorageForDisk: any;
      private vmConfigContext: VmConfigContext;
      private vmStorageId: any;
      private vmAssignmentsProperties: any;
      private computeResourceId: any;
      private vmHomeReplicationGroup: any;
      private storageLocatorItemsData: any;
      private removeCallback: any;
      private vmId: any;
      private showStoragePods: boolean;
      private expanded: boolean;
      private keepExistingProfileAssignments: boolean;
      private isGroupingHardDisks: boolean;
      // END COMPONENT BINDINGS


      private DELETE_FILE_FROM_DISK_OPERATION: string = 'destroy';

      private selectedStorage: any;
      private buildStorageSelectConfig: any;
      private checkDiskLocationPrivilege: any;
      private setDiskLocationValidity: any;

      private device: any;
      private datastoreInfo: any;
      private isPoweredOn: boolean;
      private deviceName: any;
      private formKey: any;
      private controllerSelectorName: any;
      private controllerNodeSelectorName: any;
      private deleteFilesFromDisk: any;
      private formId: any;
      private diskLocationErrorConfig: any;
      private diskProfileErrorConfig: any;
      private isPmemDisk: boolean;
      private isExistingPmemDisk: boolean;
      private isVmHomeProfileChanged: boolean;
      private isDiskAlreadyEncrypted: boolean;
      private isStorageProfileChanged: boolean;
      private isEncryptionProfileApplied: any;
      private virtualDiskSettingsForm: any;
      private replicationGroupItems: any;
      private selectedReplicationGroupItem: any;
      private replicationGroupErrorConfig: any;
      private datastores: any;
      private isRDMDisk: boolean;
      private customValidators: any[];

      private browseDialogOption: any;
      private storeWithVmOption: any;

      private i18n(res: string, key: string, ...args: any[]): string {
         let retVal: string = this.i18nService.getString(res, key, ...args);
         return retVal;
      }

      public $onInit() {
         const self = this;

         self.device = self.inflatedDevice.getCurrentDevice();

         self.datastoreInfo = this.datastoreService.findDatastore(
               self.vmStorageId,
               self.vmConfigContext.environment.configTarget);
         self.isPoweredOn = self.vmConfigContext.environment.powerState === 'poweredOn';
         self.deviceName = self.device.deviceInfo.label;
         self.formKey = self.inflatedDevice.getKey().toString().replace('-', '__');
         self.controllerSelectorName = 'editVirtualDeviceController' + self.formKey;
         self.controllerNodeSelectorName = 'editVirtualDeviceNode' + self.formKey;
         self.deleteFilesFromDisk = false;
         if (self.inflatedDevice.getDiskFileOperation() === this.DELETE_FILE_FROM_DISK_OPERATION) {
            self.deleteFilesFromDisk = true;
         }
         self.formId = 'vmHardwareVirtualDiskForm' + self.formKey;
         self.diskLocationErrorConfig = {
            message: self.i18n('VmUi',
                  'SelectDatastoreProvisioningPage.NoPermissionOnDatastore'),
            isVisible: false
         };

         self.diskProfileErrorConfig = {
            message: undefined,
            isVisible: false
         };
         self.isPmemDisk = self.inflatedDevice.isPmemDisk();
         self.isExistingPmemDisk =
               self.inflatedDevice.isPmemDisk() && !self.inflatedDevice.isNew();

         self.isVmHomeProfileChanged = false;
         self.isDiskAlreadyEncrypted = self.inflatedDevice.isDiskAlreadyEncrypted();
         self.isStorageProfileChanged = false;
         self.isEncryptionProfileApplied = self.inflatedDevice.isEncryptionProfileApplied();


         this.$scope.$watch(function () {
            return (self.vmConfigContext as any).vmProfile;
         }, _.bind(function (newValue: any, oldValue: any) {
            if (newValue !== oldValue) {
               self.isVmHomeProfileChanged = true;
               self.checkEncryptionWarning();
            }
         }, self));

         this.$scope.$watch(function () {
            return self.inflatedDevice.storageProfile;
         }, _.bind(function (newValue: any, oldValue: any) {
            if (newValue !== oldValue) {
               self.updateProfile(newValue, null, false);
               if (self.virtualDiskSettingsForm.storageProfile) {
                  self.virtualDiskSettingsForm.storageProfile.selection = newValue;
               }
            }
         }, self));

         self.replicationGroupItems = [];
         self.selectedReplicationGroupItem = null;

         self.replicationGroupErrorConfig = {
            message: "",
            isVisible: false
         };

         self.virtualMachineDevices.addRgValidationCallback(
               self.device.key, this.validateReplicationGroupSelection.bind(this));

         this.$rootScope.$on(this.vmHardwareVirtualDiskService.DISK_STACK_EXPANDED, function (event: any, key: any) {
            if (self.inflatedDevice.getKey() === key.deviceKey) {
               //nothing to do, just return
               return;
            }
            self.expanded = false;
         });

         this.$q.all(this.getAllBackendStorageCallPromises()).then(function (results: any) {

            self.storageProfiles = results.storageProfilesPromiseResult;

            self.vmAssignmentsProperties = results.vmAssignmentsPromiseResult || {};

            if (!(self.createMode || self.cloneMode)
                  && self.vmAssignmentsProperties
                  && self.vmAssignmentsProperties.vmStorageProfileAssignments && self.storageProfiles) {

               var currentDevice = self.inflatedDevice.getCurrentDeviceSpec().device;
               var replicationGroup = null;
               if (currentDevice && currentDevice.key) {
                  _.forEach(results.vmAssignmentsPromiseResult.vmReplicationGroupAssignments,
                        function (rgAssignment: any) {
                           if (rgAssignment && rgAssignment.vmObjectId) {
                              var vmObjectIdSegments = rgAssignment.vmObjectId.split(":");
                              if (vmObjectIdSegments.length > 1 &&
                                    vmObjectIdSegments[1] === currentDevice.key.toString()) {
                                 replicationGroup = rgAssignment.replicationGroup;
                              }
                           }
                        });
               }

               var profile = self.storageProfileService.makeProfileFromDevice(
                     currentDevice,
                     self.storageProfiles,
                     self.vmAssignmentsProperties.vmStorageProfileAssignments,
                     replicationGroup,
                     self.inflatedDevice.isNew());

               self.inflatedDevice.setOriginalProfile(profile, replicationGroup);
            }

            if (self.inflatedDevice.isNew()) {
               self.virtualDiskSettingsForm = self.virtualDiskSettingsFormService.build(
                     self.vmAssignmentsProperties.vmStorageProfileAssignments,
                     self.createMode || self.cloneMode,
                     self.vmConfigContext,
                     self.inflatedDevice,
                     self.vmStorageId,
                     self.selectedStorageProfile,
                     self.storageProfiles,
                     self.virtualMachineDevices
               );
               // if there is already selected disk provisioning type - use it, otherwise default
               // to the first option
               var diskProvisioningType = self.virtualDiskSettingsForm.selectedDiskProvisioningOption ?
                     self.virtualDiskSettingsForm.selectedDiskProvisioningOption.type :
                     self.virtualDiskSettingsForm.diskProvisioningOptions[0].type;
               self.updateDiskProvisioningType(diskProvisioningType);

               if (self.virtualDiskSettingsForm.storageProfile
                     && self.virtualDiskSettingsForm.storageProfile.selection) {
                  self.updateProfile(
                        self.virtualDiskSettingsForm.storageProfile.selection,
                        self.inflatedDevice.getCurrentReplicationGroup());
               }

               var lastPrivCheckedStorageId = {
                  id: null,
                  hasPrivilege: true
               };
               self.storeWithVmOption = {name: self.i18n('VmUi', 'DiskPage.StoreWithVM')};
               self.browseDialogOption = {name: self.i18n('VmUi', 'DiskPage.Browse')};
               self.buildStorageSelectConfig = function (selectedStorage: any) {
                  if (selectedStorage) {
                     var selectedStorageOption = {
                        name: selectedStorage.name,
                        id: selectedStorage.id,
                        storageObject: selectedStorage.storageObject
                     };
                     return {
                        options: [self.storeWithVmOption, selectedStorageOption, self.browseDialogOption],
                        selection: selectedStorageOption
                     };
                  } else {
                     return {
                        options: [self.storeWithVmOption, self.browseDialogOption],
                        selection: self.storeWithVmOption
                     };
                  }
               };

               self.checkDiskLocationPrivilege = function (storageId: any) {
                  var storageType = self.defaultUriSchemeUtil.getEntityType(storageId);
                  //if storage pod, do not check privileges
                  if (storageType === self.managedEntityConstants.STORAGE_POD) {
                     self.setDiskLocationValidity(true);
                     return;
                  }
                  //if we go back to the already selected storage
                  //do not check for privileges second time, but
                  //invalidate the form if needed (for errors to appear)
                  if (lastPrivCheckedStorageId.id === storageId) {
                     self.setDiskLocationValidity(lastPrivCheckedStorageId.hasPrivilege);
                     return;
                  }

                  lastPrivCheckedStorageId.id = storageId;
                  var priv = ['Datastore.AllocateSpace'];
                  var promise = self.authorizationService.checkPrivileges(storageId, priv);
                  return promise.then(function (privilegeStatuses: any) {
                     var hasPriv = privilegeStatuses[0];
                     lastPrivCheckedStorageId.hasPrivilege = hasPriv;
                     self.setDiskLocationValidity(hasPriv);
                  });
               };

               self.setDiskLocationValidity = function (isValid: boolean) {
                  self.diskLocationErrorConfig.isVisible = !isValid;
                  self.$timeout(function () {
                     var form = (self as any)[self.formId];
                     if (form) {
                        var diskLocationElem = form['disk-location'];
                        if (diskLocationElem) {
                           diskLocationElem.$invalid = !isValid;
                        }
                        (self as any)[self.formId].$setValidity('diskLocation', isValid);
                     }
                  }, 50);
               };

               let selectedStorage: any = null;
               if (self.getStorageForDisk) {
                  var selectedStorageForDisk = self.getStorageForDisk(
                        {diskKey: self.device.key});
                  if (selectedStorageForDisk) {
                     selectedStorage = {
                        key: selectedStorageForDisk.dsKey,
                        id: selectedStorageForDisk.dsId,
                        name: selectedStorageForDisk.dsName,
                        storageObject: selectedStorageForDisk.dsObject
                     };
                  }
               }
               self.datastores = self.buildStorageSelectConfig(selectedStorage);
            } else {
               self.virtualDiskSettingsForm = self.virtualDiskSettingsFormService.build(
                     self.vmAssignmentsProperties.vmStorageProfileAssignments,
                     self.createMode || self.cloneMode,
                     self.vmConfigContext,
                     self.inflatedDevice,
                     self.vmStorageId,
                     null,
                     self.storageProfiles,
                     self.virtualMachineDevices);
            }

            self.applyPmemPostProcessingToDisk();

            self.postInit();
         });

         this.$scope.$on('deviceAdded', function (event: any, device: any) {
            if (self.isAddedDeviceAController(device.deviceType)) {
               self.rebuildControllersDropdown();
            }
         });
         this.$scope.$on('deviceRemoved', function (event: any, device: any) {
            if (self.inflatedDevice.isOfType("VirtualDisk")) {
               self.$rootScope.$broadcast(self.vmHardwareVirtualDiskService.DISKS_UPDATED);
            }

            if (self.isAddedDeviceAController(device.deviceType)) {
               self.rebuildControllersDropdown();
            }
            self.validateReplicationGroupSelection();
         });

         self.isDiskEditable = this.determineDiskEditability();
         self.isRDMDisk = this.determineIfRdmDisk();
         self.customValidators = [
            {
               name: 'non-zero',
               validate: function (value: any) {
                  return value !== 0;
               },
               message: self.i18n('VmUi', 'DiskConfig.ErrorCapacityZero')
            }
         ];

         this.buildIopsLimitDetails();

      };

      private _unitChangedCallback: any = null;

      private get unitChangedCallback(): any {
         const self = this;

         if (!this._unitChangedCallback) {
            this._unitChangedCallback = function (value: any) {
               self.onUnitChanged(value);
            };
         }
         return this._unitChangedCallback;
      }

      private onUnitChanged(newUnit: any) {
         const self = this;
         self.virtualDiskSettingsForm.capacity.memoryUnitPreferred = newUnit;
         self.inflatedDevice.getCurrentDeviceSpec().memoryUnitPreferred = newUnit;
      };

      private onValueChanged() {
         const self = this;
         self.inflatedDevice.updateCapacity(
               self.virtualDiskSettingsForm.capacity.valueInMB);

         this.$rootScope.$broadcast(this.vmHardwareVirtualDiskService.DISK_SIZE_UPDATED, {
            size: self.virtualDiskSettingsForm.capacity.valueInMB,
            units: self.virtualDiskSettingsForm.capacity.memoryUnitPreferred.label
         });
      };

      private isExpandable() {
         const self = this;
         if (self.deployVmtxMode) {
            return false;
         }
         if (!self.virtualDiskSettingsForm) {
            return false;
         } else {
            return true;
         }
      };

      private _updateProfileFromUiCallback: any = null;

      private get updateProfileFromUiCallback(): any {
         let self = this;
         if (!this._updateProfileFromUiCallback) {
            this._updateProfileFromUiCallback = function (value: any) {
               self.updateProfileFromUi(value);
            };
         }
         return this._updateProfileFromUiCallback;
      }

      private updateProfileFromUi(selectedStorageProfile: any) {
         const self = this;
         self.updateProfile(selectedStorageProfile);
         self.inflatedDevice.setStorageProfileManuallySet(true);
         this.updateReplicationGroupData(selectedStorageProfile);
      };

      private updateProfile(selectedStorageProfile: any, replicationGroupInfo: any = undefined, broadcastEvent: any = undefined) {
         const self = this;
         self.isStorageProfileChanged = true;
         replicationGroupInfo = replicationGroupInfo ||
               (self.selectedReplicationGroupItem
                     ? self.selectedReplicationGroupItem.replicationGroupInfo
                     : null);

         self.inflatedDevice.updateDiskProfile(selectedStorageProfile, replicationGroupInfo);

         self.isPmemDisk = this.virtualDiskPmemService.handlePmemIfPmemProfile(
               self.inflatedDevice, selectedStorageProfile, self.selectedDiskFormat);
         self.isEncryptionProfileApplied = self.inflatedDevice.isEncryptionProfileApplied();
         if (!self.isEncryptionProfileApplied) {
            self.inflatedDevice.getCurrentDevice().backing.keyId = null;
         }

         self.checkEncryptionWarning();

         if (broadcastEvent !== false) {
            this.$rootScope.$broadcast(this.vmHardwareVirtualDiskService.DISKS_UPDATED);
         }
      };

      private checkEncryptionWarning() {
         const self = this;
         // VM Home must be encrypted in order to encrypt disks
         if (self.isEncryptionProfileApplied && !self.isVmHomeEncrypted()) {
            self.setDiskProfileValidity(false,
                  self.i18n('VmUi', 'DiskConfig.ErrorVmHomeEncryptionRequired'));
            return;
         }

         // Can't encrypt while adding existing disk
         if (self.inflatedDevice.isCreatedFromExistingDisk()
               && self.isEncryptionProfileApplied && !self.isDiskAlreadyEncrypted) {
            self.setDiskProfileValidity(false,
                  self.i18n('VmUi', 'DiskConfig.ErrorNoEncryptionOnAttach'));
            return;
         }

         // Can't decrypt while adding existing disk
         if (self.inflatedDevice.isCreatedFromExistingDisk()
               && !self.isEncryptionProfileApplied && self.isDiskAlreadyEncrypted) {
            self.setDiskProfileValidity(false,
                  self.i18n('VmUi', 'DiskConfig.ErrorNoDecryptionOnAttach'));
            return;
         }

         self.setDiskProfileValidity(true);
      };

      private isVmHomeEncrypted() {
         const self = this;
         // Check if the VM Home profile is encryption one
         if ((self.vmConfigContext as any).vmProfile) {
            var vmHomeProfile = this.storageProfileService.findProfile(
                  self.storageProfiles, (self.vmConfigContext as any).vmProfile[0]);
            self.isVmEncrypted = this.storageProfileService
                  .isEncryptionStorageProfile(vmHomeProfile);
            return self.isVmEncrypted;
         }

         // If the VM profile is not changed and VM Home is encrypted - return TRUE.
         // The key will be empty when in provisioning mode,
         // so only return if there is a key.
         if (!self.isVmHomeProfileChanged &&
               !this.vmCryptUtilService.isCryptoKeyEmpty(self.vmConfigContext.config.keyId)) {
            self.isVmEncrypted = true;
            return self.isVmEncrypted;
         }

         return self.isVmEncrypted;
      };

      private getDiskEncryptionStatus() {
         const self = this;
         return this.vmCryptUtilService.getDiskEncryptionStatus(self.isDiskAlreadyEncrypted,
               self.isStorageProfileChanged, self.isEncryptionProfileApplied);
      };

      setDiskProfileValidity(isValid: boolean, errorMessage: string | undefined = undefined) {
         const self = this;

         self.diskProfileErrorConfig.isVisible = !isValid;
         self.diskProfileErrorConfig.message = errorMessage;

         var form = (self as any)[self.formId];
         if (form) {
            var diskProfileElem = form['storage-profile'];
            if (diskProfileElem) {
               diskProfileElem.$invalid = !isValid;
            }
            (self as any)[self.formId].$setValidity('diskProfile', isValid);
         }
      };

      private updateDiskProvisioningType(selectedProvisionOptionType: any) {
         var backingType = this.diskProvisioningService.backingInfoKeys(selectedProvisionOptionType);
         this.inflatedDevice.updateDiskProvisioningOption(backingType);
      };

      private updateDiskController(selectedController: any, selectedControllerNode: any) {
         if (selectedControllerNode) {
            this.inflatedDevice.updateControllerAndNodeKeys(selectedController.getKey(), selectedControllerNode.key);
         }
      };

      /** We want to show the disk file in the following cases:
       * - when we edit an existing disk
       * - when we add a disk from an existing one
       * We don't want to show it when:
       * - creating a new disk
       * - cloning an existing disk
       */
      private showDiskFile() {
         const self = this;
         return self.expanded &&
               ((!self.createMode && !self.cloneMode)
                     || self.inflatedDevice.isCreatedFromExistingDisk())
               && !!self.virtualDiskSettingsForm && !!self.virtualDiskSettingsForm.diskFile;
      };

      private isRemoveDisabled() {
         const self = this;
         if (!self.createMode) {
            return this.vmHardwareVirtualDiskService.isRemoveDiskDisabled(self.vmConfigContext.privileges);
         }

         return false;
      };

      private onRemoveHardDisk() {
         const self = this;
         if (!self.isRemoveDisabled() && self.removeCallback) {
            self.removeCallback()(self.inflatedDevice);
            if (self.isPmemDisk) {
               self.deleteFilesFromDisk = true;
               self.updateDiskFileOperation();
            }
         }
      };

      private isEditIopsLimitDisabled() {
         const self = this;
         return self.isPmemDisk || this.vmHardwareVirtualDiskService.isEditIopsLimitDisabled(
               self.vmConfigContext.privileges, self.createMode);
      };

      private getPmemMax() {
         const self = this;
         return this.virtualDiskPmemService.getMaxAllowedCapacity(
               self.vmConfigContext, self.inflatedDevice, self.virtualMachineDevices);
      };

      private getDiskText() {
         const self = this;
         if (!self.virtualDiskSettingsForm) {
            return '';
         }
         var diskSize = this.basicDiskUtilService.determineDisplayDiskSize(
               self.virtualDiskSettingsForm.capacity.valueInMB);
         return diskSize ?
               (this.basicDiskUtilService.roundToPrecision(diskSize.size, 2).toString() + ' ' + diskSize.unitText) :
               '';
      };

      private shouldShowDiskSizeLabelOnly() {
         const self = this;
         if (!self.virtualDiskSettingsForm) {
            return true;
         }

         return !self.inflatedDevice.isNew() && self.isGroupingHardDisks &&
               (!self.virtualDiskSettingsForm.capacity.validUnits ||
                     !self.expanded);
      };

      private getMax() {
         const self = this;
         if (this.isExistingDiskAddition()) {
            //To Skip validation errors on a disabled field
            return Number.MAX_VALUE;
         } else {
            if (self.isPmemDisk) {
               return self.getPmemMax();
            }
            return self.virtualDiskSettingsForm.capacity.maxInMB;
         }
      };

      private isDiskEditable: boolean;

      private getMin() {
         const self = this;
         if (this.isExistingDiskAddition()) {
            //To Skip validation errors on a disabled field
            return Number.MIN_VALUE;
         } else {
            if (!self.isDiskEditable) {
               return Number.MIN_VALUE;
            }
            if (self.isExistingPmemDisk) {
               return self.inflatedDevice.getOriginalCapacityInMB();
            }
            if (self.isPmemDisk) {
               return this.virtualDiskPmemService.getDefaultMinValueForNewPmemDisk();
            }
            return self.virtualDiskSettingsForm.capacity.minInMB;
         }
      };

      private onReplicationGroupSelectionChange() {
         const self = this;
         self.updateProfile(self.virtualDiskSettingsForm.storageProfile.selection);
         this.validateReplicationGroupSelection();
         self.virtualMachineDevices.notifyRgValidationCallbacks();
      };

      private onDatastoreSelectionChange(newSelection: any, oldSelection: any) {
         const self = this;
         if (newSelection.name === this.browseDialogOption.name) {
            if (self.getStorageForDisk) {
               this.selectedStorage = self.getStorageForDisk({diskKey: self.device.key});
            }

            //set the storageObject.storageRef if it is missing, so we can keep the selection
            //in the location browser window, even when going back and forward
            //through the wizard's pages
            if ((!oldSelection.storageObject || !oldSelection.storageObject.storageRef)
                  && oldSelection.id) {

               oldSelection.storageObject = oldSelection.storageObject ?
                     oldSelection.storageObject : {};

               oldSelection.storageObject.storageRef =
                     this.defaultUriSchemeUtil.getManagedObjectReference(oldSelection.id);
            }

            this.getStorageLocatorItemsForCreateOrEditMode().then(function (result: any) {
               var selectedProfile = self.virtualDiskSettingsForm.storageProfile
                     ? self.virtualDiskSettingsForm.storageProfile.selection
                     : undefined;
               self.vmDatastoreLocationBrowserService.choose(self.$scope, {
                  storageProfilesData: {
                     storageProfiles: self.storageProfiles,
                     selectedProfile: selectedProfile
                  },
                  storageData: {
                     storageLocatorItemsData: result,
                     selectedStorageItem: oldSelection.storageObject
                  },
                  disableSdrs: !self.showStoragePods
               }).then(
                     function (selectionsFromBrowserService: any) {
                        if (!self.virtualDiskSettingsForm.storageProfile) {
                           self.virtualDiskSettingsForm.storageProfile = {};
                        }

                        self.virtualDiskSettingsForm.storageProfile.selection =
                              selectionsFromBrowserService.selectedStorageProfile;
                        self.updateProfile(
                              selectionsFromBrowserService.selectedStorageProfile);
                        self.datastores = self.buildStorageSelectConfig(
                              selectionsFromBrowserService.selectedStorageItem);
                        self.checkDiskLocationPrivilege(self.datastores.selection.id);
                        self.setCapacityAndErrorMessage(
                              self.datastores.selection, self.datastores.selection.id);

                        self.updateReplicationGroupData(
                              selectionsFromBrowserService.selectedStorageProfile,
                              selectionsFromBrowserService.selectedStorageItem.storageObject.storageRef);

                        self.virtualDiskSettingsForm.diskProvisioningOptions =
                              self.virtualDiskSettingsFormService.buildDiskProvisioningTypes(
                                    self.datastores.selection.storageObject.type,
                                    self.datastores.selection.storageObject.vStorageSupport);
                        self.virtualDiskSettingsForm.selectedDiskProvisioningOption =
                              self.virtualDiskSettingsForm.diskProvisioningOptions[0];
                     }, function () {
                        self.datastores.selection = oldSelection;
                     });
            });
         } else if (newSelection.name === self.storeWithVmOption.name) {
            this.setDiskLocationValidity(true);
            this.setCapacityAndErrorMessage(null);
            this.updateReplicationGroupData();
            self.virtualDiskSettingsForm.diskProvisioningOptions =
                  this.virtualDiskSettingsFormService.buildDiskProvisioningTypes(
                        self.datastoreInfo ? self.datastoreInfo.datastore.type : "",
                        self.datastoreInfo ? self.datastoreInfo.vStorageSupport : "");
            self.virtualDiskSettingsForm.selectedDiskProvisioningOption =
                  self.virtualDiskSettingsForm.diskProvisioningOptions[0];
         } else {
            this.checkDiskLocationPrivilege(newSelection.id);
            this.setCapacityAndErrorMessage(newSelection, newSelection.id);

            this.updateReplicationGroupData(null, self.datastores.selection.storageObject.storageRef);

            self.virtualDiskSettingsForm.diskProvisioningOptions =
                  this.virtualDiskSettingsFormService.buildDiskProvisioningTypes(
                        self.datastores.selection.storageObject.type,
                        self.datastores.selection.storageObject.vStorageSupport);
            self.virtualDiskSettingsForm.selectedDiskProvisioningOption =
                  self.virtualDiskSettingsForm.diskProvisioningOptions[0];
         }
      };

      private expandedChanged(expanded: boolean) {
         const self = this;
         if (self.expanded === expanded) {
            return;
         }
         self.expanded = expanded;

         if (self.inflatedDevice.isNew()) {
            this.setDiskLocationValidity(!self.diskLocationErrorConfig.isVisible);
            self.setDiskProfileValidity(!self.diskProfileErrorConfig.isVisible,
                  self.diskProfileErrorConfig.message);
         } else if (self.expanded && self.isGroupingHardDisks) {
            //broadcast to all the other hard disks
            this.$rootScope.$broadcast(this.vmHardwareVirtualDiskService.DISK_STACK_EXPANDED, {
               deviceKey: self.inflatedDevice.getKey()
            });
         }
      };

      private revertRemoval() {
         const self = this;
         self.inflatedDevice.revertRemoval();
         self.virtualMachineDevices.notifyRgValidationCallbacks();
      };

      private isEditDiskModeDisabled() {
         const self = this;
         return self.isPmemDisk || this.vmHardwareVirtualDiskService.isEditDiskModeDisabled(
               self.inflatedDevice, self.createMode, self.vmConfigContext);
      };

      private isEditFlashReadCacheDisabled(): boolean {
         const self = this;
         return self.isPmemDisk || this.vmHardwareVirtualDiskService.isEditFlashReadCacheDisabled(
               self.vmConfigContext.privileges, self.createMode);
      };

      private isEditSharesDisabled() {
         const self = this;
         return self.isPmemDisk || this.vmHardwareVirtualDiskService.isEditSharesDisabled(
               self.vmConfigContext.privileges,
               self.createMode,
               self.vmConfigContext.environment.configOption.capabilities.diskSharesSupported);
      };

      private isDiskSharingDisabled() {
         const self = this;
         return self.isPmemDisk || (self.isPoweredOn && !self.inflatedDevice.isNew());
      };

      private hasPrivilegesToEdit() {
         const self = this;
         return this.vmHardwareVirtualDiskService.hasPrivilegesToEditCapacity(self.vmConfigContext.privileges, self.createMode);
      };

      private updateDiskFileOperation() {
         const self = this;
         if (self.deleteFilesFromDisk) {
            self.inflatedDevice.setDiskFileOperation(this.DELETE_FILE_FROM_DISK_OPERATION);
         } else {
            self.inflatedDevice.setDiskFileOperation(undefined);
         }
      };

      private updateFlashCacheReservation(flashCacheReservation: any) {
         this.inflatedDevice.updateFlashCacheReservation(flashCacheReservation);
      };

      private hasError() {
         const self = this;
         if (!_.isEmpty((self as any)[self.formId])) {
            return (self as any)[self.formId].$invalid;
         }
         return false;
      };

      private shouldDisplayShares(expanded: any) {
         const self = this;
         if (!_.isEmpty((self as any)[self.formId])) {
            var errors = (self as any)[self.formId].$error;
            if (!_.isEmpty(errors)) {
               for (var obj in errors) {
                  if (errors[obj].length > 1 && (errors[obj][1].$name).startsWith('DiskSharesValue')) {
                     return true;
                  }

               }
            }
         }
         return expanded;
      };

      private shouldDisplayIOPS(expanded: boolean) {
         const self = this;
         if (!_.isEmpty((self as any)[self.formId])) {
            var errors = (self as any)[self.formId].$error;
            if (!_.isEmpty(errors)) {
               for (var obj in errors) {
                  if (errors[obj].length > 1 && (errors[obj][1].$name).startsWith('IopsLimitValue')) {
                     return true;
                  }

               }
            }
         }
         return expanded;
      };

      private showVmStoragePolicy(expanded: boolean) {
         const self = this;
         expanded = expanded || false;
         return expanded && (!self.cloneMode || self.inflatedDevice.isNew());
      };

      private showReplicationGroup(expanded: boolean) {
         const self = this;
         expanded = expanded || false;
         return expanded && self.replicationGroupItems.length &&
               (!self.cloneMode || self.inflatedDevice.isNew());
      };

      private areStorageProfilesReadOnly() {
         const self = this;
         return !self.virtualDiskSettingsForm
               || !self.virtualDiskSettingsForm.storageProfile
               || !self.virtualDiskSettingsForm.storageProfile.options
               || self.virtualDiskSettingsForm.storageProfile.options.length === 0
               || self.isExistingPmemDisk;
      };

      private shouldDisableDiskEdit() {
         const self = this;
         return !self.isDiskEditable ||
               self.inflatedDevice.isCreatedFromExistingDisk() ||
               !self.hasPrivilegesToEdit();
      };

      private getSelectedVirtualNodeText() {
         const self = this;
         if (self.virtualDiskSettingsForm && self.virtualDiskSettingsForm.controllers) {
            var rawController = self.virtualDiskSettingsForm.controllers.selection.getCurrentDevice();

            return this.vmDeviceInfoService.getControllerAndNodeLabelText(
                  rawController._type, rawController.busNumber, self.inflatedDevice.getCurrentDevice().unitNumber);
         }
         return '';
      };

      /* ------------------------ private ------------------------ */

      private validateReplicationGroupSelection() {
         const self = this;

         function getReplicationGroupError() {

            if (!self.replicationGroupItems || !self.replicationGroupItems.length) {
               return "";
            }

            if (!self.selectedReplicationGroupItem) {
               return self.i18n("VmUi", "DiskConfig.selectReplicationGroup");
            }

            var rgAssignments: any[] = [];
            if (self.vmHomeReplicationGroup) {
               rgAssignments.push(self.vmHomeReplicationGroup);
            }
            _.forEach(self.virtualMachineDevices.devicesNotMarkedForRemovalOfType("VirtualDisk"), function (virtualDisk: any) {
               var diskRg = null;
               if (self.cloneMode) {
                  var advancedConfiguration =
                        self.getAdvancedConfigurationForDisk({virtualDiskDevice: virtualDisk});
                  if (advancedConfiguration) {
                     diskRg = advancedConfiguration.replicationGroup;
                  } else {
                     diskRg = virtualDisk.getCurrentReplicationGroup();
                  }
               } else {
                  diskRg = virtualDisk.getCurrentReplicationGroup();
               }
               if (diskRg) {
                  rgAssignments.push(diskRg);
               }
            });

            return self.spbmReplicationGroupInfoService
                  .validateVmReplicationGroupAssignments(rgAssignments);
         }

         self.replicationGroupErrorConfig.message = getReplicationGroupError();
         var isValid = !self.replicationGroupErrorConfig.message;
         self.replicationGroupErrorConfig.isVisible = !isValid;

         var form = (self as any)[self.formId];
         if (form) {
            var replicationGroupElem = form['replication-group'];
            if (replicationGroupElem) {
               replicationGroupElem.$invalid = !isValid;
            }
            (self as any)[self.formId].$setValidity('replicationGroup', isValid);
         }
      }

      private updateReplicationGroupData(selectedProfile: any = undefined, selectedStorageRef: any = undefined) {
         const self = this;

         if ((self.cloneMode && !self.inflatedDevice.isNew())) {
            return;
         }

         if (!selectedProfile && self.virtualDiskSettingsForm.storageProfile) {
            selectedProfile = self.virtualDiskSettingsForm.storageProfile.selection;
         }

         if (!selectedStorageRef) {
            var currentSpec = self.inflatedDevice.getCurrentDeviceSpec();

            if (currentSpec && currentSpec.device && currentSpec.device.backing &&
                  currentSpec.device.backing.datastore) {
               selectedStorageRef = currentSpec.device.backing.datastore;
            } else if (self.vmStorageId) {
               selectedStorageRef = this.defaultUriSchemeUtil.getManagedObjectReference(
                     self.vmStorageId);
            }
         }

         this.storageProfileService.requestCompatibilityResultForDatastore(
               selectedProfile && selectedProfile.profileObj,
               selectedStorageRef).then(function (result: any) {

            self.replicationGroupItems = [];
            self.selectedReplicationGroupItem = null;

            var currentRgId = self.inflatedDevice.getCurrentReplicationGroup() ?
                  self.inflatedDevice.getCurrentReplicationGroup().id : null;

            var triggerRgUpdate = !!currentRgId;

            if (result && result.compatibilityResult) {

               var computeResourceRef = self.computeResourceId
                     ? self.defaultUriSchemeUtil.getManagedObjectReference(self.computeResourceId)
                     : null;
               var hostRefs = (computeResourceRef && computeResourceRef.type === "HostSystem")
                     ? [computeResourceRef]
                     : [];

               var replicationGroupInfos =
                     self.spbmReplicationGroupInfoService.extractReplicationGroupInfos(
                           result.compatibilityResult, hostRefs, result.replicationGroupInfo);

               _.forEach(replicationGroupInfos, function (rg: any) {
                  var rgItem = {
                     label: self.spbmReplicationGroupInfoService.getReplicationGroupName(rg),
                     replicationGroupInfo: rg
                  };
                  self.replicationGroupItems.push(rgItem);
                  if (currentRgId && self.spbmReplicationGroupInfoService.equalReplicationGroupIds(
                              currentRgId, rg.id)) {
                     self.selectedReplicationGroupItem = rgItem;
                  }
               });

               if (!currentRgId || self.selectedReplicationGroupItem) {
                  // if there was no initial RG or it has beed re-selected
                  // no need to trigger RG update.
                  triggerRgUpdate = false;
               }
            }

            if (triggerRgUpdate) {
               self.onReplicationGroupSelectionChange();
            }

            self.validateReplicationGroupSelection();
         });
      }

      private isExistingDiskAddition(): boolean {
         const self = this;
         return self.inflatedDevice.isNew() && self.inflatedDevice.isCreatedFromExistingDisk();
      }

      private determineDiskEditability(): boolean {
         const self = this;
         if (this.determineIfRdmDisk()) {
            //if RDM disk, always return false
            return false;
         }

         if (self.createMode) {
            return true;
         } else if (self.isPmemDisk && self.isPoweredOn) {
            return false;
         } else {
            return self.inflatedDevice.getCurrentDevice().backing.parent ? false : true;
         }
      }

      private determineIfRdmDisk() {
         const self = this;
         return self.inflatedDevice.getCurrentDevice().backing._type ===
               this.diskBackingInfoConstants.RAWDISKMAPPING;
      }

      private iopsLimitDetails: any;

      private buildIopsLimitDetails() {
         const self = this;
         var MIN = 16;
         var MAX = (Math.pow(2, 31) - 1);
         self.iopsLimitDetails = {};

         self.iopsLimitDetails.min = MIN;
         self.iopsLimitDetails.max = MAX;
         self.iopsLimitDetails.iopsRecommendations = [
            {
               name: self.i18n('VmUi', 'VmCpu.Limit.Minimum'),
               value: MIN
            },
            {
               name: self.i18n('VmUi', 'VmCpu.Limit.Maximum'),
               value: MAX
            }
         ];
         self.iopsLimitDetails.iopsLimitSelectList = [
            {
               label: self.i18n('VmUi', 'DiskConfig.Unlimited'),
               data: 'unlimited'
            },
            {
               label: self.i18n('VmUi', 'sharesInfoLevel.custom'),
               data: 'custom'
            }
         ];

         self.iopsLimitDetails.errorMessage = {
            max: function () {
               return self.i18n('Common', 'SharesControl.RangeError', MIN, MAX);
            },
            min: function () {
               return self.i18n('Common', 'SharesControl.RangeError', MIN, MAX);
            }
         };

         self.iopsLimitDetails.selectChange = function () {
            if (self.iopsLimitDetails.iopsLimitSelection.data === 'unlimited') {
               self.virtualDiskSettingsForm.ioLimit = self.VirtualDisk.UNLIMITED_DISK;
            } else {
               self.virtualDiskSettingsForm.ioLimit = MIN;
            }
            self.inflatedDevice.updateStorageIOAllocation(self.virtualDiskSettingsForm.ioLimit);
         };
      }

      private applyPmemPostProcessingToDisk() {
         const self = this;
         // Use the applyPmemProfile function in create mode to set Pmem Storage Policy
         // when Pmem Baseline is selected.
         if (self.createMode && this.applyPmemProfileToDisk()) {
            return;
         }

         if (self.cloneMode) {
            var advancedConfiguration =
                  self.getAdvancedConfigurationForDisk(self.inflatedDevice);
            if (advancedConfiguration) {
               self.updateProfile(advancedConfiguration.storageProfile,
                     advancedConfiguration.replicationGroup);
               return;
            }
            if (this.applyPmemProfileToDisk()) {
               return;
            }
            const storageProfileService = this.storageProfileService;

            if (!self.inflatedDevice.isStorageProfileManuallySet()) {
               var diskProfile = self.selectedStorageProfile;
               if (!self.inflatedDevice.isNew() && self.keepExistingProfileAssignments) {
                  diskProfile = storageProfileService.getDefaultProfile();

                  var diskAssignment = storageProfileService.findDiskAssignment(
                        self.vmAssignmentsProperties.vmStorageProfileAssignments,
                        self.inflatedDevice.getCurrentDevice());
                  if (diskAssignment) {
                     diskProfile = {
                        id: diskAssignment.profile.profileId.uniqueId,
                        label: diskAssignment.profile.name
                     };
                  }
               }

               self.updateProfile(diskProfile,
                     self.inflatedDevice.getCurrentReplicationGroup());
               self.virtualDiskSettingsForm.storageProfile.selection = diskProfile;
            }
         }
      }

      private findPmemProfile(storageProfiles: any) {
         const self = this;
         return _.find(storageProfiles, function (storageProfile: any) {
            return self.storageProfileService.isPmemStorageProfile(storageProfile);
         });
      }

      private applyPmemProfileToDisk() {
         const self = this;
         if (this.virtualDiskPmemService.shouldApplyPmemProfileToDisk(
                     self.inflatedDevice, self.storageBaselineId)) {
            // In hybrid mode the pmem disks need to have their profile set to the Pmem
            // profile in order to show up correctly on the RTC page.
            // The RTC page gets its data about disks from virtualMachineDevices, which
            // is directly populated by this component (for disks at least)
            var pmemStorageProfile = this.findPmemProfile(self.storageProfiles);
            self.updateProfile(pmemStorageProfile);
            self.virtualDiskSettingsForm.storageProfile.selection = pmemStorageProfile;
            return true;
         }
         return false;
      }

      private postInit() {
         const self = this;
         this.setErrorMessage(self.virtualDiskSettingsForm.capacity.maxExceededErrorMessage);

         self.iopsLimitDetails.iopsLimitSelection =
               (self.virtualDiskSettingsForm.ioLimit === this.VirtualDisk.UNLIMITED_DISK) ?
                     self.iopsLimitDetails.iopsLimitSelectList[0] :
                     self.iopsLimitDetails.iopsLimitSelectList[1];

         if (!self.createMode) {
            self.errorMessages.min =
                  self.i18n('VmUi', 'DiskConfig.ErrorCapacityShrink');
         }

         this.updateReplicationGroupData();
      }

      private errorMessages: any;

      private setErrorMessage(maxErrorMessage: string): void {
         const self = this;
         self.errorMessages = {
            max: maxErrorMessage
         };
      }

      private setCapacityAndErrorMessage(selectedStorageInfo: any, datastoreId: any = undefined) {
         const self = this;
         var datastoreInfo = this.datastoreService.findDatastore(
               datastoreId || self.vmStorageId,
               self.vmConfigContext.environment.configTarget);

         if (selectedStorageInfo && !this.isStoragePod(selectedStorageInfo)) {
            self.virtualDiskSettingsForm.diskFile = '[' + selectedStorageInfo.name + ']';
         }
         else {
            self.virtualDiskSettingsForm.diskFile = '';
         }

         var capacity = this.virtualDiskSettingsFormService.buildCapacity(
               self.inflatedDevice,
               self.createMode || self.cloneMode,
               datastoreInfo);
         self.virtualDiskSettingsForm.capacity.minInMB = capacity.minInMB;
         self.virtualDiskSettingsForm.capacity.maxInMB = capacity.maxInMB;
         self.virtualDiskSettingsForm.capacity.maxExceededErrorMessage =
               capacity.maxExceededErrorMessage;
         self.virtualDiskSettingsForm.capacity.isMaxAvailable =
               capacity.isMaxAvailable;
         this.setErrorMessage(self.virtualDiskSettingsForm.capacity.maxExceededErrorMessage);

         self.setStorageForDisk({
            storageInfo: selectedStorageInfo,
            device: self.device
         });

         self.inflatedDevice.updateFileName(self.virtualDiskSettingsForm.diskFile);

         if (selectedStorageInfo && selectedStorageInfo.storageObject) {
            self.inflatedDevice.updateDatastoreMor(selectedStorageInfo.storageObject.storageRef);
            if (selectedStorageInfo.storageObject.parentStoragePod) {
               self.inflatedDevice.updateParentStoragePodMor(
                     selectedStorageInfo.storageObject.parentStoragePod);
            }
         } else {
            self.inflatedDevice.updateDatastoreMor(null);
         }
      }

      private isStoragePod(selectedStorageInfo: any) {
         return selectedStorageInfo &&
               selectedStorageInfo.storageObject &&
               selectedStorageInfo.storageObject.storageRef &&
               selectedStorageInfo.storageObject.storageRef.type === this.managedEntityConstants.STORAGE_POD;
      }

      private isAddedDeviceAController(deviceType: any) {
         var isScsiController = deviceType === this.deviceTypeConstants.SCSICONTROLLER ||
               this.deviceClassLineageService.isClassNameSubclassOf(deviceType, this.deviceTypeConstants.SCSICONTROLLER);
         var isSataController = deviceType === this.deviceTypeConstants.VIRTUAL_SATA_CONTROLLER ||
               this.deviceClassLineageService.isClassNameSubclassOf(deviceType, this.deviceTypeConstants.VIRTUAL_SATA_CONTROLLER);
         var isNvmeController = deviceType === this.deviceTypeConstants.NVME_CONTROLLER ||
               this.deviceClassLineageService.isClassNameSubclassOf(deviceType, this.deviceTypeConstants.NVME_CONTROLLER);
         return isScsiController || isSataController || isNvmeController;
      }

      private rebuildControllersDropdown(): void {
         const self = this;
         self.virtualDiskSettingsForm.controllers =
               this.virtualDiskSettingsFormService.buildControllersDropdown(
                     self.inflatedDevice,
                     self.virtualMachineDevices.getAllDevicesNotMarkedForRemoval()
               );
      }

      private getStorageLocatorItemsForCreateOrEditMode() {
         const self = this;
         if (self.storageLocatorItemsData) {
            return this.$q.when(self.storageLocatorItemsData);
         } else {
            return this.vmDatastoreLocationBrowserService.getStorageLocatorItems(self.vmId);
         }
      }

      private getAllBackendStorageCallPromises() {
         const self = this;

         if (self.createMode || self.cloneMode || !self.vmAssignmentsProperties) {
            return {
               storageProfilesPromiseResult: self.storageProfiles,
               vmAssignmentsPromiseResult: self.vmAssignmentsProperties
            };
         }

         return this.vmHardwareVirtualDiskService.getAllBackendStorageCallPromises
         (this.$q, self.vmAssignmentsProperties, self.storageProfiles);

      }

      private disk18n(key: string): string {
         return this.i18nService.getString("VmUi", 'DiskPage.' + key);
      };

      private ARIA_LBL_CAPACITY = this.i18n("VmUi", "VmDiskView.capacity");
      private ARIA_LBL_PROFILE = this.disk18n('Profile');
      private ARIA_LBL_LOCATION = this.disk18n('Location');
      private ARIA_LBL_RGROUP = this.disk18n('ProtectionGroup');
      private ARIA_LBL_PROVISION = this.disk18n('DiskProvisioning');
      private ARIA_LBL_LIMIT_IOPS = this.disk18n('LimitIOPs');
      private ARIA_LBL_CACHE = this.disk18n('CacheReservation');
      private ARIA_LBL_SHARING = this.disk18n('Sharing');
      private ARIA_LBL_SHARES = this.disk18n('Shares');
      private ARIA_LBL_DISK_MODE = this.disk18n('DiskMode');

      private device18n(key: string): string {
         return this.i18nService.getString("VmUi", 'DeviceDeletedControl.' + key);
      };

      private ARIA_LBL_REMOVE = this.device18n('DeviceDeleted');
      private ARIA_LBL_DESTROY = this.device18n('DeleteFiles');
      private ARIA_LBL_RESTORE = this.device18n('Restore');
   } // end of class
   angular.module('com.vmware.vsphere.client.vm')
         .component('vmHardwareVirtualDisk', new VmHardwareVirtualDiskComponent());
} // end of namespace

