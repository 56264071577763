(function() {
   'use strict';
   angular.module('com.vmware.vsphere.client.host').service('editFirewallSettingsService',
         EditFirewallSettingsService);

   EditFirewallSettingsService.$inject = ['ipParserService', 'vuiConstants', 'i18nService'];

   function EditFirewallSettingsService(ipParserService, vuiConstants, i18nService) {



      // -- Public methods

      function createIpListFromRawData(rawIpList) {
         var ips = '';
         var networks = '';
         if (rawIpList.ipAddress && rawIpList.ipAddress.length > 0) {
            ips = rawIpList.ipAddress.join();
         }

         if (rawIpList.ipNetwork && rawIpList.ipNetwork.length > 0) {
            _.each(rawIpList.ipNetwork, function(network, index) {
               networks += network.network + "/" + network.prefixLength;
               if (index !== rawIpList.ipNetwork.length - 1) {
                  networks += ", ";
               }
            });
         }
         if (ips !== '' && networks !== '') {
            return ips + ', ' + networks;
         } else if (ips !== '' && networks === '') {
            return ips;
         } else if (ips === '' && networks !== '') {
            return networks;
         } else {
            return "";
         }
      }

      function createRawIpList(ipList) {
         var arrayOfIps = [];
         if (ipList) {
            var ipAddresses = ipList.split(',');
            _.each(ipAddresses, function(ip) {
               if (ip.indexOf('/') === -1) {
                  arrayOfIps.push(ip.trim());
               }
            });
         }

         return arrayOfIps;
      }

      function removeInvalidData(ipList) {
         if (!ipList) {
            return;
         }
         var rawIpList = ipList.split(',');

         var filteredItems = _.filter(rawIpList, function(ip) {
            return validateIpAddress(ip);
         });

         var invalidItems = _.filter(rawIpList, function(ip) {
            return !validateIpAddress(ip);
         });

         return {
            filteredItems: filteredItems.join(),
            invalidItems: invalidItems.join()
         };
      }

      function validateIpAddresses(ipAddressesText) {

         if (!ipAddressesText) {
            return true;
         }

         if (ipAddressesText === "") {
            return true;
         }
         var rawIpAddresses = ipAddressesText.split(',');

         return _.every(rawIpAddresses, function(item){
            return validateIpAddress(item);
         });
      }

      function createRawNetworkList(ipNetworkList) {
         var arrayOfIpNetworks = [];
         if (ipNetworkList) {
            var ipAddressesAndNetworks = ipNetworkList.split(',');
            _.each(ipAddressesAndNetworks, function(ip) {
               if (ip.indexOf('/') > -1) {
                  var network = ip.split('/');
                  arrayOfIpNetworks.push(
                        {
                           network: network[0].trim(),
                           prefixLength: parseInt(network[1])
                        }
                  );
               }
            });
         }

         return arrayOfIpNetworks;
      }

      function getDataGridOptions() {
         return {
            height: '100%',
            selectionMode: vuiConstants.grid.selectionMode.SINGLE,
            selectedItems: [],
            resizable: true,
            data: [],
            columnDefs: getColumnDefs(),
            sortOrder: [{
               field: 'serviceName',
               dir: 'asc'
            }],
            groupOrder: [{
               field: 'group',
               dir: 'asc'
            }]
         };
      }

      function createSpec(modifiedData, originalData) {
         var spec = {};
         var enabledIpList = createEnabledAndIpList(modifiedData, originalData);
         var emptyIpList = _.isEmpty(enabledIpList.connectionsWithModifiedIpList);
         var emptyEnabledList = _.isEmpty(enabledIpList.connectionsWithModifiedEnabledState);

         if (emptyEnabledList && emptyIpList) {
            return spec;
         }
         if (emptyIpList) {  // ENABLED Spec
            spec = {
               rulesetToEnabledState: getRulesetToEnabledStateMap(
                     enabledIpList.connectionsWithModifiedEnabledState
               )
            };
         } else if (emptyEnabledList) {  // IP Spec
            spec = {
               ipNetworkConfigurationSpec: getRulesetToIpListStateMap(
                     enabledIpList.connectionsWithModifiedIpList
               )
            };
         } else {  // Both
            spec = {
               rulesetToEnabledState: getRulesetToEnabledStateMap(
                     enabledIpList.connectionsWithModifiedEnabledState
               ),
               ipNetworkConfigurationSpec: getRulesetToIpListStateMap(
                     enabledIpList.connectionsWithModifiedIpList
               )
            };
         }
         return spec;
      }

      // -- Private methods

      function getString(key) {
         return i18nService.getString("HostUi", key);
      }


      function validateIpAddress(ip) {
         ip = ip.trim();

         if (ipParserService.isIpv6AddressValid(ip)
               || ipParserService.isIpv4AddressValid(ip)) {
            return true;
         } else {
            var maskedIpAddress = ip.split('/');
            return validMaskedIpAddress(maskedIpAddress);
         }
      }

      function validMaskedIpAddress(maskedIpAddress) {
         if (maskedIpAddress && maskedIpAddress.length === 2) {
            var ipAddress = maskedIpAddress[0];
            var mask = parseInt(maskedIpAddress[1]);
            var validIpVersion4 = ipParserService.isIpv4AddressValid(ipAddress) &&
                  mask >= 0 && mask < 32;
            var validIpVersion6 = ipParserService.isIpv6AddressValid(ipAddress) &&
                  mask >= 0 && mask <= 128;
            return validIpVersion4 || validIpVersion6;
         }
         return false;
      }

      function getColumnDefs() {
         return [
            {
               displayName: getString('editFirewallSettingsDialog.grid.column.groupLabel'),
               field: 'group',
               visible: false
            },{
            displayName: getString('firewallConnection.serviceName'),
            field: 'serviceName',
            filterable: false,
            searchable: false,
            template: function(dataItem) {
               return createCheckboxWithServiceName(dataItem);
            }
         }, {
            displayName: getString('editFirewallSettingsDialog.grid.column.inbound'),
            field: 'inboundDirectionInfo.combinedPorts',
            filterable: false,
            searchable: false,
            template: function(dataItem) {
               return dataItem.inboundDirectionInfo.combinedPorts || getString('none.label');
            }
         }, {
            displayName: getString('editFirewallSettingsDialog.grid.column.outbound'),
            field: 'outboundDirectionInfo.combinedPorts',
            filterable: false,
            searchable: false,
            template: function(dataItem) {
               return dataItem.outboundDirectionInfo.combinedPorts || getString('none.label');
            }
         }, {
            displayName: getString('editFirewallSettingsDialog.grid.column.daemonStatus'),
            field: 'daemonStatus',
            filterable: false,
            searchable: false,
            template: function(dataItem) {
               return getDaemonStatusDisplayMessage(dataItem.daemonStatus);
            }
         }];
      }

      function createCheckboxWithServiceName(dataItem) {
         return '' +
               '<label>' +
               '  <input type="checkbox" ' +
               '     ng-model="dataItem.enabled"' +
               '     ng-change="dataItem._rawItem.enabled = dataItem.enabled">' +
               '  {{dataItem.serviceName}}' +
               '</label>';
      }

      function getConnectionsWithModifiedEnabledState(modifiedData, originalData) {
         return _.filter(modifiedData, function(modifiedItemData, index) {
            return modifiedItemData.enabled !== originalData[index].enabled;
         });
      }

      function getConnectionsWithModifiedIpAddresses(modifiedData, originalData) {
         return _.filter(modifiedData, function(modifiedItemData, index) {
            return (modifiedItemData.rawIpList.allIp !== originalData[index].rawIpList.allIp) ||
                  (modifiedItemData.ipList !== originalData[index].ipList);
         });
      }

      function getRulesetToEnabledStateMap(connectionsWithModifiedEnabledState) {
         return _.object(_.map(connectionsWithModifiedEnabledState, function(item) {
            return [item.serviceKey, item.enabled];
         }));
      }

      function getRulesetToIpListStateMap(connectionsWithModifiedIpList) {
         return _.object(_.map(connectionsWithModifiedIpList, function(item) {
            return [item.serviceKey, {
               allIp: item.rawIpList.allIp,
               ipAddresses: item.rawIpList.ipAddress,
               ipNetworks: item.rawIpList.ipNetwork
            }];
         }));
      }


      function createEnabledAndIpList(modifiedData, originalData) {
         var connectionsWithModifiedEnabledState =
               getConnectionsWithModifiedEnabledState(
                     modifiedData,
                     originalData
               );

         var connectionsWithModifiedIpList =
               getConnectionsWithModifiedIpAddresses(
                     modifiedData,
                     originalData
               );
         return {
            connectionsWithModifiedEnabledState: connectionsWithModifiedEnabledState,
            connectionsWithModifiedIpList: connectionsWithModifiedIpList
         };

      }

      function filterSettings(originalData, searchTerm, propertiesToSearchInFor) {
         var searchValue = searchTerm.toLowerCase();
         return _.filter(originalData, function(item) {
            return isSearchValuePresentedInProperties(item, searchValue, propertiesToSearchInFor);
         });
      }

      function isSearchValuePresentedInProperties(item, searchItem, properties) {
         return _.some(properties, function(property) {
            var currentPropertyValue = item[property];
            return property === 'daemonStatus' ?
                  searchInDaemonStatus(currentPropertyValue, searchItem) :
            currentPropertyValue.toLowerCase().indexOf(searchItem) >= 0;
         });
      }

      function searchInDaemonStatus(daemonStatusValue, searchItem) {
         var daemonStatusDisplayMessageLowerCase =
               getDaemonStatusDisplayMessage(daemonStatusValue).toLowerCase();

         return daemonStatusDisplayMessageLowerCase.indexOf(searchItem) >= 0;
      }

      function getDaemonStatusDisplayMessage(daemonStatus) {
         var status = '';
         if (daemonStatus === null){
            status = getString('editFirewallSettingsDialog.daemonStatus.notPresented');
         }
         else {
            status = daemonStatus === 'true' ?
                  getString('editFirewallSettingsDialog.daemonStatus.running') :
                  getString('editFirewallSettingsDialog.daemonStatus.stopped');
         }

         return status;
      }

      return {
         createIpListFromRawData: createIpListFromRawData,
         removeInvalidData: removeInvalidData,
         createRawIpList: createRawIpList,
         validateIpAddresses: validateIpAddresses,
         createRawNetworkList: createRawNetworkList,
         getDataGridOptions: getDataGridOptions,
         createSpec: createSpec,
         filterSettings: filterSettings
      };

   }
})();
