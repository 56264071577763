(function() {
   'use strict';

   angular.module('com.vmware.vsphere.client.vm')
      .factory('addVmService', addVmService);

   addVmService.$inject = [
      'creationTypeConstants',
      'defaultUriSchemeUtil',
      'mutationService',
      'datastoreRecommendationService',
      'vscSchedulingHelper',
      'vmCreateScheduledTaskMutationService',
      '$q'
   ];

   function addVmService(
      creationTypeConstants,
      defaultUriSchemeUtil,
      mutationService,
      datastoreRecommendationService,
      vscSchedulingHelper,
      vmCreateScheduledTaskMutationService,
      $q
   ) {

      return {
         createVm: createVm,
         validateSpec: validateSpec,
         createVmScheduledTask: createVmScheduledTask
      };

      function createVm(vmParams) {
         if (isDeployVmFromVmtxTemplateMode(vmParams) || !usesSdrs(vmParams)) {
            return buildVmSpec(vmParams, vmParams.getCreationType()).then(function(spec) {
               return mutationService.add(
                  getSpecType(vmParams.getCreationType()),
                  spec
               );
            });
         }
         var topRecomendationKeys = _.map(vmParams.getVmRecommendationsSpec(), function(recommendation){
            return recommendation.key;
         });
         return mutationService.add(
            'com.vmware.vsphere.client.vm.storageDrs.VmRecommendationsSpec',
            {
               recommendations: topRecomendationKeys,
               moRef: vmParams.getStorageObject().storageRef
            }
         );
      }

      function createVmScheduledTask (vmParams, scheduledTaskData) {
         return buildVmSpec(vmParams, vmParams.getCreationType()).then(function(spec) {
            var stData = createDataModelForScheduleTask(spec,
               getSpecType(vmParams.getCreationType()),
               scheduledTaskData.schedulingData);
            return vmCreateScheduledTaskMutationService.createScheduledTask(stData);
         });
      }

      function validateSpec(vmParams) {
         if (!isDeployVmFromVmtxTemplateMode(vmParams) && usesSdrs(vmParams)) {
            return buildVmSpec(vmParams, vmParams.getCreationType()).then(function(spec) {
               return datastoreRecommendationService.getPlacementRecommendations(
                  spec,
                  getSpecType(vmParams.getCreationType())
               );
            }).then(function(result) {
               if (!result.recommendations) {
                  return {faults: result.faults};
               }

               // Group recommendations by target
               var recommendationsPerPod = {};
               result.recommendations.forEach(function(recommendation) {
                  var targetId = defaultUriSchemeUtil.getVsphereObjectId(recommendation.target);
                  if (!recommendationsPerPod[targetId]) {
                     recommendationsPerPod[targetId] = {
                        target: recommendation.target
                     };
                     recommendationsPerPod[targetId].recommendations = [];
                  }
                  recommendationsPerPod[targetId].recommendations.push(recommendation);
               });

               var topRecommendedDatastoreForVmx = null;
               var topRecommendedDatastoresPerDisk = {};
               angular.forEach(recommendationsPerPod, function(podRecommendationsData) {
                  var topRecommendationInfo =
                        datastoreRecommendationService.findTopRecommendationInfo(
                              podRecommendationsData.recommendations);

                  podRecommendationsData.topRecommendation =
                        topRecommendationInfo.topRecommendation;
                  angular.extend(topRecommendedDatastoresPerDisk,
                        topRecommendationInfo.topRecommendedDatastorePerDisk);

                  if (!topRecommendedDatastoreForVmx) {
                     topRecommendedDatastoreForVmx =
                           topRecommendationInfo.topRecommendedDatastoreForVmx;
                  }

               });

               return {
                  recommendationsPerPod: recommendationsPerPod,
                  topRecommendedDatastoresPerDisk: topRecommendedDatastoresPerDisk,
                  topRecommendedDatastoreForVmx: topRecommendedDatastoreForVmx,
                  faults: false
               };
            });
         } else {
            return $q.when({
               topRecommendedDatastoreForVmx: defaultUriSchemeUtil.getVsphereObjectId(
                     vmParams.getStorageObject().storageRef)
            });
         }

      }

      // ------- Private -------- //

      function getSpecType(creationType) {
         if (creationType === creationTypeConstants.CREATE_FROM_SCRATCH) {
            return 'com.vmware.vsphere.client.vm.VmCreateSpec';
         }
         else if (creationType === creationTypeConstants.DEPLOY_VM_FROM_VMTX) {
            return 'com.vmware.vsphere.client.h5.vmtx.libraryItem.specs.DeployVmtxLibraryItemSpec';
         }
         return 'com.vmware.vsphere.client.vm.VmCloneSpec';
      }

      function buildVmSpec(vmParams, creationType) {
         if (creationType === creationTypeConstants.CREATE_FROM_SCRATCH) {
            return $q.when(vmParams.getVmCreateSpec());
         }
         else if (creationType === creationTypeConstants.DEPLOY_VM_FROM_VMTX) {
            return $q.when(vmParams.getDeployVmtxSpec());
         }
         return vmParams.getVmCloneSpec();
      }

      function usesSdrs(vmParams) {
         if (vmParams.isXvc()) {
            return false;
         }
         if (datastoreRecommendationService.areRecommendationsAvailableForStorageObject(vmParams.getStorageObject())) {
            return true;
         }

         var disks = vmParams.getStorageDiskList();
         var diskUsingSdrs = _.find(disks, function(disk) {
            return datastoreRecommendationService.areRecommendationsAvailableForStorageObject(disk.dsObject);
         });

         return angular.isDefined(diskUsingSdrs);
      }

      function createDataModelForScheduleTask(spec, _type, scheduleTaskData) {
         scheduleTaskData.spec = spec;
         scheduleTaskData.schedulingSpec.recurrence =
            vscSchedulingHelper.formatRecurrenceData(scheduleTaskData.schedulingSpec.recurrence);
         scheduleTaskData.spec._type = _type;
         return scheduleTaskData;
      }

      function isDeployVmFromVmtxTemplateMode(vmParams) {
         return creationTypeConstants.isDeployVmFromVmtxTemplate(vmParams.getCreationType());
      }
   }

})();
