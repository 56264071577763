namespace storage_ui {

   export class StorageAdapterEditAuthenticationValidationService {
      public static $inject = ["i18nService", "storageAdapterConstants"];

      constructor(private i18nService: any,
                  private constants: any) {
      }

      public isValidName(name: string, isSoftwareIscsi: boolean): string[] {
         let errors: string[] = [];
         let maxLength: number = isSoftwareIscsi ? this.constants.constraints.SW_CHAP_NAME_MAX : this.constants.constraints.HW_CHAP_NAME_MAX;
         if (!name || !name.trim().length) {
            errors.push(this.i18nService.getString("StorageUi", "storage.adapters.edit.authentication.form.errorMessage"));
         } else if (name.trim().length > maxLength) {
            errors.push(this.i18nService.getString("StorageUi", "storage.adapters.edit.authentication.form.fieldLengthError", maxLength));
         }
         return errors;
      }

      public isValidSecret(secret: string, isSoftwareIscsi: boolean): string[] {
         let errors: string[] = [];
         let maxLength: number = isSoftwareIscsi ? this.constants.constraints.SW_CHAP_SECRET_MAX : this.constants.constraints.HW_CHAP_SECRET_MAX;
         if (!secret || !secret.trim().length) {
            errors.push(this.i18nService.getString("StorageUi", "storage.adapters.edit.authentication.form.errorMessage"));
         } else if (secret.trim().length > maxLength) {
            errors.push(this.i18nService.getString("StorageUi", "storage.adapters.edit.authentication.form.fieldLengthError", maxLength));
         }
         return errors;
      }

   }

   angular.module("com.vmware.vsphere.client.storage")
         .service("storageAdapterEditAuthenticationValidationService", StorageAdapterEditAuthenticationValidationService);
}
