module ds_cluster_ui {

   class DsClusterHistoryViewController {
      private watchForObjects: any;
      private objectId: string;
      private liveRefreshProperties: Array<string>;
      public historyTitle: string;
      public gridOptions: any;

      public static $inject = ["$scope", "dataService", "i18nService", "vuiConstants",
            "columnRenderersRegistry", "defaultUriSchemeUtil", "timeFormatterService",
            "$q", "dsClusterConstants"];

      constructor(private $scope: any, private dataService: any,
                  private i18nService: any, private vuiConstants: any,
                  private columnRenderersRegistry: any,
                  private defaultUriSchemeUtil: any,
                  private timeFormatterService: any, private $q: any,
                  private dsClusterConstants: any) {

         this.objectId = $scope._route.objectId;
         this.watchForObjects = [this.objectId];
         this.liveRefreshProperties =
               [this.dsClusterConstants.liveRefreshProperties.SUMMARY];
         this.historyTitle = this.getString("dscluster.services.drsHistoryLabel");

         this.gridOptions = this.getGridOptions();
         this.getViewData();
      }

      public getViewData() {
         this.dataService.getProperties(this.objectId, ["computedActionHistory"])
               .then((historyData: any) => {
                  if (historyData
                        && historyData.computedActionHistory
                        && historyData.computedActionHistory.length > 0) {
                     let formatTimePromises: any[] = historyData.computedActionHistory.map(
                           (item: any) => this.timeFormatterService.formatDate(item.time).then(
                                 (formattedTime: string) => {
                                    item.time = formattedTime;
                                    return item;
                                 })
                           );
                     this.$q.all(formatTimePromises).then((data: any) => {
                        this.gridOptions.data = data;
                     });
                  }
               });
      }

      private getGridOptions(): any {
         let options: any  = {
            selectionMode: this.vuiConstants.grid.selectionMode.NONE,
            sortMode: this.vuiConstants.grid.sortMode.SINGLE,
            searchable: false,
            resizable: true,
            data: [],
            columnDefs: this.getColumnDefs(),
            height: "100%"
         };

         return options;
      }

      private getColumnDefs(): any {
         return [
            {
               displayName: this.i18nService.getString("Common",
                     "actionHistoryList.timeColumnHeaderLabel"),
               field: "time",
               type: "string"
            },
            {
               displayName: this.i18nService.getString("Common",
                     "actionHistoryList.sdrsActionColumnHeaderLabel"),
               field: "text",
               type: "string",
               template: this.textRenderer.bind(this)
            }
         ];
      }

      private textRenderer(data: any): string {
         let objData: any = data || {};
         var renderer: any = this.columnRenderersRegistry.getColumnRenderer("object-name");

         //build links for each target object in the action text
         let targetLinks: string[] = objData.targetObjects.map((object: any) => {

            if (object && object.name) {
               object.uid = this.defaultUriSchemeUtil.getVsphereObjectId(
                     object.provider);
               return renderer(["uid", null, "name", "labelIds"], object);
            } else {
               return this.getString("sdrs.history.missingObjectNameLabel");
            }
         });

         let text: string = this.i18nService.interpolate(objData.text, targetLinks);

         if (!!objData.image) {
            text = `<span class="${objData.image}"></span>${text}`;
         }

         return text;
      }

      private getString(key: string, ...params: string[]) {
         return this.i18nService.getString("DsClusterUi", key, params);
      }
   }

   angular.module("com.vmware.vsphere.client.dsCluster")
         .controller("DsClusterHistoryViewController", DsClusterHistoryViewController);
}
