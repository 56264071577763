(function() {
   'use strict';

   angular
         .module('com.vmware.vsphere.client.vm')
         .service('VmProvisioningSelectVmVersionPageModel', VmProvisioningSelectVmVersionPageModel);

   VmProvisioningSelectVmVersionPageModel.$inject =
         ['dataService', '$q', 'vcService', 'vmHardwareCompatibilityService','i18nService'];

   function VmProvisioningSelectVmVersionPageModel(
         dataService, $q, vcService, vmHardwareCompatibilityService,i18nService) {
      return function(virtualMachineSpecBuilder) {
         //this variable keeps the id of the last
         //compute resource for which we have retrieved
         //the optionDescriptorsWithCreateSupported data
         var _computeResourceId = null;

         var form = {
            invalidateNextPages: false,
            compatibility: function(vmVersion) {
               var gosVersion = this.vmParams.getGosVersion();
               if (vmVersion) {
                  form.vmVersion = vmVersion;
               }
               // If we have GOS selected, check to see if it's compatible with
               // the hardware version selected here and if not - invalidate the next wizard pages.
               if (gosVersion) {
                  this.setLoading(true);

                  virtualMachineSpecBuilder.queryConfigOptionByPool(form.vmVersion.id)
                        .then(function(res) {
                           var vmConfigOption = res.vmConfigOptionByPool;

                           var osDescriptor = vmConfigOption.guestOSDescriptor;
                           var selectedGuestOS = _.find(osDescriptor, function(osDescriptor) {
                              return osDescriptor.id === gosVersion.id;
                           });

                           this.invalidateNextPages = false;
                           if (!selectedGuestOS) {
                              this.invalidateNextPages = true;
                           }

                           this.setLoading(false);
                        }.bind(this));
               }
            }
         };

         return {
            form: form,

            resetForm: function() {
               var objectId = virtualMachineSpecBuilder.getComputeResourceId();
               var submittedVmVersion = virtualMachineSpecBuilder.getVmVersion();
               // we want to use the vm version from the scheduled data only once the
               // first time we visit the page, so we reset the data
               var scheduledVmVersion = virtualMachineSpecBuilder.getScheduledVmVersion();
               if (scheduledVmVersion) {
                  virtualMachineSpecBuilder.setScheduledVmVersion(null);
               }

               if (_computeResourceId !== objectId) {
                  submittedVmVersion = form.vmVersion = null;
                  virtualMachineSpecBuilder.setVmVersion(null);
               } else if (!_.isEmpty(form.supportedVersions)) {
                  preselectOption(submittedVmVersion, form.supportedVersions);
                  return $q.when();
               }
               var propName = 'optionDescriptorsWithCreateSupported';

               var targetInformation =
                     virtualMachineSpecBuilder.getTargetInformation();

               var promises = {
                  'optionDescriptorsData': dataService.getProperties(objectId, propName),
                  'is65VcOrLater': vcService.is65VcOrLater(targetInformation.datacenterUid)
               };

               return $q.all(promises).then(function(data) {
                  var optionDescriptorsData = data['optionDescriptorsData'];
                  form.is65VcOrLater = data['is65VcOrLater'];
                  _computeResourceId = objectId;
                  form.supportedVersions =
                        vmHardwareCompatibilityService.buildVmVersions(
                              optionDescriptorsData.optionDescriptorsWithCreateSupported,
                              form.is65VcOrLater
                        );
                  preselectOption(submittedVmVersion || scheduledVmVersion, form.supportedVersions,
                        optionDescriptorsData.optionDescriptorsWithCreateSupported);
                  form.compatibility(form.vmVersion);
               });
            },

            validatePage: function() {
               if (!form.vmVersion) {
                  return {
                     error: i18nService.getString("VmUi", "SelectVmVersionPage.pageValidationError"),
                     invalidateNextPages: false,
                     wizardFlow: virtualMachineSpecBuilder.getCreationType()
                  };
               }
               return null;
            },

            submitPage: function() {
               virtualMachineSpecBuilder.setVmVersion(form.vmVersion.id);

               // in case the vm version is changed from the one selected when the
               // scheduled task was created, do not use the data to pre-populate the customize hardware page
               const scheduledTaskData = virtualMachineSpecBuilder.getScheduledData();
               if (!_.isEmpty(scheduledTaskData)) {
                  if (scheduledTaskData.spec.version !== form.vmVersion.id) {
                     scheduledTaskData.useScheduledTaskData = false;
                  }
               }

               return {
                  wizardFlow: virtualMachineSpecBuilder.getCreationType(),
                  invalidateNextPages: form.invalidateNextPages
               };
            }
         };

         function preselectOption(submittedHwVersion, options, supportedVersions) {
            // form.vmVersion is higher priority and it holds the version when user
            // change it in the page.
            var formVmVersion = form.vmVersion ?
                  form.vmVersion.id :
                  null;
            var suggestedHwVersion = formVmVersion || submittedHwVersion;
            form.vmVersion = null;
            if (suggestedHwVersion) {
               form.vmVersion = _.find(options, function(option) {
                  return option.id === suggestedHwVersion;
               });
            }
            // if this is the first time the page is loaded
            // or the selected version is no longer in the possible options
            // select the default version (defined in host/cluster/datacenter) or,
            // if none has been specified, the highest available version
            if (!form.vmVersion) {
               _.each(supportedVersions, function(version) {
                  if (version.defaultConfigOption) {
                     form.vmVersion = _.find(options, function(option) {
                        return (option.id === version.key);
                     });
                  }
               });
            }

            if (!form.vmVersion) {
               form.vmVersion = options[options.length - 1];
            }
         }
      };
   }
})();
