namespace dvs_ui {

   export class DvsReadyToCompletePageComponent {

      public bindings: any;
      public controller: any;
      public templateUrl: string;

      constructor() {
         this.bindings = {
            wizardModel: "<",
            wizardConfig: "<"
         };

         this.controller = DvsReadyToCompletePageComponentController;

         this.templateUrl = "dvs-ui/resources/dvs/create/views/" +
               "dvsReadyToCompletePageComponentTemplate.html";
      }

   }

   class DvsReadyToCompletePageComponentController {

      static $inject = [
         "i18nService",
         "$scope",
         "vxPropertyViewService"
      ];

      public wizardModel: DvsCreateWizardModel;

      public wizardConfig: any;

      private _propertyViewData: any;

      constructor(private i18nService: any,
                  private $scope: any,
                  private vxPropertyViewService: any) {
         this.$scope.i18n = this.i18nService.getString;

      }

      $onInit(): void {
         this.wizardConfig.loading = true;

         this.buildPropertyView();

         this.wizardConfig.loading = false;
      }

      private buildPropertyView(): void {
         let builder: any = this.vxPropertyViewService.createPropertyViewBuilder();

         let generalCategory: any = builder.category("general");
         let generalSection: any = generalCategory.section("generalSection");

         generalSection.property(
               this.i18nService.getString(
                     "DvsUi", "DvsReadyToCompletePage.property.dvsName"),
               this.wizardModel.uniqueDvsName);

         generalSection.property(
               this.i18nService.getString(
                     "DvsUi", "DvsReadyToCompletePage.property.dvsVersion"),
               this.wizardModel.selectedVersion);

         generalSection.property(
               this.i18nService.getString(
                     "DvsUi", "DvsReadyToCompletePage.property.numberOfUplinks"),
               this.wizardModel.numberOfUplinks);

         let niocStatusLabel: string = this.wizardModel.niocEnabled
               ? this.i18nService.getString(
                     "DvsUi", "DvsConfigureSettingsPage.resmgmt.enabled")
               : this.i18nService.getString(
                     "DvsUi", "DvsConfigureSettingsPage.resmgmt.disabled");

         generalSection.property(
               this.i18nService.getString(
                     "DvsUi", "DvsReadyToCompletePage.property.niocStatus"),
               niocStatusLabel);

         if (this.wizardModel.createDefaultPortgroup) {
            generalSection.property(
                  this.i18nService.getString(
                        "DvsUi", "DvsReadyToCompletePage.property.defaultPortgroup"),
                  this.wizardModel.defaultPortgroupName);
         }

         let suggestedActionsSection: any
               = generalCategory.section("suggestedActionsSection")
                     .title(this.i18nService.getString(
                           "DvsUi", "DvsReadyToCompletePage.section.nextActions.title"));

         let portgroupActionKeyBuilder: any = suggestedActionsSection.propertyBuilder()
               .keyBuilder();
         portgroupActionKeyBuilder.text(this.i18nService.getString(
               "DvsUi", "DvsReadyToCompletePage.property.newPortgroup"));
         portgroupActionKeyBuilder.icon("vsphere-icon-virtual-port-group");

         let addHostsActionKeyBuilder: any = suggestedActionsSection.propertyBuilder()
               .keyBuilder();
         addHostsActionKeyBuilder.text(this.i18nService.getString(
               "DvsUi", "DvsReadyToCompletePage.property.addAndManageHosts"));
         addHostsActionKeyBuilder
               .icon("non-clarity-icon k-sprite network-lib-ui-icon-dvsManageHost");

         this._propertyViewData = builder.build();
      }

      public get propertyViewData(): any {
         return this._propertyViewData;
      }

   }

   angular.module("com.vmware.vsphere.client.dvs")
         .component("dvsReadyToCompletePage",
               new DvsReadyToCompletePageComponent());
}
