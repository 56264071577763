namespace h5_vm {

   export class DiskControllerAdvice {
      /**
       * the controller to be reused. if value is not null,
       * use the unit Number to assign the device to
       */
      public recommendedController: any;

      /**
       * the Bus No to create a new Controller if the
       * recommendedController is null. -1 signifies no
       * more controller can be created as the max controller
       * limit has been reached
       */
      public recommendedBusNo: any;

      /**
       * if controller is found, use this to attach the device to
       */
      public recommendedUnitNo: any;

      constructor(controller: any, busNo: any, unitNo: any) {
         this.recommendedController = _.isUndefined(controller) ? null : controller;
         this.recommendedBusNo = _.isUndefined(busNo) ? -1 : busNo;
         this.recommendedUnitNo = _.isUndefined(unitNo) ? 0 : unitNo;
      }
   }

   /**
    * service used to find available disk-controller that can be reused to attach
    * devices.
    */
   export class DiskControllerIndexAdviserService {
      static $inject = ['nodeCountService', 'deviceClassLineageService'];

      private readonly INVALID = -1;
      private readonly MAX_SCSILIMIT = 4;//also applies to SATA
      private readonly MAX_SATALIMIT = 4;
      private readonly MAX_IDELIMIT = 2;
      private readonly VIRTUAL_IDE_CONTROLLER = "VirtualIDEController";
      private readonly VIRTUAL_SATA_CONTROLLER = "VirtualSATAController";
      private readonly  VIRTUAL_SCSI_CONTROLLER = "VirtualSCSIController";

      constructor(private nodeCountService: any,
                  private deviceClassLineageService: any) {
      }

      /**
       *
       * @param inflatedController (the controller whose empty slot is being queried
       * @param deviceOptions : the options available for the devices/controllers
       * @param inflatedDevices : all devices not marked for removal
       * @param deviceType : "VirtualDisk"
       * @returns {number} (the empty node where the device can be attached to)
       */
      private getNextAvailableNodeIndexForController(inflatedController: any,
            deviceOptions: any,
            inflatedDevices: any,
            deviceType: any): number {
         let controller = inflatedController.getCurrentDevice();
         let maxNodeCount = this.nodeCountService.getMaxNodeCountForControllerType(
               controller._type,
               deviceOptions,
               deviceType
         );

         let reservedIndices: number[] = [];
         if (controller.scsiCtlrUnitNumber) {
            reservedIndices.push(controller.scsiCtlrUnitNumber);
         }

         let occupiedNodeIndices: number[] = [];
         occupiedNodeIndices = occupiedNodeIndices.concat(reservedIndices);

         let inflatedDevicesAttachedToThisController = _.filter(inflatedDevices, (inflatedDevice: any) => {
            let device = inflatedDevice.getCurrentDevice();
            return device.controllerKey === inflatedController.getKey();
         });

         if (inflatedDevicesAttachedToThisController.length >= maxNodeCount) {
            return this.INVALID;
         }

         _.map(inflatedDevicesAttachedToThisController, (device) => {
            occupiedNodeIndices.push(device.getCurrentDevice().unitNumber);
         });

         for (let candidateNodeIndex = 0; candidateNodeIndex <= maxNodeCount; candidateNodeIndex++) {
            if (!_.contains(occupiedNodeIndices, candidateNodeIndex)) {
               return candidateNodeIndex;
            }
         }

         return this.INVALID;
      }

      /**
       *
       * @param controllerType : controller type
       * @param deviceOptions : device options
       * @param otherInflatedDevices : all inflated devices of this VM
       * @param deviceType : 'VirtualDisk'
       * @returns : result : an advice object containing:
       *    recommendedController : the found controller to reuse. if not null, check the recommendedUnitNumber
       *                         if null, create a new one, but first check the recommendedBusNumber
       *    recommendedBusNumber : If controller is found, this value does not matter.
       *                            If Controller is not found (null) : this will tell which Bus to create the
       *                            controller on. -1 (INVALID) signifies max controller limit reached.
       *    recommendedUnitNumber : if controller is found, use this value to attach the device to
       *
       *    situation : No Controller exists yet :
       *          {recommendedController: null, recommendedBusNumber: 0, recommendedUnitNumber: 0}
       *    situation : Maximum limit of controllers fully used up
       *          {recommendedController: null, recommendedBusNumber: -1, recommendedUnitNumber: X}
       *    situation : Existing Controllers fully used up (caller must create a new one)
       *          {recommendedController: controller, recommendedBusNumber: value, recommendedUnitNumber: 0}
       *    situation : Existing Controllers have empty slots for reuse
       *          {recommendedController: controller, recommendedBusNumber: X, recommendedUnitNumber: value}
       */
      public getAdviceOnReusableController(controllerType: any,
                                        deviceOptions: any,
                                        otherInflatedDevices: any,
                                        deviceType: any): DiskControllerAdvice {
         let usedBusList: any[] = [],
               recommendedUnitNo: number = 0,
               recommendedController: any = null;

         const controllerTypeToCheckAgainst = this.deviceClassLineageService.getVirtualControllerType(controllerType.typeClass);
         const maxControllerLimit = this.getControllerLimit(controllerTypeToCheckAgainst);
         let targetControllerList: any[] = _.filter(otherInflatedDevices, (inflatedDevice: any) => {
            return (controllerTypeToCheckAgainst) ? inflatedDevice.isOfType(controllerTypeToCheckAgainst): false;
         });

         if (_.isEmpty(targetControllerList)) {
            //no controller exists of this type
            //let the caller create the first one on Bus 0, attach device on Unit 0
            return new DiskControllerAdvice(null, 0, 0);
         }

         _.every(targetControllerList, (controllerDevice) => {
            recommendedUnitNo = this.getNextAvailableNodeIndexForController(
                  controllerDevice,
                  deviceOptions,
                  otherInflatedDevices,
                  deviceType);
            usedBusList.push(controllerDevice.getCurrentDevice().busNumber);
            recommendedController = (recommendedUnitNo !== this.INVALID) ? controllerDevice : null;
            return recommendedController === null;
         });

         let result: DiskControllerAdvice = new DiskControllerAdvice(
               recommendedController, this.INVALID, recommendedUnitNo);


         //if recommendedController exists : empty slot is present : all good, just return
         //OR if max no. of controllers are fully used up, (then recommendedController is null)
         //so return : In either case the recommendedBus is -1 and should not be considered
         if (result.recommendedController || usedBusList.length >= maxControllerLimit) {
            return result;
         }

         //So we have some available controller slots that can be used to create
         //a new controller. Figure which busNo.   Also force the unitNo to 0
         for (let i = 0; i < maxControllerLimit; i++) {
            if (!_.contains(usedBusList, i)) {
               result.recommendedBusNo = i;
               result.recommendedUnitNo = 0;
               break;
            }
         }
         return result;
      }

      // TODO:- Include the limit for other controller types as well.
      //        When the class becomes fully generic supporting all other types of controllers, then we need to rename
      //        the class to ControllerIndexAdviserService
      private getControllerLimit(controllerType: string) {
         switch(controllerType) {
            case this.VIRTUAL_SCSI_CONTROLLER: return this.MAX_SCSILIMIT;
            case this.VIRTUAL_SATA_CONTROLLER: return this.MAX_SATALIMIT;
            case this.VIRTUAL_IDE_CONTROLLER: return this.MAX_IDELIMIT;
            default: return 0;
         }
      }
   }

   angular
         .module('com.vmware.vsphere.client.vm')
         .service('diskControllerIndexAdviserService', DiskControllerIndexAdviserService);
}
