(function() {
   'use strict';
   angular.module('com.vmware.vsphere.client.storage').controller(
      'DeviceBackingDetailsViewController', DeviceBackingDetailsViewController);

   DeviceBackingDetailsViewController.$inject = [
      '$scope',
      'i18nService',
      'vxPropertyViewService',
      'bytesFilter',
      'vuiConstants'
   ];

   function DeviceBackingDetailsViewController ($scope, i18nService, propertyViewService, bytesFilter, vuiConstants) {
      var self = this;
      function buildPropertyViewData () {
         var builder = propertyViewService.createPropertyViewBuilder();
         var category = builder.category('category');
         var model = $scope.masterDetailsViewContext.selectedItem;
         //General
         category.section('general')
            .title('')
            .property(
               i18nService.getString('StorageUi', 'datastore.manage.deviceBacking.device'),
               model.deviceName)
            .property(
               i18nService.getString('StorageUi', 'datastore.manage.deviceBacking.partitionCapacity'),
               model.formattedDeviceCapacity || i18nService.getString('Common', 'unknown'))
            .property(
               i18nService.getString('StorageUi', 'datastore.manage.deviceBacking.devicePartitionFormat'),
               i18nService.getString('StorageUi', 'partitionFormat.' + (model.devicePartitionFormat || 'unknown') )
            )
            .property(
               i18nService.getString('StorageUi', 'deviceList.columns.driveType'),
               i18nService.getString('StorageUi', model.isSsd ? 'disk.ssd' : 'disk.nonSSD')
            )
            .property(
               i18nService.getString('StorageUi', 'deviceList.columns.sectorFormat'),
               model.sectorFormat
            );
         return builder.build();
      }

      this.datagridOptions = {
         pageConfig: {
            hidePager: false
         },
         columnDefs: getColumnDefs(),
         sortMode: vuiConstants.grid.sortMode.SINGLE,
         selectionMode: vuiConstants.grid.selectionMode.SINGLE,
         selectedItems: [],
         resizable: true,
         columnMenu: {
            sortable: false,
            messages: {
               columns: i18nService.getString('CommonUi', 'listview.showColumnsMenu'),
               filter: i18nService.getString('CommonUi', 'listview.filterMenu')
            }
         }
      };

      function getColumnDefs () {
         return [{
            displayName: i18nService.getString('StorageUi', 'storage.devices.column.name'),
            field: 'name',
            template: function(dataItem) {
               return i18nService.getString('StorageUi', 'datastore.partitionType.' + dataItem.name);
            }
         }, {
            displayName: i18nService.getString('StorageUi', 'storage.devices.column.capacity'),
            field: 'partitionCapacity',
            template: function(dataItem) {
               return bytesFilter(dataItem.partitionCapacity, 'B', 'Auto');
            }
         }, {
            displayName: i18nService.getString('StorageUi', 'storage.devices.column.partitionType'),
            field: 'isLogical',
            template: function(dataItem) {
               return (dataItem.isLogical)
                  ? i18nService.getString('StorageUi', 'storage.devices.partitionType.logical')
                  : i18nService.getString('StorageUi', 'storage.devices.partitionType.primary');
            }
         }];
      }

      function refresh() {
         self.summaryData = buildPropertyViewData();
         //Array.isArray(selectedItem.partitions) returns false, which confuses vui-grid
         var dataArray = $scope.masterDetailsViewContext.selectedItem.partitions.slice(0);
         self.datagridOptions.data = dataArray;
      }

      $scope.$watch(function() {
         return $scope.masterDetailsViewContext.selectedItem;
      }, function(newValue, oldValue) {
         if (newValue !== oldValue && newValue){
            refresh();
         }
      });

      refresh();
   }
})();
