namespace h5_client {
   export module admin_ui {

      class CreateEditRoleWizardService {
         static $inject = ['vuiWizardService',
            '$rootScope',
            'i18nService',
            'rolesService',
            'wizardPageService',
            'createEditRolePageService',
            'roleNamePageService'];

         constructor(private vuiWizardService: any, private $rootScope: any, private i18nService: any, private rolesService: any,
            private wizardPageService: any, private createEditRolePageService: any, private roleNamePageService: any) {

         }

         public showWizard(roles: any[], roleData?: any) {
            var wizardScope = this.$rootScope.$new();
            var createRolePage = this.createEditRolePageService.build();
            var createRoleNamePage = this.roleNamePageService.build();
            var flows:any = [];
            var edit = false;
            flows.push({
               id: 'create.role.flowId', // TODO: Get standard flow name
               pages: [
                  createRolePage,
                  createRoleNamePage
               ]
            });
            var pages = [createRolePage, createRoleNamePage];
            wizardScope.wizardConfig = {
               pages: this.wizardPageService.initializePageStates(pages),
               flows: this.wizardPageService.initializeFlowStates(flows)
            };

            wizardScope.wizardConfig.roles = roles;
            wizardScope.wizardConfig.title = this.i18nService.getString('AdminUi', 'roles.create.new');
            wizardScope.wizardConfig.hideToc = true;
            wizardScope.wizardConfig.hideHeader = true;
            wizardScope.wizardConfig.resizable = false;
            wizardScope.wizardConfig.maximizable = false;
            wizardScope.wizardConfig.resizable = false;
            wizardScope.wizardConfig.resizeMinWidth = 984;
            wizardScope.wizardConfig.cssClass = 'create-role-wizard';
            wizardScope.wizardState = {
               name: '',
               selectedPrivilegeIds: []
            };
            if(roleData) {
               edit = true;
               wizardScope.wizardState = {
                  name: roleData.label,
                  description: roleData.summary,
                  selectedPrivilegeIds: roleData.privilegeIds,
                  roleId: roleData.id,

               };
               wizardScope.wizardConfig.title = this.i18nService.getString('AdminUi', 'roles.create.edit');
            }


            wizardScope.wizardConfig.onFinish = () => {
               if(edit) {
                  const nameChanged = wizardScope.wizardState.name !== roleData.label;
                  const finalName = nameChanged ? wizardScope.wizardState.name : null;

                  this.rolesService.editRole(wizardScope.wizardState.roleId, finalName, wizardScope.wizardState.description, wizardScope.wizardState.selectedPrivilegeIds).then(
                      () => {
                         this.$rootScope.$broadcast('refreshRoles');
                      });
               } else {

                  this.rolesService.cloneOrCreateRole(wizardScope.wizardState.name, wizardScope.wizardState.description, wizardScope.wizardState.selectedPrivilegeIds).then(
                      () => {
                         this.$rootScope.$broadcast('refreshRoles');
                      });
               }
               return true;
            };

            this.vuiWizardService({
               scope: wizardScope,
               configObjectName: 'wizardConfig',
               sizeClass: 'vui-large-modal'
            }).show();
         }
      }

      angular.module("com.vmware.vsphere.client.admin")
            .service("createEditRoleWizardService", CreateEditRoleWizardService);
   }
}
