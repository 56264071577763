angular.module('com.vmware.vsphere.client.vm').service('vmMigrationSelectStoragePageService', [
   'i18nService',
   'wizardPageService',
   function (i18nService, wizardPageService) {
      return {
         build: function (wizardScope) {
            return {
               title: i18nService.getString('VmUi', 'MigrationWizard.storageListPage.title'),
               description: i18nService.getString('VmUi', 'MigrationWizard.storageListPage.description'),
               contentUrl: 'vm-ui/resources/vm/views/migrationWizard/pages/migrateSelectStoragePage.html',
               headerUrl: 'vm-ui/resources/vm/views/migrationWizard/pages/migratePagesCustomHeader.html',
               disableNext: function() {
                  return wizardScope.migrationWizardManager.storagePageModel.isValidating;
               },
               onCommit: function() {
                  if (!wizardScope.migrationWizardManager.hasValidStorage()) {
                     wizardPageService.markPageIncompleteWithError(
                        wizardScope.wizardConfig,
                        i18nService.getString('VmUi', 'MigrationWizard.storageListPage.error.invalid')
                     );
                     return false;
                  }

                  // NOTE speev: Recommendation fetching happens at the last page
                  wizardScope.wizardConfig.validationBanner.messages = [];
                  wizardPageService.markPageComplete(
                        wizardScope.wizardConfig, wizardScope.attributes.creationType);
                  return true;
               }
            };
         }
      };
   }]
);
