/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace network_ui {
   import NetStackConfigItem = com.vmware.vsphere.client.h5.network.host.netstack.model.NetStackConfigItem;
   import NetStackReconfigureSpec = com.vmware.vsphere.client.h5.network.host.netstack.model.NetStackReconfigureSpec;

   export class NetStackEditAdvancedPageModel {
      public congestionControlAlgorithm: string;
      public maxNumberOfConnections: number;
      public runtimeMaxNumberOfConnections: number;
      public isRuntimeInfoActive: boolean;


      public constructor(data: NetStackConfigItem) {
         this.congestionControlAlgorithm = data.congestionControlAlgorithm;
         this.maxNumberOfConnections = data.maxNumberOfConnections;
         this.runtimeMaxNumberOfConnections = data.runtimeMaxNumberOfConnections;
         this.isRuntimeInfoActive = data.isRuntimeInfoActive;
      }

      public buildSpec(originalData: NetStackEditAdvancedPageModel): any {
         let spec: any = {};
         spec.maxNumberOfConnections = this.maxNumberOfConnections;
         spec.congestionControlAlgorithm = this.congestionControlAlgorithm;
         return spec;
      }
   }
}
