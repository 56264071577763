/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace dvs_ui {

   import IRootScopeService = angular.IRootScopeService;

   export class DvsCreateWizardService {

      public static $inject = [
         "$rootScope",
         "i18nService",
         "vuiWizardService",
         "pageStateManagementService",
         "mutationService",
         "dvsCreateWizardConstants",
         "dvsCreateWizardPages",
         "dataService",
         "wizardValidationService",
         "defaultUriSchemeUtil"];

      constructor(private $rootScope: IRootScopeService,
                  private i18nService: any,
                  private vuiWizardService: any,
                  private pageStateManagementService: any,
                  private mutationService: any,
                  private wizardConstants: any,
                  private dvsCreateWizardPages: DvsCreateWizardPages,
                  private dataService: any,
                  private wizardValidationService: any,
                  private defaultUriSchemeUtil: any) {

      }

      /**
       * Shows the Create Distributed Switch wizard.
       */
      public show(parentUrn: string): void {

         let wizardScope: any = this.$rootScope.$new();

         wizardScope.model = new DvsCreateWizardModel();
         wizardScope.model.parentEntityUrn = parentUrn;

         wizardScope.wizardConfig = {
            title: this.i18nService.getString("DvsUi", "actionCreateDvsTitle"),
            loadingMessage: this.i18nService.getString("CommonUi", "wizard.loading"),
            onFinish: (): boolean => {
               let spec: any = {
                  parentEntityMor: this.defaultUriSchemeUtil
                        .getManagedObjectReference(wizardScope.model.parentEntityUrn),
                  uniqueDvsName: wizardScope.model.uniqueDvsName,
                  selectedVersion: wizardScope.model.selectedVersion,
                  numberOfUplinks: wizardScope.model.numberOfUplinks,
                  niocEnabled: wizardScope.model.niocEnabled,
                  createDefaultPortgroup: wizardScope.model.createDefaultPortgroup,
                  defaultPortgroupName: wizardScope.model.defaultPortgroupName
               };

               this.mutationService.add(
                     this.wizardConstants.spec.DVS_CREATE_WIZARD_MUTATION_SPEC, spec);

               return true;
            },
            configureSettingsPageInitialized: false
         };

         wizardScope.wizardConfig.pages =
               this.dvsCreateWizardPages.createPages(wizardScope);
         this.wizardValidationService.initWizardValidation(wizardScope);
         this.pageStateManagementService
               .registerWizardForDefaultStateManagementStrategy(wizardScope);

         this.vuiWizardService({
            scope: wizardScope,
            configObjectName: "wizardConfig"
         }).show();

         this.requestProperties(wizardScope);
      }

      private requestProperties(wizardScope: any): void {
         wizardScope.wizardConfig.loading = true;

         this.dataService.getProperties(
               wizardScope.model.parentEntityUrn, [
                  this.wizardConstants.properties.DVS_NEW_UNIQUE_NAME,
                  this.wizardConstants.properties.LOCATION_NAME_PROPERTY,
                  this.wizardConstants.properties.PRIMARY_ICON_ID,
                  this.wizardConstants.properties.SUPPORTED_PRODUCT_SPEC])
               .then((response: any): void => {
                  wizardScope.model.uniqueDvsName = response[
                        this.wizardConstants.properties.DVS_NEW_UNIQUE_NAME];
                  wizardScope.model.parentEntityName = response[
                        this.wizardConstants.properties.LOCATION_NAME_PROPERTY];
                  wizardScope.model.parentEntityIcon = response[
                        this.wizardConstants.properties.PRIMARY_ICON_ID];
                  wizardScope.model.supportedVersions = _.map(
                        response[this.wizardConstants.properties.SUPPORTED_PRODUCT_SPEC],
                        (productSpec: any): string => {
                           return productSpec.version;
                        }).sort((versionA, versionB) => {
                           if (versionA < versionB) {
                              return 1;
                           } else if (versionB < versionA) {
                              return -1;
                           } else {
                              return 0;
                           }
                        });

                  if (wizardScope.model.supportedVersions
                        && wizardScope.model.supportedVersions.length > 0) {
                     wizardScope.model.selectedVersion =
                           wizardScope.model.supportedVersions[0];
                  }

                  wizardScope.model.numberOfUplinks = 4;

                  wizardScope.model.niocEnabled = true;

                  wizardScope.model.createDefaultPortgroup = true;

                  wizardScope.wizardConfig.loading = false;
               });
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .service("dvsCreateWizardService", DvsCreateWizardService);
}
