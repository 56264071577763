/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace dvs_ui {

   import IPromise = angular.IPromise;

   export class DvsPortPoliciesComponent {

      public controller: any;
      public bindings: any;
      public templateUrl: string;

      constructor() {
         this.bindings = {
            selectedPort: "<",
            portContextId: "<",
            isPortGroupContext: "<"
         };
         this.controller = DvsPortPoliciesController;
         this.templateUrl =
               "dvs-ui/resources/dvs/ports/policies/dvsPortPoliciesViewTemplate.html";
      }
   }

   class DvsPortPoliciesController {

      private static DVS_PORTS_POLICIES_DATA_PROPERTY_NAME = "dvsport:portPoliciesData";

      private static DVPG_PORTS_POLICIES_DATA_PROPERTY_NAME =
            "dvportgroupport:portPoliciesData";

      static $inject = [
         "$scope",
         "i18nService",
         "dataService",
         "distributedPortgroupPoliciesViewService"
      ];

      public i18n: Function;

      public selectedPort: any;

      public dvsPortPoliciesData: any;

      public portContextId: any;

      public portsLoading: boolean;

      private isPortGroupContext: boolean;

      private _currentDvsPromise: any;

      constructor(private $scope: any,
                  private i18nService: any,
                  private dataService: any,
                  private distributedPortgroupPoliciesViewService: any) {

         this.i18n = this.i18nService.getString;
      }

      public $onChanges(): void {
         this.refresh(this.selectedPort);
      }

      public refresh(selectedPort: any) {

         if (!selectedPort) {
            return;
         }

         this.portsLoading = true;
         let dvsPromise: IPromise<any>;
         let portsDataProperty: string = this.isPortGroupContext
               ? DvsPortPoliciesController.DVPG_PORTS_POLICIES_DATA_PROPERTY_NAME
               : DvsPortPoliciesController.DVS_PORTS_POLICIES_DATA_PROPERTY_NAME;

         if (this.isPortGroupContext) {
             dvsPromise = this.dataService.getProperties(
                   this.portContextId, [portsDataProperty],{
                           propertyParams:  [{
                              propertyName: portsDataProperty,
                              parameterType: "java.lang.String",
                              parameter: selectedPort.portId
                           }]
                        });
         } else {
            dvsPromise = this.dataService.getProperties(
                  this.portContextId, [portsDataProperty],{
                           propertyParams:  [{
                              propertyName: portsDataProperty,
                              parameterType: "java.util.Map",
                              parameter:  {
                                 portgroupKey: selectedPort.portgroupKey,
                                 portId: selectedPort.portId
                              }
                           }]
                        });
         }

         this._currentDvsPromise = dvsPromise;

         dvsPromise.then((dataServiceResponse: any) => {
            if (this._currentDvsPromise === dvsPromise
                  && dataServiceResponse[portsDataProperty]) {
               this.dvsPortPoliciesData =
                     this.distributedPortgroupPoliciesViewService
                           .buildDistributedPortPoliciesView(dataServiceResponse[portsDataProperty]);
            }
         }).finally(() => {
            this.portsLoading = false;
         });
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .component("dvsPortPoliciesView", new DvsPortPoliciesComponent());
}
