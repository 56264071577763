namespace storage_ui {

   import IController = angular.IController;
   import IDocumentService = angular.IDocumentService;
   import ILocationService = angular.ILocationService;

   declare var h5: any;

   class DatastoreBrowserPopUpController implements IController {

      public static $inject = [
            "$document", "$location", "i18nService", "dataService"];

      constructor(private $document: IDocumentService,
                  private $location: ILocationService,
                  private i18nService: any,
                  private dataService: any) {
      }

      $onInit(): void {
         // Indicate that datastore browser is in pop up mode
         h5.isDatastoreBrowserPopUpWindow = true;

         // Set document title
         let queryStringParams: any = this.$location.search();
         let objectId: string = queryStringParams["datastoreId"];

         this.dataService.getProperties(objectId, ["name"]).then((response: any) => {
            if (response) {
               this.$document[0].title = this.i18nService.getString(
                     "StorageUi", "popupDatastoreBrowser.title", response.name);
            }
         });
      }

      $onDestroy(): void {
         delete h5.isDatastoreBrowserPopUpWindow;
      }
   }

   let datastoreBrowserPopUp: ng.IComponentOptions = {
      controller: DatastoreBrowserPopUpController,
      templateUrl: "storage-ui/resources/storage/views/manage/files/popup/datastoreBrowserPopUp.html"
   };

   angular.module("com.vmware.vsphere.client.storage")
         .component("datastoreBrowserPopUp", datastoreBrowserPopUp);
}