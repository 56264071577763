namespace network_ui {
   export class NetStackEditNamePageComponent {
      public bindings: any;
      public controller: any;
      public templateUrl: string;

      constructor() {
         this.bindings = {
            pageModel: "<"
         };

         this.controller = NetStackEditNamePageComponentController;

         this.templateUrl = "network-ui/resources/network/views/host/netstack/" +
               "edit/pages/netStackEditNamePageComponentTemplate.html";
      }
   }

   class NetStackEditNamePageComponentController {
      static $inject = ["i18nService"];
      private pageModel: NetStackEditNamePageModel;

      constructor(public i18nService: any) {}
   }

   angular.module("com.vmware.vsphere.client.network")
         .component("netStackEditNamePage", new NetStackEditNamePageComponent());
}
