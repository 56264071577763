(function() {
    'use strict';
    angular.module('com.vmware.vsphere.client.commonModule')
        .factory('RemoveTagGridFactory', RemoveTagGridFactory);

    RemoveTagGridFactory.$inject = ['$rootScope', 'tagService', 'i18nService', 'vuiConstants'];

    function RemoveTagGridFactory($rootScope, tagService, i18nService, vuiConstants) {

        function TagGrid(objectId) {
            var self = this;
            self.objectId = objectId;

            self.datagridOptions = {
                columnDefs: [
                    {
                        displayName: i18nService.getString('Common', 'tag.dashboard.assignedNameColumn'),
                        field: 'name',
                        sortable: false,
                        searchable: false
                    },
                    {
                        displayName: i18nService.getString('Common', 'tag.dashboard.categoryColumn'),
                        field: 'categoryName',
                        sortable: false,
                        searchable: false
                    },
                    {
                        displayName: i18nService.getString('Common', 'tag.dashboard.descColumn'),
                        field: 'description',
                        sortable: false,
                        searchable: false
                    }
                ],
                height: '200px',
                resizable: false,
                reorderable: false,
                selectedItems: [],
                selectionMode: vuiConstants.grid.selectionMode.MULTI,
                showCheckboxesOnMultiSelection: false,
                selectedTags: []
            };

            $rootScope.$on('tagUpdate', function(event, changedObjectId) {
                if (self.objectId === changedObjectId) {
                    self.populateDataGrid();
                }
            });
        }

        TagGrid.prototype.populateDataGrid = function() {
            var self = this;
            tagService.getTagInfoForObject(self.objectId).then(function(tagData) {
                self.datagridOptions.data = tagData;
            });
        };

        TagGrid.prototype.removeTag = function() {
            var self = this;
            var numOfSelectedTags = self.datagridOptions.selectedItems.length;
            for (var tag = 0; tag<numOfSelectedTags-1; tag++) {
                var selectedTag = self.datagridOptions.selectedItems[tag];
                tagService.detachTag(selectedTag.resourceObject, self.objectId);
            }
            var lastTagInTheList = self.datagridOptions.selectedItems[numOfSelectedTags-1];
            if(lastTagInTheList) {
                tagService.detachTag(lastTagInTheList.resourceObject, self.objectId).then(function(resp) {
                    $rootScope.$broadcast('tagUpdate', self.objectId);
                    return true;
                });
            }
        };

        TagGrid.prototype.setTagSelectionListener = function(tagSelectionListener) {
            var self = this;
            $rootScope.$watch(
                function() {
                    return self.datagridOptions.selectedItems;
                },
                function(selectedItems) {
                    var selectedTagsInPortlet = (selectedItems && selectedItems.length) ? selectedItems : null;
                    tagSelectionListener.call(this, selectedTagsInPortlet);
                }
            );
        };

        TagGrid.prototype.refresh = function(){
            var self = this;
            tagService.getTagInfoForObject(self.objectId).then(function(tagData) {
                self.datagridOptions.data = tagData;
            });
        };

        function make(objectId) {
            return new TagGrid(objectId);
        }

        return {
            make: make
        };
    }
}());