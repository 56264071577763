/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace dvs_ui {

   export class DvsUpgradeLacpWizardPages {

      public static $inject = [
            "i18nService",
            "dvsUpgradeLacpWizardConstants",
            "simpleValidationCommit",
            "dvsPrerequisitesPageValidator",
            "dvsLagPageValidator",
            "vuiConstants"
      ];

      constructor(private i18nService: any,
                  private wizardConstants: any,
                  private simpleValidationCommit: any,
                  private dvsPrerequisitesPageValidator: any,
                  private dvsLagPageValidator: any,
                  private vuiConstants: any) {
      }

      /**
       * Creates an array of page definitions for the Enhance LACP Support wizard.
       */
      public createPages(wizardScope: DvsUpgradeLacpWizardScope): WizardPage[] {

         let overviewPage: WizardPage = {
            id: this.wizardConstants.pages.DVS_LACP_OVERVIEW_PAGE,
            title: this.i18nService.getString("DvsUi",
                  "DvsUpgradeLacpWizard.overview.title"),
            description: this.i18nService.getString("DvsUi",
                  "DvsUpgradeLacpWizard.overview.description"),
            contentUrl: "dvs-ui/resources/dvs/lacpupgrade/views/" +
                  "dvsUpgradeLacpOverviewPage.html",
            onCommit: this.simpleValidationCommit(function () {
               return [];
            }, wizardScope.wizardConfig),
            validationParams: []
         };

         let prerequisitesPage: WizardPage = {
            id: this.wizardConstants.pages.DVS_LACP_VALIDATION_PAGE,
            title: this.i18nService.getString("DvsUi",
                  "DvsUpgradeLacpWizard.prerequisites.title"),
            description: this.i18nService.getString("DvsUi",
                  "DvsUpgradeLacpWizard.prerequisites.description"),
            contentUrl: "dvs-ui/resources/dvs/lacpupgrade/views/" +
                  "dvsUpgradeLacpPrerequisitesPage.html",
            onCommit: this.simpleValidationCommit(() => {
               return this.dvsPrerequisitesPageValidator.getValidationResult(
                     wizardScope.model.prerequisiteChecks,
                     wizardScope.model);
            }, wizardScope.wizardConfig),
            validationParams: []
         };

         let lagPage: WizardPage = {
            id: this.wizardConstants.pages.DVS_LACP_LAG_PAGE,
            title: this.i18nService.getString("DvsUi",
                  "DvsUpgradeLacpWizard.createLag.title"),
            description: this.i18nService.getString("DvsUi",
                  "DvsUpgradeLacpWizard.createLag.description"),
            contentUrl: "dvs-ui/resources/dvs/lacpupgrade/views/" +
                  "dvsUpgradeLacpLagPage.html",
            onCommit: this.simpleValidationCommit(() => {
               return this.dvsLagPageValidator.getValidationError(wizardScope);
            }, wizardScope.wizardConfig),
            validationParams: ["model.lag.name"]
         };

         let summaryPage: WizardPage = {
            id: this.wizardConstants.pages.DVS_LACP_SUMMARY_PAGE,
            title: this.i18nService.getString("DvsUi",
                  "DvsUpgradeLacpWizard.summary.title"),
            description: this.i18nService.getString("DvsUi",
                  "DvsUpgradeLacpWizard.summary.description"),
            contentUrl: "dvs-ui/resources/dvs/lacpupgrade/views/" +
                  "dvsUpgradeLacpSummaryPage.html",
            onCommit: this.simpleValidationCommit(function () {
               return [];
            }, wizardScope.wizardConfig),
            validationParams: []
         };

         let pages: WizardPage[] = [
               overviewPage,
               prerequisitesPage
         ];

         if (wizardScope.model.lag !== null) {
            pages.push(lagPage);
         }

         pages.push(summaryPage);

         return pages;
      }

      public skipPage(pages: WizardPage[], pageToSkipId: string): void {
         let page: WizardPage = _.findWhere(pages, { id: pageToSkipId });
         if (page) {
            page.state = this.vuiConstants.wizard.pageState.SKIPPED;
         }
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .service("dvsUpgradeLacpWizardPages", DvsUpgradeLacpWizardPages);
}
