/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
(function () {
   'use strict';

   angular
      .module('com.vmware.vsphere.client.network')
      .controller('PortFailoverPolicyPageController',
         PortFailoverPolicyPageController);

   PortFailoverPolicyPageController.$inject = [
      '$scope',
      'portFailoverPolicyPageService',
      'networkUtil',
      'networkUiConstants',
      'editPortgroupService'];

   function PortFailoverPolicyPageController($scope, portFailoverPolicyPageService,
      networkUtil, networkUiConstants, editPortgroupService) {

      var initialData = $scope.initialData.teamingAndFailover;
      this.signPostConfig = {
         message: null,
         title: networkUtil.getString(
            "PortFailoverPolicyPage.lb.loadbalance_ip.popup.title"),
         class: "edit-standard-pg-signpost"
      };

      this.loadBalancingOptions = networkUtil.createNicTeamingPolicyOptions(
            $scope.manager.portgroupData.teamingAndFailover.nicTeamingPolicy);

      this.failureDetectionValidator = {
         message: null,
         isVisible: true
      };

      this.failureDetectionOptions = [{
         val: false,
         label: networkUtil.getString("PortFailoverPolicyPage.linkStatus")
      }, {
         val: true,
         label: networkUtil.getString("PortFailoverPolicyPage.beaconProbing")
      }];

      this.notifySwitchesOptions = [{
         val: false,
         label: networkUtil.getString("PortFailoverPolicyPage.notifyOff")
      }, {
         val: true,
         label: networkUtil.getString("PortFailoverPolicyPage.notifyOn")
      }];

      this.failbackOptions = [{
         val: true,
         label: networkUtil.getString("PortFailoverPolicyPage.failbackOff")
      }, {
         val: false,
         label: networkUtil.getString("PortFailoverPolicyPage.failbackOn")
      }];

      this.notifySwitches = "notifySwitches";
      this.failback = "failback";

      this.failoverOrderHeaderTitle =
            networkUtil.getString("PortFailoverPolicyPage.orderLabel");

      this.failoverOrderActions = [
         networkUiConstants.failoverOrderActions.MOVE_UP,
         networkUiConstants.failoverOrderActions.MOVE_DOWN
      ];

      this.failoverOrder = {
         connectees: $scope.manager.portgroupData.teamingAndFailover.pnics,
         connecteesDetails: $scope.manager.portgroupData.teamingAndFailover.pnics ?
            $scope.manager.portgroupData.teamingAndFailover.pnics.pnicDetails : null,
         labels: {
            active: networkUtil.getString("FailoverOrderEditor.active"),
            standby: networkUtil.getString("FailoverOrderEditor.standby"),
            unused: networkUtil.getString("FailoverOrderEditor.unused")
         }
      };

      this.noItemsSelectedMessage = networkUtil.getString(
         'FailoverOrderEditor.detailsPrompt');

      $scope.$watchCollection(function () {
         var objectsToWatch = [
            $scope.manager.portgroupData.teamingAndFailover.loadBalancingOverridden,
            $scope.manager.portgroupData.teamingAndFailover.loadBalancing,
            $scope.manager.portgroupData.teamingAndFailover.failureDetectionOverridden,
            $scope.manager.portgroupData.teamingAndFailover.checkBeacon,
            $scope.manager.portgroupData.teamingAndFailover.notifySwitchesOverridden,
            $scope.manager.portgroupData.teamingAndFailover.notifySwitches,
            $scope.manager.portgroupData.teamingAndFailover.failbackOverridden,
            $scope.manager.portgroupData.teamingAndFailover.rollingOrder,
            $scope.manager.portgroupData.teamingAndFailover.failoverOrderOverridden
         ];
         // If there are no nics assigned `teamingAndFailover.pnics` is null.
         if ($scope.manager.portgroupData.teamingAndFailover.pnics) {
            objectsToWatch.push(
               $scope.manager.portgroupData.teamingAndFailover.pnics.active.length);
            objectsToWatch.push(
               $scope.manager.portgroupData.teamingAndFailover.pnics.standby.length);
            objectsToWatch.push(
               $scope.manager.portgroupData.teamingAndFailover.pnics.unused.length);
         }
         return objectsToWatch;
      }, function () {
         // a flag indicating whether this digest cycle is the first one,
         // or it has been triggered by a change in any of the watched elements
         this.hasBeenTouched = (this.hasBeenTouched === undefined ? false : true);

         this.loadBalancingMessages = portFailoverPolicyPageService
            .getLoadBalancingMessages($scope.manager.portgroupData.teamingAndFailover);

         this.signPostConfig.message = this.loadBalancingMessages ?
            portFailoverPolicyPageService.formatMessages(this.loadBalancingMessages) :
            null;

         if (this.signPostConfig.message) {
            this.signPostIcon = this.loadBalancingMessages.length === 3 ?
               'exclamation-triangle' : 'info-circle';
         }

         var failureDetectionWarmMessage =
            portFailoverPolicyPageService.getFailureDetectionValidationMessage(
               $scope.manager.portgroupData.teamingAndFailover);

         this.failureDetectionValidator.message = failureDetectionWarmMessage;
         // true if there is a message; false otherwise
         // if we don`t set this manually, we may see an empty signpost
         this.failureDetectionValidator.isVisible = !!failureDetectionWarmMessage;

         if ($scope.failoverPolicies.checkBeacon) {
            this.failureDetectionValidator.isVisible =
                  this.failureDetectionValidator.message !== null;
         }
      }.bind(this));

      $scope.manager.commitPageCallbacks.commitPortFailoverPolicyPage = onCommit.bind(this);

      function onCommit() {
         /*jshint validthis:true */
         var config = $scope.manager.portgroupData.teamingAndFailover;
         $scope.manager.pageDirtyFlags.isFailoverPolicyPageDirty =
               editPortgroupService.hasFailoverPolicyChanged(initialData, config, true) ||
               editPortgroupService.hasFailoverOrderChanged(initialData, config, true);

         // Perform validation ONLY if the page state has been changed
         if($scope.manager.pageDirtyFlags.isFailoverPolicyPageDirty) {
            if (this.failureDetectionValidator.message) {
               $scope.manager.showDialogErrors([this.failureDetectionValidator.message]);
               return false;
            }

            if (this.hasBeenTouched) {
               var failoverOrderWarnMessage =
                     portFailoverPolicyPageService.getFailoverOrderWarnMessage(
                           config.failoverOrderOverridden, config.pnics);
               var loadBalancingWarnMessage = _.find(
                     this.loadBalancingMessages, function(message) {
                        return message.iconClass === 'vsphere-icon-status-warning';
                     });

               if (loadBalancingWarnMessage || failoverOrderWarnMessage) {
                  var messages = [];

                  if (loadBalancingWarnMessage) {
                     messages.push(loadBalancingWarnMessage);
                  }

                  if (failoverOrderWarnMessage) {
                     messages.push(failoverOrderWarnMessage);
                  }
                  return portFailoverPolicyPageService.confirmWarningModal(messages);
               }
            }
         }
         return true;
      }

      return this;
   }
})();
