namespace storage_ui {

   import StorageContainerInfoItem = com.vmware.vsphere.client.h5.storage.data.datastore.StorageContainerInfoItem;
   import CreateVvolDatastoreSpec = com.vmware.vsphere.client.storage.vvol.CreateVvolDatastoreSpec;
   import DatastoreSystem$VvolDatastoreSpec = com.vmware.vim.binding.vim.host.DatastoreSystem$VvolDatastoreSpec;

   export class VvolWizardManager {
      public selectedHosts: any[];
      public availableContainers: StorageContainerInfoItem[] = [];
      public container: StorageContainerInfoItem|null;

      constructor(private commonWizardManager: any,
                  private i18nService: any,
                  private vxPropertyViewService: any,
                  private defaultUriSchemeUtil: any,
                  private mutationService: any,
                  private $log: any) {

      }

      getContainerInUse(): any {
         return _.find(this.availableContainers,
               (container)=>container.info.datastoreInfo
               && container.info.datastoreInfo.name === this.commonWizardManager.getDatastoreName()
         );
      }

      isNameInUse() {
         let datastoreName = this.commonWizardManager.getDatastoreName();
         return !!_.find(this.availableContainers, (container:any)=>container.info.datastoreInfo && container.info.datastoreInfo.name === datastoreName);
      }

      getReadyToCompletePageData() {
         if (!this.container) {
            return;
         }
         let getString = (key:any)=>this.i18nService.getString("StorageUi", key);

         let builder = this.vxPropertyViewService.createPropertyViewBuilder();
         let category = builder.category("category");

         let generalSection = category.section("general").title(
               getString("addDatastoreWizard.vvolReadyToComplete.general"));

         generalSection.property(
               getString("addDatastoreWizard.vvolReadyToComplete.name"),
               this.commonWizardManager.getDatastoreName());

         generalSection.property(
               getString("addDatastoreWizard.vvolReadyToComplete.type"),
               getString("addDatastoreWizard.vvolReadyToComplete.typeVvol"));

         let vvolSection = category.section('vvolSettings').title(
               getString('addDatastoreWizard.vvolReadyToComplete.storageContainer.details'));
         vvolSection.property(getString('addDatastoreWizard.vvolReadyToComplete.storageContainer.name'), this.container.name);
         vvolSection.property(getString('addDatastoreWizard.vvolReadyToComplete.storageContainer.uuid'), this.container.identifier);
         vvolSection.property(getString('addDatastoreWizard.vvolReadyToComplete.storageContainer.storageArray'), this.container.storageArrays);
         vvolSection.property(getString('addDatastoreWizard.vvolReadyToComplete.storageContainer.storageProvider'), this.container.storageProviders);

         if (!this.commonWizardManager.isHostContextObject()) {
            let hostsSection = category.section('hostsSettings').title(
                  getString('addDatastoreWizard.vvolReadyToComplete.hostConnectivity'));

            let propertyBuilder = hostsSection.propertyBuilder();
            propertyBuilder.keyBuilder()
                  .text(getString('addDatastoreWizard.vvolReadyToCompletePage.hosts'))
                  .exit();

            this.selectedHosts.forEach((hostItem: any)=> {
               propertyBuilder.valueBuilder()
                     .text(hostItem.formattedName)
                     .icon('vsphere-icon-host');
            });
         }
         return builder.build();
      }

      submit() {
         let specs: any[] = [];

         if (this.commonWizardManager.isHostContextObject()) {
            var hostRef = this.defaultUriSchemeUtil.getManagedObjectReference(
                  this.commonWizardManager.getContextObject());
            specs.push(this.buildVvolDatastoreCreateSpec(hostRef));
         } else {
            this.selectedHosts.forEach((hostItem) => {
               specs.push(this.buildVvolDatastoreCreateSpec(hostItem.hostRef));
            });
         }

         if (specs.length > 0) {
            this.mutationService.addMulti(
                  "com.vmware.vsphere.client.storage.vvol.CreateVvolDatastoreSpec", specs);
            return true;
         }

         return false;
      }

      buildVvolDatastoreCreateSpec(hostRef: any) {
         if (!this.container) {
            this.$log.warn("VvolWizardManager.container can't be undefined");
            return;
         }
         let vvolCreateSpec: CreateVvolDatastoreSpec = new CreateVvolDatastoreSpec();
         vvolCreateSpec.host = hostRef;
         vvolCreateSpec.storageFolder = this.commonWizardManager.getStorageFolder();

         let vvolDatastoreSpec: DatastoreSystem$VvolDatastoreSpec = new DatastoreSystem$VvolDatastoreSpec();
         vvolDatastoreSpec.name = this.commonWizardManager.getDatastoreName();
         vvolDatastoreSpec.scId = this.container.identifier;
         vvolCreateSpec.vvolDatastoreSpec = vvolDatastoreSpec;

         return vvolCreateSpec;
      }

   }


   angular.module("com.vmware.vsphere.client.storage")
         .factory("vvolWizardManagerFactory", ["i18nService", "vxPropertyViewService", "defaultUriSchemeUtil",
            "mutationService", "$log",
            function (i18nService: any, vxPropertyViewService: any, defaultUriSchemeUtil: any,
                      mutationService: any, $log: any) {

               return {
                  build (commonWizardManager: any): VvolWizardManager{
                     return new VvolWizardManager(commonWizardManager, i18nService,
                           vxPropertyViewService, defaultUriSchemeUtil, mutationService, $log);
                  }
               };

            }]);
}
