/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace h5_vm {

   import CryptoKeyId = com.vmware.vim.binding.vim.encryption.CryptoKeyId;

   export class VmCryptUtilService {

      public static $inject: string[] = [
         "defaultUriSchemeUtil",
         "dataService",
         "storageProfileService",
         "i18nService"
      ];

      private static readonly PROPERTY_KMIP_CLUSTERS = "kmipClusters";
      private static readonly PROPERTY_DEFAULT_KMIP_CLUSTER_STATUS = "h5DefaultKmipClusterStatus";

      constructor(private defaultUriSchemeUtil: any,
                  private dataService: any,
                  private storageProfileService: any,
                  private i18nService: any
      ) {
      }

      public isExistingDiskEncrytped(device: any /* VirtualDisk */): boolean {
         if (!device || !device.backing) {
            return false;
         }

         let keyId: CryptoKeyId = this.getCryptoKeyId(device.backing);
         if (this.isCryptoKeyEmpty(keyId)) {
            return false;
         }

         return true;
      }

      public isCryptoKeyEmpty(keyId: CryptoKeyId): boolean {
         return !keyId || !keyId.keyId;
      }

      public getKmipClusters(serverGuid: string): IPromise<any> {
         var rootFolder = this.defaultUriSchemeUtil.getRootFolder(serverGuid);
         return this.dataService.getProperties(rootFolder,
               [VmCryptUtilService.PROPERTY_KMIP_CLUSTERS]);
      }

      public getDefaultKmipServerStatus(serverGuid: string, skipErrorInterceptor: boolean = false): any {
         const rootFolder = this.defaultUriSchemeUtil.getRootFolder(serverGuid);
         return this.dataService.getProperties(rootFolder,
               [VmCryptUtilService.PROPERTY_DEFAULT_KMIP_CLUSTER_STATUS], {
                  skipErrorInterceptor: skipErrorInterceptor
               });
      }

      public getDiskEncryptionStatus(isDiskAlreadyEncrypted: boolean,
            isStorageProfileChanged: boolean, isEncryptionProfileApplied: boolean): string | undefined {
         // Disk will be encrypted
         if (!isDiskAlreadyEncrypted && isStorageProfileChanged && isEncryptionProfileApplied) {
            return this.i18nService.getString('VmUi', 'DiskPage.diskWillBeEncrypted');
         }

         // Disk will be decrypted
         if (isDiskAlreadyEncrypted && isStorageProfileChanged && !isEncryptionProfileApplied) {
            return this.i18nService.getString('VmUi', 'DiskPage.diskWillBeDecrypted');
         }

         // Disk is encrypted and will stay like this
         if (isDiskAlreadyEncrypted) {
            return this.i18nService.getString('VmUi', 'DiskPage.Encrypted');
         }

         return undefined;
      }

      public isVmHomeEncrypted(vmConfigContext: any): boolean {
         if (vmConfigContext.vmProfile) {
            return this.storageProfileService
                  .isEncryptionStorageProfile(vmConfigContext.vmProfile[0]);
         }

         if (!this.isCryptoKeyEmpty(vmConfigContext.config.keyId)) {
            return true;
         }

         return false;


      }

      public findEncryptionProfile(profiles: any[]): any {
         return _.find(profiles, (profile) => {
            return this.storageProfileService
                  .isEncryptionStorageProfile(profile);
         });
      }

      public isVmCryptPolicyDisabledForMigrateWizard(managedEntity: string, model: MigrateSelectStoragePageModel): void {
         let serverGuid: string = this.defaultUriSchemeUtil.getPartsFromVsphereObjectId(managedEntity).serverGuid;

         model.isEncryptionOptionsDisabled = false;

         this.getDefaultKmipServerStatus(serverGuid)
               .then((response: any) => {
                  // When the user doesn't have permission to aggregate the KMS statuses,
                  // don't stop him because he will still be able to create encrypted VM
                  // if the KMS servers are OK.
                  if (response && response.h5DefaultKmipClusterStatus
                        && response.h5DefaultKmipClusterStatus.noPermission) {
                     model.isEncryptionOptionsDisabled = false;
                     return;
                  }

                  let isConnectionNormal: boolean = response && response.h5DefaultKmipClusterStatus &&
                        response.h5DefaultKmipClusterStatus.connectionStatus === ConnectionStatus.CONNECTED;
                  let isCertificateExpired: boolean = response && response.h5DefaultKmipClusterStatus &&
                        response.h5DefaultKmipClusterStatus.certificateStatus === CertStatus.EXPIRED;

                  model.isEncryptionOptionsDisabled = !isConnectionNormal || isCertificateExpired;
               });
         return;
      }

      private getCryptoKeyId(backing: any) {
         if (backing === null) {
            return null;
         }

         if (backing.keyId || backing.keyid) {
            return backing.keyId || backing.keyid;
         }

         return null;
      }

   }

   export enum ConnectionStatus {
      CONNECTED = <any> "CONNECTED",
      NO_TRUST_CONNECTION = <any> "NO_TRUST_CONNECTION",
      ERROR_RETRIEVING = <any> "ERROR_RETRIEVING"
   }

   export enum CertStatus {
      GOOD = <any> "GOOD",
      ABOUT_TO_EXPIRE = <any> "ABOUT_TO_EXPIRE",
      EXPIRED = <any> "EXPIRED",
      NA = <any> "NA",
      ERROR_RETRIEVING = <any> "ERROR_RETRIEVING"
   }

   angular.module("com.vmware.vsphere.client.vm")
         .service("vmCryptUtilService", VmCryptUtilService);
}

