/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace dvs_ui {

   export class DvsHealthCheckManageDialogValidator {

      public static $inject = [
         "i18nService",
         "networkUtil"
      ];
      public i18n: Function;

      constructor(private i18nService: any,
                  private networkUtil: any) {
         this.i18n = i18nService.getString;
      }

      public getAllErrors(pageModel: DvsHealthCheckManageDialogModel): string[] {

         let messages: string[] = [];

         if (pageModel.vlanMtuInterval === null || pageModel.vlanMtuInterval === undefined) {
            let errorMessage: string =
                  this.i18n('DvsUi', 'healthCheck.manage.dialog.errorMessage',
                        this.i18n('DvsUi', 'healthCheck.manage.view.vlanMtuProperty'));
            messages.push(errorMessage);
         } else {
            let message: string | null = this.getMonitoringIntervalValidationMessage(
                  pageModel.vlanMtuInterval,
                  this.i18n('DvsUi', 'healthCheck.manage.view.vlanMtuProperty'));
            if (message) {
               messages.push(message);
            }
         }

         if (pageModel.teamingAndFailoverInterval === null ||
               pageModel.teamingAndFailoverInterval === undefined) {
            let errorMessage: string =
                  this.i18n('DvsUi', 'healthCheck.manage.dialog.errorMessage',
                        this.i18n('DvsUi', 'healthCheck.manage.view.teamingProperty'));
            messages.push(errorMessage);
         } else {
            let message: string | null = this.getMonitoringIntervalValidationMessage(
                  pageModel.teamingAndFailoverInterval,
                  this.i18n('DvsUi', 'healthCheck.manage.view.teamingProperty'));
            if (message) {
               messages.push(message);
            }
         }

         return messages;
      }

      public getMonitoringIntervalValidationMessage(monitoringInterval: number,
                                                    monitoringType: string): string | null {
         let errorMessage: string =
               this.i18n('DvsUi', 'healthCheck.manage.dialog.errorMessage', monitoringType);
         if (monitoringInterval === null || monitoringInterval === undefined) {
            return errorMessage;
         } else {
            return this.getNumericInputValidationMessage(monitoringInterval, errorMessage);
         }
      }

      private getNumericInputValidationMessage(value: number,
                                               errorMessage: string): string | null {
         if (!this.networkUtil.isPositiveInteger(Number(value))) {
            return errorMessage;
         }
         return null;
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .service("dvsHealthCheckManageDialogValidator", DvsHealthCheckManageDialogValidator);
}
