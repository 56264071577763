/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */

angular.module('com.vmware.vsphere.client.vm').factory('VirtualNvdimmWrapper', [
   'EditableVirtualDevice',
   function(EditableVirtualDevice) {
      var VirtualNvdimmWrapper = function(device) {
         var originalDevice = device;
         EditableVirtualDevice.call(this, device);

         this.setFileOperation = function(fileOperation) {
            this.getCurrentDeviceSpec().fileOperation = fileOperation;
         };

         this.setProfile = function(profile) {
            this.getCurrentDeviceSpec().profile = profile;
         };

         this.getCapacityDiff = function() {
            var currentCapacity = this.getCurrentDevice().capacityInMB;
            if (this.isNew()) {
               return currentCapacity;
            }
            return currentCapacity - originalDevice.capacityInMB;
         };

         this.getCapacityInMB = function() {
            return this.getCurrentDevice().capacityInMB;
         };

         this.getOriginalCapacityInMB = function() {
            if (this.isNew()) {
               return 0;
            }
            return originalDevice.capacityInMB;
         };
      };

      return VirtualNvdimmWrapper;
   }
]);
