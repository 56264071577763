/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace dvs_ui {

   import IController = angular.IController;
   import HostNetworkDetailsData = com.vmware.vsphere.client.dvpg.api.HostNetworkDetailsData;
   import ManagedObjectReference = com.vmware.vim.binding.vmodl.ManagedObjectReference;
   import VmVnicSettingsData = com.vmware.vsphere.client.h5.network.dvs.addhost.model.VmVnicSettingsData;
   export class VmVnicSettingsComponent {

      public bindings: any;
      public controller: any;
      public templateUrl: string;

      constructor() {
         this.bindings = {
            vmRef: "<",
            nicKey: "<"
         };

         this.controller = VmVnicSettingsController;
         this.templateUrl = "dvs-ui/resources/dvs/addhost/components/vmVnicSettings/vmVnicSettingsTemplate.html";
      }
   }

   class VmVnicSettingsController implements IController {

      static $inject = [
         "i18nService",
         "dataService",
         "defaultUriSchemeUtil",
         "vxPropertyViewService"
      ];

      private static readonly VM_VNIC_SETTINGS_PROPERTY: string = "dvsAddHost:vmVnicSettingsData";

      // Bindings

      public vmRef: ManagedObjectReference;

      public nicKey: number | undefined;

      // Other public properties

      public propertyViewData: any;

      public loadingData: boolean = false;

      constructor(private i18nService: any,
                  private dataService: any,
                  private defaultUriSchemeUtil: any,
                  private vxPropertyViewService: any) {
      }

      public $onInit(): void {
         this.requestData();
      }

      public requestData() {
         this.loadingData = true;

         let dsOptions: any = {};
         if (this.nicKey !== undefined) {
            dsOptions.propertyParams = [{
               propertyName: VmVnicSettingsController.VM_VNIC_SETTINGS_PROPERTY,
               parameterType: "java.lang.Integer",
               parameter: this.nicKey
            }];
         }

         this.dataService.getProperties(
               this.defaultUriSchemeUtil.getVsphereObjectId(this.vmRef),
               [VmVnicSettingsController.VM_VNIC_SETTINGS_PROPERTY],
               dsOptions).then((response: any): void => {
            if (response) {
               this.buildPropertyViewData(
                     response[VmVnicSettingsController.VM_VNIC_SETTINGS_PROPERTY]);
            }
         }).finally(() => {
            this.loadingData = false;
         });
      }

      private buildPropertyViewData(vmVnicSettingsDataItems: VmVnicSettingsData[]) {
         let builder = this.vxPropertyViewService.createPropertyViewBuilder();
         let defaultCategory = builder.category("default");

         _.each(vmVnicSettingsDataItems, (item: VmVnicSettingsData): void => {
            let vmVnicSection = defaultCategory.section(item.key.toString()).title(item.adapterName);
            vmVnicSection.property(
                  this.i18nService.getString("DvsUi", "VmVnicPropertiesView.macAddress"),
                  item.macAddress);
            if (item.adapterType) {
               vmVnicSection.property(
                     this.i18nService.getString("DvsUi", "VmVnicPropertiesView.adapterType"),
                     item.adapterType);
            }
            if (item.portgroupName) {
               vmVnicSection.property(
                     this.i18nService.getString("DvsUi", "VmVnicPropertiesView.portGroup"),
                     item.portgroupName);
            }
            if (item.sriovInfo) {
               vmVnicSection.property(
                     this.i18nService.getString("DvsUi", "VmVnicPropertiesView.virtualFunctionId"),
                     item.sriovInfo.virtualFunctionId
                           ? item.sriovInfo.virtualFunctionId
                           : this.i18nService.getString("DvsUi", "VmVnicPropertiesView.noData"));
               vmVnicSection.property(
                     this.i18nService.getString("DvsUi", "VmVnicPropertiesView.physicalFunctionId"),
                     this.i18nService.getString("DvsUi",
                           "VmVnicPropertiesView.physicalFunctionFormat",
                           item.sriovInfo.physicalFunctionId,
                           item.sriovInfo.physicalFunctionDeviceId
                     ));
               vmVnicSection.property(this.i18nService.getString("DvsUi", "VmVnicPropertiesView.gosMtu"),
                     item.sriovInfo.allowGuestOsMtuChange
                           ? this.i18nService.getString("DvsUi", "VmVnicPropertiesView.gosMtu.allowed")
                           : this.i18nService.getString("DvsUi", "VmVnicPropertiesView.gosMtu.disallowed"));
            }
         });

         this.propertyViewData = builder.build();
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .component("vmVnicSettings", new VmVnicSettingsComponent());
}
