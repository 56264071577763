namespace network_ui {
   export class NetStackEditAdvancedPageValidator {
      public static $inject = [
         "i18nService",
         "networkUiConstants"
      ];

      constructor(private i18nService: any, private networkUiConstants: any) {
      }

      public getMaxNumberOfConnectionsValidationMessage(
            maxNumentOfConnections: number): string | null {
         let isValid: boolean = !this.isEmptyValue(maxNumentOfConnections);

         if(maxNumentOfConnections > this.networkUiConstants.HOST_MAX_NUMBER_OT_CONNECTIONS_MAX ||
               maxNumentOfConnections < this.networkUiConstants.HOST_MAX_NUMBER_OT_CONNECTIONS_MIN) {
            isValid = false;
         }

         return isValid ? null : this.i18nService.getString("NetworkUi",
               "DnsAndRoutingData.invalidMaxNumberOfConnectionsLength");
      }

      private isEmptyValue(value: any): boolean {
         return value === undefined || value === null || value === "";
      }
   }

   angular.module("com.vmware.vsphere.client.network")
         .service("netStackEditAdvancedPageValidator", NetStackEditAdvancedPageValidator);
}
