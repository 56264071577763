(function() {
   'use strict';

   /**
    * Constants related to host services enabled on vnics.
    */
   angular
         .module('com.vmware.vsphere.client.network')
         .constant('hostVnicServiceConstants', {

            /**
             * Host vnic service keys used in the VirtualNicAddSpec mutation spec.
             *
             * Enabled services are described with array of {key:boolean} objects, where
             * the key is one of these keys.
             */
            keys: {
               VMOTION: 'isVmotionEnabled',
               PROVISIONING: 'isProvisioningNfcTrafficEnabled',
               FT_LOGGING: 'isFtEnabled',
               MANAGEMENT: 'isHaEnabled',
               VR: 'isVRTrafficEnabled',
               VR_NFC: 'isVrNfcTrafficEnabled',
               VSAN: 'isVsanEnabled'
            },

            /**
             * Host vnic service features used in the EditPortPropertiesPageModel model.
             * The same values are intentionally used in HostVnicServicesFactory.
             *
             * Feature flags are used either to show or hide as well as
             * either to enable or disable service UI controls.
             */
            featureFlags: {
               VMOTION_CONFIGURABLE: 'isVmotionConfigurable',
               PROVISIONING_CONFIGURABLE: 'isProvisioningConfigurable',
               PROVISIONING: 'isProvisioningNfcTrafficSupported',
               VR: 'isVrTrafficSupported',
               VR_NFC: 'isVrNfcTrafficSupported',
               VSAN: 'isVsanSupported',
               TCP_IP_STACK: 'isTcpIpStackSupported',
               TCP_IP_STACK_DEFAULT: 'isTcpIpStackTheDefaultOne',
               TCP_IP_STACK_PROVISIONING: 'isTcpIpStackTheProvisioningOne',
               TCP_IP_STACK_VMOTION: 'isTcpIpStackTheVmotionOne'
            },

            /**
             * The names of host options provided by HostVnicServicesData. Their meaning
             * is if Vmotion or Provisiong service traffics are supported via vnics on
             * a given host.
             */
            options: {
               VMOTION_ENABLED: 'isVmotionTrafficEnabledForDefaultStack',
               PROVISIONING_ENABLED: 'isProvisioningTrafficEnabledForDefaultStack'
            }
         });
})();