namespace h5_vm {

   import VmVersion = h5_vm.VmVersion;

   export interface VmData {
      "optionDescriptors": any;
      "config.version": string;
   }

   export class VmHardwareCompatibilityService {
      static $inject: string[] = ['i18nService'];

      constructor(private i18nService: any) {
      }

      /**
       * Builds an array of vm versions, to be used in the vmHardwareCompatibility component
       * for display of the possible hardware versions for the selected vm/s
       * @param optionDescriptors      array of possible hardware versions for the vm,
       *                               in format as returned from the Vc
       * @param is65VcOrLater   boolean  set to true if the Vc's version is 6.5 or later, false if not
       * @returns {VmVersion[]}        an array of objects, used for display
       *                               of the possible hardware versions for the selected vm/s
       */
      public buildVmVersions(optionDescriptors: Array<any>, is65VcOrLater: boolean): VmVersion[] {
         let vmVersions: VmVersion[] = <VmVersion[]> _.map(optionDescriptors, (optionDescriptor: any) => {
            return this.buildVmVersion(optionDescriptor, is65VcOrLater);
         });

         return vmVersions;
      }

      /**
       * Given an array of VmData, builds an array of vmVersions, ordered by key,
       * containing information for which vms the version is applicable
       * @param data:VmData[]           an array of VmData per Vm, containing the Vm's
       *                                hardware version and option descriptors
       * @param is65VcOrLater  boolean  shows if the Vc in which the vm is placed is 6.5
       *                                or later version
       * @returns {VmVersion[]}         an array of VmVersions, whose key is the Vm version key
       *                                (Ex: {
       *                                      'vmx-07': {
       *                                           key: 'vmx-07',
       *                                           name:'ESX/ESXi 4.0 and later',
       *                                           compatibilityInfo: 'This virtual machine uses hardware version 7...'
       *                                           vms:['urn:vmomi:VirtualMachine:vm-100:1111-2222-3333', '...', ...]
       *                                        },{...},...}
       */
      public buildSupportedVersionsForVms(data: VmData[], is65VcOrLater: boolean): Array<VmVersion> {
         let supportedVersions: Array<VmVersion> = new Array<VmVersion>();
         angular.forEach(data, (vmData: VmData, vmId: string) => {
            let vmOptionDescriptors: any = vmData.optionDescriptors;
            let vmHardwareVersion: string = vmData['config.version'];
            this.buildVmSupportedOptions(
                  vmId, vmOptionDescriptors, vmHardwareVersion,
                  is65VcOrLater, supportedVersions);
         });
         return _.sortBy(supportedVersions, 'id');
      }

      /**
       * Given a Vm's current hardware version and option descriptors,
       * adds to the supportedVersions array option dewcriptors that are not yet added,
       * or adds the vm's id to the array of supported Vms per a Hardware version
       * @param vmId string                  the Id of the vm
       * @param optionDescriptors            the supported option descriptors for the Vm
       * @param vmHardwareVersion            the current hardware version of the Vm
       * @param is65VcOrLater  boolean       shows if the Vc in which the vm is placed is 6.5
       * @param supportedVersions            an array of VmVersions
       */
      private buildVmSupportedOptions(vmId: string,
                                      optionDescriptors: any,
                                      vmHardwareVersion: string,
                                      is65VcOrLater: boolean,
                                      supportedVersions: any): void {
         for (var i = 0; i < optionDescriptors.length; i++) {
            if (vmHardwareVersion < optionDescriptors[i].key
                  && optionDescriptors[i].upgradeSupported) {
               let supportedVersion = _.find(supportedVersions, (version: VmVersion) => {
                  return version.id === optionDescriptors[i].key;
               });
               if (!supportedVersion) {
                  supportedVersion =
                        this.buildVmVersion(optionDescriptors[i], is65VcOrLater);
                  supportedVersion.vms = [vmId];
                  supportedVersions.push(supportedVersion);
               } else {
                  let vms = supportedVersion.vms;
                  if (!vms) {
                     vms = [];
                  }
                  vms.push(vmId);
               }
            }
         }
      }

      /**
       * Builds an VmVersion object from the supplied optionDescriptor,
       * changing the compatibilityInfo text according to the Vc's version
       * @param optionDescriptor
       * @param is65VcOrLater boolean    shows if the Vc in which the vm is placed is 6.5
       * @returns {VmVersion}
       */
      private buildVmVersion(optionDescriptor: any, is65VcOrLater: boolean): VmVersion {
         return <VmVersion> {
            id: optionDescriptor.key,
            name: this.i18nService.getString(
                  'VmUi', 'VmHardware.HWVersion.short.' + optionDescriptor.key),
            compatibilityInfo: is65VcOrLater ?
                  this.i18nService.getString(
                        'VmUi', 'SelectVmVersionPage.' + optionDescriptor.key) :
                  this.i18nService.getString(
                        'VmUi', 'SelectVmVersionPageLessThan65Vc.' + optionDescriptor.key)
         };
      }
   }

   angular.module('com.vmware.vsphere.client.vm')
         .service('vmHardwareCompatibilityService', VmHardwareCompatibilityService);
}
