/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
/*
 * Service to create VmfsPartitionConfigurationPage.
 * Validates the partition configuration.
 */
(function () {
   'use strict';
   angular.module('com.vmware.vsphere.client.storage')
      .service('vmfsPartitionConfigurationPageService', vmfsPartitionConfigurationPageService);

   vmfsPartitionConfigurationPageService.$inject = [
         'i18nService',
         'wizardPageService',
         'datastoreConstants',
         'bytesFilter'];

   function vmfsPartitionConfigurationPageService(
         i18nService, wizardPageService, datastoreConstants, bytesFilter) {

      return {
         build: function (wizardScope) {
            return {
               title: i18nService.getString('StorageUi', 'addDatastoreWizard.vmfsPartitionConfigPage.title'),
               description: i18nService.getString('StorageUi', 'addDatastoreWizard.vmfsPartitionConfigPage.description'),
               contentUrl: 'storage-ui/resources/storage/views/wizard/createDatastore/pages/vmfs/VmfsPartitionConfigurationPage.html',
               state: undefined,
               onCommit: function() {
                  return validate(wizardScope);
               }
            };
         }
      };

      function validate(wizardScope) {
         // Check for empty selection in device option combo
         if (!wizardScope.vmfsWizardManager.getSelectedVmfsDatastoreOptionItem()) {
            wizardPageService.markPageIncompleteWithError(
                  wizardScope.wizardConfig,
                  i18nService.getString('StorageUi', 'addDatastoreWizard.vmfsPartitionConfigPage.noSelection')
            );
            return false;
         }

         // Check datastore size
         var dsSize = wizardScope.vmfsWizardManager.getDatastoreSizeInGb();
         var minSize = datastoreConstants.vmfsSize.MIN_DATASTORE_SIZE_GB;
         if (wizardScope.vmfsWizardManager.getSelectedVmfsVersion() >= datastoreConstants.vmfsVersions.VMFS_6) {
            minSize = datastoreConstants.vmfsSize.MIN_DATASTORE_V6_SIZE_GB;
         }
         if (dsSize < minSize) {
            wizardPageService.markPageIncompleteWithError(
                  wizardScope.wizardConfig,
                  i18nService.getString('StorageUi',
                        'addDatastoreWizard.vmfsPartitionConfigPage.invalidSizeError',
                        bytesFilter(minSize, 'GB', 'GB', 2))
            );
            return false;
         }

         wizardScope.wizardConfig.validationBanner.messages = [];
         wizardPageService.markPageComplete(wizardScope.wizardConfig,
            wizardScope.commonWizardManager.getDatastoreType());
         return true;
      }
   }
})();
