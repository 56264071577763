(function() {
   'use strict';
   angular.module('com.vmware.vsphere.client.host').service('editTimeConfigurationService',
         EditTimeConfigurationService);
   EditTimeConfigurationService.$inject = [
      'i18nService',
      'hostServicesConstants',
      'vuiConstants'];

   function EditTimeConfigurationService(i18nService, hostServicesConstants, vuiConstants) {

      // -- Public methods --

      var ntpServiceId;
      var dateTimeConfig;
      var objectId;
      var timeConfigSpec;

      function initService(data, objId) {
         ntpServiceId = data.ntpServiceId;
         dateTimeConfig = data.dateTimeConfig;
         objectId = objId;
         timeConfigSpec = {
            _type: 'com.vmware.vsphere.client.host.config.TimeConfigSpec',
            dateTimeConfig: dateTimeConfig
         };
      }

      function initData(data) {
         return {
            dateTimeConfig: data.dateTimeConfig,
            ntpServiceId: data.ntpServiceId,
            selectedPolicy: getPolicyByKey(data.ntpServicePolicy),
            manuallySetTime: !data.ntpClientEnabled,
            ntpServers: data.dateTimeConfig.ntpConfig.server ? data.dateTimeConfig.ntpConfig.server.join() : '',
            isRunning: data.ntpServiceState,
            serviceStatus: data.ntpServiceState ?
                  i18nService.getString(
                        'HostUi',
                        'timeConfig.ntpServiceStatusRunning') :
                  i18nService.getString(
                        'HostUi',
                        'timeConfig.ntpServiceStatusStopped'),
            date: moment(data.currentTimeInMillis).format('YYYY-MM-DD'),
            time: moment(data.currentTimeInMillis).format('HH:mm:ss')

         };
      }

      function createTimeConfigSpec(modalOptions, initDataObject, startService) {
         modalOptions.alerts = [];
         modalOptions.isAlertClosed = true;

         if (initDataObject.manuallySetTime) {
            return createManuallyTimeConfigSpec(
                  initDataObject,
                  modalOptions
            );
         } else {
            return createEnabledNtpTimeConfigSpec(
                  initDataObject,
                  startService
            );
         }
      }


      function getPolicyDropdownList() {
         return [
            {
               key: 'automatic',
               label: i18nService.getString('HostUi', 'timeConfig.startupPolicyOpenPorts')
            },
            {
               key: 'on',
               label: i18nService.getString('HostUi', 'timeConfig.startupPolicyWithHost')
            },
            {
               key: 'off',
               label: i18nService.getString('HostUi', 'timeConfig.startupPolicyManually')
            }
         ];
      }

      // -- Private methods --

      function createManuallyTimeConfigSpec(initDataObject, modalOptions) {
         var timeConfigSpec = createDefaultTimeConfigSpec(initDataObject, false);

         try {
            timeConfigSpec.targetTime = parseDateTime(initDataObject.date, initDataObject.time);
         } catch (e) {
            modalOptions.alerts.push({
               type: vuiConstants.validationBanner.type.ERROR,
               text: i18nService.getString(
                     'HostUi',
                     'timeConfig.dateTimeFormat.error')
            });

            modalOptions.isAlertClosed = false;
         }

         return timeConfigSpec;
      }

      function createEnabledNtpTimeConfigSpec(initDataObject, startService) {
         var timeConfigSpec = createDefaultTimeConfigSpec(initDataObject, true);

         if (startService) {
            timeConfigSpec.action = hostServicesConstants.action.START;
         }
         timeConfigSpec.dateTimeConfig.ntpConfig = {
            _type: 'com.vmware.vim.binding.vim.host.NtpConfig',
            configFile: [],
            server: getServers(initDataObject.ntpServers)
         };

         return timeConfigSpec;
      }

      function createDefaultTimeConfigSpec(initDataObject, enableNtpClient) {
         var timeConfigSpec = {};

         timeConfigSpec.enableNtpClient = enableNtpClient;
         timeConfigSpec.dateTimeConfig = initDataObject.dateTimeConfig;
         timeConfigSpec.serviceId = initDataObject.ntpServiceId;
         timeConfigSpec.servicePolicy = initDataObject.selectedPolicy.key;

         return timeConfigSpec;
      }

      function getServers(ntpServers) {
         ntpServers = ntpServers ? ntpServers : '';
         var servers = ntpServers.split(',');
         servers = _.map(servers, function(server) {
            return server.trim();
         });
         servers = _.filter(servers, function(server) {
            return !!server;
         });
         return servers;
      }

      function parseDateTime(date, time) {
         if (typeof date !== 'string' || !moment(date, 'YYYY-MM-DD', true).isValid()) {
            throw new Error('Invalid date');
         }

         if (typeof time !== 'string' || !moment(time, 'HH:mm:ss', true).isValid()) {
            throw new Error('Invalid time');
         }

         return moment(date + ' ' + time, 'YYYY-MM-DD HH:mm:ss', true).toDate();
      }

      function getPolicyByKey(key) {

         var policyDropdownList = getPolicyDropdownList();

         return _.filter(policyDropdownList, function(item) {
            return item.key === key;
         })[0];
      }

      return {
         getPolicyDropdownList: getPolicyDropdownList,
         createTimeConfigSpec: createTimeConfigSpec,
         initData: initData,
         initService: initService
      };
   }
})();
