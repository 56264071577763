/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace h5_vm {

   import GuestOsDescriptor = com.vmware.vim.binding.vim.vm.GuestOsDescriptor;
   import VirtualNVDIMM = com.vmware.vim.binding.vim.vm.device.VirtualNVDIMM;
   import VmConfigContext = com.vmware.vsphere.client.vm.config.VmConfigContext;
   import PmemUtilService = h5_vm.PmemUtilService;
   import PmemCapacityService = h5_vm.PmemCapacityService;
   import VmWorkflowMode = h5_vm.VmWorkflowMode;

   export class VirtualNvdimmService {
      public static readonly POWERED_OFF_STATE: string = "poweredOff";

      public readonly NVDIMM_REMOVED: string = "nvdimmRemoved";
      public readonly MB_UNIT_LOCALIZED: string = this.i18nService.getString("VmUi", "Vm.Units.MB");
      public readonly GB_UNIT_LOCALIZED: string = this.i18nService.getString("VmUi", "Vm.Units.GB");

      static $inject: string[] = [
            "pmemUtilService",
            "pmemCapacityService",
            "i18nService"
      ];

      constructor(private pmemUtilService: PmemUtilService,
            private pmemCapacityService: PmemCapacityService,
            private i18nService: any) {
      }

      getGrowthGranularityInMB(vmConfigContext: VmConfigContext,
            guestOs: GuestOsDescriptor, vmWorkflow: VmWorkflowMode): number {
         let result: number;

         if (!this.isUpdateWorkflow(vmWorkflow)
               || this.vmIsPoweredOff(vmConfigContext)) {
            result = guestOs.persistentMemoryColdGrowthGranularityMB;
         } else {
            result = guestOs.persistentMemoryHotGrowthGranularityMB;
         }

         if (isNaN(result) || result === 0) {
            result = 1;
         }
         return result;
      }

      isGrowthSupported(vmConfigContext: VmConfigContext,
            guestOs: GuestOsDescriptor, vmWorkflow: VmWorkflowMode): boolean {
         let result: boolean;
         if (this.isCloneWorkflow(vmWorkflow)) {
            return true;
         }
         if (!this.isUpdateWorkflow(vmWorkflow)
               || this.vmIsPoweredOff(vmConfigContext)) {
            result = guestOs.persistentMemoryColdGrowthSupported;
         } else {
            result = guestOs.persistentMemoryHotGrowthSupported;
         }
         return result;
      }

      isRemoveDisabled(vmConfigContext: VmConfigContext,
            guestOs: GuestOsDescriptor, vmWorkflow: VmWorkflowMode): boolean {
         if (!this.isUpdateWorkflow(vmWorkflow)
               || this.vmIsPoweredOff(vmConfigContext)) {
            return false;
         } else {
            return !guestOs.persistentMemoryHotRemoveSupported;
         }
      }

      isControllerRemoveDisabled(vmConfigContext: VmConfigContext,
            virtualMachineDevices: any, vmWorkflow: VmWorkflowMode): boolean {
         if (!this.isUpdateWorkflow(vmWorkflow)
               || this.vmIsPoweredOff(vmConfigContext)) {
            return !this.areAllNvdimmsMarkedForRemoval(virtualMachineDevices);
         } else {
            let controllerOption: any = this.pmemUtilService.findOption(
                  vmConfigContext.environment, PmemUtilService.NVDIMM_CONTROLLER);
            let hotRemoveSupported: boolean = controllerOption.hotRemoveSupported;
            return !(hotRemoveSupported
                  || !this.areAllNvdimmsMarkedForRemoval(virtualMachineDevices));
         }
      }

      getMinimumAllowedCapacity(vmConfigContext: VmConfigContext,
            guestOs: GuestOsDescriptor,
            device: any /* inflatedDevice */): number {
         let nvdimmOption: any = this.pmemUtilService.findOption(
               vmConfigContext.environment, PmemUtilService.NVDIMM);
         let guestOsMin: number = guestOs.supportedMinPersistentMemoryMB;
         let newDeviceMinMemMB: number =
               Math.max(nvdimmOption.capacityInMB.min, guestOsMin);
         if (device.isNew()) {
            return newDeviceMinMemMB;
         } else {
            return device.getCurrentDevice().capacityInMB;
         }
      }

      getMaximumAllowedCapacity(vmConfigContext: VmConfigContext,
            guestOs: GuestOsDescriptor,
            device: any,
            virtualMachineDevices: any): number {
         return this.pmemCapacityService.getMaxCapacityForNvdimm(vmConfigContext,
               guestOs, device, virtualMachineDevices);
      }

      getIsNvmAndRamExceedingGuestOsLimits(vmConfigContext: VmConfigContext,
            guestOs: GuestOsDescriptor,
            virtualMachineDevices: any): boolean {
         return this.pmemCapacityService.getIsNvmAndRamExceedingGuestOsLimits(
               vmConfigContext, guestOs, virtualMachineDevices);
      }

      getDefaultMemoryUnit(rawNvdimmDevice: VirtualNVDIMM): string {
         if (!rawNvdimmDevice) {
            return this.GB_UNIT_LOCALIZED;
         }
         return rawNvdimmDevice.capacityInMB < 1024
               ? this.MB_UNIT_LOCALIZED : this.GB_UNIT_LOCALIZED;
      }

      private areAllNvdimmsMarkedForRemoval(virtualMachineDevices: any): boolean {
         let countOfRemovedNvdimms: number = 0;
         let countOfAllNvdimms: number = 0;
         let nvdimmArray: Array<any> =
               virtualMachineDevices.devicesOfType(PmemUtilService.NVDIMM);
         for (let nvdimm of nvdimmArray) {
            countOfAllNvdimms++;
            if (nvdimm.isMarkedForRemoval()) {
               countOfRemovedNvdimms++;
            }
         }
         return countOfRemovedNvdimms === countOfAllNvdimms;
      }

      private vmIsPoweredOff(vmConfigContext: VmConfigContext) {
         if (!vmConfigContext || !vmConfigContext.rtInfo) {
            return false;
         }
         return vmConfigContext.rtInfo.powerState
               === VirtualNvdimmService.POWERED_OFF_STATE;
      }

      private isUpdateWorkflow(vmWorkflow: VmWorkflowMode): boolean {
         return vmWorkflow === VmWorkflowMode.UpdateMode;
      }

      private isCloneWorkflow(vmWorkflow: VmWorkflowMode): boolean {
         return vmWorkflow === VmWorkflowMode.CloneMode;
      }

   }

   angular.module("com.vmware.vsphere.client.vm")
         .service("virtualNvdimmService", VirtualNvdimmService);
}
