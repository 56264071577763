namespace h5_vm {
   declare const ipaddr: any;
   import AdapterMapping = com.vmware.vim.binding.vim.vm.customization.AdapterMapping;
   import DhcpIpGenerator = com.vmware.vim.binding.vim.vm.customization.DhcpIpGenerator;
   import UnknownIpGenerator = com.vmware.vim.binding.vim.vm.customization.UnknownIpGenerator;
   import CustomIpGenerator = com.vmware.vim.binding.vim.vm.customization.CustomIpGenerator;
   import FixedIp = com.vmware.vim.binding.vim.vm.customization.FixedIp;

   class EditIpv4TabController {
      public static $inject = ["i18nService", "gosSpecNetworkService", "ipParserService"];

      private readonly ADAPTER_TYPE_DHCP = GosCustomizationHelperService.ADAPTER_TYPE_DHCP;
      private readonly ADAPTER_TYPE_UNKNOWUN_IP = GosCustomizationHelperService.ADAPTER_TYPE_UNKNOWUN_IP;
      private readonly ADAPTER_TYPE_CUSTOM_IP = GosCustomizationHelperService.ADAPTER_TYPE_CUSTOM_IP;
      private readonly ADAPTER_TYPE_FIXED_IP = GosCustomizationHelperService.ADAPTER_TYPE_FIXED_IP;

      private readonly FIXED_IP_ADDRESS = "fixedIpAddress";
      private readonly SUBNET_MASK = "subnetMask";
      private readonly DEFAULT_GATEWAY = "defaultGateway";
      private readonly ALTERNATE_GATEWAY = "alternateGateway";
      private readonly TITLE_LOCALE = "customizationSpec.networkPage.properties.ipv4.pageTitle";

      public adapterWrapper: AdapterMappingWrapper;
      public adapterMapping: AdapterMapping;
      public isNameIpGeneratorDefined: boolean;
      public i18n: (bundle: string, key: string, ...args: (string | number)[]) => string;
      public ipv4Type: string;
      public subnetAndGatewayDisabled: boolean;
      public argument: string;
      public ipAddress: string;
      public subnetMask: string;
      public defaultGateway: string;
      public alternateGateway: string;
      public updateDnsTab: any;
      public submitErrors: string[];

      public validationErrors: { [key: string]: string } = {};
      public editIpv4: () => boolean;

      constructor(private i18nService: any, private gosSpecNetworkService: GosSpecNetworkService,
            private ipParser: any) {
         this.i18n = this.i18nService.getString;
      }

      public $onInit(): void {
         this.adapterMapping = this.adapterWrapper.adapterMapping;
         this.ipv4Type = this.adapterMapping.adapter.ip._type;
         this.populateData();
         this.setSubnetAndGatewayDisabled();
         this.editIpv4 = (): boolean => {
            return this.preSubmit();
         };
      }

      public setIPv4Generator(): void {

         switch (this.ipv4Type) {

            case GosCustomizationHelperService.ADAPTER_TYPE_DHCP:
               this.adapterMapping.adapter.ip = new DhcpIpGenerator();
               break;

            case GosCustomizationHelperService.ADAPTER_TYPE_UNKNOWUN_IP:
               this.adapterMapping.adapter.ip = new UnknownIpGenerator();
               break;

            case GosCustomizationHelperService.ADAPTER_TYPE_CUSTOM_IP:
               let customIpGenerator: CustomIpGenerator = new CustomIpGenerator();
               customIpGenerator.argument = "";
               this.adapterMapping.adapter.ip = customIpGenerator;
               break;

            case GosCustomizationHelperService.ADAPTER_TYPE_FIXED_IP:
               let fixedIp: FixedIp = new FixedIp();
               fixedIp.ipAddress = "";
               this.adapterMapping.adapter.ip = fixedIp;
               break;
         }

         if (this.ipv4Type !== GosCustomizationHelperService.ADAPTER_TYPE_FIXED_IP) {
            this.clearFixedValidations();
         }

         if (this.ipv4Type !== GosCustomizationHelperService.ADAPTER_TYPE_UNKNOWUN_IP
               && this.ipv4Type !== GosCustomizationHelperService.ADAPTER_TYPE_CUSTOM_IP) {
            this.clearGeneralValidations();
         }

         this.setSubnetAndGatewayDisabled();
         this.setValidationErrors(this.preSubmit());
         if (this.updateDnsTab && typeof this.updateDnsTab === "function") {
            this.updateDnsTab();
         }
      }

      public validateIPv4(ip: string, type: string) {

         switch (type) {
            case this.FIXED_IP_ADDRESS:
               this.validationErrors["ipv4Error"] = this.validateIPv4Internal(ip, true);
               break;
            case this.SUBNET_MASK:
               let required: boolean = this.ipv4Type === GosCustomizationHelperService.ADAPTER_TYPE_FIXED_IP;
               this.validationErrors["subnetMaskError"] = this.validateIPv4Internal(ip, required, "mask");
               break;
            case this.DEFAULT_GATEWAY:
               this.validationErrors["defaultGatewayError"] = this.validateIPv4Internal(ip, false);
               break;
            case this.ALTERNATE_GATEWAY:
               this.validationErrors["alternateGatewayError"] = this.validateIPv4Internal(ip, false);
               break;
         }
         this.setValidationErrors(this.preSubmit());
      }

      private setValidationErrors(isValid: boolean) {
         if (!isValid && !_.contains(this.submitErrors, this.TITLE_LOCALE)) {
            this.submitErrors.push(this.TITLE_LOCALE);
         } else if (isValid) {
            this.submitErrors = _.without(this.submitErrors, this.TITLE_LOCALE);
         }

      }

      private clearFixedValidations() {
         this.validationErrors["ipv4Error"] = "";
         this.clearGeneralValidations();
      }

      private clearGeneralValidations() {
         this.validationErrors["subnetMaskError"] = "";
         this.validationErrors["defaultGatewayError"] = "";
         this.validationErrors["alternateGatewayError"] = "";
      }

      private showFixedValidations(): boolean {
         this.validationErrors["ipv4Error"] = this.validateIPv4Internal(this.ipAddress, true);

         return this.showGeneralValidations() && !this.validationErrors["ipv4Error"];
      }

      private showGeneralValidations(): boolean {
         let required: boolean = this.ipv4Type === GosCustomizationHelperService.ADAPTER_TYPE_FIXED_IP;
         this.validationErrors["subnetMaskError"] = this.validateIPv4Internal(this.subnetMask, required, "mask");
         this.validationErrors["defaultGatewayError"] = this.validateIPv4Internal(this.defaultGateway, false);
         this.validationErrors["alternateGatewayError"] = this.validateIPv4Internal(this.alternateGateway, false);

         let valid: boolean = _.all(this.validationErrors, (validationError: any) => {
            return !validationError;
         });

         return valid;
      }

      public validateIPv4Internal(ip: string, required: boolean, field?: string): string {
         let locale: string = "customizationSpec.networkPage.properties.ipv4.error.";
         locale += (field !== undefined) ? field : "address";
         if (!ip) {
            return required ? this.i18n("VmUi", locale) : "";
         }
         if (!this.ipParser.isIpv4AddressValid(ip)) {
            return this.i18n("VmUi", locale);
         }
         if (field === "mask" && !this.ipParser.isSubnetMaskValid(ip)) {
            return this.i18n("VmUi", locale);
         }
         return "";
      }

      public preSubmit(): boolean {
         let isValid: boolean = true;
         if (this.ipv4Type === GosCustomizationHelperService.ADAPTER_TYPE_FIXED_IP) {
            isValid = this.showFixedValidations();
         }

         if (this.ipv4Type === GosCustomizationHelperService.ADAPTER_TYPE_UNKNOWUN_IP
               || this.ipv4Type === GosCustomizationHelperService.ADAPTER_TYPE_CUSTOM_IP) {
            isValid = this.showGeneralValidations();
         }

         if (!isValid) {
            return false;
         }
         this.updateData();
         return true;
      }

      /**
       * Set the data from the input fields into the adapter
       */
      private updateData() {
         switch (this.ipv4Type) {
            case GosCustomizationHelperService.ADAPTER_TYPE_DHCP:
               this.adapterMapping.adapter.subnetMask = "";
               this.adapterMapping.adapter.gateway = [];
               break;

            case GosCustomizationHelperService.ADAPTER_TYPE_CUSTOM_IP:
               (this.adapterMapping.adapter.ip as CustomIpGenerator).argument = this.argument;
               this.updateSubnetAndGatewayData();
               break;

            case GosCustomizationHelperService.ADAPTER_TYPE_UNKNOWUN_IP:
               this.updateSubnetAndGatewayData();
               break;

            case GosCustomizationHelperService.ADAPTER_TYPE_FIXED_IP:
               // convert the ip into readable valid decimal value
               this.ipAddress = ipaddr.parse(this.ipAddress).toString();
               (this.adapterMapping.adapter.ip as FixedIp).ipAddress = this.ipAddress;
               this.updateSubnetAndGatewayData();
         }

         this.adapterWrapper.ipV4Address = this.gosSpecNetworkService.getIPv4NicSettingSummary(
               this.adapterMapping.adapter.ip);
      }

      private populateData() {
         switch (this.ipv4Type) {
            case GosCustomizationHelperService.ADAPTER_TYPE_UNKNOWUN_IP:
               this.populateSubnetAndGatewayData();
               break;
            case GosCustomizationHelperService.ADAPTER_TYPE_CUSTOM_IP:
               this.argument = (this.adapterMapping.adapter.ip as CustomIpGenerator).argument;
               this.populateSubnetAndGatewayData();
               break;

            case GosCustomizationHelperService.ADAPTER_TYPE_FIXED_IP:
               this.ipAddress = (this.adapterMapping.adapter.ip as FixedIp).ipAddress;

               this.populateSubnetAndGatewayData();
               break;
         }
      }

      private updateSubnetAndGatewayData() {
         if (this.subnetMask) {
            this.subnetMask = ipaddr.parse(this.subnetMask).toString();
         }
         this.adapterMapping.adapter.subnetMask = this.subnetMask;
         let gateways: string[] = [];
         if (this.defaultGateway) {
            this.defaultGateway = ipaddr.parse(this.defaultGateway).toString();
            gateways.push(this.defaultGateway);
         }
         if (this.alternateGateway) {
            this.alternateGateway = ipaddr.parse(this.alternateGateway).toString();
            gateways.push(this.alternateGateway);
         }

         this.adapterMapping.adapter.gateway = gateways;
      }

      private populateSubnetAndGatewayData() {
         if (this.adapterMapping.adapter.subnetMask) {
            this.subnetMask = this.adapterMapping.adapter.subnetMask;
         }

         let gateways: string[] = this.adapterMapping.adapter.gateway;
         if (gateways && gateways.length > 0) {
            if (gateways[0]) {
               this.defaultGateway = gateways[0];
            }

            if (gateways[1]) {
               this.alternateGateway = gateways[1];
            }
         }
      }

      private setSubnetAndGatewayDisabled(): void {
         this.subnetAndGatewayDisabled = this.ipv4Type === GosCustomizationHelperService.ADAPTER_TYPE_DHCP;
      }
   }

   export class EditIpv4TabComponent {
      public controller: any;
      public templateUrl: string;
      public bindings: any;

      constructor() {
         this.bindings = {
            adapterWrapper: "<",
            isNameIpGeneratorDefined: "<",
            editIpv4: "=",
            updateDnsTab: "=",
            submitErrors: "=",
         };
         this.controller = EditIpv4TabController;
         this.templateUrl = "vm-ui/resources/vm/guest-os-customization/pages/network/edit/ipv4-tab/edit-ipv4-tab.component.html";
      }
   }

   angular.module("com.vmware.vsphere.client.vm")
         .component("editIpv4Tab", new EditIpv4TabComponent());
}
