namespace h5_vm {
   import ManagedObjectReference = com.vmware.vim.binding.vmodl.ManagedObjectReference;
   import CustomizationSpecInfo = com.vmware.vim.binding.vim.CustomizationSpecInfo;
   import Specification = com.vmware.vim.binding.vim.vm.customization.Specification;
   import ParamSpec = com.vmware.vise.mvc.model.data.ParamSpec;
   import ActionEvaluation = com.vmware.vise.actionsfw.ActionEvaluation;

   export class CustomizationSpecInfoCustom extends CustomizationSpecInfo {
      vcService: VcInfo;
      vCenterName: string;
   }

   class ActionBarContext {
      specInfo?: CustomizationSpecInfoCustom;
      specDetails?: Specification;
      specXmlText?: string;

      editActionEval?: ActionEvaluation;

      editPrivsGranted: boolean;
      deletePrivsGranted: boolean;
      exportPrivsGranted: boolean;
      contextActionsDisabled: boolean;

      reset(): void {
         this.editActionEval = undefined;

         this.editPrivsGranted = true;
         this.deletePrivsGranted = true;
         this.exportPrivsGranted = true;
         this.contextActionsDisabled = false;
      }

      isEditSpecActionAvailable(actionEval: any): boolean {
         this.editActionEval = actionEval;
         return actionEval.available && this.editPrivsGranted
               && !!this.specInfo && !!this.specDetails;
      }

      isExportSpecActionAvailable(actionDef: any): boolean {
         return actionDef.available && this.exportPrivsGranted
               && !!this.specDetails && !!this.specXmlText
               && !_.isEmpty(this.specXmlText);
      }

      isDeleteSpecActionAvailable(actionDef: any): boolean {
         return actionDef.available && this.deletePrivsGranted
               && !!this.specInfo && !!this.specDetails;
      }

      isDuplicateSpecActionAvailable(actionDef: any): boolean {
         return actionDef.available && !!this.specInfo && !!this.specDetails;
      }
   }

   export class GosCustomizationListService {
      public static $inject: string[] = [
         "$q",
         "dataService",
         "defaultUriSchemeUtil",
         "datagridActionBarService",
         "authorizationService",
         "vuiConstants",
         "i18nService",
         "actionsService"];

      private readonly INFO_PROPERTY: string = "info";
      private readonly CUSTOMIZATION_SPEC_PROPERTY: string = "customizationSpec";
      private readonly XML_TEXT_PROPERTY: string = "specItemXMLText";

      private readonly MODIFY_SPEC_PRIV = "VirtualMachine.Provisioning.ModifyCustSpecs";
      private readonly READ_SPEC_PRIV = "VirtualMachine.Provisioning.ReadCustSpecs";

      private readonly ACTIONS = {
         NEW: {
            uid: "vsphere.core.vm.gos.customization.new",
            privs: []
         },
         IMPORT: {
            uid: "vsphere.core.vm.gos.customization.import",
            privs: []
         },
         EDIT: {
            uid: "vsphere.core.vm.gos.customization.edit",
            privs: [this.MODIFY_SPEC_PRIV, this.READ_SPEC_PRIV]
         },
         DUPLICATE: {
            uid: "vsphere.core.vm.gos.customization.duplicate",
            privs: []
         },
         EXPORT: {
            uid: "vsphere.core.vm.gos.customization.export",
            privs: [this.READ_SPEC_PRIV]
         },
         DELETE: {
            uid: "vsphere.core.vm.gos.customization.delete",
            privs: [this.MODIFY_SPEC_PRIV]
         }
      };
      private readonly ALL_ACTIONS_PRIVS = _.union(
            this.ACTIONS.NEW.privs, this.ACTIONS.IMPORT.privs,
            this.ACTIONS.EDIT.privs, this.ACTIONS.DELETE.privs,
            this.ACTIONS.DUPLICATE.privs, this.ACTIONS.EXPORT.privs);
      private readonly VC_NO_ERROR: string = "noError";

      private actionsContext: ActionBarContext = new ActionBarContext();

      constructor(private $q: any,
            private dataService: any,
            private defaultUriSchemeUtil: any,
            private datagridActionBarService: any,
            private authorizationService: any,
            private vuiConstants: any,
            private i18nService: any,
            private actionsService: any) {
      }

      /**
       * Retrieves the spec info property for the provided vc Services
       * Formats the retrieved timestamp in a user friendly way
       * Adds the VC name to the retrieved data
       * Sorts the data alphabetically
       * @param vcServices
       * @returns {PromiseLike<CustomizationSpecInfoCustom[]>}
       */
      public retrieveSpecInfos(vcServices: VcInfo[]): IPromise<CustomizationSpecInfoCustom[]> {
         const customizationSpecMgrs: string[] = [];
         const customizationSpecMgrService: {[moid: string]: any} = {};

         _.each(vcServices, (vcService: VcInfo) => {
            if (vcService && vcService.content && vcService.content.customizationSpecManager) {
               const customizationSpecManager: string =
                     this.defaultUriSchemeUtil.getVsphereObjectId(
                           vcService.content.customizationSpecManager);
               // add the VC's customization manager to the array of objects
               // for which the info property will be retrieved
               customizationSpecMgrs.push(customizationSpecManager);

               // set the VC name to the customization manager to be added to
               // the returned info data for each manager
               customizationSpecMgrService[customizationSpecManager] = vcService;
            }
         });

         if (customizationSpecMgrs.length > 0) {
            return this.dataService.getPropertiesForObjects(customizationSpecMgrs, [this.INFO_PROPERTY])
                  .then((properties: any[]) => {
                     let specs: CustomizationSpecInfoCustom[] = [];
                     // retrieve the data for each customization manager
                     _.each(properties, (data: any, specManagerId: string) => {
                        let vcSpecs: CustomizationSpecInfoCustom[] = data.info;
                        const vcService: VcInfo = customizationSpecMgrService[specManagerId];
                        // format the data for each spec
                        vcSpecs = this.formatSpecsData(vcSpecs, vcService);
                        specs = _.union(specs, vcSpecs);
                     });
                     return _.sortBy(specs, (spec) => {
                        return spec.name.toLowerCase();
                     });
                  });
         }

         return this.$q.when([]);
      }

      public filterVcs(vcServices: VcInfo[]): IPromise<VcInfo[]> {
         const rootFolderIds: string[] = [];
         const promises: {[rootFolder: string]: IPromise<any>} = {};
         const filteredVcs: VcInfo[] = [];
         _.each(vcServices, (vcService: VcInfo) => {
            if (vcService && vcService.errorCode === this.VC_NO_ERROR && vcService.serviceGuid) {
               const rootFolderId: string = this.defaultUriSchemeUtil
                     .getRootFolder(vcService.serviceGuid);
               promises[rootFolderId] = this.authorizationService
                     .checkGrantedPrivilege(rootFolderId, this.READ_SPEC_PRIV);
               rootFolderIds.push(rootFolderId);

            }
         });
         return this.$q.all(promises).then((results: any): VcInfo[] => {
            _.each(vcServices, (vcService: VcInfo): void => {
               if (vcService && vcService.serviceGuid) {
                  const rootFolderId: string = this.defaultUriSchemeUtil
                        .getRootFolder(vcService.serviceGuid);
                  if (results[rootFolderId]) {
                     filteredVcs.push(vcService);
                  }
               }
            });

            return filteredVcs;
         });
      }

      /**
       * Formats the retrieved timestamp in a user friendly way
       * Adds the VC name to the retrieved data
       *
       * @param vcSpecs
       * @param vCenterName
       * @returns {CustomizationSpecInfoCustom[]}
       */
      private formatSpecsData(vcSpecs: CustomizationSpecInfoCustom[],
            vcService: VcInfo): CustomizationSpecInfoCustom[] {
         _.each(vcSpecs, (spec: CustomizationSpecInfoCustom) => {
            spec.vcService = vcService;
            spec.vCenterName = vcService.name;
         });
         return vcSpecs;
      }

      /**
       * Retrieves the spec details for a customization
       * @param vcService
       * @param customizationName
       * @returns {IPromise<Specification>}
       */
      public retrieveSpecs(vcService: VcInfo, customizationName: string): IPromise<Specification> {
         const customizationSpecMgr: ManagedObjectReference = vcService.content.customizationSpecManager;
         const customizationSpecManager: string =
               this.defaultUriSchemeUtil.getVsphereObjectId(customizationSpecMgr);

         const paramCustomizationSpec: ParamSpec = new ParamSpec();
         paramCustomizationSpec.propertyName = this.CUSTOMIZATION_SPEC_PROPERTY;
         paramCustomizationSpec.parameterType = "java.lang.String";
         paramCustomizationSpec.parameter = customizationName;

         const paramXmlText: ParamSpec = new ParamSpec();
         paramXmlText.propertyName = this.XML_TEXT_PROPERTY;
         paramXmlText.parameterType = "java.lang.String";
         paramXmlText.parameter = customizationName;

         return this.dataService.getProperties(customizationSpecManager,
               [this.CUSTOMIZATION_SPEC_PROPERTY, this.XML_TEXT_PROPERTY],
               {propertyParams: [paramCustomizationSpec, paramXmlText]});
      }

      public invokeEdit(specInfo?: CustomizationSpecInfoCustom, specDetails?: any,
            allSpecs?: CustomizationSpecInfoCustom[], onActionInvoked?: Function): void {
         if (!specInfo || !specDetails || !onActionInvoked) {
            return;
         }

         const actionEval = this.actionsContext.editActionEval;
         if (!actionEval) {
            return;
         }

         const editAvailable = actionEval.available && this.actionsContext.editPrivsGranted;
         if (!editAvailable) {
            return;
         }

         const editActionContext: any = {
            gosCustomizationSpecInfo: specInfo,
            gosCustomizationSpecDetails: specDetails,
            gosCustomizationSpecs: allSpecs,
            onActionInvoked: onActionInvoked
         };

         this.actionsService.invokeAction(actionEval, editActionContext);
      }

      public updateActionBar(specsGridOptions: any,
            specInfo?: CustomizationSpecInfoCustom,
            specDetails?: Specification,
            specXmlText?: string,
            onActionInvoked?: Function,
            beforeActionInvoked?: Function,
            contextActionsDisabled: boolean = false): IPromise<void> {
         this.actionsContext.specInfo = specInfo;
         this.actionsContext.specDetails = specDetails;
         this.actionsContext.specXmlText = specXmlText;
         this.actionsContext.reset();
         this.actionsContext.contextActionsDisabled = contextActionsDisabled;

         let allActionsPrivCheckPromise: IPromise<Array<boolean>>;
         if (!this.actionsContext.contextActionsDisabled && this.actionsContext.specInfo) {
            const vcServerGuid: string|undefined = this.actionsContext.specInfo.vcService.serviceGuid;
            const vcRefId: string = this.defaultUriSchemeUtil.getRootFolder(vcServerGuid);
            allActionsPrivCheckPromise =
                  this.authorizationService.checkPrivileges(vcRefId, this.ALL_ACTIONS_PRIVS);
         } else {
            allActionsPrivCheckPromise = this.$q.resolve([]);
         }

         return allActionsPrivCheckPromise.then((allPrivStatuses: Array<boolean>) => {
            // Go through all the privilege statuses map them to the actions
            if (allPrivStatuses && allPrivStatuses.length > 0) {
               _.each(allPrivStatuses, (status: boolean, index: number) => {
                  const currPrivilege = this.ALL_ACTIONS_PRIVS[index];

                  if (_.contains(this.ACTIONS.EDIT.privs, currPrivilege)) {
                     this.actionsContext.editPrivsGranted =
                           this.actionsContext.editPrivsGranted && status;
                  }

                  if (_.contains(this.ACTIONS.EXPORT.privs, currPrivilege)) {
                     this.actionsContext.exportPrivsGranted =
                           this.actionsContext.exportPrivsGranted && status;
                  }

                  if (_.contains(this.ACTIONS.DELETE.privs, currPrivilege)) {
                     this.actionsContext.deletePrivsGranted =
                           this.actionsContext.deletePrivsGranted && status;
                  }
               });
            }

            let actionSpecs: any[];
            actionSpecs = [
               {
                  actionId: this.ACTIONS.NEW.uid,
                  isActionAvailable: (actionEval: any) => {
                     return actionEval.available;
                  },
                  getActionInvocationContext: () => {
                     return {
                        gosCustomizationSpecs: specsGridOptions.data,
                        onActionInvoked: onActionInvoked
                     };
                  }
               },
               {
                  actionId: this.ACTIONS.IMPORT.uid,
                  isActionAvailable: (actionEval: any) => {
                     return actionEval.available;
                  },
                  getActionInvocationContext: () => {
                     return {
                        gosCustomizationSpecs: specsGridOptions.data,
                        onActionInvoked: onActionInvoked
                     };
                  }
               },
               this.vuiConstants.actions.SEPARATOR,
               {
                  actionId: this.ACTIONS.EDIT.uid,
                  isActionAvailable: (actionEval: any) => {
                     return !this.actionsContext.contextActionsDisabled &&
                           this.actionsContext.isEditSpecActionAvailable(actionEval);
                  },
                  getActionInvocationContext: () => {
                     return {
                        gosCustomizationSpecInfo: this.actionsContext.specInfo,
                        gosCustomizationSpecDetails: this.actionsContext.specDetails,
                        gosCustomizationSpecs: specsGridOptions.data,
                        onActionInvoked: onActionInvoked
                     };
                  }
               },
               {
                  actionId: this.ACTIONS.DUPLICATE.uid,
                  isActionAvailable: (actionEval: any) => {
                     return !this.actionsContext.contextActionsDisabled &&
                           this.actionsContext.isDuplicateSpecActionAvailable(actionEval);
                  },
                  getActionInvocationContext: () => {
                     return {
                        gosCustomizationSpecInfo: this.actionsContext.specInfo,
                        gosCustomizationSpecs: specsGridOptions.data,
                        onActionInvoked: onActionInvoked
                     };
                  }
               },
               {
                  actionId: this.ACTIONS.EXPORT.uid,
                  isActionAvailable: (actionDef: any) => {
                     return !this.actionsContext.contextActionsDisabled &&
                           this.actionsContext.isExportSpecActionAvailable(actionDef);
                  },
                  getActionInvocationContext: () => {
                     return {
                        gosCustomizationSpecInfo: specInfo,
                        gosCustomizationSpecXmlText: specXmlText,
                        gosCustomizationSpecDetails: specDetails
                     };
                  }
               },
               {
                  actionId: this.ACTIONS.DELETE.uid,
                  isActionAvailable: (actionEval: any) => {
                     return !this.actionsContext.contextActionsDisabled &&
                           this.actionsContext.isDeleteSpecActionAvailable(actionEval);
                  },
                  onInvokeAction: (actionEval: any) => {
                     if (beforeActionInvoked) {
                        beforeActionInvoked();
                     }
                     let text = actionEval.action.confirmationText;
                     if (this.actionsContext.specInfo && text) {
                        const specName = this.actionsContext.specInfo.name;
                        text = this.i18nService.interpolate(text, [specName]);
                        actionEval.action.confirmationText = text;
                     }
                  },
                  getActionInvocationContext: () => {
                     return {
                        gosCustomizationSpecInfo: this.actionsContext.specInfo,
                        onActionInvoked: onActionInvoked
                     };
                  }
               }];

            specsGridOptions.actionsOptions = specsGridOptions.actionsOptions || {};
            return this.datagridActionBarService.updateActionBar(specsGridOptions, [], actionSpecs);
         });
      }
   }

   angular.module("com.vmware.vsphere.client.vm")
         .service("gosCustomizationListService", GosCustomizationListService);
}
