/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace dvs_ui {

   export class DvsPortEditDialogConstants {

      public static readonly EDIT_OPERATION: string = "edit";

      public static readonly DATA_DVS_PROPERTY: string = "dvsports:portEditData";

      public static readonly DATA_DVS_VLAN_TRUNK_PROPERTY: string = "dvsport:vlanTrunkRanges";

      public static readonly DATA_DVPG_PROPERTY: string = "dvportgroupport:portEditData";

      public static readonly POLICY_ALLOW_PROMISCUOUS: string = "allowPromiscuous";

      public static readonly POLICY_MAC_CHANGES: string = "macChanges";

      public static readonly POLICY_FORGET_TRANSMITS: string = "forgedTransmits";

      public static readonly NAME_MAX_BYTE_LENGTH: number = 80;

      public static readonly DESCRIPTION_MAX_CHAR_LENGTH: number = 255;
   }
}