namespace storage_ui {

   export interface RenameFileSpec {
      sourcePath: string;
      destinationName: string;
   }

   export class RenameFileService {
      public static $inject = ["i18nService", "mutationService", "datastoreConstants",
         "defaultUriSchemeUtil", "clarityModalService", "vxValidatorFactory", "storageValidationService"];

      constructor(private i18nService: any,
                  private mutationService: any,
                  private datastoreConstants: any,
                  private defaultUriSchemeUtil: any,
                  private clarityModalService: any,
                  private vxValidatorFactory: any,
                  private storageValidationService: StorageValidationService) {
      }

      public openRenameFileDialog(dsRef: any, spec: RenameFileSpec, callback: Function): void {
         let validator: any = this.vxValidatorFactory.create();
         let modalOptions: any = {
            contentTemplate: 'storage-ui/resources/storage/views/manage/files/RenameFileView.html',
            title: this.i18nService.getString('StorageUi', 'renameFile.title'),
            subTitle: {
               objectId: dsRef
            },
            defaultButton: 'submit',
            dialogData: {
               spec: spec,
               getStorageString: this.i18nService.getString.bind(this, 'StorageUi'),
               validator: validator,
               isValidName: this.storageValidationService.validateDatastoreBrowserItemName
                     .bind(this.storageValidationService)
            },
            alerts: [],
            onSubmit: () => {
               let errors: string[] = validator.validate();
               if (errors && errors.length) {
                  return false;
               }
               this.mutationService.apply(
                     dsRef,
                     "com.vmware.vsphere.client.storage.DatastoreFileRenameSpec",
                     spec).then( function () {
                  if (callback)  {
                     callback();
                  }
               });

               return true;
            }
         };

         this.clarityModalService.openOkCancelModal(modalOptions);
      }
   }

   angular.module("com.vmware.vsphere.client.storage")
         .service("renameFileService", RenameFileService);
}
