/* Copyright 2018 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace dvs_ui {

   export class VmkDetailsDialogPropertiesViewComponent {

      public bindings: any;
      public controller: any;
      public template: string;

      constructor() {
         this.bindings = {
            data: "<"
         };

         this.controller = VmkDetailsDialogPropertiesViewComponentController;

         this.template = `<vx-property-view class="network-ui-fixed-size-vx-property-view"
                                            data="$ctrl.propertyViewData"/>`;
      }
   }

   class VmkDetailsDialogPropertiesViewComponentController {

      static $inject = [
         "networkDetailsViewService"
      ];

      data: any;

      propertyViewData: any;

      constructor(private networkDetailsViewService: any) {}

      $onInit(): void {
         this.propertyViewData =
               this.networkDetailsViewService.buildOnlyProperties(this.data);
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .component("vmkDetailsDialogPropertiesView",
               new VmkDetailsDialogPropertiesViewComponent());
}
