(function(){
   'use strict';

   angular
         .module('com.vmware.vsphere.client.vm')
         .service('VmProvisioningSelectVmToClonePageModel', VmProvisioningSelectVmToClonePageModel);

   VmProvisioningSelectVmToClonePageModel.$inject = [
      '$q',
      'i18nService',
      'defaultUriSchemeUtil',
      'dataService',
      'authorizationService',
      'creationTypeConstants',
      'managedEntityConstants',
      'wizardPageService'
   ];

   function VmProvisioningSelectVmToClonePageModel($q, i18nService, defaultUriSchemeUtil,
         dataService, authorizationService, creationTypeConstants, managedEntityConstants,
         wizardPageService) {

      return function(virtualMachineSpecBuilder, wizardViewData, config) {
         var GUEST_ID_PROPERTY = 'config/guestId';
         var VAPP_PROPERTY_DESCRIPTORS_PROPERTY = "config.vAppConfig.property";
         var CONNECTION_STATE_PROPERTY = 'runtime/connectionState';

         var form = {};
         var hasActionPrivileges;
         var _wizardTitle;

         return {
            form: form,

            validateSelection: function() {
               var target = virtualMachineSpecBuilder.getVmId();
               var selectedType = defaultUriSchemeUtil.getPartsFromVsphereObjectId(target).type;
               var requiredPrivilege =
                  getRequiredPermission(virtualMachineSpecBuilder.getCreationType());

               if (selectedType === managedEntityConstants.VIRTUAL_MACHINE) {
                  return $q.all({
                     properties: dataService.getProperties(target, [CONNECTION_STATE_PROPERTY, GUEST_ID_PROPERTY,
                        VAPP_PROPERTY_DESCRIPTORS_PROPERTY]),
                     privileges: authorizationService.checkPrivileges(target,
                        [requiredPrivilege])
                  }).then(function(result) {
                     // Connection state
                     wizardViewData.setVmConnectedState(result.properties[CONNECTION_STATE_PROPERTY]);

                     virtualMachineSpecBuilder.setGuestOsId(result.properties[GUEST_ID_PROPERTY]);

                     virtualMachineSpecBuilder.setVappPropertyDescriptors(
                        result.properties[VAPP_PROPERTY_DESCRIPTORS_PROPERTY]);

                     if (result.properties[CONNECTION_STATE_PROPERTY] !== 'connected') {
                        return i18nService.getString('VmUi', 'SelectTemplateProvisioningPage.Vm.InvalidState');
                     }

                     // Privileges
                     return makeCompatibilityMessage(result.privileges);
                  });
               } else {
                  return $q.resolve(null);
               }
            },

            validatePage: function() {
               if (defaultUriSchemeUtil.getPartsFromVsphereObjectId(virtualMachineSpecBuilder.getVmId()).type !== 'VirtualMachine') {
                  return $q.resolve({ error: i18nService.getString('VmUi', 'SelectTemplateProvisioningPage.Vm.SelectValidEntity') });
               }

               if (wizardViewData.getVmConnectedState() !== 'connected') {
                  return $q.resolve({ error: i18nService.getString('VmUi', 'SelectTemplateProvisioningPage.Vm.InvalidState') });
               }

               if (!hasActionPrivileges) {
                  return $q.resolve({ error: createNoPrivilegesErrorString(virtualMachineSpecBuilder.getCreationType()) });
               }
            },

            submitPage: function() {
               //change vm title
               if(!_wizardTitle || config.title.indexOf(_wizardTitle) === -1) {
                  _wizardTitle = config.title;
               }
               config.title = wizardViewData.getSourceObjectName() + ' - ' + _wizardTitle;

               var pages = config.pages;
               var flows = config.flows;

               if (flows && flows.length > 0) {
                  var flow = _.find(flows, function (flow) {
                     return virtualMachineSpecBuilder.getCreationType() === flow.id;
                  }.bind(this));
                  if (flow) {
                     pages = flow.pages;
                  }
               }

               if (virtualMachineSpecBuilder.hasConfigurableVappProperties()) {
                  wizardPageService.markPageDisabled(
                     pages,
                     i18nService.getString(
                        'VmUi', 'VAppPropertiesProvisioningPage.title'));
               } else {
                  wizardPageService.markPageSkipped(
                     pages,
                     i18nService.getString(
                        'VmUi', 'VAppPropertiesProvisioningPage.title'));
               }

               return {
                  wizardFlow: virtualMachineSpecBuilder.getCreationType(),
                  invalidateNextPages: false
               };
            }
         };

         function makeCompatibilityMessage(privilegeStatuses) {
            hasActionPrivileges = true;
            for (var i = 0; i < privilegeStatuses.length; i++) {
               if (!privilegeStatuses[i]) {
                  hasActionPrivileges = false;
                  break;
               }
            }

            if (hasActionPrivileges) {
               return null;
            }

            var messageText = createNoPrivilegesErrorString(virtualMachineSpecBuilder.getCreationType());
            return messageText;
         }

         function createNoPrivilegesErrorString(creationType) {
            var key = 'SelectTemplateProvisioningPage.Vm.NoPermission.' +
               getRequiredPermission(creationType);

            return i18nService.getString('VmUi', key);
         }

         function getRequiredPermission(creationType) {
            var suffix;
            if (creationType === creationTypeConstants.CLONE_VM_TO_TEMPLATE) {
               suffix = 'CreateTemplateFromVM';
            } else if (creationType === creationTypeConstants.CLONE_VM_TO_VM) {
               suffix = 'Clone';
            }

            return 'VirtualMachine.Provisioning.' + suffix;
         }
      };
   }
})();

