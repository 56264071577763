namespace h5_vm {
   import IPromise = angular.IPromise;
   import IQService = angular.IQService;
   import ManagedObjectReference = com.vmware.vim.binding.vmodl.ManagedObjectReference;

   const HARDWARE_DATA = 'com.vmware.vsphere.client.vm.model.HardwareConfigData';
   const VM_STORAGE_PROFILE_ASSIGNMENTS_PROPERTY = 'VirtualMachine/vmStorageProfileAssignments';
   const HARDWARE_DATA_RESULT = 'hardwareDataResult';
   const STORAGE_PROFILES_RESULT = 'storageProfilesResult';
   const VM_STORAGE_PROFILE_ASSIGNMENTS_RESULT = 'vmStorageProfileAssignmentsResult';
   const HAS_ADD_REMOVE_DEVICE_PRIVILEGE_RESULT = 'hasAddRemoveDevicePrivilegeResult';

   export abstract class VmHardwareBaseService {

      static readonly VIRTUAL_ETHERNET_CARD = 'VirtualEthernetCard';

      constructor(
         protected vmHardwareUtil: any,
         protected dataService: any,
         protected vmNetworkService: any,
         protected defaultUriSchemeUtil: any,
         protected storageProfileService: any,
         protected $q: IQService,
         protected vmDeviceInfoService: any,
         protected VirtualMachineDevices: any) {
      }

      getDataPromises(vmId:string): IPromise<{[propertyId: string]: any}> {
         var hardwareDataPromise = this.dataService.getData(vmId, HARDWARE_DATA);
         var promises: {[propertyId: string]: IPromise<any>} = {};
         promises[HARDWARE_DATA_RESULT] = hardwareDataPromise;

         return this.$q.all(promises);
      }

      useData(vmId: string, properties: {[propertyId: string]: any}): any {
         var hardwareData = properties[HARDWARE_DATA_RESULT];
         var storageProfiles = properties[STORAGE_PROFILES_RESULT];
         var configTargets = hardwareData.vmConfigContext.environment.configTarget;
         var availableNetworks = this.vmDeviceInfoService.buildAvailableNetworkList(configTargets);
         var guestOsFamilyKey = hardwareData.guestOsFamilyKey;
         var vmStorageProfileAssignments = properties[VM_STORAGE_PROFILE_ASSIGNMENTS_RESULT];
         if(vmStorageProfileAssignments) {
            if (vmStorageProfileAssignments.hasOwnProperty(VM_STORAGE_PROFILE_ASSIGNMENTS_PROPERTY)) {
               vmStorageProfileAssignments = vmStorageProfileAssignments[VM_STORAGE_PROFILE_ASSIGNMENTS_PROPERTY];
            }
         }
         const hasAddRemoveDevicePrivilege = properties[HAS_ADD_REMOVE_DEVICE_PRIVILEGE_RESULT];
         var result = {
            vmConfigContext: hardwareData.vmConfigContext,
            originalConfig: angular.copy(hardwareData.vmConfigContext.config),
            privileges: {
               hasAddRemoveDevicePrivilege
            },
            availableNetworks,
            storageProfiles,
            guestOsFamilyKey,
            vmStorageProfileAssignments: undefined
         };
         if (vmStorageProfileAssignments !== null) {
            result.vmStorageProfileAssignments = vmStorageProfileAssignments;
         }
         return result;
      }

      getAndFormatData(vmId: string): any {
         var _this = this;
         return this.getDataPromises(vmId).then((properties: {[propertyId: string]: any}) => {
            return _this.useData(vmId, properties);
         });
      }

      getVmNetworkInfo(networkProviderId: string | ManagedObjectReference,
                       vmNetworkDevices: any[]): any {
         return this.vmNetworkService.getVmNetworkInfo(
               networkProviderId,
               vmNetworkDevices
         );
      }

      buildVirtualDevices(hardwareData: any): any {
         var virtualDeviceOptionsByDeviceType = _.indexBy(
            hardwareData.vmConfigContext.environment.configOption.hardwareOptions.virtualDeviceOption,
            (deviceOption: any) => {
               return deviceOption.type.name;
            }
         );

         var virtualMachineDevices = new this.VirtualMachineDevices(
            hardwareData.vmConfigContext.config.hardware.device,
            hardwareData.vmStorageProfileAssignments,
            hardwareData.storageProfiles,
            hardwareData.availableNetworks,
            virtualDeviceOptionsByDeviceType);

         return virtualMachineDevices;
      }

   }

   angular.module('com.vmware.vsphere.client.vm')
      .service('vmHardwareBaseService', VmHardwareBaseService);
}

