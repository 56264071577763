namespace h5_vm {

   export class EntryListController {
      public static $inject = ["$scope",
         "vuiConstants",
         "i18nService"];

      public model: string[];
      public labelKey: string;
      public ariaLabelKey: string;
      public currentText?: string;
      public currentIndex?: number;
      public gridOptions: any;
      public kendoGrid: any;
      public refreshGridData: Function;

      constructor(private $scope: any,
            private vuiConstants: any,
            public i18n: any) {
      }

      $onInit(): void {
         this.gridOptions = this.createDataGridOptions();
         this.refreshGridDataSource();
         this.refreshGridData = () => {
            this.refreshGridDataSource();
         };

         this.$scope.$watch(() => {
            return this.gridOptions.selectedItems;
         }, (newSelection?: Array<any>, oldSelection?: Array<any>) => {
            this.onSelectionChanged.call(this, newSelection, oldSelection);
         });
      }

      $onDestroy(): void {
      }

      public onAddClicked() {
         if (!this.currentText) {
            return;
         }

         this.model.push(this.currentText);
         this.refreshGridDataSource();
      }

      public onDeleteClicked() {
         if (this.currentIndex === undefined) {
            return;
         }

         this.model.splice(this.currentIndex, 1);
         this.refreshGridDataSource();
      }

      public onMoveUpClicked() {
         if (this.currentIndex === undefined || this.currentIndex === 0) {
            return;
         }

         let oldIndex: number = this.currentIndex;
         let newIndex: number = oldIndex - 1;

         this.swapItems(oldIndex, newIndex);

         this.currentIndex = newIndex;
      }

      public onMoveDownClicked() {
         if (this.currentIndex === undefined || this.currentIndex + 1 === this.model.length) {
            return;
         }

         let oldIndex: number = this.currentIndex;
         let newIndex: number = oldIndex + 1;

         this.swapItems(oldIndex, newIndex);

         this.currentIndex = newIndex;
      }

      public getCurrentTextPlaceholder(): string {
         return this.i18n.getString('VmUi', this.labelKey || 'customizationManager.customizationSpecDetailsView.dnsSearchPathPlaceholder');
      }

      public getAriaLabel(): string {
         return this.ariaLabelKey
               ? this.i18n.getString('VmUi', this.ariaLabelKey)
               : "";
      }

      private swapItems(item1Index: number, item2Index: number) {
         // Update the model
         let item1: string = this.model[item1Index];
         this.model.splice(item1Index, 1);
         this.model.splice(item2Index, 0, item1);

         // Update the gird without loosing the selection
         let dataItem: any = this.gridOptions.dataSource.at(item1Index);
         this.gridOptions.dataSource.remove(dataItem);
         this.gridOptions.dataSource.insert(item2Index, dataItem);
      }

      private onSelectionChanged(newSelection?: Array<any>, oldSelection?: Array<any>) {
         if (newSelection === oldSelection || _.isEqual(newSelection, oldSelection)) {
            return;
         }

         if (!newSelection || newSelection.length === 0 || newSelection.length > 1) {
            this.currentIndex = undefined;
            this.currentText = undefined;
         } else {
            this.currentIndex = this.gridOptions.dataSource.indexOf(newSelection[0]);
            this.currentText = newSelection[0].name;
         }
      }

      private refreshGridDataSource() {
         this.currentIndex = undefined;
         this.currentText = undefined;

         this.gridOptions.data = _.map(this.model, (itemText: string, index: number): any => {
            return {name: itemText, order: index};
         });
      }

      private createDataGridOptions(): any {
         return {
            selectionMode: this.vuiConstants.grid.selectionMode.SINGLE,
            searchable: false,
            resizable: false,
            reorderable: false,
            selectedItems: [],
            columnDefs: [
               {
                  field: "name",
                  sortable: false,
                  searchable: false,
               }
            ],
            data: [],
            height: "100%",
         };
      }
   }

   export class EntryListComponent implements angular.IComponentOptions {
      public controller: any;
      public templateUrl: string;
      public bindings: any;

      constructor() {
         this.controller = EntryListController;
         this.templateUrl =
            "vm-ui/resources/vm/guest-os-customization/common/components/entryList/entry-list.component.html";
         this.bindings = {
            model: "<",
            labelKey: "<?",
            ariaLabelKey: "<?",
            refreshGridData: "=?"
         };
      }
   }

   angular
      .module("com.vmware.vsphere.client.vm")
      .component("entryList", new EntryListComponent());
}
