/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace dvs_ui {

   import DvsHostRemoveSpec = com.vmware.vsphere.client.dvs.api.spec.DvsHostRemoveSpec;
   import GhostProxySwitchRemoveSpec = com.vmware.vsphere.client.network.GhostProxySwitchRemoveSpec;
   export class RemoveDvsDialogService {

      public static $inject = [
         "clarityModalService",
         "i18nService",
         "dataService",
         "mutationService",
         "defaultUriSchemeUtil"
      ];

      constructor(
            private clarityModalService: any,
            private i18nService: any,
            private dataService: any,
            private mutationService: any,
            private defaultUriSchemeUtil: any) {

      }

      public removeDvs(hostUrn: string, dvsName: string, dvsRef: string) {
         let removeDvsSpec: DvsHostRemoveSpec = {
            _type: "com.vmware.vsphere.client.dvs.api.spec.DvsHostRemoveSpec",
            hosts: [this.defaultUriSchemeUtil.getManagedObjectReference(hostUrn)]
         };

         let submitCallback: Function = () => {
            this.mutationService.apply(
                  dvsRef,
                  removeDvsSpec._type,
                  removeDvsSpec);
         };

         this.openConfirmationDialog(hostUrn, dvsName, submitCallback);
      }

      public removeGhostDvs(hostUrn: string, dvsName: string, dvsUuid: string) {
         let ghostProxySwitchRemoveSpec: GhostProxySwitchRemoveSpec = {
            _type: "com.vmware.vsphere.client.network.GhostProxySwitchRemoveSpec",
            dvsUuid: dvsUuid
         };

         let submitCallback: Function = () => {
            this.mutationService.apply(
                  hostUrn,
                  ghostProxySwitchRemoveSpec._type,
                  ghostProxySwitchRemoveSpec);
         };

         this.openConfirmationDialog(hostUrn, dvsName, submitCallback);
      }

      private openConfirmationDialog(hostUrn: string, dvsName: string, submitCallback: Function) {



         let modalOptions: any = {
            title: this.i18nService.getString("DvsUi",
                  "removeHostFromDvs.dialogCaption"),
            submit: submitCallback,
            saveButtonLabel: this.i18nService.getString('NetworkUi',
                  'NetworkUtil.labelYes'),
            cancelButtonLabel: this.i18nService.getString('NetworkUi',
                  'NetworkUtil.labelNo'),
            preserveNewlines: true,
            clarityIcon: {
               class: "is-warning",
               shape: "warning-standard",
               size: "32"
            }
         };

         this.dataService.getProperties(hostUrn, ["name"]).then((resp: any) => {
            modalOptions.message = this.i18nService.getString("DvsUi",
                  "removeHostFromDvs.confirmWarning", resp.name, dvsName);
         });

         this.clarityModalService.openConfirmationModal(modalOptions);
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .service("removeDvsDialogService", RemoveDvsDialogService);
}
