/**
 * Faults view constants.
 *
 * @name com.vmware.vsphere.client.cluster:drsFaultsViewConstants
 * @module com.vmware.vsphere.client.commonModule
 */
angular.module("com.vmware.vsphere.client.commonModule")
      .constant("drsFaultsViewConstants", {
   FIELD_KEY: "field",
   ICON_KEY: "icon",
   TARGET_KEY: "target",
   DETAILS_KEY: "details",
   REASON: "reason",
   LOCALIZED_REASON: "reasonLocalized",
   ICON_FIELD: "icon",
   ICON_TARGET_FIELD: "target.icon",
   TARGET_TEXT_FIELD: "target.text",
   REASON_ICON_CLASS: "reasonIconClass",
   PREVENTED_RECOMMENDATION: "preventedRecommendation",
   PREVENTED_RECOMMENDATION_ICON_CLASS: "preventedRecommendationIconClass",
   ID_KEY: "id",
   NAME_KEY: "name",
   TEXT_KEY: "text",
   DESCRIPTION_KEY: "description",
   HOST_NAME_KEY: "hostName",
   HOST_ICON_KEY: "hostIcon",
   VM_NAME_KEY: "vmName",
   VM_ICON_KEY: "vmIcon",
   DISK_NAME_KEY: "disk"
});
