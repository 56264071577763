angular.module('com.vmware.vsphere.client.vm').directive('vmHardwareControllerAndNode', [
   'i18nService',
   'informationalDialogService',
   'controllerNodeFinderService',
   'vmDeviceInfoService',
   'vmHardwareControllerAndNodeService',
   function(
      i18nService,
      informationalDialogService,
      controllerNodeFinderService,
      vmDeviceInfoService,
      vmHardwareControllerAndNodeService
   ) {
      return {
         templateUrl: 'vm-ui/resources/vm/views/settings/vmHardwareSettings/vmHardwareControllerAndNode/vmHardwareControllerAndNode.html',
         scope: {
            inflatedDevice: '=',
            node: '=',
            powerState: '=',
            availableControllers: '=',
            virtualMachineDevices: '=',
            selectorName: '=',
            controllerSelectorName: '=',
            deviceController: '=',
            privileges: '=',
            onControllerChangeCallback: '&'
         },
         link: function(scope) {
            scope.i18n = i18nService.getString;
            init_aria(scope);

            var oldNode;
            scope.$watch('virtualMachineDevices.getDeviceChanges()', function () {
               if (scope.deviceController) {
                  updateNodeOptions();
               }
            }, true);

            // in clone workflow when you expand some stackblock that has device
            // node and go back, and then forth - when the Device node block is
            // being initialized the "scope.deviceController" is not set when
            // link function of the directive is called and as a result the device
            // nodes are not loaded, in order to avoid this adding listener for
            // deviceController change to update the node options later
            // this does not affect Edit settings workflow
            scope.$watch(function () {
               return scope.deviceController;
            }, function (newValue, oldValue) {
               if (newValue && newValue !== oldValue) {
                  updateNodeOptions();
               }
            });

            function updateNodeOptions() {
               scope.nodeOptions = buildNodeOptionsWithLabelsForOccupiedNodes();
               scope.node = _.find(scope.nodeOptions, function(option) {
                  return option.key === scope.inflatedDevice.getCurrentDevice().unitNumber;
               });
               oldNode = scope.node;
            }

            function buildNodeOptionsWithLabelsForOccupiedNodes() {
               var controllerNodes = buildControllerNodes(scope.deviceController, scope.inflatedDevice);

               var connectedDevices = scope.virtualMachineDevices.getDevicesAttachedToControllerKey(
                  scope.deviceController.getKey()
               );

               return _.map(controllerNodes, function(node) {
                  var deviceForNode = _.find(connectedDevices, function(device) {
                     return node.key === device.unitNumber;
                  });

                  if (deviceForNode) {
                     node.name += ' ' + deviceForNode.deviceInfo.label;
                  }

                  return node;
               });
            }

            var oldController = scope.deviceController; // in case we need to revert the selection
            scope.onControllerChange = function(newController) {
               var availableControllerNodes = buildControllerNodes(scope.deviceController, scope.inflatedDevice);

               var devicesWithNodes = scope.virtualMachineDevices.getDevicesThatHaveBeenAssignedToNodes();

               var nextAvailableNode = controllerNodeFinderService.findNextAvailableNodeForController(
                  newController.getKey(),
                  availableControllerNodes,
                  devicesWithNodes
               );

               if (nextAvailableNode) {
                  var deviceSpec = scope.inflatedDevice.getCurrentDeviceSpec();
                  deviceSpec.device.unitNumber = nextAvailableNode.key;
                  deviceSpec.device.controllerKey = newController.getKey();
                  oldController = newController;

                  updateNodeOptions();

                  if (scope.onControllerChangeCallback) {
                     scope.onControllerChangeCallback({
                        deviceController: scope.deviceController,
                        node: scope.node
                     });
                  }
               } else {
                  scope.deviceController = oldController;
                  var controllerName = newController.getCurrentDevice().deviceInfo.label;
                  informationalDialogService.display({
                     title: controllerName,
                     content: i18nService.getString('VmUi', 'GenericDevicePage.ControllerTaken', controllerName),
                     width: '300px',
                     height: '150px'
                  });
               }
            };

            scope.onControllerNodeChange = function(newNode) {
               if (newNode.key === oldNode.key) {
                  return;
               }

               var nodeIsInUse = scope.virtualMachineDevices.isNodeInUse(scope.deviceController.getKey(), newNode.key);

               if (nodeIsInUse) {
                  informationalDialogService.display({
                     title: [newNode.name, scope.inflatedDevice.getCurrentDevice().deviceInfo.label].join(' '),
                     content: i18nService.getString('VmUi', 'GenericDevicePage.NodeTaken'),
                     width: '300px',
                     height: '150px'
                  });
                  scope.node = oldNode;
               } else {
                  oldNode = newNode;
                  scope.inflatedDevice.getCurrentDevice().unitNumber = newNode.key;

                  if (scope.onControllerChangeCallback) {
                     scope.onControllerChangeCallback({
                        deviceController: scope.deviceController,
                        node: scope.node
                     });
                  }
               }
            };

            scope.disableSelection = function() {
               return vmHardwareControllerAndNodeService.isEditVmHardwareControllerAndNodeDisabled(scope.inflatedDevice, scope.privileges, scope.powerState);
            };
         }
      };

      function buildControllerNodes(controller, device) {
         var rawController = controller.getCurrentDevice();

         var controllerNodeIndices;
         if (device.isOfType('VirtualCdrom')) {
            controllerNodeIndices = controller.getCdromNodeIndices();
         } else if (device.isOfType('VirtualDisk')) {
            controllerNodeIndices = controller.getDiskNodeIndices();
         } else {
            throw new Error("Don't know how to determine controller nodes for device" + device.getCurrentDevice());
         }

         return _.map(controllerNodeIndices, function(index) {
            return {
               name: vmDeviceInfoService.getControllerAndNodeLabelText(rawController._type, rawController.busNumber, index),
               key: index
            };
         });
      }

      function init_aria(scope) {
         var i18n = function(key) {
            return i18nService.getString("VmUi", 'DiskPage.' + key);
         };
         scope.ARIA_LBL_CTRL = i18n("Controller");
         scope.ARIA_LBL_NODE = i18n("Node");
      }
   }
]);
