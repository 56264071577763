namespace h5_vm {

   export class CpuMmuConstants {

      static AUTOMATIC: string = "automatic";
      static SOFTWARE: string = "software";
      static HARDWARE_CPU: string = "hardwareCpu";
      static HARDWARE_CPU_MMU: string = "hardwareCpuMmu";
      static HV_AUTO: string = "hvAuto";
      static HV_OFF: string = "hvOff";
      static HV_ON: string = "hvOn";
      static OFF: string = "off";
      static ON: string = "on";

   }

}
