angular.module('com.vmware.vsphere.client.vm')
   .service('vmProvisioningSelectCreationTypePageService', ['i18nService','wizardPageService',
      function (i18nService, wizardPageService) {
      return {
         build: function (wizardScope, flowIds) {
            return {
               title: i18nService.getString('VmUi', 'SelectCreationTypeProvisioningPage.Title'),
               description: i18nService.getString('VmUi', 'SelectCreationTypeProvisioningPage.Description'),
               contentUrl: 'vm-ui/resources/vm/views/createVmWizard/vmProvisioningSelectCreationType.html',
               decisionOptions: { flowIds: flowIds },
               onCommit: function () {
                  wizardPageService.markPageComplete(wizardScope.config, wizardScope.attributes.creationType);
                  return true;
               }
            };
         }
      };
   }]);
