/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
(function() {
   'use strict';
   angular.module('com.vmware.vsphere.client.storage')
      .controller('SpecifyConfigurationPageController', [
         '$scope',
         'i18nService',
         function($scope, i18nService) {
            this.labels = {
               comboBox: i18nService.getString('StorageUi', 'increaseDatastoreWizard.partitionConfigurationPage.partitionConfiguration'),
               slider: i18nService.getString('StorageUi', 'increaseDatastoreWizard.partitionConfigurationPage.increaseSize'),
               gb: i18nService.getString('StorageUi', 'increaseDatastoreWizard.partitionConfigurationPage.gb')
            };
            this.selectedOption = $scope.manager.getSelectedOption();
            this.options = $scope.manager.getOptionsForSelectedDevice();
            this.diskPartitionInfo = $scope.manager.getDiskPartitionInfoForSelectedDevice();
            this.dsInfo = $scope.manager.getDatastoreInfo();
            if ($scope.manager.getSelectedDevice().expandable) {
               this.deviceDetails = i18nService.getString('StorageUi', "increaseDatastoreWizard.partitionConfigurationPage.expandableTip");
            } else {
               this.deviceDetails = i18nService.getString('StorageUi', "increaseDatastoreWizard.partitionConfigurationPage.nonExpandableTip");
            }

            this.onSelectedOptionChanged = function(selectedOption) {
               if (selectedOption && !selectedOption.isSelectConfigurationOption) {
                  $scope.manager.setSelectedOption(selectedOption);
               } else {
                  $scope.manager.setSelectedOption(undefined);
               }
            };
         }
      ]);
})();
