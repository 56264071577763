namespace storage_ui {

   import IPromise = angular.IPromise;

   export class SwapToSsdDatastoreItem {
      public datastoreName: string;
      public datastoreId: string;

      public formattedCapacity: string;
      public capacity: number;

      public formattedFreeSpace: string;
      public freeSpace: number;

      public formattedCacheSpace: string;
      public cacheSpace: number;

      public canBeUsedForCache: boolean;
   }

   export class HostSwapToSsdData {
      public hasAdvancedConfigPrivilege: boolean;
      public ssdDatastores: SwapToSsdDatastoreItem[];
   }

   export class SwapToSsdService {

      private readonly HOST_SWAP_TO_SSD_PROP = "storage:hostSwapToSsdData";

      public static $inject = ["i18nService", "dataService", "bytesFilter", "defaultUriSchemeUtil"];

      constructor(private i18nService: any,
                  private dataService: any,
                  private bytesFilter: any,
                  private defaultUriSchemeUtil: any) {
      }

      public getHostSwapToSsdData(hostId: string): IPromise<HostSwapToSsdData> {
         return this.dataService.getProperties(hostId, [this.HOST_SWAP_TO_SSD_PROP]).then((response:any) => {
            let result = new HostSwapToSsdData();
            let swapToSsdData = response && response[this.HOST_SWAP_TO_SSD_PROP];
            if (swapToSsdData) {
               result.hasAdvancedConfigPrivilege = swapToSsdData.hasAdvancedConfigPrivilege;
               result.ssdDatastores = _.map(swapToSsdData.ssdDatastores, (rawItem: any) => {
                  let item = new SwapToSsdDatastoreItem();
                  item.datastoreName = rawItem.formattedName;
                  item.datastoreId = this.defaultUriSchemeUtil.getVsphereObjectId(rawItem.dsRef);

                  item.canBeUsedForCache = rawItem.canBeUsedForCache;

                  item.capacity = rawItem.capacity;
                  item.formattedCapacity = this.bytesFilter(rawItem.capacity, "B", "Auto", 2);

                  item.freeSpace = rawItem.freeSpace;
                  item.formattedFreeSpace = this.bytesFilter(rawItem.freeSpace, "B", "Auto", 2);

                  if (rawItem.hostCacheSpaceInMb || rawItem.hostCacheSpaceInMb === 0) {
                     item.cacheSpace = rawItem.hostCacheSpaceInMb;
                     item.formattedCacheSpace = this.bytesFilter(rawItem.hostCacheSpaceInMb * 1024 * 1024, "B", "Auto", 2);
                  } else {
                     item.formattedCacheSpace = "";
                     item.cacheSpace = 0;
                  }

                  return item;
               });
            } else {
               result.hasAdvancedConfigPrivilege = true;
               result.ssdDatastores = [];
            }

            return result;
         });
      }
   }

   angular.module("com.vmware.vsphere.client.storage")
         .service("swapToSsdService", SwapToSsdService);
}
