(function () {
   'use strict';
   angular.module('com.vmware.vsphere.client.host').controller('EditAgentVmSettingsController',
         ['$scope',
            'dataService',
            'mutationService',
            'i18nService',
            EditAgentVmSettingsCtrl]);

   function EditAgentVmSettingsCtrl($scope,
                                    dataService,
                                    mutationService,
                                    i18nService) {

      var self = this;
      self.loading = true;

      var objectId = $scope.modalOptions.dialogData.availableTargets[0];

      $scope.modalOptions.onSubmit = onOKClicked.bind(this);

      getViewData();

      function getViewData() {
         dataService.getProperties(objectId, ['fullAgentVmSettings'])
               .then(function (response) {
                  self.datastoreOptions = response.fullAgentVmSettings.datastores;
                  var selectedDatastore = response.fullAgentVmSettings.selectedDatastore;

                  if (!selectedDatastore) {
                     self.selectedDatastore = null;
                  } else {
                     var selectedDatastoreIndex = findSelectedIndex(
                           self.datastoreOptions, selectedDatastore);
                     self.selectedDatastore = self.datastoreOptions[selectedDatastoreIndex];
                  }

                  self.networkOptions = response.fullAgentVmSettings.networks;
                  var selectedNetwork = response.fullAgentVmSettings.selectedNetwork;

                  if (!selectedNetwork) {
                     self.selectedNetwork = null;
                  } else {
                     var selectedNetworkIndex = findSelectedIndex(
                           self.networkOptions, selectedNetwork);
                     self.selectedNetwork = self.networkOptions[selectedNetworkIndex];
                  }

                  self.loading = false;
               });
      }

      function findSelectedIndex(objArray, objectToSearchFor) {
         return _.findIndex(objArray, function (item) {
            return _.isEqual(item.value, objectToSearchFor);
         });
      }

      function onOKClicked() {
         mutationService.apply(objectId,
               "com.vmware.vsphere.client.h5.host.config.spec.AgentVmSettingsSpec",
               {
                  datastore: self.selectedDatastore ?
                        self.selectedDatastore.value : null,
                  network: self.selectedNetwork ?
                        self.selectedNetwork.value : null
               },
               i18nService.getString('HostUi', 'settings.agentVm.operation'));
         return true;
      }
   }
})();
