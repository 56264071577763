/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
/*
 * Service which handles the wizard showing and wiring the wizard steps
 */
angular.module('com.vmware.vsphere.client.host').service('addHostWizardService', [
   'hostVuiService',
   'i18nService',
   'dataService',
   '$rootScope',
   'addHostNameLocationPageService',
   'addHostConnectionSettingsPageService',
   'addHostHostSummaryPageService',
   'addHostAssignLicensePageService',
   'addHostLockdownModePageService',
   'addHostVmLocationPageService',
   'addHostResourceLocationPageService',
   'addHostReadyToCompletePageService',
   'HostAdder',
   'vuiConstants',
   'wizardPageService',
   'defaultUriSchemeUtil',
   'VuiWizardHelper',
   'addHostScheduleTaskPageService',
   'lockdownModeStrings',
   function(hostVuiService,
         i18nService,
         dataService,
         $rootScope,
         addHostNameLocationPageService,
         addHostConnectionSettingsPageService,
         addHostHostSummaryPageService,
         addHostAssignLicensePageService,
         addHostLockdownModePageService,
         addHostVmLocationPageService,
         addHostResourceLocationPageService,
         addHostReadyToCompletePageService,
         HostAdder,
         vuiConstants,
         wizardPageService,
         defaultUriSchemeUtil,
         VuiWizardHelper,
         addHostScheduleTaskPageService,
         lockdownModeStrings) {

      return {
         showWizardInReconnectState: function(hostUid) {
            dataService.getProperties(hostUid, ['dcOrUserCreatedHostFolder',
               'cluster', 'name']).then(function(properties) {
               var parentRef = properties.cluster;
               if (!parentRef) {
                  parentRef = properties.dcOrUserCreatedHostFolder;
               }

               if (!parentRef) {
                  return;
               }
               var destinationUid = defaultUriSchemeUtil.getVsphereObjectId(parentRef);
               invokeWizard(destinationUid, hostUid, properties.name);
            });
         },
         showWizard: function(destinationUid, actionEval) {
            invokeWizard(destinationUid, null, null, actionEval);
         }
      };

      function invokeWizard(destinationUid, hostUid, hostName, actionEval) {
         var addHostWizardTitle = 'addHostWizard.title';
         if (actionEval && actionEval.additionalData &&
               actionEval.additionalData.isScheduledTask) {
            if (actionEval.additionalData.schedulingData.scheduleTaskOperationMode === 1) {
               addHostWizardTitle = 'scheduledtask.edit.' + addHostWizardTitle;
            } else {
               addHostWizardTitle = 'scheduledtask.' + addHostWizardTitle;
            }
         }
         var title = i18nService.getString('HostUi', addHostWizardTitle);
         var hostAdder = new HostAdder();
         var wizard = new VuiWizardHelper(title);

         hostAdder.setDestinationUid(destinationUid);
         var pages = [];
         var reconnectHost = !!hostUid;
         if (reconnectHost) {
            hostAdder.setHostUid(hostUid);
            hostAdder.setHostname(hostName);
            hostAdder.setReconnectHost(reconnectHost);
            var operationName = i18nService.getString('HostUi',
                  'reconnectHostOperation.name');
            hostAdder.setOperationName(operationName);
            pages = createWizardPages(hostAdder, wizard, actionEval);

            wizard.show(pages, hostAdder, 'add-host-wizard');
         } else {
            hostAdder.setOperationName(title);
            // In case of edit schedule task we initialize the data in the wizard
            if (actionEval && actionEval.additionalData &&
                  actionEval.additionalData.isScheduledTask &&
                  actionEval.additionalData.schedulingData.scheduleTaskOperationMode === 1) {
               openEditAddHostScheduledTaskWizard(hostAdder, wizard, actionEval);
            } else {
               pages = createWizardPages(hostAdder, wizard, actionEval);
               wizard.show(pages, hostAdder, 'add-host-wizard');
            }
         }
      }

      function createWizardPages(hostAdder, wizard, actionEval) {
         var pages = [];
         if (actionEval && actionEval.additionalData
               && actionEval.additionalData.isScheduledTask === true) {
            hostAdder.getScheduleTaskPageModel.isScheduledTaskFlow
                  = actionEval.additionalData.isScheduledTask;
            hostAdder.getScheduleTaskPageModel.mutationSpec
                  = actionEval.additionalData.schedulingData;
            pages.push(addHostScheduleTaskPageService.build(hostAdder, wizard));
         }
         pages.push(addHostNameLocationPageService.build(hostAdder, wizard));
         pages.push(addHostConnectionSettingsPageService.build(hostAdder, wizard));
         pages.push(addHostHostSummaryPageService.build(wizard));
         pages.push(addHostAssignLicensePageService.build(hostAdder, wizard));
         pages.push(addHostLockdownModePageService.build(wizard));
         pages.push(addHostVmLocationPageService.build(hostAdder, wizard));
         pages.push(addHostResourceLocationPageService.build(
               hostAdder, wizard));
         pages.push(addHostReadyToCompletePageService.build());

         return pages;
      }

      /**
       * Method to open the edit scheduled task for add host. It opens the same
       * wizard for adding a host but with filled data and with first page -> the
       * scheduled task page which is ready for edit.
       * @param hostAdder The host model which is used to pass a data
       * @param wizard Object which is used to invoke the actual wizard
       * @param actionEval The actionEval object used to create the wizard pages
       */
      function openEditAddHostScheduledTaskWizard(hostAdder, wizard, actionEval) {
         var pages = [];
         // An array of type MethodActionArgument
         var args = actionEval.additionalData.schedulingData.spec.argument;
         // An object of type Connection spec
         var connectSpec = args[0].value;
         hostAdder.setHostname(connectSpec.hostName);
         hostAdder.setConnectionSettings({
            username: connectSpec.userName,
            password: connectSpec.password
         });
         hostAdder.setSslThumbprint(connectSpec.sslThumbprint);
         // When scheduling mode is enabled creation of new RP is disabled
         hostAdder.setResourceLocationType(null);

         hostAdder.setLockdownMode(lockdownModeStrings.forSpec(connectSpec.lockdownMode));

         // Get VM name if the vm folder is presented
         if (connectSpec.vmFolder) {
            var vmId = defaultUriSchemeUtil.getVsphereObjectId(connectSpec.vmFolder);
            dataService.getProperties(vmId, ['name'])
                  .then(function(result) {
                     var displayName = "";
                     if (result && result.name) {
                        displayName = result.name;
                     }
                     hostAdder.setVmLocation({
                        ref: connectSpec.vmFolder,
                        displayName: displayName
                     });
                     pages = createWizardPages(hostAdder, wizard, actionEval);
                     wizard.show(pages, hostAdder, 'add-host-wizard');
                  });
         } else {
            pages = createWizardPages(hostAdder, wizard, actionEval);
            wizard.show(pages, hostAdder, 'add-host-wizard');
         }
      }
   }
]);
