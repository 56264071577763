angular.module('com.vmware.vsphere.client.vm').directive('vmHardwareOther', ['i18nService', function(i18nService) {
   return {
      templateUrl: 'vm-ui/resources/vm/views/settings/vmHardwareSettings/vmHardwareOther/vmHardwareOther.html',
      controllerAs: 'vmHardwareOtherCtrl',

      controller: function() {

         this.getSummaryText = function(inflatedDevice, type) {
            var device = inflatedDevice.getCurrentDevice();
            var summary = device.deviceInfo.summary;
            var label = device.deviceInfo.label;
            var deviceType = device._type;
            if (deviceType === 'com.vmware.vim.binding.vim.vm.device.VirtualKeyboard' ||
               deviceType === 'com.vmware.vim.binding.vim.vm.device.VirtualPointingDevice') {
               return label;
            } else if (type === 'InputDevice') {
               return summary;
            } else {
               return (summary === label) ? summary : label + ' (' + summary + ')';
            }
         };
      },

      link: function(scope) {
         scope.i18n = i18nService.getString;
      }
   };
}])
   .filter("deviceTypeFilter", function() {
      return function(value, type) {

         return value.filter( function(o) {
            return !o.isOfType(type);
         });

      };
   });
