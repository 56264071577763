/* Copyright 2018 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace h5_vm {
   import ManagedObjectReference = com.vmware.vim.binding.vmodl.ManagedObjectReference;
   import NetworkInfo = com.vmware.vim.binding.vim.vm.NetworkInfo;
   import VirtualDeviceOption = com.vmware.vim.binding.vim.vm.device.VirtualDeviceOption;
   import VirtualDeviceSpec = com.vmware.vim.binding.vim.vm.device.VirtualDeviceSpec;
   import GroupInfo = com.vmware.vim.binding.sms.storage.replication.GroupInfo;
   import ProfileSpec = com.vmware.vim.binding.vim.vm.ProfileSpec;
   export class ScheduledTasksDeviceChangesService {
      public static $inject = ["virtualDeviceInflator", "deviceService", "vmDeviceInfoService",
         "defaultUriSchemeUtil", "storageProfileService"];

      constructor(private virtualDeviceInflator: any, private deviceService: any,
            private vmDeviceInfoService: any, private defaultUriSchemeUtil: any,
            private storageProfileService: any) {
      }

      addDevices(virtualMachineDevices: any, deviceChanges: any,
            virtualDeviceOptionsByDeviceType: { [index: string]: VirtualDeviceOption },
            vmParams: any, availableNetworks: NetworkInfo[],
            datastoreRefs: { [index: string]: ManagedObjectReference },
            vmProfile: ProfileSpec[]) {
         const additionalInfo = {
            deviceOptionsByDeviceType: virtualDeviceOptionsByDeviceType,
            availableNetworks: availableNetworks
         };
         _.each(deviceChanges, (deviceChange: any) => {
            let device = virtualMachineDevices.getVirtualDevice(deviceChange.device.key);
            if (!_.isEmpty(device)) {
               device.setCurrentDeviceSpec(deviceChange);
            } else {
               const inflatedDevice = this.virtualDeviceInflator.inflate(deviceChange.device, additionalInfo);
               inflatedDevice.setCurrentDeviceSpec(deviceChange);
               virtualMachineDevices.addVirtualDevice(inflatedDevice);
            }

            this.updateVirtualDiskProperties(deviceChange, virtualMachineDevices,
                  vmParams, datastoreRefs, vmProfile);
         });

         const allDevices = virtualMachineDevices.getInflatedDevices();
         this.deviceService.calculateKey(allDevices);
      }

      private setStorageForDisk(device: any, vmParams: any, datastoreRefs:
            { [index: string]: ManagedObjectReference }) {
         // the disks datastore name is kept in the format [datastore name]
         const match = device.backing.fileName.match(/\[(.*)\]/);
         const datastoreName = match ? match[1] : "";
         const datastoreRef = datastoreRefs[datastoreName];
         const datastoreId = this.defaultUriSchemeUtil.getVsphereObjectId(datastoreRef);

         if (datastoreId) {
            const storageObject = {
               storageObject: {
                  storageRef: datastoreRef,
                  name: datastoreName
               },
               name: datastoreName,
               id: datastoreId
            };

            vmParams.setStorageForDisk(storageObject, device);
            return;
         }

         if (device.backing.datastore) {
            const storageObject = {
               storageObject: {
                  storageRef: device.backing.datastore,
                  name: datastoreName
               },
               name: datastoreName,
               id: this.defaultUriSchemeUtil.getVsphereObjectId(device.backing.datastore)
            };

            vmParams.setStorageForDisk(storageObject, device);
         }

      }

      private updateVirtualDiskProperties(deviceChange: any, virtualMachineDevices: any,
            vmParams: any,
            datastoreRefs: { [index: string]: ManagedObjectReference },
            vmProfile: ProfileSpec[]) {
         if (this.vmDeviceInfoService.isDeviceSubclassOf(deviceChange.device, "VirtualDisk")) {
            const deviceFileName = deviceChange.device.backing.fileName;
            // if the device's file name does not contain only the datastore name
            // but the vmdk info in  the format "[datastore name] file.vmdk"
            // the hard disk is imported from a disk of an existing VM or
            // we are in clone mode and that is the original disk of the VM in
            // which case the datastore is also populated in the backing
            const createdFromExistingDisk =
                  deviceFileName.lastIndexOf("]") !== (deviceFileName.length - 1) &&
                  deviceChange.device.backing.datastore === null;
            deviceChange.device.createdFromExistingDisk = createdFromExistingDisk;
            this.setStorageForDisk(deviceChange.device, vmParams, datastoreRefs);

            let device = virtualMachineDevices.getVirtualDevice(deviceChange.device.key);
            device.setCurrentDeviceSpec(deviceChange);
            if (!createdFromExistingDisk) {
               const isProfileEqualToHome = this.storageProfileService.areProfileSpecsEqual(
                     deviceChange.device.profile && deviceChange.device.profile[0], vmProfile[0], false);
               device.setStorageProfileManuallySet(!isProfileEqualToHome);
            }
            if (!_.isEmpty(deviceChange.profile)) {
               const profile = deviceChange.profile[0];
               let replicationGroupInfo: any | undefined;
               if (profile.replicationSpec) {
                  replicationGroupInfo = {
                     id: profile.replicationSpec.replicationGroupId
                  };
               }
               device.updateDiskProfile(profile, replicationGroupInfo);
            }
         }
      }
   }
   angular.module("com.vmware.vsphere.client.vm")
         .service("scheduledTasksDeviceChangesService", ScheduledTasksDeviceChangesService);
}
