/* Copyright 2018 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace network_ui {

   export class StandardSwitchDetailsDialogContent {

      public bindings: any;
      public controller: any;
      public template: string;

      constructor() {
         this.bindings = {
            hostId: "<",
            switchKey: "<"
         };
         this.controller = StandardSwitchDetailsDialogContentController;
         this.template = `
               <vx-activity-indicator is-visible="$ctrl.loading"
                             title="{{i18n('Common', 'loadingTitle')}}"></vx-activity-indicator>
               <clr-tabs ng-if="!$ctrl.loading">

                  <clr-tab-link>{{$ctrl.i18n("H5NetworkUi", "standardSwitchDetailsView.tabs.all")}}</clr-tab-link>
                  <clr-tab-link>{{$ctrl.i18n("H5NetworkUi", "standardSwitchDetailsView.tabs.properties")}}</clr-tab-link>
                  <clr-tab-link>{{$ctrl.i18n("H5NetworkUi", "standardSwitchDetailsView.tabs.policies")}}</clr-tab-link>

                  <clr-tab-content>
                     <div class="standard-switch-details-dialog-tab">
                        <vx-property-view
                           data="$ctrl.propertiesData"
                           class="vx-property-view-standard-fixed-label-column">
                        </vx-property-view>
                        <vx-property-view
                           data="$ctrl.policiesData"
                           class="vx-property-view-standard-fixed-label-column">
                        </vx-property-view>
                     </div>
                  </clr-tab-content>

                  <clr-tab-content>
                     <div class="standard-switch-details-dialog-tab">
                        <vx-property-view
                           data="$ctrl.propertiesData"
                           class="vx-property-view-standard-fixed-label-column">
                        </vx-property-view>
                     </div>
                  </clr-tab-content>

                  <clr-tab-content>
                     <div class="standard-switch-details-dialog-tab">
                        <vx-property-view
                           data="$ctrl.policiesData"
                           class="vx-property-view-standard-fixed-label-column">
                        </vx-property-view>
                     </div>
                  </clr-tab-content>
               </clr-tabs>
               `;
      }
   }

   class StandardSwitchDetailsDialogContentController {

      static readonly $inject: any[] = [
         "i18nService",
         "standardSwitchPropertiesViewService",
         "standardSwitchPoliciesViewService"
      ];

      public hostId: string;

      public switchKey: string;

      public propertiesData: any;

      public policiesData: any;

      public i18n: Function;

      constructor(private i18nService: any,
            private standardSwitchPropertiesViewService: any,
            private standardSwitchPoliciesViewService: any) {
         this.i18n = i18nService.getString;
      }

      public $onInit(): void {
         this.standardSwitchPropertiesViewService.getData(this.hostId, this.switchKey)
               .then((propertiesData: any): void => {
                  this.propertiesData = propertiesData;
               });

         this.standardSwitchPoliciesViewService.getData(this.hostId, this.switchKey)
               .then((policiesData: any): void => {
                  this.policiesData = policiesData;
               });
      }

      public get loading(): boolean {
         return !this.propertiesData || !this.policiesData;
      }
   }

   angular.module("com.vmware.vsphere.client.network")
         .component("standardSwitchDetailsDialogContent",
               new StandardSwitchDetailsDialogContent());
}
