(function() {
   'use strict';
   angular.module('com.vmware.vsphere.client.host').service('refreshCertificateService',
         RefreshCertificateService);
   RefreshCertificateService.$inject = ['mutationService'];

   function RefreshCertificateService(mutationService) {

      function refreshCertificate(hostId) {

         mutationService.applyOnMultiEntity(
               [hostId],
               'com.vmware.vsphere.client.host.certificates.HostCertificateOpSpec',
               {certOpType:'refresh'}
         );
      }
      function refreshCaCertificate(hostId){
         mutationService.applyOnMultiEntity(
               [hostId],
               'com.vmware.vsphere.client.host.certificates.HostCertificateOpSpec',
               {certOpType:'refreshCACertificates'}
         );
      }

      return {
         refreshCertificate: refreshCertificate,
         refreshCaCertificate:refreshCaCertificate
      };
   }
})();
