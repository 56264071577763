(function() {
   'use strict';
   angular.module('com.vmware.vsphere.client.cluster')
         .controller('HostRuntimeInfoController', HostRuntimeInfoController);

   HostRuntimeInfoController.$inject = ['$scope', 'dataService', 'haSummaryService', '$q'];

   function HostRuntimeInfoController($scope, dataService, haSummaryService, $q) {
      var objectId = $scope._route ? $scope._route.objectId : null;

      $scope.loadingData = false;
      $scope.$on('haSummaryPortletsRefresh', getPortletData);

      function getPortletData() {
         if ($scope.loadingData) {
            return;
         }

         $scope.loadingData = true;

         return dataService.getProperties(objectId, ['hostHaRuntimeInfo'])
               .then(function(data) {
                  if (data && data.hostHaRuntimeInfo) {
                     $scope.portletData = haSummaryService.getHostsRuntimeData(
                           data.hostHaRuntimeInfo);
                  }
                  $scope.loadingData = false;
               })
               .catch(function(reason) {
                  $scope.loadingData = false;
                  $q.reject(reason);
               });
      }

      getPortletData();
   }
})();
