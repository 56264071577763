namespace h5_vm {

   import GosAdministratorPasswordPageModel = h5_vm.GosAdministratorPasswordPageModel;

   export class GosAdministratorPasswordPageController {
      public static $inject = [
            "i18nService",
            "$q",
            "clarityModalService"];

      model: GosAdministratorPasswordPageModel;
      passwordMismatch: boolean = false;

      constructor(private i18n: any,
                  private $q: any,
                  private clarityModalService: any) {
      }

      $onInit(): void {
         this.model.onPageCommit = this.onCommit.bind(this);
      }

      $onDestroy(): void {
         this.model.onPageCommit = undefined;
      }

      onPasswordChanged() {
         this.model.isPasswordChanged = true;
         this.model.isPreviousPasswordBlank = false;
      }

      validatePassword() {
         this.passwordMismatch = this.model.password !== this.model.confirmedPassword;
      };

      onCommit(): angular.IPromise<Array<string>> {
         if (this.model.password !== this.model.confirmedPassword) {
            this.validatePassword();
            let error: string = this.i18n.getString(
               "VmUi", "customizationSpec.workgroupDomainPage.errorMessage.passwordsMismatch");
            return this.$q.resolve([error]);
         }

         // If there are no errors last thing to check is
         // if warning for blank password is needed
         if (_.isEmpty(this.model.password) && !this.model.isPreviousPasswordBlank) {

            let deferred: any = this.$q.defer();

            let modalOptions: any = {
               title: this.i18n.getString("VmUi", "customizationSpec.adminPasswordPage.blankPwdWarningTitle"),
               message: this.i18n.getString("VmUi", "customizationSpec.adminPasswordPage.blankPwdWarning"),
               submit: () => {
                  deferred.resolve(true);
               },
               onCancel: () => {
                  deferred.resolve(false);
               },
               preserveNewlines: true,
               clarityIcon: {
                  shape: "warning",
                  class: "is-warning",
                  size: 48
               },
               saveButtonLabel: this.i18n.getString("Common", "yes.label"),
               cancelButtonLabel: this.i18n.getString("Common", "no.label")
            };

            this.clarityModalService.openConfirmationModal(modalOptions);
            return deferred.promise.then((result: boolean) => {
               if (result) {
                  this.model.isPreviousPasswordBlank = true;
                  return this.$q.when([]);
               } else {
                  return null;
               }
            });
         }
         return this.$q.resolve([]);
      }




   }

   export class GosAdministratorPasswordPageComponent implements angular.IComponentOptions {
      public controller: any;
      public templateUrl: string;
      public bindings: any;

      constructor() {
         this.controller = GosAdministratorPasswordPageController;
         this.templateUrl =
            "vm-ui/resources/vm/guest-os-customization/pages/administrator-password/gos-administrator-password-page.component.html";
         this.bindings = {
            model: "<"
         };
      }
   }

   angular
      .module("com.vmware.vsphere.client.vm")
      .component("gosAdministratorPasswordPage", new GosAdministratorPasswordPageComponent());
}
