namespace h5_vm {
   export class GosNetworkPageEditController {
      public static $inject = ["i18nService", "vuiConstants"];

      public readonly DNS_TITLE = "customizationSpec.networkPage.properties.dns.pageTitle";
      public readonly IPV4_TITLE = "customizationSpec.networkPage.properties.ipv4.pageTitle";
      public readonly IPV6_TITLE = "customizationSpec.networkPage.properties.ipv6.pageTitle";
      public readonly WINS_TITLE = "customizationSpec.networkPage.properties.wins.pageTitle";

      public adapterWrapper: AdapterMappingWrapper;
      public customizationSpecType: GosType;
      public i18n: (bundle: string, key: string, ...args: (string | number)[]) => string;
      public editIpv4: () => boolean;
      public editIpv6: () => boolean;
      public editDns: () => void;
      public editWins: () => boolean;
      public editNic: () => void;
      public onSubmit: () => boolean;
      public validationErrors: any[] = [];
      public dnsSuffixList: string[];
      public alerts: AlertMessage[];
      public updateDnsTab: () => void;
      public submitDisabled: boolean;
      public refreshGridData: Function = () => {};

      constructor(private i18nService: any, private vuiConstants: any) {
         this.i18n = this.i18nService.getString;
      }

      $onInit(): void {
         this.validationErrors = [];
         this.onSubmit = (): boolean => {
            if (!this.validateTabs()) {
               let alert: AlertMessage = {
                  type: this.vuiConstants.validationBanner.type.ERROR,
                  text: this.i18n("VmUi", "customizationSpecWizard.pageValidationError")
               };
               this.alerts = [alert];
               return false;
            }
            this.editNic();
            return true;
         };
      }

      $doCheck() {
        this.submitDisabled = this.validationErrors && this.validationErrors.length > 0;
      }

      isGosWindows(): boolean {
         return this.customizationSpecType === GosType.WINDOWS
            || this.customizationSpecType === GosType.WINDOWS_CUSTOM_SYSPREP;
      }

      showTabError(tabTitle: string) {
         return _.some(this.validationErrors, (tab: string) => {
            return tab === tabTitle;
         });
      }

      onTabSelected(index: number): void {
         if (index === 2) {
            this.refreshGridData();
         }
      }

      private validateTabs(): boolean {
         const IP_4_IS_VALID: boolean = this.editIpv4();
         const IP_6_IS_VALID: boolean = this.editIpv6();
         const WINS_IS_VALID: boolean = this.isGosWindows() ? this.editWins() : true;
         if (this.isGosWindows()) {
            this.editDns();
         }

         return IP_4_IS_VALID && IP_6_IS_VALID && WINS_IS_VALID;
      }
   }

   export class GosNetworkPageEditComponent {
      public controller: any;
      public templateUrl: string;
      public bindings: any;

      constructor() {
         this.bindings = {
            adapterWrapper: "<",
            customizationSpecType: "<",
            isNameIpGeneratorDefined: "<",
            editNic: "<",
            alerts: "=",
            onSubmit: "=",
            validationErrors: "=",
            submitDisabled: "=",
            dnsSuffixList: "="
         };
         this.controller = GosNetworkPageEditController;
         this.templateUrl = "vm-ui/resources/vm/guest-os-customization/pages/network/edit/gos-network-page-edit.component.html";
      }
   }

   angular.module("com.vmware.vsphere.client.vm")
         .component("gosNetworkPageEdit", new GosNetworkPageEditComponent());
}
