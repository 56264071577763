namespace h5_vm {
   import Specification = com.vmware.vim.binding.vim.vm.customization.Specification;
   export interface CustomizeGosUserSettingsDialogContext {
      vmId: string;
      vmGuestOsFullName: string;
      vmGuestOsType: string;
      specification: Specification;
      hasUnknownNameGen: boolean;
      hasUnknownIpv4Gen: boolean;
      hasUnknownIpv6Gen: boolean;
      focusTarget: HTMLElement;
   }

   export class CustomizeGosUserSettingsDialogService {
      public static $inject = [
         "i18nService",
         "clarityModalService",
         "vuiConstants",
         "customizeGosApplySpecService",
         "$q",
      ];

      private context: CustomizeGosUserSettingsDialogContext;
      private pageModel: CustomizeGosUserSettingsPageModel;
      private dialogOptions: any;

      constructor(private i18nService: any,
            private clarityModalService: any,
            private vuiConstants: any,
            private customizeGosApplySpecService: CustomizeGosApplySpecService,
            private $q: any) {
      }

      open(context: CustomizeGosUserSettingsDialogContext): void {
         this.context = context;
         let dialogOptions: any = {
            contentTemplate: "vm-ui/resources/vm/customize-guest-os/user-settings/customize-gos-user-settings-page.html",
            title: this.i18nService.getString("VmUi", "customizeGuestOs.title"),
            subTitle: {
               objectId: this.context.vmId
            },
            defaultButton: "submit",
            onSubmit: this.onDialogSubmit.bind(this),
            submitButtonTitle: this.i18nService.getString("VmUi", "customizeGuestOs.dialog.saveButton"),
            focusTarget: context.focusTarget
         };

         this.pageModel = new CustomizeGosUserSettingsPageModel(
               this.context.vmId,
               this.context.vmGuestOsFullName,
               this.context.vmGuestOsType,
               this.context.specification);
         this.pageModel.hasUnknownNameGen = this.context.hasUnknownNameGen;
         this.pageModel.hasUnknownIpv4Gen = this.context.hasUnknownIpv4Gen;
         this.pageModel.hasUnknownIpv6Gen = this.context.hasUnknownIpv6Gen;

         dialogOptions.dialogData = {
            userSettingsPageModel: this.pageModel
         };

         this.dialogOptions = dialogOptions;
         this.clarityModalService.openOkCancelModal(this.dialogOptions);
      }

      // region private helpers
      private onDialogSubmit(): boolean {
         let pageCommitPromise = this.pageModel.onPageCommit
               ? this.pageModel.onPageCommit()
               : this.$q.resolve([]);

         return pageCommitPromise.then((errorMsgs: Array<string>) => {
            this.dialogOptions.alerts = [];

            if (errorMsgs && errorMsgs.length > 0) {
               this.dialogOptions.alerts = errorMsgs.map((msg: string) => {
                  return {
                     text: msg,
                     type: this.vuiConstants.validationBanner.type.ERROR
                  };
               });
               return false;
            }

            if (!this.pageModel.specification) {
               throw new Error("Invalid specification");
            }

            return this.customizeGosApplySpecService.apply(this.context.vmId,
                  this.pageModel.specification);
         });
      }

      // endregion

   }

   angular.module("com.vmware.vsphere.client.vm")
         .service("customizeGosUserSettingsDialogService", CustomizeGosUserSettingsDialogService);
}
