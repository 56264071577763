module cluster_ui {

   class EditHaDialogController {

      public pageModel: any;
      public loading: boolean;

      public static $inject = ["$scope", "dataService"];

      constructor(private $scope: any, private dataService: any) {
         $scope.pages = {
            categories: $scope.modalOptions.dialogData.manager.getPageDefinitions()
         };

         $scope.selectedCategoryId = $scope.pages.categories[0].id;
         $scope.selectCategory = (categoryId: any) => {
            if ($scope.modalOptions.dialogData.manager.validate()) {
               this.$scope.selectedCategoryId = categoryId;
            }
         };

         this.getViewData();
      }

      public getSelectedPageUrl(): string {
         let selectedPage: any = _.find(this.$scope.pages.categories, (page: any) => {
            return page.id === this.$scope.selectedCategoryId;
         });

         if (selectedPage) {
            return selectedPage.contentUrl;
         }

         return "";
      }

      private getViewData(): void {
         this.setPageBusy(true);
         this.dataService.getData(this.$scope.modalOptions.dialogData.objectId,
             "com.vmware.vsphere.client.clusterui.model.services.ClusterEditHaData")
             .then((data: any) => {
                _.extend(this.$scope.modalOptions.dialogData.haData, data);
                this.pageModel = this.$scope.modalOptions.dialogData.manager
                    .getFailuresAndResponsesModel();

                this.setPageBusy(false);
             });
      }

      private setPageBusy(busy: boolean): void {
         this.loading = busy;
         this.$scope.modalOptions.submitDisabled = busy;

      }
   }

   angular.module("com.vmware.vsphere.client.cluster")
         .controller("EditHaDialogController", EditHaDialogController);
}
