/* Copyright 2015 VMware, Inc. All rights reserved. -- VMware Confidential */
(function() {
   'use strict';
   angular.module('com.vmware.vsphere.client.host').controller('AuthServicesController', AuthServicesController);

   AuthServicesController.$inject = [
      '$scope', 'dataService', 'i18nService', 'vxPropertyViewService', 'timeFormatterService', 'hostAuthService'
   ];

   function AuthServicesController($scope,
         dataService,
         i18nService,
         vxPropertyViewService,
         timeFormatterService,
         hostAuthService) {

      var isSmartCardAuthenticationEnabledProperty = 'isSmartCardAuthenticationEnabled';
      var smartCardTrustAnchorsProperty = 'smartCardTrustAnchors';
      var objectId = $scope._route.objectId;
      this.objectsToWatch = [objectId];
      this.settingsViewHeaderAccessor = {};
      this.smartcardSettingsViewHeaderAccessor = {};
      this.nfsKerberosCredentialsViewAccessor = {};
      this.headerOptions = hostAuthService.hostAuthHeaderOptions(objectId);
      this.smartCardHeaderOptions = hostAuthService.smartCardHeaderOptions();
      this.nfsKerberosHeaderOptions = hostAuthService.nfsKerberosHeaderOptions();
      this.smartCardData = {};
      this.data = {};
      this.smartcardSupported = false;
      // nfs kerberos view
      this.nfsKerberosSupported = false;
      this.userName = i18nService.getString('StorageUi', 'nfsCredentials.noUserName');
      this.enabled = false;
      this.state = '';

      this.liveRefreshProperties = [
         'config.network.dnsConfig.searchDomain',
         'config.network.dnsConfig.address',
         'config.network.netStackInstance',
         'config.domainList',
         'config.authenticationManagerInfo.authConfig',
         'config.service.service'
      ];

      this.refresh = function() {
         this.getViewData();
         if (this.settingsViewHeaderAccessor &&
               this.settingsViewHeaderAccessor.refresh &&
               this.smartcardSettingsViewHeaderAccessor &&
               this.smartcardSettingsViewHeaderAccessor.refresh &&
               this.nfsKerberosCredentialsViewAccessor &&
               this.nfsKerberosCredentialsViewAccessor.refresh) {
            this.settingsViewHeaderAccessor.refresh();
            this.smartcardSettingsViewHeaderAccessor.refresh();
            this.nfsKerberosCredentialsViewAccessor.refresh();
         }
      };

      this.getViewData = function() {

         dataService
               .getData(objectId,
                     "com.vmware.vsphere.client.h5.host.model.authentiaction.AuthenticationServicesData")
               .then(function(data) {
                  if (data) {
                     this.smartcardSupported = data.isSmartCardAuthenticationSupported;
                     //Don't request smart card data for 5.5 host
                     if (this.smartcardSupported && data.isHostConnected) {
                        dataService.getProperties(objectId,
                              [isSmartCardAuthenticationEnabledProperty,
                                 smartCardTrustAnchorsProperty]).then(function(data) {
                           if (data) {
                              this.smartCardData.enabled = hostAuthService.getSmartCardEnabledStatus(data.isSmartCardAuthenticationEnabled);
                              this.setGridOptionsData(data);
                           }
                        }.bind(this));
                     }
                     this.data.domain = i18nService.getString('HostUi', 'none.label');
                     this.data.trustedDomain = i18nService.getString('HostUi', 'none.label');
                     this.setAuthenticationStatus(data);
                     this.buildPropertyViewData(data);
                     this.nfsKerberosSupported = data.isNfsSupproted;
                     if (this.nfsKerberosSupported) {
                        this.setNfsKerberosCredentials(data);
                     }
                  }
               }.bind(this));
      };

      this.setGridOptionsData = function(data) {
         if (data.smartCardTrustAnchors) {
            timeFormatterService.getUserTimeFormatPreference()
                  .then(function(userTimeFormat) {
                     this.datagridOptions = hostAuthService.getDatagridOptions(userTimeFormat);
                     this.datagridOptions.data = data.smartCardTrustAnchors;

                  }.bind(this));
         }
      };

      this.setAuthenticationStatus = function(data) {
         if (data.isActiveDirectoryAuthenticationUsed) {
            this.data.authStatus = i18nService.getString('HostUi',
                  'authServices.directory.devices.active');

            var config = data.authenticationConfig;
            for (var index in config) {
               if (config[index]['_type'] === "com.vmware.vim.binding.vim.host.ActiveDirectoryInfo") {
                  this.data.domain = config[index].joinedDomain;
                  this.data.trustedDomain = config[index].trustedDomain;
                  break;
               }
            }
         } else {
            this.data.authStatus = i18nService.getString('HostUi',
                  'authServices.directory.devices.local');
         }
      };

      this.buildPropertyViewData = function() {
         var builder = vxPropertyViewService.createPropertyViewBuilder();
         var categoryBuilder = builder.category('');

         hostAuthService.buildDirectoryServicesConfigSection(categoryBuilder, this.data.authStatus);
         hostAuthService.buildDomainSettingsSection(categoryBuilder, this.data);

         this.propertyViewData = builder.build();
      };

      this.setNfsKerberosCredentials = function(data) {
         if (data.inDomain) {
            if (!data.nfsKerberosUser) {
               this.state = i18nService.getString('StorageUi', 'nfsCredentials.state.noUser');
            } else { // active
               this.state = i18nService.getString('StorageUi', 'nfsCredentials.state.enabled');
            }
         } else { // disabled, not in domain
            this.state = i18nService.getString('StorageUi', 'nfsCredentials.state.noDomain');
         }

         this.enabled = data.inDomain && data.nfsKerberosUser;

         this.userName = this.enabled ?
               data.nfsKerberosUser :
               i18nService.getString('StorageUi', 'nfsCredentials.noUserName');
      };

      this.getViewData();
   }
})();
