module cluster_ui {

   export class ResourceAllocationService {

      private CLUSTER_MODEL_CLASS_MEMORY: string =
            "com.vmware.vsphere.client.clusterui.model.monitor.reservation.ClusterMemoryCapacityData";
      private HOST_MODEL_CLASS_MEMORY: string =
            "com.vmware.vsphere.client.clusterui.model.monitor.reservation.HostMemoryCapacityData";
      private RP_MODEL_CLASS_MEMORY: string =
            "com.vmware.vsphere.client.clusterui.model.monitor.reservation.ResourcePoolMemoryCapacityData";

      public static $inject = ["i18nService"];

      constructor(private i18nService: any) {
      }

      public sortReservationUsersLimit(a: any, b: any): number {
         const unlimitedValue: number = -1;
         let res: number = 0;

         if (a.limit === unlimitedValue && b.limit !== unlimitedValue) {
            res = 1;
         } else if (b.limit === unlimitedValue && a.limit !== unlimitedValue) {
            res = -1;
         } else {
            res = a.limit - b.limit;
         }

         return res;
      }

      public sortSharesLevels(a: any, b: any): number {
         let valuesFactors: any = {
            "": 0,
            "low": 1,
            "normal": 2,
            "high": 3,
            "custom": 4
         };

         return valuesFactors[a.sharesInfo.level] - valuesFactors[b.sharesInfo.level];
      }

      public getSharesValueTextByLevel(level: string): string {
         let values: any = {
            "low": this.i18nService.getString("VmUi", "sharesInfoLevel.low"),
            "normal": this.i18nService.getString("VmUi", "sharesInfoLevel.normal"),
            "high": this.i18nService.getString("VmUi", "sharesInfoLevel.high"),
            "custom": this.i18nService.getString("VmUi", "sharesInfoLevel.custom")
         };
         return values[level];
      }

      public getReservationTypeByModel(modelClass: string): string {
         if (modelClass === this.HOST_MODEL_CLASS_MEMORY ||
               modelClass === this.CLUSTER_MODEL_CLASS_MEMORY ||
               modelClass === this.RP_MODEL_CLASS_MEMORY) {
            return "memory";
         }

         return "cpu";
      }

      public getMaxCapacityValue(data: any): string {
         if (data.rpReservationCapacityFormatted !== undefined) {
            return data.rpReservationCapacityFormatted;
         }

         return data.reservationCapacityFormatted;
      }
   }

   angular.module("com.vmware.vsphere.client.cluster")
         .service("resourceAllocationService", ResourceAllocationService);
}
