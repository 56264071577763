(function() {
   'use strict';

   angular.module('com.vmware.vsphere.client.vm')
      .service('CloneVmToTemplateService', CloneVmToTemplateService);

   CloneVmToTemplateService.$inject = [
      'vuiConstants',
      'vmProvisioningDatastorePageService',
      'vmProvisioningNameAndFolderPageService',
      'vmProvisioningFinishCloneVmPageService',
      'VmProvisioningComputeResourcePageService',
      'vmProvisioningVappPropertiesPageService'
   ];

   function CloneVmToTemplateService (
      vuiConstants,
      vmProvisioningDatastorePageService,
      vmProvisioningNameAndFolderPageService,
      vmProvisioningFinishCloneVmPageService,
      VmProvisioningComputeResourcePageService,
      vmProvisioningVappPropertiesPageService
   ) {
      return function() {
         this.buildPages = buildPages;

         function buildPages (wizardScope, defaultTarget, preselectedComputeResource) {
            var namePage = vmProvisioningNameAndFolderPageService.build(wizardScope, defaultTarget);
            var computeResourcePage = VmProvisioningComputeResourcePageService.build(wizardScope, preselectedComputeResource);
            var datastorePage = vmProvisioningDatastorePageService.build(wizardScope);
            var vappPropertiesPage = vmProvisioningVappPropertiesPageService.build(wizardScope);
            vappPropertiesPage.defaultState = vuiConstants.wizard.pageState.SKIPPED;
            var finishCloneVmPage = vmProvisioningFinishCloneVmPageService.build(wizardScope);

            if (wizardScope.vmParams.hasConfigurableVappProperties()) {
               vappPropertiesPage.defaultState = vuiConstants.wizard.pageState.DISABLED;
            }

            return {
               pages: [
                  namePage,
                  computeResourcePage,
                  datastorePage,
                  vappPropertiesPage,
                  finishCloneVmPage
               ]
            };
         }
      };
   }
})();
