namespace network_ui {

   import IController = angular.IController;
   import BindToPhysicalAdapterModel = network_ui.BindToPhysicalAdapterModel;
   import PnicBindingDataItem = com.vmware.vsphere.client.h5.network.host.wizard.addnetworking.model.PnicBindingDataItem;

   export class EditBindToPhysicalAdapterController implements IController {

      public static $inject = [
            "$scope",
            "i18nService",
            "bindToPhysicalAdapterService",
            "vuiConstants"];

      public i18n: Function;

      public bindToPnicModel: any;

      constructor(private $scope: any,
                  private i18nService: any,
                  private bindToPhysicalAdapterService: any,
                  private vuiConstants: any) {
         this.i18n = this.i18nService.getString.bind(this.i18nService, "NetworkUi");

         this.$scope.manager.vnicData.bindToPnicPageModel.onCommit = () => {
            let pinnedPhysicalAdapter =
                  this.$scope.manager.vnicData.bindToPnicPageModel.pinnedPnic;
            let pageModelData =
                  this.$scope.manager.vnicData.bindToPnicPageModel.componentData;

            if (pageModelData.bindToPnicEnabled && !pageModelData.selectedPnicForBinding) {
               return [{
                  type: this.vuiConstants.validationBanner.type.ERROR,
                  text: this.i18n("VnicToPnicBindingPage.error.noNic")
               }];
            }

            if (pageModelData.bindToPnicEnabled) {
               if(pageModelData.selectedPnicForBinding !== pinnedPhysicalAdapter) {
                  this.$scope.manager.vnicData.bindToPnicPageModel.dirty = true;
               }
            } else {
               if (pinnedPhysicalAdapter) {
                  this.$scope.manager.vnicData.bindToPnicPageModel.dirty = true;
               }
            }

            return [];
         };

         if(!this.$scope.manager.vnicData.bindToPnicPageModel.componentData) {
            let opaqueNetwork =
                  this.$scope.manager.vnicData.bindToPnicPageModel.opaqueNetworkSpec;
            let pinnedPnic = this.$scope.manager.vnicData.bindToPnicPageModel.pinnedPnic;

            let pageModel: BindToPhysicalAdapterModel = {
               hostId: this.$scope.manager.vnicData.hostId,
               opaqueNetworkId: opaqueNetwork.opaqueNetworkId,
               opaqueNetworkType: opaqueNetwork.opaqueNetworkType,
               bindToPnicEnabled: pinnedPnic !== null,
               selectedPnicForBinding: pinnedPnic,
               availablePnicsData: null,
               onPnicSelectionChange : (selectedPnic: string) => {
                  pageModel.selectedPnicForBinding = selectedPnic;
               },
               onBindToPnicChange: (bindToPnic: boolean) => {
                  pageModel.bindToPnicEnabled = bindToPnic;
               },
               onAvailablePnicsDataChange: (availablePnicsData: PnicBindingDataItem[] | null) => {
                  pageModel.availablePnicsData = availablePnicsData;
               },
               onLoadingStateUpdate: (loading: boolean) => {
                  this.$scope.dialogOptions.loading = loading;
               }
            };

            this.$scope.manager.vnicData.bindToPnicPageModel.componentData = pageModel;

            if (this.$scope.manager.vnicData.bindToPnicPageModel
                        .componentData.selectedPnicForBinding !== null) {
               this.$scope.dialogOptions.loading = true;

               this.bindToPhysicalAdapterService.getDataByOpaqueNetworkSpec(
                     this.$scope.manager.vnicData.hostId,
                     opaqueNetwork.opaqueNetworkId,
                     opaqueNetwork.opaqueNetworkType
               ).then((data: PnicBindingDataItem[]) => {
                  this.$scope.manager.vnicData.bindToPnicPageModel.componentData.availablePnicsData = data;
               }).finally(() => {
                  this.$scope.dialogOptions.loading = false;
               });
            }
         }

         this.bindToPnicModel = this.$scope.manager.vnicData.bindToPnicPageModel.componentData;
      }

   }

   angular.module('com.vmware.vsphere.client.network')
         .controller('EditBindToPhysicalAdapterController', EditBindToPhysicalAdapterController);
}