/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace network_ui {

   import IPromise = angular.IPromise;
   import HostStandardPortGroupInfo =
         com.vmware.vsphere.client.h5.network.host.migratevmkadapter.model.HostStandardPortGroupInfo;
   import DestinationStandardPortGroupInitialInfo =
         com.vmware.vsphere.client.h5.network.host.migratevmkadapter.model.DestinationStandardPortGroupInitialInfo;

   export class ConfigureSettingsComponent {

      public bindings: any;
      public templateUrl: string;
      public controller: any;

      constructor() {

         this.bindings = {
            model: "<"
         };
         this.templateUrl = "network-ui/resources/network/views/host/migratevmkadapter/" +
               "components/configureSettingsPageTemplate.html";
         this.controller = ConfigureSettingsController;
      }
   }

   class ConfigureSettingsController {

      static readonly HOST_STANDARD_PORT_GROUP_INFO_PROPERTY: string =
            "vmkmigratewizard:standardPortGroupInfo";

      public static $inject = [
            "dataService",
            "i18nService",
            "vxValidatorFactory",
            "networkLabelValidator"
      ];

      public i18n: Function;

      private model: MigrateVmkToVssWizardModel;

      private configSettingsLoading: boolean;

      private validator: any;

      private validateNetworkLabel: Function;

      constructor(private dataService: any,
                  private i18nService: any,
                  private vxValidatorFactory: any,
                  private networkLabelValidator: any) {

         this.i18n = this.i18nService.getString;
      }

      $onInit(): void {
         this.validator = this.vxValidatorFactory.create();

         this.validateNetworkLabel = this.newNetworkLabelValidator.bind(this);

         if (!this.model.newNetworkName || !this.model.vlanId || !this.model.vlanIds) {
            this.initSettingsFields();
         }
      }

      public initSettingsFields(): void {

         this.configSettingsLoading = true;

         let portGroupInfo: any = {};
         portGroupInfo.portGroupUniquePreffix = this.i18nService.getString(
               "NetworkUi", "AddNetworkingWizard.vmkernel");
         portGroupInfo.vmkDevice = this.model.selectedVmkAdapter;
         let destPortGroupInitialInfo: DestinationStandardPortGroupInitialInfo
               = new DestinationStandardPortGroupInitialInfo();

         let stanmdardPortGroupInfoPromise: IPromise<HostStandardPortGroupInfo>
               = this.dataService.getProperties(this.model.hostId,
               [ConfigureSettingsController.HOST_STANDARD_PORT_GROUP_INFO_PROPERTY], {
                  propertyParams: [
                     {
                        propertyName: ConfigureSettingsController.HOST_STANDARD_PORT_GROUP_INFO_PROPERTY,
                        parameterType: destPortGroupInitialInfo._type,
                        parameter: portGroupInfo
                     }
                  ]
               });

         stanmdardPortGroupInfoPromise
               .then((result: any): void => {

            if (result) {
               if (!this.model.newNetworkName) {
                  this.model.newNetworkName = result[ConfigureSettingsController
                        .HOST_STANDARD_PORT_GROUP_INFO_PROPERTY].networkLabel;
               }
               if (!this.model.vlanIds) {
                  this.model.vlanIds = result[ConfigureSettingsController
                        .HOST_STANDARD_PORT_GROUP_INFO_PROPERTY].vlanIds;
               }
               if (!this.model.vlanId) {
                  this.model.vlanId = result[ConfigureSettingsController
                        .HOST_STANDARD_PORT_GROUP_INFO_PROPERTY].sourcePortGroupVlanId;
               }
            }

            this.configSettingsLoading = false;
         });

      }

      private newNetworkLabelValidator(): string[] {
         let errorMessage: any[] = this.networkLabelValidator
               .getNetworkLabelClientSideValidationErrors(this.model.newNetworkName);

         if (errorMessage.length === 0) {
            errorMessage = this.networkLabelValidator
                  .getNetworkLabelServerSideValidationErrors(
                        this.model.newNetworkName, this.model.hostId);
         }

         return errorMessage;
      }
   }

   angular.module("com.vmware.vsphere.client.network")
         .component("configureSettingsPage", new ConfigureSettingsComponent());
}
