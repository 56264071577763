/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
(function () {
   'use strict';
   angular.module('com.vmware.vsphere.client.storage')
      .controller('DatastoreTypePageController', [
         '$scope', 'i18nService', 'datastoreConstants', 'wizardPageService',
         function ($scope, i18nService, datastoreConstants, wizardPageService) {
            $scope.wizardConfig.loading = true;

            var self = this;

            self.vmfsType = datastoreConstants.datastoreTypes.VMFS;
            self.vvolType = datastoreConstants.datastoreTypes.VVOL;
            self.isVvolSupported = true;
            showNfsV3RadioButton();

            $scope.commonWizardManager.getAvailableHosts().then(function(availableHosts) {
               if (atLeastOneHostSupportsNfsV41(availableHosts)) {
                  showNfsRadioButton();
               }
               if ($scope.commonWizardManager.isHostContextObject() && availableHosts.length === 1) {
                  self.isVvolSupported = availableHosts[0].isVvolSupported;
               }
            }).finally(function() {
               $scope.wizardConfig.loading = false;
            });

            self.selectDatastoreType = $scope.commonWizardManager.getDatastoreType();

            $scope.$watch('ctrl.selectDatastoreType', function(newType, oldType) {
               if (newType !== oldType) {
                  $scope.wizardConfig.currentPage.decisionOptions.selectedFlowId = newType;
                  $scope.commonWizardManager.setDatastoreType(newType);

                  wizardPageService.initializeFlowStates($scope.wizardConfig.flows);
               }
            });

            function showNfsV3RadioButton() {
               self.nfsType = datastoreConstants.datastoreTypes.NFS3;
               self.nfsTypeLabel = i18nService.getString('StorageUi','datastore.nfs.nfs');
            }

            function showNfsRadioButton() {
               self.nfsType = datastoreConstants.datastoreTypes.NFS;
               self.nfsTypeLabel = i18nService.getString('StorageUi','datastore.nfs');
            }

            function atLeastOneHostSupportsNfsV41(hostsList) {
               var hostsSupportedNfsV41 = hostsList.filter(function(item) {
                  return (item.isNfs4Supported !== undefined && item.isNfs4Supported === true);
               });

               return (hostsSupportedNfsV41.length > 0);
            }
         }
      ]);
})();
