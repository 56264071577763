namespace h5_vm {

   interface FlashCacheReservationInfo {
      vFlashSupportedOnHost: boolean;
      valueInMB: number;
      validUnits: Unit[];
   }

   class VmHardwareVirtualDiskFlashReadCacheController implements ng.IComponentController {
      static $inject: string[] = [
         'i18nService'
      ];

      private i18n: Function;
      private onChange: Function;
      isDisabled: boolean;
      public flashCacheReservationInfo: FlashCacheReservationInfo;

      constructor(private i18nService: any) {
         this.i18n = i18nService.getString;
      }

      onFlashCacheReservationChange() {
         this.onChange({
            flashCacheReservation: this.flashCacheReservationInfo.valueInMB
         });
      }

   }

   export class VmHardwareVirtualDiskFlashReadCache implements ng.IComponentOptions {

      bindings: any;
      controller: any;
      templateUrl: string;

      constructor() {
         this.bindings = {
            flashCacheReservationInfo: '<',
            isDisabled: '<',
            ariaLabel: '<',
            onChange: '&'
         };
         this.controller = VmHardwareVirtualDiskFlashReadCacheController;
         this.templateUrl = 'vm-ui/resources/vm/views/settings/vmHardwareSettings/vmHardwareVirtualDiskShared/' +
               'vmHardwareVirtualDiskFlashReadCache/vm-hardware-virtual-disk-flash-read-cache.html';
      }
   }

   angular.module('com.vmware.vsphere.client.vm')
         .component('vmHardwareVirtualDiskFlashReadCache', new VmHardwareVirtualDiskFlashReadCache());
}
