module network_ui {

   /**
    * List to show IPv6 address, prefix length and default gateway.
    * It supports add, edit and remove operations.
    *
    * @param {Ipv6ListData} listData
    *    The list config argument.
    *
    * @param {boolean} isEnabled
    *    Enables the component if set to true.
    */
   export class Ipv6ListComponent {

      public bindings: any;
      public controller: any;
      public templateUrl: string;

      constructor() {
         this.bindings = {
            listData: "<",
            isEnabled: "<"
         };

         this.controller = Ipv6ListComponentController;

         this.templateUrl = "network-ui/resources/network/views/host/common/" +
               "ipv6List/ipv6List.html";
      }
   }

   /**
    * Ipv6List component configuration data.
    */
   export class Ipv6ListData {
      /**
       * List items.
       */
      public ipv6List: Ipv6ListItem[];
      /**
       * Default gateway for all IPv6 addresses.
       */
      public defaultGateway: string;
      /**
       * All host IPv6 addresses.
       */
      public hostIpv6Addresses: any;
   }

   /**
    * Represents a single IPv6 list item.
    */
   export class Ipv6ListItem {
      public address: string;
      public prefixLength: number;
   }

   class Ipv6ListComponentController {
      static $inject = [
         "$scope",
         "vuiConstants",
         "i18nService",
         "networkUiConstants",
         "ipv6DialogService"];

      public listData: Ipv6ListData;
      public isEnabled: boolean;
      private datagridOptions: any;

      constructor(private $scope: any,
                  private vuiConstants: any,
                  private i18nService: any,
                  private networkUiConstants: any,
                  private ipv6DialogService: any) {
         this.datagridOptions = this.createDatagridOptions();
      }

      public $onInit() {

         //watch selected items in order to update action bar
         this.$scope.$watch(() => {
            return this.datagridOptions.selectedItems;
         }, (selectedItems: any[]) => {
            this.updateActionBar(selectedItems.length);
         });
      }

      public $onChanges() {
         if (this.datagridOptions && this.listData) {
            this.datagridOptions.data = this.listData.ipv6List || [];
            this.updateActionBar(this.datagridOptions.selectedItems.length);
         }
      }

      private createDatagridOptions() {
         return {
            height: "100%",
            pageConfig: {
               hidePager: true
            },
            columnDefs: this.initializeColumnDefs(),
            selectionMode: this.vuiConstants.grid.selectionMode.SINGLE,
            data: [],
            selectedItems: [],
            actionBarOptions: {
               actions: this.initializeActions()
            }
         };
      }

      private initializeColumnDefs(): any[] {
         return [
            {
               displayName: this.i18nService.getString("NetworkUi", "VnicIpv6SettingsPage.ipv6Address"),
               field: "address",
               template: "#:address#"
            },
            {
               displayName: this.i18nService.getString("NetworkUi", "VnicIpv6SettingsPage.prefixLength"),
               field: "prefixLength",
               template: "#:prefixLength#"
            }];
      }

      private initializeActions(): any[] {
         return [
            {
               actionId: this.networkUiConstants.DatagridActions.ADD,
               enabled: this.isEnabled,
               visible: true,
               iconClass: "vsphere-icon-add",
               onClick: this.openAddIpv6Dialog.bind(this)
            },
            this.vuiConstants.actions.SEPARATOR,
            {
               actionId: this.networkUiConstants.DatagridActions.EDIT,
               enabled: false,
               visible: true,
               iconClass: "vsphere-icon-toolbar-edit",
               onClick: this.openEditIpv6Dialog.bind(this)
            },
            {
               actionId: this.networkUiConstants.DatagridActions.DELETE,
               enabled: false,
               visible: true,
               iconClass: "vsphere-icon-toolbar-remove",
               onClick: this.removeSelectedItem.bind(this)
            }
         ];

      }

      private updateActionBar(selectedItemsLength: number) {
         _.each(this.datagridOptions.actionBarOptions.actions, (action: any) => {

            if (action.actionId === this.networkUiConstants.DatagridActions.EDIT ||
                  action.actionId === this.networkUiConstants.DatagridActions.DELETE) {
               action.enabled = !!this.isEnabled && selectedItemsLength > 0;
            } else if (action.actionId === this.networkUiConstants.DatagridActions.ADD) {
               action.enabled = !!this.isEnabled;
            }
         });
      }

      private removeSelectedItem() {
         let selectedItemIndex = this.indexOfSelectedAddress();

         this.listData.ipv6List.splice(selectedItemIndex, 1);
         this.datagridOptions.data = this.listData.ipv6List.slice();
      }

      private openAddIpv6Dialog() {
         let ipv6Details: any = {};
         ipv6Details.defaultGateway = this.listData.defaultGateway;

         this.ipv6DialogService.openIpv6AddressesDialog(
               this.i18nService.getString("NetworkUi", "Ipv6AddressPage.addTitle"),
               ipv6Details,
               this.createUsedAddressInfo(),
               this.addOkHandler.bind(this));
      }

      private openEditIpv6Dialog() {
         let ipv6Details: any = {};
         ipv6Details.address = this.datagridOptions.selectedItems[0].address;
         ipv6Details.prefixLength = this.datagridOptions.selectedItems[0].prefixLength;
         ipv6Details.defaultGateway = this.listData.defaultGateway;

         this.ipv6DialogService.openIpv6AddressesDialog(
               this.i18nService.getString("NetworkUi", "Ipv6AddressPage.editTitle"),
               ipv6Details,
               this.createUsedAddressInfo(),
               this.editOkHandler.bind(this));
      }

      private addOkHandler(ipv6Address: any) {
         let newAddress: Ipv6ListItem = new Ipv6ListItem();
         newAddress.address = ipv6Address.address;
         newAddress.prefixLength = ipv6Address.prefixLength;

         this.listData.ipv6List.push(newAddress);
         // Clone the array in order to trigger grid refresh
         this.datagridOptions.data = this.listData.ipv6List.slice();
      }

      private editOkHandler(ipv6Address: any) {
         let selectedItemIndex = this.indexOfSelectedAddress();

         this.listData.ipv6List[selectedItemIndex].address = ipv6Address.address;
         this.listData.ipv6List[selectedItemIndex].prefixLength = ipv6Address.prefixLength;

         this.datagridOptions.data = this.listData.ipv6List.slice();
      }

      private indexOfSelectedAddress() {
         let index = -1;
         if (this.datagridOptions.selectedItems &&
               this.datagridOptions.selectedItems.length === 1) {

            let selectedAddress = this.datagridOptions.selectedItems[0];
            _.forEach(this.listData.ipv6List, (address, addressIndex) => {
               if (address.address === selectedAddress.address) {
                  index = addressIndex;
               }
            });
         }

         return index;
      }

      private createUsedAddressInfo() {
         let usedAddressInfo: any = {};
         usedAddressInfo.hostAddresses = this.listData.hostIpv6Addresses;
         usedAddressInfo.vmknicAddresses = this.getVmnicAddressesInUse();

         return usedAddressInfo;
      }

      private getVmnicAddressesInUse() {
         return _.map(this.listData.ipv6List, (ipv6Address) => {
            return ipv6Address.address;
         });
      }
   }

   angular
         .module("com.vmware.vsphere.client.network")
         .component("ipv6List", new Ipv6ListComponent());
}
