/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */

(function() {
   'use strict';

   angular
         .module('com.vmware.vsphere.client.vm')
         .controller('VmProvisioningUserSettingsController', VmProvisioningUserSettingsController);

   VmProvisioningUserSettingsController.$inject = ['$scope', '$element', '$timeout', 'ipParserService', 'i18nService',
   'nicValidatorService'];

   function VmProvisioningUserSettingsController($scope, $element, $timeout, ipParserService, i18nService,
         nicValidatorService) {
      $scope.userSettingsPageModel.resetForm();
      $timeout(function() {
         var firstInput = $element[0].querySelector('input');
         if (firstInput) {
            firstInput.focus();
         }
      }, 0);
      this.form = $scope.userSettingsPageModel.form;

      this.validateComputerName = $scope.userSettingsPageModel.validateComputerName;

      this.validateIpAddress = function(nic) {
         nic.errors.addressInvalid = nicValidatorService.isIpAddressInvalid(nic);
      };
      this.validateSubnet = function(nic) {
         nic.errors.subnetMaskInvalid = nicValidatorService.isSubnetMaskInvalid(nic);
      };

      this.validateDefaultGateway = function(nic) {
         nic.errors.defaultGatewayInvalid = nicValidatorService.isDefaultGatewayInvalid(nic);
      };
      this.validateAlternateGateway = function(nic) {
         nic.errors.alternateGatewayInvalid = nicValidatorService.isAlternateGatewayInvalid(nic);
      };

      this.validateIp6Address = function(nic) {
         nic.errors.addressInvalid = nicValidatorService.isIp6AddressInvalid(nic);
      };
      this.validateIp6Subnet = function(nic) {
         nic.errors.subnetPrefixLengthInvalid = nicValidatorService.isIp6SubnetInvalid(nic);
      };

      this.addGateway = function(nic) {
         this.validateGateway(nic.lastGateway, nic);
         if (!nic.lastGateway || nic.gatewayInvalid) {
            return;
         }

         nic.gateway.push(nic.lastGateway);
         nic.gridGatewaysOptions.data = nic.gateway;

         nic.lastGateway = "";
      };

      this.validateGateway = function(gateway, nic) {
         if (!gateway) {
            nic.gatewayInvalid = null;
            return;
         }
         var isValid = ipParserService.isIpv6AddressValid(gateway);
         var isDuplicate = _.any(nic.gateway, function(currentGateway) {
            return currentGateway === gateway;
         });

         if (!isValid) {
            nic.gatewayInvalid =
                  i18nService.getString("VmUi", "CustomizationParametersPageBase.nic.error.ipv6.address");
         }

         if (isDuplicate) {
            nic.gatewayInvalid =
                  i18nService.getString("VmUi", "CustomizationParametersPageBase.nic.error.ipv6.duplicate", gateway);
         }

         if (isValid && !isDuplicate) {
            nic.gatewayInvalid = null;
         }
      };

      this.expandedChanged = function(expanded, nic) {
         nic.expanded = expanded;
         if (expanded) {
             this.form.nics.forEach(function(item) {
                if (item !== nic) {
                    item.expanded = false;
                    $scope.userSettingsPageModel.hasAlerts(item);
                }
             });
         }
         $scope.userSettingsPageModel.hasAlerts(nic);
      };

      this.shouldExpand = function(index) {
         if ((index === 0 && this.form.nics && this.form.nics.length === 1)) {
            return true;
         }

         if (this.form.nics[index] && this.form.nics[index].expanded) {
            return true;
         }

         return false;
      };

      this.tooltipRole = function(hasTooltip) {
         return hasTooltip ? 'tooltip' : '';
      };
   }
})();
