namespace h5_client {
    export module common_module_ui {

        import IPromise = angular.IPromise;

        export class ModifyTagComponent {
            public bindings: any;
            public controller: any;
            public templateUrl: any;
            public onSubmit: Function;

            public constructor() {
                this.bindings = {
                    modifyTagData: "<",
                    categoryData: "<",
                    submitDisabled: "=",
                    onSubmit: "="
                };
                this.controller = ModifyTagController;
                this.templateUrl = "common-module-ui/resources/common-module/views/tags/modify-tag-component.html";
            }
        }

        class ModifyTagController {
            public static $inject = ["i18nService", "$rootScope", "categoryService", "actionsService"];

            private readonly CREATE_CATEGORY_ACTION_ID = "vsphere.core.common.actions.addCategory";

            private i18n: Function;
            private modifyTagData: ModifyTagData;
            private categoryData: any;
            private submitDisabled: boolean;
            private onSubmit: Function;
            private onSubmitCaller: Function;
            private categories: any[];
            private validity: string;
            private errorMessage: string;
            private unModifiedName: string;

            private createTagCategoryPromise: any;
            private createTagCategoryPromiseResolved: boolean;


            public constructor(
                    private i18nService: any,
                    private $rootScope: any,
                    private categoryService: any,
                    private actionsService: any) {
            }

            public $onInit() {
                this.validity = "valid";
                this.errorMessage = "";
                this.unModifiedName = angular.copy(this.modifyTagData.name);

                this.createTagCategoryPromiseResolved = false;
                this.i18n = (key: any) => this.i18nService.getString("CommonModuleUi", key);


                this.onSubmitCaller = this.onSubmit;
                this.onSubmit = () => {
                    return this.onSubmitFunction();
                };

                this.disableSubmit();

                if (this.modifyTagData.mode === ModifyTagMode.ADD) {
                    this.populateCategorySelect();
                    this.createTagCategoryPromise = this.actionsService
                        .getAction(this.CREATE_CATEGORY_ACTION_ID)
                        .then((result: any) => {
                            if (result) {
                                this.createTagCategoryPromiseResolved = true;
                                return result;
                            }
                        });
                }

                this.$rootScope.$on("refreshCategories", () => {
                    this.populateCategorySelect();
                });
            }

            private disableSubmit(): void {
                if (this.modifyTagData.mode === ModifyTagMode.ADD) {
                    this.submitDisabled = !this.modifyTagData.name || !this.categoryData.selectedCategory;
                } else {
                    this.submitDisabled = !this.modifyTagData.name;
                }
            }

            private populateCategorySelect(): void {
                this.categoryService.getAll()
                    .then((categories: any[]) => {
                        if (categories && categories.length) {
                            this.categoryData.selectedCategory = categories[0];
                            this.categories = categories;
                        }
                        this.disableSubmit();
                    });
            }

            private isCategoryAvailable(): boolean {
                return this.modifyTagData.mode === ModifyTagMode.EDIT || !this.createTagCategoryPromiseResolved;
            }

            private addCategory(): void {
                this.createTagCategoryPromise
                        .then((actionEval: any) => {
                            let context: any = {
                                title: this.i18n("add.category"),
                                categoryState: {}
                            };
                            // TODO: Instead of broadcasting 'refreshCategories' and binding to it
                            // a deferred promise could be passed to the context object of the invokeAction.
                            // Once the category is created, the promise should be resolved.
                            // In this case, the handling (.then) of this promise comes here
                            // instead of this.$rootScope.$on('refreshCategories', fn).
                            //
                            // It would also be good to modify the back-end controllers to return the objects
                            // they created (tags/categories) on success.
                            this.actionsService.invokeAction(actionEval, context);
                        });
            }

            private onSubmitFunction(): any {
                return this.onSubmitCaller().then((result: any) => {
                    if (result.error && result.error.message && result.error.message.indexOf('AlreadyExistsFault') >= 0) {
                        this.displayDuplicateError();
                        return false;
                    } else {
                        return true;
                    }
                });
            }


            private displayDuplicateError(): void {
                let categoryName: string = this.modifyTagData.mode === ModifyTagMode.ADD ?
                    this.categoryData.selectedCategory.categoryName :
                    this.modifyTagData.categoryName;

                this.validity = "invalid";
                this.errorMessage = this.i18nService.getString(
                    "Common",
                    "tag.create.form.error.tag.duplicate",
                    categoryName
                );
            }
        }

        export interface ModifyTagData {
            name: string;
            description: string;
            mode: ModifyTagMode;
            categoryName?: string;
        }

        export enum ModifyTagMode {
            ADD,
            EDIT
        }

        angular.module("com.vmware.vsphere.client.commonModule")
            .component("modifyTagComponent", new ModifyTagComponent());
    }
}
