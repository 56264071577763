module cluster_ui {

   import ValidationResult = com.vmware.vise.core.model.ValidationResult;

   class MoveClusterToDialogController {

      public entityToMoveClusterTo: string;
      private clusterIds: Array<string>;
      private onLocationSelected: Function;
      private loading: boolean;
      private dc: string;

      public static $inject = ["$scope",
         "vuiConstants",
         "i18nService",
         "moveClusterToService", "dataService", "defaultUriSchemeUtil"];

      constructor(private $scope: any,
                  private vuiConstants: any,
                  private i18nService: any,
                  private moveClusterToService: any, private dataService: any, private defaultUriSchemeUtil: any) {
         this.loading = false;
         this.clusterIds = $scope.modalOptions.dialogData.objectIds;
         this.dc = $scope.modalOptions.dialogData.dc;

         this.onLocationSelected = (objectReference: any) => {
            this.entityToMoveClusterTo = objectReference;
         };

         $scope.modalOptions.onSubmit = () => {
            $scope.modalOptions.alerts = [];
            $scope.modalOptions.isAlertClosed = true;

            this.moveClusterToService
                  .validateMoveClusterToTarget(this.entityToMoveClusterTo, this.clusterIds)
                  .then((result: ValidationResult) => {
                     if (!!result.result) {

                        this.moveClusterToService.checkPrivileges(
                              this.clusterIds,
                              this.entityToMoveClusterTo
                        ).then((noPrivilegesIds: Array<string>) => {
                           if (!noPrivilegesIds || noPrivilegesIds.length === 0) {
                              // if objects have privileges
                              this.moveClusterToService.moveCluster(
                                    this.entityToMoveClusterTo,
                                    this.clusterIds
                              );
                           } else {
                              // no privileges error
                              this.$scope.modalOptions.alerts = [{
                                 type: this.vuiConstants.validationBanner.type.ERROR,
                                 text: this.moveClusterToService
                                       .getPrivilegesErrorMessage(
                                             noPrivilegesIds,
                                             this.entityToMoveClusterTo
                                       )
                              }];
                              this.$scope.modalOptions.isAlertClosed = false;
                           }
                        });
                        this.$scope.closeModal();
                     } else {
                        this.$scope.modalOptions.alerts = [{
                           type: this.vuiConstants.validationBanner.type.ERROR,
                           text: this.i18nService.getString("ClusterUi",
                                 "moveTo.targetNestedValidationError")
                        }];
                        this.$scope.modalOptions.isAlertClosed = false;
                     }
                  });
         };
      }
   }

   angular.module("com.vmware.vsphere.client.cluster")
         .controller("moveClusterToDialogController", MoveClusterToDialogController);
}
