angular.module('com.vmware.vsphere.client.vm').service('vmProvisioningNameAndFolderPageService', [
   'i18nService',
   'dataService',
   '$q',
   'defaultUriSchemeUtil',
   'wizardPageService',
   'VmProvisioningNameAndFolderPageModel',
   'vmVuiWizardPageBuilder',
   function (i18nService,
             dataService,
             $q,
             defaultUriSchemeUtil,
             wizardPageService,
             VmProvisioningNameAndFolderPageModel,
             vmVuiWizardPageBuilder
   ) {
      return {
         build: function (wizardScope, defaultTarget) {
            var nameAndFolderPageModel = new VmProvisioningNameAndFolderPageModel(wizardScope, wizardScope.vmParams, defaultTarget);
            wizardScope.nameAndFolderPageModel = nameAndFolderPageModel;

            return vmVuiWizardPageBuilder.buildVuiWizardPage(wizardScope.config, {
               title: i18nService.getString('VmUi', 'SelectNameFolderProvisioningPage.Title'),
               description: i18nService.getString('VmUi', 'SelectNameFolderProvisioningPage.Description'),
               contentUrl: 'vm-ui/resources/vm/views/createVmWizard/vmProvisioningNameAndFolder.html',
               model: nameAndFolderPageModel
            });
         }
      };
   }]);
