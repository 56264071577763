angular.module('com.vmware.vsphere.client.vm').directive('vmHardwareSummaryHwVersion', ['i18nService',
   'vmHardwareVersionService', function (i18nService, vmHardwareVersionService) {
   return {
      templateUrl: 'vm-ui/resources/vm/views/summary/vmHardwareSummaryHwVersion.html',
      scope: {
         ctrl: '='
      },
      link: function(scope) {
         scope.vmHwVersionLabel = i18nService.getString('VmUi', 'VmHardwareView.HWVersion');

         scope.$watch('ctrl.hardwareViewData', function(hwData) {
            var vmVersion = hwData.vmVersion;
            scope.vmHwVersion = vmHardwareVersionService.getLongText(vmVersion);

            var scheduledUpgradeData = vmHardwareVersionService.getScheduledUpgradeData(hwData);
            scope.hasScheduledUpgrade = !!scheduledUpgradeData.status;
            scope.scheduledUpgradeStatus = scheduledUpgradeData.status;
            scope.scheduledUpgradeError = scheduledUpgradeData.error;
         });
      }
   };
}]);
