namespace h5_vm {
   import IController = angular.IComponentController;

   class CloneVmLocationController implements IController {

      static $inject: string[] = [ "$scope" ];

      public locationData: any;
      public isOvfWorkflow: boolean = true;

      constructor(private $scope: any) {
         this.locationData = {
            vmId: $scope.wizardViewData.getSourceObjectId(),
            selectedLibraryId: this.$scope.cloneVmLocationPageModel.contentLibrary &&
                    this.$scope.cloneVmLocationPageModel.contentLibrary.id
         };

         this.isOvfWorkflow =
               ($scope.cloneVmBasicInformationPageModel.basicInformation.templateType === TemplateType.OVF_TEMPLATE);
      }

      public onSelectedLibraryChange(selectedLibrary: any) {
         this.$scope.vuiWizard.validationBanner.messages = [];
         this.$scope.cloneVmLocationPageModel.contentLibrary = selectedLibrary;
      }
   }

   angular.module("com.vmware.vsphere.client.vm")
         .controller("cloneVmLocationController", CloneVmLocationController);
}
