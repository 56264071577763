/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace network_lib_ui {

   export class DvsPropertiesViewComponent {

      public bindings: any;
      public controller: any;
      public templateUrl: string;

      constructor() {
         this.bindings = {
            dvsUri: "<"
         };
         this.controller = DvsPropertiesViewController;
         this.templateUrl = "network-lib-ui/resources/network-lib/components/dvsProperties/dvsPropertiesView.html";
      }
   }

   class DvsPropertiesViewController {

      static $inject = [
         "dataService",
         "dvsDetailsViewService"
      ];

      static CONFIG_DATA_PROPERTY_NAME = "dvswitchproperties:configdata";

      public dvsDetailsData: any;

      public loadingData: boolean;

      public dvsUri: string;

      public readonly liveRefreshProperties: any = [
         "name",
         "config.productInfo.version",
         "config.contact.name",
         "config.contact.contact",
         "config.linkDiscoveryProtocolConfig.protocol",
         "config.linkDiscoveryProtocolConfig.operation",
         "config.name",
         "config.description",
         "config.multicastFilteringMode",
         "config.maxMtu",
         "config.defaultProxySwitchMaxNumPorts",
         "config.numPorts",
         "config.uplinkPortPolicy.uplinkPortName",
         "config.networkResourceManagementEnabled"
      ];

      constructor(private dataService: any,
                  private dvsDetailsViewService: any) {
      }

      public $onChanges() {
         this.refresh();
      }

      public refresh() {

         if (!this.dvsUri) {
            return;
         }

         this.loadingData = true;

         this.dataService.getProperties(this.dvsUri,
            [DvsPropertiesViewController.CONFIG_DATA_PROPERTY_NAME])
               .then((dataServiceResponse: any) => {
                  let dvsConfigDetailsData = dataServiceResponse
                        ? dataServiceResponse[DvsPropertiesViewController.CONFIG_DATA_PROPERTY_NAME]
                        : null;
                  if (dvsConfigDetailsData && dvsConfigDetailsData.name) {
                     let trimmedName: string = dvsConfigDetailsData.name.trim();
                     let escapedName: string = trimmedName.replace(/%25/g, "%")
                           .replace(/%2f/g, "\/").replace(/%5c/g, "\\");

                     dvsConfigDetailsData.name = escapedName;
                  }

                  this.dvsDetailsData = this.dvsDetailsViewService
                        .build(dvsConfigDetailsData, false);

                  this.loadingData = false;
               });
      }
   }

   angular.module("com.vmware.vsphere.client.networkLibUi")
         .component("dvsPropertiesView", new DvsPropertiesViewComponent());
}
