namespace h5_vm {
   import IQService = angular.IQService;
   import VmHardwareBaseService = h5_vm.VmHardwareBaseService;
   import ManagedObjectReference = com.vmware.vim.binding.vmodl.ManagedObjectReference;
   import VmConfigContext = com.vmware.vsphere.client.vm.config.VmConfigContext;
   import IPromise = angular.IPromise;

   export class VmHardwareEditService extends VmHardwareBaseService {
      constructor(private $rootScope: any,
                  private vmFactoryService: any,
                  private mutationService: any,
                  private storageLocatorService: any,
                  private vcH5ConstantsService: any,
                  vmHardwareUtil: any,
                  dataService: any,
                  vmNetworkService: any,
                  defaultUriSchemeUtil: any,
                  storageProfileService: any,
                  $q: IQService,
                  vmDeviceInfoService: any,
                  VirtualMachineDevices: any
      ) {
         super(vmHardwareUtil,
               dataService,
               vmNetworkService,
               defaultUriSchemeUtil,
               storageProfileService,
               $q,
               vmDeviceInfoService,
               VirtualMachineDevices);
      }

      static $inject: string[] = [
         '$rootScope',
         'vmFactoryService',
         'mutationService',
         'storageLocatorService',
         'vcH5ConstantsService',
         'vmHardwareUtil',
         'dataService',
         'vmNetworkService',
         'defaultUriSchemeUtil',
         'storageProfileService',
         '$q',
         'vmDeviceInfoService',
         'VirtualMachineDevices'
      ];

      private getHost(ctx:VmConfigContext) {
         let rtHostId = this.defaultUriSchemeUtil.getVsphereObjectId(ctx.rtInfo.host);
         return rtHostId;
      }

      private fetchProfiles(vmId: string): ng.IPromise<any> {
         let serverGuid = this.defaultUriSchemeUtil.getManagedObjectReference(vmId).serverGuid;
         return this.storageProfileService.fetchProfiles(serverGuid);
      }

      private fetchAssignments(vmId: string): ng.IPromise<any> {
         const names: string[] = ["vmStorageProfileAssignments"];
         return this.dataService.getProperties(vmId, names);
      }
      private fetchRgroups(vmId: string): ng.IPromise<any> {
         const names: string[] = ["vmReplicationGroupAssignments"];
         return this.dataService.getProperties(vmId, names);
      }

      private getVmConfigContext(vmId: string): IPromise<any> {
         const  CTX = "vmConfigContext";
         let promise: IPromise<any> = this.dataService.getProperties(vmId, [CTX]);

         const retVal:IPromise<any> = promise.then((properties:any) => {
            let ctx = properties[CTX];
            let data = {hardwareDataResult: {vmConfigContext: ctx}};
            return super.useData(vmId, data);
         });

         return retVal;
      }

      public getAndFormatData(vmId: string): ng.IPromise<any> {
         const cfgContext:ng.IPromise<any> = this.$q.when(this.getVmConfigContext(vmId));

         const retVal: ng.IPromise<any> = cfgContext.then((data: any): ng.IPromise<any> => {
            const profiles:ng.IPromise<any> = this.fetchProfiles(vmId);
            const assignments:ng.IPromise<any> = this.fetchAssignments(vmId);
            const rgroups:ng.IPromise<any> = this.fetchRgroups(vmId);
            const retVal: ng.IPromise<any> = this.postProcess(data, profiles, assignments, rgroups);
            return retVal;
         });

         return retVal;
      };

      private postProcess(data:HardwareData, profiles:ng.IPromise<any>, assignments:ng.IPromise<any>, rgroups:ng.IPromise<any>) {
         const host:ManagedObjectReference = data.vmConfigContext.rtInfo.host;
         (<any>data).host = host;
         data.filterNetworksObjId = this.defaultUriSchemeUtil.getVsphereObjectId(host);
         data.virtualMachineDevices = super.buildVirtualDevices(data);
         data.storageProfiles = profiles;
         data.vmStorageProfileAssignments = assignments;
         (<any> data).vmReplicationGroupAssignments = rgroups;
         let promises = {hardwareConfigPromise: this.$q.when(data)};
         return this.$q.all(promises);
      };

      getStorageLocatorItems(rtHostId: string): ng.IPromise<any> {
         return this.storageLocatorService
               .getStorageLocatorData(rtHostId, rtHostId);
      }

      getVmNetworkInfo(hostRef: ManagedObjectReference,
                       hardwareConfigData: any): ng.IPromise<any> {
         var networkProviderId = this.defaultUriSchemeUtil
               .getVsphereObjectId(hostRef);
         var vmNetworkDevices =
               hardwareConfigData.virtualMachineDevices
                     .existingDevicesOfType(VmHardwareBaseService.VIRTUAL_ETHERNET_CARD);
         return super.getVmNetworkInfo(networkProviderId, vmNetworkDevices);
      }

      update(vmId: string, operation: any, originalVmConfig: any,
             vmConfigContextConfig: any, virtualMachineDevices: any,
             cpuFeatureMask: any, vmProfile: any) {
         var deviceChanges = virtualMachineDevices.getFinalChanges();

         var mutationOptions: any = {
            originalConfig: originalVmConfig,
            config: vmConfigContextConfig,
            deviceChange: deviceChanges,
            cpuFeatureMask: cpuFeatureMask,
            npivWwnOp: vmConfigContextConfig.npivWwnOp
         };

         if (vmProfile) {
            mutationOptions.profile = vmProfile;
         }

         this.mutationService.apply(
               vmId, 'com.vmware.vsphere.client.vm.VmConfigSpec', mutationOptions, operation);
         this.broadcastEditSaved(vmId);
      };

      applyRecommendationAndUpdate(vmId: string, recommendationKeys: any,
                                   operation: any) {
         var vmRecommendationSpec = {
            recommendations: recommendationKeys
         };
         this.mutationService.apply(vmId, 'com.vmware.vsphere.client.vm.storageDrs.VmRecommendationsSpec', vmRecommendationSpec, operation);
         this.broadcastEditSaved(vmId);
      };

      broadcastEditSaved(vmId: string) {
         this.$rootScope.$broadcast(this.vcH5ConstantsService.VM_EDIT_SAVED ,{
            vmId: vmId
         });
      };
   }

   angular.module('com.vmware.vsphere.client.vm')
         .service('vmHardwareEditService', VmHardwareEditService);
}
