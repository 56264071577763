(function() {
   /**
    * Directive for VM and host member listing and selection.
    */
   'use strict';
   angular.module('com.vmware.vsphere.client.cluster').directive(
         'groupMembersManager', groupMembersManager);

   groupMembersManager.$inject = ['$rootScope', 'vuiConstants',
         'datagridActionBarService', '$q'];

   function groupMembersManager($rootScope, vuiConstants, datagridActionBarService, $q) {

      return {
         restrict: 'E',
         templateUrl: 'cluster-ui/resources/cluster/views/settings/configuration/groups/groupMembersManager.html',
         scope: {
            objects: '=',
            objectType: '=',
            addAction: '=',
            removeAction: '=',
            gridTitle: '='
         },
         controller: ['$scope', function($scope) {
            var objectId = $rootScope._route.objectId;
            var selectedObjects = [];
            var actionSpecs = [{
               actionId: 'vsphere.core.cluster.actions.group.members.add',
               getActionInvocationContext: function() {
                  return {
                     memberObjects: $scope.objects,
                     memberObjectsType: $scope.objectType,
                     addMembersCallback: function(members) {
                        return $scope.addAction(members);
                     }
                  };
               },
               isActionAvailable: function(actionDef) {
                  return actionDef.available && !!$scope.objects;
               }
            }, {
               actionId: 'vsphere.core.cluster.actions.group.members.remove',
               getActionInvocationContext: function() {
                  return {
                     removeMembersCallback: function() {
                        return $scope.removeAction(selectedObjects);
                     }
                  };
               },
               isActionAvailable: function(actionDef) {
                  return actionDef.available && $scope.gridOptions.selectedItems.length > 0;
               }
            }];
            var datagridActionBarServiceCacheObject = null;

            $scope.gridOptions = getGridOptions();

            $scope.$watch('gridOptions.selectedItems', onSelectionChange);
            $scope.$watchCollection('objects', function(objects) {
               if (objects) {
                  $scope.gridOptions.data = objects;
               } else {
                  $scope.gridOptions.data = [];
               }

               refreshActionBar();
            });

            createActionBar();

            function createActionBar() {
               datagridActionBarService
                     .updateActionBar($scope.gridOptions, [objectId], actionSpecs)
                     .then(function(value) {
                        datagridActionBarServiceCacheObject = value;
                     });
            }

            function refreshActionBar() {
               if (!datagridActionBarServiceCacheObject) {
                  return;
               }

               datagridActionBarService.updateActionBar(
                     $scope.gridOptions, [objectId], actionSpecs, datagridActionBarServiceCacheObject
               );
            }

            function getGridOptions() {
               var options = {
                  actionBarOptions: {
                     actions: []
                  },
                  pageConfig: {
                     hidePager: true
                  },
                  searchable: false,
                  resizable: false,
                  data: [],
                  columnDefs: [
                     {
                        field: 'name',
                        type: 'string',
                        template: '<span class="object" title="#:name#"><i class="#:icon#")></i>#:name#</span>'
                     }
                  ],
                  selectionMode: vuiConstants.grid.selectionMode.MULTI,
                  showCheckboxesOnMultiSelection: false,
                  selectedItems: [],
                  height: '100%'
               };

               return options;
            }

            function onSelectionChange(newSelectedObjects) {
               if (!newSelectedObjects || newSelectedObjects.length === 0) {
                  selectedObjects = [];
               } else {
                  selectedObjects = newSelectedObjects;
               }

               refreshActionBar();
            }
         }],
         link: function(scope, element) {
            var gridReadyPromise = $q.defer();

            scope.$on('kendoWidgetCreated', function (event, widget) {
               if (widget && widget.constructor === kendo.ui.Grid) {
                  gridReadyPromise.resolve(widget);
               }
            });

            scope.$watch('gridTitle', function (gridTitle) {
               if (gridTitle && gridTitle.trim().length > 0) {
                  gridReadyPromise.promise.then(function (widget) {
                     var options = widget.getOptions();
                     options.columns[0].title = gridTitle;
                     widget.setOptions(options);

                     scope.$evalAsync(function() {
                        kendo.resize(element);
                     });
                  });
               }
            });
         }
      };
   }
})();
