namespace h5_vm {

   import GosCommandsPageModel = h5_vm.GosCommandsPageModel;

   export class GosCommandsPageController {
      public static $inject = [
         "i18nService",
         "$q"];

      model: GosCommandsPageModel;

      constructor(public i18nService: any,
                  private $q: any) {
      }

      $onInit(): void {
         this.model.onPageCommit = this.onCommit.bind(this);
      }

      $onDestroy(): void {
         this.model.onPageCommit = undefined;
      }

      onCommit(): angular.IPromise<Array<string>> {
         return this.$q.resolve([]);
      }


   }

   export class GosCommandsPageComponent implements angular.IComponentOptions {
      public controller: any;
      public templateUrl: string;
      public bindings: any;

      constructor() {
         this.controller = GosCommandsPageController;
         this.templateUrl =
            "vm-ui/resources/vm/guest-os-customization/pages/commands/gos-commands-page.component.html";
         this.bindings = {
            model: "<"
         };
      }
   }

   angular
      .module("com.vmware.vsphere.client.vm")
      .component("gosCommandsPage", new GosCommandsPageComponent());
}
