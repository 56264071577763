/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
angular.module('com.vmware.vsphere.client.licenseLibUi')
      .constant('licenseConstants', {
         LICENSE_TYPE: 'License',
         EVALUATION_LICENSE_ID: 'EVALUATION_LICENSE_ID',
         LICENSE_EXPIRATION_MAX_WARNING_DAYS_REMAINING: 60,
         LicenseAssignRestriction: {
            ExpiredLicense: 'ExpiredLicense',
            EmbeddedOemLicense: 'EmbeddedOemLicense'
         },
         LimitEnforcementType: {
            NOT_ENFORCED: 'NOT_ENFORCED',
            SOFT_ENFORCED: 'SOFT_ENFORCED',
            HARD_ENFORCED: 'HARD_ENFORCED'
         },
         RESPONSE_FAULT: {
            LICENSING_METHOD_FAULT: "com.vmware.license.client.cis.util.LicensingMethodFault"
         }

      });
