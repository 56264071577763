namespace h5_vm {

   import StoragePlacementResult = com.vmware.vim.binding.vim.storageDrs.StoragePlacementResult;
   import PmemUtilService = h5_vm.PmemUtilService;
   import VirtualDisk = com.vmware.vim.binding.vim.vm.device.VirtualDisk;

   export class VmEditSettingsDatastoreRecommendationService {

      public static $inject: string[] = [
         '$q',
         'dataServiceUtil',
         'defaultUriSchemeUtil',
         'podSelectionSpecService',
         'datastoreRecommendationService',
         'managedEntityConstants',
         'pmemUtilService'];

      constructor(private $q: any,
                  private dataServiceUtil: any,
                  private defaultUriSchemeUtil: any,
                  private podSelectionSpecService: any,
                  private datastoreRecommendationService: any,
                  private managedEntityConstants: any,
                  private pmemUtilService: PmemUtilService) {
      }

      private storageInfoMapForDisksWithBacking: any = {};
      private newDisksWithEmptyStorage: any = [];
      private newDisks :any;

      public shouldCheckRecommendations(vmxStorageObject: any, virtualMachineDevices: any): boolean{
         this.buildStorageInfoMap(virtualMachineDevices);
         let diskNeedRecommendation = _.find(this.newDisks, (disk: any) => {
            if(this.storageInfoMapForDisksWithBacking && this.storageInfoMapForDisksWithBacking[disk.key]){
               let dsObject = this.storageInfoMapForDisksWithBacking[disk.key].dsObject;
               return this.datastoreRecommendationService.areRecommendationsAvailableForStorageObject(dsObject);
            }
         });

         if(!_.isUndefined(diskNeedRecommendation)) {
            // At least one disk.backing.datastore is sdrs storage (sdrs cluster or datastore in such cluster)
            return true;
         }


         if(this.newDisksWithEmptyStorage.length > 0){
            // At least one disk with undefined disk.backing.datastore.
            // Such disk location is "Store with VM"
            if (this.datastoreRecommendationService.areRecommendationsAvailableForStorageObject(vmxStorageObject)) {
               // VM location is SDRS, then those "Store with VM" disks needs ask for recommendation
               return true;
            }
         }

         return false;
      }
      public getRecommendations(vmId: string,
                                storageObject: { parentStoragePod: any, storageRef: any },
                                virtualMachineDevices: any,
                                originalConfig: any,
                                currentConfig: any,
                                cpuFeatureMask: any,
                                onApply: (result: any) => {},
                                onError: (result: any) => {}): any {


         if(_.isEmpty(this.storageInfoMapForDisksWithBacking)){
            this.buildStorageInfoMap(virtualMachineDevices);
         }

         var podSelectionSpec: any = this.podSelectionSpecService.createSpec(
               this.newDisks, // new disks devices
               storageObject,
               this.storageInfoMapForDisksWithBacking);   // storage info map

         // PodSelectionSpec.storagePod is not to be used in edit vm settings
         podSelectionSpec.storagePod = null;

         var vmConfigSpec: any = {
            originalConfig: originalConfig,
            config: currentConfig,
            cpuFeatureMask: cpuFeatureMask,
            deviceChange: virtualMachineDevices.getFinalChanges(),
            podSelectionSpec: podSelectionSpec,
            vm: this.defaultUriSchemeUtil.getManagedObjectReference(vmId)
         };

         var model = {
            vmName: currentConfig.name,
            datastoreName: null,
            selectedRecommendation: null,
            storageNameMap: {}
         };

         this.datastoreRecommendationService.getPlacementRecommendations(vmConfigSpec, 'com.vmware.vsphere.client.vm.VmConfigSpec')
               .then((result: any) => {
                  if (!_.isEmpty(result.faults)) {
                     return this.$q.reject(result.faults);
                  }

                  if (result.recommendations && result.recommendations.length > 0) {
                     return this.buildStorageNameMap(result.recommendations);
                  } else {
                     //TODO:- Handle the case for faults here
                     return {
                        storageNameMap: null,
                        recommendations: null
                     };
                  }
               })
               .then((result: any) => {
                  if(result.recommendations){
                     model.storageNameMap = result.storageNameMap;
                     return this.datastoreRecommendationService.openRecommendationsDialogDirect(model, result.recommendations);
                  }
                  else{
                     //TODO:- Handle the case for faults here
                     return;
                  }
               })
               .then((result: any) => {
                  onApply(result);
               }, (error: any) => {
                  onError(error);
               });
      }

      private buildStorageNameMap(recommendations: Array<{target: any, action: Array<any>}>) {
         let storageNameMap: any = {};
         _.forEach(recommendations, (recommendation)=> {
            storageNameMap[this.defaultUriSchemeUtil.getVsphereObjectId(recommendation.target)] = "";

            _.forEach(recommendation.action, (action)=> {
               storageNameMap[this.defaultUriSchemeUtil.getVsphereObjectId(action.destination)] = "";
            });
         });

         let objectIds: Array<string> = Object.keys(storageNameMap);
         return this.dataServiceUtil.getPropertiesForObjects(objectIds, ["name"], {}).then((response: any) => {
            _.forEach(objectIds, (objId) => {
               storageNameMap[objId] = response[objId].name;
            });
            return {
               storageNameMap: storageNameMap,
               recommendations: recommendations
            };
         });
      }

      private buildStorageInfoMap(virtualMachineDevices:any){
         this.newDisks = _.map(virtualMachineDevices.newDevicesOfType(
               'VirtualDisk'), (disk: any) => {
            return disk.getCurrentDevice();
         });

         // Remove PMem disks and disks created from existing ones
         this.newDisks = _.filter(this.newDisks, (virtualDisk: any ) => {
            return !this.pmemUtilService.isPmemDisk(virtualDisk) && !virtualDisk.createdFromExistingDisk;
         });


         this.storageInfoMapForDisksWithBacking = {};
         this.newDisksWithEmptyStorage = [];
         _.each(this.newDisks, (disk: any) => {
            if (disk.backing.datastore) {
               this.storageInfoMapForDisksWithBacking[disk.key] = {
                  diskKey: disk.key,
                  diskBacking: disk.backing,
                  dsId: disk.backing.datastore.id,
                  dsName: disk.backing.datastore.name,
                  dsObject: {
                     parentStoragePod: disk.backing.parentStoragePod,
                     storageRef: disk.backing.datastore,
                     name: disk.deviceInfo.label,
                     capacity: disk.capacityInBytes,
                     drsEnabled: disk.backing.datastore.isSdrsEnabled
                  }
               };
            } else {
               if (disk.key < 0) {
                  this.newDisksWithEmptyStorage.push(disk);
               }
            }
         });
      }
   }

   angular.module('com.vmware.vsphere.client.vm')
         .service('vmEditSettingsDatastoreRecommendationService', VmEditSettingsDatastoreRecommendationService);

}
