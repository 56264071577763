/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace h5_client {
   export class ContentLibraryStoragePortletController {
      public static $inject: string[] = ["$scope"];

      libraryId: string;

      constructor(private $scope: any) {
         this.libraryId = this.$scope._route.objectId;
      }
   }
   angular.module("com.vmware.vsphere.client.commonModule")
         .controller("contentLibraryStoragePortletController", ContentLibraryStoragePortletController);
}
