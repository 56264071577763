(function() {
      'use strict';
      angular.module('com.vmware.vsphere.client.commonModule')
         .controller('AddPermissionDialogController', AddPermissionDialogController);

      AddPermissionDialogController.$inject = [
         '$scope',
         'PermissionDataService',
         'defaultUriSchemeUtil',
         'dataService'
      ];

      function AddPermissionDialogController ($scope, permissionDataService, defaultUriSchemeUtil, dataService) {
         var self = this;

         self.selectedRole = null;
         self.selectedUserGroup = null;
         self.domainList = null;
         self.propagateToChildren = false;
         self.selectedDomainForPermission = null;
         self.selectedUserForPermission = null;
         self.userGroupListLoading = false;

         $scope.$watch(function() {
            return self.selectedDomain;
         }, function() {
             self.selectedUserGroup = null;
         });

         function init () {
            self.objectIds = $scope.modalOptions.availableTargets;
            if($scope.modalOptions.dialogData.selectedPermission) {
               self.selectedPermission = $scope.modalOptions.dialogData.selectedPermission;
               if(self.selectedPermission.principal) {
                  var domainUser = self.selectedPermission.principal.split('\\');
                  if(domainUser.length >= 2) {
                     self.selectedDomainForPermission = domainUser[0];
                     self.selectedUserForPermission = domainUser[1];
                  }
               }
               self.propagateToChildren = $scope.modalOptions.dialogData.selectedPermission.propagateToChildren;
            }
            self.getDomainList().then(function() {
               self.selectedDomain = self.domainList[0];
            });
            self.getUserRoles().then(function() {
               self.selectedRole = self.userRolesList[0];
               if($scope.modalOptions.dialogData.edit) {
                  var role = self.userRolesList.filter(function(userRole) {
                     return userRole.id === self.selectedPermission.roleId;
                  });
                  if(role.length > 0) {
                     self.selectedRole = role[0];
                  }
               }
            });

            self.getParentObjectReference();
         }

         self.getUserRoles = function() {
            return permissionDataService.getRolesInfo(self.objectIds[0]).then(function(rolesInfo) {
               self.userRolesList = rolesInfo;
            });
         };

         self.getParentObjectReference = function() {
            return dataService.getPropertiesForObjects(self.objectIds, ['parent']).then(function(result) {
               self.parentObjects = {};
               _.each(result, function(parentInfo, objId) {
                  self.parentObjects[objId] = parentInfo.parent;
               });
            });
         };

         self.onAddPermissionButtonClicked = function() {
            addPermissions();
            $scope.closeModal();
         };

         self.onCancelButtonClicked = function() {
            $scope.closeModal();
         };

         self.getDomainList  = function() {
           return permissionDataService.getDomains().then(function(domains) {
              self.domainList = domains;
           });
         };

         self.getUserGroupsList = function(searchString) {
            self.userGroupListLoading = true;
            searchString = searchString || "";
            return permissionDataService.getUserGroups(self.objectIds[0], self.selectedDomain.name, searchString).then(function(userGroupData) {
               self.userGroupListLoading = false;
               return userGroupData['vsphere:userGroups'].map(function(userGroupDataItem) {
                  return {
                     name: userGroupDataItem.principal,
                     description: userGroupDataItem.fullName,
                     group: userGroupDataItem.group
                  };
               });
            });
         };

         function addPermissions () {
            _.each(self.objectIds, function(entityId) {
               if(self.parentObjects && self.parentObjects[entityId]
                     && self.parentObjects[entityId].type === "ComputeResource") {
                  entityId = defaultUriSchemeUtil.getVsphereObjectId(self.parentObjects[entityId]);
               }
               if($scope.modalOptions.dialogData.edit) {
                  var permission = [{
                     roleId: self.selectedRole.id,
                     propagate: self.propagateToChildren,
                     id: self.selectedPermission.permissionId,
                     principal: self.selectedPermission.principal,
                     group: self.selectedPermission.isGroup
                  }];
                  permissionDataService.editUserGroupPermissions(entityId, permission);
               } else {
                  var permissionsList = [self.selectedUserGroup].map(function(dataItem) {
                     return {
                        principal: [self.selectedDomain.name, dataItem.name].join("\\"),
                        group: dataItem.group,
                        roleId: self.selectedRole.id,
                        propagate: self.propagateToChildren
                     };
                  });
                  permissionDataService.saveUserGroupPermissions(entityId, permissionsList);
               }
            });
         }

         init();
      }
   }()
);
