/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace dvs_ui {

   import DvpgTrafficFilterModel = com.vmware.vsphere.client.h5.network.dvportgroup.trafficfilter.DvpgTrafficFilterModel;
   import DvpgTrafficFilterRule = com.vmware.vsphere.client.h5.network.dvportgroup.trafficfilter.DvpgTrafficFilterRule;
   import DvpgEditTrafficFilterSpec = com.vmware.vsphere.client.h5.network.dvportgroup.trafficfilter.DvpgEditTrafficFilterSpec;
   import DvpgTrafficRuleModel = com.vmware.vsphere.client.h5.network.dvportgroup.trafficfilter.model.DvpgTrafficRuleModel;
   import DvpgIpQualifierModel = com.vmware.vsphere.client.h5.network.dvportgroup.trafficfilter.model.DvpgIpQualifierModel;
   import DvpgProtocolModel = com.vmware.vsphere.client.h5.network.dvportgroup.trafficfilter.model.DvpgProtocolModel;
   import DvpgPortModel = com.vmware.vsphere.client.h5.network.dvportgroup.trafficfilter.model.DvpgPortModel;
   import DvpgIpAddressModel = com.vmware.vsphere.client.h5.network.dvportgroup.trafficfilter.model.DvpgIpAddressModel;
   import DvpgMacQualifierModel = com.vmware.vsphere.client.h5.network.dvportgroup.trafficfilter.model.DvpgMacQualifierModel;
   import DvpgMacAddressModel = com.vmware.vsphere.client.h5.network.dvportgroup.trafficfilter.model.DvpgMacAddressModel;
   import DvpgSystemQualifierModel = com.vmware.vsphere.client.h5.network.dvportgroup.trafficfilter.model.DvpgSystemQualifierModel;
   import DvpgAddTrafficRuleSpec = com.vmware.vsphere.client.h5.network.dvportgroup.trafficfilter.DvpgAddTrafficRuleSpec;
   import DvpgRemoveTrafficRuleSpec = com.vmware.vsphere.client.h5.network.dvportgroup.trafficfilter.DvpgRemoveTrafficRuleSpec;
   import DvpgEditTrafficRuleSpec = com.vmware.vsphere.client.h5.network.dvportgroup.trafficfilter.DvpgEditTrafficRuleSpec;
   import DvpgCloneTrafficRulesSpec = com.vmware.vsphere.client.h5.network.dvportgroup.trafficfilter.DvpgCloneTrafficRulesSpec;

   export class DvpgTrafficFilterService {

      static $inject = [
         "i18nService",
         "clarityConstants",
         "dvpgTrafficFilterConstants",
         "dvpgTrafficFilterSpecFactory",
         "dvpgTrafficRuleValidator",
         "dvpgCloneTrafficRulesValidator",
         "clarityModalService",
         "dataService",
         "mutationService",
         "defaultUriSchemeUtil",
         "$q"
      ];

      constructor(private i18nService: any,
                  private clarityConstants: any,
                  private trafficFilterConstants: any,
                  private trafficFilterSpecFactory: any,
                  private dvpgTrafficRuleValidator: any,
                  private dvpgCloneTrafficRulesValidator: any,
                  private clarityModalService: any,
                  private dataService: any,
                  private mutationService: any,
                  private defaultUriSchemeUtil: any,
                  private $q: any) {
      }

      public showEditTrafficFilterDialog(dvpgId: string): void {
         let modalOptions: any = {
            title: this.i18nService.getString(
                  "DvsUi", "trafficFilter.rulesDialog.title"),
            subTitle: {
               objectId: dvpgId
            },
            defaultButton: "submit",
            contentTemplate: "dvs-ui/resources/dvs/dvpg/trafficfilter/edit/" +
                  "dvpgEditTrafficFilterDialog.html",
            size: "lg",
            modalClass: "edit-traffic-filter-dialog",
            dialogData: {
               objectId: dvpgId,
               model: new DvpgEditTrafficFilterModel()
            },
            onSubmit: () => {
               let isRulesOrderChanged: boolean = this.isRuleOrderChanged(
                     modalOptions.dialogData.model.rules,
                     modalOptions.dialogData.model.originalRulesKeys);
               let isRulsetEnabledChanged: boolean =
                     modalOptions.dialogData.model.initialTrafficRulesetEnabled !==
                     modalOptions.dialogData.model.isTrafficRulesetEnabled;

               if (isRulsetEnabledChanged || isRulesOrderChanged) {
                  let dvpgEditTrafficFilterSpec: DvpgEditTrafficFilterSpec =
                        this.trafficFilterSpecFactory.buildEditTrafficFilterSpec(
                              modalOptions.dialogData.model, isRulesOrderChanged);
                  this.mutationService.apply(
                        dvpgId,
                        this.trafficFilterConstants.spec.DVPG_EDIT_TRAFFIC_RULES_SPEC,
                        dvpgEditTrafficFilterSpec);
               }

               return true;
            }
         };

         this.dataService.getProperties(dvpgId,
               this.trafficFilterConstants.properties.TRAFFIC_FILTER_PROPERTY)
               .then((response: any): any => response[
                     this.trafficFilterConstants.properties.TRAFFIC_FILTER_PROPERTY])
               .then((model: DvpgTrafficFilterModel): void => {
                  if (model) {
                     modalOptions.dialogData.model.initialTrafficRulesetEnabled =
                           model.isTrafficRulesetEnabled;
                     modalOptions.dialogData.model.isTrafficRulesetEnabled =
                           model.isTrafficRulesetEnabled;
                     modalOptions.dialogData.model.rules = model.rules;
                     modalOptions.dialogData.model.originalRulesKeys =
                           this.getRulesKeys(model.rules);
                  } else {
                     modalOptions.dialogData.model.rules = [];
                     modalOptions.dialogData.model.originalRulesKeys = [];
                  }
               });

         this.clarityModalService.openOkCancelModal(modalOptions);
      }

      public showCloneTrafficFilterDialog(dvpgId: string): void {
         let model: DvpgCloneTrafficRulesModel = new DvpgCloneTrafficRulesModel();
         model.dvpgId = dvpgId;
         model.selectedPortgroups = [];

         let modalOptions: any = {
            title: this.i18nService.getString(
                  "DvsUi", "trafficFilter.cloneRulesDialog.title"),
            subTitle: {
               objectId: dvpgId
            },
            defaultButton: "submit",
            contentTemplate: "dvs-ui/resources/dvs/dvpg/trafficfilter/clone/" +
                  "dvpgCloneTrafficRulesDialog.html",
            size: "lg",
            modalClass: "clone-traffic-filter-dialog",
            dialogData: {
               objectId: dvpgId,
               model: model
            },
            onSubmit: () => {
               return this.dvpgCloneTrafficRulesValidator.getValidationError(
                     modalOptions.dialogData.model).then(
                     (errors:string[] | boolean) => {

                        if (_.isArray(errors) && errors.length > 0) {
                           this.showAllErrors(errors, modalOptions);
                           return false;
                        } else if (!errors) {
                           return false;
                        }

                        let dvpgCloneTrafficRulesSpec:DvpgCloneTrafficRulesSpec =
                              this.trafficFilterSpecFactory.buildCloneTrafficFilterSpec(
                                    modalOptions.dialogData.model);
                        this.mutationService.apply(
                              dvpgId,
                              this.trafficFilterConstants.spec.DVPG_CLONE_TRAFFIC_RULES_SPEC,
                              dvpgCloneTrafficRulesSpec);

                        return true;
                     });
            }
         };

         this.dataService.getProperties(dvpgId,
               [this.trafficFilterConstants.properties.DVPG_NAME,
               this.trafficFilterConstants.properties.DVPG_DVS])
               .then((response: any): void => {
                  modalOptions.dialogData.model.dvsId =
                        this.defaultUriSchemeUtil.getVsphereObjectId(
                              response[this.trafficFilterConstants.properties.DVPG_DVS]);
                  modalOptions.dialogData.model.dvpgName =
                        response[this.trafficFilterConstants.properties.DVPG_NAME];

                  this.clarityModalService.openOkCancelModal(modalOptions);
               });
      }

      public showAddRuleDialog(dvpgId: string): void {
         let onIpValidate: any = {};
         let onMacValidate: any = {};
         let modalOptions: any = {
            title: this.i18nService.getString(
                  "DvsUi", "trafficFilter.ruleDialog.title"),
            subTitle: {
               objectId: dvpgId
            },
            defaultButton: "submit",
            contentTemplate: "dvs-ui/resources/dvs/dvpg/trafficfilter/add/" +
                  "dvpgAddTrafficRuleDialog.html",
            size: "lg",
            modalClass: "add-traffic-rule-dialog",
            dialogData: {
               availableTargets: [dvpgId],
               objectId: dvpgId,
               ipValidator: onIpValidate,
               macValidator: onMacValidate,
               newRuleModel: this.initAddTrafficRuleModel(),
               trafficFiltersModel: new DvpgTrafficFilterModel()
            },
            onSubmit: () => {
               return this.dvpgTrafficRuleValidator.getValidationResult(
                     modalOptions.dialogData.newRuleModel).then(
                           (errors: string[] | boolean) => {

                  modalOptions.dialogData.ipValidator.validate();
                  modalOptions.dialogData.macValidator.validate();

                  if (_.isArray(errors) && errors.length > 0) {

                     this.showErrors(errors, modalOptions);
                     return false;
                  } else if (!errors) {
                     return false;
                  }

                  let dvpgAddTrafficRuleSpec: DvpgAddTrafficRuleSpec =
                        this.trafficFilterSpecFactory.buildAddTrafficRuleSpec(
                              modalOptions.dialogData.newRuleModel);
                  this.mutationService.apply(
                        dvpgId,
                        this.trafficFilterConstants.spec.DVPG_ADD_TRAFFIC_RULE_SPEC,
                        dvpgAddTrafficRuleSpec);

                  return true;
               });
            }
         };

         this.dataService.getProperties(dvpgId,
               this.trafficFilterConstants.properties.TRAFFIC_FILTER_PROPERTY)
               .then((response: any): any => response[
                     this.trafficFilterConstants.properties.TRAFFIC_FILTER_PROPERTY])
               .then((model: DvpgTrafficFilterModel): void => {
                  modalOptions.dialogData.trafficFiltersModel = model;
                  modalOptions.dialogData.newRuleModel.name =
                        this.getUniqueRuleName(model.rules);
               });

         this.clarityModalService.openOkCancelModal(modalOptions);
      }

      public showEditRuleDialog(dvpgId: string, editedRuleKey: string): void {
         let onIpValidate: any = {};
         let onMacValidate: any = {};
         let modalOptions: any = {
            title: this.i18nService.getString(
                  "DvsUi", "trafficFilter.ruleDialog.editTitle"),
            subTitle: {
               objectId: dvpgId
            },
            defaultButton: "submit",
            contentTemplate: "dvs-ui/resources/dvs/dvpg/trafficfilter/add/" +
                  "dvpgEditTrafficRuleDialog.html",
            size: "lg",
            modalClass: "edit-traffic-rule-dialog",
            dialogData: {
               availableTargets: [dvpgId],
               objectId: dvpgId,
               ipValidator: onIpValidate,
               macValidator: onMacValidate,
               editRuleModel: this.initNewTrafficRuleModel()
            },
            onSubmit: () => {
               return this.dvpgTrafficRuleValidator.getValidationResult(
                     modalOptions.dialogData.editRuleModel).then(
                     (errors: string[] | boolean) => {

                        modalOptions.dialogData.ipValidator.validate();
                        modalOptions.dialogData.macValidator.validate();

                        if (_.isArray(errors) && errors.length > 0) {
                           this.showErrors(errors, modalOptions);
                           return false;
                        } else if (!errors) {
                           return false;
                        }

                        let dvpgEditTrafficRuleSpec: DvpgEditTrafficRuleSpec =
                              this.trafficFilterSpecFactory.buildEditTrafficRuleSpec(
                                    modalOptions.dialogData.editRuleModel);
                        this.mutationService.apply(
                              dvpgId,
                              this.trafficFilterConstants.spec.DVPG_EDIT_TRAFFIC_RULE_SPEC,
                              dvpgEditTrafficRuleSpec);

                        return true;
                     });
            }
         };

         let params: any = {
            propertyParams: [{
               propertyName: this.trafficFilterConstants.properties.TRAFFIC_RULE_PROPERTY,
               parameterType: "java.lang.String",
               parameter: editedRuleKey
            }]
         };

         this.dataService.getProperties(dvpgId,
               [this.trafficFilterConstants.properties.TRAFFIC_RULE_PROPERTY], params)
               .then((response: any): any => response[
                     this.trafficFilterConstants.properties.TRAFFIC_RULE_PROPERTY])
               .then((model: DvpgTrafficRuleModel): void => {
                  let ruleModel: DvpgTrafficRuleModel =
                        this.initEditTrafficRuleModel(model);

                  modalOptions.title =  this.i18nService.getString(
                        "DvsUi", "trafficFilter.ruleDialog.editTitleUpdated",
                        ruleModel.name);

                  _.extend(ruleModel, {defaultQualifierTabIndex:
                        this.getDefaultQualifierTabIndex(ruleModel)});

                  modalOptions.dialogData.editRuleModel = ruleModel;
               });

         this.clarityModalService.openOkCancelModal(modalOptions);
      }

      public removeRule(dvpgId: string, ruleKey: string, ruleName: string): void {
         let submitFuncion: any = () => {
            let dvpgRemoveTrafficRuleSpec: DvpgRemoveTrafficRuleSpec =
                  this.trafficFilterSpecFactory.buildRemoveTrafficRuleSpec(ruleKey);
            this.mutationService.apply(
                  dvpgId,
                  this.trafficFilterConstants.spec.DVPG_REMOVE_TRAFFIC_RULE_SPEC,
                  dvpgRemoveTrafficRuleSpec);
         };

         let modalOptions: any = {
            title: this.i18nService.getString("DvsUi",
                  "trafficFilter.ruleDialog.remove.caption"),
            message: (ruleName && ruleName.trim().length > 0) ?
                  this.i18nService.getString("DvsUi",
                        "trafficFilter.ruleDialog.remove.warning", ruleName) :
                  this.i18nService.getString("DvsUi",
                        "trafficFilter.ruleDialog.remove.noNameWarning"),
            submit: submitFuncion,
            subTitle: {
               objectId: dvpgId
            },
            preserveNewlines: true,
            clarityIcon: {
               class: "is-warning",
               shape: "warning-standard",
               size: "32"
            }
         };

         this.clarityModalService.openConfirmationModal(modalOptions);
      }

      public isRuleOrderChanged(modifiedRules:DvpgTrafficFilterRule[],
            originalRulesKeys: string[]): boolean {

         if (!modifiedRules || !originalRulesKeys ||
               modifiedRules.length !== originalRulesKeys.length) {
            return false;
         }

         for (let i = 0; i < modifiedRules.length; i++) {
            if (modifiedRules[i].key !== originalRulesKeys[i]) {
               return true;
            }
         }
         return false;
      }

      public getRulesKeys(originalRules:DvpgTrafficFilterRule[]): string[] {
         let rulesKeys: string[] = [];

         if (!originalRules || originalRules.length === 0) {
            return rulesKeys;
         }

         for (let i = 0; i < originalRules.length; i++) {
            rulesKeys.push(originalRules[i].key);
         }

         return rulesKeys;
      }

      public initAddTrafficRuleModel(): DvpgTrafficRuleModel {
         return this.initNewTrafficRuleModel();
      }

      public initEditTrafficRuleModel(
            existingRuleModel: DvpgTrafficRuleModel): DvpgTrafficRuleModel {
         if (!existingRuleModel) {
            return this.initNewTrafficRuleModel();
         } else {
            if (existingRuleModel.ipQualifierModel === null) {
               existingRuleModel.ipQualifierModel = this.initIpQualifierModel();
            } else {
               if (existingRuleModel.ipQualifierModel.sourcePort === null) {
                  existingRuleModel.ipQualifierModel.sourcePort = this.initPortModel();
               }

               if (existingRuleModel.ipQualifierModel.destinationPort === null) {
                  existingRuleModel.ipQualifierModel.destinationPort =
                        this.initPortModel();
               }
            }

            if (existingRuleModel.macQualifierModel === null) {
               existingRuleModel.macQualifierModel = this.initMacQualifierModel();
            }

            if (existingRuleModel.systemQualifierModel === null) {
               existingRuleModel.systemQualifierModel = this.initSystemQualifierModel();
            }
            return existingRuleModel;
         }
      }

      public getTrafficFilterActions(): any[] {
         return [{
            value: this.trafficFilterConstants.action.ALLOW,
            label: this.i18nService.getString(
                  "DvsUi", "trafficFilter.ruleDialog.allow")
         }, {
            value: this.trafficFilterConstants.action.DROP,
            label: this.i18nService.getString(
                  "DvsUi", "trafficFilter.ruleDialog.drop")
         }, {
            value: this.trafficFilterConstants.action.TAG,
            label: this.i18nService.getString(
                  "DvsUi", "trafficFilter.ruleDialog.tag")
         }];
      }

      public getTrafficFilterDirections(): any[] {
         return [{
            value: this.trafficFilterConstants.direction.BOTH,
            label: this.i18nService.getString(
                  "DvsUi", "trafficFilter.ruleDialog.direction.ingressEgress")
         }, {
            value: this.trafficFilterConstants.direction.EGRESS,
            label: this.i18nService.getString(
                  "DvsUi", "trafficFilter.ruleDialog.direction.egress")
         }, {
            value: this.trafficFilterConstants.direction.INGRESS,
            label: this.i18nService.getString(
                  "DvsUi", "trafficFilter.ruleDialog.direction.ingress")
         }];
      }

      public getQualifiersProtocolOperations(): any[] {
         return [{
            value: this.trafficFilterConstants.operator.ANY,
            label: this.i18nService.getString(
                  "DvsUi", "trafficFilter.ruleDialog.operation.any")
         }, {
            value: this.trafficFilterConstants.operator.IS,
            label: this.i18nService.getString(
                  "DvsUi", "trafficFilter.ruleDialog.operation.is")
         }, {
            value: this.trafficFilterConstants.operator.IS_NOT,
            label: this.i18nService.getString(
                  "DvsUi", "trafficFilter.ruleDialog.operation.isNot")
         }];
      }

      public getIpQualifierProtocolTypes(): any[] {
         return [{
            value: this.trafficFilterConstants.protocol.ICMP,
            label: this.i18nService.getString(
                  "DvsUi", "trafficFilter.ruleDialog.protocol.icmp",
                  this.trafficFilterConstants.protocol.ICMP.toString())
         }, {
            value: this.trafficFilterConstants.protocol.TCP,
            label: this.i18nService.getString(
                  "DvsUi", "trafficFilter.ruleDialog.protocol.tcp",
                  this.trafficFilterConstants.protocol.TCP.toString())
         }, {
            value: this.trafficFilterConstants.protocol.UDP,
            label: this.i18nService.getString(
                  "DvsUi", "trafficFilter.ruleDialog.protocol.udp",
                  this.trafficFilterConstants.protocol.UDP.toString())
         }, {
            value: this.trafficFilterConstants.protocol.ICMP6,
            label: this.i18nService.getString(
                  "DvsUi", "trafficFilter.ruleDialog.protocol.icmp6",
                  this.trafficFilterConstants.protocol.ICMP6.toString())
         }, {
            value: this.trafficFilterConstants.protocol.CUSTOM,
            label: this.i18nService.getString(
                  "DvsUi", "trafficFilter.ruleDialog.protocol.custom")
         }];
      }

      public getMacQualifierProtocolTypes(): any[] {
         return [{
            value: this.trafficFilterConstants.mac.IPV4_PROTOCOL_HEX_VALUE,
            label: this.i18nService.getString(
                  "DvsUi", "trafficFilter.ruleDialog.mac.ipv4",
                  this.getHexRepresentation(
                        this.trafficFilterConstants.mac.IPV4_PROTOCOL_HEX_VALUE))
         }, {
            value: this.trafficFilterConstants.mac.IPV6_PROTOCOL_HEX_VALUE,
            label: this.i18nService.getString(
                  "DvsUi", "trafficFilter.ruleDialog.mac.ipv6",
                  this.getHexRepresentation(
                        this.trafficFilterConstants.mac.IPV6_PROTOCOL_HEX_VALUE))
         }, {
            value: this.trafficFilterConstants.mac.ARP_PROTOCOL_HEX_VALUE,
            label: this.i18nService.getString(
                  "DvsUi", "trafficFilter.ruleDialog.mac.arp",
                  this.getHexRepresentation(
                        this.trafficFilterConstants.mac.ARP_PROTOCOL_HEX_VALUE))
         }, {
            value: this.trafficFilterConstants.protocol.CUSTOM,
            label: this.i18nService.getString(
                  "DvsUi", "trafficFilter.ruleDialog.protocol.custom")
         }];
      }

      public getMacQualifierVlanOperations(): any[] {
         return [{
            value: this.trafficFilterConstants.operator.ANY,
            label: this.i18nService.getString(
                  "DvsUi", "trafficFilter.ruleDialog.operation.any")
         }, {
            value: this.trafficFilterConstants.operator.IS,
            label: this.i18nService.getString(
                  "DvsUi", "trafficFilter.ruleDialog.operation.is")
         }, {
            value: this.trafficFilterConstants.operator.IS_NOT,
            label: this.i18nService.getString(
                  "DvsUi", "trafficFilter.ruleDialog.operation.isNot")
         }];
      }

      public getIpQualifierPortOperations(): any[] {
         return [{
            value: this.trafficFilterConstants.operator.ANY,
            label: this.i18nService.getString(
                  "DvsUi", "trafficFilter.ruleDialog.operation.any")
         }, {
            value: this.trafficFilterConstants.operator.IS,
            label: this.i18nService.getString(
                  "DvsUi", "trafficFilter.ruleDialog.operation.is")
         }, {
            value: this.trafficFilterConstants.operator.IS_NOT,
            label: this.i18nService.getString(
                  "DvsUi", "trafficFilter.ruleDialog.operation.isNot")
         }, {
            value: this.trafficFilterConstants.operator.IN_RANGE,
            label: this.i18nService.getString(
                  "DvsUi", "trafficFilter.ruleDialog.operation.inRange")
         }, {
            value: this.trafficFilterConstants.operator.NOT_IN_RANGE,
            label: this.i18nService.getString(
                  "DvsUi", "trafficFilter.ruleDialog.operation.notInRange")
         }];
      }

      public getQualifiersAddressOperations(): any[] {
         return [{
            value: this.trafficFilterConstants.operator.ANY,
            label: this.i18nService.getString(
                  "DvsUi", "trafficFilter.ruleDialog.operation.any")
         }, {
            value: this.trafficFilterConstants.operator.IS,
            label: this.i18nService.getString(
                  "DvsUi", "trafficFilter.ruleDialog.operation.is")
         }, {
            value: this.trafficFilterConstants.operator.IS_NOT,
            label: this.i18nService.getString(
                  "DvsUi", "trafficFilter.ruleDialog.operation.isNot")
         }, {
            value: this.trafficFilterConstants.operator.MATCHES,
            label: this.i18nService.getString(
                  "DvsUi", "trafficFilter.ruleDialog.operation.matches")
         }, {
            value: this.trafficFilterConstants.operator.DOES_NOT_MATCH,
            label: this.i18nService.getString(
                  "DvsUi", "trafficFilter.ruleDialog.operation.doesNotMatch")
         }];
      }

      public getIpQualifierIpAddressTypes(): any[] {
         return [{
            value: this.trafficFilterConstants.addressType.IPV4,
            label: this.i18nService.getString(
                  "DvsUi", "trafficFilter.ruleDialog.addressType.ipv4")
         }, {
            value: this.trafficFilterConstants.addressType.IPV6,
            label: this.i18nService.getString(
                  "DvsUi", "trafficFilter.ruleDialog.addressType.ipv6")
         }, {
            value: this.trafficFilterConstants.addressType.MIXED,
            label: this.i18nService.getString(
                  "DvsUi", "trafficFilter.ruleDialog.addressType.all")
         }];
      }

      public getSystemQualifierOperations(): any[] {
         return [{
            value: this.trafficFilterConstants.operator.IS,
            label: this.i18nService.getString(
                  "DvsUi", "trafficFilter.ruleDialog.operation.is")
         }, {
            value: this.trafficFilterConstants.operator.IS_NOT,
            label: this.i18nService.getString(
                  "DvsUi", "trafficFilter.ruleDialog.operation.isNot")
         }];
      }

      public getSystemQualifierTypes(): any[] {
         return [{
            value: this.trafficFilterConstants.systemTraffic.FT,
            label: this.i18nService.getString(
                  "DvsUi", "trafficFilter.ruleDialog.faultTolerance")
         }, {
            value: this.trafficFilterConstants.systemTraffic.HBR,
            label: this.i18nService.getString(
                  "DvsUi", "trafficFilter.ruleDialog.hbr")
         }, {
            value: this.trafficFilterConstants.systemTraffic.ISCSI,
            label: this.i18nService.getString(
                  "DvsUi", "trafficFilter.ruleDialog.iscsi")
         }, {
            value: this.trafficFilterConstants.systemTraffic.MANAGEMENT,
            label: this.i18nService.getString(
                  "DvsUi", "trafficFilter.ruleDialog.management")
         }, {
            value: this.trafficFilterConstants.systemTraffic.NFS,
            label: this.i18nService.getString(
                  "DvsUi", "trafficFilter.ruleDialog.nfs")
         }, {
            value: this.trafficFilterConstants.systemTraffic.VM,
            label: this.i18nService.getString(
                  "DvsUi", "trafficFilter.ruleDialog.vm")
         }, {
            value: this.trafficFilterConstants.systemTraffic.VMOTION,
            label: this.i18nService.getString(
                  "DvsUi", "trafficFilter.ruleDialog.vMotion")
         }, {
            value: this.trafficFilterConstants.systemTraffic.VSAN,
            label: this.i18nService.getString(
                  "DvsUi", "trafficFilter.ruleDialog.vsan")
         }, {
            value: this.trafficFilterConstants.systemTraffic.VDP,
            label: this.i18nService.getString(
                  "DvsUi", "trafficFilter.ruleDialog.vdp")
         }];
      }

      public getUniqueRuleName(trafficFilterRules:DvpgTrafficFilterRule[]): string {
         let count: number = 1;
         let uniqueRuleName: string = this.i18nService.getString(
               "DvsUi", "trafficFilter.ruleDialog.defaultName", count.toString());

         if (!trafficFilterRules || trafficFilterRules.length === 0) {
            return uniqueRuleName;
         }

         let trafficRuleNames: string[] = trafficFilterRules.map(
               (rule:DvpgTrafficFilterRule): string => {
                  return rule.name;
               });

         while (trafficRuleNames.indexOf(uniqueRuleName) !== -1) {
            count++;
            uniqueRuleName = this.i18nService.getString(
                  "DvsUi", "trafficFilter.ruleDialog.defaultName", count.toString());
         }
         return uniqueRuleName;
      }

      public getHexRepresentation(value: number): string {
         if (!value || isNaN(value)) {
            return "";
         }
         let hexValue: string = value.toString(16).toUpperCase();
         while (hexValue.length < 4) {
            hexValue = '0' + hexValue;
         }
         return hexValue;
      }

      public initNewTrafficRuleModel(): DvpgTrafficRuleModel {
         let model: DvpgTrafficRuleModel = new DvpgTrafficRuleModel();
         model.action = this.trafficFilterConstants.action.TAG;
         model.direction = this.trafficFilterConstants.direction.BOTH;
         model.ipQualifierModel = this.initIpQualifierModel();
         model.macQualifierModel = this.initMacQualifierModel();
         model.systemQualifierModel = this.initSystemQualifierModel();
         return model;
      }

      public areRulesChanged(
            oldRules: DvpgTrafficFilterRule[],
            newRules: DvpgTrafficFilterRule[]): boolean {

         if (!oldRules || !newRules || oldRules.length !== newRules.length) {
            return true;
         }

         for (let i = 0; i < newRules.length; i++) {
            if (this.isRuleChanged(oldRules[i], newRules[i])) {
               return true;
            }
         }
         return false;
      }

      private isRuleChanged(
            oldRule: DvpgTrafficFilterRule,
            newRule: DvpgTrafficFilterRule): boolean {

         if (!oldRule || !newRule ||
               oldRule.key !== newRule.key ||
               oldRule.sequence !== newRule.sequence ||
               oldRule.displaySequence !== newRule.displaySequence ||
               oldRule.action !== newRule.action ||
               oldRule.name !== newRule.name ||
               oldRule.direction !== newRule.direction) {
            return true;
         }

         if (!newRule.qualifiers || !oldRule.qualifiers ||
               newRule.qualifiers.length !== oldRule.qualifiers.length) {
            return true;
         }

         for (let i = 0; i < newRule.qualifiers.length; i++) {
            if (oldRule.qualifiers[i] !== newRule.qualifiers[i]) {
               return true;
            }
         }

         return false;
      }

      private initPortModel(): DvpgPortModel {
         let portModel: DvpgPortModel = new DvpgPortModel();
         portModel.operation = this.trafficFilterConstants.operator.ANY;
         return portModel;
      }

      private initIpAddressModel(): DvpgIpAddressModel {
         let ipAddressModel: DvpgIpAddressModel = new DvpgIpAddressModel();
         ipAddressModel.operation = this.trafficFilterConstants.operator.ANY;
         ipAddressModel.ipAddressType = this.trafficFilterConstants.addressType.MIXED;
         return ipAddressModel;
      }

      private initIpProtocolModel(): DvpgProtocolModel {
         let ipProtocolModel: DvpgProtocolModel = new DvpgProtocolModel();
         ipProtocolModel.operation = this.trafficFilterConstants.operator.IS;
         ipProtocolModel.type = this.trafficFilterConstants.protocol.TCP;
         return ipProtocolModel;
      }

      private initMacProtocolModel(): DvpgProtocolModel {
         let macProtocolModel: DvpgProtocolModel = new DvpgProtocolModel();
         macProtocolModel.operation =
               this.trafficFilterConstants.operator.ANY;
         macProtocolModel.type = this.trafficFilterConstants.mac.IPV4_PROTOCOL_HEX_VALUE;
         return macProtocolModel;
      }

      private initMacAddressModel(): DvpgMacAddressModel {
         let macAddressModel: DvpgMacAddressModel = new DvpgMacAddressModel();
         macAddressModel.operation = this.trafficFilterConstants.operator.ANY;
         return macAddressModel;
      }

      private initIpQualifierModel(): DvpgIpQualifierModel {
         let model:DvpgIpQualifierModel = new DvpgIpQualifierModel();
         model.protocol = this.initIpProtocolModel();
         model.sourcePort = this.initPortModel();
         model.destinationPort = this.initPortModel();
         model.sourceIpAddress = this.initIpAddressModel();
         model.destinationIpAddress = this.initIpAddressModel();
         return model;
      }

      private initMacQualifierModel(): DvpgMacQualifierModel {
         let model: DvpgMacQualifierModel = new DvpgMacQualifierModel();
         model.protocol =this.initMacProtocolModel();
         model.vlanOperation = this.trafficFilterConstants.operator.ANY;
         model.sourceMacAddress = this.initMacAddressModel();
         model.destinationMacAddress = this.initMacAddressModel();
         return model;
      }

      private initSystemQualifierModel(): DvpgSystemQualifierModel {
         let model: DvpgSystemQualifierModel = new DvpgSystemQualifierModel();
         model.trafficOperation = this.trafficFilterConstants.operator.IS;
         model.trafficType = this.trafficFilterConstants.systemTraffic.FT;
         return model;
      }

      private showErrors(errors: any, modalOptions: any): void {
         if (_.isArray(errors) && errors.length > 0) {
            if (errors.length === 1) {
               modalOptions.alerts = [{
                  type: this.clarityConstants.notifications.type.ERROR,
                  text: errors[0]
               }];
            } else {
               modalOptions.alerts = [{
                  type: this.clarityConstants.notifications.type.ERROR,
                  text: this.i18nService.getString(
                        "DvsUi", "trafficFilter.ruleDialog.generalError"),
               }];
            }
         }
      }

      private showAllErrors(errors: any, modalOptions: any): void {
         if (_.isArray(errors) && errors.length > 0) {
            modalOptions.alerts = [];

            _.each(errors, (error: string) => {
               modalOptions.alerts.push({
                  type: this.clarityConstants.notifications.type.ERROR,
                  text: error
               });
            });
         }
      }

      private getDefaultQualifierTabIndex(model: DvpgTrafficRuleModel): number {
         if (!model || model.ipQualifierModel.enableIpQualifier ||
               (!model.macQualifierModel.enableMacQualifier &&
               !model.systemQualifierModel.enableSystemQualifier)) {
            return 0;
         }

         if (model.macQualifierModel.enableMacQualifier) {
            return 1;
         } else {
            // the system qualifier tab is the default
            return 2;
         }
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .service("dvpgTrafficFilterService", DvpgTrafficFilterService);
}
