namespace h5_vm {
   export class TemplateType {
      static readonly VM_TEMPLATE: string = "VM Template";
      static readonly OVF_TEMPLATE: string = "OVF";
   }

   export class CloneType {
      static readonly NEW_TEMPLATE: string = "new";
      static readonly UPDATE_TEMPLATE: string = "update";
   }
}
