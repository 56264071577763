module cluster_ui {

   export class CapacityFormatterService {

      public static $inject = ["browserLanguage", "i18nService"];

      constructor(private browserLanguage: any, private i18nService: any) {
      }

      public format(
            value: string, capacity: string, percentage: number): string {
         let percentageFormatted: string = this.formatPercent(percentage);

         return this.i18nService.getString(
               "ClusterUi",
               "capacityToolTipFormat",
               value,
               capacity,
               percentageFormatted);
      }

      public formatPercent(value: number): string {
         let valueToFormat: number = value;
         if (!valueToFormat) {
            valueToFormat = 0;
         }

         let locale: string = this.browserLanguage.get();
         let options: any = {maximumFractionDigits: 2};

         let result: string = valueToFormat.toLocaleString(locale, options);

         return result;
      }
   }

   angular.module("com.vmware.vsphere.client.cluster")
         .service("capacityFormatterService", CapacityFormatterService);
}
