(function() {
   'use strict';

   angular.module('com.vmware.vsphere.client.cluster')
         .service('clusterVmOverridesService', clusterVmOverridesService);

   clusterVmOverridesService.$inject = ['dataService', 'i18nService', 'clarityModalService',
      'clusterSpecBuilderService', 'mutationService', 'clusterVmOverridesConstants', 'vxPropertyViewService'];

   function clusterVmOverridesService(dataService, i18nService, clarityModalService,
         clusterSpecBuilderService, mutationService, clusterVmOverridesConstants, vxPropertyViewService) {

      return {
         getVmOverridesData: getVmOverridesData,
         deleteVmOverride: deleteVmOverride,
         buildPropertyView: buildPropertyView
      };

      function getVmOverridesData(clusterId) {
         return dataService
               .getData(clusterId,
                     'com.vmware.vsphere.client.clusterui.model.configuration.VmOverridesData')
               .then(function(data) {
                  return {
                     overrides: data ? data.vmOverrides : []
                  };
               });
      }

      function deleteVmOverride(clusterId, vmOverrideInfos, focusTarget) {
         var vmMors = _.map(vmOverrideInfos, function(item) {
            return angular.merge(
                  { _type: 'com.vmware.vim.binding.vmodl.ManagedObjectReference' },
                  item.vm.provider);
         });

         var confirmProperties = {
            title: i18nService.getString('ClusterUi', 'actionDeleteVmOverrideCaption'),
            message: i18nService.getString('ClusterUi',
                  'actionDeleteVmOverrideConfirmation'),
            focusTarget: focusTarget,
            submit: function() {
               deleteVmOverrideFromCluster(clusterId, vmOverrideInfos, vmMors);
            }
         };

         clarityModalService.openConfirmationModal(confirmProperties);
      }

      function deleteVmOverrideFromCluster(clusterId, vmOverrideInfos, vmKeys) {
         var vmOverridesSpec = {};
         var someOverridesWillNotBeDeleted = false;

         vmOverridesSpec.dasVmOverride = [];
         _.each(vmKeys, function(item, index) {
            if (vmOverrideInfos[index].haOverrides.overridesHaOptions) {
               vmOverridesSpec.dasVmOverride.push({
                  _type: clusterVmOverridesConstants.overrideSpec.DAS_VM_CONFIG_SPEC,
                  operation: clusterVmOverridesConstants.operationType.REMOVE,
                  removeKey: item
               });
            }
         });

         vmOverridesSpec.drsVmOverride = [];
         _.each(vmKeys, function(item, index) {
            if (vmOverrideInfos[index].drsOverrides.overridesDrsOptions) {
               if (vmOverrideInfos[index].drsOverrides.drsAutomationLevelEditable) {
                  vmOverridesSpec.drsVmOverride.push({
                     _type: clusterVmOverridesConstants.overrideSpec.DRS_VM_CONFIG_SPEC,
                     operation: clusterVmOverridesConstants.operationType.REMOVE,
                     removeKey: item
                  });
               } else {
                  someOverridesWillNotBeDeleted = true;
               }
            }
         });

         vmOverridesSpec.orchestrationVmOverride = [];
         _.each(vmKeys, function(item, index) {
            if (vmOverrideInfos[index].haOverrides.overridesResOrchOptions) {
               vmOverridesSpec.orchestrationVmOverride.push({
                  _type: clusterVmOverridesConstants.overrideSpec.ORCH_VM_CONFIG_SPEC,
                  operation: clusterVmOverridesConstants.operationType.REMOVE,
                  removeKey: item
               });
            }
         });


         var clusterComputeResourceSpec =
               clusterSpecBuilderService.buildClusterComputeResourceSpec(
                     vmOverridesSpec, true);

         mutationService.apply(clusterId,
               'com.vmware.vsphere.client.cluster.ClusterComputeResourceSpec',
               clusterComputeResourceSpec
         );

         if (someOverridesWillNotBeDeleted) {
            clarityModalService.openConfirmationModal({
               title: i18nService.getString(
                     "ClusterUi", "actionDeleteVmOverridesCaption"),
               message: i18nService.getString(
                     "ClusterUi", "actionDeleteVmOverridesSomeOverridesWontBeDeleted"),
               icon: 'vx-icon-warning-32x32',
               saveButtonLabel: i18nService.getString("Common", "alert.ok"),
               hideCancelButton: true,
               submit: function() {
                  return true;
               }
            });
         }
      }

      function buildPropertyView(haOverrides) {
         var propertyViewBuilder = vxPropertyViewService.createPropertyViewBuilder();

         buildRestartConditionPropertyViewCategory(propertyViewBuilder, haOverrides);
         buildPdlProtectionPropertyViewCategory(propertyViewBuilder, haOverrides.pdlProtectionLevelText);
         buildApdProtectionPropertyViewCategory(propertyViewBuilder, haOverrides);
         buildVmMonitoringPropertyViewCategory(propertyViewBuilder, haOverrides);
         return propertyViewBuilder.build();
      }

      function buildRestartConditionPropertyViewCategory(propertyViewBuilder, haOverrides) {
         propertyViewBuilder
               .category("restartCondition")
               .title(i18nService.getString('ClusterUi',
                     'vm.overrides.restart.condition.tab.label'))
               .section("restartConditionSection")
               .property(i18nService.getString("ClusterUi",
                     "vm.overrides.restart.condition.label"),
                     haOverrides.haRestartConditionsText)
               .property(i18nService.getString("ClusterUi",
                     "vm.overrides.restart.condition.delay.label"),
                     haOverrides.haRestartConditionDelay)
               .property(i18nService.getString("ClusterUi",
                     "vm.overrides.restart.condition.timeout.label"),
                     haOverrides.haRestartPriorityTimeout);
      }

      function buildPdlProtectionPropertyViewCategory(propertyViewBuilder, pdlProtection) {
         propertyViewBuilder
               .category("pdlProtection")
               .title(i18nService.getString("ClusterUi", "vm.overrides.pdl.tab.label"))
               .section("pdlProtectionSection")
               .title(i18nService.getString("ClusterUi",
                     "vm.overrides.pdl.protection.title"))
               .property(i18nService.getString("ClusterUi",
                     "vm.overrides.pdlAndAdp.failure.response.protection.label"),
                     pdlProtection);
      }

      function buildApdProtectionPropertyViewCategory(propertyViewBuilder, haOverrides) {
         propertyViewBuilder
               .category("apdProtection")
               .title(i18nService.getString("ClusterUi", "vm.overrides.apd.tab.label"))
               .section("apdProtectionSection")
               .title(i18nService.getString("ClusterUi",
                     "vm.overrides.apd.protection.title"))
               .property(i18nService.getString("ClusterUi",
                     "vm.overrides.pdlAndAdp.failure.response.protection.label"),
                     haOverrides.apdProtectionLevelText)
               .property(i18nService.getString("ClusterUi",
                     "vm.overrides.apd.failover.delay.label"),
                     haOverrides.intervalBeforeVmcpReactionText)
               .property(i18nService.getString("ClusterUi",
                     "vm.overrides.apd.response.recovery.label"),
                     haOverrides.vmRemediationOnApdRecoverText);
      }

      function buildVmMonitoringPropertyViewCategory(propertyViewBuilder, haOverrides) {
         propertyViewBuilder
               .category("vmMonitoring")
               .title(i18nService.getString("ClusterUi", "vmOverrides.vmMonitoringHeaderText"))
               .section("vmMonitoringSection")
               .property(i18nService.getString("ClusterUi",
                     "vmOverrides.vmMonitoringHeaderText"),
                     haOverrides.vmMonitoringText)
               .property(i18nService.getString("ClusterUi",
                     "vmOverrides.vmMonitoringSensitivityHeaderText"),
                     haOverrides.vmMonitoringSensitivityText)
               .property(i18nService.getString("ClusterUi",
                     "vm.overrides.failure.interval.label"),
                     haOverrides.vmFailureInterval)
               .property(i18nService.getString("ClusterUi",
                     "vm.overrides.minimum.uptime.label"),
                     haOverrides.vmMinUptime)
               .property(i18nService.getString("ClusterUi",
                     "vm.overrides.max.resests.label"),
                     haOverrides.vmMaxFailures)
               .property(i18nService.getString("ClusterUi",
                     "vm.overrides.max.reset.time.window.label"),
                     haOverrides.vmMaxFailureWindow);
      }
   }
})();
