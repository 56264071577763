namespace h5_vm {

   export class IpAllocationPolicy {
      static readonly DHCP: string = "DHCP";
      static readonly STATIC_MANUAL: string = "STATIC_MANUAL";
      static readonly STATIC_IPPOOL: string = "STATIC_IPPOOL";
      static readonly TRANSIENT_IPPOOL: string = "TRANSIENT_IPPOOL";
   }

   export class IpProtocol {
      static readonly IPV4: string = "IPV4";
      static readonly IPV6: string = "IPV6";
   }

   export class VmProvisioningNetworkMappingController {
      static $inject: string[] = [
         "$scope",
         "i18nService",
         "vuiConstants"
      ];

      private i18n: any;
      private dataGridOptions: any;
      private cancelOnTargetNetworkChanged: any;

      private ipAllocationParams: any;
      private networkMappings: any;

      constructor(private $scope: any, private i18nService: any, private vuiConstants: any) {
         this.i18n = this.i18nService.getString;
      }

      $onInit() {
         if (this.networkMappings) {
            this.dataGridOptions = {
               columnDefs: [
                  {
                     displayName: this
                           .i18nService
                           .getString('ProvisioningUiLib', 'NetworkMappingsControl.templateNetworkColumnHeader'),
                     field: 'name'
                  },
                  {
                     displayName: this
                           .i18nService
                           .getString('ProvisioningUiLib', 'NetworkMappingsControl.selectedNetworkColumnHeader'),
                     field: 'target',
                     template: () => {
                        return this.buildDestinationNetworkDropdown();
                     }
                  }
               ],
               selectionMode: this.vuiConstants.grid.selectionMode.SINGLE,
               selectedItems: [],
               data: this.networkMappings.sourceNetworks
            };

            this.cancelOnTargetNetworkChanged = this.$scope.$on('onTargetNetworkChanged', (e: any, dataItem: any) => {
               var changedNetwork = this.findNetworkByName(dataItem.name);
               changedNetwork.target = dataItem.target;
            });
         }
         if (!_.isEmpty(this.ipAllocationParams)) {
            this.populateLocalizedNamesForIpAllocation();
            this.populateLocalizedNamesForIpProtocol();
         }
      }

      public shouldDisplayIpAllocation(): boolean {
         return !_.isEmpty(this.ipAllocationParams);
      }

      private findNetworkByName(name: string) {
         var network = _.find(this.networkMappings.sourceNetworks, (nw: any) => {
            return nw.name === name;
         });
         return network;
      }

      private buildDestinationNetworkDropdown() {
         var dropdownTemplate: any = ['<div class="select" >',
            '<select class="location-select"',
            'ng-change="$emit(\'onTargetNetworkChanged\',dataItem)"',
            'name="destination-networks"',
            'ng-model="dataItem.target">'];

         _.each(this.networkMappings.targetNetworks, (network: any) => {
            if (network.accessible) {
               dropdownTemplate.push(this.buildDestinationNetworkOption(network.id, network.name));
            }
         });

         dropdownTemplate.push([
            `</select></div>`
         ]);

         var template = dropdownTemplate.join('');
         return template;
      }

      private buildDestinationNetworkOption(id: string, name: string) {
         return `<option value="${id}">${name}</option>`;
      }

      private getLocalizedIpAllocationPolicy(policy: string) {
         switch (policy) {
            case IpAllocationPolicy.STATIC_MANUAL:
               return this.i18nService.getString("ProvisioningUiLib", "IpAllocationControl.staticManual");
            case IpAllocationPolicy.DHCP:
               return this.i18nService.getString("ProvisioningUiLib", "IpAllocationControl.dhcp");
            case IpAllocationPolicy.STATIC_IPPOOL:
               return this.i18nService.getString("ProvisioningUiLib", "IpAllocationControl.staticIpPool");
            case IpAllocationPolicy.TRANSIENT_IPPOOL:
               return this.i18nService.getString("ProvisioningUiLib", "IpAllocationControl.transientIpPool");
         }
      }

      private getLocalizedIpProtocol(protocol: string) {
         switch (protocol) {
            case IpProtocol.IPV4:
               return this.i18nService.getString("ProvisioningUiLib", "IpAllocationControl.ipv4");
            case IpProtocol.IPV6:
               return this.i18nService.getString("ProvisioningUiLib", "IpAllocationControl.ipv6");
         }
      }

      private populateLocalizedNamesForIpAllocation() {
         this.ipAllocationParams.supportedIpAllocationPolicy = this.ipAllocationParams.supportedIpAllocationPolicy.map((o: any) => {
            o.localizedName = this.getLocalizedIpAllocationPolicy(o.name);
            return o;
         });
      }

      private populateLocalizedNamesForIpProtocol() {
         this.ipAllocationParams.supportedIpProtocol = this.ipAllocationParams.supportedIpProtocol.map((o: any)=> {
            o.localizedName = this.getLocalizedIpProtocol(o.name);
            return o;
         });
      }
   }

   export class VmProvisioningNetworkMappingComponent {
      public controller: any;
      public templateUrl: string;
      public bindings: any;

      constructor() {
         this.controller = VmProvisioningNetworkMappingController;
         this.templateUrl = 'vm-ui/resources/vm/views/createVmWizard/vmProvisioningNetworkMapping/vm-provisioning-network-mapping.html';
         this.bindings = {
            networkMappings: "=",
            ipAllocationParams: "=",
            isValidated: "="
         };
      }
   }

   angular.module("com.vmware.vsphere.client.vm")
         .component("vmProvisioningNetworkMapping", new VmProvisioningNetworkMappingComponent());

}
