module ds_cluster_ui {

   class SdrsRulesViewController {

      public watchForObjects: string[];
      public gridOptions: any;
      public selectedRule: any = null;
      public selectedRuleDetails: any = null;
      private objectId: string;
      private actionSpecs: any[];
      private datagridActionBarServiceCacheObject: any;
      private liveRefreshProperties: Array<string>;
      private isFilterAdded: boolean = false;
      private filterElement: any;

      public static $inject = ["$scope", "$compile", "$element",
            "sdrsRulesService", "dsClusterConstants",
            "datagridActionBarService", "i18nService"];

      constructor(private $scope: any,
            private $compile: any,
            private $element: any,
            private sdrsRulesService: SdrsRulesService,
            public dsClusterConstants: any,
            private datagridActionBarService: any,
            private i18nService: any) {
         this.objectId = $scope._route.objectId;
         this.watchForObjects = [this.objectId];
         this.liveRefreshProperties =
               [this.dsClusterConstants.liveRefreshProperties.STORAGE_DRS_CONFIG];
         $scope.rulesPreselectComparator = this.rulesPreselectComparator.bind(this);

         this.actionSpecs = [{
               actionId: "vsphere.core.dscluster.actions.rule.add"
            }, {
               actionId: "vsphere.core.dscluster.actions.rule.edit",
               getActionInvocationContext: () => {
                  return {
                     selectedRule: this.selectedRule,
                     selectedRuleDetails: this.selectedRuleDetails
                  };
               },
               isActionAvailable: (actionDef: any) => {
                  return actionDef.available && this.gridOptions.selectedItems.length > 0;
               }
            }, {
               actionId: 'vsphere.core.dscluster.actions.rule.remove',
               getActionInvocationContext: () => {
                  return {
                     selectedRule: this.selectedRule
                  };
               },
               isActionAvailable: (actionDef: any) => {
                  return actionDef.available && this.gridOptions.selectedItems.length > 0;
               }
            }];

         this.datagridActionBarServiceCacheObject = null;

         this.gridOptions = sdrsRulesService.getMasterGridOptions(
               ()=> {
                  this.onDataBound();
               });

         $scope.$watch("ctrl.gridOptions.selectedItems",
               this.onSelectedRuleChange.bind(this));
         $scope.$watch(
               () => {
                  return this.selectedRule;
               }, () => {
                  this.refreshActionBar();
               });

         let filterPlaceholderText = this.i18nService.getString('Common', 'filter.label');
         let filterButtonText = i18nService.getString('Common', 'applyFilter.label');
         this.filterElement = $compile(
               `<div class="filter-input pull-right">
                <button class="nav-icon fa fa-filter filter-button" ng-click="doSearch()"
                      aria-label="${filterButtonText}" /></button>
                <input type="text" ng-model="searchTerm" ng-trim="true"
                ng-change="debouncedRefreshCallback()"
                ng-keypress="keyPress($event)"
                placeholder="${filterPlaceholderText}"/></div>`)($scope);

         $scope.doSearch = ()=> {
            this.doSearch();
         };
         $scope.keyPress = (event: KeyboardEvent)=> {
            this.handleKeyPress(event);
         };

         this.getViewData();
         this.createActionBar();
      }

      public doSearch(): void {
         this.getViewData();
      }

      public handleKeyPress(event: KeyboardEvent): void {
         if (event.keyCode === 13) {
            // In IE Enter would activate the focused item so
            // we prevent this default behavior.
            event.preventDefault();
            event.stopPropagation();
            this.getViewData();
         }
      }

      public getViewData(): void {
         this.sdrsRulesService.getMasterViewData(
               this.objectId, this.$scope.searchTerm).then((data: any): void => {
            this.gridOptions.data = _.map(data, function (rule) {
               return angular.merge({
                  _original: rule
               }, rule);
            });

            this.keepSelectionOrPreselectFirst();
            this.refreshActionBar();
         });
      }

      private createActionBar(): void {
         this.datagridActionBarService
            .updateActionBar(this.gridOptions, [this.objectId], this.actionSpecs)
            .then((value: any) => {
                this.datagridActionBarServiceCacheObject = value;
            });
      }

      private refreshActionBar(): void {
         if (!this.datagridActionBarServiceCacheObject) {
            return;
         }

         this.datagridActionBarService.updateActionBar(
               this.gridOptions, [this.objectId], this.actionSpecs,
               this.datagridActionBarServiceCacheObject);
      };

      public rulesPreselectComparator(ruleItem: any): boolean {
         return this.selectedRule && ruleItem &&
               this.selectedRule.rule.name === ruleItem.rule.name;
      }

      private onSelectedRuleChange(newSelectedItems: any): void {
         if (!newSelectedItems || newSelectedItems.length === 0) {
            this.selectedRule = null;
            return;
         }
         //compare new selected item name with current selected rule name
         //instead of with old selected item name from grid to prevent changing the
         //selecting rule twice on preselect and triggering its watcher twice
         if (this.selectedRule && newSelectedItems[0].rule.name ===
               this.selectedRule.rule.name) {
            return;
         }

         this.selectedRule = newSelectedItems[0]._original;
      }

      private keepSelectionOrPreselectFirst(): void {
         if (this.gridOptions.data && this.gridOptions.data.length > 0) {
            let itemToPreselect: any;

            if (this.selectedRule) {
               itemToPreselect = _.find(this.gridOptions.data, (ruleRow: any) => {
                  return ruleRow.rule.name === this.selectedRule.rule.name;
               });
            }

            this.selectedRule = !!itemToPreselect ?
                  itemToPreselect._original : this.gridOptions.data[0]._original;
         }
      }


      private onDataBound(): void {
         if (!this.isFilterAdded) {
            this.addToolbarFilter();
         }
      }

      private addToolbarFilter(): void {
         let grid: any = this.getGridElement();
         if (grid) {
            let toolbarElement = grid.find('.k-grid-toolbar');
            if (toolbarElement.length > 0) {
               toolbarElement.append(this.filterElement);
               this.isFilterAdded = true;
            }
         }
      }

      private getGridElement(): void {
         const element = this.$element.find('.k-grid-content');
         return element && element.parent();
      }

   }

   angular.module("com.vmware.vsphere.client.dsCluster")
         .controller("SdrsRulesViewController", SdrsRulesViewController);
}
