/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace dvs_ui {

   import IPromise = angular.IPromise;
   export class DvsLagPageValidator implements WizardPageValidator {

      public static $inject = [
            "i18nService",
            "dvsUpgradeLacpWizardConstants"];

      constructor(private i18nService: any,
                  private wizardConstants: any) {
      }

      public getValidationError(wizardScope: DvsUpgradeLacpWizardScope): string[] {
         return this.getLagNameError(wizardScope.model.lag.name);
      }

      public getLagNameError(lagName: string): string[] {
         if (!lagName || lagName.trim().length === 0) {
            return [this.i18nService.getString(
                  "DvsUi", "lag.name.nameRequired")];
         } else if (lagName.trim().length > this.wizardConstants.lag.NAME_MAX_LENGTH) {
            return [this.i18nService.getString(
                  "DvsUi", "lag.name.nameTooLong")];
         }

         return [];
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .service("dvsLagPageValidator", DvsLagPageValidator);
}
