namespace storage_ui {

   export interface DeleteFilesSpec {
      sourceDatacenter: string;
      targetFiles: string[];
      fileTypes: string[];
   }

   export class DeleteFilesService {

      public static $inject = ["i18nService", "mutationService", "clarityModalService"];

      constructor(private i18nService: any,
                  private mutationService: any,
                  private clarityModalService: any) {
      }

      public openDeleteFilesDialog(dsRef: any, targetFileNames: string[],
            spec: DeleteFilesSpec, callback: Function): void {
         let confirmationMessage: string = this.i18nService.getString('StorageUi', 'actionDeleteFilesConfirmationText');
         if (targetFileNames.length === 1) {
            confirmationMessage = this.i18nService.getString('StorageUi', 'actionDeleteFileConfirmationText',
                  targetFileNames[0]);
         }

         const modalOptions: any = {
            title: this.i18nService.getString('StorageUi', 'actionDeleteFileConfirmationTitle'),
            subTitle: {
               objectId: dsRef
            },
            defaultButton: 'close',
            message: confirmationMessage,
            clarityIcon: {
               class: "is-warning",
               shape: "warning-standard",
               size: "32"
            },
            saveButtonLabel: this.i18nService.getString(
                  "Common", "yes.label"),
            cancelButtonLabel: this.i18nService.getString(
                  "Common", "no.label"),
            submit: () => {
               this.mutationService.apply(
                     dsRef,
                     "com.vmware.vsphere.client.h5.storage.spec.DatastoreDeleteFileSpec",
                     spec).then(function (result: any) {
                  if (callback) {
                     callback(result);
                  }
               });

               return true;
            }
         };

         this.clarityModalService.openConfirmationModal(modalOptions);
      }
   }

   angular.module("com.vmware.vsphere.client.storage")
         .service("deleteFilesService", DeleteFilesService);
}
