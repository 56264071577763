angular.module('com.vmware.vsphere.client.vm').service('vmProvisioningGuestOsPageService', [
   'i18nService',
   'vuiConstants',
   'wizardPageService',
   'vmVuiWizardPageBuilder',
   'VmProvisioningGuestOsPageModel',
   function(
      i18nService,
      vuiConstants,
      wizardPageService,
      vmVuiWizardPageBuilder,
      VmProvisioningGuestOsPageModel) {
      return {
         build: function(wizardScope) {
            var guestOsPageModel = VmProvisioningGuestOsPageModel(wizardScope.vmParams);
            wizardScope.guestOsPageModel = guestOsPageModel;

            return vmVuiWizardPageBuilder.buildVuiWizardPage(wizardScope.config, {
               title: i18nService.getString('VmUi', 'SelectGuestOsProvisioningPage.Title'),
               description: i18nService.getString('VmUi', 'SelectGuestOsProvisioningPage.Description'),
               contentUrl: 'vm-ui/resources/vm/views/createVmWizard/vmProvisioningGuestOs.html',
               model: guestOsPageModel
            });
         }
      };
   }
]);
