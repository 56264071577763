namespace dvs_ui {

   export class DvpgPolicyTypePageComponent {

      public bindings: any;
      public controller: any;
      public templateUrl: string;

      constructor() {
         this.bindings = {
            wizardModel: "<",
            wizardFlags: "<",
            wizardConfig: "<"
         };

         this.controller = DvpgPolicyTypePageComponentController;

         this.templateUrl = "dvs-ui/resources/dvs/dvpg/manage/views/" +
               "dvpgPolicyTypePageTemplate.html";
      }
   }

   class DvpgPolicyTypePageComponentController {

      static $inject = [
            "i18nService",
            "$scope",
            "dataService",
            "dvpgManageWizardConstants",
            "dvpgFailoverPolicyPageService"
      ];

      public wizardModel: DvpgManageWizardModel;
      public wizardFlags: DvpgManageWizardFlags;

      public wizardConfig: any;
      public signPostConfig: any;
      public i18n: any;

      constructor(private i18nService: any,
                  private $scope: any,
                  private dataService: any,
                  private wizardConstants: any,
                  private dvpgFailoverPolicyPageService: any) {
         this.i18n = this.i18nService.getString;
      }

      $onInit(): void {
         if (!this.wizardFlags.isPolicyTypePageInitialized) {
            this.wizardConfig.loading = true;
            this.requestPolicyAvailabilityData();
         } else {
            this.buildSignpostItems();
         }
      }

      private requestPolicyAvailabilityData(): void {
         this.dataService.getProperties(
               this.wizardModel.parentId,
                     [this.wizardConstants.properties.POLICY_DATA])
               .then((response: any): void => {
                  let policyData: any =
                        response[this.wizardConstants.properties.POLICY_DATA];

                  this.wizardModel.policies.availability = policyData.policyTypeModel;
                  this.wizardModel.securityPolicyModel = policyData.securityPolicyModel;
                  this.wizardModel.failoverPolicyModel = policyData.failoverPolicyModel;
                  this.wizardModel.failoverOrderOptions =
                        this.dvpgFailoverPolicyPageService.createFailoverOrderSettings(
                              this.wizardModel.failoverPolicyModel.failoverOrder);
                  this.wizardModel.resourceAllocationPolicyModel =
                        policyData.resourceAllocationPolicyModel;
                  this.wizardModel.trafficShapingPolicyModel =
                        policyData.trafficShapingPolicyModel;
                  this.wizardModel.vlanPolicyModel = policyData.vlanPolicyModel;
                  this.wizardModel.monitoringPolicyModel =
                        policyData.monitoringPolicyModel;
                  this.wizardModel.miscPolicyModel = policyData.miscPolicyModel;

                  this.buildSignpostItems();

                  this.wizardConfig.loading = false;
                  this.wizardFlags.isPolicyTypePageInitialized = true;
               });
      }

      private buildSignpostItem(title: string, description: string, id: string): string {
         let titleTemplate: string = "<b>%title%</b><br/>"
               .replace("%title%", title);
         let descriptionTemplate: string = "<div class='horizontal-flex-container'>" +
               "<span class='dvpg-manage-policy-type-description-item'>%description%</span><br/></div>"
                     .replace("%description%", description);
         let content: string = titleTemplate + descriptionTemplate;
         let sectionTemplate: string =
               "<div data-test-id='dvpg-manage-policy-type-%id%'>%content%<br/></div>"
                     .replace("%content%", content).replace("%id%", id);
         return sectionTemplate;
      }

      private buildSignpostItems(): void {
         let signpostMessage: string = "";

         if (this.wizardModel.policies.availability.isSecurityPolicyAvailable) {
            signpostMessage = this.buildSignpostItem(
                  this.i18nService.getString(
                        'DvsUi', 'dvpg.manage.policy.security.title'),
                  this.i18nService.getString(
                        'DvsUi', 'dvpg.manage.policy.security.description'),
                  "security"
            );
         }

         signpostMessage += this.buildSignpostItem(
               this.i18nService.getString(
                     'DvsUi', 'dvpg.manage.policy.shaping.title'),
               this.i18nService.getString(
                     'DvsUi', 'dvpg.manage.policy.shaping.description'),
               "traffic-shaping"
         );

         if (this.wizardModel.policies.availability.isVlanPolicyAvailable) {
            signpostMessage += this.buildSignpostItem(
                  this.i18nService.getString(
                        'DvsUi', 'dvpg.manage.policy.vlan.title'),
                  this.i18nService.getString(
                        'DvsUi', 'dvpg.manage.policy.vlan.description'),
                  "vlan"
            );
         }

         if (this.wizardModel.policies.availability.isFailoverPolicyAvailable) {
            signpostMessage += this.buildSignpostItem(
                  this.i18nService.getString(
                        'DvsUi', 'dvpg.manage.policy.failover.title'),
                  this.i18nService.getString(
                        'DvsUi', 'dvpg.manage.policy.failover.description'),
                  "failover"
            );
         }

         if (this.wizardModel.policies.availability.isResAllocPolicyAvailable) {
            signpostMessage += this.buildSignpostItem(
                  this.i18nService.getString(
                        'DvsUi', 'dvpg.manage.policy.resourceAllocation.title'),
                  this.i18nService.getString(
                        'DvsUi', 'dvpg.manage.policy.resourceAllocation.description'),
                  "resource-allocation"
            );
         }

         if (this.wizardModel.policies.availability.isMonitoringPolicyAvailable) {
            signpostMessage += this.buildSignpostItem(
                  this.i18nService.getString(
                        'DvsUi', 'dvpg.manage.policy.monitoring.title'),
                  this.i18nService.getString(
                        'DvsUi', 'dvpg.manage.policy.monitoring.description'),
                  "monitoring"
            );
         }

         if (this.wizardModel.policies.availability.isTrafficFilterPolicyAvailable) {
            signpostMessage += this.buildSignpostItem(
                  this.i18nService.getString(
                        'DvsUi', 'dvpg.manage.policy.trafficFilter.title'),
                  this.i18nService.getString(
                        'DvsUi', 'dvpg.manage.policy.trafficFilter.description'),
                  "traffic-filter"
            );
         }

         signpostMessage += this.buildSignpostItem(
               this.i18nService.getString(
                     'DvsUi', 'dvpg.manage.policy.misc.title'),
               this.i18nService.getString(
                     'DvsUi', 'dvpg.manage.policy.misc.description'),
               "misc"
         );

         this.signPostConfig = {
            message: "<div class='dvpg-manage-policy-type-content'>%content%</div>"
                  .replace("%content%", signpostMessage),
            title: this.i18nService.getString(
                  "DvsUi", "dvpg.manage.policyType.signpost.title")
         };
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .component("dvpgPolicyTypePage", new DvpgPolicyTypePageComponent());
}
