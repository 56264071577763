(function() {
   'use strict';
   angular.module('com.vmware.vsphere.client.commonModule')
      .controller('CustomAttributesController', CustomAttributesController);

   CustomAttributesController.$inject = ['i18nService', 'vuiConstants', 'customAttributesService', '$scope', 'customAttributesActionService'];

   function CustomAttributesController(i18nService, vuiConstants, customAttributesService, $scope, customAttributesActionService) {
      var self = this;

      function refreshCustomAttributes() {
         customAttributesService.getAllCustomAttributes().then(function(customAttributesData) {
            self.datagridOptions.data = customAttributesData;
         });
      }

      function deleteCustomAttribute() {
         customAttributesActionService.deleteCustomAttribute(self.datagridOptions.selectedItems[0]);
      }

      function editCustomAttribute() {
         customAttributesActionService.editCustomAttribute(self.datagridOptions.selectedItems[0], i18nService.getString('CommonModuleUi', 'edit.attribute'));
      }

      function addCustomAttribute() {
         customAttributesActionService.editCustomAttribute(null, i18nService.getString('Common', 'editCustomFieldDefinitionView.add.title'));
      }

      var deleteAction = {
         id: 'delete-custom-action',
         iconClass: 'vsphere-icon-toolbar-remove',
         tooltipText: i18nService.getString('Common', 'ca.delete.delete'),
         enabled: false,
         onClick: deleteCustomAttribute
      };
      var editAction = {
         id: 'edit-tag-action',
         iconClass: 'vsphere-icon-toolbar-edit',
         tooltipText: i18nService.getString('CommonModuleUi', 'edit.attribute'),
         enabled: true,
         onClick : editCustomAttribute
      };

      var addAction = {
         id: 'add-tag-action',
         iconClass: 'vsphere-icon-toolbar-add',
         tooltipText: i18nService.getString('CommonModuleUi', 'add.attribute'),
         enabled: true,
         onClick : addCustomAttribute
      };

      self.datagridOptions = {
         actionBarOptions: {
            actions: [
               addAction,
               editAction,
               deleteAction
            ]
         },
         columnDefs: [
            {
               displayName: i18nService.getString('Common', 'attributesName'),
               field: 'attribute',
               sortable: true,
               searchable: false
            },
            {
               displayName: i18nService.getString('Common', 'attributesGrid.typeColumnHeaderText'),
               field: 'type',
               sortable: true,
               searchable: false,
               template: function(dataItem) {
                  return i18nService.getString('Common', 'fieldType.' + dataItem.type);
               }
            },
            {
               displayName: i18nService.getString('Common', 'ca.values.key'),
               field: 'key',
               visible: false
            }
         ],
         sortOrder: [{
            field: 'attribute',
            dir: 'asc'
         }],
         height: '100%',
         resizable: false,
         reorderable: true,
         selectionMode: vuiConstants.grid.selectionMode.SINGLE,
         selectedItems: [],
         data: []
      };

      $scope.$watch('ctrl.datagridOptions.selectedItems', function(selectedItems) {
         var selected = selectedItems && selectedItems.length;
         deleteAction.enabled = selected;
         editAction.enabled = selected;
      });

      $scope.$on('refreshCustomAttributes', function(){
         refreshCustomAttributes();
      });

      refreshCustomAttributes();
   }
}());
