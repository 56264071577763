namespace license_ui {

   export class SerialKeyLicenseActionsService {
      static $inject = ['$injector'];

      private _vcSerialKeyLicenseActionsService: SerialKeyLicenseActionsService;

      constructor(private $injector: any) {
      }

      get vcSerialKeyLicenseActionsService() {
         if (!this._vcSerialKeyLicenseActionsService) {
            this._vcSerialKeyLicenseActionsService = this.$injector.get("vcSerialKeyLicenseActionsService");
         }
         return this._vcSerialKeyLicenseActionsService;
      }

      removeLicenseKey(licenseUriIds: string[]): void {
         this.vcSerialKeyLicenseActionsService.removeLicenseKey(licenseUriIds);
      }

      renameSerialKeyLicense(license: any): void {
         this.vcSerialKeyLicenseActionsService.renameSerialKeyLicense(license);
      }

      addSerialKeyLicense(scope: string): void {
         this.vcSerialKeyLicenseActionsService.addSerialKeyLicense(scope);
      }

      syncSerialKeyLicenses(scope: string): void {
         this.vcSerialKeyLicenseActionsService.syncSerialKeyLicenses(scope);
      }
   }

   angular.module('com.vmware.vsphere.client.licenseUi').service(
         'serialKeyLicenseActionsService',
         SerialKeyLicenseActionsService
   );
}
