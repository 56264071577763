namespace h5_vm {
   import Specification = com.vmware.vim.binding.vim.vm.customization.Specification;
   import CustomizeGosPageModelBase = h5_vm.CustomizeGosPageModelBase;

   export class CustomizeGosUserSettingsPageModel extends CustomizeGosPageModelBase {
      private _vmGuestOsType: string;
      private _specification: Specification;

      constructor(vmId: string, vmGuestOsFullName: string, vmGuestOsType: string,
            specification: Specification) {
         super(vmId, vmGuestOsFullName);
         this._vmGuestOsType = vmGuestOsType;
         this._specification = specification;
      }

      get vmGuestOsType(): string {
         return this._vmGuestOsType;
      }

      get specification(): Specification {
         return this._specification;
      }
   }
}