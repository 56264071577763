module ds_cluster_ui {

   angular.module("com.vmware.vsphere.client.dsCluster").constant("dsClusterConstants", {
      DS_CLUSTER_SPEC_TYPE: "com.vmware.vsphere.client.storagedrs.DatastoreClusterSpec",
      HOSTS_AND_CLUSTERS_VALIDATE_SPEC:
            "com.vmware.vsphere.client.dsclusterui.impl.CreateDsClusterSpec",
      NAME_AND_LOCATION_VALIDATE_SPEC:
            "com.vmware.vsphere.client.dscluster.DatastoreClusterNameValidationSpec",
      DS_CLUSTER_VM_AFFINITY_VALIDATION_SPEC: "com.vmware.vsphere.client.dscluster.DefaultVmAffinityValidationSpec",
      SDRS_RULE_INFO_SPEC: "com.vmware.vsphere.client.storagedrs.rules.SdrsRuleInfo",
      SDRS_RULE_SPEC: "com.vmware.vsphere.client.dscluster.SdrsRuleSpec",
      SDRS_VM_OVERRIDE_SPEC: "com.vmware.vsphere.client.dscluster.VmOverridesReconfigureSpec",
      SDRS_MANUAL: "manual",
      SDRS_FULLAUTO: "automated",
      SDRS_SPACE_THRESHOLD_FREE_SPACE: "freeSpace",
      SDRS_SPACE_THRESHOLD_UTILIZATION: "utilization",
      loadBalanceInterval: {
         HOUR_MINUTES: 60,
         DAY_MINUTES: 60 * 24,
         MIN_HOURS: 1,
         MAX_DAYS: 29
      },
      loadBalanceIoThreshold: {
         DEFAULT: 5,
         MIN: 1,
         MAX: 100
      },
      minSpaceUtilizationDifference: {
         MIN: 1,
         MAX: 50
      },
      ioLatencyThreshold: {
         DEFAULT: 15,
         MIN: 5,
         MAX: 100
      },
      spaceUtilizationThreshold: {
         DEFAULT: 80,
         MIN: 50,
         MAX: 100
      },
      freeSpaceThresholdGB: {
         MIN: 1
      },
      ruleTypes: {
         VM_ANTI_AFFINITY_TYPE: "VM_ANTIAFFINITY_RULE_TYPE",
         VMDK_ANTI_AFFINITY_TYPE: "VIRTUAL_DISK_ANTI_AFFINITY_RULE_TYPE"
      },
      ruleSpec: {
         VM_ANTI_AFFINITY_TYPE : "com.vmware.vim.binding.vim.cluster.AntiAffinityRuleSpec",
         VMDK_ANTI_AFFINITY_TYPE : "com.vmware.vim.binding.vim.storageDrs.VirtualDiskAntiAffinityRuleSpec"
      },
      operationType: {
         ADD: "add",
         REMOVE: "remove",
         EDIT: "edit"
      },
      vmOverridesOperationType: {
         ADD: "Add",
         REMOVE: "Remove",
         EDIT: "Edit"
      },
      affinityRuleMinimumMembers: 2,
      sdrsVmOverridesAutomationLevels: {
         automated: "Automated",
         manual: "Manual",
         disabled: "Disabled",
         clusterDefault: "ClusterDefault",
         useInitialValues:"useInitialValues"
      },
      keepVmdksValues: {
         clusterDefault: "ClusterDefault",
         useInitialValues: "useInitialValues"
      },
      MAX_INTEGER: 2147483647,
      liveRefreshProperties: {
         RECOMMENDATION: "podStorageDrsEntry.recommendation",
         FAULT: "podStorageDrsEntry.drsFault",
         STORAGE_DRS_CONFIG: "podStorageDrsEntry.storageDrsConfig",
         SUMMARY: "summary"
      },
      maintenanceMode: {
         enter: "enter",
         exit: "exit",
         applyRecommendations: "applyRecommendations"
      }
   });
}
