module hostprofile_ui {

    class HostProfilesSummaryViewController implements angular.IController {

        private selectedProfiles: any[];

        private readonly DUPLICATE_PROFILE: string = "vsphere.core.hostProfile.actions.duplicateProfile";
        private readonly CHANGE_REF_HOST: string = "vsphere.core.hostProfile.actions.changeRefHostAction";
        private readonly COPY_HOST_PROFILE_SETTINGS: string = "vsphere.core.hostProfile.actions.copySettingsToHostProfiles";
        private readonly EXPORT_PROFILE: string = "vsphere.core.hostProfile.actions.exportProfile";
        private readonly IMPORT_PROFILE: string = "vsphere.core.hostProfile.actions.importProfile";
        private readonly EXTRACT_HOST_PROFILE_FROM_A_HOST: string =
              "vsphere.core.hostProfile.actions.extractHostProfileFromAHost";
        private readonly DELETE_PROFILE: string = "vsphere.core.hostProfile.actions.deleteProfile";
        private readonly ACTION_BAR_ACTIONS: string = "actions";

        public actionBarOptions: any;
        private actionDefs: any[];

        public static $inject = ["$scope", "commonActionBarService", "vuiConstants", "i18nService"];

        constructor(private $scope: any,
                    private commonActionBarService: any,
                    private vuiConstants: any,
                    private i18nService: any) {

            this.$scope.onSelectionChanged = (newItems: any, oldItems: any) => {
                this.actionBarOptions.actions = [];
                this.selectedProfiles = newItems;
                this.createOrUpdateActionBar();
            };

            this.actionBarOptions = {
                actions: []
            };

            this.actionDefs = [
                {
                   actionId: this.EXTRACT_HOST_PROFILE_FROM_A_HOST,
                   customLabel: this.i18nService.getString("HostProfileUi", "action.extractHostProfileFromHost.label"),
                   hideIcon: true
                },
                {
                   actionId: this.IMPORT_PROFILE
                },
                this.vuiConstants.actions.SEPARATOR,
                {
                   actionId: this.DUPLICATE_PROFILE,
                   customLabel: this.i18nService.getString("HostProfileUi", "action.duplicateHostProfile.label"),
                   isActionAvailable: (actionDef: any) => {
                        return !_.isEmpty(this.selectedProfiles) && actionDef.available;
                    },
                    hideIcon: true
                },
                {
                    actionId: this.CHANGE_REF_HOST,
                    customLabel: this.i18nService.getString("HostProfileUi", "actionChangeRefHostDescription"),
                    isActionAvailable: (actionDef: any) => {
                        return !_.isEmpty(this.selectedProfiles) && actionDef.available;
                    },
                    hideIcon: true
                },
                {
                    actionId: this.COPY_HOST_PROFILE_SETTINGS,
                    customLabel: this.i18nService.getString("HostProfileUi", "actionCopyHostProfileSettingsDescription"),
                    isActionAvailable: (actionDef: any) => {
                        return !_.isEmpty(this.selectedProfiles) && actionDef.available;
                    },
                    hideIcon: true
                },
                {
                    actionId: this.EXPORT_PROFILE,
                    isActionAvailable: (actionDef: any) => {
                        return !_.isEmpty(this.selectedProfiles) && actionDef.available;
                    },
                    hideIcon: true
                },
                {
                    actionId: this.DELETE_PROFILE,
                    isActionAvailable: (actionDef: any) => {
                        return !_.isEmpty(this.selectedProfiles) && actionDef.available;
                    },
                    hideIcon: true
                }
            ];

            this.createOrUpdateActionBar();
        }

        private createOrUpdateActionBar() {
            this.commonActionBarService
                .updateActionBar(
                    this.actionBarOptions,
                    this.ACTION_BAR_ACTIONS,
                    this.getProfilesIds(),
                    this.actionDefs
                );
        }

        private getProfilesIds(): string[] {
            return _.pluck(this.selectedProfiles, "id");
        }

    }
    angular.module("com.vmware.vsphere.client.hostprofile")
        .controller("HostProfilesSummaryViewController", HostProfilesSummaryViewController);
}
