/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
module hostprofile_ui {

   class HostProfileComplianceController implements angular.IController {
      public static $inject = [
         "$scope",
         "$element",
         "i18nService",
         "clusterHostProfileSettingsService",
         "vcService",
         "defaultUriSchemeUtil",
         "dataService",
         "localStorageService"];

      public readonly REMEDIATION_RESULT_KEY: string = "remediationTaskErrorResult_";

      public is66VcOrLater: boolean;
      public selectedItemId: String;
      public gridData: any[];
      isComplianceExpanded: boolean = false;
      private objectsToWatch: any = [];
      private headerOptions: any;
      private objectId: any;
      private splitterOptions: any;
      private attachDetachViewHeaderAccessor: any = {};
      private liveRefreshProperties: Array<string>;
      private focusedItem: any;
      private hostsCompliance: any;
      private remediationErrorBannerVisible: boolean;

      constructor(private $scope: any,
                  private $element: any,
                  private i18nService: any,
                  private clusterHostProfileSettingsService: ClusterHostProfileSettingsService,
                  private vcService: any, private defaultUriSchemeUtil: any,
                  private dataService: any, public localStorageService: any) {

         this.splitterOptions = this.clusterHostProfileSettingsService.getSplitterOptions();
         this.objectId = this.$scope._route.objectId;
         this.focusedItem = null;
         this.objectsToWatch = [this.objectId];
         this.liveRefreshProperties = ["configStatus", "complianceCheckState",
            "remediationState", "answerFileValidationState", "entity", "complianceStatus"];
         this.headerOptions = {
            objectId: this.objectId,
            title: this.i18nService.getString("HostProfileUi", "cluster.monitor.compliance.host"),
            actions: [{
               actionUid: "vsphere.core.hostProfile.actions.hostProfile.checkHostProfileCompliance",
               customLabel: this.i18nService.getString("HostProfileUi", "hostProfile.action.checkCompliance")
            }, {
               actionUid: "vsphere.core.hostProfile.actions.hostProfile.remediateMultipleHosts",
               customLabel:this.i18nService.getString("HostProfileUi", "actionRemediateHostLabel")
            }]
         };

         vcService.is66VcOrLater(this.objectId).then((is66VcOrLater: boolean) => {
            this.is66VcOrLater = is66VcOrLater;
         });

         // show or hide remediation error banner based on the remediation task result
         this.$scope.$on("hostProfileRemediationErrorsEvent", (event: any, showBanner: boolean) => {
            this.remediationErrorBannerVisible = showBanner;
         });
      }

      // Used to force refresh of the data in the child component (changing the reference triggers ngOnChanges in
      // host-profile-host-settings-details)
      public refresh65ComplianceFailures(): void {
         this.selectedItemId = new String(this.selectedItemId);
      }

      public onDataReceived(data: any): void {
         this.gridData = data;

         this.removeRemediationErrorsBanner(data);
      }

      public onFocusGridRow(focusedItem: any): void {
         this.focusedItem = focusedItem;
         if (focusedItem) {
            this.selectedItemId = this.defaultUriSchemeUtil.getVsphereObjectId(
                  focusedItem.provider);
         }
      }

      public onRefreshActionInvoked(event: any): void {
         this.refreshContent();
      }

      // show the remediation error banner if the remediation task finish fails
      public showRemediationErrorBanner(event: boolean): void {
         if (event === true && !this.remediationErrorBannerVisible) {
            this.remediationErrorBannerVisible = event;
         }
      }

      // hide the remediation error banner if the remediation task finish successfully
      public hideRemediationErrorBanner(event: boolean): void {
        if (event === false && this.remediationErrorBannerVisible) {
           this.remediationErrorBannerVisible = event;
        }
      }

      onComplianceExpanded(): void {
         this.isComplianceExpanded = true;
         let compliancePanel = this.getCompliancePanel();
         let splitter = this.getSplitter();
         splitter.min(compliancePanel, "400px");
         if (compliancePanel.height() < 400) {
            splitter.size(compliancePanel, "400px");
         }
      }

      onComplianceCollapsed(): void {
         this.isComplianceExpanded = false;
         let compliancePanel = this.getCompliancePanel();
         let splitter = this.getSplitter();
         splitter.min(compliancePanel, "240px");
      }

      getSplitterHolderClass(): string {
         return this.isComplianceExpanded ?
               "splitter-holder-compliance-expanded" :
               "splitter-holder-compliance-collapsed";
      }

      private getCompliancePanel(): any {
         return this.$element.find(
               ".profile-compliance-failure-section");
      }

      private getSplitter(): any {
         return this.$element.find("[kendo-splitter]")
               .data("kendoSplitter");
      }

      private getComplianceTitle(): string {
         let placeholder: string = "";
         if (this.focusedItem) {
            placeholder = this.focusedItem.hostName;
         }
         return this.i18nService.getString("HostProfileUi",
               "compliance.status.labelWithPlaceholder", placeholder);
      }

      private refreshContent() {
         if (this.attachDetachViewHeaderAccessor &&
               this.attachDetachViewHeaderAccessor.refresh) {
            this.attachDetachViewHeaderAccessor.refresh();
            // Used to force refresh of the data in the child component (changing the reference triggers ngOnChanges in
            // host-profile-hosts-compliance)
            this.objectId = new String(this.objectId);
         }
      }

      // if no hosts are attached to the host profile the remediation errors banner should be hidden
      // and related local storage data removed
      private removeRemediationErrorsBanner(data: any) {
         if (!data || (data && data.length === 0)) {
            this.remediationErrorBannerVisible = false;
            this.clearRemediationErrorsLocalStorage();
         }
      }

      private clearRemediationErrorsLocalStorage(): void {
         const key: string = this.REMEDIATION_RESULT_KEY + this.objectId;
         this.localStorageService.getUserData(key)
            .then((remediationErrors: any) => {
               if (remediationErrors) {
                  this.localStorageService.removeUserData(key);
               }
            });
      }

      private getHostComplianceCount(data: any): void {
         if (data) {
            this.hostsCompliance = {
               numberOfCompliantHosts: data.compliantHostCount,
               numberOfNonCompliantHosts: data.nonCompliantHostCount,
               numberOfUnknownHosts: data.unknownCompliantHostCount
            };
         } else {
            this.hostsCompliance = null;
         }
      }
   }

   angular
         .module("com.vmware.vsphere.client.hostprofile")
         .controller("HostProfileComplianceController", HostProfileComplianceController);
}
