namespace h5_vm {
   declare const ipaddr: any;
   import AdapterMapping = com.vmware.vim.binding.vim.vm.customization.AdapterMapping;

   class EditWinsTabController {
      public static $inject = ["i18nService", "ipParserService"];

      public adapterWrapper: AdapterMappingWrapper;
      public adapterMapping: AdapterMapping;

      /**
       * Function that gets strings from i18nService only for this tab.
       * The common prefix is "customizationSpec.networkPage.properties.wins."
       */
      public i18nForTab: (suffix: string, ...args: (string | number)[]) => string;

      private readonly TITLE_LOCALE = "customizationSpec.networkPage.properties.wins.pageTitle";

      public primaryWins: string;
      public secondaryWins: string;

      public validationErrors: { [key: string]: string } = {};
      public editWins: () => boolean;
      public submitErrors: string[];

      constructor(private i18nService: any, private ipParserService: any) {
         this.i18nForTab = (suffix: string, ...args: (string | number)[]): string => {
            return this.i18nService.getString("VmUi",
                  "customizationSpec.networkPage.properties.wins." + suffix, ...args);
         };
      }

      public $onInit(): void {
         this.adapterMapping = this.adapterWrapper.adapterMapping;
         this.populateData();
         this.editWins = (): boolean => {
            return this.preSubmit();
         };
      }

      public validatePrimaryWins(): void {
         this.validationErrors["primaryWins"] =
               this.validateIpInternal(this.primaryWins);
         this.setValidationErrors(this.areAllFieldsValid());
      }

      public validateSecondaryWins(): void {
         this.validationErrors["secondaryWins"] =
               this.validateIpInternal(this.secondaryWins);
         this.setValidationErrors(this.areAllFieldsValid());
      }

      /**
       * Same as NicSettingsWinsPageMediator.as:118 except that it does not return
       * anything.
       */
      private validateAll(): void {
         this.validatePrimaryWins();
         this.validateSecondaryWins();
      }

      private areAllFieldsValid(): boolean {
         return _.all(this.validationErrors, (validationError: string): boolean => {
            return !validationError;
         });
      }

      private validateIpInternal(ip: string): string {
         if (!ip || this.ipParserService.isIpv4AddressValid(ip)
               || this.ipParserService.isIpv6AddressValid(ip)) {
            return "";
         }
         return this.i18nForTab("error.address");
      }

      public preSubmit(): boolean {
         this.validateAll();

         if (this.areAllFieldsValid()) {
            this.updateData();
            return true;
         }
         return false;
      }

      private setValidationErrors(isValid: boolean) {
         if (!isValid && !_.contains(this.submitErrors, this.TITLE_LOCALE)) {
            this.submitErrors.push(this.TITLE_LOCALE);
         } else if (isValid) {
            this.submitErrors = _.without(this.submitErrors, this.TITLE_LOCALE);
         }

      }

      /**
       * Set the data from the input fields into the adapter
       */
      private updateData() {
         if (this.primaryWins) {
            this.primaryWins = ipaddr.parse(this.primaryWins).toString();
         }
         if (this.secondaryWins) {
            this.secondaryWins = ipaddr.parse(this.secondaryWins).toString();
         }

         this.adapterMapping.adapter.primaryWINS = this.primaryWins;
         this.adapterMapping.adapter.secondaryWINS = this.secondaryWins;
      }

      private populateData() {
         this.primaryWins = this.adapterMapping.adapter.primaryWINS || "";
         this.secondaryWins = this.adapterMapping.adapter.secondaryWINS || "";
      }
   }

   export class EditWinsTabComponent {
      public controller: any;
      public templateUrl: string;
      public bindings: any;

      constructor() {
         this.bindings = {
            adapterWrapper: "<",
            submitErrors: "=",
            editWins: "="
         };
         this.controller = EditWinsTabController;
         this.templateUrl = "vm-ui/resources/vm/guest-os-customization/pages/network/edit/wins-tab/edit-wins-tab.component.html";
      }
   }

   angular.module("com.vmware.vsphere.client.vm")
         .component("editWinsTab", new EditWinsTabComponent());
}
