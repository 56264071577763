(function() {
   'use strict';
   angular.module('com.vmware.vsphere.client.host')
         .controller('EditPowerConfigSoftwareController', EditPowerConfigSoftwareController);

   EditPowerConfigSoftwareController.$inject = ['$scope', 'dataService',
      'mutationService', 'ipParserService', 'vuiConstants', 'i18nService', '$q'];

   function EditPowerConfigSoftwareController($scope, dataService,
         mutationService, ipParserService, vuiConstants, i18nService, $q) {
      var self = this;
      var objectId = $scope.modalOptions.dialogData.availableTargets[0];
      self.loading = false;

      $scope.modalOptions.isAlertClosed = true;
      $scope.modalOptions.onSubmit = onSubmit;
      getViewData();

      function getViewData() {
         setPageBusy(true);
         dataService.getProperties(objectId, ['powerConfigSoftware'])
               .then(function(response) {
                  initData(response.powerConfigSoftware.properties);
                  setPageBusy(false);
               })
               .catch(function(error) {
                  setPageBusy(false);
                  $q.reject(error);
               });
      }

      function initData(properties) {
         _.each(properties, function(property) {
                  switch (property.name) {
                     case 'login':
                        self.userName = property.value;
                        break;
                     case 'password':
                        self.password = property.value;
                        break;
                     case 'bmcIpAddress':
                        self.bmcIpAddress = property.value;
                        break;
                     case 'bmcMacAddress':
                        self.bmcMacAddress = property.value;
                        break;
                  }
               }
         );
      }

      function onSubmit() {
         var data = {
            login: self.userName,
            password: self.password,
            bmcIpAddress: self.bmcIpAddress,
            bmcMacAddress: self.bmcMacAddress
         };
         var validationMessages = validatePowerConfigSoftwareSpec(data);
         if (validationMessages.length === 0) {
            mutationService.apply(objectId,
                  'com.vmware.vsphere.client.h5.host.config.spec.PowerConfigSoftwareSpec',
                  data);
            return true;
         } else {
            showValidationMessages(validationMessages);
            return false;
         }
      }

      function validatePowerConfigSoftwareSpec(data) {
         var validationMessages = [];
         if (data.login === '' && data.password === '' && data.bmcIpAddress === ''
               && data.bmcMacAddress === '' ) {
            return validationMessages;
         }

         if (data.login === '' || data.password === '' || data.bmcIpAddress === ''
               || data.bmcMacAddress === '') {
            var allParametersRequiredMessage = i18nService.getString('HostUi',
                  'powerConfigSoftware.error.allParametersRequired');
            validationMessages.push(allParametersRequiredMessage);
         }

         if (data.bmcIpAddress !== '' &&
               !ipParserService.isAddressValid(data.bmcIpAddress)) {
            var invalidIpAddress = i18nService.getString('HostUi',
                  'powerConfigSoftware.error.invalidIpAddress');
            validationMessages.push(invalidIpAddress);
         }

         return validationMessages;
      }

      function setPageBusy(isBusy) {
         $scope.modalOptions.submitDisabled = isBusy;
         self.loading = isBusy;
      }

      function showValidationMessages(messages) {
          var validationErrorMessages = _.map(messages, function(message) {
            return {
               type: vuiConstants.validationBanner.type.ERROR,
               text: message
            };
         });
         $scope.modalOptions.isAlertClosed = false;
         $scope.modalOptions.alerts = validationErrorMessages;
         return false;
      }
   }
})();
