/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
module hostprofile_ui {

   export interface ClusterHostProfileSettingsService {
      getGridOptions(): any;
      processHostsCompliance(hosts: any[]): ComplianceStatusCounts;
      getSplitterOptions(): Object;
      getHeaderOptions(objectId: string): Object;
      requestHostComplianceData(host: any): angular.IPromise<any>;
      requestHostProfileSummary(objectId: any): angular.IPromise<any>;
      requestClusterHostProfileComplianceDetails(objectId: any): angular.IPromise<any>;
      detachHostProfileFromCluster(clusterId: string): void;
   }

   class ClusterHostProfileSettingsServiceImpl implements ClusterHostProfileSettingsService {

      static $inject = [
         "vuiConstants",
         "i18nService",
         "timeFormatterService",
         "columnRenderersRegistry",
         "defaultUriSchemeUtil",
         "dataService",
         "HostProfileConstants",
         "mutationService",
      ];

      constructor(private vuiConstants: any,
                  private i18nService: any,
                  private timeFormatterService: any,
                  private columnRenderersRegistry: any,
                  private defaultUriSchemeUtil: any,
                  private dataService: any,
                  private hostProfileConstants: any,
                  private mutationService: any) {
      }

      public getGridOptions(): any {
         return {
            searchable: false,
            resizable: false,
            data: [],
            selectionMode: this.vuiConstants.grid.selectionMode.SINGLE,
            selectedItems: [],
            columnDefs: this.getColumnDefs(),
            height: "100%",
            pageConfig: {
               hidePager: true
            }
         };
      }

      public processHostsCompliance(hosts: any[]): ComplianceStatusCounts {
         let hostsCompliance = {
            numberOfCompliantHosts: 0,
            numberOfNonCompliantHosts: 0,
            numberOfUnknownHosts: 0
         };

         const compliance = this.hostProfileConstants.complianceStatus;

         _.each(hosts, (host) => {
            switch (host.complianceResult.complianceStatus) {
               case compliance.compliant:
                  hostsCompliance.numberOfCompliantHosts += 1;
                  break;
               case compliance.nonCompliant:
                  hostsCompliance.numberOfNonCompliantHosts += 1;
                  break;
               case compliance.unknown:
                  hostsCompliance.numberOfUnknownHosts += 1;
                  break;
            }
         });

         return hostsCompliance;
      }

      public getSplitterOptions(): Object {
         return {
            panes: [{
               min: "240px",
               size: "50%"
            }, {
               min: "240px",
               size: "50%"
            }],
            orientation: this.vuiConstants.splitter.orientation.VERTICAL
         };
      }

      public getHeaderOptions(headerObjectId: any): Object {
         return {
            objectId: headerObjectId,
            title: this.getString("cluster.monitor.compliance.host"),
            actions: [{
               actionUid: "vsphere.core.hostProfile.actions.attachHostProfile",
               customLabel: this.getString("attachHostProfileButton.label")
            }, {
               actionUid: "vsphere.core.hostProfile.actions.cluster.detachHostProfile",
               customLabel: this.getString("detachHostProfileClusterButton.label")
            }, {
               actionUid: "vsphere.core.hostProfile.actions.changeHostProfile",
               customLabel: this.getString("changeHostProfileButton.label")
            }, {
               actionUid: "vsphere.core.hostProfile.actions.cluster.checkHostProfileCompliance",
               customLabel: this.getString("hostProfile.action.checkCompliance")
            }, {
               actionUid: "vsphere.core.hostProfile.actions.cluster.remediateMultipleHosts",
               customLabel:this.getString("actionRemediateHostLabel")
            }]
         };
      }

      private getColumnDefs(): any {
         return [{
            displayName: this.getString("HostCompliance.column.hostLabel"),
            field: "hostName",
            template: (dataItem: any): string => {
               let hostRef = this.defaultUriSchemeUtil
                     .getVsphereObjectId(dataItem.provider);

               let objectLinkRenderer = this.columnRenderersRegistry
                     .getColumnRenderer("object-link");
               return objectLinkRenderer(
                     ["hostRef", "hostName", "vsphere-icon-host"],
                     {
                        hostRef: hostRef,
                        hostName: dataItem.hostName
                     }
               );
            }
         }, {
            displayName: this.getString("HostCompliance.column.hostProfileComplianceLabel"),
            field: "complianceResult.complianceStatus",
            template: (dataItem: any): string => {
               const compliance = this.hostProfileConstants.complianceStatus;

               let icon: string = "vsphere-icon-status-unknown";
               let text: string = this.getString("hpCompliance.unknown");

               if (dataItem.complianceResult.complianceStatus === compliance.compliant) {
                  icon = "vx-icon-statusCompliant";
                  text = this.getString("hpCompliance.compliant");
               }
               else if (dataItem.complianceResult.complianceStatus === compliance.nonCompliant) {
                  icon = "vx-icon-statusNotCompliant";
                  text = this.getString("hpCompliance.notCompliant");
               }

               let iconTextRenderer = this.columnRenderersRegistry
                     .getColumnRenderer("icon-text");
               return iconTextRenderer(["icon", "text"], {icon: icon, text: text});
            }
         }, {
            displayName: this.getString("HostCompliance.column.lastChecked"),
            field: "complianceResult.checkTime",
            template: (dataItem: any): string => {
               let text = this.timeFormatterService
                     .timestampToText(dataItem.complianceResult.checkTime);

               let textRenderer = this.columnRenderersRegistry
                     .getColumnRenderer("text");
               return textRenderer(['text'], {text: text});
            }
         }];
      }

      public requestHostComplianceData(host: any): angular.IPromise<any> {
         return this.dataService
               .getData(
                     host,
                     "com.vmware.vsphere.client.hostprofile.data.h5.HostProfileComplianceFailuresData");
      }

      public requestHostProfileSummary(objectId: any): angular.IPromise<any> {
         return this.dataService.getProperties(objectId, ["associatedHostProfileSummary"]);
      }

      public requestClusterHostProfileComplianceDetails(objectId: any): angular.IPromise<any> {
         return this.dataService.getData(objectId, "com.vmware.vsphere.client.hostprofile.data.h5.ClusterHostProfileComplianceDetails");
      }

      private getString(key: string): string {
         return this.i18nService.getString("HostProfileUi", key);
      }

      public detachHostProfileFromCluster(clusterId: string): void {
         this.mutationService.applyOnMultiEntity(
               [clusterId],
               "com.vmware.vsphere.client.hostprofile.data.DetachEntitiesSpec",
               {}
         );
      }
   }

   angular
         .module("com.vmware.vsphere.client.hostprofile")
         .service("clusterHostProfileSettingsService", ClusterHostProfileSettingsServiceImpl);
}
