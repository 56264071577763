module ds_cluster_ui {

   import IPromise = angular.IPromise;

   export class DsClusterConsumersService {

      public static $inject = ['vxPropertyViewService', 'dataService', 'i18nService'];

      constructor(private vxPropertyViewService: any, private dataService: any,
            private i18nService: any) { }

      public getPropertyViewData(objectId: string): IPromise<Object> {
         return this.dataService.getData(objectId,
               'com.vmware.vsphere.client.dsclusterui.model.DsClusterConsumersData')
               .then((data: any) => {
                  var builder = this.vxPropertyViewService.createPropertyViewBuilder();
                  var sectionBuilder = builder
                        .category('')
                        .section('');

                  sectionBuilder.property(this.i18nService.getString('DsClusterUi',
                        'dscluster.consumers.vms'),
                        data.vmCount);
                  sectionBuilder.property(this.i18nService.getString('DsClusterUi',
                        'dscluster.consumers.vm.templates'),
                        data.vmTemplateCount);

                  return builder.build();
               });
      }
   }

   angular.module('com.vmware.vsphere.client.dsCluster')
         .service('dsClusterConsumersService', DsClusterConsumersService);
}
