/* Copyright 2018 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace dvs_ui {

   export class UnassignUplinkDialogComponent {

      public bindings: any;
      public controller: any;
      public templateUrl: string;

      constructor() {
         this.bindings = {
            dialogData: "<"
         };

         this.controller = UnassignUplinkDialogController;
         this.templateUrl = 'dvs-ui/resources/dvs/addhost/components/unassignUplink/unassignUplinkDialogTemplate.html';
      }

   }

   class UnassignUplinkDialogController {

      static $inject = [
         "i18nService"
      ];

      public dialogData: any;

      constructor(private i18nService: any) {

      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .component("unassignUplink", new UnassignUplinkDialogComponent());
}
