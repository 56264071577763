(function() {
   'use strict';
   angular.module('com.vmware.vsphere.client.cluster')
         .controller('HeartbeatViewController', HeartbeatViewController);

   HeartbeatViewController.$inject = ['$scope', 'dataService', 'i18nService'];

   function HeartbeatViewController($scope, dataService, i18nService) {

      var HA_PROPERTIES =
            'com.vmware.vsphere.client.clusterui.model.services.ClusterHaData';

      var self = this;
      var objectId = $scope._route ? $scope._route.objectId : null;
      self.liveRefreshProperties = [
         "configurationEx.dasConfig.heartbeatDatastore",
         "summary.dasData"
      ];
      self.watchForObjects = [objectId];
      self.hbDatastoreCandidates = [];
      self.datastoresGridLabel= i18nService.getString('ClusterUi',
            'ha.runtime.selected.heartbeat.datastores');

      self.getViewData = getViewData;
      getViewData();

      function getViewData() {
         dataService.getData(objectId, HA_PROPERTIES).then(function(data) {
            self.hbDatastoreCandidates = data.heartbeatDatastores;
         });
      }
   }
})();
