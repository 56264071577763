/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace network_ui {

   export class MigrateVmkToVssWizardModel {

      public hostId: string;
      public selectedVmkAdapter: string;

      public networkNameToRemove: string;
      public sourceSwitchName: string;
      public sourceNetworkName: string;

      public newNetworkName: string;
      public targetStandardSwitch: string;

      public vlanId: string;
      public vlanIds: any[];
   }
}
