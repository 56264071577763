/* Copyright 2018 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace h5_vm {

   import VmMigrationScheduledTaskEditDefaultsValidationSpec = com.vmware.vsphere.client.h5.vm.model.migration.VmMigrationScheduledTaskEditDefaultsValidationSpec;

   import IQService = angular.IQService;
   import IPromise = angular.IPromise;
   import ManagedObjectReference = com.vmware.vim.binding.vmodl.ManagedObjectReference;
   import ScheduledMutationOperationSpec = com.vmware.vise.data.scheduling.ScheduledMutationOperationSpec;
   import RelocateSpec = com.vmware.vim.binding.vim.vm.RelocateSpec;
   import VmMigrationScheduledTaskEditDefaultsData = com.vmware.vsphere.client.h5.vm.model.migration.VmMigrationScheduledTaskEditDefaultsData;
   import ValidationResult = com.vmware.vise.core.model.ValidationResult;
   import ProfileSpec = com.vmware.vim.binding.vim.vm.ProfileSpec;
   import VirtualMachine$MovePriority = com.vmware.vim.binding.vim.VirtualMachine$MovePriority;
   import RelocateSpec$DiskLocator = com.vmware.vim.binding.vim.vm.RelocateSpec$DiskLocator;
   import Profile = com.vmware.vim.binding.pbm.profile.Profile;
   import GroupInfo = com.vmware.vim.binding.sms.storage.replication.GroupInfo;

   export class MigrateVmScheduledDataToModelMapper {
      public static $inject: string[] = [
         "$q",
         "defaultUriSchemeUtil",
         "mutationService",
         "diskProvisioningService",
         "storageProfileService",
         "initialStorageConfigurationService"
      ];

      constructor(
            private $q: IQService,
            private defaultUriSchemeUtil: {
                  getVsphereObjectId: (resourceObject: any) => string,
                  compareNullableIds: (id1: any, id2: any) => boolean,
            },
            private mutationService: {
                  validate: (objectId: string, propertyObjectType: string,
                             propertySpec: any) => IPromise<any>
               },

            private diskProvisioningService: {
                  getFormat: (backingInfo: any) => any
                  areDiskFormatsEqual: (backingInfoA: any, backingInfoB: any) => boolean
            },
            private storageProfileService: {
                  getProfileIdFromSpec: (profileSpec: ProfileSpec) => string | undefined,
                  areProfileSpecsEqual: (
                        profileSpecA: ProfileSpec,
                        profileSpecB: ProfileSpec,
                        areReplicationGroupsConsidered?: boolean) => boolean
               },
            private initialStorageConfigurationService: {
               computeInitialVmStorageConfig: (vmId: string, spec: RelocateSpec,
                     replicationGroupsData: {[index: string]: GroupInfo}) => any,
               computeStoragePageViewMode: (spec: RelocateSpec) => string
            }
      ) {
      }

      public applyDataToModels(
            migrationWizardManager: MigrationWizardManager,
            schedulingData?: ScheduledMutationOperationSpec,
      ): IPromise<any> {
         // No scheduling data, so nothing to apply, so just return
         if (!schedulingData || !schedulingData.spec) {
            return this.$q.when({});
         }

         const vm: ManagedObjectReference = schedulingData.entity;
         const args: any[] = schedulingData.spec.argument;
         const spec: RelocateSpec = args[0].value;

         // Priority is undefined when using cold migration
         const movePriority: VirtualMachine$MovePriority|undefined =
               args[1].value ? args[1].value[1] : undefined;

         const vmId = this.defaultUriSchemeUtil.getVsphereObjectId(vm);

         const validationSpec = new VmMigrationScheduledTaskEditDefaultsValidationSpec();
         validationSpec.spec = spec;
         validationSpec.movePriority = movePriority;
         return this.mutationService.validate(
               vmId,
               validationSpec._type,
               validationSpec
            ).then((response: ValidationResult) => {
               this.onDefaultsCollected(migrationWizardManager, response);
               this.applyDataToStorageModel(migrationWizardManager, vmId, spec, response);
            });
      }

      private onDefaultsCollected(
            migrationWizardManager: MigrationWizardManager,
            validationResult: ValidationResult,
      ): void {
         const defaultsData = validationResult.result as
               VmMigrationScheduledTaskEditDefaultsData;

         // Set the migration type - this is not applied on a model, but on
         // the migrationWizardManager internal data structure ... yeah it sucks, I know.
         migrationWizardManager.setSelectedMode(defaultsData.migrationType);

         const computeResourceId = this.defaultUriSchemeUtil.getVsphereObjectId(
               defaultsData.computeResource);
         migrationWizardManager.computeResourceTreePageModel.initialResource =
               computeResourceId;
         migrationWizardManager.computeResourcePageModel.initialComputeResource =
               computeResourceId;

         // Always restore the network selection page in advanced mode, as finding
         // out whether basic or advanced mode had been used just from the relocate
         // spec is nearly impossible.
         migrationWizardManager.networkPageModel.initialViewMode = "advanced";
         // since we are already in advanced mode, mark the view as changed
         migrationWizardManager.networkPageModel.setSelectNetworkPageIsViewChanged(true);
         migrationWizardManager.networkPageModel.initialMatches =
               defaultsData.networkMatches;

         migrationWizardManager.priorityPageModel.initialPriority =
               defaultsData.movePriority;
      }

      private applyDataToStorageModel(
            migrationWizardManager: MigrationWizardManager,
            vmId: string,
            spec: RelocateSpec,
            validationResult: ValidationResult) {
         const defaultsData = validationResult.result as
               VmMigrationScheduledTaskEditDefaultsData;
         const pageModel = migrationWizardManager.storagePageModel;

         pageModel.initialViewMode = this.initialStorageConfigurationService
               .computeStoragePageViewMode(spec);
         pageModel.initialVmStorageConfig = this.initialStorageConfigurationService
               .computeInitialVmStorageConfig(vmId, spec, defaultsData.replicationGroupsData);
      }

   } // class

   angular.module("com.vmware.vsphere.client.vm").service(
         "migrateVmScheduledDataToModelMapper", MigrateVmScheduledDataToModelMapper);

} // h5_vm
