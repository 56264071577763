(function() {
   'use strict';
   angular.module('com.vmware.vsphere.client.cluster')
         .controller('AdmissionControlVmsRequiringMultipleSlotsDialogController',
               AdmissionControlVmsRequiringMultipleSlotsDialogController);

   AdmissionControlVmsRequiringMultipleSlotsDialogController.$inject =
         ['$scope', 'i18nService', 'vuiConstants', 'columnRenderersRegistry'];

   function AdmissionControlVmsRequiringMultipleSlotsDialogController(
         $scope,
         i18nService,
         vuiConstants,
         columnRenderersRegistry
   ) {
      var self = this;
      self.header = i18nService.getString(
            'ClusterUi',
            'ha.config.admission.control.policy.failover.level.excluded.vms.header'
      );
      self.slotSizeRequirements =
            $scope.dialogOptions.actionDetails.dialogData.slotSizeRequirements;

      var vmSlotRequirementsGridData = [];
      var vmSlotRequirements = self.slotSizeRequirements.vmsRequiringMultipleSlots;

      if (vmSlotRequirements) {
         vmSlotRequirements.forEach(function(slot) {
            vmSlotRequirementsGridData.push({
               vmName: slot.vmName,
               requiredSlots: slot.requiredSlots
            });
         });
      }

      function getIconRenderer(data) {
         var renderer = columnRenderersRegistry.getColumnRenderer('icon-text');
         return renderer(['icon', 'text'], {
            icon: 'vsphere-icon-vm-on',
            text: data.vmName
         });
      }

      var slotSizeColumnDefs = [
         {
            displayName: i18nService.getString('ClusterUi',
                  'ha.config.admission.control.policy.failover.level.excluded.vms.' +
                  'grid.name.header'),
            field: 'vmName',
            template: function (item) {
               return getIconRenderer(item);
            }
         },
         {
            displayName: i18nService.getString('ClusterUi',
                  'ha.config.admission.control.policy.failover.level.excluded.vms.grid' +
                  '.slots.header'),
            field: 'requiredSlots'
         }
      ];

      self.datagridOptions = {
         columnDefs: slotSizeColumnDefs,
         sortMode: vuiConstants.grid.sortMode.SINGLE,
         selectionMode: vuiConstants.grid.selectionMode.SINGLE,
         selectedItems: [],
         data: vmSlotRequirementsGridData,
         resizable: true,
         height: '100%',
         pageConfig: {
            hidePager: true
         }
      };
   }
})();
