namespace h5_vm {
   import VmConfigContext = com.vmware.vsphere.client.vm.config.VmConfigContext;
   import VirtualDeviceSpec = com.vmware.vim.binding.vim.vm.device.VirtualDeviceSpec;
   import VirtualDevice = com.vmware.vim.binding.vim.vm.device.VirtualDevice;
   import VirtualDevice$ConnectInfo = com.vmware.vim.binding.vim.vm.device.VirtualDevice$ConnectInfo;
   import VirtualDeviceSpec$Operation = com.vmware.vim.binding.vim.vm.device.VirtualDeviceSpec$Operation;
   import VirtualDeviceOption = com.vmware.vim.binding.vim.vm.device.VirtualDeviceOption;
   export class VmHardwareUtil {
      static $inject: string[] = [
         'authorizationService',
         'deviceClassLineageService'
      ];
      private readonly PCI_CONTROLLER_NAME: string = "com.vmware.vim.binding.vim.vm.device.VirtualPCIController";
      private readonly DEVICE_TYPE_ETHERNET_SPEC: string = "com.vmware.vim.binding.vim.vm.device.VirtualEthernetCard";

      constructor(private authorizationService: any, private deviceClassLineageService: any) {
      }

      public fixNewDiskBackingDatastore(spec:VirtualDeviceSpec):void {
         if (!spec || !spec.device) {
            return;
         }
         if (spec.operation !== "add") {
            return;
         }

         if (spec.device._type !== "com.vmware.vim.binding.vim.vm.device.VirtualDisk") {
            return;
         }

         let backing: any = spec.device.backing;

          if (backing && backing.datastore) {
             if (backing.datastore.type === "StoragePod") {
                // SDRS recommendation errors when new disk backing.datastore is pod
                // Pod placement is specified elsewhere
                // and does not need to be inside backing
                // see bug 2242788
                backing.datastore = null;
            }
          }
          return;
      }

      public clearUnchangedConnectInfo(spec:VirtualDeviceSpec, originalDevice:VirtualDevice):void {
         if (!spec || !spec.device) {
            return;
         }

         if (spec.operation === "add") {
            return;
         }

         if (!originalDevice) {
            return;
         }

         let newInfo:VirtualDevice$ConnectInfo = spec.device.connectable;
         let oldInfo:VirtualDevice$ConnectInfo = originalDevice.connectable;

         if (VmHardwareUtil.sameConnectInfo(oldInfo, newInfo)) {
            (spec.device as any).connectable = null;
         }
         return;
      }

      private static sameConnectInfo(old:VirtualDevice$ConnectInfo, neu:VirtualDevice$ConnectInfo): boolean {

         if (!neu && !old) {
            return true; // both missing, consider same
         }

         if (!neu || !old) {
            return false; // only one missing, consider different
         }

         // If here consider both parts present and we could safely
         // compare field-by-field (vmodl fields only)

         if(old.connected !== neu.connected) {
            // checkbox "Connect" might be clicked
            return false;
         }

         if(old.startConnected !== neu.startConnected) {
            // checkbox "Connect at power On" might be clicked
            return false;
         }

         if(old.allowGuestControl !== neu.allowGuestControl) {
            // I don't think we change allowGuestControl, but just in case
            return false;
         }
         // no difference if here, return true (same)
         return true;
      }

      checkPermissions(vmId: string) {
         return this.authorizationService.checkPrivilege(vmId, "VirtualMachine.Config.AddRemoveDevice");
      }

      checkPrivileges(vmConfigContextPrivileges: Array<string>, privileges: Array<string>) {
         return _.difference(privileges, vmConfigContextPrivileges).length === 0;
      }

      static hasPrivileges(vmConfigContextPrivileges: Array<string>, privileges: Array<string>) {
         let diff:any = _.difference(privileges, vmConfigContextPrivileges);
         if (diff.length === 0) {
            return true;
         }
         return false;
      }

      public static hasRecordReplay(vmConfigContext:any):boolean {
         var rtInfo = vmConfigContext.rtInfo;
         if (!rtInfo) { // rtInfo might be null or undefined in deploy from template.
            return false;
         };
         let vmRecordReplayState  = rtInfo.recordReplayState;
         if (!vmRecordReplayState) {
            return false;
         }
         if (vmRecordReplayState === "inactive") {
            return false;
         }
         return true;
      }

      isPrimaryVm(ftRole: number): boolean {
         return (ftRole === 1);
      }

      isSecondaryVm(ftRole: number): boolean {
         return (ftRole > 1);
      }

      isPrimaryFtVm(ftState: string, ftInfo: any): boolean {
         if (!ftInfo) {
            return false;
         }

         return (ftState !== 'notConfigured') && this.isPrimaryVm(ftInfo.role);
      }

      isSecondaryFtVm(ftState: string, ftInfo: any): boolean {
         if (!ftInfo) {
            return false;
         }

         return (ftState !== 'notConfigured') && this.isSecondaryVm(ftInfo.role);
      }

      isFtVm(ftState: string, ftInfo: any): boolean {
         return this.isPrimaryFtVm(ftState, ftInfo) || this.isSecondaryFtVm(ftState, ftInfo);
      }

      isVmPoweredOn(vmPowerState: string): boolean {
         return vmPowerState === 'poweredOn';
      }

      isVmPoweredOff(vmPowerState: string): boolean {
         return vmPowerState === 'poweredOff';
      }

      isVmSuspended(vmPowerState: string): boolean {
         return vmPowerState === 'suspended';
      }

      bytesToMB(bytes: number): number {
         var MB_FACTOR = 1024 * 1024;
         return bytes / MB_FACTOR;
      }

      isFtVmConfigContext(ctx: any): boolean {

         let ftInfo = ctx.config.ftInfo;
         let rtInfo = ctx.rtInfo;
         let ftState = rtInfo ? rtInfo.faultToleranceState : null;
         let retVal = this.isFtVm(ftState, ftInfo);
         return retVal;
      }

      haveVmConfigSettingsPermission(vmConfigContext:any): boolean {
         let privileges = vmConfigContext.privileges;
         let retVal = this.checkPrivileges(privileges, [h5_vm.Privileges.VM_CONFIG_SETTINGS]);
         return retVal;
      }

      public static userHasVmSettingsPermission(vmConfigContext:any): boolean {
         let privileges = vmConfigContext.privileges;
         let retVal = VmHardwareUtil.hasPrivileges(privileges, [h5_vm.Privileges.VM_CONFIG_SETTINGS]);
         return retVal;
      }

      public static userHasVmResourcePermission(vmConfigContext:any): boolean {
         let privileges = vmConfigContext.privileges;
         let retVal = VmHardwareUtil.hasPrivileges(privileges, [h5_vm.Privileges.VM_RESOURCE_PRIVILEGE]);
         return retVal;
      }

      public static noVmSettingsPermission(vmConfigContext:any): boolean {
          return (VmHardwareUtil.userHasVmSettingsPermission(vmConfigContext) === false);
      }

      public static noVmResourcePermission(vmConfigContext:any): boolean {
         return (VmHardwareUtil.userHasVmResourcePermission(vmConfigContext) === false);
      }

      public static userHasModifyDevicePermission(vmConfigContext:any): boolean {
         let array = [h5_vm.Privileges.VM_EDITDEVICE_PRIVILEGE];
         let privileges = vmConfigContext.privileges;
         let retVal = VmHardwareUtil.hasPrivileges(privileges, array);
         return retVal;
      }

      public static userHasHostUsbPermission(ctx:VmConfigContext): boolean {
         let privileges = ctx.privileges;
         let retVal = VmHardwareUtil.hasPrivileges(privileges, [h5_vm.Privileges.VM_HOST_USB_DEVICE]);
         return retVal;
      }

      public static userHasAddRemoveDevicePermission(ctx:VmConfigContext): boolean {
         let privileges = ctx.privileges;
         let retVal = VmHardwareUtil.hasPrivileges(privileges, [h5_vm.Privileges.VM_ADDREMOVEDEVICE]);
         return retVal;
      }

      public static userHasStorageProfileViewPermission(ctx:VmConfigContext): boolean {
         let privileges = ctx.privileges;
         let retVal = VmHardwareUtil.hasPrivileges(privileges, [h5_vm.Privileges.STORAGE_PROFILE_VIEW]);
         return retVal;
      }

      public static userHasCryptographerManageKeyServersPermission(ctx:VmConfigContext): boolean {
         let privileges = ctx.privileges;
         let retVal = VmHardwareUtil.hasPrivileges(privileges, [h5_vm.Privileges.CRYPTOGRAPHER_MANAGE_KEY_SERVERS]);
         return retVal;
      }


      public static getPowerState(vmConfigContext:any) {
         if (!vmConfigContext) {
            return null;
         }
         if (!vmConfigContext.environment) {
            return null;
         }

         return vmConfigContext.environment.powerState;
      }

      public static isPoweredOn(vmConfigContext:any) {
         return VmHardwareUtil.getPowerState(vmConfigContext) === 'poweredOn';
      }

      public static isPoweredOff(vmConfigContext:any) {
         return VmHardwareUtil.getPowerState(vmConfigContext) === 'poweredOff';
      }

      public static isSuspended(vmConfigContext:any) {
         return VmHardwareUtil.getPowerState(vmConfigContext) === 'suspended';
      }

      public static getVmCapabilities(vmConfigContext:any) {
         return vmConfigContext.environment.configOption.capabilities;
      }

      getDeviceOption(vmConfigContext: any, deviceType:string): VirtualDeviceOption {
         return h5_vm.VmHardwareUtil.getVirtualDeviceOption(vmConfigContext, deviceType);
      }

      public static getVirtualDeviceOption(vmConfigContext: any, deviceType:string): any {
          if (vmConfigContext.hasOwnProperty("environment") &&
              vmConfigContext.environment.hasOwnProperty("configOption") &&
              vmConfigContext.environment.configOption.hasOwnProperty("hardwareOptions") &&
              vmConfigContext.environment.configOption.hardwareOptions.hasOwnProperty("virtualDeviceOption")) {
              return vmConfigContext.environment.configOption.hardwareOptions.virtualDeviceOption.find(function (this : any, deviceOption: any) {
                  return deviceOption.type.name === deviceType;
              }, this);
          }
      }

      isEthernetDevice(deviceOption: any): any {
         if (deviceOption.hasOwnProperty("device") &&
               deviceOption.device.hasOwnProperty("_type")) {
            return this.deviceClassLineageService.isClassNameSubclassOf(deviceOption.device._type, this.DEVICE_TYPE_ETHERNET_SPEC);
         }
      }
   }

   angular.module('com.vmware.vsphere.client.vm')
       .service('vmHardwareUtil', VmHardwareUtil);
}
