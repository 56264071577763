/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace network_ui {

   class VmMigrateSelectNetworksPageController {

      public static $inject = [
         "$scope",
         "i18nService",
         "NetworkSelectorMode",
         "networkSelectorDialogService",
         "vmMigrateWizardConstants",
         "defaultUriSchemeUtil",
         "dataService"];

      public networkSelectorMode: any;
      public networkSelectorFilterSpec: any;
      public networkSelectorDialogSpec: any;
      public onSourceNetworksChanged: any;
      public onDestinationNetworksChanged: any;

      constructor(private $wizardScope: VmMigrateWizardScope,
                  private i18nService: any,
                  private selectorMode: any,
                  private networkSelectorDialogService: any,
                  private wizardConstants: any,
                  private defaultUriSchemeUtil: any,
                  private dataService: any) {

         if (!$wizardScope.flags.isSelectNetworksPageInitialized &&
               this.$wizardScope.flags.isSourceNetworkPreselected) {
            this.requestProperties();
         }

         this.networkSelectorMode = this.selectorMode.SINGLE_SELECTION_BROWSE_INPUT;

         this.networkSelectorFilterSpec =
               this.networkSelectorDialogService.createNetworkSelectorFilterSpec(
                     true/*includeStandardNetworks*/,
                     true/*includeOpaqueNetworks*/,
                     true/*includeDistributedPortGroups*/,
                     false/*includeUplinkPortGroups*/,
                     false/*includeOpaqueNetworkTypes*/);

         this.networkSelectorDialogSpec =
               this.networkSelectorDialogService.createNetworkSelectorDialogSpec(
                     ["Network.Assign"],
                     this.i18nService.getString("NetworkUi",
                           "SelectSourceDestinationNetworkPage." +
                           "noNetworkAssignPrivilegeError"));

         this.onSourceNetworksChanged = (selectedNetworks: any[]) => {
            if (this.$wizardScope.model.sourceNetwork !== selectedNetworks[0]) {
               this.$wizardScope.model.sourceNetwork = selectedNetworks[0];
               this.clearFlags();
            }
         };

         this.onDestinationNetworksChanged = (selectedNetworks: any[]) => {
            if (this.$wizardScope.model.destinationNetwork !== selectedNetworks[0]) {
               this.$wizardScope.model.destinationNetwork = selectedNetworks[0];
               this.clearFlags();
            }
         };

         this.$wizardScope.$watch((): boolean => {
            return this.$wizardScope.flags.noSourceNetworkSelected;
         }, (newValue: boolean, oldValue: boolean): void => {
            if (newValue !== oldValue) {
               this.clearFlags();
            }
         });
      }

      private clearFlags(): void {
         this.$wizardScope.flags.isSelectVmsPageInitialized = false;
         this.$wizardScope.flags.inaccessibilityWarningShown = false;
      }

      private requestProperties(): void {
         this.$wizardScope.wizardConfig.loading = true;

         this.dataService.getProperties(
               this.$wizardScope.model.contextObjId, [
                     this.wizardConstants.properties.NAME_PROPERTY,
                     this.wizardConstants.properties.ICON_PROPERTY])
               .then((response: any): void => {
                  this.$wizardScope.model.sourceNetwork = {
                     name: response[this.wizardConstants.properties.NAME_PROPERTY],
                     icon: response[this.wizardConstants.properties.ICON_PROPERTY],
                     networkRef: this.defaultUriSchemeUtil.getManagedObjectReference(
                           this.$wizardScope.model.contextObjId)
                  };

                  this.$wizardScope.wizardConfig.loading = false;
                  this.$wizardScope.flags.isSelectNetworksPageInitialized = true;
               });
      }
   }

   angular.module("com.vmware.vsphere.client.network")
         .controller("VmMigrateSelectNetworksPageController",
               VmMigrateSelectNetworksPageController);
}
