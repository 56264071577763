namespace h5_client {
   export module common_module_ui {
      import LibraryOperations = h5_client.common_module_ui.LibraryOperations;
      import ContentLibraryConstants = h5_client.common_module_ui.ContentLibraryConstants;
      import AddLibraryDataBuilder = h5_client.common_module_ui.AddLibraryDataBuilder;
      import LibraryDataModel = h5_client.common_module_ui.LibraryDataModel;

      /**
       * Class contains the method, the url and the data required for a $http request.
       */
      export class RequestObject {
         static HTTP_GET: string = "GET";
         static HTTP_POST: string = "POST";
         static HTTP_DELETE: string = "DELETE";

         method: string = RequestObject.HTTP_POST;
         data: any;
         url: string;
         headers: any;
      }

      /*
       * Content library service based on vAPI REST endpoints.
       */
      export class LibraryApiBuilderService {
         static $inject = ['addLibraryDataBuilder', 'defaultUriSchemeUtil'];
         constructor(private addLibraryDataBuilder: AddLibraryDataBuilder, private defaultUriSchemeUtil: any) {}

         private baseUrl: string;
         private op: LibraryOperations;
         private headers: any;

         /**
          * reset all data before reuse
          */
         reset(): LibraryApiBuilderService {
            this.baseUrl = "";
            this.op = LibraryOperations.invalid;
            return this;
         }
         /**
          * set the base URL upon which extra redirect will be added
          * @param url
          * @returns {h5_client.common_module_ui.LibraryApiBuilderService}
          */
         setBaseUrl = (url: string): LibraryApiBuilderService => {
            this.baseUrl = url;
            return this;
         }

         /**
          * Set the operation type
          * @param opType : see LibraryOperations
          * @returns {h5_client.common_module_ui.AddLibraryDataBuilder}
          */
         setOperation = (opType: LibraryOperations): LibraryApiBuilderService => {
            this.op = opType;
            return this;
         }

         /**
          * Set the headers for the request
          * @param headers : string[]
          * @returns {h5_client.common_module_ui.AddLibraryDataBuilder}
          */
         setHeaders = (headers: any): LibraryApiBuilderService => {
            this.headers = headers;
            return this;
         }

         /**
          * given the LibraryOperation type:  returns the request object to be used
          * directly for a XHR http call.
          * @param libraryData : based on LibraryOperations, this data will change
          * @returns {RequestObject} : the object to be passed to $http
          */
         buildApiRequest = (libraryData: LibraryDataModel): RequestObject|null => {
            let requestObject: RequestObject | null = null;
            if (!libraryData) {
               return null;
            }

            switch(this.op) {
               case LibraryOperations.addLibrary:
                  requestObject = this.getAddLibraryRequest(libraryData);
                  break;
               case LibraryOperations.fetchLibrary:
                  requestObject = this.getFetchLibraryRequest(libraryData);
                  break;
               case LibraryOperations.deleteLibrary:
                  requestObject = this.getDeleteLibraryRequest(libraryData);
                  break;
               case LibraryOperations.updateLibrary:
                  break;
               default:
                  throw Error("Library operation unsupported");
            }
            if (requestObject) {
               requestObject.headers = this.headers;
            }
            return requestObject;
         }

         private getAddLibraryRequest(libraryData: LibraryDataModel): RequestObject|null {
            let reqObj: RequestObject = new RequestObject();
            reqObj.data = this.addLibraryDataBuilder
                  .setData(libraryData)
                  .buildModel();
            let urlForAdd = libraryData.type === ContentLibraryConstants.LIBRARYTYPE_LOCAL ?
                  ContentLibraryConstants.Api.ADD_LIBRARY_LOCAL :
                  ContentLibraryConstants.Api.ADD_LIBRARY_SUBSCRIBED;
            reqObj.url = this.baseUrl + urlForAdd;
            return reqObj;
         }

         private getFetchLibraryRequest(libraryData: LibraryDataModel): RequestObject|null {
            let reqObj: RequestObject = new RequestObject();
            let libraryId: string = this.defaultUriSchemeUtil.getPartsFromVsphereObjectId(libraryData.id).value;
            reqObj.url = this.baseUrl + ContentLibraryConstants.Api.FETCH_LIBRARY + libraryId;
            reqObj.method = RequestObject.HTTP_GET;
            reqObj.data = {};
            return reqObj;
         }

         private getDeleteLibraryRequest(libraryData: LibraryDataModel): RequestObject|null {
            let reqObj: RequestObject = new RequestObject();
            let urlForDelete = libraryData.type === ContentLibraryConstants.LIBRARYTYPE_LOCAL ?
                  ContentLibraryConstants.Api.DELETE_LIBRARY_LOCAL:
                  ContentLibraryConstants.Api.DELETE_LIBRARY_SUBSCRIBED;
            reqObj.url = this.baseUrl + urlForDelete + libraryData.id;
            reqObj.method = RequestObject.HTTP_DELETE;
            return reqObj;
         }
      }
      angular.module('com.vmware.vsphere.client.commonModule').service('libraryApiBuilderService', LibraryApiBuilderService);
}
}