namespace network_ui {

   export class PropertiesPageModel {

      public name: string;
      public mtu: number;
      public numPorts: number;
      public availableNumPorts: number;
      public showRestartWarning: boolean;
      public portNumbers: number[];

      public dirty: boolean = false;
      public initialState: PropertiesPageModel;

   }
}
