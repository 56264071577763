(function() {
   'use strict';
   angular.module('com.vmware.vsphere.client.host').service('pciDeviceService',
      PciDeviceService);

   PciDeviceService.$inject = ['vxPropertyViewService', 'i18nService',
      'columnRenderersRegistry', 'vuiConstants','mutationService', 'clarityModalService'];

   function PciDeviceService(vxPropertyViewService, i18nService, columnRenderersRegistry,
      vuiConstants, mutationService, clarityModalService) {
      var iconTextRenderer = columnRenderersRegistry.getColumnRenderer('icon-text');
      var hostStr = _.bind(i18nService.getString, i18nService, 'HostUi');
      var NOT_CONFIGURABLE = 'NOT_CONFIGURABLE';
      var AVAILABLE = "AVAILABLE";
      var UNAVAILABLE = "UNAVAILABLE";
      var AVAILABLE_PENDING = "AVAILABLE_PENDING";
      var UNAVAILABLE_PENDING = "UNAVAILABLE_PENDING";

      function createPciDetails(pciDevice) {
         var builder = vxPropertyViewService.createPropertyViewBuilder();
         var sectionBuilder = builder.category('').section(
            'pciDetailsData').title(hostStr('vmdpConfig.pciDeviceDetails.label'));

         sectionBuilder.property(
            hostStr('vmdpConfig.deviceName.label'),
            pciDevice.deviceName
         ).property(
            hostStr('vmdpConfig.deviceId.label'),
            pciDevice.deviceId
         ).property(
            hostStr('vmdpConfig.subDeviceId.label'),
            pciDevice.subDeviceId
         ).property(
            hostStr('vmdpConfig.classId.label'),
            pciDevice.classId
         ).property(
            hostStr('vmdpConfig.vendorName.label'),
            pciDevice.vendor
         ).property(
            hostStr('vmdpConfig.vendorId.label'),
            pciDevice.vendorId
         ).property(
            hostStr('vmdpConfig.subVendorId.label'),
            pciDevice.subVendorId
         );
         return builder.build();
      }

      function createEsxDeviceInfoDetails(pciDevice) {
         if (pciDevice.esxDeviceInfo) {
            var builder = vxPropertyViewService.createPropertyViewBuilder();

            var sectionBuilder = builder.category('').section('esxDeviceInfo');

            sectionBuilder.property(
                  hostStr('vmdpConfig.esxName.label'),
                  pciDevice.esxDeviceInfo
            );

            return builder.build();
         }

         return null;
      }

      function createPciBusDetails(pciDevice) {
         var builder = vxPropertyViewService.createPropertyViewBuilder();

         var sectionBuilder = builder.category('').section('busDetails').title(
               hostStr('vmdpConfig.bsfLocation.label'));

         sectionBuilder.property(
               hostStr('vmdpConfig.id.label'),
               pciDevice.busId
         ).property(
               hostStr('vmdpConfig.bus.label'),
               pciDevice.bus
         ).property(
               hostStr('vmdpConfig.slot.label'),
               pciDevice.busSlot
         ).property(
               hostStr('vmdpConfig.function.label'),
               pciDevice.function
         );

         return builder.build();
      }

      function getSplitterOptions() {
         return  {
            orientation: vuiConstants.splitter.orientation.VERTICAL,
            panes: [
               {
                  min: '150px',
                  size: '47%'
               }, {
                  min: '250px',
                  size: '53%'
               }
            ]
         };
      }

      function getGridOptions() {
         return {
            selectionMode: vuiConstants.grid.selectionMode.SINGLE,
            sortMode: vuiConstants.grid.sortMode.SINGLE,
            resizable: true,
            selectedItems: [],
            data: [],
            columnDefs: getColumnDefs(),
            height: '100%',
            pageConfig: {
               hidePager: true
            }
         };
      }

      function getColumnDefs() {
         return [
            {
               field: "busId",
               displayName: hostStr('vmdpConfig.idColHeader.label'),
               template: function(dataItem) {
                  var icon = 'vx-icon-pci';
                  if (dataItem.status === AVAILABLE_PENDING || dataItem.status === UNAVAILABLE_PENDING) {
                     icon = 'vx-icon-pci-pt-reboot';
                  }
                  return iconTextRenderer(['icon', 'text'], {
                     icon: icon,
                     text: dataItem.busId
                  });
               }
            },
            {
               field: "statusMessage",
               displayName: hostStr('vmdpConfig.statusHeader.label')
            },
            {
               field: "vendor",
               displayName: hostStr('vmdpConfig.vendorName.label')
            },
            {
               field: "deviceName",
               displayName: hostStr('vmdpConfig.deviceNameHeader.label'),
               template: textTitleRenderer
            }
         ];
      }

      function textTitleRenderer(data) {
         var text = data && data.deviceName ? data.deviceName : '';

         return '<span title=\"' + text + '\">' + text + '</span>';
      }

      function countPendingDevices(data) {
         var availablePending = _.filter(data, function(item) {
            return item.status === AVAILABLE_PENDING;
         }).length;

         var unavailablePending = _.filter(data, function(item) {
            return item.status === UNAVAILABLE_PENDING;
         }).length;

         return {
            availablePendingDevices: availablePending,
            unavailablePendingDevices: unavailablePending
         };
      }

      function formatPendingDeviceMessage(data) {
         var pendingDevices = countPendingDevices(data);
         var availablePendingDevices = pendingDevices.availablePendingDevices;
         var unavailablePendingDevices = pendingDevices.unavailablePendingDevices;

         var availablePendingDevicesCode = ((availablePendingDevices >= 2) ? 2 : availablePendingDevices).toString();
         var unavailablePendingDevicesCode = ((unavailablePendingDevices >= 2) ? 2 : unavailablePendingDevices).toString();

         var availableMessages = getAvailableMessages(availablePendingDevices, unavailablePendingDevices);

         return availableMessages[availablePendingDevicesCode+unavailablePendingDevicesCode];

      }

      function getAvailableMessages(availablePendingDevices, unavailablePendingDevices) {
         return {
            "00" : '',
            "01" : hostStr(
                  'vmdpConfig.rebootToDeactivateDevicesSingular.label',
                  unavailablePendingDevices
            ),
            "02" : hostStr(
                  'vmdpConfig.rebootToDeactivateDevicesPlural.label',
                  unavailablePendingDevices
            ),
            "10" : hostStr(
                  'vmdpConfig.rebootToActivateDevicesSingular.label',
                  availablePendingDevices
            ),
            "20" : hostStr(
                  'vmdpConfig.rebootToActivateDevicesPlural.label',
                  availablePendingDevices
            ),
            "11" : hostStr(
                  'vmdpConfig.rebootToChangeDevicesSingular.label',
                  availablePendingDevices,
                  unavailablePendingDevices
            ),
            "12" : hostStr(
                  'vmdpConfig.rebootToChangeDevicesSingular.label',
                  availablePendingDevices,
                  unavailablePendingDevices
            ),
            "21" : hostStr(
                  'vmdpConfig.rebootToChangeDevicesPlural.label',
                  availablePendingDevices,
                  unavailablePendingDevices
            ),
            "22" : hostStr(
                  'vmdpConfig.rebootToChangeDevicesPlural.label',
                  availablePendingDevices,
                  unavailablePendingDevices
            )
         };
      }

      function getChangedDevices(dataItems) {
         return _.filter(dataItems, function(dataItem) {
            return dataItem.status !== dataItem._originalStatus;
         });
      }

      function getPciDevicesWithEsxiDevice(dataItems) {
         var changedPciDevices = getChangedDevices(dataItems);
         return _.filter(changedPciDevices, function(dataItem) {
            return dataItem.esxDeviceInfo !== null;
         });
      }

      function getChangedPciDevicesUpdateSpec(dataItems) {
         var changedPciDevices = getChangedDevices(dataItems);
         return getPciPassthruUpdateSpec(changedPciDevices);
      }

      function getModalDescription(pciDevicesWithEsxiDevice) {
         var title,devices,description;
         if (pciDevicesWithEsxiDevice.length === 1) {
            // Singular label
            title = hostStr('vmdpConfig.esxDeviceWarningSingular.title');
            devices = getDevicesString(pciDevicesWithEsxiDevice);
            description =hostStr(
                  'vmdpConfig.esxDeviceWarningSingular.label',
                  devices
            );
         } else if (pciDevicesWithEsxiDevice.length === 2) {
            // Dual
            title = hostStr('vmdpConfig.esxDeviceWarningDual.title');
            devices = getDevicesString(pciDevicesWithEsxiDevice);
            description = hostStr(
               'vmdpConfig.esxDeviceWarningDual.label',
               devices
            );
         } else {
            // Plural
            title = hostStr('vmdpConfig.esxDeviceWarningPlural.title');
            devices = getDevicesString(pciDevicesWithEsxiDevice);
            description = hostStr(
               'vmdpConfig.esxDeviceWarningPlural.label',
               devices
            );
         }

         return {
            description: description,
            title: title
         };
      }

      function getDevicesString(devicesArray) {
         var esxDeviceMessages = _.map(devicesArray, function(device) {
            var key;

           if (device.esxDeviceInfo.indexOf('virtual switch') !== -1) {
              key = 'vmdpConfig.esxSwitchWarning.label';
            } else if (device.esxDeviceInfo.indexOf('dvs') !== -1) {
              key = 'vmdpConfig.esxDvsWarning.label';
            }

            if (key) {
               var esxDeviceInfoArray = device.esxDeviceInfo.split(' ');
               var nic = esxDeviceInfoArray[0];
               var esxDevice = esxDeviceInfoArray[esxDeviceInfoArray.length - 1].replace(')','');
               // return nic on vswitch or dvs message
               return hostStr(
                     key,
                     device.busId,
                     nic,
                     esxDevice
                  );
            }

            // return the storage device message
            return hostStr(
                    'vmdpConfig.esxDeviceWarning.label',
                    device.busId,
                    device.esxDeviceInfo);
         });

         return esxDeviceMessages.join(',\n');
      }

      function createPciDeviceCheckbox(dataItem) {
         return dataItem.status === NOT_CONFIGURABLE ? notConfigurablePciDevices(dataItem) :
               configurablePciDevices(dataItem);
      }

      function configurablePciDevices(dataItem) {
         dataItem.enabled = dataItem.status &&
               (dataItem.status === AVAILABLE_PENDING ||
               dataItem.status === AVAILABLE);

         return "<label><input type=\"checkbox\" " +
               " " + (dataItem.enabled ? "checked" : "") +
               " onchange=\"$(this).trigger('selectPciDevice', '" + dataItem.uid + "')\"/>" +
               iconTextRenderer(['icon', 'text'], {
                  icon: 'vx-icon-pci',
                  text: dataItem.busId
               }) +
               '</label>';
      }

      function notConfigurablePciDevices(dataItem) {
         return iconTextRenderer(['icon', 'text'], {
            icon: 'vx-icon-pci',
            text: dataItem.busId
         });
      }

      function updateStatus(dataItem) {
         switch(dataItem.status){
            case AVAILABLE_PENDING:
               dataItem.status = UNAVAILABLE;
               dataItem.statusMessage = hostStr('vmdpConfig.devStatus.unavailable');
               break;
            case AVAILABLE:
               dataItem.status = UNAVAILABLE_PENDING;
               dataItem.statusMessage = hostStr('vmdpConfig.devStatus.unavailableLater');
               break;
            case UNAVAILABLE_PENDING:
               dataItem.status = AVAILABLE;
               dataItem.statusMessage = hostStr('vmdpConfig.devStatus.available');
               break;
            case UNAVAILABLE:
               dataItem.status = AVAILABLE_PENDING;
               dataItem.statusMessage = hostStr('vmdpConfig.devStatus.availableLater');
               break;
            default:
               break;
         }
      }

      function isVmdpPassthruEnabled(dataItem) {
         return dataItem.status === AVAILABLE || dataItem.status === AVAILABLE_PENDING;
      }

      function applyEditPciDevices(pciPassthruUpdateSpec, objectId) {
         if (!_.isEmpty(pciPassthruUpdateSpec.configs)) {
            mutationService.apply(objectId,
               "com.vmware.vsphere.client.host.config.PciPassthruUpdateSpec",
               pciPassthruUpdateSpec);
         }
         return true;
      }

      function showConfirmationDialog(pciDevicesWithEsxiDevice, pciPassthruUpdateSpec, callback, objectId) {
         var okHandler = function() {
            applyEditPciDevices(pciPassthruUpdateSpec, objectId);
            callback();
         };

         var description = getModalDescription(pciDevicesWithEsxiDevice);
         var modalOptions = {
            title: description.title,
            message: description.description,
            submit: okHandler,
            icon: 'icon-warning-32',
            saveButtonLabel: hostStr('answerYes'),
            cancelButtonLabel: hostStr('answerNo'),
            preserveNewlines:true
         };

         clarityModalService.openConfirmationModal(modalOptions);
      }

      function getPciPassthruUpdateSpec(data) {
         return {
            configs: convertPciDeviceInfoToPciDeviceConfig(data)
         };
      }

      function convertPciDeviceInfoToPciDeviceConfig(data) {
         return _.map(data, function(item){
            return {
               '_type': 'com.vmware.vim.binding.vim.host.PciPassthruConfig',
               id: item.vmdpDeviceId,
               passthruEnabled: isVmdpPassthruEnabled(item)
            };
         });
      }

      return {
         createPciDetails: createPciDetails,
         createPciBusDetails: createPciBusDetails,
         createEsxDeviceInfoDetails: createEsxDeviceInfoDetails,
         getGridOptions: getGridOptions,
         getSplitterOptions: getSplitterOptions,
         formatPendingDeviceMessage: formatPendingDeviceMessage,
         getChangedPciDevicesUpdateSpec: getChangedPciDevicesUpdateSpec,
         createPciDeviceCheckbox: createPciDeviceCheckbox,
         updateStatus: updateStatus,
         getPciDevicesWithEsxiDevice: getPciDevicesWithEsxiDevice,
         applyEditPciDevices:applyEditPciDevices,
         showConfirmationDialog:showConfirmationDialog,
         textTitleRenderer: textTitleRenderer
      };
   }
})();

