/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
(function () {
   'use strict';

   /**
    * Factory that handles port groups edit related operations.
    * Builds all the edit port groups dialog page models and submits the edited
    * port group data to the server.
    */
   angular
         .module('com.vmware.vsphere.client.network')
         .factory('PortGroupDialogManager', PortGroupDialogManager);

   PortGroupDialogManager.$inject = [
      'i18nService',
      'mutationService',
      'vuiConstants'];

   function PortGroupDialogManager(
         i18nService,
         mutationService,
         vuiConstants) {

      return function (portgroupData, dialogOptions) {
         var EDIT_PORT_GROUP_SPEC =
               'com.vmware.vsphere.client.h5.network.host.portgroup.model.EditPortgroupDialogData';
         var propertiesPageDef;

         var portSecurityPageDef;

         var portTrafficShapingPolicyPageDef;

         var portFailoverPolicyPageDef;

         /**
          * Contains onCommit page callbacks.
          */
         var commitPageCallbacks = {
            commitPortgroupPage: function () { return true; },
            commitSecurityPage: function () { return true; },
            commitTrafficShapingPolicyPage: function () { return true; },
            commitPortFailoverPolicyPage: function () { return true; }
         };
         /**
          * Contains the flags indicating whether a page's state has been changed.
          */
         var pageDirtyFlags = {
            isPorpertiesPageDirty: false,
            isSecurityPageDirty: false,
            isTrafficShapingPageDirty: false,
            isFailoverPolicyPageDirty: false
         };

         createPages();

         return {
            portgroupData: portgroupData,
            commitPageCallbacks: commitPageCallbacks,
            pageDirtyFlags: pageDirtyFlags,
            getPageDefinitions: getPageDefinitions,
            showDialogErrors: showDialogErrors,
            submit: submit
         };

         function createPages() {
            propertiesPageDef = {
               page: {
                  title: i18nService.getString(
                        'NetworkUi', 'PortgroupSettingsView.properties'),
                  contentUrl: 'network-ui/resources/network/views/host/portgroup/' +
                        'pages/portGroupPropertiesPage.html',
                  onCommit: function () {
                     return execute(commitPageCallbacks.commitPortgroupPage);
                  }
               }
            };

            portSecurityPageDef = {
               page: {
                  title: i18nService.getString('NetworkUi',
                        'PortSecurityPolicyView.title'),
                  contentUrl: 'network-ui/resources/network/views/host/portgroup/' +
                        'pages/portSecurityPolicyPage.html',
                  onCommit: function () {
                     return execute(commitPageCallbacks.commitSecurityPage);
                  }
               }
            };

            portTrafficShapingPolicyPageDef = {
               page: {
                  title: i18nService.getString('NetworkUi',
                        'PortTrafficShapingPolicyView.title'),
                  contentUrl: 'network-ui/resources/network/views/host/portgroup/' +
                  'pages/portTrafficShapingPolicyPage.html',
                  onCommit: function () {
                     return execute(commitPageCallbacks.commitTrafficShapingPolicyPage);
                  }
               }
            };

            portFailoverPolicyPageDef = {
               page: {
                  title: i18nService.getString('NetworkUi',
                        'PortFailoverPolicyView.title'),
                  contentUrl: 'network-ui/resources/network/views/host/portgroup/' +
                  'pages/portFailoverPolicyPage.html',
                  onCommit: function () {
                     return execute(commitPageCallbacks.commitPortFailoverPolicyPage);
                  }
               }
            };
         }

         function getPageDefinitions() {
            var pages = [];

            pages.push(propertiesPageDef.page);
            pages.push(portSecurityPageDef.page);
            pages.push(portTrafficShapingPolicyPageDef.page);
            pages.push(portFailoverPolicyPageDef.page);

            return pages;
         }

         function submit() {
            // trigger mutation operation only if something has changed
            if (pageDirtyFlags.isPorpertiesPageDirty ||
                  pageDirtyFlags.isSecurityPageDirty ||
                  pageDirtyFlags.isTrafficShapingPageDirty ||
                  pageDirtyFlags.isFailoverPolicyPageDirty ) {
               //In order to not send any non validated values, when the value is
               // too big the serializer throws error
               if (!portgroupData.trafficShapingPolicies.overridden ||
                     !portgroupData.trafficShapingPolicies.enabled) {

                  portgroupData.trafficShapingPolicies.averageBandwidth = null;
                  portgroupData.trafficShapingPolicies.peakBandwidth = null;
                  portgroupData.trafficShapingPolicies.burstSize = null;
               }

               mutationService.apply(
                     portgroupData.hostId, EDIT_PORT_GROUP_SPEC,
                     portgroupData);
            }

            return true;
         }

         /**
          * Shows provided error messages in dialog overlay banner.
          */
         function showDialogErrors(errors /* String[] */) {
            if (!errors || errors.length < 1) {
               return;
            }

            clearValidationMessages();
            dialogOptions.validationBanner.messages = _.map(
                  errors,
                  function(message) {
                     return {
                        type: vuiConstants.validationBanner.type.ERROR,
                        text: message
                     };
                  });
         }

         function clearValidationMessages() {
            dialogOptions.validationBanner.messages = [];
         }

      };
   }

   function execute(func) {
      if (typeof func === 'function') {
         return func();
      }
   }
})();
