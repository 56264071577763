(function() {
   /**
    * Directive for auto focusing first enabled cluster vm overrides checkbox.
    */
   'use strict';
   angular.module('com.vmware.vsphere.client.cluster').directive(
         'vmOverridesAutofocus', vmOverridesAutofocus);

   function vmOverridesAutofocus() {
      return {
         restrict: 'A',
         link : function($scope: any, $element: any) {
            setTimeout(() => {
               $element.find("input[type='checkbox']:enabled").first().focus();
            }, 200);
         }
      };
   }
})();
