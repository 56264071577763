/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
(function () {
   'use strict';
   angular.module('com.vmware.vsphere.client.network')
         .controller('PortPropertiesPageController', PortPropertiesPageController);

   PortPropertiesPageController.$inject = [
            '$scope',
            '$q',
            'i18nService',
            'dataService',
            'defaultUriSchemeUtil',
            'addNetworkingWizardConstants',
            'networkUtil',
            'hostVnicServiceConstants',
            'hostVnicServiceFactory'];

   function PortPropertiesPageController($scope,
                                         $q,
                                         i18nService,
                                         dataService,
                                         defaultUriSchemeUtil,
                                         addNetworkingWizardConstants,
                                         networkUtil,
                                         hostVnicServiceConstants,
                                         hostVnicServiceFactory) {

      var ipv6EnabledProperty = "config.network.ipV6Enabled";
      var portgroupMtuProperty = 'vnic:portgroupMtuData';
      var hostStandardPortgroupInfoProperty = 'portgroup:hostStandardPortgroupInfo';
      var isTcpIpStackSupported = "capability.multipleNetworkStackInstanceSupported";
      var hostNetStackData = "netstack:netStackData";

      $scope.addNetworkingWizardConstants = addNetworkingWizardConstants;

      $scope.portPropertiesPageModel.ipSettingsType =
            $scope.portPropertiesPageModel.ipSettingsType
            || addNetworkingWizardConstants.ipSettingsMode.IP_V4;

      $scope.wizardConfig.loading = true;

      if ($scope.selectTargetNetworkPageModel.targetType
            === addNetworkingWizardConstants.targetType.EXISTING_NETWORK) {
         var dataRequestPromises = [];
         if ($scope.addNetworkingSpec.selectedNetwork.isDistributedPortgroup) {
            if ($scope.addNetworkingSpec.selectedNetwork.dvsName) {
               $scope.addNetworkingSpec.networkLabel = i18nService.getString('H5NetworkUi',
                     "VnicPortPropertiesPage.dvPortgroupFormat",
                     $scope.addNetworkingSpec.selectedNetwork.name,
                     $scope.addNetworkingSpec.selectedNetwork.dvsName);
            } else {
               $scope.addNetworkingSpec.networkLabel =
                     $scope.addNetworkingSpec.selectedNetwork.name;
            }
            //persist the generated name for next visits of the page so that we know
            //whether the value is entered by the user, thus we can determine whether to
            //generate new value or not
            $scope.portPropertiesPageModel.existingNetworkGeneratedNetworkLabel =
                  $scope.addNetworkingSpec.networkLabel;

            var networkId = defaultUriSchemeUtil.getVsphereObjectId(
                  $scope.addNetworkingSpec.selectedNetwork.networkRef);

            var networkMtuPromise = dataService
                  .getProperties(networkId, [portgroupMtuProperty]).then(
                        function (response) {
                           initMtuInputsData(response[portgroupMtuProperty]);
                        });

            dataRequestPromises.push(networkMtuPromise);

         } else {
            // opaque network
            $scope.addNetworkingSpec.networkLabel =
                  $scope.addNetworkingSpec.selectedNetwork.name;
            $scope.portPropertiesPageModel.existingNetworkGeneratedNetworkLabel =
                  $scope.addNetworkingSpec.networkLabel;
            initMtuInputsData();
         }

         var networkIpv6Promise = dataService.getProperties($scope.hostId, [ipv6EnabledProperty])
               .then(function (response) {
                  if (response) {
                     $scope.addNetworkingSpec.isIpv6Enabled = response[ipv6EnabledProperty];
                  }
               });

         if (!$scope.portPropertiesPageModel.netStackName) {
            var netStackPromises = dataService.getProperties($scope.hostId, [
               isTcpIpStackSupported,
               hostNetStackData
            ]).then(function (response) {
               if (response) {
                  $scope.addNetworkingSpec.isTcpIpStackSupported =
                        response[isTcpIpStackSupported];

                  initTcpIpStackDropDown(response[hostNetStackData]);
               }
            });

            dataRequestPromises.push(netStackPromises);
         }

         dataRequestPromises.push(networkIpv6Promise);

         // promise for the isTcpIpStackSupported property
         var isTcpIpStackSupportedPromise =
               dataService.getProperties($scope.hostId, [isTcpIpStackSupported])
               .then(function (response) {
                  if (response) {
                     $scope.addNetworkingSpec.isTcpIpStackSupported =
                           response[isTcpIpStackSupported];
                  }
               });
         dataRequestPromises.push(isTcpIpStackSupportedPromise);

         $q.all(dataRequestPromises).then(function () {
            $scope.wizardConfig.loading = false;
         });

      } else if ($scope.selectTargetNetworkPageModel.targetType) {
         var isNetworkLabelUserModified =
            $scope.portPropertiesPageModel.existingNetworkGeneratedNetworkLabel
            !== $scope.addNetworkingSpec.networkLabel && $scope.addNetworkingSpec.networkLabel !== null;
         if (isNetworkLabelUserModified) {
            $scope.wizardConfig.loading = false;
         } else {
            initPortSettings();
         }

         if ($scope.selectTargetNetworkPageModel.targetType
               === addNetworkingWizardConstants.targetType.EXISTING_STANDARD_SWITCH) {
            initMtuInputsData($scope.addNetworkingSpec.selectedStandardSwitch.mtu);
         } else if ($scope.selectTargetNetworkPageModel.targetType
               === addNetworkingWizardConstants.targetType.NEW_STANDARD_SWITCH) {
            initMtuInputsData($scope.vSwitchSpec.mtu);
         }
      }

      if (!$scope.portPropertiesPageModel.hostVnicFeatures) {
         $scope.addNetworkingSpec.vmkernelSettingsSpec = {
            _type: "com.vmware.vsphere.client.modules.api.network.VmkernelSettingsSpec"
         };

         dataService.getData($scope.hostId,
               "com.vmware.vsphere.client.h5.network.host.vnic.model.HostVnicServicesData")
               .then(function (result) {

                  $scope.portPropertiesPageModel.hostVnicFeatures = {};

                  $scope.portPropertiesPageModel.hostVnicFeatures = _.pick(
                        result.hostVnicFeatures,
                        hostVnicServiceConstants.featureFlags.TCP_IP_STACK,
                        hostVnicServiceConstants.featureFlags.PROVISIONING,
                        hostVnicServiceConstants.featureFlags.VR,
                        hostVnicServiceConstants.featureFlags.VR_NFC,
                        hostVnicServiceConstants.featureFlags.VSAN);

                  var additional = {};
                  additional[hostVnicServiceConstants.featureFlags.VMOTION_CONFIGURABLE] =
                        result[hostVnicServiceConstants.options.VMOTION_ENABLED];
                  additional[hostVnicServiceConstants.featureFlags.PROVISIONING_CONFIGURABLE] =
                        result[hostVnicServiceConstants.options.PROVISIONING_ENABLED];

                  // Add networking wizard supports only default net stack.
                  additional[hostVnicServiceConstants.featureFlags.TCP_IP_STACK_DEFAULT] =
                        true;

                  _.extend($scope.portPropertiesPageModel.hostVnicFeatures, additional);

                  var serviceSettings = hostVnicServiceFactory
                        .getServiceSettings($scope.portPropertiesPageModel.hostVnicFeatures);

                  _.extend(
                        $scope.addNetworkingSpec.vmkernelSettingsSpec, serviceSettings);
               });
      }

      function initMtuInputsData(mtu) {
         $scope.addNetworkingSpec.mtuSelectTypes = [];
         if (mtu) {
            $scope.addNetworkingSpec.mtuSelectTypes.push({
               id: 'getMtuFromSwitch',
               name: i18nService.getString('H5NetworkUi',
                     'AddNetworkingWizard.conPropPage.mtuSelectTypes.getMtuFromSwitch')
            });
            $scope.addNetworkingSpec.defaultMtu = mtu;
         } else {
            $scope.addNetworkingSpec.defaultMtu = 1500;
         }
         $scope.addNetworkingSpec.mtuSelectTypes.push({
            id: 'custom',
            name: i18nService.getString('H5NetworkUi',
                  'AddNetworkingWizard.conPropPage.mtuSelectTypes.custom')
         });

         // Init mtuType as custom in case no mtyType is specified
         if (!$scope.addNetworkingSpec.mtuSelectedType) {
            $scope.addNetworkingSpec.mtuSelectedType = $scope.addNetworkingSpec.mtuSelectTypes[0];
         }

         // No MTU selection or non-custom MTU selection - apply the default MTU
         if (!$scope.addNetworkingSpec.mtu
               || $scope.addNetworkingSpec.mtuSelectedType.id !== 'custom') {
            $scope.addNetworkingSpec.mtu = $scope.addNetworkingSpec.defaultMtu;
         }
      }

      function initPortSettings() {
         dataService.getProperties($scope.hostId, [
            hostStandardPortgroupInfoProperty,
            ipv6EnabledProperty,
            isTcpIpStackSupported,
            hostNetStackData
         ], {
            propertyParams: [{
               propertyName: hostStandardPortgroupInfoProperty,
               parameterType: 'java.lang.String',
               parameter: i18nService.getString(
                     'NetworkUi', 'AddNetworkingWizard.vmkernel')
            }]
         }).then(function (response) {
            if (response) {
               if (response[hostStandardPortgroupInfoProperty]) {
                  $scope.addNetworkingSpec.networkLabel =
                        response[hostStandardPortgroupInfoProperty].uniquePortGroupName;
                  $scope.addNetworkingSpec.vlanIds =
                        response[hostStandardPortgroupInfoProperty].vlanIds;
               }

               $scope.addNetworkingSpec.isIpv6Enabled = response[ipv6EnabledProperty];
               $scope.addNetworkingSpec.isTcpIpStackSupported = response[isTcpIpStackSupported];

               if (!$scope.portPropertiesPageModel.netStackName) {
                  // if the net stack is already set, don't reload the data
                  initTcpIpStackDropDown(response[hostNetStackData]);
               }
               $scope.wizardConfig.loading = false;
            }
         });
      }

      function initTcpIpStackDropDown(hostNetStackData) {

         if ($scope.addNetworkingSpec.isTcpIpStackSupported && hostNetStackData) {
            $scope.addNetworkingSpec.netStackName = {};
            if (hostNetStackData.netStackItems && hostNetStackData.netStackItems.length > 0) {
               $scope.addNetworkingSpec.netStackItems = [];
               hostNetStackData.netStackItems.forEach(function (value) {
                  var netStackObj = {
                     stackName: value.name,
                     netStackInstanceKey: value.key
                  };
                  // Initialize the default selected item - the default net stack
                  $scope.addNetworkingSpec.netStackItems.push(netStackObj);
                  if (value.key === addNetworkingWizardConstants.defaultNetStackKey) {
                     $scope.addNetworkingSpec.netStackName = netStackObj;
                     $scope.portPropertiesPageModel.netStackName = netStackObj;
                  }
               });
            }
         }
      }

      $scope.mtuValidator = {
         message: i18nService.getString('H5NetworkUi',
               'AddNetworkingWizard.conPropPage.mtu.inputErrorMessage'),
         isVisible: false
      };

      $scope.validateMtu = function () {
         $scope.mtuValidator.isVisible = !networkUtil.isMtuValid($scope.addNetworkingSpec.mtu);
      };

      $scope.resetMtu = function () {
         $scope.addNetworkingSpec.mtu = $scope.addNetworkingSpec.defaultMtu;
         $scope.mtuValidator.isVisible = !networkUtil.isMtuValid($scope.addNetworkingSpec.mtu);
      };

      $scope.setNetStackInstanceKey = function () {
         $scope.addNetworkingSpec.netStackItems.forEach(function (value) {
               if (value.stackName === $scope.addNetworkingSpec.netStackName.stackName) {
                  $scope.addNetworkingSpec.netStackInstanceKey =
                        value.netStackInstanceKey;
               }
            });

         $scope.portPropertiesPageModel.netStackName = $scope.addNetworkingSpec.netStackName;
         var additional = {};
         additional[hostVnicServiceConstants.featureFlags.TCP_IP_STACK_DEFAULT] =
               $scope.addNetworkingSpec.netStackName.netStackInstanceKey ===
               addNetworkingWizardConstants.defaultNetStackKey;

         additional[hostVnicServiceConstants.featureFlags.TCP_IP_STACK_PROVISIONING] =
               $scope.addNetworkingSpec.netStackName.netStackInstanceKey ===
               addNetworkingWizardConstants.provisioningNetStackKey;

         additional[hostVnicServiceConstants.featureFlags.TCP_IP_STACK_VMOTION] =
               $scope.addNetworkingSpec.netStackName.netStackInstanceKey ===
               addNetworkingWizardConstants.vmotionNetStackKey;

         _.extend($scope.portPropertiesPageModel.hostVnicFeatures, additional);

         $scope.portPropertiesPageModel.hostVnicFeatures =
               _.clone($scope.portPropertiesPageModel.hostVnicFeatures);

         var serviceSettings = hostVnicServiceFactory
               .getServiceSettings($scope.portPropertiesPageModel.hostVnicFeatures);

         _.extend(
               $scope.addNetworkingSpec.vmkernelSettingsSpec, serviceSettings);

         // for resetting the default gateway settings
         $scope.hostVnicIpRoutingInfo.isIpv4Set = false;
         $scope.hostVnicIpRoutingInfo.isIpv6Set = false;
      };

      return this;
   }
})();
