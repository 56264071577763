/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
(function () {
   'use strict';

   /**
    * Service for Resource Pool management.
    */
   angular.module('com.vmware.vsphere.client.cluster')
      .factory('editResourcePoolService', [function() {
         function editDefaultResourcePoolSpec() {
            var spec = {
               configSpec: {
                  _type: 'com.vmware.vim.binding.vim.ResourceConfigSpec',
                  cpuAllocation: {
                     _type: 'com.vmware.vim.binding.vim.ResourceAllocationInfo',
                     expandableReservation: true,
                     limit: -1,
                     reservation: 0,
                     shares: {
                        _type: 'com.vmware.vim.binding.vim.SharesInfo',
                        level: 'normal',
                        shares: 4000
                     }
                  },
                  memoryAllocation: {
                     _type: 'com.vmware.vim.binding.vim.ResourceAllocationInfo',
                     expandableReservation: true,
                     limit: -1,
                     reservation: 0,
                     shares: {
                        _type: 'com.vmware.vim.binding.vim.SharesInfo',
                        level: 'normal',
                        shares: 163840
                     }
                  }
               }
            };

            return spec;
         }

         return {
            editDefaultResourcePoolSpec: editDefaultResourcePoolSpec
         };
      }]);
})();
