/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace dvs_ui {

   import DvsPortMirroringData =
         com.vmware.vsphere.client.h5.network.dvs.portmirroring.model.DvsPortMirroringData;
   import IPromise = angular.IPromise;

   export class DvsPortMirroringComponentService {

      public static readonly $inject = ["dataService",
         "vxPropertyViewService"];

      private static readonly PORT_MIRRORING_DATA_PROPERTY_NAME: string =
            "dvswitchportmirroring:portMirroringDetails";

      constructor(private dataService: any) {

      }

      public getDvsPortMirroringData(dvsUrn: string):IPromise<DvsPortMirroringData> {

         return this.dataService
               .getProperties(dvsUrn, [DvsPortMirroringComponentService
                     .PORT_MIRRORING_DATA_PROPERTY_NAME])
               .then((response: any) => {
                  return response ? response[DvsPortMirroringComponentService
                        .PORT_MIRRORING_DATA_PROPERTY_NAME] : null;
               });

      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .service("dvsPortMirroringComponentService",
               DvsPortMirroringComponentService);
}
