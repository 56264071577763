/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
/**
 * Service providing an API for working with IP addresses.
 */
(function() {
   'use strict';

   angular.module('com.vmware.vsphere.client.vm').service('nicValidatorService', NicValidatorService);

   NicValidatorService.$inject = ['ipParserService'];

   function NicValidatorService(ipParserService) {
      this.validate = function(nicForm) {
         var validationResult = {
            errors: {
               ipv4: {}, ipv6: {}
            }
         };

         var ipv4Form = nicForm.ipv4;
         if (ipv4Form.required) {
            if (this.isIpAddressInvalid(ipv4Form)) {
               validationResult.errors.ipv4.address = true;
            }
            if (this.isSubnetMaskInvalid(ipv4Form)) {
               validationResult.errors.ipv4.subnetMask = true;
            }

            if (this.isDefaultGatewayInvalid(ipv4Form)) {
               validationResult.errors.ipv4.defaultGateway = true;
            }

            if (this.isAlternateGatewayInvalid(ipv4Form)) {
               validationResult.errors.ipv4.alternateGateway = true;
            }
         }

         var ipv6Form = nicForm.ipv6;
         if (ipv6Form.required) {
            if (this.isIp6AddressInvalid(ipv6Form)) {
               validationResult.errors.ipv6.address = true;
            }
            if (this.isIp6SubnetInvalid(ipv6Form)) {
               validationResult.errors.ipv6.subnetPrefixLength = true;
            }
         }

         return validationResult;
      };

      this.isIpAddressInvalid = function(nic) {
         return !ipParserService.isIpv4AddressValid(nic.address);
      };

      this.isSubnetMaskInvalid = function (nic) {
         return!ipParserService.isSubnetMaskValid(nic.subnetMask);
      };

      this.isDefaultGatewayInvalid = function(nic) {
         return !!nic.defaultGateway &&
               !ipParserService.isIpv4AddressValid(nic.defaultGateway);
      };
      this.isAlternateGatewayInvalid = function(nic) {
         return !!nic.alternateGateway &&
               !ipParserService.isIpv4AddressValid(nic.alternateGateway);
      };

      this.isIp6AddressInvalid = function(nic) {
         return !ipParserService.isIpv6AddressValid(nic.address);
      };
      this.isIp6SubnetInvalid = function(nic) {
         return !ipParserService.isSubnetPrefixValid(nic.subnetPrefixLength);
      };

      return this;
   }
})();
