(function() {
   'use strict';
   angular.module('com.vmware.vsphere.client.vm')
         .service('vmProvisioningCloneOptionsPageService', vmProvisioningCloneOptionsPageService);
   vmProvisioningCloneOptionsPageService.$inject = [
      'i18nService',
      'vmVuiWizardPageBuilder',
      'VmProvisioningCloneOptionsPageModel',
      'creationTypeConstants'];

   function vmProvisioningCloneOptionsPageService(i18nService, vmVuiWizardPageBuilder,
         VmProvisioningCloneOptionsPageModel, creationTypeConstants) {
      return {
         build: function(wizardScope) {
            var pageModel = new VmProvisioningCloneOptionsPageModel(
                  wizardScope.vmParams,
                  wizardScope.wizardViewData,
                  wizardScope.config);

            var cloneOptionsTitle = i18nService.getString('VmUi', 'SelectCloneOptionsProvisioningPage.Title');
            var cloneOptionsDescription = i18nService.getString('VmUi', 'SelectCloneOptionsProvisioningPage.Description');

            if(creationTypeConstants.isDeployVmFromVmtxTemplate(wizardScope.vmParams.getCreationType())){
               cloneOptionsTitle = i18nService.getString('VmUi', 'SelectCloneOptionsProvisioningPage.DeployTitle');
               cloneOptionsDescription = i18nService.getString('VmUi', 'SelectCloneOptionsProvisioningPage.DeployDescription');
            }

            wizardScope.cloneOptionsPageModel = pageModel;

            return vmVuiWizardPageBuilder.buildVuiWizardPage(wizardScope.config, {
               title: cloneOptionsTitle,
               description: cloneOptionsDescription,
               contentUrl: 'vm-ui/resources/vm/views/createVmWizard/vm-provisioning-clone-options-page.html',
               model: pageModel
            });
         }
      };
   }
}());
