/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace h5_vm {
   class VmProvisioningSelectAnyTemplatePageModelFactory {
      public static $inject = [
         "$q",
         "i18nService",
         "vmProvisioningSelectTemplatePageModel"
      ];

      constructor(
            $q: angular.IQService,
            i18nService: any,
            vmProvisioningSelectTemplatePageModel: any) {
         return (wizardScope: any) => {
            return new VmProvisioningSelectAnyTemplatePageModel(
                  $q,
                  i18nService,
                  vmProvisioningSelectTemplatePageModel,
                  wizardScope);
         };
      }
   }

   export class VmProvisioningSelectAnyTemplatePageModel {
      public static $inject = [
         "$q",
         "i18nService",
         "vmProvisioningSelectTemplatePageModel"];

      private _virtualSpecBuilder: any;
      private _wizardViewData: any;
      private _wizardConfig: any;
      private vcTemplatePageModelService: any;
      private vcTemplatePageModel: VmProvisioningSelectTemplatePageModel;
      private submittedClTemplate: any;

      constructor(private $q: angular.IQService,
            private i18nService: any,
            vmProvisioningSelectTemplatePageModel: any,
            wizardScope: any) {
         this.vcTemplatePageModelService = vmProvisioningSelectTemplatePageModel;
         this.initFromWizardScope(wizardScope);
      }

      private initFromWizardScope(wizardScope: any): void {
         this.vcTemplatePageModel = new this.vcTemplatePageModelService(
               wizardScope.vmParams,
               wizardScope.wizardViewData,
               wizardScope.config);

         this._virtualSpecBuilder = wizardScope.vmParams;
         this._wizardViewData = wizardScope.wizardViewData;
         this._wizardConfig = wizardScope.config;
      }

      public getVcTemplatePageModel() {
         return this.vcTemplatePageModel;
      }

      public getVcTemplateSelection(): string | undefined {
         return this.vcTemplatePageModel.getSelectionId();
      }

      public getIsVcTemplateSelected(): boolean {
         return this.vcTemplatePageModel.getSelectionIsTemplate();
      }

      public getClTemplateSelection(): string | undefined {
         let item: any = this._wizardViewData.getSelectedTemplate();
         return item;
      }

      public setClTemplateSelection(item?: any) {
         this._wizardViewData.setSelectedTemplate(item);
      }

      private resetStorage(): void {
         if (!this._virtualSpecBuilder) {
            return;
         }
         this._virtualSpecBuilder.resetStorage();
      }

      public resetForm(): void {
      }

      private isSameTemplate(a: any, b: any): boolean {
         if (a && b) {
            return a.templateId === b.templateId;
         }
         return false;
      }

      private isTemplateChanged(): boolean {
         let a = this._wizardViewData.getSelectedTemplate();
         let b = this.submittedClTemplate;
         if (this.isSameTemplate(a, b)) {
            return false;
         } else {
            return true;
         }
      }

      public validatePage(): VmProvisioningValidationError {
         if (this._wizardViewData.getSelectedTemplate()) {
            return {};
         }
         return this.vcTemplatePageModel.validatePage();
      }

      public submitPage(): VmProvisioningPageSubmitResult {
         let selectedTemplate: any = this._wizardViewData.getSelectedTemplate();
         if (selectedTemplate) {
            this._wizardConfig.title = this.i18nService.getString(
                  "VmUi", "ProvisioningTypes.DeployFromTemplate.WizardTitle.2",
                  selectedTemplate.templateName);
            let changed = this.isTemplateChanged();
            const retVal = {
               wizardFlow: "deployVmFromAnyTemplate",
               invalidateNextPages: changed
            };
            this._virtualSpecBuilder.setVmId(undefined);
            if (changed) {
               this.resetStorage();
               this.submittedClTemplate = selectedTemplate;
            }
            return retVal;
         }
         if (this.submittedClTemplate) {
            this.submittedClTemplate = null;
            this.resetStorage();
         }


         let result: VmProvisioningPageSubmitResult =
               this.vcTemplatePageModel.submitPage();
         result.wizardFlow = "deployVmFromAnyTemplate";
         return result;
      }
   }

   angular.module("com.vmware.vsphere.client.vm")
         .factory("vmProvisioningSelectAnyTemplatePageModel",
               VmProvisioningSelectAnyTemplatePageModelFactory);
}
