angular.module('com.vmware.vsphere.client.vm').service('opticalDriveService', [
   'deviceService',
   'diskControllerIndexAdviserService',
   'i18nService',
   'VirtualCdrom',
   function(deviceService,
         diskControllerIndexAdviserService,
         i18nService,
         VirtualCdrom) {

      var CDROM_DEVICE = 'com.vmware.vim.binding.vim.vm.device.VirtualCdrom';
      var CDROM_DEVICE_OPTION = 'com.vmware.vim.binding.vim.vm.device.VirtualCdromOption';
      var CDROM_DEVICE_OPTION__REMOTE_PASSTHROUGH = CDROM_DEVICE_OPTION + '$RemotePassthroughBackingOption';
      var CDROM_DEVICE_OPTION__PASSTHROUGH = CDROM_DEVICE_OPTION + '$PassthroughBackingOption';
      var CDROM_DEVICE_OPTION__ATAPI = CDROM_DEVICE_OPTION + '$AtapiBackingOption';
      var CDROM_DEVICE__REMOTE_PASSTHROUGH = CDROM_DEVICE + '$RemotePassthroughBackingInfo';
      var CDROM_DEVICE__PASSTHROUGH = CDROM_DEVICE + '$PassthroughBackingInfo';
      var CDROM_DEVICE__ATAPI = CDROM_DEVICE + '$AtapiBackingInfo';
      var OPTION_INFO_MAPPING = {};
      OPTION_INFO_MAPPING[CDROM_DEVICE_OPTION__REMOTE_PASSTHROUGH] = CDROM_DEVICE__REMOTE_PASSTHROUGH;
      OPTION_INFO_MAPPING[CDROM_DEVICE_OPTION__PASSTHROUGH] = CDROM_DEVICE__PASSTHROUGH;
      OPTION_INFO_MAPPING[CDROM_DEVICE_OPTION__ATAPI] = CDROM_DEVICE__ATAPI;

      deviceService.addLocalizationKey('VirtualCdrom', 'NewCdrom');

      return {
         makeDefaultOpticalDrive: makeDefaultOpticalDrive,
         CDROM_DEVICE: CDROM_DEVICE,
         CDROM_DEVICE_OPTION: CDROM_DEVICE_OPTION,
         CDROM_DEVICE_OPTION__REMOTE_PASSTHROUGH: CDROM_DEVICE_OPTION__REMOTE_PASSTHROUGH,
         CDROM_DEVICE_OPTION__PASSTHROUGH: CDROM_DEVICE_OPTION__PASSTHROUGH,
         CDROM_DEVICE_OPTION__ATAPI: CDROM_DEVICE_OPTION__ATAPI,
         CDROM_DEVICE__REMOTE_PASSTHROUGH: CDROM_DEVICE__REMOTE_PASSTHROUGH,
         CDROM_DEVICE__PASSTHROUGH: CDROM_DEVICE__PASSTHROUGH,
         CDROM_DEVICE__ATAPI: CDROM_DEVICE__ATAPI
      };

      function makeDefaultOpticalDrive(vmConfigEnvironment, guestOsDesc, inflatedDevices) {
         var deviceOptions = vmConfigEnvironment.configOption.hardwareOptions.virtualDeviceOption;
         var opticalDriveOptions = _.select(deviceOptions, function(deviceOption) {
            return deviceOption._type === CDROM_DEVICE_OPTION;
         });

         opticalDriveOptions = sortOpticalDriveOptions(opticalDriveOptions, guestOsDesc);

         var inflatedOpticalDriveDevice, inflatedOpticalDriveController;
         if (!_.isEmpty(opticalDriveOptions)) {
            var option = opticalDriveOptions[0];

            var controllerAdvise = diskControllerIndexAdviserService.getAdviceOnReusableController(
                  option.controllerType,
                  deviceOptions,
                  inflatedDevices,
                  "VirtualCdrom");

            inflatedOpticalDriveController = controllerAdvise.recommendedController;
            var recommendedBusNo = controllerAdvise.recommendedBusNo;
            var recommendedUnitNumber = controllerAdvise.recommendedUnitNo;

            if (!inflatedOpticalDriveController) {
               if (recommendedBusNo < 0) {
                  throw new Error(i18nService.getString('VmUi', 'GenericDevicePage.ControllerMaxLimitReached'));
               }
               inflatedOpticalDriveController = deviceService.makeController(deviceOptions, option.controllerType);
            }

            if (recommendedUnitNumber < 0) {
               return {
                  device: null,
                  controller: inflatedOpticalDriveController.getCurrentDevice()
               };
            } else {
               inflatedOpticalDriveDevice = makeOpticalDriveDevice(option, vmConfigEnvironment,
                     inflatedOpticalDriveController, recommendedUnitNumber);
            }
         }

         if (!inflatedOpticalDriveDevice) {
            throw new Error('no device able to be created');
         }

         deviceService.setDeviceInfo(inflatedOpticalDriveDevice);
         return {
            device: inflatedOpticalDriveDevice,
            controller: inflatedOpticalDriveController
         };
      }

      //------ Private API -----

      function sortOpticalDriveOptions(opticalDriveOptions, guestOsDesc) {
         var guestRecommendedDeviceControllerClass = guestOsDesc.recommendedCdromController && guestOsDesc.recommendedCdromController.typeClass;
         var deviceControllerPriority = [
            guestRecommendedDeviceControllerClass,
            'com.vmware.vim.binding.vim.vm.device.VirtualSATAController',
            'com.vmware.vim.binding.vim.vm.device.VirtualIDEController'
         ];

         function chosenControllerFirstSorter(a, b) {
            var c1 = a.controllerType ? a.controllerType.typeClass : null;
            var c2 = b.controllerType ? b.controllerType.typeClass : null;
            var priority1 = deviceControllerPriority.findIndex(function (deviceController) { return deviceController === c1; });
            if (priority1 === -1) {
               return 1;
            }
            var priority2 = deviceControllerPriority.findIndex(function (deviceController) { return deviceController === c2; });
            if (priority2 === -1) {
               return -1;
            }
            return priority1 - priority2;
         }
         return opticalDriveOptions.sort(chosenControllerFirstSorter);
      }

      function makeOpticalDriveDevice(driveOption, vmConfigEnvironment, inflatedDeviceController, unitNumber) {
         var opticalDriveBackingOptions = driveOption.backingOption;
         var backingInfo = createBackingInfo(opticalDriveBackingOptions, vmConfigEnvironment.configTarget.cdRom);
         if (!backingInfo) {
            return null;
         }
         var device = {
            _type: CDROM_DEVICE,
            key: deviceService.newKey(),
            connectable: createConnectInfo(driveOption.connectOption),
            backing: backingInfo,
            controllerKey: inflatedDeviceController.getKey(),
            unitNumber: unitNumber
         };
         return new VirtualCdrom(device);
      }

      function createConnectInfo(connectOption) {
         return {
            _type: "com.vmware.vim.binding.vim.vm.device.VirtualDevice$ConnectInfo",
            startConnected: false,
            allowGuestControl: connectOption.allowGuestControl.supported && connectOption.allowGuestControl.defaultValue,
            connected: false
         };
      }

      function createBackingInfo(backingOptions, hostCdromList) {
         if (!backingOptions || backingOptions.length === 0) {
            return null;
         }

         var defaultBackingOption = findDefaultBackingOption(backingOptions);

         if (!defaultBackingOption) {
            return null;
         }

         if (defaultBackingOption._type === CDROM_DEVICE_OPTION__REMOTE_PASSTHROUGH) {
            return {
               _type: OPTION_INFO_MAPPING[defaultBackingOption._type],
               deviceName: "",
               exclusive: false
            };
         }

         return createHostCdromBackingInfo(hostCdromList, defaultBackingOption);
      }

      function findDefaultBackingOption(backingOptions) {
         var defaultableBackingOptions = _.select(backingOptions, function(backingOption) {
            return _.contains(prioritizedBackingOptions(), backingOption._type);
         });

         if (!defaultableBackingOptions || defaultableBackingOptions.length === 0) {
            return null;
         }

         var firstDefaultableBackingOption = defaultableBackingOptions.sort(defaultOpticalDrivePrioritySorter)[0];
         if (firstDefaultableBackingOption) {
            return firstDefaultableBackingOption;
         }
         return null;
      }

      function createHostCdromBackingInfo(hostCdromList, defaultBackingOption) {
         if (!hostCdromList || hostCdromList.length === 0) {
            return null;
         }

         var backingInfo = {
            _type: OPTION_INFO_MAPPING[defaultBackingOption._type],
            deviceName: hostCdromList[0].name
         };

         if (defaultBackingOption._type === CDROM_DEVICE_OPTION__PASSTHROUGH) {
            backingInfo.exclusive = false;
         }

         return backingInfo;
      }

      function prioritizedBackingOptions() {
         return [
            CDROM_DEVICE_OPTION__REMOTE_PASSTHROUGH,
            CDROM_DEVICE_OPTION__PASSTHROUGH,
            CDROM_DEVICE_OPTION__ATAPI
         ];
      }

      function defaultOpticalDrivePrioritySorter(a, b) {
         var byPriority = prioritizedBackingOptions();
         return byPriority.indexOf(a._type) - byPriority.indexOf(b._type);
      }

   }]);
