(function() {
   'use strict';
   angular.module('com.vmware.vsphere.client.vm').service('vmProvisioningCustomizeHardwarePageService', [
      'i18nService',
      'wizardPageService',
      'vmVuiWizardPageBuilder',
      'VmProvisioningCustomizeHardwarePageModel',
      function(
         i18nService,
         wizardPageService,
         vmVuiWizardPageBuilder,
         VmProvisioningCustomizeHardwarePageModel
      ) {
         return {
            build: function(wizardScope) {
               var virtualMachineSpecBuilder = wizardScope.vmParams;
               var customizeHardwarePageModel =
                  new VmProvisioningCustomizeHardwarePageModel(virtualMachineSpecBuilder, wizardScope.wizardViewData);
               wizardScope.customizeHardwarePageModel = customizeHardwarePageModel;

               return vmVuiWizardPageBuilder.buildVuiWizardPage(wizardScope.config, {
                  title: i18nService.getString('VmUi', 'CustomizeHardwareProvisioningPage.Title'),
                  description: i18nService.getString('VmUi', 'CustomizeHardwareProvisioningPage.Description'),
                  contentUrl: 'vm-ui/resources/vm/views/settings/vmHardwareEdit.html',
                  model: customizeHardwarePageModel
               });
            }
         };
      }
   ]);
})();
