/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace dvs_ui {

   angular.module("com.vmware.vsphere.client.dvs").constant("dvsUpgradeWizardConstants",
      {
      pages: {
         DVS_SELECT_VERSION_PAGE: "selectVersionPage",
         DVS_NIOC_PREREQUISITES_PAGE: "upgradeNiocPage",
         DVS_NIOC_MIGRATE_SHARES_PAGE: "niocMigrateSharesPage",
         DVS_LACP_PREREQUISITES_PAGE: "enhanceLacpPage",
         DVS_LACP_CREATE_LAG_PAGE: "lacpCreateLag",
         DVS_HOST_COMPATIBILITY_PAGE: "hostCompatibilityPage",
         DVS_READY_TO_COMPLETE_PAGE: "readyToCompletePage"
      },
      properties: {
         DVS_UPGRADE_WIZARD_DATA: "dvsupgrade:wizardData",
         DVS_HOST_COMPATIBILITY_DATA: "dvsupgrade:hostCompatibility"
      },
      spec: {
         PRODUCT_SPEC_TYPE: "com.vmware.vim.binding.vim.dvs.ProductSpec"
      },
      features: {
         LACP: "lacp",
         NIOC: "nioc"
      }
   });
}
