module ds_cluster_ui {
   import IPromise = angular.IPromise;

   export class ScheduleSdrsWizardManager {

      public static $inject = ["i18nService", "vuiConstants", "mutationService",
         "defaultUriSchemeUtil", "$rootScope", "wizardPageService",
         "vscSchedulingHelper", "$q", "dsClusterConstants", "dsClusterSpecBuilderService"];

      private scheduleSettingsPageDef: any;
      private sdrsSettingsPageDef: any;

      private wizardConfig: any;
      private scheduleModel: any = {};
      private objectId: string;
      private formData: any = {};
      public sdrsDialogOptions: any;

      constructor(private i18nService: any, private vuiConstants: any,
                  private mutationService: any, private defaultUriSchemeUtil: any,
                  private $rootScope: any, private wizardPageService: any,
                  private vscSchedulingHelper: any,
                  private $q: any, private dsClusterConstants: any, private dsClusterSpecBuilderService: any) {
         this.buildPages();
      }

      /**
       * Retrieves page definitions.
       *
       * @returns {WizardPage[]}
       */
      public getPageDefinitions(): any[] {
         return [this.scheduleSettingsPageDef,
            this.sdrsSettingsPageDef];
      }

      public getObjectId(): string {
         return this.objectId;
      }

      /**
       * Retrieves wizard model.
       *
       * @returns {DsClusterModel}
       */
      public getModel(): any {
         return this.scheduleModel;
      }

      public setModel(model: any): void {
         this.scheduleModel = model;
      }

      public setFormData(formData: any): void {
         _.extend(this.formData, formData);
      }

      public submit(): boolean {
         this.scheduleModel.scheduleTaskSpec.spec =
               this.dsClusterSpecBuilderService.buildDsClusterSpec(this.formData, true);
         this.scheduleModel.scheduleTaskSpec.spec._type = this.dsClusterConstants.DS_CLUSTER_SPEC_TYPE;
         this.scheduleModel.scheduleTaskSpec.schedulingSpec.recurrence =
               this.vscSchedulingHelper.formatRecurrenceData(
                     this.scheduleModel.scheduleTaskSpec.schedulingSpec.recurrence);
         this.mutationService.apply(
               this.objectId,
               'com.vmware.vise.data.scheduling.ScheduledMutationOperationSpec',
               this.scheduleModel.scheduleTaskSpec);
         return true;

      }

      public getWizardConfig(): any {
         if (!this.wizardConfig) {
            this.wizardConfig = {
               title: this.i18nService.getString("ScheduledOpsUi", "scheduledTask.ScheduleNewTasks.label")
               + " (" + this.i18nService.getString("DsClusterUi", "sdrs.dialog.edit.title") + ")",
               pages: this.getPageDefinitions(),
               show: true,
               loading: false,
               loadingMessage: this.i18nService.getString("CommonUi", "wizard.loading"),
               onFinish: () => this.submit(),
               cssClass: "schedule-sdrs-wizard",
            };
         }

         return this.wizardConfig;
      }

      /**
       * Initializes wizard model with default values.
       */
      public init(objectId: string): void {
         this.objectId = objectId;
         // add new task
         this.scheduleModel = {
            scheduleTaskSpec: {
               spec: {},
               operation: 0, // add
               entity: this.defaultUriSchemeUtil.getManagedObjectReference(objectId),
               schedulingSpec: {
                  _type: "com.vmware.vise.core.model.scheduling.ScheduledTaskSpec",
                  scheduledTask: null,
                  recurrence: null,
                  name: "Configure Storage DRS",
                  description: "",
                  source: "StoragePod",
                  enabled: true,
                  notification: ""
               },
               deferredOperation: 0, // addDeferred
               scheduleTaskOperationMode: 0 // add
            }
         };
      }

      private buildPages(): void {
         this.scheduleSettingsPageDef = {
            title: this.i18nService.getString("ScheduledOpsUi",
                  "scheduledTask.snapshot.options"),
            contentUrl: "ds-cluster-ui/resources/ds-cluster/views/settings/services/scheduleTask/scheduleSdrsScheduleOptionsPage.html",
            state: this.vuiConstants.wizard.pageState.INCOMPLETE,
            onCommit: () => {
               let recurrence: any = this.getModel().scheduleTaskSpec.schedulingSpec.recurrence;
               let isFormStatusValid = this.getModel().scheduleTaskSpec.schedulingSpec.isFormStatusValid;
               if (recurrence && isFormStatusValid) {
                  this.setModel(this.getModel());
                  this.wizardPageService.markPageComplete(this.wizardConfig);
                  return true;
               } else {
                  if (!recurrence) {
                     this.wizardPageService.markPageIncompleteWithError(
                           this.wizardConfig,
                           this.i18nService.getString("SchedulingUi", "scheduledTask.emptyScheduler"));
                  }
                  return false;
               }
            }
         };

         this.sdrsSettingsPageDef = {
            title: this.i18nService.getString("DsClusterUi",
                  "sdrs.schedule.config.pageTitle"),
            contentUrl: "ds-cluster-ui/resources/ds-cluster/views/settings/services/scheduleTask/scheduleSdrsSdrsSettingsPage.html",
            state: this.vuiConstants.wizard.pageState.DISABLED
         };
      }
   }

   angular.module("com.vmware.vsphere.client.dsCluster")
         .service("scheduleSdrsWizardManager", ScheduleSdrsWizardManager);
}
