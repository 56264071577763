/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace dvs_ui {

   import IPromise = angular.IPromise;
   import IQService = angular.IQService;
   import ExportDvsSpec = com.vmware.vsphere.client.h5.network.dvs.exp.model.ExportDvsSpec;

   export class ExportDvsDialogService {

      static $inject = [
         "i18nService",
         "clarityModalService",
         "exportDvsConstants",
         "dataService",
         "exportDvsService",
         "$q",
         "defaultUriSchemeUtil"
      ];

      constructor(private i18nService: any,
                  private clarityModalService: any,
                  private exportDvsConstants: any,
                  private dataService: any,
                  private exportDvsService: ExportDvsService,
                  private $q: IQService,
                  private defaultUriSchemeUtil: any) {
      }

      public show(dvsId: string): void {

         let isDvpg: boolean =
               this.defaultUriSchemeUtil.getManagedObjectReference(dvsId).type ===
               "DistributedVirtualPortgroup";

         let dialogTitle: string =
               this.i18nService.getString("DvsUi", "dvs.backup.dialog.title");
         let contentTemplate: string = "dvs-ui/resources/dvs/export/exportDvsDialog.html";

         if(isDvpg) {
            dialogTitle = this.i18nService.getString("DvsUi", "dvpg.export.dialog.title");
            contentTemplate = "dvs-ui/resources/dvs/export/exportDvsDialog.html";
         }

         let dialogData: any = {};

         let modalOptions: any = {
            title: dialogTitle,
            defaultButton: "submit",
            size: "lg",
            alerts: [],
            loading: false,
            dialogData: dialogData,
            contentTemplate: contentTemplate,
            subTitle: {
               objectId: dvsId
            }
         };

         modalOptions.dialogData.pageModel = new ExportDvsPageModel();
         modalOptions.dialogData.pageModel.type = isDvpg ? "dvpg" : "dvs";
         if(!isDvpg) {
            modalOptions.dialogData.pageModel.selectedConfigurationToExport =
                  this.exportDvsConstants.configurationsToExport.DVS_AND_ALL_PORTGROUPS;
         }

         modalOptions.onSubmit = (): IPromise<boolean> => {

            const EXPORT_CONFIGURATION_PROPERTY: string =
                  "dvs:exportDvsConfiguration";
            let exportDvsSpec: ExportDvsSpec = new ExportDvsSpec();
            exportDvsSpec.description =
                  modalOptions.dialogData.pageModel.description as string || "";
            if(!isDvpg) {
               exportDvsSpec.includePortGroups =
                     modalOptions.dialogData.pageModel.selectedConfigurationToExport ===
                     this.exportDvsConstants.configurationsToExport.DVS_AND_ALL_PORTGROUPS;
            }

            modalOptions.loading = true;
            modalOptions.submitDisabled = true;

            let resultPromise: IPromise<boolean> =
                  this.dataService.getProperties(
                        dvsId,
                        EXPORT_CONFIGURATION_PROPERTY,
                        {
                           propertyParams: [{
                              propertyName: EXPORT_CONFIGURATION_PROPERTY,
                              parameterType: exportDvsSpec._type,
                              parameter: exportDvsSpec
                           }]
                        })
                        .then((response: any): IPromise<boolean> => {
                           let resultInBytes: any =
                                 response[EXPORT_CONFIGURATION_PROPERTY];

                           this.exportDvsService.saveCharArrayToDisk(resultInBytes);

                           modalOptions.loading = false;
                           modalOptions.submitDisabled = false;

                           return this.$q.when(true);
                        }, (error: any): IPromise<boolean> => {
                           return this.$q.when(true);
                        });

            return resultPromise;

         };

         this.clarityModalService.openOkCancelModal(modalOptions);
      }

   }

   angular.module("com.vmware.vsphere.client.dvs")
         .service("exportDvsDialogService", ExportDvsDialogService);
}
