/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace network_ui {

   export class VmMigrateSelectNetworksPageValidator
         implements VmMigrateWizardPageValidator {

      public static $inject = [
            "i18nService",
            "$q"];

      constructor(private i18nService: any,
                  private $q: any) {
      }

      public getValidationError(wizardScope: VmMigrateWizardScope): string[] {
         let errors: string[] = [];

         if (!wizardScope.flags.noSourceNetworkSelected &&
               !wizardScope.model.sourceNetwork) {
            errors.push(this.i18nService.getString("NetworkUi",
                  "SelectSourceDestinationNetworkPage.noSourceSelected"));
         }

         if (!wizardScope.model.destinationNetwork) {
            errors.push(this.i18nService.getString("NetworkUi",
                  "SelectSourceDestinationNetworkPage.noDestinationSelected"));
         }

         if (!wizardScope.flags.noSourceNetworkSelected &&
               this.areNetworksEqual(
                     wizardScope.model.sourceNetwork,
                     wizardScope.model.destinationNetwork)) {

            errors.push(this.i18nService.getString("NetworkUi",
                  "SelectSourceDestinationNetworkPage.invalidDestinationError"));
         }

         return this.$q.resolve(errors);
      }

      private areNetworksEqual(sourceNetwork: any, destinationNetwork: any): boolean {
         return sourceNetwork &&
               destinationNetwork &&
               sourceNetwork.networkRef &&
               destinationNetwork.networkRef &&
               sourceNetwork.networkRef.type === destinationNetwork.networkRef.type &&
               sourceNetwork.networkRef.value === destinationNetwork.networkRef.value &&
               sourceNetwork.networkRef.serverGuid ===
                     destinationNetwork.networkRef.serverGuid;
      }
   }

   angular.module("com.vmware.vsphere.client.network")
         .service("vmMigrateSelectNetworksPageValidator",
               VmMigrateSelectNetworksPageValidator);
}
