/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace dvs_ui {

   import HostListItemData = com.vmware.vsphere.client.dvs.api.host.HostListItemData;
   import DvsAddAndManageHostsSpec = com.vmware.vsphere.client.h5.network.dvs.addhost.model.DvsAddAndManageHostsSpec;
   import ManagedObjectReference = com.vmware.vim.binding.vmodl.ManagedObjectReference;
   import PnicAssignmentSpec = com.vmware.vsphere.client.h5.network.dvs.addhost.model.PnicAssignmentSpec;
   import DvsHostRemoveSpec = com.vmware.vsphere.client.dvs.api.spec.DvsHostRemoveSpec;
   import VnicAssignmentSpec = com.vmware.vsphere.client.h5.network.dvs.addhost.model.VnicAssignmentSpec;
   import VmVnicAssignmentSpec = com.vmware.vsphere.client.h5.network.dvs.addhost.model.VmVnicAssignmentSpec;
   import DvsAddHostHierarchicalListPnicItem = com.vmware.vsphere.client.h5.network.dvs.addhost.hierarchicalList.DvsAddHostHierarchicalListPnicItem;
   import DvsAddHostHierarchicalListItem = com.vmware.vsphere.client.h5.network.dvs.addhost.hierarchicalList.DvsAddHostHierarchicalListItem;
   import DvsAddHostHierarchicalListVnicItem = com.vmware.vsphere.client.h5.network.dvs.addhost.hierarchicalList.DvsAddHostHierarchicalListVnicItem;
   import DvsAddHostHierarchicalListVmVnicItem = com.vmware.vsphere.client.h5.network.dvs.addhost.hierarchicalList.DvsAddHostHierarchicalListVmVnicItem;

   /**
    * Provides methods from creating the mutation spec hierarchy sent to the backend
    * services after the completion of the DVS Add Host wizard.
    */
   export class DvsAddHostWizardSpecFactory {

      public static $inject = [
         "dvsSelectPhysicalAdaptersPageService",
         "dvsSelectVirtualAdaptersPageService",
         "dvsSelectVirtualMachinesPageService",
         "defaultUriSchemeUtil"];

      constructor(private dvsSelectPhysicalAdaptersPageService: DvsSelectPhysicalAdaptersPageService,
                  private dvsSelectVirtualAdaptersPageService: DvsSelectVirtualAdaptersPageService,
                  private dvsSelectVirtualMachinesPageService: DvsSelectVirtualMachinesPageService,
                  private defaultUriSchemeUtil: any) {
      }

      /**
       * Builds the spec the DVS mutation.
       *
       * @param model DvsAddHostWizardModel
       */
      public buildSpec(model: DvsAddHostWizardModel): DvsAddAndManageHostsSpec {

         // All selected hosts in the wizard.
         let hosts: ManagedObjectReference[] =
               _.map(model.selectedHosts, (host: HostListItemData): ManagedObjectReference => {
                  return host.hostRef;
               });

         // All items on the Select physical adapters page.
         let pnicListItems: DvsAddHostTreeListItemWrapper[] =
               model.selectPhysicalAdaptersPageModel.hosts;

         // All items on the Select virtual adapters page.
         let vnicListItems: DvsAddHostTreeListItemWrapper[] =
               model.selectVirtualAdaptersPageModel.hosts;

         // Hosts that should be mutated as a result of the wizard completion
         // (updateNetworkConfig should be applied).
         let hostsToMutate: { [key: string]: ManagedObjectReference } =
               this.getHostsToMutate(pnicListItems, vnicListItems);

         // Physical NICs that should be sent to the server.
         let pnicItemsToUpdate: any =
               _.filter(pnicListItems, (pnicListItem: DvsAddHostTreeListItemWrapper) => {
                  if (pnicListItem.type !== DvsAddHostWizardConstants.nicListItemType.PNIC_TYPE) {
                     return false;
                  }
                  let pnicItem: DvsAddHostHierarchicalListPnicItem =
                        (pnicListItem as DvsAddHostHierarchicalListItem) as DvsAddHostHierarchicalListPnicItem;
                  let hostUrn: string =
                        this.defaultUriSchemeUtil.getVsphereObjectId(pnicItem.hostRef);
                  return (this.dvsSelectPhysicalAdaptersPageService.isPnicOnThisSwitch(pnicItem) ||
                        this.dvsSelectPhysicalAdaptersPageService.isPnicUnassigned(pnicListItem)) &&
                        hostsToMutate[hostUrn] !== undefined;
               });

         let pnicAssignmentSpecs: PnicAssignmentSpec[] = _.map(pnicItemsToUpdate,
               (item: DvsAddHostTreeListItemWrapper): PnicAssignmentSpec => {
                  let listItem: DvsAddHostHierarchicalListItem =
                        item as DvsAddHostHierarchicalListItem;
                  let pnicItem: DvsAddHostHierarchicalListPnicItem =
                        listItem as DvsAddHostHierarchicalListPnicItem;
                  let pnicAssignmentSpec: PnicAssignmentSpec = new PnicAssignmentSpec();

                  pnicAssignmentSpec.host = pnicItem.hostRef;
                  pnicAssignmentSpec.pnicDevice = pnicItem.device;
                  pnicAssignmentSpec.uplinkPortgroupKey = pnicItem.uplinkPortgroup.key;
                  if (pnicItem.uplink !== DvsAddHostWizardConstants.AUTO_ASSIGN) {
                     pnicAssignmentSpec.uplink = pnicItem.uplink;
                  }
                  pnicAssignmentSpec.isPnicReassigned =
                        this.dvsSelectPhysicalAdaptersPageService.isPnicOriginallyOnThisSwitch(
                              (pnicItem as DvsAddHostHierarchicalListItem) as DvsAddHostTreeListItemWrapper) &&
                        this.dvsSelectPhysicalAdaptersPageService.isPnicOnThisSwitch(pnicItem);
                  return pnicAssignmentSpec;
               });

         // VMkernel NICs that should be sent to the server.
         let vnicItemsToUpdate: any =
               _.filter(vnicListItems, (vnicListItem: DvsAddHostTreeListItemWrapper) => {
                  if (vnicListItem.type !== DvsAddHostWizardConstants.nicListItemType.VNIC_TYPE) {
                     return false;
                  }
                  let vnicItem: DvsAddHostHierarchicalListVnicItem =
                        (vnicListItem as DvsAddHostHierarchicalListItem) as DvsAddHostHierarchicalListVnicItem;
                  let hostUrn: string =
                        this.defaultUriSchemeUtil.getVsphereObjectId(vnicItem.hostRef);
                  let isVnicModified: boolean =
                        this.dvsSelectVirtualAdaptersPageService.isVnicModified(vnicListItem) &&
                        hostsToMutate[hostUrn] !== undefined;
                  return isVnicModified;
               });
         let vnicAssignmentSpecs: VnicAssignmentSpec[] =
               _.map(vnicItemsToUpdate, (vnicItem: DvsAddHostHierarchicalListVnicItem): VnicAssignmentSpec => {
                  let vnicAssignmentSpec: VnicAssignmentSpec = new VnicAssignmentSpec();
                  vnicAssignmentSpec.host = vnicItem.hostRef;
                  vnicAssignmentSpec.vnicDevice = vnicItem.device;
                  if (vnicItem.isStandardPortgroup) {
                     vnicAssignmentSpec.standardPortgroupName = vnicItem.sourcePortgroup;
                  }
                  vnicAssignmentSpec.distributedPortgroupRef = vnicItem.portgroupRef;
                  return vnicAssignmentSpec;
               });

         // VM virtual NICs that should be sent to the server.
         let vmVnicAssignmentSpecs: VmVnicAssignmentSpec[] = [];
         if (model.selectVirtualMachinesPageModel.isMigrateVirtualMachineNetworkingEnabled) {
            let vmVnicListItems: DvsAddHostTreeListItemWrapper[] =
                  model.selectVirtualMachinesPageModel.hosts;
            let vmVnicItemsToUpdate: any =
                  _.filter(vmVnicListItems, (vmVnicListItem: DvsAddHostTreeListItemWrapper) => {
                     return vmVnicListItem && vmVnicListItem.type ===
                           DvsAddHostWizardConstants.nicListItemType.VM_VNIC_TYPE &&
                           this.dvsSelectVirtualMachinesPageService.isVnicModified(vmVnicListItem);
                  });
            vmVnicAssignmentSpecs =
                  _.map(vmVnicItemsToUpdate, (vmVnicItem: DvsAddHostHierarchicalListVmVnicItem): VmVnicAssignmentSpec => {
                     let vmVnicAssignmentSpec: VmVnicAssignmentSpec = new VmVnicAssignmentSpec();
                     vmVnicAssignmentSpec.host = vmVnicItem.hostRef;
                     vmVnicAssignmentSpec.vm = vmVnicItem.vmRef;
                     vmVnicAssignmentSpec.key = vmVnicItem.key;
                     vmVnicAssignmentSpec.distributedPortgroupRef = vmVnicItem.destinationPortgroupRef;
                     vmVnicAssignmentSpec.changeVersion = vmVnicItem.changeVersion;
                     return vmVnicAssignmentSpec;
                  });
         }

         // The final spec sent to the server.
         let spec: DvsAddAndManageHostsSpec = new DvsAddAndManageHostsSpec();
         spec.dvsConfigVersion = model.dvsConfigVersion;
         spec.hosts = hosts;
         spec.hostsToMutate = _.values(hostsToMutate);
         spec.pnicAssignmentSpecs = pnicAssignmentSpecs;
         spec.vnicAssignmentSpecs = vnicAssignmentSpecs;
         spec.vmVnicAssignmentSpecs = vmVnicAssignmentSpecs;
         spec.areMemberHosts = model.selectOperationPageModel.selectedOperationType ===
               DvsAddHostWizardConstants.operationType.MANAGE_HOST ||
               model.selectOperationPageModel.selectedOperationType ===
               DvsAddHostWizardConstants.operationType.MIGRATE_NETWORKING;

         return spec;
      }

      public buildRemoveSpec(model: DvsAddHostWizardModel): DvsHostRemoveSpec {
         let dvsHostRemoveSpec: DvsHostRemoveSpec = new DvsHostRemoveSpec();
         dvsHostRemoveSpec.hosts = _.map(model.selectHostsToRemovePageModel.hosts,
               (item: HostListItemData): ManagedObjectReference => {
                  return item.hostRef;
               });

         return dvsHostRemoveSpec;
      }

      private getHostsToMutate(pnicListItems: DvsAddHostTreeListItemWrapper[],
                               vnicListItems: DvsAddHostTreeListItemWrapper[]): { [key: string]: ManagedObjectReference } {
         let hostToMutate: { [key: string]: ManagedObjectReference } = {};

         let modifiedPnicItems: any =
               _.filter(pnicListItems, (pnicListItem: DvsAddHostTreeListItemWrapper) => {
                  return pnicListItem && pnicListItem.type ===
                        DvsAddHostWizardConstants.nicListItemType.PNIC_TYPE &&
                        this.dvsSelectPhysicalAdaptersPageService.isPnicModified(pnicListItem);
               });
         _.each(modifiedPnicItems, (item: DvsAddHostTreeListItemWrapper) => {
            let listItem: DvsAddHostHierarchicalListItem =
                  item as DvsAddHostHierarchicalListItem;
            let pnicItem: DvsAddHostHierarchicalListPnicItem =
                  listItem as DvsAddHostHierarchicalListPnicItem;

            hostToMutate[this.defaultUriSchemeUtil.getVsphereObjectId(pnicItem.hostRef)] =
                  pnicItem.hostRef;
         });

         let modifiedVnicItems: any =
               _.filter(vnicListItems, (vnicListItem: DvsAddHostTreeListItemWrapper) => {
                  return vnicListItem && vnicListItem.type ===
                        DvsAddHostWizardConstants.nicListItemType.VNIC_TYPE &&
                        this.dvsSelectVirtualAdaptersPageService.isVnicModified(vnicListItem);
               });
         _.each(modifiedVnicItems, (item: DvsAddHostTreeListItemWrapper) => {
            let listItem: DvsAddHostHierarchicalListItem =
                  item as DvsAddHostHierarchicalListItem;
            let vnicItem: DvsAddHostHierarchicalListVnicItem =
                  listItem as DvsAddHostHierarchicalListVnicItem;

            hostToMutate[this.defaultUriSchemeUtil.getVsphereObjectId(vnicItem.hostRef)] =
                  vnicItem.hostRef;
         });

         return hostToMutate;
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .service("dvsAddHostWizardSpecFactory", DvsAddHostWizardSpecFactory);
}
