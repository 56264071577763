namespace storage_ui {

   import StorageAdapterAuthenticationSettingsApi = storage_ui.StorageAdapterAuthenticationSettingsApi;

   export interface StorageAdapterAuthenticationMethod {
      id: string;
      name?: string;
   }

   export interface StorageAdapterAuthenticationChapSectionModel {
      name: string;
      secret: string;
      useInitiatorName: boolean;
   }

   export class StorageAdapterEditAuthenticationService {

      public static $inject = ["i18nService", "clarityModalService",
         "clarityConstants", "mutationService",
         "storageAdapterEditAuthenticationSpecBuilderService",
         "iscsiAuthenticationSettingsService"];

      constructor(private i18nService: any,
                  private clarityModalService: any,
                  private clarityConstants: any,
                  private mutationService: any,
                  private specBuilder: StorageAdapterEditAuthenticationSpecBuilderService,
                  private iscsiAuthenticationSettingsService: IscsiAuthenticationSettingsService) {
      }

      public editAuthSettingsIscsiTarget(hostId: string, adapter: any, target: any, parentDynamicTargetAuthProperties: any, callback: any): void {

         let ctrl = this;
         let settingsComponentApi = new StorageAdapterAuthenticationSettingsApi();

         let targetAuthSettings = this.iscsiAuthenticationSettingsService.buildIscsiAuthenticationSettings(
               target.authenticationProperties, adapter.iScsiName);

         let parentAuthSettings: any;
         if (target.parent === adapter.iScsiName || !parentDynamicTargetAuthProperties) {
            parentAuthSettings = this.iscsiAuthenticationSettingsService.buildIscsiAuthenticationSettings(
                  adapter.authenticationProperties, adapter.iScsiName);
         } else {
            parentAuthSettings = this.iscsiAuthenticationSettingsService.buildIscsiAuthenticationSettings(
                  parentDynamicTargetAuthProperties, adapter.iScsiName);
         }

         let dialogData: any = {
            adapter: adapter,
            i18n: this.i18nService.getString,
            targetAuthSettings: targetAuthSettings,
            parentAuthSettings: parentAuthSettings,
            inheritAuthSettings: targetAuthSettings.inheritSettingsFromParent,
            inheritAuthSettingsLabel: target.parent
                  ? this.i18nService.getString("StorageUi", "storage.adapters.iscsiTargets.actions.authentication.form.parentInheritLabel", target.parent)
                  : this.i18nService.getString("StorageUi", "storage.adapters.iscsiTargets.actions.authentication.form.noParentInheritLabel"),
            settingsComponentApi: settingsComponentApi
         };

         let modalOptions: any = {
            title: this.i18nService.getString("StorageUi", "storage.adapters.properties.authentication.edit.header", adapter.device),
            contentTemplate: "storage-ui/resources/storage/views/host/adapters/authentication/StorageAdapterEditTargetAuthentication.html",
            defaultButton: "submit",
            onSubmit: onCommit, // if some auth method chosen, outgoing secret is always available
            dialogData: dialogData,
            alerts: []
         };

         function isDirty(): boolean {
            return (dialogData.inheritAuthSettings !== targetAuthSettings.inheritSettingsFromParent) ||
                  (!dialogData.inheritAuthSettings && settingsComponentApi.isDirty());
         }

         function onCommit(): boolean {

            if (!isDirty()) {
               return true; // if the user did not change anything - just close
            }

            let originalSecretValue = targetAuthSettings.outgoingSecret;
            if (!dialogData.inheritAuthSettings) {
               let validationResult = settingsComponentApi.validate();
               if (!validationResult.isValid) {
                  if (validationResult.errors && validationResult.errors.length) {
                     modalOptions.alerts = _.map(validationResult.errors, function (errorMsg) {
                        return {
                           type: ctrl.clarityConstants.notifications.type.ERROR,
                           text: errorMsg
                        };
                     });
                  }

                  return false;
               }
            }

            let settings = settingsComponentApi.getSettings();
            let authenticationProperties = ctrl.specBuilder.buildAuthenticationPropertiesForTarget(
                  dialogData.inheritAuthSettings,
                  settings.authMethodSelected,
                  settings.outgoing,
                  settings.incoming,
                  originalSecretValue);

            let targetSet: any = {
               _type: "com.vmware.vim.binding.vim.host.InternetScsiHba$TargetSet"
            };

            if (target._type === "com.vmware.vim.binding.vim.host.InternetScsiHba$SendTarget") {
               targetSet.sendTargets = [target];
            } else {
               targetSet.staticTargets = [target];
            }

            let spec = {
               _type: "com.vmware.vsphere.client.storage.adapters.InternetScsiStorageAdapterAuthenticationUpdateSpec",
               device: adapter.device,
               properties: authenticationProperties,
               targetSet: targetSet
            };

            ctrl.mutationService.apply(
                  hostId,
                  spec._type,
                  spec,
                  ctrl.i18nService.getString("StorageUi", "storage.adapters.properties.authentication.edit.taskName"))
                  .then(function (result: any) {
                     if (result && !result.error) {
                        // Notify that edit is performed
                        if (callback) {
                           callback(adapter);
                        }
                     }
                  });
            return true;
         }

         this.clarityModalService.openOkCancelModal(modalOptions);
      }

      public openModal(hostId: string, adapter: any): void {
         let ctrl = this;
         let settingsComponentApi = new StorageAdapterAuthenticationSettingsApi();

         let dialogData: any = {
            adapter: adapter,
            authenticationProperties: this.iscsiAuthenticationSettingsService.buildIscsiAuthenticationSettings(
                  adapter.authenticationProperties, adapter.iScsiName),
            i18n: this.i18nService.getString,
            settingsComponentApi: settingsComponentApi
         };

         let modalOptions: any = {
            title: this.i18nService.getString("StorageUi", "storage.adapters.properties.authentication.edit.header", adapter.device),
            contentTemplate: "storage-ui/resources/storage/views/host/adapters/authentication/StorageAdapterEditAuthentication.html",
            defaultButton: "submit",
            onSubmit: onCommit, // if some auth method chosen, outgoing secret is always available
            dialogData: dialogData,
            alerts: []
         };

         function onCommit(): boolean {
            let originalSecretValue = adapter.authenticationProperties.chapSecret;

            if (!settingsComponentApi.isDirty()) {
               return true; // if the user did not change anything - just close
            }

            let validationResult = settingsComponentApi.validate();
            if (!validationResult.isValid) {
               if (validationResult.errors && validationResult.errors.length) {
                  modalOptions.alerts = _.map(validationResult.errors, function (errorMsg) {
                     return {
                        type: ctrl.clarityConstants.notifications.type.ERROR,
                        text: errorMsg
                     };
                  });
               }

               return false;
            }

            let settings = settingsComponentApi.getSettings();
            let spec = ctrl.specBuilder.buildSpecForAdapter(
                  settings.authMethodSelected,
                  settings.outgoing,
                  settings.incoming,
                  originalSecretValue,
                  adapter.device);

            ctrl.mutationService.apply(hostId, spec._type, spec,
                  ctrl.i18nService.getString("StorageUi", "storage.adapters.properties.authentication.edit.taskName"));
            return true;
         }

         this.clarityModalService.openOkCancelModal(modalOptions);
      }

   }

   angular.module("com.vmware.vsphere.client.storage")
         .service("storageAdapterEditAuthenticationService", StorageAdapterEditAuthenticationService);
}
