namespace h5_vm {
   import Specification = com.vmware.vim.binding.vim.vm.customization.Specification;
   import CustomizationSpecInfo = com.vmware.vim.binding.vim.CustomizationSpecInfo;
   import CustomizeGosPageModelBase = h5_vm.CustomizeGosPageModelBase;
   export class CustomizeGosSelectSpecPageModel extends CustomizeGosPageModelBase {
      private _relevantSpecs: Array<CustomizationSpecInfo>;

      constructor(vmId: string, vmGuestOsFullName: string,
            relevantSpecs: Array<CustomizationSpecInfo>) {

         super(vmId, vmGuestOsFullName);
         this._relevantSpecs = relevantSpecs;
      }

      get relevantSpecs(): Array<CustomizationSpecInfo> {
         return this._relevantSpecs;
      }

      specification?: Specification;
   }
}
