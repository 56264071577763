(function() {
   'use strict';
   angular.module('com.vmware.vsphere.client.vm')
         .factory('powerOpsService', PowerOpsService);
   PowerOpsService.$inject = [
      'dataService',
      'vmRecommendationsService',
      'mutationService',
      'clarityModalService',
      'i18nService',
      'actionConfirmationService',
      'defaultUriSchemeUtil'];

   function PowerOpsService(dataService, vmRecommendationsService,
         mutationService, clarityModalService,
         i18nService, actionConfirmationService, defaultUriSchemeUtil) {
      var VM_POWER_STATE_SPEC = 'com.vmware.vsphere.client.vm.powerops.VmPowerStateSpec';

      var powerOnVm = function(actionEval, availableTargets) {
         var vmPowerSpec = {
            powerState: 'poweredOn'
         };

         var message;
         var onSubmit = function(response) {
            showPowerOnFailuresAndRecommendationsDialog(response, availableTargets);
         };

         dataService.getPropertiesForObjects(
               availableTargets,
               ['parentVApp', 'name', "managedByInfo"]
         ).then(function (properties) {
            if (actionEval.context.targetObjects.length === 1) {
               message = buildSingleMessage(properties[availableTargets[0]]);
               if (message) {
                  showConfirmationDialog(availableTargets, vmPowerSpec,
                        actionEval.action.label, message, true, onSubmit);
                  return;
               }
               submitVmPowerSpec(availableTargets, vmPowerSpec).then(onSubmit);
               return;
            }

            message = buildMultipleVmsMessage(properties, actionEval, availableTargets);
            if (message) {
               showConfirmationDialog(availableTargets, vmPowerSpec,
                     actionEval.action.label, message, true, onSubmit);
               return;
            }
            submitVmPowerSpec(availableTargets, vmPowerSpec).then(onSubmit);
         });
      };

      var powerOffVm = function(availableTargets) {
         var vmPowerSpec = {
            powerOpType: 'hard',
            powerState: 'poweredOff'
         };

         return submitVmPowerSpec(availableTargets, vmPowerSpec);
      };

      var shutdownGuest = function(availableTargets) {
         var vmPowerSpec = {
            powerState: 'poweredOff',
            powerOpType: 'soft'
         };
         return submitVmPowerSpec(availableTargets, vmPowerSpec);
      };

      function suspendVMs(availableTargets) {
         var vmPowerSpec = {
            powerState: 'suspended',
            powerOpType: 'hard'
         };
         return submitVmPowerSpec(availableTargets, vmPowerSpec);
      }

      function resetVMs(availableTargets) {
         var vmPowerSpec = {
            powerOpType: 'hard',
            powerState: 'poweredOn',
            reset: true
         };

         return submitVmPowerSpec(availableTargets, vmPowerSpec);
      }

      function rebootGuest(availableTargets) {
         var vmPowerSpec = {
            powerOpType: 'soft',
            powerState: 'poweredOn',
            reset: true
         };

         return submitVmPowerSpec(availableTargets, vmPowerSpec);
      }

      function showConfirmationDialog(availableTargets, vmPowerSpec, titleText, messageText, hideIcon, onSubmit) {
         var yesHandler = function() {
            if (onSubmit) {
               return submitVmPowerSpec(availableTargets, vmPowerSpec).then(onSubmit);
            } else {
               return submitVmPowerSpec(availableTargets, vmPowerSpec);
            }
         };
         var alertProperties = {
            title: titleText,
            message: messageText,
            yesHandler: yesHandler,
            useClarity: true,
            icon: null,
            clarityIcon: {
               shape: "warning",
               class: "is-warning",
               size: 48
            },
            showXIcon: false
         };

         if (hideIcon) {
            alertProperties.icon = null;
         }
         actionConfirmationService.showConfirmationDialog(alertProperties);
      }

      function submitVmPowerSpec(availableTargets, vmPowerSpec) {
         return mutationService.applyOnMultiEntity(availableTargets,
               VM_POWER_STATE_SPEC, vmPowerSpec);
      }

      function showPowerOnFailuresAndRecommendationsDialog(response, availableTargets) {
         if (!response || !response[0] || !response[0].result) {
            return;
         }
         var notAttempted = response[0].result.notAttempted;
         if (notAttempted && notAttempted.length !== 0) {
            var objectIds = _.map(notAttempted, function(notAttemptedItem) {
               return defaultUriSchemeUtil.getVsphereObjectId(notAttemptedItem.vm);
            });
            openFailuresDialog(objectIds, notAttempted);
            return;
         }
         if (response[0].result.recommendations) {
            openRecommendationsDialog(response, availableTargets);
         }
      }

      function openFailuresDialog(objectIds, notAttempted) {
         dataService.getPropertiesForObjects(objectIds, ['name', 'hostName', 'primaryIconId']).then(
               function(vmsProps) {
                  var dialogData = {
                     notAttempted: notAttempted,
                     vmsProps: vmsProps
                  };
                  var modalOptions = {
                     title: i18nService.getString('VmUi', 'PowerOnVmResultFormMediator.failuresTitle'),
                     contentTemplate: 'vm-ui/resources/vm/views/powerops/vmPowerOnFailures.html',
                     defaultButton: 'close',
                     hideSubmitButton: true,
                     closeButtonTitle: i18nService.getString('Common', 'saveButtonLabel'),
                     size: 'lg',
                     dialogData: dialogData
                  };
                  clarityModalService.openOkCancelModal(modalOptions);
               }
         );
      }

      function openRecommendationsDialog(response, availableTargets) {
         vmRecommendationsService.fetchRecommendations(response).then(function(recommendationsData) {
            if (recommendationsData === null) {
               return;
            }

            var title = availableTargets.length > 1 ?
                  i18nService.getString('VmUi', 'RecommendationsView.descriptionMultiVm') :
                  i18nService.getString('VmUi', 'RecommendationsView.descriptionSingleVm');
            var dialogData = {
               title: title,
               recommendations: recommendationsData,
               rawRecommendations: response[0].result.recommendations
            };

            var modalOptions = {
               title: i18nService.getString('VmUi', 'PowerOnVmResultFormMediator.powerOnRecommendations'),
               dialogData: dialogData,
               contentTemplate: 'vm-ui/resources/vm/views/powerops/vmPowerOnRecommendations.html',
               defaultButton: 'submit',
               size: 'lg'
            };
            clarityModalService.openOkCancelModal(modalOptions);
         });
      }

      function buildSingleMessage(vmProperties) {
         var messages = [];
         if (vmProperties.parentVApp) {
            messages.push(i18nService.getString(
                  'VmUi', 'PowerOnResolver.SingleVappWarning', vmProperties.name));
         }

         if (vmProperties.managedByInfo) {
            messages.push(i18nService.getString(
                  'Common', 'singleVmManagedByWarning', vmProperties.managedByInfo));
         }

         if (messages.length === 0) {
            return "";
         }

            messages.push(i18nService.getString(
                  'VmUi', 'PowerOnResolver.Continue'));
            return messages.join('\n\n');
      }

      function buildMultipleVmsMessage(vmsProperties, actionEval, availableTargets) {
         var vmsInVapp = [];
         var managedVmsExist = false;
         _.each(vmsProperties, function(vmProperties) {
            if (vmProperties.parentVApp) {
               vmsInVapp.push(vmProperties);
            }

            if (vmProperties.managedByInfo) {
               managedVmsExist = true;
            }
         });
         var messages = [];
         if (vmsInVapp.length === 1) {
            messages.push(i18nService.getString(
                  'VmUi', 'PowerOnResolver.SingleVappWarning', vmsInVapp[0].name));
         }

         if (vmsInVapp.length > 1) {
            messages.push(i18nService.getString(
                  'VmUi', 'PowerOnResolver.MultiVappWarning', vmsInVapp.length));
         }

         if (managedVmsExist) {
            messages.push(i18nService.getString('Common', 'multiVmManagedByWarning'));
         }

         var someUnavailableMessage = buildSomeUnavailableMessage(availableTargets.length, actionEval);
         if(someUnavailableMessage) {
            messages.push(someUnavailableMessage);
         }

         if (messages.length === 0) {
            return "";
         }

         messages.push(i18nService.getString(
               'VmUi', 'PowerOnResolver.Continue'));
         return messages.join('\n\n');
      }

      function buildSomeUnavailableMessage(availableTargetsLength, actionEval) {
         var unavailableCount =
               actionEval.context.targetObjects.length - availableTargetsLength;
         if (unavailableCount === 0) {
            return "";
         }
         // Only some of the VMs are in correct state
         var someUnavailableMessage = i18nService.getString(
               'VmUi', 'PowerOnResolver.SomeUnavailable',
               availableTargetsLength, actionEval.context.targetObjects.length);

         var someUnavailableReasonsTxt;
         if (unavailableCount === 1) {
            someUnavailableReasonsTxt = i18nService.getString(
                  'VmUi', 'PowerOnResolver.SingleDisabledOrNoPrivs');
         } else {
            someUnavailableReasonsTxt = i18nService.getString(
                  'VmUi', 'PowerOnResolver.MultiDisabledOrNoPrivs', unavailableCount);
         }

         return [
            someUnavailableMessage,
            someUnavailableReasonsTxt
         ].join('\n\n');
      }

      return {
         powerOnVm: powerOnVm,
         powerOffVm: powerOffVm,
         shutdownGuest: shutdownGuest,
         suspendVMs: suspendVMs,
         resetVMs: resetVMs,
         rebootGuest: rebootGuest,
         showPowerOnFailuresAndRecommendationsDialog: showPowerOnFailuresAndRecommendationsDialog
      };
   }
}());
