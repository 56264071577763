module hostprofile_ui {

   export class HostHostProfileSettingsService {
      static $inject = [
         "i18nService",
         "$q",
         "dataService",
         "timeFormatterService",
         "defaultUriSchemeUtil",
         "mutationService",
         "$rootScope",
         "columnRenderersRegistry",
         "HostProfileConstants",
         "HostProfileComplianceService",
         "HostProfileConstants"
      ];

      constructor(private i18nService: any,
                  private $q: any,
                  private dataService: any,
                  private timeFormatterService: any,
                  private defaultUriSchemeUtil: any,
                  private mutationService: any,
                  private $rootScope: angular.IScope,
                  private columnRenderersRegistry: any,
                  private constants: any,
                  private hostProfileComplianceService: HostProfileComplianceService,
                  private hostProfileConstants: any) {
      }

      public getHeaderOptions(objectId: string): HeaderOptions {
         return {
            title: this.i18nService.getString("HostUi", "hostProfileNameHeaderText"),
            objectId: objectId,
            actions: [{
               actionUid: "vsphere.core.hostProfile.actions.attachHostProfile",
               customLabel: this.i18nService.getString("HostProfileUi", "attachHostProfileButton.label")
            }, {
               actionUid: "vsphere.core.hostProfile.actions.detachHostProfile",
               customLabel: this.i18nService.getString("HostProfileUi", "detachHostProfileButton.label")
            }, {
               actionUid: "vsphere.core.hostProfile.actions.changeHostProfile",
               customLabel: this.i18nService.getString("HostProfileUi", "changeHostProfileButton.label")
            }, {
               actionUid: "vsphere.core.hostProfile.actions.checkHostProfileCompliance",
               customLabel: this.i18nService.getString("HostProfileUi", "hostProfile.action.checkCompliance")
            }, {
               actionUid: "vsphere.core.hostProfile.actions.remediateHost",
               customLabel: this.i18nService.getString("HostProfileUi", "actionRemediateHostLabel")
            }]
         };
      }

      public getCustomizationHeaderOptions(objectId: string): HeaderOptions {
         return {
            title: this.i18nService.getString("HostProfileUi", "host.manage.settings.hostProfile.hostCustomization"),
            objectId: objectId,
            actions: [{
               actionUid: "vsphere.core.hostProfile.actions.editHostCustomizations",
               customLabel: this.i18nService.getString("HostProfileUi", "actionEditHostCustomizationsLabel")
            }]
         };
      }

      public getHostProfileSummary(objectId: string, isVc66OrLater: boolean): any {
         let hostProfileSummaryDataPromise: any = null;
         if (!isVc66OrLater) {
            hostProfileSummaryDataPromise = this.dataService
                  .getProperties(
                        objectId,
                        ["associatedHostProfileSummary", "hostProfileCompliance"]
                  )
                  .then((data: any) => this.$q.when(data));
         } else {
            hostProfileSummaryDataPromise = this.dataService
                  .getData(
                        objectId,
                        "com.vmware.vsphere.client.hostprofile.data.h5.model.H5HostProfileSettingsData"
                  )
                  .then((data: any) => this.$q.when(data));
         }

         return hostProfileSummaryDataPromise;
      }

      public formatHostProfile(hostProfileSummary: any) {
         let defer: any = this.$q.defer();
         if (!hostProfileSummary) {
            defer.resolve("");
         } else {
            this.$q.all([
               this.timeFormatterService.formatDate(hostProfileSummary.creationTime),
               this.timeFormatterService.formatDate(hostProfileSummary.lastUpdateTime)
            ]).then(function (result: any) {
               let createdAt = result[0];
               let editedAt = result[1];
               defer.resolve({
                  name: hostProfileSummary.name,
                  createdAt: createdAt,
                  editedAt: editedAt,
                  profile: hostProfileSummary.profile
               });

            });
         }
         return defer.promise;
      }

      public getDatagridOptions() {
         let textRenderer: any = this.columnRenderersRegistry
               .getColumnRenderer("text");

         return {
            selectionMode: "single",
            sortMode: "single",
            searchable: true,
            resizable: true,
            columnDefs: [{
               field: "name",
               sortable: true,
               searchable: true,
               displayName: this.i18nService.getString(
                     "HostUi",
                     "hostCustomizations.column.propertyName"
               ),
               template: (dataItem: any): string =>
                     textRenderer(["name"], dataItem)
            }, {
               field: "description",
               sortable: true,
               searchable: true,
               displayName: this.i18nService.getString(
                     "HostUi",
                     "hostCustomizations.column.path"
               ),
               template: (dataItem: any): string =>
                     textRenderer(["description"], dataItem)
            }, {
               field: "value",
               sortable: true,
               searchable: true,
               displayName: this.i18nService.getString(
                     "HostUi",
                     "hostCustomizations.column.value"
               ),
               template: (dataItem: any): string => {
                  let text: string;

                  if (dataItem.valueType === "password") {
                     text = this.i18nService.getString(
                           "HostProfileUi",
                           "hostCustomizations.maskedPassword"
                     );
                  } else if (dataItem.value) {
                     text = dataItem.value;
                  } else {
                     text = this.i18nService.getString(
                           "HostUi",
                           "settings.graphics.type.na"
                     );
                  }

                  return textRenderer(["text"], {"text": text});
               }
            }],
            data: [],
            height: "100%",
            pageConfig: {
               hidePager: true
            }
         };
      }

      private isCompliant(hostCompliance: any): boolean {
         const compliantValue = this.constants.complianceStatus.compliant;
         return hostCompliance &&
               hostCompliance.complianceStatus === compliantValue;
      }

      private isUnknown(hostCompliance: any): boolean {
         const unknownValue = this.constants.complianceStatus.unknown;
         return hostCompliance &&
               (hostCompliance.complianceStatus === unknownValue ||
               hostCompliance.complianceStatus === null);
      }

      public extractComplianceDataFromProfileSummary(
            profileSummary: any, userTimeFormat: any): any {

         const hostCompliance: any = profileSummary.hostProfileCompliance;
         const complianceStatuses: any = this.hostProfileComplianceService.getStatuses();
         const complianceStatusKey: string = hostCompliance.complianceStatus;
         const complianceStatus: any =
               _.has(complianceStatuses, complianceStatusKey) ?
                     complianceStatuses[complianceStatusKey] :
                     complianceStatuses[this.hostProfileConstants.complianceStatus.unknown];

         const complianceStatusCheckTimeFormatted: string =
               this.timeFormatterService.timestampToText(hostCompliance.checkTime, userTimeFormat);
         const showComplianceFailures: boolean =
               !this.isCompliant(hostCompliance) &&
               !this.isUnknown(hostCompliance);

         const showLastCheckedTime: boolean = !!complianceStatusKey &&
               (this.hostProfileConstants.complianceStatus.unknown !== complianceStatusKey);

         return {
            complianceStatus: complianceStatus,
            complianceStatusCheckTimeFormatted: complianceStatusCheckTimeFormatted,
            showComplianceFailures: showComplianceFailures,
            showLastCheckedTime: showLastCheckedTime
         };
      }
   }

   angular
         .module("com.vmware.vsphere.client.hostprofile")
         .service("hostHostProfileSettingsService", HostHostProfileSettingsService);
}
