(function() {
   'use strict';
   angular.module('com.vmware.vsphere.client.host').controller('EditExceptionUsersController', EditExceptionUsersController);

   EditExceptionUsersController.$inject = ['$scope', 'i18nService', 'vuiConstants', 'clarityModalService'];

   function EditExceptionUsersController($scope, i18nService, vuiConstants,clarityModalService) {
      var self = this;
      self.model = $scope.manager.getExceptionUsersPageModel();
      self.gridWidget = null;

      $scope.$on('kendoWidgetCreated', function(event, widget) {
         if (!widget || widget.constructor !== kendo.ui.Grid) {
            return;
         }

         self.gridWidget = widget;
      });

      var removeItemsButton = {
         actionId: 'removeItems',
         tooltipText: i18nService.getString('HostUi', 'removeExceptionUser.action.description'),
         label: i18nService.getString('HostUi', 'removeExceptionUser.action.label'),
         enabled: self.model.exceptionUsers,
         iconClass: 'vx-icon-removeIcon',
         onClick: removeSelectedItem
      };

      var addExceptionUsersButton = {
         actionId: 'addExceptionUsers',
         tooltipText: i18nService.getString('HostUi', 'addExceptionUser.action.description'),
         label: i18nService.getString('HostUi', 'addExceptionUser.action.label'),
         enabled: true,
         iconClass: 'vx-icon-addIcon',
         onClick: addExceptionUser
      };

      $scope.$watchCollection(function() {
         return self.datagridOptions.selectedItems;
      }, function() {
         if(self.datagridOptions.selectedItems && self.datagridOptions.selectedItems.length > 0){
            removeItemsButton.enabled = true;
         }else {
            removeItemsButton.enabled = false;
         }
      });

      self.datagridOptions = {
         columnDefs: [
            {
               displayName: i18nService.getString('HostUi', 'editExceptionUsersView.colHeader.User'),
               field: 'exceptionUsers',
               template : function(dataItem) {
                  return dataItem;
               }
            }
         ],
         data : self.model.exceptionUsers ? self.model.exceptionUsers : [],
         selectionMode: vuiConstants.grid.selectionMode.SINGLE,
         selectedItems: [],
         actionBarOptions: {
            actions: [addExceptionUsersButton, removeItemsButton]
         },
         resizable: false,
         height: '240px'
      };

      function addExceptionUser(){

         var modalOptions = {
            title : i18nService.getString('HostUi','addExceptionUser.action.label'),
            contentTemplate: 'host-ui/resources/host/views/settings/securityProfile/addExceptionUserView.html',
            dialogData: {
               model: self.model,
               gridWidget: self.gridWidget,
               datagridOptions: self.datagridOptions
            },
            defaultButton: 'submit'

         };

         clarityModalService.openOkCancelModal(modalOptions);
      }

      function removeSelectedItem(){
         var selectedItem = self.datagridOptions.selectedItems[0];
         if(selectedItem){
            self.datagridOptions.data = _.filter(self.model.exceptionUsers, function(item){
               return selectedItem !== item;
            });

            self.model.exceptionUsers = self.datagridOptions.data;
         }
         // refresh gird with kendo
         if (self.gridWidget) {
            self.gridWidget.refresh();
         }
      }
   }
})();
