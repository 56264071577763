namespace storage_ui {

   import IComponentController = angular.IComponentController;
   import NetworkSettingsItem = com.vmware.vsphere.client.h5.storage.data.adapters.NetworkSettingsItem;
   import IComponentOptions = angular.IComponentOptions;

   class StorageAdapterDnsSettingsComponent implements IComponentController {
      public static $inject = ["i18nService"];
      private i18n: Function;
      private model: NetworkSettingsItem;
      private dnsStackBlockExpanded = false;

      constructor(private i18nService: any) {
         this.i18n = (key: any)=>i18nService.getString("StorageUi", key);
      }

      $onInit(): void {
         if (!this.model.isPreferedDnsSupported) {
            this.model.properties.primaryDnsServerAddress = this.i18n("storage.adapters.properties.notSupportedLabel");
         }
         if (!this.model.isAlternateDnsSupported) {
            this.model.properties.alternateDnsServerAddress = this.i18n("storage.adapters.properties.notSupportedLabel");
         }
      }
   }

   angular.module("com.vmware.vsphere.client.storage")
         .component("storageAdapterDnsSettingsComponent", <IComponentOptions>{
            templateUrl: "storage-ui/resources/storage/views/host/adapters/network/StorageAdapterDnsSettingsComponent.html",
            bindings: {
               model: "="
            },
            controller: StorageAdapterDnsSettingsComponent
         });
}
