/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace dvs_ui {

   import IPromise = angular.IPromise;
   import DvsHealthCheckDetailsData =
         com.vmware.vsphere.client.h5.network.dvs.healthcheck.model.DvsHealthCheckDetailsData;
   import DvsHealthCheckDetailsItemData =
         com.vmware.vsphere.client.h5.network.dvs.healthcheck.model.DvsHealthCheckDetailsItemData;

   export class DvsHealthCheckMonitorMtuViewComponent {

      public bindings: any;
      public templateUrl: string;
      public controller: any;

      constructor() {
         this.bindings = {
            healthCheckMtuItemsData: "<"
         };
         this.templateUrl =
               "dvs-ui/resources/dvs/healthcheck/monitor/components/dvsHealthCheckMonitorVlanMtuViewTemplate.html";
         this.controller = DvsHealthCheckMonitorMtuController;
      }
   }

   class DvsHealthCheckMonitorMtuController {

      static $inject = ["i18nService",
         "$rootScope",
         "$scope",
         "vuiConstants",
         "columnRenderersRegistry",
         "pnicSettingsDialogService",
         "defaultUriSchemeUtil"];

      public datagridOptions: any;

      public healthCheckMtuItemsData: DvsHealthCheckDetailsItemData[];

      private static PNIC_ICON_ID: string = "network-lib-ui-icon-physicalAdapter";

      public constructor(private i18nService: any,
                         private $rootScope: any,
                         private $scope: any,
                         private vuiConstants: any,
                         private columnRenderersRegistry: any,
                         private pnicSettingsDialogService: PnicSettingsDialogService,
                         private defaultUriSchemeUtil: any) {

         this.createDataGrid(this.healthCheckMtuItemsData);
      }

      public $onInit() {
         this.refresh();
      }

      public $onChanges() {
         this.datagridOptions.data = this.healthCheckMtuItemsData ?
               this.healthCheckMtuItemsData : [];
      }

      private refresh(): void {
         this.createDataGrid(this.healthCheckMtuItemsData);
      }

      private createDataGrid(healthCheckVlanItemsData: DvsHealthCheckDetailsItemData[]): void {
         this.datagridOptions = {
            columnDefs: this.createColumnDefinitions(),
            sortMode: this.vuiConstants.grid.sortMode.SINGLE,
            selectionMode: this.vuiConstants.grid.selectionMode.SINGLE,
            selectedItems: [],
            data: healthCheckVlanItemsData,
            resizable: true,
            height: "100%",
            pageConfig: {
               hidePager: true
            },
            showPnicDialog: (item: any): void => {
               this.pnicSettingsDialogService.show(
                     this.defaultUriSchemeUtil.getVsphereObjectId(item.hostMor),
                     item.pnicName, item.hostName);
            }
         };
      }

      private createColumnDefinitions(): any {

         let columnRenderer:any = this.columnRenderersRegistry
               .getColumnRenderer("icon-text");

         return [{
            displayName: this.i18nService.getString(
                  "DvsUi", "healthCheck.monitor.health.vlan.uplink.column"),
            field: "uplinkName",
            width: "150px"
         },
            {
               displayName: this.i18nService.getString(
                     "DvsUi", "healthCheck.monitor.health.vlan.pnic.column"),
               field: "pnicName",
               width: "150px",
               template: (dataItem:any) => {
                  let renderer: any = columnRenderer(["icon", "text"], {
                     icon: DvsHealthCheckMonitorMtuController.PNIC_ICON_ID,
                     text: dataItem.pnicName});

                  let renderClickable = [
                     '<a ng-click="datagridOptions.showPnicDialog(dataItem)">',
                     renderer,
                     '</a>'].join('');

                  return renderClickable;
               }
            },
            {
               displayName: this.i18nService.getString(
                     "DvsUi", "healthCheck.monitor.health.mtu.mtuStatus.column"),
               field: "mtuStatus",
               width: "150px",
               template: (data:any) => {
                  return columnRenderer(["icon", "text"], {
                     icon: data.mtuStatusIcon,
                     text: data.mtuStatus});
               }
            },
            {
               displayName: this.i18nService.getString(
                     "DvsUi", "healthCheck.monitor.health.vlan.trunk.column"),
               field: "vlanTrunk",
               width: "150px"
            }];
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .component("dvsHealthCheckMonitorMtuView",
               new DvsHealthCheckMonitorMtuViewComponent());
}