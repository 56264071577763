namespace storage_ui {

   import HostData = com.vmware.vsphere.client.h5.storage.data.host.HostData;
   import IComponentController = angular.IComponentController;
   import IComponentOptions = angular.IComponentOptions;

   export class VvolSelectHostsPageComponentController implements IComponentController{

      private commonWizardManager: any;
      private vvolWizardManager: VvolWizardManager;
      private hostsData: any[];

      public static $inject = ["i18nService", "defaultUriSchemeUtil"];

      constructor(private i18nService: any,
                  private defaultUriSchemeUtil:any) {

      }

      public $onInit() {
         this.commonWizardManager
               .getAvailableHosts()
               .then((hosts: any) => {
                  this.hostsData = this.updateAvailableHosts(hosts);
               });
      }

      /**
       * Based on storage container selection change the availableHosts to be displayed in
       * the HostsPage
       */
      private updateAvailableHosts(hosts: any[]): HostData[] {
         if (!this.vvolWizardManager.container){
            return [];
         }
         let item = this.vvolWizardManager.container;

         if (!item.info.hosts) {
            // The container is not mounted to any host yet. Make them all available.
            return hosts;
         }
         //Filters out hosts that have container mounted onto them
         return _.filter(hosts, (availableHost)=>{
            return !_.find(item.info.hosts, (hostMount) =>{
               let hostMountId = this.defaultUriSchemeUtil.getVsphereObjectId(hostMount);
               return hostMountId === availableHost.hostId;
            });
         });

      }

      public formatHostName(item: any) {
         let formattedName = item.formattedName;
         if (item.isHostConnected) {
            if (!item.isVvolSupported) {
               formattedName = this.i18nService.getString("StorageUi", "addDatastoreWizard.vvolSelectHostsPage.vvolNotSupported", formattedName);
            }
            if (!item.hasConfigStoragePrivilege) {
               formattedName = this.i18nService.getString("StorageUi", "addDatastoreWizard.notEnoughPrivileges", formattedName);
            }
         }
         return formattedName;
      }

      public isHostSupported(item:any):boolean{
         return !!(item.isVvolSupported && item.hasConfigStoragePrivilege);
      }

      onSelectionChanged = (selection: any, isInitialEmptySelection: any)=> {
         if (!isInitialEmptySelection) {
            this.vvolWizardManager.selectedHosts = selection;
         }
      };

      preSelectComparator = (item: any)=> {
         let selection = this.vvolWizardManager.selectedHosts;
         if (!selection || !selection.length) {
            return false;
         }
         return !!_.find(selection, (selectionItem: any)=> item.hostId === selectionItem.hostId);
      }
   }

   angular.module("com.vmware.vsphere.client.storage")
         .component("vvolSelectHostPageComponent",
               <IComponentOptions>{
                  controller: VvolSelectHostsPageComponentController,
                  bindings: {
                     commonWizardManager: "<",
                     vvolWizardManager: "<"
                  },
                  templateUrl: "storage-ui/resources/storage/views/wizard/createDatastore/pages/vvol/VvolSelectHostsPageComponent.html"
               });
}
