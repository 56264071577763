namespace h5_vm {
   export class VmHardwareCpuIoMmuService {
      static $inject: string[] = [
         'i18nService',
         'templateHelperService',
         'vmHardwareUtil',
      ];

      constructor(private i18nService: any,
                  private templateHelperService: any,
                  private vmHardwareUtil: any) {
      }

      getSignPostParams() {
         const ioMmuVirtTemplate ='vm-ui/resources/vm/views/components/vmHardwareCpuIoMmu/iommuHelpText.html';
         return this.templateHelperService.renderTemplateWithScope(ioMmuVirtTemplate, {i18n: this.i18nService.getString})
             .then((content: string) => {
             return {
               message: content,
               title: this.i18nService.getString('Common', 'help'),
               class: 'io-mmu-help-text'
            };
         });
      }

      haveConfigSettingsPermission(privileges: Array<string>): boolean {
         const retVal = this.vmHardwareUtil.checkPrivileges(privileges, [Privileges.VM_CONFIG_SETTINGS]);
         return retVal;
      }

      isVmPoweredOn(powerState: string): boolean {
         return powerState === 'poweredOn';
      }

      isVmSuspended (powerState: string): boolean {
         return powerState === "suspended";
      }

      isDisabled(vmWorkflowMode: VmWorkflowMode, vbsEnabled: boolean, powerState: string, privileges: Array<string>): boolean {
         if (vbsEnabled) {
            return true;
         }

         const havePermission: boolean = this.haveConfigSettingsPermission(privileges);
         const poweredOnOrSuspended: boolean = this.isVmPoweredOn(powerState) || this.isVmSuspended(powerState);

         switch (vmWorkflowMode) {
            default:
               return false;
            case VmWorkflowMode.CreateMode:
               return false;
            case VmWorkflowMode.CloneMode:
               return !havePermission;
            case VmWorkflowMode.UpdateMode:
               return !havePermission || poweredOnOrSuspended;
         }
      };

   }

   angular.module('com.vmware.vsphere.client.vm')
         .service('vmHardwareCpuIoMmuService', VmHardwareCpuIoMmuService);
}
