namespace storage_ui {

   import InternetScsiStorageAdapterAuthenticationUpdateSpec = com.vmware.vsphere.client.storage.adapters.InternetScsiStorageAdapterAuthenticationUpdateSpec;
   import InternetScsiHba$AuthenticationProperties = com.vmware.vim.binding.vim.host.InternetScsiHba$AuthenticationProperties;
   export class StorageAdapterEditAuthenticationSpecBuilderService {
      public static $inject = ["storageAdapterConstants"];

      private readonly AUTH_PROP_CLASS_TYPE = "com.vmware.vim.binding.vim.host.InternetScsiHba$AuthenticationProperties";

      constructor(private constants: any) {
      }

      public buildSpecForAdapter(authMethod: string,
                                 outgoing: StorageAdapterAuthenticationChapSectionModel,
                                 incoming: StorageAdapterAuthenticationChapSectionModel,
                                 originalSecretValue: string,
                                 device: any) {

         if (authMethod === this.constants.authentication.PROHIBITED) {
            return this.createSpec(this.createNoAuthenticationProperties(), device);
         } else if (authMethod !== this.constants.authentication.MUTUAL) {
            return this.createSpec(this.createUniChapAuthenticationProperties(authMethod, outgoing, originalSecretValue), device);
         } else {
            return this.createSpec(this.createBiChapAuthentication(outgoing, incoming, originalSecretValue), device);
         }
      }

      public buildAuthenticationPropertiesForTarget(inheritFromParent: boolean,
                                                    authMethod: string,
                                                    outgoing: StorageAdapterAuthenticationChapSectionModel,
                                                    incoming: StorageAdapterAuthenticationChapSectionModel,
                                                    originalSecretValue: string): InternetScsiHba$AuthenticationProperties {

         if (inheritFromParent) {
            return this.createAuthPropsForInheritFromParent();
         }
         if (authMethod === this.constants.authentication.PROHIBITED) {
            return this.createNoAuthenticationProperties();
         } else if (authMethod !== this.constants.authentication.MUTUAL) {
            return this.createUniChapAuthenticationProperties(authMethod, outgoing, originalSecretValue);
         } else {
            return this.createBiChapAuthentication(outgoing, incoming, originalSecretValue);
         }
      }

      private createSpec(properties: any, device: any): InternetScsiStorageAdapterAuthenticationUpdateSpec {
         let spec: any = {_type: "com.vmware.vsphere.client.storage.adapters.InternetScsiStorageAdapterAuthenticationUpdateSpec"};
         spec.device = device;
         spec.properties = properties;
         return spec;
      }

      private createAuthPropsForInheritFromParent(): InternetScsiHba$AuthenticationProperties {
         let spec = new InternetScsiHba$AuthenticationProperties();
         spec._type = this.AUTH_PROP_CLASS_TYPE;
         spec.chapInherited = true;
         spec.mutualChapInherited = true;
         spec.chapAuthEnabled = false;
         // disable both outgoing and incoming CHA
         spec.chapAuthenticationType = "chapProhibited";
         spec.mutualChapAuthenticationType = "chapProhibited";
         return spec;
      }

      private createNoAuthenticationProperties(): InternetScsiHba$AuthenticationProperties {
         let spec = new InternetScsiHba$AuthenticationProperties();
         spec._type = this.AUTH_PROP_CLASS_TYPE;
         spec.chapAuthEnabled = false;
         spec.chapAuthenticationType = this.constants.authentication.PROHIBITED;
         spec.mutualChapAuthenticationType = this.constants.authentication.PROHIBITED;
         spec.chapInherited = false;
         return spec;
      }

      private createUniChapAuthenticationProperties(authMethod: string,
                                                    outgoing: StorageAdapterAuthenticationChapSectionModel,
                                                    originalSecretValue: string): InternetScsiHba$AuthenticationProperties {
         let spec = new InternetScsiHba$AuthenticationProperties();
         spec._type = this.AUTH_PROP_CLASS_TYPE;
         spec.chapAuthEnabled = true;
         spec.chapAuthenticationType = authMethod;
         spec.chapName = outgoing.name.trim();
         spec.chapSecret = this.getSecret(outgoing.secret, originalSecretValue);
         spec.mutualChapAuthenticationType = this.constants.authentication.PROHIBITED;
         spec.chapInherited = false;
         return spec;
      }

      private createBiChapAuthentication(outgoing: StorageAdapterAuthenticationChapSectionModel,
                                         incoming: StorageAdapterAuthenticationChapSectionModel,
                                         originalSecretValue: string): InternetScsiHba$AuthenticationProperties {
         let spec = new InternetScsiHba$AuthenticationProperties();
         spec._type = this.AUTH_PROP_CLASS_TYPE;
         spec.chapAuthEnabled = true;
         spec.chapAuthenticationType = this.constants.authentication.REQUIRED;
         spec.chapName = outgoing.name.trim();
         spec.chapSecret = this.getSecret(outgoing.secret, originalSecretValue);
         spec.mutualChapAuthenticationType = this.constants.authentication.REQUIRED;
         spec.mutualChapName = incoming.name.trim();
         spec.mutualChapSecret = this.getSecret(incoming.secret, originalSecretValue);
         spec.chapInherited = false;
         return spec;
      }

      private getSecret(secret: any, originalSecret: any) {
         if (secret === this.constants.EXISTING_PASSWORD_PLACEHOLDER) {
            return originalSecret;
         }
         return secret.trim();
      }
   }

   angular.module("com.vmware.vsphere.client.storage")
         .service("storageAdapterEditAuthenticationSpecBuilderService", StorageAdapterEditAuthenticationSpecBuilderService);
}
