(function () {
   'use strict';
   angular.module('com.vmware.vsphere.client.cluster')
      .controller('GeneralConfigController', GeneralConfigController);

   GeneralConfigController.$inject = ['$scope', 'i18nService',
      'clusterSwapFileLocationService'];

   function GeneralConfigController($scope, i18nService, clusterSwapFileLocationService) {
      var self = this;
      var objectId = $scope._route.objectId;

      self.headerOptions = getHeaderOptions();
      self.watchForObjects = [objectId];
      self.liveRefreshProperties = [
         "configurationEx.vmSwapPlacement"
      ];
      self.getViewData = getViewData;

      getViewData();

      function getHeaderOptions() {
         return {
            title: i18nService.getString('ClusterUi', 'manage.settings.general'),
            actions: [{
               actionUid: 'vsphere.core.cluster.actions.editSwapfileLocationAction',
               customLabel: i18nService.getString('Common', 'settingsView.defaultEditActionLabel')
            }]
         };
      }

      function getViewData() {
         clusterSwapFileLocationService.getSwapFilePlacement(objectId).then(
            function (swapPlacement) {
               self.swapFileLocation =
                  clusterSwapFileLocationService.getVmSwapFileLocation(swapPlacement);
            }
         );
      }
   }
})();
