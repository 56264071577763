(function() {
   'use strict';
   angular.module('com.vmware.vsphere.client.vm')
         .service('vmProvisioningFinishConvertToVmPageService', vmProvisioningFinishConvertToVmPageService);

   vmProvisioningFinishConvertToVmPageService.$inject = [
      'i18nService',
      'vmVuiWizardPageBuilder',
      'vmProvisioningFinishConvertToVmPageModel'];

   function vmProvisioningFinishConvertToVmPageService(
         i18nService,
         vmVuiWizardPageBuilder,
         vmProvisioningFinishConvertToVmPageModel) {
      return {
         build: function(wizardScope) {
            var pageModel = new vmProvisioningFinishConvertToVmPageModel(
                  wizardScope.vmParams,
                  wizardScope.wizardViewData);

            wizardScope.vmProvisioningFinishConvertToVmPageModel = pageModel;

            return vmVuiWizardPageBuilder.buildVuiWizardFinishPage(wizardScope.config, {
               title: i18nService.getString('VmUi', 'ProvisioningWizardSummaryPage.Title'),
               description: i18nService.getString('VmUi', 'ProvisioningWizardSummaryPage.Description'),
               contentUrl: 'vm-ui/resources/vm/views/createVmWizard/vmProvisioningFinishConvertToVm.html',
               model: pageModel
            });
         }
      };
   }
})();
