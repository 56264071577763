namespace h5_vm {
   export class VmHardwareTpm implements ng.IComponentOptions {
      bindings: any;
      controller: any;
      templateUrl: string;

      constructor() {
         this.bindings = {
            tpmDevice: "<",
            removeCallback: "&",
            createMode: "<",
            vmPoweredOn: "<",
            vmId: "<"
         };
         this.controller = VmHardwareTpmController;
         this.templateUrl =
            'vm-ui/resources/vm/views/settings/vmHardwareSettings/vmHardwareTpm/vm-hardware-tpm.component.html';
      }
   }

   class VmHardwareTpmController implements ng.IComponentController {
      static $inject: string[] = [
         'i18nService',
         'templateHelperService',
         'vmTpmService'
      ];

      public tpmDevice: any;
      public removeCallback: Function;
      public vmId: any;
      public createMode: boolean;
      public vmPoweredOn: boolean;
      public device: any;
      public tpmSignPostParams: any;
      public showModal: boolean = false;

      private i18n: Function;
      private tpmCertificates: any = [];

      constructor(private i18nService: any,
                  private templateHelperService: any,
                  private vmTpmService: any) {
         this.i18n = (key: string, bundle: string = 'VmUi') =>
            i18nService.getString(bundle, key);
      }

      $onInit() {
         this.device = this.tpmDevice.getCurrentDevice();
         let tpmHelpTemplate = 'vm-ui/resources/vm/views/settings/vmHardwareSettings/vmHardwareTpm/tpm-help-text.html';
         const spacing = "<br><br>";
         const kbArticleLink = "https://kb.vmware.com/selfservice/microsites/search.do?language=en_US&cmd=" +
               "displayKC&externalId=2147566";
         const scope = {
            i18n: this.i18n,
            createMode: this.createMode,
            vmPoweredOn: this.vmPoweredOn,
            actionableText: this.i18n('VmDeviceManager.TpmHelpAdd.NewVM.Description') + spacing,
            extraText: '',
            kbArticleLink,
            linkLabel: this.i18n('VmDeviceManager.TpmHelp.Link.Label')
         };

         if (!this.createMode && this.vmId) {
            this.vmTpmService.getCertificates(this.vmId)
                .then((result: any) => {
                   this.tpmCertificates = _.forEach(result,
                       (tmpCertificate: any, index: number) => {
                          return tmpCertificate.id = index;
                       });
                });
            scope.actionableText = this.i18n('VmDeviceManager.TpmHelpAddDelete.ExistingVM.Description') + spacing;
            scope.extraText = this.i18n('VmDeviceManager.TpmHelp.KMS.Description') + spacing;
         }

         this.templateHelperService.renderTemplateWithScope(tpmHelpTemplate, scope).then((content: string) => {
            this.tpmSignPostParams = {
               message: content,
               title: this.i18nService.getString('Common', 'help'),
               class: ''
            };
         });
      }

      isTpmRemovalDisabled() {
         return this.vmPoweredOn;
         }

      onCertificateUpdate(uploadedCertificate: any, originalCertificate: any) {
         const index = originalCertificate.id;
         if (Array.isArray(this.device.endorsementKeyCertificate) &&
             this.device.endorsementKeyCertificate.length > 0) {
            this.device.endorsementKeyCertificate[index] = {
               value: this.vmTpmService.stripHeadersFromCertificate(uploadedCertificate.rawData)
            };
         }
         this.tpmCertificates[index] = uploadedCertificate;
      }

      onTpmRemoval() {
         if (this.tpmDevice.isNew()) {
            this.removeCallback()(this.tpmDevice);
         } else {
            this.showModal = true;
         }
      }

      onAcceptModalWarning() {
         this.showModal = false;
         this.removeCallback()(this.tpmDevice);
      }

      onCancelModalWarning() {
         this.showModal = false;
      }
   }

   angular.module('com.vmware.vsphere.client.vm')
      .component('vmHardwareTpm', new VmHardwareTpm());
}
