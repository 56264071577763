/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace dvs_ui {
   export class LagConstants {
      static Mode = class {
         public static readonly ACTIVE: string = "active";
         public static readonly PASSIVE: string = "passive";
      };

      static Vlan = class {
         public static readonly OUT_RANGE_DELIMITER: string = ", ";
         public static readonly OUT_RANGE_DIVIDER: string = "-";
      };
   }
}