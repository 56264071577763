(function () {
   'use strict';
   angular.module('com.vmware.vsphere.client.cluster')
         .controller('VmsRuntimeInfoController', VmsRuntimeInfoController);

   VmsRuntimeInfoController.$inject = ['$scope', 'dataService', 'haSummaryService', '$q'];

   function VmsRuntimeInfoController($scope, dataService, haSummaryService, $q) {
      var objectId = $scope._route ? $scope._route.objectId : null;

      $scope.loadingData = false;
      $scope.$on('haSummaryPortletsRefresh', getPortletData);

      function getPortletData() {
         if ($scope.loadingData) {
            return;
         }

         $scope.loadingData = true;

         return dataService.getProperties(objectId, ['vmHaRuntimeInfo'])
               .then(function (data) {
                  if (data && data.vmHaRuntimeInfo) {
                     $scope.portletData = haSummaryService.getVmsRuntimeData(
                           data.vmHaRuntimeInfo);
                  }
                  $scope.loadingData = false;
               }).catch(function(reason) {
                  $scope.loadingData = false;
                  $q.reject(reason);
               });
      }

      getPortletData();
   }
})();
