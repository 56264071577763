namespace storage_ui {

   import IPromise = angular.IPromise;

   export class AddStorageProviderSettingsApi {
      public validate: () => boolean;
   }

   export class StorageProviderSettings {

      public name: string;

      public providerUrl: string;

      public username: string;

      public password: string;

      public customCertificateFile: any;
   }

   export class AddStorageProviderDialogService {

      public static $inject = ["i18nService", "clarityModalService",
         "clarityConstants", "mutationService", "vuiConstants", "timeFormatterService", "$q", "fileReaderService", "$rootScope"];

      constructor(private i18nService: any,
                  private clarityModalService: any,
                  private clarityConstants: any,
                  private mutationService: any,
                  private vuiConstants: any,
                  private timeFormatterService: any,
                  private $q: any,
                  private fileReaderService: FileReaderService,
                  private $rootScope: any) {
      }

      public showAddStorageProviderDialog(vcId: string, providerNames: string[]) {

         let self = this;

         let providerSettings = new StorageProviderSettings();
         let addStorageProviderSettingsApi = new AddStorageProviderSettingsApi();

         let dialogData: any = {
            providerSettings: providerSettings,
            providerNames: providerNames,
            addStorageProviderSettingsApi: addStorageProviderSettingsApi
         };

         let modalOptions: any = {
            title: this.i18nService.getString("StorageUi", "storage.storageProviders.newStorageProviderForm.title"),
            subTitle: {
               objectId: vcId
            },
            contentTemplate: "storage-ui/resources/storage/views/storageProviders/actions/AddStorageProviderDialog.html",
            defaultButton: "submit",
            onSubmit: onSubmit,
            dialogData: dialogData,
            alerts: []
         };

         this.clarityModalService.openOkCancelModal(modalOptions);

         function onSubmit(): any {
            if (modalOptions.dialogData.loading) {
               return false;
            }

            // Clear previous validation messages
            modalOptions.alerts = [];

            let validationRes: StorageProviderSettingsValidationResult|null = null;
            if (typeof dialogData.addStorageProviderSettingsApi.validate === "function") {
               validationRes = dialogData.addStorageProviderSettingsApi.validate();
            }
            if (validationRes && !validationRes.result) {
               return false;
            }

            let spec: any = {
               _type: "com.vmware.vsphere.client.sms.StorageProviderRegisterSpec",
               providerName: providerSettings.name,
               username: providerSettings.username,
               providerUrl: providerSettings.providerUrl,
               password: providerSettings.password
            };

            modalOptions.dialogData.loading = true;
            modalOptions.dialogData.loadingMsg = self.i18nService.getString(
                  "StorageUi", "storage.storageProviders.newStorageProviderForm.registering");

            if (providerSettings.customCertificateFile) {
               return self.fileReaderService.readFileContentAsBase64(providerSettings.customCertificateFile).then(
                     function (fileContent): any {

                        if (!fileContent) {
                           modalOptions.alerts = [{
                              text: self.i18nService.getString(
                                    "StorageUi", "storage.storageProviders.newStorageProviderForm.invalidFile"),
                              type: self.vuiConstants.notifications.type.ERROR
                           }];
                           modalOptions.dialogData.loading = false;
                           return false;
                        }

                        spec.certificate = fileContent;

                        return triggerMutationCall(spec).finally(function () {
                           modalOptions.dialogData.loading = false;
                        });
                     }).finally(function () {
                        modalOptions.dialogData.loading = false;
                        return false;
                     });
            } else {
               return triggerMutationCall(spec).finally(function () {
                  modalOptions.dialogData.loading = false;
               });
            }
         }

         function triggerMutationCall(spec:any): IPromise<boolean> {
            return self.mutationService.apply(vcId, spec._type, spec).then(function (response: any): any {
               if (!response) {
                  return false;
               }

               if (response && response.error && response.result && response.result.certificate && response.result.sslThumbprint) {
                  return showCertificateWarning(response.result).then(function (acceptCertificate) {
                     if (acceptCertificate) {
                        spec.certificate = response.result.certificate;
                        return triggerMutationCall(spec);
                     } else {
                        return false;
                     }
                  });
               }

               if (response.error && response.error.localizedMessage) {
                  modalOptions.alerts = [{
                     text: response.error.localizedMessage,
                     type: self.vuiConstants.notifications.type.ERROR
                  }];
                  return false;
               }

               self.$rootScope.$broadcast('vsphere.core.storage.storageProviders.providersList.refreshProvidersList');
               return true;
            });
         }

         function showCertificateWarning(certInfo: any): IPromise<boolean> {
            let warningMessagePromise: IPromise<string>;

            if (!certInfo.details) {
               warningMessagePromise = self.$q.when(self.i18nService.getString(
                     "StorageUi", "h5.storage.storageProviders.newStorageProviderForm.sslVerifyWarningMsgFormat",
                     certInfo.sslThumbprint));
            } else {
               let timePromises = {
                  validFrom: self.timeFormatterService.formatDate(certInfo.details.validFrom),
                  validTo: self.timeFormatterService.formatDate(certInfo.details.validTo),
               };

               warningMessagePromise = self.$q.all(timePromises).then(function (timePromisesResponse:any) {
                  return self.i18nService.getString(
                        "StorageUi",
                        "h5.storage.storageProviders.newStorageProviderForm.sslWithDetailsVerifyWarningMsgFormat",
                        certInfo.sslThumbprint,
                        certInfo.details.issuerName,
                        certInfo.details.subjectName,
                        timePromisesResponse.validFrom,
                        timePromisesResponse.validTo);
               });
            }

            let deferredConfirmationResponse = self.$q.defer();

            warningMessagePromise.then(function (warningMessage:string) {
               let confirmationModalOptions = {
                  title: self.i18nService.getString("StorageUi",
                        "storage.storageProviders.newStorageProviderForm.sslVerifyWarningTitle"),
                  message: warningMessage,
                  icon: "vx-icon-warning-32x32",
                  size: "lg",
                  saveButtonLabel: self.i18nService.getString("Common", "yes.label"),
                  cancelButtonLabel: self.i18nService.getString("Common", "no.label"),
                  preserveNewlines: true,
                  submit: function () {
                     deferredConfirmationResponse.resolve(true);
                     return true;
                  },
                  onCancel: function () {
                     deferredConfirmationResponse.resolve(false);
                  }
               };

               self.clarityModalService.openConfirmationModal(confirmationModalOptions);
            });

            return deferredConfirmationResponse.promise;
         }
      }
   }

   angular.module("com.vmware.vsphere.client.storage")
         .service("addStorageProviderDialogService", AddStorageProviderDialogService);
}
