/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace storage_ui {

   import DatastoreCopyItem =  com.vmware.vsphere.client.h5.storage.data.datastore.DatastoreCopyItem;

   class CopyFileDatastoreListController {

      public datagridOptions: any;

      public datastores: any[];

      public selectedDatastore: any;

      public hasSelectedDatastore: boolean;

      public selectedTargets: any[];

      public static $inject = [
         "i18nService",
         "$scope",
         "vuiConstants",
         "columnRenderersRegistry",
         "listUtil",
         "bytesFilter",
         "defaultUriSchemeUtil"];

      constructor(
            private i18nService: any,
            private $scope: any,
            private vuiConstants: any,
            private columnRenderersRegistry: any,
            private listUtil: any,
            private bytesFilter: any,
            private defaultUriSchemeUtil: any) {

         this.datagridOptions = {
            height: "100%",
            columnDefs: this.getColumnDefinitions(),
            selectionMode: this.vuiConstants.grid.selectionMode.SINGLE,
            data: [],
            resizable: true,
            selectedItems: [],
            actionBarOptions: {actions: []}
         };
      }

      public $onChanges(changes: any) {
         if (changes.datastores) {
            this.selectedTargets = _.filter(this.datastores, (datastore: DatastoreCopyItem) => {
               let dsUrn = this.defaultUriSchemeUtil.createVmomiUri(
                     datastore.ref.type,
                     datastore.ref.value,
                     datastore.ref.serverGuid
               );

               if (dsUrn === this.selectedDatastore.id) {
                  this.selectedDatastore.name = datastore.name;
                  this.selectedDatastore.type = datastore.type;
                  return true;
               }

               return false;
            });

            this.datagridOptions.data = this.datastores;
         }

            this.$scope.$watch(() => {
               return this.datagridOptions.selectedItems;
            }, (newValue:any, oldValue:any) => {
               if(newValue[0]) {
                  if (newValue[0] !== oldValue[0] && !_.isEqual(newValue,oldValue)) {
                     let dsRef = newValue[0].ref;
                     this.selectedDatastore.id = this.defaultUriSchemeUtil.createVmomiUri(
                           dsRef.type,
                           dsRef.value,
                           dsRef.serverGuid
                     );

                     this.selectedDatastore.name = newValue[0].name;
                     this.selectedDatastore.type = newValue[0].type;
                     this.hasSelectedDatastore = true;
                  }
               } else {
                  this.selectedDatastore.name = null;
                  this.selectedDatastore.type = null;
                  this.hasSelectedDatastore = false;
               }
            });

            // Once we match the datastore on which we invoke the copy action on,
            // we empty the selected targets since we don't want to preselect under
            // any circumstances.
            this.$scope.comparator = (value:any) => {
               let itemToSelect = _.find(this.selectedTargets, (selectedTarget: DatastoreCopyItem) => {
                  return selectedTarget.name === value.name;
               });

               if (itemToSelect) {
                  this.selectedTargets = [];
                  return true;
               }
               return false;
            };
      }

      public getColumnDefinitions() {
         let iconTextRenderer = this.columnRenderersRegistry.getColumnRenderer("icon-text");
         let compareNumericValues = this.listUtil.compareNumericValues;

         return [
            {
               displayName: this.i18nService.getString("StorageUi", "pathPicker.nameColumnTitle"),
               field: "name",
               template: (item: DatastoreCopyItem) => {
                  let datastoreItem = {
                     name : item.name,
                     icon: "vsphere-icon-datastore"
                  };

                  return iconTextRenderer(
                        ["icon", "name"], datastoreItem);
               }
            },
            {
               displayName: this.i18nService.getString("StorageUi", "pathPicker.spaceColumnTitle"),
               field: "freeSpace",
               template: (item: DatastoreCopyItem) => {
                  return this.bytesFilter(item.freeSpace, "B", "Auto");
               },
               sortable: function (item1: DatastoreCopyItem, item2: DatastoreCopyItem) {
                  return compareNumericValues(item1, item2, "freeSpace");
               }
            }
         ];
      }
   }

   angular.module("com.vmware.vsphere.client.storage")
         .component("copyFileDatastoreList", {
            templateUrl: "storage-ui/resources/storage/views/manage/files/copyFileComponents/copyFileDatastoreList.html",
            controller: CopyFileDatastoreListController,
            bindings: {
               datastores: "<",
               selectedDatastore: "=",
               hasSelectedDatastore: "="
            }
         });

}
