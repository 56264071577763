namespace h5_vm {
   import VmIpPoolData = h5_vm.VmIpPoolData;
   export class VmProvisioningVappPropertiesController {
      public static $inject = ["$scope"];

      networks: Array<{ id: string, name: string }>;
      ipPoolData: any;
      ipProtocol: string;
      vAppPropertyDescriptors: any[];
      private pageModel: any;
      form: any;

      constructor(private $scope: any) {
         this.pageModel = $scope.vappPropertiesPageModel;
         this.vAppPropertyDescriptors = this.pageModel.getVappPropertyDescriptors();
         this.pageModel.retrieveIpPoolsData().then((result: VmIpPoolData) => {
            this.networks = result.networks;
            this.ipPoolData = result.ipPoolData;
            this.ipProtocol = result.ipProtocol;
         });
      }
   }
   angular.module("com.vmware.vsphere.client.vm")
         .controller("VmProvisioningVappPropertiesController", VmProvisioningVappPropertiesController);
}
