namespace h5_vm {

   import GosWorkgroupDomainPageModel = h5_vm.GosWorkgroupDomainPageModel;

   export class GosWorkgroupDomainPageController {
      public static $inject = [
         "i18nService",
         "$q"];

      model: GosWorkgroupDomainPageModel;
      domainInvalid: boolean = false;
      workgroupInvalid: boolean = false;
      usernameInvalid: boolean = false;
      passwordInvalid: boolean = false;
      private readonly WORKGROUP_OPTION = WorkgroupDomainTypeOption.WORKGROUP;
      private readonly DOMAIN_OPTION = WorkgroupDomainTypeOption.DOMAIN;

      constructor(private i18n: any,
            private $q: any) {
      }

      $onInit(): void {
         this.model.onPageCommit = this.onCommit.bind(this);
      }

      $onDestroy(): void {
         this.model.onPageCommit = undefined;
      }

      onPasswordChanged() {
         this.model.isDomainPasswordChanged = true;
      }

      onCommit(): angular.IPromise<Array<string>> {
         let errors: string[] = [];

         if (this.validateWorkgroup()) {
            return this.$q.resolve([this.i18n.getString("VmUi",
                  "guestOsCustomization.workGroupOrDomainPage.invalidWorkgroup")]);
         }

         if (this.validateDomain()) {
            errors.push(this.i18n.getString("VmUi",
                  "guestOsCustomization.workGroupOrDomainPage.invalidDomain"));
         }

         if (this.validateUsername()) {
            errors.push(this.i18n.getString("VmUi",
                  "guestOsCustomization.workGroupOrDomainPage.invalidUsername"));
         }

         if (this.validatePassword()) {
            errors.push(this.i18n.getString("VmUi",
                  "customizationSpec.workgroupDomainPage.errorMessage.passwordsMismatch"));
         }

         return this.$q.resolve(errors);
      }

      validateWorkgroup(): boolean {
         this.workgroupInvalid = !this.model.workgroup &&
               this.model.networkOption === WorkgroupDomainTypeOption.WORKGROUP;
         return this.workgroupInvalid;
      }

      validateDomain(): boolean {
         this.domainInvalid = !this.model.domain &&
               this.model.networkOption === WorkgroupDomainTypeOption.DOMAIN;
         return this.domainInvalid;
      }

      validateUsername(): boolean {
         this.usernameInvalid = !this.model.username &&
               this.model.networkOption === WorkgroupDomainTypeOption.DOMAIN;
         return this.usernameInvalid;
      }

      validatePassword(): boolean {
         this.passwordInvalid = this.model.password !== this.model.confirmPassword &&
               this.model.networkOption === WorkgroupDomainTypeOption.DOMAIN;
         return this.passwordInvalid;
      }

      removeValidations() {
         if (this.model.networkOption === WorkgroupDomainTypeOption.DOMAIN) {
            this.workgroupInvalid = false;
            return;
         }
         this.domainInvalid = false;
         this.usernameInvalid = false;
         this.passwordInvalid = false;
      }

   }

   export class GosWorkgroupDomainPageComponent implements angular.IComponentOptions {
      public controller: any;
      public templateUrl: string;
      public bindings: any;

      constructor() {
         this.controller = GosWorkgroupDomainPageController;
         this.templateUrl =
               "vm-ui/resources/vm/guest-os-customization/pages/workgroup-domain/gos-workgroup-domain-page.component.html";
         this.bindings = {
            model: "<"
         };
      }
   }

   angular
         .module("com.vmware.vsphere.client.vm")
         .component("gosWorkgroupDomainPage", new GosWorkgroupDomainPageComponent());
}
