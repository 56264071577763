/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
/**
 * Shows a datagrid which supports a details view of its selected item.
 *
 * @param failoverOrderWithDetails (required):
 *    array of items for failoverOrder directive or datagridOptions for simple datagrid
 *
 * @param datagridHeaderOptions (optional):
 *    the header of the grid
 *
 * @param simple (optional):
 *    a boolean parameter which determines two different type of grids:
 *          simple=true: renders a 'simple' datagrid with no actionbar and no data division into sections.
 *          simple=false(or missing): renders a datagrid whose data is divided into failover order sections
 *                                    and supports actionbar to interact with the data.
 *
 */
(function () {
   'use strict';

   angular
         .module('com.vmware.vsphere.client.networkLibUi')
         .directive('failoverOrderWithDetails', failoverOrderWithDetails);

   failoverOrderWithDetails.$inject = [
      'physicalAdapterDetailsViewService'
   ];

   function failoverOrderWithDetails (physicalAdapterDetailsViewService) {
      var directive = {
         restrict: 'E',
         templateUrl: 'network-lib-ui/resources/network-lib/host/pnic/' +
               'failoverOrder/failoverOrderWithDetails.html',
         scope: {
            failoverOrder: '=',
            noItemsSelectedMessage: '=',
            datagridHeaderOptions: '=?',
            actions: "=?",
            actionsDisabled: '=?',
            onSelectionChange: '=?',
            selectionMode:  '=?',
            editAllItems: '=?'
         },
         replace: true,
         link: {
            pre: function ($scope) {
               $scope.showDetailsAndNotify = function (selectedItems) {
                  if (selectedItems && selectedItems.length === 1
                        && !selectedItems[0].categoryItem) {
                     $scope.selectedConnecteeDetails = physicalAdapterDetailsViewService.build(
                           $scope.failoverOrder.connecteesDetails[selectedItems[0].value]);
                  } else {
                     $scope.selectedConnecteeDetails = null;
                  }
                  if ($scope.onSelectionChange) {
                     $scope.onSelectionChange(selectedItems);
                  }
               };
            }
         }
      };
      return directive;
   }
}());
