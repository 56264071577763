namespace h5_client {
   export module common_module_ui {
      import LibraryDataModel = h5_client.common_module_ui.LibraryDataModel;
      class CreateContentLibraryService {
         static $inject = ['vuiWizardService',
            '$rootScope',
            'i18nService',
            'featureFlagsService',
            'libraryService',
            'wizardPageService',
            'contentLibraryNamePageService',
            'createContentLibraryFinishPageService',
            'createContentLibrarySelectDataStorePageService',
            'createContentLibraryConfigurePageService',
            'createContentLibraryConfigureVapiPageService'];

         constructor(private vuiWizardService: any, private $rootScope: any, private i18nService: any,
               private featureFlagsService: any, private libraryService: any,
               private wizardPageService: any, private contentLibraryNamePageService: any,
               private createContentLibraryFinishPageService: any,
               private createContentLibrarySelectDataStorePageService: any,
               private createContentLibraryConfigurePageService: any,
               private createContentLibraryConfigureVapiPageService: any) { }

         showWizard() {
            let wizardScope: any = this.$rootScope.$new();
            let createLibraryNamePage: any = this.contentLibraryNamePageService.build();
            let createLibraryFinishPage: any = this.createContentLibraryFinishPageService.build();
            let createLibrarySelectStoragePage: any = this.createContentLibrarySelectDataStorePageService.build();
            let createLibraryConfigurePage = this.createContentLibraryConfigureVapiPageService.build();
            let flows: any = [];

            flows.push({
               id: "create.contentLibrary.flowId",
               pages: [
                  createLibraryNamePage,
                  createLibraryConfigurePage,
                  createLibrarySelectStoragePage,
                  createLibraryFinishPage
               ]
            });

            wizardScope.wizardConfig = {
               pages: this.wizardPageService.initializePageStates(flows[0].pages),
               flows: this.wizardPageService.initializeFlowStates(flows)
            };

            wizardScope.wizardConfig.title = this.i18nService.getString("LibraryUi", "createLibraryWizard.title");
            wizardScope.wizardState = new LibraryDataModel();

            wizardScope.wizardConfig.onFinish = () => {
               this.libraryService.addContentLibrary(angular.copy(wizardScope.wizardState));
               return true;
            };

            this.vuiWizardService({
               scope: wizardScope,
               configObjectName: "wizardConfig",
               validationBanner: {}
            }).show();
         }
      }
      angular.module("com.vmware.vsphere.client.commonModule")
            .service("createContentLibraryService", CreateContentLibraryService);
   }
}