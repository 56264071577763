/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
(function () {
   'use strict';
   angular.module('com.vmware.vsphere.client.network')
         .service('networkLabelValidator',
               networkLabelValidator);

   networkLabelValidator.$inject = [
      'i18nService',
      'mutationService',
      'networkUtil'];

   function networkLabelValidator(i18nService,
                                  mutationService,
                                  networkUtil) {

      function getNetworkLabelClientSideValidationErrors(networkLabel) {
         var errors = [];

         if (!networkLabel || networkLabel.trim().length === 0) {
            errors.push(i18nService.getString('NetworkUi', 'VnicData.emptyNetworkLabel'));
         } else if (networkLabel.trim().length > networkUtil.NETWORK_LABEL_MAX_LENGTH) {
            errors.push(i18nService.getString('NetworkUi', 'VnicData.networkLabelTooLong'));
         } else if (networkLabel.indexOf('"') >= 0) {
            errors.push(i18nService.getString('H5NetworkUi', 'VnicData.networkLabelInvalidCharacter'));
         }

         return errors;
      }

      function getNetworkLabelServerSideValidationErrors(hostId, networkLabel) {
         return mutationService.validate(hostId,
               'com.vmware.vsphere.client.h5.network.host.wizard.addnetworking.model.StandardNetworkLabelValidateSpec', {
                  _type: 'com.vmware.vsphere.client.h5.network.host.wizard.addnetworking.model.StandardNetworkLabelValidateSpec',
                  label: networkLabel.trim()
               }).then(function (validationResult) {
            var serverErrors = [];
            if (validationResult.result) {
               serverErrors.push(validationResult.result);
            }
            return serverErrors;
         });
      }

      return {
         getNetworkLabelClientSideValidationErrors: getNetworkLabelClientSideValidationErrors,
         getNetworkLabelServerSideValidationErrors: getNetworkLabelServerSideValidationErrors
      };
   }
})();
