(function () {
   'use strict';
   angular.module('com.vmware.vsphere.client.vm')
      .component('migrateSelectNetworkBasicView', {
         templateUrl: 'vm-ui/resources/vm/views/migrationWizard/components/migrateSelectNetworkBasicView.html',
         controller: MigrateSelectNetworkBasicViewController,
         controllerAs: 'ctrl',
         bindings: {
            data: '<',
            migrateSelectNetworkService: '<',
            prefetchedNetworks : '<'
         }
      });

   MigrateSelectNetworkBasicViewController.$inject = [
      'i18nService',
      'defaultUriSchemeUtil',
      'vuiConstants',
      'NetworkSelectorMode',
      'networkSelectorDialogService',
      '$scope',
      'NetworkPageViewModes'
   ];

   function MigrateSelectNetworkBasicViewController(
      i18nService,
      defaultUriSchemeUtil,
      vuiConstants,
      NetworkSelectorMode,
      networkSelectorDialogService,
      $scope,
      NetworkPageViewModes) {

      var self = this;

      self.$onInit = function () {
         self.i18n = i18nService.getString;

         self.gridOptions = getGridOptions();
         self.detailsGridOptions = getDetailsGridOptions();
         initNetworkSelector();
         $scope.$watch(function() {
            if(self.gridOptions) {
               return self.gridOptions.selectedItems;
            }
         }, function(newValue, oldValue) {
            if (!newValue || !newValue.length) {
               self.detailsGridOptions.data = [];
            } else if (!oldValue || !oldValue.length || newValue[0] !== oldValue[0]) {
               var selectedRow = newValue[0];
               var rows = _.map(selectedRow.adapterMatches, function(match) {
                  var sourceVmId = defaultUriSchemeUtil.getVsphereObjectId(match.sourceVm);
                  return {
                     vm: selectedRow._attachedVmNamesById[sourceVmId],
                     sourceAdapter: match.sourceAdapter.deviceInfo.label,
                     networkName: selectedRow.networkName
                  };
               });
               var networkLabel = selectedRow.networkName;
               if (!networkLabel) {
                  networkLabel = self.i18n(
                        'VmUi', 'MigrationWizard.readyPage.invalidNetworkName');
               }
               self.selectNetworkDetailsGridDescription = self.i18n(
                     'Common', 'vns.networkDetails.header', networkLabel);
               self.detailsGridOptions.data = rows;
            }
         });
      };

      function getGridOptions () {
         return {
            sortMode: vuiConstants.grid.sortMode.SINGLE,
            selectionMode: vuiConstants.grid.selectionMode.SINGLE,
            searchable: false,
            resizable: true,
            data: self.data,
            columnDefs: getColumnDefs(),
            encoded: false,
            selectedItems: [],
            pageConfig: {
               hidePager: true
            }
         };
      }

      function getColumnDefs() {
         return [
            {
               displayName: self.i18n('Common', 'vns.basic.source'),
               field: 'networkName',
               type: 'string',
               sortable: true
            },
            {
               displayName: self.i18n('Common', 'vns.usedBy'),
               field: '_usedBy',
               type: 'string',
               sortable: true,
               template: function(item) {
                  return _.escape(item.usedBy());
               },
               width:'40%'
            },
            {
               displayName: self.i18n('Common', 'vns.basic.destination'),
               field: 'destinationNetworkName',
               encoded: false,
               template: getNetworkSelector
            }
         ];
      }

      function getNetworkSelector(data) {
         return '<div network-selector ' +
               'object-ref="$parent.$parent.ctrl.networkProviderId" '+
               'mode="$parent.$parent.ctrl.networkSelectorMode" '+
               'filter-spec="$parent.$parent.ctrl.networkSelectorFilterSpec" '+
               'dialog-spec="$parent.$parent.ctrl.networkSelectorDialogSpec" '+
               'preselected-network="$parent.$parent.ctrl.destinationNetworks['+ data.index +']" '+
               'prefetched-networks="$parent.$parent.ctrl.prefetchedNetworks" '+
               'on-selected-networks="$parent.$parent.ctrl.onSelectNetworks(selectedNetworks,' + data.index + ')" ' +
               'sort-params="$parent.$parent.ctrl.sortParams">'+
               '</div>';
      }

      function getDetailsGridOptions() {
         return {
            sortMode: vuiConstants.grid.sortMode.SINGLE,
            searchable: false,
            resizable: true,
            data: [],
            columnDefs: getDetailsColumnDefs(),
            pageConfig: {
               hidePager: true
            }
         };
      }

      function getDetailsColumnDefs() {
         return [
            {
               displayName: self.i18n('Common', 'vns.vm'),
               field: 'vm',
               type: 'string',
               sortable: true,
               width:'40%'
            }, {
               displayName: self.i18n('Common', 'vns.networkAdapter'),
               field: 'sourceAdapter',
               type: 'string'
            }, {
               displayName: self.i18n('Common', 'vns.network'),
               field: 'networkName',
               type: 'string',
               sortable: true
            }
         ];
      }

      function buildDestinationNetworks() {
         self.destinationNetworks = _.map(
               self.data,
               function (networkItem) {
                  return {
                     id: defaultUriSchemeUtil.getVsphereObjectId(
                           networkItem.destinationNetworkRef),
                     name: networkItem.destinationNetworkName,
                     opaqueNetworkType: networkItem.opaqueNetworkType
                  };
               });
      }

      function initNetworkSelector() {
         self.networkProviderId = self.migrateSelectNetworkService.getNetworkProviderId();
         self.networkSelectorMode = NetworkSelectorMode.SINGLE_SELECTION_DROPDOWN;
         self.networkSelectorFilterSpec =
               networkSelectorDialogService.createNetworkSelectorFilterSpec(
                     true/*includeStandardNetworks*/,
                     true/*includeOpaqueNetworks*/,
                     true/*includeDistributedPortGroups*/,
                     false/*includeUplinkPortGroups*/,
                     true/*includeOpaqueNetworkTypes*/);
         self.sortParams = [{field:"name", dir:"asc"}];

         buildDestinationNetworks();

         self.networkSelectorDialogSpec =
               networkSelectorDialogService.createNetworkSelectorDialogSpec(
                     ['Network.Assign'],
                     i18nService.getString(
                           'NetworkUi',
                           'AddNetworkingWizard.conTargetPage.noDvpgPrivilegeMessage')
               );
         self.onSelectNetworks = function(selectedNetworks, rowIndex) {
            self.migrateSelectNetworkService.onSelectNetwork(selectedNetworks[0],
                  self.data[rowIndex], NetworkPageViewModes.BASIC)
            .then(function() {
               buildDestinationNetworks();
            });
         };
      }
   }
}());
