/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace dvs_ui {

   export class DvpgManageWizardPages {

      public static $inject = [
         "i18nService",
         "dvpgManageWizardConstants",
         "simpleValidationCommit",
         "dvpgPolicyTypePageValidator",
         "dvpgSelectPortgroupsPageValidator",
         "dvpgVlanPageValidator",
         "dvpgTrafficShapingPolicyService",
         "dvpgFailoverPolicyPageValidator"
      ];

      constructor(private i18nService: any,
                  private dvpgManageWizardConstants: any,
                  private simpleValidationCommit: any,
                  private policyTypeValidator: DvpgPolicyTypePageValidator,
                  private dvpgSelectPortgroupsPageValidator: DvpgSelectPortgroupsPageValidator,
                  private dvpgVlanPageValidator: DvpgVlanPageValidator,
                  private dvpgTrafficShapingPolicyService: DvpgTrafficShapingPolicyService,
                  private dvpgFailoverPolicyPageValidator: DvpgFailoverPolicyPageValidator) {
      }

      /**
       * Creates an array of page definitions for the Manage DVPG wizard.
       */
      public createPages(wizardScope: DvpgManageWizardScope): WizardPage[] {

         let policyTypePage: WizardPage = {
            id: this.dvpgManageWizardConstants.page.POLICY_TYPE,
            title: this.i18nService.getString("DvsUi", "dvpg.manage.policyType.title"),
            description: this.i18nService.getString("DvsUi",
                  "dvpg.manage.policyType.description"),
            contentUrl: "dvs-ui/resources/dvs/dvpg/manage/views/dvpgPolicyTypePage.html",
            onCommit: this.simpleValidationCommit(() => {
               return this.policyTypeValidator.getValidationError(wizardScope);
            }, wizardScope.wizardConfig),
            validationParams: [
               "model.policies.isSecurityPolicySelected",
               "model.policies.isTrafficShapingPolicySelected",
               "model.policies.isVlanPolicySelected",
               "model.policies.isFailoverPolicySelected",
               "model.policies.isResAllocPolicySelected",
               "model.policies.isTrafficFilterPolicySelected",
               "model.policies.isMonitoringPolicySelected",
               "model.policies.isMiscPolicySelected"]
         };

         let selectPortgroups: WizardPage = {
            id: this.dvpgManageWizardConstants.page.SELECT_PORTGROUPS,
            title: this.i18nService.getString("DvsUi", "dvpg.manage.portGroupList.title"),
            description: this.i18nService.getString("DvsUi",
                  "dvpg.manage.portGroupList.description"),
            contentUrl: "dvs-ui/resources/dvs/dvpg/manage/views/dvpgSelectPortgroupsPage.html",
            onCommit: this.simpleValidationCommit((): any => {
               return this.dvpgSelectPortgroupsPageValidator
                     .getValidationError(wizardScope.model);
            }, wizardScope.wizardConfig),
            validationParams: ["model.selectedPortgroups"]
         };

         let securityPolicyPage: WizardPage = {
            id: this.dvpgManageWizardConstants.page.SECURITY_POLICY,
            title: this.i18nService.getString("DvsUi", "dvpg.manage.policy.security.title"),
            description: this.i18nService.getString("DvsUi",
                  "dvpg.manage.policy.security.description"),
            contentUrl: "dvs-ui/resources/dvs/dvpg/manage/views/dvpgSecurityPolicyPage.html",
            onCommit: this.simpleValidationCommit((): any => {
               return [];
            }, wizardScope.wizardConfig),
            validationParams: [
               "model.securityPolicyModel.allowPromiscuous",
               "model.securityPolicyModel.forgedTransmits",
               "model.securityPolicyModel.macChanges"]
         };

         let vlanPage: WizardPage = {
            id: this.dvpgManageWizardConstants.page.VLAN,
            title: this.i18nService.getString("DvsUi", "dvpg.manage.policy.vlan.title"),
            description: this.i18nService.getString("DvsUi",
                  "dvpg.manage.policy.vlan.description"),
            contentUrl: "dvs-ui/resources/dvs/dvpg/manage/views/dvpgVlanPage.html",
            onCommit: this.simpleValidationCommit((): any => {
               return this.dvpgVlanPageValidator
                     .getValidationError(wizardScope.model.selectedPortgroups[0].id,
                           wizardScope.model.vlanPolicyModel);
            }, wizardScope.wizardConfig),
            validationParams: [
               "model.vlanPolicyModel.vlanType",
               "model.vlanPolicyModel.vlanId",
               "model.vlanPolicyModel.pvlanId",
               "model.vlanPolicyModel.vlanTrunkRanges"]
         };

         let trafficShapingPage: WizardPage = {
            id: this.dvpgManageWizardConstants.page.TRAFFIC_SHAPING,
            title: this.i18nService.getString("DvsUi", "dvpg.manage.policy.shaping.title"),
            description: this.i18nService.getString("DvsUi",
                  "dvpg.manage.policy.shaping.description"),
            contentUrl: "dvs-ui/resources/dvs/dvpg/manage/views/dvpgTrafficShapingPage.html",
            onCommit: this.simpleValidationCommit((): any => {
               return this.dvpgTrafficShapingPolicyService.getErrorMessages(
                     wizardScope.model.trafficShapingPolicyModel.inShapingPolicy,
                     wizardScope.model.trafficShapingPolicyModel.outShapingPolicy);
            }, wizardScope.wizardConfig),
            validationParams: [
               "model.trafficShapingPolicyModel.inShapingPolicy.enabled",
               "model.trafficShapingPolicyModel.inShapingPolicy.averageBandwidth",
               "model.trafficShapingPolicyModel.inShapingPolicy.peakBandwidth",
               "model.trafficShapingPolicyModel.inShapingPolicy.burstSize",
               "model.trafficShapingPolicyModel.outShapingPolicy.enabled",
               "model.trafficShapingPolicyModel.outShapingPolicy.averageBandwidth",
               "model.trafficShapingPolicyModel.outShapingPolicy.peakBandwidth",
               "model.trafficShapingPolicyModel.outShapingPolicy.burstSize"]
         };

         let failoverPage: WizardPage = {
            id: this.dvpgManageWizardConstants.page.FAILOVER_POLICY,
            title: this.i18nService.getString("DvsUi", "dvpg.manage.policy.failover.title"),
            description: this.i18nService.getString("DvsUi",
                  "dvpg.manage.policy.failover.description"),
            contentUrl: "dvs-ui/resources/dvs/dvpg/manage/views/dvpgFailoverPolicyPage.html",
            onCommit: (): any => {
               return this.dvpgFailoverPolicyPageValidator.getValidationError(wizardScope)
                     .then((result: boolean): any => {
                        return this.simpleValidationCommit((): any => {
                           return result;
                        }, wizardScope.wizardConfig).call(this);
                     });
            },
            validationParams: [
               "model.failoverPolicyModel.loadBalancing",
               "model.failoverPolicyModel.beaconProbing",
               "model.failoverPolicyModel.notifySwitches",
               "model.failoverPolicyModel.rollingOrder",
               "model.failoverPolicyModel.failoverOrder.activeUplinkPortNames",
               "model.failoverPolicyModel.failoverOrder.standbyUplinkPortNames",
               "model.failoverPolicyModel.failoverOrder.unusedUplinkPortNames"]
         };

         let resourcePoolAllocationPage: WizardPage = {
            id: this.dvpgManageWizardConstants.page.VM_VNIC_RESOURCE_POOL,
            title: this.i18nService.getString("DvsUi", "dvpg.manage.policy.resourceAllocation.title"),
            description: this.i18nService.getString("DvsUi",
                  "dvpg.manage.policy.resourceAllocation.description"),
            contentUrl: "dvs-ui/resources/dvs/dvpg/manage/views/dvpgResourceAllocationPage.html",
            onCommit: this.simpleValidationCommit((): any => {
               return [];
            }, wizardScope.wizardConfig),
            validationParams: [
               "model.resourceAllocationPolicyModel.networkResourcePoolKey"]
         };

         let monitoringPage: WizardPage = {
            id: this.dvpgManageWizardConstants.page.MONITORING,
            title: this.i18nService.getString("DvsUi", "dvpg.manage.policy.monitoring.title"),
            description: this.i18nService.getString("DvsUi",
                  "dvpg.manage.policy.monitoring.description"),
            contentUrl: "dvs-ui/resources/dvs/dvpg/manage/views/dvpgMonitoringPage.html",
            onCommit: this.simpleValidationCommit((): any => {
               return [];
            }, wizardScope.wizardConfig),
            validationParams: ["model.monitoringPolicyModel.ipfixEnabled"]
         };

         let miscPage: WizardPage = {
            id: this.dvpgManageWizardConstants.page.MISC,
            title: this.i18nService.getString("DvsUi", "dvpg.manage.policy.misc.title"),
            description: this.i18nService.getString("DvsUi",
                  "dvpg.manage.policy.misc.description"),
            contentUrl: "dvs-ui/resources/dvs/dvpg/manage/views/dvpgMiscPage.html",
            onCommit: this.simpleValidationCommit((): any => {
               return [];
            }, wizardScope.wizardConfig),
            validationParams: ["model.miscPolicyModel.arePortsBlocked"]
         };

         let readyToCompletePage: WizardPage = {
            id: this.dvpgManageWizardConstants.page.SUMMARY,
            title: this.i18nService.getString("DvsUi", "dvpg.manage.summary.title"),
            description: this.i18nService.getString("DvsUi",
                  "dvpg.manage.summary.description"),
            contentUrl: "dvs-ui/resources/dvs/dvpg/manage/views/dvpgReadyToCompletePage.html",
            onCommit: this.simpleValidationCommit((): any => {
               return [];
            }, wizardScope.wizardConfig),
            validationParams: []
         };

         return [policyTypePage, selectPortgroups, securityPolicyPage, vlanPage,
            trafficShapingPage, failoverPage, resourcePoolAllocationPage, monitoringPage,
            miscPage, readyToCompletePage];
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .service("dvpgManageWizardPages", DvpgManageWizardPages);
}
