(function() {
   'use strict';
   angular.module('com.vmware.vsphere.client.host').service('moveHostToService',
         MoveHostToService);

   MoveHostToService.$inject = ['i18nService',
      'mutationService',
      'clarityModalService',
      'dataService',
      '$q',
      'defaultUriSchemeUtil'];

   function MoveHostToService(i18nService,
         mutationService,
         clarityModalService,
         dataService,
         $q,
         defaultUriSchemeUtil) {


      function displayMaintenanceModeErrorDialog(clusterName) {
         var modalOptions = {
            message: i18nService.getString('HostUi', 'hostNotInMaintenanceModeError',
                  clusterName),
            title: i18nService.getString('HostUi', 'common.util.error'),
            icon: "vx-icon-warning-32x32",
            hideCancelButton: true,
            preserveNewlines: true,
            submit: function() {
            }
         };
         clarityModalService.openConfirmationModal(modalOptions);
      }

      function showWarningDialog(clusterName, hostMors, entityToMoveHostTo) {
         var modalOptions = {
            message: i18nService.getString('HostUi', 'cluster.moveInto.noDRS.singleHost',
                  clusterName),
            title: i18nService.getString('HostUi', 'common.util.warning'),
            icon: "vx-icon-warning-32x32",
            preserveNewlines: true,
            submit: function() {
               var moveSpec = {
                  entities: hostMors,
                  attributes: null,
                  allowLoosingNestedRps: true
               };
               mutationService.apply(
                     entityToMoveHostTo,
                     'com.vmware.vsphere.client.h5.host.moveHost.MoveHostSpec',
                     moveSpec
               );
            }
         };
         clarityModalService.openConfirmationModal(modalOptions);
      }

      function showMoveVmsAndRpsDialog(hostMor,
            hostId,
            hostName,
            entityToMoveHostTo,
            dpmTurnedOn) {
         var modalOptions = {
            title: i18nService.getString('HostUi',
                  'warning.hostNotConnectedToAllDsClusterDatastores.single.dialogTitle'),
            subTitle: {
               objectId: hostId
            },
            contentTemplate: 'host-ui/resources/host/views/settings/moveto/moveHostVmsAndRpsDialog.html',
            defaultButton: 'submit',
            dialogData: {
               hostMor: hostMor,
               hostId: hostId,
               hostName: hostName,
               dpmTurnedOn: dpmTurnedOn,
               entityToMoveHostTo: entityToMoveHostTo
            }
         };
         clarityModalService.openOkCancelModal(modalOptions);
      }

      function getMoveRpSpec(hostMor, moveToRoot) {
         return {
            entities: [hostMor],
            attributes: null,
            allowLoosingNestedRps: true,
            moveVmsAndRpsConfigSpec: {
               moveEnabled: true,
               moveToRoot: moveToRoot,
               moveToCustomRp: !moveToRoot
            }
         };
      }

      function showRpmWarningCondition(hostId, dpmTurnedOn) {
         var deferred = $q.defer();
         if (dpmTurnedOn) {
            var showDpmWarning = false;
            dataService.getData(
                  hostId,
                  'com.vmware.vsphere.client.h5.host.moveHost.HostConfigInformation').then(function(data) {
               if (data && !data.lastExitSucceeded) {
                  showDpmWarning = true;
               }
               deferred.resolve(showDpmWarning);
            });
         }
         return deferred.promise;
      }

      function moveHostTo(entityToMoveHostTo, hostIds) {

         var hostMors = _.map(hostIds, function(hostId) {
            return defaultUriSchemeUtil.getManagedObjectReference(hostId);
         });

         var moveSpec = {
            entities: hostMors,
            attributes: null,
            allowLoosingNestedRps: false,
            moveVmsAndRpsConfigSpec: {
               moveEnabled: false,
               moveToRoot: false,
               moveToCustomRp: false
            }
         };

         mutationService.apply(
               entityToMoveHostTo,
               'com.vmware.vsphere.client.h5.host.moveHost.MoveHostSpec',
               moveSpec).then(function(operationsResult) {

            if (!operationsResult.error) {
               var allResults = operationsResult.result;

               var hostNotInMaintenanceModeOpResult = _.find(allResults, function(operationResult) {
                  var operationParameter = operationResult.parameter;
                  return operationParameter && operationParameter.displayMaintenanceModeError;
               });

               if (!hostNotInMaintenanceModeOpResult) {
                  _.each(allResults, function(operationResult) {
                     var operationParameter = operationResult.parameter;
                     if (operationParameter && operationParameter.showWarningDialog) {
                        showWarningDialog(operationParameter.clusterName,
                              hostMors,
                              entityToMoveHostTo);
                     } else if (operationParameter && operationParameter.showMovementDialog) {
                        var currentHostMor = operationParameter.hostRef;
                        var hostId = defaultUriSchemeUtil.getVsphereObjectId(currentHostMor);
                        showMoveVmsAndRpsDialog(
                              currentHostMor,
                              hostId,
                              operationParameter.hostName,
                              entityToMoveHostTo,
                              operationParameter.dpmTurnedOn);
                     }
                  });
               } else {
                  displayMaintenanceModeErrorDialog(hostNotInMaintenanceModeOpResult.clusterName);
               }
            }
         });
      }

      return {
         getMoveRpSpec: getMoveRpSpec,
         showRpmWarningCondition: showRpmWarningCondition,
         moveHostTo: moveHostTo
      };
   }
})();

