/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
declare var h5: any;
declare var urn: any;
h5.hostprofile = {
   angularModule: angular.module("com.vmware.vsphere.client.hostprofile",
         ["com.vmware.platform.ui", "com.vmware.vsphere.client.commonModule"])
};

module hostprofile_ui {
   /**
    * Registers hostprofile-ui actions.
    */
   angular.module("com.vmware.vsphere.client.hostprofile")
         .run([
            "hostProfileService",
            "i18nService",
            "clarityModalService",
            "HostProfileComplianceService",
            "mutationService",
            "attachHostProfileService",
            "dataService",
            "clusterHostProfileSettingsService",
            "managedEntityConstants",
            "defaultUriSchemeUtil",
            "hostCustomizationsService",
            "remediateHostWizardService",
            "createHostProfileService",
            "vscImportHostProfileDialogOpenService",
            "vscHpRemediateDialogService",
            "vscEditHostProfileSettingsModalOpenService",
            "vscAttachHostProfileModalOpenService",
            "vscExtractHostProfileFromHostService",
            "vscEditClusterHostCustomizationsService",
            "vscDeleteHostProfileService",
            "vscChangeRefHostService",
            "vscCopyHostProfileToHostProfilesOpenWizardService",
            function (hostProfileService: any,
                  i18nService: any,
                  clarityModalService: any,
                  hostProfileComplianceService: HostProfileComplianceService,
                  mutationService: any,
                  attachHostProfileService: AttachHostProfileService,
                  dataService: any,
                  clusterHostProfileService: ClusterHostProfileSettingsService,
                  managedEntityConstants: any,
                  defaultUriSchemeUtil: any,
                  hostCustomizationsService: any,
                  remediateHostWizardService: any,
                  createHostProfileService: CreateHostProfileService,
                  vscImportHostProfileDialogOpenService: any,
                  vscHpRemediateDialogService: any,
                  vscEditHostProfileSettingsModalOpenService: any,
                  vscAttachHostProfileModalOpenService: any,
                  vscExtractHostProfileFromHostService: any,
                  vscEditClusterHostCustomizationsService: any,
                  vscDeleteHostProfileService: any,
                  vscChangeRefHostService: any,
                  vscCopyHostProfileToHostProfilesOpenWizardService: any) {

                h5.actions["vsphere.core.hostProfile.actions.createProfileFromAHost"] =
                    (actionEval: any, availableTargets: string[]): void => {
                        createHostProfileService.openCreateHostProfileDialog(availableTargets[0]);
                    };

               h5.actions["vsphere.core.hostProfile.actions.attachHostProfile"] =
                     (actionEval: any, availableTargets: string[]): void => {
                         attachHostProfileService.openModal(availableTargets[0], true);
                     };

               h5.actions["vsphere.core.hostProfile.actions.changeHostProfile"] =
                     (actionEval: any, availableTargets: string[]): void => {
                        let typeOfEntity: string = defaultUriSchemeUtil.getEntityType(availableTargets[0]);
                        if (typeOfEntity === managedEntityConstants.CLUSTER) {
                            attachHostProfileService.openModal(availableTargets[0], false);
                        } else {
                           hostProfileService.changeHostProfile(availableTargets[0]);
                        }
                     };

               h5.actions["vsphere.core.hostProfile.actions.cluster.changeHostProfile"] =
                     (actionEval: any, availableTargets: string[]): void => {
                         attachHostProfileService.openModal(availableTargets[0], false);
                     };

               h5.actions["vsphere.core.hostProfile.actions.detachHostProfile"] =
                     (actionEval: any, availableTargets: string[]): void => {
                        hostProfileService.detachHostProfile(availableTargets[0]);
                     };

               h5.actions["vsphere.core.hostProfile.actions.editHostCustomizations"] =
                     (actionEval: any, availableTargets: string[]): void => {
                        let modalOptions: any = {
                           title: i18nService.getString("HostProfileUi",
                                 "editHostCustomizationsWizard.title"),
                           subTitle: {
                              objectId: availableTargets[0]
                           },
                           dialogData: {
                              availableTargets: availableTargets
                           },
                           defaultButton: "submit",
                           contentTemplate: "hostprofile-ui/resources/hostprofile/customizations/editHostCustomizations.html",
                           size: "xl"
                        };
                        clarityModalService.openOkCancelModal(modalOptions);
                     };

               h5.actions["vsphere.core.hostProfile.actions.cluster.editHostCustomizations"] =
                     (actionEval: any, availableTargets: string[]): void => {
                        vscEditClusterHostCustomizationsService.openWizard(availableTargets[0]);
                     };

               h5.actions["vsphere.core.hostProfile.actions.hostProfile.editHostCustomizations"] =
                     (actionEval: any, availableTargets: string[]): void => {
                        vscEditClusterHostCustomizationsService.openWizard(availableTargets[0]);
                     };

               h5.actions["vsphere.core.hostProfile.actions.hostProfile.editHostProfileAction"] =
                     (actionEval: any, availableTargets: string[]): void => {
                        dataService
                              .getProperties(availableTargets[0], ["validationState"])
                              .then((data: any) => {
                                 let context: any = {
                                    selectedItemPath: null,
                                    editType: "original"
                                 };

                                 if (data.validationState === 'Failed') {
                                    context.editType = "failed";
                                 }

                                 vscEditHostProfileSettingsModalOpenService.open(
                                       availableTargets[0], context);
                              });
                     };

               h5.actions["vsphere.core.hostProfile.actions.cluster.checkHostProfileCompliance"] =
                     (actionEval: any, availableTargets: string[], context: any): void => {
                        const hostProfileId: string = context && context.hostProfileId ? context.hostProfileId : null;
                        mutationService.apply(
                                 availableTargets[0],
                                 "com.vmware.vsphere.client.hostprofile.cluster.h5.ClusterHostProfileComplianceCheckSpec",
                                 {hostProfile: hostProfileId});
                     };

               h5.actions["vsphere.core.hostProfile.actions.hostProfile.checkHostProfileCompliance"] =
                     (actionEval: any, availableTargets: string[], context: any): void => {
                        mutationService.apply(
                              availableTargets[0],
                              "com.vmware.vsphere.client.hostprofile.data.h5.HostProfileCheckComplianceSpec",
                              {});

                     };

               h5.actions["vsphere.core.hostProfile.actions.checkHostProfileCompliance"] =
                     (actionEval: any, availableTargets: string[], context: any) => {
                        let hostProfileId: any = context && context.hostProfileId ? context.hostProfileId : null;
                        hostProfileComplianceService.checkCompliance(availableTargets, hostProfileId);
                     };

               h5.actions["vsphere.core.hostProfile.actions.cluster.detachHostProfile"] =
                     (actionEval: any, availableTargets: string[]) => {
                        clusterHostProfileService.detachHostProfileFromCluster(availableTargets[0]);
                     };

               h5.actions["vsphere.core.hostProfile.actions.resetHostCustomizations"] =
                     (actionEval: any, availableTargets: string[]): void => {
                        hostCustomizationsService.resetHostCustomizations(availableTargets[0]);
                     };

               h5.actions["vsphere.core.hostProfile.actions.remediateHost"] =
                     (actionEval: any, availableTargets: string[]): void => {
                        // if a single host is invoking the action
                        // than open single host remediation dialog
                        vscHpRemediateDialogService.openSingleHostRemediateDialog(availableTargets[0]);
                     };

               h5.actions["vsphere.core.hostProfile.actions.cluster.remediateMultipleHosts"] =
                     (actionEval: any, availableTargets: string[]): void => {
                        // if a cluster/host profile invoking the action
                        // than open multiple host remediation dialog
                        vscHpRemediateDialogService.openMultipleHostRemediateDialog(availableTargets[0]);
                     };
               h5.actions["vsphere.core.hostProfile.actions.hostProfile.remediateMultipleHosts"] =
                     (actionEval: any, availableTargets: string[]): void => {
                        // if a cluster/host profile invoking the action
                        // than open multiple host remediation dialog
                        vscHpRemediateDialogService.openMultipleHostRemediateDialog(availableTargets[0]);
                     };

               h5.actions["vsphere.core.hostProfile.actions.duplicateProfile"] =
                    (actionEval: any, availableTargets: string[]): void => {
                        createHostProfileService.openCreateHostProfileDialog(availableTargets[0], false);
                    };

                h5.actions["vsphere.core.hostProfile.actions.exportProfile"] =
                    (actionEval: any, availableTargets: string[]): void => {
                        hostProfileService.openExportHostProfileDialog(availableTargets[0]);
                    };

               h5.actions["vsphere.core.hostprofile.attachAction"] =
                     (actionEval: any, availableTargets: string[]): void => {
                        vscAttachHostProfileModalOpenService.open(availableTargets[0]);
                     };

                h5.actions["vsphere.core.hostProfile.actions.importProfile"] =
                    (actionEval: any, availableTargets: string[]): void => {
                        vscImportHostProfileDialogOpenService.open();
                    };

                h5.actions["vsphere.core.hostProfile.actions.exportHostCustomizations"] =
                    (actionEval: any, availableTargets: string[]): void => {
                        hostProfileService.openExportHostCustomizationsDialog(availableTargets[0]);
                    };

                h5.actions["vsphere.core.hostProfile.actions.editHostProfile"] =
                    (actionEval: any, availableTargets: string[], context: any): void => {
                        vscEditHostProfileSettingsModalOpenService.open(availableTargets[0], context);
                    };

                h5.actions["vsphere.core.hostProfile.actions.extractHostProfileFromAHost"] =
                      (actionEval: any, availableTargets: string[], context: any): void => {
                         vscExtractHostProfileFromHostService.openWizard(availableTargets[0]);
                      };

               h5.actions["vsphere.core.hostProfile.actions.deleteProfile"] =
                     (actionEval: any, availableTargets: string[], context: any): void => {
                        vscDeleteHostProfileService.openDeleteProfileConfirmationModal(availableTargets[0]);
                     };
               h5.actions["vsphere.core.hostProfile.actions.changeRefHostAction"] =
                     (actionEval: any, availableTargets: string[], context: any): void => {
                        vscChangeRefHostService.openChangeRefDialog(availableTargets[0]);
                     };

               h5.actions["vsphere.core.hostProfile.actions.copySettingsToHostProfiles"] =
                     (actionEval: any, availableTargets: string[], context: any): void => {
                        vscCopyHostProfileToHostProfilesOpenWizardService.open(availableTargets[0]);
                     };
               h5.actions["vsphere.core.hostProfile.actions.copySettingsFromHostProfile"] =
                     (actionEval: any, availableTargets: string[], context: any): void => {
                        vscCopyHostProfileToHostProfilesOpenWizardService.openFromToWizard(availableTargets[0]);
                     };
            }]);
}
