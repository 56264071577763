/* Copyright 2019 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace h5_vm {
   import IPromise = angular.IPromise;
   import VmProvisioningPageModel = h5_vm.VmProvisioningPageModel;

   export interface VmIpPoolData {
      networks: Array<{ id: string, name: string }>;
      ipPoolData: any;
      ipProtocol: string;
   }

   class VmProvisioningVappPropertiesPageModelFactory {
      public static $inject = [
         "i18nService",
         "dataService"
      ];

      constructor(
            i18nService: any,
            dataService: any
      ) {
         return (virtualMachineSpecBuilder: any) => {
            return new VmProvisioningVappPropertiesPageModel(
                  i18nService,
                  dataService,
                  virtualMachineSpecBuilder);
         };
      }
   }

   export class VmProvisioningVappPropertiesPageModel implements VmProvisioningPageModel<void> {

      static readonly $inject: string[] = [
         "i18nService",
         "dataService",
      ];

      private readonly NETWORK_PROPERTY: string = "network";
      private readonly IP_POOL_DATA_PROPERTY: string = "ipPoolData";
      private readonly IP_ALLOCATION_PROTOCOL_PROPERTY: string = "config.vAppConfig.ipAssignment.ipProtocol";

      constructor(
            private i18nService: any,
            private dataService: any,
            private virtualMachineSpecBuilder: any,
      ) {
      }

      private _vappPropertyDescriptors: any;

      resetForm() {
      }

      getVappPropertyDescriptors() {
         this._vappPropertyDescriptors = this.virtualMachineSpecBuilder.getVappPropertyDescriptors();
         this._vappPropertyDescriptors = _.filter(this._vappPropertyDescriptors, (property: any) => {
            return property.userConfigurable && property.type !== "expression";
         });
         return this._vappPropertyDescriptors;
      }

      retrieveIpPoolsData(): IPromise<VmIpPoolData> {
         const vmId: string = this.virtualMachineSpecBuilder.getVmId();
         return this.dataService
               .getProperties(vmId, [this.NETWORK_PROPERTY,
                  this.IP_POOL_DATA_PROPERTY, this.IP_ALLOCATION_PROTOCOL_PROPERTY])
               .then((result: any) => {
                  return {
                     networks: result[this.NETWORK_PROPERTY],
                     ipPoolData: result[this.IP_POOL_DATA_PROPERTY],
                     ipProtocol: result[this.IP_ALLOCATION_PROTOCOL_PROPERTY]
                  } as VmIpPoolData;
               });
      }

      validatePage(): any {
         let isValid: boolean = true;

         this._vappPropertyDescriptors.forEach((property: any) => {
            if (!property.isValid) {
               isValid = false;
               return;
            }
         });

         if (!isValid) {
            return {
               error: this.i18nService.getString("VmUi", "VAppPropertiesProvisioningPage.errorMessage")
            };
         }

         return {};
      }

      submitPage(): any {
         return {
            wizardFlow: this.virtualMachineSpecBuilder.getCreationType(),
            invalidateNextPages: false
         };
      }
   }

   angular.module("com.vmware.vsphere.client.vm")
         .service("vmProvisioningVappPropertiesPageModel",
               VmProvisioningVappPropertiesPageModelFactory);
}
