module ds_cluster_ui {

   import IRootScopeService = angular.IRootScopeService;
   import ScheduleSdrsWizardManager = ds_cluster_ui.ScheduleSdrsWizardManager;

   export class ScheduleSdrsWizardService {

      public static $inject = ['$rootScope', 'i18nService', 'vuiWizardService',
         'scheduleSdrsWizardManager', 'pageStateManagementService', 'sdrsDialogManager'];

      constructor(private $rootScope: IRootScopeService,
                  private i18nService: any, private vuiWizardService: any,
                  private wizardManager: ScheduleSdrsWizardManager,
                  private pageStateManagementService: any,
                  private sdrsDialogManager: any) { }

      /**
       * Displays schedule sdrs wizard.
       */
      public show(objectId: string, focusTarget: any): void {
         let wizardScope: any = this.$rootScope.$new();

         this.wizardManager.init(objectId);
         wizardScope.wizardConfig = this.wizardManager.getWizardConfig();
         wizardScope.manager = this.wizardManager;
         wizardScope.modalOptions = {
            focusTarget: focusTarget,
            dialogData: {
               manager: this.sdrsDialogManager,
               objectId: objectId
            }
         };
         this.pageStateManagementService.registerWizardForDefaultStateManagementStrategy(
               wizardScope);

         this.vuiWizardService({
            scope: wizardScope,
            configObjectName: 'wizardConfig'
         }).show();
      }
   }

   angular.module('com.vmware.vsphere.client.dsCluster')
         .service('scheduleSdrsWizardService', ScheduleSdrsWizardService);
}
