namespace h5_vm {
   import IPromise = angular.IPromise;
   import ManagedObjectReference = com.vmware.vim.binding.vmodl.ManagedObjectReference;
   export class VmMigrateStorageLocatorService {
      public static $inject = ["dataService", "storageLocatorService", "defaultUriSchemeUtil"];
      public static PROPERTY_STORAGE_LOCATOR_ITEMS_DATA: string =
            "storageLocator:migrationStorageLocatorItemsData";

      constructor(private dataService: any,
                  private storageLocatorService: any,
                  private defaultUriSchemeUtil: any) {

      }

      public getStorageLocatorData(
            migrationOptions: {vms: Array<string>, resource?: string}): IPromise<any> {
         return this.dataService.getProperties(
               migrationOptions.vms[0],
               VmMigrateStorageLocatorService.PROPERTY_STORAGE_LOCATOR_ITEMS_DATA, {
                  propertyParams: [{
                     propertyName:
                           VmMigrateStorageLocatorService.PROPERTY_STORAGE_LOCATOR_ITEMS_DATA,
                     parameterType:
                           "com.vmware.vsphere.client.h5.vm.model.migration.VmMigrationStorageLocatorSpec",
                     parameter: {
                        vms: this.defaultUriSchemeUtil
                              .getManagedObjectReferences(migrationOptions.vms),
                        resource: migrationOptions.resource ?
                              this.defaultUriSchemeUtil
                                 .getManagedObjectReference(migrationOptions.resource) :
                              undefined
                     }
                  }]
               })
               .then(function(response: {[property: string]: any}) {
                  return response ?
                        response[VmMigrateStorageLocatorService.PROPERTY_STORAGE_LOCATOR_ITEMS_DATA] :
                        null;
               });
      }

      public getStorageObjectNameMap(storageLocatorItemsData: any): any {
         return this.storageLocatorService
               .getStorageObjectNameMap(storageLocatorItemsData);
      }
   }

   angular.module("com.vmware.vsphere.client.vm")
         .service("vmMigrateStorageLocatorService", VmMigrateStorageLocatorService);
}
