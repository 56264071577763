namespace h5_vm {
   import VirtualUSB$USBBackingInfo = com.vmware.vim.binding.vim.vm.device.VirtualUSB$USBBackingInfo;
   import VirtualUSB = com.vmware.vim.binding.vim.vm.device.VirtualUSB;
   import VirtualUSBOption = com.vmware.vim.binding.vim.vm.device.VirtualUSBOption;
   import DeviceTypeConstants = h5_vm.DeviceTypeConstants;
   import UsbInfo = com.vmware.vim.binding.vim.vm.UsbInfo;

   export class UsbDeviceService {
      public static $inject: string[] = ['deviceService', 'i18nService', 'EditableVirtualDevice', 'VirtualUSBClass'];

      private static readonly USB_TYPE = new VirtualUSB()._type;

      constructor(private deviceService: any, private i18nService: any, private EditableVirtualDevice: any, private VirtualUSBClass: any) {
      }

      public makeUSBDevice(deviceOptions: any[], hostUsbList: UsbInfo[], inflatedDevices: any[]): {device: VirtualUSBClass} {

         if (!hostUsbList || hostUsbList.length < 1) {
            throw this.newErrorNoUSBDevices();
         }

         let rawDevice: VirtualUSB = new VirtualUSB();

         let usbOption: VirtualUSBOption = _.find(deviceOptions, function (item: any) {
            return item.type.name === UsbDeviceService.USB_TYPE;
         });

         if (!usbOption) {
            throw this.newErrorDeviceNotSupported();
         }

         rawDevice.key = this.deviceService.newKey();

         let info = this.getNextAvailableUsb(hostUsbList, inflatedDevices);

         if (this.countUSBDevices(inflatedDevices) >= this.getMaxUSBDevices(deviceOptions)) {
            throw this.newErrorDeviceLimitReached();
         }

         if (!info) {
            throw this.newErrorNoUSBDevices();
         }

         let backing: VirtualUSB$USBBackingInfo = new VirtualUSB$USBBackingInfo();
         backing.deviceName = info.physicalPath;
         rawDevice.backing = backing;
         let device = new this.VirtualUSBClass(rawDevice, info);

         this.deviceService.setDeviceInfo(device);

         return {
            device: device
         };
      }

      private countUSBDevices(inflatedDevices: any[]): number {
         let usbDeviceCount = 0;

         // marked for removal devices are skipped over, therefore replacement
         // will still be allowed if max is already reached
         inflatedDevices.forEach(function(device: any) {
            if (device.isOfType(UsbDeviceService.USB_TYPE)) {
               usbDeviceCount++;
            }
         });

         return usbDeviceCount;
      }

      private getMaxUSBDevices(deviceOptions: any[]): number {
         let virtualUSBController: any;

         deviceOptions.forEach(function(option: any) {
            if (option.type && option.type.wsdlName === DeviceTypeConstants.VIRTUAL_USB_CONTROLLER) {
               virtualUSBController = option;
            }
         });

         return (virtualUSBController && virtualUSBController.devices) ? virtualUSBController.devices.max : 20;
      }

      private getNextAvailableUsb(array: Array<UsbInfo>, inflatedDevices: any[]): UsbInfo|null {
         if (!array || array.length === 0) {
            return null;
         }
         const usbDeviceNames: string[] = inflatedDevices
               .filter((device) => device.isOfType(UsbDeviceService.USB_TYPE))
               .map((device) => device.getCurrentDevice().backing.deviceName);

         const takenUsbPaths: {[path: string]: true} = {};
         for (const deviceName of usbDeviceNames) {
            takenUsbPaths[deviceName] = true;
         }

         for(let item of array) {
            if (!takenUsbPaths[item.physicalPath]) {
               return item;
            }
         }

         return null;
      }

      private newErrorDeviceLimitReached(): any {
         return new Error(this.i18nService.getString('VmUi', 'VmDeviceManager.DeviceLimitReached'));
      }

      private newErrorDeviceNotSupported(): any {
         return new Error(this.i18nService.getString('VmUi', 'VmDeviceManager.DeviceNotSupported'));
      }

      private newErrorNoUSBDevices(): any {
         return new Error(this.i18nService.getString('VmUi', 'VmDeviceManager.NoUSBDevices'));
      }

   }
   angular.module('com.vmware.vsphere.client.vm').service('usbDeviceService', UsbDeviceService);
}
