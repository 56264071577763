/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace dvs_ui {

   export class DvpgSelectPortgroupsPageValidator {

      public static $inject = ["i18nService",
         "dvpgManageWizardConstants",
         "authorizationService",
         "$q"];

      private dvpgEditPrivilege: string;

      constructor(private i18nService: any,
                  private dvpgManageWizardConstants: any,
                  private authorizationService: any,
                  private $q: any) {
         this.dvpgEditPrivilege =
               this.dvpgManageWizardConstants.privileges.DVPORTGROUP_MODIFY;
      }

      public getValidationError(model: DvpgManageWizardModel): any {
         let errors: string[] = [];

         if (!model.selectedPortgroups || model.selectedPortgroups.length === 0) {
            errors.push(this.i18nService.getString(
                  "DvsUi", "dvpg.manage.portGroupList.noPortGroupsSelected"));
            return this.$q.when(errors);
         } else {
            return this.getNoPrivilegesValidationError(model);
         }
      }

      private getNoPrivilegesValidationError(model: DvpgManageWizardModel): any {
         let dvPortgroupIds: string[] = _.map(model.selectedPortgroups, (pg) => {
            return pg.id;
         });

         return this.authorizationService
               .checkPrivilegesForMultipleObjects(dvPortgroupIds, [this.dvpgEditPrivilege])
               .then((result: any) => {
                  return this.buildNoPrivilegesValidationMessages(
                        result, model.selectedPortgroups);
               });
      }

      private buildNoPrivilegesValidationMessages(privilegesById: any,
                                                  selectedPortgroups: any[]): string[] {
         let validationMessages: string[] = [];
         // Collect the portgroups that do not have edit privilege granted.
         Object.keys(privilegesById).forEach((id: string) => {
            if (privilegesById[id][this.dvpgEditPrivilege] === false) {
               let portgroupName: string | null =
                     this.getPortgroupName(id, selectedPortgroups);
               if (portgroupName) {
                  validationMessages.push(this.i18nService.getString("DvsUi",
                        "dvpg.manage.portGroupList.noPrivilegesFormat", portgroupName));
               }
            }
         });

         return validationMessages;
      }

      private getPortgroupName(id: string, portgroups: any[]): string | null {
         for (let i = 0; i < portgroups.length; i++) {
            if (id === portgroups[i].id) {
               return portgroups[i].name;
            }
         }
         return null;
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .service("dvpgSelectPortgroupsPageValidator", DvpgSelectPortgroupsPageValidator);
}