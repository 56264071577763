(function() {
   'use strict';

   angular.module('com.vmware.vsphere.client.vm').service('snapshotTreeService', snapshotTreeService);

   snapshotTreeService.$inject = ['timeFormatterService', 'i18nService'];

   const POWERSTATE = h5_vm.PowerState;
   var icons = {
      vm: 'vsphere-icon-vm',
      snapshotPoweredOff: 'vsphere-icon-snapshot',
      snapshotPoweredOn: 'vsphere-icon-snapshot_run',
      snapshotSuspended: 'vsphere-icon-snapshot_suspend',
      youAreHere: 'vsphere-icon-snapshot_here'
   };
   var timeFormatter;
   var currentSnapshot;
   var i18n;

   function snapshotTreeService(timeFormatterService, i18nService) {
      timeFormatter = timeFormatterService;
      i18n = i18nService;
      return {
         formatData: formatData,
         getCurrentSnapshot: getCurrentSnapshot,
         icons: icons
      };
   }

   function formatData(snapshotData) {
      var rootVMNode = buildRootVMNode(snapshotData);

      if (snapshotData.snapshot && snapshotData.snapshot.rootSnapshotList) {
         rootVMNode.items = buildChildSnapshotTreeItems(snapshotData.snapshot.rootSnapshotList, snapshotData);
      } else {
         currentSnapshot = _.pick(rootVMNode, ['label', 'metadata', 'iconClass']);
         rootVMNode.selected = true;
         rootVMNode.items = [buildYouAreHereTreeItem(snapshotData)];
      }

      return { data: [rootVMNode] };
   }

   function getCurrentSnapshot() {
      return currentSnapshot;
   }

   function buildRootVMNode(snapshotData) {
      var optionalProperties = {
         diskUsage: snapshotData.diskUsage.totalDiskUsageInBytes
      };
      return buildTreeItem(snapshotData.name, icons.vm, optionalProperties);
   }

   function buildYouAreHereTreeItem(snapshotData) {
      var optionalProperties = {
         diskUsage: snapshotData.diskUsage.currentDiskUsageInBytes
      };
      return buildTreeItem(i18n.getString('vmuiSnapshot', 'SnapshotManagerView.YouAreHere'), icons.youAreHere, optionalProperties);
   }

   function buildTreeItem(label, icon, optionalProperties) {
      var treeItem = {
         label: label,
         iconClass: icon,
         items: [],
         expanded: true
      };

      if (optionalProperties) {
         treeItem.metadata = optionalProperties;
      }

      return treeItem;
   }

   function getUsageDataForSnapshot(childSnapshot, snapshotDiskUsageArray) {
      for (var i = 0; i < snapshotDiskUsageArray.length; i++) {
         if (childSnapshot.snapshot.value === snapshotDiskUsageArray[i].snapshotId) {
            return snapshotDiskUsageArray[i].diskUsageInBytes;
         }
      }
   }

   function childTreeItem(rootSnapshotList, snapshotData) {
      var childItem = buildSnapshotTreeItem(rootSnapshotList, snapshotData);
      var currentSnapshotObject = snapshotData.snapshot.currentSnapshot;

      if (rootSnapshotList.childSnapshotList !== null) {
         childItem.items = buildChildSnapshotTreeItems(rootSnapshotList.childSnapshotList, snapshotData);
      }

      if (currentSnapshotObject && rootSnapshotList.snapshot.value === currentSnapshotObject.value) {
         currentSnapshot = _.pick(childItem, ['label', 'metadata']);
         childItem.selected = true;
         childItem.items.unshift(buildYouAreHereTreeItem(snapshotData));
      }

      return childItem;
   }

   function buildSnapshotTreeItem(rootSnapShotList, snapshotData) {
      var optionalProperties = {
         'description': rootSnapShotList.description,
         'createTime': rootSnapShotList.createTime,
         'snapshotMOR': rootSnapShotList.snapshot,
         'isMemoryIncluded': getMemorySnapshot(rootSnapShotList.state),
         'quiesced': rootSnapShotList.quiesced ? i18n.getString('vmuiSnapshot', 'SnapshotManagerView.Option.Yes')
               : i18n.getString('vmuiSnapshot', 'SnapshotManagerView.Option.No'),
         'diskUsage': getUsageDataForSnapshot(rootSnapShotList, snapshotData.diskUsage.snapshotDiskUsage)
      };
      timeFormatter.formatDate(rootSnapShotList.createTime).then(function(formattedTime) {
         optionalProperties.createTime = formattedTime;
      });
      return buildTreeItem(rootSnapShotList.name, getSnapshotIcon(rootSnapShotList.state), optionalProperties);
   }

   function buildChildSnapshotTreeItems(rootSnapshotList, snapshotData) {
      return _.map(rootSnapshotList, function(rootSnapshotList) {
         return childTreeItem(rootSnapshotList, snapshotData);
      });
   }

   function getMemorySnapshot(state) {
      switch (state) {
         case POWERSTATE.SUSPENDED:
         case POWERSTATE.POWERED_ON:
            return i18n.getString('vmuiSnapshot', 'SnapshotManagerView.Option.Yes');
         case POWERSTATE.POWERED_OFF:
            return i18n.getString('vmuiSnapshot', 'SnapshotManagerView.Option.No');
         default:
            return i18n.getString('vmuiSnapshot', 'SnapshotManagerView.Option.No');
      }
   }

   function getSnapshotIcon(state) {
      switch (state) {
         case POWERSTATE.SUSPENDED: return icons.snapshotSuspended;
         case POWERSTATE.POWERED_ON:
            return icons.snapshotPoweredOn;
         case POWERSTATE.POWERED_OFF:
            return icons.snapshotPoweredOff;
         default:
            return icons.snapshotPoweredOff;
      }
   }

})();
