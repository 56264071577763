angular.module('com.vmware.vsphere.client.vm').directive('vmHardwareSummaryCpu', ['i18nService', function (i18nService) {
   return {
      templateUrl: 'vm-ui/resources/vm/views/summary/vmHardwareSummaryCpu.html',
      scope: {
         ctrl: '='
      },
      link: function(scope) {
         scope.i18n = i18nService.getString;
         scope.fieldLabel = function (key) {
            return i18nService.getString('VmUi', key);
         };
         scope.isTemplate = scope.ctrl.hardwareViewData.memoryData.isVmTemplate;
         scope.$watch('ctrl.hardwareViewData.cpuAllocation', function(cpuAllocation) {
            if (!cpuAllocation) {
               return;
            }

            setFieldValues(scope);
            scope.fields = _.keys(scope.fieldValue);
         });

         function formatLimitForDisplay(limit) {
            if (limit < 0) {
               return scope.i18n('VmUi', 'labelUnlimited');
            } else {
               return scope.i18n('VmUi', 'cpuReservationLimit', limit);
            }
         }

         function formatEnabledDisabled(boolean) {
            if (boolean) {
               return scope.i18n('VmUi', 'VmCpuView.Enabled');
            } else {
               return scope.i18n('VmUi', 'VmCpuView.Disabled');
            }
         }

         function setFieldValues(scope) {
            var data = scope.ctrl.hardwareViewData;
            var utilization = scope.i18n('VmUi', 'numCpuUtilization', data.hardware.numCPU,
               data.cpuUtilization);
            var configured = scope.i18n('VmUi', 'numCpu', data.hardware.numCPU);
            var cpuHotPlug = scope.i18n('VmUi', 'VmCpu.Label.CpuHotPlug');
            var reservation = scope.i18n('VmUi', 'cpuReservationLimit', data.cpuAllocation.reservation);
            var limit = formatLimitForDisplay(data.cpuAllocation.limit);
            var shares = scope.ctrl.isVmTemplateCLitem ? data.cpuAllocation.shares :
                  scope.i18n('VmUi', 'sharesAndType', data.cpuAllocation.shares.shares,
                  scope.i18n('VmUi', 'sharesInfoLevel.' + data.cpuAllocation.shares.level));

            scope.fieldValue = {
               ConfiguredCPU: configured,
               Utilization: utilization,
               Reservation: reservation,
               Limit: limit,
               NestedHV: formatEnabledDisabled(data.hardwareVirtualization),
               IommuHV: formatEnabledDisabled(data.vvtdEnabled),
               PerfCounters: formatEnabledDisabled(data.performanceCounters),
               CpuHotPlug: cpuHotPlug,
               Shares: shares
            };
         }
      }
   };
}]);