/* Copyright 2018 VMware, Inc. All rights reserved. -- VMware Confidential */
(function() {
   'use strict';

   /**
    * Wrapper component designed specifically to upgrade network-selector directive and
    * allow network selector usage in angular.next components.
    * This component is not intended to be used by angular 1.5 components. For this
    * purpose the network-selector directive should continue to be used.
    */
   var upgradableNetworkSelector = {
      bindings: {
         networkSelectorName: '<?', // Optional name parameter.
         // Could be used when using the network selector in a form.
         objectRef: '=', // The context object of the network selector
         mode: '=', // The network selector display mode
         filterSpec: '=', // A spec for filtering the requested networks
         dialogSpec: '=?', // A spec for specifying network's privileges and errors
         preselectedNetwork: '=?', // The pre-selected network if any
         onSelectedNetworks: '&onSelectedNetworks', // A callback function for retrieving the selected networks
         prefetchedNetworks: '=?', //Array with all the prefetched networks
         errorConfig: '<?',  // vxError object used to display error associated with the network selector
         sortParams: '<?', // parameters for sorting the grid of the network selector dialog (ex: [{field:"name", dir:"asc"}] )
         isDisabled: '<?' // disable the selector
      },
      controller: function() {
      },
      template: '<div network-selector ' +
      '               network-selector-name="$ctrl.networkSelectorName"\n' +
      '               object-ref="$ctrl.objectRef"\n' +
      '               mode="$ctrl.mode"\n' +
      '               filter-spec="$ctrl.filterSpec"\n' +
      '               dialog-spec="$ctrl.dialogSpec"\n' +
      '               preselected-network="$ctrl.preselectedNetwork"\n' +
      '               on-selected-networks="$ctrl.onSelectedNetworks(selectedNetworks)"\n' +
      '               prefetched-networks="$ctrl.prefetchedNetworks"\n' +
      '               error-config="$ctrl.errorConfig"\n' +
      '               sort-params="$ctrl.sortParams"\n' +
      '               is-disabled="$ctrl.isDisabled">'
   };

   angular.module('com.vmware.vsphere.client.networkLibUi')
         .component("upgradableNetworkSelector", upgradableNetworkSelector);

})();
