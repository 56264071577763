/**
 * @name com.vmware.vsphere.client.cluster:clusterVmOverridesConstants
 * @module com.vmware.vsphere.client.cluster
 *
 * @description
 *    The `clusterVmOverridesConstants` service holds VM overrides related constants.
 */
module cluster_ui {

   angular.module("com.vmware.vsphere.client.cluster")
         .constant("clusterVmOverridesConstants", {
      overrideSpec: {
         DAS_VM_CONFIG_SPEC: "com.vmware.vim.binding.vim.cluster.DasVmConfigSpec",
         DRS_VM_CONFIG_SPEC: "com.vmware.vim.binding.vim.cluster.DrsVmConfigSpec",
         ORCH_VM_CONFIG_SPEC: "com.vmware.vim.binding.vim.cluster.VmOrchestrationSpec",
         ORCH_VM_INFO_SPEC: "com.vmware.vim.binding.vim.cluster.VmOrchestrationInfo",
         ORCH_VM_READINESS_SPEC: "com.vmware.vim.binding.vim.cluster.VmReadiness"
      },
      operationType: {
         ADD: "add",
         EDIT: "edit",
         REMOVE: "remove"
      },
      CLUSTER_DEFAULT: "clusterDefault",
      RESTART_PRIORITY_CLUSTER_DEFAULT: "clusterRestartPriority",
      ISOLATION_RESPONSE_CLUSTER_DEFAULT: "clusterIsolationResponse",
      RESPONSE_RECOVERY_CLUSTER_DEFAULT: "useClusterDefault",
      OVERRIDE_NA: "VmOverrideNA_S",
      INITIAL_VALUES: "useInitialValues",
      APD_DELAY_CLUSTER_DEFAULT: -1
   });
}
