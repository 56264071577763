(function() {
   'use strict';
   angular.module('com.vmware.vsphere.client.host').service('hardwareProcessorsService',
         HardwareProcessorsService);

   HardwareProcessorsService.$inject = ['i18nService'];

   function HardwareProcessorsService(i18nService) {

      /**
       * Property value format function - in some cases the property value is not a string and it requires some custom
       * formatting.
       * @param propName
       *    Name of the property.
       * @param contentValue
       *    Raw value of the property - might be a string or an object.
       */
      function formatPropertyValue(propName, contentValue) {
         if (propName === 'hyperthreading') {
            return formatHyperthreadingValue(contentValue);
         }

         // for all the properties that there is no need for custom formatting - just return the raw content value
         return contentValue;
      }


      function getGeneralSettingsOptions(hostId) {

         var headerOptions = getHeaderOptions('processorConfig.generalConfiguration', hostId);
         headerOptions.actions.push({
            actionUid: 'vsphere.core.host.actions.editProcessorGeneralConfig',
            customLabel: i18nService.getString('HostUi',
                  'processorConfig.editGeneralConfiguration')
         });

         return {
            headerOptions: headerOptions,
            settingsViewOptions: getSettingsViewOptions('processorGeneralConfig', hostId)
         };
      }

      function getSystemSettingsOptions(hostId) {

         var headerOptions = getHeaderOptions('processorConfig.systemConfiguration', hostId);
         headerOptions.actions.push({
            actionUid: 'vsphere.core.host.actions.editBootOptions',
            customLabel: i18nService.getString('HostUi',
                  'processorConfig.bootOptions')
         });

         return {
            headerOptions: headerOptions,
            settingsViewOptions: getSettingsViewOptions('processorSystemConfig', hostId)
         };
      }

      function formatHyperthreadingValue(contentValue) {
         if (!contentValue) {
            return null;
         }

         // is hyperthreading available
         var hyperAvailable = contentValue.htAvailable;
         if (!hyperAvailable) {
            return i18nService.getString('HostUi', 'notAvailable');
         }

         // current hyperthreading value
         var hyper = contentValue.ht;
         // value on restart (of the host)
         var hyperOnRestart = contentValue.htOnRestart;

         var enabledLabel = i18nService.getString('HostUi', 'enabled');
         var disabledLabel = i18nService.getString('HostUi', 'disabled');
         if (hyper === hyperOnRestart) {
            return hyper ? enabledLabel : disabledLabel;
         }

         var onRestartLabel = hyperOnRestart ?
               i18nService.getString('HostUi', 'onRestartFormat', enabledLabel) :
               i18nService.getString('HostUi', 'onRestartFormat', disabledLabel);

         return hyper ?
               i18nService.getString('HostUi', 'processorConfig.hyperThreadingFormat', enabledLabel, onRestartLabel) :
               i18nService.getString('HostUi', 'processorConfig.hyperThreadingFormat', disabledLabel, onRestartLabel);
      }

      function formatCertificateStatusValue(statusKey) {
         var nonNullStatusKey = statusKey || 'unknown';
         return i18nService.getString('HostUi', 'certificate.status.' + nonNullStatusKey);
      }

      function getHeaderOptions(titleProperty, hostId) {
         return {
            title: i18nService.getString(
                  'HostUi',
                  titleProperty
            ),
            actions: [],
            objectIds: [hostId]
         };
      }

      function getSettingsViewOptions(propertyName, hostId) {
         return {
            objectId: hostId,
            propertyName: propertyName,
            formatValueCallback: formatPropertyValue,
            type: 'stack'
         };
      }

      return {
         formatPropertyValue :formatPropertyValue,
         getGeneralSettingsOptions : getGeneralSettingsOptions,
         getSystemSettingsOptions: getSystemSettingsOptions
      };

}})();
