(function() {
   'use strict';
   angular.module('com.vmware.vsphere.client.host').controller('EditHardwareProcessorsController',
         EditHardwareProcessorsController);

   EditHardwareProcessorsController.$inject = ['$scope', 'mutationService', 'resourceService'];

   function EditHardwareProcessorsController($scope, mutationService, resourceService) {
      var self = this;
      self.hostId = $scope._route.objectId;
      self.viewData = null;

      function getData() {
         resourceService.getResourceInfo(self.hostId, 'processorGeneralConfig')
               .then(function(data) {
                  self.viewData = data.properties;
               });

      }

      getData();

      $scope.modalOptions.onSubmit = function() {
         var hyperthreadingSpec = {
            hyperthreading: {
               htOnRestart: self.hyperthreadingAvailable
            }
         };
         mutationService.applyByPropName(self.hostId, 'processorGeneralConfig', hyperthreadingSpec);
         return true;
      };
   }
})();
