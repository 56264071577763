/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
(function () {
   'use strict';

   angular.module('com.vmware.vsphere.client.storage').directive('adapterTargetsList', adapterTargetsList);

   adapterTargetsList.$inject = ['i18nService', 'vuiConstants', 'datagridActionBarService'];

   function adapterTargetsList(i18nService, vuiConstants, datagridActionBarService) {
      var directive = {
         replace: true,
         scope: {
            targets: '=', // Array of storage targets to be shown.
            selectedTargets: '=?', // Array of storage targets to be selected initially
            showDynamicTargets: '=?', // Param to display dynamic targets.
            loading: '=?',
            hostId: '=',
            adapter: '=',
            hba: '=',
            onTargetAdded: '=onTargetAdded',
            onTargetRemoved: '=onTargetRemoved',
            onTargetEdited: '=onTargetEdited'
         },
         templateUrl: 'storage-ui/resources/storage/directives/adapterTargetsList/adapterTargetsList.html',
         link: link
      };

      return directive;

      function link(scope) {
         var self = scope;

         var showDynamicTargets = !!scope.showDynamicTargets;

         var SERVER_ADDRESS_COLUMN = {
            displayName: i18nService.getString('StorageUi',
               'storage.adapters.iscsiTargets.staticTarget.serverColumnLabel'),
            field: 'address',
            width: '100px',
            template: function(dataItem) {
               return dataItem.address;
            }
         };

         var TARGET_NAME_COLUMN = {
            displayName: i18nService.getString('StorageUi',
               'storage.adapters.iscsiTargets.staticTarget.targetNameLabel'),
            field: 'name',
            width: '150px',
            template: function(dataItem) {
               return dataItem.name;
            }
         };

         self.datagridOptions = {
            height: '100%',
            columnDefs: getColumnDefinitions(showDynamicTargets),
            sortMode: vuiConstants.grid.sortMode.SINGLE,
            selectionMode: vuiConstants.grid.selectionMode.MULTI,
            showCheckboxesOnMultiSelection: false,
            data: [],
            selectedItems: [],
            resizable: true,
            columnMenu: {
               sortable: false, // this will hide sort menu items
               messages: {
                  columns: i18nService.getString('CommonUi', 'listview.showColumnsMenu'),
                  filter: i18nService.getString('CommonUi', 'listview.filterMenu')
               }
            },
            actionBarOptions: {actions: []}
         };

         self.$watch(function() {
               return self.targets;
            },
            function(newValue, oldValue) {
               if (newValue !== oldValue) {
                  setTargets();
               }
            }
         );

         self.$watch(
            function() {
               return self.datagridOptions.selectedItems;
            },
            function(newValue, oldValue) {
               if (newValue !== oldValue && !_.isEqual(newValue,oldValue)) {
                  self.selectedTargets = newValue;
                  datagridActionBarService.updateActionBar(self.datagridOptions, [self.hostId], getActions());
               }
            }
         );

         self.comparator = function (value) {
            var itemToSelect = _.find(self.selectedTargets, function(selectedTarget) {
               return selectedTarget.address === value.address;
            });
            return itemToSelect !== undefined;
         };

         function setTargets() {
            self.targetItemsMap = {};
            if (self.targets) {
               for (var i = 0; i < self.targets.length; i++) {
                  self.targetItemsMap[i] = self.targets[i];
                  self.targets[i].targetIndex = i;
               }
            }

            self.datagridOptions.data = self.targets;
         }

         setTargets();

         datagridActionBarService.updateActionBar(self.datagridOptions, [self.hostId], getActions());

         function getActions () {
            return [
               {
                  actionId: 'vsphere.core.host.actions.adapters.addIscsiTarget',
                  getActionInvocationContext: function() {
                     return {
                        adapter: self.adapter,
                        dynamicTarget: self.showDynamicTargets,
                        targets: self.targets,
                        callback: scope.onTargetAdded
                     };
                  }
               },
               {
                  actionId: 'vsphere.core.host.actions.adapters.removeIscsiTarget',

                  isActionAvailable: function(actionDef) {
                     return actionDef.available && self.datagridOptions.selectedItems.length !== 0;
                  },

                  getActionInvocationContext: function() {

                     if (!self.datagridOptions.selectedItems || !self.datagridOptions.selectedItems.length) {
                        return;
                     }

                     var targetItems = self.datagridOptions.selectedItems.map(
                        function(item) {
                           return self.targetItemsMap[item.targetIndex].target;
                        });

                     return {
                        adapter: self.adapter,
                        dynamicTarget: self.showDynamicTargets,
                        targets: targetItems,
                        callback: scope.onTargetRemoved
                     };
                  }
               },
               {
                  actionId: 'vsphere.core.host.actions.adapters.editAuthSettingsIscsiTarget',

                  isActionAvailable: function(actionDef) {
                     return actionDef.available && self.datagridOptions.selectedItems.length === 1;
                  },

                  getActionInvocationContext: function() {
                     if (!self.datagridOptions.selectedItems || !self.datagridOptions.selectedItems.length) {
                        return;
                     }

                     var selectedItem = self.targetItemsMap[self.datagridOptions.selectedItems[0].targetIndex];
                     return {
                        adapter: self.adapter,
                        target: selectedItem.target,
                        parentDynamicTargetAuthProperties: selectedItem.parentDynamicTargetAuthProperties,
                        callback: scope.onTargetEdited
                     };
                  }
               },
               {
                  actionId: 'vsphere.core.host.actions.adapters.editAdvancedOptionsForTarget',

                  isActionAvailable: function(actionDef) {
                     return actionDef.available && self.datagridOptions.selectedItems.length === 1;
                  },

                  getActionInvocationContext: function() {
                     if (!self.datagridOptions.selectedItems || !self.datagridOptions.selectedItems.length) {
                        return;
                     }

                     var selectedItem = self.targetItemsMap[self.datagridOptions.selectedItems[0].targetIndex];
                     var targetSet = {
                        _type: "com.vmware.vim.binding.vim.host.InternetScsiHba$TargetSet"
                     };

                     if (showDynamicTargets) {
                        targetSet.sendTargets = [selectedItem.target];
                     } else {
                        targetSet.staticTargets = [selectedItem.target];
                     }

                     return {
                        hba: self.hba,
                        targetSet: targetSet
                     };
                  }
               }
            ];
         }

         function getColumnDefinitions (showDynamicTargets) {
            if (showDynamicTargets) {
               return [SERVER_ADDRESS_COLUMN];
            }

            return [SERVER_ADDRESS_COLUMN, TARGET_NAME_COLUMN];
         }
      }
   }
})();
