(function() {
   'use strict';

   angular
         .module('com.vmware.vsphere.client.vm')
         .controller('VmUpgradeToolsController', VmUpgradeToolsController);

   VmUpgradeToolsController.$inject = ['$scope', '$element', '$timeout', 'i18nService', 'mutationService',
      'dataService', 'managedByMessageBuilderService'];

   function VmUpgradeToolsController($scope, $element, $timeout, i18nService, mutationService, dataService,
         managedByMessageBuilderService) {
      var PROPERTY_TOOLS_AUTO_UPDATE_SUPPORT = "capability.toolsAutoUpdateSupported";
      var PROPERTY_MANAGED_BY_INFO = "managedByInfo";
      var self = this;

      self.TOOLS_INTERACTIVE_UPGRADE = "toolsInteractiveUpgrade";
      self.TOOLS_AUTO_UPGRADE = "toolsAutoUpgrade";

      self.toolsAutoUpdateSupported = true;
      self.loading = false;
      self.header = i18nService.getString('VmUi', 'VmToolsPopupForm.header');
      self.instruction = i18nService.getString('VmUi', 'VmToolsPopupForm.singleUpgrade.instruction');
      self.cancelBtn = i18nService.getString('Common', 'cancelButtonLabel');
      self.interactiveLabel = i18nService.getString('VmUi', 'VmToolsPopupForm.singleUpgrade.interactive');
      self.interactiveExplained = i18nService.getString('VmUi', 'VmToolsPopupForm.singleUpgrade.interactiveExplained');
      self.autoLabel = i18nService.getString('VmUi', 'VmToolsPopupForm.singleUpgrade.auto');
      self.autoExplained = i18nService.getString('VmUi', 'VmToolsPopupForm.singleUpgrade.autoExplained');
      self.advancedOptionsLabel = i18nService.getString('VmUi', 'VmToolsPopupForm.singleUpgrade.advancedOptions');
      self.upgradeLabel = i18nService.getString('VmUi', 'VmToolsPopupForm.singleUpgrade.upgrade');
      self.toolsUpgradeValue = 'toolsAutoUpgrade';
      self.toolsUpgradeAdvancedOptions = '';
      self.isAutoUpgradeSelected = function() {
         return self.toolsUpgradeValue === 'toolsAutoUpgrade';
      };

      $scope.onSubmit = upgradeTools;

      requestData();

      function upgradeTools() {
         var objectId = $scope.modalOptions.dialogData.objectId;
         var propertyObjectType = 'com.vmware.vsphere.client.vm.tools.VmToolsInstallerSpec';
         var vmToolsInstallerSpec;
         if (self.toolsUpgradeValue === self.TOOLS_AUTO_UPGRADE) {
            vmToolsInstallerSpec = {
               operation: 'upgradeTools',
               upgradeOptions: self.toolsUpgradeAdvancedOptions
            };
         } else if (self.toolsUpgradeValue === self.TOOLS_INTERACTIVE_UPGRADE) {
            vmToolsInstallerSpec = {
               operation: 'mountInstaller'
            };
         }

         mutationService.apply(objectId, propertyObjectType, vmToolsInstallerSpec);
         $scope.closeModal();
         return true;
      }

      function requestData() {
         self.loading = true;
         var objectId = $scope.modalOptions.dialogData.objectId;
         dataService.getProperties(
               objectId,
               [PROPERTY_TOOLS_AUTO_UPDATE_SUPPORT, PROPERTY_MANAGED_BY_INFO]
         ).then(function(result) {
            var managedByProperties = {};
            managedByProperties[objectId] = result;
            managedByMessageBuilderService
                  .buildWarningMessage([objectId], false, managedByProperties)
                  .then(function(managedByMessage) {
                     self.loading = false;
                     self.managedByMessage = managedByMessage;
                     self.toolsAutoUpdateSupported = result[PROPERTY_TOOLS_AUTO_UPDATE_SUPPORT];
                     if (!self.toolsAutoUpdateSupported) {
                        self.toolsUpgradeValue = self.TOOLS_INTERACTIVE_UPGRADE;
                     }

                     // Determine which one of the radio buttons is preselected and set the focus on it
                     var selector = "";
                     if(self.toolsUpgradeValue === self.TOOLS_AUTO_UPGRADE) {
                        selector = "#toolsAutoUpgradeRadio";
                     } else {
                        selector = "#toolsInteractiveUpgradeRadio";
                     }

                     $timeout(function() {
                        var radio = $element[0].querySelector(selector);
                        if (radio) {
                           radio.focus();
                        }
                     }, 0);
                  });
         });
      }
   }
})();
