namespace storage_ui {

   import IPromise = angular.IPromise;
   import IQService = angular.IQService;

   export interface ActionDefinition {
      title: string;
      isEnabled: boolean;
      clickHandler: Function;
   }

   export interface StorageAdapterAction {
      uid: string;
      propertyViewActionDef: ActionDefinition;
   }

   class StorageAdapterActionsService {

      public static $inject = [
         "i18nService", "actionsService", "$q", "hostStorageConstants", "mutationService"
      ];

      constructor(private i18nService: any,
                  private actionsService: any,
                  private $q: IQService,
                  private hostStorageConstants: any,
                  private mutationService: any) {

      }

      private isIscsiAdapterConfigurable(hostId: string, adapter: any): IPromise<boolean> {
         let isIscsiAdapterConfigurablePromise = this.$q.defer();
         if (adapter.isSoftwareBased && (_.isUndefined(adapter.canBeDisabled) || adapter.canBeDisabled)) {
            // If the chosen adapter is software-based and can be disabled,
            // validate its activation and then decide upon Edit button availability.
            this.mutationService.validate(
                  hostId,
                  'com.vmware.vsphere.client.storage.adapters.InternetScsiStorageAdapterActivationSpec',
                  {activate: true}
            ).then(function (validationResult: any) {
               if (!!validationResult && (!!validationResult.result || !!validationResult.error)) {
                  isIscsiAdapterConfigurablePromise.resolve(true);
               } else {
                  isIscsiAdapterConfigurablePromise.resolve(false);
               }
            }).catch(function () {
               isIscsiAdapterConfigurablePromise.resolve(false);
            });
         } else {
            isIscsiAdapterConfigurablePromise.resolve(true);
         }
         return isIscsiAdapterConfigurablePromise.promise;
      }

      public getBuilder(hostId: string, adapter: any) {
         if (adapter.type === this.hostStorageConstants.hostAdapterTypes.ISCSI) {
            let isIscsiAdapterConfigurable = this.isIscsiAdapterConfigurable(hostId, adapter);
            return new AdapterActionsBuilder(hostId, adapter, this.i18nService, this.actionsService, this.$q, this.hostStorageConstants, isIscsiAdapterConfigurable);
         }
         return new AdapterActionsBuilder(hostId, adapter, this.i18nService, this.actionsService, this.$q, this.hostStorageConstants, this.$q.when(false));
      }

   }

   class AdapterActionsBuilder {

      private readonly ACTIONS: StorageAdapterAction[];

      private actionEvaluations: any = {};

      constructor(private hostId: string,
                  private adapter: any,
                  private i18nService: any,
                  private actionsService: any,
                  private $q: IQService,
                  private hostStorageConstants: any,
                  private isIscsiAdapterConfigurable: IPromise<boolean>) {
         this.ACTIONS = this.initActions(this.hostStorageConstants.adapterDetailsAction);
         for (let action of this.ACTIONS) {
            this.actionEvaluations[action.uid] = this.$q.defer();
         }
         actionsService.getActions([hostId], _.map(this.ACTIONS, (value)=>value.uid)).then((response: any[]) => {
            for (let actionResponse of response) {
               let action = this.findAction(actionResponse.action.uid);
               isIscsiAdapterConfigurable.then((isConfigurable)=> {
                  action.isEnabled = this.isActionEnabled(actionResponse, isConfigurable);
               });
               this.actionEvaluations[actionResponse.action.uid].resolve(actionResponse);
            }
         });
      }

      private initActions(actionsConst: any): StorageAdapterAction[] {
         return [{
            uid: actionsConst.ENABLE_SW_ISCSI_ADAPTER,
            propertyViewActionDef: {
               title: this.i18nService.getString("StorageUi", "storage.adapters.iscsi.button.labelEnable"),
               isEnabled: true,
               clickHandler: this.getInvokeActionFunction(actionsConst.ENABLE_SW_ISCSI_ADAPTER)
            }
         }, {
            uid: actionsConst.DISABLE_SW_ISCSI_ADAPTER,
            propertyViewActionDef: {
               title: this.i18nService.getString("StorageUi", "storage.adapters.iscsi.button.labelDisable"),
               isEnabled: true,
               clickHandler: this.getInvokeActionFunction(actionsConst.DISABLE_SW_ISCSI_ADAPTER)
            }
         }, {
            uid: actionsConst.EDIT_AUTH_SETTINGS,
            propertyViewActionDef: {
               title: this.i18nService.getString('StorageUi', 'generalEditAction.label'),
               isEnabled: true,
               clickHandler: this.getInvokeActionFunction(actionsConst.EDIT_AUTH_SETTINGS)

            }
         }, {
            uid: actionsConst.EDIT_GENERAL_SETTINGS,
            propertyViewActionDef: {
               title: this.i18nService.getString('StorageUi', 'generalEditAction.label'),
               isEnabled: true,
               clickHandler: this.getInvokeActionFunction(actionsConst.EDIT_GENERAL_SETTINGS)
            }
         }];
      }

      private isActionEnabled(actionResponse: any, isIscsiAdapterConfigurable: boolean) {
         if (actionResponse.action.uid === this.hostStorageConstants.adapterDetailsAction.EDIT_GENERAL_SETTINGS) {
            return actionResponse.available && isIscsiAdapterConfigurable;
         }
         return actionResponse.available;
      }

      private getInvokeActionFunction(actionUid: string): () => IPromise<any> {
         return ()=> {
            if (this.actionEvaluations[actionUid]) {
               let defferedResponse = this.$q.defer();
               this.actionEvaluations[actionUid].promise.then((actionEval: any) => {
                  this.actionsService.invokeAction(actionEval, {
                     adapter: this.adapter,
                     callback: (response:any) => defferedResponse.resolve(response)
                  });
               });
               return defferedResponse.promise;
            }
            return this.$q.reject();
         };
      }

      public findAction(actionUid: string): ActionDefinition {
         return _.find(this.ACTIONS, (item)=>item.uid === actionUid).propertyViewActionDef;
      }

      public setEnableActionSuccess(callback: Function) {
         this.setActionClickHandler(this.hostStorageConstants.adapterDetailsAction.ENABLE_SW_ISCSI_ADAPTER, callback);
         return this;
      }

      public setDisableActionSuccess(callback: Function) {
         this.setActionClickHandler(this.hostStorageConstants.adapterDetailsAction.DISABLE_SW_ISCSI_ADAPTER, callback);
         return this;
      }

      private setActionClickHandler(actionUid: string, callback: Function) {
         let action: ActionDefinition =
               this.findAction(actionUid);
         action.clickHandler = ()=> {
            this.getInvokeActionFunction(actionUid)().then((response:any)=> {
               if (response && !response.error) {
                  callback();
               }
            });
         };
      }

   }

   angular.module("com.vmware.vsphere.client.storage")
         .service("storageAdapterActionsService", StorageAdapterActionsService);

}
