(function() {
   'use strict';

   angular.module('com.vmware.vsphere.client.vm')
         .service('VmProvisioningFinishDeployFromTemplatePageModel',
               VmProvisioningFinishDeployFromTemplatePageModel);

   VmProvisioningFinishDeployFromTemplatePageModel.$inject = [
      'i18nService',
      'contentLibraryService',
      'sourceTypeConstants',
      'storageProfileService',
      'defaultUriSchemeUtil'
   ];

   function VmProvisioningFinishDeployFromTemplatePageModel(i18nService,
         contentLibraryService,
         sourceTypeConstants,
         storageProfileService,
         defaultUriSchemeUtil) {
      return function(wizardVmParams, wizardViewData) {
         var VSAN_DATASTORE_TYPE = 'vsan';
         var THIN_TYPE = 'thin';

         return {
            presentProvisioningType: function() {
               if (wizardViewData.getTemplateSourceType() === sourceTypeConstants.SOURCE_TYPE_PULL) {
                  return i18nService.getString('VmUi', 'ProvisioningTypes.DeployFromOvfRemote.Title');
               } else {
                  return i18nService.getString('VmUi', 'ProvisioningTypes.DeployFromTemplate.Title');
               }
            },

            validatePage: function() {
               return {};
            },

            getVmName: function() {
               return wizardVmParams.getName();
            },

            getCustomizationSpec: function() {
               return wizardVmParams.getCustomizationSpec();
            },

            getStorageDetails: function() {
               var datastoreMappingParams =
                     wizardViewData.getDatastoreMappingParamsFromLibraryCommonContext();

               // One mapping info for each disk group.
               var result = _.map(datastoreMappingParams.diskGroups, function(diskGroup) {
                  return {
                     name: diskGroup.name,
                     diskInfo: getDiskInfoLabel(diskGroup, datastoreMappingParams)
                  };
               });

               // And one dummy mapping info for all disks that are not part of any disk group.
               result.push({
                  name: _.size(result) > 0 ?
                        i18nService.getString('ProvisioningUiLib',
                              'SelectStoragePage.summary.allOtherDisks') :
                        i18nService.getString('ProvisioningUiLib',
                              'SelectStoragePage.summary.allDisks'),
                  diskInfo: getDiskInfoLabel(undefined, datastoreMappingParams)
               });

               // Just a single label - return it.
               if (_.size(result) === 1) {
                  return result;
               }

               // If all labels are identical - we want to merge them into 1,
               // cause this means all disk groups are going to the same location
               var someDiffer = _.some(result, function(mapping) {
                  return (mapping.diskInfo !== result[0].diskInfo);
               });

               if (someDiffer) {
                  return result;
               }

               // All disk info labels are identical - so return single unified item.
               return [{
                  name: i18nService.getString('ProvisioningUiLib',
                        'SelectStoragePage.summary.allDisks'),
                  diskInfo: result[0].diskInfo
               }];
            },

            getNetworkDetails: function(networkInfo) {
               if (networkInfo) {
                  return _.map(networkInfo.sourceNetworks, function(network) {
                     var tgt = _.find(networkInfo.targetNetworks, function(tNetwork) {
                        return tNetwork.id === network.target;
                     });
                     network.targetName = tgt.name;
                     return network;
                  });
               }
               return null;

            },

            getIpAllocationDetails: function(ipInfo) {
               if (ipInfo) {
                  ipInfo.ipAllocationPolicy = _.find(ipInfo.supportedIpAllocationPolicy, function(policy) {
                     return _.isEqual(policy.name, ipInfo.ipAllocationPolicy.name);
                  });
                  return ipInfo;
               }
               return null;
            },

            submitPage: function () {
               var gosCustomizationInfo = wizardVmParams.getCustomizationSpecInfo();
               var gosCustomizationSpec = wizardVmParams.getCustomizationSpec();

               // Add GOSC details into common context only when info & spec are available
               if (gosCustomizationInfo && gosCustomizationSpec) {
                  buildGosCustomizationParam(
                        gosCustomizationInfo,
                        gosCustomizationSpec)
                        .then(function (gosCustomizationParam) {
                           var commonContext = wizardViewData.getDeployFromLibraryCommonContext();
                           commonContext.push(gosCustomizationParam);
                           wizardViewData.setDeployFromLibraryCommonContext(commonContext);
                           deployTemplate();
                        });
               } else {
                  deployTemplate();
               }
               return true;
            },

            fetchSelectedStorage: function() {
               return wizardVmParams.getStorageObject();
            }
         };

         /**
          * Content library service does not accept GOSC Spec in plain object format.
          * GOSC Spec must be converted into an XML string with all customizations
          * present in it.
          *
          * @param gosCustomizationInfo
          *          Name, Description of the GOSC.
          * @param gosCustomizationSpec
          *          Object representation of specification with all user input values.
          * @returns
          *    {promised mutation call which results an XML output.}
          */
         function buildGosCustomizationParam(gosCustomizationInfo, gosCustomizationSpec) {

            return contentLibraryService
                  .getXmlFormattedCustomizationSpec(
                        wizardViewData.getVCenterId(),
                        gosCustomizationInfo,
                        gosCustomizationSpec)
                  .then(function (specObject) {
                     var customizationInXml = specObject.result;
                     return {
                        _type: "com.vmware.vcenter.ovf.VcenterGuestCustomizationParams",
                        type: "VcenterGuestCustomizationParams",
                        customizations: {
                           systemId: {
                              type: 'XML',
                              xml: customizationInXml
                           }
                        }
                     };
                  });
         }

         /**
          * Performs the final deploy-template call.
          * Also, modifies targetNetworks and datastore mapping in common context.
          */
         function deployTemplate() {
            var isLocalOvfDeploySession = wizardViewData.getTemplateSourceType() === sourceTypeConstants.SOURCE_TYPE_PUSH;
            var localOvfSession = isLocalOvfDeploySession ? wizardViewData.getDeployOvfSession() : undefined;

            //#2072730
            clearTargetNetworks();
            //#2072730
            clearTargetNetworks();

            //#VUCL-862
            modifyDatastoreMappings();
            //#VUCL-862
            modifyDatastoreMappings();

            contentLibraryService.deployVmFromLibraryTemplate(
                  wizardVmParams.getComputeResourceId(),
                  wizardVmParams.getTargetInformation().folderUid,
                  wizardVmParams.getName(),
                  wizardViewData.getDeployFromLibraryCommonContext(),
                  isLocalOvfDeploySession,
                  localOvfSession);
            return true;
         }

         function getDiskInfoLabel(diskGroup, datastoreMappingParams) {
            // We benefit form the fact that diskGroups and datastoreMappingParams
            // have similar properties when it comes to setting actual data.
            var diskGroupObject = diskGroup || datastoreMappingParams;

            // We use 'undefined' compare because we want to distinguish between not set
            // and set with empty string. The first means - take from parent,
            // the second means - no profile.
            var profile = (diskGroupObject.targetProfile !== undefined) ?
                  findProfile(diskGroupObject.targetProfile) :
                  findProfile(datastoreMappingParams.targetProfile);

            var isPmemProfile = storageProfileService.isPmemStorageProfile(profile);

            var storageName = diskGroupObject.targetDatastore ?
                  findStorageName(diskGroupObject.targetDatastore) :
                  !isPmemProfile && datastoreMappingParams ?
                        findStorageName(datastoreMappingParams.targetDatastore) : "";

            // NOTE: For VSAN datastores targetProvisioningType can be null
            // See VmProvisioningDatastorePageModel.updateDiskFormatIfVSAN
            var diskFormatName = diskGroupObject.targetProvisioningType ?
                  i18nService.getString('ProvisioningUiLib',
                     'DiskProvisioningType.' +
                     diskGroupObject.targetProvisioningType.name +
                     '.label') :
                  i18nService.getString('ProvisioningUiLib',
                     'DiskProvisioningType.asDefinedInProfile.label');

            var diskInfoLbl;
            if (isPmemProfile) {
               diskInfoLbl = i18nService.getString('ProvisioningUiLib',
                     'SelectStoragePage.summary.policyPMemdsFormat',
                     profile.label, diskFormatName);
            } else if (profile) {
               diskInfoLbl = i18nService.getString('ProvisioningUiLib',
                     'SelectStoragePage.summary.policyDsFormat',
                     profile.label, storageName, diskFormatName);
            } else {
               diskInfoLbl = i18nService.getString('ProvisioningUiLib',
                     'SelectStoragePage.summary.dsFormat',
                     storageName, diskFormatName);
            }

            if (!diskInfoLbl) {
               return "N/A";
            }

            return diskInfoLbl;
         }

         // NOTE speev: target datastore in the DatastoreMappingParams stucture have
         // strange format - so we need special method to search in the map.
         function findStorageName(targetDatastore) {
            var storageNamesMap = wizardViewData.getStorageNameMap();
            return _.find(storageNamesMap, function(storageName, storageKey) {
               return (storageKey.indexOf(targetDatastore) >= 0);
            });
         }

         function findProfile(targetProfileId) {
            var storageProfiles = wizardViewData.getStorageProfiles();
            return _.findWhere(storageProfiles, { id: targetProfileId });
         }

         function clearTargetNetworks() {
            var networkParams =
                  wizardViewData.getNetworkMappingParamsFromLibraryCommonContext();
            if (networkParams && networkParams.targetNetworks) {
               networkParams.targetNetworks = null;
            }
         }

         function modifyDatastoreMappings()  {
            var datastoreMappingParams =
                  wizardViewData.getDatastoreMappingParamsFromLibraryCommonContext();

            if(!datastoreMappingParams) {
               return;
            }

            //Set provisioning type to thin for targetDatastore for each diskGroup if present.
            _.each(datastoreMappingParams.diskGroups, function (diskGroup) {
               var datastore = findDatastoreItemFromId(diskGroup.targetDatastore);
               if (datastore && datastore.type === VSAN_DATASTORE_TYPE && datastore.thinProvisioningSupported) {
                  diskGroup.targetProvisioningType =
                        _.find(datastoreMappingParams.availableDiskProvisioningTypes, function (type) {
                           return type.name === THIN_TYPE;
                        });
               }
            });

            //Set provisioning type to thin for targetDatastore in datastoreMappings object.
            var datastore = findDatastoreItemFromId(datastoreMappingParams.targetDatastore);
            if (datastore && datastore.type === VSAN_DATASTORE_TYPE && datastore.thinProvisioningSupported) {
               datastoreMappingParams.targetProvisioningType =
                     _.find(datastoreMappingParams.availableDiskProvisioningTypes, function (type) {
                  return type.name === THIN_TYPE;
               });
            }
         }

         function getVsphereDatastoreId(contentLibaryDatastoreId) {
            if (!contentLibaryDatastoreId) {
               return undefined;
            }
            var parts = contentLibaryDatastoreId.split(":");
            if (parts.length < 2) {
               return undefined;
            }
            return defaultUriSchemeUtil.getVsphereObjectId({
               type: "Datastore",
               value: parts[0],
               serverGuid: parts[1]
            });
         }

         function findDatastoreItemFromId(targetDatastore) {
            if(targetDatastore) {
               var datastoreItems = wizardViewData.getStorageLocatorItemsData().datastoreItems;
               return _.find(datastoreItems, function (datastoreItem) {
                  if (datastoreItem) {
                     var id1 = getVsphereDatastoreId(targetDatastore);
                     var id2 = datastoreItem.storageRef;
                     return defaultUriSchemeUtil.compareIds(id1, id2);
                  } else {
                     return false;
                  }
               });
            }
         }
      };
   }
}());
