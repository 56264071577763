(function () {
   'use strict';

   /**
    * @name com.vmware.vsphere.client.host:hostConnectionState
    *
    * @description
    *    Represents HostSystemConnectionState enum values.
    */
   angular
      .module('com.vmware.vsphere.client.commonModule')
      .constant('hostConnectionState', {
         CONNECTED: 'connected',
         DISCONNECTED: 'disconnected',
         NOT_RESPONDING: 'notResponding'
      });
})();
