/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */

(function() {
   'use strict';

   angular.module('com.vmware.vsphere.client.networkLibUi').service(
         'dvsDetailsViewService', dvsDetailsViewService);

   dvsDetailsViewService.$inject = [
      'i18nService',
      'vxPropertyViewService',
      'networkUtil',
      'networkUiConstants'
   ];

   function dvsDetailsViewService (
         i18nService, propertyViewService, networkUtil, networkUiConstants
   ) {

      return {
         build: build
      };

      function build (dvsConfigDetailsData, isHostProxySwitchContext) {

         if (dvsConfigDetailsData) {
            var builder = propertyViewService.createPropertyViewBuilder();

            var generalCategoryBuilder = builder
                  .category("general")
                  .title(i18nService.getString(
                        'NetworkUi', 'DvsConfigPage.general.title'));

            buildGeneralSection(dvsConfigDetailsData, isHostProxySwitchContext,
                  generalCategoryBuilder);
            buildAdvancedSection(dvsConfigDetailsData, generalCategoryBuilder);
            buildDiscoveryProtocolSection(dvsConfigDetailsData,
                  generalCategoryBuilder);
            buildAdminContactSection(dvsConfigDetailsData,
                  generalCategoryBuilder);

            return builder.build();

         }

         return {};

      }

      function buildGeneralSection (
            dvsConfigDetailsData,
            isHostProxySwitchContext,
            generalCategoryBuilder
      ) {
         // Create the general section
         var generalSectionTitle = i18nService.getString('NetworkUi',
               'DvsConfigPage.general.title');

         var generalSection = generalCategoryBuilder
               .section("generalSection").title(generalSectionTitle);

         if (dvsConfigDetailsData.name){
            generalSection.property(
               networkUtil.getString('DvsConfigPage.general.body.name.label'),
               dvsConfigDetailsData.name);
         }

         if (dvsConfigDetailsData.description) {
            generalSection
                  .property(i18nService.getString(
                        'NetworkUi', 'DvsConfigPage.general.body.notes.label'),
                        dvsConfigDetailsData.description);
         }

         if (dvsConfigDetailsData.productVendor) {
            generalSection
                  .property(networkUtil.getString(
                        'HostProxySwitchDetailsView.manufacturer'),
                        dvsConfigDetailsData.productVendor);
         }

         if (dvsConfigDetailsData.productVersion) {
            generalSection
                  .property(networkUtil.getString(
                        'HostProxySwitchDetailsView.version'),
                        networkUtil.getString('SwitchDiagram.dvsVersionStringFormat',
                              dvsConfigDetailsData.productVersion,
                              dvsConfigDetailsData.productBuildNumber ?
                                    dvsConfigDetailsData.productBuildNumber : ""));
         }

         if (dvsConfigDetailsData.productBuildNumber) {
            generalSection
                  .property(networkUtil.getString(
                        'HostProxySwitchDetailsView.version'),
                        dvsConfigDetailsData.productVersion);
         }

         if (dvsConfigDetailsData.uplinkPorts) {
            generalSection
                  .property(networkUtil.getString(
                        'DvsConfigPage.general.body.numberOfUplinks.label'),
                        dvsConfigDetailsData.uplinkPorts);
         }

         if (!isHostProxySwitchContext && dvsConfigDetailsData.numberOfPorts) {
            generalSection
                  .property(networkUtil.getString(
                        'DvsConfigPage.general.body.numberOfPorts.label'),
                        dvsConfigDetailsData.numberOfPorts);
         }

         if (dvsConfigDetailsData.networkResourceManagementSupported) {
            generalSection
                  .property(networkUtil.getString(
                        'DvsConfigPage.resmgmt.label'),
                        getNiocStatus(
                              dvsConfigDetailsData.networkResourceManagementEnabled));
         }

      }

      function getNiocStatus (networkResourceManagementEnabled) {
         return networkResourceManagementEnabled ?
               networkUtil.getString('DvsConfigPage.resmgmt.enabled') :
               networkUtil.getString('DvsConfigPage.resmgmt.disabled');
      }


      function buildAdvancedSection (
            dvsConfigDetailsData,
            generalCategoryBuilder
      ) {

         // Create the advanced section
         var advancedSectionTitle = i18nService.getString('NetworkUi',
               'DvsConfigPage.advanced.title');

         var advancedSection = generalCategoryBuilder
               .section("advancedSection").title(advancedSectionTitle);

         if (dvsConfigDetailsData.maxMtu) {

            advancedSection
                  .property(
                        networkUtil.getString('DvsConfigPage.advanced.maxMtu.label'),
                        networkUtil.getString('DvsConfigPage.advanced.maxMtu.format',
                              dvsConfigDetailsData.maxMtu));
         }

         if (dvsConfigDetailsData.multicastSnoopingSupported && dvsConfigDetailsData.multicastFilteringMode) {
            advancedSection
                  .property(
                        networkUtil.getString(
                              'DvsConfigPage.advanced.multicastFiltering.label'),
                        getMulticastLabel(dvsConfigDetailsData.multicastFilteringMode));
         }

         if (dvsConfigDetailsData.dvsCapabilities && dvsConfigDetailsData.dvsCapabilities.defaultMaxNumPortsSupported &&
               dvsConfigDetailsData.defaultProxySwitchMaxNumPorts) {

            advancedSection
                  .property(
                        networkUtil.getString(
                              'DvsConfigPage.advanced.defaultMaxNumPorts.label'),
                        dvsConfigDetailsData.defaultProxySwitchMaxNumPorts);

         }
      }

      function getMulticastLabel (multicastFilteringMode) {
         if (multicastFilteringMode ===
               networkUiConstants.MulticastFiltering.BASIC) {
            return networkUtil.getString(
                  'DvsConfigPage.advanced.multicastMode.basic');
         } else if (multicastFilteringMode ===
               networkUiConstants.MulticastFiltering.SNOOPING) {
            return networkUtil.getString(
                  'DvsConfigPage.advanced.multicastMode.snooping');
         }
      }

      function buildDiscoveryProtocolSection (
            dvsConfigDetailsData,
            generalCategoryBuilder
      ) {

         // Create the link discovery protocol section
         var ldpSectionTitle = i18nService.getString('NetworkUi',
               'DvsConfigPage.advanced.dp.title');

         var ldpSection = generalCategoryBuilder
               .section("ldpSection").title(ldpSectionTitle);

         if (dvsConfigDetailsData.linkDiscoveryProtocolOperation !==
               networkUiConstants.LinkDiscoveryOperation.DISABLED) {

            ldpSection
                  .property(
                        networkUtil.getString('DvsConfigPage.advanced.dp.type.label'),
                        getDiscoveryProtocolType(dvsConfigDetailsData
                              .linkDiscoveryProtocolType));

            ldpSection
                  .property(
                        networkUtil.getString('DvsConfigPage.advanced.dp.operation.label'),
                        getDiscoveryOperation(dvsConfigDetailsData
                              .linkDiscoveryProtocolOperation));

         } else {
            ldpSection
                  .property(
                        networkUtil.getString('DvsConfigPage.advanced.dp.type.label'),
                        networkUtil.getString('DvsConfigPage.advanced.dp.type.disabled'));

            ldpSection
                  .property(
                        networkUtil.getString('DvsConfigPage.advanced.dp.operation.label'),
                        '');
         }

      }

      function getDiscoveryProtocolType (linkDiscoveryProtocolType) {
         if (linkDiscoveryProtocolType === networkUiConstants
                     .LinkDiscoveryProtocol.CDP) {
            return networkUtil.getString('DvsConfigPage.advanced.dp.type.cdp');
         } else if (linkDiscoveryProtocolType === networkUiConstants
                     .LinkDiscoveryProtocol.LLDP) {
            return networkUtil.getString('DvsConfigPage.advanced.dp.type.lldp');
         }
      }

      function getDiscoveryOperation (linkDiscoveryProtocolOperation) {

         switch (linkDiscoveryProtocolOperation) {
            case networkUiConstants.LinkDiscoveryOperation.ADVERTISE:
               return networkUtil.getString(
                     'DvsConfigPage.advanced.dp.operation.advertise');
            case networkUiConstants.LinkDiscoveryOperation.LISTEN:
               return networkUtil.getString(
                     'DvsConfigPage.advanced.dp.operation.listen');
            case networkUiConstants.LinkDiscoveryOperation.BOTH:
               return networkUtil.getString(
                     'DvsConfigPage.advanced.dp.operation.both');
            default :
               return "";
         }

      }

      function buildAdminContactSection (
            dvsConfigDetailsData,
            generalCategoryBuilder
      ) {

         // Create the admin contact section
         var adminContactSectionTitle = i18nService.getString('NetworkUi',
               'DvsConfigPage.advanced.contact.title');

         var adminContactSection = generalCategoryBuilder
               .section("adminContactSection").title(adminContactSectionTitle);

         adminContactSection
               .property(
                     networkUtil.getString('DvsConfigPage.advanced.contact.name.label'),
                     dvsConfigDetailsData.contactName);

         adminContactSection
               .property(
                     networkUtil.getString('DvsConfigPage.advanced.contact.contact.label'),
                     dvsConfigDetailsData.contact);
      }
   }
})();
