module ds_cluster_ui {

   import IController = angular.IController;

   class AddVmdkAntiAffinityRuleController implements IController {

      private objectId: string;
      private spec: any;
      public vmdkGridOptions: any;
      public addVmAction: any;
      public mode: string;
      public editMode: string;

      static $inject = ["$rootScope", "$scope", "i18nService",
            "datagridActionBarService", "vuiConstants", "columnRenderersRegistry",
            "actionsService", "sdrsRulesService", "dsClusterConstants"];

      constructor($rootScope: any, private $scope: any, private i18nService: any,
            private datagridActionBarService: any, private vuiConstants: any,
            private columnRenderersRegistry: any, private actionsService: any,
            private sdrsRulesService: SdrsRulesService, private dsClusterConstants: any) {
         this.objectId = $rootScope._route.objectId;
         this.editMode = dsClusterConstants.operationType.EDIT;

         $scope.$watch("$ctrl.vmdkGridOptions.selectedItems", () => {
            this.spec.ruleMembersData = this.vmdkGridOptions.selectedItems;
         });
         $scope.vmdkPreselectComparator = this.vmdkPreselectComparator.bind(this);
      }

      public $onChanges(changes: any): void {
         if (typeof changes.spec !== "undefined") {
            this.spec = changes.spec.currentValue;

            this.vmdkGridOptions =
                  this.getVmdkGridOptions(changes.spec.currentValue);

            // Request action evaluations from the backend
            this.actionsService.getAction(
                  "vsphere.core.dscluster.actions.rule.vmdk.members.add", [this.objectId])
                  .then((actionEvalutaions: any) => {
                     this.addVmAction = actionEvalutaions;
                  });
         } else if (typeof changes.mode !== "undefined") {
            this.mode = changes.mode.currentValue;
         }
      }

      public invokeAction(action: any): void {
         this.actionsService.invokeAction(action, {
               submitCallback: (dialogOptions: any) => {
               if (dialogOptions.dialogData.selectedItems !== undefined) {
                  let selectedVm: any = dialogOptions.dialogData.selectedItems[0];
                  this.spec.selectedVm = selectedVm;
                  this.sdrsRulesService.getVmVirtualDisks(selectedVm.id)
                        .then((virtualDisks: any[]) => {
                     this.vmdkGridOptions.data = virtualDisks;
                  });
               }
               return true;
            },
            selectedMembers: this.spec.selectedVm ? [this.spec.selectedVm.id] : [],
            dialogSubTitleObject: {
               objectId: this.objectId
            }});
      }

      private vmdkPreselectComparator(member: any): boolean {
         return this.mode === this.editMode;
      }

      private getVmdkGridOptions(spec: any): Object {
         return {
            actionBarOptions: {
               actions: []
            },
            searchable: false,
            resizable: true,
            data: spec.ruleMembersData,
            selectionMode: this.vuiConstants.grid.selectionMode.MULTI,
            showCheckboxesOnMultiSelection: true,
            selectedItems: [],
            columnDefs: [{
               displayName: this.i18nService.getString(
                     "Common", "sdrs.rules.config.vmdkNameHeaderText"),
               field: "deviceInfo.label",
               type: "string",
               template: "<span class=\"object\" title=\"#:deviceInfo.label#\">#:deviceInfo.label#</span>"
            }],
            height: "186px",
            pageConfig: {
               hidePager: true
            }
         };
      }
   }

   class AddVmdkAntiAffinityRuleComponent implements ng.IComponentOptions {
      public controller: any;
      public templateUrl: string;
      public bindings: any;

      constructor() {
         this.controller = AddVmdkAntiAffinityRuleController;
         this.templateUrl =
               "ds-cluster-ui/resources/ds-cluster/views/settings/configuration/rules/" +
               "addVmdkAntiAffinityRuleComponent.html";
         this.bindings = {
            spec: "<",
            mode: "<"
         };
      }
   }

   angular.module("com.vmware.vsphere.client.dsCluster").component(
         "addVmdkAntiAffinityRuleComponent", new AddVmdkAntiAffinityRuleComponent());
}
