namespace license_ui {

   export class ProductLicensesViewController {

      public static $inject = ["$scope", "licensesManagementNavigationService", "licensingRouteObjectsFactoryService"];

      constructor(private $scope: any, private licensesManagementNavigationService: LicensesManagementNavigationService,
            private licensingRouteObjectsFactoryService: LicensingRouteObjectsFactoryService) {
      }

      public onFocusedLicenseChanged(selectedLicense: any): void {
         this.triggerNavigationRequestToSelectedLicenseItem();
      }

      private triggerNavigationRequestToSelectedLicenseItem(): void {
         const licenses: any[] = this.$scope.masterDetailsViewContext.selectedItem.licenses;
         if (licenses && licenses.length > 0) {
            const licensesListRouteObject: LicensingRouteObject = this.licensingRouteObjectsFactoryService
                  .createLicensesListLicensingRouteObject(licenses[0].uri);
            this.licensesManagementNavigationService
                  .emitLicensesManagementNavigationRequestOnScope(this.$scope, licensesListRouteObject);
         }
      }
   }

   angular.module('com.vmware.vsphere.client.licenseUi').controller(
         'ProductLicensesViewController',
         ProductLicensesViewController
   );
}
