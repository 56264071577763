module cluster_ui {

   import ManagedObjectReference = com.vmware.vim.binding.vmodl.ManagedObjectReference;
   import IPromise = angular.IPromise;

   export class ClusterConfigVmOverrideService {

      public static $inject = ["i18nService", "clusterVmOverridesConstants", "drsConstants",
         "drsAutomationLevelService", "addClusterVmOverridesWizardService", "AddClusterVmOverridesManager"];

      constructor(private i18nService: any,
                  private clusterVmOverridesConstants: any,
                  private drsConstants: any,
                  private drsAutomationLevelService: any,
                  private addClusterVmOverridesWizardService: AddClusterVmOverridesWizardService,
                  private AddClusterVmOverridesManager: new () => AddClusterVmOverridesManager) {
      }

      public openAddVmOverrideWizard(targetUid: string, focusTarget: any) {
         let manager: AddClusterVmOverridesManager = new this.AddClusterVmOverridesManager();
         this.addClusterVmOverridesWizardService.invokeWizard(targetUid, manager, focusTarget);
      }

      public getGridOptions(): Object {
         let options = {
            actionBarOptions: {
               actions: []
            },
            pageConfig: {
               hidePager: true
            },
            searchable: false,
            resizable: false,
            data: [],
            columnDefs: this.getAllColumnDefs(),
            height: "100%"
         };

         return options;
      }

      public getDrsAutomationLevels(): Object[] {
         return [{
            id: this.drsConstants.AUTOMATION_LEVEL_DISABLED,
            label: this.i18nService.getString(
                  "ClusterUi", "vm.overrides.DRS.behavior.disabled")
         }].concat(this.drsAutomationLevelService.getLevels());
      }

      public isClusterDefault(propertyValue: string): boolean {
         return propertyValue === this.clusterVmOverridesConstants.CLUSTER_DEFAULT ||
               propertyValue === this.clusterVmOverridesConstants.OVERRIDE_NA ||
               propertyValue === this.clusterVmOverridesConstants.RESTART_PRIORITY_CLUSTER_DEFAULT ||
               propertyValue === this.clusterVmOverridesConstants.ISOLATION_RESPONSE_CLUSTER_DEFAULT ||
               propertyValue === this.clusterVmOverridesConstants.RESPONSE_RECOVERY_CLUSTER_DEFAULT;
      }

      public getTooltipParams(vmValues: any[], settingOptions: any, clusterValue: any): any {
         let messages: any = [];
         _.each(vmValues, (vmValue: any) => {
            let value: string;
            if (this.isClusterDefault(vmValue.value)) {
               value = this.i18nService.getString("ClusterUi",
                     "vm.overrides.config.clusterDefault",
                     this.getOverrideValueLocalizedString(clusterValue, settingOptions));
            } else {
               value = this.getOverrideValueLocalizedString(vmValue.value, settingOptions);
            }
            messages.push(this.i18nService.getString("ClusterUi",
                  "vm.overrides.config.editMultiple.tooltip.separator",
                  vmValue.vm.name, value));
         });

         return this.buildTooltipParams(messages);
      }

      public getValuesInSecondsTooltipParams(vmValues: any[], clusterValue: number): any {
         let messages: any = [];
         _.each(vmValues, (vmValue: any) => {
            let value: string;
            if (this.isClusterDefault(vmValue.value)) {
               value = this.i18nService.getString("ClusterUi",
                     "vm.overrides.config.clusterDefault",
                     clusterValue +
                     " " +
                     this.i18nService.getString('ClusterUi', 'vm.overrides.HA.restart.orchestration.seconds'));
            } else {
               value = vmValue.value +
                     " " +
                     this.i18nService.getString('ClusterUi', 'vm.overrides.HA.restart.orchestration.seconds');
            }
            messages.push(this.i18nService.getString("ClusterUi",
                  "vm.overrides.config.editMultiple.tooltip.separator",
                  vmValue.vm.name,
                  value));
         });

         return this.buildTooltipParams(messages);
      }

      public getApdDelayTooltipParams(isApdDelayDefaultValues: any[],
                                      vmHaApdDelayValues: any[], clusterValue: any): any {
         let messages: any = [];
         _.each(isApdDelayDefaultValues, (isApdDelayDefaultValue: any) => {
            let value: string;
            if (isApdDelayDefaultValue.value) {
               value = this.i18nService.getString("ClusterUi",
                     "vm.overrides.config.clusterDefault",
                     this.i18nService.getString("ClusterUi",
                           "vmOverrides.intervalBeforeVmcpReaction.format", clusterValue));
            } else {
               let vmValue: any = _.find(vmHaApdDelayValues, (vmApdDelayValue: any) => {
                  return vmApdDelayValue.vm.name === isApdDelayDefaultValue.vm.name;
               });
               value = this.i18nService.getString("ClusterUi",
                     "vmOverrides.intervalBeforeVmcpReaction.format", vmValue.value);
            }

            messages.push(this.i18nService.getString("ClusterUi",
                  "vm.overrides.config.editMultiple.tooltip.separator",
                  isApdDelayDefaultValue.vm.name, value));
         });

         return this.buildTooltipParams(messages);
      }

      public hasMultipleValues(settingValue: any): boolean {
         return settingValue === this.clusterVmOverridesConstants.INITIAL_VALUES;
      }

      public getUniqueValue(vmValues: any[]): any {
         let uniqueValues: any[] = _.uniq(vmValues, false, (vmValues: any) => {
            return vmValues.value;
         });

         return (uniqueValues.length === 1) ? uniqueValues[0].value :
               this.clusterVmOverridesConstants.INITIAL_VALUES;
      }

      public addInitialValuesOption(options: any[]): void {
         options.push({
            id: this.clusterVmOverridesConstants.INITIAL_VALUES,
            label: this.i18nService.getString(
                  "ClusterUi", "vm.overrides.config.editMultiple.useInitialValues")
         });
      }

      public containsValue(vmValues: any[], value: string): boolean {
         return _.some(vmValues, (vmValue: any) => {
            return vmValue.value === value;
         });
      }

      private buildTooltipParams(messages: string[]): any {
         let title: string =
               this.i18nService.getString("ClusterUi",
                     "vm.overrides.config.editMultiple.signPost.title");

         return {
            title: title,
            message: messages.join("<br>")
         };
      }

      private getOverrideValueLocalizedString(value: any, settingOptions: any): string {
         let settingOption: any = _.find(settingOptions, (option: any) => {
            return String(option.id) === String(value);
         });

         return settingOption.label;
      }

      private getAllColumnDefs(): Object[] {
         return [
            {
               displayName: this.i18nService.getString("ClusterUi",
                     "vm.overrides.config.vm.selection.name.column"),
               field: "name",
               type: "string",
               template: "<span class='object' title='#:name#'><i class='#:icon#')></i>#:name#</span>"
            },
         ];
      }
   }

   angular.module("com.vmware.vsphere.client.cluster")
         .service("clusterConfigVmOverrideService", ClusterConfigVmOverrideService);
}
