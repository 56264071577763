namespace network_ui {

   import IPromise = angular.IPromise;

   export class IscsiPortBindingDetailsTab {

      public bindings: any;
      public controller: any;
      public templateUrl: string;

      constructor() {
         this.bindings = {
            hostId: "<",
            iscsiPortBindingItem: "<",
            isBoundPortBinding: "<",
            detailsRetriever: "<"
         };

         this.controller = IscsiPortBindingDetailsTabController;
         this.templateUrl = "network-ui/resources/network/views/host/iscsi/details/IscsiPortBindingDetailsTab.html";
      }
   }

   class IscsiPortBindingDetailsTabController {

      public static $inject = ["i18nService", "physicalAdaptersListService", "physicalAdapterDetailsViewService"];

      public i18n: Function;

      public hostId: string;

      public isBoundPortBinding: boolean;

      public iscsiPortBindingItem: IscsiPortInfoItem;

      public detailsRetriever: IscsiPortBindingDetailsViewDataRetriever;

      public propertiesData: any;

      public loading: boolean = false;

      public noDetailsAvailable: boolean;

      public header: String;

      private _lastDataRequest: any;

      constructor(private i18nService: any) {

         this.i18n = i18nService.getString;
      }

      public $onChanges() {
         this.populatePropertyData();
      }

      private populatePropertyData() {
         let self = this;

         if (!self.hostId || !self.iscsiPortBindingItem || !self.detailsRetriever) {
            self.loading = false;
            self.noDetailsAvailable = true;
            return;
         }

         self.loading = true;
         self.noDetailsAvailable = true;

         let viewDataPromise = self.detailsRetriever.retrieveData(
               self.hostId, self.iscsiPortBindingItem, self.isBoundPortBinding);

         self._lastDataRequest = viewDataPromise;

         viewDataPromise.then(function (viewData: IscsiPortBindingDetailsViewData) {
            if (viewDataPromise === self._lastDataRequest) {
               if (viewData && viewData.propertiesData) {
                  self.propertiesData = viewData.propertiesData;
                  self.header = viewData.viewTitle;
                  self.noDetailsAvailable = false;
               }
            }
         }).finally(function () {
            if (viewDataPromise === self._lastDataRequest) {
               self.loading = false;
            }
         });
      }
   }

   angular.module("com.vmware.vsphere.client.network")
         .component("iscsiPortBindingDetailsTab", new IscsiPortBindingDetailsTab());
}
