module hostprofile_ui {

   import ReadHostCustomizationsSpec =
         com.vmware.vsphere.client.hostprofile.data.h5.ReadHostCustomizationsSpec;
   import ManagedObjectReference = com.vmware.vim.binding.vmodl.ManagedObjectReference;
   import ValidateHostCustomizationsSpec =
         com.vmware.vsphere.client.hostprofile.data.h5.ValidateHostCustomizationsSpec;
   import IQService = angular.IQService;
   import IPromise = angular.IPromise;

   class HostCustomizationsPageComponentController implements angular.IController {

      public objectId: string;
      public targetType: string;
      public getValidationErrorsCallback: Function;
      public getClientValidationErrorsCallback: Function;
      public submitCallback: Function;
      public showGenericErrors: Function;
      public i18n: Function;
      public hasGenericErrors: boolean = false;
      public hostCustomizations: HostCustomizationItem[];
      public hostCustomizationsErrors: HostCustomizationError[] = [];
      public isLastReadOperationImport: boolean = false;
      public hostCustomizationsImportStatus: "success" | "warning" | "error" | undefined;
      public hostCustomizationsImportResultDetailsSignPostOptions: any;
      public importOverrideSignPostOptions: any;
      public hostCustomizationsImportWarningMessages: Array<string> = [];
      public hostCustomizationsImportErrorMessages: Array<string> = [];
      public validationData: any = {};
      public is65VcOrLater: boolean;
      private hasErrors: boolean = false;
      private hostCustomizationsFileInput: any;
      private hostCustomizationsFileReader: FileReader;

      static $inject = [
         "$scope",
         "hostCustomizationsService",
         "hostCustomizationsErrorsService",
         "$element",
         "$q",
         "i18nService",
         "vcService"
      ];

      constructor(private $scope: any,
            private hostCustomizationsService: HostCustomizationsService,
            private hostCustomizationsErrorsService: HostCustomizationsErrorsService,
            private $element: any,
            private $q: IQService,
            private i18nService: any,
            private vcService: any) {
         this.i18n = i18nService.getString;
      }

      $onInit(): void {
         this.hostCustomizationsFileInput = this.$element.find(".import-host-customizations-file-input");
         this.hostCustomizationsFileReader = new FileReader();

         this.submitCallback = this.onSubmit.bind(this);
         this.getValidationErrorsCallback = this.getValidationErrors.bind(this);
         this.getClientValidationErrorsCallback = this.getClientValidationErrors.bind(this);

         this.importOverrideSignPostOptions = {
            title: null,
            message: this.i18nService.getString(
                  "HostProfileUi",
                  "editHostCustomizationsWizard.importPropertyOverrideInfo")
         };

         // listen for fileInput changes
         this.hostCustomizationsFileInput.on(
               "change",
               (event: any) => this.$scope.$evalAsync(
                     () => this.onHostCustomizationsFileSelected(
                           event.target.files[0]
                     )
               )
         );

         $(this.hostCustomizationsFileReader).on(
               "load",
               (event: any) => this.$scope.$evalAsync(
                     () => this.onHostCustomizationsFileLoad((<any> event.target).result)
               )
         );

         // $scope $destroy
         this.$scope.$on("$destroy", () => {
            (<any> $).cleanData($(this.hostCustomizationsFileReader));
         });

         this.vcService.is65VcOrLater(this.objectId).then((is65VcOrLater: boolean) => {
            this.is65VcOrLater = is65VcOrLater;
         });

         this.initValidationData();
         this.getData();
      }

      private initValidationData(): void {
         this.validationData.objectId = this.objectId;
         this.validationData.loading = true;
         this.validationData.deferredHostSettingsSpec = {
            _type: "com.vmware.vsphere.client.hostprofile.data.h5.ValidateHostCustomizationsSpec",
            forceValidationOnHosts: <ManagedObjectReference[]>[],
            skipValidationOnHosts: <ManagedObjectReference[]>[],
            isNewlyAssociatedProfile: true,
            currentHostCustomizations: [],
            executeProfile: false,
            formattedCustomizations: ""
         };
      }

      public onImportHostCustomizationsButtonClick(): void {
         this.hostCustomizationsFileInput.click();
      }

      private onHostCustomizationsFileSelected(file: Blob): void {
         this.validationData.loading = true;

         this.hostCustomizationsFileInput.prop("value", "");

         // after this method finishes the onload is called internally
         this.hostCustomizationsFileReader.readAsText(file);
      }

      private onHostCustomizationsFileLoad(formattedHostCustomizations: string) {
         if (!formattedHostCustomizations) {
            this.validationData.loading = false;
            return;
         }

         this.getCustomizations(formattedHostCustomizations);
      }

      private onSubmit(): IPromise<boolean> {
         return this.hostCustomizationsService.updateHostCustomizations(
               this.validationData.objectId,
               this.validationData.hostProfileId,
               this.validationData.deferredHostSettingsSpec.currentHostCustomizations)
               .then(() => true);
      }

      private getValidationErrors(): IPromise<any> {
         if (this.validationData.loading) {
            return this.$q.resolve(false);
         }

         if (this.validationData.hostProfileId && this.validationData.objectId
               && this.hostCustomizations && !this.hasErrors) {
            this.validationData.loading = true;
            return this.hostCustomizationsErrorsService.getValidationErrors(this.validationData)
                  .then(() => this.$q.resolve(true),
                        this.handleValidationErrors.bind(this));
         }

         this.validationData.loading = false;
         return this.$q.resolve(false);
      }

      private getClientValidationErrors(): string[] {
         if (this.validationData.hostProfileId && this.validationData.objectId
               && this.hostCustomizations && !this.hasErrors) {
            return this.hostCustomizationsErrorsService.getClientValidationErrors(
                  this.validationData);
         }

         return [];
      }

      private handleValidationErrors(validationResult: CustomizationValidationResult): IPromise<any> {
         this.validationData.loading = false;

         this.hostCustomizationsErrors = validationResult.paramErrors;

         if (validationResult.invalidHosts.length > 0) {
            return this.hostCustomizationsErrorsService.showSkipValidationDialog(
                  this.validationData, validationResult.invalidHosts).then(
                  () => this.$q.resolve(true),
                  this.handleValidationErrors.bind(this)
            );
         }

         if (validationResult.genericErrors.length > 0) {
            return this.$q.resolve(validationResult.genericErrors);
         }

         return this.$q.resolve(false);
      }

      private populateHostCustomizations(data: any, isImportHostCustomizationsRead: boolean): void {
         this.validationData.loading = false;

         if (data && data.length > 0) {
            let result: any = data[0].result;
            if (result && result.length > 0) {
               this.hostCustomizations = data[0].result;
               this.validationData.deferredHostSettingsSpec.currentHostCustomizations =
                     this.hostCustomizations;
               this.validationData.
                     deferredHostSettingsSpec.forceValidationOnHosts =
                     this.hostCustomizationsService.getCustomizationsHosts(
                           this.hostCustomizations);
            }

            this.isLastReadOperationImport = isImportHostCustomizationsRead;
            this.hostCustomizationsImportResultDetailsSignPostOptions = undefined;
            this.hostCustomizationsImportStatus = undefined;
            this.hostCustomizationsImportErrorMessages = [];
            this.hostCustomizationsImportWarningMessages = [];
            this.hasErrors = false;
            this.showGenericErrors([]);

            let error: any = data[0].error;
            if (error) {
               let commonCustomizationErrorMessages: Array<string> =
                     this.hostCustomizationsErrorsService.getCustomizationsErrorMessages(error);
               this.showGenericErrors(commonCustomizationErrorMessages);

               this.hasGenericErrors = commonCustomizationErrorMessages && commonCustomizationErrorMessages.length > 0;

               if (isImportHostCustomizationsRead) {
                  let hostCustomizationsImportErrorAndWarningMessages: CustomizationsImportErrorAndWarningMessages =
                        this.hostCustomizationsErrorsService
                              .getCustomizationsImportErrorAndWarningMessages(error);

                  this.hostCustomizationsImportErrorMessages =
                        hostCustomizationsImportErrorAndWarningMessages.errorMessages;
                  this.hostCustomizationsImportWarningMessages =
                        hostCustomizationsImportErrorAndWarningMessages.warningMessages;

                  // todo: developer must convert markup style to correct component approach
                  // using raw markup is wrong due to encapsulation violations
                  // Therefore, use component style and ensure template injection will process
                  /*
                  <clr-alert bs1 [clr-alert-type]="'danger'" [clr-alert-closable]="false" [clr-alert-size-small]="true">
                     <clr-alert-item>
                        <span class="alert-text" ng-bind="message"></span>
                     </clr-alert-item>
                  </clr-alert>
                  */

                  let signPostHtmlMessage: string = "";
                  signPostHtmlMessage = _.reduce(
                        _.map(
                           /**
                            * unacceptable markup violation
                            */
                              this.hostCustomizationsImportErrorMessages, (message) =>
                                    `
                                    <div class="alert alert-danger alert-sm">
                                       <div class="alert-items">
                                       <div class="alert-item static">
                                          <span class="alert-text">${message}</span>
                                       </div>
                                       </div>
                                    </div>
                                    `
                        ),
                        (memo: string, message: string) => memo + message,
                        signPostHtmlMessage
                  );

                  signPostHtmlMessage = _.reduce(
                        _.map(
                           /**
                            * unacceptable markup violation
                            */
                              this.hostCustomizationsImportWarningMessages, (message) =>
                                    `
                                    <div class="alert alert-warning alert-sm">
                                       <div class="alert-items">
                                       <div class="alert-item static">
                                          <span class="alert-text">${message}</span>
                                       </div>
                                       </div>
                                    </div>
                                    `
                        ),
                        (memo: string, message: string) => memo + message,
                        signPostHtmlMessage
                  );


                  this.hostCustomizationsImportResultDetailsSignPostOptions = {
                     title: this.i18nService.getString(
                           "HostProfileUi",
                           "editHostCustomizationsWizard.importDetailsDialogTitle"
                     ),
                     message: signPostHtmlMessage
                  };
               }

               this.hasErrors = !_.isEmpty(commonCustomizationErrorMessages);
            } else {
               this.hasGenericErrors = false;
            }

            if (isImportHostCustomizationsRead) {
               if (!_.isEmpty(this.hostCustomizationsImportErrorMessages)) {
                  this.hostCustomizationsImportStatus = "error";
               } else if (!_.isEmpty(this.hostCustomizationsImportWarningMessages)) {
                  this.hostCustomizationsImportStatus = "warning";
               } else {
                  this.hostCustomizationsImportStatus = "success";
               }
            }
         }
      }

      private getCustomizations(formattedHostCustomizations?: string): void {
         if (this.validationData.hostProfileId) {
            let spec: ReadHostCustomizationsSpec = new ReadHostCustomizationsSpec();
            spec.hostProfile = this.validationData.hostProfileId;
            spec.isNewlyAssociatedProfile = false;
            spec.formattedHostCustomizations = formattedHostCustomizations;
            spec.currentHostCustomizations = this.hostCustomizations;

            let isImportHostCustomizationsRead: boolean = !!formattedHostCustomizations;

            this.hostCustomizationsService
                  .getHostCustomizations(this.validationData.objectId, spec)
                  .then((hostCustomizations: any) => this.populateHostCustomizations(
                        hostCustomizations, isImportHostCustomizationsRead
                  ));
         } else {
            this.validationData.loading = false;
         }
      }

      private getData(): void {
         this.hostCustomizationsService.getAssociatedHostProfile(
               this.validationData.objectId)
               .then((data: any) => {
                  this.validationData.hostProfileId
                        = <ManagedObjectReference> data.associatedHostProfile;
                  this.getCustomizations();
               });
      }
   }

   class HostCustomizationsPageComponent implements ng.IComponentOptions {
      public controller: any;
      public templateUrl: string;
      public bindings: any;

      constructor() {
         this.controller = HostCustomizationsPageComponentController;
         this.templateUrl =
               "hostprofile-ui/resources/hostprofile/customizations/hostCustomizationsPage.html";
         this.bindings = {
            objectId: "<",
            targetType: "<",
            showGenericErrors: "<",
            getValidationErrorsCallback: "=",
            getClientValidationErrorsCallback: "=",
            submitCallback: "="
         };
      }
   }

   angular.module("com.vmware.vsphere.client.hostprofile").component(
         "hostCustomizationsPage", new HostCustomizationsPageComponent());
}
