/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace storage_ui {

   export class FileCopyService {

      public static $inject = [
         "clarityModalService",
         "i18nService",
         "mutationService",
         "defaultUriSchemeUtil",
         "clarityConstants",
         "dataService",
         "datastoreConstants"];

      constructor(private clarityModalService: any,
                  private i18nService: any,
                  private mutationService: any,
                  private defaultUriSchemeUtil: any,
                  private clarityConstants: any,
                  private dataService: any,
                  private datastoreConstants: any) {
      }

      public copyFiles(datastoreId: string, dcRef: any, filesToCopy: any[],
            type: string, callback: Function): void {

         let modalOptions: any = {
            title: this.i18nService.getString("StorageUi", "pathPicker.defaultTitle"),
            contentTemplate: "storage-ui/resources/storage/views/manage/files/CopyFilesDialog.html",
            size: "lg",
            dialogData: {
               i18n: this.i18nService.getString.bind(this, "StorageUi"),
               datastores: [],
               selectedDestination: {
                  path: null,
                  name: null
               },
               hasSelectedDatastore: false,
               selectedDatastore: {
                  id: datastoreId,
                  type: null,
                  name: null
               },
               overwriteFiles: false
            },
            onSubmit: () => {
               let alerts = this.validateDialogData(modalOptions.dialogData);

               if (alerts.length) {
                  modalOptions.alerts = alerts;
                  return false;
               }

               let datastoreFileCopySpec: any = {
                  _type: type,
                  sourceDatacenter: dcRef,
                  destinationPath: modalOptions.dialogData.selectedDestination.path,
                  destinationDatastore: this.defaultUriSchemeUtil.getManagedObjectReference(
                        modalOptions.dialogData.selectedDatastore.id),
                  sourcePaths: [],
                  sourceFileTypes: [],
                  overwrite: modalOptions.dialogData.overwriteFiles,
                  waitTasksToComplete: true
               };

               _.forEach(filesToCopy, function (fileToCopy) {
                  datastoreFileCopySpec.sourcePaths.push(fileToCopy.path);
                  datastoreFileCopySpec.sourceFileTypes.push(fileToCopy.fileType);
               });

               this.mutationService.apply(datastoreId, type, datastoreFileCopySpec).then(function() {
                  if (callback) {
                     callback(modalOptions.dialogData.selectedDatastore.id,
                           modalOptions.dialogData.selectedDestination.path);
                  }
               });

               return true;
            }
         };

         this.clarityModalService.openOkCancelModal(modalOptions);

         this.loadData(datastoreId).then(
               (response: any) => {
                  modalOptions.dialogData.datastores = response["storage:datastoresInVc"];
               }
         );
      }

      private loadData(datastoreId:string): angular.IPromise<any>{
         let rootFolder = this.defaultUriSchemeUtil.getRootFolderFromVsphereObjectId(datastoreId);

         return this.dataService.getProperties(
               rootFolder, ["storage:datastoresInVc"]);
      }

      private validateDialogData(dialogData:any): string[] {
         let result: any[] = [];
         let alert: any = {
            type: this.clarityConstants.notifications.type.ERROR,
            text: null
         };

         if(!dialogData.hasSelectedDatastore) {
            alert.text = this.i18nService.getString("StorageUi", "pathPicker.noDatastoreSelected");
         } else if (!dialogData.selectedDestination.path) {
            alert.text = this.i18nService.getString("StorageUi", "pathPicker.noLocationSelected");
         } else if (dialogData.selectedDatastore.type.toUpperCase() === this.datastoreConstants.datastoreTypes.VSAN
               // root datastore folder is selected
               && dialogData.selectedDestination.name === dialogData.selectedDatastore.name) {
            alert.text = this.i18nService.getString("StorageUi", "pathPicker.vsanRootSelected");
         } else if (dialogData.selectedDatastore.type.toUpperCase() === this.datastoreConstants.datastoreTypes.VVOL
               // root datastore folder is selected
               && dialogData.selectedDestination.name === dialogData.selectedDatastore.name) {
            alert.text = this.i18nService.getString("StorageUi", "pathPicker.vvolRootSelected");
         }

         if (alert.text) {
            result.push(alert);
         }
         return result;
      }
   }

   angular.module("com.vmware.vsphere.client.storage")
         .service("fileCopyService", FileCopyService);

}
