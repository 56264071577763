namespace h5_vm {
   import IScope = angular.IScope;
   import IQService = angular.IQService;
   export class VmHardwareVirtualDiskLocationService {
      static $inject: string[] = ['$q',
         '$rootScope',
         'i18nService',
         'vmDatastoreLocationBrowserService',
         'defaultUriSchemeUtil',
         'managedEntityConstants',
         'authorizationService'];

      readonly STYLE_HAS_ERROR: string = 'has-error';
      readonly PRIVILEGE_DATASTORE_ALLOCATE_SPACE: string = 'Datastore.AllocateSpace';

      constructor(private $q: IQService,
                  private $rootScope: IScope,
                  private i18nService: any,
                  private vmDatastoreLocationBrowserService: any,
                  private defaultUriSchemeUtil: any,
                  private managedEntityConstants: any,
                  private authorizationService: any) {
      }

      public onLocationChange(selectedStorageItem: any,
                              vmStorageBrowserMetadata: VmStorageBrowserMetadata,
                              vmStorageProfileMetadata: VmStorageProfileMetadata,
                              vmId: any,
                              showStoragePods: boolean) {

         if (!this.isBrowseOption(selectedStorageItem)) {
            return this.$q.when({
                     selectedStorageItem: selectedStorageItem,
                     selectedStorageProfile: vmStorageProfileMetadata.selectedProfile
                  }
            );
         }

         return this.getStorageSelectorItems(vmId, vmStorageBrowserMetadata)
               .then((storageSelectorItems) => {
                  vmStorageBrowserMetadata.storageData.storageLocatorItemsData = storageSelectorItems;
                  return this.vmDatastoreLocationBrowserService.choose(
                        this.$rootScope, {
                           storageData: vmStorageBrowserMetadata.storageData,
                           storageProfilesData: vmStorageProfileMetadata,
                           disableSdrs: !showStoragePods
                        });
               });
      }

      public getErrorStyle(diskLocationErrorConfig: any) {
         return diskLocationErrorConfig.isVisible ? this.STYLE_HAS_ERROR : '';
      }

      public getErrorMessage(result: any, datastores: any, storageLocatorItemsData: any) :string {
         let message = "";
         let found = false;
         if(!this
               .isValid(result.selectedStorageItem)) {
            message = this.i18nService.getString('VmUi',
               'SelectDatastoreProvisioningPage.NoPermissionOnDatastore');
         }

         let rdmSupportedDatastore = this.getRdmSupportedDatastore(datastores, storageLocatorItemsData);

         if(!message) {
            rdmSupportedDatastore.forEach((disk: any) => {
               if(this.defaultUriSchemeUtil.compareIds(result.selectedStorageItem.storageObject.storageRef, disk.storageRef)) {
                  found = true;
               }
            });
         }
         if(!found) {
            let selectedObjectType: string = this.defaultUriSchemeUtil.getEntityType(result.selectedStorageItem.id);

            message = selectedObjectType === 'StoragePod'? this.i18nService.getString('VmUi', 'DiskConfig.RDMUnsupportedCluster')
                  : this.i18nService.getString('VmUi', 'DiskConfig.RDMUnsupported');
         }
         return message;

      }

      public getRdmSupportedDatastore(datastores: any, storageLocatorItemsData: any) {
         let rdmSupportedDatastore = _.filter(datastores, function(d: any) { return d.capability.rawDiskMappingsSupported;});
         let rdmSupportedStoragePods = _.filter(storageLocatorItemsData.storageLocatorItemsData, function(d: any) { return d.isRDMSupported;});
         let nonClusterDatastore = _.filter(storageLocatorItemsData.datastoreItems, function(d: any) { return d.parentStoragePod === null;});
         let datastore = _.filter(nonClusterDatastore, function (ds1: any) {
            return _.some(rdmSupportedDatastore, function (ds2: any) {
               return JSON.stringify(ds2.datastore.datastore) === JSON.stringify(ds1.storageRef);
            });
         });
         return _.union(datastore, rdmSupportedStoragePods);
      }

      public buildLocationOptions(selectedStorage?: any): any {
         const storeWithVmOption = {
            name: this.i18nService.getString('VmUi', 'DiskPage.StoreWithVM')
         };
         const browseDialogOption = {
            name: this.i18nService.getString('VmUi', 'DiskPage.Browse')
         };

         if (selectedStorage) {
            const selectedStorageOption = {
               name: selectedStorage.name,
               id: selectedStorage.id,
               storageObject: selectedStorage.storageObject
            };
            const model: any = {
               options: [storeWithVmOption,
                  selectedStorageOption,
                  browseDialogOption],
               selection: selectedStorageOption
            };

            return model;
         }

         return {
            options: [storeWithVmOption, browseDialogOption],
            selection: storeWithVmOption
         };
      }

      public isDifferent(storageOptionA: any, storageOptionB: any): boolean {
         return storageOptionA.id !== storageOptionB.id;
      }

      public isValid(selectedStorageOption: any) {
         const storageId = selectedStorageOption.id;
         const storageName = selectedStorageOption.name;
         const storeWithVmOption = this.i18nService
               .getString('VmUi', 'DiskPage.StoreWithVM');

         const browseDialogOptionName = this.i18nService
               .getString('VmUi', 'DiskPage.Browse');

         if (storageName === browseDialogOptionName) {
            return this.$q.when(true);
         }
         if (storageName === storeWithVmOption) {
            return this.$q.when(true);
         }

         const storageType = this.defaultUriSchemeUtil.getEntityType(storageId);
         if (storageType === this.managedEntityConstants.STORAGE_POD) {
            return this.$q.when(true);
         }

         return this.authorizationService.checkPrivileges(
               storageId,
               [this.PRIVILEGE_DATASTORE_ALLOCATE_SPACE])
               .then((privilegeStatuses: any[]) => {
                  const hasPriv = privilegeStatuses[0];
                  return this.$q.when(hasPriv);
               });
      };


      private isBrowseOption(selectedOption: any) {
         const browseDialogOptionName =
               this.i18nService.getString('VmUi', 'DiskPage.Browse');
         return selectedOption.name === browseDialogOptionName;
      }

      private getStorageSelectorItems(vmId: string, vmStorageBrowserMetadata: any): ng.IPromise<any> {
         if (vmStorageBrowserMetadata.storageData.storageLocatorItemsData) {
            return this.$q.when(vmStorageBrowserMetadata.storageData.storageLocatorItemsData);
         } else {
            return this.vmDatastoreLocationBrowserService.getStorageLocatorItems(vmId);
         }
      }
   }

   angular.module('com.vmware.vsphere.client.vm')
         .service('vmHardwareVirtualDiskLocationService', VmHardwareVirtualDiskLocationService);
}
