/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace dvs_ui {

   import ValidationResult = com.vmware.vise.core.model.ValidationResult;
   export class DvpgGeneralPropertiesPageValidator implements WizardPageValidator {

      public static $inject = [
            "i18nService",
            "mutationService",
            "networkUiConstants",
            "dvpgPropertiesService",
            "dvpgCreateWizardConstants",
            "$q"];

      constructor(private i18nService: any,
                  private mutationService: any,
                  private networkUiConstants: any,
                  private dvpgPropertiesService: any,
                  private wizardConstants: any,
                  private $q: any) {
      }

      public getValidationError(wizardScope: DvpgCreateWizardScope): string[] {
         let errors: string[] = [];

         if (wizardScope.model.portBinding ===
               this.wizardConstants.portBinding.EARLY_BINDING) {

            let numPortsError: string | null =
                  this.dvpgPropertiesService.getNumPortsError(
                        wizardScope.model.numPorts);

            if (numPortsError) {
               errors.push(numPortsError);
            }
         }

         switch(wizardScope.model.vlanType) {
            case this.networkUiConstants.Vlan.Type.VLAN: {
               let vlanIdError: string | null =
                     this.dvpgPropertiesService.getVlanIdError(wizardScope.model.vlanId);
               if (vlanIdError) {
                  errors.push(vlanIdError);
               }
               break;
            }
            case this.networkUiConstants.Vlan.Type.TRUNK: {
               return this.getTrunkVlanRanges(wizardScope);
            }
            case this.networkUiConstants.Vlan.Type.PRIVATE: {
               let error: string | void = this.getPvlanError(wizardScope);
               if (error) {
                  errors.push(error);
               }
               break;
            }
         }
         return this.$q.resolve(errors);
      }

      private getPvlanError(wizardScope: DvpgCreateWizardScope): string | void {
         if (wizardScope.model.showPvlanError) {
            return this.i18nService.getString(
                  "DvsUi", "dvpg.create.properties.vlan.type.private.noConfig");
         }
      }

      private getTrunkVlanRanges(wizardScope: DvpgCreateWizardScope): any {
         let errors: string[] = [];
         let trunkVlanRangesString: string = wizardScope.model.vlanTrunkRanges;
         wizardScope.wizardConfig.loading = true;

         return this.mutationService.validate(wizardScope.model.dvsId,
            "com.vmware.vsphere.client.h5.network.dvportgroup.model.DvPortgroupTrunkVlanSpec",
            {
               _type: "com.vmware.vsphere.client.h5.network.dvportgroup.model.DvPortgroupTrunkVlanSpec",
               numericRanges: trunkVlanRangesString.trim()
            })
            .then ((validationResult: ValidationResult) => {
               if (validationResult && validationResult.result) {
                  wizardScope.model.vlanTrunkNumericRanges = validationResult.result;
               } else {
                  errors.push(this.i18nService.getString(
                        "DvsUi", "dvpg.create.properties.vlan.type.trunk.error"));
               }
               wizardScope.wizardConfig.loading = false;
               return errors;
            }, function () {
               wizardScope.wizardConfig.loading = false;
               return errors;
            });
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .service("dvpgGeneralPropertiesPageValidator",
               DvpgGeneralPropertiesPageValidator);
}
