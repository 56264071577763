(function () {
   'use strict';
   angular.module('com.vmware.vsphere.client.cluster')
         .controller('FaultsDetailsViewController', FaultsDetailsViewController);

   FaultsDetailsViewController.$inject = ['$scope', 'i18nService',
         'drsFaultsViewService', 'drsFaultsService', 'helpService', 'clusterHelpConstants'];

   function FaultsDetailsViewController($scope, i18nService,
         drsFaultsViewService, drsFaultsService, helpService, clusterHelpConstants) {
      var self = this;

      self.detailsViewGridOptions = drsFaultsViewService.getDetailsViewGridOptions();
      self.drsTroubleshootHelpId = clusterHelpConstants.DRS_FAULT_TROUBLESHOOT_GUIDE_ID;

      self.faultDetailsTitle = i18nService.getString(
            'Common', 'drs.faultControl.details.drsState.headerLabel');

      $scope.$watch('masterDetailsViewContext.selectedItems', function(newValue) {
         if (newValue && newValue.length > 0) {
            setDetailsViewGridData(newValue);
         }
      });

      $scope.openHelpLink = function(contextId) {
         helpService.showHelpPage(contextId);
      };

      function setDetailsViewGridData(faultData) {
         var data = [];

         faultData[0].forEach(function(fault) {
            fault.details.forEach(function(detail) {
               var gridItem = drsFaultsService.getFaultDetailsData(fault.reason, detail);

               if (gridItem) {
                  data.push(gridItem);
               }
            });
         });

         self.detailsViewGridOptions.data = data;
      }
   }
})();
