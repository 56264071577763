module storage_ui {

   class MoveDatastoreToDialogController {

      public rootObjId: string;
      public entityToMoveDatastoreTo: string;
      private datastoreIds: Array<string>;
      private onLocationSelected: Function;
      private sourcesAndTargetIds: Array<string>;
      private loading: boolean;
      private names: any;

      public static $inject = ["$scope",
         "moveDatastoreToService",
         "defaultUriSchemeUtil",
         "dataService",
         "vuiConstants",
         "i18nService"];

      constructor(private $scope: any,
                  private moveDatastoreToService: any,
                  private defaultUriSchemeUtil: any,
                  private dataService: any,
                  private vuiConstants: any,
                  private i18nService: any) {
         this.loading = true;
         this.datastoreIds = $scope.modalOptions.dialogData.objectIds;
         if (!_.isEmpty(this.datastoreIds)) {
            this.rootObjId = this.datastoreIds[0];
         }

         $scope.modalOptions.onSubmit = () => {
            $scope.modalOptions.alerts = [];
            $scope.modalOptions.isAlertClosed = true;
            this.sourcesAndTargetIds.push(this.entityToMoveDatastoreTo);
            this.moveDatastoreToService.checkPrivileges(this.sourcesAndTargetIds)
                  .then((noPrivilegesIds: Array<string>) => {
                     if (!noPrivilegesIds || noPrivilegesIds.length === 0) {
                        moveDatastoreToService.moveDatastoreTo(
                              this.entityToMoveDatastoreTo, this.datastoreIds);
                        $scope.closeModal();
                     } else {
                        let message: string;
                        if (_.contains(noPrivilegesIds, this.entityToMoveDatastoreTo)) {
                           if (noPrivilegesIds.length === 1) {
                              message = this.i18nService
                                    .getString('StorageUi', 'moveTo.noPermissionsOnTarget');
                           } else {
                              message = this.i18nService
                                    .getString('StorageUi', 'moveTo.noPermissions');
                           }
                        } else {
                           message = this.i18nService
                              .getString('StorageUi', 'moveTo.noPermissionsOnSource');
                        }
                        this.$scope.modalOptions.alerts = [{
                           type: this.vuiConstants.validationBanner.type.ERROR,
                           text: message
                        }];
                        this.$scope.modalOptions.isAlertClosed = false;
                     }
                  });
         };

         this.onLocationSelected = (objectReference:any) => {
            this.entityToMoveDatastoreTo = objectReference;
         };

         this.getData();
      }

      private getData(): void {
         this.dataService.getPropertiesForObjects(this.datastoreIds, ['parent'])
               .then((data: any) => {
                  if (data) {
                     this.sourcesAndTargetIds = _.map(this.datastoreIds,
                           (datastoreId: string) => {
                              return this.defaultUriSchemeUtil.getVsphereObjectId(
                                    data[datastoreId].parent
                              );
                           });
                     this.sourcesAndTargetIds.push(...this.datastoreIds);
                  }
                  this.loading = false;
               });
      }
   }

   angular.module("com.vmware.vsphere.client.storage")
         .controller("moveDatastoreToDialogController", MoveDatastoreToDialogController);
}

