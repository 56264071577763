/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace dvs_ui {

   import DvPortgroupModel = com.vmware.vsphere.client.h5.network.dvs.addhost.model.DvPortgroupModel;
   import DvsAddHostHierarchicalListItem = com.vmware.vsphere.client.h5.network.dvs.addhost.hierarchicalList.DvsAddHostHierarchicalListItem;

   export class DvsSelectPhysicalAdaptersPageModel {

      /**
       * Contains all hierarchical grid items in the select physical adapters page.
       */
      public hosts: DvsAddHostTreeListItemWrapper[];

      public uplinkPortgroups: DvPortgroupModel[];

      public uplinkNames: [string /*port*/, boolean /*isLagPort*/][];

      public lags: string[];

      public isInitialized: boolean = false;

      /**
       * The generation number of this model. Increased each time the model is updated.
       * Needed to mark the page as incomplete.
       */
      public generationNumber: number = 0;
   }
}