/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace dvs_ui {
   import DvsPortMirroringDestinationPorts =
         com.vmware.vsphere.client.h5.network.dvs.portmirroring.model.DvsPortMirroringDestinationPorts;

   export class DvsPortMirroringRemoteSourceDestinationsViewComponent {
      public bindings: any;
      public controller: any;
      public templateUrl: string;

      constructor() {
         this.bindings = {
            selectedSession: "<",
            shouldRefresh: "<",
            onRefresh: "<"
         };
         this.controller = DvsPortMirroringRemoteSourceDestinationsViewController;
         this.templateUrl = "dvs-ui/resources/dvs/portmirroring/" +
         "dvsPortMirroringSourcesDestViewTemplate.html";
      }
   }

   class DvsPortMirroringRemoteSourceDestinationsViewController {

      static $inject: any = [
         "i18nService",
         "$scope",
         "$rootScope",
         "vuiConstants",
         "columnRenderersRegistry",
         "networkUtil",
         "dvsPortMirroringSourcesAndDestRefreshService"
      ];

      public selectedSession: string;

      public shouldRefresh: boolean;

      public onRefresh: Function;

      public tableEntries: any;

      public datagridOptions: any;

      constructor(private i18nService: any,
                  private $scope: any,
                  private $rootScope: any,
                  private vuiConstants: any,
                  private columnRenderersRegistry: any,
                  private networkUtil: any,
                  private dvsPortMirroringSourcesAndDestRefreshService: any) {

         this.createDatagridOptions();
      }

      public $onInit(): void {
         this.datagridOptions.data = this.tableEntries;
         this.createDatagridOptions();
      }

      public $onChanges(): void {
         if (this.shouldRefresh) {
            this.dvsPortMirroringSourcesAndDestRefreshService
                  .refreshDvsPortMirroringDestinationView(
                        this.$scope, this.$rootScope,
                        this.selectedSession, this.fillTableEntries.bind(this));
         }
      }

      public fillTableEntries(detailsData: DvsPortMirroringDestinationPorts) {
         if (detailsData) {
            this.tableEntries = detailsData.destinationPortData ?
                  detailsData.destinationPortData : [];
         } else {
            this.tableEntries = [];
         }
         this.$scope.portMirrorLoading = false;
         this.datagridOptions.data = this.tableEntries;
         this.onRefresh();
      }

      private createDatagridOptions(): void {
         this.datagridOptions = {
            columnDefs: this.createColumnDefinitions(),
            sortMode: this.vuiConstants.grid.sortMode.SINGLE,
            selectionMode: this.vuiConstants.grid.selectionMode.SINGLE,
            selectedItems: [],
            data: this.tableEntries,
            resizable: true,
            height: "100%",
            pageConfig: {
               hidePager: true
            }
         };
      }

      private createColumnDefinitions(): any {
         return [
            {
               displayName:
                     this.i18nService.getString("DvsUi",
                           "SessionDvPortSourceList.uplink"),
               field: "portKey",
               width: "150px"
            },
         ];
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .component("dvsPortMirroringRemoteSourceDestinationsView",
               new DvsPortMirroringRemoteSourceDestinationsViewComponent());
}