/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace dvs_ui {

   import DvpgMacQualifierModel = com.vmware.vsphere.client.h5.network.dvportgroup.trafficfilter.model.DvpgMacQualifierModel;
   import DvpgMacAddressModel = com.vmware.vsphere.client.h5.network.dvportgroup.trafficfilter.model.DvpgMacAddressModel;
   import DvpgProtocolModel = com.vmware.vsphere.client.h5.network.dvportgroup.trafficfilter.model.DvpgProtocolModel;

   export class DvpgMacQualifierValidator {

      public static $inject = [
         "i18nService",
         "dvpgQualifierValidatorService",
         "dvpgTrafficFilterConstants"
      ];

      constructor(private i18nService: any,
                  private validatorService: any,
                  private trafficFilterConstants: any) {
      }

      public getValidationErrors(model: DvpgMacQualifierModel): string[] {
         let errors: string [] = [];

         if (!model) {
            return errors;
         }

         // 1. Protocol errors
         let protocolError: string = this.getProtocolError(model.protocol);

         if (protocolError !== this.trafficFilterConstants.EMPTY) {
            errors.push(protocolError);
         }

         // 2. VLAN
         let vlanError: string = this.getVlanError(model.vlanId, model.vlanOperation);

         if (vlanError !== this.trafficFilterConstants.EMPTY) {
            errors.push(vlanError);
         }

         // 3. Source and destination MAC addresses and wildcards
         let sourceMacError: string =
               this.getMacAddressError(model.sourceMacAddress, true/*isSource*/);
         let sourceWildcardMaskError: string =
               this.getWildcardMaskError(model.sourceMacAddress, true/*isSource*/);

         let destinationMacError: string =
               this.getMacAddressError(model.destinationMacAddress, false/*isSource*/);
         let destinationWildcardMaskError: string =
               this.getWildcardMaskError(model.destinationMacAddress, false/*isSource*/);

         if (sourceMacError !== this.trafficFilterConstants.EMPTY) {
            errors.push(sourceMacError);
         }

         if (sourceWildcardMaskError !== this.trafficFilterConstants.EMPTY) {
            errors.push(sourceWildcardMaskError);
         }

         if (destinationMacError !== this.trafficFilterConstants.EMPTY) {
            errors.push(destinationMacError);
         }

         if (destinationWildcardMaskError !== this.trafficFilterConstants.EMPTY) {
            errors.push(destinationWildcardMaskError);
         }

         return errors;
      }

      public getProtocolError(protocolModel: DvpgProtocolModel): string {

         if (!protocolModel ||
               protocolModel.operation === this.trafficFilterConstants.operator.ANY) {
            return this.trafficFilterConstants.EMPTY;
         }

         let protocol: number;

         if (protocolModel.type === this.trafficFilterConstants.protocol.CUSTOM) {
            protocol = parseInt(protocolModel.customValue, 16);
         } else {
            protocol = protocolModel.type;
         }

         if (isNaN(protocol) ||
               protocol < this.trafficFilterConstants.MIN_PROTOCOL_HEX_VALUE ||
               protocol > this.trafficFilterConstants.MAX_PROTOCOL_HEX_VALUE) {

            return this.i18nService.getString(
                  "DvsUi", "trafficFilter.ruleDialog.invalidProtocolHex");
         }

         return this.trafficFilterConstants.EMPTY;
      }

      public getVlanError(vlanId: string,
            vlanOperation: number): string {

         let error: string = this.trafficFilterConstants.EMPTY;

         if (vlanOperation === this.trafficFilterConstants.operator.ANY) {
            return error;
         }

         if (!this.validatorService.isValidInteger(
                     vlanId,
                     this.trafficFilterConstants.vlan.MIN,
                     this.trafficFilterConstants.vlan.MAX)) {

            return this.i18nService.getString(
                  "DvsUi", "trafficFilter.ruleDialog.invalidVlan");
         }

         return error;
      }

      public getMacAddressError(macAddressModel: DvpgMacAddressModel,
            isSourceAddress: boolean): string {

         let error: string = this.trafficFilterConstants.EMPTY;

         if (!macAddressModel ||
               macAddressModel.operation === this.trafficFilterConstants.operator.ANY) {
            return error;
         }

         let macAddress: string =
               macAddressModel.macAddress &&
                     macAddressModel.macAddress.length > 0 ?
                     macAddressModel.macAddress.trim() : macAddressModel.macAddress;

         if (!this.validatorService.isValidMac(macAddress) ||
               macAddress === this.trafficFilterConstants.EMPTY_MAC_ADDRESS) {
            return isSourceAddress
                  ? this.i18nService.getString("DvsUi",
                        "trafficFilter.ruleDialog.invalidSourceMac")
                  : this.i18nService.getString("DvsUi",
                        "trafficFilter.ruleDialog.invalidDestinationMac");
         }

         if (this.validatorService.isMulticastOrBroadcastAddress(macAddress) &&
               isSourceAddress) {
            return this.i18nService.getString(
                  "DvsUi", "trafficFilter.ruleDialog.invalidSourceMacFirstHex");
         }

         return error;
      }

      public getWildcardMaskError(macAddressModel: DvpgMacAddressModel,
            isSourceAddress: boolean): string {

         let error: string = this.trafficFilterConstants.EMPTY;

         if (!macAddressModel ||
               macAddressModel.operation === this.trafficFilterConstants.operator.ANY ||
               (macAddressModel.operation !==
                     this.trafficFilterConstants.operator.MATCHES &&
               macAddressModel.operation !==
                     this.trafficFilterConstants.operator.DOES_NOT_MATCH)) {
            return error;
         }

         let wildcardMask: string =
               macAddressModel.wildcardMask &&
               macAddressModel.wildcardMask.length > 0 ?
                     macAddressModel.wildcardMask.trim() : macAddressModel.wildcardMask;

         if (!this.validatorService.isValidMac(wildcardMask)) {
            return isSourceAddress
                  ? this.i18nService.getString(
                        "DvsUi", "trafficFilter.ruleDialog.invalidSourceMask")
                  : this.i18nService.getString(
                        "DvsUi", "trafficFilter.ruleDialog.invalidDestinationMask");
         }

         return error;
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .service("dvpgMacQualifierValidator", DvpgMacQualifierValidator);
}
