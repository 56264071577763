/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace dvs_ui {

   import IPromise = angular.IPromise;
   import ValidationCheckResult = com.vmware.vsphere.client.dvs.api.featureupgrade.ValidationCheckResult;
   export class DvsPrerequisitesPageValidator {

      public static $inject = [
            "i18nService",
            "$q",
            "dvsPrerequisitesValidationService",
            "clarityModalService"];

      constructor(private i18nService: any,
                  private $q: any,
                  private dvsPrerequisitesValidationService: any,
                  private clarityModalService: any) {
      }

      public getValidationResult(
            prerequisiteChecks: ValidationCheckResult[],
            model: any): string[] | boolean {

         let overallUpgradeStatus: string =
               this.dvsPrerequisitesValidationService.getPrerequisitesOverallStatus(
                     prerequisiteChecks);

         if (overallUpgradeStatus === this.i18nService.getString(
                     "DvsUi", "PrerequisitesPage.overallStatus.fail")) {
            return this.$q.resolve([this.i18nService.getString(
                  "DvsUi", "PrerequisitesPage.unmetPrerequisites")]);
         }
         return this.$q.resolve(
               this.showValidationWarning(prerequisiteChecks, model));
      }

      public showValidationWarning(prerequisiteChecks: ValidationCheckResult[],
            model: any): string[] | boolean {

         if (model.prerequisiteWarningShown) {
            return [];
         }

         let overallUpgradeStatus: string = this.dvsPrerequisitesValidationService
               .getPrerequisitesOverallStatus(prerequisiteChecks);

         if (overallUpgradeStatus === this.i18nService.getString(
                     "DvsUi", "PrerequisitesPage.overallStatus.warn")) {

            let deferred: any = this.$q.defer();

            let modalOptions: any = {
               size: "md",
               title: this.i18nService.getString(
                     "DvsUi", "PrerequisitesPage.confirmation.title"),
               defaultButton: "submit",
               modalClass: "dvs-upgrade-warning-modal",
               alerts: [],
               dialogData: {
                  message: this.i18nService.getString(
                        "DvsUi", "PrerequisitesPage.confirmation.text")
               },
               contentTemplate: "dvs-ui/resources/dvs/common/component/" +
                     "dvsUpgradeWarningDialog.html",
               preserveNewlines: true,
               clarityIcon: {
                  shape: "warning",
                  class: "is-warning",
                  size: 32
               },
               onSubmit : () => {
                  deferred.resolve(true);
                  model.prerequisiteWarningShown = true;
                  return true;
               },
               onCancel: () => {
                  deferred.resolve(false);
               }
            };

            this.clarityModalService.openOkCancelModal(modalOptions);

            return deferred.promise.then((result: any) => {
               return result;
            });
         } else {
            return [];
         }
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .service("dvsPrerequisitesPageValidator", DvsPrerequisitesPageValidator);
}
