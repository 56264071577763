angular.module('com.vmware.vsphere.client.host').service('connectInfoParser', [
   function() {
      return {
         parse: function(connectInfo) {
            var parsedConnectInfo = {};
            parsedConnectInfo.connectInfo = connectInfo;
            parsedConnectInfo.name = connectInfo.host.config.name;
            parsedConnectInfo.vendor = connectInfo.host.hardware.vendor;
            parsedConnectInfo.model = connectInfo.host.hardware.model;
            parsedConnectInfo.version = connectInfo.host.config.product.fullName;
            parsedConnectInfo.vms = _.map(connectInfo.vm, function(vm) {
               return vm.config.name;
            });
            parsedConnectInfo.networks = _.map(connectInfo.network, function(network) {
               return network.summary.name;
            });
            parsedConnectInfo.datastores = _.map(connectInfo.datastore, function(datastore) {
               return datastore.summary.name;
            });
            parsedConnectInfo.serverIp = connectInfo.serverIp;
            parsedConnectInfo.hostAccessManagerAvailable =
               !!connectInfo.capability && connectInfo.capability.hostAccessManagerSupported;
            return parsedConnectInfo;
         }
      };
   }]
);