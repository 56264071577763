/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace dvs_ui {

   angular.module("com.vmware.vsphere.client.dvs").constant("dvpgTrafficFilterConstants", {
      properties: {
         DVPG_NAME : "name",
         DVPG_DVS : "config/distributedVirtualSwitch",
         TRAFFIC_FILTER_PROPERTY : "dvpg:dvPortgroupTrafficFilter",
         TRAFFIC_RULE_PROPERTY : "trafficRuleModel",
         DVS_PORT_TRAFFIC_FILTER_PROPERTY: "dvsport:portTrafficFilterData",
         DVPG_PORT_TRAFFIC_FILTER_PROPERTY: "dvportgroupport:portTrafficFilterData",
         DVS_PORT_EDIT_TRAFFIC_FILTER_PROPERTY: "dvsport:portEditTrafficRuleData",
         DVPG_PORT_EDIT_TRAFFIC_FILTER_PROPERTY: "dvportgroupport:portEditTrafficRuleData"
      },
      privileges: {
         DVPG_MODIFY: "DVPortgroup.Modify"
      },
      direction: {
         BOTH: "both",
         EGRESS: "incomingPackets",
         INGRESS: "outgoingPackets"
      },
      action: {
         ALLOW: "allow",
         DROP: "drop",
         TAG: "tag"
      },
      tag: {
         MIN_COS_TAG: 0,
         MAX_COS_TAG: 7,
         MIN_DSCP_TAG: 0,
         MAX_DSCP_TAG: 63,
         MIN_MAX_DSCP_TAG: "0 - 63",
         MIN_MAX_COS_TAG: "0 - 7"
      },
      addressType: {
         IPV4: "ipv4",
         IPV6: "ipv6",
         MIXED: "mixed"
      },
      mac: {
         IPV4_PROTOCOL_HEX_VALUE: 0x0800,
         IPV6_PROTOCOL_HEX_VALUE: 0x86DD,
         ARP_PROTOCOL_HEX_VALUE: 0x0806,
         MIN_PROTOCOL_HEX_VALUE: 0x0000,
         MAX_PROTOCOL_HEX_VALUE: 0xFFFF
      },
      vlan: {
         MIN: 0,
         MAX: 4094,
         CUSTOM: -1
      },
      operator: {
         ANY: 0,
         IS: 1,
         IS_NOT: 2,
         IN_RANGE: 3,
         NOT_IN_RANGE: 4,
         MATCHES: 5,
         DOES_NOT_MATCH: 6,
      },
      protocol: {
         ICMP: 1,
         ICMP6: 58,
         TCP: 6,
         UDP: 17,
         CUSTOM: 0
      },
      systemTraffic: {
         FT: "faultTolerance",
         HBR: "hbr",
         ISCSI: "iSCSI",
         MANAGEMENT: "management",
         NFS: "nfs",
         VM: "virtualMachine",
         VMOTION: "vmotion",
         VSAN: "vsan",
         VDP: "vdp"
      },
      spec: {
         DVPG_EDIT_TRAFFIC_RULES_SPEC: "com.vmware.vsphere.client.h5.network.dvportgroup.trafficfilter.DvpgEditTrafficFilterSpec",
         DVPG_CLONE_TRAFFIC_RULES_SPEC: "com.vmware.vsphere.client.h5.network.dvportgroup.trafficfilter.DvpgCloneTrafficRulesSpec",
         DVPG_ADD_TRAFFIC_RULE_SPEC: "com.vmware.vsphere.client.h5.network.dvportgroup.trafficfilter.DvpgAddTrafficRuleSpec",
         DVPG_EDIT_TRAFFIC_RULE_SPEC: "com.vmware.vsphere.client.h5.network.dvportgroup.trafficfilter.DvpgEditTrafficRuleSpec",
         DVPG_REMOVE_TRAFFIC_RULE_SPEC: "com.vmware.vsphere.client.h5.network.dvportgroup.trafficfilter.DvpgRemoveTrafficRuleSpec",
         DVS_PORT_EDIT_TRAFFIC_RULES_SPEC: "com.vmware.vsphere.client.h5.network.dvs.ports.model.DvsPortEditTrafficFilterSpec",
         DVS_PORT_ADD_TRAFFIC_RULE_SPEC: "com.vmware.vsphere.client.h5.network.dvs.ports.model.DvsPortAddTrafficRuleSpec",
         DVS_PORT_EDIT_TRAFFIC_RULE_SPEC: "com.vmware.vsphere.client.h5.network.dvs.ports.model.DvsPortEditTrafficRuleSpec",
         DVS_PORT_REMOVE_TRAFFIC_RULE_SPEC: "com.vmware.vsphere.client.h5.network.dvs.ports.model.DvsPortRemoveTrafficRuleSpec"
      },
      EMPTY: "",
      SEQUENCE_NUMBER: 10,
      MAX_RULE_NAME_LENGTH: 255,
      MAX_PORT_NUMBER: 65535,
      IPV4_ADDRESS_MAX_PREFIX: 32,
      IPV6_ADDRESS_MAX_PREFIX: 128,
      IP_ADDRESS_MIN_PREFIX: 1,
      MIN_PROTOCOL_VALUE: 0,
      MAX_PROTOCOL_VALUE: 255,
      MIN_PROTOCOL_HEX_VALUE: 0x0000,
      MAX_PROTOCOL_HEX_VALUE: 0xFFFF,
      EMPTY_MAC_ADDRESS: "00:00:00:00:00:00"
   });
}
