/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace dvs_ui {

   import ValidationCheckResult = com.vmware.vsphere.client.dvs.api.featureupgrade.ValidationCheckResult;

   export class DvsPrerequisitesPageComponent {

      public bindings: any;
      public controller: any;
      public templateUrl: string;

      constructor() {
         this.bindings = {
            prerequisitesValidationData: "<"
         };

         this.controller = DvsPrerequisitesPageController;
         this.templateUrl =
               "dvs-ui/resources/dvs/common/component/" +
               "dvsPrerequisitesPageComponentTemplate.html";
      }
   }

   class DvsPrerequisitesPageController {

      static $inject = [
            "vuiConstants",
            "i18nService",
            "columnRenderersRegistry",
            "dvsPrerequisitesValidationService",
            "dvsUpgradeLacpWizardConstants"
      ];

      public prerequisitesValidationData: ValidationCheckResult[];

      private datagridOptions: any;
      private gridDescription: any;
      private splitterOptions: any;

      private iconTextRenderer: any;

      private prerequisiteCheckDetailsHeader: string;
      private prerequisiteCheckDetails: string;
      private overallCheckStatus: string;
      private showOverallStatusWarning: boolean;
      private showOverallStatusFail: boolean;
      private showOverallStatus: boolean;
      private checkAffectedEntities: string[];

      constructor(private vuiConstants: any,
                  private i18nService: any,
                  private columnRenderersRegistry: any,
                  private dvsPrerequisitesValidationService: any,
                  private dvsUpgradeLacpWizardConstants: any) {
         this.gridDescription = this.i18nService.getString(
               "DvsUi", "PrerequisitesPage.description");
         this.datagridOptions = {
            height: "100%",
            pageConfig: {
               hidePager: true
            },
            columnDefs: this.buildColumnDefinitions(),
            sortMode: this.vuiConstants.grid.sortMode.SINGLE,
            selectionMode: this.vuiConstants.grid.selectionMode.SINGLE,
            selectedItems: [],
            resizable: true,
            data: [],
            actionBarOptions: {
               actions: []
            },
            onChange: this.onSelectionChange.bind(this)
         };
         this.prerequisiteCheckDetailsHeader = this.i18nService.getString(
               "DvsUi", "PrerequisitesPage.validationDetails");
      }

      public $onInit(): void {
         this.setOverallValidationStatus();

         this.splitterOptions = {
            orientation: this.vuiConstants.splitter.orientation.VERTICAL,
            panes: [{size: '65%', min: '120px'}, {size: '35%', min: '50px'}]
         };

         this.iconTextRenderer =
               this.columnRenderersRegistry.getColumnRenderer('icon-text');
      }

      public $onChanges(): void {
         this.datagridOptions.data = this.prerequisitesValidationData ?
               this.prerequisitesValidationData : [];
      }

      private setOverallValidationStatus(): void {
         this.prerequisiteCheckDetails = this.i18nService.getString(
               "DvsUi", "PrerequisitesPage.overallStatus.label");
         this.overallCheckStatus =
               this.dvsPrerequisitesValidationService.getPrerequisitesOverallStatus(
                     this.prerequisitesValidationData);
         this.showOverallStatusWarning =
               this.overallCheckStatus === this.i18nService.getString(
                     "DvsUi", "PrerequisitesPage.overallStatus.warn");
         this.showOverallStatusFail =
               this.overallCheckStatus === this.i18nService.getString(
                     "DvsUi", "PrerequisitesPage.overallStatus.fail");
         this.checkAffectedEntities = [];
         this.showOverallStatus = true;
      }

      private buildColumnDefinitions() {
         return [{
            displayName: this.i18nService.getString(
                  "DvsUi", "PrerequisitesPage.checkNameColumn"),
            field: "checkName",
            width: "310px"
         }, {
            displayName: this.i18nService.getString(
                  "DvsUi", "PrerequisitesPage.checkResultColumn"),
            width: "100%",
            template: (item: ValidationCheckResult): string =>  {
               let itemStatus: String;
               switch(item.type) {
                  case this.dvsUpgradeLacpWizardConstants.validationResult.FAIL:
                     itemStatus = this.i18nService.getString(
                         "DvsUi", "PrerequisitesPage.overallStatus.fail");
                     return `<span><clr-icon shape='error' size='24' ` +
                           `class='is-error dvs-upgrade-prerequisites-error'></clr-icon>` +
                           `<span class='vertical-align-cell'>${itemStatus}</span></span>`;
                  case this.dvsUpgradeLacpWizardConstants.validationResult.WARNING:
                     itemStatus = this.i18nService.getString(
                           "DvsUi", "PrerequisitesPage.overallStatus.warn");
                     return `<span><span class='vsphere-icon-status-warning'></span>` +
                           `<span class='vertical-align-cell'>${itemStatus}</span></span>`;
                  default: {
                     itemStatus = this.i18nService.getString(
                           "DvsUi", "PrerequisitesPage.overallStatus.pass");
                     return `<span><span class='vsphere-icon-status-ok'></span>` +
                           `<span class='vertical-align-cell'>${itemStatus}</span></span>`;
                  }
               };
            }
         }];
      }

      public onSelectionChange(items: ValidationCheckResult[]): void {
         if (items && items.length > 0) {
            let validationResult: ValidationCheckResult = items[0];
            this.prerequisiteCheckDetails = validationResult.message;
            this.checkAffectedEntities = validationResult.affectedEntities;
            this.showOverallStatus = false;
         } else {
            this.setOverallValidationStatus();
         }
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .component("dvsPrerequisitesPage", new DvsPrerequisitesPageComponent());
}
