namespace h5_vm {

import VirtualDiskRecord = h5_vm.VirtualDiskRecord;


export interface VmMigrateStorageAdvancedSummary {
   vmName: string;
   diskStorage: Array<{
      diskLabel: string,
      diskStorage: string,
      diskProfile?: string,
      diskReplicationGroup?: string,
      storageUrn?: string
      parentStoragePodUrn?: string
   }>;
   vmId?: string;
}

export class VmMigrateStorageAdvancedSummaryBuilder {
   static $inject: string[] = [
      'i18nService',
      'diskFormatService',
      'storageProfileService',
      'defaultUriSchemeUtil',
      'spbmReplicationGroupInfoService'
   ];

   private _entityNameMap: { [id: string]: string };
   private _vmStorageRecommendationsService: any;
   private _selectedRecommendationDiskNames: { [vmId: string]: { [diskId: string]: string } };
   private _topRecommendations: { [podId: string]: { [vmId: string]: any } };

   constructor(private i18nService: any,
               private diskFormatService: any,
               private storageProfileService: {
                  DEFAULT_ID: string
               },
               private defaultUriSchemeUtil: any,
               private spbmReplicationGroupInfoService: any) {
   }

   public build(advancedSummaryData: any,
                entityNameMap: { [id: string]: string },
                vmStorageRecommendationsService: any): Array<VmMigrateStorageAdvancedSummary> {
      let result: Array<VmMigrateStorageAdvancedSummary> = [];
      this._entityNameMap = entityNameMap;
      this._vmStorageRecommendationsService = vmStorageRecommendationsService;
      this._selectedRecommendationDiskNames = this.buildStorageNameByDiskMap();
      this._topRecommendations = this._vmStorageRecommendationsService.getTopRecommendations();
      _.forEach(_.keys(advancedSummaryData.virtualDisksByVm), (vmId: string) => {

         let vmSummaryData: VmMigrateStorageAdvancedSummary = {
            vmId: vmId,
            vmName: entityNameMap[vmId],
            diskStorage: []
         };

         _.forEach(advancedSummaryData.virtualDisksByVm[vmId],
               (disk: VirtualDiskRecord) => {
                  vmSummaryData.diskStorage.push({
                     diskLabel: disk.label,
                     diskStorage: this.getStorageLabel(vmId, disk),
                     diskProfile: disk.profile &&
                     (disk.profile.id !== this.storageProfileService.DEFAULT_ID) ?
                           disk.profile.label : undefined,
                     diskReplicationGroup: disk.replicationGroup
                           ? this.spbmReplicationGroupInfoService.getReplicationGroupName(
                                 disk.replicationGroup)
                           : "",
                     storageUrn: disk.storage,
                     parentStoragePodUrn: disk.parentStoragePod
                  });
               });

         result.push(vmSummaryData);
      });
      return result;
   }

   private getStorageLabel(vmId: string, disk: any): string {
      let label: string;
      if (!this._selectedRecommendationDiskNames[vmId] || !this._selectedRecommendationDiskNames[vmId][disk.key]) {
         // If a recommendation is not available
         label = this.getString(
               "MigrationWizard.readyPage.locationNonSdrs",
               disk.storage ? this._entityNameMap[disk.storage] : "",
               this.buildDiskFormatName(disk));
         return label;
      }

      let localizationStr = this.isTopRecommendation(vmId, disk)
            ? "MigrationWizard.readyPage.locationSdrsAuto" : "MigrationWizard.readyPage.locationSdrsManual";
      let podUrn = disk.parentStoragePod || disk.storage;
      label = this.getString(
            localizationStr,
            this._entityNameMap[podUrn],
            this.getString("MigrationWizard.readyPage.datastoreFormatValue", this._selectedRecommendationDiskNames[vmId][disk.key]),
            this.buildDiskFormatName(disk));

      return label;
   }

   private isTopRecommendation(vmId: string, disk: any) {
      let podUrn: string  = disk.parentStoragePod || disk.storage;
      let topRecommendationKeyForDisk = this._topRecommendations[podUrn][vmId].key;
      let selectedRecommendations = this._vmStorageRecommendationsService.getSelectedRecommendationsByVm();
      let isTopRecommendation = _.some(selectedRecommendations[vmId], (rec: any) => {
         return rec.key === topRecommendationKeyForDisk;
      });
      return isTopRecommendation;
   }

   private buildStorageNameByDiskMap(): { [vmId: string]: { [diskId: string]: string } } {
      let diskNameMap: { [vmId: string]: { [diskId: string]: string } } = {};
      _.each(this._vmStorageRecommendationsService.getSelectedRecommendationsByVm(), (recommendations: any, vmId: string) => {
         if (!diskNameMap[vmId]) {
            diskNameMap[vmId] = {};
         }
         _.each(recommendations, (recommendation: any) => {
            _.each(recommendation.action, (action: any) => {
               if (!action.relocateSpec.disk) {
                  // It is the config file
                  let datastoreId = this.defaultUriSchemeUtil.getVsphereObjectId(action.relocateSpec.datastore);
                  diskNameMap[vmId]["_configFile"] = this._entityNameMap[datastoreId];
                  return;
               }
               _.each(action.relocateSpec.disk, (disk: any) => {
                  let datastoreId = this.defaultUriSchemeUtil.getVsphereObjectId(disk.datastore);
                  diskNameMap[vmId][disk.diskId] = this._entityNameMap[datastoreId];
               });
            });
         });
      });

      return diskNameMap;
   }

   private buildDiskFormatName(disk: VirtualDiskRecord): string {
      if (disk.diskFormat) {
         return this.getString(
               "MigrationWizard.readyPage.diskFormatValue", disk.diskFormat.label);
      }

      // Lack of specific disk format means "same as source".
      if (disk.key !== "_configFile") {
         return this.getString(
               "MigrationWizard.readyPage.diskFormatValue",
               this.diskFormatService.diskFormats.sameAsSource.name);
      }

      // For VMX files disk format does not make sense.
      return "";
   }

   private getString(key: string, ...params: string[]): string {
      return this.i18nService.getString("VmUi", key, ...params) as string;
   };
} // end of migrateStorageSummaryBuilder

angular.module("com.vmware.vsphere.client.vm")
      .service("vmMigrateStorageAdvancedSummaryBuilder", VmMigrateStorageAdvancedSummaryBuilder);

} // end of h5_vm namespace
