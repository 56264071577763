/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace h5_vm {

   import IPromise = angular.IPromise;
   import ManagedObjectReference = com.vmware.vim.binding.vmodl.ManagedObjectReference;

   export interface PrefetchedNetwork {
      name: string;
      id: string;
      dvsUuid: string;
      opaqueNetworkType: string;
      portgroupKey: string;
   }

   export interface PrefetchedNetworksData {
      recentNetworks: PrefetchedNetwork[];
      networkMatchesCount: number;
   }

   export interface BasicViewListItem {
      index: number;
      sourceNetworkId: string;
      networkName: string;
      _attachedVmNamesById: {[id: string]: string};
      _attachedAdapters: any[];
      usedBy: () => string;
      destinationNetworkName: string;
      destinationNetworkRef: ManagedObjectReference;
      opaqueNetworkType: string;
      adapterMatches: any[];
   }

   export interface AdvancedViewListItem {
      index: number;
      vmName: string;
      sourceAdapterLabel: string;
      sourceAdapter: any;

      sourceNetworkId: string;
      sourceNetworkName: string;

      destinationNetworkName: string;
      destinationNetworkRef: ManagedObjectReference;

      sourceVm: ManagedObjectReference;
   }

   export class VmMigrateSelectNetworkService {

      private prefetchedNetworksBasic: PrefetchedNetwork[];
      private prefetchedNetworksAdvanced: PrefetchedNetwork[];
      private isAutoSelectionMessageAdded: boolean;
      private isSelectionPerformed: boolean;
      private basicViewData: BasicViewListItem[];
      private advancedViewData: AdvancedViewListItem[];
      private compatibilityMessages: any[];

      private readonly DESTINATION_NETWORKS_FOR_VMS = "destinationNetworksForMigrateVms";
      private readonly MAX_PREFETCHED_NETWORKS_COUNT = 10;

      constructor(
            private $q: any,
            private i18nService: {
               getString: (bundle: string, key: string, ...interpolations: string[]) => string
            },
            private dataService: any,
            private dataServiceUtil: any,
            private defaultUriSchemeUtil: {
                  getVsphereObjectId: (resourceObject: any) => string,
                  getManagedObjectReference: (objectId: string) => ManagedObjectReference,
                  getManagedObjectReferences: (objectIds: string[]) => ManagedObjectReference[],
                  compareIds: (id1: any, id2: any) => boolean,
            },
            private networkSelectorDialogService: any,
            private migrationService: any,
            private compatibilityResultsPresenter: any,
            private managedEntityConstants: any,
            private networkSelectorPrefetchingService: any,
            private networkPageViewModes: {
               BASIC: string,
               ADVANCED: string
            },
            private migrationWizardManager: any,
            private pageModel: MigrateSelectNetworkPageModel,
            private validationLoadingHandlerFn: any,
      ) {
         this.migrationWizardManager = migrationWizardManager;
         this.prefetchedNetworksBasic = pageModel
               .getSelectNetworkPagePrefechedNetworks().basic;
         this.prefetchedNetworksAdvanced = pageModel
               .getSelectNetworkPagePrefechedNetworks().advanced;
         this.isAutoSelectionMessageAdded = false;
         this.isSelectionPerformed = false;
         this.basicViewData = [];
         this.advancedViewData = [];
         this.compatibilityMessages = [];

         this.pageModel = pageModel;
      }


      public getTotalPrefetchedNetworksCount(): number {
         return this.pageModel
               .getSelectNetworkPagePrefechedNetworks().totalMatchesCount;
      }

      public setTotalPrefetchedNetworksCount(count: number) {
         this.pageModel
               .getSelectNetworkPagePrefechedNetworks().totalMatchesCount = count;
      }

      public getNetworkMatches() {
         return this.migrationWizardManager.getNetworkMatches();
      }

      private initPrefetchedNetworks(prefetchedNetworks: PrefetchedNetwork[]) {
         this.initPrefetchedNetwork(prefetchedNetworks, this.networkPageViewModes.BASIC);
         this.initPrefetchedNetwork(prefetchedNetworks, this.networkPageViewModes.ADVANCED);
      }

      private initPrefetchedNetwork(prefetchedNetworks: PrefetchedNetwork[], viewType: string) {
         const list: PrefetchedNetwork[] = (viewType === this.networkPageViewModes.BASIC) ?
               this.prefetchedNetworksBasic : this.prefetchedNetworksAdvanced;

         list.length = 0;
         _.each(prefetchedNetworks, (item: any) => {
            list.push(item);
         });

         // NOTE: Additionally push the networks from the initialMatches as 'prefetched'
         _.each(this.pageModel.initialMatches as VmNetworkAdapterNetworkMatch[],
               (match: VmNetworkAdapterNetworkMatch) => {
            const destinationNetworkId = this.defaultUriSchemeUtil.getVsphereObjectId(
                  match.destinationNetwork);
            if (_.find(list, (el) => el.id === destinationNetworkId)) {
               return;
            }

            list.push({
               name: match.destinationNetworkName,
               id: destinationNetworkId,
               dvsUuid: match.destinationSwitchUuid,
               opaqueNetworkType: match.destinationOpaqueType,
               portgroupKey: match.destinationPortgroupKey
            });
         });
      }

      public getViewData(): any {
         return this.pageModel.getSelectNetworkPageViewData();
      }

      public getAdvancedNetworkMatches(): VmNetworkAdapterNetworkMatch[] {
         return this.pageModel.getAdvancedNetworkMatches();
      }

      private setAdvancedNetworkMatches(values: VmNetworkAdapterNetworkMatch[]) {
         this.updateWithInitialNetworkMatchesIfAvailable(values);
         this.pageModel.setAdvancedNetworkMatches(values);
      }

      public getBasicNetworkMatches(): VmNetworkAdapterNetworkMatch[] {
         return this.pageModel.getBasicNetworkMatches();
      }

      private setBasicNetworkMatches(values: VmNetworkAdapterNetworkMatch[]) {
         this.updateWithInitialNetworkMatchesIfAvailable(values);
         return this.pageModel.setBasicNetworkMatches(values);
      }

      public getPrefetchedNetworks() {
         return this.pageModel.getSelectNetworkPagePrefechedNetworks();
      }
      public getNetworkProviderId(): string {

         let networkProvider = this.migrationWizardManager.getDestinationHostRef();
         if (!networkProvider) {
            networkProvider = this.migrationWizardManager.getDestinationClusterRef();
         }
         // Use host as network provider if host was selected
         return this.defaultUriSchemeUtil.getVsphereObjectId(networkProvider);
      }

      public getBasicViewData(): BasicViewListItem[] {
         return this.basicViewData;
      }

      public getAdvancedViewData(): AdvancedViewListItem[] {
         return this.advancedViewData;
      }

      public getCompatibilityMessages(): any {
         return this.compatibilityMessages;
      }

      private addCompatibilityMessage(message: any) {
         this.compatibilityMessages.push(message);
      }

      private addCompatibilityMessages(messages: any[]) {
         if (!messages) {
            return;
         }

         for (let i = 0; i < messages.length; i++) {
            this.addCompatibilityMessage(messages[i]);
         }
      }

      public setCompatibilityMessage(message: any) {
         this.clearCompatibilityMessages();
         this.addCompatibilityMessage(message);
      }

      public setCompatibilityMessages(messages: any[], isPageValid: boolean) {
         this.clearCompatibilityMessages();
         this.addCompatibilityMessages(messages);

         if (this.pageModel.getSelectNetworkPageViewMode() ===
               this.networkPageViewModes.BASIC) {
            this.pageModel.setBasicCompatibilityResultsCache(messages);
            this.pageModel.isBasicPageValid = isPageValid;
         } else {
            this.pageModel.setAdvancedCompatibilityResultsCache(messages);
            this.pageModel.isAdvancedPageValid = isPageValid;
         }
      }

      private clearCompatibilityMessages() {
         this.compatibilityMessages.length = 0;
      }

      public requestData(vmIds: string[]) {
         let skipCompatibilityCheck: boolean = false;

         return this.$q.all({
            vmNameByIds: this.dataService.getPropertiesForObjects(vmIds, ["name"]),
            networksByIds: this.dataServiceUtil.getPropertiesForObjectsByRelation(
                  vmIds, "allNetwork", ["name"]),
            destinationNetworkMatches: this.dataService.getProperties(
                  this.getNetworkProviderId(),
                  [this.DESTINATION_NETWORKS_FOR_VMS],
                  {
                     propertyParams: [{
                        propertyName: this.DESTINATION_NETWORKS_FOR_VMS,
                        parameterType:
                        //NOT A TYPO
                              "[Lcom.vmware.vim.binding.vmodl.ManagedObjectReference;",
                        parameter:
                              this.defaultUriSchemeUtil.getManagedObjectReferences(vmIds)
                     }]
                  })
         }).then((values: any) => {
            _.each(values.destinationNetworkMatches[this.DESTINATION_NETWORKS_FOR_VMS],
                  (match: any) => {
                     const urn = this.defaultUriSchemeUtil.getVsphereObjectId(
                           match.sourceNetwork);
                     // values.networksByIds could be null when all the original VM networks
                     // are no longer available
                     match.sourceNetworkName = (values.networksByIds && values.networksByIds[urn]) ?
                           values.networksByIds[urn].name : null;
                  });

            const result: any = {};
            result.prefetchedNetworksPromise = this.prefetchNetworks(values)
                  .then((prefetchedNetworks: PrefetchedNetwork[]) => {
                     this.initPrefetchedNetworks(prefetchedNetworks);
                  });

            this.addDataToViewModel(values);
            this.buildBasicData();
            this.buildAdvancedData();

            const networkMatches = (this.pageModel.getSelectNetworkPageViewMode() ===
                  this.networkPageViewModes.BASIC) ?
                     this.getBasicNetworkMatches() : this.getAdvancedNetworkMatches();
            this.migrationWizardManager.setNetworkMatches(networkMatches);

            skipCompatibilityCheck = _.some(
                  networkMatches, (match: VmNetworkAdapterNetworkMatch) => {
               return match.sourceNetwork && !match.destinationNetwork;
            });

            if (skipCompatibilityCheck) {
               return result;
            }

            this.checkCompatibility();
            return result;
         });
      }

      public prefetchNetworks(values: any): IPromise<PrefetchedNetwork[]> {
         const networkSelectorFilterSpec =
               this.networkSelectorDialogService.createNetworkSelectorFilterSpec(
                     true/*includeStandardNetworks*/,
                     true/*includeOpaqueNetworks*/,
                     true/*includeDistributedPortGroups*/,
                     false/*includeUplinkPortGroups*/,
                     true/*includeOpaqueNetworkTypes*/);

         const destinationNetworksForVmsFiltered = _.filter(
               values.destinationNetworkMatches[this.DESTINATION_NETWORKS_FOR_VMS],
               (item: any) => {
                  return item.destinationNetwork;
               });
         const availableNetworks = _.map(
               destinationNetworksForVmsFiltered,
               (networkItem: any) => {
                  return networkItem.destinationNetwork;
               });

         const prefetchedNetworksCount = this.MAX_PREFETCHED_NETWORKS_COUNT;
         const additionalParams = {
            skipLoadingNotification: true
         };
         return this.networkSelectorPrefetchingService
               .prefetchNetworks(this.getNetworkProviderId(),
                     networkSelectorFilterSpec, availableNetworks, prefetchedNetworksCount,
                     additionalParams)
               .then((networksData: PrefetchedNetworksData) => {
                  this.setTotalPrefetchedNetworksCount(networksData.networkMatchesCount);

                  return networksData.recentNetworks;
               });
      }

      private addDataToViewModel(values: any) {
         this.getViewData().vmNamesById = this.dataServiceUtil.createPropertyByObjectMap(
               values.vmNameByIds, "name");
         this.getViewData().sourceNetworkNamesById = this.dataServiceUtil
               .createPropertyByObjectMap(values.networksByIds, "name");
         if (!this.pageModel.getSelectNetworkPageIsInitialized()) {
            this.setBasicNetworkMatches(angular.copy(values.destinationNetworkMatches[
                  this.DESTINATION_NETWORKS_FOR_VMS]));
            this.setAdvancedNetworkMatches(angular.copy(values.destinationNetworkMatches[
                  this.DESTINATION_NETWORKS_FOR_VMS]));

         }

         this.getViewData().networkSelector = {};
      }

      private networksMatchNetworkEquals(match: any, rowSourceNetwork: any) {
         let rowSourceNetworkId: any;
         if (angular.isObject(rowSourceNetwork)) {
            rowSourceNetworkId = this.defaultUriSchemeUtil.getVsphereObjectId(rowSourceNetwork);
         } else {
            rowSourceNetworkId = rowSourceNetwork;
         }

         let matchSourceNetworkId: any;
         if (angular.isObject(match)) {
            matchSourceNetworkId = this.defaultUriSchemeUtil.getVsphereObjectId(match.sourceNetwork);
         } else {
            matchSourceNetworkId = match;
         }

         return matchSourceNetworkId === rowSourceNetworkId;
      }

      private networksMatchSourceAdapterEquals(match: any, row: any) {
         const matchVmUrn = this.defaultUriSchemeUtil.getVsphereObjectId(match.sourceVm);
         const rowMatchVmUrn = this.defaultUriSchemeUtil.getVsphereObjectId(row.sourceVm);

         return (row.sourceAdapter.key === match.sourceAdapter.key) &&
               (matchVmUrn === rowMatchVmUrn);
      }

      private finderBasic(match: any, row: any) {
         if (!row.sourceNetworkId || !match.sourceNetwork) {
            let result = false;
            _.each(row.adapterMatches, (adapter: any) => {
               const currentFlag = this.networksMatchSourceAdapterEquals(match, {
                  sourceVm: adapter.sourceVm,
                  sourceAdapter: {key: adapter.sourceAdapter.key}
               });
               if (currentFlag) {
                  result = true;
               }
            });
            return result;
         } else {
            return this.networksMatchNetworkEquals(match, row.sourceNetworkId);
         }
      }

      private finderAdvanced(match: any, row: any) {
         if (!row.sourceNetworkId || !match.sourceNetwork) {
            return this.networksMatchSourceAdapterEquals(match, row);
         }
         return this.networksMatchNetworkEquals(match, row.sourceNetworkId) &&
               this.networksMatchSourceAdapterEquals(match, row);
      }

      public setDestinationNetwork(match: any, destinationNetwork: any) {
         match.destinationNetwork = destinationNetwork.networkRef;
         match.destinationNetworkName = destinationNetwork.name;
         match.destinationPortgroupKey = destinationNetwork.portgroupKey;
         match.destinationSwitchUuid = destinationNetwork.dvsUuid;
         match.destinationOpaqueType = destinationNetwork.opaqueNetworkType;
         match.destinationOpaqueId = destinationNetwork.destinationOpaqueId;
      }

      public onSelectNetwork(destinationNetwork: any, tableRow: any, gridType: any) {
         if (this.pageModel.getSelectNetworkPageViewMode() === this.networkPageViewModes.BASIC) {
            this.pageModel.setBasicCompatibilityResultsCache(null);
            this.pageModel.isBasicPageValid = false;
         } else {
            this.pageModel.setAdvancedCompatibilityResultsCache(null);
            this.pageModel.isAdvancedPageValid = false;
         }

         if (destinationNetwork.networkRef.type !== this.managedEntityConstants.OPAQUE_NETWORK) {
            return this.$q.when(this.onNetworkPropertiesReturn(
                  destinationNetwork, tableRow, gridType));
         }

         const destinationNetworkId = this.defaultUriSchemeUtil.getVsphereObjectId(
               destinationNetwork.networkRef);
         return this.dataServiceUtil.getPropertiesForObjects([destinationNetworkId],
               ["summary/opaqueNetworkType", "summary/opaqueNetworkId"])
               .then((result: any) => {
                  destinationNetwork.destinationOpaqueId =
                        result[destinationNetworkId]["summary/opaqueNetworkId"];
                  destinationNetwork.opaqueNetworkType =
                        result[destinationNetworkId]["summary/opaqueNetworkType"];

                  return this.onNetworkPropertiesReturn(destinationNetwork, tableRow, gridType);
               });
      }

      private onNetworkPropertiesReturn(destinationNetwork: any, tableRow: any, gridType: any) {
         const isBasic = (gridType === this.networkPageViewModes.BASIC);
         const matches = isBasic ? this.getBasicNetworkMatches()
               : this.getAdvancedNetworkMatches();
         _.each(matches, (match) => {
            const isMatching = isBasic ? this.finderBasic(match, tableRow)
                  : this.finderAdvanced(match, tableRow);
            if (isMatching) {
               this.setDestinationNetwork(match, destinationNetwork);
            }
         });

         this.removeAutoSelectionMessageIfNeeded();

         this.syncNetworkMatches();

         if (isBasic) {
            this.buildBasicData();
         } else {
            this.buildAdvancedData();
         }

         return this.checkCompatibility();
      }

      public buildBasicData() {
         const vmNamesById = this.getViewData().vmNamesById;
         const sourceNetworkNamesById = this.getViewData().sourceNetworkNamesById;
         const matchingData = this.getBasicNetworkMatches();

         const itemsById: {[key: string]: any} = {};
         this.basicViewData.length = 0;
         const data = this.basicViewData;
         const self = this;

         _.each(matchingData, (match: any) => {
            const networkId = this.defaultUriSchemeUtil.getVsphereObjectId(match.sourceNetwork);
            const sourceVmId = this.defaultUriSchemeUtil.getVsphereObjectId(match.sourceVm);
            let networkItem: any;
            if (itemsById[networkId]) {
               networkItem = itemsById[networkId];
               networkItem._attachedAdapters.push(match.sourceAdapter);
               networkItem._attachedVmNamesById[sourceVmId] = vmNamesById[sourceVmId];
               networkItem.adapterMatches.push(match);
            } else {
               networkItem = {
                  index: data.length,
                  sourceNetworkId: networkId,
                  networkName: sourceNetworkNamesById[networkId],
                  _attachedVmNamesById: {},
                  _attachedAdapters: [match.sourceAdapter],
                  usedBy: function (this: any) {
                     return self.i18nService.getString("Common", "vns.usedBy.format",
                           _.keys(this._attachedVmNamesById).length.toString(),
                           this._attachedAdapters.length.toString());

                  },
                  destinationNetworkName: match.destinationNetworkName,
                  destinationNetworkRef: match.destinationNetwork,
                  opaqueNetworkType: match.destinationOpaqueType,
                  adapterMatches: [match]
               };

               networkItem._attachedVmNamesById[sourceVmId] = vmNamesById[sourceVmId];

               itemsById[networkId] = networkItem;
               data.push(networkItem);
            }
         });
      }

      public buildAdvancedData() {
         const vmNamesById = this.getViewData().vmNamesById;
         const sourceNetworkNamesById = this.getViewData().sourceNetworkNamesById;
         const matchingData = this.getAdvancedNetworkMatches();

         this.advancedViewData.length = 0;
         const data = this.advancedViewData;

         _.each(matchingData, (match, index) => {
            const sourceNetworkId = this.defaultUriSchemeUtil.getVsphereObjectId(match.sourceNetwork);
            const sourceVmId = this.defaultUriSchemeUtil.getVsphereObjectId(match.sourceVm);

            const networkItem = {
               index: index,
               vmName: vmNamesById[sourceVmId],
               sourceAdapterLabel: match.sourceAdapter.deviceInfo.label,
               sourceAdapter: match.sourceAdapter,

               sourceNetworkId: sourceNetworkId,
               sourceNetworkName: sourceNetworkNamesById[sourceNetworkId],

               destinationNetworkName: match.destinationNetworkName,
               destinationNetworkRef: match.destinationNetwork,

               sourceVm: match.sourceVm
            };

            data.push(networkItem);
         });

      }

      private updateWithInitialNetworkMatchesIfAvailable(
            values: VmNetworkAdapterNetworkMatch[]) {
         _.each(values, (match: any) =>  {
            const matchHint = this.findMatchHint(match.sourceVm, match.sourceAdapter);
            if (matchHint) {
               this.updateMatchDestination(match, matchHint);
            }
         });
      }

      private updateMatchDestination(
            match: VmNetworkAdapterNetworkMatch,
            matchHint: VmNetworkAdapterNetworkMatch) {
         // NOTE: The match hint although the same structure - may not contain detailed
         // source network info - so we copy here only properties we know are populated
         match.destinationNetwork = matchHint.destinationNetwork;
         match.destinationNetworkName = matchHint.destinationNetworkName;
         match.destinationPortgroupKey = matchHint.destinationPortgroupKey;
         match.destinationSwitchUuid = matchHint.destinationSwitchUuid;
         match.destinationOpaqueId = matchHint.destinationOpaqueId;
         match.destinationOpaqueType = matchHint.destinationOpaqueType;
      }

      private findMatchHint(
            sourceVm: ManagedObjectReference,
            sourceAdapter: any): VmNetworkAdapterNetworkMatch|undefined {
         if (!this.pageModel.initialMatches) {
            return undefined;
         }

         return _.find(this.pageModel.initialMatches, (match: VmNetworkAdapterNetworkMatch) => {
            return this.defaultUriSchemeUtil.compareIds(match.sourceVm, sourceVm) &&
                  (match.sourceAdapter.key === sourceAdapter.key);
         });
      }

      public checkCompatibility() {
         if (this.pageModel.getSelectNetworkPageViewMode() === this.networkPageViewModes.BASIC) {
            if (this.pageModel.getBasicCompatibilityResultsCache()) {
               this.setCompatibilityMessages(
                     this.pageModel.getBasicCompatibilityResultsCache(),
                     this.pageModel.isBasicPageValid);
               return;
            }
         } else {
            if (this.pageModel.getAdvancedCompatibilityResultsCache()) {
               this.setCompatibilityMessages(
                     this.pageModel.getAdvancedCompatibilityResultsCache(),
                     this.pageModel.isAdvancedPageValid);
               return;
            }
         }

         this.validationLoadingHandlerFn(true);

         let isNetworkPageValid = false;

         return this.migrationService.validateNetworkPrivilege(
               this.migrationWizardManager.getNetworkMatches()
         ).then((validationResultsArray: any[]) => {
            if (_.find(validationResultsArray, (r: any) => {
                     return r.error;
                  })) {
               // and return it immediately - no further processing is needed.
               return validationResultsArray;
            }

            return this.migrationService.validateNetwork(
                  this.migrationWizardManager.getVmIds(),
                  this.migrationWizardManager.getNetworkMatches(),
                  this.defaultUriSchemeUtil.getVsphereObjectId(
                        this.migrationWizardManager.getTargetResourcePoolRef()),
                  this.defaultUriSchemeUtil.getVsphereObjectId(
                        this.migrationWizardManager.getDestinationComputeResourceRef())
            );
         }).then((validationResultsArray: any[]) => {
            isNetworkPageValid = this.migrationService.isValidationSuccessful(
                  validationResultsArray);
            return this.compatibilityResultsPresenter.formatValidationResults(
                  validationResultsArray);
         }).then((compatibilityItems: any[]) => {
            if (compatibilityItems.length > 0) {
               this.setCompatibilityMessages(compatibilityItems, isNetworkPageValid);
               this.validationLoadingHandlerFn(false);
               return;
            }
            const result = [{
               icon: "vsphere-icon-status-ok",
               message: this.i18nService.getString("VmUi",
                     "ProvisioningWizard.CompatibilityChecksSucceeded"),
               contents: []
            }];
            const matches = this.migrationWizardManager.getNetworkMatches();
            this.addAutoSelectionMessageIfRequired(result, matches);
            this.setCompatibilityMessages(result, isNetworkPageValid);
            this.validationLoadingHandlerFn(false);
         });
      }

      /**
       * In case there is a single prefetched network, and it"s different than the
       * source network of some of the network matches, displays an info message
       * about that in the compatibility/validation results view, after the
       * regular validation results. This is done, because the single prefetched
       * network will be auto-selected, as the only available choice.
       */
      public addAutoSelectionMessageIfRequired(result: any, matches: any[]) {
         // If there are more than one prefetched networks, no need to continue.
         if (!this.isSingleNetworkPrefetched()
               || !this.areAllPortGroups(matches)
               || this.isAutoSelectionMessageAdded) {
            return;
         }

         //should use each but it iterates all items
         // _.find stops at the first match
         // we kept _  for [] / {} compatibility

         _.find(matches, (match: any) => {
            if (this.isAutoSelectionMessageAdded === false
                  && match.sourceNetwork
                  && match.destinationNetwork
                  && match.sourceNetwork.value !== match.destinationNetwork.value) {
               const infoMessage = {
                  icon: "vsphere-icon-help-info-hover",
                  message: this.i18nService.getString("VmUi",
                        "MigrationWizard.networkPage.info.autoSelectedDifferentNetwork"),
                  contents: []
               };
               result.push(infoMessage);
               this.isAutoSelectionMessageAdded = true;

               return true;
            }

            return false;
         });

      }

      private removeAutoSelectionMessageIfNeeded() {
         if (this.isSelectionPerformed && this.isAutoSelectionMessageAdded) {
            this.setCompatibilityMessage({
               icon: "vsphere-icon-status-ok",
               message: this.i18nService.getString("VmUi",
                     "ProvisioningWizard.CompatibilityChecksSucceeded"),
               contents: []
            });
         }
      }

      private areAllPortGroups(matches: any[]) {
         let isPortGroupBoolean = true;
         _.each(matches, (match: any) => {
            if ((!match.destinationNetwork)
                  || (match.sourceNetwork && (match.sourceNetwork.type !==
                        match.destinationNetwork.type))
                  || (match.sourceNetwork && (match.sourceNetwork.type !==
                        this.managedEntityConstants.DISTRIBUTED_VIRTUAL_PORTGROUP))) {
               isPortGroupBoolean = false;
            }
         });

         return isPortGroupBoolean;
      }

      /**
       * Determines whether the last initialized prefetched networks data
       * contains just a single non-null element, which corresponds to a single
       * network, being prefetched by the network selector dropdown.
       */
      public isSingleNetworkPrefetched() {
         return 1 === this.getTotalPrefetchedNetworksCount();
      }

      public syncNetworkMatches() {
         if (this.pageModel.getSelectNetworkPageViewMode() ===
               this.networkPageViewModes.ADVANCED) {

            if (!this.pageModel
                        .getSelectNetworkPageIsViewChanged()) {
               this.getAdvancedNetworkMatches().length = 0;
               _.each(this.getBasicNetworkMatches(), (match: any) => {
                  this.getAdvancedNetworkMatches().push(angular.copy(match));
               });

               //copy basic prefetchedNetworks to advanced when
               // we change views for the first time
               this.initPrefetchedNetwork(this.prefetchedNetworksBasic,
                     this.networkPageViewModes.ADVANCED);
               this.buildAdvancedData();

               this.pageModel.setSelectNetworkPageIsViewChanged(true);
            }

            this.migrationWizardManager.setNetworkMatches(this.getAdvancedNetworkMatches());
         } else {
            this.migrationWizardManager.setNetworkMatches(this.getBasicNetworkMatches());
         }
      }

   } // class

   angular.module("com.vmware.vsphere.client.vm").service(
         "vmMigrateSelectNetworkService",
         [
            "$q",
            "i18nService",
            "dataService",
            "dataServiceUtil",
            "defaultUriSchemeUtil",
            "networkSelectorDialogService",
            "migrationService",
            "compatibilityResultsPresenter",
            "managedEntityConstants",
            "networkSelectorPrefetchingService",
            "NetworkPageViewModes",
            function (
                  $q: any,
                  i18nService: any,
                  dataService: any,
                  dataServiceUtil: any,
                  defaultUriSchemeUtil: any,
                  networkSelectorDialogService: any,
                  migrationService: any,
                  compatibilityResultsPresenter: any,
                  managedEntityConstants: any,
                  networkSelectorPrefetchingService: any,
                  networkPageViewModes: any,
            ) {
               // Anonymous constructor function to return a
               // VmMigrateSelectNetworkService instance.
               return function (
                     migrationWizardManager: any,
                     migrateSelectNetworkPageModel: MigrateSelectNetworkPageModel,
                     validationLoadingHandlerFn: any,
               ) {
                  return new VmMigrateSelectNetworkService(
                        $q,
                        i18nService,
                        dataService,
                        dataServiceUtil,
                        defaultUriSchemeUtil,
                        networkSelectorDialogService,
                        migrationService,
                        compatibilityResultsPresenter,
                        managedEntityConstants,
                        networkSelectorPrefetchingService,
                        networkPageViewModes,
                        migrationWizardManager,
                        migrateSelectNetworkPageModel,
                        validationLoadingHandlerFn,
                  );
               };
            }
         ]);
} // namespace
