/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace dvs_ui {

   export class DvpgVlanPageComponent {

      public bindings: any;
      public controller: any;
      public templateUrl: string;

      constructor() {
         this.bindings = {
            model: "<",
            overrideOptions: "<?",
            overrideEnabled: "<?",
            overrideDisallowed: "<?"
         };

         this.controller = DvpgVlanPageComponentController;

         this.templateUrl = "dvs-ui/resources/dvs/dvpg/common/components/" +
               "dvpgVlanPageTemplate.html";
      }
   }

   class DvpgVlanPageComponentController {

      static $inject = [
         "$scope",
         "i18nService",
         "networkUiConstants",
         "dvpgPropertiesService"];

      public model: any;
      public i18n: any;

      private vlanTypeOptions: any[];
      private pvlanTypeOptions: any[];
      private vlanIdValidator: any = {};
      private isPvlanAllowed: boolean;
      public overrideOptions: any;
      public overrideEnabled: boolean;
      public overrideDisallowed: boolean;

      constructor(private $scope: any,
                  private i18nService: any,
                  private networkUiConstants: any,
                  private dvpgPropertiesService: any) {
         this.i18n = i18nService.getString;
      }

      $onInit(): void {
         this.initPage();
      }

      private onVlanTypeChange(): void {
         let newType: string = this.model.vlanType;
         if (newType === this.networkUiConstants.Vlan.Type.VLAN) {
            this.model.vlanId = this.networkUiConstants.Vlan.Id.MIN;
            this.setVlanIdValidator();
         } else if (newType === this.networkUiConstants.Vlan.Type.PRIVATE) {
            if (this.model.pvlanConfig &&
                  this.model.pvlanConfig.length > 0) {
               this.model.pvlanId =
                     this.model.pvlanConfig[0].secondaryVlanId;
            }
         } else if (newType === this.networkUiConstants.Vlan.Type.TRUNK) {
            this.model.vlanTrunkRanges =
                  this.networkUiConstants.Vlan.DEFAULT_VLAN_TRUNK_RANGE;
         }
      }

      private initPage(): void {
         this.vlanTypeOptions = [{
            label: this.i18n("NetworkUi", "dvpg.policy.vlan.type.none"),
            val: this.networkUiConstants.Vlan.Type.NONE
         }, {
            label: this.i18n("NetworkUi", "dvpg.policy.vlan.type.vlan"),
            val: this.networkUiConstants.Vlan.Type.VLAN
         }, {
            label: this.i18n("NetworkUi", "dvpg.policy.vlan.type.trunk"),
            val: this.networkUiConstants.Vlan.Type.TRUNK
         }, {
            label: this.i18n("NetworkUi", "dvpg.policy.vlan.type.private"),
            val: this.networkUiConstants.Vlan.Type.PRIVATE
         }];

         this.pvlanTypeOptions = this.buildPvlanOptions();
         this.isPvlanAllowed =
               this.model.pvlanConfig && this.model.pvlanConfig.length > 0;
      }

      private setVlanIdValidator(): void {
         let message: string = this.dvpgPropertiesService.getVlanIdError(
               this.model.vlanId);

         this.vlanIdValidator.message = message;
         this.vlanIdValidator.isVisible = message !== null;
      }

      private buildPvlanOptions(): any[] {
         let options: any[] = [];
         if (!this.model.pvlanConfig) {
            return options;
         }

         this.model.pvlanConfig.forEach((pvlanData: any) => {
            let pvlanTypeLabel: any = this.getPvlanTypeLabel(pvlanData.pvlanType);

            options.push({
               label: this.i18n("DvsUi", "dvpg.policy.vlan.displayFormat",
                     pvlanTypeLabel,
                     pvlanData.primaryVlanId,
                     pvlanData.secondaryVlanId),
               val: pvlanData.secondaryVlanId
            });
         });

         return options;
      }

      private getPvlanTypeLabel(pvlanType: string): string {
         switch (pvlanType) {
            case this.networkUiConstants.Vlan.PvlanType.ISOLATED: {
               return this.i18n("DvsUi", "dvpg.policy.vlan.private.type.isolated");
            }
            case this.networkUiConstants.Vlan.PvlanType.PROMISCUOUS: {
               return this.i18n("DvsUi", "dvpg.policy.vlan.private.type.promiscuous");
            }
            case this.networkUiConstants.Vlan.PvlanType.COMMUNITY: {
               return this.i18n("DvsUi", "dvpg.policy.vlan.private.type.community");
            }
            default:
               return "";
         }
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .component("dvpgVlanPage", new DvpgVlanPageComponent());
}
