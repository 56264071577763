namespace h5_vm {
   import IController = angular.IController;

   class VmProvisioningVserviceController implements IController {

      static $inject: string[] = ["$scope"];
      private vserviceContext: any;

      constructor(private $scope: any) {

         this.vserviceContext =
               $scope.wizardViewData.getVserviceContextFromLibraryCommonContext();

         //Pre-selecting provider to either 0 or a preselected index.
         if (this.vserviceContext && this.vserviceContext.dependencies) {
            this.vserviceContext.dependencies.forEach(
                  function (dependency: any) {
                     if (dependency.availableProviders) {
                        let selectableIndex: number = 0;

                        // If provider was previously selected.
                        if (dependency.selectedProviderKey) {
                           selectableIndex = dependency.availableProviders.findIndex(
                                 (provider: any) => {
                                    return (provider.key === dependency.selectedProviderKey);
                                 });
                        }
                        dependency.selectedProviderKey =
                              dependency.availableProviders[selectableIndex].key;
                     }
                  }
            );
         }
      }
   }

   angular.module("com.vmware.vsphere.client.vm")
         .controller("vmProvisioningVserviceController",
               VmProvisioningVserviceController);
}