(function() {
   'use strict';
   angular.module('com.vmware.vsphere.client.commonModule')
      .service('PermissionDataService', PermissionDataService);

   PermissionDataService.$inject = ['dataService', 'mutationService', 'objectTypesService', '$q', 'defaultUriSchemeUtil'];

   function PermissionDataService (dataService, mutationService, objectTypesService, $q, defaultUriSchemeUtil) {

      return {
         getName: getName,
         getRolesInfo: getRolesInfo,
         getUserGroups: getUserGroups,
         getDomains: getDomains,
         saveUserGroupPermissions: saveUserGroupPermissions,
         getEntityPermissions: getEntityPermissions,
         removeUserGroupPermissions: removeUserGroupPermissions,
         editUserGroupPermissions: editUserGroupPermissions
      };

      function getName (vsphereObjectId) {
         return dataService.getProperties(vsphereObjectId, ['name']).then(function(data) {
            return data.name;
         });
      }

      function getRolesInfo (vsphereObjectId) {
         return dataService.getProperties(vsphereObjectId, ["rolesInfo"]).then(function(data) {
            return data.rolesInfo;
         });
      }

      function getDomains () {
         return dataService.getProperties('urn:vri:sso:UserDirectory', ['vsphere:domainList']).then(function(data) {
            return _.map(data["vsphere:domainList"], function(dataItem) {
               return {
                  name: dataItem.name,
                  label: dataItem.label
               };
            });
         });
      }

      function getUserGroups (vsphereObjectId, domain, searchString) {
         var paramSpec = {
            propertyName: "vsphere:userGroups",
            parameterType: "com.vmware.vise.vim.users.UserDirectorySpec",
            parameter: {
               domain: domain,
               searchString: searchString || "",
               exactMatch: false,
               findUsers: true,
               findGroups: true
            }
         };

         return dataService.getProperties(vsphereObjectId, ['vsphere:userGroups'], {
            propertyParams: [paramSpec]
         });
      }

      function saveUserGroupPermissions (entityId, permissionsList) {
         return mutationService.apply(
            entityId,
            "com.vmware.vise.vim.security.data.UpdatePermissionsSpec",
            { permissions: permissionsList },
            "Add permission"
         );
      }

      function editUserGroupPermissions (entityId, permissions) {
         return mutationService.apply(
            entityId,
            "com.vmware.vise.vim.security.data.UpdatePermissionsSpec",
            { permissions: permissions },
            "Edit permission"
         );
      }

      function removeUserGroupPermissions (entityId, permissions) {
         permissions.id = permissions.permissionId;
         permissions.group = permissions.isGroup;
         return mutationService.apply(
               entityId,
               "com.vmware.vise.vim.security.data.RemovePermissionSpec",
               { permission: permissions },
               "Remove permission"
         );
      }

      function getPermissionViewData (objectId) {
         return dataService.getProperties(objectId, ['permissionViewData']).then(function(data) {
            return $q.all(_.map(data.permissionViewData, function(dataItem) {
               var deferred = $q.defer();
               if (!dataItem.entityType) {
                  deferred.resolve(dataItem);
               } else {
                  objectTypesService.getObjectTypeSpec(dataItem.entityType).then(function(iconObject) {
                     if (dataItem.isRootFolder) {
                        dataItem.icon = 'vsphere-icon-vcenter';
                     } else {
                        dataItem.icon = iconObject.icon;
                     }
                     deferred.resolve(dataItem);
                  }, function(err) {
                     deferred.reject(err);
                  });
               }
               return deferred.promise;
            }));
         });
      }

      function getEntityPermissions(objectId) {
         return getPermissionViewData(objectId);
      }
   }
}());
