/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
(function() {
   'use strict';
   angular.module('com.vmware.vsphere.client.network').controller(
      'NetStackDetailsViewController', NetStackDetailsViewController);

   NetStackDetailsViewController.$inject = ['$scope', 'netStackDetailsViewService'];

   function NetStackDetailsViewController (
      $scope,
      netStackDetailsViewService
   ) {

      var self = this;
      var isInitialRefresh = true;

      self.hostId = $scope._route.objectId;
      self.liveRefreshProperties = [
         "config.network.netStackInstance",
         "config.network.dnsConfig.dhcp",
         "config.network.dnsConfig.address",
         "config.network.dnsConfig.searchDomain",
         "config.network.dnsConfig.domainName",
         "config.network.dnsConfig.hostName",
         "config.network.dnsConfig.virtualNicDevice",
         "config.network.ipRouteConfig.ipV6GatewayDevice",
         "config.network.ipRouteConfig.ipV6DefaultGateway",
         "config.network.ipRouteConfig.defaultGateway",
         "config.network.routeTableInfo.ipv6Route",
         "config.network.routeTableInfo.ipRoute"
      ];
      $scope.loadingData = false;

      var DEFAULT_NET_STACK = 'defaultTcpipStack';

      self.refresh = function(selectedNetStackKey) {
         $scope.isDefaultNetStack = selectedNetStackKey === DEFAULT_NET_STACK;
         $scope.loadingData = true;

         if (isInitialRefresh) {
            $scope.isDnsTabSelected = $scope.isDefaultNetStack;
            $scope.isRoutingTabSelected = !$scope.isDefaultNetStack;
            $scope.isAdvancedTabSelected = false;
         } else if (!$scope.isDefaultNetStack && $scope.isDnsTabSelected) {
            $scope.isDnsTabSelected = false;
            $scope.isRoutingTabSelected = true;
            $scope.isAdvancedTabSelected = false;
         } else if ($scope.isAdvancedTabSelected) {
            $scope.isDnsTabSelected = false;
            $scope.isRoutingTabSelected = false;
            $scope.isAdvancedTabSelected = true;
         }

         var requestPromise = netStackDetailsViewService
            .getNetStackDetails(self.hostId, selectedNetStackKey);
         self.lastRequestPromise = requestPromise;

         requestPromise.then(function(detailsData) {
            if (requestPromise === self.lastRequestPromise) {
               $scope.netStackDnsData = $scope.isDefaultNetStack ?
                  netStackDetailsViewService.buildDnsPropertiesView(
                     detailsData, $scope.isDefaultNetStack) : null;
               $scope.netStackRoutingData = netStackDetailsViewService
                  .buildRoutingPropertiesView(detailsData);
               $scope.netStackAdvancedData = netStackDetailsViewService
                  .buildAdvancedPropertiesView(detailsData);

               if (detailsData) {
                  $scope.ipv4RoutingTableData = detailsData.ipv4RouteTableEntries;
                  $scope.ipv6RoutingTableData = detailsData.ipv6RouteTableEntries;
               }
            }
         }).finally(function() {
            if (requestPromise === self.lastRequestPromise) {
               $scope.loadingData = false;
            }
         });

         isInitialRefresh = false;
      };

      $scope.onTabIndexChanged = function(currentTabIndex) {
         $scope.isDnsTabSelected = currentTabIndex === 0;
         $scope.isRoutingTabSelected = currentTabIndex === 1;
         $scope.isAdvancedTabSelected = currentTabIndex === 4;
      };

      $scope.$watch('lastSelectedNetStackKey',
         function(lastSelectedNetStackKey) {
            self.refresh(lastSelectedNetStackKey);
         });
   }
})();
