module cluster_ui {

   import IComponentController = angular.IComponentController;

   export class DrsMemoryUtilizationController implements IComponentController {

      private objectId: string;
      private DRS_CLUSTER_UTILIZATION_MODEL: string =
            "com.vmware.vsphere.client.clusterui.model.monitor.drs.utilization.DrsMemoryClusterUtilizationData";
      public drsUtilizationData: any = {};
      public watchForObjects: string[] = [this.objectId];
      public liveRefreshProperties: string[] = ["configStatus",
         "summary.currentBalance"];

      public static $inject = ["$scope", "i18nService", "dataService",
         "defaultUriSchemeUtil", "clusterConstants"];

      private memoryUtilizationTypes: Object[];
      private selectedMemoryUtilizationType: string;
      private title: string;
      private showDropdown: boolean;

      constructor(private $scope: any, private i18nService: any,
                  private dataService: any,
                  private defaultUriSchemeUtil: any,
                  private clusterConstants: any) {
         this.objectId = $scope._route.objectId;
         this.selectedMemoryUtilizationType = this.clusterConstants.memoryUtilizationTypes.ACTIVE;
         this.title = this.getString("drs.utilization.active.header");
         this.memoryUtilizationTypes = this.getMemoryUtilizationTypes();
         this.showDropdown = false;

         this.getViewData();

         $scope.$watch(() => {
            return this.selectedMemoryUtilizationType;

         }, (newValue: any) => {
            if (newValue) {
               this.title = _.find(this.memoryUtilizationTypes, (item: any) => {
                  return item.value === newValue;
               }).header;

               this.drsUtilizationData.memoryUtilizationType = newValue;
            }
         });
      }

      public getViewData(): void {
         this.dataService.getData(this.objectId, this.DRS_CLUSTER_UTILIZATION_MODEL)
               .then((data: any) => {
                  if (data) {
                     this.drsUtilizationData = _.extend({
                        memoryUtilizationType: this.selectedMemoryUtilizationType
                     }, data);
                     this.sortVms();
                  }
               });
      }

      public getString(key: string, ...params: any[]): string {
         return this.i18nService.getString("ClusterUi", key, params);
      }

      public showDropdownEvent(value: boolean): void {
         this.showDropdown = !value;
      }

      private setMemoryMetric(value: string): void {
         this.selectedMemoryUtilizationType = value;
         this.showDropdown = false;
         this.sortVms();
      }

      private sortVms(): void {
         if (this.drsUtilizationData !== null && this.drsUtilizationData.hosts !== null) {
            this.drsUtilizationData.hosts.forEach((host: any) => {
               host.poweredOnVms.sort((a: any, b: any): number => {
                  if (this.selectedMemoryUtilizationType ===
                        this.clusterConstants.memoryUtilizationTypes.ACTIVE) {
                     return a.activeMemoryBarWidth > b.activeMemoryBarWidth ? -1 : 1;
                  }
                  return a.consumedMemoryBarWidth > b.consumedMemoryBarWidth ? -1 : 1;
               });
            });
         }
      }

      private getMemoryUtilizationTypes() {

         // Remove the ':' symbol from the string, because this string will be used as a value in the dropdown
         let valueActiveLabel: string = this.getString("drs.utilization.tooltip.active");
         valueActiveLabel = valueActiveLabel.substr(0, valueActiveLabel.length - 1);

         let valueConsumedLabel: string = this.getString("drs.utilization.tooltip.consumed");
         valueConsumedLabel = valueConsumedLabel.substr(0, valueConsumedLabel.length - 1);

         return [{
            value: this.clusterConstants.memoryUtilizationTypes.ACTIVE,
            header: this.getString("drs.utilization.active.header"),
            selectValueLabel: valueActiveLabel
         }, {
            value: this.clusterConstants.memoryUtilizationTypes.CONSUMED,
            header: this.getString("drs.utilization.consumed.header"),
            selectValueLabel: valueConsumedLabel
         }];
      }
   }

   angular.module("com.vmware.vsphere.client.cluster")
         .controller("DrsMemoryUtilizationController", DrsMemoryUtilizationController);
}
