(function() {
   'use strict';
   angular.module('com.vmware.vsphere.client.host').service('hostAuthService',
         HostAuthService);

   HostAuthService.$inject = ['mutationService', 'authConstants', 'i18nService', 'vuiConstants', 'timeFormatterService'];

   function HostAuthService(mutationService, authConstants, i18nService, vuiConstants, timeFormatterService) {

      function leaveDomain(hostId) {
         mutationService.apply(
               hostId,
               'com.vmware.vsphere.client.host.DomainSettingSpec',
               {});
      }

      function joinDomain(hostId, domainInfo) {
         var spec = {
            domain: domainInfo.domain
         };
         if (domainInfo.loginType === authConstants.loginType.LOGIN_USING_CREDENTIAL) {
            spec.username = domainInfo.username;
            spec.password = domainInfo.password;
         } else {
            spec.camServer = domainInfo.ipAddress;
         }
         mutationService.apply(
               hostId,
               'com.vmware.vsphere.client.host.DomainSettingSpec',
               spec);

      }

      function addCertificate(hostId, certificateInfo) {
         var spec = {
            certPath: certificateInfo.path,
            serverIp: certificateInfo.ip
         };
         mutationService.apply(
               hostId,
               'com.vmware.vsphere.client.host.ImportCertificateInfo',
               spec);

      }

      function addAlertMessage(messageKey, alerts) {
         var alertMessage = {
            text: i18nService.getString('HostUi', messageKey),
            type: vuiConstants.notifications.type.ERROR
         };
         alerts.push(alertMessage);
         return alerts;
      }
      function hostAuthHeaderOptions(objectId){
         return {
            title: i18nService.getString('HostUi', 'authServices.settings.tabLabel'),
            objectId: objectId,
            actions: [
               {
                  actionUid: 'vsphere.core.host.actions.authenticationServiceSettingsAddCertificate',
                  customLabel: i18nService.getString('HostUi', 'importCert.actionLabel')
               },
               {
                  actionUid: 'vsphere.core.host.actions.authenticationServiceSettingsJoinDomain',
                  customLabel: i18nService.getString('HostUi', 'authJoinDomain.actionLabel')
               },
               {
                  actionUid: 'vsphere.core.host.actions.authenticationServiceSettingsLeaveDomain',
                  customLabel: i18nService.getString('HostUi', 'authLeaveDomain.actionLabel')
               }]
         };
      }
      function smartCardHeaderOptions(){
         return {
            title: i18nService.getString('HostUi', 'smartCardAuthnetication.title'),
            actions: [
               {
                  actionUid: 'vsphere.core.host.actions.editSmartCardAuthentication',
                  customLabel: i18nService.getString('HostUi', 'smartCardAuthentication.editAction.label')

               }
            ]
         };
      }

      function nfsKerberosHeaderOptions() {
         return {
            title: i18nService.getString('StorageUi','nfsCredentials.blockTitle'),
            actions: [
                  {
                     actionUid: 'vsphere.core.host.actions.clearNfsKerberosCredentials',
                     customLabel: i18nService.getString('HostUi', 'nfsCredentials.clear.actionLabel')
                  }, {
                     actionUid: 'vsphere.core.host.actions.editNfsKerberosCredentials',
                     customLabel: i18nService.getString('HostUi', 'nfsCredentials.edit.actionLabel')
                  }
               ]
         };
      }

      function getSmartCardEnabledStatus(smartCardStatus) {
         var status;
         if (smartCardStatus) {
            status = i18nService.getString('HostUi', 'smartCardAuthentication.enabled.label');
         } else {
            status = i18nService.getString('HostUi', 'smartCardAuthentication.disabled.label');
         }
         return status;
      }
      function getDatagridOptions(userTimeFormat) {
         return {
            selectionMode: "single",
            sortMode: "single",
            searchable: false,
            columnDefs: [
               {
                  field: 'subject',
                  sortable: false,
                  searchable: false,
                  displayName: i18nService.getString('HostUi',
                        'smartCardAuthnetication.certificates.subject')
               },
               {
                  field: 'issuer',
                  sortable: false,
                  searchable: false,
                  displayName: i18nService.getString('HostUi',
                        'smartCardAuthnetication.certificates.issuer')
               },
               {
                  field: 'validTo',
                  sortable: false,
                  searchable: false,
                  displayName: i18nService.getString('HostUi',
                        'smartCardAuthnetication.certificates.validTo'),
                  template: function(data){
                     return timeFormatterService.timestampToText(
                           parseInt(data.validTo),
                           userTimeFormat
                     );
                  }
               }],
            data: [],
            height: '200px',
            pageConfig: {
               hidePager: true
            }
         };
      }
      function buildDomainSettingsSection(categoryBuilder, domainObject) {
         categoryBuilder.section('domainSettings')
               .title(i18nService.getString(
                     'HostUi',
                     'authServices.domain.settingsTitle'
               ))
               .property(
                     i18nService.getString(
                           'HostUi',
                           'authServices.domain.settings.domain'
                     ),
                     domainObject.domain
               )
               .property(
                     i18nService.getString(
                           'HostUi',
                           'authServices.domain.settings.trustedControllers'
                     ),
                     domainObject.trustedDomain
               );
      }
      function buildDirectoryServicesConfigSection(categoryBuilder, authStatus) {
         categoryBuilder.section('directoryServicesConfig')
               .title(i18nService.getString(
                     'HostUi',
                     'authServices.directory.configTitle'
               ))
               .property(
                     i18nService.getString(
                           'HostUi',
                           'authServices.directory.typeTitle'
                     ),
                     authStatus
               );
      }

      return {
         leaveDomain: leaveDomain,
         joinDomain: joinDomain,
         addCertificate: addCertificate,
         addAlertMessage: addAlertMessage,
         hostAuthHeaderOptions: hostAuthHeaderOptions,
         smartCardHeaderOptions: smartCardHeaderOptions,
         nfsKerberosHeaderOptions: nfsKerberosHeaderOptions,
         getSmartCardEnabledStatus: getSmartCardEnabledStatus,
         getDatagridOptions: getDatagridOptions,
         buildDomainSettingsSection: buildDomainSettingsSection,
         buildDirectoryServicesConfigSection: buildDirectoryServicesConfigSection
      };
   }
})();
