/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace network_ui {

   import IRootScopeService = angular.IRootScopeService;

   /**
    * Provides methods for creating the mutation spec sent to the backend
    * services after the completion of the Migrate VM networks wizard.
    */
   export class VmMigrateWizardSpecFactory {

      public static $inject = ["defaultUriSchemeUtil", "vmMigrateWizardConstants"];

      constructor(private defaultUriSchemeUtil: any,
                  private wizardConstants: any) {
      }

      /**
       * Builds the spec for the Migrate VM networks operation.
       *
       * @param model VmMigrateWizardModel
       */
      public buildVmMigrateSpec(model: VmMigrateWizardModel): any {
         const listItems: any[] = model.vmListItems;

         const vnicsByVm: { [vmId: string]: any[] } = {};
         const configVersionByVm: { [vmId: string]: string } = {};

         for (let listItem of listItems) {
            if (listItem.isChecked) {
               configVersionByVm[listItem.vmId] = listItem.changeVersion;

               if (!vnicsByVm[listItem.vmId]) {
                  vnicsByVm[listItem.vmId] = [];
               }

               const vnicsCollection: any[] = vnicsByVm[listItem.vmId];
               vnicsCollection.push(listItem.virtualEthernetCard);
            }
         }

         const vmVnicSpecs: any[] = [];

         for (let vmId in vnicsByVm) {
            const vmVnicSpec: any = {
               _type: this.wizardConstants.specs.VM_MIGRATE_VM_VNICS_SPEC,
               vmRef: this.defaultUriSchemeUtil.getManagedObjectReference(vmId),
               vmChangeVersion: configVersionByVm[vmId],
               vnics: vnicsByVm[vmId]
            };

            vmVnicSpecs.push(vmVnicSpec);
         }

         return {
            vmVnicsSpecs: vmVnicSpecs,
            destinationNetwork: model.destinationNetworkData
         };
      }
   }

   angular.module("com.vmware.vsphere.client.network")
         .service("vmMigrateWizardSpecFactory", VmMigrateWizardSpecFactory);
}
