angular
   .module('com.vmware.vsphere.client.host')
   .service('maintenanceModeConfirmationService',
      [
         'maintenanceModeConstants',
         'i18nService',
         'mutationService',
         'dataService',
         'clarityModalService',
         'defaultUriSchemeUtil',
         'h5SdkApplicationService',
         function(maintenanceModeConstants,
                  i18nService,
                  mutationService,
                  dataService,
                  clarityModalService,
                  defaultUriSchemeUtil,
                  h5SdkApplicationService) {

            var getString = _.partial(i18nService.getString, 'HostUi');

            return {
               retrieveVsanData: retrieveVsanData,
               onOKClicked: onOKClicked,
               getDefaultEvacuationType: getDefaultEvacuationType,
               navigateToPrecheckView: navigateToPrecheckView
            };

            function retrieveVsanData(targets, onVsanDataRetrieved) {
               dataService
                  .getData(
                     targets[0],
                     'com.vmware.vsphere.client.h5.host.model.HostMaintenanceModeData')
                  .then(function(result) {
                     if (result) {
                        onVsanDataRetrieved(result);
                     }
                  });
            }

            function getDefaultEvacuationType() {
               return maintenanceModeConstants.ENSURE_OBJECT_ACCESSIBILITY;
            }

            function onOKClicked(targets,
                                 isVsanSupportedAndEnabled,
                                 shouldMigrateVms,
                                 showMigrationConfirmation,
                                 vSanEvacuationType) {
               var evacuatePoweredOffVms = shouldMigrateVms && showMigrationConfirmation;
               var hostMaintenanceModeSpec = createMaintenanceModeSpec(
                  isVsanSupportedAndEnabled,
                  evacuatePoweredOffVms,
                  vSanEvacuationType);

               mutationService.applyOnMultiEntity(
                  targets,
                  'com.vmware.vsphere.client.host.maintenance.HostMaintenanceSpec',
                  hostMaintenanceModeSpec);

               dataService
                  .getProperties(
                     targets[0],
                     ['name', 'poweredOnVMs._length'])
                  .then(
                     function(hostProperties) {
                        if (hostProperties['poweredOnVMs._length'] > 0) {
                           var modalOptions = {
                              title: i18nService.getString('Common',
                                 'issueStatus.warning'),
                              subTitle: {
                                 objectId: targets[0]
                              },
                              hideCancelButton: true,
                              message: getString(
                                 'actionEnterMaintenanceVMsPoweredOn',
                                 hostProperties.name),
                              icon: 'icon-warning-32',
                              submit: function() {
                                 return true;
                              },
                              preserveNewlines: true
                           };
                           clarityModalService.openConfirmationModal(modalOptions);
                        }
                     });

               return true;
            }

            function createMaintenanceModeSpec(isVsanSupportedAndEnabled,
                                               shouldEvacuatePoweredOffVms,
                                               evacuationType) {

               var hostMaintenanceModeSpec = {
                  maintenanceOpType: 'enter',
                  evacuatePoweredOffVms: shouldEvacuatePoweredOffVms
               };

               if (isVsanSupportedAndEnabled) {
                  hostMaintenanceModeSpec.maintenanceSpec = {
                     _type: "com.vmware.vim.binding.vim.host.MaintenanceSpec",
                     vsanMode: {
                        _type: "com.vmware.vim.binding.vim.vsan.host.DecommissionMode",
                        objectAction: evacuationType
                     }
                  };
               }

               return hostMaintenanceModeSpec;
            }

            function navigateToPrecheckView(clusterMor, selectedEntity, selectedVsanEvacuationType) {
               var viewId = 'com.vmware.vsan.client.h5vsanui.cluster.monitor.vsan.evacuationStatus';

               var context = {
                  selectedEntity: selectedEntity,
                  selectedEvacuationType: selectedVsanEvacuationType
               };

               var navigationData = {
                  targetViewId: viewId,
                  objectId: defaultUriSchemeUtil.getVsphereObjectId(clusterMor),
                  customData: context
               };

               h5SdkApplicationService.navigateTo(navigationData);
            }
         }]);
