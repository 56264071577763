/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
(function () {
   'use strict';
   angular
         .module('com.vmware.vsphere.client.network')
         .controller('PortSecurityPolicyPageController',
               PortSecurityPolicyPageController);

   PortSecurityPolicyPageController.$inject = [
      '$scope',
      'networkUtil',
      'editPortgroupService'];

   function PortSecurityPolicyPageController($scope, networkUtil, editPortgroupService) {
      $scope.options = [{
         label: networkUtil.getString('PortSecurityPolicyPage.accept'),
         val: true
      }, {
         label: networkUtil.getString('PortSecurityPolicyPage.reject'),
         val: false
      }];

      var initialData = $scope.initialData.securityPolicies;

      $scope.manager.commitPageCallbacks.commitSecurityPage = onCommit;

      function onCommit() {
         $scope.manager.pageDirtyFlags.isSecurityPageDirty = editPortgroupService
               .hasSecurityPolicyChanged(initialData,
                     $scope.manager.portgroupData.securityPolicies, true);
         return true;
      }

      return this;
   }
})();
