/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace spbm_ui {

   import VmStorageProfilesData = com.vmware.vsphere.client.h5.spbm.data.VmStorageProfilesData;
   import SpbmComplianceFailures = com.vmware.vsphere.client.h5.spbm.data.SpbmComplianceFailure;

   import IPromise = angular.IPromise;
   interface VmStorageProfilesDataWithFormattedDate extends VmStorageProfilesData{
      formatedDate:string;
      _complianceFailures: SpbmComplianceFailures[];
   }

   export class VmStoragePoliciesListController {

      public i18n: Function;

      public ACTIONS: any;

      public datagridOptions: any;

      private policyAssignments: VmStorageProfilesDataWithFormattedDate[];

      private masterDetailsViewContext: any;

      private vmId: string;

      private itemToSelect: any;

      public static $inject = [
            "i18nService",
            "vuiConstants",
            "columnRenderersRegistry",
            "timeFormatterService",
            "$q",
            "datagridActionBarService",
            "listUtil",
            "VmStoragePoliciesConstants",
            "$scope"];

      constructor(private i18nService: any,
                  private vuiConstants: any,
                  private columnRenderersRegistry: any,
                  private timeFormatterService: any,
                  private $q: any,
                  private datagridActionBarService: any,
                  private listUtil: any,
                  private VmStoragePoliciesConstants: any,
                  private $scope: any) {

         this.i18n = i18nService.getString;
         this.ACTIONS = {
            CHECK_VM_COMPLIANCE: 'vsphere.core.vm.actions.checkVmRollupComplianceAction',
            REAPPLY_VM_STORAGE_POLICY: 'vsphere.core.pbm.storage.reapplyProfileForVms'
         };
      }

      public $onInit() {
         this.datagridOptions = {
            height: "100%",
            columnDefs: this.getColumnDefinitions(),
            selectionMode: this.vuiConstants.grid.selectionMode.SINGLE,
            data: [],
            resizable: true,
            selectedItems: [],
            actionBarOptions: {actions: []}
         };

         this.$scope.$watch( () => {
            return this.datagridOptions.selectedItems;
         }, (newValue: any, oldValue:any) => {
            if (newValue !== oldValue) {
               if (this.masterDetailsViewContext) {
                  this.masterDetailsViewContext.selectedItems = newValue;
               }
            }
         });
      }

      public preselectComparator = (gridDataItem: any) => {
         if (this.itemToSelect) {
            return this.itemToSelect.name === gridDataItem.name;
         }
         return false;
      };

      public $onChanges() {
         if (this.policyAssignments) {
            let lastCheckedTimeFormatPromises: any[] = [];

            _.forEach(this.policyAssignments, (policyAssignment:any) => {
               let lastChecked = policyAssignment.lastChecked ? policyAssignment.lastChecked : "";
               lastCheckedTimeFormatPromises.push(this.timeFormatterService.formatDate(lastChecked));
               policyAssignment._complianceFailures = policyAssignment.complianceFailures;
               let complianceItem: any = this.formatComplianceItem(policyAssignment.complianceStatus);
               policyAssignment.complianceStatus = complianceItem.complianceStatusText;
               policyAssignment.complianceStatusIcon = complianceItem.complianceStatusIcon;
            });

            this.$q.all(lastCheckedTimeFormatPromises).then((result:any) => {
               _.forEach(result, (formatedDate:string, formatteDateIndex:number) => {
                  this.policyAssignments[formatteDateIndex].formatedDate = formatedDate;
               });

               if (this.masterDetailsViewContext && this.masterDetailsViewContext.selectedItems &&
                     this.masterDetailsViewContext.selectedItems.length === 1) {
                  this.itemToSelect = this.masterDetailsViewContext.selectedItems[0];
               } else {
                  this.itemToSelect = null;
               }
               this.datagridOptions.data = this.policyAssignments;
               this.updateActionBar();
            });
         }
      }

      private updateActionBar = () => {
         return this.datagridActionBarService.updateActionBar(this.datagridOptions, [this.vmId], [
            {
               actionId: this.ACTIONS.CHECK_VM_COMPLIANCE
            },
            {
               actionId: this.ACTIONS.REAPPLY_VM_STORAGE_POLICY
            },
         ]);
      }

      private getColumnDefinitions() {
         let iconTextRenderer = this.columnRenderersRegistry.getColumnRenderer("icon-text");
         let storagePolicyComplianceStatus = this.columnRenderersRegistry.getColumnRenderer('storage-policy-compliance-status');
         let compareNumericValues = this.listUtil.compareNumericValues;

         return [
            {
               displayName: this.i18nService.getString("SpbmUi", "nameColumnHeader"),
               field: "name",
               template: (item: VmStorageProfilesData) => {
                  let iconClass = item.type === this.VmStoragePoliciesConstants.device.type.VM_HOME
                        ? "vx-icon-virtualMachineFolder" : "vx-icon-vmDisk";
                  let profileItem: Object = {
                     name: item.name,
                     icon: iconClass
                  };
                  return iconTextRenderer(
                        ["icon", "name"], profileItem);
               }
            },
            {
               displayName: this.i18nService.getString("SpbmUi", "manageVmReplicationGroupsForm.profileColumn"),
               field: "storagePolicy",
               template: (item: VmStorageProfilesData) => {
                  let profileItem = {
                     storagePolicy: item.storagePolicy,
                     icon: ""
                  };

                  if (profileItem.storagePolicy) {
                     profileItem.icon = "spbm-ui-icon-storage-profile";
                  } else {
                     profileItem.storagePolicy = this.i18nService.getString("SpbmUi", "viewVmProfileAssignments.noneProfileLabel");
                  }

                  return iconTextRenderer(
                        ["icon", "storagePolicy"], profileItem);
               }
            },
            {
               displayName: this.i18nService.getString("SpbmUi", "complianceStatusColumnHeader"),
               field: "complianceStatus",
               template: (item: any) => {
                  let complianceItem: any = {
                     complianceStatusIcon: item.complianceStatusIcon,
                     complianceStatusText: item.complianceStatus
                  };
                  return iconTextRenderer(
                        ["complianceStatusIcon", "complianceStatusText"], complianceItem);
               }
            },
            {
               displayName: this.i18nService.getString("SpbmUi", "lastCheckedColumnHeader"),
               field: "lastChecked",
               template: (item: VmStorageProfilesDataWithFormattedDate) => {
                  return item.formatedDate;
               },
               sortable: function (item1:any, item2:any) {
                  return compareNumericValues(item1, item2, "lastChecked");
               }
            }
         ];
      }

      private formatComplianceItem(complianceStatus: string) : any {
         let iconClass: string = "";
         let complianceStatusKey: string = "";
         switch (complianceStatus) {
            case 'compliant':
               complianceStatusKey = "complianceStatus.compliant";
               iconClass = 'vsphere-icon-status-ok';
               break;
            case 'nonCompliant':
               complianceStatusKey = "complianceStatus.nonCompliant";
               iconClass = 'vsphere-icon-status-error';
               break;
            case 'outOfDate':
               complianceStatusKey = "complianceStatus.outOfDate";
               iconClass = 'vsphere-icon-status-error';
               break;
            case 'unknown':
               complianceStatusKey = "complianceStatus.unknown";
               iconClass = 'vui-icon-datagrid-status-unknown';
               break;
            case 'notApplicable':
               complianceStatusKey = "complianceStatus.notApplicable";
               iconClass = 'vui-icon-datagrid-status-notApplicable';
               break;
         }

         let label: string = complianceStatusKey === "" ? complianceStatusKey
               : this.i18nService.getString("SpbmUi", complianceStatusKey);
         return {
            complianceStatusIcon: iconClass,
            complianceStatusText: label
         };
      }
   }

   angular.module("com.vmware.vsphere.client.spbm")
         .component("vmStoragePoliciesList", {
            templateUrl: "spbm-ui/resources/spbm/views/vm/configurePolicies/VmStoragePoliciesList.html",
            bindings: {
               policyAssignments: "<",
               vmId: "<",
               masterDetailsViewContext: "<"
            },
            controller: VmStoragePoliciesListController
         });
}
