/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
(function () {
   'use strict';
   angular.module('com.vmware.vsphere.client.dvs').controller(
         'DvPortrgroupPoliciesViewController', DvPortrgroupPoliciesViewController);

   DvPortrgroupPoliciesViewController.$inject = [
         '$scope',
         'dataService',
         'distributedPortgroupPoliciesViewService',
         'i18nService'];

   function DvPortrgroupPoliciesViewController(
         $scope,
         dataService,
         distributedPortgroupPoliciesViewService,
         i18nService) {
      var self = this;
      self.dvPortgroupId = $scope._route.objectId;
      var dvPortgroupPolicies = 'dvpg:dvPortgroupPolicies';

      var headerOptions = {
         title: i18nService.getString(
               'NetworkUi', 'dvpg.manage.settings.policiesHeader'),
         objectId: self.dvPortgroupId,
         actions: [
            {
               actionUid: 'vsphere.core.dvPortgroup.editSettingsAction',
               customLabel: i18nService.getString(
                     'H5NetworkUi', 'dvpg.properties.actions.edit.label')
            }]
      };
      self.settingsViewHeaderAccessor = {};
      self.headerOptions = headerOptions;

      self.refresh = function() {
         self.loading = true;

         if (self.settingsViewHeaderAccessor && self.settingsViewHeaderAccessor.refresh) {
            self.settingsViewHeaderAccessor.refresh();
         }

         dataService.getProperties(self.dvPortgroupId, [dvPortgroupPolicies])
               .then(function(response) {
                  self.loading = false;
                  if (response) {
                     if (response[dvPortgroupPolicies]) {
                        buildPolicies(response[dvPortgroupPolicies]);
                     }
                  }
               });

         self.liveRefreshProperties = [
            "config.defaultPortConfig.vlan",
            "config.defaultPortConfig.vlan.vlanId",
            "config.defaultPortConfig.vlan.pvlanId",
            "config.defaultPortConfig.uplinkTeamingPolicy.failureCriteria.checkBeacon.value",
            "config.defaultPortConfig.uplinkTeamingPolicy.policy.value",
            "config.defaultPortConfig.uplinkTeamingPolicy.notifySwitches.value",
            "config.defaultPortConfig.uplinkTeamingPolicy.rollingOrder.value",
            "config.defaultPortConfig.uplinkTeamingPolicy.uplinkPortOrder.value",
            "config.defaultPortConfig.uplinkTeamingPolicy.uplinkPortOrder.activeUplinkPort",
            "config.defaultPortConfig.uplinkTeamingPolicy.uplinkPortOrder.standbyUplinkPort",
            "config.defaultPortConfig.securityPolicy.macChanges.value",
            "config.defaultPortConfig.securityPolicy.forgedTransmits.value",
            "config.defaultPortConfig.securityPolicy.allowPromiscuous.value",
            "config.defaultPortConfig.outShapingPolicy.burstSize.value",
            "config.defaultPortConfig.outShapingPolicy.peakBandwidth.value",
            "config.defaultPortConfig.outShapingPolicy.averageBandwidth.value",
            "config.defaultPortConfig.outShapingPolicy.enabled.value",
            "config.defaultPortConfig.inShapingPolicy.averageBandwidth.value",
            "config.defaultPortConfig.inShapingPolicy.peakBandwidth.value",
            "config.defaultPortConfig.inShapingPolicy.enabled.value",
            "config.defaultPortConfig.inShapingPolicy.burstSize.value",
            "config.defaultPortConfig.blocked.value",
            "config.defaultPortConfig.ipfixEnabled.value"
         ];
      };

      self.refresh();

      function buildPolicies(policiesData) {
         self.dvPortgroupPoliciesData = distributedPortgroupPoliciesViewService.build(policiesData);
      }
   }
})();
