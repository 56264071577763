/* Copyright 2019 VMware, Inc. All rights reserved. -- VMware Confidential */
(function () {
   "use strict";

   /**
    * This service is slightly modified version of vuiFooterActionsService (from vui-angular.js)
    * vuiFooterActionService is used by the 'Copy All' action in vui-datagrid.
    *
    * The changes are:
    * - ability to copy all items in the grid (not only the visible ones)
    * - changed text format when grouping is used.
    *   Before the change, if we had a grid with 3 columns (A, B, C) and we grouped
    *   rows by column A, we'd end up with the following text in the clipboard:
    *       A       B      C
    *      "a1",   "",    ""
    *          ,   "b1",  "c1"
    *          ,   "b2",  "c2"
    *          ,   "b3",  "c3"
    *      "a2",   "",    ""
    *          ,   "b4",  "c4"
    *          ,   "b5",  "c5"
    *          ,   "b6",  "c6"
    *
    *   After the change the text looks like:
    *
    *       A       B      C
    *      "a1",   "b1",  "c1"
    *      "a1",   "b2",  "c2"
    *      "a1",   "b3",  "c3"
    *
    *      "a2",   "b4",  "c4"
    *      "a2",   "b5",  "c5"
    *      "a2",   "b6",  "c6"
    *
    */
   function kendoGridService(clipboardService) {

      var removeHtmlRegex = /<[^>]+>/gm; //used to strip html markup from rendered cells

      function getRowValue(currentRow, column) {
         if (column.template) {
            return column.template(currentRow).replace(removeHtmlRegex, '');
         }
         return currentRow[column.field];
      }

      function getGroupValue(currentRow, column) {
         return currentRow[column.field] ? currentRow[column.field] : '';
      }

      function buildRowValues(visibleRows, columns, rows, groups) {
         var rowValue = [];
         for (var i = 0; i < visibleRows.length; i++) {
            var currentRow = visibleRows[i];
            var isGroupingRow = !!groups[currentRow.field];

            if (isGroupingRow && currentRow.items && currentRow.items.length) {
               buildRowValues(currentRow.items, columns, rows, groups);
               continue;
            }

            for (var j = 0; j < columns.length; j++) {
               var column = columns[j];
               if (column.isGrouping) {
                  rowValue.push('"' + getGroupValue(currentRow, column) + '"');
               } else if (!column.hidden) {
                  rowValue.push('"' + getRowValue(currentRow, column) + '"');
               }
            }
            rows.push(rowValue.join(','));

            rowValue.length = 0;
         }
      }

      /**
       * Copies all items of a Kendo grid to the clipboard
       *
       * @param kendoDatagridCssSelector
       *    CSS selector of the Kendo grid
       */
      function copyAllItems(kendoDatagridCssSelector) {
         var kendoGrid = angular.element(kendoDatagridCssSelector).data('kendoGrid');
         var columns = kendoGrid.columns;

         var groups = {};
         var headerRowValue = [];

         var columnsData = [];
         //extracting grouping rows
         var grouping = kendoGrid.options.groupOrder;
         if (grouping && grouping.length) {
            angular.forEach(grouping, function (group) {
               groups[group.field] = group;
               var column = $.grep(columns, function (column) {
                  return column.field === group.field;
               })[0];
               headerRowValue.push('"' + column.title + '"');
               columnsData.push({
                  field: column.field,
                  isGrouping: true
               });

            });
         }

         angular.forEach(columns, function (column) {
            if (column && !column.hidden) {
               var columnData = {
                  field: column.field
               };
               if (column.template && column.template !== 'string') {
                  columnData.template = kendo.template(column.template);
               } else {
                  columnData.template = column.template;
               }
               columnsData.push(columnData);
               headerRowValue.push('"' + column.title + '"');
            }
         });

         var rows = [];
         rows.push(headerRowValue.join(','));

         var totalPages = kendoGrid.dataSource.totalPages();
         if (totalPages > 1) {
            var initialPage = kendoGrid.dataSource.page();
            for (var i = 1; i <= totalPages; i++) {
               kendoGrid.dataSource.page(i);
               buildRowValues(kendoGrid.dataSource.view(), columnsData, rows, groups);
            }
            kendoGrid.dataSource.page(initialPage);
         } else {
            buildRowValues(kendoGrid.dataSource.view(), columnsData, rows, groups);
         }

         clipboardService.copyToClipboard(rows.join('\n'));
      }

      /**
       * The public API of the service
       */
      return {
         /**
          * copies all items of the grid to the clipboard
          */
         copyAllItems: copyAllItems
      };
   }

   angular.module("com.vmware.vsphere.client.storage")
      .factory("kendoGridService", ['clipboardService', kendoGridService]);

})();
