module ds_cluster_ui {

   export class StorageDrsLocalizationService {

      public static $inject = ["i18nService", "dsClusterConstants"];

      constructor(private i18nService: any, private dsClusterConstants: any) {}

      /**
       * Retrieves the localized description text based on the automation level passed.
       *
       * @param automationLevel
       * @returns {string}
       */
      public getAutomationLevelDescriptionLocalization(automationLevel: string | null) {
         let localizedAutomationLevelDescription: string = "";

         switch (automationLevel) {
            case this.dsClusterConstants.SDRS_MANUAL:
               localizedAutomationLevelDescription =
                     this.getString("sdrs.config.automationLevel.manualDescription");
               break;
            case this.dsClusterConstants.SDRS_FULLAUTO:
               localizedAutomationLevelDescription =
                     this.getString("sdrs.config.automationLevel.fullautoDescription");
               break;
         }

         return localizedAutomationLevelDescription;
      }

      /**
       * Retrieves the localized text based on the automation level passed.
       *
       * @param automationLevel
       * @returns {string}
       */
      public getAutomationLevelTextLocalization(automationLevel: any) {
         let localizedAutomationLevelText: string = "";

         switch (automationLevel) {
            case this.dsClusterConstants.SDRS_MANUAL:
               localizedAutomationLevelText =
                     this.getString("sdrs.config.automationLevel.manual");
               break;
            case this.dsClusterConstants.SDRS_FULLAUTO:
               localizedAutomationLevelText =
                     this.getString("sdrs.config.automationLevel.fullauto");
               break;
            case null:
               localizedAutomationLevelText =
                     this.getString("sdrs.config.automationLevel.useClusterAutomationLabel");
               break;
         }

         return localizedAutomationLevelText;
      }

      /**
       * Retrieves the localized text based on the space load balance configuration
       * passed.
       *
       * @param spaceLoadBalanceConfig
       * @returns {string}
       */
      public getSpaceThresholdText(spaceThresholdMode: string,
            freeSpaceThresholdGB: number, spaceUtilizationThreshold: number) {
         let spaceThresholdText: string = "";

         switch (spaceThresholdMode) {
            case this.dsClusterConstants.SDRS_SPACE_THRESHOLD_FREE_SPACE:
               spaceThresholdText = this.getString(
                     "sdrs.config.spaceThreshold.minFreeSpaceFormat",
                     freeSpaceThresholdGB);
               break;
            case this.dsClusterConstants.SDRS_SPACE_THRESHOLD_UTILIZATION:
               spaceThresholdText = this.getString(
                     "sdrs.config.spaceThreshold.utlizedSpaceFormat",
                     spaceUtilizationThreshold);
               break;
         }

         return spaceThresholdText;
      }

      /**
       * Retrieves the localized text based on the IO load balance configuration
       * passed.
       *
       * @param ioLoadBalanceEnabled
       * @returns {string}
       */
      public getIoMetricInclusionText(ioLoadBalanceEnabled: boolean){
         if (ioLoadBalanceEnabled) {
            return this.getString("sdrs.config.ioLoadBalanceEnabled");
         } else {
            return this.getString("sdrs.config.ioLoadBalanceDisabled");
         }
      }

      /**
       * Retrieves the localized description based on the IO balance configuration
       * passed.
       *
       * @param ioLoadBalanceEnabled
       * @returns {string}
       */
      public getIoMetricInclusionDescription(ioLoadBalanceEnabled: boolean) {
         if (ioLoadBalanceEnabled) {
            return this.getString("sdrs.config.ioLoadBalanceEnabled.description");
         } else {
            return this.getString("sdrs.config.ioLoadBalanceDisabled.description");
         }
      }

      /**
       * Retrieves the localized description based on the IO latency threshold
       * configuration passed.
       *
       * @param ioLatencyThreshold
       * @returns {string}
       */
      public getIoLatencyThresholdText(ioLatencyThreshold: number) {
         return this.getString("sdrs.config.ioLatencyThresholdText.format",
               ioLatencyThreshold);
      }



      /**
       * Retrieves the localized text based on the min space utilization difference
       * passed.
       *
       * @param minSpaceUtilizationDifference
       * @returns {string}
       */
      public getMinSpaceUtilizationDifferenceText(minSpaceUtilizationDifference: number) {
         return this.getString("sdrs.config.minSpaceUtilizationDifference.format",
               minSpaceUtilizationDifference);
      }


      /**
       * Retrieves the localized text based on the default intra vm affinity
       * configuration passed.
       *
       * @param defaultIntraVmAffinity
       * @returns {string}
       */
      public getDefaultIntraVmAffinityText(defaultIntraVmAffinity: boolean) {
         if (defaultIntraVmAffinity) {
            return this.getString("sdrs.config.advancedOptions.keepVmdskTogether.yes.label");
         } else {
            return this.getString("sdrs.config.advancedOptions.keepVmdskTogether.no.label");
         }
      }

      /**
       * Retrieves mapping for advanced options.
       *
       * @param advancedOptions
       * @returns {string}
       */
      public getAdvancedOptions(advancedOptions: any []) {
         return _.map(advancedOptions, function (advancedOption) {
            return {
               _type: "com.vmware.vim.binding.vim.option.OptionValue",
               key: advancedOption.key,
               value: advancedOption.value
            };
         });
      }

      /**
       * Retrieves the localized summary portlet metric text based on the IO balance
       * configuration passed.
       *
       * @param ioLoadBalanceEnabled
       * @returns {string}
       */
      public getIoMetricInclusion(ioLoadBalanceEnabled: boolean){
         if (ioLoadBalanceEnabled) {
            return this.getString("dscluster.services.iometrics.true");
         } else {
            return this.getString("dscluster.services.iometrics.false");
         }
      }

      /**
       * Retrieves the localized summary portlet text based on the space load balance
       * configuration passed.
       *
       * @param ioLoadBalanceEnabled
       * @returns {string}
       */
      public getPortletSpaceThresholdText(spaceLoadBalanceConfig: SpaceLoadBalanceConfig) {
         spaceLoadBalanceConfig = spaceLoadBalanceConfig || {};

         switch (spaceLoadBalanceConfig.spaceThresholdMode) {
            case this.dsClusterConstants.SDRS_SPACE_THRESHOLD_FREE_SPACE:
               return this.getString("dscluster.services.spaceThreshold.minFreeSpaceFormat",
                     spaceLoadBalanceConfig.freeSpaceThresholdGB);
            case this.dsClusterConstants.SDRS_SPACE_THRESHOLD_UTILIZATION:
               return this.getString("dscluster.services.spaceThreshold.utlizedSpaceFormat",
                     spaceLoadBalanceConfig.spaceUtilizationThreshold);
         }
      }

      private getString(key: string, ...params: any[]) {
         return this.i18nService.getString("DsClusterUi", key, params);
      }

   }

   angular.module("com.vmware.vsphere.client.dsCluster")
         .service("storageDrsLocalizationService", StorageDrsLocalizationService);
}
