module ds_cluster_ui {

   import IRootScopeService = angular.IRootScopeService;

   export class DsClusterSpecBuilderService {

      public static $inject = ["dsClusterConstants"];

      constructor(private dsClusterConstants: any) { }

      /**
       * Builds ds cluster spec.
       *
       * @param formData DsClusterModel
       * @param modify boolean
       * @returns {Object}
       */
      public buildDsClusterSpec(formData: DsClusterModel, modify: boolean): any {
         let spec: any = {
            configSpec: {
               _type: "com.vmware.vim.binding.vim.storageDrs.ConfigSpec",
               podConfigSpec: {
                  _type: "com.vmware.vim.binding.vim.storageDrs.PodConfigSpec",
                  ioLoadBalanceConfig: {
                     _type: "com.vmware.vim.binding.vim.storageDrs.IoLoadBalanceConfig"
                  }
               }
            },
            modify: modify
         };

         if (!modify || angular.isDefined(formData.name)) {
            spec.name = formData.name;
         }

         if (angular.isDefined(formData.parent)) {
            spec.parent = formData.parent;
         }

         if (angular.isDefined(formData.datastoreRefs)) {
            spec.datastores = formData.datastoreRefs;
         }

         if (angular.isDefined(formData.sdrsEnabled)) {
            spec.configSpec.podConfigSpec.enabled = formData.sdrsEnabled;
         } else {
            // sdrs disabled by default
            spec.configSpec.podConfigSpec.enabled = false;
         }

         if (angular.isDefined(formData.defaultVmBehavior)) {
            spec.configSpec.podConfigSpec.defaultVmBehavior = formData.defaultVmBehavior;
         }

         if (angular.isDefined(formData.ioLoadBalanceEnabled)) {
            spec.configSpec.podConfigSpec.ioLoadBalanceEnabled = formData.ioLoadBalanceEnabled;
         }

         if (formData.ioLatencyThreshold !== undefined) {
            spec.configSpec.podConfigSpec.ioLoadBalanceConfig.ioLatencyThreshold =
                  Math.round(formData.ioLatencyThreshold);
         }

         if (angular.isDefined(formData.defaultIntraVmAffinity)) {
            spec.configSpec.podConfigSpec.defaultIntraVmAffinity = formData.defaultIntraVmAffinity;
         }

         if (angular.isDefined(formData.loadBalanceInterval)) {
            spec.configSpec.podConfigSpec.loadBalanceInterval = formData.loadBalanceInterval;
         }

         if (angular.isDefined(formData.ioLoadImbalanceThreshold)) {
            spec.configSpec.podConfigSpec.ioLoadBalanceConfig.ioLoadImbalanceThreshold =
                  formData.ioLoadImbalanceThreshold;
         }

         if (formData.spaceLoadBalanceConfig !== undefined) {

            spec.configSpec.podConfigSpec.spaceLoadBalanceConfig = {
               _type: "com.vmware.vim.binding.vim.storageDrs.SpaceLoadBalanceConfig"
            };

            if (angular.isDefined(formData.spaceLoadBalanceConfig.spaceThresholdMode)) {
               spec.configSpec.podConfigSpec.spaceLoadBalanceConfig.spaceThresholdMode =
                     formData.spaceLoadBalanceConfig.spaceThresholdMode;
            } else {
               spec.configSpec.podConfigSpec.spaceLoadBalanceConfig.spaceThresholdMode =
                     this.dsClusterConstants.SDRS_SPACE_THRESHOLD_UTILIZATION;
               spec.configSpec.podConfigSpec.spaceLoadBalanceConfig.spaceUtilizationThreshold =
                     this.dsClusterConstants.spaceUtilizationThreshold.DEFAULT;
            }

            if (formData.spaceLoadBalanceConfig.spaceUtilizationThreshold !== undefined) {
               spec.configSpec.podConfigSpec.spaceLoadBalanceConfig.spaceUtilizationThreshold =
                     Math.round(formData.spaceLoadBalanceConfig.spaceUtilizationThreshold);
            }

            if (formData.spaceLoadBalanceConfig.freeSpaceThresholdGB !== undefined) {
               spec.configSpec.podConfigSpec.spaceLoadBalanceConfig.freeSpaceThresholdGB =
                     Math.round(formData.spaceLoadBalanceConfig.freeSpaceThresholdGB);
            }

            if (angular.isDefined(formData.spaceLoadBalanceConfig.minSpaceUtilizationDifference)) {
               spec.configSpec.podConfigSpec.spaceLoadBalanceConfig.minSpaceUtilizationDifference =
                     formData.spaceLoadBalanceConfig.minSpaceUtilizationDifference;
            }

         }

         if (formData.advancedOptions !== undefined) {

            spec.configSpec.podConfigSpec.option = {
               _type: "com.vmware.vim.binding.vim.storageDrs.OptionSpec"
            };

            if (angular.isDefined(formData.advancedOptions)) {
               spec.configSpec.podConfigSpec.option =
                     formData.advancedOptions.buildOptions();
            }
         }

         if (angular.isDefined(formData.automationOverrides)) {
            let automationOverridesObject: Object =
                  _.mapObject(formData.automationOverrides, function(automationOverride) {
                     if (automationOverride !== null) {
                        return automationOverride.id;
                     }
            });

            spec.configSpec.podConfigSpec.automationOverrides =
                  angular.merge({
                        _type: "com.vmware.vim.binding.vim.storageDrs.AutomationConfig"},
                        automationOverridesObject);
         }

         return spec;
      }
   }

   angular.module("com.vmware.vsphere.client.dsCluster")
         .service("dsClusterSpecBuilderService", DsClusterSpecBuilderService);
}
