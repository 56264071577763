module cluster_ui {

   export class ClusterUtilizationService {

      public static $inject = ["vxPropertyViewService",
         "i18nService", "capacityFormatterService"];

      constructor(
            private vxPropertyViewService: any,
            private i18nService: any,
            private capacityFormatterService: any) {
      }

      public buildCpuPropertyViewData(data: any): Object {
         var builder = this.vxPropertyViewService.createPropertyViewBuilder();
         var sectionBuilder = builder
               .category("")
               .section("");

         sectionBuilder.propertyBuilder()
               .keyBuilder()
               .text(this.i18nService.getString("ClusterUi", "consumed"))
               .icon("vx-property-view-key-legend-icon cluster-utilization-icon-color-light-blue")
               .exit()
               .valueBuilder()
               .text(data.cpuConsumedFormatted);
         sectionBuilder.propertyBuilder()
               .keyBuilder()
               .text(this.i18nService.getString("ClusterUi", "active"))
               .icon("vx-property-view-key-legend-icon cluster-utilization-icon-color-normal-blue")
               .exit()
               .valueBuilder()
               .text(data.cpuActiveFormatted);
         sectionBuilder.propertyBuilder()
               .keyBuilder()
               .text(this.i18nService.getString("ClusterUi", "capacity"))
               .exit()
               .valueBuilder()
               .text(data.cpuCapacityFormatted);

         return builder.build();
      }

      public buildMemoryPropertyViewData(data: any): Object {
         var builder = this.vxPropertyViewService.createPropertyViewBuilder();
         var sectionBuilder = builder
               .category("")
               .section("");

         sectionBuilder.propertyBuilder()
               .keyBuilder()
               .text(this.i18nService.getString("ClusterUi", "consumed"))
               .icon("vx-property-view-key-legend-icon cluster-utilization-icon-color-light-blue")
               .exit()
               .valueBuilder()
               .text(data.memoryConsumedFormatted);
         sectionBuilder.propertyBuilder()
               .keyBuilder()
               .text(this.i18nService.getString("ClusterUi", "overhead"))
               .icon("vx-property-view-key-legend-icon cluster-utilization-icon-color-desaturated-blue")
               .exit()
               .valueBuilder()
               .text(data.memoryOverheadFormatted);
         sectionBuilder.propertyBuilder()
               .keyBuilder()
               .text(this.i18nService.getString("ClusterUi", "capacity"))
               .exit()
               .valueBuilder()
               .text(data.memoryCapacityFormatted);

         return builder.build();
      }

      public buildGuestMemoryPropertyViewData(data: any): Object {
         var builder = this.vxPropertyViewService.createPropertyViewBuilder();
         var sectionBuilder = builder
               .category("")
               .section("");

         sectionBuilder.propertyBuilder()
               .keyBuilder()
               .text(this.i18nService.getString("ClusterUi", "activeGuestMemory"))
               .icon("vx-property-view-key-legend-icon cluster-utilization-icon-color-light-green")
               .exit()
               .valueBuilder()
               .text(data.activeGuestMemoryFormatted);
         sectionBuilder.propertyBuilder()
               .keyBuilder()
               .text(this.i18nService.getString("ClusterUi", "privateMemory"))
               .icon("vx-property-view-key-legend-icon cluster-utilization-icon-color-normal-green")
               .exit()
               .valueBuilder()
               .text(data.privateMemoryFormatted);
         sectionBuilder.propertyBuilder()
               .keyBuilder()
               .text(this.i18nService.getString("ClusterUi", "sharedMemory"))
               .icon("vx-property-view-key-legend-icon cluster-utilization-icon-color-dark-blue")
               .exit()
               .valueBuilder()
               .text(data.sharedMemoryFormatted);
         sectionBuilder.propertyBuilder()
               .keyBuilder()
               .text(this.i18nService.getString("ClusterUi", "compressedMemory"))
               .icon("vx-property-view-key-legend-icon cluster-utilization-icon-color-normal-blue")
               .exit()
               .valueBuilder()
               .text(data.compressedMemoryFormatted);
         sectionBuilder.propertyBuilder()
               .keyBuilder()
               .text(this.i18nService.getString("ClusterUi", "swappedMemory"))
               .icon("vx-property-view-key-legend-icon cluster-utilization-icon-color-normal-orange")
               .exit()
               .valueBuilder()
               .text(data.swappedMemoryFormatted);
         sectionBuilder.propertyBuilder()
               .keyBuilder()
               .text(this.i18nService.getString("ClusterUi", "balloonedMemory"))
               .icon("vx-property-view-key-legend-icon cluster-utilization-icon-color-normal-yellow")
               .exit()
               .valueBuilder()
               .text(data.balloonedMemoryFormatted);
         sectionBuilder.propertyBuilder()
               .keyBuilder()
               .text(this.i18nService.getString("ClusterUi", "unaccessedMemory"))
               .icon("vx-property-view-key-legend-icon cluster-utilization-icon-color-normal-gray")
               .exit()
               .valueBuilder()
               .text(data.unaccessedMemoryFormatted);

         return builder.build();
      }


      private buildLimitReservationSection(sectionBuilder: any, data: any): void {
         sectionBuilder.propertyBuilder()
               .keyBuilder()
               .text(this.i18nService.getString("ClusterUi", "reservation"))
               .icon("vx-property-view-key-legend-icon vm-utilization-reservation-arrow-icon")
               .exit()
               .valueBuilder()
               .text(data.reservation);
         sectionBuilder.propertyBuilder()
               .keyBuilder()
               .text(this.i18nService.getString("ClusterUi", "limit"))
               .icon("vx-property-view-key-legend-icon vm-utilization-limit-arrow-icon")
               .exit()
               .valueBuilder()
               .text(data.limit);
      }

      private buildSharesWorstCaseAllocationSection(sectionBuilder: any, data: any): void {
         sectionBuilder.propertyBuilder()
               .keyBuilder()
               .text(this.i18nService.getString("ClusterUi", "shares"))
               .exit()
               .valueBuilder()
               .text(data.sharesInfo);
         if (data.clusterDrsEnabled) {
            sectionBuilder.propertyBuilder()
                  .keyBuilder()
                  .text(this.i18nService.getString("ClusterUi", "worstCaseAllocation"))
                  .icon("vx-property-view-key-legend-icon vm-utilization-worst-case-allocation-arrow-icon")
                  .exit()
                  .valueBuilder()
                  .text(data.staticEntitlement);
         }
      }

      public buildVmMemoryPropertyViewData(data: any): Object {
         var builder = this.vxPropertyViewService.createPropertyViewBuilder();
         var sectionBuilder = builder
               .category("")
               .section("");
         sectionBuilder.propertyBuilder()
               .keyBuilder()
               .text(this.i18nService.getString("ClusterUi", "vmConsumed"))
               .icon("vx-property-view-key-legend-icon cluster-utilization-icon-color-light-blue")
               .exit()
               .valueBuilder()
               .text(data.consummation);
         sectionBuilder.propertyBuilder()
               .keyBuilder()
               .text(this.i18nService.getString("ClusterUi", "vmOverheadConsumed"))
               .icon("vx-property-view-key-legend-icon cluster-utilization-icon-color-desaturated-blue")
               .exit()
               .valueBuilder()
               .text(data.overheadMemory);
         this.buildLimitReservationSection(sectionBuilder, data);
         sectionBuilder.propertyBuilder()
               .keyBuilder()
               .text(this.i18nService.getString("ClusterUi", "configuredMemory"))
               .exit()
               .valueBuilder()
               .text(data.resourceSize);
         this.buildSharesWorstCaseAllocationSection(sectionBuilder, data);
         sectionBuilder.propertyBuilder()
               .keyBuilder()
               .text(this.i18nService.getString("ClusterUi", "overheadReservation"))
               .exit()
               .valueBuilder()
               .text(data.overheadAllocation);
         sectionBuilder.propertyBuilder()
               .keyBuilder()
               .text(this.i18nService.getString("ClusterUi", "vmUsedReservation"))
               .exit()
               .valueBuilder()
               .text(data.usedReservation);

         return builder.build();
      }


      public buildResourcePoolMemoryPropertyViewData(data: any): Object {
         let isResourcePoolObject: boolean =
               data.provider && data.provider.type === "ResourcePool";
         let builder: any = this.vxPropertyViewService.createPropertyViewBuilder();
         let sectionBuilder: any = builder
               .category("")
               .section("");

         let consumedLabel: string = this.i18nService.getString(
               "ClusterUi", "vAppConsumed");
         let overheadLabel: string = this.i18nService.getString(
               "ClusterUi", "vAppOverheadConsumed");

         if (isResourcePoolObject) {
            consumedLabel = this.i18nService.getString("ClusterUi", "consumed");
            overheadLabel = this.i18nService.getString("ClusterUi", "overhead");
         }

         sectionBuilder.propertyBuilder()
               .keyBuilder()
               .text(consumedLabel)
               .icon("vx-property-view-key-legend-icon cluster-utilization-icon-color-light-blue")
               .exit()
               .valueBuilder()
               .text(data.consummation);
         sectionBuilder.propertyBuilder()
               .keyBuilder()
               .text(overheadLabel)
               .icon("vx-property-view-key-legend-icon cluster-utilization-icon-color-desaturated-blue")
               .exit()
               .valueBuilder()
               .text(data.overheadMemory);
         this.buildLimitReservationSection(sectionBuilder, data);
         if (isResourcePoolObject) {
            let ownerCapacityLabel: string = this.i18nService.getString(
                  "ClusterUi", "hostCapacity");

            if (data.parentCluster) {
               ownerCapacityLabel = this.i18nService.getString(
                     "ClusterUi", "clusterCapacity");
            }
            sectionBuilder.propertyBuilder()
                  .keyBuilder()
                  .text(ownerCapacityLabel)
                  .exit()
                  .valueBuilder()
                  .text(data.ownerCapacity);
         }
         this.buildSharesWorstCaseAllocationSection(sectionBuilder, data);
         return builder.build();
      }

      public buildResourcePoolCpuPropertyViewData(data: any): Object {
         var builder = this.vxPropertyViewService.createPropertyViewBuilder();
         var sectionBuilder = builder
               .category("")
               .section("");
         sectionBuilder.propertyBuilder()
               .keyBuilder()
               .text(this.i18nService.getString("ClusterUi", "consumed"))
               .icon("vx-property-view-key-legend-icon cluster-utilization-icon-color-light-blue")
               .exit()
               .valueBuilder()
               .text(data.consummation);
         sectionBuilder.propertyBuilder()
               .keyBuilder()
               .text(this.i18nService.getString("ClusterUi", "active"))
               .icon("vx-property-view-key-legend-icon cluster-utilization-icon-color-normal-blue")
               .exit()
               .valueBuilder()
               .text(data.overallCpuDemand);
         this.buildLimitReservationSection(sectionBuilder, data);
         this.buildSharesWorstCaseAllocationSection(sectionBuilder, data);

         return builder.build();
      }

      public getCpuMetersData(data: any): any[] {
         return [{
            colorClass: "cluster-utilization-light-blue",
            value: data.cpuConsumedPercentage,
            label: this.capacityFormatterService.format(
                  data.cpuConsumedFormatted,
                  data.cpuCapacityFormatted,
                  data.cpuConsumedPercentage)
         }, {
            colorClass: "cluster-utilization-normal-blue",
            value: data.cpuActivePercentage,
            label: this.capacityFormatterService.format(
                  data.cpuActiveFormatted,
                  data.cpuCapacityFormatted,
                  data.cpuActivePercentage)
         }];
      }

      public getMemoryMetersData(data: any): BarMeter[] {
         return [{
            colorClass: "cluster-utilization-light-blue",
            value: data.memoryConsumedPercentage,
            label: this.capacityFormatterService.format(
                  data.memoryConsumedFormatted,
                  data.memoryCapacityFormatted,
                  data.memoryConsumedPercentage)
         }, {
            colorClass: "cluster-utilization-desaturated-blue",
            value: data.memoryOverheadPercentage,
            label: this.capacityFormatterService.format(
                  data.memoryOverheadFormatted,
                  data.memoryCapacityFormatted,
                  data.memoryOverheadPercentage)
         }];
      }

      public getGuestMemoryMetersData(data: any): BarMeter[] {
         return [{
            colorClass: "cluster-utilization-light-green",
            value: data.activeGuestMemoryPercentage,
            label: this.capacityFormatterService.format(
                  data.activeGuestMemoryFormatted,
                  data.guestMemorySizeFormatted,
                  data.activeGuestMemoryPercentage)
         }];
      }

      public getGuestMemoryGroupedMetersData(data: any): BarMeter[] {
         return [{
            colorClass: "cluster-utilization-normal-green",
            value: data.privateMemoryPercentage,
            label: ""
         }, {
            colorClass: "cluster-utilization-dark-blue",
            value: data.sharedMemoryPercentage,
            label: ""
         }, {
            colorClass: "cluster-utilization-normal-blue",
            value: data.compressedMemoryPercentage,
            label: ""
         }, {
            colorClass: "cluster-utilization-normal-orange",
            value: data.swappedMemoryPercentage,
            label: ""
         }, {
            colorClass: "cluster-utilization-normal-yellow",
            value: data.balloonedMemoryPercentage,
            label: ""
         }, {
            colorClass: "cluster-utilization-normal-gray",
            value: data.unaccessedMemoryPercentage,
            label: ""
         }];
      }

      public getMemoryConsumedMetersData(data: any): BarMeter[] {
         return [{
            colorClass: "cluster-utilization-light-blue",
            value: data.consumedMemoryPercentage,
            label: this.capacityFormatterService.format(
                  data.consummation,
                  data.maxValue,
                  data.consumedMemoryPercentage)
         }, {
            colorClass: "cluster-utilization-desaturated-blue",
            value: data.overheadConsumedMemoryPercentage,
            label: this.capacityFormatterService.format(
                  data.overheadMemory,
                  data.maxValue,
                  data.overheadConsumedMemoryPercentage)
         }];
      }

      public getUtilizationPointersData(data: any): BarMeter[] {
         let pointers: any[] = [{
            colorClass: "cluster-utilization-icon-color-normal-black",
            value: data.reservationPercentage,
            icon: "vm-utilization-reservation-arrow-icon"
         }];
         if (data.clusterDrsEnabled) {
            pointers.push({
               colorClass: "cluster-utilization-icon-color-normal-orange ",
               value: data.worstCaseAllocationPercentage,
               icon: "vm-utilization-worst-case-allocation-arrow-icon"
            });
         }
         if (data.limitPercentage !== null) {
            pointers.push({
               colorClass: "cluster-utilization-icon-color-normal-black",
               value: data.limitPercentage,
               icon: "vm-utilization-limit-arrow-icon"
            });
         }
         return pointers;
      }
   }

   angular.module("com.vmware.vsphere.client.cluster")
         .service("clusterUtilizationService", ClusterUtilizationService);
}
