namespace network_ui {
   export class NetStackEditRoutingPageComponent {
      public bindings: any;
      public controller: any;
      public templateUrl: string;

      constructor() {
         this.bindings = {
            pageModel: "<"
         };

         this.controller = NetStackEditRoutingPageComponentController;

         this.templateUrl = "network-ui/resources/network/views/host/netstack/" +
               "edit/pages/netStackEditRoutingPageComponentTemplate.html";
      }
   }

   class NetStackEditRoutingPageComponentController {
      static $inject = ["vxValidatorFactory", "netStackEditRoutingPageValidator", "i18nService"];

      public pageModel: NetStackEditRoutingPageModel;

      private validator: any;

      private ivp4ValidationCallback: () => string[];
      private ivp6ValidationCallback: () => string[];

      constructor(private vxValidatorFactory: any,
                  private netStackEditRoutingPageValidator: NetStackEditRoutingPageValidator,
                  private i18nService: any) {

         this.validator = vxValidatorFactory.create();
      }

      $onInit(): void {
         this.initValidationCallbacks();
      }

      private initValidationCallbacks(): void {
         this.ivp4ValidationCallback = (): string[] => {
            let message: string | null = this.netStackEditRoutingPageValidator
                  .getIpv4GatewayValidationMessage(this.pageModel.defaultGateway);

            return message ? [message] : [];
         };

         this.ivp6ValidationCallback = (): string[] => {
            let message: string | null = this.netStackEditRoutingPageValidator
                  .getIpv6GatewayValidationMessage(this.pageModel.ipV6DefaultGateway);

            return message ? [message] : [];
         };
      }
   }

   angular.module("com.vmware.vsphere.client.network")
         .component("netStackEditRoutingPage", new NetStackEditRoutingPageComponent());
}
