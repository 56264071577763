(function() {
   /**
    * Directive for group members when adding a new VM/Host group.
    */
   'use strict';
   angular.module('com.vmware.vsphere.client.cluster').directive(
         'addGroupMembers', addGroupMembers);

   addGroupMembers.$inject = ['i18nService'];

   function addGroupMembers(i18nService) {

      return {
         restrict: 'E',
         templateUrl:
               'cluster-ui/resources/cluster/views/settings/configuration/groups/addGroupMembers.html',
         scope: {
            groupType: '=',
            groupMembers: '='
         },
         controller: ['$scope', '$element', function($scope) {
            $scope.removeAction = removeGroupMember;
            $scope.addAction = addGroupMember;
            $scope.gridTitle = i18nService.getString('ClusterUi',
                  'groups.members.list.name');

            $scope.$watch('groupType', function(groupType) {
               if (groupType) {
                  $scope.groupMembers = [];
               }
            });
            
            function removeGroupMember(selectedGroupMembers) {
               $scope.groupMembers = $scope.groupMembers.filter(function(object) {
                  return !selectedGroupMembers.some(function(selected) {
                     return selected.id === object.id;
                  });
               });
            }

            function addGroupMember(selectedItems) {
               $scope.groupMembers = $scope.groupMembers.concat(selectedItems);

               return true;
            }
         }]
      };
   }
})();
