(function() {
   'use strict';

   angular
      .module('com.vmware.vsphere.client.vm')
      .controller('VmProvisioningDatastoreRecommendationsController',
         VmProvisioningDatastoreRecommendationsController);

   VmProvisioningDatastoreRecommendationsController.$inject = [
      '$scope',
      'defaultUriSchemeUtil',
      'datastoreRecommendationsDatagridOptionsService'
   ];

   function VmProvisioningDatastoreRecommendationsController(
      $scope,
      defaultUriSchemeUtil,
      datastoreRecommendationsDatagridOptionsService
   ) {
      var self = this;

      self.descriptionText = $scope.modalOptions.dialogData.descriptionText;
      self.dataGridOptions = datastoreRecommendationsDatagridOptionsService.getDatagridOptions(
         $scope.modalOptions.dialogData,
         $scope.modalOptions.dialogData.selection
      );

      $scope.preselectRecommendationComparator = function(recommendation) {
         if (_.isEmpty($scope.modalOptions.dialogData.selection)) {
            return false;
         }
         return recommendation.key === $scope.modalOptions.dialogData.selection.key;
      };

      $scope.$watch(function(){return self.dataGridOptions.selectedItems;}, function(selectedItems) {
         if (!selectedItems.length) {
            return null;
         }

         var selectedRecommendation = selectedItems[0];
         var possibleDatastores = $scope.modalOptions.dialogData.possibleDatastores;
         $scope.modalOptions.dialogData.selection = {
            recommendationSpec: selectedRecommendation,
            datastoreName: getRecommendedDs(selectedRecommendation, possibleDatastores)
         };
      });

      function getRecommendedDs(recommendation, idToNameMap) {
         var datastoreMoRef = recommendation.actions[0].destination;
         var datastoreUid = defaultUriSchemeUtil.getVsphereObjectId(datastoreMoRef);
         return idToNameMap[datastoreUid];
      }
   }
})();
