/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace dvs_ui {

   import IPromise = angular.IPromise;
   import DvsHealthCheckSettingsData =
         com.vmware.vsphere.client.h5.network.dvs.healthcheck.model.DvsHealthCheckSettingsData;
   import DvsUpdateHealthCheckSpec = com.vmware.vsphere.client.dvs.api.spec.DvsUpdateHealthCheckSpec;

   export class DvsHealthCheckManageDialogService {

      public static $inject = [
         "dataService",
         "i18nService",
         "$q",
         "clarityModalService",
         "mutationService",
         "clarityConstants",
         "dvsHealthCheckManageDialogValidator"];

      private static DVS_HEALTH_CHECK_DATA_PROPERTY =
            "dvsHealthCheckManage:dvsHealthCheckSettingsData";

      public constructor(private dataService:any,
                         private i18nService: any,
                         private $q: any,
                         private clarityModalService: any,
                         private mutationService: any,
                         private clarityConstants: any,
                         private dvsHealthCheckManageDialogValidator: DvsHealthCheckManageDialogValidator) {

      }

      public show(dvsUrn: string) {
         let modalOptions:any = {
            title: this.i18nService.getString("DvsUi", "healthCheck.manage.editTitle"),
            defaultButton: "submit",
            alerts: [],
            dialogData: {},
            contentTemplate:
                  "dvs-ui/resources/dvs/healthcheck/manage/editdialog/dvsHealthCheckManageDialog.html"
         };

         let dvsPromise: IPromise<DvsHealthCheckSettingsData>
               = this.dataService.getProperties(dvsUrn,
               DvsHealthCheckManageDialogService.DVS_HEALTH_CHECK_DATA_PROPERTY);

         let initialModel: DvsHealthCheckManageDialogModel | null = null;

         dvsPromise.then((dataServiceResponse: any) => {
                  if (dataServiceResponse && dataServiceResponse[
                        DvsHealthCheckManageDialogService.DVS_HEALTH_CHECK_DATA_PROPERTY]) {

                     let dataResponse = dataServiceResponse[
                           DvsHealthCheckManageDialogService.DVS_HEALTH_CHECK_DATA_PROPERTY];
                     let trimmedName: string = dataResponse.name.trim();
                     let escapedName: string = trimmedName.replace(/%25/g, "%")
                           .replace(/%2f/g, "\/").replace(/%5c/g, "\\");
                     modalOptions.subTitle = escapedName;
                     modalOptions.dialogData.pageModel =
                           new DvsHealthCheckManageDialogModel(dataResponse);
                     initialModel = new DvsHealthCheckManageDialogModel(dataResponse);
                  }
               }
         );

         modalOptions.onSubmit = () => this.onSubmit(initialModel, modalOptions, dvsUrn);

         this.clarityModalService.openOkCancelModal(modalOptions);
      }

      private onSubmit (initialModel: DvsHealthCheckManageDialogModel | null,
                          modalOptions: any,
                          dvsUrn: any): boolean {
         let dvsHealthConfigSpec: DvsUpdateHealthCheckSpec | null = initialModel ?
               initialModel.buildSpec(modalOptions.dialogData.pageModel) : null;
         if (dvsHealthConfigSpec === null) {
            return true;
         }

         let messages: string[] = this.dvsHealthCheckManageDialogValidator.getAllErrors(
               modalOptions.dialogData.pageModel);

         if (messages.length > 0) {
            modalOptions.alerts = _.map(
                  messages,
                  (message: string) => {
                     return {
                        type: this.clarityConstants.notifications.type.ERROR,
                        text: message
                     };
                  });

            return false;
         }

         if ((modalOptions.dialogData.pageModel.vlanMtuEanbled &&
               modalOptions.dialogData.pageModel.vlanMtuInterval === 0) ||
               (modalOptions.dialogData.pageModel.teamingAndFailoverEanbled &&
               modalOptions.dialogData.pageModel.teamingAndFailoverInterval === 0)) {
            return this.showWarningDialog(dvsUrn, dvsHealthConfigSpec._type, dvsHealthConfigSpec);
         }

         this.mutationService.apply(
               dvsUrn, dvsHealthConfigSpec._type, dvsHealthConfigSpec);

         return true;
      }

      private showWarningDialog(dvsUrn: string, type: string,
                                dvsHealthConfigSpec: DvsUpdateHealthCheckSpec): any {

         let confirmationDefered = this.$q.defer();

         let onSubmit: Function = () => {
            this.mutationService.apply(dvsUrn, type, dvsHealthConfigSpec);
            confirmationDefered.resolve(true);
         };

         let onCancel: Function = () => {
            confirmationDefered.resolve(false);
         };

         let modalOptions: any = {
            title: this.i18nService.getString('DvsUi',
                  'healthCheck.manage.dialog.warningDialogTitle'),
            message: this.i18nService.getString('DvsUi',
                  'healthCheck.manage.dialog.warningMessage'),
            clarityIcon: {
               class: "is-warning",
               shape: "warning-standard",
               size: "32"
            },
            submit: onSubmit,
            onCancel: onCancel,
            preserveNewlines: true
         };

         this.clarityModalService.openConfirmationModal(modalOptions);

         return confirmationDefered.promise;
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .service("dvsHealthCheckManageDialogService",
               DvsHealthCheckManageDialogService);
}
