namespace spbm_ui {

   import VmComplianceSummaryData = com.vmware.vsphere.client.h5.spbm.data.VmComplianceSummaryData;

   interface LabelIcon {
      text: string;
      iconClass: string;
   }

   export class VmStoragePoliciesPortletController {

      public static $inject = ["i18nService", "dataService", "navigation",
         "timeFormatterService", "$scope", "actionsService"];

      private readonly PROPERTY: string = "vmComplianceSummary";
      private readonly ACTION_ID: string = "vsphere.core.vm.actions.checkVmRollupComplianceAction";
      private portletConfigObject:any;
      private associatedProfiles: LabelIcon[]|null;
      private complianceStatus: LabelIcon|null;
      private lastCheckedDate: string|null;
      private associatedReplicationGroups: string[]|null;
      private readonly EMPTY: LabelIcon;
      private watchedObjects: string[];

      constructor(private i18nService: any,
                  private dataService: any,
                  private navigation: any,
                  private timeFormatterService: any,
                  private $scope: any,
                  private actionsService: any) {
         this.watchedObjects = [this.navigation.getRoute().objectId];
         this.EMPTY = {
            text: i18nService.getString('SpbmUi', 'string.na'),
            iconClass: ''
         };
      }

      $onInit() {
         this.loadData();
         this.initPortletFooterLink();
      }

      initPortletFooterLink(): void {
         let linkLabel = this.i18nService.getString('SpbmUi', 'storageProfile.actions.checkCompliance');
         this.actionsService.getActions(this.watchedObjects, [this.ACTION_ID]).then((response:any[]) => {
            let actionEval = response[0];
            this.portletConfigObject.footerLinks = [{
               enabled: actionEval && actionEval.available,
               label: linkLabel,
               ariaLabel: this.i18nService.getString("SpbmUi", "checkVmRollupComplianceAction.label"),
               onClick: () => {
                  this.actionsService.invokeAction(actionEval);
               }
            }];
         });

      }

      loadData = (): void => {
         this.dataService.getProperties(this.watchedObjects[0], [this.PROPERTY]).then(this.render);
      };

      render = (response: any): void => {

         let vmProfileData:VmComplianceSummaryData = response.vmComplianceSummary;
         if (!vmProfileData || !vmProfileData.hasSpbmViewPrivilege) {
            this.complianceStatus = null;
            this.associatedProfiles = null;
            this.lastCheckedDate = null;
            return;
         }

         if (vmProfileData.oldestCheckTime) {
            this.timeFormatterService.formatDate(vmProfileData.oldestCheckTime)
                  .then((formattedDate:string)=> {
                     this.lastCheckedDate = formattedDate;
                  });
         }
         let storagePolicyNames = vmProfileData.storagePolicyNames;

         this.associatedProfiles = _.map(storagePolicyNames, function(policyName: any) {
            return {
               text: policyName,
               iconClass: 'spbm-ui-icon-storage-profile'
            };
         });

         this.associatedReplicationGroups = vmProfileData.replicationGroupNames;

         if (vmProfileData.complianceStatus) {
            this.complianceStatus = this.formatComplianceStatus(
                  vmProfileData.complianceStatus);
         }
      };

      formatComplianceStatus(status: string): LabelIcon {

         let iconClass:string;
         let statusLabel = "complianceStatus." + status;
         switch (status) {
            case "compliant":
            {
               iconClass = "vx-icon-statusCompliant";
               break;
            }
            case "nonCompliant":
            {
               iconClass = "vx-icon-statusNotCompliant";
               break;
            }
            case "outOfDate":
            {
               iconClass = "vx-icon-statusNotCompliant";
               break;
            }
            case "notApplicable":
            {
               iconClass = "vx-icon-statusNotApplicable";
               break;
            }
            default:
               iconClass = "vsphere-icon-status-unknown";
               break;
         }

         return {
            text: this.i18nService.getString('SpbmUi', statusLabel),
            iconClass: iconClass
         };
      }


   }

   angular.module("com.vmware.vsphere.client.spbm")
         .component("vmStoragePoliciesPortlet", {
            templateUrl: "spbm-ui/resources/spbm/views/vm/VmStoragePoliciesPortlet.html",
            controller: VmStoragePoliciesPortletController,
            bindings: {
               portletConfigObject: "="
            }
         });

}
