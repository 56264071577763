(function () {
   'use strict';

   angular.module('com.vmware.vsphere.client.cluster')
         .service('clusterSpecBuilderService', clusterSpecBuilderService);

   clusterSpecBuilderService.$inject = ['drsMigrationThresholdService', 'drsConstants',
         'haConstants', 'dpmThresholdService'];

   function clusterSpecBuilderService(drsMigrationThresholdService, drsConstants,
         haConstants, dpmThresholdService) {

      return {
         buildClusterComputeResourceSpec: buildClusterComputeResourceSpec
      };

      /**
       * Builds a ClusterComputeResourceSpec based on the formData specified.
       *
       * @param formData
       * @param modify If true, the spec will be incrementally built.
       * @param hciEnabled flag indicating if HCI should be enabled.
       * @returns The constructed ClusterComputeResourceSpec object
       */
      function buildClusterComputeResourceSpec(formData, modify, hciEnabled) {
         var spec = {
            configSpecEx: {
               _type: 'com.vmware.vim.binding.vim.cluster.ConfigSpecEx',
               drsConfig: {
                  _type: 'com.vmware.vim.binding.vim.cluster.DrsConfigInfo'
               },
               dpmConfig: {
                  _type: 'com.vmware.vim.binding.vim.cluster.DpmConfigInfo'
               }
            },
            modify: modify
         };

         if (!modify || angular.isDefined(formData.clusterName)) {
            spec.name = formData.clusterName;
         }

         if (!modify || angular.isDefined(formData.parent)) {
            spec.parent = formData.parent;
         }

         if (formData.currentEvcMode && angular.isDefined(formData.currentEvcMode.key)) {
            spec.evcModeKey = formData.currentEvcMode.key;
         }

         if (hciEnabled) {
            spec.configSpecEx.inHciWorkflow = !!hciEnabled;
         }

         if (angular.isDefined(formData.vmSwapPlacement)) {
            spec.configSpecEx.vmSwapPlacement = formData.vmSwapPlacement;
         }

         if (angular.isDefined(formData.drsEnabled)) {
            spec.configSpecEx.drsConfig.enabled = !!formData.drsEnabled;
         }

         if (angular.isDefined(formData.drsAutomation)) {
            if (angular.isDefined(formData.drsAutomation.automationLevel)) {
               spec.configSpecEx.drsConfig.defaultVmBehavior = formData.drsAutomation.automationLevel.id;
            }

            if (angular.isDefined(formData.drsAutomation.migrationThreshold)) {
               spec.configSpecEx.drsConfig.vmotionRate = drsMigrationThresholdService
                  .getDrsMigrationRate(formData.drsAutomation.migrationThreshold);
            }

            if (angular.isDefined(formData.drsAutomation.vmAutomation)) {
               spec.configSpecEx.drsConfig.enableVmBehaviorOverrides = !!formData.drsAutomation.vmAutomation;
            }

            if (formData.drsAutomation.is65VcOrLater &&
                  angular.isDefined(formData.drsAutomation.proactiveDrsEnabled)) {
               spec.configSpecEx.proactiveDrsConfig = {
                  _type: 'com.vmware.vim.binding.vim.cluster.ProactiveDrsConfigInfo',
                  enabled: formData.drsAutomation.proactiveDrsEnabled
               };
            }
         }

         if (angular.isDefined(formData.dpmAutomation)) {
            if (angular.isDefined(formData.dpmAutomation.automationLevel)) {
               if (formData.dpmAutomation.automationLevel.id === drsConstants.dpmAutomationLevel.DISABLED) {
                  spec.configSpecEx.dpmConfig.enabled = false;
               } else {
                  spec.configSpecEx.dpmConfig.enabled = true;
                  spec.configSpecEx.dpmConfig.defaultDpmBehavior = formData.dpmAutomation.automationLevel.id;
               }
            }
            if (angular.isDefined(formData.dpmAutomation.dpmThreshold)) {
               var dpmThreshold = dpmThresholdService.reverseDpmRateAndThreshold(
                     formData.dpmAutomation.dpmThreshold);
               spec.configSpecEx.dpmConfig.hostPowerActionRate = dpmThreshold;
            }
         }

         if (angular.isDefined(formData.advancedOptionsBuilder)) {
            spec.configSpecEx.drsConfig.option =
               formData.advancedOptionsBuilder.buildOptions();
         }

         if (angular.isDefined(formData.drsPolicies)) {
            if (formData.drsAutomation.is65VcOrLater && (formData.drsPolicies.evenDistribution ||
                  angular.isDefined(formData.advancedOptionsBuilder))) {
               var evenDistributionValue = formData.drsPolicies.evenDistribution ?
                     drsConstants.drsPolicies.EVEN_DISTRIBUTION_VALUE : null;
               spec.configSpecEx.drsConfig.option = addAdvancedOption(
                     spec.configSpecEx.drsConfig.option,
                     drsConstants.drsPolicies.EVEN_DISTRIBUTION_OPTION,
                     evenDistributionValue);
            }

            if (formData.drsPolicies.cpuOvercommitment ||
                  angular.isDefined(formData.advancedOptionsBuilder)) {
               var cpuOvercommitmentValue = formData.drsPolicies.cpuOvercommitment ?
                     formData.drsPolicies.cpuOvercommitmentValue.toString() : null;
               spec.configSpecEx.drsConfig.option = addAdvancedOption(
                     spec.configSpecEx.drsConfig.option,
                     drsConstants.drsPolicies.CPU_OVERCOMMITMENT_OPTION,
                     cpuOvercommitmentValue);
            }
         }

         if (angular.isDefined(formData.haEnabled)) {
            spec.configSpecEx.dasConfig = {
               _type: 'com.vmware.vim.binding.vim.cluster.DasConfigInfo',
               enabled: !!formData.haEnabled
            };

            if (angular.isDefined(formData.haConfiguration)) {
               var haConfiguration = formData.haConfiguration;

               if (angular.isDefined(haConfiguration.defaultVmSettings)) {
                  spec.configSpecEx.dasConfig.defaultVmSettings = {
                     _type: 'com.vmware.vim.binding.vim.cluster.DasVmSettings'
                  };

                  if (angular.isDefined(haConfiguration.defaultVmSettings.isolationResponse)) {
                     spec.configSpecEx.dasConfig.defaultVmSettings.isolationResponse =
                        haConfiguration.defaultVmSettings.isolationResponse;
                  }

                  if (angular.isDefined(haConfiguration.defaultVmSettings.restartPriority)) {
                     spec.configSpecEx.dasConfig.defaultVmSettings.restartPriority =
                           haConfiguration.defaultVmSettings.restartPriority;
                  }

                  if (formData.is65VcOrLater &&
                        angular.isDefined(haConfiguration.defaultVmSettings.restartPriorityTimeout)) {
                     spec.configSpecEx.dasConfig.defaultVmSettings.restartPriorityTimeout =
                           haConfiguration.defaultVmSettings.restartPriorityTimeout;
                  }

                  if (angular.isDefined(haConfiguration.
                        defaultVmSettings.vmToolsMonitoringSettings)) {
                     spec.configSpecEx.dasConfig.
                           defaultVmSettings.vmToolsMonitoringSettings = {
                        _type: 'com.vmware.vim.binding.vim.cluster.VmToolsMonitoringSettings'
                     };

                     var vmToolsMonitoringSettings = haConfiguration.
                           defaultVmSettings.vmToolsMonitoringSettings;

                     if (angular.isDefined(
                           vmToolsMonitoringSettings.vmToolsMonitorFailureInt)) {
                        spec.configSpecEx.dasConfig.defaultVmSettings.
                           vmToolsMonitoringSettings.failureInterval =
                           vmToolsMonitoringSettings.vmToolsMonitorFailureInt;
                     }

                     if (angular.isDefined(
                           vmToolsMonitoringSettings.vmToolsMonitorMinUpTime)) {
                        spec.configSpecEx.dasConfig.defaultVmSettings.
                           vmToolsMonitoringSettings.minUpTime =
                           vmToolsMonitoringSettings.vmToolsMonitorMinUpTime;
                     }

                     if (angular.isDefined(
                           vmToolsMonitoringSettings.vmToolsMonitorMaxFailures)) {
                        spec.configSpecEx.dasConfig.defaultVmSettings.
                           vmToolsMonitoringSettings.maxFailures =
                           vmToolsMonitoringSettings.vmToolsMonitorMaxFailures;
                     }

                     if (angular.isDefined(
                        vmToolsMonitoringSettings.vmToolsMonitorMaxFailureWindow)) {
                        if (vmToolsMonitoringSettings.vmToolsMonitorHasFailureWindow) {
                           //convert to seconds
                           spec.configSpecEx.dasConfig.defaultVmSettings.
                              vmToolsMonitoringSettings.maxFailureWindow =
                              vmToolsMonitoringSettings.
                                 vmToolsMonitorMaxFailureWindow * 3600;
                        } else {
                           spec.configSpecEx.dasConfig.defaultVmSettings.
                              vmToolsMonitoringSettings.maxFailureWindow =
                              haConstants.vmMonitoringSensitivity.
                                 NO_FAILURE_WINDOW;
                        }
                     }
                  }
               }

               if (angular.isDefined(haConfiguration.admissionControlEnabled)) {
                  spec.configSpecEx.dasConfig.admissionControlEnabled =
                     !!haConfiguration.admissionControlEnabled;
               }

               if (angular.isDefined(haConfiguration.hostMonitoringEnabled)) {
                  spec.configSpecEx.dasConfig.hostMonitoring =
                     !!haConfiguration.hostMonitoringEnabled ?
                        haConstants.hostMonitoringState.ENABLED :
                        haConstants.hostMonitoringState.DISABLED;
               }

               if (angular.isDefined(haConfiguration.vmMonitoringState)) {
                  spec.configSpecEx.dasConfig.vmMonitoring =
                     haConfiguration.vmMonitoringState;
               }

               if (angular.isDefined(haConfiguration.admissionControl)) {
                  if(haConfiguration.admissionControl.policy._type ===
                        haConstants.admissionControlPolicy.FAILOVER_RESOURCES_TYPE){
                     spec.configSpecEx.dasConfig.admissionControlPolicy = {
                        _type: haConfiguration.admissionControl.policy._type
                     };
                  } else {
                     spec.configSpecEx.dasConfig.admissionControlPolicy = {
                        _type: haConfiguration.admissionControl.policy._type,
                        failoverLevel: haConfiguration.admissionControl.policy.failoverLevel
                     };
                  }
               }

               if (angular.isDefined(haConfiguration.vmcpSettings)) {
                  var dasConfigSpec = spec.configSpecEx.dasConfig;
                  if (!dasConfigSpec.defaultVmSettings) {
                     dasConfigSpec.defaultVmSettings = {
                        _type: 'com.vmware.vim.binding.vim.cluster.DasVmSettings'
                     };
                  }

                  // Do not change the current VMCP behaviour unless needed.
                  var vmcpShouldBeEnabled = haConfiguration.vmcpEnabled;

                  if (angular.isDefined(haConfiguration.vmcpSettings.apdResponse)) {
                     if (haConfiguration.vmcpSettings.apdResponse !== haConstants.storageVmReaction.DISABLED) {
                        vmcpShouldBeEnabled = true;
                     }

                     if (!dasConfigSpec.defaultVmSettings.vmComponentProtectionSettings) {
                        dasConfigSpec.defaultVmSettings.vmComponentProtectionSettings = {
                           _type: 'com.vmware.vim.binding.vim.cluster.VmComponentProtectionSettings'
                        };
                     }
                     dasConfigSpec.defaultVmSettings.vmComponentProtectionSettings.vmStorageProtectionForAPD =
                        haConfiguration.vmcpSettings.apdResponse;
                  }

                  if (angular.isDefined(haConfiguration.vmcpSettings.pdlResponse)) {
                     if (haConfiguration.vmcpSettings.pdlResponse !== haConstants.storageVmReaction.DISABLED) {
                        vmcpShouldBeEnabled = true;
                     }

                     if (!dasConfigSpec.defaultVmSettings.vmComponentProtectionSettings) {
                        dasConfigSpec.defaultVmSettings.vmComponentProtectionSettings = {
                           _type: 'com.vmware.vim.binding.vim.cluster.VmComponentProtectionSettings'
                        };
                     }
                     dasConfigSpec.defaultVmSettings.vmComponentProtectionSettings.vmStorageProtectionForPDL =
                           haConfiguration.vmcpSettings.pdlResponse;
                  }

                  if (vmcpShouldBeEnabled) {
                     dasConfigSpec.vmComponentProtecting = haConstants.serviceState.ENABLED;
                  } else {
                     dasConfigSpec.vmComponentProtecting = haConstants.serviceState.DISABLED;
                  }

                  var vmReactionOnAPDCleared = haConfiguration.vmcpSettings
                     .vmReactionOnAPDCleared;
                  if (angular.isDefined(vmReactionOnAPDCleared)) {
                     if (!dasConfigSpec.defaultVmSettings.vmComponentProtectionSettings) {
                        dasConfigSpec.defaultVmSettings.vmComponentProtectionSettings = {
                           _type: 'com.vmware.vim.binding.vim.cluster.VmComponentProtectionSettings'
                        };
                     }
                     dasConfigSpec.defaultVmSettings.vmComponentProtectionSettings
                        .vmReactionOnAPDCleared = vmReactionOnAPDCleared;
                  }

                  var apdRecoveryDelayMinutes = haConfiguration.vmcpSettings
                     .apdRecoveryDelayMinutes;
                  if (angular.isDefined(apdRecoveryDelayMinutes)) {
                     if (!dasConfigSpec.defaultVmSettings.vmComponentProtectionSettings) {
                        dasConfigSpec.defaultVmSettings.vmComponentProtectionSettings = {
                           _type: 'com.vmware.vim.binding.vim.cluster.VmComponentProtectionSettings'
                        };
                     }
                     dasConfigSpec.defaultVmSettings.vmComponentProtectionSettings
                        .vmTerminateDelayForAPDSec =
                           apdRecoveryDelayMinutes * haConstants.SECONDS_IN_A_MINUTE;
                  }
               }
            }

            if (angular.isDefined(formData.hbDatastoreCandidatePolicy)) {
               var hbDsPolicy =
                     formData.hbDatastoreCandidatePolicy;

               spec.configSpecEx.dasConfig.hBDatastoreCandidatePolicy = hbDsPolicy;

               var preferredHbDatastores = [];
               var isHbDsCandidatesDefined = angular.isDefined(
                     formData.hbDatastoreCandidates);

               if (hbDsPolicy !== haConstants.hbDatastoreCandidatePolicy.AUTO_SELECT &&
                     isHbDsCandidatesDefined) {
                  var hbDsCandidates = formData.hbDatastoreCandidates ?
                        formData.hbDatastoreCandidates : [];
                  for(var i = 0; i < hbDsCandidates.length; i++) {
                     if(hbDsCandidates[i].isPreferred){
                        preferredHbDatastores.push(hbDsCandidates[i].provider);
                     }
                  }

                  spec.configSpecEx.dasConfig.heartbeatDatastore = preferredHbDatastores;
               }
            }

            if (angular.isDefined(formData.haAdvancedOptionsBuilder)) {
               spec.configSpecEx.dasConfig.option =
                     formData.haAdvancedOptionsBuilder.buildOptions();
            }

            if(angular.isDefined(formData.admissionControlPolicy)) {
               spec.configSpecEx.dasConfig.admissionControlEnabled =
                     formData.admissionControlPolicy.type !==
                     haConstants.admissionControlPolicy.DISABLED_TYPE;

               if (spec.configSpecEx.dasConfig.admissionControlEnabled) {
                  switch (formData.admissionControlPolicy.type) {
                     case haConstants.admissionControlPolicy.FAILOVER_LEVEL_TYPE:
                        spec.configSpecEx.dasConfig.admissionControlPolicy = {
                           _type: haConstants.admissionControlPolicy.FAILOVER_LEVEL_TYPE,
                           failoverLevel: formData.failoverLevel
                        };
                        //don't send slotPolicy if cover all vm slot policy is selected
                        if (formData.slotSizePolicy === haConstants.slotSizePolicy.FIXED_SIZE) {
                           spec.configSpecEx.dasConfig.admissionControlPolicy.slotPolicy = {
                              _type: 'com.vmware.vim.binding.vim.cluster.FixedSizeSlotPolicy',
                              cpu: formData.fixedSlotSizeCpu,
                              memory: formData.fixedSlotSizeMemory
                           };
                        }
                        break;
                     case haConstants.admissionControlPolicy.FAILOVER_RESOURCES_TYPE:
                        spec.configSpecEx.dasConfig.admissionControlPolicy = {
                           _type: haConstants.admissionControlPolicy.FAILOVER_RESOURCES_TYPE,
                           cpuFailoverResourcesPercent: formData.cpuFailoverPercent,
                           memoryFailoverResourcesPercent: formData.memoryFailoverPercent
                        };
                        if (formData.is65VcOrLater) {
                           spec.configSpecEx.dasConfig.admissionControlPolicy.autoComputePercentages =
                                 formData.autoComputePercentages;
                        }
                        break;
                     case haConstants.admissionControlPolicy.FAILOVER_HOSTS_TYPE:
                        var failoverHosts = _.map(formData.failoverHosts,
                              function(failoverHost) {
                                 return failoverHost.provider;
                              });

                        spec.configSpecEx.dasConfig.admissionControlPolicy = {
                           _type: haConstants.admissionControlPolicy.FAILOVER_HOSTS_TYPE,
                           failoverHosts: failoverHosts
                        };
                        break;
                  }
                  //add failoverLevel if cluster is in 6.5 or above VC
                  if (formData.is65VcOrLater) {
                     spec.configSpecEx.dasConfig.admissionControlPolicy.failoverLevel =
                           formData.failoverLevel;
                     spec.configSpecEx.dasConfig.admissionControlPolicy
                           .resourceReductionToToleratePercent =
                           formData.resourceReductionToToleratePercent;
                  }
               }
            }
         }

         if (angular.isDefined(formData.vsanEnabled)) {
            spec.configSpecEx.vsanConfig = {
               _type: 'com.vmware.vim.binding.vim.vsan.cluster.ConfigInfo',
               enabled: formData.vsanEnabled
            };
         }

         //add orchestration if cluster is in 6.5 or above VC
         if (formData.is65VcOrLater && angular.isDefined(formData.orchestration)) {
            spec.configSpecEx.orchestration = {
               _type: 'com.vmware.vim.binding.vim.cluster.OrchestrationInfo'
            };

            if (angular.isDefined(formData.orchestration.defaultVmReadiness)) {
               spec.configSpecEx.orchestration.defaultVmReadiness = {
                  _type: 'com.vmware.vim.binding.vim.cluster.VmReadiness'
               };

               spec.configSpecEx.orchestration.defaultVmReadiness.postReadyDelay =
                     formData.orchestration.defaultVmReadiness.postReadyDelay;
               spec.configSpecEx.orchestration.defaultVmReadiness.readyCondition =
                     formData.orchestration.defaultVmReadiness.readyCondition;
            }
         }

         if (angular.isDefined(formData.group) && angular.isDefined(formData.group.info)) {
            spec.configSpecEx.groupSpec = [formData.group];
         }

         if (angular.isDefined(formData.rule)) {
            spec.configSpecEx.rulesSpec = [formData.rule];
         }

         if (angular.isDefined(formData.dasVmOverride)) {
            spec.configSpecEx.dasVmConfigSpec = formData.dasVmOverride;
         }

         if (angular.isDefined(formData.drsVmOverride)) {
            spec.configSpecEx.drsVmConfigSpec = formData.drsVmOverride;
         }

         if (angular.isDefined(formData.orchestrationVmOverride)) {
            spec.configSpecEx.vmOrchestrationSpec = formData.orchestrationVmOverride;
         }

         if (angular.isDefined(formData.infraUpdateHaConfig)) {
            spec.configSpecEx.infraUpdateHaConfig = formData.infraUpdateHaConfig;
            _.extend(spec.configSpecEx.infraUpdateHaConfig, {
               _type: "com.vmware.vim.binding.vim.cluster.InfraUpdateHaConfigInfo"
            });
         }

         return spec;
      }

      function addAdvancedOption(currentOptions, advancedOptionKey, advancedOptionValue) {
         if (currentOptions === undefined) {
            currentOptions = [];
         }

         currentOptions = _.reject(currentOptions, function(advancedOption) {
            return advancedOption.key === advancedOptionKey;
         });

         currentOptions.push({
            _type: "com.vmware.vim.binding.vim.option.OptionValue",
            key: advancedOptionKey,
            value: advancedOptionValue
         });

         return currentOptions;
      }
   }
})();
