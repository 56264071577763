module cluster_ui {

   import IComponentController = angular.IComponentController;
   import IPromise = angular.IPromise;

   export class MonitorStorageResourceReservationViewController implements IComponentController {

      public objectId: string;
      public liveRefreshProperties: string[];
      public gridOptions: any;

      public static $inject = ["$scope", "dataService", "i18nService",
         "vxPropertyViewService", "columnRenderersRegistry", "vuiConstants",
         "defaultUriSchemeUtil", "$q", "capacityFormatterService"];

      constructor(private $scope: any, private dataService: any, private i18nService: any,
                  private vxPropertyViewService: any, private columnRenderersRegistry: any,
                  private vuiConstants: any, private defaultUriSchemeUtil: any,
                  private $q: any, private capacityFormatterService: any) {
         this.objectId = $scope._route ? $scope._route.objectId : null;
         let liveRefreshProperties =
               this.$scope._view.contentSpec.metadata.liveRefreshProperties;
         if (liveRefreshProperties) {
            this.liveRefreshProperties = liveRefreshProperties
                  .split(',')
                  .map((prop: string): string => prop.trim());
         }
         $scope.loadingData = false;
         $scope.groupedMeters = [];
         $scope.maxCapacityValue = "";
         $scope.reservationData = {};

         this.gridOptions = this.getGridOptions();
         this.getData();
      }

      public getData(): void {
         if (this.$scope.loadingData) {
            return;
         }

         this.$scope.loadingData = true;

         this.dataService.getData(
               this.objectId, this.$scope._view.contentSpec.metadata.modelClass)
               .then((data: any): void => {
                  this.$scope.groupedMeters = this.getGroupedMeters(data);
                  this.$scope.maxCapacityValue = data.totalCapacityFormatted;
                  this.$scope.reservationData = this.getReservationData(data);

                  this.gridOptions.data = data.virtualDisks;
                  this.$scope.loadingData = false;
               })
               .catch((error: any) => {
                  this.$scope.loadingData = false;
                  this.$q.reject(error);
               });
      }

      private getReservationData(data: any): any {
         let builder: any = this.vxPropertyViewService.createPropertyViewBuilder();
         let sectionBuilder: any = builder
               .category("")
               .section("");

         sectionBuilder.propertyBuilder()
               .keyBuilder()
               .text(this.i18nService.getString("ClusterUi", "storage.totalCapacity"))
               .exit()
               .valueBuilder()
               .text(data.totalCapacityFormatted);
         sectionBuilder.propertyBuilder()
               .keyBuilder()
               .text(this.i18nService.getString("ClusterUi", "capacity.Reserved"))
               .icon("vx-property-view-key-legend-icon monitor-resource-reservation-grid-icon-color-light-blue")
               .exit()
               .valueBuilder()
               .text(data.usedSpaceFormatted);
         sectionBuilder.propertyBuilder()
               .keyBuilder()
               .text(this.i18nService.getString("ClusterUi", "capacity.availableCapacity"))
               .icon("vx-property-view-key-legend-icon monitor-resource-reservation-grid-icon-color-white")
               .exit()
               .valueBuilder()
               .text(data.freeSpaceFormatted);

         return builder.build();
      }

      private getGroupedMeters(data: any): Object[] {
         return [{
            colorClass: "monitor-resource-reservation-icon-color-light-blue",
            value: data.storagePercentage,
            label: this.capacityFormatterService.format(
                  data.usedSpaceFormatted,
                  data.totalCapacityFormatted,
                  data.storagePercentage)
         }, {
            colorClass: "monitor-resource-reservation-icon-color-light-gray",
            value: 100 - data.storagePercentage,
            label: this.capacityFormatterService.format(
                  data.freeSpaceFormatted,
                  data.totalCapacityFormatted,
                  100 - data.storagePercentage)
         }];
      }

      private getGridOptions(): Object {
         return {
            searchable: true,
            resizable: false,
            sortOrder: [{
               field: "name",
               dir: "asc"
            }],
            data: [],
            selectionMode: this.vuiConstants.grid.selectionMode.NONE,
            columnDefs: this.getColumnDefs(),
            height: "100%",
            pageConfig: {
               hidePager: true
            }
         };
      }

      private getColumnDefs(): Object[] {
         return [{
            displayName: this.i18nService.getString("ClusterUi", "nameColumnHeader"),
            field: "vmName",
            type: "string",
            template: (data: any): string => {
               let objData = data || {};
               let renderer: any = this.columnRenderersRegistry.getColumnRenderer("object-name");

               objData.uid = this.defaultUriSchemeUtil.getVsphereObjectId(objData.vm);

               return renderer(["uid", "vmPrimaryIcon", "vmName"], objData);
            }
         }, {
            displayName: this.i18nService.getString("ClusterUi",
                  "storage.diskColumn"),
            field: "label",
            type: "string",
            template: "<span title=\"#:label#\">#:label#</span>"
         }, {
            displayName: this.i18nService.getString("ClusterUi",
                  "storage.datastoreColumn"),
            field: "datastoreName",
            type: "string",
            template: "<span title=\"#:datastoreName#\">#:datastoreName#</span>"

         }, {
            displayName: this.i18nService.getString("ClusterUi",
                  "storage.limitColumn"),
            field: "limit",
            type: "number",
            template: (data: any): string => {
               let objData: any = data || {};
               return objData.limit !== -1 ? objData.limit :
                     this.i18nService.getString("ClusterUi", "limit.unlimited");
            }
         }, {
            displayName: this.i18nService.getString("ClusterUi",
                  "storage.shareLevelColumn"),
            field: "shareLevel",
            type: "string",
            template: "<span title=\"#:shareLevel#\">#:shareLevel#</span>"

         }, {
            displayName: this.i18nService.getString("ClusterUi",
                  "storage.sharesValueColumn"),
            field: "shares",
            type: "string",
            template: "<span title=\"#:shares#\">#:shares#</span>"
         }, {
            displayName: this.i18nService.getString("ClusterUi",
                  "storage.dsPercentSharesValueColumn"),
            field: "datastoreSharesPercentage",
            type: "string",
            template: "<span title=\"#:datastoreSharesPercentage#\">#:datastoreSharesPercentage#</span>",
            sortable: (item1: any, item2: any) => {
               return parseInt(item1.datastoreSharesPercentage) - parseInt(item2.datastoreSharesPercentage);
            }
         }];
      }
   }

   angular.module("com.vmware.vsphere.client.cluster")
         .controller("MonitorStorageResourceReservationViewController",
               MonitorStorageResourceReservationViewController);
}
