(function () {
   'use strict';

   /**
    * Service that provides information about DRS power management threshold.
    */
   angular.module('com.vmware.vsphere.client.cluster')
         .service('dpmThresholdService', dpmThresholdService);

   dpmThresholdService.$inject = ['drsConstants', 'logService', 'i18nService'];

   function dpmThresholdService(drsConstants, logService, i18nService) {
      var log = logService('drsMigrationThresholdService');

      return {
         getText: getText,
         reverseDpmRateAndThreshold: reverseDpmRateAndThreshold,
         MIN: drsConstants.dpmThreshold.MIN,
         MAX: drsConstants.dpmThreshold.MAX,
         DEFAULT: (drsConstants.dpmThreshold.MIN + drsConstants.dpmThreshold.MAX) / 2,
         MIN_LABEL: getString('drs.config.threshold.conservative'),
         MAX_LABEL: getString('drs.config.threshold.aggressive')
      };

      /**
       * Returns DPM threshold text based on the provided dpm state and rate.
       */
      function getText(dpmEnabled, dpmThreshold) {
         if (dpmEnabled && !dpmThresholdWithinRange(dpmThreshold)) {
            log.warn('Unexpected DPM threshold: ' + dpmThreshold);
            return '';
         }

         // if the dpm is not enabled, display N/A for dpm threshold
         var key = (dpmEnabled) ?
               i18nService.interpolate(
                     "drs.config.dpmThresholdLabel.priority{0}Description",
                     [dpmThreshold])
               : "drs.config.dpmThresholdLabel.notAvailable";

         return getString(key);
      }

      /**
       * Helper function to calculate the dpm threshold and rate values.
       */
      function reverseDpmRateAndThreshold(value) {
         return drsConstants.dpmThreshold.MAX - value + drsConstants.dpmThreshold.MIN;
      }

      function dpmThresholdWithinRange(value) {
         return drsConstants.dpmThreshold.RANGE.indexOf(value) !== -1;
      }

      function getString(key) {
         return i18nService.getString('ClusterUi', key);
      }
   }
})();
