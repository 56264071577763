namespace ds_cluster_ui {

   export class AddVmOverridesPageService {
      static $inject: string[] = ["vuiAddVmOverridesWizard",
         "i18nService", "dsClusterConstants",
         "mutationService",
         "storageVmOverridesService",
         "defaultUriSchemeUtil",
         "$q"];

      constructor(private vuiAddVmOverridesWizard: VuiAddVmOverridesWizard,
                  private i18nService: any,
                  private dsClusterConstants: any,
                  private mutationService: any,
                  private storageVmOverridesService: StorageVmOverridesService,
                  private defaultUriSchemeUtil: any,
                  private $q: any) {
      }

      public buildSelectVmsPage(wizardScope: any): any {
         return {
            title: this.i18nService.getString("DsClusterUi", "vmOverrides.selection.title"),
            contentUrl: "ds-cluster-ui/resources/ds-cluster/views/settings/configuration/sdrsVmOverrides/selectVmsToOverrideView.html",
            onCommit: () => {
               let selectedVms: any = wizardScope.manager.getSelectedVms();
               if (selectedVms && selectedVms.length > 0) {
                  this.vuiAddVmOverridesWizard.markPageComplete();
                  return true;
               } else {
                  this.vuiAddVmOverridesWizard.markPageIncompleteWithError(
                        this.i18nService.getString(
                              "DsClusterUi", "sdrs.vmOverrides.noVmIsSelected"));
                  return false;
               }
            },
            disableNext: () => {
               return wizardScope.wizardConfig.loading ;
            }
         };
      }

      public buildAddVmOverridesPage(wizardScope: any): any {
         return {
            title: this.i18nService.getString("DsClusterUi", "vmOverrides.services.sdrs"),
            contentUrl: "ds-cluster-ui/resources/ds-cluster/views/settings/configuration/sdrsVmOverrides/addVmOverridesView.html",
            onCommit: () => {
               let spec: any = wizardScope.manager.getSpec();
               let selectedVms: any = wizardScope.manager.getSelectedVms();
               let objectId: string = wizardScope.manager.getTargetUid();
               let validationPassed: any = this.$q.defer();
               if (spec.keepVmdksChecked || spec.sdrsAutomationLevel) {
                  let keepVmdksValue: string = spec.keepVmdksChecked ?
                        spec.keepVmdksValue :
                        this.dsClusterConstants.keepVmdksValues.clusterDefault;

                  let sdrsAutomationLevelValue: string = spec.sdrsAutomationLevel ?
                        spec.sdrsAutomationLevelValue :
                        this.dsClusterConstants.sdrsVmOverridesAutomationLevels.clusterDefault;

                  let addOverrideSpec: any = this.getOverrideSpec(selectedVms,
                        sdrsAutomationLevelValue, keepVmdksValue, "Add");

                  wizardScope.wizardConfig.loading = true;
                  this.mutationService.validate(objectId,
                        this.dsClusterConstants.SDRS_VM_OVERRIDE_SPEC,
                        addOverrideSpec).then((response: any) => {
                     wizardScope.wizardConfig.loading = false;

                     if (response && response.result && response.result.length > 0) {
                        let alert: any = this.storageVmOverridesService
                              .getVmOverrideErrorMessages(response.result);
                        this.vuiAddVmOverridesWizard.markPageIncompleteWithError(alert.text);

                        validationPassed.reject();
                     } else {
                        this.mutationService.apply(objectId,
                              this.dsClusterConstants.SDRS_VM_OVERRIDE_SPEC,
                              addOverrideSpec);
                        validationPassed.resolve(true);
                     }
                  });
               } else {
                  let error: string = this.i18nService.getString("DsClusterUi",
                        "vm.overrides.config.noChangesValidationError");
                  this.vuiAddVmOverridesWizard.markPageIncompleteWithError(error);
                  validationPassed.reject();
               }

               return validationPassed.promise;
            },
            finishReady: () => {
               return !wizardScope.wizardConfig.loading;
            }
         };
      }

      public createKeepVdmkTogetherValuesMap(): Object {
         return {
            keepVmdksTogether: {
               id: "keepVmdksTogether",
               label: this.i18nService.getString("Common", "yes.label"),
               value: "KeepVmdksTogether"
            },
            doNotKeepVmdksTogether: {
               id: "DoNotKeepVmdksTogether",
               label: this.i18nService.getString("Common", "no.label"),
               value: "DoNotKeepVmdksTogether"
            }
         };
      }

      public createAutomationLevelsMap(): Object {
         return {
            automated: {
               id: "Automated",
               label: this.i18nService.getString(
                     "DsClusterUi", "vmOverrides.vmSdrsAutomationLevelFullyAutomated")
            },
            manual: {
               id: "Manual",
               label: this.i18nService.getString(
                     "DsClusterUi", "vmOverrides.vmSdrsAutomationLevelManual")
            },
            disabled: {
               id: "Disabled",
               label: this.i18nService.getString(
                     "DsClusterUi", "vmOverrides.vmSdrsAutomationLevelDisabled")
            }
         };
      }

      public getIntraVmAffinityValueForAVm(vm: any): any {

         let keepVmdkTogetherValuesMap: any = this.createKeepVdmkTogetherValuesMap();
         let keepVmdksChecked: boolean = true;
         let keepVmdksValue: string = '';


         if (vm.rawData.intraVmAffinity === null) {
            keepVmdksChecked = false;

            keepVmdksValue =
                  vm.rawData.defaultIntraVmAffinity ?
                        keepVmdkTogetherValuesMap.keepVmdksTogether.value :
                        keepVmdkTogetherValuesMap.doNotKeepVmdksTogether.value;
         } else {
            keepVmdksChecked = true;

            keepVmdksValue = vm.rawData.intraVmAffinity ?
                  keepVmdkTogetherValuesMap.keepVmdksTogether.value :
                  keepVmdkTogetherValuesMap.doNotKeepVmdksTogether.value;
         }


         return {
            keepVmdksChecked: keepVmdksChecked,
            keepVmdksValue: keepVmdksValue
         };

      }

      public getSdrsAutomationLevelForVm(vm: any): any {

         let sdrsAutomationLevels: any = this.createAutomationLevelsMap();
         let sdrsAutomationLevel: boolean = true;
         let sdrsAutomationLevelValue: string = '';

         let vmAutomationLevel: string =
               vm.rawData.vmAutomationLevel;
         let vmAutomationLevelEnabled: boolean =
               vm.rawData.vmAutomationLevelEnabled;
         let defaultAutomationLevel: string =
               vm.rawData.defaultVmAutomationLevel;

         if (vmAutomationLevel === this.dsClusterConstants.SDRS_FULLAUTO) {
            sdrsAutomationLevel = true;
            sdrsAutomationLevelValue = sdrsAutomationLevels[vmAutomationLevel].id;
         } else if (vmAutomationLevel === this.dsClusterConstants.SDRS_MANUAL) {
            sdrsAutomationLevel = true;
            sdrsAutomationLevelValue = sdrsAutomationLevels[vmAutomationLevel].id;
         } else if (vmAutomationLevel === null) {
            if (vmAutomationLevelEnabled === null) {
               if (defaultAutomationLevel === this.dsClusterConstants.SDRS_FULLAUTO) {
                  sdrsAutomationLevel = false;
                  sdrsAutomationLevelValue =
                        sdrsAutomationLevels[defaultAutomationLevel].id;
               } else if (defaultAutomationLevel === this.dsClusterConstants.SDRS_MANUAL) {
                  sdrsAutomationLevel = false;
                  sdrsAutomationLevelValue = sdrsAutomationLevels[defaultAutomationLevel].id;
               }
            } else {
               sdrsAutomationLevel = true;
               sdrsAutomationLevelValue = sdrsAutomationLevels.disabled.id;
            }
         }

         return {
            sdrsAutomationLevel: sdrsAutomationLevel,
            sdrsAutomationLevelValue: sdrsAutomationLevelValue
         };
      }


      public getOverrideSpec(vms: any[], sdrsAutomationLevel: string,
                              keepVmdksTogheter: string, operation: string): any {
         return {
            operation: operation,
            vmOverrides: _.map(vms, (vm: any) => {
               let vmOverrides: any = {};

               if (operation === this.dsClusterConstants.vmOverridesOperationType.ADD) {
                  vmOverrides = {
                     vmSor: this.defaultUriSchemeUtil.getManagedObjectReference(vm.id),
                     vmName: vm.name,
                     sdrsAutomationLevel: sdrsAutomationLevel,
                     intraVmAffinity: keepVmdksTogheter
                  };
               } else {
                  let intraVmAffinity: string =
                        this.getIntraVmAffinityByKeepVmdksValue(keepVmdksTogheter, vm);
                  let sdrsAutomationLevelSpecValue: string =
                        this.getSdrsValueBySdrsAutomationLevel(sdrsAutomationLevel, vm);

                  vmOverrides = {
                     vmSor: vm.vm.provider,
                     vmName: vm.name,
                     sdrsAutomationLevel: sdrsAutomationLevelSpecValue,
                     intraVmAffinity: intraVmAffinity
                  };
               }

               return vmOverrides;
            })
         };
      }

      private getIntraVmAffinityByKeepVmdksValue(keepVmdksTogheter: string, vm: any): string {
         let intraVmAffinity: string = "";
         if (keepVmdksTogheter ===
               this.dsClusterConstants.keepVmdksValues.useInitialValues) {

            let intraVmAffinityForCurrentVm =
                  this.getIntraVmAffinityValueForAVm(vm);

            intraVmAffinity = intraVmAffinityForCurrentVm.keepVmdksChecked ?
                  intraVmAffinityForCurrentVm.keepVmdksValue :
                  this.dsClusterConstants.sdrsVmOverridesAutomationLevels.clusterDefault;
         } else {
            intraVmAffinity = keepVmdksTogheter;
         }
         return intraVmAffinity;
      }

      private getSdrsValueBySdrsAutomationLevel(sdrsAutomationLevel: string, vm: any): string {

         let sdrsAutomationLevelSpecValue: string = "";
         let clusterDefault: string =
               this.dsClusterConstants.sdrsVmOverridesAutomationLevels.clusterDefault;
         let useInitialValues: string =
               this.dsClusterConstants.sdrsVmOverridesAutomationLevels.useInitialValues;

         if (sdrsAutomationLevel === useInitialValues) {
            let sdrsAutomationLevelForVm: any =
                  this.getSdrsAutomationLevelForVm(vm);
            sdrsAutomationLevelSpecValue =
                  sdrsAutomationLevelForVm.sdrsAutomationLevel ?
                        sdrsAutomationLevelForVm.sdrsAutomationLevelValue :
                        clusterDefault;

         } else {
            sdrsAutomationLevelSpecValue = sdrsAutomationLevel;
         }
         return sdrsAutomationLevelSpecValue;
      }

   }

   angular.module("com.vmware.vsphere.client.dsCluster")
         .service("addVmOverridesPageService", AddVmOverridesPageService);
}

