module ds_cluster_ui {

   export class DsClusterConectivityService {
      private clusterConectivityData: any;

      public static $inject: string[] = ["i18nService", "vuiConstants",
         "columnRenderersRegistry", "defaultUriSchemeUtil", "resourceUtil",
         "entityStatusService"];

      constructor(private i18nService: any, private vuiConstants: any,
            private columnRenderersRegistry: any,
            private defaultUriSchemeUtil: any,
            private resourceUtil: any,
            private entityStatusService: any) {}

      public getMasterViewGridOptions(): Object {
         return {
            selectionMode: this.vuiConstants.grid.selectionMode.SINGLE,
            sortMode: this.vuiConstants.grid.sortMode.SINGLE,
            searchable: false,
            resizable: true,
            data: [],
            selectedItems: [],
            columnDefs: this.getMasterColumnDefs(),
            height: "100%"
         };
      }

      public getDetailsViewGridOptions(): Object {
         return {
            selectionMode: this.vuiConstants.grid.selectionMode.NONE,
            sortMode: this.vuiConstants.grid.sortMode.SINGLE,
            searchable: false,
            resizable: true,
            data: [],
            selectedItems: [],
            columnDefs: this.getDetailsColumnDefs(),
            height: "100%"
         };
      }

      private getDetailsColumnDefs(): any[] {
         return [{
               displayName: this.i18nService.getString("DsClusterUi",
                     "dsClusterComputeResourceDetailsView.nameColumn"),
               field: "name",
               type: "string",
               template: this.getObjNameIconRenderer.bind(this)
            }, {
               displayName: this.i18nService.getString("DsClusterUi",
                     "dsClusterComputeResourceDetailsView.datastoresConnectedColumn"),
               field: "datastoresConnected",
               type: "string"
            }, {
               displayName: this.i18nService.getString("DsClusterUi",
                     "dsClusterComputeResourceDetailsView.detailsColumn"),
               field: "details",
               type: "string"
            }];
      }

      private getMasterColumnDefs(): any[] {
         return [
            {
               displayName: this.i18nService.getString("DsClusterUi",
                     "dsClusterComputeResourceList.nameColumn"),
               field: "name",
               type: "string",
               template: this.getObjNameIconRenderer.bind(this)
            }, {
               displayName: this.i18nService.getString("DsClusterUi",
                     "dsClusterComputeResourceList.dsConnectionStatusColumn"),
               field: "isConnected",
               type: "boolean",
               template: this.getConnectivityIconRenderer.bind(this)
            }, {
               displayName: this.i18nService.getString("DsClusterUi",
                     "dsClusterComputeResourceList.allocatedSpace"),
               field: "allocatedSpaceFormatted",
               type: "string"
            }, {
               displayName: this.i18nService.getString("DsClusterUi",
                     "dsClusterComputeResourceList.usedSpace"),
               field: "usedSpaceFormatted",
               type: "string"
            }
         ];
      }

      public getVmConnectivityMasterViewGridOptions(): Object {
         return {
            selectionMode: this.vuiConstants.grid.selectionMode.SINGLE,
            sortMode: this.vuiConstants.grid.sortMode.SINGLE,
            searchable: true,
            resizable: true,
            data: [],
            selectedItems: [],
            columnDefs: this.getVmConnectivityMasterColumnDefs(),
            height: "100%"
         };
      }

      public getVmConnectivityDetailsViewGridOptions(): Object {
         return {
            selectionMode: this.vuiConstants.grid.selectionMode.NONE,
            sortMode: this.vuiConstants.grid.sortMode.SINGLE,
            searchable: false,
            resizable: true,
            data: [],
            selectedItems: [],
            columnDefs: this.getVmConnectivityDetailsColumnDefs(),
            height: "100%"
         };
      }

      private getVmConnectivityDetailsColumnDefs(): any[] {
         return [{
               displayName: this.i18nService.getString("DsClusterUi",
                   "dsCluster.connectivity.vm.columns.fileName"),
               field: "fileName",
               type: "string",
               template: this.getVdNameIconRenderer.bind(this)
            }, {
               displayName: this.i18nService.getString("DsClusterUi",
                   "dsCluster.connectivity.vm.columns.deviceName"),
               field: "deviceName",
               type: "string"
            }, {
               displayName: this.i18nService.getString("DsClusterUi",
                   "dsCluster.connectivity.vm.columns.type"),
               field: "diskType",
               type: "string"
            }, {
               displayName: this.i18nService.getString("DsClusterUi",
                   "dsCluster.connectivity.vm.columns.datastoreName"),
               field: "datastoreName",
               type: "string"
            }, {
               displayName: this.i18nService.getString("DsClusterUi",
                   "dsCluster.connectivity.vm.columns.capacity"),
               field: "capacityValue",
               type: "string"
            }];
      }

      private getVmConnectivityMasterColumnDefs(): any[] {
         return [{
               displayName: this.i18nService.getString("DsClusterUi",
                   "dsClusterComputeResourceList.nameColumn"),
               field: "vmName",
               type: "string",
               template: this.getVmNameRenderer.bind(this)
            },{
               displayName: this.i18nService.getString("DsClusterUi",
                   "dsCluster.connectivity.vm.columns.state"),
               field: "state",
               type: "string",
               template: this.getPowerState.bind(this)
            },{
               displayName: this.i18nService.getString("DsClusterUi",
                   "dsCluster.connectivity.vm.columns.status"),
               field: "status",
               type: "string",
               template: this.getStatusIconRenderer.bind(this)
            },{
               displayName: this.i18nService.getString("DsClusterUi",
                   "dsCluster.connectivity.vm.columns.datastore"),
               field: "datastoreName",
               type: "string",
               template: this.getDatastoreNameIconLinkRenderer.bind(this)
            },{
               displayName: this.i18nService.getString("DsClusterUi",
                   "dsCluster.connectivity.vm.columns.managedBy"),
               field: "managedByLabel",
               type: "string",
               template: this.getManagedByNameIconRenderer
            }, {
               displayName: this.i18nService.getString("DsClusterUi",
                   "dsCluster.connectivity.vm.columns.host"),
               field: "host.name",
               type: "string",
               template: this.getHostObjectLinkRenderer.bind(this)
            }, {
               displayName: this.i18nService.getString("DsClusterUi",
                   "dsCluster.connectivity.vm.columns.provisionedSpace"),
               field: "totalProvisionedSpace",
               type: "string",
               sortable: (dataItem1: any, dataItem2: any) => {
                  return this.compareNumericValues(dataItem1, dataItem2, 'provisionedSpace');
               }
            }, {
               displayName: this.i18nService.getString("DsClusterUi",
                   "dsClusterComputeResourceList.usedSpace"),
               field: "totalUsedSpace",
               type: "string",
               sortable: (dataItem1: any, dataItem2: any) => {
                  return this.compareNumericValues(dataItem1, dataItem2, 'usedSpace');
               }
         }];
      }

      private compareNumericValues(dataItem1: any, dataItem2: any, property: string): number {
         if (dataItem1[property] < dataItem2[property]) {
            return -1;
         }
         if (dataItem1[property] > dataItem2[property]) {
            return 1;
         }
         return 0;
      }

      private getObjNameIconRenderer(data: any): string {
         return this.getNameIconRenderer(data.icon, data.name);
      }

      private getVmNameRenderer(data: any): string {
         data.vmName = data.name + this.resourceUtil
             .getTaggingLabelString(data.label);

         return this.getObjectLinkRenderer(data.provider, data.vmName, data.icon);
      }

      private getPowerState(data: any): string {
         return this.i18nService.getString("Common",
             "powerState." +  data.state);
      }

      private getStatusIconRenderer(data: any): string {
         let status: any = this.entityStatusService.statusProperties(data.status);
         return this.getNameIconRenderer(status.iconClass, status.label);
      }

      private getDatastoreNameIconLinkRenderer(data: any): string {
         let datastoreArray: any[] = data.datastoreArray;

         if (datastoreArray.length === 1) {
            data.datastoreName = datastoreArray[0].name;

            return this.getObjectLinkRenderer(datastoreArray[0].provider,
                data.datastoreName, datastoreArray[0].icon);
         } else if (datastoreArray.length > 1) {
            let title: string;
            let datastoreNames: string[] = [];
            let objTextRenderer: any = this.columnRenderersRegistry
                .getColumnRenderer("text-with-custom-tooltip");

            _.map(datastoreArray, (datastore: any) => {
               datastoreNames.push(datastore.name);
            });
            title = datastoreNames.join(", ");

            data.datastoreName = this.i18nService.getString(
                "DsClusterUi", "sdrs.rules.config.multipleDatastores");

            return objTextRenderer(["text", "title" ], {
               text: data.datastoreName,
               title: title
            });
         }
         return "";
      }

      private getManagedByNameIconRenderer(data: any): string {
         if(data.managedByIcon !== null && data.managedByLabel !== null) {
            return `<span><img src="data:image/png;base64,
                ${data.managedByIcon.imageData}"/>
                ${data.managedByLabel}</span>`;
         } else if (data.managedByLabel !== null) {
            return data.managedByLabel;
         }
         return "";
      }

      private getHostObjectLinkRenderer(data: any): string {
         return this.getObjectLinkRenderer(data.host.provider, data.host.name, data.host.icon);
      }

      private getVdNameIconRenderer(data: any): string {
         return this.getNameIconRenderer("vx-icon-vmDisk", data.fileName);
      }

      private getConnectivityIconRenderer(data: any): string {
         return this.getNameIconRenderer(data.isConnectedIcon, data.connectionLabel);
      }

      public setHostFromCluster(connectivityData: any): void {
         this.clusterConectivityData = connectivityData;
      }

      public getHostFromCluster(cluster: any): any {
         let connectivityObj: any = null;
         _.each(this.clusterConectivityData, (connectivity: any) => {
            if(connectivity.name === cluster.name) {
               connectivityObj = cluster;
            }
         });

         if(connectivityObj !== null) {
            return _.map(connectivityObj.hosts, (host: any)=>{
               return {
                  name: host.name,
                  icon: host.icon,
                  datastoresConnected: host.connectedDatastoresCount,
                  details: host.datastoresConnectionStatus
               };
            });
         }
         return [];
      }

      private getNameIconRenderer(icon: string, name: string): string {
         let renderer: any = this.columnRenderersRegistry
             .getColumnRenderer("icon-text");
         return renderer(["icon", "text"], {
            icon: icon,
            text: name
         });
      }

      private getObjectLinkRenderer(provider: any, name: string, icon: string): string {
         let objectLinkRenderer: any = this.columnRenderersRegistry
             .getColumnRenderer("object-link");
         let ref: string = this.defaultUriSchemeUtil
             .getVsphereObjectId(provider);

         return objectLinkRenderer(
             ["ref", "name", icon],
             {
                ref: ref,
                name: name
             });
      }
   }

   angular.module("com.vmware.vsphere.client.dsCluster")
         .service("dsClusterConectivityService", DsClusterConectivityService);
}
