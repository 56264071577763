/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace h5_vm {
   import OperationResult = com.vmware.vise.core.model.OperationResult;
   import ValidationResult = com.vmware.vise.core.model.ValidationResult;
   import VmCustomizationDuplicateSpec = com.vmware.vsphere.client.vm.customizations.VmCustomizationDuplicateSpec;
   import VmCustomizationDuplicateValidationResult = com.vmware.vsphere.client.vm.customizations.VmCustomizationDuplicateValidationResult;

   export interface DuplicateGosSpecValidationResult {
      isValid: boolean;
      errors?: Array<string>;
      keyValidationSucceeded?: boolean;
   }

   export interface DuplicateGosSpecResult {
      result?: OperationResult;
      openSpecForEdit: boolean;
   }

   export class DuplicateGosSpecService {

      public static $inject = ["$q",
         "i18nService",
         "clarityModalService",
         "mutationService",
         "defaultUriSchemeUtil"
      ];

      private duplicateGosSpecDefered: any;
      private duplicateGosSpecResult: DuplicateGosSpecResult = {
         result: undefined,
         openSpecForEdit: false
      };

      constructor(private $q: any,
                  private i18nService: any,
                  private clarityModalService: any,
                  private mutationService: any,
                  private defaultUriSchemeUtil: any) {
      }

      openDuplicateForm(specInfo: CustomizationSpecInfoCustom,
                        gosCustomizationSpecs: CustomizationSpecInfoCustom[]): angular.IPromise<DuplicateGosSpecResult> {
         this.duplicateGosSpecDefered = this.$q.defer();
         this.duplicateGosSpecResult = {
            result: undefined,
            openSpecForEdit: false
         };

         const modalOptions: any = {
            title: this.i18nService.getString("VmUi", "guestOsCustomization.duplicate"),
            contentTemplate: "vm-ui/resources/vm/guest-os-customization/duplicate/duplicate-gos-spec.view.html",
            defaultButton: "submit",
            dialogData: {
               sourceCustomizationSpecInfo: specInfo,
               allCustomizationSpecs: gosCustomizationSpecs
            },
            onCancel: (): void => {
               this.duplicateGosSpecDefered.reject();
            }
         };

         this.clarityModalService.openOkCancelModal(modalOptions);
         return this.duplicateGosSpecDefered.promise;
      }

      validateSpec(spec: VmCustomizationDuplicateSpec): angular.IPromise<DuplicateGosSpecValidationResult> {
         this.duplicateGosSpecResult.openSpecForEdit = false;
         const sourceSpecManagerId =
               this.defaultUriSchemeUtil.getVsphereObjectId(spec.sourceSpecManagerRef);
         return this.mutationService
               .validate(sourceSpecManagerId, spec._type, spec)
               .then((valRes: ValidationResult) => {
                  if (!valRes || !valRes.result || valRes.error) {
                     const errorMsg: string = this.i18nService.getString(
                           "VmUi", "guestOsCustomization.duplicate.error");
                     return this.$q.resolve(<DuplicateGosSpecValidationResult> {
                        isValid: false,
                        errors: [errorMsg]
                     });
                  }

                  const duplicateValRes = <VmCustomizationDuplicateValidationResult> valRes.result;
                  if (!duplicateValRes.hasVcPrivileges) {
                     const noVcPrivErrorMsg = this.i18nService.getString(
                           "VmUi", "customizationSpec.nameAndOsPage.noPrivileges");
                     return this.$q.resolve(<DuplicateGosSpecValidationResult> {
                        isValid: false,
                        errors: [noVcPrivErrorMsg]
                     });
                  }

                  return this.$q.resolve(<DuplicateGosSpecValidationResult> {
                     isValid: true,
                     keyValidationSucceeded: duplicateValRes.keyValidationSucceeded
                  });
               });
      }

      duplicateSpec(spec: VmCustomizationDuplicateSpec): angular.IPromise<DuplicateGosSpecValidationResult> {
         return this.mutationService
               .add(spec._type, spec)
               .then((opResult: OperationResult) => {
                        this.duplicateGosSpecResult.result = opResult;
                        // resolve the defered and return the expected op result
                        this.duplicateGosSpecDefered.resolve(this.duplicateGosSpecResult);
                        // resolve the modal dialog so it is closed
                        return <DuplicateGosSpecValidationResult> {
                           isValid: true
                        };
                     },
                     (error: any) => {
                        // in case of an error, reject the defered promise
                        this.duplicateGosSpecDefered.reject();
                        // reject the modal dialog and display an error
                        const errorMsg: string = this.i18nService.getString(
                              "VmUi", "guestOsCustomization.duplicate.error");
                        return <DuplicateGosSpecValidationResult> {
                           isValid: false,
                           errors: [errorMsg]
                        };
                     });
      }

      /**
       * Displays an Ok/Cancel dialog that confirms the after the duplication
       * user should reset admin and domain passwords from the edit dialog
       */
      confirmKeyChange(spec: VmCustomizationDuplicateSpec) {
         const modalOptions: any = {
            title: this.i18nService.getString("VmUi", "importCustomizationSpec.invalidKey.title"),
            message: this.i18nService.getString("VmUi", "importCustomizationSpec.invalidKey.text"),
            saveButtonLabel: this.i18nService.getString("Common", "alert.continue"),
            icon: null,
            clarityIcon: {
               shape: "warning",
               class: "is-warning",
               size: 48
            },
            submit: () => {
               this.duplicateSpec(spec);
               this.duplicateGosSpecResult.openSpecForEdit = true;
            },
            onCancel: () => {
               this.duplicateGosSpecDefered.reject();
               this.duplicateGosSpecResult.openSpecForEdit = false;
            },
            preserveNewlines: true
         };

         this.clarityModalService.openConfirmationModal(modalOptions);
      }
   }

   angular.module("com.vmware.vsphere.client.vm")
         .service("duplicateGosSpecService", DuplicateGosSpecService);
}
