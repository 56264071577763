module cluster_ui {

   interface ClusterUtilizationViewScope extends angular.IScope {
      _route: {
         objectId: string;
      };

      portletOptions: {
         layout: string;
         portlets: Array<any>
      };

      _view: {
         $id: string;
         children: Array<any>;
      };
   }

   export class ClusterUtilizationViewController implements angular.IComponentController {

      public headerOptions: any;
      public watchForObjects: any;
      private objectId: string | null;

      public static $inject = ["$scope", "vuiConstants", "extensionService",
         "summaryService"];

      constructor(private $scope: ClusterUtilizationViewScope, private vuiConstants: any,
                  private extensionService: any, private summaryService: any) {
         this.objectId = $scope._route ? $scope._route.objectId : null;

         this.headerOptions = {
            title: "",
            objectId: this.objectId,
            actions: []
         };
         this.watchForObjects = [this.objectId];

         $scope.portletOptions = {
            layout: vuiConstants.portlets.layout.TWO_COLUMNS,
            portlets: this.getSummaryPortletViews()
         };
      }

      /**
       * Update the portlet views optimistically.
       */
      public refreshPortlets(): void {
         //update the UI with already available data
         this.updateExistingPortlets(this.$scope._view.children);

         //Request portlet data from the server and update if necessary
         this.requestViewExtensions().then((extensions: any) => {
            this.updateExistingPortlets(extensions);
         });
      };

      private updateExistingPortlets(portletSpecs: any) {
         var portletViews = this.summaryService.createPortlets(
               portletSpecs, this.objectId);

         this.$scope.portletOptions.portlets = this.summaryService.updatePortlets(
               portletViews, this.$scope.portletOptions.portlets);
      }

      private requestViewExtensions(): any {
         return this.extensionService.getHostedExtensions(
               this.$scope._view.$id, this.objectId);
      }

      private getSummaryPortletViews(): any {
         return this.summaryService.createPortlets(
               this.$scope._view.children, this.objectId);
      }
   }

   angular.module("com.vmware.vsphere.client.cluster")
         .controller("ClusterUtilizationViewController", ClusterUtilizationViewController);
}
