namespace h5_vm {
   import CustomizeGosSpecData = com.vmware.vsphere.client.h5.vm.data.CustomizeGosSpecData;
   export interface CloneCustomizeGosSpecData {
      error: string;
      data: CustomizeGosSpecData;
   }
   export class CustomizeGosPreCheckSpecsService {
      static $inject = ["dataService", "i18nService", "clarityModalService", "$q",
         "defaultUriSchemeUtil"];
      private CUSTOMIZE_GOS_SPEC_DATA: string = "customizeGosSpecData";
      private i18n: Function;

      constructor(private dataService: any, private i18nService: any,
            private clarityModalService: any, private $q: angular.IQService,
            private defaultUriSchemeUtil: any) {
         this.i18n = this.i18nService.getString;
      }

      customizeGosLoadData(vmId: string): IPromise<CustomizeGosSpecData> {
         return this.loadData(vmId).then((data: CustomizeGosSpecData) => {
            let error = this.validateData(data);
            if (error) {
               this.clarityModalService.openConfirmationModal({
                  title: this.i18n("VmUi", "customizeGuestOs.title"),
                  message: error,
                  submit: function () {
                  },
                  icon: "vui-icon32-error",
                  preserveNewlines: true,
                  saveButtonLabel: this.i18n("Common", "close.label"),
                  cancelButtonLabel: null,
                  hideCancelButton: true
               });
               return this.$q.reject(undefined);
            }

            return this.$q.resolve(data);
         });
      }

      cloneCustomizeGosLoadData(vmId: string, onlyPrivilegeCheck: boolean = false, vCenterId: string): IPromise<CloneCustomizeGosSpecData> {
         return this.loadData(vmId, vCenterId).then((data: CustomizeGosSpecData) => {
            let error = this.validateDataInternal(data, onlyPrivilegeCheck);
            let result: CloneCustomizeGosSpecData = {
               error: error,
               data: data
            };
            return this.$q.resolve(result);
         });
      }

      validateData(data: CustomizeGosSpecData) {
         return this.validateDataInternal(data);
      }

      private loadData(vmId: string, vCenterId?: string): IPromise<CustomizeGosSpecData> {
         const rootFolder = vCenterId
               ? vCenterId
               : this.defaultUriSchemeUtil.getRootFolderFromVsphereObjectId(vmId);

         const propertyOptions = {
            propertyParams: [{
               propertyName: this.CUSTOMIZE_GOS_SPEC_DATA,
               parameterType: "java.lang.String",
               parameter: rootFolder
            }]
         };

         return this.dataService.getProperties(vmId, [this.CUSTOMIZE_GOS_SPEC_DATA], propertyOptions)
               .then((response: any) => {
                  let data: CustomizeGosSpecData = response[this.CUSTOMIZE_GOS_SPEC_DATA];
                  return data;
               });
      }

      private validateDataInternal(data: CustomizeGosSpecData, onlyPrivilegeCheck: boolean = false): string {
         let error = "";

         if (!data.vmCustomizePrivGranted) {
            return this.i18n("VmUi",
                  "CustomizeGuestOsProvisioningPage.content.no_permissions_vm");
         }
         if (!data.vcReadSpecPrivGranted) {
            return this.i18n("VmUi",
                  "CustomizeGuestOsProvisioningPage.content.no_read_permissions");
         }

         if (onlyPrivilegeCheck) {
            return error;
         }

         if (data.pendingCustomization) {
            return this.i18n("VmUi",
                  "customizationManager.error.pendingCustomization");
         }

         switch (data.compatibility) {
            case "MISSING_WINDOWS_CUSTOMIZATION":
               error = this.i18n("VmUi",
                     "CustomizationDataProvider.missing_windows");
               break;
            case "MISSING_LINUX_CUSTOMIZATION":
               error = this.i18n("VmUi",
                     "CustomizationDataProvider.missing_linux");
               break;
            case "UNCUSTOMIZABLE_GUEST":
               error = this.i18n("VmUi",
                     "CustomizationDataProvider.cannot_customize");
               break;
            default:
               break;
         }

         return error;
      }
   }

   angular.module("com.vmware.vsphere.client.vm")
         .service("customizeGosPreCheckSpecsService", CustomizeGosPreCheckSpecsService);
}
