namespace storage_ui {

   import IDeferred = angular.IDeferred;
   import IPromise = angular.IPromise;
   import IQService = angular.IQService;

   interface ClusterInfoItem {
      name: string;
   }

   export class AffectHeartbeatDatastoreService {
      private readonly haAffectedClustersProperty: string = "datastore:haHeartbeatAffectedClusters";

      public static $inject = ["i18nService",
         "clarityModalService",
         "clarityConstants",
         "dataService",
         "$q",
         "columnRenderersRegistry",
         "vuiConstants"];

      constructor(private i18nService: any,
                  private clarityModalService: any,
                  private clarityConstants: any,
                  private dataService: any,
                  private $q: IQService,
                  private columnRenderersRegistry: any,
                  private vuiConstants: any) {
      }

      // Initiates a dataservice call and shows a warning dialog if any clusters are affected.
      // A promise is returned to the client.
      // Upon acknowledging the warning the control flow is returned to the client by resolving the promise.
      public checkForAffectedClusters(dsId: string, selectedHosts: any[]): IPromise<ClusterInfoItem> {
         let deferred: IDeferred<Boolean> = this.$q.defer();
         return this.dataService.getProperties(dsId, [this.haAffectedClustersProperty], {
            propertyParams: [{
               propertyName: this.haAffectedClustersProperty,
               parameterType: "com.vmware.vsphere.client.h5.storage.spec.HeartbeatDatastoreSpec",
               parameter: {
                  hostsToRemove: selectedHosts
               }
            }]
         }).then((result: any) => {
            let affectedClusters: ClusterInfoItem[] = result && result[this.haAffectedClustersProperty]
                  ? result[this.haAffectedClustersProperty]
                  : [];
            if (affectedClusters.length) {
               let iconRenderer = this.columnRenderersRegistry.getColumnRenderer('icon-text');
               let modalOptions: any = {
                  title: this.i18nService.getString("StorageUi", "unmountDatastoreHaWarning"),
                  contentTemplate: "storage-ui/resources/storage/views/datastore/affectedHeartbearClusterDialog.html",
                  dialogData: {
                     i18n: this.i18nService.getString,
                     gridOptions: {
                        selectionMode: this.vuiConstants.grid.selectionMode.SINGLE,
                        height: '100%',
                        resizable: true,
                        columnDefs: [{
                           displayName: this.i18nService.getString("StorageUi", "unmountDatastoreHaGridNameColumn"),
                           field: "name",
                           template: function (dataItem:any) {
                              let rendererData = {
                                 icon: "vsphere-icon-cluster",
                                 text: dataItem.name
                              };
                              return iconRenderer(["icon", "text"], rendererData);
                           }
                        }],
                        data: affectedClusters
                     }
                  },
                  onSubmit: () => {
                     // Warning is acknowledged.
                     deferred.resolve(true);
                     // Close the warning dialog.
                     return true;
                  },
                  onCancel: () => {
                     // Warning is cancelled.
                     deferred.resolve(false);
                  }
               };
               this.clarityModalService.openOkCancelModal(modalOptions);
            } else {
               // If no clusters will be affected, do not show the dialog at all and
               // return the execution to client.
               deferred.resolve(true);
            }
            return deferred.promise;
         }, () => {
            // If an error occurred while fetching property, do not show the dialog
            // at all and return the execution to client.
            deferred.resolve(true);
            return deferred.promise;
         });
      }
   }

   angular.module("com.vmware.vsphere.client.storage")
         .service("affectHeartbeatDatastoreService", AffectHeartbeatDatastoreService);
}
