namespace h5_vm {

   import GosType = h5_vm.GosType;

   export class GosWindowsLicensePageController {
      public static $inject = ["$q",
          "i18nService"];

      public model: GosWindowsLicensePageModel;

      public readonly AUTO_USERS_MIN = 5;
      public readonly AUTO_USERS_MAX = 9999;
      public readonly MAX_CHARS_PRODUCT_KEY: number = 29;
      public invalidProductKeyMessage: string;


      constructor(private $q: any,
            public i18n: any) {
      }

      $onInit(): void {
         this.invalidProductKeyMessage = "";
         this.model.onPageCommit = this.onCommit.bind(this);

         let maxConnectionsInput: any =
               angular.element("input[ng-model='$ctrl.model.maxConnections']");
         maxConnectionsInput
               .on("input", function() {
                  if (maxConnectionsInput[0].value.length > 4) {
                     maxConnectionsInput[0].value = maxConnectionsInput[0].value.slice(0, 4);
                  }
               });
      }

      $onDestroy(): void {
         this.model.onPageCommit = undefined;
      }

      onCommit(): angular.IPromise<Array<string>> {
         let errors: Array<String> = [];

         if (this.invalidProductKeyMessage !== "") {
            errors = errors.concat([
               this.errorMessage("invalidProductKeyLength")
            ]);
         }

         if (this.model.isIncludeServerLicenseInfo && this.model.isServerLicenseModePerServer) {
            if (!this.model.maxConnections ||
                  this.AUTO_USERS_MIN > this.model.maxConnections ||
                  this.AUTO_USERS_MAX < this.model.maxConnections) {
               errors = errors.concat([
                     this.errorMessage("maxConnectionsInputError")
               ]);
            }
         }
         return this.$q.resolve(errors);
      }

      onProductKeyChanged(): void {
         this.invalidProductKeyMessage = this.validateProductKey(this.model.productKey);
      }

      private validateProductKey(productKey: string): string {
         if (productKey.length > this.MAX_CHARS_PRODUCT_KEY) {
            return this.errorMessage("invalidProductKeyLength");
         }
         return "";
      }

      private errorMessage(suffix: string, args: (string | number)[] = []): string {
         return this.i18n.getString("VmUi",
               "customizationSpec.windowsLicensePage." + suffix, ...args);
      }
   }

   export class GosWindowsLicensePageComponent implements angular.IComponentOptions {
      public controller: any;
      public templateUrl: string;
      public bindings: any;

      constructor() {
         this.controller = GosWindowsLicensePageController;
         this.templateUrl =
            "vm-ui/resources/vm/guest-os-customization/pages/windows-license/gos-windows-license-page.component.html";
         this.bindings = {
            model: "<"
         };
      }
   }

   angular
      .module("com.vmware.vsphere.client.vm")
      .component("gosWindowsLicensePage", new GosWindowsLicensePageComponent());
}
