namespace storage_ui {

   export class ValidationResult {
      public isValid: boolean;
      public errors: string[];
   }

   export class AuthenticationSettings {
      public outgoing: StorageAdapterAuthenticationChapSectionModel;
      public incoming: StorageAdapterAuthenticationChapSectionModel;
      public authMethodSelected: string;
   }

   export class StorageAdapterAuthenticationSettingsApi {
      public validate: () => ValidationResult;
      public isDirty: () => boolean;
      public getSettings: () => AuthenticationSettings;
   }

   export class StorageAdapterAuthenticationSettings {

      public isSoftwareBased: boolean;
      public adapterName: string;
      public authentication: any;
      public componentApi: StorageAdapterAuthenticationSettingsApi;

      public authMethodSelected: any;
      public authenticationMethods: any[];

      public i18n: Function;

      public outgoing: StorageAdapterAuthenticationChapSectionModel;
      public incoming: StorageAdapterAuthenticationChapSectionModel;

      public isDisabled: boolean;

      private outgoingValidator: any;
      private incomingValidator: any;

      public static $inject = ["i18nService", "storageAdapterConstants", "vxValidatorFactory"];

      constructor(private i18nService: any,
                  public storageAdapterConstants: any,
                  vxValidatorFactory:any) {

         this.i18n = i18nService.getString;
         this.outgoingValidator = vxValidatorFactory.create();
         this.incomingValidator = vxValidatorFactory.create();
      }

      private replaceDummyPassword(password:string) {
         if (password) {
            return this.storageAdapterConstants.EXISTING_PASSWORD_PLACEHOLDER;
         }
         return password;
      }

      public $onInit() {
         if (this.componentApi) {
            this.componentApi.validate = this.validate.bind(this);
            this.componentApi.isDirty = this.isDirty.bind(this);
            this.componentApi.getSettings = this.getSettings.bind(this);
         }

      }

      public $onChanges() {

         this.buildAuthenticationMethodOptions();

         this.incoming = {
            name: this.authentication.incomingName,
            secret: this.authentication.authenticationMethod !== this.storageAdapterConstants.authentication.MUTUAL
                  ? ""
                  : this.replaceDummyPassword(this.authentication.incomingSecret),
            useInitiatorName: this.authentication.useInitiatorNameForIncoming
         };

         this.outgoing = {
            name: this.authentication.outgoingName,
            secret: this.authentication.authenticationMethod === this.storageAdapterConstants.authentication.PROHIBITED
                  ? ""
                  : this.replaceDummyPassword(this.authentication.outgoingSecret),
            useInitiatorName: this.authentication.useInitiatorNameForOutgoing
         };

         this.authMethodSelected = {id: this.authentication.authenticationMethod};
      }

      public getSettings(): AuthenticationSettings {
         let settings = new AuthenticationSettings();
         settings.authMethodSelected = this.authMethodSelected.id;
         settings.outgoing = this.outgoing;
         settings.incoming = this.incoming;
         return settings;
      }

      private buildAuthenticationMethodOptions() {
         if (!this.isSoftwareBased) {
            this.authenticationMethods = [{
               name: this.i18n("StorageUi", "storage.adapters.iscsi.chapProhibitedchapProhibited"),
               id: this.storageAdapterConstants.authentication.PROHIBITED
            }, {
               name: this.i18n("StorageUi", "storage.adapters.iscsi.chapPreferredchapProhibited"),
               id: this.storageAdapterConstants.authentication.PREFERRED
            }];
         } else {
            this.authenticationMethods = [
               {
                  name: this.i18n("StorageUi", "storage.adapters.iscsi.chapProhibitedchapProhibited"),
                  id: this.storageAdapterConstants.authentication.PROHIBITED
               },
               {
                  name: this.i18n("StorageUi", "storage.adapters.iscsi.chapDiscouragedchapProhibited"),
                  id: this.storageAdapterConstants.authentication.DISCOURAGED
               },
               {
                  name: this.i18n("StorageUi", "storage.adapters.iscsi.chapPreferredchapProhibited"),
                  id: this.storageAdapterConstants.authentication.PREFERRED
               },
               {
                  name: this.i18n("StorageUi", "storage.adapters.iscsi.chapRequiredchapProhibited"),
                  id: this.storageAdapterConstants.authentication.REQUIRED
               },
               {
                  name: this.i18n("StorageUi", "storage.adapters.iscsi.chapRequiredchapRequired"),
                  id: this.storageAdapterConstants.authentication.MUTUAL
               }
            ];
         }
      }

      /**
       * Checks if user changed the values returned from server
       **/
      public isDirty(): boolean {
         return this.authentication.outgoingName !== this.outgoing.name
               || this.outgoing.secret && this.outgoing.secret !== this.storageAdapterConstants.EXISTING_PASSWORD_PLACEHOLDER
               || this.authentication.incomingName !== this.incoming.name
               || this.incoming.secret && this.incoming.secret !== this.storageAdapterConstants.EXISTING_PASSWORD_PLACEHOLDER
               || this.authentication.authenticationMethod !== this.authMethodSelected.id;
      }

      public validate(): ValidationResult {

         let validationResult = new ValidationResult();

         let errors: string[] = [];
         let isOutgoingEnabled = this.authMethodSelected.id !== this.storageAdapterConstants.authentication.PROHIBITED;
         let isIncomingEnabled = this.authMethodSelected.id === this.storageAdapterConstants.authentication.MUTUAL;

         // outgoing
         if (isOutgoingEnabled) {
            errors = errors.concat(this.outgoingValidator.validate());
         }
         // incoming
         if (isIncomingEnabled) {
            errors = errors.concat(this.incomingValidator.validate());
         }
         if (isIncomingEnabled && errors.length === 0) {
            if (this.incoming.secret === this.outgoing.secret &&
                  this.incoming.secret !== this.storageAdapterConstants.EXISTING_PASSWORD_PLACEHOLDER) {

               validationResult.errors = [
                  this.i18nService.getString("StorageUi", "storage.adapters.edit.authentication.form.secretsMatch")
               ];
               validationResult.isValid = false;
               return validationResult;
            }
         }

         validationResult.isValid = !errors.length;
         return validationResult;
      }
   }

   angular.module("com.vmware.vsphere.client.storage")
         .component("storageAdapterAuthenticationSettings", {
            templateUrl: "storage-ui/resources/storage/views/host/adapters/authentication/StorageAdapterAuthenticationSettings.html",
            bindings: {
               isSoftwareBased: "<",
               adapterName: "<",
               authentication: "<",
               isDisabled: "<",
               componentApi: "="
            },
            controller: StorageAdapterAuthenticationSettings
         });
}
