/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace dvs_ui {

   export class DvsSpanSessionDestinationUplinksModel {

      public uplinks: string[];

      public notSelectedUplinks: string[];

      constructor() {
         this.uplinks = [];
         this.notSelectedUplinks = [];
      }
   }
}