/* Copyright 2018 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace network_ui {

   export class ProxySwitchDetailsDialogService {

      static readonly $inject: any[] = [
            "i18nService",
            "dataService",
            "dvsDetailsViewService"
      ];

      static readonly CONFIG_DATA_PROPERTY_NAME: string = "dvswitchproperties:configdata";

      constructor(private i18nService: any,
            private dataService: any,
            private dvsDetailsViewService: any) {}

      public getData(dvsId: string): any {
         return this.dataService.getProperties(dvsId, [ProxySwitchDetailsDialogService.CONFIG_DATA_PROPERTY_NAME])
               .then((response: any): void => {
                  const rawData: any =
                        response && response[ProxySwitchDetailsDialogService.CONFIG_DATA_PROPERTY_NAME];

                  if (rawData && rawData.name) {
                     let trimmedName: string = rawData.name.trim();
                     let escapedName: string = trimmedName.replace(/%25/g, "%")
                           .replace(/%2f/g, "\/").replace(/%5c/g, "\\");

                     rawData.name = escapedName;
                  }

                  return this.dvsDetailsViewService.build(rawData, true);
               });
      }
   }

   angular.module("com.vmware.vsphere.client.network")
         .service("proxySwitchDetailsDialogService", ProxySwitchDetailsDialogService);
}