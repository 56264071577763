/* Copyright 2015 VMware, Inc. All rights reserved. -- VMware Confidential */

/*
 * Controller for the permission view.
 */
(function() {
   'use strict';
   angular
         .module('com.vmware.vsphere.client.commonModule')
         .controller('PermissionViewController', PermissionViewController);

   PermissionViewController.$inject = ['$scope', '$q', 'i18nService', 'PermissionDataService',
      'columnRenderersRegistry', 'vuiConstants', 'actionsService', 'clarityModalService',
      'userPrivilegeService'];

   function PermissionViewController($scope, $q, i18nService, PermissionDataService,
         columnRenderersRegistry, vuiConstants, actionsService, clarityModalService,
      userPrivilegeService) {

      var self = this;
      var objectId = $scope._route.objectId ||  $scope._vxViewNodeData.node.contentSpec.metadata.objectId;
      var i18n = i18nService.getString.bind(i18nService, 'Common');
      var targetNameRenderer =
         columnRenderersRegistry.getColumnRenderer('object-name');

      self.watchForObjects = [objectId];
      self.havePrivilege = false;
      //Set column definitions
      self.datagridOptions = {
         columnDefs: [{
            displayName: i18n('PermissionView.userGroupName'),
            field: 'principal',
            width: '40%',
            template: function(dataItem) {
               var value = dataItem.principal;
               var principalIcon;
               var principalIconAriaLabel;
               if (dataItem.isGroup) {
                  principalIcon = 'icon-vSphere-group';
                  principalIconAriaLabel = i18n("PermissionView.group");
               } else {
                  principalIcon = 'icon-vSphere-user';
                  principalIconAriaLabel = i18n("PermissionView.user");
               }
               return '<div>' +
                        '<i class="' + principalIcon + '" aria-label="' + _.escape(principalIconAriaLabel) + '"></i>' +
                        '<span' +
                        ' title="' + _.escape(value) + '">' +
                        _.escape(value) + '</span>' +
                      '</div>';
            }
         }, {
            displayName: i18n('PermissionView.role'),
            field: 'role',
            width: '30%',
            template: function(dataItem) {
               return '<span>' + dataItem.role + '</span>';
            }
         }, {
            displayName: i18n('PermissionView.defined'),
            width: '30%',
            field: 'entityId',
            searchable: false,
            template: function(dataItem) {
               return getDefinedIn(dataItem);
            }
         }],
         data: [],
         sortOrder: [{
            field: 'principal',
            dir: 'asc'
         }],
         sortMode: vuiConstants.grid.sortMode.SINGLE,
         selectionMode: vuiConstants.grid.selectionMode.SINGLE,
         selectedItems: [],
         height: '100%',
         resizable: true,
         actionBarOptions: {
            actions: [
               {
                  id: 'add-permission-action',
                  iconClass: 'vsphere-icon-toolbar-add',
                  tooltipText: i18n('permissionAction.add.label'),
                  enabled: self.havePrivilege,
                  onClick : addPermission
               }, {
                  id: 'edit-permission-action',
                  iconClass: 'vsphere-icon-toolbar-edit',
                  tooltipText: i18n('permissionAction.edit.label'),
                  enabled: false,
                  onClick: editPermission
               }, {
                  id: 'delete-permission-action',
                  iconClass: 'vsphere-icon-toolbar-remove',
                  tooltipText: i18n('permissionAction.remove.label'),
                  enabled: self.havePrivilege,
                  onClick : removePermission
               }
            ]
         }
      };

      $scope.$watch(function() {
         return self.datagridOptions.selectedItems;
      }, function(selectedItems) {

         self.datagridOptions.actionBarOptions.actions[0].enabled=false;
         self.datagridOptions.actionBarOptions.actions[1].enabled=false;
         self.datagridOptions.actionBarOptions.actions[2].enabled=false;

         if (self.havePrivilege) {
            self.datagridOptions.actionBarOptions.actions[0].enabled=true;
            if (!_.isEmpty(selectedItems)) {
               self.datagridOptions.actionBarOptions.actions[1].enabled = true;
               if ((selectedItems[0].isDefinedOnCurrentObject ||
                     (objectId === 'urn:vri:acl:RootPermissionNode:root' && selectedItems[0].isGlobal))) {
                  self.datagridOptions.actionBarOptions.actions[2].enabled = true;
               }
            }
         }
      });

      // Determines the content of "Defined In" column
      function getDefinedIn(dataItem) {
         if (dataItem.isGlobal) {
            return '<span>' + i18n('globalPermission') + '</span>';
         }

         if (dataItem.isDefinedOnCurrentObject) {
            var result = dataItem.propagateToChildren ?
                  'permissionView.thisEntity.propagate' : 'PermissionView.thisEntity';
            return '<span>' + i18n(result) + '</span>';
         }
         return targetNameRenderer(['entityId', 'icon', 'entityName'], dataItem);
      }

      function addPermission(){
         return h5.actions['vsphere.core.inventory.actions.addPermission'](
            {
                action: {
                   label: i18n('permissionAction.add.label')
                }
            }, [objectId], {edit: false} );
      }

      function editPermission(){
         return h5.actions['vsphere.core.inventory.actions.addPermission'](
            {
               action: {
                  label: i18n('permissionAction.edit.dialog.title')
               }
            }, [objectId], {edit: true, selectedPermission: JSON.parse(JSON.stringify(self.datagridOptions.selectedItems[0]))});
      }

      function removePermission() {
         var confirmProperties = {
            title: i18nService.getString('Common', 'permissionAction.remove.dialog.title'),
            message: i18nService.getString('Common', 'permissionAction.remove.warning'),
             icon: 'icon-warning-32',
             defaultButton: 'close',
            submit: function () {
               PermissionDataService.removeUserGroupPermissions(objectId, self.datagridOptions.selectedItems[0].toJSON());
            }
         };
         clarityModalService.openConfirmationModal(confirmProperties);
      }


      self.fetchPermissions = function() {
         if (!objectId) {
            objectId = $scope._vxViewNodeData.node.contentSpec.metadata.objectId;
         }
         var privilegesRequest = userPrivilegeService.getUserPrivileges(objectId);
         var permissionViewDataRequest = PermissionDataService.getEntityPermissions(objectId);

         $q.all([privilegesRequest, permissionViewDataRequest]).then(function(responses) {
            var privileges = responses[0];
            var viewData = responses[1];
            self.havePrivilege = privileges.grantedPrivileges &&
                  privileges.grantedPrivileges.indexOf('Authorization.ModifyPermissions') >= 0;
            self.datagridOptions.data = viewData;
         });
      };

      self.showActionsMenu = function(event) {
         var left =  event.clientX;
         var top = event.clientY;
         var selectedObjectIds = event.data;
         actionsService.showObjectContextMenu(event, $scope, selectedObjectIds, left, top);
      };

      self.fetchPermissions();
   }
})();
