/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace dvs_ui {

   export class DvsEditNetFlowDialogConstants {

      public static readonly COLLECTOR_PORT_MIN_VAL: number = 0;
      public static readonly COLLECTOR_PORT_MAX_VAL: number = 65535;
      public static readonly OBSERVATION_DOMAIN_ID_MIN_VAL: number = 0;
      public static readonly OBSERVATION_DOMAIN_ID_MAX_VAL: number = 4294967295;
      public static readonly ACTIVE_FLOW_MIN_VAL: number = 60;
      public static readonly ACTIVE_FLOW_MAX_VAL: number = 3600;
      public static readonly IDLE_FLOW_MIN_VAL: number = 10;
      public static readonly IDLE_FLOW_MAX_VAL: number = 600;
      public static readonly SAMPLING_RATE_MIN_VAL: number = 0;
      public static readonly SAMPLING_RATE_MAX_VAL: number = 1000;
      public static readonly INTERNAL_FLOWS_ONLY_ENABLED: boolean = true;
      public static readonly INTERNAL_FLOWS_ONLY_DISABLED: boolean = false;
   }
}