module common_module_ui {

   export class DrsFaultsViewService {

      public static $inject: string[] = ["vuiConstants", "i18nService",
            "drsFaultsViewConstants", "defaultUriSchemeUtil", "linksUtil"];

      constructor(private vuiConstants: any, private i18nService: any,
            private drsFaultsViewConstants: any,
            private defaultUriSchemeUtil: any, private linksUtil: any) { }

      public getMasterViewGridOptions(): Object {
         return {
            selectionMode: this.vuiConstants.grid.selectionMode.SINGLE,
            sortMode: this.vuiConstants.grid.sortMode.SINGLE,
            searchable: false,
            resizable: true,
            data: [],
            selectedItems: [],
            columnDefs: this.getMasterColumnDefs(),
            height: "100%"
         };
      }

      public getDetailsViewGridOptions(): Object {
         return {
            selectionMode: this.vuiConstants.grid.selectionMode.NONE,
            sortMode: this.vuiConstants.grid.sortMode.SINGLE,
            searchable: false,
            resizable: true,
            data: [],
            selectedItems: [],
            columnDefs: this.getDetailsColumnDefs(),
            height: "100%"
         };
      }

      /**
       * Interpolates the template string with the target objects links.
       *
       * @param templateStr
       * @param targetObjects
       * @returns {*}
       */
      public interpolateWithLinks(templateStr: string, targetObjects: any): string {
         let interpolations: string[] = [];

         targetObjects.forEach((targetObject: any) => {
            let objectType: any = typeof targetObject;
            let text: string = "";
            if (objectType === "object") {
               let id = this.defaultUriSchemeUtil.getVsphereObjectId(
                     targetObject[this.drsFaultsViewConstants.ID_KEY]);
               text = this.linksUtil.generateObjectLink(id,
                     targetObject[this.drsFaultsViewConstants.NAME_KEY]);
            } else if (objectType === "string") {
               text = targetObject;
            }

            interpolations.push(text);
         });

         return this.i18nService.interpolate(templateStr, interpolations);
      }

      private getMasterColumnDefs(): any[] {
         return [
            {
               displayName: this.i18nService.getString("Common",
                     "drs.faultControl.reasonColumnHeaderLabel"),
               field: this.drsFaultsViewConstants.LOCALIZED_REASON,
               type: "string",
               template: (data: any) => {
                  return this.gridColumnTemplate(
                        this.drsFaultsViewConstants.LOCALIZED_REASON,
                        this.drsFaultsViewConstants.ICON_FIELD, data);
               }
            },
            {
               displayName: this.i18nService.getString("Common",
                     "drs.faultControl.targetColumnHeaderLabel"),
               field: this.drsFaultsViewConstants.TARGET_TEXT_FIELD,
               type: "string",
               template: (data: any) => {
                  return this.gridColumnTemplate(
                        this.drsFaultsViewConstants.TARGET_TEXT_FIELD,
                        this.drsFaultsViewConstants.ICON_TARGET_FIELD, data);
               }
            }
         ];
      }

      private getDetailsColumnDefs(): any[] {
         return [
            {
               displayName: this.i18nService.getString("Common",
                     "drs.faultControl.details.faultColumnHeaderLabel"),
               field: this.drsFaultsViewConstants.REASON,
               type: "string",
               template: (data: any) => {
                  return this.gridColumnTemplate(this.drsFaultsViewConstants.REASON,
                        this.drsFaultsViewConstants.REASON_ICON_CLASS, data);
               }
            },
            {
               displayName: this.i18nService.getString("Common",
                     "drs.faultControl.details" +
                     ".preventedRecommendationColumnHeaderLabel"),
               field: this.drsFaultsViewConstants.PREVENTED_RECOMMENDATION,
               type: "string",
               template: (data: any) => {
                  return this.gridColumnTemplate(
                        this.drsFaultsViewConstants.PREVENTED_RECOMMENDATION,
                        this.drsFaultsViewConstants.PREVENTED_RECOMMENDATION_ICON_CLASS,
                        data);
               }
            }
         ];
      }

      private gridColumnTemplate(fieldValue: string, iconValue: string, data: any): string {
         let props: any = {};
         props[this.drsFaultsViewConstants.FIELD_KEY] = fieldValue;
         props[this.drsFaultsViewConstants.ICON_KEY] = iconValue;

         return this.iconWithTextItemRenderer(props, data);
      }

      /**
       * Renders an icon along with text in a datagrid.
       *
       * @param props
       * @param data
       * @returns {string}
       */
      private iconWithTextItemRenderer(props: any, data: any): string {
         let objData: any = data || {};
         objData.text = this.getNestedObjectProperty(data,
               props[this.drsFaultsViewConstants.FIELD_KEY]);
         objData.icon = this.getNestedObjectProperty(data,
               props[this.drsFaultsViewConstants.ICON_KEY]);

         let iconSpan: string = "<span class=\"" + objData.icon + "\"></span>";

         return [iconSpan, objData.text].join("");
      }

      /**
       * Returns the nested property of the object.
       *
       * @param object
       * @param property
       * @returns {*}
       */
      private getNestedObjectProperty(object: any, property: string): Object {
         let o: any = object;

         property.split(".").forEach(function(property) {
            o = o[property];
         });

         return o;
      }
   }

   angular.module("com.vmware.vsphere.client.commonModule")
         .service("drsFaultsViewService", DrsFaultsViewService);
}
