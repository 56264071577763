module ds_cluster_ui {

   import WizardPageControllerAbstract =
         ds_cluster_ui.WizardPageControllerAbstract;
   import IPromise = angular.IPromise;

   class ReadyToCompletePageController extends WizardPageControllerAbstract {

      public static $inject = ["createDsClusterWizardManager", "vuiConstants",
            "simpleValidationCommit", "i18nService", "vxPropertyViewService",
            "columnRenderersRegistry", "$q", "createDsClusterWizardHostsAndClustersService",
            "createDsClusterPageConstants","storageDrsLocalizationService",
            "dsClusterConstants"];

      public location: DsClusterLocation;
      public nameAndLocationData: any;
      public automationOverrides: any;
      public runtimeSettings: any;
      public datastoresGridOptions: Object;
      public clustersAndHostsGridOptions: any;
      private model: DsClusterModel;

      constructor(createDsClusterWizardManager: CreateDsClusterWizardManager,
            private vuiConstants: any, simpleValidationCommit: any,
            private i18nService: any, private vxPropertyViewService: any,
            private columnRenderersRegistry: any, private $q: any,
            private createDsClusterWizardHostsAndClustersService:
                  CreateDsClusterWizardHostsAndClustersService,
            private createDsClusterPageConstants: any,
            private storageDrsLocalizationService: any,
            private dsClusterConstants: any) {
         super(createDsClusterWizardManager, simpleValidationCommit);

         this.initPageSubmit();
         this.initModel();
      }

      protected getValidationErrors(): IPromise<string[]> {
         return this.$q.reject();
      }

      protected getCurrentPageIndex(): number {
         return this.createDsClusterPageConstants.READY_TO_COMPLETE_INDEX;
      }

      private initModel(): void {
         let self: ReadyToCompletePageController = this;
         this.model = this.createDsClusterWizardManager.getModel();
         this.buildNameAndLocationData();
         this.buildAutomationOverridesData();
         this.buildRuntimeSettingsData();
         this.datastoresGridOptions = this.getDatastoresGridOptions();
         this.clustersAndHostsGridOptions = this.getClustersAndHostsGridOptions();
         this.createDsClusterWizardHostsAndClustersService.getHostsAndClusters(
               this.model.datastoreRefs, this.model.hostsAndClusters)
               .then(function (hostsAndClusters: HostsAndClustersItem[]) {
                  self.clustersAndHostsGridOptions.data = hostsAndClusters;
               });
      }

      private buildNameAndLocationData() {
         let builder = this.vxPropertyViewService.createPropertyViewBuilder();
         let sectionBuilder = builder
               .category("")
               .section("")
               .title(this.i18nService.getString("DsClusterUi",
                     "createDsClusterWizard.nameAndLocationPage.title"));

         sectionBuilder.property(this.i18nService.getString("DsClusterUi",
               "createDsClusterWizard.nameAndLocationPage.dsClusterNameLabel"),
               this.model.name);
         let sdrsLocalizedStatus: string = this.i18nService.getString("DsClusterUi",
               "createDsClusterWizard.readyToCompletePage."
               + (this.model.sdrsEnabled ? "sdrsEnabled" : "sdrsDisabled"));

         sectionBuilder.property(this.i18nService.getString("DsClusterUi",
               "createDsClusterWizard.readyToCompletePage.sdrsLabel"),
               sdrsLocalizedStatus);

         this.nameAndLocationData = builder.build();
      }

      private buildAutomationOverridesData() {
         let builder = this.vxPropertyViewService.createPropertyViewBuilder();
         let sectionBuilder = builder
               .category("")
               .section("")
               .title(this.i18nService.getString("DsClusterUi",
                     "createDsClusterWizard.automationLevelPage.title"));

         const automationOverrides = this.model.automationOverrides;

         if (automationOverrides) {
            sectionBuilder.property(this.i18nService.getString("DsClusterUi",
                  "createDsClusterWizard.readyToCompletePage.automationLevelLabel"),
                  this.storageDrsLocalizationService
                        .getAutomationLevelTextLocalization(this.model.defaultVmBehavior));
            sectionBuilder.property(this.i18nService.getString("DsClusterUi",
                  "createDsClusterWizard.readyToCompletePage.spaceBalanceLabel"),
                  this.storageDrsLocalizationService
                        .getAutomationLevelTextLocalization(
                              automationOverrides.spaceLoadBalanceAutomationMode.id));
            sectionBuilder.property(this.i18nService.getString("DsClusterUi",
                  "createDsClusterWizard.readyToCompletePage.ioBalanceLabel"),
                  this.storageDrsLocalizationService
                        .getAutomationLevelTextLocalization(
                              automationOverrides.ioLoadBalanceAutomationMode.id));
            sectionBuilder.property(this.i18nService.getString("DsClusterUi",
                  "createDsClusterWizard.readyToCompletePage.ruleEnforcementLabel"),
                  this.storageDrsLocalizationService
                        .getAutomationLevelTextLocalization(
                              automationOverrides.ruleEnforcementAutomationMode.id));
            sectionBuilder.property(this.i18nService.getString("DsClusterUi",
                  "createDsClusterWizard.readyToCompletePage.policyEnforcementLabel"),
                  this.storageDrsLocalizationService
                        .getAutomationLevelTextLocalization(
                              automationOverrides.policyEnforcementAutomationMode.id));
            sectionBuilder.property(this.i18nService.getString("DsClusterUi",
                  "createDsClusterWizard.readyToCompletePage.vmEvacuationLabel"),
                  this.storageDrsLocalizationService
                        .getAutomationLevelTextLocalization(
                              automationOverrides.vmEvacuationAutomationMode.id));
         }

         this.automationOverrides = builder.build();
      }

      private buildRuntimeSettingsData() {
         let builder = this.vxPropertyViewService.createPropertyViewBuilder();
         let sectionBuilder = builder
               .category("")
               .section("")
               .title(this.i18nService.getString("DsClusterUi",
                     "createDsClusterWizard.runtimeSettingsPage.title"));


         let ioLoadBalanceLocalizedStatus: string = this.i18nService.getString("DsClusterUi",
               "createDsClusterWizard.readyToCompletePage."
               + (this.model.ioLoadBalanceEnabled ? "ioLoadBalancingEnabled" : "ioLoadBalancingDisabled"));

         sectionBuilder.property(this.i18nService.getString("DsClusterUi",
               "createDsClusterWizard.readyToCompletePage.ioLoadBalancingLabel"),
               ioLoadBalanceLocalizedStatus);

         let spaceThreshold: string = "";
         if (this.model !== undefined && this.model.spaceLoadBalanceConfig !== undefined
               && this.model.spaceLoadBalanceConfig.spaceThresholdMode ===
               this.dsClusterConstants.SDRS_SPACE_THRESHOLD_UTILIZATION) {
            spaceThreshold = this.i18nService.getString("DsClusterUi",
                  "sdrs.config.spaceThreshold.utlizedSpaceFormat",
                  this.model.spaceLoadBalanceConfig.spaceUtilizationThreshold);

         } else if (this.model !== undefined && this.model.spaceLoadBalanceConfig !== undefined
               && this.model.spaceLoadBalanceConfig.spaceThresholdMode ===
               this.dsClusterConstants.SDRS_SPACE_THRESHOLD_FREE_SPACE) {
            spaceThreshold = this.i18nService.getString("DsClusterUi",
                  "sdrs.config.spaceThreshold.minFreeSpaceFormat",
                  this.model.spaceLoadBalanceConfig.freeSpaceThresholdGB);
         }

         sectionBuilder.property(this.i18nService.getString("DsClusterUi",
               "createDsClusterWizard.runtimeSettingsPage.spaceThreshold.label"),
               spaceThreshold);

         sectionBuilder.property(this.i18nService.getString("DsClusterUi",
               "createDsClusterWizard.runtimeSettingsPage.ioLatencyThreshold.label"),
               this.i18nService.getString("DsClusterUi",
                "createDsClusterWizard.runtimeSettingsPage.ioLatencyThreshold.format",
               this.model.ioLatencyThreshold));

         this.runtimeSettings = builder.build();
      }


      private getDatastoresGridOptions(): Object {
         return {
            searchable: false,
            resizable: false,
            selectable: false,
            data: this.model.datastoreDetails,
            columnDefs: this.getDatastoresGridColumnDefs(),
            height: "153px", //height for 4 rows
            pageConfig: {
               hidePager: true
            }
         };
      }

      private getDatastoresGridColumnDefs(): Object[] {
         let self: ReadyToCompletePageController = this;
         return [{
            displayName: this.i18nService.getString("DsClusterUi",
                  "createDsClusterWizard.readyToCompletePage.datastoresNameHeaderLabel"),
            field: "name",
            type: "string",
            template: function (dataItem: DatastoreDetails) {
               let iconTextRenderer: any =
                     self.columnRenderersRegistry.getColumnRenderer("icon-text");
               return iconTextRenderer(["icon", "name"], dataItem);
            }
         }, {
            displayName: this.i18nService.getString("DsClusterUi",
                  "createDsClusterWizard.readyToCompletePage.datastoresCapacityHeaderLabel"),
            field: "capacity",
            type: "string",
            template: "#:capacity#"
         }, {
            displayName: this.i18nService.getString("DsClusterUi",
                  "createDsClusterWizard.readyToCompletePage.datastoresFreeSpaceHeaderLabel"),
            field: "freeSpace",
            type: "string",
            template: "#:freeSpace#"
         }, {
            displayName: this.i18nService.getString("DsClusterUi",
                  "createDsClusterWizard.readyToCompletePage.datastoresTypeHeaderLabel"),
            field: "type",
            type: "string",
            template: "#:type#"
         }];
      }

      private getClustersAndHostsGridOptions(): any {
         return {
            searchable: false,
            resizable: false,
            selectable: false,
            data: [],
            columnDefs: this.getClustersAndHostsGridColumnDefs(),
            height: "153px", //height for 4 rows
            pageConfig: {
               hidePager: true
            }
         };
      }

      private getClustersAndHostsGridColumnDefs(): Object[] {
         let self: ReadyToCompletePageController = this;
         return [{
            displayName: this.i18nService.getString("DsClusterUi",
                  "createDsClusterWizard.readyToCompletePage.clustersAndHostNameHeaderLabel"),
            field: "name",
            type: "string",
            template: function (dataItem: HostsAndClustersItem) {
               let iconTextRenderer: any =
                     self.columnRenderersRegistry.getColumnRenderer("icon-text");
               return iconTextRenderer(["icon", "name"], dataItem);
            }
         }, {
            displayName: this.i18nService.getString("DsClusterUi",
                  "createDsClusterWizard.readyToCompletePage.clustersAndHostDatastoreConnectionStatusHeaderLabel"),
            field: "isFullyConnected",
            type: "boolean",
            template: function (dataItem: HostsAndClustersItem) {
               let iconTextRenderer: any =
                     self.columnRenderersRegistry.getColumnRenderer("icon-text");
               if (dataItem.isFullyConnected) {
                  dataItem.isFullyConnectedIcon = "vx-icon-compliant";
                  dataItem.isFullyConnectedText = self.i18nService.getString("DsClusterUi",
                        "createDsClusterWizard.readyToCompletePage.clustersAndHosts.allDatastoresConnected");
               } else {
                  dataItem.isFullyConnectedIcon = "vx-icon-warn";
                  dataItem.isFullyConnectedText = self.i18nService.getString("DsClusterUi",
                        "createDsClusterWizard.readyToCompletePage.clustersAndHosts.datastoreConnectionMissing");
               }
               return iconTextRenderer(["isFullyConnectedIcon", "isFullyConnectedText"],
                     dataItem);
            }
         }, {
            displayName: this.i18nService.getString("DsClusterUi",
                  "createDsClusterWizard.readyToCompletePage.clustersAndHostSelectedHeaderLabel"),
            field: "isSelected",
            type: "boolean",
            template: function (dataItem: HostsAndClustersItem) {
               return dataItem.isSelected ? self.i18nService.getString("DsClusterUi",
                     "createDsClusterWizard.readyToCompletePage.clustersAndHosts.selected") :
                     self.i18nService.getString("DsClusterUi",
                     "createDsClusterWizard.readyToCompletePage.clustersAndHosts.notSelected");
            }
         }, {
            displayName: this.i18nService.getString("DsClusterUi",
                  "createDsClusterWizard.readyToCompletePage.clustersAndHostIOCapableHeaderLabel"),
            field: "isIOLoadBalanceCapable",
            type: "boolean",
            template: function (dataItem: HostsAndClustersItem) {
               let iconTextRenderer: any =
                     self.columnRenderersRegistry.getColumnRenderer("icon-text");
               if (dataItem.isIOLoadBalanceCapable) {
                  dataItem.isIOLoadBalanceCapableIcon = "vx-icon-compliant";
                  dataItem.isIOLoadBalanceCapableText = self.i18nService.getString("DsClusterUi",
                        "createDsClusterWizard.readyToCompletePage.clustersAndHosts.ioLoadBalanceCapable");
               } else {
                  dataItem.isIOLoadBalanceCapableIcon = "vx-icon-warn";
                  dataItem.isIOLoadBalanceCapableText = self.i18nService.getString("DsClusterUi",
                        "createDsClusterWizard.readyToCompletePage.clustersAndHosts.ioLoadBalanceNotCapable");
               }
               return iconTextRenderer(
                     ["isIOLoadBalanceCapableIcon", "isIOLoadBalanceCapableText"], dataItem);
            }
         }];
      }
   }

   angular.module("com.vmware.vsphere.client.dsCluster")
         .controller("ReadyToCompletePageController", ReadyToCompletePageController);
}
