/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
(function () {
   'use strict';
   angular.module('com.vmware.vsphere.client.storage')
         .controller('VmfsMountOptionsPageController', [
            '$scope',
            'i18nService',
            function ($scope, i18nService) {
               var self = this;

               self.getString = _.partial(i18nService.getString, 'StorageUi');

               self.mountOptions = $scope.vmfsWizardManager.mountOptions;

               var selectedDiskInfo = $scope.vmfsWizardManager.getSelectedScsiDiskInfo();

               self.isVmfsVolumeResolvable =
                     selectedDiskInfo &&
                     selectedDiskInfo.unresolvedExtent &&
                     selectedDiskInfo.unresolvedExtent.isHeadExtent === true &&
                     selectedDiskInfo.resolveStatus &&
                     selectedDiskInfo.resolveStatus.resolvable === true;

               self.mountOptionItems = [
                  {
                     label: self.getString('addDatastoreWizard.vmfsMountOptionsPage.newSignature'),
                     description: self.getString('addDatastoreWizard.vmfsMountOptionsPage.newSignatureDescription'),
                     value: self.mountOptions.ASSIGN_NEW_SIGNATURE,
                     isEnabled: self.isVmfsVolumeResolvable
                  },
                  {
                     label: self.getString('addDatastoreWizard.vmfsMountOptionsPage.keepSignature'),
                     description: self.getString('addDatastoreWizard.vmfsMountOptionsPage.keepSignatureDescription'),
                     value: self.mountOptions.KEEP_SIGNATURE,
                     isEnabled: self.isVmfsVolumeResolvable
                  },
                  {
                     label: self.getString('addDatastoreWizard.vmfsMountOptionsPage.formatDisk'),
                     description: self.getString('addDatastoreWizard.vmfsMountOptionsPage.formatDiskDescription'),
                     value: self.mountOptions.FORMAT_DISK,
                     isEnabled: true
                  }
               ];

               var signature =
                     $scope.vmfsWizardManager.getSelectedScsiDiskInfo().volumeSignature;
               self.signatureInfoText = self.getString(
                     'addDatastoreWizard.vmfsMountOptionsPage.signatureInfo', signature);

               self.selectedMountOption = null;
               if (!self.isVmfsVolumeResolvable) {
                  self.selectedMountOption = self.mountOptions.FORMAT_DISK;
               } else {
                  self.selectedMountOption = $scope.vmfsWizardManager.getSelectedMountOption()
                        || self.mountOptions.KEEP_SIGNATURE /*Default value*/;
               }
               $scope.$watch(
                     function () {
                        return self.selectedMountOption;
                     },
                     function (selectedMountOption) {
                        $scope.vmfsWizardManager.setSelectedMountOption(selectedMountOption);
                     });
            }
         ]);
})();
