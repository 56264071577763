namespace h5_vm {
   import VmToolsInstallerSpec = com.vmware.vsphere.client.vm.tools.VmToolsInstallerSpec;
   import VmToolsInstallerSpec$OperationType = com.vmware.vsphere.client.vm.tools.VmToolsInstallerSpec$OperationType;

   export class VmToolsService {
      private spec: VmToolsInstallerSpec;
      static $inject: string[] = ["mutationService", "i18nService", "clarityModalService"];

      constructor(private mutationService: any,
            private i18nService: any,
            private clarityModalService: any) {
      }

      public mount(vmIds: string[]) {
         this.spec = new VmToolsInstallerSpec();
         this.spec.operation = "mountInstaller" as VmToolsInstallerSpec$OperationType;
         this.mutationService.applyOnMultiEntity(vmIds, this.spec._type, this.spec);
         return true;
      }

      public unmount(vmIds: string[]) {
         this.spec = new VmToolsInstallerSpec();
         this.spec.operation = "unmountInstaller" as VmToolsInstallerSpec$OperationType;
         this.mutationService.applyOnMultiEntity(vmIds, this.spec._type, this.spec);
         return true;
      }

      public installUpgrade(actionEval: any, availableTargets: string[]): void {
         const dialogData = {
            title: this.i18nService.getString("VmUi", "VmToolsPopupForm.multiAction.title"),
            size: "lg",
            availableVmIds: availableTargets,
            totalVmsCount: actionEval.context.targetObjects.length,
            defaultButton: "close"
         };

         let dialogTemplateUrl =
               "vm-ui/resources/vm/views/tools/installUpgradeVmTools.html";
         this.clarityModalService.openModal(actionEval, availableTargets,
               dialogData, dialogTemplateUrl);
      }
   }

   angular.module("com.vmware.vsphere.client.vm")
         .service("vmToolsService", VmToolsService);
}
