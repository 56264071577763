(function() {
   'use strict';
   angular.module('com.vmware.vsphere.client.host').controller('SecurityProfileController', SecurityProfileController);

   SecurityProfileController.$inject = ['$scope', '$rootScope', 'dataService',
      'i18nService', 'imageProfileEvents'];

   function SecurityProfileController($scope, $rootScope, dataService,
         i18nService, imageProfileEvents) {

      var objectId = $scope._route.objectId;
      $scope.i18nService = i18nService;
      this.objectsToWatch = [objectId];
      this.liveRefreshProperties = [
         'config.lockdownMode',
         'config.service.service'
      ];

      this.headerOptions = {
         title: getString('securityProfile.lockdownMode.label'),
         actions: [{
            actionUid: 'vsphere.core.host.actions.editLockdownMode',
            customLabel: getString('settings.edit')
         }],
         objectId: objectId
      };

      this.imageAcceptanceLevelHeaderOptions = {
         title: getString('imageProfileAcceptance.label'),
         actions: [{
            actionUid: 'vsphere.core.host.actions.editImageProfile',
            customLabel: getString('imageProfile.action.label')
         }],
         objectId: objectId
      };

      this.encryptionMode = {
         title: getString('hostEncryption.label'),
         actions: [{
            actionUid: 'vsphere.core.host.actions.editEncryptionMode',
            customLabel: getString('settings.edit')
         }],
         objectId: this.objectsToWatch
      };

      this.setSecurityProfileProperties = function(data) {
         this.lockdownMode = getString(lockdownKeyify(data.lockdownMode));
         this.exceptionUsers = data.exceptionUsers;
         this.showExceptionUsers = data.isHostAccessManagerAvailable;
         this.isHostEncryptionCapable = !!data.cryptoState;
         this.hostEncryption =
               data.cryptoState && data.cryptoState === 'safe' ?
                     getString('hostEncryption.mode.enabled') : getString('hostEncryption.mode.disabled');
      };

      this.fetchViewData = function() {
         dataService.getProperties(objectId, ['securityProfileSummaryData']).then(function(data) {
            if (data && data.securityProfileSummaryData) {
               this.setSecurityProfileProperties(data.securityProfileSummaryData);
               this.getImageAcceptanceLevel(data.securityProfileSummaryData);
            }
         }.bind(this));
      };

      var updateImageAcceptanceLevelListener = $rootScope.$on(
            imageProfileEvents.UPDATE_HOST_IMAGE_PROFILE_ACCEPTANCE_LEVEL, function(event, data) {
               this.getImageAcceptanceLevel();
            }.bind(this));

      // $scope $destroy
      $scope.$on('$destroy', function() {
         updateImageAcceptanceLevelListener();
      });


      this.getImageAcceptanceLevel = function(data) {
         this.imageAcceptanceLevel = getString('acceptanceLevel.nameLoadingPlaceholder');
         if (!data || !data.imageAcceptanceLevel) {
            dataService.getProperties(objectId, [
               'imageAcceptanceLevel'
            ]).then(function(result) {
               if (result) {
                  this.imageAcceptanceLevel = getString(
                        getAcceptanceLevelLocalizationKey(result.imageAcceptanceLevel));
               }
            }.bind(this));
         } else {
            this.imageAcceptanceLevel = getString(
                  getAcceptanceLevelLocalizationKey(data.imageAcceptanceLevel));
         }
      };

      function getString(key) {
         return i18nService.getString('HostUi', key);
      }

      this.fetchViewData();

      function lockdownKeyify(mode) {
         return {
            'lockdownNormal': 'lockdownView.mode.normal.label',
            'lockdownStrict': 'lockdownView.mode.strict.label',
            'lockdownDisabled': 'lockdownView.mode.disabled.label'
         }[mode];
      }

      function getAcceptanceLevelLocalizationKey(mode) {
         return {
            'vmware_certified': 'acceptanceLevel.vmware_certified',
            'vmware_accepted': 'acceptanceLevel.vmware_accepted',
            'partner': 'acceptanceLevel.partner',
            'community': 'acceptanceLevel.community'
         }[mode];
      }
   }
})();
