namespace storage_ui {

   import IPromise = angular.IPromise;
   import IQService = angular.IQService;
   import IDeferred = angular.IDeferred;

   export class AdapterStatusService {
      public static $inject = [
         "i18nService",
         "mutationService",
         "clarityModalService",
         "clarityConstants",
         "$q"];

      constructor(private i18nService: any,
                  private mutationService: any,
                  private clarityModalService: any,
                  private clarityConstants: any,
                  private $q: IQService) {
      }

      public disable(options: any): void {

         let specData = this.getMutationData(false, options.isIscsiAdapter, options.deviceName);
         this.clarityModalService.openConfirmationModal({
            title: this.i18nService.getString("StorageUi", "storage.adapters.iscsi.popup.disable.header"),
            message: this.i18nService.getString("StorageUi", "storage.adapters.iscsi.disablePopup.text"),
            preserveNewlines: true,
            defaultButton: "submit",
            subTitle: {
               objectId: options.hostId
            },
            submit: () => {
               this.mutationService.apply(options.hostId, specData.type, specData.spec).then((response: any) => {
                  options.onSuccessCallback(response);
               });
               return true;
            }
         });

      }

      public enable(options: any): void {
         let specData = this.getMutationData(true, options.isIscsiAdapter, options.deviceName);
         return this.mutationService.apply(options.hostId, specData.type, specData.spec);
      }

      private getMutationData(activate: boolean, isIscsiAdapter: boolean, deviceName: string): any {
         return isIscsiAdapter
               ? {
            type: "com.vmware.vsphere.client.storage.adapters.InternetScsiStorageAdapterActivationSpec",
            spec: {activate: activate}
         }
               : {
            type: "com.vmware.vsphere.client.storage.adapters.FcoeStorageAdapterRemovalSpec",
            spec: {mark: !activate, hbaName: deviceName}
         };
      }
   }

   angular.module("com.vmware.vsphere.client.storage")
         .service("adapterStatusService", AdapterStatusService);
}
