module ds_cluster_ui {

   import WizardPageControllerAbstract =
         ds_cluster_ui.WizardPageControllerAbstract;
   import IPromise = angular.IPromise;

   class DsAutomationPageController extends WizardPageControllerAbstract {

      public static $inject = ["createDsClusterWizardManager", "vuiConstants",
         "simpleValidationCommit", "createDsClusterPageConstants", "$q"];

      private model: DsClusterModel;

      constructor(createDsClusterWizardManager: CreateDsClusterWizardManager,
            private vuiConstants: any, simpleValidationCommit: any,
            private createDsClusterPageConstants: any, private $q: any) {
         super(createDsClusterWizardManager, simpleValidationCommit);

         this.initPageSubmit();
         this.initModel();
         this.setWizardPageState(this.vuiConstants.wizard.pageState.COMPLETED);
      }

      protected getValidationErrors(): IPromise<string[]> {
         return this.$q.reject();
      }

      protected getCurrentPageIndex(): number {
         return this.createDsClusterPageConstants.AUTOMATION_INDEX;
      }

      private initModel(): void {
         this.model = this.createDsClusterWizardManager.getModel();
      }
   }

   angular.module("com.vmware.vsphere.client.dsCluster")
         .controller("DsAutomationPageController", DsAutomationPageController);
}
