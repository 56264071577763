/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace h5_vm {

   export class SelectPriorityPageModel {
      /**
       * [input]
       *
       * The initial priority that the page should choose when first loaded.
       */
      public initialPriority?: string;

      /**
       * [output]
       *
       * Flag indicating migration priority type.
       */
      public priority: string;

   }
}
