namespace dvs_ui {

   export class DvpgFailoverPolicyPageComponent {

      public bindings: any;
      public controller: any;
      public templateUrl: string;

      constructor() {
         this.bindings = {
            model: "<",
            failoverOrderOptions: "<",
            overrideOptions: "<?",
            overrideEnabled: "<?",
            overrideDisallowed: "<?"
         };

         this.controller = DvpgFailoverPolicyPageController;

         this.templateUrl = "dvs-ui/resources/dvs/dvpg/common/components/" +
               "dvpgFailoverPolicyPageTemplate.html";
      }
   }

   class DvpgFailoverPolicyPageController {

      static $inject = [
         "i18nService",
         "$scope",
         "dvpgFailoverPolicyPageService",
         "networkUiConstants"
      ];

      public model: DvpgFailoverPolicyModel;
      public failoverOrderOptions: any;

      public signPostConfig: any;
      public loadBalancingOptions: any[];
      public beaconProbingOptions: any[];
      public notifySwitchesOptions: any[];
      public rollingOrderOptions: any[];
      public failoverOrderHeaderOptions: any;
      public failoverOrderActions: string[];
      public hasLoadBalancingWarningMessage: Function;
      public i18n: Function;
      public onFailoverOrderChange: Function;
      public overrideOptions: any;
      public overrideEnabled: boolean;
      public overrideDisallowed: boolean;

      public signPostIconShape: string;

      constructor(private i18nService: any,
                  private $scope: any,
                  private dvpgFailoverPolicyPageService: any,
                  private networkUiConstants: any) {

         this.i18n = this.i18nService.getString;
         this.loadBalancingOptions = [];
      }

      $onInit(): void {
         this.onFailoverOrderChange = this.updateFailoverOrder.bind(this);

         if (this.model.loadBalancingModes && this.model.loadBalancingModes.length > 0) {

            this.model.loadBalancingModes.forEach((mode: string) => {
               let option: any = {
                  label: this.dvpgFailoverPolicyPageService.getNicTeamingModeLabel(mode),
                  val: mode
               };
               this.loadBalancingOptions.push(option);
            });
         }

         this.failoverOrderHeaderOptions = {
            title: this.i18n("NetworkUi", "dvpg.policy.failover.page.order.label")
         };

         this.hasLoadBalancingWarningMessage = (): boolean => {
            return this.dvpgFailoverPolicyPageService.hasLoadBalancingWarningMessage(
                  this.failoverOrderOptions.connectees.active,
                  this.failoverOrderOptions.connectees.standby,
                  this.model.loadBalancing,
                  this.model.beaconProbing,
                  false/*showNoActiveUplinksWarning*/,
                  this.overrideOptions
            );
         };

         this.beaconProbingOptions = [{
            label: this.i18n("NetworkUi", "dvpg.policy.failover.detection.beaconProbing"),
            val: true
         }, {
            label: this.i18n("NetworkUi", "dvpg.policy.failover.detection.linkStatus"),
            val: false
         }];

         this.notifySwitchesOptions = [{
            label: this.i18n("NetworkUi", "dvpg.policy.failover.notify.off"),
            val: false
         }, {
            label: this.i18n("NetworkUi", "dvpg.policy.failover.notify.on"),
            val: true
         }];

         this.rollingOrderOptions = [{
            label: this.i18n("NetworkUi", "dvpg.policy.failover.failback.off"),
            val: true
         }, {
            label: this.i18n("NetworkUi", "dvpg.policy.failover.failback.on"),
            val: false
         }];

         this.failoverOrderActions = [
            this.networkUiConstants.failoverOrderActions.MOVE_UP,
            this.networkUiConstants.failoverOrderActions.MOVE_DOWN
         ];

         this.signPostConfig = {
            message: null,
            title: this.i18n(
                  "NetworkUi", "dvpg.policy.failover.lb.loadbalance_ip.popup.title"),
            class: "edit-dv-pg-signpost"
         };
      }

      private updateFailoverOrder(): void {
         let isFailoverOrderChanged: boolean =
               this.dvpgFailoverPolicyPageService.hasFailoverOrderChanged(
                     this.failoverOrderOptions,
                     this.model.failoverOrder.activeUplinkPortNames,
                     this.model.failoverOrder.standbyUplinkPortNames
               );

         if (isFailoverOrderChanged) {
            this.model.failoverOrder.activeUplinkPortNames =
                  this.failoverOrderOptions.connectees.active.slice();
            this.model.failoverOrder.standbyUplinkPortNames =
                  this.failoverOrderOptions.connectees.standby.slice();
            this.model.failoverOrder.unusedUplinkPortNames =
                  this.failoverOrderOptions.connectees.unused.slice();
         }

         this.getLoadBalancingMessages();
      }

      private getLoadBalancingMessages(): void {
         this.signPostConfig.message = null;
         let loadBalancingMessages: string[] =
               this.dvpgFailoverPolicyPageService.getLoadBalancingMessages(
                     this.failoverOrderOptions.connectees,
                     this.model.loadBalancing,
                     this.model.beaconProbing,
                     this.overrideOptions);

         this.signPostConfig.message = loadBalancingMessages ?
               loadBalancingMessages.join(" ") : null;

         this.signPostIconShape = this.hasLoadBalancingWarningMessage() ?
               'exclamation-triangle' : 'info-circle';
      }

   }

   angular.module("com.vmware.vsphere.client.dvs")
         .component("dvpgFailoverPolicyPage", new DvpgFailoverPolicyPageComponent());
}
