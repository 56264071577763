import IPromise = angular.IPromise;

namespace storage_ui {
   import ManagedObjectReference = com.vmware.vim.binding.vmodl.ManagedObjectReference;
   export class RescanStorageDialogService {

      public static $inject = ["i18nService", "defaultUriSchemeUtil",
            "storageUtil", "managedEntityConstants", "clarityConstants",
            "hostStorageService", "clarityModalService"];

      constructor(private i18nService: any, private defaultUriSchemeUtil: any,
            private storageUtil: any, private managedEntityConstants: any,
            private clarityConstants: any, private hostStorageService: any,
            private clarityModalService: any) {

      }

      open(targetObjects: ManagedObjectReference[]): void {
         const modalOptions: any = {
            contentTemplate: 'storage-ui/resources/storage/views/host/adapters/StorageAdaptersRescanDialog.html',
            title: this.i18nService.getString('StorageUi', 'storage.adapters.rescanForm.titleH5'),
            defaultButton: 'submit',
            alerts: [],
            dialogData: {
               i18n: this.i18nService.getString,
               spec: {
                  rescanHbas: true,
                  rescanVmfs: true
               }
            }
         };

         if (targetObjects.length === 1) {
            modalOptions.subTitle = {
               objectId: this.defaultUriSchemeUtil.getVsphereObjectId(targetObjects[0])
            };
         }

         // Warn the user that the action will be invoked on multiple hosts or
         // cluster/dc etc.
         if (targetObjects[0].type !== this.managedEntityConstants.HOST
               || targetObjects.length > 1) {
            const warning: string | undefined =
                  this.storageUtil.getRescanWarning(targetObjects[0].type);
            if (warning) {
               this.addModalWarning(modalOptions, warning);
            }
         }

         modalOptions.onSubmit = this.onSubmit.bind(this, modalOptions, targetObjects);

         this.clarityModalService.openOkCancelModal(modalOptions);
      }

      private addModalWarning(modalOptions: any, text: string): void {
         modalOptions._warnings = modalOptions._warnings || [];
         modalOptions._warnings.push({
            type: this.clarityConstants.notifications.type.WARNING,
            text: text
         });
         modalOptions.alerts = modalOptions._warnings;
      }

      private onSubmit(
            modalOptions: any,
            targetObjects: any[]): boolean | IPromise<boolean> {

         // Check if any of the rescan options are selected
         const rescanSpec: any = modalOptions.dialogData.spec;
         if (!rescanSpec.rescanHbas && !rescanSpec.rescanVmfs) {
            this.setModalError(modalOptions,
                  this.i18nService.getString('StorageUi', 'storage.adapters.rescanForm.noTagretSelected'));

            // Do not close the dialog.
            return false;
         }

         // Try to trigger rescan
         modalOptions.dialogData.loading = true;
         let rescanPromise: IPromise<any> = this.hostStorageService.rescanStorageAdapters(
               targetObjects,
               modalOptions.dialogData.spec);

         // Check if we triggered rescan for any host
         rescanPromise = rescanPromise.then((rescanResult) => {
            if (!rescanResult.affectedHosts || rescanResult.affectedHosts.length === 0) {
               this.setModalError(modalOptions,
                     this.i18nService.getString('StorageUi', 'storage.adapters.rescanForm.noAvailableHosts', rescanResult.targetName));

               // Do not close the dialog.
               return false;
            }
            return true;
         }).finally(() => {
            modalOptions.dialogData.loading = false;
         });

         return rescanPromise;
      }

      private setModalError(modalOptions: any, text: string): void {
         const alerts: any[] = angular.copy(modalOptions._warnings || []);
         alerts.splice(0, 0, {
            type: this.clarityConstants.notifications.type.ERROR,
            text: text
         });
         modalOptions.alerts = alerts;
      }
   }

   angular.module("com.vmware.vsphere.client.storage")
         .service("rescanStorageDialogService", RescanStorageDialogService);
}