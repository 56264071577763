/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
(function () {
   'use strict';
   angular.module('com.vmware.vsphere.client.host').service(
      'addHostResourceLocationPageService',
      addHostResourceLocationPageService
   );

   addHostResourceLocationPageService.$inject = ['i18nService', 'addHostWizardConstants',
         'mutationService', 'logService', 'defaultUriSchemeUtil'];

   function addHostResourceLocationPageService(i18nService, addHostWizardConstants,
         mutationService, logService, defaultUriSchemeUtil) {
      var log = logService('addHostResourceLocationPageService');
      return {
         build: function (wizardManager, vuiWizardHelper) {
            return {
               title: i18nService.getString('HostUi', 'addHostWizard.drsClusterRpPage.title'),
               description: i18nService.getString('HostUi', 'addHostWizard.drsClusterRpPage.description'),
               contentUrl: 'host-ui/resources/host/views/addHostWizard/pages/addHostResourceLocationPage.html',
               onCommit: function () {
                  var model = wizardManager.getResourcePoolInput();
                  return validateResourcePoolInput(model);
               }
            };

            function validateResourcePoolInput(model) {
               if (model.type === addHostWizardConstants.RESOURCE_LOCATION_ROOT) {
                  vuiWizardHelper.markPageComplete();
                  return true;
               }

               if (!model.name ||
                     model.name.length === 0) {
                  var errorMessage = i18nService.getString('HostUi',
                        'hostToClusterOp.resPoolPage.rpNameReqError');
                  vuiWizardHelper.markPageIncompleteWithError(errorMessage);
                  return false;
               }
               var clusterUid = defaultUriSchemeUtil.getVsphereObjectId(
                     model.destination);
               return validateResourcePoolName(clusterUid,
                     model.name);
            }

            function validateResourcePoolName(clusterUid, name) {
               vuiWizardHelper.showLoadingIndicator();
               return mutationService.validate(
                     clusterUid,
                     'com.vmware.vsphere.client.h5.rp.CreateResourcePoolNameSpec',
                     buildCreateResourcePoolNameSpec(name)
               ).then(validationResultHandler);
            }

            function validationResultHandler(validationResult) {
               vuiWizardHelper.hideLoadingIndicator();
               if (!validationResult) {
                  log.error("Server error: Can't validate resource location name.");
               }

               if (validationResult && validationResult.error) {
                  if (validationResult.error.message) {
                     log.error(validationResult.error.message);
                  } else {
                     log.error("Server error: The validation result has an error");
                  }
               }

               if (validationResult && validationResult.result &&
                  validationResult.result.length > 0) {
                  vuiWizardHelper.markPageIncompleteWithError(
                     validationResult.result);
                  return false;
               }

               vuiWizardHelper.markPageComplete();
               return true;
            }

            function buildCreateResourcePoolNameSpec(resourceLocationName) {
               var spec = {
                  name: resourceLocationName
               };
               return spec;
            }
         }
      };
   }
})();
