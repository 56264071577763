namespace spbm_ui {

    export class SpbmResetDefaultVsanProfileActionService {
        static $inject = ['$injector'];

        private _vcSpbmResetDefaultVsanProfileActionService: SpbmResetDefaultVsanProfileActionService;

        constructor(private $injector: any) {
        }

        get vcSpbmResetDefaultVsanProfileActionService() {
            if (!this._vcSpbmResetDefaultVsanProfileActionService) {
                this._vcSpbmResetDefaultVsanProfileActionService = this.$injector.get('vcSpbmResetDefaultVsanProfileActionService');
            }
            return this._vcSpbmResetDefaultVsanProfileActionService;
        }

        resetDefaultVsanProfile(storagePolicy: string): void {
            return this.vcSpbmResetDefaultVsanProfileActionService.resetDefaultVsanProfile(storagePolicy);
        }
    }

    angular.module("com.vmware.vsphere.client.spbm")
        .service("spbmResetDefaultVsanProfileActionService", SpbmResetDefaultVsanProfileActionService);
}