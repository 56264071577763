namespace h5_client {
   export module common_module_ui {
      /*
       * Content library service proxy class. Switches to different implementation based on the feature switch
       * actual implementations are in libraryMutationService and libraryVapiService
       */
      export class LibraryService {
         static $inject = ['libraryMutationService', 'libraryVapiService', 'featureFlagsService', 'defaultUriSchemeUtil', 'dataService'];

         private service: any;

         private TARGET_LIBRARIES_PROP: string = "targetLibraries";
         private FOLDER: string = "Folder";
         private GROUPD1: string = "group-d1";

         constructor(private libraryMutationService: any,
                     private libraryVapiService: any,
                     private featureFlagsService: any,
                     private defaultUriSchemeUtil: any,
                     private dataService: any) {
         }

         private usingVapiService(): boolean {
            // snanda: TODO: removing featureswitch - vAPI enabled on vmkernel-main by default
            // h5c-dev is still using old mutation based workflow
            // return this.featureFlagsService.h5uiCLSoverVAPIEnabled();
            return false;
         }

         private init() {
            this.service = this.usingVapiService() ? this.libraryVapiService : this.libraryMutationService;
         }
         /**
          * Confirm and delete a Library.
          * Defaulting delete action through MutationService. Avoiding REST VAPI call.
          */
         public deleteContentLibrary(libraryIds: Array<string>) {
            this.init();
            this.service.deleteContentLibrary(libraryIds);
         }

         /**
          * Add a new ContentLibrary
          * @param libraryData : contains full model of the content library to add
          * @returns {any}
          */
         public addContentLibrary(libraryData: any) {
            this.init();
            return this.service.addContentLibrary(libraryData);
         }

         /**
          * validate the library against
          * @param libraryUrl
          * @returns {any}
          */
         public validateSubscription(libraryUrl: any, sslThumbprint?: string, password?: string) {
            this.init();
            return this.service.validateSubscription(libraryUrl, sslThumbprint, password);
         }

         /**
          * Get all library in vc
          * @param vc
          * @returns {any}
          */
         public getAllContentLibraryInVc(vc: any) {
            let serverGuid = vc.serviceGuid;
            let rootFolder = this.defaultUriSchemeUtil.createVmomiUri(this.FOLDER, this.GROUPD1, serverGuid);
            return this.dataService.getProperties(rootFolder, [this.TARGET_LIBRARIES_PROP]);
         }

      }

      angular.module('com.vmware.vsphere.client.commonModule').service('libraryService', LibraryService);
   }
}
