(function () {
    'use strict';
    angular.module('com.vmware.vsphere.client.commonModule')
        .controller('RemoveTagDialogController', RemoveTagDialogController);

    RemoveTagDialogController.$inject = ['$scope', 'RemoveTagGridFactory', 'RemoveMultiTagsGridFactory', 'i18nService'];

    function RemoveTagDialogController($scope, RemoveTagGridFactory, RemoveMultiTagsGridFactory, i18nService) {
        var self = this,
            targets = $scope.modalOptions.availableTargets;

        if (targets.length > 1) {
            self.bulkRemoval = true;
            self.tagGrid = RemoveMultiTagsGridFactory.make(targets);
            $scope.modalOptions.title = i18nService
                .getString("Common", "tag.remove.form.batch.title", targets.length + '');
            $scope.modalOptions.secondaryTitle = "";
        } else {
            self.tagGrid = RemoveTagGridFactory.make(targets[0]);
        }

        self.tagGrid.populateDataGrid();

        self.onTagSelectionChanged = function (selectedTag) {
            self.removeDisabled = !selectedTag;
        };

        self.tagGrid.setTagSelectionListener(self.onTagSelectionChanged);

        self.removeTag = function () {
            self.tagGrid.removeTag();
            self.closeModal();
        };

        self.closeModal = function () {
            $scope.closeModal();
        };
    }
}());
