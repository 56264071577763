namespace h5_vm {
   import Privileges = h5_vm.Privileges;
   export class VmHardwareVirtualDiskFlashReadCacheService {

      static $inject: string[] = [
         "virtualDiskSettingsFormService",
         "vmHardwareUtil",
          "i18nService"
      ];

      constructor(
            private virtualDiskSettingsFormService: any,
            private vmHardwareUtil: any,
            private i18nService: any
         ) {
      }

      getFlashCacheReservationInfo(device: any, vFlashSupportedOnHost: boolean) {
         return {
            vFlashSupportedOnHost,
            valueInMB: device.vFlashCacheConfigInfo ? device.vFlashCacheConfigInfo.reservationInMB : 0,
            validUnits: [
               { label: this.i18nService.getString('VmUi', "Vm.Units.MB"), multiplier: 1 }
            ]
         };
      }

      hasPrivilegesToEditFlashReadCache(privileges: any, skipPrivilegeCheck: boolean = false): boolean {
         if(skipPrivilegeCheck) {
            return true;
         }

         return this.vmHardwareUtil.checkPrivileges(privileges, [Privileges.VM_EDITDEVICE_PRIVILEGE]);
      }

      isEditFlashReadCacheDisabled(privileges: any, createMode: boolean) {
         return !this.hasPrivilegesToEditFlashReadCache(privileges, createMode);
      }
   }

   angular.module("com.vmware.vsphere.client.vm")
         .service("vmHardwareVirtualDiskFlashReadCacheService", VmHardwareVirtualDiskFlashReadCacheService);
}
