/* Copyright 2016-2017 VMware, Inc. All rights reserved. -- VMware Confidential */
/*
 * Tag service
 */
(function () {
   'use strict';
   angular
   .module('com.vmware.vsphere.client.commonModule')
   .service('tagService', tagService);

   tagService.$inject = ['dataService', 'mutationService', 'defaultUriSchemeUtil', '$http'];

   function tagService(dataService, mutationService, defaultUriSchemeUtil, $http) {

      var MUTATION_TAG_DETACH_SPEC = 'com.vmware.vsphere.client.tagging.TagDetachSpec';
      var MUTATION_TAG_ATTACH_SPEC = 'com.vmware.vsphere.client.tagging.TagAttachSpec';
      var MUTATION_TAG_CREATE_SPEC = 'com.vmware.vsphere.client.tagging.TagCreateSpec';
      var TAGS_URL = 'tagging-ui/ctrl/tags';

      return {
         getTagInfoForObject: getTagInfoForObject,
         getAssignableTagsForObject: getAssignableTagsForObject,
         detachTag: detachTag,
         attachTag: attachTag,
         getAllTags: getAllTags,
         createTag: createTag,
         deleteTag: deleteTag,
         getEntityObjectsCount: getEntityObjectsCount,
         editTag: editTag
      };

      function formatTags(tagProperties) {
         var formattedTagsDictionary = {};

         _.each(tagProperties, function(value, object) {
            var objRef = defaultUriSchemeUtil.getPartsFromVsphereObjectId(object);
            formattedTagsDictionary[object] = angular.extend({resourceObject: objRef}, value);
         });
         return _.values(formattedTagsDictionary);
      }

      function getAllTags() {
         return $http.get(TAGS_URL).then(function(response) {
            return response.data;
         });
      }

      function getTagInfoForObject(objectId) {
         var relation = 'entity';
         var targetType = 'inventoryservice:InventoryServiceTag';
         var tagProperties = ['name', 'description', 'categoryName'];

         return dataService.getPropertiesByRelation(objectId, relation,
               targetType, tagProperties, { queryName: "getTagInfoForObject:" + objectId })
               .then(formatTags);
      }

      function getAssignableTagsForObject(objectId) {
         return dataService.getProperties(objectId, ['assignableTagsForTarget'],
               { queryName: "getAssignableTagsForObject:" + objectId });
      }

      function detachTag(tagObject, objectId) {
         // objectId can be a MoRef or a URI like Library
         var tagDetachSpec = {
            resources: [defaultUriSchemeUtil.getMoRefOrURIref(objectId)]
         };
        return mutationService.apply(defaultUriSchemeUtil.getVsphereObjectId(tagObject), MUTATION_TAG_DETACH_SPEC, tagDetachSpec);
      }

      function attachTag(tagObject, objectId) {
         // objectId can be a MoRef or a URI like Library
         var tagAttachSpec = {
            resources: [defaultUriSchemeUtil.getMoRefOrURIref(objectId)],
            ignoreAlreadyAttached: true
         };
         return mutationService.apply(defaultUriSchemeUtil.getVsphereObjectId(tagObject.resourceObject), MUTATION_TAG_ATTACH_SPEC, tagAttachSpec);

      }

      function createTag(tagName, tagDescription, categoryMoRef) {
         var tagSpec = {
            tagName: tagName,
            tagDescription: tagDescription,
            categoryMoRef: categoryMoRef
         };
         return mutationService.add(MUTATION_TAG_CREATE_SPEC, tagSpec);
      }

      function editTag(tagId, tagName, tagDescription) {
         var tagSpec = {
            tagName: tagName,
            tagDescription: tagDescription
         };
         return mutationService.apply(tagId, MUTATION_TAG_CREATE_SPEC, tagSpec);
      }

      function deleteTag(tagMoRef) {
         var tagId = defaultUriSchemeUtil.getVsphereObjectId(tagMoRef);
         return mutationService.remove(tagId, MUTATION_TAG_CREATE_SPEC, {});
      }

      function getEntityObjectsCount(tagMoRef) {
         var entityId = defaultUriSchemeUtil.createVmomiUri(tagMoRef.type, tagMoRef.value, tagMoRef.serverGuid);
         return dataService.getProperties(entityId, ["entityObjects"], { queryName: "getEntityObjectsCount:" + entityId })
            .then(function(response) {
            return response.entityObjects.totalMatchedObjectCount;
         });
      }
   }
}());
