angular.module('com.vmware.vsphere.client.host').service('hostnameParser', [
   'ipParserService',
   function(ipParserService) {
      var DIGIT_REG_EX = new RegExp("^\\d+$");
      var DEFAULT_PORT = -1;

      return {
         parse: function(rawHostname) {
            if (!rawHostname) {
               return undefined;
            }

            var parsedAsIpAddress = parseAsIp(rawHostname);
            if (parsedAsIpAddress) {
               return parsedAsIpAddress;
            }

            var parsedAsHostname = parseAsHostname(rawHostname);
            if (parsedAsHostname && !containsInvalidChars(parsedAsHostname.hostname)) {
               return parsedAsHostname;
            }

            return undefined;
         }
      };

      function parseAsIp(hostname) {
         var parsedIpAddress = ipParserService.parse(hostname);
         if (parsedIpAddress) {
            return {
               hostname: parsedIpAddress.address,
               port: parsedIpAddress.port || DEFAULT_PORT
            };
         } else {
            return null;
         }
      }

      function parseAsHostname(hostname) {
         var indexOfPort = hostname.lastIndexOf(":");
         if (indexOfPort < 0) {
            return {
               hostname: hostname,
               port: DEFAULT_PORT
            };
         }

         var port = hostname.substring(indexOfPort + 1, hostname.length);
         if (DIGIT_REG_EX.test(port)) {
            return {
               hostname: hostname.substring(0, indexOfPort),
               port: parseInt(port)
            };
         }

         return undefined;
      }

      function containsInvalidChars(hostname) {
         var invalidCharacters = ['!', '@', '#', '%', '$', '^', '&', '*', ':'];

         for (var i = 0; i < invalidCharacters.length; i++) {
            if (hostname.indexOf(invalidCharacters[i]) > DEFAULT_PORT) {
               return true;
            }
         }

         return false;
      }

   }]
);