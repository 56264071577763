namespace h5_vm {

   import CpuMmuOptionFactory = h5_vm.CpuMmuOptionFactory;
   import CpuMmuOption = h5_vm.CpuMmuOption;
   import FlagInfo$VirtualExecUsage =
      com.vmware.vim.binding.vim.vm.FlagInfo$VirtualExecUsage;
   import FlagInfo$VirtualMmuUsage =
      com.vmware.vim.binding.vim.vm.FlagInfo$VirtualMmuUsage;
   import CpuMmuConstants = h5_vm.CpuMmuConstants;

   export class CpuMmuOptionService {

      static $inject: string[] = [
         "creationTypeConstants",
         "cpuMmuOptionFactory",
         "vmHardwareUtil"
      ];

      constructor(private creationTypeConstants: any, private cpuMmuOptionFactory:any, private vmHardwareUtil:any) {
      }

      public getCpuMmuConfigOptionForCpuMmu(cpuUsage: FlagInfo$VirtualExecUsage | null,
                                            mmuUsage: FlagInfo$VirtualMmuUsage): CpuMmuOption {

         let result: CpuMmuOption = new CpuMmuOption("","");
         if (!_.isEmpty(cpuUsage)) {

            if (_.isEqual(cpuUsage, "hvAuto") && _.isEqual(mmuUsage, "automatic")) {

               result = this.cpuMmuOptionFactory.automatic;

            } else if (_.isEqual(cpuUsage, "hvOff") && _.isEqual(mmuUsage, "off")) {

               result = this.cpuMmuOptionFactory.software;

            } else if (_.isEqual(cpuUsage, "hvOn") && _.isEqual(mmuUsage, "on")) {

               result = this.cpuMmuOptionFactory.hardwareCpuMmu;

            } else if (_.isEqual(cpuUsage, "hvOn") && _.isEqual(mmuUsage, "off")) {

               result = this.cpuMmuOptionFactory.hardwareCpu;

            }
         } else {

            if (_.isEqual(mmuUsage, "automatic")) {

               result = this.cpuMmuOptionFactory.automatic;

            } else if (_.isEqual(mmuUsage, "on")) {

               result = this.cpuMmuOptionFactory.hardwareCpuMmu;

            } else if (_.isEqual(mmuUsage, "off")) {

               result = this.cpuMmuOptionFactory.software;

            }
         }
         return result;
      }

      public getCpuMmuCurrentConfig(vmConfigContext: any): any {
         let currentConfig: any = {};

         if (!vmConfigContext.environment.configOption.capabilities.virtualMmuUsageSupported
            || !vmConfigContext.config.flags) {
            return;
         }

         currentConfig.privileges = !_.isEmpty(vmConfigContext.privileges) ?
            vmConfigContext.privileges : null;
         currentConfig.provisioningType = vmConfigContext.provisioningType;
         currentConfig.fullFeature =
            vmConfigContext.environment.hostCapability.virtualExecUsageSupported;
         currentConfig.virtualCpuMmuUsageSupported =
            vmConfigContext.environment.configOption.capabilities.virtualMmuUsageSupported;

         let flags = vmConfigContext.config.flags;
         if (!flags) {
            currentConfig.virtualCpuMmuUsageSupported = false;
            return currentConfig;
         }

         if (
            _.isEqual(currentConfig.provisioningType,
               this.creationTypeConstants.createVmFromScratch) &&
            _.isEmpty(flags.virtualMmuUsage)
         ) {
            flags.virtualMmuUsage = CpuMmuConstants.AUTOMATIC;
         }

         if (
            currentConfig.fullFeature &&
            _.isEqual(currentConfig.provisioningType,
               this.creationTypeConstants.createVmFromScratch) &&
            _.isEmpty(flags.virtualExecUsage)
         ) {
            flags.virtualExecUsage = CpuMmuConstants.HV_AUTO;
         }

         currentConfig.virtualMmuUsage = flags.virtualMmuUsage;
         currentConfig.virtualExecUsage = flags.virtualExecUsage;

         return currentConfig;
      }

      public isCpuMmuDisabled(vmConfigContext:any, createMode:any): boolean {
         if (createMode) {
            return false;
         }
         var retVal = !this.vmHardwareUtil.haveVmConfigSettingsPermission(vmConfigContext);
         return retVal;
      }

      public checkPrivilege(privilege: string, details: any): boolean {

         if (_.isEqual(details.provisioningType,
               this.creationTypeConstants.createVmFromScratch)) {
            return true;
         }
         return (details.privileges.indexOf(privilege) > -1);

      }

      public getCpuMmuOptions(): Array<CpuMmuOption> {

         return [
            this.cpuMmuOptionFactory.automatic,
            this.cpuMmuOptionFactory.software,
            this.cpuMmuOptionFactory.hardwareCpu,
            this.cpuMmuOptionFactory.hardwareCpuMmu,
         ];

      }

      public getCpuMmuCurrentSelection(cpuMmuCurrentConfig: any) {
         if(!cpuMmuCurrentConfig || !cpuMmuCurrentConfig.virtualCpuMmuUsageSupported) {
            return this.cpuMmuOptionFactory.automatic;
         }
         if(cpuMmuCurrentConfig.fullFeature) {
            return this.getCpuMmuConfigOptionForCpuMmu(
               cpuMmuCurrentConfig.virtualExecUsage,
               cpuMmuCurrentConfig.virtualMmuUsage);
         }
         return this.getCpuMmuConfigOptionForCpuMmu(
            null,
            cpuMmuCurrentConfig.virtualMmuUsage);
      }

   }

   angular.module("com.vmware.vsphere.client.vm").service("cpuMmuOptionService",
      CpuMmuOptionService);

}
