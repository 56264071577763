namespace h5_vm {
    import OperationResult = com.vmware.vise.core.model.OperationResult;
    import VmEnableSecondarySpec = com.vmware.vsphere.client.vm.ft.VmEnableSecondarySpec;
    import VmDisableSecondarySpec = com.vmware.vsphere.client.vm.ft.VmDisableSecondarySpec;
    import VmRestartSecondarySpec = com.vmware.vsphere.client.vm.ft.VmRestartSecondarySpec;
    import VmTestFailoverSpec = com.vmware.vsphere.client.vm.ft.VmTestFailoverSpec;

    export class FtVmService {
        static $inject: string[] = [
            "$rootScope",
            "$injector",
            "dataService",
            "clarityModalService",
            "i18nService",
            "ftVmWizardService",
            "FtVmTurnOnManager",
            "defaultUriSchemeUtil",
            "vuiConstants",
            "columnRenderersRegistry",
            "mutationService",
            "vmFtConstants",
            "migrateVmWizardService",
            "taskConstants",
            "powerOpsService"];

        private ERROR_ICON: string = "vx-icon-error";
        private WARNING_ICON: string = "vx-icon-warn";
        private HOST_ICON: string = "vsphere-icon-host";
        private VM_ICON: string = "vsphere-icon-vm";
        private HOST_SYSTEM_TYPE: string = "HostSystem";
        private VM_TYPE: string = "VirtualMachine";
        private VIRTUAL_DEVICE_TYPE: string =
            "com.vmware.vim.binding.vim.vm.device.VirtualDisk";
        private recentTasksStoreService: any;

        constructor(private $rootScope: any,
                    private $injector: any,
                    private dataService: any,
                    private clarityModalService: any,
                    private i18nService: any,
                    private ftVmWizardService: FtVmWizardService,
                    private FtVmTurnOnManager: new () => FtVmTurnOnManager,
                    private defaultUriSchemeUtil: any,
                    private vuiConstants: any,
                    private columnRenderersRegistry: any,
                    private mutationService: any,
                    private vmFtConstants: any,
                    private migrateVmWizardService: any,
                    private taskConstants: any,
                    private powerOpsService: any) {
        }

        public getAvailableDevices(devices: any): any {
            return _.filter(devices, (device: any) => {
                return device._type === this.VIRTUAL_DEVICE_TYPE;
            });
        }

        public openVmFaultToleranceWizard(objectId: string): void {
            let manager: FtVmTurnOnManager = new this.FtVmTurnOnManager();
            this.validateFaultToleranceTurnOn(objectId).then((response: any) => {
                if (!response) {
                    return;
                }
                let ftCompability: any = response.ftCompatibilityResult;
                let ftCompatibleDatastores: any = response.ftCompatibleDatastores;
                let devices: any = response.devices;

                manager.setCompatibleDatastores(ftCompatibleDatastores);
                manager.setMetaCompatibleDatastores(response.ftMetaCompatibleDatastores);
                manager.setConfigCompatibleDatastores(response.ftConfigCompatibleDatastores);
                manager.setDevices(devices);
                manager.setDatacenter(response.datacenter);
                if (ftCompability.errors && ftCompability.errors.length > 0) {
                    let allIssues: Array<any> = this.getAllIssues(ftCompability);
                    let modalOptions: any = this.getErrorsWarningModalOptions(
                        objectId, true, allIssues, manager);
                    this.clarityModalService.openOkCancelModal(modalOptions);

                } else if (ftCompability.warnings && ftCompability.warnings.length > 0) {
                    let allIssues: Array<any> = this.createErrorWarningArray(ftCompability.warnings, false);
                    let modalOptions: any = this.getErrorsWarningModalOptions(
                        objectId, false, allIssues, manager);
                    this.clarityModalService.openOkCancelModal(modalOptions);
                } else {
                    this.ftVmWizardService.invokeWizard(objectId, manager);
                }
            });
        }

        public convertCompatibleHostsIntoMap(hosts: any): any {
            let compatibleHostsMap: any = {};
            _.each(hosts, (item: any) => {
                let hostId: string = this.defaultUriSchemeUtil.getVsphereObjectId(item.ftHost);
                compatibleHostsMap[hostId] = {
                    errors: item.errors,
                    warnings: item.warnings
                };
            });

            return compatibleHostsMap;
        }

        public generateValidationMessages(selectedHost: any, compatbileHostsMap: any): any {
            let hostData: any = compatbileHostsMap[selectedHost.id];
            let errorMessages: any = _.map(hostData.errors, (error: any) => {
                return {
                    type: "Error",
                    text: error.localizedMessage
                };
            });
            let warningMessages: any = _.map(hostData.warnings, (warning: any) => {
                return {
                    type: "Warning",
                    text: warning.localizedMessage
                };
            });

            let allFaults: any = errorMessages.concat(warningMessages);
            return allFaults;
        }

        public createSecondaryVmSpecParameter(manager: FtVmTurnOnManager, availableDevices: any): any {
               return {
                   propertyParams: [
                       {
                           propertyName: "ftCompatibleHosts",
                           parameterType: "com.vmware.vim.binding.vim.vm.FaultToleranceConfigSpec",
                           parameter: {
                               _type: 'com.vmware.vim.binding.vim.vm.FaultToleranceConfigSpec',
                               metaDataPath: {
                                   _type: 'com.vmware.vim.binding.vim.vm.FaultToleranceMetaSpec',
                                   metaDataDatastore: manager.getSelectedDatastores()[
                                         this.vmFtConstants.fileTypes.TIE_BREAKER].provider
                               },
                               secondaryVmSpec: {
                                   _type: 'com.vmware.vim.binding.vim.vm.FaultToleranceVMConfigSpec',
                                   vmConfig: manager.getSelectedDatastores()[
                                         this.vmFtConstants.fileTypes.CONFIGURATION].provider,
                                   disks: _.map(availableDevices, (item: any) => {
                                       return {
                                           _type: 'com.vmware.vim.binding.vim.vm.FaultToleranceVMConfigSpec$FaultToleranceDiskSpec',
                                           disk: item,
                                           datastore: manager.getSelectedDatastores()[
                                                 this.vmFtConstants.fileTypes.VIRTUAL_DISK
                                                 + item.key].provider
                                       };
                                   })
                               }
                           }
                       }
                   ]
               };
        }

        public getSelectDatastoreGridOptions(data: any): any {
            return {
                selectionMode: this.vuiConstants.grid.selectionMode.SINGLE,
                sortMode: this.vuiConstants.grid.sortMode.SINGLE,
                resizable: true,
                selectedItems: [],
                data: data,
                columnDefs: this.getSelectDatastoreColumnDefs(),
                height: "100%",
                pageConfig: {
                    hidePager: true
                }
            };
        }

        public getAdvancedDatastoreGridOptions(): any {
            return {
                height: "100%",
                resizable: true,
                columnDefs: this.getAdvancedColumnDefs(),
                data: [],
                pageConfig: {
                    hidePager: true
                },
                searchable: false,
                selectedItems: []
            };
        }

        public getErrorPageGridOptions(data: any): any {
            return {
                selectionMode: this.vuiConstants.grid.selectionMode.SINGLE,
                sortMode: this.vuiConstants.grid.sortMode.SINGLE,
                resizable: true,
                selectedItems: [],
                data: data,
                columnDefs: this.getErrorPageColumnDefs(),
                height: "300px",
                pageConfig: {
                    hidePager: true
                }
            };
        }

        public getFtHostsIds(compatibleHosts: any): any {
            return _.map(compatibleHosts, (host: any) => {
                return this.defaultUriSchemeUtil.getVsphereObjectId(host.ftHost);
            });
        }

        public getCompatibilityResultMessages(compatibleHosts: any): any {
            let compatibilityErrorsForHosts: any = {};
            _.each(compatibleHosts, (host: any) => {
                let hostId: any = this.defaultUriSchemeUtil.getVsphereObjectId(host.ftHost);
                if (host.errors && host.errors.length > 0) {
                    _.each(host.errors, (error: any) => {
                        if (compatibilityErrorsForHosts.hasOwnProperty(hostId)) {
                            compatibilityErrorsForHosts[hostId].errorMessages.push(error.localizedMessage);
                        } else {
                            compatibilityErrorsForHosts[hostId] = {
                                icon: 'vx-icon-host',
                                errorMessages: [error.localizedMessage]
                            };
                        }
                    });
                }
            });

            return compatibilityErrorsForHosts;
        }

        public migrateSecondaryFtVm(vmId: string): void {
            let FT_INFO: string = "config/ftInfo";
            this.dataService.getProperties(vmId, [FT_INFO]).then((res: any) => {
                if (res[FT_INFO] && res[FT_INFO].secondaries && res[FT_INFO].secondaries[0]) {
                    let secondaryVmId = this.defaultUriSchemeUtil.getVsphereObjectId(
                          res[FT_INFO].secondaries[0]);

                    this.migrateVmWizardService.showWizard([secondaryVmId]);
                }
            });
        }

        public hasFtHostWithoutErrors(hosts: any): boolean {
            return _.some(hosts, (host: any) => {
                return !host.errors || host.errors.length === 0;
            });
        }

        public ftTestFailover(vmId: string): void {
            const spec: VmTestFailoverSpec = new VmTestFailoverSpec();
            this.mutationService.apply(vmId, spec._type, spec);
        }

        public ftRestartSecondary(vmId: string): void {
            const spec: VmRestartSecondarySpec = new VmRestartSecondarySpec();
            this.mutationService.apply(vmId, spec._type, spec);
        }

        public suspendFaultTolerance(vmId: string): void {
            const spec: VmDisableSecondarySpec = new VmDisableSecondarySpec();
            this.mutationService.apply(vmId, spec._type, spec);
        }

        public resumeFaultTolerance(vmId: string): void {
            const spec: VmEnableSecondarySpec = new VmEnableSecondarySpec();
            this.mutationService.apply(vmId, spec._type, spec)
                  .then((operationResult: OperationResult): void => {
                      this.processFaultToleranceOpResult(operationResult);
                  });
        }

        private processFaultToleranceOpResult(
              operationResult: OperationResult): void {
            if (operationResult && operationResult.taskUid) {
                this.dataService
                      .getProperties(operationResult.taskUid, ["info"])
                      .then((taskData: any): void => {
                          this.onTaskInfoRetrieved(taskData);
                      });
            }
        }

        private onTaskInfoRetrieved(taskData: any): void {
            if (taskData
                  && taskData.info
                  && taskData.info.result
                  && taskData.info.result.vm
                  && taskData.info.result.powerOnAttempted
                  && taskData.info.result.powerOnResult) {

                const vmId: string =
                      this.defaultUriSchemeUtil.getVsphereObjectId(
                            taskData.info.result.vm);

                this.powerOpsService.showPowerOnFailuresAndRecommendationsDialog(
                      [{result: taskData.info.result.powerOnResult}], [vmId]);
            }
        }

        private getSelectDatastoreColumnDefs(): any {
            let columns: any = [
                {
                    displayName: this.i18nService.getString(
                        'Common', 'StorageLocatorControl.NameColumnHeader'),
                    width: 200,
                    template: this.iconNameRendererFactory('name', 'vsphere-icon-datastore')
                },
                {
                    field: 'compatibility',
                    displayName: this.i18nService.getString(
                        'Common', 'StorageLocatorControl.CompatibilityColumnHeader'
                    ),
                    width: 110,
                    visible: false
                },
                {
                    field: 'capacity',
                    displayName: this.i18nService.getString(
                        'Common', 'StorageLocatorControl.CapacityColumnHeader'
                    ),
                    type: 'number',
                    width: 110,
                    template: "#:capacityFormatted#"
                },
                {
                    field: 'provisionedSpace',
                    displayName: this.i18nService.getString(
                        'Common', 'StorageLocatorControl.ProvisionedSpaceColumnHeader'
                    ),
                    width: 110,
                    type: 'number',
                    template: "#:provisionedSpaceFormatted#"
                },
                {
                    field: 'freeSpace',
                    displayName: this.i18nService.getString(
                        'Common', 'StorageLocatorControl.FreeSpaceColumnHeader'),
                    type: 'number',
                    width: 110,
                    template: "#:freeSpaceFormatted#"
                },
                {
                    field: 'type',
                    displayName: this.i18nService.getString(
                        'Common', 'StorageLocatorControl.TypeColumnHeader'),
                    width: 110
                },
                {
                    displayName: this.i18nService.getString(
                        'Common', 'StorageLocatorControl.StoragePodColumnHeader'),
                    width: 200,
                    template: this.iconNameRendererFactory('parentPodName', 'vsphere-icon-datastore-cluster')
                },
                {
                    field: 'storageDRS',
                    displayName: this.i18nService.getString(
                        'Common', 'StorageLocatorControl.StorageDrsColumnHeader'),
                    width: 110,
                    template: (record: any) => {
                        let storageDrs: string = '';
                        if (!_.isUndefined(record.storagePodEnabled)) {
                            storageDrs = record.storagePodEnabled ?
                                this.i18nService.getString('VmUi', 'enabled') :
                                this.i18nService.getString('VmUi', 'disabled');
                        }
                        return storageDrs;
                    }
                },
                {
                    displayName: this.i18nService.getString('Common', 'StorageLocatorControl.ThinProvisioningColumnHeader'),
                    width: 110,
                    template: (record: any) => {
                        return record.isThinProvisioningSupported ?
                            this.i18nService.getString('VmUi', 'VmDatastore.ThinSupported') :
                            this.i18nService.getString('VmUi', 'VmDatastore.ThinUnsupported');
                    }
                },
                {
                    displayName: this.i18nService.getString(
                        'Common', 'StorageLocatorControl.AccessColumnHeader'),
                    width: 110,
                    template: (record: any) => {
                        return record.multipleHostAccess ?
                            this.i18nService.getString('VmUi', 'datastore.access.multiple') :
                            this.i18nService.getString('VmUi', 'datastore.access.single');
                    }
                },
                {
                    displayName: this.i18nService.getString(
                        'Common', 'StorageLocatorControl.HardwareAccelerationColumnHeader'),
                    width: 110,
                    template: (record: any) => {
                        let hardwareAccelerationValues: any = {
                            vStorageSupported: this.i18nService.getString('VmUi', 'RawDisk.vStorageSupported'),
                            vStorageUnknown: this.i18nService.getString('VmUi', 'RawDisk.vStorageUnknown'),
                            vStorageUnsupported: this.i18nService.getString('VmUi', 'RawDisk.vStorageUnsupported')
                        };
                        return hardwareAccelerationValues[record.vStorageSupport];
                    }
                }
            ];

            return columns;
        }

        private getAdvancedColumnDefs(): any[] {
            let browseOptionLabel: string = this.i18nService.getString("Common",
                  "DiskLocatorControl.Browse");

            return [
                {
                    displayName: this.i18nService.getString("Common", "DiskLocatorControl.Vm"),
                    width: 180,
                    field: "vmName",
                    type: "string",
                    template: this.i18nService.getString("Common",
                          "secondaryVmFormat", "#:vmName#"),
                    sortable: true
                },
                {
                    displayName: this.i18nService.getString("Common",
                          "DiskLocatorControl.File"),
                    width: 180,
                    field: "fileName",
                    type: "string",
                    template: (dataItem: any) => {
                        if (dataItem.itemType === this.vmFtConstants.fileTypes.TIE_BREAKER) {
                            return this.i18nService.getString("Common",
                                  "DiskLocatorControl.fileType.ftMetaInf");
                        } else if (dataItem.itemType === this.vmFtConstants.fileTypes.CONFIGURATION) {
                            return this.i18nService.getString("Common",
                                  "DiskLocatorControl.fileType.configuration");
                        } else {
                            return dataItem.fileName;
                        }
                    },
                    sortable: true
                },
                {
                    displayName: this.i18nService.getString("Common", "DiskLocatorControl.Storage"),
                    width: 180,
                    template: function (dataItem: any) {
                        return `
                  <div class="select">
                    <select ng-model="dataItem.storageItemId"
                          ng-click="dataItem.onDatastoreComboClick($event, dataItem)"
                          ng-change="dataItem.onDatastoreChange(dataItem)"
                          ng-options="option.id as option.label for option in dataItem.storageItems">
                        <option value="" disabled style="display: none">${browseOptionLabel}</option>
                     </select>
                  </div>`;
                    }
                }];
        }

        private iconNameRendererFactory(nameField: any, iconField: any) {
            return (data: any) => {
                var objectLinkRenderer = this.columnRenderersRegistry.getColumnRenderer('object-link');
                return objectLinkRenderer([undefined, nameField, iconField], data);
            };
        }


        private getErrorsWarningModalOptions(objectId: string, hasErrors: boolean,
                                             data: Array<any>, manager: FtVmTurnOnManager): any {
            let closeButtonTitle: string = hasErrors ?
                this.i18nService.getString("VmUi", "FtFaultDetailsPopUp.okButtonText") :
                this.i18nService.getString("VmUi", "FtFaultDetailsPopUp.noButtonText");

            let errorText = hasErrors ?
                this.i18nService.getString("VmUi", "turnOnFtWizard.error.hasWorkflowBreakingErrors") :
                this.i18nService.getString("VmUi", "turnOnFtWizard.error.hasWorkflowWideWarnings");

            return {
                title: this.i18nService.getString("VmUi", "FtFaultDetailsPopUp.title"),
                contentTemplate: "vm-ui/resources/vm/views/ft/ftErrorsWarningView.html",
                submitButtonTitle: this.i18nService.getString("VmUi", "FtFaultDetailsPopUp.yesButtonText"),
                closeButtonTitle: closeButtonTitle,
                hideSubmitButton: hasErrors,
                size: "lg",
                dialogData: {
                    allIssuesArray: data,
                    errorText: errorText,
                    objectId: objectId,
                    manager: manager
                },
                defaultButton: "close"
            };

        }

        private getAllIssues(ftCompability: any): Array<any> {
            let errorsArray: Array<any> = this.createErrorWarningArray(ftCompability.errors, true);
            let waringsArray: Array<any> = this.createErrorWarningArray(ftCompability.warnings, false);
            let allIssuesArray: Array<any> = errorsArray.concat(waringsArray);
            return allIssuesArray;
        }

        private createErrorWarningArray(array: any[], isErrorArray: boolean): Array<any> {
            return _.map(array, (item) => {
                let issueIconClass: string;
                let faultTypeLocalizedMessage: string = "";
                if (isErrorArray) {
                    issueIconClass = this.ERROR_ICON;
                    faultTypeLocalizedMessage =
                        this.i18nService.getString("VmUi", "FtFaultDetailsList.faultTypeError");
                } else {
                    issueIconClass = this.WARNING_ICON;
                    faultTypeLocalizedMessage =
                        this.i18nService.getString("VmUi", "FtFaultDetailsList.faultTypeWarning");
                }

                let entityIconClass: string = "";
                if (item.entity) {
                    if (item.entity.type === this.HOST_SYSTEM_TYPE) {
                        entityIconClass = this.HOST_ICON;
                    } else if (item.entity.type === this.VM_TYPE) {
                        entityIconClass = this.VM_ICON;
                    }
                }

                return _.extend({
                    issueIconClass: issueIconClass,
                    entityIconClass: entityIconClass,
                    faultTypeLocalizedMessage: faultTypeLocalizedMessage
                }, item);
            });
        }

        private validateFaultToleranceTurnOn(objectId: string): IPromise<any> {
            return this.dataService.getData(objectId,
                'com.vmware.vsphere.client.vm.impl.FtDatastoresInfo');
        }

        private getErrorPageColumnDefs(): any {
            let iconRenderer: any = this.columnRenderersRegistry.getColumnRenderer('icon-text');
            return [
                {
                    displayName: this.i18nService.getString(
                        "VmUi", "FtFaultDetailsList.faultType"
                    ),
                    template: (dataItem: any) => {
                        return iconRenderer(
                            ['issueIconClass', 'faultTypeLocalizedMessage'], dataItem
                        );
                    }
                },
                {
                    displayName: this.i18nService.getString("VmUi", "FtFaultDetailsList.entity"),
                    template: (dataItem: any) => {
                        return iconRenderer(['entityIconClass', 'entityName'], dataItem);
                    }
                },
                {
                    field: "localizedMessage",
                    displayName: this.i18nService.getString(
                        "VmUi", "FtFaultDetailsList.description"
                    )
                }
            ];
        }

    }

    angular.module('com.vmware.vsphere.client.vm')
        .service('ftVmService', FtVmService);
}
