/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace dvs_ui {

   export class DvpgCreateReadyToCompletePageService {

      static $inject = [
         "i18nService",
         "networkUiConstants",
         "dvpgCreateWizardConstants"
      ];

      constructor(private i18nService: any,
                  private networkUiConstants: any,
                  private wizardConstants: any) {
      }

      public getNetworkResourcePoolLabel(
            resourcePoolKey: string, resourcePools: any []): string {
         let networkResourcePoolLabel: string = this.i18nService.getString(
               "DvsUi", "dvpg.create.finish.resourcePool.default");

         if (resourcePoolKey ===
               this.wizardConstants.DVPG_DEFAULT_NETWORK_RESOURCE_POOL_KEY ||
               !resourcePools ||
               resourcePools.length === 0) {
            return networkResourcePoolLabel;
         }

         _.each(resourcePools, (resourcePool: any) => {

            if (resourcePool.key === resourcePoolKey) {
               networkResourcePoolLabel = resourcePool.name;
            }
         });

         return networkResourcePoolLabel;
      }

      public getPortAllocationLabel(autoExpand: boolean): string {
         return autoExpand
            ? this.i18nService.getString("DvsUi",
                  "dvpg.create.properties.portAllocation.autoExpand.true")
            : this.i18nService.getString("DvsUi",
                  "dvpg.create.properties.portAllocation.autoExpand.false");
      }

      public getPortBindingLabel(portBinding: string): string {
         let portBindingLabel: string = this.i18nService.getString("DvsUi",
               "dvpg.create.finish.property.na");

         if (!portBinding) {
            return portBindingLabel;
         }

         switch(portBinding) {
            case this.wizardConstants.portBinding.EARLY_BINDING: {
               portBindingLabel = this.i18nService.getString("DvsUi",
                     "dvpg.create.properties.portBinding.earlyBinding");
               break;
            }
            case this.wizardConstants.portBinding.EPHEMERAL: {
               portBindingLabel = this.i18nService.getString("DvsUi",
                     "dvpg.create.properties.portBinding.ephemeral");
               break;
            }
         }
         return portBindingLabel;
      }

      public getVlanPropertyName(vlanType: string): string {
         let vlanPropertyName: string = this.i18nService.getString("DvsUi",
               "dvpg.create.finish.vlan");

         if (!vlanType) {
            return vlanPropertyName;
         }

         switch(vlanType) {
            case this.networkUiConstants.Vlan.Type.NONE:
            case this.networkUiConstants.Vlan.Type.VLAN: {
               vlanPropertyName = this.i18nService.getString(
                     "DvsUi", "dvpg.create.finish.vlan.id");
               break;
            }
            case this.networkUiConstants.Vlan.Type.PRIVATE: {
               vlanPropertyName = this.i18nService.getString(
                     "DvsUi", "dvpg.create.finish.vlan.private");
               break;
            }
            case this.networkUiConstants.Vlan.Type.TRUNK: {
               vlanPropertyName = this.i18nService.getString(
                     "DvsUi", "dvpg.create.finish.vlan.trunk");
               break;
            }
         }
         return vlanPropertyName;
      }

      public getVlanPropertyValue(model: DvpgCreateWizardModel): string {
         let vlanPropertyValue: string = this.i18nService.getString("DvsUi",
               "dvpg.create.finish.property.na");

         if (!model) {
            return vlanPropertyValue;
         }

         switch(model.vlanType) {
            case this.networkUiConstants.Vlan.Type.VLAN: {
               return model.vlanId.toString();
            }
            case this.networkUiConstants.Vlan.Type.PRIVATE: {
               return this.getPvlanLabel(model.pvlanId, model.pvlanConfig);
            }
            case this.networkUiConstants.Vlan.Type.TRUNK: {
               return model.vlanTrunkRanges.replace(/ +/g, '').replace(/,/g, ', ');
            }
         }
         return vlanPropertyValue;
      }

      private getPvlanTypeLabel(pvlanType: string): string {
         switch (pvlanType) {
            case this.networkUiConstants.Vlan.PvlanType.ISOLATED: {
               return this.i18nService.getString(
                     "DvsUi", "dvpg.create.properties.vlan.type.private.isolated");
            }
            case this.networkUiConstants.Vlan.PvlanType.PROMISCUOUS: {
               return this.i18nService.getString(
                     "DvsUi", "dvpg.create.properties.vlan.type.private.promiscuous");
            }
            case this.networkUiConstants.Vlan.PvlanType.COMMUNITY: {
               return this.i18nService.getString(
                     "DvsUi", "dvpg.create.properties.vlan.type.private.community");
            }
            default:
               return "";
         }
      }

      private getPvlanLabel(secondaryVlanId: number, pvlanConfigs: any[]): string {
         let pvlanLabel: string = this.i18nService.getString(
               "DvsUi", "dvpg.create.finish.property.na");

         if (!pvlanConfigs) {
            return pvlanLabel;
         }

         _.each(pvlanConfigs, (pvlanConfig: any) => {

            if (pvlanConfig.secondaryVlanId === secondaryVlanId) {
               let pvlanTypeLabel = this.getPvlanTypeLabel(pvlanConfig.pvlanType);

               pvlanLabel = this.i18nService.getString(
                     "DvsUi", "dvpg.create.properties.vlan.type.private.displayFormat",
                     pvlanTypeLabel,
                     pvlanConfig.primaryVlanId,
                     pvlanConfig.secondaryVlanId);
            }
         });

         return pvlanLabel;
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .service("dvpgCreateReadyToCompletePageService",
               DvpgCreateReadyToCompletePageService);
}
