(function () {
   'use strict';
   angular.module('com.vmware.vsphere.client.cluster')
      .controller('EditEvcDialogController', EditEvcDialogController);

   EditEvcDialogController.$inject = ['$scope',
      'dataService',
      'i18nService',
      'mutationService',
      'vuiConstants',
      'logService',
      'evcConstants',
      'iconService'];

   function EditEvcDialogController($scope,
         dataService,
         i18nService,
         mutationService,
         vuiConstants,
         logService,
         evcConstants,
         iconService) {
      var self = this;
      var log = logService('EditEvcDialogController');
      var objectId = $scope.modalOptions.dialogData.objectId;
      // Fake EVC mode
      var DISABLED_EVC_MODE = { key: null, label: getString('evc.config.disableText') };

      var COMPATIBILITY_ITEM_CURRENT_CONFIGURATION = {
         icon: 'vsphere-icon-status-ok',
         message: getString('evc.config.validation.currentConfiguration'),
         contents: []
      };
      var COMPATIBILITY_ITEM_VALIDATION_SUCCEEDED = {
         icon: 'vsphere-icon-status-ok',
         message: getString('evc.config.validation.validationSucceeded'),
         contents: []
      };
      var COMPATIBILITY_ITEM_DISABLING_EVC = {
         icon: 'vsphere-icon-status-warning',
         message: getString('evc.config.validation.warning.disable'),
         contents: []
      };
      var COMPATIBILITY_ITEM_DISABLING_EVC_NO_FT_DRS_CHECK = {
         icon: 'vsphere-icon-status-warning',
         message: getString('evc.config.validation.warning.disable.noFtDrsCheck'),
         contents: []
      };
      var COMPATIBILITY_ITEM_RAISING_EVC_BASELINE = {
         icon: 'vsphere-icon-status-warning',
         message: getString('evc.config.validation.warning.raiseBaseline'),
         contents: []
      };

      /**
       * Map EvcModeKey -> ValidationResult
       * A cache of validation results for each checked EvcMode.
       */
      var validationResultByEvcModeKey = {};

      /**
       * Keeps the names of the hosts in the cluster, mapped by their id.
       * Used to display the names of the problematic hosts, when there are
       * compatibility errors.
       */
      var hostsNamesById = {};

      var currentEvcMode;
      var currentVendor;

      self.vendorHeaderOptions = getHeaderOptions('evc.config.evcModeBlock');
      self.descriptionHeaderOptions = getHeaderOptions('evc.config.descriptionBlock');
      self.compatibilityHeaderOptions = getHeaderOptions('evc.config.compatibilityBlock');
      $scope.modalOptions.isAlertClosed = true;

      activate();
      getViewData();

      function activate() {
         setPageBusy(true);
         $scope.modalOptions.onSubmit = onSubmit;
         $scope.evcConstants = evcConstants;
         $scope.$watch('ctrl.vendor', onVendorChanged);
         $scope.$watch('ctrl.evcMode', onEvcModeChanged);
      }

      function getViewData() {
         dataService
            .getData(objectId,
               'com.vmware.vsphere.client.clusterui.model.configuration.EditEvcConfigData')
            .then(function (data) {
               if (!data || !data.supportedIntelEvcMode || !data.supportedAmdEvcMode) {
                  showDataRetrieveError();
                  return;
               }
               self.supportedIntelEvcMode = data.supportedIntelEvcMode;
               self.supportedAmdEvcMode = data.supportedAmdEvcMode;
               self.ftDrsWithoutEvcSupported = data.ftDrsWithoutEvcSupported;
               hostsNamesById = getHostNamesById(data.hosts);
               if (!data.currentEvcMode) {
                  self.vendor = evcConstants.cpuVendor.UNKNOWN;
                  self.evcMode = DISABLED_EVC_MODE;
               } else {
                  self.vendor = data.currentEvcMode.vendor;
                  self.evcMode = data.currentEvcMode;
               }
               currentVendor = self.vendor;
               currentEvcMode = self.evcMode;
               setPageBusy(false);
            });
      }

      function onVendorChanged(newVendor) {
         self.evcModes = getEvcModes(newVendor);

         if (newVendor) {
            if (newVendor === currentVendor) {
               // Preselect the current EvcMode
               self.evcMode = _.find(self.evcModes, function(evcMode) {
                  return evcMode.key === currentEvcMode.key;
               });
            } else if (self.evcModes) {
               self.evcMode = self.evcModes[0];
            } else {
               self.evcMode = {};
            }
         }
      }

      function onEvcModeChanged(newEvcMode) {
         if (!newEvcMode || !currentEvcMode) {
            return;
         }
         self.compatibilityItems = [];
         $scope.modalOptions.isAlertClosed = true;
         $scope.modalOptions.alerts = [];
         if (newEvcMode.key === currentEvcMode.key) {
            // we are keeping the current configuration
            self.compatibilityItems = [COMPATIBILITY_ITEM_CURRENT_CONFIGURATION];
            return;
         }
         if (newEvcMode.key === null) {
            // we are disabling EVC
            if (self.ftDrsWithoutEvcSupported) {
               self.compatibilityItems = [COMPATIBILITY_ITEM_DISABLING_EVC_NO_FT_DRS_CHECK];
            } else {
               self.compatibilityItems = [COMPATIBILITY_ITEM_DISABLING_EVC];
            }
            return;
         }
         if (validationResultByEvcModeKey[newEvcMode.key]) {
            handleEvcSpecValidationResult(
                  validationResultByEvcModeKey[newEvcMode.key]);
            return;
         }
         setPageBusy(true);
         validateEvcMode(newEvcMode.key).then(function(validationResult) {
            validationResultByEvcModeKey[newEvcMode.key] = validationResult;
            handleEvcSpecValidationResult(validationResult);
            setPageBusy(false);
         });
      }

      function handleEvcSpecValidationResult (validationResult) {
         if (!validationResult) {
            return;
         }
         if (validationResult.error) {
            $scope.modalOptions.alerts = [{
               type: vuiConstants.validationBanner.type.ERROR,
               text: validationResult.error.localizedMessage
            }];
            $scope.modalOptions.isAlertClosed = false;
         } else {
            if (validationResult.result && validationResult.result.length !== 0) {
               self.compatibilityItems = groupCompatibilityItemsByDescription(
                     validationResult.result.map(getCompatibilityItem));
            } else {
               if (raisingEvcBaseline()) {
                  self.compatibilityItems =
                        [COMPATIBILITY_ITEM_RAISING_EVC_BASELINE];
               } else {
                  self.compatibilityItems =
                        [COMPATIBILITY_ITEM_VALIDATION_SUCCEEDED];
               }
            }
         }
      }

      function validateEvcMode(evcModeKey) {
         return mutationService.validate(objectId,
               'com.vmware.vsphere.client.cluster.ClusterEvcSpec',
               getEvcSpecForMode(evcModeKey));
      }

      function raisingEvcBaseline() {
         return self.evcMode.key !== null &&
               currentEvcMode.key !== null &&
               self.evcMode.vendorTier > currentEvcMode.vendorTier;
      }

      function groupCompatibilityItemsByDescription(compatibilityItems) {
         var compatibilityItemsByMessage = _.groupBy(compatibilityItems, 'message');
         return _.map(compatibilityItemsByMessage,
               function (compatibilityItems, message) {
                  var hostNames = _.uniq(_.flatten(_.map(compatibilityItems, 'hostNames')));
                  return {
                     icon: 'vsphere-icon-status-error',
                     message: message,
                     contents: _.map(hostNames, function (name) {
                        return {
                           icon: iconService.getByObjectType('HostSystem'),
                           message: name,
                           contents: []
                        };
                     })
                  };
               });
      }

      function getCompatibilityItem(checkResult) {
         var hostNames = [];
         if (checkResult.host && checkResult.host.length !== 0) {
            checkResult.host.forEach(function(hostRef) {
               var hostName = hostsNamesById[hostRef.value];
               if (hostName) {
                  hostNames.push(hostName);
               } else {
                  log.warn('Could not find a name for host: ', hostRef);
               }
            });
         }
         return {
            message: checkResult.error.localizedMessage,
            hostNames: hostNames
         };
      }

      function getEvcModes(selectedEvcVendor) {
         if (selectedEvcVendor === evcConstants.cpuVendor.INTEL) {
            return self.supportedIntelEvcMode;
         } else if (selectedEvcVendor === evcConstants.cpuVendor.AMD) {
            return self.supportedAmdEvcMode;
         } else {
            return [DISABLED_EVC_MODE];
         }
      }

      function getHostNamesById(hosts) {
         var hostsNamesById = {};
         if (hosts && hosts.length !== 0) {
            hosts.forEach(function (hostNameData) {
               hostsNamesById[hostNameData.provider.value] =
                     hostNameData.name;
            });
         }
         return hostsNamesById;
      }

      function showDataRetrieveError() {
         setPageBusy(false);
         $scope.modalOptions.submitDisabled = true;
         $scope.modalOptions.alerts = [{
            type: vuiConstants.validationBanner.type.ERROR,
            text: getString('evc.config.serverError')
         }];
         $scope.modalOptions.isAlertClosed = false;
         log.error('Server error can not retrieve edit EVC config data.');
      }

      function getHeaderOptions(textKey) {
         return {
            title: getString(textKey)
         };
      }

      function getString(key) {
         return i18nService.getString('ClusterUi', key);
      }

      function getEvcSpecForMode(evcModeKey) {
         return {
            evcModeKey: evcModeKey
         };
      }

      function onSubmit () {
         var selectedEvcModeKey = (self.evcMode) ?
            self.evcMode.key
            : null;

         mutationService.apply(objectId, 'com.vmware.vsphere.client.cluster.ClusterEvcSpec',
               getEvcSpecForMode(selectedEvcModeKey));

         $scope.closeModal();

         return false;
      }

      function setPageBusy(isBusy) {
         self.loading = isBusy;
         $scope.modalOptions.submitDisabled = isBusy;
      }
   }
})();
