namespace h5_vm {
   import CustomizeGosSpecData = com.vmware.vsphere.client.h5.vm.data.CustomizeGosSpecData;
   import CustomizationSpecInfo = com.vmware.vim.binding.vim.CustomizationSpecInfo;
   import VmCustomizationValidationResult = com.vmware.vsphere.client.vm.VmCustomizationValidationResult;
   import Specification = com.vmware.vim.binding.vim.vm.customization.Specification;
   import WinOptions = com.vmware.vim.binding.vim.vm.customization.WinOptions;

   export class CustomizeGosSelectSpecPageController {
      static $inject = ["customizeGosSelectSpecPageService", "i18nService",
         "vuiConstants", "timeFormatterService", "$q"];

      public i18n: Function;
      public model: CustomizeGosSelectSpecPageModel;
      public specsGridOptions: any;

      constructor(private pageService: CustomizeGosSelectSpecPageService,
            private i18nService: any,
            private vuiConstants: any,
            private timeFormatterService: any,
            private $q: angular.IQService) {
         this.i18n = this.i18nService.getString;
      }

      $onInit(): void {
         this.model.onPageCommit = this.onPageCommit.bind(this);
         this.specsGridOptions = this.buildGridConfiguration();
         this.specsGridOptions.data = _.sortBy(this.model.relevantSpecs, (spec) => {
            return spec.name.toLowerCase();
         });
      }

      $onDestroy(): void {
         this.model.onPageCommit = undefined;
      }

      private onPageCommit(): angular.IPromise<Array<string>> {
         return this.validateSelection();
      }

      private buildGridConfiguration(): any {
         return {
            columnDefs: [
               {
                  field: "name",
                  displayName: this.i18n("VmUi",
                        "CustomizeGuestOsProvisioningPage.content.name")
               },
               {
                  field: "type",
                  displayName: this.i18n("VmUi",
                        "CustomizeGuestOsProvisioningPage.content.type"),
                  width: 120
               },
               {
                  field: "lastUpdateTime",
                  searchable: false,
                  displayName: this.i18n("VmUi",
                        "customizationManager.customizationSpecList.lastModifiedColumnHeader"),
                  template: (spec: any) => {
                     return this.timeFormatterService.timestampToText(
                           parseInt(spec.lastUpdateTime));
                  },
                  width: 200
               }
            ],
            selectionMode: this.vuiConstants.grid.selectionMode.SINGLE,
            sortMode: this.vuiConstants.grid.sortMode.SINGLE,
            searchable: false,
            resizable: true,
            height: "100%",
            selectedItems: [],
            data: []
         };
      }

      private validateSelection(): angular.IPromise<Array<string>> {
         this.model.specification = undefined;
         this.model.hasUnknownNameGen = false;
         this.model.hasUnknownIpv4Gen = false;
         this.model.hasUnknownIpv6Gen = false;

         if (!this.specsGridOptions.selectedItems ||
               this.specsGridOptions.selectedItems.length !== 1) {
            return this.$q.resolve(
                  [this.i18n("VmUi", "customizeGuestOs.selectSpecPage.no_selection")]);
         }

         let selectedItem = this.specsGridOptions.selectedItems[0];

         return this.pageService.validateSpec(this.model.vmId, selectedItem.name)
               .then((valRes: VmCustomizationValidationResult) => {
                  return this.parseValidationResult(valRes);
               });
      }

      private parseValidationResult(valRes: VmCustomizationValidationResult): Array<string> {

         if (!valRes) {
            return [this.i18n("VmUi",
                  "customizeGuestOs.selectSpecPage.error_loading_data")];
         }

         let errors: Array<string> = [];
         if (!valRes.isPublicKeyTheSame) {
            errors.push(this.i18n("VmUi",
                  "customizeGuestOs.selectSpecPage.invalid_public_key"));
         }

         if (!valRes.areTypicalNetworkingSettingsUsed &&
               !valRes.isNetworkCardsCountTheSame) {
            errors.push(this.i18n("VmUi",
                  "customizeGuestOs.selectSpecPage.incorrect_nic_count",
                  valRes.specInfoName));
         }

         if (errors.length > 0) {
            return errors;
         }

         let specification: Specification = valRes.spec;
         let winOptions: WinOptions = specification.options as WinOptions;
         // Note: we do this to make sure there are no old specs
         if (winOptions && winOptions.deleteAccounts) {
            // this is a deprecated property
            winOptions.deleteAccounts = false;
         }
         this.model.specification = specification;
         this.model.hasUnknownNameGen = valRes.hasUnknownNameTypeGen;
         this.model.hasUnknownIpv4Gen = valRes.hasUnknownIpv4TypeGen;
         this.model.hasUnknownIpv6Gen = valRes.hasUnknownIpv6TypeGen;
         return [];
      }
   }

   export class CustomizeGosSelectSpecPageComponent {
      public controller: any;
      public templateUrl: string;
      public bindings: any;

      constructor() {
         this.controller = CustomizeGosSelectSpecPageController;
         this.templateUrl =
               "vm-ui/resources/vm/customize-guest-os/select-spec/customize-gos-select-spec-page.component.html";
         this.bindings = {
            model: "<"
         };
      }
   }

   angular.module("com.vmware.vsphere.client.vm")
         .component("customizeGosSelectSpecPage", new CustomizeGosSelectSpecPageComponent());
}