/* Copyright 2015 - 2018 VMware, Inc. All rights reserved. -- VMware Confidential */
/*
 * This js file has to be loaded first
 */
h5 = h5 || {};
h5.commonModule = {
   angularModule: angular.module('com.vmware.vsphere.client.commonModule', [
      'com.vmware.platform.ui', 'ui.bootstrap'])
};

// Add action
angular.module('com.vmware.vsphere.client.commonModule')
   .run(['mutationService',
      'vuiModalService',
      '$rootScope',
      '$document',
      'clarityConstants',
      '$timeout',
      'actionDialogService',
      'clarityModalService',
      'dataService',
      'i18nService',
      'libraryService',
      'categoryService',
      'categoryActionService',
      'createContentLibraryService',
      '$injector',
      'AngularInjector',
      'defaultUriSchemeUtil',
      'dragAndDropStockExpressionsService',
      'vscAssignTagsLauncherService',
      'vscOpenManageAlarmService',
      'managedEntityConstants',
      function (mutationService,
         vuiModalService,
         $rootScope,
         $document,
         clarityConstants,
         $timeout,
         actionDialogService,
         clarityModalService,
         dataService,
         i18nService,
         libraryService,
         categoryService,
         categoryActionService,
         createContentLibraryService,
         $injector,
         AngularInjector,
         defaultUriSchemeUtil,
         dragAndDropStockExpressionsService,
         vscAssignTagsLauncherService,
         vscOpenManageAlarmService,
         managedEntityConstants) {

         var NAME_PROPERTY = 'name';
         var TYPE_PROPERTY = 'type';
         var MOR_ID_OF_VMTX_PROPERTY = 'morIdOfVmtx';

         /*
          * Register the action invoker function in the h5.actions cache.
          */
         h5.actions['vsphere.core.actions.openAboutVsphere'] = function (actionEval, availableTargets) {
            openAboutVspherePopup();
         };
         h5.actions['vsphere.opsmgmt.alarms.acknowledge'] = function (actionEval, availableTargets, context) {
            var alarm = context.alarm ? context.alarm : context.details.alarmInfo.alarm;
            return mutationService
               .apply(
                  context.entityId,
                  'com.vmware.opsmgmt.client.alarms.AlarmAcknowledgementSpec',
                  {alarm: alarm})
               .then(function (operationResult) {
                  if (operationResult && operationResult.error) {
                     return;
                  }
                  // Since the above mutation does not cause a generation
                  // number change, we need to manually refresh the object.
                  // Here, we are refreshing only the issues, under the
                  // assumption that alarm acknowledgement should not change
                  // the status of an object.
                  $rootScope.$broadcast('IssueChangeEvent');
               });
         };
         h5.actions['vsphere.opsmgmt.alarms.reset'] = function (actionEval, availableTargets, context) {
            var alarm = context.alarm ? context.alarm : context.details.alarmInfo.alarm;
            return mutationService
               .apply(
                  context.entityId,
                  'com.vmware.opsmgmt.client.alarms.AlarmStatusSpec',
                  {
                     alarm: alarm,
                     status: 'green'
                  })
               .then(function (operationResult) {
                  if (operationResult && operationResult.error) {
                     return;
                  }
                  // Since the above mutation does not cause a generation
                  // number change, we need to manually refresh the object.
                  var objectChangeInfo = {
                     operationType: 'CHANGE',
                     objectId: context.entityId,
                     object: context.entity
                  };
                  $rootScope.$broadcast('modelChanged', objectChangeInfo);
                  $rootScope.$broadcast('IssueChangeEvent');
               });
         };

         function invokeEnableAlarmAction(availableTarget, isEnabled) {
            return mutationService
               .apply(availableTarget, 'com.vmware.opsmgmt.client.alarms.EnableAlarmActionsSpec', {
                  enabled: isEnabled
               });
         }

         h5.actions['vsphere.opsmgmt.alarms.enableAlarmActions'] = function (actionEval, availableTargets, context) {
            return invokeEnableAlarmAction(availableTargets[0], true);
         };

         h5.actions['vsphere.opsmgmt.alarms.disableAlarmActions'] = function (actionEval, availableTargets, context) {
            return invokeEnableAlarmAction(availableTargets[0], false);
         };

         h5.actions['vsphere.core.actions.editCustomAttributesAction'] = function (actionEval, availableTargets, context) {
            clarityModalService.openModal(
               actionEval,
               availableTargets,
               context,
               'common-module-ui/resources/common-module/views/custom-attributes/EditCustomAttributesDialog.html'
            );
         };

         h5.actions['vsphere.core.inventory.actions.addPermission'] = function (actionEval, availableTargets, context) {
            if (!context) {
               context = {edit: false};
            }
            clarityModalService.openModal(actionEval, availableTargets, context,
               'common-module-ui/resources/common-module/views/permission/AddPermissionDialog.html',
               {height: 'auto'});
         };

         h5.actions['vsphere.core.common.actions.removeTagAction'] = function (actionEval, availableTargets, context) {
            clarityModalService.openModal(actionEval, availableTargets, context,
               'common-module-ui/resources/common-module/views/tags/removeTagDialog.html',
               {height: 'auto'});
         };

         h5.actions['vsphere.core.common.actions.assignTag'] = function (actionEval, availableTargets, context) {
             vscAssignTagsLauncherService.showAssignTagsModal(availableTargets, context);
         };

         h5.actions['vsphere.core.common.actions.addCategory'] = function (actionEval, availableTargets, context) {
            clarityModalService.openModal(actionEval, availableTargets, context,
               'common-module-ui/resources/common-module/views/categories/add-edit-category.html');
         };

         function transformCategoriesData (categories) {
            return _.map(categories, function(category) {
               if (category.associableEntityTypes.length === 0) {
                  category.displayAssociableEntityTypesString = i18nService.getString('Common', 'tag.dashboard.categories.associable.all');
               } else {
                  category.displayAssociableEntityTypesString = lookupEntityNames(category.associableEntityTypes).join(', ');
               }
               return category;
            });
         }

         function lookupEntityNames (entityTypes) {
             return _.map(entityTypes, function(entityType) {
                 return i18nService.getString('Common', 'typeResource.entityType.' + entityType + 'Plural');
             });
         }

         h5.actions['vsphere.core.common.actions.editCategory'] = function (actionEval, availableTargets) {
              if (availableTargets) {
                  categoryService.getAll().then(function(data) {
                      var categories = transformCategoriesData(data);
                      dataService.getProperties(availableTargets[0], ['name','description',
                          'multipleCardinality', 'associableEntityType']).then(function (data) {
                           var entityTypesString = '';
                          if (data.associableEntityType === null) {
                              entityTypesString = i18nService.getString('Common', 'tag.dashboard.categories.associable.all');
                          }
                          var parts = availableTargets[0].split(':');
                          var mor = {
                              type: parts[2],
                              value: parts[3],
                              serverGuid: parts[4]
                          };
                          var categoryData = {
                              categoryName: data.name,
                              managedObjectReference: mor,
                              categoryDescription: data.description,
                              multipleCardinality: data.multipleCardinality,
                              displayAssociableEntityTypesString: entityTypesString,
                              associableEntityTypes: data.associableEntityType
                          };
                          categoryActionService.addEditCategory(categories, categoryData);
                      });
                  });
              } else {
                  return;
              }
          };

         h5.actions['vsphere.core.common.actions.deleteCategory'] = function (actionEval, availableTargets) {
            if (availableTargets) {
               var spec = "com.vmware.vsphere.client.tagging.CategoryCreateSpec";
               mutationService.remove(availableTargets, spec, {});
               $rootScope._navigateToView('vsphere.core.tagsAndCustomAttributes.domainView');
              } else {
               return;
              }
         };

         h5.actions["vsphere.opsmgmt.alarms.creation"] = function(actionEval, availableTargets) {
            if (_.isEmpty(availableTargets)) {
               return;
            }
            vscOpenManageAlarmService.openManageAlarmWizard(availableTargets);
         };

         function renameObject(availableTargets, actionEval) {
            dataService.getProperties(availableTargets[0], ['name']).then(function (data) {
               clarityModalService.openModal(
                  actionEval,
                  availableTargets,
                  {objectId: availableTargets[0], existingObjectName: data.name, defaultButton: 'submit'},
                  'common-module-ui/resources/common-module/views/rename/renameObject.html'
               );
            });
         }

         h5.actions['vsphere.core.common.actions.rename'] = function (actionEval, availableTargets) {
            renameObject(availableTargets, actionEval);
         };

         h5.actions['vsphere.core.common.actions.delete'] = function (actionEval, availableTargets) {
            mutationService.remove(availableTargets[0]);
         };

         h5.actions['vsphere.core.library.delete'] = function (actionEval, availableTargets) {
            libraryService.deleteContentLibrary(availableTargets);
         };

         h5.actions['vsphere.core.library.subscribed.editSettings'] = function (actionEval, availableTargets) {
            var editSettingsService = AngularInjector.get('vscSubscribedLibraryEditSettingsModalService');
            editSettingsService.openSubscribedLibraryEditSettingsDialog(availableTargets[0]);
         };

         h5.actions["vsphere.core.library.editNotesAction"] = function (actionEval, availableTargets, context) {
            function onUpdateNotes(objectId, notesValue, operationName) {
               var specType = "com.vmware.vsphere.client.library.specs.EditLibraryBasicSpec";
               var propSpec = {
                  description: notesValue
               };
               mutationService.apply(objectId, specType, propSpec, operationName);
               return true;
            }

            var dialogData = {
               notesPropName: "com.vmware.content.LibraryModel/description",
               onUpdateNotesCallback: onUpdateNotes,
               focusTarget: context ? context.focusTarget : undefined
            };

            clarityModalService.openModal(actionEval, availableTargets, dialogData,
               "common-module-ui/resources/common-module/views/notes/NotesDialog.html");
         };

         h5.actions["vsphere.core.library.item.actions.editNotesAction"] = function (actionEval, availableTargets, context) {
            function onUpdateNotes(objectId, notesValue, operationName) {
               var specType = "com.vmware.vsphere.client.libraryItem.specs.EditLibraryItemSpec";
               var propSpec = {
                  itemDescription: notesValue
               };
               mutationService.apply(objectId, specType, propSpec, operationName);
               return true;
            }

            var dialogData = {
               notesPropName: "com.vmware.content.library.ItemModel/description",
               onUpdateNotesCallback: onUpdateNotes,
               focusTarget: context ? context.focusTarget : undefined
            };

            clarityModalService.openModal(actionEval, availableTargets, dialogData,
               "common-module-ui/resources/common-module/views/notes/NotesDialog.html");
         };


         h5.actions['vsphere.core.library.add'] = function (actionEval, availableTargets) {
            createContentLibraryService.showWizard(availableTargets[0]);
         };

         h5.actions['vsphere.core.libraryItem.import'] = function (actionEval, availableTargets) {
            var svc = $injector.get('vscImportLibraryItemInvoker');
            svc.openImportLibraryItemModal(availableTargets[0]);
         };

         h5.actions['vsphere.core.library.rename'] = function (actionEval, availableTargets) {
            var renameLibraryService = AngularInjector.get('vscRenameLibraryService');
            renameLibraryService.renameLibrary(availableTargets[0]);
         };

         h5.actions['vsphere.core.library.local.editSettings'] = function (actionEval, availableTargets, context) {
            var editSettingsService = AngularInjector.get('vscLocalEditSettingsModalService');
            editSettingsService.openLocalEditSettingsDialog(availableTargets[0], context);
         };

         h5.actions['vsphere.core.library.sync'] = function (actionEval, availableTargets) {
            var svc = $injector.get('vscSyncLibraryService');
            svc.startLibrarySync(availableTargets);
         };

         h5.actions['vsphere.core.library.item.actions.deployFromTemplate'] = function (actionEval, availableTargets) {
            var createVmWizardService = $injector.get('createVmWizardService');
            var creationTypeConstants = $injector.get('creationTypeConstants');

            dataService.getProperties(availableTargets[0], [TYPE_PROPERTY, MOR_ID_OF_VMTX_PROPERTY, NAME_PROPERTY])
                  .then(function (data) {
                     var creationType, contextObjectId, context;
                     if (data &&
                           data[TYPE_PROPERTY] === managedEntityConstants.VM_TEMPLATE &&
                           data.hasOwnProperty(MOR_ID_OF_VMTX_PROPERTY)) {
                        var vmtxItemMor = data[MOR_ID_OF_VMTX_PROPERTY];
                        creationType = creationTypeConstants.DEPLOY_VM_FROM_VMTX;
                        contextObjectId = defaultUriSchemeUtil.createVmomiUri(vmtxItemMor.type,
                              vmtxItemMor.value, vmtxItemMor.serverGuid);
                        context = {
                           libraryItemId: availableTargets[0],
                           vmtxLibraryItemId: contextObjectId,
                           libraryItemName: data[NAME_PROPERTY]
                        };
                     }
                     else {
                        creationType = creationTypeConstants.DEPLOY_VM_FROM_TEMPLATE;
                        contextObjectId = availableTargets[0];
                     }
                     createVmWizardService.showWizard(contextObjectId, creationType, context);
                  });
         };

         h5.actions['vsphere.core.library.item.actions.publishVmtxTemplate'] = function (actionEval, availableTargets) {
            var publishVmtxTemplateService = AngularInjector.get('vscPublishVmtxTemplateService');
            publishVmtxTemplateService.invokePublishDialog(availableTargets[0]);
         };

         h5.actions['vsphere.core.library.item.actions.deployFromVapp'] = function (actionEval, availableTargets) {
            var createVmWizardService = $injector.get('createVmWizardService');
            var creationTypeConstants = $injector.get('creationTypeConstants');
            var context = {};

            var creationType = creationTypeConstants.DEPLOY_VM_FROM_VAPP;
            var contextObjectId = availableTargets[0];
            createVmWizardService.showWizard(contextObjectId, creationType, context);
         };

         h5.actions['vsphere.core.library.item.actions.updateItem'] = function (actionEval, availableTargets) {
            var svc = $injector.get('vscImportLibraryItemInvoker');
            svc.openImportLibraryItemModal(availableTargets[0], true);
         };

         h5.actions['vsphere.core.library.item.actions.exportItem'] = function (actionEval, availableTargets) {
            var exportLibraryItemService = $injector.get('vscExportLibraryItemService');
            exportLibraryItemService.exportLibraryItem(availableTargets[0]);
         };

         h5.actions['vsphere.core.library.item.actions.cloneItem'] = function (actionEval, availableTargets) {
            var cloneItemService = $injector.get('vscCloneItemModalInvokerService');
            cloneItemService.openModal(availableTargets);
         };

         h5.actions['vsphere.core.library.item.actions.deleteItem'] = function (actionEval, availableTargets) {
            var deleteLibraryItemService = AngularInjector.get('vscDeleteLibraryItemService');
            deleteLibraryItemService.deleteLibraryItem(availableTargets);
         };

         h5.actions['vsphere.core.library.item.actions.syncItem'] = function (actionEval, availableTargets) {
            var syncLibraryItemService = AngularInjector.get('vscSyncLibraryItemService');
            syncLibraryItemService.syncLibraryItem(availableTargets);
         };

         h5.actions['vsphere.core.library.item.actions.evictLibraryItem'] = function (actionEval, availableTargets) {
            var evictLibraryItemService = AngularInjector.get('vscEvictLibraryItemService');
            evictLibraryItemService.evictLibraryItem(availableTargets);
         };

         h5.actions['vsphere.core.library.item.actions.renameItem'] = function (actionEval, availableTargets) {
            var renameLibraryItemService = AngularInjector.get('vscRenameLibraryItemService');
            renameLibraryItemService.renameLibraryItem(availableTargets[0]);
         };

         h5.actions['vsphere.core.library.item.actions.createSubscription'] = function (actionEval, availableTargets) {
            var createSubscriptionService = AngularInjector.get('vscCreateSubscriptionWizardActionInvokerService');
            createSubscriptionService.invoke(actionEval, availableTargets);
         };

         //
         // Common validation methods accessible to all modules.
         //
         // A rule of thumb for all stock methods is that if they cannot firmly
         // decide whether the condition is true or false - they return true
         //
         h5.dragAndDropValidationMethods["equalServerGuid"] = function (
               targetId /*string*/, sourceId /*string*/) {
            var targetReference = defaultUriSchemeUtil.getManagedObjectReference(targetId);
            var sourceReference = defaultUriSchemeUtil.getManagedObjectReference(sourceId);
            return targetReference.serverGuid === sourceReference.serverGuid;
         };

         h5.dragAndDropValidationMethods["isDatacenterFolder"] = function (targetId /*string*/) {
            return dragAndDropStockExpressionsService.isDatacenterFolder(targetId);
         };

         h5.dragAndDropValidationMethods["isVmFolder"] = function (targetId /*string*/) {
            return dragAndDropStockExpressionsService.isVmFolder(targetId);
         };

         h5.dragAndDropValidationMethods["isTargetAccessible"] = function (dropTargetData) {
            return dragAndDropStockExpressionsService.isTargetAccessible(dropTargetData);
         };

         h5.dragAndDropValidationMethods["isWithinSameDatacenter"] = function (dropTargetData, dragObjectsData) {
            return dragAndDropStockExpressionsService.isWithinSameDatacenter(dropTargetData, dragObjectsData);
         };

         h5.dragAndDropValidationMethods["isVmFolderWithinSameDatacenter"] = function (dropTargetData, dragObjectsData) {
            return dragAndDropStockExpressionsService.isVmFolderWithinSameDatacenter(dropTargetData, dragObjectsData);
         };

         h5.dragAndDropValidationMethods["isWithinSameClusterOrHost"] = function (dropTargetData, dragObjectsData) {
            return dragAndDropStockExpressionsService.isWithinSameClusterOrHost(dropTargetData, dragObjectsData);
         };

         h5.dragAndDropValidationMethods["isWithinSameStandaloneHost"] = function (dropTargetData, dragObjectsData) {
            return dragAndDropStockExpressionsService.isWithinSameStandaloneHost(dropTargetData, dragObjectsData);
         };

         h5.dragAndDropValidationMethods["notDirectParent"] = function (dropTargetData, dragObjectsData) {
            return !dragAndDropStockExpressionsService.hasDirectParent(dropTargetData, dragObjectsData);
         };

         h5.dragAndDropValidationMethods["isInVmFolderWithinSameDatacenter"] = function (dropTargetData, dragObjectsData) {
            return dragAndDropStockExpressionsService.isInVmFolderWithinSameDatacenter(dropTargetData, dragObjectsData);
         };

         function openAboutVspherePopup() {
            var modalScope = $rootScope.$new();
            var modalOptions = {
               title: i18nService.getString('CommonUi', 'about.vsphere.title'),
               subTitle: i18nService.getString('CommonUi', 'about.vsphere.version'),
               contentUrl: 'common-module-ui/resources/common-module/views/about-vsphere/AboutVsphere.html',
               size: 'lg'
            };
            modalScope.modalOptions = modalOptions;
            clarityModalService.openPopUp(modalScope);
         }
      }
   ])
   .run([
      'tagActionService',
      'i18nService',
      'defaultUriSchemeUtil',
      'mutationService',
      'dataService',
      '$rootScope',
      function (tagActionService, i18nService, defaultUriSchemeUtil, mutationService, dataService, $rootScope) {
         h5.actions['vsphere.core.common.actions.addTag'] = function () {
            tagActionService.modifyTag({
               name: i18nService.getString('CommonModuleUi', 'tag.create.form.defaultName'),
               description: "",
               mode: 0
            });
         };
         h5.actions['vsphere.core.common.actions.editTag'] = function (actionEval, availableTargets, context) {
            if (!context || !context.tag) {
                if (availableTargets) {
                    dataService.getProperties(availableTargets[0], ['name', 'description', 'categoryName']).then(function (data) {
                        tagActionService.modifyTag({
                            name: data.name,
                            description: data.description,
                            mode: 1,
                            categoryName: data.categoryName
                        }, availableTargets);
                        return;
                    });
                } else {
                    return;
                }
            } else {
               var tagId = defaultUriSchemeUtil.getVsphereObjectId(context.tag.resourceObject);
               tagActionService.modifyTag({
                  name: context.tag.name,
                  description: context.tag.description,
                  mode: 1,
                  categoryName: context.tag.categoryName
                  // TODO: When available, pass the categoryMoRef along so that the category can be fetched
                  // and shown in edit mode.
                  // categoryMoRef: context.tag.categoryMoRef
               }, tagId);
            }
         };
         h5.actions['vsphere.core.common.actions.deleteTag'] = function (actionEval, availableTargets, context) {
            if (!context || !context.tag) {
               if (availableTargets) {
                   var spec = "com.vmware.vsphere.client.tagging.TagCreateSpec";
                   mutationService.remove(availableTargets, spec, {});
                   $rootScope._navigateToView('vsphere.core.tagsAndCustomAttributes.domainView');
               } else {
                  return;
               }
            }
            tagActionService.deleteTag(JSON.parse(JSON.stringify(context.tag.resourceObject)));
         };
      }])
   .run(['columnRenderersRegistry', 'templateHelperService',
      function (columnRenderersRegistry, templateHelperService) {
         columnRenderersRegistry.registerColumnRenderers({
            /**
             * VC name column - icon-link/label renderer.
             *
             * @param  {array} props Array of property names, where:
             *    1st property is VC name.
             *    2nd property (optional) is VC reference.
             *    Other properties are ignored.
             * @param  {object} data  Map of values, contains the specificType.
             * @param {object} config A dictionary (key/value) to control the renderer behavior.
             */
            'vcenter-name': function (props, data, config) {
               var vcName = props[0],
                  vcRef = props[1];
               var objLinkRenderer = columnRenderersRegistry.getColumnRenderer('object-link');
               return objLinkRenderer(
                  [vcRef, vcName, 'vsphere-icon-vcenter'], data, config);
            },
            'tpm-supported': function (props, data, config) {
               var isTpmSupported = data[props[0]];
               var text = "Not Supported";
               if (isTpmSupported) {
                  text = "Supported";
               }
               var textTemplate = "<span>{{text}}</span>";
               var compiledTextTemplate = templateHelperService.getHtmlEscapingTemplateRenderFunction(textTemplate);
               return compiledTextTemplate({text: text});
            },
            'tpm-version': function (props, data, config) {
               var tpmVersion = data[props[0]];
               if (!tpmVersion) {
                  tpmVersion = "N/A";
               }
               var textTemplate = "<span>{{text}}</span>";
               var compiledTextTemplate = templateHelperService.getHtmlEscapingTemplateRenderFunction(textTemplate);
               return compiledTextTemplate({text: tpmVersion});
            },
            'txtEnabled': function (props, data, config) {
               var txtEnabled = data[props[0]];
               if (!txtEnabled) {
                  txtEnabled = "N/A";
               }
               var textTemplate = "<span>{{text}}</span>";
               var compiledTextTemplate = templateHelperService.getHtmlEscapingTemplateRenderFunction(textTemplate);
               return compiledTextTemplate({text: txtEnabled});
            },
            'tpm-attestion-status': function (props, data, config) {
               var tpmAttestionStatus = data[props[0]];
               var text = "N/A";
               var textTemplate = "<div><span>{{text}}</span></div>";
               if (tpmAttestionStatus === 'accepted') {
                  text = "Passed";
               } else if (tpmAttestionStatus === 'notAccepted') {
                  textTemplate = "<div><clr-icon shape='exclamation-circle' class='is-error' size='25'></clr-icon><span>{{text}}</span></div>";
                  text = "Failed";
               }
               var compiledTextTemplate = templateHelperService.getHtmlEscapingTemplateRenderFunction(textTemplate);
               return compiledTextTemplate({text: text});
            },
            'tpm-attestion-time': function (props, data, config) {
               var tpmAttestionTime = data[props[0]];
               var text = "N/A";
               if (tpmAttestionTime) {
                  text = moment(tpmAttestionTime).format('MM/DD/YYYY, h:mm A');
               }
               var textTemplate = "<span>{{text}}</span>";
               var compiledTextTemplate = templateHelperService.getHtmlEscapingTemplateRenderFunction(textTemplate);
               return compiledTextTemplate({text: text});
            },
            'tpm-attestion-message': function (props, data, config) {
               var tpmAttestionMessage = data[props[0]];
               var text = "";
               if (tpmAttestionMessage) {
                  text = tpmAttestionMessage;
               }
               var textTemplate = "<span>{{text}}</span>";
               var compiledTextTemplate = templateHelperService.getHtmlEscapingTemplateRenderFunction(textTemplate);
               return compiledTextTemplate({text: text});
            },
            'tpm-txt-enabled': function (props, data, config) {
               var tpmTxtEnabled = data[props[0]];
               var text = "Off";
               if (tpmTxtEnabled) {
                  text = "On";
               }
               var textTemplate = "<span>{{text}}</span>";
               var compiledTextTemplate = templateHelperService.getHtmlEscapingTemplateRenderFunction(textTemplate);
               return compiledTextTemplate({text: text});
            }
         });
      }]);
