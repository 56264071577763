/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace dvs_ui {

   export class DvsEditSettingsGeneralPageModel {

      public name: string;
      public description: string;

      public maxNumUplinks: number;

      public isNiocEnabled: boolean;
      public isNiocSupported: boolean;

      public numUplinks: number;

      public hasModifyPrivilege: boolean;
      public hasNiocPrivilege: boolean;

      private _uplinkPortNames: string[];

      public get uplinkPortNames(): string[] {
         return this._uplinkPortNames;
      }

      public set uplinkPortNames(value: string[]) {
         this._uplinkPortNames = value;

         if (!this.numUplinks && value) {
            this.numUplinks = value.length;
         }
      }

      public buildChanges(originalData: any): any {
         let changesObject: any = {
            name: null,
            description: null,
            uplinkPortNames: null,
            isNiocEnabled: null
         };

         if (originalData.name !== this.name) {
            changesObject.name = this.name;
         }

         if (originalData.description !== this.description) {
            changesObject.description = this.description;
         }

         if (originalData.isNiocSupported && originalData.isNiocEnabled !== this.isNiocEnabled) {
            changesObject.isNiocEnabled = this.isNiocEnabled;
         }

         if (this.uplinkPortsChanged(originalData)) {
            changesObject.uplinkPortNames = this.uplinkPortNames;
         }

         return this.hasChanges(changesObject) ? changesObject : null;
      }

      public updateUplinkPortNames(): void {
         if (!this.uplinkPortNames) {
            return;
         }

         let existingNames: string[] = this.uplinkPortNames;
         let newUplinkPortNames: string[] = existingNames.slice();

         if (existingNames) {
            // remove deleted uplinks
            if (existingNames.length >= this.numUplinks) {
               newUplinkPortNames.splice(this.numUplinks);
            } else {
               // generate new uplinks
               let uplinksToAdd: number = this.numUplinks - existingNames.length;
               while (uplinksToAdd > 0) {
                  uplinksToAdd--;
                  newUplinkPortNames.push(this.generateUniqueUplinkName(newUplinkPortNames));
               }
            }
         }

         this.uplinkPortNames = newUplinkPortNames;
      }

      private hasChanges(changesObject: any): boolean {
         for (let key in changesObject) {
            if (changesObject[key] !== null) {
               return true;
            }
         }

         return false;
      }

      private uplinkPortsChanged(originalData: any): boolean {
         let zipped: any[][] = _.zip(originalData.uplinkPortNames, this.uplinkPortNames);
         return _.some(zipped, function (pair: any[][]) {
            return pair[0] !== pair[1];
         });
      }

      private generateUniqueUplinkName(existingNames: string[]): string {
         let index: number = 1;
         let name: string = this.buildUplinkName(index);

         while (_.contains(existingNames, name)) {
            index++;
            name = this.buildUplinkName(index);
         }
         return name;
      }

      private buildUplinkName(index: number): string {
         return "Uplink " + index;
      }
   }
}
