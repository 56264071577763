namespace h5_vm {
   import VirtualPCIPassthrough = com.vmware.vim.binding.vim.vm.device.VirtualPCIPassthrough;
   import VirtualPCIPassthrough$VmiopBackingInfo = com.vmware.vim.binding.vim.vm.device.VirtualPCIPassthrough$VmiopBackingInfo;
   import GuestOsDescriptor = com.vmware.vim.binding.vim.vm.GuestOsDescriptor;
   import VirtualPCIPassthroughOption = com.vmware.vim.binding.vim.vm.device.VirtualPCIPassthroughOption;
   import VirtualPCIPassthroughOption$VmiopBackingOption = com.vmware.vim.binding.vim.vm.device.VirtualPCIPassthroughOption$VmiopBackingOption;
   import PciPassthroughInfo = com.vmware.vim.binding.vim.vm.PciPassthroughInfo;
   import PciDevice = com.vmware.vim.binding.vim.host.PciDevice;
   import ConfigTarget = com.vmware.vim.binding.vim.vm.ConfigTarget;
   import VirtualPCIPassthrough$DeviceBackingInfo = com.vmware.vim.binding.vim.vm.device.VirtualPCIPassthrough$DeviceBackingInfo;
   import PciSharedGpuPassthroughInfo = com.vmware.vim.binding.vim.vm.PciSharedGpuPassthroughInfo;
   import VirtualPCIControllerOption = com.vmware.vim.binding.vim.vm.device.VirtualPCIControllerOption;

   export class PciDeviceService {
      public static $inject: string[] = ["deviceService", "i18nService", "deviceDiffService", "vmDeviceInfoService", "vmHardwareUtil"];

      constructor(private deviceService: any, private i18nService: any, private deviceDiffService: any, private vmDeviceInfoService: any, private vmHardwareUtil: any) {
      }

      public createPciDevice(env: any, virtualMachineDevices: any): { device: { _type: string, key: number } } {

         let target: ConfigTarget = env.configTarget;
         let deviceOptions = env.configOption.hardwareOptions.virtualDeviceOption;
         let powerState = env.powerState;

         let device: VirtualPCIPassthrough = new VirtualPCIPassthrough();

         // (1) check if device supported for this VM version. Minimum is VM version 7 (ESX 4.0)

         let pciOption: VirtualPCIPassthroughOption = _.find(deviceOptions, function (item: any) {
            return item.type.name === device._type;
         });

         if (!pciOption) {
            // VM version 4 does not support VirtualPCIPassthrough, throw exception to show error
            throw this.newErrorDeviceNotSupported();
         }

         let controllerOption: VirtualPCIControllerOption = _.find(deviceOptions, function (item: any) {
            return item.type.wsdlName === "VirtualPCIController";
         });

         // (2) ensure maximum not reached based on controller limit

         let max: number = 16; // safe limit in case controller option is missing

         if (controllerOption && controllerOption.numPCIPassthroughDevices) {
            // real limit - 16 since HWv11 (ESX 6.0), 6 before that
            // https://kb.vmware.com/s/article/2051652
            max = controllerOption.numPCIPassthroughDevices.max;
         }

         let count = virtualMachineDevices.findDevicesOfType("VirtualPCIPassthrough").length;

         if (count >= max) {
            throw this.newErrorDeviceLimitReached();
         }

         // (3) check if any pciPassthrough devices present

         if (!target || !target.pciPassthrough || target.pciPassthrough.length === 0) {
            throw this.newErrorNoPCIDevices();
         }

         // (4) check if hot add supported (via pciOption.plugAndPlay)

         if (powerState === "poweredOn" && !pciOption.plugAndPlay) {
            throw this.newErrorHotAddNotSupported();
         }

         // (5) Configure backing and complete device creation

         device.backing = PciDeviceService.info2backing(target.pciPassthrough[0]);
         device.key = this.deviceService.newKey();

         let editable: h5_vm.VmHwPciDevice = new VmHwPciDevice(device, this.deviceDiffService, this.vmDeviceInfoService, this.vmHardwareUtil);
         this.deviceService.setDeviceInfo(editable);

         return {
            device: editable
         };
      }

      public createPciSharedDevice(env: any, virtualMachineDevices: any): { device: { _type: string, key: number } } {

         let target: ConfigTarget = env.configTarget;
         let deviceOptions = env.configOption.hardwareOptions.virtualDeviceOption;
         let powerState = env.powerState;

         // (1) check if device option is here

         let pciOption: VirtualPCIPassthroughOption = _.find(deviceOptions, function (item: any) {
            return item.type.wsdlName === "VirtualPCIPassthrough";
         });

         if (!pciOption) {
            // VM version 4 does not support ANY PCI passthrough (both device backed and shared GPU)
            throw this.newErrorDeviceNotSupported();
         }

         // (2) check if vmiop device backing type supported

         let max = this.getVmiopMaxInstances(pciOption);

         if (max <= 0) {
            // VM version 11+ required
            throw this.newErrorDeviceNotSupported();
         }

         // (3) Check if over limit of vmiop devices with

         let allDevices: Array<VmHwPciDevice> = virtualMachineDevices.findDevicesOfType("VirtualPCIPassthrough");
         let count = this.countVmiopBacking(allDevices);

         if (count >= max) {
            throw this.newErrorDeviceLimitReached();
         }

         // (4) For running vm check if hot add supported (via pciOption.plugAndPlay)

         if (powerState === "poweredOn" && !pciOption.plugAndPlay) {
            throw this.newErrorHotAddNotSupported();
         }

         if (powerState === "suspended") {
            this.newErrorHotAddNotSupported();
         }

         let device: VirtualPCIPassthrough = new VirtualPCIPassthrough();
         let backing: VirtualPCIPassthrough$VmiopBackingInfo = new VirtualPCIPassthrough$VmiopBackingInfo();
         let vgpuList: PciSharedGpuPassthroughInfo[] = target.sharedGpuPassthroughTypes;

         backing.vgpu = (vgpuList && vgpuList.length > 0) ? vgpuList[0].vgpu : "";
         device.backing = backing;
         device.key = this.deviceService.newKey();

         let editable: h5_vm.VmHwPciDevice = new VmHwPciDevice(device, this.deviceDiffService, this.vmDeviceInfoService, this.vmHardwareUtil);
         this.deviceService.setDeviceInfo(editable);

         return {
            device: editable
         };
      }

      private countVmiopBacking(array: Array<VmHwPciDevice>): number {
         let retVal: number = 0;

         try {
            for (let item of array) {
               if (item && item.isSharedPCIBackingType()) {
                  retVal++;
               } else {
                  continue;
               }
            }
         } catch (e) {
         }
         return retVal;
      }

      private newErrorDeviceLimitReached(): any {
         return new Error(this.i18nService.getString("VmUi", "VmDeviceManager.DeviceLimitReached"));
      }

      private newErrorDeviceNotSupported(): any {
         return new Error(this.i18nService.getString("VmUi", "VmDeviceManager.DeviceNotSupported"));
      }

      private newErrorNoPCIDevices(): Error {
         return new Error(this.i18nService.getString("VmUi", "VmDeviceManager.NoPCIDevices"));
      }

      private newErrorHotAddNotSupported(): Error {
         return this.newError(this.i18nService.getString, "HotAddNotSupported");
      }

      private newError(i18n: any, key: string): Error {
         let msg: string = i18n("VmUi", "VmDeviceManager." + key);
         let retVal: Error = new Error(msg);
         return retVal;
      }


      /** Either updates backing (if not null) or creates and return new one after copying over all ids*/
      public static info2backing(info: PciPassthroughInfo, backing: VirtualPCIPassthrough$DeviceBackingInfo|null = null): VirtualPCIPassthrough$DeviceBackingInfo {
         if (backing === null) {
            backing = new VirtualPCIPassthrough$DeviceBackingInfo();
         }

         if (info === null) {
            return backing;
         }
         backing.systemId = info.systemId;
         backing.deviceName = info.pciDevice.deviceName;
         backing.deviceId = info.pciDevice.deviceId.toString(16);
         backing.id = info.pciDevice.id;
         backing.vendorId = info.pciDevice.vendorId;
         return backing;
      }

      private getVmiopMaxInstances(option: VirtualPCIPassthroughOption): Number {

         const VMIOP_TYPE: string = "com.vmware.vim.binding.vim.vm.device.VirtualPCIPassthroughOption$VmiopBackingOption";

         if (!option) {
            return 0;
         }

         var array: Array<any> = option.backingOption;

         for (let item of array) {
            let itemType: string = item._type;

            if (VMIOP_TYPE === itemType) {
               var backingOption: VirtualPCIPassthroughOption$VmiopBackingOption = item;// as VirtualPCIPassthroughOption$VmiopBackingOption;
               if (backingOption.maxInstances) {
                  return backingOption.maxInstances;
               } else {
                  return 0;
               }
            }
         }
         return 0;
      }
   }
   angular.module("com.vmware.vsphere.client.vm").service("pciSharedService", PciDeviceService);
}
