(function() {

   'use strict';
   angular.module('com.vmware.vsphere.client.vm').service('guestOsService', guestOsService);

   function guestOsService() {
      return {
         getGuestOsDescriptor: getGuestOsDescriptor,
         getSelectedGuestOs: getSelectedGuestOs,
         getAppropriateGuestOs: getAppropriateGuestOs
      };

      function getGuestOsDescriptor(vmConfigContext, gosVersion) {
         var guestOSDescriptor = vmConfigContext.environment.configOption.guestOSDescriptor;
         return _.find(guestOSDescriptor, function(osDescriptor) {
            return osDescriptor.id === gosVersion.id;
         });
      }

      function getSelectedGuestOs(vmConfigContext) {
         var guestOSDescriptor = vmConfigContext.environment.configOption.guestOSDescriptor;
         if (guestOSDescriptor.length > 1) {
            throw new Error('Expected only one guestOSDescriptor but found ' + guestOSDescriptor.length);
         }
         return guestOSDescriptor[0];
      }

      function getAppropriateGuestOs(vmConfigContext, vmVersion) {
         var guestOs;
         if (vmConfigContext.environment.configOption.guestOSDescriptor.length > 1) {
            guestOs = getGuestOsDescriptor(
                  vmConfigContext,
                  vmVersion);
         } else {
            guestOs = getSelectedGuestOs(vmConfigContext);
         }
         return guestOs;
      }
   }
}());

