/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace dvs_ui {
   import TreeListDataSource = kendo.data.TreeListDataSource;
   import Grid = kendo.ui.Grid;
   import IPromise = angular.IPromise;
   import IQService = angular.IQService;
   import HostListItemData = com.vmware.vsphere.client.dvs.api.host.HostListItemData;
   import ManagedObjectReference = com.vmware.vim.binding.vmodl.ManagedObjectReference;
   import DvsAddHostHierarchicalListPnicItem = com.vmware.vsphere.client.h5.network.dvs.addhost.hierarchicalList.DvsAddHostHierarchicalListPnicItem;
   import DvsAddHostHierarchicalListItem = com.vmware.vsphere.client.h5.network.dvs.addhost.hierarchicalList.DvsAddHostHierarchicalListItem;
   import DvsHostsSpec = com.vmware.vsphere.client.h5.network.dvs.addhost.model.DvsHostsSpec;
   import DvPortgroupModel = com.vmware.vsphere.client.h5.network.dvs.addhost.model.DvPortgroupModel;
   import DvsAddHostUplinkData = com.vmware.vsphere.client.h5.network.dvs.addhost.model.DvsAddHostUplinkData;
   import TreeListModel = kendo.data.TreeListModel;
   import DvsAddHostHierarchicalListHostItem = com.vmware.vsphere.client.h5.network.dvs.addhost.hierarchicalList.DvsAddHostHierarchicalListHostItem;
   import VmwareDistributedVirtualSwitch$LacpGroupConfig = com.vmware.vim.binding.vim.dvs.VmwareDistributedVirtualSwitch$LacpGroupConfig;

   class DvsSelectPhysicalAdaptersPageController {

      public static $inject = [
         "$q",
         "$scope",
         "i18nService",
         "dataService",
         "dvsSelectPhysicalAdaptersPageService",
         "assingUplinkDialogService",
         "unassingUplinkDialogService",
         "clarityModalService",
         "pnicSettingsDialogService",
         "defaultUriSchemeUtil",
         "networkUtil"];

      public treeListDataSource: any;

      public treeListColumns: any[];
      public actionBarOptions: any;

      // Only needed for getting access to the selected items.
      public treeListWidget: any;

      /**
       * The currently selected item (if any) in the grid. Undefined if there is no
       * selection.
       */
      private _selectedItem: DvsAddHostTreeListItemWrapper;

      private _actionsMap: any = new Object();

      private static readonly UPLINK_DATA_PROPERTY: string =
            "dvsAddHost:uplinkData";

      private static readonly PHYSICAL_ADAPTERS_PROPERTY: string =
            "dvsAddHost:hostPnicData";

      constructor(private $q: any,
                  private $wizardScope: DvsAddHostWizardScope,
                  private i18nService: any,
                  private dataService: any,
                  private dvsSelectPhysicalAdaptersPageService: DvsSelectPhysicalAdaptersPageService,
                  private assingUplinkDialogService: AssingUplinkDialogService,
                  private unassingUplinkDialogService: UnassingUplinkDialogService,
                  private clarityModalService: any,
                  private pnicSettingsDialogService: PnicSettingsDialogService,
                  private defaultUriSchemeUtil: any,
                  private networkUtil: any) {

         this.treeListWidget = null;

         this.actionBarOptions =
               this.dvsSelectPhysicalAdaptersPageService.getActionBarOptions(
                     this.onAssignUplinkClicked.bind(this),
                     this.onViewSettingsClicked.bind(this),
                     this.onUnassignUplinkClicked.bind(this));

         this.initActionsMap(this.actionBarOptions.actions, this._actionsMap);

         if (!this.$wizardScope.model.selectPhysicalAdaptersPageModel.isInitialized) {
            // Initialize physical adapters tree list with empty data provider
            // so that it is rendered while loading the data.
            this.initializePhysicalAdaptersTreeList([]);
            this.requestPageData();
         } else {
            this.initializePhysicalAdaptersTreeList(
                  this.$wizardScope.model.selectPhysicalAdaptersPageModel.hosts);
         }

         this.$wizardScope.$on("$destroy", () => {
            kendo.destroy($(".dvs-add-host-adapters-tree-list"));
         });
      }

      public updateToolbar(): void {
         if (this.treeListWidget === null) {
            return;
         }
         let row: any = this.treeListWidget.select();
         let data: any = this.treeListWidget.dataItem(row);
         this._selectedItem = data;

         if (!data) {
            // No item is selected, typically triggered by a deselection.
            // Disable all actions.
            _.each(this.actionBarOptions.actions, (action: any): void => {
               action.enabled = false;
            });
         }

         if (data && data.type) {
            let assignUplinkAction: any =
                  this._actionsMap[DvsSelectPhysicalAdaptersPageService.actionId.ASSIGN_UPLINK];
            let unassignUplinkAction: any =
                  this._actionsMap[DvsSelectPhysicalAdaptersPageService.actionId.UNASSIGN_UPLINK];
            let viewSettingsAction: any =
                  this._actionsMap[DvsSelectPhysicalAdaptersPageService.actionId.VIEW_SETTINGS];

            assignUplinkAction.enabled =
                  data.type === DvsAddHostWizardConstants.nicListItemType.PNIC_TYPE;

            viewSettingsAction.enabled =
                  data.type === DvsAddHostWizardConstants.nicListItemType.PNIC_TYPE;

            if (unassignUplinkAction) {
               unassignUplinkAction.enabled =
                     (data.type === DvsAddHostWizardConstants.nicListItemType.PNIC_TYPE
                     && this.dvsSelectPhysicalAdaptersPageService.isPnicOnThisSwitch(data));
            }
         }
      }

      public onDataBound(): void {
         if (this._selectedItem) {
            const row = this.treeListWidget.itemFor(this._selectedItem);
            this.treeListWidget.select(row);
         }
      }

      private containsModifications(itemWrapper: DvsAddHostTreeListItemWrapper): boolean {
         if (!itemWrapper) {
            return false;
         }

         if (itemWrapper.type === DvsAddHostWizardConstants.nicListItemType.PNIC_TYPE) {
            return this.dvsSelectPhysicalAdaptersPageService.isPnicModified(itemWrapper);
         } else if (itemWrapper.type ===
               DvsAddHostWizardConstants.nicListItemType.GROUPING_TYPE) {
            let childPnics: DvsAddHostTreeListItemWrapper[] =
                  this.treeListDataSource.childNodes(itemWrapper);
            return _.some(
                  childPnics, this.dvsSelectPhysicalAdaptersPageService.isPnicModified);
         } else if (itemWrapper.type ===
               DvsAddHostWizardConstants.nicListItemType.HOST_TYPE) {
            let childGroupingItems: DvsAddHostTreeListItemWrapper[] =
                  this.treeListDataSource.childNodes(itemWrapper);
            let childPnicsOnThisSwitch: DvsAddHostTreeListItemWrapper[] =
                  this.treeListDataSource.childNodes(childGroupingItems[0]);
            let childPnicsOnOtherSwitches: DvsAddHostTreeListItemWrapper[] =
                  this.treeListDataSource.childNodes(childGroupingItems[1]);
            return _.some(childPnicsOnThisSwitch, this.dvsSelectPhysicalAdaptersPageService.isPnicModified) ||
                  _.some(childPnicsOnOtherSwitches, this.dvsSelectPhysicalAdaptersPageService.isPnicModified);
         } else {
            throw new Error("Unknown item type: " + itemWrapper.type);
         }
      }

      private initActionsMap(actions: any[], actionsMap: any): void {
         _.each(actions, (action: any): void => {
            if (action && action.id) {
               actionsMap[action.id] = action;
            }
         });
      }

      private markPageDirty(): void {
         this.$wizardScope.model.selectPhysicalAdaptersPageModel.generationNumber++;
      }

      private requestPageData(): void {
         this.$wizardScope.wizardConfig.loading = true;

         let physicalAdaptersPromise: any = this.requestPhysicalAdapters();
         let uplinkDataPromise: any = this.requestUplinkData();

         this.$q.all([physicalAdaptersPromise, uplinkDataPromise])
               .then((values: any[]) => {
                        this.createHostPnicTreeModel(values[0]);

                        let uplinkData: DvsAddHostUplinkData = values[1][DvsSelectPhysicalAdaptersPageController.UPLINK_DATA_PROPERTY];
                        this.$wizardScope.model.selectPhysicalAdaptersPageModel.lags = [];
                        this.$wizardScope.model.selectPhysicalAdaptersPageModel.uplinkNames =
                              this.getAllUplinkNames(
                                    uplinkData.uplinkNames,
                                    uplinkData.lacpGroupConfigs,
                                    this.$wizardScope.model.selectPhysicalAdaptersPageModel.lags);

                        this.$wizardScope.model.selectPhysicalAdaptersPageModel.uplinkPortgroups = uplinkData.uplinkPortgroups;
                        this.$wizardScope.wizardConfig.loading = false;
                     },
                     (errors: any[]) => {
                        this.$wizardScope.wizardConfig.loading = false;
                     });
      }

      private getAllUplinkNames(uplinkNames: string[],
                                lacpConfigs: VmwareDistributedVirtualSwitch$LacpGroupConfig[],
                                lags: string[]): [string, boolean][] {

         // Add uplinks.
         let result: [string, boolean][] =
               _.map(uplinkNames, (uplinkName: string): [string, boolean] => {
                  return [uplinkName, false /*isLagPort*/];
               });

         // Add LAGs
         let lagNameToPortsMap: { [key: string]: string[] } = {};
         if (lacpConfigs && lacpConfigs.length > 0) {
            _.forEach(lacpConfigs, (lacpConfig: VmwareDistributedVirtualSwitch$LacpGroupConfig) => {
               if (lacpConfig.name && lacpConfig.uplinkName &&
                     lacpConfig.uplinkName.length > 0) {
                  _.forEach(lacpConfig.uplinkName, (uplinkName: string) => {
                     if (!lagNameToPortsMap[lacpConfig.name]) {
                        lagNameToPortsMap[lacpConfig.name] = [];
                     }
                     lagNameToPortsMap[lacpConfig.name].push(uplinkName);
                  });
               }
            });
         }

         let lagNames: string[] = _.keys(lagNameToPortsMap);

         if (lagNames.length > 0) {
            lagNames.sort();
            _.each(lagNames, (lagName: string) => {
               let lagPorts: string[] = lagNameToPortsMap[lagName];
               if (lagPorts && lagPorts.length > 0) {
                  // Add lag.
                  let lagEntry: string =
                        this.i18nService.getString(
                              "DvsUi", "SelectPhysicalAdaptersPage.lacpCategory",
                              lagName);
                  result.push([lagEntry, false /*isLagPort*/]);
                  lags.push(lagEntry);

                  // Sort ports.
                  lagPorts.sort();
                  _.each(lagPorts, (port: string) => {
                     // Add LAG port.
                     result.push([port, true /*isLagPort*/]);
                  });
               }
            });
         }

         return result;
      }

      private onAssignUplinkClicked(): void {
         if (this.$wizardScope.model.selectPhysicalAdaptersPageModel.uplinkPortgroups &&
               this.$wizardScope.model.selectPhysicalAdaptersPageModel.uplinkPortgroups.length > 0) {
            const assignUplinkComponentConfig: AssignUplinkComponentConfig =
                  new AssignUplinkComponentConfig();
            assignUplinkComponentConfig.uplinkPortgroups =
                  this.$wizardScope.model.selectPhysicalAdaptersPageModel.uplinkPortgroups;
            const selectedPnicItem: DvsAddHostHierarchicalListPnicItem =
                  this.asPnicItem(this._selectedItem);
            assignUplinkComponentConfig.uplinkMappings =
                  this.getUplinkMappings(selectedPnicItem);
            assignUplinkComponentConfig.lagNames =
                  this.$wizardScope.model.selectPhysicalAdaptersPageModel.lags;
            if (this.dvsSelectPhysicalAdaptersPageService.isPnicOnThisSwitch(selectedPnicItem)) {
               assignUplinkComponentConfig.selectedUplink =
                     selectedPnicItem.uplink;
               assignUplinkComponentConfig.selectedUplinkPortgroup =
                     _.find(assignUplinkComponentConfig.uplinkPortgroups,
                           (uplinkPortGroup: DvPortgroupModel): boolean => {
                              return uplinkPortGroup.name ===
                                    selectedPnicItem.uplinkPortgroup.name;
                           });
            }
            const isBatchAssignmentEnabled = this.hostItems().length > 1;

            const signPostConfig: ApplyToAllSignPostConfig = new ApplyToAllSignPostConfig();
            signPostConfig.applyToAllSignPostTitle =
                  this.i18nService.getString("DvsUi", "UplinkPortMappingAssignDialog.applyToAll.signPostTitle");

            const pnicDeviceName = this.asPnicItem(this._selectedItem).device;
            const hostsWithoutPnic = this.getHostItemsWithoutPnicDevice(pnicDeviceName);

            if (hostsWithoutPnic.length > 0) {
               const hostsWithoutPnicDeviceLabels =
                     hostsWithoutPnic.map((item) => item.label);

               signPostConfig.applyToAllSingPostMessage = hostsWithoutPnic.length > 3 ?
                     this.i18nService.getString(
                           "DvsUi",
                           "UplinkPortMappingAssignDialog.applyToAll.warnMessageManyHosts",
                           pnicDeviceName, hostsWithoutPnicDeviceLabels.slice(0, 3).join(", ")) :
                     this.i18nService.getString("DvsUi", "UplinkPortMappingAssignDialog.applyToAll.warnMessage",
                           pnicDeviceName, hostsWithoutPnicDeviceLabels.join(", "));

               signPostConfig.applyToAllSignPostIcon = "exclamation-triangle";

            } else {
               signPostConfig.applyToAllSingPostMessage =
                     this.i18nService.getString("DvsUi",
                           "UplinkPortMappingAssignDialog.applyToAll.infoMessage",
                           pnicDeviceName);

               signPostConfig.applyToAllSignPostIcon = "info-circle";
            }

            this.assingUplinkDialogService.show(
                  selectedPnicItem.device, assignUplinkComponentConfig,
                  isBatchAssignmentEnabled,
                  signPostConfig,
                  this.onUplinkAssigned.bind(this));
         } else {
            this.clarityModalService.openConfirmationModal({
               title: this.i18nService.getString(
                     "DvsUi", "SelectPhysicalAdaptersPage.error"),
               message: this.i18nService.getString(
                     "DvsUi", "SelectPhysicalAdaptersPage.noUplinkPortgroupsError"),
               clarityIcon: {
                  class: "is-error",
                  shape: "error-standard",
                  size: "32"
               },
               saveButtonLabel: this.i18nService.getString(
                     "Common", "alert.ok"),
               hideCancelButton: true,
               submit: () => true
            });
         }

      }

      private onUnassignUplinkClicked(): void {

         const selectedPnicItem: DvsAddHostHierarchicalListPnicItem =
               this.asPnicItem(this._selectedItem);

         let pnicsWithTheSameName: DvsAddHostHierarchicalListPnicItem[]
               = this.getPnicItemsWithName(selectedPnicItem.device);
         const indexOfSelectedItem = pnicsWithTheSameName.indexOf(selectedPnicItem);
         // Remove the selected item from the array
         pnicsWithTheSameName.splice(indexOfSelectedItem, 1);

         let isBatchUnassignmentEnabled: boolean = false;
         let multipleSelectedHostsToManage = this.$wizardScope.model.selectedHosts.length > 1;
         for (let pnicItem of pnicsWithTheSameName) {
            isBatchUnassignmentEnabled =
                  pnicItem.uplink !== "--"
                  && multipleSelectedHostsToManage;
         }

         let unassignCallBack: Function;

         if (this.dvsSelectPhysicalAdaptersPageService.isPnicAssigned(
                     this._selectedItem)) {
            unassignCallBack = this.onUnassignClicked.bind(this);
         } else {
            unassignCallBack = this.onUnclaimAdapter.bind(this);
         }

         if (isBatchUnassignmentEnabled) {
            this.unassingUplinkDialogService.show(
                  selectedPnicItem.device,
                  unassignCallBack.bind(this));
         } else {
            unassignCallBack(false);
         }

      }

      private onViewSettingsClicked(): void {
         let selectedItem: DvsAddHostHierarchicalListItem =
               this._selectedItem as DvsAddHostHierarchicalListItem;
         let pnicItem =
               selectedItem as DvsAddHostHierarchicalListPnicItem & TreeListModel;

         let groupingItem = this.treeListDataSource.parentNode(pnicItem);
         let hostItem = this.treeListDataSource
               .parentNode(groupingItem) as DvsAddHostHierarchicalListHostItem & TreeListModel;
         this.pnicSettingsDialogService.show(
               this.defaultUriSchemeUtil.getVsphereObjectId(pnicItem.hostRef), pnicItem.device, hostItem.label);
      }

      private getUplinkMappings(item: DvsAddHostHierarchicalListPnicItem): [string, string | undefined, boolean][] {
         let pnicsItemsInTheSameHost = this.getPnicItemsInTheSameHost(item);
         return _.map(this.$wizardScope.model.selectPhysicalAdaptersPageModel.uplinkNames,
               (uplinkName: [string, boolean]): [string, string | undefined, boolean] => {
                  let pnicItemAssignedToUplink = _.find(pnicsItemsInTheSameHost,
                        (pnicItem: DvsAddHostHierarchicalListPnicItem) => pnicItem.uplink === uplinkName[0]);
                  let pnicDevice: string | undefined = undefined; // Show "--".
                  if (pnicItemAssignedToUplink) {
                     pnicDevice = pnicItemAssignedToUplink.device;
                  } else if (_.contains(this.$wizardScope.model.selectPhysicalAdaptersPageModel.lags, uplinkName[0])) {
                     // Don't show "--" for LAGs.
                     pnicDevice = "";
                  }

                  return [uplinkName[0], pnicDevice, uplinkName[1]];
               });
      }

      private onUplinkAssigned(assignUplinkComponentConfig: AssignUplinkComponentConfig, applyToAll: boolean): void {
         if (assignUplinkComponentConfig.selectedUplink !== undefined
               && assignUplinkComponentConfig.selectedUplinkPortgroup !== undefined) {
            const selectedUplink: string = assignUplinkComponentConfig.selectedUplink;
            const selectedUplinkPortgroup: DvPortgroupModel = assignUplinkComponentConfig.selectedUplinkPortgroup;

            const selectedPnic = this.asPnicItem(this._selectedItem);
            if (applyToAll) {
               this.assignUplinkToPnics(
                     selectedUplink, selectedUplinkPortgroup,
                     this.getPnicItemsWithName(selectedPnic.device));
            } else {
               this.assignUplinkToPnics(
                     selectedUplink, selectedUplinkPortgroup, [selectedPnic]);
            }
         }
      }

      private getPnicItemsWithName(device: string): DvsAddHostHierarchicalListPnicItem[] {
         return this.pnicItems().filter(
               (item): boolean => item.device === device);
      }

      private getHostItemsWithoutPnicDevice(device: string): DvsAddHostHierarchicalListHostItem[] {
         const allHostsWithThisPnic: ManagedObjectReference[] =
               this.getPnicItemsWithName(device).map(
                     (pnic: DvsAddHostHierarchicalListPnicItem): ManagedObjectReference => {
                        return pnic.hostRef;
                     });
         const allHostItemsWithoutThisPnic = this.hostItems()
               .filter((item: DvsAddHostHierarchicalListHostItem): boolean => {
                  return !_.some(allHostsWithThisPnic, (hostRef: ManagedObjectReference): boolean => {
                     return _.isEqual(hostRef.value, item.hostRef.value);
                  });
               });
         return allHostItemsWithoutThisPnic;
      }

      private hostItems(): DvsAddHostHierarchicalListHostItem[] {
         return this.$wizardScope.model.selectPhysicalAdaptersPageModel.hosts
               .filter((item: DvsAddHostHierarchicalListItem): boolean => {
                  return item.type === DvsAddHostWizardConstants.nicListItemType.HOST_TYPE;
               })
               .map((item: DvsAddHostHierarchicalListItem): DvsAddHostHierarchicalListHostItem =>
                     item as DvsAddHostHierarchicalListHostItem);
      }

      private pnicItems(): DvsAddHostHierarchicalListPnicItem[] {
         return this.$wizardScope.model.selectPhysicalAdaptersPageModel.hosts
               .filter((item: DvsAddHostHierarchicalListItem): boolean => {
                  return item.type === DvsAddHostWizardConstants.nicListItemType.PNIC_TYPE;
               })
               .map((item: DvsAddHostHierarchicalListItem): DvsAddHostHierarchicalListPnicItem =>
                     item as DvsAddHostHierarchicalListPnicItem);
      }

      private freeUpUplink(uplink: string,
                           contextPnic: DvsAddHostHierarchicalListPnicItem): void {
         const pnicItem = _.find(this.getPnicItemsInTheSameHost(contextPnic),
               (item: DvsAddHostHierarchicalListPnicItem): boolean =>
               item.uplink === uplink);
         if (pnicItem && pnicItem !== contextPnic) {
            this.updatePnicItem(pnicItem,
                  DvsAddHostWizardConstants.AUTO_ASSIGN, pnicItem.uplinkPortgroup);
         }
      }

      private assignUplinkToPnics(selectedUplink: string,
                                  selectedUplinkPortgroup: DvPortgroupModel,
                                  pnics: DvsAddHostHierarchicalListPnicItem[]) {
         _.each(pnics, (pnicItem: DvsAddHostHierarchicalListPnicItem): void => {
            this.freeUpUplink(selectedUplink, pnicItem);

            this.updatePnicItem(pnicItem, selectedUplink, selectedUplinkPortgroup);
         });

         this.updateDataSource(pnics);

         // Mark page dirty after user interaction.
         this.markPageDirty();

         this.$wizardScope.model.selectPhysicalAdaptersPageModel.hosts =
               this.treeListDataSource.data() as DvsAddHostTreeListItemWrapper[];
      }

      private updateListOfItems(pnicItems: DvsAddHostHierarchicalListPnicItem[]): DvsAddHostTreeListItemWrapper[] {

         let allListItems: DvsAddHostTreeListItemWrapper[] =
               angular.copy(this.$wizardScope.model.selectPhysicalAdaptersPageModel.hosts, []);

         for (const pnicItem of pnicItems) {
            const itemIndexToRemove: number = _.findIndex(allListItems, (itemWrapper: DvsAddHostTreeListItemWrapper): boolean => {
               return itemWrapper.id === pnicItem.id;
            });

            if (itemIndexToRemove >= 0) {
               allListItems.splice(itemIndexToRemove, 1);
            }
         }

         for (const pnicItem of pnicItems) {
            let pnicAdded: boolean = false;
            for (let index = 0; index < allListItems.length; index++) {
               const itemWarpper = allListItems[index];

               if (itemWarpper.type === DvsAddHostWizardConstants.nicListItemType.PNIC_TYPE) {

                  let itemWrapperAsPnic: DvsAddHostHierarchicalListPnicItem =
                        this.asPnicItem(itemWarpper);

                  if (itemWrapperAsPnic.hostRef.value === pnicItem.hostRef.value) {
                     if (itemWrapperAsPnic.orderInList >  pnicItem.orderInList) {
                        allListItems.splice(index, 0, this.asWrapper(pnicItem));
                        pnicAdded = true;
                        break;
                     }
                  }
               }
            }

            // If the pnic has not been added, there are no pnic items at the given host at the moment
            // Push the item in the array, it will go under the expected parent based on parentId
            if (!pnicAdded) {
               allListItems.push(this.asWrapper(pnicItem));
            }
         }

         return allListItems;
      }

      private updatePnicItem(pnicItem: DvsAddHostHierarchicalListPnicItem,
                             uplink: string,
                             uplinkPortgroup: DvPortgroupModel) {
         let originalPnicItem: DvsAddHostHierarchicalListPnicItem =
               this.asWrapper(pnicItem).originalItem as DvsAddHostHierarchicalListPnicItem;
         let pnicAssignmentStatus: String =
               "SelectPhysicalAdaptersPage.adapterState.assigned";
         if (this.dvsSelectPhysicalAdaptersPageService.isPnicOnThisSwitch(originalPnicItem)) {
            // Reassign to potentially new uplink/uplink port group.
            if (uplink === originalPnicItem.uplink &&
                  uplinkPortgroup.name === originalPnicItem.uplinkPortgroup.name) {
               // Reassigned to its original values.
               this.unassignAdapter(this.asWrapper(pnicItem));
               return;
            } else {
               // Reassigned to a new uplink/uplink port group.
               pnicAssignmentStatus =
                     "SelectPhysicalAdaptersPage.adapterState.reassigned";
            }
         }

         pnicItem.label = this.i18nService.getString(
               "DvsUi",
               "SelectPhysicalAdaptersPage.displayName.formatter",
               originalPnicItem.label,
               this.i18nService.getString("DvsUi", pnicAssignmentStatus));
         pnicItem.parentId = pnicItem.onThisSwitchItemId;
         pnicItem.uplinkPortgroup.key = uplinkPortgroup.key;
         pnicItem.uplinkPortgroup.name = uplinkPortgroup.name;
         pnicItem.uplink = uplink;
      }

      private onUnassignClicked(applyToAll: boolean): void {

         if (this._selectedItem.type ===
               DvsAddHostWizardConstants.nicListItemType.PNIC_TYPE) {

            let pnicItems: DvsAddHostHierarchicalListPnicItem[] = [];
            const selectedPnic: DvsAddHostHierarchicalListPnicItem =
                  this.asPnicItem(this._selectedItem);

            if (applyToAll) {
               const pnicItemsWithName: DvsAddHostHierarchicalListPnicItem[] =
                     this.getPnicItemsWithName(selectedPnic.device);

               _.each(pnicItemsWithName, (pnicItem: DvsAddHostHierarchicalListPnicItem): void => {
                  this.unassignAdapter(this.asWrapper(pnicItem));
                  pnicItems.push(pnicItem);
               });
            } else {
               this.unassignAdapter(this._selectedItem);
               pnicItems.push(selectedPnic);
            }

            this.updateDataSource(pnicItems);
         }

         // Mark page dirty after user interaction.
         this.markPageDirty();

         this.$wizardScope.model.selectPhysicalAdaptersPageModel.hosts =
               this.treeListDataSource.data();
      }

      private unassignAdapter(pnicItemWrapper: DvsAddHostTreeListItemWrapper): void {

         if (!this.dvsSelectPhysicalAdaptersPageService.isPnicModified(pnicItemWrapper)) {
            return;
         }

         let originalPnicItem: DvsAddHostHierarchicalListPnicItem =
               pnicItemWrapper.originalItem as DvsAddHostHierarchicalListPnicItem;
         let updatedItem: DvsAddHostHierarchicalListItem =
               pnicItemWrapper as DvsAddHostHierarchicalListItem;
         let updatedPnicItem: DvsAddHostHierarchicalListPnicItem =
               updatedItem as DvsAddHostHierarchicalListPnicItem;

         if (this.dvsSelectPhysicalAdaptersPageService.isPnicOriginallyOnThisSwitch(
                     pnicItemWrapper)) {
            this.freeUpUplink(originalPnicItem.uplink, updatedPnicItem);
         }

         updatedPnicItem.parentId = originalPnicItem.parentId;
         updatedPnicItem.label = originalPnicItem.label;
         updatedPnicItem.uplink = originalPnicItem.uplink;
         updatedPnicItem.uplinkPortgroup.key = originalPnicItem.uplinkPortgroup.key;
         updatedPnicItem.uplinkPortgroup.name = originalPnicItem.uplinkPortgroup.name;
      }

      private onUnclaimAdapter(applyToAll: boolean): void {

         let pnicItems: DvsAddHostHierarchicalListPnicItem[] = [];
         const selectedPnic: DvsAddHostHierarchicalListPnicItem =
               this.asPnicItem(this._selectedItem);

         if (applyToAll) {
            const pnicItemsWithName: DvsAddHostHierarchicalListPnicItem[] =
                  this.getPnicItemsWithName(selectedPnic.device);

            _.each(pnicItemsWithName, (pnicItem: DvsAddHostHierarchicalListPnicItem): void => {
               let pnicAsWrapper: DvsAddHostTreeListItemWrapper = this.asWrapper(pnicItem);
               let originalItem: DvsAddHostHierarchicalListPnicItem =
                     pnicAsWrapper.originalItem as DvsAddHostHierarchicalListPnicItem;
               this.unclaimAdapter(originalItem, pnicItem);
               pnicItems.push(pnicItem);
            });

         } else {
            let originalPnicItem: DvsAddHostHierarchicalListPnicItem =
                  this._selectedItem.originalItem as DvsAddHostHierarchicalListPnicItem;
            const pnicItem: DvsAddHostHierarchicalListPnicItem =
                  this.asPnicItem(this._selectedItem);

            this.unclaimAdapter(originalPnicItem, pnicItem);
            pnicItems.push(pnicItem);
         }

         this.updateDataSource(pnicItems);

         // Mark page dirty after user interaction.
         this.markPageDirty();

         this.$wizardScope.model.selectPhysicalAdaptersPageModel.hosts =
               this.treeListDataSource.data() as DvsAddHostTreeListItemWrapper[];
      }

      private unclaimAdapter(originalPnicItem: DvsAddHostHierarchicalListPnicItem,
                             pnicItem: DvsAddHostHierarchicalListPnicItem) {

         pnicItem.parentId = originalPnicItem.onOtherSwitchesItemId;
         pnicItem.label = this.i18nService.getString(
               "DvsUi",
               "SelectPhysicalAdaptersPage.displayName.formatter",
               originalPnicItem.label,
               this.i18nService.getString(
                     "DvsUi", "SelectPhysicalAdaptersPage.adapterState.unassigned"));
         pnicItem.uplink = this.i18nService.getString(
               "DvsUi",
               "SelectPhysicalAdaptersPage.noUplink");

         // Creating a new instance to nullify the uplink port group key.
         pnicItem.uplinkPortgroup = new DvPortgroupModel();
         pnicItem.uplinkPortgroup.name = this.i18nService.getString(
               "DvsUi",
               "SelectPhysicalAdaptersPage.noUplinkPortgroupLabel");
      }

      private updateDataSource(pnicItems: DvsAddHostHierarchicalListPnicItem[]) {
         const selectedItemId = this._selectedItem.id;

         const updatedListItems: DvsAddHostTreeListItemWrapper[]
               = this.updateListOfItems(pnicItems);

         this.treeListDataSource = new kendo.data.TreeListDataSource({
            data: updatedListItems,
            schema: this.dvsSelectPhysicalAdaptersPageService.getTreeListSchema()
         });
         this.treeListDataSource.read();

         this._selectedItem = _.filter(updatedListItems, (item: DvsAddHostTreeListItemWrapper) => {
            return item.id === selectedItemId;
         })[0];
      }

      private requestUplinkData(): any {
         return this.dataService.getProperties(
               this.$wizardScope.model.dvsId,
               DvsSelectPhysicalAdaptersPageController.UPLINK_DATA_PROPERTY);
      }

      private requestPhysicalAdapters(): any {
         let hostRefs: ManagedObjectReference[] =
               _.pluck(this.$wizardScope.model.selectedHosts, "hostRef");

         let dvsHostSpec: DvsHostsSpec = new DvsHostsSpec();
         dvsHostSpec.hosts = hostRefs;
         dvsHostSpec.areMemberHosts =
               this.$wizardScope.model.selectOperationPageModel.selectedOperationType ===
               DvsAddHostWizardConstants.operationType.MANAGE_HOST ||
               this.$wizardScope.model.selectOperationPageModel.selectedOperationType ===
               DvsAddHostWizardConstants.operationType.MIGRATE_NETWORKING;

         let params = {
            propertyParams: [{
               propertyName: DvsSelectPhysicalAdaptersPageController.PHYSICAL_ADAPTERS_PROPERTY,
               parameterType: dvsHostSpec._type,
               parameter: dvsHostSpec
            }]
         };

         let physicalAdaptersPromise: any = this.dataService.getProperties(
               this.$wizardScope.model.dvsId,
               [DvsSelectPhysicalAdaptersPageController.PHYSICAL_ADAPTERS_PROPERTY],
               params);

         return physicalAdaptersPromise;

      }

      private createHostPnicTreeModel(hostPhysicalAdaptersData: any): void {
         if (hostPhysicalAdaptersData) {
            let hostDataArray: DvsAddHostHierarchicalListItem[] =
                  hostPhysicalAdaptersData[
                        DvsSelectPhysicalAdaptersPageController.PHYSICAL_ADAPTERS_PROPERTY];

            let dataProvider: DvsAddHostTreeListItemWrapper[] =
                  _.map(hostDataArray, this.mapListItemToWrapper);

            this.initializePhysicalAdaptersTreeList(dataProvider);

            this.$wizardScope.model.selectPhysicalAdaptersPageModel.isInitialized = true;
         }
      }

      private mapListItemToWrapper(item: DvsAddHostHierarchicalListItem): DvsAddHostTreeListItemWrapper {
         let wrapperItem: DvsAddHostTreeListItemWrapper =
               item as DvsAddHostTreeListItemWrapper;
         wrapperItem.originalItem =
               angular.copy(item, new DvsAddHostHierarchicalListItem());
         let pnicItem: DvsAddHostHierarchicalListPnicItem =
               item as DvsAddHostHierarchicalListPnicItem;
         let uplinkPortgroupCopy: DvPortgroupModel =
               angular.copy<DvPortgroupModel>(pnicItem.uplinkPortgroup,
                     new DvPortgroupModel());
         let originalPnicItem: DvsAddHostHierarchicalListPnicItem =
               wrapperItem.originalItem as DvsAddHostHierarchicalListPnicItem;
         originalPnicItem.uplinkPortgroup = uplinkPortgroupCopy;
         return wrapperItem;
      }

      private initializePhysicalAdaptersTreeList(data: DvsAddHostTreeListItemWrapper[]) {
         this.treeListDataSource = new kendo.data.TreeListDataSource({
            data: data,
            schema: this.dvsSelectPhysicalAdaptersPageService.getTreeListSchema()
         });
         this.treeListDataSource.read();
         this.treeListColumns = this.dvsSelectPhysicalAdaptersPageService.getColumnDefs();

         this.$wizardScope.model.selectPhysicalAdaptersPageModel.hosts =
               this.treeListDataSource.data();
      }

      private getPnicItemsInTheSameHost(item: DvsAddHostHierarchicalListPnicItem): DvsAddHostHierarchicalListPnicItem[] {
         let isPnicItemInTheSameHostPredicate =
               (listItem: DvsAddHostHierarchicalListItem): boolean => {
                  return listItem.type === DvsAddHostWizardConstants.nicListItemType.PNIC_TYPE
                        && _.isEqual(item.hostRef.value,
                              (listItem as DvsAddHostHierarchicalListPnicItem).hostRef.value);
               };

         return _.filter(this.$wizardScope.model.selectPhysicalAdaptersPageModel.hosts,
               isPnicItemInTheSameHostPredicate) as DvsAddHostHierarchicalListPnicItem[];
      }

      private asPnicItem(listItem: DvsAddHostHierarchicalListItem): DvsAddHostHierarchicalListPnicItem {
         let pnicItem: DvsAddHostHierarchicalListPnicItem =
               listItem as DvsAddHostHierarchicalListPnicItem;
         return pnicItem;
      }

      private asWrapper(listItem: DvsAddHostHierarchicalListItem): DvsAddHostTreeListItemWrapper {
         return listItem as DvsAddHostTreeListItemWrapper;
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .controller("DvsSelectPhysicalAdaptersPageController",
               DvsSelectPhysicalAdaptersPageController);
}
