(function () {
   'use strict';

   angular.module('com.vmware.vsphere.client.cluster')
      .service('haApdResponseService', haApdResponseService);

   haApdResponseService.$inject = ['i18nService', 'haConstants'];

   function haApdResponseService(i18nService, haConstants) {

      var apdResponseLabels = {};
      var apdResponseDescriptions = {};

      apdResponseLabels[haConstants.storageVmReaction.DISABLED] =
         getString('ha.config.failures.responses.ds.dswithapd.disabled');
      apdResponseLabels[haConstants.storageVmReaction.WARNING] =
         getString('ha.config.failures.responses.ds.dswithapd.issue.events');
      apdResponseLabels[haConstants.storageVmReaction.RESTART_CONSERVATIVE] =
         getString('ha.config.failures.responses.ds.dswithapd.conservative');
      apdResponseLabels[haConstants.storageVmReaction.RESTART_AGGRESSIVE] =
         getString('ha.config.failures.responses.ds.dswithapd.aggressive');

      apdResponseDescriptions[haConstants.storageVmReaction.DISABLED] =
         getString('ha.config.failures.responses.ds.dswithapd.disabled.desc');
      apdResponseDescriptions[haConstants.storageVmReaction.WARNING] =
         getString('ha.config.failures.responses.ds.dswithapd.issue.events.desc');
      apdResponseDescriptions[haConstants.storageVmReaction.RESTART_CONSERVATIVE] =
         getString('ha.config.failures.responses.ds.dswithapd.conservative.desc');
      apdResponseDescriptions[haConstants.storageVmReaction.RESTART_AGGRESSIVE] =
         getString('ha.config.failures.responses.ds.dswithapd.aggressive.desc');

      function getString(key) {
         return i18nService.getString('ClusterUi', key);
      }

      function getResponses() {
         return _.map(haConstants.storageVmReaction, function(reaction) {
            return {
               id: reaction,
               label: apdResponseLabels[reaction],
               description: apdResponseDescriptions[reaction]
            };
         });
      }

      return {
         getResponses: getResponses
      };
   }
})();
