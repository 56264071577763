module ds_cluster_ui {

   import IPromise = angular.IPromise;

   export class AddSdrsVmOverridesService {

      public static $inject = ["i18nService", "vuiConstants",
         "columnRenderersRegistry", "dsClusterConstants", "defaultUriSchemeUtil",
         "AddVmToVmOverridesManager", "addVmOverridesWizardService"];

      constructor(private i18nService: any,
                  private vuiConstants: any,
                  private columnRenderersRegistry: any,
                  private dsClusterConstants: any,
                  private defaultUriSchemeUtil: any,
                  private AddVmToVmOverridesManager: new () => AddVmToVmOverridesManager,
                  private addVmOverridesWizardService: AddVmOverridesWizardService) {
      }

      public openAddVmOverridesWizard(targetUid: string) : void {
         let manager: AddVmToVmOverridesManager = new this.AddVmToVmOverridesManager();
         this.addVmOverridesWizardService.invokeWizard(targetUid, manager);
      }

      public buildVuiSignPostSdrsAutomationLevel(selectedVms: any) {
         let title: string =
               this.i18nService.getString("DsClusterUi",
                     "vmOverrides.config.editMultiple.signPost.title");

         let messages: any = [];
         _.each(selectedVms, (selectedVm: any) => {
            messages.push(selectedVm.vm.name + " - " + selectedVm.sdrsAutomationLevelText);
         });

         return {
            title: title,
            message: messages.join("<br>")
         };
      }

      public buildVuiSignPostIntraVmAffinityLevel(selectedVms: any) {
         let title: string =
               this.i18nService.getString("DsClusterUi",
                     "vmOverrides.config.editMultiple.signPost.title");

         let messages: any = [];
         _.each(selectedVms, (selectedVm: any) => {
            messages.push(selectedVm.vm.name + " - " + selectedVm.intraVmAffinityText);
         });

         return {
            title: title,
            message: messages.join("<br>")
         };
      }

      public getEditMultipleVmOverridesIdentityResults(vms: any): Object {

         let uniqueIntraVmAffinityValues: any = _.uniq(vms, false, (item: any) => {
            return item.rawData.intraVmAffinity;
         });

         let uniqueSdrsAutomationLevels: any = _.uniq(vms, false, (item: any) => {
            return [item.rawData.vmAutomationLevel,
                  item.rawData.vmAutomationLevelEnabled,
                  item.rawData.defaultAutomationLevel].join();
         });

         let vmsAreWithEqualIntraVmAffinity: boolean =
               uniqueIntraVmAffinityValues.length === 1;

         let vmsAreWithEqualSdrsAutomationLevel: boolean =
               uniqueSdrsAutomationLevels.length === 1;

         return {
            vmsAreWithEqualIntraVmAffinity: vmsAreWithEqualIntraVmAffinity,
            vmsAreWithEqualSdrsAutomationLevel: vmsAreWithEqualSdrsAutomationLevel
         };
      }

      public getSdrsVmOverridesGridOptions(data: any): Object {
         return {
            actionBarOptions: {
               actions: []
            },
            searchable: false,
            resizable: true,
            data: data,
            selectionMode: this.vuiConstants.grid.selectionMode.SINGLE,
            selectedItems: [],
            columnDefs: [{
               displayName: this.i18nService.getString(
                     "DsClusterUi", "vmOverrides.vmNameHeaderText"),
               field: "rawData.vmName",
               template: this.vmNameRenderer.bind(this)
            }],
            pageConfig: {
               hidePager: true
            }
         };
      }

      public getSplitterOptions(): Object {
         return {
            panes: [
               {
                  min: '150px',
                  size: '25%'
               }, {
                  min: '150px',
                  size: '75%'
               }
            ],
            orientation: this.vuiConstants.splitter.orientation.HORIZONTAL
         };
      }


      private vmNameRenderer(data: any): string {
         let renderer = this.columnRenderersRegistry.getColumnRenderer('icon-text');
         let renderedColumnValue = renderer(['icon', 'name'], data);
         return renderedColumnValue;
      }

   }

   angular.module("com.vmware.vsphere.client.dsCluster")
         .service("addSdrsVmOverridesService", AddSdrsVmOverridesService);
}
