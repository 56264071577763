namespace license_ui {

   import IController = angular.IController;

   class LicenseDetailsProductsTabController implements IController {

      public static $inject = ["$scope", "licensesManagementNavigationService", "licensingRouteObjectsFactoryService"];

      constructor(private $scope: any,
                  private licensesManagementNavigationService: LicensesManagementNavigationService,
                  private licensingRouteObjectsFactoryService: LicensingRouteObjectsFactoryService) {
      }

      onSelectedProductChanged(productItem: any): void {
         if (productItem && productItem.product && productItem.product.uri) {
            const productsListRouteObject: LicensingRouteObject = this.licensingRouteObjectsFactoryService
                  .createProductsListLicensingRouteObject(productItem.product.uri);
            this.licensesManagementNavigationService
                  .emitLicensesManagementNavigationRequestOnScope(this.$scope, productsListRouteObject);
         }
      }
   }

   angular.module("com.vmware.vsphere.client.licenseUi")
         .controller("LicenseDetailsProductsTabController", LicenseDetailsProductsTabController);
}