/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace network_ui {

   export class EditVirtualSwitchPropertiesPageComponent {

      public bindings: any;
      public controller: any;
      public templateUrl: string;

      constructor() {
         this.bindings = {
            pageModel: "<"
         };

         this.controller = EditVirtualSwitchPropertiesPageComponentController;

         this.templateUrl = "network-ui/resources/network/views/host/vswitch/edit/" +
               "views/editVirtualSwitchPropertiesPageComponent.html";
      }
   }

   class EditVirtualSwitchPropertiesPageComponentController {

      static $inject = [
         "i18nService",
         "$scope",
         "networkUiConstants",
         "networkUtil"
      ];

      public pageModel: PropertiesPageModel;
      private mtuValidator: any;

      constructor(public i18nService: any,
                  private $scope: any,
                  private networkUiConstants: any,
                  private networkUtil: any) {
      }

      $onInit(): void {
         this.pageModel.portNumbers = this.getPortNumbers();
         this.mtuValidator = {
            message: this.i18nService.getString("H5NetworkUi", "VSwitchPropertiesPage.mtu.errorMessage",
                  this.networkUiConstants.Mtu.MIN, this.networkUiConstants.Mtu.MAX),
            isVisible: false
         };
      }

      private getPortNumbers() {
         let portNumbers = [this.networkUiConstants.NumPorts.MIN];
         while (_.last(portNumbers) < this.networkUiConstants.NumPorts.MAX) {
            portNumbers.push(_.last(portNumbers) * 2);
         }
         return portNumbers;
      }

      private validateMtu() {
         this.mtuValidator.isVisible = !this.networkUtil.isMtuValid(this.pageModel.mtu);
      }
   }

   angular.module("com.vmware.vsphere.client.network")
         .component("editVirtualSwitchPropertiesPage",
               new EditVirtualSwitchPropertiesPageComponent());
}
