namespace h5_vm {
   import VmConfigContext = com.vmware.vsphere.client.vm.config.VmConfigContext;
   import VirtualDevice = com.vmware.vim.binding.vim.vm.device.VirtualDevice;
   import UsbInfo = com.vmware.vim.binding.vim.vm.UsbInfo;
   import VirtualUSB$RemoteClientBackingInfo = com.vmware.vim.binding.vim.vm.device.VirtualUSB$RemoteClientBackingInfo;
   import Description = com.vmware.vim.binding.vim.Description;
   import VirtualDevice$DeviceBackingInfo = com.vmware.vim.binding.vim.vm.device.VirtualDevice$DeviceBackingInfo;

   export class VmHardwareUsbDeviceUtil {

      private static readonly clientUsb: string = new VirtualUSB$RemoteClientBackingInfo()._type;


      public static getUsbTargetDescription(rawDevice: VirtualDevice, vmConfigContext: VmConfigContext): string {

         if (!rawDevice || !rawDevice.backing) {
            return "";
         }

         let backing: any = rawDevice.backing;
         let deviceInfo: Description = rawDevice.deviceInfo;

         if (backing._type === VmHardwareUsbDeviceUtil.clientUsb) {
            return deviceInfo.summary;
         }

         let info: UsbInfo = VmHardwareUsbDeviceUtil.getTargetUsbInfo(rawDevice, vmConfigContext);

         if (info) {
            return info.description;
         }

         let deviceBacking: VirtualDevice$DeviceBackingInfo = backing;

         return deviceBacking.deviceName;
      }

      public static getTargetUsbInfo(rawDevice: VirtualDevice, vmConfigContext: VmConfigContext): any {

         if (!rawDevice || !rawDevice.backing) {
            return null;
         }

         if (!vmConfigContext) {
            return null;
         }

         let backing: any = rawDevice.backing;

         if (!backing.hasOwnProperty("deviceName")) {
            return null; // client usb backing
         }

         let deviceName: string = backing['deviceName'];

         let usbTargets: any = vmConfigContext.environment.configTarget.usb;

         let info: UsbInfo = _.find(usbTargets, function (info: any) {
            return (info && info.physicalPath === deviceName);
         });

         if (info) {
            return info;
         }

         return null;
      }


      public static getUSBUniqueID(rawDevice: VirtualDevice): any {

         if (!rawDevice || !rawDevice.backing) {
            return "";
         }

         let backing: any = rawDevice.backing;

         if (!backing.hasOwnProperty("deviceName")) {
            return ""; // client usb backing
         }

         let hostname = !backing.hasOwnProperty("hostname")
               ? "localhost" // local backing
               : backing["hostname"]; // remote backing

         let deviceName: string = backing['deviceName'];

         return "host: " + hostname + " device: " + deviceName;
      }
   }
}


