/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace network_ui {

   export class EditVirtualSwitchTrafficShapingPageComponent {

      public bindings: any;
      public templateUrl: string;
      public controller: any;

      constructor() {
         this.bindings = {
            pageModel: "<"
         };

         this.templateUrl = "network-ui/resources/network/views/host/vswitch/edit/" +
               "views/editVirtualSwitchTrafficShapingPageComponent.html";

         this.controller = EditVirtualSwitchTrafficShapingPageComponentController;
      }
   }

   class EditVirtualSwitchTrafficShapingPageComponentController {

      static $inject = [
            "i18nService",
            "$scope",
            "trafficShapingPolicyValidator",
            "networkUtil",
            "networkUiConstants"
      ];

      public pageModel: TrafficShapingPageModel;
      private options: any[];
      private minValue: number;
      private validators: any;

      constructor(public i18nService: any,
                  private $scope: any,
                  private trafficShapingPolicyValidator: any,
                  private networkUtil: any,
                  private networkUiConstants: any) {
         this.options = this.getOptions();
         this.minValue = 1;

         this.validators = {
            averageBandwidth: {
               message: "",
               isVisible: false
            },
            peakBandwidth: {
               message: "",
               isVisible: false
            },
            burstSize: {
               message: "",
               isVisible: false
            }
         };
      }

      private getOptions(): any {
         let options: any[] = [{
            label: this.i18nService.getString(
                  "NetworkUi", "PortTrafficShapingPolicyView.statusOn"),
            val: true
         }, {
            label: this.i18nService.getString(
                  "NetworkUi", "PortTrafficShapingPolicyView.statusOff"),
            val: false
         }];

         return options;
      }

      private onStatusChange() {
         this.validateTrafficShaping(
               "averageBandwidth",
               this.pageModel.averageBandwidth,
               this.validators.averageBandwidth);
         this.validateTrafficShaping(
               "peakBandwidth",
               this.pageModel.peakBandwidth,
               this.validators.peakBandwidth);
         this.validateTrafficShaping(
               "burstSize",
               this.pageModel.burstSize,
               this.validators.burstSize);
      }

      private validateTrafficShaping(attributeName: string,
            value: number, validator: any): void {

         let message = this.trafficShapingPolicyValidator.getTrafficShapingErrorMessage(
               value, attributeName);

         validator.message = message;
         validator.isVisible = this.pageModel.enabled && message !== null;
      };

      private onTrafficShapingChange(attributeName: string): void {
         switch (attributeName) {
            case "averageBandwidth": {
               this.validateTrafficShaping(
                     "averageBandwidth",
                     this.pageModel.averageBandwidth,
                     this.validators.averageBandwidth);
               break;
            }
            case "peakBandwidth": {
               this.validateTrafficShaping(
                     "peakBandwidth",
                     this.pageModel.peakBandwidth,
                     this.validators.peakBandwidth);
               break;
            }
            case "burstSize": {
               this.validateTrafficShaping(
                     "burstSize",
                     this.pageModel.burstSize,
                     this.validators.burstSize);
               break;
            }
         }
      };
   }

   angular.module("com.vmware.vsphere.client.network")
         .component("editVirtualSwitchTrafficShapingPage",
               new EditVirtualSwitchTrafficShapingPageComponent());
}
