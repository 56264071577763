(function () {
   'use strict';

   angular
         .module('com.vmware.vsphere.client.networkLibUi')
         .directive('pnicSelectorWithDetails', pnicSelectorWithDetails);

   pnicSelectorWithDetails.$inject = [
      'vuiConstants',
      'physicalAdapterDetailsViewService'
   ];

   function pnicSelectorWithDetails(vuiConstants,physicalAdapterDetailsViewService) {
      return {
         restrict: 'E',
         templateUrl: 'network-lib-ui/resources/network-lib/host/pnic/' +
               'pnicSelector/pnicSelectorWithDetails.html',
         scope: {
            pnicSelectorOptions: '=?'
         },
         replace: false,
         link: {
            pre: function ($scope) {
               $scope.datagridHeaderOptions = $scope.pnicSelectorOptions.datagridHeaderOptions;
               $scope.datagridOptions =
                     _createDatagridOptions($scope.pnicSelectorOptions.unclaimedPnics,
                           $scope.pnicSelectorOptions.selectionMode);

               $scope.$watch(function() {
                  return $scope.datagridOptions.selectedItems;
               },
                     function(selectedItems) {
                        if (selectedItems && selectedItems.length === 1) {
                           var selectedPnicDetaisData
                                 = $scope.pnicSelectorOptions.pnicDetails[selectedItems[0]];
                           $scope.selectedPnicDetails = physicalAdapterDetailsViewService.build(
                                 selectedPnicDetaisData);
                        } else {
                           $scope.selectedPnicDetails = null;
                        }

                        if($scope.pnicSelectorOptions.updateSelectedPnics) {
                           $scope.pnicSelectorOptions.updateSelectedPnics(selectedItems);
                        }
                     });

            }
         }
      };

      function _createDatagridOptions(unclaimedPnics, selectionMode) {
         var datagridOptions = {
            columnDefs: _getColumnDefinitions(),
            data: unclaimedPnics,
            pageConfig: {
               hidePager: true
            },
            selectedItems: [],
            resizable: true,
            height: '100%',
            sortMode: vuiConstants.grid.sortMode.NONE,
            selectionMode: selectionMode,
            showCheckboxesOnMultiSelection: false,
            sortOrder: [{
               field: 'pnic',
               dir: 'asc'
            }]

         };

         return datagridOptions;
      }
      function _getColumnDefinitions() {
         return [
            {
               field: 'pnic',
               width: '150px',
               template: function (dataItem) {
                  return '<span class="network-lib-ui-icon-physicalAdapter"></span>' +  // icon
                        '<span>' + dataItem + '</span>';                            // name

               }
            }
         ];
      }
   }
})();