module cluster_ui {

   import IController = angular.IController;

   class DrsMemoryUtilizationComponentController implements IController {
      static $inject = ["$rootScope", "i18nService", "defaultUriSchemeUtil", "clusterConstants"];

      private memoryUtilizationTypes: any;

      constructor(private $rootScope: any, private i18nService: any,
                  private defaultUriSchemeUtil: any,
                  private clusterConstants: any) {
         this.memoryUtilizationTypes = this.clusterConstants.memoryUtilizationTypes;
      }

      public noMemoryOnVms: string =
            this.getString("drs.utilization.histogram.barNoMemoryUsageLabel");
      public signPostParams: any = {
         templateUrl:
               "cluster-ui/resources/cluster/views/monitor/drs/memoryVmBarTooltipContent.html",
         width: 300,
         height: 200,
         direction: "up",
         "class": "drs-utilization-info-tooltip"
      };

      public goToSelectedHostLink(host: any): void {
         let selectedHostObjectId: string =
               this.defaultUriSchemeUtil.getVsphereObjectId(host.provider);
         this.$rootScope._navigateToObject(selectedHostObjectId);
      }

      public buildSignPostData(vm: any): void {
         this.signPostParams.title = vm.name;
         this.signPostParams.model = vm;
      }

      public getString(key: string, ...params: any[]  ): string {
         return this.i18nService.getString("ClusterUi", key, params);
      }

      public getBarWidthForVm(vm: any, memoryUtilizationType: string): any {
         return memoryUtilizationType === this.memoryUtilizationTypes.CONSUMED ?
               vm.consumedMemoryBarWidth : vm.activeMemoryBarWidth;
      }
   }

   class DrsMemoryUtilizationComponent implements ng.IComponentOptions {
      public controller: any;
      public templateUrl: string;
      public bindings: any;

      constructor() {
         this.controller = DrsMemoryUtilizationComponentController;
         this.templateUrl =
               "cluster-ui/resources/cluster/views/monitor/drs/drsMemoryUtilizationComponent.html";
         this.bindings = {
            data: "<"
         };
      }
   }

   angular.module("com.vmware.vsphere.client.cluster").component(
         "drsMemoryUtilizationComponent", new DrsMemoryUtilizationComponent());
}
