(function() {
   'use strict';

   /**
    * Factory that handles HA edit related operations.
    * Builds all the edit HA dialog page models and submits the edited HA data to the
    * server.
    */
   angular.module('com.vmware.vsphere.client.cluster')
         .factory('HaDialogManager', HaDialogManager);

   HaDialogManager.$inject = ['vuiConstants', 'haConstants', 'i18nService',
      'clusterSpecBuilderService', 'mutationService', 'advancedOptionsBuilderFactory',
      'haAdmissionControlService', '$q', 'clarityModalService', 'clusterConstants'];

   function HaDialogManager(vuiConstants, haConstants, i18nService,
         clusterSpecBuilderService, mutationService, advancedOptionsBuilderFactory,
         haAdmissionControlService, $q, clarityModalService, clusterConstants) {

      return function(haData, haDialogOptions) {
         var EDIT_CLUSTER_SPEC =
               'com.vmware.vsphere.client.cluster.ClusterComputeResourceSpec';

         var failuresAndResponsesDef, heartbeatDatastoresDef, advancedOptionsDef,
               admissionControlDef;
         createPages();

         return {
            getPageDefinitions: getPageDefinitions,
            getFailuresAndResponsesModel: getFailuresAndResponsesModel,
            getHeartbeatDatastoresModel: getHeartbeatDatastoresModel,
            getAdvancedOptionsModel: getAdvancedOptionsModel,
            getAdmissionControlModel: getAdmissionControlModel,
            submit: submit,
            getClusterReconfigureSpec: getClusterReconfigureSpec,
            validate: validate
         };

         function createPages() {
            // page definitions have properties like page, model, etc.
            failuresAndResponsesDef = {
               page: {
                  id: 'failuresAndResponses',
                  title: i18nService.getString('ClusterUi', 'ha.config.edit.vmResponse.ToCItem'),
                  contentUrl: 'cluster-ui/resources/cluster/views/settings/services/ha/edit/pages/failuresAndResponsesPage.html'
               }
            };
            heartbeatDatastoresDef = {
               page: {
                  id: 'heartbeatDatastores',
                  title: i18nService.getString('ClusterUi', 'ha.config.dsForHeartbeating.ToCItem'),
                  contentUrl: 'cluster-ui/resources/cluster/views/settings/services/ha/edit/pages/heartbeatDatastoresPage.html'
               }
            };
            advancedOptionsDef = {
               page: {
                  id: 'advancedOptions',
                  title: i18nService.getString('ClusterUi', 'ha.config.advancedOptions'),
                  contentUrl: 'cluster-ui/resources/cluster/views/settings/services/ha/edit/pages/advancedOptionsPage.html'
               }
            };
            admissionControlDef = {
               page: {
                  id: 'admissionControl',
                  title: i18nService.getString('ClusterUi', 'ha.config.admissionControl'),
                  contentUrl: 'cluster-ui/resources/cluster/views/settings/services/ha/edit/pages/admissionControlPage.html'
               }
            };
         }

         function getPageDefinitions() {
            var pages = [];

            pages.push(failuresAndResponsesDef.page);
            pages.push(admissionControlDef.page);
            pages.push(heartbeatDatastoresDef.page);
            pages.push(advancedOptionsDef.page);

            return pages;
         }

         function submit() {
            var clusterId = haDialogOptions.dialogData.objectId;
            var clusterSpec = getClusterReconfigureSpec();

            return validateAndConfirm(clusterId, clusterSpec)
                  .then(function() {
                     mutationService.apply(clusterId, EDIT_CLUSTER_SPEC, clusterSpec);
                     return true;
                  }, function() {
                     return false;
                  });
         }

         function getClusterReconfigureSpec() {
            var haFormModel = _.extend({},
                  failuresAndResponsesDef.model || {},
                  getHeartbeatDatastoresModel(),
                  advancedOptionsDef.model || {},
                  admissionControlDef.model || {}
            );

            if (failuresAndResponsesDef && failuresAndResponsesDef.model &&
                  failuresAndResponsesDef.model.haConfiguration &&
                  failuresAndResponsesDef.model.haConfiguration.defaultVmSettings) {
               var haConfiguration = failuresAndResponsesDef.model.haConfiguration;
               var defaultVmSettings = haConfiguration.defaultVmSettings;
               var vmToolsMonitoringSettings = defaultVmSettings.vmToolsMonitoringSettings;
               var roundedVmMonitoringSettings = roundVmToolsMonitoringSettingsValues(vmToolsMonitoringSettings);
               var originalVmToolsMonitoringSettings =
                     failuresAndResponsesDef.model.haConfiguration.defaultVmSettings.vmToolsMonitoringSettings;
               _.extend(originalVmToolsMonitoringSettings, roundedVmMonitoringSettings);
            }

            return clusterSpecBuilderService.buildClusterComputeResourceSpec(
                  haFormModel, true);
         }

         function validateAndConfirm(clusterId, clusterSpec) {
            if (!validate()) {
               return $q.reject();
            }

            var hasApdOrPdlOn = checkApdOrPdlOn();

            //show confirmation message if turning on APD or PDL and there are
            //FT VMs in the cluster
            if (hasApdOrPdlOn) {
               return mutationService.validate(clusterId, EDIT_CLUSTER_SPEC, clusterSpec)
                     .then(handleValidationResult);
            }

            return $q.resolve();
         }

         function validate() {
            haDialogOptions.alerts = [];
            haDialogOptions.isAlertClosed = true;

            //validate submit ha admission control failover hosts policy
            //with no failover hosts selected
            if (admissionControlDef.model &&
                  admissionControlDef.model.admissionControlPolicy.type ===
                  haConstants.admissionControlPolicy.FAILOVER_HOSTS_TYPE &&
                  admissionControlDef.model.failoverHosts.length === 0) {

               haDialogOptions.alerts = [{
                  type: vuiConstants.validationBanner.type.ERROR,
                  text: i18nService.getString('ClusterUi',
                        'ha.config.admission.control.policy.failover.host.error')
               }];
               haDialogOptions.isAlertClosed = false;

               return false;
            }

            return true;
         }


         function checkApdOrPdlOn() {
            var hasApdOrPdlOn = false;
            if (failuresAndResponsesDef.model && (failuresAndResponsesDef.model
                        .haConfiguration.vmcpSettings.pdlResponse !==
                  haConstants.storageVmReaction.DISABLED || failuresAndResponsesDef.model
                        .haConfiguration.vmcpSettings.apdResponse !==
                  haConstants.storageVmReaction.DISABLED)) {
               hasApdOrPdlOn = true;
            }

            return hasApdOrPdlOn;
         }

         function handleValidationResult(validationResult) {
            if (validationResult && !validationResult.error && validationResult.result &&
                  validationResult.result.isValid !== true) {
               if (validationResult.result.errorCode === clusterConstants.editClusterErrorCodes.HA_APD_PDL_WITH_FT_VMS) {
                  var title = i18nService.getString('ClusterUi',
                        'ha.config.enable.storageProtection.with.ftVms.warning.title');
                  return showConfirmationDialog(title,
                        validationResult.result.validationMessage);
               }
            }

            return $q.resolve();
         }

         function showConfirmationDialog(title, message) {
            var deferred = $q.defer();

            var dialogOptions = {
               title: title,
               message: message,
               submit: function() {
                  deferred.resolve();
                  return true;
               },
               onCancel: function() {
                  deferred.reject();
                  return true;
               }
            };

            clarityModalService.openConfirmationModal(dialogOptions);
            return deferred.promise;
         }

         //region Failures and Responses page

         function getFailuresAndResponsesModel() {
            if (!failuresAndResponsesDef.model) {
               buildFailuresAndResponsesModel();
            }
            return failuresAndResponsesDef.model;
         }

         function buildFailuresAndResponsesModel() {
            var hostMonitoring = haData.hostMonitoring ===
                  haConstants.hostMonitoringState.ENABLED;
            var vmToolsMonitorHasFailureWindow = true;
            //default failure window value
            var vmToolsMonitorMaxFailureWindow =
                  haConstants.vmMonitoringSensitivity.high.FAILURE_WINDOW;
            if (haData.vmToolsMonitorMaxFailureWindow ===
                  haConstants.vmMonitoringSensitivity.NO_FAILURE_WINDOW) {
               vmToolsMonitorHasFailureWindow = false;
            } else {
               //convert seconds to hours
               vmToolsMonitorMaxFailureWindow =
                     haData.vmToolsMonitorMaxFailureWindow / 3600;
            }

            failuresAndResponsesDef.model = {
               haEnabled: haData.haEnabled,
               haConfiguration: {
                  defaultVmSettings: {
                     isolationResponse: haData.isolationResponse,
                     restartPriority: haData.restartPriority,
                     restartPriorityTimeout: haData.restartPriorityTimeout,
                     vmToolsMonitoringSettings: {
                        vmToolsMonitorFailureInt: haData.vmToolsMonitorFailureInt,
                        vmToolsMonitorMinUpTime: haData.vmToolsMonitorMinUpTime,
                        vmToolsMonitorMaxFailures: haData.vmToolsMonitorMaxFailures,
                        vmToolsMonitorMaxFailureWindow: vmToolsMonitorMaxFailureWindow,
                        vmToolsMonitorHasFailureWindow: vmToolsMonitorHasFailureWindow
                     }
                  },
                  hostMonitoringEnabled: hostMonitoring,
                  vmMonitoringState: haData.vmMonitoring,
                  vmcpSettings: {
                     pdlResponse: haData.vmcpSettings.pdlResponse,
                     apdResponse: haData.vmcpSettings.apdResponse,
                     vmReactionOnAPDCleared: haData.vmcpSettings.vmReactionOnAPDCleared,
                     apdRecoveryDelayMinutes: (haData.vmcpSettings.vmTerminateDelayForAPDSec /
                     haConstants.SECONDS_IN_A_MINUTE)
                  }
               },
               storagePDLSupported: haData.storagePDLSupported,
               storageAPDSupported: haData.storageAPDSupported,
               orchestration: {
                  defaultVmReadiness: {
                     readyCondition: haData.readyCondition,
                     postReadyDelay: haData.postReadyDelay
                  }
               }
            };
         }

         //endregion

         //region Heartbeat datastores page

         function getHeartbeatDatastoresModel() {
            if (!heartbeatDatastoresDef.model) {
               buildHeartbeatDatastoresModel();
            }
            return heartbeatDatastoresDef.model;
         }

         function buildHeartbeatDatastoresModel() {
            heartbeatDatastoresDef.model = {
               hbDatastoreCandidatePolicy: haData.heartbeatDatastoreCandidatePolicy,
               hbDatastoreCandidates: haData.heartbeatDatastoreCandidates,
               hbDatastoresPerHost: haData.requiredDatastoreMounts
            };
         }

         //endregion

         //region Advanced options page

         function getAdvancedOptionsModel() {
            if (!advancedOptionsDef.model) {
               advancedOptionsDef.model = {
                  haAdvancedOptionsBuilder: advancedOptionsBuilderFactory
                        .getBuilder(haData.advancedOptions, true)
               };
            }
            return advancedOptionsDef.model;
         }

         //endregion

         //region Admission Control page

         function getAdmissionControlModel() {
            if (!admissionControlDef.model) {
               buildAdmissionControlModel();
            }
            return admissionControlDef.model;
         }

         function buildAdmissionControlModel() {
            var admissionControlPolicyType;

            if (!haData.admissionControlEnabled) {
               admissionControlPolicyType = haConstants.admissionControlPolicy.DISABLED_TYPE;
            } else {
               admissionControlPolicyType = haData.admissionControlPolicy._type;
            }

            var failoverHostNum = haData.failoverHostNum;
            var failoverHostNumDefault =
                  haAdmissionControlService.getFailoverLevelDefaultValue();

            if (failoverHostNum < failoverHostNumDefault) {
               failoverHostNum = failoverHostNumDefault;
            }

            var slotSizePolicy = haConstants.slotSizePolicy.COVER_ALL_VM;
            var fixedSlotSizeCpu = haAdmissionControlService.getFixedSlotSizeCpuDefaultValue();
            var fixedSlotSizeMemory = haAdmissionControlService.getFixedSlotSizeMemoryDefaultValue();
            var slotSize = haData.admissionControlPolicy.slotPolicy;

            if (slotSize) {
               slotSizePolicy = haConstants.slotSizePolicy.FIXED_SIZE;
               fixedSlotSizeCpu = slotSize.cpu;
               fixedSlotSizeMemory = slotSize.memory;
            }
            var hostData = haData.hostData;

            if (hostData === null) {
               hostData = [];
            }

            var autoComputePercentages = haData.autoComputePercentages;

            if (autoComputePercentages === null || admissionControlPolicyType !==
                  haConstants.admissionControlPolicy.FAILOVER_RESOURCES_TYPE) {
               autoComputePercentages = true;
            }

            admissionControlDef.model = {
               admissionControlPolicy: {
                  type: admissionControlPolicyType
               },
               failoverLevel: failoverHostNum,
               failoverHosts: hostData,
               cpuFailoverPercent: haData.cpuFailoverPercent,
               memoryFailoverPercent: haData.memoryFailoverPercent,
               slotSizePolicy: slotSizePolicy,
               fixedSlotSizeCpu: fixedSlotSizeCpu,
               fixedSlotSizeMemory: fixedSlotSizeMemory,
               autoComputePercentages: autoComputePercentages,
               resourceReductionToToleratePercent: haData.resourceReductionToToleratePercent,
               hostsInCluster: haData.hostsInCluster
            };
         }

         function roundVmToolsMonitoringSettingsValues(vmToolsMonitoringSettings) {

            return {
               vmToolsMonitorFailureInt: Math.round(vmToolsMonitoringSettings.vmToolsMonitorFailureInt),
               vmToolsMonitorMaxFailureWindow: Math.round(vmToolsMonitoringSettings.vmToolsMonitorMaxFailureWindow),
               vmToolsMonitorMaxFailures: Math.round(vmToolsMonitoringSettings.vmToolsMonitorMaxFailures),
               vmToolsMonitorMinUpTime: Math.round(vmToolsMonitoringSettings.vmToolsMonitorMinUpTime)
            };
         }

         //endregion
      };
   }
})();
