namespace h5_vm {
   export enum DeployWorkflowType {
      OVF,
      OVA,
      INVALID
   }
   export class OvfDeployConstants {
      static readonly DEPLOYMENT_LOCAL: string  = 'LOCAL';
      static readonly DEPLOYMENT_URL: string    = 'URL';

      static readonly TEMPLATE_TYPE_OVF: string = 'OVF';
      static readonly TEMPLATE_TYPE_OVA: string = 'OVA';
   }
}