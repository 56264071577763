(function () {
   'use strict';

   angular.module('com.vmware.vsphere.client.cluster')
         .service('haSummaryService', haSummaryService);

   haSummaryService.$inject = ['vxPropertyViewService', 'i18nService'];

   function haSummaryService(vxPropertyViewService, i18nService) {
      function getUnitHostsNumber(data) {
         var hostCount = 0;

         hostCount += data.hostsDisconnectedWithUninitError;
         hostCount += data.hostsInStandByWithUninitError;
         hostCount += data.hostsInMaintenanceWithUninitError;

         return hostCount;
      }

      function getFailoverLevelAdvancedRuntimeData(advancedRuntimeInfo) {
         var builder = vxPropertyViewService.createPropertyViewBuilder();
         var sectionBuilder = getSectionBuilder(builder);

         var slotInfo = advancedRuntimeInfo.slotInfo || {};

         sectionBuilder.property(i18nService.getString('ClusterUi',
               'ha.runtime.info.slot.size'),
               [i18nService.getString('ClusterUi',
                     'ha.runtime.info.slot.mhz', slotInfo.cpuMHz),
               i18nService.getString('ClusterUi',
                     'ha.runtime.info.slot.mb', slotInfo.memoryMB)]);
         sectionBuilder.property(i18nService.getString('ClusterUi',
               'ha.runtime.info.total.slots'),
               getTotalSlotsInCluster(advancedRuntimeInfo.totalSlots));
         sectionBuilder.property(i18nService.getString('ClusterUi',
               'ha.runtime.info.used.slots'),
               advancedRuntimeInfo.usedSlots);
         sectionBuilder.property(i18nService.getString('ClusterUi',
               'ha.runtime.info.unreserved.slots'),
               advancedRuntimeInfo.unreservedSlots);
         sectionBuilder.property(i18nService.getString('ClusterUi',
               'ha.runtime.info.failover.slots'),
               getFailoverSlots(advancedRuntimeInfo));
         sectionBuilder.property(i18nService.getString('ClusterUi',
               'ha.runtime.info.total.powered.vms'),
               advancedRuntimeInfo.totalVms);
         sectionBuilder.property(i18nService.getString('ClusterUi',
               'ha.runtime.info.total.hosts'),
               advancedRuntimeInfo.totalHosts);
         sectionBuilder.property(i18nService.getString('ClusterUi',
               'ha.runtime.info.total.good.hosts'),
               advancedRuntimeInfo.totalGoodHosts);

         return builder.build();
      }

      function getFailoverResourcesAdvancedRuntimeData(failoverResourceData) {
         var builder = vxPropertyViewService.createPropertyViewBuilder();
         var sectionBuilder = getSectionBuilder(builder);

         sectionBuilder.property(i18nService.getString('ClusterUi',
               'ha.runtime.info.resources.memory.total.GB'),
               failoverResourceData.totalMemoryText);
         sectionBuilder.property(i18nService.getString('ClusterUi',
               'ha.runtime.info.resources.memory.reserved.percentage'),
               failoverResourceData.reservedMemoryPercentText);
         sectionBuilder.property(i18nService.getString('ClusterUi',
               'ha.runtime.info.resources.cpu.total.Ghz'),
               failoverResourceData.totalCpuText);
         sectionBuilder.property(i18nService.getString('ClusterUi',
               'ha.runtime.info.resources.cpu.reserved.percentage'),
               failoverResourceData.reservedCpuPercentText);

         if (failoverResourceData.autoComputeStatus !== null) {
            var autoComputeKey = failoverResourceData.autoComputeStatus ?
                  'ha.runtime.info.resources.autocompute.flag.on':
                  'ha.runtime.info.resources.autocompute.flag.off';
            var autoComputeText = i18nService.getString('ClusterUi', autoComputeKey);
            sectionBuilder.property(i18nService.getString('ClusterUi',
                  'ha.runtime.info.resources.autocompute.flag'),
                  autoComputeText);
         }

         return builder.build();
      }

      function getHostsRuntimeData(hostHaRuntimeInfo) {
         var builder = vxPropertyViewService.createPropertyViewBuilder();
         var sectionBuilder = getSectionBuilder(builder);

         sectionBuilder.property(i18nService.getString('ClusterUi',
               'ha.runtime.master.host'),
               hostHaRuntimeInfo.masterName);
         sectionBuilder.property(i18nService.getString('ClusterUi',
               'ha.runtime.hosts.connected.to.master'),
               hostHaRuntimeInfo.hostsConnectedCount);
         sectionBuilder.property(i18nService.getString('ClusterUi',
               'ha.runtime.hosts.not.connected.to.master'),
               hostHaRuntimeInfo.hostsNotConnectedCount);
         sectionBuilder.property(i18nService.getString('ClusterUi',
               'ha.runtime.agent.not.reachable'),
               hostHaRuntimeInfo.agentUnreachableCount);
         sectionBuilder.property(i18nService.getString('ClusterUi',
               'ha.runtime.config.errors'),
               hostHaRuntimeInfo.configErrorCount);
         sectionBuilder.property(i18nService.getString('ClusterUi',
               'ha.runtime.hosts.failed'),
               hostHaRuntimeInfo.hostsUnreachableCount);
         sectionBuilder.property(i18nService.getString('ClusterUi',
               'ha.runtime.network.isolated'),
               hostHaRuntimeInfo.networkIsolatedCount);
         sectionBuilder.property(i18nService.getString('ClusterUi',
               'ha.runtime.network.partitioned'),
               hostHaRuntimeInfo.networkPartitionedCount);
         sectionBuilder.property(i18nService.getString('ClusterUi',
               'ha.runtime.agent.initializing'),
               hostHaRuntimeInfo.agentInitializingCount);
         sectionBuilder.property(i18nService.getString('ClusterUi',
               'ha.runtime.hosts.disconnected.from.vc'),
               hostHaRuntimeInfo.hostsDisconnectedFromVcCount);
         sectionBuilder.property(i18nService.getString('ClusterUi',
               'ha.runtime.hosts.in.standby'),
               hostHaRuntimeInfo.hostsInStandByCount);
         sectionBuilder.property(i18nService.getString('ClusterUi',
               'ha.runtime.hosts.in.maintenance'),
               hostHaRuntimeInfo.hostsInMaitenanceModeCount);
         sectionBuilder.property(i18nService.getString('ClusterUi',
               'ha.runtime.hosts.uninitializationError'),
               getUnitHostsNumber(hostHaRuntimeInfo));

         return builder.build();
      }

      function getVmsRuntimeData(hostHaRuntimeInfo) {
         var builder = vxPropertyViewService.createPropertyViewBuilder();
         var sectionBuilder = getSectionBuilder(builder);

         sectionBuilder.property(i18nService.getString('ClusterUi',
               'ha.runtime.protected.vms'),
               hostHaRuntimeInfo.protectedVmsCount);
         sectionBuilder.property(i18nService.getString('ClusterUi',
               'ha.runtime.not.protected.vms'),
               hostHaRuntimeInfo.notProtectedVmCount);

         return builder.build();
      }

      function getTotalSlotsInCluster(value) {
         return (value < 0)
               ? i18nService.getString('ClusterUi', 'ha.runtime.info.total.slots.na')
               : value;
      }

      function getFailoverSlots(advancedRuntimeInfo) {
         var value = advancedRuntimeInfo.totalSlots -
               advancedRuntimeInfo.unreservedSlots -
               advancedRuntimeInfo.usedSlots;

         return (value < 0 ? 0 : value);
      }

      function getSectionBuilder(builder) {
         var sectionBuilder = builder
               .category('')
               .section('');

         return sectionBuilder;
      }

      return {
         getHostsRuntimeData: getHostsRuntimeData,
         getFailoverLevelAdvancedRuntimeData: getFailoverLevelAdvancedRuntimeData,
         getFailoverResourcesAdvancedRuntimeData: getFailoverResourcesAdvancedRuntimeData,
         getVmsRuntimeData: getVmsRuntimeData
      };
   }
})();
