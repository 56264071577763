(function() {
   'use strict';

   angular.module('com.vmware.vsphere.client.cluster')
      .service('clusterGroupsService', clusterGroupsService);

   clusterGroupsService.$inject = ['i18nService', 'dataService', 'clusterGroupsConstants',
      'defaultUriSchemeUtil', 'logService', 'clusterSpecBuilderService', 'mutationService',
      'clarityModalService', 'clusterRulesConstants', 'alertService'];

   function clusterGroupsService(i18nService, dataService, clusterGroupsConstants,
      defaultUriSchemeUtil, logService, clusterSpecBuilderService, mutationService,
      clarityModalService, clusterRulesConstants, alertService) {

      var log = logService('clusterGroupsService');
      var getString = _.bind(i18nService.getString, i18nService, 'ClusterUi');

      return {
         DEFAULT_GROUP_TYPE: clusterGroupsConstants.groupType.VM_GROUP,
         getGroupsData: getGroupsData,
         getRuleGroupsData: getRuleGroupsData,
         getGroupMembers: getGroupMembers,
         getGroupByName: getGroupByName,
         buildTypesOptions: buildTypesOptions,
         buildGroupSpec: buildGroupSpec,
         buildGroupMembersSpec: buildGroupMembersSpec,
         removeGroup: removeGroup,
         removeGroupMembers: removeGroupMembers,
         getSelectedItemsIds: getSelectedItemsIds
      };

      function getGroupsData(clusterId, filterText) {
         var clusterGroupsProperty;
         var options;

         if (filterText) {
            clusterGroupsProperty = 'groupsData';
            options = {
               propertyParams: [{
                  propertyName: 'groupsData',
                  parameterType: 'java.lang.String',
                  parameter: filterText
               }]
            };
         } else {
            clusterGroupsProperty = 'ClusterComputeResource/configurationEx/group';
         }

         return dataService
            .getProperties(clusterId, [clusterGroupsProperty], options)
            .then(function (data) {
               return data ? getGroups(data[clusterGroupsProperty]) : [];
            });
      }

      function getRuleGroupsData(clusterId, rule) {
         var options = { propertyParams: buildGroupsFilterByNameSpec(rule) };

         return dataService.getProperties(clusterId, ['clusterGroups'], options)
            .then(function(data) {
               return data ? getGroups(data['clusterGroups']) : [];
            });
      }

      function getGroupMembers(group) {
         return dataService.getPropertiesForObjects(group.members,
            ['name', 'primaryIconId'])
            .then(function(propsPerObject) {
               return _.map(propsPerObject, function(memberData, id) {
                  return {
                     id: id,
                     name: memberData.name,
                     icon: memberData.primaryIconId
                  };
               });
            });
      }

      function getGroupByName(groups, groupName) {
         var groupRes = null;
         groups.forEach(function(group) {
            if (group.name === groupName) {
               groupRes = group;
            }
         });

         return groupRes;
      }

      function getGroups(groupsData) {
         return _.map(groupsData, function(group) {
            var groupType;
            var groupIcon;
            var members;
            switch (group._type) {
               case clusterGroupsConstants.groupType.VM_GROUP:
                  groupType = getString('groups.list.type.vm');
                  groupIcon = 'vx-icon-vm-drs-group';
                  members = group.vm;
                  break;
               case clusterGroupsConstants.groupType.HOST_GROUP:
                  groupType = getString('groups.list.type.host');
                  groupIcon = 'vx-icon-host-drs-group';
                  members = group.host;
                  break;
            }

            if (!members) {
               members = [];
            }

            return {
               name: group.name,
               type: groupType,
               icon: groupIcon,
               members: members.map(function(memberRef) {
                  return defaultUriSchemeUtil.getVsphereObjectId(memberRef);
               }),
               rawType: group._type
            };
         });
      }

      function buildTypesOptions() {
         return _.map(clusterGroupsConstants.groupType, function(type) {
            var typeLabel = '';
            switch (type) {
               case clusterGroupsConstants.groupType.VM_GROUP:
                  typeLabel = getString('groups.list.type.vm');
                  break;
               case clusterGroupsConstants.groupType.HOST_GROUP:
                  typeLabel = getString('groups.list.type.host');
                  break;
            }
            return {
               id: type,
               label: typeLabel
            };
         });
      }

      function createGroupInstance(groupType, name, members) {
         var groupInfo = {
            name: name
         };

         switch (groupType) {
            case clusterGroupsConstants.groupType.VM_GROUP:
               groupInfo = _.extend({
                   vm: members,
                  _type: clusterGroupsConstants.groupType.VM_GROUP
               }, groupInfo);
               break;
            case clusterGroupsConstants.groupType.HOST_GROUP:
               groupInfo = _.extend({
                   host: members,
                  _type: clusterGroupsConstants.groupType.HOST_GROUP
               }, groupInfo);
               break;
            default:
               log.warn('Unexpected group type: ' + groupType);
         }

         return groupInfo;
      }

      function createGroupSpec(groupInfo, operation) {
         var groupSpec = {
            _type: 'com.vmware.vim.binding.vim.cluster.GroupSpec',
            operation: operation,
            info: groupInfo
         };

         if (clusterGroupsConstants.operationType.REMOVE === operation) {
            groupSpec.removeKey = groupSpec.info.name;
         }
         return groupSpec;
      }

      function buildGroupSpec(groupType, name, groupMembers, operation) {
         var members = [];
         if (groupMembers) {
            members = groupMembers.source;
         }
         var groupInfo = createGroupInstance(groupType, name.trim(), members);

         return createGroupSpec(groupInfo, operation);
      }

      function buildGroupsFilterByNameSpec(rule) {
         var groupNames = [];

         if (rule.type === clusterRulesConstants.ruleType.VM_DEPENDENCY_RULE_TYPE) {
            if (rule.vmDependencyRuleProperties.vmGroup !== null) {
               groupNames.push(rule.vmDependencyRuleProperties.vmGroup);
            }

            if (rule.vmDependencyRuleProperties.dependsOnVmGroup !== null) {
               groupNames.push(rule.vmDependencyRuleProperties.dependsOnVmGroup);
            }
         } else if (rule.type === clusterRulesConstants.ruleType.VM_HOST_GROUP_RULE) {
            if (rule.vmToHostRuleProperties.vmGroupName !== null) {
               groupNames.push(rule.vmToHostRuleProperties.vmGroupName);
            }

            if (rule.vmToHostRuleProperties.hostGroupName !== null) {
               groupNames.push(rule.vmToHostRuleProperties.hostGroupName);
            }
         }

         var filterSpec = [{
            propertyName: 'clusterGroups',
            parameterType:
               'com.vmware.vsphere.client.cluster.groups.GroupsFilterByNameSpec',
            parameter: { names: groupNames }
         }];

         return filterSpec;
      }

      function buildGroupMembersSpec(members){
         return {
            source: members.map(function(member) {
               return defaultUriSchemeUtil.getManagedObjectReference(member);
            })
         };
      }

      function removeGroup(clusterId, groupInfo) {
         var groupSpec = buildGroupSpec(
               groupInfo.rawType,
               groupInfo.name,
               groupInfo.members,
               clusterGroupsConstants.operationType.REMOVE);

         mutationService
               .validate(clusterId, 'com.vmware.vim.binding.vim.cluster.GroupSpec', groupSpec)
               .then(function(validationResult) {
                  var isGroupInUse = !!validationResult &&
                        (validationResult.result || !!validationResult.error);
                  var confirmationText;

                  if (isGroupInUse) {
                     confirmationText = getString(
                           'groups.action.remove.confirmationGroupInUse',
                           groupInfo.name);
                  } else {
                     confirmationText = getString('groups.action.remove.confirmationTextOne');
                  }

                  var alertProperties = {
                     title: getString('groups.action.remove.confirmationTitle'),
                     message: confirmationText,
                     submit: function() {
                        removeGroupFromCluster(clusterId, groupSpec);
                     }
                  };

                  clarityModalService.openConfirmationModal(alertProperties);
               });
      }

      function removeGroupFromCluster(clusterId, groupSpec) {
         var clusterComputeResourceSpec =
               clusterSpecBuilderService.buildClusterComputeResourceSpec(
                     {
                        group: groupSpec
                     },
                     true);

         mutationService.apply(
               clusterId,
               'com.vmware.vsphere.client.cluster.ClusterComputeResourceSpec',
               clusterComputeResourceSpec
         );
      }

      function removeGroupMembers(clusterId, groupInfo, membersToRemove) {
         if (groupInfo && groupInfo.members && membersToRemove &&
               groupInfo.members.length === membersToRemove.length) {
            // Cannot delete all members of a group
            alertService.error(
                  getString('groups.members.action.remove.confirmationTitle'),
                  getString('groups.members.action.remove.error.CannotDeleteAllGroupMembers'));
         } else {
            var confirmProperties = {
               title: getString('groups.members.action.remove.confirmationTitle'),
               message: getString('groups.members.action.remove.confirmationTextOne'),
               submit: function() {
                  removeMemberFromGroup(clusterId, groupInfo, membersToRemove);
               }
            };

            clarityModalService.openConfirmationModal(confirmProperties);
         }
      }

      function removeMemberFromGroup(clusterId, groupInfo, membersToRemove) {
         var memberIdsToRemove = _.map(membersToRemove, 'id');
         var newMembers =  _.difference(groupInfo.members, memberIdsToRemove);
         var newMemberRefs = buildGroupMembersSpec(newMembers);

         var groupSpec = buildGroupSpec(
               groupInfo.rawType,
               groupInfo.name,
               newMemberRefs,
               clusterGroupsConstants.operationType.EDIT);

         var clusterComputeResourceSpec =
               clusterSpecBuilderService.buildClusterComputeResourceSpec(
                     {group: groupSpec}, true);

         mutationService.apply(
               clusterId,
               'com.vmware.vsphere.client.cluster.ClusterComputeResourceSpec',
               clusterComputeResourceSpec
         );
      }

      function getSelectedItemsIds(items) {
         return items.map(function(item) {
            return item.id;
         });
      }
   }
})();
