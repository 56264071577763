namespace spbm_ui {

   export class SpbmFcdProfileService {
      static $inject = ['$injector'];

      private _vsdSpbmFcdProfileService: SpbmFcdProfileService;

      constructor(private $injector: any) {
      }

      private get vscSpbmFcdProfileActionService(): SpbmFcdProfileService {
         if (!this._vsdSpbmFcdProfileService) {
            this._vsdSpbmFcdProfileService = this.$injector.get("vscSpbmFcdProfileService");
         }
         return this._vsdSpbmFcdProfileService;
      }

      updateFcdNamespaceProfile(datastoreId: string): void {
         return this.vscSpbmFcdProfileActionService.updateFcdNamespaceProfile(datastoreId);
      }
   }

   angular.module("com.vmware.vsphere.client.spbm")
         .service("spbmFcdProfileService", SpbmFcdProfileService);
}
