(function () {
   'use strict';
   angular.module('com.vmware.vsphere.client.host')
      .controller('pciDevicesController', PciDevicesController);

   PciDevicesController.$inject = ['$scope', 'dataService',
      'i18nService', 'pciDeviceService', 'columnRenderersRegistry', 'vuiUtils'];

   function PciDevicesController($scope,
         dataService, i18nService, pciDeviceService, columnRenderersRegistry, vuiUtils) {
      var objectId = $scope._route.objectId;
      var hostStr = _.bind(i18nService.getString, i18nService, 'HostUi');
      var iconTextRenderer = columnRenderersRegistry.getColumnRenderer('icon-text');
      var treeListDataSourceSchema = {
         model: {
            id: "nodeId",
            expanded: true
         }
      };
      var self = this;

      self.headerOptions = {
         title: hostStr('vmdpConfig.list.title'),
         actions: [
            {
               actionUid: 'vsphere.core.host.actions.refreshPciDevices',
               customLabel: i18nService.getString('Common', 'refresh')
            },
            {
               actionUid: 'vsphere.core.host.actions.editPciDevices',
               customLabel: i18nService.getString('HostUi',
                     'vmdpConfig.editButton.label')
             }]
      };
      self.gridOptions = pciDeviceService.getGridOptions();
      self.splitterOptions = pciDeviceService.getSplitterOptions();
      self.showCapablePassthruDevices = true;
      self.showAllDevices = false;
      self.watchForObjects = [objectId];
      self.liveRefreshProperties = [
         'config.pciPassthruInfo',
         'summary.rebootRequired'
      ];
      self.updateViewData = updateViewData;
      self.getAllDevicesData = getAllDevicesData;
      self.rebootHost = function() {
         h5.actions['vsphere.core.host.actions.rebootAction']({}, [objectId]);
      };

      self.treeListWidget = null;
      self.treeListColumns = [
         {
            field: "busId",
            title: hostStr('vmdpConfig.idColHeader.label'),
            template: function(dataItem) {
               return iconTextRenderer(['icon', 'text'], {
                  icon: 'vx-icon-pci',
                  text: dataItem.busId
               });
            }
         },
         {
            field: "statusMessage",
            title: hostStr('vmdpConfig.statusHeader.label')
         },
         {
            field: "vendor",
            title: hostStr('vmdpConfig.vendorName.label')
         },
         {
            field: "deviceName",
            title: hostStr('vmdpConfig.deviceNameHeader.label'),
            template: pciDeviceService.textTitleRenderer
         },
         {
            field: "esxDeviceInfo",
            title: hostStr('vmdpConfig.esxName.label'),
            template: function(dataItem) {
               return dataItem.esxDeviceInfo ? dataItem.esxDeviceInfo.split(" ")[0] : '';
            }
         }
      ];

      self.initializeTreeListDataSource = function(data) {
         self.treeListOptions.dataSource = new kendo.data.TreeListDataSource({
            data: data,
            schema: treeListDataSourceSchema
         });
         self.treeListOptions.dataSource.read();
      };

      self.onShowCapablePassthruDevices = onShowCapablePassthruDevices;
      self.onShowAllDevices = onShowAllDevices;

      updateViewData();

      self.onTreeListSelect = function() {
         var row = self.treeListWidget.select();
         var data = self.treeListWidget.dataItem(row);
         if (data) {
            self.pciDetailsTreeData =
                  pciDeviceService.createPciDetails(data);
            self.pciDeviceTreeBusDetatils =
                  pciDeviceService.createPciBusDetails(data);
            self.treeEsxDeviceInfo =
                  pciDeviceService.createEsxDeviceInfoDetails(data);
            self.treeDirectPathMessage = data.directPathMessage;
            self.selectedTreePciId = data.busId;
            self.hasSelectedTreePciDevice = true;
         } else {
            self.hasSelectedTreePciDevice = false;
         }
      };

      self.treeListOptions = {
         columns: self.treeListColumns,
         sortable: false,
         resizable: true,
         selectable: true,
         change: _.partial(vuiUtils.safeApply, $scope, self.onTreeListSelect)
      };

      $scope.pciDevicesPreselectComparator = pciDevicesPreselectComparator;

      function updateViewData() {
         dataService.getData(objectId,
            'com.vmware.vsphere.client.h5.host.vmdp.VmdpDeviceInfoData').then(
            function (data) {
               if (data) {
                  self.gridOptions.data = data.availableVmdpDeviceInfo;
                  self.pendingDevicesMessage =
                        pciDeviceService.formatPendingDeviceMessage(self.gridOptions.data);
                  addWatchForSelectedItems();
               }
            });
      }

      function getAllDevicesData() {
         dataService.getData(objectId,
               'com.vmware.vsphere.client.h5.host.vmdp.VmdpAllDevicesNodeData').then(
               function (data) {
                  if (data) {
                     self.allDevicesData = data.vmdpDeviceNodeList;
                     self.allPendingDevicesMessage =
                           pciDeviceService.formatPendingDeviceMessage(self.allDevicesData);
                     self.initializeTreeListDataSource(self.allDevicesData);
                  }
               });
      }

      function addWatchForSelectedItems() {
         $scope.$watch(
            function () {
               return self.gridOptions.selectedItems;
            },
            function (selectedItems) {
               if (selectedItems && selectedItems.length > 0) {
                  self.pciDetailsData =
                     pciDeviceService.createPciDetails(selectedItems[0]);
                  self.pciDeviceBusDetatils =
                     pciDeviceService.createPciBusDetails(selectedItems[0]);
                  self.esxDeviceInfo =
                     pciDeviceService.createEsxDeviceInfoDetails(selectedItems[0]);
                  self.directPathMessage = selectedItems[0].directPathMessage;
                  self.selectedPciId = selectedItems[0].busId;
                  self.hasSelectedPciDevice = true;
               } else {
                  self.hasSelectedPciDevice = false;
               }
            });
      }

      function pciDevicesPreselectComparator(gridDataItem) {
         return self.hasSelectedPciDevice && gridDataItem &&
               self.selectedPciId === gridDataItem.busId;
      }

      function onShowCapablePassthruDevices() {
         self.showCapablePassthruDevices = true;
         self.showAllDevices = false;
      }

      function onShowAllDevices() {
         self.showCapablePassthruDevices = false;
         self.showAllDevices = true;
         self.splitterOptionsAllItems = pciDeviceService.getSplitterOptions();
         if (!self.allDevicesData) {
            getAllDevicesData();
         }
      }
   }
})();
