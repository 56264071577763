module cluster_ui {

   import IController = angular.IController;

   class DrsAdvancedOptionsPageController implements IController {

      public static $inject = ["$scope", "drsConstants"];

      private pageModel: any;
      private advancedOptionsBuilder: any;
      private formData: any;

      constructor(private $scope: any, private drsConstants: any) {
         this.pageModel =
               $scope.modalOptions.dialogData.manager.getAdvancedOptionsPageModel();
         this.formData = $scope.modalOptions.dialogData.manager.getFormData();
         this.advancedOptionsBuilder = this.pageModel.advancedOptionsBuilder;
      }
   }

   angular.module("com.vmware.vsphere.client.cluster")
         .controller("DrsAdvancedOptionsPageController",
               DrsAdvancedOptionsPageController);
}
