namespace h5_vm {

   import IPromise = angular.IPromise;
   import IQService = angular.IQService;
   import VmProvisioningPageModel = h5_vm.VmProvisioningPageModel;
   const USERDEFINED_PROPERTY_PARAM: string = "com.vmware.vcenter.ovf.PropertyParams";
   const IPALLOCATION_PROPERTY_PARAM: string = "com.vmware.vcenter.ovf.IpAllocationParams";

   class VmProvisioningCustomizeTemplatePageModelFactory {
      public static $inject = [
         "$q",
         "i18nService"
      ];

      constructor($q: IQService,
            i18nService: any) {
         return (virtualMachineSpecBuilder: any, wizardViewData: any) => {
            return new VmProvisioningCustomizeTemplatePageModel(
                  $q,
                  i18nService,
                  virtualMachineSpecBuilder,
                  wizardViewData);
         };
      }
   }

   export class VmProvisioningCustomizeTemplatePageModel implements VmProvisioningPageModel <any> {
      static $inject: string[] = [
         "$q",
         "i18nService"
      ];

      private formObj: any = {
         customizeTemplateParams: undefined,
         ipAllocationPolicyParams: undefined,
         valid: false
      };

      constructor(private $q: IQService,
            private i18nService: any,
            private virtualMachineSpecBuilderObj: any,
            private wizardViewDataObj: any) {
      }

      get form(): any {
         return this.formObj;
      }

      get customizeTemplateParams(): any {
         this.formObj.customizeTemplateParams = this.retrieveUserDefinedPropertyParams();
         return this.formObj.customizeTemplateParams;
      }

      get ipAllocationPolicyParams(): any {
         this.formObj.ipAllocationPolicyParams = this.retrieveIpAllocationPolicyParams();
         return this.formObj.ipAllocationPolicyParams;
      }

      public validatePage(): VmProvisioningValidationError {
         if (this.formObj.valid) {
            return {};
         }
         return {
            error: this.i18nService.getString(
               "ProvisioningUiLib", "UserDefinedPropertiesPage.errorMessage2"
            )
         };
      }

      public submitPage(): any {
         this.wizardViewDataObj.setCustomizeTemplateCommonContext(this.formObj.customizeTemplateParams);
         return {
            wizardFlow: this.virtualMachineSpecBuilderObj.getCreationType(),
            invalidateNextPages: false
         };
      }

      private retrieveUserDefinedPropertyParams(): any {
         return _.find(
            this.wizardViewDataObj.getDeployFromLibraryCommonContext(),
            (item: any) => {
               return (_.isEqual(item._type, USERDEFINED_PROPERTY_PARAM));
            });
      }

      private retrieveIpAllocationPolicyParams(): any {
         return _.find(
            this.wizardViewDataObj.getDeployFromLibraryCommonContext(),
            (item: any) => {
               return (_.isEqual(item._type, IPALLOCATION_PROPERTY_PARAM));
            });
      }

      public resetForm(): void {
         this.formObj.customizeTemplateParams = undefined;
         this.formObj.valid = false;
      }
   }

   angular.module("com.vmware.vsphere.client.vm")
      .service("vmProvisioningCustomizeTemplatePageModel",
            VmProvisioningCustomizeTemplatePageModelFactory);
}
