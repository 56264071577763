(function() {
   'use strict';

   angular
      .module('com.vmware.vsphere.client.vm')
      .controller('VmProvisioningDatastoreFaultsController',
         VmProvisioningDatastoreFaultsController);

   VmProvisioningDatastoreFaultsController.$inject = [
      '$scope',
      'i18nService',
      'vuiConstants'
   ];

   function VmProvisioningDatastoreFaultsController(
      $scope,
      i18nService,
      vuiConstants
   ) {
      var self = this;
      self.gridDescription = i18nService.getString('VmUi', 'dsRecommendation.FaultsTitle');
      var faultsList = $scope.modalOptions.dialogData.faults;
      self.dataGridOptions = {
         columnDefs: [
            {
               displayName: i18nService.getString('VmUi', 'FtFaultDetailsList.description'),
               field: 'message',
               width: '100%',
               template: faultItemTemplate
            }
         ],
         sortMode: vuiConstants.grid.sortMode.SINGLE,
         selectionMode: vuiConstants.grid.selectionMode.SINGLE,
         selectedItems: [],
         data: faultsList,
         resizable: true,
         searchable: false,
         height: 350
      };

      function faultItemTemplate(data) {
         if (!data.localizedMessage) {
            if (data.message) {
               data.localizedMessage = data.message;
            } else {
               return '';
            }
         }

         return '<div class="sdrs-fault-item">' + _.escape(data.localizedMessage) + '</div>';
      }
   }
})();
