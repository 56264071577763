/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */

(function () {
   'use strict';
   angular.module('com.vmware.vsphere.client.storage').controller(
         'StorageAdaptersGeneralDetailsViewController', StorageAdaptersGeneralDetailsViewController);

   StorageAdaptersGeneralDetailsViewController.$inject = [
      'i18nService',
      '$scope',
      'vxPropertyViewService',
      'dataService',
      'hostStorageConstants',
      'navigation',
      'storageAdapterActionsService'
   ];

   function StorageAdaptersGeneralDetailsViewController (
         i18nService, $scope, vxPropertyViewService, dataService, hostStorageConstants,
         navigation, storageAdapterActionsService
   ) {
      var FCOE_CONFIG_PROPERTY = 'storageadapter:fcoeConfig';
      var self = this;
      var hostId = navigation.getRoute().objectId;

      $scope.$watch(function () {
         return $scope.masterDetailsViewContext.selectedItem;
      }, function (newValue, oldValue) {
         if (newValue !== oldValue) {
            reloadAdapterProperties();
         }
      });

      reloadAdapterProperties();

      function getSelectedItem () {
         return $scope.masterDetailsViewContext && $scope.masterDetailsViewContext.selectedItem;
      }

      function reloadAdapterProperties () {
         var adapter = getSelectedItem();
         if (!adapter) {
            return;
         }

         // Request additional properties for FCOE.
         if (adapter.type === hostStorageConstants.hostAdapterTypes.FCOE) {
            self.loading = true;
            var fcoeNicNameProperty = {
               propertyParams: [{
                  propertyName: FCOE_CONFIG_PROPERTY,
                  parameterType: 'java.lang.String',
                  parameter: adapter.underlyingNic
               }]
            };

            dataService.getProperties(hostId, [FCOE_CONFIG_PROPERTY], fcoeNicNameProperty).then(
                  function (resp) {
                     buildPropertyView({
                        adapter: adapter,
                        fcoeConfig: resp[FCOE_CONFIG_PROPERTY]
                     });
                  }, function () {
                     buildPropertyView({ adapter: adapter });
                  }).finally(function () {
               self.loading = false;
            });

         } else if (adapter.type === hostStorageConstants.hostAdapterTypes.ISCSI) {
            buildPropertyView({
               adapter: adapter,
               hostId: hostId
            });
         } else {
            buildPropertyView({ adapter: adapter });
         }
      }

      function disableStorageAdapterClickHandler () {
         var warningMessagesManager = $scope.masterDetailsViewContext.alertMessenger;
         warningMessagesManager.addRestartMessage(getSelectedItem().key);
      }

      function enableStorageAdapterClickHandler () {
         var warningMessagesManager = $scope.masterDetailsViewContext.alertMessenger;
         warningMessagesManager.removeRestartMessage(getSelectedItem().key);
      }

      function buildPropertyView (options) {
         var adapter = options.adapter;
         if (!adapter) {
            return;
         }

         var adapterStatus;
         var isAdapterEnabled = true;
         if (adapter.type === hostStorageConstants.hostAdapterTypes.FCOE) {
            isAdapterEnabled = !adapter.isMarkedForRemoval;
            adapterStatus = isAdapterEnabled
                  ? i18nService.getString('StorageUi', 'storage.adapters.iscsi.status.enabled')
                  : i18nService.getString('StorageUi', 'storage.adapters.iscsi.status.disabled');
         }

         if (adapter.type === hostStorageConstants.hostAdapterTypes.ISCSI
               && adapter.isSoftwareBased && adapter.canBeDisabled) {
            isAdapterEnabled = $scope.masterDetailsViewContext.hostAdaptersData.isIScsiEnabledOnHost === true;
            adapterStatus = isAdapterEnabled
                  ? i18nService.getString('StorageUi', 'storage.adapters.iscsi.status.enabled')
                  : i18nService.getString('StorageUi', 'storage.adapters.iscsi.status.disabled');
         }

         var builder = vxPropertyViewService.createPropertyViewBuilder();
         var categoryBuilder = builder.category(
               i18nService.getString('StorageUi', 'storage.devices.properties.generalHeader'), 0);

         var adapterActionDefinitions = storageAdapterActionsService
               .getBuilder(hostId, adapter)
               .setEnableActionSuccess(enableStorageAdapterClickHandler)
               .setDisableActionSuccess(disableStorageAdapterClickHandler);

         var enableDisableAdapterActionDef = adapterActionDefinitions.findAction(isAdapterEnabled
               ? "vsphere.core.host.actions.adapters.disableSoftwareIscsiAdapter"
               : "vsphere.core.host.actions.adapters.enableSoftwareIscsiAdapter");

         if (adapterStatus) {
            categoryBuilder.section('adapterStatus')
                  .title(i18nService.getString('StorageUi', 'storage.adapters.iscsi.status.sectionHeader'))
                  .property(
                        i18nService.getString('StorageUi', 'storage.adapters.iscsi.status.label'),
                        adapterStatus)
                  .action(enableDisableAdapterActionDef);
         }

         var generalSectionBuilder = categoryBuilder.section('generalSection')
               .title(i18nService.getString(
                     'StorageUi', 'storage.devices.properties.generalHeader'))
               .property(
                     i18nService.getString('StorageUi', 'storage.adapters.deviceName'),
                     adapter.device)
               .property(
                     i18nService.getString('StorageUi', 'storage.adapters.deviceModel'),
                     adapter.model);


         if (adapter.type === hostStorageConstants.hostAdapterTypes.FIBRECHANNEL ||
               adapter.type === hostStorageConstants.hostAdapterTypes.FCOE) {
            generalSectionBuilder
                  .property(
                        i18nService.getString('StorageUi', 'storage.adapters.properties.general.wwnn'),
                        adapter.formattedNodeWorldWideName)
                  .property(
                        i18nService.getString('StorageUi', 'storage.adapters.properties.general.wwpn'),
                        adapter.formattedPortWorldWideName);
         }

         if (adapter.type === hostStorageConstants.hostAdapterTypes.ISCSI) {
            var generalEditActionDefinition = adapterActionDefinitions.findAction("vsphere.core.host.actions.adapters.editIscsiGeneralSettings");
            generalSectionBuilder
                  .action(generalEditActionDefinition);
         }

         if (adapter.type === hostStorageConstants.hostAdapterTypes.FCOE) {
            categoryBuilder.section('fcoeNetworkProperties')
                  .title(i18nService.getString('StorageUi', 'storage.adapters.properties.netProps.sectionHeader'))
                  .property(
                        i18nService.getString('StorageUi', 'storage.adapters.properties.netProps.networkAdapter'),
                        adapter.underlyingNic)
                  .property(
                        i18nService.getString('StorageUi', 'storage.adapters.properties.netProps.vlanId'),
                        adapter.linkInfo ? adapter.linkInfo.vlanId : '')
                  .property(
                        i18nService.getString('StorageUi', 'storage.adapters.properties.netProps.priorityClass'),
                        options.fcoeConfig ? options.fcoeConfig.priorityClass : '')
                  .property(
                        i18nService.getString('StorageUi', 'storage.adapters.properties.netProps.vnPortMac'),
                        adapter.linkInfo ? adapter.linkInfo.vnportMac : '')
                  .property(
                        i18nService.getString('StorageUi', 'storage.adapters.properties.netProps.fcfMac'),
                        adapter.linkInfo ? adapter.linkInfo.fcfMac : '');
         }

         if (adapter.type === hostStorageConstants.hostAdapterTypes.ISCSI) {
            generalSectionBuilder
                  .property(
                        i18nService.getString('StorageUi', 'storage.adapters.properties.general.iscsiName'),
                        adapter.iScsiName)
                  .property(
                        i18nService.getString('StorageUi', 'storage.adapters.properties.general.iscsiAlias'),
                        adapter.iScsiAlias)
                  .property(
                        i18nService.getString('StorageUi', 'storage.adapters.properties.general.iscsiTargets'),
                        adapter.formattedDiscoveryProperties);
         }

         if (adapter.type === hostStorageConstants.hostAdapterTypes.ISCSI
               && adapter.isSoftwareBased === false) {
            categoryBuilder.section('networkInterface')
                  .title(i18nService.getString('StorageUi', 'storage.adapters.properties.netInt.sectionHeader'))
                  .property(
                        i18nService.getString('StorageUi', 'storage.adapters.properties.netInt.currentMaximumSpeed'),
                        i18nService.getString('StorageUi', 'storage.adapters.properties.netInt.currentMaximumSpeedValueString',
                              adapter.currentSpeedInMbit, adapter.maxSpeedInMbit))
                  .property(
                        i18nService.getString('StorageUi', 'storage.adapters.properties.netInt.macAddress'),
                        adapter.macAddress);
         }

         if (adapter.type === hostStorageConstants.hostAdapterTypes.ISCSI) {
            categoryBuilder.section('authenticationSection')
                  .title(i18nService.getString(
                        'StorageUi', 'storage.adapters.properties.authentication.sectionHeader'))
                  .property(
                        i18nService.getString('StorageUi', 'storage.adapters.properties.authentication.method'),
                        adapter.localizedAuthenticationMethod)
                  .action(adapterActionDefinitions.findAction("vsphere.core.host.actions.adapters.editIscsiAuthenticationSettings"));
         }

         self.propertyViewData = builder.build();
      }
   }
})();
