module ds_cluster_ui {

   import IRootScopeService = angular.IRootScopeService;

   export class CreateDsClusterWizardService {

      public static $inject = ['$rootScope', 'i18nService', 'vuiWizardService',
            'createDsClusterWizardManager', 'pageStateManagementService'];

      constructor(private $rootScope: IRootScopeService,
            private i18nService: any, private vuiWizardService: any,
            private dialogManager: CreateDsClusterWizardManager,
            private pageStateManagementService: any) { }

      /**
       * Displays create ds cluster wizard.
       */
      public show(objectId: string): void {
         let dialogScope: any = this.$rootScope.$new();

         this.dialogManager.initModel(objectId);
         dialogScope.wizardConfig = this.dialogManager.getWizardConfig();
         this.pageStateManagementService.registerWizardForDefaultStateManagementStrategy(
               dialogScope);

         this.vuiWizardService({
            scope: dialogScope,
            configObjectName: 'wizardConfig'
         }).show();
      }
   }

   angular.module('com.vmware.vsphere.client.dsCluster')
         .service('createDsClusterWizardService', CreateDsClusterWizardService);
}
