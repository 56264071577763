namespace h5_vm {

   import GosSpecValidationService = h5_vm.GosSpecValidationService;

   export class GosNameAndOsValidationService {
      static $inject = ["$q",
            "i18nService",
            "authorizationService",
            "defaultUriSchemeUtil",
            "mutationService"];

      constructor(private $q: any,
            private i18nService: any,
            private authorizationService: any,
            private defaultUriSchemeUtil: any,
            private mutationService: any) {}

      /**
       * @param vcInfo - the info of the VC which privileges will be checked
       * @returns a promise that resolves to an array of errors (strings)
       */
      checkCreateNewGosSpecPrivileges(vcInfo?: VcInfo): angular.IPromise<Array<string>> {
         if (!vcInfo || !vcInfo.serviceGuid) {
            return this.$q.resolve(["vcInfo invalid"]);
         }
         let vcUri: string = this.defaultUriSchemeUtil.getRootFolder(vcInfo.serviceGuid);
         return this.authorizationService.checkPrivileges(vcUri, [
               "VirtualMachine.Provisioning.ModifyCustSpecs",
               "VirtualMachine.Provisioning.ReadCustSpecs"]).then((results: boolean[]) => {
            let hasModifyPrivilege: boolean = results[0];
            let hasReadPrivilege: boolean = results[1];
            if (!hasModifyPrivilege || !hasReadPrivilege) {
               return this.$q.resolve([
                  this.i18nService.getString(
                        "VmUi", "customizationSpec.nameAndOsPage.noPrivileges")
               ]);
            }
            return this.$q.resolve([]);
         });
      }
   }

   angular.module("com.vmware.vsphere.client.vm")
         .service("gosNameAndOsValidationService", GosNameAndOsValidationService);
}

