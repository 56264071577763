module hostprofile_ui {

    import IPromise = angular.IPromise;

    class CreateHostProfileController implements angular.IController {

        public static $inject = [
            "$scope",
            "i18nService",
            "vuiConstants",
            "mutationService",
            "$q",
            "createHostProfileService"];

        public profileName: string;
        public profileDescription: string;
        private objectId: string;
        private isExtract: boolean;
        public pageErrors: any;

        constructor(private $scope: any, private i18nService: any, private vuiConstants: any,
                    private mutationService: any, private $q: any,
                    private createHostProfileService: CreateHostProfileService) {
            $scope.modalOptions.onSubmit = this.submitProfile.bind(this);
            this.objectId = this.$scope.modalOptions.dialogData.objectId;
            this.isExtract = this.$scope.modalOptions.dialogData.isExtractAction;
            this.profileName = this.isExtract ?  this.i18nService.getString("HostProfileUi",
                "createProfileWizard.defaultProfileName") : "";
        };

        private submitProfile(): boolean {
            this.$scope.modalOptions.isAlertClosed = true;
            this.$scope.modalOptions.alerts = [];
           this.pageErrors = null;

            if (this.profileName) {
                let profileNameSpec: any = {
                    profileName: this.profileName,
                    isNewHostProfile: true
                };

                this.mutationService.validate(
                    this.objectId,
                    "com.vmware.vsphere.client.hostprofile.data.HostProfileNameValidationSpec",
                    profileNameSpec
                ).then((validationResult: any) => {
                    if (!validationResult.result) {
                       this.pageErrors = this.i18nService.getString("HostProfileUi", "hpRename.validation.uniqueName.error",
                          this.profileName);
                    } else {
                        let createProfileSpec: any = this.isExtract ? this.createHostProfileService
                            .getCreateProfileFromHostSpec(this.profileName, this.profileDescription, this.objectId) :
                            this.createHostProfileService.getDuplicateProfileSpec(this.profileName,
                                this.profileDescription, this.objectId);
                        this.mutationService.add(
                            createProfileSpec._type,
                            createProfileSpec
                        );
                        this.$scope.closeModal();
                    }
                });
            } else {
               this.pageErrors = this.i18nService.getString("HostProfileUi", "importProfile.noProfileName.error");
            }

            return false;
        }
    }

    angular.module("com.vmware.vsphere.client.hostprofile")
        .controller("CreateHostProfileController", CreateHostProfileController);
}
