/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace dvs_ui {

   import IPromise = angular.IPromise;
   import DvsHealthCheckSettingsData =
         com.vmware.vsphere.client.h5.network.dvs.healthcheck.model.DvsHealthCheckSettingsData;

   export class DvsHealthCheckManageViewComponent {

      public templateUrl: string;
      public bindings: any;
      public controller: any;

      constructor() {
         this.bindings = {};
         this.templateUrl =
               "dvs-ui/resources/dvs/healthcheck/manage/dvsHealthCheckManageViewTemplate.html";
         this.controller = DvsHealthCheckManageViewController;
      }
   }

   class DvsHealthCheckManageViewController {

      static $inject = [
            "$scope",
         "$rootScope",
         "i18nService",
         "vxPropertyViewService",
         "dataService"];

      public healthCheckManagePropertyViewData: any;

      public dvsUri: string;

      public title: string;

      public action: any;

      public liveRefreshProperties: string[];

      private static HEALTH_CHECK_DETAILS_DATA_PROPERTY =
            "dvsHealthCheckManage:dvsHealthCheckSettingsData";

      public constructor(private $scope: any,
                         private $rootScope: any,
                         private i18nService: any,
                         private propertyViewService: any,
                         private dataService: any) {

         this.liveRefreshProperties = [
            "config.healthCheckConfig"
         ];
      }

      public $onChanges() {
         this.refreshPropertyView();
      }

      private refreshPropertyView(){

         this.$scope.healthCheckManageLoading = true;

         this.dvsUri = this.$rootScope._route.objectId;

         this.title = this.i18nService.getString("DvsUi",
                  "healthCheck.manage.view.title");

         this.action = [{
            actionUid: "vsphere.core.dvs.editHealthCheckSettings",
            customLabel: this.i18nService.getString(
                  "DvsUi", "DvsPropertiesView.edit.label")
         }];

         let requestPromise: IPromise<DvsHealthCheckSettingsData> =
               this.dataService.getProperties(this.dvsUri,
                     [DvsHealthCheckManageViewController
                           .HEALTH_CHECK_DETAILS_DATA_PROPERTY]);

         requestPromise.then((healthCheckManageResult: any): void => {
            if (healthCheckManageResult &&
                  healthCheckManageResult[DvsHealthCheckManageViewController
                        .HEALTH_CHECK_DETAILS_DATA_PROPERTY]) {

               let healthCheckSettingsData =
                     healthCheckManageResult[DvsHealthCheckManageViewController
                           .HEALTH_CHECK_DETAILS_DATA_PROPERTY];
               this.healthCheckManagePropertyViewData =
                     this.buildPropertyView(healthCheckSettingsData.vlanMtuEnabledLabel,
                           healthCheckSettingsData.teamingAndFailoverEnabledLabel);

            } else {
               this.healthCheckManagePropertyViewData =
                     this.buildPropertyView(this.i18nService.getString(
                           "DvsUi", "healthCheck.manage.view.na"),
                           this.i18nService.getString(
                                 "DvsUi", "healthCheck.manage.view.na"));
            }

            this.$scope.healthCheckManageLoading = false;
         });
      }

      private buildPropertyView(vlanMtuEnabledValue: string,
                                teamingAndFailoverEnabledValue: string ): any {
         let builder = this.propertyViewService.createPropertyViewBuilder();

         let propertiesCategory: any = builder.category("properties");
         let generalSection: any = propertiesCategory.section("general");

         generalSection.property(this.i18nService.getString(
               "DvsUi", "healthCheck.manage.view.vlanMtuProperty"),
               vlanMtuEnabledValue);

         generalSection.property(this.i18nService.getString(
               "DvsUi", "healthCheck.manage.view.teamingProperty"),
               teamingAndFailoverEnabledValue);

         return builder.build();
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .component("dvsHealthCheckManageView" ,
               new DvsHealthCheckManageViewComponent());
}