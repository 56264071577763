/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace h5_vm {
   class VmProvisioningSelectTemplatePageModelFactory {
      public static $inject = [
         "$q",
         "i18nService",
         "dataService",
         "authorizationService",
         "defaultUriSchemeUtil",
         "managedEntityConstants",
         "creationTypeConstants",
         "wizardPageService"
      ];

      constructor($q: angular.IQService,
            i18nService: any,
            dataService: any,
            authorizationService: any,
            defaultUriSchemeUtil: any,
            managedEntityConstants: any,
            creationTypeConstants: any,
            wizardPageService: any
            ) {
         return (virtualMachineSpecBuilder: any, wizardViewData: any, wizardConfig: any) => {
            return new VmProvisioningSelectTemplatePageModel(
                  $q,
                  i18nService,
                  dataService,
                  authorizationService,
                  defaultUriSchemeUtil,
                  managedEntityConstants,
                  creationTypeConstants,
                  wizardPageService,
                  virtualMachineSpecBuilder,
                  wizardViewData,
                  wizardConfig);
         };
      }
   }

   export class VmProvisioningSelectTemplatePageModel implements VmProvisioningPageModel<void> {

      static readonly $inject: string[] = [
         "$q",
         "i18nService",
         "dataService",
         "authorizationService",
         "defaultUriSchemeUtil",
         "managedEntityConstants",
         "creationTypeConstants",
         "wizardPageService"
      ];
      private readonly RUNTIME_CONNECTION_STATE: string = "runtime/connectionState";
      private readonly GUEST_OS_ID: string = "config/guestId";
      private readonly VAPP_PROPERTY_DESCRIPTORS_PROPERTY: string = "config.vAppConfig.property";
      private readonly INVALID_STATE = this.i18nService.getString("VmUi",
            "SelectTemplateProvisioningPage.Template.Provisioning.InvalidState");
      private readonly INVALID_ENTITY_ERROR = this.i18nService.getString("VmUi",
            "SelectTemplateProvisioningPage.Template.SelectValidEntity");
      private readonly MARK_AS_VM_PRIVILEGE = "VirtualMachine.Provisioning.MarkAsVM";
      private readonly CLONE_TEMPLATE_PRIVILEGE = "VirtualMachine.Provisioning.CloneTemplate";
      private readonly CONNECTED = "connected";

      public virtualMachineSpecBuilder: any;
      public wizardViewData: any;
      public config: any;
      public hasActionPrivileges: boolean;
      public showLoadingIndicator: boolean = true;

      private _originalWizardTitle: string;

      private _selectionId?: string;
      private _selectionIsTemplate: boolean;

      private compatibilityResultsCache: { vmProperties: any, privileges: any } | undefined;
      private lastSelectedTemplateId?: string;
      private lastSelectedCreationType: string;
      private selectedTemplateChanged: boolean;

      constructor(private $q: angular.IQService,
            private i18nService: any,
            private dataService: any,
            private authorizationService: any,
            protected defaultUriSchemeUtil: any,
            protected managedEntityConstants: any,
            private creationTypeConstants: any,
            private wizardPageService: any,
            virtualMachineSpecBuilder: any,
            wizardViewData: any,
            wizardConfig: any) {
         this.virtualMachineSpecBuilder = virtualMachineSpecBuilder;
         this.wizardViewData = wizardViewData;
         this.config = wizardConfig;
      }

      updateSelection(item: VxTreeViewItem): boolean {
         const SELECTED_TYPE: string =
               this.defaultUriSchemeUtil.getPartsFromVsphereObjectId(item.id).type;

         this._selectionId = item.id;
         this._selectionIsTemplate =
               SELECTED_TYPE === this.managedEntityConstants.VIRTUAL_MACHINE;

         this.selectedTemplateChanged = this.lastSelectedTemplateId !== this._selectionId;
         if (!this._selectionIsTemplate) {
            return false;
         }

         this.virtualMachineSpecBuilder.setVmId(item.id);
         this.wizardViewData.setSourceObjectName(item.text);
         return true;
      }

      clearSelection() {
         this._selectionId = undefined;
         this._selectionIsTemplate = false;
      }

      getSelectionId(): string|undefined {
         return this._selectionId;
      }

      getSelectionIsTemplate(): boolean {
         return !!this._selectionIsTemplate;
      }

      getLastSelectedCreationType(): string {
         return this.lastSelectedCreationType;
      }

      validateSelection(): angular.IPromise<string> {
         this.wizardPageService.clearErrors(this.config);
         this.lastSelectedCreationType = this.virtualMachineSpecBuilder.getCreationType();
         if (this.lastSelectedTemplateId
               && this.lastSelectedTemplateId === this._selectionId
               && !_.isUndefined(this.compatibilityResultsCache)) {
            return this.setPrivilegesAndConnectionState();
         }
         if (this._selectionIsTemplate) {
            return this.$q.all({
               vmProperties: this.dataService.getProperties(this._selectionId,
                     [this.RUNTIME_CONNECTION_STATE, this.GUEST_OS_ID, this.VAPP_PROPERTY_DESCRIPTORS_PROPERTY]),
               privileges: this.authorizationService.checkGrantedPrivileges(this._selectionId,
                     [this.getRequiredPermission(this.virtualMachineSpecBuilder.getCreationType())])
            }).then((result: { vmProperties: any, privileges: any }): VmProvisioningValidationError => {
               this.compatibilityResultsCache = result;
               this.lastSelectedTemplateId = this._selectionId;
               return this.setPrivilegesAndConnectionState();
            });
         }

         return this.$q.when("");
      }

      setPrivilegesAndConnectionState(): angular.IPromise<string> {
         if (!this.compatibilityResultsCache) {
            return this.$q.when("");
         }
         const CONNECTION_STATE =
               this.compatibilityResultsCache.vmProperties[this.RUNTIME_CONNECTION_STATE];
         this.wizardViewData.setVmConnectedState(CONNECTION_STATE);
         this.virtualMachineSpecBuilder.setGuestOsId(
               this.compatibilityResultsCache.vmProperties[this.GUEST_OS_ID]);
         this.virtualMachineSpecBuilder.setVappPropertyDescriptors(
               this.compatibilityResultsCache.vmProperties[this.VAPP_PROPERTY_DESCRIPTORS_PROPERTY]
         );
         let compatibilityMessage: string = this.makeCompatibilityMessage(
               this.compatibilityResultsCache.privileges, CONNECTION_STATE);
         return this.$q.resolve(compatibilityMessage);
      }

      /**
       * Set model to an initial state (the model is caching just the last selected template
       * so it is preselected the next time when this model's page is opened) but invalidate the
       * connection state and privileges cache.
       */
      reset(): void {
         this.compatibilityResultsCache = undefined;
      }

      resetForm(): void {}

      validatePage(): angular.IPromise<VmProvisioningValidationError> {
         if (!this._selectionIsTemplate) {
            return this.$q.resolve({error: this.INVALID_ENTITY_ERROR});
         }

         if (this.wizardViewData.getVmConnectedState() !== this.CONNECTED) {
            return this.$q.resolve({error: this.INVALID_STATE});
         }

         if (!this.hasActionPrivileges) {
            return this.$q.resolve({error: this.createNoPrivilegesErrorString(this.virtualMachineSpecBuilder.getCreationType())});
         }

         return this.$q.resolve({});
      }

      submitPage(): VmProvisioningPageSubmitResult {
         if (!this._originalWizardTitle || this.config.title.indexOf(this._originalWizardTitle) === -1) {
            this._originalWizardTitle = this.config.title;
         }

         this.config.title = this.wizardViewData.getSourceObjectName() + " - " +
               this._originalWizardTitle;

         let pages = this.config.pages;
         const flows = this.config.flows;

         if (flows && flows.length > 0) {
            let flow = _.find(flows, (flow: any) => {
               return this.virtualMachineSpecBuilder.getCreationType() === flow.id;
            });
            if (flow) {
               pages = flow.pages;
            }
         }

         if (this.creationTypeConstants.isCloning(
               this.virtualMachineSpecBuilder.getCreationType())) {
            if (this.virtualMachineSpecBuilder.hasConfigurableVappProperties()) {
               this.wizardPageService.markPageDisabled(
                     pages,
                     this.i18nService.getString(
                           'VmUi', 'VAppPropertiesProvisioningPage.title'));
            } else {
               this.wizardPageService.markPageSkipped(
                     pages,
                     this.i18nService.getString(
                           'VmUi', 'VAppPropertiesProvisioningPage.title'));
            }
         }
         return {
            wizardFlow: this.virtualMachineSpecBuilder.getCreationType(),
            invalidateNextPages: this.selectedTemplateChanged
         };
      }

      makeCompatibilityMessage(privilegeStatuses: any, connectionState: any): string {
         this.hasActionPrivileges = true;

         if (connectionState !== this.CONNECTED) {
            return this.INVALID_STATE;
         }

         for (let i = 0; i < privilegeStatuses.length; i++) {
            if (!privilegeStatuses[i]) {
               this.hasActionPrivileges = false;
               break;
            }
         }
         if (this.hasActionPrivileges) {
            return "";
         }
         return this.createNoPrivilegesErrorString(this.virtualMachineSpecBuilder.getCreationType());
      }

      createNoPrivilegesErrorString(creationType: string): string {
         let key: string = "SelectTemplateProvisioningPage.Template.NoPermission." +
               this.getRequiredPermission(creationType);
         return this.i18nService.getString("VmUi", key);
      }

      getRequiredPermission(creationType: string): string {
         return (creationType === this.creationTypeConstants.CONVERT_TEMPLATE_TO_VM) ? this.MARK_AS_VM_PRIVILEGE :
               (creationType === this.creationTypeConstants.CLONE_TEMPLATE_TO_TEMPLATE
                  || creationType === this.creationTypeConstants.CLONE_TEMPLATE_TO_VM) ? this.CLONE_TEMPLATE_PRIVILEGE : "";
      }
   }

   angular.module("com.vmware.vsphere.client.vm")
         .service("vmProvisioningSelectTemplatePageModel",
               VmProvisioningSelectTemplatePageModelFactory);
}
