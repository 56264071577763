/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace h5_vm {
   import SupportedEvcMode = com.vmware.vsphere.client.h5.vm.model.SupportedEvcMode;
   import VmEvcService = h5_vm.VmEvcService;
   import VmEvcData$VmEvcVendor = com.vmware.vsphere.client.h5.vm.model.VmEvcData$VmEvcVendor;
   import VmEditEvcData = com.vmware.vsphere.client.h5.vm.model.VmEditEvcData;

   export class EditVmEvcController {
      static $inject = ["i18nService", "vmEvcService", "$rootScope"];

      i18n: Function;
      vmId: string;
      onSubmit: Function;
      loading: boolean;

      evcVendorsHeader: Object;
      evcModeDescriptionHeader: Object;
      evcVendors: any;
      evcModesByVendorMap: any;
      vendorEvcModes: Array<SupportedEvcMode> = [];
      selectedEvcVendor: string;
      isCurrentEvcModeCustom: boolean;
      selectedEvcMode: SupportedEvcMode;
      unbindEvcVendorWatch: Function;

      constructor(private i18nService: any,
            private vmEvcService: VmEvcService,
            private $rootScope: angular.IScope) {
         this.i18n = i18nService.getString;
      }

      $onInit(): void {
         this.onSubmit = this.onSubmit || this.applyEvcMode.bind(this);
         this.initEvcVendors();
         this.loadVmEvcData();
         this.addVendorChangeListener();
      }

      $onDestroy(): void {
         this.removeVendorChangeListener();
      }

      isFakeEvcVendor(): boolean {
         return this.selectedEvcVendor === this.evcVendors.NONE ||
               this.selectedEvcVendor === this.evcVendors.CUSTOM;
      }

      applyEvcMode(): boolean {
         if (this.selectedEvcVendor !== this.evcVendors.CUSTOM) {
            this.vmEvcService.applyEvcMode(this.vmId, this.selectedEvcMode.featureMasks);
         }

         return true;
      }

      private initEvcVendors(): void {
         this.evcVendorsHeader = {
            title: this.i18n("VmUi", "vmEvc.edit.selectEvcMode")
         };

         this.evcModeDescriptionHeader = {
            title: this.i18n("VmUi", "vmEvc.edit.evcModeDescription")
         };

         this.evcVendors = {
            NONE: "NONE" as VmEvcData$VmEvcVendor,
            AMD: "AMD" as VmEvcData$VmEvcVendor,
            INTEL: "INTEL" as VmEvcData$VmEvcVendor,
            CUSTOM: "CUSTOM" as VmEvcData$VmEvcVendor
         };
      }

      private loadVmEvcData(): void {
         this.setPageBusy(true);
         this.vmEvcService.loadEditEvcData(this.vmId)
               .then((evcData: VmEditEvcData) => {
                  if (!evcData) {
                     return;
                  }
                  this.evcModesByVendorMap = evcData.evcModesByVendor;
                  this.selectedEvcVendor = evcData.vendor;
                  this.isCurrentEvcModeCustom =
                        this.selectedEvcVendor === this.evcVendors.CUSTOM;
                  this.onVendorChange();
                  this.selectEvcModeByKey(evcData.key);
               })
               .then(() => {
                  this.setPageBusy(false);
               });
      }

      private addVendorChangeListener(): void {
         this.unbindEvcVendorWatch = this.$rootScope.$watch(
               () => {
                  return this.selectedEvcVendor;
               },
               (newValue: string, oldValue: string) => {
                  if (newValue === oldValue) {
                     return;
                  }
                  this.onVendorChange();
                  this.selectFirstEvcModeIfNeeded();
               });
      }

      private removeVendorChangeListener(): void {
         if (this.unbindEvcVendorWatch) {
            this.unbindEvcVendorWatch();
         }
      }

      private onVendorChange(): void {
         if (!this.evcModesByVendorMap) {
            this.vendorEvcModes = [];
            return;
         }

         for (let evcVendor in this.evcModesByVendorMap) {
            if (evcVendor === this.selectedEvcVendor) {
               this.vendorEvcModes = this.evcModesByVendorMap[evcVendor];
               break;
            }
         }
      }

      private selectEvcModeByKey(modeKey: string) {
         this.selectedEvcMode = _.find(this.vendorEvcModes, (evcMode: SupportedEvcMode) => {
            return evcMode.key === modeKey;
         });
      }

      private selectFirstEvcModeIfNeeded(): void {
         if (_.isEmpty(this.vendorEvcModes)) {
            return;
         }

         if (_.contains(this.vendorEvcModes, this.selectedEvcMode)) {
            return;
         }

         this.selectedEvcMode = this.vendorEvcModes[0];
      }

      private setPageBusy(isBusy: boolean): void {
         this.loading = isBusy;
      }
   }

   export class EditVmEvcComponent {
      public controller: any;
      public templateUrl: string;
      public bindings: any;

      constructor() {
         this.controller = EditVmEvcController;
         this.templateUrl =
               "vm-ui/resources/vm/evc-mode/edit-vm-evc.component.html";
         this.bindings = {
            vmId: "<",
            onSubmit: "="
         };
      }
   }

   angular.module("com.vmware.vsphere.client.vm")
         .component("editVmEvc", new EditVmEvcComponent());
}