(function () {
   'use strict';

   angular.module('com.vmware.vsphere.client.networkLibUi').service('vmKernelAdapterService', vmKernelAdapterService);

   vmKernelAdapterService.$inject = ['dataService', 'i18nService', 'columnRenderersRegistry'];

   function vmKernelAdapterService(dataService, i18nService, columnRenderersRegistry) {

      var virtualAdaptersDataProperty = "vnic:virtualAdaptersData";
      var vnicStringLength = 'vmk'.length;

      function buildColumnDefinitions(showServiceColumns) {
         return {
            device: {
               displayName: i18nService.getString('NetworkUi', 'VnicDataGridView.deviceColumnHeader'),
               field: 'device',
               width: '150px',
               template: objectLinkRendererFactory('device', {default: 'network-lib-ui-icon-virtualAdapter'}),
               sortable: function(item1, item2) {
                  return sortVnics(item1.device, item2.device);
               }
            },
            networkLabel: {
               displayName: i18nService.getString('NetworkUi', 'VnicDataGridView.networkLabelColumnHeader'),
               field: 'networkLabel',
               width: '150px',
               template: objectLinkRendererFactory('networkLabel', {
                  dvs: 'vsphere-icon-virtual-port-group',
                  opaqueNetwork: 'vsphere-icon-cloud-network',
                  default: 'vsphere-icon-network'
               })
            },
            virtualSwitch: {
               displayName: i18nService.getString('NetworkUi', 'VnicDataGridView.switchColumnHeader'),
               field: 'virtualSwitch',
               width: '150px',
               template: objectLinkRendererFactory('virtualSwitch', {
                  dvs: 'vsphere-icon-dv-switch',
                  opaqueNetwork: 'network-lib-ui-icon-opaqueSwitch',
                  default: 'network-lib-ui-icon-vswitch'
               })
            },
            firstIpAddress: {
               displayName: i18nService.getString('NetworkUi', 'VnicDataGridView.ipAddressColumnHeader'),
               field: 'firstIpAddress',
               width: '150px'
            },
            networkStackName: {
               displayName: i18nService.getString('NetworkUi', 'VnicDataGridView.networkStack'),
               field: 'networkStackName',
               width: '150px'
            },
            isVmotionEnabled: {
               displayName: i18nService.getString('NetworkUi', 'VnicDataGridView.vMotionColumnHeader'),
               field: 'localizedVmotionEnabled',
               width: '150px',
               visible: showServiceColumns
            },
            isProvisioningNfcTrafficEnabled: {
               displayName: i18nService.getString('NetworkUi', 'VnicDataGridView.provisioningNfcTrafficColumnHeader'),
               field: 'localizedProvisioningNfcTrafficEnabled',
               width: '150px',
               visible: showServiceColumns
            },
            isFtEnabled: {
               displayName: i18nService.getString('NetworkUi', 'VnicDataGridView.ftColumnHeader'),
               field: 'localizedFtEnabled',
               width: '150px',
               visible: showServiceColumns
            },
            isManagementTrafficEnabled: {
               displayName: i18nService.getString('NetworkUi', 'VnicDataGridView.managementTrafficColumnHeader'),
               field: 'localizedManagementTrafficEnabled',
               width: '150px',
               visible: showServiceColumns
            },
            isVrTrafficEnabled: {
               displayName: i18nService.getString('NetworkUi', 'VnicDataGridView.vRTrafficColumnHeader'),
               field: 'localizedVrTrafficEnabled',
               width: '150px',
               visible: showServiceColumns
            },
            isVrNfcTrafficEnabled: {
               displayName: i18nService.getString('NetworkUi', 'VnicDataGridView.vRNfcTrafficColumnHeader'),
               field: 'localizedVrNfcTrafficEnabled',
               width: '150px',
               visible: showServiceColumns
            },
            isVsanEnabled: {
               displayName: i18nService.getString('NetworkUi', 'VnicDataGridView.vsanColumnHeader'),
               field: 'localizedVsanEnabled',
               width: '150px',
               visible: showServiceColumns
            },
            isVsanWitnessTrafficEnabled: {
               displayName: i18nService.getString('H5NetworkUi', 'VnicPortPropertiesPage.vsanWitnessTraffic'),
               field: 'localizedVsanWitnessTrafficEnabled',
               width: '150px',
               visible: showServiceColumns
            }
         };
      }

      return {
         getVnics: function (hostId, showServiceColumns) {
            return dataService.getProperties(hostId, [virtualAdaptersDataProperty]).then(function (dataServiceResponse) {
               return formatResponse(dataServiceResponse, showServiceColumns);
            });
         },
         getVnicDetailsData: function (hostId, vnicDevice) {
            var virtualAdapterDetailsDataProperty = "vnic:virtualAdapterDetailsData";

            var params = [{
               propertyName: virtualAdapterDetailsDataProperty,
               parameterType: 'java.lang.String',
               parameter: vnicDevice
            }];

            return dataService.getProperties(
                  hostId,
                  [virtualAdapterDetailsDataProperty],
                  {
                     propertyParams: params
                  }).then(function(response) {

               return response && response[virtualAdapterDetailsDataProperty];
            });
         }
      };

      function formatResponse(dataServiceResponse, showServiceColumns) {
         var vnics = dataServiceResponse ? dataServiceResponse[virtualAdaptersDataProperty] : undefined;

         if (vnics && vnics.items) {
            vnics.items.forEach(function (vnic) {
               vnic.localizedVmotionEnabled = getEnabledDisabledLocalizedText(vnic.isVmotionEnabled);
               vnic.localizedProvisioningNfcTrafficEnabled =
                     getEnabledDisabledLocalizedText(vnic.isProvisioningNfcTrafficEnabled);
               vnic.localizedFtEnabled = getEnabledDisabledLocalizedText(vnic.isFtEnabled);
               vnic.localizedManagementTrafficEnabled = getEnabledDisabledLocalizedText(vnic.isManagementTrafficEnabled);
               vnic.localizedVrTrafficEnabled = getEnabledDisabledLocalizedText(vnic.isVrTrafficEnabled);
               vnic.localizedVrNfcTrafficEnabled = getEnabledDisabledLocalizedText(vnic.isVrNfcTrafficEnabled);
               vnic.localizedVsanEnabled = getEnabledDisabledLocalizedText(vnic.isVsanEnabled);
               vnic.localizedVsanWitnessTrafficEnabled = getEnabledDisabledLocalizedText(vnic.isVsanWitnessTraficEnabled);
            });
         }

         var hostFeatures = vnics ? vnics.hostFeatures : undefined;
         var isVsanWitnessTrafficSupported = vnics ? vnics.isVsanWitnessTrafficSupported : false;
         var columns = buildColumns(hostFeatures, isVsanWitnessTrafficSupported, showServiceColumns);
         return {
            'vnic:virtualAdaptersData': vnics,
            vnicColumnDefs: columns
         };
      }

      function buildColumns(hostFeatures, isVsanWitnessTrafficSupported, showServiceColumns) {
         var columns = [];

         var columnDefinitions = buildColumnDefinitions(showServiceColumns);

         columns.push(columnDefinitions.device);
         columns.push(columnDefinitions.networkLabel);
         columns.push(columnDefinitions.virtualSwitch);
         columns.push(columnDefinitions.firstIpAddress);

         if (hostFeatures && hostFeatures.isTcpIpStackSupported) {
            columns.push(columnDefinitions.networkStackName);
         }

         columns.push(columnDefinitions.isVmotionEnabled);

         if (hostFeatures && hostFeatures.isProvisioningNfcTrafficSupported) {
            columns.push(columnDefinitions.isProvisioningNfcTrafficEnabled);
         }

         columns.push(columnDefinitions.isFtEnabled);
         columns.push(columnDefinitions.isManagementTrafficEnabled);

         if (hostFeatures && hostFeatures.isVrTrafficSupported) {
            columns.push(columnDefinitions.isVrTrafficEnabled);
         }

         if (hostFeatures && hostFeatures.isVrNfcTrafficSupported) {
            columns.push(columnDefinitions.isVrNfcTrafficEnabled);
         }

         if (hostFeatures && hostFeatures.isVsanSupported) {
            columns.push(columnDefinitions.isVsanEnabled);
         }

         if (isVsanWitnessTrafficSupported) {
            columns.push(columnDefinitions.isVsanWitnessTrafficEnabled);
         }

         return columns;
      }

      function objectLinkRendererFactory(field, iconMapping) {
         return (function (data) {

            if (!data[field]) {
               return "<span class='network-list-empty-cell'>" +
                         i18nService.getString('NetworkUi', 'NetworkUtil.noData') +
                      "</span>";
            }

            var iconName = iconMapping[data['connectedTo']] || iconMapping['default'];
            var objectLinkRenderer = columnRenderersRegistry.getColumnRenderer('object-link');
            return objectLinkRenderer([undefined, field, iconName], data);
         });
      }

      function getEnabledDisabledLocalizedText(enabled) {
         return enabled ?
               i18nService.getString('NetworkUi', 'NetworkUtil.enabled') :
               i18nService.getString('NetworkUi', 'NetworkUtil.disabled');
      }

      function sortVnics(firstVnic, secondVnic) {
         if (!firstVnic && !secondVnic) {
            return 0;
         } else if (!firstVnic && secondVnic) {
            return 1;
         } else if(firstVnic && !secondVnic) {
            return -1;
         }


         var firstVnicIndex = parseInt(firstVnic.substring(vnicStringLength));
         var secondVnicIndex = parseInt(secondVnic.substring(vnicStringLength));

         if (isNaN(firstVnicIndex) && isNaN(secondVnicIndex)) {
            return 0;
         } else if (isNaN(firstVnicIndex) && !isNaN(secondVnicIndex)) {
            return 1;
         } else if(!isNaN(firstVnicIndex) && isNaN(secondVnicIndex)) {
            return -1;
         }

         return (firstVnicIndex < secondVnicIndex
            ? -1
            : (firstVnicIndex === secondVnicIndex ? 0 : 1));
      }
   }
})();