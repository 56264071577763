(function() {
   'use strict';
   angular.module('com.vmware.vsphere.client.cluster')
         .controller('AddClusterRuleMemberController', AddClusterRuleMemberController);

   AddClusterRuleMemberController.$inject = ['$scope', 'i18nService'];

   function AddClusterRuleMemberController($scope, i18nService) {

      var clusterId = $scope.modalOptions.dialogData.objectId;
      var dialogData = $scope.modalOptions.dialogData;
      var selectedVms = getSelectedVmsManagedObjectRefs(dialogData.selectedVms);
      var selectorConfig = {
         objectType: "VirtualMachine",
         listViewId: "vsphere.core.vm.list"
      };

      var filter = 'clusterVmsExceptSecondaryVmsExcludeVmsFilter';

      // use different filter if there are no members to be excluded because
      // clusterVmsExceptSecondaryVmsFilter cannot be instantiated with an empty param
      if (selectedVms.length === 0) {
         filter = 'clusterVmsExceptSecondaryVmsFilter';
      }

      this.config = {
         contextObject: clusterId,
         multipleSelect: true,
         listTabConfig: {
            listConfig: [{
               label: i18nService.getString('Common', 'typeResource.vmPlural'),
               listViewId: selectorConfig.listViewId,
               dataModels: [selectorConfig.objectType],
               filterId: filter,
               filterParams: selectedVms
            }]
         },
         onSelectionChanged: function(newSelectedItems) {
            dialogData.selectedItems = newSelectedItems;
         }
      };
   }

   function getSelectedVmsManagedObjectRefs(selectedVms){
      return _.map(selectedVms, function(selectedVm) {
         return selectedVm.id;
      });
   }
})();
