/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
(function() {
   'use strict';

   angular.module('com.vmware.vsphere.client.vm')
         .service('datastoreRecommendationService', datastoreRecommendationService);

   datastoreRecommendationService.$inject = [
      'mutationService',
      'i18nService',
      'defaultUriSchemeUtil',
      'datastoreRecommendationsDialogService',
      'datastoreFaultsDialogService',
      'managedEntityConstants'];

   function datastoreRecommendationService(
         mutationService,
         i18nService,
         defaultUriSchemeUtil,
         datastoreRecommendationsDialogService,
         datastoreFaultsDialogService,
         managedEntityConstants) {
      return {
         getPlacementRecommendations: getPlacementRecommendations,
         findTopRecommendation: findTopRecommendation,
         findTopRecommendationInfo: findTopRecommendationInfo,
         openRecommendationsDialog: openRecommendationsDialog,
         openRecommendationsDialogDirect: openRecommendationsDialogDirect,
         openFaultsDialog: openFaultsDialog,
         areRecommendationsAvailableForStorageObject: areRecommendationsAvailableForStorageObject
      };

      function getPlacementRecommendations(spec, specType) {
         if (!spec || !specType || !spec.podSelectionSpec) {
            return null;
         }

         var isSpecValid = false;
         var targetObjId;

         switch (specType) {
            case 'com.vmware.vsphere.client.vm.VmCloneSpec':
            case 'com.vmware.vsphere.client.vm.VmConfigSpec':
               isSpecValid = !!spec.vm;
               targetObjId = defaultUriSchemeUtil.getVsphereObjectId(spec.vm);
               break;
            case 'com.vmware.vsphere.client.vm.VmCreateSpec':
               isSpecValid = !!spec.pool;
               targetObjId = defaultUriSchemeUtil.getVsphereObjectId(spec.pool);
               break;
            default:
               break;
         }

         if (!isSpecValid) {
            return null;
         }

         return mutationService.validate(targetObjId, specType, spec).then(function(data) {
            if (data.result) {
               return {
                  recommendations: data.result.recommendations,
                  faults: getFaultsList(data.result.drsFault)
               };
            }

            // We should return some generic fault if we can't find a specific one
            var faultMsg = i18nService.getString('VmUi', 'SdrsRecommendations.faults.generic');
            var message = "";
            // Specific faults handled here
            var error = data.error;
            if (error) {
               if (error.message === "vim.fault.NoCompatibleDatastore") {
                  message = i18nService.getString('VmUi', 'SdrsRecommendations.faults.noCompatibleDatastore');
               } else {
                  // the same as in mutation.service#displayErrors
                  message = error.localizedMessage || error.message;
                  if (!message && error.cause) {
                     message = error.cause.localizedMessage || error.cause.message;
                  }
               }
            }

            return {
               faults: [{
                  message: message || faultMsg
               }]
            };
         });
      }

      function findTopRecommendation(recommendations) {
         if (!recommendations || recommendations.length === 0) {
            return null;
         }
         var topPlacementRecommendation = getTopPlacementRecommendation(recommendations);
         if (!topPlacementRecommendation) {
            return null;
         }

         return topPlacementRecommendation;
      }

      function findTopRecommendationInfo(recommendations) {
         var topPlacementRecommendation = findTopRecommendation(recommendations);
         if (!topPlacementRecommendation) {
            return null;
         }

         var topRecommendedDatastorePerDisk =
               findTopRecommendedDatastorePerDisk([topPlacementRecommendation]);

         return {
            topRecommendation: topPlacementRecommendation,
            topRecommendedDatastorePerDisk: topRecommendedDatastorePerDisk,
            topRecommendedDatastoreForVmx: getDatastoreForVmx(topPlacementRecommendation)
         };
      }

      /**
       * Opens up a recommendations dialog.
       * @param diskKey - id the of virtual disk the recommendations are for.
       * @param scope - scope object of the controller
       * @param model - provisioningFinishModel representing the RTC page of the
       *       provisioning wizards
       */
      function openRecommendationsDialog(diskKey, scope, model) {
         var preselectRecommendation = null;
         var diskRecommendationsByKey = getRecommendationsByKey(diskKey, model);
         if(diskRecommendationsByKey.length > 0) {
            var podId = defaultUriSchemeUtil
                  .getVsphereObjectId(diskRecommendationsByKey[0].target);
            preselectRecommendation = model.getSelectedRecommendation(podId);
         }
         return datastoreRecommendationsDialogService.openDialog(
               diskRecommendationsByKey,
               scope.wizardViewData.getStorageNameMap(),
               preselectRecommendation,
               model.getVmName()
         ).then(function(results) {
            model.setSelectedRecommendation(results.recommendationSpec.spec);
         });
      }

      function openRecommendationsDialogDirect(model, recommendation) {
         return datastoreRecommendationsDialogService.openDialog(
            recommendation,
            model.storageNameMap,
            null,
            model.vmName
         );
      }

      /**
       * Opens up a Fault dialog
       * @param scope - scope object of the controller
       * @param recommendationFaults - array with faults to be displayed
       */
      function openFaultsDialog(scope, recommendationFaults) {
         datastoreFaultsDialogService.openDialog(scope, recommendationFaults);
      }

      // ------------------- Private methods ---------------------- //

      function getTopPlacementRecommendation(recommendations) {
         var topRec = _.max(recommendations, function(recommendation) {
            var recHasPlacementAction = hasStoragePlacementAction(recommendation);
            return recHasPlacementAction
                  ? recommendation.rating
                  : -1;
         });
         return topRec;
      }

      function getDatastorePerDisk(recommendation) {
         var datastorePerDisk = {};
         angular.forEach(recommendation.action, function(action) {
            if (isStoragePlacementAction(action) && action.relocateSpec.disk) {
               angular.forEach(action.relocateSpec.disk, function(disk) {
                  datastorePerDisk[disk.diskId] = action.destination;
               });
            }
         });

         return datastorePerDisk;
      }

      function getDatastoreForVmx(recommendation) {
         var action = _.find(recommendation.action, function(action) {
                  return isStoragePlacementAction(action) && !action.relocateSpec.disk;
               }) || {};

         return action.destination;
      }

      function hasStoragePlacementAction(recommendation) {
         var action = _.find(recommendation.action, function(action) {
            return isStoragePlacementAction(action);
         });
         return angular.isDefined(action);
      }

      function isStoragePlacementAction(action) {
         return action._type.indexOf('StoragePlacementAction') > -1;
      }

      function getFaultsList(faultsObj) {
         if (faultsObj) {
            return _.map(faultsObj.faultsByVm[0].fault, function(fault) {
               return { message: fault.localizedMessage };
            });
         }
         return null;
      }

      function findTopRecommendedDatastorePerDisk(recommendations) {
         if (!recommendations || recommendations.length === 0) {
            return null;
         }
         var topPlacementRecommendation = getTopPlacementRecommendation(recommendations);
         if (!topPlacementRecommendation) {
            return null;
         }

         return getDatastorePerDisk(topPlacementRecommendation);
      }

      /**
       * @param diskKey {int} The disk key for which to retrieve recommendations or undefined
       * if retrieving recommendations for vmx.
       * @param model - provisioningFinishModel representing the RTC page of the
       *       provisioning wizards
       * @returns {Array} Array of recommendations for a specific disk. If diskKey is undefined
       * returns the recommendations for vmx placement.
       */
      function getRecommendationsByKey(diskKey, model) {
         var recommendationsForDisk = [];
         angular.forEach(model.form.recommendationsPerPod, function(recommendationsForPod) {
            angular.forEach(recommendationsForPod.recommendations, function(recommendation) {
               if (hasActionForDisk(diskKey, recommendation.action)) {
                  recommendationsForDisk.push(recommendation);
               }
            });
         });
         return recommendationsForDisk;
      }

      /**
       * @returns {boolean} Indicates whether there is a placement action for the specified
       * disk in an array of actions. If disk key is not specified returns a value indicating
       * whether there is a placement action for the vmx
       */
      function hasActionForDisk(diskKey, actions) {
         var action = _.find(actions, function(action) {
            var found = false;

            if (!angular.isDefined(diskKey) && !action.relocateSpec.disk) {
               found = true;
            } else if (action.relocateSpec.disk) {
               found = angular.isDefined(
                     _.find(action.relocateSpec.disk, function(diskLocator) {
                        return diskKey === diskLocator.diskId;
                     })
               );
            }
            return found;
         });
         return angular.isDefined(action);
      }

      /**
       * Determines if storage recommendations/faults can be retrieved.
       *
       * @param storageObject - storage object of vmx/disk
       * @returns true if the storage object is StoragePod or is a part of SDRS enabled
       * StoragePod
       */
      function areRecommendationsAvailableForStorageObject (storageObject) {
         var result;
         if (storageObject) {
            result = storageObject.storageRef.type === managedEntityConstants.STORAGE_POD
                  || (storageObject.parentStoragePod
                  && storageObject.parentStoragePod.isSdrsEnabled);
         }
         //Cast result to boolean
         return !!result;
      }
   }
})();
