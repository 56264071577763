/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */

/**
 * @ngdoc service
 * @name com.vmware.vsphere.client.storage:hostStorageConstants
 * @module com.vmware.vsphere.client.storage
 *
 * @description
 *    The `hostStorageConstants` service holds host storage related constants.
 */
angular.module('com.vmware.vsphere.client.storage').constant('hostStorageConstants', {
   hostAdapterTypes: {
      ISCSI: 'ISCSI',
      FCOE: 'FCOE',
      FIBRECHANNEL: 'FIBRECHANNEL'
   },
   adapterDetailsAction: {
      ENABLE_SW_ISCSI_ADAPTER: "vsphere.core.host.actions.adapters.enableSoftwareIscsiAdapter",
      DISABLE_SW_ISCSI_ADAPTER: "vsphere.core.host.actions.adapters.disableSoftwareIscsiAdapter",
      EDIT_AUTH_SETTINGS: "vsphere.core.host.actions.adapters.editIscsiAuthenticationSettings",
      EDIT_GENERAL_SETTINGS: "vsphere.core.host.actions.adapters.editIscsiGeneralSettings"
   },
   adapterPathState: {
      ACTIVE: 'active',
      STANDBY: 'standby',
      DISABLED: 'disabled',
      DEAD: 'dead',
      UNKNOWN: 'unknown'
   },
   operationalState: {
      OK: 'ok',
      OFF: 'off',
      LOST_COMMUNICATION: 'lostCommunication',
      ERROR: 'error',
      UNKNOWN_STATE: 'unknownState'
   },
   DEFAULT_PORT_VALUE: 3260,
   PATH_SELECTION_POLICY_OPTIONS_PROP: 'pathSelectionPolicyOptions',
   STORAGE_ARRAY_TYPE_POLICY_OPTIONS_PROP: 'storageArrayTypePolicyOptions',
   EXTENTS_MULTIPATHING_INFO_PROP: 'datastore:vmfsExtentsMultipathingInfo',
   STORAGE_DEVICE_MULTIPATHING_INFO_PROP: 'storagedevice:hostStorageDeviceMultipathingInfo',
   STORAGE_DEVICE_MULTIPATHING_INFO_WITH_PATHS_PROP: 'storagedevice:hostStorageDeviceMultipathingInfoWithPaths',

   storagePolicies: {
      /**
       *  The key of the Fixed path policy.
       */
      FIXED_PATH: "VMW_PSP_FIXED",
      LEGACY_FIXED_PATH: "fixed",

      /**
       * The key of the Most Recently Used policy.
       */
      MOST_RECENTLY_USED_PATH: "VMW_PSP_MRU",
      LEGACY_MOST_RECENTLY_USED_PATH: "mru",

      /**
       * The key of the Round Robin policy.
       */
      ROUND_ROBIN: "VMW_PSP_RR",
      LEGACY_ROUND_ROBIN: "rr",

      /**
       * The key of the Unknown policy.
       */
      UNKNOWN: "VMW_PSP_UNKN"
   },

   protocolEndpoints: {
      BLOCK: "block",
      NAS: "nas"
   },

   connectionState: {
      CONNECTED: "connected",
      DISCONNECTED: "disconnected",
      NOT_RESPONDING: "notResponding"
   },

   deviceType: {
      CDROM: "cdrom",
      DISK: "disk"
   }
});
