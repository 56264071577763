namespace storage_ui {

   import IComponentController = angular.IComponentController;
   import IScope = angular.IScope;

   class DatastorePartitionLayoutController implements IComponentController {

      public static $inject = ["$scope"];
      public datastoreInfo: any;
      public diskPartitionInfo: any;
      public selectedOption: any;
      public size: number;
      public chartPartitions: any[];
      public partitions: any[];
      public chartApi: any = {};

      constructor(private $scope: IScope) {
      }

      $onInit(): void {
         let ctrl = this;
         if (this.datastoreInfo) {
            /**
             * When datastoreInfo is passed we loop through
             * diskPartitionInfo.layout.partition partitions and if some of them belong to
             * datastoreInfo.vmfs.extent we add ["datastoreInfo"],
             * to flag them as ones from the same datastore. (will have same color/name)
             **/
            _.forEach(this.diskPartitionInfo.layout.partition, function (item: any) {
               if (_.find(ctrl.datastoreInfo.vmfs.extent, function (p: any) {
                        return p.partition === item.partition;
                     })) {
                  item.datastoreInfo = ctrl.datastoreInfo;
               }
            });
         }
         // remove re-occuring legend elements
         this.$scope.$watch(function () {
            return ctrl.chartPartitions;
         }, function (items) {
            ctrl.partitions = _.uniq(items, function (item) {
               return item.name + item.color;
            });
         });
      }

      public resizeChart() {
         this.chartApi.reflow();
      }
   }

   angular.module("com.vmware.vsphere.client.storage")
         .component("datastorePartitionLayout", {
            templateUrl: "storage-ui/resources/storage/directives/partitionConfig/layout/datastorePartitionLayoutComponent.html",
            bindings: {
               datastoreInfo: "<?",
               diskPartitionInfo: "<",
               selectedOption: "<",
               size: "<"
            },
            controller: DatastorePartitionLayoutController
         });
}
