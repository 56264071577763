namespace h5_vm {

   export class GosComputerNameValidationService {
      public static $inject = [
         "i18nService"];

      public static readonly MAX_CHARS_WIN: number = 15;
      public static readonly MAX_CHARS_LIN: number = 63;
      public static readonly MAX_CHARS_DOMAIN: number = 63;
      public static readonly MAX_CHARS_DOMAIN_WHOLE: number = 255;

      constructor(private _i18nService: any) {
      }

      public validateComputerName(maxLength: number, computerName?: string): string[] {
         if (!computerName) {
            return [this.errorMessage("computerNameEmpty")];
         }

         if (computerName.length > maxLength) {
            return [this.errorMessage("computerNameTooLong", [maxLength])];
         }

         // Computer name can contain letters, numbers and hyphens (-),
         // but no spaces or periods (.). The name cannot consists entirely of digits.
         let regExp = new RegExp("^(?![0-9]+$)(\\*$|[a-zA-Z0-9-]+$)");
         if (!regExp.test(computerName)) {
            return [this.errorMessage("invalidComputerNameError")];
         }

         return [];
      }

      public validateDomainName(domainName?: string): string[] {
         if (!domainName) {
            return [this.errorMessage("invalidDomainNameEmptyError")];
         }

         let hasInvalidPart: boolean = _.some(domainName.split("."), (label: string) => {
            return label.length > GosComputerNameValidationService.MAX_CHARS_DOMAIN;
         });
         if (hasInvalidPart) {
            return [this.errorMessage("invalidDomainNameLabelLength")];
         }

         if (domainName.length > GosComputerNameValidationService.MAX_CHARS_DOMAIN_WHOLE) {
            return [this.errorMessage("invalidDomainNameLength")];
         }

         return [];
      }

      private errorMessage(suffix: string, args: (string | number)[] = []): string {
         return this._i18nService.getString("VmUi",
               "customizationSpec.computerNamePage." + suffix, ...args);
      }
   }

   angular.module("com.vmware.vsphere.client.vm")
         .service("gosComputerNameValidationService", GosComputerNameValidationService);
}
