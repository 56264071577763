module vm_ui {
   import VmWorkflowMode = h5_vm.VmWorkflowMode;
   import Privileges = h5_vm.Privileges;
   import VmConfigContext = com.vmware.vsphere.client.vm.config.VmConfigContext;
   import DeviceType = h5_vm.DeviceType;
   import DeviceClassLineageService = h5_vm.DeviceClassLineageService;
   export class VmHardwareRdmVirtualDiskService {

      static $inject: string[] = [
         "deviceClassLineageService",
         "virtualDiskSettingsFormService",
         "vmHardwareVirtualDiskSharesService",
         "vmHardwareVirtualDiskCompatibilityModeService",
         "vmHardwareVirtualDiskModeService",
         "vmHardwareVirtualDiskIopsService",
         "vmHardwareVirtualDiskFlashReadCacheService",
         "vmHardwareUtil"
      ];

      constructor(
            private deviceClassLineageService: DeviceClassLineageService,
            private virtualDiskSettingsFormService: any,
            private vmHardwareVirtualDiskSharesService: any,
            private vmHardwareVirtualDiskCompatibilityModeService: any,
            private vmHardwareVirtualDiskModeService: any,
            private vmHardwareVirtualDiskIopsService: any,
            private vmHardwareVirtualDiskFlashReadCacheService: any,
            private vmHardwareUtil: any) {
      }

      buildSettingsForm(vmStorageProfileAssignments: any,
                        vmWorkflowMode: VmWorkflowMode,
                        vmConfigContext: any,
                        inflatedDevice: any,
                        vmStorageId: string,
                        selectedStorageProfile: any,
                        storageProfiles: any,
                        virtualMachineDevices: any): any {
         return this.virtualDiskSettingsFormService.build(
            vmStorageProfileAssignments,
            vmWorkflowMode === VmWorkflowMode.CreateMode
                  || vmWorkflowMode === VmWorkflowMode.CloneMode,
            vmConfigContext,
            inflatedDevice,
            vmStorageId,
            selectedStorageProfile,
            storageProfiles,
            virtualMachineDevices
         );
      }

      isPoweredOn(vmConfigContext: VmConfigContext) {
         return vmConfigContext.environment.powerState === "poweredOn";
      }

      enableSharing(vmConfigContext: any, inflatedDevice: any) {
         return !(this.isPoweredOn(vmConfigContext) && !inflatedDevice.isNew());
      }

      onDiskDistroyStatusChange(destroyDisk: boolean, inflatedDevice: any) {
         if (destroyDisk) {
            inflatedDevice.setDiskFileOperation("destroy");
         } else {
            inflatedDevice.setDiskFileOperation(null);
         }
      }

      isEditFlashReadCacheDisabled(privileges: string[], isNewDevice:boolean): boolean {
         return this.vmHardwareVirtualDiskFlashReadCacheService.isEditFlashReadCacheDisabled(privileges, isNewDevice);
      }

      isSharesOrLimitDisabled(privileges: string[], isNewDevice:boolean): boolean {
         if (isNewDevice) {
            return false;
         }

         const required:string []= [h5_vm.Privileges.VM_RAWDEVICE_PRIVILEGE, h5_vm.Privileges.VM_CONFIG_RESOURCE];
         if (this.vmHardwareUtil.checkPrivileges(privileges, required)) {
            return false;
         } else {
            return true;
         }
      }

      isRemoveRdmDiskDisabled(privileges: Array<string>): boolean {
         return !this.hasPrivilegesToRemoveDisk(privileges);
      }

      hasPrivilegesToRemoveDisk(privileges: string[]) {
         return this.vmHardwareUtil.checkPrivileges(privileges, [h5_vm.Privileges.VM_RAWDEVICE_PRIVILEGE]);
      }

      isDiskModeDisabled(inflatedDevice: any, vmWorkflowMode: VmWorkflowMode, vmConfigContext: VmConfigContext): boolean {
         return this.vmHardwareVirtualDiskModeService.isEditDiskModeDisabled(inflatedDevice,
               vmWorkflowMode === VmWorkflowMode.CreateMode,
               vmConfigContext);
      }

      getCompatibilityModeInfo(vmConfigContext: VmConfigContext, inflatedDevice: any,
                               allInflatedDevices: any) {
         return this.vmHardwareVirtualDiskCompatibilityModeService.getCompatibilityModeInfo(
               vmConfigContext,
               inflatedDevice,
               allInflatedDevices
         );
      }

      getDiskModeInfo(vmConfigContext: VmConfigContext, inflatedDevice: any,
                      allVirtualDevices: any) {
         return this.vmHardwareVirtualDiskModeService.getDiskModeInfo(
               vmConfigContext,
               inflatedDevice,
               allVirtualDevices
         );
      }

      getIopsLimit(device: any) {
         return this.vmHardwareVirtualDiskIopsService.getIopsLimit(device);
      }

      getFlashCacheReservationInfo(device: any, vFlashSupportedOnHost: boolean) {
         return this.vmHardwareVirtualDiskFlashReadCacheService.getFlashCacheReservationInfo(device, vFlashSupportedOnHost);
      }

      public isDiskController(deviceType:string) {

         const isClassOrSubclass = (deviceClass:string, baseClass:string):boolean => {
            return (deviceClass === baseClass) || (this.deviceClassLineageService.isClassNameSubclassOf(deviceClass, baseClass));
         };

         const isScsi:boolean = isClassOrSubclass(deviceType, DeviceType.SCSICONTROLLER);
         const isSata:boolean = isClassOrSubclass(deviceType, DeviceType.VIRTUAL_SATA_CONTROLLER);
         const isNvme:boolean = isClassOrSubclass(deviceType, DeviceType.NVME_CONTROLLER);
         const isIDE:boolean = isClassOrSubclass(deviceType, DeviceType.IDE);

         return isScsi || isSata || isNvme || isIDE;
      }

   }

   angular.module("com.vmware.vsphere.client.vm")
         .service("vmHardwareRdmVirtualDiskService", VmHardwareRdmVirtualDiskService);
}
