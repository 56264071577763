/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
(function() {
   'use strict';
   angular.module('com.vmware.vsphere.client.storage').controller(
         'MultipathingDetailsViewController', MultipathingDetailsViewController);

   MultipathingDetailsViewController.$inject = [
      '$scope',
      'hostStorageService',
      'vxPropertyViewService',
      'i18nService',
      'hostStorageConstants',
      'storageUtil',
      'actionsService',
      '$q',
      'navigation'
   ];

   function MultipathingDetailsViewController($scope, hostStorageService,
         vxPropertyViewService, i18nService, hostStorageConstants, storageUtil,
         actionsService, $q, navigation) {

      var self = this;

      self.datastoreId = navigation.getRoute().objectId;
      self.viewId = navigation.getRoute().extensionId + '_paths';
      self.loading = false;
      self.cachedItems = {};
      self.cachedActions = {};
      self.i18n = i18nService.getString.bind(null, 'StorageUi');

      self.selectedDeviceCanonicalName = null;

      self.liveRefreshProperties = [
         'config.storageDevice',
         'config.storageDevice.scsiLun',
         'config.storageDevice.plugStoreTopology.device',
         'config.storageDevice.multipathInfo.lun',
         'config.storageDevice.plugStoreTopology.target',
         'config.fileSystemVolume.mountInfo',
         'config.multipathState.path',
         'datastore'
      ];

      saveMasterDetailsViewContext();

      function saveMasterDetailsViewContext() {
         self.isDatastoreAccessible = $scope.masterDetailsViewContext.isDatastoreAccessible;
         self.hostId = getHostId();
      }

      function isMasterDetailsViewContextChanged() {
         return self.isDatastoreAccessible !== $scope.masterDetailsViewContext.isDatastoreAccessible ||
               self.hostId !== getHostId();
      }

      $scope.$watch(function() {
            return $scope.masterDetailsViewContext.selectedItems;
         },
         function(newValue, oldValue) {
            if (newValue !== oldValue) {
               if (isMasterDetailsViewContextChanged()) {
                  saveMasterDetailsViewContext();
                  self.refresh();
               }
            }
         });

      $scope.$watch(function() {
            return $scope.masterDetailsViewContext.isDatastoreAccessible;
         },
         function(newValue, oldValue) {
            if (newValue !== oldValue) {
               if (isMasterDetailsViewContextChanged()) {
                  saveMasterDetailsViewContext();
                  self.refresh();
               }
            }
         });

      $scope.$watch(function() {
            return self.selectedDeviceMultipathingInfo;
         },
         function(newValue, oldValue) {
            if (newValue !== oldValue) {
               selectDevice();
            }
         });

      function setModel(vmfsDatastoreMultipathingData) {
         var deviceMultipathingInfos = vmfsDatastoreMultipathingData ?
               vmfsDatastoreMultipathingData[
                     hostStorageConstants.EXTENTS_MULTIPATHING_INFO_PROP] : null;

         if (!deviceMultipathingInfos || !deviceMultipathingInfos.length) {
            self.showNoDetailsAvailableMsg = true;
            return;
         }

         self.showNoDetailsAvailableMsg = false;
         self.deviceMultipathingInfos = deviceMultipathingInfos;

         if (self.selectedDeviceCanonicalName) {
            self.selectedDeviceMultipathingInfo =
               deviceMultipathingInfos.find(function(info) {
                  return info.canonicalName === self.selectedDeviceCanonicalName;
               })
               || deviceMultipathingInfos[0];

         } else {
            self.selectedDeviceMultipathingInfo = deviceMultipathingInfos[0];
         }

         self.pathSelectionPolicyOptions = vmfsDatastoreMultipathingData[
               hostStorageConstants.PATH_SELECTION_POLICY_OPTIONS_PROP];
         self.storageArrayTypePolicyOptions = vmfsDatastoreMultipathingData[
               hostStorageConstants.STORAGE_ARRAY_TYPE_POLICY_OPTIONS_PROP];

         selectDevice();
      }

      function selectDevice() {

         if (!self.selectedDeviceMultipathingInfo) {
            return;
         }

         var editMultipathingPropertiesAction;
         var actionCacheId = getHostId() + "-" + self.datastoreId;
         var editMultipathingActionDef = self.cachedActions[actionCacheId];
         if (editMultipathingActionDef) {
            editMultipathingPropertiesAction = {
               title: editMultipathingActionDef.action.label,
               isEnabled: editMultipathingActionDef.available,
               clickHandler: function () {
                  var selectedDeviceId = self.selectedDeviceMultipathingInfo.canonicalName;
                  if (selectedDeviceId) {
                     actionsService.invokeAction(editMultipathingActionDef, {
                        deviceId: selectedDeviceId,
                        name: self.selectedDeviceMultipathingInfo.deviceName
                     });
                  }
               }
            };
         }

         var pathSelectionPolicy = storageUtil.formatPathSelectionPolicyName(
            self.selectedDeviceMultipathingInfo.pathSelectionPolicy,
            self.pathSelectionPolicyOptions);

         var storageArrayTypePolicy = storageUtil.getStorageArrayTypePolicyName(
            self.selectedDeviceMultipathingInfo.storageArrayTypePolicy,
            self.storageArrayTypePolicyOptions);

         var builder = vxPropertyViewService.createPropertyViewBuilder();
         var categoryBuilder = builder.category(
            self.i18n('datastore.manage.connectivity.multipathingDetails'), 0);
         var generalSection = categoryBuilder.section('generalSection')
            .title(self.i18n('datastore.manage.connectivity.multipathingPolicies'));

         if (self.selectedDeviceMultipathingInfo.ownerPlugin === "HPP") {
            generalSection.property(
                  self.i18n('datastore.manage.connectivity.owner'),
                  self.selectedDeviceMultipathingInfo.ownerPlugin);
         } else {
            generalSection.property(
               self.i18n('datastore.manage.connectivity.pathSelectionPolicy'),
               pathSelectionPolicy)
               .property(
                  self.i18n('datastore.manage.connectivity.satp'),
                  storageArrayTypePolicy)
               .property(
                  self.i18n('datastore.manage.connectivity.owner'),
                  self.selectedDeviceMultipathingInfo.ownerPlugin)
               .action(editMultipathingPropertiesAction);
         }

         self.propertyViewData = builder.build();

         self.paths = self.selectedDeviceMultipathingInfo.paths;
         self.hostId = getHostId();
      }

      function getHostId() {
         if ($scope.masterDetailsViewContext.selectedItems && $scope.masterDetailsViewContext.selectedItems.length) {
            return $scope.masterDetailsViewContext.selectedItems[0];
         }
         return null;
      }

      self.refresh = function () {

         if (!$scope.masterDetailsViewContext.isSelectedHostConnected ||
               !$scope.masterDetailsViewContext.isDatastoreAccessible) {
            self.showNoDetailsAvailableMsg = true;
            self.loading = false;
            self.currentPromise = null;
            return;
         }
         self.showNoDetailsAvailableMsg = false;

         var hostId = getHostId();
         if (!hostId) {
            return;
         }

         var datastoreId = self.datastoreId;
         var cacheId = hostId + "-" + datastoreId;

         self.selectedDeviceCanonicalName = self.selectedDeviceMultipathingInfo
               ? self.selectedDeviceMultipathingInfo.canonicalName
               : self.selectedDeviceCanonicalName;

         if (self.cachedItems.hasOwnProperty(cacheId)) {
            setModel(self.cachedItems[cacheId]);
            self.loading = false;
         } else {
            self.deviceMultipathingInfos = null;
            self.loading = true;
         }

         var actionPromise = actionsService.getActions([hostId],
               ['vsphere.core.host.actions.storageDevice.editMultipathingPolicy']);

         var multipathingDataPromise = hostStorageService.retrieveVmfsDatastoreMultipathingData(
               hostId, self.datastoreId);

         var currentPromise = $q.all([multipathingDataPromise, actionPromise])
            .then(function(responses) {
               var multipathingData = responses[0];
               var multipathingActionData = responses[1];

               self.cachedItems[cacheId] = multipathingData;
               if (multipathingActionData && multipathingActionData.length) {
                  self.cachedActions[cacheId] = multipathingActionData[0];
               }
               if (currentPromise === self.currentPromise) {
                  setModel(multipathingData);
               }
            }, function() {
               if (currentPromise === self.currentPromise) {
                  self.showNoDetailsAvailableMsg = true;
               }
            })
            .finally(function() {
               if (self.currentPromise === currentPromise) {
                  self.loading = false;
               }
            });

         self.currentPromise = currentPromise;
      };

      self.refresh();
   }
})();
