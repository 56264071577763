namespace spbm_ui {

   import AssignDrpSpec = com.vmware.vsphere.client.spbm.spec.AssignDrpSpec;
   import QueryDrpSpec = com.vmware.vsphere.client.common.spbm.spec.QueryDrpSpec;
   import IQService = angular.IQService;
   export class DrpEditPolicyModalService {

      public static $inject = ["clarityModalService", "i18nService",
         "defaultUriSchemeUtil", "dataService", "clarityConstants",
         "mutationService", "$q"];
      private readonly AVAILABLE_DRP_PROPERTY: string = "availableDefaultResourceProfiles";
      private readonly DEFAULT_PROFILE_PROPERTY: string = "datastore:defaultRequirementProfileName";
      private modalOptions: any;

      constructor(private clarityModalService: any,
                  private i18nService: any,
                  private defaultUriSchemeUtil: any,
                  private dataService: any,
                  private clarityConstants: any,
                  private mutationService: any,
                  private $q: IQService) {
      }

      public openModal(datastoreRef: any) {
         let datastoreMor = this.defaultUriSchemeUtil.getManagedObjectReference(datastoreRef);
         let vcRef = this.defaultUriSchemeUtil.getRootFolderFromVsphereObjectId(datastoreRef);
         let self = this;
         this.modalOptions = {
            title: this.i18nService.getString("SpbmUi", "h5.drp.defaultProfileDialog.title"),
            contentTemplate: "spbm-ui/resources/spbm/views/profile/edit/EditDefaultStoragePolicy.html",
            defaultButton: "submit",
            size: "lg",
            subTitle: {
               objectId: datastoreRef
            },
            dialogData: {
               policies: [],
               selectedPolicy: null
            },
            onSubmit: function (this: any) {
               if (!this.dialogData.selectedPolicy) {
                  this.alerts = [{
                     text: self.i18nService.getString("CommonUi", "objectSelectorDialog.noSelection"),
                     type: self.clarityConstants.notifications.type.ERROR
                  }];
                  return false;
               }
               let spec = new AssignDrpSpec();
               spec.datastores = [datastoreMor];
               spec.defaultProfile = this.dialogData.selectedPolicy.profileId;
               self.mutationService.apply(vcRef, spec._type, spec);
               return true;
            },
            alerts: []
         };

         this.modalOptions.dialogData.isLoading = true;
         let profilesPromise = this.loadData(datastoreMor, vcRef);
         let currentProfilePromise = this.getCurrentProfile(datastoreRef);
         this.$q.all([profilesPromise, currentProfilePromise]).then(
               ([profilesResponse,  currentProfileResponse]
                     : [{[key: string]: any[]}, {[key: string]: any}])=> {
                  let policies = profilesResponse[this.AVAILABLE_DRP_PROPERTY];
                  let selection = currentProfileResponse[this.DEFAULT_PROFILE_PROPERTY][0];
                  let item = _.find(policies, (policy: any)=> policy.name === selection.value);
                  if (item) {
                     this.modalOptions.dialogData.defaultSelectedPolicy = item;
                  }
                  this.modalOptions.dialogData.isLoading = false;
                  this.modalOptions.dialogData.policies = policies;
               })
               .catch(()=> {
                  this.modalOptions.dialogData.isLoading = false;
                  this.modalOptions.alerts = [{
                     text: this.i18nService.getString("SpbmUi", "h5.drp.serviceUnavailable"),
                     type: this.clarityConstants.notifications.type.ERROR
                  }];
                  this.modalOptions.dialogData.policies = [];
               });
         this.clarityModalService.openOkCancelModal(this.modalOptions);
      }

      getCurrentProfile(dsRef: string) {
         return this.dataService.getProperties(dsRef, this.DEFAULT_PROFILE_PROPERTY);
      }

      loadData(datastoreRef: any, vcRef: any) {
         let spec = new QueryDrpSpec();
         spec.datastores = [datastoreRef];

         let params: any[] = [{
            propertyName: this.AVAILABLE_DRP_PROPERTY,
            parameterType: spec._type,
            parameter: spec
         }];
         return this.dataService.getProperties(vcRef, this.AVAILABLE_DRP_PROPERTY, {
            propertyParams: params
         });
      }

   }

   angular.module("com.vmware.vsphere.client.spbm")
         .service("drpEditPolicyModalService", DrpEditPolicyModalService);

}
