namespace storage_ui {


   import IScope = angular.IScope;

   export class SwapToSsdDatastoresList {

      public i18n: Function;

      public loading: boolean;

      public hasAdvancedConfigPrivilege: boolean = true;

      public datagridOptions: any;

      public headerOptions: any;

      public selectedItem: any;

      public hostId: string;

      public ACTIONS: any;

      public liveRefreshProperties: Array<string>;

      public static $inject = ["i18nService", "$scope","vuiConstants", "swapToSsdService",
         "columnRenderersRegistry", "storageUtil", "navigation", "datagridActionBarService"];

      constructor(private i18nService: any,
                  private $scope: IScope,
                  private vuiConstants: any,
                  private swapToSsdService: SwapToSsdService,
                  private columnRenderersRegistry: any,
                  private storageUtil: any,
                  private navigation: any,
                  private datagridActionBarService: any) {

         this.i18n = i18nService.getString;
         this.ACTIONS = {
            EDIT_CACHE_CONFIGURATION : 'vsphere.core.host.actions.vFlash.editCacheConfiguration',
            REFRESH_STORAGE_SYSTEM : 'vsphere.core.host.actions.refreshStorageSystem',
            RESCAN_ADAPTERS: 'vsphere.core.host.actions.rescanHost'
         };

         this.liveRefreshProperties = [
            'config.cacheConfigurationInfo',
            'config.fileSystemVolume.mountInfo',
            'datastore'
         ];
      }

      public $onInit() {
         this.datagridOptions = {
            height: "100%",
            columnDefs: this.getColumnDefinitions(),
            selectionMode: this.vuiConstants.grid.selectionMode.SINGLE,
            data: [],
            resizable: true,
            selectedItems: [],
            actionBarOptions: {actions: []}
         };

         this.headerOptions = {
            title: this.i18n("StorageUi", "storage.swapToSsd.title"),
            actions: []
         };

         this.$scope.$watch(() => {
            return this.datagridOptions.selectedItems;
         }, (newValue, oldValue) => {
            if (newValue[0] !== oldValue[0]) {
               this.selectedItem = newValue[0];
               this.updateActionBar();
            }
         });
         this.refresh();
      }

      public refresh() {
         this.loading = true;
         this.hostId = this.navigation.getRoute().objectId;

         this.swapToSsdService.getHostSwapToSsdData(this.hostId).then((hostData: HostSwapToSsdData) => {
            this.hasAdvancedConfigPrivilege = hostData.hasAdvancedConfigPrivilege;
            this.datagridOptions.data = hostData.ssdDatastores;
            this.updateActionBar();
         }).finally(() => {
            this.loading = false;
         });
      }

      public updateActionBar = () => {
         return this.datagridActionBarService.updateActionBar(this.datagridOptions, [this.hostId], [
            {
               actionId: this.ACTIONS.EDIT_CACHE_CONFIGURATION,
               isActionAvailable : (actionDef: any) => {
                  return actionDef.available && this.selectedItem && this.selectedItem.canBeUsedForCache;
               },
               getActionInvocationContext: () => {
                  return {
                     selectedDatastore: this.selectedItem
                  };
               }
            },
            {
               actionId: this.ACTIONS.REFRESH_STORAGE_SYSTEM
            },
            {
               actionId: this.ACTIONS.RESCAN_ADAPTERS
            },
         ]);
      };

      private getColumnDefinitions() {
         let objectLinkRenderer = this.columnRenderersRegistry.getColumnRenderer("object-link");
         let compareNumericValues = this.storageUtil.compareNumericValues;

         function renderDisabledNumber(formattedNumber: string, isDisabled: boolean) {
            let className = "vertical-aligned-text";
            if (isDisabled === true) {
               className += " disabled-text";
            }
            return "<span class=\"" + className + "\">" + formattedNumber + "</span>";
         }

         return [
            {
               displayName: this.i18nService.getString("StorageUi", "storage.swapToSsd.list.nameColumn"),
               field: "datastoreName",
               template: (item: SwapToSsdDatastoreItem) => {
                  return objectLinkRenderer(
                        ["datastoreId", "datastoreName", "vsphere-icon-datastore", null, item.canBeUsedForCache], item);
               }
            },
            {
               displayName: this.i18nService.getString("StorageUi", "storage.swapToSsd.list.capacityColumn"),
               field: "formattedCapacity",
               template: function (item: SwapToSsdDatastoreItem) {
                  return renderDisabledNumber(item.formattedCapacity, !item.canBeUsedForCache);
               },
               sortable: function (item1: any, item2: any) {
                  return compareNumericValues(item1, item2, "capacity");
               }
            },
            {
               displayName: this.i18nService.getString("StorageUi", "storage.swapToSsd.list.hostCacheSpaceColumn"),
               field: "formattedCacheSpace",
               template: function (item: SwapToSsdDatastoreItem) {
                  return renderDisabledNumber(item.formattedCacheSpace, !item.canBeUsedForCache);
               },
               sortable: function (item1: any, item2: any) {
                  return compareNumericValues(item1, item2, "cacheSpace");
               }
            },
            {
               displayName: this.i18nService.getString("StorageUi", "storage.swapToSsd.list.freeSpaceColumn"),
               field: "formattedFreeSpace",
               template: function (item: SwapToSsdDatastoreItem) {
                  return renderDisabledNumber(item.formattedFreeSpace, !item.canBeUsedForCache);
               },
               sortable: function (item1: any, item2: any) {
                  return compareNumericValues(item1, item2, "freeSpace");
               }
            }
         ];
      }
   }

   angular.module("com.vmware.vsphere.client.storage")
         .component("swapToSsdDatastoresList", {
            templateUrl: "storage-ui/resources/storage/views/host/swapToSsd/SwapToSsdDatastoresList.html",
            controller: SwapToSsdDatastoresList
         });
}
