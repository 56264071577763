/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace storage_ui {

   import ProtocolEndpointData = com.vmware.vsphere.client.storage.ProtocolEndpointData;

   export class ProtocolEndpointList {

      public i18n: Function;

      public loading: boolean;

      public datagridOptions: any;

      public headerOptions: any;

      public hostId: string;

      public masterDetailsViewContext: any;

      public liveRefreshProperties: Array<string>;

      private PROTOCOL_ENDPOINT_DATA_PROP: string = "hostProtocolEndpointsData";
      private CONNECTION_STATE_PROP: string = "runtime.connectionState";
      private static OPERATIONAL_STATE_OK: string = "ok";

      public static $inject = ["i18nService", "$scope", "vuiConstants", "columnRenderersRegistry",
         "storageUtil", "navigation", "dataService", "hostStorageConstants"];

      constructor(private i18nService: any,
                  private $scope: any,
                  private vuiConstants: any,
                  private columnRenderersRegistry: any,
                  private storageUtil: any,
                  private navigation: any,
                  private dataService: any,
                  private hostStorageConstants: any) {

         this.i18n = i18nService.getString;

         this.liveRefreshProperties = [
            'config.fileSystemVolume.mountInfo',
            'config.storageDevice.scsiLun'
         ];
      }

      public $onInit() {
         this.datagridOptions = {
            height: "100%",
            columnDefs: this.getColumnDefinitions(),
            selectionMode: this.vuiConstants.grid.selectionMode.MULTI,
            showCheckboxesOnMultiSelection: false,
            data: [],
            resizable: true,
            vuiFooter: {
               showCopy : true
            },
            selectedItems: [],
            actionBarOptions: {actions: []}
         };

         this.headerOptions = {
            title: this.i18n("StorageUi", "storage.pe.listView.header"),
            actions: []
         };

         this.$scope.$watch(() => {
            return this.datagridOptions.selectedItems;
         }, (newValue: any, oldValue: any) => {
            if (newValue !== oldValue && !_.isEqual(newValue, oldValue)) {
               this.masterDetailsViewContext.selectedItems = newValue;
            }
         });

         this.$scope.preselectComparator = (gridDataItem: HostProtocolItem): boolean  => {
            if (!this.masterDetailsViewContext.selectedItems || !this.masterDetailsViewContext.selectedItems.length || !gridDataItem) {
               return false;
            }

            let preselectedItem =_.find(this.masterDetailsViewContext.selectedItems, function (selectedDevice: HostProtocolItem) {
               return selectedDevice.identifier === gridDataItem.identifier;
            });

            return preselectedItem ? true : false;
         };

         this.refresh();
      }

      public refresh() {
         this.loading = true;
         this.hostId = this.navigation.getRoute().objectId;

         let requestedProperties = [this.PROTOCOL_ENDPOINT_DATA_PROP,
                  this.CONNECTION_STATE_PROP];

         this.dataService.getProperties(this.hostId, requestedProperties).then((result: any) => {
            this.datagridOptions.data = _.map(result[this.PROTOCOL_ENDPOINT_DATA_PROP],
                  (item: ProtocolEndpointData) => this.formatProtocolEndpointItem(item)
            );
            this.masterDetailsViewContext.hostConnectionState = result[this.CONNECTION_STATE_PROP];
         }).finally(() => {
            this.loading = false;
         });
      }

      private getColumnDefinitions() {
         let textRenderer = this.columnRenderersRegistry.getColumnRenderer("text");
         let compareNumericValues = this.storageUtil.compareNumericValues;

         return [
            {
               displayName: this.i18nService.getString("StorageUi", "storage.pe.name"),
               field: "name",
               template: (item: HostProtocolItem) => {
                  return textRenderer(["name"], item);
               }
            },
            {
               displayName: this.i18nService.getString("StorageUi", "storage.pe.type"),
               field: "type",
               template: (item: HostProtocolItem) => {
                  return textRenderer(["type"], item);
               }
            },
            {
               displayName: this.i18nService.getString("StorageUi", "storage.pe.array"),
               field: "storageArray",
               template: (item: HostProtocolItem) => {
                  return textRenderer(["storageArray"], item);
               }
            },
            {
               displayName: this.i18nService.getString("StorageUi", "storage.pe.location"),
               field: "location",
               template: (item: HostProtocolItem) => {
                  return textRenderer(["location"], item);
               }
            },
            {
               displayName: this.i18nService.getString("StorageUi", "storage.pe.lun"),
               field: "lun",
               template: (item: HostProtocolItem) => {
                  return textRenderer(["lun"], item);
               },
               sortable: function (item1: any, item2: any) {
                  return compareNumericValues(item1, item2, "lunNumber");
               }
            },
            {
               displayName: this.i18nService.getString("StorageUi", "storage.pe.opState"),
               field: "operationalState",
               template: (item: HostProtocolItem) => {
                  return textRenderer(["operationalState"], item);
               }
            },
         ];
      }

      private formatProtocolEndpointItem(item: ProtocolEndpointData): HostProtocolItem {
         let result: HostProtocolItem = <HostProtocolItem>{};
         let protocolEndpoint = item.protocolEndpoint;
         let peType = protocolEndpoint.peType;
         switch (peType){
            case this.hostStorageConstants.protocolEndpoints.BLOCK:
               let lun = item.underlyingLun;
               result.name = this.getStringValue(lun.displayName);
               result.storageArray = this.getStringValue(protocolEndpoint.storageArray);
               result.identifier = this.getStringValue(lun.canonicalName);
               result.location = this.getStringValue(lun.deviceName);
               result.lun = item.lunNumber.toString();
               result.type = this.i18n("StorageUi", "storage.pe.type.block");
               result.operationalState =
                     this.deviceStateMatches(item, ProtocolEndpointList.OPERATIONAL_STATE_OK)
                     ? this.i18n("StorageUi", "storage.pe.opState.accessible")
                     : this.i18n("StorageUi", "storage.pe.opState.notAccessible");
               result.transport = this.storageUtil.getScsiPeTransport(item);
               result.owner = this.getStringValue(item.ownerPlugin);
               result.isScsiPE = true;
               break;
            case this.hostStorageConstants.protocolEndpoints.NAS:
               result.name = this.i18n("StorageUi", "storage.pe.runtimeName.nfs",
                     this.getStringValue(protocolEndpoint.nfsServer));
               result.storageArray = this.getStringValue(protocolEndpoint.storageArray);
               result.identifier = this.getStringValue(protocolEndpoint.uuid);
               result.location = this.getStringValue(protocolEndpoint.nfsServer)
                     + this.getStringValue(protocolEndpoint.nfsDir);
               result.server = this.getStringValue(protocolEndpoint.nfsServer);
               result.folder = this.getStringValue(protocolEndpoint.nfsDir);
               result.lun = this.i18n("StorageUi", "informationNotAccessible");
               result.type = this.i18n("StorageUi", "storage.pe.type.nas");
               result.operationalState = item.accessible
                     ? this.i18n("StorageUi", "storage.pe.opState.accessible")
                     : this.i18n("StorageUi", "storage.pe.opState.notAccessible");
               break;
         }

         result.uuid = protocolEndpoint.uuid;
         result.lunNumber = item.lunNumber;
         result.peType = peType;
         return result;
      }

      private deviceStateMatches(data:ProtocolEndpointData, state: string): boolean {
         if (!data || !data.underlyingLun ||
               !data.underlyingLun.operationalState) {
            return false;
         }
         if (data.underlyingLun.operationalState.indexOf(state) < 0) {
            return false;
         }
         return true;
      }

      private getStringValue(value: string){
         return value ? value : "";
      }
   }

   angular.module("com.vmware.vsphere.client.storage")
         .component("protocolEndpointList", {
            templateUrl: "storage-ui/resources/storage/views/host/protocolEndpoints/ProtocolEndpointListView.html",
            bindings: {
               masterDetailsViewContext: "="
            },
            controller: ProtocolEndpointList
         });
}
