(function() {
   'use strict';
   angular.module('com.vmware.vsphere.client.host').controller('AddHostConnectionSettingsPageController', [
         '$scope',
         function($scope) {
            var self = this;

            self.model = $scope.manager.getConnectionSettingsPageModel();

            $scope.$watch(function(){ return self.model; }, function(model){
               $scope.manager.setConnectionSettings(model);
            }, true);
         }
      ]
   );
})();