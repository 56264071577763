/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace network_ui {

   export class VmMigrateWizardFlags{
      isSelectNetworksPageInitialized: boolean;
      isSelectVmsPageInitialized: boolean;
      inaccessibilityWarningShown: boolean;
      noSourceNetworkSelected: boolean;
      isSourceNetworkPreselected: boolean;
   }
}
