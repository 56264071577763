(function() {
   'use strict';

   angular
         .module('com.vmware.vsphere.client.vm')
         .service('VmProvisioningGuestOsPageModel', VmProvisioningGuestOsPageModel);

   VmProvisioningGuestOsPageModel.$inject = [
      '$q',
      '$sce',
      'i18nService',
      'gosFamilyConstants',
      'vmHardwareVersionService',
      'featureFlagsService'
   ];

   function VmProvisioningGuestOsPageModel(
         $q,
         $sce,
         i18nService,
         gosFamilyConstants,
         vmHardwareVersionService,
		 featureFlagsService) {
      return function(virtualMachineSpecBuilder) {
         var form = {
            familyChanged: function() {
               this.gosVersion = null;
               setVersionOptions(this.gosFamily);
            },
            gosFamilyOptions: [
               familyObject(gosFamilyConstants.WINDOWS),
               familyObject(gosFamilyConstants.LINUX),
               familyObject(gosFamilyConstants.OTHER)
            ],
            gosVersionOptions: {},
            isSelectedOtherOs: function() {
               return this.gosVersion && (this.gosVersion.id === 'otherGuest' || this.gosVersion.id === 'otherGuest64');
            },
            vbsEnabled: false,
            isVbsSupported: function(gosVersion) {
               return isVbsFeatureFlagEnabled() &&
                   (!gosVersion.vbsSupported ? false : gosVersion.vbsSupported.supported);
            }
         };

         var gosDescriptor;
         var gosDefault;

         return {
            form: form,

            resetForm: function() {
               var deferred = $q.defer();

               var submittedGosFamily;
               var submittedGosName;
               if (!virtualMachineSpecBuilder.getRecreateVm()) {
                  submittedGosFamily = virtualMachineSpecBuilder.getGosFamily();
                  submittedGosName = virtualMachineSpecBuilder.getGosName();
               }
               var selectedGos = form.gosFamily || submittedGosFamily;
               form.gosFamily = selectedGos || form.gosFamilyOptions[0];

               virtualMachineSpecBuilder.getConfigOptionByPool().then(function(res) {
                  gosDescriptor = res.vmConfigOptionByPool.guestOSDescriptor;
                  var guestOsDefaultIndex = res.vmConfigOptionByPool.guestOSDefaultIndex;
                  // in case we have the gos id set in the vm params, try to preselect that
                  // descriptor, this is the case with scheduled tasks where we only have
                  // the id of the guest os
                  var guestOsIndex = initiateGosFromScheduledTask(gosDescriptor);
                  if (guestOsIndex !== null) {
                     guestOsDefaultIndex = guestOsIndex;
                  }
                  if (guestOsDefaultIndex && guestOsDefaultIndex > -1 && guestOsDefaultIndex < gosDescriptor.length) {
                     gosDefault = gosDescriptor[guestOsDefaultIndex];
                  }
                  setVersionOptions(form.gosFamily);

                  form.vmVersionDescription = getVmVersionDescription();
                  // since the request for vm config environment property (that is required
                  // for customize hardware page) is heavy and slow we are initiating it so
                  // when the navigated to the next page the data hopefully will be retrieved
                  // already
                  virtualMachineSpecBuilder.getVmConfigEnvironmentForGos(form.gosVersion);
                  deferred.resolve();
               });

               form.gosName = form.gosName || submittedGosName || null;
               return deferred.promise;
            },

            pageFinishedLoading: function () {
               return !!form.gosFamily && !!form.gosVersion;
            },

            validatePage: function() {
               return {};
            },

            submitPage: function() {
               virtualMachineSpecBuilder.setGosFamilyAndVersion({
                  family: form.gosFamily,
                  version: form.gosVersion
               });

               // in case the guest os is changed from the one selected when the
               // scheduled task was created, do not use the data to pre-populate the customize hardware page
               const scheduledTaskData = virtualMachineSpecBuilder.getScheduledData();
               if (!_.isEmpty(scheduledTaskData)) {
                  if (scheduledTaskData.spec.guestId !== form.gosVersion.id) {
                     scheduledTaskData.useScheduledTaskData = false;
                  }
               }
               virtualMachineSpecBuilder.setVbsSupported(form.gosVersion.vbsSupported && form.gosVersion.vbsSupported.supported);
               virtualMachineSpecBuilder.setVbsEnabled(form.vbsEnabled);

               if (form.isSelectedOtherOs()) {
                  virtualMachineSpecBuilder.setGosName(form.gosName);
               } else {
                  virtualMachineSpecBuilder.setGosName(null);
               }

               return {
                  wizardFlow: virtualMachineSpecBuilder.getCreationType(),
                  invalidateNextPages: false
               };
            }
         };

         function isVbsFeatureFlagEnabled() {
            return (featureFlagsService.hardwareV14_vbsEnabled && featureFlagsService.hardwareV14_vbsEnabled());
         }

         function setVersionOptions(gosFamily) {
            var gosVersionOptions = [];
            _.each(gosDescriptor, function(gos) {
               if (addNewVersionOptions(gos, gosFamily)) {
                  gosVersionOptions.push({
                     id: gos.id,
                     name: gos.fullName,
                     supportLevelMessage: $sce.trustAsHtml(
                           i18nService.getString(
                                 'VmUi', 'GOSConfigBase.support_level.' + gos.supportLevel)),
                     vbsSupported: gos.vbsSupported
                  });
               }
            });


            // when guest os version is selected and user goes to the next page, and then
            // he goes back to the Guest OS family page and changes the Guest OS family,
            // the submitted (previously selected) guest os version is not valid anymore
            var submittedGosVersion;
            if (!virtualMachineSpecBuilder.getRecreateVm()) {
               submittedGosVersion = virtualMachineSpecBuilder.getGosVersion();
            }

            if (submittedGosVersion) {
               var isSubmittedGosVersionValid = _.some(gosVersionOptions, function(gosVersion) {
                  return gosVersion.id === submittedGosVersion.id;
               });

               if (!isSubmittedGosVersionValid) {
                  submittedGosVersion = null;
               }
            }

            // we should also check if the form.gosVersion option is valid, because it
            // might happen that when user changes the hw version on the previous page to
            // some lower version, that the current guest OS version preserved in
            // form.gosVersion does not support it, hence become invalid
            var formGosVersion = form.gosVersion;
            if (formGosVersion) {
               var gosVersionFromList = _.find(gosVersionOptions, function(gosVersion) {
                  return gosVersion.id === formGosVersion.id;
               });

               if (gosVersionFromList) {
                  formGosVersion = gosVersionFromList;
               } else {
                  formGosVersion = null;
               }
            }

            var selectedGosVersion = formGosVersion || submittedGosVersion;

            var defaultGosVersion = gosDefault ? _.find(gosVersionOptions, function(gos) {
               if (gos.id !== gosDefault.id) {
                  return false;
               }

               if (!gos.vbsSupported && !gosDefault.vbsSupported) {
                  return true;
               }

               return (gos.vbsSupported && gosDefault.vbsSupported) &&
                     (gos.vbsSupported.supported === gosDefault.vbsSupported.supported);
            }) : null;

            form.gosVersionOptions = gosVersionOptions;
            form.gosVersion = selectedGosVersion || defaultGosVersion || form.gosVersionOptions[0];
         }

         function initiateGosFromScheduledTask(gosDescriptor) {
            var guestId = virtualMachineSpecBuilder.getScheduledGuestOsId();
            var descriptorIndex = null;
            if (guestId) {
               _.each(gosDescriptor, function(descriptor, index) {
                  if (descriptor.id === guestId) {
                     descriptorIndex = index;
                     return;
                  }
               });
               virtualMachineSpecBuilder.setScheduledGuestOsId(null);
               if (descriptorIndex !== null) {
                  if (gosDescriptor[descriptorIndex].family === gosFamilyConstants.WINDOWS) {
                     form.gosFamily = form.gosFamilyOptions[0];
                  } else {
                     if (gosDescriptor[descriptorIndex].family === gosFamilyConstants.LINUX) {
                        form.gosFamily = form.gosFamilyOptions[1];
                     } else {
                        form.gosFamily = form.gosFamilyOptions[2];
                     }
                  }

                  if (virtualMachineSpecBuilder.getScheduledGuestName()) {
                     form.gosName = virtualMachineSpecBuilder.getScheduledGuestName();
                  }
               }
            }

            return descriptorIndex;
         }

         function addNewVersionOptions(gos, family) {
            return ((gos.family === gosFamilyConstants.WINDOWS)
                        ? family.id === gosFamilyConstants.WINDOWS
                        : ((gos.family === gosFamilyConstants.LINUX)
                              ? family.id === gosFamilyConstants.LINUX
                              : family.id === gosFamilyConstants.OTHER)) || family.id === gos.family;
         }

         function getVmVersionDescription() {
            var vmVersion = virtualMachineSpecBuilder.getVmVersion();
            var longText = vmHardwareVersionService.getLongText(vmVersion);
            return i18nService.getString('VmUi', 'VmHardware.HWVersion.vmVersionLabel', longText);
         }

         function familyObject(id) {
            return {
               id: id,
               name: i18nService.getString('VmUi', 'GeneralOptions.gos_family.' + id)
            };
         }
      };
   }
})();
