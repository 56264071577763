/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace dvs_ui {

   import IPromise = angular.IPromise;
   import DvsPvlanData = com.vmware.vsphere.client.h5.network.dvs.pvlan.model.DvsPvlanData;
   import PvlanMapEntry = com.vmware.vim.binding.vim.dvs.VmwareDistributedVirtualSwitch$PvlanMapEntry;
   export class DvsPvlanViewComponent {

      public bindings: any;
      public controller: any;
      public templateUrl: string;

      constructor() {
         this.bindings = {};
         this.controller = DvsPvlanViewController;
         this.templateUrl = "dvs-ui/resources/dvs/pvlan/dvsPvlanViewTemplate.html";
      }
   }

   class DvsPvlanViewController {

      static $inject = [
         "$rootScope",
         "$scope",
         "i18nService",
         "dataService",
         "dvsPvlanViewService"
      ];

      public dvsPvlanLoading: boolean = false;

      public datagridOptions: any;

      public dvsUri: string;

      public settingsViewHeaderAccessor: any;

      public actions: any[];

      public liveRefreshProperties: string[];

      private _selectedDistributedSwitchPromise: IPromise<any>;

      private _selectedItemSecondaryId: number;

      private _ignoreNextItemSelection: boolean;

      constructor(private $rootScope: any,
                  private $scope: any,
                  private i18nService: any,
                  private dataService: any,
                  private dvsPvlanViewService: DvsPvlanViewService) {
         this.$scope.preselectComparator =
               (gridDataItem: PvlanMapEntry): boolean => {
                  return gridDataItem.secondaryVlanId === this._selectedItemSecondaryId;
               };
         this.settingsViewHeaderAccessor = {};
         this.actions = [{
            actionUid: "vsphere.core.dvs.editPvlan",
            customLabel: this.i18nService.getString("DvsUi", "dvs.pvlanView.edit.label")
         }];
      }

      protected $onInit() {
         this.refresh();
      }

      public get title(): string {
         return this.i18nService.getString("DvsUi", "dvs.pvlanView.title");
      }

      public refresh() {
         this.dvsPvlanLoading = true;

         this.dvsUri = this.$rootScope._route.objectId;

         let dvsPromise: IPromise<any> = this.dataService
               .getData(this.dvsUri, new DvsPvlanData()._type);

         this._selectedDistributedSwitchPromise = dvsPromise;

         dvsPromise.then((dataServiceResponse: any) => {
                  if (this._selectedDistributedSwitchPromise === dvsPromise) {
                     let pvlans: PvlanMapEntry[] =
                           (dataServiceResponse && dataServiceResponse.pvlans) || [];
                     let sortedPvlans: PvlanMapEntry[] = this.dvsPvlanViewService
                           .formatPvlanEntries(pvlans);

                     if (!this.datagridOptions) {
                        // init
                        this.datagridOptions = this.dvsPvlanViewService
                              .createPvlanDatagridOptions(sortedPvlans,
                                    this.onSelectionChange.bind(this));
                     } else {
                        // refresh
                        this.datagridOptions.data = sortedPvlans;

                        // When the datagridOptions.data is refreshed, the callback for the
                        // selectedItems is called with an empty array before the grid
                        // preselection directive tries to preselect the last selected
                        // item. This flag is used to skip the first trigger of the watch
                        // when the data is refreshed.
                        this._ignoreNextItemSelection = true;
                     }
                  }

                  this.dvsPvlanLoading = false;
               }, (error: any) => {
                  this.dvsPvlanLoading = false;
               }
         );

         this.liveRefreshProperties = ["config.pvlanConfig"];

         // Call the settings-view-header's accessor #refresh function to recalculate
         // the actions availability
         if (this.settingsViewHeaderAccessor.refresh) {
            this.settingsViewHeaderAccessor.refresh();
         }
      }

      public onSelectionChange(items: PvlanMapEntry[]): void {
         if (this._ignoreNextItemSelection) {
            this._ignoreNextItemSelection = false;
            return;
         }

         if (items && items.length > 0) {
            this._selectedItemSecondaryId = items[0].secondaryVlanId;
         } else {
            this._selectedItemSecondaryId = -1;
         }
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .component("dvsPvlanView", new DvsPvlanViewComponent());
}
