namespace h5_vm {

   import VmConfigContext = com.vmware.vsphere.client.vm.config.VmConfigContext;
   import SharesInfo = com.vmware.vim.binding.vim.SharesInfo;
   declare const angular: ng.IAngularStatic;
   declare const _: any;

   export class VmHardwareCpuService {
      static $inject: string[] = [
         'vmHardwareUtil',
         'guestOsService',
         'cpuClockFilter',
         'i18nService'
      ];

      private i18n: Function;

      constructor(private vmHardwareUtil: any,
                  private guestOsService: any,
                  private cpuClockFilter: any,
                  private i18nService: any) {
         this.i18n = i18nService.getString;
      }

      getVmCapabilities(vmConfigContext:any) {
         let retVal = vmConfigContext.environment.configOption.capabilities;
         return retVal;
      }

      getHostCapabilities(vmConfigContext:any): any {
         let retVal = vmConfigContext.environment.hostCapability;
         return retVal;
      }


      isNumCpuEnabled(powerState: string, config:any, isFtVm:Boolean, guest: any,
                      privileges: Array<string>, createMode: boolean):boolean {

         if (createMode) {
            return true;
         }

         if (isFtVm) {
            return false;
         }

         if (!this.haveCpuCountPermission(privileges)) {
            return false;
         }

         const support = (guest ? guest.supportsCpuHotAdd : false);

         if (config.cpuHotAddEnabled === false || support === false) {
            if (this.isPowerOnState(powerState)) {
               return false;
            }
         }

         if (this.isSuspendedState(powerState)) {
            return false;
         }

         return true;
      }

      isCreatingNewVm(createMode:any):boolean {
         var retVal = false;

         if (createMode) {
            retVal = true;
         }

         return retVal;
      };

      isReservationEnabled(ctx:any, createMode:any):boolean {

         if(this.isCreatingNewVm(createMode)) {
            return true;
         }

         if(!this.haveChangeResourcePermission(ctx)) {
            return false;
         }

         return true;
      };

      isLimitEnabled(ctx:any, createMode:any):boolean {

         if(this.isCreatingNewVm(createMode)) {
            return true;
         }

         if(!this.haveChangeResourcePermission(ctx)) {
            return false;
         }

         return true;
      };

      isSharesEnabled(ctx:any, createMode:any):boolean {

         if(this.isCreatingNewVm(createMode)) {
            return true;
         }

         if(!this.haveChangeResourcePermission(ctx)) {
            return false;
         }
         return true;
      };

      isNestedHVSupportedOnVmAndHost(ctx: any): any {

         let vm_capabilities: any = this.getVmCapabilities(ctx);
         let host_capabilities: any = this.getHostCapabilities(ctx);

         let vm_supported: boolean = vm_capabilities.nestedHVSupported;
         let host_supported: boolean = host_capabilities.nestedHVSupported;

         let retVal = (vm_supported === true) && (host_supported === true);

         return retVal;
      }

      isNestedHVEnabled(ctx:any, createMode:any):boolean {

         let supported:boolean = this.isNestedHVSupportedOnVmAndHost(ctx);

         if (supported !== true) {
            return false;
         }

         if(this.isCreatingNewVm(createMode)) {
            return true;
         }

         if(this.isPoweredOn(ctx)) {
            return false;
         }

         if(!this.haveSettingsPermission(ctx)) {
            return false;
         }
         return true;
      };

      isCpuIdEnabled(ctx:any, createMode:any):boolean {

         if(this.isCreatingNewVm(createMode)) {
            return true;
         }

         if(this.isPoweredOn(ctx)) {
            return false;
         }

         if (this.isSuspended(ctx)) {
            return false;
         }

         if(!this.haveSettingsPermission(ctx)) {
            return false;
         }
         return true;
      };

      isCpuMmuEnabled(ctx:any, createMode:any):boolean {

         if(this.isCreatingNewVm(createMode)) {
            return true;
         }

         if(!this.haveSettingsPermission(ctx)) {
            return false;
         }
         return true;
      }

      isSuspended(ctx:any): boolean {
         if (ctx.environment.powerState === 'suspended'){
            return true;
         }
         return false;

      }

      isPoweredOn(ctx:any): boolean {
         if (this.isPowerOnState(ctx.environment.powerState)){
            return true;
         }
         return false;

      }

      isSuspendedState(state:String): boolean {
         if (state === 'suspended'){
            return true;
         }
         return false;
      }

      isPowerOnState(state:String): boolean {
         if (state === 'poweredOn'){
            return true;
         }
         return false;
      }

      haveCpuCountPermission(privileges: Array<string>): boolean {
         const CPU_COUNT:String = 'VirtualMachine.Config.CPUCount';
         var retVal = this.vmHardwareUtil.checkPrivileges(privileges, [CPU_COUNT]);
         return retVal;
      }

      haveChangeResourcePermission(vmConfigContext:any): boolean {
         var privileges = vmConfigContext.privileges;
         const CONFIG_RESOURCE:String = 'VirtualMachine.Config.Resource';
         var retVal = this.vmHardwareUtil.checkPrivileges(privileges, [CONFIG_RESOURCE]);
         return retVal;
      }

      haveSettingsPermission(vmConfigContext:any): boolean {
         var retVal = this.vmHardwareUtil.haveVmConfigSettingsPermission(vmConfigContext);
         return retVal;
      }

      checkPrivileges(id:String, privileges: Array<string>) {
         var retVal = this.vmHardwareUtil.checkPrivileges(privileges, [id]);
         return retVal;
      }

      isCpuHotPlugEnabled(vmPowerState: string,
                          ftState: string,
                          ftInfo: any,
                          guestOs: any,
                          privileges: Array<string>,
                          createMode: boolean) {
         const gOsCpuHotAddEnabled = (guestOs ? guestOs.supportsCpuHotAdd : false);
         if (vmPowerState === 'poweredOn' || this.vmHardwareUtil.isFtVm(ftState, ftInfo) || !gOsCpuHotAddEnabled) {
            return false;
         }

         return !!(this.vmHardwareUtil.checkPrivileges(privileges,
            ['VirtualMachine.Config.CPUCount']) || createMode);
      }

      /**
       * Helper method to populate the list of acceptable values for number of cores
       * per socket. It also populates data provider for number of cores per socket.
       * Called when numCPU or guest OS changes.
       */
      buildSupportedCoresPerSocket(vmConfigContext: any,
                                   originalConfig:  any,
                                   guestOs:         any): Array<number> {
         let result: Array<number>               = [];

         const isMultipleCoresPerSocketSupported = vmConfigContext.environment.configOption.
            capabilities.multipleCoresPerSocketSupported;
         const numCPU                            = vmConfigContext.config.hardware.numCPU;

         let maxCoresPerSocket: number;
         if (isMultipleCoresPerSocketSupported) {
            maxCoresPerSocket = Math.min(
               guestOs.numSupportedCoresPerSocket,
               vmConfigContext.environment.configOption.hardwareOptions.numCoresPerSocket.max,
               numCPU);
         } else {
            maxCoresPerSocket = 1;
         }

         for (let i = 1; i <= maxCoresPerSocket; i++) {
            let sockets = numCPU / i;
            if (((numCPU % i) !== 0)  ||
               (sockets > guestOs.numSupportedPhysicalSockets)) {
               continue;
            }
            result.push(i);
         }

         const originalCoresPerSocket = originalConfig.hardware.numCoresPerSocket;
         const originalNumCPU         = originalConfig.hardware.numCPU;
         if (result.length === 0) {
            if (numCPU === originalNumCPU) {
               result.push(originalCoresPerSocket);
            } else {
               result.push(1);
            }
         }
         return result;
      }

      /**
       * Helper method to populate the list of acceptable values for number of virtual CPUs.
       * Called when guest OS changes.
       */
      buildSupportedCpus(vmConfigContext: any,
                         guestOs:         any,
                         createMode:      boolean): Array<number> {

         if (!(vmConfigContext.environment && vmConfigContext.environment.configTarget
            && vmConfigContext.environment.configOption)) {
            return [];
         }

         const hostMax: number                     = vmConfigContext.environment.configTarget.numCpus;
         const gosMax: number                      = guestOs.supportedMaxCPUs;
         const numSupportedPhysicalSockets: number = guestOs.numSupportedPhysicalSockets;
         const numCoresPerSocket: number           = vmConfigContext.config.hardware.numCoresPerSocket;

         const poweredOn: boolean                  = (vmConfigContext.environment.powerState === 'poweredOn');
         const hotAddEnabled: boolean              = this.isHotAddEnabled(vmConfigContext, guestOs);
         const hotRemoveEnabled: boolean           = this.isHotRemoveEnabled(vmConfigContext, guestOs);
         const originalCpuNumber: number           = vmConfigContext.config.hardware.numCPU;

         const canAdd: boolean                     = !poweredOn || (poweredOn && hotAddEnabled);
         const canRemove: boolean                  = !poweredOn || (poweredOn && hotRemoveEnabled);

         const isMultipleCoresPerSocketSupported: boolean = vmConfigContext.environment.configOption.
            capabilities.multipleCoresPerSocketSupported;
         // Indicates if cores per socket value of this VM is editable.
         const isCoresPerSocketEditable: boolean = !poweredOn && isMultipleCoresPerSocketSupported;

         const privileges: Array<string>           = vmConfigContext.privileges;
         const hasPrivilege: boolean               = this.checkPrivilege(privileges, 'VirtualMachine.Config.CPUCount', createMode);

         const acceptableCpus: number              = vmConfigContext.environment.configOption.hardwareOptions.numCPU;
         let supportedCpus: Array<number>          = [];

         const isValidCpuNumber = (cpuNumber: number, hostMax: number, gosMax: number) => {
            return ((hostMax < 0) || (cpuNumber <= hostMax)) && ((gosMax < 0) || (cpuNumber <= gosMax));
         };

         const canCpuNumberChange = (cpuNumber: number, originalCpuNumber: number,
                                     canRemove: boolean, canAdd: boolean) => {
            return (cpuNumber === originalCpuNumber) ||
               ((cpuNumber < originalCpuNumber) && canRemove) ||
               ((cpuNumber > originalCpuNumber) && canAdd);
         };

         _.forEach(acceptableCpus, (cpuNumber: number) => {
            if (isValidCpuNumber(cpuNumber, hostMax, gosMax) &&
               canCpuNumberChange(cpuNumber, originalCpuNumber, canRemove, canAdd)) {
               // Further restrict list of valid values for number of CPUs if
               // VM is hot (or generally speaking cores per socket is not editable).
               // In this case number of CPUs should be multiple of cores per socket and
               // number of sockets should obey guest OS limits
               if (isMultipleCoresPerSocketSupported
                  && !isCoresPerSocketEditable
                  && hasPrivilege
                  && ( (cpuNumber % numCoresPerSocket) > 0 ||
                  (cpuNumber / numCoresPerSocket) > numSupportedPhysicalSockets) ) {
                  return;
               }
               supportedCpus.push(cpuNumber);
            }
         });

         return supportedCpus;
      }

      /**
       * Indicates if virtual processors can be added while this
       * virtual machine is running.
       */
      isHotAddEnabled(vmConfigContext: VmConfigContext, guestOs: any) {
         // It is possible hotAddEnabled flag in the backend to be true
         // although the guest OS does not support hot add operations.
         // This happens when VM was previously configured with more advanced guest OS
         // that supports hot add and hot add is enabled by user. Then if VM is reconfigured
         // to use guest OS w/o hot add support, hotAddEnabled flag remains enabled.
         // We should not show that CPU hot add is enabled, when gos
         // does not support it, although the UI is disabled.
         return vmConfigContext.config.cpuHotAddEnabled
            && guestOs.supportsCpuHotAdd;
      }

      /**
       * Indicates if virtual processors can be removed while this
       * virtual machine is running AND guest supports that.
       */
      isHotRemoveEnabled(vmConfigContext: VmConfigContext, guestOs: any) {
         // see comments for hotAddEnabled
         return vmConfigContext.config.cpuHotRemoveEnabled
            && guestOs.supportsCpuHotRemove;
      }

      checkPrivilege(privileges: any[], privilege: any, createMode?: any) {
         if (privileges) {
            return privileges.indexOf(privilege) > -1;
         } else if (createMode) {
            return true;
         }
         return false;
      }

      getMaxReservation(configContext: VmConfigContext): number {
         // See CpuConfig.as#onResourceAllocChange for original implementation

         let minUpperLimit: number = 0;

         const numCpus = configContext.config.hardware.numCPU;
         const mhzPerCore = configContext.environment.hostCpuMhz;
         const vmPoweredOn = this.vmHardwareUtil.isVmPoweredOn(configContext.environment.powerState);
         const isInCluster = configContext.environment.isInCluster;

         const cpuUsage = configContext.environment.configTarget.resourcePool.cpu;
         const maxUsage = cpuUsage.maxUsage;

         const limit = configContext.config.cpuAllocation.limit;
         const reservation = configContext.config.cpuAllocation.reservation;
         const configuredReservation = reservation ? reservation : 0;

         let min = 0;
         if (vmPoweredOn) {
            min = configuredReservation;
         }

         let maxCpuReservPhysLimit = maxUsage;
         if (mhzPerCore) {
            maxCpuReservPhysLimit = numCpus * mhzPerCore;
         }

         if (!vmPoweredOn) {
            minUpperLimit = Math.max(maxUsage, configuredReservation);
         } else {
            if (cpuUsage.unreservedForVm !== -1) {
               minUpperLimit = cpuUsage.unreservedForVm;
            }
            minUpperLimit += min;
         }

         if ((minUpperLimit > maxCpuReservPhysLimit) && !isInCluster) {
            if (!vmPoweredOn) {
               minUpperLimit = Math.min(maxCpuReservPhysLimit, cpuUsage.unreservedForVm);
            } else {
               minUpperLimit = Math.min(maxCpuReservPhysLimit, minUpperLimit);
            }
         }

         let reservationMaxLimit: number;
         if (limit !== -1) {
            reservationMaxLimit = Math.min(limit, minUpperLimit);
         } else {
            reservationMaxLimit = minUpperLimit;
         }

         return reservationMaxLimit;
      }

      formatEnabledDisabled(isEnabled: boolean): string {
         if (isEnabled) {
            return this.i18n('VmUi', 'VmCpuView.Enabled');
         } else {
            return this.i18n('VmUi', 'VmCpuView.Disabled');
         }
      }

      formatRecommendations(recommendations: Array<any>): void {
         _.each(recommendations, (r: any) => {
            r.formattedValue = this.cpuClockFilter(r.value);
         });
      }

      isCpuIdMaskExposed(configContext: VmConfigContext): boolean {
         // See CpuIdConfig.as#examineNxState for the full proper implementation of this
         let mask: any;
         if (configContext.config.cpuFeatureMask && configContext.config.cpuFeatureMask.length > 0) {
            mask = configContext.config.cpuFeatureMask[0];
         } else {
            mask = configContext.environment.configOption.guestOSDescriptor[0].cpuFeatureMask[0];
         }

         if (mask.edx && mask.edx[11] === 'H') {
            return true;
         }
         return false;
      }

      /**
       * Verify that the virtual machine is turned off.
       * Verify that the virtual machine does not reside in a DRS cluster.
       * Verify that the host has more than one physical processor core.
       *
       * @returns {boolean}
       */
      isSchedulingAffinityShown(configContext: VmConfigContext): boolean {
         const isVmPoweredOn = this.vmHardwareUtil.isVmPoweredOn(configContext.environment.powerState);
         const isInCluster = configContext.environment.isInCluster;
         const hasMultipleCpus = configContext.environment.configTarget.numCpus > 1;

         return !isVmPoweredOn && !isInCluster && hasMultipleCpus;
      }

      isSchedulingAffinityEnabled(configContext: VmConfigContext, createMode: boolean): boolean {

         if (createMode) {
            return true;
         }
         return this.checkPrivilege(configContext.privileges, "VirtualMachine.Config.Resource");
      }

      getAffinityDisplayValue(affinitySet: Array<any>): string {
         let current: any;
         let [previous, ...rest] = affinitySet;
         let [rangeStart, ...remaining] = affinitySet;
         let result: Array<any> = [];

         const addToResult = (rangeStart: any, current: any) => {
            if (rangeStart !== current) {
               result.push(`${rangeStart}-${current}`);
            } else {
               result.push(current);
            }
         };

         for (let i = 0; i < affinitySet.length; i++) {
            current = affinitySet[i];
            if (i > 0 && current !== previous + 1) {
               addToResult(rangeStart, previous);
               rangeStart = current;
            }
            previous = current;
         }

         addToResult(rangeStart, current);

         return result.join(",");
      }

      getCpuAffinitySignpostContext(configContext: VmConfigContext): any {
         // See VmCpuPageMediator.as#initCpuAffinity for original implementation
         let affinityText: string, htStatus: string, numProcs: string;

         const numCpus:     number = configContext.environment.configTarget.numCpus;
         const numCpuCores: number = configContext.environment.configTarget.numCpuCores;

         if (numCpus === 0 || numCpuCores === 0) {
            htStatus = this.i18n('VmUi', 'Affinity.HyperThreading.Unknown');
            numProcs = this.i18n('VmUi', 'Affinity.NumProcs.Unknown', numCpuCores);
            affinityText = this.i18n('VmUi', 'Affinity.SelectAffinity.Unknown');
         } else if (numCpuCores !== numCpus) {
            htStatus = this.i18n('VmUi', 'Affinity.HyperThreading.Active');
            numProcs = this.i18n('VmUi', 'Affinity.NumProcs.Logical', numCpus);
            affinityText = this.i18n('VmUi', 'Affinity.SelectAffinity.Logical');
         } else {
            htStatus = this.i18n('VmUi', 'Affinity.HyperThreading.Inactive');
            numProcs = this.i18n('VmUi', 'Affinity.NumProcs.Physical', numCpuCores);
            affinityText = this.i18n('VmUi', 'Affinity.SelectAffinity.Physical');
         }

         return {
            i18n: this.i18nService.getString,
            affinityText: affinityText,
            htStatus: htStatus,
            numProcs: numProcs
         };
      }

      /**
       * Validates CPU scheduling affinity pattern
       *
       * @param affinity CPU affinity pattern (e.g. "0,1-3,4,6")
       * @param numCpus
       *
       * @return {boolean}
       */
      validateAffinity(affinity: string, numCpus: number): boolean {
         // return true if affinity is "empty"
         if (affinity.trim() === "") {
            return true;
         }

         const isValidNumber = (input: string) => {
            return input && !isNaN(Number(input));
         };

         let affinitySets: Array<string> = affinity.split(",");
         for (let set of affinitySets) {
            let range = set.trim().split('-');
            if (range.length === 2) {
               var [start, end] = range;
               let startAffinity: number;
               let endAffinity: number;

               if (isValidNumber(start)) {
                  startAffinity = Number(start);
               } else {
                  return false;
               }

               if (isValidNumber(end)) {
                  endAffinity = Number(end);
               } else {
                  return false;
               }

               if (startAffinity > endAffinity || endAffinity > numCpus - 1) {
                  return false;
               }
            } else if (range.length === 1) {
               let [value] = range;
               let affinityValue: number;
               if (isValidNumber(value)) {
                  affinityValue = Number(value);
                  if (affinityValue > numCpus - 1) {
                     return false;
                  }
               } else {
                  return false;
               }
            } else {
               return false;
            }
         }
         return true;
      }

      /**
       * Transform an affinity string to an affinity set
       * Assumes affinity input is valid
       *
       * @param {string} affinity
       * @returns {Array<number>}
       *
       */
      transformAffinity(affinity: string): Array<number> {
         let affinitySet: Array<number> = [];

         if (affinity.trim() === '') {
            return affinitySet;
         }

         let affinitySets: Array<string> = affinity.split(',');
         for (let set of affinitySets) {
            let range = set.trim().split('-');
            if (range.length === 2) {
               var [start, end] = range;
               const startAffinity: number = Number(start);
               const endAffinity: number = Number(end);
               for (let i = startAffinity; i <= endAffinity; i++) {
                  affinitySet.push(i);
               }
            } else if (range.length === 1) {
               let [value] = range;
               const affinityValue: number = Number(value);
               affinitySet.push(affinityValue);
            }
         }
         return affinitySet;
      }

      setUpSharesInfo(ctx:VmConfigContext, createMode:any):SharesInfo {

         let sharesInfo: SharesInfo = new SharesInfo();
         sharesInfo.level = "normal";
         sharesInfo.shares = 1000;

         let allocation = ctx.config.cpuAllocation;

         if (!allocation) {
            return sharesInfo;
         }

         var info = allocation.shares;

         if (this.isSharesEnabled(ctx, createMode)) {
            sharesInfo = info;
         } else if (info) {
            sharesInfo.level = info.level;
            sharesInfo.shares = info.shares;
         }

         return sharesInfo;
      }
   }

   angular.module('com.vmware.vsphere.client.vm')
      .service('vmHardwareCpuService', VmHardwareCpuService);
}

