namespace h5_vm {

   import GuestOsDescriptor = com.vmware.vim.binding.vim.vm.GuestOsDescriptor;
   import VmConfigContext = com.vmware.vsphere.client.vm.config.VmConfigContext;
   import VirtualNvdimmService = h5_vm.VirtualNvdimmService;
   import PmemUtilService = h5_vm.PmemUtilService;
   import VmWorkflowMode = h5_vm.VmWorkflowMode;

   export class VmHardwareNvdimmController implements ng.IComponentOptions {
      bindings: any;
      controller: any;
      templateUrl: string;

      constructor() {
         this.bindings = {
            nvdimmControllerDevice: "<",
            removeCallback: "&",
            vmConfigContext: "<",
            vmWorkflow: "<",
            virtualMachineDevices: "<",
            selectedGuestOs: "<"
         };
         this.controller = VmHardwareNvdimmControllerController;
         this.templateUrl =
               "vm-ui/resources/vm/views/settings/vmHardwareSettings/vmHardwareNvdimmController/vm-hardware-nvdimm-controller.component.html";
      }
   }


   class VmHardwareNvdimmControllerController implements ng.IComponentController {
      static $inject: string[] = [
         "i18nService",
         "virtualNvdimmService",
         "pmemUtilService",
         "pmemCapacityService",
         "$scope"
      ];

      // Inputs
      public nvdimmControllerDevice: any;
      public removeCallback: Function;
      public vmConfigContext: VmConfigContext;
      public vmWorkflow: VmWorkflowMode;
      public virtualMachineDevices: any;
      public selectedGuestOs: GuestOsDescriptor;

      public deviceName: string;
      public isRemoveDisabled: boolean;

      private listenerDeregistrationFuction: Function;

      constructor(private i18nService: any,
            private virtualNvdimmService: VirtualNvdimmService,
            private pmemUtilService: PmemUtilService,
            private pmemCapacityService: PmemCapacityService,
            private $scope: any) {
      }

      $onInit() {
         this.deviceName = this.nvdimmControllerDevice
               .getCurrentDevice().deviceInfo.label;
         this.isRemoveDisabled = this.virtualNvdimmService.isControllerRemoveDisabled(
               this.vmConfigContext,
               this.virtualMachineDevices,
               this.vmWorkflow);
         this.listenerDeregistrationFuction = this.$scope.$on(
               this.virtualNvdimmService.NVDIMM_REMOVED, (event: any) => {
                  this.isRemoveDisabled = this.virtualNvdimmService.isControllerRemoveDisabled(
                        this.vmConfigContext,
                        this.virtualMachineDevices,
                        this.vmWorkflow);
                  // When the removal is disabled then there are non-removed NVDIMMs
                  // revert the removal of this controller to preserve the NVDIMMs operational.
                  if (this.isRemoveDisabled
                        && this.nvdimmControllerDevice.isMarkedForRemoval()) {
                     this.revertNvdimmControllerRemoval();
                  }
               });
      }

      $onDestroy() {
         if (this.listenerDeregistrationFuction) {
            this.listenerDeregistrationFuction();
         }
      }

      removeNvdimmController() {
         if (!this.isRemoveDisabled && this.removeCallback) {
            this.removeCallback()(this.nvdimmControllerDevice);
         }
      }

      revertNvdimmControllerRemoval() {
         this.nvdimmControllerDevice.revertRemoval();
      }

      getAvailablePmemCapacity(): number {
         return this.pmemUtilService.getAvailablePmemCapacity(
               this.vmConfigContext);
      }

      getNvDimmDeviceSummary(): string {
         let nvDimmDevices: any[] = this.virtualMachineDevices
               .devicesOfTypeNotMarkedForRemoval(PmemUtilService.NVDIMM);
         let totalNvdimmMemoryInMB = this.pmemCapacityService
               .getTotalNvdimmMemory(this.virtualMachineDevices);
         return this.i18nService.getString("VmUi", "NVDIMM.totalNVDIMMs",
               nvDimmDevices.length, totalNvdimmMemoryInMB);
      }

      getSupportedPmemByGuestOs(): number {
         return this.pmemUtilService.getSupportedPmemByGuestOs(this.selectedGuestOs);
      }

      i18n(key: string, param: string = "", bundle: string = "VmUi"): string {
         return this.i18nService.getString(bundle, key, param);
      }
   }

   angular.module("com.vmware.vsphere.client.vm")
         .component("vmHardwareNvdimmController", new VmHardwareNvdimmController());
}

