(function() {
   'use strict';
   angular.module('com.vmware.vsphere.client.storage').service('moveDatastoreToService',
         MoveDatastoreToService);

   MoveDatastoreToService.$inject = ['i18nService', 'mutationService',
         'clarityModalService', 'dataService', '$q', 'defaultUriSchemeUtil',
         'authorizationService'];

   function MoveDatastoreToService(i18nService,
         mutationService,
         clarityModalService,
         dataService,
         $q,
         defaultUriSchemeUtil,
         authorizationService) {

      function displayMaintenanceModeErrorDialog(clusterName) {
         var modalOptions = {
            message: i18nService.getString('HostUi', 'hostNotInMaintenanceModeError',
                  clusterName),
            title: i18nService.getString('HostUi', 'common.util.error'),
            icon: "vx-icon-warning-32x32",
            hideCancelButton: true,
            preserveNewlines: true,
            submit: function() {
            }
         };

         clarityModalService.openConfirmationModal(modalOptions);
      }

      function showWarningDialog(clusterName, hostMor, entityToMoveHostTo) {
         var modalOptions = {
            message: i18nService.getString('HostUi', 'cluster.moveInto.noDRS.singleHost',
                  clusterName),
            title: i18nService.getString('HostUi', 'common.util.warning'),
            icon: "vx-icon-warning-32x32",
            preserveNewlines: true,
            submit: function() {
               var moveSpec = {
                  entities: [hostMor],
                  attributes: null,
                  allowLoosingNestedRps: true
               };
               mutationService.apply(
                     entityToMoveHostTo,
                     'com.vmware.vsphere.client.h5.host.moveHost.MoveHostSpec',
                     moveSpec);
            }
         };

         clarityModalService.openConfirmationModal(modalOptions);
      }

      function showMoveVmsAndRpsDialog(hostMor,
            hostId,
            hostName,
            entityToMoveHostTo,
            dpmTurnedOn) {
         var modalOptions = {
            title: i18nService.getString('HostUi',
                  'warning.hostNotConnectedToAllDsClusterDatastores.single.dialogTitle'),
            subTitle: {
               objectId: hostId
            },
            contentTemplate: 'host-ui/resources/host/views/settings/moveto/moveHostVmsAndRpsDialog.html',
            defaultButton: 'submit',
            dialogData: {
               hostMor: hostMor,
               hostId: hostId,
               hostName: hostName,
               dpmTurnedOn: dpmTurnedOn,
               entityToMoveHostTo: entityToMoveHostTo
            }
         };

         clarityModalService.openOkCancelModal(modalOptions);
      }

      function getMoveRpSpec(hostMor, moveToRoot) {
         return {
            entities: [hostMor],
            attributes: null,
            allowLoosingNestedRps: true,
            moveVmsAndRpsConfigSpec: {
               moveEnabled: true,
               moveToRoot: moveToRoot,
               moveToCustomRp: !moveToRoot
            }
         };
      }

      function showRpmWarningCondition(hostId, dpmTurnedOn) {
         var deferred = $q.defer();
         if (dpmTurnedOn) {
            var showDpmWarning = false;
            dataService.getData(
                  hostId,
                  'com.vmware.vsphere.client.h5.host.moveHost.HostConfigInformation').then(function(data) {
               if (data && !data.lastExitSucceeded) {
                  showDpmWarning = true;
               }
               deferred.resolve(showDpmWarning);
            });
         }

         return deferred.promise;
      }

      function moveDatastoreTo(entityToMoveDatastoreTo, datastoreIds) {
         var datastoreMors = _.map(datastoreIds, function(datastoreId) {
            return defaultUriSchemeUtil.getManagedObjectReference(datastoreId);
         });
         var moveSpec = {
            entities: datastoreMors
         };

         mutationService.apply(
               entityToMoveDatastoreTo,
               'com.vmware.vsphere.client.h5.storage.spec.MoveDatastoreSpec',
               moveSpec);
      }

      function checkPrivileges(itemToCheckIds) {
         return authorizationService
               .checkPrivilegesForMultipleObjects(itemToCheckIds, ['Datastore.Move'])
               .then(function(result) {
                  var objectsWithNoPrivilegeIds = [];
                  _.each(result, function(privileges, id){
                           if (!privileges['Datastore.Move']) {
                              objectsWithNoPrivilegeIds.push(id);
                           }
                  });
                  return objectsWithNoPrivilegeIds;
               });
      }

      return {
         moveDatastoreTo: moveDatastoreTo,
         checkPrivileges: checkPrivileges
      };
   }
})();
