/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace dvs_ui {

   import DvsConfigSpec = com.vmware.vsphere.client.dvs.api.spec.DvsConfigSpec;
   import VmwareDistributedVirtualSwitch$ConfigSpec = com.vmware.vim.binding.vim.dvs.VmwareDistributedVirtualSwitch$ConfigSpec;
   export class DvsEditNetFlowDialogModel {

      public name: string;

      public configVersion: string;

      public collectorIpAddress: string;

      public switchIpAddress: string;

      public collectorPort: number;

      public observationDomainId: number;

      public activeFlowTimeout: number;

      public idleFlowTimeout: number;

      public samplingRate: number;

      public internalFlowsOnly: boolean;

      public ipfixV10Supported: boolean;

      public ipv6ForIpfixSupported: boolean;

      constructor(data: any) {
         this.name = data.name;
         this.configVersion = data.configVersion;
         this.ipv6ForIpfixSupported = data.ipv6ForIpfixSupported;

         if (data.dvsConfigNetflow) {
            _.extend(this, data.dvsConfigNetflow);
         }
      }

      public buildSpec(pageModel: DvsEditNetFlowDialogModel): DvsConfigSpec | null {
         let configSpec: VmwareDistributedVirtualSwitch$ConfigSpec = this.getDefaultSpec();

         let spec:DvsConfigSpec = new DvsConfigSpec();
         spec.configSpec = configSpec;

         let hasChanges: boolean = false;

         if (this.collectorIpAddress !== pageModel.collectorIpAddress) {
            configSpec.ipfixConfig.collectorIpAddress = pageModel.collectorIpAddress;
            hasChanges = true;
         }

         if (this.collectorPort !== Number(pageModel.collectorPort)) {
            configSpec.ipfixConfig.collectorPort = pageModel.collectorPort;
            hasChanges = true;
         }

         if (this.observationDomainId !== Number(pageModel.observationDomainId)) {
            configSpec.ipfixConfig.observationDomainId = pageModel.observationDomainId;
            hasChanges = true;
         }

         if (this.switchIpAddress !== pageModel.switchIpAddress) {
            configSpec.switchIpAddress = pageModel.switchIpAddress;
            hasChanges = true;
         }

         if (this.activeFlowTimeout !== Number(pageModel.activeFlowTimeout)) {
            configSpec.ipfixConfig.activeFlowTimeout = pageModel.activeFlowTimeout;
            hasChanges = true;
         }

         if (this.idleFlowTimeout !== Number(pageModel.idleFlowTimeout)) {
            configSpec.ipfixConfig.idleFlowTimeout = pageModel.idleFlowTimeout;
            hasChanges = true;
         }

         if (this.samplingRate !== Number(pageModel.samplingRate)) {
            configSpec.ipfixConfig.samplingRate = pageModel.samplingRate;
            hasChanges = true;
         }

         if (this.internalFlowsOnly !== pageModel.internalFlowsOnly) {
            configSpec.ipfixConfig.internalFlowsOnly = pageModel.internalFlowsOnly;
            hasChanges = true;
         }

         return hasChanges ? spec : null;
      }

      private getDefaultSpec(): VmwareDistributedVirtualSwitch$ConfigSpec {
         let defaultSpec: VmwareDistributedVirtualSwitch$ConfigSpec = new VmwareDistributedVirtualSwitch$ConfigSpec();
         defaultSpec.configVersion = this.configVersion;
         defaultSpec.ipfixConfig = new com.vmware.vim.binding.vim.dvs.VmwareDistributedVirtualSwitch$IpfixConfig();
         defaultSpec.ipfixConfig.idleFlowTimeout = this.idleFlowTimeout;
         defaultSpec.ipfixConfig.internalFlowsOnly = this.internalFlowsOnly;
         defaultSpec.ipfixConfig.samplingRate = this.samplingRate;
         defaultSpec.ipfixConfig.activeFlowTimeout = this.activeFlowTimeout;
         return defaultSpec;
      }
   }
}
