/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace dvs_ui {

   import TreeListDataSource = kendo.data.TreeListDataSource;
   import ManagedObjectReference = com.vmware.vim.binding.vmodl.ManagedObjectReference;
   import DvsAddHostHierarchicalListVnicItem = com.vmware.vsphere.client.h5.network.dvs.addhost.hierarchicalList.DvsAddHostHierarchicalListVnicItem;
   import TreeListModel = kendo.data.TreeListModel;
   import DvsAddHostHierarchicalListHostItem = com.vmware.vsphere.client.h5.network.dvs.addhost.hierarchicalList.DvsAddHostHierarchicalListHostItem;
   import DvsAddHostHierarchicalListItem = com.vmware.vsphere.client.h5.network.dvs.addhost.hierarchicalList.DvsAddHostHierarchicalListItem;
   import DvsHostsSpec = com.vmware.vsphere.client.h5.network.dvs.addhost.model.DvsHostsSpec;
   import IPromise = angular.IPromise;

   class DvsSelectVirtualAdaptersPageController {

      private static readonly VIRTUAL_ADAPTERS_PROPERTY: string =
            "dvsAddHost:hostVnicData";

      public static $inject = [
         "$q",
         "$scope",
         "i18nService",
         "dataService",
         "defaultUriSchemeUtil",
         "networkSelectorDialogService",
         "dvsSelectVirtualAdaptersPageService",
         "vnicSettingsDialogService",
         "dvsSelectVirtualAdaptersPageValidator"];


      public treeListDataSource: any;

      public treeListColumns: any[];
      public actionBarOptions: any;

      // Only needed for getting access to the selected items.
      public treeListWidget: any;

      /**
       * The currently selected item (if any) in the grid. Undefined if there is no
       * selection.
       */
      private _selectedItem: any;

      private vnicsWithoutBackingPnic: DvsAddHostTreeListItemWrapper[];

      constructor(private $q: any,
                  private $wizardScope: DvsAddHostWizardScope,
                  private i18nService: any,
                  private dataService: any,
                  private defaultUriSchemeUtil: any,
                  private networkSelectorDialogService: any,
                  private dvsSelectVirtualAdaptersPageService: DvsSelectVirtualAdaptersPageService,
                  private vnicSettingsDialogService: VnicSettingsDialogService,
                  private dvsSelectVirtualAdaptersPageValidator: DvsSelectVirtualAdaptersPageValidator) {

         this.treeListWidget = null;

         this.actionBarOptions =
               this.dvsSelectVirtualAdaptersPageService.getActionBarOptions(
                     this.onAssignPortgroupClicked.bind(this),
                     this.onResetChangesClicked.bind(this),
                     this.onViewSettingsClicked.bind(this)
               );

         if (!this.$wizardScope.model.selectVirtualAdaptersPageModel.isInitialized) {
            // Initialize virtual adapters tree list with empty data provider
            // so that it is rendered while loading the data.
            this.initializeVirtualAdaptersTreeList([]);
            this.requestPageData();
         } else {
            this.initializeVirtualAdaptersTreeList(
                  this.$wizardScope.model.selectVirtualAdaptersPageModel.hosts);
         }

         this.$wizardScope.$on("$destroy", () => {
            kendo.destroy($(".dvs-add-host-adapters-tree-list"));
         });
      }

      public onDataBound(): void {
         if (this._selectedItem) {
            const row = this.treeListWidget.itemFor(this._selectedItem);
            this.treeListWidget.select(row);
         }
      }

      private requestPageData(): void {
         this.$wizardScope.wizardConfig.loading = true;

         let virtualAdaptersPromise: any = this.requestVirtualAdapters();

         virtualAdaptersPromise.then((response: any, error: any) => {
            this.createHostVnicTreeModel(response);
            this.$wizardScope.wizardConfig.loading = false;
         });
      }

      private onNetworkSelectorClosed(selectedNetworks: any[], applyToAll: boolean): void {
         if (selectedNetworks && selectedNetworks.length > 0) {
            let selectedPortgroup: any = selectedNetworks[0];

            let selectedPortgroupName: string = selectedPortgroup.name;
            let selectedPortgroupRef: ManagedObjectReference =
                  selectedPortgroup.networkRef;

            let vnicsToUpdate: DvsAddHostHierarchicalListVnicItem[];
            if (applyToAll) {
               vnicsToUpdate = this.getVnicItemsWithName(this.asVnicItem(this._selectedItem).device);
            } else {
               vnicsToUpdate = [this._selectedItem];
            }

            const selectedItemId = this._selectedItem.id;

            const updatedListItems: DvsAddHostTreeListItemWrapper[] =
                  this.assignVnics(vnicsToUpdate, selectedPortgroupName, selectedPortgroupRef);

            this.treeListDataSource = new kendo.data.TreeListDataSource({
               data: updatedListItems,
               schema: this.dvsSelectVirtualAdaptersPageService.getTreeListSchema()
            });
            this.treeListDataSource.read();

            this.$wizardScope.model.selectVirtualAdaptersPageModel.hosts =
                  this.treeListDataSource.data() as DvsAddHostTreeListItemWrapper[];

            this._selectedItem = _.filter(updatedListItems, (item: DvsAddHostTreeListItemWrapper) => {
               return item.id === selectedItemId;
            })[0];
         }
      }

      private assignVnics(vnicItems: DvsAddHostHierarchicalListVnicItem[],
                          portgroupName: string,
                          portgroupRef: ManagedObjectReference): DvsAddHostTreeListItemWrapper[] {
         vnicItems.forEach((updatedVnicItem: DvsAddHostHierarchicalListVnicItem): void => {
            const originalVnicItem = this.asVnicItem(this.asWrapper(updatedVnicItem).originalItem);

            updatedVnicItem.parentId = originalVnicItem.onThisSwitchItemId;
            updatedVnicItem.label = this.i18nService.getString(
                  "DvsUi",
                  "SelectVirtualAdaptersPage.displayName.formatter",
                  originalVnicItem.label,
                  this.i18nService.getString(
                        "DvsUi", "SelectVirtualAdaptersPage.virtualAdapterState.reassigned"));

            updatedVnicItem.destinationPortgroup = portgroupName;
            updatedVnicItem.portgroupRef = portgroupRef;
         });

         this.checkVnicsForConnectivityLoss(
               this.treeListDataSource.data() as DvsAddHostTreeListItemWrapper[]);

         return this.updateListOfItems(vnicItems);
      }

      private checkVnicsForConnectivityLoss(vnics: DvsAddHostTreeListItemWrapper[]): void {
         if (vnics && vnics.length > 0) {

            this.vnicsWithoutBackingPnic = this.dvsSelectVirtualAdaptersPageValidator
                  .getVnicsWithConnectivityWarning(
                        vnics,
                        this.$wizardScope.model.selectPhysicalAdaptersPageModel.hosts,
                        vnics);
            _.each(vnics,
                  (itemWrapper: DvsAddHostTreeListItemWrapper): void => {
                     if (itemWrapper.type === DvsAddHostWizardConstants.nicListItemType.VNIC_TYPE) {
                        let vnicItem = (itemWrapper as DvsAddHostHierarchicalListItem) as DvsAddHostHierarchicalListVnicItem;
                        if (_.contains(this.vnicsWithoutBackingPnic, itemWrapper)) {
                           vnicItem.iconClass = "network-lib-ui-icon-virtualAdapterWarning";
                        } else {
                           vnicItem.iconClass = "network-lib-ui-icon-virtualAdapter";
                        }
                     }
                  });
         }
      }

      private updateListOfItems(vnicItems: DvsAddHostHierarchicalListVnicItem[]): DvsAddHostTreeListItemWrapper[] {

         let allListItems: DvsAddHostTreeListItemWrapper[] =
               angular.copy(this.$wizardScope.model.selectVirtualAdaptersPageModel.hosts, []);

         for (const vnicItem of vnicItems) {
            const itemIndexToRemove: number = _.findIndex(allListItems, (itemWrapper: DvsAddHostTreeListItemWrapper): boolean => {
               return itemWrapper.id === vnicItem.id;
            });

            if (itemIndexToRemove >= 0) {
               allListItems.splice(itemIndexToRemove, 1);
            }
         }

         for (const vnicItem of vnicItems) {
            let vnicAdded: boolean = false;
            for (let index = 0; index < allListItems.length; index++) {
               const itemWarpper = allListItems[index];

               if (itemWarpper.type === DvsAddHostWizardConstants.nicListItemType.VNIC_TYPE) {

                  let itemWrapperAsVnic: DvsAddHostHierarchicalListVnicItem =
                        this.asVnicItem(itemWarpper);

                  if (itemWrapperAsVnic.hostRef.value === vnicItem.hostRef.value) {
                     if (itemWrapperAsVnic.orderInList >  vnicItem.orderInList) {
                        allListItems.splice(index, 0, this.asWrapper(vnicItem));
                        vnicAdded = true;
                        break;
                     }
                  }
               }
            }

            // If the vnic has not been added, there are no vnic items at the given host at the moment
            // Push the item in the array, it will go under the expected parent based on parentId
            if (!vnicAdded) {
               allListItems.push(this.asWrapper(vnicItem));
            }
         }

         return allListItems;

      }

      private onAssignPortgroupClicked(): void {
         let dialogSpec = this.networkSelectorDialogService.createNetworkSelectorDialogSpec(['Network.Assign']);
         dialogSpec.isBatchAssignmentRelevant = false;
         if (this.hostItems().length > 1) {
            dialogSpec.isBatchAssignmentRelevant = true;
            const vnicItem = this.asVnicItem(this._selectedItem).device;
            const hostsWithoutVnicArray: any[] = this.getHostsWithoutVnic(vnicItem);

            // Batch assignment is enabled on all hosts if there are no hosts
            // that don't have the vmk
            dialogSpec.isBatchAssignmentEnabled = !hostsWithoutVnicArray.length;
            // Based on the hosts that have or don't have vmk with given name
            // get the signpost message
            if (dialogSpec.isBatchAssignmentEnabled) {
               dialogSpec.applyToAllSingPostMessage = this.i18nService.getString(
                     "Common",
                     "NetworkSelectorDialog.applyToAll.infoMessage", vnicItem);
            } else {
               dialogSpec.applyToAllSingPostMessage = hostsWithoutVnicArray.length > 3 ?
                     this.i18nService.getString(
                     "Common",
                     "NetworkSelectorDialog.applyToAll.warnMessageManyHosts", vnicItem, hostsWithoutVnicArray.slice(0, 3).join(", ")) :
                     this.i18nService.getString(
                           "Common",
                           "NetworkSelectorDialog.applyToAll.warnMessage", vnicItem, hostsWithoutVnicArray.join(", "));
            }
         }
         // Open network selector.
         this.networkSelectorDialogService.openNetworkSelectorDialog(
               this.$wizardScope.model.dvsId,
               this.networkSelectorDialogService.createNetworkSelectorFilterSpec(
                     false, false, true, false, false
               ),
               // TODO vchomakov: add custom message
               dialogSpec,
               this.onNetworkSelectorClosed.bind(this)
         );
      }

      private onResetChangesClicked(): void {

         if (this._selectedItem.type ===
               DvsAddHostWizardConstants.nicListItemType.VNIC_TYPE) {
            this.resetChangesForVnic(this._selectedItem);
         } else if (this._selectedItem.type ===
               DvsAddHostWizardConstants.nicListItemType.GROUPING_TYPE) {
            this.resetChangesForGroupingItem(this._selectedItem);
         } else if (this._selectedItem.type ===
               DvsAddHostWizardConstants.nicListItemType.HOST_TYPE) {
            this.resetChangesForHostItem(this._selectedItem);
         }

         this.updateToolbar();
         this.$wizardScope.model.selectVirtualAdaptersPageModel.hosts =
               this.treeListDataSource.data() as DvsAddHostTreeListItemWrapper[];
      }

      private onViewSettingsClicked(): void {
         let vnicItem = this._selectedItem as
               DvsAddHostHierarchicalListVnicItem
               & TreeListModel;

         let groupingItem = this.treeListDataSource.parentNode(vnicItem);
         let hostItem = this.treeListDataSource
               .parentNode(groupingItem) as
               DvsAddHostHierarchicalListHostItem
               & TreeListModel;
         this.vnicSettingsDialogService.show(
               this.defaultUriSchemeUtil.getVsphereObjectId(vnicItem.hostRef), vnicItem.device, hostItem.label);
      }

      private resetChangesForHostItem(hostItem: any): void {
         let childGroupingItems: any[] =
               this.treeListDataSource.childNodes(hostItem);
         if (childGroupingItems && childGroupingItems.length === 2) {
            let onThisSwitchGroupingItem: any = childGroupingItems[0];
            let onOtherSwitchesGroupingItem: any = childGroupingItems[1];

            this.resetChangesForGroupingItem(onThisSwitchGroupingItem);
            this.resetChangesForGroupingItem(onOtherSwitchesGroupingItem);
         }
      }

      private resetChangesForGroupingItem(groupingItem: any): void {
         let childVnicItems: any[] = this.treeListDataSource.childNodes(groupingItem);
         _.each(childVnicItems, (vnicItem: any) => {
            this.resetChangesForVnic(vnicItem);
         });
      }

      private resetChangesForVnic(vnicItemWrapper: DvsAddHostTreeListItemWrapper): void {

         if (!this.dvsSelectVirtualAdaptersPageService.isVnicModified(vnicItemWrapper)) {
            return;
         }

         let originalVnicItem: DvsAddHostHierarchicalListVnicItem =
               vnicItemWrapper.originalItem as DvsAddHostHierarchicalListVnicItem;
         let updatedItem: DvsAddHostHierarchicalListItem =
               vnicItemWrapper as DvsAddHostHierarchicalListItem;
         let updatedVnicItem: DvsAddHostHierarchicalListVnicItem =
               updatedItem as DvsAddHostHierarchicalListVnicItem;

         updatedVnicItem.parentId = originalVnicItem.parentId;
         updatedVnicItem.label = originalVnicItem.label;
         updatedVnicItem.destinationPortgroup = originalVnicItem.destinationPortgroup;
         updatedVnicItem.portgroupRef = originalVnicItem.portgroupRef;
         this.checkVnicsForConnectivityLoss(
               this.treeListDataSource.data() as DvsAddHostTreeListItemWrapper[]);
         this.treeListDataSource.pushUpdate(updatedVnicItem);
      }

      public updateToolbar(): void {
         if (this.treeListWidget === null) {
            return;
         }
         let row: any = this.treeListWidget.select();
         let data: any = this.treeListWidget.dataItem(row);
         this._selectedItem = data;

         if (!data) {
            // No item is selected, typically triggered by a deselection.
            // Disable all actions.
            _.each(this.actionBarOptions.actions, (action: any): void => {
               action.enabled = false;
            });
         }

         if (data && data.type) {
            let assignPortgroupAction: any = this.actionBarOptions.actions[0];
            let resetChangesAction: any = this.actionBarOptions.actions[1];
            let viewDetailsAction: any = this.actionBarOptions.actions[2];

            assignPortgroupAction.enabled =
                  data.type === DvsAddHostWizardConstants.nicListItemType.VNIC_TYPE;

            resetChangesAction.enabled = this.containsModifications(this._selectedItem);

            viewDetailsAction.enabled =
                  data.type === DvsAddHostWizardConstants.nicListItemType.VNIC_TYPE;
         }
      }

      private containsModifications(itemWrapper: DvsAddHostTreeListItemWrapper): boolean {
         if (!itemWrapper) {
            return false;
         }

         if (itemWrapper.type === DvsAddHostWizardConstants.nicListItemType.VNIC_TYPE) {
            return this.dvsSelectVirtualAdaptersPageService.isVnicModified(itemWrapper);
         } else if (itemWrapper.type ===
               DvsAddHostWizardConstants.nicListItemType.GROUPING_TYPE) {
            let childVnics: DvsAddHostTreeListItemWrapper[] =
                  this.treeListDataSource.childNodes(itemWrapper);
            return _.some(
                  childVnics, this.dvsSelectVirtualAdaptersPageService.isVnicModified);
         } else if (itemWrapper.type ===
               DvsAddHostWizardConstants.nicListItemType.HOST_TYPE) {
            let childGroupingItems: DvsAddHostTreeListItemWrapper[] =
                  this.treeListDataSource.childNodes(itemWrapper);
            let childVnicsOnThisSwitch: DvsAddHostTreeListItemWrapper[] =
                  this.treeListDataSource.childNodes(childGroupingItems[0]);
            let childVnicsOnOtherSwitches: DvsAddHostTreeListItemWrapper[] =
                  this.treeListDataSource.childNodes(childGroupingItems[1]);
            return _.some(childVnicsOnThisSwitch, this.dvsSelectVirtualAdaptersPageService.isVnicModified) ||
                  _.some(childVnicsOnOtherSwitches, this.dvsSelectVirtualAdaptersPageService.isVnicModified);
         } else {
            throw new Error("Unknown item type: " + itemWrapper.type);
         }
      }

      private requestVirtualAdapters(): IPromise<any> {
         let hostRefs: ManagedObjectReference[] =
               _.map(this.$wizardScope.model.selectedHosts,
                     (item: HostListItemData) => item.hostRef);

         let requestParameter = new DvsHostsSpec();
         requestParameter.hosts = hostRefs;
         requestParameter.areMemberHosts =
               this.$wizardScope.model.selectOperationPageModel.selectedOperationType ===
               DvsAddHostWizardConstants.operationType.MANAGE_HOST ||
               this.$wizardScope.model.selectOperationPageModel.selectedOperationType ===
               DvsAddHostWizardConstants.operationType.MIGRATE_NETWORKING;
         let params = {
            propertyParams: [{
               propertyName: DvsSelectVirtualAdaptersPageController.VIRTUAL_ADAPTERS_PROPERTY,
               parameterType: requestParameter._type,
               parameter: requestParameter
            }]
         };

         return this.dataService.getProperties(
               this.$wizardScope.model.dvsId,
               [DvsSelectVirtualAdaptersPageController.VIRTUAL_ADAPTERS_PROPERTY],
               params);
      }

      private createHostVnicTreeModel(hostVirtualAdaptersData: any): void {
         if (hostVirtualAdaptersData) {
            let hostDataArray: DvsAddHostHierarchicalListItem[] =
                  hostVirtualAdaptersData[
                        DvsSelectVirtualAdaptersPageController.VIRTUAL_ADAPTERS_PROPERTY];

            if (hostDataArray === null) {
               // Workaround for kendo ObservableArray which throws an error in case of
               // a null constructor argument.
               hostDataArray = [];
            }
            let dataProvider: DvsAddHostTreeListItemWrapper[] =
                  _.map(hostDataArray, (item: DvsAddHostHierarchicalListItem) => {
                     let wrapperItem: DvsAddHostTreeListItemWrapper =
                           item as DvsAddHostTreeListItemWrapper;
                     wrapperItem.originalItem =
                           angular.copy(item, new DvsAddHostHierarchicalListItem());
                     return wrapperItem;
                  });

            this.initializeVirtualAdaptersTreeList(dataProvider);

            this.$wizardScope.model.selectVirtualAdaptersPageModel.isInitialized = true;
         }
      }

      private initializeVirtualAdaptersTreeList(data: DvsAddHostTreeListItemWrapper[]) {
         this.checkVnicsForConnectivityLoss(data);
         this.treeListDataSource = new kendo.data.TreeListDataSource({
            data: data,
            schema: this.dvsSelectVirtualAdaptersPageService.getTreeListSchema()
         });
         this.treeListDataSource.read();
         this.treeListColumns = this.dvsSelectVirtualAdaptersPageService.getColumnDefs();

         this.$wizardScope.model.selectVirtualAdaptersPageModel.hosts =
               this.treeListDataSource.data();
      }

      private hostItems(): DvsAddHostHierarchicalListHostItem[] {
         return this.$wizardScope.model.selectVirtualAdaptersPageModel.hosts
               .filter((item: DvsAddHostHierarchicalListItem): boolean => {
                  return item.type === DvsAddHostWizardConstants.nicListItemType.HOST_TYPE;
               })
               .map((item: DvsAddHostHierarchicalListItem): DvsAddHostHierarchicalListHostItem =>
                     item as DvsAddHostHierarchicalListHostItem);
      }
      private vnicItems(): DvsAddHostHierarchicalListVnicItem[] {
         return this.$wizardScope.model.selectVirtualAdaptersPageModel.hosts
               .filter((item: DvsAddHostHierarchicalListItem): boolean => {
                  return item.type === DvsAddHostWizardConstants.nicListItemType.VNIC_TYPE;
               })
               .map((item: DvsAddHostHierarchicalListItem): DvsAddHostHierarchicalListVnicItem =>
                     item as DvsAddHostHierarchicalListVnicItem);
      }

      private asVnicItem(listItem: DvsAddHostHierarchicalListItem): DvsAddHostHierarchicalListVnicItem {
         let vnicItem: DvsAddHostHierarchicalListVnicItem =
               listItem as DvsAddHostHierarchicalListVnicItem;
         return vnicItem;
      }

      private asWrapper(listItem: DvsAddHostHierarchicalListItem): DvsAddHostTreeListItemWrapper {
         return listItem as DvsAddHostTreeListItemWrapper;
      }

      private getHostsWithoutVnic(device: string): any[] {
         const vnicItemsWithName: DvsAddHostHierarchicalListVnicItem[] = this.getVnicItemsWithName(device);

         return this.hostItems()
               .filter((hostItem: DvsAddHostHierarchicalListHostItem) => {
                  return !vnicItemsWithName.some((vnicItem: DvsAddHostHierarchicalListVnicItem): boolean => {
                     return vnicItem.hostRef.value === hostItem.hostRef.value;
                  });
               }).map((hostItem: DvsAddHostHierarchicalListHostItem) => {
                  return hostItem.label;
               });
      }

      private getVnicItemsWithName(device: string): DvsAddHostHierarchicalListVnicItem[] {
         return this.vnicItems().filter(
               (item): boolean => item.device === device);
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .controller("DvsSelectVirtualAdaptersPageController",
               DvsSelectVirtualAdaptersPageController);
}
