module cluster_ui {

    import IComponentController = angular.IComponentController;

    export class EditResourcePoolScheduledTaskDialogController implements IComponentController {
        public static $inject = ["$scope", "i18nService", "vscSchedulingHelper", "mutationService"];
        public actionEval: any;
        public invalidResourcePool: boolean = false;
        public resourcePoolSpec: any;
        public pageModel: any;
        public editResourcePool: any = 'cluster-ui/resources/cluster/views/edit_resource_pool/editResourcePool.html';
        private readonly SCHEDULE_TASK_SPEC: string = "com.vmware.vise.data.scheduling.ScheduledMutationOperationSpec";

        constructor(private $scope: any, private i18nService: any, private vscSchedulingHelper: any, private mutationService: any) {
            this.actionEval = this.$scope.modalOptions.actionEval;
            this.pageModel = this.actionEval.additionalData.schedulingData;
            const callback = this.getResourcePoolSpec.bind(this);
            this.$scope.$on("stDataEvent", callback);
        }

        public getPageValidated(): boolean {
           this.$scope.modalOptions.submitDisabled = true;
           if (this.pageModel.schedulingSpec
                 && this.pageModel.schedulingSpec.isFormStatusValid
                 && !this.invalidResourcePool) {
              this.$scope.modalOptions.submitDisabled = false;
              return false;
           }
           return true;
        }

        public getResourcePoolSpec(event: any, spec: any) {
           this.invalidResourcePool = spec.invalidResourcePool;
           this.resourcePoolSpec = spec;
        }

        public onCommit() {
           const mutationSpec: any = this.pageModel;
           mutationSpec.spec = this.resourcePoolSpec.configSpec;
           mutationSpec.spec._type = this.resourcePoolSpec._type;
           mutationSpec.schedulingSpec.recurrence
              = this.vscSchedulingHelper.formatRecurrenceData(mutationSpec.schedulingSpec.recurrence);
           this.mutationService.apply(this.resourcePoolSpec.objectId, this.SCHEDULE_TASK_SPEC, mutationSpec);
           this.$scope.closeModal();
        }
    }

   angular.module("com.vmware.vsphere.client.cluster")
         .controller("EditResourcePoolScheduledTaskDialogController",
               EditResourcePoolScheduledTaskDialogController);
}
