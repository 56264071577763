/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace network_ui {

   export class VmMigrateSelectVmsPageValidator
         implements VmMigrateWizardPageValidator {

      public static $inject = [
            "i18nService",
            "$q",
            "vmMigrateWizardConstants"];

      constructor(private i18nService: any,
                  private $q: any,
                  private wizardConstants: any) {
      }

      public getValidationError(wizardScope: VmMigrateWizardScope): string[] {
         let errors: string[] = [];

         if (!this.hasSelectedItem(wizardScope.model.vmListItems)) {
            errors.push(this.i18nService.getString(
                  "NetworkUi", "SelectVmsToMigratePage.noAdaptersSelected"));
         }

         return this.$q.resolve(errors);
      }

      private hasSelectedItem(vmItemsData: any[]): boolean {
         if (!vmItemsData) {
            return false;
         }

         for (let item of vmItemsData) {
            if (item.isChecked) {
               return true;
            }
         }
         return false;
      }
   }

   angular.module("com.vmware.vsphere.client.network")
         .service("vmMigrateSelectVmsPageValidator",
               VmMigrateSelectVmsPageValidator);
}
