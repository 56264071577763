/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
import DvpgSecurityPolicyModel =
      com.vmware.vsphere.client.h5.network.dvportgroup.common.model.DvpgSecurityPolicyModel;
import DvpgFailoverPolicyModel =
      com.vmware.vsphere.client.h5.network.dvportgroup.common.model.DvpgFailoverPolicyModel;
import DvpgResourceAllocationPolicyModel =
      com.vmware.vsphere.client.h5.network.dvportgroup.common.model.DvpgResourceAllocationPolicyModel;
import DvpgTrafficShapingPolicyModel =
      com.vmware.vsphere.client.h5.network.dvportgroup.common.model.DvpgTrafficShapingPolicyModel;
import DvpgVlanPolicyModel =
      com.vmware.vsphere.client.h5.network.dvportgroup.common.model.DvpgVlanPolicyModel;
import DvpgMonitoringPolicyModel =
      com.vmware.vsphere.client.h5.network.dvportgroup.common.model.DvpgMonitoringPolicyModel;
import DvpgMiscPolicyModel =
      com.vmware.vsphere.client.h5.network.dvportgroup.common.model.DvpgMiscPolicyModel;

namespace dvs_ui {

   export class DvpgManageWizardModel {

      public parentId: string;
      public dvsName: string;
      public selectedPortgroups: any[];
      public failoverOrderOptions: any;
      public policies: DvpgManagePolicyModel;

      public securityPolicyModel: DvpgSecurityPolicyModel;
      public failoverPolicyModel: DvpgFailoverPolicyModel;
      public resourceAllocationPolicyModel: DvpgResourceAllocationPolicyModel;
      public trafficShapingPolicyModel: DvpgTrafficShapingPolicyModel;
      public vlanPolicyModel: DvpgVlanPolicyModel;
      public monitoringPolicyModel: DvpgMonitoringPolicyModel;
      public miscPolicyModel: DvpgMiscPolicyModel;
   }
}
