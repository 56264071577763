/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace dvs_ui {

   import IRootScopeService = angular.IRootScopeService;
   import IPromise = angular.IPromise;
   import DvsPortEditData =
         com.vmware.vsphere.client.h5.network.dvs.ports.model.DvsPortEditData;
   import PortConfigSpec = com.vmware.vim.binding.vim.dvs.DistributedVirtualPort$ConfigSpec;
   import DvsPortFailoverPolicyModel = com.vmware.vsphere.client.h5.network.dvs.ports.model.DvsPortFailoverPolicyModel;

   export class DvsPortEditDialogService {

      public static $inject = [
         "$rootScope",
         "vxMultiPageDialogService",
         "i18nService",
         "dataService",
         "mutationService",
         "dvsPortEditDialogPages",
         "defaultUriSchemeUtil",
         "dvsPortEditDialogSpecFactory",
         "dvpgFailoverPolicyPageService"
      ];

      constructor(private $rootScope: IRootScopeService,
                  private vxMultiPageDialogService: any,
                  private i18nService: any,
                  private dataService: any,
                  private mutationService: any,
                  private dvsPortEditDialogPages: any,
                  private defaultUriSchemeUtil: any,
                  private dvsPortEditDialogSpecFactory: DvsPortEditDialogSpecFactory,
                  private dvpgFailoverPolicyPageService: DvpgFailoverPolicyPageService) {
      }

      /**
       * Shows the Edit Port Settings dialog
       */
      public show(portContextUrn: string, selectedPort: any,
                  isPortgoupContext: boolean, dSwitchMor: string): void {
         let dialogScope: any = this.$rootScope.$new();

         dialogScope.portContextUrn = portContextUrn;
         dialogScope.isPortgoupContext = isPortgoupContext;
         dialogScope.selectedPort = selectedPort;

         dialogScope.model = new DvsPortEditDialogModel();
         dialogScope.model.portId = selectedPort.portId;

         dialogScope.dialogOptions = this.createDialogOptions();
         dialogScope.dialogOptions.confirmOptions = {
            onClick: (): boolean => {
               this.submitDialog(dialogScope.model, dialogScope.initialModel);
               return true;
            }
         };

         let options: any = {
            scope: dialogScope,
            configObjectName: "dialogOptions"
         };

         this.vxMultiPageDialogService(options);

         this.requestData(dialogScope, dSwitchMor);
      }

      private requestData(dialogScope: any, dSwitchUri: string): void {
         let portEditProperty: string = dialogScope.isPortgoupContext
               ? DvsPortEditDialogConstants.DATA_DVPG_PROPERTY
               : DvsPortEditDialogConstants.DATA_DVS_PROPERTY;

         let portPromise: IPromise<any>;

         if (dialogScope.isPortgoupContext) {
            portPromise = this.dataService.getProperties(
                  dialogScope.portContextUrn, [portEditProperty], {
                     propertyParams: [{
                        propertyName: portEditProperty,
                        parameterType: "java.util.Map",
                        parameter: {
                           dSwitchUri: dSwitchUri,
                           portId: dialogScope.selectedPort.portId
                        }
                     }]
                  });
         } else {
            portPromise = this.dataService.getProperties(
                  dialogScope.portContextUrn, [portEditProperty], {
                     propertyParams: [{
                        propertyName: portEditProperty,
                        parameterType: "java.util.Map",
                        parameter: {
                           portgroupKey: dialogScope.selectedPort.portgroupKey,
                           portId: dialogScope.selectedPort.portId
                        }
                     }]
                  });
         }

         portPromise.then((dataServiceResponse: any) => {
            if (dataServiceResponse && dataServiceResponse[portEditProperty]) {
               let data: DvsPortEditData = dataServiceResponse[portEditProperty];
               dialogScope.initialModel = angular.copy(data);

               dialogScope.model.propertiesPage =
                     new DvsPortEditPropertiesPageModel(data.properties);
               dialogScope.model.portDvsUrn =
                     this.defaultUriSchemeUtil.getVsphereObjectId(data.switchRef);
               dialogScope.model.isUplink = data.isUplink;
               dialogScope.model.isLag = data.isLag;
               dialogScope.model.configVersion = data.configVersion;
               dialogScope.model.securityPage =
                     new DvsPortEditSecurityPageModel(data.securityPolicy);
               dialogScope.model.vlanPage = new DvsPortEditVlanPageModel(data.vlanPolicy);
               dialogScope.model.trafficShapingPage =
                     new DvsPortEditTrafficShapingPageModel(data.trafficShapingPolicy);
               dialogScope.model.failoverPolicyPage =
                     this.assignFailoverPolicies(data.failoverPolicy);
               dialogScope.model.monitoringPage =
                     new DvsPortEditMonitoringPageModel(data.monitoringPolicy);
               dialogScope.model.miscellaneousPage =
                     new DvsPortEditMiscellaneousPageModel(data.miscPolicy);
               dialogScope.model.dvPortgroupPortProperties = data.dvPortgroupPortProperties;

               dialogScope.dialogOptions.title = this.i18nService
                     .getString("NetworkUi", "port.settings.title",
                           dialogScope.selectedPort.portId, data.switchName);

               dialogScope.dialogOptions.pages =
                     this.dvsPortEditDialogPages.createPages(dialogScope);
               dialogScope.dialogOptions.loading = false;
            }
         });
      }

      private assignFailoverPolicies(
            failoverPolicyModel: DvsPortFailoverPolicyModel): DvsPortEditFailoverPageModel | null {
         if (!failoverPolicyModel) {
            return null;
         }

         let failoverOrder: any = this.dvpgFailoverPolicyPageService
               .createFailoverOrderSettings(failoverPolicyModel.failoverOrder);

         return new DvsPortEditFailoverPageModel(failoverPolicyModel, failoverOrder);
      }

      private createDialogOptions(): any {
         return {
            title: this.i18nService.getString("NetworkUi", "port.settings.initialTitle"),
            show: true,
            loading: true,
            loadingMessage: this.i18nService.getString("CommonUi", "wizard.loading"),
            validationBanner: {},
            rejectOptions: {
               rejectOnEsc: true
            },
            resizable: true,
            draggable: true,
            height: "640px",
            width: "960px",
            maximizable: true
         };
      }

      private submitDialog(dialogModel: DvsPortEditDialogModel,
                           initialDialogModel: DvsPortEditData): void {
         let data: PortConfigSpec | null = this.dvsPortEditDialogSpecFactory.buildSpec(
               dialogModel, initialDialogModel);

         if (data) {
            this.mutationService.apply(dialogModel.portDvsUrn, data._type, data);
         }
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .service("dvsPortEditDialogService", DvsPortEditDialogService);
}
