/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace dvs_ui {

   export class DvsOutOfSyncHostController {

      static $inject = ["$scope"];

      public description: string;
      public dvsOutOfSyncHostData: any;

      constructor(protected $scope: any) {
         this.description = $scope.modalOptions.dialogData.desc;
         this.dvsOutOfSyncHostData = $scope.modalOptions.dialogData.dvsOutOfSyncHostData;

         let onRouteChangeEventHandler = $scope.$on("vxRouteChangeSuccess", () => {
            $scope.modalOptions.modalOpen = false;
         });

         $scope.$on("$destroy", () => {
            onRouteChangeEventHandler();
         });
      }
   }

   angular.module('com.vmware.vsphere.client.dvs')
         .controller('DvsOutOfSyncHostController', DvsOutOfSyncHostController);
}
