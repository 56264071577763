/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace dvs_ui {

   export class DvsUpgradeWizardManager {

      public static $inject = [
         "pageStateManagementService",
         "dvsUpgradeWizardConstants",
         "vuiConstants",
         "dvsUpgradeFeaturesService"];

      constructor(private pageStateManagementService:any,
                  private wizardConstants: any,
                  private vuiConstants: any,
                  private featureService: any) {
      }

      public init(wizardScope:DvsUpgradeWizardScope):void {
         this.pageStateManagementService
               .registerWizardForDefaultStateManagementStrategy(wizardScope);

         // Hide the Upgrade NIOC pages initially
         this.showHideUpgradeNiocPages(
               wizardScope.wizardConfig.pages,
               false/*isExplicitNiocUpgradeSupported*/,
               false/*isExplicitNiocUpgradeEnabled*/,
               false/*isUserDefinedNrpAvailable*/);

         // Hide the Upgrade LACP pages initially
         this.showHideUpgradeLacpPages(
               wizardScope.wizardConfig.pages,
               false/*isExplicitLacpUpgradeSupported*/,
               false/*isExplicitLacpUpgradeEnabled*/,
               false/*isLagConfigAvailable*/);

         wizardScope.$watch("model.isExplicitLacpUpgradeEnabled", () => {
            let showLagPage: boolean =
                  wizardScope.model.isExplicitLacpUpgradeSupported &&
                  wizardScope.model.isExplicitLacpUpgradeEnabled &&
                  wizardScope.model.lag !== null;

            this.showHidePage(wizardScope.wizardConfig.pages,
                  this.wizardConstants.pages.DVS_LACP_CREATE_LAG_PAGE, showLagPage);
         });

         wizardScope.$watch("model.isExplicitNiocUpgradeEnabled", () => {
            let showMigrateSharesPage: boolean =
                  wizardScope.model.isExplicitNiocUpgradeSupported &&
                  wizardScope.model.isExplicitNiocUpgradeEnabled &&
                  wizardScope.model.niocUpgradeStatistics !== null &&
                  wizardScope.model.niocUpgradeStatistics.userDefinedNrpCount > 0;

            this.showHidePage(wizardScope.wizardConfig.pages,
                  this.wizardConstants.pages.DVS_NIOC_MIGRATE_SHARES_PAGE,
                  showMigrateSharesPage);
         });
      }

      public showHideUpgradeNiocPages(
            pages: WizardPage[],
            isExplicitNiocUpgradeSupported: boolean,
            isExplicitNiocUpgradeEnabled: boolean,
            isUserDefinedNrpAvailable: boolean): void {

         let showMigrateSharesPage: boolean = isExplicitNiocUpgradeSupported &&
               isUserDefinedNrpAvailable &&
               isExplicitNiocUpgradeEnabled;

         this.showHidePage(pages,
               this.wizardConstants.pages.DVS_NIOC_PREREQUISITES_PAGE,
               isExplicitNiocUpgradeSupported);

         this.showHidePage(pages,
               this.wizardConstants.pages.DVS_NIOC_MIGRATE_SHARES_PAGE,
               showMigrateSharesPage);
      }

      public showHideUpgradeLacpPages(
            pages: WizardPage[],
            isExplicitLacpUpgradeSupported: boolean,
            isExplicitLacpUpgradeEnabled: boolean,
            isLagConfigAvailable: boolean): void {

         let showLagPage: boolean = isExplicitLacpUpgradeSupported &&
               isLagConfigAvailable &&
               isExplicitLacpUpgradeEnabled;

         this.showHidePage(pages,
               this.wizardConstants.pages.DVS_LACP_PREREQUISITES_PAGE,
               isExplicitLacpUpgradeSupported);

         this.showHidePage(pages,
               this.wizardConstants.pages.DVS_LACP_CREATE_LAG_PAGE,
               showLagPage);
      }

      public updateExplicitUpgradesPages(wizardScope: DvsUpgradeWizardScope): void {
         wizardScope.model.isExplicitLacpUpgradeSupported =
               this.featureService.isExplicitLacpUpgradeSupported(
                     wizardScope.model.selectedVersion,
                     wizardScope.model.isLacpConfigured,
                     wizardScope.model.currentLacpVersion);

         this.showHideUpgradeLacpPages(
               wizardScope.wizardConfig.pages,
               wizardScope.model.isExplicitLacpUpgradeSupported,
               wizardScope.model.isExplicitLacpUpgradeEnabled,
               wizardScope.model.lag !== null);

         wizardScope.model.isExplicitNiocUpgradeSupported =
               this.featureService.isExplicitNiocUpgradeSupported(
                     wizardScope.model.selectedVersion,
                     wizardScope.model.currentNiocVersion,
                     wizardScope.model.niocUpgradeStatistics);

         let isUserDefinedNrpAvailable: boolean =
               wizardScope.model.niocUpgradeStatistics !== null &&
               wizardScope.model.niocUpgradeStatistics.userDefinedNrpCount > 0;

         this.showHideUpgradeNiocPages(
               wizardScope.wizardConfig.pages,
               wizardScope.model.isExplicitNiocUpgradeSupported,
               wizardScope.model.isExplicitNiocUpgradeEnabled,
               isUserDefinedNrpAvailable);
      }

      private showHidePage(
            pages: WizardPage[],
            pageId: string,
            showPage: boolean): void {

         let page: WizardPage = _.findWhere(pages, { id: pageId });

         if (page) {
            page.state = showPage
                  ? this.vuiConstants.wizard.pageState.INCOMPLETE
                  : this.vuiConstants.wizard.pageState.SKIPPED;
         }
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .service("dvsUpgradeWizardManager", DvsUpgradeWizardManager);
}
