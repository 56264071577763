(function () {
   'use strict';

   angular.module('com.vmware.vsphere.client.network').service(
         'netStackListService', netStackListService);

   netStackListService.$inject = [
      'vuiConstants',
      'i18nService',
      'networkUtil'
   ];

   function netStackListService (vuiConstants, i18nService, networkUtil) {

      return {
         createDatagridOptions: createDatagridOptions
      };

      function createDatagridOptions(data, actionBarOptions, ipv6Enabled, onSelectionChange) {
         return {
            columnDefs: createNetStackColumnDefs(ipv6Enabled),
            sortMode: vuiConstants.grid.sortMode.SINGLE,
            selectionMode: vuiConstants.grid.selectionMode.SINGLE,
            selectedItems: [],
            data: data,
            resizable: true,
            height: '100%',
            pageConfig: {
               hidePager: true
            },
            actionBarOptions: actionBarOptions,
            onChange: onSelectionChange
         };
      }

      function createNetStackColumnDefs(isIpv6Enabled) {
         var defs = [{
               displayName: i18nService.getString('H5NetworkUi', 'NetStackInstanceList.tcpIpInstance'),
               field: 'name',
               width: '120px'
            },
            {
               displayName: i18nService.getString('H5NetworkUi', 'NetStackInstanceList.type'),
               field: 'type',
               width: '100px'
            },
            {
               displayName: i18nService.getString('H5NetworkUi', 'NetStackInstanceList.assignedAdapters'),
               field: 'virtualNicCount',
               width: '100px',
               template: function (item) {
                  return '<span class="right-align-cell">' + item.virtualNicCount + '</span>';
               },
               sortable: function (item1, item2) {
                  return networkUtil.compareNumericValues(item1, item2, 'virtualNicCount');
               }
            },
            {
               displayName: i18nService.getString('H5NetworkUi', 'NetStackInstanceList.ipv4GatewayAddress'),
               field: 'ipv4GatewayAddress',
               width: '100px'
            }];

         if (isIpv6Enabled) {
            defs.push({
               displayName: i18nService.getString('H5NetworkUi', 'NetStackInstanceList.ipv6GatewayAddress'),
               field: 'ipv6GatewayAddress',
               width: '180px'
            });
         }

         defs.push({
               displayName: i18nService.getString('H5NetworkUi', 'NetStackInstanceList.preferredDnsServer'),
               field: 'preferredDnsServer',
               width: '150px'
            },
            {
               displayName: i18nService.getString('H5NetworkUi', 'NetStackInstanceList.alternateDnsServer'),
               field: 'alternateDnsServer',
               width: '150px'
            });

         return defs;
      }
   }
})();
