namespace storage_ui {

   import IPromise = angular.IPromise;

   export class FileReaderService {

      public static $inject = ["$q"];

      constructor(private $q: any) {
      }

      public readFileContentAsBase64(file: any): IPromise<string> {

         if (!file) {
            return this.$q.when(null);
         }

         let deferredResult = this.$q.defer();

         let fileReader = new FileReader();
         fileReader.onload = function () {
            let dataUrl = fileReader.result;
            if (dataUrl && dataUrl.indexOf(",") >= 0) {
               deferredResult.resolve(dataUrl.substring(dataUrl.indexOf(",") + 1));
            } else {
               deferredResult.resolve(null);
            }
         };

         fileReader.addEventListener("error", function () {
            deferredResult.resolve(null);
         });

         fileReader.readAsDataURL(file);

         return deferredResult.promise;
      }
   }

   angular.module("com.vmware.vsphere.client.storage")
         .service("fileReaderService", FileReaderService);
}
