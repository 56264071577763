namespace storage_ui {

   import IPromise = angular.IPromise;
   export class DatastoreSpaceReclamationControl {
      public bindings: any;
      public controller: any;
      public templateUrl: any;

      constructor() {
         this.bindings = {
            spaceReclamationData: "<"
         };
         this.controller = DatastoreSpaceReclamationControlController;
         this.templateUrl = "storage-ui/resources/storage/views/datastore/spacereclamation/DatastoreSpaceReclamationControl.html";
      }
   }

   interface UnmapPriority {
      api: string;
      displayValue: string;
      description: string;
   }

   class DatastoreSpaceReclamationControlController {
      public static $inject = ["i18nService"];

      public availableUnmapPriorities: UnmapPriority[];
      public spaceReclamationData: any;
      public unmapPriority: string;
      public selectedIndex: number;
      private static DEFAULT_UNMAP_PRIORITY: string = "low";

      constructor(private i18nService: any) {
         this.availableUnmapPriorities = [{
            api: "none",
            displayValue: this.i18nService.getString("StorageUi", "vmfs.unmapPriority.none"),
            description: this.i18nService.getString("StorageUi", "datastore.configure.general.spaceReclamation.spaceReclamationPriorityNoneDescription")
         }, {
            api: "low",
            displayValue: this.i18nService.getString("StorageUi", "vmfs.unmapPriority.low"),
            description: this.i18nService.getString("StorageUi", "datastore.configure.general.spaceReclamation.spaceReclamationPriorityDescription", this.i18nService.getString("StorageUi", "vmfs.unmapPriority.low"), this.i18nService.getString("StorageUi", "vmfs.unmapPriority.low"))
         }];
      }

      public onUnmapPriorityChanged(index:any) {
         this.spaceReclamationData.unmapPriority = this.availableUnmapPriorities[index].api;
      }

      public $onChanges(changes:any) {
         if (changes["spaceReclamationData"]
               && changes["spaceReclamationData"].currentValue) {
            this.findSelectedIndex(changes["spaceReclamationData"].currentValue.unmapPriority);
         }
      }

      private findSelectedIndex(newValue: string) {
         for (let i: number = 0; i < this.availableUnmapPriorities.length; i++) {
            if (this.availableUnmapPriorities[i].api === newValue) {
               this.selectedIndex = i;
               return;
            }
         }

         // The unmap priority is not found - fallback to default
         this.findSelectedIndex(DatastoreSpaceReclamationControlController.DEFAULT_UNMAP_PRIORITY);
      }
   }

   angular.module("com.vmware.vsphere.client.storage")
         .component("datastoreSpaceReclamationControl", new DatastoreSpaceReclamationControl());
}
