   /* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
(function () {
   'use strict';
   angular.module('com.vmware.vsphere.client.cluster')
      .controller('DrsPortletController', DrsPortletController);

   DrsPortletController.$inject = ['$scope', 'drsPortletService', 'i18nService'];

   function DrsPortletController($scope, drsPortletService, i18nService) {
      var self = this;
      var clusterId = $scope._route.objectId;

      $scope.clusterId = [clusterId];
      self.liveRefreshProperties = [
         "configurationEx.drsConfig.enabled",
         "configurationEx.drsConfig.defaultVmBehavior",
         "configurationEx.drsConfig.vmotionRate",
         "configurationEx.dpmConfigInfo.enabled",
         "configurationEx.dpmConfigInfo.defaultDpmBehavior",
         "summary.currentBalance",
         "summary.targetBalance",
         "recommendation",
         "drsFault"
      ];

      self.getViewData = getViewData;

      function getViewData() {
         drsPortletService.getViewData(clusterId).then(function (drsViewData) {
            self.signPostParams.model = $scope.drsModel = drsViewData;
         });
      }

      var title = i18nService.getString(
            'ClusterUi', 'drs.portlet.tooltip.hostLoadDeviationLabel');

      self.signPostParams = {
         templateUrl: 'cluster-ui/resources/cluster/views/summary/drsDeviationInfoTooltipContent.html',
         title: title,
         model: null,
         width: 440
      };

      getViewData();
   }
})();
