namespace ds_cluster_ui {

   export class AddVmOverridesWizardService {
      static $inject: string[] =
            ["$rootScope", "dataService", "vuiAddVmOverridesWizard", "addVmOverridesPageService"];
      public wizardScope: any;

      constructor(private $rootScope: any,
                  private dataService: any,
                  private vuiAddVmOverridesWizard: VuiAddVmOverridesWizard,
                  private addVmOverridesPageService: AddVmOverridesPageService) {
      }

      public invokeWizard(targetUid: string, manager: AddVmToVmOverridesManager): void {
         manager.setTargetUid(targetUid);
         manager.setSelectedVms([]);
         manager.setSpec({});
         this.wizardScope = this.$rootScope.$new();
         this.wizardScope.manager = manager;
         let pages: Array<any> = this.createWizardPages(this.wizardScope);
         this.dataService
               .getProperties(targetUid, ['name']
               )
               .then((response: any): void => {
                  if (response && response.name) {
                     let clusterName: string = response.name;
                     manager.setClusterName(clusterName);
                     this.vuiAddVmOverridesWizard.show(pages, this.wizardScope);
                  }
               });
      }

      private createWizardPages(wizardScope: any): Array<any> {
         let pages: Array<any> = [];
         pages.push(this.addVmOverridesPageService.buildSelectVmsPage(wizardScope));
         pages.push(this.addVmOverridesPageService.buildAddVmOverridesPage(wizardScope));
         return pages;
      }

   }

   angular.module('com.vmware.vsphere.client.dsCluster')
         .service('addVmOverridesWizardService', AddVmOverridesWizardService);
}

