namespace license_ui {

   export class AssetsGridViewController {

      public static $inject = ["$scope",
         "licensesManagementNavigationService",
         "licensingRouteObjectsFactoryService",
         "commonActionBarService"];

      private readonly ACTION_BAR_ACTIONS: string = "actions";
      private readonly ASSIGN_LICENSE_KEY_ACTION_ID = "vsphere.core.administration.assignLicenseKeyAction";
      private readonly REMOVE_SOLUTION_ACTION_ID = "vsphere.core.administration.removeSolutionsAction";

      private readonly ASSET_TYPE_SOLUTION = "SOLUTION";

      public focusedAsset: any;
      public focusedAssetUri: string;
      public selectedAssets: any[] = [];
      public selectedAssetsUris: any[] = [];
      public actionBarOptions: any;

      constructor(private $scope: any,
                  private licensesManagementNavigationService: LicensesManagementNavigationService,
                  private licensingRouteObjectsFactoryService: LicensingRouteObjectsFactoryService,
                  private commonActionBarService: any) {
      }

      public $onInit() {
         this.actionBarOptions = {
            actions: []
         };

         this.licensesManagementNavigationService.registerAssetsNavigationPerformedEventListener(
               this.$scope,
               (licensingRouteObject: LicensingRouteObject) => {
                  this.handleNavigationRequest(licensingRouteObject);
               }
         );

         this.focusedAssetUri = this.$scope.masterDetailsViewContext.getFocusedAssetItemUri();
         if (this.focusedAssetUri) {
            this.triggerNavigationRequestToFocusedAssetItem();
         }

         this.updateActionBar();
      }

      public onSelectedAssetsChanged(selectedAssets: any[]): void {
         if (selectedAssets && selectedAssets.length > 0) {
            this.selectedAssets = selectedAssets;
         } else {
            this.selectedAssets = [];
         }

         this.selectedAssetsUris = this.extractAssetsUris(this.selectedAssets);
         this.updateActionBar();
      }

      public onFocusedAssetChanged(focusedAsset: any): void {
         if ( this.focusedAsset !== focusedAsset) {
            this.focusedAsset = focusedAsset;
            this.$scope.masterDetailsViewContext.selectedItems = [focusedAsset];
         }

         this.focusedAssetUri = this.focusedAsset && this.focusedAsset.uri;
         this.triggerNavigationRequestToFocusedAssetItem();
      }

      private triggerNavigationRequestToFocusedAssetItem(): void {
         const selectedAssetTabId = this.$scope.masterDetailsViewContext.getSelectedAssetTabId();
         const assetsListRouteObject: LicensingRouteObject = this.licensingRouteObjectsFactoryService
               .createAssetsListLicensingRouteObject(selectedAssetTabId, this.focusedAssetUri);
         this.licensesManagementNavigationService
               .emitLicensesManagementNavigationRequestOnScope(this.$scope, assetsListRouteObject);
      }

      private updateActionBar() {
         let updatedOptions = [];
         if (this.actionBarOptions && this.actionBarOptions.actions && this.actionBarOptions.actions.length) {
            this.actionBarOptions.actions.forEach((action: any) => {
               if (action.actionId) {
                  action.enabled = false;
               }
            });
         }

         this.commonActionBarService.updateActionBar(
               updatedOptions,
               this.ACTION_BAR_ACTIONS,
               this.selectedAssetsUris,
               this.getActions()).then(() => {
            this.actionBarOptions = updatedOptions;
         });
      }

      private getActions(): any[] {
         let actionDefs: any[] = [];

         actionDefs.push({
            actionId: this.ASSIGN_LICENSE_KEY_ACTION_ID,
            getActionInvocationContext: () => {
               return this.selectedAssetsUris;
            },
            isActionVisible: (actionDef: any) => {
               return !!(this.selectedAssetsUris && this.selectedAssetsUris.length > 0 && actionDef);
            }
         });

         if (this.$scope.masterDetailsViewContext.assetType === this.ASSET_TYPE_SOLUTION) {
            actionDefs.push({
               actionId: this.REMOVE_SOLUTION_ACTION_ID,
               isActionVisible: (actionDef: any) => {
                  return !!(this.selectedAssetsUris && this.selectedAssetsUris.length > 0 && actionDef);
               }
            });
         }
         return actionDefs;
      }

      private handleNavigationRequest(licensingRouteObject: LicensingRouteObject | null): void {
         if (licensingRouteObject &&
               this.licensesManagementNavigationService.canNavigationRequestInLicensingRouteBeHandled(
                     [this.$scope.masterDetailsViewContext.getSelectedAssetTabId()], licensingRouteObject)) {
            if (licensingRouteObject.itemId) {
               this.focusedAssetUri = licensingRouteObject.itemId;
            }
         }
      }

      private extractAssetsUris(assets: any[]): string[] {
         let assetsUris: string[] = [];
         if (assets && assets.length > 0) {
            assetsUris = assets.map((asset: any) => {
               return asset && asset.uri;
            });
         }

         return assetsUris;
      }
   }

   angular.module('com.vmware.vsphere.client.licenseUi').controller(
         'AssetsGridViewController',
         AssetsGridViewController
   );
}
