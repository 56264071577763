/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
(function () {
   'use strict';
   angular.module('com.vmware.vsphere.client.storage').factory('adapterTargetsService',
      ['dataService', 'hostStorageConstants', 'defaultUriSchemeUtil', 'logService', 'mutationService',
         function (dataService, hostStorageConstants, defaultUriSchemeUtil, logService, mutationService) {
            var log = logService('adapterTargetsService');

            var targets = {
               adapterKey: undefined,
               dynamicTargets: [],
               staticTargets: []
            };

            return {
               retrieveIscsiAdapterTargets: retrieveIscsiAdapterTargets,
               addIscsiAdapterTargets: addIscsiAdapterTargets,
               removeIscsiAdapterTargets: removeIscsiAdapterTargets
            };

            function retrieveIscsiAdapterTargets(hostId, storageAdapterKey) {
               var STORAGE_BUS_ADAPTER_PROPERTY = "hostBusAdapter";

               var params;
               if (storageAdapterKey) {
                  params =
                     constructStringParamForProperty(STORAGE_BUS_ADAPTER_PROPERTY, storageAdapterKey);
               }

              return dataService.getProperties(hostId, [STORAGE_BUS_ADAPTER_PROPERTY], params).then(function(response) {
                 var result = response[STORAGE_BUS_ADAPTER_PROPERTY] || {};

                 targets.adapterKey = storageAdapterKey;
                 targets.dynamicTargets = [];
                 targets.staticTargets = [];
                 targets.hba = result;

                 var dynamicTargetsByNameMap = {};

                 // Populates dynamic targets
                 if (result.configuredSendTarget) {
                    var dynamicTargets = [];
                    result.configuredSendTarget.forEach(function(target) {
                       var formattedServerAddress = getFormattedServerAddress(target);
                       dynamicTargets.push(
                          {
                             address: formattedServerAddress,
                             target: target
                          }
                       );
                       dynamicTargetsByNameMap[formattedServerAddress] = target;
                    });
                    targets.dynamicTargets = dynamicTargets;
                 }

                 // Populates static targets
                 if (result.configuredStaticTarget) {
                    var staticTargets = [];
                    result.configuredStaticTarget.forEach(function(target) {
                       var parentAuthProperties = null;
                       if (dynamicTargetsByNameMap.hasOwnProperty(target.parent)) {
                          parentAuthProperties = dynamicTargetsByNameMap[target.parent].authenticationProperties;
                       }

                       staticTargets.push(
                          {
                             address: getFormattedServerAddress(target),
                             name: target.iScsiName,
                             target: target,
                             parentDynamicTargetAuthProperties: parentAuthProperties
                          }
                       );
                    });
                    targets.staticTargets = staticTargets;
                 }
                 return targets;
              });
            }

            function addIscsiAdapterTargets (hostId, isDynamicTarget, device, port, address, iscsiName, authenticationProperties) {
               var target = createTarget(isDynamicTarget, device, port, address, iscsiName, authenticationProperties);
               var spec = createSpec(true, hostId, isDynamicTarget, device, [target]);
               return mutationService.apply(hostId, spec._type, spec);
            }

            function removeIscsiAdapterTargets (hostId, isDynamicTarget, device, targets) {
               var spec = createSpec(false, hostId, isDynamicTarget, device, targets);
               return mutationService.apply(hostId, spec._type, spec);
            }

            function createTarget(isDynamicTarget, device, port, address, iscsiName, authenticationProperties) {
               if (!device || !port || !address) {
                  log.error('Some of mandatory parameters were null or undefined');
               }
               if (!isDynamicTarget && !iscsiName) {
                  log.error('iscsiName is mandatory for static targets');
               }

               if (isDynamicTarget) {
                  return {
                     _type: 'com.vmware.vim.binding.vim.host.InternetScsiHba$SendTarget',
                     address: address,
                     port: port,
                     parent: device,
                     authenticationProperties: authenticationProperties
                  };
               } else {
                  return {
                     _type: 'com.vmware.vim.binding.vim.host.InternetScsiHba$StaticTarget',
                     address: address,
                     port: port,
                     iScsiName: iscsiName,
                     parent: device,
                     authenticationProperties: authenticationProperties
                  };
               }
            }

            function createSpec (isAdd, hostId, isDynamicTarget, device, targets) {
               if (!hostId || !device || !targets) {
                  log.error('Some of mandatory parameters were null or undefined');
               }

               if (isDynamicTarget) {
                  return {
                     _type: 'com.vmware.vsphere.client.storage.adapters.InternetScsiStorageAdapterSendTargetsSpec',
                     isAdditionOperation: isAdd,
                     device: device,
                     hostRef: defaultUriSchemeUtil.getManagedObjectReference(hostId),
                     sendTargets: targets
                  };
               } else {
                  return {
                     _type: 'com.vmware.vsphere.client.storage.adapters.InternetScsiStorageAdapterStaticTargetsSpec',
                     isAdditionOperation: isAdd,
                     device: device,
                     hostRef: defaultUriSchemeUtil.getManagedObjectReference(hostId),
                     staticTargets: targets
                  };
               }
            }

            function getFormattedServerAddress(dynamicTarget) {
               var formattedAddress = "";
               if (!dynamicTarget) {
                  return formattedAddress;
               }

               var port = dynamicTarget.port || hostStorageConstants.DEFAULT_PORT_VALUE;
               formattedAddress = formattedAddress.concat(dynamicTarget.address, ":", port);

               return formattedAddress;
            }

            function constructStringParamForProperty (propertyName, paramValue) {
               return {
                  propertyParams: [{
                     propertyName: propertyName,
                     parameterType: 'java.lang.String',
                     parameter: paramValue
                  }]
               };
            }
         }]);
})();
