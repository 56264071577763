(function () {
   'use strict';

   angular.module('com.vmware.vsphere.client.cluster')
         .service('haPdlResponseService', haPdlResponseService);

   haPdlResponseService.$inject = ['i18nService', 'haConstants'];

   function haPdlResponseService(i18nService, haConstants) {

      var pdlResponseLabels = {};
      var pdlResponseDescriptions = {};
      var possiblePdlResponses = [
         haConstants.storageVmReaction.DISABLED,
         haConstants.storageVmReaction.WARNING,
         haConstants.storageVmReaction.RESTART_AGGRESSIVE
      ];

      pdlResponseLabels[haConstants.storageVmReaction.DISABLED] =
            getString('ha.config.failures.responses.ds.dswithpdl.disabled');
      pdlResponseLabels[haConstants.storageVmReaction.WARNING] =
            getString('ha.config.failures.responses.ds.dswithpdl.issue.events');
      pdlResponseLabels[haConstants.storageVmReaction.RESTART_AGGRESSIVE] =
            getString('ha.config.failures.responses.ds.dswithpdl.power.off');

      pdlResponseDescriptions[haConstants.storageVmReaction.DISABLED] =
            getString('ha.config.failures.responses.ds.dswithpdl.disabled.desc');
      pdlResponseDescriptions[haConstants.storageVmReaction.WARNING] =
            getString('ha.config.failures.responses.ds.dswithpdl.issue.events.desc');
      pdlResponseDescriptions[haConstants.storageVmReaction.RESTART_AGGRESSIVE] =
            getString('ha.config.failures.responses.ds.dswithpdl.power.off.desc');

      function getString(key) {
         return i18nService.getString('ClusterUi', key);
      }

      function getResponses() {
         return _.map(possiblePdlResponses, function(reaction) {
            return {
               id: reaction,
               label: pdlResponseLabels[reaction],
               description: pdlResponseDescriptions[reaction]
            };
         });
      }

      return {
         getResponses: getResponses
      };
   }
})();
