(function() {
   'use strict';
   angular.module('com.vmware.vsphere.client.commonModule')
         .service('iconService', iconService);

   function iconService() {
      var iconMap = {
         Datacenter: 'vsphere-icon-datacenter',
         ClusterComputeResource: 'vsphere-icon-cluster',
         HostSystem: 'vsphere-icon-host',
         Folder: 'vsphere-icon-folder',
         Datastore: 'vsphere-icon-datastore'
      };

      return {
         getByObjectRef: function(ref) {
            return iconMap[ref.type];
         },
         getByObjectType: function(type) {
            return iconMap[type];
         },
         /**
          *
          * Splits the icon id on colon - :
          * the first part is used as namespace i.e. indicator how the
          * icon should be rendered if custom rendering is needed
          *
          * the second part contains the name of the icon as string
          * @param iconId the id of the icon as it is defined in the plugin.xml
          * @returns {Array} containing 2 elements
          *    - the namespace(type) of the icon or undefined if there is no namespace(type)
          *    - the name of the icon
          */
         parseIcon: function parseIcon(iconId) {
            iconId = typeof iconId !== 'string'? '' : iconId;
            var parts =  iconId.split(':');
            if (parts.length === 1) {
               parts.unshift(undefined);
            }

            return parts;
         }
      };
   }
})();
