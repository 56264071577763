/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace dvs_ui {
   import PvlanMapEntry =
         com.vmware.vim.binding.vim.dvs.VmwareDistributedVirtualSwitch$PvlanMapEntry;

   export class PvlanMapEntryExtended extends PvlanMapEntry {
      public isFound: boolean;

      constructor(primaryVlanId: number, secondaryVlanId: number, pvlanType: string) {
         super();
         this.primaryVlanId = primaryVlanId;
         this.secondaryVlanId = secondaryVlanId;
         this.pvlanType = pvlanType;
         this.isFound = false;
      }
   }
}