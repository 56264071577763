/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
(function () {
   'use strict';
   angular
         .module('com.vmware.vsphere.client.dvs')
         .controller('DvPortgroupTrafficShapingPageController',
               DvPortgroupTrafficShapingPageController);

   DvPortgroupTrafficShapingPageController.$inject = [
      '$scope',
      'vuiConstants',
      'dvpgTrafficShapingPolicyService'
   ];

   function DvPortgroupTrafficShapingPageController($scope, vuiConstants,
         dvpgTrafficShapingPolicyService) {

      $scope.manager.commitPageCallbacks.commitTrafficShapingPage = onCommit;

      function onCommit() {
         $scope.manager.pageDirtyFlags.isTrafficShapingPageDirty =
               hasShapingPolicyChanged($scope.initialData.inShapingPolicy,
                     $scope.manager.dvPortgroupData.inShapingPolicy) ||
               hasShapingPolicyChanged($scope.initialData.outShapingPolicy,
                     $scope.manager.dvPortgroupData.outShapingPolicy);

         if ($scope.manager.pageDirtyFlags.isTrafficShapingPageDirty) {
            var errors = dvpgTrafficShapingPolicyService.getErrorMessages(
                  $scope.manager.dvPortgroupData.inShapingPolicy,
                  $scope.manager.dvPortgroupData.outShapingPolicy);

            return _.map(errors, function(message) {
               return {
                  type: vuiConstants.validationBanner.type.ERROR,
                  text: message
               };
            });
         }

         return true;
      }

      function hasShapingPolicyChanged(initialPolicy, actualPolicy) {
         return initialPolicy.enabled !== actualPolicy.enabled ||
               initialPolicy.averageBandwidth !== actualPolicy.averageBandwidth ||
               initialPolicy.peakBandwidth !== actualPolicy.peakBandwidth ||
               initialPolicy.burstSize !== actualPolicy.burstSize;
      }

      return this;
   }
})();
