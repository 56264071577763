namespace h5_vm {

   declare const angular: ng.IAngularStatic;
   declare const _: any;

   import VmDisplayMode   = h5_vm.VmDisplayMode;
   import VmSharesContext = h5_vm.VmSharesContext;

   class VmHardwareVirtualDiskSharesController implements ng.IComponentController {
      static $inject: string[] = [
         'i18nService',
         'vmHardwareVirtualDiskSharesService'
      ];

      onChange:                             Function;
      displayMode:                          VmDisplayMode;
      sharesContext:                        VmSharesContext;
      isDisabled:                           boolean;

      private sharesId:                     string;
      private sharesInfo:                   {
         level:                             string,
         shares:                            number
      };
      private showRecommendations:          boolean;
      private shareMin:                     number;
      private shareMax:                     number;
      private sharesLevelPresetOptions:     any;

      private i18n:                         Function;

      private shareFormInputName:           string;
      private shareFormSelectName:          string;

      private shareValidationErrorMessages: {
         max:                               Function,
         min:                               Function
      };
      private shareRecommendationValues:    Array<any>;
      private shareLevels:                  Array<any>;

      private selected:                     {
         level:                             any,
         shares:                            number
      };
      private isCustomShareLevel:           boolean;
      private formInputName:                string;
      private formSelectName:               string;
      private readMode:                     boolean;

      constructor(private i18nService: any) {
         this.i18n = i18nService.getString;
      }

      $onInit() {
         if(this.sharesContext) {
            this.setSharesContext();
         }
      }

      $onChanges(changes: any) {
         if(changes.sharesContext.currentValue) {
            this.setSharesContext();
         }
      }

      private setFormNames() {
         if (this.sharesId) {
            this.formInputName  = this.sharesId + '-shares-value';
            this.formSelectName = this.sharesId + '-shares-levels';
         } else{
            this.formInputName  = 'shares-value';
            this.formSelectName = 'shares-levels';
         }

      }

      private setSharesContext() {
         this.sharesId = this.sharesContext.sharesId;
         this.sharesInfo = this.sharesContext.sharesInfo;
         this.showRecommendations = this.sharesContext.showRecommendations;
         this.shareMin = this.sharesContext.shareMin;
         this.shareMax = this.sharesContext.shareMax;
         this.sharesLevelPresetOptions = this.sharesContext.sharesLevelPresetOptions;

         this.setFormNames();
         this.shareLevels = [
            {label: this.i18n('VmUi', 'sharesInfoLevel.low'), data: 'low'},
            {label: this.i18n('VmUi', 'sharesInfoLevel.normal'), data: 'normal'},
            {label: this.i18n('VmUi', 'sharesInfoLevel.high'), data: 'high'},
            {label: this.i18n('VmUi', 'sharesInfoLevel.custom'), data: 'custom'}
         ];
         this.shareValidationErrorMessages = {
            max: () => { return this.i18n('Common', 'SharesControl.RangeError', this.shareMin, this.shareMax); },
            min: () => { return this.i18n('Common', 'SharesControl.RangeError', this.shareMin, this.shareMax); }
         };

         if (this.showRecommendations) {
            this.shareRecommendationValues = [{
               value: this.shareMin, name: 'Minimum'
            }, {
               value: this.shareMax, name: 'Maximum'
            }];
         }

         this.selected = {
            level: _.find(this.shareLevels, (level: any) => {
               return level.data === this.sharesInfo.level;
            }),
            shares: this.sharesInfo.shares
         };
         this.isCustomShareLevel = (this.sharesInfo.level === 'custom');
         this.readMode = (this.displayMode === VmDisplayMode.ReadMode ||
                          this.displayMode === VmDisplayMode.ReadAdvancedMode);
      }

      private selectedShareValues() {
         const shares = this.selected.shares;
         const level = this.selected.level.label;

         return `${shares} ${level}`;
      }

      private isValidShare(): boolean {
         return this.selected.shares >= this.shareMin &&
                this.selected.shares <= this.shareMax;
      }

      onSharesChange = (value: any) => {
         this.selected.shares = value;
         this.onChangeCallback();
      };

      onSharesLevelChange = () => {
         const level = this.selected.level.data;
         this.isCustomShareLevel = (level === 'custom');
         if (!this.isCustomShareLevel) {
            this.selected.shares = this.recalculateSharesValue(level);
         }
         this.onChangeCallback();
      };

      private onChangeCallback() {
         if (this.isValidShare()) {
            this.onChange({
               shares: {
                  level:  this.selected.level.data,
                  shares: this.selected.shares
               }
            });
         }
      }

      private recalculateSharesValue(level: any) {
         if (!this.isCustomShareLevel) {
            let value = this.sharesLevelPresetOptions[level];
            if (value === undefined || value === null) {
               throw new Error('Unknown level: ' + level);
            }
            return value;
         }
      }
   }

   export class VmHardwareVirtualDiskShares implements ng.IComponentOptions {

      bindings:    any;
      controller:  any;
      templateUrl: string;

      constructor() {
         this.bindings    = {
            onChange:      '&',
            displayMode:   '<',
            ariaLabel:     '<',
            sharesContext: '<',
            isDisabled:    '<'
         };
         this.controller  = VmHardwareVirtualDiskSharesController;
         this.templateUrl = 'vm-ui/resources/vm/views/components'+
            '/vmHardwareVirtualDiskShares/vm-hardware-virtual-disk-shares.html';
      }
   }

   angular.module('com.vmware.vsphere.client.vm')
      .component('vmHardwareVirtualDiskShares', new VmHardwareVirtualDiskShares());
}
