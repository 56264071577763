(function() {
   'use strict';

   angular.module('com.vmware.vsphere.client.cluster')
         .service('editProactiveHaService', editProactiveHaService);

   editProactiveHaService.$inject = ['i18nService', 'clusterConstants', 'vuiConstants',
      'dataService', 'columnRenderersRegistry'];

   function editProactiveHaService(i18nService, clusterConstants,
         vuiConstants, dataService, columnRenderersRegistry) {

      var getString = _.bind(i18nService.getString, i18nService, 'ClusterUi');

      return {
         getFormData: getFormData,
         getRemediationLevelDescription: getRemediationLevelDescription,
         getAutomationLevelDescription: getAutomationLevelDescription,
         getFilterForProvider: getFilterForProvider,
         failureConditionsVuiSignPost: failureConditionsVuiSignPost,
         getFailureConditionsHostsDatagridOptions: getFailureConditionsHostsDatagridOptions,
         failureConditionsClusterLevelVuiSignPost: failureConditionsClusterLevelVuiSignPost,
         getFilterModelSpec: getFilterModelSpec,
         getBlockAtValue: getBlockAtValue,
         REMEDIATION_LEVELS: [
            {
               value: clusterConstants.proactiveHaRemediationLevel.BALANCED,
               label: getString('ha.config.failures.responses.partial.failure.remediation.dd.balanced')
            },
            {
               value: clusterConstants.proactiveHaRemediationLevel.QUARANTINE,
               label: getString('ha.config.failures.responses.partial.failure.remediation.dd.quarantinemode')
            },
            {
               value: clusterConstants.proactiveHaRemediationLevel.MAINTENANCE,
               label: getString('ha.config.failures.responses.partial.failure.remediation.dd.maintenancemode')
            }
         ],
         AUTOMATION_LEVELS: [
            {
               value: clusterConstants.proactiveHaAutomationLevel.AUTOMATED,
               label: getString('ha.portlet.host.partial.failure.response.Automated')
            },
            {
               value: clusterConstants.proactiveHaAutomationLevel.MANUAL,
               label: getString('ha.portlet.host.partial.failure.response.Manual')
            }
         ]
      };

      function getFormData(proHaEnabled, automationLevel, remediationLevel, clusterProviders) {
         var formData = {
            infraUpdateHaConfig: {
               enabled: proHaEnabled
            }
         };

         if (proHaEnabled) {
            formData.infraUpdateHaConfig.behavior = automationLevel;
            switch (String(remediationLevel)) {
               case clusterConstants.proactiveHaRemediationLevel.QUARANTINE:
                  formData.infraUpdateHaConfig.moderateRemediation =
                        clusterConstants.proactiveHaRemediationMode.QUARANTINE_MODE;
                  formData.infraUpdateHaConfig.severeRemediation =
                        clusterConstants.proactiveHaRemediationMode.QUARANTINE_MODE;
                  break;
               case clusterConstants.proactiveHaRemediationLevel.BALANCED:
                  formData.infraUpdateHaConfig.moderateRemediation =
                        clusterConstants.proactiveHaRemediationMode.QUARANTINE_MODE;
                  formData.infraUpdateHaConfig.severeRemediation =
                        clusterConstants.proactiveHaRemediationMode.MAINTENANCE_MODE;
                  break;
               default:
                  formData.infraUpdateHaConfig.moderateRemediation =
                        clusterConstants.proactiveHaRemediationMode.MAINTENANCE_MODE;
                  formData.infraUpdateHaConfig.severeRemediation =
                        clusterConstants.proactiveHaRemediationMode.MAINTENANCE_MODE;
            }

            formData.infraUpdateHaConfig.providers = _.filter(clusterProviders, function(clusterProvider) {
               return clusterProvider.isEnabled;
            }).map(function(clusterProvider) {
               return clusterProvider.idProvider;
            });
         }

         return formData;
      }

      function getRemediationLevelDescription(remediationLevel) {
         if (clusterConstants.proactiveHaRemediationLevel.QUARANTINE === remediationLevel) {
            return getString('ha.config.failures.responses.partial.failure.remediation.quarantine.desc');
         } else if (clusterConstants.proactiveHaRemediationLevel.BALANCED === remediationLevel) {
            return getString('ha.config.failures.responses.partial.failure.remediation.balanced.desc');
         } else {
            return getString('ha.config.failures.responses.partial.failure.remediation.maintenance.desc');
         }
      }

      function getAutomationLevelDescription(automationLevel) {
         if (clusterConstants.proactiveHaAutomationLevel.MANUAL === automationLevel) {
            return getString('ha.config.failures.responses.partial.failure.automation.manual.desc');
         } else {
            return getString('ha.config.failures.responses.partial.failure.automation.automated.desc');
         }
      }

      function getFilterForProvider(clusterId, providerId) {
         var params = {
            propertyParams: [{
               propertyName: 'filterForProvider',
               parameterType: 'java.lang.String',
               parameter: providerId
            }]
         };
         return dataService.getProperties(clusterId, ['filterForProvider'], params);
      }

      function failureConditionsVuiSignPost() {
         return {
            message: i18nService.getString('ClusterUi', 'ha.config.failures.responses.partial.filter.desc'),
            title: i18nService.getString('ClusterUi', 'ha.config.failures.responses.partial.filter.label')
         };
      }

      function failureConditionsClusterLevelVuiSignPost() {
         return {
            message: i18nService.getString('ClusterUi', 'ha.config.failures.responses.partial.failure.filters.clusterLevelVuiSignPost.desc'),
            title: i18nService.getString('ClusterUi', 'ha.config.failures.responses.partial.failure.filters.clusterLevelVuiSignPost.title')
         };
      }

      function getFailureConditionsHostsDatagridOptions() {
         return {
            selectionMode: vuiConstants.grid.selectionMode.MULTI,
            showCheckboxesOnMultiSelection: true,
            sortMode: vuiConstants.grid.sortMode.NONE,
            searchable: false,
            resizable: true,
            selectedItems: [],
            data: [],
            columnDefs: getFailureConditionsHostColumnDefs()
         };
      }

      function getFilterModelSpec(blockAtValue, filterModel, selectedComponents, selectedHosts) {
         var isClusterLevelSet =
               blockAtValue && blockAtValue === clusterConstants.proactiveHaBlockAtTypes.CLUSTER_LEVEL;

         var filterHostInfo;
         if (isClusterLevelSet) {
            filterHostInfo = filterModel.filterHostInfo;
         } else if (blockAtValue === clusterConstants.proactiveHaBlockAtTypes.INDIVIDUAL_HOSTS) {
            filterHostInfo =
                  selectedHosts;
         } else {
            filterHostInfo = [];
         }

         // Remove the unchecked failure conditions
         var filterHealthUpdateInfo = selectedComponents;
         filterHealthUpdateInfo = _.filter(filterHealthUpdateInfo, function(item) {
            return item.isFilterOn;
         });

         return {
            name: filterModel.name,
            id: filterModel.id,
            providerId: filterModel.providerId,
            providerName: filterModel.providerName,
            isClusterLevelSet: isClusterLevelSet,
            filterHealthUpdateInfo: filterHealthUpdateInfo,
            filterHostInfo: filterHostInfo
         };
      }

      function getBlockAtValue(filterHealthUpdateInfo, filterHostInfo) {
         var atLeastOneComponentIsSelected =
               _.some(filterHealthUpdateInfo, function(item) {
                  return item.isFilterOn;
               });

         var atLeastOneHostIsFiltered = _.some(filterHostInfo, function(item) {
            return item.isFilterOn;
         });

         var blockAtValue;
         if (atLeastOneComponentIsSelected) {
            blockAtValue = atLeastOneHostIsFiltered ?
                  clusterConstants.proactiveHaBlockAtTypes.INDIVIDUAL_HOSTS :
                  clusterConstants.proactiveHaBlockAtTypes.CLUSTER_LEVEL;
         }

         return blockAtValue;
      }


      function getFailureConditionsHostColumnDefs() {
         return [
            {
               displayName: i18nService.getString(
                     'ClusterUi',
                     'ha.config.failures.responses.partial.failure.filters.grid.hosts'
               ),
               template: function(data) {
                  return data && data.name ? getIconRenderer(data) : null;
               }
            }
         ];
      }

      function getIconRenderer(data) {
         var renderer = columnRenderersRegistry.getColumnRenderer('icon-text');
         return renderer(['icon', 'text'], {
            icon: 'vsphere-icon-cluster',
            text: data.name
         });
      }
   }
})();
