/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace dvs_ui {

   export class ExportDvsPageModel {

      public selectedConfigurationToExport: string;

      public description: string;

      public type: string;
   }
}
