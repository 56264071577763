/** Copyright 2016 VMware, Inc.  All rights reserved. -- VMware Confidential*/

(function () {
   'use strict';

   angular.module('com.vmware.vsphere.client.cluster')
         .service('ftPortletService', ftPortletService);

   ftPortletService.$inject = ['dataService'];

   function ftPortletService(dataService) {
      return {
         requestData: requestData
      };

      function requestData(vmId) {
         return dataService.getProperties(vmId, ['ftportlet:ftdata']);
      }
   }
})();
