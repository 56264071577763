module cluster_ui {

   import ManagedObjectReference = com.vmware.vim.binding.vmodl.ManagedObjectReference;
   import IPromise = angular.IPromise;

   export class RestoreResourcePoolTreeService {

      public static $inject = ["mutationService", "i18nService", "resourceTypesConstants",
      "$q"];

      constructor(public mutationService: any, public i18nService: any,
      public resourceTypesConstants: any, public $q: any) {}

      public validateSnapshotFile(objectId: any, byteArray: any): any {
         return this.mutationService.validate(
               objectId,
               "com.vmware.vsphere.client.cluster.rp.RestoreRpTreeSpec",
               { rpTreeSnapshot: byteArray }
         );
      }

      public applyMutationOperation(objectId: string, byteArray: any): void {
         this.mutationService.apply(
               objectId,
               "com.vmware.vsphere.client.cluster.rp.RestoreRpTreeSpec",
               { rpTreeSnapshot: byteArray }
         );
      }

      public getValidationResultType(resultType: string): string {
         let validationMessage: string = this.i18nService.getString("ClusterUi",
               "restore.rp.tree.dialog.validation.error");
         switch(resultType) {
            case this.resourceTypesConstants.resultValidationType.DIFFERENT_CLUSTER_ERROR:
               return this.i18nService.getString("ClusterUi",
                  "restore.rp.tree.dialog.different.cluster.validation.error");
            case this.resourceTypesConstants.resultValidationType.DRS_DISABLED_ERROR:
               return this.i18nService.getString("ClusterUi",
                  "restore.rp.tree.dialog.drs.disabled.validation.error");
            case this.resourceTypesConstants.resultValidationType.HAS_CHILD_RP_ERROR:
               return this.i18nService.getString("ClusterUi",
                  "restore.rp.tree.dialog.rp.present.validation.error");
            case this.resourceTypesConstants.resultValidationType.DESERIALIZATION_ERROR:
               return this.i18nService.getString("ClusterUi",
                  "restore.rp.tree.dialog.snapshot.deserialization.error");
            case this.resourceTypesConstants.resultValidationType.VMS_WITH_MISSING_PRIVILEGES_ERROR:
               return this.i18nService.getString(
                  "ClusterUi", "restore.rp.tree.dialog.vm.missing.privileges.error");
            default: return validationMessage;
         }
      }

      public readFileContent(file: any): IPromise<string> {
         if (!file) {
            return this.$q.when(null);
         }

         let deferredResult = this.$q.defer();

         let fileReader = new FileReader();
         fileReader.onload = function () {
            let readerResult = fileReader.result;
            deferredResult.resolve(readerResult);
         };

         fileReader.addEventListener("error", function () {
            deferredResult.resolve(null);
         });

         fileReader.readAsArrayBuffer(file);
         return deferredResult.promise;
      }
   }

   angular.module("com.vmware.vsphere.client.cluster")
         .service("restoreResourcePoolTreeService", RestoreResourcePoolTreeService);
}
