module common_module_ui {

    export class VCServerSettingConstants {

        /*
         * Properties
         */
        public static readonly PROP_SERVER_SETTINGS: string = "vcServerSettings";
        public static readonly PROP_DATABASE_STATISTICS: string = "estimatedDastabaseSize";

        /*
         * Keys for settings in array
         */
        public static readonly KEY_DATABASE: string = "databaseSettings";
        public static readonly KEY_RUNTIME: string = "runtimeSettings";
        public static readonly KEY_USER_DIRECTORY: string = "activeDirectorySettings";
        public static readonly KEY_MAIL: string = "mailSettings";
        public static readonly KEY_SNMP_RECEIVER: string = "snmpSettings";
        public static readonly KEY_PORTS: string = "portsSettings";
        public static readonly KEY_TIMEOUT: string = "timeoutSettings";
        public static readonly KEY_LOGGING: string = "logSettings";
        public static readonly KEY_SSL: string = "sslSettings";

        /*
         * Default inventory details
         */
        public static readonly DEFAULT_HOST_COUNT: number = 50;
        public static readonly DEFAULT_VM_COUNT: number = 2000;

        private constructor() { }
    }

}
