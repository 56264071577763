(function() {
   'use strict';
   angular.module('com.vmware.vsphere.client.commonModule')
      .factory('AssignTagGridFactory', AssignTagGridFactory);

   AssignTagGridFactory.$inject = ['$rootScope', 'tagService', 'i18nService', 'vuiConstants'];

   function AssignTagGridFactory($rootScope, tagService, i18nService, vuiConstants) {

      function TagGrid(objectId) {
         var self = this;
         self.objectId = objectId;

         self.datagridOptions = {
            actionBarOptions: {
               actions: [{
                  id: 'addTag',
                  enabled: true,
                  label: i18nService.getString('CommonModuleUi', 'add.tag'),
                  iconClass: 'vx-icon-tag'
               }]
            },
            columnDefs: [
               {
                  displayName: i18nService.getString('Common', 'tag.dashboard.assignedNameColumn'),
                  field: 'name',
                  sortable: true,
                  searchable: false
               },
               {
                  displayName: i18nService.getString('Common', 'tag.dashboard.categoryColumn'),
                  field: 'categoryName',
                  sortable: false,
                  searchable: false
               },
               {
                  displayName: i18nService.getString('Common', 'tag.dashboard.descColumn'),
                  field: 'description',
                  sortable: false,
                  searchable: false
               }
            ],
            height: '200px',
            resizable: false,
            reorderable: true,
            selectionMode: vuiConstants.grid.selectionMode.SINGLE,
            selectedItems: []
         };
      }

      TagGrid.prototype.populateDataGrid = function() {
         var self = this;
         tagService.getAssignableTagsForObject(self.objectId).then(function(tagData) {
            self.datagridOptions.data = tagData.assignableTagsForTarget || [];
         });
      };

      TagGrid.prototype.assignTag = function() {
         var self = this;
         tagService.attachTag(self.datagridOptions.selectedItems[0], self.objectId).then(function() {
            $rootScope.$broadcast('tagUpdate', self.objectId);
         });
      };

      TagGrid.prototype.setTagSelectionListener = function(tagSelectionListener) {
         var self = this;
         $rootScope.$watch(
            function() {
               return self.datagridOptions.selectedItems;
            },
            function(selectedItems) {
               var selectedItem = (selectedItems && selectedItems.length) ? selectedItems[0] : null;
               tagSelectionListener.call(this, selectedItem);
            }
         );
      };

      function make(objectId) {
         return new TagGrid(objectId);
      }

      return {
         make: make
      };
   }
}());