namespace h5_vm {

   import VirtualMachine$PowerState = com.vmware.vim.binding.vim.VirtualMachine$PowerState;
   import IQService = angular.IQService;
   import IPromise = angular.IPromise;
   declare const angular: ng.IAngularStatic;
   class SpbmData {assignments:any; profiles:any;}


   class EcnryptionOptionsController implements ng.IComponentController {

      readonly ENCRYPTED_VMOTION_MODE_DISABLED: string = "disabled";
      readonly ENCRYPTED_VMOTION_MODE_OPPORTUNISTIC: string = "opportunistic";
      readonly ENCRYPTED_VMOTION_MODE_REQUIRED: string = "required";

      static $inject: string[] = [
         "$scope",
         '$q',
         "i18nService",
         "storageProfileService",
         "vmCryptUtilService",
         "defaultUriSchemeUtil",
      ];

      workflowMode: VmWorkflowMode;
      vmStorageProfileAssignments: any;
      storageProfiles: any;
      vmStorageProfile: any;
      hardDisks: any[];
      vmConfigContext: any;
      datacenterId: string;
      onAlert: Function;
      doesTpmExist: boolean;
      expanded: boolean;

      private i18n: Function;
      canChangeVmHomePolicy: boolean;
      canEncryptHdds: boolean;
      canNotEncryptReason?: string;
      encryptionProfiles: any;
      selectedEncryptionProfile: any;
      hardDiskEncryptionStatuses: boolean[];
      encryptedVmotionModes: string[];
      migrateEncryption: string;
      private hasVmConfigSettingsPriviledge: boolean = false;
      private hasStorageProfileViewPermission: boolean = false;
      private hasCryptographerManageKeyServersPermission: boolean = false;
      private isKmipStatusChecked:boolean = false;
      hostSupportsEncryption: boolean = false;

      constructor(
            private $scope: any,
            private $q: IQService,
            private i18nService: any,
            private storageProfileService: any,
            private vmCryptUtilService: VmCryptUtilService,
            private defaultUriSchemeUtil: any) {
         this.i18n = (key: string, bundle: string ="VmUi") => i18nService.getString(bundle, key);
      }

      $onInit() {
         this.waitSpbmData().then(() => {
            this.initEncryptionProfiles();
            this.initialize();
         });

         this.$scope.$on('vmHomeStorageProfileChanged', ($event:any, newProfile:any) => {
            this.vmStorageProfile = newProfile;
            this.initEncryptionProfiles();
         });
      }

      private initialize() {

         this.selectedEncryptionProfile = this.vmStorageProfile;

         this.encryptedVmotionModes = [this.ENCRYPTED_VMOTION_MODE_DISABLED,
            this.ENCRYPTED_VMOTION_MODE_OPPORTUNISTIC,
            this.ENCRYPTED_VMOTION_MODE_REQUIRED];

         this.migrateEncryption = this.ENCRYPTED_VMOTION_MODE_OPPORTUNISTIC;
         if (!!this.vmConfigContext.config && !!this.vmConfigContext.config.migrateEncryption) {
            this.migrateEncryption = this.vmConfigContext.config.migrateEncryption;
         }

         if (this.isVmHomeEncrypted() || this.doesTpmExist) {
            this.migrateEncryption = this.ENCRYPTED_VMOTION_MODE_REQUIRED;
         }
         this.onMigrateEncryptionChanged();

         this.hasVmConfigSettingsPriviledge =
               h5_vm.VmHardwareUtil.userHasVmSettingsPermission(this.vmConfigContext);
         this.hasStorageProfileViewPermission =
               h5_vm.VmHardwareUtil.userHasStorageProfileViewPermission(this.vmConfigContext);
         this.hasCryptographerManageKeyServersPermission =
               h5_vm.VmHardwareUtil.userHasCryptographerManageKeyServersPermission(this.vmConfigContext);

         this.hostSupportsEncryption = this.vmConfigContext.environment.hostCapability.cryptoSupported;

         this.canChangeVmHomePolicy = this.workflowMode === VmWorkflowMode.UpdateMode;
         if (this.expanded) {
            this.checkKmipStatusIfNeeded();
         }
      }

      public expandedChanged(ev:any) {
         this.checkKmipStatusIfNeeded();
      }

      private checkKmipStatusIfNeeded() {
         if(this.isKmipStatusChecked) {
            return;
         }
         this.isKmipStatusChecked = true;
         this.checkKmipStatus();
      }

      private checkKmipStatus() {

         if (!this.hasCryptographerManageKeyServersPermission) {
            this.canEncryptHdds = true;
         } else {
            this.canEncryptHdds = false;
            if (this.hostSupportsEncryption) {
               let serverGuid = this.workflowMode === VmWorkflowMode.UpdateMode
                     ? this.vmConfigContext.rtInfo.host.serverGuid
                     : this.defaultUriSchemeUtil.getPartsFromVsphereObjectId(this.datacenterId).serverGuid;
               this.vmCryptUtilService.getDefaultKmipServerStatus(serverGuid, true).then((response: any) => {
                  // If already disabled, leave it this way
                  if (this.canNotEncryptReason) {
                     return;
                  }

                  // When the user doesn't have permission to aggregate the KMS statuses,
                  // don't stop him because he will still be able to create encrypted VM
                  // if the KMS servers are OK.
                  if (response && response.h5DefaultKmipClusterStatus
                        && response.h5DefaultKmipClusterStatus.noPermission) {
                     this.canEncryptHdds = true;
                     return;
                  }

                  var isConnectionNormal = response && response.h5DefaultKmipClusterStatus &&
                        response.h5DefaultKmipClusterStatus.connectionStatus === h5_vm.ConnectionStatus.CONNECTED;
                  var isCertificateExpired = response && response.h5DefaultKmipClusterStatus &&
                        response.h5DefaultKmipClusterStatus.certificateStatus === h5_vm.CertStatus.EXPIRED;

                  const isVmHomeEncrypted: boolean = this.isVmHomeEncrypted();
                  this.canEncryptHdds = isVmHomeEncrypted || (isConnectionNormal && !isCertificateExpired);
                  if (!isVmHomeEncrypted) {
                     if (!isConnectionNormal) {
                        this.canNotEncryptReason =
                              this.i18nService.getString("VmUi", "VmCryptConfig.reason.noKms");
                     } else if (isCertificateExpired) {
                        this.canNotEncryptReason =
                              this.i18nService.getString("VmUi", "VmCryptConfig.reason.kmsCertificateRequiresUpdate");
                     }
                  }
               }, () => {
                  // if the KMS status can not be retrieved allow the user to continue
                  this.canEncryptHdds = true;
               });
            }
         }
      }

      $onChanges(changes: any) {
         if (changes.vmStorageProfile) {

         }
         if (changes.doesTpmExist) {
            // When Trust Platform Module is present, the encrypted VMotion
            // is set to `Required` and should not be passed as part of the config spec.
            if (this.doesTpmExist) {
               this.migrateEncryption = this.ENCRYPTED_VMOTION_MODE_REQUIRED;
            }
            this.onMigrateEncryptionChanged();
         }

         if (changes.hardDisks) {
            this.refreshHddEncryptionStatuses();
         }
      }

      initEncryptionProfiles() {
         if (!this.storageProfiles || !this.vmStorageProfile) {
            return;
         }
         {
            this.encryptionProfiles = _.filter(this.storageProfiles, (profile: any) => {
               return this.storageProfileService
                     .isEncryptionStorageProfile(profile);
            });

            if (_.isEmpty(this.encryptionProfiles)) {
               this.canEncryptHdds = false;
               this.canNotEncryptReason =
                     this.i18nService.getString("VmUi", "VmCryptConfig.reason.noEncryptionPolicies");
            }

            let noEncryptionProfile: any;

            if (this.storageProfileService
                        .isEncryptionStorageProfile(this.vmStorageProfile)) {
               // VM home is encrypted
               noEncryptionProfile = this.storageProfileService.getDefaultProfile();
               this.selectedEncryptionProfile = this.vmStorageProfile;
               this.migrateEncryption = this.ENCRYPTED_VMOTION_MODE_REQUIRED;
               this.onMigrateEncryptionChanged();
            } else {
               // VM home is not encrypted
               noEncryptionProfile = angular.copy(this.vmStorageProfile);
               this.selectedEncryptionProfile = noEncryptionProfile;
            }
            noEncryptionProfile.label =
                  this.i18nService.getString("VmUi", "VmCryptConfig.encryptVM.profileNone");

            this.encryptionProfiles.splice(0, 0, noEncryptionProfile);
         }
      }

      onStorageProfileChanged(storageProfile: any) {
         const previousEncryptionProfile = this.selectedEncryptionProfile;
         this.selectedEncryptionProfile = storageProfile;

         // Change the profile for the VM
         this.vmConfigContext.vmProfile =
               [this.storageProfileService.makeProfile(storageProfile.id)];

         if (this.isVmHomeEncrypted()) {
            this.migrateEncryption = this.ENCRYPTED_VMOTION_MODE_REQUIRED;
            this.onMigrateEncryptionChanged();

            // Set the encryption policy to all disks that are:
            // - not encrypted
            // - encrypted and storage policy is not set manually
            _.each(this.hardDisks, (disk) => {
               const isDiskEncrypted: boolean = disk.isDiskEncrypted();
               const canChangeDiskProfile = !disk.isStorageProfileManuallySet()
                     && disk.getProfile()
                     && disk.getProfile().profileId === previousEncryptionProfile.id;
               if (!isDiskEncrypted || canChangeDiskProfile) {
                  disk.updateDiskProfile(this.selectedEncryptionProfile);
               }
            });

            // Hide any warnings
            this.onAlert({
               options: {
                  isVisible: false
               }
            });
         } else {
            this.vmConfigContext.config.keyId = null;

            // Decrypt all disks that are:
            // - not created from existing disks
            // - encrypted
            _.each(this.hardDisks, (disk) => {
               if (!disk.isCreatedFromExistingDisk()
                     && disk.isDiskEncrypted()) {
                  disk.updateDiskProfile(this.storageProfileService.getDefaultProfile());
               }
            });

            // Show warning that VM home and all disks will be unencrypted
            this.onAlert({
               options: {
                  isVisible: true,
                  class: "alert-warning",
                  iconShape: "exclamation-triangle",
                  text: this.i18nService.getString("VmUi", "VmCryptConfig.alert.vmHomeNotEncypted")
               }
            });
         }

         this.refreshHddEncryptionStatuses();
      }

      toggleEncryptHardDisk(disk: any) {
         if (disk.isCreatedFromExistingDisk()) {
            return;
         }

         let nonEncryptionProfileLabel =
               this.storageProfileService.getDefaultProfile().label;

         if (disk.isDiskEncrypted()) {
            // Decrypt
            disk.updateDiskProfile(this.storageProfileService.getDefaultProfile());
            disk.getCurrentDevice().backing.keyId = null;
         } else {
            // Encrypt
            disk.updateDiskProfile(this.selectedEncryptionProfile);
         }

         this.refreshHddEncryptionStatuses();

         if (this.hasUnencryptedDisks()) {
            this.onAlert({
               options: {
                  isVisible: true,
                  class: "alert-warning",
                  iconShape: "exclamation-triangle",
                  text: this.i18nService.getString("VmUi", "VmCryptConfig.alert.someDisksAreNotEncypted",
                        nonEncryptionProfileLabel)
               }
            });
         } else {
            this.onAlert({
               options: {
                  isVisible: true,
                  class: "alert-success",
                  iconShape: "check-circle",
                  text: this.i18n("VmCryptConfig.alert.allDisksAreEncypted")
               }
            });
         }
      }

      onMigrateEncryptionChanged() {
         // When VM home is encrypted, or Trust Platform Module is present,
         // the encrypted VMotion should not be passed as part of the re-config spec.
         if (this.isVmHomeEncrypted() || this.doesTpmExist) {
            delete this.vmConfigContext.config.migrateEncryption;
            return;
         }

         if(this.migrateEncryption) {
            this.vmConfigContext.config.migrateEncryption = this.migrateEncryption;
         }
      }

      isVmotionReadOnly(): boolean {
         if (this.doesTpmExist) {
            return true;
         }

         if (this.workflowMode === VmWorkflowMode.UpdateMode
               && !this.hasVmConfigSettingsPriviledge) {
            return true;
         }

         return this.isVmHomeEncrypted();
      }

      isVmHomeEncrypted(): boolean {
         return this.storageProfileService
               .isEncryptionStorageProfile(this.selectedEncryptionProfile);
      }

      isVmPoweredOn(): boolean {
         return this.vmConfigContext.rtInfo &&
               this.vmConfigContext.rtInfo.powerState === ("poweredOn" as VirtualMachine$PowerState);
      }

      displayEncryptVmBlock(): boolean {
         return this.hostSupportsEncryption &&
               this.hasStorageProfileViewPermission &&
               this.workflowMode !== VmWorkflowMode.CloneMode;
      }

      displayEncryptVmDisksBlock(): boolean {
         return this.displayEncryptVmBlock() &&
               this.isVmHomeEncrypted() &&
               !_.isEmpty(this.hardDisks);
      }

      buildSignPostContentVmotion(): any {

         const titlesAndTips = [{
            title: this.i18nService.getString("VmUi", "VmCryptConfig.vMotion.vtpm.Text"),
            tip: this.i18nService.getString("VmUi", "VmCryptConfig.vMotion.vtpm.Tip")
         }, {
            title: this.i18nService.getString("VmUi", "VmCryptConfig.vMotion.disabled.Text"),
            tip: this.i18nService.getString("VmUi", "VmCryptConfig.vMotion.disabled.Tip")
         }, {
            title: this.i18nService.getString("VmUi", "VmCryptConfig.vMotion.opportunistic.Text"),
            tip: this.i18nService.getString("VmUi", "VmCryptConfig.vMotion.opportunistic.Tip")
         }, {
            title: this.i18nService.getString("VmUi", "VmCryptConfig.vMotion.required.Text"),
            tip: this.i18nService.getString("VmUi", "VmCryptConfig.vMotion.required.Tip")
         }];
         let elements: string [] = [];

         _.each(titlesAndTips, (titleAndTip: any, index: number) => {
            if (index !== 0 || this.doesTpmExist) {
               elements.push(`<li>
                  <div class="title">${titleAndTip.title}</div>
                  <div class="tip">${titleAndTip.tip}</div>
               </li>`);
            }
         });

         return "<ul>" + elements.join(" ") + "</ul>";
      }

      private refreshHddEncryptionStatuses() {
         this.hardDiskEncryptionStatuses = _.map(this.hardDisks, (disk: any) => {
            disk.diskEncryptionStatus = undefined;
            return disk.isDiskEncrypted();
         });
      }

      private hasUnencryptedDisks(): boolean {
         return _.contains(this.hardDiskEncryptionStatuses, false);
      }

      private waitSpbmData(): IPromise<any> {
         const done: IPromise<any> = this.$q.when();

         if (this.workflowMode !== VmWorkflowMode.UpdateMode) {
            // no waiting for new and clone, only for edit
            return done;
         }

         const spbm: SpbmData = {
            assignments: <any> this.vmStorageProfileAssignments,
            profiles: this.storageProfiles
         };

         return this.$q.all(<any>spbm).then((data: any) => {
            const spbm: SpbmData = data;
            this.applySpbmData(spbm);
            return done;
         });
      }

      private applySpbmData(spbm: SpbmData): void {
         this.vmStorageProfileAssignments = spbm.assignments.vmStorageProfileAssignments;
         this.storageProfiles = spbm.profiles;
         this.initVmHomeProfile();
      }

      private initVmHomeProfile(): void {
         if (!this.vmStorageProfileAssignments) {
            this.vmStorageProfile = null;
            return;
         }

         this.vmStorageProfile = this.storageProfileService.getDefaultProfile();

         const homeProfile: any = this.vmStorageProfileAssignments.homeStorageProfile;

         if (!homeProfile || !homeProfile.profileId) {
            // keep default profile
            return;
         }

         const uniqueId: string = homeProfile.profileId.uniqueId;

         let profile = _.find(this.storageProfiles, function (item: any) {
            return (item.id === uniqueId);
         });

         if (!profile) {
            // keep default profile
            return;
         }
         this.vmStorageProfile = profile;
      }

   }

   class EncryptionOptions implements ng.IComponentOptions {
      bindings:    any;
      controller:  any;
      templateUrl: string;

      constructor() {
         this.bindings = {
            workflowMode: "<",
            vmStorageProfileAssignments:  "<",
            storageProfiles:  "<",
            vmStorageProfile: "<",
            hardDisks: "<",
            vmConfigContext: "<",
            datacenterId: "<",
            onAlert: "&",
            doesTpmExist: "<",
            expanded: "="
         };
         this.controller = EcnryptionOptionsController;
         this.templateUrl = "vm-ui/resources/vm/views/settings/vmOptions/encryption/encryption-options.html";
      }
   }

   angular.module("com.vmware.vsphere.client.vm")
         .component("encryptionOptions", new EncryptionOptions());
}
