/* Copyright 2018 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace dvs_ui {

   export class PnicDetailsDialogCdpViewComponent {

      public bindings: any;
      public controller: any;
      public template: string;

      constructor() {
         this.bindings = {
            data: "<"
         };

         this.controller = PnicDetailsDialogCdpViewComponentController;

         this.template = `<vx-property-view class="network-ui-fixed-size-vx-property-view"
                                            data="$ctrl.propertyViewData"/>`;
      }
   }

   class PnicDetailsDialogCdpViewComponentController {

      static $inject = [
         "physicalAdapterDetailsViewService"
      ];

      data: any;

      propertyViewData: any;

      constructor(private physicalAdapterDetailsViewService: any) {}

      $onInit(): void {
         this.propertyViewData =
               this.physicalAdapterDetailsViewService.buildOnlyCdp(this.data);
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .component("pnicDetailsDialogCdpView",
               new PnicDetailsDialogCdpViewComponent ());
}
