namespace h5_vm {

   import VmDisplayMode = h5_vm.VmDisplayMode;
   import VmSharingInfo = h5_vm.VmSharingInfo;

   class VmHardwareVirtualDiskSharingCtrl implements ng.IComponentController {
      static $inject: string[] = [
         'i18nService'
      ];

      onChange: Function;
      displayMode: VmDisplayMode;
      sharingInfo: VmSharingInfo;

      private i18n: Function;
      private selected: any;
      private sharingOptions: Array<any>;
      private isSelectable: boolean;
      private isReadOnly: boolean;

      constructor(private i18nService: any) {
         this.i18n = i18nService.getString;
      }

      $onInit() {
         if(this.sharingInfo){
            this.setSharingContext();
         }
      }

      $onChanges(changes: any) {
         if (changes.sharingInfo.currentValue) {
            this.setSharingContext();
         }
      }

      setSharingContext() {
         this.selected = this.sharingInfo.selectedSharing;
         this.sharingOptions = this.sharingInfo.sharingOptions;
         this.isSelectable = this.sharingInfo.enableSharing && (this.sharingOptions && this.sharingOptions.length > 0);
         this.isReadOnly = (this.displayMode === VmDisplayMode.ReadMode ||
                           this.displayMode === VmDisplayMode.ReadAdvancedMode);
      }

      onSharingChange = () => {
         this.onChange({sharing: this.selected});
      }
   }

   export class VmHardwareVirtualDiskSharing implements ng.IComponentOptions {

      bindings: any;
      controller: any;
      templateUrl: string;

      constructor() {
         this.bindings = {
            onChange: '&',
            displayMode: '<',
            ariaLabel: '<',
            sharingInfo: '<'
         };
         this.controller = VmHardwareVirtualDiskSharingCtrl;
         this.templateUrl = 'vm-ui/resources/vm/views/components'+
            '/vmHardwareVirtualDiskSharing/vm-hardware-virtual-disk-sharing.html';
      }
   }

   angular.module('com.vmware.vsphere.client.vm')
      .component('vmHardwareVirtualDiskSharing', new VmHardwareVirtualDiskSharing());
}
