namespace h5_vm {

   import Privileges = h5_vm.Privileges;
   import VmWorkflowMode = h5_vm.VmWorkflowMode;
   import DeviceTypeConstants = h5_vm.DeviceTypeConstants;
   import PciDevice = com.vmware.vim.binding.vim.host.PciDevice;
   import SizeUnits = h5_vm.SizeUnits;
   import DiskSize = h5_vm.DiskSize;
   import VirtualDeviceSpec = com.vmware.vim.binding.vim.vm.device.VirtualDeviceSpec;
   import VirtualDeviceSpec$Operation = com.vmware.vim.binding.vim.vm.device.VirtualDeviceSpec$Operation;
   import IPromise = angular.IPromise;

   class VmHardwareController {
      public static $inject = [
         "$scope",
         "$document",
         "creationTypeConstants",
         "cpuService",
         "guestOsService",
         "vmDeviceInfoService",
         "vmHardwareUtil",
         "vmHardwareEditService",
         "vmDeviceManagementService",
         "i18nService",
         "defaultUriSchemeUtil",
         "vmEditSettingsDatastoreRecommendationService",
         "storageProfileService",
         "dataService",
         "$q",
         "vmHardwareDeviceMenuService",
         "nvdimmProvisioningService",
         "vmCryptUtilService",
         "tpmProvisioningService",
         "virtualDiskSettingsFormService",
         "basicDiskUtilService"
      ];

      private readonly PCI_CONTROLLER_TYPE: string = "com.vmware.vim.binding.vim.vm.device.VirtualPCIController";
      private readonly NVME_CONTROLLER_TYPE: string = "com.vmware.vim.binding.vim.vm.device.VirtualNVMEController";
      private readonly VM_STORAGE_PROFILE_ASSIGNMENTS_PROPERTY: string = "vmStorageProfileAssignments";
      private readonly VM_REPLICATION_GROUP_ASSIGNMENTS_PROPERTY: string = "vmReplicationGroupAssignments";
      private readonly VM_HOST_PROPERTY: string = "host";
      private readonly TPM_VIEW_ID: string = "TPM_VIEW_ID";
      private readonly ON_CHILD_CHANGE_EVENT: string = "onChildValidationChange";

      //this is the number of hard disks that will be displayed as solo elements
      //if total-count-of-hdd > 4 then embed them all in a static group called "Hard disks"
      private readonly MAX_SOLO_HARD_DISK_COUNT: number = 4;

      private maxPciDevicesControllers: number = 32;
      private maxEthernetCards: number = 10;
      private maxPCIPassthrough: number = 6;
      private isInvalid: boolean;
      private iconForDevice: any;
      private canAddDevice: boolean;
      private addDeviceActionDefs: any;
      private vmConfig: any;
      private virtualEthernetCard: any[];
      private pciEthernetCount: number = 0;
      private virtualControllers: any[];
      private changeDevices: any[];
      private virtualScsiControllers: any;
      private inputDevices: any;
      private errorConfig: any;
      private controllerOptions: any;
      private editMode: boolean;

      public getStorageForDisk: Function;
      public setStorageForDisk: Function;
      public removeDevice: Function;
      public getAdvancedConfigurationForDisk: Function;
      public addDevicesMenuConfig: any;
      public banner: any;
      public vmId: string;
      public createMode: boolean;
      public cloneMode: boolean;
      public deployVmtxMode: boolean;
      public originalConfig: any;
      public vmConfigContext: any;
      public stackBlocksExpandedState: any;
      public virtualMachineDevices: any;
      public vmStorageId: string;
      public vmAssignmentsProperties: any;
      public selectedStorageProfile: any;
      public selectedReplicationGroupInfo: any;
      public storageProfiles: any;
      public storageLocatorItemsData: any[];
      public isXVc: boolean;
      public vmWorkflowMode: VmWorkflowMode;
      public computeResourceId: string;
      public vmHomeReplicationGroup: any;
      public selectedGuestOs: any;
      public networkProviderId: string;
      public availableNetworks: any[];
      public filterNetworksObjId: string;
      public nvmeDeviceOption: any;
      public vmVersion: string;
      public storageBaselineId: string;
      public selectedDiskFormat: any;
      public isVmPoweredOn: boolean;
      public keepExistingProfileAssignments: boolean = false;

      public totalHardDiskSizeText: string = '';
      private hardDiskStackBlockClicked: boolean = false;
      private isGroupingHardDisks: boolean = false;


      constructor(private $scope: any,
            private $document: any,
            private creationTypeConstants: any,
            private cpuService: any,
            private guestOsService: any,
            private vmDeviceInfoService: any,
            private vmHardwareUtil: any,
            private vmHardwareEditService: any,
            private vmDeviceManagementService: any,
            private i18nService: any,
            private defaultUriSchemeUtil: any,
            private vmEditSettingsDatastoreRecommendationService: any,
            private storageProfileService: any,
            private dataService: any,
            private $q: any,
            private vmHardwareDeviceMenuService: any,
            private nvdimmProvisioningService: any,
            private vmCryptUtilService: VmCryptUtilService,
            private tpmProvisioningService: any,
            private virtualDiskSettingsFormService: any,
            private basicDiskUtilService: any) {
         this.removeDevice = (virtualDevice: any) => {
            this.removeDeviceInternal(virtualDevice);
         };

         this.$scope.config = this.$scope.config || {};
         this.$scope.config.loading = true;
         this.isInvalid = false;
         this.iconForDevice = this.vmDeviceInfoService.iconForDevice;
         this.storageLocatorItemsData = this.$scope.storageLocatorItemsData;
         this.isXVc = this.$scope.vmParams && this.$scope.vmParams.isXvc();
         this.canAddDevice = true;
         this.banner = {};
         this.deployVmtxMode = false;

         this.stackBlocksExpandedState = this.$scope.customizeHardwarePageModel
               ? this.$scope.customizeHardwarePageModel.getStackBlocksExpandedState()
               : {virtualDevices: []};
         let checkForErrors: any;
         if (this.$scope.modalOptions) {
            this.setupForEditMode();
            checkForErrors = (isInvalid: any) => {
               this.notifyValidationChange(!isInvalid);
               this.isInvalid = isInvalid;
            };
         } else if ($scope.vmParams.getCreationType() === creationTypeConstants.CLONE_VM_TO_VM
               || $scope.vmParams.getCreationType() === creationTypeConstants.CLONE_TEMPLATE_TO_VM
               || this.$scope.vmParams.getCreationType() === this.creationTypeConstants.DEPLOY_VM_FROM_VMTX) {

            this.deployVmtxMode = this.$scope.vmParams.getCreationType() === this.creationTypeConstants.DEPLOY_VM_FROM_VMTX;
            this.setupForCloneMode();
            checkForErrors = (isInvalid: any) => {
               this.notifyValidationChange(!isInvalid);
               $scope.customizeHardwarePageModel.setFormValidity(!isInvalid);
            };
         } else {
            this.setupForCreate();
            checkForErrors = (isInvalid: any) => {
               this.notifyValidationChange(!isInvalid);
               $scope.customizeHardwarePageModel.setFormValidity(!isInvalid);
            };
         }
         $scope.$watch("hardwareForm.$invalid", checkForErrors, true);

         this.addDevicesMenuConfig = this.vmHardwareDeviceMenuService.getAddDevicesMenuConfig(
               (event: any, clickedItem: any) => {
                  this.addDevice(event, clickedItem);
               });

         this.createMode = this.vmWorkflowMode === VmWorkflowMode.CreateMode;
         this.cloneMode = this.vmWorkflowMode === VmWorkflowMode.CloneMode;

         this.pciEthernetCount = 0;

         if (this.virtualEthernetCard) {
            this.pciEthernetCount = this.virtualEthernetCard.length;
         }

         const globalClickHandler: (e: any) => void = (e: any): void => {
            this.hideAddDevicesMenu(e);
         };

         this.$document.on("click", globalClickHandler);

         this.$scope.$on("$destroy", () => {
            this.$document.off("click", globalClickHandler);
         });

         this.removeDevice = (virtualDevice: any) => {
            this.removeDeviceInternal(virtualDevice);
         };

      }

      private updateActionDefsForAddDeviceMenu(keysToRemove?: Array<string>) {
         keysToRemove = keysToRemove ? keysToRemove : [];
         // hide the option only in edit mode
         if (this.vmWorkflowMode === VmWorkflowMode.UpdateMode && !this.showNewNvidmmMenu()) {
            keysToRemove.push("addNewNvdimmAction");
         }
         this.addDeviceActionDefs = this.vmHardwareDeviceMenuService.getAddDeviceActions(
               this.vmConfigContext.privileges, this.vmWorkflowMode, keysToRemove);
      };

      private removeDeviceInternal(virtualDevice: any) {
         let deviceKey: number = virtualDevice.getKey();
         this.virtualMachineDevices.removeDeviceByKey(deviceKey);
         this.$scope.$broadcast("deviceRemoved", {
            deviceKey: virtualDevice.getKey(),
            deviceType: virtualDevice.getCurrentDevice()._type
         });

         if (this.vmDeviceInfoService.isDeviceSubclassOf(virtualDevice.getCurrentDevice(), 'VirtualTPM')) {
            this.shouldAllowAddTpmAction().then((result: boolean) => {
               if (result) {
                  this.updateActionDefsForAddDeviceMenu();
               } else {
                  this.updateActionDefsForAddDeviceMenu(['addNewTpmAction']);
               }
            });
            this.$scope.$emit(this.ON_CHILD_CHANGE_EVENT, this.TPM_VIEW_ID, true);
         }

         if (!_.isUndefined(this.stackBlocksExpandedState.virtualDevices[deviceKey])) {
            delete this.stackBlocksExpandedState.virtualDevices[deviceKey];
         }
      }

      public getNewDevices(deviceType: any): any {
         return this.virtualMachineDevices.newDevicesOfType(deviceType);
      }

      public isThereNewSATA(): any {
         return this.virtualMachineDevices.newDevicesOfType(
                     DeviceTypeConstants.VIRTUAL_SATA_CONTROLLER).length > 0;
      }

      public toggleAddDevicesMenu() {
         this.addDevicesMenuConfig.showMenu = !this.addDevicesMenuConfig.showMenu;
         if (this.addDevicesMenuConfig.showMenu) {
            this.addDevicesMenuConfig.menuItems = this.vmHardwareDeviceMenuService.getAddDeviceMenuItems(this.addDeviceActionDefs);
         }
      }

      public getUSBControllerList(): any[] {
         let retVal: any[] = [];

         const usb2: any[] = this.virtualMachineDevices.existingDevicesOfType(
               DeviceTypeConstants.VIRTUAL_USB_CONTROLLER);
         const usb3: any[] = this.virtualMachineDevices.existingDevicesOfType(
               DeviceTypeConstants.VIRTUAL_USB_XHCI_CONRTOLLER);

         const usb2new: any[] = this.virtualMachineDevices.newDevicesOfType(
               DeviceTypeConstants.VIRTUAL_USB_CONTROLLER);
         const usb3new: any[] = this.virtualMachineDevices.newDevicesOfType(
               DeviceTypeConstants.VIRTUAL_USB_XHCI_CONRTOLLER);

         retVal = retVal.concat(usb2).concat(usb3).concat(usb2new).concat(usb3new);
         return retVal;
      }

      private getTpmList(): any[] {
         let list: any[] = this.getExistingAndNewDevices("VirtualTPM");
         return list;
      }
      public getExistingAndNewDevices(shortType: string): any[] {
         let retVal = [];
         let oldList = this.virtualMachineDevices.existingDevicesOfType(shortType);
         let newList = this.virtualMachineDevices.newDevicesOfType(shortType);
         retVal = retVal.concat(oldList).concat(newList);
         return retVal;
      }

      public getUSBDeviceList(): any[] {
         let retVal: any[] = this.getExistingAndNewDevices(DeviceTypeConstants.VIRTUAL_USB);
         return retVal;
      }

      public getInflatedVideoCard(): VirtualVideoCardClass | null {

         let list: Array<any> = this.getExistingAndNewDevices(DeviceTypeConstants.VIDEO_CARD);

         if (!list || list.length < 1) {
            return null;
         }

         let videoCard: VirtualVideoCardClass = list[0];
         videoCard.attachVmConfig(this.vmConfig);
         return videoCard;
      }

      public getPciSharedList(): any[] | null {
         return this.getPciDeviceList(true);
      }

      public getPciDeviceList(needShared: boolean = false): any[] | null {
         let list: Array<any> = this.getExistingAndNewDevices(DeviceTypeConstants.VirtualPCIPassthrough);
         let retVal: Array<any> = [];

         for (let item of list) {
            let device: VmHwPciDevice = item;
            const isShared: boolean = device.isSharedPCIBackingType();
            if (isShared !== needShared) {
               continue;
            }

            device.attachVmConfig(this.vmConfig);
            device.removeCallback = this.removeDevice;
            retVal.push(item);
         }
         return retVal;
      }


      isDiskGroupingRequired(): boolean {
         return this.isGroupingHardDisks;
      }

      // hard disks stack block is a stack-view introduced to put all hard-disks under
      // one stack-block element that reads "Hard disks". That nested stack-view is
      // using clarity stack-view's styling way of drawing a stack-view (as opposed to the
      // clr-stack-view: component way).
      // This function handles the click event on the new row/stack-block introduced called "Hard disks".
      hardDisksStackClicked(): void {
         this.hardDiskStackBlockClicked = !this.hardDiskStackBlockClicked;
      }

      // initially the "Hard disks" stack-view is not expanded. so that's the false state.
      // next click expands it - and allows all ng-repeat to fill in the hard disks.
      get hardDisksStackExpanded(): boolean {
         return this.hardDiskStackBlockClicked;
      }

      public isAddDeviceMenuEnabled(): boolean {
         if (this.isEditingSuspendedVM() || this.deployVmtxMode) {
            // NO adding devices/controllers when editing suspended VM
            return false;
            // Still OK when cloning suspended VM
         }

         if (this.addDevicesMenuConfig.menuItems.length === 0) {
            this.addDevicesMenuConfig.menuItems = this.vmHardwareDeviceMenuService.getAddDeviceMenuItems(this.addDeviceActionDefs);
         }

         return this.vmHardwareDeviceMenuService.isAddDeviceMenuEnabled(this.addDevicesMenuConfig.menuItems);
      }

      public isEditingSuspendedVM(): boolean {
         if (this.vmWorkflowMode !== VmWorkflowMode.UpdateMode) {
            return false;
         }
         return VmHardwareUtil.isSuspended(this.vmConfigContext);
      }

      // deliver change to parent
      public notifyValidationChange(isValid: any) {
         this.$scope.$emit("onChildValidationChange", this, isValid);
      }

      public isRDMDisk(virtualDisk: any): boolean {
         return virtualDisk.isRDMDisk();
      }

      public isNotRDMDisk(virtualDisk: any): boolean {
         return !virtualDisk.isRDMDisk();
      }

      private checkPciLimit() {
         if (this.pciEthernetCount >= this.maxEthernetCards) {
            this.showError(this.i18nService.getString("VmUi", "VmDeviceManager.DeviceLimitReached"));
            this.canAddDevice = false;
         } else {
            this.clearError();
            this.canAddDevice = true;
         }
      }

      private emitInfo(msg: any) {
         this.$scope.$emit("editSettingsAlertMessageChanged", msg);
      }

      private showInfo(txt:string) {
         const msg = {
               isVisible: true,
               class: "info-standard",
               iconShape: "info-standard",
               text: txt};
         this.emitInfo(msg);
      }

      private showError(message: string) {
         const bannerMessage: any = [{
            text: message,
            type: "error"
         }];
         if (this.$scope.config && this.$scope.config.validationBanner) {

            this.$scope.config.validationBanner.messages = bannerMessage;
         } else {
            this.banner.messages = bannerMessage;
         }
      }

      private clearError() {
         if (this.$scope.config && this.$scope.config.validationBanner) {
            this.$scope.config.validationBanner.messages = [];
         } else {
            this.banner.messages = [];
         }
      }

      private updatePciCounter() {
         this.virtualControllers = this.virtualMachineDevices.existingDevicesOfType(
               DeviceTypeConstants.VIRTUAL_CONTROLLER);
         this.changeDevices = this.virtualMachineDevices.getDeviceChanges();

         if (this.virtualEthernetCard) {
            this.pciEthernetCount = this.virtualEthernetCard.length;
         }

         this.changeDevices.forEach((spec: VirtualDeviceSpec) => {
            if (this.vmHardwareUtil.isEthernetDevice(spec)) {
               const op:VirtualDeviceSpec$Operation = spec.operation;
               if (op === "add") {
                  this.pciEthernetCount++;
               } else if (op === "remove") {
                  this.pciEthernetCount--;
               }
            }
         });
      }

      private showErrorByDeviceType(deviceType: String): boolean {
         if (deviceType === DeviceTypeConstants.VIRTUAL_USB) {
            return this.showErrorForUsb();
         }
         // check for other device types as needed
         return false;
      }

      private showErrorForUsb(): boolean {
         if (VmHardwareUsbControllerUtil.areBothUSBControllerMarkedForRemoval(this.virtualMachineDevices)) {
            this.showError(this.i18nService.getString("VmUi", "VmDeviceManager.BothUSBControllersMarkedForRemoval"));
            return true;
         }
         return false;
      }

      private addDevice(event: any, clickedItem: any) {
         this.clearError();

         let deviceType: any = this.addDeviceActionDefs[clickedItem.id].deviceType;
         let additionalParameters: any = {};
         if (deviceType === DeviceTypeConstants.VIRTUALDISK
               || deviceType === DeviceTypeConstants.RDMDISK
               || deviceType === DeviceTypeConstants.EXISTINGHARDDISK) {
            additionalParameters.selectedStorageProfile = this.selectedStorageProfile;
            additionalParameters.selectedReplicationGroupInfo = this.selectedReplicationGroupInfo;
         }

         if (deviceType === 'VirtualTPM') {
            this.addDeviceActionDefs = this.vmHardwareDeviceMenuService.getAddDeviceActions(this.vmConfigContext.privileges, this.vmWorkflowMode, ['addNewTpmAction']);
            additionalParameters.serverGuid = this.getServerGuid();
            additionalParameters.callback = (virtualTpmDevice: any) => {
               if (virtualTpmDevice.state === virtualTpmDevice.NOT_CONNECTED ||
                     virtualTpmDevice.state === virtualTpmDevice.CONNECTING) {
                  this.$scope.$emit(this.ON_CHILD_CHANGE_EVENT, this.TPM_VIEW_ID, false);
                  if(this.$scope.customizeHardwarePageModel) {
                     this.$scope.customizeHardwarePageModel.setFormValidity(false);
                  }
               }
            };
         }

         if (deviceType === DeviceTypeConstants.ETHERNET) {
            this.updatePciCounter();
            this.checkPciLimit();
         }

         if (this.showErrorByDeviceType(deviceType)) {
            return;
         }

         if (deviceType === "VirtualNVDIMM") {
            this.populateAdditionalParametersForNvdimm(additionalParameters);
         }

         if (deviceType === DeviceTypeConstants.EXISTINGHARDDISK) {
            additionalParameters.vmConfigContext = this.vmConfigContext;
            additionalParameters.serverGuid = this.getServerGuid();
            additionalParameters.storageProfiles = this.storageProfiles;
         }

         if (this.canAddDevice) {
            try {
               this.vmDeviceManagementService.createNewDevice(
                     deviceType,
                     this.vmConfigContext.environment,
                     this.getGuestOsDescriptor(),
                     this.virtualMachineDevices,
                     additionalParameters
               ).then((result: any) => {
                  let newDeviceKey: number = result.newDeviceKey
                        ? result.newDeviceKey
                        : result;
                  if (result.newVmProfile) {
                     if (this.$scope.vmParams) {
                        this.$scope.vmParams.setSelectedStorageProfile(result.newVmProfile);
                        this.selectedStorageProfile = result.newVmProfile;
                        if (this.$scope.vmParams.getStorageSelectorState()) {
                           let storageSelectorState = this.$scope.vmParams.getStorageSelectorState();
                           if (storageSelectorState.basicModeState
                                 && storageSelectorState.basicModeState.profilesData) {
                              storageSelectorState.basicModeState.profilesData.selectedProfile =
                                    result.newVmProfile;
                           }
                        }
                     }

                     this.$scope.$broadcast("vmHomeStorageProfileChanged", result.newVmProfile);
                  }

                  this.$scope.$broadcast("deviceAdded", {
                     deviceKey: newDeviceKey,
                     deviceType: deviceType
                  });
                  this.stackBlocksExpandedState.virtualDevices[newDeviceKey] = false;
               });
            } catch (error) {
               this.showError.call(this, error.message);
            }
         }
      }

      private getGuestOsDescriptor() {
         if (this.vmWorkflowMode === VmWorkflowMode.CreateMode
               || this.vmWorkflowMode === VmWorkflowMode.CloneMode) {
            return _.find(this.vmConfigContext.environment.configOption.guestOSDescriptor, (osDescriptor: any): boolean => {
               return osDescriptor.id === this.$scope.vmParams.getGosVersion().id;
            });
         } else {
            return this.vmConfigContext.environment.configOption.guestOSDescriptor[0];
         }
      }

      private getServerGuid(): string {
         if (this.vmWorkflowMode === VmWorkflowMode.CreateMode) {
            return this.defaultUriSchemeUtil.getManagedObjectReference(this.$scope.vmParams.getTargetInformation().folderUid).serverGuid;
         } else {
            return this.defaultUriSchemeUtil.getManagedObjectReference(this.vmId).serverGuid;
         }
      }

      private setupVbsProperties(vbsProperties: any, config: any) {
         this.setVbsEnabled(true, config);
         config.flags.vvtdEnabled = vbsProperties["flags.vvtdEnabled"];
         config.nestedHVEnabled = vbsProperties["nestedHVEnabled"];
      }

      private setVbsEnabled(enabled: boolean, config: any) {
         config.flags.vbsEnabled = enabled;
      }

      private setupForCreate() {
         this.vmWorkflowMode = VmWorkflowMode.CreateMode;

         this.$scope.$on("$destroy", () => {
            this.$scope.config.loading = false;
         });

         let guestOs: any = this.guestOsService.getGuestOsDescriptor(this.$scope.hardwareConfigData.vmConfigContext, this.$scope.vmParams.getGosVersion());
         let setupData: any = angular.extend({}, this.$scope.hardwareConfigData, {
            guestOs: guestOs
         });

         this.setup(setupData);

         this.storageLocatorItemsData = this.$scope.wizardViewData.getStorageLocatorItemsData();

         this.setStorageForDisk = (storageInfo: any, device: any) => {
            this.$scope.vmParams.setStorageForDisk(storageInfo, device);
         };

         this.getStorageForDisk = (diskKey: any): any => {
            return this.$scope.vmParams.getStorageForDisk(diskKey);
         };

         this.vmStorageId = this.defaultUriSchemeUtil.getVsphereObjectId(
               this.$scope.vmParams.getStorageObject().storageRef);

         if (this.$scope.vmParams.getVbsSupported()) {
            if (this.$scope.vmParams.getVbsEnabled()) {
               this.setupVbsProperties(this.getVbsProperties("flags.vbsEnabled"), this.$scope.vmParams.getVmConfigSpec().config);
            } else {
               this.setVbsEnabled(false, this.$scope.vmParams.getVmConfigSpec().config);
            }
         }

         this.computeResourceId = this.$scope.vmParams.getComputeResourceId();
         if (this.$scope.vmParams.getStorageSelectorState()) {
            this.storageBaselineId =
                  this.$scope.vmParams.getStorageSelectorState().storageBaselineId;
         }
      }

      private initializeCpu(vmConfigContext: any) {
         // TODO civanova: After implementing memory affinity add logic for
         // initializing vmConfigContext.config.memoryAffinity the same way cpuAffinity
         // is initialized when missing
         const hasPrivilege = vmConfigContext.privileges.indexOf(Privileges.VM_RESOURCE_PRIVILEGE) > -1;
         if (hasPrivilege && !vmConfigContext.config.cpuAffinity) {
            vmConfigContext.config.cpuAffinity = {
               _type: "com.vmware.vim.binding.vim.vm.AffinityInfo",
               affinitySet: []
            };
         }

         if (!vmConfigContext.config.cpuFeatureMask) {
            vmConfigContext.config.cpuFeatureMask = [];
         }
      }

      private setupForCloneMode() {
         this.vmWorkflowMode = VmWorkflowMode.CloneMode;

         this.$scope.$on("$destroy", () => {
            this.$scope.config.loading = false;
         });

         this.vmId = this.$scope.vmParams.getVmId();
         this.$scope.vmParams.setCustomizeCloneHardware(true);
         let vmConfigContext: any = this.$scope.hardwareConfigData.vmConfigContext;
         let guestOs: any = this.guestOsService
               .getAppropriateGuestOs(vmConfigContext, this.$scope.vmParams.getGosVersion());
         this.initializeCpu(vmConfigContext);
         let setupData: any = angular.extend({}, this.$scope.hardwareConfigData, {
            guestOs: guestOs
         });
         this.setup(setupData);
         this.storageLocatorItemsData = this.$scope.wizardViewData.getStorageLocatorItemsData();
         let storageSelectorState: any = this.$scope.vmParams.getStorageSelectorState();
         if (storageSelectorState) {
            this.storageBaselineId = storageSelectorState.storageBaselineId;
            if (storageSelectorState.mode === "basicMode"
                  && !_.isEmpty(storageSelectorState.basicModeState)
                  && !_.isEmpty(storageSelectorState.basicModeState.profilesData)
                  && !_.isEmpty(storageSelectorState.basicModeState.profilesData.selectedProfile)) {
               let keepExistingProfileAssignments = storageSelectorState.basicModeState
                     .profilesData.selectedProfile.keepExistingProfileAssignments;
               this.keepExistingProfileAssignments = !_.isUndefined(keepExistingProfileAssignments)
                     ?
                     keepExistingProfileAssignments
                     : false;
            }
         }
         this.setStorageForDisk = (storageInfo: any, device: any) => {
            this.$scope.vmParams.setStorageForDisk(storageInfo, device);
         };
         this.computeResourceId = this.$scope.vmParams.getComputeResourceId();

         this.vmStorageId = this.defaultUriSchemeUtil.getVsphereObjectId(
               this.$scope.vmParams.getStorageObject().storageRef);

         this.getStorageForDisk = (diskKey: any) => {
            return this.$scope.vmParams.getStorageForDisk(diskKey);
         };

         this.selectedDiskFormat = this.$scope.vmParams.getDiskFormat();
      }

      public setupForEditMode() {
         this.vmId = this.$scope.modalOptions.availableTargets[0];
         this.vmWorkflowMode = VmWorkflowMode.UpdateMode;
         let guestOs: any = this.guestOsService.getSelectedGuestOs(this.$scope.hardwareConfigData.vmConfigContext);

         let setupData: any = angular.extend({}, this.$scope.hardwareConfigData, {
            guestOs: guestOs
         });

         this.isVmPoweredOn = setupData.vmConfigContext.environment.powerState === 'poweredOn';
         let vmDatastore: any = setupData.vmConfigContext.storageInfo.datastore;
         this.vmStorageId = vmDatastore ?
               this.defaultUriSchemeUtil.getVsphereObjectId(vmDatastore) : null;

         this.setup(setupData, true, false);

         let requests: any = {
            vmAssignmentsPropertiesRequest: this.vmAssignmentsProperties
         };
         this.$q.all(requests).then((response: any) => {
            let data: any = response.vmAssignmentsPropertiesRequest;

            if (!data) {
               return;
            }

            let assignments: IPromise<any> = data.vmStorageProfileAssignments;
            let replication: IPromise<any> = data.vmReplicationGroupAssignments;
            if (assignments) {
               assignments.then((val: any) => {
                  val = val.vmStorageProfileAssignments;
                  if (!val) {
                     return;
                  }
                  let profile = val.homeStorageProfile;
                  this.selectedStorageProfile = {
                     profileObj: profile
                  };
                  if (profile) {
                     this.selectedStorageProfile.id = profile.profileId.uniqueId;
                     this.selectedStorageProfile.label = profile.name;
                  }
               });
            }

            if (data.host) {
               this.computeResourceId = this.defaultUriSchemeUtil.getVsphereObjectId(data.host);
            }

            if (replication) {
               replication.then((val: any) => {
                  val = val.vmReplicationGroupAssignments;
                  this.vmHomeReplicationGroup = this.getVmHomeRg(val);
               });
            } else {
               this.vmHomeReplicationGroup = null;
            }

         });

         // todo This needs to be moved up to vmEditSettings directive
         this.$scope.$parent.save = () => {
            let vmConfigContextConfig: any = this.vmConfigContext.config;
            let originalVmConfig: any = this.originalConfig;
            let virtualMachineDevices: any = this.virtualMachineDevices;
            let operation: any = this.$scope.modalOptions.title;
            let vmId: any = this.vmId;
            // TODO refactor to a proper name `vmConfigContextConfig` https://www.pivotaltracker.com/story/show/138740157
            let cpuFeatureMask: any = this.cpuService.createCpuIdInfoDelta(originalVmConfig.cpuFeatureMask, vmConfigContextConfig.cpuFeatureMask);
            let vmProfile = this.vmConfigContext.vmProfile;

            let storageObject: any = {};
            let storageInfo: any = this.vmConfigContext.storageInfo;

            if (storageInfo.datastore) {
               storageObject.storageRef = storageInfo.datastore;
               storageObject.parentStoragePod = storageInfo.datastoreCluster;
               // XXX - vaivanov
               // storageLocator js extends the storageRef with this isSdrsEnabled property which is not correct
               // but we need to replicate this behavior here till that is fixed. We need that because we want to reuse
               // the podSelectionSpecService.createSpec() logic.
               if (storageObject.parentStoragePod) {
                  storageObject.parentStoragePod.isSdrsEnabled = storageInfo.isSdrsEnabledForDsCluster;
               }
            } else if (storageInfo.datastoreCluster) {
               storageObject.storageRef = storageInfo.datastoreCluster;
               // XXX - vaivanov
               // storageLocator js extends the storageRef with this isSdrsEnabled property which is not correct
               // but we need to replicate this behavior here till that is fixed. We need setupforthat because we want to reuse
               // the podSelectionSpecService.createSpec() logic.
               storageObject.storageRef.isSdrsEnabled = storageInfo.isSdrsEnabledForDsCluster;
            } else {
               storageObject = undefined; // Valid case when missing System.Read on storage
            }

            if (this.vmEditSettingsDatastoreRecommendationService.shouldCheckRecommendations(storageObject, virtualMachineDevices)) {
               this.vmEditSettingsDatastoreRecommendationService.getRecommendations(vmId, storageObject,
                     virtualMachineDevices, originalVmConfig, vmConfigContextConfig, cpuFeatureMask, (result: any) => {
                        if (result) {
                           // send mutation request
                           let recommendationKeys = [result.recommendationSpec.key];
                           this.vmHardwareEditService.applyRecommendationAndUpdate(vmId, recommendationKeys, operation);
                           this.$scope.closeModal();
                        }
                     }, (error: any) => {
                        let errorMessage: string = "";
                        if (error && error.length > 0 && error[0].message) {
                           errorMessage = error[0].message;
                        } else {
                           errorMessage = this.i18nService.getString("VmUi", "SdrsRecommendations.faults.generic");
                        }
                        this.$scope.$emit("editSettingsAlertMessageChanged", {
                           isVisible: true,
                           class: "alert-warning",
                           iconShape: "exclamation-triangle",
                           text: errorMessage
                        });
                     });
            } else {
               this.vmHardwareEditService.update(vmId, operation, originalVmConfig,
                     vmConfigContextConfig, virtualMachineDevices, cpuFeatureMask,
                     vmProfile);
               this.$scope.closeModal();
            }
         };
      }

      public isVmHomeEncrypted() {
         // if the config context is not yet retrieved do not consider the VM encrypted
         if (_.isEmpty(this.vmConfigContext)) {
            return false;
         }

         return this.vmCryptUtilService
               .isVmHomeEncrypted(this.vmConfigContext);
      }

      private setup(setupData: any, requestVmHost: boolean = false,
            updateVmHomeRg: boolean = true) {
         this.vmConfigContext = setupData.vmConfigContext;
         this.originalConfig = setupData.originalConfig;
         this.stackBlocksExpandedState = this.buildStackBlockExpandedStateForDevices(
               this.stackBlocksExpandedState, setupData.virtualMachineDevices);
         this.virtualMachineDevices = setupData.virtualMachineDevices;

         let existingVirtualDisks = this.virtualMachineDevices.existingDevicesOfType('VirtualDisk');

         this.isGroupingHardDisks = existingVirtualDisks && existingVirtualDisks.length > this.MAX_SOLO_HARD_DISK_COUNT;
         let totalHardDisksCount: number = existingVirtualDisks ? existingVirtualDisks.length : 0;
         this.totalHardDiskSizeText = this.i18nService.getString('VmUi', 'DiskPage.groupedMode.content',
               totalHardDisksCount,
               this.buildTotalHardDiskSizeText(existingVirtualDisks));

         if (setupData.storageProfiles) {
            this.storageProfiles = this.$q.when(setupData.storageProfiles);
         } else {
            let serverGuid =
                  this.defaultUriSchemeUtil.getManagedObjectReference(this.vmId).serverGuid;
            this.storageProfiles = this.storageProfileService.fetchProfiles(serverGuid);
         }

         let vmAssignmentsProperties = {
            vmStorageProfileAssignments: setupData.vmStorageProfileAssignments,
            vmReplicationGroupAssignments: setupData.vmReplicationGroupAssignments,
            host: setupData.host
         };

         this.vmAssignmentsProperties = this.$q.when(vmAssignmentsProperties);

         if (this.vmId) {
            let propsToRetrieve: string[] = [];
            if (!vmAssignmentsProperties.vmStorageProfileAssignments) {
               propsToRetrieve.push(this.VM_STORAGE_PROFILE_ASSIGNMENTS_PROPERTY);
            }
            if (!vmAssignmentsProperties.vmReplicationGroupAssignments) {
               propsToRetrieve.push(this.VM_REPLICATION_GROUP_ASSIGNMENTS_PROPERTY);
            }
            if (requestVmHost && !vmAssignmentsProperties.host) {
               propsToRetrieve.push(this.VM_HOST_PROPERTY);
            }

            if (propsToRetrieve.length) {
               this.vmAssignmentsProperties = this.dataService.getProperties(
                     this.vmId, propsToRetrieve,
                     // Suppress error notification ({skipErrorInterceptor:true})
                     // as this is likely to fail if user has no privileges.
                     // Also prevent the combined promise of being rejected
                     // since vm storage profiles are not required for the
                     // edit settings dialog to initialize.
                     {skipErrorInterceptor: true})
                     .then((response: any) => {
                        if (!response) {
                           return vmAssignmentsProperties;
                        }
                        return {
                           vmStorageProfileAssignments: response[this.VM_STORAGE_PROFILE_ASSIGNMENTS_PROPERTY] ||
                                 vmAssignmentsProperties.vmStorageProfileAssignments,
                           vmReplicationGroupAssignments: response[this.VM_REPLICATION_GROUP_ASSIGNMENTS_PROPERTY] ||
                                 vmAssignmentsProperties.vmReplicationGroupAssignments,
                           host: response[this.VM_HOST_PROPERTY] || vmAssignmentsProperties.host
                        };
                     })
                     .catch(() => {
                     });
            }
         }

         if (setupData.vmVersion) {
            this.vmVersion = setupData.vmVersion;
         }

         if (setupData.selectedStorageProfile) {
            this.selectedStorageProfile = setupData.selectedStorageProfile;
            this.vmConfigContext.vmProfile = [this.selectedStorageProfile];
         }

         this.getAdvancedConfigurationForDisk = (virtualDisk: any): any =>
               this.getAdvancedConfigurationForDiskInternal(virtualDisk);

         if (setupData.selectedReplicationGroupInfo) {
            this.selectedReplicationGroupInfo = setupData.selectedReplicationGroupInfo;
            if (updateVmHomeRg) {
               this.vmHomeReplicationGroup = this.selectedReplicationGroupInfo;
            }
         }

         this.selectedGuestOs = setupData.guestOs;
         this.filterNetworksObjId = setupData.filterNetworksObjId;
         this.virtualControllers = this.virtualMachineDevices
               .existingDevicesOfType(DeviceTypeConstants.VIRTUAL_CONTROLLER);
         this.virtualScsiControllers = this.virtualMachineDevices
               .existingDevicesOfType(DeviceTypeConstants.SCSICONTROLLER);
         this.inputDevices = this.virtualMachineDevices
               .existingDevicesOfType(DeviceTypeConstants.INPUT_DEVICE);
         this.virtualEthernetCard = this.virtualMachineDevices
               .existingDevicesOfType(DeviceTypeConstants.ETHERNET);
         this.pciEthernetCount = this.virtualEthernetCard.length;

         this.errorConfig = {
            valid: true,
            message: ""
         };

         this.networkProviderId = setupData.networkProviderId;
         this.availableNetworks = setupData.availableNetworks;

         this.shouldAllowAddTpmAction().then((result: boolean) => {
            if (result) {
               this.updateActionDefsForAddDeviceMenu();
            } else {
               this.updateActionDefsForAddDeviceMenu(['addNewTpmAction']);
            }
         });

         this.controllerOptions = this.vmHardwareUtil.getDeviceOption(this.vmConfigContext, this.PCI_CONTROLLER_TYPE);
         if (this.controllerOptions) {
            this.maxEthernetCards = this.controllerOptions.numEthernetCards.max;
            this.maxPCIPassthrough = this.controllerOptions.numPCIPassthroughDevices.max;
            this.maxPciDevicesControllers = this.controllerOptions.devices.max;
         }
         this.nvmeDeviceOption = this.vmHardwareUtil.getDeviceOption(this.vmConfigContext, this.NVME_CONTROLLER_TYPE);
         this.vmConfig = new VmConfig(this.vmConfigContext,
               this.selectedGuestOs,
               this.vmConfigContext.privileges,
               this.vmWorkflowMode,
               this.i18nService, this.removeDevice);
         this.vmConfig.showMsg = this.showInfo.bind(this);
         this.$scope.hardwareConfigData.vm = this.vmConfig;

         // propogate the selectedGuestOs information into a common object so vmOptions can use too
         this.$scope.hardwareConfigData.vmConfigContext.selectedGuestOs = this.selectedGuestOs;
         this.$scope.config.loading = false;
      }

      private buildTotalHardDiskSizeText(allDisks: any[]): string {
         if (!allDisks || allDisks.length <= 0) {
            return '';
         }

         let totalSizeText: string = '',
               resultSizeInMB: number = 0;

         _.each(allDisks, (disk: any) => {
            //bring all to the common unit: MB and ADD
            resultSizeInMB += this.virtualDiskSettingsFormService.getCapacityInMB(disk.getCurrentDevice()); //MB
         });

         let displayDiskSize: DiskSize = this.basicDiskUtilService.determineDisplayDiskSize(resultSizeInMB);

         return this.basicDiskUtilService.roundToPrecision(displayDiskSize.size, 2).toString() +
               ' ' + displayDiskSize.unitText;
      }

      private shouldAllowAddTpmAction() {
         return this.tpmProvisioningService.checkDefaultKmsServer(this.getServerGuid()).then((result: any) => {
            let isPrivilegeEnabled = this.vmHardwareUtil.checkPrivileges(
                  this.vmConfigContext.privileges, [
                     Privileges.CRYPTOGRAPHER_ENCRYPT,
                     Privileges.CRYPTOGRAPHER_ENCRYPT_NEW_VM
                  ]);
            if (!result || !result.defaultKmsClusterExists || !isPrivilegeEnabled) {
               return this.$q.when(false);
            } else {
               // A VM can only have one tpm device at most.
               const maxTpmDevices: number = 1;
               const numTPMDevices: number = this.virtualMachineDevices.devicesOfType('VirtualTPM').length;

               if (numTPMDevices < maxTpmDevices) {
                  const selectedGuestOsFirmware = this.selectedGuestOs ? this.selectedGuestOs.recommendedFirmware : undefined;
                  const doesGosSupportTPM = this.selectedGuestOs ? !!this.selectedGuestOs.supportsTPM20 : false;
                  const firmware: any = this.vmConfigContext.config.firmware ? this.vmConfigContext.config.firmware :
                     selectedGuestOsFirmware;
                  const isFirmwareBios: boolean = firmware === "bios";

                  const shouldAllow: boolean = !isFirmwareBios && doesGosSupportTPM;
                  return this.$q.when(shouldAllow);
               }
               return this.$q.when(false);
            }
         });
      }

      private getPropertyRelations(): any {
         return _.reduce(this.vmConfigContext.environment.configOption.propertyRelations, (map: any, obj: any) => {
            map[obj.key.name] = obj.relations;
            return map;
         }, {});
      }

      private getVbsProperties(vbsEnabled: string): any {
         return _.reduce(this.getPropertyRelations()[vbsEnabled], (map: any, obj: any) => {
            map[obj.name] = obj.val;
            return map;
         }, {});
      }

      private hideAddDevicesMenu(e: any) {
         if (this.addDevicesMenuConfig.showMenu && this.isItAddNewDeviceElement(e.toElement)) {
            this.addDevicesMenuConfig.showMenu = false;
         }
      }

      private isItAddNewDeviceElement(elem: any): boolean {
         return elem.innerText.toUpperCase().trim() !== this.i18nService.getString("VmUi", "VmDeviceManager.AddNewDevice").toUpperCase();
      }

      private populateAdditionalParametersForNvdimm(additionalParameters: any) {
         if (this.vmWorkflowMode === VmWorkflowMode.UpdateMode) {
            additionalParameters.vm = {
               powerState: this.vmConfigContext.rtInfo.powerState
            };
         }
         additionalParameters.vmWorkflow = this.vmWorkflowMode;
         additionalParameters.storageProfiles = this.storageProfiles;
      }

      private getAdvancedConfigurationForDiskInternal(virtualDisk: any) {
         if (!this.$scope.vmParams.getIsAdvancedStorageMode()) {
            return undefined;
         }
         let advancedDiskConfigurations: Array<any> = this.$scope.vmParams
               .getStorageSelectorState().vmStorageConfigInAdvancedMode[0].vmDisks;
         return _.find(advancedDiskConfigurations, (advancedDiskConfig) => {
            return advancedDiskConfig.key === virtualDisk.getKey();
         });
      }

      private showNewNvidmmMenu(): boolean {
         let vmConfigEnv: any = this.vmConfigContext.environment;
         let additionalParams: any = {};
         this.populateAdditionalParametersForNvdimm(additionalParams);

         return this.nvdimmProvisioningService.canAddNvdimm(vmConfigEnv,
               this.getGuestOsDescriptor(), this.virtualMachineDevices, additionalParams);
      }

      private buildStackBlockExpandedStateForDevices(expandedState: any, virtualDevices: any): any {
         _.each(virtualDevices.getAllDevicesNotMarkedForRemoval(), (virtualDevice: any) => {
            let devKey: string = virtualDevice.getKey();
            if (_.isUndefined(expandedState.virtualDevices[devKey])) {
               expandedState.virtualDevices[devKey] = false;
            }
         });

         return expandedState;
      }

      private getSortedVirtualDisks(): any {
         return this.virtualMachineDevices.existingSortedDevicesOfType('VirtualDisk');
      }

      private getVmHomeRg(array: any[]): any {
         if (!array) {
            return null;
         }
         let vmRef: any = this.defaultUriSchemeUtil.getManagedObjectReference(this.vmId);
         let result: any = null;
         for (let item of array) {
            if (item && (item.vmObjectId === vmRef.value)) {
               result = item.replicationGroup;
            }
         }
         return result;
      }

      public getSerialPortDeviceList():any {
         const retVal:Array<any> =  this.getExistingAndNewDevices('VirtualSerialPort');
         for(const item of retVal) {
            (<VmHwSerialPort> item).attachVmConfig(this.vmConfig);
         }
         return retVal;
      }
   }

   angular
         .module("com.vmware.vsphere.client.vm")
         .controller("VmHardwareController", VmHardwareController);
}
