(function() {
   'use strict';
   angular.module('com.vmware.vsphere.client.commonModule')
      .controller('addEditCategoryController', AddEditCategoryController);

   AddEditCategoryController.$inject = ['categoryService', 'i18nService', '$scope', 'associableObjectTypesService', '$rootScope', '$q'];

   function AddEditCategoryController (categoryService, i18nService, $scope, associableObjectTypesService, $rootScope, $q) {
      var self = this;

      var categoryState = $scope.modalOptions.dialogData.categoryState;
      var selectedObjectTypes = categoryState.selectedAssociableEntityTypes;
      self.associableObjectTypes = associableObjectTypesService.get();
      self.multipleCardinality = categoryState.multipleCardinality + "" === 'true' ? 'multiple' : 'single';
      self.description = categoryState.description || '';
      self.name = categoryState.name || '';
      if (typeof categoryState.allSelected !== 'undefined') {
        self.disableInputs = selectedObjectTypes && Object.keys(self.associableObjectTypes).every(isObjectTypeSelected);
        self.allCheckbox = self.allCheckbox || !selectedObjectTypes || self.disableInputs;
          if (self.multipleCardinality === 'multiple') {
              self.isCardinalityDisabled = true;
          }
      } else {
          self.allCheckbox = true;
      }
      if (selectedObjectTypes) {
         Object.keys(self.associableObjectTypes).forEach(function(key) {
            if (selectedObjectTypes.indexOf(self.associableObjectTypes[key].associableTypeString) !== -1) {
               self.associableObjectTypes[key].selection = true;
               self.associableObjectTypes[key].disabled = true;
            } else {
               self.associableObjectTypes[key].selection = self.allCheckbox;  // if allCheckbox is checked, initialize to true
               self.associableObjectTypes[key].disabled = self.allCheckbox;
            }
         });
      }

      self.toggleAllTypes = toggleAllTypes;
      self.toggleAllCheckbox = toggleAllCheckbox;
      self.submit = submitCategories;
      self.okButtonDisabled = okButtonDisabled;

      function invalidateForm () {
         self.errorMessage = i18nService.getString('Common', 'tag.create.form.error.server.category.create');
         self.validity = 'invalid';
      }

      function toggleAllTypes () {
         self.allCheckbox = _.map(self.associableObjectTypes, 'selection').filter(function(selected) {
               return !selected;
            }).length === 0;
      }

      function toggleAllCheckbox () {
         Object.keys(self.associableObjectTypes).forEach(function(key) {
            if (!self.associableObjectTypes[key].disabled) {
                self.associableObjectTypes[key].selection = self.allCheckbox;
            }
         });
      }

      function submitCategories () {
         var associationObject = [];
         var promise;
         
         for (var key in self.associableObjectTypes) {
             if(self.associableObjectTypes[key].selection) {
                 associationObject.push(self.associableObjectTypes[key].associableType);
                 if (self.associableObjectTypes[key].associatedTypes) {
                     associationObject = associationObject.concat(self.associableObjectTypes[key].associatedTypes);
                 }
             }
         }

         if (categoryState.name === self.name || validateCategories(self.name)) {
            var categoryPromise;
            if (categoryState.categoryMoRef) {
               categoryPromise = categoryService.editCategory(
                  categoryState.categoryMoRef,
                  self.name,
                  self.description,
                  hasMultipleCardinality(),
                  associationObject
               );
            } else {
               categoryPromise = categoryService.addCategory(
                  self.name,
                  self.description,
                  hasMultipleCardinality(),
                  associationObject
               );
            }
            promise = $q.all([categoryPromise]).then(function () {
               $rootScope.$broadcast('refreshCategories');
            });
            self.closeModal();
         } else {
            invalidateForm();
            promise = $q.resolve();
         }
         return promise;
      }

      function hasMultipleCardinality () {
         return self.multipleCardinality === 'multiple';
      }

      function validateCategories (newCategoryName) {
         return _.filter($scope.modalOptions.dialogData.categories, function(category) {
               return category.categoryName === newCategoryName;
            }).length === 0;
      }

      function okButtonDisabled() {
         if(self.name === "") {
            return true;
         }
         return !(self.name || isAssociableObjectTypeIsChecked());
      }

      function isAssociableObjectTypeIsChecked() {
         if(self.allCheckbox) {
            return true;
         }

         return Object.keys(self.associableObjectTypes).some(function(key) {
            return self.associableObjectTypes[key].selection;
         });
      }

      function isObjectTypeSelected(key){
        return selectedObjectTypes.indexOf(self.associableObjectTypes[key].associableTypeString) !== -1;
      }


      self.closeModal = function() {
         $scope.closeModal();
      };
   }
})();
