(function() {
   /**
    * Directive for adding VM to VM rule.
    */
   'use strict';
   angular.module('com.vmware.vsphere.client.cluster').directive(
         'addVmDependencyRule', addVmDependencyRule);

   addVmDependencyRule.$inject = ['$rootScope', 'i18nService', 'clusterGroupsService',
      'clusterGroupsConstants'];

   function addVmDependencyRule($rootScope, i18nService, clusterGroupsService,
         clusterGroupsConstants) {
      return {
         restrict: 'A',
         templateUrl:
               'cluster-ui/resources/cluster/views/settings/rules/addVmDependencyRule.html',
         scope: {
            spec: '='
         },
         controller: ['$scope', function($scope) {
            var objectId = $rootScope._route.objectId;
            $scope.dependsOnVmGroups = [];
            $scope.vmGroups = [];
            $scope.spec.selectedDependsOnVmGroup = null;
            $scope.spec.selectedVmGroup = null;

            getGroupsOptions();

            $scope.$watch('spec.selectedDependsOnVmGroup',
                  function(selectedDependsOnVmGroup) {
                     if (selectedDependsOnVmGroup) {
                        //update available vm groups to exclude selected depends-on group
                        //when it changes
                        $scope.vmGroups = $scope.dependsOnVmGroups.filter(function(group) {
                           return group.name !== selectedDependsOnVmGroup;
                        });

                        if ($scope.vmGroups.length > 0) {
                           if($scope.spec.operation === "edit" && !$scope.spec.selectedVmGroup) {
                              $scope.spec.selectedVmGroup =
                                 $scope.spec.vmDependencyRuleProperties &&
                                 $scope.spec.vmDependencyRuleProperties.vmGroup ?
                                    $scope.spec.vmDependencyRuleProperties.vmGroup : null;
                           } else {
                              $scope.spec.selectedVmGroup = $scope.vmGroups[0].name;
                           }
                        }
                     }
                  });

            $scope.$watch('spec.selectedVmGroup', function() {
               updateRuleDescription();
            });

            $scope.ruleDescriptionTitle = i18nService.getString(
                  'Common', 'rules.config.description');
            $scope.dependsOnVmGroupTitle = i18nService.getString(
                  'ClusterUi', 'rules.config.vmGroup2.text');
            $scope.vmGroupTitle = i18nService.getString(
                  'ClusterUi', 'rules.config.vmGroup1.label');

            function updateRuleDescription() {
               if ($scope.spec.selectedDependsOnVmGroup === null ||
                     $scope.spec.selectedVmGroup === null) {
                  $scope.ruleDescription =
                        i18nService.getString(
                              'ClusterUi', 'rules.config.relationTypes.selectVmGroup');
               } else {
                  $scope.ruleDescription =
                        i18nService.getString('ClusterUi',
                              'rules.config.type.vmToVM.desc',
                              $scope.spec.selectedDependsOnVmGroup,
                              $scope.spec.selectedVmGroup);
               }
            }

            function getGroupsOptions() {
               var dependsOnVmGroups = [];
               return clusterGroupsService.getGroupsData(objectId)
                     .then(function(groupsData) {
                        groupsData.forEach(function(group) {
                           if (group.rawType === clusterGroupsConstants.groupType.VM_GROUP) {
                              dependsOnVmGroups.push(group);
                           }
                        });
                        $scope.dependsOnVmGroups = dependsOnVmGroups;

                        if(dependsOnVmGroups.length > 0) {
                           if($scope.spec.operation === "edit") {
                              $scope.spec.selectedDependsOnVmGroup =
                                 $scope.spec.vmDependencyRuleProperties &&
                                 $scope.spec.vmDependencyRuleProperties.dependsOnVmGroup ?
                                    $scope.spec.vmDependencyRuleProperties.dependsOnVmGroup : null;
                           } else {
                              $scope.spec.selectedDependsOnVmGroup = dependsOnVmGroups[0].name;
                           }
                        }

                     });
            }
         }]
      };
   }
})();
