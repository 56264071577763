namespace dvs_ui {

   export class DvpgReadyToCompletePageComponent {

      public bindings: any;
      public controller: any;
      public templateUrl: string;

      constructor() {
         this.bindings = {
            wizardModel: "<"
         };

         this.controller = DvpgReadyToCompletePageComponentController;

         this.templateUrl = "dvs-ui/resources/dvs/dvpg/manage/views/" +
               "dvpgReadyToCompletePageTemplate.html";
      }

   }

   class DvpgReadyToCompletePageComponentController {

      static $inject = [
         "i18nService",
         "vxPropertyViewService"
      ];

      public wizardModel: DvpgManageWizardModel;

      private propertyViewData: any;

      constructor(private i18nService: any,
                  private vxPropertyViewService: any) {
      }

      $onInit(): void {
         this.buildPropertyView();
      }

      private buildPropertyView(): void {
         let builder: any = this.vxPropertyViewService.createPropertyViewBuilder();

         let generalCategory: any = builder.category("general");
         let generalSection: any = generalCategory.section("generalSection");
         let updatedPoliciesSection: any = generalCategory.section("updatedPoliciesSection")
               .title(this.i18nService.getString("DvsUi", "dvpg.manage.summary.policiesToUpdate"));

         generalSection.property(
               this.i18nService.getString(
                     "DvsUi", "dvpg.manage.summary.portGroupCount"),
               this.wizardModel.selectedPortgroups.length);

         // Security policy
         if (this.wizardModel.policies.isSecurityPolicySelected) {
            updatedPoliciesSection.property(this.i18nService.getString(
                  "DvsUi", "dvpg.manage.policy.security.title"));
         }
         // Traffic shaping policy
         if (this.wizardModel.policies.isTrafficShapingPolicySelected) {
            updatedPoliciesSection.property(this.i18nService.getString(
                  "DvsUi", "dvpg.manage.policy.shaping.title"));
         }
         // VLAN policy
         if (this.wizardModel.policies.isVlanPolicySelected) {
            updatedPoliciesSection.property(this.i18nService.getString(
                  "DvsUi", "dvpg.manage.policy.vlan.title"));
         }
         // Failover policy
         if (this.wizardModel.policies.isFailoverPolicySelected) {
            updatedPoliciesSection.property(this.i18nService.getString(
                  "DvsUi", "dvpg.manage.policy.failover.title"));
         }
         // Resource allocation policy
         if (this.wizardModel.policies.isResAllocPolicySelected) {
            updatedPoliciesSection.property(this.i18nService.getString(
                  "DvsUi", "dvpg.manage.policy.resourceAllocation.title"));
         }
         // Monitoring policy
         if (this.wizardModel.policies.isMonitoringPolicySelected) {
            updatedPoliciesSection.property(this.i18nService.getString(
                  "DvsUi", "dvpg.manage.policy.monitoring.title"));
         }
         // Traffic filtering policy
         if (this.wizardModel.policies.isTrafficFilterPolicySelected) {
            updatedPoliciesSection.property(this.i18nService.getString(
                  "DvsUi", "dvpg.manage.policy.trafficFilter.title"));
         }
         // Miscellaneous policy
         if (this.wizardModel.policies.isMiscPolicySelected) {
            updatedPoliciesSection.property(this.i18nService.getString(
                  "DvsUi", "dvpg.manage.policy.misc.title"));
         }

         this.propertyViewData = builder.build();
      }

   }

   angular.module("com.vmware.vsphere.client.dvs")
         .component("dvpgReadyToCompletePage",
               new DvpgReadyToCompletePageComponent());
}
