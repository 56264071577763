namespace h5_vm {

   import GosBasePageModel = h5_vm.GosBasePageModel;

   export class GosAdministratorPasswordPageModel extends GosBasePageModel {

      password: string;
      confirmedPassword: string;
      autoLogon: boolean;
      logonTimes: number;
      isPasswordChanged: boolean;
      isPreviousPasswordBlank: boolean;

   }
}

