/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
/**
 * A Service for opening the Network Selector Dialog.
 */
(function() {
   'use strict';
   angular
      .module('com.vmware.vsphere.client.networkLibUi')
      .factory('networkSelectorDialogService', networkSelectorDialogService);

   networkSelectorDialogService.$inject = ['$q',
      'i18nService',
      'authorizationService',
      'defaultUriSchemeUtil',
      'userSessionService',
      'clarityModalService',
      'clarityConstants'];

   function networkSelectorDialogService (
      $q,
      i18nService,
      authorizationService,
      defaultUriSchemeUtil,
      userSessionService,
      clarityModalService,
      clarityConstants) {

      // ======================= Public API ==========================
      return {
         openNetworkSelectorDialog: openNetworkSelectorDialog,
         createNetworkSelectorFilterSpec: createNetworkSelectorFilterSpec,
         createNetworkSelectorDialogSpec: createNetworkSelectorDialogSpec
      };

      //======================= Public Functions ==========================
      /**
       * Opens an OK/Cancel modal dialog in the application.
       */
      function openNetworkSelectorDialog(contextObjectId,
                                          networkSelectorFilterSpec,
                                          networkSelectorDialogSpec,
                                          okHandler,
                                          sortParams,
                                          retrievedNetworks) {
         var modalOptions = {
            title: i18nService.getString("Common", "NetworkSelectorDialog.titleSingular"),
            contentTemplate: 'network-lib-ui/resources/network-lib/components/networkselector/networkSelectorDialog.html',
            alerts: [],
            defaultButton: 'submit'
         };

         userSessionService.getUserSession().then(function(userSession) {

            var dialogData = {};
            dialogData.contextObjectId = contextObjectId;
            dialogData.networkSelectorFilterSpec = networkSelectorFilterSpec;
            dialogData.userSessionClientId = userSession.clientId;
            dialogData.sortParams = sortParams;
            dialogData.retrievedNetworks = retrievedNetworks;
            dialogData.applyToAll = false;

            dialogData.isBatchAssignmentRelevant = false;

            if (networkSelectorDialogSpec.isBatchAssignmentRelevant !== undefined) {
               dialogData.isBatchAssignmentRelevant = networkSelectorDialogSpec.isBatchAssignmentRelevant;
            }
            dialogData.isBatchAssignmentEnabled = networkSelectorDialogSpec.isBatchAssignmentEnabled;
            dialogData.applyToAllLabel = i18nService.getString("Common", "NetworkSelectorDialog.applyToAll.label");
            dialogData.applyToAllDisabledTitle = i18nService.getString("Common", "NetworkSelectorDialog.applyToAll.signPostTitle");
            dialogData.applyToAllSingPostMessage = networkSelectorDialogSpec.applyToAllSingPostMessage;
            dialogData.applyToAllSignPostIcon = dialogData.isBatchAssignmentEnabled ? "info-circle" : "exclamation-triangle";

            modalOptions.dialogData = dialogData;
            modalOptions.onSubmit = function() {
               var selectedItems = dialogData.selectedItems;
               if (selectedItems.length === 0) {

                  showError(i18nService.getString('Common', 'NetworkSelectorDialog.noNetworksSelectedSingular'),
                     modalOptions);

                  return false;
               }

               return checkPrivileges(networkSelectorDialogSpec, selectedItems)
                  .then(function(networkItemsWithoutPrivileges) {

                     if (networkItemsWithoutPrivileges && networkItemsWithoutPrivileges.length > 0) {
                        showPrivilegeValidationError(networkItemsWithoutPrivileges, modalOptions,
                           networkSelectorDialogSpec.networkSelectorErrorMessages);
                        return false;
                     } else if (okHandler) {
                        okHandler(selectedItems, dialogData.applyToAll);
                     }
                     return true;
                  });
            };
         });

         clarityModalService.openOkCancelModal(modalOptions);
      }

      function createNetworkSelectorFilterSpec (
         includeStandardNetworks, includeOpaqueNetworks,
         includeDistributedPortGroups, includeUplinkPortGroups,
         includeOpaqueNetworkTypes) {

         return {
            _type: 'com.vmware.vsphere.client.networkselector.NetworkSelectorFilterSpec',
            includeStandardNetworks: includeStandardNetworks,
            includeOpaqueNetworks: includeOpaqueNetworks,
            includeDistributedPortGroups: includeDistributedPortGroups,
            includeUplinkPortGroups: includeUplinkPortGroups,
            includeOpaqueNetworkTypes: includeOpaqueNetworkTypes
         };
      }

      function createNetworkSelectorDialogSpec (
         privilegesToCheck,
         noPrivilegesOnFewNetworksMsg, noPrivilegesOnManyNetworksMsg) {
         return {
            privilegesToCheck: privilegesToCheck,
            networkSelectorErrorMessages: {
               noPrivilegesOnFewNetworksMsg: noPrivilegesOnFewNetworksMsg,
               noPrivilegesOnManyNetworksMsg: noPrivilegesOnManyNetworksMsg
            }
         };
      }

      function checkPrivileges (networkSelectorDialogSpec, networkItemsToCheck) {
         if (!networkSelectorDialogSpec || !networkSelectorDialogSpec.privilegesToCheck ||
            networkSelectorDialogSpec.privilegesToCheck.length === 0) {
            return $q.when(true);
         }

         var networkItemsById = {};
         _.each(networkItemsToCheck, function(networkItem) {
            var networkId = defaultUriSchemeUtil.getVsphereObjectId(networkItem.networkRef);
            networkItemsById[networkId] = networkItem;
         });

         var networkIds = _.keys(networkItemsById);
         var privileges = networkSelectorDialogSpec.privilegesToCheck;
         return authorizationService.checkPrivilegesForMultipleObjects(networkIds, privileges)
            .then(function(result) {
               var networksWithoutAllRequiredPrivileges = [];
               _.each(result, function(privileges, networkId) {

                  var allRequiredPrivilegesGranted = _.values(privileges).reduce(function(memo, isPrivilegeGranted) {
                     return memo && isPrivilegeGranted;
                  }, true);

                  if (!allRequiredPrivilegesGranted) {
                     networksWithoutAllRequiredPrivileges.push(networkItemsById[networkId]);
                  }

               });

               return networksWithoutAllRequiredPrivileges;
            });
      }

      function showPrivilegeValidationError (networkItemsWithoutPrivileges, modalOptions, customErrorMessages) {

         var networkItemsNameWithoutPrivileges = _.pluck(networkItemsWithoutPrivileges, "name");
         var networkNames, errorMessageKey;

         if (networkItemsNameWithoutPrivileges.length < 4) {
            networkNames = networkItemsNameWithoutPrivileges.join(', ');
            // Show a default error message if there is no custom error message specified
            errorMessageKey = i18nService.getString('Common', 'NetworkSelectorDialog.noPrivileges');
            if (customErrorMessages && customErrorMessages.noPrivilegesOnFewNetworksMsg) {
               errorMessageKey = customErrorMessages.noPrivilegesOnFewNetworksMsg;
            }
         } else {
            networkNames = networkItemsNameWithoutPrivileges.slice(0, 4).join(', ');
            // Show a default privilege error message if there is no custom error message specified
            errorMessageKey = i18nService.getString('Common', 'NetworkSelectorDialog.noPrivilegesAndMore');
            if (customErrorMessages && customErrorMessages.noPrivilegesOnManyNetworksMsg) {
               errorMessageKey = customErrorMessages.noPrivilegesOnManyNetworksMsg;
            }
         }
         showError(i18nService.interpolate(errorMessageKey, [networkNames]), modalOptions);
      }

      function showError (errorMessage, modalOptions) {
         modalOptions.alerts = [{
            text: errorMessage,
            type: clarityConstants.notifications.type.ERROR
         }];
      }
   }
})();
