angular.module('com.vmware.vsphere.client.host')
      .factory('hostConnectionService',
            ['dataService', 'mutationService', 'alertService',
               'i18nService', 'addHostWizardService', 'defaultUriSchemeUtil',
               function(dataService, mutationService, alertService,
                        i18nService, addHostWizardService, defaultUriSchemeUtil) {
                  var reconnect = function(availableTargets) {
                     var hostConnectionSpec = { connectionOpType: 'connect' };
                     var operationName = i18nService.getString('HostUi',
                           'reconnectHostOperation.name');

                     mutationService.applyOnMultiEntity(availableTargets,
                           'com.vmware.vsphere.client.h5.host.connect.spec.ReconnectConnectionStateSpec',
                           hostConnectionSpec,
                           operationName).then(function(operationResults) {
                        for (var i = 0; i < operationResults.length; i++) {
                           if (operationResults[i].error) {
                              addHostWizardService.showWizardInReconnectState(
                                    defaultUriSchemeUtil.getVsphereObjectId(operationResults[i].entity));
                              break;
                           }
                        }
                     });
                     return true;
                  };

                  var disconnect = function(availableTargets) {
                     var hostConnectionSpec = { connectionOpType: 'disconnect' };

                     mutationService.applyOnMultiEntity(availableTargets,
                           'com.vmware.vsphere.client.host.connection.api.HostConnectionStateSpec',
                           hostConnectionSpec);
                     return true;
                  };

                  return {
                     reconnect: reconnect,
                     disconnect: disconnect
                  };

               }
            ]);
