/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace dvs_ui {
   /**
    * Represents a PvlanMapEntry, but the primary and secondary pvlan ids
    * are replaced with PvlanIdModel, so their value can be modified,
    * but not their reference and they can keep a unique, non editable key
    */
   export class PvlanMapModel {
      public readonly primaryId: PvlanIdModel;
      public readonly secondaryId: PvlanIdModel;
      public type: string;
      public errors: PvlanMapErrors;

      constructor(primaryId: PvlanIdModel, secondaryId: PvlanIdModel, type: string) {
         this.primaryId = primaryId;
         this.secondaryId = secondaryId;
         this.type = type;
         this.errors = new PvlanMapErrors();
      }

      public hasErrors(): boolean {
         return this.errors.duplicate || this.errors.isolated ||
               this.errors.secondaryDuplicate || this.errors.secondaryIsolated;
      }
   }

   export class PvlanType {
      public static readonly promiscuous: string = "promiscuous";
      public static readonly isolated: string = "isolated";
      public static readonly community: string = "community";
   }

   export class PvlanMapErrors {
      public duplicate: boolean = false;
      public isolated: boolean = false;
      public secondaryDuplicate: boolean = false;
      public secondaryIsolated: boolean = false;
   }
}