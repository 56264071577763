/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
h5 = h5 || {};
h5.license = {
   angularModule: angular.module('com.vmware.vsphere.client.licenseUi', [
      'com.vmware.platform.ui', 'com.vmware.vsphere.client.commonModule',
      'com.vmware.vsphere.client.licenseLibUi'
   ])
};

angular.module('com.vmware.vsphere.client.licenseUi')
   .run(['serialKeyLicenseActionsService', function(serialKeyLicenseActionsService) {
      h5.actions["vsphere.core.administration.removeLicensesAction"] =
            function(actionEval, availableTargets, context) {
               if(!availableTargets) {
                  return;
               }

               serialKeyLicenseActionsService.removeLicenseKey(availableTargets);
            };

      h5.actions["vsphere.core.administration.renameLicenseAction"] =
            function(actionEval, availableTargets, context) {
               if(!availableTargets || !context) {
                  return;
               }

               serialKeyLicenseActionsService.renameSerialKeyLicense(context);
            };

      h5.actions["vsphere.core.administration.addLicenseAction"] =
            function(actionEval, availableTargets, context) {
               if (!context) {
                  return;
               }

               serialKeyLicenseActionsService.addSerialKeyLicense(context);
            };

      h5.actions["vsphere.core.administration.syncFromPortalAction"] =
            function(actionEval, availableTargets, context) {
               if (!context) {
                  return;
               }

               serialKeyLicenseActionsService.syncSerialKeyLicenses(context);
            };
   }])
   .run(['assetActionsService', 'assignLicenseService',
      function(assetActionsService, assignLicenseService) {
         h5.actions["vsphere.core.administration.removeSolutionsAction"] =
            function(actionEval, availableTargets, context) {
               if (!availableTargets) {
                  return;
               }

               assetActionsService.removeSolution(availableTargets);
            };

         h5.actions["vsphere.core.administration.assignLicenseKeyAction"] =
            function(actionEval, availableTargets, context) {
               if (!availableTargets) {
                  return;
               }

               assignLicenseService.showAssignLicenseForm(availableTargets);
            };
      }]);
