/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace dvs_ui {
   export class DvsEditPvlanDialogComponent {
      public bindings: any;
      public controller: any;
      public templateUrl: string;

      constructor() {
         this.bindings = {
            pageModel: "<"
         };
         this.controller = DvsEditNetFlowController;
         this.templateUrl =
               "dvs-ui/resources/dvs/pvlan/edit/dvsEditPvlanDialogTemplate.html";
      }
   }

   class DvsEditNetFlowController {
      static $inject = [
         "dvsEditPvlanDialogService",
         "vuiConstants",
         "i18nService",
         "$scope"
      ];

      public i18n: any;
      private pageModel: DvsEditPvlanDialogModel;
      private primaryDatagridOptions: any;
      private primaryDatagridDescription: any;
      private secondaryDatagridOptions: any;
      private selectedPrimaryIdKey: number;
      private selectedSecondaryIdKey: number;
      private valueToBeUsedForNewPvlan: PvlanIdModel[];
      private primaryDatagridCallbacks: any;
      private secondaryDatagridCallbacks: any;

      private lastEditedPrimaryVlan: PvlanMapModel | null;
      private lastEditedSecondaryVlan: PvlanMapModel | null;

      constructor(private dvsEditPvlanDialogService: DvsEditPvlanDialogService,
                  private vuiConstants: any,
                  private i18nService: any,
                  private $scope: any) {
         this.i18n = this.i18nService.getString;

         this.$scope.primaryPreselectComparator = (item: PvlanMapModel): boolean => {
            return this.selectedPrimaryIdKey === item.primaryId.key;
         };

         this.$scope.secondaryPreselectComparator = (item: PvlanMapModel): boolean => {
            return this.selectedSecondaryIdKey === item.secondaryId.key;
         };

         this.primaryDatagridCallbacks = {
            selectPrimaryIdRow: this.selectPrimaryIdRow.bind(this),
            updatePvlanIdValue: this.onPrimaryPvlanIdChange.bind(this),
            addPromiscuousPvlanMap: this.addPromiscuousPvlanMap.bind(this),
            removePrimaryVlanId: this.removePrimaryVlanId.bind(this),
            validateDuplicatePvlanMaps: this.validateDuplicatePrimaryPvlanMaps.bind(this)
         };

         this.secondaryDatagridCallbacks = {
            selectSecondaryIdRow: this.selectSecondaryIdRow.bind(this),
            updatePvlanType: this.updatePvlanType.bind(this),
            updatePvlanIdValue: this.onSecondaryPvlanIdChange.bind(this),
            addCommunityPvlanMap: this.addCommunityPvlanMap.bind(this),
            removeSecondaryVlanId: this.removeSecondaryVlanId.bind(this),
            validateDuplicatePvlanMaps: this.validateDuplicateSecondaryPvlanMaps.bind(this)
         };

         this.valueToBeUsedForNewPvlan = [];
      }

      protected $onInit() {
         this.primaryDatagridDescription =
               this.i18n("DvsUi", "PrivateVlanView.list.primary.header");

         this.primaryDatagridOptions = this.dvsEditPvlanDialogService
               .createPrimaryVlanIdDatagridOptions(this.pageModel.pvlanMaps,
                     this.primaryDatagridCallbacks);
         let promiscuousPlanMaps: PvlanMapModel[] = this.primaryDatagridOptions.data;
         _.forEach(promiscuousPlanMaps, (pvlanMap: PvlanMapModel): void => {
            let filteredPvlanMaps: PvlanMapModel[] = this.dvsEditPvlanDialogService
                  .filterPvlanMapsByPrimaryId(this.pageModel.pvlanMaps,
                        pvlanMap.primaryId.key);
            this.pageModel.updateIsolatedError(pvlanMap.primaryId.key,
                  filteredPvlanMaps);
         });
      }

      public removePrimaryVlanId(): void {
         if (this.primaryDatagridOptions.selectedItems.length > 0) {
            // need copy to iterate over originally selected items
            let selectedItems: PvlanMapModel[] =
                  this.primaryDatagridOptions.selectedItems.slice();
            _.forEach(selectedItems, (pvlanMap: PvlanMapModel): void => {
               let filtered: PvlanMapModel[] = this.dvsEditPvlanDialogService
                     .filterPvlanMapsByPrimaryId(
                           this.pageModel.pvlanMaps,
                           pvlanMap.primaryId.key);

               _.forEach(filtered, (pvlanMap: PvlanMapModel): void => {
                  delete this.pageModel.pvlanMaps[pvlanMap.secondaryId.key];
                  delete this.pageModel.pvlanIds[pvlanMap.secondaryId.key];
                  this.removeValueToBeUsedForNewPvlan(pvlanMap.secondaryId.key);
               });

               this.primaryDatagridOptions.data = this.dvsEditPvlanDialogService
                     .removePvlanIdFromData(this.primaryDatagridOptions.data, pvlanMap);
            });

            this.lastEditedPrimaryVlan = null;
         }
      }

      public addPromiscuousPvlanMap(): void {
         let pvlanMapNew: PvlanMapModel = this.addNewPvlanMap(PvlanType.promiscuous);
         this.selectedPrimaryIdKey = pvlanMapNew.primaryId.key;
         this.primaryDatagridOptions.data = this.primaryDatagridOptions.data
               .concat(pvlanMapNew);
      }

      public removeSecondaryVlanId(): void {
         if (this.primaryDatagridOptions.selectedItems.length > 0 &&
               this.secondaryDatagridOptions.selectedItems.length > 0) {
            // need copy to iterate over originally selected items
            let selectedItems: PvlanMapModel[] =
                  this.secondaryDatagridOptions.selectedItems.slice();
            _.forEach(selectedItems, (pvlanMap: PvlanMapModel): void => {
               let selectedKey: number = pvlanMap.secondaryId.key;
               delete this.pageModel.pvlanMaps[selectedKey];
               delete this.pageModel.pvlanIds[selectedKey];

               this.removeValueToBeUsedForNewPvlan(selectedKey);

               this.secondaryDatagridOptions.data = this.dvsEditPvlanDialogService
                     .removePvlanIdFromData(this.secondaryDatagridOptions.data, pvlanMap);

               this.pageModel.updateIsolatedError(this.selectedPrimaryIdKey,
                     this.secondaryDatagridOptions.data);
               this.lastEditedSecondaryVlan = null;
            });
         }
      }

      public addCommunityPvlanMap(): void {
         let selectedPrimaryId: PvlanIdModel = this.pageModel.pvlanIds[
               this.primaryDatagridOptions.selectedItems[0].primaryId.key];
         let pvlanMapNew: PvlanMapModel =
               this.addNewPvlanMap(PvlanType.community, selectedPrimaryId);
         this.selectedSecondaryIdKey = pvlanMapNew.secondaryId.key;
         this.secondaryDatagridOptions.data = this.secondaryDatagridOptions.data
               .concat(pvlanMapNew);
      }

      private addNewPvlanMap(pvlanType: string, primaryVlanId?: PvlanIdModel): PvlanMapModel {
         let keys: number[] = this.dvsEditPvlanDialogService.getKeys(
               this.pageModel.pvlanIds);
         let firstAvailableKey: number = this.dvsEditPvlanDialogService
               .getFirstAvailableKey(keys);
         let secondaryVlanId: PvlanIdModel = this.createNextPvlanId(firstAvailableKey);
         this.pageModel.pvlanIds[secondaryVlanId.key] = secondaryVlanId;

         if (!primaryVlanId) {
            primaryVlanId = secondaryVlanId;
         }

         let pvlanMapNew: PvlanMapModel =
               new PvlanMapModel(primaryVlanId, secondaryVlanId, pvlanType);
         this.pageModel.pvlanMaps[secondaryVlanId.key] = pvlanMapNew;

         return pvlanMapNew;
      }

      /**
       * A callback method, passed to the primary datagrid options, triggered
       * when a row is selected.
       * Creates the second datagrid, based on the selection in the first one.
       */
      private selectPrimaryIdRow(selectedItems: PvlanMapModel[]): void {
         this.pageModel.updateDuplicateError();
         this.updateSelectedPrimaryIdValue();

         if (!_.isEmpty(selectedItems)) {
            if (selectedItems.length === 1) {
               this.selectedPrimaryIdKey = selectedItems[0].primaryId.key;
               this.secondaryDatagridOptions = this.dvsEditPvlanDialogService
                     .createSecondaryVlanIdDatagridOptions(
                           this.pageModel.pvlanMaps,
                           this.selectedPrimaryIdKey,
                           this.secondaryDatagridCallbacks);
            }
            this.primaryDatagridOptions.actionBarOptions.actions[1].enabled = true;
         } else {
            this.primaryDatagridOptions.actionBarOptions.actions[1].enabled = false;
         }
         // needed to avoid null pointer exception in datagrid rerendering
         this.selectedSecondaryIdKey = -1;
      }

      private selectSecondaryIdRow(selectedItems: PvlanMapModel[]): void {
         this.pageModel.updateDuplicateError();
         this.updateSelectedSecondaryIdValue();

         if (!_.isEmpty(selectedItems)) {
            this.secondaryDatagridOptions.actionBarOptions.actions[1].enabled =
                  !this.dvsEditPvlanDialogService.hasPromiscuousPvlanMap(selectedItems);
            this.selectedSecondaryIdKey = selectedItems[0].secondaryId.key;
         } else {
            this.secondaryDatagridOptions.actionBarOptions.actions[1].enabled = false;
         }
      }

      private onPrimaryPvlanIdChange(pvlanId: PvlanIdModel): void {
         this.pageModel.pvlanIds[pvlanId.key].val = pvlanId.val;
         this.lastEditedPrimaryVlan = this.primaryDatagridOptions.selectedItems[0];
      }

      private onSecondaryPvlanIdChange(pvlanId: PvlanIdModel): void {
         this.pageModel.pvlanIds[pvlanId.key].val = pvlanId.val;
         this.lastEditedSecondaryVlan = this.secondaryDatagridOptions.selectedItems[0];
      }

      private validateDuplicatePrimaryPvlanMaps() {
         this.pageModel.updateDuplicateError();
         this.updateSelectedPrimaryIdValue();
      }

      private validateDuplicateSecondaryPvlanMaps() {
         this.pageModel.updateDuplicateError();
         this.updateSelectedSecondaryIdValue();
      }

      private updatePvlanType(pvlanMap: PvlanMapModel): void {
         this.pageModel.pvlanMaps[pvlanMap.secondaryId.key].type = pvlanMap.type;
         this.pageModel.updateIsolatedError(this.selectedPrimaryIdKey,
               this.secondaryDatagridOptions.data);
      }

      private updateSelectedPrimaryIdValue() {
         if (this.lastEditedPrimaryVlan) {
            this.lastEditedPrimaryVlan.primaryId.val =
                  this.pageModel.pvlanIds[this.lastEditedPrimaryVlan.primaryId.key].val;
         }
      }

      private updateSelectedSecondaryIdValue() {
         if (this.lastEditedSecondaryVlan) {
            this.lastEditedSecondaryVlan.secondaryId.val =
                  this.pageModel.pvlanIds[this.lastEditedSecondaryVlan.secondaryId.key].val;
         }
      }

      private createNextPvlanId(firstAvailableKey: number): PvlanIdModel {
         let vals: number[] = [];
         _.forEach(this.pageModel.pvlanIds, (pvlanId: PvlanIdModel): void => {
            vals.push(pvlanId.val);
         });

         let lastAvailablePvlanId: number = _.last(this.valueToBeUsedForNewPvlan)
               ? _.last(this.valueToBeUsedForNewPvlan).val : 0;
         let nextAvailableValue: number = this.dvsEditPvlanDialogService
               .getNextAvailableValue(vals, lastAvailablePvlanId);
         let pvlanIdNew: PvlanIdModel =
               new PvlanIdModel(firstAvailableKey, nextAvailableValue);
         this.valueToBeUsedForNewPvlan.push(pvlanIdNew);
         return pvlanIdNew;
      }

      private removeValueToBeUsedForNewPvlan(selectedIdKey: number): void {
         let index: number = _.findIndex(this.valueToBeUsedForNewPvlan,
               (pvlanId: PvlanIdModel): boolean => {
                  return pvlanId.key === selectedIdKey;
               });

         if (index !== -1) {
            this.valueToBeUsedForNewPvlan.splice(index, 1);
         }
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .component("dvsEditPvlanDialog", new DvsEditPvlanDialogComponent());
}
