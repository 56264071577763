/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace h5_vm {
   import VirtualNVDIMM = com.vmware.vim.binding.vim.vm.device.VirtualNVDIMM;

   export class VmHardwareSummaryNvdimm {
      public templateUrl: string;
      public controller: any;
      public controllerAs: string;
      public bindings: any;

      constructor() {
         this.templateUrl = "vm-ui/resources/vm/views/summary/vmHardwareSummaryNvdimm.html";
         this.controller = VmHardwareSummaryNvdimmController;
         this.bindings = {
            device: "<"
         };
      }

   } // VmHardwareSummaryNvdimm

   class VmHardwareSummaryNvdimmController {
      public static $inject: string[] = [
         "bytesFilter"
      ];

      // Input
      public device: VirtualNVDIMM;

      // Used in the view
      public label: string;
      public content: string;

      constructor(private bytesFilter: any) {
      }

      public $onInit() {
         this.label = this.device.deviceInfo.label;
         this.content = this.getContent();
      }

      private getContent(): string {
         return this.bytesFilter(this.device.capacityInMB, "MB");
      }

      public $onChanges(changesObj: any) {
         let deviceChange = changesObj["device"];
         if (!deviceChange || deviceChange.isFirstChange())  {
            return;
         }

         this.content = this.getContent();
      }
   } // VmHardwareSummaryNvdimmController

   angular.module("com.vmware.vsphere.client.vm").component(
         "vmHardwareSummaryNvdimm",
         new VmHardwareSummaryNvdimm());
}
