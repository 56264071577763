/* Copyright 2015 VMware, Inc. All rights reserved. -- VMware Confidential */

(function() {
   'use strict';
   angular
      .module('com.vmware.vsphere.client.commonModule')
      .controller('AboutVsphereController', AboutVsphereController);

   AboutVsphereController.$inject = ['$scope', '$sce', 'i18nService'];

   function AboutVsphereController($scope, $sce, i18nService) {
      var i18n = i18nService.getString;
      $scope.contentPatent = $sce.trustAsHtml(
            i18n('CommonUi', 'about.vsphere.content.patent',
            i18n('CommonUi', 'about.content.content.patentUrl')));
   }

})();
