declare var h5: any;
h5 = h5 || {};
h5.dsCluster = {
   angularModule: angular.module("com.vmware.vsphere.client.dsCluster", [])
};

module ds_cluster_ui {
   /**
    * Registers ds-cluster-ui actions.
    */
   angular.module("com.vmware.vsphere.client.dsCluster")
         .run(["columnRenderersRegistry", "i18nService",
            function (columnRenderersRegistry: any, i18nService: any) {

               columnRenderersRegistry.registerColumnRenderers({
                  /**
                   * Datastore name column - object-name renderer.
                   *
                   * @param  {array} props Array of property names.
                   * @param  {object} data Map of values, contains the specificType.
                   * @param {object} config A dictionary (key/value) to control the renderer behavior.
                   */
                  "datastore-name": function (props: string[], data: any, config: any) {
                     let objLinkRenderer: any = columnRenderersRegistry.getColumnRenderer(
                           "object-name");

                     data.datastoreName = "";

                     if (data.datastoreNames) {
                        data.datastoreTitle = data.datastoreNames.join(", ");

                        if (data.datastoreNames.length === 1) {
                           data.datastoreIcon = "vsphere-icon-datastore";
                           data.datastoreName = data.datastoreNames[0];
                        } else if (data.datastoreNames.length > 1) {
                           data.datastoreName = i18nService.getString("DsClusterUi",
                                 "sdrs.rules.config.multipleDatastores");
                        }
                     }

                     return objLinkRenderer([null, "datastoreIcon", "datastoreName", null,
                        "datastoreTitle"], data, config);
                  }
               });
            }])
         .run([
            "createDsClusterWizardService", "sdrsDialogService", "sdrsRulesService",
            "clarityModalService", "i18nService", "mutationService",
            "dsClusterConstants", "storageVmOverridesService", "addSdrsVmOverridesService",
            "datastoreMaintenanceModeService", "scheduleSdrsWizardService",
            function (createDsClusterDialogService: CreateDsClusterWizardService,
                      sdrsDialogService: SdrsDialogService, sdrsRulesService: SdrsRulesService,
                      clarityModalService: any, i18nService: any, mutationService: any,
                      dsClusterConstants: any, storageVmOverridesService: StorageVmOverridesService,
                      addSdrsVmOverridesService: AddSdrsVmOverridesService,
                      datastoreMaintenanceModeService: DatastoreMaintenanceModeService,
                      scheduleSdrsWizardService: any) {

               function showCreateDsClusterDialog(actionEval: any, availableTargets: string[]) {
                  createDsClusterDialogService.show(availableTargets[0]);
               }

               function showEditSdrsDialog(actionEval: any, availableTargets: string[]) {
                  sdrsDialogService.show(availableTargets[0]);
               }

               function showAddStorageVmOverrideView(actionEval: any,
                     availableTargets: any, context: any) {

                  addSdrsVmOverridesService.openAddVmOverridesWizard(availableTargets[0]);
               }

               function showRuleDialog(actionEval: any, availableTargets: string[],
                     mode: string, context: any): void {
                  let modalTitle: string;
                  if (mode === dsClusterConstants.operationType.ADD) {
                     modalTitle = i18nService.getString("DsClusterUi",
                           "sdrs.rules.rules.config.title.add");
                  } else {
                     modalTitle = i18nService.getString("DsClusterUi",
                           "sdrs.rules.rules.config.title.edit");
                  }

                  clarityModalService.openOkCancelModal({
                     title: modalTitle,
                     subTitle: {
                        objectId: availableTargets[0]
                     },
                     defaultButton: "submit",
                     modalClass: "ds-cluster-add-sdrs-rule-modal",
                     dialogData: {
                        operationType: mode,
                        context: context
                     },
                     contentTemplate: "ds-cluster-ui/resources/ds-cluster/views/settings/configuration/rules/addSdrsRule.html"
                  });
               }

               function showAddVmRuleMemberDialog(actionEval: any, availableTargets: any,
                     context: any): void {
                  let dialogOptions: any = {
                     title: i18nService.getString(
                           "DsClusterUi", "rules.config.members.dialog.title"),
                     subTitle: context.dialogSubTitleObject,
                     size: "lg",
                     defaultButton: "submit",
                     modalClass: "ds-cluster-add-sdrs-rule-member-dialog",
                     contentTemplate: "ds-cluster-ui/resources/ds-cluster/views/settings/configuration/rules/addSdrsVmRuleMember.html",
                     dialogData: {
                        selectedVms: context.selectedMembers,
                        objectId: availableTargets[0]
                     },
                     onSubmit: function () {
                        return context.submitCallback(dialogOptions);
                     }
                  };

                  clarityModalService.openOkCancelModal(dialogOptions);
               }

               function showAddVmdkRuleMemberDialog(actionEval: any, availableTargets: any,
                      context: any): void {
                  let dialogOptions: any = {
                     title: i18nService.getString(
                           "DsClusterUi", "vms.per.dscluster.single.selection.title"),
                     subTitle: context.dialogSubTitleObject,
                     size: "lg",
                     defaultButton: "submit",
                     modalClass: "ds-cluster-add-sdrs-rule-member-dialog",
                     contentTemplate: "ds-cluster-ui/resources/ds-cluster/views/settings/configuration/rules/addSdrsVmdkRuleMember.html",
                     dialogData: {
                        selectedVms: context.selectedMembers,
                        objectId: availableTargets[0]
                     },
                     onSubmit: function () {
                        return context.submitCallback(dialogOptions);
                     }
                  };

                  clarityModalService.openOkCancelModal(dialogOptions);
               }

               function showRemoveRuleMemberConfirmationDialog(
                     actionEval: any, availableTargets: any, context: any): void {
                  let confirmCallback: any = context.confirmCallback;

                  let confirmProperties: any = {
                     title: i18nService.getString("Common",
                           "sdrs.rules.config.remove.vmmember.question.title"),
                     message: i18nService.getString("Common",
                           "sdrs.rules.config.remove.vmmember.question.text"),
                     submit: function () {
                        return confirmCallback();
                     }
                  };

                  clarityModalService.openConfirmationModal(confirmProperties);
               }

               function removeRule(actionEval: any, availableTargets: string[], context: any) {
                  sdrsRulesService.deleteRule(availableTargets[0], context.selectedRule);
               }

               function showRemoveSdrsVmOverrideConfirmationDialog(availableTargets: string[], context: any) {
                  let dialogOptions: any = {
                     title: i18nService.getString("DsClusterUi",
                           "vmOverrides.actionDelete.caption"),
                     defaultButton: "submit",
                     contentTemplate:
                           "ds-cluster-ui/resources/ds-cluster/views/settings/" +
                           "configuration/sdrsVmOverrides/removeSdrsVmOverridesView.html",
                     dialogData: {
                        context: context,
                        objectId: availableTargets[0]
                     },
                  };
                  clarityModalService.openOkCancelModal(dialogOptions);
               }

               function showEditOverridesDialog(availableTargets: string[], context: any) {
                  let editOperationType: string =
                        dsClusterConstants.vmOverridesOperationType.EDIT;

                  let title: string =
                        storageVmOverridesService.getEditVmOverridesDialogTitle(context);

                  let dialogOptions: any = {
                     title: title,
                     defaultButton: "submit",
                     size: "lg",
                     contentTemplate:
                     "ds-cluster-ui/resources/ds-cluster/views/settings/" +
                     "configuration/sdrsVmOverrides/editSdrsVmOverridesView.html",
                     dialogData: {
                        context: context,
                        objectId: availableTargets,
                        mode: editOperationType
                     },
                     modalClass: "ds-cluster-add-sdrs-override-dialog"
                  };

                  clarityModalService.openOkCancelModal(dialogOptions);
               }

               function datastoreEnterMaintenanceMode(
                     actionEval: any, availableTargets: string[], context: any) {
                  datastoreMaintenanceModeService.initiateEnterMaintenanceMode(
                        availableTargets[0]);
               }

               function datastoreExitMaintenanceMode(
                     actionEval: any, availableTargets: string[], context: any) {
                  datastoreMaintenanceModeService.exitMaintenanceMode(
                        availableTargets[0]);
               }

               function showScheduleSdrsWizard(actionEval: any, availableTargets: string[], context: any) {
                  scheduleSdrsWizardService.show(availableTargets[0], context && context.focusTarget);
               }

               function deleteDsCluster(actionEval: any, availableTargets: string[]) {
                  if (availableTargets.length === 1) {
                     mutationService.remove(availableTargets[0]);
                  } else if (availableTargets.length > 1) {
                     mutationService.removeOnMultiEntity(availableTargets);
                  }
               }


               h5.actions["vsphere.core.dsCluster.actions.add"] =
                     showCreateDsClusterDialog;
               h5.actions["vsphere.core.dsCluster.actions.editSdrs"] =
                     showEditSdrsDialog;
               h5.actions["vsphere.core.dscluster.actions.rule.add"] =
                     (actionEval: any, availableTargets: string[], context: any) =>
                           showRuleDialog(actionEval, availableTargets,
                                 dsClusterConstants.operationType.ADD, context);
               h5.actions["vsphere.core.dscluster.actions.rule.members.add"] =
                     showAddVmRuleMemberDialog;
               h5.actions['vsphere.core.dscluster.actions.rule.edit'] =
                     (actionEval: any, availableTargets: string[], context: any) =>
                           showRuleDialog(actionEval, availableTargets,
                                 dsClusterConstants.operationType.EDIT, context);
               h5.actions["vsphere.core.dscluster.actions.rule.members.remove"] =
                     showRemoveRuleMemberConfirmationDialog;
               h5.actions["vsphere.core.dscluster.actions.rule.remove"] = removeRule;
               h5.actions["vsphere.core.dscluster.actions.rule.vmdk.members.add"] =
                     showAddVmdkRuleMemberDialog;
               h5.actions['vsphere.core.dscluster.actions.vmoverrides.remove'] =
                     (actionEval: any, availableTargets: string[], context: any) => {
                        showRemoveSdrsVmOverrideConfirmationDialog(availableTargets, context);
                     };
               h5.actions["vsphere.core.dscluster.actions.vmOverrides.add"] =
                     showAddStorageVmOverrideView;
               h5.actions["vsphere.core.dscluster.actions.vmOverrides.edit"] =
                   (actionEval: any, availableTargets: string[], context: any) => {
                      showEditOverridesDialog(availableTargets, context);
                   };
               h5.actions["vsphere.core.datastore.actions.enterMaintenanceMode"] =
                     datastoreEnterMaintenanceMode;
               h5.actions["vsphere.core.datastore.actions.exitMaintenanceMode"] =
                     datastoreExitMaintenanceMode;
               h5.actions["vsphere.core.dsCluster.actions.scheduleSdrs"] =
                     showScheduleSdrsWizard;
               h5.actions["vsphere.core.dscluster.actions.delete"] =
                     deleteDsCluster;
            }]);
}
