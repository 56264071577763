/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace dvs_ui {

   import IPromise = angular.IPromise;
   import DvpgTrafficFilterRule = com.vmware.vsphere.client.h5.network.dvportgroup.trafficfilter.DvpgTrafficFilterRule;
   import DvsPortTrafficFilterModel = com.vmware.vsphere.client.h5.network.dvs.ports.model.DvsPortTrafficFilterModel;
   import Action = com.vmware.vise.actionsfw.Action;
   export class DvsPortTrafficFilterViewComponent {

      public bindings: any;
      public controller: any;
      public templateUrl: string;

      constructor() {
         this.bindings = {
            selectedPort: "<",
            portContextId: "<",
            isPortGroupContext: "<"
         };

         this.controller = DvsPortTrafficFilterViewController;

         this.templateUrl = "dvs-ui/resources/dvs/ports/trafficfilter/" +
               "dvsPortTrafficFilterViewTemplate.html";
      }
   }

   export class DvsPortTrafficFilterViewController {

      static $inject = [
            "dataService",
            "i18nService",
            "actionsService",
            "dvpgTrafficFilterService",
            "dvsPortTrafficFilterService",
            "dvpgTrafficFilterConstants"
      ];

      public selectedPort: any;
      public portContextId: any;

      public isPortGroupContext: boolean;
      private enableAndReorderActionLabel: any;

      public isLoading: boolean;
      public trafficFilterSettingsTitle: string;
      public isTrafficFilterEnabled = false;

      public liveRefreshProperties: string[] = [
            "config.defaultPortConfig.filterPolicy",
            "config.defaultPortConfig.filterPolicy.filterConfig"
      ];

      public trafficFilterRules: DvpgTrafficFilterRule[];

      constructor(private dataService: any,
                  private i18nService: any,
                  private actionsService: any,
                  private dvpgTrafficFilterService: any,
                  private dvsPortTrafficFilterService: any,
                  private dvpgTrafficFilterConstants: any) {

         this.trafficFilterSettingsTitle = this.i18nService.getString(
               "DvsUi", "dvpg.trafficFilter.title.disabled");
         this.enableAndReorderActionLabel = this.i18nService.getString(
               "DvsUi", "actionEditTrafficFiltersLabel");
      }

      public $onChanges(): void {
         this.refresh(this.selectedPort);
      }

      public invokeEnableAndReorderAction() {
         this.actionsService.getAction(
               "vsphere.core.dvs.trafficFilters.editPortRulesAction",
               [this.portContextId])
               .then((action: any) => {
                  let context: any = {};
                  context.isPortGroupContext = this.isPortGroupContext;
                  context.selectedPort = this.selectedPort;
                  this.actionsService.invokeAction(action, context);
               });
      }

      public refresh(selectedPort: any) {

         if (!selectedPort) {
            return;
         }

         this.isLoading = true;
         let dvsPromise: IPromise<any>;
         let portsDataProperty: string = this.isPortGroupContext
               ? this.dvpgTrafficFilterConstants.properties.
                     DVPG_PORT_TRAFFIC_FILTER_PROPERTY
               : this.dvpgTrafficFilterConstants.properties.
                     DVS_PORT_TRAFFIC_FILTER_PROPERTY;

         if (this.isPortGroupContext) {
            dvsPromise = this.dataService.getProperties(
                  this.portContextId, [portsDataProperty],{
                     propertyParams:  [{
                        propertyName: portsDataProperty,
                        parameterType: "java.lang.String",
                        parameter: selectedPort.portId
                     }]
                  });
         } else {
            dvsPromise = this.dataService.getProperties(
                  this.portContextId, [portsDataProperty],{
                     propertyParams:  [{
                        propertyName: portsDataProperty,
                        parameterType: "java.util.Map",
                        parameter:  {
                           portgroupKey: selectedPort.portgroupKey,
                           portId: selectedPort.portId
                        }
                     }]
                  });
         }

         dvsPromise.then((dataServiceResponse: any) => {
            let model: DvsPortTrafficFilterModel =
                  dataServiceResponse[portsDataProperty];

            if (model) {
               this.isTrafficFilterEnabled =
                     model.isTrafficRulesetOverrideEnabled &&
                     model.isTrafficRulesetOverwritten;

               if (this.dvpgTrafficFilterService.areRulesChanged(
                           this.trafficFilterRules, model.rules)) {
                  this.trafficFilterRules = model.rules;
               }

               this.trafficFilterSettingsTitle = model.isTrafficRulesetEnabled ?
                     this.i18nService.getString(
                           "DvsUi", "dvpg.trafficFilter.title.enabled"):
                     this.i18nService.getString(
                           "DvsUi", "dvpg.trafficFilter.title.disabled");
            } else {
               this.trafficFilterRules = [];
            }
         }).finally(() => {
            this.isLoading = false;
         });
      }

      public onAddRule(): void {
         this.dvsPortTrafficFilterService.showPortAddRuleDialog(
               this.portContextId,
               this.isPortGroupContext,
               this.selectedPort.portgroupKey,
               this.selectedPort.portId);
      }

      public onEditRule(rule: DvpgTrafficFilterRule): void {
         this.dvsPortTrafficFilterService.showPortEditRuleDialog(
               this.portContextId,
               this.isPortGroupContext,
               this.selectedPort.portId,
               rule.key);
      }

      public onRemoveRule(rule: DvpgTrafficFilterRule): void {
         this.dvsPortTrafficFilterService.removePortRule(
               this.portContextId,
               this.selectedPort.portId,
               rule.key,
               rule.name);
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .component("dvsPortTrafficFilterView", new DvsPortTrafficFilterViewComponent());
}