(function() {
   'use strict';
   angular.module('com.vmware.vsphere.client.host')
         .controller('HostServicesController', HostServicesController);

   HostServicesController.$inject = ['$scope', 'dataService', 'hostServicesService',
      'authorizationService'];

   function HostServicesController($scope,
         dataService,
         hostServicesService,
         authorizationService) {

      var objectId = $scope._route.objectId;
      var hasPrivileges = true;
      var selectedItemKey = '';
      var self = this;

      self.headerOptions = hostServicesService.getViewHeaderOptions(objectId);
      self.datagridOptions = hostServicesService.getDatagridOptions(
            serviceCommandCallback);
      self.updateViewData = updateViewData;
      self.showMenu = hostServicesService.showServiceActionContextMenu;
      self.watchForObjects = [objectId];
      self.isHostConnected = false;
      self.liveRefreshProperties = ['config.service.service'];
      $scope.preselectComparator = function(gridDataItem) {
         return selectedItemKey && selectedItemKey === gridDataItem.key;
      };

      updateViewData();
      initializeSelectionChangeEventWatch();

      function updateViewData() {
         checkPrivileges();
         updateListData();
      }

      function checkPrivileges() {
         authorizationService.checkPrivileges(objectId, ['Host.Config.NetService'])
               .then(function(privilegesStatuses) {
                        hasPrivileges = privilegesStatuses[0];
                        updateServiceActionButtonStatus();
                     }
               );
      }

      function updateListData() {
         selectedItemKey = getSelectedItemKey();
         dataService.getProperties(objectId, ['config.service', 'runtime.connectionState']).then(function(data) {
            if (data && data['config.service']) {
               self.datagridOptions.data = data['config.service'].service || [];
            }

            if (data && data['runtime.connectionState']) {
               self.isHostConnected = data['runtime.connectionState'] === "connected";
            }

         });
      }

      function initializeSelectionChangeEventWatch() {
         $scope.$watchCollection(function() {
            return self.datagridOptions.selectedItems;
         }, onSelectedItemChanged);
      }

      function getSelectedItemRunningStatus() {
         var selectedItem = getSelectedGridItem();
         return selectedItem.running;
      }

      function getSelectedItemKey() {
         var selectedItem = getSelectedGridItem();
         return selectedItem.key;
      }

      function getSelectedGridItem() {
         var selectedGridItem;
         if (!_.isEmpty(self.datagridOptions.selectedItems)) {
            selectedGridItem = self.datagridOptions.selectedItems[0];
         } else {
            selectedGridItem = '';
         }
         return selectedGridItem;
      }

      function updateServiceActionButtonStatus() {
         var serviceStatus = getSelectedItemRunningStatus();
         self.datagridOptions.actionBarOptions.updateActionBar(serviceStatus,
               hasPrivileges, self.isHostConnected);
      }

      function onSelectedItemChanged() {
         updateServiceActionButtonStatus();
      }

      function serviceCommandCallback(actionId) {
         var currentlySelectedItem = self.datagridOptions.selectedItems[0];
         var itemKey = currentlySelectedItem.key;
         var itemLabel = currentlySelectedItem.label;
         var itemPolicy = currentlySelectedItem.policy;
         var item = { key: itemKey, label: itemLabel, policy: itemPolicy };
         hostServicesService.confirmAndExecuteServiceCommand(objectId, actionId, item);
      }
   }
})();
