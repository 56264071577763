(function(){
   'use strict';
   angular
         .module('com.vmware.vsphere.client.vm')
         .service('VmProvisioningUserSettingsPageModel', VmProvisioningUserSettingsPageModel);

   VmProvisioningUserSettingsPageModel.$inject = [
      '$q',
      'i18nService',
      'gosComputerNameValidationService',
      'nicValidatorService',
      'guestOsCustomizationSpecValidator',
      'creationTypeConstants',
      'defaultUriSchemeUtil'
   ];

   function VmProvisioningUserSettingsPageModel($q,
         i18nService,
         computerNameValidationService,
         nicValidatorService,
         guestOsCustomizationSpecValidator,
         creationTypeConstants,
         defaultUriSchemeUtil) {
      return function(virtualMachineSpecBuilder, wizardViewData) {
         var form = { nics: [] };
         var currentSpecName;
         var currentNicsToCustomize = [];

         return {
            form: form,

            resetForm: function() {
               var doResetForm = function () {
                  currentSpecName = wizardViewData.getCustomizationSpecName();
                  form.hasNicsToCustomize = false;

                  currentNicsToCustomize = angular.extend([],
                     wizardViewData.getNICsToCustomize());
                  form.nics = currentNicsToCustomize;
                  _.each(form.nics, function(nic) {
                     nic.expanded = false;
                     nic.ipv4.errors = {};
                     nic.ipv6.errors = {};
                     if (nic.ipv6.required) {
                        buildGridOptions(nic.ipv6);
                        form.hasNicsToCustomize = true;
                     }
                     if(nic.ipv4.required) {
                        form.hasNicsToCustomize = true;
                     }
                  }.bind(this));

                  if (wizardViewData.getNeedToInputComputerName()) {
                     form.computerName = {name: ''};
                  } else {
                     form.computerName = undefined;
                  }

                  var WINDOWS_MAX_NAME_LEN = 15;
                  var LINUX_MAX_NAME_LEN = 63;
                  var guestOsType = wizardViewData.getGuestOsType();

                  if (guestOsType === 'Windows') {
                     form.pcNameMaxLength = WINDOWS_MAX_NAME_LEN;
                  } else {
                     form.pcNameMaxLength = LINUX_MAX_NAME_LEN;
                  }
               };

               // Check if another spec was selected
               var isNewSpecSelected =
                  wizardViewData.getCustomizationSpecName() !== currentSpecName;

               if (isNewSpecSelected) {
                  // Spec is changed - reset the form
                  doResetForm();
                  return;
               }

               var vmId = virtualMachineSpecBuilder.getVmId();
               if (virtualMachineSpecBuilder.getCreationType() ===
                     creationTypeConstants.DEPLOY_VM_FROM_TEMPLATE) {
                  // Constructing a dummy VM ID for deploy OVF templates. OVF templates
                  // do not have associated VM IDs. VM ID is necesary to get GOSC spec.
                  vmId = 'urn:vmomi:VirtualMachine:template:' +
                        defaultUriSchemeUtil
                              .getPartsFromVsphereObjectId(wizardViewData.getVCenterId())
                              .serverGuid;
               }

               // No new spec is selected but the spec properties may have changed
               guestOsCustomizationSpecValidator.validate(
                  vmId,
                  virtualMachineSpecBuilder.getName(),
                  wizardViewData.getCustomizationSpecName()
               ).then(function (result) {
                  wizardViewData.setCustomizationSpecName(result.specName);
                  wizardViewData.setNICsToCustomize(result.nicsToCustomize);
                  wizardViewData.setNeedToInputComputerName(result.needsComputerName);

                  // Check if the current spec's computer name type was changed
                  var isNeedToInputComputerName = !!form.computerName;
                  var isSpecChanged =
                     wizardViewData.getNeedToInputComputerName() !== isNeedToInputComputerName;

                  // Check if the current spec's NICs were changed
                  var nicsChanged = _.any(wizardViewData.getNICsToCustomize(), function(value, index) {
                     return value.ipv4.required !== currentNicsToCustomize[index].ipv4.required
                           || value.ipv6.required !== currentNicsToCustomize[index].ipv6.required;
                  });
                  isSpecChanged = isSpecChanged || nicsChanged;

                  if (isSpecChanged) {
                     doResetForm();
                  }
               });
            },

            validateComputerName: function() {
               var errors =
                  computerNameValidationService
                     .validateComputerName(form.pcNameMaxLength, form.computerName.name);
               form.computerName.invalid = false;
               if (!_.isEmpty(errors)) {
                  form.computerName.invalid = true;
                  form.computerName.error = errors[0];
               }
            },
            hasAlerts: hasAlerts,

            validatePage: function() {
               var needToInputComputerName = wizardViewData.getNeedToInputComputerName();

               var pageHasErrors = false;

               if (needToInputComputerName) {
                  this.validateComputerName();
                  pageHasErrors = form.computerName.invalid;
               }

               _.each(form.nics, function(nicForm) {
                  var validationResult = nicValidatorService.validate(nicForm);
                  nicForm.ipv4.errors.addressInvalid = validationResult.errors.ipv4.address;
                  nicForm.ipv4.errors.subnetMaskInvalid = validationResult.errors.ipv4.subnetMask;
                  nicForm.ipv4.errors.defaultGatewayInvalid = validationResult.errors.ipv4.defaultGateway;
                  nicForm.ipv4.errors.alternateGatewayInvalid = validationResult.errors.ipv4.alternateGateway;
                  nicForm.ipv6.errors.addressInvalid = validationResult.errors.ipv6.address;
                  nicForm.ipv6.errors.subnetPrefixLengthInvalid = validationResult.errors.ipv6.subnetPrefixLength;
                  hasAlerts(nicForm);
                  pageHasErrors = pageHasErrors || _.any(validationResult.errors, _.negate(_.isEmpty));
               });

               if (pageHasErrors) {
                  return $q.resolve({
                     error: i18nService.getString('VmUi', 'UserSettingsPage.validation_error')
                  });
               }

               return $q.resolve(null);
            },

            submitPage: function() {
               var needToInputComputerName = wizardViewData.getNeedToInputComputerName();

               if (needToInputComputerName) {
                  virtualMachineSpecBuilder.setCustomPcName(
                        form.computerName.name
                  );
               }

               _.each(form.nics, function(nic, index) {
                  if (nic.ipv4.required) {
                     virtualMachineSpecBuilder.setCustomIpv4ForNIC(index, nic);
                  }
                  if (nic.ipv6.required) {
                     virtualMachineSpecBuilder.setCustomIpv6ForNIC(index, nic);
                  }
               });

               return {
                  wizardFlow: virtualMachineSpecBuilder.getCreationType(),
                  invalidateNextPages: false
               };
            }
         };
      };

      function hasAlerts(nic) {
         nic.alerts = 0;
         if(!nic.expanded) {
            _.each(nic.ipv4.errors, function(error) {
               if(error) {
                  nic.alerts +=1;
               }
            });

            _.each(nic.ipv6.errors, function(error) {
               if(error) {
                  nic.alerts +=1;
               }
            });
         }

         return nic.alerts;
      }

      function buildGridOptions(nic) {
         var gridGatewaysOptions = {};
         gridGatewaysOptions.columnDefinitions = [
            {
               displayName: i18nService.getString("VmUi", "CustomizationParametersPageBase.nic.ipv6.gateway"),
               type: "string"
            }
         ];
         gridGatewaysOptions.data = nic.gateway;
         gridGatewaysOptions.deleteLabel = i18nService.getString(
               "VmUi", "CustomizationParametersPageBase.nic.delete.label");
         gridGatewaysOptions.deleteTooltip = i18nService.getString(
               "VmUi", "CustomizationParametersPageBase.nic.delete.tooltip");
         gridGatewaysOptions.onDelete = function(gateway) {
            deleteGatewayFromList(gateway, nic);
         };
         gridGatewaysOptions.placeholder = i18nService.getString(
               "VmUi", "CustomizationParametersPageBase.noGateways");
         gridGatewaysOptions.gridLabel = i18nService.getString(
               "VmUi", "CustomizationParametersPageBase.ipv6GatewaysDescription");
         nic.gridGatewaysOptions = gridGatewaysOptions;
      }

      function deleteGatewayFromList(gateway, nic) {
         if (!gateway) {
            return;
         }

         var index = _.findIndex(nic.gateway,
               function(currentGateway) {
                  return currentGateway === gateway;
               });
         if (index < 0) {
            return;
         }

         nic.gateway.splice(index, 1);
      }
   }
})();
