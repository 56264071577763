(function() {
   'use strict';
   angular.module('com.vmware.vsphere.client.cluster')
         .controller('ViewDetailsClusterRuleMemberController',
               ViewDetailsClusterRuleMemberController);

   ViewDetailsClusterRuleMemberController.$inject = ['$scope', 'i18nService',
         'clusterRulesService', 'vuiConstants', 'clusterRulesConstants',
         'defaultUriSchemeUtil'];

   function ViewDetailsClusterRuleMemberController($scope, i18nService,
        clusterRulesService, vuiConstants, clusterRulesConstants, defaultUriSchemeUtil) {

      var self = this;
      self.conflictsGridData = getGridData(getConflictsDescColumnDefs(),
            vuiConstants.grid.selectionMode.MULTI);
      self.membersRulesGridData = getGridData(getConflictingRulesColumnDefs(),
            vuiConstants.grid.selectionMode.SINGLE);

      getViewData();

      function getViewData() {
         getConflictsMessages(
               $scope.modalOptions.dialogData.member.conflicts,
               $scope.modalOptions.dialogData.rule.name);
      }

      function getGridData(columnDefs, selection) {
         var options = {
            pageConfig: {
               hidePager: true
            },
            searchable: false,
            resizable: false,
            data: [],
            selectionMode: selection,
            showCheckboxesOnMultiSelection: false,
            selectedItems: [],
            columnDefs: columnDefs,
            height: '100%'
         };
         return options;
      }

      function getConflictingRulesColumnDefs() {
         return [{
            displayName: i18nService.getString('ClusterUi', 'rules.vmRuleMemberDetails.ruleName'),
            field: 'name',
            type: 'string',
            template: '<span class="object" title="#:name#"><i class="#:icon#")></i>#:name#</span>'
         }];
      }

      function getConflictsDescColumnDefs() {
         return [{
            displayName: i18nService.getString('ClusterUi', 'rules.vmRuleMemberDetails.issue'),
            field: 'issue',
            type: 'string',
            template: '<span class="object" title="#:issue#"><i class="#:icon#")></i>#:issue#</span>'
         }];
      }

      function getRulesGridData(rules) {
         var ruleNames = [];
         _.each(rules, function(item) {
            if(item.rule.name !== $scope.modalOptions.dialogData.rule.name) {
               ruleNames.push({
                  name: item.rule.name,
                  icon: item.conflictingRules.length > 0 ?
                        'vx-icon-rule-conflict' : 'vx-icon-rule'
               });
            }
         });

        self.membersRulesGridData.data = ruleNames;
      }

      function getDescriptionByConflictType(conflict, vmNamesLabel, otherRuleType, otherRuleName) {
         var issue;
         switch (conflict.conflictType) {
            case clusterRulesConstants.conflictType.ANTIAFFINITY_ANTIAFFINITY:
            case clusterRulesConstants.conflictType.AFFINITY_AFFINITY:
               issue = i18nService.getString('ClusterUi',
                     "rules.vmRuleMemberDetails.affinityAffinityConflictDescription",
                     vmNamesLabel, otherRuleType, otherRuleName);
               break;

            case clusterRulesConstants.conflictType.AFFINITY_ANTIAFFINITY :
               issue = i18nService.getString('ClusterUi',
                     "rules.vmRuleMemberDetails.affinityAntiaffinityConflictDescription",
                     vmNamesLabel, otherRuleType, otherRuleName);
               break;
            default :
               issue = i18nService.getString('ClusterUi', "rules.vmRuleMemberDetails.unknownConflictDescription");
         }
         return issue;
      }

      function getVmNamesLabel(vmsNames){
         var index = 0;
         var vmNamesLabel = '';

         _.each(vmsNames, function(vmName) {
            if (index === 0) {
               vmNamesLabel = vmName;
            } else {
               if (index === vmsNames.length - 1) {
                  vmNamesLabel += ' ' + i18nService.getString('ClusterUi',
                              'rules.vmRuleMemberDetails.and') + ' ' + vmName;
               } else {
                  vmNamesLabel += ', ' + vmName;
               }
            }
            index++;
         });

         return vmNamesLabel;
      }

      function getAllConflictsMembers(conflicts) {
         var vms = [];
         _.each(conflicts, function(conflict) {
            vms = [].concat(conflict.members, vms);
         });

         return vms;
      }

      function getConflictsMessages(conflicts, parentRuleName) {
         var vms = getAllConflictsMembers(conflicts);
         var issueDescLabels = [];
         clusterRulesService.getVmNames(vms).then(function(vmsData){
            _.each(conflicts, function(conflict) {
               var vmNames = [];
               var otherRule = parentRuleName === conflict.rule1.rule.name ?
                     conflict.rule2 : conflict.rule1;
               var otherRuleName = otherRule.rule.name;
               var otherRuleType =
                     clusterRulesConstants.ruleType.VM_AFFINITY_RULE === otherRule.ruleType ?
                           i18nService.getString('ClusterUi', 'rules.vmRuleMemberDetails.affinity') :
                           i18nService.getString('ClusterUi', 'rules.vmRuleMemberDetails.antiAffinity');

               _.each(conflict.members, function(member) {
                  var vmId = defaultUriSchemeUtil.getVsphereObjectId(member.entity);
                  vmNames.push(vmsData[vmId].name);
               });

               var vmNamesLabel = getVmNamesLabel(vmNames);
               issueDescLabels.push(
                     {
                        issue: getDescriptionByConflictType(conflict, vmNamesLabel,
                              otherRuleType, otherRuleName),
                        icon: 'vx-icon-error'
                     });
            });
            self.conflictsGridData.data = issueDescLabels;

            // setting the data here is needed in order to properly size the second grid
            // otherwise the grid doesn't refresh its data and its scroller doesn't exist
            getRulesGridData($scope.modalOptions.dialogData.member.rules);

         });
      }
   }
})();
