namespace storage_ui {

   import IComponentController = angular.IComponentController;

   export class StorageAdaptersMasterViewController implements IComponentController {

      public static $inject = ["i18nService", "$q"];

      public readonly detailsViewData: any;

      private readonly tabs: any[] = [{
         name: this.i18nService.getString("StorageUi", "storage.adapters.tabs.properties.header"),
         templateUrl: "storage-ui/resources/storage/views/host/adapters/StorageAdaptersGeneralDetailsView.html",
         uid: "storage.adapters.generalTab"
      }, {
         name: this.i18nService.getString("StorageUi", "storage.adapters.tabs.devices.header"),
         templateUrl: "storage-ui/resources/storage/views/host/adapters/StorageAdaptersDevicesDetailsView.html",
         uid: "storage.adapters.devicesTab"
      }, {
         name: this.i18nService.getString("StorageUi", "storage.adapters.tabs.paths.header"),
         templateUrl: "storage-ui/resources/storage/views/host/adapters/StorageAdaptersPathsDetailsView.html",
         uid: "storage.adapters.pathsTab"
      }, {
         name: this.i18nService.getString("StorageUi", "storage.adapters.tabs.iscsiDynamicTargets.header"),
         templateUrl: "storage-ui/resources/storage/views/host/adapters/targets/IscsiDynamicTargetsDetailsView.html",
         uid: "storage.adapters.dynamicTargetsTab",
         metadata: {
            relevantFor: "isIscsiAdapter"
         }
      }, {
         name: this.i18nService.getString("StorageUi", "storage.adapters.tabs.iscsiStaticTargets.header"),
         templateUrl: "storage-ui/resources/storage/views/host/adapters/targets/IscsiStaticTargetsDetailsView.html",
         uid: "storage.adapters.staticTargetsTab",
         metadata: {
            relevantFor: "isIscsiAdapter"
         }
      }, {
         name: this.i18nService.getString("StorageUi", "storage.adapters.tabs.networkSettings.header"),
         templateUrl: "storage-ui/resources/storage/views/host/adapters/StorageAdaptersNetworkSettingsView.html",
         uid: "storage.adapters.networkSettingsTab",
         metadata: {
            relevantFor: "isIscsiAdapter, !isSoftwareBased"
         }
      }, {
         name: this.i18nService.getString("NetworkUi", "storage.adapters.tabs.networkPortBinding.header"),
         templateUrl: "network-ui/resources/network/views/host/iscsi/IscsiPortBindingView.html",
         uid: "storage.adapters.networkPortBindingsTab",
         metadata: {
            relevantFor: "portBindableIScsi"
         }
      }, {
         name: this.i18nService.getString("StorageUi", "storage.adapters.tabs.advancedOptions.header"),
         templateUrl: "storage-ui/resources/storage/views/host/adapters/advanced/IscsiAdvancedOptionsDetailsView.html",
         uid: "storage.adapters.iscsiAdvancedOptions",
         metadata: {
            relevantFor: "isIscsiAdapter"
         }
      }];

      constructor(private i18nService: any, private $q: ng.IQService) {
         this.detailsViewData = {
            tabsPromise: this.$q.when(this.tabs)
         };
      }
   }

   angular.module("com.vmware.vsphere.client.storage")
         .controller("StorageAdaptersMasterViewController",
               StorageAdaptersMasterViewController);
}
