namespace network_ui {

   import IPromise = angular.IPromise;

   export class IscsiPortBindingDetailsViewData {
      public propertiesData: any;
      public viewTitle: string;
   }

   export class IscsiPortBindingDetailsViewDataRetriever {

      constructor(public retrieveData: (hostId: String, item: IscsiPortInfoItem, isBoundPortBinding: boolean)
            => IPromise<IscsiPortBindingDetailsViewData>) {
      }
   }

   export class IscsiPortBindingDetailsService {

      public static STANDARD_PORT_GROUP_DETAILS = "virtualswitch:portGroupPropertyViewDataByName";

      public static $inject = ["dataService", "i18nService", "$q",
         "networkDetailsViewService", "physicalAdaptersListService",
         "physicalAdapterDetailsViewService", "vmKernelAdapterService",
         "dvPortgroupPropertiesService", "vxPropertyViewService", "distributedPortgroupPoliciesViewService",
         "iscsiPortBindingConstants",
         "standardSwitchPropertiesViewService",
         "standardSwitchPoliciesViewService",
         "dvsDetailsViewService"];

      constructor(private dataService: any,
                  private i18nService: any,
                  private $q: any,
                  private networkDetailsViewService: any,
                  private physicalAdaptersListService: any,
                  private physicalAdapterDetailsViewService: any,
                  private vmKernelAdapterService: any,
                  private dvPortgroupPropertiesService: any,
                  private vxPropertyViewService: any,
                  private distributedPortgroupPoliciesViewService: any,
                  private iscsiPortBindingConstants: any,
                  private standardSwitchPropertiesViewService: any,
                  private standardSwitchPoliciesViewService: any,
                  private dvsDetailsViewService: any) {
      }

      public requestPortgroupDetailsViewData(hostId: string, item: IscsiPortInfoItem): IPromise<IscsiPortBindingDetailsViewData> {

         if (hostId && item) {
            switch (item.vnicBackingType) {
               case this.iscsiPortBindingConstants.backingType.STANDARD_VNIC_BACKING_TYPE:
                  return this.requestPortgroupDetailsViewDataForStandardPg(hostId, item);
               case this.iscsiPortBindingConstants.backingType.DISTRIBUTED_VNIC_BACKING_TYPE:
                  return this.requestPortgroupDetailsViewDataForDvpg(hostId, item);
            }
         }

         return this.$q.when(null);
      }

      public requestSwitchDetailsViewData(hostId: string, item: IscsiPortInfoItem): IPromise<IscsiPortBindingDetailsViewData> {

         if (hostId && item) {
            switch (item.vnicBackingType) {
               case this.iscsiPortBindingConstants.backingType.STANDARD_VNIC_BACKING_TYPE:
                  return this.requestSwitchDetailsViewDataForStandardSwitch(hostId, item);
               case this.iscsiPortBindingConstants.backingType.DISTRIBUTED_VNIC_BACKING_TYPE:
                  return this.requestSwitchDetailsViewDataForDvs(hostId, item);
            }
         }

         return this.$q.when(null);
      }

      private requestSwitchDetailsViewDataForStandardSwitch(hostId: string, item: IscsiPortInfoItem) {
         let self = this;

         if (!item.switchName) {
            return this.$q.when(null);
         }

         let requests = {
            switchProperties: this.standardSwitchPropertiesViewService.getDataByName(hostId, item.switchName),
            switchPolicies: this.standardSwitchPoliciesViewService.getDataByName(hostId, item.switchName)
         };

         return this.$q.all(requests).then(function (response: any) {
            if (!response || !response.switchProperties) {
               return null;
            }

            let builder = self.vxPropertyViewService.createPropertyViewBuilder();

            let propertiesCategory = builder
                  .category("properties")
                  .title(self.i18nService.getString("NetworkUi", "VSwitchSettingsView.properties"));

            self.standardSwitchPropertiesViewService.addPropertiesToCategory(
                  response.switchProperties,
                  propertiesCategory);

            if (response.switchPolicies) {
               let policiesCategory = builder
                     .category("policies")
                     .title(self.i18nService.getString("NetworkUi", "VSwitchSettingsView.policy"));

               self.standardSwitchPoliciesViewService.addPropertiesToCategory(response.switchPolicies, policiesCategory);
               builder.generateAllCategory();
            }

            let result = new IscsiPortBindingDetailsViewData();
            result.propertiesData = builder.build();
            result.viewTitle = self.i18nService.getString("NetworkUi", "VSwitchSettingsView.title", item.switchName);

            return result;
         });
      }

      private requestSwitchDetailsViewDataForDvs(hostId: string, item: IscsiPortInfoItem) {
         let self = this;

         if (!item.dvSwitchUuid) {
            return this.$q.when(null);
         }

         let dvsConfigDataByUuidProperty = "dvswitchproperties:configDataByUuid";

         return this.dataService.getProperties(hostId, [dvsConfigDataByUuidProperty], {
            propertyParams: [{
               propertyName: dvsConfigDataByUuidProperty,
               parameterType: "java.lang.String",
               parameter: item.dvSwitchUuid
            }]
         }).then(function (response: any) {
            let dvsConfigData = response && response[dvsConfigDataByUuidProperty];
            if (!dvsConfigData) {
               return null;
            }

            let result = new IscsiPortBindingDetailsViewData();
            result.propertiesData = self.dvsDetailsViewService.build(dvsConfigData, true);
            result.viewTitle = self.i18nService.getString(
                  "NetworkUi", "HostProxySwitchDetailsView.titleFormat", item.switchName);
            return result;
         });
      }

      private requestPortgroupDetailsViewDataForStandardPg(hostId: string, item: IscsiPortInfoItem) {
         let self = this;
         return self.dataService.getProperties(
               hostId, [IscsiPortBindingDetailsService.STANDARD_PORT_GROUP_DETAILS], {
                  propertyParams: [{
                     propertyName: IscsiPortBindingDetailsService.STANDARD_PORT_GROUP_DETAILS,
                     parameterType: "java.lang.String",
                     parameter: item.vnicSpec.portgroup
                  }]
               }).then(function (response: any) {
            let networkData = response && response[IscsiPortBindingDetailsService.STANDARD_PORT_GROUP_DETAILS];
            if (!networkData) {
               return null;
            }
            let result = new IscsiPortBindingDetailsViewData();
            result.propertiesData = self.networkDetailsViewService.build(networkData);
            result.viewTitle = self.i18nService.getString(
                  "NetworkUi", "PortgroupSettingsView.title", networkData.networkLabel);
            return result;
         });
      }

      private requestPortgroupDetailsViewDataForDvpg(hostId: string, item: IscsiPortInfoItem) {

         if (!item.vnicSpec || !item.vnicSpec.distributedVirtualPort || !item.vnicSpec.distributedVirtualPort.portgroupKey) {
            return this.$q.when(null);
         }

         let self = this;
         return this.dvPortgroupPropertiesService.requestDvPortgroupPropertiesAndPolicy(
               hostId, item.vnicSpec.distributedVirtualPort.portgroupKey).then(function (dvpgPropertiesAndPolicyData: any) {

            if (!dvpgPropertiesAndPolicyData || !dvpgPropertiesAndPolicyData.properties) {
               return null;
            }

            let builder = self.vxPropertyViewService.createPropertyViewBuilder();

            let propertiesCategory = builder
                  .category("properties")
                  .title(self.i18nService.getString("NetworkUi", "dvpg.manage.settings.propertiesHeader"));

            self.dvPortgroupPropertiesService.addPropertiesToSection(
                  dvpgPropertiesAndPolicyData.properties, propertiesCategory);

            if (dvpgPropertiesAndPolicyData.policy) {
               self.distributedPortgroupPoliciesViewService.build(dvpgPropertiesAndPolicyData.policy, builder);
            }

            let result = new IscsiPortBindingDetailsViewData();
            builder.generateAllCategory();
            result.propertiesData = builder.build();
            if (dvpgPropertiesAndPolicyData.isUplink) {
               result.viewTitle = self.i18nService.getString(
                     "NetworkUi", "DvPortgroupProperties.uplinkTitleFormat", dvpgPropertiesAndPolicyData.properties.name);
            } else {
               result.viewTitle = self.i18nService.getString(
                     "NetworkUi", "DvPortgroupProperties.titleFormat", dvpgPropertiesAndPolicyData.properties.name);
            }

            return result;
         });
      }

      public requestPnicDetailsViewData(hostId: string, item: IscsiPortInfoItem): IPromise<IscsiPortBindingDetailsViewData> {
         let self = this;

         if (item && item.pnicDevice) {
            return this.physicalAdaptersListService
                  .getPhysicalAdapterDetailsItem(hostId, item.pnicDevice)
                  .then(function (pnicDetailsItem: any) {
                     if (!pnicDetailsItem) {
                        return null;
                     }

                     let result = new IscsiPortBindingDetailsViewData();
                     result.propertiesData = self.physicalAdapterDetailsViewService.build(pnicDetailsItem);
                     result.viewTitle = self.i18nService.getString(
                           "NetworkUi", "PnicPropertiesView.title", item.pnicDevice);
                     return result;
                  });
         }

         return this.$q.when(null);
      }

      public requestVnicDetailsViewData(hostId: string, item: IscsiPortInfoItem): IPromise<IscsiPortBindingDetailsViewData> {
         let self = this;

         if (item && item.vnicDevice) {
            return this.vmKernelAdapterService.getVnicDetailsData(hostId, item.vnicDevice)
                  .then(function (detailsData: any) {

                     if (!detailsData) {
                        return null;
                     }

                     let result = new IscsiPortBindingDetailsViewData();
                     result.propertiesData = self.networkDetailsViewService.build(detailsData);
                     result.viewTitle = self.i18nService.getString(
                           "NetworkUi", "VnicDetailsView.title", item.vnicDevice);
                     return result;
                  });
         }

         return this.$q.when(null);
      }
   }

   angular.module("com.vmware.vsphere.client.network")
         .service("iscsiPortBindingDetailsService", IscsiPortBindingDetailsService);
}
