namespace storage_ui {

   import NetworkSettingsItem = com.vmware.vsphere.client.h5.storage.data.adapters.NetworkSettingsItem;
   import InternetScsiStorageAdapterIpConfigurationUpdateSpec = storage_ui.InternetScsiStorageAdapterIpConfigurationUpdateSpec;
   import InternetScsiHba$IscsiIpv6Address = storage_ui.InternetScsiHba$IscsiIpv6Address;
   import InternetScsiHba$IPProperties = storage_ui.InternetScsiHba$IPProperties;
   import InternetScsiHba$IPv6Properties = storage_ui.InternetScsiHba$IPv6Properties;

   export class StorageAdapterEditNetworkSettingsDialogService {

      public static $inject = [
         "i18nService",
         "clarityModalService",
         "StorageAdapterNetworkSettingsConstants",
         "vxValidatorFactory",
         "mutationService",
         "hbaNetworkingUtil",
         "clarityConstants"
      ];
      private dialogData: any;

      constructor(private i18nService: any,
                  private clarityModalService: any,
                  private constants: any,
                  private vxValidatorFactory: any,
                  private mutationService: any,
                  private hbaNetworkingUtil: HbaNetworkingUtil,
                  private clarityConstants:any) {
      }

      open(hostId: any, adapter: any, networkSettings: NetworkSettingsItem): void {
         let spec = new InternetScsiStorageAdapterIpConfigurationUpdateSpec();
         spec.device = adapter.device;
         spec.properties = <InternetScsiHba$IPProperties>_.clone(networkSettings.properties);
         this.dialogData = {
            constants: this.constants,
            model: this.clone(networkSettings),
            validator: this.vxValidatorFactory.create(),
            spec: spec,
            ipv4StackBlockExpanded: true,
            ipv6StackBlockExpanded: false,
            dnsStackBlockExpanded: false,
            i18n: this.i18nService.getString,
            ipv6options: [{
               name: this.i18nService.getString("Common", "enabled"),
               value: true
            }, {
               name: this.i18nService.getString("Common", "disabled"),
               value: false
            }],
            ipv6LinkLocalAddress: null
         };

         let modalOptions = {
            title: this.i18nService.getString("StorageUi", "storage.adapters.properties.ipDnsConfig.edit.h5header"),
            subTitle: {
               text: adapter.device
            },
            defaultButton: "submit",
            size: "lg",
            alerts: <any[]>[],
            dialogData: this.dialogData,
            contentTemplate: "storage-ui/resources/storage/views/host/adapters/network/StorageAdapterEditNetworkSettingsDialog.html",
            onSubmit: ()=> {
               let errors = this.dialogData.validator.validate();
               if (errors.length) {
                  modalOptions.alerts = _.map(errors, (error)=>({
                     text: error,
                     type: this.clarityConstants.notifications.type.ERROR
                  }));
                  return false;
               }
               let updateSpec:InternetScsiStorageAdapterIpConfigurationUpdateSpec =
                     new InternetScsiStorageAdapterIpConfigurationUpdateSpec();
               updateSpec.device = spec.device;
               updateSpec.properties = new InternetScsiHba$IPProperties();
               updateSpec.properties.ipv6properties = new InternetScsiHba$IPv6Properties();
               if (this.updateIpv4PageData(updateSpec.properties) ||
                     this.updateIpv6PageData(updateSpec.properties) ||
                     this.updateDnsPageData(updateSpec.properties)) {
                  this.mutationService.apply(hostId, updateSpec._type, updateSpec);
               }
               return true;
            }
         };
         this.clarityModalService.openOkCancelModal(modalOptions);
      }

      /****
       * Returns a ~deep clone of passed networkSettingsItem,
       * Used to not change/store local version of Network Settings,
       * while update is still running
       *
       * @param networkSettings instance you wish to clone
       */
      private clone(networkSettings: NetworkSettingsItem): NetworkSettingsItem {
         let clone = _.clone(networkSettings);
         clone.properties = _.clone(networkSettings.properties);
         clone.properties.ipv6properties = _.clone(networkSettings.properties.ipv6properties);
         return clone;
      }

      private updateIpv4PageData(updateProperties: InternetScsiHba$IPProperties): boolean {
         let isDirty = false;
         let model = this.dialogData.model;
         let properties: InternetScsiHba$IPProperties = model.properties;
         let capabilities = model.capabilities;
         let spec: InternetScsiHba$IPProperties = this.dialogData.spec.properties;

         if (!capabilities.ipv4EnableSettable && !properties.ipv4Enabled) {
            // The IPv4 is not enabled and cannot be enabled.
            return false;
         }
         if (!capabilities.ipConfigurationMethodSettable && properties.dhcpConfigurationEnabled) {
            // The IPv4 DHCP is enabled and cannot be disabled.
            return false;
         }

         if (capabilities.ipv4EnableSettable) {
            // Enable or disable the IPV4 configuration
            isDirty = spec.ipv4Enabled !== properties.ipv4Enabled;
            if (isDirty) {
               updateProperties.ipv4Enabled = properties.ipv4Enabled;
            }
            if (!properties.ipv4Enabled) {
               // If IPv4 is disabled, no further configuration is possible
               return isDirty;
            }
         }

         if (capabilities.ipConfigurationMethodSettable) {
            // Enable disable DHCP
            isDirty = isDirty || spec.dhcpConfigurationEnabled !== properties.dhcpConfigurationEnabled;
            if (isDirty) {
               // No matter if IPv4 is enabled or DHCP is dirty, we have to set the property
               updateProperties.dhcpConfigurationEnabled = properties.dhcpConfigurationEnabled;
            }
            if (properties.dhcpConfigurationEnabled) {
               return isDirty;
            }
         }

         // IPv4 address and subnet mask
         const isAddressOrSubmaskSettable = capabilities.subnetMaskSettable || capabilities.addressSettable;
         const isChangeRequired = updateProperties.ipv4Enabled === true
               || updateProperties.dhcpConfigurationEnabled === false;
         const isAddressAndMaskChangeRequired = spec.address !== properties.address
               || spec.subnetMask !== properties.subnetMask
               || isChangeRequired;
         if (isAddressOrSubmaskSettable && isAddressAndMaskChangeRequired) {
            // IP address and Subnet mask are always changed together
            updateProperties.address = properties.address;
            updateProperties.subnetMask = properties.subnetMask;
            isDirty = true;
         }

         // IPv4 default gateway address
         const isGwChangeRequired = spec.defaultGateway !== properties.defaultGateway
               || isChangeRequired;
         if (capabilities.defaultGatewaySettable && isGwChangeRequired) {
            updateProperties.defaultGateway = properties.defaultGateway;

            // on gateway change, the other fields must also be set.
            updateProperties.address = properties.address;
            updateProperties.subnetMask = properties.subnetMask;
            isDirty = true;
         }

         return isDirty;
      }

      private updateIpv6PageData(updateProperties: InternetScsiHba$IPProperties): boolean {
         let isDirty = false;
         let spec = this.dialogData.spec.properties;
         let isOnlyLinkLocal = this.dialogData.selectedOption === this.constants.IPv6Settings.LINK_LOCAL;
         let properties = this.dialogData.model.properties;
         let capabilities = this.dialogData.model.capabilities;
         let initialIProperties = spec.ipv6properties;
         let modifiedProperties = properties.ipv6properties;
         let propertiesToUpdate = updateProperties.ipv6properties;
         if (!capabilities.ipv6Supported) {
            return false;
         }
         if (!capabilities.ipv6EnableSettable && !properties.ipv6Enabled) {
            // The IPv6 is not enabled and cannot be enabled.
            return false;
         }
         // IPv6 has been disabled/enabled through UI
         if (capabilities.ipv6EnableSettable) {
            isDirty = spec.ipv6Enabled !== properties.ipv6Enabled;
            if(isDirty) {
               updateProperties.ipv6Enabled = properties.ipv6Enabled;
            }
            if (!properties.ipv6Enabled) {
               return isDirty;
            }
         }

         // DHCP
         if (capabilities.ipv6DhcpConfigurationSettable
               && initialIProperties.ipv6DhcpConfigurationEnabled !== modifiedProperties.ipv6DhcpConfigurationEnabled) {
            propertiesToUpdate.ipv6DhcpConfigurationEnabled = modifiedProperties.ipv6DhcpConfigurationEnabled;
            isDirty = true;
         }

         // Router advertisement
         if (capabilities.ipv6RouterAdvertisementConfigurationSettable
               && initialIProperties.ipv6RouterAdvertisementConfigurationEnabled !==
               modifiedProperties.ipv6RouterAdvertisementConfigurationEnabled) {
            propertiesToUpdate.ipv6RouterAdvertisementConfigurationEnabled =
                  modifiedProperties.ipv6RouterAdvertisementConfigurationEnabled;
            isDirty = true;
         }

         let linkLocalOverrideAddress: InternetScsiHba$IscsiIpv6Address|null = null;
         // Link-local override
         if (capabilities.ipv6LinkLocalAutoConfigurationSettable) {
            if (initialIProperties.ipv6LinkLocalAutoConfigurationEnabled !==
                  modifiedProperties.ipv6LinkLocalAutoConfigurationEnabled) {
               // This setting is changed.
               propertiesToUpdate.ipv6LinkLocalAutoConfigurationEnabled =
                     modifiedProperties.ipv6LinkLocalAutoConfigurationEnabled;
               isDirty = true;
            }

            const isLinkLocalOverrideDirty = this.dialogData.ipv6LinkLocalAddress !== this.hbaNetworkingUtil.getLinkLocalIPv6Address(spec);
            if (!modifiedProperties.ipv6LinkLocalAutoConfigurationEnabled && isLinkLocalOverrideDirty) {
               // if overriden - save address here
               linkLocalOverrideAddress = this.hbaNetworkingUtil.createIpv6(this.dialogData.ipv6LinkLocalAddress);
               propertiesToUpdate.iscsiIpv6Address = [linkLocalOverrideAddress];
               isDirty = isLinkLocalOverrideDirty;
            }
         }

         // Static addresses
         let automaticOptionSelected: boolean =
               modifiedProperties.ipv6DhcpConfigurationEnabled ||
               modifiedProperties.ipv6RouterAdvertisementConfigurationEnabled;
         let origStaticAddresses: InternetScsiHba$IscsiIpv6Address[] =
               this.getIPv6StaticAddressesList(spec);

         if (!automaticOptionSelected) {
            let isStaticAddressDirty = this.isStaticIpv6Dirty(modifiedProperties.iscsiIpv6Address, origStaticAddresses);
            isDirty = isDirty || isStaticAddressDirty;
            if (isStaticAddressDirty || isOnlyLinkLocal) {
               propertiesToUpdate.iscsiIpv6Address = propertiesToUpdate.iscsiIpv6Address || [];

               // mark old ones for removal
               _.each(origStaticAddresses, (address) => {
                  if (!_.find(modifiedProperties.iscsiIpv6Address, (existingAddress:InternetScsiHba$IscsiIpv6Address)=>
                        address.address === existingAddress.address)) {
                     address.operation = this.constants.IPv6Settings.OPERATION.REMOVE;
                     propertiesToUpdate.iscsiIpv6Address.push(address);
                  }
               });

               // mark new ones for addition
               _.each(modifiedProperties.iscsiIpv6Address, (address: any) => {
                  if (!_.find(origStaticAddresses, (newAddress:InternetScsiHba$IscsiIpv6Address)=>
                        address.address === newAddress.address)) {
                     address.operation = this.constants.IPv6Settings.OPERATION.ADD;
                     propertiesToUpdate.iscsiIpv6Address.push(address);
                  }
               });
            }
         }

         // IPv6 Gateway
         if (capabilities.ipv6DefaultGatewaySettable
               && initialIProperties.ipv6DefaultGateway !== modifiedProperties.ipv6DefaultGateway) {
            propertiesToUpdate.ipv6DefaultGateway = modifiedProperties.ipv6DefaultGateway;
            isDirty = true;
         }

         return isDirty;
      }

      private isStaticIpv6Dirty(current: InternetScsiHba$IscsiIpv6Address[],
                                old: InternetScsiHba$IscsiIpv6Address[]): boolean {
         //current array may have operations defined - we want to neglect them for this check
         current = _.pluck(_.sortBy(current, (address: InternetScsiHba$IscsiIpv6Address)=>address.address), "address");
         old = _.pluck(_.sortBy(old, (address: InternetScsiHba$IscsiIpv6Address)=>address.address), "address");
         return !_.isEqual(current, old);
      }

      private getIPv6StaticAddressesList(ipProperties: InternetScsiHba$IPProperties): InternetScsiHba$IscsiIpv6Address[] {
         let addressesList: InternetScsiHba$IscsiIpv6Address[] = [];
         if (!ipProperties || !ipProperties.ipv6properties || !ipProperties.ipv6properties.iscsiIpv6Address.length) {
            return addressesList;
         }
         // Add all static non link-local addresses.
         _.each(this.hbaNetworkingUtil.getIPv6AddressesOfType(ipProperties,
               this.constants.AddressConfigurationType.Static, true), (address: InternetScsiHba$IscsiIpv6Address) => {
            addressesList.push(address);
         });

         // Add all auto configured non link-local addresses, if routerAdv is enabled.
         if (ipProperties.ipv6properties.ipv6RouterAdvertisementConfigurationEnabled) {
            _.each(this.hbaNetworkingUtil.getIPv6AddressesOfType(ipProperties,
                  this.constants.AddressConfigurationType.AutoConfigured, true), (address: InternetScsiHba$IscsiIpv6Address) => {
               addressesList.push(address);
            });
         }

         // Add all dhcp non link-local addresses, if dhcp is enabled.
         if (ipProperties.ipv6properties.ipv6DhcpConfigurationEnabled) {
            _.each(this.hbaNetworkingUtil.getIPv6AddressesOfType(ipProperties,
                  this.constants.AddressConfigurationType.DHCP, true), (address: InternetScsiHba$IscsiIpv6Address) => {
               addressesList.push(address);
            });
         }

         return addressesList;
      }


      private updateDnsPageData(updateProperties: InternetScsiHba$IPProperties): boolean {
         let isDirty = false;
         let spec = this.dialogData.spec.properties;
         let model = this.dialogData.model.properties;
         let capabilities = this.dialogData.model.capabilities;

         // Primary DNS address
         if (capabilities.primaryDnsServerAddressSettable
               && model.primaryDnsServerAddress !== spec.primaryDnsServerAddress) {
            updateProperties.primaryDnsServerAddress = model.primaryDnsServerAddress;
            isDirty = true;
         }

         // Alternate DNS address
         if (capabilities.alternateDnsServerAddressSettable
               && model.alternateDnsServerAddress !== spec.alternateDnsServerAddress) {
            updateProperties.alternateDnsServerAddress = model.alternateDnsServerAddress;
            isDirty = true;
         }

         return isDirty;
      }
   }

   angular
         .module("com.vmware.vsphere.client.storage")
         .service("storageAdapterEditNetworkSettingsDialogService", StorageAdapterEditNetworkSettingsDialogService);
}
