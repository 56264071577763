/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
(function () {
   'use strict';
   angular
         .module('com.vmware.vsphere.client.dvs')
         .controller('DvPortgroupVlanPageController',
               DvPortgroupVlanPageController);

   DvPortgroupVlanPageController.$inject = [
      '$scope',
      'i18nService',
      'networkUiConstants',
      'dvpgPropertiesService',
      'dvPortgroupVlanPageService'];

   function DvPortgroupVlanPageController($scope,
         i18nService,
         networkUiConstants,
         dvpgPropertiesService,
         dvPortgroupVlanPageService) {

      $scope.manager.commitPageCallbacks.commitVlanPage = onCommit;

      function onCommit () {
         $scope.manager.pageDirtyFlags.isVlanPageDirty =
               hasVlanDataChanged();

         if ($scope.manager.pageDirtyFlags.isVlanPageDirty) {
            switch ($scope.manager.dvPortgroupData.vlanType) {
               case networkUiConstants.Vlan.Type.VLAN: {

                  var vlanIdError = dvpgPropertiesService.getVlanIdError(
                        $scope.manager.dvPortgroupData.vlanId);

                  if (vlanIdError) {
                     $scope.manager.showDialogErrors([vlanIdError]);
                     return false;
                  }

                  break;
               }
               case networkUiConstants.Vlan.Type.TRUNK: {
                  return dvPortgroupVlanPageService.getTrunkVlanRanges(
                        $scope.manager.dvPortgroupData.dvPortgroupId,
                        $scope.manager.dvPortgroupData.vlanTrunkRanges
                  ).then(function(numericRanges) {
                     $scope.manager.dvPortgroupData.vlanTrunkNumericRanges = numericRanges;

                     if (!numericRanges) {
                        $scope.manager.showDialogErrors([
                           i18nService.getString(
                                 'DvsUi', 'dvpg.policy.vlan.trunk.errorMessage')]);
                        return false;
                     }

                     return true;
                  });
               }
               case networkUiConstants.Vlan.Type.PRIVATE: {
                  if ($scope.manager.dvPortgroupData.showPvlanError) {
                     $scope.manager.showDialogErrors([
                        i18nService.getString(
                              'DvsUi', 'dvpg.policy.vlan.private.noConfig')]);
                     return false;
                  }

                  break;
               }
            }
         }
         return true;
      }

      function hasVlanDataChanged() {
         return $scope.manager.dvPortgroupData.vlanType !== $scope.initialData.vlanType ||
               $scope.manager.dvPortgroupData.vlanId !== $scope.initialData.vlanId ||
               $scope.manager.dvPortgroupData.vlanTrunkRanges !== $scope.initialData.vlanTrunkRanges ||
               $scope.manager.dvPortgroupData.pvlanId !== $scope.initialData.pvlanId;
      }

      return this;
   }
})();
