module h5_vm {

    import IRootScopeService = angular.IRootScopeService;
    import FtVmTurnOnManager = h5_vm.FtVmTurnOnManager;

    export interface VuiFtTurnOnWizard {
        show(pages: Array<any>, wizardScope: any, manager: FtVmTurnOnManager): void;
        markPageIncompleteWithError(message: string): void;
        markPageComplete(flow?: any): void;
    }

    class VuiFtTurnOnWizardImpl implements VuiFtTurnOnWizard {

        static $inject = [
            'wizardPageService',
            'vuiWizardService',
            '$rootScope',
            'i18nService',
            'mutationService'
        ];

        private wizardConfig: any;
        private wizardScope: any;

        constructor(private wizardPageService: any,
                    private vuiWizardService: any,
                    private $rootScope: any,
                    private i18nService: any,
                    private mutationService: any) {
        }

        public show(pages: Array<any>, wizardScope: any, manager: FtVmTurnOnManager): void {
            this.wizardConfig = {
                title: this.i18nService.getString('VmUi', 'turnOnFtWizard.title2', manager.getVmName()),
                pages: this.wizardPageService.initializePageStates(pages),
                resizeMinHeight: 500,
                onFinish: (): boolean => {
                    return true;
                }
            };

            this.wizardScope = wizardScope;
            this.wizardScope.manager = manager;
            this.wizardScope.wizardConfig = this.wizardConfig;

            this.vuiWizardService({
                scope: this.wizardScope,
                configObjectName: "wizardConfig"
            }).show();
        }

        public markPageIncompleteWithError(message: string): void {
            this.wizardPageService.markPageIncompleteWithError(this.wizardConfig, message);
        }

        public markPageComplete(flow?: any): void {
            this.wizardPageService.clearErrors(this.wizardConfig);
            this.wizardPageService.markPageComplete(this.wizardConfig, flow);
        }

    }

    angular.module("com.vmware.vsphere.client.vm")
        .service("vuiFtTurnOnWizard", VuiFtTurnOnWizardImpl);
}

