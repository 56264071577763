/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
(function () {
   'use strict';
   angular.module('com.vmware.vsphere.client.storage').factory('NfsWizardManager', [
         'i18nService', 'vxPropertyViewService', 'defaultUriSchemeUtil', 'mutationService',
         'datastoreConstants', 'storageValidationService', 'ipParserService', 'dataService', '$q',
      function (i18nService, vxPropertyViewService, defaultUriSchemeUtil,
            mutationService, datastoreConstants, storageValidationService, ipParserService,
            dataService, $q) {

         return function NfsWizardManager(commonWizardManager) {
            var state = {};

            var cache = {
               existingDsHostsPromise: null,
               existingDsId: null
            };

            var manager = {
               getNfsVersion: getNfsVersion,
               setNfsVersion: setNfsVersion,
               isNfsVersion41: isNfsVersion41,
               getFolderName: getFolderName,
               setFolderName: setFolderName,
               getServerName: getServerName,
               setSelectedAccessMode: setSelectedAccessMode,
               getSelectedAccessMode: getSelectedAccessMode,
               getServerNamesArray: getServerNamesArray,
               setServerNamesArray: setServerNamesArray,
               hasIpV6Servers: hasIpV6Servers,
               splitServerNames: splitServerNames,
               validateServerNames: validateServerNames,
               addServerNamesAction: addServerNamesAction,
               setServerName: setServerName,
               getExistingDatastoreId: getExistingDatastoreId,
               setExistingDatastoreId: setExistingDatastoreId,
               requestExistingDatastoreHosts: requestExistingDatastoreHosts,
               getSelectedHosts: getSelectedHosts,
               setSelectedHosts: setSelectedHosts,
               getKerberosAuthenticationType: getKerberosAuthenticationType,
               setKerberosAuthenticationType: setKerberosAuthenticationType,
               getReadyToCompletePageData: getReadyToCompletePageData,
               submit: submit
            };

            function getFolderName() {
               return state.folderName;
            }

            function setFolderName(name) {
               state.folderName = name;
            }

            function getServerName() {
               return state.serverName;
            }

            function setServerNamesArray (array) {
               state.nfs41ServerNames = array;
            }

            function getServerNamesArray () {
               return state.nfs41ServerNames || [];
            }

            function hasIpV6Servers () {
               if (getNfsVersion() === datastoreConstants.nfsVersions.NFS_4_1) {
                  var ipV6Server = _.find(getServerNamesArray(), function(item) {
                     return ipParserService.isIpv6AddressValid(item);
                  });
                  return !!ipV6Server;
               } else {
                  return ipParserService.isIpv6AddressValid(getServerName());
               }
            }

            function splitServerNames(namesString) {
               //trying to split
               return _.map(namesString.split(/[\s,;]+/), function(name) {
                  return name.trim();
               }).filter(function(name) {
                     return !/\s/.test(name) && name.length > 0;
               });
            }

            function addServerNamesAction() {
               var serverName = getServerName();
               if (isServerNameNullOrEmpty(serverName)) {
                  return;
               }

               var servers = splitServerNames(serverName);
               if (!servers || servers.length === 0) {
                  return;
               }

               // Add only those servers which haven't been already added.
               var serversToAdd = [];
               servers.forEach(function(server) {
                  if (!serverNameExists(server)) {
                     serversToAdd.push(server);
                  }
               });

               if (serversToAdd.length > 0) {
                  var currentServers = getServerNamesArray().concat(serversToAdd);
                  setServerNamesArray(currentServers);
               }
            }

            function validateServerNames() {
               var MAX_SERVER_NAME_LENGTH = 253;
               var inputFiledServerName = getServerName();
               var isEmptyInputFieldServerName = isServerNameNullOrEmpty(inputFiledServerName);
               var enteredServerNames = [];

               switch (getNfsVersion()) {
                  case datastoreConstants.nfsVersions.NFS_3: {
                     // Get the server name from the input field
                     if (!isEmptyInputFieldServerName) {
                        enteredServerNames = [inputFiledServerName];
                     }
                     break;
                  }
                  case datastoreConstants.nfsVersions.NFS_4_1: {
                     // Get server names from the servers grid.
                     enteredServerNames = getServerNamesArray();
                     if (!isEmptyInputFieldServerName) {
                        // Add the server names from the input field.
                        enteredServerNames = enteredServerNames.concat(
                              splitServerNames(inputFiledServerName));
                     }
                     break;
                  }
               }

               if (!enteredServerNames || enteredServerNames.length === 0) {
                  return i18nService.getString(
                        'StorageUi', 'addDatastoreWizard.nfsSettingsPage.serverListEmptyString');
               }

               var invalidServerNames = _.filter(enteredServerNames, function(name) {
                  var isInvalidName = !storageValidationService.isValidDnsName(name)
                        && !ipParserService.isIpv6AddressValid(name);
                  return name.length > MAX_SERVER_NAME_LENGTH || isInvalidName;
               });

               if (invalidServerNames && invalidServerNames.length > 0) {
                  return i18nService.getString(
                     'StorageUi', 'addDatastoreWizard.nfsServerName.invalid', invalidServerNames[0]);
               }

               return null;
            }

            function serverNameExists(name) {
               var existingServerNames = getServerNamesArray();
                if (existingServerNames) {
                   var serverItem = _.find(existingServerNames, function(item) {
                      return item === name;
                   });

                   return serverItem !== undefined;
                }
               return false;
            }

            function isServerNameNullOrEmpty(serverName) {
               return !serverName || serverName.trim() === '';
            }

            function setServerName(name) {
               state.serverName = name;
            }

            function getExistingDatastoreId() {
               return state.existingDatastoreId;
            }

            function setExistingDatastoreId(datastoreId) {
               state.existingDatastoreId = datastoreId;
            }

            function requestExistingDatastoreHosts() {
               if (!state.existingDatastoreId) {
                  return $q.when({});
               }

               if (!cache.existingDsHostsPromise || cache.existingDsId !== state.existingDatastoreId) {
                  cache.existingDsId = state.existingDatastoreId;
                  cache.existingDsHostsPromise = dataService.getProperties(
                     state.existingDatastoreId, ['hostKey']).then(function (response) {
                        var result = {};
                        if (response && response['hostKey']) {
                           _.forEach(response['hostKey'], function(hostRef) {
                              if (hostRef) {
                                 var hostId = defaultUriSchemeUtil.getVsphereObjectId(hostRef);
                                 if (hostId) {
                                    result[hostId] = true;
                                 }
                              }
                           });
                        }
                        return result;
                     });
               }

               return cache.existingDsHostsPromise;
            }

            function getReadyToCompletePageData() {
               var getString = _.partial(i18nService.getString, 'StorageUi');

               var builder = vxPropertyViewService.createPropertyViewBuilder();
               var category = builder.category('category');

               var generalSection = category.section('general').title(
                  getString('addDatastoreWizard.vmfsReadyToCompletePage.general'));

               generalSection.property(
                  getString('addDatastoreWizard.vmfsReadyToCompletePage.name'),
                  commonWizardManager.getDatastoreName());

               generalSection.property(
                  getString('addDatastoreWizard.vmfsReadyToCompletePage.type'),
                  getFormattedSelectedNfsVersion());

               var nfsSection = category.section('nfsSettings').title(
                  getString('addDatastoreWizard.nfsReadyToCompletePage.nfsSettings'));
               nfsSection.property(
                  getString('addDatastoreWizard.nfsSettingsPage.server'), formatServerName());
               nfsSection.property(
                  getString('addDatastoreWizard.nfsSettingsPage.folder'), getFolderName());
               nfsSection.property(
                  getString('addDatastoreWizard.nfsReadyToCompletePage.accessMode'),
                  getString('datastore.accessMode.' + getSelectedAccessMode()));
               if (isNfsVersion41()) {
                  nfsSection.property(
                     getString('addDatastoreWizard.nfsReadyToCompletePage.kerberos'),
                     getKerberosStatus());
               }

               if(!commonWizardManager.isHostContextObject()) {
                  var nfsHostsSection =  category.section('nfsHostsSettings').title(
                     getString('addDatastoreWizard.nfsReadyToCompletePage.hostsTitle'));

                  var propertyBuilder = nfsHostsSection.propertyBuilder();
                  propertyBuilder.keyBuilder()
                        .text(getString('addDatastoreWizard.nfsReadyToCompletePage.hosts'))
                        .exit();

                  getSelectedHosts().forEach(function(hostItem) {
                     propertyBuilder.valueBuilder()
                        .text(hostItem.formattedName)
                        .icon('vsphere-icon-host');
                  });
               }

               return builder.build();
            }

            function getKerberosStatus() {
               var securityType = getKerberosAuthenticationType();
               if (securityType === datastoreConstants.kerberosSecurityTypes.AUTH_SYS) {
                  return i18nService.getString("StorageUi", "addDatastoreWizard.nfsReadyToCompletePage.kerbDisabled");
               }
               if (securityType === datastoreConstants.kerberosSecurityTypes.SEC_KRB5){
                  return i18nService.getString("StorageUi", "datastore.manage.connectivity.hostList.kerberosAuthentication.enabledKrb5");
               }
               if (securityType === datastoreConstants.kerberosSecurityTypes.SEC_KRB5I){
                  return i18nService.getString("StorageUi", "datastore.manage.connectivity.hostList.kerberosAuthentication.enabledKrb5i");
               }
            }

            function getNfsVersion() {
               return state.nfsVersion? state.nfsVersion :
                  datastoreConstants.nfsVersions.NFS_3;
            }

            function isNfsVersion41() {
               return getNfsVersion() === datastoreConstants.nfsVersions.NFS_4_1;
            }

            function setNfsVersion(version) {
               state.nfsVersion = version;
            }

            function getSelectedHosts() {
               return state.selectedHosts;
            }

            function setSelectedHosts(selectedHosts) {
               state.selectedHosts = selectedHosts;
            }

            function getKerberosAuthenticationType() {
               return state.kerbereosAuthentication;
            }

            function setKerberosAuthenticationType(authType) {
               state.kerbereosAuthentication = authType;
            }

            function setSelectedAccessMode(mode) {
               state.accessMode = mode;
            }

            function getSelectedAccessMode() {
                return state.accessMode || datastoreConstants.accessModes.readWrite;
            }

            function getFormattedSelectedNfsVersion() {
               if (getNfsVersion() === datastoreConstants.nfsVersions.NFS_4_1) {
                  return i18nService.getString('StorageUi', 'datastore.nfs.nfs41');
               }
               return i18nService.getString('StorageUi', 'datastore.nfs.nfs');
            }

            function formatServerName () {
               if (getNfsVersion() === datastoreConstants.nfsVersions.NFS_4_1) {
                  return getServerNamesArray().join(', ');
               } else {
                  return getServerName();
               }
            }

            function submit() {
               var specs = [];

               if (commonWizardManager.isHostContextObject()) {
                  var hostRef = defaultUriSchemeUtil.getManagedObjectReference(
                     commonWizardManager.getContextObject());
                  specs.push(buildNfsDatastoreCreateSpec(hostRef));
               } else {
                  getSelectedHosts().forEach(function(hostItem) {
                     specs.push(buildNfsDatastoreCreateSpec(hostItem.hostRef));
                  });
               }

               if (specs.length > 0) {
                  mutationService.addMulti(
                     'com.vmware.vsphere.client.storage.NfsDatastoreCreateSpec', specs);
                  return true;
               }

               return false;
            }

            function buildNfsDatastoreCreateSpec(hostRef) {
               var createSpec = {
                  _type: 'com.vmware.vsphere.client.storage.NfsDatastoreCreateSpec',
                  host: hostRef,
                  storageFolder: commonWizardManager.getStorageFolder(),
                  nfsDatastoreCreateSpec: {
                     _type: "com.vmware.vim.binding.vim.host.NasVolume$Specification",
                     type: getNfsVersion().toUpperCase(),
                     remoteHost: getServerName(),
                     remotePath: getFolderName(),
                     localPath: commonWizardManager.getDatastoreName(),
                     accessMode: getSelectedAccessMode()
                  }
               };

               if (getNfsVersion() === datastoreConstants.nfsVersions.NFS_4_1) {
                  var serverNames = getServerNamesArray();
                  createSpec.nfsDatastoreCreateSpec.remoteHostNames = serverNames;
                  createSpec.nfsDatastoreCreateSpec.remoteHost = serverNames[serverNames.length - 1];
                  createSpec.nfsDatastoreCreateSpec.securityType = getKerberosAuthenticationType();
               }

               return createSpec;
            }

            return manager;
         };
      }
   ]);
})();
