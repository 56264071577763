namespace storage_ui {

   export interface StorageProviderSettingsValidationResult {
      result: boolean;
      errors: string[];
   }

   export class AddStorageProviderSettings {

      // Bindings
      public providerSettings: StorageProviderSettings;
      public providerNames: string[];
      public componentApi: AddStorageProviderSettingsApi;

      // Public fields
      public i18n: any;
      public validator: any;

      public PROVIDER_NAME_MAX_CHARS = 80;
      public USERNAME_MAX_CHARS = 255;
      public PASSWORD_MAX_CHARS = 255;

      public useCustomCertificate = false;
      public certificateFileLocation: string;

      public nameInputId: string;
      public urlInputId: string;
      public usernameInputId: string;
      public passwordInputId: string;
      public certificateLocationLabelId: string;

      private customCertificateFile: any;

      private INVALID_CHARACTERS = /!|@|#|%|\^|\&|\*/;

      public static $inject = ["i18nService", "$element", "$timeout", "vxValidatorFactory", "idGeneratorService"];

      constructor(private i18nService: any,
                  private $element: any,
                  private $timeout: any,
                  private vxValidatorFactory: any,
                  private idGeneratorService: any) {

         this.i18n = i18nService.getString;
         this.validator = this.vxValidatorFactory.create();
      }

      public $onInit() {
         if (this.componentApi) {
            this.componentApi.validate = this.validate.bind(this);
         }

         let ctrl = this;
         let certificateFileInput = this.$element.find(".storage-provider-certificate-upload-file-input");
         certificateFileInput.on("change", function (this: any, event: any) {
            if (event.target.files && event.target.files.length) {
               ctrl.setCertificateFile(event.target.files[0]);
            }
            this.value = null;
         });

         this.nameInputId = this.idGeneratorService.nextId();
         this.urlInputId = this.idGeneratorService.nextId();
         this.usernameInputId = this.idGeneratorService.nextId();
         this.passwordInputId = this.idGeneratorService.nextId();
         this.certificateLocationLabelId = this.idGeneratorService.nextId();
      }

      public setCertificateFile(file: File) {
         this.customCertificateFile = file;
         this.certificateFileLocation = file.name;

         this.updateCertificateFile();
      }

      public unsetCertificateFile() {
         if (this.certificateFileLocation) {
            this.customCertificateFile = null;
            this.certificateFileLocation = '';

            this.updateCertificateFile();
         }
      }

      public updateCertificateFile() {
         if (this.useCustomCertificate) {
            this.providerSettings.customCertificateFile = this.customCertificateFile;
         } else {
            this.providerSettings.customCertificateFile = null;
         }
      }

      public onBrowseCertificate() {
         let certificateFileInput = this.$element.find(".storage-provider-certificate-upload-file-input");
         this.$timeout(function () {
            certificateFileInput.click();
         });
      }

      private validate(): StorageProviderSettingsValidationResult {
         let validationErrors: string[] = this.validator.validate();

         return {
            result: !(validationErrors && validationErrors.length),
            errors: validationErrors
         };
      }

      private validateName = (inputValue: any): string[] => {
         let errors: string[] = [];

         if (!this.validateInvalidCharacters(inputValue)) {
            errors.push(this.i18n("StorageUi", "storage.storageProviders.newStorageProviderForm.invalidChars"));
         } else if (!this.validateIsProviderNameUnique(inputValue)) {
            errors.push(this.i18n("StorageUi", "storage.storageProviders.newStorageProviderForm.providerNameExists"));
         }

         return errors;
      }

      private validateUsername = (inputValue: any): string[] => {
         let errors: string[] = [];

         if (!this.validateInvalidCharacters(inputValue)) {
            errors.push(this.i18n("StorageUi", "storage.storageProviders.newStorageProviderForm.invalidChars"));
         }

         return errors;
      }

      private validateInvalidCharacters(inputValue: string): boolean {
         return !(inputValue && this.INVALID_CHARACTERS.test(inputValue));
      }

      private validateIsProviderNameUnique(inputValue: string): boolean {
         return !(this.providerNames && this.providerNames.indexOf(inputValue) >= 0);
      }
   }

   angular.module("com.vmware.vsphere.client.storage")
         .component("addStorageProviderSettings", {
            templateUrl: "storage-ui/resources/storage/views/storageProviders/actions/AddStorageProviderSettings.html",
            bindings: {
               providerSettings: "=",
               providerNames: "<",
               componentApi: "="
            },
            controller: AddStorageProviderSettings
         });
}
