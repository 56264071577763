(function () {
   'use strict';

   angular.module('com.vmware.vsphere.client.vm').service('deviceService', [
      'vmDeviceInfoService',
      'i18nService',
      'VmController',
      'virtualDeviceInflator',
      deviceService
   ]);
   function deviceService(vmDeviceInfoService, i18nService, VmController, virtualDeviceInflator) {
      var currentKey = -100;
      var keyTable = {};

      addLocalizationKey('VirtualUSB', 'NewVirtualUSB');
      addLocalizationKey('VirtualDisk', 'NewDisk');
      addLocalizationKey('VirtualEthernetCard', 'NewEthernet');
      addLocalizationKey('VirtualSCSIController', 'NewSCSIController');
      addLocalizationKey('VirtualSATAController', 'NewSATAController');
      addLocalizationKey('VirtualTPM', 'NewTPM');
      addLocalizationKey('ParaVirtualSCSIController', 'NewSCSIController');
      addLocalizationKey('VirtualUSBController', 'NewUSBController');
      addLocalizationKey('VirtualUSBXHCIController', 'NewUSBController');
      addLocalizationKey('VirtualNVMEController', 'NewVirtualNVMEController');
      addLocalizationKey('VirtualPCIPassthrough', 'NewPCIPassthrough');
      addLocalizationKey('VirtualNVDIMM', 'NewNVDIMM');
      addLocalizationKey('VirtualNVDIMMController', 'NewNVDIMMController');
      addLocalizationKey('VirtualSerialPort', 'NewSerialPort');

      return {
         setDeviceInfo: setDeviceInfo,
         newKey: newKey,
         calculateKey: calculateKey,
         makeController: makeController,
         findLocalizationKey: findLocalizationKey,
         addLocalizationKey: addLocalizationKey
      };

      function newKey() { return --currentKey; }

      /**
       * Calculates the smallest key, from which to start when adding a new device
       * Needed in case there are already new devices present, like for the case with
       * scheduled tasks
       * @param devices
       */
      function calculateKey(devices) {
         _.each(devices, function(device) {
            if (device.getKey() < currentKey) {
               currentKey = device.getKey();
            }
         });
      }

      function makeController(deviceOptions, controllerType, busNumber) {
         var vmController = VmController.createForType(deviceOptions, controllerType, newKey(), busNumber);
         var deviceOptionsByDeviceType = _.indexBy(deviceOptions, function(deviceOption){
            return deviceOption.type.name;
         });
         var inflatedController = virtualDeviceInflator.inflate(vmController.toJson(), {
            deviceOptionsByDeviceType: deviceOptionsByDeviceType
         });
         inflatedController = setDeviceInfo(inflatedController);
         return inflatedController;
      }

      function setDeviceInfo(inflatedDevice) {
         var device = inflatedDevice.getCurrentDevice();
         var localizationKey = findLocalizationKey(device);
         var deviceLabel = i18nService.getString('VmUi', 'VmDeviceManager.' + localizationKey);

         device.deviceInfo = {
            _type: 'com.vmware.vim.binding.vim.Description',
            label: deviceLabel,
            summary: deviceLabel
         };
         return inflatedDevice;
      }

      function addLocalizationKey(key, value) {
         keyTable[key] = value;
      }

      function findLocalizationKey(device) {
         var localizationKey = _.find(keyTable, function (value, key) {
            return vmDeviceInfoService.isDeviceSubclassOf(device, key);
         });
         if (localizationKey === undefined) {
            throw('no localization lookup for key for ' + device._type);
         }
         return localizationKey;
      }
   }
}());
