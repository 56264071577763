(function() {
   angular.module('com.vmware.vsphere.client.cluster').directive(
      'haApdResponse', haApdResponse);

   haApdResponse.$inject = ['i18nService', 'haApdResponseService',
      'haConstants', 'haVmReactionsOnApdClearedService', 'clusterConstants'];

   function haApdResponse(i18nService, haApdResponseService,
         haConstants, haVmReactionsOnApdClearedService, clusterConstants) {
      return {
         restrict: 'E',
         templateUrl: 'cluster-ui/resources/cluster/views/settings/services/ha/haApdResponse.html',
         scope: {
            haConfiguration: '=',
            storageApdSupported: '='
         },
         controller: ['$scope', function($scope) {
            $scope.isViewDisabled = function () {
               return !$scope.storageApdSupported ||
                  !$scope.haConfiguration.hostMonitoringEnabled ||
                  $scope.haConfiguration.defaultVmSettings.restartPriority ===
                  haConstants.restartPriority.DISABLED;
            };
            $scope.isApdRecoveryDelayDisabled = function() {
               return $scope.haConfiguration.vmcpSettings.apdResponse ===
                     haConstants.storageVmReaction.DISABLED ||
                     $scope.haConfiguration.vmcpSettings.apdResponse ===
                     haConstants.storageVmReaction.WARNING;
            };

            $scope.normalizeRecoveryDelay = function(event) {
               if (angular.isUndefined(event.target.value) ||
                     event.target.value < $scope.MIN_APD_RECOVERY_DELAY) {
                  $scope.haConfiguration.vmcpSettings.apdRecoveryDelayMinutes =
                        $scope.MIN_APD_RECOVERY_DELAY;
               }

               if (event.target.value > $scope.MAX_APD_RECOVERY_DELAY) {
                  $scope.haConfiguration.vmcpSettings.apdRecoveryDelayMinutes =
                        $scope.MAX_APD_RECOVERY_DELAY;
               }
            };

            $scope.apdResponses = haApdResponseService.getResponses();
            $scope.reactionsOnApdCleared = haVmReactionsOnApdClearedService.getReactions();
            $scope.responseForApdLabel = i18nService.getString('ClusterUi',
               'ha.config.failures.responses.ds.dswithapd.label');
            $scope.responseForApdSubmenuLabel = i18nService.getString('ClusterUi',
               'ha.config.failures.responses.ds.dswithapd.submenu.label');
            $scope.responseForApdDescription = i18nService.getString('ClusterUi',
               'ha.config.failures.responses.ds.dswithapd.desc');
            $scope.responseRecoveryLabel = i18nService.getString('ClusterUi',
               'ha.config.failures.responses.ds.dswithapd.recovery.submenu');
            $scope.responseDelayLabel = i18nService.getString('ClusterUi',
               'ha.config.failures.responses.ds.dswithapd.recovery.delay');
            $scope.minutesLabel = i18nService.getString('ClusterUi',
               'ha.config.failures.responses.ds.dswithapd.minutes');
            $scope.MIN_APD_RECOVERY_DELAY = 0;
            $scope.MAX_APD_RECOVERY_DELAY = Math.floor(clusterConstants.MAX_INTEGER /
                  haConstants.SECONDS_IN_A_MINUTE);
         }]
      };
   }
})();
