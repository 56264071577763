/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace dvs_ui {

   import DvsHealthCheckData =
         com.vmware.vsphere.client.h5.network.dvs.healthcheck.model.DvsHealthCheckData;
   import IPromise = angular.IPromise;
   import DvsHealthCheckItemData =
         com.vmware.vsphere.client.h5.network.dvs.healthcheck.model.DvsHealthCheckItemData;

   export class DvsHealthCheckMonitorViewComponent {

      public bindings: any;
      public templateUrl: string;
      public controller: any;

      constructor() {
         this.bindings = {
              healthCheckStatusData: "<"
         };
         this.templateUrl =
               "dvs-ui/resources/dvs/healthcheck/monitor/components/dvsHealthCheckMonitorViewTemplate.html";
         this.controller = DvsHealthCheckMonitorViewController;
      }
   }

   class DvsHealthCheckMonitorViewController {

      static $inject = [
         "$q",
         "$rootScope",
         "$scope",
         "i18nService",
         "dataService",
         "vuiConstants",
         "columnRenderersRegistry",
         "networkUiConstants",
         "vxPropertyViewService"
      ];

      static HEALTH_CHECK_DATA_PROPERTY_NAME =
            "dvsHealthCheckMonitor:dvsHealthCheckData";

      public datagridOptions: any;

      public headerOptions: any;

      public splitterOptions: any;

      public i18n: Function;

      public healthCheckLoading: boolean;

      public liveRefreshProperties: any;

      public dvsUri: string;

      public showNoItemSelected: boolean;

      public overallHealthCheckStatus: string;

      public overallHealthCheckStatusIcon: string;

      public vlanMtuHealthCheckEnablement: string;

      public teamingHealthCheckEnablement: string;

      public showWarningsLinkBtn: boolean;

      public signPostContent: any;

      public signPostCustomTriggerBtnLabel: string;

      public dvsHealthCheckItemsData: DvsHealthCheckItemData[];

      public propertyName: any;

      public propertyValueDictionary: any[];

      public constructor(private $q: any,
                         private $rootScope: any,
                         private $scope: any,
                         private i18nService: any,
                         private dataService: any,
                         private vuiConstants: any,
                         private columnRenderersRegistry: any,
                         private networkUiConstants: any) {

         this.i18n = this.i18nService.getString;
         this.createHealthCheckStatusDatagridOptions(this.dvsHealthCheckItemsData);
      }

      protected $onInit(): void {
         this.showNoItemSelected = true;
         this.refresh();
      }

      public $onChanges(): void {
         this.datagridOptions.data = this.dvsHealthCheckItemsData ?
               this.dvsHealthCheckItemsData : [];
         this.$scope.refreshDetails = true;
      }

      public refresh() {
         this.$scope.healthCheckLoading = true;
         this.$scope.refreshDetails = true;

         this.dvsUri = this.$rootScope._route.objectId;

         this.headerOptions = {
            title: this.i18nService.getString("DvsUi", "healthCheck.monitor.masterTitle"),
            objectId: this.dvsUri,
            actions: null
         };

         this.splitterOptions = {
            orientation: this.vuiConstants.splitter.orientation.VERTICAL,
            panes: [
               {
                  min: "150px",
                  size: "45%"
               }, {
                  min: "250px",
                  size: "55%"
               }
            ]
         };

         let dvsHealthCheckDataPromise: IPromise<DvsHealthCheckData> =
               this.dataService
                     .getProperties(this.dvsUri, [DvsHealthCheckMonitorViewController
                           .HEALTH_CHECK_DATA_PROPERTY_NAME]);

         dvsHealthCheckDataPromise
               .then((response: any) => {
                  if (response[DvsHealthCheckMonitorViewController
                              .HEALTH_CHECK_DATA_PROPERTY_NAME]) {

                     let dvsHealthCheckData: DvsHealthCheckData =
                           response[DvsHealthCheckMonitorViewController
                                 .HEALTH_CHECK_DATA_PROPERTY_NAME];

                     this.overallHealthCheckStatus =
                           dvsHealthCheckData.normalOverallHealthCheckStatus ?
                                 this.i18n("DvsUi", "healthCheck.monitor.status.normal") :
                                 this.i18n("DvsUi", "healthCheck.monitor.status.warning");
                     this.overallHealthCheckStatusIcon =
                           dvsHealthCheckData.overallHealthCheckStatusIcon;
                     this.showWarningsLinkBtn =
                           !dvsHealthCheckData.normalOverallHealthCheckStatus;
                     if (this.showWarningsLinkBtn) {
                        this.signPostContent =
                              this.buildSignPostContentView(dvsHealthCheckData);
                        this.signPostCustomTriggerBtnLabel =
                              this.i18n("DvsUi", "healthCheck.monitor.status.issues",
                                    dvsHealthCheckData.overallWarningsCount);
                     }

                     this.vlanMtuHealthCheckEnablement =
                           dvsHealthCheckData.vlanAndMtuHealthCheckEnabled ?
                                 this.i18n("DvsUi", "healthCheck.monitor.enabled") :
                                 this.i18n("DvsUi", "healthCheck.monitor.disabled");

                     this.teamingHealthCheckEnablement =
                        dvsHealthCheckData.teamingAndFailoverHealthCheckEnabled ?
                                 this.i18n("DvsUi", "healthCheck.monitor.enabled") :
                                 this.i18n("DvsUi", "healthCheck.monitor.disabled");

                     this.dvsHealthCheckItemsData =
                           dvsHealthCheckData.dvsHealthCheckItems ?
                                 dvsHealthCheckData.dvsHealthCheckItems : [];
                     this.createHealthCheckStatusDatagridOptions(this.dvsHealthCheckItemsData);

                     this.$scope.healthCheckLoading = false;
                  }
               });

         this.liveRefreshProperties = [
            "runtime",
            "config.healthCheckConfig"
         ];
      }

      private createHealthCheckStatusDatagridOptions(dvsHealthCheckItemsData: DvsHealthCheckItemData[]): void {
         this.datagridOptions = {
            columnDefs: this.createColumnDefinitions(),
            sortMode: this.vuiConstants.grid.sortMode.SINGLE,
            selectionMode: this.vuiConstants.grid.selectionMode.SINGLE,
            selectedItems: [],
            data: dvsHealthCheckItemsData,
            resizable: true,
            height: "100%",
            pageConfig: {
               hidePager: true
            },
            actionBarOptions: {
               actions: []
            },
            onChange: this.onSelectionChange.bind(this)
         };
      }

      private createColumnDefinitions(): any {

         let columnRenderer:any = this.columnRenderersRegistry
               .getColumnRenderer("object-link");

         return [{
            displayName: this.i18nService.getString(
                  "DvsUi", "healthCheck.monitor.grid.hostName.column"),
            field: "hostNameFormated",
            width: "150px",
            template: (data:any) => {
               return columnRenderer([undefined, "hostNameFormated",
                  data.hostIcon], data);
            }
         },
            {
               displayName: this.i18nService.getString(
                     "DvsUi", "healthCheck.monitor.grid.state.column"),
               field: "hostState",
               width: "150px"
            },
            {
               displayName: this.i18nService.getString(
                     "DvsUi", "healthCheck.monitor.grid.vdsStatus.column"),
               field: "dvsStatus",
               width: "150px",
               template: (data:any) => {
                  return columnRenderer([undefined, "dvsStatus",
                     data.dvsStatusIcon], data);
               }
            },
            {
               displayName: this.i18nService.getString(
                     "DvsUi", "healthCheck.monitor.grid.vlanHealthStatus.column"),
               field: "vlanHealthStatus",
               width: "150px",
               template: (data:any) => {
                  return columnRenderer([undefined, "vlanHealthStatus",
                     data.vlanHealthStatusIcon], data);
               }
            },
            {
               displayName: this.i18nService.getString(
                     "DvsUi", "healthCheck.monitor.grid.mtuHealthStatus.column"),
               field: "mtuHealthStatus",
               width: "150px",
               template: (data:any) => {
                  return columnRenderer([undefined, "mtuHealthStatus",
                     data.mtuHealthStatusIcon], data);
               }
            },
            {
               displayName: this.i18nService.getString(
                     "DvsUi", "healthCheck.monitor.grid.teamingHealthStatus.column"),
               field: "teamingAndFailoverHealthStatus",
               width: "150px",
               template: (data:any) => {
                  return columnRenderer([undefined, "teamingAndFailoverHealthStatus",
                     data.teamingAndFailoverHealthStatusIcon], data);
               }
            }];
      }

      public onSelectionChange(items: any[]): void {
         if (items && items.length > 0) {
            this.$scope.hostName = items[0].hostName;
            this.showNoItemSelected = false;
         } else {
            this.$scope.hostName = null;
            this.showNoItemSelected = true;
         }
      }

      public preselectComparator = (item: any): boolean => {
         return !!this.$scope.hostName &&
               this.$scope.hostName === item.hostName;
      };

      private buildSignPostContentView(dvsHealthCheckData: DvsHealthCheckData): any {

         this.propertyValueDictionary = [];
         this.propertyValueDictionary.push({
                  property: this.i18n(
                        "DvsUi", "healthCheck.monitor.signpost.disconnectedHosts"),
                  value: dvsHealthCheckData.disconnectedHostsCount
               },
               {
                  property: this.i18n(
                        "DvsUi", "healthCheck.monitor.signpost.outOfSyncHosts"),
                  value: dvsHealthCheckData.outOfSyncHostsCount
               },
               {
                  property: this.i18n(
                        "DvsUi", "healthCheck.monitor.signpost.unsupportedVlan"),
                  value: dvsHealthCheckData.unsupportedVlansCount
               },
               {
                  property: this.i18n(
                        "DvsUi", "healthCheck.monitor.signpost.mtuMismatch"),
                  value: dvsHealthCheckData.mtuMismatchCount
               },
               {
                  property: this.i18n(
                        "DvsUi", "healthCheck.monitor.signpost.ipHashMismatch"),
                  value: dvsHealthCheckData.ipHashMismatchCount
               });

         let rowElements: string [] = [];

         for (let propertyValuePair of this.propertyValueDictionary) {
            rowElements.push(`<div class="row">
               <div class="col-md-8">
                  <span class="nw-property-label-group">
                     ${propertyValuePair.property}
                  </span>
               </div>
               <div class="col-md-2">
                  <span class="nw-property-label-group">
                     ${propertyValuePair.value}
                  </span>
               </div>
            </div>`);
         }

         return rowElements.join(" ");
      }
   }

   angular.module("com.vmware.vsphere.client.dvs").component(
         "dvsHealthCheckMonitorView", new DvsHealthCheckMonitorViewComponent());
}
