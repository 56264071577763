namespace h5_vm {

   import MigrationReasonCode = com.vmware.vsphere.client.vm.migration.MigrationReasonCode;
   import MigrationType = com.vmware.vsphere.client.vm.migration.MigrationType;
   import ManagedObjectReference = com.vmware.vim.binding.vmodl.ManagedObjectReference;

   export class SelectMigrationTypePageComponent {
      public templateUrl: string;
      public controller: any;
      public controllerAs: string;
      public bindings: any;

      constructor() {
         this.templateUrl = "vm-ui/resources/vm/views/migrationWizard/pages/selectMigrationTypePageComponent.html";
         this.controller = SelectMigrationTypePageComponentController;
         this.controllerAs = "ctrl";
         this.bindings = {
            hostRef: "<",
            attributes: "<",
            wizardConfig: "<",
            migrationWizardManager: "<",
            pageModel: "<",
            migrationType: "<",
            destinationTarget: "<"
         };
      }
   } // SelectComputeResourceTreePageComponent

   class SelectMigrationTypePageComponentController {

      public static $inject: string[] = [
         "migrationTypeConstants",
         "wizardPageService",
         "dataService",
         "dataServiceUtil",
         "defaultUriSchemeUtil",
         "managedEntityConstants",
         "i18nService",
         "$element",
         "$timeout"
      ];

      //
      // Import parameters
      //
      public hostRef: ManagedObjectReference;
      public pageModel: SelectMigrationTypePageModel;
      public migrationWizardManager: MigrationWizardManager;
      public migrationType: string;
      public destinationTarget: string;
      public wizardConfig: any;
      public attributes: any;

      //
      // Used by the HTML page
      //
      public selectedMigrationType: string;
      public i18n: any;
      public flowAvailability: any = {};
      public issues: any = {
         changeResource: [],
         changeStorage: [],
         changeResourceAndStorage: [],
      };

      constructor(
         private migrationTypeConstants: any,
         private wizardPageService: any,
         private dataService: any,
         private dataServiceUtil: any,
         private defaultUriSchemeUtil: any,
         private managedEntityConstants: any,
         private i18nService: any,
         private $element: any,
         private $timeout: any
      ) {
         this.i18n = i18nService.getString;
      }

      public $onInit() {
         if (this.pageModel.cachedProperties) {
            this.onPropertiesReceived(this.pageModel.cachedProperties);
            this.checkXvcAvailability(this.pageModel.cachedProperties);
            return;
         }

         this.dataService.getPropertiesForObjects(this.pageModel.vms, ["vmMigrationOptions", "runtime.host"]).then(
            (properties: any) => {
               this.pageModel.cachedProperties = properties;
               this.onPropertiesReceived(properties);
               this.checkXvcAvailability(properties);
            });
      }

      private onPropertiesReceived(properties: {[vmId: string]: any}): void {
         this.processMigrationOptions(properties);
         this.selectedMigrationType = this.calculateMigrationType();
         this.onSelectionChanged();
         this.$timeout(() => {
            let firstInput = this.$element[0].querySelector(
                  "#selectMigrationTypePage input[value=" + this.selectedMigrationType + "]");
            if (firstInput) {
               firstInput.focus();
            }
         }, 0);
      }

      public onSelectionChanged(): void {
         this.wizardConfig.currentPage.decisionOptions.selectedFlowId = this.selectedMigrationType;
         this.attributes.creationType = this.selectedMigrationType;
         this.migrationWizardManager.setSelectedMode(this.selectedMigrationType);
         this.wizardPageService.initializeFlowStates(this.wizardConfig.flows);
      }

      private areAllHostsCompatible(properties: {[vmId: string]: any}): angular.IPromise<boolean> {
         const hosts: string[] = [];
         _.each(properties, (singleVmProps: any) => {
            if (!_.isEmpty(singleVmProps["runtime.host"])) {
               const hostId = this.defaultUriSchemeUtil.getVsphereObjectId(singleVmProps["runtime.host"]);
               if(hosts.indexOf(hostId) === -1) {
                  hosts.push(hostId);
               }
            }
         });
         // All VMs should be on ESX version 6.0 or later.
         return this.dataService.getPropertiesForObjects(hosts, ["config.product.version"], {skipLoadingNotification: true})
               .then((result: any) => {
                  return _.every(result, (properties: any) => {
                     return this.isEsx6OrLater(properties["config.product.version"]);
                  });
               });
      }

      private haveSameVcOrigin(vms: string[]): boolean {
         let firstGuid = this.defaultUriSchemeUtil.getPartsFromVsphereObjectId(
            vms[0]).serverGuid;
         return _.every(vms, (vm: string) => {
            let currentGuid = this.defaultUriSchemeUtil.getPartsFromVsphereObjectId(
                  vm).serverGuid;
            return currentGuid === firstGuid;
         });
      }

      private isEsx6OrLater(version: string): boolean {
         if (version === null) {
            return false;
         }

         if ("e.x.p" === version) {
            return true;
         }

         let digits = version.split(".");

         return (digits.length > 0 && Number(digits[0]) > 5);
      }

      // We do the check here, to have it ready on init of the compute resource tree page
      private checkXvcAvailability(properties: {[vmId: string]: any}):  void {
         this.pageModel.isXvcMigrationPossible = true;

         // All VMs should be on the same VC
         let hasSameOrigin = this.haveSameVcOrigin(this.pageModel.vms);
         if (!hasSameOrigin) {
            this.pageModel.isXvcMigrationPossible = false;
            return;
         }

         // All VMs should be on ESX version 6.0 or later.
         this.areAllHostsCompatible(properties).then(
            (areCompatible: boolean) => {
               if (!areCompatible) {
                  this.pageModel.isXvcMigrationPossible = false;
               }
            });
      }

      private processMigrationOptions(properties: any): void{
         this.flowAvailability.changeResourceOnly = true;
         this.flowAvailability.changeStorageOnly = true;
         this.flowAvailability.changeComputeResourceAndStorage = true;

         let reasons = {
            changeResource: [],
            changeStorage: [],
            changeResourceAndStorage: [],
         };

         // Collect the options available by ANDing the options for each VM
         // and collect the reasons for un-availability of an option.
         _.each(properties, (singleVmProps: any) => {
            if (!singleVmProps.vmMigrationOptions) {
               return;
            }
            _.each(singleVmProps.vmMigrationOptions.migrationAvailabilityOptions, (option: any) => {
               // Change compute resource only
               this.flowAvailability.changeResourceOnly =
                     this.processOption("host" as MigrationType, option,
                           reasons.changeResource) && this.flowAvailability.changeResourceOnly;

               // Change storage only
               this.flowAvailability.changeStorageOnly =
                     this.processOption("datastore" as MigrationType, option,
                           reasons.changeStorage) && this.flowAvailability.changeStorageOnly;

               // Change both compute resource and storage
               this.flowAvailability.changeComputeResourceAndStorage =
                     this.processOption("hostAndDatastore" as MigrationType, option,
                           reasons.changeResourceAndStorage) && this.flowAvailability.changeComputeResourceAndStorage;
            });
         });

         this.showReasons(reasons.changeResource, this.issues.changeResource);
         this.showReasons(reasons.changeStorage, this.issues.changeStorage);
         this.showReasons(reasons.changeResourceAndStorage, this.issues.changeResourceAndStorage);
      }

      private processOption(migrationType: MigrationType, option: any, reasons: Array<any>): boolean{
         let available = true;
         if (migrationType === option.type) {
            available = option.available;
            if (!available) {
               _.each(option.reasons, (reason: any) => {
                  if (!_.contains(reasons, reason)) {
                     reasons.push(reason);
                  }
               });
            }
         }
         return available;
      }

      private showReasons(reasons: Array<MigrationReasonCode>, container: any): void{
         _.each(reasons, (reason: MigrationReasonCode) => {
            container.push(this.getReasonText(reason));
         });
      }

      private getReasonText(reason: MigrationReasonCode){
         switch (reason) {
            case "hostInMaintenance" as MigrationReasonCode:
               return this.i18n('VmUi', "MigrationWizard.migrationTypePage.issue.hostInMaintenance");
            case "smpFt" as MigrationReasonCode:
               return this.i18n('VmUi', "MigrationWizard.migrationTypePage.issue.smpFt");
            case"ftSecondary" as MigrationReasonCode:
               return this.i18n('VmUi', "MigrationWizard.migrationTypePage.issue.ftSecondary");
            case "ftPrimaryNotInCorrectState" as MigrationReasonCode:
               return this.i18n('VmUi', "MigrationWizard.migrationTypePage.issue.ftPrimaryNotIncorrectState");
            case "noPrivilege" as MigrationReasonCode:
               return this.i18n('VmUi', "MigrationWizard.migrationTypePage.issue.noPrivilege");
         }
      }

      private calculateMigrationType(): string {
         // If the user already was on this page and picked something
         // Or if the wizard is loaded wit specified workflow (e.g from the Move To dialog)
         // We preselect it
         let preselectedType = this.migrationWizardManager.getSelectedMode();
         if (preselectedType && this.flowAvailability[preselectedType]) {
            return preselectedType;
         }

         // If we have a drag and drop target - we preselect the appropriate workflow
         if (this.destinationTarget) {
            let entityType = this.defaultUriSchemeUtil.getEntityType(this.destinationTarget);
            switch (entityType) {
               case this.managedEntityConstants.DATASTORE:
               case this.managedEntityConstants.STORAGE_POD:
                  // default target is a storage object - choose 'Change Storage' path.
                  if (this.flowAvailability.changeStorageOnly) {
                     return this.migrationTypeConstants.MIGRATE_STORAGE_ONLY;
                  }
                  break;
               default:
                  if (this.flowAvailability.changeResourceOnly) {
                     return this.migrationTypeConstants.MIGRATE_RESOURCE_ONLY;
                  }
            }
         }

         // No destination - let the default view choice, based on availability.
         if (this.flowAvailability.changeResourceOnly) {
            return this.migrationTypeConstants.MIGRATE_RESOURCE_ONLY;
         }
         if (this.flowAvailability.changeStorageOnly) {
            return this.migrationTypeConstants.MIGRATE_STORAGE_ONLY;
         }
         if (this.flowAvailability.changeComputeResourceAndStorage) {
            return this.migrationTypeConstants.MIGRATE_RESOURCE_AND_STORAGE;
         }

         return this.migrationTypeConstants.MIGRATE_RESOURCE_ONLY;
      }
   } // class

   angular.module("com.vmware.vsphere.client.vm").component(
         "selectMigrationTypePageComponent",
         new SelectMigrationTypePageComponent());

} // namespace

