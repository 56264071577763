namespace dvs_ui {

   import DvsPortMirroringSpec =
         com.vmware.vsphere.client.h5.network.dvs.portmirroring.model.DvsPortMirroringSpec;
   import DvsPortMirroringSessionData =
         com.vmware.vsphere.client.h5.network.dvs.portmirroring.model.DvsPortMirroringSessionData;

   export class DvsPortMirroringRemoveSessionService {

      public static readonly $inject = [
         "mutationService",
         "actionConfirmationService",
         "i18nService",
         "clarityModalService"
      ];

      constructor(private mutationService: any,
                  private actionConfirmationService: any,
                  private i18nService: any,
                  private clarityModalService: any) {

      }

      public removeSpanSession(availableTargets: string[], context: any): void {
         let DVS_SPAN_SESSOIN_SPEC_TYPE: string =
               "com.vmware.vsphere.client.h5.network.dvs.portmirroring.model.DvsPortMirroringSpec";

         let dvsSpanSessionSpec: DvsPortMirroringSpec = {
            _type: DVS_SPAN_SESSOIN_SPEC_TYPE,
            sessionKey: context.selectedSession.key
         };

         let submitFunc: any = () => {
            this.mutationService.apply(availableTargets[0],
                  dvsSpanSessionSpec._type, dvsSpanSessionSpec);
         };

         let modalOptions: any = {
            title: this.i18nService.getString("DvsUi",
                  "actionRemoveSpanSessionCaption"),
            message: this.i18nService.getString("DvsUi",
                  "actionRemoveSpanSessionWarning", context.selectedSession.name),
            submit: submitFunc,
            subTitle: {
               text:  context.selectedSession.name,
               objectId: availableTargets[0]
            },
            preserveNewlines: true,
            clarityIcon: {
               class: "is-warning",
               shape: "warning-standard",
               size: "32"
            }
         };

         this.clarityModalService.openConfirmationModal(modalOptions);
      }

   }

   angular.module("com.vmware.vsphere.client.dvs")
         .service("dvsPortMirroringRemoveSessionService",
         DvsPortMirroringRemoveSessionService);

}
