/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
(function () {
   'use strict';

   /**
    * `vnic adapter - Edit Settings` dialog manager.
    */
   angular
         .module('com.vmware.vsphere.client.network')
         .factory('editVmkernelAdapterDialogManager', editVmkernelAdapterDialogManager);

   editVmkernelAdapterDialogManager.$inject = [
         'i18nService',
         'hostVnicServiceConstants',
         'vnicConstants',
         'vuiConstants',
         'clarityModalService',
         '$q',
         'mutationService'];

   /**
    * Factory injection constructor.
    */
   function editVmkernelAdapterDialogManager(
         i18nService,
         hostVnicServiceConstants,
         vnicConstants,
         vuiConstants,
         clarityModalService,
         $q,
         mutationService) {

      /**
       * Factory instantiation constructor.
       */
      return function (vnicData, dialogOptions) {
         /**
          * Factory interface.
          */
         return {
            vnicData: vnicData,
            getPageDefinitions: getPageDefinitions,
            loadIpv6Page: loadIpv6Page,
            loadBindToPnicPage: loadBindToPnicPage,
            submit: submit
         };

         // region interface impl

         function getPageDefinitions() {
            var portPropertiesPage = {
               title: i18nService.getString('NetworkUi', 'VnicView.properties'),
               contentUrl: 'network-ui/resources/network/views/host/vnic/' +
                           'pages/editVnicPortPropertiesPage.html',
               onCommit: function () {
                  return vnicData.portPropertiesPageModel.onCommit();
               }
            };

            var ipv4Page = {
               title: i18nService.getString('NetworkUi', 'VnicView.ipv4Settings'),
               contentUrl: 'network-ui/resources/network/views/host/vnic/' +
                           'pages/editVnicIpv4Page.html',
               onCommit: function () {
                  return vnicData.ipv4PageModel.onCommit();
               }
            };

            return [portPropertiesPage, ipv4Page];
         }

         function loadIpv6Page () {
               var ipv6Page = {
                  title: i18nService.getString('NetworkUi', 'VnicView.ipv6Settings'),
                  contentUrl: 'network-ui/resources/network/views/host/vnic/' +
                  'pages/editVnicIpv6Page.html',
                  onCommit: function() {
                     return vnicData.ipv6PageModel.onCommit();
                  }
               };
               dialogOptions.pages.push(ipv6Page);
         }

         function loadBindToPnicPage() {
               var bindToPnicPage = {
                  title: i18nService.getString('NetworkUi',
                        'AddNetworkingWizard.physicalNicBindingPage.title'),
                  contentUrl: 'network-ui/resources/network/views/host/vnic/' +
                  'pages/editBindToPhysicalAdapterPage.html',
                  onCommit: function() {
                     return vnicData.bindToPnicPageModel.onCommit();
                  }
               };
               dialogOptions.pages.push(bindToPnicPage);
         }

         function submit() {
            if (!ipSettingsEnabled()) {
               dialogOptions.validationBanner.messages = [];

               dialogOptions.validationBanner.messages.push({
                  text: i18nService.getString('NetworkUi', 'EditVnicView.noIpSettingsError'),
                  type: vuiConstants.validationBanner.type.ERROR
               });
               return false;
            }

            var vnicEditSpec = createEditVnicSpec();
            var ipConfig = createIpConfig();
            var ipRouteSpec = createIpRouteSpec();

            if (ipConfig !== null) {
               _.extend(vnicEditSpec.spec, {
                  ip: ipConfig
               });
            }

            if (vnicData.ipv6PageModel.dirty) {
               var ipv6Config = createIpv6Config(true);

               if (vnicEditSpec.spec.ip === null) {
                  vnicEditSpec.spec.ip = createEmptyIpConfig();
               }

               _.extend(vnicEditSpec.spec.ip, { ipV6Config: ipv6Config });
            }

            if (ipRouteSpec !== null) {
               _.extend(vnicEditSpec.spec, {
                  ipRouteSpec: ipRouteSpec
               });
            }

            var portsDirty = vnicData.portPropertiesPageModel.dirty
                  || vnicEditSpec.changedVmkSettings.length > 0;

            var ipv4Dirty = vnicData.ipv4PageModel.dirty;
            var ipv6Dirty = vnicData.ipv6PageModel.dirty;

            var bindToPnicDirty = vnicData.bindToPnicPageModel.dirty;

            if (portsDirty || ipv4Dirty || ipv6Dirty || bindToPnicDirty) {
               return validateVnic(vnicEditSpec)
                  .then(function (validationResult) {
                     if (validationResult) {
                        mutationService.apply(
                           vnicData.hostId,
                           vnicEditSpec._type,
                           vnicEditSpec);
                     }
                     return validationResult;
                  });
            } else {
               return true;
            }
         }

         // endregion

         // region private helpers

         function createEditVnicSpec() {
            var keys = hostVnicServiceConstants.keys;
            var keyToNicTypeMap = vnicConstants.serviceKeyToNicTypeMap;

            var vnicEditSpec = _.pick(
                  vnicData.portPropertiesPageModel.hostVnicSettings,
                  keys.VMOTION,
                  keys.PROVISIONING,
                  keys.FT_LOGGING,
                  keys.MANAGEMENT,
                  keys.VR,
                  keys.VR_NFC,
                  keys.VSAN
            );

            vnicEditSpec._type = 'com.vmware.vsphere.client.modules.api.network.VirtualNicEditSpec';
            vnicEditSpec.spec = {
               _type: 'com.vmware.vim.binding.vim.host.VirtualNic$Specification'
            };

            if (vnicData.portPropertiesPageModel.dirty) {
               vnicEditSpec.spec.mtu = vnicData.portPropertiesPageModel.mtuInBytes;
            }

            if (vnicData.bindToPnicPageModel.dirty) {
               if (vnicData.bindToPnicPageModel.componentData.bindToPnicEnabled) {
                  vnicEditSpec.spec.pinnedPnic =
                        vnicData.bindToPnicPageModel.componentData.selectedPnicForBinding;
               } else {
                  // Set the pinnedPnic to empty string
                  // in order to remove the pinned physical adapter
                  vnicEditSpec.spec.pinnedPnic = "";
               }
            }

            // The name is required even though it is read-only
            vnicEditSpec.virtualNicName = vnicData.vnicDevice;

            vnicEditSpec.changedVmkSettings = [];
            _.forEach(vnicData.portPropertiesPageModel.hostVnicSettings,
                  function(setting, key) {
                     if (setting !== vnicData.portPropertiesPageModel
                           .initialHostVnicSettings[key]) {
                        vnicEditSpec.changedVmkSettings.push(keyToNicTypeMap[key]);
                     }
                  });

            return vnicEditSpec;

         }

         /**
          * Create part of vnic edit spec based on IPv4 settings page.
          */
         function createIpConfig() {
            if (!vnicData.ipv4PageModel.dirty && !vnicData.ipv6PageModel.dirty) {
               return null;
            }

            var ipOption = vnicConstants.ipSettingsOption;

            var ipConfig = createEmptyIpConfig();
            ipConfig.dhcp = vnicData.ipv4PageModel.ipSettingsOption === ipOption.dhcp;

            if (vnicData.ipv4PageModel.ipSettingsOption === ipOption.unset) {
               ipConfig.ipAddress = '';
               ipConfig.subnetMask = '';
               // IPv6 Config should be set because it is required by the updateVnic API.
               ipConfig.ipV6Config = createIpv6Config(true /* isIpv6ConfigSet */);
            } else if (vnicData.ipv4PageModel.ipSettingsOption === ipOption.staticIp) {
               ipConfig.ipAddress = vnicData.ipv4PageModel.ipAddress;
               ipConfig.subnetMask = vnicData.ipv4PageModel.subnetMask;
            }

            // IPv6 Config should be set when Ipv6 is enabled on host.
            if (vnicData.ipv4PageModel.ipv6Enabled) {
               ipConfig.ipV6Config = createIpv6Config();
            }

            return ipConfig;
         }

         /**
          * Create part of vnic edit spec that assigns
          * ipv4 and ipv6 default gateways
          */
         function createIpRouteSpec() {
            if (!vnicData.ipv4PageModel.isGatewayFeatureSupported
                  || (!vnicData.ipv4PageModel.dirty && !vnicData.ipv6PageModel.dirty)) {
               return null;
            }

            var ipRouteSpec = createEmptyIpRouteSpec();

            if (vnicData.ipv4PageModel.ipSettingsOption
                  === vnicConstants.ipSettingsOption.staticIp
                  && vnicData.ipv4PageModel.isGatewayOverriden) {
               ipRouteSpec.ipRouteConfig.defaultGateway =
                     vnicData.ipv4PageModel.defaultGateway;
            } else {
               ipRouteSpec.ipRouteConfig.defaultGateway = null;
            }

            // in order to preserve the ipv6 default gateway value from the vnic
            if (vnicData.ipv4PageModel.ipv6Enabled
                  && vnicData.ipv6PageModel.staticIpEnabled
                  && vnicData.ipv6PageModel.isGatewayOverridden) {
               ipRouteSpec.ipRouteConfig.ipV6DefaultGateway =
                     vnicData.ipv6PageModel.defaultGateway;
            } else {
               ipRouteSpec.ipRouteConfig.ipV6DefaultGateway = null;
            }

            return ipRouteSpec;
         }

         function createIpv6Config(isIpv6ConfigSet) {
            return {
               _type: 'com.vmware.vim.binding.vim.host.IpConfig$IpV6AddressConfiguration',
               autoConfigurationEnabled: vnicData.ipv6PageModel.autoConfigEnabled,
               dhcpV6Enabled: vnicData.ipv6PageModel.dhcpEnabled,
               ipV6Address: isIpv6ConfigSet ? createIpv6Addresses() : []
            };
         }

         function createIpv6Addresses() {
            var ipAddresses = vnicData.ipv6PageModel.staticIpv6Addresses;
            var isStaticIpEnabled = vnicData.ipv6PageModel.staticIpEnabled;

            return _.map(ipAddresses, function(ipAddress) {
               return {
                  _type: 'com.vmware.vim.binding.vim.host.IpConfig$IpV6Address',
                  ipAddress: ipAddress.address,
                  prefixLength: ipAddress.prefixLength,
                  operation: (isStaticIpEnabled)
                        ? ipAddress.operation
                        : vnicConstants.changeOperation.remove
               };
            });
         }

         function createEmptyIpConfig() {
            return {
               _type: 'com.vmware.vim.binding.vim.host.IpConfig'
            };
         }

         function ipSettingsEnabled () {
            var staticIp = vnicConstants.ipSettingsOption.staticIp;
            var dhcp = vnicConstants.ipSettingsOption.dhcp;

            return vnicData.ipv4PageModel.ipSettingsOption === staticIp ||
                   vnicData.ipv4PageModel.ipSettingsOption === dhcp ||
                   vnicData.ipv6PageModel.autoConfigEnabled ||
                   vnicData.ipv6PageModel.dhcpEnabled ||
                   vnicData.ipv6PageModel.staticIpEnabled;
         }

         function createEmptyIpRouteSpec() {
            return {
               _type: 'com.vmware.vim.binding.vim.host.VirtualNic$IpRouteSpec',
               ipRouteConfig: {
                  _type: 'com.vmware.vim.binding.vim.host.IpRouteConfig'
               }
            };
         }

         function validateVnic(vnicEditSpec) {
            //display a progress bar while validating the vmknic settings
            dialogOptions.loading = true;
            dialogOptions.loadingMessage =
               i18nService.getString('CommonUi', 'wizard.validating');

            var deferred = $q.defer();

            mutationService
               .validate(vnicData.hostId, vnicEditSpec._type, vnicEditSpec)
               .then(function (validationResult) {
                  if (!!validationResult.result) {
                     var modalOptions = {
                        title: i18nService.getString(
                           'H5NetworkUi', 'EditVMkernel.confirmEditTitle'),
                        message: validationResult.result,
                        clarityIcon: {
                           class: "is-warning",
                           shape: "warning-standard",
                           size: "32"
                        },
                        preserveNewlines: true,
                        submit: function () {
                           deferred.resolve(true);
                        },
                        onCancel: function() {
                           deferred.resolve(false);
                        }
                     };
                     clarityModalService.openConfirmationModal(modalOptions);
                  } else {
                     deferred.resolve(true);
                  }
               })
               .finally(function() {
                  dialogOptions.loading = false;
               });
            return deferred.promise;
         }

         // endregion
      };
   }
})();