/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
(function () {
   'use strict';
   angular.module('com.vmware.vsphere.client.network')
         .service('ipv6SettingsPageValidator',
               Ipv6SettingsPageValidator);

   Ipv6SettingsPageValidator.$inject = ['i18nService', 'ipParserService'];

   function Ipv6SettingsPageValidator(i18nService, ipParserService) {

      this.getValidationErrors = function (ipv6DhcpEnabled,
                                           routerAdvertisementEnabled,
                                           staticAddressesEnabled,
                                           ipv6AddressesArrLength,
                                           overrideIpv6Gateway,
                                           ipv6DefaultGateway) {
         var validationErrors = [];
         if (staticAddressesEnabled) {
            if (!ipv6AddressesArrLength) {
               validationErrors.push(i18nService.getString('NetworkUi',
                     'AddNetworkingWizard.ipv6Settings.noIpv6ManualAddressError'));
            }

            if (overrideIpv6Gateway) {
               // check if the configured default gateway is empty or invalid ipv6 address
               if (!ipParserService.isIpv6AddressValid(ipv6DefaultGateway)) {
                  validationErrors.push(i18nService.getString(
                        'NetworkUi', 'Ipv6GatewayDialog.defaultGateway.error'));
               }
               if (ipv6DhcpEnabled || routerAdvertisementEnabled) {
                  validationErrors.push(i18nService.getString('H5NetworkUi',
                        'VnicIpv6SettingsPage.invalidIpConfiguration'));
               }
            }
         } else if (!ipv6DhcpEnabled && !routerAdvertisementEnabled) {
            validationErrors.push(i18nService.getString('NetworkUi',
                  'AddNetworkingWizard.ipv6Settings.noIpv6SettingsError'));
         }
         return validationErrors;
      };
   }
})();
