/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace dvs_ui {

   import IPromise = angular.IPromise;

   export class DvsPortNetworkConnectionComponent {

      public controller: any;
      public bindings: any;
      public templateUrl: string;

      constructor() {
         this.bindings = {
            selectedPort: "<"
         };
         this.controller = DvsPortNetworkConnectionController;
         this.templateUrl =
               "dvs-ui/resources/dvs/ports/networkConnection/dvsPortNetworkConnectionViewTemplate.html";
      }
   }

   class DvsPortNetworkConnectionController {

      static $inject = [
         "$scope",
         "i18nService",
         "dvsPortsService"
      ];

      public i18n: Function;

      public selectedPort: any;

      public dvsPortNetworkConnectionData: any;

      constructor(private $scope: any,
                  private i18nService: any,
                  private dvsPortsService: any) {

         this.i18n = this.i18nService.getString;
      }

      public $onChanges(): void {
         this.refresh(this.selectedPort);
      }

      public refresh(selectedPort: any) {
         this.dvsPortNetworkConnectionData =
               this.dvsPortsService.buildDvsPortNetworkConnectionPropertiesView(selectedPort);
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .component("dvsPortNetworkConnectionView", new DvsPortNetworkConnectionComponent());
}
