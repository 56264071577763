namespace storage_ui {

   export class AddSoftwareAdapterControl {
      public bindings: any;
      public controller: any;
      public templateUrl: string;

      constructor() {
         this.bindings = {
            hostId: "<",
            apiObj: "="
         };
         this.controller = AddSoftwareAdapterControlController;
         this.templateUrl = "storage-ui/resources/storage/views/host/adapters/add/AddSoftwareAdapterControl.html";
      }
   }

   export class MutationOperationSpec {
      public spec: any;
      public operationName: string|null;
   }

   export class AddSoftwareAdapterControlApi {
      public validate: () => boolean;
      public createSpec: () => MutationOperationSpec;
      public isSwBasedIscsiAdapter: () => boolean;
   }

   class AddSoftwareAdapterControlController {

      // Bindings
      public hostId: string;
      public apiObj: AddSoftwareAdapterControlApi;

      // Public methods
      public i18n: any;
      public loading: boolean;

      public canAddFcoeAdapter: boolean;
      public canAddIscsiAdapter: boolean;

      public pnicDevices: any[];
      public selectedPnic: any;

      public selectedAdapterType: string;

      public fcoeSettingsValidator: any;

      // FCoE settings
      public vlanId: number|undefined;
      public vlanIdEnabled: boolean;
      public priorityClass: number|undefined;
      public priorityClassEnabled: boolean;
      public macAddressText: string|undefined;
      public macAddressTextEnabled: boolean;

      // Const
      public readonly ISCSI_ADAPTER_TYPE = "ISCSI_ADAPTER_TYPE";
      public readonly FCOE_ADAPTER_TYPE = "FCOE_ADAPTER_TYPE";
      public readonly VLAN_ID_MIN = 0;
      public readonly VLAN_ID_MAX = 4094;
      public readonly PRIORITY_CLASS_MIN = 0;
      public readonly PRIORITY_CLASS_MAX = 7;

      private readonly SOFTWARE_INTERNET_SCSI_ENABLED = "config.storageDevice.softwareInternetScsiEnabled";
      private readonly FCOE_CAPABLE_PNICS_PROP = "fcoeCapablePnics";

      public static $inject = ["i18nService", "clarityConstants", "dataService", "vxValidatorFactory", "$timeout"];

      constructor(private i18nService: any,
                  private clarityConstants:any,
                  private dataService: any,
                  private vxValidatorFactory: any,
                  private $timeout: any) {

         this.i18n = this.i18nService.getString;

         this.fcoeSettingsValidator = this.vxValidatorFactory.create();
      }

      public $onInit() {
         if (this.apiObj) {
            this.apiObj.validate = this.validate.bind(this);
            this.apiObj.createSpec = this.createSpec.bind(this);
            this.apiObj.isSwBasedIscsiAdapter = this.isSwBasedIscsiAdapter.bind(this);
         }

         this.requestPageData();
      }

      public onPnicDeviceChange() {
         if (!this.selectedPnic || !this.selectedPnic.fcoeConfiguration) {
            this.vlanIdEnabled = false;
            this.priorityClassEnabled = false;
            this.macAddressTextEnabled = false;

            this.vlanId = undefined;
            this.priorityClass = undefined;
            this.macAddressText = undefined;

            return;
         }

         let fcoeConf = this.selectedPnic.fcoeConfiguration;
         if (fcoeConf.vlanRange && fcoeConf.vlanRange.length) {
            this.vlanId = this.selectedPnic.fcoeConfiguration.vlanRange[0].vlanLow;
         } else {
            this.vlanId = undefined;
         }
         this.priorityClass = fcoeConf.priorityClass;
         this.macAddressText = fcoeConf.sourceMac;

         this.vlanIdEnabled = fcoeConf.capabilities && fcoeConf.capabilities.vlanRange;
         this.priorityClassEnabled = fcoeConf.capabilities && fcoeConf.capabilities.priorityClass;
         this.macAddressTextEnabled = fcoeConf.capabilities && fcoeConf.capabilities.sourceMacAddress;
      }

      private requestPageData() {

         this.loading = true;
         this.canAddFcoeAdapter = false;
         this.canAddIscsiAdapter = false;

         this.dataService.getProperties(
               this.hostId,
               [this.SOFTWARE_INTERNET_SCSI_ENABLED, this.FCOE_CAPABLE_PNICS_PROP]).then((response:any) => {

            if (!response) {
               return;
            }

            if (response[this.SOFTWARE_INTERNET_SCSI_ENABLED] === false) {
               this.canAddIscsiAdapter = true;
               this.selectedAdapterType = this.ISCSI_ADAPTER_TYPE;
            }

            this.pnicDevices = response[this.FCOE_CAPABLE_PNICS_PROP];

            if (this.pnicDevices && this.pnicDevices.length) {
               this.canAddFcoeAdapter = true;
               this.selectedAdapterType = this.selectedAdapterType || this.FCOE_ADAPTER_TYPE;
               this.selectedPnic = this.pnicDevices[0];
               this.onPnicDeviceChange();
            }

            this.focusRadio();

         }).finally(() => {
            this.loading = false;
         });
      }

      public createSpec(): MutationOperationSpec {
         let operationSpec = new MutationOperationSpec();
         if (this.selectedAdapterType === this.ISCSI_ADAPTER_TYPE) {
            operationSpec.spec = {
               _type: "com.vmware.vsphere.client.storage.adapters.InternetScsiStorageAdapterActivationSpec",
               activate: true
            };
         } else if (this.selectedAdapterType === this.FCOE_ADAPTER_TYPE) {
            operationSpec.spec = this.createFCoESpec();
            operationSpec.operationName = this.i18n("StorageUi", "storage.adapters.actions.addAdapter.fcoe.taskName");
         }

         return operationSpec;
      }

      public isSwBasedIscsiAdapter(): boolean {
         return this.selectedAdapterType === this.ISCSI_ADAPTER_TYPE;
      }

      private createFCoESpec(): any {
         let fcoeSpec: any = {
            _type: "com.vmware.vim.binding.vim.host.FcoeConfig$FcoeSpecification"
         };
         fcoeSpec.underlyingPnic = this.selectedPnic.device;
         if (this.vlanIdEnabled) {
            fcoeSpec.vlanRange = [{
               _type: "com.vmware.vim.binding.vim.host.FcoeConfig$VlanRange",
               vlanHigh: this.vlanId,
               vlanLow: this.vlanId
            }];
         }

         if (this.priorityClassEnabled) {
            fcoeSpec.priorityClass = this.priorityClass;
         }

         if (this.macAddressTextEnabled) {
            fcoeSpec.sourceMac = this.macAddressText;
         }

         return {
            _type: "com.vmware.vsphere.client.storage.adapters.FcoeStorageAdapterCreationSpec",
            fcoeSpec: fcoeSpec
         };
      }

      private isFloatValue(value: number): boolean {
         return !!(value % 1);
      }

      private isValidValue(value: any, minValue: number, maxValue: number) {
         let numericValue = Number(value);
         if (!value || isNaN(numericValue)
               || numericValue < minValue || numericValue > maxValue
               || this.isFloatValue(numericValue)) {
            return [this.i18n("StorageUi", "invalidIntegerValue.outOfRange", minValue, maxValue)];
         }

         return [];
      }

      private focusRadio(): void {
         const adapterTypeRadios: any = angular.element("input[type='radio'][name='adapterType']");
         if (adapterTypeRadios && adapterTypeRadios[0]
               && this.selectedAdapterType === this.ISCSI_ADAPTER_TYPE) {
            // Resolve all bindings (enable radios first) and then focus.
            this.$timeout(() => {
               adapterTypeRadios[0].focus();
            }, 0);
         } else if (adapterTypeRadios && adapterTypeRadios[1]
               && this.selectedAdapterType === this.FCOE_ADAPTER_TYPE) {
            // Resolve all bindings (enable radios first) and then focus.
            this.$timeout(() => {
               adapterTypeRadios[1].focus();
            }, 0);
         }
      }

      public validateVlanId = (value:any) => {
         if (!this.vlanIdEnabled) {
            return [];
         }
         return this.isValidValue(value, this.VLAN_ID_MIN, this.VLAN_ID_MAX);
      };

      public validatePriorityClass = (value:any) => {
         if (!this.priorityClassEnabled) {
            return [];
         }
         return this.isValidValue(value, this.PRIORITY_CLASS_MIN, this.PRIORITY_CLASS_MAX);
      };

      public validateMacAddressText = (value:any) => {
         if (this.macAddressTextEnabled && !value) {
            return [this.i18n("Common", "vxValidation.emptyFieldError")];
         }
         return [];
      };

      public validate(): boolean {
         if (!this.selectedAdapterType) {
            return false;
         }

         if (this.selectedAdapterType === this.ISCSI_ADAPTER_TYPE) {
            return true;
         }

         return !this.fcoeSettingsValidator.validate().length;
      }
   }

   angular.module("com.vmware.vsphere.client.storage")
         .component("addSoftwareAdapterControl", new AddSoftwareAdapterControl());
}
