/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace dvs_ui {

   export interface DvsAddHostWizardScope extends ng.IScope {
      model: DvsAddHostWizardModel;
      wizardConfig: WizardConfig;
   }

   export interface DvsImportWizardScope extends ng.IScope {
      model: DvsImportWizardModel;
      wizardConfig: WizardConfig;
   }

   export interface DvpgCreateWizardScope extends ng.IScope {
      model: DvpgCreateWizardModel;
      flags: DvpgCreateWizardFlags;
      wizardConfig: WizardConfig;
   }

   export interface DvsCreateWizardScope extends ng.IScope {
      model: DvsCreateWizardModel;
      wizardConfig: WizardConfig;
   }

   export interface DvsUpgradeWizardScope extends ng.IScope {
      model: DvsUpgradeWizardModel;
      wizardConfig: WizardConfig;
   }

   export interface DvsUpgradeNiocWizardScope extends ng.IScope {
      model: DvsUpgradeNiocWizardModel;
      wizardConfig: WizardConfig;
   }

   export interface DvsUpgradeLacpWizardScope extends ng.IScope {
      model: DvsUpgradeLacpWizardModel;
      wizardConfig: WizardConfig;
   }

   export interface DvsAddSpanSessionWizardScope extends ng.IScope {
      model :DvsAddSpanSessionWizardModel;
      wizardConfig: WizardConfig;
      busy: boolean;
   }

   export interface DvpgManageWizardScope extends ng.IScope {
      model: DvpgManageWizardModel;
      wizardConfig: WizardConfig;
   }

   export interface DvpgCreateWizardPageValidator {
      getValidationError(wizardScope: DvpgCreateWizardScope): string[];
      getInvalidNameError(name: string): any;
   }

   export interface WizardPageValidator {
      getValidationError(wizardScope: ng.IScope): string[];
   }

   export class WizardConfig {
      title: string;
      loadingMessage: string;
      cssClass: string;
      loading: boolean = false;
      onFinish: () => boolean;
      pages: WizardPage[] = [];
      currentPage: WizardPage;
      validationBanner: any;
   }

   // TODO vchomakov: move to a common module.

   export interface WizardPage {
      id: string;
      title: string;
      description: string;
      contentUrl: string;
      state?: string;
      defaultState? : string;
      onCommit?: () => boolean;
      validationParams?: string[];
      validationFunction?: (...args: any[]) => string[];
   }
}
