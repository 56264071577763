/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace dvs_ui {

   export class DvpgFailoverPolicyPageValidator implements WizardPageValidator {

      public static $inject = [
         "i18nService",
         "$q",
         "dvpgFailoverPolicyPageService"];

      constructor(private i18nService: any,
                  private $q: any,
                  private dvpgFailoverPolicyPageService: any) {
      }

      public getValidationError(wizardScope: any): any {

         if (this.dvpgFailoverPolicyPageService.shouldShowErrorWarningDialog(
                     wizardScope.model.failoverOrderOptions.connectees,
                     wizardScope.model.failoverPolicyModel.loadBalancing,
                     wizardScope.model.failoverPolicyModel.beaconProbing)) {
            return this.dvpgFailoverPolicyPageService.showErrorWarningDialog(
                  wizardScope.model.failoverOrderOptions.connectees,
                  wizardScope.model.failoverPolicyModel.loadBalancing,
                  wizardScope.model.failoverPolicyModel.beaconProbing);
         } else {
            return this.$q.resolve(true);
         }
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .service("dvpgFailoverPolicyPageValidator", DvpgFailoverPolicyPageValidator);
}
