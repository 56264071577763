namespace storage_ui {

   /**
    * AngularJS service for upgrade VMFS datastore modal
    */
   export class UpgradeVmfsDatastoreModalService {
      public static $inject = ["i18nService",
         "clarityModalService",
         "mutationService",
         "clarityConstants"];

      constructor(private i18nService: any,
                  private clarityModalService: any,
                  private mutationService: any,
                  private clarityConstants: any) {
      }

      /**
       *  Open the upgrade VMFS datastore dialog
       **/
      public openDialog(datastoreRefs: any) {
         let self = this;
         let dialogProperties: any = {
            title: this.i18nService.getString("StorageUi", "datastore.upgradeVmfsDatastoreForm.title"),
            contentTemplate: "storage-ui/resources/storage/views/datastore/upgradeVmfsDatastoreDialog.html",
            dialogData: {
               i18n: this.i18nService.getString
            },
            onSubmit: function () {
               let upgradeSpec = {
                  _type: "com.vmware.vsphere.client.h5.storage.spec.H5VmfsDatastoreUpgradeSpec"
               };

               return self.mutationService.validate(datastoreRefs,
                     upgradeSpec._type,
                     upgradeSpec).then(function (validationResult:any) {
                        if (validationResult.result) {
                           dialogProperties.alerts = [{
                              text: validationResult.result,
                              type: self.clarityConstants.notifications.type.ERROR
                           }];

                           return false;
                        }
                        self.mutationService.apply(
                              datastoreRefs[0],
                              upgradeSpec._type,
                              upgradeSpec,
                              self.i18nService.getString("StorageUi", "datastore.action.upgradeVmfsDatastore.taskName"));

                        return true;
                     }
               );
            }
         };

         if (datastoreRefs.length === 1) {
            dialogProperties.subTitle = {
               objectId: datastoreRefs[0]
            };
         }

         this.clarityModalService.openOkCancelModal(dialogProperties);
      }

   }

   angular.module("com.vmware.vsphere.client.storage")
         .service("upgradeVmfsDatastoreModalService", UpgradeVmfsDatastoreModalService);
}
