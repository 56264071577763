/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */

(function() {
   'use strict';
   angular.module('com.vmware.vsphere.client.storage').controller(
      'StorageAdaptersViewController', StorageAdaptersViewController);

   StorageAdaptersViewController.$inject = ['i18nService',
      '$scope',
      'vuiConstants',
      'dataService',
      'columnRenderersRegistry',
      'storageUtil',
      'defaultUriSchemeUtil',
      'datagridActionBarService',
      'navigation',
      'hostStorageConstants',
      'kendoGridService'];

   function StorageAdaptersViewController(i18nService, $scope, vuiConstants, dataService,
         columnRenderersRegistry, storageUtil, defaultUriSchemeUtil,
         datagridActionBarService, navigation, hostStorageConstants, kendoGridService) {

      var ISCSI_ADAPTER_TYPE = i18nService.getString('StorageUi', 'storage.adapters.type.ISCSI');
      var ACTIONS = {
         REFRESH_STORAGE_SYSTEM : 'vsphere.core.host.actions.refreshStorageSystem',
         RESCAN_HBA : 'vsphere.core.host.actions.rescanHba',
         ADD_ADAPTER: 'vsphere.core.host.actions.adapters.addSoftwareAdapter',
         RESCAN_ADAPTERS: 'vsphere.core.host.actions.rescanHost'
      };

      var storageAdaptersProperty = "storageadapter:hostStorageAdapters";
      var isHostConnectedProperty = "isHostConnectedAndPoweredOn";

      var self = this;
      self.hostId = navigation.getRoute().objectId;
      self.headerOptions = {
            title: i18nService.getString('StorageUi', 'storage.adapters.header'),
            objectId: self.hostId,
            actions: []
      };
      self.storageAdaptersLoading = false;

      self.liveRefreshProperties = [
         'config.storageDevice.hostBusAdapter',
         'config.storageDevice.softwareInternetScsiEnabled',
         'config.storageDevice.scsiTopology.adapter',
         'config.storageDevice.plugStoreTopology.adapter',
         'config.firewall.ruleset',
         'config.storageDevice.scsiLun',
         'config.storageDevice',
         'config.storageDevice.plugStoreTopology.device',
         'config.storageDevice.multipathInfo.lun',
         'config.storageDevice.plugStoreTopology.target',
         'config.fileSystemVolume.mountInfo',
         'config.multipathState.path'
      ];

      $scope.preselectComparator = function(gridDataItem) {
         if($scope.masterDetailsViewContext && $scope.masterDetailsViewContext.selectedItems &&
            $scope.masterDetailsViewContext.selectedItems.length === 1) {
            return $scope.masterDetailsViewContext.selectedItems[0].key === gridDataItem.key;
         }
         return false;
      };

      self.rescanWarningMessages = [];

      $scope.masterDetailsViewContext.alertMessenger =
            $scope.masterDetailsViewContext.alertMessenger || new AlertMessenger(i18nService.getString);

      var unregisterRescanCompletedHandler = $scope.$on('vsphere.core.host.actions.rescan.completed', function (event, args) {
         if (args && args.rescanSpec && args.rescanSpec.rescanHbas) {
            // If the user did rescan all the HBA on host/cluster/dc/folder
            if (args.affectedHosts && args.affectedHosts.length) {
               for (var i = 0; i < args.affectedHosts.length; i++) {
                  // And the current hosts HBA are rescanned as result
                  if (defaultUriSchemeUtil.compareIds(args.affectedHosts[i], self.hostId)) {
                     // Clear all the warnings.
                     $scope.masterDetailsViewContext.alertMessenger.removeRescanMessages();
                     break;
                  }
               }
            }

         }
      });

      $scope.$on('$destroy', function () {
         unregisterRescanCompletedHandler();
      });

      self.clearWarning = function () {
         $scope.masterDetailsViewContext.alertMessenger.removeMessages();
      };

      $scope.$watch(function () {
         return $scope.masterDetailsViewContext.alertMessenger.getMessages();
      }, function () {
         // Re-render the datagrid so that it will resize and update the warning icons
         self.datagridOptions.data = self.datagridOptions.data.slice(0);

         if ($scope.masterDetailsViewContext.alertMessenger) {
            self.rescanWarningMessages = $scope.masterDetailsViewContext.alertMessenger.getMessages();
         }
      }, true);

      self.refresh = function() {
         self.storageAdaptersLoading = true;
         dataService.getProperties(self.hostId, [storageAdaptersProperty, isHostConnectedProperty])
               .then(function(storageAdaptersResponse) {
                  var storageAdapters =
                        (storageAdaptersResponse[storageAdaptersProperty] &&
                              storageAdaptersResponse[storageAdaptersProperty].adapters)
                                    ? storageAdaptersResponse[storageAdaptersProperty].adapters
                                    : [];

                  storageAdapters.forEach(function (item) {
                     if (item.type) {
                        item.localizedType = i18nService.getString('StorageUi', 'storage.adapters.type.' + item.type);
                        item.isIscsiAdapter = (item.localizedType === ISCSI_ADAPTER_TYPE);
                        if (item.isIscsiAdapter) {
                           item.portBindableIScsi = item.isSoftwareBased;
                        }
                     }
                     if (item.status) {
                        item.localizedStatus = i18nService.getString('StorageUi', 'storage.adapters.status.' + item.status);
                     }

                  });
                  self.datagridOptions.data = storageAdapters;
                  $scope.masterDetailsViewContext.hostAdaptersData = storageAdaptersResponse[storageAdaptersProperty];
                  $scope.masterDetailsViewContext.isHostConnected = storageAdaptersResponse[isHostConnectedProperty];

                  updateActionBar();

               })
               .finally(function() {
                  self.storageAdaptersLoading = false;
               });
      };

      self.datagridOptions = {
         height: '100%',
         columnDefs: getColumnDefinitions(),
         sortMode: vuiConstants.grid.sortMode.SINGLE,
         selectionMode: vuiConstants.grid.selectionMode.SINGLE,
         groupOrder: [{
            field: 'model'
         }],
         data: [],
         vuiFooter: {
            actions: [{
               disabled: false,
               label: i18nService.getString('StorageUi', 'action.copyAll'),
               tooltip: i18nService.getString('StorageUi', 'action.copyAll'),
               icon: 'vui-icon-action-copy',
               action: function() {
                  kendoGridService.copyAllItems('#storageAdaptersGrid [kendo-grid]');
               }
            }]
         },
         selectedItems: [],
         resizable: true,
         actionBarOptions: {actions: []}
      };

      $scope.$watch(function() {
         return self.datagridOptions.selectedItems;
      }, function(newValue, oldValue) {
         if (!newValue || !newValue.length || !oldValue || !oldValue.length) {
            $scope.masterDetailsViewContext.selectedItems = newValue;
         }
         if (newValue[0] !== oldValue[0]) {
            $scope.masterDetailsViewContext.selectedItems = newValue;
            updateActionBar();
         }
      });

      function updateActionBar() {
         return datagridActionBarService.updateActionBar(self.datagridOptions, [self.hostId], [
            {
               actionId: ACTIONS.ADD_ADAPTER,
               getActionInvocationContext: function() {
                  return {
                     callback: function(isSwBasedIscsiAdapter) {
                        var adapter = findAdapter(isSwBasedIscsiAdapter);
                        if (adapter) {
                           $scope.masterDetailsViewContext.alertMessenger.removeRestartMessage(adapter.key);
                        }
                     }
                  };
               }
            },
            {
               actionId: ACTIONS.REFRESH_STORAGE_SYSTEM,
               getActionInvocationContext: function() {
                  return {
                     callback: function() {
                        self.refresh();
                     }
                  };
               }
            },
            {
               actionId: ACTIONS.RESCAN_ADAPTERS
            },
            vuiConstants.actions.SEPARATOR,
            {
               actionId: ACTIONS.RESCAN_HBA,
               isActionAvailable: function(actionDef) {
                  return actionDef.available && self.datagridOptions.selectedItems.length > 0;
               },
               getActionInvocationContext: function() {
                  if (self.datagridOptions.selectedItems.length > 0) {
                     var adapterKey = self.datagridOptions.selectedItems[0].key;
                     return {
                        adapterKey: adapterKey,
                        callback: function () {
                           $scope.masterDetailsViewContext.alertMessenger.removeRescanMessage(adapterKey);
                        }
                     };
                  }
                  return {};
               }
            }
         ]);
      }

      function findAdapter(isSwBasedIscsiAdapter) {
         return _.find(self.datagridOptions.data, function(adapter) {
            var iscsi = isSwBasedIscsiAdapter && adapter.type === hostStorageConstants.hostAdapterTypes.ISCSI && adapter.isSoftwareBased;
            var fcoe = !isSwBasedIscsiAdapter && adapter.type === hostStorageConstants.hostAdapterTypes.FIBRECHANNEL;
            return iscsi || fcoe;
         });
      }

      function getColumnDefinitions() {
         return [
            {
               displayName: i18nService.getString('StorageUi', 'storage.adapters.adapter'),
               field: 'device',
               width: '150px',
               template: deviceRenderer()
            },
            {
               displayName: i18nService.getString('StorageUi', 'storage.adapters.deviceModel'),
               field: 'model',
               width: '250px',
               visible: false,
               template: '#:model#'
            },
            {
               displayName: i18nService.getString('StorageUi', 'storage.adapters.type'),
               field: 'localizedType',
               width: '80px',
               template: '#:localizedType#'
            },
            {
               displayName: i18nService.getString('StorageUi', 'storage.adapters.status'),
               field: 'localizedStatus',
               width: '80px',
               template: '#:localizedStatus#'
            },
            {
               displayName: i18nService.getString('StorageUi', 'storage.adapters.wwn'),
               field: 'identifier',
               width: '210px',
               template: '#:identifier#'
            },
            {
               displayName: i18nService.getString('StorageUi', 'storage.adapters.numberTargets'),
               field: 'numberOfTargets',
               type: 'number',
               width: '70px',
               sortable: function(cti1, cti2) {
                  return storageUtil.compareNumericValues(cti1, cti2, 'numberOfTargets');
               },
               template: '#:numberOfTargets#'
            },
            {
               displayName: i18nService.getString('StorageUi', 'storage.adapters.numberDevices'),
               field: 'numberOfDevices',
               type: 'number',
               width: '70px',
               sortable: function(cti1, cti2) {
                  return storageUtil.compareNumericValues(cti1, cti2, 'numberOfDevices');
               },
               template: '#:numberOfDevices#'
            },
            {
               displayName: i18nService.getString('StorageUi', 'storage.adapters.numberPaths'),
               field: 'numberOfPaths',
               type: 'number',
               width: '65px',
               sortable: function(cti1, cti2) {
                  return storageUtil.compareNumericValues(cti1, cti2, 'numberOfPaths');
               },
               template: '#:numberOfPaths#'
            }
         ];
      }

      function deviceRenderer() {
         return (function (data) {
            var objectLinkRenderer = columnRenderersRegistry.getColumnRenderer('icon-text-ex');
            var rendererConfig = { icon: [ 'vsphere-icon-scsi-adapter'] };
            if ($scope.masterDetailsViewContext.alertMessenger.isMessageAvailable(data.key)) {
               rendererConfig.icon = 'vsphere-icon-scsi-adapter-warning';
            }
            return objectLinkRenderer(['device'], data, rendererConfig);
         });
      }

      function AlertMessenger(stringFn) {
         var getString = stringFn;

         var RESCAN_TYPE = 'rescan';
         var RESTART_TYPE = 'restart';

         var messageStrings = {};
         messageStrings[RESCAN_TYPE] = {
            single: 'storage.adapters.warning.rescanNeeded',
            multiple: 'storage.adapters.warning.rescanNeededMulti'
         };
         messageStrings[RESTART_TYPE] = 'storage.adapters.iscsi.warning';

         var messages = [];

         return {
            addRescanMessage: function(key, adapterName) {
               return addMessage(
                     getString('StorageUi', messageStrings[RESCAN_TYPE].single, adapterName),
                     key,
                     RESCAN_TYPE);
            },
            addRestartMessage: function(key) {
               return addMessage(
                     getString('StorageUi', messageStrings[RESTART_TYPE]),
                     key,
                     RESTART_TYPE);
            },
            removeMessages: function() {
               messages = [];
            },
            removeRescanMessages: function() {
               messages = _.filter(messages, function(msg) {
                  return msg.type !== RESCAN_TYPE;
               });
            },
            removeRescanMessage: function(key) {
               removeMessage(key, RESCAN_TYPE);
            },
            removeRestartMessage: function(key) {
               removeMessage(key, RESTART_TYPE);
            },
            getMessages: function() {
               var rescanMessages = computeRescanMessages();
               var restartMessages = computeRestartMessages();
               return [].concat(rescanMessages, restartMessages);
            },
            isMessageAvailable: function(key) {
               return !_.isUndefined(
                     _.find(messages, function(m) {
                        return key === m.key;
                     })
               );
            }
         };

         function getTextMessages(msgs) {
            return _.map(msgs, function(msg) {
               return msg.text;
            });
         }

         function computeRescanMessages() {
            var rescanMessages = _.filter(messages, function(msg) {
               return msg.type === RESCAN_TYPE;
            });
            if (rescanMessages.length > 1) {
               return [getString('StorageUi',
                  messageStrings[RESCAN_TYPE].multiple)];
            } else if (rescanMessages.length === 1) {
               return getTextMessages(rescanMessages);
            }
            return [];
         }

         function computeRestartMessages() {
            var restartMessages = _.filter(messages, function(msg) {
               return msg.type === RESTART_TYPE;
            });
            if (restartMessages.length >= 1) {
               return [getString('StorageUi',
                  messageStrings[RESTART_TYPE])];
            }
            return [];
         }

         function addMessage(msg, key, type) {
            var isTypedMessageForKeyAvailable = _.find(messages, function(msg) {
               return msg.type === type && msg.key === key;
            });
            if (!isTypedMessageForKeyAvailable) {
               messages.push({
                  text: msg,
                  type: type,
                  key: key
               });
               return true;
            }
            return false;
         }

         function removeMessage(key, type) {
            var index = _.findIndex(messages, function(m) {
               return key === m.key && m.type === type;
            });
            if (index > -1) {
               messages.splice(index, 1);
            }
         }
      }

      self.refresh();
   }
})();
