(function() {
   /**
    * Directive for VM and host group members datagrids.
    */
   'use strict';
   angular.module('com.vmware.vsphere.client.cluster').directive(
      'vmAndHostGroupMembers', vmAndHostGroupMembers);

   vmAndHostGroupMembers.$inject = ['i18nService', 'clusterGroupsService', '$rootScope',
         'clusterGroupsConstants', 'mutationService', 'clusterSpecBuilderService'];

   function vmAndHostGroupMembers(i18nService, clusterGroupsService, $rootScope,
         clusterGroupsConstants, mutationService, clusterSpecBuilderService) {

      return {
         restrict: 'E',
         templateUrl:
            'cluster-ui/resources/cluster/views/settings/configuration/groups/vmAndHostGroupMembers.html',
         scope: {
            group: '='
         },
         controller: ['$scope', '$element', function($scope) {
            var objectId = $rootScope._route.objectId;

            $scope.removeAction = removeGroupMember;
            $scope.addAction = addGroupMember;

            $scope.$watch('group', function(group) {
               if (group) {
                  clusterGroupsService.getGroupMembers(group)
                     .then(function(membersData) {
                        if ($scope.group &&  $scope.group.name === group.name) {
                           $scope.objects = membersData;
                           $scope.objectType = group.rawType;
                           $scope.gridTitle = i18nService.getString(
                                 'ClusterUi', 'rules.groupMembers.members', _.escape(group.name));
                        }
                     });
               } else {
                  $scope.objects = null;
                  $scope.gridTitle = ' ';
               }
            });

            function removeGroupMember(selectedGroupMembers) {
               clusterGroupsService.removeGroupMembers(objectId, $scope.group, selectedGroupMembers);
            }

            function addGroupMember(selectedItems) {
                  var allGroupMembers = getSelectedItemsIds(
                        $scope.objects.concat(selectedItems));

                  var groupSpec = clusterGroupsService.buildGroupSpec(
                        $scope.group.rawType,
                        $scope.group.name,
                        clusterGroupsService.buildGroupMembersSpec(allGroupMembers),
                        clusterGroupsConstants.operationType.EDIT
                  );

                  var clusterSpec =
                        clusterSpecBuilderService.buildClusterComputeResourceSpec(
                              {group: groupSpec}, true);

                  mutationService.apply(
                        objectId,
                        'com.vmware.vsphere.client.cluster.ClusterComputeResourceSpec',
                        clusterSpec
                  );
                  return true;
               }

            function getSelectedItemsIds(items) {
               return items.map(function(item) {
                  return item.id;
               });
            }
         }]
      };
   }
})();
