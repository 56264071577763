(function() {
   angular.module('com.vmware.vsphere.client.commonModule')
      .controller('renameObjectController', renameObjectController);

   renameObjectController.$inject = ['$scope', 'managedEntityMutationService', 'mutationService', 'i18nService'];

   function renameObjectController ($scope, managedEntityMutationService, mutationService, i18nService) {
      var self = this;
      self.validity = "";
      self.errorMessage = "";
      self.name = $scope.modalOptions.dialogData.existingObjectName;
      self.maxNameLength = $scope.modalOptions.dialogData.maxNameLength || 80;
      self.renameObject = function() {
         if(self.name) {
            mutationService.validate($scope.modalOptions.dialogData.objectId,
               'com.vmware.vsphere.client.mixed.ManagedEntityExtendedNameInfo',
               {
                  name: self.name,
                  targetType: null
               }).then(function(data) {
               if(!data.result) {
                  self.invalidateForm(i18nService.getString('Common', 'rename.name.exist', self.name));
               } else {
                  managedEntityMutationService.rename($scope.modalOptions.dialogData.objectId,
                                                      { name: self.name },
                                                      $scope.modalOptions.secondaryTitle + '-' + $scope.modalOptions.title);
                  self.closeModal();
               }
            });
         } else {
            self.invalidateForm(i18nService.getString('CommonModuleUi', 'NameValidator.emptyName'));
         }
      };

      self.invalidateForm = function (errorMessage) {
         self.validity = "invalid";
         self.errorMessage = errorMessage;
      };

      self.closeModal = function() {
         $scope.closeModal();
      };
   }
})();
