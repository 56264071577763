/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
(function () {
   'use strict';

   angular
         .module('com.vmware.vsphere.client.network')
         .controller('PortTrafficShapingPolicyPageController',
               PortTrafficShapingPolicyPageController);

   PortTrafficShapingPolicyPageController.$inject = [
      '$scope',
      'networkUtil',
      'networkUiConstants',
      'trafficShapingPolicyValidator',
      'editPortgroupService',
      'vuiConstants'];

   function PortTrafficShapingPolicyPageController($scope,
         networkUtil,
         networkUiConstants,
         trafficShapingPolicyValidator,
         editPortgroupService,
         vuiConstants) {

      var attributeNames = [
         networkUiConstants.TrafficShapingSetting.AVERAGE_BANDWIDTH,
         networkUiConstants.TrafficShapingSetting.PEAK_BANDWIDTH,
         networkUiConstants.TrafficShapingSetting.BURST_SIZE
      ];

      var initialData = $scope.initialData.trafficShapingPolicies;

      this.options = [{
         label: networkUtil.getString('PortTrafficShapingPolicyPage.statusOn'),
         val: true
      }, {
         label: networkUtil.getString('PortTrafficShapingPolicyPage.statusOff'),
         val: false
      }];

      this.validators = {
         averageBandwidth: {
            message: null,
            isVisible: false
         },
         peakBandwidth: {
            message: null,
            isVisible: false
         },
         burstSize: {
            message: null,
            isVisible: false
         }
      };

      $scope.manager.commitPageCallbacks.commitTrafficShapingPolicyPage = onCommit;

      $scope.$watchCollection(function() {
         return [
            $scope.manager.portgroupData.trafficShapingPolicies.enabled,
            $scope.manager.portgroupData.trafficShapingPolicies.overridden
         ];
      }, function() {
         _.forEach(attributeNames, function(attributeName) {
            this.onTrafficShapingChange(attributeName);
         }.bind(this));
      }.bind(this));

      this.onTrafficShapingChange = function (attributeName) {
         var value = $scope.manager.portgroupData.trafficShapingPolicies[attributeName];

         var message =
            trafficShapingPolicyValidator.getTrafficShapingErrorMessage(value, attributeName);

         this.validators[attributeName].message = message;
         this.validators[attributeName].isVisible =
            $scope.manager.portgroupData.trafficShapingPolicies.overridden &&
            $scope.manager.portgroupData.trafficShapingPolicies.enabled && message !== null;
      };

      function onCommit () {
         var errors = [];
         $scope.manager.pageDirtyFlags.isTrafficShapingPageDirty = editPortgroupService
               .hasTrafficShapingPolicyChanged(initialData,
                     $scope.manager.portgroupData.trafficShapingPolicies, true);

         if ($scope.manager.pageDirtyFlags.isTrafficShapingPageDirty &&
               $scope.manager.portgroupData.trafficShapingPolicies.overridden &&
               $scope.manager.portgroupData.trafficShapingPolicies.enabled) {
            _.forEach(attributeNames, function(attributeName) {
               var value =
                     $scope.manager.portgroupData.trafficShapingPolicies[attributeName];
               var errorMessage = trafficShapingPolicyValidator
                     .getTrafficShapingErrorMessage(value, attributeName);
               if (errorMessage !== null) {
                  errors.push(createError(errorMessage));
               }
            });

            if (errors.length === 0 &&
                  $scope.manager.portgroupData.trafficShapingPolicies.averageBandwidth >
                  $scope.manager.portgroupData.trafficShapingPolicies.peakBandwidth) {
               var msg = networkUtil.getString(
                     'PortPolicyData.shaping.error.avgBwGreaterThanPeakBw');
               errors.push(createError(msg));
            }
         }

         return errors;
      }

      function createError(errorMessage) {
         return {
            type: vuiConstants.validationBanner.type.ERROR,
            text: errorMessage
         };
      }
      return this;
   }
})();
