angular.module('com.vmware.vsphere.client.vm').directive('vmSummaryImage', [
   'i18nService',
   'actionsService',
   'featureFlagsService',
   'dataService',
   'persistenceService',
   'clarityModalService',
   function (i18nService, actionsService, featureFlagsService, dataService, persistenceService, clarityModalService) {
      return {
         templateUrl: 'vm-ui/resources/vm/views/summary/vmSummaryImage.html',
         link: function (scope) {
            var vmId = scope.entityReference;
            scope.screenshotInProgress = false;

            scope.i18n = i18nService.getString;
            scope.featureFlagsService = featureFlagsService;
            scope.launchConsoleActionEval = null;

            scope.getVmScreenshot = getVmScreenshot;
            scope.imageBytes = getBlackImage();

            var PROPERTY_DEFAULT_CONSOLE = "com.vmware.usersettings.defaultConsole";
            var ACTION_OPEN_CONSOLE = "vsphere.core.vm.actions.openConsole";
            var ACTION_LAUNCH_REMOTE_CONSOLE = "vsphere.core.vm.actions.launchRemoteConsole";
            var ACTION_DOWNLOAD_VMRC = "vsphere.core.vm.actions.downloadVMRC";

            scope.$watch('summaryData', function (newValue, oldValue) {
               angular.extend(scope, params(scope.summaryData, scope.i18n));

               if (!scope.launchConsoleActionEval || newValue !== oldValue) {
                  if (angular.isDefined(newValue.isTemplate) && !newValue.isTemplate) {
                     evaluateLaunchRemoteConsole();
                  }

                  if(oldValue.powerState !== newValue.powerState) {
                     if(newValue.powerState === 'poweredOff') {
                        scope.imageBytes = null;
                     } else {
                        getVmScreenshot();
                     }
                  }
               }
            }, true);

            scope.signPostContentTemplate = '<div class="locked-vm-signpost-content">' +
                  i18nService.getString('VmUi', 'summaryView.unlockVm.signpost.text') +
                  '</div>';

            getVmScreenshot();

            scope.vmrcInfoSignPostParams = {
               message: i18nService.getString('VmUi', 'summaryView.downloadVMRCPlayer.tooltip'),
               title: i18nService.getString('Common', 'help'),
               linkLabel: i18nService.getString('VmUi', 'summaryView.downloadVMRCPlayer'),
               linkFunction: downloadVmrcClicked
            };

            scope.launchVmrcInfoSignPostParams = {
               message: i18nService.getString('VmUi', 'summaryView.launchVMRCConsoleInfo.text'),
               title: i18nService.getString('VmUi', 'summaryView.launchVMRCConsole'),
               linkLabel: i18nService.getString('VmUi', 'summaryView.downloadVMRCPlayer'),
               linkFunction: downloadVmrcClicked
            };

            scope.itemClicked = function (targetId) {

               var conditionObj = {
                  key: 'uid',
                  op: '=',
                  value: PROPERTY_DEFAULT_CONSOLE
               };

               persistenceService.getData(conditionObj).then(function (data) {
                  var consolePreferencesData = data[0];
                  if (consolePreferencesData
                     && consolePreferencesData.value
                     && consolePreferencesData.value.defaultConsole) {
                        var consoleType = consolePreferencesData.value.defaultConsole;
                     if (consoleType === "WEB_CONSOLE") {
                        scope.launchWebConsole();
                     } else {
                        scope.launchVMRCConsole();
                     }
                  } else {
                        clarityModalService.openOkCancelModal({
                           contentTemplate: 'vm-ui/resources/vm/views/summary/modals/select-default-console/SelectConsoleDialog.html',
                           title: i18nService.getString('VmUi', 'launchConsoleModal.title'),
                           dialogData: {
                              vmId: targetId
                           }
                        });
                  }
               });
            };

            scope.unlockVmClicked = function (targetId) {
               actionsService.getAction("vsphere.core.vm.actions.cryptoUnlock", [targetId])
                  .then(function (action) {
                     actionsService.invokeAction(action);
                  });
            };

            scope.unlockVmClicked = function (targetId) {
               actionsService.getAction("vsphere.core.vm.actions.cryptoUnlock", [targetId])
                  .then(function (action) {
                     actionsService.invokeAction(action);
                  });
            };

            scope.launchVMRCConsole = function () {
               actionsService.getAction(ACTION_LAUNCH_REMOTE_CONSOLE, [vmId])
                  .then(function(action) {
                     actionsService.invokeAction(action);
                  });
            };

            scope.launchWebConsole = function () {
               actionsService.getAction(ACTION_OPEN_CONSOLE, [vmId])
                  .then(function(action) {
                     actionsService.invokeAction(action);
                  });
            };

            function getVmScreenshot() {
               if (scope.screenshotInProgress) {
                  return;
               }

               scope.screenshotInProgress = true;
               dataService.getProperties(scope.entityReference, ["vmScreenshot"], {
                  propertyParams: [{
                     propertyName: "vmScreenshot",
                     parameterType: "com.vmware.vsphere.client.vm.screenshot.VmScreenshotSize",
                     parameter: {
                        width: 160,
                        height: 120
                     }
                  }]
               }).then(function (response) {
                  var vmScreenshot = response["vmScreenshot"];
                  if (!_.isEmpty(vmScreenshot)) {
                     scope.imageBytes = vmScreenshot;
                  }
               }).then(function () {
                  scope.screenshotInProgress = false;
               });
            }

            function evaluateLaunchRemoteConsole() {
               actionsService.getAction("vsphere.core.vm.actions.launchRemoteConsole", [vmId])
                     .then(function(action) {
                        scope.launchConsoleActionEval = action;
                     });
            }

            function downloadVmrcClicked() {
               actionsService.getAction("vsphere.core.vm.actions.downloadVMRC")
                  .then(function (action) {
                     actionsService.invokeAction(action);
                  });
            }

            function getBlackImage() {
               return "R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs=";
            }
         }
      };

      function params(model, i18n) {
         var imageCaption, imageCaptionIcon, stateLabel, isWebConsoleAvailable;
         if (!model.powerState || model.isTemplate) {
            return;
         }

         switch (model.powerState) {
            case 'poweredOn' :
               isWebConsoleAvailable = true;
               imageCaption = i18n('VmUi', 'VmImage.poweredOn');
               imageCaptionIcon = 'icon-vSphere-vmPowerOn';
               break;
            case 'poweredOff' :
               stateLabel = i18n('VmUi', 'VmImage.poweredOff');
               isWebConsoleAvailable = false;
               break;
            case 'suspended' :
               imageCaption = i18n('VmUi', 'VmImage.suspended');
               imageCaptionIcon = 'icon-vSphere-vmSuspend';
               isWebConsoleAvailable = false;
               break;
         }

         return {
            isWebConsoleAvailable: isWebConsoleAvailable,
            stateLabel: stateLabel,
            imageCaption: imageCaption,
            imageCaptionIcon: imageCaptionIcon
         };
      }
   }
]);
