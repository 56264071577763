module cluster_ui {

   import IComponentController = angular.IComponentController;

   class HaResourcesAdvancedRuntimeInfoController implements IComponentController {

      public static $inject = ["$scope", "dataService", "haSummaryService", "$q"];

      public propertyViewData: any[];
      private loadingData: boolean = false;
      private objectId: string;


      constructor(private $scope: any, private dataService: any,
                  private haSummaryService: any,
                  private $q: any) {
         this.objectId = this.$scope._route.objectId;
         this.propertyViewData = [];

         this.$scope.$on("haSummaryPortletsRefresh", () => {
            this.getData();
         });

         this.getData();
      }

      public getData(): void {
         if (this.loadingData) {
            return;
         }

         this.loadingData = true;

         return this.dataService.getData(this.objectId,
               "com.vmware.vsphere.client.clusterui.model.services.HaFailoverResourceRuntimeInfoData")
               .then((failoverResourceData: any) => {
                  this.propertyViewData =
                        this.haSummaryService.getFailoverResourcesAdvancedRuntimeData(
                              failoverResourceData);
                  this.loadingData = false;
               })
               .catch((error: any) => {
                  this.loadingData = false;
                  this.$q.reject(error);
               });
      }
   }

   angular.module("com.vmware.vsphere.client.cluster")
         .controller("HaResourcesAdvancedRuntimeInfoController",
               HaResourcesAdvancedRuntimeInfoController);
}
