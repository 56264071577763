namespace h5_vm {

   import FlagInfo$VirtualExecUsage =
      com.vmware.vim.binding.vim.vm.FlagInfo$VirtualExecUsage;
   import FlagInfo$VirtualMmuUsage =
      com.vmware.vim.binding.vim.vm.FlagInfo$VirtualMmuUsage;
   import CpuMmuConstants = h5_vm.CpuMmuConstants;

   export class CpuMmuOptionFactory {

      static $inject: string[] = [
         'i18nService'
      ];

      public readonly automatic =
         new CpuMmuOption(CpuMmuConstants.AUTOMATIC,
               this.i18nService.getString("VmUi", "CpuMmuVirt.Label.automatic"));

      public readonly software: CpuMmuOption =
         new CpuMmuOption(CpuMmuConstants.SOFTWARE,
               this.i18nService.getString("VmUi", "CpuMmuVirt.Label.software"));

      public readonly hardwareCpu: CpuMmuOption =
         new CpuMmuOption(CpuMmuConstants.HARDWARE_CPU,
               this.i18nService.getString("VmUi", "CpuMmuVirt.Label.hardwareCpu"));

      public readonly hardwareCpuMmu: CpuMmuOption =
         new CpuMmuOption(CpuMmuConstants.HARDWARE_CPU_MMU,
               this.i18nService.getString("VmUi", "CpuMmuVirt.Label.hardwareCpuMmu"));

      constructor(private i18nService: any) {
      }
   }

   angular.module("com.vmware.vsphere.client.vm")
         .service("cpuMmuOptionFactory", CpuMmuOptionFactory);


   export class CpuMmuOption {

      private _cpuUsage: FlagInfo$VirtualExecUsage;
      private _mmuUsage: FlagInfo$VirtualMmuUsage;
      private _value: string;
      private _label: string;

      constructor(val: string, label: string) {
         this._value = val;
         this._label = label;

         switch (this._value) {
            case CpuMmuConstants.AUTOMATIC:
               this._cpuUsage = "hvAuto";
               this._mmuUsage = "automatic";
               break;
            case CpuMmuConstants.SOFTWARE:
               this._cpuUsage = "hvOff";
               this._mmuUsage = "off";
               break;
            case CpuMmuConstants.HARDWARE_CPU:
               this._cpuUsage = "hvOn";
               this._mmuUsage = "off";
               break;
            case CpuMmuConstants.HARDWARE_CPU_MMU:
               this._cpuUsage = "hvOn";
               this._mmuUsage = "on";
               break;
            default:
               return;
         }
      }

      get cpuUsage(): FlagInfo$VirtualExecUsage {
         return this._cpuUsage;
      }

      get mmuUsage(): FlagInfo$VirtualMmuUsage {
         return this._mmuUsage;
      }

      get value(): string {
         return this._value;
      }

      get label(): string {
         return this._label;
      }

      public equals(another: any): Boolean {
         if (another instanceof CpuMmuOption) {

            return (_.isEqual(this._cpuUsage, another.cpuUsage) &&
            _.isEqual(this._mmuUsage, another.mmuUsage));

         } else if (_.isEqual(typeof another, "string")) {

            return (_.isEqual(this._value, another));

         }
         return false;
      }

   }

}
