(function () {
   'use strict';
   angular.module('com.vmware.vsphere.client.host')
         .controller('EditServiceStartupPolicyController', EditServiceStartupPolicyController);

   EditServiceStartupPolicyController.$inject = ['$scope', 'mutationService',
      'hostServicesConstants', 'i18nService'];

   function EditServiceStartupPolicyController($scope, mutationService,
         hostServicesConstants, i18nService) {
      var objectId = $scope.modalOptions.dialogData.model.hostId;
      var serviceKeyId = $scope.modalOptions.dialogData.model.service.key;

      $scope.policy = hostServicesConstants.policy;
      $scope.selectedPolicy = $scope.modalOptions.dialogData.model.service.policy;

      $scope.modalOptions.onSubmit = function() {
         mutationService.apply(objectId,
               'com.vmware.vsphere.client.host.config.ServicePolicySpec',
               {policy:$scope.selectedPolicy, serviceKey:serviceKeyId});
         return true;
      };

      $scope.popupParams = {
         title: i18nService.getString('HostUi','policy.auto.label'),
         message: i18nService.getString('HostUi','policy.auto.desc')
      };
   }
})();
