namespace storage_ui {
   import IComponentController = angular.IComponentController;
   import IComponentOptions = angular.IComponentOptions;
   import NetworkSettingsItem = com.vmware.vsphere.client.h5.storage.data.adapters.NetworkSettingsItem;

   class StorageAdapterIPv6SettingsComponent implements IComponentController {

      private validator: any;
      public selectedOption: string;
      private staticIpv6Address: InternetScsiHba$IscsiIpv6Address[];
      private model: NetworkSettingsItem;
      private linkLocalAddress: string;
      private validateManually: {[key: string]: Function} = {};

      public static $inject = ["i18nService", "StorageAdapterNetworkSettingsConstants",
         "ipParserService", "vxValidatorFactory", "hbaNetworkingUtil"];

      constructor(private i18nService: any,
                  private constants: any,
                  private ipParserService: any,
                  private vxValidatorFactory: any,
                  private hbaNetworkingUtil: any) {
      }

      $onInit() {
         this.linkLocalAddress = this.getLinkLocalAddress();
         this.model.properties.ipv6properties.iscsiIpv6Address = _.filter(this.model.properties.ipv6properties.iscsiIpv6Address,
                     (address: com.vmware.vim.binding.vim.host.InternetScsiHba$IscsiIpv6Address)=> {
                        return address.address !== this.linkLocalAddress;
                     }) || [];
         this.staticIpv6Address = this.model.properties.ipv6properties.iscsiIpv6Address || [];
         this.populateSelection();
         //Here we wrap "global" validation to trigger Ipv6 validations/grid values check
         this.validator.validate = _.wrap(this.validator.validate, (validatorValidate: Function)=> {
            let errors: string[] = validatorValidate.call(this.validator);
            _.each(this.validateManually, (validate: Function)=> {
               errors = errors.concat(validate());
            });

            if (this.selectedOption === this.constants.IPv6Settings.STATIC) {
               if (!this.model.properties.ipv6properties.iscsiIpv6Address.length) {
                  errors.push(this.i18nService.getString("StorageUi", "storage.adapters.properties.ipDnsConfig.emptyStaticV6Addresses"));
               }
            }
            return errors;
         });
      }

      private isStatic(): boolean {
         return this.selectedOption === this.constants.IPv6Settings.STATIC;
      }

      private isLinkLocal(): boolean {
         return this.selectedOption === this.constants.IPv6Settings.LINK_LOCAL;
      }

      private getLinkLocalAddress() {
         return this.hbaNetworkingUtil.getLinkLocalIPv6Address(this.model.properties);
      }

      private populateSelection() {
         let props = this.model.properties;
         let types = this.constants.IPv6Settings;
         if (props.ipv6properties.ipv6DhcpConfigurationEnabled) {
            this.selectedOption = types.DHCP;
         } else if (props.ipv6properties.ipv6RouterAdvertisementConfigurationEnabled) {
            this.selectedOption = types.ROUTER;
         } else if (this.linkLocalAddress && !this.staticIpv6Address.length) {
            this.selectedOption = types.LINK_LOCAL;
         } else {
            this.selectedOption = types.STATIC;
         }
      }

      private changeStaticAddress(ipv6: string) {
         this.model.properties.ipv6properties.iscsiIpv6Address = [this.hbaNetworkingUtil.createIpv6(ipv6)];
      }

      private onSelectionChange(radioSelection: string): void {
         let ipv6: InternetScsiHba$IPv6Properties = <InternetScsiHba$IPv6Properties>this.model.properties.ipv6properties;
         switch (radioSelection) {
            case this.constants.IPv6Settings.DHCP:
               ipv6.ipv6DhcpConfigurationEnabled = true;
               ipv6.ipv6RouterAdvertisementConfigurationEnabled = false;
               break;
            case this.constants.IPv6Settings.ROUTER:
               ipv6.ipv6DhcpConfigurationEnabled = false;
               ipv6.ipv6RouterAdvertisementConfigurationEnabled = true;
               break;
            case this.constants.IPv6Settings.LINK_LOCAL:
               let newLinkLocal = this.hbaNetworkingUtil.getLinkLocalIPv6(this.model.properties);
               ipv6.iscsiIpv6Address = newLinkLocal ? [newLinkLocal] : [];
               ipv6.ipv6DhcpConfigurationEnabled = false;
               ipv6.ipv6RouterAdvertisementConfigurationEnabled = false;
               break;
            case this.constants.IPv6Settings.STATIC:
               ipv6.ipv6DhcpConfigurationEnabled = false;
               ipv6.ipv6RouterAdvertisementConfigurationEnabled = false;
               break;
         }
      }

   }

   angular.module("com.vmware.vsphere.client.storage")
         .component("storageAdapterIpV6SettingsComponent", <IComponentOptions>{
            templateUrl: "storage-ui/resources/storage/views/host/adapters/network/StorageAdapterIPv6SettingsComponent.html",
            bindings: {
               model: "=",
               validator: "=",
               linkLocalAddress: "=",
               selectedOption: "="
            },
            controller: StorageAdapterIPv6SettingsComponent
         });

}
