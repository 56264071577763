/* Copyright 2018 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace dvs_ui {

   export class MoveDvsToComponent {

      public bindings: any;
      public controller: any;
      public templateUrl: string;

      constructor() {
         this.bindings = {
            pageModel: "="
         };

         this.controller = MoveDvsToComponentController;

         this.templateUrl =
               "dvs-ui/resources/dvs/moveTo/moveDvsToComponentTemplate.html";
      }
   }

   class MoveDvsToComponentController {

      private pageModel: any;

      public dvsIds: string[];
      public entityToMoveDvsTo: any;
      private onLocationSelected: Function;

      public static $inject = [];

      constructor() {
      }

      $onInit(): void {
         this.dvsIds = this.pageModel.objectIds;

         this.onLocationSelected = (objectReference: any) => {
            this.pageModel.entityToMoveDvsTo = objectReference;
         };
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .component("moveDvsToComponent", new MoveDvsToComponent());

}

