/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace dvs_ui {
   export class DvsAddHostWizardConstants {
      public static readonly targetType: any = {
         DVS_RECONFIGURE_COMMAND_SPEC_TYPE: "com.vmware.vsphere.client.dvs.specs.DvsReconfigureCommandSpec",
         DVS_HOST_MEMBER_CONFIG_SPEC_TYPE: "com.vmware.vim.binding.vim.dvs.HostMember$ConfigSpec"
      };

      public static readonly page: any = {
         DVS_SELECT_WORKFLOW_PAGE: "selectWorkflowPage",
         DVS_SELECT_HOSTS_PAGE: "selectHostsPage",
         DVS_SELECT_HOSTS_TO_REMOVE_PAGE: "selectHostsToRemovePage",
         DVS_SELECT_HOSTS_TO_MANAGE_PAGE: "selectHostsToManagePage",
         DVS_SELECT_PHYSICAL_ADAPTERS_PAGE: "selectPhysicalAdaptersPage",
         DVS_SELECT_VIRTUAL_ADAPTERS_PAGE: "selectVirtualAdaptersPage",
         DVS_SELECT_VIRTUAL_MACHINES_PAGE: "selectVirtualMachinesPage",
         DVS_READY_TO_COMPLETE_PAGE: "dvsAddHostReadyToCompletePage"
      };

      public static readonly nicListItemType: any = {
         PNIC_TYPE: "DvsAddHostHierarchicalListPnicItem",
         GROUPING_TYPE: "DvsAddHostHierarchicalListGroupingItem",
         HOST_TYPE: "DvsAddHostHierarchicalListHostItem",
         VNIC_TYPE: "DvsAddHostHierarchicalListVnicItem",
         VM_TYPE: "DvsAddHostHierarchicalListVmItem",
         VM_VNIC_TYPE: "DvsAddHostHierarchicalListVmVnicItem"
      };

      public static readonly operationType: any = {
         ADD_HOST: "addHostOperation",
         REMOVE_HOST: "removeHostOperation",
         MANAGE_HOST: "manageHostOperation",
         MIGRATE_NETWORKING: "migrateNetworkingOperation"
      };

      public static readonly AUTO_ASSIGN: string = "";

      public static readonly hostConnectionState: any = {
         CONNECTED: "connected",
         DISCONNECTED: "disconnected",
         NOT_RESPONDING: "notResponding"
      };
   }
}
