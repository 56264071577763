import VmConfigContext = com.vmware.vsphere.client.vm.config.VmConfigContext;
import ConfigInfo = com.vmware.vim.binding.vim.vm.ConfigInfo;
import GuestOsDescriptor = com.vmware.vim.binding.vim.vm.GuestOsDescriptor;
import SharesInfo = com.vmware.vim.binding.vim.SharesInfo;
import IScope = angular.IScope;
import VmHardwareUtil = h5_vm.VmHardwareUtil;
import VmHardwareMemoryService = h5_vm.VmHardwareMemoryService;
import VmConfig = h5_vm.VmConfig;
import Recommendation = h5_vm.VmRecommendation;

interface Unit {
   label: string;
   multiplier: number;
}

angular.module('com.vmware.vsphere.client.vm').directive('vmHardwareMemory', [
   'i18nService',
   'numberFormatterService',
   'scopeUtil',
   'bytesFilter',
   'vmHardwareUtil',
   'vmHardwareMemoryService',
   function (i18nService: any,
             numberFormatterService: any,
             scopeUtil: any,
             bytesFilter: any,
             vmHardwareUtil: VmHardwareUtil,
             vmHardwareMemoryService: VmHardwareMemoryService) {
      return {
         scope: {
            vm: '=',
            vmConfigContext: '=',
            originalConfig: '=',
            createMode: '=',
            deployVmtxMode: "=",
            expanded: '=',
            selectedGuestOs: '='
         },
         templateUrl: 'vm-ui/resources/vm/views/settings/vmHardwareSettings/vmHardwareMemory/vmHardwareMemory.html',
         link: {
            pre: function (iScope: IScope) {
               let scope: VmHwMemoryScope = <any> iScope;
               var initailizationStepDone = false;
               var initailizationShares: number;
               scope.i18n = i18nService.getString;
               scope.MAX_SHARES_VALUE = 1000000;
               scope.MIN_SHARES_VALUE = 1;
               scope.fieldLabel = function (field: string) {
                  return scope.i18n('VmUi', 'label' + field);
               };
               scope.memoryOptions = [
                  {label: scope.i18n('VmUi', "Vm.Units.MB"), multiplier: 1},
                  {label: scope.i18n('VmUi', "Vm.Units.GB"), multiplier: 1024},
                  {label: scope.i18n('VmUi', "Vm.Units.TB"), multiplier: 1024 * 1024}
               ];

               if (scope.vm) {
                  scope.memoryRecommendations = scope.vm.getMemoryRecommendations();
               }

               var memoryMB = scope.vmConfigContext.config.hardware.memoryMB;

               if (memoryMB < 1024) {
                  (scope.vmConfigContext.config.hardware as any).memoryUnitPreferred = scope.memoryOptions[0];
               }

               scope.onMemoryReservationLockedToMax = function () {
                  if (scope.vmConfigContext.config.memoryReservationLockedToMax) {
                     scope.vmConfigContext.config.memoryAllocation.reservation = scope.vmConfigContext.config.hardware.memoryMB;
                  }
               };

               let memoryValidator = {
                  name: "memoryMB",
                  validate: validateFn,
                  message: errorFn,
                  error: ""
               };

               function validateFn(mb: number): boolean {
                  if (!scope.vm) {
                     return true;
                  }
                  const result = scope.vm.validateMemoryMB(mb);
                  memoryValidator.error = result.error;
                  return result.valid;
               }

               function errorFn(): string {
                  return memoryValidator.error;
               }

               scope.memoryValidators = [memoryValidator];

               scope.onUnitChanged = function (newUnit: Unit) {
                  (scope.vmConfigContext.config.hardware as any).memoryUnitPreferred = newUnit;
               };

               scope.onReservationUnitChanged = function (newUnit: Unit) {
                  (scope.vmConfigContext.config.memoryAllocation as any).reservationMemoryUnitPreferred = newUnit;
               };

               scope.onLimitUnitChanged = function (newUnit: Unit) {
                  (scope.vmConfigContext.config.memoryAllocation as any).limitMemoryUnitPreferred = newUnit;
               };

               scope.sharesValueMap = {
                  low: 0,
                  normal: 0,
                  high: 0
               };
               var unlimitedLabel = scope.i18n('VmUi', 'labelUnlimited');

               var limit: number = -1, reservation: number = 0;
               if (_.isEmpty(scope.vmConfigContext.config.memoryAllocation)
                     || !(scope.vmConfigContext.config.memoryAllocation as any).isInitialized) {
                  limit = scope.originalConfig.memoryAllocation ?
                        scope.originalConfig.memoryAllocation.limit : -1;
                  reservation = scope.originalConfig.memoryAllocation ?
                        scope.originalConfig.memoryAllocation.reservation : 0;

                  (scope.vmConfigContext.config.memoryAllocation as any).isInitialized = true;
                  //set default units
                  (scope.vmConfigContext.config.memoryAllocation as any).reservationMemoryUnitPreferred =
                        scope.memoryOptions[0];
                  (scope.vmConfigContext.config.memoryAllocation as any).limitMemoryUnitPreferred =
                        scope.memoryOptions[0];
               }
               scope.limitRecommendations = [
                  {
                     name: scope.i18n('VmUi', 'VmMemory.Limit.Current'),
                     value: limit
                  },
                  {
                     name: scope.i18n('VmUi', 'VmMemory.Limit.Minimum'),
                     value: 0
                  },
                  {
                     name: scope.i18n('VmUi', 'VmMemory.Limit.Maximum'),
                     value: -1
                  }
               ];

               scope.reservationRecommendations = [
                  {
                     name: scope.i18n('VmUi', 'VmMemory.Reservation.Current'),
                     value: reservation
                  },
                  {
                     name: scope.i18n('VmUi', 'VmMemory.Reservation.Minimum'),
                     value: 0
                  },
                  {
                     name: scope.i18n('VmUi', 'VmMemory.Reservation.Maximum'),
                     value: 0
                  }
               ];

               function getReservationErrorMessage() {
                  return scope.i18n('VmUi', 'Util.Validation.ValidRange',
                        scope.i18n('VmUi', 'VmMemory.Label.Reservation'),
                        scope.i18n('VmUi', 'memValue', 0),
                        scope.i18n('VmUi', 'memValue', scope.limits.reservationMaxLimit)
                  );
               }

               function setReservationErrorMessage() {
                  scope.reservationErrorMessages = {
                     max: getReservationErrorMessage,
                     min: getReservationErrorMessage
                  };
               }

               setReservationErrorMessage();

               var formatter = function (r: { formattedValue: string, value: number, name: string }) {
                  r.formattedValue = r.value === -1 ? unlimitedLabel : bytesFilter(r.value, 'MB');
               };

               scope.$watchGroup(['vmConfigContext.config.memoryAllocation.reservation',
                  'vmConfigContext.config.memoryAllocation.limit',
                  'vmConfigContext.config.hardware.memoryMB'], function (data: any) {
                  var reservation = scope.vmConfigContext.config.memoryAllocation.reservation;
                  if (reservation) {
                     scope.limitRecommendations[1].value = reservation;
                  }

                  scope.limits = {
                     reservationMaxLimit: getReservationMaxLimit()
                  };

                  setReservationErrorMessage();

                  scope.reservationRecommendations[2].value = scope.limits.reservationMaxLimit;
                  _.each(scope.limitRecommendations, formatter);
                  _.each(scope.reservationRecommendations, formatter);
                  var memoryMB = scope.vmConfigContext.config.hardware.memoryMB;
                  if (memoryMB) {
                     scope.sharesValueMap = {
                        low: Math.min(5 * memoryMB, scope.MAX_SHARES_VALUE),
                        normal: Math.min(10 * memoryMB, scope.MAX_SHARES_VALUE),
                        high: Math.min(20 * memoryMB, scope.MAX_SHARES_VALUE)
                     };
                     var level = scope.sharesInfo.level;
                     if (level !== 'custom') {
                        if (!initailizationStepDone) {
                           initailizationShares = (scope.sharesValueMap as any)[level];
                        }

                        initailizationStepDone = true;
                        scope.sharesInfo.shares = (scope.sharesValueMap as any)[level];
                     }
                  }
               });

               scope.hasChanged = function () {
                  var isChanged = false;
                  var level = scope.vmConfigContext.config.cpuAllocation.shares.level;

                  isChanged = scope.vmConfigContext.config.hardware.memoryMB !== scope.originalConfig.hardware.memoryMB;
                  isChanged = isChanged || scope.originalConfig.memoryAllocation.reservation !== scope.vmConfigContext.config.memoryAllocation.reservation;
                  isChanged = isChanged || scope.originalConfig.memoryAllocation.limit !== scope.vmConfigContext.config.memoryAllocation.limit;
                  isChanged = isChanged || scope.vmConfigContext.config.memoryHotAddEnabled !== scope.originalConfig.memoryHotAddEnabled;

                  if (level !== 'custom') {
                     isChanged = isChanged ||
                           scope.sharesInfo.shares !== initailizationShares;
                  } else {
                     isChanged = isChanged ||
                           scope.sharesInfo.shares !== scope.originalConfig.memoryAllocation.shares.shares;
                  }

                  return isChanged;
               };

               function getReservationMaxLimit() {
                  var memoryUsage = scope.vmConfigContext.environment.configTarget.resourcePool.memory;
                  var configuredMemory = scope.vmConfigContext.config.hardware.memoryMB;
                  var memoryAllocation = scope.vmConfigContext.config.memoryAllocation;
                  var vmPowerState = scope.vmConfigContext.environment.powerState;

                  var reservationMaxLimit = vmHardwareMemoryService.getReservationMaxLimit(memoryUsage,
                        configuredMemory,
                        memoryAllocation,
                        vmPowerState);

                  return reservationMaxLimit;
               }

               function rangeError() {
                  var maxAllowedMemInGB = scope.maxAllowedMemory / 1024;
                  var key: string, maxAllowedMemory: number;
                  if (maxAllowedMemInGB < 1) {
                     key = "vmHardwareMemory.invalidMemoryRangeMB";
                     maxAllowedMemory = scope.maxAllowedMemory;
                  } else {
                     key = "vmHardwareMemory.invalidMemoryRangeGB";
                     maxAllowedMemory = maxAllowedMemInGB;
                  }
                  return scope.i18n("VmUi", key,
                        scope.minAllowedMemory, numberFormatterService.format(maxAllowedMemory, {
                           fractionSize: 1,
                           trimTrailingZeros: true
                        }));
               }

               scope.$watch('vmConfigContext', function (vmConfigContext: VmConfigContext) {
                  if (!vmConfigContext) {
                     return;
                  }

                  scope.minAllowedMemory = vmHardwareMemoryService.getMinMemoryMB(scope.vmConfigContext.config, scope.vmConfigContext.environment);
                  scope.maxAllowedMemory = vmHardwareMemoryService.getMaxMemoryMB(scope.vmConfigContext.config, scope.vmConfigContext.environment);

                  if (vmConfigContext.config) {
                     scope.memoryIncrementSize = vmConfigContext.config.hotPlugMemoryIncrementSize || 4;
                  } else {
                     scope.memoryIncrementSize = 4;
                  }

                  scope.memoryErrorMessages = {
                     max: rangeError,
                     min: rangeError,
                     mod: scope.i18n("VmUi", "VmMemory.ErrorMultiple", scope.memoryIncrementSize + " MB")
                  };
               });

               scope.isMemoryDisabled = function () {
                  return vmHardwareMemoryService.isMemoryDisabled(scope.vmConfigContext.config,
                        scope.vmConfigContext.environment,
                        scope.vmConfigContext.privileges,
                        scope.createMode);
               };

               scope.isReservationDisabled = function () {
                  return vmHardwareMemoryService.isReservationDisabled(scope.vmConfigContext.config,
                        scope.vmConfigContext.privileges,
                        scope.createMode);
               };

               scope.isMaxReservationDisabled = function () {
                  return vmHardwareMemoryService.isMaxReservationDisabled(scope.vmConfigContext.rtInfo,
                        scope.vmConfigContext.privileges,
                        scope.vmConfigContext.environment.configOption.capabilities,
                        scope.createMode);
               };

               scope.isLimitDisabled = function () {
                  return vmHardwareMemoryService.isLimitDisabled(scope.vmConfigContext.privileges,
                        scope.createMode);
               };

               scope.isSharesDisabled = function () {
                  return vmHardwareMemoryService.isSharesDisabled(scope.vmConfigContext.privileges,
                        scope.createMode);
               };

               scope.isMemoryHotPlugDisabled = function () {
                  return vmHardwareMemoryService.isMemoryHotPlugDisabled(scope.vmConfigContext.environment,
                        scope.vmConfigContext.privileges,
                        scope.selectedGuestOs,
                        scope.createMode);
               };

               scope.hasError = function () {
                  if (!_.isEmpty(scope.vmHardwareMemoryForm)) {
                     return scope.vmHardwareMemoryForm.$invalid;
                  }
                  return false;
               };

               var info = scope.vmConfigContext.config.memoryAllocation.shares;
               scope.sharesInfo = info;

               if (scope.isSharesDisabled() && info !== null) {
                  // value copy to avoid modifying underlying shares when no permissions etc.
                  scope.sharesInfo = {level: info.level, shares: info.shares, _type: ""};
               }
               scope.init_aria = function () {

                  scope.ARIA_LBL_MEMORY = scope.i18n('VmUi', 'VmMemory.Title');
                  scope.ARIA_LBL_RSRV = scope.fieldLabel('Reservation');
                  scope.ARIA_LBL_LIMIT = scope.fieldLabel('Limit');
                  scope.ARIA_LBL_SHARES = scope.fieldLabel('Shares');
               };
               scope.init_aria();
            }
         }
      };
   }]);

// Input parameters of the directive
interface VmHwMemoryScopeBase extends IScope {
   vmConfigContext: VmConfigContext;
   originalConfig: ConfigInfo;
   createMode: boolean;
   deployVmtxMode: boolean;
   expanded: boolean;
   selectedGuestOs: GuestOsDescriptor;
   vm: VmConfig;
}

// TODO bfortes - convert to angular component controller in Angular2
interface VmHwMemoryScope extends VmHwMemoryScopeBase {
   i18n(vmui: string, key: string, ...args: any[]): string;

   MAX_SHARES_VALUE: number;
   MIN_SHARES_VALUE: number;

   fieldLabel(key: string): string;

   memoryOptions: Array<any>;

   onMemoryReservationLockedToMax(): void;

   onUnitChanged(newUnit: Unit): void;

   onReservationUnitChanged(newUnit: Unit): void;

   onLimitUnitChanged(newUnit: Unit): void;

   sharesValueMap: { low: number, normal: number, high: number };
   memoryRecommendations: Recommendation[];
   limitRecommendations: Recommendation[];
   reservationRecommendations: Recommendation[];
   limits: { reservationMaxLimit: number };
   reservationErrorMessages: { max: () => string, min: () => string };
   sharesInfo: SharesInfo;

   hasChanged(): boolean;

   maxAllowedMemory: number;
   minAllowedMemory: number;
   memoryIncrementSize: number;
   memoryErrorMessages: { max: () => string, min: () => string, mod: string };

   isMemoryDisabled(): boolean;

   isReservationDisabled(): boolean;

   isMaxReservationDisabled(): boolean;

   isLimitDisabled(): boolean;

   isSharesDisabled(): boolean;

   isMemoryHotPlugDisabled(): boolean;

   hasError(): boolean;

   init_aria(): void;

   ARIA_LBL_MEMORY: string;
   ARIA_LBL_RSRV: string;
   ARIA_LBL_LIMIT: string;
   ARIA_LBL_SHARES: string;
   vmHardwareMemoryForm: any;
   memoryValidators: any;
}
