(function () {
   'use strict';

   angular
         .module('com.vmware.vsphere.client.network')
         .controller(
               'EditVnicPortPropertiesPageController',
               EditVnicPortPropertiesPageController);

   EditVnicPortPropertiesPageController.$inject = [
         '$scope',
         'networkUtil',
         'i18nService',
         'vuiConstants'];

   function EditVnicPortPropertiesPageController(
         $scope,
         networkUtil,
         i18nService,
         vuiConstants) {

      var self = this;
      var MTU_ERROR = i18nService.getString(
            'NetworkUi',
            'AddNetworkingWizard.conPropPage.mtu.inputErrorMessage');

      $scope.manager.vnicData.portPropertiesPageModel.onCommit = onCommit;

      self.pageModel = $scope.manager.vnicData.portPropertiesPageModel;
      self.isTcpIpStackSupported = self.pageModel.hostVnicFeatures.isTcpIpStackSupported;
      self.errorTooltip = {
         message: MTU_ERROR,
         isVisible: !networkUtil.isMtuValid(self.pageModel.mtuInBytes)
      };

      self.validateMtu = validateMtu;

      function validateMtu() {
         self.errorTooltip.isVisible =
               !networkUtil.isMtuValid(self.pageModel.mtuInBytes);
      }

      function onCommit() {
         var isMtuDirty = $scope.nwEditVnicPortPropertiesPageForm.mtu.$dirty;
         $scope.manager.vnicData.portPropertiesPageModel.dirty =
               !!$scope.manager.vnicData.portPropertiesPageModel.dirty || isMtuDirty;

         if (isMtuDirty && !networkUtil.isMtuValid(self.pageModel.mtuInBytes)) {
            return [{
               type: vuiConstants.validationBanner.type.ERROR,
               text: MTU_ERROR
            }];
         }

         return [];
      }

      return self;
   }
})();