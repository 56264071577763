/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
/**
 * Shows failover order of connectees grouped in categories and provides the ability to add new ones.
 */
(function () {
   'use strict';

   angular
      .module('com.vmware.vsphere.client.networkLibUi')
      .directive('failoverOrder', failoverOrder);

   failoverOrder.$inject = [
         '$timeout',
         'FailoverOrderDatagridManager'
   ];

   function failoverOrder(
         $timeout,
         FailoverOrderDatagridManager) {
      var directive = {
         restrict: 'E',
         template: '<div class="fill-parent no-column-header' +
                   ' assigned-adapters-datagrid failover-order" ' +
                   'vx-stretch-grid vui-datagrid="datagridOptions" ' +
                   'vx-datagrid-preselect-item="true" reselect-on-change="true" ' +
                   'preselect-comparator="preselectComparator"></div>',
         scope: {
            failoverOrder: '=',
            onSelectionChange: '=?',
            actions: "=?",
            actionsDisabled: '=?',
            selectionMode: '=?',
            editAllItems: '=?'
         },
         replace: false,
         link: {
            pre: function ($scope) {

               var failoverOrderDatagridManager = new FailoverOrderDatagridManager(
                     $scope.failoverOrder, $scope.actions,
                     $scope.selectionMode, $scope.editAllItems,
                     $scope.actionsDisabled);

               $scope.datagridOptions = failoverOrderDatagridManager.datagridOptions;
               $scope.preselectComparator = failoverOrderDatagridManager.preselectComparator;
               $scope.$watch(function () {
                  return $scope.datagridOptions.selectedItems;
               }, function (selectedItems) {
                  var selectionChanged = failoverOrderDatagridManager
                        .selectionChanged(selectedItems);
                  if (selectionChanged && $scope.onSelectionChange) {
                     $scope.onSelectionChange(selectedItems);
                  }
               });

               $scope.$watch('actionsDisabled', function () {
                  failoverOrderDatagridManager.enableDisableAllActions(
                        $scope.datagridOptions.selectedItems,
                        $scope.actionsDisabled);
               });

               // Workaround which resizes datagrid after the action bar creation
               $timeout(function () {
                  var gridElement = $("div.assigned-adapters-datagrid .k-grid");
                  if (gridElement) {
                     var kendoGrid = gridElement.data("kendoGrid");
                     if (kendoGrid) {
                        kendoGrid.resize();
                     }
                  }
               }, 0);
            }
         }
      };
      return directive;
   }
})();
