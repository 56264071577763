/* Copyright 2018 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace h5_vm {
   /**
    * A "builder" for the ScheduleTaskPageService.
    */
   export class VmMigrationScheduleTaskPageService {
      static $inject: string[] = [
         "i18nService",
         "wizardPageService",
         "vscSchedulingHelper"
      ];


      constructor(private i18nService: any,
                  private wizardPageService: any,
                  private vscSchedulingHelper: any) {
      }

      public build(wizardScope: any, schedulingSpec: any) {

         return {
            title: this.i18nService.getString(
                  "VmUi", "wizard.scheduleTaskPage.title"),
            contentUrl: "vm-ui/resources/vm/common/scheduleTaskWizard/"
                  + "scheduleTaskPage/vmWizardScheduleTaskPage.html",

            disableNext: function() {
                  return wizardScope.migrationWizardManager
                  .vmWizardScheduleTaskPageModel.isValidating;
               },
            onCommit: () => {
               return this.onCommit(wizardScope);
            }
         };
      }

      private onCommit(wizardScope: any): boolean {
         if (wizardScope.migrationWizardManager.vmWizardScheduleTaskPageModel
               .mutationSpec.schedulingSpec.isFormStatusValid === false ||
               wizardScope.migrationWizardManager.vmWizardScheduleTaskPageModel
                     .mutationSpec.schedulingSpec.recurrence === null) {
            this.wizardPageService.markPageIncompleteWithErrors(wizardScope.wizardConfig,
                  [this.i18nService.getString("VmUi",
                        "wizard.scheduleTaskPage.invalidSchedulerError")]);
            return false;
         }

         wizardScope.wizardConfig.validationBanner.messages = [];
         this.wizardPageService.markPageComplete(wizardScope.wizardConfig,
               wizardScope.attributes.creationType);
         return true;
      }
   } // class

   angular.module("com.vmware.vsphere.client.vm").service(
         "vmMigrationScheduleTaskPageService",
         VmMigrationScheduleTaskPageService);

} // namespace
