/* Copyright 2015 VMware, Inc. All rights reserved. -- VMware Confidential */
(function() {
   'use strict';
   angular.module('com.vmware.vsphere.client.host').controller('TimeConfigSettingsController', TimeConfigSettingsController);

   TimeConfigSettingsController.$inject = ['$scope', 'dataService', 'i18nService', 'timeFormatterService'];

   function TimeConfigSettingsController($scope, dataService, i18nService, timeFormatterService) {
      var self = this;
      var objectId = $scope._route.objectId;
      var headerOptions = {
         title: i18nService.getString('HostUi', 'timeConfig.tabLabel'),
         objectId: objectId,
         actions: [{
            actionUid: 'vsphere.core.host.actions.editTimeConfigurationSettings',
            customLabel: i18nService.getString('HostUi', 'editTimeConfigurationButton.label')
         }]
      };

      self.headerOptions = headerOptions;
      self.watchForObjects = [objectId];
      self.getViewData = getViewData;
      self.data = {};
      self.liveRefreshProperties = [
         'config.dateTimeInfo.ntpConfig.configFile',
         'config.dateTimeInfo.ntpConfig.server',
         'config.firewall.ruleset',
         'config.service.service'
      ];

      getViewData();

      function getViewData() {
         dataService
               .getData(objectId, 'com.vmware.vsphere.client.h5.host.model.TimeConfigData')
               .then(function(data) {
                  if (!data) {
                     return;
                  }

                  timeFormatterService
                        .formatDate(data.currentTimeInMillis)
                        .then(function(formattedDate) {
                           self.data = data;
                           self.currentTime = formattedDate;
                        });
               });
      }
   }
})();
