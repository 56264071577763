/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
(function () {
   'use strict';

   angular
         .module('com.vmware.vsphere.client.licenseLibUi')
         .service(
               'licenseValidationMessageBuilder',
               licenseValidationMessageBuilder
         );

   licenseValidationMessageBuilder.$inject = ['i18nService', 'licenseCommonUtil', '$sce'];

   function licenseValidationMessageBuilder(i18nService, licenseCommonUtil, $sce) {
      var licenseUiString = _.partial(i18nService.getString, 'LicenseLibUi');
      var ValidationMessage = licenseCommonUtil.ValidationMessage;
      var ValidationMessageType = licenseCommonUtil.ValidationMessageType;
      var ValidationMessageTextDetails =
            licenseCommonUtil.ValidationMessageTextDetails;
      var ValidationMessageTextAndListDetails =
            licenseCommonUtil.ValidationMessageTextAndListDetails;

      function buildNoLicenseSelectedValidationMessage() {
         return buildValidationMessage(
               'noLicenseSelectedMessage',
               ValidationMessageType.ALERT,
               licenseUiString('assignLicense.noLicenseSelected')
         );
      }

      function buildSuccessValidationMessage() {
         return buildValidationMessage(
               'validationSuccessMessage',
               ValidationMessageType.OK,
               licenseUiString('assignLicense.validationSuccess')
         );
      }

      function buildLicenseFromAnotherVcValidationMessage() {
         return buildValidationMessageWithTextDetails(
               'licenseFromAnotherVcValidationMessage',
               ValidationMessageType.WARNING,
               licenseUiString('assignLicense.licenseFromAnotherVcSelected.short'),
               licenseUiString('assignLicense.licenseFromAnotherVcSelected.long')
         );
      }

      function buildEmbeddedOemLicenseUnassignableValidationMessage() {
         return buildValidationMessage(
               'embeddedOemUnassignableValidationMessage',
               ValidationMessageType.ALERT,
               licenseUiString('assignLicense.unassignableEoemLicense')
         );
      }

      function buildAddHostEmbeddedOemLicenseUnassignableValidationMessage() {
         return buildValidationMessage(
               'embeddedOemUnassignableValidationMessage',
               ValidationMessageType.ALERT,
               licenseUiString('assignLicense.unassignableEoemLicense.addHost')
         );
      }

      function buildEmbeddedOemLicenseAssignValidationMessage() {
         return buildValidationMessage(
               'embeddedOemAssignValidationMessage',
               ValidationMessageType.WARNING,
               licenseUiString('assignLicense.embeddedOemLicenseAssign')
         );
      }

      function buildEmbeddedOemLicenseUnassignValidationMessage() {
         return buildValidationMessage(
               'embeddedOemUnassignValidationMessage',
               ValidationMessageType.WARNING,
               licenseUiString('assignLicense.embeddedOemLicenseUnassign')
         );
      }

      function buildAssetDlfVersionValidationMessage() {
         return buildValidationMessageWithTextDetails(
               'assetDlfVersionValidationMessage',
               ValidationMessageType.WARNING,
               licenseUiString('assignLicense.unsupportedDlfVersion.short'),
               licenseUiString('assignLicense.unsupportedDlfVersion.long')
         );
      }

      function buildFeaturesInUseValidationMessage(unsupportedFeaturesInUse /*Array.<Feature>*/,
            assetName /*string*/) {

         return buildFeaturesValidationMessage(
               'featuresInUseValidationMessage',
               ValidationMessageType.ALERT,
               licenseUiString('assignLicense.unsupportedFeaturesInUse.short'),
               unsupportedFeaturesInUse,
               assetName,
               licenseUiString('assignLicense.unsupportedFeaturesInUse.long')
         );
      }

      function buildAvailableFeaturesValidationMessage(unsupportedAvailableFeatures /*Array.<Feature>*/,
            assetName /*string*/) {

         return buildFeaturesValidationMessage('availableFeaturesValidationMessage',
               ValidationMessageType.WARNING,
               licenseUiString('assignLicense.unsupportedAvailableFeatures.short'),
               unsupportedAvailableFeatures,
               assetName,
               licenseUiString('assignLicense.unsupportedAvailableFeatures.long')
         );
      }

      function buildInsufficientCapacityValidationMessage(usageValidationItems /*Array.<UsageValidationItem>*/) {

         return buildUsageValidationMessage(
               'insufficientCapacityValidationMessage',
               ValidationMessageType.ALERT,
               licenseUiString('assignLicense.insufficientCapacity.short'),
               licenseUiString('assignLicense.insufficientCapacity.long'),
               usageValidationItems
         );
      }

      function buildUnavailableUsageDataValidationMessage(usageValidationItems /*Array.<UsageValidationItem>*/) {

         return buildUsageValidationMessage(
               'unavailableUsageDataValidationMessage',
               ValidationMessageType.WARNING,
               licenseUiString('assignLicense.unavailableUsageData.short'),
               licenseUiString('assignLicense.unavailableUsageData.long'),
               usageValidationItems
         );
      }

      function buildUnknownCapacitySufficiencyValidationMessage(usageValidationItems /*Array.<UsageValidationItem>*/) {

         return buildUsageValidationMessage(
               'unavailableUsageDataValidationMessage',
               ValidationMessageType.WARNING,
               licenseUiString('assignLicense.unknownCapacitySufficiency.short'),
               licenseUiString('assignLicense.unknownCapacitySufficiency.long'),
               usageValidationItems
         );
      }

      function buildLicenseExpiringValidationMessage(licenseExpirationRemainingDays /*int > 0*/) {
         return buildValidationMessage(
               'licenseExpiringValidationMessage',
               ValidationMessageType.WARNING,
               licenseUiString(
                     'assignLicense.licenseExpiring',
                     licenseExpirationRemainingDays
               )
         );
      }

      function buildLicenseExpiredValidationMessage() {
         return buildValidationMessage(
               'licenseExpiredValidationMessage',
               ValidationMessageType.ALERT,
               licenseUiString('assignLicense.licenseExpired')
         );
      }

      function buildFeaturesValidationMessage(id,
            type,
            message,
            unsupportedFeatures /*Array.<Feature>*/,
            assetName /*string*/,
            detailsText) {

         var featuresFormattedIds /*Array.<string>*/ =
               _.map(unsupportedFeatures, function(feature) {
                  return feature.formattedId;
               });
         var featuresDelim = licenseUiString('assignLicense.unsupportedFeatures.delim');
         var featuresListText = featuresFormattedIds.join(featuresDelim);

         var detailsList = [licenseUiString(
               'assignLicense.unsupportedFeatures.format',
               assetName,
               featuresListText
         )];

         return buildValidationMessageWithTextAndListDetails(
               id,
               type,
               message,
               detailsText,
               detailsList
         );
      }

      function buildUsageValidationMessage(id,
            type,
            message,
            detailsText,
            usageValidationItems /*Array.<UsageValidationItem>*/) {

         var detailsList = _.map(usageValidationItems, function(validationItem) {
            var costUnits /*Array.<CostUnit>*/ = validationItem.costUnits;
            var costUnitsFormattedIds /*Array.<string>*/ =
                  _.map(costUnits, function(costUnit) {
                     return costUnit.formattedId;
                  });
            var costUnitsDelim = licenseUiString(
                  'assignLicense.usageValidation.delim'
            );
            var costUnitsText = costUnitsFormattedIds.join(costUnitsDelim);

            return licenseUiString(
                  'assignLicense.usageValidation.format',
                  validationItem.product.formattedName,
                  costUnitsText
            );
         });

         return buildValidationMessageWithTextAndListDetails(
               id,
               type,
               message,
               detailsText,
               detailsList
         );
      }

      function buildValidationMessage(id,
            type,
            message,
            details) {

         message = $sce.trustAsHtml(message);

         return new ValidationMessage(
               id,
               type,
               message,
               details
         );
      }

      function buildValidationMessageWithTextDetails(id,
            type,
            message,
            detailsText) {

         detailsText = $sce.trustAsHtml(detailsText);

         var details = new ValidationMessageTextDetails(
               detailsText
         );

         return buildValidationMessage(
               id,
               type,
               message,
               details);
      }

      function buildValidationMessageWithTextAndListDetails(id,
            type,
            message,
            detailsText,
            detailsList) {

         detailsText = $sce.trustAsHtml(detailsText);
         detailsList = _.map(detailsList, function (detailsListItem) {
            return $sce.trustAsHtml(detailsListItem);
         });

         var details = new ValidationMessageTextAndListDetails(
               detailsText,
               detailsList
         );

         return buildValidationMessage(
               id,
               type,
               message,
               details);
      }

      return {
         buildNoLicenseSelectedValidationMessage: buildNoLicenseSelectedValidationMessage,
         buildSuccessValidationMessage: buildSuccessValidationMessage,
         buildLicenseFromAnotherVcValidationMessage: buildLicenseFromAnotherVcValidationMessage,
         buildEmbeddedOemLicenseUnassignableValidationMessage: buildEmbeddedOemLicenseUnassignableValidationMessage,
         buildEmbeddedOemLicenseAssignValidationMessage: buildEmbeddedOemLicenseAssignValidationMessage,
         buildEmbeddedOemLicenseUnassignValidationMessage: buildEmbeddedOemLicenseUnassignValidationMessage,
         buildAddHostEmbeddedOemLicenseUnassignableValidationMessage: buildAddHostEmbeddedOemLicenseUnassignableValidationMessage,
         buildAssetDlfVersionValidationMessage: buildAssetDlfVersionValidationMessage,
         buildFeaturesInUseValidationMessage: buildFeaturesInUseValidationMessage,
         buildAvailableFeaturesValidationMessage: buildAvailableFeaturesValidationMessage,
         buildInsufficientCapacityValidationMessage: buildInsufficientCapacityValidationMessage,
         buildUnavailableUsageDataValidationMessage: buildUnavailableUsageDataValidationMessage,
         buildUnknownCapacitySufficiencyValidationMessage: buildUnknownCapacitySufficiencyValidationMessage,
         buildLicenseExpiringValidationMessage: buildLicenseExpiringValidationMessage,
         buildLicenseExpiredValidationMessage: buildLicenseExpiredValidationMessage
      };
   }
}());
