(function() {
   'use strict';
   angular.module('com.vmware.vsphere.client.host').service('editSmartCardAuthService',
         EditSmartCardAuthService);

   EditSmartCardAuthService.$inject = ['timeFormatterService', 'i18nService', 'mutationService', 'vuiConstants'];

   function EditSmartCardAuthService(timeFormatterService, i18nService, mutationService, vuiConstants) {
      function getColumnDefs(userTimeFormat) {
         return [
            {
               field: 'subject',
               sortable: false,
               searchable: false,
               displayName: i18nService.getString('HostUi',
                     'smartCardAuthnetication.certificates.subject')
            },
            {
               field: 'issuer',
               sortable: false,
               searchable: false,
               displayName: i18nService.getString('HostUi',
                     'smartCardAuthnetication.certificates.issuer')
            },
            {
               field: 'version',
               sortable: false,
               searchable: false,
               displayName: i18nService.getString('HostUi',
                     'smartCardAuthnetication.certificates.version')
            },
            {
               field: 'validFrom',
               sortable: false,
               searchable: false,
               displayName: i18nService.getString('HostUi',
                     'smartCardAuthnetication.certificates.validFrom'),
               template: function(data) {
                  return timeFormatterService.timestampToText(
                        parseInt(data.validFrom),
                        userTimeFormat
                  );
               }
            },
            {
               field: 'validTo',
               sortable: false,
               searchable: false,
               displayName: i18nService.getString('HostUi',
                     'smartCardAuthnetication.certificates.validTo'),
               template: function(data) {
                  return timeFormatterService.timestampToText(
                        parseInt(data.validTo),
                        userTimeFormat
                  );
               }
            },
            {
               field: 'algorithm',
               sortable: false,
               searchable: false,
               displayName: i18nService.getString('HostUi',
                     'smartCardAuthnetication.certificates.algorithm')
            },            {
               field: 'publicKey',
               sortable: false,
               searchable: false,
               displayName: i18nService.getString('HostUi',
                     'smartCardAuthnetication.certificates.publicKeyAlgorithm')
            }];
      }

      function getDataGridOptions(userTimeFormat) {
         return {
            columnDefs: getColumnDefs(userTimeFormat),
            data: [],
            selectionMode: vuiConstants.grid.selectionMode.SINGLE,
            selectedItems: [],
            actionBarOptions: {
               actions: []
            },
            resizable: false,
            height: '275px'
         };
      }

      function validateCertificate(objectId, file) {
         // Needs a conversion from an Int8Array to a normal JS array, as the Int8Array could not be send
         // to backend ( we expect byte array there), and an error is thrown if we send an Int8Array
         var byteArray = new Int8Array(file);
         var normalArray = Array.prototype.slice.call(byteArray);
         // validate the file to be added as certificate, if correct a X509Spec Object containing the certificate as a string, is returned
         return mutationService.validate(
               objectId,
               'com.vmware.vsphere.client.host.DecodePemFileSpec',
               {
                  file: normalArray
               });
      }


      function updateCertificateData(objectId, originalData, modifiedData,
            isSmartCardAuthenticationEnabled, isSmartCardAuthenticationEnabledOldState) {

         function certificateArrayToSerialNumberToCertificateMap(certificates) {
            return _.object(
                  _.map(certificates, function(certificate) {
                     return certificate.serialNumber;
                  }),
                  certificates
            );
         }

         var originalDataSerialNumberToCertificateMap = certificateArrayToSerialNumberToCertificateMap(originalData);
         var modifiedDataSerialNumberToCertificateMap = certificateArrayToSerialNumberToCertificateMap(modifiedData);

         var addCertificates = _.map(
               _.filter(
                     modifiedData,
                     function(item) {
                        return !originalDataSerialNumberToCertificateMap.hasOwnProperty(item.serialNumber);
                     }
               ),
               function(item) {
                  return item.certificateAsString;
               }
         );

         var removeCertificates = _.map(
               _.filter(
                     originalData,
                     function(item) {
                        return !modifiedDataSerialNumberToCertificateMap.hasOwnProperty(item.serialNumber);
                     }
               ),
               function(item) {
                  return {
                     algorithm: item.algorithm,
                     thumbprint: item.thumbprint
                  };
               });

         var enableStateChanged = isSmartCardAuthenticationEnabled !== isSmartCardAuthenticationEnabledOldState;
         var smartCardSpec = {
            removeCertificates: removeCertificates,
            addCertificates: addCertificates,
            enable: enableStateChanged ? isSmartCardAuthenticationEnabled : null
         };

         // If we have changes than the mutation operation should be invoked
         // Otherwise we should not make a request
         if (!_.isEmpty(removeCertificates) || !_.isEmpty(addCertificates) || enableStateChanged) {
            mutationService.apply(
                  objectId,
                  'com.vmware.vsphere.client.host.SmartCardAuthSettingsSpec',
                  smartCardSpec
            );
         }
         return true;
      }


      return {
         getDataGridOptions: getDataGridOptions,
         validateCertificate: validateCertificate,
         updateCertificateData: updateCertificateData
      };
   }
})
();

