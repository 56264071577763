namespace storage_ui {

   export class StorageProviderItemGeneralDetails {

      public propertyViewData: any;

      public storageProviderListItem: StorageProviderListItem;

      public static $inject = ["storageProvidersItemBuilderService"];

      constructor(private storageProvidersItemBuilderService: StorageProvidersItemBuilderService) {
      }

      public $onChanges() {
         this.refresh();
      }

      public refresh() {
         if (!this.storageProviderListItem) {
            return;
         }

         if (this.storageProviderListItem._isStorageProviderItem) {
            this.propertyViewData = this.storageProvidersItemBuilderService.getStorageProviderGeneralDetails(
                  this.storageProviderListItem);
         } else if (this.storageProviderListItem._isStorageArrayItem) {
            this.propertyViewData = this.storageProvidersItemBuilderService.getStorageArrayGeneralDetails(
                  this.storageProviderListItem);
         }
      }
   }

   angular.module("com.vmware.vsphere.client.storage")
         .component("storageProviderItemGeneralDetails", {
            template: "<vx-property-view data=\"$ctrl.propertyViewData\"></vx-property-view>",
            bindings: {
               storageProviderListItem: "<"
            },
            controller: StorageProviderItemGeneralDetails
         });
}
