/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace dvs_ui {

   angular.module("com.vmware.vsphere.client.dvs").constant("dvsCreateWizardConstants", {
      page: {
         DVS_SELECT_NAME_AND_LOCATION_PAGE: "selectNameAndLocationPage",
         DVS_SELECT_VERSION_PAGE: "selectVersionPage",
         DVS_CONFIGURE_SETTINGS_PAGE: "configureSettingsPage",
         DVS_READY_TO_COMPLETE_PAGE: "readyToCompletePage"
      },
      properties: {
         DVS_NEW_UNIQUE_NAME: "newUniqueDvsName",
         DVPG_NEW_UNIQUE_NAME: "newUniquePortGroupName",
         PRIMARY_ICON_ID: "primaryIconId",
         LOCATION_NAME_PROPERTY: "name",
         SUPPORTED_PRODUCT_SPEC: "supportedProductSpec"
      },
      spec: {
         DVS_CREATE_WIZARD_MUTATION_SPEC: "com.vmware.vsphere.client.h5.network.dvs.create.model.DvsCreateWizardMutationSpec",
         DVS_CREATE_SPEC: "com.vmware.vim.binding.vim.DistributedVirtualSwitch$CreateSpec",
         DVS_CONFIG_SPEC: "com.vmware.vim.binding.vim.dvs.VmwareDistributedVirtualSwitch$ConfigSpec",
         DVS_PRODUCT_SPEC: "com.vmware.vim.binding.vim.dvs.ProductSpec",
         DVS_UPLINK_PORT_POLICY: "com.vmware.vim.binding.vim.DistributedVirtualSwitch$NameArrayUplinkPortPolicy"
      },
      niocVersions: {
         na: null,
         _2: "version2",
         _3: "version3"
      },
      lacpVersions: {
         na: null,
         singleLag: "singleLag",
         multipleLag: "multipleLag"
      },
      defaultUplinkName: "Uplink "
   });
}
