(function() {
   'use strict';

   /**
    * @name com.vmware.vsphere.client.host:imageProfileEvents
    *
    * @description
    *    Represents image profile events for activity notifications.
    *
    *    List of available constants
    *
    *    - **`UPDATE_HOST_IMAGE_PROFILE_ACCEPTANCE_LEVEL`** : The event has to be dispatched when
    *    the host image profile acceptance level has been changed.
    */
   angular
         .module('com.vmware.vsphere.client.host')
         .constant('imageProfileEvents', {
            UPDATE_HOST_IMAGE_PROFILE_ACCEPTANCE_LEVEL: 'updateHostImageProfileAcceptanceLevel'
         });
})();
