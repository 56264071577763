namespace h5_vm {
   import ManagedObjectReference = com.vmware.vim.binding.vmodl.ManagedObjectReference;
   import IPromise = angular.IPromise;
   import MigrateOrMoveValidationSpec = com.vmware.vsphere.client.h5.vm.data.MigrateOrMoveValidationSpec;
   import MigrateOrMoveValidationData = com.vmware.vsphere.client.h5.vm.model.MigrateOrMoveValidationData;
   import IQService = angular.IQService;
   export interface DestinationTarget {
      dropTarget: string;
   }

   export interface AlertProperties {
      title: string;
      message: string;
   }

   export interface MoveOrMigrateData {
      moveVms: string[];
      moveTarget: string;
      migrateVms: string[];
      migrateTarget: string;
   }

   /**
    * Implements the action code which is invoked when the user drops a VM over some target
    */
   export class VmDragAndDropService {
      public static $inject: string[] = [
         "actionsService",
         "defaultUriSchemeUtil",
         "managedEntityConstants",
         "i18nService",
         "actionConfirmationService",
         "$q",
         "mutationService"
      ];

      private readonly MOVE_ACTION_ID =
            "vsphere.core.vm.actions.moveVmOrTemplateFromDragAndDrop";
      private readonly MIGRATE_ACTION_ID = "vsphere.core.vm.actions.migrate";
      private readonly MIGRATE_NETWORKING_BY_DRAG_ACTION_ID =
            "vsphere.core.dvs.migrateVmNetworkingDragAndDropAction";

      private _actionsService: any;
      private _defaultUriSchemeUtil: any;
      private _managedEntityConstants: any;
      private _i18nService: any;
      private _actionConfirmationService: any;
      private _q: IQService;
      private _mutationService: any;

      public constructor(actionsService: any, defaultUriSchemeUtil: any,
            managedEntityConstants: any, i18nService: any, actionConfirmationService: any,
            $q: IQService, mutationService: any) {
         this._actionsService = actionsService;
         this._defaultUriSchemeUtil = defaultUriSchemeUtil;
         this._managedEntityConstants = managedEntityConstants;
         this._i18nService = i18nService;
         this._actionConfirmationService = actionConfirmationService;
         this._q = $q;
         this._mutationService = mutationService;
      }

      /**
       * Decides which action to be invoked depending on the destinationTarget's type
       * and whether or not the action is allowed for multiple objects
       * @param availableTargets
       * @param destinationTarget
       * @param isMulti
       */
      public moveOrMigrateVm(availableTargets: string[],
            destinationTarget: DestinationTarget): void {
         let targetType: string = this._defaultUriSchemeUtil.getEntityType(destinationTarget.dropTarget);
         let targetIsFolder: boolean = !!destinationTarget
               && !!destinationTarget.dropTarget
               && (targetType === this._managedEntityConstants.FOLDER
               || targetType === this._managedEntityConstants.DATACENTER);
         if (targetIsFolder) {
            this.invokeAction(availableTargets, destinationTarget, this.MOVE_ACTION_ID);
            return;
         }

         if (targetType === this._managedEntityConstants.NETWORK
               || targetType === this._managedEntityConstants.OPAQUE_NETWORK
               || targetType === this._managedEntityConstants.DISTRIBUTED_VIRTUAL_PORTGROUP) {
            this._actionsService.getAction(
                  this.MIGRATE_NETWORKING_BY_DRAG_ACTION_ID, destinationTarget.dropTarget)
                  .then((actionEval: any = {}) => {
                     if (actionEval.available) {
                        this._actionsService.invokeAction(actionEval, availableTargets, destinationTarget);
                     }
                  });
            return;
         }

         this.invokeAction(availableTargets, destinationTarget, this.MIGRATE_ACTION_ID);
      }

      /**
       * Evaluates the action with action service for the given targets
       * If the action is available checks if the destination target is valid for
       * the supplied virtual machines(availableTargets)
       * if the action is not available or an error is returned from the validation
       * a warning message is shown
       *
       * @param availableTargets
       * @param destinationTarget
       * @param actionId
       */
      private invokeAction(availableTargets: string[], destinationTarget: DestinationTarget, actionId: string): void {
         this._actionsService.getAction(actionId, availableTargets).then((actionEval: any = {}) => {
            actionEval.action.label = this._i18nService.getString("VmUi", "dialogMoveToVmLabel");
            if (actionEval.available === true) {
               this.buildConfirmationMessages(actionEval);
               this._actionsService.invokeAction(actionEval, destinationTarget);
            } else {
               this.displayWarning(actionEval.action);
            }
         });
      }

      private buildConfirmationMessages(actionEval: any) {
         // No longer custom
         // Now when passing a second time through the action confirmation service
         // it will handle the managed vms
         actionEval.action.confirmationText = "";
         actionEval.action.confirmationTextSome =
               this._i18nService.getString("Common", "defaultConfirmation.someOK");
         actionEval.action.confirmationTextAll =
               this._i18nService.getString("Common", "defaultConfirmation.allOK");
      }

      /**
       * Displays a warning message
       * if it is passed as parameter(waning)
       * if no message is passed it is retrieved from the action
       * if no message is found the default warning is shown
       * @param action
       * @param warning
       */
      private displayWarning(action: any = {}, warning: string = "") {
         let confirmationTitle: string = !!action.confirmationTitle ?
               action.confirmationTitle :
               action.label;
         let confirmationText: string = warning ||
               action.confirmationTextNone ||
               this._i18nService.getString("Common", "defaultConfirmation.noneOK");
         let alertProperties: AlertProperties = {
            title: confirmationTitle,
            message: confirmationText
         };
         this._actionConfirmationService.showWarningDialog(alertProperties);
      }

      /**
       * Separates the provided available targets into two arrays:
       * moveVms - vms for which the move action will be invoked
       * migrateVms - vms for which the migrate wizard will be open
       * For targets of types HOST, CLUSTER, RESOURCE_POOL, VAPP
       * makes a request to the backend for the needed calculations
       * @param destinationTarget
       * @param availableTargets
       * @returns {any}
       */
      public retrieveMoveOrMigrateData(destinationTarget: string, availableTargets: string[]): IPromise<MoveOrMigrateData> {
         let target: ManagedObjectReference =
               this._defaultUriSchemeUtil.getManagedObjectReference(destinationTarget);
         let result: MoveOrMigrateData = {
            moveVms: [],
            moveTarget: destinationTarget,
            migrateVms: availableTargets,
            migrateTarget: destinationTarget
         };
         switch (target.type) {
            case this._managedEntityConstants.HOST:
            case this._managedEntityConstants.CLUSTER:
            case this._managedEntityConstants.RESOURCE_POOL:
            case this._managedEntityConstants.V_APP:
               let spec: MigrateOrMoveValidationSpec = new MigrateOrMoveValidationSpec();
               spec.target = target;
               spec.entities = this._defaultUriSchemeUtil.getManagedObjectReferences(availableTargets);
               let operationName = this._i18nService.getString("VmUi", "actionMoveVmLabel");
               return this._mutationService.validateSpec(spec._type, spec, operationName).then((data: any) => {
                  if (!data.result || data.error) {
                     return {
                        moveVms: [],
                        moveTarget: "",
                        migrateVms: [],
                        migrateTarget: ""
                     };
                  }

                  let migrateOrMoveValidationData: MigrateOrMoveValidationData = data.result;
                  result.moveVms = this._defaultUriSchemeUtil
                        .getVsphereObjectIds(migrateOrMoveValidationData.moveVms);
                  result.moveTarget = this._defaultUriSchemeUtil
                        .getVsphereObjectId(migrateOrMoveValidationData.moveTarget);
                  result.migrateVms = this._defaultUriSchemeUtil
                        .getVsphereObjectIds(migrateOrMoveValidationData.migrateVms);
                  result.migrateTarget = this._defaultUriSchemeUtil
                        .getVsphereObjectId(migrateOrMoveValidationData.migrateTarget);
                  return result;
               });
            case this._managedEntityConstants.DATASTORE:
            case this._managedEntityConstants.STORAGE_POD:
            default:
               return this._q.when(result);
         }
      }
   }

   angular.module("com.vmware.vsphere.client.vm")
         .service("vmDragAndDropService", VmDragAndDropService);
}
