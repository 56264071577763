/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace network_ui {

   import PhysicalNicEditSpec = com.vmware.vsphere.client.network.PhysicalNicEditSpec;
   import LinkSpeedDuplex = com.vmware.vim.binding.vim.host.PhysicalNic$LinkSpeedDuplex;
   import EditHostPnicModel = com.vmware.vsphere.client.h5.network.host.pnic.model.EditHostPnicModel;
   import SriovConfig = com.vmware.vim.binding.vim.host.SriovConfig;

   export class EditPnicDialogModel {
      /**
       * The name of the physical adapter.
       */
      public name: string;

      /**
       * The current value set for linkSpeedDuplex.
       */
      public linkSpeed: LinkSpeedDuplex;

      /**
       * The available linkSpeedDuplex options for the current physical adapter.
       */
      public linkSpecification: LinkSpeedDuplex[];

      /**
       * Shows if SR-IOV is available on the pnic.
       */
      public sriovCapable: boolean;

      /**
       * Shows if SR-IOV is active on the pnic.
       */
      public sriovActive: boolean;

      /**
       * Shows if SR-IOV is enabled on the pnic.
       */
      public sriovEnabled: boolean;

      /**
       * Shows the number of virtual functions if SR-IOV is active on the pnic.
       */
      public numVirtualFunction: number;

      /**
       * Shows the number of virtual functions requested when the host is restarted.
       */
      public numVirtualFunctionsRequested: number;

      /**
       * Shows the number of virtual functions supported for the pnic.
       */
      public maxVirtualFunctionSupported: number;

      /**
       * The pci of the physical adapter.
       */
      public pnicPci: string;

      constructor(data: EditHostPnicModel) {
         if (data) {
            this.name = data.name;
            if (data.linkSpeed) {
               this.linkSpeed = new LinkSpeedDuplex();
               this.linkSpeed.duplex = data.linkSpeed.duplex;
               this.linkSpeed.speedMb = data.linkSpeed.speedMb;
            }
            if (data.linkSpecification) {
               this.linkSpecification = [];
               data.linkSpecification.forEach((currentLinkSpeed) => {
                  // We need to store the same reference as 'this.linkSpeed' for the
                  // ng-option directive to be able to match the current model's value to
                  // one of the select's options
                  if (this.equals(currentLinkSpeed, this.linkSpeed)) {
                     this.linkSpecification.push(this.linkSpeed);
                  } else {
                     this.linkSpecification.push(currentLinkSpeed);
                  }
               });
            }
            if (data.sriovInfoItem && data.sriovInfoItem.sriovCapable) {
               this.sriovCapable = data.sriovInfoItem.sriovCapable;
               this.sriovActive = data.sriovInfoItem.sriovActive;
               this.sriovEnabled = data.sriovInfoItem.sriovEnabled;
               this.numVirtualFunction = data.sriovInfoItem.numVirtualFunction;
               this.numVirtualFunctionsRequested =
                     data.sriovInfoItem.numVirtualFunctionsRequested;
               this.maxVirtualFunctionSupported =
                     data.sriovInfoItem.maxVirtualFunctionSupported;
               this.pnicPci = data.pci;
            }
         }
      }

      public buildSpec(initialSpec: EditPnicDialogModel): PhysicalNicEditSpec | null {
         let spec: PhysicalNicEditSpec = new PhysicalNicEditSpec();

         spec.device = this.name;

         let linkSpeedChanged = !this.equals(this.linkSpeed, initialSpec.linkSpeed);

         let changedSRIOV: boolean = initialSpec.sriovEnabled !== this.sriovEnabled
               || (this.sriovEnabled
                  && initialSpec.numVirtualFunctionsRequested !== this.numVirtualFunctionsRequested);

         if (linkSpeedChanged && !this.isAutoNegotiate(this.linkSpeed)) {
            spec.linkSpeed = this.linkSpeed;
         }

         if (changedSRIOV) {
            let sriovConfig: SriovConfig = new SriovConfig();
            sriovConfig.sriovEnabled = this.sriovEnabled;

            sriovConfig.numVirtualFunction = this.sriovEnabled
                  ? this.numVirtualFunctionsRequested
                  : 0;

            sriovConfig.id = this.pnicPci;
            spec.sriovConfig = sriovConfig;
         }

         return changedSRIOV || linkSpeedChanged ? spec : null;
      }

      private isAutoNegotiate(linkSpeed: LinkSpeedDuplex): boolean {
         return linkSpeed.speedMb === 0 && linkSpeed.duplex === false;
      }

      private equals(src: LinkSpeedDuplex, dest: LinkSpeedDuplex): boolean {
         if (!src || !dest) {
            return false;
         }
         return src.speedMb === dest.speedMb &&
               src.duplex === dest.duplex;
      }
   }
}
