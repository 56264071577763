angular
   .module('com.vmware.vsphere.client.vm')
   .service('vmProvisioningSelectVmVersionPageService', [
      'i18nService',
      'wizardPageService',
      'vmVuiWizardPageBuilder',
      'VmProvisioningSelectVmVersionPageModel',
   function (
      i18nService,
      wizardPageService,
      vmVuiWizardPageBuilder,
      VmProvisioningSelectVmVersionPageModel) {
      return {
         build: function (wizardScope) {
            var selectVmVersionPageModel = new VmProvisioningSelectVmVersionPageModel(
               wizardScope.vmParams,
               wizardScope.wizardViewData
            );
            wizardScope.selectVmVersionPageModel = selectVmVersionPageModel;

            return vmVuiWizardPageBuilder.buildVuiWizardPage(wizardScope.config, {
               title: i18nService.getString('VmUi', 'SelectVmVersionPage.Title'),
               description: i18nService.getString('VmUi', 'SelectVmVersionPage.Description'),
               contentUrl: 'vm-ui/resources/vm/views/createVmWizard/vmProvisioningVmVersion.html',
               model: selectVmVersionPageModel
            });
         }
      };
   }
]);
