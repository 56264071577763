(function () {
   'use strict';

   /**
    * Provides faults text data.
    */
   angular.module('com.vmware.vsphere.client.cluster')
         .factory('drsFaultsService', drsFaultsService);

   drsFaultsService.$inject = ['i18nService', 'drsConstants', 'drsFaultsViewConstants',
         'drsFaultsViewService', 'iconService', 'feedbackService'];

   function drsFaultsService(i18nService, drsConstants, drsFaultsViewConstants,
         drsFaultsViewService, iconService, feedbackService) {

      return {
         getFaultMasterData: getFaultMasterData,
         getFaultDetailsData: getFaultDetailsData
      };

      function getDrsMigrationErrorLocalization(reason) {
         return i18nService.getString('Common', 'drs.migration.error.' + reason);
      }

      function getFaultMasterData(fault) {
         var target = {};

         if (fault.target) {
            var interpolationItem = {};

            interpolationItem[drsFaultsViewConstants.ID_KEY] = fault.target.provider;
            interpolationItem[drsFaultsViewConstants.NAME_KEY] = fault.target.name;
            var interpolations = [interpolationItem];

            target[drsFaultsViewConstants.TEXT_KEY] =
                  drsFaultsViewService.interpolateWithLinks('{0}', interpolations);
            target[drsFaultsViewConstants.ICON_KEY] =
                  iconService.getByObjectRef(fault.target.provider);
         }

         var gridItem = {};
         gridItem[drsFaultsViewConstants.LOCALIZED_REASON] =
               getDrsMigrationErrorLocalization(fault.reason);
         gridItem[drsFaultsViewConstants.REASON] = fault.reason;
         gridItem[drsFaultsViewConstants.TARGET_KEY] = fault.target ? target : '';
         gridItem[drsFaultsViewConstants.DETAILS_KEY] = fault.details;
         gridItem[drsFaultsViewConstants.ICON_KEY] = 'vsphere-icon-status-error';

         return gridItem;
      }

      function getFaultDetailsData(reason, details) {
         var gridItem = null;

         switch(reason) {
            case drsConstants.faults.DRSFAULT_POWERONVM: {
               gridItem = buildVmPlacementFaultItem(details);
               break;
            }
            case drsConstants.faults.DRSFAULT_INCREASECAP: {
               gridItem = buildHostPowerOnFaultItem(details);
               break;
            }
            case drsConstants.faults.DRSFAULT_ENTERSTANDBY: {
               gridItem = buldHostPowerOffFaultText(details);
               break;
            }
            case drsConstants.faults.DRSFAULT_INSUFFICIENT_RESOURCES: {
               var preventedRecommendation =
                     i18nService.getString('ClusterUi', 'drs.action.reconfigureHA');
               gridItem = buildGridItem(details, preventedRecommendation);
               break;
            }
            default: {
               if (!details.preventedRecommendation.targetVm) {
                  feedbackService
                        .sendDebugMessage('[DRS Faults] details.' +
                              'preventedRecommendation.targetVm is not defined', {
                           'reason': reason,
                           'details': details
                        });
               } else {
                  gridItem = buildMigrationFaultItem(details);
               }
            }
         }

         return gridItem;
      }

      // Make the "Power off host {0}" localized string with appropriate object
      // substitution.
      function buldHostPowerOffFaultText(details) {
         var preventedRecommendation = i18nService.getString('ClusterUi',
               'drs.faults.preventedRecommendation.powerOffHost');

         if (details.preventedRecommendation.targetHost) {
            preventedRecommendation = drsFaultsViewService.interpolateWithLinks(
                  i18nService.getString('ClusterUi', 'drs.action.powerOffHost'),
                  [getDestinationHostObject(details)]);
         }

         return buildGridItem(details, preventedRecommendation);
      }

      // Make the "Power on host {0}" localized string with appropriate object
      // substitution if a specific host has been recommended; otherwise, emit the
      // "Power on any host" message.
      function buildHostPowerOnFaultItem(details) {
         var preventedRecommendation = i18nService.getString('ClusterUi',
               'drs.action.powerOnAnyHost');

         if (details.preventedRecommendation.targetHost) {
            preventedRecommendation = drsFaultsViewService.interpolateWithLinks(
                  i18nService.getString('ClusterUi', 'drs.action.powerOnHost'),
                  [getDestinationHostObject(details)]);
         }

         return buildGridItem(details, preventedRecommendation);
      }

      // Make the "Place {0} at host {1}" localized string with appropriate object
      // substitution.
      function buildVmPlacementFaultItem(details) {
         var targetObjects = [
            getVmObject(details),
            getDestinationHostObject(details)
         ];

         var preventedRecommendation = drsFaultsViewService.interpolateWithLinks(
               i18nService.getString('ClusterUi', 'drs.action.placeVm'),
                     targetObjects);

         return buildGridItem(details, preventedRecommendation);
      }

      // Make the "Migrate {0} from {1} to {2}" localized string with appropriate object
      // substitution.
      function buildMigrationFaultItem(details) {
         var destinationHostLink = i18nService.getString('ClusterUi', 'anyHost');

         if (details.preventedRecommendation.targetHost) {
            destinationHostLink = getDestinationHostObject(details);
         }

         var targetObjects = [
            getVmObject(details),
            getSourceHostObject(details),
            destinationHostLink
         ];

         var preventedRecommendation =
               drsFaultsViewService.interpolateWithLinks(
                     i18nService.getString('ClusterUi', 'drs.action.migrateVm'),
                     targetObjects);

         return buildGridItem(details, preventedRecommendation);
      }

      function buildGridItem(details, preventedRecommendation) {
         var gridItem = {};
         gridItem[drsFaultsViewConstants.REASON] = buildReasonLink(
               details.faultDetailMessage, details.helpId);
         gridItem[drsFaultsViewConstants.REASON_ICON_CLASS] =
               'vsphere-icon-status-error';
         gridItem[drsFaultsViewConstants.PREVENTED_RECOMMENDATION] =
               preventedRecommendation;
         gridItem[drsFaultsViewConstants.PREVENTED_RECOMMENDATION_ICON_CLASS] =
               details.preventedRecommendationIcon;

         return gridItem;
      }

      function buildReasonLink(reason, helpId) {
         return '<a ng-click="$parent.$parent.openHelpLink(\'' + helpId + '\')">'
               + reason + '</a>';
      }

      function getSourceHostObject(details) {
         var targetVm = details.preventedRecommendation.targetVm;
         var sourceHostObject = {};
         sourceHostObject[drsFaultsViewConstants.ID_KEY] = targetVm.host;
         sourceHostObject[drsFaultsViewConstants.NAME_KEY] = targetVm.hostName;

         return sourceHostObject;
      }

      function getDestinationHostObject(details) {
         var targetHost = details.preventedRecommendation.targetHost;

         var destinationHostLink = {};
         destinationHostLink[drsFaultsViewConstants.ID_KEY] = targetHost.provider;
         destinationHostLink[drsFaultsViewConstants.NAME_KEY] = targetHost.name;

         return destinationHostLink;
      }

      function getVmObject(details) {
         var targetVm = details.preventedRecommendation.targetVm;
         var targetVmObject = {};

         targetVmObject[drsFaultsViewConstants.ID_KEY] = targetVm.provider;
         targetVmObject[drsFaultsViewConstants.NAME_KEY] = targetVm.name;

         return targetVmObject;
      }
   }
})();
