namespace h5_vm {
   import IController = angular.IComponentController;
   import VmProvisioningDeploymentOptionsPageModel = h5_vm.VmProvisioningDeploymentOptionsPageModel;

   class VmProvisioningDeploymentOptionsController implements IController {
      static $inject: string[] = ["$scope", "vmProvisioningDeploymentOptionsService"];

      constructor(private $scope: any, private vmProvisioningDeploymentOptionsService: any) {
      }

      private get pageModel(): VmProvisioningDeploymentOptionsPageModel {
         return this.$scope.vmProvisioningDeploymentConfigPageModel;
      }

      public get deploymentOptionParams(): any {
         return this.pageModel.form.deploymentOptionParams;
      }

      public $onInit() {
         this.vmProvisioningDeploymentOptionsService
               .figureOutTheItemSelectedInDeploymentOptions(this.deploymentOptionParams);
      }

      onSelectionChange(selectedOption: any): void {
         this.pageModel.form.selectedKey = selectedOption.key;
      }
   }

   angular.module("com.vmware.vsphere.client.vm")
         .controller("vmProvisioningDeploymentOptionsController", VmProvisioningDeploymentOptionsController);
}

