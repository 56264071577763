module ds_cluster_ui {

   export class StorageDrsAdvancedOptionsService {

      public static $inject = ["i18nService", "dsClusterConstants"];

      constructor(private i18nService: any, private dsClusterConstants: any) { }

      public getUnitOptions(): Object[] {
         return [
            {
               label: this.i18nService.getString("DsClusterUi", "TIME_MINUTES"),
               multiplier: 1
            }, {
               label: this.i18nService.getString("DsClusterUi", "TIME_HOURS"),
               multiplier: this.dsClusterConstants.loadBalanceInterval.HOUR_MINUTES
            }, {
               label: this.i18nService.getString("DsClusterUi", "TIME_DAYS"),
               multiplier: this.dsClusterConstants.loadBalanceInterval.DAY_MINUTES
            }
         ];
      }

      public getIntervalOptions(pageModel: any): Object {
         // TODO cpournaris: get PO feedback on the current value selection.
         let loadBalanceInterval = pageModel.loadBalanceInterval;
         return {
            "current": {
               name: this.i18nService.getString("DsClusterUi",
                     "createDsClusterWizard.runtimeSettingsPage.advancedOptions.loadBalanceInterval.Current"),
               value: loadBalanceInterval,
               formattedValue: pageModel.convertedLoadBalanceInterval.value +
               " "  + this.i18nService.getString(
                     "DsClusterUi", pageModel.convertedLoadBalanceInterval.unitKey).toLocaleLowerCase(),
               unit: pageModel.convertedLoadBalanceInterval.unit
            },
            "minimum": {
               name: this.i18nService.getString("DsClusterUi",
                     "createDsClusterWizard.runtimeSettingsPage.advancedOptions.loadBalanceInterval.Minimum"),
               value: this.dsClusterConstants.loadBalanceInterval.MIN_HOURS *
                     this.dsClusterConstants.loadBalanceInterval.HOUR_MINUTES,
               formattedValue: this.i18nService.getString("Common", "hour",
                     this.dsClusterConstants.loadBalanceInterval.MIN_HOURS, 1),
               unit: this.i18nService.getString("DsClusterUi", "TIME_HOURS")
            },
            "maximum": {
               name: this.i18nService.getString("DsClusterUi",
                     "createDsClusterWizard.runtimeSettingsPage.advancedOptions.loadBalanceInterval.Maximum"),
               value: this.dsClusterConstants.loadBalanceInterval.DAY_MINUTES *
                     this.dsClusterConstants.loadBalanceInterval.MAX_DAYS,
               formattedValue: this.i18nService.getString("Common", "days",
                     this.dsClusterConstants.loadBalanceInterval.MAX_DAYS),
               unit: this.i18nService.getString("DsClusterUi", "TIME_DAYS")
            }
         };
      }

      public getErrorMessages(): Object {
         let errorMessage: string = this.i18nService.getString("DsClusterUi",
               "createDsClusterWizard.runtimeSettingsPage.advancedOptions.loadBalanceInterval.errorRange",
               this.dsClusterConstants.loadBalanceInterval.MIN_HOURS,
               this.dsClusterConstants.loadBalanceInterval.MAX_DAYS);

         return {
            min: errorMessage,
            max: errorMessage
         };
      }

      public getIoImbalanceThresholdSliderOptions(value: number, dragHandleTitle: string): any {
         return {
            value: value,
            min: this.dsClusterConstants.loadBalanceIoThreshold.MIN,
            max: this.dsClusterConstants.loadBalanceIoThreshold.MAX,
            minLabel: this.i18nService.getString("DsClusterUi",
                  "createDsClusterWizard.runtimeSettingsPage.advancedOptions.ioLoadImbalance.minUnit"),
            maxLabel: this.i18nService.getString("DsClusterUi",
                  "createDsClusterWizard.runtimeSettingsPage.advancedOptions.ioLoadImbalance.maxUnit"),
            enabled: true,
            width: "auto",
            dragHandleTitle: dragHandleTitle
         };
      }
   }

   angular.module("com.vmware.vsphere.client.dsCluster")
         .service("storageDrsAdvancedOptionsService",
               StorageDrsAdvancedOptionsService);
}
