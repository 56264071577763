namespace h5_vm {

   import GosType = h5_vm.GosType;

   export class GosComputerNamePageController {
      public static $inject = ["$q",
         "defaultUriSchemeUtil",
         "dataService",
         "gosComputerNameValidationService",
         "i18nService"];

      private static readonly FQDN_LABEL_MAX_LENGTH = 63;
      private static readonly FQDN_MAX_LENGTH = 255;

      public nameOption: Function = h5_vm.ComputerNameOption;
      public model: GosComputerNamePageModel;

      public useVmNameSignPostMessage: string;
      public useVmNameSubscriptionParams: PopupParams;
      public enterNameIncludeUniqueNumber: string;
      public enterNameIncludeUniqueNumberSignPostMessage: string;
      public enterNameIncludeUniqueNumberSubscriptionParams: PopupParams;
      public isNameIpGeneratorDefined: boolean = false;
      public invalidNameMessage: string;
      public invalidDomainMessage: string;

      constructor(private $q: any,
            private defaultUriSchemeUtil: any,
            private dataService: any,
            private computerNameValidationService: GosComputerNameValidationService,
            private i18nService: any) {
      }

      i18n(key: string, ...args: (string | number)[]): string {
         return this.i18nService.getString("VmUi", key, args);
      }

      $onInit(): void {
         this.model.onPageCommit = this.onCommit.bind(this);

         this.invalidNameMessage = "";
         this.invalidDomainMessage = "";
         let computerNameMaxChars: number;
         if (GosType.WINDOWS === this.model.type) {
            computerNameMaxChars = GosComputerNameValidationService.MAX_CHARS_WIN;
         } else if (GosType.LINUX === this.model.type) {
            computerNameMaxChars = GosComputerNameValidationService.MAX_CHARS_LIN;
         } else {
            throw new Error("Invalid Guest OS selected.");
         }

         this.useVmNameSignPostMessage = this.i18n(
               "customizationSpec.computerNamePage.useVmName.subscription",
               computerNameMaxChars);
         this.useVmNameSubscriptionParams = {
            title: this.i18nService.getString("Common", "help"),
            message: this.useVmNameSignPostMessage
         };
         this.enterNameIncludeUniqueNumber = this.i18n(
               "customizationSpec.computerNamePage.enterName.includeUniqueNumber");
         this.enterNameIncludeUniqueNumberSignPostMessage = this.i18n(
               "customizationSpec.computerNamePage.enterName.includeUniqueNumber.subscription",
               computerNameMaxChars);
         this.enterNameIncludeUniqueNumberSubscriptionParams = {
            title: this.i18nService.getString("Common", "help"),
            message: this.enterNameIncludeUniqueNumberSignPostMessage
         };

         this.getCustomNameAvailabilityIfNeeded();
      }

      $onDestroy(): void {
         this.model.onPageCommit = undefined;
      }

      onCommit(): angular.IPromise<Array<string>> {
         let errors: Array<String> = [];
         errors = errors.concat(this.validateComputerName());
         errors = errors.concat(this.validateDomain());
         this.onNameChanged();
         this.onDomainChanged();

         return this.$q.resolve(errors);
      }


      isLinux(): boolean {
         return GosType.LINUX === this.model.type;
      }

      private validateComputerName(): string[] {
         if (h5_vm.ComputerNameOption.ENTER_NAME !== this.model.setNameOption) {
            return [];
         }

         let maxLength = this.model.type === GosType.WINDOWS ?
               GosComputerNameValidationService.MAX_CHARS_WIN :
               GosComputerNameValidationService.MAX_CHARS_LIN;

         return this.computerNameValidationService.validateComputerName(maxLength, this.model.computerName);
      }

      onNameChanged(): void {
         this.invalidNameMessage = this.validateComputerName()[0];
      }

      private validateDomain(): string[] {
         if (this.isLinux()) {
            return this.computerNameValidationService.validateDomainName(this.model.domainName);
         }
         return [];
      }

      onDomainChanged(): void {
         this.invalidDomainMessage = this.validateDomain()[0];
      }

      private getCustomNameAvailabilityIfNeeded() {
         if (this.model.isNameIpGeneratorDefined !== undefined) {
            this.isNameIpGeneratorDefined = this.model.isNameIpGeneratorDefined;
            return;
         }

         let vcUri: string = this.defaultUriSchemeUtil.getRootFolder(this.model.vcServiceGuid);
         this.dataService.getProperties(vcUri, ["isNameIpGeneratorDefined"])
               .then((data: any) => {
                  if (data) {
                     this.isNameIpGeneratorDefined = data.isNameIpGeneratorDefined;
                     this.model.isNameIpGeneratorDefined = data.isNameIpGeneratorDefined;
                  }
               });
      }
   }

   export class GosComputerNamePageComponent implements angular.IComponentOptions {
      public controller: any;
      public templateUrl: string;
      public bindings: any;

      constructor() {
         this.controller = GosComputerNamePageController;
         this.templateUrl =
               "vm-ui/resources/vm/guest-os-customization/pages/computer-name/gos-computer-name-page.component.html";
         this.bindings = {
            model: "<"
         };
      }
   }

   angular
         .module("com.vmware.vsphere.client.vm")
         .component("gosComputerNamePage", new GosComputerNamePageComponent());
}
