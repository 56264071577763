namespace network_ui {

   import IPromise = angular.IPromise;
   import IscsiPortBindingDetailsViewDataRetriever = network_ui.IscsiPortBindingDetailsViewDataRetriever;

   export class IscsiPortBindingDetailsView {

      public bindings: any;
      public controller: any;
      public templateUrl: string;

      constructor() {
         this.bindings = {
            hostId: "<",
            iscsiPortBindingItem: "<",
            isBoundPortBinding: "<"
         };

         this.controller = IscsiPortBindingDetailsViewController;
         this.templateUrl = "network-ui/resources/network/views/host/iscsi/details/IscsiPortBindingDetailsView.html";
      }
   }

   class IscsiPortBindingDetailsViewController {

      public static $inject = ["i18nService", "iscsiPortBindingDetailsService"];

      public i18n: Function;

      public hostId: string;

      public iscsiPortBindingItem: any;

      public isBoundPortBinding: boolean;

      public portGroupDetailsRetriever: IscsiPortBindingDetailsViewDataRetriever;

      public pnicDetailsRetriever: IscsiPortBindingDetailsViewDataRetriever;

      public vnicDetailsRetriever: IscsiPortBindingDetailsViewDataRetriever;

      public switchDetailsRetriever: IscsiPortBindingDetailsViewDataRetriever;

      constructor(private i18nService: any,
                  private iscsiPortBindingDetailsService: IscsiPortBindingDetailsService) {
         this.i18n = i18nService.getString;

         this.portGroupDetailsRetriever = new IscsiPortBindingDetailsViewDataRetriever(
               iscsiPortBindingDetailsService.requestPortgroupDetailsViewData.bind(iscsiPortBindingDetailsService));

         this.pnicDetailsRetriever = new IscsiPortBindingDetailsViewDataRetriever(
               iscsiPortBindingDetailsService.requestPnicDetailsViewData.bind(iscsiPortBindingDetailsService));

         this.vnicDetailsRetriever = new IscsiPortBindingDetailsViewDataRetriever(
               iscsiPortBindingDetailsService.requestVnicDetailsViewData.bind(iscsiPortBindingDetailsService));

         this.switchDetailsRetriever = new IscsiPortBindingDetailsViewDataRetriever(
               iscsiPortBindingDetailsService.requestSwitchDetailsViewData.bind(iscsiPortBindingDetailsService));
      }
   }

   angular.module("com.vmware.vsphere.client.network")
         .component("iscsiPortBindingDetailsView", new IscsiPortBindingDetailsView());
}
