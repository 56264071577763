namespace license_ui {

   export interface SelectedTabInfo {
      selectedTab: any;
      isDefault: boolean;
   }

   export class LicensesTabbedViewController {

      public static $inject = [
            "$scope",
            "vcLicensingPrivilegesService",
            "licensesManagementNavigationService",
            "licensingRouteObjectsFactoryService",
            "i18nService",
            "licensesManagementConstants"
      ];

      public readonly scopeProvider: string = "licensing_service_version1";
      public readonly PORTAL_URL: string = "https://my.vmware.com/";

      public tabs: any = [];
      public tabViewParams: any = {};
      public loading: boolean = false;
      public isGlobalLicensePrivilegeGranted: boolean = false;
      public selectedTab: any;

      private licensingRouteObject: LicensingRouteObject;

      constructor(private $scope: any,
                  private vcLicensingPrivilegesService: any,
                  private licensesManagementNavigationService: LicensesManagementNavigationService,
                  private licensingRouteObjectsFactoryService: LicensingRouteObjectsFactoryService,
                  private i18n: any,
                  private licensesManagementConstants: LicensesManagementConstants) {
      }

      public $onInit() {
         this.tabViewParams = {
            scopeProvider: this.scopeProvider
         };

         this.loading = true;
         this.vcLicensingPrivilegesService
               .checkIfGlobalLicensePrivilegeIsGranted(this.scopeProvider)
               .then((globalLicensePrivilegeGranted: boolean) => {
                  this.isGlobalLicensePrivilegeGranted = globalLicensePrivilegeGranted;
                  if (this.isGlobalLicensePrivilegeGranted) {
                     this.initializeTabs();

                     this.registerListenerForLicensesManagementNavigationRequest();
                     this.attachWatchOnSelectedTab();

                     this.tryHandleAdditionalNavigationalInformationInUrl();

                     // register listener for handling additional information in URL
                     this.$scope.$on("vxRouteChangeSuccess", () => {
                        this.tryHandleAdditionalNavigationalInformationInUrl();
                     });

                  }
               }).finally(() => {
                  this.loading = false;
               }
         );
      }

      private initializeTabs(): void {
         this.tabs = [{
            name: this.i18n.getString("LicenseLibUi", "management.name"),
            templateUrl: "license-ui/resources/license/views/management/licenses/serial-key-licenses/licensesAdministrationView.html",
            id: this.licensesManagementConstants.LICENSES_ADMINISTRATION_VIEW_TAB_ID,
            tabParams:  this.createTabParamWithTabId()
         }, {
            name: this.i18n.getString("LicenseLibUi", "management.navigation.products"),
            templateUrl: "license-ui/resources/license/views/management/licenses/products/productsAdministrationView.html",
            id: this.licensesManagementConstants.PRODUCTS_ADMINISTRATION_VIEW_TAB_ID,
            tabParams: this.createTabParamWithTabId(this.licensesManagementConstants.PRODUCTS_ADMINISTRATION_VIEW_TAB_ID)
         }, {
            name: this.i18n.getString("LicenseLibUi", "management.navigation.assets"),
            templateUrl: "license-ui/resources/license/views/management/licenses/assets/assetsAdministrationView.html",
            id: this.licensesManagementConstants.ASSETS_ADMINISTRATION_VIEW_TAB_ID,
            tabParams: this.createTabParamWithTabId(this.licensesManagementConstants.ASSETS_ADMINISTRATION_VIEW_TAB_ID)
         }];

         this.selectedTab = this.tabs[0];
      }

      private tryHandleAdditionalNavigationalInformationInUrl(): void {
         const licensingRouteObject: LicensingRouteObject | null =
               this.licensesManagementNavigationService.extractLicensingRouteObjectFromUrl();
         this.handleNavigationRequest(licensingRouteObject);
      }

      private registerListenerForLicensesManagementNavigationRequest(): void {
         this.$scope.$on(
               this.licensesManagementConstants.LICENSES_MANAGEMENT_NAVIGATION_REQUEST_EVENT,
               (event: any, licensingRouteObject: LicensingRouteObject) => {
                  event.stopPropagation();
                  this.handleNavigationRequest(licensingRouteObject);
               }
         );
      }

      private handleNavigationRequest(licensingRouteObject: LicensingRouteObject | null): void {
         if (!licensingRouteObject) {
            return;
         }

         if (licensingRouteObject.tabPaths && licensingRouteObject.tabPaths.length) {
            this.selectedTab = this.findTabById(licensingRouteObject.tabPaths[0]);
            this.licensingRouteObject = licensingRouteObject;
            this.licensesManagementNavigationService
                  .navigateByProvidedLicensingRouteObject(licensingRouteObject);

            this.broadcastEventForLicensesManagementNavigationPerformed(licensingRouteObject);
         } else {
            this.selectedTab = this.tabs[0];
            this.navigateToDefaultLicensingTab(licensingRouteObject.itemId);

            this.broadcastEventForLicensesManagementNavigationPerformed(licensingRouteObject);
         }
      }

      private navigateToDefaultLicensingTab(itemId?: string): void {
         const licensesListRouteObject: LicensingRouteObject =
               this.licensingRouteObjectsFactoryService.createLicensesListLicensingRouteObject(itemId);
         this.licensesManagementNavigationService.navigateByProvidedLicensingRouteObject(licensesListRouteObject);
      }

      private broadcastEventForLicensesManagementNavigationPerformed(
            licensingRouteObject: LicensingRouteObject): void {
         this.$scope.$broadcast(
               this.licensesManagementConstants.LICENSES_MANAGEMENT_NAVIGATION_PERFORMED_EVENT,
               licensingRouteObject
         );
      }

      private broadcastEventForLicensesManagementSelectedTabChanged(newTab: any, isDefault: boolean = false): void {
         const selectedTabInfo: SelectedTabInfo = {
            selectedTab: newTab,
            isDefault: isDefault
         };

         this.$scope.$broadcast(
               this.licensesManagementConstants.LICENSES_MANAGEMENT_SELECTED_TAB_CHANGED_EVENT,
               selectedTabInfo
         );
      }

      private attachWatchOnSelectedTab(): void {
         this.$scope.$watch(() => {
            return this.selectedTab;
         }, (newTab: any) => {
            if (!newTab || !newTab.id) {
               return;
            }

            if (newTab.id === this.tabs[0].id) {
               this.licensesManagementNavigationService.navigateToLicensesManagementTabByTabId();
               this.broadcastEventForLicensesManagementSelectedTabChanged(newTab, true);
               return;
            }

            this.licensesManagementNavigationService.navigateToLicensesManagementTabByTabId(newTab.id);
            this.broadcastEventForLicensesManagementSelectedTabChanged(newTab);
         });
      }

      private findTabById(tabId: string): void {
         if (!tabId) {
            return;
         }

         return this.tabs.find((tab: any) => {
            return tabId === tab.id;
         });
      }

      private createTabParamWithTabId(tabId?: string): any {
         const tabPaths: string[] = tabId ? [ tabId ] : [];
         return { tabPaths: tabPaths };
      }
   }

   angular.module('com.vmware.vsphere.client.licenseUi').controller(
         'LicensesTabbedViewController',
         LicensesTabbedViewController
   );
}
