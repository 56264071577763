namespace h5_client {
   export module common_module_ui {

      export class AngularInjector {
         static $inject = ['$injector'];

         constructor(private $injector: any) {}

         public get(serviceName: string) {
            return this.$injector.get(serviceName);
         }
      }

      angular.module('com.vmware.vsphere.client.commonModule').service('AngularInjector', AngularInjector);
   }
}
