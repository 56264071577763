namespace h5_vm {
   class LegacyVappOptionsView {

      public static $inject = ["vcH5ConstantsService"];

      public vmId: string;

      public version: number = -1;

      public watchObjects: string[] = [];

      public liveProperties: string[] = ["config.vAppConfig", "disabledMethod", "runtime.powerState"];

      public refreshEventTypes: string[] = [];

      constructor(private vcH5ConstantsService: any) {
         this.refreshEventTypes.push(vcH5ConstantsService.DATA_REFRESH_INVOCATION_EVENT);
         this.refreshEventTypes.push(vcH5ConstantsService.OBJECT_DETAILS_CHANGED_EVENT);
         this.refreshEventTypes.push(vcH5ConstantsService.MODEL_CHANGED_EVENT);
      }

      $onInit() {
         this.watchObjects = [this.vmId];
      }

      onRefresh() {
         this.version++;
      }
   }

   angular.module("com.vmware.vsphere.client.vm").component("legacyVappOptionsView", {
      bindings: {
         vmId: "<"
      },
      controller: LegacyVappOptionsView,
      template: `
          <div vx-refreshable 
            watch-objects="{{$ctrl.watchObjects}}"
            refresh="$ctrl.onRefresh()"
            live-refresh-properties="$ctrl.liveProperties"
            refresh-event-types="{{$ctrl.refreshEventTypes}}"> 
             <vm-edit-vapp-options-component [vm-id]="$ctrl.vmId" [version]="$ctrl.version">
            </vm-edit-vapp-options-component>
         </div>`
   });
}


