namespace h5_vm {
   export class VirtualNetworkDeviceInflator {
      public static $inject: string[] = ["VirtualNetworkDevice"];

      constructor(private VirtualNetworkDevice: any) {
      }

      public build(rawDevice: any, availableNetworks: any): any {
         let backing = rawDevice.backing;
         let correspondingNetwork = _.find(availableNetworks, (candidateNetwork: any) => {
            return this.compareBacking(candidateNetwork, backing);
         });
         return new this.VirtualNetworkDevice(rawDevice, correspondingNetwork);
      }

      private compareBacking(candidateNetwork: any, backing: any): boolean {
         if (backing._type === NetBackingType.STD_NETWORK &&
               candidateNetwork._type === NetworkType.STD_NETWORK) {
            return backing.deviceName === candidateNetwork.name;
         }

         if (backing._type === NetBackingType.DVPORGGROUP &&
               candidateNetwork._type === NetworkType.DVPORGGROUP) {
            return backing.port.switchUuid === candidateNetwork.switchUuid &&
                  backing.port.portgroupKey === candidateNetwork.portgroupKey;
         }

         if (backing._type === NetBackingType.DVPORGGROUP &&
               candidateNetwork._type === NetworkType.DVSWITCH) {
            return backing.port.switchUuid === candidateNetwork.switchUuid &&
                  (_.isEmpty(backing.port.portgroupKey));
         }

         if (backing._type === NetBackingType.NSX_NETWORK &&
               candidateNetwork._type === NetworkType.NSX_NETWORK) {
            let opaqueNetworkId = candidateNetwork.network.opaqueNetworkId;
            let opaqueNetworkType = candidateNetwork.network.opaqueNetworkType;
            return opaqueNetworkId === backing.opaqueNetworkId &&
                  opaqueNetworkType === backing.opaqueNetworkType;
         }

         return false;
      }

   }

   angular.module('com.vmware.vsphere.client.vm')
         .service('virtualNetworkDeviceInflator', VirtualNetworkDeviceInflator);
}
