(function () {
   'use strict';

   /**
    * Opens Edit port group dialog as a multi page dialog.
    */
   angular
         .module('com.vmware.vsphere.client.dvs')
         .service('editDvPortgroupDialogService', editDvPortgroupDialogService);

   editDvPortgroupDialogService.$inject = [
      'dataService',
      '$rootScope',
      'i18nService',
      'vxMultiPageDialogService',
      'networkUiConstants',
      'EditDvPortgroupDialogManager'];

   function editDvPortgroupDialogService(dataService,
         $rootScope,
         i18nService,
         vxMultiPageDialogService,
         networkUiConstants,
         EditDvPortgroupDialogManager) {

      return {
         show: show
      };

      function show(dvPortgroupId, actionEval) {
         var editDvPortgroupDialogPropertyName =
               'dvpg:editDvPortgroupDialogProperties';

         var dvPortgroupData = {
            dvPortgroupId: dvPortgroupId
         };

         var dialogScope = $rootScope.$new();
         dialogScope.dialogOptions = {};

         var dvPortgroupDialogManager = new EditDvPortgroupDialogManager(dvPortgroupData,
               dialogScope.dialogOptions);
         dialogScope.manager = dvPortgroupDialogManager;

         _.extend(dialogScope.dialogOptions, {
            title: i18nService.getString('NetworkUi', 'dvpg.settings.initialTitle'),
            iconClass: actionEval.action.icon,
            actionDetails: {
               actionEval: actionEval,
               availableTargets: [dvPortgroupId]
            },
            show: true,
            height: "640px",
            width: "960px",
            loading: true, // this prevents the dialog from being initialized
                           // until distributed port group data is loaded
            loadingMessage: i18nService.getString('CommonUi', 'wizard.loading'),
            confirmOptions: {
               onClick: dvPortgroupDialogManager.submit
            },
            validationBanner: {},
            rejectOptions: {
               rejectOnEsc: true
            },
            resizable: true,
            draggable: true,
            maximizable: true
         });

         var options = {
            scope: dialogScope,
            configObjectName: 'dialogOptions'
         };

         vxMultiPageDialogService(options);

         dataService.getProperties(dvPortgroupId, [editDvPortgroupDialogPropertyName])
               .then(function(response) {
                  _.extend(dvPortgroupData, response[editDvPortgroupDialogPropertyName]);

                  dvPortgroupData.initialNumPorts = dvPortgroupData.numPorts;
                  dvPortgroupData.showPortAllocation = !dvPortgroupData.isUplink &&
                        dvPortgroupData.type !== networkUiConstants.PortgroupType.EPHEMERAL;
                  dvPortgroupData.showPortAllocationInfo = false;

                  dvPortgroupData.showPvlanError = !dvPortgroupData.pvlanConfig ||
                        dvPortgroupData.pvlanConfig.length === 0;

                  dialogScope.dialogOptions.title = i18nService.getString('NetworkUi',
                        'dvpg.settings.title', dvPortgroupData.name);
                  dialogScope.dialogOptions.loading = false;

                  dialogScope.dialogOptions.pages =
                        dvPortgroupDialogManager.getPageDefinitions();

                  dialogScope.initialData = angular.copy(dvPortgroupData);
               });
      }
   }
})();
