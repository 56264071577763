(function() {
   'use strict';
   angular.module('com.vmware.vsphere.client.host')
         .controller('hostGraphicsController', HostGraphicsController);

   HostGraphicsController.$inject = [
      '$scope',
      'dataService',
      'hostGraphicsService'];

   function HostGraphicsController(
         $scope,
         dataService,
         service) {
      var self = this;
      self.hostId = $scope._route.objectId;
      self.watchForObjects = [self.hostId];
      self.headerOptions = service.getHostSettingsHeaderOptions(self.hostId);
      self.getData = getData;
      self.liveRefreshProperties = ['config.graphicsConfig.hostDefaultGraphicsType',
         'config.graphicsConfig.sharedPassthruAssignmentPolicy', 'config.graphicsInfo'];

      getData();

      function getData() {
         dataService
               .getData(
                     self.hostId,
                     'com.vmware.vsphere.client.h5.host.model.GraphicsData')
               .then(function(data) {
                  if (data) {
                     self.typeLabel = service.getTypeLabel(data.defaultGraphicsType);
                     self.policyLabel = service.getPolicyLabel(data.sharedPassthruAssignmentPolicy);
                     self.isHostSettingsEnabled = data.isHostSettingsEnabled;
                  }
               });
      }
   }
})();
