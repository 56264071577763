namespace h5_vm {
   export class VmProvisioningFinishRegisterController {
      public static $inject = [
         "i18nService"];

      private vmNameLabel: string;
      private vmName: string;
      private targetFolderLabel: string;
      private targetFolderName: string;
      private computeResourceType: string;
      private computeResourceName: string;
      public pageModel: VmProvisioningFinishRegisterPageModel;

      constructor(private i18nService: any) {
      }

      $onInit(): void {
         let isTemplate: boolean = this.pageModel.getIsTemplate();
         this.vmNameLabel = isTemplate ?
               this.i18nService.getString("VmUi", "ProvisioningWizardSummaryPage.TemplateName") :
               this.i18nService.getString("VmUi", "ProvisioningWizardSummaryPage.VmName");
         this.vmName = this.pageModel.getVmName();
         this.targetFolderLabel = this.i18nService.getString("VmUi", "ProvisioningWizardSummaryPage.Folder");
         this.targetFolderName = this.pageModel.getTargetFolderName();
         let computeResourceInfo: any =
               this.pageModel.getComputeResourceInfo();
         this.computeResourceType = computeResourceInfo.label;
         this.computeResourceName = computeResourceInfo.name;
      }
   }

   export class VmProvisioningFinishRegisterComponent implements angular.IComponentOptions {
      public controller: any;
      public templateUrl: string;
      public bindings: any;

      constructor() {
         this.controller = VmProvisioningFinishRegisterController;
         this.templateUrl =
               "vm-ui/resources/vm/views/createVmWizard/vmProvisioningFinishRegisterComponent.html";
         this.bindings = {
            pageModel: "<"
         };
      }
   }

   angular.module("com.vmware.vsphere.client.vm")
         .component("vmProvisioningFinishRegister",
               new VmProvisioningFinishRegisterComponent());
}
