namespace h5_vm {

   export class GosNetworkPageController {
      public static $inject = [
         "$q",
         "i18nService",
         "vuiConstants",
         "gosSpecNetworkService",
         "clarityModalService",
         "featureFlagsService",
         "defaultUriSchemeUtil",
         "dataService"
      ];

      readonly NETWORK_SETTINGS_STANDARD: GosNetworkSettingsType =
            GosNetworkSettingsType.STANDARD;
      readonly NETWORK_SETTINGS_CUSTOM: GosNetworkSettingsType =
            GosNetworkSettingsType.CUSTOM;

      model: GosNetworkPageModel;
      gridConfiguration: CrudGridConfiguration<AdapterMappingWrapper>;
      i18n: Function;

      constructor(private $q: any,
            private i18nService: any,
            private vuiConstants: any,
            private gosSpecNetworkService: GosSpecNetworkService,
            private clarityModalService: any,
            private featureFlagsService: any,
            private defaultUriSchemeUtil: any,
            private dataService: any) {
      }

      $onInit(): void {
         this.model.onPageCommit = this.onCommit.bind(this);
         this.gridConfiguration = this.generateGridConfiguration();
         this.i18n = this.i18nService.getString;
      }

      $onDestroy(): void {
         this.model.onPageCommit = undefined;
      }

      onCommit(): angular.IPromise<Array<string>> {
         // This page has no validation. The only validation done here is on the
         // edit action.
         return this.$q.resolve([]);
      }

      customSettingsGridDisabled(): boolean {
         return this.model.networkSettingsType ===
               GosNetworkSettingsType.STANDARD;
      }

      private generateGridConfiguration(): CrudGridConfiguration<AdapterMappingWrapper> {
         let result: CrudGridConfiguration<AdapterMappingWrapper> =
               new CrudGridConfiguration<AdapterMappingWrapper>();
         result.data = this.model.adapterMappingWrappers;

         result.columnDefinitions = this.getColumnDefs();
         result.height = "250";

         result.addLabel = this.i18nService.getString(
               "VmUi", "customizationSpec.networkPage.actionButton.add.label");
         result.addTooltip = this.i18nService.getString(
               "VmUi", "customizationSpec.networkPage.actionButton.add.tooltip");
         result.onAdd = this.addNewNicMapping.bind(this);

         result.editLabel = this.i18nService.getString(
               "VmUi", "customizationSpec.networkPage.actionButton.edit.label");
         result.editTooltip = this.i18nService.getString(
               "VmUi", "customizationSpec.networkPage.actionButton.edit.tooltip");
         result.onEdit = this.editNicMapping.bind(this);

         result.deleteLabel = this.i18nService.getString(
               "VmUi", "customizationSpec.networkPage.actionButton.delete.label");
         result.deleteTooltip = this.i18nService.getString(
               "VmUi", "customizationSpec.networkPage.actionButton.delete.tooltip");
         result.onDelete = this.deleteNicMapping.bind(this);

         return result;
      }

      private getColumnDefs(): any {
         return [
            {
               displayName: this.i18nService.getString("VmUi",
                     "customizationSpec.networkPage.settingsList.description"),
               field: "description",
               type: "string",
               sortable: true
            },
            {
               displayName: this.i18nService.getString("VmUi",
                     "customizationSpec.networkPage.settingsList.iPv4Address"),
               field: "ipV4Address",
               type: "string",
               sortable: true,
            },
            {
               displayName: this.i18nService.getString("VmUi",
                     "customizationSpec.networkPage.settingsList.iPv6Address"),
               field: "ipV6Address",
               type: "string",
               sortable: true
            }
         ];
      }

      private addNewNicMapping(): void {
         this.model.adapterMappingWrappers = this.model.adapterMappingWrappers || [];
         let newAdapterDescription: string = this.i18nService.getString(
               "VmUi", "customizationManager.customizationSpecViewData.nicNameFormat",
               this.model.adapterMappingWrappers.length + 1);
         let newAdapterMappingWrapper: AdapterMappingWrapper =
               this.gosSpecNetworkService.createNewAdapterMappingWrapper(
                     newAdapterDescription);
         this.model.adapterMappingWrappers.push(newAdapterMappingWrapper);
      }

      private editNicMapping(selectedAdapterMapping?: AdapterMappingWrapper): void {
         if (!selectedAdapterMapping) {
            return;
         }
         this.getCustomNameAvailabilityIfNeeded();

         let editedAdapterMappingWrapper: AdapterMappingWrapper = angular.copy(selectedAdapterMapping);
         let dnsSuffixList: string[] = angular.copy(this.model.dnsSuffixList);
         let validationErrors: string[] = [];
         let modalOptions: any = {
            title: this.i18nService.getString("VmUi", "customizationSpec.networkPage.editDialog.title"),
            subTitle: {
               text: editedAdapterMappingWrapper.description
            },
            size: "xl",
            defaultButton: "submit",
            contentTemplate: "vm-ui/resources/vm/guest-os-customization/pages/network/edit/gos-network-page-edit.html",
            dialogData: {
               adapterWrapper: editedAdapterMappingWrapper,
               dnsSuffixList: dnsSuffixList,
               customizationSpecType: this.model.type,
               isNameIpGeneratorDefined: this.model.isNameIpGeneratorDefined,
               validationErrors: validationErrors,
               editNic: () => {
                  this.editNic(editedAdapterMappingWrapper, dnsSuffixList);
               }
            }
         };

         this.clarityModalService.openOkCancelModal(modalOptions);
      }

      public editNic(editedAdapterMappingWrapper: AdapterMappingWrapper, dnsSuffixList: string[]): void {
         let adapterWrappers: AdapterMappingWrapper[] =
               this.model.adapterMappingWrappers;

         let adapterIndex: number = _.findIndex(adapterWrappers,
               (adapterWrapper: AdapterMappingWrapper) => {
                  return adapterWrapper.description === editedAdapterMappingWrapper.description;
               });

         if (adapterIndex > -1) {
            // change the original values for the NIC with the customized
            adapterWrappers[adapterIndex] = editedAdapterMappingWrapper;
         }
         this.model.dnsSuffixList = dnsSuffixList;
      }

      private deleteNicMapping(selectedAdapterMapping?: AdapterMappingWrapper): void {
         if (!selectedAdapterMapping) {
            return;
         }
         let adapterWrappers: Array<AdapterMappingWrapper> =
               this.model.adapterMappingWrappers;

         let index: number = _.findIndex(adapterWrappers,
               (adapterWrapper: AdapterMappingWrapper) => {
                  return adapterWrapper.description === selectedAdapterMapping.description;
               });
         if (index < 0) {
            return;
         }
         adapterWrappers.splice(index, 1);
         this.gosSpecNetworkService.updateAdapterMappingNames(index, adapterWrappers);
      }

      private getCustomNameAvailabilityIfNeeded() {
         if (this.model.isNameIpGeneratorDefined !== undefined) {
            return;
         }

         let vcUri: string = this.defaultUriSchemeUtil.getRootFolder(this.model.vcServiceGuid);
         this.dataService.getProperties(vcUri, ["isNameIpGeneratorDefined"])
               .then((data: any) => {
                  this.model.isNameIpGeneratorDefined = data.isNameIpGeneratorDefined;
               });
      }

   }

   export class GosNetworkPageComponent implements angular.IComponentOptions {
      public controller: any;
      public templateUrl: string;
      public bindings: any;

      constructor() {
         this.controller = GosNetworkPageController;
         this.templateUrl =
               "vm-ui/resources/vm/guest-os-customization/pages/network/gos-network-page.component.html";
         this.bindings = {
            model: "<"
         };
      }
   }

   angular
         .module("com.vmware.vsphere.client.vm")
         .component("gosNetworkPage", new GosNetworkPageComponent());
}


