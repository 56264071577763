(function() {
   'use strict';
   angular.module('com.vmware.vsphere.client.host').controller('AddExceptionUsersController',
         AddExceptionUsersController);

   AddExceptionUsersController.$inject = ['$scope'];

   function AddExceptionUsersController($scope) {

      var self = this;
      self.users = '';

      self.parseUsers = function() {
         var splittedUsers = _.filter(self.users.split(';'), function(user) {return user !== '';});

         return _.map(splittedUsers, function(item) {
            return item.replace(/localhost\//i, '');
         });
      }.bind(this);

      $scope.modalOptions.onSubmit = function() {
         var existingExceptionUsers = $scope.modalOptions.dialogData.model.exceptionUsers;
         $scope.modalOptions.dialogData.model.exceptionUsers =
               _.union(existingExceptionUsers, self.parseUsers());
         $scope.modalOptions.dialogData.datagridOptions.data =
               $scope.modalOptions.dialogData.model.exceptionUsers;
         $scope.modalOptions.dialogData.gridWidget.refresh();
         return true;
      };
   }
})();
