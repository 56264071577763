angular.module('com.vmware.vsphere.client.vm').factory('VmController', [
   'i18nService', 'deviceClassLineageService', function(i18nService, deviceClassLineageService) {
      var CONTROLLER_CREATION_ERROR_MESSAGE = 'Cannot create controller!';
      var ControllerParser = function(deviceOptions, controllerType) {
         this.parse = function() {
            var parsedControllerComponents = {};
            var controllerOption = this.controllerOption(deviceOptions, controllerType);
            if(controllerOption.scsiCtlrUnitNumber){
                parsedControllerComponents.scsiCtlrUnitNumber = controllerOption.scsiCtlrUnitNumber;
            }
            parsedControllerComponents.controllerOptionType = controllerOption.type.typeClass;
            parsedControllerComponents.sharedBus = getSharedBus(parsedControllerComponents.controllerOptionType, controllerOption);
            return parsedControllerComponents;
         };

         this.controllerOption = function () {
            function classFinder(deviceOption) {
               return deviceOption.type.typeClass === controllerType;
            }

            function subclassFinder(deviceOption) {
               return deviceClassLineageService.isClassNameSubclassOf(deviceOption.type.typeClass, controllerType);
            }

            var controllerOption = _.find(deviceOptions, classFinder) || _.find(deviceOptions, subclassFinder);
            if (!controllerOption) {
               throw new Error(CONTROLLER_CREATION_ERROR_MESSAGE);
            }
            return controllerOption;
         };

         function getSharedBus(controllerType, controllerOption) {
            if (deviceClassLineageService.isClassNameSubclassOf(controllerType, 'VirtualSCSIController')) {
               return controllerOption.sharing[controllerOption.defaultSharedIndex];
            }
         }
      };

      var VmController = function(controller, deviceOptions) {
         var parser = new ControllerParser(deviceOptions, controller._type);

         this.toJson = function() {
            return controller;
         };

         this.sharingOptions = function () {
            return parser.controllerOption().sharing;
         };
      };

      VmController.createForType = function (deviceOptions, typeRecommendation, key, busNumber) {
         if (controllerCannotBeCreated(typeRecommendation.typeClass)) {
            throw new Error(CONTROLLER_CREATION_ERROR_MESSAGE);
         }

         var parser = new ControllerParser(deviceOptions, typeRecommendation.typeClass);
         var parsedControllerComponents = parser.parse();

         var device = {
            _type: parsedControllerComponents.controllerOptionType,
            key: key,
            busNumber: busNumber || 0,
            sharedBus: parsedControllerComponents.sharedBus
         };
         if(parsedControllerComponents.scsiCtlrUnitNumber){
             device.scsiCtlrUnitNumber = parsedControllerComponents.scsiCtlrUnitNumber;
         }
         return new VmController(device, deviceOptions);
      };

      function controllerCannotBeCreated(controllerType) {
         var nonCreatableControllerType = 'com.vmware.vim.binding.vim.vm.device.VirtualIDEController';
         return !controllerType || controllerType === nonCreatableControllerType;
      }

      return VmController;
   }]);
