namespace h5_vm {
   export class SelectHostModalService {
      static $inject: string[] = ['clarityModalService',
         'i18nService',
         'defaultUriSchemeUtil',
         'datastoreBrowserConstants',
         'contentLibraryService',
         'dataService'];

      constructor(private clarityModalService: any,
                  private i18nService: any,
                  private defaultUriSchemeUtil: any,
                  private datastoreBrowserConstants: any,
                  private contentLibraryService: any,
                  private dataService: any) {
      }

      public openHostDeviceSelector(computeResourceId: any, okHandler: Function, cancelHandler?: Function) {

         let dialogData = {
            i18nService: this.i18nService,
            hosts:[],
            selectedHost: '',
            isLoading: true,
            onHostChange: this.onHostChange.bind(this)
         };

         this.dataService.getProperties(computeResourceId, ['vmCdromContext']).then((response: any) => {
            this.processResponse(response, dialogData, okHandler, cancelHandler);
         });

      }

      private processResponse(response: any, dialogData: any, okHandler: Function, cancelHandler?: Function): any {
         dialogData.isLoading = false;

         let modalOptions: any = {
            title: this.i18nService.getString("VmUi", "USBDevicePage.SelectHostDevice"),
            contentTemplate: 'vm-ui/resources/vm/views/summary/modals/select-host/select-host.html',
            defaultButton: 'submit',
            size: 'md',
            dialogData: dialogData,
            hideSubmitButton: false,
            submitDisabled: true,
            onCancel: this.onCancel.bind(this, cancelHandler)
         };

         if (response.vmCdromContext.cdRom) {
            dialogData.hosts = response.vmCdromContext.cdRom;
            dialogData.selectedHost = dialogData.hosts[0];
            modalOptions.submitDisabled = false;
         }

         modalOptions.dialogData.isSelectorDisabled = this.isSelectorDisabled.bind(this, modalOptions);
         modalOptions.onSubmit = this.onSubmit.bind(this, okHandler, modalOptions),

         this.clarityModalService.openOkCancelModal(modalOptions);
         return modalOptions;
      };

      private onCancel(callback: Function) : void {
         if (angular.isFunction(callback)) {
            callback();
         }
      };

      private onSubmit(callback: Function, modalOptions: any) : boolean {
         if (angular.isFunction(callback)) {
            callback(modalOptions.dialogData.selectedHost);
         }
         return true;
      };

      private isSelectorDisabled(modalOptions: any): boolean {
         return (modalOptions.dialogData.isLoading || !modalOptions.dialogData.hosts.length);
      }

      private onHostChange(item: any, modalOptions: any) : void {
         modalOptions.dialogData.selectedHost = item;
         modalOptions.submitDisabled = false;
      };
   }
   angular.module("com.vmware.vsphere.client.vm")
      .service("selectHostModalService", SelectHostModalService);
}
