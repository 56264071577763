/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace dvs_ui {

   import DvsCommonPropertiesValidator = dvs_ui.DvsCommonPropertiesValidator;

   export class DvsEditSettingsGeneralPageValidator extends DvsCommonPropertiesValidator {

      public static DESCRIPTION_MAX_LENGHT: number = 255;

      public static MAX_UPLINK_NAME_LENGTH: number = 80;

      public getAllErrors(pageModel: DvsEditSettingsGeneralPageModel): string[] {
         let errors: string[] = [];

         let nameError: string = this.getNameError(pageModel.name);
         if (nameError) {
            errors.push(nameError);
         }

         let numUplinksError: string = this.getNumberOfUplinksError(pageModel.numUplinks,
               pageModel.maxNumUplinks);
         if (numUplinksError) {
            errors.push(numUplinksError);
         }

         let descriptionError: string = this.getDescriptionError(pageModel.description);
         if (descriptionError) {
            errors.push(descriptionError);
         }

         return errors;
      }

      public getDescriptionError(description: string): any {
         if (description &&
               description.length > DvsEditSettingsGeneralPageValidator.DESCRIPTION_MAX_LENGHT) {
            return this.i18nService.getString(
                  "DvsUi", "DvsEditSettingsDialog.generalPage.description.error");
         }

         return null;
      }

      public getUplinkNameError(uplinkName: string): any {
         if (!uplinkName || uplinkName.length === 0) {
            return this.i18nService.getString("NetworkUi", "UplinkNamingDialog.empty");
         } else if (uplinkName.length > DvsEditSettingsGeneralPageValidator.MAX_UPLINK_NAME_LENGTH) {
            return this.i18nService.getString("NetworkUi", "UplinkNamingDialog.tooLong");
         } else {
            return null;
         }
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .service("dvsEditSettingsGeneralPageValidator", DvsEditSettingsGeneralPageValidator);
}
