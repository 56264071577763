/* Copyright 2018 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace network_ui {

   import ValidationResult = com.vmware.vise.core.model.ValidationResult;

   export class RemoveVnicService {

      static $inject = [
         "i18nService",
         "clarityModalService",
         "mutationService"
      ];

      constructor(private i18nService: any,
                  private clarityModalService: any,
                  private mutationService: any) {
      }

      public openConfirmationDialog(hostId: string, vnic: any): void {
         const REMOVE_VNIC_SPEC_TYPE: string =
            "com.vmware.vsphere.client.modules.api.network.VirtualNicRemoveSpec";
         let standardPortgroup = vnic.connectedTo === 'standardNetwork'
               ? vnic.networkLabel : null;
         let removeVnicSpec = {
            virtualNicName: vnic.device,
            portgroupName: standardPortgroup,
            isServiceConsoleVirtualNic: false
         };

         let modalOptions = {
            title: this.i18nService.getString(
               'H5NetworkUi', 'removeVnicDialog.confirmRemoveTitle'),
            submitDisabled: true,
            preserveNewlines: true,
            saveButtonLabel: this.i18nService.getString(
               'H5NetworkUi', 'removeButtonLabel')
         };

         this.clarityModalService.openConfirmationModal(modalOptions);
         this.mutationService
            .validate(hostId, REMOVE_VNIC_SPEC_TYPE, removeVnicSpec)
            .then((validationResult: ValidationResult) => {
               if (!!validationResult.error) {
                  _.extend(modalOptions, {
                     message: this.i18nService.getString('H5NetworkUi',
                        'removeVnicDialog.validationError'),
                     clarityIcon: {
                        class: "is-error",
                        shape: "error-standard",
                        size: "32"
                     }
                  });
               } else {
                  modalOptions.submitDisabled = false;
                  if (validationResult.result.messageType === "error") {
                     _.extend(modalOptions, {
                        message: validationResult.result.message,
                        saveButtonLabel: this.i18nService.getString('Common',
                           'saveButtonLabel'),
                        clarityIcon: {
                           class: "is-error",
                           shape: "error-standard",
                           size: "32"
                        },
                        submit: () => {
                           return true;
                        },
                        hideCancelButton: true
                     });
                  } else {
                     let warning = !!validationResult.result.message
                           ? validationResult.result.message
                           : this.i18nService.getString('H5NetworkUi',
                              'removeVnicDialog.confirmRemoveMessage',
                              removeVnicSpec.virtualNicName);
                     _.extend(modalOptions, {
                        message: warning,
                        clarityIcon: {
                           class: "is-warning",
                           shape: "warning-standard",
                           size: "32"
                        },
                        submit: () => {
                           this.mutationService.apply(hostId,
                              REMOVE_VNIC_SPEC_TYPE, removeVnicSpec);
                        }
                     });
                  }
               }
            });
      }
   }

   angular.module("com.vmware.vsphere.client.network")
      .service("removeVnicService", RemoveVnicService);
}