namespace h5_vm {
   import Recommendation = com.vmware.vim.binding.vim.cluster.Recommendation;
   import Action = com.vmware.vim.binding.vim.cluster.Action;
   import ManagedObjectReference = com.vmware.vim.binding.vmodl.ManagedObjectReference;

   export interface FormattedRecommendation {
      key: string;
      reasonText: string;
      warningText?: string;
      priority: number;
      actions: FormattedAction[];
   }

   export interface FormattedAction {
      icon: string;
      text: string;
   }

   /**
    * Formats a VMODL Recommendation structure into displayable `FormattedRecommendation`
    * structure
    */
   export class VmPlacementRecommendationFormatter {
      public static $inject = [
            "$q",
            "i18nService",
            "defaultUriSchemeUtil",
            "dataServiceUtil"
      ];

      private static PROPERTY_NAME: string = "name";
      private static PROPERTY_VMDEVICES: string = "config/hardware/device";
      private static MAX_STAR_RATING: number = 5;
      private static MAX_PRIORITY_RATING: number = 1;

      constructor(
            private $q: angular.IQService,
            private i18nService: any,
            private defaultUriSchemeUtil: any,
            private dataServiceUtil: any
      ) {}

      public formatRecommendations(
            recommendations: Recommendation[]): angular.IPromise<FormattedRecommendation[]> {
         if (!recommendations || !recommendations.length) {
            return this.$q.resolve([]);
         }

         return this.requestData(recommendations).then((entityData) => {
            let formattedRecommendations: FormattedRecommendation[] = [];
            _.each(recommendations, (rawRecommendation: Recommendation, key: number) => {
               let formattedRec: FormattedRecommendation = {
                  key: rawRecommendation.key,
                  priority: this.ratingToPriority(rawRecommendation.rating),
                  reasonText: this.text("PlacementRecommendationView.recommendationItemText",
                        (key + 1).toString(), rawRecommendation.reasonText),
                  warningText: rawRecommendation.warningDetails && rawRecommendation.warningDetails.message ?
                        rawRecommendation.warningDetails.message : rawRecommendation.warningText || "",
                  actions: this.formatActions(rawRecommendation.action, entityData)
               };
               formattedRecommendations.push(formattedRec);
            });
            return formattedRecommendations;
         });
      }

      private requestData(recommendations: Recommendation[]): angular.IPromise<any> {
         let extractedEntities: {vms: string[], other: string[]} =
               this.extractRelatedEntities(recommendations);

         let requests: any = {
           vmData: this.dataServiceUtil.getPropertiesForObjects(
                 extractedEntities.vms, [
                       VmPlacementRecommendationFormatter.PROPERTY_NAME,
                       VmPlacementRecommendationFormatter.PROPERTY_VMDEVICES]),
           otherData: this.dataServiceUtil.getPropertiesForObjects(
                 extractedEntities.other,
                 [VmPlacementRecommendationFormatter.PROPERTY_NAME])
         };
         return this.$q.all(requests).then((response: any) => {
            let data: any = angular.extend({},  response.vmData, response.otherData);
            return data;
         });
      }

      private extractRelatedEntities(recommendations: Recommendation[]):
            {vms: string[], other: string[]} {
         let result: {vms: string[], other: string[]} = {
            vms: [],
            other: []
         };

         _.each(recommendations, (recommendation: Recommendation) => {
            _.each(recommendation.action, (action: any) => {
               this.pushIfNotPresent(result.other, action.targetHost);
               this.pushIfNotPresent(result.vms, action.vm);

               if (action.relocateSpec) {
                  this.pushIfNotPresent(
                        result.other, action.relocateSpec.datastore);
                  _.each(action.relocateSpec.disk, (diskLocator: any) => {
                     this.pushIfNotPresent(
                           result.other, diskLocator.datastore);
                  });
               }
            });

         });
         return result;
      }

      private formatActions(actions: Action[], entitytData: any): FormattedAction[] {
         if (!actions || !actions.length) {
            return [];
         }

         let formattedActions: FormattedAction[] = [];
         _.each(actions, (action: Action) => {
            if (action._type !== "com.vmware.vim.binding.vim.cluster.PlacementAction") {
               return;
            }

            let placementAction: any = action;
            if (placementAction.targetHost) {
               formattedActions.push({
                  icon: "vsphere-icon-host",
                  text: this.text("PlacementRecommendationView.vmHostPlacementItemText",
                        this.getEntityName(placementAction.vm, entitytData),
                        this.getEntityName(placementAction.targetHost, entitytData))
               });
            }

            let noDiskLocators: boolean = !placementAction.relocateSpec.disk
                  || !placementAction.relocateSpec.disk.length;
            if (noDiskLocators) {
               formattedActions.push({
                  icon: "vsphere-icon-vm",
                  text: this.text("PlacementRecommendationView.vmStoragePlacementItemText",
                        this.getEntityName(placementAction.vm, entitytData),
                        this.getEntityName(
                              placementAction.relocateSpec.datastore, entitytData))
               });
            } else {
               formattedActions.push({
                  icon: "vsphere-icon-vm",
                  text: this.text("PlacementRecommendationView.vmxPlacementItemText",
                        this.getEntityName(placementAction.vm, entitytData),
                        this.getEntityName(
                              placementAction.relocateSpec.datastore, entitytData))
               });
               _.each(placementAction.relocateSpec.disk, (locator: any, index: number) => {
                  formattedActions.push({
                     icon: "vx-icon-vmDisk",
                     text: this.text("PlacementRecommendationView.vmdkPlacementItemText",
                           this.getEntityName(placementAction.vm, entitytData),
                           this.getDiskLabel(
                                 placementAction.vm, locator.diskId, entitytData),
                           this.getEntityName(locator.datastore, entitytData))
                  });
               });
            }
         });

         return formattedActions;
      }

      private ratingToPriority(rating: number = 0): number {
         // The DRS-assigned star rating can be up to MAX_STAR_RATING (more being better).
         // Calculate the differential between this recommendation's rating and the best
         // possible DRS rating.
         let differentialFromBest: number =
               VmPlacementRecommendationFormatter.MAX_STAR_RATING - rating;
         // The priority rating can be down to MAX_PRIORITY_RATING (less being better).
         // Add the calculated differential to the best possible priority rating to obtain
         // the priority rating of this recommendation.
         return VmPlacementRecommendationFormatter.MAX_PRIORITY_RATING
               + differentialFromBest;
      }

      private getEntityName(ref: ManagedObjectReference, entityData: any): string {
         let data: any = entityData[this.toId(ref)];
         let name: string = "";
         if (data) {
            name = data[VmPlacementRecommendationFormatter.PROPERTY_NAME];
         }
         return name;
      }

      private getDiskLabel(
            vm: ManagedObjectReference, diskId: number, entityData: any): string {
         let data: any = entityData[this.toId(vm)];
         let diskLabel: string = "";
         if (data) {
            let vmDevices: any[] =
                  data[VmPlacementRecommendationFormatter.PROPERTY_VMDEVICES];
            let diskDevice: any = _.find(vmDevices, (device) => device.key === diskId);
            if (diskDevice) {
               diskLabel = diskDevice.deviceInfo.label;
            }
         }
         return diskLabel;
      }

      private pushIfNotPresent(entityIds: string[], ref: ManagedObjectReference): void {
         if (ref && entityIds) {
            if (entityIds.indexOf(this.toId(ref)) < 0) {
               entityIds.push(this.toId(ref));
            }
         }
      }

      private toId(ref: ManagedObjectReference) {
         return this.defaultUriSchemeUtil.getVsphereObjectId(ref);
      }

      private text(key: string, ...params: string[]): string {
         return this.i18nService.getString("VmUi", key, ...params);
      }
   }

   angular.module("com.vmware.vsphere.client.vm")
         .service("vmPlacementRecommendationFormatter", VmPlacementRecommendationFormatter);
}
