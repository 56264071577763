/* Copyright 2018 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace h5_vm {
   import RelocateSpec = com.vmware.vim.binding.vim.vm.RelocateSpec;
   import ProfileSpec = com.vmware.vim.binding.vim.vm.ProfileSpec;
   import RelocateSpec$DiskLocator = com.vmware.vim.binding.vim.vm.RelocateSpec$DiskLocator;
   import ManagedObjectReference = com.vmware.vim.binding.vmodl.ManagedObjectReference;
   import GroupInfo = com.vmware.vim.binding.sms.storage.replication.GroupInfo;
   import VirtualDevice = com.vmware.vim.binding.vim.vm.device.VirtualDevice;
   import VirtualDevice$FileBackingInfo = com.vmware.vim.binding.vim.vm.device.VirtualDevice$FileBackingInfo;

   export interface StorageData {
      initialVmStorageConfig: VmStorageConfig;
      initialViewMode: string;
   }
   export class InitialStorageConfigurationService {
      public readonly BASIC_MODE = "basicMode";
      public readonly ADVANCED_MODE = "advancedMode";

      public static $inject: string[] = [
         "$q",
         "defaultUriSchemeUtil",
         "mutationService",
         "diskProvisioningService",
         "storageProfileService",
         "vmDeviceInfoService"
      ];

      constructor(private $q: angular.IQService,
            private defaultUriSchemeUtil: {
               getVsphereObjectId: (resourceObject: any) => string,
               compareNullableIds: (id1: any, id2: any) => boolean,
            },
            private mutationService: {
               validate: (objectId: string, propertyObjectType: string,
                     propertySpec: any) => IPromise<any>
            },
            private diskProvisioningService: {
               getFormat: (backingInfo: any) => any
               areDiskFormatsEqual: (backingInfoA: any, backingInfoB: any) => boolean
            },
            private storageProfileService: {
               getProfileIdFromSpec: (profileSpec: ProfileSpec) => string | undefined,
               areProfileSpecsEqual: (profileSpecA: ProfileSpec,
                     profileSpecB: ProfileSpec,
                     areReplicationGroupsConsidered?: boolean) => boolean
            },
            private vmDeviceInfoService: {
               isDeviceSubclassOf: (device: VirtualDevice, type: string) => boolean
            }) {
      }

      computeInitialVmStorageConfig(vmId: string,
            spec: RelocateSpec,
            replicationGroupsData?: { [key: string]: GroupInfo }) {
         const result = new VmStorageConfig();
         result.vmId = vmId;
         result.vmHome = {
            key: -1,
            storageObj: spec.datastore &&
            {
               storageRef: spec.datastore
            },
            storageProfile: this.computeStorageProfile(spec.profile),
            replicationGroup: this.computeReplicationGroup(spec.profile, replicationGroupsData)
         };
         result.vmDisks = [];
         const vmDisks = result.vmDisks;
         _.each(spec.disk, (diskLocator: RelocateSpec$DiskLocator) => {
            vmDisks.push({
               key: diskLocator.diskId,
               storageObj: diskLocator.datastore &&
               {
                  storageRef: diskLocator.datastore,
               },
               diskFormat: diskLocator.diskBackingInfo &&
               this.diskProvisioningService.getFormat(
                     diskLocator.diskBackingInfo),
               storageProfile: this.computeStorageProfile(diskLocator.profile),
               replicationGroup: this.computeReplicationGroup(diskLocator.profile, replicationGroupsData)
            });
         });

         this.buildVmDisks(spec.deviceChange, result.vmDisks, {});

         return result;
      }

      computeInitialVmStorageConfigForCreateVm(datastoreRef: ManagedObjectReference,
            deviceChange: { device: VirtualDevice, profile: ProfileSpec[] }[],
            datastoreRefs: { [index: string]: ManagedObjectReference },
            profiles?: ProfileSpec[],
            replicationGroupsData?: { [key: string]: GroupInfo }) {
         const result = new VmStorageConfig();
         result.vmId = "";
         result.vmHome = {
            key: -1,
            storageObj: {
               storageRef: datastoreRef
            },
            storageProfile: this.computeStorageProfile(profiles),
            replicationGroup: this.computeReplicationGroup(profiles, replicationGroupsData)
         };
         result.vmDisks = [];

         this.buildVmDisks(deviceChange, result.vmDisks, datastoreRefs);
         return result;
      }

      computeSourceVmStorageConfig(initialVmStorageConfig: VmStorageConfig, profiles: any,
            storageSelectorData: any, isCreateFromScratch: boolean) {
         const sourceVmStorageConfig = angular.copy(initialVmStorageConfig);

         if(isCreateFromScratch) {
            storageSelectorData.sourceVmStorageConfig = [sourceVmStorageConfig];
         }

         if (!_.isEmpty(sourceVmStorageConfig.vmDisks)) {
            _.each(sourceVmStorageConfig.vmDisks, (disk: any) => {
               const originalDisk = _.find(storageSelectorData.sourceVmStorageConfig[0].vmDisks, (vmDisk: any) => {
                  return vmDisk.key === disk.key;
               });
               if (originalDisk) {
                  const profile = _.find(profiles, (profile: any) => {
                     return profile.id === (disk.storageProfile && disk.storageProfile.id);
                  });

                  if (profile) {
                     originalDisk.storageProfile = profile;
                  }
               }
            });
         }
      }

      private buildVmDisks(deviceChange: { device: VirtualDevice, profile: ProfileSpec[] }[],
            vmDisks: VmComponentStorageConfig[], datastoreRefs: { [index: string]: ManagedObjectReference }) {
         _.each(deviceChange, (device: { device: VirtualDevice, profile: ProfileSpec[] }) => {
            if (this.vmDeviceInfoService.isDeviceSubclassOf(device.device, "VirtualDisk")) {
               let datastoreName = device.device && device.device.backing &&
                     (device.device.backing as VirtualDevice$FileBackingInfo).fileName;
               let datastore: ManagedObjectReference | undefined;
               if (datastoreName) {
                  const match = datastoreName.match(/\[(.*)\]/);
                  datastoreName = match ? match[1] : "";
                  datastore = datastoreRefs[datastoreName];
               }
               vmDisks.push({
                  key: device.device.key,
                  storageObj: datastore &&
                  {
                     storageRef: datastore,
                  },
                  diskFormat: device.device && device.device.backing &&
                  this.diskProvisioningService.getFormat(
                        device.device.backing),
                  storageProfile: this.computeStorageProfile(device.profile),
                  replicationGroup: this.computeReplicationGroup(device.profile)
               });
            }
         });
      }

      private computeStorageProfile(profiles?: ProfileSpec[]) {
         if (!profiles || !profiles[0]) {
            return undefined;
         }
         const id = this.storageProfileService.getProfileIdFromSpec(profiles[0]);

         if (!id) {
            return undefined;
         }

         return {id: id};
      }

      private computeReplicationGroup(profiles?: ProfileSpec[], replicationGroupsData?: { [key: string]: GroupInfo }) {
         if (!profiles || !profiles[0] || !(<any>profiles[0]).replicationSpec) {
            return undefined;
         }

         const id = (<any>profiles[0]).replicationSpec.replicationGroupId;
         if (!id) {
            return undefined;
         }

         const stringId = id === null ? "" : id.faultDomainId.id + ":" + id.deviceGroupId.id;
         let groupInfo: GroupInfo | undefined;
         if (stringId && replicationGroupsData && replicationGroupsData[stringId]) {
            groupInfo = replicationGroupsData[stringId];
         }
         return {
            id: id,
            groupInfo: groupInfo
         };
      }

      computeStoragePageViewMode(spec: RelocateSpec): string {
         if (spec.datastore && !spec.disk) {
            return this.BASIC_MODE;
         }

         // Seek if there is a disk locator which is different from the home datastore
         const referenceStorage = spec.datastore;
         let uniqueDiskLocator = _.find(spec.disk,
               (diskLocator: RelocateSpec$DiskLocator) => {
                  return !this.defaultUriSchemeUtil.compareNullableIds(
                        diskLocator.datastore, referenceStorage);
               });

         // If unique found - this means different disks go to different storage -
         // so editing in advanced mode is required
         if (uniqueDiskLocator) {
            return this.ADVANCED_MODE;
         }

         const referenceDisk = spec.disk && spec.disk[0];
         uniqueDiskLocator = referenceDisk && _.find(spec.disk,
                     (diskLocator: RelocateSpec$DiskLocator) => {
                        return !this.diskProvisioningService.areDiskFormatsEqual(
                              diskLocator.diskBackingInfo, referenceDisk.diskBackingInfo);
                     });
         if (uniqueDiskLocator) {
            return this.ADVANCED_MODE;
         }

         const referenceProfile = spec.profile && spec.profile[0];
         uniqueDiskLocator = _.find(spec.disk,
               (diskLocator: RelocateSpec$DiskLocator) => {
                  return !this.storageProfileService.areProfileSpecsEqual(
                        diskLocator.profile && diskLocator.profile[0],
                        referenceProfile,
                        true);
               });
         if (uniqueDiskLocator) {
            return this.ADVANCED_MODE;
         }

         return this.BASIC_MODE;
      }

      /**
       * @returns {boolean}
       *    'true' if things were preselected due to initial data present in the model
       *    and 'false' otherwise.
       */
      initSelectedStorageItems(storageSelectorState: any,
            storageSelectorData: any/*StorageSelectorData*/,
            storageData: StorageData): boolean {
         if (!storageData.initialViewMode ||
               !storageData.initialVmStorageConfig) {
            return false;
         }

         storageSelectorState.mode = storageData.initialViewMode;

         const vmId = storageData.initialVmStorageConfig.vmId;

         if (storageSelectorState.mode === this.BASIC_MODE) {
            if (!storageSelectorData.initialConfig) {
               storageSelectorData.initialConfig = {};
            }

            const storageObj = storageData.initialVmStorageConfig.vmHome.storageObj;
            const storageId = this.defaultUriSchemeUtil.getVsphereObjectId(
                  storageObj && storageObj.storageRef);
            const storageItem = this.findStorageItem(
                  storageSelectorData.storageLocatorItemsData.datastoreItems, storageId);
            storageSelectorData.initialConfig.selectedItemInBasicMode = storageItem;

            // In basic mode all disks should have the same format
            const diskA = storageData.initialVmStorageConfig.vmDisks[0];
            const diskFormat = diskA && diskA.diskFormat;
            storageSelectorData.initialConfig.selectedDiskFormatInBasicMode = diskFormat;

            const storageProfile = storageData.initialVmStorageConfig
                  .vmHome.storageProfile;
            const storageProfileId = storageProfile && storageProfile.id;
            const storageProfileItem = this.findStorageProfileItem(
                  storageSelectorData.storageProfilesData.storageProfiles,
                  storageProfileId);
            storageSelectorData.initialConfig
                  .selectedStorageProfileInBasicMode = storageProfileItem;

            const replicationGroup = storageData.initialVmStorageConfig
                  .vmHome.replicationGroup;
            storageSelectorData.initialConfig.selectedReplicationGroupInBasicMode = replicationGroup;
         } else {
            if (!storageSelectorState.vmStorageConfigInAdvancedMode) {
               // Тhis line is borrowed from StorageSelector::initAdvancedModeData
               storageSelectorState.vmStorageConfigInAdvancedMode =
                     angular.copy(storageSelectorData.sourceVmStorageConfig);

               const properVmStorageConfig = _.find(
                     storageSelectorState.vmStorageConfigInAdvancedMode,
                     (currentVmStorageConfig: any) =>
                     currentVmStorageConfig.vmId === vmId);

               if (properVmStorageConfig) {
                  const storageObj = storageData.initialVmStorageConfig.vmHome.storageObj;
                  const storageId = this.defaultUriSchemeUtil.getVsphereObjectId(
                        storageObj && storageObj.storageRef);
                  properVmStorageConfig.vmHome.storageObj = this.findStorageItem(
                        storageSelectorData.storageLocatorItemsData.datastoreItems,
                        storageId);
                  properVmStorageConfig.vmHome.replicationGroup = storageData
                        .initialVmStorageConfig.vmHome.replicationGroup;

                  // NOTE: vmHome does not have diskFormat setting

                  const storageProfile = storageData.initialVmStorageConfig
                        .vmHome.storageProfile;
                  const storageProfileId = storageProfile && storageProfile.id;
                  const storageProfileItem = this.findStorageProfileItem(
                        storageSelectorData.storageProfilesData.storageProfiles,
                        storageProfileId);
                  properVmStorageConfig.vmHome.storageProfile = storageProfileItem;

                  _.each(properVmStorageConfig.vmDisks, (properVmDisk: any) => {
                     const initialVmDisk: VmComponentStorageConfig = _.find(
                           (<VmStorageConfig>storageData.initialVmStorageConfig).vmDisks,
                           (disk: VmComponentStorageConfig) => {
                              return disk.key === properVmDisk.key;
                           });

                     if (!initialVmDisk) {
                        return;
                     }

                     const diskStorageObj = initialVmDisk.storageObj;
                     const diskStorageId = this.defaultUriSchemeUtil.getVsphereObjectId(
                           diskStorageObj && diskStorageObj.storageRef);
                     properVmDisk.storageObj = this.findStorageItem(
                           storageSelectorData.storageLocatorItemsData.datastoreItems,
                           diskStorageId);

                     properVmDisk.diskFormat = initialVmDisk.diskFormat;

                     const diskProfile = initialVmDisk.storageProfile;
                     const diskProfileId = diskProfile && diskProfile.id;
                     const diskProfileItem = this.findStorageProfileItem(
                           storageSelectorData.storageProfilesData.storageProfiles,
                           diskProfileId);
                     properVmDisk.storageProfile = diskProfileItem;

                     properVmDisk.replicationGroup = initialVmDisk.replicationGroup;
                  });
               }
            }
         }

         return true;
      }

      private findStorageItem(listOfStorageLocatorItems: any[], storageId?: string) {
         return _.find(listOfStorageLocatorItems, (item: any) => {
            return this.defaultUriSchemeUtil.getVsphereObjectId(
                        item.storageRef) === storageId;
         });
      }

      private findStorageProfileItem(listOfStorageProfileItems: any[], storageProfileId?: string) {
         return _.find(listOfStorageProfileItems,
               (sp: any) => sp.id === storageProfileId);
      }
   }

   angular.module("com.vmware.vsphere.client.vm").service(
         "initialStorageConfigurationService", InitialStorageConfigurationService);
}
