module cluster_ui {

   export class DrsAutomationLevelPageService {

      public static $inject = ["i18nService", "drsMigrationThresholdService"];

      public constructor(private i18nService: any, private drsMigrationThresholdService: any) {
      }

      public getSliderOptions(dragHandleTitle: string): any {
         return {
            value: this.drsMigrationThresholdService.DEFAULT,
            min: this.drsMigrationThresholdService.MIN,
            max: this.drsMigrationThresholdService.MAX,
            minLabel: this.drsMigrationThresholdService.MIN_LABEL,
            maxLabel: this.drsMigrationThresholdService.MAX_LABEL,
            enabled: true,
            width: 'auto',
            dragHandleTitle: dragHandleTitle
         };
      }

      public getSignPostParams(): any {
         return {
            title: this.getString("help", "Common"),
            message: this.getString("drs.config.migrationThreshold.signpostMessage")
         };
      }

      public getMigrationThresholdDescription(migrationThreshold: any): string {
         let description: string =
               this.drsMigrationThresholdService.getDescription(migrationThreshold);

         return description;
      }

      public getVmAutomationSignPostParams(): any {
         return {
            title: this.getString("help", "Common"),
            message: this.getString("drs.config.vmAutomation.enableOverridesDescription")
         };
      }

      public getPredictiveDrsSignPostParams(): any {
         return {
            title: this.getString("help", "Common"),
            message: this.getString("drs.config.migration.forecastedMetrics.desc")
         };
      }

      public getAutomationLevelInformationMessage(automationLevelId: string): string {

         let automationLevelMessages: any = {
            "manual": this.getString("drs.config.automationLevel.manualDescription"),
            "partiallyAutomated": this.getString("drs.config.automationLevel.partialautoDescription"),
            "fullyAutomated": this.getString("drs.config.automationLevel.fullautoDescription")
         };
         return automationLevelMessages[automationLevelId];
      }

      private getString(key: string, bundle?: string): string {
         return bundle ? this.i18nService.getString(bundle, key) :
               this.i18nService.getString("ClusterUi", key);
      }
   }

   angular.module("com.vmware.vsphere.client.cluster")
         .service("drsAutomationLevelPageService", DrsAutomationLevelPageService);
}

