(function() {
   'use strict';
   angular.module('com.vmware.vsphere.client.admin').service('rolesService', rolesService);

   rolesService.$inject = ['dataService',
      'userSessionService',
      'defaultUriSchemeUtil',
      'mutationService',
      'notificationService'];

   var ANONYMOUS_ID = '-3';
   var VIEW_ID = '-4';
   var ROOT_PERMISSION_NODE_URI = 'urn:vri:acl:RootPermissionNode:root';
   var CREATE_ROLE_SPEC = 'com.vmware.vise.vim.security.data.CreateRoleSpec';
   var EDIT_ROLE_SPEC = 'com.vmware.vise.vim.security.data.EditRoleSpec';
   var DELETE_ROLE_SPEC = 'com.vmware.vise.vim.security.data.RemoveRoleSpec';

   function rolesService (
      dataService,
      userSessionService,
      defaultUriSchemeUtil,
      mutationService,
      notificationService
   ) {

      return {
         getRoles: getRoles,
         getRoleUsages: getRoleUsages,
         cloneOrCreateRole: cloneOrCreateRole,
         editRole: editRole,
         deleteRole: deleteRole
      };

      function getRoles () {
         return dataService.getProperties(ROOT_PERMISSION_NODE_URI, ['rolesInfo']).then(function(response) {
            return response.rolesInfo.filter(function(data) {
               return data.id !== ANONYMOUS_ID && data.id !== VIEW_ID;
            });
         }).then(sortRoles);
      }

      function sortRoles (data) {
         if (!data || data.length === 0) {
            return data;
         }

         var adminRoleIndex = data.findIndex(function(x) {
            return x.id === '-1';
         });
         var adminRole = data.splice(adminRoleIndex, 1);
         var readOnlyIndex = data.findIndex(function(x) {
            return x.id === '-2';
         });
         var readOnly = data.splice(readOnlyIndex, 1);
         var noAccessIndex = data.findIndex(function(x) {
            return x.id === '-5';
         });
         var noAccess = data.splice(noAccessIndex, 1);

         data.sort(function(data1, data2) {
            if (data1.label.toLowerCase() < data2.label.toLowerCase()) {
               return -1;
            }
            if (data1.label.toLowerCase() > data2.label.toLowerCase()) {
               return 1;
            }
            return 0;
         }).unshift(adminRole[0], readOnly[0], noAccess[0]);
         return data;
      }

      function getRoleUsages (roleId, vc) {
         var paramSpec = {
            propertyName: 'vsphere:permissionForRole',
            parameterType: 'flex.messaging.io.amf.ASObject',
            parameter: {
               _type: 'flex.messaging.io.amf.ASObject',
               roleId: roleId
            }
         };

         var serverGuid = vc.serviceGuid;
         var rootFolder = defaultUriSchemeUtil.createVmomiUri('Folder', 'group-d1', serverGuid);
         var properties = ['vsphere:permissionForRole'];

         return dataService.getProperties(rootFolder, properties, {
            propertyParams: [paramSpec],
            // needed to show a custom error dialog
            skipErrorInterceptor: true
         }).then(formatUsageData).catch(function() {
            // On 6.0 environments it is observed that querying for properties of a role immediately after its
            // creation, throws a vim.fault.NotFound error. Catch the error here and display a user-friendly message.
            notificationService.notifyError("Syncronization error", "Error retrieving usage data on this vCenter.");
         });
      }

      function formatUsageData (usagesResponse) {
         var permissionData = usagesResponse['vsphere:permissionForRole'];
         if (permissionData === undefined || permissionData === null) {
            return [];
         }

         var formattedUsages = [];
         var names = permissionData.names;
         var permissions = permissionData.permissions;

         for (var i = 0; i < permissions.length; i++) {
            formattedUsages.push({
               definedIn: findNameOfPermission(permissions[i], names),
               userGroup: permissions[i].principal,
               propagate: permissions[i].propagate
            });
         }
         return formattedUsages;
      }

      function findNameOfPermission (permission, names) {
         //not using an obvious find method here as we are in js file and find might not be shimed by ts compiler for a js file,
         // for internet explorer, migrate to find once we stop supporting internet explorer
         var permissionEntity = permission.entity;
         for (var i = 0; i < names.length; ++i) {
            var nameEntity = names[i].value;
            if (permissionEntity.value === nameEntity.value && nameEntity.serverGuid === permissionEntity.serverGuid) {
               return names[i].name;
            }
         }
         return '';
      }

      function cloneOrCreateRole (name, description, privileges) {
         var createRoleSpec = {
            roleName: name,
            description: description,
            privileges: privileges
         };
         return userSessionService.getAllServersInfo().then(function(response) {
            var serverGuid = response.serversInfo[0].serviceGuid;
            var rootFolder = defaultUriSchemeUtil.createVmomiUri('Folder', 'group-d1', serverGuid);
            return mutationService.apply(rootFolder, CREATE_ROLE_SPEC, createRoleSpec);
         });
      }

      function editRole (id, name, description, privileges) {
         var editRoleSpec = {
            roleId: id,
            roleName: name,
            roleDescription: description,
            privileges: privileges
         };
         return userSessionService.getAllServersInfo().then(function(response) {
            var serverGuid = response.serversInfo[0].serviceGuid;
            var rootFolder = defaultUriSchemeUtil.createVmomiUri('Folder', 'group-d1', serverGuid);
            return mutationService.apply(rootFolder, EDIT_ROLE_SPEC, editRoleSpec);
         });
      }

      function deleteRole (roleId) {
         var deleteRoleSpec = {
            roleId: String(roleId)
         };
         return userSessionService.getAllServersInfo().then(function(response) {
            var serverGuid = response.serversInfo[0].serviceGuid;
            var rootFolder = defaultUriSchemeUtil.createVmomiUri('Folder', 'group-d1', serverGuid);
            return mutationService.apply(rootFolder, DELETE_ROLE_SPEC, deleteRoleSpec);
         });
      }
   }
})();
