namespace h5_vm {

   import VirtualVideoCard = com.vmware.vim.binding.vim.vm.device.VirtualVideoCard;
   import VirtualVideoCardOption = com.vmware.vim.binding.vim.vm.device.VirtualVideoCardOption;
   import GuestOsDescriptor = com.vmware.vim.binding.vim.vm.GuestOsDescriptor;
   import VmConfigContext = com.vmware.vsphere.client.vm.config.VmConfigContext;
   import VirtualDeviceOption = com.vmware.vim.binding.vim.vm.device.VirtualDeviceOption;

   export class VirtualVideoCardSetup {

      public static setup(fixtures: any, virtualMachineDevicesHelpers: any,
                          i18nService: any,
                          vmVersion: number = 11,
                          guest3dSupport: boolean = true,
                          powerState: string,
                          modifyDevice: boolean,
                          mode: VmWorkflowMode,
                          support3DDefaultValue: boolean = false,
                          isScheduledTask: boolean = false,
                          rawDeviceEnable3DSupport: boolean = false): VirtualVideoCardClass {

         let fix = fixtures.vmHardwareConfigData();

         if (rawDeviceEnable3DSupport) {
            const videoCardRawDevice = _.find(fix.vmConfigContext.config.hardware.device, (device: any) => {
               return device._type === "com.vmware.vim.binding.vim.vm.device.VirtualVideoCard";
            });

            if (videoCardRawDevice) {
               videoCardRawDevice.enable3DSupport = rawDeviceEnable3DSupport;
            }
         }

         let ctx: VmConfigContext = fix.vmConfigContext;
         let guest = ctx.environment.configOption.guestOSDescriptor[0];
         let environment = ctx.environment;
         environment.powerState = powerState;
         let privileges = ctx.privileges;

         if (!modifyDevice) {
            privileges = _.without(ctx.privileges, Privileges.VM_EDITDEVICE_PRIVILEGE);
         }

         guest.supports3D = guest3dSupport;
         let options: Array<any> = environment.configOption.hardwareOptions.virtualDeviceOption;

         let option: VirtualVideoCardOption = VirtualVideoCardSetup.findVideoCardOption(options);

         if (vmVersion >= 7) {
            // miltimon introduced in VM version 7
            option.numDisplays.max = 10;
         } else {
            option.numDisplays.max = 1;
         }

         if (vmVersion === 9 || vmVersion === 10) {
            // In version 9 and 10 we already had 3D support
            // but we didn't have graphics memory yet,
            // instead videoRam was max increased to 512MB
            option.videoRamSizeInKB.max = 512 * 1024;
         } else {
            // starting from VM version 11
            // max dropped back to 128 MB
            option.videoRamSizeInKB.max = 128 * 1024;
         }

         // 3D was introduced in VM version 9
         // but option always coming as false
         // and we use guest flag instead

         option.support3D.supported = false;
         option.support3D.defaultValue = support3DDefaultValue;

         // 3D renderer was introduced in VM version 9,
         // together with 3D support

         if (vmVersion > 8) {
            option.use3dRendererSupported.supported = true;
         } else {
            option.use3dRendererSupported.supported = false;
         }

         // VM version 11 introduces graphics memory to offload 3D
         if (vmVersion > 10) {
            option.graphicsMemorySizeSupported.supported = true;
         } else {
            option.graphicsMemorySizeSupported.supported = false;
         }

         let virtualMachineDevices = virtualMachineDevicesHelpers.buildVirtualMachineDevices(ctx, isScheduledTask);
         let vm: VmConfig = new VmConfig(ctx, guest, privileges, mode, i18nService);

         let inflatedVideoCard: VirtualVideoCardClass = virtualMachineDevices.getVirtualDevice(500);

         if (mode === h5_vm.VmWorkflowMode.CreateMode) {
            // New VM wizard does ot populate raw device fields.
            // We have to fix place defaults before we render it in customize hardware.
            // Here we nullify rawDevice fixture to test this behaviour
            VirtualVideoCardSetup.unsetRawDevice(inflatedVideoCard.rawDevice);
         }

         inflatedVideoCard.attachVmConfig(vm);

         return inflatedVideoCard;
      }

      private static unsetRawDevice(rawDevice: VirtualVideoCard): void {
         // rawDevice.videoRamSizeInKB = null;
         // rawDevice.enable3DSupport = null;
         // rawDevice.graphicsMemorySizeInKB = null;
         // rawDevice.numDisplays = null;
         // rawDevice.useAutoDetect = null;
         // rawDevice.use3dRenderer = null;
      }

      private static findVideoCardOption(options: Array <any>): VirtualVideoCardOption {

         let match: Function = function (item: VirtualDeviceOption) {
            //TODO wsdlName is wrong, type.name or type.typeClass? - check java docs
            // Turned out wsldName is VirtualMachineVideoCard, not VirtualVideoCard
            return item.type.name === "com.vmware.vim.binding.vim.vm.device.VirtualVideoCard";
         };

         let option = _.find(options, match);
         let retVal: VirtualVideoCardOption = option as VirtualVideoCardOption;
         return retVal;
      }
   }
}
