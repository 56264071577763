/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace dvs_ui {

   import IPromise = angular.IPromise;
   export class DvsHostCompatibilityPageValidator implements WizardPageValidator {

      public static $inject = ["i18nService", "$q", "dvsHostCompatibilityService"];

      constructor(private i18nService: any,
                  private $q: any,
                  private dvsHostCompatibilityService: any) {
      }

      public getValidationError(wizardScope: DvsUpgradeWizardScope): string[] {

         if (this.dvsHostCompatibilityService.isIncompatibleHostAvailable(
               wizardScope.model.hostCompatibilityData)) {
            return this.$q.resolve([this.i18nService.getString(
                  "DvsUi", "DvsHostCompatibilityPage.incompatibleHostsPresent")]);
         }
         return this.$q.resolve([]);
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .service("dvsHostCompatibilityPageValidator", DvsHostCompatibilityPageValidator);
}
