(function() {
   'use strict';
   angular.module('com.vmware.vsphere.client.host')
         .controller('editSingleVmStartupShutdownSettingsDialogController',
               EditSingleVmStartupShutdownSettingsDialogController);

   EditSingleVmStartupShutdownSettingsDialogController.$inject = [
      '$scope',
      'editVmStartupShutdownService',
      'vmStartupShutdownConstants',
      'i18nService'];

   function EditSingleVmStartupShutdownSettingsDialogController($scope,
         editVmStartupShutdownService,
         vmStartupShutdownConstants,
         i18nService) {
      var self = this;
      self.SYSTEM_DEFAULT = vmStartupShutdownConstants.SYSTEM_DEFAULT;
      self.USER_DEFINED = vmStartupShutdownConstants.USER_DEFINED;
      self.START_DELAY_MIN_VALUE = 0;
      self.START_DELAY_MAX_VALUE = 65535;
      self.STOP_DELAY_MIN_VALUE = 0;
      self.STOP_DELAY_MAX_VALUE = 65535;
      self.startupSettingHeader = {
         title: i18nService.getString('HostUi',
               'editSingleVmStartupShutdownSettingsDialog.startupSetting.header')
      };
      self.shutdownSettingHeader = {
         title: i18nService.getString('HostUi',
               'editSingleVmStartupShutdownSettingsDialog.shutdownSetting.header')
      };
      self.shutdownActionOptions = editVmStartupShutdownService.getShutdownActionOptions();
      self.startupSettings = $scope.modalOptions.dialogData.vmPowerInfoSettings.startupSettings;
      self.shutdownSettings = $scope.modalOptions.dialogData.vmPowerInfoSettings.shutdownSettings;

      self.validateStartDelay = function(event) {
         if (typeof self.startupSettings.startDelay === "undefined") {
            var valueFromInput = event.target.value;
            if (valueFromInput > self.START_DELAY_MAX_VALUE) {
               self.startupSettings.startDelay = self.START_DELAY_MAX_VALUE;
            } else {
               self.startupSettings.startDelay = self.START_DELAY_MIN_VALUE;
            }
         }
      };
      self.validateStopDelay = function(event) {
         if (typeof self.shutdownSettings.stopDelay === "undefined") {
            var valueFromInput = event.target.value;

            if (valueFromInput > self.STOP_DELAY_MAX_VALUE) {
               self.shutdownSettings.stopDelay = self.STOP_DELAY_MAX_VALUE;
            } else {
               self.shutdownSettings.stopDelay = self.STOP_DELAY_MIN_VALUE;
            }
         }
      };

   }
})();
