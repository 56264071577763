namespace storage_ui {
   import IPromise = angular.IPromise;

   declare let h5: any;

   export class DatastoreFileBrowserActionBarBuilder {

      private actionBarItems: any[] = [];
      private contextGetters: any = {};

      constructor(private actionDefinitions: any,
                  private actionsService: any,
                  private vuiConstants: any,
                  private isActionAvailable: {(actionId: string, actionDef: any): boolean}) {
      }

      public addAction(actionId: string, getContext: Function, clickHandler: Function|null = null): DatastoreFileBrowserActionBarBuilder {
         let actionDef = this.actionDefinitions[actionId];
         this.contextGetters[actionId] = getContext;
         let actionButton = {
            actionId: actionDef.action.uid,
            tooltipText: actionDef.action.description,
            label: actionDef.action.label,
            enabled: this.isActionAvailable(actionId, actionDef),
            iconClass: actionDef.action.icon,
            onClick: clickHandler || this.onClickHandler
         };
         this.actionBarItems.push(actionButton);
         return this;
      }

      public addSeparator(): DatastoreFileBrowserActionBarBuilder {
         this.actionBarItems.push(this.vuiConstants.actions.SEPARATOR);
         return this;
      }

      public build(): any[] {
         return this.actionBarItems;
      };

      onClickHandler = (e: Event, actionDef: any): void => {
         let actionId = actionDef.actionId;
         let action = this.actionDefinitions[actionId];
         //evaluate context getter function
         let context = this.contextGetters[actionId]();
         this.actionsService.invokeAction(action, context);
      }
   }

   export class DatastoreFileBrowserActionsService {
      public readonly ACTIONS = {
         CREATE_FOLDER: "vsphere.core.datastore.actions.createFolder",
         UPLOAD_FILE: "vsphere.core.datastore.actions.uploadToDatastore",
         UPLOAD_FOLDER: "vsphere.core.datastore.actions.uploadFolderToDatastore",
         DOWNLOAD_FILE: "vsphere.core.datastore.actions.downloadFromDatastore",
         DELETE_FILE: "vsphere.core.datastore.actions.deleteFile",
         REGISTER_VM: "vsphere.core.datastore.file.actions.registerVm",
         RENAME_FILE: "vsphere.core.datastore.actions.renameFile",
         INFLATE_THIN_DISK: "vsphere.core.datastore.inflateDiskAction",
         COPY_FILE: "vsphere.core.datastore.actions.copyFile",
         MOVE_FILE: "vsphere.core.datastore.actions.moveFile"
      };
      public static $inject = [
         "actionsService", "vuiConstants"
      ];

      constructor(private actionsService: any,
                  private vuiConstants: any) {
      }

      public requestActions(datastoreId: string, actionsToLoad: any[]|null = null): IPromise<any[]> {
         let dsBrowserActions = _.values(this.ACTIONS);
         if (h5 && h5.isDatastoreBrowserPopUpWindow) {
            // Do not show all vm related actions when in popup mode.
            // 'See datastoreBrowserPopUp.ts' for more info.
            let registerVmActionIndex = dsBrowserActions.indexOf(this.ACTIONS.REGISTER_VM);
            dsBrowserActions.splice(registerVmActionIndex, 1);
         }

         return this.actionsService.getActions([datastoreId], actionsToLoad || dsBrowserActions)
               .then(
                     (actionDefs:any[])=> {
                        let actionDefsById:{ [key: string]: any; } = {};
                        actionDefs.forEach((actionDef:any)=> {
                           actionDefsById[actionDef.action.uid] = actionDef;
                        });
                        return actionDefsById;
                     });
      }

      public buildActionBarItems(datastoreId: string, isActionAvailable: {(actionId: string, actionDef: any): boolean}): IPromise<DatastoreFileBrowserActionBarBuilder> {
         return this.requestActions(datastoreId).then((actionDefsById: any)=> {
            return new DatastoreFileBrowserActionBarBuilder(
                  actionDefsById,
                  this.actionsService,
                  this.vuiConstants,
                  isActionAvailable
            );
         });
      }

      public updateActionBar(datastoreId: string, actions: any[], isActionAvailable: {(actionId: string, actionDef: any): boolean}): void {
         this.requestActions(datastoreId).then((actionDefsById)=> {
            actions.forEach((actionButton:any)=> {
               if (actionButton === this.vuiConstants.actions.SEPARATOR) {
                  return;
               }
               let actionId = actionButton.actionId;
               let actionDef = actionDefsById[actionId];
               if (actionDef) {
                  actionButton.enabled = isActionAvailable(actionId, actionDef);
               }
            });
         });

      }

      public disableAll(actions: any) {
         actions.forEach((action:any)=> {
            if (action === this.vuiConstants.actions.SEPARATOR) {
               return;
            }
            action.enabled = false;
         });
      }


   }

   angular.module("com.vmware.vsphere.client.storage")
         .service("datastoreFileBrowserActionsService", DatastoreFileBrowserActionsService);
}
