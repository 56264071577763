module ds_cluster_ui {

   import IPromise = angular.IPromise;

   export class SdrsRulesService {

      public static $inject = ["i18nService", "dataService", "vuiConstants",
         "defaultUriSchemeUtil", "columnRenderersRegistry", "dsClusterConstants",
         "dsClusterSpecBuilderService", "mutationService", "clarityModalService"];

      private PROPERTY_SDRS_RULES: string = "computedSdrsRules";
      private PROPERTY_VM_VIRTUAL_DISKS: string = "virtualDisks";
      private PROPERTY_VM_RULE_DETAILS: string = "computedSdrsRuleVmDetails";

      constructor(private i18nService: any, private dataService: any,
            private vuiConstants: any, private defaultUriSchemeUtil: any,
            private columnRenderersRegistry: any, private dsClusterConstants: any,
            private dsClusterSpecBuilderService: DsClusterSpecBuilderService,
            private mutationService: any, private clarityModalService: any) { }

      public getMasterViewData(objectId: string, filterText?: string): IPromise<Object> {
         let options: any;

         if (filterText && filterText !== "") {
            options = {
               propertyParams: [{
                  propertyName: this.PROPERTY_SDRS_RULES,
                  parameterType: 'java.lang.String',
                  parameter: filterText
               }]
            };
         }

         return this.dataService
               .getProperties(objectId, [this.PROPERTY_SDRS_RULES], options)
               .then((data: any) => data.computedSdrsRules);
      }

      public getMasterGridOptions(onDataBound?: Function): Object {
         let options: any = {
            actionBarOptions: {
               actions: []
            },
            pageConfig: {
               hidePager: true
            },
            selectionMode: this.vuiConstants.grid.selectionMode.SINGLE,
            searchable: false,
            resizable: true,
            data: [],
            selectedItems: [],
            columnDefs: this.getMasterColumnDefs(),
            height: "100%",
            dataBound: onDataBound
         };

         return options;
      }

      public getVmdkDetailsViewData(rule: any): IPromise<string[]> {
         let vmRef: ManagedObjectReference =
               this.defaultUriSchemeUtil.getVsphereObjectId(rule.vm.provider);

         return this.dataService.getProperties(vmRef, [this.PROPERTY_VM_VIRTUAL_DISKS])
               .then((data: any) => {
                  let virtualDisksInRule: any = _.filter(data.virtualDisks,
                        (virtualDisk: any): boolean =>
                              _.contains(rule.rule.diskId, virtualDisk.key));

                  return _.map(virtualDisksInRule, (virtualDisk: any) => {
                     return {
                        name: virtualDisk.deviceInfo.label,
                        key: virtualDisk.key,
                        deviceInfo: {
                           label: virtualDisk.deviceInfo.label
                        }
                     };
                  });
               });
      }

      public getVmDetailsViewData(rule: any): IPromise<string[]> {
         let vms: string[] = _.map(rule.rule.vm,
               vm => this.defaultUriSchemeUtil.getVsphereObjectId(vm));

         return this.dataService.getPropertiesForObjects(vms,
               [this.PROPERTY_VM_RULE_DETAILS])
               .then((data: any) => _.map(data, (member: any, id: string) => {
                  return _.extend(member.computedSdrsRuleVmDetails, {"id": id});
               }));
      }

      public deleteRule(dsClusterId: string, rule: any): void {
         var confirmProperties = {
            title: this.i18nService.getString('DsClusterUi',
                  'sdrs.rules.action.remove.confirmation.title'),
            message: this.i18nService.getString('DsClusterUi',
                  'sdrs.rules.action.remove.confirmation.text'),
            submit: () => {
              this.deleteRuleFromDsCluster(dsClusterId, rule);
            }
         };

         this.clarityModalService.openConfirmationModal(confirmProperties);
      }

      private deleteRuleFromDsCluster(dsClusterId: string, dsClusterRule: any) {
         let ruleType: string = this.dsClusterConstants.ruleSpec.VM_ANTI_AFFINITY_TYPE;
         let vm: any = null;

         if (dsClusterRule.ruleType ===
               this.dsClusterConstants.ruleTypes.VMDK_ANTI_AFFINITY_TYPE) {
            ruleType = this.dsClusterConstants.ruleSpec.VMDK_ANTI_AFFINITY_TYPE;
            vm = dsClusterRule.vm.provider;
         }

         let deleteRuleSpec: DeleteDsClusterRuleSpec = {
            operation: this.dsClusterConstants.operationType.REMOVE,
            ruleInfo: {
               _type: this.dsClusterConstants.SDRS_RULE_INFO_SPEC,
               rule: {
                  _type: ruleType,
                  key: dsClusterRule.rule.key,
               },
               vm: vm
            }
         };

         this.mutationService.apply(dsClusterId, this.dsClusterConstants.SDRS_RULE_SPEC,
               deleteRuleSpec
         );
      }

      private getMasterColumnDefs(): Object[] {
         return [
            {
               displayName: this.getString("rules.view.name"),
               field: "rule.name",
               type: "string",
               template: "<span class=\"object\" title=\"#:rule.name#\">" +
                     "<i class=\"vx-icon-rule\")></i>#:rule.name#</span>"
            },
            {
               displayName: this.getString("rules.view.description"),
               field: "ruleType",
               type: "string",
               template: this.renderRuleType.bind(this)
            },
            {
               displayName: this.getString("rules.view.enabled"),
               field: "rule.enabled",
               type: "boolean",
               template: this.renderEnabled.bind(this)
            },
            {
               displayName: this.getString("rules.view.vmName"),
               field: "vm",
               type: "object",
               searchable: false,
               template: this.renderVmName.bind(this)
            }
         ];
      }

      private renderRuleType(data: any): string {
         if (data.ruleType === this.dsClusterConstants.ruleTypes.VM_ANTI_AFFINITY_TYPE) {
            return this.getString("rules.view.type.vmAntiAffinity");
         } else {
            return this.getString("rules.view.type.vmdkAntiAffinity");
         }
      }

      private renderEnabled(data: any): string {
         if (data.rule.enabled) {
            return this.getString("rules.view.enabled.yes");
         } else {
            return this.getString("rules.view.enabled.no");
         }
      }

      private renderVmName(data: any): string {
         if (data.vm === null) {
            return this.getString("rules.view.multipleVms");
         } else {
            let iconTextRenderer: any =
                  this.columnRenderersRegistry.getColumnRenderer("icon-text");
            return iconTextRenderer(["primaryIconId", "name"], data.vm);
         }
      }

      private getString(key: string): string {
         return this.i18nService.getString("Common", key);
      }

      public getVmdkDetailsGridOptions(): Object {
         let options: any = {
            pageConfig: {
               hidePager: true
            },
            searchable: false,
            resizable: false,
            data: [],
            selectedItems: [],
            columnDefs: this.getVmdkDetailsColumnDefs(),
            height: "100%"
         };

         return options;
      }

      private getVmdkDetailsColumnDefs(): Object[] {
         return [
            {
               displayName: this.getString("rules.view.name"),
               field: "name",
               type: "string",
               template: "<span class=\"object\" title=\"#:name#\">#:name#</span>"
            }
         ];
      }

      public getVmDetailsGridOptions(): Object {
         let options: any = {
            pageConfig: {
               hidePager: true
            },
            searchable: false,
            resizable: true,
            data: [],
            selectedItems: [],
            columnDefs: this.getVmDetailsColumnDefs(),
            height: "100%"
         };

         return options;
      }

      private getVmDetailsColumnDefs(): Object[] {
         return [
            {
               displayName: this.getString("rules.view.vmName"),
               field: "name",
               type: "string",
               template: "<span class=\"object\" title=\"#:name#\">" +
                     "<i class=\"#:primaryIconId#\")></i>#:name#</span>"
            },
            {
               displayName: this.i18nService.getString(
                     "DsClusterUi", "sdrs.rules.config.vmDatastoreHeaderText"),
               field: "datastoreName",
               type: "string",
               template: this.renderDatastoreData.bind(this)
            }
         ];
      }

      private renderDatastoreData(data: any): string {
         let objData: any = data || {};
         objData.primaryIconId = "vsphere-icon-datastore";
         let renderer: any = this.columnRenderersRegistry.getColumnRenderer("object-name");

         return renderer(
               [null, "primaryIconId", "datastoreName", null, "datastoreHint"], objData);
      }

      public getSdrsRuleTypes(): any[] {
         return [{
            id: this.dsClusterConstants.ruleSpec.VM_ANTI_AFFINITY_TYPE,
            label: this.getString("sdrs.rules.config.label.VM_ANTIAFFINITY_RULE_TYPE")
         }, {
            id: this.dsClusterConstants.ruleSpec.VMDK_ANTI_AFFINITY_TYPE,
            label: this.getString(
                  "sdrs.rules.config.label.VIRTUAL_DISK_ANTI_AFFINITY_RULE_TYPE")
         }];
      }

      private createRuleInstance(ruleSpec: string, name: string, enabled: boolean,
            members: any, key: string): any {
         let ruleInfo: any = {
            name: name,
            enabled: enabled,
            userCreated: true,
            key: key
         };

         switch (ruleSpec) {
            case this.dsClusterConstants.ruleSpec.VM_ANTI_AFFINITY_TYPE:
               ruleInfo = _.extend({
                  vm: members,
                  _type: ruleSpec
               }, ruleInfo);
               break;
            case this.dsClusterConstants.ruleSpec.VMDK_ANTI_AFFINITY_TYPE:
               ruleInfo = _.extend({
                  diskId: members,
                  _type: ruleSpec
               }, ruleInfo);
               break;
            default:
               break;
         }

         return ruleInfo;
      }

      private createSdrsRuleInfo(ruleInfo: any, ruleType: string, operation: string,
            vmdkVm: any): any {
         let sdrsRuleInfo: any = {
            _type: this.dsClusterConstants.SDRS_RULE_INFO_SPEC,
            rule: ruleInfo,
            ruleType: ruleType,
            operation: operation
         };

         if (ruleType === this.dsClusterConstants.ruleTypes.VMDK_ANTI_AFFINITY_TYPE) {
            sdrsRuleInfo = _.extend({
               vm: vmdkVm
            }, sdrsRuleInfo);
         }

         return sdrsRuleInfo;
      }

      private getRuleTypeByRuleSpec(ruleSpec: string): string {
         switch (ruleSpec) {
            case this.dsClusterConstants.ruleSpec.VM_ANTI_AFFINITY_TYPE:
               return this.dsClusterConstants.ruleTypes.VM_ANTI_AFFINITY_TYPE;
            case this.dsClusterConstants.ruleSpec.VMDK_ANTI_AFFINITY_TYPE:
               return this.dsClusterConstants.ruleTypes.VMDK_ANTI_AFFINITY_TYPE;
         }

         return "";
      }

      public buildSdrsRuleInfo(ruleSpec: string, name: string, enabled: boolean,
            ruleMembers: any, operation: string, ruleKey: string, vmdkVm: any): any {
         let ruleInfo: any =
               this.createRuleInstance(ruleSpec, name.trim(), enabled, ruleMembers,
                      ruleKey);

         let ruleType: string = this.getRuleTypeByRuleSpec(ruleSpec);

         return this.createSdrsRuleInfo(ruleInfo, ruleType, operation, vmdkVm);
      }

      public buildSdrsRuleSpec(ruleInfo: any, operation: string): any {
         return {
            _type: this.dsClusterConstants.SDRS_RULE_SPEC,
            ruleInfo: ruleInfo,
            operation: operation
         };
      }

      public getVmVirtualDisks(vmId: string): IPromise<any> {
         return this.dataService.getProperties(vmId, [this.PROPERTY_VM_VIRTUAL_DISKS])
               .then((data: any) => {
            return _.map(data.virtualDisks, (virtualDisk: any) => {
               return {
                  key: virtualDisk.key,
                  deviceInfo: virtualDisk.deviceInfo
               };
            });
         });
      }

      public getRuleMemberRefs(spec: any): any[] {
         let ruleMemberRefs: any[];

         if (spec.ruleType.id ===
               this.dsClusterConstants.ruleSpec.VM_ANTI_AFFINITY_TYPE) {
            ruleMemberRefs = _.map(spec.ruleMembersData, (vm: any) => {
               return this.defaultUriSchemeUtil.getManagedObjectReference(vm.id);
            });
         } else {
            ruleMemberRefs = _.pluck(spec.ruleMembersData, "key");
         }
         return ruleMemberRefs;
      }
   }

   angular.module("com.vmware.vsphere.client.dsCluster")
         .service("sdrsRulesService", SdrsRulesService);
}
