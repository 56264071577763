module ds_cluster_ui {

   import IController = angular.IController;
   import SdrsRulesService = ds_cluster_ui.SdrsRulesService;

   class VmAntiAffinityRuleDetailsController implements IController {
      static $inject = ["$rootScope", "i18nService", "sdrsRulesService"];
      public gridOptions: any;
      public ruleDetailsTitle: string;
      public ruleDetailsDescription: string;
      public selectedRuleDetails: any;

      constructor(private $rootScope: any, private i18nService: any,
            private sdrsRulesService: SdrsRulesService) {

         this.ruleDetailsTitle = i18nService.getString("DsClusterUi",
               "manage.settings.sdrsRuleDetails");
         this.ruleDetailsDescription = i18nService.getString("DsClusterUi",
               "sdrs.rules.view.vm.details.hint");
      }

      public $onChanges(changes: any): void {
         if (typeof changes.rule !== "undefined") {
            this.gridOptions = this.sdrsRulesService.getVmDetailsGridOptions();

            this.sdrsRulesService.getVmDetailsViewData(changes.rule.currentValue)
                  .then((data: any) => {
                     this.selectedRuleDetails = {
                        "data": data,
                        "formattedData": () => {
                           return _.map(data, (member: any) => {
                              return {
                                 "id": member.id,
                                 "icon": member.primaryIconId,
                                 "name": member.name,
                                 "rawData": {
                                    datastoreNames: [member.datastoreName],
                                    datastoreHint: member.datastoreHint
                                 }
                              };
                           });
                        }
                     };

                     this.gridOptions.data = data;
                  });
         }
      }
   }

   class VmAntiAffinityRuleDetailsComponent implements ng.IComponentOptions {
      public controller: any;
      public templateUrl: string;
      public bindings: any;

      constructor() {
         this.controller = VmAntiAffinityRuleDetailsController;
         this.templateUrl =
               "ds-cluster-ui/resources/ds-cluster/views/settings/configuration/rules/" +
               "vmAndVmdkAntiAffinityRuleDetailsComponent.html";
         this.bindings = {
            rule: "<",
            selectedRuleDetails: "="
         };
      }
   }

   angular.module("com.vmware.vsphere.client.dsCluster").component(
         "vmAntiAffinityRuleDetailsComponent",
               new VmAntiAffinityRuleDetailsComponent());
}
