/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace spbm_ui {
   angular.module("com.vmware.vsphere.client.spbm").constant("VmStoragePoliciesConstants", {

      device: {
         type: {
            VM_HOME : "vmHome",
            VM_DISK : "vmDisk"
         }
      }

   });
}
