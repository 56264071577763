(function() {
   'use strict';

   angular.module('com.vmware.vsphere.client.vm')
         .service('convertTemplateToVmService', convertTemplateToVmService);

   convertTemplateToVmService.$inject = ['VmProvisioningComputeResourcePageService',
      'vmProvisioningFinishConvertToVmPageService'];

   function convertTemplateToVmService(VmProvisioningComputeResourcePageService,
         vmProvisioningFinishConvertToVmPageService) {
      return function() {
         this.buildPages = buildPages;

         function buildPages(wizardScope, defaultTargetId, preselectedComputeResource) {
            var dcId = wizardScope.wizardViewData.getDatacenterId();
            wizardScope.vmParams
                  .setTargetInformation(null, dcId , null, null);

            var computeResourcePage = VmProvisioningComputeResourcePageService.build(
                  wizardScope, preselectedComputeResource);
            var finishPage = vmProvisioningFinishConvertToVmPageService.build(
                  wizardScope);

            return {
               pages: [
                  computeResourcePage,
                  finishPage
               ]
            };
         }
      };
   }
})();
