/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
(function () {
   'use strict';
   angular
         .module('com.vmware.vsphere.client.dvs')
         .controller('DvPortgroupGeneralPropertiesPageController',
               DvPortgroupGeneralPropertiesPageController);

   DvPortgroupGeneralPropertiesPageController.$inject = [
      '$scope',
      'dvPortgroupGeneralPropertiesPageService',
      '$q',
      'i18nService',
      'networkUiConstants',
      'dvpgPropertiesService',
      'networkUtil'];

   function DvPortgroupGeneralPropertiesPageController($scope,
         dvPortgroupGeneralPropertiesPageService,
         $q,
         i18nService,
         networkUiConstants,
         dvpgPropertiesService,
         networkUtil) {

      $scope.nameValidator = {
         message: null,
         isVisible: false
      };

      $scope.descriptionValidator = {
         message: null,
         isVisible: false
      };

      $scope.numPortsValidator = {};

      $scope.portBindingOptions = [{
         label: networkUtil.getString('NetworkUtil.earlyBinding'),
         val: networkUiConstants.PortgroupType.EARLY_BINDING
      }, {
         label: networkUtil.getString('NetworkUtil.ephemeral'),
         val: networkUiConstants.PortgroupType.EPHEMERAL
      }];

      $scope.portAllocationOptions = [{
         label: i18nService.getString(
               'NetworkUi', 'dvpg.properties.portAllocation.autoExpand.true'),
         val: true
      }, {
         label: i18nService.getString(
               'NetworkUi', 'dvpg.properties.portAllocation.autoExpand.false'),
         val: false
      }];

      $scope.networkResourcePoolOptions = [];

      if ($scope.manager.dvPortgroupData.networkResourcePools &&
            $scope.manager.dvPortgroupData.networkResourcePools.length > 0) {

         $scope.manager.dvPortgroupData.networkResourcePools.forEach(
               function (resourcePool) {

            $scope.networkResourcePoolOptions.push({
               label: resourcePool.name,
               val: resourcePool.key
            });
         });
      }

      $scope.onNameChange = function () {
         var message = dvpgPropertiesService.getInvalidNameError(
               $scope.manager.dvPortgroupData.name);
         $scope.nameValidator.message = message;
         $scope.nameValidator.isVisible = message !== null;
      };

      $scope.onDescriptionChange = function () {
         var message = dvPortgroupGeneralPropertiesPageService.getInvalidDescriptionError(
               $scope.manager.dvPortgroupData.description);
         $scope.descriptionValidator.message = message;
         $scope.descriptionValidator.isVisible = message !== null;
      };

      setNumPortsValidator();

      $scope.onNumPortsChange = function () {
         setNumPortsValidator();
      };

      $scope.manager.commitPageCallbacks.commitGeneralPropertiesPage = onCommit;

      if (!$scope.manager.dvPortgroupData.isUplink) {
         $scope.$watch(function() {
            return $scope.manager.dvPortgroupData.type;
         }, function(newType, oldType) {

            switch(newType) {
               case networkUiConstants.PortgroupType.EARLY_BINDING: {
                  $scope.manager.dvPortgroupData.showPortAllocation = true;
                  $scope.manager.dvPortgroupData.showPortAllocationInfo =
                        $scope.manager.dvPortgroupData.autoExpand;
                  break;
               }
               case networkUiConstants.PortgroupType.EPHEMERAL: {
                  $scope.manager.dvPortgroupData.showPortAllocation = false;
                  $scope.manager.dvPortgroupData.showPortAllocationInfo = false;
                  break;
               }
            }

            if ($scope.manager.dvPortgroupData.oldType === networkUiConstants.PortgroupType.LATE_BINDING
                  && oldType !== newType) {
               $scope.manager.dvPortgroupData.showDynamicPortBindingWarning = false;
            }
         });

         $scope.$watch(function() {
            return $scope.manager.dvPortgroupData.autoExpand;
         }, function(newPortAllocation) {
            $scope.manager.dvPortgroupData.showPortAllocationInfo = newPortAllocation &&
                  networkUiConstants.PortgroupType.EARLY_BINDING ===
                        $scope.manager.dvPortgroupData.type;
         });
      }

      function onCommit() {
         $scope.manager.pageDirtyFlags.isGeneralPropertiesPageDirty =
               haveGeneralPropertiesChanged();

         if ($scope.manager.pageDirtyFlags.isGeneralPropertiesPageDirty) {
            return validateProperties().then(function(errors) {
               if (errors.length > 0) {
                  $scope.manager.showDialogErrors(errors);
               }

               return errors.length === 0;
            });
         }

         return true;
      }

      function validateProperties() {
         var errors = [];

         var invalidNameError = dvpgPropertiesService
               .getInvalidNameError($scope.manager.dvPortgroupData.name);

         if (invalidNameError) {
            errors.push(invalidNameError);
         }

         if ($scope.manager.dvPortgroupData.type ===
               networkUiConstants.PortgroupType.EARLY_BINDING) {
            var numPortsError = getNumPortErrorMessage();

            if (numPortsError) {
               errors.push(numPortsError);
            }
         }

         var descriptionError = dvPortgroupGeneralPropertiesPageService
               .getInvalidDescriptionError($scope.manager.dvPortgroupData.description);

         if (descriptionError) {
            errors.push(descriptionError);
         }

         if (errors.length > 0) {
            return $q.resolve(errors);
         }

         var data = $scope.manager.dvPortgroupData;

         if ($scope.initialData.name === data.name) {
            return $q.resolve([]);
         }

         return dvpgPropertiesService.getDuplicateOrTooLongNameError(
               data.dvPortgroupId, data.name);
      }

      function getNumPortErrorMessage() {
         var message;

         if ($scope.manager.dvPortgroupData.initialNumPorts >
               networkUiConstants.DvPortgroupNumPorts.MAX) {
            message = dvpgPropertiesService.getNumPortsError(
                  $scope.manager.dvPortgroupData.numPorts,
                  $scope.manager.dvPortgroupData.initialNumPorts);
         } else {
            message = dvpgPropertiesService.getNumPortsError(
                  $scope.manager.dvPortgroupData.numPorts);
         }
         return message;
      }

      function setNumPortsValidator() {
         var message = getNumPortErrorMessage();
         $scope.numPortsValidator.message = message;
         $scope.numPortsValidator.isVisible = message !== null;
      }

      function haveGeneralPropertiesChanged() {
         return $scope.manager.dvPortgroupData.name !== $scope.initialData.name ||
               $scope.manager.dvPortgroupData.type !== $scope.initialData.type ||
               $scope.manager.dvPortgroupData.autoExpand !== $scope.initialData.autoExpand ||
               $scope.manager.dvPortgroupData.numPorts !== $scope.initialData.numPorts ||
               $scope.manager.dvPortgroupData.description !== $scope.initialData.description ||
               $scope.manager.dvPortgroupData.networkResourcePoolKey !== $scope.initialData.networkResourcePoolKey;
      }

      return this;
   }
})();
