/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace h5_vm {

   export class MigrateSelectNetworkPageModel {
      /**
       * [input]
       *
       * The view mode of the page when loaded for the first time. If specified
       * should be either 'basic' or 'advanced'.
       */
      public initialViewMode?: string;

      /**
       * [input]
       *
       * The network mapping to apply when loaded for the first time
       */
      public initialMatches?: VmNetworkAdapterNetworkMatch[];

      /**
       * [page specific]
       *
       * Indicates if the compatibility checks of the basic view have passed successfully
       */
      public isBasicPageValid: boolean = false;

      /**
       * [page specific]
       *
       * Indicates if the compatibility checks of the advanced view have passed successfully
       */
      public isAdvancedPageValid: boolean = false;

      private state: any;

      constructor () {
         this.state = {
            selectNetworkPage: {
               computeResourceUrn: "",
               viewModeState: undefined,
               isViewChanged: false,
               basicViewMatches: [],
               advancedViewMatches: [],
               isInitialized: false,
               isValidating: false,
               basicCompatibilityResultsCache: null,
               advancedCompatibilityResultsCache: null,
               viewData: {},
               prefetchedNetworks: {
                  basic: [],
                  advanced: [],
                  totalMatchesCount: 0
               }
            }
         };
      }

      public getSelectNetworkPageViewMode(): string {
         return this.state.selectNetworkPage.viewModeState ||
               this.initialViewMode ||
               "basic";
      }

      public  setSelectNetworkPageViewMode(viewState: string): void {
         this.state.selectNetworkPage.viewModeState = viewState;
      }

      public getSelectNetworkPageIsViewChanged(): any {
         return this.state.selectNetworkPage.isViewChanged;
      }

      public setSelectNetworkPageIsViewChanged(value: any): void {
         this.state.selectNetworkPage.isViewChanged = value;
      }

      public getBasicNetworkMatches(): any {
         return this.state.selectNetworkPage.basicViewMatches;
      }

      public setBasicNetworkMatches(values: any): void {
         this.state.selectNetworkPage.basicViewMatches = values;
      }

      public getAdvancedNetworkMatches(): any {
         return this.state.selectNetworkPage.advancedViewMatches;
      }

      public setAdvancedNetworkMatches(values: any): void {
         this.state.selectNetworkPage.advancedViewMatches = values;
      }

      public getSelectNetworkPageIsInitialized(): any {
         return this.state.selectNetworkPage.isInitialized;
      }

      public setSelectNetworkPageIsInitialized(value: any): void {
         this.state.selectNetworkPage.isInitialized = !!value;
      }

      public getSelectNetworkPageIsValidating(): any {
         return this.state.selectNetworkPage.isValidating;
      }

      public setSelectNetworkPageIsValidating(value: any): void {
         this.state.selectNetworkPage.isValidating = !!value;
      }

      public getSelectNetworkPageViewData(): any {
         return this.state.selectNetworkPage.viewData;
      }

      public getSelectNetworkPagePrefechedNetworks(): {
            basic: PrefetchedNetwork[],
            advanced: PrefetchedNetwork[],
            totalMatchesCount: number} {
         return this.state.selectNetworkPage.prefetchedNetworks;
      }

      public getBasicCompatibilityResultsCache(): any {
         return this.state.selectNetworkPage.basicCompatibilityResultsCache;
      }

      public setBasicCompatibilityResultsCache(value: any): void {
         this.state.selectNetworkPage.basicCompatibilityResultsCache = value;
      }

      public getAdvancedCompatibilityResultsCache(): any {
         return this.state.selectNetworkPage.advancedCompatibilityResultsCache;
      }

      public setAdvancedCompatibilityResultsCache(value: any): void {
         this.state.selectNetworkPage.advancedCompatibilityResultsCache = value;
      }

      public getComputeResourceUrn(): any {
         return this.state.selectNetworkPage.computeResourceUrn;
      }

      public setComputeResourceUrn(computeResourceUrn: any): void {
         this.state.selectNetworkPage.computeResourceUrn = computeResourceUrn;
      }

      public checkResourceAndReset(urn: any): void {
         if (urn === this.getComputeResourceUrn()) {
            return;
         }

         this.reset();
         this.setComputeResourceUrn(urn);
      }

      public reset(): void {
         this.state.selectNetworkPage.isInitialized = false;
      }
   } // class MigrateSelectNetworkPageModel

   let migrateSelectNetworkPageModelService: Function = function () {
      return MigrateSelectNetworkPageModel;
   };

   angular.module("com.vmware.vsphere.client.vm")
      .service("MigrateSelectNetworkPageModel", migrateSelectNetworkPageModelService);

} // namespace