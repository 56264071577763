/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
(function () {
   'use strict';

   /**
    *  Helper for opening the "Add ISCSI target dialog".
    */
   angular
         .module('com.vmware.vsphere.client.storage')
         .service('AddIscsiTargetDialogService', AddIscsiTargetDialogService);

   AddIscsiTargetDialogService.$inject = [
      'clarityModalService',
      'clarityConstants',
      'i18nService',
      'adapterTargetsService',
      'storageValidationService',
      'ipParserService',
      'storageAdapterConstants',
      'storageAdapterEditAuthenticationSpecBuilderService',
      'vxValidatorFactory'];

   function AddIscsiTargetDialogService (
      clarityModalService,
      clarityConstants,
      i18nService,
      adapterTargetsService,
      storageValidationService,
      ipParserService,
      storageAdapterConstants,
      authSpecBuilderService,
      vxValidatorFactory
   ) {
      // ======================= Public API ==========================

      return {
         openAddIscsiTargetDialog: openAddIscsiTargetDialog
      };

      //======================= Public Functions ==========================

      /**
       * Opens an OK/Cancel modal dialog in the application.
       */
      function openAddIscsiTargetDialog(adapter, hostId, dynamicTarget, existingTargets, invocationContext) {

         var title = i18nService.getString(
               'StorageUi',
               dynamicTarget
                     ? 'storage.adapters.iscsiTargets.actions.addSend.h5header'
                     : 'storage.adapters.iscsiTargets.actions.addStatic.h5header');
         var DEFAULT_TARGET_PORT = 3260;
         var authSettingsComponentApi = {};

         var modalOptions = {
            title: title,
            subTitle: {
               text: adapter.device
            },
            defaultButton: 'submit',
            alerts: [],
            dialogData: {
               adapter: adapter,
               target: {
                  server: '',
                  port: DEFAULT_TARGET_PORT,
                  targetName: !dynamicTarget ? '' : null
               },
               options: {
                  dynamicTarget: dynamicTarget,
                  PORT_MAX_VALUE: 65535,
                  PORT_MIN_VALUE: 1
               },
               authSettingsComponentApi: authSettingsComponentApi,
               inheritAuthSettings: true,
               initialAuthenticationProperties: {
                  authenticationMethod: storageAdapterConstants.authentication.PROHIBITED
               },
               validator: vxValidatorFactory.create(),
               isValidServer: function() {
                  var errors = [];
                  var serverName = modalOptions.dialogData.target.server;
                  if (!ipParserService.isIpv6AddressValid(serverName)
                           && !storageValidationService.isValidDnsName(serverName)) {
                     errors.push(i18nService.getString('StorageUi','storage.adapters.edit.authentication.form.invalidSendAddress'));
                  }

                  return errors;
               },
               isValidPort: function() {
                  var errors = [];
                  if (!storageValidationService.isValidPort(modalOptions.dialogData.target.port)) {
                        errors.push(i18nService.getString(
                           'StorageUi','storage.adapters.iscsiTargets.add.form.error.invalidPort.text',
                           modalOptions.dialogData.options.PORT_MIN_VALUE,
                           modalOptions.dialogData.options.PORT_MAX_VALUE));
                     }

                  return errors;
               }
            },
            contentTemplate: 'storage-ui/resources/storage/views/host/adapters/targets/AddIscsiTargetDialog.html',
            onSubmit: onSubmit
         };

         clarityModalService.openOkCancelModal(modalOptions);

         function onSubmit () {
            if (!validate()){
               return false;
            }
            if (modalOptions.dialogData.target.server && !isNaN(modalOptions.dialogData.target.port) &&
               (dynamicTarget || modalOptions.dialogData.target.targetName)) {

               var authSettings = authSettingsComponentApi.getSettings();

               var authenticationProperties =
                  authSpecBuilderService.buildAuthenticationPropertiesForTarget(
                     modalOptions.dialogData.inheritAuthSettings,
                     authSettings.authMethodSelected,
                     authSettings.outgoing,
                     authSettings.incoming,
                     '');

               var addResult = adapterTargetsService.addIscsiAdapterTargets(hostId, dynamicTarget,
                  adapter.device, modalOptions.dialogData.target.port,
                  modalOptions.dialogData.target.server, modalOptions.dialogData.target.targetName, authenticationProperties);

               addResult.then(function (result) {
                  if (result && result.effect && result.effect.affectedEntites
                        && result.effect.affectedEntites.length) {
                     // Notify that add is performed
                     if (invocationContext && invocationContext.callback) {
                        invocationContext.callback(result, adapter);
                     }
                  }
               });

               return true;
            }

            return false;
         }

         function validate () {
            var isValid = true;

            var errors = modalOptions.dialogData.validator.validate();
            if (errors && errors.length) {
               displayErrorMessage('form.validationError');
               isValid = false;
            }

            if (targetAlreadyExists()) {
               displayErrorMessage('storage.adapters.edit.authentication.form.targetExists');
               isValid = false;
            }

            if(!modalOptions.dialogData.inheritAuthSettings) {
               var authValidationResult = modalOptions.dialogData.authSettingsComponentApi.validate();
               if (!authValidationResult.isValid) {
                  if (isValid) {
                     if (authValidationResult.errors && authValidationResult.errors.length) {
                        displayAuthenticationErrorMessage(authValidationResult.errors);
                     } else {
                        displayErrorMessage('form.validationError');
                     }
                  }

                  isValid = false;
               }
            }

            return isValid;
         }

         function targetAlreadyExists () {
            var targetToAdd = modalOptions.dialogData.target;
            return _.some(existingTargets, function(target) {
               var attrs = target.address.split(':');
               return targetToAdd.server === attrs[0]
                  && targetToAdd.port === Number(attrs[1])
                  && (dynamicTarget || target.name === targetToAdd.targetName);
            });
         }

         function displayAuthenticationErrorMessage(errors) {
            modalOptions.alerts = _.map(errors, function(error) {
               return {
                  text: error,
                  type: clarityConstants.notifications.type.ERROR
               };
            });
         }

         function displayErrorMessage (msgKey) {
            var alertMessage = {
               text: i18nService.getString('StorageUi', msgKey),
               type: clarityConstants.notifications.type.ERROR
            };
            if (modalOptions.alerts.length > 0) {
               modalOptions.alerts = [];
            }
            modalOptions.alerts = [alertMessage];
         }
      }
   }
})();
