namespace h5_vm {
   export class VmProvisioningFinishRegisterPageService {
      public static $inject: string[] = ["i18nService",
         "vmVuiWizardPageBuilder",
         "vmProvisioningFinishRegisterPageModel"
      ];

      constructor(private i18nService: any,
         private vmVuiWizardPageBuilder: any,
         private vmProvisioningFinishRegisterPageModel: any) {}

      build(wizardScope: any): any {
         let pageModel = new this.vmProvisioningFinishRegisterPageModel(
               wizardScope.vmParams,
               wizardScope.wizardViewData);

         wizardScope.finishRegisterPageModel = pageModel;

         return this.vmVuiWizardPageBuilder.buildVuiWizardFinishPage(wizardScope.config, {
            title: this.i18nService.getString("VmUi", "ProvisioningWizardSummaryPage.Title"),
            description: this.i18nService.getString("VmUi", "regVm.Summary.pageDesc"),
            contentUrl: "vm-ui/resources/vm/views/createVmWizard/vmProvisioningFinishRegister.html",
            model: pageModel
         });
      }
   }
   angular.module("com.vmware.vsphere.client.vm")
      .service("vmProvisioningFinishRegisterPageService", VmProvisioningFinishRegisterPageService);
}
