/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace dvs_ui {

   import DvsAddSpanSessionSpec =
         com.vmware.vsphere.client.h5.network.dvs.portmirroring.model.wizard.DvsAddSpanSessionSpec;

   /**
    * Provides methods for creating the mutation spec sent to the backend
    * services after the completion of the DVS Add Port Mirroring Session wizard.
    */
   export class DvsAddSpanSessionWizardSpecFactory {

      /**
       * Builds the spec the span session creation.
       *
       * @param model DvsAddSpanSessionWizardModel
       */
      public buildSpec(model: DvsAddSpanSessionWizardModel): DvsAddSpanSessionSpec {
         let spec: DvsAddSpanSessionSpec = new DvsAddSpanSessionSpec();

         spec.sessionType = model.sessionType;

         spec.sessionName = model.propertiesPageModel.sessionName;
         spec.enabled = model.propertiesPageModel.enabled;

         spec.mirroredPacketLength = model.propertiesPageModel.mirroredPacketLengthEnabled
               ? model.propertiesPageModel.mirroredPacketLength : -1;

         spec.samplingRate = model.propertiesPageModel.samplingRate;
         spec.description = model.propertiesPageModel.description;

         switch (model.sessionType) {
            case DvsSpanSessionConstants.SESSION_TYPE.L3_SOURCE:
               this.buildEncapsulatedSessionSpec(spec, model);
               break;
            case DvsSpanSessionConstants.SESSION_TYPE.REMOTE_MIRROR_SOURCE:
               this.buildRemoteSourceSessionSpec(spec, model);
               break;
            case DvsSpanSessionConstants.SESSION_TYPE.DV_PORT_MIRROR:
               this.buildDvPortSessionSpec(spec, model);
               break;
            case DvsSpanSessionConstants.SESSION_TYPE.REMOTE_MIRROR_DEST:
               this.buildRemoteDestSessionSpec(spec, model);
               break;
         }

         return spec;
      }

      private buildEncapsulatedSessionSpec(spec: DvsAddSpanSessionSpec,
            model: DvsAddSpanSessionWizardModel): void {

         spec.encapsulationType = model.propertiesPageModel.encapsulationType;
         if (model.propertiesPageModel.encapsulationType !==
               DvsSpanSessionConstants.ENCAPSULATION_TYPE.GRE) {
            spec.sessionId = model.propertiesPageModel.sessionId;
         }
         if (model.propertiesPageModel.isTcpIpStackSupported) {
            spec.tcpIpStack = model.propertiesPageModel.tcpIpStack;
         }

         this.buildSourcePortsSpec(spec, model);

         if (model.selectDestinationsPageModel.ipAddressesListModel.values) {
            spec.destinationIps =
                  model.selectDestinationsPageModel.ipAddressesListModel.values
                        .filter((ip: any): boolean => {
                           return ip;
                        });
         }

      }

      private buildRemoteSourceSessionSpec(spec: DvsAddSpanSessionSpec,
                                           model: DvsAddSpanSessionWizardModel): void {

         spec.encapsulationVlanId = model.propertiesPageModel.encapsulationVlanId;
         spec.preserveOriginalVlan = model.propertiesPageModel.preserveOriginalVlan;
         spec.normalTrafficAllowed = model.propertiesPageModel.normalTrafficAllowed;

         this.buildSourcePortsSpec(spec, model);

         spec.destinationUplinks = model.selectDestinationsPageModel
               .uplinksListModel.uplinks;
      }

      private buildSourcePortsSpec (spec: DvsAddSpanSessionSpec,
                                    model: DvsAddSpanSessionWizardModel): void {
         if (model.selectSourcesPageModel.portsListModel.ports) {
            spec.sourcePortsEgress = [];
            spec.sourcePortsIngress = [];

            model.selectSourcesPageModel.portsListModel.ports
                  .forEach((port: PortData): void => {
                     if (port.trafficDirection ===
                           DvsSpanSessionConstants.TRAFFIC_DIRECTION.EGRESS
                           || port.trafficDirection ===
                           DvsSpanSessionConstants.TRAFFIC_DIRECTION.BOTH) {
                        spec.sourcePortsEgress.push(port.id);
                     }

                     if (port.trafficDirection ===
                           DvsSpanSessionConstants.TRAFFIC_DIRECTION.INGRESS
                           || port.trafficDirection ===
                           DvsSpanSessionConstants.TRAFFIC_DIRECTION.BOTH) {
                        spec.sourcePortsIngress.push(port.id);
                     }
                  });
         }

      }

      private buildDvPortSessionSpec(spec: DvsAddSpanSessionSpec,
            model: DvsAddSpanSessionWizardModel): void {

         spec.normalTrafficAllowed = model.propertiesPageModel.normalTrafficAllowed;

         if (model.selectSourcesPageModel.portsListModel.ports) {
            spec.sourcePortsEgress = [];
            spec.sourcePortsIngress = [];

            model.selectSourcesPageModel.portsListModel.ports
                  .forEach((port: PortData): void => {
                     if (port.trafficDirection ===
                           DvsSpanSessionConstants.TRAFFIC_DIRECTION.EGRESS
                           || port.trafficDirection ===
                           DvsSpanSessionConstants.TRAFFIC_DIRECTION.BOTH) {
                        spec.sourcePortsEgress.push(port.id);
                     }

                     if (port.trafficDirection ===
                           DvsSpanSessionConstants.TRAFFIC_DIRECTION.INGRESS
                           || port.trafficDirection ===
                           DvsSpanSessionConstants.TRAFFIC_DIRECTION.BOTH) {
                        spec.sourcePortsIngress.push(port.id);
                     }
                  });
         }

         spec.destinationPorts = _.map(model.selectDestinationsPageModel.portsListModel.ports,
               (data: PortData): string => {
                  return data.id;
               });
      }

      private buildRemoteDestSessionSpec(spec: DvsAddSpanSessionSpec,
                                         model: DvsAddSpanSessionWizardModel): void {
         spec.normalTrafficAllowed = model.propertiesPageModel.normalTrafficAllowed;

         spec.sourceVlanIds = _.map(model.selectSourcesPageModel.vlanIdListModel.values,
               (vlanId: string): number => {
                  return +vlanId; //convert to number
               });

         spec.destinationPorts = _.map(model.selectDestinationsPageModel.portsListModel.ports,
               (port: PortData): string => {
                  return port.id;
               });
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .service("dvsAddSpanSessionWizardSpecFactory", DvsAddSpanSessionWizardSpecFactory);
}
