/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
(function () {
   'use strict';
   angular.module('com.vmware.vsphere.client.dvs').controller(
         'DvPortrgroupPropertiesViewController', DvPortrgroupPropertiesViewController);

   DvPortrgroupPropertiesViewController.$inject = ['i18nService', '$scope',
      'dataService', 'dvPortgroupPropertiesService'];

   function DvPortrgroupPropertiesViewController(i18nService, $scope,
         dataService, dvPortgroupPropertiesService) {
      var self = this;
      self.dvPortgroupId = $scope._route.objectId;
      var dvPortgroupProperties = 'dvpg:dvPortgroupProperties';

      var headerOptions = {
         title: i18nService.getString(
               'NetworkUi', 'dvpg.manage.settings.propertiesHeader'),
         objectId: self.dvPortgroupId,
         actions: [
            {
               actionUid: 'vsphere.core.dvPortgroup.editSettingsAction',
               customLabel: i18nService.getString(
                     'H5NetworkUi', 'dvpg.properties.actions.edit.label')
            }]
      };
      self.settingsViewHeaderAccessor = {};
      self.headerOptions = headerOptions;

      self.refresh = function() {
         self.loading = true;

         if (self.settingsViewHeaderAccessor && self.settingsViewHeaderAccessor.refresh) {
            self.settingsViewHeaderAccessor.refresh();
         }

         dataService.getProperties(self.dvPortgroupId, [dvPortgroupProperties])
               .then(function (response) {
                  self.loading = false;
                  if (response && response[dvPortgroupProperties]) {
                     self.dvPortgroupPropertiesData = dvPortgroupPropertiesService.buildProperties(
                           response[dvPortgroupProperties]);
                  }
               });

         self.liveRefreshProperties = [
            "config.name",
            "config.description",
            "config.type",
            "config.numPorts",
            "config.autoExpand",
            "config.vmVnicNetworkResourcePoolKey",
            "config.policy.portConfigResetAtDisconnect",
            "config.policy.securityPolicyOverrideAllowed",
            "config.policy.vlanOverrideAllowed",
            "config.policy.vendorConfigOverrideAllowed",
            "config.policy.uplinkTeamingOverrideAllowed",
            "config.policy.ipfixOverrideAllowed",
            "config.policy.trafficFilterOverrideAllowed",
            "config.policy.blockOverrideAllowed",
            "config.policy.shapingOverrideAllowed"
         ];
      };

      self.refresh();

      return this;
   }
})();
