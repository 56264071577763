(function() {
   'use strict';
   angular.module('com.vmware.vsphere.client.host').service('advancedSystemSettingsService',
         AdvancedSystemSettingsService);

   AdvancedSystemSettingsService.$inject = ['vuiConstants',
      'i18nService', 'mutationService', 'resourceService'];

   function AdvancedSystemSettingsService(vuiConstants, i18nService,
                                          mutationService, resourceService) {

      function getGridOptions(onSaveFn, customValidation) {
         return {
            selectionMode: vuiConstants.grid.selectionMode.SINGLE,
            sortMode: vuiConstants.grid.sortMode.SINGLE,
            resizable: true,
            selectedItems: [],
            data: [],
            columnDefs: getColumnDefs(customValidation),
            height: '100%',
            editable: {
               mode: 'incell',
               createAt: 'bottom'
            },
            // handler for grid save
            save: onSaveFn
         };
      }

      function getColumnDefs(customValidation) {
         return [
            {
               displayName: getString('advancedSettings.edit.nameColumnHeader'),
               field: 'key',
               type: 'string',
               searchable: false,
               editable: false,
               width: '480px'
            },
            {
               displayName: getString('advancedSettings.edit.valueColumnHeader'),
               field: 'value',
               type: 'string',
               searchable: false,
               editable: {
                  validator: {
                     validationFunction: customValidation
                  }
               }
            }
         ];
      }

      function getString(key, params) {
         return i18nService.getString('HostUi', key, params);
      }

      function updateAdvancedSystemSettings(hostId, settings) {
         var optionValues = _.values(settings);
         var hostAdvancedSettingsSpec = {
            hostSettingsChangeset: optionValues
         };
         var operationName = getString('advancedSettings.edit.taskName');

         mutationService.apply(hostId,
               'com.vmware.vsphere.client.host.config.HostAdvancedSettingsSpec',
               hostAdvancedSettingsSpec,
               operationName);
      }

      function loadAdvancedSystemSettings(hostId, gridOptions) {
         resourceService.getResourceInfo(hostId, 'advancedSettings').then(function(hostProperties) {
            gridOptions.data = hostProperties.properties;
         });
      }

      function createOptionValue(event) {
         var optionValue = {
            _type: 'com.vmware.vim.binding.vim.option.OptionValue',
            key: event.model.key,
            value: [getType(event.model.type), event.values.value]
         };

         return optionValue;
      }

      function getType(type) {
         var result;
         switch (type) {
            case 'int':
               result = 'java.lang.Integer';
               break;
            case 'long':
               result = 'java.lang.Long';
               break;
            case 'boolean':
               result = 'java.lang.Boolean';
               break;
            default:
               result = 'java.lang.String';
               break;
         }
         return result;
      }

      function getOptionValueSpec(staticValueSpace) {
         var toReturn = {
            min: null,
            max: null,
            enumeration: []
         };
         _.each(staticValueSpace, function(valueSpace) {
            if (valueSpace.type === "MININCLUSIVE") {
               toReturn.min = valueSpace.value;
            }
            if (valueSpace.type === "MAXINCLUSIVE") {
               toReturn.max = valueSpace.value;
            }
            if (valueSpace.type === "ENUMERATION") {
               toReturn.enumeration.push(valueSpace.value);
            }
         });
         return toReturn;
      }

      function getSelectedSettingData(selectedItem) {
         var ALLOWED_BOOLEAN_VALUES = "true, false";
         var displaySettingsData = {
            allowedMin: null,
            allowedMax: null,
            allowedValues: null,
            description: null
         };

         var staticValueSpace;
         var description;
         var type;
         if (!_.isEmpty(selectedItem)) {
            staticValueSpace = selectedItem[0].staticValueSpace;
            description = selectedItem[0].description;
            type = selectedItem[0].type;
         }
         var staticValue = getOptionValueSpec(staticValueSpace);

         if (type === "boolean") {
            displaySettingsData.allowedValues = ALLOWED_BOOLEAN_VALUES;
         } else {
            displaySettingsData.allowedValues = staticValue.enumeration.filter(
                  function(e) {
                     return !!e.trim();
                  }).join(", ");
         }

         displaySettingsData.allowedMin = staticValue.min;
         displaySettingsData.allowedMax = staticValue.max;
         displaySettingsData.description = description;

         return displaySettingsData;
      }

      function getValidationResult(validationRule, inputData) {
         var validationResult = {
            status: true,
            message: null
         };

         if (!validationRule) {
            return validationResult;
         }

         validationResult = validateByType(inputData, validationRule.type);
         if (validationResult.status) {
            validationResult = validateByStaticValueSpace(inputData,
                  validationRule.staticValueSpace);
         }
         return validationResult;
      }

      function validateByType(inputData, type) {
         var validationResult = {
            status: false,
            message: getString('advancedSettings.invalidInput')
         };

         switch (type) {
            case 'int':
            case 'long':
               if (isInt(inputData)) {
                  validationResult.status = true;
                  validationResult.message = null;
               }
               break;
            case 'boolean':
               if (inputData === 'false' ||
                     inputData === 'true') {
                  validationResult.status = true;
                  validationResult.message = null;
               }
               break;
            default:
               validationResult.status = true;
               validationResult.message = null;
               break;
         }
         return validationResult;
      }

      function isInt(value) {
         var integerValue = Number.parseInt(value);
         if (integerValue <= Number.MIN_SAFE_INTEGER ||
               integerValue >= Number.MAX_SAFE_INTEGER) {
            return true;
         }
         return value !== 'NaN' && integerValue.toString() === value;
      }

      function validateByStaticValueSpace(inputData, validationRules) {
         var validResult = {
            status: true,
            message: null
         };

         if (!validationRules) {
            return validResult;
         }

         if (validationRules.min !== null &&
               validationRules.min > Number.MIN_SAFE_INTEGER &&
               validationRules.min > inputData) {
            validResult.status = false;
            validResult.message = getString('advancedSettings.numberInput.minError',
                  validationRules.min);
         }
         if (validationRules.max !== null &&
               validationRules.max < Number.MAX_SAFE_INTEGER &&
               validationRules.max < inputData) {
            validResult.status = false;
            validResult.message = getString('advancedSettings.numberInput.maxError',
                  validationRules.max);
         }
         if (validationRules.enumeration.length > 0 &&
               validationRules.enumeration.indexOf(inputData) < 0) {
            validResult.status = false;
            validResult.message = getString('advancedSettings.invalidInput');
         }
         return validResult;
      }

      return {
         getGridOptions: getGridOptions,
         updateAdvancedSystemSettings: updateAdvancedSystemSettings,
         loadAdvancedSystemSettings: loadAdvancedSystemSettings,
         createOptionValue: createOptionValue,
         getOptionValueSpec: getOptionValueSpec,
         getSelectedSettingData: getSelectedSettingData,
         getValidationResult: getValidationResult
      };
   }
})();
