/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace network_ui {

   import IRootScopeService = angular.IRootScopeService;
   import VmMigrateWizardModel = network_ui.VmMigrateWizardModel;
   import VmMigrateWizardFlags = network_ui.VmMigrateWizardFlags;

   export class VmMigrateWizardService {

      public static $inject = [
         "$rootScope",
         "i18nService",
         "vuiWizardService",
         "pageStateManagementService",
         "mutationService",
         "vmMigrateWizardConstants",
         "vmMigrateWizardPages",
         "dataService",
         "defaultUriSchemeUtil",
         "wizardValidationService",
         "vmMigrateWizardSpecFactory"
      ];

      constructor(private $rootScope: IRootScopeService,
                  private i18nService: any,
                  private vuiWizardService: any,
                  private pageStateManagementService: any,
                  private mutationService: any,
                  private wizardConstants: any,
                  private vmMigrateWizardPages: VmMigrateWizardPages,
                  private dataService: any,
                  private defaultUriSchemeUtil: any,
                  private wizardValidationService: any,
                  private vmMigrateWizardSpecFactory: VmMigrateWizardSpecFactory) {
      }

      /**
       * Shows the Migrate VMs to Another Network... wizard.
       */
      public show(contextObjId: string): void {

         let wizardScope: any = this.$rootScope.$new();

         wizardScope.model = this.initWizardModel(contextObjId);
         wizardScope.flags = this.initWizardFlags();

         wizardScope.wizardConfig = {
            title: this.i18nService.getString("NetworkUi", "VmMigrateWizard.title"),
            loadingMessage: this.i18nService.getString("CommonUi", "wizard.loading"),
            cssClass: "migrate-vm-networking-wizard",
            onFinish: (): boolean => {
               let spec: any = this.vmMigrateWizardSpecFactory.buildVmMigrateSpec(
                    wizardScope.model);
               this.mutationService.apply(
                    wizardScope.model.contextObjId,
                    this.wizardConstants.specs.VM_MIGRATE_NETWORKING_SPEC,
                    spec);

               return true;
            }
         };

         wizardScope.wizardConfig.pages =
               this.vmMigrateWizardPages.createPages(wizardScope);
         this.wizardValidationService.initWizardValidation(wizardScope);
         this.pageStateManagementService
               .registerWizardForDefaultStateManagementStrategy(wizardScope);

         let contextObjRef: ManagedObjectReference =
               this.defaultUriSchemeUtil.getManagedObjectReference(contextObjId);

         if (contextObjRef &&
               contextObjRef.type !== this.wizardConstants.objectType.DATACENTER_TYPE) {
            wizardScope.flags.isSourceNetworkPreselected = true;

            this.dataService.getProperties(
                  contextObjId, [
                     this.wizardConstants.properties.DATACENTER_PROPERTY])
                  .then((response: any): void => {

                     let datacenterRef: ManagedObjectReference =
                           response[this.wizardConstants.properties.DATACENTER_PROPERTY];

                     if (datacenterRef) {
                        wizardScope.model.datacenterId =
                              this.defaultUriSchemeUtil.getVsphereObjectId(datacenterRef);
                     }

                     this.invokeWizard(wizardScope);
                  });
         } else {
            wizardScope.model.datacenterId = contextObjId;

            this.invokeWizard(wizardScope);
         }
      }

      private invokeWizard(wizardScope: VmMigrateWizardScope): void {
         this.vuiWizardService({
            scope: wizardScope,
            configObjectName: "wizardConfig"
         }).show();
      }

      private initWizardModel(contextObjId: string): VmMigrateWizardModel {
         let model: VmMigrateWizardModel = new VmMigrateWizardModel();
         model.contextObjId = contextObjId;
         return model;
      }

      private initWizardFlags(): VmMigrateWizardFlags {
         let flags: VmMigrateWizardFlags = new VmMigrateWizardFlags();
         flags.isSelectVmsPageInitialized = false;
         flags.isSelectNetworksPageInitialized = false;
         flags.inaccessibilityWarningShown = false;
         flags.noSourceNetworkSelected = false;
         flags.isSourceNetworkPreselected = false;
         return flags;
      }
   }

   angular.module("com.vmware.vsphere.client.network")
         .service("vmMigrateWizardService", VmMigrateWizardService);
}
