/* Copyright 2018 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace dvs_ui {

   import VmVnicAdapterDetails = com.vmware.vsphere.client.dvs.api.switchdiagram.model.details.VmVnicAdapterDetails;

   export class VmVnicDetailsDialogContent {

      public bindings: any;
      public controller: any;
      public template: string;

      constructor() {
         this.bindings = {
            data: "<"
         };

         this.controller = VmVnicDetailsDialogContentController;

         this.template = `<vx-property-view class="network-ui-fixed-size-vx-property-view vm-vnic-details-dialog-content"
                                            data="$ctrl.propertyViewData"/>`;
      }
   }

   class VmVnicDetailsDialogContentController {

      static $inject = [
         "vxPropertyViewService",
         "i18nService",
         "numberFormatterService"
      ];

      data: VmVnicAdapterDetails;

      propertyViewData: any;

      constructor(private propertyViewService: any, private i18nService: any,
                  private numberFormatterService: any) {}

      $onInit(): void {
         this.propertyViewData = this.buildPropertyViewData();
      }

      private buildPropertyViewData(): any {
         const builder: any = this.propertyViewService.createPropertyViewBuilder();

         const categoryBuilder: any = builder.category("");

         if (!!this.data.dvsPortData) {
            const networkConnection: any = categoryBuilder
                  .section("networkConnection")
                  .title(this.getString("switchdiagram.nic.details.networkConnection"))
                  .property(
                        this.getString("switchdiagram.nic.details.portId"),
                        this.data.dvsPortData.portId);

            const propertyBuilder: any = networkConnection.propertyBuilder();

            const stateIconAndLabel: { icon: string, label: string }
                  = this.getStateIconAndLabel(this.data.dvsPortData.state);
            propertyBuilder
                  .keyBuilder()
                  .text(this.getString("switchdiagram.nic.details.state"));
            propertyBuilder
                  .valueBuilder()
                  .icon(stateIconAndLabel.icon)
                  .text(stateIconAndLabel.label);

            if (!!this.data.dvsPortData.mac) {
               networkConnection.property(
                     this.getString("switchdiagram.nic.details.macRuntime"),
                     this.data.dvsPortData.mac);
            }
         }

         const adapterBuilder: any = categoryBuilder
               .section("adapter")
               .title(this.data.vmVnicModel.adapterName)
                     .property(
                           this.getString("switchdiagram.nic.details.mac"),
                           this.data.vmVnicModel.macAddress)
                     .property(
                           this.getString("switchdiagram.nic.details.adapterType"),
                           this.data.vmVnicModel.adapterType);

         if (this.data.vmVnicModel.resourceAllocation) {
            adapterBuilder
                  .property(
                        this.getString("switchdiagram.nic.details.shares"),
                        this.i18nService.getString("DvsUi", "switchdiagram.nic.details.sharesValueFormat",
                              this.data.vmVnicModel.resourceAllocation.sharesValue,
                              this.getSharesLevelLabel(this.data.vmVnicModel.resourceAllocation.sharesLevel)))
                  .property(
                        this.getString("switchdiagram.nic.details.reservation"),
                        this.numberFormatterService.formatBandwidth(
                              this.data.vmVnicModel.resourceAllocation.reservation,
                              BandwidthUnit.MBITPS,
                              BandwidthUnit.AUTO));
            const limitValue: string = this.data.vmVnicModel.resourceAllocation.limit > -1
                  ? this.numberFormatterService.formatBandwidth(
                        this.data.vmVnicModel.resourceAllocation.limit,
                        BandwidthUnit.MBITPS,
                        BandwidthUnit.AUTO)
                  : this.getString("switchdiagram.nic.details.unlimited");

            adapterBuilder.property(
                  this.getString("switchdiagram.nic.details.limit"),
                  limitValue);
         }

         if (this.data.vmVnicModel.sriovCapable) {
            categoryBuilder
                  .section("sriov")
                  .title(this.getString("switchdiagram.nic.details.sriov"))
                  .property(
                        this.getString("switchdiagram.nic.details.sriovStatus"),
                        this.getSriovStatusLabel(this.data.vmVnicModel.sriovCapable))
                  .property(
                        this.getString("switchdiagram.nic.details.sriov.virtualFunction"),
                        this.data.vmVnicModel.virtualFunctionId)
                  .property(
                        this.getString("switchdiagram.nic.details.sriov.physicalFunction"),
                        this.i18nService.getString(
                              "DvsUi",
                              "switchdiagram.nic.details.sriov.physicalFunctionFormat",
                              this.data.vmVnicModel.physicalFunctionId,
                              this.data.vmVnicModel.physicalFunctionDeviceId))
                  .property(
                        this.getString("switchdiagram.nic.details.gosMtu"),
                        this.getGuestOsMtuChangesLabel(this.data.vmVnicModel.guestOsMtuChange));
         }

         return builder.build();
      }

      private getStateIconAndLabel(portState: string): { icon: string, label: string } {
         let icon: string = "";
         let label: string = this.getString("switchdiagram.noData");

         if (portState === "LINK_UP") {
            icon = "vsphere-icon-port_green";
            label = this.getString("PortState.linkUp");
         } else if (portState === "LINK_DOWN") {
            icon = "vsphere-icon-port_white";
            label = this.getString("PortState.linkDown");
         } else if (portState === "BLOCKED") {
            icon = "vsphere-icon-port_blocked";
            label = this.getString("PortState.blocked");
         }

         return { icon: icon, label: label };
      }

      private getSriovStatusLabel(sriovCapable: boolean): string {
         return this.getString(
               sriovCapable
                     ? "switchdiagram.nic.details.sriov.enabled"
                     : "switchdiagram.nic.details.sriov.notSupported");
      }

      private getGuestOsMtuChangesLabel(allowed: boolean): string {
         return this.getString(
               allowed
                     ? "switchdiagram.nic.details.gosMtu.allowed"
                     : "switchdiagram.nic.details.gosMtu.disallowed");
      }

      private getSharesLevelLabel(value: string): string {
         switch (value) {
            case "low": {
               return this.getString("switchdiagram.nic.details.sharesLevel.low");
            }
            case "normal": {
               return this.getString("switchdiagram.nic.details.sharesLevel.normal");
            }
            case "high": {
               return this.getString("switchdiagram.nic.details.sharesLevel.high");
            }
            case "custom": {
               return this.getString("switchdiagram.nic.details.sharesLevel.custom");
            }
            default: {
               return "";
            }
         }
      }

      private getString(key: string): string {
         return this.i18nService.getString("DvsUi", key);
      }
   }

   enum BandwidthUnit { BITPS, KBITPS, MBITPS, GBITPS, TBITPS, AUTO }

   angular.module("com.vmware.vsphere.client.dvs")
         .component("vmVnicDetailsDialogContent",
               new VmVnicDetailsDialogContent());
}
