/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace h5_vm {
   export class CreateVmChangeCreationTypeService {
      public static $inject = [
            "i18nService",
            "vuiConstants",
            "wizardPageService",
            "creationTypeConstants"
      ];

      constructor(
            private i18nService: any,
            private vuiConstants: any,
            private wizardPageService: any,
            private creationTypeConstants: any
      ) {}

      public changeCreationType(
            wizardScope: any,
            selectedCreationType: {creationType: string, wizardTitle: string},
            previouslySelectedType?: {creationType: string, wizardTitle: string} ) {
         // If not specified extract the previously type from wizard state.
         let oldCreationType: string = previouslySelectedType ?
               previouslySelectedType.creationType :
               wizardScope.vmParams.getCreationType();
         if (wizardScope.config.currentPage.decisionOptions) {
            wizardScope.config.currentPage.decisionOptions.selectedFlowId = selectedCreationType.creationType;
         }
         this.setSkippedPages(wizardScope, selectedCreationType.creationType);

         if (selectedCreationType.creationType !== oldCreationType) {
            wizardScope.attributes.creationType = selectedCreationType.creationType;
            wizardScope.vmParams.setCreationType(selectedCreationType.creationType);
            wizardScope.config.title = selectedCreationType.wizardTitle;

            //set recreateVm to true so that when the creation type is changed
            //and the customize hardware page is visited again the configuration in vmParams
            //will be loaded with the new data
            wizardScope.vmParams.setRecreateVm(true);
            //set customize hardware flag to false, so that when the page has been visited
            //and then a flow is selected, that does not use the customize hardware page,
            //the information set in it will not be used
            wizardScope.vmParams.setCustomizeCloneHardware(false);

            this.wizardPageService.initializeFlowStates(wizardScope.config.flows);
         }
      }

      /**
       * If the flow is `Clone vm to vm` or `Clone template to vm`
       * by default the `Customize hardware` page and `Customize Guest OS` page are skipped
       * If they have been previously enabled on the `Clone options` page (visible only in those two flows)
       * then those pages are marked as disabled
       * In all other flows those pages are disabled
       * @param flows
       * @param creationType
       */
      private setSkippedPages(
            wizardScope: any, creationType: string) {
         let flows: [{id: string, pages: any}] = wizardScope.config.flows;
         //is the flow `Clone vm to vm` or `Clone template to vm`
         var isCreateVmFlow = (creationType === this.creationTypeConstants.CLONE_VM_TO_VM
               || creationType === this.creationTypeConstants.DEPLOY_VM_FROM_VAPP
               || creationType === this.creationTypeConstants.DEPLOY_VM_FROM_TEMPLATE
               || creationType === this.creationTypeConstants.CLONE_TEMPLATE_TO_VM);
         //find the selected flow
         var selectedFlow = _.find(flows, (flow: any) => flow.id === creationType);
         if(selectedFlow) {
            //retrieve the options set in the `Clone options` page
            var cloneOptions = wizardScope.vmParams.getCloneOptions() || {};

            var customizeHardwarePage = _.find(selectedFlow.pages, (page: any) => {
               return page.title ===
                     this.i18nService.getString("VmUi", "CustomizeHardwareProvisioningPage.Title");
            });

            if(customizeHardwarePage) {
               //set `Customize hardware` page as disabled for all flows
               customizeHardwarePage.defaultState = this.vuiConstants.wizard.pageState.DISABLED;
               //if the flow is `Clone vm to vm` or `Clone template to vm`
               //and customize hardware page has not been previou\sly enabled on the `Clone options` page
               //skip the page
               if(isCreateVmFlow && !wizardScope.vmParams.getCustomizeCloneHardware()) {
                  customizeHardwarePage.defaultState = this.vuiConstants.wizard.pageState.SKIPPED;
               }
            }

            var customizeGuestOsPage = _.find(selectedFlow.pages, (page: any) => {
               return page.title ===
                     this.i18nService.getString("VmUi", "CustomizeGuestOsProvisioningPage.Title");
            });

            if(customizeGuestOsPage) {
               //set `Customize Guest OS` page as disabled for all flows
               customizeGuestOsPage.defaultState = this.vuiConstants.wizard.pageState.DISABLED;
               //if the flow is `Clone vm to vm` or `Clone template to vm`
               //and customize guest os page page has not been previously enabled on the `Clone options` page
               //skip the page
               if(isCreateVmFlow && !cloneOptions.customizeOs) {
                  customizeGuestOsPage.defaultState = this.vuiConstants.wizard.pageState.SKIPPED;
               }
            }
         }
      }
   }

   angular.module("com.vmware.vsphere.client.vm")
         .service("createVmChangeCreationTypeService",
               CreateVmChangeCreationTypeService);
}
