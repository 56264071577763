/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace h5_vm {
   import VmEvcSpec = com.vmware.vsphere.client.h5.vm.data.VmEvcSpec;
   import FeatureMask = com.vmware.vim.binding.vim.host.FeatureMask;
   export class VmEvcService {
      public static $inject = ["dataService", "mutationService"];
      private EVC_DATA: string = "vmEvcData";
      private EDIT_EVC_DATA: string = "vmEditEvcData";

      constructor(private dataService: any,
            private mutationService: any) { }

      loadEvcData(vmId: string): any {
         return this.dataService.getProperties(vmId, [this.EVC_DATA])
               .then((result: any) => {
                  return result[this.EVC_DATA];
               });
      }

      loadEditEvcData(vmId: string): any {
         return this.dataService.getProperties(vmId, [this.EDIT_EVC_DATA])
               .then((result: any) => {
                  return result[this.EDIT_EVC_DATA];
               });
      }

      applyEvcMode(vmId: string, featureMasks: FeatureMask[]): angular.IPromise<any> {
         let spec = new VmEvcSpec();
         spec.featureMasks = featureMasks;
         return this.mutationService.apply(vmId, spec._type, spec);
      }
   }

   angular.module("com.vmware.vsphere.client.vm")
         .service("vmEvcService", VmEvcService);
}