(function() {
   'use strict';
   angular.module('com.vmware.vsphere.client.vm').service('vmProvisioningFinishCreateVmPageService', [
      '$q',
      'i18nService',
      'addVmService',
      'mutationService',
      'wizardPageService',
      'defaultUriSchemeUtil',
      'vmVuiWizardPageBuilder',
      'vmProvisioningFinishCreateVmPageModel',
      function(
         $q,
         i18nService,
         addVmService,
         mutationService,
         wizardPageService,
         defaultUriSchemeUtil,
         vmVuiWizardPageBuilder,
         vmProvisioningFinishCreateVmPageModel
      ) {
         return {
            build: function(wizardScope) {

               var pageModel = new vmProvisioningFinishCreateVmPageModel(
                     wizardScope.vmParams,
                     wizardScope.wizardViewData,
                     wizardScope.scheduleTaskData);

               wizardScope.vmProvisioningFinishCreateVmPageModel = pageModel;

               return vmVuiWizardPageBuilder.buildVuiWizardFinishPage(wizardScope.config, {
                  title: i18nService.getString('VmUi', 'ProvisioningWizardSummaryPage.Title'),
                  description: i18nService.getString('VmUi', 'ProvisioningWizardSummaryPage.Description'),
                  contentUrl: 'vm-ui/resources/vm/views/createVmWizard/vmProvisioningFinish.html',
                  model: pageModel
               });
            }
         };
      }
   ]);
})();
