namespace h5_vm {

   import IFormController = angular.IFormController;
   import INgModelController = angular.INgModelController;

   class VmHardwareVideocardController implements ng.IComponentController {

      inflatedDevice: VirtualVideoCardClass;
      private vmHardwareVideoCardForm: IFormController;
      static $inject: string[] = ["i18nService"];

      constructor(private i18nService: any) {
         return;
      }

      i18n2(key: string, section: string = "VideoCardPage"): string {
         const retVal = this.i18nService.getString("VmUi", section + "." + key);
         return retVal;
      }

      ARIA_LBL_VIDEO: string = this.i18n2('Title');
      ARIA_LBL_NUM_DISPLAYS: string = this.i18n2('NumberOfDisplays');
      ARIA_LBL_VRAM: string = this.i18n2('TotalVideoMemory');
      ARIA_LBL_3D: string = this.i18n2('3DGraphics');
      ARIA_LBL_3DRNDR: string = this.i18n2('3DRenderer');
      ARIA_LBL_3DRAM: string = this.i18n2('3DMemory');

      public i18n(key: string): string {
         if (this.inflatedDevice) {
            return this.inflatedDevice.i18n(key);
         }
         return "";
      }

      private isReady(): boolean {
         if (!this.inflatedDevice) {
            return false;
         }
         return true;
      }

      public hasChanged(): boolean {
         if (this.isReady()) {
            return this.inflatedDevice.hasChanged();
         } else {
            return false;
         }
      }

      $postLink(): void {
         this.videoRamSizeInKB_Validatate();
         this.graphicsMemorySizeInMB_Validate();
      }

      hasError(): boolean {
         if (!_.isEmpty(this.vmHardwareVideoCardForm)) {
            return this.vmHardwareVideoCardForm.$invalid;
         }
         return false;
      };

      public expanded: boolean = false;

      // PART 0 useAutoDetect

      public useAutoDetect_List(): Array<any> {
         if (this.isReady()) {
            return this.inflatedDevice.useAutoDetect_List();
         }
         return [];
      }

      public useAutoDetect_Text(value: boolean): string {
         if (this.isReady()) {
            return this.inflatedDevice.useAutoDetect_Text(value);
         }
         return "";
      }

      public get useAutoDetect(): boolean {
         if (this.isReady()) {
            return this.inflatedDevice.useAutoDetect;
         }
         return false;
      }

      public set useAutoDetect(value: boolean) {
         if (this.isReady()) {
            this.inflatedDevice.useAutoDetect = value;
            this.expandIfCustomSettings();
         }
      }

      private expandIfCustomSettings(): void {
         if (!this.isReady()) {
            return;
         }
         if (this.inflatedDevice.useAutoDetect) {
            return;
         }
         this.expanded = true;
      }

      public useAutoDetect_isEditDisabled(): boolean {
         if (this.isReady()) {
            return this.inflatedDevice.useAutoDetect_isEditDisabled();
         } else {
            return true;
         }
      }

      // PART 1 videoRamSizeInKB

      public videoRamSizeInKB_isEditDisabled(): boolean {
         if (this.isReady()) {
            return this.inflatedDevice.videoRamSizeInKB_isEditDisabled();
         }
         return true;
      }

      public videoRamSizeInKB_isInvalid(): boolean {
         if (this.isReady()) {
            return this.inflatedDevice.videoRamSizeInKB_isInvalid();
         } else {
            return false;
         }
      }

      public videoRamSizeInKB_getError(): string {
         if (this.isReady()) {
            return this.inflatedDevice.videoRamSizeInKB_getError();
         } else {
            return "";
         }
      }

      public get videoRamSizeInKB(): string {
         if (this.isReady()) {
            return this.inflatedDevice.videoRamSizeInKB;
         } else {
            return "";
         }
      }

      public set videoRamSizeInKB(value: string) {
         if (this.isReady()) {
            this.inflatedDevice.videoRamSizeInKB = value;
            this.videoRamSizeInKB_Validatate();
         }
      }

      public videoRamSizeInKB_Validatate(): void {
         if (this.isReady()) {
            let invalid: boolean = this.inflatedDevice.videoRamSizeInKB_isInvalid();
            this.setValidityByName('videoRamSizeInKB', invalid);
         }
      }

      private setValidityByName(name: string, invalid: boolean) {
         if (!this.isReady()) {
            return;
         }

         let ngmc: INgModelController = this.vmHardwareVideoCardForm[name];

         if (ngmc) {
            let key: string = name + "_value";
            let valid: boolean = !invalid;
            ngmc.$setValidity(key, valid);
         }
      }

      // PART 2 numberOfDisplays

      public numberOfDisplays_List(): Array<any> {
         if (this.isReady()) {
            return this.inflatedDevice.numDisplays_getList();
         }
         return [];
      }

      public get numberOfDisplays(): number {
         if (this.isReady()) {
            return this.inflatedDevice.numDisplays;
         }
         return 1;
      }

      public set numberOfDisplays(value: number) {
         if (this.isReady()) {
            this.inflatedDevice.numDisplays = value;
         }
      }

      public numberOfDisplays_isEditDisabled(): boolean {
         if (this.isReady()) {
            return this.inflatedDevice.numDisplays_isEditDisabled();
         }
         return true;
      }

      // PART 3 enable3DSupport

      public get enable3DSupport(): boolean {
         if (this.isReady()) {
            return this.inflatedDevice.enable3DSupport;
         } else {
            return false;
         }
      }

      public set enable3DSupport(value: boolean) {
         if (this.isReady()) {
            this.inflatedDevice.enable3DSupport = value;
         }
      }

      public enable3DSupport_isEditDisabled(): boolean {
         if (this.isReady()) {
            return this.inflatedDevice.enable3DSupport_isEditDisabled();
         }
         return true;
      }

      public enable3DSupport_isVisible(): boolean {
         if (this.isReady()) {
            return this.inflatedDevice.enable3DSupport_isVisible();
         } else {
            return true;
         }
      }

      // PART 4 use3dRenderer

      public use3dRenderer_isVisible(): boolean {
         if (this.isReady()) {
            return this.inflatedDevice.use3dRenderer_isVisible();
         } else {
            return true;
         }
      }

      public get use3dRenderer(): string {
         if (this.isReady()) {
            return this.inflatedDevice.use3dRenderer;
         }
         return "";
      }

      public set use3dRenderer(value: string) {
         if (this.isReady()) {
            this.inflatedDevice.use3dRenderer = value;
         }
      }

      public use3dRenderer_List(): Array<any> {
         if (this.isReady()) {
            return this.inflatedDevice.use3dRenderer_List();
         }
         return [];
      }

      public use3dRenderer_Text(value: string): string {
         if (this.isReady()) {
            return this.inflatedDevice.use3dRenderer_Text(value);
         }
         return "";
      }

      public use3dRenderer_isEditDisabled(): boolean {
         if (this.isReady()) {
            return this.inflatedDevice.use3dRenderer_isEditDisabled();
         }
         return true;
      }

      // PART 5 graphicsMemorySizeInKB

      public get graphicsMemorySizeInMB(): string {
         if (this.isReady()) {
            return this.inflatedDevice.graphicsMemorySizeInMB;
         }
         return "";
      }

      public set graphicsMemorySizeInMB(text: string) {
         if (this.isReady()) {
            this.inflatedDevice.graphicsMemorySizeInMB = text;
            this.graphicsMemorySizeInMB_Validate();
         }
      }

      public graphicsMemorySizeInMB_Validate(): void {

         if (this.isReady()) {
            let invalid: boolean = this.inflatedDevice.graphicsMemorySizeInMB_isInvalid();
            this.setValidityByName('graphicsMemorySizeInMB', invalid);
         }
      }

      public graphicsMemorySizeInMB_isEditDisabled(): boolean {
         if (this.isReady()) {
            return this.inflatedDevice.graphicsMemorySizeInMB_isEditDisabled();
         } else {
            return true;
         }
      }

      public graphicsMemorySizeInMB_isVisible(): boolean {

         if (!this.isReady()) {
            return false;
         }

         return this.inflatedDevice.graphicsMemorySizeInMB_isVisible();
      }

      public graphicsMemorySizeInMB_isInvalid(): boolean {
         if (this.isReady()) {
            return this.inflatedDevice.graphicsMemorySizeInMB_isInvalid();
         } else {
            return false;
         }
      }

      public graphicsMemorySizeInMB_getError(): string {
         if (this.isReady()) {
            return this.inflatedDevice.graphicsMemorySizeInMB_getError();
         } else {
            return "";
         }
      }

      public getAutoIncreaseText(): string {
         if (this.isReady()) {
            return this.inflatedDevice.getAutoIncreaseText();
         } else {
            return "";
         }
      }

      public getAutoIncreaseVisible(): boolean {
         if (this.isReady()) {
            return this.inflatedDevice.getAutoIncreaseVisible();
         } else {
            return false;
         }
      }
   }

   class VmHardwareVideocardComponent implements ng.IComponentOptions {

      constructor(public bindings: any = {inflatedDevice: '<'},
                  public controller: any = VmHardwareVideocardController,
                  public templateUrl: string = 'vm-ui/resources/vm/views/settings/vmHardwareSettings/vmHardwareVideoCard/vm-hw-video-card.html') {
      }
   }

   angular.module('com.vmware.vsphere.client.vm').component('vmHardwareVideoCard', new VmHardwareVideocardComponent());
}
