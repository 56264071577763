namespace h5_vm {
   export class IopsLimitDetails {
      iopsLimitSelection: any;
      iopsLimitSelectList: IopsLimitSelect[];
      iopsRecommendations: IopsRecommendation[];
      min: number;
      max: number;
      errorMessage: any;

      public readonly IOPSLIMIT_MIN: number = 16;
      public readonly IOPSLIMIT_MAX: number = (Math.pow(2, 31) - 1);
      public readonly IOPS_UNLIMITED_SELECTION: string = "unlimited";
      public readonly IOPS_CUSTOM_SELECTION: string = "custom";

      constructor(private i18nService: any, private unlimited: string, private iopsLimit: string | number) {
         const i18n = i18nService.getString;

         this.min = this.IOPSLIMIT_MIN;
         this.max = this.IOPSLIMIT_MAX;
         this.iopsRecommendations = [
            {
               name: i18n('VmUi', 'VmCpu.Limit.Minimum'),
               value: this.IOPSLIMIT_MIN
            },
            {
               name: i18n('VmUi', 'VmCpu.Limit.Maximum'),
               value: this.IOPSLIMIT_MAX
            }
         ];
         this.iopsLimitSelectList = [
            {
               label: i18n('VmUi', 'DiskConfig.Unlimited'),
               data: 'unlimited'
            },
            {
               label: i18n('VmUi', 'sharesInfoLevel.custom'),
               data: 'custom'
            }
         ];

         this.errorMessage = {
            max: () => i18n('Common', 'SharesControl.RangeError', this.IOPSLIMIT_MIN, this.IOPSLIMIT_MAX),
            min: () => i18n('Common', 'SharesControl.RangeError', this.IOPSLIMIT_MIN, this.IOPSLIMIT_MAX)
         };

         this.iopsLimitSelection =
               (iopsLimit === unlimited) ?
                     this.iopsLimitSelectList[0] :
                     this.iopsLimitSelectList[1];
      }
   }

   export class IopsRecommendation {
      name: string;
      value: number;
   }

   export class IopsLimitSelect {
      label: string;
      data: string;
   }

   class VmHardwareVirtualDiskIopsController implements ng.IComponentController {
      static $inject: string[] = [
         'i18nService',
         'VirtualDisk'
      ];

      private i18n: Function;
      private iopsLimit: string | number;
      private onChange: Function;
      private iopsLimitDetails: IopsLimitDetails;
      private isDisabled: boolean;

      constructor(private i18nService: any, private VirtualDisk: any) {
         this.i18n = i18nService.getString;
      }

      onIopsLimitSelectionChange() {
         if (this.iopsLimitDetails.iopsLimitSelection.data === this.iopsLimitDetails.IOPS_UNLIMITED_SELECTION) {
            this.iopsLimit = this.VirtualDisk.UNLIMITED_DISK;
         } else {
            this.iopsLimit = this.iopsLimitDetails.IOPSLIMIT_MIN;
         }
         this.onChange({iopsLimit: this.iopsLimit});
      }

      onIopsLimitValueChange() {
         this.onChange({iopsLimit: this.iopsLimit});
      }

      isCustom() {
         if(this.iopsLimitDetails) {
            return this.iopsLimitDetails.iopsLimitSelection.data === this.iopsLimitDetails.IOPS_CUSTOM_SELECTION;
         } else {
            return false;
         }
      }

      $onInit(){
         if(this.iopsLimit){
            this.iopsLimitDetails = new IopsLimitDetails(this.i18nService, this.VirtualDisk.UNLIMITED_DISK, this.iopsLimit);
         }
      }

      $onChanges(changes: any) {
         if (changes.iopsLimit.currentValue) {
            this.iopsLimitDetails = new IopsLimitDetails(this.i18nService, this.VirtualDisk.UNLIMITED_DISK, this.iopsLimit);
         }
      }
   }

   export class VmHardwareVirtualDiskIops implements ng.IComponentOptions {

      bindings: any;
      controller: any;
      templateUrl: string;

      constructor() {
         this.bindings = {
            iopsLimit: '<',
            isDisabled: '<',
            ariaLabel: '<',
            onChange: '&'
         };
         this.controller = VmHardwareVirtualDiskIopsController;
         this.templateUrl = 'vm-ui/resources/vm/views/settings/vmHardwareSettings/vmHardwareVirtualDiskShared/' +
               'vmHardwareVirtualDiskIops/vm-hardware-virtual-disk-iops.html';
      }


   }

   angular.module('com.vmware.vsphere.client.vm')
         .component('vmHardwareVirtualDiskIops', new VmHardwareVirtualDiskIops());
}
