/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
/*
 * Service for Assign License page
 */
(function() {
   'use strict';
   angular.module('com.vmware.vsphere.client.host').service(
         'addHostAssignLicensePageService',
         addHostAssignLicensePageService
   );

   addHostAssignLicensePageService.$inject = [
      'i18nService',
      'licenseValidationUtil',
      '$q',
      'defaultUriSchemeUtil'
   ];

   function addHostAssignLicensePageService(i18nService, licenseValidationUtil, $q, defaultUriSchemeUtil) {

      return {
         build: function(hostAdder, vuiWizardHelper) {
            var destinationRef = { 
               rootFolder: defaultUriSchemeUtil.getRootFolderFromVsphereObjectId(hostAdder.getDestinationUid()) 
            };
            hostAdder.prepareLicenseValidation(destinationRef);

            return {
               title: i18nService.getString('HostUi', 'addHostWizard.assignLicensePage.title'),
               description: i18nService.getString('HostUi', 'addHostWizard.assignLicensePage.description'),
               contentUrl: 'host-ui/resources/host/views/addHostWizard/pages/addHostAssignLicensePage.html',
               onCommit: function() {
                  if (!hostAdder.getLastAssetAssignLicenseValidationResult()) {
                     return false;
                  }

                  var errors = licenseValidationUtil.getValidationBlockingErrors(
                        hostAdder.getAssignLicensePageModel().userSelectedLicense,
                        hostAdder.getLastAssetAssignLicenseValidationResult(),
                        true
                  );

                  if (!_.isEmpty(errors)) {
                     vuiWizardHelper.markPageIncompleteWithErrors(errors);
                     return false;
                  } else {
                     var warnings = licenseValidationUtil.getValidationWarnings(
                           hostAdder.getAssignLicensePageModel().userSelectedLicense,
                           hostAdder.getLastAssetAssignLicenseValidationResult(),
                           true
                     );

                     if (!_.isEmpty(warnings)) {
                        return licenseValidationUtil.showLicenseWarningsConfirmationDialog(warnings)
                              .then(function (result) {
                                 if (result) {
                                    vuiWizardHelper.markPageComplete();
                                 } else {
                                    vuiWizardHelper.markPageIncompleteWithErrors([]);
                                 }
                                 return result;
                              }, function (reason) {
                                 vuiWizardHelper.markPageIncompleteWithErrors([]);
                                 return $q.reject(reason);
                              });
                     } else {
                        vuiWizardHelper.markPageComplete();
                        return true;
                     }
                  }
               }
            };
         }
      };
   }
})();
