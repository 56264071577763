(function() {
   'use strict';
   angular.module('com.vmware.vsphere.client.commonModule')
      .controller('CustomAttributesPortletController', CustomAttributesPortletController);

   CustomAttributesPortletController.$inject = ['$scope', 'customAttributesService', 'i18nService', 'actionsService'];

   function CustomAttributesPortletController($scope, customAttributesService, i18nService, actionsService) {
      var self = this;
      var objectId = $scope._route.objectId;
      self.objectId = objectId;
      self.liveRefreshProperties = [
         'availableField',
         'customValue'
      ];
      self.datagridOptions = {
         columnDefs: [
            {
               displayName: i18nService.getString('Common','attributesName'),
               field: 'attribute',
               sortable: false,
               searchable: false
            },
            {
               displayName: i18nService.getString('Common','ca.values.value'),
               field: 'value',
               sortable: false,
               searchable: false
            }
         ],
         height: '200px',
         resizable: false,
         reorderable: false
      };

      var context = {
         summaryViewCustomAttributeDatagridOptions: self.datagridOptions
      };

      actionsService.getAction("vsphere.core.actions.editCustomAttributesAction", [objectId])
         .then(function (action) {
            var linkEnabled = (action && action.available) ? true : false;

            $scope.portlet.footerLinks = [{
               label: i18nService.getString('Common','action.editCustomAttributes.label'),
               ariaLabel: i18nService.getString('Common','action.editCustomAttributes.description'),
               onClick: function () {
                  actionsService.invokeAction(action, context);
               },
               enabled: linkEnabled
            }];
         });

      self.refresh = function() {
         customAttributesService.getCustomAttributes(objectId).then(function(customAttributes) {
            self.datagridOptions.data = customAttributes;
         });
      };

      self.refresh();
   }
}());
