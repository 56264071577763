namespace storage_ui {

   import IComponentController = angular.IComponentController;

   export class StorageProvidersMasterViewController implements IComponentController {

      public static $inject = ["i18nService", "$q"];

      public readonly detailsViewData:any;
      public readonly masterDetailsViewContext:any;

      private readonly tabs: any[] = [{
         name: this.i18nService.getString("StorageUi", "storage.storageProviders.generalSettingsView.label"),
         templateUrl: "storage-ui/resources/storage/views/storageProviders/details/StorageProviderItemGeneralDetailsView.html",
         uid: "storage.providers.generalTab"
      }, {
         name: this.i18nService.getString("StorageUi", "storage.storageProviders.supportedVendorIdsView.label"),
         templateUrl: "storage-ui/resources/storage/views/storageProviders/details/StorageProviderSupportedVendorIdsView.html",
         uid: "storage.providers.supportedVendorIdsTab",
         metadata: {
            relevantFor: "_isStorageProviderItem"
         }
      }, {
         name: this.i18nService.getString("StorageUi", "storage.storageProviders.certificateDetailsView.label"),
         templateUrl: "storage-ui/resources/storage/views/storageProviders/details/StorageProviderCertificateDetailsView.html",
         uid: "storage.providers.certificateDetailsTab",
         metadata: {
            relevantFor: "_isStorageProviderItem"
         }
      }];

      constructor(private i18nService: any, private $q: ng.IQService) {
         this.detailsViewData = {
            tabsPromise: this.$q.when(this.tabs)
         };
      }

   }

   angular.module("com.vmware.vsphere.client.storage")
         .controller("StorageProvidersMasterViewController",
               StorageProvidersMasterViewController);
}
