(function() {
   'use strict';
   angular.module('com.vmware.vsphere.client.cluster')
         .controller('EditProactiveHaDialogController', EditProactiveHaDialogController);

   EditProactiveHaDialogController.$inject = ['$scope', 'dataService', 'mutationService',
      'clusterSpecBuilderService', 'vuiConstants', 'i18nService', 'editProactiveHaService',
      '$element', 'clusterConstants'];

   function EditProactiveHaDialogController($scope, dataService, mutationService,
         clusterSpecBuilderService, vuiConstants, i18nService, editProactiveHaService,
         $element, clusterConstants) {

      var self = this;
      var objectId = $scope.modalOptions.dialogData.availableTargets[0];

      $scope.modalOptions.alerts = [];
      $scope.modalOptions.onSubmit = onSubmit;
      $scope.$on('kendoWidgetCreated', function(event, widget) {
         if (!widget || widget.constructor !== kendo.ui.Grid) {
            return;
         }
         var enableProvidersKendoGridSelector =
               ".enable-providers-container [kendo-grid]";
         self.gridWidget = getKendoGrid(enableProvidersKendoGridSelector);
      });

      self.loading = true;
      self.signPostConfig = {
         message: i18nService.getString(
               'ClusterUi', 'ha.config.failures.responses.partial.failure.remediation.dd.help'),
         title: i18nService.getString('Common', 'help')
      };
      self.automationLevels = editProactiveHaService.AUTOMATION_LEVELS;
      self.remediationLevels = editProactiveHaService.REMEDIATION_LEVELS;

      self.getRemediationLevelDesc = function() {
         self.remediationLevelDesc = editProactiveHaService
               .getRemediationLevelDescription(self.remediationLevel.value);
      }.bind(this);

      self.getAutomationLevelDesc = function() {
         self.automationLevelDesc = editProactiveHaService
               .getAutomationLevelDescription(self.automationLevel.value);
      }.bind(this);

      self.datagridOptions = {
         selectionMode: vuiConstants.grid.selectionMode.SINGLE,
         sortMode: vuiConstants.grid.sortMode.NONE,
         searchable: false,
         resizable: true,
         selectedItems: [],
         data: [],
         columnDefs: getColumnDefs(),
         onMasterCheckboxClick: function(event) {
            var enabledState =
                  event.currentTarget.checked;
            _.each(self.clusterProviders, function(item) {
               item.isEnabled = enabledState;
               return self.datagridOptions.data;
            });

            _.each(self.gridWidget._data, function(item) {
               item.isEnabled = enabledState;
               return self.gridWidget._data;
            });

            if (self.gridWidget) {
               self.gridWidget.refresh();
            }
         },
         onProviderCheckboxClick: function(dataItem) {
            var enabledState = !dataItem.isEnabled;

            dataItem.isEnabled = enabledState;
            _.find(self.clusterProviders, function(clusterProvider) {
               return clusterProvider.nameProvider === dataItem.nameProvider;
            }).isEnabled = enabledState;

            if (self.gridWidget) {
               // setOptions recompiles header, i.e. mastercheckbox works fine then,
               // refresh doesn't refresh headers.
               self.gridWidget.setOptions(self.datagridOptions);
            }
         }
      };

      self.failureConditionsSignPostParams =
            editProactiveHaService.failureConditionsVuiSignPost();

      self.datagridOptionsFailureConditions = getFailureConditionsDatagridOptions();

      self.failureConditionsClusterLevelVuiSignPost =
            editProactiveHaService.failureConditionsClusterLevelVuiSignPost();

      self.datagridOptionsFailureConditionsHosts =
            editProactiveHaService.getFailureConditionsHostsDatagridOptions();

      self.proactiveHaBlockAtTypes = clusterConstants.proactiveHaBlockAtTypes;
      self.blockAtOldValue = null;

      self.filterModel = {};
      self.filterHealthUpdateInfo = [];
      self.filterHostInfo = [];

      self.preselectComparator = function(gridDataItem) {
         return gridDataItem.isFilterOn;
      };

      getViewData();

      function getViewData() {
         dataService.getData(
               objectId,
               'com.vmware.vsphere.client.clusterui.model.services.ClusterProactiveHaConfigData')
               .then(function(proHaData) {
                  if (proHaData) {
                     self._originalData = {
                        proHaEnabled: proHaData.infraUpdateHaEnabled,
                        automationLevel: proHaData.behaviorInfraUpdate,
                        remediationLevel: proHaData.proactiveHaRemediationLevel,
                        clusterProviders: _.map(proHaData.clusterProviders, _.clone)
                     };

                     self.proHaEnabled = proHaData.infraUpdateHaEnabled;
                     self.automationLevel = {
                        value: proHaData.behaviorInfraUpdate
                     };
                     self.remediationLevel = {
                        value: proHaData.proactiveHaRemediationLevel
                     };

                     self.getAutomationLevelDesc();
                     self.getRemediationLevelDesc();

                     self.clusterProviders = proHaData.clusterProviders;
                     self.datagridOptions.data = proHaData.clusterProviders;
                  }

                  self.loading = false;
               });
      }

      function isDataChanged() {
         var isProvidersStateChanged = _.find(self.clusterProviders, function(clusterProvider, index) {
            return clusterProvider.isEnabled !== self._originalData.clusterProviders[index].isEnabled;
         });

         return self._originalData &&
               (self._originalData.proHaEnabled !== self.proHaEnabled ||
               self._originalData.automationLevel !== self.automationLevel.value ||
               self._originalData.remediationLevel !== self.remediationLevel.value ||
               isProvidersStateChanged ||
               self.blockAtValue);
      }

      function onSubmit() {
         $scope.modalOptions.alerts = [];

         if (self.loading) {
            return false;
         }

         if (!isDataChanged()) {
            // do not make a call when nothing changed
            return true;
         }

         var formData = editProactiveHaService.getFormData(
               self.proHaEnabled,
               self.automationLevel.value,
               self.remediationLevel.value,
               self.clusterProviders
         );
         var clusterSpec =
               clusterSpecBuilderService.buildClusterComputeResourceSpec(
                     formData, true);

         return mutationService.validate(
               objectId,
               'com.vmware.vsphere.client.cluster.ClusterComputeResourceSpec',
               clusterSpec).then(function(validationResult) {
            if (validationResult && !validationResult.error &&
                  validationResult.result &&
                  validationResult.result.isValid !== true) {
               $scope.modalOptions.alerts.push({
                  type: vuiConstants.validationBanner.type.ERROR,
                  text: validationResult.result.validationMessage
               });
               return false;
            } else {
               mutationService.apply(
                     objectId,
                     'com.vmware.vsphere.client.cluster.ClusterComputeResourceSpec',
                     clusterSpec)
                     .then(function(response) {
                        var selectedItem = self.datagridOptions.selectedItems[0];
                        if (!response.error &&
                              selectedItem &&
                              selectedItem.isEnabled &&
                              self.blockAtValue) {

                           var datagridFailureConditionsHosts =
                                 self.datagridOptionsFailureConditionsHosts;
                           var filterModelSpec =
                                 editProactiveHaService.getFilterModelSpec(
                                       self.blockAtValue,
                                       self.filterModel,
                                       self.filterHealthUpdateInfo,
                                       datagridFailureConditionsHosts.selectedItems);
                           mutationService.apply(
                                 objectId,
                                 'com.vmware.vsphere.client.cluster.ha.proactive.FilterModel',
                                 filterModelSpec
                           );
                        }
                     });

               return true;
            }
         });
      }

      function getColumnDefs() {
         return [{
            filterable: false,
            sortable: false,
            groupable: false,
            searchable: false,
            width: '39px',
            headerTemplate: function() {
               var isNotChecked;
               var isDisabled = false;
               if (!self.clusterProviders || self.clusterProviders.length === 0) {
                  isNotChecked = true;
                  isDisabled = true;
               } else {
                  isNotChecked = _.find(self.clusterProviders, function(clusterProvider) {
                     return !clusterProvider.isEnabled;
                  });
               }
               return '<input type="checkbox"' +
                     ' ng-checked="' + !isNotChecked +
                     '" ng-disabled="' + isDisabled +
                     '" ng-click="datagridOptions.onMasterCheckboxClick($event)"' +
                     ' data-test-id="master-check-box"/>';
            },
            field: 'isEnabled',
            type: 'boolean',
            template: function(dataItem) {
               return '<input type="checkbox" class="row-checkbox" ng-checked="' +
                     dataItem.isEnabled +
                     '" ng-click="datagridOptions.onProviderCheckboxClick(dataItem)">';
            }
         }, {
            displayName: i18nService.getString(
                  'ClusterUi',
                  'ha.config.failures.responses.partial.failure.providers.grid.provider'),
            field: 'nameProvider',
            type: 'string'
         }, {
            displayName: i18nService.getString(
                  'ClusterUi',
                  'ha.config.failures.responses.partial.failure.providers.grid.status'),
            field: 'isEnabled',
            type: 'boolean',
            template: function(dataItem) {
               var isEnabledLabel = dataItem.isEnabled ?
                     i18nService.getString('ClusterUi', 'ha.config.proHa.provider.enabled') :
                     i18nService.getString('ClusterUi', 'ha.config.proHa.provider.disabled');
               return '<span>' + isEnabledLabel + '</span>';
            }
         }, {
            displayName: i18nService.getString(
                  'ClusterUi',
                  'ha.config.failures.responses.partial.failure.providers.grid.isBlocked'),
            field: 'isBlocked',
            type: 'boolean',
            template: function(dataItem) {
               var isBlockedLabel = dataItem.isFiltered ?
                     i18nService.getString('ClusterUi', 'ha.config.constants.yes') :
                     i18nService.getString('ClusterUi', 'ha.config.constants.no');
               return '<span>' + isBlockedLabel + '</span>';
            }
         }];
      }

      $scope.$watch(function() {
         if (self.datagridOptions.selectedItems) {
            return self.datagridOptions.selectedItems;
         }
      }, function(newValue, oldValue) {
         if (newValue && newValue.length === 1) {
            var selectedItem = newValue[0];
            var previousSelectedItemIdProvider =
                  oldValue && oldValue.length > 0 ? oldValue[0].idProvider : null;
            if (selectedItem.idProvider !== previousSelectedItemIdProvider) {
               getFailureConditions(selectedItem);
            }
         }
      }, true);

      $scope.$watch(function() {
         if (self.filterHealthUpdateInfo) {
            return self.filterHealthUpdateInfo;
         }
      }, function(newValue) {
         if (newValue) {
            self.isBlockAtAvailable = _.some(self.filterHealthUpdateInfo, function(item) {
               return item.isFilterOn;
            });
         }
      }, true);

      $scope.$watch(function() {
         if (self.blockAtValue) {
            return self.blockAtValue;
         }
      }, function(newValue, oldValue) {
         self.blockAtOldValue = oldValue;
         if (newValue) {
            fillDatagrid(self.filterHealthUpdateInfo, self.datagridOptionsFailureConditions);

         }

      });

      $scope.$watchCollection(function() {
         if (self.datagridOptionsFailureConditionsHosts.selectedItems) {
            return self.datagridOptionsFailureConditionsHosts.selectedItems;
         }
      }, function(newValue) {
         if (newValue && self.filterHealthUpdateInfo.length > 0) {
            fillDatagrid(self.filterHealthUpdateInfo, self.datagridOptionsFailureConditions);
         }
      });


      function getFailureConditions(selectedItem) {
         var providerId = selectedItem.idProvider;
         editProactiveHaService.getFilterForProvider(objectId, providerId)
               .then(function(data) {
                  if (data) {
                     self.filterModel = data.filterForProvider;

                     self.filterHealthUpdateInfo =
                           self.filterModel && self.filterModel.filterHealthUpdateInfo ?
                                 self.filterModel.filterHealthUpdateInfo : [];

                     self.filterHostInfo = self.filterModel && self.filterModel.filterHostInfo ?
                           self.filterModel.filterHostInfo : [];

                     fillDatagrid(self.filterHealthUpdateInfo, self.datagridOptionsFailureConditions);

                     fillDatagrid(self.filterHostInfo, self.datagridOptionsFailureConditionsHosts);

                     self.blockAtValue =
                           editProactiveHaService.getBlockAtValue(self.filterHealthUpdateInfo, self.filterHostInfo);

                  }
               });
      }

      function getKendoGrid(gridSelector) {
         return $element.find(gridSelector).data('kendoGrid');
      }

      function fillDatagrid(data, datagridOptions) {
         datagridOptions.data = data;
      }

      function getFailureConditionsDatagridOptions() {
         return {
            selectionMode: vuiConstants.grid.selectionMode.NONE,
            sortMode: vuiConstants.grid.sortMode.NONE,
            searchable: false,
            resizable: true,
            selectedItems: [],
            data: [],
            columnDefs: getFailureConditionsColumnDefs(),
            onFailureComponentMasterCheckboxClick: function(event) {
               var checkedState = event.currentTarget.checked;

               _.each(self.filterHealthUpdateInfo, function(item) {
                  item.isFilterOn = checkedState;
               });

               fillDatagrid(self.filterHealthUpdateInfo, self.datagridOptionsFailureConditions);
            },
            onFailureComponentSelected: function(currentItem) {
               if (self.filterModel && self.filterHealthUpdateInfo) {
                  currentItem.isFilterOn = !currentItem.isFilterOn;

                  _.each(self.filterHealthUpdateInfo, function(item) {
                     if (currentItem.id === item.id) {
                        item.isFilterOn = currentItem.isFilterOn;
                     }
                  });

                  var hasAtLeastOneFailureComponentSelected =
                        _.some(self.filterHealthUpdateInfo, function(item) {
                           return item.isFilterOn;
                        });
                  if(hasAtLeastOneFailureComponentSelected && !self.blockAtValue) {
                     self.blockAtValue = clusterConstants.proactiveHaBlockAtTypes.CLUSTER_LEVEL;
                  }


                  fillDatagrid(self.filterHealthUpdateInfo, self.datagridOptionsFailureConditions);
               }
            }
         };
      }

      function getFailureConditionsColumnDefs() {
         return [
            {
               width: '39px',
               headerTemplate: function() {
                  var isNotChecked;
                  if (!self.filterHealthUpdateInfo || self.filterHealthUpdateInfo.length === 0) {
                     isNotChecked = true;
                  } else {
                     isNotChecked = _.find(self.filterHealthUpdateInfo, function(filterHealthUpdateInfo) {
                        return !filterHealthUpdateInfo.isFilterOn;
                     });
                  }
                  return '<input type="checkbox"' +
                        ' ng-checked="' + !isNotChecked +
                        '" ng-click="datagridOptions.onFailureComponentMasterCheckboxClick($event)"' +
                        ' data-test-id="failure-master-check-box"/>';
               },
               field: 'isFilterOn',
               type: 'boolean',
               template: function(dataItem) {
                  return '<input type="checkbox" class="row-checkbox" ng-checked="' +
                        dataItem.isFilterOn +
                        '"' +
                        ' ng-click="datagridOptions.onFailureComponentSelected(dataItem)">';
               }
            },
            {
               displayName: i18nService.getString(
                     'ClusterUi',
                     'ha.config.failures.responses.partial.failure.filters.grid.component'
               ),
               field: 'component'
            },
            {
               displayName: i18nService.getString(
                     'ClusterUi',
                     'ha.config.failures.responses.partial.failure.filters.grid.id'
               ),
               field: 'id'
            },
            {
               displayName: i18nService.getString(
                     'ClusterUi',
                     'ha.config.failures.responses.partial.failure.filters.grid.desc'
               ),
               field: 'description',
               template: function(dataItem) {
                  return dataItem && dataItem.description ?
                  '<span title="' + dataItem.description + '">' + dataItem.description + '</span>' : '';
               }
            },
            {
               displayName: i18nService.getString(
                     'ClusterUi',
                     'ha.config.failures.responses.partial.failure.filters.grid.blocked'),
               field: 'isFilterOn',
               type: 'boolean',
               template: function(dataItem) {

                  var isBlockAtValueIndividualHosts =
                        self.blockAtValue === clusterConstants.proactiveHaBlockAtTypes.INDIVIDUAL_HOSTS;
                  var noHostsAreSelectedFromDatagrid =
                        self.datagridOptionsFailureConditionsHosts.selectedItems &&
                        self.datagridOptionsFailureConditionsHosts.selectedItems.length === 0;

                  var areHostsSelected = !(isBlockAtValueIndividualHosts && noHostsAreSelectedFromDatagrid);

                  var isBlockedLabel = dataItem.isFilterOn && areHostsSelected ?
                        i18nService.getString('ClusterUi', 'ha.config.constants.yes') :
                        i18nService.getString('ClusterUi', 'ha.config.constants.no');
                  return '<span>' + isBlockedLabel + '</span>';
               }
            }
         ];
      }
   }
})();
