namespace h5_vapp {
   import ManagedObjectReference = com.vmware.vim.binding.vmodl.ManagedObjectReference;
   import ValidateMoveVappSpec = com.vmware.vsphere.client.h5.vapp.data.ValidateMoveVappSpec;
   import ActionEvaluation = com.vmware.vise.actionsfw.ActionEvaluation;
   export interface DestinationTarget {
      dropTarget: string;
   }
   export interface AlertProperties {
      title: string;
      message: string;
   }

   /**
    * Implements the action code which is invoked when the user drops a VApp over some target
    */
   export class VAppDragAndDropService {
      public static $inject: string[] = [
         "defaultUriSchemeUtil",
         "i18nService",
         "actionConfirmationService",
         "moveVAppService"
      ];

      private _defaultUriSchemeUtil: any;
      private _i18nService: any;
      private _actionConfirmationService: any;
      private _moveVAppService: any;

      public constructor(
            defaultUriSchemeUtil: any,
            i18nService: any,
            actionConfirmationService: any,
            moveVAppService: any,
            dragAndDropStockExpressionsService: any) {
         this._defaultUriSchemeUtil = defaultUriSchemeUtil;
         this._i18nService = i18nService;
         this._actionConfirmationService = actionConfirmationService;
         this._moveVAppService = moveVAppService;
      }

      public dragAndDropVapp(availableTargets: string[], destinationTarget: DestinationTarget,
                             actionEval: ActionEvaluation): void {

         this._moveVAppService.validateMove(availableTargets,
               destinationTarget.dropTarget).then((result: any) => {
            if (result) {
               this.displayWarning(actionEval.action, result);
            } else {
               this._moveVAppService.moveVApps(availableTargets, destinationTarget);
            }
         });
      }

      /**
       * Displays a warning message
       * if it is passed as parameter(waning)
       * if no message is passed it is retrieved from the action
       * if no message is found the default warning is shown
       * @param action
       * @param warning
       */
      private displayWarning(action: any = {}, warning: string = "") {
         let confirmationTitle: string = !!action.confirmationTitle ?
               action.confirmationTitle :
               action.label;
         let alertProperties: AlertProperties = {
            title: confirmationTitle,
            message: warning
         };
         this._actionConfirmationService.showWarningDialog(alertProperties);
      }

   }

   angular.module("com.vmware.vsphere.client.vapp")
         .service("vAppDragAndDropService", VAppDragAndDropService);
}
