namespace storage_ui {

   import IPromise = angular.IPromise;
   import IQService = angular.IQService;
   import QueryReclamationOptionsSpec = com.vmware.vsphere.client.h5.storage.spec.QueryReclamationOptionsSpec;
   import VmfsVolume$UnmapBandwidthSpec = com.vmware.vim.binding.vim.host.VmfsVolume$UnmapBandwidthSpec;
   import UpdateVmfsUnmapBandwidthSpec = com.vmware.vsphere.client.h5.storage.spec.UpdateVmfsUnmapBandwidthSpec;
   import DatastoreData = com.vmware.vsphere.client.h5.storage.data.datastore.reclamation.DatastoreData;

   import DatastoreSpaceReclamationBandwidthService = storage_ui.DatastoreSpaceReclamationBandwidthService;

   export class UnmapPriorityMutationSpec {
      unmapPriority: string;
      vmfsUuid: string;
      hostRef: string;
      _type: string = "com.vmware.vsphere.client.storage.VmfsDatastoreUnmapPrioritySpec";
   }

   export class EntitySpecPair {
      entity: any;
      spec: any;
   }

   export class DatastoreSpaceReclamationPriorityDialogService {

      public static $inject = ["$q", "dataService", "clarityModalService",
         "mutationService", "i18nService", "clarityConstants",
         "defaultUriSchemeUtil", "datastoreSpaceReclamationBandwidthService"];

      private static RECLAMATION_OPTIONS_PROPERTY: string = "datastore:reclamationOptionsData";
      private readonly DEFAULT_PRIORITY: string = "low";
      private readonly NONE_PRIORITY: string = "none";

      constructor(private $q: IQService,
                  private dataService: any,
                  private clarityModalService: any,
                  private mutationService: any,
                  private i18nService: any,
                  private clarityConstants: any,
                  private defaultUriSchemeUtil: any,
                  private reclamationBandwidthService: DatastoreSpaceReclamationBandwidthService) {
      }

      public show(datastoreIds: string[]): void {
         let dialogData: any = {
            datastoreIds: datastoreIds,
            spaceReclamationData: null,
            spec: null,
            isLoading: true,
            i18n: this.i18nService.getString,
            entitySpecs: []
         };

         let modalOptions: any = {
            contentTemplate: "storage-ui/resources/storage/views/datastore/spacereclamation/DatastoreSpaceReclamationDialog.html",
            title: this.i18nService.getString("StorageUi", "datastore.editSpaceReclamationForm.title"),
            defaultButton: "submit",
            dialogData: dialogData
         };
         modalOptions.onSubmit = this.onSpaceReclamationSubmitted.bind(this, dialogData, modalOptions);

         dialogData.spaceReclamationData = {unmapPriority: this.DEFAULT_PRIORITY};

         if (datastoreIds.length === 1) {
            modalOptions.subTitle = {
               objectId: datastoreIds[0]
            };
         }

         this.queryReclamationOptionsData(datastoreIds)
               .then((reclamationOptionsData: any) => {
                  this.initializeDialogState(reclamationOptionsData, dialogData);
               }, () => {
                  this.setModalErrors(modalOptions, [this.i18nService.getString(
                        "StorageUi",
                        "datastore.configure.general.spaceReclamation.responseError")]);
               })
               .finally(() => {
                  dialogData.isLoading = false;
               });

         modalOptions.signPostParams = {
            message: this.i18nService.getString("StorageUi", "datastore.editSpaceReclamationForm.title")
         };
         this.clarityModalService.openOkCancelModal(modalOptions);
      }

      private setModalErrors(modalOptions: any, errors: string[], disableSubmit: boolean = true) {
         const errorObjects = _.map(errors, (err) => {
            return {
               text: err,
               type: this.clarityConstants.notifications.type.ERROR
            };
         });
         modalOptions.alerts = errorObjects;
         if (disableSubmit) {
            modalOptions.submitDisabled = true;
         }
      }

      private queryReclamationOptionsData(datastoreIds: string[]): IPromise<any> {

         let querySpec: QueryReclamationOptionsSpec = new QueryReclamationOptionsSpec();
         querySpec.datastores = this.defaultUriSchemeUtil.getManagedObjectReferences(datastoreIds);

         let params: any[] = [{
            propertyName: DatastoreSpaceReclamationPriorityDialogService.RECLAMATION_OPTIONS_PROPERTY,
            parameterType: querySpec._type,
            parameter: querySpec
         }];

         return this.dataService.getProperties(datastoreIds[0],
               DatastoreSpaceReclamationPriorityDialogService.RECLAMATION_OPTIONS_PROPERTY,
               {
                  propertyParams: params
               }).then( (response: any) => {

                  let reclamationOptions: any = response && response[
                        DatastoreSpaceReclamationPriorityDialogService.RECLAMATION_OPTIONS_PROPERTY];
                  if (!reclamationOptions) {
                     return this.$q.reject(null);
                  }

                  if (!reclamationOptions.datastoresData ||
                        _.some(reclamationOptions.datastoresData,
                              (datastoreData:any) =>
                                    !datastoreData.connectedHost ||
                                    !datastoreData.datastoreUuid)) {

                     // There is a datastore for which there is no connected host
                     // or the datastore uuid is missing.
                     return this.$q.reject(null);
                  }

                  return reclamationOptions;
               });
      }

      private initializeDialogState(reclamationOptions: any, dialogData: any): void {

         dialogData.reclamationOptions = reclamationOptions;

         if (!reclamationOptions.fixedReclamationSupported) {
            // legacy datastore, show priority reclamation options
            dialogData.showBandwidthControls = false;
            dialogData.spaceReclamationData = {
               unmapPriority: reclamationOptions.reclamationPriority
            };
         } else {
            // show bandwidth reclamation options
            dialogData.showBandwidthControls = true;
            dialogData.vmfsConfigOption = reclamationOptions.vmfsConfigOption;
            this.populateUnmapBandwidthSpec(reclamationOptions);
            dialogData.unmapBandwidthSpec = reclamationOptions.unmapBandwidthSpec;
            dialogData.showDynamicUnmapOption = reclamationOptions.dynamicReclamationSupported;
         }
      }

      private populateUnmapBandwidthSpec(reclamationOptions: any) {
         if (!reclamationOptions.unmapBandwidthSpec) {
            let disabledSpec: VmfsVolume$UnmapBandwidthSpec = new VmfsVolume$UnmapBandwidthSpec();
            disabledSpec.policy = "disabled";
            reclamationOptions.unmapBandwidthSpec = disabledSpec;
         }

         this.reclamationBandwidthService.setDefaultRatesIfMissing(
               reclamationOptions.unmapBandwidthSpec,
               reclamationOptions.vmfsConfigOption,
               reclamationOptions.dynamicReclamationSupported);
      }

      private onSpaceReclamationSubmitted(dialogData: any, modalOptions: any): boolean {

         if (!dialogData.showBandwidthControls) {
            this.setReclamationPriority(dialogData, dialogData.spaceReclamationData.unmapPriority);
         } else {
            let bandwidthSpec: VmfsVolume$UnmapBandwidthSpec = dialogData.unmapBandwidthSpec;
            let validationError: string|null =
                  this.reclamationBandwidthService.validateUnmapBandwidthSpec(bandwidthSpec);
            if (validationError) {
               this.setModalErrors(modalOptions, [validationError], false);
               return false;
            }

            if (bandwidthSpec.policy === DatastoreSpaceReclamationBandwidthService.FIXED_METHOD ||
                  bandwidthSpec.policy === DatastoreSpaceReclamationBandwidthService.DYNAMIC_METHOD) {
               this.updateReclamationBandwidth(dialogData, bandwidthSpec);
            } else {
               // disable automatic unmap
               this.setReclamationPriority(dialogData, this.NONE_PRIORITY);
            }
         }

         // Close the modal
         return true;
      }

      private setReclamationPriority(dialogData: any, priority: string): void {
         let entitySpecs: any[] = _.map(
               dialogData.reclamationOptions.datastoresData,
               (datastoreData: any) => {
                  const spec = new UnmapPriorityMutationSpec();
                  spec.unmapPriority = priority;
                  spec.hostRef = datastoreData.connectedHost;
                  spec.vmfsUuid = datastoreData.datastoreUuid;

                  let entitySpec: EntitySpecPair = new EntitySpecPair();
                  entitySpec.spec = spec;
                  entitySpec.entity = this.defaultUriSchemeUtil.getVsphereObjectId(
                        datastoreData.datastore);
                  return entitySpec;
               });

         this.mutationService.applyMulti(entitySpecs[0].spec._type, entitySpecs);
      }

      private updateReclamationBandwidth(
            dialogData: any, bandwidthSpec: VmfsVolume$UnmapBandwidthSpec): void {

         let entitySpecs: any[] = _.map(
               dialogData.reclamationOptions.datastoresData,
               (datastoreData: DatastoreData) => {
                  const spec:UpdateVmfsUnmapBandwidthSpec = new UpdateVmfsUnmapBandwidthSpec();
                  spec.datastore = datastoreData.datastore;
                  spec.unmapBandwidthSpec = bandwidthSpec;
                  spec.vmfsUuid = datastoreData.datastoreUuid;

                  let entitySpec = new EntitySpecPair();
                  entitySpec.spec = spec;
                  entitySpec.entity = this.defaultUriSchemeUtil.getVsphereObjectId(
                        datastoreData.connectedHost);
                  return entitySpec;
               });

         this.mutationService.applyMulti(entitySpecs[0].spec._type, entitySpecs);
      }

   }

   angular.module("com.vmware.vsphere.client.storage")
         .service("datastoreSpaceReclamationPriorityDialogService", DatastoreSpaceReclamationPriorityDialogService);
}

