/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
/*
 * Controller for Content Library Subscription Portlet.
 *
 */
namespace h5_client {
   export class ContentLibrarySubscriptionPortletController {
      public static $inject: string[] = ["$scope", "AngularInjector", "i18nService", "actionsService"];

      libraryId: string;
      editSettingsService: any;

      constructor(private $scope: any,
                  private AngularInjector: any,
                  private i18nService: any,
                  private actionsService: any) {
         this.libraryId = this.$scope._route.objectId;

         this.editSettingsService = AngularInjector.get("vscSubscribedLibraryEditSettingsModalService");

         this.actionsService.getAction("vsphere.core.library.subscribed.editSettings", [ this.libraryId ])
               .then((action: any) => {
                  this.$scope.portlet.footerLinks = [
                     {
                        label: this.i18nService.getString("LibraryUi", "library.editAction"),
                        ariaLabel: this.i18nService.getString("LibraryUi", "library.editSubscriptionSettings"),
                        onClick: this.onEditSettingsClicked.bind(this),
                        enabled: action && action.available
                     }
                  ];
               });


      }

      private onEditSettingsClicked($event: KeyboardEvent): void {
         const triggerElement = $event.currentTarget as HTMLElement;
         this.editSettingsService.openSubscribedLibraryEditSettingsDialog(this.libraryId, triggerElement);
      }
   }
   angular.module("com.vmware.vsphere.client.commonModule")
         .controller("contentLibrarySubscriptionPortletController", ContentLibrarySubscriptionPortletController);
}
