(function () {
   'use strict';

   /**
    * Provides admission policy text and description data.
    */
   angular.module('com.vmware.vsphere.client.cluster')
      .service('haAdmissionControlService', haAdmissionControlService);

   haAdmissionControlService.$inject = ['i18nService', 'haConstants', 'logService',
      'defaultUriSchemeUtil'];

   function haAdmissionControlService(i18nService, haConstants, logService,
                                      defaultUriSchemeUtil) {
      var log = logService('haAdmissionControlService');

      var FAILOVER_LEVEL_DEFAULT_VALUE = 1;
      var FIXED_SLOT_SIZE_CPU_DEFAULT = 32;
      var FIXED_SLOT_SIZE_MEMORY_DEFAULT = 100;

      /**
       * Retrieves admission control data.
       */
      function getAdmissionControlModel(haData) {
         var model = {};
         if (!haData || haData.admissionControlEnabled === null ||
               !angular.isDefined(haData.admissionControlEnabled)) {
            log.error("HA data is not set: " + haData);
            return model;
         }

         if (!haData.admissionControlEnabled) {
            model.policyLabel = getString('policy.disabled.label');
            model.policyType = haConstants.admissionControlPolicy.DISABLED_TYPE;
            return model;
         }

         if (!haData.admissionControlPolicy) {
            log.error("Admission control policy is not defined: " + haData);
            return model;
         }

         return createAdmissionControlPolicyViewData(haData);
      }

      /**
       * Retrieves admission policy data.
       */
      function createAdmissionControlPolicyViewData(haData) {
         var model = {};
         model.policyType = haData.admissionControlPolicy._type;
         switch (haData.admissionControlPolicy._type) {
            case haConstants.admissionControlPolicy.FAILOVER_LEVEL_TYPE:
               model.policyLabel = getString('policy.failoverlevel.label');
               model.reservedFailoverCapacity = getNumberOfHostsLabel(
                     haData.failoverHostNum);
               break;
            case haConstants.admissionControlPolicy.FAILOVER_RESOURCES_TYPE:
               model.policyLabel = getString('policy.failoverResources.label');
               model.cpuFailoverInPercent = getString('failoverResources.percent',
                     haData.cpuFailoverPercent);
               model.memoryFailoverInPercent = getString('failoverResources.percent',
                     haData.memoryFailoverPercent);
               break;
            case haConstants.admissionControlPolicy.FAILOVER_HOSTS_TYPE:
               model.policyLabel = getString('policy.failoverHosts.label');
               model.dedicatedHosts = getNumberOfHostsLabel(haData.dedicatedHostNum);
               break;
            default:
               log.warnings("Unknown admission policy: " +
                     haData.admissionControlPolicy._type);
         }
         return model;
      }

      /**
       * Retrieves a label for the number of hosts.
       */
      function getNumberOfHostsLabel(numOfHosts) {
         var label = "";
         if (numOfHosts === 1) {
            label = getString('host', numOfHosts);
         } else if (numOfHosts > 1) {
            label = getString('host.plural', numOfHosts);
         }
         return label;
      }

      /**
       * Retrieves admission control policies
       */
      function getPolicies() {
         var policies =
               Object.keys(haConstants.admissionControlPolicy).map(function(key) {
                  var policyType = haConstants.admissionControlPolicy[key];
                  var policy = buildPolicy(policyType);
                  return policy;
               });

         return policies;
      }

      function buildPolicy(policyType) {
         return {
            type: policyType,
            label: getLabel(policyType)
         };
      }

      /**
       * Retrieves service for admission control failover hosts processing.
       *
       * @param pageModel admission control page model
       */
      function getFailoverHostsService(pageModel) {
         var failoverHostsModel = pageModel;

         /**
          * Retrieves data for failover hosts grid.
          *
          * @returns Array of failover hosts
          */
         function getFailoverHostsGridData() {
            return failoverHostsModel.failoverHosts;
         }

         /**
          * Retrieves object id-s of failover hosts
          * @returns Array of host object id-s
          */
         function getFailoverHostIds() {
            var dedicatedFailoverHostIds = _.map(
                  failoverHostsModel.failoverHosts, function(hostMor) {
                     return defaultUriSchemeUtil.getVsphereObjectId(hostMor.provider);
                  });

            return dedicatedFailoverHostIds;
         }

         /**
          * Adds selected hosts to failover hosts model and grid data.
          *
          * @param failoverHostsGridData
          * @param selectedObjects
          * @returns {boolean}
          */
         function addFailoverHosts(failoverHostsGridData, selectedItems) {
            var newHostItems = _.map(selectedItems, function(selectedItem) {
               return generateHostItem(selectedItem);
            });

            failoverHostsGridData.data =
                  failoverHostsGridData.data.concat(newHostItems);
            failoverHostsModel.failoverHosts = failoverHostsGridData.data;

            return true;
         }

         /**
          * Remove selected host from failover hosts model and grid data.
          *
          * @param failoverHostsGridData
          * @param selectedObjects
          */
         function removeFailoverHost(failoverHostsGridData, itemsToRemove) {
            failoverHostsGridData.data = _.reject(failoverHostsGridData.data,
                  function(hostData) {
                     return !!_.find(itemsToRemove,
                           function(itemToRemove) {
                              return itemToRemove.name === hostData.name;
                           }
                     );
                  });

            failoverHostsModel.failoverHosts = failoverHostsGridData.data;
         }

         function generateHostItem(selectedItem) {
            var hostMor = defaultUriSchemeUtil.getPartsFromVsphereObjectId(
                  selectedItem.rawData.id);
            return {
               iconId: selectedItem.rawData.icon,
               name: selectedItem.rawData.name,
               provider: hostMor
            };
         }

         return {
            getFailoverHostsGridData: getFailoverHostsGridData,
            getFailoverHostIds: getFailoverHostIds,
            addFailoverHosts: addFailoverHosts,
            removeFailoverHost: removeFailoverHost
         };

      }

      function getFailoverLevelDefaultValue() {
         return FAILOVER_LEVEL_DEFAULT_VALUE;
      }

      function getFixedSlotSizeCpuDefaultValue() {
         return FIXED_SLOT_SIZE_CPU_DEFAULT;
      }

      function getFixedSlotSizeMemoryDefaultValue() {
         return FIXED_SLOT_SIZE_MEMORY_DEFAULT;
      }

      function getLabel(policyType) {
         var policyLabel;
         switch (policyType) {
            case haConstants.admissionControlPolicy.FAILOVER_LEVEL_TYPE:
               policyLabel = 
                     'ha.config.admission.control.failover.policy.label.failoverLevel';
               break;
            case haConstants.admissionControlPolicy.FAILOVER_RESOURCES_TYPE:
               policyLabel = 
                     'ha.config.admission.control.failover.policy.label.failoverResources';
               break;
            case haConstants.admissionControlPolicy.FAILOVER_HOSTS_TYPE:
               policyLabel = 
                     'ha.config.admission.control.failover.policy.label.failoverHosts';
               break;
            case haConstants.admissionControlPolicy.DISABLED_TYPE:
               policyLabel = 'ha.config.admission.control.failover.policy.label.disabled';
               break;
            default:
               return '';
         }
         return i18nService.getString('ClusterUi', policyLabel);
      }

      function getString(key, args) {
         return i18nService.getString('ClusterUi', 'ha.config.admission.control.' + key,
               args);
      }

      return {
         getAdmissionControlModel: getAdmissionControlModel,
         getPolicies: getPolicies,
         getFailoverHostsService: getFailoverHostsService,
         getFailoverLevelDefaultValue: getFailoverLevelDefaultValue,
         getFixedSlotSizeCpuDefaultValue: getFixedSlotSizeCpuDefaultValue,
         getFixedSlotSizeMemoryDefaultValue: getFixedSlotSizeMemoryDefaultValue
      };
   }
})();
