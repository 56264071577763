namespace storage_ui {

   import IComponentController = angular.IComponentController;
   import IComponentOptions = angular.IComponentOptions;
   import DatastoreProtocolEndpointHostData = com.vmware.vsphere.client.storage.DatastoreProtocolEndpointHostData;
   import ProtocolEndpointData = com.vmware.vsphere.client.storage.ProtocolEndpointData;
   import ScsiLun = com.vmware.vim.binding.vim.host.ScsiLun;

   export class DatastoreProtocolEndpointHostsListItem {
      hostUrl: string;
      lun: string;
      identifier: string;
      location: string;
      operationalState: string;
      transport: string;
      owner: string;
      i18nService: any;

      constructor(storageUtil: any, host: string, hostData: ProtocolEndpointData) {

         this.hostUrl = host || "";
         if (hostData) {
            // Extract the SCSI LUN for this PE
            let scsiLUN: ScsiLun = hostData.underlyingLun;
            // Get the identifier for the procol endpoint based on the LUN
            this.identifier = scsiLUN.canonicalName || "";
            // Get the location for the procol endpoint based on the LUN
            this.location = scsiLUN.deviceName || "";
            // Get the LUN for the SCSI protocol endpoint
            this.lun = String(hostData.lunNumber);
            // Get the operational state of the protocol endpoint
            this.operationalState = storageUtil.getPEOperationalStateString(hostData);
            // Get the transport for the protocol endpoint
            this.transport = storageUtil.getScsiPeTransport(hostData);
            // Get the owner plugin name for the protocol endpoint
            this.owner = hostData.ownerPlugin || "";
         }
      }



   }

   class ProtocolEndpointsHostMountComponentController implements IComponentController {
      static $inject = ["vuiConstants", "i18nService", "dataService", "navigation", "storageUtil"];

      private datagridOptions: any;
      private selectedItems: DatastoreProtocolEndpointListItem[];
      private readonly DATASTORE_ENDPOINTS_HOST_DATA = "datastoreProtocolEndpointHostData";

      constructor(private vuiConstants: any,
                  private i18nService: any,
                  private dataService: any,
                  private navigation: any,
                  private storageUtil:any) {
         this.initDatagrid();
      }


      $onChanges() {
         if (this.selectedItems && this.selectedItems.length) {
            let params: any[] = [{
               propertyName: this.DATASTORE_ENDPOINTS_HOST_DATA,
               parameterType: "java.lang.String",
               parameter: this.selectedItems[0].identifier
            }];

            this.dataService.getProperties(this.navigation.getRoute().objectId,
                  this.DATASTORE_ENDPOINTS_HOST_DATA, {
                     propertyParams: params
                  }).then(
                  (response: {[key: string]: DatastoreProtocolEndpointHostData[]})=> {
                     this.datagridOptions.data = _.map(
                           response[this.DATASTORE_ENDPOINTS_HOST_DATA],
                           (endpointData: DatastoreProtocolEndpointHostData) => {
                              return new DatastoreProtocolEndpointHostsListItem(
                                    this.storageUtil, endpointData.hostURL,
                                    endpointData.peData);
                           });
                  });
         }
      }

      private initDatagrid() {
         this.datagridOptions = {
            resizable: true,
            height: "100%",
            columnDefs: this.getColumnDefs(),
            selectionMode: this.vuiConstants.grid.selectionMode.SINGLE,
            selectedItems: []
         };
      }

      private getColumnDefs() {
         return [
            {
               displayName: this.i18nService.getString("StorageUi", "storage.pe.datastores.hostUrl"),
               field: "name",
               width: "120",
               template: "#:hostUrl#"
            },
            {
               displayName: this.i18nService.getString("StorageUi", "storage.pe.identifier"),
               field: "identifier",
               width: "180px",
               template: "#:identifier#"
            },
            {
               displayName: this.i18nService.getString("StorageUi", "storage.pe.location"),
               field: "location",
               width: "220px",
               template: "#:location#"
            },
            {
               displayName: this.i18nService.getString("StorageUi", "storage.pe.lun"),
               field: "lun",
               width: "60px",
               template: "#:lun#"
            },
            {
               displayName: this.i18nService.getString("StorageUi", "storage.pe.opState"),
               field: "operationalState",
               width: "120px",
               template: "#:operationalState#"
            },
            {
               displayName: this.i18nService.getString("StorageUi", "storage.pe.transport"),
               field: "transport",
               width: "80px",
               template: "#:transport#"
            }, {
               displayName: this.i18nService.getString("StorageUi", "storage.pe.owner"),
               field: "owner",
               width: "80px",
               template: "#:owner#"
            }
         ];
      }

   }

   angular.module("com.vmware.vsphere.client.storage").component(
         "protocolEndpointsHostMountData", <IComponentOptions>{
            controller: ProtocolEndpointsHostMountComponentController,
            templateUrl: "storage-ui/resources/storage/views/datastore/protocolEndpoints/hostMountData/ProtocolEndpointsHostMountComponent.html",
            bindings: {
               selectedItems: "<"
            }
         });

}
