namespace h5_vm {

   declare const ipaddr: any;
   import AdapterMapping = com.vmware.vim.binding.vim.vm.customization.AdapterMapping;
   import IPSettings = com.vmware.vim.binding.vim.vm.customization.IPSettings;
   import DhcpIpGenerator = com.vmware.vim.binding.vim.vm.customization.DhcpIpGenerator;
   import DhcpIpV6Generator = com.vmware.vim.binding.vim.vm.customization.DhcpIpV6Generator;

   class EditDnsTabController {
      public static $inject = ["i18nService", "gosSpecNetworkService", "ipParserService"];

      public i18n: (bundle: string, key: string, ...args: (string | number)[]) => string;

      private readonly OPTION_TYPE_DHCP = "useDhcp";
      private readonly OPTION_DNS_MANUAL = "dnsManual";
      private readonly IPV4_TYPE_DHCP = GosCustomizationHelperService.ADAPTER_TYPE_DHCP;
      private readonly PREFERRED_DNS = "preferredDns";
      private readonly ALTERNATE_DNS = "alternateDns";
      private readonly TITLE_LOCALE = "customizationSpec.networkPage.properties.dns.pageTitle";

      public adapterWrapper: AdapterMappingWrapper;
      public adapterMapping: AdapterMapping;
      public dnsType: string;
      public isDhcpEnabled: boolean;
      public preferredDns: string;
      public alternateDns: string;
      public dnsSuffixList: string[];
      public updateDnsTab: () => void;
      public editDns: () => void;
      public submitErrors: string[];
      public validationErrors: any = {};
      public refreshGridData: Function;

      constructor(private i18nService: any,
                  private gosSpecNetworkService: GosSpecNetworkService,
                  private ipParser: any) {

         this.i18n = this.i18nService.getString;
      }

      public $onInit(): void {
         this.adapterMapping = this.adapterWrapper.adapterMapping;
         this.populateData();
         this.updateDnsTab = (): void => {
            this.updateData();
            this.populateData();
         };
         this.editDns = (): void => {
            this.updateData();
         };
      }

      public updateData() {
         let dnsServers: string[] = [];

         if (this.dnsType === this.OPTION_DNS_MANUAL) {
            if (this.preferredDns ) {
               dnsServers.push(this.getNormalizedDnsAddress(this.preferredDns));
            }
            if (this.alternateDns) {
               dnsServers.push(this.getNormalizedDnsAddress(this.alternateDns));
            }
         }

         this.adapterMapping.adapter.dnsServerList = dnsServers;
      }

      private getNormalizedDnsAddress(address: string) {
         let isValid: boolean = this.ipParser.isIpv4AddressValid(address);
         if (!isValid) {
            return address;
         }
         let addr: any = ipaddr.parse(address);
         return addr.toString();
      }

      public dnsTypeChanged() {
         this.validationErrors = {};

         if (this.dnsType === this.OPTION_DNS_MANUAL) {
            this.validateDns(this.PREFERRED_DNS);
            this.validateDns(this.ALTERNATE_DNS);
         } else {
            this.setValidationErrors(true);
         }
      }

      private setValidationErrors(isValid: boolean) {
         if (!isValid && !_.contains(this.submitErrors, this.TITLE_LOCALE)) {
            this.submitErrors.push(this.TITLE_LOCALE);
         } else if (isValid) {
            let index: number = _.findIndex(this.submitErrors,
               (currentError: string) => {
                  return currentError === this.TITLE_LOCALE;
               });
            if (index < 0) {
               return;
            }
            this.submitErrors.splice(index, 1);
         }

      }

      public validateDns(dnsType: string) {
         let address: string = dnsType === this.PREFERRED_DNS? this.preferredDns: this.alternateDns;
         let isValid: boolean = !address || this.ipParser.isAddressValid(address);

         if (!isValid) {
            this.validationErrors[dnsType] =
               this.i18n("VmUi", "customizationSpec.networkPage.properties.dns.error.address");
         } else {
            this.validationErrors[dnsType] = null;
         }

         if (this.validationErrors.preferredDns || this.validationErrors.alternateDns) {
            this.setValidationErrors(false);
         } else {
            this.setValidationErrors(true);
         }
      }

      public populateData = () => {

         let adapter: IPSettings = this.adapterMapping.adapter;

         // Enable DCHP only if IPv4 or IPv6 uses DCHP
         this.isDhcpEnabled = (adapter.ip._type === this.IPV4_TYPE_DHCP);

         if (adapter.ipV6Spec && adapter.ipV6Spec.ip && adapter.ipV6Spec.ip.length
            && adapter.ipV6Spec.ip[0] instanceof DhcpIpV6Generator) {
            this.isDhcpEnabled = true;
         }

         let dnsServerList: string[] = adapter.dnsServerList;

         if (dnsServerList && dnsServerList.length) {
            this.dnsType = this.OPTION_DNS_MANUAL;
            this.preferredDns = dnsServerList[0];
            if (dnsServerList.length > 1) {
               this.alternateDns = dnsServerList[1];
            }
         } else if (this.isDhcpEnabled) {
            this.dnsType = this.OPTION_TYPE_DHCP;
         } else {
            this.dnsType = this.OPTION_DNS_MANUAL;
         }
      };

   }

   export class EditDnsTabComponent {
      public controller:any;
      public templateUrl:string;
      public bindings:any;

      constructor() {
         this.bindings = {
            adapterWrapper: "<",
            dnsSuffixList: "=",
            updateDnsTab: "=",
            submitErrors: "=",
            editDns: "=",
            refreshGridData: "="
         };

         this.controller = EditDnsTabController;
         this.templateUrl = "vm-ui/resources/vm/guest-os-customization/pages/network/edit/dns-tab/edit-dns-tab.component.html";
      }
   }

   angular.module("com.vmware.vsphere.client.vm")
      .component("editDnsTab", new EditDnsTabComponent());
}
