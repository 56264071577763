module ds_cluster_ui {

   angular.module('com.vmware.vsphere.client.dsCluster')
         .constant('createDsClusterPageConstants', {
            NAME_AND_LOCATION_INDEX: 0,
            AUTOMATION_INDEX: 1,
            RUNTIME_INDEX: 2,
            HOSTS_AND_CLUSTERS_INDEX: 3,
            DATASTORES_INDEX: 4,
            READY_TO_COMPLETE_INDEX: 5
   });
}
