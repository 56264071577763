(function() {
   'use strict';

   /**
    * @name com.vmware.vsphere.client.host:hostServiceStatus
    *
    * @description
    *    Represents vim.host.Service.Policy enum values.
    *
    *    List of available constants
    *
    *    - **`ON`** : Service should be started when the host starts up.
    *    - **`OFF`** : Service should not be started when the host starts up.
    *    - **`AUTOMATIC`** : Service should run if and only if it has open firewall ports.
    */
   angular
         .module('com.vmware.vsphere.client.host')
         .constant('hostServicesConstants', {
            status: {
               RUNNING: 'true',
               STOPPED: 'false'
            },
            action: {
               START: 'start',
               STOP: 'stop',
               RESTART: 'restart',
               EDIT: 'edit'
            },
            policy:{
               ON: 'on',
               OFF: 'off',
               AUTOMATIC: 'automatic'
            },
            NO_PRIVILEGE: 'No privilege'
         });
})();
