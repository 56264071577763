namespace h5_vm {
   export class GosCustomSysprepFilePageController {
      public static $inject = [
         "$q", "i18nService", "$element", "vcService"];

      model: GosCustomSysprepFilePageModel;
      reader: any;
      sysprepAnswerFileDesc: string;
      sysprepAnswerFileFilter: string;

      private readonly IMPORT_FILE = CustomSysprepFileOption.IMPORT_FILE;
      private readonly CREATE_FILE = CustomSysprepFileOption.CREATE_FILE;

      constructor(private $q: any,
                  private i18n: any,
                  private $element: any,
                  private vcService: any) {
      }

      $onInit(): void {
         let importSysprep: any = this.$element.find(".select-sysprep-file-input");
         // when the file browser is open this event is triggered
         // when `Open` or `Cancel` is pressed
         importSysprep.on("change", (event: any) => {
            this.onFileChosen(event);
         });
         this.model.onPageCommit = this.onCommit.bind(this);

         // default values - in case of some failure or something else
         let answerFileDescKey =
               "customizationSpec.customSysprepFilePage.pageDirections.postVista";
         let answerFileFilter = ".xml";
         this.vcService.is67VcOrLaterByGuid(this.model.vcServerGuid)
               .then((isVc67OrLater: boolean) => {
                  if (!isVc67OrLater) {
                     answerFileDescKey =
                           "customizationSpec.customSysprepFilePage.pageDirections.preVista";
                     answerFileFilter = ".inf, .xml";
                  }
               })
               .then(() => {
                  this.sysprepAnswerFileDesc = this.i18n.getString("VmUi", answerFileDescKey);
                  this.sysprepAnswerFileFilter = answerFileFilter;
               });
      }

      $onDestroy(): void {
         this.model.onPageCommit = undefined;
      }

      onCommit(): angular.IPromise<Array<string>> {
         if (this.model.fileOption === CustomSysprepFileOption.IMPORT_FILE) {

            if (_.isEmpty(this.model.sysprepImportFileName)) {
               let error: string = this.i18n.getString(
                     "VmUi", "customizationSpec.customSysprepFilePage.selectSysprepFile");
               return this.$q.resolve([error]);
            }

            if (_.isEmpty(this.model.sysprepImportFileContent.trim())) {
               let error: string = this.i18n.getString(
                     "VmUi", "customizationSpec.customSysprepFilePage.emptySysprepFile");
               return this.$q.resolve([error]);
            }
         } else {
            if (_.isEmpty(this.model.sysprepContent.trim())) {
               let error: string = this.i18n.getString(
                     "VmUi", "customizationSpec.customSysprepFilePage.insertSysprepText");
               return this.$q.resolve([error]);
            }
         }

         return this.$q.resolve([]);
      }

      onBrowseFileClicked() {
         let browseFileInput = this.$element.find(".select-sysprep-file-input");
         browseFileInput.click();
      }

      onFileChosen(event: any) {
         if (event.target.files && event.target.files.length > 0) {
            this.model.sysprepImportFileName = event.target.files[0].name;

            this.reader = new FileReader();
            this.reader.readAsText(event.target.files[0]);
            this.reader.onload = (): void => {
               this.model.sysprepContent = this.reader.result.trim();
               this.model.sysprepImportFileContent = this.model.sysprepContent;
            };
         }
      }
   }

   export class GosCustomSysprepFilePageComponent implements angular.IComponentOptions {
      public controller: any;
      public templateUrl: string;
      public bindings: any;

      constructor() {
         this.controller = GosCustomSysprepFilePageController;
         this.templateUrl =
               "vm-ui/resources/vm/guest-os-customization/pages/custom-sysprep-file/gos-custom-sysprep-file-page.component.html";
         this.bindings = {
            model: "<"
         };
      }
   }

   angular
         .module('com.vmware.vsphere.client.vm')
         .component('gosCustomSysprepFilePage', new GosCustomSysprepFilePageComponent());
}

