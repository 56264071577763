(function() {
   'use strict';
   angular
         .module('com.vmware.vsphere.client.host')
         .constant('authConstants', {
            loginType: {
               LOGIN_USING_CREDENTIAL: 'credential',
               LOGIN_USING_CAM_SERVER: 'camServer'
            }
         });
})();
