/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace dvs_ui {

   import DvpgIpQualifierModel = com.vmware.vsphere.client.h5.network.dvportgroup.trafficfilter.model.DvpgIpQualifierModel;

   export class DvpgIpQualifierComponent {

      public bindings: any;
      public controller: any;
      public templateUrl: string;

      constructor() {
         this.bindings = {
            model: "<",
            onValidate: "="
         };

         this.controller = DvpgIpQualifierContoller;

         this.templateUrl = "dvs-ui/resources/dvs/dvpg/trafficfilter/common/" +
               "dvpgIpQualifierTemplate.html";
      }
   }

   export class DvpgIpQualifierContoller {

      static $inject = [
            "i18nService",
            "dvpgTrafficFilterService",
            "dvpgTrafficFilterConstants",
            "dvpgIpQualifierValidator",
            "vxValidatorFactory"
      ];

      public model: DvpgIpQualifierModel;
      public onValidate: any;

      public ipProtocolValidator: any;
      public sourcePortValidator: any;
      public sourcePortEndValidator: any;
      public destPortValidator: any;
      public destPortEndValidator: any;

      public sourceIpValidator: any;
      public sourcePrefixLengthValidator: any;
      public destIpValidator : any;
      public destPrefixLengthValidator: any;

      private i18n: any;
      private constants: any;

      private cosTagPlaceholderText: string;
      private dscpTagPlaceholderText: string;

      private protocolTypes: string[];
      private protocolOperations: string[];

      private portOperations: string[];

      private ipAddressOperations: string[];
      private ipAddressTypes: string[];

      private protocolValidationFunction: Function;
      private showProtocolError: boolean = false;

      private sourcePortValidationFunction: Function;
      private showSourcePortError: boolean = false;

      private sourcePortEndValidationFunction: Function;
      private showSourcePortEndError: boolean = false;

      private destPortValidationFunction: Function;
      private showDestPortError: boolean = false;

      private destPortEndValidationFunction: Function;
      private showDestPortEndError: boolean = false;

      private sourceIpValidationFunction: Function;
      private showSourceIpError: boolean = false;

      private destIpValidationFunction: Function;
      private showDestIpError: boolean = false;

      private sourcePrefixValidationFunction: Function;
      private showSourcePrefixError: boolean = false;

      private destPrefixValidationFunction: Function;
      private showDestPrefixError: boolean = false;

      private showAllErrors: boolean = false;

      constructor(private i18nService: any,
                  private trafficFilterService: any,
                  private trafficFilterConstants: any,
                  private ipQualifierValidator: any,
                  private vxValidatorFactory: any) {
         this.i18n = this.i18nService.getString;
         this.constants = this.trafficFilterConstants;

         this.protocolTypes =
               this.trafficFilterService.getIpQualifierProtocolTypes();
         this.protocolOperations =
               this.trafficFilterService.getQualifiersProtocolOperations();
         this.portOperations =
               this.trafficFilterService.getIpQualifierPortOperations();
         this.ipAddressOperations =
               this.trafficFilterService.getQualifiersAddressOperations();
         this.ipAddressTypes =
               this.trafficFilterService.getIpQualifierIpAddressTypes();

         this.cosTagPlaceholderText = this.trafficFilterConstants.tag.MIN_MAX_COS_TAG;
         this.dscpTagPlaceholderText = this.trafficFilterConstants.tag.MIN_MAX_DSCP_TAG;

         this.protocolValidationFunction = this.validateProtocol.bind(this);

         this.sourcePortValidationFunction = this.validateSourcePort.bind(this);
         this.destPortValidationFunction = this.validateDestPort.bind(this);

         this.sourcePortEndValidationFunction = this.validateSourcePortEnd.bind(this);
         this.destPortEndValidationFunction = this.validateDestPortEnd.bind(this);

         this.sourceIpValidationFunction = this.validateSourceIpAddress.bind(this);
         this.destIpValidationFunction = this.validateDestIpAddress.bind(this);

         this.sourcePrefixValidationFunction =
               this.validateSourcePrefixLength.bind(this);
         this.destPrefixValidationFunction = this.validateDestPrefixLength.bind(this);

         this.initValidators();
      }

      $onInit(): void {
         if (this.onValidate) {
            this.onValidate.validate = this.validate.bind(this);
         }
      }

      public validate(): void {
         this.showAllErrors = true;
         this.ipProtocolValidator.validate();

         this.sourcePortValidator.validate();
         this.sourcePortEndValidator.validate();
         this.destPortValidator.validate();
         this.destPortEndValidator.validate();

         this.sourceIpValidator.validate();
         this.sourcePrefixLengthValidator.validate();
         this.destIpValidator.validate();
         this.destPrefixLengthValidator.validate();
         this.showAllErrors = false;
      }

      private initValidators(): void {
         this.ipProtocolValidator = this.vxValidatorFactory.create();

         this.sourcePortValidator = this.vxValidatorFactory.create();
         this.sourcePortEndValidator = this.vxValidatorFactory.create();
         this.destPortValidator = this.vxValidatorFactory.create();
         this.destPortEndValidator = this.vxValidatorFactory.create();

         this.sourceIpValidator = this.vxValidatorFactory.create();
         this.sourcePrefixLengthValidator = this.vxValidatorFactory.create();
         this.destIpValidator = this.vxValidatorFactory.create();
         this.destPrefixLengthValidator = this.vxValidatorFactory.create();
      }

      public onProtocolChanged(): void {
         if (!this.model.protocol.customValue) {
            this.showProtocolError = false;
         }
         this.ipProtocolValidator.validate();
      }

      public onProtocolBlur(): void {
         this.showProtocolError = true;
         this.ipProtocolValidator.validate();
      }

      private validateProtocol(): string[] {
         if (this.showAllErrors || this.showProtocolError) {
            let error: string =
                  this.ipQualifierValidator.getProtocolError(this.model.protocol);
            return error !== this.trafficFilterConstants.EMPTY ? [error] : [];
         }
         return [];
      }

      public onSourcePortChanged(): void {
         if (!this.model.sourcePort.port) {
            this.showSourcePortError = false;
         }
         if (!this.model.sourcePort.portRangeEnd) {
            this.showSourcePortEndError = false;
         }
         this.sourcePortValidator.validate();
         this.sourcePortEndValidator.validate();
      }

      public onSourcePortBlur(): void {
         this.showSourcePortError = true;
         this.sourcePortValidator.validate();
      }

      private validateSourcePort(): string[] {
         if (this.showAllErrors || this.showSourcePortError) {
            let error: string = this.ipQualifierValidator.getPortError(
                  this.model.sourcePort, true/*isSource*/);
            return error !== this.trafficFilterConstants.EMPTY ? [error] : [];
         }
         return [];
      }

      public onSourcePortEndBlur(): void {
         this.showSourcePortEndError = true;
         this.sourcePortEndValidator.validate();
      }

      private validateSourcePortEnd(): string[] {
         if (this.showAllErrors || this.showSourcePortEndError) {
            let error: string = this.ipQualifierValidator.getPortRangeError(
                  this.model.sourcePort, true/*isSource*/);
            return error !== this.trafficFilterConstants.EMPTY ? [error] : [];
         }
         return [];
      }

      public onDestPortChanged(): void {
         if (!this.model.destinationPort.port) {
            this.showDestPortError = false;
         }
         if (!this.model.destinationPort.portRangeEnd) {
            this.showDestPortEndError = false;
         }
         this.destPortValidator.validate();
         this.destPortEndValidator.validate();
      }

      public onDestPortBlur(): void {
         this.showDestPortError = true;
         this.destPortValidator.validate();
      }

      private validateDestPort(): string[] {
         if (this.showAllErrors || this.showDestPortError) {
            let error: string = this.ipQualifierValidator.getPortError(
                  this.model.destinationPort, false/*isSource*/);
            return error !== this.trafficFilterConstants.EMPTY ? [error] : [];
         }
         return [];
      }

      public onDestPortEndBlur(): void {
         this.showDestPortEndError = true;
         this.destPortEndValidator.validate();
      }

      private validateDestPortEnd(): string[] {
         if (this.showAllErrors || this.showDestPortEndError) {
            let error: string = this.ipQualifierValidator.getPortRangeError(
                  this.model.destinationPort, false/*isSource*/);
            return error !== this.trafficFilterConstants.EMPTY ? [error] : [];
         }
         return [];
      }

      public onSourceIpChanged(): void {
         if (!this.model.sourceIpAddress.ipAddress) {
            this.showSourceIpError = false;
         }
         if (!this.model.sourceIpAddress.prefixLength) {
            this.showSourcePrefixError = false;
         }
         this.sourceIpValidator.validate();
         this.sourcePrefixLengthValidator.validate();
      }

      public onSourceIpBlur(): void {
         this.showSourceIpError = true;
         this.sourceIpValidator.validate();
      }

      private validateSourceIpAddress(): string[] {
         if (this.showAllErrors || this.showSourceIpError) {
            let error: string = this.ipQualifierValidator.getIpAddressError(
                  this.model.sourceIpAddress, true/*isSource*/);
            return error !== this.trafficFilterConstants.EMPTY ? [error] : [];
         }
         return [];
      }

      public onSourcePrefixLengthBlur(): void {
         this.showSourcePrefixError = true;
         this.sourcePrefixLengthValidator.validate();
      }

      private validateSourcePrefixLength(): string[] {
         if (this.showAllErrors || this.showSourcePrefixError) {
            let error: string = this.ipQualifierValidator.getPrefixLengthError(
                  this.model.sourceIpAddress, true/*isSource*/);
            return error !== this.trafficFilterConstants.EMPTY ? [error] : [];
         }
         return [];
      }

      public onDestIpChanged(): void {
         if (!this.model.destinationIpAddress.ipAddress) {
            this.showDestIpError = false;
         }
         if (!this.model.destinationIpAddress.prefixLength) {
            this.showDestPrefixError = false;
         }
         this.destIpValidator.validate();
         this.destPrefixLengthValidator.validate();
      }

      public onDestIpBlur(): void {
         this.showDestIpError = true;
         this.destIpValidator.validate();
      }

      private validateDestIpAddress(): string[] {
         if (this.showAllErrors || this.showDestIpError) {
            let error: string = this.ipQualifierValidator.getIpAddressError(
                  this.model.destinationIpAddress, false/*isSource*/);
            return error !== this.trafficFilterConstants.EMPTY ? [error] : [];
         }
         return [];
      }

      public onDestPrefixLengthBlur(): void {
         this.showDestPrefixError = true;
         this.destPrefixLengthValidator.validate();
      }

      private validateDestPrefixLength(): string[] {
         if (this.showAllErrors || this.showDestPrefixError) {
            let error: string = this.ipQualifierValidator.getPrefixLengthError(
                  this.model.destinationIpAddress, false/*isSource*/);
            return error !== this.trafficFilterConstants.EMPTY ? [error] : [];
         }
         return [];
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .component("dvpgIpQualifier", new DvpgIpQualifierComponent());
}

