(function () {
   'use strict';

   angular.module('com.vmware.vsphere.client.vm')
      .service('DeployVmFromOvfService', DeployVmFromOvfService);

   DeployVmFromOvfService.$inject = [
      'vmProvisioningSelectOvfPageService',
      'vmProvisioningNameAndFolderPageService',
      'VmProvisioningComputeResourcePageService',
      'vmProvisioningProductDetailsPageService',
      'vmProvisioningScaleoutConfigurationPageService',
      'vmProvisioningDatastorePageService',
      'vmProvisioningNetworkPageService',
      'vmProvisioningFinishDeployTemplatePageService',
      'VmProvisioningEulaPageService',
      'VmProvisioningDeploymentOptionsPageService',
      'vmProvisioningCustomizeTemplatePageService',
      'vmProvisioningVservicePageService'
   ];

   function DeployVmFromOvfService(vmProvisioningSelectOvfPageService,
                                   vmProvisioningNameAndFolderPageService,
                                   VmProvisioningComputeResourcePageService,
                                   vmProvisioningProductDetailsPageService,
                                   vmProvisioningScaleoutConfigurationPageService,
                                   vmProvisioningDatastorePageService,
                                   vmProvisioningNetworkPageService,
                                   vmProvisioningFinishDeployTemplatePageService,
                                   VmProvisioningEulaPageService,
                                   VmProvisioningDeploymentOptionsPageService,
                                   vmProvisioningCustomizeTemplatePageService,
                                   vmProvisioningVservicePageService) {
      return function () {
         this.buildPages = buildPages;

         function buildPages(wizardScope, defaultTarget, preselectedComputeResource, preselectedStorage) {
            var selectOvfPage = vmProvisioningSelectOvfPageService.build(
               wizardScope,
               defaultTarget,
               preselectedComputeResource);
            var namePage = vmProvisioningNameAndFolderPageService.build(
               wizardScope,
               defaultTarget);
            var computeResourcePage = VmProvisioningComputeResourcePageService.build(
               wizardScope,
               preselectedComputeResource);
            var deploymentOptions = VmProvisioningDeploymentOptionsPageService.build(
               wizardScope);
            var productDetails = vmProvisioningProductDetailsPageService.build(
               wizardScope,
               preselectedComputeResource);
            var eulaPage = VmProvisioningEulaPageService.build(
               wizardScope);
            var scaleoutPage = vmProvisioningScaleoutConfigurationPageService.build(
               wizardScope,
               defaultTarget,
               preselectedComputeResource
            );
            var storagePage = vmProvisioningDatastorePageService.build(
               wizardScope,
               preselectedStorage);
            var networkPage = vmProvisioningNetworkPageService.build(
               wizardScope);
            var customizeTemplatePage = vmProvisioningCustomizeTemplatePageService.build(
               wizardScope);
            var vservicePage = vmProvisioningVservicePageService.build(
                  wizardScope,
                  defaultTarget,
                  preselectedComputeResource);
            var readyToComplete = vmProvisioningFinishDeployTemplatePageService.build(
               wizardScope,
               preselectedComputeResource);
            return {
               pages: [
                  selectOvfPage,
                  namePage,
                  computeResourcePage,
                  productDetails,
                  eulaPage,
                  deploymentOptions,
                  scaleoutPage,
                  storagePage,
                  networkPage,
                  customizeTemplatePage,
                  vservicePage,
                  readyToComplete
               ]
            };
         }
      };
   }
})();

