namespace h5_vm {

   import VmProvisioningFinishPageModel = h5_vm.VmProvisioningFinishPageModel;
   import IPromise = angular.IPromise;
   import IQService = angular.IQService;
   import VirtualDisk = com.vmware.vim.binding.vim.vm.device.VirtualDisk;
   import VirtualDevice = com.vmware.vim.binding.vim.vm.device.VirtualDevice;
   import DeviceOperationConstants = h5_vm.DeviceOperationConstants;
   import PmemUtilService = h5_vm.PmemUtilService;
   import VirtualDiskPmemService = h5_vm.VirtualDiskPmemService;
   class VmProvisioningFinishCloneVmPageModelFactory {
      public static $inject = [
         "$q",
         "dataService",
         "i18nService",
         "addVmService",
         "vmDeviceInfoService",
         "defaultUriSchemeUtil",
         "creationTypeConstants",
         "bytesFilter",
         "storageProfileService",
         "diskModeService",
         "managedEntityConstants",
         "datastoreRecommendationService",
         "spbmReplicationGroupInfoService",
         "pmemUtilService",
         "virtualDiskPmemService"
      ];

      constructor(
            $q: IQService,
            dataService: any,
            i18nService: any,
            addVmService: any,
            vmDeviceInfoService: any,
            defaultUriSchemeUtil: any,
            creationTypeConstants: any,
            bytesFilter: any,
            storageProfileService: any,
            diskModeService: DiskModeService,
            managedEntityConstants: any,
            datastoreRecommendationService: any,
            spbmReplicationGroupInfoService: any,
            pmemUtilService: PmemUtilService,
            virtualDiskPmemService: VirtualDiskPmemService,
            scheduledTaskData: any) {
         return (virtualMachineSpecBuilder: any, wizardViewData: any, scheduledTaskData: any) => {
            return new VmProvisioningFinishCloneVmPageModel(
                  $q,
                  dataService,
                  i18nService,
                  addVmService,
                  vmDeviceInfoService,
                  defaultUriSchemeUtil,
                  creationTypeConstants,
                  bytesFilter,
                  storageProfileService,
                  diskModeService,
                  managedEntityConstants,
                  datastoreRecommendationService,
                  spbmReplicationGroupInfoService,
                  pmemUtilService,
                  virtualDiskPmemService,
                  virtualMachineSpecBuilder,
                  wizardViewData,
                  scheduledTaskData);
         };
      }
   }

   export class VmProvisioningFinishCloneVmPageModel extends VmProvisioningFinishPageModel {

      private static readonly VIRTUAL_DISK_TYPE: string = "com.vmware.vim.binding.vim.vm.device.VirtualDisk";

      static $inject: string[] = [
         "$q",
         "dataService",
         "i18nService",
         "addVmService",
         "vmDeviceInfoService",
         "defaultUriSchemeUtil",
         "creationTypeConstants",
         "bytesFilter",
         "storageProfileService",
         "diskModeService",
         "managedEntityConstants",
         "datastoreRecommendationService",
         "spbmReplicationGroupInfoService",
         "pmemUtilService",
         "virtualDiskPmemService"
      ];

      constructor($q: IQService,
            private dataService: any,
            i18nService: any,
            addVmService: any,
            vmDeviceInfoService: any,
            defaultUriSchemeUtil: any,
            protected creationTypeConstants: any,
            private bytesFilter: any,
            storageProfileService: any,
            diskModeService: DiskModeService,
            managedEntityConstants: any,
            datastoreRecommendationService: any,
            spbmReplicationGroupInfoService: any,
            private pmemUtilService: PmemUtilService,
            private virtualDiskPmemService: VirtualDiskPmemService,
            virtualMachineSpecBuilder: any,
            wizardViewData: any,
            scheduledTaskData: any) {

         super($q, i18nService, addVmService, vmDeviceInfoService,
               defaultUriSchemeUtil, storageProfileService, diskModeService,
               managedEntityConstants, datastoreRecommendationService,
               spbmReplicationGroupInfoService, creationTypeConstants,
               virtualMachineSpecBuilder, wizardViewData, scheduledTaskData);

      }

      public getSchedulingData(): any {
         return this.scheduledTaskData;
      }

      public resetForm(): void {
         super.resetForm();
         this.form.selectedDiskFormat = this.wizardViewData.getDiskFormatLabel();
      }

      public getSourceObjectInfo(): Object {
         let label: string;
         if (this.creationTypeConstants.isCloningFromATemplate(this.virtualMachineSpecBuilder.getCreationType())) {
            label = this.i18nService.getString("VmUi", "ProvisioningWizardSummaryPage.SourceTemplate");
         } else {
            label = this.i18nService.getString("VmUi", "ProvisioningWizardSummaryPage.SourceVm");
         }

         return {
            name: this.wizardViewData.getSourceObjectName(),
            label: label
         };
      }

      public getTargetObjectInfo(): Object {
         let label: string;

         if (this.creationTypeConstants.isCloningToATemplate(this.virtualMachineSpecBuilder.getCreationType())) {
            label = this.i18nService.getString("VmUi", "ProvisioningWizardSummaryPage.TemplateName");
         } else {
            label = this.i18nService.getString("VmUi", "ProvisioningWizardSummaryPage.VmName");
         }

         return {
            label: label,
            name: this.virtualMachineSpecBuilder.getName()
         };
      }

      public getGosCustomizationSpecName(): string {
         return this.wizardViewData.getCustomizationSpecName();
      }

      public presentDiskFormat(): string {
         return this.wizardViewData.getDiskFormatLabel();
      }

      public presentProvisioningType(): string {
         switch (this.virtualMachineSpecBuilder.getCreationType()) {
            case this.creationTypeConstants.CLONE_TEMPLATE_TO_TEMPLATE:
               return this.i18nService.getString("VmUi", "ProvisioningTypes.CloneTemplateToTemplate.Title");
            case this.creationTypeConstants.CLONE_TEMPLATE_TO_VM:
               return this.i18nService.getString("VmUi", "ProvisioningTypes.DeployFromTemplate.Title");
            case this.creationTypeConstants.CLONE_VM_TO_TEMPLATE:
               return this.i18nService.getString("VmUi", "ProvisioningTypes.CloneVmToTemplate.Title");
            case this.creationTypeConstants.CLONE_VM_TO_VM:
               return this.i18nService.getString("VmUi", "ProvisioningTypes.CloneVmToVm.Title");
            case this.creationTypeConstants.CONVERT_TEMPLATE_TO_VM:
               return this.i18nService.getString("VmUi", "ProvisioningTypes.ConvertTemplateToVm.Title");
            case this.creationTypeConstants.DEPLOY_VM_FROM_VMTX:
               return this.i18nService.getString("VmUi", "ProvisioningTypes.DeployFromVmtx.Title");
         }
         return "";
      }

      public isGosCustomized(): boolean {
         return this.virtualMachineSpecBuilder.getCloneOptions()
               && this.virtualMachineSpecBuilder.getCloneOptions().customizeOs;
      }

      public getNewDisks(): any[] {
         let allNewDisks: any[] = this.getNewDevicesOfType(
               VmProvisioningFinishCloneVmPageModel.VIRTUAL_DISK_TYPE);

         return _.map(allNewDisks, (newDisk: VirtualDisk, index: number) => {

            let diskStorageProps: any = this.virtualMachineSpecBuilder
                  .getStorageForDisk(newDisk.key);
            let diskSpec: any = this.getDevices().findDeviceSpec(newDisk.key);
            let virtualDevice = this.getDevices().getVirtualDevice(newDisk.key);

            let isRDM: boolean = virtualDevice.isRDMDisk();
            let isCreatedFromExistingDisk = virtualDevice.isCreatedFromExistingDisk();

            let diskInfo: any = this.buildCommonDiskInfo(newDisk, index + 1,
                  this.getInflatedDevices(), isRDM, isCreatedFromExistingDisk);

            if (isCreatedFromExistingDisk) {
               diskInfo = _.extend(diskInfo,
                     this.buildExistingDiskInfo(diskSpec.device.backing.fileName));
               return diskInfo;
            }

            if (!isRDM) {
               diskInfo = _.extend(diskInfo, this.buildNewDiskInfo(newDisk, diskStorageProps));
               return diskInfo;
            }

            this.virtualMachineSpecBuilder.getVmConfigEnvironment().then((environment: any) => {
               diskInfo = _.extend(diskInfo, this.buildRdmDiskInfo(environment, diskSpec));
            });

            return diskInfo;
         });
      }

      private buildCommonDiskInfo(newDisk: any, index: number, allVirtualDevices: any[],
            isRDM: boolean, isCreatedFromExistingDisk: boolean): any {
         let diskInfo: any = {
            diskKey: newDisk.key,
            label: this.i18nService
                  .getString("VmUi", "ProvisioningWizardSummaryPage.CreateDisk", index),
            storageProfile: this.getStorageProfileForDisk(newDisk),
            replicationGroupLabel: this.getReplicationGroupLabelForDisk(newDisk),
            isPmemDisk: this.isDiskGoingToPmem(newDisk),
            nodeLabel: this.i18nService
                  .getString("VmUi", "ProvisioningWizardSummaryPage.VdNodeLabel"),
            node: this.getDiskNode(
                  newDisk,
                  allVirtualDevices),
            modeLabel: this.i18nService
                  .getString("VmUi", "ProvisioningWizardSummaryPage.DiskModeLabel"),
            mode: this.getDiskMode(newDisk),
            isRDM: isRDM,
            isCreatedFromExistingDisk: isCreatedFromExistingDisk
         };
         return diskInfo;
      }

      private buildExistingDiskInfo(fileName: string): any {
         let diskInfo: any = {};
         diskInfo.name = this.i18nService
               .getString("VmUi", "ProvisioningWizardSummaryPage.ExistingDisk");
         diskInfo.filePathLabel = this.i18nService
               .getString("VmUi", "vmProvisioningFinishPage.DiskFilePath");
         diskInfo.filePath = fileName;
         return diskInfo;
      }

      private buildNewDiskInfo(newDisk: any, diskStorageProps: any): any {
         let diskInfo: any = {};
         diskInfo.name = this.i18nService
               .getString("VmUi", "ProvisioningWizardSummaryPage.NewDisk");
         diskInfo.capacityLabel = this.i18nService
               .getString("VmUi", "ProvisioningWizardSummaryPage.DiskCapacity");
         diskInfo.capacity = this.bytesFilter(newDisk.capacityInKB, "KB");
         diskInfo.datastoreLabel = this.i18nService
               .getString("VmUi", "ProvisioningWizardSummaryPage.Datastore");
         diskInfo.datastoreInfo = this.getDatastore(diskStorageProps);
         diskInfo.getStorageObject = () => {
            return this.getStorageObject(diskStorageProps);
         };

         return diskInfo;
      }

      private buildRdmDiskInfo(environment: any, diskSpec: any): any {
         let diskInfo: any = {};
         diskInfo.name = this.i18nService
               .getString("VmUi", "ProvisioningWizardSummaryPage.NewRdmDisk");
         diskInfo.targetLunLabel = this.i18nService
               .getString("VmUi", "vmProvisioningFinishPage.DiskTargetLun");
         diskInfo.targetLun = this.getRDMTargetLUNName(diskSpec.device,
               environment.vmConfigEnvironmentPerPool);
         diskInfo.compatibilityLabel = this.i18nService
               .getString("VmUi", "vmProvisioningFinishPage.DiskCompatibilityMode");
         diskInfo.compatibility = this.getRDMCompatibility(diskSpec.device);
         diskInfo.datastoreLabel = this.i18nService
               .getString("VmUi", "vmProvisioningFinishPage.DiskMappedDatastore");
         diskInfo.showStorageRecommendations = () => {
            return false;
         };
         diskInfo.datastoreName = this.getRDMDatastoreName();
         return diskInfo;
      }

      public getExistingDisks(): any[] {
         let allExistingDisks: any[] = this.getExistingDevicesOfType(
               VmProvisioningFinishCloneVmPageModel.VIRTUAL_DISK_TYPE);

         return _.map(allExistingDisks, (existingDisk: VirtualDisk) => {
            return {
               diskKey: existingDisk.key,
               name: existingDisk.deviceInfo.label,
               capacity: this.bytesFilter(existingDisk.capacityInKB, "KB"),
               datastoreInfo: this.getDatastore(),
               getStorageObject: () => {
                  let diskStorageProps: any =
                        this.virtualMachineSpecBuilder.getStorageForDisk(existingDisk.key);
                  return this.getStorageObject(diskStorageProps);
               },
               provisioningType: this.virtualMachineSpecBuilder.getProvisioningTypePerDisk(existingDisk.key),
               storageProfile: this.getStorageProfileForDisk(existingDisk),
               replicationGroupLabel: this.getReplicationGroupLabelForDisk(existingDisk),
               isPmemDisk: this.isDiskGoingToPmem(existingDisk)
            };
         });
      }

      public getNumberOfCpus(): number {
         return this.virtualMachineSpecBuilder.getVmConfigSpec().hardware.numCPU;
      }

      public getAllocatedMemory(): string {
         let memoryMB: number =
            this.virtualMachineSpecBuilder.getVmConfigSpec().hardware.memoryMB;
         let memoryUnitPreferred: any =
            this.virtualMachineSpecBuilder.getVmConfigSpec().hardware.memoryUnitPreferred;

         if (memoryUnitPreferred) {
            return memoryMB / memoryUnitPreferred.multiplier + " " + memoryUnitPreferred.label;
         } else {
            return this.bytesFilter(memoryMB, "MB");
         }
      }

      public shouldDsiplayHwCustomizedProperties(): boolean {
         return this.virtualMachineSpecBuilder.getCreationType() === this.creationTypeConstants.DEPLOY_VM_FROM_VMTX &&
               this.virtualMachineSpecBuilder.getCustomizeCloneHardware();
      }

      public getNetworkInterfaces(): Array<any> {
         let virtualNetworkDevices: Array<any> =
            this.virtualMachineSpecBuilder
               .getUpdatedNetwork();
         return _.map(virtualNetworkDevices, this.buildNetworkInfo.bind(this));
      }

      private buildNetworkInfo(virtualNetworkDevice: any, index: number): Object {
         const deviceNetwork = this.virtualMachineSpecBuilder.getVirtualMachineDevices()
               .getVirtualDevice(virtualNetworkDevice.device.key).network;

         return {
            networkNameLabel: this.i18nService
               .getString("VmUi", "ProvisioningWizardSummaryPage.NicNetwork", index + 1),
            networkName: deviceNetwork.displayName ||
                  this.i18nService.getString("VmUi", "ProvisioningWizardSummaryPage.None"),
            networkTypeLabel: this.i18nService
               .getString("VmUi", "ProvisioningWizardSummaryPage.NicType", index + 1),
            networkType: this.vmDeviceInfoService
               .getEthernetCardLabel(virtualNetworkDevice.device._type)
         };
      }


      public shouldShowDisks(): boolean {
         return this.virtualMachineSpecBuilder.getCustomizeCloneHardware()
            || this.virtualMachineSpecBuilder.getIsAdvancedStorageMode();
      }

      public isAdvancedStorageMode(): boolean {
         return this.virtualMachineSpecBuilder.getIsAdvancedStorageMode();
      }

      public getPmemDiskInfo(): {
            hasPmemDisk: boolean,
            hasRegularDisk: boolean,
            hasDowngradedPmemDisk: boolean } {
         return this.virtualDiskPmemService.getPmemDiskInfo(
               this.virtualMachineSpecBuilder.getStorageSelectorState());
      }

      /**
       * When the customize hardware is turned on, any disks going to pmem will have
       * their backings set to the correct pmem backing.
       * @returns {boolean} when disk is configured as Pmem
       */
      private isDiskGoingToPmem(disk: any): boolean {
         if (this.virtualMachineSpecBuilder.getCustomizeCloneHardware()) {
            return this.pmemUtilService.isPmemDisk(disk);
         } else {
            return this.pmemUtilService.isPMemStoragePolicy(
                  this.getStorageProfileForDisk(disk));
         }
      }

      private getExistingDevicesOfType(type: string): any[] {
         const operations = this.virtualMachineSpecBuilder.getCustomizeCloneHardware()
               ? [DeviceOperationConstants.EDIT]
               : [DeviceOperationConstants.EDIT, DeviceOperationConstants.REMOVE];

         return this.getDevicesOfType(type, operations);
      }

      private getNewDevicesOfType(type: string): any[] {
         if (!this.virtualMachineSpecBuilder.getCustomizeCloneHardware()) {
            return [];
         }

         return this.getDevicesOfType(type, [ DeviceOperationConstants.ADD ]);
      }

      private getDevicesOfType(deviceType: string, operations: string[]): Array<any> {
         let allDevices: Array<any> = [];

         _.forEach(this.getInflatedDevices(), (virtualDevice: any) => {
            let deviceOperation: string = virtualDevice.getCurrentDeviceSpec().operation;
            let device: any = virtualDevice.getCurrentDevice();

            if (device._type === deviceType && _.contains(operations, deviceOperation)) {
               allDevices.push(virtualDevice.getCurrentDevice());
            }
         });

         return allDevices;
      }

      private getDiskNode(device: any, allVirtualDevices: any[]): any {
         let ctrlVirtualDevice: any = _.find(allVirtualDevices, (maybeController: any) => {
            return maybeController.getCurrentDevice().key === device.controllerKey;
         });
         let controller: any = ctrlVirtualDevice.getCurrentDevice();

         return this.vmDeviceInfoService.getControllerAndNodeLabelText(
               controller._type, controller.busNumber, device.unitNumber);
      }

      private getInflatedDevices(): any[] {
         return this.getDevices() ? this.getDevices().getInflatedDevices() : [];
      }

      private getDevices(): any {
         return this.virtualMachineSpecBuilder.getVirtualMachineDevices();
      }
   }

   angular.module("com.vmware.vsphere.client.vm")
         .service("vmProvisioningFinishCloneVmPageModel",
               VmProvisioningFinishCloneVmPageModelFactory);

}
