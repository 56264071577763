/**
 * @description
 *    The `haConfigIssuesConstants` service holds HA configuration issues related constants.
 */
angular.module('com.vmware.vsphere.client.cluster').constant('haConfigIssuesConstants', {
   hostRole : {
      SLAVE : 'slave',
      MASTER : 'master'
   },

   issueCategory: {
      INFO: 'info',
      WARNING: 'warning',
      ERROR: 'error'
   },

   iconClass: {
      NONE: 'vx-icon-issueStatusNone',
      WARNING: 'vx-icon-issueStatusWarning',
      ERROR: 'vx-icon-issueStatusError',
      INFO: 'vx-icon-issueStatusInfo'
   }
});
