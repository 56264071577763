angular.module('com.vmware.vsphere.client.vm').service('deviceDiffService', [
   function() {
      return {
         isUnchanged: isUnchanged
      };

      function isUnchanged(original, modified) {
         if(!_.isObject(original) || !_.isObject(modified)){
            throw new Error("isUnchanged only works with objects");
         }

         var modifiedKeys = _.keys(modified);
         var originalKeys = _.keys(original);
         var difference = _.difference(modifiedKeys, originalKeys);
         if (difference && difference.length > 0) {
            return false;
         }

         var changedField = false;
         _.each(modifiedKeys, function(key) {
            var modifiedValue = modified[key];
            var originalValue = original[key];

            if (areObjects(originalValue, modifiedValue)) {
               if (!isUnchanged(originalValue, modifiedValue)) {
                  changedField = true;
               }
            } else {
               if (modifiedValue !== originalValue) {
                  changedField = true;
               }
            }
         });

         return !changedField;
      }

      function areObjects(original, modified) {
         return _.isObject(modified) && _.isObject(original);
      }
   }
]);
