namespace storage_ui {

   import IRootScopeService = angular.IRootScopeService;
   import IPromise = angular.IPromise;
   import IQService = angular.IQService;

   export class FolderDownloadService {

      private static readonly FOLDER_TYPE: string = "folder";

      public static $inject = ["i18nService", "clarityModalService",
            "clarityConstants", "$q", "datastoreBrowserTreeService", "bytesFilter"];

      constructor(private i18nService: any,
                  private clarityModalService: any,
                  private clarityConstants: any,
                  private $q: IQService,
                  private datastoreBrowserTreeService: any,
                  private bytesFilter: any) {
      }

      /**
       * Displays DownloadFolderDialog while retrieving folder's children.
       */
      public getFolderChildren(datastoreId: string, folderItem: any): IPromise<any> {

         let deferred: any = this.$q.defer();

         let childFileItems: any[] = [];

         let modalOptions: any = {
            title: this.i18nService.getString(
                  "StorageUi", "downloadFolderDialog.title"),
            subTitle: folderItem.formattedName,
            defaultButton: "submit",
            submitButtonTitle: this.i18nService.getString(
                  "StorageUi", "downloadFolderDialog.downloadButton"),
            submitDisabled: true,
            contentTemplate: "storage-ui/resources/storage/views/manage/files/DownloadFolderDialog.html",
            onSubmit: () => {
               deferred.resolve(childFileItems);
               return true;
            },
            onCancel: () => {
               deferred.resolve(null);
               return true;
            }
         };

         modalOptions.dialogData = {
            loading: true,
            loadingText: this.i18nService.getString("StorageUi", "downloadFolderDialog.calculating"),
            confirmationText: ""
         };

         this.clarityModalService.openOkCancelModal(modalOptions);

         let totalFileSize: number = 0;
         let numberOfFolders: number = 0;
         this.datastoreBrowserTreeService.searchResults(datastoreId,  folderItem.path, "")
               .then( (searchResults: any[]) => {
                  _.forEach(searchResults, (fileItem: any) => {
                     if (fileItem.type !== FolderDownloadService.FOLDER_TYPE) {
                        childFileItems.push(fileItem);
                        if (fileItem.size) {
                           totalFileSize += fileItem.size;
                        }
                     } else {
                        numberOfFolders++;
                     }
                  });
               })
               .finally(() => {
                  modalOptions.dialogData.loading = false;
                  if (!childFileItems.length) {
                     modalOptions.dialogData.confirmationText = this.i18nService.getString(
                           "StorageUi", "downloadFolderDialog.emptyFolderMsg");
                     modalOptions.dialogData.icon = "error-standard";
                     modalOptions.dialogData.iconClass = "is-error";
                  } else {
                     modalOptions.dialogData.confirmationText = this.i18nService.getString(
                           "StorageUi", "downloadFolderDialog.folderInfo",
                           childFileItems.length.toString(),
                           numberOfFolders.toString(),
                           this.bytesFilter(totalFileSize, "B"));
                     modalOptions.dialogData.icon = "info-standard";
                     modalOptions.dialogData.iconClass = "is-info";
                     modalOptions.submitDisabled = false;
                  }
               });

         return deferred.promise;
      }
   }

   angular.module("com.vmware.vsphere.client.storage")
         .service("folderDownloadService", FolderDownloadService);
}
