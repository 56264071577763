namespace spbm_ui {

   export class DspListViewController {

      private static readonly CREATE_DSP_ACTION: string = "vsphere.core.pbm.storage.createDspAction";
      private static readonly REMOVE_DSP_ACTION: string = "vsphere.core.pbm.storage.removeDspAction";
      private static readonly EDIT_DSP_ACTION: string = "vsphere.core.pbm.storage.editDspAction";
      private static readonly CLONE_DSP_ACTION: string = "vsphere.core.pbm.storage.cloneDspAction";

      private readonly ACTION_BAR_ACTIONS: string = "actions";

      private selectedProfiles: any[];

      public actionBarOptions: any;

      public static $inject = ["$scope",
            "commonActionBarService",
            "vuiConstants"];

      constructor(private $scope: any,
                  private commonActionBarService: any,
                  private vuiConstants: any) {

         this.$scope.onSelectionChanged = (newItems: any, oldItems: any) => {
            if (newItems === oldItems) {
               return;
            }
            this.selectedProfiles = newItems;
            $scope.masterDetailsViewContext.selectedItems = newItems;
            this.updateActionBar();
         };

         this.actionBarOptions = {
            actions: []
         };
         this.updateActionBar();
      }

      private updateActionBar() {
         if (this.actionBarOptions && this.actionBarOptions.actions && this.actionBarOptions.actions.length) {
            this.actionBarOptions.actions.forEach((action: any) => {
               if (action.actionId && action.actionId !== DspListViewController.CREATE_DSP_ACTION) {
                  action.enabled = false;
               }
            });
         }

         let updateActionBarOptions: any = { actions: [] };
         this.commonActionBarService.updateActionBar(
               updateActionBarOptions,
               this.ACTION_BAR_ACTIONS,
               this.getProfilesIds(),
               this.getActions()).then(() => {
            this.actionBarOptions = updateActionBarOptions;
         });
      }

      private getProfilesIds(): string[] {
         return _.pluck(this.selectedProfiles, "id");
      }

      private getActions() {
         let actionDefs: any[] = [];

         actionDefs.push({
            actionId: DspListViewController.CREATE_DSP_ACTION
         });
         actionDefs.push(this.vuiConstants.actions.SEPARATOR);

         actionDefs.push({
            actionId: DspListViewController.EDIT_DSP_ACTION,
            getActionInvocationContext: () => {
               return this.getProfilesIds();
            },
            isActionVisible: (actionDef: any) => {
               return this.selectedProfiles &&
                      this.selectedProfiles.length === 1 &&
                      actionDef && actionDef.available;
            }
         });

         actionDefs.push({
            actionId: DspListViewController.CLONE_DSP_ACTION,
            getActionInvocationContext: () => {
               return this.getProfilesIds();
            },
            isActionVisible: (actionDef: any) => {
               return this.selectedProfiles &&
                     this.selectedProfiles.length === 1 &&
                     actionDef && actionDef.available;
            }
         });

         actionDefs.push({
            actionId: DspListViewController.REMOVE_DSP_ACTION,
            getActionInvocationContext: () => {
               return this.getProfilesIds();
            },
            isActionVisible: (actionDef: any) => {
               return this.selectedProfiles &&
                      this.selectedProfiles.length &&
                      actionDef && actionDef.available;
            }
         });

         return actionDefs;
      }
   }

   angular.module("com.vmware.vsphere.client.spbm")
         .controller("DspListViewController", DspListViewController);
}
