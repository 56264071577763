/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
module host_ui {
   import IController = angular.IComponentController;

   export class HardwareMemoryComponent {
      public templateUrl: string;
      public controller: any;
      public controllerAs: string;
      public bindings: any;

      constructor() {
         this.templateUrl = "host-ui/resources/host/views/settings/memory/hardwareMemoryComponent.html";
         this.controller = HardwareMemoryController;
         this.controllerAs = "ctrl";
         this.bindings = {
            hostId: "<"
         };
      }

   } // HardwareMemoryComponent

   export class HardwareMemoryController implements angular.IComponentController  {
      public hostId: string;
      public memoryHeaderOptions: any;
      public memorySettingsViewOptions: any;
      public pmemHeaderOptions: any;
      public pmemSettingsViewOptions: any;
      public isPmemEnabled: boolean;

      static $inject = [
         "hardwareMemoryService",
         "featureFlagsService"
      ];

      constructor(private hardwareMemoryService: any,
                  private featureFlagsService: any) {
      }

      public $onInit() {
         let memorySettingsObject = this.hardwareMemoryService.getMemorySettingsOptions(this.hostId);
         this.memoryHeaderOptions = memorySettingsObject.headerOptions;
         this.memorySettingsViewOptions = memorySettingsObject.settingsViewOptions;

         let pmemSettingsObject = this.hardwareMemoryService.getPmemSettingsOptions(this.hostId);
         if (pmemSettingsObject){
             this.pmemHeaderOptions = pmemSettingsObject.headerOptions;
             this.pmemSettingsViewOptions = pmemSettingsObject.settingsViewOptions;
             this.isPmemEnabled = this.featureFlagsService.PMemEnabled();
         }
      }
   } // HardwareMemoryController

   angular.module("com.vmware.vsphere.client.host")
         .component("hardwareMemoryComponent", new HardwareMemoryComponent());

}
