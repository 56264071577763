namespace h5_client {
   export class ManagedEntityConstants {
      static readonly ANY_COMPUTE_RESOURCE: string = "AnyComputeResource";
      static readonly COMPUTE_RESOURCE: string = "ComputeResource";
      static readonly CLUSTER: string = "ClusterComputeResource";
      static readonly DATACENTER: string = "Datacenter";
      static readonly DATASTORE: string = "Datastore";
      static readonly FOLDER: string = "Folder";
      static readonly HOST: string = "HostSystem";
      static readonly HOST_PROFILE: string = "HostProfile";
      static readonly RESOURCE_POOL: string = "ResourcePool";
      static readonly STORAGE_POD: string = "StoragePod";
      static readonly V_APP: string = "VirtualApp";
      static readonly VIRTUAL_MACHINE: string = "VirtualMachine";
      static readonly NETWORK: string = "Network";
      static readonly DISTRIBUTED_VIRTUAL_PORTGROUP: string = "DistributedVirtualPortgroup";
      static readonly OPAQUE_NETWORK: string = "OpaqueNetwork";
      static readonly VM_TEMPLATE: string = "vm-template";
   }

   angular.module("com.vmware.vsphere.client.commonModule")
         .constant("managedEntityConstants", ManagedEntityConstants);
}
