namespace h5_vm {

   import VmConfigContext = com.vmware.vsphere.client.vm.config.VmConfigContext;
   import VmHardwareUtil = h5_vm.VmHardwareUtil;
   import VirtualDevice = com.vmware.vim.binding.vim.vm.device.VirtualDevice;
   import VirtualMachine$PowerState = com.vmware.vim.binding.vim.VirtualMachine$PowerState;
   import VirtualVideoCardOption = com.vmware.vim.binding.vim.vm.device.VirtualVideoCardOption;
   import VirtualDeviceOption = com.vmware.vim.binding.vim.vm.device.VirtualDeviceOption;
   import GuestOsDescriptor = com.vmware.vim.binding.vim.vm.GuestOsDescriptor;
   import PciSharedGpuPassthroughInfo = com.vmware.vim.binding.vim.vm.PciSharedGpuPassthroughInfo;
   import ConfigTarget = com.vmware.vim.binding.vim.vm.ConfigTarget;
   import ConfigInfo = com.vmware.vim.binding.vim.vm.ConfigInfo;
   import ResourceAllocationInfo = com.vmware.vim.binding.vim.ResourceAllocationInfo;
   import PciPassthroughInfo = com.vmware.vim.binding.vim.vm.PciPassthroughInfo;
   import VirtualPCIPassthroughOption = com.vmware.vim.binding.vim.vm.device.VirtualPCIPassthroughOption;
   import Capability = com.vmware.vim.binding.vim.vm.Capability;
   import VmConfigEnvironment = com.vmware.vsphere.client.vm.config.VmConfigEnvironment;
   import SerialInfo = com.vmware.vim.binding.vim.vm.SerialInfo;
   import FlagInfo = com.vmware.vim.binding.vim.vm.FlagInfo;
   import VirtualHardwareOption = com.vmware.vim.binding.vim.vm.VirtualHardwareOption;

   export interface VmRecommendation {
      name: string;
      value: number;
      formattedValue?: string;
   }

   export class VmConfig {
      public showMsg: Function;

      private powerState: VirtualMachine$PowerState;
      private environment: VmConfigEnvironment;
      private config: ConfigInfo;
      private originalRAM: number = 0;

      constructor(private vmConfigContext: VmConfigContext, private guest: GuestOsDescriptor, private privileges: any, private workflow: VmWorkflowMode, private i18nService: any, private removeDevice: Function | null = null) {

         this.environment = this.vmConfigContext.environment;

         if (!this.environment) {
            throw new Error("Class VmConfig missing config environment");
         }
         this.config = vmConfigContext.config;

         if (this.config && this.config.hardware) {
            this.originalRAM = this.config.hardware.memoryMB;
         }

         this.powerState = this.environment.powerState;
         this.removeInvalidCPUs();
      }

      private removeInvalidCPUs(): void {
         if (!this.environment.configOption) {
            return;
         }
         if (!this.guest) {
            return;
         }

         let options: VirtualHardwareOption = this.environment.configOption.hardwareOptions;

         if (!options) {
            return;
         }

         const maxCores = this.guest.numSupportedCoresPerSocket;
         const maxSockets = this.guest.numSupportedPhysicalSockets;

         const newCPU: Array<number> = [];

         // preserve original values in case user selects different guest
         if (!(options as any).cpuBackup) {
            (options as any).cpuBackup = options.numCPU.concat();
         }
         const oldCPU: Array<number> = (options as any).cpuBackup;

         for (let cpus of oldCPU) {
            for (var cores = 1; cores <= maxCores; cores++) {

               const modulo: number = cpus % cores;

               if (modulo !== 0) {
                  continue;
               }
               const sockets: number = cpus / cores;

               if (sockets > maxSockets) {
                  continue;
               } else {
                  newCPU.push(cpus);
                  break;
               }
            }
         }
         options.numCPU = newCPU;
         return;
      }

      public getGuestOsDescriptror(): GuestOsDescriptor {
         return this.guest;
      }

      public getVmConfigContext(): VmConfigContext {
         return this.vmConfigContext;
      }

      public getI18nService(): any {
         return this.i18nService;
      }

      private i18n(key: string): string {
         return this.i18nService.getString("VmUi", key);
      }

      public get grantedConfigSettings(): boolean {
         if (!this.privileges) {
            return true;
         }
         let retVal = h5_vm.VmHardwareUtil.hasPrivileges(this.privileges, [h5_vm.Privileges.VM_CONFIG_SETTINGS]);
         return retVal;
      }

      public get grantedEditDevice(): boolean {
         if (!this.privileges) {
            return true;
         }
         let retVal = h5_vm.VmHardwareUtil.hasPrivileges(this.privileges, [h5_vm.Privileges.VM_EDITDEVICE_PRIVILEGE]);
         return retVal;
      }

      public get grantedRemoveDevice(): boolean {
         if (!this.privileges) {
            return true;
         }
         let retVal = h5_vm.VmHardwareUtil.hasPrivileges(this.privileges, [h5_vm.Privileges.VM_ADDREMOVEDEVICE]);
         return retVal;
      }

      public get grantedDeviceConnection(): boolean {
         if (!this.privileges) {
            return true;
         }
         let retVal = h5_vm.VmHardwareUtil.hasPrivileges(this.privileges, [h5_vm.Privileges.VM_INTERACT_DEVICE_CONNECTION]);
         return retVal;
      }

      public canRemoveDevice(option: VirtualDeviceOption | null): boolean {


         if (this.isUpdate) {

            if (this.suspended) {
               return false;
            }

            let granted: boolean = this.grantedRemoveDevice;

            if (!granted) {
               return false;
            }

            let hotRemoveSupported: boolean = option ? option.hotRemoveSupported : true;
            if (this.poweredOn && !hotRemoveSupported) {
               return false;
            }
            return true;
         }

         if (this.isClone) {
            let granted: boolean = this.grantedRemoveDevice;
            return granted;
         }

         return true;

      }

      public getVideoCardOption(): VirtualVideoCardOption | null {

         let options: Array<any> = this.getVirtualDeviceOption();

         if (!options) {
            return null;
         }

         let match: Function = function (item: VirtualDeviceOption) {
            //TODO wsdlName is wrong, type.name or type.typeClass? - check java docs
            // Turned out wsldName is VirtualMachineVideoCard, not VirtualVideoCard
            return item.type.name === "com.vmware.vim.binding.vim.vm.device.VirtualVideoCard";
         };

         let option = _.find(options, match);
         let retVal: VirtualVideoCardOption = option as VirtualVideoCardOption;
         return retVal;
      }

      private get configTarget(): ConfigTarget {
         let retVal: ConfigTarget = this.environment.configTarget;
         return retVal;
      }

      public getGpuProfileList(): Array<PciSharedGpuPassthroughInfo> {
         let retVal: Array<PciSharedGpuPassthroughInfo> = this.configTarget.sharedGpuPassthroughTypes;
         return retVal;
      }

      public getSerialPortList(): SerialInfo[] {
         let retVal: Array<SerialInfo> = this.configTarget.serial;
         if (!retVal) {
            retVal = [];
         }
         return retVal;
      }

      public getPciPassList(): Array<PciPassthroughInfo> {
         let retVal: Array<PciPassthroughInfo> = this.configTarget.pciPassthrough;
         return retVal;
      }

      private getVirtualDeviceOption(): Array<any> {
         if (!this.environment || !this.environment.configOption) {
            return [];
         }

         return this.environment.configOption.hardwareOptions.virtualDeviceOption;
      }

      public get poweredOn(): boolean {
         return this.powerState === 'poweredOn';
      }

      public get poweredOff(): boolean {
         return this.powerState === 'poweredOff';
      }

      public get suspended() {
         return this.powerState === 'suspended';
      }

      public get isCreate(): boolean {
         return this.workflow === VmWorkflowMode.CreateMode;
      }

      public get isUpdate(): boolean {
         return this.workflow === VmWorkflowMode.UpdateMode;
      }

      public get isClone(): boolean {
         return this.workflow === VmWorkflowMode.CloneMode;
      }

      public isEditDeviceDisabled(hotEditable: boolean = false): boolean {

         const DISABLED: boolean = true;
         const enabled: boolean = false;


         if (this.isCreate) {
            return enabled;
         }

         if (this.isUpdate) {
            if (!this.grantedEditDevice) {
               return DISABLED;
            }
            if (this.poweredOff) {
               return enabled;
            } else if (this.suspended) {
               return DISABLED;
            } else if (this.poweredOn) {
               if (hotEditable) {
                  return enabled;
               } else {
                  return DISABLED;
               }
            }
         }

         if (!this.grantedEditDevice) {
            return DISABLED;
         }
         return enabled;
      }

      public memoryUnderReserved(): boolean {

         if (!this.config) {
            return false;
         }

         if (!this.config.memoryAllocation) {
            return true;
         }

         let allocation: ResourceAllocationInfo = this.config.memoryAllocation;
         let memoryMB: number = this.config.hardware.memoryMB;

         if (memoryMB > allocation.reservation) {
            return true;
         }

         return false;
      }

      private getVmCaps(): Capability {
         let retVal = {};
         if (this.environment && this.environment.configOption) {
            return this.environment.configOption.capabilities;
         } else {
            return {} as Capability;
         }
      }

      // Memory section begins

      public get memoryMB(): number {
         return this.config.hardware.memoryMB;
      }

      public set memoryMB(value: number) {
         this.config.hardware.memoryMB = value;
      }

      readonly BIOS_LIMIT_MB: number = 6128 * 1024;

      public validateMemoryMB(value: number): { error: string; valid: boolean } {
         const OK = {error: "", valid: true};

         if (!value) {
            return OK;
         }

         if (false === this.isCreate) {
            // Don't validate existing value unless new VM
            // Since currently there is no easy way
            // to both show memory error and enable OK
            if (this.originalRAM === value) {
               return OK;
            }
         }

         const os: GuestOsDescriptor = this.getGuestOsDescriptror();

         if (os) {
            const max: number = os.supportedMaxMemMB;
            if (value > max) {
               let maxError: string = this.i18n("VmMemory.GuestMaxError");
               maxError += " " + os.fullName;
               return {error: maxError, valid: false};
            }
         }

         if (true === this.isEfiConfigured()) {
            return OK;
         }

         if (value > this.BIOS_LIMIT_MB) {
            let biosError: string = this.i18n("VmMemory.BiosMaxError");
            return {error: biosError, valid: false};
         }

         return OK;
      }

      getMemoryRecommendations(): VmRecommendation[] {
         const os: GuestOsDescriptor = this.getGuestOsDescriptror();
         if (!os) {
            return [];
         }
         const mbLabel = this.i18n("VmMemory.MB");
         const gbLabel = this.i18n("VmMemory.GB");
         const maxLabel = this.i18n("VmMemory.GuestOSmax");
         const minLabel = this.i18n("VmMemory.GuestOSmin");

         const format = function (memMB: number) {
            if (memMB > 1024) {
               var value = memMB / 1024;
               return value.toString() + " " + gbLabel;

            }
            return memMB.toString() + mbLabel;
         };

         const min: number = os.supportedMinMemMB;
         const max: number = os.supportedMaxMemMB;

         let retVal = [
            {name: os.fullName, value: this.memoryMB, formattedValue: " "},
            {name: maxLabel, value: max, formattedValue: format(max)},
            {name: minLabel, value: min, formattedValue: format(min)}
         ];
         return retVal;
      }

      public reserveAllMemory(): void {

         if (!this.config) {
            return;
         }

         if (!this.config.memoryAllocation) {
            this.config.memoryAllocation = new ResourceAllocationInfo();
         }

         let allocation: ResourceAllocationInfo = this.config.memoryAllocation;
         let memoryMB: number = this.config.hardware.memoryMB;

         allocation.reservation = memoryMB;

         if (this.getVmCaps().memoryReservationLockSupported) {
            this.config.memoryReservationLockedToMax = true;
         }
      }
      public canReserveAllMemory(): boolean {

         if (this.isUpdate) {
            let granted: boolean = VmHardwareUtil.hasPrivileges(this.vmConfigContext.privileges, [h5_vm.Privileges.VM_CONFIG_SETTINGS]);

            if (!granted) {
               return false;
            }
            return true;

         } else if (this.isCreate) {
            return true;
         } else if (this.isClone) {
            return true;
         }

         return true;
      }

      // Memory section ends

      // CPU section begins

      public get cpu(): number {
         const retVal: number = this.config.hardware.numCPU;
         return retVal;
      }

      private static readonly ONE_TWENTY_EIGHT:number = 128;

      public getBiosCpuLimit() {
         return VmConfig.ONE_TWENTY_EIGHT;
      }

      public isBigCpu(): boolean {
         if (this.cpu > VmConfig.ONE_TWENTY_EIGHT) {
            return true;
         }
         return false;
      }

      public isCpuHotplugError(originalConfig:ConfigInfo): boolean {
         if (!this.isUpdate) {
            return false;
         }
         if (false === this.poweredOn) {
            return false;
         }
         const oldCpu: number = originalConfig.hardware.numCPU;

         if (this.cpu === oldCpu) {
            return false;
         }

         if (oldCpu > this.getBiosCpuLimit()) {
            return false; // we above 128 already, good
         }

         if (this.cpu > this.getBiosCpuLimit()) {
            return true; // report error
         }
         return false; // no error
      }

      public isCpuFirmwareError(): boolean {
         if (this.isEfiConfigured()) {
            return false;
         }
         if (this.cpu > this.getBiosCpuLimit()) {
            return true;
         }
         return false;
      }

      private readonly FIRMWARE_EFI: string = "efi";

      public get firmware(): string {
         const retVal = this.config.firmware;
         return retVal;
      }

      public afterFirmwareChanged() {
         const cpu: number = this.cpu;
         if (cpu > this.getBiosCpuLimit()) {
            this.ensureBigCpu();
         }
      }

      public isFirmwareLocked(): boolean {
         const retVal: boolean = this.isEfiConfigured() && this.isEfiRequired();
         return retVal;
      }

      public isEfiRequired(): boolean {
         const numCPU: number = this.cpu;
         if (numCPU > this.getBiosCpuLimit()) {
            return true;
         }
         if (this.memoryMB > this.BIOS_LIMIT_MB) {
            return true;
         }
         return false;
      }

      private configureEfi(): void {
         this.config.firmware = this.FIRMWARE_EFI;
         return;
      }

      public isEfiConfigured(): boolean {
         if (this.firmware === this.FIRMWARE_EFI) {
            return true;
         } else {
            return false;
         }
      }

      // VVTD section (aka IO MMU)

      public get vvtd():boolean {
         return this.config.flags.vvtdEnabled;
      }

      public set vvtd(value:boolean) {
         this.config.flags.vvtdEnabled = value;
      }

      public isVvtdEnabled(): boolean {
         let flags: any = this.config.flags;
         if (true === flags.vvtdEnabled) {
            return true;
         }
         return false;
      }

      public isVvtdRequired(): boolean {
         let flags: any = this.config.flags;
         if (flags && true === flags.vbsEnabled) {
            return true;
         }

         if (this.cpu > this.getBiosCpuLimit()) {
            return true;
         }
         return false;
      }

      private isBadPowerState(): boolean {
         if (this.isCreate) {
            return false;
         }
         if (this.isClone) {
            return false;
         }

         if (this.isUpdate) {
            if (this.poweredOn) {
               return true;
            }
            if (this.suspended) {
               return true;
            }
         }
         return false;
      }

      private isBadUser(): boolean {
         if (this.isCreate) {
            return false;
         }
         if (this.isClone) {
            return false;
         }

         if (this.isUpdate) {
            if (false === this.grantedConfigSettings) {
               return true;
            }
         }
         return false;
      }

      public isConfigReadonly(): boolean {
         if (this.isBadPowerState()) {
            return true;
         }

         if (this.isBadUser()) {
            return true;
         }
         return false;
      }

      public isVvtdReadonly(): boolean {
         if (this.isVvtdEnabled() && this.isVvtdRequired()) {
            return true;
         }

         if (this.isConfigReadonly()) {
            return true;
         }
         return false;
      }

      public isVvtdEnforced(): boolean {
         if (this.isVvtdEnabled() && this.isVvtdRequired()) {
            return true;
         }

         return true;
      }

      public isVbsConfigured() {
         if (!this.config) {
            return false;
         }
         const flags: FlagInfo = this.config.flags;
         if (!flags) {
            return false;
         }
         if (true === flags.vbsEnabled) {
            return true;
         }
         return false;
      }

      public whyVvtdRequired(): string {
         const reasonCpu: boolean = this.isBigCpu();
         const reasonVbs:boolean = this.isVbsConfigured();

         if (!reasonCpu && !reasonVbs) {
            return "";
         }

         const textCPU: string = this.i18n('VmCpu.iommu.DescriptionCpu128');
         const textVBS: string = this.i18n('VmCpu.iommu.Description');
         const textCPUVSB: string = textVBS + "<br>" + textCPU;

         if (reasonCpu && reasonVbs) {
            return textCPUVSB;
         }
         if (reasonCpu) {
            return textCPU;
         }
         if (reasonVbs) {
            return textVBS;
         }
         return "";
      }

      private enableVvtd(): void {
         let flags: any = this.config.flags;
         flags.vvtdEnabled = true;
         return;
      }

      public afterCpuChange() {
         const cpu: number = this.cpu;
         if (cpu > this.getBiosCpuLimit()) {
            this.ensureBigCpu();
         }
      }

      private firmwareDoesNotSupportBigCpu(): boolean {
         if (this.isEfiConfigured()) {
            return false;
         }
         return true;
      }

      private ensureBigCpu(): void {
         let isEfiForced: boolean = false;
         let isVvtdForced: boolean = false;

         if (this.isUpdate) {
            if (!this.poweredOff) {
               return;
            }
            if (this.firmwareDoesNotSupportBigCpu()) {
               return;
            }
         }

         if (this.isClone && this.firmwareDoesNotSupportBigCpu()) {
            return;
         }

         if (this.isCreate && false === this.isEfiConfigured()) {
            this.configureEfi();
            isEfiForced = true;
         }
         if (false === this.isVvtdEnabled()) {
            this.enableVvtd();
            isVvtdForced = true;
         }

         if (isEfiForced && isVvtdForced) {
            this.showI18nMsg("VmCpu.EFI_VVTD_FORCED");
         } else if(isEfiForced) {
            this.showI18nMsg("VmCpu.EFI_FORCED");
         } else if(isVvtdForced) {
            this.showI18nMsg("VmCpu.VVTD_FORCED");
         }
         return;
      }

      private showI18nMsg(key:string) {
         const msg:string = this.i18n(key);
         if (this.showMsg) {
            this.showMsg(msg);
         }
      }
      // CPU section ends

      public getPciPassthoughOption(): VirtualPCIPassthroughOption | null {

         let options: Array<any> = this.getVirtualDeviceOption();
         if (!options) {
            return null;
         }

         const prefix: string = "com.vmware.vim.binding.vim.vm.device.";
         const type_name: string = prefix + "VirtualPCIPassthrough";

         let match: Function = function (item: VirtualDeviceOption) {
            return item.type.name === type_name;
         };

         let option = _.find(options, match);
         let retVal: VirtualPCIPassthroughOption = option as VirtualPCIPassthroughOption;
         return retVal;
      };

      public getDeviceOption(device: VirtualDevice): VirtualDeviceOption | null {

         if (!device) {
            return null;
         }

         const device_type: string = device._type;

         let options: Array<VirtualDeviceOption> = this.getVirtualDeviceOption();
         if (!options) {
            return null;
         }

         for (const item of options) {
            if (!item) {
               continue;
            }

            const typeClass: string = (<any> item.type).typeClass;
            if (typeClass === device_type) {
               return item;
            }

         }
         return null;
      }

      public remove(device: EditableVirtualDeviceClass) {
         if (this.removeDevice) {
            this.removeDevice(device);
         }
      }

      public deviceRemoveEnabled(hotRemoveSupported: boolean = false): boolean {
         if (this.isCreate) {
            return true;
         }

         if (this.isUpdate) {
            if (false === this.grantedRemoveDevice) {
               return false;
            }
            if (this.poweredOff) {
               return true;
            } else if (this.suspended) {
               return false;
            } else if (this.poweredOn) {
               return hotRemoveSupported;
            }
         } else if (this.isClone) {
            if (false === this.grantedRemoveDevice) {
               return false;
            }
         }

         return true;
      }

      public isConnectDeviceDisabled(): boolean {
         if (this.isCreate) {
            return false;
         } else if (this.isUpdate) {
            if (!this.grantedDeviceConnection) {
               return true;
            }
            if (this.suspended) {
               return true;
            }
         } else if (this.isClone) {
            return !this.grantedDeviceConnection;
         }
         return false;
      }

      public debugPermissions(array: string[]) {
         this.privileges = array;
      }

      public debugNoPermissions() {
         this.privileges = [];
      }

      private debugPowerState(value: h5_vm.PowerState) {
         this.powerState = <VirtualMachine$PowerState> value;
      }

      public debugPoweredOff() {
         this.debugPowerState(h5_vm.PowerState.POWERED_OFF);
      }

      public debugPoweredOn() {
         this.debugPowerState(h5_vm.PowerState.POWERED_ON);
      }

      public debugSuspended() {
         this.debugPowerState(h5_vm.PowerState.SUSPENDED);
      }

      private debugWorkflow(value: VmWorkflowMode) {
         this.workflow = value;
      }

      public debugCloneVm(): void {
         this.debugWorkflow(VmWorkflowMode.CloneMode);
      }

      public debugNewVm(): void {
         this.debugWorkflow(VmWorkflowMode.CreateMode);
      }

      public debugEditVm(): void {
         this.debugWorkflow(VmWorkflowMode.UpdateMode);
      }
   }
}
