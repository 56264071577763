namespace h5_vm {
   import DeviceTypeConstants = h5_vm.DeviceTypeConstants;

   class SataControllerService {
      public static $inject: string[] = ['deviceService', 'deviceClassLineageService', 'i18nService'];

      constructor(private deviceService: any, private deviceClassLineageService: any, private i18nService: any) {
      }

      public makeDefaultSataController(deviceOptions: any,
                                       virtualMachineDevices: any): { device: { _type: string, key: number } } {
         let sataSupportIndex: number = this.getSATASupport(deviceOptions);
         if (sataSupportIndex < 0) {
            throw new Error(this.i18nService.getString('VmUi', 'VmDeviceManager.DeviceNotSupported'));
         }
         let deviceControllerType: any = deviceOptions[sataSupportIndex].type;

         let availableBusNumber: number = this.findAvailableBusNumber(virtualMachineDevices);
         if (availableBusNumber >= 0) {
            var device = this.deviceService.makeController(
                  deviceOptions, deviceControllerType, availableBusNumber);
            return {
               device: device
            };
         }
         else {
            throw new Error(this.i18nService.getString('VmUi', 'VmDeviceManager.DeviceLimitReached'));
         }
      }

      private getSATASupport(deviceOptions: Array<any>) {
         let index: number = _.findIndex(deviceOptions, (option: any) => {
            return this.deviceClassLineageService.isClassNameSubclassOf(option.type.wsdlName,
                DeviceTypeConstants.VIRTUAL_SATA_CONTROLLER);
         });
         return index;
      }

      private findAvailableBusNumber(virtualMachineDevices: any): number {
         let allSataControllers = virtualMachineDevices.existingDevicesOfType(DeviceTypeConstants.VIRTUAL_SATA_CONTROLLER);
         allSataControllers = allSataControllers.concat(virtualMachineDevices
               .newDevicesOfType(DeviceTypeConstants.VIRTUAL_SATA_CONTROLLER));
         for (let busNumber = 0; busNumber <= 3; busNumber++) {
            if (!this.findController(busNumber, allSataControllers)) {
               return busNumber;
            }
         }
         return -1;
      }

      private findController(busNumber: number, allSataControllers: Array<any>): number {
         return _.find(allSataControllers, (controller) => {
            return controller.getCurrentDevice().busNumber === busNumber;
         });
      }
   }

   angular.module('com.vmware.vsphere.client.vm')
         .service('sataControllerService', SataControllerService);
}
