namespace h5_vm {
   import VmConfigContext = com.vmware.vsphere.client.vm.config.VmConfigContext;
   import VmHardwareUtil = h5_vm.VmHardwareUtil;
   import DeviceTypeConstants = h5_vm.DeviceTypeConstants;
   export class VmHardwareUsbControllerUtil {

      public static GetUsb20VmodlType(): string {
         return "com.vmware.vim.binding.vim.vm.device.VirtualUSBController";
      }

      public static GetUsb30VmodlType(): string {
         return "com.vmware.vim.binding.vim.vm.device.VirtualUSBXHCIController";
      }

      public static isUnsupportedByGuest(inflatedDevice: any, guest: any): boolean {

         let devType: string = inflatedDevice.getCurrentDevice()._type;
         let list = guest.supportedUSBControllerList;

         if (!_.find(list, (item: any) => item.typeClass === devType)) {
            return true;
         } else {
            return false;
         }
      }

      public static GetUsbTypeI18nKey(type: string): string {
         if (type === h5_vm.VmHardwareUsbControllerUtil.GetUsb20VmodlType()) {
            return 'USBController.USB20';
         }
         if (type === h5_vm.VmHardwareUsbControllerUtil.GetUsb30VmodlType()) {
            return ('USBController.USB30');
         }
         return "";
      }

      public static isRemovalDisabled(inflatedUSBcontroller: any, virtualMachineDevices: any, ctx: VmConfigContext, workflow: VmWorkflowMode): boolean {

         const updateVm: boolean = workflow === VmWorkflowMode.UpdateMode;

         if (!inflatedUSBcontroller) {
            return true; // DISABLE removal
         }


         let rawDevice: any = inflatedUSBcontroller.getCurrentDevice();

         if (!rawDevice) {
            return true; // DISABLE removal
         }

         if (!inflatedUSBcontroller.isNew()) {
            if (!VmHardwareUtil.userHasAddRemoveDevicePermission(ctx)) {
               return true; // DISABLE removal
            }
         }

         if (updateVm && VmHardwareUtil.isSuspended(ctx)) {
            return true; // DISABLE removal for suspended VM (editing)
            // Note: When cloning suspended VM, we could remove devices,
            // because result of cloning is powered-off VM
         }

         if (VmHardwareUsbControllerUtil.hasAttachedDevices(rawDevice)) {
            // So, if there are elements there,
            // this would be existing controller with existing USB devices assigned to this controller.

            // Even if we are about to remove USB devices assigned to this USB controller,
            // we'd rather wait till reconfigure actually remove all those devices and ensure that controller gets empty.

            // We might allow removal of USB controller with devices still on it, if devices also marked for removal,
            // but we normally not doing this in UI.
            // Backend actually allows removal of controller with devices attached
            // and it might be OK to allow this in UI when VM is powered off.

            return true; // disable removal
         }

         // we get here if we don't have any pre-existing USB devices on ths USB controller

         let newUSBList: Array<any> = virtualMachineDevices.newDevicesOfType(DeviceTypeConstants.VIRTUAL_USB);

         if (!newUSBList || newUSBList.length === 0) {
            return false; // ENABLE removal, as we dont' have any new devices.
         }

         // We have some new USB devices, so we need to keep at least one USB controller:
         // either USB 2.0 or USB 3.0

         if (VmHardwareUsbControllerUtil.isSecondUSBControllerAvailable(rawDevice, virtualMachineDevices)) {
            return false; // ENABLE removal, as we have alternate USB controller to host new USB devices
         }

         return true; // DISABLE removal as we adding USB devices and there is no backup USB controller
      }

      private static isSecondUSBControllerAvailable(rawDevice: any, virtualMachineDevices: any): boolean {

         // It's possible to have one USB 2.0 controller and one USB 3.0 controller.

         let usb20type = VmHardwareUsbControllerUtil.GetUsb20VmodlType();
         let usb30type = VmHardwareUsbControllerUtil.GetUsb30VmodlType();

         // typeA is type of "this one" of rawDevice

         let typeA: String = rawDevice._type;

         // typeB is "the other one", i.e. if current one is USB 3.0,
         //  there other one is USB 20 and another way around

         let typeB: String = typeA === usb20type ? usb30type : usb20type;

         let typeBList: Array<any> = virtualMachineDevices.devicesNotMarkedForRemovalOfType(typeB);

         if (typeBList && typeBList.length > 0) {
            return true;
         }

         return false;

      }

      private static hasAttachedDevices(rawUSBController: any) {

         if (!rawUSBController) {
            return false;
         }

         if (!rawUSBController.device) {
            return false;
         }

         // vim.vm.VirtualDevice.device is array containing keys of devices
         // attached to this controller.
         // In case of USB controller we don't modify this array (hopefully).

         let existingUsbDevicesKeys: Array<any> = rawUSBController.device;

         if (!existingUsbDevicesKeys) {
            return false;
         }

         if (rawUSBController.device.length > 0) {
            return true;
         }

         return false;
      }

      public static areBothUSBControllerMarkedForRemoval(virtualMachineDevices: any) {

         let usb20removal: Array<any> = virtualMachineDevices.devicesMarkedForRemovalOfType(DeviceTypeConstants.VIRTUAL_USB_CONTROLLER);
         let usb30removal: Array<any> = virtualMachineDevices.devicesMarkedForRemovalOfType(DeviceTypeConstants.VIRTUAL_USB_XHCI_CONRTOLLER);

         if (!usb20removal || !usb30removal) {
            return false;
         }

         if (usb20removal.length > 0 && usb30removal.length > 0) {
            return true;
         }
         return false;
      }
   }
}
