namespace h5_vm {
   import CustomizeGosSpecData = com.vmware.vsphere.client.h5.vm.data.CustomizeGosSpecData;
   import ValidationResult = com.vmware.vise.core.model.ValidationResult;
   import VmCustomizationValidationResult = com.vmware.vsphere.client.vm.VmCustomizationValidationResult;

   export class CustomizeGosSelectSpecPageService {
      static $inject = ["mutationService"];

      constructor(private mutationService: any) {
      }

      validateSpec(vmId: string, specName: string): IPromise<VmCustomizationValidationResult> {
         return this.mutationService.validate(vmId, "java.lang.String", angular.toJson(specName))
               .then((response: ValidationResult) => {
                  return response.result;
               });
      }
   }

   angular.module("com.vmware.vsphere.client.vm")
         .service("customizeGosSelectSpecPageService", CustomizeGosSelectSpecPageService);
}