/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace h5_vm {
   import VmProvisioningSelectTemplatePageModel = h5_vm.VmProvisioningSelectTemplatePageModel;

   class VmProvisioningSelectTemplateToConvertPageModelFactory {
      public static $inject = [
         "$q",
         "i18nService",
         "dataService",
         "authorizationService",
         "defaultUriSchemeUtil",
         "managedEntityConstants",
         "creationTypeConstants",
         "wizardPageService"
      ];

      constructor($q: angular.IQService,
            i18nService: any,
            dataService: any,
            authorizationService: any,
            defaultUriSchemeUtil: any,
            managedEntityConstants: any,
            creationTypeConstants: any,
            wizardPageService: any
      ) {
         return (virtualMachineSpecBuilder: any, wizardViewData: any, wizardConfig: any) => {
            return new VmProvisioningSelectTemplateToConvertPageModel(
                  $q,
                  i18nService,
                  dataService,
                  authorizationService,
                  defaultUriSchemeUtil,
                  managedEntityConstants,
                  creationTypeConstants,
                  wizardPageService,
                  virtualMachineSpecBuilder,
                  wizardViewData,
                  wizardConfig);
         };
      }
   }

   export class VmProvisioningSelectTemplateToConvertPageModel
         extends VmProvisioningSelectTemplatePageModel {

      static readonly $inject: string[] = [
         "$q",
         "i18nService",
         "dataService",
         "authorizationService",
         "defaultUriSchemeUtil",
         "managedEntityConstants",
         "creationTypeConstants",
         "wizardPageService"
      ];

      constructor($q: angular.IQService,
            i18nService: any,
            dataService: any,
            authorizationService: any,
            defaultUriSchemeUtil: any,
            managedEntityConstants: any,
            creationTypeConstants: any,
            wizardPageService: any,
            virtualMachineSpecBuilder: any,
            wizardViewData: any,
            wizardConfig: any
            ) {
         super($q, i18nService, dataService, authorizationService, defaultUriSchemeUtil,
               managedEntityConstants, creationTypeConstants, wizardPageService,
               virtualMachineSpecBuilder, wizardViewData, wizardConfig);
      }

      updateSelection(item: VxTreeViewItem): boolean {
         let selectionUpdated = super.updateSelection(item);
         if (!selectionUpdated) {
            return false;
         }

         const DATACENTER_ITEM: VxTreeViewItem = this.getDatacenter(item);
         this.virtualMachineSpecBuilder.setTargetInformation(
               DATACENTER_ITEM.id,
               DATACENTER_ITEM.id,
               null,
               DATACENTER_ITEM.text
         );
         return true;
      }

      getDatacenter(item: VxTreeViewItem): VxTreeViewItem {
         while (this.defaultUriSchemeUtil.getEntityType(item.objRef) !== this.managedEntityConstants.DATACENTER) {
            item = item.parentNode();
         }
         return item;
      }
   }

   angular.module("com.vmware.vsphere.client.vm")
         .service("vmProvisioningSelectTemplateToConvertPageModel",
               VmProvisioningSelectTemplateToConvertPageModelFactory);
}