/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace h5_vm {
   export class VmProvisioningSelectTemplatePageService implements VmProvisioningPageService {
      static readonly $inject: string[] = [
         "i18nService",
         "defaultUriSchemeUtil",
         "wizardPageService",
         "vmVuiWizardPageBuilder",
         "vmProvisioningSelectTemplatePageModel"
      ];

      constructor(private i18nService: any,
            private defaultUriSchemeUtil: any,
            private wizardPageService: any,
            private vmVuiWizardPageBuilder: any,
            private vmProvisioningSelectTemplatePageModel: any) {
      }

      build(wizardScope: any): VmProvisioningWizardPage {

         let pageModel = new this.vmProvisioningSelectTemplatePageModel(
               wizardScope.vmParams,
               wizardScope.wizardViewData,
               wizardScope.config);

         wizardScope.selectTemplateModel = pageModel;

         return this.vmVuiWizardPageBuilder.buildVuiWizardPage(wizardScope.config, {
            title: this.i18nService.getString("VmUi", "SelectTemplateProvisioningPage.Template.Title"),
            description: this.i18nService.getString("VmUi", "SelectTemplateProvisioningPage.Template.Description"),
            contentUrl: "vm-ui/resources/vm/views/createVmWizard/vm-provisioning-select-template-page.html",
            model: pageModel
         });
      }
   }

   angular.module("com.vmware.vsphere.client.vm")
         .service("vmProvisioningSelectTemplatePageService",
               VmProvisioningSelectTemplatePageService);
}