namespace h5_vm {

   class VmHardwareVirtualDiskTypeController implements ng.IComponentController {
      static $inject: string[] = ['vmHardwareRdmVirtualDiskTypeService'];

      private deviceBacking: any;

      constructor(private vmHardwareRdmVirtualDiskTypeService: VmHardwareRdmVirtualDiskTypeService) {
      }

      private get provisioningTypeLabel(): string {
         if (!this.deviceBacking) {
            return "";
         }
         if (!this.vmHardwareRdmVirtualDiskTypeService) {
            return "";
         }
         const retVal: string = this.vmHardwareRdmVirtualDiskTypeService.getProvisioningTypeLabel(this.deviceBacking);
         return retVal;
      }
   }

   export class VmHardwareVirtualDiskType implements ng.IComponentOptions {

      bindings: any;
      controller: any;
      templateUrl: string;

      constructor() {
         this.bindings = {
            deviceBacking: '<'
         };
         this.controller = VmHardwareVirtualDiskTypeController;
         this.templateUrl = 'vm-ui/resources/vm/views/settings/vmHardwareSettings/' +
            'vmHardwareVirtualDiskType/vm-hardware-virtual-disk-type.html';
      }
   }
   angular.module('com.vmware.vsphere.client.vm')
      .component('vmHardwareVirtualDiskType', new VmHardwareVirtualDiskType());
}
