module cluster_ui {

   export class AdditionalOptionsPageService {

      public static $inject = ["i18nService"];

      public constructor(private i18nService: any) {}

      public getCpuOverCommitmentSignPostParams(): any {
         return {
            title: this.getString("help", "Common"),
            message: this.getString("drs.config.overcommitment.label")
         };
      }

      private getString(key: string, bundle?: string): string {
         return bundle ? this.i18nService.getString(bundle, key) :
               this.i18nService.getString("ClusterUi", key);
      }
   }

   angular.module("com.vmware.vsphere.client.cluster")
         .service("additionalOptionsPageService", AdditionalOptionsPageService);
}


