angular.module('com.vmware.vsphere.client.vm').service('resourcePoolLookupService', [
   'dataService',
   'managedEntityConstants',
   'defaultUriSchemeUtil',
   function (dataService, managedEntityConstants, defaultUriSchemeUtil) {
      return {
         lookupResourcePoolForComputeResource: function (computeResourceId) {
            if (isAResourcePoolOrVapp(computeResourceId)) {
               return dataService.getProperties(computeResourceId, ['runtime']);
            } else if (isCluster(computeResourceId)){
               return dataService.getPropertiesByRelation(computeResourceId,
                     'parent',
                     managedEntityConstants.RESOURCE_POOL,
                     ['runtime']
               ).then(function (computeResourceProperties) {
                  return formatClusterResult(computeResourceProperties);
               });
            } else if (isHostSystem(computeResourceId)) {
               return dataService.getData(computeResourceId,
                     "com.vmware.vsphere.client.h5.vm.model.HostResourcePoolData"
               ).then(function(computeResourceProperties) {
                  return formatHostResult(computeResourceProperties);
               });
            }
         }
      };

      function isAResourcePoolOrVapp(computeResourceId) {
         var computeResourceType =
               defaultUriSchemeUtil.getPartsFromVsphereObjectId(computeResourceId).type;

         var isARpOrVapp = computeResourceType === managedEntityConstants.RESOURCE_POOL
               || computeResourceType === managedEntityConstants.V_APP;
         return isARpOrVapp;
      }

      function isHostSystem(computeResourceId) {
         var computeResourceType =
               defaultUriSchemeUtil.getPartsFromVsphereObjectId(computeResourceId).type;

         return computeResourceType === managedEntityConstants.HOST;
      }

      function isCluster(computeResourceId) {
         var computeResourceType =
               defaultUriSchemeUtil.getPartsFromVsphereObjectId(computeResourceId).type;

         return computeResourceType === managedEntityConstants.CLUSTER;
      }

      /**
       * Formats the dataService result object to what the world expects this
       * service to return.
       * @param resultObject {
       *    "vmomiUri": the name of the field is the uri of the resourcePool
       *     It has a property "runtime", which is the RP's runtime object
       * }
       * @returns {
       *    id: uri of the resourcePool
       *    runtime: Rp's runtime object
       * }
       */
      function formatClusterResult(resultObject) {
         var result = {};
         for (var objectId in resultObject) {
            if (resultObject.hasOwnProperty(objectId)) {
               result.id = objectId;
            }
         }
         if (resultObject[result.id]
               && resultObject[result.id].runtime) {
            result.runtime = resultObject[result.id].runtime;
         }
         return result;
      }

      /**
       * Formats the dataService result object to what the world expects this
       * service to return.
       * @param resultObject {
       *    rpId: MOR of the resource pool
       *    rpRuntime: RP's runtime object
       * }
       * @returns {
       *    id: uri of the resourcePool
       *    runtime: Rp's runtime object
       * }
       */
      function formatHostResult(resultObject) {
         if (!resultObject && !resultObject.rpId && !resultObject.rpRuntime) {
            return {
               id: null,
               runtime: null
            };
         }

         var id = defaultUriSchemeUtil.getVsphereObjectId(resultObject.rpId);

         return {
            id: id,
            runtime: resultObject.rpRuntime
         };
      }
   }
]);
