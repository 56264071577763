(function() {
   angular.module('com.vmware.vsphere.client.host').controller(
         'EditVmSwapfileLocationController', EditVmSwapfileLocationController);

   EditVmSwapfileLocationController.$inject = ['$scope', 'dataService',
      'bytesFormatter', 'i18nService', 'vuiConstants', 'mutationService', '$q'];

   function EditVmSwapfileLocationController($scope, dataService,
         bytesFormatter, i18nService, vuiConstants, mutationService, $q) {
      var hostId = $scope.modalOptions.dialogData.availableTargets[0];
      var self = this;
      $scope.modalOptions.isAlertClosed = true;
      self.datastoreMorefs = [];
      self.datastores = [];
      setPageBusy(true);
      self.gridOptions = {
         selectionMode: vuiConstants.grid.selectionMode.SINGLE,
         sortMode: vuiConstants.grid.sortMode.SINGLE,
         searchable: false,
         resizable: true,
         selectedItems: [],
         data: [],
         columnDefs: getColumnDefs(),
         height: 'auto'
      };

      $scope.$watch(function() {
               return self.gridOptions.selectedItems;
            },
            function(selectedItems) {
               self.selectedDatastore = selectedItems[0];
            });

      $scope.modalOptions.onSubmit = onOkClick;

      getDialogViewData();

      function getDialogViewData() {
         dataService.getData(
               hostId,
               'com.vmware.vsphere.client.h5.host.model.SwapfileLocationData'
         ).then(function(data) {
            if (data) {
               self.originalSwapFileLocation = data.vmSwapPlacement;
               self.swapfileLocation = data.vmSwapPlacement;
               self.computeResource = data.computeResource;
               self.isRadioDisabled = data.isStandalone ?
                     false : self.swapfileLocation !== "vmDirectory";
            }
         });

         getDatastores();
      }

      function getDatastores() {
         dataService.getProperties(hostId,
               ['configTargetDatastoreInfo'])
               .then(function(data) {
                  if (data) {
                     self.gridOptions.data = processDatastores(data.configTargetDatastoreInfo);
                  }
                  setPageBusy(false);
               }).catch(function(error) {
                  setPageBusy(false);
                  $q.reject(error);
               });
      }

      function processDatastores(datastoreInfos) {
         datastoreInfos = _.filter(datastoreInfos, function(datastoreInfo) {
            return datastoreInfo.datastore.type !== 'VVOL';
         });

         self.datastoreMorefs = _.map(datastoreInfos, function(datastoreInfo) {
            return {
               name: datastoreInfo.name,
               moRef: datastoreInfo.datastore.datastore
            };
         });

         return _.map(datastoreInfos, function(datastoreInfo) {
            var provisionedCapacity = calculateProvisionedCapacity(datastoreInfo.datastore);
            return {
               name: datastoreInfo.name,
               raw_capacity: datastoreInfo.datastore.capacity,
               capacity: bytesFormatter.bytesFormat(datastoreInfo.datastore.capacity),
               raw_provisioned: provisionedCapacity,
               provisioned: bytesFormatter.bytesFormat(provisionedCapacity),
               raw_free: datastoreInfo.datastore.freeSpace,
               free: bytesFormatter.bytesFormat(datastoreInfo.datastore.freeSpace),
               type: datastoreInfo.datastore.type,
               thin: calculateThinCapacity(datastoreInfo)
            };
         });
      }

      function calculateProvisionedCapacity(datastore) {
         var freeCapacity = datastore.freeSpace;
         var totalCapacity = datastore.capacity;
         var provisionedCapacity = totalCapacity - freeCapacity;

         if (datastore.uncommitted && !isNaN(datastore.uncommitted)) {
            provisionedCapacity += datastore.uncommitted;
         }

         return provisionedCapacity;
      }

      function calculateThinCapacity(datastoreInfo) {
         var thinLabel = i18nService.getString("HostUi", "swapfile.supported");

         if (datastoreInfo.capability
               && !datastoreInfo.capability.perFileThinProvisioningSupported) {
            thinLabel = i18nService.getString("HostUi", "swapfile.notsupported");
         }

         return thinLabel;
      }

      function onOkClick() {
         var hostSwapFileReconfigureSpec = {
            swapFileLocationType: self.swapfileLocation,
            computeResource: self.computeResource,
            reconfigure: self.swapfileLocation !== self.originalSwapFileLocation
         };

         if (self.swapfileLocation && self.swapfileLocation === 'hostLocal') {
            if (self.selectedDatastore) {
               var datastoreMoref = _.find(self.datastoreMorefs, function(datastoreMoref){
                  return datastoreMoref.name === self.selectedDatastore.name;
               });
               hostSwapFileReconfigureSpec.datastore = datastoreMoref.moRef;
            } else {
               $scope.modalOptions.alerts = [{
                  type: vuiConstants.validationBanner.type.ERROR,
                  text: i18nService
                        .getString('HostUi', 'settings.swapfile.missing.datastore.error')
               }];
               $scope.modalOptions.isAlertClosed = false;
               return false;
            }
         }

         mutationService.apply(hostId,
               'com.vmware.vsphere.client.host.config.HostSwapFileReconfigureSpec',
               hostSwapFileReconfigureSpec);
         return true;
      }

      function setPageBusy(isBusy) {
         $scope.modalOptions.submitDisabled = isBusy;
         self.loading = isBusy;
      }

      function getColumnDefs() {
         return [
            {
               displayName: i18nService.getString('HostUi', 'swapfile.datastore.name'),
               field: 'name'
            },
            {
               displayName: i18nService.getString('HostUi', 'swapfile.datastore.capacity'),
               field: 'capacity',
               sortable: function compare(firstItem, secondItem) {
                  return sort(firstItem, secondItem, 'raw_capacity');
               }
            },
            {
               displayName: i18nService.getString('HostUi', 'swapfile.datastore.provisioned'),
               field: 'provisioned',
               sortable: function compare(firstItem, secondItem) {
                  return sort(firstItem, secondItem, 'raw_provisioned');
               }
            },
            {
               displayName: i18nService.getString('HostUi', 'swapfile.datastore.free'),
               field: 'free',
               sortable: function compare(firstItem, secondItem) {
                  return sort(firstItem, secondItem, 'raw_free');
               }
            }, {
               displayName: i18nService.getString('HostUi', 'swapfile.datastore.type'),
               field: 'type'
            },
            {
               displayName: i18nService.getString('HostUi', 'swapfile.datastore.thin'),
               field: 'thin'
            }
         ];
      }

      function sort(firstItem, secondItem, sortProperty) {
         if (firstItem[sortProperty] < secondItem[sortProperty]) {
            return -1;
         }
         if (firstItem[sortProperty] > secondItem[sortProperty]) {
            return 1;
         }
         return 0;
      }
   }
})();
