/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace dvs_ui {

   export class DvsPortStatisticsService {

      public static $inject = [
         "i18nService",
         "vxPropertyViewService",
         "browserLanguage"];

      constructor(private i18nService: any,
                  private vxPropertyViewService: any,
                  private browserLanguage: any) {
      }

      public buildDistributedPortStatisticsView(statistics: any): any {
         let statisticsBuilder: any = this.vxPropertyViewService.createPropertyViewBuilder();
         let statisticsCategory: any = statisticsBuilder
               .category("statistics");

         let statisticsSection: any = statisticsCategory
               .section("statistics")
               .title(this.i18nService.getString("DvsUi", "PortList.statistics.title"));

         if (statistics) {
            statisticsSection.property(
                  this.i18nService.getString("DvsUi", "PortList.bytesInUnicastHeaderText"),
                  statistics.bytesInUnicast);

            statisticsSection.property(
                  this.i18nService.getString("DvsUi", "PortList.bytesOutUnicastHeaderText"),
                  statistics.bytesOutUnicast);

            statisticsSection.property(
                  this.i18nService.getString("DvsUi", "PortList.bytesInBroadcastHeaderText"),
                  statistics.bytesInBroadcast);

            statisticsSection.property(
                  this.i18nService.getString("DvsUi", "PortList.bytesOutBroadcastnameHeaderText"),
                  statistics.bytesOutBroadcast);

            statisticsSection.property(
                  this.i18nService.getString("DvsUi", "PortList.bytesInMulticastHeaderText"),
                  statistics.bytesInMulticast);

            statisticsSection.property(
                  this.i18nService.getString("DvsUi", "PortList.bytesOutMulticastHeaderText"),
                  statistics.bytesOutMulticast);

            statisticsSection.property(
                  this.i18nService.getString("DvsUi", "PortList.packetsInUnicastHeaderText"),
                  this.getLocalizedPacketsValue(statistics.packetsInUnicast));

            statisticsSection.property(
                  this.i18nService.getString("DvsUi", "PortList.packetsOutUnicastHeaderText"),
                  this.getLocalizedPacketsValue(statistics.packetsOutUnicast));

            statisticsSection.property(
                  this.i18nService.getString("DvsUi", "PortList.packetsInBroadcastHeaderText"),
                  this.getLocalizedPacketsValue(statistics.packetsInBroadcast));

            statisticsSection.property(
                  this.i18nService.getString("DvsUi", "PortList.packetsOutBroadcastnameHeaderText"),
                  this.getLocalizedPacketsValue(statistics.packetsOutBroadcast));

            statisticsSection.property(
                  this.i18nService.getString("DvsUi", "PortList.packetsInMulticastHeaderText"),
                  this.getLocalizedPacketsValue(statistics.packetsInMulticast));

            statisticsSection.property(
                  this.i18nService.getString("DvsUi", "PortList.packetsOutMulticastHeaderText"),
                  this.getLocalizedPacketsValue(statistics.packetsOutMulticast));

            statisticsSection.property(
                  this.i18nService.getString("DvsUi", "PortList.packetsInDroppedHeaderText"),
                  this.getLocalizedPacketsValue(statistics.packetsInDropped));

            statisticsSection.property(
                  this.i18nService.getString("DvsUi", "PortList.packetsOutDroppedHeaderText"),
                  this.getLocalizedPacketsValue(statistics.packetsOutDropped));

            statisticsSection.property(
                  this.i18nService.getString("DvsUi", "PortList.packetsInExceptionHeaderText"),
                  this.getLocalizedPacketsValue(statistics.packetsInException));

            statisticsSection.property(
                  this.i18nService.getString("DvsUi", "PortList.packetsOutExceptionHeaderText"),
                  this.getLocalizedPacketsValue(statistics.packetsOutException));
         }

         return statisticsBuilder.build();
      }

      private getLocalizedPacketsValue(packets: number): string {
         if(packets === -1) {
            return this.i18nService.getString("DvsUi", "PortList.noData");
         }

         return packets.toLocaleString(this.browserLanguage.get(), {
            minimumFractionDigits: 0,
            maximumFractionDigits: 2
         });
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .service("dvsPortStatisticsService", DvsPortStatisticsService);
}
