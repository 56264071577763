namespace h5_vm {

   import GosType = h5_vm.GosType;

   export class GosDnsSettingsPageController {
      public static $inject = ["$q",
         "ipParserService",
         "i18nService"];

      public readonly PRIMARY_DNS: string = "primary";
      public readonly SECONDARY_DNS: string = "secondary";
      public readonly TERTIARY_DNS: string = "tertiary";

      public model: GosDnsSettingsPageModel;
      public primaryInvalid: boolean = false;
      public secondaryInvalid: boolean = false;
      public tertiaryInvalid: boolean = false;


      constructor(private $q: any,
            private ipParser: any,
            public i18n: any) {
      }

      $onInit(): void {
         this.model.onPageCommit = this.onCommit.bind(this);
      }

      $onDestroy(): void {
         this.model.onPageCommit = undefined;
      }

      onCommit(): angular.IPromise<Array<string>> {
         let errors: Array<String> = [];

         if (!this.isDnsValid(this.model.primaryDns)
               || !this.isDnsValid(this.model.secondaryDns)
               || !this.isDnsValid(this.model.tertiaryDns)) {
            this.validateDNS(this.PRIMARY_DNS);
            this.validateDNS(this.SECONDARY_DNS);
            this.validateDNS(this.TERTIARY_DNS);
            errors.push(this.i18n.getString("VmUi", "guestOsCustomization.dnsSettingsPage.invalidIpAddress"));
         }

         // TODO: Validate DNS search path if we have network settings type to be CUSTOM
         // and we have an adapter that is not DHCP. We'll have those from the network page mogel.
         // More info on the validation: https://goo.gl/u60I3d

         return this.$q.resolve(errors);
      }

      validateDNS(dnsNumber: string) {
         switch (dnsNumber) {
            case this.PRIMARY_DNS:
               this.primaryInvalid = !this.isDnsValid(this.model.primaryDns);
               break;
            case this.SECONDARY_DNS:
               this.secondaryInvalid = !this.isDnsValid(this.model.secondaryDns);
               break;
            case this.TERTIARY_DNS:
               this.tertiaryInvalid = !this.isDnsValid(this.model.tertiaryDns);
               break;
         }
      }

      private isDnsValid(dns: string): boolean {
         if (dns && !_.isEmpty(dns)
               && !this.ipParser.isIpv4AddressValid(dns)
               && !this.ipParser.isIpv6AddressValid(dns)) {
            return false;
         }

         return true;
      }
   }

   export class GosDnsSettingsPageComponent implements angular.IComponentOptions {
      public controller: any;
      public templateUrl: string;
      public bindings: any;

      constructor() {
         this.controller = GosDnsSettingsPageController;
         this.templateUrl =
            "vm-ui/resources/vm/guest-os-customization/pages/dns-settings/gos-dns-settings-page.component.html";
         this.bindings = {
            model: "<"
         };
      }
   }

   angular
      .module("com.vmware.vsphere.client.vm")
      .component("gosDnsSettingsPage", new GosDnsSettingsPageComponent());
}
