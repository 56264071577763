namespace h5_vm {
   import IController = angular.IComponentController;

   class VmProvisioningScaleoutConfigurationController implements IController {
      static $inject: string[] = ["$scope"];
      private scaleoutConfiguration : any;

      constructor(private $scope: any) {
         this.scaleoutConfiguration = {
            params: this.getScaleoutConfigurationParams(),
            valid: true
         };

         this.$scope.vuiWizard.currentPage.disableNext = () => {
            return !this.scaleoutConfiguration.valid;
         };
      }

      private getScaleoutConfigurationParams(): any {
         let scaleoutParams = this.$scope
               .vmProvisioningScaleoutConfigurationPageModel
               .virtualMachineSpecBuilderObj
               .getSclaeoutConfigurationFromLibraryCommonContext();
         return scaleoutParams.groups;
      }
   }

   angular.module("com.vmware.vsphere.client.vm")
         .controller("vmProvisioningScaleoutConfigurationController", VmProvisioningScaleoutConfigurationController);
}