(function() {
   'use strict';
   angular.module('com.vmware.vsphere.client.host')
         .controller('moveHostToController', MoveHostToController);

   MoveHostToController.$inject = [
      '$scope',
      'moveHostToService',
      'defaultUriSchemeUtil'
   ];

   function MoveHostToController($scope,
         moveHostToService, defaultUriSchemeUtil) {
      var self = this;
      self.hostIds = $scope.modalOptions.dialogData.objectIds;
      self.onLocationSelected = onLocationSelected;
      self.treeRoot = defaultUriSchemeUtil.getRootFolderFromVsphereObjectId(self.hostIds[0]);

      $scope.modalOptions.onSubmit = function() {
         moveHostToService.moveHostTo(self.entityToMoveHostTo,self.hostIds);

         $scope.closeModal();
      };

      function onLocationSelected(objectReference) {
         self.entityToMoveHostTo = objectReference;
      }
   }
})();

