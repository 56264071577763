angular
      .module('com.vmware.vsphere.client.host')
      .constant('suppressWarningSettingConstants', {
         SUPPRESS_SHELL_WARNING_KEY: 'UserVars.SuppressShellWarning',
         SUPPRESS_HYPERTHREAD_WARNING_KEY: 'UserVars.SuppressHyperthreadWarning',
         WARNING_SUPPRESS_VALUE: {
            TRUE: 1,
            FALSE: 0
         }
      });
