/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace dvs_ui {

   import DvsImportInfoSpec = com.vmware.vsphere.client.dvs.api.spec.DvsImportInfoSpec;
   import DvsImportPortgroupWrapperSpec = com.vmware.vsphere.client.dvs.api.spec.DvsImportPortgroupWrapperSpec;
   import DistributedVirtualPortgroup$ConfigSpec = com.vmware.vim.binding.vim.dvs.DistributedVirtualPortgroup$ConfigSpec;
   import DvPortgroupRestoreInfoWrapperSpec = com.vmware.vsphere.client.dvs.api.spec.DvPortgroupRestoreInfoWrapperSpec;

   export class DvsImportWizardConfigurationPageModel {

      /**
       * The configuration data for the DVS as read from the backup file.
       */
      public configurationData: string;

      /**
       * The configuration data for the DVPG as read from the backup file.
       */
      public restoreDvpgConfigurationData: number[];

      /**
       * The <code>DvsImportInfoSpec</code> retrieved from the server.
       */
      public dvsImportInfoSpec: DvsImportInfoSpec;

      /**
       * The <code>DvsImportPortgroupWrapperSpec</code> retrieved from the server.
       */
      public dvsImportPortgroupWrapperSpec: DvsImportPortgroupWrapperSpec;

      /**
       * The location of the currently selected file.
       */
      public fileLocation: string;

      /**
       * Whether to preserve original distributed switch and distributed
       * port group identifiers.
       */
      public preserveOriginalIdentifiers: Boolean = false;

      /**
       * Whether to restore dvs and all port groups or dvs only.
       */
      public restorePortgroups: string = "dvsAndAllPortgroups";

      /**
       * Whether to restore dvpg from file or from previous configuration.
       */
      public restoreType: string = "previous";

      /**
       * The configuration spec for the DVPG restore functionality.
       */
      public dvPortgroupRestoreInfoWrapperSpec: DvPortgroupRestoreInfoWrapperSpec;
   }
}
