module ds_cluster_ui {
   export class DsClusterVmConnectivityDetailsController {
      public detailsViewGridOptions: any;

      public static $inject = ["$scope", "dsClusterConectivityService"];

      constructor(private $scope: SdrsDetailsViewScope,
                  private dsClusterConectivityService: any) {
         this.detailsViewGridOptions = this.dsClusterConectivityService
               .getVmConnectivityDetailsViewGridOptions();

         $scope.$watch("masterDetailsViewContext.selectedItems", (newValue: any) => {
            if (newValue && newValue.length > 0) {
               this.setVmDetailsViewGridData(newValue[0]);
            }
         });
      }

      private setVmDetailsViewGridData(vm: any): void {
         this.detailsViewGridOptions.data = vm.vmDisks.slice(0);
      }
   }

   angular.module("com.vmware.vsphere.client.dsCluster")
         .controller("DsClusterVmConnectivityDetailsController",
               DsClusterVmConnectivityDetailsController);
}
