angular.module('com.vmware.vsphere.client.vm').factory('VirtualMachineDevices', [
   'vmDeviceInfoService',
   'virtualDeviceInflator',
   function (vmDeviceInfoService: any, virtualDeviceInflator: any) {
      var VirtualMachineDevices = function VirtualMachineDevices(
         this: any,
         rawDevices: any,
         profileAssignments: any,
         profiles: any,
         availableNetworks: any,
         deviceOptionsByDeviceType: any,
         isScheduledTask: any
      ) {
         var self = this;
         var additionalInfo = {
            storageProfiles: profiles,
            storageProfileAssignments: profileAssignments,
            availableNetworks: availableNetworks,
            deviceOptionsByDeviceType: deviceOptionsByDeviceType,
            isScheduledTask: isScheduledTask
         };
         var deviceList = virtualDeviceInflator.inflateDevices(rawDevices, additionalInfo);
         var inflatedDevices = _.reduce(deviceList, function(memo: { [index: number]: any }, virtualDevice: any) {
            memo[virtualDevice.getKey()] = virtualDevice;
            return memo;
         }, {});

         var rgValidationCallbacks: { [index: number]: any } = {};

         this.addRgValidationCallback = function(deviceKey: number, callback: any) {
            rgValidationCallbacks[deviceKey] = callback;
         };

         this.notifyRgValidationCallbacks = function() {
            var deviceKeys: any = Object.keys(rgValidationCallbacks);
            deviceKeys.forEach(function(deviceKey: any){
               if (rgValidationCallbacks[deviceKey]) {
                  (rgValidationCallbacks[deviceKey] as any)();
               }
            });
         };

         this.getDeviceChanges = function(this: any) {
            var array = _.chain(this.getInflatedDevices())
               .filter(function(inflatedDevice) {
                  return inflatedDevice.hasChanged();
               })
               .map(function(changedDevice) {
                  return changedDevice.getCurrentDeviceSpec();
               })
               .value();
            return array;
         };

         this.getFinalChanges = function(this: any) {
            var array = _.chain(this.getInflatedDevices())
               .filter(function(inflatedDevice) {
                  return inflatedDevice.hasChanged();
               })
               .map(function(changedDevice) {
                  return changedDevice.getFinalDeviceSpec();
               })
               .value();
            return array;
         };

         this.findDeviceSpec = function(deviceKey: any) {
            var virtualDevice = _.find(inflatedDevices, function(device: any) {
               return device.getKey() === deviceKey;
            });

            return virtualDevice && virtualDevice.getCurrentDeviceSpec();
         };

         this.getInflatedDevices = function() {
            return _.values(inflatedDevices);
         };

         this.getDeviceSpecForDevice = function(device: any) {
            var virtualDevice = inflatedDevices[device.key];
            if (!virtualDevice) {
               virtualDevice = virtualDeviceInflator.inflate(device);
               inflatedDevices[device.key] = virtualDevice;
            }

            return virtualDevice.getCurrentDeviceSpec();
         };

         this.devicesOfType = function (deviceType: any) {
            return _.filter(inflatedDevices, function (virtualDevice: any) {
               return virtualDevice.isOfType(deviceType);
            });
         };

         this.devicesOfTypeNotMarkedForRemoval = function (deviceType: any) {
            return _.filter(inflatedDevices, function (virtualDevice: any) {
               return virtualDevice.isOfType(deviceType)
                     && !virtualDevice.isMarkedForRemoval();
            });
         };

         // Presenter method that could be moved to its own service
         this.existingDevicesOfType = function(this: any, deviceType: any) {
            return _.filter(this.devicesOfType(deviceType), function (virtualDevice: any) {
               return !virtualDevice.isNew();
            });
         };

         this.existingSortedDevicesOfType = function(this: any, deviceType: any) {
            var existingDevicesOfType = _.filter(this.devicesOfType(deviceType), function (virtualDevice: any) {
               return !virtualDevice.isNew();
            });

            existingDevicesOfType.sort(function(a: any, b: any) {
               // match only numbers, then convert to true integers
               var diskNumberA = parseInt(a.deviceName().match(/\d+/g)[0], 10);
               var diskNumberB = parseInt(b.deviceName().match(/\d+/g)[0], 10);

               return diskNumberA - diskNumberB;
            });

            return existingDevicesOfType;
         };

         // Presenter method that could be moved to its own service
         this.newDevicesOfType = function (this: any, deviceType: any) {
            return _.filter(this.devicesOfType(deviceType), function (virtualDevice: any) {
               return virtualDevice.isNew();
            });
         };

         // Presenter method that could be moved to its own service
         this.findDevicesOfType = function (this: any, deviceType: any) {
            return this.devicesOfType(deviceType);
         };

         this.changedDevicesOfType = function (this: any, deviceType: any) {
            return _.filter(this.devicesOfType(deviceType), function (virtualDevice: any) {
               return virtualDevice.hasChanged();
            });
         };

         this.removeDeviceByKey = function(this: any, deviceKey: any) {
            var virtualDevice = this.getVirtualDevice(deviceKey);
            if(virtualDevice.isNew()) {
               delete inflatedDevices[deviceKey];
               delete rgValidationCallbacks[deviceKey];
            } else {
               virtualDevice.markForRemoval();
            }
         };

         this.getAllDevicesNotMarkedForRemoval = function () {
            return _.filter(self.getInflatedDevices(), function(device: any) {
               return !device.isMarkedForRemoval();
            });
         };

         this.getDevicesThatHaveBeenAssignedToNodes = function (this: any) {
            return _.chain(this.getAllDevicesNotMarkedForRemoval())
               .filter(function(device){
                  return _.isNumber(device.getCurrentDevice().unitNumber);
               })
               .sortBy(function(device) {
                  return device.hasChanged() ? 1 : -1;
               })
               .map(function(device){
                  return device.getCurrentDevice();
               })
               .value();
         };

         this.getDevicesAttachedToControllerKey = function (controllerKey: any) {
            var assignedDevices = self.getDevicesThatHaveBeenAssignedToNodes();
            return _.filter(assignedDevices, function(device: any) {
               return device.controllerKey === controllerKey;
            });
         };

         this.getRdmDisksNotMarkedForRemoval = function(this: any) {
            return _.chain(this.getAllDevicesNotMarkedForRemoval())
               .filter(function(inflatedDevice) {
                  return _.isFunction(inflatedDevice.isRDMDisk) && inflatedDevice.isRDMDisk();
               })
               .map(function(inflatedDevice) {
                  return inflatedDevice.getCurrentDevice();
               })
               .value();
         };

         this.isNodeInUse = function (controllerKey: any, nodeUnitNumber: any) {
            var devicesNotMarkedForRemoval = self.getAllDevicesNotMarkedForRemoval();
            return _.any(devicesNotMarkedForRemoval, function(virtualDevice: any){
               return virtualDevice.getCurrentDevice().controllerKey === controllerKey
                  && virtualDevice.getCurrentDevice().unitNumber === nodeUnitNumber;
            });
         };

         this.getVirtualDevice = function(deviceKey: any){
            return inflatedDevices[deviceKey];
         };

         this.addVirtualDevice = function(virtualDevice: any) {
            var deviceKey = virtualDevice.getKey();
            if(self.getVirtualDevice(deviceKey)) {
               throw(new Error('VirtualDevice already exists for key ' + deviceKey + ":" + _.keys(inflatedDevices)));
            }
            inflatedDevices[deviceKey] = virtualDevice;
         };

         this.devicesMarkedForRemovalOfType = function(deviceType: any) {
            return _.filter(self.getInflatedDevices(), function(device: any) {
               return device.isMarkedForRemoval() && device.isOfType(deviceType);
            });
         };

         this.devicesNotMarkedForRemovalOfType = function(deviceType: any) {
            return _.filter(self.getInflatedDevices(), function(device: any) {
               return !device.isMarkedForRemoval() && device.isOfType(deviceType);
            });
         };
      };

      return VirtualMachineDevices;
   }
]);
