/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
(function() {
   'use strict';
   angular.module('com.vmware.vsphere.client.storage')
      .controller('SelectDevicePageController', [
         '$scope',
         'i18nService',
         'hostStorageService',
         'wizardPageService',
         function($scope, i18nService, hostStorageService, wizardPageService) {
            var self = this;
            var manager = $scope.manager;
            wizardPageService.showLoadingIndicator($scope.wizardConfig);

            self.selectedDevice = manager.getSelectedDevice();
            self.onSelectedDeviceChanged = function onSelectedDeviceChanged (selected) {
               if (!selected //row is deselected - set selectedDevice and selectedOption to undefined
                  || (manager.getSelectedDevice() && selected.disk.uuid !== manager.getSelectedDevice().disk.uuid)) {
                  /**
                   * When the user changes selection to a different device,
                   * we'd better invalidate Pages 2 and 3,
                   * and set selectedOption to undefined
                   **/
                  manager.setSelectedOption(undefined);
                  resetSizeToAdd(manager.getOptionsForSelectedDevice());
                  wizardPageService.invalidateNextPages(
                     $scope.wizardConfig,
                     null,
                     $scope.wizardConfig.currentPage.title
                  );
               }
               manager.setSelectedDevice(selected);
            };

            self.devices = manager.getDevices();
            if (!self.devices) {
               $scope.manager.loadDevices().then(function() {
                  self.devices = manager.getDevices();
               }).finally(function() {
                  wizardPageService.hideLoadingIndicator($scope.wizardConfig);
               });
            } else {
               wizardPageService.hideLoadingIndicator($scope.wizardConfig);
            }

            function resetSizeToAdd (options) {
               angular.forEach(options, function(o) {
                  o.sizeToAdd = undefined;
               });
            }

         }
      ]);
})();