/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace network_ui {

   export class SelectVmkAdaptersToMigratePageComponent {

      public bindings: any;
      public controller: any;
      public templateUrl: string;

      constructor() {
         this.bindings = {
            model: "<"
         };
         this.controller = SelectVmkAdaptersToMigratePageController;
         this.templateUrl =
               "network-ui/resources/network/views/host/migratevmkadapter/" +
               "components/selectVmkAdaptersToMigratePageTemplate.html";
      }
   }

   class SelectVmkAdaptersToMigratePageController {

      public static $inject = [
            "$scope",
            "$rootScope",
            "vuiConstants",
            "vmKernelAdapterService",
            "migrateVmkWizardConstants"];

      public datagridOptions: any;

      public splitterOptions: any;

      public selectedVmkDevice: any;

      public vmkernelAdaptersLoading: boolean;

      public model: MigrateVmkToVssWizardModel;

      private virtualAdaptersDataProperty: string = "vnic:virtualAdaptersData";

      private ingnoreVmkDeviceItemSelection: boolean;

      constructor(private $scope: any,
                  private $rootScope: any,
                  private vuiConstants: any,
                  private vmKernelAdapterService: any,
                  private migrateVmkWizardConstants: any) {

         this.$scope.hostUri = this.$rootScope._route.objectId;

         this.splitterOptions = {
            orientation: this.vuiConstants.splitter.orientation.VERTICAL,
            panes: [
               {
                  min: "125px",
                  size: "60%"
               }, {
                  min: "225px",
                  size: "40%"
               }
            ]
         };
      }

      $onInit(): void {
         this.getVnicsDataGridData();
      }

      private getVnicsDataGridData() {

         this.vmkernelAdaptersLoading = true;

         let showServiceColumns = false;
         let gridLoadedPromise: any =
               this.vmKernelAdapterService.getVnics(
                     this.$scope.hostUri, showServiceColumns);

         gridLoadedPromise.then((vnicsResponse: any): any => {

            let vnicItems: any = vnicsResponse[this.virtualAdaptersDataProperty]
                  ? vnicsResponse[this.virtualAdaptersDataProperty].items
                  : [];

            let filteredVnics = _.filter(vnicItems, (vnicItem: any) => {
               return vnicItem.virtualSwitch !== this.model.targetStandardSwitch;
            });

            if (!this.datagridOptions) {
               this.datagridOptions =
                     this.createDatagridOptions(filteredVnics, vnicsResponse.vnicColumnDefs);
            } else {
               this.datagridOptions.data = vnicItems;
            }

            // When the datagridOptions.data is refreshed, the watch for the
            // selectedItems is called with an empty array. This happens before
            // the grid preselection directive tries to preselect the last
            // selected item. This flag is used to skip the first trigger of the
            // watch when the data is refreshed.
            this.ingnoreVmkDeviceItemSelection = true;

            this.vmkernelAdaptersLoading = false;
         });
      }

      private createDatagridOptions(vnicItems: any, vnicColumnDefs: any): any {

         let options = {
            height: "100%",
            pageConfig: {
               hidePager: true
            },
            columnDefs: vnicColumnDefs,
            sortMode: this.vuiConstants.grid.sortMode.SINGLE,
            selectionMode: this.vuiConstants.grid.selectionMode.SINGLE,
            data: vnicItems,
            selectedItems: [],
            resizable: true,
            filterable: false,
            searchable: false,
            actionBarOptions: {actions: []},
            onChange: this.onSelectionChange.bind(this)
         };
         return options;
      }

      private onSelectionChange(selectedItems: any): void {

         if (this.model.selectedVmkAdapter && this.ingnoreVmkDeviceItemSelection) {
            this.ingnoreVmkDeviceItemSelection = false;
            return;
         }

         if (selectedItems && selectedItems.length > 0) {
            this.$scope.selectedVmkDevice = selectedItems[0].device;

            if (this.model.selectedVmkAdapter !== selectedItems[0].device) {
               this.model.sourceNetworkName = selectedItems[0].networkLabel;
               this.model.sourceSwitchName = selectedItems[0].virtualSwitch;
               if (selectedItems[0].dvPortGroupVlanId) {
                  this.model.vlanId = selectedItems[0].dvPortGroupVlanId;
               } else {
                  this.model.vlanId = "0";
               }

               if (selectedItems[0].connectedTo ===
                     this.migrateVmkWizardConstants.connectedToStandardSwitch) {
                  this.model.networkNameToRemove = selectedItems[0].networkLabel;
               }
            }
         } else {
            this.$scope.selectedVmkDevice = null;
         }

         this.model.selectedVmkAdapter = this.$scope.selectedVmkDevice;
      }

      public preselectComparator = (item: any): boolean => {
         if (!this.model.selectedVmkAdapter) {
            return false;
         }

         return this.model.selectedVmkAdapter === item.device;
      }
   }

   angular.module("com.vmware.vsphere.client.network")
         .component("selectVmkAdapterToMigratePage",
               new SelectVmkAdaptersToMigratePageComponent());
}