/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
(function () {
   'use strict';
   angular.module('com.vmware.vsphere.client.storage').controller(
         'DatastoreConnectivityViewController', DatastoreConnectivityViewController);

   DatastoreConnectivityViewController.$inject = [
      'vuiConstants',
      'i18nService',
      'dataService',
      'columnRenderersRegistry',
      'defaultUriSchemeUtil',
      'navigation'
   ];

   function DatastoreConnectivityViewController(vuiConstants, i18nService, dataService,
         columnRenderersRegistry, defaultUriSchemeUtil, navigation) {

      var datastoreConnectivityProperty = 'connectivityWithHost:nfsVvolDatastoreConnectivity';

      var self = this;
      self.datastoreId = navigation.getRoute().objectId;
      self.headerOptions = {
         title: i18nService.getString('StorageUi', 'datastore.manage.connectivity.connectivityWithHosts')
      };
      self.hostConnectivityLoading = false;

      self.liveRefreshProperties = [
         'host'
      ];

      self.refresh = function () {
         self.hostConnectivityLoading = true;
         dataService.getProperties(self.datastoreId, [datastoreConnectivityProperty])
               .then(function (response) {
                  if (response && response[datastoreConnectivityProperty]) {

                     self.datagridOptions = {
                        height: '100%',
                        columnDefs: getColumnDefinitions(response[datastoreConnectivityProperty]),
                        sortMode: vuiConstants.grid.sortMode.SINGLE,
                        selectionMode: vuiConstants.grid.selectionMode.SINGLE,
                        data: response[datastoreConnectivityProperty],
                        selectedItems: [],
                        resizable: true
                     };

                  }
               })
               .finally(function () {
                  self.hostConnectivityLoading = false;
               });

      };

      self.refresh();

      function getColumnDefinitions(data) {
         var textWithTooltipRenderer = columnRenderersRegistry.getColumnRenderer('text-with-custom-tooltip');
         var columnDefinitions = [
            {
               displayName: i18nService.getString('StorageUi', 'datastore.manage.connectivity.hostList.name'),
               field: 'hostFormattedName',
               width: '150px',
               template: function (dataItem) {
                  var objectLinkRenderer = columnRenderersRegistry.getColumnRenderer('object-link');
                  var modifiedData = {
                     hostName: dataItem.hostFormattedName,
                     hostId: defaultUriSchemeUtil.getVsphereObjectId(dataItem.hostRef)
                  };
                  return objectLinkRenderer(['hostId', 'hostName', 'vsphere-icon-host'], modifiedData);
               }
            },
            {
               displayName: i18nService.getString('StorageUi', 'datastore.manage.connectivity.hostList.datastoreConnectivity'),
               field: 'isAccessible',
               width: '150px',
               type: 'boolean',
               template: function (dataItem) {
                  var localizationKey = dataItem.isAccessible === true ? 'datastore.connected' : 'datastore.notConnected';
                  var title = i18nService.getString('StorageUi', localizationKey);
                  return textWithTooltipRenderer(["text", "title" ], {
                     text: title,
                     title: title
                  });
               }
            },
            {
               displayName: i18nService.getString('StorageUi', 'datastore.manage.connectivity.hostList.accessMode'),
               field: 'accessMode',
               width: '150px',
               template: function(dataItem) {
                  return textWithTooltipRenderer(["accessMode", "accessMode"], dataItem);
               }
            },
            {
               displayName: i18nService.getString('StorageUi', 'datastore.manage.connectivity.hostList.mountPoint'),
               field: 'mountPoint',
               width: '150px',
               template: function(dataItem) {
                  return textWithTooltipRenderer(["mountPoint", "mountPoint"], dataItem);
               }
            }
         ];

         if (data && data.length && data[0].kerberosAuthentication){
            var kerberosAuthenticationColumn = {
               displayName: i18nService.getString('StorageUi', 'datastore.manage.connectivity.hostList.kerberosAuthentication'),
               field: 'accessMode',
               width: '150px',
               template: function(dataItem) {
                  return textWithTooltipRenderer(["kerberosAuthentication", "kerberosAuthentication"], dataItem);
               }
            };
            columnDefinitions.splice(3, 0, kerberosAuthenticationColumn);
         }
         return columnDefinitions;
      }
   }
})();
