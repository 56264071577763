(function() {
    'use strict';


    angular.module('com.vmware.vsphere.client.vm')
       .component('vmAdvancedOptions', {
           templateUrl: 'vm-ui/resources/vm/views/settings/vmOptions/vmAdvancedOptions/vmAdvancedOptions.html',
           controller: VmAdvancedOptionsController,
           controllerAs: 'ctrl',
           bindings: {
               advancedOptions: '=',
               swapPlacementType: '=',
               vmConfigContext: '<',
               vmWorkflowMode: '<',
               expanded: '='
           }
       });

    VmAdvancedOptionsController.$inject = ['i18nService',
      'vmAdvancedOptionsService', 'vscAdvancedEditConfigurationModalService', 'vmHardwareUtil'];

    function VmAdvancedOptionsController (i18nService,
       vmAdvancedOptionsService,
       advancedEditConfigurationModalService,
       vmHardwareUtil) {

       var self = this;
       var i18n = function(key) {
          return i18nService.getString("VmUi", 'AdvancedOptions.' + key);
       };

       self.LBL_TITLE = i18n('title');
       self.LBL_EXPAND = i18n('expand');
       self.LBL_SETTINGS = i18n('settings');
       self.LBL_ACCEL = i18n('settings.acceleration');
       self.LBL_LOGGING = i18n('settings.logging');
       self.LBL_MONITOR = i18n('monitor');
       self.LBL_SWAP = i18n('swapfile');
       self.LBL_SWAP_DEF = i18n('swapfile.default');
       self.LBL_SWAP_VM = i18n('swapfile.vm');
       self.LBL_SWAP_HOST = i18n('swapfile.host');
       self.LBL_PARAMS = i18n('config_params');
       self.LBL_EDIT_PARAMS = i18n('config_params.label');
       self.LBL_LATENCY = i18n('latency_sensitivity.label');

       self.service = vmAdvancedOptionsService;
       self.vmHardwareUtil = vmHardwareUtil;

       self.$onInit = function() {
          self.monitorTypeList = self.service.getMonitorTypeList(self.vmConfigContext);

          const hasPrivilege =
             self.vmConfigContext.privileges.indexOf(h5_vm.Privileges.VM_RESOURCE_PRIVILEGE) > -1;
          if ((hasPrivilege || self.vmWorkflowMode === h5_vm.VmWorkflowMode.CreateMode)
                && !self.vmConfigContext.config.latencySensitivity) {
             self.vmConfigContext.config.latencySensitivity = {
                _type: "com.vmware.vim.binding.vim.LatencySensitivity",
                level: "normal",
                sensitivity: null
             };
          }
          self.lsense = self.vmConfigContext.config.latencySensitivity;

          if (!self.lsense) {
             self.lsense = {level: "normal"};
          }

          self.swapPlacementTypeOptions = [
             {
                id: 'inherit',
                label: self.LBL_SWAP_DEF.split('\n')[0],
                description: self.LBL_SWAP_DEF.split('\n')[1]
             },
             {
                id: 'vmDirectory',
                label: self.LBL_SWAP_VM.split('\n')[0],
                description: self.LBL_SWAP_VM.split('\n')[1]
             },
             {
                id: 'hostLocal',
                label: self.LBL_SWAP_HOST.split('\n')[0],
                description: self.LBL_SWAP_HOST.split('\n')[1]
             }
          ];

          // Default values
          if (self.advancedOptions.enableLogging === undefined) {
             self.advancedOptions.enableLogging = true;
          }
          if (self.swapPlacementType === undefined && !this.isEditSwapFileLocationDisabled()) {
             self.swapPlacementType = self.swapPlacementTypeOptions[0].id;
          }
       };

       this.openAdvancedEditConfigurationModal = function () {
          advancedEditConfigurationModalService.openModal(
             self.vmConfigContext,
             this.vmConfigContext.environment.powerState,
             self.vmHardwareUtil.checkPrivileges(self.vmConfigContext.privileges, ["VirtualMachine.Config.AdvancedConfig"]));
       };

       this.isEditSwapFileLocationDisabled = function() {
          return vmAdvancedOptionsService.isEditSwapFileLocationDisabled(self.vmConfigContext.privileges, self.vmConfigContext.environment.configOption.capabilities);
       };

       this.getMonitorTypeLabel = function(value) {
          return i18n('monitor.' + value);
       };

       this.isEditMonitorTypeDisabled = function() {
          return self.service.isEditMonitorTypeDisabled(self.vmConfigContext, self.vmWorkflowMode);
       };

       this.isDisableAccelerationDisabled = function () {
          return vmAdvancedOptionsService.isDisableAccelerationDisabled(self.vmConfigContext.privileges, self.vmWorkflowMode);
       };

       this.isEnableLoggingDisabled = function() {
          return vmAdvancedOptionsService.isEnableLoggingDisabled(self.vmConfigContext.privileges, self.vmWorkflowMode);
       };

       // Section for Latency Sensitivity (Ls) Level

       this.getSetLsLevel = function(value) {
          return angular.isDefined(value) ? (self.lsense.level = value) : self.lsense.level;
       };

       this.getLsLevelList = function () {
          return self.service.getLsLevelList(self.vmConfigContext);
       };

       this.getLsLevelLabel = function (item) {
          var key =  "VmOptionsView.Advanced.LatencySensitivityLevel." + item;
          return i18nService.getString('VmUi', key);
       };

       this.isEditingLsLevelDisabled = function() {
          return self.service.isEditingLsLevelDisabled(self.vmConfigContext, self.vmWorkflowMode);
       };
       this.isEditingLsLevelVisible = function() {
          return self.service.isEditingLsLevelVisible(self.vmConfigContext);
       };

       // End of Latency Sensitivity Level section
    }
})();
