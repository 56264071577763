namespace storage_ui {

   import IPromise = angular.IPromise;
   import Datastore$Info = com.vmware.vim.binding.vim.Datastore$Info;
   export class VvolNameAndContainerSelectionPageService {

      public static $inject = ["wizardPageService", "i18nService", "addDatastoreNameValidatorService", "datastoreConstants", "$q"];

      constructor(private wizardPageService: any,
                  private i18nService: any,
                  private addDatastoreNameValidatorService: any,
                  private datastoreConstants: any,
                  private $q: any) {

      }

      private validate(wizardScope: any): IPromise<boolean> {

         let hasSelection = !!wizardScope.vvolWizardManager.container;
         let nameAndContainerError = this.vvolDatastoreNameAndContainerValidation(
               wizardScope.vvolWizardManager,
               wizardScope.commonWizardManager.getDatastoreName()
         );

         let isValid = hasSelection && !nameAndContainerError;
         if (!isValid) {
            let errorMessage = nameAndContainerError || this.i18nService.getString('StorageUi', 'addDatastoreWizard.vvolNameAndContainerPage.noSelection');
            this.invalidateWizard(wizardScope, errorMessage);
            return this.$q.when(false);
         }
         let self = this;
         let vvolDuplicateNameValidationCallback = (datastoreInfo: Datastore$Info)=> {
            if (datastoreInfo._type !== "com.vmware.vim.binding.vim.host.VvolDatastoreInfo") {
               return self.i18nService.getString(
                     "StorageUi", "addDatastoreWizard.nameValidator.default.duplicateNameDifferentType");
            } else {
               return wizardScope.vvolWizardManager.isNameInUse() ? null : self.i18nService.getString(
                     "StorageUi", "addDatastoreWizard.nameValidator.default.duplicateName");
            }
         };

         return this.addDatastoreNameValidatorService.validate(
               wizardScope.commonWizardManager.getContextObject(),
               wizardScope.commonWizardManager.getDatastoreName(),
               this.datastoreConstants.maxNameLengthForNonNfsDatastore,
               vvolDuplicateNameValidationCallback)
               .then((datastoreNameValidationError: any)=> {
                  if (datastoreNameValidationError) {
                     this.invalidateWizard(wizardScope, datastoreNameValidationError);
                     return false;
                  }
                  wizardScope.wizardConfig.validationBanner.messages = [];
                  this.wizardPageService.markPageComplete(wizardScope.wizardConfig,
                        wizardScope.commonWizardManager.getDatastoreType());
                  return true;
               });
      }


      private invalidateWizard(wizardScope: any, errorMessage: any) {
         this.wizardPageService.markPageIncompleteWithError(
               wizardScope.wizardConfig, errorMessage);
         this.wizardPageService.invalidateNextPages(wizardScope.wizardConfig,
               wizardScope.commonWizardManager.getDatastoreType(),
               wizardScope.wizardConfig.currentPage.title);
      }

      public build(wizardScope: any): any {
         return {
            title: this.i18nService.getString("StorageUi", "addDatastoreWizard.vvolNameAndContainerPage.title"),
            description: this.i18nService.getString("StorageUi", "addDatastoreWizard.vvolNameAndContainerPage.description"),
            contentUrl: "storage-ui/resources/storage/views/wizard/createDatastore/pages/vvol/VvolNameAndContainerSelectionPage.html",
            state: undefined,
            onCommit: () => {
               wizardScope.wizardConfig.loading = true;

               return this.validate(wizardScope).then((isPageValid)=> {
                  wizardScope.wizardConfig.loading = false;
                  return isPageValid;
               }, (error) => {
                  wizardScope.removeLoadingAndShowError(error);
                  return false;
               });
            }
         };
      }

      vvolDatastoreNameAndContainerValidation = (vvolWizardManager: VvolWizardManager, datastoreName: string): string|null => {
         if (!vvolWizardManager.container) {
            return null;
         }
         let nameInUse = vvolWizardManager.isNameInUse();
         let containerInUse = vvolWizardManager.getContainerInUse();

         if (!nameInUse && vvolWizardManager.container.info.datastoreInfo) {
            return this.i18nService.getString("StorageUi",
                  "addDatastoreWizard.nameValidator.vvol.differentNameSameContainer",
                  vvolWizardManager.container.info.datastoreInfo.name);
         }

         if (nameInUse && containerInUse
               && (!vvolWizardManager.container.info.datastoreInfo
               || vvolWizardManager.container.info.datastoreInfo.name !== datastoreName)) {
            return this.i18nService.getString("StorageUi",
                  "addDatastoreWizard.nameValidator.vvol.duplicateNameDifferentContainer",
                  containerInUse.name);
         }

         return null;
      }

   }
   angular.module("com.vmware.vsphere.client.storage")
         .service("vvolNameAndContainerSelectionPageService", VvolNameAndContainerSelectionPageService);
}
