/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
(function () {
   'use strict';
   angular
      .module('com.vmware.vsphere.client.network')
      .service('portFailoverPolicyPageService', portFailoverPolicyPageService);

   portFailoverPolicyPageService.$inject = [
      'clarityModalService',
      '$q',
      'networkUtil',
      'networkUiConstants'
   ];

   function portFailoverPolicyPageService(clarityModalService, $q,
      networkUtil, networkUiConstants) {

      return {
         getFailureDetectionValidationMessage: getFailureDetectionValidationMessage,
         getLoadBalancingMessages: getLoadBalancingMessages,
         getFailoverOrderWarnMessage: getFailoverOrderWarnMessage,
         formatMessages: formatMessages,
         confirmWarningModal: confirmWarningModal
      };

      function getFailureDetectionValidationMessage(config) {
         if (config.failureDetectionOverridden) {
            var numberOfNicsAssigned = 0;

            if (config.pnics) {
               if (config.pnics.active) {
                  numberOfNicsAssigned += config.pnics.active.length;
               }
               if (config.pnics.standby) {
                  numberOfNicsAssigned += config.pnics.standby.length;
               }
               if (config.pnics.unused) {
                  numberOfNicsAssigned += config.pnics.unused.length;
               }
            }

            if (config.checkBeacon && numberOfNicsAssigned < 2) {
               return networkUtil.getString(
                  'PortPolicyData.failover.error.beaconProbingSingleNic');
            }
         }
         return null;
      }

      // formats the given messages into a html with the corresponding icon classes
      // if the messages == 3 - the first is a warning and the rest - info messages
      // if the messages != 3 - all of them are info messages
      function formatMessages(messages) {
         if (!messages || messages.length === 0) {
            return null;
         }

         var result = [];
         _.each(messages, function(message) {
            result.push(formatMessage(message));
         });

         return result.length > 0 ? result.join("<br>") : null;
      }

      function formatMessage(message) {
         return "<div class='row'>" +
                   "<span class='col-md-1'>" +
                     "<span class='" + message.iconClass + " failover-icon'></span>" +
                   "</span>" +
                   "<span class='col-md-11 info-msg-color'>" + message.text + "</span>" +
                "</div>";
      }

      function confirmWarningModal(messages) {
         var deferred = $q.defer();
         var modalOptions = {
            title: networkUtil.getString(
                  'PortPolicyData.failover.lb.loadbalance_ip.confirmation.title'),
            size: 'md',
            defaultButton: 'submit',
            alerts: [],
            dialogData : {
               messages: [],
               dialogInfo: networkUtil.getString("IssuesConfirmationDialog.tip")
            },
            contentTemplate: 'network-lib-ui/resources/network-lib/dvpg/' +
                  'edit/portgroupFailoverErrorWarningDialog.html',
            onSubmit : function () {
               deferred.resolve(true);
               return true;
            }
         };

         _.forEach(messages, function (message) {
            modalOptions.dialogData.messages.push(buildWarnMessage(message.text, true));
         });

         // open modal
         clarityModalService.openOkCancelModal(modalOptions);
         return deferred.promise.then(function (value) {
            return value;
         });
      }

      function getLoadBalancingMessages(config) {
         if (!config.loadBalancingOverridden ||
            config.loadBalancing !== networkUiConstants.LoadBalancingPolicy.IP) {
            return null;
         }

         var messages = getLoadBalancingInfoMessages();
         var checkBeacon = config.failureDetectionOverridden ? config.checkBeacon : null;

         var standbyAdapters = config.failoverOrderOverridden && config.pnics ?
            config.pnics.standby : [];

         var warningMessage = getLoadBalancingWarningMessage(standbyAdapters, checkBeacon);

         if (warningMessage) {
            messages = [warningMessage].concat(messages);
         }
         return messages;
      }

      function getFailoverOrderWarnMessage(isFailoverOrderOverridden, pnics) {
         if (isFailoverOrderOverridden &&
               pnics && pnics.active && pnics.active.length === 0) {
            return buildWarnMessage(networkUtil.getString(
                  'PortPolicyData.failover.error.noActiveAdapters'));
         }
         return null;
      }

      function getLoadBalancingWarningMessage(standby, checkBeacon) {
         if (standby && standby.length > 0) {
            var stringToLocalize = checkBeacon ?
               "PortPolicyData.failover.lb.loadbalance_ip.warn.beaconProbingAndStandbyUplinks" :
               "PortPolicyData.failover.lb.loadbalance_ip.warn.standbyUplinks";
            return buildWarnMessage(networkUtil.getString(stringToLocalize));
         } else if (checkBeacon) {
            return buildWarnMessage(networkUtil.getString(
               "PortPolicyData.failover.lb.loadbalance_ip.warn.beaconProbing"));
         }
         return null;
      }

      function getLoadBalancingInfoMessages() {
         var messages = [
            buildInfoMessage(networkUtil.getString(
               "PortPolicyData.failover.lb.loadbalance_ip.info.etherChannel")),
            buildInfoMessage(networkUtil.getString(
               "PortPolicyData.failover.lb.loadbalance_ip.info.portGroupPolicies"))
         ];
         return messages;
      }

      function buildInfoMessage(infoMessage) {
         return {
            text: infoMessage,
            iconClass: 'vsphere-icon-info'
         };
      }

      function buildWarnMessage(warnMessage, isDialogMessage) {
         return {
            text: warnMessage,
            iconClass: isDialogMessage ? 'warning' : 'vsphere-icon-status-warning'
         };
      }
   }
})();
