namespace storage_ui {

   export class StorageProvidersService {

      public static $inject = ["i18nService", "dataService", "mutationService",
         "storageProviderConstants", "clarityModalService", "$rootScope"];

      private static STORAGE_PROVIDER_SYSTEM_PROPERTY = "vasa:storageProviderSystemInfoEx";

      constructor(private i18nService: any,
                  private dataService: any,
                  private mutationService: any,
                  private storageProviderConstants: any,
                  private clarityModalService: any,
                  private $rootScope: any) {
      }

      public getStorageProviderSystemInfoEx(vcId: string): any {
         return this.dataService.getProperties(vcId,
               [StorageProvidersService.STORAGE_PROVIDER_SYSTEM_PROPERTY])
               .then(function (response:any) {
                  return response && response[StorageProvidersService.STORAGE_PROVIDER_SYSTEM_PROPERTY];
               });
      }

      public resyncInternalVasaProviders(vcId: string) {
         let spec = {
            _type: "com.vmware.vsphere.client.sms.ResyncVsanProvidersSpec"
         };

         this.mutationService.apply(vcId, spec._type, spec).then(() => {
            this.$rootScope.$broadcast('vsphere.core.storage.storageProviders.providersList.refreshProvidersList');
         });
      }

      public refreshCertificate(vcId: string, storageProvider: any): void {
         if (!vcId || !storageProvider || !storageProvider.providerInfo) {
            return;
         }

         if (storageProvider.providerInfo.certificateStatus === this.storageProviderConstants.certificateStatus.expired) {

            let modalOptions = {
               title: this.i18nService.getString("StorageUi",
                     "storage.storageProviders.actions.refresh.certificateExpiredTitle"),
               hideCancelButton: true,
               message: this.i18nService.getString("StorageUi",
                     "storage.storageProviders.actions.refresh.certificateExpiredWarning"),
               icon: "icon-warning-32",
               submit: function () {
                  return true;
               },
               preserveNewlines: true
            };
            this.clarityModalService.openConfirmationModal(modalOptions);
            return;
         }

         let spec: any = {
            vasaStorageProvider: storageProvider.provider
         };

         if (storageProvider.providerInfo.status === this.storageProviderConstants.vasaProviderStatus.disconnected) {
            spec._type = "com.vmware.vsphere.client.sms.ReconnectStorageProviderSpec";
         } else {
            spec._type = "com.vmware.vsphere.client.sms.RefreshStorageProviderCertificateSpec";
         }

         this.mutationService.apply(vcId, spec._type, spec).then(() => {
            this.$rootScope.$broadcast('vsphere.core.storage.storageProviders.providersList.refreshProvidersList');
         });
      }

      public syncProvider(vcId: string, storageProvider: any) {
         if (!storageProvider || !storageProvider.provider) {
            return;
         }

         let spec = {
            _type: "com.vmware.vsphere.client.sms.StorageProviderSyncSpec",
            vasaStorageProvider: storageProvider.provider
         };

         this.mutationService.apply(vcId, spec._type, spec).then(() => {
            this.$rootScope.$broadcast('vsphere.core.storage.storageProviders.providersList.refreshProvidersList');
         });
      }

      public removeStorageProvider(vcId: string, storageProvider: any) {
         if (!storageProvider || !storageProvider.providerInfo || !storageProvider.providerInfo.uid) {
            return;
         }

         let spec = {
            _type: "com.vmware.vsphere.client.sms.StorageProviderUnregisterSpec",
            uid: storageProvider.providerInfo.uid
         };

         this.mutationService.apply(vcId, spec._type, spec,
               this.i18nService.getString("StorageUi",
                     "storage.storageProviders.actions.remove.taskName")).then(() => {
            this.$rootScope.$broadcast('vsphere.core.storage.storageProviders.providersList.refreshProvidersList');
         });
      }
   }

   angular.module("com.vmware.vsphere.client.storage")
         .service("storageProvidersService", StorageProvidersService);
}
