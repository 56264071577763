/* Copyright 2015 VMware, Inc. All rights reserved. -- VMware Confidential */
/**
 * The edit settings controller.
 */
(function() {
   'use strict';

   angular
         .module('com.vmware.vsphere.client.vm')
         .controller('VmEditSettingsController', VmEditSettingsController);

   VmEditSettingsController.$inject = [
      '$scope',
      '$element',
      '$timeout',
      'creationTypeConstants',
      'vmHardwareEditService',
      'vmHardwareCreateService',
      'vmHardwareCloneService'];

   function VmEditSettingsController (
         $scope,
         $element,
         $timeout,
         creationTypeConstants,
         vmHardwareEditService,
         vmHardwareCreateService,
         vmHardwareCloneService
   ) {
      initialize();

      // ============public =========

      $scope.isInputStateValid = function () {
         var isValid = true;

         isValid = !$scope.loading;
         validationErrorsMap.forEach(function(flag) {
            isValid = isValid && flag;
         });

         return isValid;
      };

      // collect validation errors from the child tabs
      $scope.$on("onChildValidationChange", function( $event, childId, isValidState ) {
         $event.stopPropagation();
         validationErrorsMap.set(childId, isValidState);
      });

      $scope.$on("editSettingsAlertMessageChanged", function( $event, options ) {
         $event.stopPropagation();
         $scope.alertOptions = options;
      });

      $scope.onTabSelected = function($event) {
         $scope.alertOptions = undefined;
      };

      // ============private =========
      var validationErrorsMap;
      function initialize () {
         validationErrorsMap = new Map();
         $scope.deployVmtx = false;

         $scope.loading = true;
         if ($scope.modalOptions) {
            fetchForEditMode();
         } else if ($scope.vmParams.getCreationType() === creationTypeConstants.CLONE_VM_TO_VM ||
               $scope.vmParams.getCreationType() === creationTypeConstants.CLONE_TEMPLATE_TO_VM ||
               $scope.vmParams.getCreationType() === creationTypeConstants.DEPLOY_VM_FROM_VMTX) {
            fetchForCloneMode();
            $scope.deployVmtx = $scope.vmParams.getCreationType() === creationTypeConstants.DEPLOY_VM_FROM_VMTX;
         } else {
            fetchForCreate();
         }
      }

      function fetchForCreate () {
         vmHardwareCreateService.getAndFormatData($scope.vmParams, $scope.wizardViewData)
               .then(setHardwareConfig);
      }

      function fetchForEditMode () {
         $scope.startEditSettings = Date.now();
         var vmId = $scope.modalOptions.availableTargets[0];
         $scope.vmId = vmId;
         vmHardwareEditService.getAndFormatData(vmId)
               .then(function(result) {
                  setHardwareConfig(result.hardwareConfigPromise);
               });
      }

      function fetchForCloneMode () {
         vmHardwareCloneService.getAndFormatData($scope.vmParams, $scope.wizardViewData)
               .then(setHardwareConfig);
      }

      function setHardwareConfig (hardwareConfigData) {

         var log = function(msg) {
            if (window && window.console && window.console.log) {
               window.console.log(msg);
            }
         };

         $scope.endEditSettings = Date.now();
         log("Edit VM Settings blocking data loading took " + ($scope.endEditSettings - $scope.startEditSettings) + "ms");
         $scope.loading = false;
         $scope.isInvalid = false;
         $scope.hardwareConfigData = hardwareConfigData;
         var ctx = hardwareConfigData.vmConfigContext;
         var times = ctx? ctx.times: null;
         if (times) {
            log("Java timing " + times.javaTiming);
            var javaTotal = times.javaFinishTime - times.javaStartTime;
            log("Java total for getVmConfigContext = " + javaTotal + "ms");
         }

         var callback = function() {
            $scope.showVmOptions = true;
         };

         setTimeout(callback, 1000);

         // focus the first tab on the page
         $timeout(function () {
            var firstTab = $element[0].querySelector("clr-tab-link");
            if (firstTab) {
               firstTab.querySelector("button").focus();
            }
         }, 0);
      }
   }
})();
