angular.module('com.vmware.vsphere.client.vm').service('virtualSCSIControllerInflator', [
   'VirtualSCSIController',
   function(VirtualSCSIController) {
      return {
         build: function(rawDevice, deviceOptionsByDeviceType) {
            var deviceOption = deviceOptionsByDeviceType[rawDevice._type];

            return new VirtualSCSIController(rawDevice, {
               maxDisks: deviceOption.numSCSIDisks.max,
               maxCdroms: deviceOption.numSCSICdroms.max,
               reservedNode: deviceOption.scsiCtlrUnitNumber
            });
         }
      };
   }
]);
