angular.module('com.vmware.vsphere.client.vm').directive('vmHardwareSummaryOther', ['i18nService', function (i18nService) {
   return {
      templateUrl: 'vm-ui/resources/vm/views/summary/vmHardwareSummaryOther.html',
      scope: {
         ctrl: '=',
         devices: '='
      },
      link: function(scope) {
         scope.i18n = i18nService.getString;

         scope.getSummaryText = function (device, type) {
            var summary = device.deviceInfo.summary;
            var label = device.deviceInfo.label;
            var deviceType = device._type;

            if (deviceType === 'com.vmware.vim.binding.vim.vm.device.VirtualKeyboard' ||
                deviceType === 'com.vmware.vim.binding.vim.vm.device.VirtualPointingDevice') {
               return label;
            } else if (type === 'InputDevice') {
               return summary;
            } else {
               return (summary === label) ? summary :
                     summary ? label + ' (' + summary + ')' : label;
            }
         };
      }
   };
}]);
