(function() {
   'use strict';
   angular.module('com.vmware.vsphere.client.host').controller('EditNfsKerberosCredentialsController',
         EditNfsKerberosCredentialsController);

   EditNfsKerberosCredentialsController.$inject = ['$scope', 'dataService',
         'i18nService', 'mutationService', 'vuiConstants', '$q'];

   function EditNfsKerberosCredentialsController($scope, dataService, i18nService,
         mutationService, vuiConstants, $q) {
      var objectId = $scope.modalOptions.objectId;
      // this is in case user clicks OK without updating values; in that case
      // updateValues method should run so that red borders are displayed
      var valuesUpdated = false;
      var arePasswordsMatching = true;
      var isPasswordEmpty = true;
      var isUsernameEmpty = true;
      // error message for dialog alert
      var errorMessage = i18nService.getString('HostUi', 'nfsCredentials.edit.emptyPassword');
      // tooltip for username error
      var self = this;

      self.loading = true;
      self.usernameError = {
         message: '',
         isVisible: false
      };
      // tooltip for password error
      self.passwordError = {
         message: '',
         isVisible: false
      };

      $scope.modalOptions.onSubmit = function () {
         $scope.modalOptions.alerts = [];
         if (!isUsernameEmpty && !isPasswordEmpty && arePasswordsMatching) {
            mutationService.apply(
                  objectId,
                  "com.vmware.vsphere.client.storage.NfsEditCredentialsSpec",
                  {
                     userName: self.username,
                     password: self.password
                  });
            return true;
         } else {
            // thsi is called to display red borders and tooltips appropriately in
            // case OK is clicked without updating the inputs
            if (!valuesUpdated) {
               self.updateValue();
            }
            $scope.modalOptions.alerts  = [{
               text: errorMessage,
               type: vuiConstants.notifications.type.ERROR
            }];
            return false;
         }
      };

      self.updateValue = function() {
         valuesUpdated = true;
         arePasswordsMatching = self.password === self.confirmPassword;
         isPasswordEmpty = (!self.password) || self.password === '';
         isUsernameEmpty = (!self.username) || self.username === '';
         self.passwordError.isVisible = !arePasswordsMatching || isPasswordEmpty;
         self.usernameError.isVisible = isUsernameEmpty;

         // set username error
         if (isUsernameEmpty) {
            errorMessage = self.usernameError.message =
                  i18nService.getString('HostUi', 'nfsCredentials.edit.emptyUserName');
         }

         // set password error
         if (isPasswordEmpty) {
            errorMessage = self.passwordError.message =
                  i18nService.getString('HostUi', 'nfsCredentials.edit.emptyPassword');
         } else if (!arePasswordsMatching) {
            errorMessage = self.passwordError.message =
                  i18nService.getString('HostUi', 'nfsCredentials.edit.confirmPassword.error');
         }
      };

      getViewData();

      function getViewData() {
         dataService.getProperties(objectId, ['nfsKerberosUser'])
               .then(function(data) {
                  if (data) {
                     self.username = data.nfsKerberosUser;
                     isUsernameEmpty = !data.nfsKerberosUser;
                     if (isUsernameEmpty) {
                        errorMessage = i18nService
                              .getString('HostUi', 'nfsCredentials.edit.emptyUserName');
                     }
                  } else {
                     isUsernameEmpty = true;
                     errorMessage = i18nService
                           .getString('HostUi', 'nfsCredentials.edit.emptyUserName');
                  }
                  self.loading = false;
               })
               .catch(function(error) {
                  self.loading = false;
                  $q.reject(error);
               });
      }
   }
})();

