/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace dvs_ui {

   export class DvpgCloneTrafficRulesComponent {

      public bindings: any;
      public controller: any;
      public templateUrl: string;

      constructor() {
         this.bindings = {
            model: "<"
         };

         this.controller = DvpgCloneTrafficRulesComponentController;

         this.templateUrl = "dvs-ui/resources/dvs/dvpg/trafficfilter/clone/" +
               "dvpgCloneTrafficRulesTemplate.html";
      }
   }

   class DvpgCloneTrafficRulesComponentController {

      static $inject = ["i18nService"];

      public model: DvpgCloneTrafficRulesModel;

      private portGroupConfig: any;
      private cloneRulesInfoMessage: string;
      private selectPortgroupsMessage: string;

      constructor(private i18nService: any) {
      }

      $onInit(): void {
         this.selectPortgroupsMessage =
               this.i18nService.getString(
                     'DvsUi', 'trafficFilter.cloneRulesDialog.description');
         this.cloneRulesInfoMessage =
               this.i18nService.getString(
                     "DvsUi", "trafficFilter.cloneRulesDialog.info",
                     this.model.dvpgName);

         this.portGroupConfig = {
            contextObject: this.model.dvsId,
            multipleSelect: true,
            listTabConfig: {
               label: this.i18nService
                     .getString("DvsUi", "dvpg.manage.portGroupList.tabs.filter"),
               listConfig: [{
                  label: "",
                  listViewId: "vsphere.core.dvs.dvpg.manage.list",
                  dataModels: ['DistributedVirtualPortgroup'],
                  filterId: "dvpgExcludedObjectsFilter",
                  filterParams: [this.model.dvpgId]
               }]
            },
            selectedObjects: _.map(this.model.selectedPortgroups,
                  (portgroup: any): string => {
                     return portgroup.id;
                  }),
            onSelectionChanged: (newSelectedItems: any[]): void => {
               this.model.selectedPortgroups = newSelectedItems;
            }
         };
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .component("dvpgCloneTrafficRules", new DvpgCloneTrafficRulesComponent());
}
