(function() {
   'use strict';
   angular.module('com.vmware.vsphere.client.commonModule')
      .controller('TagsPortletController', TagsPortletController);

   TagsPortletController.$inject = ['$scope', 'RemoveTagGridFactory', 'i18nService', 'actionsService', 'clarityModalService'];

   function TagsPortletController($scope, RemoveTagGridFactory, i18nService, actionsService, clarityModalService) {
      var self = this;
      self.objectId = $scope._route.objectId;

      $scope.portlet.footerLinks = [
         {
            label: i18nService.getString('Common', 'tag.dashboard.assign'),
            ariaLabel: i18nService.getString('Common', 'tag.dashboard.assignAriaLabel'),
            onClick: onAssignTagClicked,
            enabled: true
         },
         {
            label: i18nService.getString('Common', 'tag.dashboard.remove'),
            ariaLabel: i18nService.getString('Common', 'tag.dashboard.removeAriaLabel'),
            onClick: onDetachTagClicked,
            enabled: false
         }];

      self.tagGrid = RemoveTagGridFactory.make(self.objectId);
      self.tagGrid.populateDataGrid(self.objectId);

      self.refresh = function(){
         self.tagGrid.refresh(self.objectId);
      };

      self.onTagSelectionChanged = function(selectedTags) {
         setRemoveTagLinkEnabled(!!selectedTags);
         self.selectedTags = selectedTags;
      };

      self.tagGrid.setTagSelectionListener(self.onTagSelectionChanged);

      function setRemoveTagLinkEnabled(linkEnabled) {
         $scope.portlet.footerLinks[1].enabled = linkEnabled;
      }

      function onDetachTagClicked($event) {
         if(self.selectedTags) {
            var modalOptions = {
               title: i18nService.getString('Common', 'tag.remove.warning.title'),
               message: i18nService.getString('Common', 'tag.remove.warning.text', self.selectedTags[0].name, $scope.currentObject.name),
               saveButtonLabel: i18nService.getString('Common', 'tag.remove.warning.submit'),
               submit: function() {
                  self.tagGrid.removeTag();
                  return true;
               },
               focusTarget: $event.currentTarget
            };
            if(self.selectedTags.length > 1) {
               modalOptions.message = i18nService.getString('Common', 'tag.multi.remove.warning.text',
                   self.selectedTags.length, $scope.currentObject.name);
            }
            clarityModalService.openConfirmationModal(modalOptions);
         }
      }

      self.assignMutex = false;

      function onAssignTagClicked($event) {
         if (!self.assignMutex) {
            self.assignMutex = true;
            actionsService.getAction("vsphere.core.common.actions.assignTag", [self.objectId])
               .then(function(action) {
                  actionsService.invokeAction(action, {focusTarget: $event.currentTarget});
               }).finally(function () {
                  self.assignMutex = false;
               });
         }
      }
   }
}());
