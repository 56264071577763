namespace storage_ui {

   import IPromise = angular.IPromise;

   declare let moment: any;

   export class StorageProviderListItem {
      public id: string;

      public parentId: string|null;

      public nameFormatted: string;

      public providerStatusFormatted: string;

      public activeStandbyFormatted: string;

      public priorityFormatted: string;

      public urlFormatted: string;

      public lastSyncTime: number;

      public lastSyncTimeFormatted: string;

      public vasaApiVersionFormatted: string;

      public certificateExpiryTime: number;

      public certificateExpiryTimeFormatted: string;

      public certificateExpiryTimeIcon: string|null;

      public statusFault: any;

      public _isStorageProviderItem: boolean = false;

      public _isStorageArrayItem: boolean = false;

      public _data: any;
   }

   export class StorageProvidersItemBuilderService {

      public static $inject = ["i18nService", "defaultUriSchemeUtil", "storageProviderConstants",
         "timeFormatterService", "vxPropertyViewService", "$q"];

      private NA_STRING: string;

      private readonly STORAGE_SUPPORTED_PROFILES_PREFIX = "storage.storageProviders.storageArrayView.supportedProfile.";

      constructor(private i18nService: any,
                  private defaultUriSchemeUtil: any,
                  private storageProviderConstants: any,
                  private timeFormatterService: any,
                  private vxPropertyViewService: any,
                  private $q: any) {

         this.NA_STRING = i18nService.getString("StorageUi", "informationNotAccessible");
      }

      public getProviderCentricData(storageProviderSystemInfoEx: any[]): IPromise<StorageProviderListItem[]> {

         let self = this;
         let listItemIndex = 0;

         function generateId() {
            return "itemId_" + listItemIndex++;
         }

         let resultItems: StorageProviderListItem[] = [];

         let storageProviderItemsById:{ [key: string]: any; }  = {};

         let timeFormatPromises = [];

         _.forEach(storageProviderSystemInfoEx, function (infoItem) {
            _.forEach(infoItem.storageProviderInfoList, function (storageProviderInfo: any) {
               let providerItemId = storageProviderInfo.provider.value;

               if (!storageProviderItemsById.hasOwnProperty(providerItemId)) {
                  let providerItem = self.createStorageProviderItem(storageProviderInfo, providerItemId, timeFormatPromises);
                  storageProviderItemsById[providerItemId] = providerItem;
                  resultItems.push(providerItem);
               }

               let storageArrayItem = self.createStorageArrayItem(
                     infoItem, storageProviderInfo, generateId(), providerItemId);

               resultItems.push(storageArrayItem);
            });
         });

         return this.$q.all(timeFormatPromises).then(function () {
            return resultItems;
         });
      }

      private createStorageArrayItem(storageProviderSystemInfoEx: any,
                                     storageProviderInfo: any,
                                     id: string,
                                     parentId: string): StorageProviderListItem {

         let item = new StorageProviderListItem();

         item.id = this.generateStorageArrayItemId(storageProviderSystemInfoEx, parentId, id);
         item.parentId = parentId;
         item.nameFormatted = this.formatStorageArrayName(storageProviderSystemInfoEx, storageProviderInfo);
         item.providerStatusFormatted = "";
         item.activeStandbyFormatted = this.formatStorageArrayActiveStandby(storageProviderInfo);
         item.priorityFormatted = this.formatStorageArrayPriority(storageProviderSystemInfoEx, storageProviderInfo);
         item.urlFormatted = "";
         item.lastSyncTime = 0;
         item.lastSyncTimeFormatted = "";
         item.certificateExpiryTime = 0;
         item.certificateExpiryTimeFormatted = "";
         item.vasaApiVersionFormatted = "";
         item._data = storageProviderSystemInfoEx;
         item._isStorageArrayItem = true;

         return item;
      }

      public formatStorageArrayPriority(storageProviderSystemInfoEx: any, storageProviderInfo: any): string {

         if (!storageProviderSystemInfoEx.storageArray || !storageProviderSystemInfoEx.storageArray.uuid || !this.isVasaProviderInfo(storageProviderInfo.providerInfo)) {
            return this.NA_STRING;
         }

         let relatedArray: any = _.find(storageProviderInfo.providerInfo.relatedStorageArray,
               function (relatedStorageArray: any) {
                  return relatedStorageArray && relatedStorageArray.arrayId ===
                        storageProviderSystemInfoEx.storageArray.uuid;

               });

         if (relatedArray) {
            return "" + relatedArray.priority;
         }

         return this.NA_STRING;
      }

      public formatStorageArrayActiveStandby(storageProviderInfo: any): string {
         if (!storageProviderInfo) {
            return this.NA_STRING;
         }

         if (storageProviderInfo.isActive === true) {
            return this.i18nService.getString(
                  "StorageUi",
                  "storage.storageProviders.providersList.activeColumn.activeValue");
         } else if (storageProviderInfo.isActive === false) {
            return this.i18nService.getString(
                  "StorageUi",
                  "storage.storageProviders.providersList.activeColumn.standbyValue");
         }
         return this.NA_STRING;
      }

      private generateStorageArrayItemId(storageProviderSystemInfoEx: any,
                                    parentId:string, defaultId:string): string {
         if (!storageProviderSystemInfoEx.storageArray) {
            return defaultId;
         }

         return storageProviderSystemInfoEx.storageArray.uuid + "_" + parentId;
      }

      public formatStorageArrayName(storageProviderSystemInfoEx: any,
                                    storageProviderInfo: any): string {

         let numberOfProviders = storageProviderSystemInfoEx.storageProviderInfoList ?
               storageProviderSystemInfoEx.storageProviderInfoList.length : 0;
         let onlineProviders = storageProviderSystemInfoEx.numOnlineStorageProviders;

         if (!storageProviderSystemInfoEx.storageArray) {
            if (this.isIndependentPersistentProvider(storageProviderInfo)) {
               // Show label for undefined storage arrays associated with
               // arrayIndependent persistent internal provider.
               return this.i18nService.getString(
                     "StorageUi",
                     "storage.storageProviders.providersList.nameColumn.internallyManagedStorageArray");
            } else {
               // Show label for undefined storage arrays
               return this.i18nService.getString(
                     "StorageUi",
                     "storage.storageProviders.providersList.nameColumn.undefinedStorageArrayTitleFormat",
                     onlineProviders, numberOfProviders);
            }

         } else {
            let dsName = storageProviderSystemInfoEx.storageArrayFriendlyName
                  || storageProviderSystemInfoEx.storageArray.name;

            return this.i18nService.getString(
                  "StorageUi", "storage.storageProviders.providersList.nameColumn.storageArrayTitleFormat",
                  dsName, onlineProviders, numberOfProviders);
         }
      }

      private isIndependentPersistentProvider(storageProvider: any): boolean {
         if (!storageProvider || !this.isVasaProviderInfo(storageProvider.providerInfo)) {
            return false;
         }

         let providerInfo = storageProvider.providerInfo;

         let isArrayIndependent = !!providerInfo.arrayIndependentProvider;
         let isPersistent = providerInfo.type === this.storageProviderConstants.vasaProviderInfoType.PERSISTENCE;
         let isInternal = providerInfo.category === this.storageProviderConstants.vasaProviderInfoCategory.internal;

         return isArrayIndependent && isPersistent && isInternal;
      }

      public isVasaProviderInfo(providerInfo:any) {
         return providerInfo && providerInfo._type === "com.vmware.vim.binding.sms.provider.VasaProviderInfo";
      }

      public formatActivationMode(providerInfo:any) {
         if (this.isVasaProviderInfo(providerInfo)) {
            if (providerInfo.needsExplicitActivation === true) {
               return this.i18nService.getString("StorageUi", "storage.storageProviders.providersList.activation.manual");
            } else {
               return this.i18nService.getString("StorageUi", "storage.storageProviders.providersList.activation.auto");
            }
         }

         return this.NA_STRING;
      }

      public formatNamespace(providerInfo:any) {
         if (this.isVasaProviderInfo(providerInfo)) {
            return providerInfo.namespace || this.NA_STRING;
         }

         return this.NA_STRING;
      }

      public formatProviderId(providerInfo:any) {
         if (this.isVasaProviderInfo(providerInfo)) {
            return providerInfo.providerId || this.NA_STRING;
         }

         return this.NA_STRING;
      }

      public formatSupportedProfiles(supportedProviderProfile:any): string[] {
         if (supportedProviderProfile) {
            let profileNames: string[] = [];

            _.forEach(supportedProviderProfile, (profile: string) => {
               if (profile) {
                  let formattedProfileName: string = this.i18nService.getString(
                        "StorageUi", this.STORAGE_SUPPORTED_PROFILES_PREFIX + profile);

                  // Check if the provided storage profile has localization.
                  // If not use the provided value from backend.
                  if (formattedProfileName.indexOf(this.STORAGE_SUPPORTED_PROFILES_PREFIX) === 0) {
                     formattedProfileName = profile;
                  }
                  if (formattedProfileName) {
                     profileNames.push(formattedProfileName);
                  }
               }
            });

            if (profileNames.length) {
               return profileNames;
            }
         }

         return [this.NA_STRING];
      }

      public formatArrayProperty(property: any): any {
         if (!property || property.length === 0) {
            return this.NA_STRING;
         }

         if (property.join(" ").trim()) {
            return property;
         }
         return this.NA_STRING;
      }

      public getStorageArrayGeneralDetails(storageProviderListItem: StorageProviderListItem): any {

         let storageArray = storageProviderListItem && storageProviderListItem._data && storageProviderListItem._data.storageArray;
         if (!storageArray) {
            return null;
         }

         let builder = this.vxPropertyViewService.createPropertyViewBuilder();
         let generalCategory = builder.category("general");
         let generalSection = generalCategory.section("generalSection");

         generalSection.property(
               this.i18nService.getString("StorageUi", "storage.storageProviders.storageArrayView.arrayName"),
               storageProviderListItem._data.storageArrayFriendlyName || storageArray.name || this.NA_STRING);

         generalSection.property(
               this.i18nService.getString("StorageUi", "storage.storageProviders.storageArrayView.uuid"),
               storageArray.uuid || this.NA_STRING);

         generalSection.property(
               this.i18nService.getString("StorageUi", "storage.storageProviders.storageArrayView.vendorId"),
               storageArray.vendorId || this.NA_STRING);

         generalSection.property(
               this.i18nService.getString("StorageUi", "storage.storageProviders.storageArrayView.modelId"),
               storageArray.modelId || this.NA_STRING);

         generalSection.property(
               this.i18nService.getString("StorageUi", "storage.storageProviders.storageArrayView.firmware"),
               storageArray.firmware || this.NA_STRING);

         generalSection.property(
               this.i18nService.getString("StorageUi", "storage.storageProviders.storageArrayView.alternateName"),
               this.formatArrayProperty(storageArray.alternateName));

         generalSection.property(
               this.i18nService.getString("StorageUi", "storage.storageProviders.storageArrayView.supportedBlockInterface"),
               this.formatArrayProperty(storageArray.supportedBlockInterface));

         generalSection.property(
               this.i18nService.getString("StorageUi", "storage.storageProviders.storageArrayView.supportedFileSystemInterface"),
               this.formatArrayProperty(storageArray.supportedFileSystemInterface));

         generalSection.property(
               this.i18nService.getString("StorageUi", "storage.storageProviders.storageArrayView.supportedProfile"),
               this.formatSupportedProfiles(storageArray.supportedProfile));

         return builder.build();
      }

      public getStorageProviderGeneralDetails(storageProviderListItem: StorageProviderListItem): any {

         let providerInfo = storageProviderListItem &&
               storageProviderListItem._data &&
               storageProviderListItem._data.providerInfo;

         if (!providerInfo) {
            return null;
         }

         let builder = this.vxPropertyViewService.createPropertyViewBuilder();
         let generalCategory = builder.category("general");
         let generalSection = generalCategory.section("generalSection");

         generalSection.property(
               this.i18nService.getString("StorageUi", "storage.storageProviders.generalSettingsView.providerName"),
               providerInfo.name || this.NA_STRING);

         generalSection.property(
               this.i18nService.getString("StorageUi", "storage.storageProviders.generalSettingsView.providerStatus"),
               storageProviderListItem.providerStatusFormatted);

         generalSection.property(
               this.i18nService.getString("StorageUi", "storage.storageProviders.generalSettingsView.activeStandbyStatus"),
               storageProviderListItem.activeStandbyFormatted);

         generalSection.property(
               this.i18nService.getString("StorageUi", "storage.storageProviders.generalSettingsView.needsExplicitActivation"),
               this.formatActivationMode(providerInfo));

         generalSection.property(
               this.i18nService.getString("StorageUi", "storage.storageProviders.generalSettingsView.url"),
               storageProviderListItem.urlFormatted);

         generalSection.property(
               this.i18nService.getString("StorageUi", "storage.storageProviders.generalSettingsView.providerVersion"),
               providerInfo.version || this.NA_STRING);

         generalSection.property(
               this.i18nService.getString("StorageUi", "storage.storageProviders.generalSettingsView.apiVersion"),
               storageProviderListItem.vasaApiVersionFormatted);

         generalSection.property(
               this.i18nService.getString("StorageUi", "storage.storageProviders.generalSettingsView.defaultNamespace"),
               this.formatNamespace(providerInfo));

         generalSection.property(
               this.i18nService.getString("StorageUi", "storage.storageProviders.generalSettingsView.providerId"),
               this.formatProviderId(providerInfo));

         generalSection.property(
               this.i18nService.getString("StorageUi", "storage.storageProviders.storageArrayView.supportedProfile"),
               this.isVasaProviderInfo(providerInfo)
                     ? this.formatSupportedProfiles(providerInfo.supportedProviderProfile)
                     : this.NA_STRING);

         return builder.build();
      }

      public getStorageProviderCertificateDetails(storageProviderListItem: StorageProviderListItem): IPromise<any> {

         if (!storageProviderListItem || !storageProviderListItem._data) {
            return this.$q.when(null);
         }

         let certificateDetailsInfo = storageProviderListItem._data.certificateDetailsInfo || {};

         let timePromises = {
            validFrom: this.timeFormatterService.formatDate(certificateDetailsInfo.validFrom),
            validTo: this.timeFormatterService.formatDate(certificateDetailsInfo.validTo),
         };

         return this.$q.all(timePromises).then((timePromisesResponse:any) => {

            let builder = this.vxPropertyViewService.createPropertyViewBuilder();
            let generalCategory = builder.category("general");
            let generalSection = generalCategory.section("generalSection");

            generalSection.property(
                  this.i18nService.getString("StorageUi", "storage.storageProviders.certificateDetailsView.subject"),
                  certificateDetailsInfo.subjectName || this.NA_STRING);

            generalSection.property(
                  this.i18nService.getString("StorageUi", "storage.storageProviders.certificateDetailsView.issuer"),
                  certificateDetailsInfo.issuerName || this.NA_STRING);

            generalSection.property(
                  this.i18nService.getString("StorageUi", "storage.storageProviders.certificateDetailsView.version"),
                  certificateDetailsInfo.version || this.NA_STRING);

            generalSection.property(
                  this.i18nService.getString("StorageUi", "storage.storageProviders.certificateDetailsView.kind"),
                  certificateDetailsInfo.kind || this.NA_STRING);

            generalSection.property(
                  this.i18nService.getString("StorageUi", "storage.storageProviders.certificateDetailsView.validFrom"),
                  timePromisesResponse.validFrom || this.NA_STRING);

            generalSection.property(
                  this.i18nService.getString("StorageUi", "storage.storageProviders.certificateDetailsView.validTo"),
                  timePromisesResponse.validTo || this.NA_STRING);

            generalSection.property(
                  this.i18nService.getString("StorageUi", "storage.storageProviders.certificateDetailsView.algorithm"),
                  certificateDetailsInfo.algorithmName || this.NA_STRING);

            generalSection.property(
                  this.i18nService.getString("StorageUi", "storage.storageProviders.certificateDetailsView.publicKeyAlgorithm"),
                  certificateDetailsInfo.publicKeyAlgorithmName || this.NA_STRING);

            return builder.build();
         });
      }

      public getSupportedVendorIdItems(storageProviderListItem: StorageProviderListItem): any[] {
         let providerInfo = storageProviderListItem &&
               storageProviderListItem._data &&
               storageProviderListItem._data.providerInfo;

         if (!providerInfo || !this.isVasaProviderInfo(providerInfo)) {
            return [];
         }

         return _.map(providerInfo.supportedVendorModelMapping, function (mapping: any) {
            return {
               vendorId: mapping.vendorId,
               modelId: mapping.modelId
            };
         });
      }

      private createStorageProviderItem(storageProviderInfo: any, id: string, timeFormatPromises: any[]): StorageProviderListItem {
         let item = new StorageProviderListItem();

         item.id = id;
         item.parentId = null;
         item.nameFormatted = storageProviderInfo.providerInfo.name;
         item._data = storageProviderInfo;
         item._isStorageProviderItem = true;

         if (this.isVasaProviderInfo(storageProviderInfo.providerInfo)) {
            item.urlFormatted = storageProviderInfo.providerInfo.url || this.NA_STRING;
            item.vasaApiVersionFormatted = storageProviderInfo.providerInfo.vasaVersion || this.NA_STRING;
            item.providerStatusFormatted = this.formatProviderStatus(storageProviderInfo.providerInfo.status);
            item.statusFault = storageProviderInfo.providerInfo.statusFault;
            item.lastSyncTime = moment(storageProviderInfo.providerInfo.lastSyncTime).valueOf();
            item.lastSyncTimeFormatted = this.NA_STRING;
            if (storageProviderInfo.providerInfo.lastSyncTime && !isNaN(item.lastSyncTime)) {
               timeFormatPromises.push(this.timeFormatterService.formatDate(item.lastSyncTime).then(
                     function (formattedDate:string) {
                        item.lastSyncTimeFormatted = formattedDate;
                     }));
            }

            item.certificateExpiryTime = moment(storageProviderInfo.providerInfo.certificateExpiryDate).valueOf();
            item.certificateExpiryTimeFormatted =
                  this.formatCertificateExpiryTime(
                        storageProviderInfo.providerInfo.certificateStatus,
                        storageProviderInfo.providerInfo.certificateExpiryDate);
            item.certificateExpiryTimeIcon =
                  this.getCertificateExpiryTimeIcon(storageProviderInfo.providerInfo.certificateStatus);

            item.activeStandbyFormatted = this.NA_STRING;
            item.priorityFormatted = this.NA_STRING;
         }

         return item;
      }

      public getCertificateExpiryTimeIcon(certificateStatus: string): string|null {
         switch (certificateStatus) {
            case this.storageProviderConstants.certificateStatus.expired:
            case this.storageProviderConstants.certificateStatus.expiryHardLimitReached:
               return "vsphere-icon-status-error";
            case this.storageProviderConstants.certificateStatus.expirySoftLimitReached:
               return "vsphere-icon-status-warning";
         }
         return null;
      }

      public formatCertificateExpiryTime(certificateStatus: string, expiryDateStr: string): string {

         if (certificateStatus === this.storageProviderConstants.certificateStatus.expired) {
            return this.i18nService.getString(
                  "StorageUi", "storage.storageProviders.providersList.certificateExpiryColumn.certificateExpired");
         }
         if (!expiryDateStr) {
            return this.NA_STRING;
         }

         let expiryDate = moment(expiryDateStr);
         if (isNaN(expiryDate.valueOf())) {
            return this.NA_STRING;
         }

         let currentDate = moment();
         let totalDays = expiryDate.diff(currentDate, "days");

         return this.i18nService.getString(
               "StorageUi", "storage.storageProviders.providersList.certificateExpiryColumn.daysRemaining", totalDays);
      }

      public formatProviderStatus(status: string): string {
         switch (status) {
            case this.storageProviderConstants.vasaProviderStatus.offline:
               return this.i18nService.getString("StorageUi", "storage.storageProviders.providersList.statusColumn.offlineValue");
            case this.storageProviderConstants.vasaProviderStatus.online:
               return this.i18nService.getString("StorageUi", "storage.storageProviders.providersList.statusColumn.onlineValue");
            case this.storageProviderConstants.vasaProviderStatus.syncError:
               return this.i18nService.getString("StorageUi", "storage.storageProviders.providersList.statusColumn.syncErrorValue");
            case this.storageProviderConstants.vasaProviderStatus.connected:
               return this.i18nService.getString("StorageUi", "storage.storageProviders.providersList.statusColumn.connectedValue");
            case this.storageProviderConstants.vasaProviderStatus.disconnected:
               return this.i18nService.getString("StorageUi", "storage.storageProviders.providersList.statusColumn.disconnectedValue");
            default:
               return this.NA_STRING;
         }
      }
   }

   angular.module("com.vmware.vsphere.client.storage")
         .service("storageProvidersItemBuilderService", StorageProvidersItemBuilderService);
}
