/* Copyright 2018 VMware, Inc. All rights reserved. -- VMware Confidential */

import ScheduledMutationOperationSpec = com.vmware.vise.data.scheduling.ScheduledMutationOperationSpec;

namespace h5_vm {

   /**
    * A "builder" for the ScheduleTaskPageService.
    */
   export class VmCreateScheduledTaskMutationService {
      static $inject: string[] = [
         "mutationService",
      ];

      constructor(private mutationService: any) {
      }

      public createScheduledTask(scheduledTaskData: ScheduledMutationOperationSpec): any {

         return this.mutationService.add("com.vmware.vise.data.scheduling.ScheduledMutationOperationSpec",
               scheduledTaskData);
      }
   } // class

   angular.module("com.vmware.vsphere.client.vm").service("vmCreateScheduledTaskMutationService",
         VmCreateScheduledTaskMutationService);

} // namespace
