/* Copyright 2018 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace h5_client {
   module common_module_ui {

      export class ContentLibraryTemplatesViewController {

         public static $inject = [
            "i18nService",
            "navigation",
            "dataService",
            "featureFlagsService",
         ];
         public i18n: any;
         public showVmtxNotAvailableAlert: boolean = false;
         public showPublishAvailableAlert: boolean = false;

         constructor(private i18nService: any,
               private navigation: any,
               private dataService: any,
               private featureFlagsService: any
         ) {
         }

         public $onInit() {
            this.i18n = this.i18nService.getString.bind(this.i18nService, "LibraryUi");

            if (!this.featureFlagsService.CL_VMTX_SyncEnabled()) {
               return;
            }

            this.dataService.getProperties(this.navigation.getRoute().objectId,
                  ["isLocalLibrary", "publishInfo.published", "isSubscriptionsCapabilitySupported"])
                  .then((response: any) => {
                     this.showVmtxNotAvailableAlert = response["isLocalLibrary"] === false
                           && response["publishInfo.published"] === false;
                     this.showPublishAvailableAlert = response["isLocalLibrary"] === true
                           && response["publishInfo.published"] === true
                           && response["isSubscriptionsCapabilitySupported"] === true;
                  });
         }

         public hideWarning() {
            this.showVmtxNotAvailableAlert = false;
            this.showPublishAvailableAlert = false;
         }

      }

      angular.module("com.vmware.vsphere.client.commonModule")
            .controller("ContentLibraryTemplatesViewController", ContentLibraryTemplatesViewController);
   }
}
