(function () {
   'use strict';
   angular
         .module('com.vmware.vsphere.client.network')
         .controller('ConnectionSettingsPageController',
               ConnectionSettingsPageController);

   ConnectionSettingsPageController.$inject = [
      '$scope',
      'dataService',
      'i18nService'];

   function ConnectionSettingsPageController($scope,
                                             dataService,
                                             i18nService) {
      var hostStandardPortgroupInfoProperty = "portgroup:hostStandardPortgroupInfo";

      if (!$scope.connectionSettingsPageModel.isInitialized) {
         $scope.wizardConfig.loading = true;
         dataService.getProperties(
               $scope.hostId,
               [hostStandardPortgroupInfoProperty],
               {
                  propertyParams: [{
                     propertyName: hostStandardPortgroupInfoProperty,
                     parameterType: 'java.lang.String',
                     parameter: i18nService.getString(
                           'NetworkUi', 'AddNetworkingWizard.vmNetwork')
                  }]
               })
               .then(function (response) {
                  $scope.wizardConfig.loading = false;
                  if (response) {
                     if (response[hostStandardPortgroupInfoProperty]) {
                        $scope.addNetworkingSpec.networkLabel =
                              response[hostStandardPortgroupInfoProperty].uniquePortGroupName;
                        $scope.connectionSettingsPageModel.vlanIds = response[hostStandardPortgroupInfoProperty].vlanIds;
                     }
                  }
                  $scope.connectionSettingsPageModel.isInitialized = true;
               });
      }
   }
})();
