namespace h5_vm {

   import CustomizationSpecInfo = com.vmware.vim.binding.vim.CustomizationSpecInfo;
   import VmCustomizationRemovalSpec = com.vmware.vsphere.client.vm.customizations.VmCustomizationRemovalSpec;
   import OperationResult = com.vmware.vise.core.model.OperationResult;
   import IQService = angular.IQService;
   import IDeferred = angular.IDeferred;
   import IPromise = angular.IPromise;

   export class DeleteGosSpecService {

      public static $inject = ["defaultUriSchemeUtil",
         "mutationService",
         "actionConfirmationService",
         "$q"];

      constructor(private defaultUriSchemeUtil: any,
            private mutationService: any,
            private actionConfirmationService: any,
            private $q: IQService) {}

      confirmDelete(title: string, message: string): IPromise<any> {
         let deferred: IDeferred<any> = this.$q.defer();
         let alertProperties: any = {
            useClarity: true,
            title: title,
            message: message,
            yesHandler: () => {
               deferred.resolve();
            },
            noHandler: () => {
               deferred.reject();
            }
         };

         this.actionConfirmationService.showConfirmationDialog(alertProperties);
         return deferred.promise;
      }

      delete(specInfo: CustomizationSpecInfoCustom): IPromise<OperationResult> {
         let spec: VmCustomizationRemovalSpec = new VmCustomizationRemovalSpec();
         spec.specName = specInfo.name;

         let specManagerId = this.defaultUriSchemeUtil.getVsphereObjectId(
               specInfo.vcService.content.customizationSpecManager);

         return this.mutationService.remove(specManagerId, spec._type, spec);
      }
   }

   angular.module("com.vmware.vsphere.client.vm")
         .service("deleteGosSpecService", DeleteGosSpecService);
}
