/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace h5_vm {

export class MigrateFinishPageModel {
   /**
    * [page specific]
    *
    * If the compute resource is changed since the last data retrieval
    */
   public isComputeResourceChanged?: boolean;

   /**
    * [page specific]
    *
    * If the folder is changed since the last data retrieval
    */
   public isFolderChanged?: boolean;

   /**
    * [page specific]
    *
    * The retrieved name of the Vc
    */
   public vcName?: string;

   /**
    * [page specific]
    *
    * The retrieved name of the folder
    */
   public folderName?: string;

   /**
    * [page specific]
    *
    * The retrieved host id needed for silent host selection
    */
   public hostForSilentSelection?: string;
} // class
} // module
