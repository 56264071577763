namespace spbm_ui {
   export class SpbmReapplyStoragePolicyActionService {
      static $inject = ['$injector'];

      private _vcSpbmReapplyStoragePolicyActionService: SpbmReapplyStoragePolicyActionService;

      constructor(private $injector: any) {
      }

      get vcSpbmReapplyStoragePolicyActionService() {
         if (!this._vcSpbmReapplyStoragePolicyActionService) {
            this._vcSpbmReapplyStoragePolicyActionService = this.$injector.get('vcReapplyVmStoragePolicyService');
         }
         return this._vcSpbmReapplyStoragePolicyActionService;
      }

      openDialogForVm(vms: string[]): void {
         this.vcSpbmReapplyStoragePolicyActionService.openDialogForVm(vms);
      }

      openDialogForProfile(profile: string): void {
         this.vcSpbmReapplyStoragePolicyActionService.openDialogForProfile(profile);
      }
   }

   angular.module("com.vmware.vsphere.client.spbm")
         .service("spbmReapplyStoragePolicyActionService", SpbmReapplyStoragePolicyActionService);
}