/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace dvs_ui {

   import PvlanMapEntry = com.vmware.vim.binding.vim.dvs.VmwareDistributedVirtualSwitch$PvlanMapEntry;
   export class DvsPvlanViewService {

      public static $inject = [
            "vuiConstants",
            "i18nService"
      ];

      private i18n: any;

      constructor(private vuiConstants: any, private i18nService: any) {
         this.i18n = this.i18nService.getString;
      }

      public formatPvlanEntries(pvlans: PvlanMapEntry[]): PvlanMapEntry[] {

         if (pvlans !== undefined && pvlans !== null && pvlans.length > 0) {
            pvlans.map((pvlan: PvlanMapEntry) => {
               pvlan.pvlanType = this.localizePvlanType(pvlan);
            });
         }

         let sortedBySecondaryVlanId: PvlanMapEntry[] =
               _.sortBy(pvlans, (pvlanMap: PvlanMapEntry): number => {
                  return pvlanMap.secondaryVlanId;
               });
         return _.sortBy(sortedBySecondaryVlanId, (pvlanMap: PvlanMapEntry): number => {
            return pvlanMap.primaryVlanId;
         });
      }

      public createPvlanDatagridOptions(pvlans: PvlanMapEntry[], onSelectionChange: Function): any {
         return {
            columnDefs: this.createColumnDefinitions(),
            sortMode: this.vuiConstants.grid.sortMode.SINGLE,
            selectionMode: this.vuiConstants.grid.selectionMode.SINGLE,
            selectedItems: [],
            data: pvlans,
            resizable: true,
            height: "100%",
            onChange: onSelectionChange
         };
      }

      private createColumnDefinitions(): any {
         return [{
            displayName: this.i18n("NetworkUi", "PrivateVlanView.list.primary.header"),
            field: "primaryVlanId",
            type: "number",
            sortable: true,
            width: "50px"
         }, {
            displayName: this.i18n("NetworkUi", "PrivateVlanView.list.secondary.header"),
            field: "secondaryVlanId",
            type: "number",
            sortable: true,
            width: "50px"
         }, {
            displayName: this.i18n("NetworkUi", "PrivateVlanView.list.type.header"),
            field: "pvlanType",
            type: "string",
            sortable: true,
            width: "200px"
         }];
      }

      private localizePvlanType(row: any): string {
         if (row.pvlanType !== PvlanType.promiscuous &&
               row.pvlanType !== PvlanType.isolated &&
               row.pvlanType !== PvlanType.community) {
            return "";
         }

         return this.i18n("DvsUi", "PrivateVlanPage.type." + row.pvlanType);
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .service("dvsPvlanViewService", DvsPvlanViewService);
}
