namespace h5_vm {

   import GosBasePageModel = h5_vm.GosBasePageModel;

   export class GosComputerNamePageModel extends GosBasePageModel {
      vcServiceGuid: string;
      type: GosType;

      setNameOption: ComputerNameOption;
      computerName: string;
      isAppendNumericValue: boolean;
      generateNameArgument: string;

      domainName: string;
      isNameIpGeneratorDefined: boolean;
   }
}
