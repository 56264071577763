namespace h5_client {
   export module common_module_ui {

      import IScope = angular.IScope;
      import ContentLibraryConstants = h5_client.common_module_ui.ContentLibraryConstants;
      import LibraryDataModel = h5_client.common_module_ui.LibraryDataModel;

      interface CreateContentLibraryFinishControllerScope extends IScope {
         wizardConfig: any;
         vuiWizard: any;
         wizardState: LibraryDataModel;
      }

      class CreateContentLibraryFinishController {
         public static $inject = ['$scope', 'i18nService', 'vuiConstants'];
         public i18n: any;

         constructor(private $scope: CreateContentLibraryFinishControllerScope, private i18nService: any,
                     private vuiConstants: any) {
            this.i18n = i18nService.getString;
            this.$scope.vuiWizard.currentPage.state = this.vuiConstants.wizard.pageState.INCOMPLETE;
         }

         getLibraryTypeString(): string {
            return this.$scope.wizardState.type === ContentLibraryConstants.LIBRARYTYPE_LOCAL ?
                  this.i18n("LibraryUi", "createLibraryWizard.readyToCompletePage.localLibrary") :
                  this.i18n("LibraryUi", "createLibraryWizard.readyToCompletePage.subscribedLibrary");
         }

         isLibraryLocal(): boolean {
            return this.$scope.wizardState.type === ContentLibraryConstants.LIBRARYTYPE_LOCAL;
         }

         getPublishedString(): string {
            return !this.$scope.wizardState.isPublished ?
                  this.i18n("LibraryUi", "createLibraryWizard.readyToCompletePage.published.no") :
                  this.getDetailedPublishedString();
         }

         getDetailedPublishedString(): string {
            let streamOptimized: string = this.$scope.wizardState.isStreamingOptimized ?
                  this.i18n("LibraryUi", "createLibraryWizard.readyToCompletePage.streamOptimized") :
                  this.i18n("LibraryUi", "createLibraryWizard.readyToCompletePage.streamNotOptimized");
            let passwordProtection: string = this.$scope.wizardState.isAuthenticationEnabled ?
                  this.i18n("LibraryUi", "createLibraryWizard.readyToCompletePage.passwordRequired") :
                  this.i18n("LibraryUi", "createLibraryWizard.readyToCompletePage.passwordNotRequired");

            return this.i18n("LibraryUi", "createLibraryWizard.readyToCompletePage.published.yes", streamOptimized, passwordProtection);
         }
      }

      angular.module("com.vmware.vsphere.client.commonModule")
            .controller("createContentLibraryFinishController", CreateContentLibraryFinishController);
   }
}
