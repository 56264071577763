namespace h5_vm {

   import VmProvisioningFinishPageModel =
         h5_vm.VmProvisioningFinishPageModel;
   import IPromise = angular.IPromise;
   import IQService = angular.IQService;

   class VmProvisioningFinishConvertToVmPageModelFactory {
      public static $inject = [
         "$q",
         "dataService",
         "i18nService",
         "addVmService",
         "vmDeviceInfoService",
         "defaultUriSchemeUtil",
         "managedEntityConstants",
         "mutationService",
         "storageProfileService",
         "diskModeService",
         "datastoreRecommendationService",
         "spbmReplicationGroupInfoService",
         "creationTypeConstants"
      ];

      constructor($q: IQService,
            dataService: any,
            i18nService: any,
            addVmService: any,
            vmDeviceInfoService: any,
            defaultUriSchemeUtil: any,
            managedEntityConstants: any,
            mutationService: any,
            storageProfileService: any,
            diskModeService: DiskModeService,
            datastoreRecommendationService: any,
            spbmReplicationGroupInfoService: any,
            creationTypeConstants: any) {
         return (virtualMachineSpecBuilder: any, wizardViewData: any, additionalData: any) => {
            return new VmProvisioningFinishConvertToVmPageModel(
                  $q,
                  dataService,
                  i18nService,
                  addVmService,
                  vmDeviceInfoService,
                  defaultUriSchemeUtil,
                  managedEntityConstants,
                  mutationService,
                  storageProfileService,
                  diskModeService,
                  datastoreRecommendationService,
                  spbmReplicationGroupInfoService,
                  creationTypeConstants,
                  virtualMachineSpecBuilder,
                  wizardViewData,
                  additionalData);
         };
      }
   }

   export class VmProvisioningFinishConvertToVmPageModel extends VmProvisioningFinishPageModel {

      public static VM_CONVERT_SPEC_TYPE: string = "com.vmware.vsphere.client.vm.VmConvertSpec";

      static $inject: string[] = [
         "$q",
         "dataService",
         "i18nService",
         "addVmService",
         "vmDeviceInfoService",
         "defaultUriSchemeUtil",
         "managedEntityConstants",
         "mutationService",
         "storageProfileService",
         "diskModeService",
         "datastoreRecommendationService",
         "spbmReplicationGroupInfoService",
         "creationTypeConstants"
      ];

      constructor($q: IQService,
            private dataService: any,
            i18nService: any,
            addVmService: any,
            vmDeviceInfoService: any,
            defaultUriSchemeUtil: any,
            managedEntityConstants: any,
            private mutationService: any,
            storageProfileService: any,
            diskModeService: DiskModeService,
            protected datastoreRecommendationService: any,
            protected spbmReplicationGroupInfoService: any,
            protected creationTypeConstants: any,
            virtualMachineSpecBuilder: any,
            wizardViewData: any,
            additionalData: any) {

         super($q, i18nService, addVmService, vmDeviceInfoService,
               defaultUriSchemeUtil, storageProfileService, diskModeService,
               managedEntityConstants, datastoreRecommendationService,
               spbmReplicationGroupInfoService, creationTypeConstants,
               virtualMachineSpecBuilder, wizardViewData, additionalData);
      }

      public getProvisioningType(): string {
         return this.i18nService.getString("VmUi", "ProvisioningTypes.ConvertTemplateToVm.Title");
      }

      public validatePage(): Object {
         return {};
      }

      public getTemplateName(): string {
         return this.wizardViewData.getSourceObjectName();
      }

      public submitPage(): IPromise<boolean> {
         let vmTemplateId: string = this.virtualMachineSpecBuilder.getVmId();
         let selectedResId: string = this.virtualMachineSpecBuilder.getComputeResourceId();
         let selectedRes: any =
               this.defaultUriSchemeUtil.getPartsFromVsphereObjectId(selectedResId);

         let resPoolPromise: IPromise<any>;

         // TODO mibryamov: move this logic to JAVA and when all the properties are
         // resolved call the correct mutation provider by passing VmConvertSpec.
         // In order to do that the VmConvertSpec type first should be moved from
         // vm-service to modules-api.
         switch (selectedRes.type) {
            case this.managedEntityConstants.CLUSTER:
               resPoolPromise = this.dataService.getProperties(
                     selectedResId, ["resourcePool"])
                     .then((result: any) => {
                        return {
                           resourcePoolRef: result.resourcePool,
                           hostRef: null
                        };
                     });
               break;
            case this.managedEntityConstants.HOST:
               resPoolPromise = this.dataService.getPropertiesByRelation(selectedResId,
                     "host", this.managedEntityConstants.ANY_COMPUTE_RESOURCE, ["resourcePool"])
                     .then((result: any) => {
                        return {
                           resourcePoolRef: _.values(result)[0].resourcePool,
                           hostRef: selectedRes
                        };
                     });
               break;
            case this.managedEntityConstants.RESOURCE_POOL:
            case this.managedEntityConstants.V_APP:
               resPoolPromise = this.$q.when({
                  resourcePoolRef: selectedRes,
                  hostRef: null
               });
               break;
            default:
               throw new Error("Invalid compute resource selected");
         }

         if (!resPoolPromise) {
            return this.$q.when(false);
         }

         resPoolPromise.then((result: any) => {
            let vmConvertSpec: Object = {
               pool: result.resourcePoolRef,
               host: result.hostRef
            };

            this.mutationService.apply(vmTemplateId,
                  VmProvisioningFinishConvertToVmPageModel.VM_CONVERT_SPEC_TYPE, vmConvertSpec);
         });

         return this.$q.when(true);
      }

   }
   angular.module("com.vmware.vsphere.client.vm")
         .service("vmProvisioningFinishConvertToVmPageModel",
               VmProvisioningFinishConvertToVmPageModelFactory);
}
