namespace h5_vm {

   import GosBasePageModel = h5_vm.GosBasePageModel;

   export class GosCommandsPageModel extends GosBasePageModel {

      commands: string[];

   }
}

