namespace h5_vm {
   import IPromise = angular.IPromise;
   import VmProvisioningPageModel = h5_vm.VmProvisioningPageModel;

   class VmProvisioningScaleoutConfigurationPageModelFactory {
      public static $inject = [
         "i18nService"
      ];

      constructor(i18nService: any) {
         return (wizardViewData: any, virtualMachineSpecBuilder: any) => {
            return new VmProvisioningScaleoutConfigurationPageModel(
                  i18nService,
                  wizardViewData,
                  virtualMachineSpecBuilder);
         };
      }
   }

   export class VmProvisioningScaleoutConfigurationPageModel implements VmProvisioningPageModel<any> {
      static $inject: string[] = ["i18nService"];

      private wizardViewDataObj: any;

      constructor(private i18nService: any, wizardViewData: any,
            private virtualMachineSpecBuilderObj: any) {
         this.wizardViewDataObj = wizardViewData;
      }

      get wizardViewData(): any {
         return this.wizardViewDataObj;
      }

      public resetForm(): void {
      };

      public validatePage(): any {
         let scaleoutConfiguration = this.wizardViewDataObj.getSclaeoutConfigurationFromLibraryCommonContext();

         let validationError: string = "";
         for (let group of scaleoutConfiguration.groups) {
            if (_.isUndefined(group.instanceCount) || _.isNull(group.instanceCount)) {
               validationError = this
                     .i18nService
                     .getString("ProvisioningUiLib", "ScaleoutPage.validator.required");
            }
            else if (!_.isUndefined(group.minimumInstanceCount) && !_.isNull(group.minimumInstanceCount) &&
                  group.instanceCount < group.minimumInstanceCount) {
               validationError = this
                     .i18nService
                     .getString("ProvisioningUiLib", "ScaleoutPage.validator.lessThanMinError");
            }
            else if (!_.isUndefined(group.maximumInstanceCount) && !_.isNull(group.maximumInstanceCount) &&
                  group.instanceCount > group.maximumInstanceCount) {
               validationError = this
                     .i18nService
                     .getString("ProvisioningUiLib", "ScaleoutPage.validator.greaterThanMaxError");
            }
            if (validationError.length > 0) {
               break;
            }
         }

         if (validationError.length > 0) {
            return {
               error: validationError
            };
         }
         else {
            return {};
         }
      };

      public submitPage(validationResult: any): any {
         return {
            wizardFlow: this.virtualMachineSpecBuilderObj.getCreationType(),
            invalidateNextPages: false
         };
      };
   }
   angular.module("com.vmware.vsphere.client.vm")
         .service("vmProvisioningScaleoutConfigurationPageModel",
               VmProvisioningScaleoutConfigurationPageModelFactory);

}
