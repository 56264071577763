/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace dvs_ui {

   class DvpgNameAndLocationPageController {

      public static $inject = [
            "$scope",
            "dvpgNameAndLocationPageValidator",
            "dataService",
            "dvpgCreateWizardConstants"
      ];

      public nameValidator: any;
      public onNameChange: Function;

      constructor(private $wizardScope: DvpgCreateWizardScope,
                  private validator: DvpgCreateWizardPageValidator,
                  private dataService: any,
                  private wizardConstants: any) {

         if (!this.$wizardScope.flags.isNameAndLocationPageInitialized) {
            this.requestProperties();
         }

         this.nameValidator = {
            message: null,
            isVisible: false
         };

         this.onNameChange = function (this: DvpgNameAndLocationPageController) {
            let message: string =
                  this.validator.getInvalidNameError($wizardScope.model.name);

            this.nameValidator.message = message;
            this.nameValidator.isVisible = message !== null;
         };
      }

      private requestProperties(): void {
         this.$wizardScope.wizardConfig.loading = true;

         this.dataService.getProperties(
            this.$wizardScope.model.dvsId, [
                  this.wizardConstants.properties.DVPG_NEW_UNIQUE_NAME,
                  this.wizardConstants.properties.NAME_PROPERTY,
                  this.wizardConstants.properties.PRIMARY_ICON_ID])
            .then((response: any): void => {
               this.$wizardScope.model.name = response[
                     this.wizardConstants.properties.DVPG_NEW_UNIQUE_NAME];
               this.$wizardScope.model.switchName = response[
                     this.wizardConstants.properties.NAME_PROPERTY];
               this.$wizardScope.model.switchIconId = response[
                     this.wizardConstants.properties.PRIMARY_ICON_ID];

               this.$wizardScope.wizardConfig.loading = false;
               this.$wizardScope.flags.isNameAndLocationPageInitialized = true;
            });
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .controller("DvpgNameAndLocationPageController",
               DvpgNameAndLocationPageController);
}
