/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
(function() {
   'use strict';
   angular
      .module('com.vmware.vsphere.client.host')
      .controller('AddHostResourceLocationPageController', ['$scope', 'addHostWizardConstants', AddHostResourceLocationPageController]);

   function AddHostResourceLocationPageController($scope, addHostWizardConstants) {
      this.model = $scope.manager.getResourceLocationPageModel();
      this.addHostWizardConstants = addHostWizardConstants;

      $scope.$watch('ctrl.model.resourceLocationType', function(resourceLocationType) {
         $scope.manager.setResourceLocationType(resourceLocationType);
      });

      $scope.$watch('ctrl.model.resourceLocationName', function(resourceLocationName) {
         $scope.manager.setResourceLocationName(resourceLocationName);
      });
   }
})();
