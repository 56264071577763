(function() {
   'use strict';

   angular
      .module('com.vmware.vsphere.client.vm')
      .controller('VmOptionsController', VmOptionsController);

   VmOptionsController.$inject = [
      '$scope',
      '$q',
      'vmHardwareUtil',
      'vmHardwareVirtualDiskService',
      'defaultUriSchemeUtil',
      'storageProfileService',
      'creationTypeConstants'
   ];

   function VmOptionsController($scope, $q, vmHardwareUtil, vmHardwareVirtualDiskService,
      defaultUriSchemeUtil, storageProfileService, creationTypeConstants) {

      this.networkAdapterDevices = $scope.hardwareConfigData.virtualMachineDevices.findDevicesOfType('VirtualEthernetCard');

      this.stackBlocksExpandedState = $scope.customizeHardwarePageModel
            ? $scope.customizeHardwarePageModel.getStackBlocksExpandedState()
            : {};

      var self = this;

      self.hardDisks = $scope.hardwareConfigData.virtualMachineDevices.devicesOfType('VirtualDisk');

      self.storageProfiles = $scope.hardwareConfigData.storageProfiles;
      self.vmStorageProfileAssignments = $scope.hardwareConfigData.vmStorageProfileAssignments;

      if (!$scope.modalOptions) {
         // Wizard mode - we have the data we need
         self.vmStorageProfile = $scope.hardwareConfigData.selectedStorageProfile;
      } else {
         self.vmStorageProfile = null;
      }

      this.workflow = calculateWorkflow($scope);

      // We need to detect changes in both the hard disks count and the disk profiles
      $scope.$on(vmHardwareVirtualDiskService.DISKS_UPDATED, function($event) {
         self.hardDisks = $scope.hardwareConfigData.virtualMachineDevices.devicesOfType('VirtualDisk');
      });

      $scope.$on('vmHomeStorageProfileChanged', function($event, newProfile) {
         self.vmStorageProfile = newProfile;
      });

      this.onVmwareToolsChange = function(vmToolsOptions) {
         $scope.hardwareConfigData.vmConfigContext.config.tools = vmToolsOptions;
      };

      this.isVmPoweredOn = function() {
         return vmHardwareUtil.isVmPoweredOn($scope.hardwareConfigData.vmConfigContext.environment.powerState);
      };

      this.hasVmConfigSettingsPrivilege = function() {
         return vmHardwareUtil.checkPrivileges($scope.hardwareConfigData.vmConfigContext.privileges, ['VirtualMachine.Config.Settings']);
      };

      self.onBootOptionsChanged = function(updatedModel) {
         $scope.hardwareConfigData.vmConfigContext.config.bootOptions = updatedModel;
      };

      self.onVbsUpdate = function(vbsEnabled) {
         var config = $scope.hardwareConfigData.vmConfigContext.config;
         config.flags.vbsEnabled = vbsEnabled;
         self.vbsEnabled = vbsEnabled;

         if (vbsEnabled) {
            setupVbsProperties(config);
            updateVbsProperties(config);
         }
      };

      self.onAlert = function(options) {
         $scope.$emit('editSettingsAlertMessageChanged', options);
      };

      function setupVbsProperties (config) {
         config.bootOptions = {
            _type: "com.vmware.vim.binding.vim.vm.BootOptions",
            efiSecureBootEnabled: self.vbsProperties["bootOptions.efiSecureBootEnabled"]
         };
         config.firmware = self.vbsProperties["firmware"];
      }

      function updateVbsProperties (config) {
         config.flags.vvtdEnabled = self.vbsProperties["flags.vvtdEnabled"];
         config.nestedHVEnabled = self.vbsProperties["nestedHVEnabled"];
      }

      self.onFirmwareSelectionChanged = function(newFirmware) {
         $scope.hardwareConfigData.vmConfigContext.config.firmware = newFirmware;
         if (newFirmware.toLowerCase() === "bios") {
            $scope.ctrl.updateActionDefsForAddDeviceMenu(['addNewTpmAction']);
         } else {
            $scope.ctrl.updateActionDefsForAddDeviceMenu();
         }
      };

      self.onStandbyOptionsChange = function(standbyAction) {
         $scope.hardwareConfigData.vmConfigContext.config.defaultPowerOps.standbyAction = standbyAction;
      };

      self.vbsVisible = function() {
         return !!$scope.modalOptions;
      };

      self.getVbsProperties = function()  {
         var propertyRelations = _.reduce($scope.hardwareConfigData.vmConfigContext.environment.configOption.propertyRelations, function(map, obj) {
            map[obj.key.name] = obj.relations;
            return map;
         }, {});

         return _.reduce(propertyRelations["flags.vbsEnabled"], function(map, obj) {
            map[obj.name] = obj.val;
            return map;
         }, {});
      };

      self.doesTPMExist = function () {
         if ($scope.ctrl && $scope.ctrl.virtualMachineDevices) {
            return $scope.ctrl.virtualMachineDevices.devicesOfType('VirtualTPM').length > 0;
         }
         return false;
      };

      function calculateWorkflow (scope) {
         if (scope.modalOptions) {
            return h5_vm.VmWorkflowMode.UpdateMode;
         } else if (scope.vmParams.getCreationType() === creationTypeConstants.CLONE_VM_TO_VM
               || scope.vmParams.getCreationType() === creationTypeConstants.CLONE_TEMPLATE_TO_VM) {
            return h5_vm.VmWorkflowMode.CloneMode;
         } else {
            return h5_vm.VmWorkflowMode.CreateMode;
         }
      }

      initialize();

      function initialize () {
         self.vbsProperties = self.getVbsProperties();
         if ($scope.vmParams &&
               $scope.vmParams.getVbsEnabled) {
            self.vbsEnabled = $scope.vmParams.getVbsEnabled();
         } else {
            self.vbsEnabled = $scope.hardwareConfigData.vmConfigContext.config.flags.vbsEnabled;
         }


         if (self.vbsEnabled) {
            setupVbsProperties($scope.hardwareConfigData.vmConfigContext.config);
         }
      }
   }

})();
