/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
(function () {
   'use strict';
   angular.module('com.vmware.vsphere.client.network')
         .controller('AddPnicPageController', AddPnicPageController);

   AddPnicPageController.$inject = [
      '$scope',
      'i18nService',
      'physicalAdaptersListService'];

   function AddPnicPageController($scope, i18nService, physicalAdaptersListService) {

      $scope.noItemsSelectedMessage = i18nService.getString('NetworkUi',
            'FailoverOrderEditor.detailsPrompt');

      $scope.headerOptions = {
         title: i18nService.getString('NetworkUi', 'FailoverOrderEditor.failoverOrder')
      };

      if (!$scope.addPnicPageModel.isInitialized) {
         initFailoverOrderSettings();
      } else {
         $scope.failoverOrder = createFailoverOrderSettings(
               $scope.vSwitchSpec.pnics,
               $scope.vSwitchSpec.pnicsDetails);
      }

      function createFailoverOrderSettings(connectees, contecteesDetails) {
         return {
            connectees: connectees,
            connecteesDetails: contecteesDetails,
            labels: {
               active: i18nService.getString('NetworkUi', 'FailoverOrderEditor.active'),
               standby: i18nService.getString('NetworkUi', 'FailoverOrderEditor.standby'),
               unused: i18nService.getString('NetworkUi', 'FailoverOrderEditor.unused')
            }
         };
      }

      function initFailoverOrderSettings() {
         $scope.wizardConfig.loading = true;
         $scope.vSwitchSpec.pnics = {
            active: [],
            standby: [],
            unused: [],
            unclaimed: []
         };

         physicalAdaptersListService.getPhysicalAdaptersWithDetails($scope.hostId,
               $scope.addNetworkingSpec.selectedStandardSwitch.name)
               .then(function (data) {
                  $scope.vSwitchSpec.pnics.active = data.active || [];
                  $scope.vSwitchSpec.pnics.standby = data.standby || [];
                  $scope.vSwitchSpec.pnics.unused = data.unused || [];
                  $scope.vSwitchSpec.pnics.unclaimed = data.unclaimed;
                  $scope.vSwitchSpec.pnicsDetails = data.pnicDetails;

                  $scope.failoverOrder = createFailoverOrderSettings(
                        $scope.vSwitchSpec.pnics,
                        $scope.vSwitchSpec.pnicsDetails);
                  $scope.addPnicPageModel.isInitialized = true;
                  $scope.wizardConfig.loading = false;
               });
      }

      return this;
   }
})();
