/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
/**
 * Defines the pages used in addNetworkingWizard
 */
(function () {
   'use strict';
   angular.module('com.vmware.vsphere.client.network')
         .service('addNetworkingPages', AddNetworkingPages);

   AddNetworkingPages.$inject = [
      'addNetworkingWizardConstants',
      'selectTargetNetworkPageValidator',
      'addHostNetworkingAddPnicPageValidator',
      'addPnicToDvsPageValidator',
      'portPropertiesPageValidator',
      'addHostNetworkingCreateStandardSwitchPageValidator',
      'connectionSettingsPageValidator',
      'ipv4SettingsPageValidator',
      'ipv6SettingsPageValidator',
      'i18nService',
      'vuiConstants',
      'bindToPnicPageValidator',
      'featureFlagsService',
      '$q'
   ];

   function AddNetworkingPages(addNetworkingWizardConstants,
                               selectTargetNetworkPageValidator,
                               addHostNetworkingAddPnicPageValidator,
                               addPnicToDvsPageValidator,
                               portPropertiesPageValidator,
                               addHostNetworkingCreateStandardSwitchPageValidator,
                               connectionSettingsPageValidator,
                               ipv4SettingsPageValidator,
                               ipv6SettingsPageValidator,
                               i18nService,
                               vuiConstants,
                               bindToPnicPageValidator,
                               featureFlagsService,
                               $q) {

      /**
       * Creates an array with page definitions for all pages in add networking wizard.
       */
      this.createWizardPages = function (wizardScope) {
         var selectConnectionTypePage = {
            id: addNetworkingWizardConstants.pages.SELECT_CONNECTION_TYPE,
            title: i18nService.getString(
                  'NetworkUi', 'AddNetworkingWizard.conTypePage.title'),
            description: i18nService.getString(
                  'NetworkUi', 'AddNetworkingWizard.conTypePage.description'),
            contentUrl: 'network-ui/resources/network/views/host/addnetworking/' +
            'pages/selectConnectionTypePage.html',
            validationParams: [
               'selectConnectionTypePageModel.connectionType'
            ],
            onCommit: function () {
               wizardScope.wizardConfig.currentPage.state =
                     vuiConstants.wizard.pageState.COMPLETED;
               return true;
            }
         };

         var selectTargetNetworkPage = {
            id: addNetworkingWizardConstants.pages.SELECT_TARGET_TYPE,
            title: i18nService.getString('NetworkUi', 'AddNetworkingWizard.conTargetPage.title'),
            description: i18nService.getString('NetworkUi', 'AddNetworkingWizard.conTargetPage.description'),
            contentUrl: 'network-ui/resources/network/views/host/addnetworking/pages/SelectTargetNetworkPage.html',
            validationParams: [
               'hostCapability',
               'selectTargetNetworkPageModel.targetType',
               'addNetworkingSpec.selectedStandardSwitch',
               'addNetworkingSpec.selectedNetwork',
               'vSwitchSpec.mtu',
               'vSwitchSpec.numPorts'
            ],
            validationFunction: function () {
               return selectTargetNetworkPageValidator.getValidationErrors(wizardScope);
            }
         };

         var addPnicPage = {
            id: addNetworkingWizardConstants.pages.ADD_PNIC,
            title: i18nService.getString(
                  'NetworkUi', 'AddNetworkingWizard.physicalNicPage.title'),
            description: i18nService.getString(
                  'NetworkUi', 'AddNetworkingWizard.physicalNicPage.description'),
            contentUrl: 'network-ui/resources/network/views/host/addnetworking/' +
            'pages/addPnicPage.html',
            validationParams: ['vSwitchSpec.pnics.active',
               'vSwitchSpec.pnics.standby',
               'vSwitchSpec.pnics.unused'],
            validationFunction: function () {
               return addHostNetworkingAddPnicPageValidator
                     .getValidationErrorsAndWarnings(
                           wizardScope.vSwitchSpec.pnics,
                           wizardScope.vSwitchSpec.pnicsDetails);
            }
         };

         var addPnicToDvsPage = {
            id: addNetworkingWizardConstants.pages.ADD_PNIC_DVS,
            title: i18nService.getString(
                  'NetworkUi', 'AddNetworkingWizard.physicalNicPage.title'),
            description: i18nService.getString(
                  'NetworkUi', 'AddNetworkingWizard.physicalNicPage.description'),
            contentUrl: 'network-ui/resources/network/views/host/addnetworking/' +
            'pages/addPnicToDvsPage.html',
            validationParams: [
               'addPnicToDvsPageModel.portMapping.addedMappings'
            ],
            validationFunction: addPnicToDvsPageValidator.getValidationErrors
         };

         var createStandardSwitchPage = {
            id: addNetworkingWizardConstants.pages.CREATE_STANDARD_SWITCH,
            title: i18nService.getString('NetworkUi', 'AddNetworkingWizard.newSwitchPage.title'),
            description: i18nService.getString('NetworkUi', 'AddNetworkingWizard.newSwitchPage.description'),
            contentUrl: 'network-ui/resources/network/views/host/addnetworking/pages/CreateStandardSwitchPage.html',
            defaultState: vuiConstants.wizard.pageState.SKIPPED,
            validationParams: ['vSwitchSpec.pnics.active.length',
               'vSwitchSpec.pnics.standby.length',
               'vSwitchSpec.pnics.unused.length'],
            validationFunction: function () {
               return addHostNetworkingCreateStandardSwitchPageValidator
                     .getValidationErrorsAndWarnings(
                           wizardScope.vSwitchSpec.pnics,
                           wizardScope.vSwitchSpec.pnicsDetails);
            }
         };

         var connectionSettingsPage = {
            id: addNetworkingWizardConstants.pages.CONNECTION_SETTINGS,
            title: i18nService.getString(
                  'NetworkUi', 'AddNetworkingWizard.vmNetworkingPage.title'),
            description: i18nService.getString(
                  'NetworkUi', 'AddNetworkingWizard.vmNetworkingPage.description'),
            contentUrl: 'network-ui/resources/network/views/host/addnetworking/' +
            'pages/connectionSettingsPage.html',
            validationParams: [
               'hostId',
               'addNetworkingSpec.networkLabel',
               'addNetworkingSpec.vlanId'
            ],
            validationFunction: connectionSettingsPageValidator.getValidationErrors
         };

         var portPropertiesPage = {
            id: addNetworkingWizardConstants.pages.PORT_PROPERTIES,
            title: i18nService.getString('NetworkUi', 'AddNetworkingWizard.portPropPage.title'),
            description: i18nService.getString('NetworkUi', 'AddNetworkingWizard.portPropPage.descVmkernel'),
            contentUrl: 'network-ui/resources/network/views/host/addnetworking/pages/PortPropertiesPage.html',
            validationParams: [
               'hostId',
               'addNetworkingSpec.networkLabel',
               'selectTargetNetworkPageModel.targetType',
               'addNetworkingSpec.vlanId',
               'addNetworkingSpec.mtuSelectedType',
               'addNetworkingSpec.mtu',
               'addNetworkingSpec.netStackName.stackName'
            ],
            validationFunction: function () {
               var netStackInstanceKey;
               if (wizardScope.addNetworkingSpec.isTcpIpStackSupported) {
                  netStackInstanceKey =
                        wizardScope.addNetworkingSpec.netStackName.netStackInstanceKey;
               }

               return $q.when(portPropertiesPageValidator.getValidationErrorsAndWarnings(
                     wizardScope.hostId,
                     wizardScope.addNetworkingSpec.networkLabel,
                     wizardScope.selectTargetNetworkPageModel.targetType,
                     wizardScope.addNetworkingSpec.vlanId,
                     wizardScope.addNetworkingSpec.mtuSelectedType,
                     wizardScope.addNetworkingSpec.mtu,
                     netStackInstanceKey))
                     .then(function (result) {
                        return result;
                     });
            }
         };

         var ipv4SettingsPage = {
            id: addNetworkingWizardConstants.pages.IPV4_SETTINGS,
            title: i18nService.getString('NetworkUi', 'AddNetworkingWizard.ipv4Settings.title'),
            description: i18nService.getString('NetworkUi', 'AddNetworkingWizard.ipv4Settings.descVmkernel'),
            contentUrl: 'network-ui/resources/network/views/host/addnetworking/pages/Ipv4SettingsPage.html',
            validationParams: [
               'hostId',
               'addNetworkingSpec.dhcpEnabled',
               'addNetworkingSpec.ipv4Address',
               'addNetworkingSpec.subnetMask',
               'addNetworkingSpec.overrideGateway',
               'addNetworkingSpec.ipv4DefaultGateway'
            ],
            validationFunction: function () {
               // Trigger ipv4Page's inputs inline validations
               wizardScope.ipv4SettingsPageModel.validateIpAddress();
               wizardScope.ipv4SettingsPageModel.validateSubnetMask();

               if (wizardScope.addNetworkingSpec.isGatewayFeatureSupported
                     && wizardScope.addNetworkingSpec.overrideGateway) {
                  wizardScope.ipv4SettingsPageModel.validateIpv4DefaultGateway();
               }

               var validationData = {
                  hostId: wizardScope.hostId,
                  dhcpEnabled: wizardScope.addNetworkingSpec.dhcpEnabled,
                  ipv4Address: wizardScope.addNetworkingSpec.ipv4Address,
                  subnetMask: wizardScope.addNetworkingSpec.subnetMask,
                  isGatewayFeatureSupported: wizardScope.addNetworkingSpec.isGatewayFeatureSupported,
                  overrideGateway: wizardScope.addNetworkingSpec.overrideGateway,
                  ipv4DefaultGateway: wizardScope.addNetworkingSpec.ipv4DefaultGateway
               };

               return ipv4SettingsPageValidator.getValidationErrors(validationData);
            }
         };

         var ipv6SettingsPage = {
            id: addNetworkingWizardConstants.pages.IPV6_SETTINGS,
            title: i18nService.getString('NetworkUi', 'AddNetworkingWizard.ipv6Settings.title'),
            description: i18nService.getString('NetworkUi', 'AddNetworkingWizard.ipv6Settings.descVmkernel'),
            contentUrl: 'network-ui/resources/network/views/host/addnetworking/pages/Ipv6SettingsPage.html',
            validationParams: [
               'addNetworkingSpec.ipv6DhcpEnabled',
               'addNetworkingSpec.routerAdvertisementEnabled',
               'addNetworkingSpec.staticAddressesEnabled',
               'addNetworkingSpec.ipv6Addresses.length',
               'addNetworkingSpec.overrideIpv6Gateway',
               'addNetworkingSpec.ipv6DefaultGateway',
               function (wizardScope) {
                  if (wizardScope.addNetworkingSpec && wizardScope.addNetworkingSpec.ipv6Addresses) {
                     var ipv6AddressesMap = _.map(wizardScope.addNetworkingSpec.ipv6Addresses, function (ipv6Address) {
                        return ipv6Address.address + ipv6Address.prefixLength;
                     });
                     return ipv6AddressesMap.toString();
                  }
               }
            ],
            validationFunction: function () {
               // Trigger ipv6Page's inputs inline validation
               if (wizardScope.addNetworkingSpec.isGatewayFeatureSupported
                     && wizardScope.addNetworkingSpec.overrideIpv6Gateway) {
                  wizardScope.ipv6SettingsPageModel.validateIpv6DefaultGateway();
               }

               return ipv6SettingsPageValidator.getValidationErrors(
                     wizardScope.addNetworkingSpec.ipv6DhcpEnabled,
                     wizardScope.addNetworkingSpec.routerAdvertisementEnabled,
                     wizardScope.addNetworkingSpec.staticAddressesEnabled,
                     wizardScope.addNetworkingSpec.ipv6Addresses.length,
                     wizardScope.addNetworkingSpec.overrideIpv6Gateway,
                     wizardScope.addNetworkingSpec.ipv6DefaultGateway);
            }
         };

         var bindToPnicPage = {
            id: addNetworkingWizardConstants.pages.BIND_TO_PNIC,
            title: i18nService.getString('NetworkUi', 'AddNetworkingWizard.physicalNicBindingPage.title'),
            description: i18nService.getString('NetworkUi', 'AddNetworkingWizard.physicalNicBindingPage.description'),
            contentUrl: 'network-ui/resources/network/views/host/addnetworking/pages/BindToPhysicalAdapterPage.html',
            validationParams: [
               'bindPnicPageModel.bindToPnic',
               'bindPnicPageModel.selectedPnic'],
            validationFunction: bindToPnicPageValidator.getValidationErrors
         };

         var readyToCompletePage = {
            id: addNetworkingWizardConstants.pages.RTC,
            title: i18nService.getString(
                  'H5NetworkUi', 'AddNetworkingWizard.readyToCompletePage.title'),
            description: i18nService.getString(
                  'H5NetworkUi', 'AddNetworkingWizard.readyToCompletePage.description'),
            contentUrl: 'network-ui/resources/network/views/host/addnetworking/pages/ReadyToCompletePage.html'
         };

         var pages = [
            selectConnectionTypePage,
            selectTargetNetworkPage,
            addPnicPage,
            addPnicToDvsPage,
            createStandardSwitchPage,
            connectionSettingsPage,
            portPropertiesPage,
            ipv4SettingsPage,
            ipv6SettingsPage,
            bindToPnicPage,
            readyToCompletePage
         ];

         return pages;
      };
   }
})();