/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace dvs_ui {

   import DvpgTrafficRuleModel = com.vmware.vsphere.client.h5.network.dvportgroup.trafficfilter.model.DvpgTrafficRuleModel;
   export class DvpgTrafficRuleValidator {

      public static $inject = [
         "i18nService",
         "dvpgTrafficFilterConstants",
         "dvpgQualifierValidatorService",
         "dvpgIpQualifierValidator",
         "dvpgMacQualifierValidator",
         "clarityModalService",
         "$q"
      ];

      constructor(private i18nService: any,
                  private dvpgTrafficFilterConstants: any,
                  private dvpgQualifierValidatorService: any,
                  private dvpgIpQualifierValidator: any,
                  private dvpgMacQualifierValidator: any,
                  private clarityModalService: any,
                  private $q: any) {
      }

      public getValidationResult(
            model: DvpgTrafficRuleModel): string[] | boolean {

         if (!model) {
            return this.$q.resolve(false);
         }

         let errors: string[] = [];

         // 1. The rule name must be < 255
         if (!this.isValidRuleName(model.name)) {
            errors.push(this.i18nService.getString(
                  "DvsUi", "trafficFilter.ruleDialog.exceededMaxLength"));
         }

         if (model.action === this.dvpgTrafficFilterConstants.action.TAG) {

            // 2. The Cos and DSCP tags
            if (model.qosTagSelected && !this.isValidQosTag(model.qosTagValue)) {
               errors.push(this.i18nService.getString(
                     "DvsUi", "trafficFilter.ruleDialog.invalidQosTag"));
            }

            if (model.dscpTagSelected && !this.isValidDscpTag(model.dscpTagValue)) {
               errors.push(this.i18nService.getString(
                     "DvsUi", "trafficFilter.ruleDialog.invalidDscpTag"));
            }
         }

         // 3. The selected action must be Allow/Drop/Tag
         if (model.action !== this.dvpgTrafficFilterConstants.action.ALLOW &&
               model.action !== this.dvpgTrafficFilterConstants.action.DROP &&
               model.action !== this.dvpgTrafficFilterConstants.action.TAG) {

            errors.push(this.i18nService.getString(
                  "DvsUi", "trafficFilter.ruleDialog.unsupportedAction"));
         }

         // 4. The rule must have at least one enabled qualifier
         if (!model.ipQualifierModel.enableIpQualifier &&
               !model.macQualifierModel.enableMacQualifier &&
               !model.systemQualifierModel.enableSystemQualifier) {

            errors.push(this.i18nService.getString(
                  "DvsUi", "trafficFilter.ruleDialog.missingQualifier"));
         }

         // 5. Validate qualifiers
         let qualifiersErrors: string [] = this.getQualifiersErrors(model);
         if (qualifiersErrors.length > 0) {
            _.each(qualifiersErrors, (error: string) => {
               errors.push(error);
            });
         }

         if (errors.length > 0) {
            return this.$q.resolve(errors);
         }

         // 6. The tag action should have selected CoS or DSCP parameters
         if (model.action === this.dvpgTrafficFilterConstants.action.TAG &&
               (!model.dscpTagSelected && !model.qosTagSelected)) {

            return this.showTagActionWarning();
         }

         return this.$q.resolve([]);
      }

      public getQualifiersErrors(model: DvpgTrafficRuleModel): string[] {
         if (!model) {
            return [];
         }

         let errors: string[] = [];

         if (model.ipQualifierModel.enableIpQualifier) {
            let ipQualifierErrors: string[] =
                  this.dvpgIpQualifierValidator.getValidationErrors(
                        model.ipQualifierModel);

            if (ipQualifierErrors.length > 0) {
               _.each(ipQualifierErrors, (ipQualifierError: string) => {
                  errors.push(ipQualifierError);
               });
            }
         }

         if (model.macQualifierModel.enableMacQualifier) {
            let macQualifierErrors: string[] =
                  this.dvpgMacQualifierValidator.getValidationErrors(
                        model.macQualifierModel);

            if (macQualifierErrors.length > 0) {
               _.each(macQualifierErrors, (macQualifierError: string) => {
                  errors.push(macQualifierError);
               });
            }
         }

         return errors;
      }

      public isValidRuleName(name: string): boolean {
         // The rule name must be <= 255
         return name !== undefined && name.length <=
               this.dvpgTrafficFilterConstants.MAX_RULE_NAME_LENGTH;
      }

      public isValidQosTag(tag: string): boolean {
         if (tag === undefined || tag === null || tag.length === 0) {
            // 0 by default, if the value is not specified
            return true;
         }
         // The valid Cos tag must be between 0 and 7
         return this.dvpgQualifierValidatorService.isValidInteger(tag,
               this.dvpgTrafficFilterConstants.tag.MIN_COS_TAG,
               this.dvpgTrafficFilterConstants.tag.MAX_COS_TAG);
      }

      public isValidDscpTag(tag: string): boolean {
         if (tag === undefined || tag === null || tag.length === 0) {
            // 0 by default, if the value is not specified
            return true;
         }
         // The valid DSCP tag must be between 0 and 63
         return this.dvpgQualifierValidatorService.isValidInteger(tag,
               this.dvpgTrafficFilterConstants.tag.MIN_DSCP_TAG,
               this.dvpgTrafficFilterConstants.tag.MAX_DSCP_TAG);
      }

      private showTagActionWarning(): boolean {
         let deferred: any = this.$q.defer();

         let modalOptions: any = {
            size: "md",
            title: this.i18nService.getString(
                  "DvsUi", "trafficFilter.ruleDialog.noTagSpecifiedTitle"),
            defaultButton: "submit",
            modalClass: "dvpg-traffic-rule-tag-warning",
            alerts: [],
            dialogData: {
               message: this.i18nService.getString(
                     "DvsUi", "trafficFilter.ruleDialog.noTagSpecified")
            },
            contentTemplate: "dvs-ui/resources/dvs/dvpg/trafficfilter/validators/" +
                  "dvpgTrafficRuleWarningDialog.html",
            preserveNewlines: true,
            clarityIcon: {
               shape: "warning",
               class: "is-warning",
               size: 32
            },
            onSubmit : () => {
               deferred.resolve(true);
               return true;
            },
            onCancel: () => {
               deferred.resolve(false);
            }
         };

         this.clarityModalService.openOkCancelModal(modalOptions);

         return deferred.promise.then((result: any) => {
           return result;
         });
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .service("dvpgTrafficRuleValidator", DvpgTrafficRuleValidator);
}
