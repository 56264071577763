namespace h5_vm {
   import ManagedObjectReference = com.vmware.vim.binding.vmodl.ManagedObjectReference;
   class ImportGosSpecController {
      public static $inject = [
         "$q",
         "$element",
         "$timeout",
         "i18nService",
         "vuiConstants",
         "userSessionService",
         "gosSpecValidationService",
         "importGosSpecService"];

      public vCentersData: any;
      public vCenter: any;
      public vcLabel: string;
      public nameLabel: string;
      public name: string = "";
      public descriptionLabel: string;
      public description: string = "";
      public sourceLabel: string;
      public browseButtonLabel: string;
      public loadingLabel: string;
      public customizationSectionLabel: String;
      public sourceFileSectionLabel: String;
      public onSubmit: Function;
      public closeModal: Function;
      public file: any;
      public fileNameToDisplay: string;
      public alerts: AlertMessage[];
      public allSpecs: CustomizationSpecInfoCustom[];
      public invalidNameMessage: String;
      public submitDisabled: boolean;

      private importSpecInput: any;

      constructor(private $q: any,
            private $element: any,
            private $timeout: any,
            private i18nService: any,
            private vuiConstants: any,
            private userSessionService: any,
            private gosSpecValidationService: GosSpecValidationService,
            private importGosSpecService: ImportGosSpecService) {
         this.vcLabel =
               this.i18nService.getString("VmUi", "guestOsCustomization.import.vcLabel");
         this.nameLabel =
               this.i18nService.getString("VmUi", "guestOsCustomization.import.name");
         this.descriptionLabel =
               this.i18nService.getString("VmUi", "guestOsCustomization.import.description");
         this.sourceLabel =
               this.i18nService.getString("VmUi", "guestOsCustomization.import.sourceFile");
         this.browseButtonLabel =
               this.i18nService.getString("VmUi", "guestOsCustomization.import.browseLabel");
         this.loadingLabel =
               this.i18nService.getString("VmUi", "guestOsCustomization.import.loadingLabel");
         this.fileNameToDisplay =
               this.i18nService.getString("VmUi", "guestOsCustomization.import.sourceFile.noFileSelected");
         this.customizationSectionLabel = this.i18nService.getString("VmUi",
               "guestOsCustomization.nameAndTargetOsPage.vmCustomizationSection");
         this.sourceFileSectionLabel = this.i18nService.getString("VmUi",
               "guestOsCustomization.import.sourceFileLabel");
      }

      $onInit(): void {
         this.retrieveVcenterData();

         this.importSpecInput = this.$element.find(".gos-spec-file-input");
         // when the file browser is open this event is triggered
         // when `Open` or `Cancel` is pressed
         this.importSpecInput.on("change", (event: any) => {
            if (!event.target.files || !event.target.files.length) {
               return;
            }

            this.alerts = [];
            this.validateSpecName();
            this.onFileSelected(event).then(() => {
               this.validateSpecName();
            }).catch((error: string) => {
               this.createAlerts([error]);
            });
         });

         this.onSubmit = (): IPromise<boolean> => {
            return this.submitForm();
         };
      }

      public onChooseFileClicked(): void {
         this.$timeout((): void => {
            this.importSpecInput.click();
         }, 0);
      }

      public validateSpecName(): void {
         this.invalidNameMessage = this.gosSpecValidationService.validateName(this.name,
               false, this.allSpecs, this.vCenter.name);
      }

      private onFileSelected(event: any) {
         let deferredValidation: any = this.$q.defer();
         this.file = event.target.files[0];
         this.fileNameToDisplay = this.file.name;
         this.$element.find(".gos-spec-file-input").val("");
         this.name = "";
         this.description = "";

         let error: string = this.importGosSpecService.validateFileMetadata(this.file);
         if (!error) {
            this.importGosSpecService.loadFile(this.file)
                  .then((xmlContent: string) => {
                     let nameAndDescr: any = this.importGosSpecService
                           .retrieveNameAndDescription(xmlContent);
                     this.name = nameAndDescr.name;
                     this.description = nameAndDescr.description;
                     deferredValidation.resolve();
                  }).catch((error: string) => {
               deferredValidation.reject(error);
            });
         } else {
            deferredValidation.reject(error);
         }

         return deferredValidation.promise;
      }

      /**
       * Retrieve all the registered VCs
       * Sets the first one as the selected
       * and sets the customizationSpecMgr for the selected VC
       */
      private retrieveVcenterData(): void {
         this.submitDisabled = true;
         this.userSessionService.getAllServersInfo().then((session: any) => {
            this.vCentersData = session.serversInfo;
            if (this.vCentersData && this.vCentersData.length > 0) {
               this.vCenter = this.vCentersData[0];
            }
            this.submitDisabled = false;
         });
      }

      /**
       * Validates if a name is entered and if it is unique.
       * Validates if a file has been selected
       * Validates permissions to create a spec on the selected VC
       * Sends the file to the backend to be validated
       */
      private submitForm(): IPromise<boolean> {
         let cusSpecManager: ManagedObjectReference =
               this.vCenter.content.customizationSpecManager;
         this.submitDisabled = true;
         return this.importGosSpecService.validateForm(
               this.vCenter.serviceGuid, this.file, this.name, this.allSpecs,
               this.vCenter.name, cusSpecManager)
               .then((result: GosCustomizationImportValidationResult) => {
                  this.submitDisabled = false;
                  if (result.errors.length > 0) {
                     this.createAlerts(result.errors);
                     return this.$q.when(false);
                  }
                  this.onValidationComplete(result.result);
                  return this.$q.when(true);
               });
      }

      private createAlerts(errors: string[]) {
         this.alerts = [];
         _.each(errors, (error) => {
            let alert: AlertMessage = {
               type: this.vuiConstants.validationBanner.type.ERROR,
               text: error
            };
            this.alerts.push(alert);
         });
      }

      /**
       * In case the file is not validated show an error, in case it is valid
       * parse the response and add new customization spec
       * @param response
       */
      private onValidationComplete(response: any): void {
         this.importGosSpecService.parseValidationResponse(response,
               this.vCenter.content.customizationSpecManager,
               this.name, this.description);
      }
   }

   export class ImportGosSpecComponent {
      public controller: any;
      public templateUrl: string;
      public bindings: any;

      constructor() {
         this.bindings = {
            onSubmit: "=",
            closeModal: "<", // method of the modal dialog, when invoked the modal closes
            alerts: "=", // property of the modal dialog, when initialized with data
            // an alert will be shown
            allSpecs: "<",
            submitDisabled: "="
         };
         this.controller = ImportGosSpecController;
         this.templateUrl = "vm-ui/resources/vm/guest-os-customization/import/import-gos-spec.component.html";
      }
   }

   angular.module("com.vmware.vsphere.client.vm")
         .component("importGosSpec", new ImportGosSpecComponent());
}
