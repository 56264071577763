namespace h5_vm {
   import ActionEvaluation = com.vmware.vise.actionsfw.ActionEvaluation;
   export class MarkAsTemplateService {
      static $inject: string[] = [
         "mutationService"];
      private readonly CONVERT_TO_TEMPLATE_SPEC_NAME: string =
            "com.vmware.vsphere.client.vm.VmTemplateSpec";

      constructor(private mutationService: any) {
      }


      public markAsTemplate(availableTargets: string[]): void {
         this.mutationService.applyOnMultiEntity(availableTargets,
               this.CONVERT_TO_TEMPLATE_SPEC_NAME, {});
      }
   }

   angular.module("com.vmware.vsphere.client.vm")
         .service("markAsTemplateService", MarkAsTemplateService);
}
