namespace h5_vm {

   import IpGenerator = com.vmware.vim.binding.vim.vm.customization.IpGenerator;
   import DhcpIpGenerator = com.vmware.vim.binding.vim.vm.customization.DhcpIpGenerator;
   import FixedIp = com.vmware.vim.binding.vim.vm.customization.FixedIp;
   import IPSettings$IpV6AddressSpec = com.vmware.vim.binding.vim.vm.customization.IPSettings$IpV6AddressSpec;
   import FixedIpV6 = com.vmware.vim.binding.vim.vm.customization.FixedIpV6;
   import AdapterMapping = com.vmware.vim.binding.vim.vm.customization.AdapterMapping;
   import IPSettings = com.vmware.vim.binding.vim.vm.customization.IPSettings;
   import Specification = com.vmware.vim.binding.vim.vm.customization.Specification;

   export class GosSpecNetworkService {

      public static $inject = ["i18nService"];

      constructor(private i18nService: any) {
      }

      /**
       * Returns a summary of the IPv4 settings of the virtual network adapter
       * possible values are:
       * <ul>
       *    <li>Use DHCP</li>
       *    <li>Prompt user</li>
       *    <li>Custom</li>
       *    <li>Fixed IPv4 (The IP address)</li>
       * </ul>
       */
      getIPv4NicSettingSummary(ipGenerator: IpGenerator): string {
         if (ipGenerator._type === GosCustomizationHelperService.ADAPTER_TYPE_DHCP) {
            return this.getString("nicSettingsUseDhcp");
         } else if (ipGenerator._type
               === GosCustomizationHelperService.ADAPTER_TYPE_UNKNOWUN_IP) {
            return this.getString("nicSettingsPromptUser");
         } else if (ipGenerator._type
               === GosCustomizationHelperService.ADAPTER_TYPE_CUSTOM_IP) {
            return this.getString("nicSettingsCustom");
         } else if (ipGenerator._type
               === GosCustomizationHelperService.ADAPTER_TYPE_FIXED_IP) {
            return (ipGenerator as FixedIp).ipAddress;
         }

         return this.getString("nicSettingsUnknown");
      }

      /**
       * Returns a summary of the IPv6 settings of the virtual network adapter
       * possible values are:
       * <ul>
       *    <li>Not used</li>
       *    <li>Use DHCP</li>
       *    <li>Prompt user</li>
       *    <li>Custom</li>
       *    <li>Fixed IPv6 (The IP address)</li>
       * </ul>
       */
      getIPv6NicSettingSummary(ipv6AddressSpec: IPSettings$IpV6AddressSpec): string {

         if (!ipv6AddressSpec || !ipv6AddressSpec.ip || !ipv6AddressSpec.ip.length) {
            return this.getString("nicSettingsNotUsed");
         }

         if (ipv6AddressSpec.ip[0]._type
               === GosCustomizationHelperService.ADAPTER_TYPE_DHCP_V6) {
            return this.getString("nicSettingsUseDhcp");
         } else if (ipv6AddressSpec.ip[0]._type
               === GosCustomizationHelperService.ADAPTER_TYPE_UNKNOWUN_IP_V6) {
            return this.getString("nicSettingsPromptUser");
         } else if (ipv6AddressSpec.ip[0]._type ===
               GosCustomizationHelperService.ADAPTER_TYPE_CUSTOM_IP_V6) {
            return this.getString("nicSettingsCustom");
         } else if (ipv6AddressSpec.ip[0]._type
               === GosCustomizationHelperService.ADAPTER_TYPE_FIXED_IP_V6) {
            return (ipv6AddressSpec.ip[0] as FixedIpV6).ipAddress;
         }

         return this.getString("nicSettingsUnknown");
      }

      getInitialNetworkCards(): Array<AdapterMappingWrapper> {
         let adapterMappingWrapper: AdapterMappingWrapper =
               this.createNewAdapterMappingWrapper(this.getString("nicNameFormat", "1"));
         return [adapterMappingWrapper];
      }

      /**
       * Determines if network adapters use typical settings for
       * customization.
       */
      isNetworkTypeStandard(specDetails?: Specification): boolean {
         if (!specDetails) {
            return false;
         }
         // User has customized DNS suffixes - does not hold for Linux
         if (!this.isLinuxPrep(specDetails) && this.hasDnsSuffixList(specDetails)) {
            return false;
         }

         if (this.specificationHasDefaultNumberOfNics(specDetails)) {
            if (this.hasCustomizedAdapter(specDetails)) {
               return false;
            }
         } else {
            // If not equal - it means that user has added/deleted NICs
            return false;
         }

         return true;
      }

      createAdapterMappingWrappersFromSpecification(
            spec: Specification): Array<AdapterMappingWrapper> {
         let adapterMappingWrappers: Array<AdapterMappingWrapper> = [];
         let adapterMappings: Array<AdapterMapping> = spec.nicSettingMap;

         if (adapterMappings && adapterMappings.length) {
            for (let i: number = 0; i < adapterMappings.length; i++) {
               let adapterDescription: string = this.getString("nicNameFormat", (i + 1));
               let adapterWrapper: AdapterMappingWrapper =
                     this.createNewAdapterMappingWrapper(adapterDescription, adapterMappings[i]);
               adapterMappingWrappers.push(adapterWrapper);
            }
         }
         return adapterMappingWrappers;
      }

      createNewAdapterMappingWrapper(description: string,
            adapterMapping?: AdapterMapping): AdapterMappingWrapper {
         if (!adapterMapping) {
            adapterMapping = new AdapterMapping();
            let ipSettings: IPSettings = new IPSettings();
            ipSettings.ip = new DhcpIpGenerator();
            adapterMapping.adapter = ipSettings;
         }

         let adapterMappingWrapper: AdapterMappingWrapper = new AdapterMappingWrapper();
         adapterMappingWrapper.adapterMapping = adapterMapping;
         adapterMappingWrapper.description = description;
         adapterMappingWrapper.ipV4Address = this.getIPv4NicSettingSummary(
               adapterMapping.adapter.ip);
         adapterMappingWrapper.ipV6Address = this.getIPv6NicSettingSummary(
               adapterMapping.adapter.ipV6Spec);

         return adapterMappingWrapper;
      }

      /**
       * Updates the names of adapters when a previous adapter has been deleted.
       */
      updateAdapterMappingNames(index: number,
            adapterWrappers: Array<AdapterMappingWrapper>): void {
         for (let i: number = index; i < adapterWrappers.length; i++) {
            let adapterWrapper: AdapterMappingWrapper = adapterWrappers[i];
            adapterWrapper.description = this.getString("nicNameFormat", (i + 1));
         }
      }

      private getString(key: string, parameter?: any): string {
         return this.i18nService.getString(
               "VmUi",
               "customizationManager.customizationSpecViewData." + key, parameter);
      }

      /**
       * Checks if given netowrk adapter is customized by the user.
       */
      private static isAdapterCustomized(adapter: AdapterMapping): boolean {
         if (adapter.adapter.ip._type
                     !== GosCustomizationHelperService.ADAPTER_TYPE_DHCP
               || (adapter.adapter.ipV6Spec && adapter.adapter.ipV6Spec._type
                     === GosCustomizationHelperService.IPV6_ADDRESS_SPEC_TYPE)
               || adapter.adapter.dnsServerList
               || adapter.adapter.gateway
               || adapter.adapter.primaryWINS
               || adapter.adapter.secondaryWINS
               || adapter.adapter.subnetMask) {
            return true;
         }
         return false;
      }

      private isLinuxPrep(specDetails: Specification): boolean {
         return specDetails.identity._type
               === GosCustomizationHelperService.IDENTITY_TYPE_LINUX_PREP;
      }

      private hasDnsSuffixList(specDetails: Specification): boolean {
         return specDetails.globalIPSettings
               && !_.isEmpty(specDetails.globalIPSettings.dnsSuffixList);
      }

      private specificationHasDefaultNumberOfNics(specDetails: Specification): boolean {
         return specDetails.nicSettingMap
               && specDetails.nicSettingMap.length
                     === GosCustomizationHelperService.DEFAULT_NUMBER_OF_NICS;
      }

      private hasCustomizedAdapter(specDetails: Specification): boolean {
         let customizedAdapter: AdapterMapping =
               _.find(specDetails.nicSettingMap, (adapter: AdapterMapping) => {
                  return GosSpecNetworkService.isAdapterCustomized(adapter);
               });
         return !!customizedAdapter;
      }

   }

   angular.module("com.vmware.vsphere.client.vm")
         .service("gosSpecNetworkService", GosSpecNetworkService);
}

