namespace dvs_ui {

   export class DvsConfigureSettingsPageComponent {

      public bindings: any;
      public controller: any;
      public templateUrl: string;

      constructor() {
         this.bindings = {
            wizardModel: "<",
            wizardConfig: "<"
         };

         this.controller = DvsConfigureSettingsPageComponentController;

         this.templateUrl = "dvs-ui/resources/dvs/create/views/" +
               "dvsConfigureSettingsPageComponentTemplate.html";
      }
   }

   class DvsConfigureSettingsPageComponentController {

      static $inject = [
         "i18nService",
         "$scope",
         "dvsCommonPropertiesValidator",
         "dataService",
         "dvsCreateWizardConstants",
         "dvpgPropertiesService"
      ];

      public wizardModel: DvsCreateWizardModel;

      public wizardConfig: any;

      private _numUplinksValidator: any;

      private _portgroupNameValidator: any;

      private _niocStatusOptions: any;

      constructor(private i18nService: any,
                  private $scope: any,
                  private validator: DvsCommonPropertiesValidator,
                  private dataService: any,
                  private wizardConstants: any,
                  private dvpgPropertiesService: any) {
         $scope.i18n = this.i18nService.getString;

         this._numUplinksValidator = {
            message: null,
            isVisible: false
         };

         this._portgroupNameValidator = {
            message: null,
            isVisible: false
         };

         this._niocStatusOptions = [{
            label: this.i18nService.getString(
                  "DvsUi", "DvsConfigureSettingsPage.resmgmt.enabled"),
            value: true
         }, {
            label: this.i18nService.getString(
                  "DvsUi", "DvsConfigureSettingsPage.resmgmt.disabled"),
            value: false
         }];
      }

      $onInit(): void {
         if (!this.wizardConfig.configureSettingsPageInitialized) {
            this.wizardConfig.loading = true;
            this.requestUniquePortgroupName();
         } else {
            // triggers validation in case the user returns to this page
            this.onNumUplinksChange();
            if (this.wizardModel.createDefaultPortgroup) {
               this.onPortgroupNameChange();
            }
         }
      }

      public get numUplinksValidator(): any {
         return this._numUplinksValidator;
      }

      public onNumUplinksChange(): void {
         let message: string =
               this.validator.getNumberOfUplinksError(this.wizardModel.numberOfUplinks);
         this.numUplinksValidator.message = message;
         this.numUplinksValidator.isVisible = message !== null;
      }

      public get portgroupNameValidator(): any {
         return this._portgroupNameValidator;
      }

      public onPortgroupNameChange(): void {
         let message: string =
               this.dvpgPropertiesService.getInvalidNameError(
                     this.wizardModel.defaultPortgroupName);
         this.portgroupNameValidator.message = message;
         this.portgroupNameValidator.isVisible = message !== null;
      }

      public onCreatePortGroupCheckBoxChange(): void {
         if (this.wizardModel.createDefaultPortgroup) {
            this.onPortgroupNameChange();
         } else {
            this.portgroupNameValidator.message = null;
            this.portgroupNameValidator.isVisible = false;
         }
      }

      public get niocStatusOptions(): any {
         return this._niocStatusOptions;
      }

      private requestUniquePortgroupName(): void {
         this.dataService.getProperties(
               this.wizardModel.parentEntityUrn,
                  [this.wizardConstants.properties.DVPG_NEW_UNIQUE_NAME])
               .then((response: any): void => {
                  this.wizardModel.defaultPortgroupName
                        = response[this.wizardConstants.properties.DVPG_NEW_UNIQUE_NAME];
                  this.wizardConfig.loading = false;
                  this.wizardConfig.configureSettingsPageInitialized = true;
               });
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .component("dvsConfigureSettingsPage",
               new DvsConfigureSettingsPageComponent());
}
