/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
(function () {
   'use strict';

   angular
         .module('com.vmware.vsphere.client.networkLibUi')
         .controller('NetworkSelectorController', NetworkSelectorController);


   NetworkSelectorController.$inject = [
         '$scope',
         'i18nService',
         'NetworkSelectorMode',
         'defaultUriSchemeUtil',
         'networkSelectorDialogService',
         'managedEntityConstants'
   ];

   function NetworkSelectorController (
         $scope,
         i18nService,
         NetworkSelectorMode,
         defaultUriSchemeUtil,
         networkSelectorDialogService,
         managedEntityConstants) {

      var self = this;
      var BROWSE_OPTION_ID = 'browse-networks-option-id';

      self.browseButtonLabel =
            i18nService.getString('Common', 'NetworkSelectorDropDown.browseEntry');
      var recentNetworks = [];

      self.$onInit = function () {
         initController();
         self.selectedNetwork = self.preselectedNetwork;

         if(self.mode === NetworkSelectorMode.SINGLE_SELECTION_DROPDOWN) {
            self.networkDropdownOptions = [{
               name: self.browseButtonLabel,
               id: BROWSE_OPTION_ID
            }];

            populatePrefetchedNetworks();
            $scope.$on('onPrefetchNetworksCompleted', function () {
               populatePrefetchedNetworks();
            });
         }
      };

      function populatePrefetchedNetworks() {
         recentNetworks = angular.copy(self.prefetchedNetworks);
         addStandardNetworkFromMatch(self.preselectedNetwork);
         if (self.preselectedNetwork) {
            buildDropdownData(self.preselectedNetwork.id);
         }

         self.selectedNetwork = self.preselectedNetwork;
      }

      self.onSelectorBrowseButtonClicked = function() {
         networkSelectorDialogService.openNetworkSelectorDialog(self.objectRef,
               self.filterSpec, self.dialogSpec, onNetworkSelection);
      };

      function initController() {
         self.networkSelectorName = $scope.networkSelectorName ?
               $scope.networkSelectorName : 'selectNetwork';
         self.objectRef = $scope.objectRef;
         self.mode = $scope.mode;
         self.filterSpec = $scope.filterSpec;
         self.dialogSpec = $scope.dialogSpec;
         self.preselectedNetwork = $scope.preselectedNetwork;
         self.onSelectedNetworks = $scope.onSelectedNetworks;
         self.onPreloadNetworks = $scope.onPreloadNetworks;
         self.preloadNetworks = $scope.preloadNetworks;
         self.prefetchedNetworks = $scope.prefetchedNetworks;
         self.sortParams = $scope.sortParams;
         $scope.isDisabled = !!$scope.isDisabled;

         if ($scope.errorConfig === undefined) {
            $scope.errorConfig = {
               message: "",
               isVisible: false
            };
         }

         self.errorConfig = $scope.errorConfig;
      }

      self.onSelectNetwork = onSelectorDropdownOptionChanged;
      function onSelectorDropdownOptionChanged(newSelectionValue , oldSelectionValue) {
         var newSelection = self.selectedNetwork;
         if (newSelection.id === BROWSE_OPTION_ID) {

            self.selectedNetwork = oldSelectionValue;

            if (self.preloadNetworks) {
               self.onPreloadNetworks().then(function(retrievedNetworks) {
                  self.retrievedNetworks = retrievedNetworks;

                  networkSelectorDialogService.openNetworkSelectorDialog(self.objectRef,
                        self.filterSpec, self.dialogSpec, onNetworkSelection,
                        self.sortParams, self.retrievedNetworks);
               });
            } else {
               networkSelectorDialogService.openNetworkSelectorDialog(self.objectRef,
                     self.filterSpec, self.dialogSpec, onNetworkSelection,
                     self.sortParams, self.retrievedNetworks);
            }

         } else {
            var selectedNetwork = {
               name: newSelection.name,
               networkRef: defaultUriSchemeUtil.getManagedObjectReference(newSelection.id),
               dvsUuid: newSelection.dvsUuid,
               opaqueNetworkType: newSelection.opaqueNetworkType,
               portgroupKey: newSelection.portgroupKey
            };
            if (self.onSelectedNetworks) {
               self.onSelectedNetworks({ selectedNetworks: [selectedNetwork] });
            }
            buildDropdownData(defaultUriSchemeUtil.getVsphereObjectId(selectedNetwork.networkRef));
         }
      }

      function onNetworkSelection(selectedNetworks) {
         var network = selectedNetworks[0];
         var selectedNetwork = {
            name: network.name,
            id: defaultUriSchemeUtil.getVsphereObjectId(network.networkRef),
            dvsUuid: network.dvsUuid,
            portgroupKey: network.portgroupKey
         };

         if (self.mode === NetworkSelectorMode.SINGLE_SELECTION_DROPDOWN) {
            pushRecentNetwork(selectedNetwork);
            if (selectedNetwork.id) {
               buildDropdownData(selectedNetwork.id);
            }
         }

         self.selectedNetwork = selectedNetwork;
         if (self.onSelectedNetworks) {
            self.onSelectedNetworks({ selectedNetworks: selectedNetworks });
         }
      }

      function addStandardNetworkFromMatch(network) {
         if (!network || !network.id) {
            return;
         }
         var networkRef = defaultUriSchemeUtil.getManagedObjectReference(network.id);
         if (networkRef.type !== managedEntityConstants.NETWORK &&
               networkRef.type !== managedEntityConstants.OPAQUE_NETWORK){
            return;
         }

         var selectedNetwork = {
            name: network.name,
            id: network.id,
            networkRef: networkRef,
            dvsUuid: null,
            opaqueNetworkType: network.opaqueNetworkType
         };

         pushRecentNetwork(selectedNetwork);
      }

      function buildDropdownData(selectedNetworkId) {
         var selectedNetwork =  _.filter(recentNetworks, function(network) {
            return network.id === selectedNetworkId;
         });
         var filteredNetworks = _.filter(recentNetworks, function(network) {
            return network.id !== selectedNetworkId;
         });
         var sortedNetworks = _.sortBy(filteredNetworks, function(network) {
            return network.name;
         });
         if (selectedNetwork.length) {
            sortedNetworks = selectedNetwork.concat(sortedNetworks);
         }

         self.networkDropdownOptions =
               sortedNetworks.concat([{
                  name: self.browseButtonLabel,
                  id: BROWSE_OPTION_ID
               }]);
      }

      function pushRecentNetwork(network) {
         //TODO: zzhelyazkov - add here conditional propagation of the pushed network to the self.prefetchedNetworks
         if (_.findIndex(recentNetworks, function(value) {
                  return value.id === network.id;
               }) < 0) {
            recentNetworks.push(network);
         }
      }
   }
})();
