/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace network_ui {

   import IRootScopeService = angular.IRootScopeService;
   import EditVirtualSwitchDialogManager = network_ui.EditVirtualSwitchDialogManager;
   import PropertiesPageModel = network_ui.PropertiesPageModel;
   import SecurityPageModel = network_ui.SecurityPageModel;
   import TrafficShapingPageModel = network_ui.TrafficShapingPageModel;
   import TeamingAndFailoverPageModel = network_ui.TeamingAndFailoverPageModel;

   export class EditVirtualSwitchDialogService {
      public static $inject = [
         "$rootScope",
         "i18nService",
         "dataService",
         "editVirtualSwitchDialogManagerFactory",
         "vxMultiPageDialogService"];

      private i18n: any;

      private vSwitchProp = "virtualswitch:editDialogData";

      constructor(private $rootScope: IRootScopeService,
                  private i18nService: any,
                  private dataService: any,
                  private editVirtualSwitchDialogManagerFactory: EditVirtualSwitchDialogManagerFactory,
                  private vxMultiPageDialogService: any) {
         this.i18n = i18nService;
      }

      public show(hostId: String, selectedSwitch: any): void {
         let dialogScope: any = this.$rootScope.$new();
         let dialogManager: EditVirtualSwitchDialogManager;

         dialogScope.dialogOptions = {};
         dialogScope.propertiesPageModel = new PropertiesPageModel();
         dialogScope.securityPageModel = new SecurityPageModel();
         dialogScope.trafficShapingPageModel = new TrafficShapingPageModel();
         dialogScope.teamingAndFailoverPageModel = new TeamingAndFailoverPageModel();
         dialogScope.dialogOptions.confirmOptions = {
            onClick: () => {
               return dialogManager.submit();
            }
         };

         dialogManager = this.editVirtualSwitchDialogManagerFactory.createManager(dialogScope);

         _.extend(dialogScope.dialogOptions, {
            title: this.i18n.getString(
                  "NetworkUi", "EditVSwitchSettingsView.title", selectedSwitch.name),
            pages: dialogManager.getPageDefinitions(),
            show: true,
            loading: true,
            loadingMessage: this.i18n.getString("CommonUi", "wizard.loading"),
            validationBanner: {},
            rejectOptions: {
               rejectOnEsc: true
            },
            resizable: true,
            draggable: true,
            maximizable: true,
            height: "676px",
            width: "1000px"
         });

         let options = {
            scope: dialogScope,
            configObjectName: "dialogOptions"
         };

         this.vxMultiPageDialogService(options);

         this.getVirtualSwitchDialogDataPromise(hostId, selectedSwitch.key).then(
               (result: any) => {
                  dialogScope.hostId = hostId;
                  this.buildGeneralPageModel(result[this.vSwitchProp], dialogScope);
                  dialogScope.dialogOptions.loading = false;
               });
      }

      private buildGeneralPageModel(data: any, dialogScope: any) {
         if (!data) {
            return;
         }
         // Properties page model
         if (data.propertiesData) {
            dialogScope.propertiesPageModel.name = data.propertiesData.name;
            dialogScope.propertiesPageModel.mtu = data.propertiesData.mtu;
            dialogScope.propertiesPageModel.numPorts = data.propertiesData.numberOfPorts;
            dialogScope.propertiesPageModel.availableNumPorts =
                  data.propertiesData.availableNumberOfPorts + data.propertiesData.numberOfPorts;
            dialogScope.propertiesPageModel.showRestartWarning =
                  data.propertiesData.showRestartWarning;
            dialogScope.propertiesPageModel.initialState =
                  angular.copy(dialogScope.propertiesPageModel);
         }
         // Security page model
         if (data.securityPolicies) {
            dialogScope.securityPageModel.allowPromiscuousMode =
                  data.securityPolicies.allowPromiscuousMode;
            dialogScope.securityPageModel.forgedTransmits =
                  data.securityPolicies.forgedTransmits;
            dialogScope.securityPageModel.macChanges =
                  data.securityPolicies.macChanges;
            dialogScope.securityPageModel.initialState =
                  angular.copy(dialogScope.securityPageModel);
         }
         // Traffic Shaping page model
         if (data.trafficShapingPolicies) {
            dialogScope.trafficShapingPageModel.enabled =
                  data.trafficShapingPolicies.enabled;

            dialogScope.trafficShapingPageModel.averageBandwidth =
                  data.trafficShapingPolicies.averageBandwidth;

            dialogScope.trafficShapingPageModel.peakBandwidth =
                  data.trafficShapingPolicies.peakBandwidth;

            dialogScope.trafficShapingPageModel.burstSize =
                  data.trafficShapingPolicies.burstSize;
            dialogScope.trafficShapingPageModel.initialState =
                  angular.copy(dialogScope.trafficShapingPageModel);
         }
         // Teaming and Failover page model
         if (data.teamingAndFailover) {
            dialogScope.teamingAndFailoverPageModel.loadBalancing =
                  data.teamingAndFailover.loadBalancing;
            dialogScope.teamingAndFailoverPageModel.nicTeamingPolicy =
                  data.teamingAndFailover.nicTeamingPolicy;
            dialogScope.teamingAndFailoverPageModel.checkBeacon =
                  data.teamingAndFailover.checkBeacon;
            dialogScope.teamingAndFailoverPageModel.notifySwitches =
                  data.teamingAndFailover.notifySwitches;
            dialogScope.teamingAndFailoverPageModel.rollingOrder =
                  data.teamingAndFailover.rollingOrder;
            dialogScope.teamingAndFailoverPageModel.setPnics(
                  data.teamingAndFailover.pnics);
            dialogScope.teamingAndFailoverPageModel.pnicDetails =
                  data.teamingAndFailover.pnics ?
                        data.teamingAndFailover.pnics.pnicDetails : null;
            dialogScope.teamingAndFailoverPageModel.initialState =
                  angular.copy(dialogScope.teamingAndFailoverPageModel);
         }
      }

      private getVirtualSwitchDialogDataPromise(hostId: String, selectedSwitchKey: String): any {

         let switchIdSpec = {
            _type: "com.vmware.vsphere.client.h5.network.host.virtualswitch.spec.VirtualSwitchIdSpec",
            key: selectedSwitchKey
         };

         let params = {
            propertyParams: [{
               propertyName: this.vSwitchProp,
               parameterType: switchIdSpec._type,
               parameter: switchIdSpec
            }]
         };

         return this.dataService.getProperties(hostId, [this.vSwitchProp], params);
      }
   }

   angular.module("com.vmware.vsphere.client.network")
         .service("editVirtualSwitchDialogService", EditVirtualSwitchDialogService);
}
