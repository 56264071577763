angular.module('com.vmware.vsphere.client.vm').service('datastoreNameFetcher',
      ['dataService', 'defaultUriSchemeUtil', function (dataService, defaultUriSchemeUtil) {
   return {
      fetchNames: function (vmId) {
         return dataService.getProperties(vmId, ['host']).then(function(response) {
            var hostId = defaultUriSchemeUtil.getVsphereObjectId(response.host);
            return dataService.getProperties(hostId, ['dc']);
         }).then(function(response) {
            var dcId = defaultUriSchemeUtil.getVsphereObjectId(response.dc);
            return dataService.getProperties(dcId, ['datastore']);
         }).then(function(response) {
            return response.datastore;
         });
      }
   };
}]);
