/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace dvs_ui {

   import IPromise = angular.IPromise;
   import HostListItemData = com.vmware.vsphere.client.dvs.api.host.HostListItemData;
   export class DvsHostListComponent {

      public bindings: any;
      public controller: any;
      public template: string;

      constructor() {
         this.bindings = {
            onHostsChange: "&",
            hostsRetriever: "&",
            showVdsStateColumn: "<",
            allowAnySelection: "<",
            addedHosts: "<",
            showIncompatibleHostsButton: "<",
            dvsId: "<",
            addActionLabel: "@",
            addDialogTitle: "@",
            hostColumnFormat: "@"
         };

         this.controller = DvsHostListController;
         this.template =
               `<div class="fill-parent flexbox flex-grow-auto">
                  <div ng-if="$ctrl.datagridOptions"
                       vui-datagrid="$ctrl.datagridOptions"
                       vx-stretch-grid
                       autofocus tabindex="-1"
                       aria-label="{{$ctrl.gridDescription}}"
                       class="flex-grid"
                       id="selectedHostsGrid"/>
               </div>`;
      }
   }

   class DvsHostListController {

      static $inject = [
         "vuiConstants",
         "i18nService",
         "selectHostsDialogService",
         "defaultUriSchemeUtil"
      ];

      private selectedHostItems: HostListItemData[];

      private datagridOptions: any;

      private gridDescription: string;

      public onHostsChange: (data: {hosts: HostListItemData[]}) => void;

      public hostsRetriever: () => IPromise<HostListItemData[]>;

      public addedHosts: HostListItemData[];

      public addActionLabel: string;

      public addDialogTitle: string;

      public showVdsStateColumn: boolean;

      public showIncompatibleHostsButton: boolean;

      public dvsId: string;

      public allowAnySelection: boolean;

      public hostColumnFormat: string;

      constructor(private vuiConstants: any,
                  private i18nService: any,
                  private selectHostsDialogService: SelectHostsDialogService,
                  private defaultUriSchemeUtil: any) {
      }

      public $onInit() {
         this.hostColumnFormat = this.hostColumnFormat || "{0}";
         let selectedItems = [];
         let hostListController: DvsHostListController = this;
         this.gridDescription = this.i18nService.getString(
               "DvsUi", "DvsHostSelector.selectedHostsList.description");
         this.datagridOptions = {
            height: "100%",
            pageConfig: {
               hidePager: false
            },
            columnDefs: this.buildColumnDefinitions(),
            sortMode: this.vuiConstants.grid.sortMode.SINGLE,
            selectionMode: this.vuiConstants.grid.selectionMode.MULTI,
            showCheckboxesOnMultiSelection: false,
            data: this.addedHosts,
            get selectedItems() {
               return selectedItems;
            },
            set selectedItems(selectedItems: any[]) {
               hostListController.selectedHostItems =
                     hostListController.mapGridItemsToHostListItems(selectedItems);
               hostListController.updateRemoveActionEnabledState();
            },
            resizable: true,
            actionBarOptions: this.createActionBarOptions()
         };

         this.updateRemoveActionEnabledState();
      }

      private createActionBarOptions(): any {
         return {
            actions: [{
               id: "add-action",
               label: this.addActionLabel,
               enabled: true,
               iconClass: "network-lib-ui-icon-add",
               onClick: this.onAddClicked.bind(this)
            }, {
               id: "remove-action",
               label: this.i18nService.getString(
                     "DvsUi", "DvsHostSelector.removeHostsButton.title"),
               iconClass: "network-lib-ui-icon-deleteIcon",
               onClick: this.onRemoveClicked.bind(this)
            }]
         };
      }

      private updateRemoveActionEnabledState(): void {
         this.datagridOptions.actionBarOptions.actions[1].enabled =
               this.selectedHostItems && this.selectedHostItems.length !== 0;
      }

      private mapGridItemsToHostListItems(selectedItems: any[]): HostListItemData[] {
         return _.filter(this.addedHosts, (item: HostListItemData) => {
            return _.findWhere(selectedItems, {hostName: item.hostName});
         });
      }

      private onAddClicked(): void {
         let hostsPromise: IPromise<HostListItemData[]> =
               this.hostsRetriever().then((hosts: HostListItemData[]): HostListItemData[] => {
                  return this.hostItemsDifference(hosts, this.addedHosts);
               });
         this.showDialog(hostsPromise);
      }

      private onRemoveClicked(): void {
         if (this.selectedHostItems.length > 0) {

            this.updateData(this.hostItemsDifference(this.addedHosts, this.selectedHostItems));
         }
      }

      private buildColumnDefinitions() {
         return [{
            displayName: this.i18nService.getString(
                  "DvsUi", "DvsHostSelector.selectedHostsList.hostNameColumn.title"),
            field: "hostName",
            width: "180px",
            template: (data: HostListItemData): string => {
               return this.buildCellHtml(
                     this.i18nService.interpolate(
                           this.hostColumnFormat,
                           [data.hostName]),
                     data.hostIconId
               );
            }
         }, {
            displayName: this.i18nService.getString(
                  "DvsUi", "DvsHostSelector.selectedHostsList.hostStatusColumn.title"),
            field: "connectionState",
            width: "150px",
            template: (data: HostListItemData): string => this.buildCellHtml(data.connectionStateLabel)
         }];
      }

      private buildCellHtml(text: string, icon: string = ""): string {
         let template: string = "<div>%icon%text</div>";

         return template
               .replace("%icon", "<span class='" + icon + "'></span>")
               .replace("%text", "<span class='vertical-align-cell'>" + text + "</span>");
      }

      private updateData(hosts: HostListItemData[]) {
         this.addedHosts = hosts;
         this.datagridOptions.data = hosts;
         this.onHostsChange({hosts: hosts});
      }

      private showDialog(hostsPromise: IPromise<HostListItemData[]>): void {
         this.selectHostsDialogService.show(
               hostsPromise,
               (selectedHostItems: HostListItemData[]): void => {
                     this.updateData(this.addedHosts.concat(selectedHostItems));
               },
               this.addDialogTitle,
               this.showVdsStateColumn,
               this.showIncompatibleHostsButton,
               this.allowAnySelection,
               this.dvsId
         );
      }

      private hostItemsDifference(items: HostListItemData[],
                                  itemsToBeRemoved: HostListItemData[]): HostListItemData[] {
         return _.filter(items, (item: HostListItemData): boolean => {
            return !_.some(itemsToBeRemoved, (itemToBeRemoved): boolean => {
               return this.defaultUriSchemeUtil.getVsphereObjectId(itemToBeRemoved.hostRef) === this.defaultUriSchemeUtil.getVsphereObjectId(item.hostRef);
            });
         });
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .component("dvsHostList", new DvsHostListComponent());
}
