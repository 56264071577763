/* Copyright 2019 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace network_lib_ui {

   export class PhysicalAdaptersListService {

      static readonly $inject: string[] = [
         "dataService",
         "i18nService",
         "numberFormatterService"
      ];

      static readonly PHYSICAL_ADAPTER_ITEMS_PROPERTY: string = "pnic:physicalAdapterItems";
      static readonly PHYSICAL_ADAPTER_ITEM_DETAILS_PROPERTY: string = "pnic:physicalAdapterItemDetails";
      static readonly UNCLAIMED_PHYSICAL_ADAPTERS_PROPERTY: string = "pnic:unclaimedPhysicalAdapters";
      static readonly PHYSICAL_ADAPTERS_WITH_DETAILS_PROPERTY: string = "pnic:physicalAdaptersWithDetails";
      static readonly PROXY_SWITCH_UPLINK_DATA_PROPERTY: string = "proxyswitch:getProxySwitchUplinkData";

      constructor(private dataService: any,
            private i18nService: any,
            private numberFormatterService: any) {
      }

      getPhysicalAdapterItems(hostId: string): any {
         return this.dataService.getProperties(hostId, [PhysicalAdaptersListService.PHYSICAL_ADAPTER_ITEMS_PROPERTY], {
            skipErrorInterceptor: true
         }).then((dataServiceResponse: any): any[] => {
            let physicalAdapterItems: any[] = dataServiceResponse
                  ? dataServiceResponse[PhysicalAdaptersListService.PHYSICAL_ADAPTER_ITEMS_PROPERTY]
                  : [];

            physicalAdapterItems = physicalAdapterItems || [];
            let currentPnicIndex: number = 0;

            physicalAdapterItems.forEach((pnicItem: any): void => {
               if (!pnicItem.virtualSwitch) {
                  pnicItem.virtualSwitch = {
                     switchName: this.i18nService
                           .getString("NetworkUi", "NetworkUtil.noData")
                  };
               }

               pnicItem.wakeOnLanSupportedLabel = pnicItem.wakeOnLanSupported
                     ? this.i18nService.getString("NetworkUi", "PnicView.labelYes")
                     : this.i18nService.getString("NetworkUi", "PnicView.labelNo");
               pnicItem.actualSpeedLabel = pnicItem.actualSpeed
                     ? this.numberFormatterService.formatBandwidth(
                           pnicItem.actualSpeed, BandwidthUnit.MBITPS, BandwidthUnit.AUTO)
                     : this.i18nService.getString("NetworkUi", "PnicView.down");
               pnicItem.configuredSpeedLabel = !pnicItem.configuredSpeed || pnicItem.configuredSpeed === 0
                     ? this.i18nService.getString("NetworkUi", "PnicView.autonegotiate")
                     : this.numberFormatterService.formatBandwidth(
                           pnicItem.configuredSpeed, BandwidthUnit.MBITPS, BandwidthUnit.AUTO);

               if(pnicItem.sriovInfo && pnicItem.sriovInfo.sriovCapable) {
                  if (pnicItem.sriovInfo.sriovEnabled) {
                     pnicItem.sriovStatusLabel =
                           this.i18nService.getString("NetworkUi", "Sriov.status.enabled");
                     pnicItem.sriovNumVirtuanFunctionsLabel =
                           pnicItem.sriovInfo.numVirtualFunctionsRequested ===
                           pnicItem.sriovInfo.numVirtualFunction
                                 ? pnicItem.sriovInfo.numVirtualFunction
                                 : this.i18nService.getString("NetworkUi", "Sriov.vf.mixedFormat",
                                 pnicItem.sriovInfo.numVirtualFunctionsRequested,
                                 pnicItem.sriovInfo.numVirtualFunction);
                  } else {
                     pnicItem.sriovStatusLabel =
                           this.i18nService.getString("NetworkUi", "Sriov.status.disabled");
                     pnicItem.sriovNumVirtuanFunctionsLabel =
                           this.i18nService.getString("NetworkUi", "Sriov.vf.na");
                  }
               } else {
                  pnicItem.sriovStatusLabel = this.i18nService.getString(
                        "NetworkUi", "Sriov.status.notSupported");
                  pnicItem.sriovNumVirtuanFunctionsLabel = this.i18nService.getString(
                        "NetworkUi", "Sriov.vf.na");
               }

               pnicItem.pnicIndex = currentPnicIndex++;
            });

            return physicalAdapterItems;
         });
      }

      getPhysicalAdapterDetailsItem(hostId: string, pnicName: string): any {
         const params: any = [{
            propertyName: PhysicalAdaptersListService.PHYSICAL_ADAPTER_ITEM_DETAILS_PROPERTY,
            parameterType: "java.lang.String",
            parameter: pnicName
         }];
         return this.dataService
               .getProperties(hostId, [PhysicalAdaptersListService.PHYSICAL_ADAPTER_ITEM_DETAILS_PROPERTY], {
                  propertyParams: params
               })
               .then((response: any): any => {
                  if (response && response[PhysicalAdaptersListService.PHYSICAL_ADAPTER_ITEM_DETAILS_PROPERTY]) {
                     return response[PhysicalAdaptersListService.PHYSICAL_ADAPTER_ITEM_DETAILS_PROPERTY];
                  }
                  return null;
               });
      }

      getUnclaimedPhysicalAdapters(hostId: string): any {
         return this.dataService.getProperties(hostId, [PhysicalAdaptersListService.UNCLAIMED_PHYSICAL_ADAPTERS_PROPERTY])
               .then((response: any): any => {
                  if (response && response[PhysicalAdaptersListService.UNCLAIMED_PHYSICAL_ADAPTERS_PROPERTY]) {
                     return response[PhysicalAdaptersListService.UNCLAIMED_PHYSICAL_ADAPTERS_PROPERTY];
                  }
                  return null;
               });
      }

      getPhysicalAdaptersWithDetails(hostId: string, vSwitchName: string): any {
         const params: any = [{
            propertyName: PhysicalAdaptersListService.PHYSICAL_ADAPTERS_WITH_DETAILS_PROPERTY,
            parameterType: "java.lang.String",
            parameter: vSwitchName
         }];
         return this.dataService.
               getProperties(hostId, [PhysicalAdaptersListService.PHYSICAL_ADAPTERS_WITH_DETAILS_PROPERTY], {
                  propertyParams: params
               })
               .then((response: any): any => {
                  if (response && response[PhysicalAdaptersListService.PHYSICAL_ADAPTERS_WITH_DETAILS_PROPERTY]) {
                     return response[PhysicalAdaptersListService.PHYSICAL_ADAPTERS_WITH_DETAILS_PROPERTY];
                  }
                  return null;
               });
      }

      getProxySwitchUplinkData(hostId: string, dvsRef: any, proxySwitchKey: string) {
         const hostSwitchSpec: any = {
            dvsRef: dvsRef,
            proxySwitchKey: proxySwitchKey
         };

         const params: any = [{
            propertyName: PhysicalAdaptersListService.PROXY_SWITCH_UPLINK_DATA_PROPERTY,
            parameterType: "com.vmware.vsphere.client.h5.network.host.proxyswitch.spec.ProxySwitchInfoSpec",
            parameter: hostSwitchSpec
         }];
         return this.dataService.
               getProperties(hostId, [PhysicalAdaptersListService.PROXY_SWITCH_UPLINK_DATA_PROPERTY], {
                  propertyParams: params
               })
               .then((response: any): any => {
                  if (response && response[PhysicalAdaptersListService.PROXY_SWITCH_UPLINK_DATA_PROPERTY]) {
                     return response[PhysicalAdaptersListService.PROXY_SWITCH_UPLINK_DATA_PROPERTY];
                  }
                  return null;
               });
      }
   }

   enum BandwidthUnit { BITPS, KBITPS, MBITPS, GBITPS, TBITPS, AUTO }

   angular.module("com.vmware.vsphere.client.networkLibUi")
         .service("physicalAdaptersListService", PhysicalAdaptersListService);
}