angular.module('com.vmware.vsphere.client.vm').service(
      'vmMigrationSelectMigrationTypePageService', [
         'i18nService',
         'wizardPageService',
         'vmCryptUtilService',
         'migrationTypeConstants',
         function(i18nService, wizardPageService, vmCryptUtilService, migrationTypeConstants) {
            return {
               build: function(wizardScope, flowIds) {
                  return {
                     title: i18nService.getString('VmUi', 'MigrationWizard.migrationTypePage.heading'),
                     description: i18nService.getString('VmUi', 'MigrationWizard.migrationTypePage.description'),
                     contentUrl: 'vm-ui/resources/vm/views/migrationWizard/pages/selectMigrationTypePage.html',
                     headerUrl: 'vm-ui/resources/vm/views/migrationWizard/pages/migratePagesCustomHeader.html',
                     hostData: wizardScope.attributes.hostData,
                     decisionOptions: {
                        flowIds: flowIds,
                        selectedFlowId: migrationTypeConstants.MIGRATE_RESOURCE_ONLY
                     },
                     disableNext: function() {
                        // The call to the VC is not yet resolved
                        return !wizardScope.migrationWizardManager.migrationTypePageModel.cachedProperties;
                     },
                     onCommit: function() {
                           wizardScope.wizardConfig.validationBanner.messages = [];
                           wizardPageService.markPageComplete(wizardScope.wizardConfig, wizardScope.attributes.creationType);

                           if (wizardScope.migrationWizardManager.getSelectedMode() === migrationTypeConstants.MIGRATE_STORAGE_ONLY) {
                              // Evaluate if the vm crypt storage profile is available for the
                              // selected vm or not
                              vmCryptUtilService.isVmCryptPolicyDisabledForMigrateWizard(
                                    // since the vms can not be from different vCenters we
                                    // can check get the vCenter of only one of them
                                    wizardScope.migrationWizardManager._theModel.vms[0],
                                    wizardScope.migrationWizardManager.storagePageModel);

                              // Reset the compute resource to clear it from the spec sent to the backend
                              // and to initialize correctly the page in the storage only flow
                              wizardScope.migrationWizardManager.clearSelectedComputeResource();
                           }
                           return true;
                     }
                  };
               }
            };
         }]
);
