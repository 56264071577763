namespace h5_vm {
   import FormattedRecommendation = h5_vm.FormattedRecommendation;
   export class VmXdrsRecommendationDialogService {
      public static $inject = [
            "$q",
            "clarityModalService",
            "i18nService"];

      constructor(
            private $q: angular.IQService,
            private clarityModalService: any,
            private i18nService: any) {
      }

      public showRecommendations(
            recommendations: FormattedRecommendation[],
            selectedRecommendationKey: string):
                  angular.IPromise<{selectedRecommendationKey: string}> {
         let deferredSelection: angular.IDeferred<any> = this.$q.defer();

         let dialogData: any = {
            recommendations: recommendations,
            selectedRecommendationKey: selectedRecommendationKey,
         };

         let modalOptions: any = {
            contentTemplate: "vm-ui/resources/vm/views/migrationWizard/components/" +
                  "vmPlacementRecommendation/vmPlacementRecommendationDialog.html",
            title: this.i18nService.getString("VmUi", "PlacementRecommendationDialog.title"),
            size: 'lg',
            dialogData: dialogData,
            onSubmit: (): boolean => {
               deferredSelection.resolve({
                  selectedRecommendationKey: dialogData.selectedRecommendationKey
               });
               return true;
            },
            onCancel: (): boolean => {
               deferredSelection.reject();
               return false;
            },
            defaultButton: 'submit',
            submitButtonTitle: this.i18nService
                  .getString("VmUi", "PlacementRecommendationDialog.ok")
         };
         this.clarityModalService.openOkCancelModal(modalOptions);

         return deferredSelection.promise;
      }

      public showFaults(faults: any[]): void {
         let modalOptions: any = {
            contentTemplate: "vm-ui/resources/vm/views/migrationWizard/components/" +
               "vmPlacementRecommendation/vmPlacementRecommendationDialog.html",
            title: this.i18nService.getString("VmUi", "PlacementRecommendationDialog.faults.title"),
            size: "lg",
            hideSubmitButton: true,
            closeButtonTitle: this.i18nService.getString("Common", "saveButtonLabel"),
            defaultButton: "close",
            dialogData: {
               faults: faults
            }
         };
         this.clarityModalService.openOkCancelModal(modalOptions);
      }
   }

   angular.module("com.vmware.vsphere.client.vm").service(
         "vmPlacementRecommendationDialogService", VmXdrsRecommendationDialogService);
}
