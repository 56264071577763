namespace h5_vm {
   import LinuxPrep = com.vmware.vim.binding.vim.vm.customization.LinuxPrep;
   import SysprepText = com.vmware.vim.binding.vim.vm.customization.SysprepText;
   import Sysprep = com.vmware.vim.binding.vim.vm.customization.Sysprep;

   export class GosFinishPageController {
      public static $inject = ["$q",
            "i18nService",
            "gosCustomizationHelperService"];

      model: GosFinishPageModel;

      i18n: any;

      nics: Array<any>;

      constructor(private $q: any,
            private i18nService: any,
            private helperService: GosCustomizationHelperService) {
         this.i18n = this.i18nService.getString;
      }

      $onInit(): void {
         this.model.onPageCommit = this.onCommit.bind(this);
         this.nics = this.getNics();
      }

      $onDestroy(): void {
         this.model.onPageCommit = undefined;
      }

      getSpecTypeString(gosSpecType: GosType): string {
         return this.helperService.gosTypeToString(gosSpecType);
      }

      onCommit(): angular.IPromise<Array<string>> {
         return this.$q.resolve([]);
      }

      isLinuxPrep(): boolean {
         return this.model.specDetails.identity instanceof LinuxPrep;
      }

      isSysPrep(): boolean {
         return this.model.specDetails.identity instanceof Sysprep;
      }

      isSysPrepText(): boolean {
         return this.model.specDetails.identity instanceof SysprepText;
      }

      getComputerName(): string {
         return this.helperService.getComputerName(this.model.specDetails);
      }

      getDomainName(): string {
         return this.helperService.getDomain(this.model.specDetails);
      }

      getProductKey(): string {
         return this.helperService.getWindowsLicenseKey(this.model.specDetails);
      }

      isServerLicensingIncluded(): boolean {
         return this.helperService.isServerLicensingIncluded(this.model.specDetails);
      }

      getServerLicensingInfo(): string {
         return this.helperService.getServerLicensingInfo(this.model.specDetails);
      }

      getTimeZone(): string {
         return this.helperService.getTimeZone(this.model.specDetails);
      }

      getHardwareClock(): string {
         return this.helperService.getHardwareClock(this.model.specDetails);
      }

      getRegInfoName(): string {
         return this.helperService.getRegistrationInfo(this.model.specDetails);
      }

      getPrimaryDns(): string {
         return this.helperService.getPrimaryDnsServer(this.model.specDetails);
      }

      getSecondaryDns(): string {
         return this.helperService.getSecondaryDnsServer(this.model.specDetails);
      }

      getTertiaryDns(): string {
         return this.helperService.getTertiaryDnsServer(this.model.specDetails);
      }

      getDnsSuffixList(): string[] {
         return this.helperService.getDnsSuffixList(this.model.specDetails);
      }

      getNetworkType(): string {
         return this.helperService.getNetworkTypeString(this.model.specDetails);
      }

      getLogInType(): string {
         return this.helperService.getLoginType(this.model.specDetails);
      }

      getAutomaticLogonTimes(): number|null {
         return this.helperService.getAutomaticLogonTimes(this.model.specDetails);
      }

      getWorkgroup(): string {
         return this.helperService.getWorkgroup(this.model.specDetails);
      }

      getDomain(): string {
         return this.helperService.getDomain(this.model.specDetails);
      }

      getDomainUsername(): string {
         return this.helperService.getDomainUsername(this.model.specDetails);
      }

      getGenerateNewSIDText(): string {
         let generateNewSID: boolean = this.helperService.generateNewSID(this.model.specDetails);
         if (generateNewSID) {
            return this.i18n("VmUi", "customizationManager.customizationSpecDetailsView.generateSID");
         }

         return this.i18n("VmUi", "customizationManager.customizationSpecDetailsView.doNotGenerateSID");
      }

      private getNics(): Array<any> {
         if (this.helperService.isNetworkTypical(this.model.specDetails)) {
            return [];
         }

         return this.helperService.getNicIpSettings(this.model.specDetails);
      }

      getCommands(): string[] {
         return this.helperService.getCommands(this.model.specDetails);
      }
   }

   export class GosFinishPageComponent implements angular.IComponentOptions {
      public controller: any;
      public templateUrl: string;
      public bindings: any;

      constructor() {
         this.controller = GosFinishPageController;
         this.templateUrl =
            "vm-ui/resources/vm/guest-os-customization/pages/finish/gos-finish-page.component.html";
         this.bindings = {
            model: "<"
         };
      }
   }

   angular
      .module("com.vmware.vsphere.client.vm")
      .component("gosFinishPage", new GosFinishPageComponent());
}
