module cluster_ui {

   import IComponentController = angular.IComponentController;

   export class DrsCpuUtilizationController implements IComponentController {

      private objectId: string;
      private DRS_CLUSTER_UTILIZATION_MODEL: string =
            "com.vmware.vsphere.client.clusterui.model.monitor.drs.utilization.DrsCpuClusterUtilizationData";
      public cpuUtilizationData: any = {};
      public watchForObjects: string[] = [this.objectId];
      public liveRefreshProperties: string[] = ["configStatus",
            "summary.currentBalance"];

      public static $inject = ["$scope", "i18nService", "dataService",
         "defaultUriSchemeUtil"];

      constructor(private $scope: any, private i18nService: any,
                  private dataService: any,
                  private defaultUriSchemeUtil: any) {

         this.objectId = $scope._route.objectId;
         this.getViewData();
      }

      public getViewData(): void {
         this.dataService.getData(this.objectId, this.DRS_CLUSTER_UTILIZATION_MODEL)
               .then((data: any) => {
                  this.sortVms(data);
                  this.cpuUtilizationData = data;
               });
      }

      private sortVms(data: any): void {
         if (data !== null && data.hosts !== null) {
            data.hosts.forEach((host: any) => {
               host.poweredOnVms.sort((a: any, b: any): number => {
                  return a.cpuBarWidth > b.cpuBarWidth ? -1 : 1;
               });
            });
         }
      }

      public getString(key: string, ...params: any[]) : string {
         return this.i18nService.getString("ClusterUi", key, params);
      }
   }

   angular.module("com.vmware.vsphere.client.cluster")
         .controller("DrsCpuUtilizationController", DrsCpuUtilizationController);
}
