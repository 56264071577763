namespace h5_vm {

   import LocationSpecPair = com.vmware.vsphere.client.vm.migration.LocationSpecPair;

   let defaultUriSchemeUtil: any;
   let mutationService: any;

   export class VmXdrsRecommendationService {

      private _recommendations: any[];
      private _faults: any[];
      private _faultsReason: string;
      private _selectedRecommendation: any;
      private _hasInitErrors: boolean;

      constructor() {
         this._recommendations = [];
         this._faults = [];
         this._selectedRecommendation = undefined;
         this._hasInitErrors = false;
      }

      /**
       * Fetches and saves xDRS recommendations
       * @param locationSpecPair
       *    Contains info a about the VM being migrated and the location specification
       *    with wich the migration will be executed.
       */
      public load(clusterId: string, locationSpecPair: LocationSpecPair): IPromise<void> {
         let vmPlacementSpec: any /* VmPlacementSpec */ = {
            _type: "com.vmware.vsphere.client.vm.migration.VmPlacementSpec",
            vm: locationSpecPair.entity,
            relocateSpec: locationSpecPair.spec.relocateSpec,
            priority: locationSpecPair.spec.priority
         };
         vmPlacementSpec.vm = locationSpecPair.entity;
         vmPlacementSpec.relocateSpec = locationSpecPair.spec.relocateSpec;
         vmPlacementSpec.priority = locationSpecPair.spec.priority;

         return mutationService.validate(
               clusterId,
               vmPlacementSpec._type,
               vmPlacementSpec
         ).then((response: any) => {
            this._hasInitErrors = false;

            if (response.error ||
                  !response.result ||
                  (!response.result.recommendations && !response.result.drsFault)) {
               this._hasInitErrors = true;
            }

            this.init(response.result);
         });
      }

      public getSelectedRecommendation(): any {
         return this._selectedRecommendation;
      }

      public setSelectedRecommendation(value: any): any {
         this._selectedRecommendation = value;
      }

      public hasRecommendations(): boolean {
         return this._recommendations && !!this._recommendations.length;
      }

      public getRecommendations(): any[] {
         return this._recommendations;
      }

      public hasFaults(): boolean {
         return this._faults && !!this._faults.length;
      }

      public getFaults(): any[] {
         return this._faults;
      }

      public getFaultsReason(): string {
         return this._faultsReason;
      }

      public hasInitErrors(): boolean {
         return this._hasInitErrors;
      }

      private init(placementResult: any): void {
         this._recommendations = placementResult && placementResult.recommendations ?
                  placementResult.recommendations : [];

         this._faults = placementResult && placementResult.drsFault ?
               placementResult.drsFault.faultsByVm : [];

         this._faultsReason = placementResult && placementResult.drsFault ?
               placementResult.drsFault.reason : "";

         this._selectedRecommendation = this.getTopRecommendation(this._recommendations);
      }

      private getTopRecommendation(recommendations: any[]): any {
         if (!recommendations) {
            return undefined;
         }
         let topRec: any = undefined;
         recommendations.forEach((rec) => {
            if (!topRec) {
               topRec = rec;
            } else if (rec.rating > topRec.rating) {
               topRec = rec;
            }
         });
         return topRec;
      }
   } // class

   angular.module("com.vmware.vsphere.client.vm").service("vmXdrsRecommendationService", [
      "defaultUriSchemeUtil",
      "mutationService",
      function (
            _defaultUriSchemeUtil: any,
            _mutationService: any,
      ) {

         defaultUriSchemeUtil = _defaultUriSchemeUtil;
         mutationService = _mutationService;

         return VmXdrsRecommendationService;
      }
   ]);

} // module
