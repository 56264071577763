(function() {
   'use strict';
   angular.module('com.vmware.vsphere.client.cluster').service('moveResourcePoolToService',
         MoveResourcePoolToService);

   MoveResourcePoolToService.$inject = [ 'mutationService', 'defaultUriSchemeUtil',
      'authorizationService', '$q', 'managedEntityConstants', 'i18nService',
      'dataService', 'vuiConstants'];

   function MoveResourcePoolToService(
         mutationService,
         defaultUriSchemeUtil,
         authorizationService,
         $q,
         managedEntityConstants,
         i18nService,
         dataService,
         vuiConstants) {

      function moveResourcePoolTo(entityToMoveRpTo, rpIds) {
         var rpMors = _.map(rpIds, function(rpId) {
            return defaultUriSchemeUtil.getManagedObjectReference(rpId);
         });
         var moveSpec = {
            entities: rpMors
         };

         mutationService.apply(
               entityToMoveRpTo,
               'com.vmware.vsphere.client.h5.rp.spec.MoveResourcePoolSpec',
               moveSpec);
      }

      function validateMoveResourcePoolToTarget(entityToMoveRpTo, rpIds) {
         var rpMors = _.map(rpIds, function(rpId) {
            return defaultUriSchemeUtil.getManagedObjectReference(rpId);
         });
         var moveSpec = {
            entities: rpMors
         };

         return mutationService.validate(
               entityToMoveRpTo,
               'com.vmware.vsphere.client.h5.rp.spec.MoveResourcePoolSpec',
               moveSpec);
      }

      function checkPrivileges(itemToCheckIds, targetToCheckId) {
         var targetType = defaultUriSchemeUtil.getEntityType(targetToCheckId);
         var promises = [];

         if (targetType === managedEntityConstants.V_APP) {
            promises.push(authorizationService
                  .checkPrivilege(targetToCheckId, 'VApp.AssignResourcePool'));
         } else {
            itemToCheckIds.push(targetToCheckId);
         }
         promises.push(authorizationService
               .checkPrivilegesForMultipleObjects(_.uniq(itemToCheckIds), ['Resource.MovePool']));

         return $q.all(promises).then(function(result) {
                  var objectsWithNoPrivilegeIds = [];
                  var resourceMovePoolPrivilegeResult;
                  if (result.length > 1) {
                     if (!result[0]) {
                        objectsWithNoPrivilegeIds.push(targetToCheckId);
                     }
                     resourceMovePoolPrivilegeResult = result[1];
                  } else {
                     resourceMovePoolPrivilegeResult = result[0];
                  }
                  _.each(resourceMovePoolPrivilegeResult, function(privileges, id){
                           if (!privileges['Resource.MovePool']) {
                              objectsWithNoPrivilegeIds.push(id);
                           }
                  });
                  return objectsWithNoPrivilegeIds;
               });
      }

      function getPrivilegesErrorMessage(noPrivilegesIds, entityToMoveRpTo) {
         var message;
         if (_.contains(noPrivilegesIds, entityToMoveRpTo)) {
            if (noPrivilegesIds.length === 1) {
               message = i18nService.getString('RpUi', 'moveTo.noPermissionsOnTarget');
            } else {
               message = i18nService.getString('RpUi', 'moveTo.noPermissions');
            }
         } else {
            message = i18nService.getString('RpUi', 'moveTo.noPermissionsOnSource');
         }

         return message;
      }

      function moveRpToRootRp(parent, rpIds, scope) {
         dataService.getProperties(parent, ['resourcePool'])
               .then(function(data) {
                  if (data) {
                     moveRpAndCloseModal(
                           defaultUriSchemeUtil.getVsphereObjectId(data['resourcePool']),
                           rpIds,
                           scope);
                  } else {
                     scope.modalOptions.alerts = [{
                        type: vuiConstants.validationBanner.type.ERROR,
                        text: i18nService.getString('RpUi', 'moveTo.target.error')
                     }];
                     scope.modalOptions.isAlertClosed = false;
                  }
               });
      }

      function moveRpAndCloseModal(entityToMoveToId, entityToMoveIds, scope) {
            moveResourcePoolTo(entityToMoveToId, entityToMoveIds);
            scope.closeModal();
      }
      function isHostOrCluster(entityToMoveRpTo) {
         var targetEntityType = defaultUriSchemeUtil.getEntityType(
               entityToMoveRpTo
         );
        return targetEntityType === managedEntityConstants.HOST
              || targetEntityType === managedEntityConstants.CLUSTER;
   }

      function moveResourcePool(entityToMoveRpTo, rpIds, scope) {
            if (isHostOrCluster(entityToMoveRpTo)) {
               // move to host's or cluster's hidden resource pool
               moveRpToRootRp(
                     entityToMoveRpTo,
                     rpIds,
                     scope
               );
            } else {
               // move to resource pool or vApp
               moveRpAndCloseModal(
                     entityToMoveRpTo,
                     rpIds,
                     scope
               );
            }

         }

      return {
         moveResourcePool: moveResourcePool,
         validateMoveResourcePoolToTarget: validateMoveResourcePoolToTarget,
         checkPrivileges: checkPrivileges,
         getPrivilegesErrorMessage: getPrivilegesErrorMessage
      };
   }
})();
