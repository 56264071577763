/* Copyright 2015 VMware, Inc. All rights reserved. -- VMware Confidential */
/*
 * This js file has to be loaded first
 */
h5 = h5 || {};
h5.cluster = {
   angularModule: angular.module('com.vmware.vsphere.client.cluster',
         ['com.vmware.platform.ui', 'com.vmware.vsphere.client.commonModule',
               'com.vmware.vsphere.client.licenseLibUi'])
};

/*
 * Registers cluster-ui actions.
 */
angular.module('com.vmware.vsphere.client.cluster')
      .run([
         'mutationService', 'i18nService', '$rootScope',
         'vuiConstants', 'haDialogService', 'clarityModalService', 'clusterGroupsService',
         'clusterRulesService', 'clusterRulesConstants', 'alertService',
         'clusterVmOverridesService', 'clusterConfigVmOverrideService',
         'assignLicenseService', 'editDrsDialogService', 'moveResourcePoolToService', 'featureFlagsService',
         'scheduleDrsWizardService', 'quickstartRefreshService', 'moveClusterToService',
         function(mutationService, i18nService, $rootScope,
                  vuiConstants, haDialogService, clarityModalService, clusterGroupsService,
                  clusterRulesService, clusterRulesConstants, alertService,
                  clusterVmOverridesService, clusterConfigVmOverrideService,
                  assignLicenseService, editDrsDialogService, moveResourcePoolToService, featureFlagsService,
                  scheduleDrsWizardService, quickstartRefreshService, moveClusterToService) {

            var angularZone = window.Zone.current;

            if (h5 && h5.configuration && h5.configuration.featureStates &&
                  h5.configuration.featureStates.HCI === "ENABLED") {
               // Check feature switch using the global variable because
               // featureFlagsService is initialized after the run block
               // completes and will always return that the feature is disabled.
               quickstartRefreshService.init();
            }

            function showCreateClusterDialog(actionEval, availableTargets) {
               var templateUrl = featureFlagsService.HCIEnabled() ?
                     'cluster-ui/resources/cluster/views/create_cluster/CreateNewCluster.html' :
                     'cluster-ui/resources/cluster/views/create_cluster/CreateCluster.html';

               var modalOptions = {
                  title: i18nService.getString('ClusterUi', 'createClusterDialog.title'),
                  subTitle: {
                     objectId: availableTargets[0]
                  },
                  defaultButton: 'submit',
                  contentTemplate: templateUrl,
                  size: 'md',
                  modalClass: 'create-cluster-dialog',
                  dialogData: {
                     availableTargets: availableTargets,
                     objectId: availableTargets[0]
                  }
               };

               clarityModalService.openOkCancelModal(modalOptions);
            }

            function showEditClusterDialog(actionEval, availableTargets) {
               var templateUrl = 'cluster-ui/resources/cluster/views/edit_cluster/EditCluster.html';

               var modalOptions = {
                  title: i18nService.getString('ClusterUi', 'editClusterDialog.title'),
                  subTitle: {
                     objectId: availableTargets[0]
                  },
                  defaultButton: 'submit',
                  contentTemplate: templateUrl,
                  size: 'md',
                  modalClass: 'create-cluster-dialog',
                  dialogData: {
                     availableTargets: availableTargets,
                     objectId: availableTargets[0]
                  }
               };

               angularZone.run(function () {
                  clarityModalService.openOkCancelModal(modalOptions);
               });
            }

            function showEditDrsDialog(actionEval, availableTargets, context) {
               editDrsDialogService.show(availableTargets[0], context && context.focusTarget);
            }

            function showScheduleDrsWizard(actionEval, availableTargets, context) {
               scheduleDrsWizardService.show(actionEval, availableTargets[0], context && context.focusTarget);
            }

            function showEditHaDialog(actionEval, availableTargets, context) {
               haDialogService.show(availableTargets[0], context && context.focusTarget);
            }

            function showEditProctiveHaDialog(actionEval, availableTargets, context) {
               var modalOptions = {
                  title: i18nService.getString('ClusterUi', 'editDialog.proHa.title'),
                  subTitle: {
                     objectId: availableTargets[0]
                  },
                  defaultButton: 'submit',
                  submitButtonTitle: i18nService.getString(
                        'ClusterUi',
                        'editDialog.proHa.saveButton.title'),
                  contentTemplate:
                        'cluster-ui/resources/cluster/views/settings/services/proactiveHa/editProactiveHaDialog.html',
                  size: 'lg',
                  modalClass: 'cluster-edit-proactive-ha-dialog-modal',
                  focusTarget: context && context.focusTarget,
                  dialogData: {
                     availableTargets: availableTargets,
                     objectId: availableTargets[0]
                  }
               };

               clarityModalService.openOkCancelModal(modalOptions);
            }

            function showEditEvcModeDialog(actionEval, availableTargets) {
               var modalOptions = {
                  title: i18nService.getString('ClusterUi', 'evc.config.formTitle'),
                  subTitle: {
                     objectId: availableTargets[0]
                  },
                  defaultButton: 'submit',
                  contentTemplate: 'cluster-ui/resources/cluster/views/settings/configuration/EditEvcDialog.html',
                  size: 'lg',
                  modalClass: 'edit-cluster-evc-dialog',
                  dialogData: {
                     availableTargets: availableTargets,
                     objectId: availableTargets[0]
                  }
               };

               clarityModalService.openOkCancelModal(modalOptions);
            }

            function navigateToClusterSettings(actionEval, availableTargets) {
               $rootScope._navigateToViewAndObject('vsphere.core.cluster.manage', availableTargets[0]);
            }

            function showEditDefaultVmCompatibilityDialog(actionEval, availableTargets, context) {
               var modalOptions = {
                  title: i18nService.getString('HostUi', 'defaultVmCompatibilityDialog.title'),
                  subTitle: {
                     objectId: availableTargets[0]
                  },
                  defaultButton: 'submit',
                  contentTemplate: 'host-ui/resources/host/views/settings/EditDefaultVmCompatibility.html',
                  focusTarget: context && context.focusTarget,
                  dialogData: {
                     availableTargets: availableTargets
                  }
               };

               clarityModalService.openOkCancelModal(modalOptions);
            }

            function showEditSwapfileLocationDialog(actionEval, availableTargets, context) {
               var modalOptions = {
                  title: i18nService.getString('ClusterUi', 'editClusterSettingsDialog.title'),
                  subTitle: {
                     objectId: availableTargets[0]
                  },
                  defaultButton: 'submit',
                  contentTemplate: 'cluster-ui/resources/cluster/views/settings/configuration/EditSwapfileLocationDialog.html',
                  focusTarget: context && context.focusTarget,
                  dialogData: {
                     availableTargets: availableTargets,
                     objectId: availableTargets[0]
                  }
               };

               clarityModalService.openOkCancelModal(modalOptions);
            }

            function showCreateResourcePoolDialog(actionEval, availableTargets, context) {
               var modalOptions = {
                  title: i18nService.getString('RpUi', 'actionCreateRPDefaultName'),
                  subTitle: {
                     objectId: availableTargets[0]
                  },
                  defaultButton: 'submit',
                  contentTemplate: 'cluster-ui/resources/cluster/views/create_resource_pool/createResourcePool.html',
                  focusTarget: context && context.focusTarget,
                  dialogData: {
                     availableTargets: availableTargets,
                     objectId: availableTargets[0]
                  },
                  modalClass: 'cluster-resource-pool-modal'
               };

               clarityModalService.openOkCancelModal(modalOptions);
            }

            function showEditResourcePoolDialog(actionEval, availableTargets) {
               var modalOptions = {
                  title: i18nService.getString('RpUi', 'settings.edit.title'),
                  subTitle: {
                     objectId: availableTargets[0]
                  },
                  defaultButton: 'submit',
                  contentTemplate: 'cluster-ui/resources/cluster/views/edit_resource_pool/editResourcePool.html',
                  dialogData: {
                     objectId: availableTargets[0]
                  },
                  modalClass: 'edit-cluster-resource-pool-modal'
               };
               if (actionEval && actionEval.additionalData && actionEval.additionalData.isScheduledTask) {
                  modalOptions.contentTemplate =
                     'cluster-ui/resources/cluster/views/edit_resource_pool_scheduled_task/editResourcePoolScheduledTaskDialog.html';
                  modalOptions.actionEval = actionEval;
                  if ( modalOptions.actionEval.additionalData.schedulingData.scheduleTaskOperationMode === 0) {
                     modalOptions.title =  i18nService.getString('ClusterUi', 'st.new.scheduled.task',
                        i18nService.getString("ScheduledOpsUi", "Wizard.scheduleTask.ResourceConfigForm.title"));
                  } else {
                     modalOptions.title =  i18nService.getString('ClusterUi', 'st.edit.scheduled.task',
                        i18nService.getString("ScheduledOpsUi", "Wizard.scheduleTask.ResourceConfigForm.title"));
                  }
               }
               clarityModalService.openOkCancelModal(modalOptions);
            }

            function showMoveToResourcePoolDialog( actionEval, availableTargets) {
               if (!availableTargets || !availableTargets.length) {
                  return;
               }

               var modalOptions = {
                  title: i18nService.getString('RpUi', 'moveTo.title'),
                  defaultButton: 'submit',
                  dialogData: {
                     objectIds: availableTargets
                  },
                  contentTemplate: 'cluster-ui/resources/cluster/views/move_to_resource_pool/moveResourcePoolToDialog.html'
               };

               clarityModalService.openOkCancelModal(modalOptions);
            }

            function showAddGroupDialog(actionEval, availableTargets, context) {
               clarityModalService.openOkCancelModal({
                  title: i18nService.getString('ClusterUi', 'groups.config.title.create'),
                  subTitle: {
                     objectId: availableTargets[0]
                  },
                  defaultButton: 'submit',
                  modalClass: 'cluster-add-vm-host-group-modal',
                  contentTemplate: 'cluster-ui/resources/cluster/views/settings/configuration/groups/AddClusterGroup.html',
                  focusTarget: context && context.focusTarget
               });
            }

            function removeGroup(actionEval, availableTargets, context) {
               clusterGroupsService.removeGroup(availableTargets[0], context.selectedGroup);
            }

            function showAddGroupMembersDialog(actionEval, availableTargets, context) {
               var dialogData = {
                  objects: context.memberObjects,
                  objectType: context.memberObjectsType,
                  objectId: availableTargets[0],
                  onSubmit: function() {
                     return context.addMembersCallback(dialogData.selectedItems);
                  }
               };

               clarityModalService.openOkCancelModal({
                  title: i18nService.getString(
                        'ClusterUi', 'groups.members.dialog.title'),
                  subTitle: {
                     objectId: availableTargets[0]
                  },
                  dialogData: dialogData,
                  defaultButton: 'submit',
                  size: 'lg',
                  modalClass: 'cluster-add-group-members-selector-dialog',
                  contentTemplate: 'cluster-ui/resources/cluster/views/settings/configuration/groups/addGroupMembersSelectorDialog.html',
                  focusTarget: context && context.focusTarget
               });
            }

            function removeGroupMembers(actionEval, availableTargets, context) {
               context.removeMembersCallback();
            }

            function showAddRuleDialog(actionEval, availableTargets) {
               clarityModalService.openOkCancelModal({
                  title: i18nService.getString('ClusterUi', 'rules.config.title.create'),
                  subTitle: {
                     objectId: availableTargets[0]
                  },
                  defaultButton: 'submit',
                  modalClass: 'cluster-add-vm-host-rule-modal',
                  contentTemplate: 'cluster-ui/resources/cluster/views/settings/rules/addClusterRule.html'
               });
            }

            function removeRule(actionEval, availableTargets, context) {
               clusterRulesService.deleteRule(availableTargets[0], context.selectedRule);
            }

            function showEditRuleDialog(actionEval, availableTargets, context) {
               clarityModalService.openOkCancelModal({
                  title: i18nService.getString('ClusterUi', 'rules.config.title.edit'),
                  subTitle: {
                     objectId: availableTargets[0]
                  },
                  dialogData: {
                     rule: context.selectedRule
                  },
                  defaultButton: 'submit',
                  modalClass: 'cluster-add-vm-host-rule-modal',
                  contentTemplate: 'cluster-ui/resources/cluster/views/settings/rules/addClusterRule.html'
               });
            }

            function showAddRuleMemberDialog(actionEval, availableTargets, context) {
               var dialogOptions = {
                  title: i18nService.getString(
                        'ClusterUi', 'rules.add.affinity.rule.dialog.title'),
                  subTitle: context.dialogSubTitleObject,
                  size: 'lg',
                  defaultButton: 'submit',
                  modalClass: 'cluster-add-affinity-rule-member-dialog',
                  contentTemplate: 'cluster-ui/resources/cluster/views/settings/rules/addClusterRuleMember.html',
                  dialogData: {
                     selectedVms: context.selectedMembers,
                     objectId: availableTargets[0]
                  },
                  onSubmit: function() {
                     return context.submitCallback(dialogOptions);
                  }
               };

               clarityModalService.openOkCancelModal(dialogOptions);
            }

            function showRemoveRuleMemberConfirmationDialog(actionEval, availableTargets, context) {
               var isExistingRule = context.isExistingRule;
               var ruleInfo = context.rule;
               var membersToRemove = context.membersToRemove;
               var confirmCallback = context.confirmCallback;

               if (isExistingRule && ruleInfo.members.length - membersToRemove.length <
                     clusterRulesConstants.affinityRuleMinimumMembers) {
                  // Cannot leave less than 2 members in rule
                  alertService.error(
                        i18nService.getString('ClusterUi',
                              'rules.members.action.remove.confirmationTitle'),
                        i18nService.getString('ClusterUi',
                              'rules.members.action.remove.error.cannotDeleteAllRuleMembers'));
               } else {
                  var confirmProperties = {
                     title: i18nService.getString('ClusterUi',
                           'rules.members.action.remove.confirmationTitle'),
                     message: i18nService.getString('ClusterUi',
                           'rules.members.action.remove.confirmationTextOne'),
                     submit: function() {
                        return confirmCallback();
                     }
                  };

                  clarityModalService.openConfirmationModal(confirmProperties);
               }
            }

            function showDetailsRuleMembersDialog(actionEval, availableTargets, context) {
               clarityModalService.openModal(
                     angular.merge(
                           {},
                           actionEval,
                           {
                              action: {
                                 label: i18nService.getString('ClusterUi', 'rules.members.detailsPopupTitle')
                              }
                           }),
                     availableTargets,
                     {
                        rule: context.selectedRule,
                        member: context.selectedMember
                     },
                     'cluster-ui/resources/cluster/views/settings/rules/viewDetailsClusterRuleMember.html'
               );
            }

            function showEditHostOptionsDialog(actionEval, availableTargets, context) {
               clarityModalService.openOkCancelModal({
                  title: i18nService.getString(
                        'ClusterUi',
                        'hostOptions.edit.dialogTitle'),
                  subTitle: {
                     objectId: context.selectedHost
                  },
                  dialogData: {
                     selectedHost: context.selectedHost,
                     clusterRef: context.clusterRef
                  },
                  size: 'lg',
                  defaultButton: 'submit',
                  modalClass: 'edit-host-options-dialog',
                  contentTemplate:
                        'cluster-ui/resources/cluster/views/settings/hostOptions/editHostOptions.html'
               });
            }

            function deleteVmOverride(actionEval, availableTargets, context) {
               clusterVmOverridesService.deleteVmOverride(
                     availableTargets[0], context.selectedOverrides,
                     context && context.focusTarget);
            }

            function showAddVmOverrideDialog(actionEval, availableTargets, context) {
               clusterConfigVmOverrideService.openAddVmOverrideWizard(availableTargets[0], context && context.focusTarget);
            }

            function showEditVmOverrideDialog(actionEval, availableTargets, context) {
               var vmName = context.selectedOverrides.length === 1 ?
                     context.selectedOverrides[0].vm.name :
                     i18nService.getString('ClusterUi',
                           'vm.overrides.config.edit.dialog.title.vms',
                           context.selectedOverrides.length);

               clarityModalService.openOkCancelModal({
                  title: i18nService.getString('ClusterUi',
                        'vm.overrides.config.edit.dialog.title', vmName),
                  subTitle: {
                     objectId: availableTargets[0]
                  },
                  dialogData: {
                     selectedOverrides: context.selectedOverrides,
                     objectId: availableTargets[0]
                  },
                  size: 'lg',
                  defaultButton: 'submit',
                  modalClass: 'cluster-edit-vm-override-modal',
                  contentTemplate:
                        'cluster-ui/resources/cluster/views/settings/configuration/overrides/editVmOverrideDialog.html'
               });
            }

            function openRestoreRpTreeDialog(actionEval, availableTargets, context) {
               var modalOptions = {
                  title: i18nService.getString('ClusterUi', 'restore.rp.tree.dialog.title'),
                  subTitle: {
                     objectId: availableTargets[0]
                  },
                  defaultButton: 'submit',
                  size: 'lg',
                  contentTemplate:
                        'cluster-ui/resources/cluster/views/restore_resource_pool_tree/restoreResourcePoolTreeDialog.html',
                  focusTarget: context && context.focusTarget,
                  objectId: availableTargets[0]
               };

               clarityModalService.openOkCancelModal(modalOptions);
            }

            function showAssignLicenseForm(actionEval, availableTargets) {
               if (_.isEmpty(availableTargets)) {
                  return;
               }

               assignLicenseService.showAssignLicenseForm(availableTargets);
            }

            function showMoveToClusterDialog( actionEval, availableTargets) {
               moveClusterToService.showMoveCluster(availableTargets);
            }

            function deleteClusterAndResourcePool(actionEval, availableTargets) {
               if (availableTargets.length === 1) {
                  mutationService.remove(availableTargets[0]);
               } else if (availableTargets.length > 1) {
                  mutationService.removeOnMultiEntity(availableTargets);
               }
            }

            function moveClusterToDrag (actionEval, availableTargets, destinationTarget) {
               if (!availableTargets || !availableTargets.length || !destinationTarget) {
                  return;
               }

               moveClusterToService.moveCluster(destinationTarget.dropTarget, availableTargets);
            }


            h5.actions['vsphere.core.resourcePool.actions.createResourcePool'] = showCreateResourcePoolDialog;
            h5.actions['vsphere.core.resourcePool.actions.editResourcePool'] = showEditResourcePoolDialog;
            h5.actions['vsphere.core.resourcePool.actions.moveTo'] = showMoveToResourcePoolDialog;
            h5.actions['vsphere.core.cluster.actions.create'] = showCreateClusterDialog;
            h5.actions['vsphere.core.cluster.actions.edit'] = showEditClusterDialog;
            h5.actions['vsphere.core.cluster.actions.editDrs'] = showEditDrsDialog;
            h5.actions['vsphere.core.cluster.actions.scheduleDrs'] = showScheduleDrsWizard;
            h5.actions['vsphere.core.cluster.actions.editHa'] = showEditHaDialog;
            h5.actions['vsphere.core.cluster.actions.editProactiveHa'] = showEditProctiveHaDialog;
            h5.actions['vsphere.core.cluster.actions.editEvcMode'] = showEditEvcModeDialog;
            h5.actions['vsphere.core.cluster.actions.settings'] = navigateToClusterSettings;
            h5.actions['vsphere.core.cluster.actions.editDefaultHwVersionAction'] =
                  showEditDefaultVmCompatibilityDialog;
            h5.actions['vsphere.core.cluster.actions.editSwapfileLocationAction'] = showEditSwapfileLocationDialog;
            h5.actions["vsphere.core.cluster.assignLicenseKeyAction"] = showAssignLicenseForm;

               h5.actions['vsphere.core.cluster.actions.group.add'] = showAddGroupDialog;
            h5.actions['vsphere.core.cluster.actions.group.remove'] = removeGroup;
            h5.actions['vsphere.core.cluster.actions.group.members.add'] = showAddGroupMembersDialog;
            h5.actions['vsphere.core.cluster.actions.group.members.remove'] = removeGroupMembers;

            h5.actions['vsphere.core.cluster.actions.rule.add'] = showAddRuleDialog;
            h5.actions['vsphere.core.cluster.actions.rule.remove'] = removeRule;
            h5.actions['vsphere.core.cluster.actions.rule.edit'] = showEditRuleDialog;
            h5.actions['vsphere.core.cluster.actions.rule.members.add'] = showAddRuleMemberDialog;
            h5.actions['vsphere.core.cluster.actions.rule.members.remove'] = showRemoveRuleMemberConfirmationDialog;
            h5.actions['vsphere.core.cluster.actions.rule.members.details'] = showDetailsRuleMembersDialog;

            h5.actions['vsphere.core.cluster.actions.hostOptions.edit'] = showEditHostOptionsDialog;

            h5.actions['vsphere.core.cluster.actions.vmOverride.delete'] = deleteVmOverride;
            h5.actions['vsphere.core.cluster.actions.vmOverride.add'] = showAddVmOverrideDialog;
            h5.actions['vsphere.core.cluster.actions.vmOverride.edit'] = showEditVmOverrideDialog;

            h5.actions['vsphere.core.cluster.actions.restoreRpTree'] = openRestoreRpTreeDialog;
            h5.actions['vsphere.core.rp.actions.delete'] = deleteClusterAndResourcePool;
            h5.actions['vsphere.core.cluster.actions.delete'] = deleteClusterAndResourcePool;
            h5.actions['vsphere.core.cluster.actions.moveTo'] = showMoveToClusterDialog;
            h5.actions["vsphere.core.cluster.actions.moveClusterToDrag"] = moveClusterToDrag;
         }]);
