(function() {
   'use strict';
   angular.module('com.vmware.vsphere.client.cluster')
      .controller('ClusterResourcesController', ClusterResourcesController);

   ClusterResourcesController.$inject = ['$scope', '$controller', 'i18nService',
         'featureFlagsService'];

   function ClusterResourcesController($scope, $controller, i18nService,
         featureFlagsService) {
      var self = this;
      angular.extend(self, $controller('GenericPortletController', { $scope: $scope }));

      self.liveRefreshProperties = ["summary.currentEVCModeKey", "host"];
      self.getString = getString;
      self.isPmemEnabled = isPmemEnabled();

      function getString(key) {
         return i18nService.getString('ClusterUi', key);
      }

      function isPmemEnabled() {
         return (typeof featureFlagsService.PMemEnabled === "function") ?
               featureFlagsService.PMemEnabled() : false;
      }
   }
})();