namespace h5_vm {
   import IController = angular.IComponentController;

   class CloneVappBasicInformationController implements IController {

      static $inject: string[] = [ "$scope" ];
      public basicInformationData: any;

      constructor(private $scope: any) {
         this.basicInformationData = this.getBasicInformationData();
         if (!this.$scope.cloneVmBasicInformationPageModel.basicInformation){
            this.$scope.cloneVmBasicInformationPageModel.basicInformation = this.basicInformationData.params;
         } else {
            // Reuse existing model when navigated back in the wizard.
            this.basicInformationData.params = this.$scope.cloneVmBasicInformationPageModel.basicInformation;
         }

      }

      private getBasicInformationData(): any {
         const vmName: string = this.$scope.wizardViewData.getSourceObjectName();
         const vmId: string = this.$scope.wizardViewData.getSourceObjectId();
         return {
            params: {
               templateType: TemplateType.OVF_TEMPLATE,
               name: vmName,
               notes: "",
               vmId: vmId,
               cloneOvfType: CloneType.NEW_TEMPLATE,
               valid: true,
               isPrivilegeError: false
            },
            isVMTXPhase2: false
         };
      }

       public clonePagesChange(cloneType: any) {
           this.$scope.vuiWizard.validationBanner.messages = [];
           const isVMTX: boolean = this.basicInformationData.params.templateType === TemplateType.VM_TEMPLATE;
           const isUpdate: boolean = this.basicInformationData.params.cloneOvfType !== CloneType.NEW_TEMPLATE;
           this.$scope.cloneVmBasicInformationPageModel.changePages(isVMTX, isUpdate);
       }
   }

   angular.module("com.vmware.vsphere.client.vm")
         .controller("cloneVappBasicInformationController", CloneVappBasicInformationController);
}
