(function() {
   'use strict';

   /**
    * @name com.vmware.vsphere.client.host:hardwareHealthConstants
    *
    * @description
    *    Represents Hardware Health Status enum values.
    */
   angular
         .module('com.vmware.vsphere.client.host')
         .constant('hardwareHealthConstants', {
            status: {
               NORMAL: 'normal',
               WARNING: 'warning',
               ALERT: 'alert',
               UNKNOWN: 'unknown'
            },
            HARDWARE_STATUS_PROPERTY: 'hardwareStatus',
            HARDWARE_STATUS_65_PROPERTY: 'hardwareHealthStatus',
            HARDWARE_STATUS_XML_PROPERTY: 'hardwareStatusXml',
            ACTION: {
               REFRESH_DATA: 'refresh_data',
               RESET_SENSORS: 'reset_sensors',
               RESET_EVENTS: 'reset_events',
               EXPORT_XML: 'export_xml'
            },
            OPERATION: {
               REFRESH_DATA: "REFRESH_DATA",
               RESET_SENSORS: "RESET_SENSORS",
               RESET_EVENTS_LOG: "RESET_EVENTS_LOG"
            },
            SENSOR_ICONS: {
               processor: 'vx-icon-CPU',
               temperature: 'vx-icon-temp',
               storage: 'vx-icon-storage',
               battery: 'vx-icon-battery',
               fan: 'vx-icon-fan',
               memory: 'vx-icon-memory',
               pci: 'vx-icon-pci',
               voltage: 'vx-icon-voltage',
               power: 'vx-icon-power',
               network: 'vx-icon-network',
               software: 'vx-icon-software',
               bmc: 'vx-icon-system_board',
               sel: 'vx-icon-sel',
               cable: 'vx-icon-cable',
               watchdog: 'vx-icon-watchdog'
            }
         });
})();
