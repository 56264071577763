/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace h5_vm {

   import GuestOsDescriptor = com.vmware.vim.binding.vim.vm.GuestOsDescriptor;
   import VirtualNVDIMM = com.vmware.vim.binding.vim.vm.device.VirtualNVDIMM;
   import VmConfigContext = com.vmware.vsphere.client.vm.config.VmConfigContext;

   export class PmemCapacityService {

      static $inject: string[] = [
         "pmemUtilService"
      ];

      constructor(private pmemUtilService: PmemUtilService) {
      }

      getMaxCapacityForNvdimm(vmConfigContext: VmConfigContext,
            guestOs: GuestOsDescriptor,
            device: any,
            virtualMachineDevices: any): number {
         let crMax: number = this.getComputeResourceAvailablePmemCapacity(
               vmConfigContext, device, virtualMachineDevices);

         let nvdimmOption: any = this.pmemUtilService.findOption(
               vmConfigContext.environment, PmemUtilService.NVDIMM);
         let deviceMax: number = nvdimmOption.capacityInMB.max;

         // RAM + NVM must not exceed RAM maximum
         let vmMax: number = this.getRemainingOsMemory(vmConfigContext,
               guestOs, virtualMachineDevices, device);

         let guestOsMax: number = this.getRemainingPmemCapacitySupportedByGuestOs(
               guestOs, virtualMachineDevices, device);
         let result: number = Math.min(crMax, deviceMax, vmMax, guestOsMax);
         // In case any negative number creeps in, return 0 as minimum
         return result > 0 ? result : 0;
      }

      getMaxCapacityForPmemDisk(vmConfigContext: VmConfigContext,
            device: any,
            virtualMachineDevices: any): number {
         return this.getComputeResourceAvailablePmemCapacity(
               vmConfigContext, device, virtualMachineDevices);
      }

      getIsNvmAndRamExceedingGuestOsLimits(vmConfigContext: VmConfigContext,
            guestOs: GuestOsDescriptor,
            virtualMachineDevices: any): boolean {
         return this.getRemainingOsMemory(
                     vmConfigContext, guestOs, virtualMachineDevices) < 0;
      }

      getTotalNvdimmMemory(virtualMachineDevices: any,
                           skippedNewDevice?: any): number {
         let result: number = 0;
         let allNvdimmDevices: Array<any> =
               virtualMachineDevices.devicesOfType(PmemUtilService.NVDIMM);
         for (let nvdimmDevice of allNvdimmDevices) {
            if (skippedNewDevice
                  && (skippedNewDevice.getKey() === nvdimmDevice.getKey())) {
               continue;
            }
            if (nvdimmDevice.isMarkedForRemoval()) {
               continue;
            }
            result += nvdimmDevice.getCapacityInMB();
         }
         return result;
      }

      private getComputeResourceAvailablePmemCapacity(vmConfigContext: VmConfigContext,
            device: any,
            virtualMachineDevices: any): number {
         let crMax: number = this.pmemUtilService.getAvailablePmemCapacity(vmConfigContext);
         let totalNewNvdimmCapacityUsed: number =
               this.getTotalNewPmemCapacity(virtualMachineDevices, device);
         crMax += device.getOriginalCapacityInMB();
         let result: number = crMax - totalNewNvdimmCapacityUsed;
         // Have 0 as safeguard against negative numbers
         return result > 0 ? result : 0;
      }

      private getRemainingOsMemory(vmConfigContext: VmConfigContext,
            guestOs: GuestOsDescriptor,
            virtualMachineDevices: any,
            skippedDevice?: any): number {
         let totalNvdimmPlusRam: number = this.getTotalNvdimmAndRamMemory(
               vmConfigContext, virtualMachineDevices, skippedDevice);
         return guestOs.supportedMaxMemMB - totalNvdimmPlusRam;
      }

      private getRemainingPmemCapacitySupportedByGuestOs(guestOs: GuestOsDescriptor,
            virtualMachineDevices: any,
            skippedDevice?: any): number {
         return this.pmemUtilService.getSupportedPmemByGuestOs(guestOs)
               - this.getTotalNvdimmMemory(virtualMachineDevices, skippedDevice);
      }

      private getTotalNewPmemCapacity(virtualMachineDevices: any,
            skippedDevice?: any): number {
         let result: number = 0;

         result += this.getNvdimmContributionToPmemCapacity(
               virtualMachineDevices, skippedDevice);
         result += this.getPmemDiskContributionToPmemCapacity(
               virtualMachineDevices, skippedDevice);
         return result;
      }

      private getPmemDiskContributionToPmemCapacity(
            virtualMachineDevices: any, skippedDevice: any): number {
         let result: number = 0;
         let allDiskDevices: Array<any> =
               virtualMachineDevices.devicesOfType("VirtualDisk");
         let allNewOrChangedPmemDisks: Array<any> = _.filter(allDiskDevices, (disk) => {
            // Existing Pmem Disks are already factored in by the backend
            if (!disk.isNew() && !disk.hasChanged()) {
               return false;
            }
            if (!disk.isPmemDisk() || disk.isMarkedForRemoval()) {
               return false;
            }
            if (skippedDevice
                  && (skippedDevice.getKey() === disk.getKey())) {
               return false;
            }
            return true;
         });
         for (let pmemDisk of allNewOrChangedPmemDisks) {
            result += this.getDeviceContributionToPmemCapacity(pmemDisk);
         }
         return result;
      }

      private getNvdimmContributionToPmemCapacity(
            virtualMachineDevices: any, skippedDevice: any): number {
         let result: number = 0;
         let allNvdimmDevices: Array<any> =
               virtualMachineDevices.devicesOfType(PmemUtilService.NVDIMM);
         let filteredNvdimmDevices: Array<any> = _.filter(allNvdimmDevices, (nvdimm) => {
            // Existing Nvdimms are already factored in by the backend
            if (!nvdimm.isNew() && !nvdimm.hasChanged()) {
               return false;
            }
            if (skippedDevice
                  && (skippedDevice.getKey() === nvdimm.getKey())) {
               return false;
            }
            if (nvdimm.isMarkedForRemoval()) {
               return false;
            }
            return true;
         });
         for (let nvdimmDevice of filteredNvdimmDevices) {
            result += this.getDeviceContributionToPmemCapacity(nvdimmDevice);
         }
         return result;
      }

      private getTotalNvdimmAndRamMemory(vmConfigContext: VmConfigContext,
            virtualMachineDevices: any,
            skippedDevice?: any): number {
         return this.getTotalNvdimmMemory(virtualMachineDevices, skippedDevice) +
               vmConfigContext.config.hardware.memoryMB;
      }

      private getDeviceContributionToPmemCapacity(device: any): number {
         return device.getCapacityDiff();
      }

   }

   angular.module("com.vmware.vsphere.client.vm")
         .service("pmemCapacityService", PmemCapacityService);
}
