namespace h5_vm {
    import IController = angular.IComponentController;
    import TemplateType = h5_vm.TemplateType;
    import CloneType = h5_vm.CloneType;

    class CloneVmReviewController implements IController {

        static $inject: string[] = [ "$scope", "i18nService" ];

        public i18n: Function;
        public basicInformationData: any;
        public vmtxPhase2Enabled: any;
        public cloneAsVMTX: boolean;
        public cloneOvfType: boolean;
        public locationData: any;
        public libraryItemData: any;
        public computeResourceName: any;
        public computeResourceType: any;
        public storageData: StorageDetailsView;

        constructor(
                private $scope: any,
                private i18nService: any) {

            this.i18n = (key: string, bundle: string = "ProvisioningUiLib", ...args: any[]) => i18nService.getString(bundle, key, ...args);
            this.basicInformationData = $scope.cloneVmBasicInformationPageModel.basicInformation;
            this.vmtxPhase2Enabled = $scope.cloneVmBasicInformationPageModel.isVMTXPhase2Enabled;
            this.cloneAsVMTX = this.basicInformationData.templateType=== TemplateType.VM_TEMPLATE;
            this.cloneOvfType = this.basicInformationData.cloneOvfType !== CloneType.NEW_TEMPLATE;


            if (this.vmtxPhase2Enabled && this.cloneAsVMTX ){
                let crInfo:any = $scope.cloneVmReviewPageModel.getComputeResourceInfo();
                this.computeResourceName = crInfo.name;
                this.computeResourceType = crInfo.label;
            }
            if (!this.cloneOvfType || this.cloneAsVMTX ){
                this.locationData = $scope.cloneVmLocationPageModel.contentLibrary;
            }

            if (this.cloneOvfType && !this.cloneAsVMTX ){
                this.libraryItemData = $scope.cloneVmLibraryItemPageModel.contentLibraryItem;
            }

            if (this.cloneAsVMTX ){
                let isAdvancedStorageMode: boolean = $scope.datastorePageModel.form.isAdvancedStorageMode;
                this.storageData = isAdvancedStorageMode ? this.getAdvancedStorageDetails() : this.getSimpleStorageDetails();
            }
        }

        private getSimpleStorageDetails(): StorageDetailsView {
            let storageDetailsView: StorageDetailsView = new StorageDetailsView();
            let datastorePageModelForm: any = this.$scope.datastorePageModel.form;

            storageDetailsView.vmHomeDatastore = datastorePageModelForm.selectedStorage.name;
            storageDetailsView.vmHomeStoragePolicy = datastorePageModelForm.storageProfilesData._currentVmProfileAssignment.vmHomeProfile.label;

            return storageDetailsView;
        }

        private getAdvancedStorageDetails(): StorageDetailsView {
            let storageDetailsView: StorageDetailsView = new StorageDetailsView();
            let storageConfig: any = this.$scope.datastorePageModel.form.storageSelectorState.vmStorageConfigInAdvancedMode[0];

            storageDetailsView.vmHomeDatastore = storageConfig.vmHome.storageObj.name;
            storageDetailsView.vmHomeStoragePolicy =
                  storageConfig.vmHome.storageProfile ? storageConfig.vmHome.storageProfile.label : '';
            storageDetailsView.vmDiskInfo = <any> [];

            for (let index = 0; index < storageConfig.vmDisks.length; index++) {
                let vmDisk: any = storageConfig.vmDisks[index];
                const profileLabel: string = vmDisk.storageProfile ? vmDisk.storageProfile.label : '-';
                let diskInfo: any = {
                    name: vmDisk.name,
                    datastore: this.i18n("sharesAndType", "VmUi",
                          vmDisk.storageObj ? vmDisk.storageObj.name: "",
                          profileLabel)
                };
                storageDetailsView.vmDiskInfo.push(diskInfo);
            }

            return storageDetailsView;
        }
    }

    class StorageDetailsView {
        vmHomeDatastore: string;
        vmHomeStoragePolicy: string;
        vmDiskInfo: [{
            name: string;
            datastore: string;
        }];
    }

    angular.module("com.vmware.vsphere.client.vm")
        .controller("cloneVmReviewController", CloneVmReviewController);
}
