/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */

/**
 * Manages the page states of the Manage DVPG Wizard.
 */
namespace dvs_ui {

   export class DvpgManageWizardFlowManager {

      public static $inject = [
         "pageStateManagementService",
         "dvpgManageWizardConstants",
         "vuiConstants"];

      public pages: WizardPage[];
      private pageIds: string[];

      constructor(private pageStateManagementService: any,
                  private dvpgManageWizardConstants: any,
                  private vuiConstants: any) {
         // Preserve the same order as in the watchGroup array
         this.pageIds = [
            this.dvpgManageWizardConstants.page.SECURITY_POLICY,
            this.dvpgManageWizardConstants.page.VLAN,
            this.dvpgManageWizardConstants.page.TRAFFIC_SHAPING,
            this.dvpgManageWizardConstants.page.FAILOVER_POLICY,
            this.dvpgManageWizardConstants.page.VM_VNIC_RESOURCE_POOL,
            this.dvpgManageWizardConstants.page.MONITORING,
            this.dvpgManageWizardConstants.page.MISC
         ];
      }

      public init(wizardScope: DvpgManageWizardScope): void {
         this.pages = wizardScope.wizardConfig.pages;

         this.pageStateManagementService
               .registerWizardForDefaultStateManagementStrategy(wizardScope);

         wizardScope.$watchGroup([
                  "model.policies.isSecurityPolicySelected",
                  "model.policies.isVlanPolicySelected",
                  "model.policies.isTrafficShapingPolicySelected",
                  "model.policies.isFailoverPolicySelected",
                  "model.policies.isResAllocPolicySelected",
                  "model.policies.isMonitoringPolicySelected",
                  "model.policies.isMiscPolicySelected"],
               (newValues: boolean[]) => {
                  this.updatePagesVisibility(newValues);
               });
      }

      private updatePagesVisibility(policies: boolean[]) {
         policies.forEach((isPolicySelected, index): void => {
            this.togglePageVisibility(this.pageIds[index], isPolicySelected);
         });
      }

      private togglePageVisibility(pageId: string, isVisible: boolean) {
         let page = _.findWhere(this.pages, {id: pageId});
         if (isVisible) {
            // change the state to DISABLED only if the page is currently invisible.
            if (page.state === this.vuiConstants.wizard.pageState.SKIPPED) {
               page.state = this.vuiConstants.wizard.pageState.DISABLED;
            }
         } else {
            page.state = this.vuiConstants.wizard.pageState.SKIPPED;
         }
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .service("dvpgManageWizardFlowManager", DvpgManageWizardFlowManager);
}
