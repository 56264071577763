namespace h5_vm {
   import ManagedObjectReference = com.vmware.vim.binding.vmodl.ManagedObjectReference;
   import ValidateNameSpec = com.vmware.vsphere.client.h5.vm.ValidateNameSpec;
   import NameValidationResult = com.vmware.vsphere.client.h5.vm.NameValidationResult;

   export class NameAndFolderValidatorService {
      public static $inject = ["defaultUriSchemeUtil", "managedEntityConstants", "mutationService"];
      private readonly VM_FOLDER_VALUE: string = "group-v";
      private readonly HOST_FOLDER_VALUE: string = "group-h";

      constructor(private defaultUriSchemeUtil: any,
            private managedEntityConstants: any,
            private mutationService: any) {

      }

      public validateNameAndGetLocationUids(newVmName: string, selectedTarget: any): angular.IPromise<any> {
         let vmNameSpec: ValidateNameSpec = this.buildValidateNameSpec(newVmName,
               selectedTarget);
         return this.mutationService.validateSpec(
               vmNameSpec._type, vmNameSpec)
               .then((result: any): any => {
                  let validationResult: NameValidationResult = result.result;
                  return {
                     folderUid: this.defaultUriSchemeUtil.getVsphereObjectId(validationResult.vmFolderRef),
                     datacenterUid: this.getDatacenter(selectedTarget),
                     validName: validationResult.error === null,
                     validationMessage: validationResult.error,
                     isVmFolder: this.checkVmFolder(selectedTarget)
                  };
               });
      }

      public isVmFolder(folderId: string): boolean {
         let folderRef: ManagedObjectReference = this.defaultUriSchemeUtil.getManagedObjectReference(folderId);
         let folderType: string = folderRef.type;
         let folderValue: string = folderRef.value;

         return (folderType === this.managedEntityConstants.FOLDER &&
         folderValue.indexOf(this.VM_FOLDER_VALUE) !== -1);
      }

      public isHostFolder(folderId: string): boolean {
         let folderRef: ManagedObjectReference = this.defaultUriSchemeUtil.getManagedObjectReference(folderId);
         let folderType: string = folderRef.type;
         let folderValue: string = folderRef.value;

         return (folderType === this.managedEntityConstants.FOLDER
            && folderValue.indexOf(this.HOST_FOLDER_VALUE) !== -1);
      }

      private buildValidateNameSpec(newVmName: string, selectedTarget: any): ValidateNameSpec {
         let targetParts: ManagedObjectReference = this.defaultUriSchemeUtil
               .getPartsFromVsphereObjectId(selectedTarget.uid);
         let selectedTargetRef: ManagedObjectReference = this.defaultUriSchemeUtil
               .getManagedObjectReference(selectedTarget.uid);
         let nameSpec: ValidateNameSpec = new ValidateNameSpec();
         nameSpec.name = newVmName;
         let targetType: string = targetParts ? targetParts.type : selectedTarget.type;
         if (targetType === this.managedEntityConstants.DATACENTER) {
            nameSpec.datacenterRef = selectedTargetRef;
         } else {
            nameSpec.folderRef = selectedTargetRef;
         }

         return nameSpec;
      }

      private getDatacenter(selectedTarget: any): string {
         let targetParts: ManagedObjectReference = this.defaultUriSchemeUtil
               .getPartsFromVsphereObjectId(selectedTarget.uid);
         let targetType: string = targetParts ? targetParts.type : selectedTarget.type;
         if (targetType === this.managedEntityConstants.DATACENTER) {
            return selectedTarget.uid;
         }
         let item: any = selectedTarget.item;

         while (!_.isEmpty(item) &&
         this.defaultUriSchemeUtil.getEntityType(item.objRef) === this.managedEntityConstants.FOLDER) {
            item = item.parentNode();
            if(!item){
               return "";
            }
            if (this.defaultUriSchemeUtil.getEntityType(item.objRef) === this.managedEntityConstants.DATACENTER) {
               break;
            }
         }

         return item.objRef;
      }

      private checkVmFolder(selectedTarget: any): boolean {
         let targetParts: ManagedObjectReference = this.defaultUriSchemeUtil.getPartsFromVsphereObjectId(
               selectedTarget.uid);
         let targetType: string = targetParts ? targetParts.type : selectedTarget.type;
         if (targetType === this.managedEntityConstants.DATACENTER) {
            return true;
         }

         return this.isVmFolder(selectedTarget.uid);
      }
   }

   angular.module("com.vmware.vsphere.client.vm")
         .service("nameAndFolderValidatorService", NameAndFolderValidatorService);
}
