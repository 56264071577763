namespace h5_vm {
   import ArrayUpdateSpec$Operation = com.vmware.vim.binding.vim.option.ArrayUpdateSpec$Operation;
   export class CpuIdMaskConstants {
      static readonly VENDOR_AMD: string = 'amd';
      static readonly VENDOR_OTHER: string = '';
      static readonly CHAR_NX_SHOW: string = 'H';
      static readonly CHAR_NX_HIDE: string = '0';
      static readonly CHAR_NOCHANGE: string = 'N';
      static readonly REG_USE_GOS: string = "--------------------------------";
      static readonly NX_LEVEL: number = -2147483647;
      static readonly NX_CHAR_POS: number = 11;
      static readonly CPUIDINFO_TYPE: string = 'com.vmware.vim.binding.vim.host.CpuIdInfo';
      static readonly OPERATION_ADD: ArrayUpdateSpec$Operation = "add";
      static readonly OPERATION_EDIT: ArrayUpdateSpec$Operation = "edit";
      static readonly OPERATION_REMOVE: ArrayUpdateSpec$Operation = "remove";

      static readonly NXFLAG_NOTFOUND: string = "notfound";
      static readonly NXFLAG_EXPOSED: string = "exposed";
      static readonly NXFLAG_HIDDEN: string = "hidden";
      static readonly NXFLAG_ADVANCED: string = "advanced";
      static readonly NXFLAG_NOTAPPLICABLE: string = "na";
   }
}
