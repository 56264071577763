/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace dvs_ui {

   import DvsResControlUpgradeSpec = com.vmware.vsphere.client.dvs.api.spec.DvsResControlUpgradeSpec;

   /**
    * Provides methods from creating the mutation spec sent to the backend
    * after the completion of the Upgrade NIOC wizard.
    */
   export class DvsUpgradeNiocWizardSpecFactory {

      public static $inject = ["defaultUriSchemeUtil"];

      constructor(private defaultUriSchemeUtil: any) {
      }

      /**
       * Builds the spec the Upgrade NIOC mutation operation.
       */
      public buildDvsNiocUpgradeSpec(
            model: DvsUpgradeNiocWizardModel): DvsResControlUpgradeSpec {
         let upgradeSpec: DvsResControlUpgradeSpec = new DvsResControlUpgradeSpec();
         upgradeSpec.migrateShares = model.migrateShares;
         return upgradeSpec;
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .service("dvsUpgradeNiocWizardSpecFactory", DvsUpgradeNiocWizardSpecFactory);
}
