/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace dvs_ui {

   import Dictionary = _.Dictionary;
   import HostListItemData = com.vmware.vsphere.client.dvs.api.host.HostListItemData;
   export class SelectHostsComponent {

      public bindings: any;
      public controller: any;
      public template: string;

      constructor() {
         this.bindings = {
            hosts: "<",
            allowAnySelection: "<",
            showVdsStateColumn: "<",
            showIncompatibleHostsButton: "<",
            onSelectedHostsChange: "&",
            selectAllConnectedHosts: "<?"
         };

         this.controller = SelectHostsController;

         this.template =
               `<div class="fill-parent dvs-select-hosts">
                  <button ng-if="$ctrl.showIncompatibleHostsButton"
                          type="submit"
                          class="btn btn-sm btn-link"
                          ng-click="$ctrl.onShowIncompatibleHostsButtonClicked()"
                  >{{$ctrl.showIncompatibleHostsButtonLabel}}</button>
                  <div class="filter-input pull-right">
                     <span class="nav-icon fa fa-filter"/>
                     <input type="text"
                            ng-model="$ctrl.searchTerm"
                            ng-model-options="{ getterSetter: true }"
                            ng-trim="true"
                            aria-label="{{$ctrl.i18nService.getString('Common', 'filter.label')}}"
                            placeholder="{{$ctrl.i18nService.getString('Common', 'filter.label')}}"/>
                  </div>
                  <div class="flex-grid"
                       ng-if="$ctrl.datagridOptions"
                       vx-stretch-grid
                       autofocus tabindex="-1"
                       aria-label="{{$ctrl.gridDescription}}"
                       vui-datagrid="$ctrl.datagridOptions"></div>
               </div>`;
      }
   }

   class SelectHostsController {

      static $inject = [
         "vuiConstants",
         "i18nService",
         "columnRenderersRegistry",
         "defaultUriSchemeUtil"
      ];

      private static readonly ICON_PREFIX: string = "network-lib-ui-icon-";

      private gridDescription: string;

      private selectMasterCheckbox: boolean = false;
      // Bindings

      public hosts: HostListItemData[];

      public allowAnySelection: boolean;

      public datagridOptions: any;

      public showVdsStateColumn: boolean;

      public showIncompatibleHostsButton: boolean;

      public selectAllConnectedHosts: boolean;

      public onSelectedHostsChange: (data: {selectedHostItems: HostListItemData[]}) => void;

      // Other public properties

      public showIncompatibleHostsButtonLabel: string;

      public areIncompatibleHostsShown: boolean = false;

      private _searchTerm: string;

      get searchTerm() {
         return this._searchTerm;
      }

      set searchTerm(value: string) {
         this._searchTerm = value;
         this.datagridOptions.data = this.getDatagridData();
      }

      constructor(private vuiConstants: any,
                  public i18nService: any,
                  private columnRenderersRegistry: any,
                  private defaultUriSchemeUtil: any) {
      }

      public $onInit(): void {
         this.gridDescription = this.i18nService.getString(
               "DvsUi", "DvsHostSelector.selectedHostsList.description");

         if (this.allowAnySelection === undefined) {
            this.allowAnySelection = false;
         }
         if (this.showIncompatibleHostsButton === undefined) {
            this.showIncompatibleHostsButton = false;
         }
         if (this.showVdsStateColumn === undefined) {
            this.showVdsStateColumn = false;
         }
         this.showIncompatibleHostsButtonLabel = this.getShowIncompatibleHostsButtonLabel();
         this.datagridOptions = this.createDatagridOptions();

         if (this.selectAllConnectedHosts) {
            this.notifySelectionChange();
         }
      }

      public onShowIncompatibleHostsButtonClicked(): void {
         this.areIncompatibleHostsShown = !this.areIncompatibleHostsShown;
         this.showIncompatibleHostsButtonLabel = this.getShowIncompatibleHostsButtonLabel();

         this.datagridOptions.data = this.getDatagridData();
      }

      private getShowIncompatibleHostsButtonLabel() {
         let label: string;
         if (!this.areIncompatibleHostsShown) {
            label = this.i18nService.getString("DvsUi",
                  "SelectHostsComponent.showIncompatibleHostsButtonLabel.show");
         } else {
            label = this.i18nService.getString("DvsUi",
                  "SelectHostsComponent.showIncompatibleHostsButtonLabel.hide");
         }
         return label;
      }

      private buildResult(): HostListItemData[] {
         let selectedItems: HostListItemData[] = _.filter(this.datagridOptions.data, (item: HostListItemData): boolean => {
            return this.datagridOptions.selectedHosts[item.hostName] && !this.isHostItemSelectionDisabled(item);
         });

         // return back only the HostListItemData that are selected
         return _.filter(this.hosts, (host: HostListItemData): boolean => {
            return _.some(selectedItems, (selectedItem: HostListItemData): boolean => {
               return selectedItem.hostName === host.hostName;
            });
         });
      }

      private createDatagridOptions() {
         let selectHostsController: SelectHostsController = this;

         return {
            height: "300",
            pageConfig: {
               hidePager: false
            },
            columnDefs: this.buildColumnDefinitions(),
            sortMode: this.vuiConstants.grid.sortMode.SINGLE,
            selectionMode: this.vuiConstants.grid.selectionMode.NONE,
            data: this.getDatagridData(),
            resizable: true,
            actionBarOptions: {actions: []},
            isItemDisabledCallback: function (item: HostListItemData) {
               return selectHostsController.isHostItemSelectionDisabled(item);
            },
            onMasterCheckboxClick: function (event: any) {
               let checked: boolean = event.currentTarget.checked;
               for (let key in selectHostsController.datagridOptions.selectedHosts) {
                  if (selectHostsController.isHostVisible(key)) {
                     selectHostsController.datagridOptions.selectedHosts[key] = checked;
                  }
               }
               selectHostsController.notifySelectionChange();
            },
            onItemCheckboxClick: function () {
               selectHostsController.notifySelectionChange();
            },
            selectedHosts: (() => {
               let value: Dictionary<boolean> = {};

               _.forEach(selectHostsController.hosts, (item: HostListItemData) => {
                  value[item.hostName] = !!this.selectAllConnectedHosts;
               });

               return value;
            })()
         };
      }

      private notifySelectionChange(): void {
         this.onSelectedHostsChange({selectedHostItems: this.buildResult()});
      }

      private getDatagridData(): HostListItemData[] {
         let hostsToShow: HostListItemData[] = this.hosts;
         if (!this.areIncompatibleHostsShown) {
            hostsToShow =
                  _.filter(hostsToShow, (item) => this.isHostCompatible(item));
         }
         if (this._searchTerm) {
            hostsToShow = _.filter(hostsToShow, (item) => this.isItemMatchingSearchTerm(item));
         }

         return hostsToShow;
      }

      private isHostCompatible(item: HostListItemData): boolean {
         return item.compatibilityErrors.length === 0;
      }

      private isItemMatchingSearchTerm(item: any): boolean {
         const filterableFields = _.pluck(this.datagridOptions.columnDefs, "field");
         return _.some(filterableFields,
               (field: string): boolean => this.isSubstringIgnoreCase(item[field], this._searchTerm));
      }

      private isHostVisible(hostName: string): boolean {
         return this.datagridOptions.data.some((item: HostListItemData) => item.hostName === hostName);
      }

      private isSubstringIgnoreCase(str: string, substr: string): boolean {
         if (!str) {
            return false;
         }
         if (!substr) {
            return true;
         }
         return str.toLowerCase().indexOf(substr.toLowerCase()) !== -1;
      }

      private isHostItemSelectionDisabled(item: HostListItemData): boolean {
         return !this.allowAnySelection &&
               (item.connectionState === DvsAddHostWizardConstants.hostConnectionState.DISCONNECTED ||
               item.connectionState === DvsAddHostWizardConstants.hostConnectionState.NOT_RESPONDING)
               || !this.isHostCompatible(item);
      }

      private buildColumnDefinitions() {
         let self: SelectHostsController = this;
         let columnns: any[] = [];

         this.selectMasterCheckbox = !!this.selectAllConnectedHosts
               && !_.every(this.hosts, (host: HostListItemData) => {
                  return this.isHostItemSelectionDisabled(host);
               });

         let headerTemplate =
               `<input data-test-id="selectHostsMasterCheckBox" type="checkbox" class="select-hosts-master-checkbox" 
                       ng-checked=${this.selectMasterCheckbox}
                       ng-click="datagridOptions.onMasterCheckboxClick($event)"/>`;
         columnns.push({
                  filterable: false,
                  sortable: false,
                  groupable: false,
                  searchable: false,
                  width: "25px",
                  headerTemplate: headerTemplate,
                  field: "selected",
                  template: function (item: HostListItemData) {
                     let value: string = `datagridOptions.selectedHosts["${item.hostName}"]`;

                     return self.isHostItemSelectionDisabled(item)
                           ? `<input type="checkbox" disabled/>`
                           : `<input type="checkbox" %checked %model
                                     ng-change="datagridOptions.onItemCheckboxClick($event)"/>`
                                 .replace("%checked", "ng-checked=" + value)
                                 .replace("%model", "ng-model=" + value);
                  }
               }
         );
         columnns.push({
            searchable: false,
            displayName: self.i18nService.getString(
                  "DvsUi", "DvsSelectHostsDialog.columnHostName.title"),
            field: "hostName",
            width: "150px",
            template: function (item: HostListItemData) {
               return self.buildCellHtml(item.hostName,
                     item.hostIconId,
                     self.isHostItemSelectionDisabled(item));
            }
         });
         columnns.push({
            searchable: false,
            displayName: self.i18nService.getString(
                  "DvsUi", "DvsSelectHostsDialog.columnHostState.title"),
            field: "connectionStateLabel",
            width: "150px",
            template: self.getHostConnectionStateColumnRenderer()
         });
         if (this.showVdsStateColumn) {

            columnns.push({
               searchable: false,
               displayName: self.i18nService.getString(
                     "DvsUi", "DvsSelectHostsDialog.columnDvsState.title"),
               field: "vdsState",
               width: "150px",
               template: self.getVdsStateColumnRenderer(),
            });
         }
         columnns.push({
            searchable: false,
            displayName: self.i18nService.getString(
                  "DvsUi", "DvsSelectHostsDialog.columnClusters.title"),
            field: "clusterName",
            width: "150px",
            template: self.getClusterColumnRenderer()
         });
         if (this.showIncompatibleHostsButton) {
            columnns.push({
               searchable: false,
               displayName: self.i18nService.getString(
                     "DvsUi", "SelectHostsComponent.compatibilityColumnHeader"),
               width: "150px",
               template: self.getCompatibilityColumnRenderer()
            });
         }
         return columnns;
      }

      private getIncompatibleHostTooltip(item: HostListItemData): string {
         let tooltip: string = "";

         if (item.compatibilityErrors && item.compatibilityErrors.length > 0) {
            _.forEach(item.compatibilityErrors, function (error: string) {
               tooltip += '<span>' + error + '</span>';
            });
         }
         return tooltip;
      }

      private getCompatibilityColumnRenderer(): ((item: HostListItemData) => string) {
         return (item: HostListItemData): string => {
            if (this.isHostCompatible(item)) {
               return this.buildCellHtml(
                     this.i18nService.getString("DvsUi", "HostsCompatibilityList.compatibleText"),
                     "vsphere-icon-status-ok",
                     this.isHostItemSelectionDisabled(item));
            } else {
               return this.buildIncompatibleHostCell(item);
            }
         };
      }

      private getClusterColumnRenderer(): ((item: HostListItemData) => string) {
         return ((item: HostListItemData): string => {
            if (item.clusterName) {
               return this.buildCellHtml(item.clusterName,
                     "vsphere-icon-cluster",
                     this.isHostItemSelectionDisabled(item));
            } else {
               return this.buildCellHtml(
                     this.i18nService.getString("DvsUi", "DvsSelectHostsDialog.na"),
                     "",
                     this.isHostItemSelectionDisabled(item));
            }
         });
      }

      private getHostConnectionStateColumnRenderer(): ((item: HostListItemData) => string) {
         return ((item: HostListItemData): string => {
            return this.buildCellHtml(item.connectionStateLabel,
                  "",
                  this.isHostItemSelectionDisabled(item));
         });
      }

      private getVdsStateColumnRenderer(): ((item: HostListItemData) => string) {
         let self: SelectHostsController = this;
         return (item: HostListItemData) =>
               self.buildCellHtml(
                     item.vdsState,
                     SelectHostsController.ICON_PREFIX + item.vdsStateIcon,
                     this.isHostItemSelectionDisabled(item));
      }

      private buildCellHtml(text: string, icon: string, disabled: boolean): string {
         let template: string = `<div class="no-tooltip-cell %disabled">%icon%text</div>`;

         return template
               .replace("%icon", "<span class='" + icon + "'></span>")
               .replace("%text", "<span ng-non-bindable class='vertical-align-cell'>" + text + "</span>")
               .replace("%disabled", disabled ? "disabled-cell" : "");
      }

      private buildIncompatibleHostCell(dataItem: HostListItemData): string {

         let tooltip: string = this.getIncompatibleHostTooltip(dataItem);
         let text: string = this.i18nService.getString(
               "DvsUi", "HostsCompatibilityList.incompatibleText");
         let disabled: string = this.isHostItemSelectionDisabled(dataItem) ?
               "class='element-disabled'" : "";

         let template: string = `
            <span class="vertical-align-cell">
               <a role="tooltip" aria-haspopup="true" class="tooltip tooltip-md tooltip-top-left">
                  <clr-icon shape="error" size="24" class="is-error"></clr-icon>
                  <span class="tooltip-content">${tooltip}</span>
               </a>
               <span ${disabled}><span class='vertical-align-cell'>${text}</span></span>
            </span>
         `;

         return template;
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .component("selectHosts", new SelectHostsComponent());
}
