namespace h5_vm {

   import IComponentController = angular.IComponentController;
   import IComponentOptions = angular.IComponentOptions;

   export class VmTpmController implements IComponentController {

      static $inject: string[] = ['$rootScope', 'vmTpmService', 'i18nService'];
      private vmId: string;
      private label: string;
      private i18n: Function;
      private tpmCertificates: any;

      constructor(private $rootScope:any, private vmTpmService: any, private i18nService: any) {
         this.i18n = i18nService.getString;
      }

      $onInit(): void {
         this.vmTpmService.getCertificates(this.vmId)
            .then((result: any) => {
               this.tpmCertificates = result;
            });
      }

      public navigateToConfigureTab() {
         this.$rootScope._navigateToViewAndObject('vsphere.core.vm.manage.settings.tpmCertificatesView', this.vmId);
      }
   }


   export class VmTpmComponent implements IComponentOptions {
      public controller: any;
      public templateUrl: string;
      public bindings: any;


      constructor() {
         this.controller = VmTpmController;
         this.templateUrl = "vm-ui/resources/vm/views/summary/vmTpm/vm-tpm.html";
         this.bindings = {
            vmId: "<",
            label: "<"
         };
      }

   }

   angular.module("com.vmware.vsphere.client.vm")
      .component("vmTpm", new VmTpmComponent());
}
