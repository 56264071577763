/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace dvs_ui {

   export class DvsNetFlowViewService {

      public static $inject = [
            "i18nService",
            "vxPropertyViewService"];

      private i18n: any;

      constructor(private i18nService: any,
                  private vxPropertyViewService: any) {
         this.i18n = this.i18nService.getString;
      }

      public buildProperties(properties: any) {
         let builder = this.vxPropertyViewService.createPropertyViewBuilder();
         let generalCategory: any = builder.category("general");
         this.addPropertiesToSection(properties, generalCategory);
         return builder.build();
      }

      public addPropertiesToSection(properties: any, category: any): void {
         let generalSection: any = category.section("generalSection");

         let unsetProperty = this.i18n("NetworkUi", "NetworkUtil.noData");

         generalSection.property(
               this.i18n("DvsUi", "dvs.netFlowView.collector.ipAddress"),
               (properties.collectorIpAddress !== null
                     && properties.collectorIpAddress !== "")
                  ? properties.collectorIpAddress : unsetProperty);

         generalSection.property(
               this.i18n("DvsUi", "dvs.netFlowView.collector.port"),
               properties.collectorPort !== null
                     ? properties.collectorPort : unsetProperty);

         if (properties.ipfixV10Supported) {
            generalSection.property(
                  this.i18n("DvsUi", "dvs.netFlowView.collector.observationDomainId"),
                  properties.observationDomainId !== null
                        ? properties.observationDomainId : unsetProperty);
         }

         generalSection.property(
               this.i18n("DvsUi", "dvs.netFlowView.switch.ipAddress"),
               (properties.switchIpAddress !== null
                     && properties.switchIpAddress !== "")
                     ? properties.switchIpAddress : unsetProperty);

         generalSection.property(
               this.i18n("DvsUi", "dvs.netFlowView.activeFlowTimeout"),
               properties.activeFlowTimeout !== null
                     ? this.i18n("DvsUi",
                           "dvs.netFlowView.flowTimeout.unitFormat",
                           properties.activeFlowTimeout)
                     : unsetProperty);

         generalSection.property(
               this.i18n("DvsUi", "dvs.netFlowView.idleFlowTimeout"),
               properties.idleFlowTimeout !== null
                     ? this.i18n("DvsUi",
                           "dvs.netFlowView.flowTimeout.unitFormat",
                           properties.idleFlowTimeout)
                     : unsetProperty);

         generalSection.property(
               this.i18n("DvsUi", "dvs.netFlowView.samplingRate"),
               properties.samplingRate !== null
                     ? properties.samplingRate : unsetProperty);

         generalSection.property(
               this.i18n("DvsUi", "dvs.netFlowView.internalFlowsOnly"),
               properties.internalFlowsOnly
                     ? this.i18n("DvsUi",
                           "dvs.netFlowView.internalFlowsOnlyEnabled")
                     : this.i18n("DvsUi",
                           "dvs.netFlowView.internalFlowsOnlyDisabled"));
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .service("dvsNetFlowViewService", DvsNetFlowViewService);
}