angular.module('com.vmware.vsphere.client.vm').factory('VirtualDisk', [
   'EditableVirtualDevice',
   'deviceDiffService',
   'storageProfileService',
   'diskBackingInfoConstants',
   'pmemUtilService',
   'i18nService',
   'vmCryptUtilService',
   function(EditableVirtualDevice, deviceDiffService, storageProfileService,
         diskBackingInfoConstants, pmemUtilService, i18nService, vmCryptUtilService) {
      var UNLIMITED_DISK = i18nService.getString('VmUi', 'DiskConfig.Unlimited');
      var CREATE_FILE_OPERATION = 'create';
      var DESTROY_FILE_OPERATION = "destroy";
      var original_capacityInBytes;
      var original_capacityInKB;


      var VirtualDisk = function(device, storageProfile, replicationGroupInfo) {
         EditableVirtualDevice.call(this, device);

         var originalReplicationGroupInfo;
         var currentReplicationGroupInfo;

         if (replicationGroupInfo) {
            originalReplicationGroupInfo = replicationGroupInfo;
            currentReplicationGroupInfo = replicationGroupInfo;
         }

         original_capacityInBytes = device.capacityInBytes;
         original_capacityInKB = device.capacityInKB;
         var originalBacking = device.backing;
         var originalCapacityInMB = device.capacityInKB / 1024;
         var isStorageProfileManuallySet = false;
         this.storageProfile = storageProfile;


         if (this.isNew() && !this.getCurrentDevice().createdFromExistingDisk) {
            this.getCurrentDeviceSpec().fileOperation = CREATE_FILE_OPERATION;
         }

         var superHasChanged = this.hasChanged;

         this.setDiskFileOperation = function(fileOperation) {
            this.getCurrentDeviceSpec().fileOperation = fileOperation;
         };

         this.getDiskFileOperation = function() {
            return this.getCurrentDeviceSpec().fileOperation;
         };

         this.isCreatedFromExistingDisk = function() {
            return this.getCurrentDevice().createdFromExistingDisk;
         };

         this.isRDMDisk = function() {
            return this.getCurrentDevice().backing._type === diskBackingInfoConstants.RAWDISKMAPPING;
         };

         this.setProfile = function(updatedProfile) {
            this.getCurrentDeviceSpec().profile = updatedProfile
                  ? [updatedProfile]
                  : undefined;
         };

         this.setOriginalProfile = function (storageProfile, replicationGroupInfo) {

            if (!originalProfile) {
               originalProfile = storageProfile;
               this.setProfile(storageProfile);
            }

            if (!originalReplicationGroupInfo && !!replicationGroupInfo) {
               originalReplicationGroupInfo = replicationGroupInfo;
               currentReplicationGroupInfo = replicationGroupInfo;
               // call "setProfile" again to propagate replicaionSpec
               this.setProfile(storageProfile);
            }

         };

         if (storageProfile) {
            var originalProfile;
            this.setOriginalProfile(storageProfile, replicationGroupInfo);
         }

         this.restoreOriginalBacking = function() {
            this.getCurrentDevice().backing = originalBacking;
         };

         this.getProfile = function() {
            return this.getCurrentDeviceSpec().profile && this.getCurrentDeviceSpec().profile[0];
         };

         this.updateCapacity = function(capacityValueInMB) {
            this.getCurrentDevice().capacityInKB = capacityValueInMB * 1024;
            this.getCurrentDevice().capacityInBytes = this.getCurrentDevice().capacityInKB * 1024;

            if (this.getCurrentDevice().capacityInKB === original_capacityInKB) {
               this.getCurrentDevice().capacityInBytes = original_capacityInBytes                                                                                                                  ;
            }
         };

         this.updateDiskMode = function(diskMode) {
            this.getCurrentDevice().backing.diskMode = diskMode;
         };

         this.updateFileName = function(fileName) {
            this.getCurrentDevice().backing.fileName = fileName;
         };

         this.updateDiskProvisioningOption = function(backingType) {
            this.getCurrentDevice().backing = angular.extend(
                  this.getCurrentDevice().backing,
                  backingType);
         };

         this.updateDiskProfile = function(storageProfile, replicationGroupInfo) {
            this.storageProfile = storageProfile;
            var profile;
            if (storageProfile && (storageProfile.id || storageProfile.profileId)) {
               profile = storageProfileService.makeProfile(
                     storageProfile.id || storageProfile.profileId,
                     replicationGroupInfo);
               profile.label = storageProfile.label;
            }
            currentReplicationGroupInfo = replicationGroupInfo;
            this.setProfile(profile);
         };

         this.getCurrentReplicationGroup = function() {
            return currentReplicationGroupInfo;
         };

         this.updateStorageIOAllocation = function(ioLimit) {
            this.getCurrentDevice().storageIOAllocation.limit = (ioLimit === UNLIMITED_DISK ? -1 : ioLimit);
         };

         this.updateSharing = function(sharingRule) {
            this.getCurrentDevice().backing.sharing = sharingRule || null;
         };

         this.updateShares = function(value) {
            const io = this.getCurrentDevice().storageIOAllocation;
            const info = io.shares;
            info.level = value.level;
            info.shares = value.shares;
         };

         this.updateCompatibilityMode = function(compatibilityMode) {
            this.getCurrentDevice().backing.compatibilityMode = compatibilityMode;
         };

         this.updateFlashCacheReservation = function(reservationInMB) {
            this.getCurrentDevice().vFlashCacheConfigInfo = this.getCurrentDevice().vFlashCacheConfigInfo || { _type:"com.vmware.vim.binding.vim.vm.device.VirtualDisk$VFlashCacheConfigInfo"};
            this.getCurrentDevice().vFlashCacheConfigInfo.reservationInMB = reservationInMB;
         };

         this.updateControllerAndNodeKeys = function(controllerKey, controllerNodeKey) {
            this.getCurrentDevice().controllerKey = controllerKey;
            this.getCurrentDevice().unitNumber = controllerNodeKey ? controllerNodeKey : this.getCurrentDevice().unitNumber;
         };

         this.updateDatastoreMor = function(datastoreRef) {
            this.getCurrentDevice().backing.datastore = datastoreRef;
            if (!datastoreRef) {
               //this.getCurrentDevice().backing.fileName;
            }
         };

         this.updateParentStoragePodMor = function(parentStoragePodRef) {
            this.getCurrentDevice().backing.parentStoragePod = parentStoragePodRef;
         };

         this.hasChanged = function() {
            var hasDeviceChanged = superHasChanged.apply(this);
            if (originalProfile && this.getCurrentDeviceSpec().profile) {
               var hasProfileChanged = !_.isEqual(originalProfile.profileId, this.getCurrentDeviceSpec().profile[0].profileId);
               hasDeviceChanged = hasDeviceChanged || hasProfileChanged;
            }

            if (originalReplicationGroupInfo && currentReplicationGroupInfo) {
               var hasReplicationGroupChanged = !_.isEqual(
                     originalReplicationGroupInfo.id, currentReplicationGroupInfo.id);
               hasDeviceChanged = hasDeviceChanged || hasReplicationGroupChanged;
            }

            return hasDeviceChanged;
         };

         this.deviceName = function() {
            return this.getCurrentDevice().deviceInfo.label;
         };

         this.isPmemDisk = function() {
           return pmemUtilService.isPmemDisk(this.getCurrentDevice());
         };

         this.isDiskAlreadyEncrypted = function() {
            return vmCryptUtilService.isExistingDiskEncrytped(this.getCurrentDevice());
         };

         this.isEncryptionProfileApplied = function() {
            return storageProfileService.isEncryptionStorageProfile(this.storageProfile);
         };

         this.isDiskEncrypted = function() {
            return this.isDiskAlreadyEncrypted() || this.isEncryptionProfileApplied();
         };

         this.getOriginalCapacityInMB = function() {
            if (this.isNew()) {
               return 0;
            }
            return originalCapacityInMB;
         };

         this.getCapacityDiff = function() {
            var currentCapacity = this.getCurrentDevice().capacityInKB / 1024;
            if (this.isNew()) {
               return currentCapacity;
            }
            return currentCapacity - originalCapacityInMB;
         };

         this.isStorageProfileManuallySet = function() {
            return isStorageProfileManuallySet;
         };

         this.setStorageProfileManuallySet = function(value) {
            isStorageProfileManuallySet = value;
         };

      };


      VirtualDisk.UNLIMITED_DISK = UNLIMITED_DISK;

      return VirtualDisk;
   }
]);
