/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace dvs_ui {

   export class ExportDvsService {

      public saveCharArrayToDisk(charArray: string): void {
         let saveByteArray: Function = (function () {
            return (data: Uint8Array[], name: string) => {
               let blob: Blob = new Blob(data, {type: "octet/stream"});
               if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                  window.navigator.msSaveOrOpenBlob(blob, name);
               } else {
                  let a: any = document.getElementsByClassName("invisibleExportDvsDownloadAnchor")[0];
                  let url: string = window.URL.createObjectURL(blob);
                  if (a) {
                     a.href = url;
                     a.download = name;
                     a.click();
                  }
                  window.URL.revokeObjectURL(url);
               }
            };
         }());

         let bytes = this.base64ToArrayBuffer(charArray);
         saveByteArray([bytes], 'backup.zip');
      }

      private base64ToArrayBuffer(base64: string): Uint8Array {
         let binaryString: string = window.atob(base64);
         let binaryLength: number = binaryString.length;
         let bytes = new Uint8Array(binaryLength);
         for (let i: number = 0; i < binaryLength; i++) {
            let ascii: number = binaryString.charCodeAt(i);
            bytes[i] = ascii;
         }

         return bytes;
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .service("exportDvsService", ExportDvsService);
}
