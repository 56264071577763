/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
(function() {
   'use strict';

   angular.module('com.vmware.vsphere.client.licenseLibUi')
      .component("licenseSelector", {
         templateUrl: "license-lib-ui/resources/license/components/licenseSelector/licenseSelector.html",
         controller: LicenseSelectorController,
         bindings: {
            licenseData: '<?',
            selectedLicenseItem: '=?',
            onItemChange: '&',
            isAddHostState: '=?'
         }
      });

   LicenseSelectorController.$inject = [
      '$scope',
      'vuiUtils',
      'i18nService',
      'licenseCommonUtil',
      'licenseTreeListUtil',
      'licenseValidationUtil',
      'columnRenderersRegistry',
      'vuiDialogService'
   ];

   function LicenseSelectorController (
      $scope,
      vuiUtils,
      i18nService,
      licenseCommonUtil,
      licenseTreeListUtil,
      licenseValidationUtil,
      columnRenderersRegistry,
      vuiDialogService
   ) {

      var NAME_FIELD = 'name';
      var LICENSE_KEY_FIELD = 'licenseKey';
      var PRODUCT_FIELD = 'product';
      var CAPACITY_FIELD = 'capacity';

      function iconNameRendererFactory(nameField, iconField) {
         return (function(data) {
            var name = data[nameField];
            var iconClass = data[iconField];

            if (!name && !iconClass) {
               return "";
            }

            var objectLinkRenderer =
               columnRenderersRegistry.getColumnRenderer('object-link');
            return objectLinkRenderer([undefined, nameField, iconClass], data);
         });
      }

      function scrollSelectedTreeListItemIntoView() {
         var itemNode = self.treeListWidget.select();

         if (itemNode.length !== 1 || !itemNode.scrollParent || !itemNode.scrollParent() ||
            itemNode.scrollParent().length === 0) {
            return;
         }

         var scrollParent = itemNode.scrollParent();

         var itemTop = itemNode[0].offsetTop;
         var itemBottom = itemTop + itemNode.height();
         var viewportTop = scrollParent.scrollTop();
         var viewportBottom = viewportTop + scrollParent.height();

         if (itemTop < viewportTop) {
            scrollParent.scrollTop(itemTop);
         } else if (viewportBottom < itemBottom) {
            scrollParent.scrollTop(viewportTop + (itemBottom - viewportBottom));
         }
      }

      /**
       * Makes the license key column match its content width if there is at least
       * one serial key in the grid.
       * Otherwise it uses the standard grid algorithm to calculate the width.
       */
      function autofitLicenseKeyColumnIfSerialKeysExist() {
         if (!self.treeListWidget || !self.treeListWidget.columns
               || !self.treeListWidget.dataSource
               || !self.treeListWidget.dataSource.options
               || !self.treeListWidget.dataSource.options.data
               || !self.treeListWidget.dataSource.options.data.length) {
            return;
         }

         var serialKeyExistInGrid = false;
         var treeListData = self.treeListOptions.dataSource.options.data;
         serialKeyExistInGrid = treeListData.some(function(license) {
            return license && license.licenseKey !== "--";
         });

         if (!serialKeyExistInGrid) {
            return;
         }

         for (var i = 0; i < self.treeListWidget.columns.length; i++) {
            if (LICENSE_KEY_FIELD === self.treeListWidget.columns[i].field) {
               self.treeListWidget.autoFitColumn(i);
            }
         }
      }

      var self = this;

      var treeListDataSourceSchema = {
         model: {
            id: "id",
            expanded: false
         }
      };
      var licensePreselected = false;

      self.ValidationMessageType = licenseCommonUtil.ValidationMessageType;

      self.licenseValidationMessages = [];
      self.validationPanelTitle = i18nService.getString(
         'LicenseLibUi',
         'assignLicense.validationTitle'
      );

      self.i18nService = i18nService;
      self.treeListWidget = null;
      self.treeListColumns = [{
         title: i18nService.getString('LicenseLibUi', 'list.licenses.column.name'),
         field: NAME_FIELD,
         template: iconNameRendererFactory('name', 'icon')
      }, {
         title: i18nService.getString('LicenseLibUi', 'list.licenses.column.serialKey'),
         field: LICENSE_KEY_FIELD
      }, {
         title: i18nService.getString('LicenseLibUi', 'list.licenses.column.product'),
         field: PRODUCT_FIELD
      }, {
         title: i18nService.getString('LicenseLibUi', 'list.licenses.column.usage'),
         field: '',
         template: function(dataItem) {
            var label = dataItem.usage.current.label;
            var tooltip = '';
            if (dataItem.usage.current.tooltip) {
               tooltip = dataItem.usage.current.tooltip;
            }

            var templateElement = $('<div></div>')
               .text(label)
               .attr('title', tooltip);

            if (dataItem.usage.current.indicateUsageChange) {
               templateElement.addClass('changed-usage-highlight');
            }

            return templateElement[0].outerHTML;
         }
      }, {
         title: i18nService.getString('LicenseLibUi', 'list.licenses.column.limit'),
         field: CAPACITY_FIELD,
         sortable: {
            compare: function(license1, license2) {
               var capacity1 = parseInt(license1.capacity.split(" ")[0]);
               var capacity2 = parseInt(license2.capacity.split(" ")[0]);
               return capacity1 - capacity2;
            }
         }
      }];

      self.handleTreeListChange = function() {
         var selectedLicense = null;

         if (self.treeListWidget.select().length === 1) {
            var dataItem = self.treeListWidget.dataItem(self.treeListWidget.select());
            selectedLicense = dataItem._license;
         } else {
            selectedLicense = null;
         }

         if (!_.isEqual(self.selectedLicenseItem, selectedLicense)) {
            self.selectedLicenseItem = selectedLicense;
            self.onItemChange({item: selectedLicense});
         }

         self.licenseValidationMessages =
            licenseValidationUtil.getValidationMessages(
               selectedLicense,
               self.licenseData,
               self.isAddHostState
            );

         if (selectedLicense) {
            self.validationPanelTitle = i18nService.getString(
               'LicenseLibUi',
               'assignLicense.validationTitle.license',
               selectedLicense.name
            );
         } else {
            self.validationPanelTitle = i18nService.getString(
               'LicenseLibUi',
               'assignLicense.validationTitle'
            );
         }

         _.each(self.treeListOptions.dataSource.data(), function(item) {
            if (item._license.uri === licenseCommonUtil.getLicenseUri(selectedLicense)) {
               item.usage.current = item.usage.selected;
            } else {
               item.usage.current = item.usage.deselected;
            }
         });

         self.treeListWidget.refresh();
      };

      self.handleTreeListDataBound = function() {
         if (!self.treeListWidget || !self.licenseData || licensePreselected) {
            return;
         }

         autofitLicenseKeyColumnIfSerialKeysExist();

         licensePreselected = true;
         var preSelectedLicenseUri = licenseCommonUtil.getLicenseUri(
            licenseCommonUtil.getPreSelectedLicense(
               self.licenseData,
               self.selectedLicenseItem
            ));

         $scope.$evalAsync(function() {
            var preSelectedLicenseDataItem =
               _.filter(self.treeListOptions.dataSource.rootNodes(), function(item) {
                  var itemLicenseUri = licenseCommonUtil.getLicenseUri(item._license);
                  return preSelectedLicenseUri === itemLicenseUri;
               });

            if (preSelectedLicenseDataItem.length === 1) {
               var preSelectedLicenseItemNode =
                  self.treeListWidget.itemFor(preSelectedLicenseDataItem[0]);
               self.treeListWidget.select(preSelectedLicenseItemNode);
               scrollSelectedTreeListItemIntoView();
            } else {
               self.treeListWidget.select($([]));
            }
         });
      };

      self.showValidationMessageDetailsDialog = function(validationMessage) {
         var dialogScope = $scope.$new(true);
         dialogScope.dialogOptions = {
            title: i18nService.getString(
               'LicenseLibUi',
               'assignLicense.validationMessageDetails.dialog.title'
            ),
            contentUrl: 'license-lib-ui/resources/license/components/licenseSelector' +
                  '/licenceValidationMessageDetailsContent.html',
            show: true,
            rejectOptions: {
               visible: false
            },
            confirmOptions: {
               label: i18nService.getString(
                  'LicenseLibUi',
                  'assignLicense.validationMessageDetails.dialog.okButtonLabel'
               )
            },
            height: 'auto',
            width: '450px'
         };
         dialogScope.contentModel = {
            validationMessage: validationMessage
         };

         var options = {
            scope: dialogScope,
            configObjectName: 'dialogOptions'
         };

         vuiDialogService(options);
      };

      self.treeListOptions = {
         columns: self.treeListColumns,
         sortable: true,
         resizable: true,
         selectable: true,
         change: _.partial(vuiUtils.safeApply, $scope, self.handleTreeListChange),
         dataBound: _.partial(vuiUtils.safeApply, $scope, self.handleTreeListDataBound)
      };

      self.$onChanges = function(changes) {
         if (changes.hasOwnProperty("licenseData") &&
               changes.licenseData.previousValue !== changes.licenseData.currentValue) {
            self.licenseData = changes.licenseData.currentValue;
            populateLicenseList();
         }
      };

      function populateLicenseList() {
         if (self.licenseData) {
            self.treeListOptions.dataSource = new kendo.data.TreeListDataSource({
               data: licenseTreeListUtil.createLicenseTreeListItems(self.licenseData),
               schema: treeListDataSourceSchema
            });
            self.treeListOptions.dataSource.read();
         }
      }
   }
})();
