module ds_cluster_ui {

   class StorageDrsAutomationPageViewController {

      public static $inject = ["$scope", "storageDrsAutomationService"];

      public pageModel: any;
      public enableButtonAutofocus: boolean;
      private sdrsManager: SdrsDialogManager;

      constructor(private $scope: any,
                  private storageDrsAutomationService: StorageDrsAutomationService) {
         this.sdrsManager = $scope.modalOptions.dialogData.manager;
         this.pageModel = this.sdrsManager.getStorageDrsAutomationModel();
         this.enableButtonAutofocus = $scope.enableButtonAutofocus;
      }
   }

   angular.module("com.vmware.vsphere.client.dsCluster")
         .controller("StorageDrsAutomationPageViewController",
               StorageDrsAutomationPageViewController);
}
