/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
(function () {
   'use strict';

   angular.module('com.vmware.vsphere.client.storage').directive('adapterPathsList', adapterPathsList);

   adapterPathsList.$inject = ['i18nService', 'columnRenderersRegistry', 'vuiConstants',
         'listViewColumnService', 'hostStorageConstants', 'datagridActionBarService', 'listUtil', 'kendoGridService'];

   function adapterPathsList(i18nService, columnRenderersRegistry, vuiConstants,
         listViewColumnService, hostStorageConstants, datagridActionBarService, listUtil, kendoGridService) {

      var directive = {
         replace: true,
         scope: {
            paths: '=', // Array of storage paths to be shown.
            selectedPath: '=?',  // Optional path to be selected initially.
            columnSet: '=?', // Optional param controlling which columns should be shown
                             // Valid values are:
                             //    - STORAGE_ADAPTERS_PATHS (default)
                             //    - STORAGE_DEVICE_PATHS
                             //    - VMFS_EXTENT_PATHS
                             //    - PROTOCOL_ENDPOINT_PATHS
            viewId: '=', // Needed for persisting visible grid columns in the user session.
            hostId: '=?', // hostId needed to request path actions from the AF, used when showPathActions is true.
            showPathActions: '=?', // controls whether to show enable and disable path actions.
            showRefreshStorageSystemAction: '=?' // Optional param (false by default) to display or not
                                                 // the refresh storage system action.
         },
         templateUrl: 'storage-ui/resources/storage/directives/adapterPathsList/adapterPathsList.html',
         link: link
      };

      return directive;

      function link(scope) {

         var STORAGE_ADAPTERS_PATHS = 'STORAGE_ADAPTERS_PATHS';
         var STORAGE_DEVICE_PATHS = 'STORAGE_DEVICE_PATHS';
         var VMFS_EXTENT_PATHS = 'VMFS_EXTENT_PATHS';
         var PROTOCOL_ENDPOINT_PATHS = 'PROTOCOL_ENDPOINT_PATHS';

         scope.showPathActions = scope.showPathActions || false;
         scope.showRefreshStorageSystemAction = scope.showRefreshStorageSystemAction || false;

         scope.columnSet = scope.columnSet || STORAGE_ADAPTERS_PATHS;

         var NAME_COLUMN = {
            displayName: i18nService.getString('StorageUi', 'storage.paths.list.name'),
            field: 'name',
            width: '150px',
            template: function(dataItem) {
               return dataItem.name;
            }
         };

         var RUNTIME_NAME_COLUMN = {
            displayName: i18nService.getString('StorageUi', 'storage.adapters.paths.runtimeName'),
            field: 'runtimeName',
            width: '100px',
            template: function(dataItem) {
               return dataItem.runtimeName;
            }
         };

         var TARGET_COLUMN = {
            displayName: i18nService.getString('StorageUi', 'storage.adapters.paths.target'),
            field: 'formattedTarget',
            width: '180px',
            template: function(dataItem) {
               return dataItem.formattedTarget;
            }
         };

         var LUN_COLUMN = {
            displayName: i18nService.getString('StorageUi', 'storage.adapters.paths.lun'),
            field: 'lunNumber',
            width: '80px',
            sortable: function(item1, item2) {
               return listUtil.compareNumericValues(item1, item2, 'lunNumber');
            },
            template: lunNumberTemplate
         };

         var LUN_ID_COLUMN = {
            displayName: i18nService.getString('StorageUi', 'storage.adapters.paths.lunId'),
            field: 'lunId',
            width: '150px',
            visible: false,
            template: function(dataItem) {
               return dataItem.lunId;
            }
         };

         var STATUS_COLUMN = {
            displayName: i18nService.getString('StorageUi', 'storage.adapters.paths.status'),
            field: 'status',
            width: '100px',
            template: function(dataItem) {
               var data = computePathState(dataItem.pathState, dataItem.isWorkingPath);
               return iconTextRenderer(['icon', 'text'], data);
            },
            sortable: function(item1, item2) {
               return computePathState(item1.pathState, item1.isWorkingPath).text
                  .localeCompare(computePathState(item2.pathState, item2.isWorkingPath).text);
            }
         };

         var ADAPTER_COLUMN = {
            displayName: i18nService.getString('StorageUi', 'storage.paths.list.adapter'),
            field: 'adapter',
            width: '100px',
            visible: false,
            template: function(dataItem) {
               return dataItem.adapter;
            }
         };

         var DEVICE_COLUMN = {
            displayName: i18nService.getString('StorageUi', 'storage.adapters.paths.device'),
            field: 'device',
            width: '180px',
            visible: false,
            template: function(dataItem) {
               return dataItem.device;
            }
         };

         var PREFERRED_COLUMN = {
            displayName: i18nService.getString('StorageUi', 'storage.paths.list.preferred'),
            field: 'preferred',
            width: '150px',
            template: function(dataItem) {
               return dataItem.preferred;
            }
         };

         var iconTextRenderer = columnRenderersRegistry.getColumnRenderer('icon-text');

         var self = scope;
         var columnsChangeHandler = listViewColumnService.getColumnChangeHandler(scope.viewId, scope);
         listViewColumnService.applyPersistedState(getColumnDefinitions(scope.columnSet), scope.viewId)
               .then(function(columnDefs) {
                  self.datagridOptions = {
                     height: '100%',
                     columnDefs: columnDefs,
                     sortMode: vuiConstants.grid.sortMode.SINGLE,
                     selectionMode: vuiConstants.grid.selectionMode.SINGLE,
                     data: [],
                     selectedItems: [],
                     resizable: true,
                     vuiFooter: {
                        actions: [{
                           disabled: false,
                           label: i18nService.getString('StorageUi', 'action.copyAll'),
                           tooltip: i18nService.getString('StorageUi', 'action.copyAll'),
                           icon: 'vui-icon-action-copy',
                           action: function() {
                              kendoGridService.copyAllItems('#adapterPathsGrid [kendo-grid]');
                           }
                        }]
                     },
                     columnMenu: {
                        sortable: false, // this will hide sort menu items
                        messages: {
                           columns: i18nService.getString('CommonUi', 'listview.showColumnsMenu'),
                           filter: i18nService.getString('CommonUi', 'listview.filterMenu')
                        }
                     },
                     columnHide: columnsChangeHandler,
                     columnReorder: columnsChangeHandler,
                     columnResize: columnsChangeHandler,
                     columnShow: columnsChangeHandler,
                     actionBarOptions: {actions: []}
                  };

                  self.$watch(function() {
                        return self.paths;
                     },
                     function(newValue, oldValue) {
                        if (newValue !== oldValue) {
                           self.datagridOptions.data = newValue;
                        }
                     }
                  );

                  self.$watch(function() {
                        return self.datagridOptions.selectedItems;
                     },
                     function(newValue, oldValue) {
                        //the grid fires an empty selection when rendered for the first time
                        if (newValue === oldValue || _.isEqual(newValue, oldValue)) {
                           return;
                        }
                        if (newValue && newValue.length) {
                           self.selectedPath = newValue[0];
                        } else {
                           self.selectedPath = null;
                        }

                        updateActionbar();
                     }
                  );

                  self.datagridOptions.data = self.paths;

                  updateActionbar();
               });

         scope.preselectComparator = function(gridDataItem) {
            if (!scope.selectedPath || !gridDataItem) {
               return false;
            }

            return scope.selectedPath.runtimeName === gridDataItem.runtimeName;
         };

         function updateActionbar() {
            if (!self.showPathActions) {
               return;
            }

            var actions = [];
            if (self.showRefreshStorageSystemAction) {
               actions.push({
                  actionId: 'vsphere.core.host.actions.refreshStorageSystem'
               });
               actions.push(vuiConstants.actions.SEPARATOR);
            }

            actions.push(
               {
                  actionId: 'vsphere.core.host.actions.paths.enablePath',
                  getActionInvocationContext: function() {
                     return {
                        pathName: self.selectedPath.name
                     };
                  },
                  isActionAvailable: function(actionDef) {
                     return self.selectedPath && actionDef.available &&
                        self.selectedPath.pathState === hostStorageConstants.adapterPathState.DISABLED;
                  }
               }
            );

            actions.push(
               {
                  actionId: 'vsphere.core.host.actions.paths.disablePath',
                  getActionInvocationContext: function() {
                     return {
                        pathName: self.selectedPath.name
                     };
                  },
                  isActionAvailable: function(actionDef) {
                     return self.selectedPath && actionDef.available &&
                        (self.selectedPath.pathState === hostStorageConstants.adapterPathState.ACTIVE ||
                        self.selectedPath.pathState === hostStorageConstants.adapterPathState.STANDBY);
                  }
               }
            );

            datagridActionBarService.updateActionBar(self.datagridOptions, [self.hostId], actions);
         }

         function getColumnDefinitions(columnSet) {
            if (columnSet === STORAGE_DEVICE_PATHS) {
               NAME_COLUMN.visible = true;
               return [
                  RUNTIME_NAME_COLUMN, STATUS_COLUMN,
                  TARGET_COLUMN, NAME_COLUMN, PREFERRED_COLUMN
                ];
            }

            if (columnSet === VMFS_EXTENT_PATHS) {
               NAME_COLUMN.visible = false;
               return [
                  RUNTIME_NAME_COLUMN, STATUS_COLUMN, ADAPTER_COLUMN,
                  TARGET_COLUMN, LUN_COLUMN, LUN_ID_COLUMN, NAME_COLUMN, PREFERRED_COLUMN
               ];
            }

            if (columnSet === PROTOCOL_ENDPOINT_PATHS){
               DEVICE_COLUMN.visible = true;
               return [RUNTIME_NAME_COLUMN, STATUS_COLUMN, DEVICE_COLUMN, TARGET_COLUMN,
                  NAME_COLUMN, PREFERRED_COLUMN];
            }

            return [RUNTIME_NAME_COLUMN, TARGET_COLUMN, LUN_COLUMN,
               STATUS_COLUMN, DEVICE_COLUMN, LUN_ID_COLUMN];
         }

         function computePathState(pathState, isWorkingPath) {
            var displayState;
            var iconClassPrefix = 'storage-ui-icon-path-';

            if (!pathState) {
               return {
                  icon: iconClassPrefix + hostStorageConstants.adapterPathState.STANDBY,
                  text: i18nService.getString('StorageUi', 'storage.adapters.paths.state.unknown')
               };
            }

            switch (pathState) {
               case hostStorageConstants.adapterPathState.ACTIVE:
                  displayState = i18nService.getString('StorageUi', 'storage.adapters.paths.state.active');
                  break;
               case hostStorageConstants.adapterPathState.STANDBY:
                  displayState = i18nService.getString('StorageUi', 'storage.adapters.paths.state.standby');
                  break;
               case hostStorageConstants.adapterPathState.DISABLED:
                  displayState = i18nService.getString('StorageUi', 'storage.adapters.paths.state.disabled');
                  break;
               case hostStorageConstants.adapterPathState.DEAD:
                  displayState = i18nService.getString('StorageUi', 'storage.adapters.paths.state.dead');
                  break;
               default:
                  displayState = i18nService.getString('StorageUi', 'storage.adapters.paths.state.unknown');
            }

            if (isWorkingPath) {
               displayState = i18nService.getString('StorageUi', 'storage.adapters.paths.state.workingPath', displayState);
            }

            return {
               icon: iconClassPrefix + pathState,
               text: displayState
            };
         }

         function lunNumberTemplate(dataItem) {
            if (dataItem.lunNumber === -1 || dataItem.lunNumber === '-1') {
               return '';
            }
            return dataItem.lunNumber;
         }
      }
   }
})();
