/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace h5_vapp {
   import MoveVAppService = h5_vapp.MoveVAppService;
   export interface Tab {
      iconClass: string;
      tooltipText: string;
      contentUrl: string;
   }

   export interface AlertMessage {
      text: string;
      type: string;
   }

   export class MoveVappViewComponent {

      public bindings: any;
      public controller: any;
      public templateUrl: string;

      constructor() {
         this.bindings = {
            objectIds: "<", // the selected objects for which the move will be done
            datacenter: "<", // the datacenter of the first vapp, will be used only
                             // for move of a single vapp
            owner: "<", // The owner of the VApp. Used for root of the move Vapp dialog.
                        // If the VApps have different owners, this property is null.
                        // If the vApps are under standlaone host the property holds not
                        // the owner but the host system itself.
            onSubmit: "=", // the method that is called by the clarity modal service
                           // when `Ok` is pressed
            submitDisabled: "=", // property of the modal dialog that show whether or not
                                 // `OK` button should be disabled
            alerts: "=", // property of the modal dialog, when initialized with data
                         // an alert will be shown
            closeModal: "<" // method of the modal dialog, when invoked the modal closes
         };
         this.controller = MoveVappController;
         this.templateUrl = "vapp-ui/resources/vapp/views/moveVapp/moveVappViewComponent.html";
      }
   }
   export class MoveVappController {
      public static $inject: string[] = [
            "$scope",
            "moveVAppService",
            "i18nService",
            "vuiConstants",
            "$element",
            "$timeout"
      ];

      public objectIds: string[];
      public datacenter: string;
      public rpOwner: string;
      public onSubmit: Function;
      public onTabSelected: Function;
      public submitDisabled: boolean;
      public actionMoveInstruction: boolean;
      public closeModal: Function;
      public tabOptions: any;
      public alerts: AlertMessage[];
      public loading: boolean = false;
      public selectedTabUrl: string;
      public loadingLabel: string;

      private _scope: any;
      private _i18nService: any;
      private _vuiConstants: any;
      private _moveVappService: MoveVAppService;

      private _targetId: {[key: string]: string};

      /**
       * Builds the initial state of the modal dialog content
       * - sets the `OK` button as disabled
       * - builds the tabOptions for the possible tabs with trees from which the target
       * could be selected
       * - initializes the onSubmit method that will be called after `OK` is pressed
       * @param $scope
       * @param moveVappService
       * @param i18nService
       * @param vuiConstants
       */
      public constructor($scope: any, moveVappService: MoveVAppService, i18nService: any,
            vuiConstants: any, private $element: any, private $timeout: any) {
         this._scope = $scope;
         this._moveVappService = moveVappService;
         this._i18nService = i18nService;
         this._vuiConstants = vuiConstants;

         // initialise the targets array
         this._targetId = {};

         // set the selected tab index;
         this.selectedTabUrl = "vapp_host_and_cluster_tree.html";

         // mark the `OK` button as disabled
         this.submitDisabled = true;

         // set a label for aria-label of the focused element when dialog is opened
         this.actionMoveInstruction = this._i18nService.getString("VAppUi", "actionMoveInstruction");

         this.loadingLabel = this._i18nService.getString("CommonUi", "wizard.loading");
         // initialize the possible tabs with trees from which the target could be selected
         this._scope.tabOptions = {
            tabType: this._vuiConstants.tabs.type.PRIMARY,
            tabs: this.getTabs()
         };
      }

      $onInit() {
         // mark the `OK` button as disabled
         this.submitDisabled = true;

         // initializes the onSubmit method that will be called after `OK` is pressed
         // it closes the modal and applies the move operation
         this.onSubmit = () => {
            this.submitDisabled = true;
            this.loading = true;
            let targetId = this._targetId[this.selectedTabUrl];
            this._moveVappService
                  .validateMove(this.objectIds, targetId).then((error: string) => {
               if (!error) {
                  this._moveVappService.applyMove(this.objectIds, targetId);
                  this.closeModal();
               } else {
                  let alert: AlertMessage = <AlertMessage> {
                     type: this._vuiConstants.validationBanner.type.ERROR,
                     text: error
                  };
                  this.alerts = [alert];
               }
               this.loading = false;
               this.submitDisabled = false;
            });
         };

         this.onTabSelected = (index: number): void => {
            let tabs = this.getTabs();
            this.selectedTabUrl = tabs[index].contentUrl;
         };

         this.$timeout(() => {
            const firstTab = this.$element[0].querySelector("button");
            if (firstTab) {
               firstTab.focus();
            }
         }, 0);
      }

      /**
       * Invoked when the selection in the tree is changed.
       * Marks the `OK` button as enabled, because now we have a selection in the tree
       * And saves the selected object id
       * @param objectId
       */
      public onLocationSelected(objectId: string, treeUrl: string): void {
         this.submitDisabled = false;
         this._targetId[treeUrl] = objectId;
      }

      /**
       * Builds an array of tabs that will contain the different types of trees
       * the tab's content (ex. vapp_folders_tree.html), should be defined
       * as a template in the component's template
       * @returns {Tab[]}
       */
      private getTabs(): Tab[] {
         const vappHostAndClusterTree: Tab = {
            iconClass: "vsphere-icon-cluster-24x",
            tooltipText: this._i18nService.getString("VAppUi", "actionMoveHostInventoryFolder"),
            contentUrl: "vapp_host_and_cluster_tree.html"
         };

         const vappFoldersTree: Tab = {
            iconClass: "vsphere-icon-vm-templates-24x",
            tooltipText: this._i18nService.getString("VAppUi", "actionMoveVmInventoryFolder"),
            contentUrl: "vapp_folders_in_datacenter_tree.html"
         };

         return [vappHostAndClusterTree, vappFoldersTree];
      }
   }

   angular.module("com.vmware.vsphere.client.vapp")
         .component("moveVappView", new MoveVappViewComponent());
}
