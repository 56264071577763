(function () {
   'use strict';
   angular.module('com.vmware.vsphere.client.network').controller(
         'VmKernelAdaptersViewController', VmKernelAdaptersViewController);

   VmKernelAdaptersViewController.$inject = ['i18nService',
      '$scope',
      'vuiConstants',
      'vmKernelAdapterService',
      '$q',
      'networkUiConstants',
      'actionToolbarFactory'];

   function VmKernelAdaptersViewController(i18nService, $scope, vuiConstants,
         vmKernelAdapterService, $q, networkUiConstants,
         actionToolbarFactory) {
      var virtualAdaptersDataProperty = "vnic:virtualAdaptersData";
      var self = this;
      self.liveRefreshProperties = [
         "config.network.vnic",
         "config.virtualNicManagerInfo.netConfig",
         "config.network.vswitch",
         "config.network.proxySwitch",
         "config.network.opaqueSwitch",
         "config.network.netStackInstance",
         "config.network.portgroup"
      ];
      var evaluationContext = { vnic: null };
      var actionAvailabilityById = {};

      self.hostId = $scope._route.objectId;
      self.headerOptions = {
         title: i18nService.getString('NetworkUi', 'host.manage.vnicHeader'),
         objectId: self.hostId,
         actions: []
      };
      self.splitterOptions = {
         orientation: vuiConstants.splitter.orientation.VERTICAL,
         panes: [
            {
               min: '150px',
               size: '45%'
            }, {
               min: '250px',
               size: '55%'
            }
         ]
      };
      self.vmkernelAdaptersLoading = false;
      self.ingnoreNextItemSelection = false;

      $scope.preselectComparator = function (gridDataItem) {
         return gridDataItem.device === $scope.lastSelectedVmKernelAdapterDevice;
      };

      self.refresh = function () {
         self.vmkernelAdaptersLoading = true;

         var showServiceColumns = true;
         var gridLoadedPromise = vmKernelAdapterService.getVnics(self.hostId,
               showServiceColumns);

         var requestActionsPromise = requestActions();

         $q.all([gridLoadedPromise, requestActionsPromise]).then(function (results) {
            var vnicsResponse = results[0];

            var vnicItems = vnicsResponse[virtualAdaptersDataProperty]
                  ? vnicsResponse[virtualAdaptersDataProperty].items
                  : [];
            if (!self.datagridOptions) {
               self.datagridOptions =
                     createDatagridOptions(vnicItems, vnicsResponse.vnicColumnDefs);
            } else {
               self.datagridOptions.data = vnicItems;
            }

            // actions response
            self.datagridOptions.actionBarOptions = results[1];

            _.forEach(self.datagridOptions.actionBarOptions.actions, function (action) {
               if (action !== vuiConstants.actions.SEPARATOR) {
                  actionAvailabilityById[action.actionId] = action.enabled;
               }
            });

            // When the last selected adapter was deleted (not present in refreshed
            // data), the details view should be removed.
            if (wasLastSelectedVnicRemoved(vnicItems)) {
               $scope.lastSelectedVmKernelAdapterDevice = null;
            }

            // When the datagridOptions.data is refreshed, the watch for the selectedItems
            // is called with an empty array before the grid preselection directive tries
            // to preselect the last selected item. This flag is used to skip the first
            // trigger of the watch when the data is refreshed.
            self.ingnoreNextItemSelection = true;

            self.vmkernelAdaptersLoading = false;

         }, function () {
            if (self.datagridOptions) {
               self.datagridOptions.data = [];
            }
            self.vmkernelAdaptersLoading = false;
         });
      };

      self.refresh();

      function requestActions() {
         var vnicActions = [
            networkUiConstants.actions.ADD_NETWORKING,
            networkUiConstants.actions.REFRESH_NETWORK,
            vuiConstants.actions.SEPARATOR,
            networkUiConstants.actions.EDIT_VNIC,
            networkUiConstants.actions.REMOVE_VNIC];

         return actionToolbarFactory.createActionbarOptions([$scope._route.objectId],
               vnicActions, evaluationContext);
      }

      // Toggles the enabled/disabled flag of those actions that depend on some item's
      // selection (e.g. Edit, Remove, etc..)
      function toggleActionsAvailability() {
         self.datagridOptions.actionBarOptions.actions.forEach(function(action) {
            if (action !== vuiConstants.actions.SEPARATOR) {
               action.enabled = actionAvailabilityById[action.actionId] &&
                     (self.datagridOptions.selectedItems.length > 0 ||
                     !isSelectionDependingAction(action));
            }
         });
      }

      function isSelectionDependingAction(action) {
         return action.actionId === networkUiConstants.actions.EDIT_VNIC ||
               action.actionId === networkUiConstants.actions.REMOVE_VNIC;
      }

      function wasLastSelectedVnicRemoved(vnicItems) {
         return !_.some(vnicItems, function (vnicItem) {
            return vnicItem.device === $scope.lastSelectedVmKernelAdapterDevice;
         });
      }

      function onSelectionChange(selectedItems) {
         toggleActionsAvailability();
         if (self.ingnoreNextItemSelection) {
            self.ingnoreNextItemSelection = false;
            return;
         }

         if (selectedItems && selectedItems.length > 0) {
            // User selection or data grid pre-selection.
            self.adapterDetailsHeader = i18nService.getString(
                  'NetworkUi',
                  'VnicDetailsView.title',
                  selectedItems[0].device);
            $scope.lastSelectedVmKernelAdapterDevice =
                  selectedItems[0].device;
            evaluationContext.vnic = selectedItems[0];
         } else {
            evaluationContext.vnic = null;
            $scope.lastSelectedVmKernelAdapterDevice = null;
         }
      }

      function createDatagridOptions(data, columnDefs) {
         return {
            height: '100%',
            pageConfig: {
               hidePager: true
            },
            columnDefs: columnDefs,
            sortMode: vuiConstants.grid.sortMode.SINGLE,
            selectionMode: vuiConstants.grid.selectionMode.SINGLE,
            data: data,
            selectedItems: [],
            resizable: true,
            actionBarOptions: {actions: []},
            onChange: onSelectionChange
         };
      }
   }
})();
