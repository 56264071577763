module hostprofile_ui {

   import ReadHostCustomizationsSpec = com.vmware.vsphere.client.hostprofile.data.h5.ReadHostCustomizationsSpec;
   import ManagedObjectReference = com.vmware.vim.binding.vmodl.ManagedObjectReference;
   import IPromise = angular.IPromise;

   export interface HostCustomizationsService {
      getHostCustomizations(targetId: string, spec: ReadHostCustomizationsSpec): angular.IPromise<any>;
      getAssociatedHostProfile(targetId: string): angular.IPromise<any>;
      updateHostCustomizations(targetId: string, hostProfileMoRef: ManagedObjectReference,
            hostCustomizations: HostCustomizationItem[]): angular.IPromise<any>;
      getCustomizationsHosts(hostCustomizations: HostCustomizationItem[]): ManagedObjectReference[];
      filterCustomizations(searchTerm: string, data: HostCustomizationItem[]):  HostCustomizationItem[];
   }

   class HostCustomizationsServiceImpl implements HostCustomizationsService {
      static $inject = ["mutationService", "dataService"];

      constructor(private mutationService: any, private dataService: any) {
      }

      public getAssociatedHostProfile(targetId: string): angular.IPromise<any> {
         return this.dataService.getProperties(targetId, ["associatedHostProfile"]);
      }

      public getHostCustomizations(targetId: string, spec: ReadHostCustomizationsSpec): angular.IPromise<any> {
         return this.mutationService.applyOnMultiEntity(
               [targetId],
               "com.vmware.vsphere.client.hostprofile.data.h5.ReadHostCustomizationsSpec",
               spec,
               "OP_IGNORE_ERROR"
         );
      }

      public updateHostCustomizations(targetId: string,
            hostProfileMoRef: ManagedObjectReference,
            hostCustomizations: Array<HostCustomizationItem>): angular.IPromise<any> {

         return this.mutationService.applyOnMultiEntity(
               [targetId],
               "com.vmware.vsphere.client.hostprofile.data.h5.UpdateHostCustomizationsSpec",
               {
                  hostProfile: hostProfileMoRef,
                  currentHostCustomizations: hostCustomizations
               }
         );
      }

      public resetHostCustomizations(hostId: string): void {
         return this.mutationService.applyOnMultiEntity(
               [hostId],
               "com.vmware.vsphere.client.hostprofile.data.ResetHostCustomizationsSpec",
               {}
         );
      }

      public getCustomizationsHosts(hostCustomizations: HostCustomizationItem[]): ManagedObjectReference[] {
         let sortedHosts: ManagedObjectReference[] = _.pluck(hostCustomizations, "hostMor")
               .sort((a: ManagedObjectReference, b: ManagedObjectReference) => {
                  return a.value.localeCompare(b.value);
         });

         return _.uniq(sortedHosts, true, (item: ManagedObjectReference) => {
            return item.value;
         });
      }

      public filterCustomizations(searchTerm: string, data: HostCustomizationItem[]): HostCustomizationItem[] {
         const search: string = searchTerm.toLowerCase();

         return data.filter(function (item: HostCustomizationItem) {
            const requiredValue: string = item.requiredValue.toLowerCase();
            const propertyName: string = item.name.toLowerCase();
            const path: string = item.description.toLowerCase();
            const value: string = item.value.toLowerCase();

            return requiredValue.indexOf(search) !== -1 ||
                  propertyName.indexOf(search) !== -1 ||
                  path.indexOf(search) !== -1 ||
                  value.indexOf(search) !== -1;
         });
      }
   }

   angular.module("com.vmware.vsphere.client.hostprofile").service("hostCustomizationsService",
         HostCustomizationsServiceImpl);
}
