namespace dvs_ui {

   export class DvsSpanSessionEditableListValidator {

      public static $inject = [
         "i18nService",
         "ipParserService",
         "networkUtil"
      ];

      constructor(private i18nService: any,
                  private ipParserService: any,
                  private networkUtil: any) {

      }

      public getIpAddressError(address: string): string | null {
         let trimmedAddress = address.trim();

         let isEmpty: boolean = !trimmedAddress.length;
         let isInvalid: boolean = !this.ipParserService.isIpv4AddressValid(trimmedAddress)
               && !this.ipParserService.isIpv6AddressValid(trimmedAddress);

         if (isEmpty || isInvalid) {
            return this.i18nService.getString("DvsUi", "SpanSession.error.ipAddress");
         }

         return null;
      }

      public getVlanIdError(vlanId: string): string | null {
         let isValid: boolean = this.networkUtil.isPositiveInteger(vlanId,
               DvsSpanSessionConstants.VLAN_ID_MIN,
               DvsSpanSessionConstants.VLAN_ID_MAX);

         return isValid ? null : this.i18nService.getString("DvsUi",
               "AddSpanSessionWizard.sourceVlansPage.invalidVlanIdError",
               DvsSpanSessionConstants.VLAN_ID_MIN,
               DvsSpanSessionConstants.VLAN_ID_MAX);
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .service("dvsSpanSessionEditableListValidator", DvsSpanSessionEditableListValidator);
}
