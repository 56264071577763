namespace cluster_ui {

   import IPromise = angular.IPromise;

   export class ClusterVmOverridesPageService {
      static $inject: string[] = ["vuiClusterAddVmOverridesWizard", "i18nService",
         "mutationService",
         "defaultUriSchemeUtil",
         "clusterVmOverridesConstants",
         "clusterSpecBuilderService",
         "haVmMonitoringService",
         "drsConstants",
         "haConstants",
         "dataService"];

      constructor(private vuiClusterAddVmOverridesWizard: VuiClusterAddVmOverridesWizard,
                  private i18nService: any,
                  private mutationService: any,
                  private defaultUriSchemeUtil: any,
                  private clusterVmOverridesConstants: any,
                  private clusterSpecBuilderService: any,
                  private haVmMonitoringService: any,
                  private drsConstants: any,
                  private haConstants: any,
                  private dataService: any) {
      }

      public buildSelectVmsPage(wizardScope: any): any {
         return {
            title: this.i18nService.getString("ClusterUi", "vm.overrides.config.vm.selection.title"),
            contentUrl: "cluster-ui/resources/cluster/views/settings/configuration/overrides/selectClusterVmOverrideMemberView.html",
            onCommit: () => {
               let selectedVms: any = wizardScope.manager.getSelectedVms();
               if (selectedVms && selectedVms.length > 0) {
                  this.vuiClusterAddVmOverridesWizard.markPageComplete();
                  return true;
               } else {
                  this.vuiClusterAddVmOverridesWizard.markPageIncompleteWithError(
                        this.i18nService.getString(
                              "ClusterUi", "vm.overrides.selection.error.title"));
                  return false;
               }

            },
            disableNext: () => {
               return wizardScope.wizardConfig.loading;
            }
         };
      }

      public buildAddVmOverridesPage(wizardScope: any): any {
         return {
            title: this.i18nService.getString("ClusterUi", "vm.overrides.config.add.page.title"),
            contentUrl: "cluster-ui/resources/cluster/views/settings/configuration/overrides/selectClusterVmOverridesView.html",
            onCommit: () => {
               let objectId: string = wizardScope.manager.getClusterId();
               let vmsSettingsSpec: any = wizardScope.manager.getVmSettingsSpec();
               let selectedVmsSpec: any = wizardScope.manager.getSelectedVmsSpec();
               let isValidationSuccessful = false;
               if (this.hasOverrides(vmsSettingsSpec)) {
                  let vms: any = _.map(selectedVmsSpec.vms, (vmRow: any) => {
                     return {
                        skipFromOrchestrationSpec : vmRow.skipFromOrchestrationSpec,
                        arrayOperation: vmRow.arrayOperation,
                        key: this.defaultUriSchemeUtil.getManagedObjectReference(vmRow.id),
                        haOverrides: {
                           overridesHaOptions: false
                        },
                        drsOverrides: {
                           overridesDrsOptions: false,
                           drsAutomationLevelEditable: false
                        }
                     };
                  });

                  this.applyOverrides(objectId, vms, vmsSettingsSpec);

                  this.vuiClusterAddVmOverridesWizard.markPageComplete();
                  isValidationSuccessful = true;
               } else {
                  this.vuiClusterAddVmOverridesWizard.markPageIncompleteWithError(
                        this.i18nService.getString(
                              "ClusterUi", "vm.overrides.config.noChangesValidationError"));

               }

               return isValidationSuccessful;
            },
            finishReady: () => {
               return !wizardScope.wizardConfig.loading;
            }
         };
      }

      public applyOverrides(objectId: string, vms: any[], vmSettingsSpec: any): void {
         let clusterSpecData: any = {};

         if (vmSettingsSpec.drsAutomationOverrideEnabled) {
            _.each(vms, (vm: any) => {
               let drsVmOverrideSpec: any = this.buildDrsVmOverridesSpec(
                     vmSettingsSpec.drsAutomationLevel, vm);

               if (drsVmOverrideSpec !== null) {
                  if (angular.isUndefined(clusterSpecData.drsVmOverride)) {
                     clusterSpecData.drsVmOverride = [];
                  }

                  clusterSpecData.drsVmOverride.push(drsVmOverrideSpec);
               }
            });
         } else {
            // build spec to remove the DRS override if it previously existed and
            // now we are setting it to cluster default
            _.each(vms, (vm: any) => {
               if (vm.drsOverrides.overridesDrsOptions &&
                     vm.drsOverrides.drsAutomationLevelEditable) {
                  if (angular.isUndefined(clusterSpecData.drsVmOverride)) {
                     clusterSpecData.drsVmOverride = [];
                  }
                  clusterSpecData.drsVmOverride.push(
                        this.buildRemoveDrsVmOverridesSpec(vm));
               }
            });
         }

         _.each(vms, (vm: any) => {
            if (vmSettingsSpec.haRestartPriorityEnabled ||
                  vmSettingsSpec.haRestartPriorityTimeoutEnabled ||
                  vmSettingsSpec.haIsolationResponseEnabled ||
                  vmSettingsSpec.haPdlProtectionEnabled ||
                  vmSettingsSpec.haApdProtectionEnabled ||
                  vmSettingsSpec.haApdDelayEnabled ||
                  vmSettingsSpec.haApdVmRecoveryEnabled ||
                  vmSettingsSpec.haVmMonitoringEnabled || (vmSettingsSpec.mode ===
                  this.clusterVmOverridesConstants.operationType.EDIT &&
                  vm.haOverrides.overridesHaOptions)) {
               // build spec to submit only if some of the HA settings are overridden
               // or we are in edit mode and there previously used to have override that
               // has to be removed (set to clusterDefault)
               if (angular.isUndefined(clusterSpecData.dasVmOverride)) {
                  clusterSpecData.dasVmOverride = [];
               }

               clusterSpecData.dasVmOverride.push(
                     this.buildDasVmOverridesSpec(vmSettingsSpec, vm));
            }
         });

         // if both ready condition and post ready delay are disabled, then delete
         // this override
         let orchestrationVmOverrideSpec: Array<any>;
         if (!vmSettingsSpec.haRestartOrchestrationEnabled && !vmSettingsSpec.haRestartOrchestrationAdditionalDelayEnabled) {
            orchestrationVmOverrideSpec = this.buildRemoveVmOrchestrationSpec(vms, clusterSpecData);
         } else {
            orchestrationVmOverrideSpec = this.buildAddVmOrchestrationSpec(vms, vmSettingsSpec);
         }

         if (orchestrationVmOverrideSpec && orchestrationVmOverrideSpec.length > 0) {
            if (angular.isUndefined(clusterSpecData.orchestrationVmOverride)) {
               clusterSpecData.orchestrationVmOverride = [];
            }

            clusterSpecData.orchestrationVmOverride = orchestrationVmOverrideSpec;
         }

         let clusterSpec = this.clusterSpecBuilderService
               .buildClusterComputeResourceSpec(clusterSpecData, true);

         this.mutationService.apply(objectId,
               "com.vmware.vsphere.client.cluster.ClusterComputeResourceSpec",
               clusterSpec);
      }


      public hasOverrides(vmSettingsSpec: any): boolean {
         return vmSettingsSpec.drsAutomationOverrideEnabled ||
               vmSettingsSpec.haRestartPriorityTimeoutEnabled ||
               vmSettingsSpec.haRestartOrchestrationEnabled ||
               vmSettingsSpec.haRestartOrchestrationAdditionalDelayEnabled ||
               vmSettingsSpec.haIsolationResponseEnabled ||
               vmSettingsSpec.haPdlProtectionEnabled ||
               vmSettingsSpec.haApdProtectionEnabled ||
               vmSettingsSpec.haApdDelayEnabled ||
               vmSettingsSpec.haApdVmRecoveryEnabled ||
               vmSettingsSpec.haVmMonitoringEnabled;
      }


      public populateVmFtRole(vms: any[]): IPromise<any> {
         let objIds: any[] = _.pluck(vms, "id");

         return this.dataService.getPropertiesForObjects(
               objIds, ["ftRole", "isVmcpSupportedForVm"])
               .then((result: any) => {
                  _.each(vms, (vmObject: any) => {
                     vmObject.vm = {
                        ftVm: !_.isNull(result[vmObject.id].ftRole),
                        isVmcpSupportedForVm: result[vmObject.id].isVmcpSupportedForVm
                     };
                  });

                  return vms;
               });
      }

      public buildDrsVmOverridesSpec(drsAutomationLevel: any, vm: any): any {
         let drsAutomationEnabled: boolean = true;
         if (this.isInitalValue(drsAutomationLevel)) {
            if (this.isClusterDefault(vm.drsOverrides.drsAutomationLevel)) {
               return null;
            } else {
               drsAutomationLevel = vm.drsOverrides.drsAutomationLevel;
            }
         }

         if (drsAutomationLevel === this.drsConstants.AUTOMATION_LEVEL_DISABLED) {
            drsAutomationEnabled = false;
            drsAutomationLevel = null;
         }

         let vmOverrideInfo: any = {
            _type: "com.vmware.vim.binding.vim.cluster.DrsVmConfigInfo",
            key: vm.key,
            behavior: drsAutomationLevel,
            enabled: drsAutomationEnabled
         };

         // use either add or edit operation depending if there was an DRS override initially
         let operation: string = vm.drsOverrides.overridesDrsOptions ?
               this.clusterVmOverridesConstants.operationType.EDIT :
               this.clusterVmOverridesConstants.operationType.ADD;

         return {
            _type: this.clusterVmOverridesConstants.overrideSpec.DRS_VM_CONFIG_SPEC,
            info: vmOverrideInfo,
            operation: operation
         };
      }

      public buildRemoveDrsVmOverridesSpec(vm: any): Object {
         return {
            _type: this.clusterVmOverridesConstants.overrideSpec.DRS_VM_CONFIG_SPEC,
            removeKey: vm.key,
            operation: this.clusterVmOverridesConstants.operationType.REMOVE
         };
      }

      public buildDasVmOverridesSpec(vmSettingsSpec: any, vm: any): Object {
         let vmOverrideInfo: any = {
            _type: "com.vmware.vim.binding.vim.cluster.DasVmConfigInfo",
            key: vm.key
         };

         vmOverrideInfo.dasSettings = {
            _type: "com.vmware.vim.binding.vim.cluster.DasVmSettings"
         };

         if (vmSettingsSpec.haRestartPriorityEnabled) {
            if (this.isInitalValue(vmSettingsSpec.haRestartPriority)) {
               vmOverrideInfo.dasSettings.restartPriority =
                     vm.haOverrides.haRestartPriority;
            } else {
               vmOverrideInfo.dasSettings.restartPriority =
                     vmSettingsSpec.haRestartPriority;
            }
         } else {
            vmOverrideInfo.dasSettings.restartPriority =
                  this.clusterVmOverridesConstants.RESTART_PRIORITY_CLUSTER_DEFAULT;
         }

         if (vmSettingsSpec.haRestartPriorityTimeoutEnabled) {
            if (this.isInitalValue(vmSettingsSpec.haRestartPriorityTimeout)) {
               vmOverrideInfo.dasSettings.restartPriorityTimeout =
                     vm.haOverrides.haRestartPriorityTimeout;
            } else {
               vmOverrideInfo.dasSettings.restartPriorityTimeout =
                     vmSettingsSpec.haRestartPriorityTimeout;
            }
         } else {
            vmOverrideInfo.dasSettings.restartPriorityTimeout =
                  this.clusterVmOverridesConstants.APD_DELAY_CLUSTER_DEFAULT;
         }

         if (vmSettingsSpec.haIsolationResponseEnabled) {
            if (this.isInitalValue(vmSettingsSpec.haIsolationResponse)) {
               vmOverrideInfo.dasSettings.isolationResponse =
                     vm.haOverrides.haIsolationResponse;
            } else {
               vmOverrideInfo.dasSettings.isolationResponse =
                     vmSettingsSpec.haIsolationResponse;
            }
         } else {
            vmOverrideInfo.dasSettings.isolationResponse =
                  this.clusterVmOverridesConstants.ISOLATION_RESPONSE_CLUSTER_DEFAULT;
         }

         this.buildVmcpSettingsSpec(vmSettingsSpec, vmOverrideInfo, vm);
         this.buildVmMonitoringSettingsSpec(vmSettingsSpec, vmOverrideInfo, vm);

         // use either add or edit operation depending if there was an HA override initially
         let operation: string = vm.haOverrides.overridesHaOptions ?
               this.clusterVmOverridesConstants.operationType.EDIT :
               this.clusterVmOverridesConstants.operationType.ADD;

         return {
            _type: this.clusterVmOverridesConstants.overrideSpec.DAS_VM_CONFIG_SPEC,
            info: vmOverrideInfo,
            operation: operation
         };
      }

      public isClusterDefault(propertyValue: string): boolean {
         return propertyValue === this.clusterVmOverridesConstants.CLUSTER_DEFAULT ||
               propertyValue === this.clusterVmOverridesConstants.OVERRIDE_NA ||
               propertyValue === this.clusterVmOverridesConstants.RESTART_PRIORITY_CLUSTER_DEFAULT ||
               propertyValue === this.clusterVmOverridesConstants.ISOLATION_RESPONSE_CLUSTER_DEFAULT ||
               propertyValue === this.clusterVmOverridesConstants.RESPONSE_RECOVERY_CLUSTER_DEFAULT;
      }

      private isInitalValue(value: string): boolean {
         return value === this.clusterVmOverridesConstants.INITIAL_VALUES ||
               value === this.i18nService.getString("ClusterUi",
                     "vm.overrides.config.editMultiple.useInitialValues");
      }

      private buildAddVmOrchestrationSpec(vms: Array<any>, vmSettingsSpec: any): Array<any> {
        let result: Array<any> = [];
         _.each(vms, (vm: any) => {
            let vmOrchestrationSpec: any = {
               _type: this.clusterVmOverridesConstants.overrideSpec.ORCH_VM_CONFIG_SPEC,
               operation: vm.arrayOperation,
               info: {
                  _type: this.clusterVmOverridesConstants.overrideSpec.ORCH_VM_INFO_SPEC,
                  vm: vm.key,
                  vmReadiness: {
                     _type: this.clusterVmOverridesConstants.overrideSpec.ORCH_VM_READINESS_SPEC
                  }
               }
            };

            // overwrite value only if not set to useInitialValues
            if (!this.isInitalValue(vmSettingsSpec.restartOrchestration.readyCondition)) {
               _.extend(vmOrchestrationSpec.info.vmReadiness, {
                  readyCondition: vmSettingsSpec.haRestartOrchestrationEnabled ?
                        vmSettingsSpec.restartOrchestration.readyCondition :
                        "useClusterDefault"
               });
            }

            // overwrite value only if not set to useInitialValues
            if (!this.isInitalValue(vmSettingsSpec.restartOrchestration.postReadyDelay)) {
               _.extend(vmOrchestrationSpec.info.vmReadiness, {
                  postReadyDelay: vmSettingsSpec.restartOrchestration.postReadyDelay});
            }

            // execute mutation only if either readyCondition or postReadyDelay
            // are changed from useInitialValues
            if (vmOrchestrationSpec.info.vmReadiness.readyCondition ||
                  vmOrchestrationSpec.info.vmReadiness.postReadyDelay) {
               result.push(vmOrchestrationSpec);
            }
         });

         return result;
      }

      private buildRemoveVmOrchestrationSpec(vms: Array<any>, clusterSpecData: any): Array<any> {
         let result: Array<any> = [];
         _.each(vms, (vm: any) => {
            if (!vm.skipFromOrchestrationSpec &&
                  vm.arrayOperation === this.clusterVmOverridesConstants.operationType.REMOVE) {
               result.push({
                  _type: this.clusterVmOverridesConstants.overrideSpec.ORCH_VM_CONFIG_SPEC,
                  removeKey: vm.key,
                  operation: this.clusterVmOverridesConstants.operationType.REMOVE
               });
            }
         });

         return result;
      }

      private buildVmcpSettingsSpec(vmSettingsSpec: any, vmOverrideInfo: any, vm: any): void {
         if (vmSettingsSpec.haPdlProtectionEnabled ||
               vmSettingsSpec.haApdProtectionEnabled ||
               vmSettingsSpec.haApdDelayEnabled ||
               vmSettingsSpec.haApdVmRecoveryEnabled || vmSettingsSpec.mode ===
               this.clusterVmOverridesConstants.operationType.EDIT) {

            vmOverrideInfo.dasSettings.vmComponentProtectionSettings = {
               _type: "com.vmware.vim.binding.vim.cluster.VmComponentProtectionSettings",
            };

            if (vmSettingsSpec.haPdlProtectionEnabled) {
               if (this.isInitalValue(vmSettingsSpec.haPdlProtection)) {
                  vmOverrideInfo.dasSettings.vmComponentProtectionSettings
                        .vmStorageProtectionForPDL =
                        vm.haOverrides.pdlProtectionLevel;
               } else {
                  vmOverrideInfo.dasSettings.vmComponentProtectionSettings
                        .vmStorageProtectionForPDL = vmSettingsSpec.haPdlProtection;
               }
            } else {
               vmOverrideInfo.dasSettings.vmComponentProtectionSettings
                     .vmStorageProtectionForPDL =
                     this.clusterVmOverridesConstants.CLUSTER_DEFAULT;
            }

            if (vmSettingsSpec.haApdProtectionEnabled) {
               if (this.isInitalValue(vmSettingsSpec.haApdProtection)) {
                  vmOverrideInfo.dasSettings.vmComponentProtectionSettings
                        .vmStorageProtectionForAPD =
                        vm.haOverrides.apdProtectionLevel;
               } else {
                  vmOverrideInfo.dasSettings.vmComponentProtectionSettings
                        .vmStorageProtectionForAPD = vmSettingsSpec.haApdProtection;
               }
            } else {
               vmOverrideInfo.dasSettings.vmComponentProtectionSettings
                     .vmStorageProtectionForAPD =
                     this.clusterVmOverridesConstants.CLUSTER_DEFAULT;
            }

            if (vmSettingsSpec.haApdDelayEnabled) {
               if (this.isInitalValue(vmSettingsSpec.haApdDelay)) {
                  vmOverrideInfo.dasSettings.vmComponentProtectionSettings
                        .vmTerminateDelayForAPDSec =
                        vm.haOverrides.intervalBeforeVmcpReaction *
                        this.haConstants.SECONDS_IN_A_MINUTE;
               } else {
                  vmOverrideInfo.dasSettings.vmComponentProtectionSettings
                        .vmTerminateDelayForAPDSec = vmSettingsSpec.haApdDelay *
                        this.haConstants.SECONDS_IN_A_MINUTE;
               }
            } else {
               vmOverrideInfo.dasSettings.vmComponentProtectionSettings
                     .vmTerminateDelayForAPDSec =
                     this.clusterVmOverridesConstants.APD_DELAY_CLUSTER_DEFAULT;
            }

            if (vmSettingsSpec.haApdVmRecoveryEnabled) {
               if (this.isInitalValue(vmSettingsSpec.haApdVmRecovery)) {
                  vmOverrideInfo.dasSettings.vmComponentProtectionSettings
                        .vmReactionOnAPDCleared =
                        vm.haOverrides.vmRemediationOnApdRecover;
               } else {
                  vmOverrideInfo.dasSettings.vmComponentProtectionSettings
                        .vmReactionOnAPDCleared = vmSettingsSpec.haApdVmRecovery;
               }
            } else {
               vmOverrideInfo.dasSettings.vmComponentProtectionSettings
                     .vmReactionOnAPDCleared =
                     this.clusterVmOverridesConstants.RESPONSE_RECOVERY_CLUSTER_DEFAULT;
            }
         }
      }

      private buildVmMonitoringSettingsSpec(vmSettingsSpec: any, vmOverrideInfo: any,
                                            vm: any): void {
         if (vmSettingsSpec.haVmMonitoringEnabled || vmSettingsSpec.mode ===
               this.clusterVmOverridesConstants.operationType.EDIT) {
            vmOverrideInfo.dasSettings.vmToolsMonitoringSettings = {
               _type: "com.vmware.vim.binding.vim.cluster.VmToolsMonitoringSettings",
            };

            if (vmSettingsSpec.haVmMonitoringEnabled) {
               vmOverrideInfo.dasSettings.vmToolsMonitoringSettings.clusterSettings =
                     false;

               this.setVmMonitoringSettings(vmSettingsSpec, vmOverrideInfo, vm);
            } else {
               vmOverrideInfo.dasSettings.vmToolsMonitoringSettings.clusterSettings =
                     true;
            }
         }
      }

      private setVmMonitoringSettings(vmSettingsSpec: any, vmOverrideInfo: any, vm: any): void {
         if (this.isInitalValue(vmSettingsSpec.haVmMonitoring)) {
            if (this.isClusterDefault(vm.haOverrides.vmMonitoring)) {
               vmOverrideInfo.dasSettings.vmToolsMonitoringSettings.clusterSettings =
                     true;
               return;
            } else {
               vmOverrideInfo.dasSettings.vmToolsMonitoringSettings.vmMonitoring =
                     vm.haOverrides.vmMonitoring;
            }
         } else {
            vmOverrideInfo.dasSettings.vmToolsMonitoringSettings.vmMonitoring =
                  vmSettingsSpec.haVmMonitoring;
         }

         this.setVmMonitoringSensitivitySettings(vmSettingsSpec, vmOverrideInfo, vm);
      }

      private setVmMonitoringSensitivitySettings(vmSettingsSpec: any,
                                                 vmOverrideInfo: any, vm: any): void {
         if (this.isInitalValue(vmSettingsSpec.haVmMonitoringSensitivity)) {
            this.setVmMonitoringSettingsValues(vmOverrideInfo,
                  vm.haOverrides.vmFailureIntervalValue,
                  vm.haOverrides.vmMinUptimeValue,
                  vm.haOverrides.vmMaxFailuresValue,
                  vm.haOverrides.vmMaxFailureWindowValue
            );
         } else if (vmSettingsSpec.haVmMonitoringSensitivity ===
               this.haVmMonitoringService.getPresetLevels().CUSTOM) {

            let vmFailureWindow: number = vmSettingsSpec.vmToolsMonitorHasFailureWindow ?
            vmSettingsSpec.vmToolsMonitorMaxFailureWindow * 3600 :
                  this.haConstants.vmMonitoringSensitivity.NO_FAILURE_WINDOW;

            this.setVmMonitoringSettingsValues(vmOverrideInfo,
                  vmSettingsSpec.vmToolsMonitorFailureInt,
                  vmSettingsSpec.vmToolsMonitorMinUpTime,
                  vmSettingsSpec.vmToolsMonitorMaxFailures,
                  vmFailureWindow
            );
         } else {
            // get values for failure int, min uptime, max failures and failure window
            // corresponding to sensitivity level
            let sensitivityLevelValues: any =
                  this.haVmMonitoringService.getSensitivityLevelValues(
                        vmSettingsSpec.haVmMonitoringSensitivity);

            this.setVmMonitoringSettingsValues(vmOverrideInfo,
                  sensitivityLevelValues.failure_interval,
                  sensitivityLevelValues.min_uptime,
                  sensitivityLevelValues.max_failures,
                  sensitivityLevelValues.failure_window * 3600
            );
         }
      }

      private setVmMonitoringSettingsValues(vmOverrideInfo: any, failureInterval: number,
                                            minUpTime: number, maxFailures: number, maxFailureWindow: number): void {
         vmOverrideInfo.dasSettings.vmToolsMonitoringSettings.failureInterval =
               failureInterval;
         vmOverrideInfo.dasSettings.vmToolsMonitoringSettings.minUpTime = minUpTime;
         vmOverrideInfo.dasSettings.vmToolsMonitoringSettings.maxFailures = maxFailures;
         vmOverrideInfo.dasSettings.vmToolsMonitoringSettings.maxFailureWindow =
               maxFailureWindow;
      }

   }

   angular.module("com.vmware.vsphere.client.cluster")
         .service("clusterVmOverridesPageService", ClusterVmOverridesPageService);
}


