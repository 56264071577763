namespace h5_vm {

/**
 * A 'builder' for the SelectComputeResourceTreePage.
 */
export class VmMigrationSelectComputeResourceTreePageService {
   static $inject: string[] = [
      "i18nService",
      "wizardPageService",
      "vmCryptUtilService",
   ];

   constructor(private i18nService: any, private wizardPageService: any, private vmCryptUtilService: VmCryptUtilService) {
   }

   public build(wizardScope: any) {
      return {
         title: this.i18nService.getString(
               "VmUi", "MigrationWizard.resourceListPage.title"),
         description: this.i18nService.getString(
               "VmUi", "MigrationWizard.resourceListPage.description"),
         contentUrl: "vm-ui/resources/vm/views/migrationWizard/pages/selectComputeResourceTreePage.html",
         headerUrl: "vm-ui/resources/vm/views/migrationWizard/pages/migratePagesCustomHeader.html",
         disableNext: function() {
            return wizardScope.migrationWizardManager.computeResourceTreePageModel.isValidating ||
                  !wizardScope.migrationWizardManager.computeResourceTreePageModel.resource;
         },
         onCommit: () => {
            return this.onCommit(wizardScope);
         }
      };
   }

   private onCommit(wizardScope: any): boolean {
      if (!wizardScope.migrationWizardManager.hasValidComputeResourceSelected()) {
         this.wizardPageService.markPageIncompleteWithError(
               wizardScope.wizardConfig,
               this.i18nService.getString(
                     "VmUi", "MigrationWizard.resourceListPage.error.invalid")
         );

         return false;
      }

      this.vmCryptUtilService.isVmCryptPolicyDisabledForMigrateWizard(
            wizardScope.migrationWizardManager.computeResourceTreePageModel.resource,
            wizardScope.migrationWizardManager.storagePageModel);

      wizardScope.wizardConfig.validationBanner.messages = [];
      this.wizardPageService.markPageComplete(wizardScope.wizardConfig,
            wizardScope.attributes.creationType);
      return true;
   }
} // class

angular.module("com.vmware.vsphere.client.vm").service(
      "vmMigrationSelectComputeResourceTreePageService",
      VmMigrationSelectComputeResourceTreePageService);

} // namespace
