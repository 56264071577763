/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace dvs_ui {

   export class DvsOutOfSyncHostService {

      public static $inject = [
            "clarityModalService",
            "i18nService",
            "vxPropertyViewService",
            "columnRenderersRegistry",
            "defaultUriSchemeUtil"
      ];

      constructor(
            private clarityModalService: any,
            private i18nService: any,
            private vxPropertyViewService: any,
            private columnRenderersRegistry: any,
            private defaultUriSchemeUtil: any) {

      }

      public showDetails(hostOutOfSyncData: any[], description: string, entityId: string): void {
         let modalOptions:any = {
            title: this.i18nService.getString("DvsUi", "OutOfSyncDvsHost.details"),
            subTitle: {
               objectId: entityId
            },
            size: "lg",
            defaultButton: "close",
            closeButtonTitle: this.i18nService.getString("DvsUi", "OutOfSyncDvsHost.close"),
            hideSubmitButton: true,
            alerts: [],
            dialogData: {
               desc: description,
               dvsOutOfSyncHostData: this.buildHostOutOfSyncPropertiesView(hostOutOfSyncData)
            },
            contentTemplate: "dvs-ui/resources/dvs/summary/views/dvsOutOfSyncHost.html",
            modalClass: "dvs-out-of-sync-host-details-modal"
         };

         this.clarityModalService.openOkCancelModal(modalOptions);
      }

      private buildHostOutOfSyncPropertiesView(hostOutOfSyncData: any[]): any {
         if (hostOutOfSyncData) {
            let builder = this.vxPropertyViewService.createPropertyViewBuilder();

            let dvsOutOfSyncHostCategory: any = builder
                  .category("dvsOutOfSyncHost");

            this.buildHostOutOfSyncSection(dvsOutOfSyncHostCategory, hostOutOfSyncData);

            return builder.build();
         }
      }

      private buildHostOutOfSyncSection(category: any, dvsPortDetailsData: any[]): any {
         let linkAndTextRenderer = this.columnRenderersRegistry.getColumnRenderer("link-and-text");

         for (let host of dvsPortDetailsData) {
            let hostLinkObject: any = {
               hostName : host.outOfSyncHost.name,
               hostId : this.defaultUriSchemeUtil.getVsphereObjectId(host.outOfSyncHost.host)
            };

            let hostSection: any = category.section(hostLinkObject.hostName)
                  .compileAsHtml(true)
                  .title(linkAndTextRenderer(
                        hostLinkObject.hostId,
                        hostLinkObject.hostName,
                        null,
                        "Host:",
                        true));

            hostSection.property(this.i18nService.getString(
                  "DvsUi", "OutOfSyncDvsHost.params"), host.configParamters);
         }
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .service("dvsOutOfSyncHostService", DvsOutOfSyncHostService);
}
