namespace h5_client {
   export module admin_ui {
      import IScope = angular.IScope;
      import PrivilegeService = admin_ui.PrivilegeService;

      interface PrivilegeNode {
         id: string;
         name: string;
         label: string;
         group: string;
         childNodes: Array<PrivilegeNode>;
         selected: boolean;
         visible: boolean;
         privilegeExpanded: boolean;
      }

      interface CreateEditRoleControllerScope extends IScope {
         wizardConfig: any;
         vuiWizard: any;
         wizardState: any;
      }

      class CreateEditRoleController {
         public static $inject = ['$scope', '$rootScope', 'privilegeService', 'rolesService', 'i18nService', '$timeout'];
         public i18n: any;
         private privilegeNodes: Array<PrivilegeNode>;
         private selectedPrivilegeIds: Array<String>;
         public selectedPrivilege: PrivilegeNode;
         public privileges: Array<PrivilegeNode> ;
         public isHorizontalMode: boolean;
         public isVerticalMode: boolean;
         public groupSelected: boolean;
         public showAll: boolean = true;
         public showSelected: boolean = false;
         public showUnselected: boolean = false;

         constructor(private $scope: CreateEditRoleControllerScope, private $rootScope: IScope, private privilegeService: PrivilegeService, private rolesService: any, private i18nService: any, private $timeout: any) {
            this.selectedPrivilege;
            this.privilegeNodes = [];
            this.getPrivileges();
            this.selectedPrivilegeIds = $scope.wizardState.selectedPrivilegeIds;
            this.groupSelected = false;
            this.i18n = i18nService.getString;

            this.init();
         }

         private focusPane(): void {
            const privilegeList = angular.element("#privilege-list-pane");
            if (privilegeList && privilegeList[0]) {
              this.$timeout(() => {
                  privilegeList[0].focus();
              }, 0);
            }
         }

         private handleKeyUp(e: any): void {
            if(e.target.tagName.toUpperCase() === "LI") {
               const liSelected = e.target;
               if(e.keyCode === 40){ //Arrow up pressed
                  if(liSelected && liSelected.nextElementSibling){
                     liSelected.nextElementSibling.focus();
                     this.selectPrivilege(liSelected.nextElementSibling.innerText);
                  }
               }  else if(e.keyCode === 38){ //Arrow down pressed
                  if(liSelected && liSelected.previousElementSibling){
                     liSelected.previousElementSibling.focus();
                     this.selectPrivilege(liSelected.previousElementSibling.innerText);
                  }
               }
            }
         }

         private getPrivileges(): void {
            this.privilegeService.getPrivilegeTree().then((data: Array<PrivilegeNode>) => {
               this.privilegeNodes = data;
               this.privileges = _.pluck(data, 'label').sort();
               this.setSelectedIds(this.privilegeNodes);
               this.setNodesVisibleFlag(this.privilegeNodes, true, false);
            });
         }

         private setNodesVisibleFlag(nodes: Array<PrivilegeNode>, flag: boolean, selectedOnly: boolean) {
            nodes.forEach(childNode => {
               if (childNode.childNodes.length === 0) {
                  if ((selectedOnly && childNode.selected) || !selectedOnly) {
                     childNode.visible = flag;
                  } else {
                     childNode.visible = !flag;
                  }
                  return;
               }
               this.setNodesVisibleFlag(childNode.childNodes, flag, selectedOnly);
               if (selectedOnly) {
                  let notSelected: Array<PrivilegeNode> = _.filter(childNode.childNodes, (child: any) => {
                     return !child.selected;
                  });
                  if (flag === true) {
                     // Show selected
                     if (notSelected.length === childNode.childNodes.length) {
                        childNode.visible = false;
                     } else {
                        childNode.visible = true;
                     }
                  } else {
                     // Show Unselected
                     if (notSelected.length === 0) {
                        childNode.visible = false;
                     } else {
                        childNode.visible = true;
                     }
                  }
               } else {
                  childNode.visible = flag;
               }
            });
            this.focusPane();
         }

         private getSelectedIds(nodes: Array<PrivilegeNode>, results: Array<String>):Array<String> {
            nodes.forEach(childNode => {
               if (childNode.childNodes.length === 0 && childNode.selected) {
                  results.push(childNode.id);

                  return;
               }
               this.getSelectedIds(childNode.childNodes, results);
            });
            return results;
         }

         private setSelectedIds(nodes: Array<PrivilegeNode>) {
            nodes.forEach(childNode => {
               if (childNode.childNodes.length === 0 && this.selectedPrivilegeIds.indexOf(childNode.id) >= 0 ) {
                  childNode.selected = true;
                  return;
               }
               this.setSelectedIds(childNode.childNodes);
               if (childNode.childNodes.length) {
                  this.toggleParent(childNode);
               }
            });
         }

         public toggleChild(model: PrivilegeNode): void {
            _.forEach(model.childNodes, (child: PrivilegeNode) => {
               child.selected = model.selected;
            });
         };

         public toggleParent(model: PrivilegeNode): void {
            let notSelected: Array<PrivilegeNode> = _.filter(model.childNodes, (child: any) => {
               return !child.selected;
            });
            model.selected = notSelected.length === 0;
         };

         public selectPrivilege(name: string) {
            let data: any = _.filter(this.privilegeNodes, (child: any) => {
               return child.label === name;
            });

            this.selectedPrivilege = data[0];

            let childNodes: Array<PrivilegeNode> = this.selectedPrivilege.childNodes;
            if (childNodes.length > 0 && childNodes[0].childNodes.length > 0) {
               this.isHorizontalMode = true;
               this.isVerticalMode = false;
            } else {
               this.isHorizontalMode = false;
               this.isVerticalMode = true;
            }

            this.checkIfAllSelected();
         }


         public displaySelected() {
            this.setNodesVisibleFlag(this.privilegeNodes, true, true);
            this.showSelected = true;
            this.showUnselected = false;
            this.showAll = false;
         }

         public displayUnselected() {
            this.setNodesVisibleFlag(this.privilegeNodes, false, true);
            this.showUnselected = true;
            this.showSelected = false;
            this.showAll = false;
         }

         public displayAll() {
            this.setNodesVisibleFlag(this.privilegeNodes, true, false);
            this.showUnselected = false;
            this.showSelected = false;
            this.showAll = true;
         }

         public toggleSelectAll() {
            this.selectedPrivilege.childNodes.forEach(childNode => {
               childNode.selected = this.groupSelected;
               this.toggleChild(childNode);
            });
         }

         public checkIfAllSelected() {
            let notSelectedAll: Array<PrivilegeNode> = _.filter(this.selectedPrivilege.childNodes, (child: any) => {
               return !child.selected;
            });
            this.groupSelected = notSelectedAll.length === 0;
         }

         public toggleExpandPrivileges(model: PrivilegeNode) {
            model.privilegeExpanded = !model.privilegeExpanded;
         }

         private init(): void {
            let self = this;
            this.$scope.vuiWizard.currentPage.disableNext = () => {
               let keys: Array<String> = self.getSelectedIds(self.privilegeNodes, []);
               return keys.length === 0;
            };
            this.$scope.vuiWizard.currentPage.onCommit = () => {
               let keys: Array<String> = self.getSelectedIds(self.privilegeNodes, []);
               self.$scope.wizardState.selectedPrivilegeIds = keys;
               self.$scope.vuiWizard.currentPage.state = "completed";
               return true;
            };
         }
      }

      angular.module('com.vmware.vsphere.client.admin')
          .controller('createEditRoleController', CreateEditRoleController);
   }
}
