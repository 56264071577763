namespace h5_vm {
   import RegisterVmSpec = com.vmware.vsphere.client.h5.vm.data.RegisterVmSpec;

   class VmProvisioningFinishRegisterPageModelFactory {
      public static $inject = [
         "defaultUriSchemeUtil",
         "i18nService",
         "dataService",
         "managedEntityConstants",
         "$q",
         "mutationService"
      ];

      constructor(defaultUriSchemeUtil: any,
            i18nService: any,
            dataService: any,
            managedEntityConstants: any,
            $q: angular.IQService,
            mutationService: any) {
         return (virtualMachineSpecBuilder: any, wizardViewData: any) => {
            return new VmProvisioningFinishRegisterPageModel(
                  defaultUriSchemeUtil,
                  i18nService,
                  dataService,
                  managedEntityConstants,
                  $q,
                  mutationService,
                  virtualMachineSpecBuilder,
                  wizardViewData);
         };
      }
   }

   export class VmProvisioningFinishRegisterPageModel {
      public static $inject = [
         "defaultUriSchemeUtil",
         "i18nService",
         "dataService",
         "managedEntityConstants",
         "$q",
         "mutationService"];

      constructor(private defaultUriSchemeUtil: any,
         private i18nService: any,
            private dataService: any,
            private managedEntityConstants: any,
            private $q: angular.IQService,
            private mutationService: any,
            public virtualMachineSpecBuilder: any,
            public wizardViewData: any) {
      }

      getVmName(): string {
         return this.virtualMachineSpecBuilder.getName();
      }

      getTargetFolderName(): any {
         return this.virtualMachineSpecBuilder.getTargetInformation().name;
      }

      getIsTemplate(): boolean {
         return this.virtualMachineSpecBuilder.getRegisterVmData().isTemplate;
      }

      getComputeResourceInfo(): Object {
         let crId: string =
               this.virtualMachineSpecBuilder.getComputeResourceId();
         let crName: string =
               this.virtualMachineSpecBuilder.getComputeResourceName();
         let crType: string = this.labelForType(crId);

         return {
            name: crName,
            label: crType
         };
      }

      validatePage(): Object {
         return {};
      }

      submitPage(): angular.IPromise<boolean> {
         let spec: RegisterVmSpec = new RegisterVmSpec();
         spec.folderRef = this.defaultUriSchemeUtil.getManagedObjectReference(
               this.virtualMachineSpecBuilder.getTargetInformation().folderUid);
         spec.targetRef = this.defaultUriSchemeUtil.getManagedObjectReference(
               this.virtualMachineSpecBuilder.getComputeResourceId());
         spec.vmName = this.virtualMachineSpecBuilder.getName();
         spec.vmPath = this.virtualMachineSpecBuilder.getRegisterVmData().vmFilePath;
         spec.isVmTemplate = this.virtualMachineSpecBuilder.getRegisterVmData().isTemplate;

         this.mutationService.add(spec._type, spec);
         return this.$q.resolve(true);
      }

      private labelForType(urn: string): string {
         let type: string = this.defaultUriSchemeUtil.getPartsFromVsphereObjectId(urn).type;

         switch (type) {
            case this.managedEntityConstants.CLUSTER:
               return this.i18nService.getString("VmUi", "ProvisioningWizardSummaryPage.Cluster");
            case this.managedEntityConstants.HOST:
               return this.i18nService.getString("VmUi", "ProvisioningWizardSummaryPage.Host");
            case this.managedEntityConstants.RESOURCE_POOL:
               return this.i18nService.getString("VmUi", "ProvisioningWizardSummaryPage.RP");
            case this.managedEntityConstants.V_APP:
               return this.i18nService.getString("VmUi", "ProvisioningWizardSummaryPage.RP");
            default:
               throw new Error("Unknown compute resouce id: " + urn);
         }
      }

   }
   angular.module("com.vmware.vsphere.client.vm")
         .service("vmProvisioningFinishRegisterPageModel",
               VmProvisioningFinishRegisterPageModelFactory);
}
