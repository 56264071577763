module cluster_ui {

   class ScheduleDrsDrsSettingsPageController {

      private loading: boolean;
      private is65VcOrLater: boolean;
      private objectId: string;
      private formData: any;

      public static $inject = ["$scope", "dataService", "vcService", "$q",
         "drsConstants"];

      constructor(private $scope: any,
                  private dataService: any,
                  private vcService: any,
                  private $q: any,
                  private drsConstants: any) {
         this.objectId = $scope.modalOptions.dialogData.objectId;
         this.$scope.modalOptions.dialogData.manager.setObjectId(this.objectId);
         this.$scope.modalOptions.dialogData.manager.setDrsDialogOptions(this.$scope.modalOptions);
         this.$scope.modalOptions.isAlertClosed = true;
         this.getViewData();
      }

      public getSelectedPageUrl(): string {
         let selectedPage: any = _.find(this.$scope.pages.categories, (page: any) => {
            return page.id === this.$scope.selectedCategoryId;
         });

         return selectedPage ? selectedPage.contentUrl : "";
      }

      private getViewData(): void {
         this.loading = true;

         let vcVersionPromise: any =
               this.vcService.is65VcOrLater(this.objectId)
                     .then((is65VcOrLater: any) => {
                        this.is65VcOrLater = is65VcOrLater;

                        this.$scope.pages = {
                           categories: this.$scope.modalOptions.dialogData.manager.getPageDefinitions(true)
                        };

                        this.$scope.selectedCategoryId = this.$scope.pages.categories[0].id;
                        this.$scope.selectCategory = (categoryId: any) => {
                           this.$scope.selectedCategoryId = categoryId;
                        };
                     });

         if (this.$scope.modalOptions.dialogData.isEditScheduledTaskMode) {
            vcVersionPromise.then(() => {
               this.loadFormData();
               this.loading = false;
            });
         } else {
            let promises: Array<any> = [];

            let requestDataPromise: any =
                  this.dataService
                        .getData(this.objectId,
                              'com.vmware.vsphere.client.clusterui.model.services.DrsConfigData')
                        .then((drsData: any) => {
                           _.extend(this.$scope.modalOptions.dialogData.editDrsData, drsData);
                           this.loading = false;
                        });
            promises.push(vcVersionPromise);
            promises.push(requestDataPromise);
            this.$q.all(promises).then(()=> {
               this.loadFormData();
            });
         }
      }

      private addWatchers(): void {
         this.$scope.$watch('ctrl.formData', () => {
            this.$scope.manager.setFormData(
                  this.$scope.modalOptions.dialogData.manager.getFormData());
         });
         this.$scope.$watch('ctrl.formData.drsPolicies.allConsumedMemoryMetric',
               this.allConsumedMemoryMetricChanged.bind(this));
      }

      private allConsumedMemoryMetricChanged(allConsumedMemoryMetric: boolean): void {
         let allConsumedMemoryMetricOption =
               this.formData.advancedOptionsBuilder.getOptionByKey(
                     this.drsConstants.drsPolicies.ALL_CONSUMED_MEMORY_METRIC_OPTION);
         if (!allConsumedMemoryMetric) {
            if (allConsumedMemoryMetricOption) {
               this.formData.advancedOptionsBuilder
                     .removeOption(allConsumedMemoryMetricOption.id);
            }
         } else if (!allConsumedMemoryMetricOption) {
            this.formData.advancedOptionsBuilder.addOption({
               key: this.drsConstants.drsPolicies.ALL_CONSUMED_MEMORY_METRIC_OPTION,
               value: this.drsConstants.drsPolicies.ALL_CONSUMED_MEMORY_METRIC_VALUE
            });
         }
      }

      private loadFormData() {
         let drsData: any =
               this.$scope.modalOptions.dialogData.editDrsData;
         this.$scope.modalOptions.dialogData.manager.setFormData(drsData, this.is65VcOrLater);
         this.formData = this.$scope.modalOptions.dialogData.manager.getFormData();
         this.addWatchers();
      }
   }

   angular.module("com.vmware.vsphere.client.cluster")
         .controller("ScheduleDrsDrsSettingsPageController", ScheduleDrsDrsSettingsPageController);
}
