/*
 *  Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential
 */

(function() {
   /**
    * Directive for affinity and anti-affinity rule details.
    */
   'use strict';
   angular.module('com.vmware.vsphere.client.cluster').directive(
         'affinityRuleDetails', affinityRuleDetails);

   affinityRuleDetails.$inject = ['i18nService', 'clusterRulesService',
         'clusterRulesConstants', 'vuiConstants', 'defaultUriSchemeUtil',
         'datagridActionBarService'];

   function affinityRuleDetails(i18nService, clusterRulesService, clusterRulesConstants,
         vuiConstants, defaultUriSchemeUtil, datagridActionBarService) {

      return {
         restrict: 'E',
         templateUrl: 'cluster-ui/resources/cluster/views/settings/rules/affinityRuleDetails.html',
         scope: {
            rule: '='
         },
         controller: ['$scope', '$rootScope', function($scope, $rootScope) {
            var objectId = $rootScope._route.objectId;

            $scope.ruleMembersGridOptions = getRuleMembersGridOptions();
            $scope.conflictingRulesGridOptions = getConflictingRulesGridOptions();
            $scope.ruleDetailsTitle = i18nService.getString(
                  'ClusterUi', 'manage.settings.ruleDetails');
            $scope.$watch('rule', onRuleChange);

            var actionSpecs = [{
               actionId: 'vsphere.core.cluster.actions.rule.members.add',
               getActionInvocationContext: function() {
                  return {
                     selectedMembers: $scope.ruleMembersGridOptions.data,
                     dialogSubTitleObject: {
                        text: $scope.rule.name
                     },
                     submitCallback: function(dialogOptions) {
                        dialogOptions.isAlertClosed = true;
                        dialogOptions.alerts = [];

                        if (_.isEmpty(dialogOptions.dialogData.selectedItems)) {
                           dialogOptions.alerts = [{
                              text: i18nService.getString(
                                    'CommonUi', 'objectSelectorDialog.noSelection'),
                              type: vuiConstants.validationBanner.type.ERROR
                           }];
                           dialogOptions.isAlertClosed = false;

                           return false;
                        }

                        return clusterRulesService
                              .addRuleMembers(objectId, $scope.rule, dialogOptions.dialogData.selectedItems)
                              .then(function() {
                                 return true;
                              })
                              .catch(function() {
                                 return false;
                              });
                     }
                  };
               },
               isActionAvailable: function(actionDef) {
                  return actionDef.available && !!$scope.rule;
               }
            }, {
               actionId: 'vsphere.core.cluster.actions.rule.members.details',
               getActionInvocationContext: function() {
                  return {
                     selectedRule: $scope.rule,
                     selectedMember: _.first(_.filter(
                           $scope.rule.members,
                           function(member) {
                              return defaultUriSchemeUtil.getVsphereObjectId(member.entity) ===
                                    $scope.ruleMembersGridOptions.selectedItems[0].id;

                           }))
                  };
               },
               isActionAvailable: function(actionDef) {
                  return actionDef.available &&
                        $scope.ruleMembersGridOptions.selectedItems.length === 1 &&
                        $scope.ruleMembersGridOptions.selectedItems[0].conflictsCount > 0;
               }
            }, {
               actionId: 'vsphere.core.cluster.actions.rule.members.remove',
               getActionInvocationContext: function() {
                  return {
                     isExistingRule: true,
                     rule: $scope.rule,
                     membersToRemove: $scope.ruleMembersGridOptions.selectedItems,
                     confirmCallback: function() {
                        clusterRulesService.removeRuleMembers(objectId, $scope.rule, $scope.ruleMembersGridOptions.selectedItems);
                        return true;
                     }
                  };
               },
               isActionAvailable: function(actionDef) {
                  return actionDef.available && $scope.ruleMembersGridOptions.selectedItems.length > 0;
               }
            }];
            var datagridActionBarServiceCacheObject = null;

            $scope.$watch('ruleMembersGridOptions.selectedItems', function() {
               refreshActionBar();
            });

            createActionBar();

            function createActionBar() {
               datagridActionBarService
                     .updateActionBar($scope.ruleMembersGridOptions, [objectId], actionSpecs)
                     .then(function(value) {
                        datagridActionBarServiceCacheObject = value;
                     });
            }

            function refreshActionBar() {
               if (!datagridActionBarServiceCacheObject) {
                  return;
               }

               datagridActionBarService.updateActionBar(
                     $scope.ruleMembersGridOptions, [objectId], actionSpecs, datagridActionBarServiceCacheObject
               );
            }

            function getRuleMembersGridOptions() {
               var options = {
                  actionBarOptions: {
                     actions: []
                  },
                  searchable: false,
                  resizable: true,
                  data: [],
                  columnDefs: getRuleMembersGridColumnDefs(),
                  selectionMode: vuiConstants.grid.selectionMode.MULTI,
                  showCheckboxesOnMultiSelection: false,
                  selectedItems: [],
                  height: '100%',
                  pageConfig: {
                     hidePager: true
                  }
               };

               return options;
            }

            function getConflictingRulesGridOptions() {
               var options = {
                  searchable: false,
                  resizable: false,
                  data: [],
                  columnDefs: getConflictingRulesGridColumnDefs(),
                  height: '100%',
                  pageConfig: {
                     hidePager: true
                  }
               };

               return options;
            }

            function getRuleMembersGridColumnDefs() {
               return [{
                  displayName: i18nService.getString(
                        'ClusterUi', 'rules.vmRuleDetails.ruleMembers'),
                  field: 'name',
                  type: 'string',
                  template: '<span class="object" title="#:name#"><i class="#:icon#")></i>#:name#</span>'
               }, {
                  displayName: i18nService.getString(
                        'ClusterUi', 'rules.vmRuleDetails.conflicts'),
                  field: 'conflictsCount',
                  type: 'string',
                  template: '<span title="#:conflictsCount#">#:conflictsCount#</span>'
               }];
            }

            function getConflictingRulesGridColumnDefs() {
               return [
                  {
                     displayName: i18nService.getString(
                           'ClusterUi', 'rules.vmRuleDetails.conflicts'),
                     field: 'name',
                     type: 'string',
                     template: '<span class="object" title="#:name#"><i class="#:icon#")></i>#:name#</span>'
                  }
               ];
            }

            function getAffinityRuleDetailsLabel() {
               var ruleDetailsLabel = '';

               if ($scope.rule) {
                  if ($scope.rule.type === clusterRulesConstants.ruleType.VM_AFFINITY_RULE) {
                     ruleDetailsLabel = i18nService.getString(
                           'ClusterUi', 'rules.vmRuleDetails.typeDescription.vmAffinity',
                           $scope.rule.members.length
                     );
                  }

                  if ($scope.rule.type === clusterRulesConstants.ruleType.VM_ANTIAFFINITY_RULE) {
                     ruleDetailsLabel = i18nService.getString(
                           'ClusterUi', 'rules.vmRuleDetails.typeDescription.vmAntiAffinity',
                           $scope.rule.members.length
                     );
                  }
               }

               return ruleDetailsLabel;
            }

            function onRuleChange() {
               $scope.ruleMembersGridOptions.data = [];
               $scope.conflictingRulesGridOptions.data = [];

               if ($scope.rule) {
                  clusterRulesService.getAffinityRulesMembersData($scope.rule)
                        .then(function(membersData) {
                           $scope.ruleMembersGridOptions.data = membersData;
                        });
                  $scope.conflictingRulesGridOptions.data =
                        clusterRulesService.getConflictingRulesData($scope.rule.conflictingRules);
               }

               $scope.ruleDetailsLabel = getAffinityRuleDetailsLabel();
            }
         }]
      };
   }
})();
