/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
(function () {
   'use strict';


   angular.module('com.vmware.vsphere.client.storage').factory('hostStorageService',
         ['dataService', 'i18nService', 'bytesFilter', '$q', 'defaultUriSchemeUtil',
            'mutationService', 'hostStorageConstants', '$rootScope',
         function (dataService, i18nService, bytesFilter, $q, defaultUriSchemeUtil,
               mutationService, hostStorageConstants, $rootScope) {

            var FORMAT_DISK_SPEC_TYPE = 'com.vmware.vsphere.client.storage.FormatHostDiskSpec';
            var MARK_AS_LOCAL_SPEC_TYPE = 'com.vmware.vsphere.client.storage.TagDiskAsLocalSpec';

            return {
               retrieveStorageDevicesData: retrieveStorageDevicesData,
               retrieveStorageDevicePartitionData: retrieveStorageDevicePartitionData,
               retrieveHostStorageAdapterPathsData: retrieveHostStorageAdapterPathsData,
               retrieveHostProtocolEndpointsPathsData: retrieveHostProtocolEndpointsPathsData,
               retrieveHostStorageDevicePathsData: retrieveHostStorageDevicePathsData,
               retrieveDatastoreStorageDevices: retrieveDatastoreStorageDevices,
               retrieveDatastoreIncreaseOptions: retrieveDatastoreIncreaseOptions,
               retrieveVmfsDatastoreMultipathingData: retrieveVmfsDatastoreMultipathingData,
               rescanStorageAdapters: rescanStorageAdapters,
               erasePartitions: erasePartitions,
               changeDiskLocalState: changeDiskLocalState
            };

            function retrieveStorageDevicesData(hostId, storageAdapterKey) {
               var STORAGE_DEVICE_PROPERTY= "storagedevice:hostStorageDevices";

               var params;
               if (storageAdapterKey) {
                  params = constructStringParamForProperty(STORAGE_DEVICE_PROPERTY, storageAdapterKey);
               }

               return dataService.getProperties(hostId, [STORAGE_DEVICE_PROPERTY], params)
                  .then(function(response) {
                     var storageDevices = response[STORAGE_DEVICE_PROPERTY] || [];

                     storageDevices.forEach(function(item) {
                        item.localizedState = i18nService.getString('StorageUi',
                              'storage.adapters.devices.deviceState.' + item.operationalState);

                        item.localizedDriveType = i18nService.getString('StorageUi',
                              item.isFlash ? 'disk.ssd' : 'disk.nonSSD');

                        item.localizedLunNumber = item.lunNumber >= 0 ? item.lunNumber : '';

                        item.localizedCapacity = item.isScsiDisk
                              ? bytesFilter(item.capacity, 'B', 'Auto', 2)
                              : '';

                        item.disabled = item.operationalState !== 'ok';
                     });

                     return storageDevices;
                  });
            }

            function retrieveStorageDevicePartitionData (hostId, storageDeviceKey) {
               var STORAGE_DEVICE_PARTITION_DATA_PROPERTY = "storagedevice:partitionDetails";

               if (!storageDeviceKey) {
                  return $q.when({"partitionFormat": "", "partitions": []});
               }

               var params = constructStringParamForProperty(STORAGE_DEVICE_PARTITION_DATA_PROPERTY, storageDeviceKey);

               return dataService.getProperties(hostId, [STORAGE_DEVICE_PARTITION_DATA_PROPERTY], params)
                  .then(function(response) {
                     var storageDevicePartitions = response[STORAGE_DEVICE_PARTITION_DATA_PROPERTY] || [];
                     return storageDevicePartitions;
                  });
            }

            function retrieveDatastoreStorageDevices (datastoreRef) {
               var STORAGE_DEVICE_PROPERTY = 'storagedevice:datastoreStorageDevices';

               return dataService.getProperties(datastoreRef, [STORAGE_DEVICE_PROPERTY]).then(function(response) {
                  return response[STORAGE_DEVICE_PROPERTY];
               });
            }

            function retrieveDatastoreIncreaseOptions (datastoreRef, hostRef, scsiDiskInfo) {
               var DATASTORE_INCREASE_OPTIONS = 'datastore:datastoreIncreaseOptions';
               var options = {
                  propertyParams: [{
                     propertyName: DATASTORE_INCREASE_OPTIONS,
                     parameterType: 'com.vmware.vsphere.client.storage.DatastoreIncreaseOptionsQuerySpec',
                     parameter: {
                        host: hostRef,
                        scsiDiskInfo: scsiDiskInfo
                     }
                  }]
               };
               return dataService.getProperties(datastoreRef, [DATASTORE_INCREASE_OPTIONS], options).then(function(response) {
                  return response[DATASTORE_INCREASE_OPTIONS];
               });
            }

            function retrieveHostStorageAdapterPathsData (hostId, storageAdapterKey) {
               return getPaths(hostId, storageAdapterKey, "storageadapter:hostStorageAdapterPaths");
            }

            function retrieveHostStorageDevicePathsData(hostId, storageDeviceName) {
               return getPaths(hostId, storageDeviceName, "storagedevice:hostStorageDevicePaths");
            }

            function retrieveHostProtocolEndpointsPathsData (hostId, identifier) {
               return getPaths(hostId, identifier, "storagedevice:hostStoragePEPaths");
            }

            function getPaths (hostId, stringParam, property) {
               var params;
               if (stringParam) {
                  params = constructStringParamForProperty(property, stringParam);
               }

               return dataService.getProperties(hostId, [property], params).then(function(response) {
                  var paths = response[property] || [];

                  return paths;
               });
            }

            function retrieveVmfsDatastoreMultipathingData (hostId, datastoreId) {

               var options = constructMorParamForProperty(
                     hostStorageConstants.EXTENTS_MULTIPATHING_INFO_PROP, datastoreId);

               return dataService.getProperties(
                     hostId,
                     [hostStorageConstants.EXTENTS_MULTIPATHING_INFO_PROP,
                        hostStorageConstants.PATH_SELECTION_POLICY_OPTIONS_PROP,
                        hostStorageConstants.STORAGE_ARRAY_TYPE_POLICY_OPTIONS_PROP],
                        options);
            }

            function rescanStorageAdapters (moRefs, spec) {
               var result = { affectedHosts: [], targetName: null };

               if (!moRefs || !moRefs.length) {
                  return $q.when(result);
               }

               if (moRefs[0].type === 'HostSystem') {
                  applyMutationOperation(moRefs, spec);
                  result.affectedHosts = moRefs;
                  return $q.when(result);
               } else {
                  var applicableHostsProperty = 'storageadapter:getHostsApplicableForRescan';
                  var nameProperty = 'name';

                  // Get all child hosts of a host folder, datacenter or cluster
                  // which are connected and for which the appropriate privilege is granted.
                  var objId = defaultUriSchemeUtil.getVsphereObjectId(moRefs[0]);
                  return dataService.getProperties(objId, [applicableHostsProperty, nameProperty])
                        .then(function (resp) {
                           var childHosts = resp[applicableHostsProperty];
                           if (childHosts && childHosts.length) {
                              applyMutationOperation(childHosts, spec);
                           }
                           result.affectedHosts = childHosts;
                           result.targetName = resp[nameProperty];
                           return result;
                        });
               }
            }

            function constructStringParamForProperty (propertyName, paramValue) {
               return {
                  propertyParams: [{
                     propertyName: propertyName,
                     parameterType: 'java.lang.String',
                     parameter: paramValue
                  }]
               };
            }

            function constructMorParamForProperty (propertyName, objectId) {
               return {
                  propertyParams: [{
                     propertyName: propertyName,
                     parameterType: 'com.vmware.vim.binding.vmodl.ManagedObjectReference',
                     parameter: defaultUriSchemeUtil.getManagedObjectReference(objectId)
                  }]
               };
            }

            function applyMutationOperation (hostRefs, spec) {
               var specType = 'com.vmware.vsphere.client.storage.adapters.StorageAdapterRescanSpec';
               var objIds = hostRefs.map(defaultUriSchemeUtil.getVsphereObjectId.bind(defaultUriSchemeUtil));
               return mutationService.applyOnMultiEntity(
                     objIds,
                     specType,
                     spec)
                     .then(function () {
                        $rootScope.$broadcast('vsphere.core.host.actions.rescan.completed', {
                           affectedHosts: hostRefs,
                           rescanSpec: spec
                        });
                     });
            }

            function erasePartitions(hostDevicesInfos) {
               var specs = [];
               _.forEach(hostDevicesInfos, function(hostDevicesInfo) {
                  var formatHostDiskSpecs =
                        _.map(hostDevicesInfo.devices, function(device) {
                              return {
                                 entity: hostDevicesInfo.hostId,
                                 spec: {
                                    _type: FORMAT_DISK_SPEC_TYPE,
                                    devicePath: device.devicePath
                                 }
                              };
                  });
                  specs = specs.concat(formatHostDiskSpecs);
               });

               mutationService.applyMulti(FORMAT_DISK_SPEC_TYPE, specs);
            }

            function changeDiskLocalState(hostId, disks, tagAsLocal) {
               var diskUuids = _.pluck(disks, 'lunUuid');
               var spec = {
                  _type: MARK_AS_LOCAL_SPEC_TYPE,
                  isLocal: tagAsLocal,
                  diskUUID: diskUuids
               };

               mutationService.apply(hostId, MARK_AS_LOCAL_SPEC_TYPE, spec);
            }

         }]);
})();
