namespace storage_ui {

   import IPromise = angular.IPromise;

   export class EditIscsiAdvancedOptionsControl {

      public hba: any;
      public hostId: string;
      public targetSet: any;
      public apiObj:any;

      public loading: boolean;
      public datagridOptions:any;
      public vxValidator:any;

      public i18n:any;

      private readonly ADVANCED_SETTINGS_PROPERTY = ".internetScsiAdvancedSettings";
      private readonly ADVANCED_SETTINGS_INFO_PROPERTY = ".internetScsiAdvancedSettings.@info";

      private readonly ADVANCED_SETTINGS_TARGET_PROPERTY = "HostSystem/localizedInternetScsiTargetAdvancedSettings";
      private readonly ADVANCED_SETTINGS_TARGET_INFO_PROPERTY = "HostSystem/internetScsiTargetAdvancedSettings.@info";

      private originalOptionValues:OptionItem[];

      public static $inject = ["dataService", "navigation", "i18nService",
         "editIscsiAdvancedOptionsService", "vuiConstants", "advancedOptionConstants",
         "columnRenderersRegistry", "vxValidatorFactory", "$timeout", "$element"];

      constructor(private dataService: any,
                  private navigation: any,
                  private i18nService: any,
                  private editIscsiAdvancedOptionsService:EditIscsiAdvancedOptionsService,
                  private vuiConstants:any,
                  private advancedOptionConstants:any,
                  private columnRenderersRegistry:any,
                  private vxValidatorFactory:any,
                  private $timeout: any,
                  private $element: any) {

         this.vxValidator = this.vxValidatorFactory.create();
         this.i18n = this.i18nService.getString;
      }

      public $onInit() {
         if (this.apiObj) {
            this.apiObj.validate = () => {
               let validationErrors = this.vxValidator.validate();

               _.forEach(this.datagridOptions.data, (dataItem:any) => {
                  if (!dataItem.readOnly) {
                     let validationErrors = dataItem._rawItem.validate();
                     let validationError = validationErrors && validationErrors.length && validationErrors[0];
                     dataItem.validationError.errorMessage = validationError;
                  }
               });

               return !validationErrors || !validationErrors.length;
            };

            this.apiObj.getModifiedOptions = () => {
               let modifiedOptions:OptionItem[] = [];
               _.forEach(this.originalOptionValues, (originalOptionItem:OptionItem) => {
                  let optionItem = this.getOptionItemByKey(originalOptionItem.optionKey);
                  if (optionItem && !this.editIscsiAdvancedOptionsService.equalOptionItems(optionItem, originalOptionItem)) {
                     modifiedOptions.push(optionItem);
                  }
               });
               return modifiedOptions;
            };
         }
      }

      private getOptionItemByKey(key:string):OptionItem {
         let dataItem:any = _.find(this.datagridOptions.data, (dataItem:any) => {
            return dataItem._rawItem.optionKey === key;
         });

         return dataItem && dataItem._rawItem;
      }

      public $onChanges() {
         this.requestData();
      }

      private requestData() {
         if (!this.hostId || !this.hba) {
            return;
         }

         this.loading = true;

         let dataPromise:IPromise<OptionItem[]>;

         if (this.targetSet) {
            dataPromise = this.requestDataForTarget();
         } else {
            dataPromise = this.requestDataForAdapter();
         }

         dataPromise
               .then((optionItems:OptionItem[]) => {

                  this.originalOptionValues = angular.copy(optionItems);

                  let dataItems = _.map(optionItems, function(optionItem) {
                     return angular.merge({
                        _rawItem: optionItem
                     }, optionItem);
                  });

                  _.forEach(dataItems, function(dataItem) {
                     dataItem.validationError = {
                        errorMessage: "",
                        message: function() {
                           return dataItem.validationError.errorMessage;
                        },
                        isVisible: function() {
                           return !!dataItem.validationError.errorMessage;
                        }
                     };
                  });

                  this.datagridOptions = {
                     columnDefs: this.getColumnDefs(),
                     data: dataItems,
                     height: "100%",
                     selectionMode: this.vuiConstants.grid.selectionMode.SINGLE,
                     searchable: false,
                     resizable: true
                  };

                  this.focusGrid();
               })
               .finally( () => {
                  this.loading = false;
               });
      }

      private requestDataForAdapter():IPromise<OptionItem[]> {
         let advSettingsProp = this.hba.key + this.ADVANCED_SETTINGS_PROPERTY;
         let advSettingsInfoProp = this.hba.key + this.ADVANCED_SETTINGS_INFO_PROPERTY;

         return this.dataService.getProperties(this.hostId, [advSettingsProp, advSettingsInfoProp])
               .then((response: any) => {
                  return this.editIscsiAdvancedOptionsService.parseOptionItems(
                        response[advSettingsProp], response[advSettingsInfoProp]);
               });
      }

      private requestDataForTarget():IPromise<OptionItem[]> {
         let retrievalSpec = this.editIscsiAdvancedOptionsService.buildIscsiTargetsAdvancedSettingsRetrievalSpec(
               this.hba, this.targetSet);

         return this.dataService.getProperties(this.hostId,
               [this.ADVANCED_SETTINGS_TARGET_PROPERTY, this.ADVANCED_SETTINGS_TARGET_INFO_PROPERTY],
               {
                  propertyParams: [
                     {
                        propertyName: this.ADVANCED_SETTINGS_TARGET_PROPERTY,
                        parameterType: retrievalSpec._type,
                        parameter: retrievalSpec
                     },
                     {
                        propertyName: this.ADVANCED_SETTINGS_TARGET_INFO_PROPERTY,
                        parameterType: retrievalSpec._type,
                        parameter: retrievalSpec
                     }
                  ]
               })
               .then((response: any) => {
                  response = response || {};
                  return this.editIscsiAdvancedOptionsService.parseOptionItems(
                        response[this.ADVANCED_SETTINGS_TARGET_PROPERTY],
                        response[this.ADVANCED_SETTINGS_TARGET_INFO_PROPERTY]);
               });
      }

      private getColumnDefs(): any {
         let textRender = this.columnRenderersRegistry.getColumnRenderer('text');

         let columns:any[] = [
            {
               displayName: this.i18nService.getString("StorageUi", "storage.adapters.iscsi.advancedOptions.column.option"),
               field: "name",
               width: "300",
               template: function(item: any) {
                  return textRender(["name"], item);
               }
            },
            {
               displayName: this.i18nService.getString("StorageUi", "storage.adapters.iscsi.advancedOptions.column.description"),
               field: "description",
               width: "400",
               template: function(item: any) {
                  return textRender(["description"], item);
               }
            }];

         if (this.targetSet) {
            columns.push({
               displayName: this.i18nService.getString("StorageUi", "storage.adapters.iscsi.advancedOptions.column.inherited"),
               width: "300",
               template: (dataItem: any) => {
                  return '' +
                        '<label>' +
                        '  <input type="checkbox" ' +
                        '     ng-model="dataItem.inherited" ' +
                        '     ng-change="dataItem._rawItem.inherited = dataItem.inherited" ' +
                        '     ng-disabled="dataItem.readOnly">' +
                        this.i18nService.getString("StorageUi", "storage.adapters.iscsi.advancedOptions.inheritedEnabled") +
                        '</label>';
               }
            });
         }

         columns.push({
               displayName: this.i18nService.getString("StorageUi", "storage.adapters.iscsi.advancedOptions.column.value"),
               width: "300",
               template: (dataItem:OptionItem) => {
                  dataItem.validator = this.vxValidator;
                  if (dataItem.valueType === this.advancedOptionConstants.optionTypes.STRING_OPTION_TYPE &&
                        dataItem.allowedValues && dataItem.allowedValues.length) {

                     return `<div class="select value-cell" >
                             <select ng-model="dataItem.value"
                                     ng-options="option.value as option.name for option in dataItem.allowedValues"
                                     ng-change="dataItem._rawItem.value = dataItem.value"
                                     ng-disabled="dataItem.readOnly || dataItem.inherited"
                                     aria-label="{{dataItem.name}}">
                              </div>`;

                  } else if (dataItem.valueType === this.advancedOptionConstants.optionTypes.BOOLEAN_OPTION_TYPE) {

                     return `<label>
                             <input type="checkbox"
                                ng-model="dataItem.value"
                                ng-change="dataItem._rawItem.value = dataItem.value"
                                ng-disabled="dataItem.readOnly || dataItem.inherited"
                                aria-label="{{dataItem.name}}">
                           ${this.i18nService.getString("StorageUi", "storage.adapters.iscsi.advancedOptions.booleanValue")}
                           </label>`;
                  }

                  let tooltips:string[] = [];
                  if (dataItem.minValue !== null && dataItem.minValue !== undefined) {
                     tooltips.push(this.i18nService.getString(
                           "StorageUi", "storage.adapters.iscsi.advancedOptions.minValue", dataItem.minValue));
                  }
                  if (dataItem.maxValue !== null && dataItem.maxValue !== undefined) {
                     tooltips.push(this.i18nService.getString(
                           "StorageUi", "storage.adapters.iscsi.advancedOptions.maxValue", dataItem.maxValue));
                  }

                  let title = tooltips.length ? 'title="' + tooltips.join(' ') + '"' : '';

                  let inputElement = `<input type="text" ng-model="dataItem.value"
                        ng-disabled="dataItem.readOnly || dataItem.inherited"
                        ng-change="dataItem._rawItem.value = dataItem.value"
                        vx-error="dataItem.validationError"
                        class="iscsi-advanced-options-input"
                        aria-label="{{dataItem.name}}"
                        ${title} >`;

                  return `<div class="value-cell"><vsc-validate [validator]="dataItem.validator"
                        [required]="false"
                        [clr-tooltip-direction]="'top-left'"
                        [custom-validation-function]="dataItem.validate"> ${inputElement} </vsc-validate></div>`;
               }
            });

         return columns;
      }

      private focusGrid(): void {
         this.$timeout(() => {
            const gridElement: any[] = this.$element.find("div[vui-datagrid] div[kendo-grid]");
            if (gridElement && gridElement[0]) {
               gridElement[0].setAttribute("tabindex", "-1");
               gridElement[0].focus();
            }
         }, 0);
      }
   }

   angular.module("com.vmware.vsphere.client.storage")
         .component("editIscsiAdvancedOptionsControl", {
            templateUrl: "storage-ui/resources/storage/views/host/adapters/advanced/edit/EditIscsiAdvancedOptionsControl.html",
            bindings: {
               hba: "<",
               hostId: "<",
               targetSet: "<",
               apiObj: '<'
            },
            controller: EditIscsiAdvancedOptionsControl
         });
}
