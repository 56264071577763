/* Copyright 2015 VMware, Inc. All rights reserved. -- VMware Confidential */

(function() {
   'use strict';

   angular
      .module('com.vmware.vsphere.client.vm')
      .controller('VmHardwareSummaryController', VmHardwareSummaryController);

   VmHardwareSummaryController.$inject = [
      '$scope',
      'dataService',
      'i18nService',
      'actionsService',
      'feedbackService',
      'deviceOrganizer',
      'vmDeviceInfoService',
      'logService',
      'ovfTemplateHardwareDataMapping',
      'pmemUtilService',
      'vmCryptUtilService',
      'defaultUriSchemeUtil',
      '$q',
      'managedEntityConstants',
      'diskBackingInfoConstants'
   ];

   function VmHardwareSummaryController (
      $scope,
      dataService,
      i18nService,
      actionsService,
      feedbackService,
      deviceOrganizer,
      vmDeviceInfoService,
      logService,
      ovfTemplateHardwareDataMapping,
      pmemUtilSevice,
      vmCryptUtilService,
      defaultUriSchemeUtil,
      $q,
      managedEntityConstants,
      diskBackingInfoConstants
   ) {
      var self = this;
      var objectId = $scope._route.objectId;

      self.liveRefreshProperties = [
         'config.hardware.numCPU',
         'config.hardware.memoryMB',
         'config.hardware.device',
         'config.version'
      ];

      self.watchObjects = [ objectId ];

      self.objectId = objectId;
      self.isVmtxTemplateCLitem = false;
      self.isEncryptionSummaryVisible = false;
      self.isPmemSummaryVisible = false;
      self.displayHardDiskDevices = [];

      // Flag to re-use this VM hardware summary portlet for Content Library ovf templates
      self.isVmTemplateCLitem = (objectId.indexOf("urn:vapi:com.vmware.content.library.Item") === 0);

      var editSettingsLabel = i18nService.getString('VmUi', 'actionLabelEditSettings');
      var editSettingsAriaLabel = i18nService.getString('VmUi', 'actionDescEditSettings');

      if ($scope.portlet) {
         if (self.isVmTemplateCLitem) {
            $scope.portlet.footerLinks = null;
         } else {
            actionsService.getAction("vsphere.core.vm.editSettingsAction", [ objectId ])
                  .then(function (action) {
                     var linkEnabled = !!action && !!action.available;
                     self.editAction = action && action.available ? action : undefined;
                     $scope.portlet.footerLinks = [{
                        label: editSettingsLabel,
                        ariaLabel: editSettingsAriaLabel,
                        onClick: self.invokeEditAction,
                        enabled: linkEnabled
                     }];
                  });
         }
      }

      self.invokeEditAction = function (event) {
         if (event) {
            event.stopPropagation();
            event.preventDefault();
         }
         return self.editAction ? actionsService.invokeAction(self.editAction) : null;
      };

      self.getVmHardwareData = function () { return getVMId().then(function (vmId) { getVMHardwareViewData(vmId); }); };
      self.getVmHardwareData();

      self.getGroupedDiskText = function() {
         return i18nService.getString('VmUi', 'summaryView.groupedDisks.value', self.totalHardDiskCount);
      };

      function getVMId() {
         if (self.isVmTemplateCLitem) {
            var TYPE_PROPERTY = 'type';
            var MOR_ID_OF_VMTX_PROPERTY = 'morIdOfVmtx';

            return dataService.getProperties(self.objectId, [ TYPE_PROPERTY, MOR_ID_OF_VMTX_PROPERTY ])
                  .then(function(response) {
                     if (response && response[TYPE_PROPERTY] === managedEntityConstants.VM_TEMPLATE) {
                        var vmtxItemMor = response[MOR_ID_OF_VMTX_PROPERTY];
                        var vmId = defaultUriSchemeUtil.createVmomiUri(vmtxItemMor.type,
                              vmtxItemMor.value, vmtxItemMor.serverGuid);
                        self.isVmtxTemplateCLitem = true;
                        self.isVmTemplateCLitem = false;
                        if (!self.watchObjects.includes(vmId)) {
                           self.watchObjects.push(vmId);
                        }
                        return $q.resolve(vmId);
                     }

                     return $q.resolve(self.objectId);
                  });
         }

         return $q.resolve(self.objectId);
      }

      function getVMHardwareViewData (vmId) {
         var log = logService('VmHardwareSummaryController');
         var dataModel = self.isVmTemplateCLitem ?
               'com.vmware.vsphere.client.library.h5model.OvfTemplateHardwareData' :
               'com.vmware.vsphere.client.vm.model.HardwareViewData';

         dataService
             .getData(vmId, dataModel,
                     {queryName: 'VmHardwareSummaryController.' + getVMHardwareViewData.name})
            .then(function(hardwareViewData) {
               if (self.isVmTemplateCLitem) {
                  // if the ovf template is not yet completely imported,
                  // the backend returns empty data for the cpu and memory
                  // return null and do not display the data, until it is available
                  // return null also if it is vApp template
                  if (hardwareViewData.isVappTemplate
                     || !hardwareViewData.cpu
                     || !hardwareViewData.memory) {
                     hardwareViewData = null;
                     return;
                  }
                  hardwareViewData =
                        ovfTemplateHardwareDataMapping.convertOvfTemplateHardwareData(hardwareViewData);
               }
               self.hardwareViewData = hardwareViewData;
               if (hardwareViewData.hardware) {
                  var devices = hardwareViewData.hardware.device;

                  var devicesToDisplay = _.reject(devices, function(device){
                     // Hide devices which are not marshalled correctly to avoid displaying this error in the UI
                     // ** FIX ME device.VirtualDevice.label (vm.vmsg)
                     // TODO remove this once VMODL is stable in order to make errors more obvious to QA?
                    var rejectDevice = vmDeviceInfoService.isDeviceInstanceOf(device, 'VirtualDevice');

                     if(rejectDevice) {
                        log.error('Invalid device (unstable VMODL between ESX and VC?): ' + JSON.stringify(device));
                     }

                     return rejectDevice;
                  });

                  devicesToDisplay = _.filter(devicesToDisplay, function(device) {
                     return device._type !== "com.vmware.vim.binding.vim.vm.device.VirtualTPM";
                  });

                  self.devices = deviceOrganizer.groupAndSortDevices(devicesToDisplay);

                  var allVirtualDisks = self.devices['VirtualDisk'];

                  var allDisks = _.partition(self.devices['VirtualDisk'], function(disk) {
                     return !isRDMDisk(disk);
                  });

                  var allHardDisks = allDisks && allDisks[0] ? allDisks[0] : [];
                  var allRdmDisks = allDisks && allDisks[1] ? allDisks[1] : [];


                  self.shouldGroupHardDisks = allVirtualDisks ? allVirtualDisks.length > 2 : false;
                  self.totalHardDiskCount = allVirtualDisks ? allVirtualDisks.length : 0;

                  //reset first
                  self.displayHardDiskDevices = [];
                  self.displayRdmDiskDevices = [];

                  if (self.shouldGroupHardDisks) {
                     //just add the first one per UX spec
                     if (allHardDisks.length) {
                        self.displayHardDiskDevices.push(allHardDisks[0]);
                     } else {
                        self.displayRdmDiskDevices.push(allRdmDisks[0]);
                     }
                  } else {
                     self.displayHardDiskDevices = allHardDisks;
                     self.displayRdmDiskDevices = allRdmDisks;
                  }

                  var tpmDevice = _.filter(devices, function(device) {
                     return device._type === "com.vmware.vim.binding.vim.vm.device.VirtualTPM";
                  });

                  if (tpmDevice && tpmDevice.length > 0) {
                     self.tpmDevice = tpmDevice[0];
                  } else {
                     self.tpmDevice = null;
                  }

                  self.isPmemSummaryVisible = isPmemSummaryVisibile(self.devices);
                  self.isEncryptionSummaryVisible =
                     !vmCryptUtilService.isCryptoKeyEmpty(hardwareViewData.cryptoKeyId);
               } else {
                  // temporary debug message to understand how hardware can be null here for many users
                  // See http://h5-feedbackportal.eng.vmware.com:3000/feedback?errlist=1&id=b079aa4c
                  feedbackService.sendDebugMessage('null hardwareViewData.hardware in VmHardwareSummaryController!', hardwareViewData);
               }
            });
      }

      function isPmemSummaryVisibile(devices) {
         if (!_.isEmpty(self.devices['VirtualNVDIMM'])) {
            return true;
         }

         return _.some(devices['VirtualDisk'], function(device) {
            return pmemUtilSevice.isPmemDisk(device);
         });
      }

      function isRDMDisk(diskDevice) {
         return diskDevice && diskDevice.backing._type ===
               diskBackingInfoConstants.RAWDISKMAPPING;
      }
   }
})();
