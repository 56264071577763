namespace storage_ui {

   export class StorageProviderSupportedVendorIds {

      public datagridOptions: any;

      public storageProviderListItem: StorageProviderListItem;

      public static $inject = ["storageProvidersItemBuilderService", "vuiConstants", "i18nService"];

      constructor(private storageProvidersItemBuilderService: StorageProvidersItemBuilderService,
                  private vuiConstants: any,
                  private i18nService: any) {
      }

      public $onChanges() {
         this.refresh();
      }

      private getColumnDefinitions() {
         return [
            {
               displayName: this.i18nService.getString("StorageUi", "storage.storageProviders.supportedVendorIdsView.vendorIdsColumn"),
               field: "vendorId",
               template: "#:vendorId#"
            },
            {
               displayName: this.i18nService.getString("StorageUi", "storage.storageProviders.supportedVendorIdsView.modelIdsColumn"),
               field: "modelId",
               template: "#:modelId#"
            }
         ];
      }

      public refresh() {

         let vendorIdItems: any[] = [];
         if (this.storageProviderListItem) {
            vendorIdItems = this.storageProvidersItemBuilderService.getSupportedVendorIdItems(
                  this.storageProviderListItem);
         }

         this.datagridOptions = {
            height: "100%",
            columnDefs: this.getColumnDefinitions(),
            selectionMode: this.vuiConstants.grid.selectionMode.NONE,
            data: vendorIdItems,
            resizable: true
         };
      }
   }

   angular.module("com.vmware.vsphere.client.storage")
         .component("storageProviderSupportedVendorIds", {
            template: "<div vui-datagrid=\"$ctrl.datagridOptions\" class=\"fill-parent vui-grid-cell-ellipsis\"></div>",
            bindings: {
               storageProviderListItem: "<"
            },
            controller: StorageProviderSupportedVendorIds
         });
}
