/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace dvs_ui {

   export class DvpgNameAndLocationPageValidator implements WizardPageValidator {

      public static $inject = [
            "i18nService",
            "mutationService",
            "$q",
            "dvpgCreateWizardConstants"
      ];

      constructor(private i18nService: any,
                  private mutationService: any,
                  private $q: any,
                  private wizardConstants: any) {
      }

      public getValidationError(wizardScope: DvpgCreateWizardScope): string[] {

         // client side validation

         let nameError: string | null =
               this.getInvalidNameError(wizardScope.model.name);

         if (nameError) {
            return this.$q.resolve([nameError]);
         }

         let dvsErrors: string[] = this.getDvsErrors(wizardScope);

         if (dvsErrors.length > 0) {
            return this.$q.resolve(dvsErrors);
         }

         // server side validation
         return this.getNameValidationError(wizardScope);
      }

      public getInvalidNameError(name: string): string | null {
         let dvpgName: string = name
               ? name.trim()
               : name;

         let escapedDvpgName = dvpgName.replace(/%/g, "%25")
               .replace(/\//g, "%2f").replace(/\\/g, "%5c");

         if (!escapedDvpgName || escapedDvpgName.length === 0) {
            return this.i18nService.getString(
                  "DvsUi", "dvpg.create.nameAndLocation.nameRequired");
         }

         if (escapedDvpgName.length >
               this.wizardConstants.MAX_ALLOWED_DVPG_NAME_LENGTH) {
            return this.i18nService.getString(
                  "DvsUi", "dvpg.create.nameAndLocation.tooLong");
         }

         return null;
      }

      private getDvsErrors(wizardScope: DvpgCreateWizardScope): string[] {
         if (wizardScope.model.dvsId === null) {
            return [this.i18nService.getString(
                  "DvsUi", "dvpg.create.nameAndLocation.noSelectionError")];
         }

         return [];
      }

      private getNameValidationError(wizardScope: DvpgCreateWizardScope): any {
         wizardScope.wizardConfig.loading = true;

         return this.mutationService.validate(wizardScope.model.dvsId,
               "com.vmware.vsphere.client.h5.network.dvportgroup.model.DvPortgroupNameSpec",
               { _type: "com.vmware.vsphere.client.h5.network.dvportgroup.model.DvPortgroupNameSpec",
                  name: wizardScope.model.name.trim()
               }).then((validationResult: any) =>  {
            let errors: string[] = [];

            if (validationResult.error && validationResult.error.localizedMessage) {
               errors.push(validationResult.error.localizedMessage);
            } else if (validationResult.result) {
               errors.push(validationResult.result);
            }
            wizardScope.wizardConfig.loading = false;
            return errors;
         }, function () {
            wizardScope.wizardConfig.loading = false;
            return [];
         });
      }
    }

   angular.module("com.vmware.vsphere.client.dvs")
         .service("dvpgNameAndLocationPageValidator",
               DvpgNameAndLocationPageValidator);
}
