(function() {
   'use strict';
   angular.module('com.vmware.vsphere.client.cluster')
         .controller('VmOverridesController', VmOverridesController);

   VmOverridesController.$inject = ['$rootScope', '$scope', 'i18nService',
      'defaultUriSchemeUtil', 'clusterVmOverridesService', 'vuiConstants',
      'linksUtil', 'vcService', 'datagridActionBarService'];

   function VmOverridesController($rootScope, $scope, i18nService, defaultUriSchemeUtil,
         clusterVmOverridesService, vuiConstants, linksUtil, vcService,
         datagridActionBarService) {
      var self = this;
      var objectId = $rootScope._route.objectId;
      self.watchForObjects = [objectId];
      self.liveRefreshProperties = [
         'configurationEx.drsConfig.enabled',
         'configurationEx.drsConfig.defaultVmBehavior',
         'configurationEx.drsConfig.enableVmBehaviorOverrides',
         'configurationEx.dasConfig.enabled',
         'configurationEx.dasConfig.vmComponentProtecting',
         'configurationEx.dasConfig.vmMonitoring',
         'configurationEx.dasConfig.defaultVmSettings.isolationResponse',
         'configurationEx.dasConfig.defaultVmSettings.vmComponentProtectionSettings.vmTerminateDelayForAPDSec',
         'configurationEx.dasConfig.defaultVmSettings.vmComponentProtectionSettings.vmReactionOnAPDCleared',
         'configurationEx.dasConfig.defaultVmSettings.vmComponentProtectionSettings.vmStorageProtectionForPDL',
         'configurationEx.dasConfig.defaultVmSettings.vmComponentProtectionSettings.vmStorageProtectionForAPD',
         'configurationEx.dasConfig.defaultVmSettings.restartPriority',
         'configurationEx.dasConfig.defaultVmSettings.restartPriorityTimeout',
         'configurationEx.dasConfig.defaultVmSettings.vmToolsMonitoringSettings.failureInterval',
         'configurationEx.dasConfig.defaultVmSettings.vmToolsMonitoringSettings.maxFailureWindow',
         'configurationEx.dasConfig.defaultVmSettings.vmToolsMonitoringSettings.maxFailures',
         'configurationEx.dasConfig.defaultVmSettings.vmToolsMonitoringSettings.minUpTime',
         'configurationEx.orchestration.defaultVmReadiness.postReadyDelay',
         'configurationEx.orchestration.defaultVmReadiness.readyCondition',
         'configurationEx.drsVmConfig',
         'configurationEx.dasVmConfig',
         'configurationEx.vmOrchestration',
         'summary.currentEVCModeKey'
      ];

      self.gridTitle = i18nService.getString('ClusterUi', 'manage.settings.vmOverrides');
      self.selectedOverrides = null;
      self.vmMonitoringWarningMessage = null;
      var actionSpecs = [
         {
            actionId: 'vsphere.core.cluster.actions.vmOverride.add'
         }, {
            actionId: 'vsphere.core.cluster.actions.vmOverride.edit',
            getActionInvocationContext: function() {
               return {
                  selectedOverrides: self.selectedOverrides
               };
            },
            isActionAvailable: function(actionDef) {
               return actionDef.available && !_.isEmpty(self.gridOptions.selectedItems)
                     && (self.gridOptions.selectedItems.length === 1 ||
                     !_.some(self.gridOptions.selectedItems, function(selectedItem) {
                        return selectedItem.vm && selectedItem.vm.ftVm;
                     }));
            }
         }, {
            actionId: 'vsphere.core.cluster.actions.vmOverride.delete',
            getActionInvocationContext: function() {
               return {
                  selectedOverrides: self.selectedOverrides
               };
            },
            isActionAvailable: function(actionDef) {
               return actionDef.available && !_.isEmpty(self.gridOptions.selectedItems) &&
                     _.every(self.gridOptions.selectedItems, function(selectedItem){
                        return selectedItem.vm.ftVm === false;
                     });
            }
         }];
      var datagridActionBarServiceCacheObject = null;

      self.getViewData = function() {
         clusterVmOverridesService.getVmOverridesData(objectId)
               .then(function(data) {
                  self.gridOptions.data = data.overrides;

                  self.vmMonitoringWarningMessage =
                        getVmMonitoringWarningMessage(data.overrides);
               });
      };

      vcService.is65VcOrLater(objectId).then(function(is65vcOrLater) {
         self.gridOptions = getGridOptions(is65vcOrLater);

         self.getViewData();
         createActionBar();

         $scope.$watch('ctrl.gridOptions.selectedItems', onSelectedVmOverrideChange);
      });

      $scope.overridesPreselectComparator = overridesPreselectComparator;

      $scope.$watch(function() {
         return self.selectedOverrides;
      }, function() {
         refreshActionBar();

         if (self.selectedOverrides && self.selectedOverrides.length === 1 && self.selectedOverrides[0].haOverrides) {
            self.propertyGrid = clusterVmOverridesService.buildPropertyView(
                  self.selectedOverrides[0].haOverrides);
         } else {
            self.propertyGrid = null;
         }
      });

      function createActionBar() {
         datagridActionBarService
               .updateActionBar(self.gridOptions, [objectId], actionSpecs)
               .then(function(value) {
                  datagridActionBarServiceCacheObject = value;
               });
      }

      function refreshActionBar() {
         if (!datagridActionBarServiceCacheObject) {
            return;
         }

         datagridActionBarService.updateActionBar(
               self.gridOptions, [objectId], actionSpecs, datagridActionBarServiceCacheObject
         );
      }

      function getGridOptions(is65vcOrLater) {
         var options = {
            actionBarOptions: {
               actions: []
            },
            pageConfig: {
               hidePager: true
            },
            resizable: true,
            data: [],
            selectionMode: vuiConstants.grid.selectionMode.MULTI,
            selectedItems: [],
            columnDefs: getAllColumnDefs(is65vcOrLater),
            height: '100%',
            showCheckboxesOnMultiSelection: false
         };

         return options;
      }

      function getVmMonitoringWarningMessage(vmOverridesData) {
         var vmOverrideWarning = _.find(vmOverridesData, function(vmOverride) {
            return vmOverride.haOverrides.vmMonitoringWarningText !== null;
         });

         if (vmOverrideWarning) {
            return vmOverrideWarning.haOverrides.vmMonitoringWarningText;
         } else {
            return null;
         }
      }

      function getAllColumnDefs(is65vcOrLater) {
         var columnDefs = [{
               displayName: i18nService.getString('ClusterUi',
                     'vmOverrides.vmNameHeaderText'),
               field: 'vm.name',
               type: 'string',
               template: '<span class="object" title="#:vm.name#"><i class="#:vm.primaryIconId#")></i>#:vm.name#</span>'
            }, {
               displayName: i18nService.getString('ClusterUi',
                     'vmOverrides.drsAutomationLevelHeaderText'),
               field: 'drsOverrides.drsAutomationLevelText',
               type: 'string',
               template: '<span title="#:drsOverrides.drsAutomationLevelText#">#:drsOverrides.drsAutomationLevelText#</span>'
            }, {
               displayName: i18nService.getString('ClusterUi',
                     'vmOverrides.haRestartPriorityHeaderText'),
               field: 'haOverrides.haRestartPriorityText',
               type: 'string',
               template: '<span title="#:haOverrides.haRestartPriorityText#">#:haOverrides.haRestartPriorityText#</span>'
            }
         ];

         if (is65vcOrLater) {
            columnDefs.push({
               displayName: i18nService.getString('ClusterUi',
                     'vmOverrides.haRestartConditionHeaderText'),
               field: 'haOverrides.haRestartConditionsText',
               type: 'string',
               template: '<span title="#:haOverrides.haRestartConditionsText#">#:haOverrides.haRestartConditionsText#</span>'
            });
         }

         columnDefs = columnDefs.concat([{
               displayName: i18nService.getString('ClusterUi',
                     'vmOverrides.haHostIsolationResponseHeaderText'),
               field: 'haOverrides.haIsolationResponseText',
               type: 'string',
               template: '<span title="#:haOverrides.haIsolationResponseText#">#:haOverrides.haIsolationResponseText#</span>'
            }, {
               displayName: i18nService.getString('ClusterUi',
                     'vmOverrides.vmHostHeaderText'),
               field: 'vm.host',
               type: 'string',
               template: hostTextRenderer
            }
         ]);

         return columnDefs;
      }

      function hostTextRenderer(data) {
         if (_.isNull(data.vm.host)) {
            return '';
         }

         var icon = '<span class="' + data.vm.host.primaryIconId + '"></span>';
         var hostLink = linksUtil.generateObjectLink(
               defaultUriSchemeUtil.getVsphereObjectId(data.vm.host.provider),
               data.vm.host.name);

         return icon + hostLink;
      }

      function onSelectedVmOverrideChange(newSelectedOverrides) {
         if (!newSelectedOverrides || newSelectedOverrides.length === 0) {
            self.selectedOverrides = null;
            return;
         }

         self.selectedOverrides = newSelectedOverrides;

      }

      function overridesPreselectComparator(override) {
         var result = false;
         if (self.selectedOverrides && override) {
            result = _.some(self.selectedOverrides, function(selected) {
               return selected.vm.name === override.vm.name
                     && ((selected.vm.host && override.vm.host &&
                     selected.vm.host.name === override.vm.host.name) ||
                     (!selected.vm.host && !override.vm.host));
            });
         }
         return result;
      }
   }
})();
