/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace dvs_ui {

   export class DvsSpanSessionPortBrowserDialogService {

      public static $inject = [
            "$q",
            "i18nService",
            "clarityModalService",
            "mutationService"
      ];

      constructor(private $q: any,
                  private i18nService: any,
                  private clarityModalService: any,
                  private mutationService: any) {
      }

      public show(dvsUrn: string, portsToIgnore: string[], okHandler: Function): void {
         let dialogData: PortBrowserDialogData = new PortBrowserDialogData(dvsUrn, portsToIgnore);

         let modalOptions = {
            title: this.i18nService.getString("DvsUi", "PortBrowserDialog.title"),
            defaultButton: "submit",
            size: "lg",
            alerts: [],
            dialogData: dialogData,
            contentTemplate: "dvs-ui/resources/dvs/portmirroring/common/components/dvsSpanSessionPortBrowserDialog.html",
            onSubmit: (): boolean => {
               okHandler(dialogData.portIds);
               return true;
            },
            onSelectionChange: (portIds: string[]): void => {
               dialogData.portIds = portIds;
            }
         };

         this.clarityModalService.openOkCancelModal(modalOptions);
      }
   }

   class PortBrowserDialogData {
      public dvsUrn: string;
      public portsToIgnore: string[];
      public portIds: string[];

      constructor(urn: string, portsToIgnore: string[]) {
         this.dvsUrn = urn;
         this.portsToIgnore = portsToIgnore;
         this.portIds = [];
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .service("dvsSpanSessionPortBrowserDialogService",
               DvsSpanSessionPortBrowserDialogService);
}
