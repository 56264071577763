angular.module('com.vmware.vsphere.client.host').service('addHostWizardPageModelGetter', [
   'defaultUriSchemeUtil',
   'lockdownModeStrings',
   'managedEntityConstants',
   'addHostWizardConstants',
   'i18nService',
   'hostWizardScheduleTaskPageModel',
   function(defaultUriSchemeUtil,
            lockdownModeStrings,
            managedEntityConstants,
            addHostWizardConstants,
            i18nService,
            hostWizardScheduleTaskPageModel) {
      return {
         getNameLocationPageModel: function(state) {
            return {
               destinationName: state.destination.name,
               destinationIcon: state.destination.icon,
               hostname: state.userEnteredHostname,
               reconnectHost: state.reconnectHost
            };
         },

         getConnectionSettingsPageModel: function(state) {
            return {
               username: state.username,
               password: state.password
            };
         },

         getHostSummaryPageModel: function(state) {
            return {
               name: state.name,
               vendor: state.vendor,
               model: state.model,
               version: state.version,
               vms: state.vms,
               serverIp: state.serverIp
            };
         },

         getAssignLicensePageModel: function(state) {
            return {
               serverGuid: state.destination.ref.serverGuid,
               connectInfo: state.connectInfo,
               userSelectedLicense: state.userSelectedLicense
            };
         },

         getLockdownModePageModel: function(state) {
            return {
               lockdownMode: state.lockdownMode || 'disabled',
               showStrictModeOption: state.hostAccessManagerAvailable
            };
         },

         getVmLocationPageModel: function(state) {
            var model = {
               treeRoot: defaultUriSchemeUtil.getVsphereObjectId(state.dc)
            };

            if (state.vmLocation) {
               model.selectedLocationId = defaultUriSchemeUtil.getVsphereObjectId(state.vmLocation.ref);
            }

            return model;
         },

         getResourceLocationPageModel: function(state) {
            var resourceLocationName = state.resourceLocationName
               || i18nService.getString('HostUi', 'addHostWizard.defaultNewRpNameValueFormat', state.hostname);

            return {
               resourceLocationType: state.resourceLocationType,
               resourceLocationName: resourceLocationName
            };
         },

         getResourcePoolInput: function(state) {
            return {
               destination: state.destination.ref,
               type: state.resourceLocationType,
               name: state.resourceLocationName
            };
         },

         getReadyToCompletePageModel: function(state) {
            var model = {
               name: state.name,
               version: state.version,
               licenseName: state.userSelectedLicense ? state.userSelectedLicense.name : "",
               vms: state.vms,
               networks: state.networks,
               datastores: state.datastores,
               lockdownMode: lockdownModeStrings.forDisplay(state.lockdownMode),
               destinationName: state.destination.name,
               destinationIcon: state.destination.icon
            };

            if (state.vmLocation) {
               model.vmLocation = state.vmLocation.displayName;
            }

            if (state.destination.ref.type === managedEntityConstants.CLUSTER && state.destination.drsState === true) {
               if (state.resourceLocationType === addHostWizardConstants.RESOURCE_LOCATION_ROOT) {
                  model.destinationResourceLocation = state.destination.name;
               } else {
                  model.destinationResourceLocation = state.resourceLocationName;
               }
            }

            return model;
         },

         isHostnameValid: function(state) {
            return !!state.hostname;
         },

         isUsernameValid: function(state) {
            return state.username && state.username.length > 0;
         },

         getHostAlreadyManagedMessage: function(state) {

            if (!!state.serverIp) {
               var hasFtVmOnHost = false;
               if (!_.isEmpty(state.connectInfo.vm)) {
                  hasFtVmOnHost = _.any(state.connectInfo.vm, function(vm) { return vm.config && vm.config.ftInfo; });
               }

               var message = hasFtVmOnHost ?
                   i18nService.getString('HostUi', 'addHostWizard.hostSummaryPage.ftVmExistsOnHostMsgFormat', state.serverIp) :
                   i18nService.getString('HostUi', 'addHostWizard.hostSummaryPage.alreadyManagedMessageFormat', state.serverIp);

               return message;
            }
            return "";
         },

         getStateLoadedFlag: function(state){
            return state.loadedFlag;
         },

         getDestinationUid: function(state) {
            return state.destinationUid;
         },

         getScheduleTaskPageModel: function(state) {
            return {
               hostWizardScheduleTaskPageModel: state.scheduleTaskPageModel
            };
         }
      };
   }]
);
