/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace dvs_ui {

   import DvsConfigSpec = com.vmware.vsphere.client.dvs.api.spec.DvsConfigSpec;
   export class DvsEditNetFlowDialogService {

      public static $inject = [
         "dataService",
         "i18nService",
         "clarityModalService",
         "mutationService",
         "dvsEditNetFlowDialogValidator",
         "clarityConstants"];

      public static EDIT_NETFLOW_DATA_MODEL =
            "com.vmware.vsphere.client.h5.network.dvs.netflow.model.DvsEditNetFlowDialogData";

      private i18n: any;

      constructor(private dataService:any,
                  private i18nService: any,
                  private clarityModalService: any,
                  private mutationService: any,
                  private dvsEditNetFlowDialogValidator: any,
                  private clarityConstants: any) {
         this.i18n = this.i18nService.getString;
      }

      public show(dvsUrn: string) {
         let modalOptions:any = {
            title: this.i18nService.getString("DvsUi", "actionEditNetFlowCaption"),
            defaultButton: "submit",
            alerts: [],
            dialogData: {},
            contentTemplate: "dvs-ui/resources/dvs/netflow/edit/dvsEditNetFlowDialog.html",
            onSubmit: onSubmit.bind(this)
         };

         let dvsPromise: any = this.dataService.getData(dvsUrn,
               DvsEditNetFlowDialogService.EDIT_NETFLOW_DATA_MODEL);

         let initialModel:DvsEditNetFlowDialogModel | null = null;

         dvsPromise.then((dataServiceResponse: any) => {
                  if (dataServiceResponse) {
                     modalOptions.subTitle = dataServiceResponse.name;
                     modalOptions.dialogData.pageModel =
                           new DvsEditNetFlowDialogModel(dataServiceResponse);
                     initialModel = new DvsEditNetFlowDialogModel(dataServiceResponse);
                  }
               }
         );

         this.clarityModalService.openOkCancelModal(modalOptions);

         function onSubmit(this: DvsEditNetFlowDialogService):boolean {
            let dvsConfigSpec:DvsConfigSpec | null = initialModel ?
                  initialModel.buildSpec(modalOptions.dialogData.pageModel) : null;
            if (dvsConfigSpec === null) {
               return true;
            }

            let messages: string[] = this.dvsEditNetFlowDialogValidator.getAllErrors(
                  modalOptions.dialogData.pageModel);

            if (messages.length > 0) {
               modalOptions.alerts = _.map(
                     messages,
                     (message: string) => {
                        return {
                           type: this.clarityConstants.notifications.type.ERROR,
                           text: message
                        };
                     });

               return false;
            }

            this.mutationService.apply(
                  dvsUrn, dvsConfigSpec._type, dvsConfigSpec);

            return true;
         }
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .service("dvsEditNetFlowDialogService", DvsEditNetFlowDialogService);
}
