/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
(function() {
   'use strict';
   angular.module('com.vmware.vsphere.client.storage')
      .controller('IncreaseDatastoreCapacityReadyToCompletePageController', [
         '$scope', 'hostStorageService', 'vxPropertyViewService', 'i18nService',
         function(
            $scope, hostStorageService, propertyViewService, i18nService
         ) {
            var self = this;
            self.summaryData = buildPropertyViewData();
            self.i18n = i18n;
            /**
             * Prepares data for summary page's vx-property-view
             */
            function buildPropertyViewData () {
               var manager = $scope.manager;
               var model = manager.getReadyToCompleteModel();
               var builder = propertyViewService.createPropertyViewBuilder();
               var category = builder.category('category');

               //General
               category.section('general')
                  .title(
                     i18n('increaseDatastoreWizard.readyToCompletePage.general'))
                  .property(
                     i18n('increaseDatastoreWizard.readyToCompletePage.name'),
                     model.datastoreName)
                  .property(
                     i18n('increaseDatastoreWizard.readyToCompletePage.increaseSizeBy'),
                     model.capacityIncrease)
                  .property(
                     i18n('increaseDatastoreWizard.readyToCompletePage.totalSize'),
                     model.futureSize
                  );

               //Device and formatting
               category.section('device')
                  .title(
                     i18n('increaseDatastoreWizard.readyToCompletePage.deviceAndFormatting'))
                  .property(
                     i18n('increaseDatastoreWizard.readyToCompletePage.diskLun'),
                     model.formattedDeviceName)
                  .property(
                     i18n('increaseDatastoreWizard.readyToCompletePage.partitionFormat'),
                     model.partitionFormat)
                  .property(
                     i18n('increaseDatastoreWizard.readyToCompletePage.vmfsVersion'),
                     model.vmfsVersion)
                  .property(
                     i18n('increaseDatastoreWizard.readyToCompletePage.maximumFileSize'),
                     model.maximumFileSize)
                  .property(
                     i18n('increaseDatastoreWizard.readyToCompletePage.blockSize'),
                     model.blockSize);
               return builder.build();
            }

            function i18n (key, value) {
               return i18nService.getString('StorageUi', key, value);
            }

         }
      ]);
})();
