/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace network_ui {

   export class EditVirtualSwitchDialogManager {

      private propertiesPage: any;
      private securityPage: any;
      private trafficShapingPage: any;
      private teamingAndFailoverPage: any;

      private propertiesPageModel: PropertiesPageModel;
      private securityPageModel: SecurityPageModel;
      private trafficShapingPageModel: TrafficShapingPageModel;
      private teamingAndFailoverPageModel: TeamingAndFailoverPageModel;

      public static EDIT_STANDARD_SWITCH_DIALOG_SPEC =
            "com.vmware.vsphere.client.h5.network.host.virtualswitch.model.EditStandardSwitchDialogData";

      constructor(private dialogScope: any,
                  private i18nService: any,
                  private mutationService: any,
                  private editVirtualSwitchDialogValidator: EditVirtualSwitchDialogValidator,
                  private portFailoverPolicyPageService: any,
                  private editPortgroupService: any) {
         this.propertiesPageModel = dialogScope.propertiesPageModel;
         this.securityPageModel = dialogScope.securityPageModel;
         this.trafficShapingPageModel = dialogScope.trafficShapingPageModel;
         this.teamingAndFailoverPageModel = dialogScope.teamingAndFailoverPageModel;
      }

      public getPageDefinitions(): any[] {
         this.initDialogPages();
         let pages: any[] = [
            this.propertiesPage,
            this.securityPage,
            this.trafficShapingPage,
            this.teamingAndFailoverPage];
         return pages;
      }

      public submit(): boolean {
         // Perform mutation only if some page state has been changed
         if (this.propertiesPageModel.dirty ||
               this.securityPageModel.dirty ||
               this.trafficShapingPageModel.dirty ||
               this.teamingAndFailoverPageModel.dirty) {
            this.mutationService.apply(
                  this.dialogScope.hostId,
                  EditVirtualSwitchDialogManager.EDIT_STANDARD_SWITCH_DIALOG_SPEC,
                  this.buildSpec());
         }
         return true;
      }

      private buildSpec(): any {
         let editVirtualSwitchSpec: any = {
            propertiesData: {
               name: this.propertiesPageModel.name,
               mtu: this.propertiesPageModel.mtu,
               numberOfPorts: this.propertiesPageModel.numPorts,
               availableNumberOfPorts: this.propertiesPageModel.availableNumPorts,
               showRestartWarning: this.propertiesPageModel.showRestartWarning
            },
            securityPolicies: {
               allowPromiscuousMode: this.securityPageModel.allowPromiscuousMode,
               macChanges: this.securityPageModel.macChanges,
               forgedTransmits: this.securityPageModel.forgedTransmits
            },
            trafficShapingPolicies: {
               enabled: this.trafficShapingPageModel.enabled,
               averageBandwidth: null,
               peakBandwidth: null,
               burstSize: null
            },
            teamingAndFailover: {
               loadBalancing: this.teamingAndFailoverPageModel.loadBalancing,
               checkBeacon: this.teamingAndFailoverPageModel.checkBeacon,
               notifySwitches: this.teamingAndFailoverPageModel.notifySwitches,
               rollingOrder: this.teamingAndFailoverPageModel.rollingOrder,
               pnics: this.teamingAndFailoverPageModel.pnics
            }
         };
         if (this.trafficShapingPageModel.enabled) {
            editVirtualSwitchSpec.trafficShapingPolicies.averageBandwidth =
                  this.trafficShapingPageModel.averageBandwidth;
            editVirtualSwitchSpec.trafficShapingPolicies.peakBandwidth =
                  this.trafficShapingPageModel.peakBandwidth;
            editVirtualSwitchSpec.trafficShapingPolicies.burstSize =
                  this.trafficShapingPageModel.burstSize;
         }

         return editVirtualSwitchSpec;
      }

      private initDialogPages(): void {
         this.propertiesPage = {
            title: this.i18nService.getString(
                  "NetworkUi", "VSwitchPropertiesView.title"),
            contentUrl: "network-ui/resources/network/views/host/vswitch/edit/" +
            "views/editVirtualSwitchPropertiesPage.html",
            onCommit: (): string[] => {
               this.propertiesPageModel.dirty = this.editPortgroupService
                     .haveVirtualSwitchPropertiesChanged(
                           this.propertiesPageModel.initialState,
                           this.propertiesPageModel);
               if (this.propertiesPageModel.dirty) {
                  let numPortsError = this.editVirtualSwitchDialogValidator
                        .getNumPortsError(this.dialogScope);
                  let mtuError = this.editVirtualSwitchDialogValidator
                        .getMtuError(this.dialogScope);
                  return numPortsError.concat(mtuError);
               }
               return [];
            }
         };

         this.securityPage = {
            title: this.i18nService.getString(
                  "NetworkUi", "PortSecurityPolicyView.title"),
            contentUrl: "network-ui/resources/network/views/host/vswitch/edit/" +
            "views/editVirtualSwitchSecurityPage.html",
            onCommit: (): string[] => {
               this.securityPageModel.dirty = this.editPortgroupService
                     .hasSecurityPolicyChanged(
                           this.securityPageModel.initialState,
                           this.securityPageModel,
                           false);
               return [];
            }
         };

         this.trafficShapingPage = {
            title: this.i18nService.getString(
                  "NetworkUi", "PortTrafficShapingPolicyView.title"),
            contentUrl: "network-ui/resources/network/views/host/vswitch/edit/" +
            "views/editVirtualSwitchTrafficShapingPage.html",
            onCommit: (): string[] => {
               this.trafficShapingPageModel.dirty = this.editPortgroupService
                     .hasTrafficShapingPolicyChanged(
                           this.trafficShapingPageModel.initialState,
                           this.trafficShapingPageModel,
                           false);
               if (this.trafficShapingPageModel.dirty) {
                  return this.editVirtualSwitchDialogValidator
                        .getTrafficShapingInputsError(this.dialogScope);
               }
               return [];
            }
         };

         this.teamingAndFailoverPage = {
            title: this.i18nService.getString(
                  "NetworkUi", "PortFailoverPolicyView.title"),
            contentUrl: "network-ui/resources/network/views/host/vswitch/edit/" +
            "views/editVirtualSwitchTeamingAndFailoverPage.html",
            onCommit: (): any => {
               this.teamingAndFailoverPageModel.dirty =
                     this.editPortgroupService
                           .hasFailoverPolicyChanged(
                                 this.teamingAndFailoverPageModel.initialState,
                                 this.teamingAndFailoverPageModel,
                                 false) ||
                     this.editPortgroupService
                           .hasFailoverOrderChanged(
                                 this.teamingAndFailoverPageModel.initialState,
                                 this.teamingAndFailoverPageModel,
                                 false);
               if (this.teamingAndFailoverPageModel.dirty) {
                  let errors = this.editVirtualSwitchDialogValidator
                        .getTeamingAndFailureInputsError(
                              this.dialogScope.teamingAndFailoverPageModel);

                  let warnings = this.editVirtualSwitchDialogValidator
                        .getTeamingAndFailoverWarnings(
                              this.dialogScope.teamingAndFailoverPageModel);

                  if (errors.length > 0) {
                     return errors;
                  } else if (warnings.length > 0) {
                     return this.portFailoverPolicyPageService.confirmWarningModal(warnings);
                  }
               }
               return true;
            }
         };
      }
   }

   angular.module("com.vmware.vsphere.client.network")
         .service("editVirtualSwitchDialogManager", EditVirtualSwitchDialogManager);
}
