namespace storage_ui {

   export class VvolSelectHostsPageService {

      public static $inject = ["wizardPageService", "i18nService"];

      constructor(private wizardPageService: any, private i18nService: any) {

      }

      private validate(wizardScope: any): boolean {
         let isValid = !!(wizardScope.vvolWizardManager.selectedHosts && wizardScope.vvolWizardManager.selectedHosts.length);
         if (isValid) {
            this.wizardPageService.markPageComplete(wizardScope.wizardConfig,
                  wizardScope.commonWizardManager.getDatastoreType());
         } else {
            this.wizardPageService.markPageIncompleteWithError(
                  wizardScope.wizardConfig,
                  this.i18nService.getString('StorageUi', 'addDatastoreWizard.selectHostsPage.noSelection')
            );
            this.wizardPageService.invalidateNextPages(wizardScope.wizardConfig,
                  wizardScope.commonWizardManager.getDatastoreType(), wizardScope.wizardConfig.currentPage.title);
         }
         return isValid;
      }


      public build(wizardScope: any): any {
         return {
            title: this.i18nService.getString("StorageUi", "addDatastoreWizard.vvolSelectHostsPage.title"),
            description: this.i18nService.getString("StorageUi", "addDatastoreWizard.vvolSelectHostsPage.description"),
            contentUrl: "storage-ui/resources/storage/views/wizard/createDatastore/pages/vvol/VvolSelectHostsPage.html",
            state: undefined,
            onCommit: ()=> {
               return this.validate(wizardScope);
            }
         };
      }
   }
   angular.module("com.vmware.vsphere.client.storage")
         .service("vvolSelectHostsPageService", VvolSelectHostsPageService);
}
