namespace spbm_ui {

   import IComponentController = angular.IComponentController;
   import IComponentOptions = angular.IComponentOptions;

   class EditDefaultStoragePolicyComponentController implements IComponentController {
      static $inject = ["i18nService", "dataService", "vuiConstants"];

      private datagridOptions: any;
      private message: string;
      private policies: any[];
      private selectedPolicy: any;
      private defaultSelectedPolicy: any;
      private isLoading: boolean;

      constructor(private i18nService: any,
                  private dataService: any,
                  private vuiConstants: any) {
         this.initDatagrid();
         this.message = this.i18nService.getString("SpbmUi", "drp.defaultProfileDialog.infoSingle");
      }

      $onChanges() {
         if (this.policies && this.policies.length) {
            this.datagridOptions.data = this.policies;
         }
      }

      preselectComparator = (data: any)=> {
         return this.defaultSelectedPolicy && data.name === this.defaultSelectedPolicy.name;
      };

      initDatagrid() {
         this.datagridOptions = {
            resizable: true,
            height: "100%",
            columnDefs: this.getColumnDefs(),
            selectionMode: this.vuiConstants.grid.selectionMode.SINGLE,
            selectedItems: [],
            data: this.policies,
            onChange: ()=> {
               this.selectedPolicy = this.datagridOptions.selectedItems[0];
            }
         };
      }

      getColumnDefs(): any[] {
         return [
            {
               displayName: this.i18nService.getString("SpbmUi", "drp.defaultProfileDialog.policyColumn"),
               field: "name",
               template: "#:name#"
            },
            {
               displayName: this.i18nService.getString("SpbmUi", "drp.defaultProfileDialog.descriptionColumn"),
               field: "description",
               template: "#:description#"
            }
         ];
      }

   }

   angular.module("com.vmware.vsphere.client.spbm").component(
         "editDefaultStoragePolicyComponent", <IComponentOptions>{
            controller: EditDefaultStoragePolicyComponentController,
            templateUrl: "spbm-ui/resources/spbm/views/profile/edit/EditDefaultStoragePolicyComponent.html",
            bindings: {
               policies: "<",
               defaultSelectedPolicy: "<",
               selectedPolicy: "=",
               isLoading: '<'
            }
         });

}
