namespace network_ui {

   import UplinkPortMapping = com.vmware.vsphere.client.h5.network.host.proxyswitch.model.UplinkPortMapping;
   import UpdateProxySwitchUplinkMappingSpec = com.vmware.vsphere.client.h5.network.host.proxyswitch.spec.UpdateProxySwitchUplinkMappingSpec;
   import ValidationResult = com.vmware.vise.core.model.ValidationResult;
   import IPromise = angular.IPromise;
   import IQService = angular.IQService;
   import ProxySwitchUplinkData = com.vmware.vsphere.client.h5.network.host.proxyswitch.model.ProxySwitchUplinkData;
   export class ManageProxySwitchPnicsDialogService {

      static $inject = [
         "i18nService",
         "clarityModalService",
         "mutationService",
         "clarityConstants",
         "$q"
      ];

      constructor(private i18nService: any,
                  private clarityModalService: any,
                  private mutationService: any,
                  private clarityConstants: any,
                  private $q: IQService) {
      }

      public show(hostId: string, dvsRef: ManagedObjectReference, proxySwitchKey: string, dvsName: string): void {

         let uplinkData: ProxySwitchUplinkData;
         let modalOptions: any = {
            title: this.i18nService.getString("H5NetworkUi", "manageVssPnicsDialog.title"),
            subTitle: {
               text: dvsName
            },
            contentTemplate: "network-ui/resources/network/views/host/proxyswitch/manageProxySwitchPnicsDialogTemplate.html",
            defaultButton: "submit",
            dialogData: {
               hostId: hostId,
               dvsRef: dvsRef,
               proxySwitchKey: proxySwitchKey,
               onUplinkMappingCreated: (newValue: ProxySwitchUplinkData): void => {
                  uplinkData = newValue;
               }
            },
            alerts: [],
            size: "lg",
            modalClass: "manage-pnic-modal",
            onSubmit: (): IPromise<boolean> => {
               let spec = new UpdateProxySwitchUplinkMappingSpec();
               spec.uplinkPortMappings = uplinkData.uplinkPortMapping;
               spec.proxySwitchKey = proxySwitchKey;

               let resultPromise: IPromise<boolean>;
               if (this.areAllUplinksFreeOrDisconnected(uplinkData)) {
                  resultPromise = this.mutationService.validate(hostId, spec._type, spec)
                        .then((validationResult: ValidationResult): IPromise<boolean> => {
                           let validationPromise: IPromise<boolean>;
                           if (validationResult.result) {
                              modalOptions.alerts = [{
                                 type: this.clarityConstants.notifications.type.ERROR,
                                 text: validationResult.result
                              }];
                              validationPromise = this.$q.when(false);
                           } else {
                              validationPromise =
                                    this.clarityModalService.openConfirmationModal({
                                       title: this.i18nService.getString("DvsUi", "HpsPhysicalNicEditor.warn.noActiveNics.title"),
                                       message: this.i18nService.getString("DvsUi", "HpsPhysicalNicEditor.warn.noActiveNics.text"),
                                       clarityIcon: {
                                          class: "is-warning",
                                          shape: "warning-standard",
                                          size: "32"
                                       },
                                       preserveNewlines: true
                                    });
                           }
                           return validationPromise;
                        });
               } else {
                  resultPromise = this.$q.when(true);
               }
               resultPromise.then((isOperationConfirmed: boolean): void => {
                  if (isOperationConfirmed) {
                     this.mutationService.apply(hostId, spec._type, spec);
                  }
               });
               return resultPromise;
            }
         };

         this.clarityModalService.openOkCancelModal(modalOptions);
      }

      private areAllUplinksFreeOrDisconnected(uplinkData: ProxySwitchUplinkData): boolean {
         return _.all(uplinkData.uplinkPortMapping,
               (mapping: UplinkPortMapping): boolean => mapping.pnicName === null || !uplinkData.pnicDetails[mapping.pnicName].actualSpeed);
      }
   }
   angular.module("com.vmware.vsphere.client.network")
         .service("manageProxySwitchPnicsDialogService", ManageProxySwitchPnicsDialogService);
}