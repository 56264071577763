module hostprofile_ui {

   class HostProfileSettingsController implements angular.IController {

      public static $inject = [
         "$rootScope",
         "$scope",
         "$timeout",
         "dataService",
         "vuiConstants",
         "i18nService",
         "actionsService",
         "$log",
         "vcService"
      ];

      public readonly EDIT_PROFILE: string = "vsphere.core.hostProfile.actions.editHostProfile";
      public readonly CHANGE_HOST_PROFILE_REF: string = "vsphere.core.hostProfile.actions.changeRefHostAction";
      public readonly COPY_HOST_PROFILE_SETTINGS_FROM_HOST_PROFILE: string =
            "vsphere.core.hostProfile.actions.copySettingsFromHostProfile";

      public selectedItemPath: Array<string>;
      public selectedItem: any;
      public actionUids: Array<string>;
      public actionsToRender: Array<any>;
      public hostProfileId: string;
      public profileWrapper: any;
      public objectsToWatch: Array<string>;
      public liveRefreshProperties: Array<string>;
      public validationStateProperty: string;
      public splitterOptions: any;
      public profileDataLoading: boolean = false;
      public profileValidating: boolean = false;
      public validationState: string;
      public showDropDown: boolean = false;
      public showCopyDropDown: boolean = false;
      public invokeActionThrottle: Function;
      public actionToRenderMap: any;
      public is65VcOrLater: boolean;

      constructor(private $rootScope: ScopeRoute,
                  private $scope: ScopeRoute,
                  private $timeout: any,
                  private dataService: any,
                  private vuiConstants: any,
                  private i18nService: any,
                  private actionsService: any,
                  private $log: any,
                  private vcService: any) {
         this.hostProfileId = this.$rootScope._route.objectId;
         this.objectsToWatch = [this.hostProfileId];
         this.liveRefreshProperties = ["config"];
         this.validationStateProperty = "validationState";

         this.actionUids = [this.EDIT_PROFILE, this.CHANGE_HOST_PROFILE_REF, this.COPY_HOST_PROFILE_SETTINGS_FROM_HOST_PROFILE];

         this.splitterOptions = {
            orientation: vuiConstants.splitter.orientation.HORIZONTAL,
            panes: [{
               min: '200px',
               size: '30%'
            }, {
               min: '400px',
               size: '70%'
            }]
         };

         this.vcService.is65VcOrLater(this.hostProfileId)
               .then((is65VcOrLater: boolean) => {
                  this.is65VcOrLater = is65VcOrLater;
               });
         this.getInitialData();
         this.getIsValidating("initialization");
         this.evaluateActions();

         this.invokeActionThrottle =
               _.debounce(this.actionsService.invokeAction.bind(this.actionsService), 5000, true);
         this.$scope.$on("hostProfileEditedEvent", (event, editedHostProfileId) => {
            if (this.hostProfileId === editedHostProfileId) {
               this.$log.debug("Scheduling validation check after receiving 'hostProfileEditedEvent'");
               this.profileValidating = true;
               this.$timeout(() => this.getIsValidating("timeout"), 5000);
            }
         });
      }

      public get loadingText(): string {
         return this.profileValidating ?
               this.i18nService.getString("HostProfileUi",
                     "hostProfile.validating") : "";
      }

      private evaluateActions(): void {
         this.actionsService.getActions(this.hostProfileId, this.actionUids)
             .then((actionEvaluations: Array<any>) => {
                this.actionToRenderMap = {};

                // Build actions to render items
                _.each(actionEvaluations, (actionEvaluation: any) => {

                   let actionUid: string = actionEvaluation.action.uid;

                   this.actionToRenderMap[actionUid] = {
                      label: actionEvaluation.action.label,
                      actionId: actionEvaluation.action.uid,
                      isEnabled: actionEvaluation.available,
                      eval: actionEvaluation
                   };
                });

                let actionsToRender: Array<any> = [];
                _.each(this.actionUids, (actionUid: string) => {
                   if (this.actionToRenderMap[actionUid]) {
                      actionsToRender.push(this.actionToRenderMap[actionUid]);
                   }
                });

                this.actionsToRender = actionsToRender;
             });
      }

      private invokeAction(action: any): void {
         this.invokeActionThrottle(action.eval,
               { selectedItemPath: this.selectedItemPath });
      }

      private invokeEditAction(action: any, type: string): void {
         this.invokeActionThrottle(action.eval,
               {
                  selectedItemPath: this.selectedItemPath,
                  editType: type
               });
      }

      private getInitialData() {
         this.profileDataLoading = true;
         this.dataService
               .getProperties(this.hostProfileId, ["hostApplyProfileData", "validationState"])
               .then((data: any) => {
                  this.profileWrapper = data.hostApplyProfileData.result;
                  this.validationState = data.validationState;
                  this.profileDataLoading = false;
               });
      }

      private getData() {
         this.profileDataLoading = true;
         this.dataService
               .getProperties(this.hostProfileId, ["hostApplyProfileData"])
               .then((data: any) => {
                  this.profileWrapper = data.hostApplyProfileData.result;
                  this.profileDataLoading = false;
               });
      }

      private refreshValidation() {
         this.getIsValidating("refresh");
         this.getValidationState();
      }

      private getIsValidating(caller: string) {
         this.dataService
               .getProperties(this.hostProfileId, ["isValidating"])
               .then((data: any) => {
                  this.$log.debug("Resolved 'getIsValidating' returned " + data.isValidating + ". Invoked by: " + caller);
                  this.profileValidating = data.isValidating;
               });
      }

      private getValidationState() {
         this.dataService
             .getProperties(this.hostProfileId, ["validationState"])
             .then((data: any) => {
                // validatonState is present only for VC 6.7 and later
                // so for earlier VC versions it would be unset and validation banner will not be shown
                this.validationState = data.validationState;
             });
      }

      private invokeActionById(actionId: string): void {
         if (this.actionToRenderMap) {
            const actionToInvoke: any = this.actionToRenderMap[actionId];
            this.actionsService.invokeAction(actionToInvoke.eval,
                  { selectedItemPath: this.selectedItemPath });
         }
      }

      private isActionAvailable(actionId: string): boolean {
         return this.actionToRenderMap && this.actionToRenderMap[actionId] && this.actionToRenderMap[actionId].isEnabled;
      }

      private isCopyAction(action: any) {
         return action && (this.isChangeHostReferenceAction(action) ||
               this.isCopyHostProfileAction(action));
      }

      private isChangeHostReferenceAction(action: any) {
         return this.CHANGE_HOST_PROFILE_REF === action.actionId;
      }

      private isCopyHostProfileAction(action: any) {
         return this.COPY_HOST_PROFILE_SETTINGS_FROM_HOST_PROFILE === action.actionId;
      }
   }

   angular.module("com.vmware.vsphere.client.hostprofile")
         .controller("HostProfileSettingsController", HostProfileSettingsController);
}
