namespace h5_vm {
   export class VmProvisioningSelectAnyTemplatePageService implements VmProvisioningPageService {
      static readonly $inject: string[] = [
         "i18nService",
         "vmVuiWizardPageBuilder",
         "creationTypeConstants",
         "vmProvisioningSelectAnyTemplatePageModel"
      ];

      constructor(private i18nService: any,
            private vmVuiWizardPageBuilder: any,
            private creationTypeConstants: any,
            private vmProvisioningSelectAnyTemplatePageModel: any) {
      }

      build(wizardScope: any): VmProvisioningWizardPage {
         let selectAnyTemplatePageModel =
               new this.vmProvisioningSelectAnyTemplatePageModel(wizardScope);

         wizardScope.selectAnyTemplateModel = selectAnyTemplatePageModel;

         let page = this.vmVuiWizardPageBuilder.buildVuiWizardPage(wizardScope.config, {
            title: this.i18nService.getString("VmUi", "SelectTemplateProvisioningPage.Title"),
            contentUrl: "vm-ui/resources/vm/views/createVmWizard/vmProvisioningSelectAnyTemplate.html",
            model: selectAnyTemplatePageModel
         });
         page.decisionOptions = {
            flowIds: [
               this.creationTypeConstants.DEPLOY_VM_FROM_TEMPLATE,
               this.creationTypeConstants.CLONE_TEMPLATE_TO_VM
            ],
            selectedFlowId: this.creationTypeConstants.DEPLOY_VM_FROM_TEMPLATE
         };
         return page;
      }
   }

   angular.module("com.vmware.vsphere.client.vm")
         .service("vmProvisioningSelectAnyTemplatePageService",
               VmProvisioningSelectAnyTemplatePageService);
}