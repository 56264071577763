namespace h5_vm {
   import Specification = com.vmware.vim.binding.vim.vm.customization.Specification;
   export class CustomizeGosPageModelBase {
      private _vmId: string;
      private _vmGuestOsFullName: string;

      constructor(vmId: string, vmGuestOsFullName: string) {
         this._vmId = vmId;
         this._vmGuestOsFullName = vmGuestOsFullName;
      }

      get vmId(): string {
         return this._vmId;
      }

      get vmGuestOsFullName(): string {
         return this._vmGuestOsFullName;
      }

      hasUnknownNameGen: boolean;
      hasUnknownIpv4Gen: boolean;
      hasUnknownIpv6Gen: boolean;

      onPageCommit?: Function;
   }
}