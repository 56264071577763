namespace h5_vm {

   import IQService = angular.IQService;
   import VmProvisioningPageService = h5_vm.VmProvisioningPageService;
   import VmProvisioningPage = h5_vm.VmProvisioningPageModel;
   import VmProvisioningWizardPage = h5_vm.VmProvisioningWizardPage;

   export class VmProvisioningEulaPageService implements VmProvisioningPageService {

      static $inject: string[] = [
         "i18nService",
         "vuiConstants",
         "vmVuiWizardPageBuilder",
         "vmProvisioningEulaPageModel"
      ];

      constructor(
         private i18nService: any,
         private vuiConstants: any,
         private vmVuiWizardPageBuilder: any,
         private vmProvisioningEulaPageModel: any) {}

      public build(wizardScope: any): VmProvisioningWizardPage {
         let pageModel = new this.vmProvisioningEulaPageModel(
               wizardScope.vmParams,
               wizardScope.wizardViewData);

         wizardScope.vmProvisioningEulaPageModel = pageModel;

         return this.vmVuiWizardPageBuilder.buildVuiWizardPage(
            wizardScope.config,
            {
               title: this.i18nService.getString("Common", "EulaControl.title"),
               description: this.i18nService.getString(
                  "ProvisioningUiLib", "AcceptEulaPage.errorEulaNotAccepted"),
               contentUrl: "vm-ui/resources/vm/views/createVmWizard/deployOvfPages" +
               "/vmProvisioningEula/vm-provisioning-eula.html",
               model: pageModel,
               defaultState: this.vuiConstants.wizard.pageState.SKIPPED
            }
         );
      }
   }

   angular.module("com.vmware.vsphere.client.vm")
      .service("VmProvisioningEulaPageService", VmProvisioningEulaPageService);

}
