/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */

(function () {
   'use strict';

   angular.module('com.vmware.vsphere.client.networkLibUi')
      .service('pnicSelectorDialogService', PnicSelectorDialogService);

   PnicSelectorDialogService.$inject = [
         'i18nService',
         'vuiConstants',
         'clarityModalService',
         'clarityConstants'];

   function PnicSelectorDialogService (i18nService, vuiConstants, clarityModalService, clarityConstants) {

      // ======================= Public API ==========================

      return {
         createPnicSelectorDialog: createPnicSelectorDialog
      };

      // ======================= Public Functions ==========================
      /**
       * Opens an OK/Cancel modal dialog in the application.
       *
       * @param unclaimedPnics Array of strings with pnicNames
       * @param pnicDetails Object containing properties pnicName and values pnicDetail
       * @param {vuiConstants.grid.selectionMode} selectionMode
       * @param dropdownOptions array of object with
       *          id: unique for the array used to be mapped to selected item in okHandler
       *          title: title label for the dropdown
       *          items: array of object with key/value properties for populating the dropdown
       *          selectedItem: one of the keys of the items array used for default dropdown option
       * @param okHandler callback to be invoked on Dialog confirmation
       *
       */
      function createPnicSelectorDialog(unclaimedPnics, pnicDetails, selectionMode, dropdownOptions, okHandler) {

         var dialogData = {};
         dialogData.hasDropdownOptions = dropdownOptions && dropdownOptions.length > 0;
         dialogData.dropdownOptions = dropdownOptions;
         dialogData.selectedPnics = [];
         dialogData.pnicSelectorOptions = {};

         dialogData.pnicSelectorOptions.datagridHeaderOptions = {
            title: i18nService.getString("NetworkUi", "FailoverOrderAddDialog.networkAdapters")
         };
         dialogData.pnicSelectorOptions.selectionMode =
               selectionMode ? selectionMode : vuiConstants.grid.selectionMode.MULTI;
         dialogData.pnicSelectorOptions.unclaimedPnics = unclaimedPnics;
         dialogData.pnicSelectorOptions.pnicDetails = pnicDetails;

         dialogData.pnicSelectorOptions.updateSelectedPnics = function (selectedItems) {
            dialogData.selectedPnics = selectedItems;
         };

         dialogData.noItemsSelectedMessage =
               i18nService.getString('NetworkUi', 'FailoverOrderAddDialog.detailsPrompt');



         var onClick = function () {
            if (dialogData.selectedPnics.length === 0) {
               showError(i18nService
                     .getString('H5NetworkUi', 'AddNetworkingWizard.pnicSelector.noPnicSelected'),
                     modalOptions);

               return false;
            }
            if (okHandler) {
               var dropdownSelections = {};
               _.each(dialogData.dropdownOptions, function (dropdown) {
                  dropdownSelections[dropdown.id] = dropdown.selectedItem;
               });
               okHandler(dialogData.selectedPnics, dropdownSelections);
            }
            return true;
         };

         var modalOptions = {
            title: i18nService.getString('NetworkUi', 'FailoverOrderAddDialog.title'),
            contentTemplate: 'network-lib-ui/resources/network-lib/host/pnic/' +
                  'pnicSelector/pnicSelectorDialog.html',
            defaultButton: 'submit',
            dialogData: dialogData,
            alerts: [],
            size: 'lg',
            modalClass: 'pnic-selector-dialog',
            onSubmit: onClick
         };


         clarityModalService.openOkCancelModal(modalOptions);
      }

      function showError(errorMessage, modalOptions) {
         modalOptions.alerts = [{
            text: errorMessage,
            type: clarityConstants.notifications.type.ERROR
         }];
      }
   }
}());