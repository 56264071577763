/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace network_ui {

   export class EditIpv6SupportComponent {

      public bindings: any;
      public controller: any;
      public templateUrl: string;

      constructor() {
         this.bindings = {
            hostId: "<",
            onSubmitWrapper: "="
         };
         this.controller = EditIpv6SupportController;
         this.templateUrl =
               "network-ui/resources/network/views/host/editIpv6SupportTemplate.html";
      }
   }

   class EditIpv6SupportController {

      static $inject: any = [
         "i18nService",
         "dataService",
         "$scope",
         "$q",
         "mutationService"
      ];
      private atBootIpV6EnabledProperty = "config.network.atBootIpV6Enabled";
      private ipv6EnabledProperty = "config.network.ipV6Enabled";

      public hostId: any;
      public onSubmitWrapper: any;

      public supportStateLabel: string;
      public supportStateValueLabel: string;
      public savedSupportState: boolean;
      public buttonLabel: string;
      public changeLabel: string;
      public supportButtonState: boolean;

      constructor(private i18nService: any,
                  private dataService: any,
                  private $scope: any,
                  private $q: any,
                  private mutationService: any) {
         this.supportStateLabel = i18nService.getString(
               "NetworkUi", "IPv6SupportView.ipv6SupportedDialogTitle");
         this.buttonLabel = i18nService.getString(
               "NetworkUi", "IPv6SupportView.enableIPv6SupportButtonLabel");
         this.changeLabel =
               i18nService.getString("NetworkUi", "IPv6SupportView.changeLabel");
      }

      $onInit() {
         if (this.onSubmitWrapper) {
            this.onSubmitWrapper.submit = this.submit.bind(this);
         }
         this.dataService.getProperties(this.hostId,
               [this.atBootIpV6EnabledProperty, this.ipv6EnabledProperty])
               .then((response: any) => {
                  this.savedSupportState = response[this.atBootIpV6EnabledProperty];

                  let statusChanged =
                        response[this.ipv6EnabledProperty] !== this.savedSupportState;
                  let enabledLabel =
                        this.i18nService.getString("NetworkUi", "IPv6SupportView.enabled");
                  let disabledLabel =
                        this.i18nService.getString("NetworkUi", "IPv6SupportView.disabled");
                  this.supportStateValueLabel = statusChanged ?
                        this.savedSupportState ? disabledLabel : enabledLabel :
                        this.savedSupportState ? enabledLabel : disabledLabel;
                  this.supportButtonState = this.savedSupportState;
               });
      }

      public submit() {
         if (this.savedSupportState !== this.supportButtonState) {
            let spec: any = {
               _type: "com.vmware.vsphere.client.network.NetworkConfigSpec",
               config: {
                  _type: 'com.vmware.vim.binding.vim.host.NetworkConfig',
                  ipV6Enabled: this.supportButtonState
               }
            };
            this.mutationService.apply(this.hostId, spec._type, spec);
         }
         return true;
      }
   }

   angular.module("com.vmware.vsphere.client.network")
         .component("editIpv6Support", new EditIpv6SupportComponent());
}
