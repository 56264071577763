/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace h5_vm {
   import ManagedObjectReference = com.vmware.vim.binding.vmodl.ManagedObjectReference;
   import VmCustomizationDuplicateSpec = com.vmware.vsphere.client.vm.customizations.VmCustomizationDuplicateSpec;
   import DuplicateGosResult = h5_vm.DuplicateGosSpecValidationResult;

   class DuplicateGosSpecController {
      public static $inject = [
         "$q",
         "i18nService",
         "vuiConstants",
         "userSessionService",
         "gosSpecValidationService",
         "duplicateGosSpecService",
         "idGeneratorService"
      ];

      vCentersData: any;
      vCenter: any;
      vcLabel: string;
      nameLabel: string;
      name: string = "";
      descriptionLabel: string;
      description: string = "";
      customizationSectionLabel: String;
      invalidNameMessage: string;
      sourceSpecInfo: CustomizationSpecInfoCustom;
      sourceSpecManagerRef: ManagedObjectReference;
      allSpecs: CustomizationSpecInfoCustom[];
      alerts: AlertMessage[];
      onSubmit: Function;
      submitDisabled: boolean;

      descriptionId: string;
      vcId: string;
      nameId: string;
      customizationSectionLabelId: string;
      invalidNameMessageId: string;

      constructor(private $q: angular.IQService,
                  private i18nService: any,
                  private vuiConstants: any,
                  private userSessionService: any,
                  private gosSpecValidationService: GosSpecValidationService,
                  private duplicateGosSpecService: DuplicateGosSpecService,
                  private idGeneratorService: any) {
         this.vcLabel =
               this.i18nService.getString("VmUi", "guestOsCustomization.import.vcLabel");
         this.nameLabel =
               this.i18nService.getString("VmUi", "guestOsCustomization.import.name");
         this.descriptionLabel =
               this.i18nService.getString("VmUi", "guestOsCustomization.import.description");
         this.customizationSectionLabel = this.i18nService.getString("VmUi",
               "guestOsCustomization.nameAndTargetOsPage.vmCustomizationSection");
      }

      $onInit(): void {
         this.sourceSpecManagerRef =
               this.sourceSpecInfo.vcService.content.customizationSpecManager;
         this.retrieveVcenterData();

         this.onSubmit = (): angular.IPromise<boolean> => {
            return this.submitForm();
         };

         this.createAriaIds();
      }

      createAriaIds() {
         this.descriptionId = this.idGeneratorService.nextId("descriptionId");
         this.vcId = this.idGeneratorService.nextId("vcId");
         this.nameId = this.idGeneratorService.nextId("nameId");
         this.customizationSectionLabelId = this.idGeneratorService.nextId("customizationSectionLabelId");
         this.invalidNameMessageId = this.idGeneratorService.nextId("invalidNameMessageId");
      }

      isNameValid(): boolean {
         this.invalidNameMessage = this.gosSpecValidationService.validateName(this.name,
               false, this.allSpecs, this.vCenter.name);
         return !this.invalidNameMessage;
      }

      private retrieveVcenterData(): void {
         this.submitDisabled = true;
         this.userSessionService.getAllServersInfo()
               .then((session: any) => {
                  this.vCentersData = session.serversInfo;
                  if (this.vCentersData && this.vCentersData.length > 0) {
                     this.vCenter = this.vCentersData[0];
                  }
               })
               .then(() => {
                  this.submitDisabled = false;
               });
      }

      private submitForm(): angular.IPromise<boolean> {
         const nameResult = this.gosSpecValidationService
               .validateName(this.name, true, this.allSpecs, this.vCenter.name);
         this.createAlerts(nameResult ? [nameResult] : []);

         if (nameResult) {
            return this.$q.resolve(false);
         }

         this.submitDisabled = true;

         const spec = new VmCustomizationDuplicateSpec();
         spec.sourceSpecName = this.sourceSpecInfo.name;
         spec.sourceSpecManagerRef = this.sourceSpecManagerRef;
         spec.newSpecName = this.name;
         spec.newSpecDesc = this.description;
         spec.targetVcGuid = this.vCenter.serviceGuid;

         return this.duplicateGosSpecService.validateSpec(spec).then((valRes: DuplicateGosResult) => {
            this.submitDisabled = false;

            if (!valRes.isValid && valRes.errors && valRes.errors.length > 0) {
               this.createAlerts(valRes.errors);
            }

            if (!valRes.isValid) {
               return valRes.isValid;
            }

            // if the VC is changed, close this dialog and open a new confirmation
            if (!valRes.keyValidationSucceeded) {
               this.duplicateGosSpecService.confirmKeyChange(spec);
               return valRes.isValid;
            }

            // if the VC is the same, duplicate the dialog
            return this.duplicateGosSpecService
                  .duplicateSpec(spec)
                  .then((dupRes: DuplicateGosResult) => {
                     if (!dupRes.isValid && dupRes.errors && dupRes.errors.length > 0) {
                        this.createAlerts(dupRes.errors);
                     }

                     return dupRes.isValid;
                  });

         });
      }

      private createAlerts(errors: string[]) {
         this.alerts = [];
         _.each(errors, (error) => {
            const alert: AlertMessage = {
               type: this.vuiConstants.validationBanner.type.ERROR,
               text: error
            };
            this.alerts.push(alert);
         });
      }
   }

   export class DuplicateGosSpecComponent {
      public controller: any;
      public templateUrl: string;
      public bindings: any;

      constructor() {
         this.bindings = {
            sourceSpecInfo: "<",
            allSpecs: "<",
            alerts: "=",
            onSubmit: "=",
            submitDisabled: "="
         };
         this.controller = DuplicateGosSpecController;
         this.templateUrl = "vm-ui/resources/vm/guest-os-customization/duplicate/duplicate-gos-spec.component.html";
      }
   }

   angular.module("com.vmware.vsphere.client.vm")
         .component("duplicateGosSpec", new DuplicateGosSpecComponent());
}
