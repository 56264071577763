/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace dvs_ui {

   export class DvsUpgradeReadyToCompletePageComponent {

      public bindings: any;
      public controller: any;
      public templateUrl: string;

      constructor() {
         this.bindings = {
            wizardModel: "<"
         };

         this.controller = DvsReadyToCompletePageComponentController;

         this.templateUrl = "dvs-ui/resources/dvs/upgrade/components/" +
               "dvsUpgradeReadyToCompletePageTemplate.html";
      }
   }

   class DvsReadyToCompletePageComponentController {

      static $inject = [
         "i18nService",
         "vxPropertyViewService",
         "lagPropertiesFormattingService"
      ];

      public wizardModel: DvsUpgradeWizardModel;

      private propertyViewData: any;
      private dvsUpgradeWarning: string;

      constructor(private i18nService: any,
                  private vxPropertyViewService: any,
                  private formattingService: LagPropertiesFormattingService) {

         this.dvsUpgradeWarning = this.i18nService.getString(
               "DvsUi", "DvsUpgradeWizard.summary.warningMessage");
      }

      $onInit(): void {
         this.buildPropertyView();
      }

      private buildPropertyView(): void {

         let builder: any = this.vxPropertyViewService.createPropertyViewBuilder();

         let generalCategory: any = builder.category("general");
         let generalSection: any = generalCategory.section("generalSection");

         // Distributed switch name
         generalSection.property(
               this.i18nService.getString(
                     "DvsUi", "DvsUpgradeWizard.summary.dvsName"),
               this.wizardModel.dvsName);

         // Current distributed switch version
         generalSection.property(
               this.i18nService.getString(
                     "DvsUi", "DvsUpgradeWizard.summary.currentDvsVersion"),
               this.wizardModel.currentVersion);

         // Upgrade distributed switch version
         generalSection.property(
               this.i18nService.getString(
                     "DvsUi", "DvsUpgradeWizard.summary.upgradeDvsVersion"),
               this.wizardModel.selectedVersion);

         // Upgrade Network I/O Control
         if (this.wizardModel.isExplicitNiocUpgradeSupported) {

            generalSection.property(
                  this.i18nService.getString(
                        "DvsUi", "DvsUpgradeWizard.summary.upgradeNioc"),
                  this.wizardModel.isExplicitNiocUpgradeSupported &&
                  this.wizardModel.isExplicitNiocUpgradeEnabled
                        ? this.i18nService.getString(
                        "DvsUi", "DvsUpgradeWizard.summary.enabled")
                        : this.i18nService.getString(
                        "DvsUi", "DvsUpgradeWizard.summary.disabled"));
         }

         // Upgrade LACP Support
         if (this.wizardModel.isExplicitLacpUpgradeSupported) {

            generalSection.property(
                  this.i18nService.getString(
                        "DvsUi", "DvsUpgradeWizard.summary.upgradeLacp"),
                  this.wizardModel.isExplicitLacpUpgradeSupported &&
                  this.wizardModel.isExplicitLacpUpgradeEnabled
                        ? this.i18nService.getString(
                        "DvsUi", "DvsUpgradeWizard.summary.enabled")
                        : this.i18nService.getString(
                        "DvsUi", "DvsUpgradeWizard.summary.disabled"));
         }

         // NIOC - migrate shares
         if (this.wizardModel.isExplicitNiocUpgradeSupported &&
               this.wizardModel.isExplicitNiocUpgradeEnabled &&
               this.wizardModel.niocUpgradeStatistics &&
               this.wizardModel.niocUpgradeStatistics.userDefinedNrpCount > 0) {

            let niocSection: any = generalCategory.section("niocSection").title(
                  this.i18nService.getString(
                        "DvsUi", "DvsUpgradeWizard.summary.niocSettings"));

            niocSection.property(
                  this.i18nService.getString(
                        "DvsUi", "DvsUpgradeNiocWizard.summary.migrateShares"),
                  this.wizardModel.migrateShares
                        ? this.i18nService.getString("DvsUi",
                        "DvsUpgradeNiocWizard.summary.migrateShares.on")
                        : this.i18nService.getString("DvsUi",
                        "DvsUpgradeNiocWizard.summary.migrateShares.off"));
         }

         // LACP support
         if (this.wizardModel.isExplicitLacpUpgradeSupported &&
               this.wizardModel.isExplicitLacpUpgradeEnabled) {

            if (this.wizardModel.lag !== null) {

               let lagSection:any = generalCategory.section("lagSection").title(
                     this.i18nService.getString(
                           "DvsUi", "DvsUpgradeWizard.summary.lacpSettings"));

               // LAG name
               lagSection.property(
                     this.i18nService.getString(
                           "DvsUi", "DvsUpgradeLacpWizard.summary.lagName"),
                     this.wizardModel.lag.name);

               // Number of ports
               lagSection.property(
                     this.i18nService.getString(
                           "DvsUi", "DvsUpgradeLacpWizard.createLag.numPorts"),
                     this.wizardModel.lag.uplinkNum.toString());

               // Mode
               lagSection.property(
                     this.i18nService.getString(
                           "DvsUi", "DvsUpgradeLacpWizard.createLag.mode"),
                     this.formattingService.getLacpModeText(this.wizardModel.lag.mode));

               // Load balancing
               lagSection.property(
                     this.i18nService.getString(
                           "DvsUi", "DvsUpgradeLacpWizard.createLag.loadBalancing"),
                     this.formattingService.getLoadBalancingModeText(
                           this.wizardModel.lag.loadbalanceAlgorithm));

            }
         }

         this.propertyViewData = builder.build();
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .component("dvsUpgradeReadyToCompletePage",
               new DvsUpgradeReadyToCompletePageComponent());
}
