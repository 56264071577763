(function() {
   'use strict';
   angular.module('com.vmware.vsphere.client.cluster')
         .controller('haSummaryViewController', HaSummaryViewController);

   HaSummaryViewController.$inject = ['$scope', 'vuiConstants', 'extensionService',
         'summaryService'];

   function HaSummaryViewController($scope, vuiConstants, extensionService, summaryService) {

      // This is used in case of plugin's portlets which need the objectId in the iFrame url
      var objectId = $scope._route ? $scope._route.objectId : null;
      this.watchForObjects = [objectId];
      this.liveRefreshProperties = ["configStatus"];

      $scope.refreshPortlets = refreshPortlets;
      $scope.refreshPortletsData = refreshPortletsData;
      $scope.portletOptions = {
         layout: vuiConstants.portlets.layout.TWO_COLUMNS,
         portlets: getSummaryPortletViews()
      };

      function refreshPortlets() {
         //Request portlet data from the server and update if necessary
         requestViewExtensions().then(function(extensions) {
            updateExistingPortlets(extensions);
         });
      }

      function updateExistingPortlets(portletSpecs) {
         var portletViews = summaryService.createPortlets(portletSpecs, objectId);
         $scope.portletOptions.portlets = summaryService.updatePortlets(portletViews,
               $scope.portletOptions.portlets);
      }

      function requestViewExtensions() {
         return extensionService.getHostedExtensions($scope._view.$id, objectId);
      }

      function refreshPortletsData() {
         $scope.$broadcast('haSummaryPortletsRefresh');
      }

      function getSummaryPortletViews() {
         return summaryService.createPortlets($scope._view.children, objectId);
      }
   }
})();
