angular.module('com.vmware.vsphere.client.vm').service('vmMigrationSelectComputeResourcePageService', [
   'i18nService',
   'vuiConstants',
   'wizardPageService',
   function(i18nService, vuiConstants, wizardPageService) {
      return {
         build: function(wizardScope) {
            return {
               title: i18nService.getString('VmUi', 'MigrationWizard.resourceListPage.title'),
               description: i18nService.getString('VmUi', 'MigrationWizard.resourceListPage.description'),
               contentUrl: 'vm-ui/resources/vm/views/migrationWizard/pages/selectComputeResourcePage.html',
               headerUrl: 'vm-ui/resources/vm/views/migrationWizard/pages/migratePagesCustomHeader.html',
               disableNext: function() {
                  return wizardScope.migrationWizardManager.computeResourcePageModel.isValidating;
               },
               onCommit: function() {
                  if (!wizardScope.migrationWizardManager.hasValidComputeResourceSelected()) {
                     wizardPageService.markPageIncompleteWithError(
                           wizardScope.wizardConfig,
                           i18nService.getString('VmUi', 'MigrationWizard.resourceListPage.error.invalid')
                     );

                     return false;
                  }

                  wizardScope.wizardConfig.validationBanner.messages = [];
                  wizardPageService.markPageComplete(wizardScope.wizardConfig,
                        wizardScope.attributes.creationType);

                  return true;
               }
            };
         }
      };
   }
]);
