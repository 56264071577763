/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */

(function () {
   var module = angular.module('com.vmware.vsphere.client.networkLibUi');
   module.constant('networkUiConstants', {
      POSITIVE_INTEGER_REG_EXP: new RegExp('^[0-9]+$'),
      MAX_SAFE_INTEGER: 9007199254740991,
      MAX_HOST_NAME_LENGTH: 63,
      MAX_DOMAIN_NAME_LENGTH: 63,
      HOST_MAX_NUMBER_OT_CONNECTIONS_MAX: 20000,
      HOST_MAX_NUMBER_OT_CONNECTIONS_MIN: 2000,
      FQDN_MAX_LENGTH: 255,
      MAX_NUMBER_SEARCH_DOMAINS: 6,
      Mtu: {
         DEFAULT: 1500,
         MIN: 1280,
         MAX: 9000
      },
      NumPorts: {
         DEFAULT: 128,
         MIN: 16,
         MAX: 4096
      },
      DvPortgroupNumPorts: {
         MIN: 0,
         MAX: 8192
      },
      Ipv6PrefixLength: {
         DEFAULT: 64
      },
      DatagridActions: {
         ADD: 'vsphere.core.network.wizard.actions.addIpV6',
         EDIT: 'vsphere.core.network.wizard.actions.editIpV6',
         DELETE: 'vsphere.core.network.wizard.actions.deleteIpV6'
      },
      LoadBalancingPolicy: {
         SRCID: 'loadbalance_srcid',
         IP: 'loadbalance_ip',
         SRCMAC: 'loadbalance_srcmac',
         EXPLICIT: 'failover_explicit',
         LOADBASED: 'loadbalance_loadbased'
      },
      TrafficShapingSetting: {
         AVERAGE_BANDWIDTH: "averageBandwidth",
         PEAK_BANDWIDTH: "peakBandwidth",
         BURST_SIZE: "burstSize"
      },
      Vlan: {
         Type: {
            NONE: 'none',
            VLAN: 'vlan',
            TRUNK: 'trunk',
            PRIVATE: 'private'
         },
         Id : {
            NONE: 0,
            MIN: 1,
            MAX: 4094,
            ALL: 4095
         },
         PvlanType: {
            PROMISCUOUS: 'promiscuous',
            COMMUNITY: 'community',
            ISOLATED: 'isolated'
         },
         DEFAULT_VLAN_TRUNK_RANGE: "0-4094"
      },
      PortgroupType: {
         EARLY_BINDING: 'earlyBinding',
         LATE_BINDING: 'lateBinding',
         EPHEMERAL: 'ephemeral'
      },
      actions: {
         EDIT_DVS_NOTES: 'vsphere.core.dvs.actions.editNotes',
         ADD_NETWORKING: 'vsphere.core.network.actions.addNetworking',
         EDIT_VNIC: 'vsphere.core.host.network.actions.editVnicAction',
         REMOVE_VNIC: 'vsphere.core.host.network.actions.removeVnicAction',
         EDIT_PORTGROUP: 'vsphere.core.host.network.editPortgroupSettingsAction',
         EDIT_DVPG: 'vsphere.core.dvPortgroup.editSettingsAction',
         EXPORT_DVPG: 'vsphere.core.dvPortgroup.exportConfiguration',
         IMPORT_DVPG: 'vsphere.core.dvPortgroup.importConfiguration',
         RESTORE_DVPG: 'vsphere.core.dvPortgroup.restoreConfiguration',
         EDIT_DVPG_NOTES: 'vsphere.core.dvPortgroup.actions.editNotes',
         REMOVE_PORTGROUP: 'vsphere.core.host.network.removePortgroupAction',
         EDIT_VSWITCH: 'vsphere.core.host.network.editVSwitchSettingsAction',
         REMOVE_VSWITCH: 'vsphere.core.host.network.removeVirtualSwitchAction',
         MANAGE_PNICS: 'vsphere.core.host.network.managePhysicalNicsAction',
         MIGRATE_HOST_NETWORKING: 'vsphere.core.dvs.migrateHostNetworkingAction',
         ADD_PORT_BINDING_FOR_ISCSI: 'vsphere.core.network.addIscsiPortBindingAction',
         REMOVE_PORT_BINDING_FROM_ISCSI: 'vsphere.core.network.removeIscsiPortBindingAction',
         SHOW_ISCSI_PORT_BINDING_DETAILS: 'vsphere.core.network.showIscsiPortBindingDetailsAction',
         EDIT_DNS_AND_ROUTING: 'vsphere.core.host.network.editDnsAndRoutingAction',
         EDIT_IPV6_SUPPORT: 'vsphere.core.host.network.editIpv6SupportAction',
         VM_MIGRATE: 'vsphere.core.network.vmMigrateAction',
         RENAME_DV_PORTGROUP: 'vsphere.core.dvPortgroup.renameAction',
         REFRESH_NETWORK: 'vsphere.core.host.network.refreshNetworkAction',
         EDIT_PNIC: 'vsphere.core.host.network.actions.editPnicAction',
         REMOVE_SPAN_SESSION: 'vsphere.core.dvs.spanSession.removeAction',
         ADD_SPAN_SESSION: 'vsphere.core.dvs.spanSession.addAction',
         EDIT_SPAN_SESSION: 'vsphere.core.dvs.spanSession.editAction',
         HOST_OF_SYNC_DVS_HOST: "vsphere.core.dvs.configissues.HostOutOfSyncIssue",
         REMOVE_DVS: "vsphere.core.dvs.removeHostFromDvsAction",
         RECTIFY_HOSTS_OUT_OF_SYNC_DVS: "vsphere.core.dvs.configissues.RectifyHostOutOfSync",
         MIGRATE_VMK_TO_VSWITCH: "vsphere.core.host.network.migrateVmkAction",
         GHOST_DVS_PROXY_SWITCH_DETACHED: "vsphere.core.network.configissues.GhostDvsProxySwitchDetectedIssue",
         NETWORK_MOVE_TO_ACTION: "vsphere.core.network.actions.moveTo",
         DVS_MOVE_TO_ACTION: "vsphere.core.dvs.actions.moveTo",
         NETWORK_MOVE_TO_DRAG_AND_DROP_ACTION: "vsphere.core.network.actions.moveNetworkToDrag",
         DVS_MOVE_TO_DRAG_AND_DROP_ACTION: "vsphere.core.dvs.actions.moveDvsToDrag",
         EDIT_DVPORT: "vsphere.core.dvs.editPortAction",
         VSS_VIEW_SETTINGS: "vsphere.core.host.network.vss.viewSettings",
         PROXY_SWITCH_VIEW_SETTINGS: "vsphere.core.host.network.proxySwitch.viewSettings"
      },
      failoverOrderActions: {
         ADD: 'add-action',
         REMOVE: 'remove-action',
         MOVE_UP: 'moveup-action',
         MOVE_DOWN: 'movedown-action'
      },
      switchType: {
         STANDARD: 'standardSwitch',
         DISTRIBUTED: 'distributedSwitch'
      },
      UnitFormat: {
         KBPS: 1000,
         KB: 1024
	   },
      MulticastFiltering: {
          BASIC: 'legacyFiltering',
          SNOOPING: 'snooping'
      },
      LinkDiscoveryOperation: {
          LISTEN: 'listen',
          ADVERTISE:'advertise',
          BOTH: 'both',
          DISABLED: 'none'
      },
      LinkDiscoveryProtocol: {
          CDP: 'cdp',
          LLDP: 'lldp'
      },
      LacpSupport: {
         BASIC: 'singleLag',
         ENHANCED: 'multipleLag'
      },
      LacpMode: {
         ACTIVE: 'active',
         PASSIVE: 'passive'
      },
      ManageDvpgWizard: {
         DVPG_CONFIG_VERSION_PROP: 'config.configVersion'
      },
      TcpIpStack: {
         DEFAULT_NET_STACK_KEY: 'defaultTcpipStack',
         VMOTION_NET_STACK_KEY: 'vmotion'
      }
   });
} ());
