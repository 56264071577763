/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
/*
 * Service which handles the prefetching of the networks for networkSelectorController.
 */
(function () {
   'use strict';
   angular.module('com.vmware.vsphere.client.networkLibUi')
         .service('networkSelectorPrefetchingService', networkSelectorPrefetchingService);

   networkSelectorPrefetchingService.$inject = ['dataService', 'defaultUriSchemeUtil'];

   function networkSelectorPrefetchingService(dataService, defaultUriSchemeUtil) {

      return {
         prefetchNetworks: prefetchNetworks
      };

      function prefetchNetworks(objectRef, filterSpec, availableNetworks, prefetchedNetworksCount,
                                additionalParams) {
         var recentNetworks = [];

         var networkSelectorFilteredNetworksProp = "networkSelectorFilteredNetworks";
         var param = {
            includeDistributedPortGroups: filterSpec.includeDistributedPortGroups,
            includeStandardNetworks: filterSpec.includeStandardNetworks,
            includeUplinkPortGroups: filterSpec.includeUplinkPortGroups,
            includeOpaqueNetworks: filterSpec.includeOpaqueNetworks,
            includeOpaqueNetworkTypes: filterSpec.includeOpaqueNetworkTypes,
            networks: availableNetworks,
            networksCount: prefetchedNetworksCount
         };

         var options = {
            propertyParams: [{
               propertyName: networkSelectorFilteredNetworksProp,
               parameterType: "com.vmware.vsphere.client.modules.api.networkselector.NetworkSelectorFilterSpec",
               parameter: param
            }]
         };

         if (!!additionalParams && additionalParams.skipLoadingNotification) {
            options.skipLoadingNotification = additionalParams.skipLoadingNotification;
         }

         return dataService.getProperties(objectRef, [networkSelectorFilteredNetworksProp], options).then(function(result) {
            var networkInfo = result[networkSelectorFilteredNetworksProp];

            _.forEach(networkInfo.networks, function(nw) {
               pushRecentNetwork({
                  name: nw.name,
                  id: defaultUriSchemeUtil.getVsphereObjectId(nw.networkRef),
                  dvsUuid: nw.dvsUuid,
                  portgroupKey: nw.portgroupKey,
                  opaqueNetworkType: nw.opaqueNetworkType
               });
            });

            var res = {
               recentNetworks: recentNetworks,
               networkMatchesCount: result[networkSelectorFilteredNetworksProp].totalMatchedObjectCount
            };
            return res;
         });

         function pushRecentNetwork(network) {
            if (_.findIndex(recentNetworks, function(value) {
                     return value.id === network.id;
                  }) < 0) {
               recentNetworks.push(network);
            }
         }
      }
   }
})();
