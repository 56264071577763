namespace network_ui {
   export class NetStackEditRoutingPageValidator {
      public static $inject = [
         "i18nService",
         "ipParserService"
      ];

      constructor(private i18nService: any,
                  private ipParserService: any) {
      }

      public getIpv4GatewayValidationMessage(ipv4Gateway: string): string | null {
         let isValid: boolean = this.isEmptyValue(ipv4Gateway)
               || this.ipParserService.isIpv4AddressValid(ipv4Gateway);

         return isValid ? null : this.i18nService.getString(
               "NetworkUi", "DnsAndRoutingPage.ipv4VmkernelGateway.error");
      }

      public getIpv6GatewayValidationMessage(ipv6Gateway: string): string | null {
         let isValid: boolean = this.isEmptyValue(ipv6Gateway)
               || this.ipParserService.isIpv6AddressValid(ipv6Gateway, true);

         return isValid ? null : this.i18nService.getString(
                     "NetworkUi", "DnsAndRoutingPage.ipv6VmkernelGateway.error");
      }

      private isEmptyValue(value: any): boolean {
         return value === undefined || value === null || value === "";
      }
   }

   angular.module("com.vmware.vsphere.client.network")
         .service("netStackEditRoutingPageValidator", NetStackEditRoutingPageValidator);
}
