/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace h5_vm {

   import ManagedObjectReference = com.vmware.vim.binding.vmodl.ManagedObjectReference;

   export class MigrateSelectNetworkPageComponent {
      public templateUrl: string = "vm-ui/resources/vm/views/migrationWizard/pages/migrateSelectNetworkPageComponent.html";
      public controller: any = MigrateSelectNetworkPageComponentController;
      public controllerAs = "ctrl";
      public bindings: {} = {
         migrationWizardManager: "<",
         wizardConfig: "<",
         pageModel: "<",
         destinationTarget: "<"
      };
   }

   export class MigrateSelectNetworkPageComponentController {
      public static $inject = [
         "$scope",
         "i18nService",
         "vmMigrateSelectNetworkService",
         "NetworkPageViewModes",
         "defaultUriSchemeUtil",
         "zoneDigestPatchService"
      ];

      public migrationWizardManager: MigrationWizardManager;
      public wizardConfig: any;
      public pageModel: MigrateSelectNetworkPageModel;
      public destinationTarget: any;

      //
      // Properties accessed in the HTML
      //
      public isValidating: boolean = false;
      public pageReady: boolean = false;
      public compatibilityTitle: string;
      public compatibilityMessages: any[];
      public selectNetworkGridDescription: string;
      public networkPageAriaLabel: string;
      public advancedButtonText: string;
      public vmName: string;
      public basicViewData: any;
      public advancedViewData: any;
      public prefetchedNetworksBasic: any;
      public prefetchedNetworksAdvanced: any;

      public vmMigrateSelectNetworkService: VmMigrateSelectNetworkService;

      constructor(
         private $scope: any,
         private i18nService: {
            getString: (file: string, label: string) => string
         },
         private vmMigrateSelectNetworkServiceClass: any,
         private networkPageViewModes: any,
         private defaultUriSchemeUtil: {
            getVsphereObjectId: (ref: any) => string,
         },
         private zoneDigestPatchService: any
      ) {
         this.compatibilityTitle = this.i18nService.getString(
               "VmUi", "MigrationWizard.networkPage.compatibility");
         this.networkPageAriaLabel = this.i18nService.getString(
               "VmUi", "MigrationWizard.networkPage.description");
         this.selectNetworkGridDescription = this.i18nService.getString(
               "Common", "vns.basic.description");
      }

      private requestData(vmIds: string[]) {
         this.setIsLoading(true);

         this.vmMigrateSelectNetworkService
               .requestData(vmIds)
               .then((result: any) => {
            if (!!result.prefetchedNetworksPromise) {
               result.prefetchedNetworksPromise.then(() => {
                  this.$scope.$broadcast("onPrefetchNetworksCompleted");
               });
            }

            this.setIsLoading(false);
            this.onDataRequested();
            this.pageModel.setSelectNetworkPageIsInitialized(true);
         }, () => {
            this.setIsLoading(false);
         });
      }

      public getViewMode() {
         return this.pageModel.getSelectNetworkPageViewMode();
      }

      private setIsLoading(isLoading: boolean) {
         this.wizardConfig.loading = isLoading;
      }

      private setIsValidating(isValidating: boolean) {
         this.isValidating = isValidating;
         this.pageModel.setSelectNetworkPageIsValidating(this.isValidating);
         // patching the application because there is a issue which
         // prevents the digest from execution when data is changed
         // TODO - vasila remove this call when the issue is resolved
         this.zoneDigestPatchService.digest();
      }

      public $onInit() {
         const computeResourceUrn = this.defaultUriSchemeUtil.getVsphereObjectId(
               this.migrationWizardManager.getDestinationComputeResourceRef());

         this.pageModel.checkResourceAndReset(computeResourceUrn);

         this.vmMigrateSelectNetworkService = new this.vmMigrateSelectNetworkServiceClass(
               this.migrationWizardManager, this.pageModel,
               (v: any) => this.setIsValidating(v));

         this.pageReady = false;
         this.compatibilityMessages = [];

         if (this.pageModel.getSelectNetworkPageViewMode() ===
               this.networkPageViewModes.BASIC) {
            this.advancedButtonText = this.i18nService.getString(
                  "Common", "vns.switchToAdvanced");
         } else {
            this.advancedButtonText = this.i18nService.getString(
                  "Common", "vns.switchToBasic");
         }

         // TODO speev: Is this vmName variable used?
         this.vmName = this.migrationWizardManager.getVmName();
         this.compatibilityMessages = this.vmMigrateSelectNetworkService.getCompatibilityMessages();

         if (!this.pageModel.getSelectNetworkPageIsInitialized()) {
            this.pageModel.setBasicCompatibilityResultsCache(null);
            this.pageModel.setAdvancedCompatibilityResultsCache(null);
            this.pageModel.isBasicPageValid = false;
            this.pageModel.isAdvancedPageValid = false;
            this.requestData(this.migrationWizardManager.getVmIds());
         } else {
            this.onDataRequested();
            this.vmMigrateSelectNetworkService.buildBasicData();
            this.vmMigrateSelectNetworkService.buildAdvancedData();
            this.vmMigrateSelectNetworkService.checkCompatibility();
         }
      }

      private onDataRequested() {
         this.pageReady = true;
         this.basicViewData = this.vmMigrateSelectNetworkService.getBasicViewData();
         this.advancedViewData = this.vmMigrateSelectNetworkService.getAdvancedViewData();
         this.prefetchedNetworksBasic = this.vmMigrateSelectNetworkService.
               getPrefetchedNetworks().basic;
         this.prefetchedNetworksAdvanced = this.vmMigrateSelectNetworkService.
               getPrefetchedNetworks().advanced;
      }

      public onAndvancedButtonClick() {
         if (this.pageModel.getSelectNetworkPageViewMode() ===
               this.networkPageViewModes.ADVANCED) {
            this.pageModel.setSelectNetworkPageViewMode(
                  this.networkPageViewModes.BASIC);

            this.selectNetworkGridDescription = this.i18nService.getString(
                  "Common", "vns.basic.description");
            this.advancedButtonText = this.i18nService.getString(
                  "Common", "vns.switchToAdvanced");
         } else {
            this.pageModel.setSelectNetworkPageViewMode(
                  this.networkPageViewModes.ADVANCED);

            this.selectNetworkGridDescription = this.i18nService.getString(
                  "Common", "vns.advanced.description");
            this.advancedButtonText = this.i18nService.getString(
                  "Common", "vns.switchToBasic");
         }

         this.vmMigrateSelectNetworkService.syncNetworkMatches();
         this.vmMigrateSelectNetworkService.checkCompatibility();
      }
   } // class

   angular.module("com.vmware.vsphere.client.vm").component(
         "migrateSelectNetworkPageComponent", new MigrateSelectNetworkPageComponent());

} // namespace