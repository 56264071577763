module hostprofile_ui {

   export class HostsCustomizations implements angular.IComponentOptions {

      public bindings: any;
      public controller: any;
      public templateUrl: string;

      constructor() {
         this.bindings = {
            data: "<",
            errors: "<",
            targetType: "@"
         };
         this.controller = HostsCustomizationsController;
         this.templateUrl = "hostprofile-ui/resources/hostprofile/customizations/hostCustomizationsComponent.html";
      }
   }

   export class HostsCustomizationsController implements angular.IComponentController {

      static $inject = [
         "i18nService",
         "managedEntityConstants",
         "hostCustomizationsErrorsService",
         "hostCustomizationsService"
      ];

      public targetType: string;
      public data: HostCustomizationItem[];
      public errors: HostCustomizationError[];
      public paramToErrorMap: any;
      public datagridOptions: any;
      public filterPlaceholderText: string;
      public hostCustomizationsGridTitle: string;
      public searchTerm: string;

      private KEY_SPLITTER: string = "/";

      constructor(private i18nService: any,
            private managedEntityConstants: any,
            private hostCustomizationsErrorsService: HostCustomizationsErrorsService,
            private hostCustomizationsService: HostCustomizationsService) {
         this.datagridOptions = this.getGridOptions();
         this.filterPlaceholderText = this.i18nService.getString("Common", "filter.label");
         this.hostCustomizationsGridTitle = this.i18nService.getString(
               "HostProfileUi", "host.customizations.datagrid.title");
         this.searchTerm = "";
      }

      public $onInit() {
         this.paramToErrorMap = this.hostCustomizationsErrorsService.getParamErrorsMap(
               this.data, this.errors);
         this.datagridOptions.data = this.createGridData(this.data);
      }

      public $onChanges(changes: any) {
         if (changes.errors) {
            this.paramToErrorMap = this.hostCustomizationsErrorsService.getParamErrorsMap(
                  this.data, this.errors);
         }

         if (this.datagridOptions  && (changes.data || changes.errors)) {
            this.datagridOptions.data = this.createGridData(this.data);
         }
      }

      private createGridData(hostCustomization: any): Array<HostCustomizationItem> {
         return _.map(hostCustomization, (item: HostCustomizationItem, index: number) => {
            return _.extend({index: index, error: this.getItemError(item)}, item);
         });
      }

      private getGridOptions() {
         return {
            selectionMode: "single",
            sortMode: "single",
            searchable: true,
            columnDefs: this.getColumnDefs(),
            data: [],
            height: "100%",
            resizable: true
         };
      }

      private getColumnDefs(): void {
         let columnDefs: any = [];
         if (this.targetType === this.managedEntityConstants.CLUSTER) {
            columnDefs.push({
               field: "hostName",
               sortable: true,
               searchable: false,
               displayName: this.i18nService.getString("HostProfileUi",
                     "hostCustomizations.column.host")
            });
         }
         columnDefs.push({
            field: "requiredValue",
            sortable: true,
            searchable: false,
            displayName: this.i18nService.getString("HostProfileUi",
                  "hostCustomizations.column.required")
         });
         columnDefs.push({
            field: "name",
            sortable: true,
            searchable: false,
            displayName: this.i18nService.getString("HostProfileUi",
                  "hostCustomizations.column.propertyName"),
            template: "<span title='#:name#'>#:name#</span>"
         });
         columnDefs.push({
            field: "description",
            sortable: true,
            searchable: false,
            displayName: this.i18nService.getString("HostProfileUi",
                  "hostCustomizations.column.path"),
            template: "<span title='#:description#'>#:description#</span>"
         });
         columnDefs.push({
            field: "value",
            sortable: true,
            searchable: false,
            displayName: this.i18nService.getString("HostProfileUi",
                  "hostCustomizations.column.value"),
            template: (dataItem: any) => {
               if (dataItem.valueType === "password") {
                  return "<password-text-input" +
                        " item='dataItem'></password-text-input>";
               }

               return "<customization-text-input" +
                     " item='dataItem'></customization-text-input>";
            }
         });
         return columnDefs;
      }

      public updateHostCustomizationSetting(dataItem: HostCustomizationItem) {
         let hostCustomizationSetting: any = this.data[dataItem.index];
         if (hostCustomizationSetting) {
            hostCustomizationSetting.value = dataItem.value;
         }
      }

      private getItemError(item: HostCustomizationItem): any {
         let key: string = item.hostName + this.KEY_SPLITTER + item.inputPath.profilePath +
               this.KEY_SPLITTER + item.inputPath.policyId +
               this.KEY_SPLITTER + item.inputPath.parameterId;

         let errorMessage: string = this.paramToErrorMap[key];

         if (errorMessage) {
            return {
               message: errorMessage,
               isVisible: true
            };
         }

         return null;
      }

      private filterSettings(): void {
         this.datagridOptions.data = this.hostCustomizationsService.filterCustomizations(this.searchTerm, this.data);
      }
   }

   angular.module("com.vmware.vsphere.client.hostprofile")
         .component("hostCustomizations", new HostsCustomizations());
}
