namespace h5_vm {
   import IPromise = angular.IPromise;
   import VmProvisioningPageModel = h5_vm.VmProvisioningPageModel;

   class CloneVmLocationPageModelFactory {
      public static $inject = ["i18nService", "authorizationService"];

      constructor(i18nService: any, authorizationService: any) {
         return (wizardViewData: any, wizardScope: any) => {
            return new CloneVmLocationPageModel(
                  wizardViewData,
                  wizardScope,
                  i18nService,
                  authorizationService);
         };
      }
   }

   export class CloneVmLocationPageModel implements VmProvisioningPageModel<any> {

      static $inject = [];

      constructor(public wizardViewData: any,
         public wizardScope: any,
         public i18nService: any,
         public authorizationService: any) { }

      public resetForm(): void { }

      public validatePage(): any {
         //No selection
         if (!this.wizardScope.cloneVmLocationPageModel.contentLibrary) {
            return {
               error: this.i18nService.getString(
                     "ProvisioningUiLib", "LocationPage.error.noLibrarySelected")
            };
         } else {
            //Check privilege on library
            const libraryId: string = this.wizardScope.cloneVmLocationPageModel.contentLibrary.id;
            return this.authorizationService
                  .checkGrantedPrivileges(libraryId, [h5_vm.Privileges.ADD_LIBRARY_ITEM])
                  .then((hasPrivileges: Array<boolean>) => {
                     let result: any = {};
                     if (!hasPrivileges[0]) {
                        result['error'] = this.i18nService.getString(
                              "ProvisioningUiLib", "LocationPage.error.noPrivilege");
                     }
                     return result;
                  });
         }
      }

      public submitPage(validationResult: any): any {
         return { };
      }
   }

   angular.module("com.vmware.vsphere.client.vm")
         .service("cloneVmLocationPageModel",
               CloneVmLocationPageModelFactory);
}
