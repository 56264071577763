namespace h5_vm {
   import VmProvisioningFinishPageModel =
         h5_vm.VmProvisioningFinishPageModel;
   import IPromise = angular.IPromise;
   import IQService = angular.IQService;
   import VirtualDiskOption$CompatibilityMode = com.vmware.vim.binding.vim.vm.device.VirtualDiskOption$CompatibilityMode;

   class VmProvisioningFinishCreateVmPageModelFactory {
      public static $inject = [
         "$q",
         "dataService",
         "i18nService",
         "addVmService",
         "vmDeviceInfoService",
         "defaultUriSchemeUtil",
         "bytesFilter",
         "storageProfileService",
         "vmHardwareVersionService",
         "managedEntityConstants",
         "diskModeService",
         "datastoreRecommendationService",
         "spbmReplicationGroupInfoService",
         "creationTypeConstants"
      ];

      constructor(
            $q: IQService,
            dataService: any,
            i18nService: any,
            addVmService: any,
            vmDeviceInfoService: any,
            defaultUriSchemeUtil: any,
            bytesFilter: any,
            storageProfileService: any,
            vmHardwareVersionService: any,
            managedEntityConstants: any,
            diskModeService: DiskModeService,
            datastoreRecommendationService: any,
            spbmReplicationGroupInfoService: any,
            creationTypeConstants: any) {
         return (virtualMachineSpecBuilder: any, wizardViewData: any, scheduledTaskData: any) => {
            return new VmProvisioningFinishCreateVmPageModel(
                  $q,
                  dataService,
                  i18nService,
                  addVmService,
                  vmDeviceInfoService,
                  defaultUriSchemeUtil,
                  bytesFilter,
                  storageProfileService,
                  vmHardwareVersionService,
                  managedEntityConstants,
                  diskModeService,
                  datastoreRecommendationService,
                  spbmReplicationGroupInfoService,
                  creationTypeConstants,
                  virtualMachineSpecBuilder,
                  wizardViewData,
                  scheduledTaskData);
         };
      }
   }

   export class VmProvisioningFinishCreateVmPageModel extends VmProvisioningFinishPageModel {

      static $inject: string[] = [
         "$q",
         "dataService",
         "i18nService",
         "addVmService",
         "vmDeviceInfoService",
         "defaultUriSchemeUtil",
         "bytesFilter",
         "storageProfileService",
         "vmHardwareVersionService",
         "managedEntityConstants",
         "diskModeService",
         "datastoreRecommendationService",
         "spbmReplicationGroupInfoService",
         "creationTypeConstants"
      ];

      constructor($q: IQService,
            private dataService: any,
            i18nService: any,
            addVmService: any,
            vmDeviceInfoService: any,
            defaultUriSchemeUtil: any,
            private bytesFilter: any,
            storageProfileService: any,
            private vmHardwareVersionService: any,
            managedEntityConstants: any,
            diskModeService: DiskModeService,
            protected  datastoreRecommendationService: any,
            protected spbmReplicationGroupInfoService: any,
            protected creationTypeConstants: any,
            virtualMachineSpecBuilder: any,
            wizardViewData: any, scheduledTaskData: any) {

         super($q, i18nService, addVmService, vmDeviceInfoService,
               defaultUriSchemeUtil, storageProfileService, diskModeService,
               managedEntityConstants, datastoreRecommendationService,
               spbmReplicationGroupInfoService, creationTypeConstants,
               virtualMachineSpecBuilder, wizardViewData, scheduledTaskData);

      }

      public getSchedulingData(): any {
         return this.scheduledTaskData;
      }

      public submitPage(): IPromise<boolean> {
         this.wizardViewData.setDatastoreObjectName(
               this.form.highestRecommendedDatastoreName);
         return super.submitPage();
      }

      public getDisks(): Array<any> {
         let createdDisks: any =
               _.pluck(this.virtualMachineSpecBuilder.getDiskChanges(), "device");
         return _.map(createdDisks, this.buildDiskInfo.bind(this));
      }

      public getGuestOsName(): string {
         return this.virtualMachineSpecBuilder.getGosVersion().name;
      }

      public getVbsEnabled(): string {
         return this.virtualMachineSpecBuilder.getVbsEnabled() ?
             this.i18nService.getString("VmUi", "ProvisioningWizardSummaryPage.enabled") :
             this.i18nService.getString("VmUi", "ProvisioningWizardSummaryPage.disabled") ;
      }

      public getTpmDevice(): any {
         let tpmDevices: any =
             _.pluck(this.virtualMachineSpecBuilder.getTpmChanges(), "device");
         // Only one tpm device can exist at max so always pick index 0
         return tpmDevices.length ? tpmDevices[0] : null;
      }

      public getNumberOfCpus(): number {
         return this.virtualMachineSpecBuilder.getVmConfigSpec().config.hardware.numCPU;
      }

      public getAllocatedMemory(): string {
         let memoryMB: number =
               this.virtualMachineSpecBuilder.getVmConfigSpec().config.hardware.memoryMB;
         let memoryUnitPreferred: any =
               this.virtualMachineSpecBuilder.getVmConfigSpec().config.hardware.memoryUnitPreferred;

         if (memoryUnitPreferred) {
            return memoryMB / memoryUnitPreferred.multiplier + " " + memoryUnitPreferred.label;
         } else {
            return this.bytesFilter(memoryMB, "MB");
         }
      }

      public getScsiControllers(): Array<any> {
         let createdScsiControllers: any =
               _.pluck(this.virtualMachineSpecBuilder.getScsiControllerChanges(), "device");
         return _.map(createdScsiControllers, this.buildScsiInfo.bind(this));
      }

      public getNetworkInterfaces(): Array<any> {
         let virtualNetworkDevices: Array<any> =
               this.virtualMachineSpecBuilder
                     .getVirtualMachineDevices()
                     .changedDevicesOfType("VirtualEthernetCard");
         return _.map(virtualNetworkDevices, this.buildNetworkInfo.bind(this));
      }

      public getVmVersionCompatibility(): string {
         return this.i18nService.getString(
               "VmUi", "VmHardware.HWVersion.vmVersionLabel",
               this.vmHardwareVersionService.getLongText(
                     this.virtualMachineSpecBuilder.getVmConfigSpec().config.version));
      }

      private buildNetworkInfo(virtualNetworkDevice: any, index: number): Object {
         return {
            networkNameLabel: this.i18nService
                  .getString("VmUi", "ProvisioningWizardSummaryPage.NicNetwork", index + 1),
            networkName: virtualNetworkDevice.getNetworkName() ||
            this.i18nService.getString("VmUi", "ProvisioningWizardSummaryPage.None"),
            networkTypeLabel: this.i18nService
                  .getString("VmUi", "ProvisioningWizardSummaryPage.NicType", index + 1),
            networkType: this.vmDeviceInfoService
                  .getEthernetCardLabel(virtualNetworkDevice.getCurrentDevice()._type)
         };
      }

      private buildScsiInfo(controller: any, index: number): Object {
         return {
            label: this.i18nService
                  .getString("VmUi", "ProvisioningWizardSummaryPage.ScsiController", index + 1),
            name: this.vmDeviceInfoService.getScsiControllerLabel(controller)
         };
      }

      private buildDiskInfo(disk: any, index: number): any {
         let diskStorageProps: any =
               this.virtualMachineSpecBuilder.getStorageForDisk(disk.key);
         let diskSpec: any =
               this.virtualMachineSpecBuilder.getVirtualMachineDevices().findDeviceSpec(disk.key);
         let virtualDevice = this.virtualMachineSpecBuilder.getVirtualMachineDevices()
               .getVirtualDevice(disk.key);
         let isRDM: boolean = virtualDevice.isRDMDisk();
         let isCreatedFromExistingDisk = virtualDevice.isCreatedFromExistingDisk();
         let diskInfo: any = {
            diskKey: disk.key,
            label: this.isDiskEncrypted(disk.key) ?
                  this.i18nService.getString("VmUi", "ProvisioningWizardSummaryPage.CreateEncryptedDisk", index + 1) :
                  this.i18nService.getString("VmUi", "ProvisioningWizardSummaryPage.CreateDisk", index + 1),
            storageProfile: this.getStorageProfileForDisk(disk),
            replicationGroupLabel: this.getReplicationGroupLabelForDisk(disk),
            nodeLabel: this.i18nService
                  .getString("VmUi", "ProvisioningWizardSummaryPage.VdNodeLabel"),
            node: this.getDiskNode(
                  disk,
                  this.virtualMachineSpecBuilder.getVirtualMachineDevices().getDeviceChanges()),
            modeLabel: this.i18nService
                  .getString("VmUi", "ProvisioningWizardSummaryPage.DiskModeLabel"),
            mode: this.getDiskMode(disk),
            isPmemDisk: virtualDevice.isPmemDisk(),
            isRDM: isRDM,
            isCreatedFromExistingDisk: isCreatedFromExistingDisk
         };

         if (isCreatedFromExistingDisk) {
            diskInfo.name = this.i18nService
                  .getString("VmUi", "ProvisioningWizardSummaryPage.ExistingDisk");
            diskInfo.filePathLabel = this.i18nService
                  .getString("VmUi", "vmProvisioningFinishPage.DiskFilePath");
            diskInfo.filePath = diskSpec.device.backing.fileName;

            return diskInfo;
         }

         if (!isRDM) {
            diskInfo.name = this.i18nService
                  .getString("VmUi", "ProvisioningWizardSummaryPage.NewDisk");
            diskInfo.capacityLabel = this.i18nService
                  .getString("VmUi", "ProvisioningWizardSummaryPage.DiskCapacity");
            diskInfo.capacity = this.buildDiskCapacity(disk, diskSpec);
            diskInfo.datastoreLabel = this.i18nService
                  .getString("VmUi", "ProvisioningWizardSummaryPage.Datastore");
            diskInfo.datastoreInfo = this.getDatastore(diskStorageProps);
            diskInfo.getStorageObject = () => {
               return this.getStorageObject(diskStorageProps);
            };
            diskInfo.showStorageRecommendations = () => {
               return this.showStorageRecommendations(diskStorageProps);
            };
            return diskInfo;
         }

         this.virtualMachineSpecBuilder.getVmConfigEnvironment().then((environment: any) => {
            diskInfo.name = this.i18nService
                  .getString("VmUi", "ProvisioningWizardSummaryPage.NewRdmDisk");
            diskInfo.targetLunLabel = this.i18nService
                  .getString("VmUi", "vmProvisioningFinishPage.DiskTargetLun");
            diskInfo.targetLun = this.getRDMTargetLUNName(diskSpec.device,
                  environment.vmConfigEnvironmentPerPool);
            diskInfo.compatibilityLabel = this.i18nService
                  .getString("VmUi", "vmProvisioningFinishPage.DiskCompatibilityMode");
            diskInfo.compatibility = this.getRDMCompatibility(diskSpec.device);
            diskInfo.datastoreLabel = this.i18nService
                  .getString("VmUi", "vmProvisioningFinishPage.DiskMappedDatastore");
            diskInfo.showStorageRecommendations = () => {
               return false;
            };
            diskInfo.datastoreName = this.getRDMDatastoreName();
         });

         return diskInfo;
      }

      private showStorageRecommendations(diskStorageProps: any): boolean {
         let storageObject: any = this.virtualMachineSpecBuilder.getStorageObject();
         if (diskStorageProps) {
            storageObject = diskStorageProps.dsObject;
         }

         return storageObject.storageRef.type === this.managedEntityConstants.STORAGE_POD ||
               (storageObject.parentStoragePod && storageObject.parentStoragePod.isSdrsEnabled);
      }

      private buildDiskCapacity(disk: any, diskSpec: any): string {
         return this.bytesFilter(disk.capacityInKB, "KB");
      }

      private getDiskNode(device: any, deviceChanges: any): any {
         let allDevices: Array<any> = this.virtualMachineSpecBuilder.getVmConfigSpec().config.hardware.device.concat(_.pluck(deviceChanges, "device"));
         let controller: any = _.find(allDevices, (maybeController: any) => {
            return maybeController.key === device.controllerKey;
         });

         return this.vmDeviceInfoService.getControllerAndNodeLabelText(
               controller._type, controller.busNumber, device.unitNumber);
      }

   }
   angular.module("com.vmware.vsphere.client.vm")
         .service("vmProvisioningFinishCreateVmPageModel",
               VmProvisioningFinishCreateVmPageModelFactory);
}
