/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
(function() {
   'use strict';
   angular.module('com.vmware.vsphere.client.host').controller('ShutdownConfirmationController', ShutdownConfirmationController);

   ShutdownConfirmationController.$inject = ['$scope', 'confirmationDialogWithReasonService'];

   function ShutdownConfirmationController(scope, dialogService) {
      var self = this;
      var hostIds = scope.modalOptions.dialogData.availableTargets;

      scope.modalOptions.onSubmit = dialogService.onOKClicked.bind(this, getReason, getOnlyMmHosts, 'shutdown',
          scope.modalOptions, hostIds, getMmHosts);

      self.loading = true;
      self.inMaintenanceMode = true;
      self.onlyMmHosts = false;

      if (hostIds.length > 1) {
         self.multipleHosts = true;
         self.onlyMmHosts = true;
         self.hostsNotInMm = [];
         self.hostsInMm = [];
         self.hostsNames = [];
         dialogService.fetchHostsNameAndMaintenanceModeStatus(hostIds, updateMultipleControllerCallback);
      } else {
         dialogService.fetchHostNameAndMaintenanceModeStatus(hostIds, updateControllerCallback);
      }


      function updateControllerCallback(hostName, inMaintenanceMode) {
         self.loading = false;
         self.hostName = hostName;
         self.inMaintenanceMode = inMaintenanceMode;
      }

      function updateMultipleControllerCallback(hostName, inMaintenanceMode, hostId, loading) {
         self.loading = loading;
         self.hostsNames.push(hostName);

         if (!inMaintenanceMode) {
            self.inMaintenanceMode = inMaintenanceMode;
            self.hostsNotInMm.push(hostName);
         } else {
            self.hostsInMm.push(hostId);
         }
      }

      function getReason() {
         return self.shutdownReason;
      }

      function getOnlyMmHosts() {
         return self.onlyMmHosts;
      }

      function getMmHosts() {
         return self.hostsInMm;
      }
   }
})();
