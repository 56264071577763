/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
angular.module('com.vmware.vsphere.client.network')
   .factory('switchSelectorDialogService',
      ['i18nService',
         'virtualSwitchListService',
         'clarityModalService',
         'clarityConstants',
         'addNetworkingWizardConstants',
         'dataService',
      function (i18nService,
                virtualSwitchListService,
                clarityModalService,
                clarityConstants,
                addNetworkingWizardConstants,
                dataService) {

         // ======================= Public API ==========================

         return {
            openSwitchSelectorDialog: openSwitchSelectorDialog
         };

         //======================= Public Functions ==========================
         /**
          * Opens an OK/Cancel modal dialog in the application.
          */
         function openSwitchSelectorDialog(hostId, connectionType, title, retrieveAllSwitches, okHandler) {
            var dialogData = {};

            var virtualSwitchesProperty = retrieveAllSwitches
                  ? 'virtualswitch:proxyAndStandardSwitches'
                  : 'virtualswitch:virtualStandardSwitches';

            var virtualSwitchesPromise =
                  dataService.getProperties(hostId, [virtualSwitchesProperty]);

            virtualSwitchesPromise.then(function (virtualSwitchesResponse) {
               var virtualSwitches = [];

               var isResponseValid = virtualSwitchesResponse &&
                     virtualSwitchesResponse[virtualSwitchesProperty];
               if (isResponseValid) {
                  virtualSwitches =
                        virtualSwitchesResponse[virtualSwitchesProperty].switchItems || [];
               }
               var showPortsColumn = isResponseValid &&
                     virtualSwitchesResponse[virtualSwitchesProperty].showNumberOfPortsColumn;

               var showIssuesColumn = connectionType === addNetworkingWizardConstants.connectionType.PNIC;

               dialogData.datagridOptions = virtualSwitchListService.createDatagridOptions(
                     virtualSwitches, showIssuesColumn);
            });


            function onClick() {
               var selectedItems = dialogData.datagridOptions.selectedItems;
               if (selectedItems.length === 0) {
                  showError(i18nService.getString('H5NetworkUi',
                        'HostSwitchSelectorDialog.noNetworksSelected'),
                        modalOptions);
                  return false;
               }
               if (okHandler) {
                  okHandler(selectedItems);
               }
               return true;
            }

            var modalOptions = {
               title: title || i18nService
                     .getString("NetworkUi", "HostSwitchSelectorDialog.title"),
               contentTemplate: 'network-ui/resources/network/views/host/switchselector/switchSelectorDialog.html',
               defaultButton: 'submit',
               dialogData: dialogData,
               alerts: [],
               onSubmit: onClick
            };

            clarityModalService.openOkCancelModal(modalOptions);
         }

         function showError(errorMessage, modalOptions) {
            modalOptions.alerts = [{
               text: errorMessage,
               type: clarityConstants.notifications.type.ERROR
            }];
         }
   }]);
