namespace h5_vm {

   export class GosSpecCreatePagesService {

      public static $inject = ["i18nService", "$q", "vuiConstants"];

      constructor(private i18nService: any,
            private $q: any,
            private vuiConstants: any) {
      }

      createNameAndOsPage(scope: any,
                          onPageCommit: Function,
                          defaultFlowId: GosType,
                          allFlowIds: Array<GosType> = []): WizardPage {
         if (!scope.gosNameAndOsPageModel) {
            scope.gosNameAndOsPageModel = new GosNameAndOsPageModel();
         }
         (scope.gosNameAndOsPageModel as GosNameAndOsPageModel).allSpecs =
               scope.allSpecs;

         let page = {
            title: this.i18nService.getString("VmUi", "guestOsCustomization.nameAndTargetOsPage.title"),
            description: this.i18nService.getString("VmUi", "customizationSpec.nameAndOsPage.description"),
            contentUrl: "vm-ui/resources/vm/guest-os-customization/pages/name-and-os/gos-name-and-os-page.html",
            state: this.vuiConstants.wizard.pageState.INCOMPLETE,
            decisionOptions: {flowIds: allFlowIds, selectedFlowId: defaultFlowId},
            onCommit: () => {
               return onPageCommit(scope.gosNameAndOsPageModel);
            }
         };
         return page;
      }

      getWizardFlows(wizardScope: any, onPageCommit: Function): Array<any> {
         let allPages: any = this.createAllPages(wizardScope, onPageCommit);

         let flows: Array<any> = [
            {
               id: GosType.WINDOWS,
               pages: this.getPagesByType(allPages, GosType.WINDOWS)
            }, {
               id: GosType.WINDOWS_CUSTOM_SYSPREP,
               pages: this.getPagesByType(allPages, GosType.WINDOWS_CUSTOM_SYSPREP)
            }, {
               id: GosType.LINUX,
               pages: this.getPagesByType(allPages, GosType.LINUX)
            }
         ];

         return flows;
      }

      getDialogPages(dialogScope: any, gosType: GosType,
            onPageCommit: Function): Array<WizardPage> {
         let flowPages: Array<WizardPage> = this.createFlowPages(gosType, dialogScope, onPageCommit);

         let namesAndOsPage: any = this.createNameAndOsPage(
               dialogScope,
               onPageCommit,
               gosType);
         let restOfPages: Array<WizardPage> = flowPages;

         let pages: Array<WizardPage> = [];
         pages.push(namesAndOsPage);
         pages = pages.concat(restOfPages);
         pages.forEach((page: WizardPage) => {
            page.state = this.vuiConstants.wizard.pageState.COMPLETED;
         });

         return pages;
      }

      private createFlowPages(flow: GosType,
            wizardScope: any, onPageCommit: Function): Array<WizardPage> {
         if (GosType.WINDOWS === flow) {
            return [
               this.createRegistrationInformationPage(wizardScope, onPageCommit),
               this.createComputerNamePage(wizardScope, onPageCommit),
               this.createWindowsLicensePage(wizardScope, onPageCommit),
               this.createAdministratorPasswordPage(wizardScope, onPageCommit),
               this.createTimeZonePage(wizardScope, onPageCommit),
               this.createCommandsPage(wizardScope, onPageCommit),
               this.createNetworkPage(wizardScope, onPageCommit),
               this.createWorkgroupDomainPage(wizardScope, onPageCommit),
               this.createFinishPage(wizardScope)];
         }
         if (GosType.WINDOWS_CUSTOM_SYSPREP === flow) {
            return [
               this.createCustomSysprepFilePage(wizardScope, onPageCommit),
               this.createNetworkPage(wizardScope, onPageCommit),
               this.createFinishPage(wizardScope)];
         }
         if (GosType.LINUX === flow) {
            return [
               this.createComputerNamePage(wizardScope, onPageCommit),
               this.createTimeZonePage(wizardScope, onPageCommit),
               this.createNetworkPage(wizardScope, onPageCommit),
               this.createDnsSettingsPage(wizardScope, onPageCommit),
               this.createFinishPage(wizardScope)];
         }

         throw new Error("Unsupported flow");
      }

      private createAllPages(wizardScope: any, onPageCommit: Function): any {
         return {
            customSysprepFilePage:
                  this.createCustomSysprepFilePage(wizardScope, onPageCommit),
            registrationInformationPage:
                  this.createRegistrationInformationPage(wizardScope, onPageCommit),
            computerNamePage:
                  this.createComputerNamePage(wizardScope, onPageCommit),
            windowsLicensePage:
               this.createWindowsLicensePage(wizardScope, onPageCommit),
            administratorPasswordPage:
               this.createAdministratorPasswordPage(wizardScope, onPageCommit),
            timeZonePage:
                  this.createTimeZonePage(wizardScope, onPageCommit),
            commandsPage:
                  this.createCommandsPage(wizardScope, onPageCommit),
            networkPage:
                  this.createNetworkPage(wizardScope, onPageCommit),
            workgroupDomainPage:
               this.createWorkgroupDomainPage(wizardScope, onPageCommit),
            dnsSettingsPage:
                  this.createDnsSettingsPage(wizardScope, onPageCommit),
            finishPage:
                  this.createFinishPage(wizardScope)
         };
      }

      private getPagesByType(allPages: any, specType: GosType): Array<WizardPage> {
         // Plug your pages here.
         if (GosType.WINDOWS === specType) {
            return [
               allPages.registrationInformationPage,
               allPages.computerNamePage,
               allPages.windowsLicensePage,
               allPages.administratorPasswordPage,
               allPages.timeZonePage,
               allPages.commandsPage,
               allPages.networkPage,
               allPages.workgroupDomainPage,
               allPages.finishPage
            ];
         }
         if (GosType.WINDOWS_CUSTOM_SYSPREP === specType) {
            return [
               allPages.customSysprepFilePage,
               allPages.networkPage,
               allPages.finishPage
            ];
         }
         if (GosType.LINUX === specType) {
            return [
               allPages.computerNamePage,
               allPages.timeZonePage,
               allPages.networkPage,
               allPages.dnsSettingsPage,
               allPages.finishPage
            ];
         }

         throw new Error("Unsupported flow");
      }

      private createComputerNamePage(scope: any, onPageCommit: Function): WizardPage {
         if (!scope.computerNamePageModel) {
            scope.computerNamePageModel = new GosComputerNamePageModel();
         }

         let page = {
            title: this.i18nService.getString("VmUi", "guestOsCustomization.computerNamePage.title"),
            description: this.i18nService.getString("VmUi", "customizationSpec.computerNamePage.description"),
            contentUrl: "vm-ui/resources/vm/guest-os-customization/pages/computer-name/gos-computer-name-page.html",
            state: this.vuiConstants.wizard.pageState.INCOMPLETE,
            onCommit: () => {
               return onPageCommit(scope.computerNamePageModel);
            }
         };

         return page;
      }

      private createWindowsLicensePage(scope: any, onPageCommit: Function): WizardPage {
         if (!scope.windowsLicensePageModel) {
            scope.windowsLicensePageModel = new GosWindowsLicensePageModel();
         }

         let page = {
            title: this.i18nService.getString("VmUi", "guestOsCustomization.windowsLicensePage.title"),
            description: this.i18nService.getString("VmUi", "customizationSpec.windowsLicensePage.pageInfoNew"),
            contentUrl: "vm-ui/resources/vm/guest-os-customization/pages/windows-license/gos-windows-license-page.html",
            state: this.vuiConstants.wizard.pageState.INCOMPLETE,
            onCommit: () => {
               return onPageCommit(scope.windowsLicensePageModel);
            }
         };

         return page;
      }

      private createDnsSettingsPage(scope: any, onPageCommit: Function): WizardPage {
         if (!scope.dnsSettingsPageModel) {
            scope.dnsSettingsPageModel = new GosDnsSettingsPageModel();
         }

         let page = {
            title: this.i18nService.getString("VmUi", "guestOsCustomization.dnsSettingsPage.title"),
            description: this.i18nService.getString("VmUi", "customizationSpec.dnsDomainSettingsPage.description"),
            contentUrl: "vm-ui/resources/vm/guest-os-customization/pages/dns-settings/gos-dns-settings-page.html",
            state: this.vuiConstants.wizard.pageState.INCOMPLETE,
            onCommit: () => {
               return onPageCommit(scope.dnsSettingsPageModel);
            }
         };

         return page;
      }

      private createCustomSysprepFilePage(scope: any, onPageCommit: Function): WizardPage {
         if (!scope.customSysprepFilePageModel) {
            scope.customSysprepFilePageModel = new GosCustomSysprepFilePageModel();
         }

         let page = {
            title: this.i18nService.getString("VmUi", "guestOsCustomization.customSysPrepPagePage.title"),
            description: this.i18nService.getString("VmUi", "customizationSpec.customSysprepFilePage.pageInfoNew"),
            contentUrl: "vm-ui/resources/vm/guest-os-customization/pages/custom-sysprep-file/gos-custom-sysprep-file-page.html",
            state: this.vuiConstants.wizard.pageState.INCOMPLETE,
            onCommit: () => {
               return onPageCommit(scope.customSysprepFilePageModel);
            }
         };

         return page;
      }

      private createRegistrationInformationPage(scope: any, onPageCommit: Function): WizardPage {
         if (!scope.registrationInformationPageModel) {
            scope.registrationInformationPageModel = new GosRegistrationInformationPageModel();
         }

         let page = {
            title: this.i18nService.getString("VmUi", "guestOsCustomization.registrationInformationPage.title"),
            description: this.i18nService.getString("VmUi", "guestOsCustomization.new.registrationInformationPage.Description"),
            contentUrl: "vm-ui/resources/vm/guest-os-customization/pages/registration-information/gos-registration-information-page.html",
            state: this.vuiConstants.wizard.pageState.INCOMPLETE,
            onCommit: () => {
               return onPageCommit(scope.registrationInformationPageModel);
            }
         };

         return page;
      }

      private createTimeZonePage(scope: any, onPageCommit: Function): WizardPage {
         if (!scope.timeZonePageModel) {
            scope.timeZonePageModel = new GosTimeZonePageModel();
         }

         let page = {
            title: this.i18nService.getString("VmUi", "guestOsCustomization.timeZonePage.title"),
            description: this.i18nService.getString("VmUi", "customizationSpec.timeZonePage.description"),
            contentUrl: "vm-ui/resources/vm/guest-os-customization/pages/time-zone/gos-time-zone-page.html",
            state: this.vuiConstants.wizard.pageState.INCOMPLETE,
            onCommit: () => {
               return onPageCommit(scope.timeZonePageModel);
            }
         };

         return page;
      }

      private createNetworkPage(scope: any, onPageCommit: Function): WizardPage {
         if (!scope.networkPageModel) {
            scope.networkPageModel = new GosNetworkPageModel();
         }

         let page = {
            title: this.i18nService.getString("VmUi", "guestOsCustomization.networkPage.title"),
            description: this.i18nService.getString("VmUi", "customizationSpec.networkPage.description"),
            contentUrl: "vm-ui/resources/vm/guest-os-customization/pages/network/gos-network-page.html",
            state: this.vuiConstants.wizard.pageState.INCOMPLETE,
            onCommit: () => {
               return onPageCommit(scope.networkPageModel);
            }
         };

         return page;
      }

      private createFinishPage(scope: any): WizardPage {
         if (!scope.finishPageModel) {
            scope.finishPageModel = new GosFinishPageModel();
         }

         let page = {
            title: this.i18nService.getString("CommonUi", "SummaryPage.title"),
            description: this.i18nService.getString("CommonUi", "SummaryPage.description"),
            contentUrl: "vm-ui/resources/vm/guest-os-customization/pages/finish/gos-finish-page.html",
            state: this.vuiConstants.wizard.pageState.INCOMPLETE,
            onCommit: () => {
               return true;
            }
         };

         return page;
      }

      private createAdministratorPasswordPage(scope: any, onPageCommit: Function) {
         if (!scope.administratorPasswordPageModel) {
            scope.administratorPasswordPageModel = new GosAdministratorPasswordPageModel();
         }

         let page = {
            title: this.i18nService.getString("VmUi", "guestOsCustomization.administratorPasswordPage.title"),
            description: this.i18nService.getString("VmUi", "customizationSpec.adminPasswordPage.pageInfoNew"),
            contentUrl: "vm-ui/resources/vm/guest-os-customization/pages/administrator-password/gos-administrator-password-page.html",
            state: this.vuiConstants.wizard.pageState.INCOMPLETE,
            onCommit: () => {
               return onPageCommit(scope.administratorPasswordPageModel);
            }
         };

         return page;
      }

      private createCommandsPage(scope: any, onPageCommit: Function) {
         if (!scope.commandsPageModel) {
            scope.commandsPageModel = new GosCommandsPageModel();
         }

         let page = {
            title: this.i18nService.getString("VmUi", "guestOsCustomization.runOnceCommandsPage.title"),
            description: this.i18nService.getString("VmUi", "customizationSpec.runOncePage.pageInfoNew"),
            contentUrl: "vm-ui/resources/vm/guest-os-customization/pages/commands/gos-commands-page.html",
            state: this.vuiConstants.wizard.pageState.INCOMPLETE,
            onCommit: () => {
               return onPageCommit(scope.commandsPageModel);
            }
         };

         return page;
      }

      private createWorkgroupDomainPage(scope: any, onPageCommit: Function) {
         if (!scope.workgroupDomainPageModel) {
            scope.workgroupDomainPageModel = new GosWorkgroupDomainPageModel();
         }

         let page = {
            title: this.i18nService.getString("VmUi", "guestOsCustomization.workGroupOrDomainPage.title"),
            description: this.i18nService.getString("VmUi", "customizationSpec.workgroupDomainPage.pageInfoNew"),
            contentUrl: "vm-ui/resources/vm/guest-os-customization/pages/workgroup-domain/gos-workgroup-domain-page.html",
            state: this.vuiConstants.wizard.pageState.INCOMPLETE,
            onCommit: () => {
               return onPageCommit(scope.workgroupDomainPageModel);
            }
         };

         return page;
      }
   }

   angular.module("com.vmware.vsphere.client.vm")
         .service("gosSpecCreatePagesService", GosSpecCreatePagesService);
}
