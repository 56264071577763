/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace dvs_ui {

   import DvsPortFailoverPolicyModel$OverrideOptions =
         com.vmware.vsphere.client.h5.network.dvs.ports.model.DvsPortFailoverPolicyModel$OverrideOptions;

   export class DvpgFailoverPolicyPageService {

      public static $inject = [
            "networkUtil",
            "networkUiConstants",
            "clarityModalService",
            "$q"];

      constructor(private networkUtil: any,
                  private networkUiConstants: any,
                  private clarityModalService: any,
                  private $q: any) {
      }

      public hasFailoverOrderChanged(
            failoverOrder: any,
            activeUplinkPortNames: string[],
            standbyUplinkPortNames: string[]): boolean {

         if (failoverOrder.connectees.active.length !== activeUplinkPortNames.length
               || failoverOrder.connectees.standby.length !==
                     standbyUplinkPortNames.length) {
            return true;
         } else {
            let i: number;

            for (i = 0; i < failoverOrder.connectees.active.length; i++) {
               if (failoverOrder.connectees.active[i] !== activeUplinkPortNames[i]) {
                  return true;
               }
            }

            for (i = 0; i < failoverOrder.connectees.standby.length; i++) {
               if (failoverOrder.connectees.standby[i] !== standbyUplinkPortNames[i]) {
                  return true;
               }
            }

            return false;
         }
      }

      public getNicTeamingModeLabel(mode: string): string {
         switch(mode) {
            case this.networkUiConstants.LoadBalancingPolicy.IP: {
               return this.networkUtil.getString(
                     "dvpg.policy.failover.lb.loadbalance_ip");
            }
            case this.networkUiConstants.LoadBalancingPolicy.EXPLICIT: {
               return this.networkUtil.getString(
                     "dvpg.policy.failover.lb.failover_explicit");
            }
            case this.networkUiConstants.LoadBalancingPolicy.LOADBASED: {
               return this.networkUtil.getString(
                     "dvpg.policy.failover.lb.loadbalance_loadbased");
            }
            case this.networkUiConstants.LoadBalancingPolicy.SRCID: {
               return this.networkUtil.getString(
                     "dvpg.policy.failover.lb.loadbalance_srcid");
            }
            case this.networkUiConstants.LoadBalancingPolicy.SRCMAC: {
               return this.networkUtil.getString(
                     "dvpg.policy.failover.lb.loadbalance_srcmac");
            }
            default: {
               return "";
            }
         }
      }

      public createFailoverOrderSettings(failoverOrder: any): any {
         return {
            connectees: {
               active: failoverOrder.activeUplinkPortNames.slice(),
               standby: failoverOrder.standbyUplinkPortNames.slice(),
               unused: failoverOrder.unusedUplinkPortNames.slice(),
               lagNames: failoverOrder.lagNames ? failoverOrder.lagNames.slice() : null
            },
            labels: {
               active: this.networkUtil.getString("dvpg.policy.failover.order.active.group"),
               standby: this.networkUtil.getString("dvpg.policy.failover.order.standby.group"),
               unused: this.networkUtil.getString("dvpg.policy.failover.order.unused.group")
            }
         };
      }

      public getLoadBalancingMessages(
            failoverOrderConnectees: any,
            loadBalancing: string,
            beaconProbing: boolean,
            overrideOptions: DvsPortFailoverPolicyModel$OverrideOptions): string[] {

         let messages: string[] = this.getFormattedLoadBalancingInfoMessages(
               failoverOrderConnectees, loadBalancing);

         if (loadBalancing !== this.networkUiConstants.LoadBalancingPolicy.IP) {
            return messages;
         }

         let warningMessage: string[] = this.getFormattedLoadBalancingWarningMessages(
               failoverOrderConnectees.active,
               failoverOrderConnectees.standby,
               loadBalancing,
               beaconProbing,
               false/*showNoActiveUplinksWarning*/,
               overrideOptions);

         if (warningMessage) {
            messages = warningMessage.concat(messages);
         }
         return messages;
      }

      public getLoadBalancingWarningMessages(
            activeUplinks: string[],
            standbyUplinks: string[],
            loadBalancing: string,
            beaconProbing: boolean,
            showNoActiveUplinksWarning: boolean,
            overrideOptions: DvsPortFailoverPolicyModel$OverrideOptions): string[] {

         let messages: string[] = [];

         if (overrideOptions) {
            return this.getLoadBalancingOverrideWarningMessages(activeUplinks,
                  standbyUplinks,
                  loadBalancing,
                  beaconProbing,
                  showNoActiveUplinksWarning,
                  overrideOptions);
         }

         if (showNoActiveUplinksWarning && activeUplinks.length === 0) {
            messages = [ this.networkUtil.getString(
                  "dvpg.policy.failover.order.warn.noActiveUplinks") ];
         }

         if (loadBalancing !== this.networkUiConstants.LoadBalancingPolicy.IP) {
            return messages;
         }

         let standbyUplinksExist: boolean = standbyUplinks && standbyUplinks.length > 0;

         let warning: string[];

         if (standbyUplinksExist && beaconProbing) {
            warning = [ this.networkUtil.getString(
                  "dvpg.policy.failover.lb.loadbalance_ip." +
                  "warn.beaconProbingAndStandbyUplinks") ];
            messages = messages.concat(warning);
         } else if (standbyUplinksExist) {
            warning = [ this.networkUtil.getString(
                  "dvpg.policy.failover.lb.loadbalance_ip.warn.standbyUplinks") ];
            messages = messages.concat(warning);
         } else if (beaconProbing) {
            warning = [ this.networkUtil.getString(
                  "dvpg.policy.failover.lb.loadbalance_ip.warn.beaconProbing") ];
            messages = messages.concat(warning);
         }

         return messages;
      }

      public getLoadBalancingOverrideWarningMessages(
            activeUplinks: string[],
            standbyUplinks: string[],
            loadBalancing: string,
            beaconProbing: boolean,
            showNoActiveUplinksWarning: boolean,
            overrideOptions: DvsPortFailoverPolicyModel$OverrideOptions): string[] {
         if (!overrideOptions.loadBalancing && !overrideOptions.failoverOrder
               && !overrideOptions.beaconProbing) {
            return [];
         }

         let messages: string[] = [];
         let standbyUplinksExist: boolean = standbyUplinks && standbyUplinks.length > 0;

         if (overrideOptions.failoverOrder
               && showNoActiveUplinksWarning && activeUplinks.length === 0) {
            messages = [this.networkUtil.getString(
                  "dvpg.policy.failover.order.warn.noActiveUplinks")];
         }

         if (overrideOptions.loadBalancing
               && loadBalancing !== this.networkUiConstants.LoadBalancingPolicy.IP) {
            return messages;
         }

         if (overrideOptions.loadBalancing) {
            if (overrideOptions.beaconProbing && overrideOptions.failoverOrder
                  && beaconProbing && standbyUplinksExist) {
               messages.push(this.networkUtil.getString(
                     "dvpg.policy.failover.lb.loadbalance_ip." +
                     "warn.beaconProbingAndStandbyUplinks"));
            } else if (overrideOptions.beaconProbing && beaconProbing) {
               messages.push(this.networkUtil.getString(
                     "dvpg.policy.failover.lb.loadbalance_ip.warn.beaconProbing"));
            } else if (overrideOptions.failoverOrder && standbyUplinksExist) {
               messages.push(this.networkUtil.getString(
                     "dvpg.policy.failover.lb.loadbalance_ip.warn.standbyUplinks"));
            }
         }

         return messages;
      }

      public hasLoadBalancingWarningMessage(
            activeUplinks: string[],
            standbyUplinks: string[],
            loadBalancing: string,
            beaconProbing: boolean,
            showNoActiveUplinksWarning: boolean,
            overrideOptions: DvsPortFailoverPolicyModel$OverrideOptions): boolean {
         if (overrideOptions) {
            return this.hasLoadBalancingOverrideWarningMessage(activeUplinks,
                  standbyUplinks,
                  loadBalancing,
                  beaconProbing,
                  showNoActiveUplinksWarning,
                  overrideOptions);
         }

         if (showNoActiveUplinksWarning && activeUplinks.length === 0) {
            return true;
         }

         if (loadBalancing !== this.networkUiConstants.LoadBalancingPolicy.IP) {
            return false;
         }

         return (standbyUplinks && standbyUplinks.length > 0) || beaconProbing;
      }

      public shouldShowErrorWarningDialog(
            failoverOrderConnectees: any,
            loadBalancing: string,
            beaconProbing: boolean,
            overrideOptions: DvsPortFailoverPolicyModel$OverrideOptions): boolean {
         return this.hasLagErrorMessage(failoverOrderConnectees, overrideOptions) ||
               this.hasLoadBalancingWarningMessage(
                     failoverOrderConnectees.active,
                     failoverOrderConnectees.standby,
                     loadBalancing,
                     beaconProbing,
                     true/*showNoActieUplinkWarning*/, overrideOptions) ||
               this.hasLagWarningMessages(failoverOrderConnectees, beaconProbing,
                     overrideOptions);
      }

      public showErrorWarningDialog(
            failoverOrderConnectees: any,
            loadBalancing: string,
            beaconProbing: boolean,
            overrideOptions: DvsPortFailoverPolicyModel$OverrideOptions): void {

         let errorMessage: string | null =
               this.getLagErrorMessage(failoverOrderConnectees, overrideOptions);
         let loadBalancingWarningMessages: string[] =
               this.getLoadBalancingWarningMessages(
                     failoverOrderConnectees.active,
                     failoverOrderConnectees.standby,
                     loadBalancing,
                     beaconProbing,
                     true/*showNoActiveUplinksWarning*/,
                     overrideOptions);

         let lagWarningMessages: string[] = this.getLagWarningMessages(
               failoverOrderConnectees, beaconProbing, overrideOptions);

         let warningMessages: string[] =
               loadBalancingWarningMessages.concat(lagWarningMessages);

         let deferred: any = this.$q.defer();

         let modalOptions: any = {
            size: "md",
            defaultButton: "close",
            alerts: [],
            dialogData: {},
            contentTemplate: "network-lib-ui/resources/network-lib/dvpg/" +
                  "edit/portgroupFailoverErrorWarningDialog.html",
            submit : function () {
               deferred.resolve(true);
               return true;
            },
            onCancel: function () {
               deferred.resolve(false);
               return true;
            }
         };

         let dialogWarningMessages: string[] =
               this.getDialogWarningMessages(warningMessages);

         if (errorMessage === null) {
            modalOptions.title =
                  this.networkUtil.getString("dvpg.policy.failover.confirmation.title");
            modalOptions.dialogData.messages = dialogWarningMessages;

         } else {

            let dialogErrorMessages: any[] =
                  this.getDialogErrorMessages([errorMessage]);
            let dialogMessages: string[] =
                  dialogErrorMessages.concat(dialogWarningMessages);

            modalOptions.title =
                  this.networkUtil.getString("dvpg.policy.failover.error.title");
            modalOptions.dialogData.messages = dialogMessages;
            modalOptions.hideSubmitButton = true;
            modalOptions.closeButtonTitle =
                  this.networkUtil.getString("MessageDialog.okButton");
         }

         // Append the confirmation tip message to the end
         modalOptions.dialogData.dialogInfo =
               this.networkUtil.getString("IssuesConfirmationDialog.tip") ;

         this.clarityModalService.openConfirmationModal(modalOptions);

         return deferred.promise.then(function (value: any) {
            return value;
         });
      }

      private getFormattedLoadBalancingWarningMessages(
            activeUplinks: string[],
            standbyUplinks: string[],
            loadBalancing: string,
            beaconProbing: boolean,
            showNoActiveUplinksWarning: boolean,
            overrideOptions: DvsPortFailoverPolicyModel$OverrideOptions): string[] {

         let warnMessageIconClass: string = 'vsphere-icon-status-warning';

         let message: string[] = this.getLoadBalancingWarningMessages(
               activeUplinks,
               standbyUplinks,
               loadBalancing,
               beaconProbing,
               showNoActiveUplinksWarning,
               overrideOptions);

         return this.networkUtil.formatTextMessagesWithIcon(
               message, warnMessageIconClass);
      }

      private getLagWarningMessages(
            failoverOrderConnectees: any,
            beaconProbing: boolean,
            overrideOptions: DvsPortFailoverPolicyModel$OverrideOptions): string[] {

         let lagWarningMessages: string[] = [];

         let activeUplinks: string[] = failoverOrderConnectees.active;
         let standbyUplinks: string[] = failoverOrderConnectees.standby;
         let lagNames: string[] = failoverOrderConnectees.lagNames;

         let activeLagCount: number = this.getLagCount(activeUplinks, lagNames);
         let standbyLagCount: number = this.getLagCount(standbyUplinks, lagNames);

         let activeStandaloneUplinkCount: number =
               activeUplinks.length - activeLagCount;

         if (standbyLagCount === 1 && activeStandaloneUplinkCount >= 1) {
            lagWarningMessages.push(this.networkUtil.getString(
                  "dvpg.policy.failover.order.warn.migrationMode"));
         }

         if (overrideOptions && !overrideOptions.beaconProbing) {
            return lagWarningMessages;
         }

         if (beaconProbing
               && !this.hasLagErrorMessage(failoverOrderConnectees, overrideOptions)
               && (activeLagCount + standbyLagCount) >= 1) {
            lagWarningMessages.push(this.networkUtil.getString(
                  "dvpg.policy.failover.order.warn.lacpBeaconProbing"));
         }

         return lagWarningMessages;
      }

      private hasLagErrorMessage(failoverOrderConnectees: any,
            overrideOptions: DvsPortFailoverPolicyModel$OverrideOptions): boolean {
         return this.getLagErrorMessage(failoverOrderConnectees, overrideOptions) !== null;
      }

      private getLagErrorMessage(failoverOrderConnectees: any,
            overrideOptions: DvsPortFailoverPolicyModel$OverrideOptions): string | null {
         if (overrideOptions && !overrideOptions.failoverOrder) {
            return null;
         }

         let activeUplinks: string[] = failoverOrderConnectees.active;
         let standbyUplinks: string[] = failoverOrderConnectees.standby;
         let unusedUplinks: string[] = failoverOrderConnectees.unused;
         let lagNames: string[] = failoverOrderConnectees.lagNames;

         if (!lagNames || lagNames.length === 0) {
            return null;
         }

         let activeAndStandbyLagCount: number =
               lagNames.length - this.getLagCount(unusedUplinks, lagNames);
         let activeAndStandbyStandaloneUplinkCount: number =
               activeUplinks.length + standbyUplinks.length - activeAndStandbyLagCount;

         if (activeAndStandbyLagCount > 1 &&
               activeAndStandbyStandaloneUplinkCount === 0) {
            return this.networkUtil.getString(
                  "dvpg.policy.failover.order.error.multipleLags");
         }

         let activeLagCount: number = this.getLagCount(activeUplinks, lagNames);
         let standbyLagCount: number = this.getLagCount(standbyUplinks, lagNames);

         let standbyStandaloneUplinkCount: number =
               standbyUplinks.length - standbyLagCount;

         if ((activeLagCount >= 1 && activeAndStandbyStandaloneUplinkCount >= 1) ||
               (standbyLagCount >= 1 && standbyStandaloneUplinkCount >= 1)) {
            return this.networkUtil.getString(
                  "dvpg.policy.failover.order.error.hybridMode");
         }

         return null;
      }

      private hasLagWarningMessages(
            failoverOrderConnectees: any,
            beaconProbing: boolean,
            overrideOptions: DvsPortFailoverPolicyModel$OverrideOptions): boolean {

         if (overrideOptions && !overrideOptions.failoverOrder) {
            return false;
         }

         return this.getLagWarningMessages(
               failoverOrderConnectees, beaconProbing, overrideOptions).length > 0;
      }

      private getLagCount(uplinks: string[], lagNames: string[]): number {
         let lagCount: number = 0;

         if(!uplinks || !lagNames || uplinks.length === 0 || lagNames.length === 0) {
            return lagCount;
         }

         _.forEach(uplinks, function(uplink: string) {
            if (_.indexOf(lagNames, uplink) !== -1) {
               lagCount++;
            }
         });

         return lagCount;
      }

      private getFormattedLoadBalancingInfoMessages(
            failoverOrderConnectees: any,
            loadBalancing: string): string[] {
         let infoMessageIconClass: string = "vsphere-icon-info";

         let messages: string[] = [];

         if (loadBalancing === this.networkUiConstants.LoadBalancingPolicy.IP) {
            messages = [
               this.networkUtil.getString(
                     "dvpg.policy.failover.lb.loadbalance_ip.info.etherChannel"),
               this.networkUtil.getString(
                     "dvpg.policy.failover.lb.loadbalance_ip.info.portGroupPolicies")
            ];
         }

         if (this.hasSingleLagBacking(failoverOrderConnectees)) {
            messages.push(
               this.networkUtil.getString("dvpg.policy.failover.lb.sinlgeLag.info"));
         }

         return this.networkUtil.formatTextMessagesWithIcon(
               messages, infoMessageIconClass);
      }

      private getDialogWarningMessages(warningMessages: string[]): any[] {
         let dialogWarningMessages: any[] = [];

         _.forEach(warningMessages, function(warningMessage: string) {
            dialogWarningMessages.push( {
               iconClass: "warning",
               text: warningMessage
            });
         });

         return dialogWarningMessages;
      }

      private getDialogErrorMessages(errorMessages: string[]): any[] {
         let dialogErrorMessages: any[] = [];

         _.forEach(errorMessages, function(errorMessage: string) {
            dialogErrorMessages.push( {
               iconClass: "danger",
               text: errorMessage
            });
         });

         return dialogErrorMessages;
      }

      private hasSingleLagBacking(failoverOrderConnectees: any): boolean {
         if (failoverOrderConnectees) {
            if (failoverOrderConnectees.active.length +
                  failoverOrderConnectees.standby.length === 1) {

               if (failoverOrderConnectees.lagNames &&
                     failoverOrderConnectees.lagNames.length > 0) {

                  let singleConnecteeName: string =
                        failoverOrderConnectees.active.length === 1 ?
                              failoverOrderConnectees.active[0] :
                              failoverOrderConnectees.standby[0];
                  return _.indexOf(failoverOrderConnectees.lagNames,
                              singleConnecteeName) !== -1;
               }
            }
         }

         return false;
      }

      private hasLoadBalancingOverrideWarningMessage(
            activeUplinks: string[],
            standbyUplinks: string[],
            loadBalancing: string,
            beaconProbing: boolean,
            showNoActiveUplinksWarning: boolean,
            overrideOptions: DvsPortFailoverPolicyModel$OverrideOptions): boolean {
         if (!overrideOptions.loadBalancing && !overrideOptions.failoverOrder
               && !overrideOptions.beaconProbing) {
            return false;
         }

         if (overrideOptions.failoverOrder
               && showNoActiveUplinksWarning && activeUplinks.length === 0) {
            return true;
         }

         if (overrideOptions.loadBalancing
               && loadBalancing === this.networkUiConstants.LoadBalancingPolicy.IP) {
            if (overrideOptions.beaconProbing && overrideOptions.failoverOrder) {
               return (standbyUplinks && standbyUplinks.length > 0) || beaconProbing;
            } else if (overrideOptions.beaconProbing) {
               return beaconProbing;
            } else if (overrideOptions.failoverOrder) {
               return standbyUplinks && standbyUplinks.length > 0;
            }
         }

         return false;
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .service("dvpgFailoverPolicyPageService", DvpgFailoverPolicyPageService);
}
