angular.module('com.vmware.vsphere.client.vm').service('createVmWizardService', [
   '$q',
   '$rootScope',
   '$injector',
   'vuiService',
   'i18nService',
   'dataService',
   'wizardPageService',
   'defaultUriSchemeUtil',
   'creationTypeConstants',
   'VirtualMachineSpecBuilder',
   'ProvisioningWizardViewData',
   'creationTypeServiceFactory',
   'managedEntityConstants',
   'vmCreateEditScheduledTaskService',
   'vmCloneEditScheduledTaskService',
   function (
         $q,
         $rootScope,
         $injector,
         vuiService,
         i18nService,
         dataService,
         wizardPageService,
         defaultUriSchemeUtil,
         creationTypeConstants,
         VirtualMachineSpecBuilder,
         ProvisioningWizardViewData,
         creationTypeServiceFactory,
         managedEntityConstants,
         vmCreateEditScheduledTaskService,
         vmCloneEditScheduledTaskService) {

      var contextObjectId;
      var contextObjectParts;
      var GUEST_ID_PROPERTY = 'config.guestId';
      var NAME_PROPERTY = 'name';
      var DC_PROPERTY = 'dc';
      var SERVER_GUID_PROPERTY = 'serverGuid';
      var PARENT_PROPERTY = 'parent';
      var VAPP_PROPERTY_DESCRIPTORS_PROPERTY = 'config.vAppConfig.property';

      var FIRST_FOLDER_WITH_CREATE_VM_PRIVILEGE_PROPERTY =
            'firstFolderWithCreateVmPrivilege';
      var FIRST_FOLDER_WITH_CREATE_FROM_EXISTING_VM_PRIVILEGE_PROPERTY =
            'firstFolderWithCreateFromExistingPrivilege';
      var FIRST_TEMPLATE_FOLDER_WITH_CREATE_FROM_EXISTING_PRIVILEGE_PROPERTY =
            'firstTemplateFolderWithCreateFromExistingPrivilege';
      var FIRST_FOLDER_WITH_REGISTER_VM_PRIVILEGE_PROPERTY =
            'firstFolderWithRegisterVmPrivilege';
      var FIRST_TEMPLATE_FOLDER_WITH_REGISTER_VM_PRIVILEGE_PROPERTY =
            'firstTemplateFolderWithRegisterVmPrivilege';

      var FIRST_COMPUTE_WITH_CREATE_VM_PRIVILEGE_PROPERTY =
            'firstComputeWithCreateVmPrivilege';
      var FIRST_COMPUTE_WITH_VAPP_IMPORT_PRIVILEGE_PROPERTY =
            'firstComputeWithVAppImportPrivilege';

      var SCHEDULE_TASK_EDIT_OPERATION_MODE = 1;

      var SCHEDULED_TASK_DATA_REQUEST = "scheduledTaskDataRequest";
      var CONTEXT_OBJECT_PROPERTIES_REQUEST = "contextObjectPropertiesRequest";

      var FIRST_FOLDER_WITH_APPROPRIATE_PRIVILEGE;
      var FIRST_COMPUTE_WITH_APPROPRIATE_PRIVILEGE;

      return {
         showWizard: showWizard
      };

      function titleString(creationType, name, isTemplate) {
         var titleMap = {
            cloneVmToTemplate: 'ProvisioningTypes.CloneVmToTemplate.WizardTitle',
            cloneVappToLibrary: 'ProvisioningTypes.CloneVappToTemplate.WizardTitle',
            createVmFromScratch: 'ProvisioningTypes.CreateFromScratch.WizardTitle',
            cloneTemplateToVm: 'ProvisioningTypes.DeployFromTemplate.WizardTitle',
            deployVmFromVmtx: 'ProvisioningTypes.DeployFromVmtx.WizardTitle',
            cloneVmToVm: 'ProvisioningTypes.CloneVmToVm.WizardTitle',
            convertTemplateToVm: 'ProvisioningTypes.ConvertTemplateToVm.WizardTitle',
            cloneTemplateToTemplate: 'ProvisioningTypes.CloneTemplateToTemplate.WizardTitle',
            deployVmFromOvf: 'ProvisioningTypes.OvfDeployWizard.WizardTitle',
            registerVm: isTemplate ? 'regVm.template.title' : 'regVmWizard.tiwoDesc',
            deployVmFromTemplate: 'ProvisioningTypes.DeployFromContentLibraryTemplate.WizardTitle',
            deployVmFromVapp: 'ProvisioningTypes.DeployFromContentLibraryVapp.WizardTitle',
            cloneVmToLibrary: 'ProvisioningTypes.CloneVmToTemplate.WizardTitle',
            ScheduledTaskcreateVmFromScratch: 'ProvisioningTypes.ScheduledTask.CreateFromScratch.WizardTitle',
            ScheduledTaskEditcreateVmFromScratch: 'ProvisioningTypes.ScheduledTask.EditCreateFromScratch.WizardTitle',
            ScheduledTaskcloneVmToVm: 'ProvisioningTypes.ScheduledTask.CloneVmToVm.WizardTitle',
            ScheduledTaskEditcloneVmToVm: 'ProvisioningTypes.ScheduledTask.EditCloneVmToVm.WizardTitle'

         };
         return i18nService.getString('VmUi', titleMap[creationType] + '.2', name);
      }

      function showWizard(contextObjId, creationType, context) {
         var requests = {};
         var isScheduledTask = false;
         var scheduledTaskEditData;
         var scheduledTaskService;
         var properties = [NAME_PROPERTY, DC_PROPERTY, SERVER_GUID_PROPERTY];
         if (context && context.isScheduledTask) {
            isScheduledTask = true;
            var scheduleTaskOperationMode = context.schedulingData.scheduleTaskOperationMode;
            if (scheduleTaskOperationMode === SCHEDULE_TASK_EDIT_OPERATION_MODE) {
               if (creationType === creationTypeConstants.CREATE_FROM_SCRATCH) {
                  scheduledTaskService = vmCreateEditScheduledTaskService;
               }

               if (creationType === creationTypeConstants.CLONE_VM_TO_VM) {
                  scheduledTaskService = vmCloneEditScheduledTaskService;
                  properties.push(PARENT_PROPERTY);
               }

               scheduledTaskEditData = scheduledTaskService
                     .buildCreateVmData(context.schedulingData);
               contextObjId = scheduledTaskEditData.contextObjId;
               requests[SCHEDULED_TASK_DATA_REQUEST] =
                     scheduledTaskService.requestScheduledData(
                           contextObjId, scheduledTaskEditData);
            }
         }

         contextObjectId = contextObjId;
         contextObjectParts =
               defaultUriSchemeUtil.getPartsFromVsphereObjectId(contextObjectId);
         FIRST_FOLDER_WITH_APPROPRIATE_PRIVILEGE = undefined;
         FIRST_COMPUTE_WITH_APPROPRIATE_PRIVILEGE = undefined;

         switch (contextObjectParts.type) {
            case managedEntityConstants.VIRTUAL_MACHINE:
               properties.push(GUEST_ID_PROPERTY, VAPP_PROPERTY_DESCRIPTORS_PROPERTY);
               break;
            default: break;
         }
         addPropertiesPerCreationType(creationType, properties, isScheduledTask, context);

         requests[CONTEXT_OBJECT_PROPERTIES_REQUEST] = dataService.getProperties(
               contextObjectId, properties);

         $q.all(requests).then(function(responses) {
            if (context && context.hasOwnProperty('libraryItemName')) {
               responses[CONTEXT_OBJECT_PROPERTIES_REQUEST][NAME_PROPERTY] = context['libraryItemName'];
            }
            showWizardInternal(creationType, responses[CONTEXT_OBJECT_PROPERTIES_REQUEST],
                  context, isScheduledTask, scheduledTaskEditData, scheduledTaskService);
         });
      }

      function showWizardInternal(creationType, props, context, isScheduledTask,
            scheduledTaskEditData, scheduledTaskService) {
         if (isScheduledTask && contextObjectParts.type === managedEntityConstants.FOLDER) {
            var parent = props[PARENT_PROPERTY];
            if (parent.type === managedEntityConstants.DATACENTER) {
               contextObjectId = defaultUriSchemeUtil.getVsphereObjectId(parent);
               contextObjectParts =
                     defaultUriSchemeUtil.getPartsFromVsphereObjectId(contextObjectId);
            }
         }
         var wizardScope = $rootScope.$new();
         wizardScope.vmParams = new VirtualMachineSpecBuilder();
         wizardScope.wizardViewData = new ProvisioningWizardViewData();

         wizardScope.vmParams.setCreationType(creationType);
         if(creationType === creationTypeConstants.DEPLOY_VM_FROM_VMTX) {
            wizardScope.vmParams.setLibraryItemId(context.libraryItemId);
            wizardScope.vmParams.setVmtxLibraryItemId(contextObjectId);
         }
         wizardScope.attributes = {creationType: creationType};

         if (contextObjectParts.type === managedEntityConstants.VIRTUAL_MACHINE) {
            wizardScope.vmParams.setVmId(contextObjectId);
            wizardScope.wizardViewData.setSourceObjectName(props[NAME_PROPERTY]);
            wizardScope.vmParams.setGuestOsId(props[GUEST_ID_PROPERTY]);
            wizardScope.vmParams.setVappPropertyDescriptors(
               props[VAPP_PROPERTY_DESCRIPTORS_PROPERTY]);
         }

         var isDeployFromTemplateOrVapp =
               creationTypeConstants.isDeployVmFromTemplate(creationType) ||
               creationTypeConstants.isDeployVmFromVapp(creationType);

         if (isDeployFromTemplateOrVapp) {
            wizardScope.wizardViewData.setTemplateId(contextObjectId);
         }

         var vCenterId = defaultUriSchemeUtil.getRootFolder(props[SERVER_GUID_PROPERTY]);
         wizardScope.wizardViewData.setVCenterId(vCenterId);

         var preselectFolderId = getTargetLocationId(props, creationType);
         wizardScope.wizardViewData.setDatacenterId(preselectFolderId);

         // NOTE: This should be after wizardScope.wizardViewData.setDatacenterId(preselectFolderId)
         var isTemplate = false;
         var isRegisterVm = creationTypeConstants.isRegisterVm(creationType);
         if(isRegisterVm) {
            isTemplate = context.isTemplate;
            wizardScope.vmParams.setRegisterVmData(context);
            wizardScope.vmParams.setName(context.vmName);
            wizardScope.vmParams.setStorageObject(contextObjectId);
            var objDc = props[DC_PROPERTY];
            var datacenterId = defaultUriSchemeUtil.getVsphereObjectId(objDc);
            wizardScope.wizardViewData.setDatacenterId(datacenterId);
         }

         var preselectComputeResourceId = getTargetComputeResId(creationType, props,
               isScheduledTask, scheduledTaskEditData);
         if (preselectComputeResourceId) {
            wizardScope.wizardViewData.setSourceObjectName(props[NAME_PROPERTY]);
            wizardScope.wizardViewData.setSourceObjectId(contextObjectId);
         }

         if (creationType === creationTypeConstants.CREATE_FROM_SCRATCH) {
            setRecommendedPreselections(props, wizardScope.wizardViewData);
         }

         if (creationTypeConstants.isCloningVmToLibrary(creationType)) {
            wizardScope.wizardViewData.setSourceObjectId(contextObjectId);
            wizardScope.vmParams.setName(props[NAME_PROPERTY]);
            //TODO: this code will be removed after clone workflow is going have folder selection page in the wizard
            if(context) {
               wizardScope.vmParams.setTargetInformation(null, null, context.folderUid, null);
            }
         }

         var preselectStorageId = getTargetStorageId();

         var creationTypeTitle = creationType;

         if (isScheduledTask) {
            wizardScope.scheduleTaskData = {
               isScheduledTask: context.isScheduledTask,
               schedulingData: context.schedulingData
            };

            var scheduleTaskOperationMode = context.schedulingData.scheduleTaskOperationMode;
            if (scheduleTaskOperationMode === SCHEDULE_TASK_EDIT_OPERATION_MODE) {
               creationTypeTitle = 'ScheduledTaskEdit' + creationType;
               if (scheduledTaskEditData) {
                  scheduledTaskService.setVmParams(
                        scheduledTaskEditData, wizardScope.vmParams, wizardScope.wizardViewData);
               }
            } else {
               creationTypeTitle = 'ScheduledTask' + creationType;
            }
         }

         wizardScope.config = {
            title: titleString(creationTypeTitle, props[NAME_PROPERTY], isTemplate),
            cssClass: 'provisioning-vm-wizards'
         };

         var provisioningService =
               creationTypeServiceFactory.serviceForCreationType(creationType);
         var result = provisioningService.buildPages(
               wizardScope, preselectFolderId,
               preselectComputeResourceId, preselectStorageId);

         wizardScope.config.pages = wizardPageService.initializePageStates(result.pages);
         wizardScope.config.flows = result.flows;

         var onFinishClicked = false;

         wizardScope.config.onFinish = function () {
            onFinishClicked = true;
            return true;
         };

         wizardScope.config.onClose = function () {
            if (onFinishClicked) {
               return;
            }

            var workflowManagerService = $injector.get('vscOvfWorkflowProxyService');
            var session = wizardScope.wizardViewData.getDeployOvfSession();
            var sessionId = (session && session.deployOvfSessionId) ? session.deployOvfSessionId : '';

            // Check to see if a session exists
            if (sessionId) {
               // Cancel session
               workflowManagerService.cancelSession$(sessionId);
            }
            // Close Wizard (Do not wait for session cancel completion)
            wizardScope.config.show = false;
         };

         wizardScope.wizardViewData.defaultTarget = preselectFolderId;

         vuiService.createWizard({
            scope: wizardScope,
            configObjectName: 'config'
         }).show();
      }

      function getTargetLocationId(props, creationType) {
         // this condition is with highest priority because in Deploy from OVF workflow
         // we have to request and consider firstFolderWithCreateVmPriv even if the
         // context object is DC or folder (because the action does not do this priv check)
         var firstFolderObjWithAppropriatePriv =
               props[FIRST_FOLDER_WITH_APPROPRIATE_PRIVILEGE];
         if (creationType !== creationTypeConstants.CREATE_FROM_SCRATCH &&
               firstFolderObjWithAppropriatePriv) {
            return defaultUriSchemeUtil.getVsphereObjectId(firstFolderObjWithAppropriatePriv);
         }

         var allowedTypes = [managedEntityConstants.DATACENTER, managedEntityConstants.FOLDER];
         var objType = contextObjectParts.type;
         if (allowedTypes.indexOf(objType) > -1) {
            return contextObjectId;
         }

         // In the case of create new vm from scratch workflow, because of the possibility
         // to change the workflows, we do not know which workflow will be selected
         // and if it will have a recommended preselection or no, so for preselection we
         // use the data saved in wizardViewData object - RecommendedPreselections.
         // In the object there is a property defaultFolderId that is used in case there is no recommendation
         // available for the selected workflow and it now contains the DC of the context object.
         if (creationType === creationTypeConstants.CREATE_FROM_SCRATCH) {
            return undefined;
         }

         var objDc = props[DC_PROPERTY];
         return defaultUriSchemeUtil.getVsphereObjectId(objDc);
      }

      function getTargetComputeResId(creationType, props, isScheduledTask, scheduledTaskEditData) {
         var allowedTypes = [managedEntityConstants.CLUSTER, managedEntityConstants.HOST];

         var isCloneToTemplate = creationTypeConstants.isCloningToATemplate(creationType);
         if (!isCloneToTemplate) {
            allowedTypes.push(managedEntityConstants.RESOURCE_POOL);
            allowedTypes.push(managedEntityConstants.V_APP);
         }

         var objType = contextObjectParts.type;
         if (allowedTypes.indexOf(objType) > -1) {
            return contextObjectId;
         }

         // if we are editing a scheduled task, preselect the resource pool from the tasks data
         if (isScheduledTask && scheduledTaskEditData && scheduledTaskEditData.computeResourceId) {
            var crType = defaultUriSchemeUtil
                  .getEntityType(scheduledTaskEditData.computeResourceId);
            if (allowedTypes.indexOf(crType) > -1) {
               return scheduledTaskEditData.computeResourceId;
            }
         }

         var firstComputeObjWithAppropriatePriv =
               props[FIRST_COMPUTE_WITH_APPROPRIATE_PRIVILEGE];
         if (firstComputeObjWithAppropriatePriv) {
            return defaultUriSchemeUtil.getVsphereObjectId(firstComputeObjWithAppropriatePriv);
         }

         return undefined;
      }

      function getTargetStorageId() {
         var isStorage =
               contextObjectParts.type === managedEntityConstants.STORAGE_POD
               || contextObjectParts.type === managedEntityConstants.DATASTORE;
         return isStorage ? contextObjectId : undefined;
      }

      function addPropertiesPerCreationType(creationType, properties, isScheduledTask, context) {
         switch (creationType) {
            case creationTypeConstants.CREATE_FROM_SCRATCH:
               // in case a scheduled task for create VM is edited
               // the folder on which the device is created might be the default folder of
               // the datacenter, to check if this is the case and preselect the correct datacenter
               // we need to check if the folder's parent is a Datacenter
               if (contextObjectParts.type === managedEntityConstants.FOLDER && isScheduledTask) {
                  properties.push(PARENT_PROPERTY);
               }

               // if context object is not DC and VM Folder then request
               // firstFolderWithCreateVmPrivilege property
               if (contextObjectParts.type !== managedEntityConstants.FOLDER
                     && contextObjectParts.type !== managedEntityConstants.DATACENTER) {
                  FIRST_FOLDER_WITH_APPROPRIATE_PRIVILEGE =
                        FIRST_FOLDER_WITH_CREATE_VM_PRIVILEGE_PROPERTY;
                  properties.push(FIRST_FOLDER_WITH_APPROPRIATE_PRIVILEGE);
                  properties.push(FIRST_FOLDER_WITH_CREATE_FROM_EXISTING_VM_PRIVILEGE_PROPERTY);
                  properties.push(FIRST_TEMPLATE_FOLDER_WITH_CREATE_FROM_EXISTING_PRIVILEGE_PROPERTY);
               }

               // if context object is not Cluster, Host, RP and VApp request
               // firstComputeWithCreateVmPrivilege property
               if (contextObjectParts.type !== managedEntityConstants.CLUSTER
                     && contextObjectParts.type !== managedEntityConstants.HOST
                     && contextObjectParts.type !== managedEntityConstants.RESOURCE_POOL
                     && contextObjectParts.type !== managedEntityConstants.V_APP) {
                  FIRST_COMPUTE_WITH_APPROPRIATE_PRIVILEGE =
                        FIRST_COMPUTE_WITH_CREATE_VM_PRIVILEGE_PROPERTY;
                  properties.push(FIRST_COMPUTE_WITH_APPROPRIATE_PRIVILEGE);
               }
               break;
            case creationTypeConstants.DEPLOY_VM_FROM_OVF:
               // if context object is not VM Folder then request
               // firstFolderWithCreateVmPrivilege property
               if (contextObjectParts.type !== managedEntityConstants.FOLDER) {
                  FIRST_FOLDER_WITH_APPROPRIATE_PRIVILEGE =
                        FIRST_FOLDER_WITH_CREATE_VM_PRIVILEGE_PROPERTY;
                  properties.push(FIRST_FOLDER_WITH_APPROPRIATE_PRIVILEGE);
               }

               // if context object is not Cluster, Host, RP and VApp request
               // firstComputeWithVAppImportPrivilege property
               if (contextObjectParts.type !== managedEntityConstants.CLUSTER
                     && contextObjectParts.type !== managedEntityConstants.HOST
                     && contextObjectParts.type !== managedEntityConstants.RESOURCE_POOL
                     && contextObjectParts.type !== managedEntityConstants.V_APP) {
                  FIRST_COMPUTE_WITH_APPROPRIATE_PRIVILEGE =
                        FIRST_COMPUTE_WITH_VAPP_IMPORT_PRIVILEGE_PROPERTY;
                  properties.push(FIRST_COMPUTE_WITH_APPROPRIATE_PRIVILEGE);
               }

               break;
            default: break;
         }

         // add the properties that will recommend a folder or compute resource
         // to be preselected
         var recommendedVmFolderProperty = getPropertyToRecommendVmFolderByType(creationType, context);
         addPropertyForRecommendedFolderToBeRetrieved(contextObjectParts.type, properties, recommendedVmFolderProperty);
      }

      function addPropertyForRecommendedFolderToBeRetrieved(
            contextObjectType, properties, property) {
         // if context object is not DC and VM Folder then request the property
         // to recommend a VM folder for preselection
         if (property && contextObjectType !== managedEntityConstants.FOLDER
               && contextObjectType !== managedEntityConstants.DATACENTER) {
            FIRST_FOLDER_WITH_APPROPRIATE_PRIVILEGE = property;
            properties.push(FIRST_FOLDER_WITH_APPROPRIATE_PRIVILEGE);
         }
      }

      function getPropertyToRecommendVmFolderByType(creationType, context) {
         switch (creationType) {
            case creationTypeConstants.CLONE_VM_TO_VM:
            case creationTypeConstants.CLONE_TEMPLATE_TO_VM:
               return FIRST_FOLDER_WITH_CREATE_FROM_EXISTING_VM_PRIVILEGE_PROPERTY;
            case creationTypeConstants.CLONE_VM_TO_TEMPLATE:
            case creationTypeConstants.CLONE_TEMPLATE_TO_TEMPLATE:
               return FIRST_TEMPLATE_FOLDER_WITH_CREATE_FROM_EXISTING_PRIVILEGE_PROPERTY;
            case creationTypeConstants.DEPLOY_VM_FROM_OVF:
               return FIRST_FOLDER_WITH_CREATE_VM_PRIVILEGE_PROPERTY;
            case creationTypeConstants.REGISTER_VM:
               if(context.isTemplate) {
                  return FIRST_TEMPLATE_FOLDER_WITH_REGISTER_VM_PRIVILEGE_PROPERTY;
               } else {
                  return FIRST_FOLDER_WITH_REGISTER_VM_PRIVILEGE_PROPERTY;
               }
               break;
            default: break;
         }
      }

      function setRecommendedPreselections(props, wizardViewData) {
         var recommendedPreselections = {
            createWorkflow: {},
            cloneWorkflow: {},
            templateWorkflow: {},
            defaultFolderId: ""
         };

         var objDc = props[DC_PROPERTY];
         var dcId = defaultUriSchemeUtil.getVsphereObjectId(objDc);

         if (props[FIRST_FOLDER_WITH_CREATE_VM_PRIVILEGE_PROPERTY]) {
            recommendedPreselections.createWorkflow.folderId =
                  defaultUriSchemeUtil.getVsphereObjectId(
                        props[FIRST_FOLDER_WITH_CREATE_VM_PRIVILEGE_PROPERTY]);
         } else {
            recommendedPreselections.createWorkflow.folderId = dcId;
         }

         if (props[FIRST_FOLDER_WITH_CREATE_FROM_EXISTING_VM_PRIVILEGE_PROPERTY]) {
            recommendedPreselections.cloneWorkflow.folderId =
                  defaultUriSchemeUtil.getVsphereObjectId(
                        props[FIRST_FOLDER_WITH_CREATE_FROM_EXISTING_VM_PRIVILEGE_PROPERTY]);
         } else {
            recommendedPreselections.cloneWorkflow.folderId = dcId;
         }

         if (props[FIRST_TEMPLATE_FOLDER_WITH_CREATE_FROM_EXISTING_PRIVILEGE_PROPERTY]) {
            recommendedPreselections.templateWorkflow.folderId =
                  defaultUriSchemeUtil.getVsphereObjectId(
                        props[FIRST_TEMPLATE_FOLDER_WITH_CREATE_FROM_EXISTING_PRIVILEGE_PROPERTY]);
         } else {
            recommendedPreselections.templateWorkflow.folderId = dcId;
         }

         recommendedPreselections.defaultFolderId = dcId;

         wizardViewData
               .setRecommendedFolderAndComputeResource(recommendedPreselections);
      }
   }]);
