(function () {
   'use strict';

   /**
    * Constants used in Add Networking wizard.
    */
   angular.module('com.vmware.vsphere.client.network')
         .constant('addNetworkingWizardConstants', {
                  connectionType: {
                     VMKERNEL: '0',
                     VM_PORTGROUP: '1',
                     PNIC: '2'
                  },
                  targetType: {
                     EXISTING_NETWORK: 'existingNetwork',
                     EXISTING_STANDARD_SWITCH: 'existingStandardSwitch',
                     NEW_STANDARD_SWITCH: 'newStandardSwitch'
                  },
                  ipSettingsMode: {
                     IP_V4: 'ipv4',
                     IP_V6: 'ipv6',
                     MIXED: 'mixed'
                  },
                  switchType: {
                     STANDARD: 'standardSwitch',
                     DISTRIBUTED: 'distributedSwitch'
                  },
                  pages: {
                     SELECT_CONNECTION_TYPE: "selectConnectionTypePage",
                     SELECT_TARGET_TYPE: "selectTargetNetworkPage",
                     ADD_PNIC: "addPnicPage",
                     ADD_PNIC_DVS: "addPnicToDvsPage",
                     CREATE_STANDARD_SWITCH: "createStandardSwitchPage",
                     CONNECTION_SETTINGS: "addHostNetworkingConnectionSettingsPag",
                     PORT_PROPERTIES: "portPropertiesPag",
                     IPV4_SETTINGS: "addHostNetworkingIpv4SettingsPag",
                     IPV6_SETTINGS: "addHostNetworkingIpv6SettingsPag",
                     BIND_TO_PNIC: "bindToPhysicalAdapterPage",
                     RTC: "addVnicReadyToCompletePage"
                  },
                  defaultNetStackKey: 'defaultTcpipStack',
                  provisioningNetStackKey: 'vSphereProvisioning',
                  vmotionNetStackKey: 'vmotion'
               }
         );
})();
