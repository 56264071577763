/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace dvs_ui {

   import DvsSpanSessionDestinationUplinksData =
         com.vmware.vsphere.client.h5.network.dvs.portmirroring.model.wizard.DvsSpanSessionDestinationUplinksData;

   import IPromise = angular.IPromise;

   export class DvsSpanSessionDestinationUplinksService {

      public static $inject = [
         "$q",
         "i18nService",
         "vuiConstants",
         "dataService"
      ];

      public static DATA_PROPERTY: string = "dvswitchportmirroring:dvsuplinks";

      public getString: (key: string) => string;

      constructor(private $q: any,
                  private i18nService: any,
                  private vuiConstants: any,
                  private dataService: any) {
         this.getString = (key: string): string => {
            return this.i18nService.getString("DvsUi", key);
         };
      }

      public getDestinationUplinkNames(dvsUrn: string):IPromise<DvsSpanSessionDestinationUplinksData[]> {
         return this.dataService.getProperties(
               dvsUrn, [DvsSpanSessionDestinationUplinksService.DATA_PROPERTY])
               .then((response: any) => {
                  return response[DvsSpanSessionDestinationUplinksService.DATA_PROPERTY];
               });
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .service("dvsSpanSessionDestinationUplinksService",
         DvsSpanSessionDestinationUplinksService);
}