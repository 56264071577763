(function() {
   'use strict';
   angular.module('com.vmware.vsphere.client.host')
         .controller('HardwareProcessorsController', HardwareProcessorsController);

   HardwareProcessorsController.$inject =
         ['$scope', 'hardwareProcessorsService'];

   function HardwareProcessorsController($scope, hardwareService) {
      this.hostId = $scope._route.objectId;
      this.watchForObjects = [this.hostId];
      var generalSettingsObject = hardwareService.getGeneralSettingsOptions(this.hostId);
      this.generalHeaderOptions = generalSettingsObject.headerOptions;
      this.generalSettingsViewOptions = generalSettingsObject.settingsViewOptions;

      var systemSettingsObject = hardwareService.getSystemSettingsOptions(this.hostId);
      this.systemHeaderOptions = systemSettingsObject.headerOptions;
      this.systemSettingsViewOptions = systemSettingsObject.settingsViewOptions;
   }
})();
