module vm_ui {

   import Privileges = h5_vm.Privileges;
   import IQService = angular.IQService;

   import ConfigTarget = com.vmware.vim.binding.vim.vm.ConfigTarget;
   import VirtualVmxnet3VrdmaOption = com.vmware.vim.binding.vim.vm.device.VirtualVmxnet3VrdmaOption;
   import ChoiceOption = com.vmware.vim.binding.vim.option.ChoiceOption;
   import VirtualEthernetCardOption = com.vmware.vim.binding.vim.vm.device.VirtualEthernetCardOption;
   import VirtualPCIControllerOption = com.vmware.vim.binding.vim.vm.device.VirtualPCIControllerOption;
   import IntOption = com.vmware.vim.binding.vim.option.IntOption;
   import VmConfigContext = com.vmware.vsphere.client.vm.config.VmConfigContext;
   import ElementDescription = com.vmware.vim.binding.vim.ElementDescription;

   export class VmHardwareNetworkService {
      static $inject: string[] = [
         "$q",
         "i18nService",
         "dataService",
         "defaultUriSchemeUtil",
         "authorizationService",
         "vmHardwareUtil",
         "vmHardwareVersionService"
      ];

      private readonly ROCEV1 = "rocev1";
      private readonly ROCEV2 = "rocev2";
      private readonly PCI_CONTROLLER_TYPE: string = "com.vmware.vim.binding.vim.vm.device.VirtualPCIController";

      constructor(private $q: IQService,
                  private i18nService: any,
                  private dataService: any,
                  private defaultUriSchemeUtil: any,
                  private authorizationService: any,
                  private vmHardwareUtil: any,
                  private vmHardwareVersionService: any) {
      }

      public isVmPoweredOn(vmConfigContext: any):boolean {
         return this.vmHardwareUtil.isVmPoweredOn(vmConfigContext.environment.powerState);
      }
      public getNetworkProviderFromVmConfig(vmConfigContext: any): any {
         return this.defaultUriSchemeUtil.getVsphereObjectId(vmConfigContext.rtInfo.host);
      }

      public getVmConfigTarget(vmId: string): ng.IPromise<any> {
         return this.dataService.getProperties(vmId, ['vmConfigTarget']);
      }

      /**
       * Get the network selector networks from the VM environment browser config target
       * @param filterSpec
       *       The filter spec of the network selector
       * @param configTarget
       *       VM environment browser config target
       * @returns An array of NetworkSelectorPortgroupData objects
       */
      public getNetworkSelectorNetworks(filterSpec: any, configTarget: ConfigTarget): any[] {

         var networkSelectorData: any[] = [];

         if (!configTarget || !filterSpec) {
            return networkSelectorData;
         }

         if (filterSpec.includeDistributedPortGroups) {
            var dvpgInfos: any[] = configTarget.distributedVirtualPortgroup;

            if (dvpgInfos && dvpgInfos.length > 0) {
               _.forEach(dvpgInfos,  (dvpgInfo: any) => {
                  if (filterSpec.includeUplinkPortGroups || !dvpgInfo.uplinkPortgroup) {
                     var dvpgData: any = this.createDvpgNetworkSelectorData(dvpgInfo);

                     if (dvpgData) {
                        networkSelectorData.push(dvpgData);
                     }
                  }
               });
            }
         }

         if (filterSpec.includeOpaqueNetworks) {
            var opaqueNetworkInfos: any[] = configTarget.opaqueNetwork;

            if (opaqueNetworkInfos && opaqueNetworkInfos.length > 0) {
               _.forEach(opaqueNetworkInfos,  (opaqueNetworkInfo: any) => {

                  var opaqueNetworkData: any =
                        this.createOpaqueNetworkSelectorData(opaqueNetworkInfo);

                  if (opaqueNetworkData) {
                     networkSelectorData.push(opaqueNetworkData);
                  }
               });
            }
         }

         if (filterSpec.includeStandardNetworks) {
            var networkInfos: any[] = configTarget.network;

            if (networkInfos && networkInfos.length > 0) {
               _.forEach(networkInfos, (networkInfo: any) => {

                  var standardNetworkData: any =
                        this.createStandardNetworkSelectorData(networkInfo);

                  if (standardNetworkData) {
                     networkSelectorData.push(standardNetworkData);
                  }
               });
            }
         }

         return networkSelectorData;
      }

      private createDvpgNetworkSelectorData(dvpgInfo: any): any {
         if (!dvpgInfo) {
            return undefined;
         }

         return {
            _type: "com.vmware.vsphere.client.modules.api.networkselector.NetworkSelectorPortgroupData",
            name: dvpgInfo.portgroupName,
            portgroupKey:  dvpgInfo.portgroupKey,
            dvsName:  dvpgInfo.switchName,
            dvsUuid:  dvpgInfo.switchUuid,
            networkRef:  dvpgInfo.portgroup,
            isUplink:  dvpgInfo.uplinkPortgroup
         };
      }

      private createOpaqueNetworkSelectorData(opaqueNetworkInfo: any): any {
         if (!opaqueNetworkInfo || !opaqueNetworkInfo.network) {
            return undefined;
         }

         return {
            _type: "com.vmware.vsphere.client.modules.api.networkselector.NetworkSelectorPortgroupData",
            isOpaqueNetwork: true,
            name: opaqueNetworkInfo.name,
            networkRef:  opaqueNetworkInfo.network.network,
            opaqueNetworkType:  opaqueNetworkInfo.network.opaqueNetworkType
         };
      }

      private createStandardNetworkSelectorData(networkInfo: any): any {
         if (!networkInfo || !networkInfo.network) {
            return undefined;
         }

         return {
            _type: "com.vmware.vsphere.client.modules.api.networkselector.NetworkSelectorPortgroupData",
            isStandardNetwork: true,
            name: networkInfo.name,
            networkRef:  networkInfo.network.network
         };
      }

      public checkNetworkPrivileges(id: string): ng.IPromise<[boolean]> {
         if (id) {
            return this.authorizationService.checkPrivileges(id, ['Network.Assign']);
         } else {
            return this.$q.when([false]);
         }
      }

      public buildNetworkItemForSelectedNetworkBacking(configTarget: any, device: any): any {

         let backing = device.backing;

         if (backing._type.indexOf('DistributedVirtualPortBackingInfo') > -1) {
            return this.buildDistributedVirtualPortGroupItem(configTarget, backing);
         } else if (backing._type.indexOf('OpaqueNetworkBackingInfo') > -1) {
            return this.buildOpaqueNetworkItem(configTarget, backing);
         } else if (backing._type.indexOf('NetworkBackingInfo') > -1) {
            return this.buildNetworkItem(configTarget, backing);
         }
      }

      public getResourcePoolForVm(vmId: string): ng.IPromise<any> {
         return this.dataService.getProperties(vmId, ['resourcePool']);
      }

      public canConfigurePVRDMADeviceProtocol(vmVersion: string): boolean {
         //TODO: replace this with a flag passed from the backend
         return this.vmHardwareVersionService.getVersionNumber(vmVersion) >= 14;
      }

      public getPVRDMADeviceProtocols(): Array<string> {
         return [this.ROCEV1, this.ROCEV2];
      }

      public getPVRDMADeviceProtocolLabel(deviceProtocol: string): string {
         return this.i18nService.getString('VmUi', 'NetworkConfig.VirtualVmxnet3Vrdma.'+deviceProtocol);
      }

      /* Private */
      private buildDistributedVirtualPortGroupItem(configTarget: any, backing: any) {
         let identifier = backing.port.portgroupKey;
         let allPortGroups = configTarget.distributedVirtualPortgroup;
         let selection = _.find(allPortGroups, (item: any) => {
            return (item.portgroupKey === identifier);
         });
         if (!selection){
            return this.generateEmptyNetworkItem();
         }

         let mor = selection.portgroup;
         let uri = this.defaultUriSchemeUtil.createVmomiUri(mor.type, mor.value, mor.serverGuid);

         return this.generateNetworkItem(
               selection.portgroupName,
               selection.switchUuid,
               uri,
               uri,
               null
         );
      }

      private buildNetworkItem(configTarget: any, backing: any) {
         let identifier = backing.deviceName;
         let allNetworks = configTarget.network;
         let selection = _.find(allNetworks, (item: any) => {
            return (item.name === identifier);
         });
         if (!selection){
            return this.generateEmptyNetworkItem();
         }

         let mor = selection.network.network;
         let uri = this.defaultUriSchemeUtil.createVmomiUri(mor.type, mor.value, mor.serverGuid);

         return this.generateNetworkItem(
               selection.name,
               null,
               uri,
               uri,
               null
         );
      }

      private buildOpaqueNetworkItem(configTarget: any, backing: any) {
         /*TODO somashekars: test this code with NSX switch available in the inventory*/
         let identifier = backing.opaqueNetworkId;
         let allOpaqueNetworks = configTarget.opaqueNetwork;
         let selection = _.find(allOpaqueNetworks, (item: any) => {
            return (item.network.opaqueNetworkId === identifier);
         });
         if (!selection){
            return this.generateEmptyNetworkItem();
         }

         let opaqueNetwork = selection.network;
         return this.generateNetworkItem(
               opaqueNetwork.name,
               null,
               opaqueNetwork.opaqueNetworkId,
               opaqueNetwork.opaqueNetworkId,
               opaqueNetwork.opaqueNetworkType
         );
      }

      private generateEmptyNetworkItem(){
         return this.generateNetworkItem("", null, null, null, null);
      }

      private generateNetworkItem(name: string, dvsUuid: any, networkId: any, id: any, opaqueNetworkType: any) {
         return {
            name: name,
            dvsUuid: dvsUuid,
            networkId: networkId,
            id: id,
            opaqueNetworkType: opaqueNetworkType
         };
      }

      public isRemoveNetworkAdapterDisabled(privileges: Array<string>): boolean {
         return !this.hasAddRemoveDevicePrivilege(privileges);
      }

      private hasAddRemoveDevicePrivilege(privileges: any) {
         return this.vmHardwareUtil.checkPrivileges(privileges, [Privileges.VM_ADDREMOVEDEVICE_PRIVILEGE]);
      }

      public supportsDeviceProtocol(option:VirtualEthernetCardOption): boolean {

         let vrdmaOption:VirtualVmxnet3VrdmaOption = option as VirtualVmxnet3VrdmaOption;

         if (!vrdmaOption) {
            return false;
         }

         let choice:ChoiceOption = vrdmaOption.deviceProtocol;

         if (!choice) {
            return false;
         }

         if (choice.choiceInfo.length < 2) {
            return false;
         }

         return true;
      }

      public getDefaultRDMAProtocol(vrdmaOption:VirtualVmxnet3VrdmaOption):string {
         if (!vrdmaOption) {
            return "";
         }
         let choice:ChoiceOption = vrdmaOption.deviceProtocol;

         let array:ElementDescription[] = choice.choiceInfo;
         let defaultIndex = choice.defaultIndex;

         if(defaultIndex === 0 && array.length > 1) {
            defaultIndex = 1;
         }

         let item:ElementDescription = array[defaultIndex];
         return item.key;
      }

      public isPVRDMALimitReached(virtualMachineDevices:any, vmConfigContext:VmConfigContext):boolean {
         let max:number = this.getPVRDMALimit(vmConfigContext);
         let count:number = this.getPVRDMACount(virtualMachineDevices);
         return count >= max;
      }

      public getPVRDMACount(virtualMachineDevices:any):number {

         let vrdmaList:Array<any> = virtualMachineDevices.devicesOfType("VirtualVmxnet3Vrdma");

         if (!vrdmaList || vrdmaList.length === 0) {
            return 0;
         }

         let vrdmaEthernetCount:number = vrdmaList.length;
         return vrdmaEthernetCount;
      }

      public getPVRDMALimit(vmConfigContext:VmConfigContext):number {

         let pciControllerOptions:VirtualPCIControllerOption = this.vmHardwareUtil.getDeviceOption(vmConfigContext, this.PCI_CONTROLLER_TYPE);
         let numVrdmaOption:IntOption = pciControllerOptions.numVmxnet3VrdmaEthernetCards;
         let vrdmaEthernetMaxCount = 1;

         if (numVrdmaOption) {
            vrdmaEthernetMaxCount = numVrdmaOption.max;
         }
         return vrdmaEthernetMaxCount;
      }
      private readonly RDMA_DEVICE_CLASS: string = "com.vmware.vim.binding.vim.vm.device.VirtualVmxnet3Vrdma";

      public removeType(adapterTypes:Array<any>, clazz:any):Array<any> {
         let retVal:Array<any>;
         let filterFn:any =  (item: any) => {return item.type.name !== clazz;};
         retVal =_.filter(adapterTypes, filterFn );
         return retVal;
      }

      public removePVRDMAType(adapterTypes:Array<any>):Array<any> {
         let clazz:any = this.RDMA_DEVICE_CLASS;
         return this.removeType(adapterTypes,clazz);
      }
   } // class ends



   angular.module("com.vmware.vsphere.client.vm").service("vmHardwareNetworkService", VmHardwareNetworkService);
}
