namespace network_ui {
   export class NetStackEditDnsConfigPageValidator {
      public static $inject = [
         "i18nService",
         "networkUtil",
         "networkUiConstants",
         "ipParserService"
      ];

      constructor(private i18nService: any,
                  private networkUtil: any,
                  private networkUiConstants: any,
                  private ipParserService: any) {
      }

      public getNoVirtualNicsValidationMessage(vnics: string[]): string | null {
         if (!vnics || vnics.length === 0) {
            return this.i18nService.getString(
                  'NetworkUi', 'DnsAndRoutingData.noDhcpVmkernelAdapters');
         }
         return null;
      }

      public getNoVnicSelectedValidationMessage(vnics: string[], selectedVnic: string): string | null {
         if ((vnics && vnics.length > 0) &&
               (!selectedVnic || vnics.indexOf(selectedVnic) === -1)) {
            return this.i18nService
                  .getString("NetworkUi", "DnsAndRoutingData.invalidDhcpVmkernelAdapter");
         }
         return null;
      }

      public getHostNameValidationMessage(hostName: string): string | null {
         if (!hostName) {
            return this.i18nService
                  .getString("NetworkUi", "DnsAndRoutingData.hostNameNotSpecified");
         }

         // The host name should be up to 63 characters long
         if (hostName.length > this.networkUiConstants.MAX_HOST_NAME_LENGTH) {
            return this.i18nService
                  .getString("NetworkUi", "DnsAndRoutingData.invalidHostNameLength");
         }

         // Check for an invalid host name
         if (!this.networkUtil.isHostNameValid(hostName)) {
            // get the correct message
            return hostName.indexOf(".") === -1 ?
                  this.i18nService.getString("NetworkUi",
                        "DnsAndRoutingData.invalidHostNameFormat") :
                  this.i18nService.getString("NetworkUi",
                        "DnsAndRoutingData.invalidHostNameWithPeriod");
         }

         return null;
      }

      public getDomainNameValidationMessage(domainName: string): string | null {
         if (!domainName) {
            return this.i18nService
                  .getString("NetworkUi", "DnsAndRoutingData.domainNameNotSpecified");
         }

         // Check for too long domain name
         if (domainName.length > this.networkUiConstants.FQDN_MAX_LENGTH) {
            return this.i18nService
                  .getString("NetworkUi", "DnsAndRoutingData.invalidFqdnLength");
         }

         // Check for too long labels in the domain name
         let labels:string[] = domainName.split(".");
         if (!this.areDomainLabelsLengthsValid(labels)) {
            return this.i18nService
                  .getString("NetworkUi", "DnsAndRoutingData.invalidDomainLabelLength");
         }

         // Check for invalid domain names
         if (!this.networkUtil.isDomainNameValid(domainName)) {
            return this.i18nService
                  .getString("NetworkUi", "DnsAndRoutingData.invalidDomainNameFormat");
         }

         return null;
      }

      public getSearchDomainsValidationMessage(searchDomains: string): string | null {
         if (!searchDomains || !searchDomains.trim()) {
            return null;
         }

         let searchDomainsArray: string[] =
               NetStackEditDnsConfigPageValidator.splitSearchDomains(searchDomains);

         if (searchDomainsArray.length > this.networkUiConstants.MAX_NUMBER_SEARCH_DOMAINS) {
            return this.i18nService.getString(
                  "NetworkUi", "DnsAndRoutingData.searchDomainsMaxNumber",
                  this.networkUiConstants.MAX_NUMBER_SEARCH_DOMAINS);
         }

         for (let searchDomain of searchDomainsArray) {
            // Check for too long domain name
            if (searchDomain.length > this.networkUiConstants.FQDN_MAX_LENGTH) {
               return this.i18nService.getString(
                     "NetworkUi", "DnsAndRoutingData.invalidSearchDomainsNameLength");
            }

            // Check for too long labels in the domain name
            let labels:string[] = searchDomain.split(".");
            if (!this.areDomainLabelsLengthsValid(labels)) {
               return this.i18nService.getString(
                     "NetworkUi", "DnsAndRoutingData.invalidSearchDomainsLabelLength");
            }

            // Check for invalid domain names
            if (!this.networkUtil.isDomainNameValid(searchDomain)) {
               return this.i18nService.getString(
                     "NetworkUi", "DnsAndRoutingData.invalidSearchDomainsFormat");
            }
         }

         return null;
      }

      public getPreferredDnsValidationMessage(preferredDns: string,
                                              isIpv6Enabled: boolean): string | null {

         return this.isDnsValid(preferredDns, isIpv6Enabled) ? null :
               this.i18nService.getString(
                     "NetworkUi", "DnsAndRoutingData.preferredDnsError");
      }

      public getAlternateDnsValidationMessage(alternateDns: string,
                                              isIpv6Enabled: boolean): string | null {
         return this.isDnsValid(alternateDns, isIpv6Enabled) ? null :
               this.i18nService.getString(
                     "NetworkUi", "DnsAndRoutingData.alternateDnsError");
      }

      private isDnsValid(dsn: string,
                         isIpv6Enabled: boolean): boolean {
         if (!dsn) {
            return true;
         }

         let isDnsValid: boolean = this.ipParserService.isIpv4AddressValid(dsn);

         // If the DNS is not in a valid IPv4 format AND IPv6 is enabled - validate
         // against IPv6 format
         if (!isDnsValid && isIpv6Enabled) {
            isDnsValid = this.ipParserService.isIpv6AddressValid(dsn);
         }

         return isDnsValid;
      }

      private areDomainLabelsLengthsValid(labels: string[]): boolean {
         if (!labels || labels.length === 0) {
            return false;
         }
         return !_.any(labels, (label) => {
            return label.length > this.networkUiConstants.MAX_DOMAIN_NAME_LENGTH;
         });
      }

      private static splitSearchDomains(searchDomains: string): string[] {
         return searchDomains.trim().split(/\s+/);
      }
   }

   angular.module("com.vmware.vsphere.client.network")
         .service("netStackEditDnsConfigPageValidator", NetStackEditDnsConfigPageValidator);
}
