module h5_vm {

    class FtErrorsWarningController implements angular.IController {

        static $inject = [
            "$scope",
            "vuiConstants",
            "i18nService",
            "columnRenderersRegistry",
            "ftVmWizardService",
            "ftVmService"
        ];

        private faultDefaultDescriptionText: string;
        private gridOptions: any;
        private iconTextRenderer: any;
        private objectId: string;

        constructor(private $scope: any,
                    private vuiConstants: any,
                    private i18nService: any,
                    private columnRenderersRegistry: any,
                    private ftVmWizardService: FtVmWizardService,
                    private ftVmService: FtVmService) {

            let dialogData: any = $scope.modalOptions.dialogData;
            this.objectId = dialogData.objectId;
            this.faultDefaultDescriptionText = dialogData.errorText;
            this.iconTextRenderer = this.columnRenderersRegistry.getColumnRenderer('icon-text');
            this.gridOptions = this.ftVmService.getErrorPageGridOptions(dialogData.allIssuesArray);

            $scope.modalOptions.onSubmit = () => {
                this.ftVmWizardService.invokeWizard(this.objectId, dialogData.manager);
                return true;
            };
        }
    }

    angular.module('com.vmware.vsphere.client.vm')
        .controller('FtErrorsWarningController', FtErrorsWarningController);
}
