/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
(function () {
   'use strict';
   angular.module('com.vmware.vsphere.client.host').controller('AddHostReadyToCompletePageController', [
      '$scope', 'i18nService', 'vxPropertyViewService',
      function($scope, i18nService, vxPropertyViewService) {
         var model = $scope.manager.getReadyToCompletePageModel();

         var hostStr = _.bind(i18nService.getString, i18nService, 'HostUi');
         var getValue = function(value) {
            if (!value) {
               return hostStr('none.label');
            }
            return value;
         };

         var builder = vxPropertyViewService.createPropertyViewBuilder();
         var sectionBuilder = builder.category('').section('');

         sectionBuilder
               .property(
                     hostStr('addHostWizard.confirmationPage.hostNameLabel'),
                     model.name
               )
               .propertyBuilder()
               .keyBuilder()
               .text(hostStr('addHostWizard.confirmationPage.locationLabel'))
               .exit()
               .valueBuilder()
               .text(model.destinationName)
               .icon(model.destinationIcon)
               .exit().exit()
               .property(
                     hostStr('addHostWizard.confirmationPage.hostVersionLabel'),
                     model.version
               );

         if (model.licenseName) {
            sectionBuilder.property(
                  hostStr('addHostWizard.confirmationPage.licenseKeyLabel'),
                  model.licenseName
            );
         }

         sectionBuilder.property(
               hostStr('addHostWizard.confirmationPage.networksLabel'),
               getValue(model.networks)
         ).property(
                     hostStr('addHostWizard.confirmationPage.datastoresLabel'),
                     getValue(model.datastores)
         );

         if (model.lockdownMode) {
            sectionBuilder.property(
                  hostStr('addHostWizard.confirmationPage.lockDownModeLabel'),
                  model.lockdownMode
            );
         }

         if (model.vmLocation) {
            sectionBuilder.property(
                  hostStr('addHostWizard.confirmationPage.vmLocationLabel'),
                  model.vmLocation
            );
         }

         if (model.destinationResourceLocation) {
            sectionBuilder.property(
                  hostStr('addHostWizard.confirmationPage.rpLabel'),
                  model.destinationResourceLocation
            );
         }

         this.propertyViewData = builder.build();
      }
   ]);
})();
