namespace h5_client {
    module common_module_ui {

        import IComponentController = angular.IComponentController;

        export class VCServerSettingController implements IComponentController {

            public static $inject = [
                "$scope",
                "vcServerSettingService"
            ];

            /*
             * Server settings properties
             */
            private vcServerSettings: any;

            public constructor(
                    private $scope: any,
                    private vcServerSettingService: any) {

                let objectId: string = this.$scope._route.objectId ||
                        this.$scope._vxViewNodeData.node.contentSpec.metadata.objectId;

                this.vcServerSettingService.getServerSettings(objectId).then((vcServerSettings: any) => {
                    this.vcServerSettings = vcServerSettings;
                });
            }

        }

        angular.module("com.vmware.vsphere.client.commonModule")
                .controller("VCServerSettingController", VCServerSettingController);

    }
}
