angular.module('com.vmware.vsphere.client.vm').factory('VirtualTPM', [
   'EditableVirtualDevice',
   function (EditableVirtualDevice) {
      var VirtualTPM = function (device, state) {
         EditableVirtualDevice.call(this, device);
         this.state = state ? state : VirtualTPM.prototype.CONNECTING;
      };

      VirtualTPM.prototype.CONNECTED = 'CONNECTED';
      VirtualTPM.prototype.CONNECTING = 'CONNECTING';
      VirtualTPM.prototype.NOT_CONNECTED = 'NOT_CONNECTED';
      return VirtualTPM;
   }
]);
