namespace network_ui {

   import IRootScopeService = angular.IRootScopeService;
   import NetStackConfigItem =
         com.vmware.vsphere.client.h5.network.host.netstack.model.NetStackConfigItem;
   import NetStackConfigSpec =
         com.vmware.vsphere.client.h5.network.host.netstack.model.NetStackConfigSpec;

   export class NetStackEditDialogService {

      public static $inject = ["$rootScope",
         "vxMultiPageDialogService",
         "i18nService",
         "dataService",
         "netStackEditDialogManagerFactory",
         "netStackEditDialogPages"];

      public static DATA_PROPERTY: string = "netstack:netStackConfig";

      constructor(private $rootScope: IRootScopeService,
                  private vxMultiPageDialogService: any,
                  private i18nService: any,
                  private dataService: any,
                  private managerFactory: NetStackEditDialogManagerFactory,
                  private netStackEditDialogPages: NetStackEditDialogPages) {
      }

      public show(hostId: string, netStackKey: string, netStackName: string,
                  multipleNetworkStackInstanceSupported: boolean): void {
         let dialogScope: any = this.$rootScope.$new();
         dialogScope.hostId = hostId;
         dialogScope.netStackKey = netStackKey;
         dialogScope.multipleNetworkStackInstanceSupported =
               multipleNetworkStackInstanceSupported;

         let dialogManager: NetStackEditDialogManager =
               this.managerFactory.createManager(dialogScope);

         dialogScope.dialogOptions = this.createDialogOptions(netStackName);

         dialogScope.dialogOptions.pages =
               this.netStackEditDialogPages.createPages(dialogScope);
         dialogScope.dialogOptions.confirmOptions = {
            onClick: (): boolean => {
               return dialogManager.submitDialog();
            }
         };

         let options: any = {
            scope: dialogScope,
            configObjectName: "dialogOptions"
         };

         this.requestData(dialogScope);

         this.vxMultiPageDialogService(options);
      }

      private requestData(dialogScope: any): void {
         let netStackConfigSpec: NetStackConfigSpec = new NetStackConfigSpec();
         netStackConfigSpec.netStackInstanceKey = dialogScope.netStackKey;

         let params: any = [{
            propertyName: NetStackEditDialogService.DATA_PROPERTY,
            parameterType: netStackConfigSpec._type,
            parameter: netStackConfigSpec
         }];

         this.dataService.getProperties(
               dialogScope.hostId, [NetStackEditDialogService.DATA_PROPERTY], {
                  propertyParams: params
               })
               .then((result: any): void => {
                        if (result && result[NetStackEditDialogService.DATA_PROPERTY]) {
                           let data: NetStackConfigItem =
                                 result[NetStackEditDialogService.DATA_PROPERTY];

                           if (data.isDefaultNetStack) {
                              dialogScope.dnsConfigPageModel =
                                    new NetStackEditDnsConfigPageModel(data);
                              dialogScope.initialDnsConfigPageModel =
                                    angular.copy(dialogScope.dnsConfigPageModel);
                           }

                           dialogScope.routingPageModel =
                                 new NetStackEditRoutingPageModel(data);
                           dialogScope.namePageModel =
                                 new NetStackEditNamePageModel(data);
                           dialogScope.advancedPageModel =
                                 new NetStackEditAdvancedPageModel(data);

                           // deep clone
                           dialogScope.initialRoutingPageModel =
                                 angular.copy(dialogScope.routingPageModel);
                           dialogScope.initialNamePageModel =
                                 angular.copy(dialogScope.namePageModel);
                           dialogScope.initialAdvancedPageModel =
                                 angular.copy(dialogScope.advancedPageModel);

                           dialogScope.dialogOptions.loading = false;
                        }
                     }
               );
      }

      private createDialogOptions(netStackName: string): any {
         return {
            title: this.i18nService.getString("NetworkUi",
                  "EditDnsAndRoutingView.updatedTitle", netStackName),
            show: true,
            loading: true,
            loadingMessage: this.i18nService.getString("CommonUi", "wizard.loading"),
            validationBanner: {},
            rejectOptions: {
               rejectOnEsc: true
            },
            resizable: true,
            draggable: true,
            maximizable: true
         };
      }
   }

   angular.module("com.vmware.vsphere.client.network")
         .service("netStackEditDialogService", NetStackEditDialogService);
}
