(function() {
   'use strict';
   angular.module('com.vmware.vsphere.client.host').controller('EditLockdownModeController',
         EditLockdownModeController);

   EditLockdownModeController.$inject = ['$scope'];

   function EditLockdownModeController($scope) {
      var self = this;
      self.model = $scope.manager.getLockdownModePageModel();
   }
})();
