angular.module('com.vmware.vsphere.client.commonModule').service('wizardPageService', ['vuiConstants',
   function (vuiConstants) {
      return {
         clearErrors: function(config) {
            config.validationBanner.messages = [];
         },
         showLoadingIndicator: function(config){
            config.loading = true;
         },
         hideLoadingIndicator: function(config){
            config.loading = false;
         },
         markPageComplete: function (config, flowId) {
            config.currentPage.state = vuiConstants.wizard.pageState.COMPLETED;

            var nextPage;
            if (flowId && config.flows) {
               var pageIsBranchingPage = _.any(config.pages, function (page) {
                  return page.title === config.currentPage.title;
               });

               if (pageIsBranchingPage) {
                  // If we haven't branched into flows yet, the current page should be in config.pages
                  // In that case, the 'next page' is the first page in the correct flow
                  nextPage = findFlowById(config, flowId).pages[0];
               } else {
                  // Otherwise, we should be able to find the page in one of the flows,
                  // in which case the next page is the next index in that flow array.
                  // Consider also pages that are part of the selected flow in case some
                  // of the pages of the current flow has a decision option
                  var pages = getPages(config, flowId);
                  nextPage = findPageThatFollows(pages, config.currentPage.title);
               }

               if (nextPage && nextPage.state === vuiConstants.wizard.pageState.DISABLED) {
                  nextPage.state = vuiConstants.wizard.pageState.INCOMPLETE;
               }
            } else {
               nextPage = findPageThatFollows(config.pages, config.currentPage.title);
               if (nextPage && nextPage.state !== vuiConstants.wizard.pageState.COMPLETED) {
                  nextPage.state = vuiConstants.wizard.pageState.INCOMPLETE;
               }
            }
         },
         markPageIncompleteWithError: function (config, message) {
            config.currentPage.state = vuiConstants.wizard.pageState.INCOMPLETE;
            config.validationBanner.messages = [{
               text: message,
               type: vuiConstants.validationBanner.type.ERROR
            }];
         },
         markPageIncompleteWithErrors: function (config, messages) {
            config.currentPage.state = vuiConstants.wizard.pageState.INCOMPLETE;
            config.validationBanner.messages = _.map(messages, function (message) {
               return {
                  text: message,
                  type: vuiConstants.validationBanner.type.ERROR
               };
            });
         },
         invalidateNextPages: function (config, flowId, currentPageTitle) {
            var pages;
            if (config.flows && config.flows.length > 0) {
               pages = getPages(config, flowId);
            } else {
               pages = config.pages;
            }

            var currentPageIdx = _.findIndex(pages, function (page) {
               return page.title === currentPageTitle;
            });

            if (currentPageIdx === -1) {
               throw new Error('Cannot find page index');
            }

            _.each(pages, function (page, i) {
               if (i > currentPageIdx) {
                  if(page.state !== vuiConstants.wizard.pageState.SKIPPED){
                     page.state = vuiConstants.wizard.pageState.DISABLED;
                  }
               }
            });
         },
         initializePageStates: function (pages) {
            var newPages = _.map(pages, function (page) {
               page.state = page.defaultState || vuiConstants.wizard.pageState.DISABLED;
               return page;
            });
            newPages[0].state = vuiConstants.wizard.pageState.INCOMPLETE;
            return newPages;
         },
         initializeFlowStates: function (flows) {
            return _.map(flows, function (flow) {
               flow.pages = _.map(flow.pages, function (page) {
                  page.state = page.defaultState || vuiConstants.wizard.pageState.DISABLED;
                  return page;
               });
               return flow;
            });
         },
         markPageSkipped: function(pages, pageTitle) {
            findPageByTitle(pages, pageTitle).state = vuiConstants.wizard.pageState.SKIPPED;
         },
         markPageDisabled: function(pages, pageTitle) {
            findPageByTitle(pages, pageTitle).state = vuiConstants.wizard.pageState.DISABLED;
         },
         showWarningMessages: function (config, messages) {
            config.validationBanner.messages = _.map(messages, function (message) {
               return {
                  text: message,
                  type: vuiConstants.validationBanner.type.WARNING
               };
            });
         }
      };

      function findPageByTitle(pages, pageTitle) {
         return _.find(pages, function(page) {
            return page.title === pageTitle;
         });
      }

      function findFlowById(config, flowId) {
         var flow = _.find(config.flows, function (flow) {
            return flow.id === flowId;
         });
         if (!flow) {
            throw new Error('Could not find a valid flow with ID: ' + flowId);
         }
         return flow;
      }

      function findPageThatFollows(pages, currentPageTitle) {
         var currentPageIdx = _.findIndex(pages, function (page) {
            return page.title === currentPageTitle;
         });

         if (currentPageIdx === -1) {
            throw new Error('Cannot find page index');
         }

         var nextUnskippedPage = _.find(pages.slice(currentPageIdx + 1), function(page){
            return page.state !== vuiConstants.wizard.pageState.SKIPPED;
         });

         return nextUnskippedPage;
      }

      function getPages(config, flowId) {
         var wizardPages = [];
         var flow = findFlowById(config, flowId);
         if (!flow || !flow.pages || flow.pages.length === 0) {
            return wizardPages;
         }

         var flowPages = flow.pages;
         for (var  i = 0; i < flowPages.length; i++) {
            wizardPages.push(flowPages[i]);
            // Decision Page
            var subFlowId;
            if (flowPages[i].hasOwnProperty('decisionOptions')) {
               var decisionPage = flowPages[i].decisionOptions;
               if (decisionPage.hasOwnProperty('selectedFlowId')) {
                  subFlowId = decisionPage.selectedFlowId;
               } else {
                  subFlowId = decisionPage.flowIds[0];
               }

               var subFlowPages = getPages(config, subFlowId);
               wizardPages = wizardPages.concat(subFlowPages);
            }
         }
         return wizardPages;
      }
   }
]);
