/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace dvs_ui {

   export class DvsSpanSessionPortsListModel {
      public ports: PortData[];

      // should be cleared when changing session type
      public cachedData: any[];

      constructor() {
         this.ports = [];
      }
   }

   export class PortData {
      public id: string;

      public trafficDirection: string;

      constructor(id: string, direction: string) {
         this.id = id;
         this.trafficDirection = direction;
      }
   }
}
