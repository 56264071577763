/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace dvs_ui {

   import HostListItemData = com.vmware.vsphere.client.dvs.api.host.HostListItemData;
   export class DvsSelectHostsPageValidator {

      public static $inject = ["i18nService"];

      constructor(private i18nService: any) {
      }

      public getValidationError(hosts: HostListItemData[]): string[] {
         let errors: string[] = [];

         if (hosts.length === 0) {
            errors.push(this.i18nService.getString(
                  "DvsUi", "SelectHostsPage.validator.noHostsSelected"));
         }

         return errors;
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .service("dvsSelectHostsPageValidator", DvsSelectHostsPageValidator);
}
