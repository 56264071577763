namespace h5_client {
    export module admin_ui {

        export class PrivilegeService {
            static $inject = ['dataService', '$http'];

            private privilegesUrl: string = 'admin-ui/ctrl/privileges';

            constructor(private dataService: any, private $http: any) {}

            public getPrivilegeTree() {
                return this.$http.get(this.privilegesUrl).then((response: any) => {
                    return response.data;
                });
            }

        }

        angular.module('com.vmware.vsphere.client.admin').service('privilegeService', PrivilegeService);
    }
}
