(function() {
   'use strict';

   /**
    * @description
    *
    * Directive to show an error tooltip on an element.
    *
    * |---------|  /-----------------------|
    * | Element | <  Error message         |
    * |---------|  \-----------------------|
    *
    * In order to function correctly this directive needs the `error-tooltip-data`
    * attribute.
    *
    * ******
    * Usage:
    * ******
    *
    *  <input type="text" error-tooltip error-tooltip-data="tooltipData"></input>
    *  <div error-tooltip error-tooltip-data="tooltipData"></input>
    *
    *  <script>
    *     $scope.tooltipData = {
    *        message: 'Some error message',
    *        showMessage: function() { return true/false }
    *     };
    *  </script>
    */
   angular
         .module('com.vmware.vsphere.client.networkLibUi')
         .directive('errorTooltip', errorTooltip);

   errorTooltip.$inject = [
         '$compile',
         '$window',
         'positionService'];

   function errorTooltip(
         $compile,
         $window,
         positionService) {

      return {
         restrict: 'A',
         scope: false,
         link: function(scope, element, attributes) {
            if (!attributes.errorTooltipData) {
               throw new Error('error-tooltip-data attribute is not found.');
            }

            var tooltipScope = scope.$new(true);
            scope.$watch(attributes.errorTooltipData, function(errorData) {
               tooltipScope.errorData = errorData;
            });

            // Show error tooltip on hover
            element.bind('mouseenter', show);
            element.bind('mouseleave', hide);

            var bodyElement = angular.element($window.document.body);
            var templateElement;
            // Template element for tooltip
            var template = '<div ng-show="errorData.showMessage()" ' +
                     'class="vx-error-tooltip tooltip right in">' +
                  '<div class="tooltip-arrow"></div>' +
                  '<div class="tooltip-inner">{{errorData.message}}</div>' +
                  '</div>';

            // Position the tooltip element.
            var positionTooltip = function() {
               var ttPosition = positionService
                     .positionElements(element, templateElement, 'right', true);
               ttPosition.top += 'px';
               ttPosition.left += 'px';

               // Now set the calculated positioning.
               templateElement.css(ttPosition);
            };

            function show() {
               if (!tooltipScope.errorData || !tooltipScope.errorData.showMessage()) {
                  return;
               }

               // Compile template on new inner scope with the values.
               templateElement = $compile(template)(tooltipScope);
               tooltipScope.$apply();
               bodyElement.append(templateElement);
               positionTooltip();
            }

            function hide() {
               // Remove the element.
               if (templateElement) {
                  templateElement.remove();
               }
            }

            scope.$on('$destroy', function() {
               if(templateElement) {
                  templateElement.remove();
               }
            });
         }
      };
   }
})();
