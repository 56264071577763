angular.module('com.vmware.vsphere.client.vm').service('vmProvisioningSelectOvfPageService', [
   'i18nService',
   'wizardPageService',
   'vmVuiWizardPageBuilder',
   'VmProvisioningSelectOvfPageModel',
   function (i18nService, wizardPageService, vmVuiWizardPageBuilder, VmProvisioningSelectOvfPageModel) {
      return {
         build: function(wizardScope, defaultTarget, preselectedComputeResource) {
            var deployOvfSelectPageModel = new VmProvisioningSelectOvfPageModel(
               wizardScope.vmParams,
               wizardScope.wizardViewData,
               wizardScope.config,
               defaultTarget,
               preselectedComputeResource
            );
            wizardScope.deployOvfSelectPageModel = deployOvfSelectPageModel;

            return vmVuiWizardPageBuilder.buildVuiWizardPage(wizardScope.config, {
               title: i18nService.getString('VmUi', 'SelectOvfProvisioningPage.Title'),
               description: i18nService.getString('VmUi', 'SelectOvfProvisioningPage.Description'),
               contentUrl: "vm-ui/resources/vm/views/createVmWizard/deployOvfPages/vmProvisioningSelectOvf/vm-provisioning-select-ovf-page.html",
               model: deployOvfSelectPageModel
            });
         }
      };
   }]);
