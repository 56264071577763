namespace h5_vm {

   import SriovInfo = com.vmware.vim.binding.vim.vm.SriovInfo;
   import VmConfigContext = com.vmware.vsphere.client.vm.config.VmConfigContext;
   import ConfigInfo = com.vmware.vim.binding.vim.vm.ConfigInfo;
   import NetworkInfo = com.vmware.vim.binding.vim.vm.NetworkInfo;
   import Network$Summary = com.vmware.vim.binding.vim.Network$Summary;
   import ManagedObjectReference = com.vmware.vim.binding.vmodl.ManagedObjectReference;

   export class VmHwSriovFixture {

      constructor() {

      }
      public getSriovInfo(): SriovInfo {
         return VmHwSriovFixture.makeSriovInfo();
      }

      public getMultipleSriovInfo(): SriovInfo[] {
         const info: SriovInfo[] = [<SriovInfo> {
            "_type": "com.vmware.vim.binding.vim.vm.SriovInfo",
            "name": "",
            "configurationTag": [],
            "pciDevice": {
               "_type": "com.vmware.vim.binding.vim.host.PciDevice",
               "id": "0000:04:00.0",
               "classId": 512,
               "bus": 4,
               "slot": 0,
               "physicalSlot": 0,
               "slotDescription": "",
               "function": 0,
               "vendorId": -32634,
               "subVendorId": -32634,
               "vendorName": "Intel Corporation",
               "deviceId": 5404,
               "subDeviceId": -24516,
               "parentBridge": "0000:00:07.0",
               "deviceName": "82599EB 10 Gigabit TN Network Connection"
            },
            "systemId": "5b74bbaa-3542-aa1c-57c1-180373f2fec5",
            "virtualFunction": false,
            "pnic": "vmnic4",
            "devicePool": {
               "_type": "com.vmware.vim.binding.vim.vm.SriovNetworkDevicePoolInfo",
               "key": "5295db74-7343-6f96-d123-59974c659348",
               "switchKey": "",
               "switchUuid": "50 29 20 56 ec 99 0d 18-eb a1 ba b3 0a e1 06 cd"
            }
         }, <SriovInfo> {
            "_type": "com.vmware.vim.binding.vim.vm.SriovInfo",
            "name": "",
            "configurationTag": [],
            "pciDevice": {
               "_type": "com.vmware.vim.binding.vim.host.PciDevice",
               "id": "0000:05:00.0",
               "classId": 512,
               "bus": 4,
               "slot": 0,
               "physicalSlot": 0,
               "slotDescription": "",
               "function": 0,
               "vendorId": -32634,
               "subVendorId": -32634,
               "vendorName": "Intel Corporation",
               "deviceId": 5404,
               "subDeviceId": -24516,
               "parentBridge": "0000:00:07.0",
               "deviceName": "82599EB 10 Gigabit TN Network Connection"
            },
            "systemId": "5b74bbaa-3542-aa1c-57c1-180373f2fec5",
            "virtualFunction": true,
            "pnic": "vmnic5",
            "devicePool": {
               "_type": "com.vmware.vim.binding.vim.vm.SriovNetworkDevicePoolInfo",
               "key": "5295db74-7343-6f96-d123-59974c659348",
               "switchKey": "",
               "switchUuid": "50 29 20 56 ec 99 0d 18-eb a1 ba b3 0a e1 06 cd"
            }
         }, <SriovInfo> {
            "_type": "com.vmware.vim.binding.vim.vm.SriovInfo",
            "name": "",
            "configurationTag": [],
            "pciDevice": {
               "_type": "com.vmware.vim.binding.vim.host.PciDevice",
               "id": "0000:06:00.0",
               "classId": 512,
               "bus": 4,
               "slot": 0,
               "physicalSlot": 0,
               "slotDescription": "",
               "function": 0,
               "vendorId": -32634,
               "subVendorId": -32634,
               "vendorName": "Intel Corporation",
               "deviceId": 5404,
               "subDeviceId": -24516,
               "parentBridge": "0000:00:07.0",
               "deviceName": "82599EB 10 Gigabit TN Network Connection"
            },
            "systemId": "5b74bbaa-3542-aa1c-57c1-180373f2fec5",
            "virtualFunction": false,
            "pnic": "vmnic6",
            "devicePool": {
               "_type": "com.vmware.vim.binding.vim.vm.SriovNetworkDevicePoolInfo",
               "key": "5295db74-7343-6f96-d123-59974c659348",
               "switchKey": "5295db74-7343-6f96-d123-59974c659348",
               "switchUuid": ""
            }
         }];

         return info;
      }

      private static makeSriovInfo(): SriovInfo {
         const info: SriovInfo = <SriovInfo> {
            "_type": "com.vmware.vim.binding.vim.vm.SriovInfo",
            "name": "",
            "configurationTag": [],
            "pciDevice": {
               "_type": "com.vmware.vim.binding.vim.host.PciDevice",
               "id": "0000:04:00.0",
               "classId": 512,
               "bus": 4,
               "slot": 0,
               "physicalSlot": 0,
               "slotDescription": "",
               "function": 0,
               "vendorId": -32634,
               "subVendorId": -32634,
               "vendorName": "Intel Corporation",
               "deviceId": 5404,
               "subDeviceId": -24516,
               "parentBridge": "0000:00:07.0",
               "deviceName": "82599EB 10 Gigabit TN Network Connection"
            },
            "systemId": "5b74bbaa-3542-aa1c-57c1-180373f2fec5",
            "virtualFunction": false,
            "pnic": "vmnic4",
            "devicePool": {
               "_type": "com.vmware.vim.binding.vim.vm.SriovNetworkDevicePoolInfo",
               "key": "5295db74-7343-6f96-d123-59974c659348",
               "switchKey": "",
               "switchUuid": "50 29 20 56 ec 99 0d 18-eb a1 ba b3 0a e1 06 cd"
            }
         };

         return info;
      }

      public fixContext(): VmConfigContext {
         let vm: VmConfigContext = <VmConfigContext> {environment: {configTarget: {sriov: [this.getSriovInfo()]}}};

         vm.config = <ConfigInfo> {
            hardware: {memoryMB: 100},
            memoryAllocation: {reservation: 0}
         };
         return vm;
      }

      public makeNetworkInfo():NetworkInfo {
         let network:NetworkInfo = new NetworkInfo();
         network.vswitch = "vswitch-1";
         network.network = <Network$Summary> {network: new ManagedObjectReference()};
         return network;

      }

      public static addSriov(vm:VmConfigContext) {
         vm.environment.configTarget.sriov = [VmHwSriovFixture.makeSriovInfo()];
      }
   }
}
