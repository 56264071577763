module cluster_ui {

   import IController = angular.IController;

   class DrsAutomationLevelController implements IController {

      public static $inject = ["$scope", "drsAutomationLevelService",
         "i18nService", "drsMigrationThresholdService", "drsConstants", "drsAutomationLevelPageService"];

      private pageModel: any;
      private drsAutomation: any;
      private automationLevels: any;
      private signPostParams: any;
      private automationLevelSignPostParams: any;
      private mtSliderOptions: any;
      private predictiveDrsSignPostParams: any;
      private vmAutomationSignPostParams: any;
      private migrationThresholdInformationMessage: any;
      private automationLevelInformationMessage: string;
      private scheduleDrsWizardManager: ScheduleDrsWizardManager;

      constructor(private $scope: any,
                  private drsAutomationLevelService: any,
                  private i18nService: any,
                  private drsMigrationThresholdService: any,
                  private drsConstants: any,
                  private drsAutomationLevelPageService: DrsAutomationLevelPageService) {

         this.scheduleDrsWizardManager = $scope.manager;
         this.pageModel =
               $scope.modalOptions.dialogData.manager.getDrsAutomationLevelPageModel();
         this.drsAutomation = this.pageModel.drsAutomation;
         this.automationLevels = this.drsAutomationLevelService.getLevels();
         this.signPostParams =  this.drsAutomationLevelPageService.getSignPostParams();

         this.automationLevelInformationMessage =
               this.drsAutomationLevelPageService.getAutomationLevelInformationMessage(this.drsAutomation.automationLevel.id);

         this.predictiveDrsSignPostParams = this.drsAutomationLevelPageService.getPredictiveDrsSignPostParams();

         this.vmAutomationSignPostParams = this.drsAutomationLevelPageService.getVmAutomationSignPostParams();

         this.migrationThresholdInformationMessage =
               this.drsAutomationLevelPageService.getMigrationThresholdDescription(this.drsAutomation.migrationThreshold);

         this.mtSliderOptions =
               this.drsAutomationLevelPageService.getSliderOptions(
                     this.i18nService.getString(
                           'ClusterUi',
                           'drs.config.migrationThresholdLabel'
                     )
               );

         $scope.$watch(() => {
            return this.drsAutomation;
         }, (newValue: any) => {
            if (newValue) {
               this.mtSliderOptions.value = this.drsAutomation.migrationThreshold;
               this.migrationThresholdInformationMessage =
                     this.drsAutomationLevelPageService.getMigrationThresholdDescription(this.drsAutomation.migrationThreshold);
               this.drsAutomation.is65VcOrLater = this.pageModel.is65VcOrLater;
            }
         });

         $scope.$watch(() => {
            return this.mtSliderOptions.value;
         }, (newValue: any) => {
            if (newValue && this.drsAutomation) {
               let migrationThreshold: number = parseInt(newValue, 10);
               this.drsAutomation.migrationThreshold = migrationThreshold;
               this.migrationThresholdInformationMessage =
                     this.drsAutomationLevelPageService.getMigrationThresholdDescription(migrationThreshold);
            }
         });

         $scope.$watch("ctrl.drsAutomation.automationLevel.id", (newValue: any) => {
            if (newValue && this.drsAutomation) {
               this.automationLevelInformationMessage =
                     this.drsAutomationLevelPageService.getAutomationLevelInformationMessage(newValue);
            }
         });
      }
      $onInit() {
         if (this.scheduleDrsWizardManager) {
            $("select[data-test-id='drsAutomationLevelDropdown']").focus();
         }
      }
   }

   angular.module("com.vmware.vsphere.client.cluster")
         .controller("DrsAutomationLevelController",
               DrsAutomationLevelController);
}
