/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */

/**
 *  Replaces all <input type="range"/> elements in storage-ui with ones,
 *  that return Numbers as their ng-model value, not strings
 */
(function() {
   angular.module('com.vmware.vsphere.client.commonModule').directive('rangeInput', function() {
      return {
         restrict: 'A',
         require: '?ngModel',
         scope: {
            ngModel: '='
         },
         link: function(scope, element, attrs, ngModel) {

            if ('type' in attrs && attrs.type.toLowerCase() === 'range') {
               ngModel.$parsers.push(Number);
               scope.$watch('ngModel', function(newValue) {
                  if (!newValue) {
                     newValue = 0;
                  }
                  element.val(newValue);
               });
            }
         }
      };
   });
})();
