namespace h5_client {
   export module common_module_ui {
      import IScope = angular.IScope;
      import ContentLibraryConstants = h5_client.common_module_ui.ContentLibraryConstants;
      import LibraryDataModel = h5_client.common_module_ui.LibraryDataModel;

      interface LibraryConfigureControllerScope extends IScope {
         wizardConfig: any;
         vuiWizard: any;
         wizardState: any;
         wizardManager: any;
      }

      class CreateContentLibraryConfigureVapiController {
         public static $inject = ['$scope', 'i18nService',
            'libraryService', 'vuiConstants', 'clarityModalService', 'authorizationService', 'defaultUriSchemeUtil'];

         private readonly CERTIFICATE_ERROR_STATUS: string = "CERTIFICATE_ERROR";
         private readonly CREATE_LOC_LIB_PRIVILEGE: string = "ContentLibrary.CreateLocalLibrary";
         private readonly CREATE_SUB_LIB_PRIVILEGE: string = "ContentLibrary.CreateSubscribedLibrary";
         private readonly PROBE_SUBSCRIPTION_PRIVILEGE: string = "ContentLibrary.ProbeSubscription";
         private readonly PASSWORD_PATTERN: RegExp =
                   /((?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9]).{8,})/;
         public i18n: any;
         private subscriptionUrl: string = "";
         private local: string = ContentLibraryConstants.LIBRARYTYPE_LOCAL;
         private readonly SYNC_IMMEDIATE: string = ContentLibraryConstants.SYNC_STRATEGY_IMMEDIATE;
         private readonly SYNC_ONDEMAND: string = ContentLibraryConstants.SYNC_STRATEGY_ONDEMAND;
         private placeholderSubscription: string = "";

         //activity indicator
         private loading: boolean = false;

         //privilege flags
         private isLocalLibraryPrivilege: boolean = true;
         private isPublishedLibraryPrivilege: boolean = true;
         private isProbeSubscriptionPrivilege: boolean = true;

         /*Publish Library*/
         private publishExternally: boolean = false;
         private isAuthenticationEnabled: boolean = false;
         private password: string = "";
         private isPasswordValid: boolean = false;
         private isStreamingOptimized: boolean = false;

         /*Subscribed library*/
         private subscribed: string = ContentLibraryConstants.LIBRARYTYPE_SUBSCRIBED;
         private sslThumbprint: string = "";
         private isSubscribeAuthEnabled: boolean = false;
         private subscribePassword: string = "";

         //default selections
         private selectedLibraryType: string = this.local;
         private syncStrategy: string = this.SYNC_IMMEDIATE;

         constructor(private $scope: LibraryConfigureControllerScope, private i18nService: any,
                     private libraryService: any, private vuiConstants: any, private clarityModalService: any,
                     private authorizationService: any, private defaultUriSchemeUtil: any) {
            this.i18n = i18nService.getString;
            this.$scope.vuiWizard.currentPage.state = this.vuiConstants.wizard.pageState.INCOMPLETE;
            this.placeholderSubscription = this.i18n("LibraryUi", "createLibraryWizard.configurePage.exampleSubscriptionURL");
            this.init();
         }

         private init = (): void => {
            const rootFolderId: string = this.defaultUriSchemeUtil
                  .getRootFolder(this.$scope.wizardState.vcService.serviceGuid);

            this.authorizationService.checkPrivileges(rootFolderId, [
                  this.CREATE_LOC_LIB_PRIVILEGE,
                  this.CREATE_SUB_LIB_PRIVILEGE,
                  this.PROBE_SUBSCRIPTION_PRIVILEGE])
                  .then((privileges: Array<boolean>) => {

                     if (privileges && privileges.length === 3) {
                        this.isLocalLibraryPrivilege = privileges[0];
                        this.isPublishedLibraryPrivilege = privileges[1];
                        this.isProbeSubscriptionPrivilege = privileges[2];
                     }

                     this.$scope.vuiWizard.currentPage.disableNext = () => {
                        return this.loading ||
                        this.isLocalLibrarySelected() ?
                              !this.isLocalLibraryValid() :
                              !this.isSubscribedLibraryValid();
                     };

                     //Apply pre-set values, if any.
                     this.selectedLibraryType = this.$scope.wizardState.type;
                     if (this.isLocalLibrarySelected()) {
                        this.publishExternally = this.$scope.wizardState.isPublished;
                        this.isStreamingOptimized = this.$scope.wizardState.isStreamingOptimized;
                        this.isAuthenticationEnabled = this.$scope.wizardState.isAuthenticationEnabled;
                        if (this.isAuthenticationEnabled) {
                           this.password = this.$scope.wizardState.password;
                           this.isPasswordValid = true;
                        }
                     } else {
                        this.subscriptionUrl = this.$scope.wizardState.subscriptionUrl;
                        this.isSubscribeAuthEnabled = this.$scope.wizardState.isSubscriptionAuthenticated;
                        this.syncStrategy = this.$scope.wizardState.syncStrategy;
                        this.subscribePassword = this.$scope.wizardState.subscriptionPassword;
                     }

                     this.$scope.vuiWizard.currentPage.onCommit = () => {
                        const self: any = this;

                        //Privilege checks
                        if (this.isLocalLibrarySelected()) {
                           if (!self.isLocalLibraryPrivilege) {
                              this.setBannerErrorMessage(this.i18n('LibraryUi', 'error.noPrivileges.localLibrary'));
                              return false;
                           }
                        } else {
                           if (!self.isPublishedLibraryPrivilege) {
                              this.setBannerErrorMessage(this.i18n('LibraryUi', 'error.noPrivileges.subscribedLibrary'));
                              return false;
                           } else if (!self.isProbeSubscriptionPrivilege) {
                              this.setBannerErrorMessage(this.i18n('LibraryUi', 'error.noPrivileges.probeSubscription'));
                              return false;
                           }
                        }

                        self.$scope.wizardState.type = this.selectedLibraryType;
                        if (this.selectedLibraryType === ContentLibraryConstants.LIBRARYTYPE_LOCAL) {
                           self.$scope.wizardState.isPublished = this.publishExternally;
                           self.$scope.wizardState.isStreamingOptimized = this.isStreamingOptimized;
                           self.$scope.wizardState.isAuthenticationEnabled = this.isAuthenticationEnabled;
                           if (this.isAuthenticationEnabled) {
                              self.$scope.wizardState.password = this.password;
                           }
                           self.$scope.vuiWizard.currentPage.state = self.vuiConstants.wizard.pageState.COMPLETED;
                           return true;
                        } else {
                           this.loading = true;
                           return this.libraryService.validateSubscription(self.subscriptionUrl,
                                 self.sslThumbprint, self.subscribePassword)
                                 .then((result: any): boolean => {
                                    this.loading = false;
                                    let errorMessage: string = '';
                                    let invalidCredentials: boolean = false;
                                    if (result.error) {
                                       errorMessage = result.error.localizedMessage;
                                       errorMessage = this.translateErrorMessage(errorMessage);
                                    }

                                    if (result.result) {
                                       if (self.isSslThumbprintError(result.result)) {
                                          self.sslThumbprint = result.result.sslThumbprint;
                                          self.openSslThumbprintAlert(self.sslThumbprint);
                                          return false;
                                       } else if (result.result.errorMessages.length) {
                                          errorMessage = result.result.errorMessages[0].defaultMessage;
                                          if (result.result.status.name === "INVALID_CREDENTIALS") {
                                             self.isSubscribeAuthEnabled = true;
                                             invalidCredentials = true;
                                          }
                                       } else {
                                          self.$scope.wizardState.subscriptionUrl = self.subscriptionUrl;
                                          self.$scope.wizardState.syncStrategy = self.syncStrategy;
                                          self.$scope.wizardState.isSubscriptionAuthenticated = self.isSubscribeAuthEnabled;
                                          self.$scope.wizardState.subscriptionPassword = self.subscribePassword;
                                          self.$scope.vuiWizard.currentPage.state = self.vuiConstants.wizard.pageState.COMPLETED;
                                          return true;
                                       }
                                    }

                                    if (errorMessage) {
                                       self.$scope.wizardConfig.validationBanner.messages = [{
                                          text: errorMessage,
                                          type: invalidCredentials ?
                                                self.vuiConstants.validationBanner.type.WARNING :
                                                self.vuiConstants.validationBanner.type.ERROR
                                       }];
                                       self.$scope.wizardConfig.validationBanner.showAllMessages = true;
                                       return false;
                                    }
                                    return true;
                                 });
                        }
                     };
                  });
         };


         private isSubscribedLibraryValid(): boolean {
            const passwordReady: boolean = (this.isSubscribeAuthEnabled) ?
                        !!this.subscribePassword : true;
            return !!this.subscriptionUrl && passwordReady;
         }

         private onPublishedChanged(): void {
            if (!this.publishExternally) {
               this.isAuthenticationEnabled = false;
            }
         }

         private isLocalLibrarySelected(): boolean {
            return this.selectedLibraryType === ContentLibraryConstants.LIBRARYTYPE_LOCAL;
         }

         private isLocalLibraryValid(): boolean {
            return this.isAuthenticationEnabled ? this.getPasswordValid() : true;
         }

         private getPasswordValid(): boolean {
            return this.isPasswordValid;
         }

         private isSslThumbprintError(resultObj: any): boolean {
            if (resultObj && resultObj.status && resultObj.status.enumValue) {
               return (resultObj.status.enumValue === this.CERTIFICATE_ERROR_STATUS);
            }
            return false;
         }

         private onPublishPasswordStatusChange(data: any): void {
            this.isPasswordValid = data.isValid;
            this.password = data.password;
         }

         private openSslThumbprintAlert(sslThumbprint: any): void {
            const self = this;
            this.clarityModalService.openConfirmationModal({
               title: this.i18n("LibraryUi", "sslDialog.securityAlertTitle", self.$scope.wizardState.name),
               message: this.i18nService.getString("LibraryUi", "sslDialog.securityAlertText", sslThumbprint),
               preserveNewlines: true,
               saveButtonLabel: this.i18n("LibraryUi", "yes"),
               clarityIcon: {
                  shape: "warning",
                  class: "is-warning",
                  size: 64
               },
               submit: function () {
                  self.$scope.wizardState.sslThumbprint = self.sslThumbprint;
                  self.$scope.wizardManager.onNextClick();
                  return true;
               },
               onCancel: function () {
                  self.sslThumbprint = "";
               }
            });
         }

         private setBannerErrorMessage(errorMsg: string): any {
            if (errorMsg) {
               this.$scope.wizardConfig.validationBanner.messages = [{
                  text: errorMsg,
                  type: this.vuiConstants.validationBanner.type.ERROR
               }];
               this.$scope.wizardConfig.validationBanner.showAllMessages = true;
            }
         }

         private radioChanged() {
            this.$scope.wizardConfig.validationBanner.messages = [];
         }

         private translateErrorMessage(message: string) {
            if (message) {
               if (message.toLowerCase().indexOf("unknown protocol") > -1) {
                  return this.i18nService
                        .getString("LibraryUi", "h5.probe.subscription.url.unknownProtocol");
               } else if (message.toLowerCase().indexOf("no protocol") > -1) {
                  return this.i18nService
                        .getString("LibraryUi", "h5.probe.subscription.url.noProtocol");
               }
            }
            return message;
         }
      }
      angular.module("com.vmware.vsphere.client.commonModule")
            .controller("createContentLibraryConfigureVapiController", CreateContentLibraryConfigureVapiController);
   }
}
