angular.module('com.vmware.vsphere.client.vm')
.factory('vmHardwareVersionService', ['i18nService', function (i18nService) {
   var HW_UPGRADE_STATUS_NONE = 'none';
   var HW_UPGRADE_STATUS_FAILED = 'failed';

   return {
      getVersionNumber: getVersionNumber,
      getLongText: getLongText,
      getScheduledUpgradeData: getScheduledUpgradeData
   };

   function getVersionNumber(vmVersionId) {
      var vmVersionNumber = parseInt(vmVersionId.split("-")[1]);

      if (_.isNaN(vmVersionNumber)) {
         return -1;
      }
      return vmVersionNumber;
   }

   function getLongText(vmVersion) {
      return i18nService.getString('VmUi', 'VmHardware.HWVersion.long.' + vmVersion);
   }

   function getScheduledUpgradeData(vmHardwareData) {
      var statusStr, errorStr;
      var upgradeStatus;
      if (vmHardwareData.upgrade && vmHardwareData.upgrade.scheduledHardwareUpgradeStatus) {
         upgradeStatus = vmHardwareData.upgrade.scheduledHardwareUpgradeStatus;
      }

      if (upgradeStatus !== HW_UPGRADE_STATUS_NONE) {
         statusStr = i18nService.getString('VmUi', 'VmHardwareView.' + upgradeStatus);

         if (upgradeStatus === HW_UPGRADE_STATUS_FAILED) {
            errorStr = buildFaultMessage(vmHardwareData.upgrade.fault);
         }
      }

      return {
         status: statusStr,
         error: errorStr
      };
   }

   function buildFaultMessage(fault) {
      var msg = '';
      var separator = '';

      if (fault && fault.faultMessage && fault.faultMessage.length > 0) {
         fault.faultMessage.forEach(function(lmsg) {

            msg += separator + lmsg.message;

            if (separator === '') {
               separator  = '\n';
            }
         });
      }
      return msg;
   }
}]);
