/* Copyright 2015 VMware, Inc. All rights reserved. -- VMware Confidential */

(function() {
   'use strict';
   angular.module('com.vmware.vsphere.client.cluster')
      .controller('EditResourcePoolController', ['$scope', 'dataService', 'i18nService',
         'mutationService', 'defaultUriSchemeUtil', 'editResourcePoolService',
         'bytesFilter', 'cpuClockFilter', 'resourcePoolManagementService',
         'managedEntityConstants', '$q',
         function(
            $scope, dataService, i18nService, mutationService, defaultUriSchemeUtil,
            editResourcePoolService, bytesFilter, cpuClockFilter,
            resourcePoolManagementService, managedEntityConstants, $q
         ) {

            var self = this;
            var CONVERT_MEMORY_CONSTANT = 1024 * 1024;
            var VAPP_POWERED_OFF = 'stopped';

            var objectId;
            var unlimitedLabel = i18nService.getString('RpUi', 'labelUnlimited');

            var resourcePoolManagementServiceInstance = resourcePoolManagementService
                  .createInstance();

            // CPU reservation defaults.
            self.cpuReservationOptions = resourcePoolManagementServiceInstance
               .getCpuDropdownOptions();
            self.cpuUnitOptions = resourcePoolManagementServiceInstance
               .getCpuUnitOptions();

            // CPU limit defaults
            self.cpuLimitOptions = resourcePoolManagementServiceInstance
               .getCpuLimitOptions();

            // Memory reservation defaults.
            self.memoryReservationOptions = resourcePoolManagementServiceInstance
               .getMemoryDropdownOptions();
            self.memoryUnitOptions = resourcePoolManagementServiceInstance
               .getMemoryUnitOptions();

            // Memory limit defaults.
            self.memoryLimitOptions = resourcePoolManagementServiceInstance
               .getMemoryLimitOptions();
            self.cpuReservationValueErrorMessages = {
               min: cpuReservationRangeErrorMessage,
               max: cpuReservationRangeErrorMessage
            };
            self.cpuLimitValueErrorMessages = {
               min: cpuLimitRangeErrorMessage,
               max: cpuLimitRangeErrorMessage
            };
            self.memoryReservationValueErrorMessages = {
               min: memoryReservationRangeErrorMessage,
               max: memoryReservationRangeErrorMessage
            };
            self.memoryLimitValueErrorMessages = {
               min: memoryLimitRangeErrorMessage,
               max: memoryLimitRangeErrorMessage
            };

            // Format limits.
            _.each(self.cpuReservationOptions, cpuFormatter);
            _.each(self.cpuLimitOptions, cpuFormatter);
            _.each(self.memoryReservationOptions, memoryFormatter);
            _.each(self.memoryLimitOptions, memoryFormatter);

            self.spec = editResourcePoolService.editDefaultResourcePoolSpec();

            objectId = $scope.modalOptions.dialogData.objectId;

            $scope.modalOptions.onSubmit = function() {
               return onCommit();
            };

            getViewData();

            function getViewData() {
               self.loading = true;
               $scope.modalOptions.submitDisabled = true;

               dataService.getData(
                  objectId,
                  'com.vmware.vsphere.client.clusterui.model.ResourcePoolData')
                  .then(function(resourcePoolData) {
                     if (!resourcePoolData) {
                        self.loading = false;
                        $scope.modalOptions.submitDisabled = false;
                        return null;
                     }

                     var scopeParent =  $scope.$parent.$parent;
                     var additionalData = scopeParent.ctrl && scopeParent.ctrl.actionEval &&
                        scopeParent.ctrl.actionEval.additionalData;

                     if (scopeParent && additionalData && additionalData.isScheduledTask &&
                        additionalData.schedulingData.scheduleTaskOperationMode === 1) {
                        resourcePoolData.config =
                           additionalData.schedulingData.spec.argument[1].value;
                     }

                     setCpuConfig(resourcePoolData);
                     setMemoryConfig(resourcePoolData);
                     // format limits
                     _.each(self.cpuReservationOptions, cpuFormatter);
                     _.each(self.cpuLimitOptions, cpuFormatter);
                     _.each(self.memoryReservationOptions, memoryFormatter);
                     _.each(self.memoryLimitOptions, memoryFormatter);
                     self.loading = false;
                     $scope.modalOptions.submitDisabled = false;
                  })
                  .catch(function(error) {
                     self.loading = false;
                     $scope.modalOptions.submitDisabled = false;
                     $q.reject(error);
                  });
            }

            function cpuReservationRangeErrorMessage() {
               return i18nService.getString('RpUi', 'Util.Validation.ValidRange',
                  i18nService.getString('RpUi', 'RpCpu.Label.Reservation'),
                  i18nService.getString('RpUi', 'cpuValue',
                        self.cpuReservationOptions.minimum.value),
                  i18nService.getString('RpUi', 'cpuValue',
                     self.cpuReservationOptions.maximum.value)
               );
            }

            function cpuLimitRangeErrorMessage() {
               return i18nService.getString('RpUi', 'Util.Validation.ValidRange',
                     i18nService.getString('RpUi', 'settings.limit'),
                     i18nService.getString('RpUi', 'cpuValue',
                           self.cpuLimitOptions.minimum.value),
                     i18nService.getString('RpUi', 'cpuValue',
                           self.cpuLimitOptions.maximum.value)
               );
            }

            function memoryReservationRangeErrorMessage() {
               return i18nService.getString('RpUi', 'Util.Validation.ValidRange',
                  i18nService.getString('RpUi', 'RpMemory.Label.Reservation'),
                  i18nService.getString('RpUi', 'memValue',
                        self.memoryReservationOptions.minimum.value),
                  i18nService.getString('RpUi', 'memValue',
                     self.memoryReservationOptions.maximum.value)
               );
            }

            function memoryLimitRangeErrorMessage() {
               return i18nService.getString('RpUi', 'Util.Validation.ValidRange',
                     i18nService.getString('RpUi', 'settings.limit'),
                     i18nService.getString('RpUi', 'memValue',
                           self.memoryLimitOptions.minimum.value),
                     i18nService.getString('RpUi', 'memValue',
                           self.memoryLimitOptions.maximum.value)
               );
            }

            function cpuFormatter(element) {
               element.formattedValue = cpuClockFilter(element.value,
                  i18nService.getString('RpUi', 'rpCpu.MHz'));
            }

            function memoryFormatter(element) {
               element.formattedValue = (element.value === -1) ?
                  unlimitedLabel : bytesFilter(element.value, 'MB');
            }

            function setCpuConfig(data) {

               var parentUnreservedForPool =
                  Math.max(0, data.parentRuntime.cpu.unreservedForPool);

               var reservationUsed =
                  Math.max(data.runtimeInfo.cpu.reservationUsed,
                  data.config.cpuAllocation.reservation);
               var maxCpuReservation = reservationUsed + parentUnreservedForPool;
               // For vApps that are powered off and located in a cluster you can set
               // the reservation beyond what is currently available.
               if(data.owner.type === managedEntityConstants.CLUSTER
                     && data.summary.vAppState
                     && data.summary.vAppState === VAPP_POWERED_OFF){

                  maxCpuReservation = Math.max(data.parentRuntime.cpu.maxUsage,
                        reservationUsed);
               }

               var parentMaxUsage = data.parentRuntime.cpu.maxUsage;
               var cpuMaxLimit = Math.max(parentMaxUsage, reservationUsed);
               // It may occur that the current limit is larger than the maximum allowed.
               // This is because the maximum allowed may decrease over time. In that
               // case we set the max limit to the current limit, thus allowing the user
               // to keep or decrease the limit, but not increase it.
               cpuMaxLimit = Math.max(cpuMaxLimit, data.config.cpuAllocation.limit);

               // cpu current shares
               self.spec.configSpec.cpuAllocation.shares.shares =
                  data.config.cpuAllocation.shares.shares;
               self.spec.configSpec.cpuAllocation.shares.level =
                  data.config.cpuAllocation.shares.level;

               // max cpu allowed reservation
               resourcePoolManagementServiceInstance.setCpuMaxReservation(
                  maxCpuReservation);

               // min cpu allowed reservation for non-expandable pools
               self.cpuReservationUsed = data.runtimeInfo.cpu.reservationUsed;
               // current cpu reservation
               resourcePoolManagementServiceInstance.setCpuCurrentReservation(
                  data.config.cpuAllocation.reservation);
               self.spec.configSpec.cpuAllocation.reservation =
                  data.config.cpuAllocation.reservation;
               self.cpuReservationOptions = resourcePoolManagementServiceInstance
                  .getCpuDropdownOptions();

               // max cpu limit
               resourcePoolManagementServiceInstance.setCpuMaxLimit(cpuMaxLimit);
               // min cpu limit
               resourcePoolManagementServiceInstance.setCpuMinLimit(
                     data.config.cpuAllocation.reservation);
               // current cpu limit
               resourcePoolManagementServiceInstance.setCpuCurrentLimit(
                  data.config.cpuAllocation.limit);
               self.spec.configSpec.cpuAllocation.limit =
                  data.config.cpuAllocation.limit;
               self.cpuLimitOptions = resourcePoolManagementServiceInstance
                  .getCpuLimitOptions();

               // cpu reservation type
               self.spec.configSpec.cpuAllocation.expandableReservation =
                  data.config.cpuAllocation.expandableReservation;
            }

            function setMemoryConfig(data) {

               var parentUnreservedForPool = Math.max(0,
                     data.parentRuntime.memory.unreservedForPool / CONVERT_MEMORY_CONSTANT);
               var reservationUsed = Math.max(
                     data.runtimeInfo.memory.reservationUsed / CONVERT_MEMORY_CONSTANT,
                     data.config.memoryAllocation.reservation);
               var maxMemoryReservation = parentUnreservedForPool + reservationUsed;
               // For vApps that are powered off and located in a cluster you can set
               // the reservation beyond what is currently available.
               if(data.owner.type === managedEntityConstants.CLUSTER &&
                     data.summary.vAppState &&
                     data.summary.vAppState === VAPP_POWERED_OFF){

                  maxMemoryReservation = Math.max(
                        data.parentRuntime.memory.maxUsage / CONVERT_MEMORY_CONSTANT,
                        reservationUsed
                  );
               }

               var parentMaxUsage = data.parentRuntime.memory.maxUsage
                     / CONVERT_MEMORY_CONSTANT;
               var memoryMaxLimit = Math.max(parentMaxUsage, reservationUsed);
               // It may occur that the current limit is larger than the maximum allowed.
               // This is because the maximum allowed may decrease over time. In that
               // case we set the max limit to the current limit, thus allowing the user
               // to keep or decrease the limit, but not increase it.
               memoryMaxLimit = Math.max(memoryMaxLimit,
                  data.config.memoryAllocation.limit);

               // current memory shares
               self.spec.configSpec.memoryAllocation.shares.shares =
                  data.config.memoryAllocation.shares.shares;
               self.spec.configSpec.memoryAllocation.shares.level =
                  data.config.memoryAllocation.shares.level;

               // max memory allowed reservation
               resourcePoolManagementServiceInstance.setMemoryMaxReservation(maxMemoryReservation);

               // min memory allowed reservation for non-expandable pools.
               self.memoryReservationUsed =
                     data.runtimeInfo.memory.reservationUsed / CONVERT_MEMORY_CONSTANT;
               // current memory reservation
               resourcePoolManagementServiceInstance.setMemoryCurrentReservation(
                  data.config.memoryAllocation.reservation);
               self.spec.configSpec.memoryAllocation.reservation =
                  data.config.memoryAllocation.reservation;
               self.memoryReservationOptions = resourcePoolManagementServiceInstance
                     .getMemoryDropdownOptions();

               // max memory limit
               resourcePoolManagementServiceInstance.setMemoryMaxLimit(memoryMaxLimit);
               // min memory limit
               resourcePoolManagementServiceInstance.setMemoryMinLimit(
                     data.config.memoryAllocation.reservation);
               // current memory limit
               resourcePoolManagementServiceInstance.setMemoryCurrentLimit(
                  data.config.memoryAllocation.limit);
               self.spec.configSpec.memoryAllocation.limit =
                  data.config.memoryAllocation.limit;
               self.memoryLimitOptions =
                  resourcePoolManagementServiceInstance.getMemoryLimitOptions();

               // memory reservation type
               self.spec.configSpec.memoryAllocation.expandableReservation =
                  data.config.memoryAllocation.expandableReservation;
            }

            function setConfigSpecData() {
               if(self.spec.configSpec.cpuAllocation.limit !== -1 &&
                     self.spec.configSpec.cpuAllocation.limit <
                     resourcePoolManagementServiceInstance.getCpuLimitOptions().minimum.value){
                  self.spec.configSpec.cpuAllocation.limit = resourcePoolManagementServiceInstance
                        .getCpuLimitOptions().minimum.value;
               }

               if(self.spec.configSpec.memoryAllocation.limit !== -1 &&
                     self.spec.configSpec.memoryAllocation.limit <
                     resourcePoolManagementServiceInstance.getMemoryLimitOptions().minimum.value){
                  self.spec.configSpec.memoryAllocation.limit =
                        resourcePoolManagementServiceInstance.getMemoryLimitOptions().minimum.value;
               }
            }

            function onCommit() {
               if ($scope.editResourcePoolForm.$invalid) {
                  return false;
               }
               setConfigSpecData();
               mutationService.apply(objectId,
                  'com.vmware.vsphere.client.rp.ResourcePoolSpec',
                  self.spec
               );
               return true;
            }

            function onResourcePoolScheduleTaskCommit() {
               setConfigSpecData();
               var emitDataObject = {
                     objectId: objectId,
                     _type: 'com.vmware.vsphere.client.rp.ResourcePoolSpec',
                     configSpec: self.spec,
                     invalidResourcePool: $scope.editResourcePoolForm.$invalid
               };
               $scope.$emit('stDataEvent', emitDataObject);
            }

            $scope.$watch(angular.bind(this, function () {
               if ($scope.modalOptions.actionEval
                     && $scope.modalOptions.actionEval.additionalData
                     && $scope.modalOptions.actionEval.additionalData.isScheduledTask) {
                  onResourcePoolScheduleTaskCommit();
               }
            }));
         }
      ]);
})();
