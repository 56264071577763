namespace h5_vm {
   import VirtualPCI = h5_vm.VmHwPciDevice;

   export class VmHwPciSharedController implements ng.IComponentController {

      public inflatedDevice: VirtualPCI;
      public removeCallback: Function;
      public deviceLabel: string;
      public removalDisabled: boolean = true;
      private pciList: Array<any> = [];

      static $inject: string[] = ["i18nService"];

      constructor(public i18nService: any) {
      }

      $onInit() {
         if (this.inflatedDevice) {
            this.deviceLabel = this.inflatedDevice.deviceLabel();
         } else {
            this.deviceLabel = "";
         }

         this.removalDisabled = true;
         // PCI list from Config target not changing.
         // Then we could cache it in onInit.
         // But consider calculating list of available devices,
         // by removing thos already in use (not done in Flex)
         this.pciList = this.inflatedDevice.deviceList();
      }

      public i18n(key: string): string {
         if (!this.i18nService) {
            return "";
         }

         const retVal: string = this.i18nService.getString("VmUi", "PciDevicePage." + key);
         return retVal;
      }

      removeDevice() {

         if (!this.inflatedDevice) {
            return;
         }

         if (!this.inflatedDevice.canRemove()) {
            return;
         }

         this.inflatedDevice.removeDevice();
      }

      revertRemoval() {
         if (this.inflatedDevice) {
            this.inflatedDevice.revertRemoval();
         }
      }

      public isMarkedForRemoval():boolean {
         if (!this.inflatedDevice) {
            return false;
         }

         return this.inflatedDevice.isMarkedForRemoval();
      }


      private isRemoveDisabled(): boolean | null {
         if (!this.inflatedDevice) {
            return true;
         }
         const retVal: boolean = (this.inflatedDevice.canRemove() === false);
         return retVal;
      }

      public get pluginValue(): string {
         const retVal: string = "NVIDIA GRID vGPU";
         return retVal;
      }

      public get pluginList(): Array<string> {
         const retVal: Array<string> = ["NVIDIA GRID vGPU"];
         return retVal;
      }

      public get profileList(): Array<string> {
         const retVal: Array<string> = this.inflatedDevice.sharedGpuList;
         return retVal;
      }

      public get profile(): string {

         if (!this.inflatedDevice) {
            return "";
         }

         const retVal = this.inflatedDevice.sharedGpuValue;
         return retVal;

      }

      public set profile(value: string) {
         if (!this.inflatedDevice) {
            return;
         }

         this.inflatedDevice.sharedGpuValue = value;
      }

      public isProfileValueDisabled():boolean {
         if (!this.inflatedDevice) {
            return true;
         }

         const retVal:boolean = this.inflatedDevice.sharedGpuValue_isReadonly();
         return retVal;

      }

      public isProfileInputVisible(): boolean {
         if (!this.inflatedDevice) {
            return false;
         }
         const retVal: boolean = this.inflatedDevice.sharedGpuListIsEmpty;
         return retVal;
      }

      public isProfileSelectVisible(): boolean {
         if (!this.inflatedDevice) {
            return false;
         }
         const retVal: boolean = !this.inflatedDevice.sharedGpuListIsEmpty;
         return retVal;
      }

      public showMemorySection(): boolean {
         if (!this.inflatedDevice) {
            return false;
         }
         const retVal: boolean = this.inflatedDevice.memoryUnderReserved();
         return retVal;
      }

      public showDeviceWarning(): boolean {
         return this.showMemorySection();
      }

      public reserveAllMemory(ev: any): void {
         if (!this.inflatedDevice) {
            return;
         }
         this.inflatedDevice.reserveAllMemory();
      }
   }

   class VmHwPciSharedComponent implements ng.IComponentOptions {

      constructor(public bindings: any = { inflatedDevice: '<'},
                  public controller: any = VmHwPciSharedController,
                  public templateUrl: string = 'vm-ui/resources/vm/views/settings/vmHardwareSettings/vmHardwarePciDevice/vm-hw-pci-shared.html') {
      }
   }
   angular.module('com.vmware.vsphere.client.vm').component('vmHwPciShared', new VmHwPciSharedComponent());
}
