/* Copyright 2015 VMware, Inc. All rights reserved. -- VMware Confidential */

/*
 * Controller for the edit notes action.
 */
(function() {
   'use strict';
   angular.module('com.vmware.vsphere.client.commonModule')
          .controller('NotesDialogController', NotesDialogController);

   NotesDialogController.$inject = ['$scope', 'dataService'];

   function NotesDialogController($scope, dataService) {
      var self = this;
      self.loading = true;

      var objectId = $scope.modalOptions.availableTargets[0];
      var notesPropName = $scope.modalOptions.dialogData.notesPropName;
      var additionalPropForEdit = $scope.modalOptions.dialogData.additionalPropForEdit;
      var onUpdateNotesCallback =
         $scope.modalOptions.dialogData.onUpdateNotesCallback;
      var additionalPropForEditValue;

      var propsToRequest = [notesPropName, 'name'];
      if (additionalPropForEdit) {
         propsToRequest.push(additionalPropForEdit);
      }
      dataService.getProperties(objectId, propsToRequest).then(
         function(data) {
            self.notes = data[notesPropName];
            self.name = data.name;
            self.loading = false;
            if (additionalPropForEdit) {
               additionalPropForEditValue = data[additionalPropForEdit];
            }
         }
      );


      var operationName = $scope.modalOptions.title;

      self.closeModal = function() {
         $scope.closeModal();
      };

      self.save = function() {
         self.closeModal();
         var notesValue = self.notes;

         return onUpdateNotesCallback(objectId, notesValue, operationName, additionalPropForEditValue);
      };
   }
})();
