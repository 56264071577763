(function () {
   'use strict';
   angular.module('com.vmware.vsphere.client.host')
      .controller('editPciDevicesController', EditPciDevicesController);

   EditPciDevicesController.$inject = ['$scope',
      'dataService',
      'i18nService',
      'pciDeviceService',
      'vuiUtils',
      '$element'];

   function EditPciDevicesController($scope,
                                     dataService,
                                     i18nService,
                                     pciDeviceService,
                                     vuiUtils,
                                     $element) {
      var objectId = $scope._route.objectId;
      var hostStr = _.bind(i18nService.getString, i18nService, 'HostUi');
      var self = this;
      self.getData = getData;

      getData();

      $scope.modalOptions.onSubmit = onSubmit;
      self.treeListWidget = null;
      self.showPciDevicePortlets = true;
      self.showHideLabel = hostStr('vmdpConfig.hidedetails.label');

      self.showPortlets = function() {
         if (self.showHideLabel === hostStr('vmdpConfig.hidedetails.label')) {
            self.showPciDevicePortlets = false;
            self.showHideLabel = hostStr('vmdpConfig.showdetails.label');
         } else {
            self.showPciDevicePortlets = true;
            self.showHideLabel = hostStr('vmdpConfig.hidedetails.label');
         }
      };

      function onSubmit() {
         var pciPassthruUpdateSpec = pciDeviceService
            .getChangedPciDevicesUpdateSpec(self.treeListOptions.dataSource.data());

         var pciDevicesWithEsxiDevice =
            pciDeviceService.getPciDevicesWithEsxiDevice(self.treeListOptions.dataSource.data());

         if (pciDevicesWithEsxiDevice.length > 0) {
            pciDeviceService.showConfirmationDialog(
               pciDevicesWithEsxiDevice,
               pciPassthruUpdateSpec,
               $scope.closeModal,
               objectId);
         } else {
            return pciDeviceService.applyEditPciDevices(pciPassthruUpdateSpec, objectId);
         }
      }


      function getData() {
         dataService.getData(objectId,
            'com.vmware.vsphere.client.h5.host.vmdp.VmdpDeviceNodeData').then(
            function (data) {
               if (data) {
                  self.modifiedData = _.map(data.vmdpDeviceNodeList, function(dataItem) {
                     return angular.merge({
                        _originalStatus: dataItem.status
                     }, dataItem);
                  });

                  self.initializeTreeListDataSource(self.modifiedData);
               }
            });
      }

      self.initializeTreeListDataSource = function(data) {
         self.treeListOptions.dataSource = new kendo.data.TreeListDataSource({
            data: data,
            schema: treeListDataSourceSchema
         });
         self.treeListOptions.dataSource.read();
      };

      var treeListDataSourceSchema = {
         model: {
            id: "nodeId",
            expanded: true
         }
      };

      self.treeListColumns = [
         {
            field: "busId",
            title: hostStr('vmdpConfig.idColHeader.label'),
            template: function(dataItem) {
               return pciDeviceService.createPciDeviceCheckbox(dataItem);
            }
         },
         {
            field: "statusMessage",
            title: hostStr('vmdpConfig.statusHeader.label')
         },
         {
            field: "vendor",
            title: hostStr('vmdpConfig.vendorName.label')
         },
         {
            field: "deviceName",
            title: hostStr('vmdpConfig.deviceNameHeader.label'),
            template: pciDeviceService.textTitleRenderer
         },
         {
            field: "esxDeviceInfo",
            title: hostStr('vmdpConfig.esxName.label'),
            template: function(dataItem) {
               return dataItem.esxDeviceInfo ? dataItem.esxDeviceInfo.split(" ")[0] : '';
            }
         }
      ];

      $element.on("selectPciDevice", function(event, dataItemUid) {
         event.stopPropagation();

         var data = self.treeListOptions.dataSource.data();

         var dataItem = _.find(data, function(item) {
            return item.uid === dataItemUid;
         });

         if (dataItem.dependentDevice) {
            // here it will find itself also
            _.each(data, function(item) {
               if (dataItem.dependentDevice === item.dependentDevice) {
                  pciDeviceService.updateStatus(item);
               }
            });
         } else {
            pciDeviceService.updateStatus(dataItem);
         }

         self.treeListWidget.refresh();
      });

      self.onTreeListSelect = function() {
         var row = self.treeListWidget.select();
         var data = self.treeListWidget.dataItem(row);
         if (data) {
            self.pciDetailsData =
               pciDeviceService.createPciDetails(data);
            self.pciDeviceBusDetatils =
               pciDeviceService.createPciBusDetails(data);
            self.esxDeviceInfo =
               pciDeviceService.createEsxDeviceInfoDetails(data);
            self.directPathMessage = data.directPathMessage;
            self.selectedPciId = data.busId;
            self.hasSelectedPciDevice = true;
         } else {
            self.hasSelectedPciDevice = false;
         }
      };

      self.treeListOptions = {
         columns: self.treeListColumns,
         sortable: false,
         resizable: true,
         selectable: true,
         change: _.partial(vuiUtils.safeApply, $scope, self.onTreeListSelect)
      };
   }
})();
