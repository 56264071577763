namespace h5_vm {
   import VmProvisioningWizardPage = h5_vm.VmProvisioningWizardPage;

   export class CloneVmLocationPageService implements VmProvisioningPageService {

      static $inject: string[] = [
         "i18nService",
         "vuiConstants",
         "vmVuiWizardPageBuilder",
         "cloneVmLocationPageModel"
      ];

      constructor(private i18nService: any,
                  private vuiConstants: any,
                  private vmVuiWizardPageBuilder: any,
                  private cloneVmLocationPageModel: any) { }

      public build(wizardScope: any,
                   defaultTarget?: any,
                   preselectedComputeResource?: any,
                   preselectedStorage?: any): VmProvisioningWizardPage {

         // todo: why the entire wizardScope and some particular properties are passed to
         // the model? In general model shouldn't need the entire wizard scope, but even
         // so, why we need to pass some wizard scope properties as well?
         let pageModel = new this.cloneVmLocationPageModel(
               wizardScope.wizardViewData,
               wizardScope);

         wizardScope.cloneVmLocationPageModel = pageModel;

         return this.vmVuiWizardPageBuilder.buildVuiWizardPage(wizardScope.config, {
            title: this.i18nService.getString("ProvisioningUiLib", "LocationPage.title"),
            description: this.i18nService.getString("ProvisioningUiLib", "LocationPage.description"),
            contentUrl: "vm-ui/resources/vm/views/clone/clone-vm-location.html",
            model: pageModel
         });
      };
   }

   angular.module("com.vmware.vsphere.client.vm")
         .service("cloneVmLocationPageService", CloneVmLocationPageService);
}
