/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace dvs_ui {

   import TrafficRule = com.vmware.vim.binding.vim.dvs.TrafficRule;
   import DvpgTrafficRuleModel = com.vmware.vsphere.client.h5.network.dvportgroup.trafficfilter.model.DvpgTrafficRuleModel;
   import DvpgEditRuleSequenceSpec = com.vmware.vsphere.client.h5.network.dvportgroup.trafficfilter.DvpgEditRuleSequenceSpec;
   import DvsPortEditTrafficFilterSpec = com.vmware.vsphere.client.h5.network.dvs.ports.model.DvsPortEditTrafficFilterSpec;
   import DvsPortAddTrafficRuleSpec = com.vmware.vsphere.client.h5.network.dvs.ports.model.DvsPortAddTrafficRuleSpec;
   import DvsPortEditTrafficRuleSpec = com.vmware.vsphere.client.h5.network.dvs.ports.model.DvsPortEditTrafficRuleSpec;
   import DvpgTrafficFilterRule = com.vmware.vsphere.client.h5.network.dvportgroup.trafficfilter.DvpgTrafficFilterRule;
   import DvsPortRemoveTrafficRuleSpec = com.vmware.vsphere.client.h5.network.dvs.ports.model.DvsPortRemoveTrafficRuleSpec;


   export class DvsPortTrafficFilterSpecFactory {

      public static $inject = [];

      /**
       * Builds the spec for enable and/or reorder the traffic filter rules of
       * distributed virtual port.
       *
       * @param portId The key of port which is updated
       * @param model DvpgEditTrafficFilterModel
       * @param isRuleOrderChanged True if the rule order has been changed.
       */
      public buildEditTrafficFilterSpec(
            portId: string,
            model: DvpgEditTrafficFilterModel,
            isRuleOrderChanged: boolean): DvsPortEditTrafficFilterSpec {

         let configSpec: DvsPortEditTrafficFilterSpec =
               new DvsPortEditTrafficFilterSpec();
         configSpec.isTrafficRulesetEnabled = model.isTrafficRulesetEnabled;
         configSpec.isTrafficRulesetOverwritten = model.isTrafficRulesetOverwritten;
         configSpec.portKey = portId;

         if (isRuleOrderChanged) {
            let trafficRuleSpecs: DvpgEditRuleSequenceSpec[] = [];
            model.rules.forEach((rule: DvpgTrafficFilterRule): void => {
               let trafficRuleSpec: DvpgEditRuleSequenceSpec =
                     new DvpgEditRuleSequenceSpec();
               trafficRuleSpec.ruleKey = rule.key;
               trafficRuleSpec.sequence = rule.sequence;
               trafficRuleSpecs.push(trafficRuleSpec);
            });

            configSpec.rules = trafficRuleSpecs;
         }
         return configSpec;
      }

      /**
       * Builds the spec for adding a traffic rule to the current rules
       * configuration of the distributed virtual port.
       *
       * @param portId The key of port which is updated
       * @param model DvpgTrafficRuleModel
       */
      public buildAddTrafficRuleSpec(
            portId: string,
            model: DvpgTrafficRuleModel): DvsPortAddTrafficRuleSpec {
         let spec: DvsPortAddTrafficRuleSpec = new DvsPortAddTrafficRuleSpec();
         spec.portKey = portId;
         spec.newRule = model;
         return spec;
      }

      /**
       * Builds the spec for editing a traffic rule from the current rules
       * configuration of the distributed virtual port.
       *
       * @param portId The key of port which is updated
       * @param editedRuleModel DvpgTrafficRuleModel
       */
      public buildEditTrafficRuleSpec(portId: string,
            editedRuleModel: DvpgTrafficRuleModel): DvsPortEditTrafficRuleSpec {
         let spec: DvsPortEditTrafficRuleSpec = new DvsPortEditTrafficRuleSpec();
         spec.portKey = portId;
         spec.editedRule = editedRuleModel;
         return spec;
      }

      /**
       * Builds the spec for removing a traffic rule from the current rules
       * configuration of the distributed port group.
       *
       * @param portId The key of port which is updated
       * @param ruleKey The key of the removed traffic rule
       */
      public buildRemoveTrafficRuleSpec(portId: string,
            ruleKey: string): DvsPortRemoveTrafficRuleSpec {
         let spec: DvsPortRemoveTrafficRuleSpec = new DvsPortRemoveTrafficRuleSpec();
         spec.portKey = portId;
         spec.ruleKey = ruleKey;
         return spec;
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .service("dvsPortTrafficFilterSpecFactory", DvsPortTrafficFilterSpecFactory);
}
