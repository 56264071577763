namespace h5_vm {

   import VmCloneSpec = com.vmware.vsphere.client.vm.VmCloneSpec;
   import CloneSpec = com.vmware.vim.binding.vim.vm.CloneSpec;
   import RelocateSpec = com.vmware.vim.binding.vim.vm.RelocateSpec;
   import ManagedObjectReference = com.vmware.vim.binding.vmodl.ManagedObjectReference;
   import RelocateSpec$DiskLocator = com.vmware.vim.binding.vim.vm.RelocateSpec$DiskLocator;
   import CalculateProvisioningCostSpec = com.vmware.vsphere.client.spbm.spec.CalculateProvisioningCostSpec;
   import VirtualDisk = com.vmware.vim.binding.vim.vm.device.VirtualDisk;
   import ProfileId = com.vmware.vim.binding.pbm.profile.ProfileId;
   import VsanCreateCostData = com.vmware.vsphere.client.vsancommon.data.VsanCreateCostData;

   interface ChecklValidationResult {
      compatibilityData: {
         errors: Array<string>,
         warnings: Array<string>,
         infos: Array<string>
      };
      hasSuccess: boolean;
   }

   export class VmProvisioningStorageSelectionValidationService {

      static $inject = [
         "authorizationService",
         "compatibilityCheckService",
         "i18nService",
         "compatibilityResultsPresenter",
         "$q",
         "defaultUriSchemeUtil",
         "mutationService",
         "managedEntityConstants",
         "dataService",
         "bytesFilter",
         "diskProvisioningService",
         "storageProfileService"
      ];

      private readonly DATASTORE_ALLOCATESPACE = "Datastore.AllocateSpace";

      constructor(private authorizationService: any,
                  private compatibilityCheckService: any,
                  private i18nService: any,
                  private compatibilityResultsPresenter: any,
                  private $q: any,
                  private defaultUriSchemeUtil: any,
                  private mutationService: any,
                  private managedEntityConstants: any,
                  private dataService: any,
                  private bytesFilter: any,
                  private diskProvisioningService: any,
                  private storageProfileService: any) {
      }

      public validateSelection(validationModel: any, vmCloneSpec: VmCloneSpec,
                               vmId: any, folderId: any, vmConf: any, vmDisks: any[],
                               isVmtxWorkflow: boolean): IPromise<any> {
         return this.$q.all({
            privilegesCheck: this.checkPrivileges(validationModel),
            compatibilityResult: this.checkCompatibility(vmId, vmCloneSpec, isVmtxWorkflow),
            placementCheck: this.checkVmPlacement(validationModel),
            provisioningCostCheck: this.requestProvisioningCost(folderId, vmConf, true, vmDisks)
         }).then((promisesResult: any) => {
            let compatibilityHasSuccess: boolean = this.getSuccessForCompatibility(
                  promisesResult.compatibilityResult);
            this.addToValidationResult(promisesResult.privilegesCheck,
                  promisesResult.compatibilityResult);
            this.addToValidationResult(promisesResult.placementCheck,
                  promisesResult.compatibilityResult);
            this.addToValidationResult(promisesResult.provisioningCostCheck,
                  promisesResult.compatibilityResult);

            return this.compatibilityResultsPresenter.formatValidationResults(
                  [promisesResult.compatibilityResult]).then((compatibilityMessages: any) => {
               if (compatibilityMessages.length > 0) {
                  // This is workaround for replacing the name of the vm,
                  // because the VC returns the source vm instead of the new one
                  if (vmCloneSpec.name) {
                     compatibilityMessages[0].message = vmCloneSpec.name;
                     compatibilityMessages[0].advancedCompatibilityContents.vmProps.name
                           = vmCloneSpec.name;
                  }

                  return {
                     compatibilityMessages: compatibilityMessages,
                     hasSuccess: compatibilityHasSuccess &&
                           promisesResult.privilegesCheck.hasSuccess &&
                           promisesResult.placementCheck.hasSuccess &&
                           promisesResult.provisioningCostCheck.hasSuccess
                  };
               }


               return {
                  compatibilityMessages: [{
                     icon: "vsphere-icon-status-ok",
                     message: this.i18nService.getString(
                           "VmUi", "ProvisioningWizard.CompatibilityChecksSucceeded"),
                     contents: [],
                     advancedCompatibilityContents: {}
                  }],
                  hasSuccess: true
               };
            });
         });
      }

      private getSuccessForCompatibility(compatibilityResult: any): boolean {
         if (compatibilityResult.error) {
            return false;
         }

         return _.any(compatibilityResult.result, (result: any) => {
            return !result.error;
         });
      }

      private addToValidationResult(checkResult: {compatibilityData: any, hasSuccess: boolean },
                                    validationResult: any): void {
         let currentResult = {
            error: _.map(checkResult.compatibilityData.errors, (error: any) => {
               return {
                  message: error,
                  localizedMessage: error
               };
            }),
            warning: _.map(checkResult.compatibilityData.warnings, (warning: any) => {
               return {
                  message: warning,
                  localizedMessage: warning
               };
            }),
            info: _.map(checkResult.compatibilityData.infos, (info: any) => {
               return {
                  message: info,
                  localizedMessage: info
               };
            }),
            host: this.compatibilityResultsPresenter.NO_HOST_REF,
            vm: validationResult.entity
         };
         if (!currentResult.error.length && !currentResult.warning.length
               && !currentResult.info.length) {
            return;
         }
         if (!validationResult.result) {
            validationResult.result = [];
         }
         validationResult.result.push(currentResult);
      }

      private checkPrivileges(validationModel: any): IPromise<ChecklValidationResult> {
         let storageIds: Array<string> = [];
         let storageIdsSet: any = {};

         _.each(validationModel.virtualDisksByVm, (vmDisks: Array<any>) => {
            _.each(vmDisks, (vmDisk: { storage: string }) => {
               let storageObj: ManagedObjectReference = this.defaultUriSchemeUtil.getManagedObjectReference(vmDisk.storage);
               if (vmDisk.storage && !storageIdsSet[vmDisk.storage] &&
                     storageObj.type === this.managedEntityConstants.DATASTORE) {
                  storageIdsSet[vmDisk.storage] = true;
               }
            });
         });
         storageIds = _.keys(storageIdsSet);

         return this.authorizationService.checkPrivilegesForMultipleObjects(
            storageIds, ["Datastore.AllocateSpace"]
         ).then((privilegesMap: { [storageId: string]: { [privilege: string]: boolean } }) => {
            let isAuthorized: boolean = true;
            _.each(storageIds, (storageId: string) => {
               if (!privilegesMap[storageId][this.DATASTORE_ALLOCATESPACE]) {
                  isAuthorized = false;
               }
            });

            let response: ChecklValidationResult = {
               compatibilityData: {
                  errors: [],
                  warnings: [],
                  infos: []
               },
               hasSuccess: true
            };

            if (!isAuthorized) {
               response.hasSuccess = false;
               response.compatibilityData.errors = [this.i18nService.getString(
                     "VmUi", "SelectDatastoreProvisioningPage.NoPermissionOnDatastore")];
            }

            return response;
         });
      }

      private checkCompatibility(vmId: string, vmCloneSpec: VmCloneSpec, isVmtxWorkflow: boolean): IPromise<any> {

         if (!vmCloneSpec.testsToRun || !vmCloneSpec.testsToRun.length || isVmtxWorkflow) {
            return this.$q.when({
               "entity": this.defaultUriSchemeUtil.getManagedObjectReference(vmId),
               "error": null,
               "propertyName": null,
               "parameter": null,
               "result": [{
                  "vm": null,
                  "host": null,
                  "disk": null,
                  "warning": null,
                  "error": null
               }]
            });
         }

         return this.mutationService.validate(vmId, vmCloneSpec._type , vmCloneSpec);
      }

      public buildVmCloneSpec(folder: ManagedObjectReference, name: string, vm: ManagedObjectReference,
                              pool: ManagedObjectReference, host: ManagedObjectReference,
                              vmStorageConf: any): VmCloneSpec {
         let tests: string[] = [];

         let vmCloneSpec: any = new VmCloneSpec();

         vmCloneSpec.folder = folder;
         vmCloneSpec.vm = vm;
         vmCloneSpec.name = name;
         let cloneSpec: any = new CloneSpec();
         cloneSpec.template = false;
         cloneSpec.powerOn = false;
         let location: any = new RelocateSpec();
         location.pool = pool;
         if (host.type === this.managedEntityConstants.HOST) {
            location.host = host;
         }
         if (vmStorageConf.vmHome.storageObj &&
               vmStorageConf.vmHome.storageObj.storageRef.type !==
               this.managedEntityConstants.STORAGE_POD &&
               vm && this.defaultUriSchemeUtil.getVsphereObjectId(vm)) {
            location.datastore = vmStorageConf.vmHome.storageObj.storageRef;
            location.disk = this.buildDiskLocators(vmStorageConf);
            tests.push("datastoreTests");
         }
         vmCloneSpec.testsToRun = tests;
         cloneSpec.location = location;
         vmCloneSpec.cloneSpec = cloneSpec;

         return vmCloneSpec;
      }

      private buildDiskLocators(vmStorageConf: any): RelocateSpec$DiskLocator[] {
         let disks: any[] = vmStorageConf.vmDisks;
         let filteredDisks: any[] = _.filter(disks, (disk: any) => {
            return !this.storageProfileService.isPmemStorageProfile(disk.storageProfile);
         });
         let result: any[] = _.map(filteredDisks, (disk: any) => {
            let diskLocator: any = new RelocateSpec$DiskLocator();
            diskLocator.diskId = disk.key;
            if (disk.storageObj && disk.storageObj.storageRef.type !==
                  this.managedEntityConstants.STORAGE_POD) {
               diskLocator.datastore = disk.storageObj.storageRef;
               diskLocator.diskBackingInfo = (disk.diskFormat.type === 'sameAsSource' ?
                  null :
                  this.diskProvisioningService.getBackingInfo(disk.diskFormat.type));
            }
            diskLocator.profile = this.getProfileForDisk(disk);
            return diskLocator;
         });
         return _.filter(result, (disk: any) => {
           return disk.datastore;
         });
      }

      private getProfileForDisk(disk: any): Array<any> {
         const diskProfileId = disk.storageProfile ? disk.storageProfile.id : undefined;
         const storageProfile = this.storageProfileService.makeProfile(diskProfileId);
         return [storageProfile];
      }

      private checkVmPlacement(validationModel: any): IPromise<ChecklValidationResult> {
         let assignmentsWithProfiles: Array<{ storageId: string, profile: any }> = [];

         // Inspect them for profile info.
         _.each(validationModel.virtualDisksByVm, (vmDisks: Array<any>) => {
            _.each(vmDisks, (disk: any) => {
               if (disk.profile) {
                  assignmentsWithProfiles.push({
                     storageId: disk.storage,
                     profile: disk.profile
                  });
               }
            });
         });

         if (assignmentsWithProfiles.length === 0 || false) {
            return this.$q.resolve({
               compatibilityData: {
                  errors: [],
                  warnings: [],
                  infos: []
               },
               hasSuccess: true
            });
         }

         return this.compatibilityCheckService.validateVmPlacementForMultipleVms(assignmentsWithProfiles)
            .then((result: any) => {
               // In case the server returns a generic message (e.g. VSAN policy message on VC 6.0),
               // we return only that message. In case it returns a more specific message,
               // we return the specific message

               let spbmPolicyWarning: string =
                  this.i18nService.getString("VmUi", "SelectDatastoreProvisioningPage.SpbmPolicyWarning");
               let spbmPolicyWarningAdded: boolean = false;
               let warningMessages: string[] = [];
               _.each(result.warnings, (warning: any) => {
                  if (!_.isEmpty(warning)) {
                     let warningMessage: string = warning.localizedMessage;
                     if (warningMessage === null &&
                        (warning.faultMessage !== null) &&
                        (warning.faultMessage.length > 0)) {
                        warningMessage = warning.faultMessage[0].message;
                     }
                     if (warningMessage) {
                        warningMessages.push(warningMessage);
                        return;
                     }
                  }
                  if (!spbmPolicyWarningAdded) {
                     spbmPolicyWarningAdded = true;
                     warningMessages.push(spbmPolicyWarning);
                  }
               });

               return {
                  compatibilityData: {
                     errors: result.errors,
                     warnings: warningMessages,
                     infos: []
                  },
                  hasSuccess: !result.errors.length
               };
            });
      }

      public validateDisksSdrsMap(vmStorageConf: any) {
         let collisionsChecker: any = {};

         let disks: any[] = vmStorageConf.vmDisks;
         let existCollision = _.any(disks, (disk: any) => {
            if (this.isDiskPlacedOnSdrsCluster(disk)) {
               let isSdrsEnabled = this.isSdrsEnabledValue(disk.storageObj);
               let objId = this.defaultUriSchemeUtil.getVsphereObjectId(disk.storageObj.parentStoragePod || disk.storageObj.storageRef);
               if (objId in collisionsChecker &&
                  collisionsChecker[objId] !== isSdrsEnabled) {
                  return true;
               }
               collisionsChecker[objId] = isSdrsEnabled;
            }
            return false;
         });
         if (existCollision) {
            return false;
         }

         let isSdrsEnabled = this.isSdrsEnabledValue(vmStorageConf.vmHome.storageObj);
         let objId = this.defaultUriSchemeUtil.getVsphereObjectId(vmStorageConf.vmHome.storageObj.parentStoragePod || vmStorageConf.vmHome.storageObj.storageRef);
         if (objId in collisionsChecker && collisionsChecker[objId] !== isSdrsEnabled) {
            return false;
         }
         return true;
      }

      private isDiskPlacedOnSdrsCluster(disk: any): boolean {
         return disk.storageObj
               && (disk.storageObj.storageRef.type === this.managedEntityConstants.STORAGE_POD
                     || disk.storageObj.parentStoragePod);
      }

      private isSdrsEnabledValue(storageObj: any) {
         if (storageObj.storageRef.type === this.managedEntityConstants.STORAGE_POD) {
            return true;
         } else {
            return !storageObj.parentStoragePod || !storageObj.parentStoragePod.isSdrsEnabled;
         }
      }

      public requestProvisioningCost(vcId: any, vmStorageConf: any, isAdvancedMode: boolean,
            vmDisks: any[]) {
         let specs: CalculateProvisioningCostSpec[] = [];
         let containsVsan: boolean = false;

         if(!vmStorageConf) {
            return this.$q.when(this.formatDiskCostSpec([], []));
         }

         if (isAdvancedMode) {
            let disks: any[] = vmStorageConf.vmDisks;
            _.each(disks, (disk: any) => {
               let diskObj: any = this.getDiskObject(vmDisks, disk.key);
               if (disk.storageObj) {
                  containsVsan = containsVsan || disk.storageObj.type === "vsan";
                  let spec: any = this.createCalculateProvisioningCostSpec(disk.storageProfile && disk.storageProfile.id,
                        disk.storageObj, diskObj && diskObj.capacityInBytes ? diskObj.capacityInBytes : 0);
                  specs.push(spec);
               }
            });

            // Add a spec for the VM home. The size here is irrelevant.
            let spec: any = this.createCalculateProvisioningCostSpec(vmStorageConf.vmHome.storageProfile && vmStorageConf.vmHome.storageProfile.id,
               vmStorageConf.vmHome.storageObj, 1);
            specs.push(spec);
         } else {
            _.each(vmDisks, (disk: any) => {
               if (vmStorageConf.vmHome.storageObj) {
                  containsVsan = containsVsan || vmStorageConf.vmHome.storageObj.type === "vsan";
                  let spec: any = this.createCalculateProvisioningCostSpec(vmStorageConf.vmHome.storageProfile && vmStorageConf.vmHome.storageProfile.id,
                     vmStorageConf.vmHome.storageObj, disk.capacityInBytes);
                  specs.push(spec);
               }
            });
         }

         if (!containsVsan) {
            return this.$q.when(this.formatDiskCostSpec([], []));
         }

         return this.dataService.getProperties(vcId, ["spbmCreateCostData"],
            {
               propertyParams: [{
                  propertyName: "spbmCreateCostData",
                  parameterType: "[Lcom.vmware.vsphere.client.spbm.spec.CalculateProvisioningCostSpec;",
                  parameter:
                     specs
               }]
            }).then((response: any) => {
               return this.formatDiskCostSpec(response.spbmCreateCostData, vmDisks);
            });
      }

      private getDiskObject(vmDisks: VirtualDisk[], diskKey: number): VirtualDisk {
         return _.find(vmDisks, (disk: VirtualDisk) => {
            return disk.key === diskKey;
         });
      }

      private createCalculateProvisioningCostSpec(profileId: string, storageObj: any, capacity: number): CalculateProvisioningCostSpec {
         let spec: CalculateProvisioningCostSpec = new CalculateProvisioningCostSpec();
         spec.sizeInBytes = capacity;
         if (profileId && profileId !== "defaultId") {
            spec.profileId = new ProfileId();
            spec.profileId.uniqueId = profileId;
         }
         spec.storageContainer = storageObj.storageRef;
         return spec;
      }

      private formatDiskCostSpec(response: VsanCreateCostData[], vmDisks: any[]): ChecklValidationResult {
         if (!response || !response.length) {
            return {
               compatibilityData: {
                  errors: [],
                  warnings: [],
                  infos: []
               },
               hasSuccess: true
            };
         }

         // var hasErrors:Boolean = false;
         let totalDataUsage: number = 0;
         let totalSsdUsage: number = 0;
         let isUsageInfoAvailable: boolean = false;

         // Go through each disk and check for vsan policy cost or errors
         _.each(vmDisks, (disk: any, idx: number) => {
            let costData: VsanCreateCostData =
               response[idx];
            // Check whether we have policy cost for the disk
            let policySatisfiability: any = costData.cost;

            if (policySatisfiability && policySatisfiability.isSatisfiable &&
               policySatisfiability.cost) {

               let policyCost: any = policySatisfiability.cost;
               if (policyCost) {
                  isUsageInfoAvailable = true;
                  // We are interested in the projected capacity of the disk when it
                  // is fully provisioned.
                  totalDataUsage += disk.capacityInBytes *
                     policyCost.diskSpaceToAddressSpaceRatio;
                  totalSsdUsage += policyCost.currentFlashReadCacheSize +
                     policyCost.changeFlashReadCacheSize;
               }
            }
         });

         // Shows the total VSAN consumption of the VM only when there are no
         // errors.
         let usageMessages: any[] = [];
         if (isUsageInfoAvailable) {
            usageMessages.push(this.i18nService.getString("VmUi", "whatIf.vsanConsumption",
                  this.bytesFilter(totalDataUsage), this.bytesFilter(totalSsdUsage)));
         }

         return {
            compatibilityData: {
               errors: [],
               warnings: [],
               infos: usageMessages
            },
            hasSuccess: true
         };

      }

   }

   angular.module("com.vmware.vsphere.client.vm").service(
      "vmProvisioningStorageSelectionValidationService",
      VmProvisioningStorageSelectionValidationService);

} // module
