(function () {
   'use strict';

   angular.module('com.vmware.vsphere.client.network').controller(
         'NetStackViewController', NetStackViewController);

   NetStackViewController.$inject = [
         '$q',
         '$scope',
         'i18nService',
         'vuiConstants',
         'dataService',
         'networkUiConstants',
         'actionToolbarFactory',
         'netStackListService'];

   function NetStackViewController(
         $q,
         $scope,
         i18nService,
         vuiConstants,
         dataService,
         networkUiConstants,
         actionToolbarFactory,
         netStackListService) {

      var self = this;
      var netStackDataProperty = 'netstack:netStackData';

      var netstackActions = [networkUiConstants.actions.EDIT_DNS_AND_ROUTING];
      var editActionEnabled = false;

      self.liveRefreshProperties = [
         "config.network.ipRouteConfig.ipV6GatewayDevice",
         "config.network.ipRouteConfig.ipV6DefaultGateway",
         "config.network.ipRouteConfig.defaultGateway",
         "config.network.dnsConfig.address",
         "config.network.vnic",
         "config.network.netStackInstance"
      ];
      self.netstacksLoading = false;
      self.showNoItemSelected = true;
      self.netstackDetailsHeader = null;
      self.hostId = $scope._route.objectId;

      self.headerOptions = {
         title: i18nService.getString('H5NetworkUi', 'NetStackInstanceList.tcpIpTitleLabel'),
         objectId: self.hostId,
         actions: [{
            actionUid: "vsphere.core.host.network.editIpv6SupportAction",
            customLabel: i18nService.getString(
               'H5NetworkUi', 'NetStackInstanceList.editLabel')
         }]
      };

      self.watchForObjects = [self.hostId];
      self.evaluationContext = {};

      self.splitterOptions = {
         orientation: vuiConstants.splitter.orientation.VERTICAL,
         panes: [
            {
               min: '150px',
               size: '45%'
            }, {
               min: '250px',
               size: '55%'
            }
         ]
      };

      $scope.multipleNetworkStackInstanceSupported = false;
      $scope.preselectComparator = function (gridDataItem) {
         return gridDataItem.key === $scope.lastSelectedNetStackKey;
      };

      function getNetStackInstances() {
         return dataService.getProperties(self.hostId, [netStackDataProperty]);
      }

      // init function
      self.refresh = function() {
         var netStackInstancesPromise = getNetStackInstances();

         var createActionbarOptionsPromise = actionToolbarFactory
               .createActionbarOptions([self.hostId], netstackActions, self.evaluationContext);

         self.netstacksLoading = true;
         $q.all([netStackInstancesPromise, createActionbarOptionsPromise]).then(function(responses) {
            var netStackResponse = responses[0];
            var actionbarOptions = responses[1];

            if (actionbarOptions && actionbarOptions.actions
                  && actionbarOptions.actions.length > 0) {
               // actionToolbarFactory ensures ordering of actions in the response
               editActionEnabled = actionbarOptions.actions[0].enabled;
            }

            var hostNetStackData = netStackResponse ? netStackResponse[netStackDataProperty] : {};
            var netStackItems = hostNetStackData.netStackItems || [];
            setNetStacksType(netStackItems);
            if(netStackResponse && netStackResponse[netStackDataProperty]) {
               $scope.multipleNetworkStackInstanceSupported = netStackResponse[
                  netStackDataProperty].multipleNetworkStackInstanceSupported;
            }

            if (!self.datagridOptions) {
               // init
               self.datagridOptions = netStackListService.createDatagridOptions(
                     netStackItems, actionbarOptions, hostNetStackData.ipv6Enabled, onSelectionChange);
            } else {
               // refresh

               // When the datagridOptions.data is refreshed, the watch for the selectedItems
               // is called with an empty array before the grid preselection directive tries
               // to preselect the last selected item. This flag is used to skip the first
               // trigger of the watch when the data is refreshed.
               self.ingnoreNextItemSelection = true;
               self.datagridOptions.data = netStackItems;
            }

            self.netstacksLoading = false;
         });
      };

      self.refresh();

      function setNetStacksType(netStacks) {
         netStacks.forEach(function (item) {
            item.type = item.isSystemStack
                  ? i18nService.getString('H5NetworkUi', 'NetStackInstanceList.systemSingle')
                  : i18nService.getString('H5NetworkUi', 'NetStackInstanceList.customSingle');
         });
      }

      function onSelectionChange(selectedItems) {
         if (self.datagridOptions.actionBarOptions.actions &&
               self.datagridOptions.actionBarOptions.actions.length > 0) {
            self.datagridOptions.actionBarOptions.actions[0].enabled =
                  selectedItems && selectedItems.length > 0 && editActionEnabled;
         }

         if (self.ingnoreNextItemSelection) {
            self.ingnoreNextItemSelection = false;
            return;
         }

         if (selectedItems && selectedItems.length > 0) {
            $scope.lastSelectedNetStackKey = selectedItems[0].key;

            self.evaluationContext.selectedNetStack = selectedItems[0];
            self.evaluationContext.multipleNetworkStackInstanceSupported =
               $scope.multipleNetworkStackInstanceSupported;

            self.netstackDetailsHeader = i18nService.getString('NetworkUi',
                  'DnsAndRoutingView.tcpIpInstance',
                  selectedItems[0].name);
         } else {
            self.evaluationContext.selectedNetStack = null;
            $scope.lastSelectedNetStackKey = null;
            self.evaluationContext.multipleNetworkStackInstanceSupported = false;
         }

         self.showNoItemSelected = $scope.lastSelectedNetStackKey === null;
      }
   }
})();
