/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace dvs_ui {

   import DvpgIpQualifierModel = com.vmware.vsphere.client.h5.network.dvportgroup.trafficfilter.model.DvpgIpQualifierModel;
   import DvpgPortModel = com.vmware.vsphere.client.h5.network.dvportgroup.trafficfilter.model.DvpgPortModel;
   import DvpgIpAddressModel = com.vmware.vsphere.client.h5.network.dvportgroup.trafficfilter.model.DvpgIpAddressModel;
   import DvpgProtocolModel = com.vmware.vsphere.client.h5.network.dvportgroup.trafficfilter.model.DvpgProtocolModel;

   export class DvpgIpQualifierValidator {

      public static $inject = [
         "i18nService",
         "dvpgTrafficFilterConstants",
         "dvpgQualifierValidatorService",
         "ipParserService",
         "networkUtil"
      ];

      constructor(private i18nService: any,
                  private trafficFilterConstants: any,
                  private validatorService: any,
                  private ipParserService: any,
                  private networkUtil: any) {
      }

      public getValidationErrors(model: DvpgIpQualifierModel): string[] {
         let errors: string [] = [];

         if (!model) {
            return errors;
         }

         // 1. Source and destination ports
         if (model.protocol.type === this.trafficFilterConstants.protocol.TCP ||
               model.protocol.type === this.trafficFilterConstants.protocol.UDP) {
            let sourcePortError: string =
                  this.getPortError(model.sourcePort, true/*isSource*/);
            let sourcePortRangeError: string =
                  this.getPortRangeError(model.sourcePort, true/*isSource*/);
            let destinationPortError: string =
                  this.getPortError(model.destinationPort, false/*isSource*/);
            let destinationPortRangeError: string =
                  this.getPortRangeError(model.destinationPort, false/*isSource*/);

            if (sourcePortError !== this.trafficFilterConstants.EMPTY) {
               errors.push(sourcePortError);
            } else if (sourcePortRangeError !== this.trafficFilterConstants.EMPTY) {
               errors.push(sourcePortRangeError);
            }

            if (destinationPortError !== this.trafficFilterConstants.EMPTY) {
               errors.push(destinationPortError);
            } else if (destinationPortRangeError !== this.trafficFilterConstants.EMPTY) {
               errors.push(destinationPortRangeError);
            }
         }

         // 2. Source and destination IP addresses
         let sourceIpError: string =
               this.getIpAddressError(model.sourceIpAddress, true/*isSource*/);
         let sourcePrefixLengthError: string =
               this.getPrefixLengthError(model.sourceIpAddress, true/*isSource*/);

         let destinationIpError: string =
               this.getIpAddressError(model.destinationIpAddress, false/*isSource*/);
         let destinationPrefixLengthError: string =
               this.getPrefixLengthError(model.destinationIpAddress, false/*isSource*/);

         if (sourceIpError !== this.trafficFilterConstants.EMPTY) {
            errors.push(sourceIpError);
         }

         if (sourcePrefixLengthError !== this.trafficFilterConstants.EMPTY) {
            errors.push(sourcePrefixLengthError);
         }

         if (destinationIpError !== this.trafficFilterConstants.EMPTY) {
            errors.push(destinationIpError);
         }

         if (destinationPrefixLengthError !== this.trafficFilterConstants.EMPTY) {
            errors.push(destinationPrefixLengthError);
         }

         // 3. IP mismatch errors
         let ipMismatchError: string =
               this.getIpMatchError(model.sourceIpAddress, model.destinationIpAddress);

         if (ipMismatchError !== this.trafficFilterConstants.EMPTY) {
            errors.push(ipMismatchError);
         }

         // 4. Protocol errors
         let protocolError: string = this.getProtocolError(model.protocol);

         if (protocolError !== this.trafficFilterConstants.EMPTY) {
            errors.push(protocolError);
         }

         return errors;
      }

      public getPortError(portModel: DvpgPortModel, isSourcePort: boolean): string {
         let error: string = this.trafficFilterConstants.EMPTY;

         if (!portModel ||
               (portModel.operation !==
                     this.trafficFilterConstants.operator.IS &&
               portModel.operation !==
                     this.trafficFilterConstants.operator.IS_NOT)) {
            return error;
         }

         if (!this.validatorService.isValidInteger(portModel.port, 0,
                     this.trafficFilterConstants.MAX_PORT_NUMBER)) {
            return isSourcePort
                  ? this.i18nService.getString(
                        "DvsUi", "trafficFilter.ruleDialog.invalidSourcePort")
                  : this.i18nService.getString(
                        "DvsUi", "trafficFilter.ruleDialog.invalidDestinationPort");
         }

         return error;
      }

      public getPortRangeError(
            portModel: DvpgPortModel, isSourcePort: boolean): string {

         let error: string = this.trafficFilterConstants.EMPTY;

         if (!portModel ||
               (portModel.operation !==
                     this.trafficFilterConstants.operator.IN_RANGE &&
               portModel.operation !==
                     this.trafficFilterConstants.operator.NOT_IN_RANGE)) {
            return error;
         }

         let startPortId: number = parseInt(portModel.port);
         let endPortId: number = parseInt(portModel.portRangeEnd);

         if (isNaN(startPortId) || isNaN(endPortId)) {
            return isSourcePort
                  ? this.i18nService.getString("DvsUi",
                        "trafficFilter.ruleDialog.emptySourcePortRange")
                  : this.i18nService.getString("DvsUi",
                        "trafficFilter.ruleDialog.emptyDestinationPortRange");
         } else if (!this.validatorService.isValidInteger(portModel.port, 0,
                     this.trafficFilterConstants.MAX_PORT_NUMBER) ||
               !this.validatorService.isValidInteger(portModel.portRangeEnd, 0,
                     this.trafficFilterConstants.MAX_PORT_NUMBER)) {
            return isSourcePort
                  ? this.i18nService.getString("DvsUi",
                        "trafficFilter.ruleDialog.invalidSourcePortRange")
                  : this.i18nService.getString("DvsUi",
                        "trafficFilter.ruleDialog.invalidDestinationPortRange");
         } else if (startPortId >= endPortId) {

            return isSourcePort
                  ? this.i18nService.getString("DvsUi",
                        "trafficFilter.ruleDialog.invalidEndSourcePortRange")
                  : this.i18nService.getString("DvsUi",
                        "trafficFilter.ruleDialog.invalidEndDestinationPortRange");
         }
         return error;
      }

      public getIpAddressError(
            ipAddressModel: DvpgIpAddressModel,
            isSourceAddress: boolean): string {

         let error: string = this.trafficFilterConstants.EMPTY;

         if (!ipAddressModel ||
               ipAddressModel.operation === this.trafficFilterConstants.operator.ANY) {
            return error;
         }

         let ipAddress: string =
               (ipAddressModel.ipAddress &&
                     ipAddressModel.ipAddress.length > 0) ?
                     ipAddressModel.ipAddress.trim() : ipAddressModel.ipAddress;

         let isIpv4: boolean = this.ipParserService.isIpv4AddressValid(ipAddress);
         let isIpv6: boolean = false;

         if (!isIpv4) {
            isIpv6 = this.ipParserService.isIpv6AddressValid(ipAddress);
         }

         if (!isIpv4 && !isIpv6) {
            return isSourceAddress
                  ? this.i18nService.getString("DvsUi",
                        "trafficFilter.ruleDialog.invalidSourceIp")
                  : this.i18nService.getString("DvsUi",
                        "trafficFilter.ruleDialog.invalidDestinationIp");
         }

         return error;
      }

      public getPrefixLengthError(
            ipAddressModel: DvpgIpAddressModel,
            isSource: boolean): string {

         let error: string = this.trafficFilterConstants.EMPTY;

         if (!ipAddressModel ||
               (ipAddressModel.operation !==
                     this.trafficFilterConstants.operator.MATCHES &&
                ipAddressModel.operation !==
                     this.trafficFilterConstants.operator.DOES_NOT_MATCH)) {
            return error;
         }

         let ipAddress: string =
               (ipAddressModel.ipAddress &&
               ipAddressModel.ipAddress.length > 0) ?
                     ipAddressModel.ipAddress.trim() : ipAddressModel.ipAddress;

         let isIpv4: boolean = this.ipParserService.isIpv4AddressValid(ipAddress);

         let maxIpPrefix: number = isIpv4
               ? this.trafficFilterConstants.IPV4_ADDRESS_MAX_PREFIX
               : this.trafficFilterConstants.IPV6_ADDRESS_MAX_PREFIX;

         if (!this.validatorService.isValidInteger(ipAddressModel.prefixLength,
               this.trafficFilterConstants.IP_ADDRESS_MIN_PREFIX, maxIpPrefix)) {

            return isSource
                  ? this.i18nService.getString("DvsUi",
                        "trafficFilter.ruleDialog.invalidSourcePrefix",
                        this.trafficFilterConstants.IP_ADDRESS_MIN_PREFIX,
                        maxIpPrefix)
                  : this.i18nService.getString("DvsUi",
                        "trafficFilter.ruleDialog.invalidDestinationPrefix",
                        this.trafficFilterConstants.IP_ADDRESS_MIN_PREFIX,
                        maxIpPrefix);
         }

         return error;
      }

      public getIpMatchError(
            sourceIpAddressModel: DvpgIpAddressModel,
            destinationIpAddressModel: DvpgIpAddressModel): string {

         let error: string = this.trafficFilterConstants.EMPTY;

         if (!sourceIpAddressModel || !destinationIpAddressModel) {
            return error;
         }

         let sourceIpVersion: string =
               this.getIpAddressVersion(sourceIpAddressModel);
         let destinationIpVersion: string =
               this.getIpAddressVersion(destinationIpAddressModel);

         if (sourceIpVersion !== this.trafficFilterConstants.addressType.MIXED &&
               destinationIpVersion !== this.trafficFilterConstants.addressType.MIXED &&
               sourceIpVersion !== destinationIpVersion) {

            error = this.i18nService.getString(
                  "DvsUi", "trafficFilter.ruleDialog.ipMismatch");
         }

         return error;
      }

      public getIpAddressVersion(ipAddressModel: DvpgIpAddressModel): string {

         if (!ipAddressModel) {
            return this.trafficFilterConstants.addressType.MIXED;
         }

         if (ipAddressModel.operation === this.trafficFilterConstants.operator.ANY) {
            return ipAddressModel.ipAddressType;
         }

         let ipAddress: string =
               (ipAddressModel.ipAddress &&
                     ipAddressModel.ipAddress.length > 0)
                     ? ipAddressModel.ipAddress.trim()
                     : ipAddressModel.ipAddress;

         let isIpv4: boolean = this.ipParserService.isIpv4AddressValid(ipAddress);
         let isIpv6: boolean = this.ipParserService.isIpv6AddressValid(ipAddress);

         if (isIpv4) {
            return this.trafficFilterConstants.addressType.IPV4;
         } else if (isIpv6) {
            return this.trafficFilterConstants.addressType.IPV6;
         }

         return this.trafficFilterConstants.addressType.MIXED;
      }

      public getProtocolError(
            protocolModel: DvpgProtocolModel): string {

         if (!protocolModel ||
               protocolModel.operation === this.trafficFilterConstants.operator.ANY) {
            return this.trafficFilterConstants.EMPTY;
         }

         let protocolCode: string;

         if (protocolModel.type === this.trafficFilterConstants.protocol.CUSTOM) {
            protocolCode = protocolModel.customValue;
         } else {
            protocolCode = protocolModel.type.toString();
         }

         if (!this.validatorService.isValidInteger(protocolCode,
               this.trafficFilterConstants.MIN_PROTOCOL_VALUE,
               this.trafficFilterConstants.MAX_PROTOCOL_VALUE)) {

            return this.i18nService.getString(
                  "DvsUi", "trafficFilter.ruleDialog.invalidProtocol");
         }

         return this.trafficFilterConstants.EMPTY;
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .service("dvpgIpQualifierValidator", DvpgIpQualifierValidator);
}
