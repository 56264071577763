/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
(function () {
   'use strict';

   /**
    *  Helper for opening the Modify Datastore dialog.
    */
   angular
      .module('com.vmware.vsphere.client.storage')
      .service('modifyDatastoreDialogService', modifyDatastoreDialogService);

   modifyDatastoreDialogService.$inject = [
      'clarityModalService',
      'dataService',
      'i18nService',
      'clarityConstants'];

   function modifyDatastoreDialogService(
         clarityModalService, dataService, i18nService, clarityConstants) {

      // ======================= Public API ==========================

      return {
         openModifyDatastoreDialog: openModifyDatastoreDialog,
         openRemoveDatastoreDialog: openRemoveDatastoreDialog
      };

      //======================= Public Functions ==========================

      /**
       * Opens an OK/Cancel modal dialog in the application.
       */
      function openModifyDatastoreDialog(
            datastoreId, dialogTitle, availableHostPropertyName, submitCallback, headerHtmlUrl) {

         dataService.getProperties(datastoreId, ['name']).then(
            function (result) {
               var dsName = result ? result.name : null;
               openDialog(dsName);
            },
            function () {
               openDialog(null);
            });

         function openDialog(dsName) {
            var modalOptions = {
               title: dialogTitle,
               subTitle: {
                  text: dsName
               },
               defaultButton: 'submit',
               size: 'lg',
               alerts: [],
               contentTemplate: 'storage-ui/resources/storage/views/datastore/ModifyDatastoreDialog.html',
               onSubmit: onSubmit
            };

            modalOptions.dialogData = {
               datastoreId: datastoreId,
               selectedHosts: [],
               availableHostPropertyName: availableHostPropertyName,
               headerHtmlUrl: headerHtmlUrl,
               errorApi: {
                  showError: showError,
                  clearErrors: clearErrors
               }
            };

            clarityModalService.openOkCancelModal(modalOptions);

            function onSubmit () {
               if (modalOptions.dialogData.selectedHosts && modalOptions.dialogData.selectedHosts.length) {
                  return submitCallback(modalOptions.dialogData.selectedHosts,
                     modalOptions.dialogData.allHosts.length ===
                     modalOptions.dialogData.selectedHosts.length);
               }

               showError(i18nService.getString('StorageUi', 'modifyDatastoreForm.noSelection'));
               return false;
            }

            function showError(errorMessage) {
               modalOptions.alerts = [{
                  text: errorMessage,
                  type: clarityConstants.notifications.type.ERROR
               }];
            }

            function clearErrors() {
               modalOptions.alerts = [];
            }
         }
      }

      function openRemoveDatastoreDialog (datastoreIds, dialogTitle, dialogMsg, submitCallback) {

         var modalOptions = {
            title: dialogTitle,
            message: dialogMsg,
            defaultButton: 'submit',
            alerts: [],
            preserveNewlines: true,
            submit: function (){
               return submitCallback();
            }
         };

         modalOptions.dialogData = {
            datastoreIds: datastoreIds
         };

         clarityModalService.openConfirmationModal(modalOptions);
      }
   }
})();
