/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
(function () {
   'use strict';

   angular
         .module('com.vmware.vsphere.client.network')
         .service('addHostNetworkingCreateStandardSwitchPageValidator',
               AddHostNetworkingCreateStandardSwitchPageValidator);

   AddHostNetworkingCreateStandardSwitchPageValidator.$inject = [
      'i18nService',
      'clarityModalService'
   ];

   function AddHostNetworkingCreateStandardSwitchPageValidator(i18nService,
                                                               clarityModalService) {

      this.getValidationErrorsAndWarnings = function (pnics, pnicsDetails) {

         var dialogTitle = null;
         var warningMessage = null;
         var hasToShowWarning = false;

         var activePnics = pnics.active;
         if (!activePnics || activePnics.length === 0) {
            dialogTitle = i18nService.getString('NetworkUi',
                  'PhysicalNicFailoverOrderPage.warn.noActiveNics.title');
            warningMessage = i18nService.getString('NetworkUi',
                  'PhysicalNicFailoverOrderPage.warn.noActiveNics.text');
            hasToShowWarning = true;
         } else if (areAllPnicsDisconnected(activePnics, pnicsDetails)) {
            dialogTitle = i18nService.getString('NetworkUi',
                  'PhysicalNicFailoverOrderPage.warn.allActiveNicsDisconnected.title');
            warningMessage = i18nService.getString('NetworkUi',
                  'PhysicalNicFailoverOrderPage.warn.allActiveNicsDisconnected.text');
            hasToShowWarning = true;
         }

         if(hasToShowWarning) {

            var modalOptions = {
               title: dialogTitle,
               message: warningMessage,
               clarityIcon: {
                  class: "is-warning",
                  shape: "warning-standard",
                  size: "32"
               },
               preserveNewlines: true
            };

            return clarityModalService.openConfirmationModal(modalOptions);
         } else {
            return true;
         }
      };

      function areAllPnicsDisconnected(activePnics, pnicsDetails) {
         return !_.some(activePnics, function (pnic) {
            if (pnic && pnicsDetails[pnic] && pnicsDetails[pnic].isConnected) {
               return true;
            }
         });
      }
   }
})();