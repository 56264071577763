/* Copyright 2015 VMware, Inc. All rights reserved. -- VMware Confidential */

/*
 * Controller for alarm definitions (VM(other type) > Manage > Alarm Definitions).
 */
(function() {
   'use strict';
    angular.module('com.vmware.vsphere.client.commonModule')
           .controller('AlarmDefinitionsController', AlarmDefinitionsController);
    angular.module('com.vmware.vsphere.client.commonModule').filter('trusted', ['$sce', function($sce) {
       return function(text) { return $sce.trustAsHtml(text); };
    }]);
    AlarmDefinitionsController.$inject = ['$scope', 'vuiConstants', 'dataService', 'mutationService',
                                          'i18nService', 'defaultUriSchemeUtil', 'clarityModalService'];

    function AlarmDefinitionsController($scope, vuiConstants, dataService, mutationService,
            i18nService, defaultUriSchemeUtil, clarityModalService) {
       var self = this;
       self.bundle = 'AlarmsUi';

       var REMOVE_ALARM_SPEC = 'com.vmware.opsmgmt.client.alarms.RemoveAlarmDefinitionSpec';

       var nameColumn = i18nService.getString(self.bundle, 'alarmsDefTabView.tableHeaderText.name');
       var definedInColumn = i18nService.getString(self.bundle, 'alarmsDefTabView.tableHeaderText.definedIn');

       /**
        * Open Delete Confirmation Modal
        */
       self.openDeleteModal = function () {
          var modalOptions = {
             title: i18nService.getString('AlarmsUi', 'alarmSettingsWizard.confirm.removeAlarmDefinition.shorttitle'),
             message: i18nService.getString('AlarmsUi', 'alarmSettingsWizard.confirm.removeAlarmDefinition.message', self.selectedItem.alarmInfo.name),
             icon: 'vsphere-icon-status-warning',
             submit: self.deleteAlarm
          };

          clarityModalService.openConfirmationModal(modalOptions);
       };

       var deleteAction = {
          id: 'delete-alarm-action',
          iconClass: 'vsphere-icon-toolbar-remove',
          enabled: false,
          tooltipText: i18nService.getString('AlarmsUi', 'alarmAction.remove.description'),
          onClick: self.openDeleteModal
       };

       self.dataGridOptions = {
          actionBarOptions: {
             actions: [
                deleteAction
             ]
          },
          columnDefs: [
             { displayName: nameColumn, field: 'alarmInfo.name', width: '150px'},
             { displayName: definedInColumn, field: 'definedInName', width: '150px'}
          ],
          sortMode: vuiConstants.grid.sortMode.SINGLE,
          selectionMode: vuiConstants.grid.selectionMode.SINGLE,
          selectedItems: [],
          data: [],
          height: '100%',
          resizable: true
       };
       self.splitterOptions = {
          orientation: vuiConstants.splitter.orientation.VERTICAL,
          panes: [{
             min: '140px'
          }, {
             min: '100px'
          }]
       };

       /*
        * Detects click events of the vui-datagrid.
        */
       $scope.$watch('c.dataGridOptions.selectedItems', function(selectedItems) {
          if (selectedItems.length > 0) {
             self.selectedItem = selectedItems[0];
             self.stackViewOptions = {
                header: { title: self.selectedItem.alarmInfo.name }
             };
             self.triggersTitle = computeTriggersTitle();
             deleteAction.enabled = isDeleteActionAvailable();
             getAlarmDetails();
          } else {
             deleteAction.enabled = false;
             self.selectedItem = null;
          }
       });

       /*
        * Fetches the data for the Alarm Definitions (vui-datagrid).
        */
       self.refreshAlarmsGrid = function () {
          return dataService.getProperties($scope._route.objectId, "alarmDefinitions").then(function(res) {
             self.dataGridOptions.data = res.alarmDefinitions;
          });
       };

       function getAlarmDetails() {
          self.alarmDetails = null;
          var objectId = defaultUriSchemeUtil.getVsphereObjectId(self.selectedItem.alarmState.alarm);
          dataService.getProperties(objectId, "alarmDetails").then(function (res) {
             self.alarmDetails = res.alarmDetails || undefined;
          });
       }

       self.getStatusImage = function(status) {
          var imageClass;
          switch (status) {
             case 'statusGreen':
                imageClass = "vsphere-icon-status-ok";
                break;
             case 'statusYellow':
                imageClass = "vsphere-icon-status-warning";
                break;
             case 'statusRed':
                imageClass = "vsphere-icon-status-error";
                break;
             case 'statusUnknown':
                imageClass = "vsphere-icon-status-unknown";
                break;
          }
          return imageClass;
       };

       /*
        * Localize the title text for the triggers stack block.
        */
       function computeTriggersTitle() {
          if (!self.selectedItem) {
             return;
          }
          var exp = self.selectedItem.alarmInfo.expression;
          if (!exp) {
             return;
          }
          var orExpType = 'com.vmware.vim.binding.vim.alarm.OrAlarmExpression';
          var pred = (exp._type === orExpType) ? 'triggers.any' : 'triggers.all';
          var predicate = i18nService.getString(self.bundle, pred);
          return i18nService.getString(self.bundle, 'triggersBlock.lblAllAny.states', [predicate]);
       }

       /**
        * Check whether the delete action is enabled or not based on the level of the object
        *
        * @returns {boolean}
        */
       function isDeleteActionAvailable() {
          var entityServerGuid = self.selectedItem.alarmInfo.entity.serverGuid;
          var entityType = self.selectedItem.alarmInfo.entity.type;
          var entityValue = self.selectedItem.alarmInfo.entity.value;
          var alarmObjectId = defaultUriSchemeUtil.createVmomiUri(entityType, entityValue, entityServerGuid);

          return alarmObjectId === $scope._route.objectId;
       }

       /**
        * Delete the alarm definition
        */
       self.deleteAlarm = function () {
          var objectId = defaultUriSchemeUtil.getVsphereObjectId(self.selectedItem.alarmState.alarm);
          return mutationService.remove(objectId, REMOVE_ALARM_SPEC, {}).then(function () {
             self.refreshAlarmsGrid();
          });
       };

       self.refreshAlarmsGrid();
    }
})();
