(function() {
   'use strict';
   angular.module('com.vmware.vsphere.client.host').service('hostServicesService',
         HostServicesService);

   HostServicesService.$inject = ['hostServicesConstants', 'i18nService', 'logService',
      'mutationService', 'vuiActionsMenuService', '$rootScope', 'vuiConstants',
      'actionConfirmationService', 'actionsService', 'clarityModalService'];

   function HostServicesService(hostServicesConstants, i18Service, logService,
         mutationService, vuiActionsMenuService, $rootScope,
         vuiConstants, actionConfirmationService, actionsService, clarityModalService) {

      var EDIT_SERVICE_STARTUP_POLICY =
            'vsphere.core.host.actions.service.editStartupPolicy';
      var log = logService('HostServicesService');

      var startButton = {
         id: hostServicesConstants.action.START,
         tooltipText: getString('service.start.action.description'),
         label: getString('service.start.action.label'),
         enabled: false,
         iconClass: 'vx-icon-serviceStart'
      };
      var stopButton = {
         id: hostServicesConstants.action.STOP,
         tooltipText: getString('service.stop.action.description'),
         label: getString('service.stop.action.label'),
         enabled: false,
         iconClass: 'vx-icon-serviceStop'
      };
      var restartButton = {
         id: hostServicesConstants.action.RESTART,
         tooltipText: getString('service.restart.action.description'),
         label: getString('service.restart.action.label'),
         enabled: false,
         iconClass: 'vx-icon-serviceRestart'
      };

      var editButton = {
         id: hostServicesConstants.action.EDIT,
         tooltipText: getString('service.edit.action.description'),
         label: getString('service.edit.action.label'),
         enabled: false,
         iconClass: 'vx-icon-serviceEdit'
      };

      function updateButtonStatus(serviceStatus) {
         switch (serviceStatus) {
            case hostServicesConstants.status.RUNNING:
               startButton.enabled = false;
               stopButton.enabled = true;
               restartButton.enabled = true;
               editButton.enabled = true;
               break;
            case hostServicesConstants.status.STOPPED:
               startButton.enabled = true;
               stopButton.enabled = false;
               restartButton.enabled = false;
               editButton.enabled = true;
               break;
			default:
               startButton.enabled = false;
               stopButton.enabled = false;
               restartButton.enabled = false;
               editButton.enabled = false;
			   break;
         }
      }

      function updateActionBar(serviceStatus, hasPrivileges, hostConnected) {
         if (hostConnected && hasPrivileges) {
            updateButtonStatus(serviceStatus);
         } else {
            updateButtonStatus(hostServicesConstants.NO_PRIVILEGE);
         }
      }

      function getString(key, parameters) {
         return i18Service.getString('HostUi', key, parameters);
      }

      function createActionBar(callback) {
         startButton.onClick = function() {
            callback(hostServicesConstants.action.START);
         };

         stopButton.onClick = function() {
            callback(hostServicesConstants.action.STOP);
         };

         restartButton.onClick = function() {
            callback(hostServicesConstants.action.RESTART);
         };

         editButton.onClick = function() {
            callback(hostServicesConstants.action.EDIT);
         };
         return {
            actions: [
               restartButton, startButton, stopButton,
               vuiConstants.actions.SEPARATOR, editButton
            ],
            updateActionBar: updateActionBar
         };
      }

      function confirmAndExecuteServiceCommand(hostId, actionId, service) {
         function executeCommand() {
            executeServiceCommand(hostId, actionId, service.key);
         }
         var modalOptions = {};
         switch (actionId) {
            case hostServicesConstants.action.RESTART:
               modalOptions = {
                   title :getString('service.restart.confirmation.title'),
                   message :getString('service.restart.confirmation.text', service.label),
                   submit : executeCommand
               };
               clarityModalService.openConfirmationModal(modalOptions);
               break;
            case hostServicesConstants.action.START:
               executeCommand();
               break;
            case hostServicesConstants.action.STOP:
               modalOptions = {
                  title :getString('service.stop.confirmation.title'),
                  message :getString('service.stop.confirmation.text', service.label),
                  submit : executeCommand
               };
               clarityModalService.openConfirmationModal(modalOptions);
               break;
            case hostServicesConstants.action.EDIT:
               //TODO move the action in plugin xml
               actionsService.invokeAction({
                  available: true,
                  invoker: [EDIT_SERVICE_STARTUP_POLICY],
                  action: {
                    uid:EDIT_SERVICE_STARTUP_POLICY
                  }
               }, { hostId: hostId,
                   service: service});
               break;
			default:
               break;
         }
      }

      function executeServiceCommand(hostId, actionId, serviceKey) {
         var specType = 'com.vmware.vsphere.client.host.config.ServiceActionSpec';
         var spec = {
            action: actionId,
            serviceKey: serviceKey
         };

         return mutationService.apply(hostId, specType, spec);
      }

      function getColumnDefs() {
         return [
            {
               displayName: getString('services.col.name'),
               field: 'label'
            },
            {
               displayName: getString('services.col.daemon'),
               field: 'running',
               template: function(dataItem) {
                  if (dataItem.running === "true") {
                     return getString('daemon.running');
                  } else {
                     return getString('daemon.stopped');
                  }
               }
            },
            {
               displayName: getString('services.col.startupPolicy'),
               field: 'policy',
               template: function(dataItem) {
                  return getPolicyText(dataItem.policy);
               }
            }
         ];
      }

      function getViewHeaderOptions(objectId) {
         return {
            title: getString('settings.service'),
            objectId: objectId,
            actions: [{
               actionUid: 'vsphere.core.host.actions.refreshServicesAction',
               customLabel: getString('refresh.services.button.label')
            }]
         };
      }

      function getPolicyText(policy) {
         var policyText;
         switch (policy) {
            case hostServicesConstants.policy.ON:
               policyText = getString('policy.on.label');
               break;
            case hostServicesConstants.policy.OFF:
               policyText = getString('policy.off.label');
               break;
            case hostServicesConstants.policy.AUTOMATIC:
               policyText = getString('policy.auto.label');
               break;
            default:
               log.error("Unexpected startup policy: " + policy);
               policyText = getString('policy.na.label');
         }
         return policyText;
      }

      function showServiceActionContextMenu(event) {
         var menuTitle = '';
         if (event && !_.isEmpty(event.data) && event.data[0]) {
            menuTitle = getString('service.menu.label', event.data[0].label);
         }
         var localScope = $rootScope.$new();
         localScope.hostServicesMenuDescriptor = {
            label: menuTitle,
            iconClass: '',
            items: [
               restartButton, startButton, stopButton,
               vuiConstants.actions.SEPARATOR, editButton
            ]
         };

         var target = event.currentTarget;
         if (!target && event) {
            target = event.target;
         }

         var menuOptions = {
            scope: localScope,
            configObjectName: 'hostServicesMenuDescriptor',
            target: target,
            coordinates: { x: event.clientX, y: event.clientY },
            menuContainerId: 'applicationMenuContainer'
         };

         vuiActionsMenuService.showMenu(menuOptions);
      }


      function getDatagridOptions(serviceActionBarCallback) {
         return {
            selectionMode: vuiConstants.grid.selectionMode.SINGLE,
            sortMode: vuiConstants.grid.sortMode.SINGLE,
            searchable: false,
            resizable: false,
            selectedItems: [],
            actionBarOptions: createActionBar(serviceActionBarCallback),
            data: [],
            columnDefs: getColumnDefs(),
            height: '100%',
            pageConfig: {
               hidePager: true
            }
         };
      }

      return {
         confirmAndExecuteServiceCommand: confirmAndExecuteServiceCommand,
         getColumnDefs: getColumnDefs,
         showServiceActionContextMenu: showServiceActionContextMenu,
         getDatagridOptions: getDatagridOptions,
         getViewHeaderOptions: getViewHeaderOptions
      };
   }
})();
