namespace h5_client {
    export module common_module_ui {

        import IPromise = angular.IPromise;

        export class TriggeredAlarmsRefreshService {

            static $inject = [
                "$q",
                "defaultUriSchemeUtil",
                "objectTypesService",
                "dataService",
                "timeFormatterService"
            ];

            private readonly SOURCE_OBJECTS_PROPERTY: string = "sourceObjects";

            constructor(
                    private $q: any,
                    private defaultUriSchemeUtil: any,
                    private objectTypesService: any,
                    private dataService: any,
                    private timeFormatterService: any) { }

            public handleAlarmUpdates(partialUpdate: any, objectId: string, alarms: Array<any>): IPromise<boolean> {
                // Update to an existing alarm
                if (partialUpdate.isDelta) {
                    // Reset to green
                    if (!partialUpdate.data) {
                        return this.handleResetToGreenAlarm(partialUpdate, alarms);
                    }

                    // Acknowledge
                    if (partialUpdate.data) {
                        return this.handleAcknowledgeAlarm(partialUpdate, alarms);
                    }
                }

                return this.isNewAlarmRelevant(partialUpdate, objectId)
                        .then((isAlarmRelevant: boolean): IPromise<boolean> => {
                            if (isAlarmRelevant) {
                                return this.handleNewAlarm(partialUpdate, alarms);
                            }

                            return this.$q.resolve(false);
                        });
            }

            private isNewAlarmRelevant(partialUpdate: any, objectId: string): IPromise<boolean> {
                if (this.isAlarmEntitySameAsCurrentView(partialUpdate, objectId)) {
                    return this.$q.resolve(true);
                }

                if (this.isCurrentViewRootFolder(objectId) && this.isAlarmEntityRootFolderMatching(partialUpdate, objectId)) {
                    return this.$q.resolve(true);
                }

                return this.isAlarmEntityUnderCurrentView(partialUpdate, objectId);
            }

            private isAlarmEntitySameAsCurrentView(partialUpdate: any, objectId: string): boolean {
                let alarmEntityId: string = this.defaultUriSchemeUtil.getVsphereObjectId(partialUpdate.data.entity);
                return alarmEntityId === objectId;
            }

            private isCurrentViewRootFolder(objectId: string): boolean {
                return this.defaultUriSchemeUtil.isRootFolder(objectId);
            }

            private isAlarmEntityUnderCurrentView(partialUpdate: any, objectId: string): IPromise<boolean> {
                let alarmEntityId: string = this.defaultUriSchemeUtil.getVsphereObjectId(partialUpdate.data.entity);

                return this.dataService.getProperties(alarmEntityId, this.SOURCE_OBJECTS_PROPERTY)
                        .then((response: any): boolean => {
                            if (response && response[this.SOURCE_OBJECTS_PROPERTY]) {
                                let sourceObjects: any = response[this.SOURCE_OBJECTS_PROPERTY];
                                let hostedObjectId: any = this.defaultUriSchemeUtil.getVsphereObjectId(sourceObjects.hostedObject);
                                let vmFolder: any = this.defaultUriSchemeUtil.getVsphereObjectId(sourceObjects.vmFolder);

                                return (hostedObjectId === objectId) || (vmFolder === objectId);
                            }

                            return false;
                        });
            }

            private isAlarmEntityRootFolderMatching(partialUpdate: any, objectId: string): boolean {
                let alarmEntityMor: any = partialUpdate.data.entity;
                let objectMor: any = this.defaultUriSchemeUtil.getManagedObjectReference(objectId);

                return alarmEntityMor.serverGuid === objectMor.serverGuid;
            }

            private handleNewAlarm(partialUpdate: any, alarms: Array<any>): IPromise<boolean> {
                let alarmKey: string = partialUpdate.data.key;
                let alarmInfo: any = {
                    entity: partialUpdate.data.entity,
                    entityName: partialUpdate.metadata.issueInfo.entityName,
                    details: {
                        alarmState: {
                            _type: "com.vmware.vim.binding.vim.alarm.AlarmState",
                            key: alarmKey,
                            overallStatus: partialUpdate.data.overallStatus,
                            time: this.getTimestampFromDate(partialUpdate.data.time),
                            acknowledged: partialUpdate.data.acknowledged,
                            entity: partialUpdate.data.entity
                        },
                        alarmInfo: {
                            _type: "com.vmware.vim.binding.vim.alarm.AlarmInfo",
                            name: partialUpdate.metadata[alarmKey].name,
                            entity: partialUpdate.metadata[alarmKey].entity,
                            alarm: partialUpdate.data.alarm
                        },
                        definedInEntityName: partialUpdate.metadata.alarmInfoName
                    }
                };

                return this.getDefinedInEntityObject(alarmInfo)
                        .then((typeInfo: any) => {
                            alarmInfo.definedInEntityIcon = typeInfo.icon;
                            alarmInfo.definedInEntityNamespace = typeInfo.namespace;
                            alarms.push(alarmInfo);
                            return true;
                        });
            }

            private handleResetToGreenAlarm(partialUpdate: any, alarms: Array<any>): IPromise<boolean> {
                let alarmIndex: number = _.findIndex(alarms, (alarm: any): boolean => {
                    return partialUpdate.source === alarm.details.alarmState.key;
                });

                if (alarmIndex === -1) {
                    return this.$q.resolve(false);
                }

                alarms.splice(alarmIndex, 1);
                return this.$q.resolve(true);
            }

            private handleAcknowledgeAlarm(partialUpdate: any, alarms: Array<any>): IPromise<boolean> {
                let alarmIndex: number = _.findIndex(alarms, (alarm: any): boolean => {
                    return partialUpdate.data.key === alarm.details.alarmState.key;
                });

                if (alarmIndex === -1) {
                    return this.$q.resolve(false);
                }

                let alarmStateToUpdate = alarms[alarmIndex].details.alarmState;
                alarmStateToUpdate.acknowledged = partialUpdate.data.acknowledged;
                alarmStateToUpdate.acknowledgedTime = this.getTimestampFromDate(partialUpdate.data.acknowledgedTime);
                alarmStateToUpdate.acknowledgedByUser = partialUpdate.data.acknowledgedByUser;

                return this.$q.resolve(true);
            }

            private getTimestampFromDate(time: any): number {
                return new Date(
                    Date.UTC(
                        time.year, time.month, time.dayOfMonth,
                        time.hourOfDay, time.minute, time.second
                    )
                ).valueOf();
            }

            private getDefinedInEntityObject(alarmInfo: any): IPromise<any> {
                let definedInType: string = alarmInfo.details.alarmInfo.entity._type;
                return this.objectTypesService.getObjectTypeSpec(definedInType);
            }
        }

        angular
            .module("com.vmware.vsphere.client.commonModule")
            .service("triggeredAlarmsRefreshService", TriggeredAlarmsRefreshService);
    }
}