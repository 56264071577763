namespace h5_vm {
   declare const ipaddr: any;
   import Specification = com.vmware.vim.binding.vim.vm.customization.Specification;
   import LinuxPrep = com.vmware.vim.binding.vim.vm.customization.LinuxPrep;
   import LinuxOptions = com.vmware.vim.binding.vim.vm.customization.LinuxOptions;
   import Sysprep = com.vmware.vim.binding.vim.vm.customization.Sysprep;
   import WinOptions = com.vmware.vim.binding.vim.vm.customization.WinOptions;
   import UserData = com.vmware.vim.binding.vim.vm.customization.UserData;
   import SysprepText = com.vmware.vim.binding.vim.vm.customization.SysprepText;
   import NameGenerator = com.vmware.vim.binding.vim.vm.customization.NameGenerator;
   import FixedName = com.vmware.vim.binding.vim.vm.customization.FixedName;
   import PrefixNameGenerator = com.vmware.vim.binding.vim.vm.customization.PrefixNameGenerator;
   import CustomNameGenerator = com.vmware.vim.binding.vim.vm.customization.CustomNameGenerator;
   import VirtualMachineNameGenerator = com.vmware.vim.binding.vim.vm.customization.VirtualMachineNameGenerator;
   import UnknownNameGenerator = com.vmware.vim.binding.vim.vm.customization.UnknownNameGenerator;
   import GlobalIPSettings = com.vmware.vim.binding.vim.vm.customization.GlobalIPSettings;
   import GuiUnattended = com.vmware.vim.binding.vim.vm.customization.GuiUnattended;
   import LicenseFilePrintData = com.vmware.vim.binding.vim.vm.customization.LicenseFilePrintData;
   import LicenseFilePrintData$AutoMode = com.vmware.vim.binding.vim.vm.customization.LicenseFilePrintData$AutoMode;
   import Password = com.vmware.vim.binding.vim.vm.customization.Password;
   import Identification = com.vmware.vim.binding.vim.vm.customization.Identification;
   import GuiRunOnce = com.vmware.vim.binding.vim.vm.customization.GuiRunOnce;

   export class GosSpecModelManagerService {
      static $inject = ["$q",
         "gosCustomizationHelperService",
         "i18nService",
         "defaultUriSchemeUtil",
         "gosSpecNetworkService",
         "ipParserService"];

      private static readonly AUTO_USERS_MIN: number = 5;
      private readonly DOMAIN_DEFAULT: string = "DOMAIN";
      private readonly WORKGROUP_DEFAULT: string = "WORKGROUP";

      constructor(private $q: any,
            private gosCustomizationHelperService: GosCustomizationHelperService,
            private i18nService: any,
            private defaultUriSchemeUtil: any,
            private gosSpecNetworkService: GosSpecNetworkService,
            private ipParserService: any) {
      }

      public setModelDefaults(scope: any,
            defaultFlowId: GosType, onWizardFlowChange: Function): void {
         // Name and OS
         let nameAndOsPageModel: GosNameAndOsPageModel =
               scope.gosNameAndOsPageModel as GosNameAndOsPageModel;
         nameAndOsPageModel.gosType = defaultFlowId;
         nameAndOsPageModel.gosTypeChanged = onWizardFlowChange;
         nameAndOsPageModel.originalSpecName = undefined;
         nameAndOsPageModel.specName = "";
         nameAndOsPageModel.description = "";
         nameAndOsPageModel.isSidGenerated = true;

         // Computer name
         let computerNamePageModel: GosComputerNamePageModel =
               scope.computerNamePageModel as GosComputerNamePageModel;
         computerNamePageModel.type = nameAndOsPageModel.gosType;
         computerNamePageModel.setNameOption = ComputerNameOption.USE_VM_NAME;
         computerNamePageModel.computerName = "";
         computerNamePageModel.isAppendNumericValue = false;
         computerNamePageModel.generateNameArgument = "";
         computerNamePageModel.domainName = "";

         // Registration information
         let registrationInformationPageModel: GosRegistrationInformationPageModel =
               scope.registrationInformationPageModel as GosRegistrationInformationPageModel;
         registrationInformationPageModel.ownerName = "";
         registrationInformationPageModel.organizationName = "";

         // Windows license
         let windowsLicensePageModel: GosWindowsLicensePageModel =
               scope.windowsLicensePageModel as GosWindowsLicensePageModel;
         windowsLicensePageModel.productKey = "";
         windowsLicensePageModel.isIncludeServerLicenseInfo = true;
         windowsLicensePageModel.isServerLicenseModePerServer = true;
         windowsLicensePageModel.maxConnections = GosSpecModelManagerService.AUTO_USERS_MIN;

         // Time zone
         let timeZonePageModel: GosTimeZonePageModel =
               scope.timeZonePageModel as GosTimeZonePageModel;
         timeZonePageModel.timeZoneArea = "";
         timeZonePageModel.timeZoneLocation = "";
         timeZonePageModel.timeZoneWinCode = 0;
         timeZonePageModel.hwClockUTC = true;

         // Network
         let networkPageModel: GosNetworkPageModel =
               scope.networkPageModel as GosNetworkPageModel;
         networkPageModel.networkSettingsType =
               GosNetworkSettingsType.STANDARD;
         networkPageModel.adapterMappingWrappers = this.gosSpecNetworkService
               .getInitialNetworkCards();
         networkPageModel.dnsSuffixList = [];

         // DNS settings
         let dnsSettingsPageModel: GosDnsSettingsPageModel =
               scope.dnsSettingsPageModel as GosDnsSettingsPageModel;
         dnsSettingsPageModel.primaryDns = "";
         dnsSettingsPageModel.secondaryDns = "";
         dnsSettingsPageModel.tertiaryDns = "";
         dnsSettingsPageModel.dnsSearchPath = [];

         // Administrator password
         let administratorPasswordPageModel: GosAdministratorPasswordPageModel =
               scope.administratorPasswordPageModel as GosAdministratorPasswordPageModel;
         administratorPasswordPageModel.autoLogon = false;
         administratorPasswordPageModel.password = "";
         administratorPasswordPageModel.confirmedPassword = "";
         administratorPasswordPageModel.isPasswordChanged = false;
         administratorPasswordPageModel.logonTimes = 1;

         // Custom sysprep file
         let customSysprepFilePageModel: GosCustomSysprepFilePageModel =
               scope.customSysprepFilePageModel as GosCustomSysprepFilePageModel;
         customSysprepFilePageModel.fileOption = CustomSysprepFileOption.IMPORT_FILE;
         customSysprepFilePageModel.sysprepContent = "";
         customSysprepFilePageModel.sysprepImportFileContent = "";
         customSysprepFilePageModel.sysprepImportFileName = "";

         // Workgroup or domain
         let workgroupDomainPageModel: GosWorkgroupDomainPageModel =
               scope.workgroupDomainPageModel;
         workgroupDomainPageModel.networkOption = WorkgroupDomainTypeOption.WORKGROUP;
         workgroupDomainPageModel.workgroup = this.WORKGROUP_DEFAULT;
         workgroupDomainPageModel.domain = this.DOMAIN_DEFAULT;
         workgroupDomainPageModel.username = "";
         workgroupDomainPageModel.password = "";
         workgroupDomainPageModel.confirmPassword = "";
         workgroupDomainPageModel.isDomainPasswordChanged = false;

         // Run once
         let commandsPageModel: GosCommandsPageModel = scope.commandsPageModel;
         commandsPageModel.commands = [];
      }

      /**
       *  Populates the initial state taken from the specInfo object into the page models.
       */
      public populatePageModels(scope: any,
            specInfo: CustomizationSpecInfoCustom, specDetails: Specification): void {
         // Name and OS
         if (scope.gosNameAndOsPageModel) {
            this.populateNameAndOsModel(scope, specInfo, specDetails);
            this.updateDependentModels(scope, scope.gosNameAndOsPageModel);
         }

         // Computer Name
         if (scope.computerNamePageModel) {
            this.populateComputerNameModel(scope, specInfo, specDetails);
            this.updateDependentModels(scope, scope.computerNamePageModel);
         }

         // Registration Information
         if (scope.registrationInformationPageModel) {
            this.populateRegistrationInformationModel(scope, specDetails);
            this.updateDependentModels(scope, scope.registrationInformationPageModel);
         }

         // Windows license
         if (scope.windowsLicensePageModel) {
            this.populateWindowsLicenseModel(scope, specInfo, specDetails);
            this.updateDependentModels(scope, scope.windowsLicensePageModel);
         }

         // Time Zone
         if (scope.timeZonePageModel) {
            this.populateTimeZoneModel(scope, specInfo, specDetails);
            this.updateDependentModels(scope, scope.timeZonePageModel);
         }

         if (scope.networkPageModel) {
            this.populateNetworkPageModel(scope, specDetails);
            this.updateDependentModels(scope, scope.networkPageModel);
         }

         // DNS settings
         if (scope.dnsSettingsPageModel) {
            this.populateDnsSettingsModel(scope, specInfo, specDetails);
            this.updateDependentModels(scope, scope.dnsSettingsPageModel);
         }

         if (scope.customSysprepFilePageModel) {
            this.populateCustomSysprepFileModel(scope, specInfo, specDetails);
            this.updateDependentModels(scope, scope.customSysprepFilePageModel);
         }

         if (scope.administratorPasswordPageModel) {
            this.populateAdministratorPasswordModel(scope, specInfo, specDetails);
            this.updateDependentModels(scope, scope.administratorPasswordPageModel);
         }

         if (scope.commandsPageModel) {
            this.populateCommandsModel(scope, specInfo, specDetails);
            this.updateDependentModels(scope, scope.commandsPageModel);
         }

         if (scope.osOptionsPageModel) {
            this.populateOsOptionsModel(scope, specInfo, specDetails);
            this.updateDependentModels(scope, scope.osOptionsPageModel);
         }

         if (scope.workgroupDomainPageModel) {
            this.populateWorkgroupDomainModel(scope, specInfo, specDetails);
            this.updateDependentModels(scope, scope.workgroupDomainPageModel);
         }
      }

      public updateDependentModels(scope: any, pageModel: GosBasePageModel): void {
         if (!pageModel) {
            return;
         }

         if (pageModel instanceof GosNameAndOsPageModel) {
            this.nameAndOsModelChanged(scope);
         } else if (pageModel instanceof GosComputerNamePageModel) {
            this.computerNameModelChanged(scope);
         } else if (pageModel instanceof GosRegistrationInformationPageModel) {
            this.registrationInformationModelChanged(scope);
         } else if (pageModel instanceof GosWindowsLicensePageModel) {
            this.windowsLicenseModelChanged(scope);
         } else if (pageModel instanceof GosTimeZonePageModel) {
            this.timeZoneModelChanged(scope);
         } else if (pageModel instanceof GosDnsSettingsPageModel) {
            this.dnsSettingsModelChanged(scope);
         } else if (pageModel instanceof GosNetworkPageModel) {
            this.networkPageModelChanged(scope);
         } else if (pageModel instanceof GosAdministratorPasswordPageModel) {
            this.administratorPassModelChanged(scope);
         } else if (pageModel instanceof GosCommandsPageModel) {
            this.commandsModelChanged(scope);
         } else if (pageModel instanceof GosCustomSysprepFilePageModel) {
            this.customSysprepFileModelChanged(scope);
         } else if (pageModel instanceof GosWorkgroupDomainPageModel) {
            this.workgroupDomainModelChanged(scope);
         } else {
            throw new Error("Unknown page model type");
         }
      }

      private populateNameAndOsModel(scope: any, specInfo: CustomizationSpecInfoCustom,
            specDetails: Specification): void {
         const nameAndOsPageModel: GosNameAndOsPageModel = scope.gosNameAndOsPageModel;

         nameAndOsPageModel.gosType = this.gosCustomizationHelperService
               .getGosType(specDetails.identity);
         nameAndOsPageModel.specName = specInfo.name;
         nameAndOsPageModel.description = specInfo.description;
         nameAndOsPageModel.vc = specInfo.vcService;
         nameAndOsPageModel.isEditMode = true;
         if (nameAndOsPageModel.gosType !== GosType.LINUX) {
            nameAndOsPageModel.isSidGenerated = (specDetails.options as WinOptions).changeSID;
         } else {
            nameAndOsPageModel.isSidGenerated = true;
         }
      }

      private populateRegistrationInformationModel(scope: any, specDetails: Specification): void {
         const registrationInformationModel: GosRegistrationInformationPageModel =
               scope.registrationInformationPageModel;

         let isSysPrep: boolean = this.gosCustomizationHelperService.isSysPrep(specDetails);
         if (!specDetails || !isSysPrep) {
            return;
         }

         let userData: UserData = (specDetails.identity as Sysprep).userData;
         registrationInformationModel.ownerName = userData.fullName;
         registrationInformationModel.organizationName = userData.orgName;
      }

      private nameAndOsModelChanged(scope: any): void {
         const sourceModel: GosNameAndOsPageModel = scope.gosNameAndOsPageModel;
         const targetModels = {
            customSysprepFilePageModel: scope.customSysprepFilePageModel as GosCustomSysprepFilePageModel,
            computerName: scope.computerNamePageModel as GosComputerNamePageModel,
            timeZone: scope.timeZonePageModel as GosTimeZonePageModel,
            network: scope.networkPageModel as GosNetworkPageModel,
            finish: scope.finishPageModel as GosFinishPageModel
         };

         if (!sourceModel.vc || !sourceModel.vc.serviceGuid) {
            throw new Error("sourceModel.vc.serviceGuid is not set ");
         }

         // Update Custom sysprep file model
         if (!!targetModels.customSysprepFilePageModel) {
            targetModels.customSysprepFilePageModel.vcServerGuid =
                  sourceModel.vc.serviceGuid;
         }

         // Update Computer Name model
         if (!!targetModels.computerName) {
            targetModels.computerName.vcServiceGuid = sourceModel.vc.serviceGuid;
            targetModels.computerName.type = sourceModel.gosType;
         }

         // Update Network page model
         if (!!targetModels.network) {
            targetModels.network.vcServiceGuid = sourceModel.vc.serviceGuid;
            targetModels.network.type = sourceModel.gosType;
         }

         // Update Time Zone model
         if (!!targetModels.timeZone) {
            if (!sourceModel.vc) {
               throw new Error("VC info is not defined");
            }
            targetModels.timeZone.customizationSpecManagerUrn =
                  this.defaultUriSchemeUtil.getVsphereObjectId(
                        sourceModel.vc.content.customizationSpecManager);
            targetModels.timeZone.type = sourceModel.gosType;
         }

         // Update finish page model
         targetModels.finish.specName = sourceModel.specName;
         targetModels.finish.description = sourceModel.description;
         targetModels.finish.customizationSpecManager = sourceModel.vc.content.customizationSpecManager;

         if (!targetModels.finish.specDetails) {
            targetModels.finish.specDetails = new Specification();
         }

         if (sourceModel.gosType !== GosType.LINUX) {
            targetModels.finish.specDetails.options = new WinOptions();
            (targetModels.finish.specDetails.options as WinOptions).changeSID =
                  sourceModel.isSidGenerated;
         }

         if (targetModels.finish.gosType !== sourceModel.gosType) {
            targetModels.finish.gosType = sourceModel.gosType;

            switch (sourceModel.gosType) {
               case GosType.LINUX:
                  let linuxPrep: LinuxPrep = new LinuxPrep();
                  targetModels.finish.specDetails.options = new LinuxOptions();
                  targetModels.finish.specDetails.identity = linuxPrep;
                  break;
               case GosType.WINDOWS:
                  let sysPrep: Sysprep = new Sysprep();
                  sysPrep.userData = new UserData();
                  sysPrep.guiUnattended = new GuiUnattended();
                  targetModels.finish.specDetails.identity = sysPrep;
                  break;
               case GosType.WINDOWS_CUSTOM_SYSPREP:
                  targetModels.finish.specDetails.identity = new SysprepText();
                  break;
               default:
                  throw new Error("Invalid GOS type");
            }
         }
      }

      private populateComputerNameModel(scope: any,
            specInfo: CustomizationSpecInfoCustom,
            specDetails: Specification): void {
         const computerNamePageModel: GosComputerNamePageModel =
               scope.computerNamePageModel;

         let nameGenerator: NameGenerator =
               this.gosCustomizationHelperService.getNameGenerator(specDetails);

         computerNamePageModel.vcServiceGuid = specInfo.vcService.serviceGuid;

         computerNamePageModel.computerName = "";
         computerNamePageModel.isAppendNumericValue = false;
         computerNamePageModel.generateNameArgument = "";
         switch (nameGenerator._type) {
            case GosCustomizationHelperService.COMPUTER_NAME_TYPE_FIXED_NAME:
               computerNamePageModel.setNameOption = ComputerNameOption.ENTER_NAME;
               computerNamePageModel.computerName = (nameGenerator as FixedName).name;
               break;
            case GosCustomizationHelperService.COMPUTER_NAME_TYPE_PREFIX_NAME_GENERATOR:
               computerNamePageModel.setNameOption = ComputerNameOption.ENTER_NAME;
               computerNamePageModel.computerName =
                     (nameGenerator as PrefixNameGenerator).base;
               computerNamePageModel.isAppendNumericValue = true;
               break;
            case GosCustomizationHelperService.COMPUTER_NAME_TYPE_VIRTUAL_MACHINE_NAME_GENERATOR:
               computerNamePageModel.setNameOption = ComputerNameOption.USE_VM_NAME;
               break;
            case GosCustomizationHelperService.COMPUTER_NAME_TYPE_CUSTOM_NAME_GENERATOR:
               computerNamePageModel.setNameOption = ComputerNameOption.GENERATE_NAME;
               break;
            case GosCustomizationHelperService.COMPUTER_NAME_TYPE_UNKNOWN_NAME_GENERATOR:
               computerNamePageModel.setNameOption = ComputerNameOption.DEPLOY_WIZARD;
               computerNamePageModel.generateNameArgument =
                     (nameGenerator as CustomNameGenerator).argument;
               break;
            default:
               throw new Error("Unknown computer name option");
         }

         computerNamePageModel.domainName = "";
         if (specDetails.identity._type === new LinuxPrep()._type) {
            computerNamePageModel.domainName =
                  (specDetails.identity as LinuxPrep).domain;
         }
      }

      private computerNameModelChanged(scope: any): void {
         const sourceModel: GosComputerNamePageModel = scope.computerNamePageModel;
         const targetModels = {
            network: scope.networkPageModel as GosNetworkPageModel,
            finish: scope.finishPageModel as GosFinishPageModel
         };

         if (!!targetModels.network) {
            targetModels.network.isNameIpGeneratorDefined = sourceModel.isNameIpGeneratorDefined;
         }

         let nameGenerator: NameGenerator;

         switch (sourceModel.setNameOption) {
            case ComputerNameOption.ENTER_NAME:
               if (!sourceModel.isAppendNumericValue) {
                  nameGenerator = new FixedName();
                  (nameGenerator as FixedName).name = sourceModel.computerName;
               } else {
                  nameGenerator = new PrefixNameGenerator();
                  (nameGenerator as PrefixNameGenerator).base = sourceModel.computerName;
               }
               break;
            case ComputerNameOption.USE_VM_NAME:
               nameGenerator = new VirtualMachineNameGenerator();
               break;
            case ComputerNameOption.GENERATE_NAME:
               nameGenerator = new CustomNameGenerator();
               (nameGenerator as CustomNameGenerator).argument =
                     sourceModel.generateNameArgument;
               break;
            case ComputerNameOption.DEPLOY_WIZARD:
               nameGenerator = new UnknownNameGenerator();
               break;
            default:
               throw new Error("Unknown computer name option");
         }

         if (targetModels.finish.specDetails.identity instanceof LinuxPrep) {
            (targetModels.finish.specDetails.identity as LinuxPrep).hostName =
                  nameGenerator;
         } else if (targetModels.finish.specDetails.identity instanceof Sysprep) {
            (targetModels.finish.specDetails.identity as Sysprep).userData.computerName =
                  nameGenerator;
         }

         // Domain
         (targetModels.finish.specDetails.identity as LinuxPrep).domain =
               sourceModel.domainName;
      }

      private registrationInformationModelChanged(scope: any): void {
         const sourceModel: GosRegistrationInformationPageModel = scope.registrationInformationPageModel;
         const targetModels = {
            finish: scope.finishPageModel as GosFinishPageModel
         };

         if (targetModels.finish.specDetails.identity instanceof Sysprep) {
            (targetModels.finish.specDetails.identity as Sysprep).userData.fullName = sourceModel.ownerName;
            (targetModels.finish.specDetails.identity as Sysprep).userData.orgName = sourceModel.organizationName;
         }
      }

      private populateWindowsLicenseModel(scope: any,
            specInfo: CustomizationSpecInfoCustom,
            specDetails: Specification) {
         let model: GosWindowsLicensePageModel = scope.windowsLicensePageModel;

         let userData: UserData = (specDetails.identity as Sysprep).userData;
         model.productKey = !_.isEmpty(userData.productId) ? userData.productId : "";

         model.isIncludeServerLicenseInfo =
               this.gosCustomizationHelperService.isServerLicensingIncluded(specDetails);

         if (model.isIncludeServerLicenseInfo) {
            let licenseData: LicenseFilePrintData =
                  (specDetails.identity as Sysprep).licenseFilePrintData;
            model.isServerLicenseModePerServer =
                  (("perServer" as LicenseFilePrintData$AutoMode) === licenseData.autoMode);
            model.maxConnections = licenseData.autoUsers;
         } else {
            model.isServerLicenseModePerServer = true;
            model.maxConnections = GosSpecModelManagerService.AUTO_USERS_MIN;
         }
      }

      private windowsLicenseModelChanged(scope: any): void {
         const sourceModel: GosWindowsLicensePageModel = scope.windowsLicensePageModel;
         const targetModels = {
            finish: scope.finishPageModel as GosFinishPageModel
         };

         let sysPrep: Sysprep = (targetModels.finish.specDetails.identity as Sysprep);
         sysPrep.userData.productId = sourceModel.productKey;

         if (sourceModel.isIncludeServerLicenseInfo) {
            let licenseData: LicenseFilePrintData = new LicenseFilePrintData();

            licenseData.autoMode = sourceModel.isServerLicenseModePerServer ?
                  ("perServer" as LicenseFilePrintData$AutoMode) :
                  ("perSeat" as LicenseFilePrintData$AutoMode);

            licenseData.autoUsers = sourceModel.maxConnections;

            sysPrep.licenseFilePrintData = licenseData;
         } else {
            delete sysPrep.licenseFilePrintData;
         }
      }

      private populateTimeZoneModel(scope: any,
            specInfo: CustomizationSpecInfoCustom,
            specDetails: Specification): void {
         const timeZonePageModel: GosTimeZonePageModel = scope.timeZonePageModel;

         if (specDetails.identity._type === new LinuxPrep()._type) {
            timeZonePageModel.hwClockUTC = (specDetails.identity as LinuxPrep).hwClockUTC;
            const timeZone = (specDetails.identity as LinuxPrep).timeZone;
            if (timeZone) {
               let data: string[] = (specDetails.identity as LinuxPrep).timeZone
                     .split(GosCustomizationHelperService.AREA_LOCATION_SEPARATOR);
               timeZonePageModel.timeZoneArea = data[0];
               // remove the Area part
               data.shift();
               // join back the location part - /Argentina/Buenos_Aires
               let location: string = data.join(GosCustomizationHelperService.AREA_LOCATION_SEPARATOR);
               timeZonePageModel.timeZoneLocation = location;
            }
         } else if (specDetails.identity._type === new Sysprep()._type) {
            timeZonePageModel.timeZoneWinCode = (specDetails.identity as Sysprep)
                  .guiUnattended.timeZone;
         }
      }

      private timeZoneModelChanged(scope: any): void {
         const sourceModel: GosTimeZonePageModel = scope.timeZonePageModel;
         const targetModels = {
            finish: scope.finishPageModel as GosFinishPageModel
         };

         if (targetModels.finish.specDetails.identity instanceof LinuxPrep) {
            const identity: LinuxPrep = targetModels.finish.specDetails.identity;
            if (sourceModel.timeZoneArea && sourceModel.timeZoneLocation) {
               identity.timeZone = sourceModel.timeZoneArea +
                     GosCustomizationHelperService.AREA_LOCATION_SEPARATOR +
                     sourceModel.timeZoneLocation;
            }
            identity.hwClockUTC = sourceModel.hwClockUTC;
         } else if (targetModels.finish.specDetails.identity instanceof Sysprep) {
            const identity: Sysprep = targetModels.finish.specDetails.identity;
            identity.guiUnattended.timeZone = sourceModel.timeZoneWinCode;
         } else {
            throw new Error("Invalid identity type of GOS customization specification");
         }
      }

      private populateDnsSettingsModel(scope: any,
            specInfo: CustomizationSpecInfoCustom,
            specDetails: Specification) {
         let model: GosDnsSettingsPageModel = scope.dnsSettingsPageModel;

         model.primaryDns =
               this.gosCustomizationHelperService.getPrimaryDnsServer(specDetails);
         model.secondaryDns =
               this.gosCustomizationHelperService.getSecondaryDnsServer(specDetails);
         model.tertiaryDns =
               this.gosCustomizationHelperService.getTertiaryDnsServer(specDetails);

         model.dnsSearchPath =
               angular.copy(this.gosCustomizationHelperService.getDnsSuffixList(specDetails));
      }

      private dnsSettingsModelChanged(scope: any): void {
         const sourceModel: GosDnsSettingsPageModel = scope.dnsSettingsPageModel;
         const targetModels = {
            finish: scope.finishPageModel as GosFinishPageModel
         };

         targetModels.finish.specDetails.globalIPSettings = new GlobalIPSettings();
         let dns: string;
         // DNS servers
         let dnsServers: string[] = [];
         if (sourceModel.primaryDns !== "") {
            dns = sourceModel.primaryDns;
            if (this.ipParserService.isIpv4AddressValid(dns)) {
               dns = ipaddr.parse(dns).toString();
            }
            dnsServers.push(dns);
         }
         if (sourceModel.secondaryDns !== "") {
            dns = sourceModel.secondaryDns;
            if (this.ipParserService.isIpv4AddressValid(dns)) {
               dns = ipaddr.parse(dns).toString();
            }
            dnsServers.push(dns);
         }
         if (sourceModel.tertiaryDns !== "") {
            dns = sourceModel.tertiaryDns;
            if (this.ipParserService.isIpv4AddressValid(dns)) {
               dns = ipaddr.parse(dns).toString();
            }
            dnsServers.push(dns);
         }
         targetModels.finish.specDetails.globalIPSettings.dnsServerList = dnsServers;

         // DNS suffixes
         targetModels.finish.specDetails.globalIPSettings.dnsSuffixList = sourceModel.dnsSearchPath;
      }

      private populateNetworkPageModel(scope: any,
            specDetails: Specification): void {
         const networkPageModel: GosNetworkPageModel =
               scope.networkPageModel;

         if (_.isNull(specDetails.globalIPSettings.dnsSuffixList)) {
            specDetails.globalIPSettings.dnsSuffixList = [];
         }

         networkPageModel.dnsSuffixList = specDetails.globalIPSettings.dnsSuffixList;
         networkPageModel.networkSettingsType =
               this.gosSpecNetworkService.isNetworkTypeStandard(specDetails) ?
                     GosNetworkSettingsType.STANDARD :
                     GosNetworkSettingsType.CUSTOM;
         if (GosNetworkSettingsType.CUSTOM
               === networkPageModel.networkSettingsType) {
            networkPageModel.adapterMappingWrappers = this.gosSpecNetworkService
                  .createAdapterMappingWrappersFromSpecification(specDetails);
         } else {
            networkPageModel.adapterMappingWrappers =
                  this.gosSpecNetworkService.getInitialNetworkCards();
         }
      }

      private networkPageModelChanged(scope: any): void {
         const sourceModel: GosNetworkPageModel = scope.networkPageModel;
         const targetModels = {
            computerName: scope.computerNamePageModel as GosComputerNamePageModel,
            finish: scope.finishPageModel as GosFinishPageModel
         };

         if (!!targetModels.computerName) {
            targetModels.computerName.isNameIpGeneratorDefined = sourceModel.isNameIpGeneratorDefined;
         }

         if (!targetModels.finish.specDetails) {
            targetModels.finish.specDetails = new Specification();
         }
         if (!targetModels.finish.specDetails.globalIPSettings) {
            targetModels.finish.specDetails.globalIPSettings = new GlobalIPSettings();
         }

         targetModels.finish.specDetails.nicSettingMap = [];

         if (GosNetworkSettingsType.STANDARD
               === sourceModel.networkSettingsType) {
            targetModels.finish.specDetails.nicSettingMap.push(
                  this.gosSpecNetworkService
                        .createNewAdapterMappingWrapper("").adapterMapping);
            return;
         }

         for (let adapterWrapper of sourceModel.adapterMappingWrappers) {
            targetModels.finish.specDetails.nicSettingMap
                  .push(adapterWrapper.adapterMapping);
         }
         targetModels.finish.specDetails.globalIPSettings.dnsSuffixList = sourceModel.dnsSuffixList;
      }

      private populateAdministratorPasswordModel(scope: any,
            specInfo: CustomizationSpecInfoCustom, specDetails: Specification) {
         const model: GosAdministratorPasswordPageModel =
               scope.administratorPasswordPageModel;

         let identity: Sysprep = (specDetails.identity as Sysprep);
         model.password = identity.guiUnattended.password ? identity.guiUnattended.password.value : "";
         model.isPreviousPasswordBlank = model.password === "";
         model.confirmedPassword = model.password;
         model.autoLogon = identity.guiUnattended.autoLogon;
         model.logonTimes = identity.guiUnattended.autoLogonCount;

      }

      private populateCustomSysprepFileModel(scope: any,
            specInfo: CustomizationSpecInfoCustom, specDetails: Specification) {
         const model: GosCustomSysprepFilePageModel = scope.customSysprepFilePageModel;

         const sysprep: SysprepText = specDetails.identity as SysprepText;

         if (sysprep.value) {
            model.fileOption = CustomSysprepFileOption.CREATE_FILE;
            model.sysprepContent = sysprep.value;
         }
      }

      private populateCommandsModel(scope: any,
            specInfo: CustomizationSpecInfoCustom, specDetails: Specification) {
         const model: GosCommandsPageModel = scope.commandsPageModel;
         let identity: Sysprep = (specDetails.identity as Sysprep);

         if (identity.guiRunOnce && identity.guiRunOnce.commandList) {
            model.commands = angular.copy(identity.guiRunOnce.commandList);
         } else {
            model.commands = [];
         }
      }

      private populateOsOptionsModel(scope: any,
            specInfo: CustomizationSpecInfoCustom, specDetails: Specification) {
      }

      private populateWorkgroupDomainModel(scope: any,
            specInfo: CustomizationSpecInfoCustom, specDetails: Specification) {
         const model: GosWorkgroupDomainPageModel = scope.workgroupDomainPageModel;
         let identity: Sysprep = (specDetails.identity as Sysprep);

         if (!_.isEmpty(identity.identification.joinWorkgroup)) {
            model.networkOption = WorkgroupDomainTypeOption.WORKGROUP;
            model.workgroup = identity.identification.joinWorkgroup;
            model.domain = this.DOMAIN_DEFAULT;
         } else {
            model.networkOption = WorkgroupDomainTypeOption.DOMAIN;
            model.workgroup = this.WORKGROUP_DEFAULT;
            model.domain = identity.identification.joinDomain;
            model.username = identity.identification.domainAdmin;
            let domainPassword: Password = identity.identification.domainAdminPassword;
            model.password = _.isEmpty(domainPassword) ? "" : domainPassword.value;
            model.confirmPassword = _.isEmpty(domainPassword) ? "" : domainPassword.value;
         }
      }

      private administratorPassModelChanged(scope: any) {
         const sourceModels = {
            administratorPasswordPageModel: scope.administratorPasswordPageModel as GosAdministratorPasswordPageModel,
            nameAndOsPageModel: scope.gosNameAndOsPageModel as GosNameAndOsPageModel
         };
         const targetModels = {
            finish: scope.finishPageModel as GosFinishPageModel
         };

         if (sourceModels.nameAndOsPageModel.gosType === GosType.WINDOWS) {
            let identity = (targetModels.finish.specDetails.identity as Sysprep);
            let newPassword: string = sourceModels.administratorPasswordPageModel.password;
            if (newPassword && !_.isEmpty(newPassword)) {
               let specPassword = new Password();
               specPassword.plainText = false;
               specPassword.value = newPassword;
               targetModels.finish.encryptAdminPassword =
                     sourceModels.administratorPasswordPageModel.isPasswordChanged;
               identity.guiUnattended.password = specPassword;
            } else {
               if (identity.guiUnattended.password) {
                  delete identity.guiUnattended.password;
               }
            }
            identity.guiUnattended.autoLogon = sourceModels.administratorPasswordPageModel.autoLogon;
            identity.guiUnattended.autoLogonCount = sourceModels.administratorPasswordPageModel.logonTimes;
         }
      }

      private commandsModelChanged(scope: any) {
         const sourceModel = scope.commandsPageModel as GosCommandsPageModel;
         const targetModels = {
            finish: scope.finishPageModel as GosFinishPageModel
         };

         let identity = (targetModels.finish.specDetails.identity as Sysprep);
         if (sourceModel.commands && sourceModel.commands.length) {
            identity.guiRunOnce = new GuiRunOnce();
            identity.guiRunOnce.commandList = sourceModel.commands;
            return;
         }

         if (identity.guiRunOnce) {
            delete identity.guiRunOnce;
         }
      }

      private customSysprepFileModelChanged(scope: any) {
         const sourceModel = scope.customSysprepFilePageModel as GosCustomSysprepFilePageModel;
         const targetModels = {
            finish: scope.finishPageModel as GosFinishPageModel
         };
         let sysprep = targetModels.finish.specDetails.identity as SysprepText;
         sysprep.value = sourceModel.fileOption === CustomSysprepFileOption.CREATE_FILE ?
               sourceModel.sysprepContent : sourceModel.sysprepImportFileContent;
      }

      private workgroupDomainModelChanged(scope: any) {
         const sourceModel = scope.workgroupDomainPageModel as GosWorkgroupDomainPageModel;
         const targetModels = {
            finish: scope.finishPageModel as GosFinishPageModel
         };

         let identity = (targetModels.finish.specDetails.identity as Sysprep);
         identity.identification = new Identification();
         if (sourceModel.networkOption === WorkgroupDomainTypeOption.WORKGROUP) {
            identity.identification.joinWorkgroup = sourceModel.workgroup;
         } else {
            identity.identification.joinDomain = sourceModel.domain;
            identity.identification.domainAdmin = sourceModel.username;
            if (sourceModel.password) {
               identity.identification.domainAdminPassword = new Password();
               identity.identification.domainAdminPassword.value = sourceModel.password;
               identity.identification.domainAdminPassword.plainText = false;
               targetModels.finish.encryptDomainPassword = sourceModel.isDomainPasswordChanged;
            }
         }
      }
   }

   angular.module("com.vmware.vsphere.client.vm")
         .service("gosSpecModelManagerService", GosSpecModelManagerService);
}

