(function () {
   'use strict';

   angular.module('com.vmware.vsphere.client.cluster')
      .service('haVmReactionsOnApdClearedService', haVmReactionsOnApdClearedService);

   haVmReactionsOnApdClearedService.$inject = ['i18nService', 'haConstants'];

   function haVmReactionsOnApdClearedService(i18nService, haConstants) {

      var vmReactionLabels = {};
      var possibleVmReactions = [
         haConstants.vmReactionOnApdCleared.NONE,
         haConstants.vmReactionOnApdCleared.RESET
      ];

      vmReactionLabels[haConstants.vmReactionOnApdCleared.NONE] =
         getString('ha.config.vmResponse.vmRemediationIfApdRecovers.doNotResetVm');
      vmReactionLabels[haConstants.vmReactionOnApdCleared.RESET] =
         getString('ha.config.vmResponse.vmRemediationIfApdRecovers.resetVm');

      function getString(key) {
         return i18nService.getString('ClusterUi', key);
      }

      function getReactions() {
         return _.map(possibleVmReactions, function(reaction) {
            return {
               id: reaction,
               label: vmReactionLabels[reaction]
            };
         });
      }

      return {
         getReactions: getReactions
      };
   }
})();
