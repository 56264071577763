/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */

import HostListItemData = com.vmware.vsphere.client.dvs.api.host.HostListItemData;
import DvsAddHostHierarchicalListHostItem = com.vmware.vsphere.client.h5.network.dvs.addhost.hierarchicalList.DvsAddHostHierarchicalListHostItem;
import DvsAddHostHierarchicalListPnicItem = com.vmware.vsphere.client.h5.network.dvs.addhost.hierarchicalList.DvsAddHostHierarchicalListPnicItem;
import DvsAddHostHierarchicalListVnicItem = com.vmware.vsphere.client.h5.network.dvs.addhost.hierarchicalList.DvsAddHostHierarchicalListVnicItem;

namespace dvs_ui {

   class SwitchConfig {
      pnics: string[] = [];
      vnics: string[] = [];
   }

   class HostConfig {
      name: string;
      isManagementOnOpaqueSwitch: boolean;
      standardSwitches: {[switchName: string]: SwitchConfig};
      distributedSwitches: {[switchName: string]: SwitchConfig};
   }

   export class DvsSelectVirtualAdaptersPageValidator {

      /**
       * A special value representing the name of this switch (the context switch).
       * We don't have the actual name here and we don't care what it actually is as
       * long as we can pick a value which does not coincide with some other switch name.
       * Empty string is invalid for a switch name so it is a safe pick.
       */
      private readonly THIS_SWITCH_NAME: string = "";

      public static $inject = ["i18nService",
         "dvsSelectPhysicalAdaptersPageService",
         "dvsSelectVirtualAdaptersPageService",
         "defaultUriSchemeUtil"];

      constructor(private i18nService: any,
                  private dvsSelectPhysicalAdaptersPageService: DvsSelectPhysicalAdaptersPageService,
                  private dvsSelectVirtualAdaptersPageService: DvsSelectVirtualAdaptersPageService,
                  private defaultUriSchemeUtil: any) {
      }

      public getValidationError(hosts: DvsAddHostTreeListItemWrapper[],
                                pnics: DvsAddHostTreeListItemWrapper[],
                                vnics: DvsAddHostTreeListItemWrapper[]): string[] {
         hosts = _.filter(hosts, (itemWrapper: DvsAddHostTreeListItemWrapper): boolean =>
               (itemWrapper.type === DvsAddHostWizardConstants.nicListItemType.HOST_TYPE));
         pnics = _.filter(pnics, (itemWrapper: DvsAddHostTreeListItemWrapper): boolean =>
               (itemWrapper.type === DvsAddHostWizardConstants.nicListItemType.PNIC_TYPE));

         let hostConfigMap: {[hostUrn: string]: HostConfig} = {};
         this.addHosts(hostConfigMap, hosts);
         this.addPnics(hostConfigMap, pnics);
         this.addVnics(hostConfigMap, vnics);

         let hostWithError: HostConfig =
               _.find(_.values(hostConfigMap), (hostConfig: HostConfig): boolean => {
                  let switchForMgmt: SwitchConfig =
                        _.find(_.values(hostConfig.standardSwitches)
                                    .concat(_.values(hostConfig.distributedSwitches)),
                              (standardSwitchConfig: SwitchConfig): boolean => {
                                 return standardSwitchConfig.vnics &&
                                       standardSwitchConfig.vnics.length > 0 &&
                                       standardSwitchConfig.pnics &&
                                       standardSwitchConfig.pnics.length > 0;
                              });
                  return !switchForMgmt && !hostConfig.isManagementOnOpaqueSwitch;
               });

         let errors: string[] = [];
         if (hostWithError) {
            errors.push(this.i18nService.getString("DvsUi",
                  "SelectVirtualAdaptersPage.errorForNetworkConnectivityLoss",
                  hostWithError.name));
         }

         return errors;
      }

      public getVnicsWithConnectivityWarning(hosts: DvsAddHostTreeListItemWrapper[],
                                             pnics: DvsAddHostTreeListItemWrapper[],
                                             vnics: DvsAddHostTreeListItemWrapper[]): DvsAddHostTreeListItemWrapper[] {
         hosts = _.filter(hosts, (itemWrapper: DvsAddHostTreeListItemWrapper): boolean =>
               (itemWrapper.type === DvsAddHostWizardConstants.nicListItemType.HOST_TYPE));
         pnics = _.filter(pnics, (itemWrapper: DvsAddHostTreeListItemWrapper): boolean =>
               (itemWrapper.type === DvsAddHostWizardConstants.nicListItemType.PNIC_TYPE));

         let hostConfigMap: {[hostUrn: string]: HostConfig} = {};
         this.addHosts(hostConfigMap, hosts);
         this.addPnics(hostConfigMap, pnics);

         let originalHostConfigMap: {[hostUrn: string]: HostConfig} = {};
         this.addHosts(originalHostConfigMap, hosts);
         this.addOriginalPnics(originalHostConfigMap, pnics);

         let notAssignedVnics = _.filter(vnics,
               (itemWrapper: DvsAddHostTreeListItemWrapper): boolean =>
                     (itemWrapper.type === DvsAddHostWizardConstants.nicListItemType.VNIC_TYPE
                     && !this.dvsSelectVirtualAdaptersPageService.isVnicAssigned(itemWrapper)));
         return _.filter(notAssignedVnics, (itemWrapper: DvsAddHostTreeListItemWrapper): boolean => {
            let item: DvsAddHostHierarchicalListItem =
                  itemWrapper as DvsAddHostHierarchicalListItem;
            let vnicItem: DvsAddHostHierarchicalListVnicItem =
                  item as DvsAddHostHierarchicalListVnicItem;
            let switchConfig = this.getSwitchConfigForVnic(hostConfigMap, vnicItem);
            let originalSwitchConfig = this.getSwitchConfigForVnic(originalHostConfigMap, vnicItem);
            return switchConfig.pnics.length === 0 && originalSwitchConfig.pnics.length > 0;
         });
      }

      private addHosts(hostConfigMap: {[hostUrn: string]: HostConfig},
                       hosts: DvsAddHostTreeListItemWrapper[]): void {
         _.each(hosts, (itemWrapper: DvsAddHostTreeListItemWrapper) => {
            let item: DvsAddHostHierarchicalListItem =
                  itemWrapper as DvsAddHostHierarchicalListItem;
            let hostItem: DvsAddHostHierarchicalListHostItem =
                  item as DvsAddHostHierarchicalListHostItem;
            let hostConfig: HostConfig = new HostConfig();
            hostConfig.name = hostItem.label;
            hostConfig.standardSwitches = {};
            hostConfig.distributedSwitches = {};
            hostConfigMap[this.defaultUriSchemeUtil.getVsphereObjectId(hostItem.hostRef)] =
                  hostConfig;
         });
      }

      private addOriginalPnics(hostConfigMap: {[hostUrn: string]: HostConfig},
                               pnics: DvsAddHostTreeListItemWrapper[]): void {

         _.each(_.map(pnics, (pnic: DvsAddHostTreeListItemWrapper) => pnic.originalItem),
               (originalPnicItem: DvsAddHostHierarchicalListItem) =>
                     this.addPnicItem(hostConfigMap, originalPnicItem));
      }

      private addPnics(hostConfigMap: {[hostUrn: string]: HostConfig},
                       pnics: DvsAddHostTreeListItemWrapper[]): void {
         let notUnassginedPnics = _.filter(pnics, (itemWrapper: DvsAddHostTreeListItemWrapper) =>
               !this.dvsSelectPhysicalAdaptersPageService.isPnicUnassigned(itemWrapper));
         _.each(notUnassginedPnics, (pnic: DvsAddHostHierarchicalListItem) => this.addPnicItem(hostConfigMap, pnic));
      }

      private addPnicItem(hostConfigMap: {[hostUrn: string]: HostConfig}, item: DvsAddHostHierarchicalListItem): void {
         let pnicItem: DvsAddHostHierarchicalListPnicItem = item as DvsAddHostHierarchicalListPnicItem;
         let hostConfig: HostConfig =
               hostConfigMap[this.defaultUriSchemeUtil.getVsphereObjectId(pnicItem.hostRef)];
         let switchesMap: {[switchName: string]: SwitchConfig} =
               this.dvsSelectPhysicalAdaptersPageService.isPnicOnThisSwitch(pnicItem)
               || !pnicItem.isStandardSwitch
                     ? hostConfig.distributedSwitches : hostConfig.standardSwitches;
         let switchName: string | null = null;
         if (this.dvsSelectPhysicalAdaptersPageService.isPnicOnThisSwitch(pnicItem)) {
            switchName = this.THIS_SWITCH_NAME;
         } else if (pnicItem.isAssigned) {
            // inUseBySwitch cannot be empty and cannot be this switch.
            switchName = pnicItem.inUseBySwitch;
         }

         if (switchName !== null) {
            let switchConfig: SwitchConfig =
                  this.getOrCreateSwitchConfig(switchesMap, switchName);

            switchConfig.pnics.push(pnicItem.device);
         }
      }

      private addVnics(hostConfigMap: {[hostUrn: string]: HostConfig},
                       vnics: DvsAddHostHierarchicalListItem[]): void {
         _.each(vnics, (item: DvsAddHostHierarchicalListItem) => {
            if (item.type === DvsAddHostWizardConstants.nicListItemType.VNIC_TYPE) {
               let vnicItem: DvsAddHostHierarchicalListVnicItem =
                     item as DvsAddHostHierarchicalListVnicItem;
               if (vnicItem.isOnManagementStack) {
                  if (vnicItem.isOpaqueSwitch) {
                     let hostConfig: HostConfig =
                           hostConfigMap[this.defaultUriSchemeUtil.getVsphereObjectId(vnicItem.hostRef)];
                     hostConfig.isManagementOnOpaqueSwitch = true;
                  } else {
                     // Ignore vnics on other stacks as they cannot be used for management
                     // traffic.
                     let switchConfig: SwitchConfig = this.getSwitchConfigForVnic(hostConfigMap, vnicItem);
                     switchConfig.vnics.push(vnicItem.device);
                  }
               }
            }
         });
      }

      private getSwitchConfigForVnic(hostConfigMap: {[hostUrn: string]: HostConfig},
                                     vnicItem: DvsAddHostHierarchicalListVnicItem): SwitchConfig {
         let hostConfig: HostConfig =
               hostConfigMap[this.defaultUriSchemeUtil.getVsphereObjectId(vnicItem.hostRef)];
         let switchesMap: {[switchName: string]: SwitchConfig} =
               this.dvsSelectVirtualAdaptersPageService.isVnicAssigned(vnicItem)
               || !vnicItem.isStandardSwitch
                     ? hostConfig.distributedSwitches : hostConfig.standardSwitches;
         let switchName: string;
         if (this.dvsSelectVirtualAdaptersPageService.isVnicAssigned(vnicItem)) {
            switchName = this.THIS_SWITCH_NAME;
         } else {
            // inUseBySwitch cannot be empty and cannot be this switch.
            switchName = vnicItem.inUseBySwitch;
         }
         return this.getOrCreateSwitchConfig(switchesMap, switchName);
      }

      private getOrCreateSwitchConfig(switchesMap: {[switchName: string]: SwitchConfig},
                                      switchName: string): SwitchConfig {
         let result: SwitchConfig = switchesMap[switchName];

         if (!result) {
            result = new SwitchConfig();
            switchesMap[switchName] = result;
         }

         return result;
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .service("dvsSelectVirtualAdaptersPageValidator", DvsSelectVirtualAdaptersPageValidator);
}
