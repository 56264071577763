namespace h5_vm {

   import VirtualDiskOption$CompatibilityMode = com.vmware.vim.binding.vim.vm.device.VirtualDiskOption$CompatibilityMode;
   import VmConfigContext = com.vmware.vsphere.client.vm.config.VmConfigContext;

   export class VmHardwareVirtualDiskCompatibilityModeService {
      static $inject: string[] = [
         'i18nService',
         'vmDeviceInfoService'
      ];

      public readonly VIRTUAL_COMPATIBILITY_MODE: VirtualDiskOption$CompatibilityMode = "virtualMode";

      constructor(private i18nService: any, private vmDeviceInfoService: any) {
      }

      getCompatibilityModeInfo(vmConfigContext: VmConfigContext, inflatedDevice: any,
                               allInflatedDevices: any[]) {
         var optionsForDiskBacking = this.vmDeviceInfoService.backingOptionForDevice(vmConfigContext, inflatedDevice, allInflatedDevices);

         if (!optionsForDiskBacking.compatibilityMode) {
            return;
         }

         var options: any[] = _.map(optionsForDiskBacking.compatibilityMode.choiceInfo, (modeOption: any) => {
            return {
               name: this.i18nService.getString('VmUi', 'DiskPage.' + modeOption.key),
               value: modeOption.key
            };
         });

         var selection = _.find(options, (option: any) => {
            return option.value === inflatedDevice.getCurrentDevice().backing.compatibilityMode;
         });

         return {
            options: options,
            selection: selection
         };
      }
   }

   angular.module('com.vmware.vsphere.client.vm')
         .service('vmHardwareVirtualDiskCompatibilityModeService', VmHardwareVirtualDiskCompatibilityModeService);
}
