namespace storage_ui {

   import IRootScopeService = angular.IRootScopeService;
   import IPromise = angular.IPromise;
   import IQService = angular.IQService;

   export class DatastoreConfigIOControlDialogData {
      selectedSiocOption: string;
      selectedCongestionThresholdOption: string;
      enabledSdrsStatisticsForEnabledIoC: boolean;
      enabledSdrsStatisticsForDisabledIoC: boolean;
      manualCongestionThreshold: number;
      percentageCongestionThreshold: number;
      minManualCongestionThreshold: number;
   }

   export class DatastoreConfigIOControlDialogService {

      public static $inject = ["$q", "dataService", "clarityModalService",
         "mutationService", "i18nService", "clarityConstants"];

      public CONGESTION_THRESHOLD_DEFAULT_PERCENT_PEAK_VALUE: number = 90;
      public CONGESTION_THRESHOLD_PERCENT_MIN: number = 50;
      public CONGESTION_THRESHOLD_PERCENT_MAX: number = 100;

      public CONGESTION_THRESHOLD_DEFAULT_MANUAL_VALUE: number = 30;
      public CONGESTION_THRESHOLD_MANUAL_MIN: number = 5;
      public CONGESTION_THRESHOLD_MANUAL_MAX: number = 100;

      public siocOptions = {
         ENABLE_SOIC_ENABLED_STATISTIC: "ENABLE_SOIC_ENABLED_STATISTIC",
         DISABLE_SOIC_ENABLE_STATISTIC: "DISABLE_SOIC_ENABLE_STATISTIC",
         DISABLE_SOIC_DISABLE_STATISTICS: "DISABLE_SOIC_DISABLE_STATISTICS"
      };

      public congestionThresholdOptions = {
         PERCENT: "CONGESTION_THRESHOLD_PERCENT",
         MANUAL: "CONGESTION_THRESHOLD_MANUAL"
      };

      private readonly PROP_MINIMUM_CONGESTION_THRESHOLD: string = "capability/minimumCongestionThresholdInMs";

      constructor(private $q: IQService,
                  private dataService: any,
                  private clarityModalService: any,
                  private mutationService: any,
                  private i18nService: any,
                  private clarityConstants: any) {
      }

      public getDialogData(datastoreIds: string[]): IPromise<DatastoreConfigIOControlDialogData> {
         if (datastoreIds && datastoreIds.length) {
            let propertiesToRequest = [this.PROP_MINIMUM_CONGESTION_THRESHOLD];
            let multipleDatastores: boolean = datastoreIds.length > 1;
            if (!multipleDatastores) {
               propertiesToRequest.push("iormConfiguration");
            }
            return this.dataService.getPropertiesForObjects(datastoreIds, propertiesToRequest)
                  .then((response: any) => {
                     let iormConfig: any = this.getDefaultDialogData();
                     let thresholdManualMin: number = -1;
                     _.each(response, (data: any) => {
                        if (!multipleDatastores && data.iormConfiguration) {
                           iormConfig = data.iormConfiguration;
                        }
                        if (data[this.PROP_MINIMUM_CONGESTION_THRESHOLD]
                              && data[this.PROP_MINIMUM_CONGESTION_THRESHOLD] > thresholdManualMin) {
                           thresholdManualMin = data[this.PROP_MINIMUM_CONGESTION_THRESHOLD];
                        }
                     });

                     if (thresholdManualMin > -1) {
                        iormConfig.minManualCongestionThreshold = thresholdManualMin;
                     }

                     if (multipleDatastores) {
                        return iormConfig;
                     }

                     let dialogData = new DatastoreConfigIOControlDialogData();

                     if (iormConfig.enabled) {
                        dialogData.selectedSiocOption = this.siocOptions.ENABLE_SOIC_ENABLED_STATISTIC;
                     } else {
                        if (iormConfig.statsCollectionEnabled) {
                           dialogData.selectedSiocOption = this.siocOptions.DISABLE_SOIC_ENABLE_STATISTIC;
                        } else {
                           dialogData.selectedSiocOption = this.siocOptions.DISABLE_SOIC_DISABLE_STATISTICS;
                        }
                     }

                     dialogData.enabledSdrsStatisticsForEnabledIoC = !iormConfig.statsAggregationDisabled;
                     dialogData.enabledSdrsStatisticsForDisabledIoC = !iormConfig.statsAggregationDisabled;
                     dialogData.manualCongestionThreshold = iormConfig.congestionThreshold;
                     dialogData.percentageCongestionThreshold = iormConfig.percentOfPeakThroughput;

                     if (thresholdManualMin > -1) {
                        dialogData.minManualCongestionThreshold = thresholdManualMin;
                     } else {
                        dialogData.minManualCongestionThreshold = this.CONGESTION_THRESHOLD_MANUAL_MIN;
                     }

                     if (iormConfig.congestionThresholdMode === "manual") {
                        dialogData.selectedCongestionThresholdOption = this.congestionThresholdOptions.MANUAL;
                     } else {
                        dialogData.selectedCongestionThresholdOption = this.congestionThresholdOptions.PERCENT;
                     }

                     return dialogData;
                  });
         } else {
            return this.$q.when(this.getDefaultDialogData());
         }
      }

      public resetCongestionThresholdValues(dialogData: DatastoreConfigIOControlDialogData): void {
         dialogData.selectedCongestionThresholdOption = this.congestionThresholdOptions.PERCENT;
         dialogData.manualCongestionThreshold = this.CONGESTION_THRESHOLD_DEFAULT_MANUAL_VALUE;
         dialogData.percentageCongestionThreshold = this.CONGESTION_THRESHOLD_DEFAULT_PERCENT_PEAK_VALUE;
      }

      public getDefaultDialogData(): DatastoreConfigIOControlDialogData {
         let dialogData = new DatastoreConfigIOControlDialogData();
         dialogData.selectedSiocOption = this.siocOptions.DISABLE_SOIC_DISABLE_STATISTICS;
         dialogData.selectedCongestionThresholdOption = this.congestionThresholdOptions.PERCENT;
         dialogData.enabledSdrsStatisticsForEnabledIoC = true;
         dialogData.enabledSdrsStatisticsForDisabledIoC = true;
         dialogData.manualCongestionThreshold = this.CONGESTION_THRESHOLD_DEFAULT_MANUAL_VALUE;
         dialogData.percentageCongestionThreshold = this.CONGESTION_THRESHOLD_DEFAULT_PERCENT_PEAK_VALUE;
         dialogData.minManualCongestionThreshold = this.CONGESTION_THRESHOLD_MANUAL_MIN;
         return dialogData;
      };

      public buildIORMConfigSpec(dialogData: DatastoreConfigIOControlDialogData): any {
         let spec: any = {
            _type: "com.vmware.vim.binding.vim.StorageResourceManager$IORMConfigSpec"
         };
         switch (dialogData.selectedSiocOption) {
            case this.siocOptions.ENABLE_SOIC_ENABLED_STATISTIC:
               spec.enabled = true;
               spec.statsCollectionEnabled = true;

               if (dialogData.selectedCongestionThresholdOption === this.congestionThresholdOptions.PERCENT) {
                  spec.percentOfPeakThroughput = dialogData.percentageCongestionThreshold;
                  spec.congestionThresholdMode = "automatic";
                  spec.congestionThreshold = this.CONGESTION_THRESHOLD_DEFAULT_MANUAL_VALUE;
               } else {
                  spec.congestionThresholdMode = "manual";
                  spec.congestionThreshold = dialogData.manualCongestionThreshold;
               }

               spec.statsAggregationDisabled = !dialogData.enabledSdrsStatisticsForEnabledIoC;
               break;

            case this.siocOptions.DISABLE_SOIC_ENABLE_STATISTIC:
               spec.enabled = false;
               spec.statsCollectionEnabled = true;
               spec.statsAggregationDisabled = !dialogData.enabledSdrsStatisticsForDisabledIoC;
               break;

            case this.siocOptions.DISABLE_SOIC_DISABLE_STATISTICS:
               spec.enabled = false;
               spec.statsCollectionEnabled = false;
               spec.statsAggregationDisabled = true;
               break;
            default:
               return null;
         }

         return spec;
      }

      public validateDatastoreConfigIOControlDialogData(dialogData: DatastoreConfigIOControlDialogData, modalOptions: ModalOptions): boolean {

         let warnings = _.filter(modalOptions.alerts, (item: any) => {
            return item.type === this.clarityConstants.notifications.type.WARNING;
         });

         if (dialogData.selectedSiocOption === this.siocOptions.ENABLE_SOIC_ENABLED_STATISTIC) {
            if (dialogData.selectedCongestionThresholdOption === this.congestionThresholdOptions.PERCENT) {
               if (!this.numberInRange(dialogData.percentageCongestionThreshold,
                           this.CONGESTION_THRESHOLD_PERCENT_MIN, this.CONGESTION_THRESHOLD_PERCENT_MAX)) {
                  modalOptions.alerts = [{
                     text: this.percentThresholdValidationError(),
                     type: this.clarityConstants.notifications.type.ERROR
                  }].concat(warnings);
                  return false;
               }
            } else {
               if (!this.numberInRange(dialogData.manualCongestionThreshold,
                           dialogData.minManualCongestionThreshold, this.CONGESTION_THRESHOLD_MANUAL_MAX)) {
                  modalOptions.alerts = [{
                     text: this.manualThresholdValidationError(dialogData.minManualCongestionThreshold),
                     type: this.clarityConstants.notifications.type.ERROR
                  }].concat(warnings);
                  return false;
               }
            }
         }

         modalOptions.alerts = warnings;
         return true;
      }

      private numberInRange(value: number, min: number, max: number): boolean {
         return value >= min && value <= max;
      }

      public show(datastoreIds: string[]): void {
         let self = this;

         let modalOptions: any = {
            contentTemplate: "storage-ui/resources/storage/views/datastore/DatastoreConfigIOControlDialog.html",
            title: this.i18nService.getString("StorageUi", "datastore.editCapabilitiesForm.title"),
            defaultButton: "submit",
            size: "lg",
            onSubmit: function () {

               if (!modalOptions.dialogData.datastoreConfigIOControlDialogData) {
                  return false;
               }

               if (!self.validateDatastoreConfigIOControlDialogData(
                           modalOptions.dialogData.datastoreConfigIOControlDialogData, modalOptions)) {
                  return false;
               }

               let spec = self.buildIORMConfigSpec(
                     modalOptions.dialogData.datastoreConfigIOControlDialogData);
               if (!spec) {
                  return false;
               }

               self.mutationService.applyOnMultiEntity(
                     datastoreIds,
                     spec._type,
                     spec
               );

               return true;
            },
            dialogData: {
               datastoreConfigIOControlDialogDataPromise: this.getDialogData(datastoreIds)
            }
         };

         if (datastoreIds.length === 1) {
            modalOptions.subTitle = {
               objectId: datastoreIds[0]
            };
         }

         this.clarityModalService.openOkCancelModal(modalOptions);
      }

      public manualThresholdValidationError(minThreshold: number): string {
         return this.i18nService.getString("StorageUi", "datastore.editCapabilitiesForm.customThreshold.errorInvalidValue",
               minThreshold, this.CONGESTION_THRESHOLD_MANUAL_MAX);
      }

      public percentThresholdValidationError(): string {
         return this.i18nService.getString("StorageUi", "datastore.editCapabilitiesForm.percentPeakThroughput.errorInvalidValue",
               this.CONGESTION_THRESHOLD_PERCENT_MIN, this.CONGESTION_THRESHOLD_PERCENT_MAX);
      }
   }

   angular.module("com.vmware.vsphere.client.storage")
         .service("datastoreConfigIOControlDialogService", DatastoreConfigIOControlDialogService);
}
