/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace dvs_ui {

   import DvpgTrafficFilterRule = com.vmware.vsphere.client.h5.network.dvportgroup.trafficfilter.DvpgTrafficFilterRule;

   export class DvpgEditTrafficFilterModel {
      public showTrafficFilterOverride: boolean = false;
      public enableTrafficFilterOverride: boolean = false;

      public isTrafficRulesetOverwritten: boolean = false;
      public initialIsTrafficRulesetOverwritten: boolean = false;

      public initialTrafficRulesetEnabled: boolean = false;
      public isTrafficRulesetEnabled: boolean = false;
      public rules: DvpgTrafficFilterRule[];
      public originalRulesKeys: string[];
   }
}
