namespace h5_vm {
   export const BEGIN_CERT_MARKER = "-----BEGIN CERTIFICATE-----\n";
   export const END_CERT_MARKER = "\n-----END CERTIFICATE-----\n";
   export const BEGIN_CSR_MARKER = "-----BEGIN CERTIFICATE REQUEST-----\n";
   export const END_CSR_MARKER = "\n-----END CERTIFICATE REQUEST-----\n";

   export class VmTpmService {
      static $inject: string[] = [
         'dataService',
         '$http',
         '$q'
      ];

      constructor(private dataService: any, private $http: any, private $q: any) {
      }

      stripHeadersFromCertificate(rawCertificate: string) {
          const stripCertHeaderRegex = /-+BEGIN CERTIFICATE-+([^-]+)-+END CERTIFICATE-+/im;
          const match = rawCertificate.match(stripCertHeaderRegex);
          return match ? match[1] : rawCertificate;
      }

      getCertificates(vmId: string): IPromise<any> {
         return this.dataService.getProperties(vmId, ['tpmCertificates'])
            .then((result: any) => {
               return _.map(result.tpmCertificates.certificateInfos, (certificateInfo: any) => {
                  certificateInfo.rawData = BEGIN_CERT_MARKER + certificateInfo.rawData + END_CERT_MARKER;
                   return certificateInfo;
               });
            });
      }

      getCertSigningRequests(vmId: string): IPromise<any> {
         return this.dataService.getProperties(vmId, ['tpmCertificates'])
               .then((result: any) => {
                  return _.map(result.tpmCertificates.csrInfos, (csrInfo: any) => {
                     csrInfo.rawData = BEGIN_CSR_MARKER + csrInfo.rawData + END_CSR_MARKER;
                     return csrInfo;
                  });
               });
      }

       isTpmRemovalDisabled(vmPoweredOn: boolean): boolean {
         return vmPoweredOn;
      }

      parseCertificate(rawCertificate: string): IPromise<any> {
          if (!rawCertificate) {
              return this.$q.when(null);
          }

          const rawCertificateStrippedOfHeaders = this.stripHeadersFromCertificate(rawCertificate);

          const url: string = 'certs/cert-info';
          const data = $.param({
             certificate: rawCertificateStrippedOfHeaders
          });
          const config: any = {
             headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
             }
          };

          return this.$http.post(url, data, config).then((response: any) => {
             const certificate = response.data;
             let cnFound = certificate.issuerName.match(/CN=([^,]*)/);
             certificate.issuerNameCN = cnFound ? cnFound[1] : certificate.issuerName;
             certificate.rawData = rawCertificate;
             return certificate;
          }, (error: any) => {
             return this.$q.reject(error.data);
          });
       }
   }

   angular.module('com.vmware.vsphere.client.vm')
      .service('vmTpmService', VmTpmService);
}
