module hostprofile_ui {

   import ManagedEntityMoveSpec = com.vmware.vsphere.client.mixed.ManagedEntityMoveSpec;
   import ManagedObjectReference = com.vmware.vim.binding.vmodl.ManagedObjectReference;
   export class PasswordTextInput {
      public bindings: any;
      public controller: any;
      public templateUrl: string;
      public controllerAs: any;
      public require: any;

      constructor() {
         this.require = {
            parentCtrl: "^hostCustomizations"
         };
         this.controllerAs = "ctrl";
         this.bindings = {
            item: "<"
         };
         this.controller = PasswordTextInputController;
         this.templateUrl = "hostprofile-ui/resources/hostprofile/customizations/passwordTextInputTemplate.html";
      }
   }

   class PasswordTextInputController implements angular.IComponentController {
      static $inject = [
         "i18nService",
         "hostCustomizationsErrorsService",
         "$element",
         "HostProfileConstants",
         "clarityModalService"
      ];

      public item: HostCustomizationItem;
      public parentCtrl: HostsCustomizationsController;
      public requiredConfig: any;
      public value: string = this.i18nService
            .getString("HostProfileUi", "hostCustomizations.maskedPassword");

      constructor(private i18nService: any,
            private hostCustomizationsErrorsService: HostCustomizationsErrorsService,
            private $element: any,
            private HostProfileConstants: any,
            private clarityModalService: any) {
         this.requiredConfig = {
            message: this.i18nService.getString("HostProfileUi", "hostCustomizations.requiredField.error"),
            isVisible: false
         };
      }

      public getErrorConfig(): any {
         if (!this.requiredConfig.isVisible && this.item.error) {
            return this.item.error;
         }

         return this.requiredConfig;
      }

      public openPasswordModal() {
         let modalOptions = {
            title: this.i18nService.getString("HostProfileUi", "editHostCustomizations.editPassword.title"),
            defaultButton: "submit",
            contentTemplate: "hostprofile-ui/resources/hostprofile/customizations/editPassword.html",
            dialogData: {
               value: "",
               matching: true
            },
            onSubmit: () => {
               if (modalOptions.dialogData.matching) {
                  this.item.value = modalOptions.dialogData.value;
                  this.requiredConfig.isVisible = this.hostCustomizationsErrorsService
                        .isPropertyRequiredAndEmpty(this.item);
                  this.parentCtrl.updateHostCustomizationSetting(this.item);
                  return true;
               }
               return false;
            }
         };

         this.clarityModalService.openOkCancelModal(modalOptions);
      }

   }

   angular.module("com.vmware.vsphere.client.hostprofile")
         .component("passwordTextInput", new PasswordTextInput());
}
