namespace h5_vm {

   import AdapterMapping = com.vmware.vim.binding.vim.vm.customization.AdapterMapping;
   import IPSettings = com.vmware.vim.binding.vim.vm.customization.IPSettings;
   import IpV6Generator = com.vmware.vim.binding.vim.vm.customization.IpV6Generator;
   import DhcpIpV6Generator = com.vmware.vim.binding.vim.vm.customization.DhcpIpV6Generator;
   import CustomIpGenerator = com.vmware.vim.binding.vim.vm.customization.CustomIpGenerator;
   import IPSettings$IpV6AddressSpec = com.vmware.vim.binding.vim.vm.customization.IPSettings$IpV6AddressSpec;

   import FixedIpV6 = com.vmware.vim.binding.vim.vm.customization.FixedIpV6;
   import UnknownIpV6Generator = com.vmware.vim.binding.vim.vm.customization.UnknownIpV6Generator;
   import CustomIpV6Generator = com.vmware.vim.binding.vim.vm.customization.CustomIpV6Generator;
   class EditIpv6TabController {
      public static $inject = ["i18nService", "gosSpecNetworkService", "ipParserService"];

      public i18n: (bundle: string, key: string, ...args: (string | number)[]) => string;

      private readonly DO_NOT_USE_IPV6 = "doNotUseIpv6";
      private readonly ADAPTER_TYPE_DHCP = GosCustomizationHelperService.ADAPTER_TYPE_DHCP_V6;
      private readonly ADAPTER_TYPE_UNKNOWUN_IP = GosCustomizationHelperService.ADAPTER_TYPE_UNKNOWUN_IP_V6;
      private readonly ADAPTER_TYPE_CUSTOM_IP = GosCustomizationHelperService.ADAPTER_TYPE_CUSTOM_IP_V6;
      private readonly ADAPTER_TYPE_FIXED_IP = GosCustomizationHelperService.ADAPTER_TYPE_FIXED_IP_V6;
      private readonly TITLE_LOCALE = "customizationSpec.networkPage.properties.ipv6.pageTitle";

      public ipv6Type: string;
      public editIpv6: () => boolean;
      public gridAddressesOptions: any = {};
      public gridGatewaysOptions: any = {};
      public adapterWrapper: AdapterMappingWrapper;
      public adapterMapping: AdapterMapping;
      public gateways: string[] = [];
      public argument: string;
      public ipAddress: string;
      public validationErrors: any = {};
      public prefixLength: number|undefined;
      public gateway: string;
      public submitErrors: string[];
      public updateDnsTab: any;

      public ipAddressesList: FixedIpV6[] = [];

      constructor(private i18nService: any,
                  private gosSpecNetworkService: GosSpecNetworkService,
                  private ipParser: any) {
      }

      $onInit(): void {
         this.i18n = this.i18nService.getString;
         this.adapterMapping = this.adapterWrapper.adapterMapping;
         this.ipv6Type = this.getSpecType();
         this.populateData();
         this.initGrids();
         this.editIpv6 = (): boolean => {
            return this.preSubmit();
         };
      }

      private initGrids() {
         this.gridAddressesOptions.columnDefinitions = this.getAddressesColumnDefs();
         this.gridAddressesOptions.data = this.ipAddressesList;
         this.gridAddressesOptions.deleteLabel = this.i18nService.getString(
            "VmUi", "customizationSpec.networkPage.actionButton.delete.label");
         this.gridAddressesOptions.deleteTooltip = this.i18nService.getString(
            "VmUi", "customizationSpec.networkPage.actionButton.delete.tooltip");
         this.gridAddressesOptions.onDelete = this.deleteAddressFromList.bind(this);

         this.gridGatewaysOptions.columnDefinitions = this.getGatewaysColumnDefs();
         this.gridGatewaysOptions.data = this.gateways;
         this.gridGatewaysOptions.deleteLabel = this.i18nService.getString(
            "VmUi", "customizationSpec.networkPage.actionButton.delete.label");
         this.gridGatewaysOptions.deleteTooltip = this.i18nService.getString(
            "VmUi", "customizationSpec.networkPage.actionButton.delete.tooltip");
         this.gridGatewaysOptions.onDelete = this.deleteGatewayFromList.bind(this);
      }

      public typeChanged() {
         if (this.ipv6Type === this.DO_NOT_USE_IPV6 || this.ipv6Type === this.ADAPTER_TYPE_DHCP) {
            this.validationErrors.gateway = null;
         }

         if (this.ipv6Type !== this.ADAPTER_TYPE_FIXED_IP) {
            this.validationErrors.ipAddress = null;
            this.validationErrors.prefixLength = null;
         }

         let isValid: boolean = this.validate();
         this.setValidationErrors(isValid);

         this.updateData();

         if (this.updateDnsTab && typeof this.updateDnsTab === "function") {
            this.updateDnsTab();
         }
      }

      private setValidationErrors(isValid: boolean) {
         if (!isValid && !_.contains(this.submitErrors, this.TITLE_LOCALE)) {
            this.submitErrors.push(this.TITLE_LOCALE);
         } else if (isValid) {
            this.submitErrors = _.without(this.submitErrors, this.TITLE_LOCALE);
         }

      }

      public addAddress() {
         this.validateIPv6(this.ipAddress);
         this.validatePrefixLength(this.prefixLength);
         if (this.validationErrors.ipAddress || this.validationErrors.prefixLength) {
            return;
         }

         let address = new FixedIpV6();
         address.ipAddress = this.ipAddress;
         if (this.prefixLength) {
            address.subnetMask = this.prefixLength;
         }

         this.ipAddressesList.push(address);
         this.gridAddressesOptions.data = this.ipAddressesList;

         this.ipAddress = "";
         this.prefixLength = undefined;
         this.setValidationErrors(true);

      }

      public addGateway() {
         this.validateGateway(this.gateway);
         if (!this.gateway || this.validationErrors.gateway) {
            return;
         }

         this.gateways.push(this.gateway);
         this.gridGatewaysOptions.data = this.gateways;

         this.gateway = "";
      }

      public validateGateway(gateway: string) {
         if (!gateway) {
            this.validationErrors.gateway = null;
            return;
         }
         let isValid: boolean = this.ipParser.isIpv6AddressValid(gateway);
         let isDuplicate = _.any(this.gateways, (currentGateway: string) => {
            return currentGateway === gateway;
         });

         if (!isValid) {
            this.validationErrors.gateway =
               this.i18n("VmUi", "customizationSpec.networkPage.properties.ipv6.dialog.error.address");
         }

         if (isDuplicate) {
            this.validationErrors.gateway =
               this.i18n("VmUi", "customizationSpec.networkPage.properties.ipv6.dialog.error.duplicate", gateway);
         }

         if (isValid && !isDuplicate) {
            this.validationErrors.gateway = null;
         }
      }

      public validateIPv6(ipAddress: string) {
         let isValid: boolean = this.ipParser.isIpv6AddressValid(ipAddress);
         let isDuplicate = _.any(this.ipAddressesList, (address: FixedIpV6) => {
            return address.ipAddress === ipAddress;
         });

         if (!isValid) {
            this.validationErrors.ipAddress =
               this.i18n("VmUi", "customizationSpec.networkPage.properties.ipv6.dialog.error.address");
         }

         if (isDuplicate) {
            this.validationErrors.ipAddress =
               this.i18n("VmUi", "customizationSpec.networkPage.properties.ipv6.dialog.error.duplicate", ipAddress);
         }

         if (isValid && !isDuplicate) {
            this.validationErrors.ipAddress = null;
         }
      }

      public validatePrefixLength(prefixLength?: number) {
         if (!this.ipParser.isSubnetPrefixValid(prefixLength)) {
            this.validationErrors.prefixLength =
               this.i18n("VmUi", "customizationSpec.networkPage.properties.ipv6.dialog.error.prefix");
         } else {
            this.validationErrors.prefixLength = null;
         }
      }

      private getSpecType() {
         let adapter: IPSettings = this.adapterMapping.adapter;

         if (!adapter.ipV6Spec) {
            return this.DO_NOT_USE_IPV6;
         }

         if (!adapter.ipV6Spec.ip || !adapter.ipV6Spec.ip.length) {
            return this.DO_NOT_USE_IPV6;
         }

         let ipGenerator: IpV6Generator = adapter.ipV6Spec.ip[0];
         return ipGenerator._type;
      }

      private populateData() {
         if (this.ipv6Type === this.DO_NOT_USE_IPV6) {
            return;
         }

         let adapter: IPSettings = this.adapterMapping.adapter;

         if (adapter.ipV6Spec.gateway && adapter.ipV6Spec.gateway.length) {
            this.gateways = adapter.ipV6Spec.gateway;
         } else {
            this.gateways = [];
         }

         if (this.ipv6Type === this.ADAPTER_TYPE_CUSTOM_IP) {
            this.argument = (adapter.ipV6Spec.ip[0] as CustomIpGenerator).argument;
         }

         if (this.ipv6Type === this.ADAPTER_TYPE_FIXED_IP) {
            // There could be more so assign all of them to the ipv6 address list
            this.ipAddressesList = (adapter.ipV6Spec.ip as FixedIpV6[]);
         }
      }

      public preSubmit(): boolean {
         let isValid = this.validate();

         if (!isValid) {
            return false;
         }

         this.updateData();
         return true;
      }

      private validate() {
         let isValid = true;
         if (this.ipv6Type === this.ADAPTER_TYPE_FIXED_IP &&
               (!this.ipAddressesList || !this.ipAddressesList.length)) {
            isValid = false;
            this.validationErrors.ipAddress =
               this.i18n("VmUi", "customizationSpec.networkPage.properties.ipv6.error.required");
         }

         return isValid;
      }

      private updateData() {
         let adapter: IPSettings = this.adapterMapping.adapter;

         if (this.ipv6Type === this.DO_NOT_USE_IPV6) {
            delete adapter.ipV6Spec;
            this.adapterWrapper.ipV6Address = this.gosSpecNetworkService.getIPv6NicSettingSummary(
               adapter.ipV6Spec);
            return;
         }

         adapter.ipV6Spec = new IPSettings$IpV6AddressSpec();
         adapter.ipV6Spec.gateway = this.gateways;
         adapter.ipV6Spec.ip = this.getIpGenerators();

         this.adapterWrapper.ipV6Address = this.gosSpecNetworkService.getIPv6NicSettingSummary(
            adapter.ipV6Spec);
      }

      private getIpGenerators(): IpV6Generator[]{
         switch (this.ipv6Type) {
            case this.ADAPTER_TYPE_UNKNOWUN_IP:
               return [new UnknownIpV6Generator()];

            case this.ADAPTER_TYPE_CUSTOM_IP:
               let customIpGenerator: CustomIpV6Generator = new CustomIpV6Generator();
               customIpGenerator.argument = this.argument;
               return [customIpGenerator];

            case this.ADAPTER_TYPE_FIXED_IP:
               return this.ipAddressesList;
         }

         return [new DhcpIpV6Generator()];
      }

      private getAddressesColumnDefs(): any {
         return [
            {
               displayName: this.i18n("VmUi", "customizationSpec.networkPage.properties.ipv6.address"),
               field: "ipAddress",
               type: "string",
               class: "ipv6-address-col"
            },
            {
               displayName: this.i18n("VmUi", "customizationSpec.networkPage.properties.ipv6.prefix"),
               field: "subnetMask",
               type: "string",
            }
         ];
      }

      private getGatewaysColumnDefs(): any {
         return [
            {
               displayName: this.i18n("VmUi", "customizationSpec.networkPage.properties.ipv6.gateway"),
               type: "string",
            }
         ];
      }

      private deleteAddressFromList(address?: FixedIpV6) {
         if (!address) {
            return;
         }

         let index: number = _.findIndex(this.ipAddressesList,
            (currentAddress: FixedIpV6) => {
               return currentAddress.ipAddress === address.ipAddress;
            });
         if (index < 0) {
            return;
         }
         this.ipAddressesList.splice(index, 1);
         this.setValidationErrors(this.validate());

      }

      private deleteGatewayFromList(gateway?: string) {
         if (!gateway) {
            return;
         }

         let index: number = _.findIndex(this.gateways,
            (currentGateway: string) => {
               return currentGateway === gateway;
            });
         if (index < 0) {
            return;
         }
         this.gateways.splice(index, 1);
      }
   }

   export class EditIpv6TabComponent {
      public controller: any;
      public templateUrl: string;
      public bindings: any;

      constructor() {
         this.bindings = {
            adapterWrapper: "<",
            isNameIpGeneratorDefined: "<",
            editIpv6: "=",
            submitErrors: "=",
            updateDnsTab: "="
         };
         this.controller = EditIpv6TabController;
         this.templateUrl = "vm-ui/resources/vm/guest-os-customization/pages/network/edit/ipv6-tab/edit-ipv6-tab.component.html";
      }
   }

   angular.module("com.vmware.vsphere.client.vm")
      .component("editIpv6Tab", new EditIpv6TabComponent());
}
