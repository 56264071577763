(function() {
    'use strict';
    angular
        .module('com.vmware.vsphere.client.commonModule')
        .controller('EditCustomAttributesDialogController', EditCustomAttributesDialogController);

    EditCustomAttributesDialogController.$inject = [
        '$scope',
        'customAttributesService',
        'i18nService',
        'defaultUriSchemeUtil',
        'vuiConstants'
    ];

    function EditCustomAttributesDialogController($scope, customAttributesService, i18nService, defaultUriSchemeUtil, vuiConstants) {
        var self = this;
        self.FOLDER_MAX_LENGTH = 80;
        var objectId = $scope.modalOptions.availableTargets[0];
        var modifiedAttributes = [];
        var entityMor = defaultUriSchemeUtil.getManagedObjectReference(objectId);
        self.addAttribute = addAttribute;
        self.deleteCustomAttribute = deleteCustomAttribute;
        self.saveModifiedCustomAttributes = saveModifiedCustomAttributes;

        resetAddAttributeForm();
        self.attributeTypeOptions = [
            {
                label: i18nService.getString('Common', 'fieldType.global'),
                value: 'global'
            },
            {
                label: i18nService.getString('Common', 'fieldType.' + entityMor.type),
                value:  entityMor.type
            }
        ];

        self.datagridOptions = {
            actionBarOptions: {
                actions: [{
                    id: 'deleteCustomAttributeAction',
                    enabled: false,
                    tooltipText: i18nService.getString('Common', 'ca.delete.delete'),
                    iconClass: 'vsphere-icon-toolbar-remove',
                    onClick: self.deleteCustomAttribute
                }]
            },
            columnDefs: [
                {
                    displayName: i18nService.getString('Common', 'ca.values.attribute'),
                    field: 'attribute',
                    sortable: false,
                    searchable: false,
                    editable: {
                        validator: {
                            required: true,
                            uniqueAttribute: function(input) {
                                var hasValidLength = input.val().length < self.FOLDER_MAX_LENGTH;
                                if (!hasValidLength) {
                                    input.attr("data-uniqueAttribute-msg", "The name is too long");
                                    return false;
                                }
                                var attributeNames = input.parents('table').find('[ng-bind="dataItem.attribute"]').map(function(i, td) {
                                    return $(td).html();
                                });
                                input.attr("data-uniqueAttribute-msg", "Attribute is not unique");
                                return !_.contains(attributeNames, input.val());
                            }
                        }
                    }
                },
                {
                    displayName: i18nService.getString('Common', 'ca.values.key'),
                    field: 'key',
                    visible: false
                },
                {
                    displayName: i18nService.getString('Common', 'ca.values.value'),
                    field: 'value',
                    sortable: false,
                    searchable: false,
                    editable: {
                        validator: {
                            uniqueAttribute: function(input) {
                                var hasValidLength = input.val().length < self.FOLDER_MAX_LENGTH;

                                if (!hasValidLength) {
                                    input.attr("maxlength", self.FOLDER_MAX_LENGTH);
                                    input.attr("data-uniqueAttribute-msg", "The value is too long");
                                    input.val(input.val().substring(0, self.FOLDER_MAX_LENGTH));
                                    return false;
                                }

                                return true;
                            }
                        }
                    }
                },
                {
                    displayName: i18nService.getString('Common', 'ca.values.type'),
                    field: 'typeLabel',
                    sortable: false,
                    searchable: false,
                    editable: false
                }
            ],
            height: '200px',
            resizable: false,
            reorderable: false,
            selectionMode: vuiConstants.grid.selectionMode.SINGLE,
            editable: true,
            selectedItems: [],
            save: updateModifiedAttributes
        };

        $scope.$watch('editCtrl.datagridOptions.selectedItems', function(selectedItems) {
            self.datagridOptions.actionBarOptions.actions[0].enabled = (selectedItems.length > 0);
        });

        customAttributesService.getCustomAttributes(objectId).then(function(customAttributes) {
            self.datagridOptions.data = customAttributes;
        });

        self.newAttributeErrorConfig = {
            message: '',
            isVisible: function(inputElement) {
                if (inputElement.$error.duplicateAttributeError) {
                    this.message = i18nService.getString('Common', 'ca.error.duplicate');
                    return true;
                }
                if (inputElement.$error.emptyAttributeError) {
                    this.message = i18nService.getString('Common', 'ca.error.empty');
                    return true;
                }
                if (inputElement.$error.maxLengthError) {
                    this.message = i18nService.getString('Common', 'ca.error.length');
                    return true;
                }

                return false;
            }
        };

        function updateModifiedAttributes(saveData) {
            var newModifiedAttribute = angular.extend({}, saveData.model, saveData.values);
            newModifiedAttribute.operationId = saveData.values.value ? 'EDIT' : 'RENAME';
            // Add operations are ignored until final "Ok" button is clicked
            // key is undefined for newly added attributes
            if (saveData.model.key) {
                modifiedAttributes.push(newModifiedAttribute);
            }
        }

        function saveModifiedCustomAttributes() {
            //getting the newly added attributes
            var newAttributes = self.datagridOptions.dataSource.view().filter(function(o) {
                return !o.key;
            });

            var allAttributesToBeSaved = modifiedAttributes.concat(newAttributes);
            if (allAttributesToBeSaved.length > 0) {
                customAttributesService.modifyCustomAttributes(objectId, allAttributesToBeSaved).then(function(response) {
                    if (!response.error) {
                        updateSummaryViewDatagrid();
                        self.closeModal();
                    }
                });
            }
        }


        self.closeModal = function() {
            $scope.closeModal();
        };

        function resetAddAttributeForm() {
            $scope.attribute = '';
            $scope.value = '';
            $scope.type = entityMor.type;
        }

        function deleteCustomAttribute() {
            var dialogData = self.datagridOptions.dataSource.view().filter(function(data) {
                return self.datagridOptions.selectedItems[0].attribute !== data.attribute;
            });
            self.datagridOptions.data = dialogData;

            if (self.datagridOptions.selectedItems[0].key) {
                self.datagridOptions.selectedItems[0].operationId = 'REMOVE';
                modifiedAttributes.push(self.datagridOptions.selectedItems[0]);
            }
        }

        function addAttribute(attributeName, attributeValue, attributeType) {
            if (_.isUndefined(attributeName) || _.isNull(attributeName) || _.isEmpty(attributeName)) {
                setPopulatedValidityOnAttributeInputField(false);
                return;
            }
            var hasValidLength = attributeName.length < self.FOLDER_MAX_LENGTH;
            if (!hasValidLength) {
                setMaxLengthValidityOnAttributeInputField(false);
                return;
            }

            var valueHasValidLength = attributeValue.length < self.FOLDER_MAX_LENGTH;
            if (!valueHasValidLength) {
                setMaxLengthValidityOnValueInputField(false);
                return;
            }

            var newAttribute = {
                attribute: attributeName,
                value: attributeValue,
                type: attributeType,
                typeLabel: i18nService.getString('Common', 'fieldType.' + attributeType),
                operationId: 'ADD'
            };
            addToDialogDatagrid(newAttribute);
        }

        function updateSummaryViewDatagrid() {
            refreshWithUpdatedData();
        }

        function addToDialogDatagrid(attributeToAdd) {
            var dialogData = [];
            angular.copy(self.datagridOptions.dataSource.view(), dialogData);

            if (isAttributeDuplicate(attributeToAdd.attribute)) {
                setUniquenessValidityOnAttributeInputField(false);
                return;
            }

            dialogData.splice(0, 0, attributeToAdd);
            self.datagridOptions.data = dialogData;
            resetAddAttributeForm();
            setUniquenessValidityOnAttributeInputField(true);
        }

        function refreshWithUpdatedData() {
            _.forEach(modifiedAttributes, function(modifiedAttribute) {
                _.forEach(self.datagridOptions.data, function(currentAttribute) {
                    if (modifiedAttribute.key && modifiedAttribute.key === currentAttribute.key) {
                        currentAttribute.value = modifiedAttribute.value;
                        currentAttribute.attribute = modifiedAttribute.attribute;
                    }
                });
            });
        }

        function isAttributeDuplicate(attributeName) {
            var foundElement = _.find(self.datagridOptions.dataSource.view(), function(item) {
                return (item.attribute === attributeName);
            });
            return (foundElement !== undefined) ? true : false;
        }

        function setUniquenessValidityOnAttributeInputField(validity) {
            if (self.addForm) {
                self.addForm.newCustomAttributeName.$setValidity("duplicateAttributeError", validity);
                self.newAttributeErrorConfig.isVisible(self.addForm.newCustomAttributeName);
            }
        }

        function setPopulatedValidityOnAttributeInputField(validity) {
            if (self.addForm) {
                self.addForm.newCustomAttributeName.$setValidity("emptyAttributeError", validity);
                self.newAttributeErrorConfig.isVisible(self.addForm.newCustomAttributeName);
            }
        }

        function setMaxLengthValidityOnAttributeInputField(validity) {
            if (self.addForm) {
                self.addForm.newCustomAttributeName.$setValidity("maxLengthError", validity);
                self.newAttributeErrorConfig.isVisible(self.addForm.newCustomAttributeName);
            }
        }

        function setMaxLengthValidityOnValueInputField(validity) {
            if (self.addForm) {
                self.addForm.newCustomAttributeValue.$setValidity("maxLengthError", validity);
                self.newAttributeErrorConfig.isVisible(self.addForm.newCustomAttributeValue);
            }
        }
    }
}());
