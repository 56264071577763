(function() {
   'use strict';

   angular.module('com.vmware.vsphere.client.vm')
         .service('CreateVmFromScratchService', CreateVmFromScratchService);

   CreateVmFromScratchService.$inject = [
      'vuiConstants',
      'wizardPageService',
      'creationTypeConstants',
      'vmProvisioningGuestOsPageService',
      'vmProvisioningDatastorePageService',
      'vmProvisioningNetworkPageService',
      'vmProvisioningNameAndFolderPageService',
      'vmProvisioningFinishCloneVmPageService',
      'vmProvisioningFinishCreateVmPageService',
      'vmProvisioningSelectAnyTemplatePageService',
      'VmProvisioningComputeResourcePageService',
      'vmProvisioningProductDetailsPageService',
      'vmProvisioningSelectVmVersionPageService',
      'vmProvisioningSelectVmToClonePageService',
      'vmProvisioningSelectTemplatePageService',
      'vmProvisioningSelectTemplateToConvertPageService',
      'vmProvisioningFinishConvertToVmPageService',
      'vmProvisioningCustomizeHardwarePageService',
      'vmProvisioningSelectCreationTypePageService',
      'vmProvisioningFinishDeployTemplatePageService',
      'vmProvisioningCloneOptionsPageService',
      'vmProvisioningCloneCustomizeGuestOsPageService',
      'vmProvisioningUserSettingsPageService',
      'VmProvisioningEulaPageService',
      'VmProvisioningDeploymentOptionsPageService',
      'vmCreateScheduleTaskPageService',
      'vmProvisioningScaleoutConfigurationPageService',
      'vmProvisioningCustomizeTemplatePageService',
      'vmProvisioningVservicePageService',
      'vmProvisioningVappPropertiesPageService'
   ];

   function CreateVmFromScratchService(
         vuiConstants,
         wizardPageService,
         creationTypeConstants,
         vmProvisioningGuestOsPageService,
         vmProvisioningDatastorePageService,
         vmProvisioningNetworkPageService,
         vmProvisioningNameAndFolderPageService,
         vmProvisioningFinishCloneVmPageService,
         vmProvisioningFinishCreateVmPageService,
         vmProvisioningSelectAnyTemplatePageService,
         VmProvisioningComputeResourcePageService,
         vmProvisioningProductDetailsPageService,
         vmProvisioningSelectVmVersionPageService,
         vmProvisioningSelectVmToClonePageService,
         vmProvisioningSelectTemplatePageService,
         vmProvisioningSelectTemplateToConvertPageService,
         vmProvisioningFinishConvertToVmPageService,
         vmProvisioningCustomizeHardwarePageService,
         vmProvisioningSelectCreationTypePageService,
         vmProvisioningFinishDeployTemplatePageService,
         vmProvisioningCloneOptionsPageService,
         vmProvisioningCloneCustomizeGuestOsPageService,
         vmProvisioningUserSettingsPageService,
         VmProvisioningEulaPageService,
         VmProvisioningDeploymentOptionsPageService,
         vmCreateScheduleTaskPageService,
         vmProvisioningScaleoutConfigurationPageService,
         vmProvisioningCustomizeTemplatePageService,
         vmProvisioningVservicePageService,
         vmProvisioningVappPropertiesPageService) {
      return function() {
         this.buildPages = buildPages;

         function buildPages(wizardScope, defaultTarget, preselectedComputeResource, preselectedStorage) {
            var nameAndFolderPage = vmProvisioningNameAndFolderPageService.build(wizardScope, defaultTarget);
            var computeResourcePage = VmProvisioningComputeResourcePageService.build(wizardScope, preselectedComputeResource);
            var productDetails = vmProvisioningProductDetailsPageService.build(wizardScope, preselectedComputeResource);
            var datastorePage = vmProvisioningDatastorePageService.build(wizardScope, preselectedStorage);
            var networkPage = vmProvisioningNetworkPageService.build(wizardScope);
            var selectAnyTemplatePage = vmProvisioningSelectAnyTemplatePageService.build(wizardScope);
            var selectVmVersionPage = vmProvisioningSelectVmVersionPageService.build(wizardScope);
            var guestOSPage = vmProvisioningGuestOsPageService.build(wizardScope);
            var customizeHardwarePage = vmProvisioningCustomizeHardwarePageService.build(wizardScope);
            var finishCreateVmPage = vmProvisioningFinishCreateVmPageService.build(wizardScope);
            var finishCloneVmPage = vmProvisioningFinishCloneVmPageService.build(wizardScope);
            var selectVMToClonePage = vmProvisioningSelectVmToClonePageService.build(wizardScope);
            var selectVcTemplatePage = vmProvisioningSelectTemplatePageService.build(wizardScope);
            var selectVcTemplateToConvertPage = vmProvisioningSelectTemplateToConvertPageService.build(wizardScope);
            var finishTemplatePage = vmProvisioningFinishConvertToVmPageService.build(wizardScope);
            var finishDeployFromTemplatePage = vmProvisioningFinishDeployTemplatePageService.build(wizardScope, preselectedComputeResource);
            var cloneOptionsPage = vmProvisioningCloneOptionsPageService.build(wizardScope);
            var customizeGuestOSPage = vmProvisioningCloneCustomizeGuestOsPageService.build(wizardScope);
            var userSettingsPage = vmProvisioningUserSettingsPageService.build(wizardScope);
            var eulaPage = VmProvisioningEulaPageService.build(wizardScope);
            var deploymentOptions = VmProvisioningDeploymentOptionsPageService.build(wizardScope);
            var scaleoutPage = vmProvisioningScaleoutConfigurationPageService.build(
                  wizardScope,
                  defaultTarget,
                  preselectedComputeResource
            );
            var customizeTemplatePage = vmProvisioningCustomizeTemplatePageService.build(
                  wizardScope);
            var vservicePage = vmProvisioningVservicePageService.build(
                  wizardScope,
                  defaultTarget,
                  preselectedComputeResource);
            var vappPropertiesPage = vmProvisioningVappPropertiesPageService.build(wizardScope);
            vappPropertiesPage.defaultState = vuiConstants.wizard.pageState.SKIPPED;

            var flows = [
               {
                  id: creationTypeConstants.CREATE_FROM_SCRATCH,
                  pages: [
                     nameAndFolderPage,
                     computeResourcePage,
                     datastorePage,
                     selectVmVersionPage,
                     guestOSPage,
                     customizeHardwarePage,
                     finishCreateVmPage
                  ]
               },
               {
                  id: creationTypeConstants.DEPLOY_VM_FROM_ANY_TEMPLATE,
                  pages: [selectAnyTemplatePage]
               },{
                  id: creationTypeConstants.DEPLOY_VM_FROM_TEMPLATE,
                  pages: [
                     nameAndFolderPage,
                     customizeGuestOSPage,
                     userSettingsPage,
                     computeResourcePage,
                     productDetails,
                     eulaPage,
                     deploymentOptions,
                     scaleoutPage,
                     datastorePage,
                     networkPage,
                     customizeTemplatePage,
                     vservicePage,
                     finishDeployFromTemplatePage
                  ],
                  parentWorkflow: creationTypeConstants.DEPLOY_VM_FROM_ANY_TEMPLATE
               }, {
                  id: creationTypeConstants.CLONE_TEMPLATE_TO_VM,
                  pages: [
                     nameAndFolderPage,
                     computeResourcePage,
                     datastorePage,
                     cloneOptionsPage,
                     customizeGuestOSPage,
                     userSettingsPage,
                     customizeHardwarePage,
                     vappPropertiesPage,
                     finishCloneVmPage
                  ],
                  parentWorkflow: creationTypeConstants.DEPLOY_VM_FROM_ANY_TEMPLATE
               },
               {
                  id: creationTypeConstants.CLONE_VM_TO_VM,
                  pages: [
                     selectVMToClonePage,
                     nameAndFolderPage,
                     computeResourcePage,
                     datastorePage,
                     cloneOptionsPage,
                     customizeGuestOSPage,
                     userSettingsPage,
                     customizeHardwarePage,
                     vappPropertiesPage,
                     finishCloneVmPage
                  ]
               },
               {
                  id: creationTypeConstants.CLONE_VM_TO_TEMPLATE,
                  pages: [
                     selectVMToClonePage,
                     nameAndFolderPage,
                     computeResourcePage,
                     datastorePage,
                     vappPropertiesPage,
                     finishCloneVmPage
                  ]
               },
               {
                  id: creationTypeConstants.CLONE_TEMPLATE_TO_TEMPLATE,
                  pages: [
                     selectVcTemplatePage,
                     nameAndFolderPage,
                     computeResourcePage,
                     datastorePage,
                     vappPropertiesPage,
                     finishCloneVmPage
                  ]
               },
               {
                  id: creationTypeConstants.CONVERT_TEMPLATE_TO_VM,
                  pages: [
                     selectVcTemplateToConvertPage,
                     computeResourcePage,
                     finishTemplatePage
                  ]
               }
            ];

            if (wizardScope.scheduleTaskData
               && wizardScope.scheduleTaskData.isScheduledTask) {
               flows = flows.splice(0, 1); // only CREATE_FROM_SCRATCH in case of scheduled task
            }

            flows = wizardPageService.initializeFlowStates(flows);

            var pages = [
               vmProvisioningSelectCreationTypePageService.build(wizardScope, _.map(flows, function(flow) {
                  return flow.id;
               }))
            ];

            if (wizardScope.scheduleTaskData  &&
               wizardScope.scheduleTaskData.isScheduledTask) {
               var scheduleTaskPage = vmCreateScheduleTaskPageService.build(wizardScope);
               pages.splice(0, 0, scheduleTaskPage);
            }

            return {
               pages: pages,
               flows: flows
            };
         }
      };
   }
})();
