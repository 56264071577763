/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace dvs_ui {

   import HostCompatibilityListItem = com.vmware.vsphere.client.h5.network.dvs.upgrade.model.HostCompatibilityListItem;

   export class DvsHostCompatibilityService {

      static $inject = [];

      constructor() {
      }

      public isIncompatibleHostAvailable(
            hostCompatibilityData: HostCompatibilityListItem[]): boolean {

         if (!hostCompatibilityData || hostCompatibilityData.length === 0) {
            return false;
         }

         let incompatibleHostItem: HostCompatibilityListItem =
               _.find(hostCompatibilityData, (item: HostCompatibilityListItem) => {
                  return item.compatibilityErrors &&
                        item.compatibilityErrors.length !== 0;
               });

         return incompatibleHostItem !== undefined;
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .service("dvsHostCompatibilityService", DvsHostCompatibilityService);
}
