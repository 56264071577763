/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
(function () {
   'use strict';

   angular
      .module('com.vmware.vsphere.client.networkLibUi')
      .factory('FailoverOrderDatagridManager', FailoverOrderDatagridManager);

   FailoverOrderDatagridManager.$inject = ['vuiConstants', 'i18nService',
         'clarityModalService', 'pnicSelectorDialogService', 'networkUiConstants'];

   function FailoverOrderDatagridManager(vuiConstants, i18nService,
         clarityModalService, pnicSelectorDialogService, networkUiConstants) {

      // Helper array for looping through connectee categories
      var categories = ['active', 'standby', 'unused'];

      var ADD = networkUiConstants.failoverOrderActions.ADD;
      var REMOVE = networkUiConstants.failoverOrderActions.REMOVE;
      var MOVE_UP = networkUiConstants.failoverOrderActions.MOVE_UP;
      var MOVE_DOWN = networkUiConstants.failoverOrderActions.MOVE_DOWN;

      /**
       *
       * @param failoverOrder
       *       Info about the connectees and the categories.
       * @param {vuiConstants.grid.selectionMode} selectionMode
       *
       * @param {boolean} areAllItemsEditable
       *       Default false
       */
      return function (failoverOrder, visibleActions,
                       selectionMode, editAllItems, actionsDisabled) {

         //Default value of editAllItems is false
         editAllItems = editAllItems || false;

         var disableAllActions = actionsDisabled;
         var connectees = failoverOrder.connectees;

         /*
            Store the initially unclaimed physical adapters.
            The array 'unclaimed' should stay unchanged if some nics are moved
            from unclaimed to active and the directive is invoked once again
            afterwards.
            (for example - you are in a wizard, add a nic to the active category,
            go back to the previous page and then invoke the directive again).
          */
         if (failoverOrder.connectees) {
            if (!failoverOrder.connectees.unclaimedInitial) {
               failoverOrder.connectees.unclaimedInitial =
                  _.extend([], failoverOrder.connectees.unclaimed);
            }
         }

         var selectedItemsBeforeDatagridUpdate = [];

         var allActionDefinitions = [
            {
               id: ADD,
               enabled: !actionsDisabled,
               iconClass: 'network-lib-ui-icon-add',
               tooltipText: i18nService.getString("NetworkUi", "FailoverOrder.add"),
               onClick: _addCallback
            },
            vuiConstants.actions.SEPARATOR,
            {
               id: REMOVE,
               enabled: false,
               iconClass: 'vx-icon-removeIcon',
               tooltipText: i18nService.getString("NetworkUi", "FailoverOrder.remove"),
               onClick: _removeCallback
            },
            {
               id: MOVE_UP,
               enabled: false,
               iconClass: 'vx-icon-moveUp',
               tooltipText: i18nService.getString("NetworkUi", "FailoverOrder.moveUp"),
               onClick: _moveUpCallback
            },
            {
               id: MOVE_DOWN,
               enabled: false,
               iconClass: 'vx-icon-moveDown',
               tooltipText: i18nService.getString("NetworkUi", "FailoverOrder.moveDown"),
               onClick: _moveDownCallback
            }
         ];

         var datagridOptions = _createDatagridOptions(selectionMode);

         return {
            datagridOptions: datagridOptions,
            selectionChanged: _selectionChanged,
            preselectComparator: _preselectComparator,
            enableDisableAllActions: _enableDisableAllActions
         };

         /**
          * Checks whether the selection really changed or the call was due to moving
          * elements up or down. Updates the action bar of the grid with the available
          * actions based on the new selection.
          * @param selectedItems
          *       list of the selected parameters
          * @returns
          *       true if the selection actually changed.
          */
         function _selectionChanged(selectedItems) {
            // vxDatagridPreselectItem iterates over the grid items and calls preselectComparator for
            // each of them. For each item where the preselectComparator returns true the
            // vxDatagridPreselectItem directive selects it and triggers this watch,
            // but  we are interested only in the last update of selectedItems.
            // This happens when the number of selectedItems reaches the number of the
            // selected items before the datagrid update a.k.a selectedItemsBeforeDatagridUpdate
            if(selectedItemsBeforeDatagridUpdate.length !== 0
                  && selectedItemsBeforeDatagridUpdate.length !== selectedItems.length) {
               return false;
            }
            //once the number of selected items reaches the number before making an
            //action we are no longer interesting in selectedItemsBeforeDatagridUpdate and
            //have to reset it because next time _selectionChanged is called it could be
            //not because of an action but an usual user interaction.
            selectedItemsBeforeDatagridUpdate = [];

            return _updateActions(selectedItems);
         }

         /**
          * Updates the enabled/disabled state of the grid actions.
          * @param selectedItems
          *       list of the selected parameters
          * @param actionsDisabled
          *       True if all actions need to be disabled
          * @returns
          *       true if the selection actually changed.
          */
         function _enableDisableAllActions(selectedItems, actionsDisabled) {
            disableAllActions = actionsDisabled;
            _updateActions(selectedItems);
         }

         /**
          * Updates the enabled/disabled state of the grid actions.
          * @param selectedItems
          *       list of the selected parameters
          * @returns
          *       true if the selection actually changed.
          */
         function _updateActions(selectedItems) {

            _.forEach(datagridOptions.actionBarOptions.actions, function (action) {
               if (action === vuiConstants.actions.SEPARATOR) {
                  return;
               }

               if (disableAllActions) {
                  action.enabled = false;
                  return;
               }

               if (!selectedItems || selectedItems.length === 0) {
                  action.enabled = action.id === ADD;
                  return;
               }

               switch (action.id) {
                  case ADD :
                     action.enabled = true;
                     break;
                  case REMOVE :
                     action.enabled = _isRemoveButtonEnabled(selectedItems);
                     break;
                  case MOVE_UP :
                     action.enabled =
                           connectees && !_isFirstItemSelected(selectedItems, connectees.active) &&
                           _isPnicSelected(selectedItems);
                     break;
                  case MOVE_DOWN :
                     action.enabled =
                           connectees && !_isLastItemSelected(selectedItems, connectees.unused) &&
                           _isPnicSelected(selectedItems);
                     break;
               }
            });
            return true;
         }

         /**
          * Returns true if the item has to be selected on grid's data source reset.
          */
         function _preselectComparator(datagridItem) {
            return _.some(selectedItemsBeforeDatagridUpdate, function(item) {
               if (item.value === datagridItem.value) {
                  return true;
               }
            });
         }

         /**
          * Check if there is pnic in the selectedItems
          */
         function _isPnicSelected(selectedItems) {
            return _.some(selectedItems, function (item) {
               return !item.categoryItem;
            });
         }

         /**
          * Checks if one of the selected items is in first position in the given array.
          */
         function _isFirstItemSelected(selectedItems, items) {
            var isFirstItemSelected = _.some(selectedItems, function (item) {
               return item.value === items[0];
            });

            return selectedItems
               && selectedItems.length > 0
               && items
               && items.length > 0
               && isFirstItemSelected;
         }

         /**
          * Checks if one of the selected items is in last position in the given array.
          */
         function _isLastItemSelected(selectedItems, items) {
            var isLastItemSelected = _.some(selectedItems, function (item) {
               return item.value === items[items.length - 1];
            });
            return selectedItems
               && selectedItems.length > 0
               && items
               && items.length > 0
               && isLastItemSelected;
         }

         /**
          * Check are there new pnics and there are no old pnics in the selected items.
          */
         function _isRemoveButtonEnabled(selectedItems) {
            var isRemoveButtonEnabled;

            if(editAllItems) {
               var isPnicSelected = _.some(selectedItems, function (item) {
                  return !item.categoryItem;
               });

               isRemoveButtonEnabled = !!selectedItems && isPnicSelected;

            } else {
               var isNewPnicSelected = _.some(selectedItems, function (item) {
                  return _isNewPnic(item.value);
               });

               var areAllElementsCategoriesOrNewPnics = _.every(selectedItems, function (item) {
                  return item.categoryItem || _isNewPnic(item.value);
               });

               isRemoveButtonEnabled = !!selectedItems && isNewPnicSelected && areAllElementsCategoriesOrNewPnics;
            }

            return isRemoveButtonEnabled;
         }

         /**
          * Returns options for failover order data grid.
          * @param failoverOrder
          *       Data to initialize the grid with.
          *       Flag if the datagrid should only show unclaimed pnics.
          * @param {vuiConstants.grid.selectionMode} selectionMode
          *
          */
         function _createDatagridOptions(selectionMode) {
            var datagridOptions = {
               columnDefs: _getColumnDefinitions(),
               data: _createDatagridData(),
               pageConfig: {
                  hidePager: true
               },
               selectedItems: [],
               resizable: true,
               height: '100%',
               sortMode: vuiConstants.grid.sortMode.NONE,
               selectionMode: vuiConstants.grid.selectionMode.MULTI,
               showCheckboxesOnMultiSelection: false,
               actionBarOptions: {
                  actions: _getActionDefinitions()
               }
            };
            if(selectionMode) {
               datagridOptions.selectionMode = selectionMode;
            }

            return datagridOptions;
         }

         /**
          * Loops through the given labels and creates the corresponding category items.
          *
          * @param labels
          *       The label names.
          */
         function _createCategories(labels) {
            var categories = {};
            _.forEach(labels, function (value, key) {
               categories[key] = {
                  categoryItem: true,
                  value: value
               };
            });
            return categories;
         }

         /**
          * Maps array of connectees' names to array of objects,
          * cointaining the connectees' names.
          */
         function _mapConnectees(connectees) {
            return _.map(connectees, function (connecteeName) {
               return {
                  value: connecteeName
               };
            });
         }

         /**
          * Categorizes the given data.
          */
         function _createDatagridData() {
            var datagridData = [];
            var categories = _createCategories(failoverOrder.labels);
            _.forEach(categories, function (value, key) {
               datagridData.push(categories[key]);
               if (connectees && connectees[key]) {
                  datagridData = datagridData.concat(_mapConnectees(connectees[key]));
               }
            });
            return datagridData;
         }

         /**
          * Changes the datagrid's datasource and preserves the previously selected items.
          * @param itemsToBeSelected
          *             Array containing objects with property value.
          *             When new pnics are added we are selecting them,
          *             in other cases we use last selected items from the grid.
          */
         function _updateDataGrid (itemsToBeSelected) {
            var selectedItems =
               _.filter(datagridOptions.selectedItems, function(datagridItem) {
                  return !datagridItem.categoryItem;
               });

            selectedItemsBeforeDatagridUpdate = itemsToBeSelected ?
               itemsToBeSelected : selectedItems;

            datagridOptions.data = _createDatagridData();
         }

         /**
          * Creates the action bar items for the datagrid.
          * The unclaimed pnics datagrid does not show actions at all.
          *
          * If there are visibleActions defined, the allActionDefinitions
          * array is filtered according to the directive's definition.
          *
          * @returns
          *       The array of actions.
          */
         function _getActionDefinitions() {
            if (visibleActions) {
               return _.filter(allActionDefinitions, function (action) {
                  return _.indexOf(visibleActions, action.id) !== -1;
               });
            } else {
               return allActionDefinitions;
            }
         }

         /**
          * Add button handler.
          */
         function _addCallback() {
            if (connectees.unclaimed && connectees.unclaimed.length > 0) {
               pnicSelectorDialogService.createPnicSelectorDialog(
                     connectees.unclaimed, failoverOrder.connecteesDetails,
                     vuiConstants.grid.selectionMode.MULTI, null, _add);
            } else {
               var modalOptions = {
                  message: i18nService.getString('NetworkUi', 'FailoverOrderEditor.noFreeNics'),
                  title: i18nService.getString('H5NetworkUi', 'failoverOrder.notification.warning'),
                  icon: "vx-icon-warning-32x32",
                  hideCancelButton: true,
                  preserveNewlines: true,
                  submit: function() {}
               };

               clarityModalService.openConfirmationModal(modalOptions);
            }
         }

         /**
          * Adds selected unclaimed connectees to the list with active ones.
          */
         function _add(selectedConnectees) {
            var itemsToBeSelected = [];
            _.forEach(selectedConnectees, function (connectee) {
               var index = _.indexOf(connectees.unclaimed, connectee);
               connectees.unclaimed.splice(index, 1);
               connectees.active.push(connectee);

               itemsToBeSelected.push({value:connectee});
            });
            _updateDataGrid(itemsToBeSelected);
         }

         /**
          * Remove button handler.
          */
         function _removeCallback() {
            _.each(datagridOptions.selectedItems, function (item) {
               _.some(categories, function (categoryName) {
                  return _seekAndDestroy(categoryName, item);
               });
            });
            datagridOptions.selectedItems.splice(0);

            _updateDataGrid();
         }

         /**
          * Searches for selected connectee and moves it to the unclaimed connectees.
          */
         function _seekAndDestroy(categoryKey, selectedConnectee) {
            var category = connectees[categoryKey];
            var index = _.indexOf(category, selectedConnectee.value);

            if (index !== -1) {
               category.splice(index, 1);
               connectees.unclaimed.push(selectedConnectee.value);
               connectees.unclaimed.sort();
            }
            return index !== -1;
         }

         /**
          * Moveup button callback.
          */
         function _moveUpCallback() {
            _.forEach(datagridOptions.selectedItems, function (selectedConnectee) {
               if(!selectedConnectee.categoryItem){
                  var pnicCategoryName = _getConecteeCategory(categories, selectedConnectee);
                  var pnicCategoryIndex =
                        _getPnicCategoryIndex(connectees[pnicCategoryName], selectedConnectee);

                  _moveUp(selectedConnectee, pnicCategoryIndex, pnicCategoryName);
               }
            });

            _updateDataGrid();
         }

         /**
          * Moves the selected item to the previous category if it is in first position
          * or swaps it with the previous item.
          *
          * @param connectee
          *       The selected item.
          * @param index
          *       The index of the selected item.
          * @param categoryName
          *       The name of the item's category.
          */
         function _moveUp(connectee, index, categoryName) {
            if (index === 0) {
               var previousCategoryName = categories[_.indexOf(categories, categoryName) - 1];
               connectees[previousCategoryName].push(connectee.value);
               connectees[categoryName] = connectees[categoryName].slice(1, connectees[categoryName].length);
            } else {
               _swap(categoryName, index, index - 1);
            }
         }

         /**
          * Movedown button callback.
          */
         function _moveDownCallback() {

            for (var index = datagridOptions.selectedItems.length - 1; index >= 0; index--) {
               var selectedConnectee = datagridOptions.selectedItems[index];

               var pnicCategoryName = _getConecteeCategory(categories, selectedConnectee);
               var pnicCategoryIndex =
                     _getPnicCategoryIndex(connectees[pnicCategoryName], selectedConnectee);

               if(pnicCategoryIndex !== -1) {
                  _moveDown(selectedConnectee, pnicCategoryIndex, pnicCategoryName);
               }
            }
            _updateDataGrid();
         }

         /**
          * Moves the selected item to the next category if it is in last position
          * or swaps it with the next item.
          *
          * @param connectee
          *       The selected item.
          * @param index
          *       The index of the selected item.
          * @param categoryName
          *       The name of the item's category.
          */
         function _moveDown(connectee, index, categoryName) {
            var unusedConnectees = connectees.unused;

            if(unusedConnectees && unusedConnectees.length > 0
               && connectee.value === unusedConnectees[unusedConnectees.length - 1]) {
               return;
            }

            if (index === connectees[categoryName].length - 1) {
               var nextCategoryName = categories[_.indexOf(categories, categoryName) + 1];
               connectees[nextCategoryName].unshift(connectee.value);
               connectees[categoryName] = connectees[categoryName].slice(0, connectees[categoryName].length - 1);
            } else {
               _swap(categoryName, index, index + 1);
            }
         }

         function _getConecteeCategory(categories, conectee) {
            var categoryName;
            _.each(categories, function (category) {
               var index = _.findIndex(connectees[category], function (element) {
                  return element === conectee.value;
               });
               if(index !== -1) {
                  categoryName = category;
               }
            });
            return categoryName;
         }

         function _getPnicCategoryIndex(pnicCategory, selectedConnectee) {
            return _.findIndex(pnicCategory, function (element) {
               return element === selectedConnectee.value;
            });
         }

         /**
          * Swaps two elements in an array.
          *
          * @param categoryName
          *       The name of the item's category.
          * @param indexFrom
          *       The index of the first element to swap.
          * @param indexTo
          *       The index of the second element to swap.
          */
         function _swap(categoryName, indexFrom, indexTo) {
            var tempElement = connectees[categoryName][indexFrom];

            connectees[categoryName][indexFrom] = connectees[categoryName][indexTo];
            connectees[categoryName][indexTo] = tempElement;
         }

         /**
          * Depending on the definition describes either category cell or a general one.
          */
         function _getColumnDefinitions() {
            return [
               {
                  field: 'value',
                  width: '150px',
                  template: function (dataItem) {
                     if(dataItem.categoryItem) {
                        return '<span class="assigned-pnic-sections">' +
                              dataItem.value + '</span>';
                     } else {
                        if (connectees.lagNames && connectees.lagNames.length > 0) {

                           if (_.indexOf(connectees.lagNames, dataItem.value) === -1) {
                              return '<span class="network-lib-ui-icon-physicalAdapter"></span>' +
                                    '<span ng-non-bindable>' + _formatName(dataItem) + '</span>';
                           } else {// is Lag
                              return '<span class="network-lib-ui-icon-portChannel"></span>' +  // icon
                                    '<span ng-non-bindable>' + _formatName(dataItem) + '</span>';
                           }
                        }
                        return '<span class="network-lib-ui-icon-physicalAdapter"></span>' +  // icon
                               '<span ng-non-bindable>' + _formatName(dataItem) + '</span>'; // name
                     }
                  }
               }
            ];
         }

         /**
          * Adds (New) at the beginning of the name if needed.
          */
         function _formatName(dataItem) {
            return (!editAllItems && _isNewPnic(dataItem.value))
                  ? i18nService.getString("NetworkUi", "FailoverOrder.newNicFormat", dataItem.value)
                  : dataItem.value;
         }

         function _isNewPnic(pnic){
               return _.contains(connectees.unclaimedInitial, pnic);
         }
      };
   }
})();
