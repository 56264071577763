/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */

/**
 * Manages the page states of the Import DVS wizard.
 */
namespace dvs_ui {

   export class DvsImportWizardFlowManager {

      public static $inject = [
         "pageStateManagementService",
         "vuiConstants"];

      public pages: WizardPage[];

      constructor(private pageStateManagementService: any) {
      }

      public init(wizardScope: DvsImportWizardScope): void {
         this.pages = wizardScope.wizardConfig.pages;

         this.pageStateManagementService
               .registerWizardForDefaultStateManagementStrategy(wizardScope);
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .service("dvsImportWizardFlowManager", DvsImportWizardFlowManager);
}
