(function() {
   'use strict';
   angular.module('com.vmware.vsphere.client.host').service('vmStartupShutdownService',
         VmStartupShutdownService);

   VmStartupShutdownService.$inject = [
      'i18nService',
      'vuiConstants',
      'vmStartupShutdownConstants',
      'templateHelperService'];

   function VmStartupShutdownService(i18nService, vuiConstants, vmConstants, templateHelperService) {

      function populateDatagrid(autoStartConfig, vmStartupData) {
         var vmsOnHost = _.filter(vmStartupData, function(vm) {
            return vm.isNormalVMOrPrimaryFTVM === true;
         });
         var vmPerCategory = {
            automaticOrdered: [],
            automatic: [],
            manualStartup: []
         };

         if (!autoStartConfig || !autoStartConfig.defaults) {
            return [];
         }

         _.forEach(vmsOnHost, function(vm) {
            createVmItem(vm, autoStartConfig, vmPerCategory);
         });

         vmPerCategory.automaticOrdered.sort(function(a, b) {
            return a.startOrder - b.startOrder;
         });

         vmPerCategory.automaticOrdered.unshift(createCategoryItem("vmStartUp.categoryAutomaticOrdered"));
         vmPerCategory.automatic.unshift(createCategoryItem("vmStartUp.categoryAutomatic"));
         vmPerCategory.manualStartup.unshift(createCategoryItem("vmStartUp.categoryManual"));

         var result = [];
         if (vmPerCategory.automaticOrdered.length > 1) {
            result = result.concat(vmPerCategory.automaticOrdered);
         }

         if (vmPerCategory.automatic.length > 1) {
            result = result.concat(vmPerCategory.automatic);
         }

         if (vmPerCategory.manualStartup.length > 1) {
            result = result.concat(vmPerCategory.manualStartup);
         }

         return result;
      }

      function createDatagridOptions(dialogShowExtendedOptions, callbackStartUp, onEditClick) {
         return {
            selectionMode: vuiConstants.grid.selectionMode.SINGLE,
            sortMode: vuiConstants.grid.sortMode.SINGLE,
            searchable: false,
            resizable: true,
            selectedItems: [],
            actionBarOptions: dialogShowExtendedOptions ? createActionBar(callbackStartUp, onEditClick) : null,
            columnDefs: [
               {
                  field: 'startOrderFormatted',
                  template: templateHelperService.getHtmlEscapingTemplateRenderFunction(
                     '<span class="{{startOrderColumnClasses}}">{{startOrderFormatted}}</span>'
                  ),
                  sortable: false,
                  searchable: false,
                  displayName: i18nService.getString('HostUi', 'vmStartUp.StartupOrder')
               },
               {
                  field: 'name',
                  sortable: false,
                  searchable: false,
                  template: templateHelperService.getHtmlEscapingTemplateRenderFunction(
                     '<span title="{{name}}">{{name}}</span>'
                  ),
                  displayName: i18nService.getString('HostUi', 'vmStartUp.VMColumn')
               },
               {
                  field: 'startUp',
                  sortable: false,
                  searchable: false,
                  template: templateHelperService.getHtmlEscapingTemplateRenderFunction(
                     '{{startUp}}'
                  ),
                  displayName: i18nService.getString('HostUi', 'vmStartUp.StartUpColumn')
               },
               {
                  field: 'startUpDelay',
                  sortable: false,
                  searchable: false,
                  template: templateHelperService.getHtmlEscapingTemplateRenderFunction(
                     '{{startUpDelay}}'
                  ),
                  displayName: i18nService.getString('HostUi', 'vmStartUp.StartUpDelayColumn')
               },
               {
                  field: 'vmwareTools',
                  sortable: false,
                  searchable: false,
                  template: templateHelperService.getHtmlEscapingTemplateRenderFunction(
                     '{{vmwareTools}}'
                  ),
                  displayName: i18nService.getString('HostUi', 'vmStartUp.vmWareToolsColumns'),
                  visible: !!dialogShowExtendedOptions
               },
               {
                  field: 'shutdown',
                  sortable: false,
                  searchable: false,
                  template: templateHelperService.getHtmlEscapingTemplateRenderFunction(
                     '{{shutdown}}'
                  ),
                  displayName: i18nService.getString('HostUi', 'vmStartUp.ShutdownBehaviorColumn')
               },
               {
                  field: 'shutdownDelay',
                  sortable: false,
                  searchable: false,
                  template: templateHelperService.getHtmlEscapingTemplateRenderFunction(
                     '{{shutdownDelay}}'
                  ),
                  displayName: i18nService.getString('HostUi', 'vmStartUp.ShutdownDelayColumn')
               }
            ],
            data: [],
            height: '100%',
            pageConfig: {
               hidePager: true
            }
         };
      }

      var moveUpButton = {
         actionId: vmConstants.state.UP,
         tooltipText: i18nService.getString('HostUi', 'vmStartUp.button.moveUp.description'),
         enabled: false,
         label: i18nService.getString('HostUi', 'vmStartUp.MoveUp'),
         iconClass: 'vx-icon-moveUp'
      };
      var moveDownButton = {
         actionId: vmConstants.state.DOWN,
         tooltipText: i18nService.getString('HostUi', 'vmStartUp.button.moveDown.description'),
         enabled: false,
         label: i18nService.getString('HostUi', 'vmStartUp.MoveDown'),
         iconClass: 'vx-icon-moveDown'
      };
      var editButton = {
         actionId: vmConstants.state.EDIT,
         tooltipText: i18nService.getString('HostUi', 'vmStartUp.Edit'),
         enabled: false,
         label: i18nService.getString('HostUi', 'vmStartUp.Edit'),
         iconClass: 'vx-icon-edit16x16'
      };

      function createActionBar(moveUpAndDown, onEditClickCallback) {
         moveUpButton.onClick = function() {
            moveUpAndDown(vmConstants.state.UP);
         };

         moveDownButton.onClick = function() {
            moveUpAndDown(vmConstants.state.DOWN);
         };
         editButton.onClick = function() {
            onEditClickCallback();

         };
         return {
            actions: [
               moveUpButton, moveDownButton, editButton
            ],
            updateActionBar: function() {
            }
         };
      }

      function createVmItem(vm, autoStartConfig, vmPerCategory) {

         var powerInfo = findPowerInfoForVm(autoStartConfig, vm.provider);
         var autoStartDefaults = autoStartConfig.defaults;

         if (!powerInfo) {
            powerInfo = autoStartDefaults;
         }

         var vmListItem = getVmListItem(vm, powerInfo, autoStartDefaults);

         switch (vmListItem.category) {
            case vmConstants.category.MANUAL:
               vmPerCategory.manualStartup.push(vmListItem);
               break;
            case vmConstants.category.AUTOMATIC_ORDERED:
               vmPerCategory.automaticOrdered.push(vmListItem);
               break;
            default:   //case "Automatic":
               vmPerCategory.automatic.push(vmListItem);
         }
      }

      function getVmListItem(vm, powerInfo, autoStartDefaults) {
         var startOrder = powerInfo.startOrder || -1;
         var startOrderFormatted = startOrder > -1 ? startOrder : "";

         var vmListItem = {
            startOrder: startOrder,
            startOrderFormatted: startOrderFormatted,
            startOrderColumnClasses: 'automatic-order',
            name: vm.name,
            startUp: function() {
               if (powerInfo.startAction &&
                     powerInfo.startAction.toLowerCase() === vmConstants.startAction.POWER_ON_LOWERCASE) {
                  return i18nService.getString('HostUi', 'vmStartUp.vmStartupEnabled');
               } else {
                  return i18nService.getString('HostUi', 'vmStartUp.vmStartupDisabled');
               }
            }(),
            startUpDelay: powerInfo.startDelay !== vmConstants.SYSTEM_DEFAULT_DELAY ?
                  powerInfo.startDelay :
                  autoStartDefaults.startDelay,
            shutdown: getShutdownBehaviorLabel(powerInfo.stopAction, autoStartDefaults),
            shutdownDelay: powerInfo.stopDelay !== vmConstants.SYSTEM_DEFAULT_DELAY ?
                  powerInfo.stopDelay :
                  autoStartDefaults.stopDelay,
            vmwareTools: getVMwareTools(powerInfo, autoStartDefaults),
            powerInfoKey: powerInfo.key
         };

         if (!powerInfo.startAction || (powerInfo.startAction === vmConstants.startAction.NONE)) {
            vmListItem.category = vmConstants.category.MANUAL;
         } else if (powerInfo.startOrder > -1) {
            vmListItem.category = vmConstants.category.AUTOMATIC_ORDERED;
         } else {
            vmListItem.category = vmConstants.category.AUTOMATIC;
         }
         return vmListItem;
      }

      function getVMwareTools(powerInfo, autoStartDefaults) {
         if (powerInfo === autoStartDefaults ||
               powerInfo.waitForHeartbeat === vmConstants.heartbeat.SYSTEM_DEFAULT) {
            if (!!autoStartDefaults.waitForHeartbeat) {
               return i18nService.getString('HostUi', 'vmStartUp.toolsContinueLabel');

            } else {
               return i18nService.getString('HostUi', 'vmStartUp.toolsWaitStatupLabel');
            }
         } else {
            if (powerInfo.waitForHeartbeat === vmConstants.heartbeat.YES) {
               return i18nService.getString('HostUi', 'vmStartUp.toolsContinueLabel');
            } else {
               return i18nService.getString('HostUi', 'vmStartUp.toolsWaitStatupLabel');
            }
         }
      }

      function createCategoryItem(category) {
         return {
            startOrder: -1,
            startOrderFormatted: i18nService.getString('HostUi', category),
            startOrderColumnClasses: 'vm-startup-category',
            name: "",
            startUp: "",
            startUpDelay: "",
            shutdown: "",
            shutdownDelay: ""
         };
      }

      function getShutdownBehaviorLabel(behavior, defaultConfig) {
         switch (behavior.toLowerCase()) {
            case 'poweroff':
               return i18nService.getString('HostUi', 'vmStartUp.actionPowerOff');
            case 'suspend':
               return i18nService.getString('HostUi', 'vmStartUp.actionPowerSuspend');
            case 'none':
               return i18nService.getString('HostUi', 'vmStartUp.actionNone');
            case 'guestshutdown':
               return i18nService.getString('HostUi', 'vmStartUp.actionGuestShutdown');
            case 'systemdefault':
               return getShutdownBehaviorLabel(defaultConfig.stopAction, null);
            default:
               return '';
         }
      }

      function findPowerInfoForVm(autoStartConfig, vmProvider) {
         return _.find(autoStartConfig.powerInfo, function(possiblePowerInfo) {
            return _.isEqual(vmProvider, possiblePowerInfo.key);
         });
      }

      return {
         populateDatagrid: populateDatagrid,
         createDatagridOptions: createDatagridOptions,
         findPowerInfoForVm: findPowerInfoForVm
      };
   }
})();
