/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
(function () {
   'use strict';
   angular.module('com.vmware.vsphere.client.network')
      .controller('CreateStandardSwitchPageController', CreateStandardSwitchPageController);

   CreateStandardSwitchPageController.$inject = [
      '$scope',
      'i18nService',
      'physicalAdaptersListService'];

   function CreateStandardSwitchPageController (
         $scope, i18nService, physicalAdaptersListService) {

      this.noItemsSelectedMessage = i18nService.getString('NetworkUi',
            'FailoverOrderEditor.detailsPrompt');

      this.headerOptions = {
         title: i18nService.getString("NetworkUi", "FailoverOrderEditor.failoverOrder")
      };

      if (typeof $scope.vSwitchSpec.pnics === 'undefined'
            || $scope.vSwitchSpec.pnics === null) {
         $scope.vSwitchSpec.pnics = {
            active: [],
            standby: [],
            unused: [],
            unclaimed: []
         };
         physicalAdaptersListService.getUnclaimedPhysicalAdapters($scope.hostId)
               .then(function (data) {
                  $scope.vSwitchSpec.pnics.unclaimed = data.unclaimed;
                  $scope.vSwitchSpec.pnicsDetails = data.pnicDetails;
                  // Initialize the failoverOrder's directive's data so that
                  // the ng-if in the template triggers the directive's rendering.
                  this.failoverOrder = _buildFailoverOptions();
               }.bind(this));
      } else {
         this.failoverOrder = _buildFailoverOptions();
      }

      function _buildFailoverOptions() {
         return {
            connectees: $scope.vSwitchSpec.pnics,
            connecteesDetails: $scope.vSwitchSpec.pnicsDetails,
            labels: {
               active: i18nService.getString("NetworkUi", "FailoverOrderEditor.active"),
               standby: i18nService.getString("NetworkUi", "FailoverOrderEditor.standby"),
               unused: i18nService.getString("NetworkUi", "FailoverOrderEditor.unused")
            }
         };
      }
      return this;
   }
}) ();
