/* Copyright 2018 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace h5_vm {

   /**
    * A "builder" for the ScheduleTaskPageService.
    */
   export class VmCreateScheduleTaskPageService {
      static $inject: string[] = [
         "i18nService",
         "vmVuiWizardPageBuilder",
         "creationTypeConstants",
         "vmWizardScheduleTaskPageModel"
      ];

      constructor(private i18nService: any,
                  private vmVuiWizardPageBuilder: any,
                  private creationTypeConstants: any,
                  private vmWizardScheduleTaskPageModel: any) {
      }

      public build(wizardScope: any): VmProvisioningWizardPage {

         let vmWizardScheduleTaskPageModel =
               new this.vmWizardScheduleTaskPageModel();
         vmWizardScheduleTaskPageModel.mutationSpec = wizardScope.scheduleTaskData.schedulingData;
         vmWizardScheduleTaskPageModel.isScheduledTaskFlow =
               wizardScope.scheduleTaskData.isScheduledTask;
         wizardScope.vmWizardScheduleTaskPageModel = vmWizardScheduleTaskPageModel;

         let page = this.vmVuiWizardPageBuilder.buildVuiWizardPage(wizardScope.config, {
            title: this.i18nService.getString("ScheduledOpsUi", "Wizard.scheduleTaskPage.title"),
               contentUrl: "vm-ui/resources/vm/common/scheduleTaskWizard/scheduleTaskPage/vmCreateWizardSchedulePage.html",
            model: vmWizardScheduleTaskPageModel
         });
         return page;
      }
   } // class

   angular.module("com.vmware.vsphere.client.vm").service(
         "vmCreateScheduleTaskPageService",
         VmCreateScheduleTaskPageService);

} // namespace
