/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace dvs_ui {

   import NetworkResourcePoolData = com.vmware.vsphere.client.h5.network.dvs.ports.model.DvsPortPropertiesModel$NetworkResourcePoolData;
   export class DvsPortEditPropertiesPage {

      public bindings: any;
      public controller: any;
      public templateUrl: string;

      constructor() {
         this.bindings = {
            pageModel: "<",
            isLagOrUplink: "<",
            disableNetworkResourcePool: "<"
         };

         this.controller = DvsPortEditPropertiesPageController;

         this.templateUrl = "dvs-ui/resources/dvs/ports/edit/components/" +
               "dvsPortEditPropertiesPageComponentTemplate.html";
      }
   }

   class DvsPortEditPropertiesPageController {

      static $inject = [
         "$scope",
         "i18nService",
         "dvsPortEditPropertiesPageValidator"
      ];

      public i18n: Function;

      public pageModel: DvsPortEditPropertiesPageModel;

      public nameValidator: any;

      public descriptionValidator: any;

      public resourcePoolOptions: any[];

      public disableNetworkResourcePool: boolean;

      constructor(private $scope: any,
                  private i18nService: any,
                  private dvsPortEditPropertiesPageValidator: any) {
         this.i18n = this.i18nService.getString;
         this.nameValidator = {
            message: null,
            isVisible: false
         };
         this.descriptionValidator = {
            message: null,
            isVisible: false
         };

      }

      $onInit(): void {
         if (this.pageModel.networkResourcePools
               && this.pageModel.networkResourcePools.length) {

            this.resourcePoolOptions = [];
            _.forEach(this.pageModel.networkResourcePools,
                  (resourcePool: NetworkResourcePoolData): void => {
               this.resourcePoolOptions.push({
                  label: resourcePool.name,
                  val: resourcePool.key
               });
            });
         }
      }

      public onNameChange(): void {
         let message: string = "";

         if (this.pageModel.name) {
            message = this.dvsPortEditPropertiesPageValidator
                  .getNameError(this.pageModel.name);
         }

         this.nameValidator.message = message;
         this.nameValidator.isVisible = !!message;
      }

      public onDescriptionChange(): void {
         let message: string = "";

         if (this.pageModel.description) {
            message = this.dvsPortEditPropertiesPageValidator
                  .getDescriptionError(this.pageModel.description);
         }

         this.descriptionValidator.message = message;
         this.descriptionValidator.isVisible = !!message;
      }
   }


   angular.module("com.vmware.vsphere.client.dvs")
         .component("dvsPortEditPropertiesPage", new DvsPortEditPropertiesPage());
}