/* Copyright 2015 VMware, Inc. All rights reserved. -- VMware Confidential */

(function () {
   'use strict';

   angular
      .module('com.vmware.vsphere.client.vm')
      .controller('VmProvisioningFinishController', VmProvisioningFinishController);

   VmProvisioningFinishController.$inject = [
      '$scope',
      'datastoreRecommendationService',
      'i18nService'
   ];

   function VmProvisioningFinishController(
      $scope,
      datastoreRecommendationService,
      i18nService
   ) {
      var self = this;
      var model = $scope.vmProvisioningFinishCreateVmPageModel;

      var crInfo = model.getComputeResourceInfo();
      $scope.computeResourceName = crInfo.name;
      $scope.computeResourceLabel = crInfo.label;

      //Wrap in anonymous function in order to preserve the functions "this" context.
      //Otherwise it's replaced by the $scope's this.
      self.datastoreLabel = model.presentTargetStorageName();
      self.isStorageProfileAvailable = function() {
         return model.isStorageProfileSpecified();
      };

      self.isVmHomeEncrypted = function() {
         return model.isVmHomeEncrypted();
      };
      self.areAllDisksEncrypted = function() {
         return model.areAllDisksEncrypted();
      };

      $scope.numberOfCpus = model.getNumberOfCpus();
      $scope.allocatedMemory = model.getAllocatedMemory();
      $scope.vmVersion = model.getVmVersionCompatibility();
      $scope.vmName = model.getVmName();
      $scope.targetFolderName = model.getTargetFolderName();
      $scope.vmStorageProfile = model.getStorageProfile();
      $scope.vmReplicationGroupLabel = model.getReplicationGroup();
      $scope.guestOsName = model.getGuestOsName();
      $scope.vbsEnabled = model.getVbsEnabled();
      $scope.networkDevices = model.getNetworkInterfaces();
      $scope.disks = model.getDisks();
      $scope.scsiControllers = model.getScsiControllers();
      $scope.tpmDeviceExists = !!model.getTpmDevice();
      $scope.nvdimmDevices = model.getNvdimmDevicesInfo();

      $scope.config.loadingMessage =
            i18nService.getString('CommonUi', 'wizard.loading');
      $scope.config.loading = true;
      model.fetchStoragePlacementRecommendations().then(function() {
         self.recommendationFaults = [];
      }, function(faults) {
         self.recommendationFaults = faults;
      }).then(function() {
         $scope.config.loading = false;
         self.datastoreLabel = model.presentTargetStorageName();
         model.setDisksDatastoreInfo($scope.disks);
      });

      self.openFaultsDialog = function() {
         datastoreRecommendationService.openFaultsDialog($scope, self.recommendationFaults);
      };

      self.openRecommendationsDialog = function(disk) {
         datastoreRecommendationService.openRecommendationsDialog(disk ? disk.diskKey : undefined, $scope, model)
               .then(function() {
                  self.datastoreLabel = model.presentTargetStorageName();
                  model.setDisksDatastoreInfo($scope.disks);
               });
      };

      self.shouldDisplayRecommendations = function (disk) {
         var storageObject;
         if(disk){
            storageObject = disk.getStorageObject();
         }
         else{
            storageObject = model.virtualMachineSpecBuilder.getStorageObject();
         }

         return datastoreRecommendationService
               .areRecommendationsAvailableForStorageObject(storageObject);
      };

      self.isNewDisk = function(disk) {
         return disk.diskKey < 0;
      };

      self.shouldShowStorageProfile = function(diskObject) {
         // The disk object has the disk key in "diskKey" property
         var diskKey = {
            key: diskObject.diskKey
         };
         return !model.diskHasDefaultStoragePolicy(diskKey);
      };
   }
})();
