(function() {
   'use strict';

   angular.module('com.vmware.vsphere.client.vm')
      .controller('VmProvisioningSelectTemplateController', VmProvisioningSelectTemplateController);

   VmProvisioningSelectTemplateController.$inject = [
      '$scope',
      '$element',
      '$timeout',
      'i18nService',
      'contentLibraryService',
      'createVmChangeCreationTypeService',
      'creationTypeConstants',
      'vuiConstants'
   ];

   function VmProvisioningSelectTemplateController(
         $scope, $element, $timeout, i18nService, contentLibraryService,
         createVmChangeCreationTypeService, creationTypeConstants, vuiConstants) {
      var self = this;
      var loadingMessage = i18nService.getString('CommonUi', 'wizard.loading');

      self.pageModel = $scope.selectAnyTemplateModel;
      self.pageModel.vcTemplatePageModel.showLoadingIndicator = false;

      self.clTemplateTabLabel = i18nService.getString('VmUi',
            'SelectTemplateProvisioningPage.clTemplateTab.label');
      self.vcTemplateTabLabel = i18nService.getString('VmUi',
            'SelectTemplateProvisioningPage.vcTemplateTab.label');
      self.isVcTabSelected = self.pageModel.getIsVcTemplateSelected();

      self.datagridOptions = {
         columnDefs: [
            {
               displayName: i18nService.getString('VmUi',
                     'SelectTemplateProvisioningPage.clTemplateTab.templateName.label'),
               field: 'templateName'
            },
            {
               displayName: i18nService.getString('VmUi',
                     'SelectTemplateProvisioningPage.clTemplateTab.libraryName.label'),
               field: 'libraryName'
            }
         ],
         selectionMode: vuiConstants.grid.selectionMode.SINGLE,
         selectedItems: []
      };

      // focus the first tab on the page
      $timeout(function () {
         var firstTab = $element[0].querySelector("clr-tab-link");
         firstTab.querySelector("button").focus();
      }, 0);

      self.watchSelectedItems = function() {

      $scope.$watch(function() {
               return self.datagridOptions.selectedItems;
            },
            function(selectedItems) {
               var selectedItem = selectedItems && selectedItems.length ?
                     selectedItems[0] : undefined;
               self.pageModel.setClTemplateSelection(selectedItem);
               if (selectedItem) {
                  // If something was selected in the library template list then we need to
                  // clear the selection in the other tab and set the correct creation type
                  // so that the workflow path changes.
                  clearVcTemplateSelection();
                  changeCreationType(creationTypeConstants.DEPLOY_VM_FROM_TEMPLATE);
               }
            }
      );
      };

      $scope.clPreselectComparator = function(gridDataItem) {
         var itemToSelect = self.pageModel.getClTemplateSelection();
         if (itemToSelect) {
            return itemToSelect.templateId === gridDataItem.templateId;
         }
         return false;
      };

      self.onVcTemplateSelectionChanged = function() {
         if (self.pageModel.getVcTemplateSelection()) {
            // If something was selected in the vsphere tree view then we need to
            // clear the selection in the other tab and set the correct creation type
            // so that the workflow path changes.
            clearClTemplateSelection();
         }

         if (self.pageModel.getIsVcTemplateSelected()) {
            changeCreationType(creationTypeConstants.CLONE_TEMPLATE_TO_VM);
         }
      };

      var sourceObjectId = $scope.wizardViewData.getSourceObjectId();
      if ($scope.wizardViewData.defaultTarget) {
         sourceObjectId = $scope.wizardViewData.defaultTarget;
      }

      setLoadingIndicator(true);
      contentLibraryService.getAllTemplates(sourceObjectId).then(function(templateData) {
         var templateProperty, libraryProperty, gosTypeProperty, gosDescriptorProperty;
         var templateDataGridContent = _.map(templateData.libraryTemplates.resultSet[0].items, function(item) {
            templateProperty = _.find(item.properties, function(prop) {
               return (prop.propertyName === 'com.vmware.content.library.ItemModel/name');
            });
            libraryProperty = _.find(item.properties, function(prop) {
               return (prop.propertyName === 'com.vmware.content.library.ItemModel/libraryName');
            });
            gosTypeProperty = _.find(item.properties, function(prop) {
                return (prop.propertyName === 'com.vmware.content.type.ovf.OvfTemplate/vmTemplate/osType');
            });
            gosDescriptorProperty = _.find(item.properties, function(prop) {
               return (prop.propertyName === 'com.vmware.content.type.ovf.OvfTemplate/vmTemplate/osDescription');
            });
            return {
               templateName: templateProperty.value,
               libraryName: libraryProperty.value,
               templateId: item.resourceObject,
               gosType: gosTypeProperty.value,
               gosDescription: gosDescriptorProperty.value
            };
         });
         self.datagridOptions.data = templateDataGridContent;
         // preselect
         var selection = self.pageModel.getClTemplateSelection();
         var selectedItem = selection && _.find(self.datagridOptions.data, function(item) {
            return (item.templateId === selection.templateId);
         });
         if (selectedItem) {
            self.datagridOptions.selectedItems = [selectedItem];
         }

      }).then(function() {
         setLoadingIndicator(false);
         self.watchSelectedItems();
      });

      function setLoadingIndicator(toggle) {
         $scope.config.loadingMessage = loadingMessage;
         $scope.config.loading = toggle;
      }

      function clearClTemplateSelection() {
         var grid = $element.find('.library-template-selector [kendo-grid]');
         if(grid && grid.data('kendoGrid')) {
            grid.data('kendoGrid').clearSelection();
         }
      }

      function clearVcTemplateSelection() {
         var tree = $element.find('[vx-tree-view]');
         tree.data("kendoTreeView").select($());
         // For some reason deselect does not trigger onChange() in the vc template
         // selector so we need to clear the model directly
         self.pageModel.getVcTemplatePageModel().clearSelection();
      }

      function changeCreationType(selectedCreationType) {
         createVmChangeCreationTypeService.changeCreationType(
               $scope, {
                  creationType: selectedCreationType,
                  wizardTitle: i18nService.getString('VmUi',
                        'ProvisioningTypes.DeployFromTemplate.WizardTitle.1')
               });
      }
   }
})();
