namespace h5_vm {
   import ManagedObjectReference = com.vmware.vim.binding.vmodl.ManagedObjectReference;
   import IScope = angular.IScope;
   import Specification = com.vmware.vim.binding.vim.vm.customization.Specification;
   import CustomizationSpecInfo = com.vmware.vim.binding.vim.CustomizationSpecInfo;
   import OperationResult = com.vmware.vise.core.model.OperationResult;
   import SysprepText = com.vmware.vim.binding.vim.vm.customization.SysprepText;

   class GuestOsCustomizationListController {
      public static $inject: string[] = [
         "$scope",
         "i18nService",
         "userSessionService",
         "vuiConstants",
         "gosCustomizationListService",
         "columnRenderersRegistry",
         "gosCustomizationHelperService",
         "vcH5ConstantsService",
         "timeFormatterService"];

      private vCentersData: any[];
      private selectedSpecInfo?: CustomizationSpecInfoCustom;
      private selectedSpecDetails?: Specification;
      private selectedSpecXmlText?: string;
      private selectedSpecDetailsNicIpSettings: any[];
      private selectedSpecDetailsDnsSuffixList: string[];
      private invokeEditAction: boolean = false;
      private preselectItemName: string | undefined;
      private savedDateTimeFormat: any;

      public loadingLabel: string;
      public isLoadingSpecInfos: boolean;
      public isLoadingSpecDetails: boolean;
      public displayGrid: boolean;
      public displayNoPermissions: boolean;
      /**
       * Used to disable actions on the action bar after the user invokes one.
       * Prevents timing issues and possible server errors, when for example
       * duplicating a GOS spec twice, if user double-clicks the button.
       */
      public contextActionsDisabled: boolean;
      public gridOptions: any;
      public splitterOptions: any;
      public preselectComparator: Function;

      constructor(private $scope: any,
                  private i18nService: any,
                  private userSessionService: any,
                  private vuiConstants: any,
                  private listService: GosCustomizationListService,
                  private columnRenderersRegistry: any,
                  private helperService: GosCustomizationHelperService,
                  private vcH5ConstantsService: any,
                  private timeFormatterService: any) {

         this.$scope.i18n = this.i18nService.getString;
         this.displayNoPermissions = false;
         this.displayGrid = false;
         this.isLoadingSpecInfos = false;
         this.isLoadingSpecDetails = false;
         this.loadingLabel = this.i18nService.getString("Common", "loadingTitle");
         this.gridOptions = this.createDataGridOptions();
         this.selectedSpecInfo = undefined;
         this.selectedSpecDetails = undefined;
         this.selectedSpecXmlText = undefined;
         this.selectedSpecDetailsNicIpSettings = [];
         this.selectedSpecDetailsDnsSuffixList = [];

         this.updateActionBar();

         $scope.$on(this.vcH5ConstantsService.DATA_REFRESH_INVOCATION_EVENT, () => {
            this.retrieveVCentersData();
         });
         this.retrieveVCentersData();

         this.splitterOptions = {
            orientation: this.vuiConstants.splitter.orientation.VERTICAL,
            panes: [
               {
                  min: "250px",
                  size: "50%"
               }, {
                  min: "250px",
                  size: "50%"
               }
            ]
         };

         this.$scope.$watch(() => {
            return this.gridOptions.selectedItems;
         }, (newSelection?: Array<any>, oldSelection?: Array<any>) => {
            this.onSpecInfoChanged.call(this, newSelection, oldSelection);
         });

         this.preselectComparator = (gridItem: any): boolean => {
            return this.preselectComparatorInternal(gridItem);
         };
      }

      public getSelectedSpecName(): string {
         return this.selectedSpecInfo
               ? this.selectedSpecInfo.name
               : "";
      }

      public getSelectedSpecType(): string {
         return this.selectedSpecInfo
               ? this.selectedSpecInfo.type
               : "";
      }

      public getSelectedSpecDescription(): string {
         return this.selectedSpecInfo
               ? this.selectedSpecInfo.description
               : "";
      }

      public isSelectedSpecDetailsLinuxPrep(): boolean {
         return this.helperService.isLinuxPrep(this.selectedSpecDetails);
      }

      public isSelectedSpecDetailsSysPrep(): boolean {
         return this.helperService.isSysPrep(this.selectedSpecDetails);
      }

      public isSelectedSpecDetailsSysPrepText(): boolean {
         return this.helperService.isSysPrepText(this.selectedSpecDetails);
      }

      public getSelectedSpecDetailsComputerName(): string {
         return this.helperService.getComputerName(this.selectedSpecDetails);
      }

      public getSelectedSpecDetailsTimeZone(): string {
         return this.helperService.getTimeZone(this.selectedSpecDetails);
      }

      public getSelectedSpecDetailsTimeZoneArea(): string {
         return this.helperService.getTimeZoneArea(this.selectedSpecDetails);
      }

      public getSelectedSpecDetailsTimeZoneLocation(): string {
         return this.helperService.getTimeZoneLocation(this.selectedSpecDetails);
      }

      public getSelectedSpecDetailsHardwareClock(): string {
         return this.helperService.getHardwareClock(this.selectedSpecDetails);
      }

      public isSelectedSpecDetailsNetworkTypical(): boolean {
         return this.helperService.isNetworkTypical(this.selectedSpecDetails);
      }

      public getSelectedSpecDetailsNicIpSettings(): any[] {
         return this.selectedSpecDetailsNicIpSettings;
      }

      public hasOsOptions(): boolean {
         return !!(this.selectedSpecDetails && this.selectedSpecDetails.options);
      }

      public hasDnsServers(): boolean {
         return !_.isEmpty(this.helperService.getDnsServers(this.selectedSpecDetails));
      }

      public getPrimaryDnsServer(): string {
         return this.helperService.getPrimaryDnsServer(this.selectedSpecDetails);
      }

      public getSecondaryDnsServer(): string {
         return this.helperService.getSecondaryDnsServer(this.selectedSpecDetails);
      }

      public getTertiaryDnsServer(): string {
         return this.helperService.getTertiaryDnsServer(this.selectedSpecDetails);
      }

      public getSelectedSpecDetailsDnsAndDomain(): string {
         return this.helperService.getDnsAndDomain(this.selectedSpecDetails);
      }

      public getSelectedSpecDetailsDnsSuffixList(): string[] {
         return this.selectedSpecDetailsDnsSuffixList;
      }

      public getSelectedSpecDetailsRegistrationInfo(): string {
         return this.helperService.getRegistrationInfo(this.selectedSpecDetails);
      }

      public getSelectedSpecDetailsWindowsLicenseKeyHeader(): string {
         return this.helperService.getWindowsLicenseKeyHeader(this.selectedSpecDetails);
      }

      public getSelectedSpecDetailsWindowsLicenseKey(): string {
         return this.helperService.getWindowsLicenseKey(this.selectedSpecDetails);
      }

      public isSelectedSpecDetailsServerLicensingIncluded(): boolean {
         return this.helperService.isServerLicensingIncluded(this.selectedSpecDetails);
      }

      public getSelectedSpecDetailsServerLicensingInfo(): string {
         return this.helperService.getServerLicensingInfo(this.selectedSpecDetails);
      }

      public isSelectedSpecDetailsAutoLogon(): boolean {
         return this.helperService.isAutoLogon(this.selectedSpecDetails);
      }

      public getSelectedSpecDetailsAutoLogons(): number {
         return this.helperService.getAutoLogonCount(this.selectedSpecDetails);
      }

      public getSelectedSpecDetailsWorkgroup(): string {
         return this.helperService.getWorkgroup(this.selectedSpecDetails);
      }

      public getSelectedSpecDetailsDomain(): string {
         return this.helperService.getDomain(this.selectedSpecDetails);
      }

      public isSelectedSpecDetailsGenerateSID(): boolean {
         return this.helperService.generateNewSID(this.selectedSpecDetails);
      }

      public getRunOnceCommands(): string[] {
         return this.helperService.getRunOnceCommands(this.selectedSpecDetails);
      }

      private onSpecInfoChanged(newSelection?: Array<any>, oldSelection?: Array<any>) {
         if (newSelection === oldSelection || _.isEqual(newSelection, oldSelection)) {
            return;
         }

         if (!newSelection || newSelection.length === 0 || newSelection.length > 1) {
            this.selectedSpecInfo = undefined;
            this.selectedSpecDetails = undefined;
            this.selectedSpecXmlText = undefined;
            this.preselectItemName = undefined;
            this.selectedSpecDetailsNicIpSettings = [];
            this.selectedSpecDetailsDnsSuffixList = [];
            this.updateActionBar();
         } else {
            this.selectedSpecInfo = newSelection[0];
            this.preselectItemName = newSelection[0].name;
            this.retrieveSpecDetails();
         }
      }

      public getSysPrepText(): string {
         if (!this.selectedSpecDetails) {
            return "";
         }
         return (this.selectedSpecDetails.identity as SysprepText).value;
      }

      public retrieveVCentersData(): void {
         // retrieve the user's preferred time format and use it when displaying the
         // `Last Modified` column
         this.timeFormatterService.getUserTimeFormatPreference().then((format: any): void => {
            this.savedDateTimeFormat = format;
         });

         this.userSessionService.getAllServersInfo().then((session: any) => {
            // if there are no VCs to begin with disable the actions but do not show
            // missing privileges error
            if (session.serversInfo && session.serversInfo.length === 0) {
               this.updateActionBar(true);
               this.displayGrid = true;
               return;
            }

            this.listService.filterVcs(session.serversInfo).then((filteredVcs: VcInfo[]): void => {
               this.vCentersData = filteredVcs;
               if (this.vCentersData && this.vCentersData.length > 0) {
                  this.retrieveSpecInfos();
                  this.displayGrid = true;
               } else {
                  this.displayNoPermissions = true;
               }
            });

         });
      }

      private retrieveSpecInfos(): angular.IPromise<void> {
         this.isLoadingSpecInfos = true;
         return this.listService.retrieveSpecInfos(this.vCentersData)
               .then((data: CustomizationSpecInfoCustom[]) => {
                  this.gridOptions.data = data;
               })
               .then(() => {
                  this.isLoadingSpecInfos = false;
                  if (this.contextActionsDisabled) {
                     this.contextActionsDisabled = false;
                     this.updateActionBar();
                  }
               });
      }

      /**
       * Retrieves the customization spec details for the selected spec
       * and passes it to the details view
       */
      private retrieveSpecDetails(): void {
         if (!this.selectedSpecInfo) {
            return;
         }

         this.isLoadingSpecDetails = true;
         this.listService.retrieveSpecs(this.selectedSpecInfo.vcService, this.selectedSpecInfo.name)
               .then((data: any) => {
                  this.selectedSpecDetails = data.customizationSpec.spec;
                  this.selectedSpecXmlText = data.specItemXMLText;
                  this.selectedSpecDetailsNicIpSettings =
                        this.helperService.getNicIpSettings(this.selectedSpecDetails);
                  this.selectedSpecDetailsDnsSuffixList =
                        this.helperService.getDnsSuffixList(this.selectedSpecDetails);
               })
               .then(() => {
                  this.isLoadingSpecDetails = false;
                  return this.updateActionBar();
               }).then(() => {
                  // if the edit action has to be invoked for the preselected item
                  // invoke it and clean the flag so that it is not invoked again
                  if (this.invokeEditAction) {
                     this.listService.invokeEdit(
                           this.selectedSpecInfo,
                           this.selectedSpecDetails,
                           this.gridOptions.data,
                           this.onActionInvoked.bind(this)
                     );
                     this.invokeEditAction = false;
                  }
               });
      }

      private createDataGridOptions(): any {
         let linkRenderer: Function =
               this.columnRenderersRegistry.getColumnRenderer("vcenter-name");

         return {
            selectionMode: this.vuiConstants.grid.selectionMode.SINGLE,
            sortMode: this.vuiConstants.grid.sortMode.SINGLE,
            searchable: true,
            resizable: true,
            reorderable: false,
            selectedItems: [],
            columnDefs: [
               {
                  field: "name",
                  sortable: true,
                  searchable: true,
                  displayName: this.i18nService
                        .getString("VmUi", "customizationManager.customizationSpecList.nameColumnHeader")
               },
               {
                  field: "type",
                  sortable: true,
                  searchable: true,
                  displayName: this.i18nService
                        .getString("VmUi", "customizationManager.customizationSpecList.guestOsColumnHeader")
               },
               {
                  field: "lastUpdateTime",
                  sortable: true,
                  searchable: false,
                  displayName: this.i18nService
                        .getString("VmUi", "customizationManager.customizationSpecList.lastModifiedColumnHeader"),
                  template: (spec: any) => {
                     return this.timeFormatterService.timestampToText(
                           parseInt(spec.lastUpdateTime), this.savedDateTimeFormat);
                  }
               },
               {
                  field: "vCenterName",
                  sortable: true,
                  searchable: true,
                  displayName: this.i18nService.getString("Common", "vcColumnHeader"),
                  template: (data: any) => {
                     return linkRenderer(["vCenterName"], data, {navigatable: false});
                  }
               }
            ],
            data: [],
            height: "100%",
            columnMenu: {
               sortable: false,
               messages: {
                  columns: this.i18nService.getString("CommonUi", "listview.showColumnsMenu"),
                  filter: this.i18nService.getString("CommonUi", "listview.filterMenu")
               }
            },
         };
      }

      private updateActionBar(contextActionsDisabled: boolean = false): IPromise<void> {
         return this.listService.updateActionBar(this.gridOptions, this.selectedSpecInfo,
               this.selectedSpecDetails, this.selectedSpecXmlText,
               this.onActionInvoked.bind(this),
               this.beforeActionInvoked.bind(this),
               contextActionsDisabled);
      }

      private preselectComparatorInternal(dataGridItem: any): boolean {
         let preselect: boolean = false;
         if (this.preselectItemName) {
            preselect = dataGridItem.name === this.preselectItemName;
         }
         return preselect;
      }

      private onActionInvoked(actionId: string, opResult: OperationResult, invokeEdit: boolean) {
         if (!actionId) {
            return;
         }

         switch (actionId) {
            case "vsphere.core.vm.gos.customization.new":
            case "vsphere.core.vm.gos.customization.edit":
               this.onSpecCreated(opResult);
               break;
            case "vsphere.core.vm.gos.customization.duplicate":
               this.onSpecDuplicated(opResult, invokeEdit);
               break;
            case "vsphere.core.vm.gos.customization.import":
               this.onSpecImported(opResult, invokeEdit);
               break;
            case "vsphere.core.vm.gos.customization.delete":
               this.onSpecDeleted(opResult);
               break;
            default:
               break;
         }
      }

      private beforeActionInvoked(): void {
         this.contextActionsDisabled = true;
         this.updateActionBar(this.contextActionsDisabled);
      }

      private onSpecCreated(opResult: OperationResult) {
         this.retrieveSpecInfos().then(() => {
            this.preselectItemName = opResult.result;
         });
      }

      private onSpecDuplicated(opResult: OperationResult, invokeEdit: boolean): void {
         this.retrieveSpecInfos().then(() => {
            this.preselectItemName = opResult.result;
            this.invokeEditAction = invokeEdit;
         });
      }

      private onSpecImported(opResult: OperationResult, invokeEdit: boolean): void {
         this.retrieveSpecInfos().then(() => {
            this.preselectItemName = opResult.result.info.name;
            this.invokeEditAction = invokeEdit;
         });
      }

      private onSpecDeleted(opResult: OperationResult) {
         // User canceled the delete
         if (!opResult) {
            this.contextActionsDisabled = false;
            this.updateActionBar();
            return;
         }
         let deletedSpecName: string = opResult.result;
         this.setPreselectNameOnDelete(deletedSpecName);
         this.retrieveSpecInfos();
      }

      private setPreselectNameOnDelete(deletedSpecName: string): void {
         // last remaining GOS spec is deleted
         if (this.gridOptions.data.length === 1
               && this.gridOptions.data[0].name === deletedSpecName) {
            this.preselectItemName = undefined;
            return;
         }
         for (let i: number = 0; i < this.gridOptions.data.length; i++) {
            if (this.gridOptions.data[i].name === deletedSpecName) {
               if (i + 1 < this.gridOptions.data.length) {
                  this.preselectItemName = this.gridOptions.data[i + 1].name;
                  return;
               } else {
                  this.preselectItemName = this.gridOptions.data[i - 1].name;
                  return;
               }
            }
         }
      }
   }

   export class GuestOsCustomizationListComponent {
      public controller: any;
      public templateUrl =
            "vm-ui/resources/vm/guest-os-customization/list/gos-customization-list.component.html";

      constructor() {
         this.controller = GuestOsCustomizationListController;
      }
   }

   angular.module("com.vmware.vsphere.client.vm")
         .component("gosCustomizationList", new GuestOsCustomizationListComponent());
}
