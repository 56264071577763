namespace network_ui {

   export class BindToPnicPageValidator {

      static $inject = [
         "i18nService"
      ];

      constructor(private i18nService: any) {}

      public getValidationErrors = (bindToPnic: boolean, selectedPnic: string): string[] => {
         let errors: string[] = [];

         if (bindToPnic && !selectedPnic) {
            errors.push(this.i18nService.getString(
                  "NetworkUi", "VnicToPnicBindingPage.error.noNic"));
         }

         return errors;
      }
   }

   angular.module("com.vmware.vsphere.client.network")
         .service("bindToPnicPageValidator", BindToPnicPageValidator);

}