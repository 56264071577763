/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace h5_vm {

import VirtualDiskRecord = h5_vm.VirtualDiskRecord;
import ManagedObjectReference = com.vmware.vim.binding.vmodl.ManagedObjectReference;
import ValidationResult = com.vmware.vise.core.model.ValidationResult;
import VirtualDisk$LocalPMemBackingInfo = com.vmware.vim.binding.vim.vm.device.VirtualDisk$LocalPMemBackingInfo;
import LocationSpec = com.vmware.vsphere.client.vm.migration.LocationSpec;
import RelocateSpec = com.vmware.vim.binding.vim.vm.RelocateSpec;
import Recommendation = com.vmware.vim.binding.vim.cluster.Recommendation;
import LocationSpecPair = com.vmware.vsphere.client.vm.migration.LocationSpecPair;
import IQService = angular.IQService;
import ComputeResourceTreePageSpec = com.vmware.vsphere.client.h5.vm.model.migration.ComputeResourceTreePageSpec;
import RelocateSpec$DiskLocator = com.vmware.vim.binding.vim.vm.RelocateSpec$DiskLocator;
import ValidateComputeResourceStateBuilder = h5_vm.ValidateComputeResourceStateBuilder;
import VirtualDeviceSpec = com.vmware.vim.binding.vim.vm.device.VirtualDeviceSpec;
import VirtualDisk = com.vmware.vim.binding.vim.vm.device.VirtualDisk;

export interface MigrationService$ValidationResultsData {
   /**
    * The {@code ManagedObjectReference} of the object passed as parameter
    * to the {@code MigrationService.validateComputeResource} method.
    *
    * This means that it can be Cluster, Host, ResourcePool, VApp.
    */
   resourceRef: ManagedObjectReference;

   /**
    * The ResourcePool of the {@code resourceRef}. If the {@code resourceRef} happens
    * to be a ResourcePool itself - the will hold the same value in here.
    */
   targetResourcePoolRef: ManagedObjectReference;

   /**
    * A summary whether the validation is an overall success of failure. Note that
    * the overall result may be a success, even if the {@code validationResults}
    * property holds some errors. E.g.: validating a cluster may have errors for
    * some hosts in the cluster and no problems for others - so overall the validation
    * is considered successful.
    */
   isValid: boolean;

   /**
    * An array with the individual validation results.
    */
   validationResults: Array<ValidationResult>;
}

export interface VmNetworkAdapterNetworkMatch {
   sourceAdapter: any;
   sourceNetwork: ManagedObjectReference;
   sourceVm: ManagedObjectReference;
   destinationNetwork: ManagedObjectReference;
   destinationNetworkName: string;
   destinationPortgroupKey: string;
   destinationSwitchUuid: string;
   destinationOpaqueId: string;
   destinationOpaqueType: string;
}

export interface ValidateComputeResourceParams {
   vmIds: string[];
   computeResourceId: string;
   isHotMigration: boolean;
   selectedMode: string;
   isNetworkSelectionRequired: boolean;
   clusterName?: string;
   hostName?: string;
   resPoolName?: string;
}

export class MigrationService {
   private _log: any;

   static $inject: string[] = [
      "mutationService",
      "dataService",
      "defaultUriSchemeUtil",
      "$q",
      "managedEntityConstants",
      "logService",
      "storageProfileService",
      "diskFormatService",
      "diskProvisioningService",
      "i18nService",
      "migrationTypeConstants",
      "migrationHelperService",
      "authorizationService",
      "vmMigrateValidateComputeResourceStateBuilderFactory",
      "pmemUtilService",
      'vscSchedulingHelper'

   ];

   constructor (private mutationService: any,
         private dataService: any,
         private defaultUriSchemeUtil: any,
         private $q: angular.IQService,
         private managedEntityConstants: any,
         private logService: any,
         private storageProfileService: any,
         private diskFormatService: any,
         private diskProvisioningService: any,
         private i18nService: any,
         private migrationTypeConstants: any,
         private migrationHelperService: any,
         private authorizationService: any,
         private vmMigrateValidateComputeResourceStateBuilderFactory: any,
         private pmemUtilService: PmemUtilService,
         private vscSchedulingHelper: any) {
      this._log = logService("migrationService");
   }

   /**
    * Request root RP for cluster or standalone host. If the target is a resource pool,
    * we already have the target resource pool for the migration.
    *
    * @param resourceId the target resource selected by the user.
    */
   private fetchResourcePool(resourceId: string): angular.IPromise<ManagedObjectReference | null> {
      let resourceRef = this.defaultUriSchemeUtil.getPartsFromVsphereObjectId(resourceId);
      switch (resourceRef.type) {
         case this.managedEntityConstants.CLUSTER:
         case this.managedEntityConstants.HOST:
            return this.dataService.getProperties(resourceId, ["resourcePool"])
               .then((response: any) => {
                  return response.resourcePool;
               });
         case this.managedEntityConstants.RESOURCE_POOL:
         case this.managedEntityConstants.V_APP:
            return this.$q.resolve(resourceRef);
         default:
            this._log.warn("Unexpected resource type: " + resourceRef.type);
            return this.$q.resolve(null);
      }
   }

   /**
    * Build a location spec for a particular Virtual Machine.
    *
    * This means that if we have multiple VMs, we will have one such spec per VM.
    */
   private buildLocationSpec(vmId: string, dataModel: MigrationWizardDataModel): any {
      let self = this;

      let locationSpec: any = {
         relocateSpec: new RelocateSpec()
      };
      if (dataModel.pool) {
         locationSpec.relocateSpec.pool = self.toRef(dataModel.pool);
      }
      if (dataModel.computeResource) {
         let mor = self.toRef(dataModel.computeResource);
         if (mor.type === self.managedEntityConstants.HOST) {
            locationSpec.relocateSpec.host = mor;
         }
      }
      if (dataModel.destinationFolder) {
         locationSpec.relocateSpec.folder = this.defaultUriSchemeUtil
               .getManagedObjectReference(dataModel.destinationFolder);
      }
      if (dataModel.networkMatches) {
         self.populateNetworkChanges(locationSpec.relocateSpec,
               _.filter(dataModel.networkMatches, (nwMatch: any) => {
                  return vmId === self.toId(nwMatch.sourceVm);
               }));
      }

      if (dataModel.priority) {
         locationSpec.priority = dataModel.priority;
      }

      let vmDisks: Array<VirtualDiskRecord> = dataModel.stretchedStorage ?
            dataModel.stretchedStorage[vmId] : dataModel.virtualDisksByVm[vmId];
      locationSpec.relocateSpec.disk = locationSpec.relocateSpec.disk || [];
      locationSpec.relocateSpec.deviceChange = locationSpec.relocateSpec.deviceChange || [];
      _.each(vmDisks, (disk: VirtualDiskRecord) => {

         // VMX file processing...
         if (disk.key === "_configFile") {
            if (disk.storage) {
               locationSpec.relocateSpec.datastore = self.toRef(disk.storage);
            }
            if (disk.profile) {
               locationSpec.relocateSpec.profile =
                     [self.storageProfileService.makeProfile(disk.profile.id, disk.replicationGroup)];
            }
            return;
         }

         // Disk processing...
         if (this.storageProfileService.isPmemStorageProfile(disk.profile)) {
            if (this.pmemUtilService.isPmemDisk(disk.origin)) {
               // Pmem -> Pmem does not require device change or disk locator spec.
               return;
            }

            // Non Pmem -> Pmem upgrade requires device change record.
            let deviceChangeSpec = this.buildDeviceChange(disk);
            locationSpec.relocateSpec.deviceChange.push(deviceChangeSpec);
            return;
         }

         let diskLocator = this.buildDiskLocator(dataModel, disk);
         locationSpec.relocateSpec.disk.push(diskLocator);
      });

      return self.trimEmptyProperties(locationSpec);
   }

   private buildDeviceChange(disk: VirtualDiskRecord): VirtualDeviceSpec {
      let deviceSpec = new VirtualDeviceSpec();

      let pmemBacking: VirtualDisk$LocalPMemBackingInfo =
            new VirtualDisk$LocalPMemBackingInfo();
      pmemBacking.diskMode = "persistent";
      pmemBacking.fileName = "";

      deviceSpec.operation = "edit";
      deviceSpec.device = new VirtualDisk();
      deviceSpec.device.key = disk.key as number;
      deviceSpec.device.backing = pmemBacking;
      deviceSpec.profile = [this.storageProfileService.makeProfile(disk.profile.id, disk.replicationGroup)];
      return deviceSpec;
   }

   private buildDiskLocator(
         dataModel: MigrationWizardDataModel,
         disk: VirtualDiskRecord): RelocateSpec$DiskLocator {
      let diskLocator = new RelocateSpec$DiskLocator();
      diskLocator.diskId = disk.key as number;
      diskLocator.datastore = this.toRef(disk.storage);

      if (disk.profile) {
         diskLocator.profile = [this.storageProfileService.makeProfile(disk.profile.id, disk.replicationGroup)];
      }

      let diskFormatValue: string|null = this.buildDiskFormatValue(
         disk.diskFormat, dataModel.diskFormat);
      if (diskFormatValue) {
         diskLocator.diskBackingInfo = this.diskProvisioningService.getBackingInfo(
            diskFormatValue);
      }

      return diskLocator;
   }

   private buildDiskFormatValue(
         perDiskFmt?: VirtualDiskFormat, dataModelFmt?: any): string|null {
      if (perDiskFmt) {
         if (perDiskFmt.value !== "sameAsSource") {
            return perDiskFmt.value;
         } else {
            return null;
         }
      }

      if (dataModelFmt && (dataModelFmt.type !== "sameAsSource")) {
         return dataModelFmt.type;
      }

      return null;
   }

   private trimEmptyProperties(locationSpec: LocationSpec) {
      if (locationSpec.relocateSpec.disk.length === 0) {
         delete locationSpec.relocateSpec.disk;
      }

      if (locationSpec.relocateSpec.deviceChange.length === 0) {
         delete locationSpec.relocateSpec.deviceChange;
      }

      return locationSpec;
   }

   private populateNetworkChanges(relocateSpec: RelocateSpec, networkMatches: any[]) {
      let self = this;
      let result: Array<any> = [];
      _.forEach(networkMatches, (match: any) => {
         let sourceNetworkUrn = self.defaultUriSchemeUtil.getVsphereObjectId(match.sourceNetwork);
         let destinationNetworkUrn = self.defaultUriSchemeUtil.getVsphereObjectId(match.destinationNetwork);

         if (!match.destinationNetwork || sourceNetworkUrn === destinationNetworkUrn) {
            return;
         }

         let networkSpec = {
            _type: "com.vmware.vim.binding.vim.vm.device.VirtualDeviceSpec",
            operation: "edit",
            device: match.sourceAdapter
         };

         // If the destination network is standard network
         if (match.destinationNetwork.type === self.managedEntityConstants.NETWORK) {
            networkSpec.device.backing = {
               _type: "com.vmware.vim.binding.vim.vm.device.VirtualEthernetCard$NetworkBackingInfo",
               deviceName: match.destinationNetworkName
            };
         }
         // If the destination network is distributed virtual portgroup
         else if (match.destinationNetwork.type === self.managedEntityConstants.DISTRIBUTED_VIRTUAL_PORTGROUP) {
            networkSpec.device.backing = {
               _type: "com.vmware.vim.binding.vim.vm.device.VirtualEthernetCard$DistributedVirtualPortBackingInfo",
               port: {
                  _type: "com.vmware.vim.binding.vim.dvs.PortConnection",
                  portgroupKey: match.destinationPortgroupKey,
                  switchUuid: match.destinationSwitchUuid
               }
            };
         }
         // If the destination network is an opaque network
         else if (match.destinationNetwork.type === self.managedEntityConstants.OPAQUE_NETWORK) {
            const adapterBacking = match.sourceAdapter.backing;
            if (adapterBacking && (adapterBacking._type ===
                  "com.vmware.vim.binding.vim.vm.device.VirtualEthernetCard$OpaqueNetworkBackingInfo")) {
               if ((adapterBacking.opaqueNetworkId === match.destinationOpaqueId) &&
                     (adapterBacking.opaqueNetworkType === match.destinationOpaqueType)) {
                  // Same underlying NSX network can manifest under different MORs,
                  // as per PR 2302060 we should not add deviceChange spec in that case
                  return;
               }
            }

            networkSpec.device.backing = {
               _type: "com.vmware.vim.binding.vim.vm.device.VirtualEthernetCard$OpaqueNetworkBackingInfo",
               opaqueNetworkId: match.destinationOpaqueId,
               opaqueNetworkType: match.destinationOpaqueType
            };
         }

         result.push(networkSpec);
      });

      if (!relocateSpec.deviceChange) {
         relocateSpec.deviceChange = result;
      } else {
         // Concatenating to the deviceChange array is safe here, as no duplicate
         // records for VM network adapters are possible.
         relocateSpec.deviceChange = relocateSpec.deviceChange.concat(result);
      }
   }

   public getLocationSpecsForTesting(validationModel: MigrationWizardDataModel) {
      let self = this;

      let testsToRun = [
         // Source and resource pool tests are mandatory.
         "sourceTests",
         "resourcePoolTests",
         "datastoreTests",
         // Although it"s possible that relocateSpec.host could be unset (in case of
         // selected cluster), we still have to perform host tests, otherwise we can get
         // errors like these described in bug 871584.
         "hostTests"
      ];

      return self.createLocationSpecs(validationModel, testsToRun);
   }

   public getLocationSpecsForRecommendations(
         dataModel: MigrationWizardDataModel): LocationSpecPair[] {
      let self = this;
      let testsToRun = ["storagePodTests"];
      return self.createLocationSpecs(dataModel, testsToRun);
   }

   private createLocationSpecs(
         dataModel: MigrationWizardDataModel,
         testsToRun: Array<string>): LocationSpecPair[] {
      let self = this;

      let locationSpecPairs: LocationSpecPair[] = [];
      _.each(dataModel.vms, (vmId: string) => {
         let locationSpec = self.buildLocationSpec(vmId, dataModel);

         locationSpec.testsToRun = testsToRun;
         let locationSpecPair: LocationSpecPair = new LocationSpecPair();
         locationSpecPair.entity = self.toRef(vmId);
         locationSpecPair.spec = locationSpec;

         locationSpecPairs.push(locationSpecPair);
      });
      return locationSpecPairs;
   }

   private getNetworkSpecsForTesting(
         vmIds: Array<string>, networkMatches: any[], resPoolId: string,
         computeResourceId: string, workflow: string) {
      let self = this;
      let testsToRun = [
         "sourceTests",
         "resourcePoolTests",
         "hostTests",
         "networkTests"
      ];

      // as in flex - the datastore tests are executed only if both workflow is
      // migrate both - storage and computation resource.
      if (workflow === self.migrationTypeConstants.MIGRATE_RESOURCE_AND_STORAGE) {
         testsToRun.push("datastoreTests");
      }

      return self.createNetworkSpecs(vmIds, networkMatches, resPoolId,
         computeResourceId, testsToRun);
   }

   private createNetworkSpecs(
         vmIds: Array<string>, networkMatches: any[], resPoolId: string,
         computeResourceId: string,
         testsToRun: Array<string>) {
      let self = this;
      let locationSpecPairs: Array<{entity: any, spec: any}> = [];
      let dataModel = new h5_vm.MigrationWizardDataModel(vmIds, resPoolId);
      dataModel.networkMatches = networkMatches;
      dataModel.computeResource = computeResourceId;

      _.forEach(vmIds, (vmId: string) => {
         let locationSpec = self.buildLocationSpec(vmId, dataModel);
         locationSpec.testsToRun = testsToRun;

         let locationSpecPair = {
            entity: self.defaultUriSchemeUtil.getManagedObjectReference(vmId),
            spec: locationSpec
         };

         locationSpecPairs.push(locationSpecPair);
      });
      return locationSpecPairs;
   }

   public validateComputeResource(params: ValidateComputeResourceParams):
         angular.IPromise<VmMigrationWizardComputeResourceState> {
      let resourceRef = this.defaultUriSchemeUtil.getManagedObjectReference(params.computeResourceId);

      let result = this.validateComputeResourceType(resourceRef);
      if (result) {
         return result;
      }

      let validationSpec: ComputeResourceTreePageSpec = new ComputeResourceTreePageSpec();

      validationSpec.isXvcMigration = this.migrationHelperService
            .isXvcOperation(params.vmIds, params.computeResourceId);
      validationSpec.isHotMigration = params.isHotMigration;
      validationSpec.isBatchMigration = _.size(params.vmIds) > 1;
      validationSpec.migrationType = params.selectedMode;
      validationSpec.vms = _.map(params.vmIds, (vmId: string) => this.toRef(vmId));
      validationSpec.computeResource = resourceRef;
      validationSpec.fetchClusterName = _.isEmpty(params.clusterName);


      result = this.mutationService.validateSpec(
         "com.vmware.vsphere.client.h5.vm.model.migration.ComputeResourceTreePageSpec",
         validationSpec)
            .then((validateResourceResult: ValidationResult) => {
               if (validateResourceResult.error) {
                  return {
                     resourceRef: resourceRef,
                   //  targetResourcePoolRef: validateResourceResult.result.resourcePoolRef,
                     isValid: false,
                     validationResults: [validateResourceResult]
                  };
               }
               let testsToRun: string[] = ["sourceTests", "resourcePoolTests", "hostTests"];
               if (!params.isNetworkSelectionRequired) {
                  // NOTE1: The user should receive a warning if VM networks are inaccessible
                  // on the destination compute resource - Problem described in bug 1630300.
                  // NOTE2: The network tests should be added only if there is no network
                  // selection page in the wizard, else they cause problems - The VC reports
                  // errors instead of warnings and the user is stuck on the compute
                  // resource page even though there is a change network page after it.
                  testsToRun.push("networkTests");
               }
               if (params.selectedMode === this.migrationTypeConstants.MIGRATE_RESOURCE_ONLY) {
                  // Include datastore tests to ensure that changing the compute
                  // resource only will not affect current storage requirements
                  testsToRun.push("datastoreTests");
               }

               let dataModel = new h5_vm.MigrationWizardDataModel(params.vmIds, this.toId(validateResourceResult.result.resourcePoolRef));
               dataModel.computeResource = params.computeResourceId;
               let locationSpecPairs = this.createLocationSpecs(dataModel, testsToRun);
               return this.mutationService.validateMultiSpec(
                  "com.vmware.vsphere.client.vm.migration.LocationSpecPair",
                  locationSpecPairs
               ).then((validationResult: Array<ValidationResult>) => {
                  let validationSuccess: boolean = this.isValidationSuccessful(validationResult);
                  return (this.vmMigrateValidateComputeResourceStateBuilderFactory
                        .create() as ValidateComputeResourceStateBuilder)
                        .setComputeResource(params.computeResourceId)
                        .setData(validateResourceResult)
                        .setParams(params)
                        .setIsValid(validationSuccess)
                        .setValidationResult(validationResult)
                        .build();
               });
            });

      return (result as angular.IPromise<VmMigrationWizardComputeResourceState>);
   }

   public validateComputeResourceType(
         resourceRef: ManagedObjectReference):
               angular.IPromise<MigrationService$ValidationResultsData>|undefined {
      switch (resourceRef.type) {
         case this.managedEntityConstants.CLUSTER:
         case this.managedEntityConstants.HOST:
         case this.managedEntityConstants.RESOURCE_POOL:
         case this.managedEntityConstants.V_APP:
            // These are allowed types. Do nothing. Let the check continue;
            return undefined;
         default:
            return this.$q.resolve({
               resourceRef: resourceRef,
               isValid: false,
               validationResults: [{
                  entity: resourceRef,
                  error: new Error(this.i18nService.getString("VmUi",
                        "MigrationWizard.resourceTreePage.error.no_selection"))
               }]
            });
      }
   }

   /**
    * @returns {boolean} true if all validation results are valid, false otherwize.
    * A validation result is considered valid if it contains at least on result that has
    * no error.
    */
   private isValidationSuccessful(validationResults: Array<any>): boolean {
      for (let index: number = 0; index < validationResults.length; index++) {
         let hasInitErrors = validationResults[index].error;
         let hasResultWithoutErrors = _.any(validationResults[index].result, (result: any) => {
            return !result.error;
         });
         if (hasInitErrors || !hasResultWithoutErrors) {
            return false;
         }
      }
      return true;
   }

   public validateStorage(validationModel: MigrationWizardDataModel) {
      let locationSpecPairsArray = this.getLocationSpecsForTesting(validationModel);

      let url = new LocationSpecPair()._type;
      return this.mutationService.validateMultiSpec(url, locationSpecPairsArray);
   }

   public validateNetwork(
         vmIds: Array<string>, networkMatches: any[], resPoolId: string,
         computeResourceId: string, workflow: string) {
      let networkSpecPairsArray = this.getNetworkSpecsForTesting(
            vmIds, networkMatches, resPoolId, computeResourceId, workflow);
      let url = new LocationSpecPair()._type;
      return this.mutationService.validateMultiSpec(url, networkSpecPairsArray);
   }

   public validateNetworkPrivilege(
         networkMatches: VmNetworkAdapterNetworkMatch[]):
               angular.IPromise<ValidationResult[]> {
      if (_.isEmpty(networkMatches)) {
         return this.$q.when([]);
      }

      let networkMatchesByIdMap: {[networkId: string]: VmNetworkAdapterNetworkMatch} =
         _.reduce(networkMatches,
            (memo: any, networkMatch: VmNetworkAdapterNetworkMatch) => {
               if (!networkMatch || ! networkMatch.destinationNetwork) {
                  return memo;
               }

               // Its not unusual depending on the destination compute resource,
               // the source and destination network to be the same
               // sourceNetwork can be null if the VM has disconnected network adapter
               if (networkMatch.sourceNetwork && this.defaultUriSchemeUtil.compareIds(
                     networkMatch.sourceNetwork, networkMatch.destinationNetwork)) {
                  return memo;
               }

               // We only pick for validation the changed networks
               let networkId: string = this.defaultUriSchemeUtil.getVsphereObjectId(
                     networkMatch.destinationNetwork);
               memo[networkId] = networkMatch;
               return memo;
            }, {});

      if (_.isEmpty(networkMatchesByIdMap)) {
         return this.$q.when([]);
      }

      let networkIds = _.keys(networkMatchesByIdMap);
      return this.authorizationService.checkPrivilegesForMultipleObjects(
            networkIds, ["Network.Assign"])
         .then((results: {[objectId: string]: {[privilegeId: string]: boolean}}) => {
            return _.map(networkIds, (networkId: string) => {
               let checkResultPerObj = results[networkId];
               if (checkResultPerObj && !checkResultPerObj["Network.Assign"]) {
                  return {
                     entity: networkId,
                     error: {
                        message: this.i18nService.getString(
                           "NetworkUi",
                           "AddNetworkingWizard.conTargetPage.noDvpgPrivilegeMessage",
                           networkMatchesByIdMap[networkId].destinationNetworkName)
                     }
                  };
               } else {
                  return {
                     entity: networkId
                  };
               }
            });
         });
   }

   public migrate(
         vmIds: Array<string>,
         operationName: string,
         dataModel: MigrationWizardDataModel,
         xdrsRecommendation?: any,
         stPageModel?: VmWizardScheduleTaskPageModel) {

      let self = this;
      // TODO alalev: this should eventually call mutationService.applyMultiSpec()
      // and pass an array of specs
      _.each(vmIds, (vmId: string) => {
         let locationSpec: LocationSpec = self.buildLocationSpec(vmId, dataModel);
         if (xdrsRecommendation) {
            this.applyXdrsRecommendation(xdrsRecommendation, locationSpec);
         }
         if (stPageModel && stPageModel.isScheduledTaskFlow) {
            stPageModel = this.createDataModelForScheduleTask(locationSpec, stPageModel);
            self.mutationService.apply(vmId,
                  "com.vmware.vise.data.scheduling.ScheduledMutationOperationSpec",
                  stPageModel.mutationSpec);
         } else {
            self.mutationService.apply(
                  vmId,
                  new LocationSpec()._type,
                  locationSpec,
                  operationName
            );
         }
      });
   }

   public applyStorageRecommendations(recommendations: Recommendation[],
                                      storageRef: ManagedObjectReference) {
      return this.mutationService.add(
            "com.vmware.vsphere.client.vm.storageDrs.VmRecommendationsSpec",
            {
               recommendations: _.pluck(recommendations, "key"),
               moRef: storageRef
            }
      );
   }

   private applyXdrsRecommendation(recommendation: Recommendation, locationSpec: LocationSpec) {
      // xDRS placement recommendations should contain only a single PlacementAction,
      // so if it doesn't, just use the first one found (see bug 1030464).
      let action: any = recommendation.action[0];

      // xDRS API returns a relocateSpec with the recommended action. However, due to API
      // deficiencies not all fields of the relocate spec ar set. We need to apply only
      // the information that was set. See PR 1901738.
      // Update host and datastore with recommended ones
      locationSpec.relocateSpec.host = action.relocateSpec.host;
      locationSpec.relocateSpec.datastore = action.relocateSpec.datastore;

      if (action.relocateSpec.folder) {
         locationSpec.relocateSpec.folder = action.relocateSpec.folder;
      }

      // If a there is a recommended profile update it as well.
      if (action.relocateSpec.profile) {
         locationSpec.relocateSpec.profile = action.relocateSpec.profile;
      }

      // If device changes are set in recommended relocate spec then use it.
      if (!_.isEmpty(action.relocateSpec.deviceChange)) {
         locationSpec.relocateSpec.deviceChange = action.relocateSpec.deviceChange;
      }

      // For disk locators API only sets certain fields per disk. Only values that are
      // set in the recommended action relocateSpec will be applied to the final
      // relocate spec
      if (!_.isEmpty(action.relocateSpec.disk)) {
         _.each(action.relocateSpec.disk,
               (recommendedLocator: RelocateSpec$DiskLocator) => {
            let originalLocator: RelocateSpec$DiskLocator = _.find(
                  locationSpec.relocateSpec.disk,
                  (d: RelocateSpec$DiskLocator) => d.diskId === recommendedLocator.diskId);
            this.updateDiskLocator(originalLocator, recommendedLocator);
         });
      }
   }

   private updateDiskLocator(
         originalDisk: RelocateSpec$DiskLocator,
         recommendedDisk: RelocateSpec$DiskLocator) {
      if (!originalDisk) {
         return;
      }
      if (recommendedDisk.datastore) {
         originalDisk.datastore = recommendedDisk.datastore;
      }

      if (recommendedDisk.profile) {
         originalDisk.profile = recommendedDisk.profile;
      }

      if (recommendedDisk.diskMoveType) {
         originalDisk.diskMoveType = recommendedDisk.diskMoveType;
      }
   }

   private toRef(id: string|undefined): any {
      id = id || "";
      return vm_model.MoRef.fromUri(id);
   }

   private toId(ref: any): string {
      return this.defaultUriSchemeUtil.getVsphereObjectId(ref);
   }

   private createDataModelForScheduleTask(
         locationSpec: LocationSpec,
         stPageModel: VmWizardScheduleTaskPageModel): VmWizardScheduleTaskPageModel {

      stPageModel.mutationSpec.schedulingSpec.recurrence =
            this.vscSchedulingHelper.formatRecurrenceData(stPageModel.mutationSpec.schedulingSpec.recurrence);
      locationSpec._type = "com.vmware.vsphere.client.vm.migration.LocationSpec";
      stPageModel.mutationSpec.spec = locationSpec;
      return stPageModel;
   }

} // class MigrationService

angular.module("com.vmware.vsphere.client.vm").service(
   "migrationService", MigrationService);

} // namespace h5_vm
