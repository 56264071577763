/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
(function () {
   'use strict';
   angular.module('com.vmware.vsphere.client.storage')
      .controller('NfsVersionPageController', [
         '$scope', 'i18nService', 'datastoreConstants', 'wizardPageService',
         function ($scope, i18nService, datastoreConstants, wizardPageService) {
            var self = this;

            self.nfsV3 = datastoreConstants.nfsVersions.NFS_3;
            self.nfsV41 = datastoreConstants.nfsVersions.NFS_4_1;
            self.selectNfsVersion = $scope.nfsWizardManager.getNfsVersion();

            $scope.$watch('ctrl.selectNfsVersion', function(newType, oldType) {
               if (newType !== oldType) {
                  $scope.nfsWizardManager.setNfsVersion(newType);

                  var flows = $scope.wizardConfig.flows;
                  var currentFlow = _.find(flows, function (flow) {
                     return flow.id === $scope.commonWizardManager.getDatastoreType();
                  });

                  var pages = currentFlow ? currentFlow.pages : [];

                  if (newType === self.nfsV41) {
                     wizardPageService.markPageDisabled(
                        pages,
                        i18nService.getString("StorageUi", "addDatastoreWizard.nfsKerberosPage.title"));
                  } else {
                     wizardPageService.markPageSkipped(
                        pages,
                        i18nService.getString("StorageUi", "addDatastoreWizard.nfsKerberosPage.title"));
                  }

                  wizardPageService.invalidateNextPages($scope.wizardConfig,
                     $scope.commonWizardManager.getDatastoreType(), $scope.wizardConfig.currentPage.title);
               }
            });
         }
      ]);
})();
