namespace storage_ui {

   export class IscsiAuthenticationSettings {
      public authenticationMethod: String;
      public useInitiatorNameForOutgoing: boolean;
      public outgoingName: string;
      public outgoingSecret: string;
      public useInitiatorNameForIncoming: boolean;
      public incomingName: string;
      public incomingSecret: string;

      public inheritSettingsFromParent: boolean;
   }

   export class IscsiAuthenticationSettingsService {

      public static $inject = ["storageAdapterConstants"];

      constructor(private constants: any) {
      }

      public buildIscsiAuthenticationSettings(authenticationProperties: any, iscsiHbaName: string): IscsiAuthenticationSettings {

         let authentication = new IscsiAuthenticationSettings();

         authentication.authenticationMethod = this.getAuthenticationMethod(authenticationProperties);
         authentication.useInitiatorNameForOutgoing = iscsiHbaName === authenticationProperties.chapName;
         authentication.outgoingName = authenticationProperties.chapName;
         authentication.outgoingSecret =
               authenticationProperties.chapAuthenticationType !== this.constants.authentication.PROHIBITED
                     ? authenticationProperties.chapSecret
                     : null;

         authentication.useInitiatorNameForIncoming = iscsiHbaName === authenticationProperties.mutualChapName;
         authentication.incomingName = authenticationProperties.mutualChapName;
         authentication.incomingSecret =
               authenticationProperties.mutualChapAuthenticationType !== this.constants.authentication.PROHIBITED
                     ? authenticationProperties.mutualChapSecret
                     : null;

         authentication.inheritSettingsFromParent = authenticationProperties.chapInherited;

         return authentication;
      }

      private getAuthenticationMethod(authenticationProperties: any): String {

         if (!authenticationProperties.chapAuthEnabled) {
            return this.constants.authentication.PROHIBITED;
         }

         switch (authenticationProperties.chapAuthenticationType) {
            case this.constants.authentication.DISCOURAGED:
            case this.constants.authentication.PREFERRED:
               return authenticationProperties.chapAuthenticationType;

            case this.constants.authentication.REQUIRED:
               if (authenticationProperties.mutualChapAuthenticationType === this.constants.authentication.PROHIBITED) {
                  return this.constants.authentication.REQUIRED;
               }
               return this.constants.authentication.MUTUAL;
         }
         return this.constants.authentication.PROHIBITED;
      }
   }

   angular.module("com.vmware.vsphere.client.storage")
         .service("iscsiAuthenticationSettingsService", IscsiAuthenticationSettingsService);
}
