/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
(function () {
   'use strict';

   angular
         .module('com.vmware.vsphere.client.network')
         .service('standardSwitchPoliciesViewService', standardSwitchPoliciesViewService);

   standardSwitchPoliciesViewService.$inject = [
      'dataService',
      'vxPropertyViewService',
      'networkUtil'
   ];

   function standardSwitchPoliciesViewService(
         dataService, propertyViewService, networkUtil) {

      var property = "virtualswitch:policiesViewData";

      return {
         getData: getData,
         getDataByName: getDataByName,
         addPropertiesToCategory: addPropertiesToCategory
      };

      function getData(hostId, selectedSwitchKey) {
         var switchIdSpec = {
            _type: 'com.vmware.vsphere.client.h5.network.host.virtualswitch.spec.VirtualSwitchIdSpec',
            key: selectedSwitchKey
         };
         return getDataInternal(hostId, switchIdSpec, true);
      }

      function getDataByName(hostId, switchName) {
         var switchIdSpec = {
            _type: 'com.vmware.vsphere.client.h5.network.host.virtualswitch.spec.VirtualSwitchIdSpec',
            name: switchName
         };
         return getDataInternal(hostId, switchIdSpec, false);
      }

      function getDataInternal(hostId, switchIdSpec, buildPropertyViewData) {
         var params = {
            propertyParams: [{
               propertyName: property,
               parameterType: switchIdSpec._type,
               parameter: switchIdSpec
            }]
         };

         var propertiesPromise = dataService.getProperties(
            hostId,
            [property],
            params
         );

         return propertiesPromise.then(function(result) {
            var policyData = result && result[property];
            if (buildPropertyViewData) {
               return build(policyData);
            } else {
               return policyData;
            }
         });
      }

      function build(policiesViewData) {
         var builder = propertyViewService.createPropertyViewBuilder();
         var generalCategory = builder.category("general");

         addPropertiesToCategory(policiesViewData, generalCategory);

         return builder.build();
      }

      function addPropertiesToCategory(policiesViewData, category) {
         if (policiesViewData) {
            networkUtil.fillPoliciesCategory(category, policiesViewData);
         }
      }
   }
}());
