/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace dvs_ui {

   class DvsAddHostReadyToCompletePageController {

      public static $inject = [
         "$scope",
         "i18nService",
         "vxPropertyViewService",
         "dvsSelectPhysicalAdaptersPageService",
         "dvsSelectVirtualAdaptersPageService",
         "dvsSelectVirtualMachinesPageService"
      ];

      constructor(private $scope: DvsAddHostWizardScope,
            private i18nService: any,
            private vxPropertyViewService: any,
            private dvsSelectPhysicalAdaptersPageService: DvsSelectPhysicalAdaptersPageService,
            private dvsSelectVirtualAdaptersPageService: DvsSelectVirtualAdaptersPageService,
            private dvsSelectVirtualMachinesPageService: DvsSelectVirtualMachinesPageService) {

         let operationType: string = $scope.model.selectOperationPageModel.selectedOperationType;
         let builder: any = vxPropertyViewService.createPropertyViewBuilder();
         let generalCategory: any = builder.category("general");
         let generalSection: any = generalCategory.section("generalSection");

         if (operationType === DvsAddHostWizardConstants.operationType.ADD_HOST) {

            generalSection.title(i18nService.getString(
                  "DvsUi", "ManageHostsSummayPage.heading.hostsForUpdate"));

            generalSection.property(
                  i18nService.getString(
                        "DvsUi", "ManageHostsSummayPage.property.hostsToAddCount"),
                  $scope.model.selectHostsPageModel.hosts.length);
         } else if (operationType === DvsAddHostWizardConstants.operationType.MANAGE_HOST) {
            generalSection.title(this.i18nService.getString("DvsUi", "ManageHostsSummayPage.heading.hostsForUpdate"));
            generalSection.property(
                  i18nService.getString(
                        "DvsUi", "ManageHostsSummayPage.property.hostsToUpdateCount"),
                  $scope.model.selectHostsToManagePageModel.hosts.length);
         } else if (operationType === DvsAddHostWizardConstants.operationType.REMOVE_HOST) {
            generalSection.property(
                  i18nService.getString(
                        "DvsUi", "ManageHostsSummayPage.property.hostsToRemoveCount"),
                  $scope.model.selectHostsToRemovePageModel.hosts.length);
         }

         let numberOfUpdatedPhysicalAdapters: number =
               this.dvsSelectPhysicalAdaptersPageService
                     .getNumberOfUpdatedPhysicalAdapters(
                           $scope.model.selectPhysicalAdaptersPageModel.hosts);
         let numberOfUpdatedVirtualAdapters: number =
               this.dvsSelectVirtualAdaptersPageService
                     .getNumberOfUpdatedVirtualAdapters(
                           $scope.model.selectVirtualAdaptersPageModel.hosts);
         let numberOfUpdatedVirtualMachineAdapters: number =
               $scope.model.selectVirtualMachinesPageModel.isMigrateVirtualMachineNetworkingEnabled
                     ? this.dvsSelectVirtualMachinesPageService
                           .getNumberOfUpdatedVirtualMachineAdapters(
                                 $scope.model.selectVirtualMachinesPageModel.hosts)
                     : 0;

         if (operationType !== DvsAddHostWizardConstants.operationType.REMOVE_HOST &&
               (numberOfUpdatedPhysicalAdapters > 0
                     || numberOfUpdatedVirtualAdapters > 0
                     || numberOfUpdatedVirtualMachineAdapters > 0)) {
            // Show number of network adapters summary section only when such are
            // reassigned.
            let numberOfNetworkAdaptersSection: any =
                  generalCategory.section("networkAdapters")
                        .title(i18nService.getString(
                              "DvsUi", "ManageHostsSummayPage.heading.adaptersForUpdate"));

            if (numberOfUpdatedPhysicalAdapters > 0) {
               numberOfNetworkAdaptersSection.property(
                     i18nService.getString(
                           "DvsUi", "ManageHostsSummayPage.property.pNicsCount"),
                     numberOfUpdatedPhysicalAdapters);
            }

            if (numberOfUpdatedVirtualAdapters > 0) {
               numberOfNetworkAdaptersSection.property(
                     i18nService.getString(
                           "DvsUi", "ManageHostsSummayPage.property.vNicsReassignedCount"),
                     numberOfUpdatedVirtualAdapters);
            }

            if (numberOfUpdatedVirtualMachineAdapters > 0) {
               numberOfNetworkAdaptersSection.property(
                     this.i18nService.getString(
                           "DvsUi", "ManageHostsSummayPage.property.vmNicsCount"),
                     numberOfUpdatedVirtualMachineAdapters);
            }
         }

         this.$scope.model.summaryData = builder.build();
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .controller("DvsAddHostReadyToCompletePageController",
               DvsAddHostReadyToCompletePageController);
}
