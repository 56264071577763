module ds_cluster_ui {

   import IRootScopeService = angular.IRootScopeService;

   export interface VuiAddVmOverridesWizard {
      show(pages: Array<any>, wizardScope: any): void;
      markPageIncompleteWithError(message: string): void;
      markPageComplete(flow?: any): void;
   }

   class VuiAddVmOverridesWizardImpl implements VuiAddVmOverridesWizard {

      static $inject = [
         'wizardPageService',
         'vuiWizardService',
         '$rootScope',
         'i18nService'
      ];

      private wizardConfig: any;
      private wizardScope: any;

      constructor(private wizardPageService: any,
                  private vuiWizardService: any,
                  private $rootScope: any,
                  private i18nService: any) {
      }

      public show(pages: Array<any>, wizardScope: any): void {
         this.wizardConfig = _.extend(
               wizardScope.wizardConfig ? wizardScope.wizardConfig : {},
               {
                  title: this.i18nService.getString("DsClusterUi", "vmOverrides.config.add.title", wizardScope.manager.getClusterName()),
                  pages: this.wizardPageService.initializePageStates(pages),
                  resizeMinHeight: 500,
                  onFinish: (): boolean => {
                     return true;
                  }
               });

         this.wizardScope = wizardScope;
         this.wizardScope.wizardConfig = this.wizardConfig;

         this.vuiWizardService({
            scope: this.wizardScope,
            configObjectName: "wizardConfig"
         }).show();
      }

      public markPageIncompleteWithError(message: string): void {
         this.wizardPageService.markPageIncompleteWithError(this.wizardConfig, message);
      }

      public markPageComplete(flow?: any): void {
         this.wizardPageService.clearErrors(this.wizardConfig);
         this.wizardPageService.markPageComplete(this.wizardConfig, flow);
      }

   }

   angular.module("com.vmware.vsphere.client.dsCluster")
         .service("vuiAddVmOverridesWizard", VuiAddVmOverridesWizardImpl);
}
