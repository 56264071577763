module cluster_ui {

   export class ClusterResourcePoolMemoryUtilizationPortletController
   implements angular.IComponentController {

      public meters: any[] = [];
      public pointers: any[] = [];
      public maxCapacityValue: string = "";
      public resourcesData: any = {};

      public static $inject = ["$scope", "dataService", "clusterUtilizationService",
            "$controller"];

      constructor(protected $scope: angular.IScope, private dataService: any,
            protected clusterUtilizationService: ClusterUtilizationService,
            public $controller: angular.IControllerService) {
         angular.extend(this, $controller("GenericPortletController", {$scope: $scope}));

         $scope.$watch("data", (data: any) => {
            if (data) {
               this.meters = this.clusterUtilizationService.getMemoryConsumedMetersData(data);
               this.maxCapacityValue = data.maxValue;
               this.resourcesData =
                     this.clusterUtilizationService.buildResourcePoolMemoryPropertyViewData(data);
               this.pointers = this.clusterUtilizationService.getUtilizationPointersData(data);
            }
         });
      }
   }

   angular.module("com.vmware.vsphere.client.cluster")
         .controller("ClusterResourcePoolMemoryUtilizationPortletController",
               ClusterResourcePoolMemoryUtilizationPortletController);
}
