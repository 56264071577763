/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace dvs_ui {

   import DvpgTrafficRuleModel = com.vmware.vsphere.client.h5.network.dvportgroup.trafficfilter.model.DvpgTrafficRuleModel;

   export class DvpgAddTrafficRuleComponent {

      public bindings: any;
      public controller: any;
      public templateUrl: string;

      constructor() {
         this.bindings = {
            model: "<",
            onIpValidate: "=",
            onMacValidate: "="
         };

         this.controller = DvpgAddTrafficRuleContoller;

         this.templateUrl = "dvs-ui/resources/dvs/dvpg/trafficfilter/add/" +
               "dvpgAddTrafficRuleTemplate.html";
      }
   }

   export class DvpgAddTrafficRuleContoller {

      static $inject = [
            "i18nService",
            "dvpgTrafficFilterService",
            "dvpgTrafficFilterConstants",
            "dvpgTrafficRuleValidator",
            "vxValidatorFactory"
      ];

      public model: DvpgTrafficRuleModel;
      public validator: any;

      public onIpValidate: any;
      public onMacValidate: any;

      private i18n: any;

      private qosTagPlaceholderText: string;
      private dscpTagPlaceholderText: string;
      private directions: string[];
      private actions: string[];

      private validateRuleName: Function;
      private validateTags: Function;
      private signPostConfig: any;

      constructor(private i18nService: any,
                  private trafficFilterService: any,
                  private trafficFilterConstants: any,
                  private dvpgTrafficRuleValidator: any,
                  private vxValidatorFactory: any) {
         this.i18n = this.i18nService.getString;
         this.directions = this.trafficFilterService.getTrafficFilterDirections();
         this.actions = this.trafficFilterService.getTrafficFilterActions();
         this.qosTagPlaceholderText = this.trafficFilterConstants.tag.MIN_MAX_COS_TAG;
         this.dscpTagPlaceholderText = this.trafficFilterConstants.tag.MIN_MAX_DSCP_TAG;
         this.validateRuleName = this.ruleNameValidator.bind(this);
         this.validateTags = this.tagsValidator.bind(this);

         this.validator = this.vxValidatorFactory.create();
      }

      public $onInit() {
         this.signPostConfig = {
            message: this.i18n("DvsUi",
                  "trafficFilter.ruleDialog.trafficDirectionInfo"),
            title: this.i18n("DvsUi",
                  "trafficFilter.ruleDialog.trafficDirectionInfo.title"),
            class: "traffic-rule-direction-signpost"
         };
      }

      private ruleNameValidator(): string[] {
         if (!this.dvpgTrafficRuleValidator.isValidRuleName(this.model.name)) {
            return [this.i18n("DvsUi", "trafficFilter.ruleDialog.exceededMaxLength")];
         }
         return [];
      }

      private tagsValidator(): string[] {
         let errors: string[] = [];

         if (this.model.qosTagSelected &&
               !this.dvpgTrafficRuleValidator.isValidQosTag(this.model.qosTagValue)) {
            errors.push(this.i18n("DvsUi", "trafficFilter.ruleDialog.invalidQosTag"));
         }

         if (this.model.dscpTagSelected &&
               !this.dvpgTrafficRuleValidator.isValidDscpTag(this.model.dscpTagValue)) {
            errors.push(this.i18n("DvsUi", "trafficFilter.ruleDialog.invalidDscpTag"));
         }

         return errors;
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .component("dvpgAddTrafficRule", new DvpgAddTrafficRuleComponent());
}

