namespace ds_cluster_ui {

   export class RuntimeSettingsComponent {
      public bindings: any;
      public controller: any;
      public templateUrl: string;

      constructor() {
         this.bindings = {
            model: "="
         };

         this.controller = RuntimeSettingsComponentController;
         this.templateUrl = "ds-cluster-ui/resources/ds-cluster/components/runtimeSettings.html";
      }
   }

   class RuntimeSettingsComponentController {

      public static $inject = ["vuiConstants", "i18nService", "dsClusterConstants"];


      public automationOverrides: any;
      public model: DsClusterModel;
      public maxLatencyThreshold: number;
      public minLatencyThreshold: number;
      public minUtilizationThreshold: number;
      public maxUtilizationThreshold: number;
      public signPostParams: Object;
      public i18n: any;
      public ioMetricsInclusionLegend: string;
      public ioMetricsInclusionCheck: string;
      public ioLatencyThresholdLegend: string;
      public ioLatencyThresholdDesc: string;
      public spaceThresholdLegend: string;
      public spaceThresholdDesc: string;
      public utilizedSpaceLabel: string;
      public utilizedSpaceUnit: string;
      public utilizedSpaceDesc: string;
      public minFreeSpaceLabel: string;
      public minFreeSpaceMetricLabel: string;
      public minFreeSpaceDesc: string;

      constructor(private vuiConstants: any,
                  private i18nService: any,
                  private dsClusterConstants: any) {
         this.i18n = this.i18nService.getString;
         this.ioMetricsInclusionLegend = this.i18n('DsClusterUi',
               'createDsClusterWizard.runtimeSettingsPage.ioMetricsInclusion.legend');
         this.ioMetricsInclusionCheck = this.i18n('DsClusterUi',
               'createDsClusterWizard.runtimeSettingsPage.ioMetricsInclusion.check');
         this.ioLatencyThresholdLegend = this.i18n('DsClusterUi',
               'createDsClusterWizard.runtimeSettingsPage.ioLatencyThreshold.legend');
         this.ioLatencyThresholdDesc = this.i18n('DsClusterUi',
               'createDsClusterWizard.runtimeSettingsPage.ioLatencyThreshold.desc');
         this.spaceThresholdLegend = this.i18n('DsClusterUi',
               'createDsClusterWizard.runtimeSettingsPage.spaceThreshold.legend');
         this.spaceThresholdDesc = this.i18n('DsClusterUi',
               'createDsClusterWizard.runtimeSettingsPage.sdrsThreshold.desc');
         this.utilizedSpaceLabel = this.i18n('DsClusterUi',
               'createDsClusterWizard.runtimeSettingsPage.spaceThreshold.utilizedSpace.label');
         this.utilizedSpaceUnit = this.i18n('DsClusterUi',
               'createDsClusterWizard.runtimeSettingsPage.spaceThreshold.utilizedSpace.unit');
         this.utilizedSpaceDesc = this.i18n('DsClusterUi',
               'createDsClusterWizard.runtimeSettingsPage.spaceThreshold.utilizedSpace.desc');
         this.minFreeSpaceLabel = this.i18n('DsClusterUi',
               'createDsClusterWizard.runtimeSettingsPage.spaceThreshold.minFreeSpace.label');
         this.minFreeSpaceMetricLabel = this.i18n("DsClusterUi",
               "sdrs.config.minFreeSpaceThreshold.metricLabel");
         this.minFreeSpaceDesc = this.i18n("DsClusterUi",
               "createDsClusterWizard.runtimeSettingsPage.spaceThreshold.minFreeSpace.desc");
      }

      $onInit(): void {
         this.buildSignPostParams();
         this.initMinMaxThresholdValues();
      }

      private initMinMaxThresholdValues() {
         this.minUtilizationThreshold = this.dsClusterConstants.spaceUtilizationThreshold.MIN;
         this.maxUtilizationThreshold = this.dsClusterConstants.spaceUtilizationThreshold.MAX;
         this.minLatencyThreshold = this.dsClusterConstants.ioLatencyThreshold.MIN;
         this.maxLatencyThreshold = this.dsClusterConstants.ioLatencyThreshold.MAX;
      }

      public normalizeFreeSpaceThreshold(): void {
         if (this.model !== undefined && this.model.spaceLoadBalanceConfig !== undefined) {
            this.model.spaceLoadBalanceConfig.freeSpaceThresholdGB = this.normalize(
                  this.model.spaceLoadBalanceConfig.freeSpaceThresholdGB,
                  this.dsClusterConstants.freeSpaceThresholdGB.MIN,
                  this.dsClusterConstants.MAX_INTEGER);
         }
      }

      public normalizeIOLatencyThreshold(): void {
         if (this.model !== undefined && this.model.ioLatencyThreshold !== undefined
               &&  this.minLatencyThreshold !== undefined && this.maxLatencyThreshold !== undefined) {
            this.model.ioLatencyThreshold = this.normalize(this.model.ioLatencyThreshold,
                  this.minLatencyThreshold, this.maxLatencyThreshold);
         }
      }

      public normalizeUtilizationThreshold(): void {
         if (this.model !== undefined && this.model.spaceLoadBalanceConfig !== undefined
               && this.model.spaceLoadBalanceConfig.spaceUtilizationThreshold !== undefined
               &&  this.minUtilizationThreshold !== undefined
               && this.maxUtilizationThreshold !== undefined) {
            this.model.spaceLoadBalanceConfig.spaceUtilizationThreshold = this.normalize(
                  this.model.spaceLoadBalanceConfig.spaceUtilizationThreshold,
                  this.minUtilizationThreshold, this.maxUtilizationThreshold);
         }
      }

      private normalize(val: number, min: number, max: number): number {
         return Math.max(Math.min(val, max), min);
      }

      private buildSignPostParams(): void {
         this.signPostParams = {
            message: this.i18nService.getString("DsClusterUi", "sdrs.config.ioMetrics.info"),
            title: this.i18nService.getString("Common", "help")
         };
      }
   }

   angular.module("com.vmware.vsphere.client.dsCluster")
         .component("runtimeSettings",
               new RuntimeSettingsComponent());
}
