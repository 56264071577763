/* Copyright 2019 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace network_lib_ui {

   export class PhysicalAdapterDetailsViewService {

      static readonly $inject: string[] = [
         'i18nService',
         'vxPropertyViewService',
         'networkUtil',
         "numberFormatterService"
      ];

      constructor(private i18nService: any,
            private propertyViewService: any,
            private networkUtil: any,
            private numberFormatterService: any) {
      }

      build(pnicDetailsItem: any): any {
         if (pnicDetailsItem !== null) {
            const builder: any = this.propertyViewService.createPropertyViewBuilder();
            this.buildPropertiesCategoryInternal(builder, pnicDetailsItem);
            this.buildCdpCategoryInternal(builder, pnicDetailsItem);
            this.buildLldpCategoryInternal(builder, pnicDetailsItem);

            // Add 'All' tab
            builder
                  .category('all', 0)
                  .title(this.i18nService.getString(
                        'H5NetworkUi', 'PhysicalAdapterDetailsView.allTabTitle'))
                  .cloneAndAddBuilder(builder.getSection('properties', 'general'))
                  .cloneAndAddBuilder(builder.getSection('properties', 'status'))
                  .cloneAndAddBuilder(builder.getSection('properties', 'networkIoControl'))
                  .cloneAndAddBuilder(builder.getSection('properties', 'sriov'))
                  .cloneAndAddBuilder(builder.getSection('cdp', 'cdpSection'))
                  .cloneAndAddBuilder(builder.getSection('cdp', 'cdpCapabilitySection'))
                  .cloneAndAddBuilder(builder.getSection('lldp', 'lldpSection'))
                  .cloneAndAddBuilder(builder.getSection('lldp', 'lldpCapabilitySection'));

            return builder.build();
         }
         return {};
      }

      buildOnlyProperties(pnicDetailsItem: any): any {
         const builder: any = this.propertyViewService.createPropertyViewBuilder();
         this.buildPropertiesCategoryInternal(builder, pnicDetailsItem);

         builder.getCategory("properties").title(null);

         return builder.build();
      }

      buildOnlyCdp(pnicDetailsItem: any): any {
         const builder: any = this.propertyViewService.createPropertyViewBuilder();
         this.buildCdpCategoryInternal(builder, pnicDetailsItem);

         builder.getCategory("cdp").title(null);

         return builder.build();
      }

      buildOnlyLldp(pnicDetailsItem: any): any {
         const builder: any = this.propertyViewService.createPropertyViewBuilder();
         this.buildLldpCategoryInternal(builder, pnicDetailsItem);

         builder.getCategory("lldp").title(null);

         return builder.build();
      }

      private buildPropertiesCategoryInternal(builder: any, pnicDetailsItem: any): void {
         const categoryBuilder: any = builder
               .category("properties")
               .title(this.networkUtil.getString('PnicView.adapterDetails'));

         this.buildGeneralSection(categoryBuilder, pnicDetailsItem);
         this.buildStatusSection(categoryBuilder, pnicDetailsItem);
         this.buildSriovSection(categoryBuilder, pnicDetailsItem);
      }

      private buildGeneralSection(categoryBuilder: any, pnicDetailsItem: any): any {
         categoryBuilder
               .section("general")
               .property(
                     this.networkUtil.getString('PnicView.adapter'),
                     pnicDetailsItem.adapterName)
               .property(
                     this.networkUtil.getString('PnicView.name'),
                     pnicDetailsItem.deviceName)
               .property(
                     this.networkUtil.getString('PnicView.location'),
                     pnicDetailsItem.location)
               .property(
                     this.networkUtil.getString('PnicView.driver'),
                     pnicDetailsItem.driverName);
      }

      private buildStatusSection(categoryBuilder: any, pnicDetailsItem: any): any {
         categoryBuilder
               .section("status")
               .title(this.networkUtil.getString('PnicView.status'))

               .property(
                     this.networkUtil.getString('PnicView.statusLabel'),
                     pnicDetailsItem.isConnected
                           ? this.networkUtil.getString('PnicView.connected')
                           : this.networkUtil.getString('PnicView.disconnected'))
               .property(
                     this.networkUtil.getString('PnicView.actualSpeed'),
                     this.getPnicActualSpeedValue(pnicDetailsItem))
               .property(
                     this.networkUtil.getString('PnicView.configSpeed'),
                     this.getConfiguredSpeedValue(pnicDetailsItem))
               .property(
                     this.networkUtil.getString('PnicView.networks'),
                     this.getNetworks(pnicDetailsItem));

         if (pnicDetailsItem.resourcePoolSchedulerAllowed !== null) {
            const sectionBuilder: any =
                  categoryBuilder
                        .section("networkIoControl")
                        .title(this.networkUtil.getString('PnicView.netIorm'));
            sectionBuilder
                  .property(
                        this.networkUtil.getString('PnicView.status'),
                        pnicDetailsItem.resourcePoolSchedulerAllowed
                              ? this.networkUtil.getString('PnicView.netIorm.allowed')
                              : this.networkUtil.getString('PnicView.netIorm.notAllowed')
                  );

            _.forEach(pnicDetailsItem.resourcePoolSchedulerDisallowedReasons,
                  (reason: any) => {
                     sectionBuilder.info(reason);
                  });
         }
      }

      private getPnicActualSpeedValue(pnicDetailsItem: any): string {
         let actualSpeedValue: string;
         if (!pnicDetailsItem.actualSpeed) {
            actualSpeedValue = this.networkUtil.getString('PnicView.down');
         } else if (pnicDetailsItem.isActualSpeedFullDuplex) {
            actualSpeedValue = this.networkUtil.getString(
                  'PnicView.fullDuplex', this.numberFormatterService.formatBandwidth(
                        pnicDetailsItem.actualSpeed, BandwidthUnit.MBITPS, BandwidthUnit.AUTO));
         } else {
            actualSpeedValue = this.networkUtil.getString(
                  'PnicView.halfDuplex', this.numberFormatterService.formatBandwidth(
                        pnicDetailsItem.actualSpeed, BandwidthUnit.MBITPS, BandwidthUnit.AUTO));
         }
         return actualSpeedValue;
      }

      private getConfiguredSpeedValue(pnicDetailsItem: any): string {
         let configuredSpeedValue: string;
         if (!pnicDetailsItem.configuredSpeed) {
            configuredSpeedValue = this.networkUtil.getString('PnicView.autonegotiate');
         } else if (pnicDetailsItem.isConfiguredSpeedFullDuplex) {
            configuredSpeedValue = this.networkUtil.getString('PnicView.fullDuplex',
                  this.numberFormatterService.formatBandwidth(
                        pnicDetailsItem.configuredSpeed, BandwidthUnit.MBITPS, BandwidthUnit.AUTO));
         } else {
            configuredSpeedValue = this.networkUtil.getString('PnicView.halfDuplex',
                  this.numberFormatterService.formatBandwidth(
                        pnicDetailsItem.configuredSpeed, BandwidthUnit.MBITPS, BandwidthUnit.AUTO));
         }
         return configuredSpeedValue;
      }

      private getNetworks(pnicDetailsItem: any): string {
         return (pnicDetailsItem.networkHints && pnicDetailsItem.networkHints.length) ?
               pnicDetailsItem.networkHints : this.networkUtil.getString('NetworkUtil.NoNetworks');
      }

      private buildSriovSection(categoryBuilder: any, pnicDetailsItem: any): void {
         if (pnicDetailsItem.sriovInfo && pnicDetailsItem.sriovInfo.sriovCapable) {
            if (pnicDetailsItem.sriovInfo.sriovEnabled) {
               categoryBuilder
                     .section("sriov")
                     .title(this.networkUtil.getString('Sriov.title'))
                     .property(this.networkUtil.getString('Sriov.status'),
                           this.networkUtil.getString('Sriov.status.enabled'))
                     .property(this.networkUtil.getString('Sriov.virtualFunctionsCount'),
                           this.getVirtualFunctions(pnicDetailsItem));
            } else {
               categoryBuilder
                     .section("sriov")
                     .title(this.networkUtil.getString('Sriov.title'))
                     .property(this.networkUtil.getString('Sriov.status'),
                           this.networkUtil.getString('Sriov.status.disabled'));
            }
         } else {
            categoryBuilder
                  .section("sriov")
                  .title(this.networkUtil.getString('Sriov.title'))
                  .property(this.networkUtil.getString('Sriov.status'),
                        this.networkUtil.getString('Sriov.status.notSupported'));
         }
      }

      private getVirtualFunctions(pnicDetailsItem: any): string {
         if (pnicDetailsItem.sriovInfo.numVirtualFunctionsRequested ===
               pnicDetailsItem.sriovInfo.numVirtualFunction) {
            return pnicDetailsItem.sriovInfo.numVirtualFunction;
         }

         return this.networkUtil.getString('Sriov.vf.mixedFormat',
               pnicDetailsItem.sriovInfo.numVirtualFunctionsRequested,
               pnicDetailsItem.sriovInfo.numVirtualFunction);
      }

      private buildCdpCategoryInternal(builder: any, pnicDetailsItem: any): void {
         const categoryBuilder: any = builder
               .category("cdp")
               .title(this.networkUtil.getString('CdpDetailsView.category'));

         const cdpSectionBuilder: any = categoryBuilder
               .section("cdpSection")
               .title(this.networkUtil.getString('CdpDetailsView.title'));

         if (!pnicDetailsItem.areNetworkHintsRetrieved) {
            cdpSectionBuilder.info(this.networkUtil.getString('CdpDetailsView.notAccessible'));
         } else {
            if (pnicDetailsItem.cdpInfo === null) {
               cdpSectionBuilder.info(this.networkUtil.getString('CdpDetailsView.notAvailable'));
            } else {
               cdpSectionBuilder.property(this.networkUtil.getString('CdpDetailsView.version'),
                     pnicDetailsItem.cdpInfo.version);
               cdpSectionBuilder.property(this.networkUtil.getString('CdpDetailsView.timeout'),
                     pnicDetailsItem.cdpInfo.timeout);
               cdpSectionBuilder.property(this.networkUtil.getString('CdpDetailsView.ttl'),
                     pnicDetailsItem.cdpInfo.ttl);
               cdpSectionBuilder.property(this.networkUtil.getString('CdpDetailsView.samples'),
                     pnicDetailsItem.cdpInfo.samples);
               cdpSectionBuilder.property(this.networkUtil.getString('CdpDetailsView.deviceId'),
                     pnicDetailsItem.cdpInfo.devId);
               cdpSectionBuilder.property(this.networkUtil.getString('CdpDetailsView.ipAddress'),
                     pnicDetailsItem.cdpInfo.address);
               cdpSectionBuilder.property(this.networkUtil.getString('CdpDetailsView.portId'),
                     pnicDetailsItem.cdpInfo.portId);
               cdpSectionBuilder.property(this.networkUtil.getString('CdpDetailsView.softwareVersion'),
                     pnicDetailsItem.cdpInfo.softwareVersion);
               cdpSectionBuilder.property(this.networkUtil.getString('CdpDetailsView.hardwarePlatform'),
                     pnicDetailsItem.cdpInfo.hardwarePlatform);
               cdpSectionBuilder.property(this.networkUtil.getString('CdpDetailsView.ipPrefix'),
                     pnicDetailsItem.cdpInfo.ipPrefix);
               cdpSectionBuilder.property(this.networkUtil.getString('CdpDetailsView.ipPrefixLength'),
                     pnicDetailsItem.cdpInfo.ipPrefixLen);
               cdpSectionBuilder.property(this.networkUtil.getString('CdpDetailsView.vlan'),
                     pnicDetailsItem.cdpInfo.vlan);
               cdpSectionBuilder.property(this.networkUtil.getString('CdpDetailsView.fullDuplex'),
                     pnicDetailsItem.cdpInfo.fullDuplex);
               cdpSectionBuilder.property(this.networkUtil.getString('CdpDetailsView.mtu'),
                     pnicDetailsItem.cdpInfo.mtu);
               cdpSectionBuilder.property(this.networkUtil.getString('CdpDetailsView.systemName'),
                     pnicDetailsItem.cdpInfo.systemName);
               cdpSectionBuilder.property(this.networkUtil.getString('CdpDetailsView.systemOid'),
                     pnicDetailsItem.cdpInfo.systemOID);
               cdpSectionBuilder.property(this.networkUtil.getString('CdpDetailsView.managementAddress'),
                     pnicDetailsItem.cdpInfo.mgmtAddr);
               cdpSectionBuilder.property(this.networkUtil.getString('CdpDetailsView.location'),
                     pnicDetailsItem.cdpInfo.location);

               const cdpCapabilitySectionBuilder: any =
                     categoryBuilder
                           .section("cdpCapabilitySection")
                           .title(this.networkUtil.getString('CdpDetailsView.deviceCapability'));

               cdpCapabilitySectionBuilder.property(
                     this.networkUtil.getString('CdpDetailsView.router'),
                     pnicDetailsItem.cdpInfo.deviceCapabilities.router);
               cdpCapabilitySectionBuilder.property(
                     this.networkUtil.getString('CdpDetailsView.transBridge'),
                     pnicDetailsItem.cdpInfo.deviceCapabilities.transparentBridge);
               cdpCapabilitySectionBuilder.property(
                     this.networkUtil.getString('CdpDetailsView.srcRouteBridge'),
                     pnicDetailsItem.cdpInfo.deviceCapabilities.sourceRouteBridge);
               cdpCapabilitySectionBuilder.property(
                     this.networkUtil.getString('CdpDetailsView.networkSwitch'),
                     pnicDetailsItem.cdpInfo.deviceCapabilities.networkSwitch);
               cdpCapabilitySectionBuilder.property(
                     this.networkUtil.getString('CdpDetailsView.host'),
                     pnicDetailsItem.cdpInfo.deviceCapabilities.host);
               cdpCapabilitySectionBuilder.property(
                     this.networkUtil.getString('CdpDetailsView.igmp'),
                     pnicDetailsItem.cdpInfo.deviceCapabilities.igmpEnabled);
               cdpCapabilitySectionBuilder.property(
                     this.networkUtil.getString('CdpDetailsView.repeater'),
                     pnicDetailsItem.cdpInfo.deviceCapabilities.repeater);
            }
         }
      }

      private buildLldpCategoryInternal(builder: any, pnicDetailsItem: any): void {
         const categoryBuilder: any = builder
               .category("lldp")
               .title(this.networkUtil.getString('LldpDetailsView.category'));

         const lldpSectionBuilder: any = categoryBuilder
               .section("lldpSection")
               .title(this.networkUtil.getString('LldpDetailsView.title'));

         if (!pnicDetailsItem.areNetworkHintsRetrieved) {
            lldpSectionBuilder.info(
                  this.networkUtil.getString('LldpDetailsView.notAccessible'));
         } else {
            if (pnicDetailsItem.lldpInfo === null) {
               lldpSectionBuilder.info(
                     this.networkUtil.getString('LldpDetailsView.notAvailable'));
            } else {

               lldpSectionBuilder.property(this.networkUtil.getString('LldpDetailsView.chassisId'),
                     pnicDetailsItem.lldpInfo.chassisId);
               lldpSectionBuilder.property(this.networkUtil.getString('LldpDetailsView.portId'),
                     pnicDetailsItem.lldpInfo.portId);
               lldpSectionBuilder.property(this.networkUtil.getString('LldpDetailsView.ttl'),
                     pnicDetailsItem.lldpInfo.ttl);

               _.forEach(pnicDetailsItem.lldpInfo.parameter, (param: any): void => {
                  lldpSectionBuilder.property(param.key,
                        param.value);
               });

               const lldpCapabilitySectionBuilder: any =
                     categoryBuilder
                           .section("lldpCapabilitySection")
                           .title(this.networkUtil.getString(
                                 'LldpDetailsView.deviceCapability'));

               lldpCapabilitySectionBuilder.property(
                     this.networkUtil.getString('LldpDetailsView.router'),
                     pnicDetailsItem.lldpInfo.deviceCapabilities.router);
               lldpCapabilitySectionBuilder.property(
                     this.networkUtil.getString('LldpDetailsView.transBridge'),
                     pnicDetailsItem.lldpInfo.deviceCapabilities.transparentBridge);
               lldpCapabilitySectionBuilder.property(
                     this.networkUtil.getString('LldpDetailsView.srcRouteBridge'),
                     pnicDetailsItem.lldpInfo.deviceCapabilities.sourceRouteBridge);
               lldpCapabilitySectionBuilder.property(
                     this.networkUtil.getString('LldpDetailsView.networkSwitch'),
                     pnicDetailsItem.lldpInfo.deviceCapabilities.networkSwitch);
               lldpCapabilitySectionBuilder.property(
                     this.networkUtil.getString('LldpDetailsView.host'),
                     pnicDetailsItem.lldpInfo.deviceCapabilities.host);
               lldpCapabilitySectionBuilder.property(
                     this.networkUtil.getString('LldpDetailsView.igmp'),
                     pnicDetailsItem.lldpInfo.deviceCapabilities.igmpEnabled);
               lldpCapabilitySectionBuilder.property(
                     this.networkUtil.getString('LldpDetailsView.repeater'),
                     pnicDetailsItem.lldpInfo.deviceCapabilities.repeater);
            }
         }
      }
   }

   enum BandwidthUnit { BITPS, KBITPS, MBITPS, GBITPS, TBITPS, AUTO }

   angular.module("com.vmware.vsphere.client.networkLibUi")
         .service("physicalAdapterDetailsViewService", PhysicalAdapterDetailsViewService);
}