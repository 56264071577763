(function() {
   'use strict';

   /**
    * Factory that handles Host Security Profile edit related operations.
    * Builds all the edit Host Security Profile dialog page models and submits the
    * edited data to the server.
    */
   angular.module('com.vmware.vsphere.client.host')
         .factory('SecurityProfileDialogManager', SecurityProfileDialogManager);

   SecurityProfileDialogManager.$inject = ['i18nService', 'mutationService'];

   function SecurityProfileDialogManager(i18nService, mutationService) {

      return function SecurityProfileDialogManager(securityProfileData, securityProfileDialogOptions) {

         var lockdownModeDef, exceptionUsersDef, isChanged = false;

         createPages();

         return {
            getPageDefinitions: getPageDefinitions,
            getLockdownModePageModel: getLockdownModePageModel,
            getExceptionUsersPageModel: getExceptionUsersPageModel,
            submit: submit
         };

         function createPages() {
            // page definitions have properties like page, model, etc.
            lockdownModeDef = {
               page: {
                  title: i18nService.getString('HostUi', 'editLockdownView.title'),
                  contentUrl: 'host-ui/resources/host/views/settings/securityProfile/editLockdownModeView.html'
               }
            };
            exceptionUsersDef = {
               page: {
                  title: i18nService.getString('HostUi', 'editExceptionUsersView.title'),
                  contentUrl: 'host-ui/resources/host/views/settings/securityProfile/editExceptionUsersView.html'
               }
            };
         }

         function getPageDefinitions() {
            var pages = [];

            pages.push(lockdownModeDef.page);
            if (securityProfileData.isHostAccessManagerAvailable) {
               pages.push(exceptionUsersDef.page);
            }
            return pages;
         }

         function getLockdownModePageModel() {
            if (!lockdownModeDef.model) {
               lockdownModeDef.model = {
                  lockdownMode: securityProfileData.lockdownMode,
                  hostAccessManagerAvailable: securityProfileData.isHostAccessManagerAvailable
               };
            }
            return lockdownModeDef.model;
         }

         function getExceptionUsersPageModel() {
            if (!exceptionUsersDef.model) {
               exceptionUsersDef.model = {
                  exceptionUsers: securityProfileData.exceptionUsers
               };
            }
            return exceptionUsersDef.model;
         }

         function submit() {
            // isChanged - checks if there is a change in either lockdown mode or exception user or both,
            // otherwise don't call the mutation op
            var hostLockdownModeUpdateSpec = {};
            if (lockdownModeDef.model && lockdownModeDef.model.lockdownMode !== securityProfileData.lockdownMode) {
               hostLockdownModeUpdateSpec.lockdownMode = lockdownModeDef.model.lockdownMode;
               isChanged = true;
            }

            var hostId = securityProfileDialogOptions.actionDetails.availableTargets[0];

            // this depends on the host version
            if (securityProfileData.isHostAccessManagerAvailable) {
               if (exceptionUsersDef.model && exceptionUsersDef.model.exceptionUsers !== securityProfileData.exceptionUsers) {
                  hostLockdownModeUpdateSpec.exceptionUsers = exceptionUsersDef.model.exceptionUsers;
                  isChanged = true;
               }
            }

            if (isChanged) {
               mutationService.apply(hostId,
                     "com.vmware.vsphere.client.host.config.HostLockdownModeUpdateSpec",
                     hostLockdownModeUpdateSpec
               );
            }

            return true;
         }
      };
   }
})();
