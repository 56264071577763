angular.module('com.vmware.vsphere.client.vm').directive('sharesControl', ['$document', 'i18nService', function ($document, i18nService) {
   'use strict';

   return {
      templateUrl: 'vm-ui/resources/vm/views/settings/vmHardwareSettings/sharesControl/sharesControl.html',
      scope: {
         sharesInfo: '=',
         sharesValueMap: '=',
         shareValueMin: '@',
         shareValueMax: '@',
         isDisabled: '=',
         formInputFieldName: '@',
         formSelectFieldName: '@',
         showRecommendations: '=?',
         sharesAriaLabel: '<?',
         quantityCaretAriaLabel: "<?",
         quantityAriaLabel: "<?"
      },
      link: function (scope, element, attrs, ctrl) {
         scope.i18n = i18nService.getString;
         scope.shareValueMin = parseInt(scope.shareValueMin, 10);
         scope.shareValueMax = parseInt(scope.shareValueMax, 10);
         scope.shareValueErrorMessages = {
            max: function() { return scope.i18n('Common', 'SharesControl.RangeError', scope.shareValueMin, scope.shareValueMax); },
            min: function() { return scope.i18n('Common', 'SharesControl.RangeError', scope.shareValueMin, scope.shareValueMax); }
         };
         scope.shareInfoLevels = [
            {label: scope.i18n('VmUi', 'sharesInfoLevel.low'), data: 'low'},
            {label: scope.i18n('VmUi', 'sharesInfoLevel.normal'), data: 'normal'},
            {label: scope.i18n('VmUi', 'sharesInfoLevel.high'), data: 'high'},
            {label: scope.i18n('VmUi', 'sharesInfoLevel.custom'), data: 'custom'}
         ];

         scope.showRecommendations = angular.isDefined(scope.showRecommendations) ? scope.showRecommendations : true;
         if (scope.showRecommendations) {
            scope.shareInfoValues = [{
               value: scope.shareValueMin, name: 'Minimum'
            }, {
               value: scope.shareValueMax, name: 'Maximum'
            }];
         }

         scope.properties = {
            selectedSharesLevel: _.find(scope.shareInfoLevels, function(x) {
               return scope.sharesInfo && x.data === scope.sharesInfo.level;
            })
         };

         scope.$watch('properties.selectedSharesLevel', function (newLevel) {
            if (!newLevel) {
               return;
            }
            scope.sharesInfo.level = newLevel.data;
            scope.isShareLevelCustom = scope.sharesInfo.level === 'custom';
            updateSharesData();
         });

         scope.$watch('sharesValueMap', function (newLevel) {
            if (!newLevel) {
               return;
            }
            updateSharesData();
         }, true);

         function updateSharesData() {
            var level = scope.sharesInfo.level;
            scope.properties.selectedSharesLevel = _.find(scope.shareInfoLevels, function(x) { return x.data === level; });
            if (scope.sharesInfo.level !== 'custom') {
               scope.sharesInfo.shares = reCalculateSharesValue(level);
            }
         }

         function reCalculateSharesValue(level) {
            if (level === 'custom') {
               return scope.sharesInfo.shares;
            } else {
               var value = scope.sharesValueMap[level];
               if (value === undefined || value === null) {
                  throw new Error('Unknown level: ', level);
               }
               return value;
            }
         }
      }
   };
}]);
