namespace h5_vm {

import MigrationService = h5_vm.MigrationService;
import MigrationService$ValidationResultsData = h5_vm.MigrationService$ValidationResultsData;
import MigrationWizardManager = h5_vm.MigrationWizardManager;
import MigrationWizardManager$ComputeResourceValidationData = h5_vm.MigrationWizardManager$ComputeResourceValidationData;
import MigrateSelectFolderPageModel = h5_vm.MigrateSelectFolderPageModel;

export class MigrateSelectFolderPageComponent {
   public templateUrl: string;
   public controller: any;
   public controllerAs: string;
   public bindings: any;

   constructor() {
      this.templateUrl = "vm-ui/resources/vm/views/migrationWizard/pages/migrateSelectFolderPageComponent.html";
      this.controller = MigrateSelectFolderPageComponentController;
      this.controllerAs = "ctrl";
      this.bindings = {
         wizardConfig: "<",
         migrationWizardManager: "<",
         pageModel: "<",
         // target from drag and drop operation
         destinationTarget: "<"
      };
   }

} // MigrateSelectFolderPageComponent

class MigrateSelectFolderPageComponentController {

   public static $inject: string[] = [
      "$scope",
      "defaultUriSchemeUtil",
      "managedEntityConstants",
      "migrationService",
      "compatibilityResultsPresenter",
      "i18nService",
      "wizardPageService",
      "dataService",
      "authorizationService",
      "$q"
   ];

   //
   // Used by the HTML page
   //
   public pageInstructions: any;
   public treeType: any;
   public treeRoot: any;
   public preselectedLocation: any;
   public compatibilityContents: any;

   public wizardConfig: any;
   public migrationWizardManager: MigrationWizardManager;
   public pageModel: MigrateSelectFolderPageModel;
   public destinationTarget: string;

   private dcFolderIdsCache: any;

   constructor(
      private $scope: any,
      private defaultUriSchemeUtil: any,
      private managedEntityConstants: any,
      private migrationService: MigrationService,
      private compatibilityResultsPresenter: any,
      private i18nService: any,
      private wizardPageService: any,
      private dataService: any,
      private authorizationService: any,
      private $q: any
   ) {
   }

   public $onInit() {
      this.pageInstructions = this.i18nService.getString(
            "VmUi", "MigrationWizard.selectFolderPage.Instructions");

      this.treeType = "VmFoldersTreeFromDatacenter";
      this.treeRoot = this.pageModel.datacenter;
      if (this.pageModel.selectedTarget) {
         this.preselectedLocation = this.pageModel.selectedTarget.uid;
         // disable `Next` button
         this.pageModel.isLoading = true;
      }

      this.dcFolderIdsCache = {};

      this.$scope.$on("$destroy", () => {
         this.setLoadingIndicator(false);
      });
   }

   public onTargetChange (locationId: any, locationName: any, nodeTypeId: any): void {
      if (!locationId || !locationName || !nodeTypeId) {
         return;
      }

      if (this.preselectedLocation === locationId) {
         // Tree has loaded to its preselection - enable `Next` button
         this.pageModel.isLoading = false;
      }

      this.pageModel.selectedTarget = {
         uid: locationId,
         name: locationName,
         type: nodeTypeId
      };
      this.setLoadingIndicator(true);
      this.validateSelection(this.pageModel.selectedTarget).then(() => {
         this.setLoadingIndicator(false);
      });
   }

   /**
    * Performs VM folder validation. The validation includes:
    * if datacenter is selected:
    *       - Its default VM folder should be retrieved
    *       - User should have privilege for migrating VM to this default VM folder
    * if VM folder is selected:
    *       - User should have privilege for migrating VM to the VM folder
    */
   private validateSelection(selectedTargetObj: {uid: string, name: string, type: string}): angular.IPromise<any> {
      this.compatibilityContents = [];
      this.wizardConfig.loadingMessage = this.i18nService.getString("CommonUi", "wizard.validating");

      // get the selected folder, check if it is DC or VM folder and calculate correct target folder
      let destinationType = this.defaultUriSchemeUtil.getEntityType(selectedTargetObj.uid);
      if (destinationType === this.managedEntityConstants.DATACENTER) {
         if (this.dcFolderIdsCache[selectedTargetObj.uid]) {
            return this.ckeckPrivilegesOnFolder(this.dcFolderIdsCache[selectedTargetObj.uid]);
         } else {
            return this.dataService.getProperties(selectedTargetObj.uid, ["vmFolder"])
                  .then((result: any) => {
                     let dcFolderUid = this.defaultUriSchemeUtil
                           .getVsphereObjectId(result["vmFolder"]);
                     this.dcFolderIdsCache[selectedTargetObj.uid] = dcFolderUid;
                     this.ckeckPrivilegesOnFolder(dcFolderUid);
                  });
         }
      } else if (destinationType === this.managedEntityConstants.FOLDER) {
         return this.ckeckPrivilegesOnFolder(selectedTargetObj.uid);
      }

      // selectedTargetObj is not valid type
      return this.$q.when({});
   }

   private ckeckPrivilegesOnFolder(destinationFolderUid: string): angular.IPromise<any> {
      let requiredPrivileges = ["VirtualMachine.Inventory.Create",
         "VirtualMachine.Inventory.Register"];

      return this.authorizationService.checkPrivileges(destinationFolderUid, requiredPrivileges)
            .then((privilegeStatuses: Array<boolean>) => {
               if (this.hasPrivileges(privilegeStatuses)) {
                  this.pageModel.destinationFolder = destinationFolderUid;
                  this.compatibilityContents = this.makeCompatibilityMessage(true);
               } else {
                  this.pageModel.destinationFolder = undefined;
                  let destinationType: string|undefined;
                  if (this.pageModel.selectedTarget) {
                     destinationType = this.defaultUriSchemeUtil
                           .getEntityType(this.pageModel.selectedTarget.uid);
                  }
                  this.compatibilityContents = this.makeCompatibilityMessage(false,
                        destinationType);
               }
            });
   }

   private hasPrivileges(privilegeStatuses: Array<boolean>) {
      let hasActionPrivileges = true;
      for (let i = 0; i < privilegeStatuses.length; i++) {
         if (!privilegeStatuses[i]) {
            hasActionPrivileges = false;
            break;
         }
      }
      return hasActionPrivileges;
   }

   private makeCompatibilityMessage(hasActionPrivileges: boolean, destinationType?: string): Array<{icon: string, message: string, contents: Array<any>}>{
      if (hasActionPrivileges) {
         return [{
            icon: "vsphere-icon-status-ok",
            message: this.i18nService.getString(
                  "VmUi", "MigrationCompatibilityResultsView.Success"),
            contents: []
         }];
      }

      let message = this.i18nService.getString("VmUi", "MigrationWizard.selectFolderPage.error.NoRegisterVmPrivilegeOnFolder");
      if (destinationType === this.managedEntityConstants.DATACENTER) {
         message = this.i18nService.getString("VmUi", "MigrationWizard.selectFolderPage.error.NoRegisterVmPrivilegeOnDatacenter");
      }

      return [{
         icon: "vsphere-icon-status-error",
         message: message,
         contents: []
      }];
   }

   private setLoadingIndicator(setting: boolean): void {
      this.wizardConfig.loading = setting;
   }

} // class MigrateSelectFolderPageComponentController

angular.module("com.vmware.vsphere.client.vm").component(
      "migrateSelectFolderPageComponent",
      new MigrateSelectFolderPageComponent());

} // namespace
