namespace network_ui {

   export class NetStackEditAdvancedPageComponent {
      public bindings: any;
      public controller: any;
      public templateUrl: string;

      constructor() {
         this.bindings = {
            pageModel: "<"
         };

         this.controller = NetStackEditAdvancedPageComponentController;

         this.templateUrl = "network-ui/resources/network/views/host/netstack/" +
               "edit/pages/netStackEditAdvancedPageComponentTemplate.html";
      }
   }

   class NetStackEditAdvancedPageComponentController {
      static $inject = [
         "i18nService",
         "netStackEditAdvancedPageValidator",
         "vxValidatorFactory"
      ];
      private pageModel: NetStackEditAdvancedPageModel;
      private algTypes: any[] = [];
      private validator: any;
      private validateMaxNumOfConnections: () => string[];

      constructor(private i18nService: any,
                  private netStackEditAdvancedPageValidator: NetStackEditAdvancedPageValidator,
                  private vxValidatorFactory: any) {
         this.validator = vxValidatorFactory.create();
      }

      public $onInit(): void {
         this.initValidationCallbacks();
         let newRenoValue: string = "newreno";
         let cubicValue: string = "cubic";
         this.algTypes = [
            {
               key: newRenoValue,
               label: this.i18nService.getString(
                     "NetworkUi", "AdvancedNetStackPage.newReno")
            }, {
               key: cubicValue,
               label: this.i18nService.getString(
                     "NetworkUi", "AdvancedNetStackPage.cubic")
            }
         ];
      }

      private initValidationCallbacks(): void {
         this.validateMaxNumOfConnections = (): string[] => {
            let maxNumOfConnectionsErrorMessage: string | null =
                  this.netStackEditAdvancedPageValidator
                        .getMaxNumberOfConnectionsValidationMessage(
                              this.pageModel.maxNumberOfConnections);
            return maxNumOfConnectionsErrorMessage ?
                  [maxNumOfConnectionsErrorMessage] : [];
         };
      }
   }

   angular.module("com.vmware.vsphere.client.network")
         .component("netStackEditAdvancedPage", new NetStackEditAdvancedPageComponent());
}
