module hostprofile_ui {

   import ManagedEntityMoveSpec = com.vmware.vsphere.client.mixed.ManagedEntityMoveSpec;
   import ManagedObjectReference = com.vmware.vim.binding.vmodl.ManagedObjectReference;

   export class CustomizationTextInput {
      public bindings: any;
      public controller: any;
      public templateUrl: string;
      public controllerAs: any;
      public require: any;

      constructor() {
         this.require = {
            parentCtrl: "^hostCustomizations"
         };
         this.controllerAs = "ctrl";
         this.bindings = {
            item: "<"
         };
         this.controller = CustomizationTextInputController;
         this.templateUrl = "hostprofile-ui/resources/hostprofile/customizations/customizationTextInputTemplate.html";
      }
   }

   class CustomizationTextInputController implements angular.IComponentController {
      static $inject = [
         "$scope",
         "i18nService",
         "hostCustomizationsErrorsService",
         "$element",
         "HostProfileConstants"
      ];

      public item: HostCustomizationItem;
      public parentCtrl: HostsCustomizationsController;
      public requiredConfig: any;

      constructor(private $scope: any, private i18nService: any,
            private hostCustomizationsErrorsService: HostCustomizationsErrorsService,
            private $element: any, private HostProfileConstants: any) {
         this.requiredConfig = {
            message: this.i18nService.getString("HostProfileUi", "hostCustomizations.requiredField.error"),
            isVisible: false
         };
      }

      public updateValue(newValue: string) {
         this.item.value = newValue;
         this.requiredConfig.isVisible = this.hostCustomizationsErrorsService
               .isPropertyRequiredAndEmpty(this.item);
         this.parentCtrl.updateHostCustomizationSetting(this.item);
         this.item.error = null;
      }

      public getErrorConfig(): any {
         if (!this.requiredConfig.isVisible && this.item.error) {
            return this.item.error;
         }

         return this.requiredConfig;
      }

   }

   angular.module("com.vmware.vsphere.client.hostprofile")
         .component("customizationTextInput", new CustomizationTextInput());
}
