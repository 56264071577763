/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace dvs_ui {

   import DvsSpanSessionPortBrowserPortData =
         com.vmware.vsphere.client.h5.network.dvs.portmirroring.model.portbrowser.DvsSpanSessionPortBrowserPortData;

   export class DvsSpanSessionPortBrowserComponent {

      public bindings: any;
      public controller: any;
      public templateUrl: string;

      constructor() {
         this.bindings = {
            dvsUrn: "<",
            portsToIgnore: "<",
            onSelectionChange: "<"
         };

         this.controller = DvsSpanSessionPortBrowserComponentController;

         this.templateUrl = "dvs-ui/resources/dvs/portmirroring/common/components/" +
               "dvsSpanSessionPortBrowserComponentTemplate.html";
      }
   }

   class DvsSpanSessionPortBrowserComponentController {

      static $inject = [
            "i18nService",
            "vuiConstants",
            "dvsSpanSessionPortBrowserService"
      ];

      public dvsUrn: string;

      public portsToIgnore: string[];

      public onSelectionChange: Function;

      public datagridOptions: any;

      public loading: boolean;

      public getString: (key: string, bundle?: string) => string;

      constructor(private i18nService: any,
                  private vuiConstants: any,
                  private portBrowserService: DvsSpanSessionPortBrowserService) {
         this.getString = (key: string, bundle: string = "DvsUi"): string => {
            return i18nService.getString(bundle, key);
         };
      }

      public $onInit(): void {
         this.datagridOptions = this.portBrowserService.createDatagridOptions(this.onSelectionChange);
         this.loading = true;
         this.portBrowserService.getData(this.dvsUrn)
               .then((data: DvsSpanSessionPortBrowserPortData[]): void => {
                  let filteredData: DvsSpanSessionPortBrowserPortData[];
                  if (this.portsToIgnore && data) {
                     // minor optimization
                     let ignoredPorts: _.Dictionary<boolean> = {};
                     this.portsToIgnore.forEach((portId: string): void => {
                        ignoredPorts[portId] = true;
                     });

                     filteredData = data.filter((portData: DvsSpanSessionPortBrowserPortData): boolean => {
                              return !ignoredPorts[portData.portId];
                           });
                  } else {
                     filteredData = data;
                  }

                  this.datagridOptions.data = filteredData || [];
                  this.loading = false;
               });
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .component("dvsSpanSessionPortBrowser",
               new DvsSpanSessionPortBrowserComponent());
}
