/* Copyright 2018 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace h5_vapp {

   class LegacyVappEulaPageView {
      public vappId: string;
      constructor() {
      }
   }

   angular.module("com.vmware.vsphere.client.vapp").component("legacyVappEulaPageView", {
      bindings: {
         vappId: "<"
      },
      controller: LegacyVappEulaPageView,
      template: `
            <vapp-eula-page-view
               class="eula-flex-fill-parent"
               [vapp-id]="$ctrl.vappId"
            >
            </vapp-eula-page-view>
      `
   });
}