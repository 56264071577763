/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
module hostprofile_ui {

   class ClusterHostProfileSettingsController implements angular.IController {
      public static $inject = [
         "$scope",
         "$element",
         "i18nService",
         "clusterHostProfileSettingsService",
         "vcService",
         "defaultUriSchemeUtil"];

      public is66VcOrLater: boolean;
      public selectedItemId: String;
      isComplianceExpanded: boolean = false;
      private objectsToWatch: any = [];
      private headerOptions: any;
      private objectId: any;
      private splitterOptions: any;
      private attachDetachViewHeaderAccessor: any = {};
      private focusedItem: any;
      private gridData: any[];
      private liveRefreshProperties: string[];
      private remediationErrorBannerVisible: boolean = false;

      constructor(private $scope: any,
                  private $element: any,
                  private i18nService: any,
                  private clusterHostProfileSettingsService: ClusterHostProfileSettingsService,
                  private vcService: any, private defaultUriSchemeUtil: any) {

         this.splitterOptions = this.clusterHostProfileSettingsService.getSplitterOptions();
         this.objectId = this.$scope._route.objectId;
         this.focusedItem = null;
         this.objectsToWatch = [this.objectId];
         this.headerOptions = this.clusterHostProfileSettingsService.getHeaderOptions(this.objectId);
         this.liveRefreshProperties = ["configStatus", "complianceCheckState",
            "remediationState", "answerFileValidationState"];

         vcService.is66VcOrLater(this.objectId).then((is66VcOrLater: boolean) => {
            this.is66VcOrLater = is66VcOrLater;
         });

         this.$scope.$on("hostProfileRemediationErrorsEvent", (event: any, showBanner: boolean) => {
            this.remediationErrorBannerVisible = showBanner;
         });
      }

      public onDataReceived(data: any): void {
         this.gridData = data;
      }

      // Used to force refresh of the data in the child component (changing the reference triggers ngOnChanges in
      // host-profile-host-settings-details)
      public refresh65ComplianceFailures(): void {
         this.selectedItemId = new String(this.selectedItemId);
      }


      public onFocusGridRow(focusedItem: any): void {
         this.focusedItem = focusedItem;
         if (focusedItem) {
            this.selectedItemId = this.defaultUriSchemeUtil.getVsphereObjectId(
                  focusedItem.provider);
         }
      }

      onComplianceExpanded(): void {
         this.isComplianceExpanded = true;
         let compliancePanel = this.getCompliancePanel();
         let splitter = this.getSplitter();
         splitter.min(compliancePanel, "400px");
         if (compliancePanel.height() < 400) {
            splitter.size(compliancePanel, "400px");
         }
      }

      onComplianceCollapsed(): void {
         this.isComplianceExpanded = false;
         let compliancePanel = this.getCompliancePanel();
         let splitter = this.getSplitter();
         splitter.min(compliancePanel, "240px");
      }

      getSplitterHolderClass(): string {
         return this.isComplianceExpanded ?
               "splitter-holder-compliance-expanded" :
               "splitter-holder-compliance-collapsed";
      }

      // show the remediation error banner if there are remediation errors persisted in the local storage
      public showRemediationErrorBanner(event: boolean): void {
         if (event === true && !this.remediationErrorBannerVisible) {
            this.remediationErrorBannerVisible = event;
         }
      }

      // hide the remediation error banner if there are no remediation errors persisted in the local storage
      public hideRemediationErrorBanner(event: boolean): void {
         if (event === false && this.remediationErrorBannerVisible) {
            this.remediationErrorBannerVisible = event;
         }
      }

      private getCompliancePanel(): any {
         return this.$element.find(
               ".profile-compliance-failure-section");
      }

      private getSplitter(): any {
         return this.$element.find("[kendo-splitter]")
               .data("kendoSplitter");
      }

      private getComplianceTitle(): string {
         let placeholder: string = "";
         if (this.focusedItem) {
            placeholder = this.focusedItem.hostName;
         }
         return this.i18nService.getString("HostProfileUi",
               "compliance.status.labelWithPlaceholder", placeholder);
      }

      private refreshContent() {
         if (this.attachDetachViewHeaderAccessor &&
               this.attachDetachViewHeaderAccessor.refresh) {
            this.attachDetachViewHeaderAccessor.refresh();
         }
      }
   }

   angular
         .module("com.vmware.vsphere.client.hostprofile")
         .controller("ClusterHostProfileSettingsController", ClusterHostProfileSettingsController);
}
