/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
(function () {
   'use strict';
   angular
         .module('com.vmware.vsphere.client.network')
         .controller('AddHostNetworkingReadyToCompletePageController',
               AddHostNetworkingReadyToCompletePageController);

   AddHostNetworkingReadyToCompletePageController.$inject = [
         '$scope',
         'addNetworkingWizardConstants',
         'i18nService',
         'vxPropertyViewService',
         'networkUtil',
         'hostVnicServiceFactory',
         'networkUiConstants'];

   function AddHostNetworkingReadyToCompletePageController(
         $scope,
         addNetworkingWizardConstants,
         i18nService,
         propertyViewService,
         networkUtil,
         hostVnicServiceFactory,
         networkUiConstants) {

      switch ($scope.selectConnectionTypePageModel.connectionType) {
         case addNetworkingWizardConstants.connectionType.VMKERNEL :
            buildSummaryForVmkernelConnectionType();
            break;

         case addNetworkingWizardConstants.connectionType.VM_PORTGROUP :
            buildSummaryForVmPortgroupConnectionType();
            break;

         case addNetworkingWizardConstants.connectionType.PNIC :
            buildSummaryForPnicConnectionType();
            break;
      }

      function buildSummaryForVmPortgroupConnectionType() {
         var builder = propertyViewService.createPropertyViewBuilder();
         var generalCategory = builder.category("general");
         var generalSection = generalCategory.section("generalSection");


         if ($scope.selectTargetNetworkPageModel.targetType
               === addNetworkingWizardConstants.targetType.EXISTING_STANDARD_SWITCH) {

            generalSection.property(
                  i18nService.getString(
                        'NetworkUi', 'AddNetworkingWizard.summary.vmNetworking'),
                  $scope.addNetworkingSpec.networkLabel);

            generalSection.property(
                  i18nService.getString(
                        'NetworkUi', 'AddNetworkingWizard.summary.virtualSwitch'),
                  $scope.addNetworkingSpec.selectedStandardSwitch.name);
         }

         if ($scope.selectTargetNetworkPageModel.targetType
               === addNetworkingWizardConstants.targetType.NEW_STANDARD_SWITCH) {

            generalSection.property(
                  i18nService.getString(
                        'NetworkUi', 'AddNetworkingWizard.summary.newVirtualSwitch'),
                  $scope.vSwitchSpec.name);

            generalSection.property(
                  i18nService.getString(
                        'NetworkUi', 'AddNetworkingWizard.summary.vmNetworking'),
                  $scope.addNetworkingSpec.networkLabel);
            generalSection.property(
                  i18nService.getString('NetworkUi',
                        'AddNetworkingWizard.summary.assignedAdapters'),
                  formatPnics($scope.vSwitchSpec.pnics));

            if ($scope.hostCapability.switchAutoScaleSupported === false) {
               generalSection.property(
                     i18nService.getString(
                           'NetworkUi',
                           'AddNetworkingWizard.summary.newVirtualSwitch.numPorts'),
                     $scope.vSwitchSpec.numPorts);
            }

            generalSection.property(
                  i18nService.getString(
                        'H5NetworkUi', 'AddNetworkingWizard.summary.switchMtu'),
                  $scope.vSwitchSpec.mtu);

         }
               generalSection.property(
                     i18nService.getString(
                           'NetworkUi', 'AddNetworkingWizard.summary.vlanId'),
                     networkUtil.formatVlanId($scope.addNetworkingSpec.vlanId));

         $scope.summaryData = builder.build();
      }

      function buildSummaryForVmkernelConnectionType() {
         var builder = propertyViewService.createPropertyViewBuilder();
         var generalCategory = builder.category("general");
         var generalSection = generalCategory.section("generalSection");

         if ($scope.selectTargetNetworkPageModel.targetType
               === addNetworkingWizardConstants.targetType.EXISTING_NETWORK) {
            if ($scope.addNetworkingSpec.selectedNetwork.isDistributedPortgroup) {
               generalSection.property(
                     i18nService.getString(
                           'NetworkUi', 'AddNetworkingWizard.summary.dvPortGroup'),
                     $scope.addNetworkingSpec.selectedNetwork.name);

               generalSection.property(
                     i18nService.getString('NetworkUi', 'AddNetworkingWizard.summary.dvs'),
                     $scope.addNetworkingSpec.selectedNetwork.dvsName);
            }
            if ($scope.addNetworkingSpec.selectedNetwork.isOpaqueNetwork) {
               if ($scope.addNetworkingSpec.selectedNetwork.isNsxNetwork) {
                  generalSection.property(
                     i18nService.getString(
                        'NetworkUi', 'AddNetworkingWizard.summary.nsxOpaqueNetworkName'),
                     $scope.addNetworkingSpec.selectedNetwork.name);
               } else {
                  generalSection.property(
                     i18nService.getString(
                        'NetworkUi', 'AddNetworkingWizard.summary.opaqueNetworkName'),
                     $scope.addNetworkingSpec.selectedNetwork.name);
               }
            }
         }

         if ($scope.selectTargetNetworkPageModel.targetType
               === addNetworkingWizardConstants.targetType.EXISTING_STANDARD_SWITCH) {
            generalSection.property(
                  i18nService.getString(
                        'NetworkUi', 'AddNetworkingWizard.summary.newPortgroup'),
                  $scope.addNetworkingSpec.networkLabel);

            generalSection.property(
                  i18nService.getString(
                        'NetworkUi', 'AddNetworkingWizard.summary.virtualSwitch'),
                  $scope.addNetworkingSpec.selectedStandardSwitch.name);

            generalSection.property(
                  i18nService.getString(
                        'NetworkUi', 'AddNetworkingWizard.summary.vlanId'),
                  networkUtil.formatVlanId($scope.addNetworkingSpec.vlanId));
         }

         if ($scope.selectTargetNetworkPageModel.targetType
               === addNetworkingWizardConstants.targetType.NEW_STANDARD_SWITCH) {

            generalSection.property(
                  i18nService.getString(
                        'NetworkUi', 'AddNetworkingWizard.summary.newVirtualSwitch'),
                  $scope.vSwitchSpec.name);

            generalSection.property(
                  i18nService.getString('NetworkUi',
                        'AddNetworkingWizard.summary.assignedAdapters'),
                  formatPnics($scope.vSwitchSpec.pnics));

            if ($scope.hostCapability.switchAutoScaleSupported === false) {
               generalSection.property(
                     i18nService.getString(
                           'NetworkUi',
                           'AddNetworkingWizard.summary.newVirtualSwitch.numPorts'),
                     $scope.vSwitchSpec.numPorts);
            }

            generalSection.property(
                  i18nService.getString(
                        'H5NetworkUi', 'AddNetworkingWizard.summary.switchMtu'),
                  $scope.vSwitchSpec.mtu);

            generalSection.property(
                  i18nService.getString(
                        'NetworkUi', 'AddNetworkingWizard.summary.newPortgroup'),
                  $scope.addNetworkingSpec.networkLabel);

            generalSection.property(
                  i18nService.getString(
                        'NetworkUi', 'AddNetworkingWizard.summary.vlanId'),
                  networkUtil.formatVlanId($scope.addNetworkingSpec.vlanId));

         }

         var serviceSettings = hostVnicServiceFactory
               .pickServiceSettings($scope.addNetworkingSpec.vmkernelSettingsSpec);
         var serviceDescriptions = hostVnicServiceFactory
               .getServiceDescriptions($scope.portPropertiesPageModel.hostVnicFeatures);

         _.forEach(serviceSettings, function(value, key) {
            var serviceData = serviceDescriptions[key];
            generalSection.property(serviceData.summaryLabel, formatEnabledValue(value));
         });

         if ($scope.selectTargetNetworkPageModel.targetType
               === addNetworkingWizardConstants.targetType.EXISTING_NETWORK
               && $scope.addNetworkingSpec.selectedNetwork.isOpaqueNetwork
               && $scope.bindPnicPageModel.bindToPnic
               && $scope.bindPnicPageModel.selectedPnic) {
            generalSection.property(
                  i18nService.getString(
                        'NetworkUi', 'AddNetworkingWizard.summary.boundPnic'),
                  $scope.bindPnicPageModel.selectedPnic);

         }

         var nicSettingsSection =
               generalCategory
                     .section('nicSettings')
                     .title(i18nService.getString(
                           'H5NetworkUi', 'AddNetworkingWizard.summary.nicSettings'));

         nicSettingsSection.property(
               i18nService.getString('H5NetworkUi', 'AddNetworkingWizard.summary.mtu'),
               $scope.addNetworkingSpec.mtu
         );

         if ($scope.addNetworkingSpec.netStackName
               && $scope.addNetworkingSpec.netStackName.stackName) {
            nicSettingsSection.property(
                  i18nService.getString('H5NetworkUi', 'AddNetworkingWizard.summary.netStack'),
                  $scope.addNetworkingSpec.netStackName.stackName
            );
         }

         // IPv4 Section
         if ($scope.portPropertiesPageModel.ipSettingsType
               === addNetworkingWizardConstants.ipSettingsMode.IP_V4
               || $scope.portPropertiesPageModel.ipSettingsType
               === addNetworkingWizardConstants.ipSettingsMode.MIXED) {
            var ipSettingsSection =
                  generalCategory
                        .section('ipv4Settings')
                        .title(i18nService.getString(
                              'NetworkUi', 'AddNetworkingWizard.summary.ipv4Settings'));

            if ($scope.addNetworkingSpec.dhcpEnabled) {
               ipSettingsSection.property(
                     i18nService.getString(
                           'NetworkUi', 'AddNetworkingWizard.summary.ipv4Dhcp'),
                     i18nService.getString(
                           'NetworkUi', 'AddNetworkingWizard.summary.enabled'));
            } else {
               ipSettingsSection.property(
                     i18nService.getString(
                           'NetworkUi', 'AddNetworkingWizard.summary.ipv4Address'),
                     i18nService.getString(
                           'H5NetworkUi',
                           'AddNetworkingWizard.readyToCompletePage.staticIpAddressFormat',
                           $scope.addNetworkingSpec.ipv4Address));

               ipSettingsSection.property(
                     i18nService.getString(
                           'NetworkUi', 'AddNetworkingWizard.summary.subnetMask'),
                     $scope.addNetworkingSpec.subnetMask);

               if ($scope.addNetworkingSpec.overrideGateway) {
                  ipSettingsSection.property(
                        i18nService.getString(
                              'NetworkUi', 'AddNetworkingWizard.summary.ipv4Gateway'),
                        $scope.addNetworkingSpec.ipv4DefaultGateway);
               }
            }
         }

         // IPv6 Section
         if ($scope.portPropertiesPageModel.ipSettingsType
               === addNetworkingWizardConstants.ipSettingsMode.IP_V6
               || $scope.portPropertiesPageModel.ipSettingsType
               === addNetworkingWizardConstants.ipSettingsMode.MIXED) {
            var ipv6SettingsSection =
                  generalCategory
                        .section('ipv6Settings')
                        .title(i18nService.getString(
                              'NetworkUi', 'AddNetworkingWizard.summary.ipv6Settings'));

            ipv6SettingsSection.property(
                  i18nService.getString(
                        'NetworkUi', 'AddNetworkingWizard.summary.ipv6Dhcp'),
                  formatEnabledValue($scope.addNetworkingSpec.ipv6DhcpEnabled));

            ipv6SettingsSection.property(
                  i18nService.getString(
                        'NetworkUi', 'AddNetworkingWizard.summary.routerAdv'),
                  formatEnabledValue($scope.addNetworkingSpec.routerAdvertisementEnabled));

            if($scope.addNetworkingSpec.staticAddressesEnabled
                  && $scope.addNetworkingSpec.ipv6Addresses.length > 0) {

               ipv6SettingsSection.property(
                     i18nService.getString(
                           'NetworkUi', 'AddNetworkingWizard.summary.ipv6Addresses'),
                     formatIpv6Addresses($scope.addNetworkingSpec.ipv6Addresses));

               if ($scope.addNetworkingSpec.overrideIpv6Gateway) {
                  ipv6SettingsSection.property(
                        i18nService.getString(
                              'NetworkUi', 'AddNetworkingWizard.summary.ipv6Gateway'),
                        $scope.addNetworkingSpec.ipv6DefaultGateway);
               }
            }
         }

         $scope.summaryData = builder.build();
      }

      function buildSummaryForPnicConnectionType() {
         var builder = propertyViewService.createPropertyViewBuilder();
         var generalCategory = builder.category("general");
         var generalSection = generalCategory.section("generalSection");

         if ($scope.selectTargetNetworkPageModel.targetType
               === addNetworkingWizardConstants.targetType.NEW_STANDARD_SWITCH) {
            generalSection.property(
                  i18nService.getString(
                        'NetworkUi', 'AddNetworkingWizard.summary.newVirtualSwitch'),
                  $scope.vSwitchSpec.name);

            generalSection.property(
                  i18nService.getString('NetworkUi',
                        'AddNetworkingWizard.summary.assignedAdapters'),
                  formatPnics($scope.vSwitchSpec.pnics));

            if ($scope.hostCapability.switchAutoScaleSupported === false) {
               generalSection.property(
                     i18nService.getString(
                           'NetworkUi',
                           'AddNetworkingWizard.summary.newVirtualSwitch.numPorts'),
                     $scope.vSwitchSpec.numPorts);
            }

            generalSection.property(
                  i18nService.getString(
                        'H5NetworkUi', 'AddNetworkingWizard.summary.switchMtu'),
                  $scope.vSwitchSpec.mtu);
         } else if ($scope.selectTargetNetworkPageModel.targetType
               === addNetworkingWizardConstants.targetType.EXISTING_STANDARD_SWITCH) {
            if ($scope.addNetworkingSpec.selectedStandardSwitch.type
                  === networkUiConstants.switchType.STANDARD) {

               generalSection.property(
                     i18nService.getString(
                           'NetworkUi', 'AddNetworkingWizard.summary.virtualSwitch'),
                     $scope.addNetworkingSpec.selectedStandardSwitch.name);

               generalSection.property(
                     i18nService.getString('NetworkUi',
                           'AddNetworkingWizard.summary.assignedAdapters'),
                     formatNewPnics(getNewPnics($scope.vSwitchSpec.pnics)));
            } else if ($scope.addNetworkingSpec.selectedStandardSwitch.type
                  === networkUiConstants.switchType.DISTRIBUTED) {

               generalSection.property(
                     i18nService.getString(
                           'NetworkUi', 'AddNetworkingWizard.summary.dvs'),
                     $scope.addNetworkingSpec.selectedStandardSwitch.name);

               generalSection.property(
                     i18nService.getString('NetworkUi',
                           'AddNetworkingWizard.summary.assignedAdapters'),
                     formatNewPnics(_.values($scope.addPnicToDvsPageModel.portMapping.addedMappings)));
            }
         }
         $scope.summaryData = builder.build();
      }

      function formatEnabledValue(isEnabled) {
         return i18nService.getString('NetworkUi',
               isEnabled ? 'AddNetworkingWizard.summary.enabled'
                     : 'AddNetworkingWizard.summary.disabled');
      }

      function formatIpv6Addresses(ipv6Addresses) {
         return _.map(ipv6Addresses, function (ipv6) {
            return ipv6.address + '/' + ipv6.prefixLength + ' ' +
                  i18nService.getString('NetworkUi', 'VnicView.ipAddrStaticTypeFormat');
         });
      }

      function formatPnics(pnics) {
         var result = [];

         if (pnics) {
            if (pnics.active && pnics.active.length > 0) {
               result = result.concat(pnics.active);
            }
            if (pnics.standby && pnics.standby.length > 0) {
               result = result.concat(pnics.standby);
            }
            if (pnics.unused && pnics.unused.length > 0) {
               result = result.concat(pnics.unused);
            }
         }

         return result.length > 0
            ? result.join(', ')
            : i18nService.getString('NetworkUi', 'AddNetworkingWizard.summary.noData');
      }

      function formatNewPnics(pnics) {
         return pnics.join(', ');
      }

      function getNewPnics(pnics) {
         var newPnics = [];
         newPnics.push.apply(newPnics, _.filter(pnics.active, function (pnic) {
            return _.indexOf(pnics.unclaimedInitial, pnic) !== -1;
         }));

         newPnics.push.apply(newPnics, _.filter(pnics.standby, function (pnic) {
            return _.indexOf(pnics.unclaimedInitial, pnic) !== -1;
         }));

         newPnics.push.apply(newPnics, _.filter(pnics.unused, function (pnic) {
            return _.indexOf(pnics.unclaimedInitial, pnic) !== -1;
         }));
         return newPnics;
      }
      return this;
   }
})();
