(function () {
   'use strict';

   angular.module('com.vmware.vsphere.client.vm')
         .service('selectIsoFileService', SelectIsoFileService);

   SelectIsoFileService.$inject = ['clarityModalService', 'i18nService',
      'defaultUriSchemeUtil', 'datastoreBrowserConstants'];

   function SelectIsoFileService(clarityModalService, i18nService,
         defaultUriSchemeUtil, datastoreBrowserConstants) {

      return {
         display: function (scope) {
            var selectedIsoFile;

            var contentUrl = 'vm-ui/resources/vm/views/settings/vmHardwareSettings/' +
                  'vmHardwareVirtualDisk/select-iso-file.view.html';
            var modalOptions = {
               title: i18nService.getString('Common', 'dsBrowser.defaultTitle'),
               contentTemplate: contentUrl,
               defaultButton: 'submit',
               size: 'lg',
               hideSubmitButton: false,
               submitDisabled: true,
               onSubmit: function () {
                  scope.device.backing.fileName = selectedIsoFile.path;
                  scope.$broadcast('isoDeviceBackingChange', scope.device.backing);
                  return true;
               }
            };

            var dsBrowserId = defaultUriSchemeUtil.getVsphereObjectId(
                  scope.vmConfigContext.environment.datastoreBrowser);
            var vmDatastores = scope.vmConfigContext.environment.configTarget.datastore;
            var datastoreIds = _.map(vmDatastores, function(vmDatastore) {
               return defaultUriSchemeUtil.getVsphereObjectId(vmDatastore.datastore.datastore);
            });
            var initialFileQueryType = datastoreBrowserConstants.fileQueryType.ISO_IMAGE
                  + datastoreBrowserConstants.fileQueryType.FOLDERS
                  + datastoreBrowserConstants.fileQueryType.FLOPPY_IMAGE;
            var filterQueryTypes = [{
                  label: i18nService.getString('Common', 'dsBrowser.isoImageFile'),
                  value: datastoreBrowserConstants.fileQueryType.ISO_IMAGE
                     + datastoreBrowserConstants.fileQueryType.FOLDERS
               },
               {
                  label: i18nService.getString('Common', 'dsBrowser.allFiles'),
                  value: datastoreBrowserConstants.fileQueryType.ALL_FILES
               }
            ];
            modalOptions.dsFileBrowserData = {
               datastoreBrowserId: dsBrowserId,
               datastoreIds: datastoreIds,
               initialFileQueryType: initialFileQueryType,
               filterFileQueryTypes: filterQueryTypes,
               showVmImagesFolder: true,
               selectedFileChanged: function (selectedFile) {
                  selectedIsoFile = selectedFile;
                  modalOptions.submitDisabled = !selectedIsoFile;
               }
            };

            clarityModalService.openOkCancelModal(modalOptions);
         }
      };
   }
})();
