(function () {
   'use strict';
   angular.module('com.vmware.vsphere.client.host')
      .service('addHostVmLocationPageService', addHostVmLocationPageService);

   addHostVmLocationPageService.$inject = ['i18nService'];

   function addHostVmLocationPageService(i18nService) {
      return {
         build: function (hostAdder, wizard) {
            return {
               title: i18nService.getString('HostUi', 'addHostWizard.vmLocationPage.title'),
               contentUrl: 'host-ui/resources/host/views/addHostWizard/pages/addHostVmLocationPage.html',
               onCommit: function () {
                  if (!hostAdder.getVmLocationPageModel().selectedLocationId) {
                     wizard.markPageIncompleteWithErrors([
                           i18nService.getString('HostUi',
                                 'addHostWizard.vmLocationPage.error')]);
                     return false;
                  }

                  wizard.markPageComplete();
                  return true;
               }
            };
         }
      };
   }
})();
