angular.module('com.vmware.vsphere.client.vm').service('vmProvisioningSelectVmToClonePageService',
   ['i18nService',
      'defaultUriSchemeUtil',
      'wizardPageService',
      'vmVuiWizardPageBuilder',
      'VmProvisioningSelectVmToClonePageModel',
      function(i18nService,
               defaultUriSchemeUtil,
               wizardPageService,
               vmVuiWizardPageBuilder,
               VmProvisioningSelectVmToClonePageModel) {
         return {
            build: function(wizardScope) {
               var selectVmToClonePageModel = new VmProvisioningSelectVmToClonePageModel(
                  wizardScope.vmParams,
                  wizardScope.wizardViewData,
                  wizardScope.config
               );
               wizardScope.selectVmToClonePageModel = selectVmToClonePageModel;

               return vmVuiWizardPageBuilder.buildVuiWizardPage(wizardScope.config, {
                  title: i18nService.getString('VmUi', 'SelectTemplateProvisioningPage.Vm.Title'),
                  description: i18nService.getString('VmUi', 'SelectTemplateProvisioningPage.Vm.Description'),
                  contentUrl: 'vm-ui/resources/vm/views/createVmWizard/vmProvisioningSelectVmToClone.html',
                  model: selectVmToClonePageModel
               });
            }
         };
      }]);
