namespace h5_vm {

   import VmProvisioningPageModel = h5_vm.VmProvisioningPageModel;

   class VmProvisioningVservicePageModelFactory {
      public static $inject = [
         "i18nService"
      ];

      constructor(i18nService: any) {
         return (wizardViewData: any, virtualMachineSpecBuilder: any) => {
            return new VmProvisioningVservicePageModel(
                  i18nService,
                  wizardViewData,
                  virtualMachineSpecBuilder);
         };
      }
   }

   export class VmProvisioningVservicePageModel implements VmProvisioningPageModel <any> {
      static $inject: string[] = [
         "i18nService"
      ];

      private formObj: any = {};

      constructor(private i18nService: any,
                  private wizardViewData: any,
                  private virtualMachineSpecBuilderObj: any) {
      }

      get form(): any {
         return this.formObj;
      }

      public validatePage(): any {
         if (this.validateProviderStatus()) {
            return {};
         }
         return {
            error: this.i18nService.getString(
                  "ProvisioningUiLib", "VServicePage.errorInvalidBindings"
            )
         };
      }

      public submitPage(): any {
         return {
            wizardFlow: this.virtualMachineSpecBuilderObj.getCreationType(),
            invalidateNextPages: false
         };
      }

      public resetForm(): void {
      }

      private validateProviderStatus(): boolean {
         let result: boolean = true;
         let vServiceContext: any =
               this.wizardViewData.getVserviceContextFromLibraryCommonContext();

         if (vServiceContext && vServiceContext.dependencies) {
            vServiceContext.dependencies.forEach((dependency: any) => {
               if (dependency.availableProviders &&
                     dependency.availableProviders.length > 0) {
                  let selectedProviderKey = dependency.selectedProviderKey;
                  if(!selectedProviderKey) {
                     result = false;
                     return;
                  }
                  let selectedProviderObj = dependency.availableProviders.find((provider: any) => {
                     return provider.key === selectedProviderKey;
                  });
                  //Validation
                  if (selectedProviderObj.status.name === 'red') {
                     result = false;
                     return;
                  }
               } else {
                  result = false;
                  return;
               }
            });
         }
         return result;
      }
   }

   angular.module("com.vmware.vsphere.client.vm")
         .service("vmProvisioningVservicePageModel",
               VmProvisioningVservicePageModelFactory);
}
