namespace network_ui {

   import IComponentController = angular.IComponentController;
   import IScope = angular.IScope;

   export class IscsiAddPortBindingCandidatesList {
      public bindings: any;
      public controller: any;
      public templateUrl: string;

      constructor() {
         this.bindings = {
            candidatePortGroupsData: "<",
            selectedPortGroups: "=?"
         };

         this.controller = IscsiAddPortBindingCandidatesListController;
         this.templateUrl = "network-ui/resources/network/views/host/iscsi/add/IscsiAddPortBindingCandidatesList.html";
      }
   }

   class IscsiAddPortBindingCandidatesListController implements IComponentController {
      public static $inject = ["$scope", "i18nService", "iscsiPortBindingService", "vuiConstants", "columnRenderersRegistry"];

      public candidatePortGroupsData: IscsiPortInfoData;
      public selectedPortGroups: IscsiPortInfoItem[];
      public datagridOptions: any;

      constructor(private $scope: IScope,
                  public i18nService: any,
                  public iscsiPortBindingService: any,
                  public vuiConstants: any,
                  public columnRenderersRegistry: any) {

      }

      public $onInit() {
         this.datagridOptions = {
            height: "100%",
            pageConfig: {
               hidePager: false
            },
            columnDefs: this.getColumnDefs(),
            sortMode: this.vuiConstants.grid.sortMode.SINGLE,
            selectionMode: this.vuiConstants.grid.selectionMode.MULTI,
            selectedItems: [],
            resizable: true,
            actionBarOptions: {actions: []},
            isItemDisabledCallback: function (item: any) {
               return !item.isCompliant;
            }
         };

         if (this.candidatePortGroupsData) {
            this.initGridData();
         }

         let self: IscsiAddPortBindingCandidatesListController = this;
         this.$scope.$watch(function () {
            return self.datagridOptions.selectedItems;
         }, function (newSelection: any, oldSelection: any) {
            if (newSelection === oldSelection || _.isEqual(newSelection, oldSelection)) {
               return;
            }
            self.selectedPortGroups = newSelection;
         });
      }

      public $onChanges(changes: any) {
         if (this.datagridOptions && changes.candidatePortGroupsData) {
            this.initGridData();
         }
      }

      private getColumnDefs(): any[] {
         let iconTextRenderer = this.columnRenderersRegistry.getColumnRenderer("icon-text");
         let textRenderer = this.columnRenderersRegistry.getColumnRenderer("text");

         let renderImageLabel = function (imageLabel: ImageLabel) {
            if (imageLabel.icon) {
               return iconTextRenderer(["icon", "label"], imageLabel);
            }
            return textRenderer(["label"], imageLabel);
         };

         return [
            {
               displayName: this.i18nService.getString("NetworkUi", "portbinding.list.portgroup.label"),
               field: "portgroupName.label",
               width: "180px",
               template: function (item: IscsiPortInfoItem) {
                  return renderImageLabel(item.portgroupName);
               }
            },
            {
               displayName: this.i18nService.getString("NetworkUi", "portbinding.list.vMKernel.label"),
               field: "vmKernelAdapter.label",
               width: "180px",
               template: function (item: IscsiPortInfoItem) {
                  return renderImageLabel(item.vmKernelAdapter);
               }
            },
            {
               displayName: this.i18nService.getString("NetworkUi", "portbinding.list.physicalNetworkAdapter.label"),
               field: "physicalNetworkAdapterInfo.label",
               width: "180px",
               template: function (item: IscsiPortInfoItem) {
                  return renderImageLabel(item.physicalNetworkAdapterInfo);
               }
            }
         ];
      }

      private initGridData(): void {
         this.datagridOptions.data = this.candidatePortGroupsData.items;
      }
   }

   angular.module("com.vmware.vsphere.client.network")
         .component("iscsiAddPortBindingCandidatesList", new IscsiAddPortBindingCandidatesList());
}
