(function() {
   'use strict';
   angular.module('com.vmware.vsphere.client.cluster')
         .controller('ConfigIssuesViewController', ConfigIssuesViewController);

   ConfigIssuesViewController.$inject = ['$scope', 'dataService', 'i18nService',
      'vuiConstants', 'columnRenderersRegistry', 'haConfigIssuesService', 'defaultUriSchemeUtil'];

   function ConfigIssuesViewController($scope, dataService, i18nService, vuiConstants,
         columnRenderersRegistry, haConfigIssuesService, defaultUriSchemeUtil) {

      var self = this;
      var objectId = $scope._route ? $scope._route.objectId : null;
      self.watchForObjects = [objectId];
      self.liveRefreshProperties = ["configIssue", "configStatus", "name"];

      self.datagridOptions = getGridOptions();
      self.getViewData = getViewData;
      getViewData();

      function getViewData() {
         dataService.getProperties(objectId, ['haConfigIssues'])
               .then(function(data) {
                  if (data && data.haConfigIssues) {
                     _.each(data.haConfigIssues, function(issueConfig) {
                        issueConfig.hostRoleFormatted =
                              haConfigIssuesService.getHostRoleFormatted(
                                    issueConfig.hostRole);
                        issueConfig.messageIcon =
                              haConfigIssuesService.getIssueMessageIcon(issueConfig);
                     });
                     self.datagridOptions.data = data.haConfigIssues;
                  }
               });
      }

      function getGridOptions() {
         return {
            selectionMode: vuiConstants.grid.selectionMode.MULTI,
            showCheckboxesOnMultiSelection: false,
            searchable: false,
            resizable: true,
            selectedItems: [],
            data: [],
            columnDefs: getColumnDefs(),
            height: '100%'
         };
      }

      function getColumnDefs() {
         return [
            {
               displayName: i18nService.getString('ClusterUi',
                     'ha.config.issues.grid.entity.header'),
               field: 'entityName',
               type: 'string',
               width: '35%',
               template: entityIconTextRenderer
            },
            {
               displayName: i18nService.getString('ClusterUi',
                     'ha.config.issues.grid.role.header'),
               field: 'hostRoleFormatted',
               type: 'string',
               width: '10%',
               template: '#:hostRoleFormatted#'
            },
            {
               displayName: i18nService.getString('ClusterUi',
                     'ha.config.issues.grid.issue.description.header'),
               field: 'issue.fullFormattedMessage',
               type: 'string',
               width: '55%',
               template: issueMessageIconTextTitleRenderer
            }
         ];
      }

      function entityIconTextRenderer(data) {
         var renderer = columnRenderersRegistry.getColumnRenderer('object-link');
         if (data){
            _.extend(
                  data,
                  {
                     entityId: defaultUriSchemeUtil.getVsphereObjectId(
                        {
                           _type: 'com.vmware.vim.binding.vmodl.ManagedObjectReference',
                           value: data.entity.value,
                           type: data.entity.type,
                           serverGuid: data.entity.serverGuid
                        }
                  )
               }
            );
         }
         var itemHtml = renderer(['entityId', 'entityName', data['entityIcon']], data || {});

         return itemHtml;
      }

      function issueMessageIconTextTitleRenderer(data) {
         var icon = data && data.messageIcon ? data.messageIcon : '';
         var text = data && data.issue.fullFormattedMessage ?
               data.issue.fullFormattedMessage : '';

         var iconHtmlElement = '<i class=\"' + icon + '\"></i>';
         return '<span title=\"' + text + '\">' + iconHtmlElement + text + '</span>';
      }

   }
})();
