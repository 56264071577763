module ds_cluster_ui {

   class SelectVmsToOverrideController {

      public static $inject = ["$scope", "i18nService"];
      private config: any;

      constructor(private $scope: any, private i18nService: any) {

         let dsClusterId: string = $scope.manager.getTargetUid();
         let selectedVms: string[] =  $scope.manager.getSelectedVms();

         let selectorConfig: any = {
            objectType: "VirtualMachine",
            listViewId: "vsphere.core.vm.members.list"
         };

         let filter: string = "dsClusterVmOverridesExceptSecondaryVmsAndOverridedVmsFilter";

         this.config = {
            contextObject: dsClusterId,
            multipleSelect: true,
            listTabConfig: {
               listConfig: [{
                  label: i18nService.getString("Common", "typeResource.vmPlural"),
                  listViewId: selectorConfig.listViewId,
                  dataModels: [selectorConfig.objectType],
                  filterId: filter
               }]
            },
            selectedObjects: _.map(selectedVms, (vm: any) => {
               return vm.id;
            }),
            onSelectionChanged: function (newSelectedItems: any) {
               $scope.manager.setSelectedVms(newSelectedItems);
            }
         };
      }
   }

   angular.module("com.vmware.vsphere.client.dsCluster")
         .controller("SelectVmsToOverrideController", SelectVmsToOverrideController);
}

