namespace h5_vm {
   class CloneVappToLibraryServiceFactory {
      public static $inject = [
         "cloneVappBasicInformationPageService",
         "cloneVmLocationPageService",
         "cloneVmLibraryItemPageService",
         "VmProvisioningComputeResourcePageService",
         "vmProvisioningDatastorePageService",
         "cloneVmReviewPageService"
      ];

      constructor(cloneVappBasicInformationPageService: any,
            cloneVmLocationPageService: any,
            cloneVmLibraryItemPageService: any,
            VmProvisioningComputeResourcePageService: any,
            vmProvisioningDatastorePageService: any,
            cloneVmReviewPageService: any) {
         return () => {
            return new CloneVappToLibraryService(
                  cloneVappBasicInformationPageService,
                  cloneVmLocationPageService,
                  cloneVmLibraryItemPageService,
                  VmProvisioningComputeResourcePageService,
                  vmProvisioningDatastorePageService,
                  cloneVmReviewPageService);
         };
      }
   }

   export class CloneVappToLibraryService {

      public static $inject = [
            "cloneVappBasicInformationController",
            "cloneVmLocationPageService",
            "cloneVmLibraryItemPageService",
            "VmProvisioningComputeResourcePageService",
            "vmProvisioningDatastorePageService",
            "cloneVmReviewPageService"
      ];

      constructor(
            private cloneVappBasicInformationController: any,
            private cloneVmLocationPageService: any,
            private cloneVmLibraryItemPageService: any,
            private VmProvisioningComputeResourcePageService: any,
            private vmProvisioningDatastorePageService: any,
            private cloneVmReviewPageService: any) { }

      buildPages(wizardScope: any, defaultTargetId: string,
                 preselectedComputeResourceId: string, preselectedStorageId: string): { pages: Array<any> } {

         let basicInformationPage: any = this.cloneVappBasicInformationController
               .build(wizardScope, defaultTargetId);
         let locationPage: any = this.cloneVmLocationPageService
               .build(wizardScope, defaultTargetId);
         let libraryItemPage: any = this.cloneVmLibraryItemPageService
             .build(wizardScope, defaultTargetId);
         let computeResourcePage: any = this.VmProvisioningComputeResourcePageService
             .build(wizardScope, preselectedComputeResourceId);
         let storagePage: any = this.vmProvisioningDatastorePageService
               .build(wizardScope, preselectedStorageId);
         let reviewPage: any = this.cloneVmReviewPageService
               .build(wizardScope, defaultTargetId);


         return {
            pages: [
                basicInformationPage,
                locationPage,
                libraryItemPage,
                computeResourcePage,
                storagePage,
                reviewPage
            ]
         };
      }
   }

   angular.module("com.vmware.vsphere.client.vm")
         .service("cloneVappToLibraryService",
               CloneVappToLibraryServiceFactory);
}
