/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace dvs_ui {

   import DvsPortMirroringSpec =
         com.vmware.vsphere.client.h5.network.dvs.portmirroring.model.DvsPortMirroringSpec;
   import DvsPortMirroringSessionData =
         com.vmware.vsphere.client.h5.network.dvs.portmirroring.model.DvsPortMirroringSessionData;
   import IPromise = angular.IPromise;

   export class DvsPortMirroringDetailsViewService {

      public static readonly $inject = ["dataService",
                                       "i18nService",
                                       "vxPropertyViewService"];

      public static readonly DATA_PROPERTY: string =
            "dvswitchportmirroring:portMirroringSessionDetails";

      private i18n: any;

      constructor(private dataService: any,
                  private i18nService: any,
                  private propertyViewService: any) {

         this.i18n = this.i18nService.getString;

      }

      public getDvsPortMirrorSessionDetails(dvsUrn: string,
            sessionKey: string): IPromise<DvsPortMirroringSessionData> {
         let dvsPortMirroringSpec: DvsPortMirroringSpec = {
            _type: "com.vmware.vsphere.client.h5.network.dvs.portmirroring.model.DvsPortMirroringSpec",
            sessionKey: sessionKey
         };

         let params: any = {
            propertyParams: [{
               propertyName: DvsPortMirroringDetailsViewService.DATA_PROPERTY,
               parameterType: "com.vmware.vsphere.client.h5.network.dvs.portmirroring.model.DvsPortMirroringSpec",
               parameter: dvsPortMirroringSpec
            }]
         };

         return this.dataService.getProperties(
               dvsUrn, [DvsPortMirroringDetailsViewService.DATA_PROPERTY], params)
               .then((response: any) => {
                  return response[DvsPortMirroringDetailsViewService.DATA_PROPERTY];
               });
      }

      public buildDvsPortMirrorSessionPropertiesView(
            dvsPortMirrorSessionData: DvsPortMirroringSessionData): any {
         if (dvsPortMirrorSessionData) {
            let builder = this.propertyViewService.createPropertyViewBuilder();
            this.buildDvsPortMirrorSessionPropertiesCategory(
                  builder, dvsPortMirrorSessionData);

            return builder.build();
         }
      }

      private buildDvsPortMirrorSessionPropertiesCategory(
            builder: any, dvsPortMirrorSessionData: DvsPortMirroringSessionData) {
         // Add "Properties" tab
         let propertiesCategory: any = builder.category("properties");

         propertiesCategory.section("general").property(this.i18n(
                     "DvsUi", "SpanSessionDetailsView.name"),
               dvsPortMirrorSessionData.name);

         propertiesCategory.section("general").property(this.i18n(
                     "DvsUi", "SpanSessionDetailsView.type"),
               dvsPortMirrorSessionData.sessionType);

         if (dvsPortMirrorSessionData.encapsulationType) {

            propertiesCategory.section("general").property(this.i18n(
                        "DvsUi", "SpanSessionDetailsView.encapsulationType"),
                  dvsPortMirrorSessionData.encapsulationType);

         }

         if (dvsPortMirrorSessionData.sessionId !== -1) {

            propertiesCategory.section("general").property(this.i18n(
                        "DvsUi", "SpanSessionDetailsView.erspanId"),
                  dvsPortMirrorSessionData.sessionId);

         }

         if (dvsPortMirrorSessionData.tcpIpStack) {
            propertiesCategory.section("general").property(this.i18n(
                  "DvsUi", "SpanSessionDetailsView.tcpIpStack"),
                  dvsPortMirrorSessionData.tcpIpStack);
         }

         propertiesCategory.section("general").property(this.i18n(
                     "DvsUi", "SpanSessionDetailsView.status"),
               dvsPortMirrorSessionData.enabled);

         if (dvsPortMirrorSessionData.normalTrafficOnDestPorts) {

            propertiesCategory.section("general").property(this.i18n(
                        "DvsUi", "SpanSessionDetailsView.normalTrafficAllowed"),
                  dvsPortMirrorSessionData.normalTrafficOnDestPorts);

         }

         if (dvsPortMirrorSessionData.encapsulationVlanId) {

            propertiesCategory.section("general").property(this.i18n(
                        "DvsUi", "SpanSessionDetailsView.encapsulationVlanId"),
                  dvsPortMirrorSessionData.encapsulationVlanId);

         }

         if (dvsPortMirrorSessionData.preserveOriginalVlan) {

            propertiesCategory.section("general").property(this.i18n(
                        "DvsUi", "SpanSessionDetailsView.preserveOriginalVlan"),
                  dvsPortMirrorSessionData.preserveOriginalVlan);

         }

         propertiesCategory.section("general").property(this.i18n(
                     "DvsUi", "SpanSessionDetailsView.mirroredPacketLength"),
               dvsPortMirrorSessionData.mirroredPacketLength);

         if (dvsPortMirrorSessionData.samplingRate) {

            propertiesCategory.section("general").property(this.i18n(
                        "DvsUi", "SpanSessionDetailsView.samplingRate"),
                  dvsPortMirrorSessionData.samplingRate);

         }

         if (dvsPortMirrorSessionData.description) {

            propertiesCategory.section("general").property(this.i18n(
                        "DvsUi", "SpanSessionDetailsView.description"),
                  dvsPortMirrorSessionData.description);

         }

      }

   }

   angular.module("com.vmware.vsphere.client.dvs")
         .service("dvsPortMirroringDetailsViewService",
            DvsPortMirroringDetailsViewService);
}
