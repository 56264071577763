module ds_cluster_ui {

   import IController = angular.IController;

   class ScheduleSdrsSdrsSettingsPageController implements IController {

      private loading: boolean;
      private objectId: string;
      private formData: any;
      public onTabsClick: Function;

      public static $inject = ["$scope", "dataService", "vcService", "$q", "$timeout"];

      constructor(private $scope: any,
                  private dataService: any,
                  private vcService: any,
                  private $q: any,
                  private $timeout: any) {
         this.objectId = $scope.modalOptions.dialogData.objectId;
         this.$scope.modalOptions.dialogData.manager.initManager({}, this.$scope.modalOptions);
         this.$scope.modalOptions.isAlertClosed = true;
         $scope.enableButtonAutofocus = true;

         this.onTabsClick = () => {
            if($scope.enableButtonAutofocus) {
               $scope.enableButtonAutofocus = false;
            }
         };

         this.getViewData();
      }

      public getSelectedPageUrl(): string {
         let selectedPage: any = _.find(this.$scope.pages.categories, (page: any) => {
            return page.id === this.$scope.selectedCategoryId;
         });

         return selectedPage ? selectedPage.contentUrl : "";

      }

      private getViewData(): void {
         this.setPageBusy(true);

         this.$scope.pages = {
            categories: this.$scope.modalOptions.dialogData.manager.getPageDefinitions()
         };

         this.$scope.selectedCategoryId = this.$scope.pages.categories[0].id;
         this.$scope.selectCategory = (categoryId: any) => {
            this.$scope.selectedCategoryId = categoryId;
         };

         this.dataService.getData(this.$scope.modalOptions.dialogData.objectId,
               "com.vmware.vsphere.client.dsclusterui.model.DsClusterEditSdrsData")
               .then((data: any) => {
                  _.extend(this.$scope.modalOptions.dialogData.manager.sdrsData, data);
                  this.formData = this.$scope.modalOptions.dialogData.manager.getSheduleModel();
                  this.setPageBusy(false);
                  this.addWatchers();

                  this.$timeout(() => {
                     $("#dsClusterEditSdrsEnabledCheckbox").focus();
                  }, 1);
               });
      }

      private setPageBusy(busy: boolean): void {
         this.loading = busy;
         this.$scope.modalOptions.submitDisabled = busy;

      }

      private addWatchers(): void {
         this.$scope.$watch('ctrl.formData', () => {
            this.$scope.manager.setFormData(
                  this.$scope.modalOptions.dialogData.manager.getFormData());
         }, true);
      }
   }

   angular.module("com.vmware.vsphere.client.dsCluster")
         .controller("ScheduleSdrsSdrsSettingsPageController", ScheduleSdrsSdrsSettingsPageController);
}
