(function() {
   'use strict';
   angular.module('com.vmware.vsphere.client.cluster')
         .controller('AddGroupMembersSelectorDialog',
               AddGroupMembersSelectorDialog);

   AddGroupMembersSelectorDialog.$inject = ['$scope', 'clusterGroupsConstants',
         'i18nService', 'managedEntityConstants', 'vuiConstants'];

   function AddGroupMembersSelectorDialog($scope, clusterGroupsConstants,
         i18nService, managedEntityConstants, vuiConstants) {
      var self = this;

      self.managedEntityConstants = managedEntityConstants;
      self.clusterGroupsConstants = clusterGroupsConstants;

      var dialogData = $scope.modalOptions.dialogData;
      dialogData.selectedItems = [];

      $scope.modalOptions.onSubmit = function() {
         if (dialogData.selectedItems.length === 0) {
            $scope.modalOptions.alerts = [{
               text: i18nService.getString('CommonUi', 'objectSelectorDialog.noSelection'),
               type: vuiConstants.validationBanner.type.ERROR
            }];
            $scope.modalOptions.isAlertClosed = false;
            return false;
         }

         return $scope.modalOptions.dialogData.onSubmit();
      };

      var clusterId = dialogData.objectId;
      var objects = dialogData.objects;
      var objectType = dialogData.objectType;
      var groupMembers = getSelectedItemsIds(objects);
      var selectorConfig = getGroupTypeSelectorConfiguration(objectType);
      var filter = getObjectsFilter(objectType, groupMembers);

      if (selectorConfig) {
         this.config = {
            contextObject: clusterId,
            multipleSelect: true,
            listTabConfig: {
               listConfig: [{
                  label: selectorConfig.label,
                  listViewId: selectorConfig.listViewId,
                  dataModels: [selectorConfig.objectType],
                  filterId: filter,
                  filterParams: groupMembers
               }]
            },
            onSelectionChanged: function(newSelectedItems) {
               dialogData.selectedItems = newSelectedItems;
            }
         };
      }

      function getGroupTypeSelectorConfiguration(groupType) {
         if (groupType === clusterGroupsConstants.groupType.VM_GROUP) {
            return {
               label: i18nService.getString('Common', 'typeResource.vmPlural'),
               objectType: self.managedEntityConstants.VIRTUAL_MACHINE,
               listViewId: "vsphere.core.vm.list"
            };
         } else if (groupType === clusterGroupsConstants.groupType.HOST_GROUP) {
            return {
               label: i18nService.getString('Common', 'typeResource.hostPlural'),
               objectType: self.managedEntityConstants.HOST,
               listViewId: "vsphere.core.host.list"
            };
         }

         return null;
      }

      function getObjectsFilter(objectType, existingMembers) {
         if (objectType === self.clusterGroupsConstants.groupType.VM_GROUP) {
            if (existingMembers.length === 0) {
               return 'clusterVmsExceptSecondaryVmsFilter';
            } else {
               return 'clusterVmsExceptSecondaryVmsExcludeVmsFilter';
            }
         } else if (objectType === self.clusterGroupsConstants.groupType.HOST_GROUP) {
            if (existingMembers.length === 0) {
               return 'clusterObjectsFilter';
            } else {
               return 'clusterObjectsExcludeObjectsFilter';
            }
         }
      }

      //Make a clean array of group members, because the currently selected group
      //is taken from the datagrid selected row items and the datagrid adds extra unnecessary
      //properties to the row data which break the object selector.
      function buildCleanGroupMembers(members) {
         return members.map(function(memberRef) {
            return memberRef;
         });
      }

      function getSelectedItemsIds(items) {
         return items.map(function(item) {
            return item.id;
         });
      }
   }
})();
