namespace h5_vm {
   import AdapterMapping = com.vmware.vim.binding.vim.vm.customization.AdapterMapping;
   import UnknownIpGenerator = com.vmware.vim.binding.vim.vm.customization.UnknownIpGenerator;
   import UnknownIpV6Generator = com.vmware.vim.binding.vim.vm.customization.UnknownIpV6Generator;
   import FixedName = com.vmware.vim.binding.vim.vm.customization.FixedName;
   import FixedIp = com.vmware.vim.binding.vim.vm.customization.FixedIp;
   import FixedIpV6 = com.vmware.vim.binding.vim.vm.customization.FixedIpV6;
   import IPSettings$IpV6AddressSpec = com.vmware.vim.binding.vim.vm.customization.IPSettings$IpV6AddressSpec;
   import Sysprep = com.vmware.vim.binding.vim.vm.customization.Sysprep;
   import LinuxPrep = com.vmware.vim.binding.vim.vm.customization.LinuxPrep;
   import IdentitySettings = com.vmware.vim.binding.vim.vm.customization.IdentitySettings;

   export class IpGenAdapterMapping {
      adapterMapping: AdapterMapping;
      alerts: number;
      expanded: boolean;
      errors: any;

      defaultGateway: string;
      alternateGateway: string;
      lastGateway: string;

      isIpv4Unknown: boolean;

      isIpv6Unknown: boolean;
      ipv6GatewayInvalidMessage: string;
      ipv6Gateways: string[];
      ipv6GridGatewaysOptions: any;
   }

   export class CustomizeGosUserSettingsPageController {
      private readonly UNKNOWN_IP_GEN_TYPE = new UnknownIpGenerator()._type;
      private readonly UNKNOWN_IPV6_GEN_TYPE = new UnknownIpV6Generator()._type;
      private readonly GOS_TYPE_WINDOWS = "Windows";

      static $inject = ["i18nService",
         "$q",
         "gosComputerNameValidationService",
         "ipParserService",
         "$timeout",
         "$element"];

      i18n: Function;
      model: CustomizeGosUserSettingsPageModel;

      mainTitle: string;
      computerNameLabel: string;
      computerNameLengthWarning: string;
      nameGenerator: FixedName;
      nameMaxLength: number;
      nameError?: string;

      nicSettingsMapWrapper: IpGenAdapterMapping[];
      ipv4AddressLabel: string;
      ipv4SubnetMaskLabel: string;

      ipv6AddressLabel: string;
      ipv6PrefixLabel: string;

      constructor(private i18nService: any,
            private $q: any,
            private computerNameValidationService: GosComputerNameValidationService,
            private ipParserService: any,
            private $timeout: any,
            private $element: any) {
         this.i18n = i18nService.getString;
      }

      $onInit(): void {
         this.model.onPageCommit = this.onPageCommit.bind(this);
         this.mainTitle = this.i18n("VmUi",
               "CustomizationParametersPageBase.nic.mainTitle");

         if (this.model.hasUnknownNameGen) {
            this.initComputerNameModel();
            this.initComputerNameLabels();
         }
         this.initNicLabels();
         this.initNicModels();

         this.$timeout(() => {
            const firstInput = this.$element[0].querySelector("input,[role='button']");
            if (firstInput) {
               firstInput.focus();
            }
         }, 0);
      }

      $onDestroy(): void {
         this.model.onPageCommit = undefined;
      }

      private onPageCommit(): angular.IPromise<string[]> {
         this.validateComputerName();
         this.validateAllNics();

         const result: string[] = (this.isComputerNameValid() && this.areAllNicsValid())
               ? []
               : [this.i18n("VmUi", "CustomizationParametersPageBase.validation_error")];
         if (result.length === 0) {
            this.applyAdapterChanges();
         }
         return this.$q.resolve(result);
      }

      expandedChanged(expanded: boolean, nic: IpGenAdapterMapping) {
         nic.expanded = expanded;
         nic.alerts = 0;
         if (nic.expanded) {
            return;
         }
         _.each(nic.errors, function (error) {
            if (error) {
               nic.alerts += 1;
            }
         });
      }

      shouldExpand(index: number): boolean {
         return index === 0 &&
               this.nicSettingsMapWrapper &&
               this.nicSettingsMapWrapper.length === 1;
      }

      private applyAdapterChanges(): void {
         if (!this.nicSettingsMapWrapper) {
            return;
         }

         for (let nic of this.nicSettingsMapWrapper) {
            nic.adapterMapping.adapter.gateway = [];
            if (nic.defaultGateway) {
               nic.adapterMapping.adapter.gateway.push(nic.defaultGateway);
            }

            if (nic.alternateGateway) {
               nic.adapterMapping.adapter.gateway.push(nic.alternateGateway);
            }
            if (nic.isIpv6Unknown) {
               nic.adapterMapping.adapter.ipV6Spec.gateway = nic.ipv6Gateways;
            }
         }
      }

      private initComputerNameModel(): void {
         let identity: IdentitySettings = this.model.specification.identity;

         if (this.model.vmGuestOsType === this.GOS_TYPE_WINDOWS) {
            this.nameGenerator = (identity as Sysprep).userData.computerName =
                  new FixedName();
            this.nameMaxLength = GosComputerNameValidationService.MAX_CHARS_WIN;
         } else {
            this.nameGenerator = (identity as LinuxPrep).hostName =
                  new FixedName();
            this.nameMaxLength = GosComputerNameValidationService.MAX_CHARS_LIN;
         }

         this.nameGenerator.name = "";
      }

      private initComputerNameLabels(): void {
         this.computerNameLabel = this.i18n("VmUi",
               "CustomizationParametersPageBase.name.label");
         this.computerNameLengthWarning = this.i18n("VmUi",
               "CustomizationParametersPageBase.name.warning", this.nameMaxLength);
      }

      private initNicLabels(): void {
         this.ipv4AddressLabel = this.i18n("VmUi",
               "CustomizationParametersPageBase.nic.ipv4Address");
         this.ipv4SubnetMaskLabel = this.i18n("VmUi",
               "CustomizationParametersPageBase.nic.ipv4SubnetMask");

         this.ipv6AddressLabel = this.i18n("VmUi",
               "CustomizationParametersPageBase.nic.ipv6Address");
         this.ipv6PrefixLabel = this.i18n("VmUi",
               "CustomizationParametersPageBase.nic.ipv6PrefixInput");
      }

      private initNicModels(): void {
         this.nicSettingsMapWrapper = [];
         if (!this.model.specification.nicSettingMap) {
            return;
         }

         for (let i = 0; i < this.model.specification.nicSettingMap.length; i++) {
            let adapterMapping = this.model.specification.nicSettingMap[i];
            let ipGenAdapterMapping = new IpGenAdapterMapping();
            ipGenAdapterMapping.adapterMapping = adapterMapping;
            ipGenAdapterMapping.errors = {};
            ipGenAdapterMapping.expanded = false;

            this.nicSettingsMapWrapper.push(ipGenAdapterMapping);

            ipGenAdapterMapping.isIpv4Unknown = this.isIpv4Unknown(adapterMapping);
            ipGenAdapterMapping.errors.isIpv4AddressInvalid = false;
            ipGenAdapterMapping.errors.isIpv4SubnetInvalid = false;
            if (ipGenAdapterMapping.isIpv4Unknown) {
               adapterMapping.adapter.ip = new FixedIp();
               if (adapterMapping.adapter.gateway && adapterMapping.adapter.gateway.length > 0) {
                  ipGenAdapterMapping.defaultGateway = adapterMapping.adapter.gateway[0];
               }

               if (adapterMapping.adapter.gateway && adapterMapping.adapter.gateway.length > 1) {
                  ipGenAdapterMapping.alternateGateway = adapterMapping.adapter.gateway[1];
               }
            }

            ipGenAdapterMapping.isIpv6Unknown = this.isIpv6Unknown(adapterMapping);
            ipGenAdapterMapping.errors.isIpv6AddressInvalid = false;
            ipGenAdapterMapping.errors.isIpv6SubnetInvalid = false;
            if (ipGenAdapterMapping.isIpv6Unknown) {
               ipGenAdapterMapping.ipv6Gateways = [];
               if (adapterMapping.adapter.ipV6Spec.gateway
                     && adapterMapping.adapter.ipV6Spec.gateway.length > 0) {
                  ipGenAdapterMapping.ipv6Gateways = adapterMapping.adapter.ipV6Spec.gateway;
               }

               let newIPv6: FixedIpV6 = new FixedIpV6();
               let ipv6Spec: IPSettings$IpV6AddressSpec = new IPSettings$IpV6AddressSpec();
               ipv6Spec.ip = [newIPv6];

               adapterMapping.adapter.ipV6Spec = ipv6Spec;
            }

            this.buildGridOptions(ipGenAdapterMapping);
         }
      }

      getNicHeaderName(index: number): string {
         return this.i18n("VmUi",
               "CustomizationParametersPageBase.nic.title", index);
      }

      private isIpv4Unknown(mapping: AdapterMapping): boolean {
         return mapping && mapping.adapter &&
               mapping.adapter.ip && mapping.adapter.ip._type === this.UNKNOWN_IP_GEN_TYPE;
      }

      private isIpv6Unknown(mapping: AdapterMapping): boolean {
         return mapping && mapping.adapter && mapping.adapter.ipV6Spec
               && mapping.adapter.ipV6Spec.ip
               && mapping.adapter.ipV6Spec.ip.length > 0
               && mapping.adapter.ipV6Spec.ip[0]._type === this.UNKNOWN_IPV6_GEN_TYPE;
      }

      private isComputerNameValid(): boolean {
         return !this.nameError;
      }

      public validateComputerName(): void {
         let errors: string[] = this.model.hasUnknownNameGen
               ? this.computerNameValidationService.validateComputerName(
                     this.nameMaxLength, this.nameGenerator.name)
               : [];

         this.nameError = _.isEmpty(errors) ? undefined : errors[0];
      }

      private areAllNicsValid(): boolean {
         return !_.some(this.nicSettingsMapWrapper,
               (nic: IpGenAdapterMapping): boolean => {
                  return _.some(nic.errors, (error: boolean) => {
                     return error;
                  });
               });
      }

      private validateAllNics(): void {
         if (!this.nicSettingsMapWrapper) {
            return;
         }
         for (let nic of this.nicSettingsMapWrapper) {
            this.validateNicIpv4Address(nic);
            this.validateNicIpv4Subnet(nic);
            this.validateDefaultGateway(nic);
            this.validateAlternateGateway(nic);
            this.validateNicIpv6Address(nic);
            this.validateNicIpv6Subnet(nic);
            this.expandedChanged(nic.expanded, nic);
         }
      }

      public validateNicIpv4Address(nic: IpGenAdapterMapping): void {
         if (!nic.isIpv4Unknown) {
            return;
         }
         nic.errors.isIpv4AddressInvalid = !this.ipParserService.isIpv4AddressValid(
               (nic.adapterMapping.adapter.ip as FixedIp).ipAddress);
      }

      public validateNicIpv4Subnet(nic: IpGenAdapterMapping): void {
         if (!nic.isIpv4Unknown) {
            return;
         }
         nic.errors.isIpv4SubnetInvalid = !this.ipParserService.isSubnetMaskValid(
               nic.adapterMapping.adapter.subnetMask);
      }

      public validateNicIpv6Address(nic: IpGenAdapterMapping): void {
         if (!nic.isIpv6Unknown) {
            return;
         }
         nic.errors.isIpv6AddressInvalid = !this.ipParserService.isIpv6AddressValid(
               (nic.adapterMapping.adapter.ipV6Spec.ip[0] as FixedIpV6).ipAddress);
      }

      public validateNicIpv6Subnet(nic: IpGenAdapterMapping): void {
         if (!nic.isIpv6Unknown) {
            return;
         }
         nic.errors.isIpv6SubnetInvalid = !this.ipParserService.isSubnetPrefixValid(
               (nic.adapterMapping.adapter.ipV6Spec.ip[0] as FixedIpV6).subnetMask);
      }

      public validateDefaultGateway(nic: IpGenAdapterMapping): void {
         if (!nic.isIpv4Unknown) {
            return;
         }
         nic.errors.defaultGatewayInvalid = !!nic.defaultGateway &&
               !this.ipParserService.isIpv4AddressValid(nic.defaultGateway);

      }

      public validateAlternateGateway(nic: IpGenAdapterMapping): void {
         if (!nic.isIpv4Unknown) {
            return;
         }
         nic.errors.alternateGatewayInvalid = !!nic.alternateGateway &&
               !this.ipParserService.isIpv4AddressValid(nic.alternateGateway);

      }

      public validateGateway(gateway: string, nic: IpGenAdapterMapping): void {
         if (!gateway) {
            nic.ipv6GatewayInvalidMessage = "";
            return;
         }
         let isValid: boolean = this.ipParserService.isIpv6AddressValid(gateway);
         let isDuplicate: boolean = _.any(nic.ipv6Gateways, function (currentGateway) {
            return currentGateway === gateway;
         });

         if (!isValid) {
            nic.ipv6GatewayInvalidMessage =
                  this.i18nService.getString("VmUi", "CustomizationParametersPageBase.nic.error.ipv6.address");
         }

         if (isDuplicate) {
            nic.ipv6GatewayInvalidMessage =
                  this.i18nService.getString("VmUi", "CustomizationParametersPageBase.nic.error.ipv6.duplicate", gateway);
         }

         if (isValid && !isDuplicate) {
            nic.ipv6GatewayInvalidMessage = "";
         }
      }

      public addGateway(nic: IpGenAdapterMapping): void {
         this.validateGateway(nic.lastGateway, nic);
         if (!nic.lastGateway || nic.ipv6GatewayInvalidMessage) {
            return;
         }

         nic.ipv6Gateways.push(nic.lastGateway);
         nic.ipv6GridGatewaysOptions.data = nic.ipv6Gateways;

         nic.lastGateway = "";
      }

      private buildGridOptions(nic: IpGenAdapterMapping): void {
         let gridGatewaysOptions: any = {};
         gridGatewaysOptions.columnDefinitions = [
            {
               displayName: this.i18nService.getString("VmUi", "CustomizationParametersPageBase.nic.ipv6.gateway"),
               type: "string"
            }
         ];
         gridGatewaysOptions.data = nic.ipv6Gateways;
         gridGatewaysOptions.deleteLabel = this.i18nService.getString(
               "VmUi", "CustomizationParametersPageBase.nic.delete.label");
         gridGatewaysOptions.deleteTooltip = this.i18nService.getString(
               "VmUi", "CustomizationParametersPageBase.nic.delete.tooltip");
         gridGatewaysOptions.onDelete = (gateway: string) => {
            this.deleteGatewayFromList(gateway, nic);
         };
         gridGatewaysOptions.placeholder = this.i18nService.getString(
               "VmUi", "CustomizationParametersPageBase.noGateways");
         gridGatewaysOptions.gridLabel = this.i18nService.getString(
               "VmUi", "CustomizationParametersPageBase.ipv6GatewaysDescription");
         nic.ipv6GridGatewaysOptions = gridGatewaysOptions;
      }

      private deleteGatewayFromList(gateway: string, nic: IpGenAdapterMapping) {
         if (!gateway) {
            return;
         }

         let index: number = _.findIndex(nic.ipv6Gateways,
               function (currentGateway) {
                  return currentGateway === gateway;
               });
         if (index < 0) {
            return;
         }

         nic.ipv6Gateways.splice(index, 1);
      }
   }

   export class CustomizeGosUserSettingsPageComponent {
      public controller: any;
      public templateUrl: string;
      public bindings: any;

      constructor() {
         this.controller = CustomizeGosUserSettingsPageController;
         this.templateUrl =
               "vm-ui/resources/vm/customize-guest-os/user-settings/customize-gos-user-settings-page.component.html";
         this.bindings = {
            model: "<"
         };
      }
   }

   angular.module("com.vmware.vsphere.client.vm")
         .component("customizeGosUserSettingsPage", new CustomizeGosUserSettingsPageComponent());
}
