namespace h5_vm {

import VirtualDiskRecord = h5_vm.VirtualDiskRecord;

/**
 * This type contains all the data required for the migration/relocate operation.
 *
 * NOTE speev: This one is a mimic of the MigrationWizardDataModel.as data structure.
 */
export class MigrationWizardDataModel {
   /** Array of vms to be migrated */
   public vms: Array<string> /* vm ids */ = [];

   /** Flag indicating if cross vc migration is possible. */
   public isXvcMigrationPossible: boolean;

   /** Flag indicating if network selection is required. */
   public isNetworkSelectionRequired: boolean;

   /**
    * The vmId -> vmName map holding the names of the vms to be migrated.
    */
   public vmNames: {[propName: string]: string} = {};

   /** Destination resource pool. */
   public pool?: string;

   /**
    * Destination compute resource - cluster (if available),
    * else the host.
    */
   public computeResource?: string;

   /**
    *  Used when migrating to a new compute resource using stretched storage
    *  (same storage, but mounted as different datastore across Datacenters or VCs).
    */
   public stretchedStorage?:  { [vmId: string]: Array<VirtualDiskRecord> };

   /**
    * Id of the datacenter of the selected compute resource
    */
   public datacenter: string;

   /**
    * The destination folder uid chosen from the Select vm floder page
    */
   public destinationFolder?: string;

   /**
    * Flag that indicates whether the wizard is going through the advanced page
    * flow in the storage page.
    */
   public isAdvancedStorageMode: boolean;

   /**
    * TODO speev: This one is temporary. Its should go into each virtualDisksByVm record.
    */
   public diskFormat: any;

   /**
    * Priority with which the VMs should be migrated.
    */
   public priority: string;

   /**
    * An array of network adapter to network matches, containing info about source VMs
    * network migration in xVDS/xVC migration/vMotion scenarios. Populated manualy by
    * the user, in the "Select Network" page.
    *
    * TODO speev: Make this more strongly typed.
    */
   public networkMatches: any;

   /**
    * Dictionary, containing the destination for the virtual disks of all the migrated
    * VMs. The array of elements, belonging to each VM may be obtained by the
    * VM UID.
    *
    * NOTE: The VM configuration file is part of the disks array per VM,
    * but uses a 'fake/dummy/special' key: _configKey. This way you can distinguish it
    * from the other disks.
    */
   public virtualDisksByVm: { [key: string]: Array<VirtualDiskRecord> } = {};

   constructor(vms: Array<string>, pool?: string) {
      this.vms = vms;
      this.pool = pool;
   }
} // class
} // namespace h5_vm
