/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
/*
 * Service which handles the wizard showing and wiring the Add datastore wizard steps.
 */
(function () {
   'use strict';
   angular.module('com.vmware.vsphere.client.storage')
         .service('addDatastoreWizardService', addDatastoreWizardService);

   addDatastoreWizardService.$inject = ['vuiWizardService',
      'i18nService',
      '$rootScope',
      'wizardPageService',
      'datastoreConstants',
      'CommonWizardManager',
      'VmfsWizardManager',
      'NfsWizardManager',
      'vvolWizardManagerFactory',
      'datastoreTypePageService',
      'vmfsNameAndDevicePageService',
      'addDatastoreReadyToCompletePageService',
      'vmfsVersionPageService',
      'vmfsPartitionConfigurationPageService',
      'vmfsMountOptionsPageService',
      'nfsNameAndConfigurationPageService',
      'nfsVersionPageService',
      'nfsSelectHostsPageService',
      'defaultUriSchemeUtil',
      'managedEntityConstants',
      'vvolNameAndContainerSelectionPageService',
      'vvolSelectHostsPageService',
      'nfsKerberosAuthenticationPageService'];

   function addDatastoreWizardService(vuiWizardService,
         i18nService,
         $rootScope,
         wizardPageService,
         datastoreConstants,
         CommonWizardManager,
         VmfsWizardManager,
         NfsWizardManager,
         vvolWizardManagerFactory,
         datastoreTypePageService,
         vmfsNameAndDevicePageService,
         addDatastoreReadyToCompletePageService,
         vmfsVersionPageService,
         vmfsPartitionConfigurationPageService,
         vmfsMountOptionsPageService,
         nfsNameAndConfigurationPageService,
         nfsVersionPageService,
         nfsSelectHostsPageService,
         defaultUriSchemeUtil,
         managedEntityConstants,
         vvolNameAndContainerSelectionPageService,
         vvolSelectHostsPageService,
         nfsKerberosAuthenticationPageService) {

      return {
         showWizard: function (contextObjectId) {
            var wizardScope = $rootScope.$new();

            var flowsId = [
               datastoreConstants.datastoreTypes.VMFS,
               datastoreConstants.datastoreTypes.NFS,
               datastoreConstants.datastoreTypes.NFS3,
               datastoreConstants.datastoreTypes.VVOL
            ];

            var selectDatastoreTypePage = datastoreTypePageService.build(wizardScope, flowsId);
            var readyToCompletePage = addDatastoreReadyToCompletePageService.build(wizardScope);

            function buildVmfsPages() {
               var vmfsNameAndDevicePage = vmfsNameAndDevicePageService.build(wizardScope);
               var vmfsMountOptionsPage = vmfsMountOptionsPageService.build(wizardScope);
               var vmfsPartitionConfigurationPage = vmfsPartitionConfigurationPageService.build(wizardScope);
               var vmfsVersionPage = vmfsVersionPageService.build(wizardScope);
               return [vmfsNameAndDevicePage, vmfsMountOptionsPage, vmfsVersionPage,
                  vmfsPartitionConfigurationPage, readyToCompletePage];
            }

            function buildNfsPages(isHostContextObject, isVersion41) {
               var nfsNameAndConfigurationPage = nfsNameAndConfigurationPageService.build(wizardScope);
               var pages;

               if (isHostContextObject) {
                  pages = [nfsNameAndConfigurationPage, readyToCompletePage];
               }
               else {
                  var nfsSelectHostsPage = nfsSelectHostsPageService.build(wizardScope);
                  pages =[nfsNameAndConfigurationPage, nfsSelectHostsPage, readyToCompletePage];

               }

               if(isVersion41){
                  var nfsVersionPage = nfsVersionPageService.build(wizardScope);
                  var nfsKerberosAuthenticationPage = nfsKerberosAuthenticationPageService.build(wizardScope);
                  pages.unshift(nfsVersionPage);
                  pages.splice(2, 0, nfsKerberosAuthenticationPage);
               }

               return pages;
            }

            function buildVvolPages(isHostContextObject) {
               var vvolNameAndContainerSelectionPage = vvolNameAndContainerSelectionPageService.build(wizardScope);

               if (isHostContextObject) {
                  return [vvolNameAndContainerSelectionPage, readyToCompletePage];
               }
               else {
                  var vvolSelectHostsPage = vvolSelectHostsPageService.build(wizardScope);
                  return [vvolNameAndContainerSelectionPage, vvolSelectHostsPage, readyToCompletePage];
               }
            }

            var isHostContextObject =
               (defaultUriSchemeUtil.getEntityType(contextObjectId) === managedEntityConstants.HOST);

            var flows = [
               {
                  id: datastoreConstants.datastoreTypes.VMFS,
                  pages: buildVmfsPages()
               },
               {
                  id: datastoreConstants.datastoreTypes.NFS,
                  pages: buildNfsPages(isHostContextObject, true)
               },
               {
                  id: datastoreConstants.datastoreTypes.NFS3,
                  pages: buildNfsPages(isHostContextObject, false)
               },
               {
                  id: datastoreConstants.datastoreTypes.VVOL,
                  pages: buildVvolPages(isHostContextObject)
               }
            ];

            var pages = [selectDatastoreTypePage];

            wizardScope.wizardConfig = {
               pages: wizardPageService.initializePageStates(pages),
               flows: wizardPageService.initializeFlowStates(flows)
            };

            wizardScope.wizardConfig.iconClass = 'storage-ui-icon-create-datastore';
            wizardScope.wizardConfig.title =
               i18nService.getString('StorageUi', 'addDatastoreWizard.title');
            wizardScope.wizardConfig.cssClass = 'new-datastore-wizard';

            wizardScope.commonWizardManager =
                  new CommonWizardManager(contextObjectId, wizardScope.wizardConfig);
            wizardScope.vmfsWizardManager =
                  new VmfsWizardManager(wizardScope.commonWizardManager);
            wizardScope.nfsWizardManager =
               new NfsWizardManager(wizardScope.commonWizardManager);
            wizardScope.vvolWizardManager =
               vvolWizardManagerFactory.build(wizardScope.commonWizardManager);

            wizardScope.activeWizardManager = wizardScope.vmfsWizardManager;

            wizardScope.removeLoadingAndShowError = function (error) {
               wizardScope.wizardConfig.loading = false;
               if (error && error.data && error.data.message) {
                  wizardPageService.markPageIncompleteWithError(
                        wizardScope.wizardConfig,
                        error.data.message);
               }
            };

            // show the wizard
            vuiWizardService({
               scope: wizardScope,
               configObjectName: 'wizardConfig'
            }).show();

            wizardScope.commonWizardManager.prepareUniqueDatastoreName();
         }
      };
   }
})();
