/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace dvs_ui {

   import DvsAddHostHierarchicalListVmVnicItem = com.vmware.vsphere.client.h5.network.dvs.addhost.hierarchicalList.DvsAddHostHierarchicalListVmVnicItem;
   import DvsAddHostHierarchicalListVmItem = com.vmware.vsphere.client.h5.network.dvs.addhost.hierarchicalList.DvsAddHostHierarchicalListVmItem;

   export class DvsSelectVirtualMachinesPageValidator {

      public static $inject = ["i18nService",
         "authorizationService",
         "$q",
         "dvsSelectVirtualMachinesPageService",
         "defaultUriSchemeUtil"];

      constructor(private i18nService: any,
            private authorizationService: any,
            private $q: any,
            private dvsSelectVirtualMachinesPageService: DvsSelectVirtualMachinesPageService,
            private defaultUriSchemeUtil: any) {
      }

      /**
       * Validates the supplied pageModel.
       *
       * Returns a promise containing validation errors if such exist.
       */
      public getValidationError(pageModel: DvsSelectVirtualMachinesPageModel): any {
         let deferred: any = this.$q.defer();

         if (!pageModel ||
               !pageModel.isMigrateVirtualMachineNetworkingEnabled ||
               !pageModel.hosts || pageModel.hosts.length === 0) {
            deferred.resolve([]);
            return deferred.promise;
         }

         let vmEditDevicePrivilege: string = "VirtualMachine.Config.EditDevice";
         let vmsToUpdate: string[] = [];
         _.each(pageModel.hosts, (vmVnicListItem: DvsAddHostTreeListItemWrapper) => {
            if (vmVnicListItem && vmVnicListItem.type ===
                  DvsAddHostWizardConstants.nicListItemType.VM_VNIC_TYPE &&
                  this.dvsSelectVirtualMachinesPageService.isVnicModified(vmVnicListItem)) {
               let listItem: DvsAddHostHierarchicalListItem =
                     vmVnicListItem as DvsAddHostHierarchicalListItem;
               let vnicItem: DvsAddHostHierarchicalListVmVnicItem =
                     listItem as DvsAddHostHierarchicalListVmVnicItem;
               vmsToUpdate.push(
                     this.defaultUriSchemeUtil.getVsphereObjectId(vnicItem.vmRef));
            }
         });

         if (!vmsToUpdate || vmsToUpdate.length === 0) {
            deferred.resolve([]);
            return deferred.promise;
         }

         this.authorizationService
               .checkPrivilegesForMultipleObjects(vmsToUpdate, [vmEditDevicePrivilege])
               .then((privilegesById: any) => {
                  let validationMessages: string[] = [];
                  // Collect the VMs that do not have edit privilege granted.
                  Object.keys(privilegesById).forEach((id: string) => {
                     if (privilegesById[id][vmEditDevicePrivilege] === false) {
                        let vmName: string =
                              this.getVmName(id, pageModel.hosts);
                        validationMessages.push(this.i18nService.getString("DvsUi",
                              "SelectVirtualMachinesPage.errorNoPermissionForVm",
                              vmName));
                     }
                  });

                  deferred.resolve(validationMessages);
               });

         return deferred.promise;
      }

      private getVmName(id: string, items: DvsAddHostTreeListItemWrapper[]): string {
         let vmItem: DvsAddHostTreeListItemWrapper =
               _.find(items, (item: DvsAddHostTreeListItemWrapper) => {
                  if (item && item.type ===
                        DvsAddHostWizardConstants.nicListItemType.VM_TYPE) {
                     let listItem: DvsAddHostHierarchicalListItem =
                           item as DvsAddHostHierarchicalListItem;
                     let vmItem: DvsAddHostHierarchicalListVmItem =
                           listItem as DvsAddHostHierarchicalListVmItem;
                     return id === this.defaultUriSchemeUtil.getVsphereObjectId(vmItem.vmRef);
                  }

                  return false;
               });

         if (vmItem) {
            return vmItem.label;
         } else {
            return "";
         }
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .service("dvsSelectVirtualMachinesPageValidator", DvsSelectVirtualMachinesPageValidator);
}
