module ds_cluster_ui {

   /**
    * Opens Edit SDRS dialog as a multi page dialog.
    */
   export class SdrsDialogService {

      public static $inject = ["sdrsDialogManager", "i18nService", "clarityModalService"];

      constructor(private sdrsDialogManager: any, private i18nService: any,
            private clarityModalService: any) { }

      public show(dsClusterId: string): void {
         let sdrsData: any = {};

         let modalOptions: any = {};
         this.sdrsDialogManager.initManager(sdrsData, modalOptions);

         _.extend(modalOptions, {
            title: this.i18nService.getString("DsClusterUi", "sdrs.dialog.edit.title"),
            subTitle: {
               objectId: dsClusterId
            },
            defaultButton: "submit",
            contentTemplate:
                  "ds-cluster-ui/resources/ds-cluster/views/settings/services/editSdrs/editSdrsDialog.html",
            size: "lg",
            onSubmit: () => {
               return this.sdrsDialogManager.submit();
            },
            modalClass: "ds-cluster-edit-sdrs-dialog-modal",
            dialogData: {
               sdrsData: sdrsData,
               manager: this.sdrsDialogManager,
               objectId: dsClusterId
            }
         });

         this.clarityModalService.openOkCancelModal(modalOptions);
      }
   }

   angular.module("com.vmware.vsphere.client.dsCluster")
         .service("sdrsDialogService", SdrsDialogService);
}
