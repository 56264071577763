/* Copyright 2015 VMware, Inc. All rights reserved. -- VMware Confidential */
(function() {
   'use strict';
   angular.module('com.vmware.vsphere.client.host').controller('VMStartupShutdownViewController', VMStartupShutdownViewController);

   VMStartupShutdownViewController.$inject = ['$scope', 'i18nService', 'dataService', 'vmStartupShutdownService'];

   function VMStartupShutdownViewController($scope, i18nService, dataService, vmStartupShutdownService) {
      var self = this;
      self.loader = true;

      var hostId = $scope._route.objectId;
      self.headerOptions = {
         title: i18nService.getString('HostUi', 'vmStartUp.VmStartupAndShutdown'),
         objectId: hostId,
         actions: [{
            actionUid: 'vsphere.core.host.actions.editVmStartupShutdownAction',
            customLabel: i18nService.getString('Common', 'settingsView.defaultEditActionLabel')
         }]
      };
      self.objectsToWatch = [hostId];
      self.liveRefreshProperties = [
         'config.autoStart.powerInfo',
         'config.autoStart.defaults.stopDelay',
         'config.autoStart.defaults.enabled',
         'config.autoStart.defaults.stopAction',
         'config.autoStart.defaults.startDelay',
         'vm'
      ];

      self.datagridOptions = vmStartupShutdownService.createDatagridOptions();
      self.fetchViewData = fetchViewData;
      fetchViewData();

      function fetchViewData() {
         dataService.getData(hostId, "com.vmware.vsphere.client.h5.host.model.HostAutoStartData")
               .then(function(hostAutoStartData) {
                  var hostAutoStartConfig = hostAutoStartData.autoStartConfig;
                  self.datagridOptions.data = vmStartupShutdownService.populateDatagrid(
                        hostAutoStartConfig,
                        hostAutoStartData.vmStartupData
                  );
                  self.loader = true;
               });
      }
   }
})();
