namespace license_ui {

   export interface LicensingRouteObject {
      // the extension id
      extensionId: string;

      // the path of tabs that have to be walked
      tabPaths: string[];

      // the id of the item that have to be preselected
      itemId?: string;

      additionalParams?: any;
   }

   export class LicensingRouteObjectsFactoryService {

      public static $inject = [
            "licensesManagementConstants"
      ];

      constructor(private licensesManagementConstants: LicensesManagementConstants) {
      }

      createProductsListLicensingRouteObject(productId?: string): LicensingRouteObject {
         return {
            extensionId: this.licensesManagementConstants.ADMINISTRATION_LICENSES_VIEWS_EXTENSION_ID,
            tabPaths: [this.licensesManagementConstants.PRODUCTS_ADMINISTRATION_VIEW_TAB_ID],
            itemId: productId
         };
      }

      createLicensesListLicensingRouteObject(licenseId?: string): LicensingRouteObject {
         return {
            extensionId: this.licensesManagementConstants.ADMINISTRATION_LICENSES_VIEWS_EXTENSION_ID,
            tabPaths: [],
            itemId: licenseId
         };
      }

      createAssetsListLicensingRouteObject(assetType?: string, assetId?: string): LicensingRouteObject {
         const licensingRouteObject: LicensingRouteObject = {
            extensionId: this.licensesManagementConstants.ADMINISTRATION_LICENSES_VIEWS_EXTENSION_ID,
            tabPaths: [this.licensesManagementConstants.ASSETS_ADMINISTRATION_VIEW_TAB_ID],
            itemId: assetId
         };

         if (assetType && assetType.length) {
            licensingRouteObject.tabPaths.push(assetType);
         }

         return licensingRouteObject;
      }
   }

   angular.module('com.vmware.vsphere.client.licenseUi').service(
         'licensingRouteObjectsFactoryService',
         LicensingRouteObjectsFactoryService
   );
}
