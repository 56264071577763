(function () {
   'use strict';

   angular
      .module('com.vmware.vsphere.client.vm')
      .controller('VmPowerOnFailuresController', VmPowerOnFailuresController);

   VmPowerOnFailuresController.$inject = [
      '$scope',
      'i18nService',
      'columnRenderersRegistry',
      'defaultUriSchemeUtil',
   ];

   function VmPowerOnFailuresController($scope, i18nService, columnRenderersRegistry, defaultUriSchemeUtil) {
      var self = this;

      var dialogData = $scope.modalOptions.dialogData;

      var objectNameRenderer = columnRenderersRegistry.getColumnRenderer('object-name');
      var wrappedTextRenderer = columnRenderersRegistry.getColumnRenderer('wrapped-text');
      var hostNameRenderer = columnRenderersRegistry.getColumnRenderer('host-name');

      var treeListData = [];

      for (var i = 0; i < dialogData.notAttempted.length; i++) {
         var notAttemptedItem = dialogData.notAttempted[i];

         var vmId = defaultUriSchemeUtil.getVsphereObjectId(notAttemptedItem.vm);

         var parent = {
            id: notAttemptedItem.vm.value,
            parentId: null,
            vmName: _.escape(dialogData.vmsProps[vmId].name),
            vmPrimaryIconId: dialogData.vmsProps[vmId].primaryIconId,
            hostName: i18nService.getString('VmUi', 'PowerOnVmFaultsView.host.notApplicable'),
            hideHostIcon: true,
            description: notAttemptedItem.fault.localizedMessage
         };

         treeListData.push(parent);

         var errors = notAttemptedItem.fault.error;
         if (errors) {
            for (var j = 0; j < errors.length; j++) {
               var child = {
                  id: notAttemptedItem.vm.value + "_child_" + j,
                  parentId: parent.id,
                  vmName: _.escape(dialogData.vmsProps[vmId].name),
                  vmPrimaryIconId: dialogData.vmsProps[vmId].primaryIconId,
                  hostName: _.escape(dialogData.vmsProps[vmId].hostName),
                  hideHostIcon: false,
                  description: errors[j].message
               };
               treeListData.push(child);
            }
         }
      }

      var treeListDataSource = new kendo.data.TreeListDataSource({
         data: treeListData,
         schema: {
            model: {
               id: "id",
               expanded: true
            }
         },
      });

      var treeListColumns = [{
         title: i18nService.getString('VmUi', 'PowerOnVmFaultsList.virtualMachine'),
         field: 'vmName',
         template: function(failureItem) {
            return objectNameRenderer(['', 'vmPrimaryIconId', 'vmName'],
                  failureItem);
         }
      }, {
         title: i18nService.getString('VmUi', 'PowerOnVmFaultsList.host'),
         field: 'hostName',
         template: function(failureItem) {
            return hostNameRenderer(['', 'hostName'], failureItem);
         }
      }, {
         title: i18nService.getString('VmUi', 'PowerOnVmFaultsList.description'),
         field: 'description',
         template: function(failureItem) {
            return wrappedTextRenderer(['description'], failureItem);
         }
      }];

      self.treeListOptions = {
         dataSource: treeListDataSource,
         resizable: true,
         columns: treeListColumns,
         sortable: true,
      };
   }
})();
