namespace license_ui {

   import IController = angular.IController;

   export class LicensesAdministrationViewController implements IController {
      public static $inject = ["i18nService", "$q", "$scope"];

      public detailsViewData: any;
      public masterDetailsViewContext: any;

      constructor(private i18nService: any,
                  private $q: ng.IQService,
                  private $scope: any) {
      }

      public $onInit() {
         this.masterDetailsViewContext = {
            scopeProvider: this.$scope.scopeProvider,
            tabPaths: this.$scope.tabPaths
         };

         this.detailsViewData = {
            tabsPromise: this.$q.when([
               {
                  name: this.i18nService.getString("LicenseLibUi", "management.license.summary.tabLabel"),
                  templateUrl: "license-ui/resources/license/views/management/licenses/serial-key-licenses/details/licenseDetailsSummaryTab.html",
                  uid: "license.summary"
               },
               {
                  name: this.i18nService.getString("LicenseLibUi", "management.license.summary.productFeatures.label"),
                  templateUrl: "license-ui/resources/license/views/management/licenses/serial-key-licenses/details/productsFeatures.html",
                  uid: "license.features",
                  metadata: {
                     relevantFor: "!isEvaluation"
                  }
               },
               {
                  name: this.i18nService.getString("LicenseLibUi", "list.products"),
                  templateUrl: "license-ui/resources/license/views/management/licenses/serial-key-licenses/details/licenseDetailsProductsTab.html",
                  uid: "license.products",
                  metadata: {
                     relevantFor: "!isEvaluation"
                  }
               },
               {
                  name: this.i18nService.getString("LicenseLibUi", "management.navigation.vcs"),
                  templateUrl: "license-ui/resources/license/views/management/licenses/assets/vcAssetsList.html",
                  uid: "license.vcAssets",
                  metadata: {
                     relevantFor: "isAssignableToVcs"
                  }
               },
               {
                  name: this.i18nService.getString("LicenseLibUi", "management.navigation.hosts"),
                  templateUrl: "license-ui/resources/license/views/management/licenses/assets/hostAssetsList.html",
                  uid: "license.hostAssets",
                  metadata: {
                     relevantFor: "isAssignableToHosts"
                  }
               },
               {
                  name: this.i18nService.getString("LicenseLibUi", "management.navigation.clusters"),
                  templateUrl: "license-ui/resources/license/views/management/licenses/assets/clusterAssetsList.html",
                  uid: "license.clusterAssets",
                  metadata: {
                     relevantFor: "isAssignableToClusters"
                  }
               },
               {
                  name: this.i18nService.getString("LicenseLibUi", "management.navigation.solutions"),
                  templateUrl: "license-ui/resources/license/views/management/licenses/assets/solutionAssetsList.html",
                  uid: "license.solutionAssets",
                  metadata: {
                     relevantFor: "isAssignableToSolutions"
                  }
               },
               {
                  name: this.i18nService.getString("LicenseLibUi", "license.tab.myvmware.title"),
                  templateUrl: "license-ui/resources/license/views/management/licenses/serial-key-licenses/details/myVmwareLicenseDetailsTab.html",
                  uid: "myvmwarelicense.summary",
                  metadata: {
                     relevantFor: "isMyVmwareLicense"
                  }
               }
            ])
         };
      }
   }

   angular.module("com.vmware.vsphere.client.licenseUi")
         .controller("LicensesAdministrationViewController", LicensesAdministrationViewController);
}
