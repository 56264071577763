namespace storage_ui {

   import IComponentOptions = angular.IComponentOptions;
   class SelectHostsPageComponentController implements angular.IComponentController {

      static $inject = ["i18nService", "vuiConstants", "columnRenderersRegistry"];
      private datagridOptions: any;
      private isHostValidForSelectionCustom: (item:any)=>boolean;
      private onSelectionChange: Function;
      private preSelectComparator: Function;
      private formatName:(item:any)=>string;
      private hosts: any[];

      constructor(private i18nService: any,
                  private vuiConstants: any,
                  private columnRenderersRegistry: any) {
      }

      $onInit(): void {
         this.initDatagridOptions();
      }

      initDatagridOptions() {
         let self = this;
         this.datagridOptions = {
            resizable: true,
            height: "100%",
            selectionMode: this.vuiConstants.grid.selectionMode.MULTI,
            selectedItems: [],
            pageConfig: {
               displayMode: this.vuiConstants.grid.displayMode.VIRTUAL_SCROLLING
            },
            columnDefs: this.getColumnDefs(),
            sortMode: this.vuiConstants.grid.sortMode.SINGLE,
            data: this.hosts,
            isItemDisabledCallback: (item:any)=>!this.isHostValidForSelection(item),
            columnMenu: {
               sortable: false, // this will hide sort menu items
               messages: {
                  columns: this.i18nService.getString("CommonUi", "listview.showColumnsMenu"),
                  filter: this.i18nService.getString("CommonUi", "listview.filterMenu")
               }
            }
         };
         //each time grid is displayed empty ([]) selection is fired
         //since we use onChange, and it's fired BEFORE preselection comparator callback
         // - we have to introduce this workaround
         let isInitialEmptySelection = true;
         if (this.onSelectionChange) {
            this.datagridOptions["onChange"] = ()=> {
               self.onSelectionChange(this.datagridOptions.selectedItems, isInitialEmptySelection);
               isInitialEmptySelection = false;
            };
         }
      }

      onPreSelect = (item:any)=>{
         return this.preSelectComparator ? this.preSelectComparator(item) : false;
      };

      getColumnDefs() {
         return [
            {
               displayName: this.i18nService.getString("StorageUi", "addDatastoreWizard.selectHostsPage.hostColumnTitle"),
               field: "formattedName",
               width: "150px",
               template: (item: any)=>this.getTemplate("vsphere-icon-host", item.formattedName, item)
            },
            {
               displayName: this.i18nService.getString("StorageUi", "addDatastoreWizard.selectHostsPage.clusterColumnTitle"),
               field: "clusterName",
               width: "150px",
               template: (item: any) => {
                  if (item.clusterName) {
                     return this.renderIconText("vsphere-icon-cluster", item.clusterName, this.isHostValidForSelection(item));
                  }
                  return "";
               }
            }];
      }

      isHostValidForSelection(host: any) {
         let isValid = host.isHostConnected;
         if (this.isHostValidForSelectionCustom) {
            return isValid && this.isHostValidForSelectionCustom(host);
         }
         return isValid;
      };

      getTemplate(icon:string, name:string, item:any) {
         let formattedName: string = name;
         if (this.formatName){
            formattedName = this.formatName(item);
         }
         return this.renderIconText(icon, formattedName, this.isHostValidForSelection(item));
      }

      renderIconText(icon:string, text:string, enabled:boolean) {
         return `<div><span class="${icon}"></span>${this.getDisabledTextRenderer(text, enabled)}</div>`;
      }

      getDisabledTextRenderer(text:string, enabled:boolean) {
         var disabledTextRenderer =
               this.columnRenderersRegistry.getColumnRenderer("disabled-text");
         return disabledTextRenderer(["text", "disabled"], {
            text: text,
            disabled: !enabled
         });
      }

   }
   angular.module("com.vmware.vsphere.client.storage").component(
         "selectHostsPageComponent", <IComponentOptions>{
            controller: SelectHostsPageComponentController,
            templateUrl: "storage-ui/resources/storage/views/wizard/createDatastore/pages/SelectHostsPageComponent.html",
            bindings: {
               isHostValidForSelectionCustom: "<?",
               preSelectComparator: "<?",
               hosts: "<",
               onSelectionChange: "<?",
               formatName: "<?"
            }
         });
}
