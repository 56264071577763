(function() {
   'use strict';
   angular.module('com.vmware.vsphere.client.host').controller('AddHostNameLocationPageController', [
         '$scope',
         function($scope) {
            var self = this;
            if(!$scope.manager.getStateLoadedFlag()) {
               $scope.$watch($scope.manager.getStateLoadedFlag, function(value) {
                  if (value) {
                     initializeView();
                  }
               });
            } else{
               initializeView();
            }

            function initializeView() {
               self.model = $scope.manager.getNameLocationPageModel();
               $scope.$watch(function() {
                  return self.model;
               }, function(model) {
                  $scope.manager.setHostname(model.hostname);
               }, true);
            }
         }
      ]
   );
})();
