/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace dvs_ui {

   import DvsSpanSessionDestinationUplinksData =
         com.vmware.vsphere.client.h5.network.dvs.portmirroring.model.wizard.DvsSpanSessionDestinationUplinksData;

   import IPromise = angular.IPromise;

   export class DvsSpanSessionSelectDestinationUplinksPageComponent {

      public bindings: any;
      public controller: any;
      public templateUrl: string;

      constructor() {
         this.bindings = {
            model: "<",
            dvsUrn: "<"
         };

         this.controller = DvsSpanSessionSelectDestinationUplinksPageComponentController;

         this.templateUrl = "dvs-ui/resources/dvs/portmirroring/common/components/" +
               "dvsSpanSessionSelectDestinationUplinksPageComponentTemplate.html";
      }
   }

   class DvsSpanSessionSelectDestinationUplinksPageComponentController {

      static $inject = [
         "i18nService",
         "vuiConstants",
         "dvsSpanSessionDestinationUplinksService",
         "$scope"
      ];

      public dvsUrn: string;

      public model: DvsSpanSessionDestinationUplinksModel;

      public existingUplinksDatagridOptions: any;

      public selectedUplinksDatagridOptions: any;

      public getString: (key: string, bundle?: string) => string;

      public isAddBtnEnabled: boolean;

      public isRemoveBtnEnabled: boolean;

      public isAddAllBtnEnabled: boolean;

      public isRemoveAllBtnEnabled: boolean;

      private uplinksPromise: IPromise<DvsSpanSessionDestinationUplinksData[]>;

      private _loaded: boolean;

      constructor(private i18nService: any,
                  private vuiConstants: any,
                  private dvsSpanSessionDestinationUplinksService: DvsSpanSessionDestinationUplinksService,
                  private $scope: any) {

         $scope.i18n = this.i18nService.getString;

         this._loaded = false;
      }

      public $onInit():void {
         this.existingUplinksDatagridOptions = {
            height: "100%",
            pageConfig: {
               hidePager: true
            },
            columnDefs: this.getColumnDefinitions(),
            sortMode: this.vuiConstants.grid.sortMode.NONE,
            selectionMode: this.vuiConstants.grid.selectionMode.MULTI,
            showCheckboxesOnMultiSelection: false,
            data: [],
            selectedItems: [],
            resizable: true,
            onChange: this.onPrimaryGridSelectionChange.bind(this)
         };

         this.selectedUplinksDatagridOptions = {
            height: "100%",
            pageConfig: {
               hidePager: true
            },
            columnDefs: this.getSelectedUplinksColumnDefinitions(),
            sortMode: this.vuiConstants.grid.sortMode.NONE,
            selectionMode: this.vuiConstants.grid.selectionMode.MULTI,
            showCheckboxesOnMultiSelection: false,
            data: [],
            selectedItems: [],
            resizable: true,
            onChange: this.onSecondaryGridSelectionChange.bind(this)
         };

         this.getDestinationUplinkNames(this.dvsUrn);
      }

      private getDestinationUplinkNames(dvsUrn:string):void {

         let existingUplinksNotLoaded: boolean = !this.model.notSelectedUplinks ||
               this.model.notSelectedUplinks.length === 0;
         let selectedUplinksNotLoaded: boolean = !this.model.uplinks ||
               this.model.uplinks.length === 0;

         if (existingUplinksNotLoaded && selectedUplinksNotLoaded) {

            // If the page is loaded for the first time and the model is not filled
            // reuqest data and fill the datagridOptions for the existing uplinks
            let uplinksRequestPromise:IPromise<DvsSpanSessionDestinationUplinksData[]> =
                  this.dvsSpanSessionDestinationUplinksService
                        .getDestinationUplinkNames(dvsUrn);

            this.uplinksPromise = uplinksRequestPromise;

            uplinksRequestPromise
                  .then((uplinkNamesReceived:DvsSpanSessionDestinationUplinksData[]) => {
                     if (this.uplinksPromise === uplinksRequestPromise) {
                        this.existingUplinksDatagridOptions.data
                              = uplinkNamesReceived ? uplinkNamesReceived : [];
                        this._loaded = true;

                        this.isAddAllBtnEnabled =
                              this.existingUplinksDatagridOptions.data.length > 0;
                        this.isRemoveAllBtnEnabled =
                              this.selectedUplinksDatagridOptions.data.length > 0;
                     }
                  });
         } else {
            // If the page is loaded for the second time, fill both grids with
            // the data from the model
            this.fillDataForDataGrid(this.model.notSelectedUplinks,
                  this.existingUplinksDatagridOptions);
            this.fillDataForDataGrid(this.model.uplinks,
                  this.selectedUplinksDatagridOptions);

            this.isAddAllBtnEnabled =
                  this.existingUplinksDatagridOptions.data.length > 0;
            this.isRemoveAllBtnEnabled =
                  this.selectedUplinksDatagridOptions.data.length > 0;
         }
      }

      private fillDataForDataGrid(uplinkNames: string[], datagridOptions: any): void {
         let dataArray: any = [];
         for (let i = 0; i < uplinkNames.length; i++) {
            let dataObj:any = {
               uplinkName: uplinkNames[i]
            };
            dataArray.push(dataObj);
         }
         datagridOptions.data = dataArray;
      }

      private getColumnDefinitions():any[] {
         return [
            {
               displayName:
                     this.$scope.i18n("DvsUi", "AddSpanSessionWizard.destinationsPage.UplinksLists.existingUplinksTitle"),
               width: "150px",
               field: "uplinkName",
               sortable: true,
               type: "string"
            }
         ];
      }

      private getSelectedUplinksColumnDefinitions():any[] {
         return [
            {
               displayName:
                     this.$scope.i18n("DvsUi", "AddSpanSessionWizard.destinationsPage.UplinksLists.selectedUplinksTitle"),
               width: "150px",
               field: "uplinkName",
               sortable: true,
               type: "string"
            }
         ];
      }

      private onPrimaryGridSelectionChange(items:any[]):void {
         this.isAddBtnEnabled = items && items.length > 0;
      }

      private onSecondaryGridSelectionChange(items:any[]):void {
         this.isRemoveBtnEnabled = items && items.length > 0;
      }

      private onAddBtnClicked():void {
         if (this.existingUplinksDatagridOptions.selectedItems &&
               this.existingUplinksDatagridOptions.selectedItems.length > 0) {

            let selectedUplinksDatagridData:any =
                  this.selectedUplinksDatagridOptions.data.slice();
            let datagridData:any = this.existingUplinksDatagridOptions.data.slice();

            this.existingUplinksDatagridOptions.selectedItems.forEach((item:any):void => {
               let dataObj:any = {
                  uplinkName: item.uplinkName
               };
               let whereRes:any = _.where(datagridData, dataObj);

               datagridData = _.without(datagridData, whereRes[0]);
               selectedUplinksDatagridData.push(dataObj);
            });

            this.selectedUplinksDatagridOptions.data = selectedUplinksDatagridData;
            this.existingUplinksDatagridOptions.data = datagridData;
            this.isAddAllBtnEnabled =
                  this.existingUplinksDatagridOptions.data.length > 0;
            this.isRemoveAllBtnEnabled =
                  this.selectedUplinksDatagridOptions.data.length > 0;
            this.existingUplinksDatagridOptions.selectedItems = [];
            this.syncListModel();
         }
      }

      private onAddAllBtnClicked():void {
         if (this.existingUplinksDatagridOptions.data &&
               this.existingUplinksDatagridOptions.data.length > 0) {

            let selectedUplinksDatagridData:any =
                  this.selectedUplinksDatagridOptions.data.slice();

            if (!this.existingUplinksDatagridOptions.data.length) {
               this.selectedUplinksDatagridOptions.data
                     = this.existingUplinksDatagridOptions.data;
            } else {
               this.selectedUplinksDatagridOptions.data =
                     _.union(selectedUplinksDatagridData, this.existingUplinksDatagridOptions.data);
            }
            this.existingUplinksDatagridOptions.data = [];

            this.isRemoveAllBtnEnabled = true;
            this.isAddAllBtnEnabled = false;
            this.syncListModel();
         }
      }

      private onRemoveBtnClicked():void {
         if (this.selectedUplinksDatagridOptions.selectedItems &&
               this.selectedUplinksDatagridOptions.selectedItems.length > 0) {

            let datagridData:any = this.existingUplinksDatagridOptions.data.slice();
            let selectedUplinksDatagridData:any =
                  this.selectedUplinksDatagridOptions.data.slice();

            this.selectedUplinksDatagridOptions.selectedItems.forEach(
                  (item:any):void => {
                     let dataObj:any = {
                        uplinkName: item.uplinkName
                     };
                     let whereRes:any =
                           _.where(selectedUplinksDatagridData, dataObj);

                     selectedUplinksDatagridData =
                           _.without(selectedUplinksDatagridData, whereRes[0]);
                     datagridData.push(dataObj);
                  });

            this.selectedUplinksDatagridOptions.data = selectedUplinksDatagridData;
            this.existingUplinksDatagridOptions.data = datagridData;
            this.selectedUplinksDatagridOptions.selectedItems = [];
            this.isRemoveAllBtnEnabled =
                  this.selectedUplinksDatagridOptions.data.length > 0;
            this.isAddAllBtnEnabled =
                  this.existingUplinksDatagridOptions.data.length > 0;
            this.syncListModel();
         }
      }

      private onRemoveAllBtnClicked():void {
         if (this.selectedUplinksDatagridOptions.data &&
               this.selectedUplinksDatagridOptions.data.length > 0) {

            let datagridData:any = this.existingUplinksDatagridOptions.data.slice();

            if (!this.existingUplinksDatagridOptions.data.length) {
               this.existingUplinksDatagridOptions.data =
                     this.selectedUplinksDatagridOptions.data;
            } else {
               this.existingUplinksDatagridOptions.data =
                     _.union(datagridData, this.selectedUplinksDatagridOptions.data);
            }
            this.selectedUplinksDatagridOptions.data = [];

            this.isRemoveAllBtnEnabled = false;
            this.isAddAllBtnEnabled = true;

            this.syncListModel();
         }
      }

      private syncListModel():void {
         this.model.uplinks = [];
         this.model.notSelectedUplinks = [];

         this.selectedUplinksDatagridOptions.data.forEach((item:any) => {
            this.model.uplinks.push(item.uplinkName);
         });

         this.existingUplinksDatagridOptions.data.forEach((item:any) => {
            this.model.notSelectedUplinks.push(item.uplinkName);
         });
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .component("dvsSpanSessionSelectDestinationUplinksPageComponent",
         new DvsSpanSessionSelectDestinationUplinksPageComponent());
}