/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
/*
 * Service for Host Summary page
 */
(function () {
   'use strict';
   angular.module('com.vmware.vsphere.client.host')
      .service('addHostHostSummaryPageService', addHostHostSummaryPageService);

   addHostHostSummaryPageService.$inject = ['i18nService'];

   function addHostHostSummaryPageService(i18nService) {
      return {
         build: function (vuiWizardHelper) {
            return {
               title: i18nService.getString('HostUi', 'addHostWizard.hostSummaryPage.title'),
               description: i18nService.getString('HostUi', 'addHostWizard.hostSummaryPage.description'),
               contentUrl: 'host-ui/resources/host/views/addHostWizard/pages/addHostHostSummaryPage.html',
               onCommit: function () {
                  vuiWizardHelper.markPageComplete();
                  return true;
               }
            };
         }
      };
   }
})();
