/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential  */
namespace h5_ui {

   import IComponentController = ng.IComponentController;

   export class TpmConfigCertController implements IComponentController {

      public masterDetailsViewContext: any;
      public detailsViewData:any;

      public static $inject = ["i18nService", "$q"];

      constructor(private i18nService: any, private $q: any) {

         this.masterDetailsViewContext = {};

         this.detailsViewData = {
            tabsPromise: this.$q.when([
               {
                  name: this.i18nService.getString("VmUi", "VmTpm.certificateInfo"),
                  templateUrl: "vm-ui/resources/vm/views/configure/tpm/tpm-cert-detail-info.html",
                  uid: "certDetailInfo"
               }
            ])
         };
      }
   }

   angular.module("com.vmware.vsphere.client.vm")
         .component("vmTpmCertificates", {
            templateUrl: "vm-ui/resources/vm/views/configure/tpm/tpm-config-cert.component.html",
            controller: TpmConfigCertController
         });
}
