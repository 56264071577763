namespace network_ui {

   export class NetStackEditDnsConfigPageComponent {
      public bindings: any;
      public controller: any;
      public templateUrl: string;

      constructor() {
         this.bindings = {
            pageModel: "<"
         };

         this.controller = NetStackEditDnsConfigPageComponentController;

         this.templateUrl = "network-ui/resources/network/views/host/netstack/" +
               "edit/pages/netStackEditDnsConfigPageComponentTemplate.html";
      }
   }

   class NetStackEditDnsConfigPageComponentController {
      static $inject = [
         "i18nService",
         "vuiConstants",
         "netStackEditDnsConfigPageValidator",
         "vxValidatorFactory"
      ];
      private pageModel: NetStackEditDnsConfigPageModel;

      // Validation functions
      private validateHostName: () => string[];
      private validateDomainName: () => string[];
      private validateSearchDomains: () => string[];
      private validatePreferredDns: () => string[];
      private validateAlternateDns: () => string[];

      private noVnicsValidationMessage: string | null;
      private validator: any;

      constructor(private i18nService: any,
                  private vuiConstants: any,
                  private netStackEditDnsConfigPageValidator: NetStackEditDnsConfigPageValidator,
                  private vxValidatorFactory: any) {
      }

      public $onInit(): void {
         this.validator = this.vxValidatorFactory.create();
         this.updateNoVnicsValidationMessage();
         this.initValidationCallbacks();
         let hasVnics: boolean = this.pageModel.vnicsWithDhcpEnabled
               && this.pageModel.vnicsWithDhcpEnabled.length > 0;
         let isSelectedVnicInTheVnicsList: boolean = _.contains(
               this.pageModel.vnicsWithDhcpEnabled, this.pageModel.selectedVnic);
         if (!this.pageModel.isDhcpEnabled && hasVnics && !isSelectedVnicInTheVnicsList) {
            this.pageModel.selectedVnic = this.pageModel.vnicsWithDhcpEnabled[0];
         }
      }

      private initValidationCallbacks(): void {
         this.validateHostName = (): string[] => {
            let hostNameErrorMessage: string | null =
                  this.netStackEditDnsConfigPageValidator
                        .getHostNameValidationMessage(this.pageModel.hostName);
            return (!this.pageModel.isDhcpEnabled && hostNameErrorMessage) ?
                  [hostNameErrorMessage] : [];
         };

         this.validateDomainName = (): string[] => {
            let domainNameErrorMessage: string | null =
                  this.netStackEditDnsConfigPageValidator
                        .getDomainNameValidationMessage(this.pageModel.domainName);
            return (!this.pageModel.isDhcpEnabled && domainNameErrorMessage) ?
                  [domainNameErrorMessage] : [];
         };

         this.validateSearchDomains = (): string[] => {
            let searchDomainsErrorMessage: string | null =
                  this.netStackEditDnsConfigPageValidator
                        .getSearchDomainsValidationMessage(this.pageModel.searchDomains);
            return (!this.pageModel.isDhcpEnabled && searchDomainsErrorMessage) ?
                  [searchDomainsErrorMessage] : [];
         };

         this.validatePreferredDns = (): string[] => {
            let preferredDnsErrorMessage: string | null =
                  this.netStackEditDnsConfigPageValidator
                        .getPreferredDnsValidationMessage(this.pageModel.preferredDns,
                              this.pageModel.isIpv6Enabled);
            return (!this.pageModel.isDhcpEnabled && preferredDnsErrorMessage) ?
                  [preferredDnsErrorMessage] : [];
         };

         this.validateAlternateDns = (): string[] => {
            let alternateDnsErrorMessage: string | null =
                  this.netStackEditDnsConfigPageValidator
                        .getAlternateDnsValidationMessage(this.pageModel.alternateDns,
                              this.pageModel.isIpv6Enabled);
            return (!this.pageModel.isDhcpEnabled && alternateDnsErrorMessage)
                  ? [alternateDnsErrorMessage] : [];
         };
      }

      private onDhcpChange(): void {
         this.updateNoVnicsValidationMessage();
         this.validator.validate();
      }

      private updateNoVnicsValidationMessage(): void {
         this.noVnicsValidationMessage = this.pageModel.isDhcpEnabled
               ? this.netStackEditDnsConfigPageValidator
                     .getNoVirtualNicsValidationMessage(
                           this.pageModel.vnicsWithDhcpEnabled)
               : null;
      }
   }

   angular.module("com.vmware.vsphere.client.network")
         .component("netStackEditDnsConfigPage", new NetStackEditDnsConfigPageComponent());
}
