namespace storage_ui {

   import VmfsVolume$UnmapBandwidthSpec = com.vmware.vim.binding.vim.host.VmfsVolume$UnmapBandwidthSpec;
   import LongOption = com.vmware.vim.binding.vim.option.LongOption;

   export interface RateItem {
      rate: number;
      label: string;
   }

   export class DatastoreSpaceReclamationBandwidthService {

      public static $inject = ["i18nService"];

      public static readonly FIXED_METHOD: string = "fixed";
      public static readonly DYNAMIC_METHOD: string = "dynamic";

      constructor(private i18nService: any) {
      }

      public validateUnmapBandwidthSpec(spec: VmfsVolume$UnmapBandwidthSpec): string|null {
         if (spec.policy === DatastoreSpaceReclamationBandwidthService.DYNAMIC_METHOD) {
            if (!_.isNumber(spec.dynamicMin)) {
               return this.i18nService.getString("StorageUi",
                     "datastore.manage.general.spaceReclamation.dynamic.invalidMinBandwidth");
            }
            if (!_.isNumber(spec.dynamicMax)) {
               return this.i18nService.getString("StorageUi",
                     "datastore.manage.general.spaceReclamation.dynamic.invalidMaxBandwidth");
            }
            if (spec.dynamicMin > spec.dynamicMax) {
               return this.i18nService.getString("StorageUi",
                     "datastore.manage.general.spaceReclamation.dynamic.minBandwidthBiggerThanMax");
            }
         } else if (spec.policy === DatastoreSpaceReclamationBandwidthService.FIXED_METHOD) {
            if (!_.isNumber(spec.fixedValue)) {
               return this.i18nService.getString("StorageUi",
                     "datastore.manage.general.spaceReclamation.fixed.invalidRate");
            }
         }
         // no error
         return null;
      }

      public setDefaultRatesIfMissing(
            spec: VmfsVolume$UnmapBandwidthSpec,
            vmfsConfigOption: any,
            dynamicReclamationSupported: boolean) {

         spec.fixedValue = this.getRateValue(
               spec.fixedValue, vmfsConfigOption.unmapBandwidthFixedValue);
         if (dynamicReclamationSupported) {
            spec.dynamicMin = this.getRateValue(
                  spec.dynamicMin, vmfsConfigOption.unmapBandwidthDynamicMin);
            spec.dynamicMax = this.getRateValue(
                  spec.dynamicMax, vmfsConfigOption.unmapBandwidthDynamicMax);
         }
      }

      public generateRateItems(longOption: LongOption, increment: number): RateItem[] {
         let result: RateItem[] = [];
         // avoid infinite loops
         increment = increment || 1;
         for (let rate: number = longOption.min; rate <= longOption.max; rate += increment) {
            result.push({
               label: this.i18nService.getString(
                     "StorageUi",
                     "datastore.manage.general.spaceReclamation.rateItemFormat",
                     rate),
               rate: rate
            });
         }

         return result;
      }

      public findRateItem(
            rateItems: RateItem[], rate: number, returnFirstAsDefault: boolean): RateItem {

         let result: RateItem = _.find(rateItems, (rateItem: RateItem) => rateItem.rate === rate);
         if (!result && returnFirstAsDefault && rateItems && rateItems.length) {
            result = rateItems[0];
         }
         return result;
      }

      private getRateValue(value: any, longOption: LongOption): number {
         if (value || value === 0) {
            return value;
         }
         return longOption && longOption.defaultValue;
      }
   }

   angular.module("com.vmware.vsphere.client.storage")
         .service("datastoreSpaceReclamationBandwidthService", DatastoreSpaceReclamationBandwidthService);
}

