(function() {
   'use strict';
   angular.module('com.vmware.vsphere.client.host').controller('EditImageProfileAcceptanceLevelController',
         ['$scope','$rootScope',
            'mutationService',
            'dataService',
            'i18nService',
            'imageProfileEvents',
            EditImageProfileAcceptanceLevelController]);

   function EditImageProfileAcceptanceLevelController($scope, $rootScope,
         mutationService,
         dataService,
         i18nService,
         imageProfileEvents) {

      var objectId = $scope._route.objectId;
      var self = this;

      $scope.modalOptions.onSubmit = onSubmit;

      self.dialogSubtitle = i18nService.getString('HostUi', 'edit.imageProfile.desc');

      self.imageAcceptanceLevelOptions = getOptions();
      self.changeEvent = false;

      getViewData();

      function getViewData() {
         self.loading = true;
         dataService.getProperties(objectId, ['imageAcceptanceLevel'])
               .then(function(result) {
                  self.selectedOption = getOptionObjectByValue(result.imageAcceptanceLevel);
                  self.loading = false;
               });
      }

      $scope.$watch(function() {
         return self.selectedOption;
      }, function(newValue, oldValue) {
         if (newValue !== oldValue) {
            self.changeEvent = true;
         }
      }, true);

      function onSubmit() {
         if (!self.changeEvent) {
            return true;
         }

         mutationService.apply(objectId,
               "com.vmware.vsphere.client.host.config.ImageProfileAcceptanceLevelSpec",
               {
                  acceptanceLevel: self.selectedOption.value
               },
               i18nService.getString('HostUi', 'acceptanceLevel.operation')).then(function(operationResult) {
            if (!operationResult.error) {
               $rootScope.$emit(imageProfileEvents.UPDATE_HOST_IMAGE_PROFILE_ACCEPTANCE_LEVEL);
            }
         });

         return true;
      }

      function getOptions() {
         return [{
            label: i18nService.getString('HostUi', 'acceptanceLevel.vmware_certified'),
            value: 'vmware_certified'
         }, {
            label: i18nService.getString('HostUi', 'acceptanceLevel.vmware_accepted'),
            value: 'vmware_accepted'
         }, {
            label: i18nService.getString('HostUi', 'acceptanceLevel.partner'),
            value: 'partner'
         }, {
            label: i18nService.getString('HostUi', 'acceptanceLevel.community'),
            value: 'community'
         }];
      }

      function getOptionObjectByValue(value) {
         var options = getOptions();

         var result = _.filter(options, function(option) {
            return option.value === value;
         });

         return result ? result[0] : null;
      }
   }
})();
