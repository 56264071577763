module hostprofile_ui {

import IQService = angular.IQService;
import ReadHostCustomizationsSpec = com.vmware.vsphere.client.hostprofile.data.h5.ReadHostCustomizationsSpec;
import ManagedObjectReference = com.vmware.vim.binding.vmodl.ManagedObjectReference;
   class EditPasswordController implements angular.IController {

      public static $inject = [
         "$scope",
         "i18nService"];

      constructor(private $scope: any, private i18nService: any) {
      };

      public updateValue(value: any): void {
         this.$scope.modalOptions.dialogData.matching = !value.isVisible;
         this.$scope.modalOptions.dialogData.value = value.passwordValue;
      }
   }

   angular.module("com.vmware.vsphere.client.hostprofile")
         .controller("EditPasswordController", EditPasswordController);
}

