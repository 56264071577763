namespace h5_vm {
   import VmHardwareBaseService = h5_vm.VmHardwareBaseService;
   import IQService = angular.IQService;
   import VmConfigContext = com.vmware.vsphere.client.vm.config.VmConfigContext;
   import GuestOsDescriptor = com.vmware.vim.binding.vim.vm.GuestOsDescriptor;

   export interface HardwareData {
      originalConfig: any;
      selectedStorageProfile: any;
      selectedReplicationGroupInfo: any;
      storageProfiles: any;
      vmConfigContext: VmConfigContext;
      guestOsFamilyKey: any;
      filterNetworksObjId: string;
      virtualMachineDevices: any;
      networkProviderId: any;
      availableNetworks: any;
      vmStorageProfileAssignments?: any;
   }

   export class VmHardwareCloneService extends VmHardwareBaseService {
      static $inject: string[] = [
         "vmFactoryService",
         "vmHardwareUtil",
         "dataService",
         "vmNetworkService",
         "defaultUriSchemeUtil",
         "storageProfileService",
         "$q",
         "vmDeviceInfoService",
         "VirtualMachineDevices",
         "managedEntityConstants",
         "deviceTypeConstants"
      ];

      private readonly CONFIG_PROPERTY = "config";
      private readonly STORAGE_PROFILES_PROPERTY = "vmStorageProfileAssignments";
      private readonly CONFIG_RESULT = "configResult";
      private readonly STORAGE_PROFILES_RESULT = "spResult";
      private readonly PARENT_PRIVILEGES_RESULT = "parentPrivilegesResult";

      constructor(private vmFactoryService: any,
            vmHardwareUtil: any,
            dataService: any,
            vmNetworkService: any,
            defaultUriSchemeUtil: any,
            storageProfileService: any,
            $q: IQService,
            vmDeviceInfoService: any,
            VirtualMachineDevices: any,
            private managedEntityConstants: any,
            private deviceTypeConstants: any) {
         super(vmHardwareUtil,
               dataService,
               vmNetworkService,
               defaultUriSchemeUtil,
               storageProfileService,
               $q,
               vmDeviceInfoService,
               VirtualMachineDevices);
      }

      public getAndFormatData(vmParams: any, wizardViewData?: any): ng.IPromise<HardwareData> {
         let dataPromises = this.getPromises(vmParams);

         return dataPromises.then((dataResults: any) => {
            const hardwareDataProperties = _.extend({},
                  dataResults[this.CONFIG_RESULT],
                  dataResults[this.STORAGE_PROFILES_RESULT]);
            // set the vmVersion, so that the config environment can be retrieved for it
            // in the next call
            vmParams.setVmVersion(hardwareDataProperties.config.version);
            // retrieve the environment
            return vmParams.getVmConfigEnvironment().then((poolProperties: any) => {
               let result: HardwareData = this.buildHardwareData(
                     vmParams, wizardViewData, poolProperties, hardwareDataProperties,
                     dataResults[this.PARENT_PRIVILEGES_RESULT]);

               if (vmParams.getRecreateVm() || !vmParams.getVmConfigSpec()) {
                  result.virtualMachineDevices = this.buildVirtualDevices(result);
                  this.vmFactoryService.buildCloneVM(vmParams, result.vmConfigContext,
                        result.virtualMachineDevices, result.originalConfig, result.vmStorageProfileAssignments);
                  vmParams.setRecreateVm(false);

                  // Still need to assign resourcePool after fetching environment,
                  // see CustomizeHardwareProvisioningPageMediator#onEnvironmentLoaded in Flex
                  result.vmConfigContext.environment.configTarget.resourcePool =
                        vmParams.getResourcePool().runtime;
               } else {
                  // The storage on some other disk than the vmx can be changed on the storage page,
                  // so here we recreate the storage info according to vmParams.getStorageDiskList
                  let virtualDisks: any[] = result.virtualMachineDevices.existingDevicesOfType(
                              this.deviceTypeConstants.VIRTUALDISK);
                  this.vmFactoryService.applyStorageToVirtualDisks(virtualDisks,
                        result.vmStorageProfileAssignments, vmParams);
               }

               let vmConfigSpecConfig: any = vmParams.getVmConfigSpec();
               result.vmConfigContext.config = vmConfigSpecConfig;

               result.virtualMachineDevices = vmParams.getVirtualMachineDevices();

               // return hardwareConfigData;
               return this.getVmNetworkInfoForClone(vmParams).then((networkInfoData: any) => {
                  result.networkProviderId = networkInfoData.networkProviderId;
                  result.availableNetworks = networkInfoData.availableNetworks;
                  return result;
               });
            });
         });
      }

      private getVmNetworkInfoForClone(vmParams: any): ng.IPromise<any> {
         let networkProviderId: string = vmParams.getComputeResourceId();
         let vmNetworkDevices: any[] =
               vmParams.getVirtualMachineDevices()
                     .existingDevicesOfType(VmHardwareBaseService.VIRTUAL_ETHERNET_CARD);
         return super.getVmNetworkInfo(networkProviderId, vmNetworkDevices);
      }

      private getPromises(vmParams: any): IPromise<{[propertyId: string]: any}> {
         const vmConfigPromise = this.dataService.getProperties(
               vmParams.getVmId(), [this.CONFIG_PROPERTY]);
         const vmStorageProfileAssignmentsPromise = this.dataService
               .getProperties(vmParams.getVmId(),
                     [this.STORAGE_PROFILES_PROPERTY],
                     // Suppress error notification as it is likely to fail if SPS is down
                     { skipErrorInterceptor: true })
               .catch(() => { return { "vmStorageProfileAssignments": undefined }; });

         let promises: {[propertyId: string]: IPromise<any>} = {};
         promises[this.CONFIG_RESULT] = vmConfigPromise;
         promises[this.STORAGE_PROFILES_RESULT] = vmStorageProfileAssignmentsPromise;
         promises[this.PARENT_PRIVILEGES_RESULT] = vmParams.getPrivileges();

         return this.$q.all(promises);
      }

      /**
       * Builds an object containing the needed hardware data used for building the
       * `Customize hardware page`
       */
      private buildHardwareData(vmParams: any,
            wizardViewData: any,
            poolProperties: any,
            hardwareDataProperties: any, privileges: string[]): HardwareData {

         let vmConfigEnvironment: any = poolProperties.vmConfigEnvironmentPerPool;
         let availableNetworks: any = this.getAvailableNetworks(vmConfigEnvironment);
         let hardwareConfig: any =
               this.createConfigInfoObject(hardwareDataProperties[this.CONFIG_PROPERTY]);

         let vmConfigContext: VmConfigContext = new VmConfigContext();
         vmConfigContext.environment = vmConfigEnvironment;
         vmConfigContext.config = angular.copy(hardwareConfig);
         vmConfigContext.privileges = privileges;

         let guestOsDescriptor: GuestOsDescriptor =
               _.find(vmConfigEnvironment.configOption.guestOSDescriptor,
                     (descriptor: GuestOsDescriptor): boolean => {
                        return descriptor.id === vmParams.getGuestOsId();
                     });

         let result: HardwareData = <HardwareData>{
            originalConfig: hardwareConfig,
            selectedStorageProfile: vmParams.getSelectedStorageProfile(),
            selectedReplicationGroupInfo: vmParams.getSelectedReplicationGroupInfo(),
            vmConfigContext: vmConfigContext,
            guestOsFamilyKey: guestOsDescriptor ? guestOsDescriptor.family : null,
            filterNetworksObjId: vmParams.getFilterNetworksObjectId(),
            virtualMachineDevices: vmParams.getVirtualMachineDevices(),
            networkProviderId: null,
            availableNetworks: availableNetworks,
            storageProfiles: wizardViewData.getStorageProfiles()
         };

         if (hardwareDataProperties[this.STORAGE_PROFILES_PROPERTY] !== null) {
            result.vmStorageProfileAssignments =
                  hardwareDataProperties[this.STORAGE_PROFILES_PROPERTY];
         }

         return result;
      }

      /**
       * Returns a config info object and adds the correct type to it,
       * since it is missing when returned from the backend,
       * but is needed when the object is sent
       * @param hardwareDataProperties
       * @returns {any}
       */
      private createConfigInfoObject(hardwareDataProperties: any): any {
         return angular.extend(
               {_type: "com.vmware.vim.binding.vim.vm.ConfigInfo"},
               hardwareDataProperties);
      }

      private getAvailableNetworks(vmConfigEnvironment: any): any {
         let configTargets: any = vmConfigEnvironment.configTarget;
         let availableNetworks: any =
               this.vmDeviceInfoService.buildAvailableNetworkList(configTargets);
         return availableNetworks;
      }
   }


   angular.module("com.vmware.vsphere.client.vm")
         .service("vmHardwareCloneService", VmHardwareCloneService);
}
