module cluster_ui {

   import IController = angular.IController;

   class ConfigVmOverrideDialogController implements IController {

      public static $inject = ["$scope", "i18nService", "vuiConstants",
         "clusterVmOverridesPageService", "clusterVmOverridesConstants",
         "defaultUriSchemeUtil"];

      public selectedVmsSpec: any;
      public vmSettingsSpec: any;
      private objectId: string;

      constructor(private $scope: any,
                  private i18nService: any, private vuiConstants: any,
                  private clusterVmOverridesPageService: ClusterVmOverridesPageService,
                  private clusterVmOverridesConstants: any,
                  private defaultUriSchemeUtil: any) {

         this.objectId = $scope.modalOptions.dialogData.objectId;

         let editedOverrides: any = $scope.modalOptions.dialogData.selectedOverrides;
         let mode: string = clusterVmOverridesConstants.operationType.EDIT;

         this.selectedVmsSpec = {
            objectId: this.objectId,
            vms: []
         };

         this.selectedVmsSpec.vms = editedOverrides;

         this.vmSettingsSpec = {
            objectId: this.objectId,
            mode: mode
         };

         $scope.modalOptions.onSubmit = () => this.onCommit();
      }

      private onCommit(): boolean {
         if (this.validate()) {
            let vms: any[] = _.map(this.selectedVmsSpec.vms, (vmRow: any) => {
               let vmKey: any = angular.extend({}, vmRow.vm.provider, {
                  _type: "com.vmware.vim.binding.vmodl.ManagedObjectReference"
               });
               return {
                  skipFromOrchestrationSpec: vmRow.skipFromOrchestrationSpec,
                  arrayOperation: vmRow.arrayOperation,
                  key: vmKey,
                  haOverrides: vmRow.haOverrides,
                  drsOverrides: vmRow.drsOverrides
               };
            });

            this.clusterVmOverridesPageService.applyOverrides(this.objectId,
                  vms, this.vmSettingsSpec);
            return true;
         }

         return false;
      }

      private validate(): boolean {
         this.$scope.modalOptions.alerts = [];
         this.$scope.modalOptions.isAlertClosed = true;

         if (!this.clusterVmOverridesPageService.hasOverrides(this.vmSettingsSpec) &&
               this.vmSettingsSpec.mode !== this.clusterVmOverridesConstants.operationType.EDIT) {
            this.$scope.modalOptions.alerts = [{
               text: this.i18nService.getString("ClusterUi",
                     "vm.overrides.config.noChangesValidationError"),
               type: this.vuiConstants.validationBanner.type.ERROR
            }];
            this.$scope.modalOptions.isAlertClosed = false;
            return false;
         }

         return true;
      }
   }

   angular.module("com.vmware.vsphere.client.cluster")
         .controller("ConfigVmOverrideDialogController", ConfigVmOverrideDialogController);
}

