/* Copyright 2015 VMware, Inc. All rights reserved. -- VMware Confidential */
/*
 * This js file has to be loaded first
 */
h5 = h5 || {};
h5.storage = {
   angularModule: angular.module('com.vmware.vsphere.client.storage', [
      'com.vmware.platform.ui', 'com.vmware.vsphere.client.networkLibUi', 'ngCookies'])
};

/*
 * Registers datastore-list specific column renderers.
 */
angular.module('com.vmware.vsphere.client.storage')
.run(['columnRenderersRegistry', 'i18nService', 'datastoreConstants',
function(columnRenderersRegistry, i18nService, datastoreConstants) {
   columnRenderersRegistry.registerColumnRenderers({
      /**
       * Localizes datastore Type column (specificType property) and takes care if it
       * is null.
       * @param  {array} props Array of property names, where 1st property is
       *    specificType. Other properties are ignored
       * @param  {object} data  Map of values, contains the specificType.
       */
      'datastore-type': function(props, data) {
         var displayText;
         var dsType = data[props[0]];
         if (!dsType) {
            displayText = '';
         } else {
            var dsTypeLower = dsType.toLowerCase();
            switch (dsTypeLower) {
               case datastoreConstants.datastoreSpecificType.nfs3.toLowerCase() :
                  displayText = i18nService.getString("StorageUi", "datastore.specificType.nfs3");
                  break;
               case datastoreConstants.datastoreSpecificType.nfs41.toLowerCase() :
                  displayText = i18nService.getString("StorageUi", "datastore.specificType.nfs41");
                  break;
               case datastoreConstants.datastoreSpecificType.vsan.toLowerCase() :
                  displayText = i18nService.getString("StorageUi", "datastore.specificType.vsan");
                  break;
               case datastoreConstants.datastoreSpecificType.vvol.toLowerCase() :
                  displayText = i18nService.getString("StorageUi", "datastore.specificType.vvol");
                  break;
               case datastoreConstants.datastoreSpecificType.vmfs3.toLowerCase() :
                  displayText = i18nService.getString("StorageUi", "datastore.specificType.vmfs3");
                  break;
               case datastoreConstants.datastoreSpecificType.vmfs5.toLowerCase() :
                  displayText = i18nService.getString("StorageUi", "datastore.specificType.vmfs5");
                  break;
               case datastoreConstants.datastoreSpecificType.vmfs6.toLowerCase() :
                  displayText = i18nService.getString("StorageUi", "datastore.specificType.vmfs6");
                  break;
               default :
                  displayText = dsType;
               }
         }

         return '<div title="' + displayText + '">' + displayText + '</div>';
      },
      /**
       * Datastore cluster name column - icon-link renderer.
       *
       * @param  {array} props Array of property names, where 1st property is
       *    policy name. Other properties are ignored.
       * @param  {object} data  Map of values, contains the specificType.
       * @param {object} config A dictionary (key/value) to control the renderer behavior.
       */
      'ds-cluster-name': function(props, data, config) {
         var objLinkRenderer = columnRenderersRegistry.getColumnRenderer('object-link');
         return objLinkRenderer(
               [props[0], props[1], 'vsphere-icon-datastore-cluster'], data, config);
      },
      /**
       * Localizes datastore VM Storage Policy column.
       *
       * @param  {array} props Array of property names, where 1st property is
       *    policy name. Other properties are ignored.
       * @param  {object} data  Map of values, contains the specificType.
       */
      'storage-policy-name': function(props, data) {
         var sotragePolicyName = data[props[0]];
         if (!sotragePolicyName) {
            sotragePolicyName = i18nService.getString(
                  'StorageUi', 'datastore.policy.notAvailable');
         }

         return '<div title="' + sotragePolicyName + '">' + sotragePolicyName + '</div>';
      }
   });
}])
.run(['mutationService', 'i18nService', 'clarityModalService','vxValidatorFactory', 'renameFileService', 'deleteFilesService', 'storageValidationService',
   function ( mutationService, i18nService, clarityModalService, vxValidatorFactory, renameFileService, deleteFilesService, storageValidationService) {
      /*
       * Action creating a new folder in a datastore browser.
       */
      h5.actions["vsphere.core.datastore.actions.createFolder"] =

         function(actionEval, availableTargets, context) {
            if (!availableTargets || !availableTargets.length || !context && !context.currentPath) {
               return;
            }

            var datastoreTargetName = context.currentPath.slice(1, context.currentPath.length - 1);

            var name = {
               value: i18nService.getString('StorageUi','datastore.fileManager.defaultFolderName')
            };
            var validator = vxValidatorFactory.create();
            var modalOptions = {
               contentTemplate: 'storage-ui/resources/storage/views/manage/files/CreateNewFolderView.html',
               title: i18nService.getString('StorageUi', 'actionCreateFolderDescription'),
               subTitle: {
                  text: datastoreTargetName
               },
               defaultButton:'submit',
               dialogData: {
                  name: name,
                  prompt: i18nService.getString('StorageUi', 'datastore.fileManager.folderNamePrompt'),
                  validator: validator,
                  isValidFolderName: storageValidationService.validateDatastoreBrowserItemName
                        .bind(storageValidationService)
               },
               alerts: [],
               onSubmit: function() {
                  var errors = validator.validate();
                  if (errors && errors.length) {
                     return false;
                  }

                  var folderName =  name.value.trim();
                  // Root folder names for datastores having supportTopLevelDirectoryCreate capability
                  // should start with datastore name, which is the current path.
                  var folderPath = (context.isRootFolder && !context.topLevelDirectoryCreateSupported)
                     ? folderName
                     : context.currentPath + '/' + folderName;

                 mutationService.apply(
                     availableTargets[0],
                     "com.vmware.vsphere.client.h5.storage.spec.DatastoreCreateFolderSpec",
                     {
                        datacenter: context.datastoreDatacenter,
                        isRootFolder: context.isRootFolder,
                        supportTopLevelDirectoryCreate: context.topLevelDirectoryCreateSupported,
                        folderName: folderPath
                     }).then(function (result) {
                        if (context.callback) {
                           context.callback(result);
                        }
                  });
                  return true;
               }
            };

            clarityModalService.openOkCancelModal(modalOptions);
         };

      /*
       * Action for deleting files or folders on a datastore
       */
      h5.actions["vsphere.core.datastore.actions.deleteFile"] =
         function (actionEval, availableTargets, context) {
            if (!availableTargets || !availableTargets.length || !context) {
               return;
            }

            deleteFilesService.openDeleteFilesDialog(availableTargets[0], context.targetFileNames,
                  context.spec, context.callback);
         };

      h5.actions["vsphere.core.datastore.actions.renameFile"] =
            function (actionEval, availableTargets, context) {
               if (!availableTargets || !availableTargets.length || !context) {
                  return;
               }

               renameFileService.openRenameFileDialog(availableTargets[0], context.spec, context.callback);
            };
   }])
.run(['fileDownloadService', 'folderDownloadService', 'streamVmdkService', '$q',
      function(fileDownloadService, folderDownloadService, streamVmdkService, $q) {

      h5.actions["vsphere.core.datastore.actions.downloadFromDatastore"] =
            function (actionEval, availableTargets, datastoreBrowserContext) {
               if (!availableTargets || !availableTargets.length || !datastoreBrowserContext) {
                  return;
               }

               downloadFiles(datastoreBrowserContext);
            };

      function downloadFiles(datastoreBrowserContext) {

         var datastoreId = datastoreBrowserContext.datastoreId;

         var filesToDownloadPromise;
         var isFolderDownload = false;
         if (datastoreBrowserContext.selectedFiles &&
               datastoreBrowserContext.selectedFiles.length === 1 &&
               datastoreBrowserContext.selectedFiles[0].type === 'folder') {
            filesToDownloadPromise = folderDownloadService.getFolderChildren(
                  datastoreId, datastoreBrowserContext.selectedFiles[0]);
            isFolderDownload = true;
         } else if (datastoreBrowserContext.isStreamFormat
               && datastoreBrowserContext.selectedFiles
               && datastoreBrowserContext.selectedFiles.length === 1
               && datastoreBrowserContext.selectedFiles[0].diskExtents) {
            // the selected file is vSAN VMDK
            filesToDownloadPromise = streamVmdkService
                  .openStreamVmdkWarningDialog(datastoreBrowserContext.selectedFiles);
         } else {
            filesToDownloadPromise = $q.when(datastoreBrowserContext.selectedFiles);
         }

         filesToDownloadPromise.then( function(filesToDownload) {
               var datastoreDcRef = datastoreBrowserContext.datastoreDcRef;
               var pathsToFilesToDownload = [];
               var zipFileName = "";
               var isStreamFormat = false;

               _.forEach(filesToDownload, function (selectedFile) {

                  isStreamFormat = isStreamFormat
                        || (selectedFile.diskExtents && datastoreBrowserContext.isStreamFormat);
                  // Selected file and paths to VMDK extents if available
                  pathsToFilesToDownload.push(selectedFile.path);
                  if (selectedFile.diskExtents && !isStreamFormat) {
                     // Slice casts the diskExtents to Array
                     pathsToFilesToDownload =
                           pathsToFilesToDownload.concat(selectedFile.diskExtents.slice());
                  }

                  zipFileName = datastoreBrowserContext.zipFileName || (selectedFile.name + ".zip");
               });

               if (pathsToFilesToDownload && pathsToFilesToDownload.length > 0) {
                  if (pathsToFilesToDownload.length === 1 && !isFolderDownload) {
                     // If there is only one file to download start download.
                     fileDownloadService.startFilesDownload(datastoreDcRef, datastoreId,
                           pathsToFilesToDownload, isStreamFormat);
                  } else {
                     // If there is more than one file to download
                     // download them as single zip file.
                     fileDownloadService.startFilesDownloadAsZip(
                           datastoreDcRef, datastoreId, pathsToFilesToDownload, zipFileName,
                           isFolderDownload ? datastoreBrowserContext.selectedFiles[0].path : undefined, isStreamFormat);

                  }
               }

            }
         );
      }
   }])
.run(['fileCopyService', function(fileCopyService) {
   h5.actions['vsphere.core.datastore.actions.copyFile'] =
      function (actionEval, availableTargets, context) {
         if (!availableTargets || !availableTargets.length || !context) {
            return;
         }

         var type = "com.vmware.vsphere.client.storage.DatastoreFileCopySpec";

         fileCopyService.copyFiles(availableTargets[0], context.datastoreDcRef, context.selectedFiles, type, context.callback);
      };
   }])
.run(['fileCopyService', function(fileCopyService) {
      h5.actions['vsphere.core.datastore.actions.moveFile'] =
         function (actionEval, availableTargets, context) {
            if (!availableTargets || !availableTargets.length || !context) {
               return;
            }

            var type = "com.vmware.vsphere.client.storage.DatastoreFileMoveSpec";

            fileCopyService.copyFiles(availableTargets[0], context.datastoreDcRef, context.selectedFiles, type, context.callback);
         };
   }])
.run(['fileUploadService',
   function (fileUploadService) {

      h5.actions['vsphere.core.datastore.actions.uploadToDatastore'] =
            function (actionEval, availableTargets, datastoreBrowserContext) {
               if (!availableTargets || !availableTargets.length || !datastoreBrowserContext) {
                  return;
               }

               fileUploadService.startFilesUpload(
                     datastoreBrowserContext.datastoreDcRef,
                     datastoreBrowserContext.datastoreId,
                     datastoreBrowserContext.path,
                     datastoreBrowserContext.files,
                     datastoreBrowserContext.isDatastoreSelected,
                     datastoreBrowserContext.isStreamFormat
               );
            };
   }])
.run(['mutationService', function(mutationService) {

   h5.actions["vsphere.core.datastore.inflateDiskAction"] =
      function(actionEval, availableTargets, context) {
         if (availableTargets && availableTargets.length === 1 && context && context.spec) {
            mutationService.apply(
               availableTargets[0],
               'com.vmware.vsphere.client.storage.InflateDiskSpec',
               context.spec).then(function (result) {
               if (context.callback) {
                  context.callback(result);
               }
            });
         }
      };

}])
.run(['$window', function($window) {
   h5.actions["vsphere.core.datastore.actions.popupDsBrowser"] =
      function(actionEval, availableTargets, context) {
         if (availableTargets && availableTargets.length === 1) {

            var hashQueryString = $.param({
               "datastoreId": availableTargets[0]
            });

            var urlBuilder = document.createElement("a");
            urlBuilder.href = "datastore-browser";
            urlBuilder.hash = "?" + hashQueryString;

            if (h5.debug) {
               var queryString = $.param({
                  "debug": true
               });
               urlBuilder.search = queryString;
            }

            var url = urlBuilder.href;
            $window.open(url, "_blank",
               "width=980,height=640,toolbar=0,location=0,menubar=0,resizable=1,noopener");
         }
      };
}])
.run(['$rootScope', 'mutationService', 'dataService', 'datastoreConstants',
      'clarityModalService', 'selectVmToRegisterService', 'actionsService',
   function ($rootScope, mutationService, dataService, datastoreConstants,
         clarityModalService, selectVmToRegisterService, actionsService) {

      h5.actions["vsphere.core.datastore.actions.browseFiles"] =
            function (actionEval, availableTargets) {
               if (!availableTargets || !availableTargets.length) {
                  return;
               }

               if ($rootScope && $rootScope._navigateToViewAndObject) {
                  $rootScope._navigateToViewAndObject(
                        'vsphere.core.datastore.manage.filesView',
                        availableTargets[0]);
               }
            };

      h5.actions["vsphere.core.datastore.actions.registerVm"] = function(actionEval, availableTargets) {
         if (!availableTargets || !availableTargets.length) {
            return;
         }

         var datastoreId = availableTargets[0];

         var onVmFileSelected = function(selectedVmFileItem) {
            var vmFileName = selectedVmFileItem.name;
            var vmFileExtension =
                  vmFileName.substring(vmFileName.lastIndexOf('.') + 1);
            var context = {
               vmName: vmFileName.substring(0, vmFileName.lastIndexOf('.')),
               vmFilePath: selectedVmFileItem.path,
               isTemplate: vmFileExtension === 'vmtx'
            };
            var registerVmActionId = actionEval.additionalData.registerVmActionId;
            var registerVmEvalStatus = [];
            registerVmEvalStatus[datastoreId] = {
               available: true
            };

            var registerVmActionEval = {
               action: {
                  uid: registerVmActionId
               },
               invoker: registerVmActionId,
               evaluationStatuses: registerVmEvalStatus,
               available: true
            };
            actionsService.invokeAction(registerVmActionEval, context);
         };
         selectVmToRegisterService.browseFile(datastoreId, onVmFileSelected);
      };

      h5.actions["vsphere.core.datastore.actions.rename"] = function(actionEval, availableTargets) {
         if(!availableTargets || !availableTargets.length) {
            return;
         }

         var dsRef = availableTargets[0];
         dataService.getProperties(dsRef, ['summary.type', 'name']).then(
               function(dsTypeResponse) {

                  var dsType = dsTypeResponse && dsTypeResponse['summary.type']
                        ? dsTypeResponse['summary.type'].toLowerCase()
                        : null;

                  var dsMaxNameLength = datastoreConstants.maxNameLengthForNonNfsDatastore;
                  if(dsType === datastoreConstants.nfsVersions.NFS_3 ||
                        dsType === datastoreConstants.nfsVersions.NFS_4_1) {
                     dsMaxNameLength = datastoreConstants.maxNameLengthForNfsDatastore;
                  }

                  clarityModalService.openModal(
                        actionEval,
                        availableTargets,
                        {
                           objectId: dsRef,
                           maxNameLength: dsMaxNameLength,
                           existingObjectName: dsTypeResponse.name
                        },
                        'common-module-ui/resources/common-module/views/rename/renameObject.html'
                  );
               });
      };

      h5.actions["vsphere.core.datastore.actions.refresh"] =
            function (actionEval, availableTargets) {
               if (!availableTargets || !availableTargets.length) {
                  return;
               }

               mutationService.applyOnMultiEntity(availableTargets,
                     'com.vmware.vsphere.client.storage.DatastoreRefreshSpec', {
                        _type: 'com.vmware.vsphere.client.storage.DatastoreRefreshSpec'
                     });
            };

      h5.actions["vsphere.core.datastore.actions.deleteVmfs"] =
            function (actionEval, availableTargets) {
               if (!availableTargets || !availableTargets.length) {
                  return;
               }

               mutationService.removeOnMultiEntity(availableTargets, 'com.vmware.vsphere.client.storage.DatastoreRemovalSpec', {});
            };

   }])
.run(['$rootScope', 'addDatastoreWizardService', 'increaseDatastoreCapacityWizardService',
   function ($rootScope, addDatastoreWizardService, increaseDatastoreCapacityWizardService) {

      h5.actions['vsphere.core.datastore.actions.add'] =
            function (actionEval, availableTargets) {
               if (!availableTargets || !availableTargets.length) {
                  return;
               }

               addDatastoreWizardService.showWizard(availableTargets[0]);
            };

      h5.actions['vsphere.core.datastore.actions.increaseCapacity'] =
         function ShowDatastoreIncreaseCapacityWizard(actionEval, availableTargets) {
            if (!availableTargets || !availableTargets.length) {
               return;
            }

            increaseDatastoreCapacityWizardService.showWizard(availableTargets[0]);
         };
   }])
.run(['upgradeVmfsDatastoreModalService',
   function (upgradeVmfsDatastoreModalService) {
      h5.actions['vsphere.core.datastore.actions.upgradeVmfsDatastore'] =
            function (actionEval, availableTargets) {
               if (!availableTargets || !availableTargets.length) {
                  return;
               }

               upgradeVmfsDatastoreModalService.openDialog(availableTargets);
            };
   }])
.run(['mutationService',
   function (mutationService) {

      h5.actions['vsphere.core.host.actions.refreshStorageSystem'] =
         function (actionEval, availableTargets, context) {
            if (!availableTargets || !availableTargets.length) {
               return;
            }

            mutationService.apply(
               availableTargets[0],
               'com.vmware.vsphere.client.storage.StorageSystemRefreshSpec', {
                  _type: 'com.vmware.vsphere.client.storage.StorageSystemRefreshSpec'
               }).then(
                  function (result) {
                     if (context && context.callback) {
                        context.callback(result);
                     }
                  }
               );
         };
   }])
.run(['mutationService',
      function (mutationService) {
         h5.actions['vsphere.core.host.actions.rescanHba'] =
            function (actionEval, availableTargets, context) {
               if (!availableTargets || !availableTargets.length) {
                  return;
               }

               mutationService.apply(
                  availableTargets[0],
                  'com.vmware.vsphere.client.storage.adapters.StorageAdapterRescanSpec', {
                     adapterKey: context.adapterKey
                  }).then(
                  function (result) {
                     if (context && context.callback) {
                        context.callback(result);
                     }
                  }
               );
            };
      }])
.run(['addSoftwareAdapterDialogService',
   function (addSoftwareAdapterDialogService) {
      h5.actions['vsphere.core.host.actions.adapters.addSoftwareAdapter'] =
         function (actionEval, availableTargets, context) {
            if (!availableTargets || !availableTargets.length) {
               return;
            }
            addSoftwareAdapterDialogService.show(availableTargets[0], context);
         };
   }])
.run(['AddIscsiTargetDialogService',
   function (AddIscsiTargetDialogService) {

      h5.actions['vsphere.core.host.actions.adapters.addIscsiTarget'] =
         function (actionEval, availableTargets, context) {
            if (!availableTargets || !availableTargets.length) {
               return;
            }

            AddIscsiTargetDialogService.openAddIscsiTargetDialog(
                  context.adapter, availableTargets[0], context.dynamicTarget, context.targets, context);
            };
      }])
.run(['editIscsiAdvancedOptionsService',
   function (editIscsiAdvancedOptionsService) {

      h5.actions['vsphere.core.host.actions.adapters.editAdvancedOptionsForAdapter'] =
         function (actionEval, availableTargets, context) {
            if (!availableTargets || !availableTargets.length) {
               return;
            }

            editIscsiAdvancedOptionsService.showAdvancedOptionsDialogForAdapter(availableTargets[0], context.hba);
         };

      h5.actions['vsphere.core.host.actions.adapters.editAdvancedOptionsForTarget'] =
         function (actionEval, availableTargets, context) {
            if (!availableTargets || !availableTargets.length) {
               return;
            }

            editIscsiAdvancedOptionsService.showAdvancedOptionsDialogForTarget(availableTargets[0], context.hba, context.targetSet);
         };
   }])
.run(['clarityModalService', 'i18nService', 'adapterTargetsService',
   function(clarityModalService, i18nService, adapterTargetsService) {

      h5.actions['vsphere.core.host.actions.adapters.removeIscsiTarget'] =
         function(actionEval, availableTargets, context) {
            if (!availableTargets || !availableTargets.length || !context || !context.adapter) {
               return;
            }

            var dialogProperties = {
               title: i18nService.getString('StorageUi', 'storage.adapters.iscsiTargets.actions.remove.header', context.adapter.device),
               message: i18nService.getString('StorageUi', 'storage.adapters.iscsiTargets.actions.remove.message'),
               submit: function () {
                  adapterTargetsService.removeIscsiAdapterTargets(
                        availableTargets[0],
                        context.dynamicTarget,
                        context.adapter.device,
                        context.targets)
                        .then(function (result) {
                           if (result && result.effect && result.effect.affectedEntites
                                 && result.effect.affectedEntites.length) {
                              // Notify that remove is performed
                              if (context && context.callback) {
                                 context.callback(result, context.adapter);
                              }
                           }
                        });
                  return true;
               }
            };

            clarityModalService.openConfirmationModal(dialogProperties);
         };
   }])
.run(['storageAdapterEditAuthenticationService', 'adapterStatusService',
   'hostStorageConstants', 'swIscsiEditSettingsDialogService',
   function(storageAdapterEditAuthenticationService, adapterStatusService,
         hostStorageConstants, swIscsiEditSettingsDialogService) {

      h5.actions['vsphere.core.host.actions.adapters.editAuthSettingsIscsiTarget'] =
         function(actionEval, availableTargets, context) {
            if (!availableTargets || !availableTargets.length || !context || !context.adapter || !context.target) {
               return;
            }

            storageAdapterEditAuthenticationService.editAuthSettingsIscsiTarget(
               availableTargets[0],
               context.adapter,
               context.target,
               context.parentDynamicTargetAuthProperties,
               context.callback
            );
         };

      h5.actions['vsphere.core.host.actions.adapters.editIscsiAuthenticationSettings'] =
            function (actionEval, availableTargets, context) {
               if (!availableTargets || !availableTargets.length || !context || !context.adapter) {
                  return;
               }
               storageAdapterEditAuthenticationService
                     .openModal(availableTargets[0], context.adapter);
            };

      h5.actions['vsphere.core.host.actions.adapters.editIscsiGeneralSettings'] =
            function (actionEval, availableTargets, context) {
               if (availableTargets.length && context && context.adapter) {
                  swIscsiEditSettingsDialogService.openModal(availableTargets[0], context.adapter);
               }
            };

      h5.actions['vsphere.core.host.actions.adapters.disableSoftwareIscsiAdapter'] =
         function(actionEval, availableTargets, context) {
            if (!availableTargets || !availableTargets.length || !context || !context.adapter) {
               return;
            }
            var opts = {
               hostId: availableTargets[0],
               // iSCSI or FCoE
               isIscsiAdapter: context.adapter.type === hostStorageConstants.hostAdapterTypes.ISCSI,
               deviceName: context.adapter.device,
               onSuccessCallback: context.callback
            };

            adapterStatusService.disable(opts);
         };

      h5.actions['vsphere.core.host.actions.adapters.enableSoftwareIscsiAdapter'] =
            function(actionEval, availableTargets, context) {
               if (!availableTargets || !availableTargets.length || !context || !context.adapter) {
                  return;
               }
               var opts = {
                  hostId: availableTargets[0],
                  // iSCSI or FCoE
                  isIscsiAdapter: context.adapter.type === hostStorageConstants.hostAdapterTypes.ISCSI,
                  deviceName: context.adapter.device
               };
               adapterStatusService.enable(opts).then(function (response) {
                  context.callback(response);
               });
            };
   }])
.run(['defaultUriSchemeUtil', 'rescanStorageDialogService',
   function (defaultUriSchemeUtil, rescanStorageDialogService) {
      h5.actions['vsphere.core.host.actions.rescan'] =
            function (actionEval, availableTargets) {
               var targetObjects = defaultUriSchemeUtil.getManagedObjectReferences(availableTargets);
               if (!targetObjects || !targetObjects.length) {
                  return;
               }

               rescanStorageDialogService.open(targetObjects);
            };
   }
])
.run(['modifyDatastoreDialogService', 'mutationService', 'i18nService', 'defaultUriSchemeUtil', 'affectHeartbeatDatastoreService',
   function (modifyDatastoreDialogService, mutationService, i18nService, defaultUriSchemeUtil, affectHeartbeatDatastoreService) {

      h5.actions['vsphere.core.datastore.actions.mountVmfs'] =
         function (actionEval, availableTargets) {
            if (!availableTargets || !availableTargets.length) {
               return;
            }

            modifyDatastoreDialogService.openModifyDatastoreDialog(
               availableTargets[0],
               i18nService.getString('StorageUi', 'vmfsDatastore.mountDialogTitle'),
               'datastoreOperations:availableHostsForMountVmfs',
               function(selectedHosts) {
                  var hostIds = _.map(selectedHosts, function(hostData) {
                     return defaultUriSchemeUtil.getVsphereObjectId(hostData.hostRef);
                  });
                  mutationService.applyOnMultiEntity(
                     hostIds,
                     'com.vmware.vsphere.client.storage.VmfsDatastoreHostsMountSpec',
                     {
                        datastoreToMount: defaultUriSchemeUtil.getManagedObjectReference(availableTargets[0])
                     },
                     i18nService.getString('StorageUi', 'modifyDatastoreForm.mountDatastore'));
                  return true;
               }
            );
         };

      h5.actions['vsphere.core.datastore.actions.mountNfs'] =
         function (actionEval, availableTargets) {
            if (!availableTargets || !availableTargets.length) {
               return;
            }

            modifyDatastoreDialogService.openModifyDatastoreDialog(
               availableTargets[0],
               i18nService.getString('StorageUi', 'nfsDatastore.mountDialogTitle'),
               'datastoreOperations:availableHostsForMountNfs',
               function(selectedHosts) {
                  var hostIds = _.map(selectedHosts, function(hostData) {
                     return hostData.hostRef;
                  });
                  mutationService.applyOnMultiEntity(
                     [availableTargets[0]],
                     'com.vmware.vsphere.client.h5.storage.spec.MountNfsDatastoreSpec',
                     {
                        hostsToMount: hostIds
                     },
                     i18nService.getString('StorageUi', 'modifyDatastoreForm.mountNfsDatastore'));
                  return true;
               }
            );
         };

      h5.actions['vsphere.core.datastore.actions.unmountVmfs'] =
         function (actionEval, availableTargets) {
            if (!availableTargets || !availableTargets.length) {
               return;
            }

            modifyDatastoreDialogService.openModifyDatastoreDialog(
               availableTargets[0],
               i18nService.getString('StorageUi', 'vmfsDatastore.unmountDialogTitle'),
               'datastoreOperations:availableHostsForUnmount',
               function(selectedHosts) {
                  var hostIds = _.map(selectedHosts, function(hostData) {
                     return defaultUriSchemeUtil.getVsphereObjectId(hostData.hostRef);
                  });
                  var hostRefs = _.map(selectedHosts, function (hostData) {
                     return hostData.hostRef;
                  });

                  return affectHeartbeatDatastoreService
                     .checkForAffectedClusters(availableTargets[0], hostRefs)
                     .then(function(isConfirmed) {
                        if (isConfirmed) {
                           mutationService.applyOnMultiEntity(
                              hostIds,
                              'com.vmware.vsphere.client.storage.VmfsDatastoreUnmountSpec',
                              {
                                 datastoreToUnmount: defaultUriSchemeUtil.getManagedObjectReference(availableTargets[0])
                              },
                              i18nService.getString('StorageUi', 'modifyDatastoreForm.unmountDatastore'));
                           return true;
                        }
                        // Do not close the dialog since since the warning has not been confirmed.
                        return false;
                     });
               },
               'storage-ui/resources/storage/views/datastore/UnmountVmfsDatastoreDialogHeader.html'
            );
         };

      h5.actions['vsphere.core.datastore.actions.unmountNfs'] =
            function (actionEval, availableTargets) {
               if (!availableTargets || !availableTargets.length) {
                  return;
               }

               if (availableTargets.length > 1) {
                  modifyDatastoreDialogService.openRemoveDatastoreDialog(
                     availableTargets,
                     i18nService.getString('StorageUi', 'actionUnmountDatastoreCaption'),
                     i18nService.getString('StorageUi', 'actionUnmountDatastoreWarning'),
                     function() {
                        mutationService.removeOnMultiEntity(
                           availableTargets,
                           "com.vmware.vsphere.client.storage.DatastoreRemovalSpec",
                           {selectedContextObject:defaultUriSchemeUtil.getManagedObjectReference(availableTargets[0])},
                           i18nService.getString('StorageUi', 'modifyDatastoreForm.removeDatastore')
                        );
                        return true;
                     }
                  );
               } else {
                  modifyDatastoreDialogService.openModifyDatastoreDialog(
                     availableTargets[0],
                     i18nService.getString('StorageUi', 'vmfsDatastore.unmountDialogTitle'),
                     'datastoreOperations:availableHostsForUnmountNfs',
                     function (selectedHosts, allHostsSelected) {
                        var hostIds = _.map(selectedHosts, function (hostData) {
                           return defaultUriSchemeUtil.getVsphereObjectId(hostData.hostRef);
                        });
                        var hostRefs = _.map(selectedHosts, function (hostData) {
                           return hostData.hostRef;
                        });

                        return affectHeartbeatDatastoreService
                           .checkForAffectedClusters(availableTargets[0], hostRefs)
                           .then(function(isConfirmed) {
                              if (isConfirmed) {
                                 var mutationParams = [
                                    hostIds,
                                    'com.vmware.vsphere.client.storage.NfsDatastoreRemovalSpec',
                                    {
                                       datastoreToRemove: defaultUriSchemeUtil.getManagedObjectReference(availableTargets[0])
                                    },
                                    i18nService.getString('StorageUi', 'modifyDatastoreForm.unmountNfsDatastore')];

                                 if (allHostsSelected) {
                                    mutationService.removeOnMultiEntity.apply(mutationService, mutationParams);
                                 } else {
                                    mutationService.applyOnMultiEntity.apply(mutationService, mutationParams);
                                 }
                                 return true;
                              }
                              // Do not close the dialog since since the warning has not been confirmed.
                              return false;
                           });
                     },
                     'storage-ui/resources/storage/views/datastore/UnmountVmfsDatastoreDialogHeader.html'
                  );
               }
            };

      h5.actions['vsphere.core.datastore.actions.unmountVvol'] =
         function(actionEval, availableTargets) {
            if (!availableTargets || !availableTargets.length) {
               return;
            }
            modifyDatastoreDialogService.openModifyDatastoreDialog(
               availableTargets[0],
               i18nService.getString('StorageUi', 'vmfsDatastore.unmountDialogTitle'),
               'datastoreOperations:availableHostsForUnmount',
               function(selectedHosts) {
                  var hostIds = _.map(selectedHosts, function(hostData) {
                     return defaultUriSchemeUtil.getVsphereObjectId(hostData.hostRef);
                  });
                  mutationService.removeOnMultiEntity(
                     hostIds,
                     'com.vmware.vsphere.client.storage.vvol.RemoveVvolDatastoreSpec', {
                        datastore: defaultUriSchemeUtil.getManagedObjectReference(availableTargets[0])
                     },
                     i18nService.getString('StorageUi', 'modifyDatastoreForm.unmountVvolDatastore')
                  );
                  return true;
               },
               'storage-ui/resources/storage/views/datastore/UnmountVmfsDatastoreDialogHeader.html'
            );
         };

      h5.actions['vsphere.core.datastore.actions.mountVvol'] =
            function (actionEval, availableTargets) {
               if (!availableTargets || !availableTargets.length) {
                  return;
               }
               modifyDatastoreDialogService.openModifyDatastoreDialog(
                     availableTargets[0],
                     i18nService.getString('StorageUi', 'actionMountVvolDatastore'),
                     'datastoreOperations:availableHostsForMountVvol',
                     function (selectedHosts) {
                        var hostIds = _.map(selectedHosts, function (hostData) {
                           return hostData.hostRef;
                        });

                        mutationService.apply(
                              availableTargets[0],
                              'com.vmware.vsphere.client.h5.storage.spec.MountVvolDatastoreSpec',
                              {
                                 hostsToMount: hostIds
                              },
                              i18nService.getString('StorageUi', 'modifyDatastoreForm.mountDatastore'));
                        return true;
                     });
            };

   }])
.run(['mutationService', 'i18nService',
   function (mutationService, i18nService) {

      h5.actions['vsphere.core.host.actions.paths.enablePath'] =
         function (actionEval, availableTargets, context) {
            if (!availableTargets || !availableTargets.length || !context) {
               return;
            }

            mutationService.apply(
               availableTargets[0],
               'com.vmware.vsphere.client.storage.adapters.StoragePathEnableSpec',
               {
                  pathName: context.pathName,
                  enable: true
               },
               i18nService.getString('StorageUi', 'storage.paths.actions.enableTask'));
         };

      h5.actions['vsphere.core.host.actions.paths.disablePath'] =
         function (actionEval, availableTargets, context) {
            if (!availableTargets || !availableTargets.length || !context) {
               return;
            }

            mutationService.apply(
               availableTargets[0],
               'com.vmware.vsphere.client.storage.adapters.StoragePathEnableSpec',
               {
                  pathName: context.pathName,
                  enable: false
               },
               i18nService.getString('StorageUi', 'storage.paths.actions.disableTask'));
         };
   }])
.run(['storageDeviceMultipathingPolicyDialogService',
   function (storageDeviceMultipathingPolicyDialogService) {

      h5.actions['vsphere.core.host.actions.storageDevice.editMultipathingPolicy'] =
         function (actionEval, availableTargets, context) {
            if (!availableTargets || !availableTargets.length || !context) {
               return;
            }

            storageDeviceMultipathingPolicyDialogService.show(availableTargets[0], context.deviceId, context.name);
         };

   }])
.run(['mutationService', 'i18nService', 'clarityModalService',
   function (mutationSerivce, i18nService, clarityModalService) {

      h5.actions['vsphere.core.host.actions.storageDevice.unmount'] =
         function (actionEval, availableTargets, context) {
            if (!availableTargets || !availableTargets.length || !context) {
               return;
            }

            var modalOptions = {
               message: i18nService.getString(
                     'StorageUi', 'storage.adapters.devices.action.unmount.text', context.name),
               title: i18nService.getString(
                     'StorageUi', 'storage.adapters.devices.action.unmount.title'),
               saveButtonLabel: i18nService.getString('Common', 'yes.label'),
               cancelButtonLabel: i18nService.getString('Common', 'no.label'),
               preserveNewlines: true,
               defaultButton: 'submit',
               showXIcon: false,
               clarityIcon: { class: 'is-warning', shape: 'warning', size: 48 },
               subTitle: { objectId: availableTargets[0] },
               submit: function () {
                  // dispatch an unmount request
                  // additional context for mount/unmount and lunUUID
                  var spec = {
                     _type: 'com.vmware.vsphere.client.storage.adapters.StorageDeviceMountSpec',
                     lunUuid: context.lunUuid,
                     mount: false
                  };

                  mutationSerivce.apply(
                        availableTargets[0],
                        spec._type,
                        spec,
                        i18nService.getString(
                              'StorageUi', 'storage.adapters.devices.action.unmount.taskName'));
               }
            };

            clarityModalService.openConfirmationModal(modalOptions);
         };

      h5.actions['vsphere.core.host.actions.storageDevice.mount'] =
         function (actionEval, availableTargets, context) {
            if (!availableTargets || !availableTargets.length || !context) {
               return;
            }

            // dispatch a mount request
            // additional context for mount/unmount and lunUUID
            var spec = {
               _type: 'com.vmware.vsphere.client.storage.adapters.StorageDeviceMountSpec',
               lunUuid: context.lunUuid,
               mount: true
            };

            mutationSerivce.apply(
               availableTargets[0],
               spec._type,
               spec,
               i18nService.getString(
                  'StorageUi', 'storage.adapters.devices.action.mount.taskName'));
         };

   }])
.run(['datastoreConfigIOControlDialogService',
   function (datastoreConfigIOControlDialogService) {
      h5.actions['vsphere.core.datastore.actions.configSIOC'] =
         function (actionEval, availableTargets) {
            if (!availableTargets || !availableTargets.length) {
               return;
            }

            datastoreConfigIOControlDialogService.show(availableTargets);
         };
   }])
.run(['datastoreSpaceReclamationPriorityDialogService',
   function (datastoreSpaceReclamationPriorityDialogService) {
      h5.actions['vsphere.core.datastore.actions.spaceReclamationPriorityAction'] =
         function (actionEval, availableTargets) {
            if (!availableTargets || !availableTargets.length) {
               return;
            }

            datastoreSpaceReclamationPriorityDialogService.show(availableTargets);
         };
   }])
.run(['renameStorageDeviceDialogService',
   function (dialogService) {
      h5.actions["vsphere.core.host.actions.storageDevice.rename"] =
         function(actionEval, availableTargets, contextObject) {
            if (!availableTargets || !availableTargets.length || !contextObject) {
               return;
            }

            dialogService.show(availableTargets[0], contextObject.lunUuid, contextObject.name);
         };
   }])
.run(['mutationService', 'i18nService', function(mutationService, i18nService) {

   function changeDeviceTag (host, diskUids, isSsd) {
      mutationService.apply(
         host,
         "com.vmware.vsphere.client.storage.TagDiskAsSsdSpec",
         {
            isSSD: isSsd,
            diskUUID: diskUids
         },
         i18nService.getString('StorageUi',
            isSsd ? 'storage.adapters.devices.action.tagAsSsd.taskName'
                  : 'storage.adapters.devices.action.untagAsSsd.taskName')
      );
      return true;
   }

   h5.actions['vsphere.core.storage.devices.tagAsSSD'] =
      function(actionEval, availableTargets, disks) {
         if (!availableTargets || !availableTargets.length || !disks.length) {
            return true;
         }
         return changeDeviceTag(availableTargets[0],
            _.map(disks, function(disk) {
               return disk.lunUuid;
            }), true);
      };

   h5.actions['vsphere.core.storage.devices.tagAsHDD'] =
      function(actionEval, availableTargets, disks) {
         if (!availableTargets || !availableTargets.length || !disks.length) {
            return true;
         }
         return changeDeviceTag(availableTargets[0],
            _.pluck(disks, 'lunUuid'), false);
      };

}])
.run(['mutationService', 'defaultUriSchemeUtil', 'i18nService',
   function (mutationService, defaultUriSchemeUtil, i18nService) {
      h5.actions["vsphere.core.datastore.actions.mountVmfsSingleHost"] =
         function(actionEval, availableTargets, contextObject) {
            if (!availableTargets || !availableTargets.length || !contextObject) {
               return;
            }


            var spec = {
               _type: 'com.vmware.vsphere.client.storage.VmfsDatastoreHostsMountSpec',
               datastoreToMount: defaultUriSchemeUtil.getManagedObjectReference(contextObject.datastoreId)
            };

            mutationService.apply(
               availableTargets[0],
               spec._type,
               spec,
               i18nService.getString('StorageUi', 'modifyDatastoreForm.mountDatastore'));
         };

      h5.actions["vsphere.core.datastore.actions.unmountVmfsSingleHost"] =
         function(actionEval, availableTargets, contextObject) {
            if (!availableTargets || !availableTargets.length || !contextObject) {
               return;
            }


            var spec = {
               _type: 'com.vmware.vsphere.client.storage.VmfsDatastoreUnmountSpec',
               datastoreToUnmount: defaultUriSchemeUtil.getManagedObjectReference(contextObject.datastoreId)
            };

            mutationService.apply(
               availableTargets[0],
               spec._type,
               spec,
               i18nService.getString('StorageUi', 'modifyDatastoreForm.unmountDatastore'));
         };
   }])
.run(['storageProvidersService', 'storageProviderConstants',
   function (storageProvidersService, storageProviderConstants) {
      h5.actions[storageProviderConstants.actions.RESYNC_INTERNAL_PROVIDER] =
         function (actionEval, availableTargets) {
            if (availableTargets && availableTargets.length === 1) {
               storageProvidersService.resyncInternalVasaProviders(availableTargets[0]);
            }
         };
      h5.actions[storageProviderConstants.actions.REMOVE] =
         function (actionEval, availableTargets, context) {
            if (availableTargets && availableTargets.length === 1) {
               storageProvidersService.removeStorageProvider(availableTargets[0], context);
            }
         };
      h5.actions[storageProviderConstants.actions.SYNC] =
         function (actionEval, availableTargets, context) {
            if (availableTargets && availableTargets.length === 1) {
               storageProvidersService.syncProvider(availableTargets[0], context);
            }
         };

      h5.actions[storageProviderConstants.actions.REFRESH_CERTIFICATE] =
         function (actionEval, availableTargets, context) {
            if (availableTargets && availableTargets.length === 1) {
               storageProvidersService.refreshCertificate(availableTargets[0], context);
            }
         };
   }])
.run(['addStorageProviderDialogService',
   function (addStorageProviderDialogService) {
      h5.actions['vsphere.core.storage.storageProviders.addNew'] =
         function (actionEval, availableTargets, context) {
            if (availableTargets && availableTargets.length === 1) {
               addStorageProviderDialogService.showAddStorageProviderDialog(availableTargets[0], context.providerNames);
            }
         };
   }])
.run(['allocateSpaceDialogService',
   function (allocateSpaceDialogService) {
      h5.actions['vsphere.core.host.actions.vFlash.editCacheConfiguration'] =
         function (actionEval, availableTargets, context) {
            if (availableTargets && availableTargets.length === 1 && context && context.selectedDatastore) {
               allocateSpaceDialogService.openSpaceAllocationDialog(context.selectedDatastore, availableTargets[0]);
            }
         };
   }])
.run(['clarityModalService', 'i18nService', 'moveDatastoreToService',
   function (clarityModalService, i18nService, moveDatastoreToService) {
      h5.actions['vsphere.core.datastore.actions.moveTo'] =
            function (actionEval, availableTargets) {
               if (!availableTargets || !availableTargets.length) {
                  return;
               }

               var modalOptions = {
                  title: i18nService.getString('StorageUi', 'datastoreMoveToTitle'),
                  defaultButton: 'submit',
                  dialogData: {
                     objectIds: availableTargets
                  },
                  contentTemplate: 'storage-ui/resources/storage/views/datastore/moveDatastoreToDialog.html'
               };

               clarityModalService.openOkCancelModal(modalOptions);
            };
      h5.actions['vsphere.core.datastore.actions.moveDatastoreToDrag'] =
            function(actionEval, availableTargets, destinationTarget) {
               if (!availableTargets || !availableTargets.length || !destinationTarget) {
                  return;
               }


               moveDatastoreToService.moveDatastoreTo(destinationTarget.dropTarget, availableTargets);
            };
   }])
.run(['mutationService', 'i18nService', function(mutationService, i18nService) {
      function changeLed (host, diskUids, turnOn) {
         mutationService.apply(
            host,
            "com.vmware.vsphere.client.storage.LightDiskLedSpec",
            {
               turnOn : turnOn,
               diskUUID: diskUids
            },
            i18nService.getString('StorageUi',
               turnOn ? 'storage.adapters.devices.action.turnLedOn.taskName'
                  : 'storage.adapters.devices.action.turnLedOff.taskName')
         );
      }

      h5.actions['vsphere.core.host.actions.storageDevice.turnLedOn'] =
         function(actionEval, availableTargets, disks) {
            if (!availableTargets || !availableTargets.length || !disks.length) {
               return;
            }
            changeLed(availableTargets[0],
               _.map(disks, function(disk) {
                  return disk.lunUuid;
               }), true);
         };

      h5.actions['vsphere.core.host.actions.storageDevice.turnLedOff'] =
         function(actionEval, availableTargets, disks) {
            if (!availableTargets || !availableTargets.length || !disks.length) {
               return;
            }
            changeLed(availableTargets[0],
               _.map(disks, function(disk) {
                  return disk.lunUuid;
               }), false);
         };
   }])
.run(['erasePartitionsDialogService',
   function(erasePartitionsDialogService) {
      h5.actions['vsphere.core.host.actions.storageDevice.erasePartitions'] =
            function(actionEval, availableTargets, context) {

               if (!availableTargets || !availableTargets.length
                     || !context || !context.selectedDevices) {
                  return;
               }

               erasePartitionsDialogService.open(
                     availableTargets[0],
                     context.selectedDevices);
            };
   }
])
.run(['hostStorageService',
   function(hostStorageService) {
      h5.actions['vsphere.core.host.actions.storageDevice.tagAsLocal'] =
            function(actionEval, availableTargets, context) {
               changeDiskLocalState(availableTargets, context, true);
            };

      h5.actions['vsphere.core.host.actions.storageDevice.untagAsLocal'] =
            function(actionEval, availableTargets, context) {
               changeDiskLocalState(availableTargets, context, false);
            };

      function changeDiskLocalState(availableTargets, context, tagAsLocal) {
         if (!availableTargets || !availableTargets.length
               || !context || !context.selectedDevices) {
            return;
         }

         hostStorageService.changeDiskLocalState(
               availableTargets[0],
               context.selectedDevices,
               tagAsLocal
         );
      }
   }
]);
