module ds_cluster_ui {
   import WizardPageControllerAbstract =
         ds_cluster_ui.WizardPageControllerAbstract;
   import IPromise = angular.IPromise;

   class DatastoresPageController extends WizardPageControllerAbstract {

      private config: any;
      public dsFilterOptions: Object[];

      public static $inject = ["$scope", "createDsClusterWizardManager",
            "simpleValidationCommit", "vuiConstants", "i18nService", "$q",
            "createDsClusterWizardDatastoresService", "$compile",
            "createDsClusterPageConstants", "defaultUriSchemeUtil"];

      constructor(private $scope: ScopeRoute,
            createDsClusterWizardManager: CreateDsClusterWizardManager,
            simpleValidationCommit: any, private vuiConstants: any,
            private i18nService: any, private $q: any,
            private createDsClusterWizardDatastoresService:
                  CreateDsClusterWizardDatastoresService,
            private $compile: any, private createDsClusterPageConstants: any,
            private defaultUriSchemeUtil: any) {
         super(createDsClusterWizardManager, simpleValidationCommit);

         this.initPageSubmit();
         this.initModel();
      }

      protected getValidationErrors(): IPromise<string[]> {
         if (this.$scope.model.datastores.length <= 0) {
            return this.$q.resolve([this.i18nService.getString("DsClusterUi",
                  "createDsClusterWizard.selectDatastoresPage.noDatastoreSelectedErrorMessage")]);
         }

         let invalidType: boolean =
               _.some(this.$scope.model.datastoreDetails,
                     (datastore: DatastoreDetails) => this.$scope.model.datastoreDetails[0].type !== datastore.type);

         if (invalidType) {
            return this.$q.resolve([this.i18nService.getString("DsClusterUi",
                  "createDsClusterWizard.selectDatastoresPage.mixedTypeDatastoresErroroMessage")]);
         }

         return this.$q.reject();
      }

      protected getCurrentPageIndex(): number {
         return this.createDsClusterPageConstants.DATASTORES_INDEX;
      }

      private initModel(): void {
         let self: DatastoresPageController = this;
         this.$scope.model = this.createDsClusterWizardManager.getModel();
         let datacenterId: string = this.$scope.model.objectId;

         this.dsFilterOptions = this.createDsClusterWizardDatastoresService.getOptions();

         this.config = self.getObjectSelectorConfig(datacenterId);

         // update the object selector filter config
         this.$scope.$watch("model.dsFilter.filterId", function() {
            self.config.listTabConfig.listConfig[0].filterId =
                  self.$scope.model.dsFilter.filterId;
         });

         self.$scope.$watch("model.datastores", function() {
            self.getValidationErrors().then(function() {
               self.setWizardPageState(self.vuiConstants.wizard.pageState.INCOMPLETE);
            }, function() {
               self.setWizardPageState(self.vuiConstants.wizard.pageState.COMPLETED);
            });
         });
      }

      private getObjectSelectorConfig(datacenterId: string): any {
         return {
            contextObject: datacenterId,
            multipleSelect: true,
            listTabConfig: {
               listConfig: [{
                  label: this.i18nService.getString("Common",
                      "typeResource.datastorePlural"),
                  listViewId: "vsphere.core.datastore.simplified.list",
                  dataModels: ["Datastore"],
                  filterId: this.$scope.model.dsFilter.filterId,
                  filterParams: this.$scope.model.hostsAndClusters,
                  propertyParams: [{
                     _type: "com.vmware.vise.mvc.model.data.ParamSpec",
                     propertyName: "hostConnectionStatus",
                     parameterType: "com.vmware.vsphere.client.dsclusterui.model.DsHostsConnectionStatusSpec",
                     parameter: {
                        _type: "com.vmware.vsphere.client.dsclusterui.model.DsHostsConnectionStatusSpec",
                        hostsAndClusters: _.map(this.$scope.model.hostsAndClusters,
                              objectId => this.defaultUriSchemeUtil
                                    .getManagedObjectReference(objectId))
                     }
                  }]
               }]
            },
            selectedObjects: this.$scope.model.datastores,
            onSelectionChanged: (newSelectedItems: any) => {
               this.$scope.model.datastores = _.pluck(newSelectedItems, "id");
               this.createDsClusterWizardManager.setModelDatastoreRefs();
               this.$scope.model.datastoreDetails = _.map(newSelectedItems,
                     (object: any) => {
                        return {
                           name: object.name,
                           icon: object.icon,
                           type: object.rawData.specificType,
                           capacity: object.rawData["summary.capacity.@formatted"],
                           freeSpace: object.rawData["info.freeSpace.@formatted"]
                        };
                     });
            }
         };
      }
   }

   angular.module("com.vmware.vsphere.client.dsCluster")
         .controller("DatastoresPageController", DatastoresPageController);
}
