namespace storage_ui {

   export class ErasePartitionsDialogService {
      public static $inject = ["clarityModalService", "i18nService", "mutationService",
            "hostStorageService"];

      constructor(
            private clarityModalService: any,
            i18nService: any,
            private mutationService: any,
            private hostStorageService: any) {

         this.i18n = i18nService.getString.bind(this, "StorageUi");
      };

      private i18n: Function;

      public open(hostId: string, devices: any[]) {
         const title: string = devices.length === 1
               ? this.i18n("storage.adapters.devices.action.format.formTitle")
               : this.i18n("storage.adapters.devices.action.format.formTitleMulti");

         const modalOptions: any = {
            title: title,
            size: "lg",
            defaultButton: "submit",
            alerts: [],
            contentTemplate: "storage-ui/resources/storage/views/host/devices/"
                  + "erasePartitions/erasePartitionsDialog.html",
            onSubmit: this.onSubmit.bind(this, hostId, devices)
         };

         if (devices.length === 1) {
            modalOptions.subTitle = devices[0].name;
         }

         modalOptions.dialogData = {
            hostId: hostId,
            devices: devices,
            infoHeader: devices.length === 1
               ? this.i18n("storage.adapters.devices.action.format.infoHeaderSingle")
               : this.i18n("storage.adapters.devices.action.format.infoHeaderMulti"),
            confirmationText: devices.length === 1
               ? this.i18n("storage.adapters.devices.action.format.confirmationSingle")
               : this.i18n("storage.adapters.devices.action.format.confirmationMulti"),
            partitions: []
         };

         this.clarityModalService.openOkCancelModal(modalOptions);

         if (devices.length && devices.length === 1) {
            modalOptions.dialogData.isLoading = true;
            this.hostStorageService.retrieveStorageDevicePartitionData(
                  hostId,
                  devices[0].devicePath
            ).then((result: any) => {
               modalOptions.dialogData.partitions = result.partitions;
            }).finally(() => {
               modalOptions.dialogData.isLoading = false;
            });
         }
      }

      private onSubmit(hostId: string, devices: any[]): boolean {
         this.hostStorageService.erasePartitions([{
            hostId: hostId,
            devices: devices
         }]);
         return true;
      }
   }

   angular.module("com.vmware.vsphere.client.storage")
         .service("erasePartitionsDialogService", ErasePartitionsDialogService);
}
