namespace h5_vm {
   import DiskModeOption = h5_vm.DiskModeOption;
   import VmConfigContext = com.vmware.vsphere.client.vm.config.VmConfigContext;
   export class VmHardwareVirtualDiskModeService {
      static $inject: string[] = [
         'vmDeviceInfoService',
         'i18nService',
         'templateHelperService',
         'vmHardwareUtil'
      ];

      constructor(private vmDeviceInfoService: any,
                  private i18nService: any,
                  private templateHelperService: any,
                  private vmHardwareUtil: any) {
      }

      getDiskModeInfo(vmConfigContext: VmConfigContext, inflatedDevice: any,
                      allVirtualDevices: any[]) {
         const optionsForDiskBacking = this.vmDeviceInfoService.backingOptionForDevice(vmConfigContext, inflatedDevice, allVirtualDevices);

         var options: any[] = _.map(optionsForDiskBacking.diskMode.choiceInfo, (modeOption: any) => {
            return {
               name: this.i18nService.getString('VmUi', 'DiskConfig.' + modeOption.key),
               value: modeOption.key
            };
         });

         var selection = _.find(options, (option: any) => {
            return option.value === inflatedDevice.getCurrentDevice().backing.diskMode;
         });

         return {
            options: options,
            selection: selection
         };
      }

      getDiskModeSignPostParams() {
         const diskModeSignPostTemplate = 'vm-ui/resources/vm/views/settings/vmHardwareSettings/' +
               'vmHardwareVirtualDiskShared/vmHardwareVirtualDiskMode/vm-hardware-virtual-disk-mode-sign-post.html';
         return this.templateHelperService.renderTemplateWithScope(diskModeSignPostTemplate, {i18n: this.i18nService.getString})
               .then((content: string) => {
                  return {
                     message: content,
                     title: this.i18nService.getString('Common', 'help'),
                     height: '250px'
                  };
               });
      }

      hasPrivilegesToEditDiskMode(privileges: any, skipPrivilegeCheck: boolean = false): boolean {
         if(skipPrivilegeCheck) {
            return true;
         }

         return this.vmHardwareUtil.checkPrivileges(privileges, [Privileges.VM_EDITDEVICE_PRIVILEGE]);
      }

      isEditDiskModeDisabled(inflatedDevice: any, createMode: boolean,
                             vmConfigContext: VmConfigContext) {
         if(!this.hasPrivilegesToEditDiskMode(vmConfigContext.privileges, createMode)){
            return true;
         }

         if (this.vmHardwareUtil.isVmPoweredOn(vmConfigContext.environment.powerState) && !inflatedDevice.isNew()) {
            return true;
         }

         if(createMode){
            return false;
         } else {
            return inflatedDevice.getCurrentDevice().backing.parent ? true : false;
         }
      }
   }

   angular.module('com.vmware.vsphere.client.vm')
         .service('vmHardwareVirtualDiskModeService', VmHardwareVirtualDiskModeService);
}
