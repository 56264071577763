namespace network_ui {

   import IPromise = angular.IPromise;

   export class IscsiAddPortBinding {
      public bindings: any;
      public controller: any;
      public templateUrl: string;

      constructor() {
         this.bindings = {
            hostId: "<",
            iscsiHbaName: "<",
            onSubmitWrapper: "="
         };

         this.controller = IscsiPortBindingDialogController;
         this.templateUrl = "network-ui/resources/network/views/host/iscsi/add/IscsiAddPortBinding.html";
      }
   }

   export interface AddPortBindingResult {
      isSuccessful: boolean;
      errors: string[];
   }

   class IscsiPortBindingDialogController {
      public static $inject = ["i18nService", "iscsiPortBindingService", "$q", "vuiConstants"];

      private _iscsiPortBindingCandidatesData: CandidateIscsiPortInfoData = {
         items: []
      };
      public selectedPortGroups: IscsiPortInfoItem[] = [];

      public hostId: string;
      public iscsiHbaName: string;
      public onSubmitWrapper: any;

      public loading: boolean = false;

      public splitterOptions: any;

      constructor(private i18nService: any,
                  private iscsiPortBindingService: IscsiPortBindingService,
                  private $q: any,
                  private vuiConstants: any) {
      }

      public $onInit() {
         if (this.onSubmitWrapper) {
            this.onSubmitWrapper.submit = this.submit.bind(this);
         }
         let self: IscsiPortBindingDialogController = this;

         self.splitterOptions = {
            orientation: self.vuiConstants.splitter.orientation.VERTICAL,
            panes: [
               {
                  min: "150px",
                  size: "50%"
               }, {
                  min: "150px",
                  size: "50%"
               }
            ]
         };

         this.loading = true;
         this.iscsiPortBindingService.getCandidateIscsiPortInfoData(this.hostId, this.iscsiHbaName)
               .then(function (iscsiPortInfoData: CandidateIscsiPortInfoData) {
                  if (!iscsiPortInfoData) {
                     return;
                  }
                  self._iscsiPortBindingCandidatesData = iscsiPortInfoData;
               }).finally(function () {
            self.loading = false;
         });
      }

      public get candidateIscsiPortBindingItem(): IscsiPortInfoItem|null {
         if (this.selectedPortGroups && this.selectedPortGroups.length === 1) {
            return this.selectedPortGroups[0];
         }
         return null;
      }

      public get noDetailsMessage(): String {
         if (this.selectedPortGroups && this.selectedPortGroups.length > 1) {
            return this.i18nService.getString("Common", "ListPreviewSplitView.multipleItemsSelected");
         } else {
            return this.i18nService.getString("Common", "ListPreviewSplitView.noItemsSelected");
         }
      }

      /**
       * Performs the port addition operation after validating the grid selection.
       *
       * @param onSuccessCallback (optional)
       *      function to be called upon successful completion of the mutation operation
       *
       * @returns {IPromise<AddPortBindingResult>}
       *      result of the immediate validation - e.g.
       *      {isSuccessful: false, errors: ["..."]} if no adapters have been selected
       */
      public submit(onSuccessCallback?: Function): IPromise<AddPortBindingResult> {
         let operationResult: AddPortBindingResult = {isSuccessful: true, errors: []};

         if (!this.selectedPortGroups || !this.selectedPortGroups.length) {
            operationResult.isSuccessful = false;
            operationResult.errors.push(this.i18nService.getString("NetworkUi", "portbinding.addForm.notSelected"));
            return this.$q.when(operationResult);
         }

         let addCandidatePromise: any
               = this.iscsiPortBindingService.addCandidateIscsiPortInfo(
               this.hostId,
               this.iscsiHbaName,
               this.selectedPortGroups);

         if (onSuccessCallback) {
            addCandidatePromise.then(onSuccessCallback);
         }

         return this.$q.when(operationResult);
      }

      public get iscsiPortBindingCandidatesData(): CandidateIscsiPortInfoData {
         return this._iscsiPortBindingCandidatesData;
      }
   }

   angular.module("com.vmware.vsphere.client.network")
         .component("iscsiAddPortBinding", new IscsiAddPortBinding());
}
