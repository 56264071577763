/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace network_ui {

   class VmMigrateSelectVmsPageController {

      public static $inject = [
         "$scope",
         "i18nService",
         "columnRenderersRegistry",
         "vmMigrateWizardConstants",
         "dataService",
         "clarityModalService",
         "defaultUriSchemeUtil",
         "vuiConstants"
      ];

      public datagridOptions: any;
      public selectVmsInfo: string;

      private bindTreeListEvents: boolean;
      private restoreExpandedItems: boolean;

      private items: any;

      private itemsById: { [id: string]: any };

      private _isMasterCheckboxChecked: boolean;

      private _searchTerm: string;

      private filterDatagridDataDebounced: Function;

      constructor(private $wizardScope: VmMigrateWizardScope,
                  public i18nService: any,
                  private columnRenderersRegistry: any,
                  private wizardConstants: any,
                  private dataService: any,
                  private clarityModalService: any,
                  private defaultUriSchemeUtil: any,
                  private vuiConstants: any) {

         // Debouncing the filter handling to improve responsiveness while typing.
         this.filterDatagridDataDebounced = _.debounce(this.filterDatagridData.bind(this), 500);

         if (!this.$wizardScope.flags.isSelectVmsPageInitialized) {
            this.requestProperties();
         } else {
            this.initialize($wizardScope.model.vmListItems);
         }

         this.selectVmsInfo = this.$wizardScope.flags.noSourceNetworkSelected
               ? this.i18nService.getString("NetworkUi",
                     "SelectVmsToMigratePage.selectVmsLabelNoSource",
                     this.$wizardScope.model.destinationNetwork.name)
               : this.i18nService.getString("NetworkUi",
                     "SelectVmsToMigratePage.selectVmsLabel",
                     this.$wizardScope.model.sourceNetwork.name,
                     this.$wizardScope.model.destinationNetwork.name);
      }

      get searchTerm() {
         return this._searchTerm;
      }

      set searchTerm(value: string) {
         this._searchTerm = value;
         this.filterDatagridDataDebounced();
      }

      private requestProperties() : void {
         this.$wizardScope.wizardConfig.loading = true;

         let networkId: string = this.defaultUriSchemeUtil.getVsphereObjectId(
               this.$wizardScope.model.destinationNetwork.networkRef);

         let spec: any = {
            sourceNetworkRef: this.$wizardScope.flags.noSourceNetworkSelected
                  ? null
                  : this.$wizardScope.model.sourceNetwork.networkRef,
            datacenterRef: this.defaultUriSchemeUtil.getManagedObjectReference(
                  this.$wizardScope.model.datacenterId)
         };

         this.dataService.getProperties(
               networkId,
               [this.wizardConstants.properties.VM_NETWORKS_PROPERTY],
               {
                  propertyParams: [{
                     propertyName: this.wizardConstants.properties.VM_NETWORKS_PROPERTY,
                     parameterType: this.wizardConstants.specs.VM_MIGRATE_PROPERTY_SPEC,
                     parameter: spec
                  }]
               })
               .then((response: any): void => {
                  const vmData: any = response[
                        this.wizardConstants.properties.VM_NETWORKS_PROPERTY];

                  this.$wizardScope.model.destinationNetworkData =
                        vmData.destinationNetwork;
                  this.$wizardScope.model.vmListItems = vmData.vmListItems;

                  this.$wizardScope.wizardConfig.loading = false;
                  this.$wizardScope.flags.isSelectVmsPageInitialized = true;

                  this.initialize(vmData.vmListItems);
               });
      }

      private initialize(vmListItems: any[]): void {
         this.items = vmListItems;
         this.itemsById = {};

         for (const item of vmListItems) {
            const id: string = `${item.vmId}_${item.adapter}`;
            this.itemsById[id] = item;

            item.isAccessibleLabel = item.isAccessible
                  ? this.i18nService.getString(
                        "NetworkUi", "SelectVmsToMigratePage.accessible")
                  : this.i18nService.getString(
                        "NetworkUi", "SelectVmsToMigratePage.inaccessible");
         }

         this.initDatagridOptions(vmListItems);
         this.evaluateMasterCheckboxState();
      }

      private filterDatagridData(): void {
         if (!this._searchTerm) {
            // Nothing in the search field, show all items
            this.datagridOptions.data = this.items;
         } else {
            // Filter according to the search field value
            this.datagridOptions.data = _.filter(
                  this.items,
                  (item: any): boolean => this.isItemMatchingSearchTerm(item));
         }

         this.evaluateMasterCheckboxState();
      }

      private isItemMatchingSearchTerm(item: any): boolean {
         const filterableFields: string[] = _.pluck(this.datagridOptions.columnDefs, "field");
         return _.some(filterableFields,
               (field: string): boolean => {
                  if (!item[field]) {
                     return false;
                  }
                  if (!this._searchTerm) {
                     return true;
                  }
                  return item[field].toLowerCase().indexOf(this._searchTerm.toLowerCase()) !== -1;
               });
      }

      private onItemCheckboxClick(id: string, event: any): void {
         this.itemsById[id].isChecked = event.currentTarget.checked;
         this.evaluateMasterCheckboxState();
      }

      private onMasterCheckboxClick(event: any): void {
         const checked: boolean = event.currentTarget.checked;
         this._isMasterCheckboxChecked = checked;

         let hasInaccessibleItems: boolean = false;

         // If the checkbox is deselected the state of all items, even the ones
         // which are filtered out needs to be modified. If the checkbox is selected
         // only the accessible items visible to the user need to be modified.
         const items: any = checked ? this.datagridOptions.data : this.items;
         for (const item of items) {
            if (item.isAccessible) {
               item.isChecked = checked;
            } else {
               hasInaccessibleItems = true;
            }
         }

         if (checked && hasInaccessibleItems
               && !this.$wizardScope.flags.inaccessibilityWarningShown) {
            this.showWarningDialog();
         }
      }

      private isItemChecked(id: string): void {
         return this.itemsById[id].isChecked;
      }

      private getIsMasterCheckboxChecked(): boolean {
         return this._isMasterCheckboxChecked;
      }

      private evaluateMasterCheckboxState(): void {
         let allItemsChecked: boolean = false;
         let hasAccessibleItem: boolean = false;

         // The master checkbox state always reflects the visible collection of accessible items.
         // Inaccessible items or items which do not match the search term are not taken
         // into account.
         if (this.datagridOptions.data.length > 0) {
            allItemsChecked = _.all(this.datagridOptions.data, (item: any): boolean => {
               hasAccessibleItem = hasAccessibleItem || item.isAccessible;
               return !item.isAccessible || item.isChecked;
            });
         }

         this._isMasterCheckboxChecked = allItemsChecked && hasAccessibleItem;
      }

      private showWarningDialog() {
         this.clarityModalService.openConfirmationModal({
            title: this.i18nService.getString(
                  "NetworkUi", "SelectVmsToMigratePage" +
                  ".vmsWithInaccessibleDestinationSelected.title"),
            message: this.i18nService.getString(
                  "NetworkUi", "SelectVmsToMigratePage" +
                  ".vmsWithInaccessibleDestinationSelected.description"),
            submit: () => {
               this.$wizardScope.flags.inaccessibilityWarningShown = true;
            },
            clarityIcon: {
               class: "is-warning",
               shape: "warning-standard",
               size: "32"
            },
            preserveNewlines: true,
            saveButtonLabel: this.i18nService.getString(
                  "Common", "alert.ok"),
            cancelButtonLabel: null,
            hideCancelButton: true
         });
      }

      private initDatagridOptions(data: any): void {
         this.datagridOptions = {
            columnDefs: this.getColumnDefs(),
            sortMode: this.vuiConstants.grid.sortMode.SINGLE,
            selectionMode: this.vuiConstants.grid.selectionMode.NONE,
            selectedItems: [],
            data: data,
            resizable: true,
            height: "100%",
            pageConfig: {
               hidePager: true,
               displayMode: this.vuiConstants.grid.displayMode.VIRTUAL_SCROLLING
            },
            isMasterCheckboxDisabled: _.all(data, (item: any): boolean => !item.isAccessible),
            getIsMasterCheckboxChecked: this.getIsMasterCheckboxChecked.bind(this),
            onItemCheckboxClick: this.onItemCheckboxClick.bind(this),
            onMasterCheckboxClick: this.onMasterCheckboxClick.bind(this),
            isItemChecked: this.isItemChecked.bind(this)
         };
      }

      private getColumnDefs(): any[] {
         const columnRenderer: any = this.columnRenderersRegistry
               .getColumnRenderer('disabled-text-icon-with-tooltip');
         return [
            this.getCheckboxColumnDef(),
            {
               field: "name",
               displayName: this.i18nService.getString(
                     "NetworkUi", "SelectVmsToMigratePage.vmNameColumnHeader"),
               width: "165px",
               searchable: false,
               template: function(dataItem: any) {
                  return columnRenderer(["icon", "label", "disabled", "tooltip"], {
                     icon: dataItem.iconId,
                     label: dataItem.name,
                     disabled: !dataItem.isAccessible,
                     tooltip: dataItem.itemTooltip
                  });
               }
            },
            {
               field: "adapter",
               displayName: this.i18nService.getString(
                     "NetworkUi", "SelectVmsToMigratePage.vmNicColumnHeader"),
               width: "165px",
               searchable: false,
               template: function(dataItem: any) {
                  return columnRenderer(["icon", "label", "disabled", "tooltip"], {
                     icon: "network-lib-ui-icon-virtualAdapter",
                     label: dataItem.adapter,
                     disabled: !dataItem.isAccessible,
                     tooltip: dataItem.itemTooltip
                  });
               }
            },
            {
               field: "hostName",
               displayName: this.i18nService.getString(
                     "NetworkUi", "SelectVmsToMigratePage.hostColumnHeader"),
               searchable: false,
               template: function(dataItem: any) {
                  return columnRenderer(["icon", "label", "disabled", "tooltip"], {
                     icon: dataItem.hostName ? "vsphere-icon-host" : null,
                     label: dataItem.hostName,
                     disabled: !dataItem.isAccessible,
                     tooltip: dataItem.itemTooltip
                  });
               }
            },
            {
               field: "isAccessibleLabel",
               displayName: this.i18nService.getString(
                     "NetworkUi", "SelectVmsToMigratePage.destNetworkColumnHeader"),
               searchable: false,
               template: (dataItem: any) => {
                  let accessibility: string = "";

                  if (dataItem.isAccessible) {
                     accessibility = this.i18nService.getString(
                           "NetworkUi", "SelectVmsToMigratePage.accessible");
                  } else {
                     accessibility = this.i18nService.getString(
                           "NetworkUi", "SelectVmsToMigratePage.inaccessible");
                  }
                  return columnRenderer(["icon", "label", "disabled", "tooltip"], {
                     icon: null,
                     label: accessibility,
                     disabled: !dataItem.isAccessible,
                     tooltip: dataItem.itemTooltip
                  });
               }
            }
         ];
      }

      private getCheckboxColumnDef(): any {
         const headerTemplate: string =
               `<input type="checkbox"
                       ng-disabled="datagridOptions.isMasterCheckboxDisabled"
                       ng-checked="datagridOptions.getIsMasterCheckboxChecked()"
                       ng-click="datagridOptions.onMasterCheckboxClick($event)"/>`;
         return {
               filterable: false,
               sortable: false,
               groupable: false,
               searchable: false,
               width: "30px",
               headerTemplate: headerTemplate,
               template: function (dataItem: any) {
                  const id: string = `${dataItem.vmId}_${dataItem.adapter}`;
                  return `<input type="checkbox"
                                 ng-disabled="${!dataItem.isAccessible}"
                                 ng-checked="datagridOptions.isItemChecked('${id}')"
                                 ng-click="datagridOptions.onItemCheckboxClick('${id}', $event)"/>`;
               }
            };
      }
   }

   angular.module("com.vmware.vsphere.client.network")
         .controller("VmMigrateSelectVmsPageController",
               VmMigrateSelectVmsPageController);
}
