namespace h5_vm {

   import IPromise = angular.IPromise;
   import IQService = angular.IQService;
   import VmProvisioningPageModel = h5_vm.VmProvisioningPageModel;
   const type: string = "com.vmware.vcenter.ovf.EulaParams";

   class VmProvisioningEulaPageModelFactory {
      public static $inject = [
         "$q",
         "i18nService"
      ];

      constructor($q: IQService,
            i18nService: any
      ) {
         return (virtualMachineSpecBuilder: any, wizardViewData: any) => {
            return new VmProvisioningEulaPageModel(
                  $q,
                  i18nService,
                  virtualMachineSpecBuilder,
                  wizardViewData);
         };
      }
   }

   export class VmProvisioningEulaPageModel implements VmProvisioningPageModel<any> {
      static $inject: string[] = [
         "$q",
         "i18nService"
      ];

      private wizardViewDataObj: any;
      private virtualMachineSpecBuilderObj: any;
      private formObj: any = {
         eulaInfo: undefined
      };

      constructor(
         private $q: IQService,
         private i18nService: any,
            virtualMachineSpecBuilder: any,
            wizardViewData: any) {
         this.virtualMachineSpecBuilderObj = virtualMachineSpecBuilder;
         this.wizardViewDataObj = wizardViewData;
      }

      get form(): any {
         this.formObj.eulaInfo = this.retrieveCommonContext();
         return this.formObj;
      }

      public resetForm(): void {
         this.formObj.eulaInfo.allEULAAccepted = false;
      }

      public validatePage(): Object {
         if (this.formObj.eulaInfo && this.formObj.eulaInfo.allEULAAccepted) {
            return {};
         } else {
            return {
               error: this.i18nService.getString(
                  "ProvisioningUiLib", "AcceptEulaPage.errorEulaNotAccepted")
            };
         }
      }

      public submitPage(): any {
         return {
            wizardFlow: this.virtualMachineSpecBuilderObj.getCreationType(),
            invalidateNextPages: false
         };
      }

      private retrieveCommonContext(): any {
         return _.find(
            this.wizardViewDataObj.getDeployFromLibraryCommonContext(),
            (item: any) => {
               return (_.isEqual(item._type, type));
            });
      }
   }

   angular.module("com.vmware.vsphere.client.vm")
      .service("vmProvisioningEulaPageModel",
            VmProvisioningEulaPageModelFactory);
}
