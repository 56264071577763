namespace storage_ui {

   import IRootScopeService = angular.IRootScopeService;

   export class ScsiLunDisplayNameUpdateSpec {
      _type: string = "com.vmware.vsphere.client.storage.adapters.ScsiLunDisplayNameUpdateSpec";
      uuid: string;
      text: string;
   }

   export class RenameStorageDeviceDialogService {

      public static $inject = ["i18nService",
         "clarityModalService", "clarityConstants", "vxValidatorFactory", "mutationService"];

      constructor(private i18nService: any,
                  private clarityModalService: any,
                  private clarityConstants: any,
                  private vxValidatorFactory: any,
                  private mutationService: any) {
      }

      /**
       * Displays the StorageDeviceMultipathingPolicyDialog
       */
      public show(hostId: string, lunUuid: string, deviceName: string) {

         const self: RenameStorageDeviceDialogService = this;

         let modalOptions: any = {
            title: self.i18nService.getString(
                  "StorageUi", "storage.devices.action.rename.dialogTitle"),
            subTitle: {
               text: deviceName
            },
            defaultButton: "submit",
            alerts: [],
            contentTemplate: "storage-ui/resources/storage/views/host/devices/RenameStorageDeviceDialog.html",
            onSubmit: ()=> {
               if (modalOptions.dialogData.renameDeviceFormValidator.validate().length) {
                  return false;
               }
               let spec: ScsiLunDisplayNameUpdateSpec = new ScsiLunDisplayNameUpdateSpec();
               spec.uuid = lunUuid;
               spec.text = modalOptions.dialogData.name;
               if (spec.text) {
                  this.mutationService.apply(hostId, spec._type, spec);
               }
               return !!spec.text;
            }
         };

         modalOptions.dialogData = {
            hostId: hostId,
            lunUuid: lunUuid,
            name: deviceName,
            i18n: this.i18nService,
            renameDeviceFormValidator: this.vxValidatorFactory.create()
         };

         self.clarityModalService.openOkCancelModal(modalOptions);
      }

   }

   angular.module("com.vmware.vsphere.client.storage")
         .service("renameStorageDeviceDialogService", RenameStorageDeviceDialogService);
}
