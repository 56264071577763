/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace network_ui {

   import IController = angular.IController;
   import ProxySwitchUplinkData = com.vmware.vsphere.client.h5.network.host.proxyswitch.model.ProxySwitchUplinkData;
   import UplinkPortMapping = com.vmware.vsphere.client.h5.network.host.proxyswitch.model.UplinkPortMapping;
   export class ConfigProxySwitchPnicsComponent {

      public bindings: any;
      public controller: any;
      public templateUrl: string;

      constructor() {
         this.bindings = {
            hostId: "<",
            dvsRef: "<",
            proxySwitchKey: "<",
            onUplinkMappingCreated: "&"
         };

         this.controller = ConfigProxySwitchPnicsController;

         this.templateUrl = "network-ui/resources/network/components/proxyswitch/configProxySwitchPnicsTemplate.html";
      }
   }

   class ConfigProxySwitchPnicsController implements IController {

      public static $inject = ["physicalAdaptersListService"];

      // Bindings
      public hostId: string;

      public dvsRef: ManagedObjectReference;

      public proxySwitchKey: string;

      public onUplinkMappingCreated: (data: {uplinkData: ProxySwitchUplinkData}) => void;

      // Other public fields
      public portMapping: ProxySwitchUplinkData & {addedMappings: {[index: string]: string}};

      public loading: boolean;

      constructor(private physicalAdaptersListService: any) {
      }

      public $onInit() {
         this.loading = true;
         this.physicalAdaptersListService.getProxySwitchUplinkData(
               this.hostId, this.dvsRef, this.proxySwitchKey)
               .then((proxySwitchUplinkData: ProxySwitchUplinkData & {addedMappings: {[index: string]: string}}): void => {
                  this.portMapping = proxySwitchUplinkData;
                  this.portMapping.addedMappings = {};
                  this.onUplinkMappingCreated({uplinkData: this.portMapping});
                  this.loading = false;
               });
      }
   }

   angular.module("com.vmware.vsphere.client.network")
         .component("configProxySwitchPnics", new ConfigProxySwitchPnicsComponent());
}