/* Copyright 2015 VMware, Inc. All rights reserved. -- VMware Confidential */
(function() {
   'use strict';
   angular.module('com.vmware.vsphere.client.commonModule').directive('vxCompatibilityView', VxCompatibilityView);

   VxCompatibilityView.$inject = [
      'clarityModalService',
      'i18nService',
      '$injector',
      '$q'
   ];

   /**
    * A compatibility box that holds the results from the compatibility check in wizards (i.e migrate, create VM...).
    *
    * onShowDetailsClick - A callback that can accept the contents as a parameter.
    *    If it returns a promise, the compatibility box will wait for the promise to be
    *    resolved and then it will display the dialog.
    *    If the callback is not of type 'function', it will be ignored.
    * summarize - if 'true', the messages are summarized and 'Show details..' link is displayed
    *    It is 'true' by default.
    */
   function VxCompatibilityView(clarityModalService, i18nService, $injector, $q) {
      return {
         restrict: 'E',
         replace: true,
         templateUrl: 'common-module-ui/resources/common-module/views/compatibility/vxCompatibilityView.html',
         scope: {
            compatibilityTitle: '<',
            contents: '<',
            summarize: '<?',
            onShowDetailsClick: '&?',
            isLoading: '<',
            validatingAriaLabel: '@?'
         },
         link: function(scope, element, attrs) {
            _.defaults(scope, {
               summarize: true
            });

            if (!scope.summarize) {
               return;
            }

            scope.showDetailsLabel = i18nService.getString("CommonModuleUi", "compatibilityView.showDetails");
            scope.validationMessage =  i18nService.getString("CommonModuleUi", "compatibilityView.validating");

            scope.openPopup = function() {
               var advancedCompatibilityContentsArr = _.pluck(scope.contents,
                     "advancedCompatibilityContents");
               advancedCompatibilityContentsArr = _.filter(advancedCompatibilityContentsArr,
                     function(item) {
                        return item;
                     });
               if (!_.isEmpty(advancedCompatibilityContentsArr)) {
                  var vscAdvancedCompatibilityDialogOpenService =
                        $injector.get("vscAdvancedCompatibilityDialogOpenService");
                  vscAdvancedCompatibilityDialogOpenService.open(advancedCompatibilityContentsArr);
                  return;
               }
               var modalOptions = {
                  dialogData: {
                     compatibilityTitle: scope.compatibilityTitle,
                     contents: scope.contents
                  },
                  title: i18nService.getString('CommonModuleUi', 'compatibilityViewPopup.title'),
                  contentTemplate: 'common-module-ui/resources/common-module/views/compatibility/vxCompatibilityViewPopup.html',
                  hideSubmitButton: true,
                  defaultButton: 'close',
                  closeButtonTitle: i18nService.getString('Common', 'close.label')
               };

               if (typeof scope.onShowDetailsClick === 'function') {
                  $q.resolve(scope.onShowDetailsClick({ contents: scope.contents }))
                        .then(function() {
                           clarityModalService.openOkCancelModal(modalOptions);
                        });
               } else {
                  clarityModalService.openOkCancelModal(modalOptions);
               }
            };

            scope.$watchCollection(function() {
               return scope.contents;
            }, function(newContents, oldContents) {
               var issuesCount = countCompatibilityProblems(newContents);
               setCompatibilityMessages(issuesCount);
            });

            function countCompatibilityProblems(contents) {
               var issuesCount = {
                  errors: 0,
                  warnings: 0,
                  unknown: 0,
                  ok: 0
               };

               _.each(contents, function(contentItem) {
                  if (contentItem.icon === 'vsphere-icon-status-error'
                        || contentItem.icon === 'vui-icon-datagrid-status-red') {
                     issuesCount.errors++;
                  } else if (contentItem.icon === 'vsphere-icon-status-warning'
                        || contentItem.icon === 'vui-icon-datagrid-status-yellow') {
                     issuesCount.warnings++;
                  } else if (contentItem.icon === 'vsphere-icon-status-unknown'
                        || contentItem.icon === 'vui-icon-datagrid-status-unknown') {
                     issuesCount.unknown++;
                  } else if (contentItem.icon === 'vsphere-icon-status-ok'
                        || contentItem.icon === 'vui-icon-datagrid-status-green') {
                     issuesCount.ok++;
                  }
                  if (!_.isEmpty(contentItem.contents)) {
                     var childIssueCount = countCompatibilityProblems(contentItem.contents);
                     issuesCount = sum(issuesCount, childIssueCount);
                  }
               });

               return issuesCount;
            }

            function sum(issues, additionalIssues) {
               return _.mapObject(issues, function(val, key) {
                  return val + additionalIssues[key];
               });
            }

            function setCompatibilityMessages(issuesCount) {
               scope.issuesSummaryMessage = {
                  message: '',
                  icon: ''
               };

               scope.issuesLength = issuesCount.errors +
                     issuesCount.warnings + issuesCount.unknown;

               if (scope.issuesLength > 1) {
                  if (issuesCount.errors > 0) {
                     scope.issuesSummaryMessage.message = i18nService.getString("CommonModuleUi",
                           "compatibilityView.errorStateDefaultMessage");
                     scope.issuesSummaryMessage.icon = 'vsphere-icon-status-error';
                  } else if (issuesCount.warnings > 0) {
                     scope.issuesSummaryMessage.message = i18nService.getString("CommonModuleUi",
                           "compatibilityView.warningStateDefaultMessage");
                     scope.issuesSummaryMessage.icon = 'vsphere-icon-status-warning';
                  } else if (issuesCount.unknown > 0) {
                     scope.issuesSummaryMessage.message = i18nService.getString("CommonModuleUi",
                           "compatibilityView.infoStateDefaultMessage");
                     scope.issuesSummaryMessage.icon = 'vsphere-icon-status-unknown';
                  }
               }
            }
         }
      };
   }
})();
