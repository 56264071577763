(function() {
   'use strict';
   angular.module('com.vmware.vsphere.client.cluster')
         .controller('HeartbeatDatastoresPageController', HeartbeatDatastoresPageController);

   HeartbeatDatastoresPageController.$inject = ['$scope', 'i18nService',
      'heartbeatDatastoresService'];

   function HeartbeatDatastoresPageController($scope, i18nService,
         heartbeatDatastoresService) {
      var isAutoSelectPolicy = false;
      var self = this;

      self.datastoresGridLabel =
            getString('ha.config.ds.preferred.heartbeat.datastores');

      var haManager = $scope.modalOptions.dialogData.manager;
      self.pageModel = haManager.getHeartbeatDatastoresModel();

      self.hbDatastoreDescLabel = self.pageModel.hbDatastoresPerHost === 1 ?
            i18nService.getString(
                  'ClusterUi',
                  'ha.config.ds.heartbeat.hint.single',
                  self.pageModel.hbDatastoresPerHost) :
            i18nService.getString(
                  'ClusterUi',
                  'ha.config.ds.heartbeat.hint.multiple',
                  self.pageModel.hbDatastoresPerHost);
      self.hbDatestoreCandidatePolicyLabel = getString('ha.config.ds.heartbeat.buttons.header');
      self.hbDatastoreCandidatePolicies =
            heartbeatDatastoresService.getCandidatePolicies();
      self.isHbDatastoresSelectionAllowed = isHbDatastoresSelectionAllowed;

      $scope.$watch('ctrl.pageModel.hbDatastoreCandidatePolicy',
            function(hbDsCandidatePolicy) {
               isAutoSelectPolicy =
                     !heartbeatDatastoresService.isAutoSelectPolicy(hbDsCandidatePolicy);
            });

      function isHbDatastoresSelectionAllowed() {
         return isAutoSelectPolicy;
      }

      function getString(key) {
         return i18nService.getString('ClusterUi', key);
      }
   }
})();
