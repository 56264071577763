namespace h5_vm {
   import VmProvisioningWizardPage = h5_vm.VmProvisioningWizardPage;

   export class CloneVmBasicInformationPageService implements VmProvisioningPageService {

      static $inject: string[] = [
         "i18nService",
         "vuiConstants",
         "wizardPageService",
         "vmVuiWizardPageBuilder",
         "cloneVmBasicInformationPageModel"
      ];

      constructor(private i18nService: any,
                  private vuiConstants: any,
                  private wizardPageService: any,
                  private vmVuiWizardPageBuilder: any,
                  private cloneVmBasicInformationPageModel: any) { }

      public build(wizardScope: any,
                   defaultTarget?: any,
                   preselectedComputeResource?: any,
                   preselectedStorage?: any): VmProvisioningWizardPage {

         let pageModel: any = new this.cloneVmBasicInformationPageModel(wizardScope.wizardViewData,
               wizardScope.vmParams, null, wizardScope.config);

         wizardScope.cloneVmBasicInformationPageModel = pageModel;

         return this.vmVuiWizardPageBuilder.buildVuiWizardPage(wizardScope.config, {
            title: this.i18nService.getString("ProvisioningUiLib", "BasicInformationPage.title"),
            contentUrl: "vm-ui/resources/vm/views/clone/clone-vm-basic-information.html",
            model: pageModel,
            defaultState: this.vuiConstants.wizard.pageState.INCOMPLETE,
         });
      }
   }

   angular.module("com.vmware.vsphere.client.vm")
         .service("cloneVmBasicInformationPageService", CloneVmBasicInformationPageService);
}
