/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
(function () {
   'use strict';
   angular.module('com.vmware.vsphere.client.storage')
         .controller('VmfsNameAndDevicePageController', [
            '$scope',
            'i18nService',
            '$q',
            'datastoreConstants',
            'wizardPageService',
            'storageUtil',
            function ($scope, i18nService, $q, datastoreConstants, wizardPageService, storageUtil) {
               var self = this;

               self.getString = i18nService.getString;
               self.datastoreName = '';
               self.showHostSelector = !$scope.commonWizardManager.isHostContextObject();

               var selectHostPlaceHolder = {
                  formattedName: self.getString('StorageUi',
                        'addDatastoreWizard.vmfsSelectDevicePage.selectHostItem'),
                  isPlaceholderOptionItem: true
               };

               self.selectedHost = selectHostPlaceHolder;

               var pagePromises = [];

               if ($scope.commonWizardManager.getDatastoreName()) {
                  self.datastoreName = $scope.commonWizardManager.getDatastoreName();
               } else {
                  pagePromises.push(
                        $scope.commonWizardManager.getUniqueDatastoreName().then(function (uniqueName) {
                           self.datastoreName = uniqueName;
                           $scope.commonWizardManager.setDatastoreName(uniqueName);
                        }));
               }

               if (self.showHostSelector) {
                  pagePromises.push(
                        $scope.commonWizardManager.getAvailableHosts().then(function(availableHosts) {
                           var availableHostItems = availableHosts.slice();
                           availableHostItems.splice(0, 0, selectHostPlaceHolder);
                           self.availableHosts = availableHostItems;

                           if ($scope.vmfsWizardManager.getSelectedHostId()) {
                              self.selectedHost = _.find(availableHostItems,
                                    function (hostItem) {
                                       return hostItem.hostId ===
                                             $scope.vmfsWizardManager.getSelectedHostId();
                                    });
                              requestDiskInfos(self.selectedHost);
                           }
                        })
                  );
               } else {
                  pagePromises.push(
                        $scope.vmfsWizardManager.requestAvailableScsiDiskInfos().then(function (diskInfos) {
                           self.availableDiskInfos = diskInfos;
                        })
                  );

                  pagePromises.push(
                     $scope.commonWizardManager.getAvailableHosts().then(function(availableHosts) {
                        if (availableHosts && availableHosts.length) {
                           $scope.vmfsWizardManager.updateVmfsVersionPageVisibility(availableHosts[0]);
                           $scope.vmfsWizardManager.setIsVmfs6Supported(availableHosts[0].isVMFS6Supported);
                        }
                     })
                  );
               }

               self.selectedDiskInfo = $scope.vmfsWizardManager.getSelectedScsiDiskInfo();

               // Show loading indicator while retrieving data
               $scope.wizardConfig.loading = true;
               $q.all(pagePromises).then(function () {
                  $scope.wizardConfig.loading = false;
               }, $scope.removeLoadingAndShowError);

               self.onSelectedDeviceChanged = function(selectedDevice) {
                  if ($scope.vmfsWizardManager.getSelectedScsiDiskInfo() !== selectedDevice) {
                     $scope.vmfsWizardManager.setSelectedScsiDiskInfo(selectedDevice);
                     self.selectedDiskInfo = $scope.vmfsWizardManager.getSelectedScsiDiskInfo();

                     wizardPageService.clearErrors($scope.wizardConfig);

                     if (self.selectedDiskInfo && storageUtil.is4kDevice(self.selectedDiskInfo.disk)) {
                        if(!$scope.vmfsWizardManager.getIsVmfs6Supported()) {
                           wizardPageService.markPageIncompleteWithError($scope.wizardConfig,
                              i18nService.getString('StorageUi', "datastoreWizards.selectDevicePage.incompatibleSelection"));
                        } else {
                           $scope.vmfsWizardManager.skipVmfsVersionPage(true);
                           $scope.vmfsWizardManager.setSelectedVmfsVersion(datastoreConstants.vmfsVersions.VMFS_6);
                        }
                     } else {
                        $scope.vmfsWizardManager.updateVmfsVersionPageVisibility({isVMFS6Supported: $scope.vmfsWizardManager.getIsVmfs6Supported()});
                     }
                  }
               };

               self.getFormattedNameWithPrivilege = function(hostObj) {
                  if (hostObj.isHostConnected && !hostObj.hasConfigStoragePrivilege && !hostObj.isPlaceholderOptionItem) {
                     return i18nService.getString('StorageUi', 'addDatastoreWizard.notEnoughPrivileges', hostObj.formattedName);
                  }
                  return hostObj.formattedName;
               };

               $scope.$watch(
                     function () {
                        return self.datastoreName;
                     },
                     function (datastoreName) {
                        $scope.commonWizardManager.setDatastoreName(datastoreName);
                     }
               );

               $scope.$watch(
                     function() {
                        return self.selectedHost;
                     }, function(newHostDataItem, oldHostDataItem) {
                        if (newHostDataItem !== oldHostDataItem) {

                           $scope.vmfsWizardManager.setSelectedScsiDiskInfo(null);
                           $scope.vmfsWizardManager.updateVmfsVersionPageVisibility(newHostDataItem);
                           self.availableDiskInfos = [];

                           if (!newHostDataItem || newHostDataItem.isPlaceholderOptionItem) {
                              $scope.vmfsWizardManager.setSelectedHostId(null);
                              $scope.vmfsWizardManager.setIsVmfs6Supported(false);
                           } else {
                              $scope.vmfsWizardManager.setSelectedHostId(
                                    newHostDataItem.hostId);
                              $scope.vmfsWizardManager.setIsVmfs6Supported(newHostDataItem.isVMFS6Supported);
                              requestDiskInfos(newHostDataItem);
                           }
                     }
                  });

               function requestDiskInfos(hostDataItem) {
                  if (hostDataItem && hostDataItem.isHostConnected && hostDataItem.hasConfigStoragePrivilege) {
                     self.availableDiskInfos = [];
                     $scope.wizardConfig.loading = true;
                     $scope.vmfsWizardManager.requestAvailableScsiDiskInfos().then(
                        function (diskInfos) {
                           $scope.wizardConfig.loading = false;
                           self.availableDiskInfos = diskInfos;
                        }, $scope.removeLoadingAndShowError);
                  }
               }
            }
         ]);
})();
