/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
(function() {
   'use strict';
   angular
      .module('com.vmware.vsphere.client.host')
      .controller('RebootConfirmationController', RebootConfirmationController);

   RebootConfirmationController.$inject = ['$scope', 'confirmationDialogWithReasonService', 'dataService', 'i18nService'];

   function RebootConfirmationController(scope, dialogService, dataService, i18nService) {
      var self = this;
      var hostIds = scope.modalOptions.dialogData.availableTargets;

      scope.modalOptions.onSubmit = dialogService.onOKClicked.bind(this, getReason, getOnlyMmHosts, 'reboot',
          scope.modalOptions, hostIds, getMmHosts);

      self.loading = true;
      self.inMaintenanceMode = true;
      self.onlyMmHosts = false;

      if (hostIds.length > 1) {
         self.multipleHosts = true;
         self.onlyMmHosts = true;
         self.hostsNotInMm = [];
         self.hostsInMm = [];
         self.selectedHosts = [];
         dialogService.fetchHostsRebootData(hostIds, updateMultipleControllerCallback);
         scope.modalOptions.size = 'lg';
         self.showGuardRail = true;
      } else {
         self.hostId = hostIds[0];
         dataService.getData(self.hostId,
               'com.vmware.vsphere.client.h5.host.model.HostRebootData')
               .then(function(result) {
                  if (result) {
                     updateControllerCallback(result);
                  }
               });
      }


      function updateControllerCallback(result) {
         self.hostName = result.hostName;
         self.clusterMor = result.clusterMor;
         self.isVsanSupportedAndEnabled = !!result.isVsanSupportedAndEnabled;
         self.inMaintenanceMode = result.inMaintenanceMode;
         // resize the dialog due to large dialog content
         if (self.isVsanSupportedAndEnabled) {
            scope.modalOptions.size = 'lg';
         }

         self.showGuardRail = !self.inMaintenanceMode;
         self.loading = false;
      }

      function updateMultipleControllerCallback(hostName, inMaintenanceMode, vSanEnabled,
            hostId, loading) {
         self.isVsanSupportedAndEnabled = self.isVsanSupportedAndEnabled
               ? self.isVsanSupportedAndEnabled : vSanEnabled;
         if (!inMaintenanceMode) {
            self.inMaintenanceMode = inMaintenanceMode;
            self.hostsNotInMm.push(hostName);
            self.selectedHosts.push({
               label: i18nService.getString('HostUi', 'rebootHostNotInMaintenanceMode', hostName),
               iconClass: 'vsphere-icon-host'
            });
         } else {
            self.hostsInMm.push(hostId);
            self.selectedHosts.push({
               label: hostName,
               iconClass: 'vsphere-icon-host-maintenance'
            });
         }

         self.loading = loading;
      }

      function getReason() {
         return self.rebootReason;
      }

      function getOnlyMmHosts() {
         return self.onlyMmHosts;
      }

      function getMmHosts() {
         return self.hostsInMm;
      }
   }
})();
