(function() {
   'use strict';

   /**
    * Vnic related constants.
    */
   angular
         .module('com.vmware.vsphere.client.network')
         .constant('vnicConstants', {

            /**
             * Map <hostVnicServiceConstants.keys, VirtualNicManager.NicType>.
             *
             * @see hostVnicServiceConstants.keys
             */
            serviceKeyToNicTypeMap: {
               isVmotionEnabled: 'vmotion',
               isProvisioningNfcTrafficEnabled: 'vSphereProvisioning',
               isFtEnabled: 'faultToleranceLogging',
               isHaEnabled: 'management',
               isVRTrafficEnabled: 'vSphereReplication',
               isVrNfcTrafficEnabled: 'vSphereReplicationNFC',
               isVsanEnabled: 'vsan'
            },
            /**
             * Values of the IPv4 options shown on Edit vnic setting dialog.
             *
             * @see com.vmware.vsphere.client.h5.network.host.vnic.model.edit.IpSettingsOption
             */
            ipSettingsOption: {
               unset: 'unset',
               dhcp: 'dhcp',
               staticIp: 'staticIp'
            },
            /**
             * Values used in vnic ipv6 edit operations.
             */
            changeOperation: {
               add: 'add',
               remove: 'remove'
            }
         });
})();
