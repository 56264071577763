namespace storage_ui {

   import IScope = angular.IScope;
   import NetworkSettingsItem = com.vmware.vsphere.client.h5.storage.data.adapters.NetworkSettingsItem;

   class StorageAdaptersNetworkSettingsController {
      private readonly NETWORK_SETTINGS_PROPERTY = "storageadapter:networkSettings";
      private loading: boolean;
      private i18n: Function;
      private networkSettings: any;
      static $inject = [
         "dataService",
         "i18nService",
         "navigation",
         "$scope",
         "vxPropertyViewService",
         "hostStorageConstants",
         "hostConnectionState",
         "storageAdapterEditNetworkSettingsDialogService"
      ];

      constructor(private dataService: any,
                  private i18nService: any,
                  private navigation: any,
                  private $scope: any,
                  private propertyViewService: any,
                  private hostStorageConstants: any,
                  private hostConnectionState: any,
                  private modalService: StorageAdapterEditNetworkSettingsDialogService) {
         this.i18n = i18nService;
         let release = $scope.$watch(
               ()=>this.$scope.masterDetailsViewContext.selectedItem,
               this.loadData
         );
         $scope.$on("$destroy", ()=> {
            release();
         });
      }

      isHostAvailable = ()=> {
         let hostId = this.navigation.getRoute().objectId;
         let hostConnectionState = this.hostConnectionState;
         return this.dataService.getProperties(hostId, ["runtime"]).then(function (data: any) {
            let runtime = data.runtime;
            return runtime.connectionState === hostConnectionState.CONNECTED && !runtime.inMaintenanceMode;
         });
      };

      loadData = ()=> {
         let self = this;
         let hostId = this.navigation.getRoute().objectId;
         let adapterKey = this.$scope.masterDetailsViewContext.selectedItem.key;
         this.dataService.getProperties(hostId, [this.NETWORK_SETTINGS_PROPERTY], {
            propertyParams: [{
               propertyName: this.NETWORK_SETTINGS_PROPERTY,
               parameterType: "java.lang.String",
               parameter: adapterKey
            }]
         })
               .then((response:any) => {
                  if (response[this.NETWORK_SETTINGS_PROPERTY]) {
                     self.buildPropertyView(response[this.NETWORK_SETTINGS_PROPERTY]);
                  }
               })
               .finally(() => {
                  self.loading = false;
               });
      };

      private buildPropertyView(model: NetworkSettingsItem): void {
         let self = this;
         let adapter = this.$scope.masterDetailsViewContext.selectedItem;
         let builder = this.propertyViewService.createPropertyViewBuilder();
         let i18n = (key:string)=>this.i18nService.getString("StorageUi", key);

         let notSupported = i18n("storage.adapters.properties.notSupportedLabel");
         let notEnabled = i18n("storage.adapters.properties.notEnabledLabel");

         let section = builder.category("category").section("general")
               .title(i18n("storage.adapters.properties.ipDnsConfig.sectionHeader"));

         if (adapter.type === this.hostStorageConstants.hostAdapterTypes.ISCSI) {
            this.isHostAvailable().then((isHostAvailable:boolean)=> {
               section
                     .action({
                        title: this.i18nService.getString("StorageUi", "generalEditAction.label"),
                        isEnabled: isHostAvailable,
                        clickHandler: function () {
                           let hostId = self.navigation.getRoute().objectId;
                           self.modalService.open(hostId, adapter, model);
                        }
                     });
            });
         }
         // IPv4
         if (!model.isIpv4Supported) {
            section.property(
                  i18n("storage.adapters.properties.ipDnsConfig.ipAddress"), notSupported);
         } else if (!model.isIpv4Enabled) {
            section.property(i18n("storage.adapters.properties.ipDnsConfig.ipAddress"), notEnabled);
         } else {
            section.property(
                  i18n("storage.adapters.properties.ipDnsConfig.ipAddress"), model.ipv4Address)
                  .property(
                        i18n("storage.adapters.properties.ipDnsConfig.subnetMask"),
                        model.ipv4SubnetMask)
                  .property(
                        i18n("storage.adapters.properties.ipDnsConfig.defaultGateway"),
                        model.ipv4DefaultGateway
                  );
         }

         // IPv6
         if (!model.isIpv6Supported) {
            section.property(
                  i18n("storage.adapters.properties.ipDnsConfig.ipV6Address"),
                  notSupported);
         } else if (!model.isIpv6Enabled) {
            section.property(
                  i18n("storage.adapters.properties.ipDnsConfig.ipV6Address"),
                  notEnabled);
         } else {
            section.property(
                  i18n("storage.adapters.properties.ipDnsConfig.ipV6Address"),
                  model.ipv6Address
            );
            section.property(
                  i18n("storage.adapters.properties.ipDnsConfig.defaultGatewayV6"),
                  model.ipv6DefaultGateway
            );
         }

         // DNS
         section.property(
               i18n("storage.adapters.properties.ipDnsConfig.preferredDns"),
               model.isPreferedDnsSupported ? model.preferedDns : notSupported
         ).property(
               i18n("storage.adapters.properties.ipDnsConfig.alternateDns"),
               model.isAlternateDnsSupported ? model.alternateDns : notSupported
         );
         this.networkSettings = builder.build();
      }

   }

   angular.module("com.vmware.vsphere.client.storage").controller(
         "StorageAdaptersNetworkSettingsController", StorageAdaptersNetworkSettingsController);

}
