(function () {
   angular.module('com.vmware.vsphere.client.vm').service('nodeCountService', NodeCountService);

   NodeCountService.$inject = ['deviceClassLineageService'];

   function NodeCountService(deviceClassLineageService) {
      return { getMaxNodeCountForControllerType: getMaxNodeCountForControllerType };

      function getMaxNodeCountForControllerType (controllerType, deviceOptions, deviceType) {
         var deviceToKeySuffix = {
            VirtualDisk: 'Disks',
            VirtualCdrom: 'Cdroms'
         };

         var controllerTypeOptions = _.find(deviceOptions, function (option) {
            return deviceClassLineageService.isClassNameSubclassOf(
               controllerType,
               option.type.typeClass
            );
         });

         var matcher = new RegExp("^num\\w+" + deviceToKeySuffix[deviceType] + "$");
         var numDiskKey = _.find(_.keys(controllerTypeOptions), function (key) {
            return key.match(matcher);
         });
         if (!numDiskKey) {
            throw new Error('Could not find max node count for ' + deviceType);
         }

         return controllerTypeOptions[numDiskKey].max;
      }
   }
})();
