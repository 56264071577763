module ds_cluster_ui {

   class StorageVmOverridesController {

      public watchForObjects: string[];
      public gridOptions: any;
      private objectId: string;
      private datagridActionBarServiceCacheObject: any;
      private actions: any;
      private liveRefreshProperties: Array<string>;
      private selectedOverrides: any;

      public static $inject = ["$scope", "storageVmOverridesService",
         "datagridActionBarService", "dsClusterConstants"];

      constructor($scope: any,
                  private storageVmOverridesService: StorageVmOverridesService,
                  private datagridActionBarService: any,
                  private dsClusterConstants: any) {

         this.objectId = $scope._route.objectId;
         this.watchForObjects = [this.objectId];
         this.liveRefreshProperties =
               [this.dsClusterConstants.liveRefreshProperties.STORAGE_DRS_CONFIG];
         this.gridOptions = storageVmOverridesService.getGridOptions();
         this.getViewData();
         this.actions = [{
            actionId: "vsphere.core.dscluster.actions.vmOverrides.add"
         }, {
            actionId: "vsphere.core.dscluster.actions.vmOverrides.edit",
            getActionInvocationContext: () => {
               return this.gridOptions.selectedItems;
            },
            isActionAvailable: (actionDef: any) => {
               return actionDef.available &&
                   this.gridOptions.selectedItems.length >= 1;
            }
         }, {
            actionId: "vsphere.core.dscluster.actions.vmoverrides.remove",
            getActionInvocationContext: () => {
               return this.gridOptions.selectedItems;
            },
            isActionAvailable: (actionDef: any) => {
               return actionDef.available &&
                     this.gridOptions.selectedItems.length > 0;
            }
         }];

         this.createActionBar();

         $scope.$watch(
             () => {
                return this.gridOptions.selectedItems;
             }, this.onSelectedVmOverrideChange.bind(this));

         $scope.overridesPreselectComparator =
               this.overridesPreselectComparator.bind(this);
      }

      public overridesPreselectComparator(override: any): boolean {
         return this.selectedOverrides && override &&
               _.some(this.selectedOverrides, (selected: any) => {
                  return selected.vm.name === override.vm.name;
               });
      }

      private getViewData(): void {
         this.storageVmOverridesService.getViewData(this.objectId).then((data: any) => {
            this.gridOptions.data = data;
         });
      }

      private onSelectedVmOverrideChange(newSelectedOverrides: any): void {
         if (!newSelectedOverrides || newSelectedOverrides.length === 0) {
            this.selectedOverrides = null;
         } else {
            this.selectedOverrides = newSelectedOverrides;
         }

         this.refreshActionBar();
      }

      private refreshActionBar(): void {
         if (!this.datagridActionBarServiceCacheObject) {
            return;
         }
         this.datagridActionBarService.updateActionBar(
               this.gridOptions,
               [this.objectId],
               this.actions,
               this.datagridActionBarServiceCacheObject
         );
      }

      private createActionBar(): void {
         this.datagridActionBarService
             .updateActionBar(this.gridOptions, [this.objectId], this.actions)
             .then((value: any) => {
                this.datagridActionBarServiceCacheObject = value;
             });
      }
   }

   angular.module("com.vmware.vsphere.client.dsCluster")
         .controller("StorageVmOverridesController", StorageVmOverridesController);
}
