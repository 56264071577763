angular.module('com.vmware.vsphere.client.vm').directive('vmHardwareSummaryMemory',
      ['$filter', 'i18nService', 'feedbackService', function ($filter, i18nService, feedbackService) {
   return {
      scope: {
         ctrl: '='
      },
      templateUrl: 'vm-ui/resources/vm/views/summary/vmHardwareSummaryMemory.html',
      link: function (scope) {
         scope.i18n = i18nService.getString;
         scope.fieldLabel = function (field) {
            return scope.i18n('VmUi', 'label' + field);
         };

         scope.isTemplate = scope.ctrl.hardwareViewData.memoryData.isVmTemplate;
         var isVmTemplateCLitem = scope.ctrl.isVmTemplateCLitem;

         scope.$watch('ctrl.hardwareViewData', function (data) {
            if (!data || !data.hardware) {
               return;
            }
            var bytesFilter = $filter('bytes');
            scope.memoryUtilizationPercentage = Math.round(data.memoryData.memoryUtilization / data.hardware.memoryMB * 100);
            if (scope.memoryUtilizationPercentage > 100) {
               scope.memoryUtilizationPercentage = 100;
            }
            var configuredMemory = scope.i18n('VmUi', 'memValue', data.hardware.memoryMB);
            var utilization = isVmTemplateCLitem ? "" :
               scope.i18n('VmUi', 'h5memValueUtilization', bytesFilter(data.hardware.memoryMB, 'MB', 'GB'),
                  bytesFilter(data.memoryData.memoryUtilization, 'MB', 'GB'));
            var shares = isVmTemplateCLitem ? data.memoryData.memoryAllocation.shares :
               scope.i18n('VmUi', 'sharesAndType', data.memoryData.memoryAllocation.shares.shares,
                  scope.i18n('VmUi', 'sharesInfoLevel.' + data.memoryData.memoryAllocation.shares.level));
            var reservation = scope.i18n('VmUi', 'memValue', data.memoryData.memoryAllocation.reservation);
            var limit = data.memoryData.memoryAllocation.limit < 0 ? scope.i18n('VmUi', 'labelUnlimited') :
               data.memoryData.memoryAllocation.limit;
            var hostOverhead = scope.i18n('VmUi', 'memValue', data.memoryData.overheadMemory);

            scope.fieldValue = {
               ConfiguredMemory: configuredMemory,
               Utilization: utilization,
               Shares: shares,
               Reservation: reservation,
               Limit: limit,
               HostOverhead: hostOverhead
            };

            scope.fields = _.keys(scope.fieldValue);
         });
      }
   };
}]);
