/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace dvs_ui {

   import DvsUpdateHealthCheckSpec = com.vmware.vsphere.client.dvs.api.spec.DvsUpdateHealthCheckSpec;
   import VmwareDistributedVirtualSwitch$ConfigSpec =
         com.vmware.vim.binding.vim.dvs.VmwareDistributedVirtualSwitch$ConfigSpec;
   import VmwareDistributedVirtualSwitch$VlanMtuHealthCheckConfig =
         com.vmware.vim.binding.vim.dvs.VmwareDistributedVirtualSwitch$VlanMtuHealthCheckConfig;
   import VmwareDistributedVirtualSwitch$TeamingHealthCheckConfig =
         com.vmware.vim.binding.vim.dvs.VmwareDistributedVirtualSwitch$TeamingHealthCheckConfig;
   import DistributedVirtualSwitch$HealthCheckConfig =
         com.vmware.vim.binding.vim.DistributedVirtualSwitch$HealthCheckConfig;

   export class DvsHealthCheckManageDialogModel {

      public vlanMtuEanbled: boolean;

      public teamingAndFailoverEanbled: boolean;

      public vlanMtuInterval: number;

      public teamingAndFailoverInterval: number;

      public constructor(private data: any) {
         this.vlanMtuEanbled = data.vlanMtuEnabled;
         this.teamingAndFailoverEanbled = data.teamingAndFailoverEnabled;
         this.vlanMtuInterval = data.vlanMtuEnabled ? data.vlanMtuInterval : 1;
         this.teamingAndFailoverInterval = data.teamingAndFailoverEnabled ?
               data.teamingAndFailoverInterval : 1;
      }

      public buildSpec(model: DvsHealthCheckManageDialogModel): DvsUpdateHealthCheckSpec | null {
         let dvsHealthCheckConfig: DistributedVirtualSwitch$HealthCheckConfig[] =
               this.getInitialConfig();

         let hasUpdates: boolean = false;

         for (let config of dvsHealthCheckConfig) {
            if (config instanceof VmwareDistributedVirtualSwitch$VlanMtuHealthCheckConfig &&
                  (config.enable !== model.vlanMtuEanbled ||
                  config.interval !== model.vlanMtuInterval)) {
               let index: number = dvsHealthCheckConfig.indexOf(config);
               dvsHealthCheckConfig[index].enable = model.vlanMtuEanbled;
               dvsHealthCheckConfig[index].interval =
                     dvsHealthCheckConfig[index].enable ? model.vlanMtuInterval : 0;
               hasUpdates = true;
            } else if (config instanceof VmwareDistributedVirtualSwitch$TeamingHealthCheckConfig &&
                  (config.enable !== model.teamingAndFailoverEanbled ||
                  config.interval !== model.teamingAndFailoverInterval)) {
               let index: number = dvsHealthCheckConfig.indexOf(config);
               dvsHealthCheckConfig[index].enable =
                     model.teamingAndFailoverEanbled;
               dvsHealthCheckConfig[index].interval =
                     dvsHealthCheckConfig[index].enable ?
                           model.teamingAndFailoverInterval : 0;
               hasUpdates = true;
            }
         }

         let spec: DvsUpdateHealthCheckSpec = new DvsUpdateHealthCheckSpec();
         spec.config = dvsHealthCheckConfig;

         return hasUpdates ? spec : null;
      }

      private getInitialConfig(): DistributedVirtualSwitch$HealthCheckConfig[] {

         let healthCheckConfig: DistributedVirtualSwitch$HealthCheckConfig[] = [];

         // VLAN and MTU config
         let vlanHealthCheckConfig: VmwareDistributedVirtualSwitch$VlanMtuHealthCheckConfig =
               new VmwareDistributedVirtualSwitch$VlanMtuHealthCheckConfig();
         vlanHealthCheckConfig.enable = this.vlanMtuEanbled;
         vlanHealthCheckConfig.interval = this.vlanMtuInterval;
         // Teaming and Fail config
         let teamingHealthCheckConfig: VmwareDistributedVirtualSwitch$TeamingHealthCheckConfig =
               new VmwareDistributedVirtualSwitch$TeamingHealthCheckConfig();
         teamingHealthCheckConfig.enable = this.teamingAndFailoverEanbled;
         teamingHealthCheckConfig.interval = this.teamingAndFailoverInterval;

         healthCheckConfig.push(vlanHealthCheckConfig);
         healthCheckConfig.push(teamingHealthCheckConfig);

         return healthCheckConfig;
      }
   }
}
