/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace h5_vm {
   import VirtualDisk = com.vmware.vim.binding.vim.vm.device.VirtualDisk;

   export class VmHardwareSummaryEncryption {
      public templateUrl: string;
      public controller: any;
      public controllerAs: string;
      public bindings: any;

      constructor() {
         this.templateUrl = "vm-ui/resources/vm/views/summary/vm-hardware-summary-encryption.html";
         this.controller = VmHardwareSummaryEncryptionController;
         this.bindings = {
            devices: "<",
         };
      }

   } // VmHardwareSummaryPmem

   class VmHardwareSummaryEncryptionController {
      public static $inject: string[] = [
         "i18nService",
         "vmCryptUtilService"
      ];

      // Input
      public devices: any;

      // Used in the view
      public label: string;
      public vmConfigContent: string;
      public disksContent: string;

      constructor(
         private i18nService: any,
         private vmCryptUtilService: VmCryptUtilService,
      ) {}

      public $onInit(): void {
         this.label = this.i18nService.getString("VmUi", "VmCryptConfig.encryptionLabel");
      }

      public $onChanges(changes: any): void {
         if (!changes.devices)  {
            return;
         }

         this.refreshContent();
      }

      private refreshContent(): void {
         this.vmConfigContent = this.i18nService.getString("VmUi", "VmCryptConfig.vmHomeEncrypted");

         let allDisksCount: number = this.devices[DeviceTypeConstants.VIRTUALDISK] ?
               this.devices[DeviceTypeConstants.VIRTUALDISK].length : 0;
         let encryptedDisksCount: number = this.getEncryptedDisksCount();
         if (encryptedDisksCount === 0) {
            this.disksContent =
                  this.i18nService.getString("VmUi", "VmCryptConfig.StatusDisksNone");
         } else if (allDisksCount === encryptedDisksCount) {
            if (allDisksCount === 1) {
               this.disksContent =
                     this.i18nService.getString("VmUi", "VmCryptConfig.StatusDisks1Encrypted");
            } else {
               this.disksContent =
                     this.i18nService.getString("VmUi", "VmCryptConfig.StatusDisksAll", allDisksCount);
            }
         } else {
            this.disksContent =
                  this.i18nService.getString("VmUi", "VmCryptConfig.StatusDisksSome",
                        encryptedDisksCount, allDisksCount);
         }
      }

      private getEncryptedDisksCount(): number {
         return _.filter(this.devices[DeviceTypeConstants.VIRTUALDISK], (device) => {
            return this.vmCryptUtilService.isExistingDiskEncrytped(device);
         }).length;
      }

   } // VmHardwareSummaryEncryptionmController

   angular.module("com.vmware.vsphere.client.vm").component(
         "vmHardwareSummaryEncryption",
         new VmHardwareSummaryEncryption());
}
