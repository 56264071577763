module ds_cluster_ui {

   import IController = angular.IController;
   import IScope = angular.IScope;

   class VmOverridesServicesComponentController implements IController {
      private STORAGE_POD_AUTOMATION_LEVEL_PROPERTY: string =
            "podStorageDrsEntry/storageDrsConfig/podConfig/defaultVmBehavior";
      private DEFAULT_INTRA_VM_AFFINITY_PROPERTY: string =
            "podStorageDrsEntry/storageDrsConfig/podConfig/defaultIntraVmAffinity";

      static $inject = ["$rootScope", "$scope", "addVmOverridesPageService",
         "addSdrsVmOverridesService", "dataService", "i18nService", "dsClusterConstants"];

      private sdrsAutomationLevel: boolean;
      private storagePodSdrsAutomationText: string;
      private sdrsAutomationLevels: any;
      private objectId: string;
      private selectedVms: any;
      private i18n: Function;
      private sdrsAutomationSectionTitle: string;
      private sdrsAutomationLabel: string;
      private sdrsDefaultValue: string;

      private keepVdmkTogetherLabel: string;
      private keepVdmkTogetherText: string;
      private keepVdmkTogetherValuesMap: any;
      private keepVmdkTogetherDefaultValue: any;
      private spec: SdrsVmOverrideSpec;
      private areVmsWithEqualIntraVmAffinityValues: boolean;
      private areVmsWithEqualSdrsAutomationValues: boolean;
      private sdrsAutomationLevelSignPostParams: any;
      private intraVmAffinityLevelSignPostParams: any;
      private multipleEditWarningMessage: string;


      constructor(private $rootScope: any,
                  private $scope: any,
                  private addVmOverridesPageService: AddVmOverridesPageService,
                  private addSdrsVmOverridesService: AddSdrsVmOverridesService,
                  private dataService: any,
                  private i18nService: any,
                  private dsClusterConstants: any) {

         this.i18n = i18nService.getString;
         this.objectId = $rootScope._route.objectId;
         this.sdrsAutomationSectionTitle = this.i18nService.getString("DsClusterUi",
               "vmOverrides.services.sdrs");
         this.sdrsAutomationLabel = this.i18nService.getString("DsClusterUi",
               "vmOverrides.sdrsAutomationLevelHeaderText");
         this.sdrsAutomationLevel = false;
         this.storagePodSdrsAutomationText = "";
         this.sdrsAutomationLevels =
               this.addVmOverridesPageService.createAutomationLevelsMap();
         this.keepVdmkTogetherValuesMap =
               this.addVmOverridesPageService.createKeepVdmkTogetherValuesMap();
         this.keepVdmkTogetherLabel = this.i18nService.getString("DsClusterUi",
               "vmOverrides.intraVmAffinityHeaderText");
         this.areVmsWithEqualIntraVmAffinityValues = true;
         this.areVmsWithEqualSdrsAutomationValues = true;
         this.multipleEditWarningMessage = '';
      }

      $onInit(): void {
         this.getViewData();
      }

      private updateSdrsAutomationLevelValue(): void {
         if (!this.spec.sdrsAutomationLevel) {
            this.spec.sdrsAutomationLevelValue = this.sdrsDefaultValue;
         }
      }

      private updateVdmkTogetherlValue(): void {
         if (!this.spec.keepVmdksChecked) {
            this.spec.keepVmdksValue = this.keepVmdkTogetherDefaultValue;
         }
      }

      private editPreselectionOfUiElements(): void {

         if (this.selectedVms && this.selectedVms.length > 0 && this.selectedVms[0].rawData) {
            this.setIntraVmAffinity();
            this.setSdrsAutomationLevel();
         }
      }

      private setSdrsAutomationLevel() {
         if (!this.areVmsWithEqualSdrsAutomationValues) {
            this.sdrsAutomationLevels = _.extend({
               useInitialValues: {
                  id: "useInitialValues",
                  label: this.i18nService.getString("DsClusterUi",
                        "vmOverrides.config.editMultiple.useInitialValues"),
                  value: this.dsClusterConstants.sdrsVmOverridesAutomationLevels.useInitialValues
               }
            }, this.sdrsAutomationLevels);
            this.spec.sdrsAutomationLevelValue =
                  this.dsClusterConstants.sdrsVmOverridesAutomationLevels.useInitialValues;
            this.spec.sdrsAutomationLevel = true;
         } else {
            let sdrsAutomationLevelForVm =
                  this.addVmOverridesPageService.getSdrsAutomationLevelForVm(
                        this.selectedVms[0]);
            this.spec.sdrsAutomationLevel = sdrsAutomationLevelForVm.sdrsAutomationLevel;

            this.spec.sdrsAutomationLevelValue =
                  sdrsAutomationLevelForVm.sdrsAutomationLevelValue;

         }
      }

      private setIntraVmAffinity() {

         if (this.areVmsWithEqualIntraVmAffinityValues) {

            let intraVmAffinityForVm =
                  this.addVmOverridesPageService.getIntraVmAffinityValueForAVm(
                        this.selectedVms[0]);

            this.spec.keepVmdksChecked = intraVmAffinityForVm.keepVmdksChecked;
            this.spec.keepVmdksValue = intraVmAffinityForVm.keepVmdksValue;

         } else {
            this.keepVdmkTogetherValuesMap = _.extend({
               useInitialValues: {
                  id: "useInitialValues",
                  label: this.i18nService.getString("DsClusterUi",
                        "vmOverrides.config.editMultiple.useInitialValues"),
                  value: this.dsClusterConstants.keepVmdksValues.useInitialValues
               }
            }, this.keepVdmkTogetherValuesMap);
            this.spec.keepVmdksValue =
                  this.dsClusterConstants.keepVmdksValues.useInitialValues;
         }

      }

      private initAddSdrsVmOverridesData(response: any): void {
         let automationLevelKey: string =
               response[this.STORAGE_POD_AUTOMATION_LEVEL_PROPERTY];

         let automationLevel: string =
               this.sdrsAutomationLevels[automationLevelKey].label;

         this.sdrsDefaultValue = this.sdrsAutomationLevels[automationLevelKey].id;
         this.spec.sdrsAutomationLevelValue = this.sdrsDefaultValue;
         this.spec.sdrsAutomationLevelDefaultValue = this.sdrsDefaultValue;
         this.keepVmdkTogetherDefaultValue = response[this.DEFAULT_INTRA_VM_AFFINITY_PROPERTY] ?
               this.keepVdmkTogetherValuesMap.keepVmdksTogether.value :
               this.keepVdmkTogetherValuesMap.doNotKeepVmdksTogether.value;
         this.spec.keepVmdksValue = this.keepVmdkTogetherDefaultValue;
         this.spec.keepVmdksDefaultValue = this.keepVmdkTogetherDefaultValue;
         this.keepVdmkTogetherText =
               response[this.DEFAULT_INTRA_VM_AFFINITY_PROPERTY] ?
                     this.i18nService.getString("Common", "yes.label") :
                     this.i18nService.getString("Common", "no.label");

         this.storagePodSdrsAutomationText = automationLevel;
      }


      private initValidationResultsForEditMultipleVms(): void {
         let identityResults: any =
               this.addSdrsVmOverridesService.getEditMultipleVmOverridesIdentityResults(
                     this.selectedVms
               );
         this.areVmsWithEqualIntraVmAffinityValues =
               identityResults.vmsAreWithEqualIntraVmAffinity;
         this.areVmsWithEqualSdrsAutomationValues =
               identityResults.vmsAreWithEqualSdrsAutomationLevel;

         let differentVmOverridesCount = 0;
         if (!this.areVmsWithEqualIntraVmAffinityValues) {
            this.spec.keepVmdksChecked = true;
            this.intraVmAffinityLevelSignPostParams =
                  this.addSdrsVmOverridesService.buildVuiSignPostIntraVmAffinityLevel(this.selectedVms);
            differentVmOverridesCount++;
         }

         if (!this.areVmsWithEqualSdrsAutomationValues) {
            this.sdrsAutomationLevelSignPostParams =
                  this.addSdrsVmOverridesService.buildVuiSignPostSdrsAutomationLevel(this.selectedVms);
            differentVmOverridesCount++;
         }

         if (differentVmOverridesCount > 0) {
            let countAsString: string = differentVmOverridesCount === 1 ?
                  this.i18nService.getString(
                        "DsClusterUi",
                        "vmOverrides.config.editMultiple.warningMessage.one"
                  ) :
                  this.i18nService.getString(
                        "DsClusterUi",
                        "vmOverrides.config.editMultiple.warningMessage.two"
                  );
            this.multipleEditWarningMessage =
                  this.i18nService.getString(
                        "DsClusterUi",
                        "vmOverrides.config.editMultiple.warningMessage",
                        countAsString
                  );
         }

      }

      private getViewData(): void {
         this.dataService.getProperties(this.objectId,
               [this.STORAGE_POD_AUTOMATION_LEVEL_PROPERTY,
                  this.DEFAULT_INTRA_VM_AFFINITY_PROPERTY])
               .then((response: any) => {
                  this.initAddSdrsVmOverridesData(response);
                  if (this.spec.mode === this.dsClusterConstants.vmOverridesOperationType.EDIT) {
                     this.initValidationResultsForEditMultipleVms();
                     this.editPreselectionOfUiElements();
                  }
               });
      }
   }

   class VmOverridesServicesComponent implements ng.IComponentOptions {
      public controller: any;
      public templateUrl: string;
      public bindings: any;

      constructor() {
         this.controller = VmOverridesServicesComponentController;
         this.templateUrl =
               "ds-cluster-ui/resources/ds-cluster/views/settings/configuration/" +
               "sdrsVmOverrides/vmOverridesServicesComponent.html";
         this.bindings = {
            spec: "=",
            selectedVms: "="
         };
      }
   }

   angular.module("com.vmware.vsphere.client.dsCluster").component(
         "vmOverridesServicesComponent", new VmOverridesServicesComponent());
}
