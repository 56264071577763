module common_module_ui {

    export class DrsRecommendationsService {

        public createRecommendationsStore(
              recommendationsData: any[]): RecommendationsStore {
            return new RecommendationsStore(
                  recommendationsData);
        }
    }

    export class RecommendationsStore {

        private selectableActionsByKey: _.Dictionary<any>;

        private checkedRecommendations: _.Dictionary<boolean>;

        private selectableActionsCount: number;

        constructor(private recommendations: any[]) {
            let selectableActions = recommendations.filter(
                  function(item) {
                      return item.isFirst;
                  });

            this.selectableActionsByKey = _.indexBy(selectableActions, function(
                  action) {
                return action.parentRecommendation.key;
            });

            this.checkedRecommendations = {};
            selectableActions.forEach((action) => {
                this.checkedRecommendations[action.parentRecommendation.key] = true;
            });

            this.selectableActionsCount = selectableActions.length;
        }

        getRecommendationsToApply(): string[] {
            let checkedRecommendationKeys: string[] = [];

            this.recommendations.forEach((action) => {
                if (action.isFirst === true) {
                    let recKey = action.parentRecommendation.key;
                    if (this.checkedRecommendations[recKey] === true) {
                        checkedRecommendationKeys.push(recKey);
                    }
                }
            });

            // Remove prerequisite recommendations from the list
            let recommendationKeysToApply = checkedRecommendationKeys.slice();
            checkedRecommendationKeys.forEach((checkedKey) => {
                let prerequisites = this.selectableActionsByKey[checkedKey]
                      .prerequisiteRecommendations;
                recommendationKeysToApply = recommendationKeysToApply.filter(
                      (keyToApply) => {
                          return prerequisites.indexOf(keyToApply) === -1;
                      });
            });

            return recommendationKeysToApply;
        }

        getCheckedRecommendationCount(): number {
           let checkedNumber = _.filter(this.checkedRecommendations, (recommendation) => {
              return recommendation === true;
           });
           return checkedNumber.length;
        }

        getSelectableActionsCount(): number {
            return this.selectableActionsCount;
        }

        isRecommendationChecked(key: string): boolean {
            return this.checkedRecommendations[key] === true;
        }

       uncheckRecommendation(key: string): boolean {
          let shouldShowNotification = false;
          this.checkedRecommendations[key] = false;
          let action = this.selectableActionsByKey[key];
          action.dependantRecommendations.forEach((dependantKey: string) => {
             if (this.checkedRecommendations[dependantKey] === true) {
                this.checkedRecommendations[dependantKey] = false;
                shouldShowNotification = true;
             }
          });
          return shouldShowNotification;
       }

       checkRecommendation(key: string): boolean {
          let shouldShowNotification: boolean = false;
          this.checkedRecommendations[key] = true;
          let action = this.selectableActionsByKey[key];
          action.prerequisiteRecommendations.forEach((prerequisiteKey: string) => {
             if (this.checkedRecommendations[prerequisiteKey] === false) {
                this.checkedRecommendations[prerequisiteKey] = true;
                shouldShowNotification = true;
             }
            });
            return shouldShowNotification;
        }

        getRecommendationKeys(): string[] {
            return this.recommendations.map((rec) => rec.parentRecommendation.key);
        }
    }

    angular.module("com.vmware.vsphere.client.commonModule")
          .service("drsRecommendationsService", DrsRecommendationsService);
}
