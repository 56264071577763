(function() {
   'use strict';

   angular.module('com.vmware.vsphere.client.vm')
         .service('creationTypeServiceFactory', creationTypeServiceFactory);

   creationTypeServiceFactory.$inject = [
      'CloneVmToVmService',
      'creationTypeConstants',
      'CloneVmToTemplateService',
      'CreateVmFromScratchService',
      'convertTemplateToVmService',
      'DeployVmFromOvfService',
      'registerVmService',
      'deployVmFromTemplateService',
      'cloneVmToLibraryService',
      'cloneVappToLibraryService'
   ];

   function creationTypeServiceFactory(CloneVmToVmService,
         creationTypeConstants,
         CloneVmToTemplateService,
         CreateVmFromScratchService,
         convertTemplateToVmService,
         DeployVmFromOvfService,
         registerVmService,
         deployVmFromTemplateService,
         cloneVmToLibraryService,
         cloneVappToLibraryService) {
      var service = {};
      service.serviceForCreationType = serviceForCreationType;

      return service;

      function serviceForCreationType(creationType) {
         switch(creationType) {
            case creationTypeConstants.CREATE_FROM_SCRATCH:
               return new CreateVmFromScratchService();
            case creationTypeConstants.DEPLOY_VM_FROM_OVF:
               return new DeployVmFromOvfService();
            case creationTypeConstants.DEPLOY_VM_FROM_VAPP:
            case creationTypeConstants.DEPLOY_VM_FROM_TEMPLATE:
               return deployVmFromTemplateService;
            case creationTypeConstants.CLONE_VM_TO_TEMPLATE:
            case creationTypeConstants.CLONE_TEMPLATE_TO_TEMPLATE:
               return new CloneVmToTemplateService();
            case creationTypeConstants.CLONE_TEMPLATE_TO_VM:
            case creationTypeConstants.CLONE_VM_TO_VM:
            case creationTypeConstants.DEPLOY_VM_FROM_VMTX:
               return new CloneVmToVmService();
            case creationTypeConstants.CONVERT_TEMPLATE_TO_VM:
               return new convertTemplateToVmService();
            case creationTypeConstants.REGISTER_VM:
               return new registerVmService();
            case creationTypeConstants.CLONE_AS_VMTEMPLATE_TO_LIBRARY:
               return new cloneVmToLibraryService();
            case creationTypeConstants.CLONE_VAPP_AS_VMTEMPLATE_TO_LIBRARY:
               return new cloneVappToLibraryService();
            default:
               var message = "Unknown creation type '" + creationType + "'";
               throw new Error(message);
         }
      }
   }
})();
