/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */

(function () {
   'use strict';
   angular
         .module('com.vmware.vsphere.client.cluster')
         .controller('CreateClusterController', CreateClusterController);

   CreateClusterController.$inject = [
      '$rootScope',
      '$scope',
      '$q',
      'mutationService',
      'defaultUriSchemeUtil',
      'dataService',
      'extensionService',
      'vuiConstants',
      'i18nService',
      'clusterSpecBuilderService',
      'drsAutomationLevelService',
      'drsMigrationThresholdService',
      'haVmMonitoringService',
      'iconService',
      'vcService'
   ];

   function CreateClusterController(
         $rootScope,
         $scope,
         $q,
         mutationService,
         defaultUriSchemeUtil,
         dataService,
         extensionService,
         vuiConstants,
         i18nService,
         clusterSpecBuilderService,
         drsAutomationLevelService,
         drsMigrationThresholdService,
         haVmMonitoringService,
         iconService,
         vcService) {
      var self = this;
      var specType = "com.vmware.vsphere.client.cluster.ClusterComputeResourceSpec";
      var quickstartExtensionId = "vsphere.core.cluster.manage.settings.quickstart";
      var contextObjectId = $scope.modalOptions.dialogData.objectId;
      var contextObjectMor =
            defaultUriSchemeUtil.getManagedObjectReference(contextObjectId);

      self.isVc65OrLater = false;
      // Default EVC mode settings.
      var DISABLED_EVC_MODE = {
         key: null,
         label: getString('evc.config.disableText'),
         description: getString('create.blank')
      };

      $scope.modalOptions.onSubmit = onOkClick;

      // The object that contains the new cluster configuration.
      self.formState = {};
      // EVC modes. They will be retrieved from the server.
      self.supportedEvcModes = [];
      // DRS automation levels drop-down options
      self.drsAutomationLevels = drsAutomationLevelService.getLevels();
      // DRS migration threshold slider options
      self.drsMigrationThresholdSliderOptions = getSliderOptions();
      // HA vm monitoring related options
      self.haVmMonitoringStates = haVmMonitoringService.getMonitoringLevels();
      $scope.modalOptions.isAlertClosed = true;

      var drsSignPostMessage =
            '<div class="flex-row">' +
            '<div class="flex-auto flex-column">' +
            '<div>' + getString('create.DRS.signpost.message.automationLevel') + '</div>' +
            '<div>' + getString('create.DRS.signpost.message.migrationThreshold') + '</div>' +
            '</div>' +
            '<div class="flex-auto flex-column">' +
            '<div>' + getString('create.DRS.signpost.message.automationLevelValue') + '</div>' +
            '<div>' + getString('create.DRS.signpost.message.migrationThresholdValue') + '</div>' +
            '</div>' +
            '</div>';

      self.drsSignPostParams = {
         title: getString('create.DRS.signpost.title'),
         message: drsSignPostMessage
      };

      var haSignPostMessage =
            '<div class="flex-row">' +
            '<div class="flex-auto flex-column">' +
            '<div>' + getString('create.HA.signpost.message.hostMonitoring') + '</div>' +
            '<div>' + getString('create.HA.signpost.message.admissionControl') + '</div>' +
            '<div>' + getString('create.HA.signpost.message.vmMonitoring') + '</div>' +
            '</div>' +
            '<div class="flex-auto flex-column">' +
            '<div>' + getString('create.HA.signpost.message.hostMonitoringValue') + '</div>' +
            '<div>' + getString('create.HA.signpost.message.admissionControlValue') + '</div>' +
            '<div>' + getString('create.HA.signpost.message.vmMonitoringValue') + '</div>' +
            '</div>' +
            '</div>';

      self.haSignPostParams = {
         title: getString('create.HA.signpost.title'),
         message: haSignPostMessage
      };

      getViewData();

      function getViewData() {
         setPageBusy(true);

         var dataRequest = dataService.getProperties(
               contextObjectId, ['name', 'createClusterConfig']);

         $q.all([vcService.is65VcOrLater(contextObjectId), dataRequest]).then(
               function (response) {
                  self.isVc65OrLater = response[0];
                  var data = response[1];
                  var supportedEvcModes =
                        data.createClusterConfig.evcModes.slice();
                  // Add fake evc mode to support disabled state
                  supportedEvcModes.unshift(DISABLED_EVC_MODE);
                  self.supportedEvcModes = supportedEvcModes;
                  self.hciEnabled = !!data.createClusterConfig.hciWorkflowSupported;
                  self.formState = createFormState(data);

                  setPageBusy(false);
               });
      }

      // Creates a form state object with initial values/defaults.
      function createFormState(data) {
         var formData = {
            clusterName: data.createClusterConfig.uniqueName,
            currentEvcMode: DISABLED_EVC_MODE,
            drsEnabled: false,
            haEnabled: false,
            parent: contextObjectMor,
            drsAutomation: getDrsSettings(),
            haConfiguration: getHaSettings(),
            locationIcon: getLocationIcon(contextObjectMor),
            locationName: _.escape(data.name)
         };

         if (self.hciEnabled) {
            formData.vsanEnabled = false;
         }

         return formData;
      }

      // Initial HA settings.
      function getHaSettings() {

         var admissionControlPolicy;
         if(self.isVc65OrLater) {
            admissionControlPolicy = {
               _type: 'com.vmware.vim.binding.vim.cluster.FailoverResourcesAdmissionControlPolicy'
            };
         } else {
            admissionControlPolicy = {
               _type: 'com.vmware.vim.binding.vim.cluster.FailoverLevelAdmissionControlPolicy',
               failoverLevel: 1
            };
         }

         return {
            vmMonitoringState: haVmMonitoringService.DEFAULT_STATE.id,
            hostMonitoringEnabled: true,
            admissionControlEnabled: true,
            admissionControl: {
               policy: admissionControlPolicy
            }
         };
      }

      // Init DRS automation settings.
      function getDrsSettings() {
         return {
            automationLevel: drsAutomationLevelService.DEFAULT_LEVEL,
            migrationThreshold: self.drsMigrationThresholdSliderOptions.value
         };
      }

      // Migration threshold vui-slider options.
      function getSliderOptions() {
         return {
            value: drsMigrationThresholdService.DEFAULT,
            min: drsMigrationThresholdService.MIN,
            max: drsMigrationThresholdService.MAX,
            minLabel: drsMigrationThresholdService.MIN_LABEL,
            maxLabel: drsMigrationThresholdService.MAX_LABEL,
            enabled: true,
            width: 'auto',
            dragHandleTitle: getString('create.vm.DRS.migration.threshold')
         };
      }

      function onOkClick() {
         // Update the threshold manually.
         // The spec is not bound to the slider's value.
         self.formState.drsAutomation.migrationThreshold =
               parseInt(self.drsMigrationThresholdSliderOptions.value, 10);

         var spec = clusterSpecBuilderService.buildClusterComputeResourceSpec(
               self.formState, false, self.hciEnabled);

         setPageBusy(true);
         mutationService.validate(contextObjectId, specType, spec)
               .then(function (validationResult) {
                  handleValidationResult(validationResult, spec);
                  setPageBusy(false);
               })
               .catch(function(error) {
                  setPageBusy(false);
                  $q.reject(error);
               });
         return false;
      }

      function handleValidationResult(validationResult, spec) {
         if (!validationResult ||
               validationResult.error || !validationResult.result ||
               validationResult.result.isValid === true) {
            var createClusterPromise = mutationService.add(specType, spec);

            if (self.hciEnabled) {
               var quickstartExtensionPromise =
                     extensionService.getHostedExtensions(quickstartExtensionId);

               $q.all([createClusterPromise, quickstartExtensionPromise])
                     .then(function (response) {
                        navigateToClusterQuickstart(response);
                     });
            }

            $scope.closeModal();
         } else {
            $scope.modalOptions.alerts = [{
               type: vuiConstants.validationBanner.type.ERROR,
               text: validationResult.result.validationMessage
            }];
            $scope.modalOptions.isAlertClosed = false;
         }
      }

      function navigateToClusterQuickstart(response) {
         var operationResult = response[0];
         var extensionResult = response[1];

         if (operationResult && operationResult.result && extensionResult
               && extensionResult.length > 0 && extensionResult[0]
               && extensionResult[0].uid) {
            var newClusterId = defaultUriSchemeUtil.getVsphereObjectId(
                  operationResult.result);

            $rootScope._navigateToViewAndObject(
                  quickstartExtensionId,
                  newClusterId);
         }
      }

      function setPageBusy(isBusy) {
         $scope.modalOptions.submitDisabled = isBusy;
         self.loading = isBusy;
      }

      function getLocationIcon(locationRef) {
         return iconService.getByObjectRef(locationRef);
      }

      function getString(key) {
         return i18nService.getString('ClusterUi', key);
      }
   }
})();
