/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace dvs_ui {

   export class ExportDvsComponent {

      public bindings: any;
      public controller: any;
      public templateUrl: string;

      constructor() {
         this.bindings = {
            pageModel: "<"
         };

         this.controller = ExportDvsComponentController;

         this.templateUrl =
               "dvs-ui/resources/dvs/export/exportDvsComponentTemplate.html";
      }
   }

   class ExportDvsComponentController {

      static $inject = [
         "i18nService",
         "exportDvsConstants"
      ];

      constructor(private i18nService: any,
                  private exportDvsConstants: any) {
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .component("exportDvsComponent",
               new ExportDvsComponent());
}
