/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace dvs_ui {

   export class DvsImportWizardModel {

      objectId: string;

      configurationPageModel: DvsImportWizardConfigurationPageModel =
            new DvsImportWizardConfigurationPageModel();

      isDvpgConfig: boolean;

      isRestoreAction: boolean;
   }
}
