/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace dvs_ui {

   import DvpgEditTrafficFilterSpec = com.vmware.vsphere.client.h5.network.dvportgroup.trafficfilter.DvpgEditTrafficFilterSpec;
   import DvpgEditRuleSequenceSpec = com.vmware.vsphere.client.h5.network.dvportgroup.trafficfilter.DvpgEditRuleSequenceSpec;
   import DvpgTrafficFilterRule = com.vmware.vsphere.client.h5.network.dvportgroup.trafficfilter.DvpgTrafficFilterRule;
   import DvpgAddTrafficRuleSpec = com.vmware.vsphere.client.h5.network.dvportgroup.trafficfilter.DvpgAddTrafficRuleSpec;
   import TrafficRule = com.vmware.vim.binding.vim.dvs.TrafficRule;
   import DvpgTrafficRuleModel = com.vmware.vsphere.client.h5.network.dvportgroup.trafficfilter.model.DvpgTrafficRuleModel;
   import DvpgRemoveTrafficRuleSpec = com.vmware.vsphere.client.h5.network.dvportgroup.trafficfilter.DvpgRemoveTrafficRuleSpec;
   import DvpgEditTrafficRuleSpec = com.vmware.vsphere.client.h5.network.dvportgroup.trafficfilter.DvpgEditTrafficRuleSpec;
   import DvpgCloneTrafficRulesSpec = com.vmware.vsphere.client.h5.network.dvportgroup.trafficfilter.DvpgCloneTrafficRulesSpec;

   export class DvpgTrafficFilterSpecFactory {

      public static $inject = ["defaultUriSchemeUtil"];

      constructor(private defaultUriSchemeUtil: any) {
      }

      /**
       * Builds the spec for enable and/or reorder the traffic filter rules of
       * distributed port group.
       *
       * @param model DvpgEditTrafficFilterModel
       */
      public buildEditTrafficFilterSpec(model: DvpgEditTrafficFilterModel,
            isRuleOrderChanged: boolean): DvpgEditTrafficFilterSpec {

         let configSpec: DvpgEditTrafficFilterSpec = new DvpgEditTrafficFilterSpec();
         configSpec.isTrafficRulesetEnabled = model.isTrafficRulesetEnabled;

         if (isRuleOrderChanged) {
            let trafficRuleSpecs: DvpgEditRuleSequenceSpec[] = [];
            model.rules.forEach((rule:DvpgTrafficFilterRule): void => {
               let trafficRuleSpec: DvpgEditRuleSequenceSpec =
                     new DvpgEditRuleSequenceSpec();
               trafficRuleSpec.ruleKey = rule.key;
               trafficRuleSpec.sequence = rule.sequence;
               trafficRuleSpecs.push(trafficRuleSpec);
            });

            configSpec.rules = trafficRuleSpecs;
         }
         return configSpec;
      }

      /**
       * Builds the spec for clone traffic filter rules of
       * distributed port group to another port groups.
       *
       * @param model DvpgCloneTrafficRulesSpec
       */
      public buildCloneTrafficFilterSpec(
            model: DvpgCloneTrafficRulesModel): DvpgCloneTrafficRulesSpec {
         let configSpec: DvpgCloneTrafficRulesSpec =
               new DvpgCloneTrafficRulesSpec();

         let portgroupRefs: any[] = [];
         model.selectedPortgroups.forEach((portgroupId: any): void => {
            portgroupRefs.push(
                  this.defaultUriSchemeUtil.getManagedObjectReference(portgroupId.id));
         });

         configSpec.portgroups = portgroupRefs;
         return configSpec;
      }

      /**
       * Builds the spec for adding a traffic rule to the current rules configuration
       * of the distributed port group.
       *
       * @param model DvpgTrafficRuleModel
       */
      public buildAddTrafficRuleSpec(
            model: DvpgTrafficRuleModel): DvpgAddTrafficRuleSpec {
         let spec: DvpgAddTrafficRuleSpec = new DvpgAddTrafficRuleSpec();
         spec.newRule = model;
         return spec;
      }

      /**
       * Builds the spec for editing a traffic rule to the current rules configuration
       * of the distributed port group.
       *
       * @param model DvpgTrafficRuleModel
       */
      public buildEditTrafficRuleSpec(
            editedRuleModel: DvpgTrafficRuleModel): DvpgEditTrafficRuleSpec {
         let spec: DvpgEditTrafficRuleSpec = new DvpgEditTrafficRuleSpec();
         spec.editedRule = editedRuleModel;
         return spec;
      }

      /**
       * Builds the spec for removing a traffic rule to the current rules configuration
       * of the distributed port group.
       *
       * @param key The key of the removed traffic rule
       */
      public buildRemoveTrafficRuleSpec(ruleKey: string): DvpgRemoveTrafficRuleSpec {
         let spec: DvpgRemoveTrafficRuleSpec = new DvpgRemoveTrafficRuleSpec();
         spec.key = ruleKey;
         return spec;
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .service("dvpgTrafficFilterSpecFactory", DvpgTrafficFilterSpecFactory);
}
