namespace storage_ui {
   export class DatastoreFileBrowserGridColumnConfigService {
      public static $inject = ["i18nService", "storageUtil", "columnRenderersRegistry", "fileUploadConstants"];

      constructor(private i18nService: any,
                  private storageUtil: any,
                  private columnRenderersRegistry: any,
                  private fileUploadConstants: any) {

      }

      public getDatastoreBrowserColumnConfig(hasFriendlyName: boolean) {
         let self = this;
         let columns = [
            {
               displayName: self.i18nService.getString('StorageUi', 'dsExplorer.colName'),
               field: 'formattedName',
               sortable: function (cti1:any, cti2:any) {
                  return self.storageUtil.compareNumericValues(cti1, cti2, 'nameSortOrder');
               },
               template: function (item:any) {
                  return '<div><span class="' + item.spriteCssClass + '"></span>' +
                        self.renderText(item.formattedName) + '</div>';
               }
            },
            {
               displayName: self.i18nService.getString('StorageUi', 'dsExplorer.colSize'),
               field: 'formattedSize',
               sortable: function (cti1:any, cti2:any) {
                  return self.storageUtil.compareNumericValues(cti1, cti2, 'size');
               },
               template: function (item:any) {
                  return self.renderText(item.formattedSize, ['right-align-cell']);
               }
            },
            {
               displayName: self.i18nService.getString('StorageUi', 'dsExplorer.colModified'),
               field: 'lastModifiedFormatted',
               sortable: function (cti1:any, cti2:any) {
                  return self.storageUtil.compareNumericValues(cti1, cti2, 'lastModified');
               },
               template: function (item:any) {
                  return self.renderText(item.lastModifiedFormatted);
               }
            },
            {
               displayName: self.i18nService.getString('StorageUi', 'dsExplorer.colType'),
               field: 'localizedType',
               template: function (item:any) {
                  return self.renderText(item.localizedType);
               }
            },
            {
               displayName: self.i18nService.getString('StorageUi', 'dsExplorer.colPath'),
               field: 'path',
               template: function (item:any) {
                  return self.renderText(item.path);
               }
            }
         ];
         if (hasFriendlyName) {
            columns.push({
               displayName: self.i18nService.getString('StorageUi', 'dsExplorer.colUuid'),
               field: 'name',
               template: function (item:any) {
                  const displayText = item.friendlyName ? item.name : "";
                  return self.renderText(displayText);
               }
            });
         }
         return columns;
      }

      public getUploadSessionsGridColumnDefs(): any[] {
         let self = this;
         return [
            {
               displayName: this.i18nService.getString('StorageUi', 'dsFileTransferView.colName'),
               field: 'name',
               template: function (item:any) {
                  return `<div><span class="vx-icon-dsUpload"></span>
                        ${self.renderText(item.name)}</div>`;
               }
            }, {
               displayName: this.i18nService.getString('StorageUi', 'dsFileTransferView.colStatus'),
               field: 'progress',
               template: this.formatStatusColumn
            }, {
               displayName: this.i18nService.getString('StorageUi', 'dsFileTransferView.startTime'),
               field: 'startTimeFormatted',
               template: '#:startTimeFormatted#'
            }, {
               displayName: this.i18nService.getString('StorageUi', 'dsFileTransferView.lastUpdated'),
               field: 'lastUpdatedFormatted',
               template: '#:lastUpdatedFormatted#'
            }
         ];
      }

      renderText = (plainText: string, cssClasses?: string[]|null, title?: string): string => {
         let span = $("<span ng-non-bindable></span>")
               .addClass("vertical-align-cell")
               .text(plainText);

         if (cssClasses) {
            _.forEach(cssClasses, function (className: string) {
               span.addClass(className);
            });
         }

         if (title) {
            span.attr("title", title);
         }

         return span[0].outerHTML;
      };

      formatStatusColumn = (item:any): string|undefined => {
         if (item.status === this.fileUploadConstants.state.SUCCESS) {
            return this.renderIconWithText(
                  'vsphere-icon-status-ok',
                  this.i18nService.getString('Common', 'success'));
         } else if (item.status === this.fileUploadConstants.state.ERROR) {
            if (item.isGeneralError) {
               let iconSpan = '<span class="vsphere-icon-status-error"></span>';
               let textSpan = this.renderText(
                     this.i18nService.getString('StorageUi', 'fileUpload.error.generalError'));
               let detailsSpan = this.renderText(
                     this.i18nService.getString('StorageUi', 'fileUpload.error.details'));
               let detailsLink = `<a class="datastore-upload-error-details-link" ng-click="dataItem.showError()">${detailsSpan}</a>`;
               return '<div>' +
                     iconSpan +
                     textSpan +
                     detailsLink +
                     '</div>';

            } else {
               return this.renderIconWithText('vsphere-icon-status-error', item.error);
            }
         } else if (item.status === this.fileUploadConstants.state.CANCELED) {
            return this.renderIconWithText(
                  'vsphere-icon-status-error',
                  this.i18nService.getString('StorageUi', 'fileUpload.status.canceled'));
         } else if (item.status === this.fileUploadConstants.state.RUNNING) {
            let progressBarRenderer = this.columnRenderersRegistry.getColumnRenderer('progress-bar-cancel-link');
            return progressBarRenderer(item.progress, item.isCancelable, false, 'dataItem.cancel()');
         }
         return undefined;
      };

      renderIconWithText = (icon:string, text:string): string => {
         let iconSpan = `<span class="${icon}"></span>`;
         let textSpan = this.renderText(text, null, text);
         return '<div>' +
               iconSpan +
               textSpan +
               '</div>';
      };

   }

   angular.module("com.vmware.vsphere.client.storage")
         .service("datastoreFileBrowserGridColumnConfigService", DatastoreFileBrowserGridColumnConfigService);

}
