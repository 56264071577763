module ds_cluster_ui {

   import IController = angular.IController;
   import IScope = angular.IScope;

   class SelectVmToVmOverrideComponentController implements IController {


      static $inject = ["$rootScope", "$scope",
         "addSdrsVmOverridesService", "datagridActionBarService"];

      private gridOptions: any;
      private objectId: string;
      private watchForObjects: string[];
      private selectedVms: any;

      constructor(private $rootScope: any,
                  private $scope: any,
                  private addSdrsVmOverridesService: AddSdrsVmOverridesService,
                  private datagridActionBarService: any) {

      }

      $onInit(): void {
         this.objectId = this.$rootScope._route.objectId;
         this.gridOptions =
               this.addSdrsVmOverridesService.getSdrsVmOverridesGridOptions(
                     this.selectedVms);
         this.watchForObjects = [this.objectId];
      }
   }

   class SelectVmToVmOverrideComponent implements ng.IComponentOptions {
      public controller: any;
      public templateUrl: string;
      public bindings: any;
      constructor() {
         this.controller = SelectVmToVmOverrideComponentController;
         this.templateUrl =
               "ds-cluster-ui/resources/ds-cluster/views/settings/configuration" +
               "/sdrsVmOverrides/selectVmToVmOverrideComponent.html";
         this.bindings = {
            selectedVms: "="
         };
      }
   }

   angular.module("com.vmware.vsphere.client.dsCluster").component(
         "selectVmToVmOverrideComponent", new SelectVmToVmOverrideComponent());
}
