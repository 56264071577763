/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
(function () {
   'use strict';
   angular.module('com.vmware.vsphere.client.storage')
      .service('nfsNameAndConfigurationPageService', nfsNameAndConfigurationPageService);

   nfsNameAndConfigurationPageService.$inject = [
      'i18nService',
      'wizardPageService',
      'addDatastoreNameValidatorService', '$q', 'defaultUriSchemeUtil'];

   function nfsNameAndConfigurationPageService (
      i18nService, wizardPageService, addDatastoreNameValidatorService, $q, defaultUriSchemeUtil) {
      return {
         build: function (wizardScope) {
            return {
               title: i18nService.getString('StorageUi', 'addDatastoreWizard.nfsSettingsPage.title'),
               description: i18nService.getString('StorageUi', 'addDatastoreWizard.nfsSettingsPage.description'),
               contentUrl: 'storage-ui/resources/storage/views/wizard/createDatastore/pages/nfs/NfsNameAndConfigurationPage.html',
               state: undefined,
               onCommit: function() {
                  return validate(wizardScope).then(function(errors) {
                     if (errors && errors.length > 0) {
                        wizardPageService.markPageIncompleteWithErrors(
                           wizardScope.wizardConfig,
                           errors);
                        return false;
                     }

                     wizardScope.wizardConfig.validationBanner.messages = [];
                     wizardPageService.markPageComplete(wizardScope.wizardConfig,
                        wizardScope.commonWizardManager.getDatastoreType());
                     return true;
                  });
               }
            };
         }
      };

      function validate(wizardScope) {
         var errors = [];

         wizardScope.nfsWizardManager.setExistingDatastoreId(null);

         // Validate datastore name
         if (!wizardScope.commonWizardManager.getDatastoreName()) {
            errors.push(i18nService.getString(
               'StorageUi', 'addDatastoreWizard.nameValidator.noDatastoreName'));
         }

         // Validate folder name
         if (!wizardScope.nfsWizardManager.getFolderName()) {
            errors.push(i18nService.getString(
               'StorageUi', 'addDatastoreWizard.nfsSettingsPage.folderNameEmpty'));
         }

         // Validate server names
         var serverNamesInvalidError = wizardScope.nfsWizardManager.validateServerNames();
         if (serverNamesInvalidError) {
            errors.push(serverNamesInvalidError);
         }

         if (errors.length) {
            return $q.when(errors);
         }

         return validateDatastoreNameAndConfiguration(wizardScope);
      }

      function validateDatastoreNameAndConfiguration(wizardScope) {
         if (wizardScope.nfsWizardManager.isNfsVersion41()) {
            wizardScope.nfsWizardManager.addServerNamesAction();
            wizardScope.nfsWizardManager.setServerName('');
         }

         // Validate name and configuration uniqueness
         var promise = addDatastoreNameValidatorService.validateNfsNameAndConfiguration(
            wizardScope.commonWizardManager.getContextObject(),
            wizardScope.commonWizardManager.getDatastoreName(),
            wizardScope.nfsWizardManager.getFolderName(),
            wizardScope.nfsWizardManager.isNfsVersion41()
               ? wizardScope.nfsWizardManager.getServerNamesArray()
               : [wizardScope.nfsWizardManager.getServerName()],
            wizardScope.nfsWizardManager.getNfsVersion());

         return promise.then(function(configurationValidationResult) {
            if (configurationValidationResult && configurationValidationResult.validationError) {
               return [configurationValidationResult.validationError];
            }
            if (configurationValidationResult && configurationValidationResult.existingDatastore) {
               wizardScope.nfsWizardManager.setExistingDatastoreId(
                  defaultUriSchemeUtil.getVsphereObjectId(configurationValidationResult.existingDatastore));
            }
            return [];
         });
      }
   }
})();
