namespace vm_ui {

   export class TpmCertDetailInfoController {

      public propertyViewData: any;
      public certificateInfo: any;
      private NA_STRING: string;

      public static $inject = ["i18nService", "timeFormatterService",
            "vxPropertyViewService", "$q"];

      constructor(private i18nService: any,
                  private timeFormatterService: any,
                  private vxPropertyViewService: any,
                  private $q: any) {

         this.NA_STRING = i18nService.getString("VmUi", "VmTpm.info.na");
      }

      public $onChanges() {
         this.refresh();
      }

      public refresh() {
         if (!this.certificateInfo) {
            return;
         }
         let timePromises = {
            validFrom: this.timeFormatterService.formatDate(this.certificateInfo.validFrom),
            validTo: this.timeFormatterService.formatDate(this.certificateInfo.validTo),
         };

         this.$q.all(timePromises).then((timePromisesResponse:any) => {
            const builder = this.vxPropertyViewService.createPropertyViewBuilder();
            const generalCategory = builder.category("general");
            const generalSection = generalCategory.section("generalSection");
            // Subject not shown on purpose
            // generalSection.property(
            //       this.i18nService.getString("VmUi", "certificateInfo.subject"),
            //       this.certificateInfo.subjectName || this.NA_STRING);
            generalSection.property(
                  this.i18nService.getString("VmUi", "certificateInfo.issuer"),
                  this.certificateInfo.issuerName || this.NA_STRING);
            generalSection.property(
                  this.i18nService.getString("VmUi", "certificateInfo.version"),
                  this.certificateInfo.version || this.NA_STRING);
            generalSection.property(
                  this.i18nService.getString("VmUi", "certificateInfo.kind"),
                  this.certificateInfo.kind || this.NA_STRING);
            generalSection.property(
                  this.i18nService.getString("VmUi", "certificateInfo.validFrom"),
                  timePromisesResponse.validFrom || this.NA_STRING);
            generalSection.property(
                  this.i18nService.getString("VmUi", "certificateInfo.validTo"),
                  timePromisesResponse.validTo || this.NA_STRING);
            generalSection.property(
                  this.i18nService.getString("VmUi", "certificateInfo.algorithm"),
                  this.certificateInfo.algorithmName || this.NA_STRING);
            generalSection.property(
                  this.i18nService.getString("VmUi", "certificateInfo.publicKey"),
                  this.certificateInfo.publicKeyAlgorithmName || this.NA_STRING);
            this.propertyViewData = builder.build();
         });
      }
   }

   angular.module("com.vmware.vsphere.client.vm")
         .component("tpmCertDetailInfo", {
            template: "<vx-property-view data=\"$ctrl.propertyViewData\"></vx-property-view>",
            bindings: {
               certificateInfo: "<"
            },
            controller: TpmCertDetailInfoController
         });
}
