namespace h5_vm {
   import IPromise = angular.IPromise;
   import IQService = angular.IQService;

   import ManagedEntityMoveSpec = com.vmware.vsphere.client.mixed.ManagedEntityMoveSpec;
   import ManagedObjectReference = com.vmware.vim.binding.vmodl.ManagedObjectReference;
   import ValidateMoveVmSpec = com.vmware.vsphere.client.h5.vm.data.ValidateMoveVmSpec;

   export class MoveVmService {
      static $inject: string[] = [
         "mutationService",
         "defaultUriSchemeUtil",
         "vuiService",
         "clarityModalService",
         "i18nService",
         "$q",
         "nameAndFolderValidatorService",
         "managedEntityConstants",
         "dataService",
         "migrateVmWizardService",
         "actionConfirmationService",
         "managedByMessageBuilderService"];

      private readonly PROPERTY_TEMPLATE: string = "template";
      private readonly PROPERTY_DATACENTER: string = "dc";
      private readonly PROPERTY_MANAGED_BY_INFO: string = "managedByInfo";
      private _mutationService: any;
      private _defaultUriSchemeUtil: any;
      private _vuiService: any;
      private _clarityModalService: any;
      private _i18nService: any;
      private _q: IQService;
      private _nameAndFolderValidatorService: any;
      private _managedEntityConstants: any;
      private _dataService: any;
      private _migrateVmWizardService: any;
      private _actionConfirmationService: any;
      private _managedByMessageBuilderService: any;

      constructor(mutationService: any,
            defaultUriSchemeUtil: any,
            vuiService: any,
            clarityModalService: any,
            i18nService: any,
            $q: IQService,
            nameAndFolderValidatorService: any,
            managedEntityConstants: any,
            dataService: any,
            migrateVmWizardService: any,
            actionConfirmationService: any,
            managedByMessageBuilderService: any) {
         this._mutationService = mutationService;
         this._defaultUriSchemeUtil = defaultUriSchemeUtil;
         this._vuiService = vuiService;
         this._clarityModalService = clarityModalService;
         this._i18nService = i18nService;
         this._q = $q;
         this._nameAndFolderValidatorService = nameAndFolderValidatorService;
         this._managedEntityConstants = managedEntityConstants;
         this._dataService = dataService;
         this._migrateVmWizardService = migrateVmWizardService;
         this._actionConfirmationService = actionConfirmationService;
         this._managedByMessageBuilderService = managedByMessageBuilderService;
      }

      /**
       * If a drop target is supplied invokes the move action for the provided virtual
       * machines to the target. If the drop target is not supplied
       * then opens a wizard from which the drop target can be selected
       * @param vmIds
       * @param target
       * @param actionEval
       */
      public moveVms(vmIds: string[], target: DestinationTarget, actionEval: any): void {
         if (target && target.dropTarget) {
            this.applyMove(vmIds, target.dropTarget);
            return;
         }
         if (this.allVmsFromSameVc(vmIds)) {
            this.confirmIfRequired(vmIds, actionEval).then((properties: {[prop: string]: any}) => {
               this.showWizard(vmIds, properties);
            });
         } else {
            this.showMultipleVcsErrorDialog();
         }
      }

      /**
       * Builds a ManagedEntityMoveSpec that is used for the apply of the move action
       * @param virtualMachines
       * @returns {ManagedEntityMoveSpec}
       */
      private buildMoveSpec(virtualMachines: string[]): ManagedEntityMoveSpec {
         let entities: ManagedObjectReference[] =
               this._defaultUriSchemeUtil.getManagedObjectReferences(virtualMachines);
         let moveSpec: ManagedEntityMoveSpec = new ManagedEntityMoveSpec();
         moveSpec.entities = entities;

         return moveSpec;
      }

      /**
       * Invokes the mutation service's apply operation to initiate the move entities
       * operation for the supplied virtual machines to the target
       * @param virtualMachineIds
       * @param targetId
       */
      public applyMove(virtualMachineIds: string[], targetId: string): void {
         let moveSpec: ManagedEntityMoveSpec = this.buildMoveSpec(virtualMachineIds);
         this._mutationService.apply(targetId, moveSpec._type, moveSpec);
      }

      /**
       * Validates if the given virtual machines can be moved to the supplied target.
       * 1. Checks if the targets is of allowed type (Datacenter, VM Folder)
       * 2. Invokes the backend for additional validation
       * @param virtualMachineIds
       * @param targetId
       * @returns {any}
       */
      public validateMove(virtualMachineIds: string[], targetId: string): IPromise<string> {
         let targetType: string = this._defaultUriSchemeUtil.getEntityType(targetId);
         let isTargetVmFolder: boolean = false;
         let isTargetDatacenter: boolean = !!(targetType === this._managedEntityConstants.DATACENTER);
         if (targetType === this._managedEntityConstants.FOLDER) {
            isTargetVmFolder = this._nameAndFolderValidatorService.isVmFolder(targetId);
         }
         if (isTargetVmFolder || isTargetDatacenter) {
            return this.retrieveValidationMessage(virtualMachineIds, targetId);
         }

         // if the target is not Datacenter or VM Folder, return error
         return this._q.when(
               this._i18nService.getString("Common", "moveTo.incorrectTargetTypeError"));
      };

      /**
       * Ask the backend for validation if the given virtual machines
       * can be moved to the supplied target.
       *
       * @param virtualMachineIds
       * @param targetId
       * @returns {any}
       */
      public retrieveValidationMessage(virtualMachineIds: string[], targetId: string): IPromise<string> {
         let entities: ManagedObjectReference[] =
               this._defaultUriSchemeUtil.getManagedObjectReferences(virtualMachineIds);
         let moveSpec: ValidateMoveVmSpec = new ValidateMoveVmSpec();
         moveSpec.entities = entities;

         return this._mutationService
               .validate(targetId, moveSpec._type, moveSpec)
               .then((result: any) => {
                  return result.result;
               });
      }

      /**
       * Opens a clarity modal from which a drop target can be selected for the move
       * operation.
       * @param virtualMachineIds
       */
      private showWizard(virtualMachineIds: string[], properties: {[prop: string]: any}): void {
         let subTitleObject: any = this.buildSubtitle(virtualMachineIds);
         let modalOptions: any = {
            title: this._i18nService.getString("VmUi", "moveVmToFolderDialogTitle"),
            subTitle: subTitleObject,
            defaultButton: "submit",
            dialogData: {
               objectIds: virtualMachineIds,
               // check if the selection is for virtual machines or templates
               // no need to check for all of the items, since no mixed selection can be made
               isTemplate: properties[virtualMachineIds[0]][this.PROPERTY_TEMPLATE],
               datacenter: this._defaultUriSchemeUtil
                     .getVsphereObjectId(properties[virtualMachineIds[0]][this.PROPERTY_DATACENTER]),
               virtualCenter: this._defaultUriSchemeUtil.getRootFolderFromVsphereObjectId(virtualMachineIds[0])
            },
            contentTemplate: "vm-ui/resources/vm/services/wizards/moveVm/moveVmDialog.html"
         };
         this._clarityModalService.openOkCancelModal(modalOptions);
      }

      /**
       * Builds the subtitle object that is sent to the clarity modal service
       * If the virtual machine is only one, sets the objectId property of the
       * subtitle object, and the clarityModalService will retrieve and display its name
       * If the virtual machines are more than one, builds a text, that is set as a property
       * to the subtitle object and will directly be displayed in the dialog
       * @param virtualMachineIds
       * @returns {any}
       */
      private buildSubtitle(virtualMachineIds: string[]): any {
         let subTitleObject: any = {};
         if (virtualMachineIds.length === 1) {
            subTitleObject.objectId = virtualMachineIds[0];
            return subTitleObject;
         }

         subTitleObject.text = this._i18nService.getString(
               "Common", "contextMenu.title.nObjects", virtualMachineIds.length);
         return subTitleObject;
      }

      /**
       * Determines if all Vms are from the same VC.
       * If they are not. The move action is not available.
       * If they are we should open the Move Vms dialog.
       * @param virtualMachineIds {string[]}
       * @returns {boolean}
       */
      private allVmsFromSameVc(virtualMachineIds: string[]): boolean {
         let result: boolean;
         let mor: ManagedObjectReference;
         let firstVmServerGuid: string;

         if (virtualMachineIds && virtualMachineIds[0]) {
            firstVmServerGuid = this._defaultUriSchemeUtil.getManagedObjectReference(virtualMachineIds[0]).serverGuid;
         }
         result = true;
         _.each(virtualMachineIds, (virtualMachineId: string) => {
            mor = this._defaultUriSchemeUtil.getManagedObjectReference(virtualMachineId);
            if (mor.serverGuid !== firstVmServerGuid) {
               result = false;
            }
         });
         return result;
      }

      /**
       * Method that displays an error dialog if the selected Vms are from differentVCs
       * with the corresponding message and error icon.
       */
      private showMultipleVcsErrorDialog(): void {
         let errorOptions: any = {
            title: this._i18nService.getString("VmUi", "dialogMoveToVmLabel"),
            message: this._i18nService.getString("VmUi", "MoveToDialog.MultipleVcErrors"),
            icon: "vui-icon32-error",
            submit: () => {
               return true;
            },
            hideCancelButton: true
         };
         this._clarityModalService.openConfirmationModal(errorOptions);
      }

      private confirmIfRequired(vmIds: string[], actionEval: any): IPromise<{[prop: string]: any}> {
         let deferred = this._q.defer();
         this._dataService.getPropertiesForObjects(
               vmIds,
               [this.PROPERTY_TEMPLATE, this.PROPERTY_DATACENTER, this.PROPERTY_MANAGED_BY_INFO])
               .then((properties: {[prop: string]: any}) => {
                  if (!actionEval) {
                     deferred.resolve(properties);
                     return;
                  }
                  actionEval.action.confirmationTextSome =
                        this._i18nService.getString("Common", "defaultConfirmation.someOK");
                  actionEval.action.confirmationTextAll =
                        this._i18nService.getString("Common", "defaultConfirmation.allOK");
                  actionEval.action.confirmationTitle =
                        this._i18nService.getString("VmUi", "moveVmToFolderDialogTitle");
                  let withProceed: boolean = Object.keys(actionEval.evaluationStatuses).length === 1;
                  this._managedByMessageBuilderService.buildWarningMessage(vmIds, withProceed, properties)
                        .then((message: string) => {
                           if (message) {
                              actionEval.action.confirmationText = message;
                              actionEval.action.confirmationTextSome = message + "\n\n" +
                                    actionEval.action.confirmationTextSome;
                              actionEval.action.confirmationTextAll = message + "\n\n" +
                                    actionEval.action.confirmationTextAll;
                           }
                           this._actionConfirmationService.confirmIfRequired(actionEval, function () {
                              deferred.resolve(properties);
                           }, true);
                        });
               });
         return deferred.promise;
      }
   }

   angular.module("com.vmware.vsphere.client.vm")
         .service("moveVmService", MoveVmService);
}
