(function(){
   'use strict';
   angular.module('com.vmware.vsphere.client.vm')
         .service('datastoreRecommendationsDialogService', datastoreRecommendationsDialogService);
   datastoreRecommendationsDialogService.$inject = [
      'clarityModalService',
      '$q',
      'i18nService'];
   function datastoreRecommendationsDialogService(clarityModalService, $q, i18nService) {
      return {
         openDialog: openDialog
      };

      function openDialog(recommendedPlacements, storageIdToNameMap, preselectRecommendation, vmName) {
         var deferredSelection = $q.defer();

         var modalOptions = {
            contentTemplate: 'vm-ui/resources/vm/views/createVmWizard/' +
            'datastoreRecommendations/vmProvisioningDatastoreRecommendations.html',
            title: i18nService.getString('VmUi', 'dsRecommendation.title'),
            size: 'lg',
            dialogData: {
               selection: preselectRecommendation,
               recommendations: recommendedPlacements,
               newVmName: vmName,
               possibleDatastores: storageIdToNameMap,
               descriptionText: i18nService.getString('VmUi', 'dsRecommendation.header')
            },
            onSubmit: function () {
               if (!this.dialogData.selection) {
                  deferredSelection.reject();
                  return;
               }

               deferredSelection.resolve({
                  recommendationSpec: this.dialogData.selection.recommendationSpec
               });
               return true;
            },
            onCancel: function () {
               deferredSelection.reject();
            },
            defaultButton: 'submit',
            submitButtonTitle: i18nService.getString('VmUi', 'dsRecommendation.apply')
         };
         clarityModalService.openOkCancelModal(modalOptions);

         return deferredSelection.promise;
      }
   }
})();
