(function() {
   'use strict';
   angular.module('com.vmware.vsphere.client.vm').service('VmProvisioningCustomizeHardwarePageModel', [
      'i18nService',
      'deviceTypeConstants',
      function(
         i18nService,
         deviceTypeConstants
      ) {
         return function(virtualMachineSpecBuilder, wizardViewData) {
            var form = {};
            var computeResourceId;
            return {
               getStackBlocksExpandedState: function() {
                  // if the customize hardware page is visited for the first time, or the
                  // compute resource has been changed, collapse all stack blocks
                  // by overwriting their current state with the default one
                  if (computeResourceId !== virtualMachineSpecBuilder.getComputeResourceId()) {
                     computeResourceId = virtualMachineSpecBuilder.getComputeResourceId();
                     // note mibryamov: default behavior - all the stack blocks are collapsed by
                     // default. If in the future we want to customize the default expanded
                     // state of the stack blocks on this page, we will pass this settings as part
                     // of virtualMachineSpecBuilder or as a separate parameter to this model
                     // when we instantiate it in given vm provisioning service. For now the setting
                     // created here in this model will apply for all the workflows - by default
                     // the stack blocks are collapsed
                     form.stackBlocksExpandedState = {
                        cpu: false,
                        memory: false,
                        virtualDevices: [],
                        other: false,
                        // here list all the other stack blocks' state properties
                        // vm options stack block state properties
                        generalOptions: false,
                        vmrcOptions: false,
                        standbyOptions: false,
                        vmwareToolsOptions: false,
                        bootOptions: false,
                        vmAdvancedOptions: false,
                        vmHwNpiv: false,
                        encryptionOptions: false
                     };
                  }
                  return form.stackBlocksExpandedState;
               },
               validatePage: function() {
                  if (!form.isHardwareValid) {
                     return {
                        error: i18nService.getString('VmUi', 'CustomizeHardwareProvisioningPage.PageValidationError')
                     };
                  }

                  if (!!wizardViewData.getNICsToCustomize()) {
                     const networkAdapters = virtualMachineSpecBuilder
                        .getVirtualMachineDevices()
                        .devicesNotMarkedForRemovalOfType(deviceTypeConstants.ETHERNET);
                     if (networkAdapters.length < wizardViewData.getNICsToCustomize().length) {
                        return {
                           error: i18nService.getString('VmUi',
                              'CustomizeHardwareProvisioningPage.PageValidationError.vmNicsLessThanGosSpecNics',
                              wizardViewData.getCustomizationSpecName())
                        };
                     }
                  }

                  return {};
               },
               submitPage: function() {
                  return {
                     wizardFlow: virtualMachineSpecBuilder.getCreationType(),
                     invalidateNextPages: false
                  };
               },

               setFormValidity: function(isValid) {
                  form.isHardwareValid = isValid;
               }
            };
         };
      }]
   );
})();
