module host_ui {
    import IController = angular.IComponentController;
    export class EncryptionMode {
        public bindings: any;
        public controller: any;
        public templateUrl: string;
        public controllerAs: any;

        static $inject = [
            "i18nService"
        ];

        constructor() {
            this.controllerAs = "ctrl";
            this.bindings = {
                header: "<",
                hostEncryption: "<"
            };
            this.templateUrl =
                "host-ui/resources/host/views/settings/securityProfile/encryptionModeTemplate.html";
            this.controller = EncryptionModeController;
        }
    }

    export class EncryptionModeController implements angular.IComponentController {
        static $inject = [
            "i18nService"
        ];

        public subtitle: string;
        public encryptionLabel: string;
        public i18n: any;

        constructor(private i18nService: any) {
            this.i18n = i18nService.getString.bind(i18nService);
            this.subtitle = this.i18nService.getString("HostUi", "hostEncryption.description");
            this.encryptionLabel = this.i18nService.getString("HostUi",
                  "hostEncryption.mode.label");
        }
    }

    angular.module("com.vmware.vsphere.client.host")
        .component("encryptionMode", new EncryptionMode());
}
