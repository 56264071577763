angular.module('com.vmware.vsphere.client.vm').factory('vmDeviceManagementService', [
   'vmDeviceInfoService',
   'opticalDriveService',
   'lunSelectorDialogService',
   'diskProvisioningService',
   'networkAdapterService',
   'scsiControllerService',
   'sataControllerService',
   'usbDeviceService',
   'usbControllerService',
   'nvmeControllerService',
   'pciSharedService',
   'existingHardDiskSelectorDialogService',
   'tpmProvisioningService',
   'nvdimmProvisioningService',
   'deviceTypeConstants',
   '$q',
   'i18nService',
   'vmCryptUtilService',
   'clarityModalService',
   'storageProfileService',
   'serialPortService',
   function(vmDeviceInfoService,
         opticalDriveService,
         lunSelectorDialogService,
         diskProvisioningService,
         networkAdapterService,
         scsiControllerService,
         sataControllerService,
         usbDeviceService,
         usbControllerService,
         nvmeControllerService,
         pciSharedService,
         existingHardDiskSelectorDialogService,
         tpmProvisioningService,
         nvdimmProvisioningService,
         deviceTypeConstants,
         $q,
         i18nService,
         vmCryptUtilService,
         clarityModalService,
         storageProfileService,
         serialPortService) {
      return {
         createNewDevice: function (
               deviceType,
               vmConfigEnvironment,
               guestOsDescriptor,
               virtualMachineDevices,
               additionalParameters) {
            var mergedInflatedDevices =
                  virtualMachineDevices.getAllDevicesNotMarkedForRemoval();

            var createdInflatedDeviceContainer;
            var deviceOptions = vmConfigEnvironment.configOption.hardwareOptions.virtualDeviceOption;
            var configTarget = vmConfigEnvironment.configTarget;
            switch (deviceType) {
               case "VirtualUSBController":
                  createdInflatedDeviceContainer =
                        usbControllerService.makeDefaultUSBController(
                              guestOsDescriptor,
                              vmConfigEnvironment.configOption.hardwareOptions.virtualDeviceOption,
                              virtualMachineDevices, additionalParameters);
                  break;
               case "VirtualUSB":
                  var hostUsbList = configTarget.usb;

                  var usb20 = virtualMachineDevices.devicesNotMarkedForRemovalOfType('VirtualUSBController');
                  var usb30 = virtualMachineDevices.devicesNotMarkedForRemovalOfType('VirtualUSBXHCIController');

                  if (_.isEmpty(usb20) && _.isEmpty(usb30)) {
                     this.createNewDevice('VirtualUSBController', vmConfigEnvironment, guestOsDescriptor, virtualMachineDevices, additionalParameters);
                  }

                  createdInflatedDeviceContainer = usbDeviceService.makeUSBDevice(
                        deviceOptions,
                        hostUsbList,
                        mergedInflatedDevices
                  );

                  break;

               case deviceTypeConstants.CDROM:
                  createdInflatedDeviceContainer = opticalDriveService.makeDefaultOpticalDrive(
                        vmConfigEnvironment,
                        guestOsDescriptor,
                        mergedInflatedDevices);
                  break;
               case deviceTypeConstants.VIRTUALDISK:
                  createdInflatedDeviceContainer =
                        diskProvisioningService.makeDefaultVirtualDisk(
                              vmConfigEnvironment,
                              guestOsDescriptor,
                              mergedInflatedDevices,
                              additionalParameters);
                  break;
               case deviceTypeConstants.RDMDISK:
                  var deferredRDM = $q.defer();
                  lunSelectorDialogService.openLunSelectorDialog(virtualMachineDevices.getRdmDisksNotMarkedForRemoval(),
                        vmConfigEnvironment, function (selectedItem) {
                           if (!selectedItem || _.isEmpty(selectedItem)) {
                              return ;
                           }
                           var createdInflatedDeviceContainer =
                                 diskProvisioningService.makeDefaultRDMDisk(
                                       vmConfigEnvironment,
                                       guestOsDescriptor,
                                       mergedInflatedDevices,
                                       additionalParameters,
                                       selectedItem[0]);
                           deferredRDM.resolve(addDevice(createdInflatedDeviceContainer, virtualMachineDevices));
                        });
                  return deferredRDM.promise;
               case deviceTypeConstants.EXISTINGHARDDISK:
                  var deferredExisting = $q.defer();
                  existingHardDiskSelectorDialogService.openExistingDiskSelectorDialog(vmConfigEnvironment, function (selectedFile) {
                     createdInflatedDeviceContainer = diskProvisioningService.makeDefaultVirtualDiskFromExistingDisk(
                           vmConfigEnvironment,
                           guestOsDescriptor,
                           mergedInflatedDevices,
                           additionalParameters,
                           selectedFile
                     );

                     if (createdInflatedDeviceContainer.device.isDiskAlreadyEncrypted()
                        && !vmCryptUtilService.isVmHomeEncrypted(
                           additionalParameters.vmConfigContext)) {
                        addEncryptedDiskToUnencryptedVm(deferredExisting, additionalParameters,
                           createdInflatedDeviceContainer, virtualMachineDevices);
                     } else {
                        deferredExisting.resolve(addDevice(createdInflatedDeviceContainer, virtualMachineDevices));
                     }
                  });
                  return deferredExisting.promise;
               case deviceTypeConstants.ETHERNET:
                  createdInflatedDeviceContainer =
                        networkAdapterService.makeDefaultNetworkAdapter(
                              vmConfigEnvironment,
                              guestOsDescriptor);
                  break;

               case deviceTypeConstants.VirtualPCIPassthrough:
                  createdInflatedDeviceContainer = pciSharedService.createPciDevice(vmConfigEnvironment, virtualMachineDevices);
                  break;

               case deviceTypeConstants.VirtualPCIPassthrough + "Shared":
                  createdInflatedDeviceContainer = pciSharedService.createPciSharedDevice(vmConfigEnvironment, virtualMachineDevices);
                  break;

               case deviceTypeConstants.SCSICONTROLLER:
                  createdInflatedDeviceContainer =
                        scsiControllerService.makeDefaultSCSIController(
                              guestOsDescriptor,
                              vmConfigEnvironment.configOption.hardwareOptions.virtualDeviceOption,
                              virtualMachineDevices);
                  break;
               case deviceTypeConstants.NVME_CONTROLLER:
                  createdInflatedDeviceContainer =
                        nvmeControllerService.makeDefaultNVMeController(
                              guestOsDescriptor,
                              vmConfigEnvironment.configOption.hardwareOptions.virtualDeviceOption,
                              virtualMachineDevices,
                              vmConfigEnvironment.powerState);
                  break;
               case deviceTypeConstants.VIRTUAL_SATA_CONTROLLER:
                  createdInflatedDeviceContainer =
                     sataControllerService.makeDefaultSataController(
                        vmConfigEnvironment.configOption.hardwareOptions.virtualDeviceOption,
                        virtualMachineDevices);
                  break;
               case deviceTypeConstants.TPM:
                  createdInflatedDeviceContainer =
                        tpmProvisioningService.makeDefaultTpm(additionalParameters.serverGuid,
                              additionalParameters.callback);
                  break;
               case deviceTypeConstants.NVDIMM:
                  createdInflatedDeviceContainer =
                        nvdimmProvisioningService.makeDefaultNvdimm(vmConfigEnvironment,
                              guestOsDescriptor,
                              virtualMachineDevices,
                              additionalParameters);
                  break;
               case deviceTypeConstants.VIRTUAL_SERIAL_PORT:
                  createdInflatedDeviceContainer = serialPortService.makeDefaultSerialPort(
                        vmConfigEnvironment, virtualMachineDevices);
                  break;
               default:
                  throw new Error('Unknown device: ' + deviceType);
            }
            return $q.when(addDevice(createdInflatedDeviceContainer, virtualMachineDevices));
         }
      };

      function addDevice(createdInflatedDeviceContainer, virtualMachineDevices) {
         if (!createdInflatedDeviceContainer || !createdInflatedDeviceContainer.device) {
            return;
         }

         var virtualDeviceForDeviceController =
               createdInflatedDeviceContainer.controller;
         if (virtualDeviceForDeviceController) {
            if(!virtualMachineDevices.getVirtualDevice(
                  virtualDeviceForDeviceController.getKey())) {
               virtualMachineDevices.addVirtualDevice(virtualDeviceForDeviceController);
            }
         }

         virtualMachineDevices.addVirtualDevice(createdInflatedDeviceContainer.device);
         return createdInflatedDeviceContainer.device.getKey();
      }

      function addEncryptedDiskToUnencryptedVm(promise, additionalParameters, createdInflatedDeviceContainer, virtualMachineDevices) {
         clarityModalService.openConfirmationModal({
            message: i18nService.getString('VmUi', 'VmCryptConfig.alert.addEncryptedDisk'),
            clarityIcon: {
               shape: "warning",
               class: "is-warning",
               size: 48
            },
            showXIcon: false,
            preserveNewlines: true,
            saveButtonLabel: i18nService.getString("Common", "yes.label"),
            cancelButtonLabel: i18nService.getString("Common", "no.label"),
            submit: function() {
               // Choose an encryption profile
               if (!additionalParameters.storageProfiles
                  || additionalParameters.storageProfiles.$$state.status !== 1
                  || !additionalParameters.storageProfiles.$$state.value) {
                  promise.reject();
                  return;
               }

               var encryptionProfile = vmCryptUtilService.findEncryptionProfile(
                  additionalParameters.storageProfiles.$$state.value);
               if (!encryptionProfile) {
                  promise.reject();
                  showWarning(i18nService.getString('VmUi', 'VmCryptConfig.reason.noEncryptionPolicies'));
                  return;
               }

               // Check KMS servers availability
               vmCryptUtilService.getDefaultKmipServerStatus(additionalParameters.serverGuid, true)
                  .then(function(response) {
                     // When the user doesn't have permission to aggregate the KMS statuses,
                     // don't stop him because he will still be able to create encrypted VM
                     // if the KMS servers are OK.
                     var noPermission = response && response.h5DefaultKmipClusterStatus
                        && response.h5DefaultKmipClusterStatus.noPermission;

                     var isConnectionNormal = response && response.h5DefaultKmipClusterStatus &&
                        response.h5DefaultKmipClusterStatus.connectionStatus === h5_vm.ConnectionStatus.CONNECTED;
                     var isCertificateExpired = response && response.h5DefaultKmipClusterStatus &&
                        response.h5DefaultKmipClusterStatus.certificateStatus === h5_vm.CertStatus.EXPIRED;

                     if (!noPermission && !isConnectionNormal) {
                        promise.reject();
                        showWarning(i18nService.getString('VmUi', 'VmCryptConfig.reason.noKms'));
                        return;
                     }
                     if (!noPermission && isCertificateExpired) {
                        promise.reject();
                        showWarning(i18nService.getString('VmUi', 'VmCryptConfig.reason.kmsCertificateRequiresUpdate'));
                        return;
                     }

                     addEncryptedDisk(promise, encryptionProfile, additionalParameters,
                           createdInflatedDeviceContainer, virtualMachineDevices);
                  }, function() {
                     // if the kms status can not be retrieved proceed as if
                     // the status is correct
                     addEncryptedDisk(promise, encryptionProfile, additionalParameters,
                           createdInflatedDeviceContainer, virtualMachineDevices);
                  });
            },
            onCancel: function () {
               promise.reject();
            }
         });
      }

      function addEncryptedDisk(promise, encryptionProfile, additionalParameters, createdInflatedDeviceContainer, virtualMachineDevices) {
         // Encrypt the VM
         var encryptionProfileSpec =
               storageProfileService.makeProfile(encryptionProfile.id);
         additionalParameters.vmConfigContext.vmProfile = [encryptionProfileSpec];

         // Assign the encryption profile to the disk
         createdInflatedDeviceContainer.device.setOriginalProfile(encryptionProfileSpec);
         createdInflatedDeviceContainer.device.updateDiskProfile(encryptionProfile);

         promise.resolve({
            newVmProfile: encryptionProfile,
            newDeviceKey: addDevice(createdInflatedDeviceContainer, virtualMachineDevices)
         });
      }

      function showWarning(message) {
         clarityModalService.openConfirmationModal({
            message: message,
            clarityIcon: {
               shape: 'warning',
               class: 'is-warning',
               size: 48
            },
            showXIcon: false,
            hideCancelButton: true,
         });
      }
   }
]);
