module ds_cluster_ui {

   export class EnterMaintenanceModeFaultsWarningComponent {
      public bindings: any;
      public controller: any;
      public templateUrl: string;

      constructor() {
         this.bindings = {
            faults: "<"
         };

         this.controller = EnterMaintenanceModeFaultsWarningController;
         this.templateUrl =
               "ds-cluster-ui/resources/ds-cluster/views/maintenanceMode/" +
               "enterMaintenanceModeFaultsWarningView.html";
      }
   }

   class EnterMaintenanceModeFaultsWarningController {

      public static $inject = [
         "$scope",
         "vuiConstants",
         "drsFaultsViewConstants",
         "i18nService",
         "datastoreMaintenanceModeService"];

      private faultDetailsGridOptions: any;
      private i18n: any;
      private faults: any;

      constructor(private $scope: any,
                  private vuiConstants: any,
                  private drsFaultsViewConstants: any,
                  private i18nService: any,
                  private datastoreMaintenanceModeService: any) {
         this.i18n = this.i18nService.getString;
      }

      $onInit(): void {
         this.faultDetailsGridOptions =
               this.datastoreMaintenanceModeService.getFaultDetailsGridOptions(this.faults);
      }
   }

   angular.module("com.vmware.vsphere.client.dsCluster")
         .component("enterMaintenanceModeFaultsWarning",
               new EnterMaintenanceModeFaultsWarningComponent());
}