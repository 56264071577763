namespace h5_vm {

   import VmTurnOffFtSpec = com.vmware.vsphere.client.vm.ft.VmTurnOffFtSpec;

   interface VmFtCompatibilityClusterData {
      isDrsEnabled: boolean;
      isEvcEnabled: boolean;
   }

   interface VmFtCompatibilityData {
      clusterData: VmFtCompatibilityClusterData;
      managedByInfo: any;
   }

   const VM_FT_COMPATIBILITY_MODEL: string =
         "com.vmware.vsphere.client.vm.model.VmFtCompatibilityData";

   const VM_WARNINGS: string = "managedByVmWarnings";

   export class VmFtService {

      static $inject: string[] = ["i18nService", "mutationService",
            "actionConfirmationService", "dataService"];

      constructor(private i18nService: any,
            private mutationService: any,
            private actionConfirmationService: any,
            private dataService: any) {
      }

      public turnOffFt(vmId: string[]): void {
         this.dataService.getData(vmId, VM_FT_COMPATIBILITY_MODEL)
               .then((vmFtData: VmFtCompatibilityData) => {
                  let extensionLabel: string =
                        this.getManagedByWarningText(vmFtData.managedByInfo);

                  let warning: string = this.i18nService.getString("VmUi",
                        "ftTurnOffAction.warning");

                  if (vmFtData.clusterData.isDrsEnabled
                        && !vmFtData.clusterData.isEvcEnabled) {
                     warning = this.i18nService.getString("VmUi",
                           "ftTurnOffAction.drsWarning");
                  }

                  if (extensionLabel !== "") {
                     warning = extensionLabel + "\n\n" + warning;
                  }

                  this.actionConfirmationService.showConfirmationDialog({
                     title: this.i18nService.getString("VmUi", "ftTurnOffAction.label"),
                     message: warning,
                     yesHandler: () => {
                        let turnOffFtSpec: VmTurnOffFtSpec = new VmTurnOffFtSpec();

                        this.mutationService.apply(vmId, turnOffFtSpec._type, turnOffFtSpec);
                     },
                     useClarity: true
                  });
               });
      }

      private getManagedByWarningText(managedByData: any): string {
         if (managedByData === null) {
            return "";
         }

         let managedByLabels: string[] = _.map(managedByData, (item: any) => {
            if (item !== null && !item.isUnset) {
               return item.value;
            }

            if (item !== null) {
               return item;
            }
         });

         // None of the VMs or vApps were managed, simply return
         if (_.isEmpty(managedByLabels)) {
            return "";
         }

         let text: string = managedByData.length > 1 ?
               this.i18nService.getString("Common", "multiVmManagedByWarning") :
               this.i18nService.getString("Common", "singleVmManagedByWarning",
                     managedByLabels[0]);

         return text;
      }
   }

   angular.module('com.vmware.vsphere.client.vm')
         .service('vmFtService', VmFtService);
}
