/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace network_ui {

   class VmMigrateReadyToCompletePageController {

      public static $inject = [
            "$scope",
            "i18nService",
            "vmMigrateWizardConstants",
            "vxPropertyViewService"
      ];

      constructor(private $wizardScope: VmMigrateWizardScope,
                  private i18nService: any,
                  private wizardConstants: any,
                  private vxPropertyViewService: any) {

         let builder: any = vxPropertyViewService.createPropertyViewBuilder();

         let generalCategory: any = builder.category("general");
         let generalSection: any = generalCategory.section("generalSection");

         // Source Network
         if (this.$wizardScope.flags.noSourceNetworkSelected) {
            generalSection.property(this.i18nService.getString(
                  "H5NetworkUi", "VmMigrateWizard.summary.source"),
                  this.i18nService.getString(
                        "H5NetworkUi", "VmMigrateWizard.summary.noNetwork"));
         } else {
            generalSection.property(this.i18nService.getString(
                  "H5NetworkUi", "VmMigrateWizard.summary.sourceNetwork"),
                  this.$wizardScope.model.sourceNetwork.name);

         }

         // Destination Network
         generalSection.property(this.i18nService.getString(
               "H5NetworkUi", "VmMigrateWizard.summary.destinationNetwork"),
               this.$wizardScope.model.destinationNetwork.name);

         // The number of VMs to migrate
         generalSection.property(this.i18nService.getString(
               "H5NetworkUi", "VmMigrateWizard.summary.numberOfVmsToMigrate"),
               this.getNumVms(this.$wizardScope.model.vmListItems));

         // The number of virtual NICs to migrate
         generalSection.property(this.i18nService.getString(
               "H5NetworkUi", "VmMigrateWizard.summary.numberOfAdaptersToMigrate"),
               this.getNumVnics(this.$wizardScope.model.vmListItems));


         this.$wizardScope.model.summaryData = builder.build();
      }

      private getNumVms(vmItemsData: any): string {
         // only used for duplicate lookup
         const itemsById: any = {};

         let count: number = 0;

         for (const vmItem of vmItemsData) {
            if (!itemsById[vmItem.vmId] && vmItem.isChecked) {
               count++;
               itemsById[vmItem.vmId] = vmItem;
            }
         }

         return String(count);
      }

      private getNumVnics(vmItemsData: any): string {
         let numVnics: number = 0;

         if (vmItemsData) {
            let vmVnicItems = _.filter(vmItemsData, function(item: any) {
               return item.isChecked && item.virtualEthernetCard;
            });

            numVnics = vmVnicItems.length;
         }
         return numVnics.toString();
      }
   }

   angular.module("com.vmware.vsphere.client.network")
         .controller("VmMigrateReadyToCompletePageController",
               VmMigrateReadyToCompletePageController);
}
