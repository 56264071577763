namespace h5_vm {

   import VirtualVideoCard = com.vmware.vim.binding.vim.vm.device.VirtualVideoCard;
   import VirtualVideoCardOption = com.vmware.vim.binding.vim.vm.device.VirtualVideoCardOption;
   import GuestOsDescriptor = com.vmware.vim.binding.vim.vm.GuestOsDescriptor;
   import BoolOption = com.vmware.vim.binding.vim.option.BoolOption;
   import LongOption = com.vmware.vim.binding.vim.option.LongOption;
   import NumberFormatOptions = Intl.NumberFormatOptions;
   import VirtualDeviceSpec = com.vmware.vim.binding.vim.vm.device.VirtualDeviceSpec;
   import VirtualDeviceSpec$Operation = com.vmware.vim.binding.vim.vm.device.VirtualDeviceSpec$Operation;

   class RENDERER {
      static readonly AUTOMATIC:string = "automatic";
      static readonly SOFTWARE:string = "software";
      static readonly HARDWARE:string = "hardware";
   }

   export class VirtualVideoCardClass {

      rawDevice: VirtualVideoCard;
      private vmConfig: VmConfig;
      private base: any;
      private option: VirtualVideoCardOption|null;
      private guest: GuestOsDescriptor;
      private i18nService: any;
      private originalDevice: VirtualVideoCard;
      private isScheduledTask: boolean;
      private readonly OP_ADD:VirtualDeviceSpec$Operation = "add";


      constructor(video: VirtualVideoCard, isScheduledTask: boolean = false) {
         this.base = this;
         this.rawDevice = video as VirtualVideoCard;
         this.isScheduledTask = isScheduledTask;
         this.originalDevice = angular.copy(video);
      }

      public attachVmConfig(value: VmConfig): void {
         if (this.vmConfig || !value) {
            return;
         }

         this.vmConfig = value;
         this.onVmConfigAttached();
      }

      private onVmConfigAttached(): void {
         this.rawDevice = this.base.getCurrentDevice();

         if (!this.vm) {
            return;
         }

         this.option = this.vm.getVideoCardOption();
         this.guest = this.vm.getGuestOsDescriptror();
         this.i18nService = this.vm.getI18nService();

         if (this.vm.isCreate) {
            VirtualVideoCardClass.fixNewVideoCard(this.rawDevice, this.option, this.guest,
                  this.isScheduledTask);
            this.fixDeviceSpecOp();
         }
      }

      private fixDeviceSpecOp():void {
         let spec:VirtualDeviceSpec = this.base.getCurrentDeviceSpec() as VirtualDeviceSpec;

         if (this.vm.isCreate) {
            spec.operation = this.OP_ADD;
         }

      }

      private static fixNewVideoCard(video: VirtualVideoCard, option: VirtualVideoCardOption|null,
            guest: GuestOsDescriptor, isScheduledTask: boolean = false) {
         if (!video) {
            return;
         }

         if (!video.useAutoDetect) {
            video.useAutoDetect = false;
         }

         if (!video.numDisplays) {
            video.numDisplays = 1;
         }

         if (!video.videoRamSizeInKB && guest) {
            video.videoRamSizeInKB = guest.vRAMSizeInKB.defaultValue;
         }

         // In case the device is restored from a scheduled task
         // use the device's enable3DSupport value
         // Otherwise use virtual HW default as starting point
         // defaultValue seems to be false for HWv13, but true for HWv14??
         // Note that this is guest-agnostic default value.
         // Alternatively we could use guest.recommended3D - TBD
         if (!isScheduledTask) {
            video.enable3DSupport = option ? option.support3D.defaultValue : false;
         }

         if (guest && !guest.supports3D) {
            // Override vHW default, force enable3D to false if unsupported by guest,
            video.enable3DSupport = false;
         }

         if (!video.use3dRenderer && option && option.use3dRendererSupported) {
            if (option.use3dRendererSupported.supported) {
               video.use3dRenderer = RENDERER.AUTOMATIC;
            }
         }

         if (!video.graphicsMemorySizeInKB && option) {
            if (option.graphicsMemorySizeSupported
                  && option.graphicsMemorySizeSupported.supported) {
                  // && option.graphicsMemorySizeSupported.defaultValue) {
               video.graphicsMemorySizeInKB = option.graphicsMemorySizeInKB.defaultValue;
            }
         }
      }

      public i18n(key: string, ...rest: Array<any>): string {
         if (this.i18nService) {
            return this.i18nService.getString('VmUi', 'VideoCardPage.' + key, rest);
         } else {
            return "";
         }
      }

      public get vm(): VmConfig {
         return this.vmConfig;
      }

      public hasChanged(): boolean {

         if (!this.rawDevice) {
            return false;
         } else {
            return this.base.hasChanged();
         }
      }

      public isEditDeviceDisabled(): boolean {
         if (!this.vm) {
            return true;
         }
         return this.vm.isEditDeviceDisabled();
      }

      private reduceKB2MB(value: number): number {
         if (!value) {
            return 0;
         }
         let retVal: number = value / 1024;
         return retVal;
      }

      private increaseMB2KB(value: number): number {
         if (!value) {
            return 0;
         }
         let retVal: number = value * 1024;
         return retVal;
      }

      private formatKB2MB(valueInKB: number): string {

         if (valueInKB === null) {
            return "";
         }
         if (angular.isUndefined(valueInKB)) {
            return "";
         }

         let megabytes: number = this.reduceKB2MB(valueInKB);
         return megabytes.toString();
      }

      // PART 1 - AUTODETECT ------------------------------------------------------------

      useAutoDetect_List(): Array <boolean> {
         return [true, false];
      }

      public useAutoDetect_Text(value: boolean): string {
         if (value) {
            return this.i18n('AutoDetect');
         } else {
            return this.i18n('SpecifyCustom');
         }
      }

      set useAutoDetect(value: boolean) {
         this.rawDevice.useAutoDetect = value;
      }

      get useAutoDetect(): boolean {
         return this.rawDevice.useAutoDetect;
      }

      public useAutoDetect_isEditDisabled(): boolean {
         return this.isEditDeviceDisabled();
      }

      // PART 2 - VIDEO MEMORY ----------------------------------------------------------

      // videoRamSizeInKB maximum was 128MB for VM versions 4,7 and 8

      // In VM version 8 VMware added 3D graphics
      // Then we realized that 128MB is tight for 3D graphics
      // So, in VM version 9 max for videoRam was increase to 512.

      // And same was for VM version 10 - 512MB max

      // Then for VM version 11, dedicated graphics memory was
      // introduced to handle 3D.
      // At the same time max videoRamSize dropped back to 128MB

      public videoRamSizeInKB_isEditDisabled(): boolean {
         if (this.useAutoDetect) {
            return true;
         }
         return this.isEditDeviceDisabled();
      }

      private videoRamSizeText: string|null = null;

      public get videoRamSizeInKB(): string {
         if (this.videoRamSizeText === null) {
            this.initVideoRamSizeText();
         }
         if (this.videoRamSizeText === null) {
            return "";
         }
         return this.videoRamSizeText;
      }

      private initVideoRamSizeText():void {

         if (!this.rawDevice) {
            return;
         }

         let valueInKB = this.rawDevice.videoRamSizeInKB;
         this.videoRamSizeText = this.formatKB2MB(valueInKB);
      }

      public set videoRamSizeInKB(textMB: string) {
         if (textMB === this.videoRamSizeText) {
            return;
         }
         this.videoRamSizeText = textMB;
         let valueInMB: number = parseFloat(textMB);
         let valueInKb = this.increaseMB2KB(valueInMB);
         this.videoRamSizeInKB_Error = this.videoRamSizeInKB_validate(valueInKb);
         this.rawDevice.videoRamSizeInKB = this.increaseMB2KB(valueInMB);
         return;
      }

      private videoRamSizeInKB_Error: string = "";

      public videoRamSizeInKB_isInvalid(): boolean {
         if (this.videoRamSizeInKB_Error) {
            return true;
         } else {
            return false;
         }
      }

      public videoRamSizeInKB_getError(): string {
         return this.videoRamSizeInKB_Error;
      }

      private videoRamSizeInKB_validate(value: number): string {

         if (!this.option) {
            return "";
         }

         let max = this.option.videoRamSizeInKB.max;
         let min = this.option.videoRamSizeInKB.min;

         if (value < min || value > max) {
            return this.formatRangeError(min / 1024, max / 1024, 'TotalVideoMemory');
         } else {
            return "";
         }
      }

      // PART 3 - NUMBER OF DISPLAYS (numDisplays) --------------------------------------

      // For VM version 4 we support only one display (aka monitor).
      // Since VM version 7, max coming as 10.
      // But for some reasons Flex UI hard limited numDisplays to 4.

      public get numDisplays(): number {
         return this.rawDevice.numDisplays;
      }

      public set numDisplays(value: number) {
         if (this.rawDevice.numDisplays === value) {
            return;
         }

         let oldValue: number = this.rawDevice.numDisplays;
         this.rawDevice.numDisplays = value;
         this.afterNumDisplaysChanged(oldValue, value);
      }


      private afterNumDisplaysChanged(oldValue: number, newValue: number): void {

         let delta: number = newValue - oldValue;

         if (!this.enable3DSupport) {
            return;
         }

         if (this.graphicsMemorySupported()) {
            return;
         }

         this.bumpUpVideoRamByMB(delta * 16);
      }


      public numDisplays_isEditDisabled(): boolean {
         if (this.useAutoDetect) {
            return true;
         }
         return this.isEditDeviceDisabled();
      }

      public numDisplays_getList(): Array<number> {
         if (!this._numDisplays_List_Cached) {
            this.numDisplays_List_init();
         }
         return this._numDisplays_List_Cached;
      }

      private _numDisplays_List_Cached: Array<number>;

      private numDisplays_List_init(): void {
         let max = this.get_numDisplays_max();
         let array: Array<number> = [];

         for (let value: number = 1; value <= max; value++) {
            array.push(value);
         }

         this._numDisplays_List_Cached = array;
      }

      private get_numDisplays_max(): number {

         let retVal: number = 0;

         if (this.option && this.option.numDisplays) {
            retVal = this.option.numDisplays.max;
         }

         if (!retVal) {
            retVal = 4;
         }

         return retVal;
      }

      //  -- PART 4 - enable3DSupport (true/false) ----------------------------------------------

      public enable3DSupport_isVisible(): boolean {

         if (this.enable3DSupport) {
            // already enabled, so we better show it
            return true;
         }

         return true;
      }

      public enable3DSupport_isEditDisabled(): boolean {

         if (this.isEditDeviceDisabled()) {
            return true;
         }

         if (this.enable3DSupport) {
            return false;
         }

         if (this.guestLacks3DSupport()) {
            return true;
         }
         return false;
      }

      public get enable3DSupport(): boolean {
         return this.rawDevice.enable3DSupport;
      }

      public set enable3DSupport(value: boolean) {

         if (value === this.rawDevice.enable3DSupport) {
            return;
         }

         if (this.enable3DSupport_isEditDisabled()) {
            return;
         }


         this.rawDevice.enable3DSupport = value;
         this.afterEnable3DSupportChanged(value);

      }

      private afterEnable3DSupportChanged(value:boolean): void {

         if (this.graphicsMemorySupported()) {
            return;
         }

         if (value) {
            this.bump2dMemoryFor3dOps();
         } else {
            this.restore2dMemory();
         }
      }

      // Logic to bump videoRamSize when user enables 3D support - ----------------------

      private preBumpVideoMemoryInKB: number|undefined = undefined;

      private bump2dMemoryFor3dOps(): void {
         this.preBumpVideoMemoryInKB = this.rawDevice.videoRamSizeInKB;

         let rawNumDisplays: number = this.rawDevice.numDisplays;
         let newValueInMB: number = 64 + (rawNumDisplays - 1) * 16;
         this.bumpVideoRamToMB(newValueInMB);

         if (rawNumDisplays === 1) {
            this.autoIncreaseInfoText = this.i18n('3DAutoIncrease');
         } else {
            this.autoIncreaseInfoText = this.i18n('3DAutoIncrease2');
         }

         this.autoIncreaseInfoVisible = true;
      }

      private restore2dMemory() {
         if (this.preBumpVideoMemoryInKB) {
            this.updateVideoRamSize(this.preBumpVideoMemoryInKB);
            this.preBumpVideoMemoryInKB = undefined;
         }
         this.autoIncreaseInfoVisible = false;
         this.autoIncreaseInfoText = "";
      }

      private autoIncreaseInfoText: string = "";
      private autoIncreaseInfoVisible: boolean = false;

      public getAutoIncreaseText(): string {
         return this.autoIncreaseInfoText;
      }

      public getAutoIncreaseVisible(): boolean {
         return this.autoIncreaseInfoVisible;
      }

      private graphicsMemorySupported(): boolean {

         if (!this.option) {
            return false;
         }

         let boolOption: BoolOption = this.option.graphicsMemorySizeSupported;

         if (!boolOption) {
            return false;
         }

         if (boolOption.supported) {
            return true;
         }
         return false;
      }

      private bumpVideoRamToMB(newValueInMB: number): void {
         let newValueInKB = this.trimVideoRamSize(newValueInMB * 1024);
         this.updateVideoRamSize(newValueInKB);
      }

      private bumpUpVideoRamByMB(bumpInMB: number): void {
         let oldValueInKB = this.rawDevice.videoRamSizeInKB;
         let newValueInKB: number = oldValueInKB + (bumpInMB * 1024);
         newValueInKB = this.trimVideoRamSize(newValueInKB);
         this.updateVideoRamSize(newValueInKB);
      }

      private updateVideoRamSize(newValueInKB: number): void {
         this.rawDevice.videoRamSizeInKB = newValueInKB;
         this.videoRamSizeText = null;
      }

      private trimVideoRamSize(valueInKb: number): number {
         let newValue: number = valueInKb;

         if (this.option && this.option.videoRamSizeInKB) {

            let max: number = this.option.videoRamSizeInKB.max;

            if (newValue > max) {
               newValue = max;
            }

            let min: number = this.option.videoRamSizeInKB.min;

            if (newValue < min) {
               newValue = min;
            }
         }
         return newValue;
      }

      private guestLacks3DSupport() {

         if (this.guest && !this.guest.supports3D) {
            return true;
         }

         return false;
      }

      // PART 5 - 3D RENDERER (software/hardware/auto) ----------------------------------

      public get use3dRenderer(): string {
         return this.rawDevice.use3dRenderer;
      }

      public set use3dRenderer(value: string) {
         this.rawDevice.use3dRenderer = value;
      }

      public use3dRenderer_List(): Array<string> {
         return [RENDERER.AUTOMATIC, RENDERER.HARDWARE, RENDERER.SOFTWARE];
      }

      public use3dRenderer_Text(value: string): string {
         return this.i18n(value);
      }

      public use3dRenderer_isEditDisabled(): boolean {

         if (this.isEditDeviceDisabled()) {
            return true;
         }

         if (this.guestLacks3DSupport()) {
            return true;
         }

         if (!this.enable3DSupport) {
            return true;
         }

         return false;

      }

      //   PART 6 - 3D MEMORY (MB) ------------------------------------------------------

      public graphicsMemorySizeInMB_isVisible(): boolean {
         if (!this.option) {
            return true;
         }
         let boolOption: BoolOption = this.option.graphicsMemorySizeSupported;

         if (boolOption && boolOption.supported) {
            return true;
         }
         return false;
      }

      public graphicsMemorySizeInMB_isEditDisabled(): boolean {

         if (this.isEditDeviceDisabled()) {
            return true;
         }

         if (this.guestLacks3DSupport()) {
            return true;
         }

         if (!this.enable3DSupport) {
            return true;
         }

         return false;
      }

      private _graphicsMemorySizeInMB: string|null = null;

      public get graphicsMemorySizeInMB(): string {

         if (this._graphicsMemorySizeInMB === null) {
            let value = this.rawDevice.graphicsMemorySizeInKB;
            let text: string = "";

            if (value === null) {
               text = "";
            } else {
               text = (value / 1024).toString();
            }

            this._graphicsMemorySizeInMB = text;
         }
         return this._graphicsMemorySizeInMB;
      }

      public set graphicsMemorySizeInMB(text: string) {
         this._graphicsMemorySizeInMB = text;
         let value = parseFloat(text);
         this.rawDevice.graphicsMemorySizeInKB = value * 1024;
         this.graphicsMemorySizeInMBError = this.graphicsMemorySizeInMB_getErrorText();
      }

      public graphicsMemorySizeInMB_isInvalid(): boolean {
         let txt: string = this.graphicsMemorySizeInMBError;
         if (txt && txt.length > 0) {
            return true;
         } else {
            return false;
         }
      }

      private graphicsMemorySizeInMBError: string = "";

      public graphicsMemorySizeInMB_getError(): string {
         this.graphicsMemorySizeInMBError = this.graphicsMemorySizeInMB_getErrorText();
         return this.graphicsMemorySizeInMBError;
      }

      private graphicsMemorySizeInMB_getErrorText(): string {

         const SUCCESS: string = "";

         if (!this.rawDevice) {
            return SUCCESS;
         }

         let valueInKB: number = this.rawDevice.graphicsMemorySizeInKB;

         if (angular.isUndefined(valueInKB)) {
            return SUCCESS;
         }

         if (!this.option) {
            return SUCCESS;
         }

         let optionInKB: LongOption = this.option.graphicsMemorySizeInKB;

         if (!optionInKB) {
            return SUCCESS;
         }

         let maxInKB: number = optionInKB.max;
         let minInKB: number = optionInKB.min;

         if (valueInKB >= minInKB && valueInKB <= maxInKB) {
            return SUCCESS;
         }

         return this.formatRangeError(minInKB / 1024, maxInKB / 1024, '3DMemory');
      }

      private formatRangeError(min: number, max: number, name: string) {
         name = this.i18n(name);

         let mbSuffix = " " + this.i18n("MB");

         let lower: string = min.toLocaleString() + mbSuffix;
         let upper: string = max.toLocaleString() + mbSuffix;

         let format: string = "Util.Validation.ValidRange"; //{0} must be between {1} and {2}.

         let retVal: string = this.i18nService.getString("VmUi", format, name, lower, upper);

         return retVal;
      }

      public use3dRenderer_isVisible(): boolean {

         if (!this.option) {
            return true;
         }

         let booleOption: BoolOption = this.option.use3dRendererSupported;

         if (booleOption && booleOption.supported) {
            return true;
         }

         return false;
      }
   }

   function GetVirtualVideoCardClass(EditableVirtualDevice: any): any {

      let factoryFn: Function = function (rawDevice: any, isScheduledTask: boolean = false): any {

         let instance = new VirtualVideoCardClass(rawDevice, isScheduledTask);
         EditableVirtualDevice.call(instance, rawDevice);

         return instance;
      };

      return factoryFn;
   }

   angular.module('com.vmware.vsphere.client.vm')
         .factory('VirtualVideoCard', ['EditableVirtualDevice', GetVirtualVideoCardClass]);
}

