/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace dvs_ui {

   import IPromise = angular.IPromise;
   import DvsPortEditTrafficFilterSpec = com.vmware.vsphere.client.h5.network.dvs.ports.model.DvsPortEditTrafficFilterSpec;
   import DvsPortAddTrafficRuleSpec = com.vmware.vsphere.client.h5.network.dvs.ports.model.DvsPortAddTrafficRuleSpec;
   import DvsPortRemoveTrafficRuleSpec = com.vmware.vsphere.client.h5.network.dvs.ports.model.DvsPortRemoveTrafficRuleSpec;
   import DvsPortEditTrafficRuleSpec = com.vmware.vsphere.client.h5.network.dvs.ports.model.DvsPortEditTrafficRuleSpec;
   import DvsPortTrafficFilterModel = com.vmware.vsphere.client.h5.network.dvs.ports.model.DvsPortTrafficFilterModel;
   import DvpgTrafficRuleModel = com.vmware.vsphere.client.h5.network.dvportgroup.trafficfilter.model.DvpgTrafficRuleModel;

   export class DvsPortTrafficFilterService {

      static $inject = [
         "i18nService",
         "clarityConstants",
         "dvpgTrafficFilterConstants",
         "dvpgTrafficFilterService",
         "dvpgTrafficRuleValidator",
         "dvsPortTrafficFilterSpecFactory",
         "clarityModalService",
         "dataService",
         "mutationService",
         "$q"
      ];

      constructor(private i18nService: any,
                  private clarityConstants: any,
                  private trafficFilterConstants: any,
                  private dvpgTrafficFilterService: any,
                  private dvpgTrafficRuleValidator: any,
                  private dvsPortTrafficFilterSpecFactory: any,
                  private clarityModalService: any,
                  private dataService: any,
                  private mutationService: any,
                  private $q: any) {
      }

      public showPortEditTrafficFilterDialog(
            portContextId: string,
            isPortGroupContext: boolean,
            selectedPort: any): void {

         let modalOptions: any = {
            title: this.i18nService.getString(
                  "DvsUi", "trafficFilter.rulesDialog.title"),
            subTitle: {
               objectId: portContextId
            },
            defaultButton: "submit",
            contentTemplate: "dvs-ui/resources/dvs/dvpg/trafficfilter/edit/" +
                  "dvpgEditTrafficFilterDialog.html",
            size: "lg",
            modalClass: "edit-traffic-filter-dialog",
            dialogData: {
               objectId: selectedPort.portId,
               model: new DvpgEditTrafficFilterModel()
            },
            onSubmit: () => {
               let isRulesOrderChanged: boolean =
                     this.dvpgTrafficFilterService.isRuleOrderChanged(
                     modalOptions.dialogData.model.rules,
                     modalOptions.dialogData.model.originalRulesKeys);
               let isRulsetEnabledChanged: boolean =
                     modalOptions.dialogData.model.initialTrafficRulesetEnabled !==
                     modalOptions.dialogData.model.isTrafficRulesetEnabled;
               let isRulsetOverrideChanged: boolean =
                     modalOptions.dialogData.model.initialIsTrafficRulesetOverwritten !==
                     modalOptions.dialogData.model.isTrafficRulesetOverwritten;

               if (isRulsetOverrideChanged ||
                     isRulsetEnabledChanged ||
                     isRulesOrderChanged) {

                  let dvsPortEditTrafficFilterSpec: DvsPortEditTrafficFilterSpec =
                        this.dvsPortTrafficFilterSpecFactory.buildEditTrafficFilterSpec(
                              selectedPort.portId,
                              modalOptions.dialogData.model,
                              isRulesOrderChanged);
                  this.mutationService.apply(
                        portContextId,
                        this.trafficFilterConstants.spec.DVS_PORT_EDIT_TRAFFIC_RULES_SPEC,
                        dvsPortEditTrafficFilterSpec);
               }

               return true;
            }
         };

         let portDataProperty: string = isPortGroupContext
               ? this.trafficFilterConstants.properties.DVPG_PORT_TRAFFIC_FILTER_PROPERTY
               : this.trafficFilterConstants.properties.DVS_PORT_TRAFFIC_FILTER_PROPERTY;

         let dvsPromise: IPromise<any>;

         if (isPortGroupContext) {
            dvsPromise = this.dataService.getProperties(
                  portContextId, [portDataProperty],{
                     propertyParams:  [{
                        propertyName: portDataProperty,
                        parameterType: "java.lang.String",
                        parameter: selectedPort.portId
                     }]
                  });
         } else {
            dvsPromise = this.dataService.getProperties(
                  portContextId, [portDataProperty],{
                     propertyParams:  [{
                        propertyName: portDataProperty,
                        parameterType: "java.util.Map",
                        parameter:  {
                           portgroupKey: selectedPort.portgroupKey,
                           portId: selectedPort.portId
                        }
                     }]
                  });
         }

         dvsPromise.then((response: any) => {
            let model: DvsPortTrafficFilterModel = response[portDataProperty];

            if (model) {
               modalOptions.dialogData.model.initialTrafficRulesetEnabled =
                     model.isTrafficRulesetEnabled;
               modalOptions.dialogData.model.isTrafficRulesetEnabled =
                     model.isTrafficRulesetEnabled;

               modalOptions.dialogData.model.showTrafficFilterOverride = true;
               modalOptions.dialogData.model.enableTrafficFilterOverride =
                     model.isTrafficRulesetOverrideEnabled;

               modalOptions.dialogData.model.initialIsTrafficRulesetOverwritten =
                     model.isTrafficRulesetOverwritten;
               modalOptions.dialogData.model.isTrafficRulesetOverwritten =
                     model.isTrafficRulesetOverwritten;

               modalOptions.dialogData.model.rules = model.rules;
               modalOptions.dialogData.model.originalRulesKeys =
                     this.dvpgTrafficFilterService.getRulesKeys(model.rules);
            } else {
               modalOptions.dialogData.model.rules = [];
               modalOptions.dialogData.model.originalRulesKeys = [];
            }
         });

         this.clarityModalService.openOkCancelModal(modalOptions);
      }

      public showPortAddRuleDialog(
            portContextId: string,
            isPortGroupContext: boolean,
            portgroupKey: string,
            portId: string): void {

         let onIpValidate: any = {};
         let onMacValidate: any = {};
         let modalOptions: any = {
            title: this.i18nService.getString(
                  "DvsUi", "trafficFilter.ruleDialog.title"),
            subTitle: {
               objectId: portContextId
            },
            defaultButton: "submit",
            contentTemplate: "dvs-ui/resources/dvs/dvpg/trafficfilter/add/" +
                  "dvpgAddTrafficRuleDialog.html",
            size: "lg",
            modalClass: "add-traffic-rule-dialog",
            dialogData: {
               availableTargets: [portContextId],
               objectId: portId,
               ipValidator: onIpValidate,
               macValidator: onMacValidate,
               newRuleModel: this.dvpgTrafficFilterService.initAddTrafficRuleModel(),
               trafficFiltersModel: new DvsPortTrafficFilterModel()
            },
            onSubmit: () => {
               return this.dvpgTrafficRuleValidator.getValidationResult(
                     modalOptions.dialogData.newRuleModel).then(
                           (errors: string[] | boolean) => {

                  modalOptions.dialogData.ipValidator.validate();
                  modalOptions.dialogData.macValidator.validate();

                  if (_.isArray(errors) && errors.length > 0) {

                     this.dvpgTrafficFilterService.showErrors(errors, modalOptions);
                     return false;
                  } else if (!errors) {
                     return false;
                  }

                  let dvsPortAddTrafficRuleSpec: DvsPortAddTrafficRuleSpec =
                        this.dvsPortTrafficFilterSpecFactory.buildAddTrafficRuleSpec(
                              portId,
                              modalOptions.dialogData.newRuleModel);
                  this.mutationService.apply(
                        portContextId,
                        this.trafficFilterConstants.spec.DVS_PORT_ADD_TRAFFIC_RULE_SPEC,
                        dvsPortAddTrafficRuleSpec);

                  return true;
               });
            }
         };

         let dataProperty: string = isPortGroupContext
               ? this.trafficFilterConstants.properties.DVPG_PORT_TRAFFIC_FILTER_PROPERTY
               : this.trafficFilterConstants.properties.DVS_PORT_TRAFFIC_FILTER_PROPERTY;

         let dvsPromise: IPromise<any>;

         if (isPortGroupContext) {
            dvsPromise = this.dataService.getProperties(
                  portContextId, [dataProperty],{
                     propertyParams:  [{
                        propertyName: dataProperty,
                        parameterType: "java.lang.String",
                        parameter: portId
                     }]
                  });
         } else {
            dvsPromise = this.dataService.getProperties(
                  portContextId, [dataProperty],{
                     propertyParams:  [{
                        propertyName: dataProperty,
                        parameterType: "java.util.Map",
                        parameter:  {
                           portgroupKey: portgroupKey,
                           portId: portId
                        }
                     }]
                  });
         }

         dvsPromise.then((response: any) => {
            let model: DvsPortTrafficFilterModel = response[dataProperty];

            if (model) {
               modalOptions.dialogData.trafficFiltersModel = model;
               modalOptions.dialogData.newRuleModel.name =
                     this.dvpgTrafficFilterService.getUniqueRuleName(model.rules);
            }
         });

         this.clarityModalService.openOkCancelModal(modalOptions);
      }

      public showPortEditRuleDialog(
            portContextId: string,
            isPortGroupContext: boolean,
            portId: string,
            editedRuleKey: string): void {

         let onIpValidate: any = {};
         let onMacValidate: any = {};
         let modalOptions: any = {
            title: this.i18nService.getString(
                  "DvsUi", "trafficFilter.ruleDialog.editTitle"),
            subTitle: {
               objectId: portContextId
            },
            defaultButton: "submit",
            contentTemplate: "dvs-ui/resources/dvs/dvpg/trafficfilter/add/" +
                  "dvpgEditTrafficRuleDialog.html",
            size: "lg",
            modalClass: "edit-traffic-rule-dialog",
            dialogData: {
               availableTargets: [portContextId],
               objectId: portId,
               ipValidator: onIpValidate,
               macValidator: onMacValidate,
               editRuleModel: this.dvpgTrafficFilterService.initNewTrafficRuleModel()
            },
            onSubmit: () => {
               return this.dvpgTrafficRuleValidator.getValidationResult(
                     modalOptions.dialogData.editRuleModel).then(
                     (errors: string[] | boolean) => {

                        modalOptions.dialogData.ipValidator.validate();
                        modalOptions.dialogData.macValidator.validate();

                        if (_.isArray(errors) && errors.length > 0) {
                           this.dvpgTrafficFilterService.showErrors(errors, modalOptions);
                           return false;
                        } else if (!errors) {
                           return false;
                        }

                        let dvsPortEditTrafficRuleSpec: DvsPortEditTrafficRuleSpec =
                              this.dvsPortTrafficFilterSpecFactory.buildEditTrafficRuleSpec(
                                    portId, modalOptions.dialogData.editRuleModel);
                        this.mutationService.apply(
                              portContextId,
                              this.trafficFilterConstants.spec.DVS_PORT_EDIT_TRAFFIC_RULE_SPEC,
                              dvsPortEditTrafficRuleSpec);

                        return true;
                     });
            }
         };

         let editRuleProperty: string = isPortGroupContext
               ? this.trafficFilterConstants.properties.
                     DVPG_PORT_EDIT_TRAFFIC_FILTER_PROPERTY
               : this.trafficFilterConstants.properties.
                     DVS_PORT_EDIT_TRAFFIC_FILTER_PROPERTY;

         let dvsPromise: IPromise<any> = this.dataService.getProperties(
               portContextId, [editRuleProperty],{
                  propertyParams:  [{
                     propertyName: editRuleProperty,
                     parameterType: "java.util.Map",
                     parameter:  {
                        ruleKey: editedRuleKey,
                        portId: portId
                     }
                  }]
               });

         dvsPromise.then((response: any) => {
            let ruleModel: DvpgTrafficRuleModel =
                  this.dvpgTrafficFilterService.initEditTrafficRuleModel(
                        response[editRuleProperty]);

            modalOptions.title =  this.i18nService.getString(
                  "DvsUi", "trafficFilter.ruleDialog.editTitleUpdated",
                  ruleModel.name);

            _.extend(ruleModel, {defaultQualifierTabIndex:
                  this.dvpgTrafficFilterService.getDefaultQualifierTabIndex(
                        ruleModel)});

            modalOptions.dialogData.editRuleModel = ruleModel;
         });

         this.clarityModalService.openOkCancelModal(modalOptions);
      }

      public removePortRule(
            portContextId: string,
            portId: string,
            ruleKey: string,
            ruleName: string): void {

         let submitFuncion: any = () => {
            let dvsPortRemoveTrafficRuleSpec: DvsPortRemoveTrafficRuleSpec =
                  this.dvsPortTrafficFilterSpecFactory.buildRemoveTrafficRuleSpec(
                        portId, ruleKey);
            this.mutationService.apply(
                  portContextId,
                  this.trafficFilterConstants.spec.DVS_PORT_REMOVE_TRAFFIC_RULE_SPEC,
                  dvsPortRemoveTrafficRuleSpec);
         };

         let modalOptions: any = {
            title: this.i18nService.getString("DvsUi",
                  "trafficFilter.ruleDialog.remove.caption"),
            message: (ruleName && ruleName.trim().length > 0) ?
                  this.i18nService.getString("DvsUi",
                        "trafficFilter.ruleDialog.remove.warning", ruleName) :
                  this.i18nService.getString("DvsUi",
                        "trafficFilter.ruleDialog.remove.noNameWarning"),
            submit: submitFuncion,
            subTitle: {
               objectId: portContextId
            },
            preserveNewlines: true,
            clarityIcon: {
               class: "is-warning",
               shape: "warning-standard",
               size: "32"
            }
         };

         this.clarityModalService.openConfirmationModal(modalOptions);
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .service("dvsPortTrafficFilterService", DvsPortTrafficFilterService);
}
