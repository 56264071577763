/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace dvs_ui {

   angular.module("com.vmware.vsphere.client.dvs").constant("dvpgCreateWizardConstants",
      {
      properties: {
         DVPG_NEW_UNIQUE_NAME: "newUniquePortGroupName",
         PRIMARY_ICON_ID: "primaryIconId",
         NAME_PROPERTY: "name",
         DVPG_PROPERTIES: "createdvpg:settingsdata"
      },
      portBinding: {
         EARLY_BINDING: "earlyBinding",
         EPHEMERAL: "ephemeral"
      },
      portAllocation: {
         DEFAULT_AUTO_EXPAND: true
      },
      numPorts: {
         MIN: 0,
         MAX: 8192,
         DEFAULT_NUMBER_OF_PORTS_FIXED: 128,
         DEFAULT_NUMBER_OF_PORTS_ELASTIC: 8
      },
      objectType: {
         DISTRIBUTED_SWITCH: "DistributedSwitch",
         ROOT_FOLDER: "RootFolder",
         DATACENTER: "Datacenter",
      },
      DVPG_DEFAULT_NETWORK_RESOURCE_POOL_KEY: "-1",
      MAX_ALLOWED_DVPG_NAME_LENGTH: 80,
      DC_DVS_NODE_TYPE_ID: "DcDvs",
      NETWORK_FOLDER_DVS_NODE_TYPE_ID: "NetworkFolderDvs"
   });
}
