/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace dvs_ui {

   import LacpGroupConfig = com.vmware.vim.binding.vim.dvs.VmwareDistributedVirtualSwitch$LacpGroupConfig;
   export class LacpConfigComponent {

      public bindings: any;
      public controller: any;
      public templateUrl: string;

      constructor() {
         this.bindings = {
            dvsId: "<"
         };

         this.controller = LacpConfigController;

         this.templateUrl = "dvs-ui/resources/dvs/lacp/lacpConfigTemplate.html";
      }
   }

   export class LacpConfigController {

      private static readonly LAGS_PROPERTY: string = "config.lacpGroupConfig";

      static $inject = ["dataService",
         "i18nService",
         "lagPropertiesService",
         "configurationService"];

      public dvsId: string;

      public hasPendingRequest: boolean;

      public liveRefreshProperties: string[] = [LacpConfigController.LAGS_PROPERTY];

      public lacpGroupConfig: LacpGroupConfig[];

      public hasSelection: boolean;

      public propertiesData: any;

      public selectedLagName: string;

      constructor(private dataService: any,
                  public i18n: any,
                  private lagPropertiesService: LagPropertiesService,
                  private configurationService: any) {
      }

      public $onInit() {
         this.getData();
      }

      public getData(): void {
         this.hasPendingRequest = true;
         this.dataService.getProperties(this.dvsId, LacpConfigController.LAGS_PROPERTY)
               .then((response: any): LacpGroupConfig[] => response[LacpConfigController.LAGS_PROPERTY])
               .then((value: LacpGroupConfig[]): void => {
                  if (!value) {
                     value = [];
                  }
                  this.lacpGroupConfig = value;
                  this.hasPendingRequest = false;
               });
      }

      public selectionChanged(lag: LacpGroupConfig): void {
         if (lag !== null) {
            this.propertiesData = this.lagPropertiesService.getPropertyViewData(lag);
            this.selectedLagName = lag.name;
            this.hasSelection = true;
         } else {
            this.hasSelection = false;
         }
      }

   }

   angular.module("com.vmware.vsphere.client.dvs")
         .component("lacpConfig", new LacpConfigComponent());
}

