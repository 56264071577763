namespace h5_vm {

   export class GosNetworkSettingsType {

      public readonly value: string;

      private static readonly STANDART_VALUE: string = "standard";
      private static readonly CUSTOM_VALUE: string = "custom";

      public static readonly STANDARD: GosNetworkSettingsType =
            new GosNetworkSettingsType(GosNetworkSettingsType.STANDART_VALUE);

      public static readonly CUSTOM: GosNetworkSettingsType
            = new GosNetworkSettingsType(GosNetworkSettingsType.CUSTOM_VALUE);

      /**
       * Used as default return value in fromString when invalid input is provided
       * @type {h5_vm.GosNetworkSettingsType}
       */
      public static readonly NONE: GosNetworkSettingsType = new GosNetworkSettingsType("");

      constructor(value: string) {
         this.value = value;
      }
   }
}