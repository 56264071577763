namespace h5_vm {
    export class FtVmPageService {
        static $inject: string[] = ["$rootScope",
            "i18nService",
            "mutationService",
            "defaultUriSchemeUtil",
            "vuiConstants",
            "vuiFtTurnOnWizard",
            "vmFtConstants"];

        private VIRTUAL_DEVICE_TYPE: string = "com.vmware.vim.binding.vim.vm.device.VirtualDisk";

        constructor(private $rootScope: any,
                    private i18nService: any,
                    private mutationService: any,
                    private defaultUriSchemeUtil: any,
                    private vuiConstants: any,
                    private vuiFtTurnOnWizard: VuiFtTurnOnWizard,
                    private vmFtConstants: any) {
        }

        public buildSelectDatastoresPage(wizardScope: any): any {
            return {
                title: this.i18nService.getString("VmUi", "turnOnFtWizard.selectDatastoresPage.title"),
                description: this.i18nService.getString("VmUi", "turnOnFtWizard.selectDatastoresPage.description"),
                contentUrl: "vm-ui/resources/vm/views/ft/ftSelectDatastoresView.html",
                onCommit: () => {
                    let noErrors: boolean = true;
                    if (!wizardScope.manager.getIsAdvancedMode()) {
                        if (wizardScope.manager.getSelectedDatastore()) {
                            if (wizardScope.manager.getNoHostsCompatible()) {
                                this.vuiFtTurnOnWizard.markPageIncompleteWithError(
                                      this.i18nService.getString(
                                            "VmUi",
                                            "turnOnFtWizard.error.noHostsForDatastoreSelection"
                                      )
                                );
                                noErrors = false;
                            } else {
                                this.vuiFtTurnOnWizard.markPageComplete();
                            }
                        } else {
                            this.vuiFtTurnOnWizard.markPageIncompleteWithError(
                                  this.i18nService.getString(
                                        "VmUi",
                                        "turnOnFtWizard.error.incompleteDatastoreSelection"
                                  )
                            );
                            noErrors = false;
                        }
                    } else {
                        if (wizardScope.manager.validateSelectedDatastores()) {
                            if (wizardScope.manager.getNoHostsCompatible()) {
                                this.vuiFtTurnOnWizard.markPageIncompleteWithError(
                                      this.i18nService.getString(
                                            "VmUi",
                                            "turnOnFtWizard.error.noHostsForDatastoreSelection"
                                      )
                                );
                                noErrors = false;
                            } else {
                                this.vuiFtTurnOnWizard.markPageComplete();
                            }
                        } else {
                            this.vuiFtTurnOnWizard.markPageIncompleteWithError(
                                  this.i18nService.getString(
                                        "VmUi",
                                        "turnOnFtWizard.error.incompleteDatastoreSelection"
                                  )
                            );
                            noErrors = false;
                        }
                    }

                    return noErrors;
                },
                disableNext: () => {
                    return wizardScope.wizardConfig.loading;
                }
            };
        }

        public buildSelectHostPage(wizardScope: any): any {
            return {
                title: this.i18nService.getString("VmUi", "turnOnFtWizard.selectHostPage.title"),
                description: this.i18nService.getString("VmUi", "turnOnFtWizard.selectHostPage.description"),
                contentUrl: "vm-ui/resources/vm/views/ft/ftSelectHostView.html",
                onCommit: () => {
                    let selectedHost: any = wizardScope.manager.getSelectedHost();
                    let hostHasErrors: boolean = this.hasHostAnyErrors(selectedHost);
                    if (selectedHost && !hostHasErrors) {
                        this.vuiFtTurnOnWizard.markPageComplete();
                        return true;
                    } else {
                        if (!selectedHost) {
                            this.vuiFtTurnOnWizard.markPageIncompleteWithError(
                                this.i18nService.getString(
                                    "VmUi",
                                    "turnOnFtWizard.error.noHostSelected"
                                )
                            );
                        } else if (hostHasErrors) {
                            this.vuiFtTurnOnWizard.markPageIncompleteWithError(
                                this.i18nService.getString(
                                    "VmUi",
                                    "turnOnFtWizard.error.invalidHostSelected"
                                )
                            );
                        }

                        return false;
                    }
                },
                disableNext: () => {
                    return wizardScope.wizardConfig.loading;
                }
            };
        }

        public buildReadyToCompletePage(wizardScope: any) {
            return {
                title: this.i18nService.getString("VmUi", "turnOnFtWizard.summaryPage.title"),
                description: this.i18nService.getString("VmUi", "turnOnFtWizard.summaryPage.description"),
                contentUrl: "vm-ui/resources/vm/views/ft/ftReadyToCompleteView.html",
                onCommit: () => {
                    let manager: FtVmTurnOnManager = wizardScope.manager;
                    let objectId = manager.getTargetUid();
                    this.mutationService.apply(objectId,
                        "com.vmware.vsphere.client.vm.ft.VmCreateSecondarySpec",
                        this.getVmCreateSecondarySpec(manager));
                    return true;
                },
                finishReady: () => {
                    return !wizardScope.wizardConfig.loading;
                }
            };
        }

        private getVmCreateSecondarySpec(manager: FtVmTurnOnManager) {
            let selectedHostMor =
                this.defaultUriSchemeUtil.getManagedObjectReference(
                    manager.getSelectedHost().id
                );

                return {
                    host: selectedHostMor,
                    configSpec: {
                        _type: "com.vmware.vim.binding.vim.vm.FaultToleranceConfigSpec",
                        metaDataPath: {
                            _type: "com.vmware.vim.binding.vim.vm.FaultToleranceMetaSpec",
                            metaDataDatastore: manager.getSelectedDatastores()[
                                  this.vmFtConstants.fileTypes.TIE_BREAKER].provider
                        },
                        secondaryVmSpec: {
                            _type: "com.vmware.vim.binding.vim.vm.FaultToleranceVMConfigSpec",
                            vmConfig: manager.getSelectedDatastores()[
                                  this.vmFtConstants.fileTypes.CONFIGURATION].provider,
                            disks: _.map(this.getAvailableDevices(manager.getDevices()), (item: any) => {
                                return {
                                    _type: "com.vmware.vim.binding.vim.vm.FaultToleranceVMConfigSpec$FaultToleranceDiskSpec",
                                    disk: item,
                                    datastore: manager.getSelectedDatastores()[
                                          this.vmFtConstants.fileTypes.VIRTUAL_DISK
                                          + item.key].provider
                                };
                            })
                        }
                    }
                };
        }

        private hasHostAnyErrors(host: any): boolean {
            return host && host.errors && host.errors.length > 0;
        }

        private getAvailableDevices(devices: any): any {
            return _.filter(devices, (device: any) => {
                return device._type === this.VIRTUAL_DEVICE_TYPE;
            });
        }
    }

    angular.module("com.vmware.vsphere.client.vm")
        .service("ftVmPageService", FtVmPageService);
}
