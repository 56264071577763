angular.module('com.vmware.vsphere.client.cluster')
.directive('resourceConfigControl',
   ['i18nService', 'resourceTypesConstants', 'bytesFilter', 'cpuClockFilter', 'numberFormatterService',
function (i18nService, resourceTypesConstants, bytesFilter, cpuClockFilter, numberFormatterService) {
   'use strict';

   return {
      scope: {
         resourceType: '@',
         resourceAllocationInfo: '=',
         reservationOptions: '=',
         limitOptions: '=',
         unitOptions: '=',
         reservationValueErrorMessages: '=',
         limitValueErrorMessages: '=',
         defaultUnit: '@?',
         expandableReservation: '=',
         reservationUsed: '=',
         actionType: '@',
         hasAutofocus: '@'
      },
      templateUrl: 'cluster-ui/resources/cluster/components/resourceConfigControl.html',
      controller: ['$scope',
         function($scope) {

            var CPU_BASE_MULTIPLIER = 1000;
            var MEM_BASE_MULTIPLIER = 10 * 1024;
            var CPU_RESPOOL_CPUCOUNT = 4;
            var MEM_RESPOOL_MEMCOUNT = 16;

            $scope.i18n = function(key) {
               return i18nService.getString('RpUi', key);
            };
            $scope.expanded = true;
            $scope.currentReservationUnit = {};
            $scope.currentLimitUnit = {};

            var initialMaxReservation = $scope.reservationOptions.maximum.value;
            var isInitialized = false;

            $scope.$watch('reservationOptions.maximum.value', function(newValue) {
               if (newValue !== -1 && !isInitialized) {
                  initialMaxReservation = newValue;
                  isInitialized = true;
               }
               $scope.printReservationMaxLabel();
            });
            $scope.$watch('limitOptions.maximum.value', function() {
               $scope.printLimitMaxLabel();
            });

            $scope.$watch('currentReservationUnit', function() {
               $scope.printReservationMaxLabel();
            });

            $scope.$watch('currentLimitUnit', function() {
               $scope.printLimitMaxLabel();
            });

            $scope.printReservationMaxLabel = function() {
               if (!$scope.currentReservationUnit.multiplier) {
                  if ($scope.resourceType === resourceTypesConstants.CPU) {
                     $scope.reservationMaxLabel = cpuClockFilter(
                           $scope.reservationOptions.maximum.value,
                           i18nService.getString('RpUi', 'rpCpu.MHz'));
                  } else {
                     $scope.reservationMaxLabel = bytesFilter(
                           $scope.reservationOptions.maximum.value, 'MB', 'MB');
                  }
               } else {
                  if ($scope.resourceType === resourceTypesConstants.CPU) {
                     $scope.reservationMaxLabel = numberFormatterService.format(
                           $scope.reservationOptions.maximum.value / $scope.currentReservationUnit.multiplier,
                           { suffix: $scope.currentReservationUnit.label });
                  } else {
                     $scope.reservationMaxLabel = bytesFilter(
                           $scope.reservationOptions.maximum.value,
                           'MB',
                           $scope.currentReservationUnit.label);
                  }
               }
            };

            $scope.printLimitMaxLabel = function() {
               if (!$scope.currentLimitUnit.multiplier) {
                  if ($scope.resourceType === resourceTypesConstants.CPU) {
                     $scope.limitMaxLabel = cpuClockFilter(
                           $scope.limitOptions.maximum.value,
                           i18nService.getString('RpUi', 'rpCpu.MHz'));
                  } else {
                     $scope.limitMaxLabel = bytesFilter(
                           $scope.limitOptions.maximum.value, 'MB', 'MB');
                  }
               } else {
                  if ($scope.resourceType === resourceTypesConstants.CPU) {
                     $scope.limitMaxLabel = numberFormatterService.format(
                           $scope.limitOptions.maximum.value / $scope.currentLimitUnit.multiplier,
                           { suffix: $scope.currentLimitUnit.label });
                  } else {
                     $scope.limitMaxLabel = bytesFilter(
                           $scope.limitOptions.maximum.value,
                           'MB',
                           $scope.currentLimitUnit.label);
                  }
               }
            };

            $scope.$watch('resourceAllocationInfo.reservation', function(newValue) {
               if (typeof newValue !== "undefined") {
                  if ($scope.actionType !== 'edit') {
                     $scope.limitOptions.minimum.value = newValue;
                  } else {
                     $scope.limitOptions.minimum.value = Math.max(newValue,
                           $scope.limitOptions.current.value);
                  }
                  if ($scope.resourceType === resourceTypesConstants.CPU) {
                     $scope.limitOptions.minimum.formattedValue = cpuClockFilter(
                           $scope.limitOptions.minimum.value, i18nService.getString(
                                 'RpUi', 'rpCpu.MHz'));
                  } else {
                     $scope.limitOptions.minimum.formattedValue = bytesFilter(
                           $scope.limitOptions.minimum.value, 'MB');
                  }
               }
            });

            $scope.$watch('resourceAllocationInfo.limit', function(newValue) {
               if (typeof newValue !== "undefined" &&
                     newValue >= $scope.limitOptions.minimum.value) {
                  var initialMaxValue = initialMaxReservation;
                  if (newValue <= initialMaxValue && newValue !== -1) {
                     initialMaxValue = newValue;
                  }

                  $scope.reservationOptions.maximum.value = initialMaxValue;

                  if ($scope.resourceType === resourceTypesConstants.CPU) {
                     $scope.reservationOptions.maximum.formattedValue = cpuClockFilter(
                           initialMaxValue, i18nService.getString('RpUi', 'rpCpu.MHz'));
                  } else {
                     $scope.reservationOptions.maximum.formattedValue = bytesFilter(
                           initialMaxValue, 'MB');
                  }

                  $scope.printReservationMaxLabel();
               }
            });

            $scope.$watchGroup(['expandableReservation', 'reservationUsed'], function () {
               if ($scope.expandableReservation !== undefined &&
                     $scope.reservationUsed !== undefined) {
                  $scope.reservationOptions.minimum.value =
                        $scope.expandableReservation ? 0 : $scope.reservationUsed;

                  var formattedValue;
                  if (!$scope.currentReservationUnit.multiplier) {
                     if ($scope.resourceType === resourceTypesConstants.CPU) {
                        formattedValue = cpuClockFilter($scope.reservationOptions.minimum.value,
                              i18nService.getString('RpUi', 'rpCpu.MHz'));
                     } else {
                        formattedValue = bytesFilter($scope.reservationOptions.minimum.value,
                              'MB', $scope.currentReservationUnit.label);
                     }
                  } else {
                     if ($scope.resourceType === resourceTypesConstants.CPU) {
                        formattedValue = numberFormatterService.format(
                              $scope.reservationOptions.minimum.value / $scope.currentReservationUnit.multiplier,
                              { suffix: $scope.currentReservationUnit.label });
                     } else {
                        formattedValue = bytesFilter($scope.reservationOptions.minimum.value,
                              'MB',
                              $scope.currentReservationUnit.label);
                     }
                  }

                  $scope.reservationOptions.minimum.formattedValue = formattedValue;
               }
            });

            $scope.getMultiplier = function() {
               if ($scope.resourceType === resourceTypesConstants.CPU) {
                  return CPU_RESPOOL_CPUCOUNT;
               } else {
                  return MEM_RESPOOL_MEMCOUNT;
               }
            };

            $scope.getDefaultShares = function() {
               if ($scope.resourceType === resourceTypesConstants.CPU) {
                  return CPU_BASE_MULTIPLIER;
               } else {
                  return MEM_BASE_MULTIPLIER;
               }
            };
         }]
   };
}]);
