/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
(function() {
   'use strict';

   angular
         .module('com.vmware.vsphere.client.network')
         .controller('Ipv6SettingsPageController', Ipv6SettingsPageController);

   Ipv6SettingsPageController.$inject = [
      '$scope',
      'dataService',
      'staticIpInputTypes',
      'i18nService'];

   function Ipv6SettingsPageController (
         $scope,
         dataService,
         staticIpInputTypes,
         i18nService) {

      this.defaultIpv6GatewayPropertyType = staticIpInputTypes.IPV6.DEFAULT_GATEWAY;

      this.initPage = function () {
         var ipv6RetrievedData = $scope.hostVnicIpRoutingInfo.value;

         $scope.defaultGateway = (ipv6RetrievedData && ipv6RetrievedData.ipv6DefaultGateway)
               ? ipv6RetrievedData.ipv6DefaultGateway
               : i18nService.getString('NetworkUi', 'NetworkUtil.noData');

         if (!$scope.hostVnicIpRoutingInfo.isIpv6Set) {
            $scope.addNetworkingSpec.overrideIpv6Gateway = false;
            $scope.addNetworkingSpec.ipv6DefaultGateway = ipv6RetrievedData.ipv6DefaultGateway;

            $scope.hostVnicIpRoutingInfo.isIpv6Set = true;
         }

         $scope.addNetworkingSpec.isGatewayFeatureSupported =
               ipv6RetrievedData.isGatewayFeatureSupported;

         this.listData = {};
         this.listData.ipv6List = $scope.addNetworkingSpec.ipv6Addresses;
         // needed for passing the default gateway to the 'Add IPv6 Address dialog'.
         this.listData.defaultGateway = ipv6RetrievedData.ipv6DefaultGateway;
         this.listData.hostIpv6Addresses = ipv6RetrievedData.hostIpv6Addresses;
      }.bind(this);

      if ($scope.hostVnicIpRoutingInfo.isIpv6Set) {
         this.initPage();
      } else {
         requestData().then(function() {
            this.initPage();
         }.bind(this));
      }

      function requestData () {
         var netStackKey = "";
         if ($scope.addNetworkingSpec.isTcpIpStackSupported) {
            netStackKey =
                  $scope.addNetworkingSpec.netStackName.netStackInstanceKey;
         }
         var vnicIpRouting = 'vnic:virtualAdapterIpRouting';

         return dataService.getProperties($scope.hostId, [vnicIpRouting], {
            propertyParams: [{
               propertyName: vnicIpRouting,
               parameterType: 'java.lang.String',
               parameter: netStackKey
            }]
         }).then(
               function (ipConfigInfo) {
                  $scope.hostVnicIpRoutingInfo.value = ipConfigInfo[vnicIpRouting];
               });
      }

      return this;
   }
})();
