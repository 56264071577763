module h5_vm {

   class FtSelectDatastoresController implements angular.IController {
      static $inject = [
         "$scope",
         "vuiConstants",
         "i18nService",
         "columnRenderersRegistry",
         "ftVmService",
         "dataService",
         "defaultUriSchemeUtil",
         "storageSelectorService",
         "diskFormatService",
         "clarityModalService",
         "clarityConstants",
         "vmFtConstants"
      ];

      private gridOptions: any;
      private manager: any;
      private selectedVm: any;
      private availableDevices: any;
      private compatibleHosts: any;
      private compatibilityResultMessagesMap: any;
      private hasAvailableHosts: boolean;
      private hasSelectedDatastore: boolean;
      private storageSelectorState: string;
      public storageLocatorItemsData: any;
      public advancedGridOptions: any;
      private selectedStorageItems: {[key: string]: any} = {};
      private managerSelectedStorageItems: {[key: string]: any} = {};
      private managerBasicSelectedStorageItems: {[key: string]: any} = {};
      private selectedStorageItemsNames: {[key: string]: string} = {};
      private allStorageItems: {[key: string]: any[]} = {};
      private initialData: any[];

      constructor(private $scope: any,
            private vuiConstants: any,
            private i18nService: any,
            private columnRenderersRegistry: any,
            private ftVmService: FtVmService,
            private dataService: any,
            private defaultUriSchemeUtil: any,
            private storageSelectorService: any,
            private diskFormatService: any,
            private clarityModalService: any,
            private clarityConstants: any,
            private vmFtConstants: any) {

         this.manager = $scope.manager;
         this.selectedVm = this.manager.getTargetUid();
         this.storageSelectorState = this.vmFtConstants.storageSelectionModes.BASIC_MODE;
         let devices: any = this.$scope.manager.getDevices();
         this.availableDevices = this.ftVmService.getAvailableDevices(devices);
         this.gridOptions = this.ftVmService.getSelectDatastoreGridOptions(
               this.manager.getConfigCompatibleDatastores());
         this.hasAvailableHosts = false;
         this.hasSelectedDatastore = false;
         this.advancedGridOptions = this.ftVmService.getAdvancedDatastoreGridOptions();
         _.each(this.manager.getCompatibleDatastores(), (datastore: any) => {
            this.selectedStorageItemsNames[datastore.provider.value] = datastore.name;
         });

         this.dataService.getData(this.selectedVm,
               "com.vmware.vsphere.client.vm.model.VmFtAdvancedStorageData")
               .then((data: any) => {
                  this.initialData = data.vmDisks;
                  this.advancedGridOptions.data = this.buildRowsData(this.initialData);
                  this.manager.setNumberOfVmFiles(this.initialData.length);
               });

         $scope.$watch(
               (): any => this.gridOptions.selectedItems[0],
               (newSelectedItem: any): void => {
                  if(newSelectedItem) {
                     this.hasSelectedDatastore = true;
                     this.compatibilityResultMessagesMap = {};
                     this.manager.setNoHostsCompatible(false);
                     this.hasAvailableHosts = true;
                     this.validateDatastoreSelection(newSelectedItem);
                  } else {
                     this.hasSelectedDatastore = false;
                  }
               });

         $scope.basicModeComparator = this.basicModeComparator.bind(this);
         this.manager.setIsAdvancedMode(false);
      }

      public basicModeComparator(dataItem: any): boolean {
         return this.manager.getSelectedDatastore() && dataItem.provider.value ===
                  this.manager.getSelectedDatastore().provider.value;

      }

      // adds functions to the row, that can be called on ng-click and ng-change from the template
      private buildRowsData(vmDisks: any): any[] {
         let data: any[] = [];
         let rowFunctions: any = {
            onDatastoreComboClick: (event: any, dataItem: any): void => {
               if (event.target.value !== "") {
                  return;
               }
               if (event && event.target && event.target.blur) {
                  // hide the dropdown's pop-up
                  event.target.blur();
               }
               dataItem.onDatastoreChange(dataItem);
            },
            onDatastoreChange: (dataItem: any): void => {
               if (!dataItem.storageItemId) {
                  this.showDatastoreSelector(dataItem);
               } else {
                  this.selectedStorageItems[dataItem.id] = dataItem.storageItemId;

                  _.each(this.selectedStorageItems, (storageItem: any, index: any) => {
                     this.managerSelectedStorageItems[index] = {
                        provider: storageItem,
                        name: this.selectedStorageItemsNames[storageItem.value]

                     };
                  });
                  this.manager.setSelectedDatastores(this.managerSelectedStorageItems);

                  if (this.manager.validateSelectedDatastores()) {
                     this.hasSelectedDatastore = true;
                     this.validateDatastoreSelection(null, true);
                  }
               }
            }

         };

         _.each(vmDisks, (vmDisk: any) => {

            angular.extend(vmDisk, rowFunctions);

            if (this.selectedStorageItems[vmDisk.id]) {
               angular.extend(vmDisk, {
                  storageItems: this.updateStorageItems(vmDisk.id),
                  storageItemId: this.selectedStorageItems[vmDisk.id]
               });
            }

            data.push(vmDisk);
         });

         return data;
      }

      // opens a modal with datastores for advanced selection per file
      private showDatastoreSelector(dataItem: any): void {
         let datastores: any[];
         if (dataItem.itemType === this.vmFtConstants.fileTypes.TIE_BREAKER) {
            datastores = this.manager.getMetaCompatibleDatastores();
         } else if (dataItem.itemType === this.vmFtConstants.fileTypes.CONFIGURATION) {
            datastores = this.manager.getConfigCompatibleDatastores();
         } else {
            datastores = this.manager.getCompatibleDatastores();
         }
         let modalOptions: any = {
            contentTemplate: "vm-ui/resources/vm/views/ft/ftStorageSelectorDialog.html",
            title: this.i18nService.getString("Common", "StorageLocatorDialog.Title"),
            defaultButton: "submit",
            size: "lg",
            dialogData: {
               datastores: datastores,
               isDatastoreSelected: false,
               selectedDatastore: {}
            },
            modalClass: "ft-advanced-datastore-selection-wizard",
            alerts: [],
            onSubmit: (): boolean => {
               if (!modalOptions.dialogData.isDatastoreSelected) {
                  modalOptions.alerts = [{
                     text: this.i18nService.getString("Common",
                           "storageSelector.incompleteSelection"),
                     type: this.clarityConstants.notifications.type.ERROR
                  }];
                  return false;
               }
               this.updateSelectedDatastore(dataItem,
                     modalOptions.dialogData.selectedDatastore);

               return true;
            },
            onCancel: (): boolean => {
               dataItem.storageItemId = this.selectedStorageItems[dataItem.id];
               return true;
            }
         };

         this.clarityModalService.openOkCancelModal(modalOptions);
      }

      //adds storage items to dropdown in advanced storage selection and browse at the end
      private updateStorageItems(id: string): any[] {
         let items: any[] = [];
         _.forEach(this.allStorageItems[id],
               (recentItem: any) => this.addStorageItemOptionToList(items,
                     this.buildStorageItemOption(recentItem)));

         items = _.sortBy(items, "label");
         items.push({
            id: "",
            label: this.i18nService.getString("Common", "DiskLocatorControl.Browse")
         });

         return items;
      }

      private addStorageItemOptionToList(items: any[], itemToAdd: any) {
         if (!_.find(items, (item: any) => item.id === itemToAdd.id)) {
            items.push(itemToAdd);
         }
      }


      private buildStorageItemOption(storageObj: any): Object {
         return {
            id: storageObj.provider,
            label: storageObj.name
         };
      }

      private updateSelectedDatastore(dataItem: any, datastore: any): void {

         this.selectedStorageItems[dataItem.id] = datastore.provider;

         _.each(this.selectedStorageItems, (storageItem: any, index: any) => {
           this.managerSelectedStorageItems[index] = {
                  provider: storageItem,
                  name: this.selectedStorageItemsNames[storageItem.value]

            };
         });

         this.manager.setSelectedDatastores(this.managerSelectedStorageItems);
         if (this.manager.validateSelectedDatastores()) {
            this.hasSelectedDatastore = true;
            this.validateDatastoreSelection(null, true);
         }
         if (!this.allStorageItems[dataItem.id]) {
            this.allStorageItems[dataItem.id] = [];
         }
         if (!_.find(this.allStorageItems[dataItem.id],
                     (item: any) => item.provider === datastore.provider)) {
            this.allStorageItems[dataItem.id].push(datastore);
         }
         this.advancedGridOptions.data = this.buildRowsData(this.initialData);
      }

      private validateDatastoreSelection(newSelectedItem: any,
             isAdvancedMode: boolean = false): void {
         if (!isAdvancedMode) {
            if (newSelectedItem && newSelectedItem !== null) {

               if (this.initialData && this.initialData.length > 0) {
                  _.each(this.initialData, (file: any) => {
                     this.managerBasicSelectedStorageItems[file.id] = newSelectedItem;
                  });

                  this.manager.setSelectedDatastores(this.managerBasicSelectedStorageItems);
               }
               this.manager.setSelectedDatastore(newSelectedItem);
               this.validateSelection();
            }
         } else {
            this.validateSelection();
         }

      }

      private validateSelection(): void {
         this.getCompatibleHosts().then((response: any) => {
            this.compatibleHosts = response.ftCompatibleHosts;
            let compatibleHostsIds: any =
                  this.ftVmService.getFtHostsIds(this.compatibleHosts);
            this.getHostNames(compatibleHostsIds).then((response: any) => {
               this.compatibilityResultMessagesMap =
                     this.ftVmService.getCompatibilityResultMessages(
                           this.compatibleHosts);

               _.each(response, (item: any, key: any) => {
                  let currentItem: any = this.compatibilityResultMessagesMap[key];
                  _.extend(currentItem, {
                     name: item.name
                  });
               });

               this.hasAvailableHosts =
                     this.ftVmService.hasFtHostWithoutErrors(this.compatibleHosts);
               this.manager.setNoHostsCompatible(!this.hasAvailableHosts);
            });

         });
      }

      private getHostNames(hostIds: any) {
         return this.dataService.getPropertiesForObjects(hostIds, ["name"]);
      }

      private getCompatibleHosts() {
         return this.dataService.getProperties(this.selectedVm,
               ["ftCompatibleHosts"], this.ftVmService.createSecondaryVmSpecParameter(
                     this.manager, this.availableDevices)
         );
      }

      /**
       * Switches between basic and advanced mode.
       */
      public toggleSelectionMode(): void {
         switch (this.storageSelectorState) {
            case this.vmFtConstants.storageSelectionModes.BASIC_MODE:
               this.storageSelectorState =
                     this.vmFtConstants.storageSelectionModes.ADVANCED_MODE;
               break;
            case this.vmFtConstants.storageSelectionModes.ADVANCED_MODE:
               this.storageSelectorState =
                     this.vmFtConstants.storageSelectionModes.BASIC_MODE;
               break;
         }
         this.onSelectionModeChanged();
      }

      private onSelectionModeChanged(): void {
         this.hasSelectedDatastore = false;
         switch (this.storageSelectorState) {
            case this.vmFtConstants.storageSelectionModes.BASIC_MODE:
               this.manager.setIsAdvancedMode(false);
               if (this.manager.getSelectedDatastore()) {
                  this.hasSelectedDatastore = true;
               }
               break;
            case this.vmFtConstants.storageSelectionModes.ADVANCED_MODE:
               this.manager.setIsAdvancedMode(true);
               this.manager.setSelectedDatastores(this.managerSelectedStorageItems);
               if (this.manager.validateSelectedDatastores()) {
                  this.hasSelectedDatastore = true;
                  this.validateDatastoreSelection(null, true);
               }
               break;
         }
         this.hasAvailableHosts = true;
         this.compatibilityResultMessagesMap = null;
      }
   }


   angular.module("com.vmware.vsphere.client.vm")
         .controller("FtSelectDatastoresController", FtSelectDatastoresController);
}

