/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
(function () {
   'use strict';

   angular
         .module('com.vmware.vsphere.client.licenseLibUi')
         .service('licenseValidationUtil', licenseValidationUtil);

   licenseValidationUtil.$inject = [
      'licenseValidationMessageBuilder',
      'licenseCommonUtil',
      'licenseConstants',
      'i18nService',
      '$q',
      '$rootScope',
      'vuiDialogService'
   ];

   function licenseValidationUtil(validationMessageBuilder, licenseCommonUtil,
         licenseConstants, i18nService, $q, $rootScope, vuiDialogService) {
      var UsageValidationItem = licenseCommonUtil.UsageValidationItem;

      function getValidationMessages(license/*License*/,
            validationResult /*AssetAssignLicenseValidationResult*/,
            isAddHostState /*Boolean*/) {
         if (!license) {
            license = null;
         }

         var validationMessages /*Array.<ValidationMessage>*/ = [
            getNoLicenseSelectedValidationMessage(license),
            getLicenseFromAnotherVcValidationMessage(license, validationResult),
            getEoemValidationMessage(license, validationResult, isAddHostState),
            getFeaturesValidationMessage(license, validationResult, isAddHostState),
            getUsageValidationMessage(license, validationResult),
            getExpirationValidationMessage(license, validationResult)
         ];

         validationMessages = _.filter(validationMessages,
               function(message) {
                  return message !== null;
               });

         if (_.isEmpty(validationMessages)) {
            validationMessages = [
               getSuccessValidationMessage()
            ];
         }

         return validationMessages;
      }

      function getValidationBlockingErrors(license/*License*/,
            validationResult /*AssetAssignLicenseValidationResult*/,
            isAddHostState /*Boolean*/) {
         if (!license) {
            license = null;
         }

         var validationBlockingErrors = [
            getNoLicenseSelectedBlockingError(license),
            getExpiredLicenseBlockingError(license, validationResult),
            getEoemBlockingError(license, validationResult, isAddHostState),
            getFeaturesInUseBlockingError(license, validationResult, isAddHostState),
            getInsufficientCapacityBlockingError(license, validationResult)
         ];

         validationBlockingErrors = _.filter(validationBlockingErrors,
               function(error) {
                  return error !== null;
               });

         return validationBlockingErrors;
      }

      function getValidationWarnings(license/*License*/,
            validationResult /*AssetAssignLicenseValidationResult*/) {

         if (!license) {
            license = null;
         }

         var validationWarnings = [
            getLicenseFromAnotherVcWarning(license, validationResult),
            getInsufficientCapacityWarning(license, validationResult)
         ];

         validationWarnings = _.filter(validationWarnings,
               function(error) {
                  return error !== null;
               });

         return validationWarnings;
      }

      function showLicenseWarningsConfirmationDialog(warnings) {
         var deferred = $q.defer();
         var promise = deferred.promise;

         var dialogScope = $rootScope.$new();
         dialogScope.dialogOptions = {
            title: i18nService.getString(
               'LicenseLibUi',
               'assignLicense.warning.confirmationDialog.title'
            ),
            contentUrl: 'license-lib-ui/resources/license/components/licenseSelector' +
               '/licenseWarningsConfirmationDialogContent.html',
            show: true,
            rejectOptions: {
               label: i18nService.getString(
                  'LicenseLibUi',
                  'assignLicense.warning.confirmationDialog.cancelButtonLabel'
               ),
               onClick: function() {
                  deferred.reject(false);
                  return true;
               }
            },
            confirmOptions: {
               label: i18nService.getString(
                  'LicenseLibUi',
                  'assignLicense.warning.confirmationDialog.okButtonLabel'
               ),
               onClick: function() {
                  deferred.resolve(true);
                  return true;
               }
            },
            width: '600px',
            height: 'auto'
         };
         dialogScope.contentModel = {
            warnings: warnings
         };

         var options = {
            scope: dialogScope,
            configObjectName: 'dialogOptions'
         };

         vuiDialogService(options);

         return promise;
      }

      function getSuccessValidationMessage() {
         return validationMessageBuilder.buildSuccessValidationMessage();
      }

      function getNoLicenseSelectedValidationMessage(license/*License*/) {
         if (license === null) {
            return validationMessageBuilder.buildNoLicenseSelectedValidationMessage();
         }

         return null;
      }

      function getLicenseFromAnotherVcValidationMessage(license/*License*/,
            validationResult /*AssetAssignLicenseValidationResult*/) {
         if (license === null) {
            return null;
         }

         if (licenseCommonUtil.isLicenseFromAnotherVc(license, validationResult)) {
            return validationMessageBuilder.buildLicenseFromAnotherVcValidationMessage();
         }

         return null;
      }

      function getEoemValidationMessage(license/*License*/,
            validationResult /*AssetAssignLicenseValidationResult*/,
            isAddHostState) /*ValidationMessage*/ {
         if (license === null) {
            return null;
         }
         var assignedLicense = validationResult.assets[0].license;

         if (licenseCommonUtil.getLicenseUri(license) ===
               licenseCommonUtil.getLicenseUri(assignedLicense)) {
            // We have selected the currently assigned license to all assets. No EOEM
            // license assignments are changed.
            return null;
         }

         if (licenseCommonUtil.isUnassignableEoemLicense(license, validationResult)) {
            if (isAddHostState) {
               return validationMessageBuilder
                     .buildAddHostEmbeddedOemLicenseUnassignableValidationMessage();
            } else {
               return validationMessageBuilder
                     .buildEmbeddedOemLicenseUnassignableValidationMessage();
            }
         }

         if (assignedLicense && licenseCommonUtil.isEoemLicense(assignedLicense)) {
            // The new license is not EOEM. We need to check whether some of the assets
            // have EOEM license currently assigned, which we will change with non-EOEM.
            return validationMessageBuilder
                  .buildEmbeddedOemLicenseUnassignValidationMessage();
         }

         if (licenseCommonUtil.isEoemLicense(license)) {
            // If the selected license is EOEM and it is not currently assigned to all
            // assets, we are assigning it to at least 1 new asset, thus we are changing
            // the EOEM license assignments.
            return validationMessageBuilder
                  .buildEmbeddedOemLicenseAssignValidationMessage();
         }

         return null;
      }

      function getFeaturesValidationMessage(license/*License*/,
            validationResult /*AssetAssignLicenseValidationResult*/,
            isAddHostState) /*ValidationMessage*/ {
         if (license === null) {
            return null;
         }

         if (isAddHostState && license.isEvaluation) {
            // There is no option to validate host evaluation license in add host state
            // because the lack of info about evaluation license in ConnectInfo object.
            // Selecting evaluation license in add host state is considered as upgrade.
            return null;
         }

         if (!validationResult.isAssetDlfVersionSupported) {
            return validationMessageBuilder.buildAssetDlfVersionValidationMessage();
         }

         var asset = validationResult.assets[0];
         var assetUnsupportedFeatures = licenseCommonUtil.getAssetUnsupportedFeatures(
               license.uri,
               asset.uri,
               validationResult.unsupportedFeatures
         );

         if (!_.isEmpty(assetUnsupportedFeatures.unsupportedFeaturesInUse)) {
            return validationMessageBuilder.buildFeaturesInUseValidationMessage(
                  assetUnsupportedFeatures.unsupportedFeaturesInUse,
                  asset.name
            );
         }

         if (!_.isEmpty(assetUnsupportedFeatures.unsupportedAvailableFeatures)) {
            return validationMessageBuilder.buildAvailableFeaturesValidationMessage(
                  assetUnsupportedFeatures.unsupportedAvailableFeatures,
                  asset.name
            );
         }

         // There are no validation problems with the features of the new license.
         return null;
      }

      function getUsageValidationMessage(license /*License*/,
            validationResult /*AssetAssignLicenseValidationResult*/) /*ValidationMessage*/ {
         if (license === null) {
            return null;
         }

         var insufficientCapacityUsageValidationItems /*Array.<UsageValidationItem>*/ =
               getInsufficientCapacityUsageValidationItems(
                     license,
                     validationResult.aggregatedUsages
               );
         if (!_.isEmpty(insufficientCapacityUsageValidationItems)) {
            return validationMessageBuilder.buildInsufficientCapacityValidationMessage(
                  insufficientCapacityUsageValidationItems
            );
         }

         var unavailableUsageDataUsageValidationItems /*Array.<UsageValidationItem>*/ =
               getUnavailableDataUsageValidationItems(
                     license,
                     validationResult.aggregatedUsages
               );
         if (!_.isEmpty(unavailableUsageDataUsageValidationItems)) {
            if (areAllCostUnitCapacitiesUnlimited(unavailableUsageDataUsageValidationItems)) {
               return validationMessageBuilder.buildUnavailableUsageDataValidationMessage(
                     unavailableUsageDataUsageValidationItems
               );
            } else {
               return validationMessageBuilder.buildUnknownCapacitySufficiencyValidationMessage(
                     unavailableUsageDataUsageValidationItems
               );
            }
         }

         // There are no validation problems with the usage of the new license.
         return null;
      }

      function getInsufficientCapacityUsageValidationItems(license/*license*/,
            assignLicenseAggregatedUsages /*AssignLicenseAggregatedUsages*/) /*Array.<*UsageValidationItem>*/ {

         if (license === null) {
            return [];
         }

         return _.filter(
               _.map(license.productRestrictions, function(productRestriction) {
                  var product = productRestriction.product;

                  var costUnitsWithInsufficientCapacity =
                        getCostUnitsWithInsufficientCapacity(
                              license,
                              product,
                              assignLicenseAggregatedUsages
                        );

                  if (_.isEmpty(costUnitsWithInsufficientCapacity)) {
                     return null;
                  }

                  return new UsageValidationItem(
                        product,
                        costUnitsWithInsufficientCapacity
                  );
               }),
               function(validationItem) {
                  return !_.isNull(validationItem);
               });

      }

      function getCostUnitsWithInsufficientCapacity(license /*License*/,
            product /*Product*/,
            assignLicenseAggregatedUsages /*AssignLicenseAggregatedUsages*/) /*Array.<CostUnit>*/ {

         if (license === null || product === null) {
            return [];
         }

         return _.filter(product.costUnits, function(costUnit) {
            if (licenseCommonUtil.isUnlimitedCapacityCostUnit(costUnit)) {
               return false;
            }

            var aggregatedCostUnitUsage = licenseCommonUtil.getAggregatedCostUnitUsage(
                  license.uri,
                  product.uri,
                  costUnit.id,
                  assignLicenseAggregatedUsages
            );

            if (aggregatedCostUnitUsage === null) {
               return false;
            }

            var costUnitUsageWithAsset = licenseCommonUtil.isEoemLicense(license) ?
                  aggregatedCostUnitUsage.maxAssetUsage :
                  aggregatedCostUnitUsage.usageWithAggregatedAssetUsage;

            return costUnitUsageWithAsset > costUnit.limit;
         });
      }

      function getUnavailableDataUsageValidationItems(license/*license*/,
            assignLicenseAggregatedUsages /*AssignLicenseAggregatedUsages*/) /*Array.<*UsageValidationItem>*/ {

         if (license === null) {
            return [];
         }

         return _.filter(
               _.map(license.productRestrictions, function(productRestriction) {
                  var product = productRestriction.product;

                  var costUnitsWithMissingAssetsContribution =
                        getCostUnitsWithMissingAssetsContribution(
                              license,
                              product,
                              assignLicenseAggregatedUsages
                        );

                  if (_.isEmpty(costUnitsWithMissingAssetsContribution)) {
                     return null;
                  }

                  return new UsageValidationItem(
                        product,
                        costUnitsWithMissingAssetsContribution
                  );
               }),
               function(validationItem) {
                  return !_.isNull(validationItem);
               });
      }

      function getCostUnitsWithMissingAssetsContribution(license /*License*/,
            product /*Product*/,
            assignLicenseAggregatedUsages /*AssignLicenseAggregatedUsages*/) /*Array.<CostUnit>*/ {

         if (license === null || product === null) {
            return [];
         }

         return _.filter(product.costUnits, function(costUnit) {
            var aggregatedCostUnitUsage = licenseCommonUtil.getAggregatedCostUnitUsage(
                  license.uri,
                  product.uri,
                  costUnit.id,
                  assignLicenseAggregatedUsages);

            return aggregatedCostUnitUsage !== null && !aggregatedCostUnitUsage.aggregationComplete;
         });
      }

      function areAllCostUnitCapacitiesUnlimited(validationItems /*Array.<UsageValidationItem>*/) /*boolean*/ {
         return _.every(validationItems, function(validationItem) {
            return _.every(validationItem.costUnits, function(costUnit) {
               return licenseCommonUtil.isUnlimitedCapacityCostUnit(costUnit);
            });
         });
      }

      function getExpirationValidationMessage(license/*License*/,
            validationResult /*AssetAssignLicenseValidationResult*/) /*ValidationMessage*/ {
         if (license === null) {
            return null;
         }

         if (licenseCommonUtil.isPermanentLicense(license, validationResult)) {
            return null;
         }

         if (licenseCommonUtil.isExpiredLicense(license, validationResult)) {
            return validationMessageBuilder.buildLicenseExpiredValidationMessage();
         }

         if (licenseCommonUtil.isExpiringLicense(license, validationResult)) {
            // License expires in less than <X> days
            var remainingDays = licenseCommonUtil.getLicenseExpirationRemainingDays(
                  license, validationResult
            );

            return validationMessageBuilder
                  .buildLicenseExpiringValidationMessage(remainingDays);
         }

         return null;
      }

      function getNoLicenseSelectedBlockingError(license/*License*/) {
         if (license === null) {
            return i18nService.getString(
                  'LicenseLibUi',
                  'assignLicense.blockingError.noLicenseSelected'
            );
         }

         return null;
      }

      function getExpiredLicenseBlockingError(license/*License*/,
            validationResult /*AssetAssignLicenseValidationResult*/) {

         if (license === null) {
            return null;
         }

         if (licenseCommonUtil.isPermanentLicense(license, validationResult)) {
            return null;
         }

         if (licenseCommonUtil.isExpiredLicense(license, validationResult)) {
            return i18nService.getString(
                  'LicenseLibUi',
                  'assignLicense.blockingError.licenseExpired'
            );
         }

         return null;
      }

      function getEoemBlockingError(license/*License*/,
            validationResult /*AssetAssignLicenseValidationResult*/,
            isAddHostState /*Boolean*/) {

         if (license === null) {
            return null;
         }

         var assignedLicense = validationResult.assets[0].license;

         if (licenseCommonUtil.getLicenseUri(license) ===
               licenseCommonUtil.getLicenseUri(assignedLicense)) {
            // We have selected the currently assigned license to all assets. No EOEM
            // license assignments are changed.
            return null;
         }

         if (licenseCommonUtil.isUnassignableEoemLicense(license, validationResult)) {
            return i18nService.getString(
                  'LicenseLibUi',
                  'assignLicense.blockingError.unassignableEoemLicense'
                        + (isAddHostState ? ".addHost" : "")
            );
         }

         return null;
      }

      function getFeaturesInUseBlockingError(license/*License*/,
            validationResult /*AssetAssignLicenseValidationResult*/,
            isAddHostState /*Boolean*/) {

         if (license === null) {
            return null;
         }

         if (isAddHostState && license.isEvaluation) {
            // There is no option to validate host evaluation license in add host state
            // because the lack of info about evaluation license in ConnectInfo object.
            // Selecting evaluation license in add host state is considered as upgrade.
            return null;
         }

         var asset = validationResult.assets[0];

         var assetUnsupportedFeatures = licenseCommonUtil.getAssetUnsupportedFeatures(
               license.uri,
               asset.uri,
               validationResult.unsupportedFeatures
         );

         if (!_.isEmpty(assetUnsupportedFeatures.unsupportedFeaturesInUse)) {
            return i18nService.getString(
                  'LicenseLibUi',
                  'assignLicense.blockingError.unsupportedFeaturesInUse'
            );
         }

         return null;
      }

      function getInsufficientCapacityBlockingError(license/*License*/,
            validationResult /*AssetAssignLicenseValidationResult*/) {

         var insufficientCapacityItems /*UsageValidationItem*/ =
               getInsufficientCapacityUsageValidationItems(
                     license,
                     validationResult.aggregatedUsages
               );

         var hasInsufficientCapacityCostUnitWithHardEnforcedLimit =
               _.some(insufficientCapacityItems, function(insufficientCapacityItem) {
                  return _.some(insufficientCapacityItem.costUnits, function(costUnit) {
                     return licenseConstants.LimitEnforcementType.HARD_ENFORCED ===
                           costUnit.limitEnforcementType;
                  });
               });

         if (hasInsufficientCapacityCostUnitWithHardEnforcedLimit) {
            return i18nService.getString(
                  'LicenseLibUi',
                  'assignLicense.blockingError.insufficientCapacity'
            );
         }

         return null;
      }

      function getLicenseFromAnotherVcWarning(license/*License*/,
            validationResult /*AssetAssignLicenseValidationResult*/) {

         if (license === null) {
            return null;
         }

         if (licenseCommonUtil.isLicenseFromAnotherVc(license, validationResult)) {
            return i18nService.getString(
                  'LicenseLibUi',
                  'assignLicense.warning.licenseFromAnotherVc'
            );
         }

         return null;
      }

      function getInsufficientCapacityWarning(license/*License*/,
            validationResult /*AssetAssignLicenseValidationResult*/) {

         if (license === null) {
            return null;
         }

         var insufficientCapacityItems /*UsageValidationItem*/ =
               getInsufficientCapacityUsageValidationItems(
                     license,
                     validationResult.aggregatedUsages
               );

         if (!_.isEmpty(insufficientCapacityItems)) {
            return i18nService.getString(
                  'LicenseLibUi',
                  'assignLicense.warning.insufficientCapacity'
            );
         }

         return null;

      }

      return {
         getValidationMessages: getValidationMessages,
         getValidationBlockingErrors: getValidationBlockingErrors,
         getValidationWarnings: getValidationWarnings,
         showLicenseWarningsConfirmationDialog: showLicenseWarningsConfirmationDialog
      };
   }
}());
