module cluster_ui {

   import IController = angular.IController;

   class EditSdrsDialogController implements IController {

      public pageModel: any;
      public loading: boolean;
      public onTabsClick: Function;

      public static $inject = ["$scope", "dataService", "$timeout"];

      constructor(private $scope: any, private dataService: any, private $timeout: any) {
         $scope.pages = {
            categories: $scope.modalOptions.dialogData.manager.getPageDefinitions()
         };

         $scope.selectedCategoryId = $scope.pages.categories[0].id;
         $scope.selectCategory = (categoryId: any) => {
            this.$scope.selectedCategoryId = categoryId;
         };

         $scope.enableButtonAutofocus = true;

         this.getViewData();
         this.onTabsClick = () => {
            if($scope.enableButtonAutofocus) {
               $scope.enableButtonAutofocus = false;
            }
         };
      }

      public getSelectedPageUrl(): string {
         let selectedPage: any = _.find(this.$scope.pages.categories, (page: any) => {
            return page.id === this.$scope.selectedCategoryId;
         });

         if (selectedPage) {
            return selectedPage.contentUrl;
         }

         return "";
      }

      private getViewData(): void {
         this.setPageBusy(true);
         this.dataService.getData(this.$scope.modalOptions.dialogData.objectId,
             "com.vmware.vsphere.client.dsclusterui.model.DsClusterEditSdrsData")
             .then((data: any) => {
                _.extend(this.$scope.modalOptions.dialogData.sdrsData, data);
                this.pageModel = this.$scope.modalOptions.dialogData.manager
                    .getStorageDrsAutomationModel();

                this.setPageBusy(false);

                this.$timeout(() => {
                   $("#dsClusterEditSdrsEnabledCheckbox").focus();
                }, 1);
             });
      }

      private setPageBusy(busy: boolean): void {
         this.loading = busy;
         this.$scope.modalOptions.submitDisabled = busy;

      }
   }

   angular.module("com.vmware.vsphere.client.dsCluster")
         .controller("EditSdrsDialogController", EditSdrsDialogController);
}
