angular.module('com.vmware.vsphere.client.vm').service('vmProvisioningDatastorePageService', [
   'i18nService',
   'VmProvisioningDatastorePageModel',
   'vmVuiWizardPageBuilder',
   function (i18nService, VmProvisioningDatastorePageModel, vmVuiWizardPageBuilder) {
      return {
         build: function (wizardScope, preselectedStorage, preselectedProfile) {
            wizardScope.datastorePageForm = {};

            var datastorePageModel = new VmProvisioningDatastorePageModel(
                  wizardScope.vmParams, wizardScope.wizardViewData, preselectedStorage, wizardScope.config);
            wizardScope.datastorePageModel = datastorePageModel;

            return vmVuiWizardPageBuilder.buildVuiWizardPage(wizardScope.config, {
               title: i18nService.getString('VmUi', 'SelectDatastoreProvisioningPage.Title'),
               description: i18nService.getString('VmUi', 'SelectDatastoreProvisioningPage.Description'),
               contentUrl: 'vm-ui/resources/vm/views/createVmWizard/vmProvisioningDatastorePage.html',
               model: datastorePageModel
            });
         }
      };
   }
]);
