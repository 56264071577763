namespace h5_vm {

   import VmWorkflowMode = h5_vm.VmWorkflowMode;
   declare const angular: ng.IAngularStatic;

   class VmwareToolsController implements ng.IComponentController {

      static $inject: string[] = [
         'i18nService',
         'creationTypeConstants',
         'vmHardwareUtil'
      ];

      private vmDefaultPowerOperations: any;
      private vmToolsOptions: any;
      private vmToolsCapabilities: any;
      private vmPowerState: string;
      private vmHasConfigSettingsPrivilege: boolean;
      private onChange: Function;
      public expanded: boolean;

      private i18n: Function;
      private vmWorkflowMode: VmWorkflowMode;
      private canChangeScriptOptions: boolean;

      constructor(private i18nService: any, private creationTypeConstants: any, private vmHardwareUtil: any) {
         this.i18n = (key: string, bundle: string = 'VmUi') => i18nService.getString(bundle, key);
      }

      private i18n2(key: string, section: string = 'VMwareTools') {
         return this.i18nService.getString("VmUi", section + '.' + key);
      }

      public ARIA_LBL_UPGRADE: string = this.i18n2('upgrade.policy');
      public ARIA_LBL_TIME_SYNC: string = this.i18n2('time.synch');
      public ARIA_LBL_AFTER_POWER_ON: string = this.i18n2('scripts.after_power_on');
      public ARIA_LBL_AFTER_RESUME: string = this.i18n2('scripts.after_resuming');
      public ARIA_LBL_BEFORE_SUSPEND: string = this.i18n2('scripts.before_suspending');
      public ARIA_LBL_BEFORE_SHUTDOWN: string = this.i18n2('scripts.before_shutting_guest');

      $onInit() {
         this.canChangeScriptOptions = (this.vmWorkflowMode === VmWorkflowMode.CreateMode) || (this.vmHasConfigSettingsPrivilege && !this.vmHardwareUtil.isVmPoweredOn(this.vmPowerState));
      }

      toolsUpgradePolicyEnabled = () => {
         if (this.vmWorkflowMode === VmWorkflowMode.CreateMode) {
            return true;
         }

         if (!this.vmHasConfigSettingsPrivilege) {
            return false;
         }

         return true;
      }

      syncTimeWithHostDisabled = () => {
         if (!this.vmToolsCapabilities.toolsSyncTimeSupported) {
            return true;
         }

         if (this.vmWorkflowMode === VmWorkflowMode.CreateMode) {
            return false;
         }

         return !this.vmHasConfigSettingsPrivilege;
      }

      onVmwareToolsChange = () => {
         this.vmToolsOptions._type = 'com.vmware.vim.binding.vim.vm.ToolsConfigInfo';

         this.onChange({
            vmToolsOptions: this.vmToolsOptions,
            vmDefaultPowerOperations: this.vmDefaultPowerOperations
         });
      }
   }

   class VmwareTools implements ng.IComponentOptions {
      bindings: any;
      controller: any;
      templateUrl: string;

      constructor() {
         this.bindings = {
            vmWorkflowMode: '<',
            vmDefaultPowerOperations: '<',
            vmToolsOptions: '<',
            vmToolsCapabilities: '<',
            vmPowerState: '<',
            vmHasConfigSettingsPrivilege: '<',
            onChange: '&',
            expanded: '='
         };
         this.controller = VmwareToolsController;
         this.templateUrl = 'vm-ui/resources/vm/views/settings/vmOptions/vmwareTools/vmware-tools.html';
      }
   }

   angular.module('com.vmware.vsphere.client.vm')
      .component('vmwareTools', new VmwareTools());
}
