/**
 * @name com.vmware.vsphere.client.cluster:clusterRulesConstants
 * @module com.vmware.vsphere.client.cluster
 *
 * @description
 *    The `clusterRulesConstants` service holds cluster rules, options and operations
 *    related constants.
 */
angular.module('com.vmware.vsphere.client.cluster').constant('clusterRulesConstants', {
   ruleType : {
      VM_AFFINITY_RULE: 'VM_AFFINITY_RULE_TYPE',
      VM_ANTIAFFINITY_RULE : 'VM_ANTIAFFINITY_RULE_TYPE',
      VM_HOST_GROUP_RULE : 'VM_HOST_GROUP_RULE_TYPE',
      VM_DEPENDENCY_RULE : 'VM_DEPENDENCY_RULE_TYPE'
   },
   optionType: {
      VM_ANTIAFFINITY_RULES: 'das.respectVmVmAntiAffinityRules',
      VM_HOST_AFFINITY_RULES: 'das.respectVmHostSoftAffinityRules'
   },
   ruleSpec: {
      VM_AFFINITY_RULE : 'com.vmware.vim.binding.vim.cluster.AffinityRuleSpec',
      VM_ANTIAFFINITY_RULE : 'com.vmware.vim.binding.vim.cluster.AntiAffinityRuleSpec',
      VM_HOST_GROUP_RULE : 'com.vmware.vim.binding.vim.cluster.VmHostRuleInfo',
      VM_DEPENDENCY_RULE : 'com.vmware.vim.binding.vim.cluster.DependencyRuleInfo'
   },
   operationType: {
      ADD: 'add',
      REMOVE: 'remove',
      EDIT: 'edit'
   },
   affinityRuleMinimumMembers: 2,

   conflictType: {
      AFFINITY_AFFINITY: 'AFFINITY_AFFINITY',
      AFFINITY_ANTIAFFINITY: 'AFFINITY_ANTIAFFINITY',
      ANTIAFFINITY_ANTIAFFINITY: 'ANTIAFFINITY_ANTIAFFINITY'
   }
});
