(function () {
   'use strict';

   /**
    * Service that opens `Manage physical network adapters` dialog.
    */
   angular
         .module('com.vmware.vsphere.client.network')
         .service('manageVssPnicsDialogService', ManageVssPnicsDialogService);

   ManageVssPnicsDialogService.$inject = [
      'i18nService',
      'physicalAdaptersListService',
      'clarityModalService',
      'mutationService',
      '$q'
   ];

   function ManageVssPnicsDialogService(i18nService,
                                        physicalAdaptersListService,
                                        clarityModalService,
                                        mutationService,
                                        $q) {

      this.show = function (hostId, vSwitchName) {

         var dialogData = {};
         dialogData.noItemsSelectedMessage = i18nService.getString('NetworkUi',
               'FailoverOrderEditor.detailsPrompt');
         dialogData.headerOptions = {
            title: i18nService.getString("NetworkUi", "FailoverOrderEditor.failoverOrder")
         };
         dialogData.loading = true;

         var modalOptions = {
            title: i18nService.getString("H5NetworkUi", "manageVssPnicsDialog.title"),
            subTitle: {
               text: vSwitchName
            },
            contentTemplate: 'network-ui/resources/network/views/host/vnic/manageVssPnicsDialog.html',
            defaultButton: 'submit',
            dialogData: dialogData,
            alerts: [],
            size: 'lg',
            modalClass: 'manage-pnic-modal',
            onSubmit: submit
         };
         clarityModalService.openOkCancelModal(modalOptions);

         physicalAdaptersListService.getPhysicalAdaptersWithDetails(hostId, vSwitchName).then(function (data) {
            var connectees = {};
            connectees.active = data.active || [];
            connectees.standby = data.standby || [];
            connectees.unused = data.unused || [];
            connectees.unclaimed = data.unclaimed;
            modalOptions.dialogData.failoverOrderOptions = {};
            modalOptions.dialogData.failoverOrderOptions.connectees = connectees;
            modalOptions.dialogData.failoverOrderOptions.connecteesDetails = data.pnicDetails;
            modalOptions.dialogData.failoverOrderOptions.labels = {
               active: i18nService.getString("NetworkUi", "FailoverOrderEditor.active"),
               standby: i18nService.getString("NetworkUi", "FailoverOrderEditor.standby"),
               unused: i18nService.getString("NetworkUi", "FailoverOrderEditor.unused")
            };
            modalOptions.dialogData.editAllItems = true;
            modalOptions.dialogData.loading = false;
         });

         function submit() {
            var deferred = $q.defer();

            getValidationErrors(hostId, vSwitchName,
                  modalOptions.dialogData.failoverOrderOptions.connectees,
                  modalOptions.dialogData.failoverOrderOptions.connecteesDetails
            ).then(function (validationError) {
               if(validationError && validationError.result) {
                  var modalOptions = {
                     title: i18nService.getString("NetworkUi",
                           'PhysicalNicFailoverOrderPage.warn.noActiveNics.title'),
                     message: validationError.result,
                     clarityIcon: {
                        class: "is-warning",
                        shape: "warning-standard",
                        size: "32"
                     },
                     preserveNewlines: true,
                     submit: function () {
                        confirmManagePnics();
                        deferred.resolve(true);
                     },
                     onCancel: function() {
                        deferred.resolve(false);
                     }
                  };
                  clarityModalService.openConfirmationModal(modalOptions);
               } else {
                  confirmManagePnics();
                  deferred.resolve(true);
               }
            });

            return deferred.promise.then(function (value) {
               return value;
            });
         }

         function confirmManagePnics(){
            var vssPnicReconfigureSpec =
                  new VssPnicReconfigureSpec(vSwitchName,
                        modalOptions.dialogData.failoverOrderOptions.connectees.active,
                        modalOptions.dialogData.failoverOrderOptions.connectees.standby,
                        modalOptions.dialogData.failoverOrderOptions.connectees.unused);

            mutationService.apply(hostId, vssPnicReconfigureSpec._type, vssPnicReconfigureSpec);
         }
      };

      function getValidationErrors(hostId, vSwitchName, connectees, pnicDetails) {
         return $q(function (resolve) {
            var validationResult = {};

            if(areAllPnicsDisconnected(connectees.active, pnicDetails)) {
               if(connectees.standby.length + connectees.unused.length > 0) {
                  validationResult.result =
                        i18nService.getString('NetworkUi', 'VssPhysicalNicEditor.warn.noActiveNics.text');
                  resolve(validationResult);
               } else {

                  var managePnicValidateSpec = {};
                  managePnicValidateSpec.vSwitchName = vSwitchName;

                  var validationPromise = mutationService.validate(hostId,
                        'com.vmware.vsphere.client.h5.network.host.managepnic.model.ManagePnicValidateSpec',
                        managePnicValidateSpec);
                  resolve(validationPromise);
               }
            } else {
               resolve(validationResult);
            }
         });
      }

      function areAllPnicsDisconnected(activePnics, pnicsDetails) {
         return !_.some(activePnics, function (pnic) {
            if (pnic !== null && pnicsDetails[pnic] && pnicsDetails[pnic].isConnected) {
               return true;
            }
         });
      }

      function VssPnicReconfigureSpec(vSwitchName, active, standby, unused) {
         this._type = "com.vmware.vsphere.client.h5.network.host.managepnic.model.VssPnicReconfigureSpec";
         this.vSwitchName = vSwitchName;
         this.activeNic = active;
         this.standbyNic = standby;
         this.unusedNic = unused;
      }
   }
})();
