(function () {
   'use strict';
   angular.module('com.vmware.vsphere.client.host').controller('hostGraphicsDevicesController', HostGraphicsDevicesController);

   HostGraphicsDevicesController.$inject = ['$scope', 'i18nService', 'dataService', 'hostGraphicsService', 'clarityModalService'];

   function HostGraphicsDevicesController($scope, i18nService, dataService, hostGraphicsService, clarityModalService) {
      var self = this;

      self.hostId = $scope._route.objectId;
      $scope.dataGridGraphicsOptions = hostGraphicsService.getGridGraphicsOptions(
         openEditDeviceSettings);
      $scope.dataGridVmOptions = hostGraphicsService.getGridVmsOptions();
      self.headerOptions = {
         title: i18nService.getString('HostUi', 'settings.graphics.devicesView.title')
      };
      self.watchForObjects = [self.hostId];
      self.liveRefreshProperties = ['config.graphicsConfig.deviceType', 'config.graphicsInfo'];
      self.loadGraphicsSettingsData = function() {

         dataService.getData(
            self.hostId,
            'com.vmware.vsphere.client.h5.host.model.GraphicsData')
            .then(function(data) {
               if (data && data.graphicsInformation) {
                  $scope.defaultGraphicsType = data.defaultGraphicsType;
                  $scope.sharedPassthruAssignmentPolicy = data.sharedPassthruAssignmentPolicy;
                  $scope.dataGridGraphicsOptions.data = data.graphicsInformation;
                  if (data.isHostSettingsEnabled) {
                     $scope.dataGridGraphicsOptions.actionBarOptions.actions[0].enabled = true;
                  }
               }
         });
      };
      $scope.$watch('dataGridGraphicsOptions.selectedItems',
         function(newItems, oldItems) {
            if (newItems && newItems !== oldItems) {
               hostGraphicsService.getVmInfoAndUpdateGridData(newItems, setGridData);
               if (newItems[0]) {
                  $scope.titleOfGraphicCard = i18nService.getString(
                     'HostUi',
                     'settings.graphics.vmsView.title',
                     newItems[0].deviceName);
            }
            }
         });
      function openEditDeviceSettings() {
         var modalOptions = {
            title: i18nService.getString('HostUi',
               'settings.graphics.editDevicesConfigForm.title'),
            subTitle : {
               text: $scope.dataGridGraphicsOptions.selectedItems[0].pciId
            },
            contentTemplate: 'host-ui/resources/host/views/settings/graphics/editGraphicDeviceView.html',
            defaultButton: 'submit',
            dialogData: {
               selectedItem: $scope.dataGridGraphicsOptions.selectedItems[0],
               defaultGraphicsType : $scope.defaultGraphicsType,
               sharedPassthruAssignmentPolicy: $scope.sharedPassthruAssignmentPolicy,
               objectId: self.hostId
            }
         };
         clarityModalService.openOkCancelModal(modalOptions);
      }
      function setGridData(data) {
         $scope.dataGridVmOptions.data = data;
      }
      self.loadGraphicsSettingsData();
   }
})();
