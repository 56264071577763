(function () {
   'use strict';

   /**
    * Generates link based on given moId and text.
    */
   angular.module('com.vmware.vsphere.client.commonModule')
         .factory('linksUtil', linksUtil);

   function linksUtil() {

      return {
         generateObjectLink: generateObjectLink
      };

      /**
       * Given a moId it generates a link to that object which can be used for navigation.
       *
       * @param objectId
       * @param text
       * @returns {*}
       */
      function generateObjectLink(objectId, text) {
         var anchorCssClass = '';

         var extensionName = 'vsphere.core.inventory.serverObjectViewsExtension';
         var navParams = [
            '"' + extensionName + '"',
            '"' + objectId + '"'
         ];

         var textElement = $('<a/>')
               .addClass(anchorCssClass)
               .attr('ng-click', "$root._navigateToViewAndObject("
                     + navParams.join(', ') + ")")
               .attr('title', text)
               .text(text)
               [0].outerHTML;

         return '<div class="object object-link">' + textElement + '</div>';
      }
   }
})();
