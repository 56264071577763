(function() {
   'use strict';
   angular.module('com.vmware.vsphere.client.host')
         .controller('authAddCertificateController',
               AuthAddCertificateController);

   AuthAddCertificateController.$inject = [
      '$scope',
      'i18nService',
      'hostAuthService',
      'ipParserService'];

   function AuthAddCertificateController($scope,
         i18nService,
         hostAuthService,
         ipParserService) {
      var self = this;

      $scope.certificateInfo = {
         path: "",
         ip: ""
      };

      self.settingHeader = {
         title: i18nService.getString('HostUi',
               'authJoinDomain.activeDirAuthChoicesBlockTitle')
      };
      var hostId = $scope.modalOptions.dialogData.availableTargets[0];
      $scope.modalOptions.onSubmit = onSubmit;

      function onSubmit() {
         $scope.modalOptions.isAlertClosed = true;
         $scope.modalOptions.alerts = [];
         validateIpAddress($scope.certificateInfo.ip);
         validatePath($scope.certificateInfo.path);

         if ($scope.modalOptions.alerts.length === 0) {
            hostAuthService.addCertificate(hostId, $scope.certificateInfo);
            return true;
         } else {
            $scope.modalOptions.isAlertClosed = false;
         }
      }

      function validatePath(path) {
         if (!path) {
            $scope.modalOptions.alerts =
                  hostAuthService.addAlertMessage('importCert.error.certPathRequired',
                        $scope.modalOptions.alerts);
         }
      }

      function validateIpAddress(ipAddress) {
         if (!ipAddress) {
            $scope.modalOptions.alerts = hostAuthService.addAlertMessage(
                  'importCert.error.serverIPRequired',
                  $scope.modalOptions.alerts);
         } else if (!ipParserService.isAddressValid(ipAddress)) {
            $scope.modalOptions.alerts = hostAuthService.addAlertMessage(
                  'importCert.error.serverIPInvalid',
                  $scope.modalOptions.alerts);
         }
      }
   }
})();
