/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace host_ui{

   export class HardwareMemoryService {
      public static $inject: string[] = [
         "i18nService",
         "bytesFilter"
      ];

      private readonly PROP_TOTAL_PMEM: string = "totalPMem";
      private readonly PROP_AVAILABLE_PMEM: string = "availablePMem";
      private readonly PROP_NOT_AVAILABLE: string = "--";

      constructor(
            private i18nService: any,
            private bytesFilter: any
      ) {}

      /**
       * Property value format function - in some cases the property value is not a string and it requires some custom
       * formatting.
       * @param propName
       *    Name of the property.
       * @param contentValue
       *    Raw value of the property - might be a string or an object.
       */
      public formatPropertyValue(propName: string, contentValue: any) {
         if (propName === this.PROP_TOTAL_PMEM || propName === this.PROP_AVAILABLE_PMEM) {
            if (contentValue === this.PROP_NOT_AVAILABLE) {
               return this.bytesFilter(0, "MB");
            }

            return this.bytesFilter(contentValue, "MB");
         }

         // for all the properties that there is no need for custom formatting - just return the raw content value
         return contentValue;
      }

      public getMemorySettingsOptions(hostId: string) {
         let headerOptions = this.getHeaderOptions("settings.memoryConfig.title", hostId);

         return {
            headerOptions: headerOptions,
            settingsViewOptions: this.getSettingsViewOptions("memoryConfig", hostId)
         };
      }

      public getPmemSettingsOptions(hostId: string) {
         let headerOptions = this.getHeaderOptions("settings.pMemConfig.title", hostId);

         return {
            headerOptions: headerOptions,
            settingsViewOptions: this.getSettingsViewOptions("pMemConfig", hostId)
         };
      }

      private getHeaderOptions(titleProperty: string, hostId: string) {
         return {
            title: this.i18nService.getString(
                  "HostUi",
                  titleProperty
            ),
            actions: [],
            objectIds: [hostId]
         };
      }

      private getSettingsViewOptions(propertyName: string, hostId: string) {
         return {
            objectId: hostId,
            propertyName: propertyName,
            formatValueCallback: (propName: string, contentValue: any) => {
               return this.formatPropertyValue(propName, contentValue);
            },
            type: "stack"
         };
      }

   } // class

   angular.module("com.vmware.vsphere.client.host").service(
         "hardwareMemoryService", HardwareMemoryService);

} // module
