(function() {
   'use strict';

   /**
    * @name com.vmware.vsphere.client.host:systemSwapConstants
    *
    * @description
    *    Represents vim.host.Service.Policy enum values.
    *
    *    List of available constants
    *    - systemSwapType - different SystemSwapConfiguration option types.
    *        - **`DATASTORE`**
    *        - **`HOST_CACHE`**
    *        - **`HOST_SWAP_LOCATION`**
    *        - **`DISABLED`**
    */
   angular
         .module('com.vmware.vsphere.client.host')
         .constant('systemSwapConstants', {
            systemSwapType: {
               DATASTORE: 'com.vmware.vim.binding.vim.host.SystemSwapConfiguration$DatastoreOption',
               HOST_CACHE: 'com.vmware.vim.binding.vim.host.SystemSwapConfiguration$HostCacheOption',
               HOST_SWAP_LOCATION: 'com.vmware.vim.binding.vim.host.SystemSwapConfiguration$HostLocalSwapOption',
               DISABLED: 'com.vmware.vim.binding.vim.host.SystemSwapConfiguration$DisabledOption'
            }
         });
})();
