/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
(function() {
   'use strict';
   angular.module('com.vmware.vsphere.client.storage').controller(
      'StorageAdaptersPathsDetailsViewController', StorageAdaptersPathsDetailsViewController);

   StorageAdaptersPathsDetailsViewController.$inject = [
      '$scope',
      'hostStorageService',
      'navigation'
   ];

   function StorageAdaptersPathsDetailsViewController($scope, hostStorageService, navigation) {
      var self = this;
      self.loading = false;
      self.hostId = navigation.getRoute().objectId;
      // View id for persisting column definitions state.
      self.viewId = navigation.getRoute().extensionId + '_paths';

      self.cache = {};

      $scope.$watch(function() { return $scope.masterDetailsViewContext.selectedItem; },
         function(newValue, oldValue) {
            if (newValue !== oldValue && newValue) {
               self.refresh();
            }
         });

      function getSelectedAdapterKey() {
         if (!$scope.masterDetailsViewContext.selectedItem) {
            return null;
         }

         return $scope.masterDetailsViewContext.selectedItem.key;
      }

      self.refresh = function() {
         var adapterKey = getSelectedAdapterKey();
         if (!adapterKey) {
            return;
         }

         var hostId = self.hostId;
         var cacheId = hostId + '-' + adapterKey;

         if (self.cache.hasOwnProperty(cacheId)) {
            self.paths = self.cache[cacheId];
         } else {
            self.loading = true;
         }

         hostStorageService.retrieveHostStorageAdapterPathsData(hostId, adapterKey)
            .then(function(adapterPaths) {
               self.cache[cacheId] = adapterPaths;
               if (self.hostId !== hostId || getSelectedAdapterKey() !== adapterKey) {
                  return;
               }
               self.paths = adapterPaths;
            })
            .finally(function() {
               if (self.hostId !== hostId || getSelectedAdapterKey() !== adapterKey) {
                  return;
               }
               self.loading = false;
            });
      };

      self.refresh();
   }
})();
