namespace h5_vm {

   import AdapterMapping = com.vmware.vim.binding.vim.vm.customization.AdapterMapping;

   export class AdapterMappingWrapper {

      adapterMapping: AdapterMapping;

      description: string;

      ipV4Address: string;

      ipV6Address: string;
   }
}