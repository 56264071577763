namespace h5_vm {
   import IComponentController = angular.IComponentController;
   import IComponentOptions =  angular.IComponentOptions;
   import BootOptionsViewModel = h5_vm.BootOptionsViewModel;
   import BootOptionsData = com.vmware.vim.binding.vim.vm.BootOptions;
   import FirmwareMenuItem = h5_vm.FirmwareMenuItem;
   import VmWorkflowMode = h5_vm.VmWorkflowMode;
   import GuestOsDescriptor = com.vmware.vim.binding.vim.vm.GuestOsDescriptor;
   import VmConfigContext = com.vmware.vsphere.client.vm.config.VmConfigContext;

   export class BootOptions implements IComponentOptions {
      public bindings: any;
      public controller: any;
      public templateUrl: string;

      constructor() {
         this.bindings = {
            vm: '<',
            vmConfigContext: '<',
            vmWorkflowMode: '<',
            onBootoptionsUpdate: '&?',
            onFirmwareUpdate: '&?',
            vbsEnabled: '<',
            vbsProperties: '<',
            doesTpmExist: '&',
            expanded: '='
         };
         this.controller = BootOptionsController;
         this.templateUrl = 'vm-ui/resources/vm/views/settings/vmOptions/bootOptions/bootOptions.html';
      }
   }

   export class BootOptionsController implements IComponentController {
      //Component input
      public vm:VmConfig;
      public vmConfigContext : VmConfigContext;
      public vmWorkflowMode: VmWorkflowMode;
      public onBootoptionsUpdate:Function;
      public doesTpmExist: Function;
      public onFirmwareUpdate:Function;
      public expanded: boolean;
      public vbsEnabled: boolean;
      public vbsProperties: any;
      static $inject: string[] = [
         'i18nService',
         'vmBootOptionsBuilderService'
      ];

      private originalCoreModel: BootOptionsData;
      private originalFirmwareSelection: FirmwareMenuItem;
      private signFirmwarePostParams: any;
      private signSecureBootPostParams: any;
      /**
       * this is the view viewModel, which will reflect changes from the view
       */
      public viewModel: BootOptionsViewModel;
      public i18n: Function;
      constructor(private i18nService: any,
            private vmBootOptionsBuilderService: BootOptionsBuilderService) {
         this.i18n = i18nService.getString;

         this.vmBootOptionsBuilderService.getFirmwareSignPostParams()
             .then((value: any) => {
                this.signFirmwarePostParams = value;
             });

         this.vmBootOptionsBuilderService.getSecureBootSignPostParams()
             .then((value: any) => {
                this.signSecureBootPostParams = value;
             });
      }

      /**
       * build the passed on vmConfigContext to be used
       * by the view.
       */
       public $onInit = () => {
         this.viewModel = this.vmBootOptionsBuilderService
               .buildViewModel(this.vmConfigContext, this.vmWorkflowMode, this.vbsEnabled, this.vbsProperties);
         this.originalCoreModel = this.viewModel.cloneBootOptionsData();
         this.originalFirmwareSelection = angular.copy(this.viewModel.firmwareSelection);
         this.viewModel.firmwareCompatibilityWarningFlag = this.vmBootOptionsBuilderService
               .shouldEnableFirmwareCompatibilityWarning(this.viewModel);
      };

      /**
       * any view changes will trigger this, update the parent
       * with the updated viewModel immediately.
       */
      public onPropertyChanged = () => {
         let updatedModel: BootOptionsData = this.vmBootOptionsBuilderService
               .processBootOptionsForUpdate(this.viewModel, this.originalCoreModel)!
               .cloneBootOptionsData();
         if (this.onBootoptionsUpdate) {
            this.onBootoptionsUpdate({updatedModel});
         }
      };

      public isBootRetryDelayEditDisabled():boolean {
         return this.vmBootOptionsBuilderService.isBootRetryDelayEditDisabled(this.viewModel);
      }

      public onFirmwareSelectionChanged() {
         this.viewModel.firmwareWarningFlag = this.vmBootOptionsBuilderService
               .shouldEnableFirmwareWarning(this.viewModel, this.originalFirmwareSelection);
         this.viewModel.firmwareCompatibilityWarningFlag = this.vmBootOptionsBuilderService
               .shouldEnableFirmwareCompatibilityWarning(this.viewModel);

         if(!this.isSecureBootAllowed()) {
            this.viewModel.efiSecureBootEnabled = false;
            this.onPropertyChanged();
         }

         let newFirmware: string = this.viewModel.firmwareSelection.id;
         if (this.onFirmwareUpdate) {
            this.onFirmwareUpdate({newFirmware});
         }
      }

      public isFirmwareDisabled() {
         if (this.vm && this.vm.isFirmwareLocked()) {
            return true;
         }
         // TODO fortes - move logic below to vm.isFirmwareLocked
         return this.vbsEnabled || !this.viewModel.firmwareEnabled || this.doesTpmExist();
      }

      public isVbsEnabled() {
         return this.vbsEnabled;
      }

      public isSecureBootAllowed(): Boolean {
         const selectedFirmware: any = this.viewModel.firmwareSelection;
         const firmware: string = selectedFirmware ? selectedFirmware.id : 'bios';
         const selectedOs: GuestOsDescriptor = (<any> this.vmConfigContext).selectedGuestOs;

         return firmware === 'efi' && selectedOs && selectedOs.supportsSecureBoot;
      }

      public showFirmwareCompatibilityWarning(): boolean {
         return this.viewModel.firmwareCompatibilityWarningFlag;
      }

      public getString = (strKeyWithParam: string, strKeySubstitutable: string): string => {
         let strSubstitutable: string = this.i18n('VmUi', 'BIOSOptions.' +  strKeySubstitutable);
         return this.i18n('VmUi', strKeyWithParam, strSubstitutable);
      }

      public get firmware(): string {
         const retVal: string = this.vmConfigContext.config.firmware;
         return retVal;
      }

      public set firmware(value: string) {
         if (this.vmConfigContext) {
            this.vmConfigContext.config.firmware = value;
         }
         if (this.vm) {
            this.vm.afterFirmwareChanged();
         }
         if (this.viewModel) {
            this.viewModel.selectFirmware(value);
            this.onFirmwareSelectionChanged();
         }
      }
   }
   angular
         .module('com.vmware.vsphere.client.vm')
         .component('bootOptions', new BootOptions());
}
