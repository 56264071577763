/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace dvs_ui {

   export class DvsSpanSessionEditableListModel implements DvsSpanSessionValidateableModel {

      public values: string[];

      public errors: string[];

      public cachedData: any[];

      constructor() {
         this.values = [];
      }
   }
}
