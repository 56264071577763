/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace dvs_ui {
   import PvlanMapEntry =
         com.vmware.vim.binding.vim.dvs.VmwareDistributedVirtualSwitch$PvlanMapEntry;
   import DvsEditPvlanData =
         com.vmware.vsphere.client.h5.network.dvs.pvlan.model.DvsEditPvlanData;
   import PvlanId = dvs_ui.PvlanIdModel;
   import PvlanMap = dvs_ui.PvlanMapModel;
   import Dictionary = _.Dictionary;
   import DvsConfigSpec = com.vmware.vsphere.client.dvs.api.spec.DvsConfigSpec;
   import VmwareDvsConfigSpec =
         com.vmware.vim.binding.vim.dvs.VmwareDistributedVirtualSwitch$ConfigSpec;
   import PvlanConfigSpec =
         com.vmware.vim.binding.vim.dvs.VmwareDistributedVirtualSwitch$PvlanConfigSpec;

   /**
    * Represents the data, received from the backend
    */
   export class DvsEditPvlanDialogModel {
      public name: string;
      public pvlanIds: Dictionary<PvlanId>;
      public pvlanMaps: Dictionary<PvlanMap>;
      private pvlansInitial: PvlanMapEntry[];
      private configVersion: string;

      constructor(data: DvsEditPvlanData) {
         this.name = data.name;
         this.configVersion = data.configVersion;
         this.pvlansInitial = data.pvlans;
         this.pvlanIds = this.extractUniqueIds();
         this.pvlanMaps = this.buildPvlanMaps();
      }

      public buildSpec(): DvsConfigSpec | null {
         let pvlansUpdated: PvlanMapEntryExtended[] =
               this.buildPvlansUpdated();
         let pvlanConfigSpecs: PvlanConfigSpec[] = [];

         _.forEach(this.pvlansInitial, (iterated: PvlanMapEntry): void => {
            let found: PvlanMapEntryExtended = _.findWhere(pvlansUpdated,
                  {secondaryVlanId: iterated.secondaryVlanId});
            if (found) {
               found.isFound = true;
               if (iterated.primaryVlanId !== found.primaryVlanId ||
                     iterated.pvlanType !== found.pvlanType) {
                  pvlanConfigSpecs
                        .push(this.buildPvlanSpec(found, PvlanOperationType.edit));
               }
            } else {
               pvlanConfigSpecs
                     .push(this.buildPvlanSpec(iterated, PvlanOperationType.remove));
            }
         });

         _.forEach(pvlansUpdated, (iterated: PvlanMapEntryExtended): void => {
            if (!iterated.isFound) {
               pvlanConfigSpecs
                     .push(this.buildPvlanSpec(iterated, PvlanOperationType.add));
            }
         });

         if (_.isEmpty(pvlanConfigSpecs)) {
            return null;
         }

         let dvsConfigSpec: DvsConfigSpec = new DvsConfigSpec();
         dvsConfigSpec.configSpec = new VmwareDvsConfigSpec();
         dvsConfigSpec.configSpec.configVersion = this.configVersion;
         dvsConfigSpec.configSpec.pvlanConfigSpec = pvlanConfigSpecs;
         return dvsConfigSpec;
      }

      public updateIsolatedError(selectedPrimaryIdKey: number,
                                 secondaryDatagridData: PvlanMapModel[]): void {
         let isolated: PvlanMapModel[] =
               _.filter(secondaryDatagridData, (map: PvlanMapModel): boolean => {
                  return map.type === PvlanType.isolated;
               });
         let nonIsolated: PvlanMapModel[] =
               _.difference(secondaryDatagridData, isolated);

         _.forEach(isolated, (pvlanMap: PvlanMapModel): void => {
            pvlanMap.errors.isolated = isolated.length > 1;
         });

         _.forEach(nonIsolated, (pvlanMap: PvlanMapModel): void => {
            pvlanMap.errors.isolated = false;
         });

         this.pvlanMaps[selectedPrimaryIdKey].errors.secondaryIsolated =
               isolated.length > 1 ;
      }

      public updateDuplicateError(): void {
         let duplicatePvlanMaps: PvlanMapModel[] = this.getDuplicatePvlanMaps();
         let nonDuplicatePvlanMaps: PvlanMapModel[] =
               _.difference(_.values(this.pvlanMaps), duplicatePvlanMaps);

         _.forEach(duplicatePvlanMaps, (pvlanMap: PvlanMapModel): void => {
            pvlanMap.errors.duplicate = true;
            if (pvlanMap.type !== PvlanType.promiscuous) {
               this.pvlanMaps[pvlanMap.primaryId.key].errors.secondaryDuplicate =
                     true;
            }
         });

         _.forEach(nonDuplicatePvlanMaps, (pvlanMap: PvlanMapModel): void => {
            pvlanMap.errors.duplicate = false;
            if (pvlanMap.type === PvlanType.promiscuous) {
               this.pvlanMaps[pvlanMap.primaryId.key].errors.secondaryDuplicate =
                     true;
            }
         });

         _.forEach(this.pvlanMaps, (pvlanMapPrimary: PvlanMapModel): void => {
            if (pvlanMapPrimary.type === PvlanType.promiscuous) {
               pvlanMapPrimary.errors.secondaryDuplicate =
                     this.isDuplicateInPvlanMaps(pvlanMapPrimary);
            }
         });
      }

      private isDuplicateInPvlanMaps(pvlanMapPrimary: PvlanMapModel): boolean {
         let filtered: PvlanMapModel[] = this.filterPvlanMapsByPrimaryId(
               this.pvlanMaps, pvlanMapPrimary.primaryId.key);
         let found: PvlanMapModel =
               _.find(filtered, (pvlanMapSecondary: PvlanMapModel): boolean => {
                  return pvlanMapSecondary.type !== PvlanType.promiscuous &&
                        pvlanMapSecondary.errors.duplicate;
               });

         return !!found;
      }

      private getDuplicatePvlanMaps(): PvlanMapModel[] {
         // create an array to fill with the duplicate items
         let duplicate: PvlanMapModel[] = [];
         // sort the items by their value
         let sorted: PvlanMapModel[] = this.sortPvlanMaps(_.values(this.pvlanMaps));

         // compare the value of each item with the previous one
         for (let i: number = 1; i < sorted.length; i++) {
            let current: PvlanMapModel = sorted[i];
            let previous: PvlanMapModel = sorted[i - 1];
            if (current.secondaryId.val === previous.secondaryId.val) {
               // current and previous have the same values
               if (_.last(duplicate) !== previous) {
                  // previous has not been pushed
                  duplicate.push(previous);
               }
               duplicate.push(current);
            }
         }

         return duplicate;
      }

      private sortPvlanMaps(pvlanMaps: PvlanMapModel[]): PvlanMapModel[] {
         return _.sortBy(pvlanMaps, (pvlanMap: PvlanMapModel): number => {
            return pvlanMap.secondaryId.val;
         });
      }

      private filterPvlanMapsByPrimaryId(pvlanMaps: Dictionary<PvlanMapModel>,
                                        selectedPrimaryIdKey: number): PvlanMapModel[] {
         return _.filter(_.values(pvlanMaps), (pvlanMap: PvlanMapModel): boolean => {
            return pvlanMap.primaryId.key === selectedPrimaryIdKey;
         });
      }

      /**
       * Extract all the unique ids from a PvlanMapEntry array
       * and wraps them in a PvlanIdModel
       *
       * @returns {Dictionary<PvlanIdModel>} - all the unique pvlanIDs wrapped in
       * PvlanIdModel objects so they can be accessed by reference and can contain
       * a unique readonly key, but still their value can be modified
       */
      private extractUniqueIds(): Dictionary<PvlanIdModel>  {
         let secondaryVlanIds: number[] = _.pluck(this.pvlansInitial, "secondaryVlanId");

         let uniqueIds: Dictionary<PvlanIdModel> = {};
         _.forEach(secondaryVlanIds, (vlanId: number): void => {
            uniqueIds[vlanId] = new PvlanIdModel(vlanId, vlanId);
         });

         return uniqueIds;
      }

      /**
       * @returns {Dictionary<PvlanMapModel>} - a duplicate of the PvlanMapEntry[] array
       * that is received as a parameter, with its numeric primary and secondary ids
       * replaced by their PvlanIdModel wrapper
       */
      private buildPvlanMaps(): Dictionary<PvlanMapModel> {
         let pvlanMaps: Dictionary<PvlanMapModel> = {};
         _.forEach(this.pvlansInitial, (pvlanMapEntry: PvlanMapEntry): void => {
            let primaryId: PvlanIdModel = this.pvlanIds[pvlanMapEntry.primaryVlanId];
            let secondaryId: PvlanIdModel = this.pvlanIds[pvlanMapEntry.secondaryVlanId];
            pvlanMaps[pvlanMapEntry.secondaryVlanId] =
                  new PvlanMapModel(primaryId, secondaryId, pvlanMapEntry.pvlanType);
         });

         return pvlanMaps;
      }

      private buildPvlanSpec(model: PvlanMapEntry, operation: string): PvlanConfigSpec {
         let entry: PvlanMapEntry = new PvlanMapEntry();
         entry.primaryVlanId = model.primaryVlanId;
         entry.secondaryVlanId = model.secondaryVlanId;
         entry.pvlanType = model.pvlanType;

         let spec: PvlanConfigSpec = new PvlanConfigSpec();
         spec.pvlanEntry = entry;
         spec.operation = operation;

         return spec;
      }

      private buildPvlansUpdated(): PvlanMapEntryExtended[] {
         return _.map(this.pvlanMaps, (iterated: PvlanMapModel): PvlanMapEntryExtended => {
            return new PvlanMapEntryExtended(iterated.primaryId.val,
                  iterated.secondaryId.val, iterated.type);
         });
      }
   }

   export class PvlanOperationType {
      public static add: string = "add";
      public static remove: string = "remove";
      public static edit: string = "edit";
   }
}
