/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace network_ui {

   import PhysicalNicEditSpec = com.vmware.vsphere.client.network.PhysicalNicEditSpec;
   import EditHostPnicModel = com.vmware.vsphere.client.h5.network.host.pnic.model.EditHostPnicModel;
   export class EditPnicDialogService {

      public static $inject = [
         "dataService",
         "i18nService",
         "clarityModalService",
         "mutationService",
         "vxValidatorFactory",
         "vuiConstants"];

      public static EDIT_PNIC_DATA_MODEL: string = "pnic:editPhysicalAdapterModel";

      private i18n: any;

      private validator: any;

      constructor(private dataService: any,
                  private i18nService: any,
                  private clarityModalService: any,
                  private mutationService: any,
                  private vxValidatorFactory: any,
                  private vuiConstants: any) {
         this.i18n = this.i18nService.getString;
      }

      public show(hostId: string, pnicName: string): void {
         let modalOptions: any = {
            title: this.i18nService.getString("H5NetworkUi", "PnicView.editTitle"),
            defaultButton: "submit",
            alerts: [],
            dialogData: {
               validator: this.vxValidatorFactory.create()
            },
            contentTemplate: "network-ui/resources/network/views/host/pnic/edit/editPnicDialog.html",
            onSubmit: onSubmit.bind(this)
         };

         let pnicPromise: any = this.getEditPnicDialogData(hostId, pnicName);

         let initialModel: EditPnicDialogModel | null = null;

         pnicPromise.then((dataServiceResponse: any) => {
            let data: EditHostPnicModel =
                  dataServiceResponse[EditPnicDialogService.EDIT_PNIC_DATA_MODEL];

            if (data) {
               modalOptions.subTitle = data.name;
               // Store the initial model in order the generate the diff onSubmit.
               initialModel = new EditPnicDialogModel(data);
            }

            modalOptions.dialogData.pageModel =
                  new EditPnicDialogModel(data);
         });

         this.clarityModalService.openOkCancelModal(modalOptions);

         function onSubmit(this: EditPnicDialogService): boolean {
            if (initialModel) {
               if (modalOptions.dialogData.validator.validate().length) {
                  modalOptions.alerts = [{
                     text: this.i18n("H5NetworkUi", "EditPnicView.sriov.errorMessage",
                              modalOptions.dialogData.pageModel.maxVirtualFunctionSupported),
                     type: this.vuiConstants.validationBanner.type.ERROR
                  }];
                  return false;
               }

               let spec: PhysicalNicEditSpec =
                     modalOptions.dialogData.pageModel.buildSpec(initialModel);
               if (spec) {
                  this.mutationService.apply(hostId, spec._type, spec);
               }
            }

            return true;
         }
      }

      private getEditPnicDialogData(hostId: string, pnicName: string): any {
         let params: any[] = [{
            propertyName: EditPnicDialogService.EDIT_PNIC_DATA_MODEL,
            parameterType: 'java.lang.String',
            parameter: pnicName
         }];

         let pnicPromise: any = this.dataService.getProperties(hostId,
               [EditPnicDialogService.EDIT_PNIC_DATA_MODEL], {
                  propertyParams: params
               });

         return pnicPromise;
      }
   }

   angular.module("com.vmware.vsphere.client.network")
         .service("editPnicDialogService", EditPnicDialogService);
}
