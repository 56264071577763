/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace h5_client {
   export module common_module_ui {
      /*
       * Category action service.
       */
      import IRootScopeService = angular.IRootScopeService;
      export class CustomAttributesActionService {
         static $inject = ['customAttributesService', 'clarityModalService', 'i18nService', '$rootScope'];

         constructor(private customAttributesService: any,
               private clarityModalService: any,
               private i18nService: any,
               private $rootScope: IRootScopeService) {}

         public createCustomAttribute() {
            const self = this;
            const title = self.i18nService.getString('Common','editCustomFieldDefinitionView.add.title');
            self.openAddEditCustomAttributeModal(title, {});
         }

         public editCustomAttribute(customAttributeData: any) {
            let self = this;
            const title = self.i18nService.getString('Common', 'editCustomAttributesView.title');
            self.openAddEditCustomAttributeModal(title, customAttributeData);
         }

         public deleteCustomAttribute(customAttributeData: any) {
            const self = this;
            var modalOptions = {
               title: self.i18nService.getString('Common','customFieldAction.delete.confirmation.title'),
               message: self.i18nService.getString('Common','customFieldAction.delete.confirmation.message'),
               icon: 'vsphere-icon-status-warning',
               submit: () => {
                  self.customAttributesService.deleteCustomAttribute(customAttributeData)
                     .then(() => {
                        // deferred promise does not work with $http,
                        // so just using a broadcast.
                        self.$rootScope.$broadcast('refreshCustomAttributes');
                     });
               },
               preserveNewlines: true
            };
            self.clarityModalService.openConfirmationModal(modalOptions);
         }

         private openAddEditCustomAttributeModal(title: string, customAttributeData: any) {
            const actionEval: any = {
               action: {
                  label: title
               }
            };
            const context: any = {
               customAttributeState: angular.copy(customAttributeData),
               title: title
            };
            this.clarityModalService.openModal(actionEval, null, context,
                  'common-module-ui/resources/common-module/views/custom-attributes/add-edit-custom-attribute.html');
         }
      }

      angular.module('com.vmware.vsphere.client.commonModule').service('customAttributesActionService', CustomAttributesActionService);
   }
}
