module cluster_ui {

   import IPromise = angular.IPromise;
   import IQService = angular.IQService;
   import IDeferred = angular.IDeferred;

   export class HostOptionsService {

      public static $inject = [
         "$q",
         "i18nService",
         "columnRenderersRegistry",
         "timeFormatterService",
         "defaultUriSchemeUtil",
         "datagridActionBarService",
         "vuiConstants",
         "drsConstants",
         "ipParserService"
      ];

      private getString: (key: string, ...args: any[]) => string;

      constructor(private $q: IQService,
                  private i18nService: any,
                  private columnRenderersRegistry: any,
                  private timeFormatterService: any,
                  private defaultUriSchemeUtil: any,
                  private datagridActionBarService: any,
                  private vuiConstants: any,
                  private drsConstants: any,
                  private ipParserService: any) {
         this.getString = _.partial(<(bundle: string, key: string, ...args: any[]) => string> this.i18nService.getString, "ClusterUi");
      }

      public parseHostOptionsData(data: any): IPromise<Array<HostOptionListItem>> {
         let hostDpmOverrides: any = {};
         if (data.dpmHostConfig) {
            _.each(data.dpmHostConfig, function (dpmOverride: any) {
               hostDpmOverrides[dpmOverride.key.value] = dpmOverride;
            });
         }

         if (data.hosts && data.hosts.length > 0) {
            let datagridData: Array<HostOptionListItem> = _.map(data.hosts, (host: any) => {
               let listItem: any = {
                  hostRef: host.provider,
                  name: host.name,
                  primaryIconId: host.primaryIconId,
                  bmcUsername: host.ipmiInfo ? host.ipmiInfo.login : "",
                  bmcIpAddr: host.ipmiInfo ? host.ipmiInfo.bmcIpAddress : "",
                  bmcMacAddr: host.ipmiInfo ? host.ipmiInfo.bmcMacAddress : ""
               };

               _.extend(listItem, {
                  powerManagement: this.formatPowerManagementProperty(
                        hostDpmOverrides[host.provider.value],
                        data.dpmEnabled,
                        data.dpmBehavior
                  ),
                  lteStandbyPromise: this.formatLastTimeExitProperty(host.hostStandbyInfo)
               });

               return listItem;
            });
            // TODO refactor once timeFormatterService asynchronous dateFormat method is fixed.
            return this.getDatagridData(datagridData);

         } else {
            return this.$q.when([]);
         }
      }

      // TODO remove complicated and unreadable defer flow when
      // timeFormatterService has working synchronous dateFormat method.
      private getDatagridData(datagridData: Array<HostOptionListItem>): IPromise<Array<HostOptionListItem>> {
         return this.$q
               .all(_.map(datagridData, (item: HostOptionListItem) => item.lteStandbyPromise))
               .then((formattedDates: Array<string>) => {
                  _.each(datagridData, (listItem: HostOptionListItem, index: number) => {
                     listItem.lteStandby = formattedDates[index];
                  });
                  return datagridData;
               });
      }

      // TODO refactor once timeFormatterService asynchronous dateFormat method is fixed.
      private formatLastTimeExitProperty(hostStandbyInfo: any): IPromise<string> {
         if (!hostStandbyInfo) {
            return this.$q.when(this.getString("hostOptions.lastExitStandbyNA"));
         }

         let lastExitSuccededLabel: string = hostStandbyInfo.lastExitSucceeded ?
               this.getString("hostOptions.lastExitStandbySucceeded") :
               this.getString("hostOptions.lastExitStandbyFailed");

         return this.timeFormatterService.formatDate(hostStandbyInfo.lastTimeExit).then((formattedDate: string) => {
            return this.getString("hostOptions.lastExitStandbyTimeAndResult",
                  formattedDate,
                  lastExitSuccededLabel);
         });
      }

      private formatPowerManagementProperty(dpmHostConfig: any, dpmEnabled: boolean, dpmBehavior: string): string {
         return dpmHostConfig ?
               this.getDpmBehavior(dpmHostConfig.enabled, dpmHostConfig.behavior) :
               this.getString(
                     "hostOptions.dpmClusterDefault",
                     this.getDpmBehavior(dpmEnabled, dpmBehavior)
               );
      }

      private getDpmBehavior(dpmEnabled: boolean, dpmBehavior: string): string {
         return dpmEnabled ? this.getDpmBehaviorLabel(dpmBehavior) : this.getString("hostOptions.dpmDisabled");
      }

      private getDpmBehaviorLabel(dpmBehavior: string): string {
         return dpmBehavior === "manual" ?
               this.getString("drs.config.dpm.automationLevel.manual") :
               this.getString("drs.config.dpm.automationLevel.automated");
      }

      private getHostNameRenderer(data: any): any {
         let objectLinkRenderer = this.columnRenderersRegistry.getColumnRenderer("object-link");
         let modifiedData = {
            hostName: data.name,
            hostId: this.getHostRef(data)
         };
         let hostIcon = data.primaryIconId ? data.primaryIconId : "vsphere-icon-host";
         return objectLinkRenderer(["hostId", "hostName", hostIcon], modifiedData);
      }

      private getColumnDefs(): Array<ColumnDef> {
         return [
            {
               displayName: this.getString("hostOptions.hostNameHeaderText"),
               field: "name",
               template: (item: any): void => {
                  return this.getHostNameRenderer(item);
               }
            },
            {
               displayName: this.getString("hostOptions.powerMgmtHeaderText"),
               field: "powerManagement"
            },
            {
               displayName: this.getString("hostOptions.LastExitStandbyHeaderText"),
               field: "lteStandby"
            },
            {
               displayName: this.getString("hostOptions.ipmiLoginHeaderText"),
               field: "bmcUsername"
            },
            {
               displayName: this.getString("hostOptions.ipmiIpAddressHeaderText"),
               field: "bmcIpAddr"
            },
            {
               displayName: this.getString("hostOptions.ipmiMacAddressHeaderText"),
               field: "bmcMacAddr"
            }
         ];
      }

      public getDatagridOptions(): DatagridOptions {
         return {
            actionBarOptions: {
               actions: []
            },
            searchable: false,
            resizable: false,
            selectedItems: [],
            data: [],
            selectionMode: this.vuiConstants.grid.selectionMode.SINGLE,
            columnDefs: this.getColumnDefs(),
            height: "100%",
            pageConfig: {
               hidePager: true
            }
         };
      }

      private getActionsSpec(datagridOptions: DatagridOptions, clusterId: string): Object[] {
         return [{
            actionId: "vsphere.core.cluster.actions.hostOptions.edit",
            getActionInvocationContext: (): Object => {
               return {
                  selectedHost: this.getHostRef(datagridOptions.selectedItems[0]),
                  clusterRef: clusterId
               };
            },
            isActionAvailable: (actionDef: any): boolean => {
               return actionDef.available && datagridOptions.selectedItems && datagridOptions.selectedItems.length > 0;
            }
         }];
      }

      private getHostRef(hostData: any): string {
         return this.defaultUriSchemeUtil.getVsphereObjectId(hostData.hostRef);
      }

      public createActionBar(datagridOptions: DatagridOptions, targetId: string): IPromise<Object> {
         let actionsSpec = this.getActionsSpec(datagridOptions, targetId);
         return this.datagridActionBarService
               .updateActionBar(datagridOptions, [targetId], actionsSpec)
               .then((data: any) => {
                  return data;
               });
      }

      public refreshActionBar(datagridOptions: DatagridOptions, targetId: string, actionBarCache: any): void {
         let actionsSpec = this.getActionsSpec(datagridOptions, targetId);
         if (!actionBarCache) {
            return;
         }

         this.datagridActionBarService.updateActionBar(
               datagridOptions, [targetId], actionsSpec, actionBarCache
         );
      }

      public createClusterSpec(dpmHostConfigSpecs: DpmHostConfigSpec[]): ClusterReconfigureSpec {
         return <ClusterReconfigureSpec>{
            configSpecEx: {
               _type: "com.vmware.vim.binding.vim.cluster.ConfigSpecEx",
               dpmHostConfigSpec: dpmHostConfigSpecs
            },
            modify: true
         };
      }

      public createDpmHostConfigSpec(powerManagementConfig: any): DpmHostConfigSpec {
         let dpmHostConfigSpec = <DpmHostConfigSpec>{
            _type: "com.vmware.vim.binding.vim.cluster.DpmHostConfigSpec"
         };

         if (!powerManagementConfig.powerManagementOverride) {
            dpmHostConfigSpec = _.extend(dpmHostConfigSpec, {
               removeKey: this.defaultUriSchemeUtil.getManagedObjectReference(powerManagementConfig.hostId),
               operation: "remove"
            });
         } else {
            dpmHostConfigSpec = _.extend(dpmHostConfigSpec, {
               info: {
                  _type: "com.vmware.vim.binding.vim.cluster.DpmHostConfigInfo",
                  key: this.defaultUriSchemeUtil.getManagedObjectReference(powerManagementConfig.hostId),
                  behavior: powerManagementConfig.selectedDpmBehavior ? powerManagementConfig.selectedDpmBehavior : null,
                  enabled: powerManagementConfig.selectedDpmBehavior !== this.drsConstants.dpmAutomationLevel.DISABLED
               },
               operation: powerManagementConfig.defaultDpmOverride === powerManagementConfig.powerManagementOverride ? "edit" : "add"
            });
         }

         return dpmHostConfigSpec;
      }

      public validatePowerConfigSoftwareSpec(data: any): string[] {
         let validationMessages = <string[]>[];
         if (!data) {
            return validationMessages;
         }
         if (data.login === "" && data.password === "" && data.bmcIpAddress === ""
               && data.bmcMacAddress === "") {
            return validationMessages;
         }

         if (data.login === "" || data.password === "" || data.bmcIpAddress === ""
               || data.bmcMacAddress === "") {
            let allParametersRequiredMessage = this.i18nService.getString("ClusterUi",
                  "hostOptions.edit.ipmi.allParametersRequired");
            validationMessages.push(allParametersRequiredMessage);
         }

         if (data.bmcIpAddress !== "" && !this.ipParserService.isAddressValid(data.bmcIpAddress)) {
            let invalidIpAddress = this.i18nService.getString("ClusterUi",
                  "hostOptions.edit.ipmi.invalidIpAddress");
            validationMessages.push(invalidIpAddress);
         }

         return validationMessages;
      }

      public showValidationMessages(messages: any[]): {type: string, text: string}[] {
        return _.map(messages, function (message) {
            return {
               type: "error",
               text: message
            };
         });
      }

      public setSelectedDpmBehavior(clusterDpmConfigData: any, powerManagementConfig: any): any {
         if (clusterDpmConfigData.dpmHostConfig) {
            let hostConfig = _.find(clusterDpmConfigData.dpmHostConfig, (hostConfig: any) => {
               return this.defaultUriSchemeUtil.getVsphereObjectId(hostConfig.key) === powerManagementConfig.hostId;
            });
            if (!hostConfig) {
               powerManagementConfig.powerManagementOverride = false;
               powerManagementConfig.defaultDpmOverride = false;
               this.setDpmBehavior(powerManagementConfig, this.drsConstants.dpmAutomationLevel.DISABLED);
            } else {
               if (hostConfig.enabled) {
                  // save the default dpm configuration of the host.
                  this.setDpmBehavior(powerManagementConfig, this.createPowerManagementOverrideSelection(hostConfig));
               } else {
                  this.setDpmBehavior(powerManagementConfig, this.drsConstants.dpmAutomationLevel.DISABLED);
               }
               powerManagementConfig.powerManagementOverride = true;
               powerManagementConfig.defaultDpmOverride = true;

            }
         } else {
            this.setDpmBehavior(powerManagementConfig, this.drsConstants.dpmAutomationLevel.DISABLED);
         }
      }

      private setDpmBehavior(powerManagementConfig: any, powerManagementValue: string): any {
         powerManagementConfig.selectedDpmBehavior = powerManagementValue;
         powerManagementConfig.defaultDpmBehavior = powerManagementValue;
      }

      private createPowerManagementOverrideSelection(hostConfig: {enabled: boolean, behavior: string}): string {
         let dpmManual = this.drsConstants.dpmAutomationLevel.MANUAL;
         let dpmAutomated = this.drsConstants.dpmAutomationLevel.AUTOMATED;
         return hostConfig.behavior === dpmManual ? dpmManual : dpmAutomated;
      }

      public getDpmBehaviors() {
         return Object.keys(this.drsConstants.dpmAutomationLevel).map((key) => {
            return this.buildDpmBehavior(key);
         });
      }

      private buildDpmBehavior(key: string) {
         let dpmEnabled = true;
         let automationLevel = this.drsConstants.dpmAutomationLevel[key];
         if (automationLevel === "") {
            dpmEnabled = false;
         }

         return {
            id: automationLevel,
            label: this.getDpmBehavior(dpmEnabled, automationLevel.toLowerCase())
         };
      }
   }

   angular.module("com.vmware.vsphere.client.cluster")
         .service("hostOptionsService", HostOptionsService);
}
