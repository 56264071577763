namespace h5_vm {

   class VmProvisioningDeploymentOptionsService {

      constructor() {
      }

      public figureOutTheItemSelectedInDeploymentOptions(deploymentOptionParams: any) {
         if (deploymentOptionParams
               && deploymentOptionParams.deploymentOptions
               && deploymentOptionParams.deploymentOptions.length > 0) {

            this.createIsSelectedFieldInEachOption(deploymentOptionParams);

         }
      }

      private createIsSelectedFieldInEachOption(deploymentOptionParams: any) {
         let currentSelectedOptionKey = deploymentOptionParams.selectedKey;

         _.each(deploymentOptionParams.deploymentOptions,
               (option: any) => {
                  option['defaultChoice'] = (option.key === currentSelectedOptionKey) ? true : false;
                  option['isSelected'] = option.defaultChoice;
               });
      }
   }

   angular.module("com.vmware.vsphere.client.vm").service('vmProvisioningDeploymentOptionsService',
         VmProvisioningDeploymentOptionsService);
}