(function () {
   'use strict';

   angular.module('com.vmware.vsphere.client.cluster')
         .service('clusterRulesService', clusterRulesService);

   clusterRulesService.$inject = ['i18nService', 'dataService', 'mutationService',
      'clusterRulesConstants', 'defaultUriSchemeUtil', 'clarityModalService',
      'clusterSpecBuilderService', 'logService', 'alertService', '$q', '$rootScope'];


   function clusterRulesService(i18nService, dataService, mutationService,
                                clusterRulesConstants, defaultUriSchemeUtil,
                                clarityModalService, clusterSpecBuilderService,
                                logService, alertService, $q) {
      var log = logService('clusterRulesService');

      return {
         DEFAULT_TYPE: clusterRulesConstants.ruleSpec.VM_AFFINITY_RULE,
         getRulesData: getRulesData,
         getAffinityRulesMembersData: getAffinityRulesMembersData,
         getConflictingRulesData: getConflictingRulesData,
         deleteRule: deleteRule,
         getRuleTypes: getRuleTypes,
         buildRuleSpec: buildRuleSpec,
         removeRuleMembers: removeRuleMembers,
         getSpecTypeByRuleType: getSpecTypeByRuleType,
         addRuleMembers: addRuleMembers,
         getVmNames: getVmNames

      };

      function getRulesData(clusterId) {
         var optionProperty = 'ClusterComputeResource/configurationEx/dasConfig/option';
         var haEnabledProperty = 'ClusterComputeResource/configurationEx/dasConfig/enabled';
         return dataService
               .getProperties(clusterId, ['rulesData', optionProperty, haEnabledProperty])
               .then(function (data) {
                  return {
                     rules: data ? getRules(data.rulesData) : [],
                     options: data ? getOptions(data[optionProperty]) : [],
                     haEnabled: data ? (data[haEnabledProperty] || false) : false
                  };
               });
      }

      function getAffinityRulesMembersData(rule) {
         var objectConflicts = {};
         var objectIds = _.map(rule.members, function(member) {
            var objectId = defaultUriSchemeUtil.getVsphereObjectId(member.entity);
            objectConflicts[objectId] = member.conflicts.length;
            return objectId;
         });

         return dataService.getPropertiesForObjects(objectIds,
            ['name', 'primaryIconId'])
            .then(function(propsPerObject) {
               return _.map(propsPerObject, function(memberData, id) {
                  return {
                     id: id,
                     name: memberData.name,
                     icon: memberData.primaryIconId,
                     conflictsCount: objectConflicts[id]
                  };
               });
            });
      }

      function getConflictingRulesData(conflictingRules) {
        return _.map(conflictingRules, function(item) {
            return {
               name: item.rule.name,
               icon: 'vx-icon-rule-conflict'
            };
         });
      }

      function getRules(rulesData) {
         return _.map(rulesData, function(ruleItem) {
            var ruleType;
            switch (ruleItem.ruleType) {
               case clusterRulesConstants.ruleType.VM_AFFINITY_RULE:
                  ruleType = i18nService.getString('ClusterUi',
                        'rules.list.type.vmAffinity');
                  break;
               case clusterRulesConstants.ruleType.VM_ANTIAFFINITY_RULE:
                  ruleType = i18nService.getString('ClusterUi',
                        'rules.list.type.vmAntiAffinity');
                  break;
               case clusterRulesConstants.ruleType.VM_HOST_GROUP_RULE:
                  ruleType = i18nService.getString('ClusterUi',
                        'rules.list.type.vmHostGroup');
                  break;
               case clusterRulesConstants.ruleType.VM_DEPENDENCY_RULE:
                  ruleType = i18nService.getString('ClusterUi',
                        'rules.list.type.vmDependency');
                  break;
            }

            return {
               name: ruleItem.rule.name,
               icon: getRuleIcon(ruleItem),
               type: ruleItem.ruleType,
               key: ruleItem.rule.key,
               typeText: ruleType,
               enabled: ruleItem.rule.enabled,
               userCreated: ruleItem.rule.userCreated,
               members: ruleItem.members,
               conflictingRules: ruleItem.conflictingRules,
               vmToHostRuleProperties: {
                  vmGroupName: ruleItem.rule.vmGroupName,
                  hostGroupName: getRuleHostGroupName(ruleItem.rule),
                  isAffineHostGroupName: isAffineHostGroupName(ruleItem.rule),
                  isMandatory: ruleItem.rule.mandatory
               },
               vmDependencyRuleProperties: {
                  dependsOnVmGroup: ruleItem.rule.dependsOnVmGroup,
                  vmGroup: ruleItem.rule.vmGroup
               }
            };
         });
      }

      function getOptions(optionsData) {
         return _.map(optionsData, function(option) {
            return {
               key: option.key,
               value: option.value
            };
         });
      }

      function getRuleIcon(ruleItem) {
         return ruleItem.conflictingRules.length === 0 ?
            'vx-icon-rule': 'vx-icon-rule-conflict';
      }

      function getRuleHostGroupName(rule) {
         return rule.affineHostGroupName !== null ?
            rule.affineHostGroupName: rule.antiAffineHostGroupName;
      }

      function isAffineHostGroupName(rule) {
         return rule.affineHostGroupName !== undefined &&
               rule.affineHostGroupName !== null;
      }

      function deleteRule(clusterId, ruleInfo) {
         var confirmProperties = {
            title: i18nService.getString('ClusterUi', 'rules.action.remove.confirmationTitle'),
            message: i18nService.getString('ClusterUi', 'rules.action.remove.confirmationTextOne'),
            submit: function () {
               deleteRuleFromCluster(clusterId, ruleInfo.key);
            }
         };

         clarityModalService.openConfirmationModal(confirmProperties);
      }

      function deleteRuleFromCluster(clusterId, ruleKey) {
         var deleteRuleSpec = {
            _type: 'com.vmware.vim.binding.vim.cluster.RuleSpec',
            operation: clusterRulesConstants.operationType.REMOVE,
            removeKey: ruleKey
         };

         var clusterComputeResourceSpec =
               clusterSpecBuilderService.buildClusterComputeResourceSpec(
                     { rule: deleteRuleSpec }, true);

         mutationService.apply(
               clusterId,
               'com.vmware.vsphere.client.cluster.ClusterComputeResourceSpec',
               clusterComputeResourceSpec
         );
      }

      function addRuleMembers(clusterId, ruleInfo, membersToAdd /* [{ id: <vm-id> }, ...] */) {
         var ruleMemberIds = _.map(ruleInfo.members, function(member) {
            return defaultUriSchemeUtil.getVsphereObjectId(member.entity);
         });
         var memberIdsToAdd = _.map(membersToAdd, 'id');

         var newMembers = _.union(ruleMemberIds, memberIdsToAdd);
         var newMemberRefs = buildRuleMembersSpec(newMembers);

         var ruleSpec = buildRuleSpec(
            getSpecTypeByRuleType(ruleInfo.type),
            ruleInfo.name,
            ruleInfo.enabled,
            newMemberRefs,
            null,
            clusterRulesConstants.operationType.EDIT,
            ruleInfo.key
         );

         return mutationService.validate(
            clusterId,
            'com.vmware.vim.binding.vim.cluster.RuleSpec',
            ruleSpec
         ).then(function (validationResult) {
            if (!!validationResult && (!!validationResult.result || !!validationResult.error)) {
               if (validationResult.result === 'RULE_CONFLICT_ERROR') {
                  var confirmationDeferred = $q.defer();

                  alertService.okCancel(
                     i18nService.getString('ClusterUi', 'rules.error.ruleConflictsConfirmationTitle'),
                     i18nService.getString('ClusterUi', 'rules.error.ruleConflicts'),
                     function () {
                        confirmationDeferred.resolve();
                     },
                     function () {
                        confirmationDeferred.reject();
                     }
                  );

                  return confirmationDeferred.promise;
               }

               return undefined;
            }
         }).then(function () {
            var clusterComputeResourceSpec =
               clusterSpecBuilderService.buildClusterComputeResourceSpec(
                  {
                     rule: ruleSpec
                  },
                  true
               );

            mutationService.apply(
               clusterId,
               'com.vmware.vsphere.client.cluster.ClusterComputeResourceSpec',
               clusterComputeResourceSpec
            );

            return undefined;
         });
      }

      function removeRuleMembers(clusterId, ruleInfo, membersToRemove) {
         var memberIdsToRemove = _.map(membersToRemove, 'id');
         var ruleMemberIds = _.map(ruleInfo.members, function(member) {
            return defaultUriSchemeUtil.getVsphereObjectId(member.entity);
         });
         var newMembers =  _.difference(ruleMemberIds, memberIdsToRemove);
         var newMemberRefs = buildRuleMembersSpec(newMembers);

         var ruleSpec = buildRuleSpec(getSpecTypeByRuleType(ruleInfo.type), ruleInfo.name,
               ruleInfo.enabled, newMemberRefs, null,
               clusterRulesConstants.operationType.EDIT, ruleInfo.key);

         var clusterComputeResourceSpec =
               clusterSpecBuilderService.buildClusterComputeResourceSpec(
                     {rule: ruleSpec}, true);

         mutationService.apply(
               clusterId,
               'com.vmware.vsphere.client.cluster.ClusterComputeResourceSpec',
               clusterComputeResourceSpec
         );
      }

      function buildRuleMembersSpec(members){
         return members.map(function(member) {
            return defaultUriSchemeUtil.getManagedObjectReference(member);
         });
      }

      function getSpecTypeByRuleType(ruleType) {
         var specType = null;
         switch (ruleType) {
            case clusterRulesConstants.ruleType.VM_AFFINITY_RULE:
               specType = clusterRulesConstants.ruleSpec.VM_AFFINITY_RULE;
               break;
            case clusterRulesConstants.ruleType.VM_ANTIAFFINITY_RULE:
               specType = clusterRulesConstants.ruleSpec.VM_ANTIAFFINITY_RULE;
               break;
            case clusterRulesConstants.ruleType.VM_HOST_GROUP_RULE:
               specType = clusterRulesConstants.ruleSpec.VM_HOST_GROUP_RULE;
               break;
            case clusterRulesConstants.ruleType.VM_DEPENDENCY_RULE:
               specType = clusterRulesConstants.ruleSpec.VM_DEPENDENCY_RULE;
               break;
            default:
               log.warn('Unexpected rule type: ' + ruleType);
               break;
         }

         return specType;
      }

      function getRuleTypes(is65VcOrLater) {
         var ruleTypes = [{
            id: clusterRulesConstants.ruleSpec.VM_AFFINITY_RULE,
            label: i18nService.getString('ClusterUi', 'rules.config.type.vmAffinity.name')
         }, {
            id: clusterRulesConstants.ruleSpec.VM_ANTIAFFINITY_RULE,
            label: i18nService.getString('ClusterUi', 'rules.config.type.vmAntiAffinity.name')
         }, {
            id: clusterRulesConstants.ruleSpec.VM_HOST_GROUP_RULE,
            label: i18nService.getString('ClusterUi', 'rules.config.type.vmToHost.name')
         }];

         if (is65VcOrLater) {
            ruleTypes.push({
               id: clusterRulesConstants.ruleSpec.VM_DEPENDENCY_RULE,
               label: i18nService.getString('ClusterUi', 'rules.config.type.vmToVM.name')
            });
         }

         return ruleTypes;
      }

      function createRuleInstance(ruleType, name, enabled, members, ruleProps, key) {
         var ruleInfo = {
            name: name,
            enabled: enabled,
            userCreated: true,
            key: key
         };

         switch (ruleType) {
            case clusterRulesConstants.ruleSpec.VM_AFFINITY_RULE:
            case clusterRulesConstants.ruleSpec.VM_ANTIAFFINITY_RULE:
               ruleInfo = _.extend({
                  vm: members,
                  _type: ruleType
               }, ruleInfo);
               break;
            case clusterRulesConstants.ruleSpec.VM_HOST_GROUP_RULE:
               ruleInfo = _.extend({
                  vmGroupName: ruleProps.vmGroupName,
                  affineHostGroupName:
                        ruleProps.isAffineHostGroupName ? ruleProps.hostGroupName : null,
                  antiAffineHostGroupName:
                        !ruleProps.isAffineHostGroupName ? ruleProps.hostGroupName : null,
                  mandatory: ruleProps.isMandatory,
                  _type: ruleType
               }, ruleInfo);
               break;
            case clusterRulesConstants.ruleSpec.VM_DEPENDENCY_RULE:
               ruleInfo = _.extend({
                  vmGroup: ruleProps.vmGroup,
                  dependsOnVmGroup: ruleProps.dependsOnVmGroup,
                  _type: ruleType
               }, ruleInfo);
               break;
            default:
               log.warn('Unexpected rule type: ' + ruleType);
               break;
         }

         return ruleInfo;
      }

      function createRuleSpec(ruleInfo, operation) {
         var ruleSpec = {
            _type: 'com.vmware.vim.binding.vim.cluster.RuleSpec',
            operation: operation,
            info: ruleInfo
         };

         return ruleSpec;
      }

      function buildRuleSpec(ruleType, name, enabled, ruleMembers, ruleProps,
            operation, ruleKey) {
         var ruleInfo =
               createRuleInstance(ruleType, name.trim(), !!enabled, ruleMembers,
                     ruleProps, ruleKey);

         return createRuleSpec(ruleInfo, operation);
      }

      function getVmNames(vms) {
         var vmsIds = _.map(vms, function(vm) {
            return defaultUriSchemeUtil.getVsphereObjectId(vm.entity);
         });
         return dataService.getPropertiesForObjects(vmsIds, ['name']);
      }
   }
})();
