namespace h5_vm {

   import IComponentController = angular.IComponentController;

   export class LunSelectorList implements angular.IComponentOptions {
      public bindings: any;
      public controller: any;
      public template: string;

      constructor() {
         this.bindings = {
            lunData: "<",
            selectionChanged: '&'
         };
         this.controller = LunSelectorListController;
         this.template = "<div id='lunSelectorGrid' class='lun-selector-list'><div vui-datagrid='$ctrl.gridOptions' vx-stretch-grid></div></div>";
      }
   }

   export class LunSelectorListController implements IComponentController {

      public static $inject: string[] = ['i18nService', 'vuiConstants'];

      public gridOptions: any;
      private scope: any;
      public lunData: any;
      public selectedItems: any[];
      public selectionChanged: (selectedItems: any) => any;

      constructor(private i18nService: any,
            private vuiConstants: any) {
      }

      $onInit(): void {
         this.gridOptions = this.getGridOptions();
      }

      private i18n = (id: String): String => {
         return this.i18nService.getString('VmUi', id);
      }

      private getGridOptions = ():any => {
         let options  = {
            sortMode: this.vuiConstants.grid.sortMode.SINGLE,
            selectionMode: this.vuiConstants.grid.selectionMode.SINGLE,
            selectedItems: this.selectedItems,
            searchable: false,
            resizable: true,
            data: this.lunData,
            columnDefs: this.getColumnDefs(),
            onChange: this.onSelectionChanged,
            height: '200px'
         };
         return options;
      }

      private getColumnDefs = ():any => {
         return [
            {
               displayName: this.i18n('FormSelectLUN.Name'),
               field: 'lunName',
               type: 'string'
            },
            {
               displayName: this.i18n('FormSelectLUN.PathID'),
               field: 'pathId',
               type: 'string'
            },
            {
               displayName: this.i18n('FormSelectLUN.LUN'),
               field: 'lunNum',
               type: 'string'
            },
            {
               displayName: this.i18n('FormSelectLUN.Capacity'),
               field: 'displayCapacity',
               type: 'string'
            },
            {
               displayName: this.i18n('FormSelectLUN.DriveType'),
               field: 'driveType',
               type: 'string'
            },
            {
               displayName: this.i18n('FormSelectLUN.HardwareAcceleration'),
               field: 'hardwareAcceleration',
               type: 'string'
            }
         ];
      }

      public onSelectionChanged = (selectedItems: any) => {
         this.selectionChanged({selectedItems});
      }

   }

   angular.module('com.vmware.vsphere.client.vm')
         .component('lunSelectorList',
         new LunSelectorList());
}
