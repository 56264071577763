/* Copyright 2013 VMware, Inc. All rights reserved. -- VMware Confidential */
/*
 * This js file has to be loaded first
 */
h5 = h5 || {};
h5.host = {
   angularModule: angular.module('com.vmware.vsphere.client.host',
         ['com.vmware.platform.ui', 'com.vmware.vsphere.client.commonModule',
               'com.vmware.vsphere.client.licenseLibUi'])
};

/*
 * Registers host action invokers in the h5.actions cache.
 *
 * We do this in the run block of the module so that invokers can take
 * advantage of angular services injected into a closure.
 */
angular.module('com.vmware.vsphere.client.host')
   .run([
      'columnRenderersRegistry',
      'templateHelperService',
      function(columnRenderersRegistry, templateHelperService) {

         var clusterLinkRenderer = {
            'cluster-link': function(props, data, config) {
               var objLinkRenderer = columnRenderersRegistry.getColumnRenderer(
                  'object-link');
               return objLinkRenderer([props[0], props[1], 'vsphere-icon-cluster'],
                  data,
                  config);
            }
         };

         var hostHaStateTemplate = "<span class='object' title='{{title}}'><i class='{{icon}}'></i>{{text}}</span>";
         var compiledHostHaStateTemplate = templateHelperService.getHtmlEscapingTemplateRenderFunction(hostHaStateTemplate);

         var haStateRenderer = {
            'host-ha-state': function(props, data) {
               var icon = '';
               var text = data[props[0]];

               switch (data[props[1]]) {
                  case 'master':
                  case 'connectedToMaster':
                     icon = "vsphere-icon-status-ok";
                     break;
                  case 'uninitialized':
                  case 'election':
                     icon = "vsphere-icon-status-warning";
                     break;
                  case 'fdmUnreachable':
                  case 'hostDown':
                  case 'initializationError':
                  case 'networkIsolated':
                  case 'networkPartitionedFromMaster':
                  case 'uninitializationError':
                     icon = "vsphere-icon-status-error";
                     break;

                  default:
                     icon = "vsphere-icon-status-unknown";
               }

               return compiledHostHaStateTemplate({ title: text, icon: icon, text: text });
            }
         };

         var hostVersionRenderer = {
            'host-version': function(props, data) {
               if (data[props[0]] === null || data[props[1]] === null){
                  return "";
               }

               if (data[props[0]] !== 'embeddedEsx') {
                  return "";
               }

               return data[props[1]];
            }
         };

         columnRenderersRegistry.registerColumnRenderers(clusterLinkRenderer);
         columnRenderersRegistry.registerColumnRenderers(haStateRenderer);
         columnRenderersRegistry.registerColumnRenderers(hostVersionRenderer);
      }
   ])
   .run([
   '$rootScope',
   'hciCapabilityService',
   'mutationService',
   'actionDialogService',
   'actionConfirmationService',
   'removeHostAction',
   'columnRenderersRegistry',
   'i18nService',
   'addHostWizardService',
   'clarityModalService',
   'refreshCertificateService',
   'hostAuthService',
   'securityProfileDialogService',
   'hostConnectionService',
   'suppressWarningSettingService',
   'moveHostToService',
   'assignLicenseService',
   'vscExportWizardLauncherService',
   'addHostsActionWizardService',
   'vuiUtils',
   'vxGridSettingsService',
   'suppressWarningSettingConstants',
   function($rootScope,
         hciCapabilityService,
         mutationService,
         actionDialogService,
         actionConfirmationService,
         removeHostAction,
         columnRenderersRegistry,
         i18nService,
         addHostWizardService,
         clarityModalService,
         refreshCertificateService,
         hostAuthService,
         securityProfileDialogService,
         hostConnectionService,
         suppressWarningSettingService,
         moveHostToService,
         assignLicenseService,
         vscExportWizardLauncherService,
         addHostsActionWizardService,
         vuiUtils,
         vxGridSettingsService,
         suppressWarningSettingConstants) {
      /*
       * Action for editing power configuration (hardware) of the host.
       */
      h5.actions["vsphere.core.host.actions.editPowerConfigHardware"] = function(actionEval, availableTargets) {

         var modalOptions = {
            title: i18nService.getString('HostUi', 'powerPolicy.title'),
            subTitle: {
               objectId: availableTargets[0]
            },
            defaultButton: 'submit',
            contentTemplate: 'host-ui/resources/host/views/settings/EditPowerConfigHardware.html',
            dialogData: {
               availableTargets: availableTargets
            }
         };

         clarityModalService.openOkCancelModal(modalOptions);
      };

      h5.actions["vsphere.core.host.actions.editPowerConfigSoftware"] = function(actionEval, availableTargets) {
         var modalOptions = {
            title: i18nService.getString('HostUi',
                  'powerConfigSoftware.powerManagementSettings'),
            subTitle: {
               objectId: availableTargets[0]
            },
            defaultButton: 'submit',
            contentTemplate: 'host-ui/resources/host/views/settings/editPowerConfigSoftware.html',
            dialogData: {
               availableTargets: availableTargets
            }
         };

         clarityModalService.openOkCancelModal(modalOptions);
      };

      h5.actions["vsphere.core.host.actions.editAgentVmSettings"] = function(actionEval, availableTargets) {

         var modalOptions = {
            title: i18nService.getString('HostUi', 'settings.agentVm.title'),
            subTitle: {
               objectId: availableTargets[0]
            },
            defaultButton: 'submit',
            contentTemplate: 'host-ui/resources/host/views/settings/EditAgentVmSettings.html',
            dialogData: {
               availableTargets: availableTargets
            }
         };

         clarityModalService.openOkCancelModal(modalOptions);
      };

      h5.actions['vsphere.core.host.actions.enterMaintenanceMode'] = function(actionEval, availableTargets) {
         var modalOptions = {
            title: i18nService.getString('HostUi', 'actionEnterMaintenanceLabel'),
            subTitle: {
               objectId: availableTargets[0]
            },
            defaultButton: 'submit',
            contentTemplate: 'host-ui/resources/host/views/settings/maintenanceMode/MaintenanceModeConfirmation.html',
            dialogData: {
               availableTargets: availableTargets
            }
         };

         clarityModalService.openOkCancelModal(modalOptions);
      };

      h5.actions['vsphere.core.host.actions.exitMaintenanceMode'] = function(actionEval, availableTargets) {
         var maintenanceAction = { maintenanceOpType: 'exit' };
         mutationService.applyOnMultiEntity(availableTargets,
               'com.vmware.vsphere.client.host.maintenance.HostMaintenanceSpec', maintenanceAction);
      };

      h5.actions['vsphere.core.host.actions.enterStandbyAction'] = function(actionEval, availableTargets) {
         var modalOptions = {
            title: i18nService.getString('HostUi', 'actionEnterStandbyLabel'),
            subTitle: availableTargets.length > 1 ? "" : {
               objectId: availableTargets[0]
            },
            defaultButton: 'submit',
            contentTemplate: 'host-ui/resources/host/standby/standbyModeConfirmationDialog.html',
            dialogData: {
               availableTargets: availableTargets
            }
         };

         clarityModalService.openOkCancelModal(modalOptions);
      };

      h5.actions['vsphere.core.host.actions.exitStandby'] = function(actionEval, availableTargets) {
         var hostStandbySpec = {
            standbyOpType: 'exit'
         };
         mutationService.applyOnMultiEntity(
               availableTargets,
               'com.vmware.vsphere.client.host.standby.HostStandbySpec',
               hostStandbySpec
         );
      };

      h5.actions['vsphere.core.host.actions.shutdownAction'] = function(actionEval, availableTargets) {
         var modalOptions = {
            title: availableTargets.length === 1 ? i18nService.getString('HostUi', 'actionShutdownHostDialogTitle') :
                i18nService.getString('HostUi', 'actionShutdownHostsDialogTitle'),
            subTitle: availableTargets.length > 1 ? "" : {
               objectId: availableTargets[0]
            },
            dialogData: {
               availableTargets: availableTargets
            },
            defaultButton: 'submit',
            contentTemplate: 'host-ui/resources/host/views/settings/shutdownConfirmation.html'
         };
         clarityModalService.openOkCancelModal(modalOptions);
      };

      h5.actions['vsphere.core.host.actions.rebootAction'] = function(actionEval, availableTargets) {
         var modalOptions = {
            title: availableTargets.length === 1 ? i18nService.getString('HostUi', 'actionRebootHostDialogTitle') :
                i18nService.getString('HostUi', 'actionRebootHostsDialogTitle'),
            subTitle: availableTargets.length > 1 ? "" : {
               objectId: availableTargets[0]
            },
            dialogData: {
               availableTargets: availableTargets
            },
            defaultButton: 'submit',
            contentTemplate: 'host-ui/resources/host/views/settings/RebootConfirmation.html'
         };
         clarityModalService.openOkCancelModal(modalOptions);
      };

      h5.actions['vsphere.core.host.actions.editAdvancedSystemSettings'] = function(actionEval, availableTargets, context) {
         context = { selectedItems: vxGridSettingsService.getSelectedSettings() };
         var modalOptions = {
            title: i18nService.getString('HostUi', 'advancedSettings.edit.dialogTitle'),
            subTitle: {
               objectId: availableTargets[0]
            },
            dialogData: {
               availableTargets: availableTargets,
               selectedItems: context.selectedItems
            },
            defaultButton: 'submit',
            contentTemplate: 'host-ui/resources/host/views/settings/advancedSystemSettings/editAdvancedSystemSettings.html',
            size: 'lg'
         };
         clarityModalService.openOkCancelModal(modalOptions);

      };

      h5.actions['vsphere.core.host.actions.enableEncryptionMode'] = function(actionEval, availableTargets, context) {
         mutationService.apply(context.entityId,
            'com.vmware.vsphere.client.host.config.HostEncryptionModeSpec',
            {'encryptionMode': 'safe'});
      };

      h5.actions['vsphere.core.host.actions.connectHost'] = function(actionEval, availableTargets) {
         var modalOptions = {
            title: i18nService.getString('HostUi', 'actionConnectLabel'),
            subTitle: availableTargets.length > 1 ? "" : {
               objectId: availableTargets[0]
            },
            message: availableTargets.length > 1 ?
                  i18nService.getString('HostUi', 'confirmReconnect.multipleHostsWarning') :
                  i18nService.getString('HostUi', 'confirmReconnect.singleHostWarning'),
            icon: 'icon-warning-32',
            submit: function() {
               hostConnectionService.reconnect(availableTargets);
            },
            preserveNewlines: true
         };
         clarityModalService.openConfirmationModal(modalOptions);
      };

      h5.actions['vsphere.core.host.actions.disconnectHost'] = function(actionEval, availableTargets) {
         var modalOptions = {
            title: i18nService.getString('HostUi', 'actionDisconnectLabel'),
            subTitle: availableTargets.length > 1 ? "" : {
               objectId: availableTargets[0]
            },
            message: availableTargets.length > 1 ? i18nService.getString('HostUi', 'confirmDisconnectHostsQuestion') :
                  i18nService.getString('HostUi', 'confirmDisconnectHostQuestion'),
            icon: 'icon-warning-32',
            submit: function() {
               hostConnectionService.disconnect(availableTargets);
            },
            preserveNewlines: true
         };
         clarityModalService.openConfirmationModal(modalOptions);
      };

      h5.actions['vsphere.core.host.actions.reconfigureDAS'] = function(actionEval, availableTargets) {
         mutationService.applyOnMultiEntity(availableTargets,
               'com.vmware.vsphere.client.host.config.ReconfigureDasSpec', {});
      };

      h5.actions['vsphere.core.host.actions.removeHost'] = function(actionEval, availableTargets) {
         removeHostAction.perform(availableTargets);
      };

      h5.actions['vsphere.core.host.actions.editVmStartupShutdownAction'] = function(actionEval, availableTargets) {
         var modalOptions = {
            title: i18nService.getString('HostUi', 'vmStartUp.editLabel'),
            subTitle: {
               objectId: availableTargets[0]
            },
            defaultButton: 'submit',
            contentTemplate: 'host-ui/resources/host/views/settings/vmStartupShutdown/editVmStartupShutdown.html',
            size: 'lg',
            modalClass: 'editVmStartupShutdown'
         };

         clarityModalService.openOkCancelModal(modalOptions);
      };

      h5.actions['vsphere.core.host.actions.editSwapfileLocation'] = function(actionEval, availableTargets) {
         var modalOptions = {
            title: i18nService.getString('HostUi', 'editSwapfile.titleFormat'),
            subTitle: {
               objectId: availableTargets[0]
            },
            defaultButton: 'submit',
            contentTemplate: 'host-ui/resources/host/views/settings/editVmSwapFileLocationView.html',
            size: 'lg',
            dialogData: {
               availableTargets: availableTargets
            }
         };

         clarityModalService.openOkCancelModal(modalOptions);
      };

      h5.actions['vsphere.core.host.actions.editDefaultHwVersionAction'] = function(actionEval, availableTargets) {
         var modalOptions = {
            title: i18nService.getString('HostUi', 'defaultVmCompatibilityDialog.title'),
            subTitle: {
               objectId: availableTargets[0]
            },
            defaultButton: 'submit',
            contentTemplate: 'host-ui/resources/host/views/settings/EditDefaultVmCompatibility.html',
            dialogData: {
               availableTargets: availableTargets
            }
         };

         clarityModalService.openOkCancelModal(modalOptions);
      };

      h5.actions['vsphere.core.host.actions.navToSettings'] = function(actionEval, availableTargets) {
         $rootScope._navigateToViewAndObject('vsphere.core.host.manage', availableTargets[0]);
      };

      h5.actions['vsphere.core.host.actions.refreshServicesAction'] = function(actionEval, availableTargets) {
         mutationService.apply(availableTargets[0],
               'com.vmware.vsphere.client.host.config.RefreshServicesSpec', {});
      };

      h5.actions['vsphere.core.host.actions.addHost'] = function(actionEval, availableTargets) {
         addHostWizardService.showWizard(availableTargets[0], actionEval);
      };

      h5.actions['vsphere.core.host.actions.service.editStartupPolicy'] =
            function(actionEval, availableTargets, dialogData) {
         var modalOptions = {
            title: i18nService.getString('HostUi', 'service.edit.startup.policy.title'),
            subTitle: {
               text: dialogData.service.label
            },
            defaultButton: 'submit',
            contentTemplate:
                  'host-ui/resources/host/views/settings/hostServices/editServiceStartupPolicyDialog.html',
            modalClass: 'edit-service-startup-policy-dialog',
            dialogData: {
               model: {
                  service: dialogData.service,
                  hostId: dialogData.hostId
               }
            }
         };

         clarityModalService.openOkCancelModal(modalOptions);
      };

      h5.actions['vsphere.core.host.actions.editFirewallSettings'] = function(actionEval, availableTargets) {
         var modalOptions = {
            title: i18nService.getString('HostUi', 'editFirewallSettingsDialog.title'),
            subTitle: {
               objectId: availableTargets[0]
            },
            defaultButton: 'submit',
            contentTemplate:
                  'host-ui/resources/host/views/settings/editFirewallSettingsDialog.html',
            size: 'lg',
            dialogData: {
               availableTargets: availableTargets
            }
         };

         clarityModalService.openOkCancelModal(modalOptions);
      };

      h5.actions['vsphere.core.host.actions.editTimeConfigurationSettings'] = function(actionEval, availableTargets) {
         var modalOptions = {
            title: i18nService.getString('HostUi', 'timeConfig.editTimeConfiguration'),
            subTitle: {
               objectId: availableTargets[0]
            },
            defaultButton: 'submit',
            size: 'lg',
            contentTemplate: 'host-ui/resources/host/views/settings/timeConfiguration/editTimeConfigurationDialog.html',
            dialogData: {
               availableTargets: availableTargets
            }
         };

         clarityModalService.openOkCancelModal(modalOptions);
      };

      h5.actions['vsphere.core.host.actions.authenticationServiceSettingsJoinDomain'] = function(actionEval, availableTargets) {
         var modalOptions = {
            title: i18nService.getString('HostUi', 'authJoinDomain.tiwoName'),
            subTitle: {
               objectId: availableTargets[0]
            },
            defaultButton: 'submit',
            modalClass: 'edit-auth-join-domain-dialog',
            contentTemplate:
                  'host-ui/resources/host/views/settings/authenticationServices/authJoinDomainDialog.html',
            dialogData: {
               availableTargets: availableTargets
            }
         };

         clarityModalService.openOkCancelModal(modalOptions);
      };

      h5.actions['vsphere.core.host.actions.authenticationServiceSettingsLeaveDomain'] = function(actionEval, availableTargets) {
         hostAuthService.leaveDomain(availableTargets[0]);
      };

      h5.actions['vsphere.core.host.actions.authenticationServiceSettingsAddCertificate'] = function(actionEval, availableTargets) {
         var modalOptions = {
            title: i18nService.getString('HostUi', 'importCert.actionDescription'),
            subTitle: {
               objectId: availableTargets[0]
            },
            defaultButton: 'submit',
            modalClass: 'edit-auth-add-certificate-dialog',
            contentTemplate:
                  'host-ui/resources/host/views/settings/authenticationServices/authImportCertificateDialog.html',
            dialogData: {
               availableTargets: availableTargets
            }
         };

         clarityModalService.openOkCancelModal(modalOptions);
      };

      h5.actions['vsphere.core.host.actions.editImageProfile'] = function(actionEval, availableTargets) {
         var modalOptions = {
            title: i18nService.getString('HostUi', 'imageProfileAcceptance.label'),
            subTitle: {
               objectId: availableTargets[0]
            },
            defaultButton: 'submit',
            contentTemplate: 'host-ui/resources/host/views/settings/securityProfile/editImageProfileAcceptanceLevel.html'
         };
         clarityModalService.openOkCancelModal(modalOptions);
      };

      h5.actions['vsphere.core.host.actions.editEncryptionMode'] = function(actionEval, availableTargets) {
         var modalOptions = {
            title: i18nService.getString('HostUi','hostEncryption.edit.title'),
            subTitle: {
               objectId: availableTargets[0]
            },
            defaultButton: 'submit',
            contentTemplate: 'host-ui/resources/host/views/settings/securityProfile/editEncryptionMode.html'
         };
         clarityModalService.openOkCancelModal(modalOptions);
      };

      h5.actions['vsphere.core.host.actions.editLockdownMode'] = function(actionEval, availableTargets) {
         securityProfileDialogService.show(availableTargets[0], actionEval);
      };

      h5.actions['vsphere.core.host.configissues.SuppressESXiAndSSHConfigIssue'] = function(actionEval, availableTargets) {
         suppressWarningSettingService.suppressWarnings(availableTargets[0],
               suppressWarningSettingConstants.SUPPRESS_SHELL_WARNING_KEY);
      };

      h5.actions['vsphere.core.host.configissues.SuppressHyperthreadWarning'] = function(actionEval, availableTargets) {
         suppressWarningSettingService.suppressWarnings(availableTargets[0],
               suppressWarningSettingConstants.SUPPRESS_HYPERTHREAD_WARNING_KEY);
      };


      h5.actions['vsphere.core.host.actions.editSystemSwap'] = function(actionEval, availableTargets) {
         var modalOptions = {
            title: i18nService.getString('HostUi','userWorldSwap.edit.title'),
            subTitle: {
               objectId: availableTargets[0]
            },
            dialogData: {
               availableTargets: availableTargets
            },
            modalClass: 'edit-system-swap-settings',
            defaultButton: 'submit',
            contentTemplate: 'host-ui/resources/host/views/settings/systemSwap/editSystemSwapSettingsView.html'
         };

         clarityModalService.openOkCancelModal(modalOptions);
      };

      h5.actions['vsphere.core.host.actions.refreshCertificate'] = function(actionEval, availableTargets) {
         refreshCertificateService.refreshCertificate(availableTargets[0]);
      };

      h5.actions['vsphere.core.host.actions.refreshCaCertificate'] = function(actionEval, availableTargets) {
         refreshCertificateService.refreshCaCertificate(availableTargets[0]);
      };

      h5.actions['vsphere.core.host.configissues.SuppressNoRedundantManagementNetworkIssue'] = function(actionEval, availableTargets) {
         var hostId = availableTargets[0];
         var operationName = i18nService.getString(
               'HostUi', 'SuppressHostNoRedundantManagementNetwork.taskName');
         mutationService.apply(hostId,
               'com.vmware.vsphere.client.host.config.SuppressHostNoRedundantManagementNetworkWarningSpec',
               {}, operationName
         );
      };

      h5.actions['vsphere.core.host.actions.editProcessorGeneralConfig'] = function(actionEval, availableTargets) {
         var modalOptions = {
            title: i18nService.getString('HostUi', 'processorConfig.generalConfiguration'),
            subTitle: {
               objectId: availableTargets[0]
            },
            icon: 'icon-warning-32',
            defaultButton: 'submit',
            contentTemplate: 'host-ui/resources/host/views/settings/processors/editHardwareProcessorsView.html'
         };

         clarityModalService.openOkCancelModal(modalOptions);
      };

      h5.actions['vsphere.core.host.actions.editBootOptions'] = function(actionEval, availableTargets) {
         var modalOptions = {
            title: i18nService.getString('HostUi', 'bootDevice.title'),
            subTitle: {
               objectId: availableTargets[0]
            },
            icon: 'icon-warning-32',
            defaultButton: 'submit',
            contentTemplate: 'host-ui/resources/host/views/settings/processors/editBootOptions.html'
         };

         clarityModalService.openOkCancelModal(modalOptions);
      };

      h5.actions['vsphere.core.host.actions.editPciDevices'] = function(actionEval, availableTargets) {
         var modalOptions = {
            title: i18nService.getString('HostUi', 'vmdpConfig.edit.title'),
            subTitle: {
               objectId: availableTargets[0]
            },
            defaultButton: 'submit',
            contentTemplate: 'host-ui/resources/host/views/settings/pciDevices/editPciDevicesView.html',
            size: 'lg'
         };

         clarityModalService.openOkCancelModal(modalOptions);
      };

      h5.actions['vsphere.core.host.actions.moveHostTo'] = function(actionEval, availableTargets) {

            var modalOptions = {
               title: i18nService.getString('HostUi', 'actionMoveHostTitle'),
               subTitle: {
                  objectId: availableTargets[0]
               },
               defaultButton: 'submit',
               dialogData: {
                  objectIds: availableTargets
               },
               contentTemplate: 'host-ui/resources/host/views/settings/moveto/moveToDialog.html'
            };
            clarityModalService.openOkCancelModal(modalOptions);

      };

      h5.actions['vsphere.core.host.actions.editHostGraphics'] = function(actionEval, availableTargets) {
         var modalOptions = {
            title: i18nService.getString('HostUi', 'settings.graphics.editHostConfigForm.title'),
            subTitle: {
               objectId: availableTargets[0]
            },
            defaultButton: 'submit',
            contentTemplate: 'host-ui/resources/host/views/settings/graphics/editHostGraphicsSettingsView.html'
         };

         clarityModalService.openOkCancelModal(modalOptions);
      };
      h5.actions['vsphere.core.host.actions.refreshPciDevices'] = function(actionEval, availableTargets) {
         var hostId = availableTargets[0];
         mutationService.apply(
               hostId,
               'com.vmware.vsphere.client.h5.host.vmdp.spec.HostVmdpRefreshSpec',
               {});
      };

      h5.actions['vsphere.core.host.actions.moveHostToDrag'] = function(actionEval, availableTargets, destinationTarget) {
         moveHostToService.moveHostTo(destinationTarget.dropTarget, availableTargets);
      };

      h5.actions['vsphere.core.host.actions.editSmartCardAuthentication'] = function(actionEval, availableTargets) {
         var modalOptions = {
            title: i18nService.getString('HostUi', 'smartCardAuthnetication.title'),
            subTitle: {
               objectId: availableTargets[0]
            },
            size: 'lg',
            defaultButton: 'submit',
            contentTemplate: 'host-ui/resources/host/views/settings/authenticationServices/editSmartCardAuthenticationDialog.html',
            objectId: availableTargets[0]
         };

         clarityModalService.openOkCancelModal(modalOptions);
      };

      h5.actions['vsphere.core.host.actions.clearNfsKerberosCredentials'] = function(actionEval, availableTargets) {
         var modalOptions = {
            title: i18nService.getString('HostUi', 'nfsCredentials.clear.confirm.title'),
            message: i18nService.getString('HostUi', 'nfsCredentials.clear.confirm.text'),
            icon: "icon-warning-32",
            submit: function() {
               mutationService.apply(
                     availableTargets[0],
                     'com.vmware.vsphere.client.storage.NfsClearCredentialsSpec',
                     {});
               return true;
            },
            preserveNewlines: true
         };

         clarityModalService.openConfirmationModal(modalOptions);
      };

      h5.actions['vsphere.core.host.actions.editNfsKerberosCredentials'] = function(actionEval, availableTargets) {
         var modalOptions = {
            title: i18nService.getString('HostUi', 'nfsCredentials.edit.dialogTitle'),
            subTitle: {
               objectId: availableTargets[0]
            },
            size: 'md',
            defaultButton: 'submit',
            contentTemplate: 'host-ui/resources/host/views/settings/authenticationServices/editNfsKerberosCredentialsDialog.html',
            objectId: availableTargets[0]
         };
         clarityModalService.openOkCancelModal(modalOptions);
      };

      /*
       * Action 'Assign License' to a host.
       */
      h5.actions["vsphere.core.host.assignLicenseKeyAction"] = function(actionEval, availableTargets) {
         if (_.isEmpty(availableTargets)) {
            return;
         }

         assignLicenseService.showAssignLicenseForm(availableTargets);
      };

      /*
       * Export system logs on single host.
       */
      h5.actions["vsphere.core.host.exportLogsAction"] = function(actionEval, availableTargets, context) {
         if (_.isEmpty(availableTargets)) {
            return;
         }

         vscExportWizardLauncherService.showSystemLogExportDialog(availableTargets);
      };
      var angularZone = window.Zone.current;
      /*
       * Action 'Add Hosts' to a cluster.
       */
      h5.actions['vsphere.core.hci.addHosts'] = function (actionEval, availableTargets) {
         angularZone.run(function () {
            var clusterId = availableTargets[0];
            hciCapabilityService.checkHCISupported(clusterId).then(
                  function (isHCISupported) {
                     vuiUtils.safeApply($rootScope, function () {
                        if (isHCISupported) {
                           addHostsActionWizardService.openWizard(clusterId);
                        } else {
                           addHostWizardService.showWizard(clusterId);
                        }
                     });
                  });
         });
      };
   }]);
