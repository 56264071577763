/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
(function () {
   'use strict';

   angular
         .module('com.vmware.vsphere.client.network')
         .service('addHostNetworkingAddPnicPageValidator',
               AddHostNetworkingAddPnicPageValidator);

   AddHostNetworkingAddPnicPageValidator.$inject = [
      'i18nService',
      'clarityModalService'
   ];

   function AddHostNetworkingAddPnicPageValidator(i18nService,
                                                  clarityModalService) {

      this.getValidationErrorsAndWarnings = function (pnics, pnicsDetails) {

         var errors = getValidationErrors(pnics);
         var warning = getWarning(pnics, pnicsDetails);

         if (errors && errors.length > 0) {
            return errors;
         } else if (warning) {

            var modalOptions = {
               title: warning.dialogTitle,
               message: warning.warningMessage,
               clarityIcon: {
                  class: "is-warning",
                  shape: "warning-standard",
                  size: "32"
               },
               preserveNewlines: true
            };

            return clarityModalService.openConfirmationModal(modalOptions);
         } else {
            return true;
         }
      };

      function getValidationErrors(pnics) {
         var errors = [];

         if (!hasNewPnics(pnics)) {
            if (typeof pnics.unclaimed === 'undefined'
                  || pnics.unclaimed.length === 0) {
               errors.push(i18nService.getString('NetworkUi',
                     'PhysicalNicFailoverOrderPage.error.noUnclaimedNics'));
            } else if (pnics.unclaimed.length > 0) {
               errors.push(i18nService.getString('NetworkUi',
                     'PhysicalNicFailoverOrderPage.error.noNics'));
            }
         }

         return errors;
      }

      function getWarning(pnics, pnicsDetails) {
         var warning;

         var activePnics = pnics.active;
         if (!hasActivePnics(pnics.active)) {
            warning = {};
            warning.dialogTitle = i18nService.getString('NetworkUi',
                  'PhysicalNicFailoverOrderPage.warn.noActiveNics.title');
            warning.warningMessage = i18nService.getString('NetworkUi',
                  'PhysicalNicFailoverOrderPage.warn.noActiveNics.text');
         } else if (areAllPnicsDisconnected(activePnics, pnicsDetails)) {
            warning = {};
            warning.dialogTitle = i18nService.getString('NetworkUi',
                  'PhysicalNicFailoverOrderPage.warn.allActiveNicsDisconnected.title');
            warning.warningMessage = i18nService.getString('NetworkUi',
                  'PhysicalNicFailoverOrderPage.warn.allActiveNicsDisconnected.text');
         }

         return warning;
      }

      function hasNewPnics(pnics) {
         // unclaimedInitial is an array created by the failoverOrder directive,
         // which is a copy of the initially unclaimed pnics and is used to compare
         // against newly added pnics.
         if (!pnics.unclaimedInitial || pnics.unclaimedInitial.length === 0) {
            return false;
         }
         var hasNewActivePnics = _.some(pnics.active, function (pnic) {
            return _.indexOf(pnics.unclaimedInitial, pnic) !== -1;
         });

         var hasNewStandbyPnics = _.some(pnics.standby, function (pnic) {
            return _.indexOf(pnics.unclaimedInitial, pnic) !== -1;
         });

         var hasNewUnusedPnics = _.some(pnics.unused, function (pnic) {
            return _.indexOf(pnics.unclaimedInitial, pnic) !== -1;
         });

         return hasNewActivePnics || hasNewStandbyPnics || hasNewUnusedPnics;
      }

      function areAllPnicsDisconnected(activePnics, pnicsDetails) {
         return !_.some(activePnics, function (pnic) {
            if (pnic !== null && pnicsDetails[pnic] && pnicsDetails[pnic].isConnected) {
               return true;
            }
         });
      }

      function hasActivePnics(activePnics) {
         return activePnics && activePnics.length > 0;
      }
   }
})();