/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace dvs_ui {

   import HostListItemData = com.vmware.vsphere.client.dvs.api.host.HostListItemData;
   export class DvsAddHostWizardModel {

      readonly operationTypes: any = DvsAddHostWizardConstants.operationType;
      dvsId: string;
      dvsConfigVersion: string;

      get selectedHosts(): HostListItemData[] {
         let hosts: HostListItemData[] = [];
         switch (this.selectOperationPageModel.selectedOperationType) {
            case  DvsAddHostWizardConstants.operationType.ADD_HOST:
               hosts = this.selectHostsPageModel.hosts;
               break;
            case  DvsAddHostWizardConstants.operationType.MANAGE_HOST:
               hosts = this.selectHostsToManagePageModel.hosts;
               break;
            case  DvsAddHostWizardConstants.operationType.REMOVE_HOST:
               hosts = this.selectHostsToRemovePageModel.hosts;
               break;
            case DvsAddHostWizardConstants.operationType.MIGRATE_NETWORKING:
               hosts = this.selectHostsToManagePageModel.hosts;
               break;
         }
         return hosts;
      };

      selectOperationPageModel: SelectOperationPageModel = new SelectOperationPageModel();
      selectHostsPageModel: DvsSelectHostsPageModel = new DvsSelectHostsPageModel();
      selectHostsToManagePageModel: DvsSelectHostsPageModel = new DvsSelectHostsPageModel();
      selectHostsToRemovePageModel: DvsSelectHostsPageModel = new DvsSelectHostsPageModel();
      selectPhysicalAdaptersPageModel: DvsSelectPhysicalAdaptersPageModel =
            new DvsSelectPhysicalAdaptersPageModel();
      selectVirtualAdaptersPageModel: DvsSelectVirtualAdaptersPageModel =
            new DvsSelectVirtualAdaptersPageModel();
      selectVirtualMachinesPageModel: DvsSelectVirtualMachinesPageModel =
            new DvsSelectVirtualMachinesPageModel();
      summaryData: any;
   }
}