/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace dvs_ui {

   import IPromise = angular.IPromise;
   import IQService = angular.IQService;
   import HostListItemData = com.vmware.vsphere.client.dvs.api.host.HostListItemData;
   import DvsHostsData = com.vmware.vsphere.client.dvs.api.host.DvsHostsData;

   export class DvsSelectHostsToManagePageController {

      private static readonly DVS_HOSTS_DATA_PROP: string = "dvs:dvsHostsData";

      public static $inject =
            ["$scope",
               "dataService",
               "$q"];

      constructor(private $scope: DvsAddHostWizardScope,
                  private dataService: any,
                  private $q: IQService) {
      }

      private allHosts: HostListItemData[];

      public onHostsChanged(addedHosts: HostListItemData[]): void {
         this.$scope.model.selectHostsToManagePageModel.hosts = addedHosts;
      }

      public requestHosts(): IPromise<HostListItemData[]> {
         if (!this.allHosts) {
            return this.dataService.getProperties(
                  this.$scope.model.dvsId,
                  [DvsSelectHostsToManagePageController.DVS_HOSTS_DATA_PROP])
                  .then((response: any): HostListItemData[] => {
                     let data: DvsHostsData = response[DvsSelectHostsToManagePageController.DVS_HOSTS_DATA_PROP];
                     if (data) {
                        this.allHosts = data.dvsHosts;
                     } else {
                        this.allHosts = [];
                     }
                     return this.allHosts;
                  });
         } else {
            return this.$q.when(this.allHosts);
         }
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .controller("DvsSelectHostsToManagePageController", DvsSelectHostsToManagePageController);
}
