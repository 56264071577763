namespace h5_vm {
   import Specification = com.vmware.vim.binding.vim.vm.customization.Specification;
   import VmCustomizationSpecification = com.vmware.vsphere.client.vm.VmCustomizationSpecification;
   export class CustomizeGosApplySpecService {
      public static $inject = ["mutationService"];

      constructor(private mutationService: any) {
      }

      apply(vmId: string, specification: Specification): boolean {
         let spec: VmCustomizationSpecification = new VmCustomizationSpecification();
         spec.spec = specification;
         this.mutationService.apply(vmId, spec._type, spec);
         return true;
      }
   }

   angular.module("com.vmware.vsphere.client.vm")
         .service("customizeGosApplySpecService", CustomizeGosApplySpecService);
}
