namespace license_ui {

   export class ProductsListViewController {

      public static $inject = [
            "$scope",
            "licensesManagementNavigationService",
            "licensingRouteObjectsFactoryService",
            "licensesManagementConstants"
      ];

      public scopeProvider: string;
      public selectedTab: any;
      public selectedProductUri: string | undefined;

      private selectedProduct: any;
      private tabPaths: string[];

      constructor(private $scope: any,
                  private licensesManagementNavigationService: LicensesManagementNavigationService,
                  private licensingRouteObjectsFactoryService: LicensingRouteObjectsFactoryService,
                  private licensesManagementConstants: LicensesManagementConstants) {
      }

      public $onInit() {
         this.scopeProvider = this.$scope.masterDetailsViewContext.scopeProvider;
         this.tabPaths = this.$scope.masterDetailsViewContext.tabPaths;

         this.licensesManagementNavigationService.registerNavigationPerformedEventListener(
               this.$scope,
               (licensingRouteObject: LicensingRouteObject) => {
                  this.handleNavigationRequest(licensingRouteObject);
               }
         );

         this.registerSelectedTabChangedEventListener();
         this.tryProcessNavigationRouteInfoInUrl();
      }

      public onSelectedProductChanged(selectedProduct: any): void {
         if (this.selectedProduct !== selectedProduct) {
            this.selectedProduct = selectedProduct;
            this.selectedProductUri = this.extractUriFromProductAggregationItem(this.selectedProduct);

            this.$scope.masterDetailsViewContext.selectedItems = [selectedProduct];

            this.triggerNavigationRequestToSelectedProductItem();
         }
      }

      private handleNavigationRequest(licensingRouteObject: LicensingRouteObject | null): void {
         if (licensingRouteObject
               && this.licensesManagementNavigationService.canNavigationRequestInLicensingRouteBeHandled(this.tabPaths, licensingRouteObject)) {
            if (licensingRouteObject.itemId) {
               this.selectedProductUri = licensingRouteObject.itemId;
            }
         }
      }

      private extractUriFromProductAggregationItem(productAggregationItem: any): string | undefined {
         let productUri: string | undefined = undefined;
         if (productAggregationItem && productAggregationItem.product
               && productAggregationItem.product.product
               && productAggregationItem.product.product.uri) {
            productUri = productAggregationItem.product.product.uri;
         }

         return productUri;
      }

      private triggerNavigationRequestToSelectedProductItem(): void {
         const productsListRouteObject: LicensingRouteObject = this.licensingRouteObjectsFactoryService
               .createProductsListLicensingRouteObject(this.selectedProductUri);
         this.licensesManagementNavigationService
               .emitLicensesManagementNavigationRequestOnScope(this.$scope, productsListRouteObject);
      }

      private registerSelectedTabChangedEventListener(): void {
         this.$scope.$on(this.licensesManagementConstants.LICENSES_MANAGEMENT_SELECTED_TAB_CHANGED_EVENT,
               (event: any, data: SelectedTabInfo) => {
                  this.selectedTab = data.selectedTab;
                  const shouldSelectCurrentTabAsDefault: boolean = data.isDefault && this.isCurrentTabDefaultTab();
                  if (this.selectedProductUri && (shouldSelectCurrentTabAsDefault || this.isCurrentTabSelected())) {
                     this.triggerNavigationRequestToSelectedProductItem();
                  }
               }
         );
      }

      private tryProcessNavigationRouteInfoInUrl(): void {
         const licensingRouteObject: LicensingRouteObject | null =
               this.licensesManagementNavigationService.extractLicensingRouteObjectFromUrl();
         this.handleNavigationRequest(licensingRouteObject);
      }

      private isCurrentTabDefaultTab(): boolean {
         return !(this.tabPaths && this.tabPaths.length);
      }

      private isCurrentTabSelected(): boolean {
         return !!(this.tabPaths && this.tabPaths.length > 0
               && this.selectedTab && this.selectedTab.id === this.tabPaths[this.tabPaths.length-1]);
      }
   }

   angular.module('com.vmware.vsphere.client.licenseUi').controller(
         'ProductsListViewController',
         ProductsListViewController
   );
}
