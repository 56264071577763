/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace dvs_ui {

   import NumericRange = com.vmware.vim.binding.vim.NumericRange;
   import LacpGroupConfig = com.vmware.vim.binding.vim.dvs.VmwareDistributedVirtualSwitch$LacpGroupConfig;
   import LagConstants = dvs_ui.LagConstants;

   export class LagPropertiesFormattingService {

      static $inject = ["i18nService"];

      constructor(private i18nService: any) {
      }

      public getLacpModeText(lacpMode: string): string {
         switch (lacpMode) {
            case LagConstants.Mode.ACTIVE:
               return this.i18nService.getString("DvsUi", "lag.mode.active");
            case LagConstants.Mode.PASSIVE:
               return this.i18nService.getString("DvsUi", "lag.mode.passive");
            default:
               return "";
         }
      }

      public getLoadBalancingModeText(loadBalancingMode: string): string {
         return this.i18nService.getString("DvsUi", "lag.loadBalancingMode." +
               loadBalancingMode);
      }

      public getVlanText(lag: LacpGroupConfig): string {
         if (!lag || lag.vlan === null || lag.vlan.vlanId === null ||
               lag.vlan.vlanId.length === 0) {
            return this.i18nService.getString("DvsUi", "lag.vlan.inherit");
         }

         let vlanTrunkRanges: string = "";

         _.forEach(lag.vlan.vlanId, (range: NumericRange) => {
            if (range.start === range.end) {
               vlanTrunkRanges += range.start.toString();
            } else {
               vlanTrunkRanges += range.start.toString() +
                     LagConstants.Vlan.OUT_RANGE_DIVIDER +
                     range.end.toString();
            }
            vlanTrunkRanges += LagConstants.Vlan.OUT_RANGE_DELIMITER;
         });

         if (vlanTrunkRanges.length > 0) {
            vlanTrunkRanges = vlanTrunkRanges.substring(0,
                  vlanTrunkRanges.length -
                  LagConstants.Vlan.OUT_RANGE_DELIMITER.length);
         }

         return vlanTrunkRanges;
      }

      public getIpfixText(lag: LacpGroupConfig): string {
         if (!lag || lag.ipfix === null || lag.ipfix.ipfixEnabled === null) {
            return this.i18nService.getString("DvsUi", "lag.ipfix.inherit");
         }

         return lag.ipfix.ipfixEnabled ?
               this.i18nService.getString("DvsUi", "lag.ipfix.on") :
               this.i18nService.getString("DvsUi", "lag.ipfix.off");
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .service("lagPropertiesFormattingService", LagPropertiesFormattingService);
}
