/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace dvs_ui {

   export class DvsSelectVirtualMachinesPageModel {

      /**
       * Whether to load virtual machines data.
       */
      public isMigrateVirtualMachineNetworkingEnabled: boolean = false;

      /**
       * Contains all hierarchical grid items in the select virtual machines page.
       */
      public hosts: DvsAddHostTreeListItemWrapper[];

      public isInitialized: boolean = false;
   }
}
