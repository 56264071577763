/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace dvs_ui {

   export class DvpgMiscPageComponent {

      public bindings: any;
      public controller: any;
      public templateUrl: string;

      constructor() {
         this.bindings = {
            model: "<",
            overrideOptions: "<?",
            overrideEnabled: "<?",
            overrideDisallowed: "<?"
         };

         this.controller = DvpgMiscPageComponentController;

         this.templateUrl = "dvs-ui/resources/dvs/dvpg/common/components/" +
               "dvpgMiscPageTemplate.html";
      }
   }

   class DvpgMiscPageComponentController {

      static $inject = ["i18nService"];

      public model: DvpgMiscPolicyModel;
      public overrideOptions: any;
      public overrideEnabled: boolean;
      public overrideDisallowed: boolean;
      private options: any[];
      private blockPortLabel: string;
      private blockPortWarning: string;

      constructor(private i18nService: any) {
      }

      $onInit(): void {
         this.options = [{
            value: true,
            label: this.i18nService.getString("DvsUi", "dvpg.policy.misc.block.on")
         },{
            value: false,
            label: this.i18nService.getString("DvsUi", "dvpg.policy.misc.block.off")
         }];

         this.blockPortLabel = this.overrideEnabled
               ? this.i18nService.getString("NetworkUi", "port.policy.misc.block.label")
               : this.i18nService.getString("DvsUi", "dvpg.manage.policy.misc.block");

         this.blockPortWarning = this.overrideEnabled
               ? this.i18nService.getString("NetworkUi", "port.policy.misc.block.warning")
               : this.i18nService.getString("DvsUi", "dvpg.policy.misc.block.warning");
      }
   }

   angular.module("com.vmware.vsphere.client.dvs").component(
         "dvpgMiscPage", new DvpgMiscPageComponent());
}
