/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
(function() {
   'use strict';
   angular.module('com.vmware.vsphere.client.cluster')
         .controller('RpSettingsPortletController', RpSettingsPortletController);

   RpSettingsPortletController.$inject = ['$scope', 'dataService', 'i18nService',
      'vxPropertyViewService', 'sharesLevelsConstants'];

   function RpSettingsPortletController($scope, dataService, i18nService,
          vxPropertyViewService, sharesLevelsConstants) {

      var self = this;
      var rpId = $scope._route.objectId;

      self.rpId = [rpId];
      self.getViewData = getViewData;
      self.liveRefreshProperties = ["config",
            "summary.quickStats.staticCpuEntitlement",
            "summary.quickStats.staticCpuEntitlement"
      ];

      getViewData();

      function getViewData() {
         dataService.getData(rpId,
               'com.vmware.vsphere.client.clusterui.model.ResourcePoolSummaryData',
               {queryName: 'RpSettingsPortletController.' + getViewData.name})
               .then(function(rpData) {
                  self.resourcePoolData = rpData;

                  buildPropertyGridData(rpData);
               });
      }

      function buildPropertyGridData (resourcePoolData) {
         var builder = vxPropertyViewService.createPropertyViewBuilder();

         createCpuSectionBuilder(builder, resourcePoolData);
         createMemorySectionBuilder(builder, resourcePoolData);

         self.resourcePoolData = builder.build();

      }

      function createCpuSectionBuilder(builder, rpData) {
         if(rpData) {
            var sectionBuilder = builder
                  .category('')
                  .section('cpu')
                  .title(getString('settings.cpu'));

            sectionBuilder.property(getString('settings.shares'),
                  getShares(rpData.configSpec.cpuAllocation.shares));
            sectionBuilder.property(getString('settings.reservation'),
                  rpData.cpuReservationFormatted);
            sectionBuilder.property(getString('settings.limit'),
                  rpData.cpuLimitFormatted);
            sectionBuilder.property(getString('settings.worstcase'),
                  rpData.cpuWorstCaseAllocationFormatted);
         }
      }

      function createMemorySectionBuilder(builder, resourcePoolData) {
         if(resourcePoolData) {
            var memorySettingsBuilder = builder
                  .category('')
                  .section('memory')
                  .title(getString('settings.memory'));

            memorySettingsBuilder.property(getString('settings.shares'),
                  getShares(resourcePoolData.configSpec.memoryAllocation.shares));
            memorySettingsBuilder.property(getString('settings.reservation'),
                  resourcePoolData.memoryReservationFormatted);
            memorySettingsBuilder.property(getString('settings.limit'),
                  resourcePoolData.memoryLimitFormatted);
            memorySettingsBuilder.property(getString('settings.worstcase'),
                  resourcePoolData.memoryWorstCaseAllocationFormatted);
         }
      }

      function getShares(shares) {
         return i18nService.getString('RpUi', 'resourceAllocationInfoControl.shares',
               formatSharesLevel(shares.level), shares.shares);
      }

      function formatSharesLevel(level) {
         switch(level){
            case sharesLevelsConstants.sharesLevel.LOW:
               return getString('rpList.lowSharesLabel');
            case sharesLevelsConstants.sharesLevel.HIGH:
               return getString('rpList.highSharesLabel');
            case sharesLevelsConstants.sharesLevel.NORMAL:
               return getString('rpList.normalSharesLabel');
            case sharesLevelsConstants.sharesLevel.CUSTOM:
               return getString('rpList.customSharesLabel');
         }
      }

      function getString(key) {
         return i18nService.getString('RpUi', key);
      }
   }
})();
