/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace storage_ui {

   export class NfsKerberosAuthenticationPageService {

      public static $inject = ["wizardPageService", "i18nService", "vuiConstants"];

      constructor(
            private wizardPageService: any,
            private i18nService: any,
            private vuiConstants: any) {

      }

      private validate(wizardScope: any): boolean {
         this.wizardPageService.markPageComplete(wizardScope.wizardConfig,
               wizardScope.commonWizardManager.getDatastoreType());

         return true;
      }

      public build(wizardScope: any): any {
         return {
            title: this.i18nService.getString("StorageUi", "addDatastoreWizard.nfsKerberosPage.title"),
            description: this.i18nService.getString("StorageUi", "addDatastoreWizard.nfsKerberosPage.description"),
            contentUrl: "storage-ui/resources/storage/views/wizard/createDatastore/pages/nfs/NfsKerberousAuthenticationComponentPage.html",
            defaultState: this.vuiConstants.wizard.pageState.SKIPPED,
            onCommit: ()=> {
               return this.validate(wizardScope);
            }
         };
      }
   }
   angular.module("com.vmware.vsphere.client.storage")
         .service("nfsKerberosAuthenticationPageService", NfsKerberosAuthenticationPageService);
}
