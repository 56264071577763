/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace dvs_ui {

   export class DvpgCreateWizardModel {
      dvsId: string | null;
      contextObjId: string;
      name: string;
      switchName: string;
      switchIconId: any;
      portBinding: string;
      autoExpand: boolean;
      numPorts: number;
      defaultNumPortsChanged: boolean;
      isNetworkResourcePoolSupported: boolean;
      isVmVnicResourcePoolSupported: boolean;
      networkResourcePoolKey: string;
      networkResourcePools: any[];
      vlanType: string;
      vlanId: number;
      vlanTrunkRanges: string;
      vlanTrunkNumericRanges: any;
      pvlanId: number;
      pvlanConfig: any[];
      showPvlanError: boolean;
      summaryData: any;

      isPolicyConfigurationEnabled: boolean;
      trafficShapingPolicyModel: DvpgTrafficShapingPolicyModel;
      miscPolicyModel: DvpgMiscPolicyModel;
      monitoringPolicyModel: DvpgMonitoringPolicyModel;
      failoverPolicyModel: DvpgFailoverPolicyModel;
      securityPolicyModel: DvpgSecurityPolicyModel;
      failoverOrderOptions: any;

   }
}
