namespace h5_client {
    export module common_module_ui {

        class CreateContentLibraryFinishPageService {
            static $inject = ['i18nService'];

            constructor(private i18nService: any) {

            }

            public build() {
                return {
                    title: this.i18nService.getString('LibraryUi', 'createLibraryWizard.readyToCompletePage.title'),
                    description: this.i18nService.getString('LibraryUi', 'createLibraryWizard.readyToCompletePage.description'),
                    contentUrl: 'common-module-ui/resources/common-module/views/content-library/wizard/pages/createContentLibraryFinish.html'
                };
            }
        }

        angular.module("com.vmware.vsphere.client.commonModule")
            .service("createContentLibraryFinishPageService", CreateContentLibraryFinishPageService);
    }
}
