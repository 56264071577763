(function () {
   angular.module('com.vmware.vsphere.client.vm')
         .service('vmDatastoreLocationBrowserService', vmDatastoreLocationBrowserService);

   vmDatastoreLocationBrowserService.$inject = [
       '$q',
       'i18nService',
       'dataService',
       'clarityModalService',
       'defaultUriSchemeUtil',
       'storageLocatorService'
   ];

   function vmDatastoreLocationBrowserService(
       $q,
       i18nService,
       dataService,
       clarityModalService,
       defaultUriSchemeUtil,
       storageLocatorService
   ) {
      return {
         getStorageLocatorItems: function (vmId) {
             return dataService.getProperties(vmId, 'host')
                .then(function(result) {
                   var hostRef = defaultUriSchemeUtil.getVsphereObjectId(result.host);
                   return storageLocatorService
                       .getStorageLocatorData(hostRef, hostRef);
             });
         },
         choose: function (scope, options) {
            var deferredSelection = $q.defer();

            var modalOptions = {
               storageData: options.storageData,
               storageProfilesData: options.storageProfilesData,
               disableSdrs: options.disableSdrs,
               comparatorWrapper: options.comparatorWrapper,
               preselectItem: options.preselectItem,
               onSelectionChanged: function (selectedItem) {
                  modalOptions.submitDisabled = !selectedItem;
               },
               title: i18nService.getString('Common', 'StorageLocatorDialog.Title'),
               contentTemplate: 'vm-ui/resources/vm/views/settings/vmHardwareSettings/vmHardwareVirtualDisk/vmDatastoreLocationBrowserDialog.html',
               defaultButton: 'submit',
               size: 'lg',
               hideSubmitButton: false,
               submitDisabled: true,
               onCancel: function() {
                  deferredSelection.reject();
                  return true;
               }
            };

            modalOptions.onSubmit = function() {
               deferredSelection.resolve({
                  selectedStorageItem: {
                     storageObject: modalOptions.storageData.selectedStorageItem,
                     name: modalOptions.storageData.selectedStorageItem.name,
                     id: defaultUriSchemeUtil.getVsphereObjectId(modalOptions.storageData.selectedStorageItem.storageRef)
                  },
                  selectedStorageProfile: modalOptions.storageProfilesData.selectedProfile
               });
               return true;
            };

            clarityModalService.openOkCancelModal(modalOptions);

            return deferredSelection.promise;
         }
      };
   }
})();
