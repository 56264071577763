(function() {
   'use strict';
   angular
      .module('com.vmware.vsphere.client.commonModule')
      .controller('ViHomeController', ViHomeController);

   ViHomeController.$inject = ['dataService', 'i18nService'];

   function ViHomeController(dataService, i18nService) {
      var self = this;

      self.objectId = "urn:vmomi:Folder:aggregatedData:serverGUID";

      self.getObjSummaryData = function () {
         dataService.getData(self.objectId,
            "com.vmware.vsphere.client.folder.model.AggregatedFolderSummaryData").then(function(data) {
            self.summaryData = data;
         });
      };

      self.getObjSummaryData();

      self.getFreeCapacity = function (resourceMeter) {
         return resourceMeter.progress > 100 ?
            i18nService.getString('Common', 'ResourceAllocationComponent.overusedCapFormat', resourceMeter.free) :
            i18nService.getString('Common', 'ResourceAllocationComponent.freeCapFormat', resourceMeter.free);
      };
   }

})();

