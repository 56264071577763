namespace spbm_ui {
   export class SpbmManageVmStorageProfilesActionService {
      static $inject = ['$injector'];

      private _vscSpbmManageVmStorageProfilesActionService: SpbmManageVmStorageProfilesActionService;

      constructor(private $injector: any) {
      }

      get vscSpbmManageVmStorageProfilesActionService() {
         if (!this._vscSpbmManageVmStorageProfilesActionService) {
            this._vscSpbmManageVmStorageProfilesActionService =
                  this.$injector.get('vscSpbmManageVmStorageProfilesModalService');
         }
         return this._vscSpbmManageVmStorageProfilesActionService;
      }

      openDialog(vmIds: string[]): void {
         this.vscSpbmManageVmStorageProfilesActionService.openDialog(vmIds);
      }
   }

   angular.module("com.vmware.vsphere.client.spbm")
         .service("spbmManageVmStorageProfilesActionService", SpbmManageVmStorageProfilesActionService);
}