/**
 * @name com.vmware.vsphere.client.cluster:haConstants
 * @module com.vmware.vsphere.client.cluster
 *
 * @description
 *    The `haConstants` service holds cluster HA related constants.
 *
 *    List of available constants:
 *
 *    - admissionControlPolicy
 *       - **`FAILOVER_LEVEL_TYPE`** : Failover level admission control policy type
 *       - **`FAILOVER_RESOURCES`** : Failover resource admission control policy type
 *       - **`FAILOVER_HOSTS_TYPE`** : Failover host admission control policy type
 *       - **`DISABLED_TYPE`** : Failover host admission control policy type
 *    - haVmMonitoring (ClusterDasConfigInfo.vmMonitoring enum values)
 *       - **`DISABLED`** : VM monitoring feature is not enabled.
 *       - **`VM_ONLY`** : Only VMs are monitored.
 *       - **`VM_AND_APP`** : Both VMs and Apps are monitored.
 *    - hostMonitoringState (ClusterDasConfigInfo.hostMonitoring)
 *       - **`ENABLED`** : Host monitoring feature is not enabled.
 *       - **`DISABLED`** : Host monitoring feature is not enabled.
 *    - restartPriority (ClusterDasConfigInfo.defaultVmSettings.restartPriority)
 *       - **`DISABLED`** : Restart priority is disabled.
 *       - **`LOW`** : Lower priority when restarting
 *       - **`MEDIUM`** : Medium priority when restarting
 *       - **`HIGH`** : Higher priority when restarting
 *    - hostIsolationResponse (ClusterDasConfigInfo.defaultVmSettings.isolationResponse)
 *       - **`LEAVE_POWERED_ON`** : Do not power off the virtual machine in the event of
 *             a host network isolation.
 *       - **`POWER_OFF_AND_FAILOVER`** : Power off the virtual machine in the event
 *             of a host network isolation.
 *       - **`SHUT_DOWN_AND_FAILOVER`** : Shut down the virtual machine guest operating
 *             system in the event of a host network isolation.
 *    - vmMonitoringSensitivity
 *       - low values for different options for low VM monitoring sensitivity
 *          - ** `FAILURE_INTERVAL` ** : value for failureInterval
 *          - ** `MIN_UPTIME` ** : value for minUpTime
 *          - ** `MAX_FAILURES` ** : value for maxFailures
 *          - ** `FAILURE_WINDOW` ** : value for maxFailureWindow
 *       - medium values for different options for medium VM monitoring sensitivity
 *          - ** `FAILURE_INTERVAL` ** : value for failureInterval
 *          - ** `MIN_UPTIME` ** : value for minUpTime
 *          - ** `MAX_FAILURES` ** : value for maxFailures
 *          - ** `FAILURE_WINDOW` ** : value for maxFailureWindow
 *       - high values for different options for high VM monitoring sensitivity
 *          - ** `FAILURE_INTERVAL` ** : value for failureInterval
 *          - ** `MIN_UPTIME` ** : value for minUpTime
 *          - ** `MAX_FAILURES` ** : value for maxFailures
 *          - ** `FAILURE_WINDOW` ** : value for maxFailureWindow
 *       - ** `NO_FAILURE_WINDOW` ** : value for maxFailureWindow for case when
 *             no failure window is defined
 *    - hbDatastoreCandidatePolicy (ClusterDasConfigInfoHBDatastoreCandidate)
 *       - **`AUTO_SELECT`** : vCenter Server chooses heartbeat datastores from all the
 *             feasible ones.
 *       - **`USER_SELECTED_ONLY`** : vCenter Server chooses heartbeat datastores from
 *             the set specified by the user.
 *       - **`USER_SELECTED_WITH_AUTO_SELECTED`** : vCenter Server chooses heartbeat
 *             datastores from all the feasible ones while giving preference to those
 *             specified by the user.
 */
angular.module('com.vmware.vsphere.client.cluster').constant('haConstants', {
   SECONDS_IN_A_MINUTE: 60,
   MAX_POST_READY_DELAY: 3600,
   MAX_RESTART_PRIORITY_TIMEOUT: 86400,
   MAX_FAILURE_INTERVAL: 100000,
   MAX_MINIMUM_UPTIME: 100000,
   MAX_VM_PER_RESET: 1000,
   MIN_RESETS_WINDOW_HRS: 1,
   MAX_RESETS_WINDOW_HRS: 1000,
   MAX_VM_FAILOVER_DELAY: 35791394,
   MIN_NUMBER_INPUT: 0,
   admissionControlPolicy: {
      DISABLED_TYPE: 'disabled',
      FAILOVER_LEVEL_TYPE: 'com.vmware.vim.binding.vim.cluster.FailoverLevelAdmissionControlPolicy',
      FAILOVER_RESOURCES_TYPE: 'com.vmware.vim.binding.vim.cluster.FailoverResourcesAdmissionControlPolicy',
      FAILOVER_HOSTS_TYPE: 'com.vmware.vim.binding.vim.cluster.FailoverHostAdmissionControlPolicy'
   },
   slotSizePolicy: {
      COVER_ALL_VM: 'coverAllVm',
      FIXED_SIZE: 'fixedSize'
   },
   vmMonitoring: {
      DISABLED: 'vmMonitoringDisabled',
      VM_ONLY: 'vmMonitoringOnly',
      VM_AND_APP: 'vmAndAppMonitoring'
   },
   // TODO remove references to hostMonitoringState and replace with serviceState
   hostMonitoringState: {
      ENABLED: 'enabled',
      DISABLED: 'disabled'
   },
   serviceState: {
      ENABLED: 'enabled',
      DISABLED: 'disabled'
   },
   restartPriority: {
      DISABLED: 'disabled',
      LOWEST: 'lowest',
      LOW: 'low',
      MEDIUM: 'medium',
      HIGH: 'high',
      HIGHEST: 'highest'
   },
   readyCondition: {
      NONE: "none",
      POWERED_ON: "poweredOn",
      APP_HEARTBEAT_DETECTED: "appHbStatusGreen",
      GUEST_HEARTBEAT_DETECTED: "guestHbStatusGreen"
   },
   hostIsolationResponse: {
      LEAVE_POWERED_ON: 'none',
      POWER_OFF_AND_FAILOVER: 'powerOff',
      SHUT_DOWN_AND_FAILOVER: 'shutdown'
   },
   vmMonitoringSensitivity: {
      low: {
         FAILURE_INTERVAL: 120,
         MIN_UPTIME: 480,
         MAX_FAILURES: 3,
         FAILURE_WINDOW: 168,
         HAS_WINDOW: true
      },
      medium: {
         FAILURE_INTERVAL: 60,
         MIN_UPTIME: 240,
         MAX_FAILURES: 3,
         FAILURE_WINDOW: 24,
         HAS_WINDOW: true
      },
      high: {
         FAILURE_INTERVAL: 30,
         MIN_UPTIME: 120,
         MAX_FAILURES: 3,
         FAILURE_WINDOW: 1,
         HAS_WINDOW: true
      },
      NO_FAILURE_WINDOW: -1
   },
   hbDatastoreCandidatePolicy: {
      AUTO_SELECT: 'allFeasibleDs',
      USER_SELECTED_ONLY: 'userSelectedDs',
      USER_SELECTED_WITH_AUTO_SELECTED: 'allFeasibleDsWithUserPreference'
   },
   storageVmReaction: {
      DISABLED: 'disabled',
      WARNING: 'warning',
      RESTART_CONSERVATIVE: 'restartConservative',
      RESTART_AGGRESSIVE: 'restartAggressive'
   },
   vmReactionOnApdCleared: {
      NONE: 'none',
      RESET: 'reset',
      USE_CLUSTER_DEFAULT: 'useClusterDefault'
   }
});
