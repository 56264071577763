(function() {
   angular.module('com.vmware.vsphere.client.folder')
      .controller('addDatacenterController', addDatacenterController);

   addDatacenterController.$inject = ['$scope', 'datacenterService', 'dataService'];

   function addDatacenterController($scope, datacenterService, dataService) {
      var self = this;

      self.loading = true;
      $scope.modalOptions.submitDisabled=true;
      self.moRef = $scope.modalOptions.dialogData.objectId;
      self.icon = $scope.modalOptions.iconClass;

      $scope.modalOptions.onSubmit = createDatacenter;

      dataService.getProperties(self.moRef , ['name', 'primaryIconId', 'newUniqueDatacenterName']).then(function(data) {
         self.name = data.newUniqueDatacenterName;
         self.location = data.name;
         self.parentIconClass = data.primaryIconId;
         self.loading = false;
         $scope.modalOptions.submitDisabled=false;
      });

      function createDatacenter() {
         datacenterService.createDatacenter(self.name, self.moRef);
         $scope.closeModal();

      }
   }
})();
