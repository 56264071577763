/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace dvs_ui {

   class DvpgCreateReadyToCompletePageController {

      public static $inject = [
            "$scope",
            "i18nService",
            "vxPropertyViewService",
            "networkUiConstants",
            "dvpgCreateWizardConstants",
            "dvpgCreateReadyToCompletePageService"
      ];

      constructor(private $wizardScope: DvpgCreateWizardScope,
                  private i18nService: any,
                  private vxPropertyViewService: any,
                  private networkUiConstants: any,
                  private wizardConstants: any,
                  private pageService: any) {

         let builder: any = vxPropertyViewService.createPropertyViewBuilder();

         let generalCategory: any = builder.category("general");
         let generalSection: any = generalCategory.section("generalSection");

         // Distributed Portgroup Name
         generalSection.property(
               this.i18nService.getString("DvsUi", "dvpg.create.finish.name"),
            this.$wizardScope.model.name);

         // Port Binding
         generalSection.property(
               this.i18nService.getString(
                     "DvsUi", "dvpg.create.finish.portBinding"),
               this.pageService.getPortBindingLabel(
                     this.$wizardScope.model.portBinding));

         if (this.$wizardScope.model.portBinding ===
               this.wizardConstants.portBinding.EARLY_BINDING) {
            // Number of Ports
            generalSection.property(
                  this.i18nService.getString(
                        "DvsUi", "dvpg.create.finish.numberOfPorts"),
                  this.$wizardScope.model.numPorts);

            // Port Allocation
            generalSection.property(
                  this.i18nService.getString(
                        "DvsUi", "dvpg.create.finish.portAllocation"),
                  this.pageService.getPortAllocationLabel(
                        this.$wizardScope.model.autoExpand));
         }

         // Network Resource Pool
         generalSection.property(
               this.i18nService.getString(
                     "DvsUi", "dvpg.create.finish.resourcePool"),
               this.pageService.getNetworkResourcePoolLabel(
                     this.$wizardScope.model.networkResourcePoolKey,
                     this.$wizardScope.model.networkResourcePools));

         // VLAN
         generalSection.property(
               this.pageService.getVlanPropertyName(this.$wizardScope.model.vlanType),
               this.pageService.getVlanPropertyValue(this.$wizardScope.model));

         this.$wizardScope.model.summaryData = builder.build();
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .controller("DvpgCreateReadyToCompletePageController",
               DvpgCreateReadyToCompletePageController);
}
