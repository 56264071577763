namespace license_ui {

   export class AssetsAdministrationViewController {

      public static $inject = [
            "$scope",
            "licensingRouteObjectsFactoryService",
            "licensesManagementNavigationService",
            "licensesManagementConstants"
      ];

      public scopeProvider: string;
      public selectedTab: any;
      public selectedAssetTabId: string;
      public focusedAssetItemUri: string | undefined;

      private tabPaths: string[];

      constructor(private $scope: any,
                  private licensingRouteObjectsFactoryService: LicensingRouteObjectsFactoryService,
                  private licensesManagementNavigationService: LicensesManagementNavigationService,
                  private licensesManagementConstants: LicensesManagementConstants) {
      }

      public $onInit() {
         this.scopeProvider = this.$scope.scopeProvider;
         this.tabPaths = this.$scope.tabPaths;

         this.licensesManagementNavigationService.registerNavigationPerformedEventListener(
               this.$scope,
               (licensingRouteObject: LicensingRouteObject) => {
                  this.handleNavigationRequest(licensingRouteObject);
               }
         );

         this.registerSelectedTabChangedEventListener();
         this.tryProcessNavigationRouteInfoInUrl();
      }

      public onSelectedAssetTabIdChanged(assetTabId: string): void {
         if (assetTabId && this.selectedAssetTabId !== assetTabId) {
            this.selectedAssetTabId = assetTabId;
            this.focusedAssetItemUri = undefined;
            this.triggerNavigationRequestToSelectedAssetTab();
         }
      }

      private triggerNavigationRequestToSelectedAssetTab(): void {
         const assetsListRouteObject: LicensingRouteObject = this.licensingRouteObjectsFactoryService
               .createAssetsListLicensingRouteObject(this.selectedAssetTabId, this.focusedAssetItemUri);
         this.licensesManagementNavigationService
               .emitLicensesManagementNavigationRequestOnScope(this.$scope, assetsListRouteObject);
      }

      private handleNavigationRequest(licensingRouteObject: LicensingRouteObject | null): void {
         if (licensingRouteObject &&
               this.licensesManagementNavigationService.canNavigationRequestInLicensingRouteBeHandled(this.tabPaths, licensingRouteObject)) {
            const licensingRouteObjectForSubTabs: LicensingRouteObject | null =
                  this.licensesManagementNavigationService.createLicensingRouteObjectForSubTabs(this.tabPaths, licensingRouteObject);
            if (licensingRouteObjectForSubTabs && licensingRouteObjectForSubTabs.tabPaths.length > 0) {
               if (this.selectedAssetTabId === licensingRouteObjectForSubTabs.tabPaths[0]) {
                  this.broadcastEventForAssetsManagementNavigationPerformed(licensingRouteObjectForSubTabs);
               }

               this.selectedAssetTabId = licensingRouteObjectForSubTabs.tabPaths[0];
               this.focusedAssetItemUri = licensingRouteObjectForSubTabs.itemId;
            }
         }
      }

      private registerSelectedTabChangedEventListener(): void {
         this.$scope.$on(this.licensesManagementConstants.LICENSES_MANAGEMENT_SELECTED_TAB_CHANGED_EVENT,
               (event: any, data: SelectedTabInfo) => {
                  this.selectedTab = data.selectedTab;
                  const shouldSelectCurrentTabAsDefault: boolean = data.isDefault && this.isCurrentTabDefaultTab();
                  if (this.selectedAssetTabId && (shouldSelectCurrentTabAsDefault || this.isCurrentTabSelected())) {
                     this.triggerNavigationRequestToSelectedAssetTab();
                  }
               }
         );
      }

      private broadcastEventForLicensesManagementNavigationPerformed(
            licensingRouteObject: LicensingRouteObject | null): void {
         this.$scope.$broadcast(
               this.licensesManagementConstants.LICENSES_MANAGEMENT_NAVIGATION_PERFORMED_EVENT,
               licensingRouteObject
         );
      }

      private broadcastEventForAssetsManagementNavigationPerformed(
            licensingRouteObject: LicensingRouteObject | null): void {
         this.$scope.$broadcast(
               this.licensesManagementConstants.ASSETS_MANAGEMENT_NAVIGATION_PERFORMED_EVENT,
               licensingRouteObject
         );
      }

      private tryProcessNavigationRouteInfoInUrl(): void {
         const licensingRouteObject: LicensingRouteObject | null =
               this.licensesManagementNavigationService.extractLicensingRouteObjectFromUrl();
         this.handleNavigationRequest(licensingRouteObject);
      }

      private isCurrentTabDefaultTab(): boolean {
         return !(this.tabPaths && this.tabPaths.length);
      }

      private isCurrentTabSelected(): boolean {
         return !!(this.tabPaths && this.tabPaths.length > 0
               && this.selectedTab && this.selectedTab.id === this.tabPaths[this.tabPaths.length-1]);
      }
   }

   angular.module('com.vmware.vsphere.client.licenseUi').controller(
         'AssetsAdministrationViewController',
         AssetsAdministrationViewController
   );
}
