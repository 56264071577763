namespace ds_cluster_ui {

   import bind = kendo.bind;
   export class FaultsAndRecommendationsComponent {
      public bindings: any;
      public controller: any;
      public templateUrl: string;

      constructor() {
         this.bindings = {
            faults: "<",
            recommendations: "<",
            recommendationStore: "=",
         };

         this.controller = FaultsAndRecommendationsController;
         this.templateUrl = "ds-cluster-ui/resources/ds-cluster/views/maintenanceMode/faultsAndRecommendationsView.html";
      }
   }

   class FaultsAndRecommendationsController {

      public static $inject = ["i18nService", "vuiConstants", "clarityConstants",
         "drsFaultsViewConstants", "$timeout", "datastoreMaintenanceModeService"];

      private faultDetailsGridOptions: any;
      private faults: any;
      private recommendations: any;
      private recommendationStore: any;
      private i18n: any;

      constructor(private i18nService: any, private vuiConstants: any,
                  private clarityConstants: any, private drsFaultsViewConstants: any,
                  private $timeout: any, private datastoreMaintenanceModeService:any) {
         this.i18n = this.i18nService.getString;
      }

      $onInit(): void {
         this.faultDetailsGridOptions =
               this.datastoreMaintenanceModeService.getFaultDetailsGridOptions(this.faults);
      }
   }

   angular.module("com.vmware.vsphere.client.dsCluster")
         .component("faultsAndRecommendations",
               new FaultsAndRecommendationsComponent());
}