module cluster_ui {

   import IController = angular.IController;

   class DrsNetworkUtilizationComponentController implements IController {
      static $inject = ["$rootScope", "i18nService", "defaultUriSchemeUtil"];

      constructor(private $rootScope: any, private i18nService: any,
                  private defaultUriSchemeUtil: any) { }
      public signPostParams: any = {};

      public getWidth(value: number): number {
         return Math.max(value, 0.5);
      }

      public goToSelectedHostLink(host: any): void {
         let selectedHostObjectId: string =
               this.defaultUriSchemeUtil.getVsphereObjectId(host.provider);
         this.$rootScope._navigateToObject(selectedHostObjectId);
      }

      public buildSignPostData(host: any, receivedMode: boolean): void {
         let templateUrl: string =
               "cluster-ui/resources/cluster/views/monitor/drs/networkHostBarTooltipContent.html";

         host.receivedMode = receivedMode;

         this.signPostParams = {
            templateUrl: templateUrl,
            title: host.name,
            model: host,
            width: 300
         };
      }

      public getString(key: string, ...params: any[]) : string {
         return this.i18nService.getString("ClusterUi", key, params);
      }
   }

   class DrsNetworkUtilizationComponent implements ng.IComponentOptions {
      public controller: any;
      public templateUrl: string;
      public bindings: any;

      constructor() {
         this.controller = DrsNetworkUtilizationComponentController;
         this.templateUrl =
               "cluster-ui/resources/cluster/views/monitor/drs/drsNetworkUtilizationComponent.html";
         this.bindings = {
            data: "<",
            receivedMode: "<"
         };
      }
   }

   angular.module("com.vmware.vsphere.client.cluster").component(
         "drsNetworkUtilizationComponent", new DrsNetworkUtilizationComponent());
}
