/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace dvs_ui {

   angular.module("com.vmware.vsphere.client.dvs").constant("dvpgManageWizardConstants", {
      page: {
         POLICY_TYPE: "policyTypePage",
         SELECT_PORTGROUPS: "selectPortgroupsPage",
         SECURITY_POLICY: "securityPolicyPage",
         VLAN: "vlanPage",
         TRAFFIC_SHAPING: "trafficShapingPage",
         FAILOVER_POLICY: "teamingAndFailoverPage",
         VM_VNIC_RESOURCE_POOL: "vmVnicResourcePoolPage",
         MONITORING: "monitoringPage",
         MISC: "miscPage",
         SUMMARY: "readyToCompletePage"
      },
      properties: {
         WIZARD_DATA: "manageDvpg:wizardData",
         POLICY_DATA: "manageDvpg:policiesData"
      },
      privileges: {
         DVPORTGROUP_MODIFY: "DVPortgroup.Modify"
      }
   });
}
