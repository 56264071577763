(function () {
   'use strict';

   /**
    * Shows list of host services that can be enabled on a VMkernel adapter.
    * The services are rendered as list of checkbox label pairs.
    *
    * Example:
    *    [] vMotion
    *    [] Provisioning
    *    [] Fault Tolerance loging
    *
    * @param hostVnicFeatures (input, optional)
    *    Features describing the services the directive will render.
    *
    *    Example:
    *    ```js
    *       var hostVnicFeatures = {
    *          isVmotionConfigurable: true,
    *          isProvisioningConfigurable: true,
    *          isVsanSupported: true
    *          // ...
    *       }
    *    ```
    *
    * @param hostVnicSettings (input, output, optional)
    *
    *    Example:
    *    ```js
    *       var hostVnicSettings = {
    *          isVmotionEnabled: true,
    *          isProvisioningNfcTrafficEnabled: false,
    *          isVsanEnabled: false
    *          // ...
    *       }
    *    ```
    *
    *    Usage:
    *       <host-vnic-services
    *          host-vnic-features="hostVnicFeatures"
    *          host-vnic-settings="vmkernelSettingsSpec">
    *       </host-vnic-services>
    *
    * @see hostVnicServiceConstants.keys
    * @see hostVnicServiceConstants.featureFlags
    */
   angular
         .module('com.vmware.vsphere.client.network')
         .directive('hostVnicServices', hostVnicServices);

   hostVnicServices.$inject = [];

   function hostVnicServices() {
      var directive = {
         restrict: 'E',
         scope: {
            hostVnicFeatures: '=?', // show/hide or enable/disable UI components
            hostVnicSettings: '=?'  // on/off service traffic
         },
         templateUrl: 'network-ui/resources/network/views/host/common/' +
               'hostVnicServices/hostVnicServices.html',
         controller: HostVnicServicesController
      };

      return directive;
   }

   // Note: i18n service is not available for templates of directive's with isolated
   // scope. Hence it is injected explicitly.
   HostVnicServicesController.$inject = [
         '$scope',
         'i18nService',
         'hostVnicServiceFactory'];

   function HostVnicServicesController(
         $scope,
         i18nService,
         hostVnicServiceFactory) {

      $scope.getString = getString;

      $scope.hostVnicSettings = $scope.hostVnicSettings
            || hostVnicServiceFactory
                  .getServiceSettings($scope.hostVnicFeatures);

      $scope.$watch('hostVnicFeatures', function (value) {
         $scope.services = hostVnicServiceFactory
               .getServiceDescriptions(value, $scope.hostVnicSettings);
      });

      function getString(key) {
         return i18nService.getString('NetworkUi', key);
      }
   }
})();