(function() {
   'use strict';
   angular.module('com.vmware.vsphere.client.cluster')
         .controller('AddClusterRuleController', AddClusterRuleController);

   AddClusterRuleController.$inject = ['$scope', 'i18nService', '$q',
         'vuiConstants', 'mutationService', 'clusterRulesService',
         'clusterSpecBuilderService', 'clusterRulesConstants',
         'defaultUriSchemeUtil', 'vcService'];

   function AddClusterRuleController($scope, i18nService, $q, vuiConstants,
                                     mutationService, clusterRulesService,
                                     clusterSpecBuilderService, clusterRulesConstants,
                                     defaultUriSchemeUtil, vcService) {
      var self = this;
      var objectId = $scope._route.objectId;
      self.isRuleTypeSelectDisabled = false;
      $scope.modalOptions.isAlertClosed = true;

      if(!$scope.modalOptions.dialogData || !$scope.modalOptions.dialogData.rule) {
         self.spec = {};
         self.spec.ruleType = clusterRulesService.DEFAULT_TYPE;
         self.spec.name = '';
         self.spec.enabled = true;
         self.spec.operation = clusterRulesConstants.operationType.ADD;
      } else {
         self.spec = $scope.modalOptions.dialogData.rule;
         self.spec.ruleType = clusterRulesService.getSpecTypeByRuleType(
               $scope.modalOptions.dialogData.rule.type);
         self.spec.operation = clusterRulesConstants.operationType.EDIT;
         self.isRuleTypeSelectDisabled = true;
      }
      getViewData();

      function getViewData() {
         vcService.is65VcOrLater(objectId)
               .then(function(is65VcOrLater) {
                  self.typeOptions = clusterRulesService.getRuleTypes(is65VcOrLater);
               });
      }

      $scope.modalOptions.onSubmit = function() {
         return onCommit();
      };

      function getString(key) {
         return i18nService.getString('ClusterUi', key);
      }

      function validate() {
         if (self.spec.name) {
            if ((/^\s*$/gi).test(self.spec.name)) {
               $scope.modalOptions.alerts = [{
                  text: getString('rules.config.error.nameEmpty'),
                  type: vuiConstants.validationBanner.type.ERROR
               }];
               $scope.modalOptions.isAlertClosed = false;

               return $q.reject();
            } else if (self.spec.name.length > 80) {
               $scope.modalOptions.alerts = [{
                  text: getString('rules.config.error.nameTooLong'),
                  type: vuiConstants.validationBanner.type.ERROR
               }];
               $scope.modalOptions.isAlertClosed = false;

               return $q.reject();
            }
         } else {
            $scope.modalOptions.alerts = [{
               text: getString('rules.config.error.nameEmpty'),
               type: vuiConstants.validationBanner.type.ERROR
            }];
            $scope.modalOptions.isAlertClosed = false;

            return $q.reject();
         }

         if (self.spec.ruleType === clusterRulesConstants.ruleSpec.VM_AFFINITY_RULE
               || self.spec.ruleType === clusterRulesConstants.ruleSpec.VM_ANTIAFFINITY_RULE) {
            if (self.spec.vmAffinityData.length < 2) {
               $scope.modalOptions.alerts = [{
                  text: getString('rules.config.members.error.noMembers'),
                  type: vuiConstants.validationBanner.type.ERROR
               }];
               $scope.modalOptions.isAlertClosed = false;
               return $q.reject();
            }
         }

         var ruleProps = null;

         if (self.spec.ruleType === clusterRulesConstants.ruleSpec.VM_HOST_GROUP_RULE) {
            if (!self.spec.selectedVmGroup) {
               $scope.modalOptions.alerts = [{
                  text: getString('rules.config.error.noSelectedVmGroup'),
                  type: vuiConstants.validationBanner.type.ERROR
               }];
               $scope.modalOptions.isAlertClosed = false;
               return $q.reject();
            } else if (self.spec.invalidVmGroup) {
               $scope.modalOptions.alerts = [{
                  text: getString('rules.config.relationTypes.selectVmGroup'),
                  type: vuiConstants.validationBanner.type.ERROR
               }];
               $scope.modalOptions.isAlertClosed = false;
               return $q.reject();
            } else if (!self.spec.selectedHostGroup) {
               $scope.modalOptions.alerts = [{
                  text: getString('rules.config.error.noSelectedHostGroup'),
                  type: vuiConstants.validationBanner.type.ERROR
               }];
               $scope.modalOptions.isAlertClosed = false;
               return $q.reject();
            } else if (self.spec.invalidHostGroup) {
               $scope.modalOptions.alerts = [{
                  text: getString('rules.config.relationTypes.selectHostGroup'),
                  type: vuiConstants.validationBanner.type.ERROR
               }];
               $scope.modalOptions.isAlertClosed = false;
               return $q.reject();
            } else {
               ruleProps = {
                  vmGroupName: self.spec.selectedVmGroup,
                  hostGroupName: self.spec.selectedHostGroup,
                  isMandatory: self.spec.selectedVmToHostRuleType.isMandatory,
                  isAffineHostGroupName: self.spec.selectedVmToHostRuleType.isAffineHostGroupName
               };
            }
         }

         if (self.spec.ruleType === clusterRulesConstants.ruleSpec.VM_DEPENDENCY_RULE) {
            if (!self.spec.selectedVmGroup || !self.spec.selectedDependsOnVmGroup) {
               $scope.modalOptions.alerts = [{
                  text: getString('rules.config.error.noSelectableVmGroup'),
                  type: vuiConstants.validationBanner.type.ERROR
               }];
               $scope.modalOptions.isAlertClosed = false;
               return $q.reject();
            } else {
               ruleProps = {
                  vmGroup: self.spec.selectedVmGroup,
                  dependsOnVmGroup: self.spec.selectedDependsOnVmGroup
               };
            }
         }

         var key = null;
         if(self.spec.operation === clusterRulesConstants.operationType.EDIT) {
            key = self.spec.key;
         }

         self.ruleSpec = clusterRulesService.buildRuleSpec(
               self.spec.ruleType,
               self.spec.name,
               self.spec.enabled,
               _.map(self.spec.vmAffinityData, function(vm) {
                  return defaultUriSchemeUtil.getManagedObjectReference(vm.id);
               }),
               ruleProps,
               self.spec.operation,
               key);

         var validationPromise =
               mutationService.validate(
                     objectId,
                     'com.vmware.vim.binding.vim.cluster.RuleSpec',
                     self.ruleSpec
               ).then(function(validationResult) {
                  $scope.modalOptions.alerts = [];
                  $scope.modalOptions.isAlertClosed = true;

                  if (!!validationResult && (!validationResult.result || !!validationResult.error)) {
                     if (validationResult.result === 'NAME_ERROR') {
                        $scope.modalOptions.alerts.push({
                           text: getString('rules.error.nameNotUnique'),
                           type: vuiConstants.validationBanner.type.ERROR
                        });
                        $scope.modalOptions.isAlertClosed = false;
                        return $q.reject();
                     }

                     if (validationResult.result === 'RULE_CONFLICT_ERROR') {
                        if (self.spec.enabled) {
                           $scope.modalOptions.alerts.push({
                              text: getString('rules.error.ruleConflicts'),
                              type: vuiConstants.validationBanner.type.ERROR
                           });
                           $scope.modalOptions.isAlertClosed = false;
                           self.spec.enabled = false;
                           return $q.reject();
                        } else {
                           return $q.resolve();
                        }
                     }

                  } else {
                     return $q.resolve();
                  }
               });

         return validationPromise;
      }

      function onCommit() {
         return validate()
               .then(function() {
                  var clusterComputeResourceSpec =
                        clusterSpecBuilderService.buildClusterComputeResourceSpec(
                              {
                                 rule: self.ruleSpec
                              },
                              true
                        );

                  mutationService.apply(
                        objectId,
                        'com.vmware.vsphere.client.cluster.ClusterComputeResourceSpec',
                        clusterComputeResourceSpec
                  );
                  return true;
               })
               .catch(function(e) {
                  return false;
               });
      }
   }
})();
