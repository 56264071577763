/* Copyright 2015 VMware, Inc. All rights reserved. -- VMware Confidential */

(function() {
   /**
    * Provide information about the user agent (browser)
    */
   angular.module('com.vmware.vsphere.client.commonModule')
      .factory('browserService', browserService);

   browserService.$inject = ['$window', '$q'];

   function browserService($window, $q) {

      var _isWebkitDirectorySupported;
      var _arePopupsAllowed;

      var browserInfo = {
         name: getBrowserName(),
         version: getBrowserVersion(),
         isSafari: isSafari(),
         isEdge: isEdge(),
         isIe: isIe(),
         isFirefox: isFirefox,
         isWebkitDirectorySupported: isWebkitDirectorySupported,
         areBlockingPopupsAllowed: areBlockingPopupsAllowed
      };

      return browserInfo;

      function getBrowserName() {
         return $window.bowser.name;
      }

      function getBrowserVersion() {
         return $window.bowser.version;
      }

      function isSafari() {
         var is_safari = /^((?!chrome|android).)*safari/i.test($window.navigator.userAgent.toLowerCase());

         return is_safari;
      }

      function isChrome() {
         return $window.navigator.userAgent.toLowerCase().indexOf('chrome') >= 0 &&
               $window.navigator.vendor.toLowerCase().indexOf('google inc') >= 0;
      }

      function isEdge() {
         return $window.navigator.userAgent.toLowerCase().indexOf('edge') >= 0;
      }

      function isIe() {
         var userAgent = $window.navigator.userAgent.toLowerCase();
         var isIe10orBelow = userAgent.indexOf('msie') >= 0;
         var isIe11 = userAgent.indexOf('trident/7') >= 0;
         return isIe10orBelow || isIe11;
      }

      function isFirefox() {
         return $window.navigator.userAgent.toLowerCase().indexOf('firefox') >= 0;
      }

      function isWebkitDirectorySupported() {
         if (_isWebkitDirectorySupported !== undefined) {
            return _isWebkitDirectorySupported;
         }

         _isWebkitDirectorySupported = false;

         if (isEdge() || isChrome()) {
            _isWebkitDirectorySupported = true;
         }

         if (isFirefox()) {
            var version = parseInt(getBrowserVersion());
            if (version >= 50) {
               _isWebkitDirectorySupported = true;
            }
         }

         return _isWebkitDirectorySupported;
      }

      function areBlockingPopupsAllowed() {
         var deferred = $q.defer();
         if (_arePopupsAllowed) {
            deferred.resolve(_arePopupsAllowed);
         } else {
            var popup = $window.open();

            setTimeout(
                  function () {
                     _arePopupsAllowed = closePopup(popup);
                     deferred.resolve(_arePopupsAllowed);
                  },
                  100
            );
         }

         return deferred.promise;
      }

      function closePopup (popup) {
         if (!popup || popup.outerHeight === 0) {
            return false;
         } else {
            popup.close();
            return true;
         }
      }
   }
})();
