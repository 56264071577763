(function() {
   'use strict';
   angular.module('com.vmware.platform.ui')
      .controller('SelectConsoleDialogController', SelectConsoleDialogController);

   SelectConsoleDialogController.$inject = [
      '$scope',
      '$rootScope',
      'i18nService',
      'persistenceService',
      'actionsService'
   ];

   function SelectConsoleDialogController($scope, $rootScope, i18nService, persistenceService, actionsService) {
      var self = this;
      self.$scope = $scope;
      var PROPERTY_DEFAULT_CONSOLE = 'com.vmware.usersettings.defaultConsole';
      var ACTION_OPEN_CONSOLE = "vsphere.core.vm.actions.openConsole";
      var ACTION_LAUNCH_REMOTE_CONSOLE = "vsphere.core.vm.actions.launchRemoteConsole";
      self.currentlySelectedFormat = "WEB_CONSOLE";
      
      self.onClick = function() {
         if (self.shouldRemember) {
            var value = {'defaultConsole' : self.currentlySelectedFormat };
            persistenceService.setData(PROPERTY_DEFAULT_CONSOLE, value, {}, 'defaultConsole',  'CONTENT_TYPE_TEXT');
         }

         var action = (self.currentlySelectedFormat === "WEB_CONSOLE") ? ACTION_OPEN_CONSOLE : ACTION_LAUNCH_REMOTE_CONSOLE;
         actionsService.getAction(action, [$scope.modalOptions.dialogData.vmId])
            .then(function(action) {
               actionsService.invokeAction(action);
            });
         return true;
      };

      $scope.modalOptions.onSubmit = self.onClick;
   }
})();
