/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace network_ui {
   export class TeamingAndFailoverPageModel {

      public nicTeamingPolicy: string[];
      public loadBalancing: string;

      public checkBeacon: boolean;
      public notifySwitches: boolean;
      public rollingOrder: boolean;

      public pnics: any;
      public pnicDetails: any;

      public dirty: boolean = false;
      public initialState: TeamingAndFailoverPageModel;

      public setPnics(pnics: any) {
         if (pnics) {
            this.pnics = new Object();
            this.pnics.active = pnics.active;
            this.pnics.standby = pnics.standby;
            this.pnics.unused = pnics.unused;
         }
      }
   }
}
