/* Copyright 2015 VMware, Inc. All rights reserved. -- VMware Confidential */

/**
 * @ngdoc service
 * @name com.vmware.vsphere.client.storage:datastoreConstants
 * @module com.vmware.vsphere.client.storage
 *
 * @description
 *    The `datastoreConstants` service holds datastores related constants.
 *
 *    List of available `datastoreConstants`:
 *
 *    - nfsVersions
 *       - **`NFS_3`** : NFS 3 version datastore.
 *       - **`NFS_4_1`** : NFS 4.1 version datastore.
 *    - maximum length of datastore names
 */
angular.module('com.vmware.vsphere.client.storage').constant('datastoreConstants', {
   datastoreTypes: {
      VMFS: "VMFS",
      NFS: "NFS",
      NFS3: "NFS3",
      VVOL: "VVOL",
      VSAN: "VSAN"
   },

   nfsVersions: {
      NFS_3: 'nfs',
      NFS_4_1: 'nfs41'
   },

   datastoreSpecificType: {
      nfs3: "NFS",
      nfs41: "NFS41",
      vsan: "vsan",
      vvol: "VVol",
      vmfs3: "VMFS 3",
      vmfs5: "VMFS 5",
      vmfs6: "VMFS 6"
   },

   vmfsVersions: {
      VMFS_3: 3,
      VMFS_5: 5,
      VMFS_6: 6
   },

   accessModes: {
      readOnly: 'readOnly',
      readWrite: 'readWrite'
   },

   maxNameLengthForNfsDatastore: 42,
   maxNameLengthForNonNfsDatastore: 80,

   BYTES_TO_GB: 1024 * 1024 * 1024,
   MB_TO_GB: 1024,
   vmfsSize: {
      DATASTORE_SIZE_STEP_IN_BYTES: 10737418.24,
      MIN_DATASTORE_SIZE_GB: 1.3,
      MIN_DATASTORE_V6_SIZE_GB: 2,
   },

   reclamationPriority: {
      NONE: 'none',
      LOW: 'low'
   },

   protocolEndpoint: {
      type: {
         block: "block",
         nas: "nas"
      }
   },

   scsiDiskType: {
      EMULATED_512: "emulated512",
      NATIVE_4K: "native4k",
      SOFTWARE_EMULATED_4K: "SoftwareEmulated4k",
      NATIVE_512: "native512",
      UNKNOWN: "unknown"
   },

   kerberosSecurityTypes: {
      AUTH_SYS: "AUTH_SYS",
      SEC_KRB5: "SEC_KRB5",
      SEC_KRB5I: "SEC_KRB5I"
   }
});
