namespace dvs_ui {

   export class DvpgSecurityPolicyPageComponent {

      public bindings: any;
      public controller: any;
      public templateUrl: string;

      constructor() {
         this.bindings = {
            model: "<",
            overrideOptions: "<?",
            overrideEnabled: "<?",
            overrideDisallowed: "<?"
         };

         this.controller = DvpgSecurityPolicyPageController;

         this.templateUrl = "dvs-ui/resources/dvs/dvpg/common/components/" +
               "dvpgSecurityPolicyPageTemplate.html";
      }
   }

   class DvpgSecurityPolicyPageController {

      static $inject = [
            "i18nService",
            "$scope"
      ];

      public model: DvpgSecurityPolicyModel;

      public signPostConfig: any;
      public securityPolicyOptions: any[];
      public overrideOptions: any;
      public overrideEnabled: boolean;
      public overrideDisallowed: boolean;
      public i18n: Function;

      constructor(private i18nService: any,
                  private $scope: any) {

         this.i18n = this.i18nService.getString;

         this.securityPolicyOptions = [{
            label: this.i18n("NetworkUi", "dvpg.policy.security.accept"),
            val: true
         }, {
            label: this.i18n("NetworkUi", "dvpg.policy.security.reject"),
            val: false
         }];
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .component("dvpgSecurityPolicyPage", new DvpgSecurityPolicyPageComponent());
}
