/* Copyright 2015 VMware, Inc. All rights reserved. -- VMware Confidential */
angular.
module('com.vmware.vsphere.client.vapp').
controller('VAppStatusController', [
   '$scope',
   '$controller',
   'i18nService',
   'entityStatusService',
   function ($scope, $controller, i18nService, entityStatusService) {
      angular.extend(this, $controller('GenericPortletController', {$scope: $scope}));


      $scope.$watch('data', function (data) {
         if (_.isEmpty(data)) {
            return;
         }

         var statusProperties = entityStatusService.statusProperties(data.overallStatus);
         $scope.data.statusLabel = statusProperties.label;
         $scope.data.statusClass = statusProperties.iconClass;

         humanizeState($scope);
      });

      function humanizeState(scope) {
         var state = scope.data.powerState;
         var suspended = $scope.data.isSuspended;

         var products = $scope.data.products;
         var appUrl;
         var key;

         if (state === 'started') {
            appUrl = _.isEmpty(products) ? null : products[0].appUrl;
            if (appUrl) {
               key = "VAppStatusView.available";
            } else {
               key = "VAppStatusView.running";
            }
         } else if (state === 'stopped') {
            if (suspended) {
               key = "VAppStatusView.suspended";
            } else {
               key = "VAppStatusView.stopped";
            }
         } else if (state === 'stopping') {
            if (suspended) {
               key = "VAppStatusView.suspending";
            } else {
               key = "VAppStatusView.stopping";
            }
         } else if (state === 'starting') {
            if (suspended) {
               key = "VAppStatusView.resuming";
            } else {
               key = "VAppStatusView.starting";
            }
         } else {
            $scope.data.state = null;
            return;
         }

         $scope.data.state = i18nService.getString('VAppUi', key);
         $scope.data.appUrl = appUrl;
      }
   }]);
