/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace h5_vm {
   import VmEvcData = com.vmware.vsphere.client.h5.vm.model.VmEvcData;
   import VmEvcData$VmEvcVendor = com.vmware.vsphere.client.h5.vm.model.VmEvcData$VmEvcVendor;
   import VmEvcService = h5_vm.VmEvcService;
   import CpuIdInfo = com.vmware.vim.binding.vim.host.CpuIdInfo;

   class CpuidFeatureFlag {
      register: string;
      value: string;
      isLeaf: boolean;
   }

   export class VmEvcController {
      static $inject = ["i18nService", "vmEvcService"];
      private EDIT_EVC_ACTION_UID = "vsphere.core.vm.editEvcModeAction";
      private readonly CONNECTION_STATE_PROP_NAME = "runtime.powerState";
      private readonly EVC_MODE_KEY_PROP_NAME = "runtime.minRequiredEVCModeKey";

      i18n: Function;
      vmId: string;
      loading: boolean;

      watchForObjects: Array<string>;
      evcDataProperties: Array<string>;
      configureEvcAvailabilityProperties: Array<string>;
      evcVendors: any;
      currentEvcMode: VmEvcData;
      headerOptions: any;
      isFakeEvcVendor: boolean = true;
      cpuidFeaturesList: CpuidFeatureFlag[];
      settingsViewHeaderAccessor: any = {};

      constructor(private i18nService: any,
            private vmEvcService: VmEvcService) {
         this.i18n = i18nService.getString;
      }

      $onInit(): void {
         this.watchForObjects = [this.vmId];
         this.evcDataProperties = [this.EVC_MODE_KEY_PROP_NAME];
         this.configureEvcAvailabilityProperties = [this.CONNECTION_STATE_PROP_NAME];
         this.headerOptions = {
            title: this.i18n("VmUi", "vmEvc.view.title"),
            objectId: this.vmId,
            actions: [{
               actionUid: this.EDIT_EVC_ACTION_UID,
               customLabel: this.i18n("VmUi", "vmEvc.edit.actionCustomLabel")
            }]
         };
         this.evcVendors = {
            NONE: "NONE" as VmEvcData$VmEvcVendor,
            AMD: "AMD" as VmEvcData$VmEvcVendor,
            INTEL: "INTEL" as VmEvcData$VmEvcVendor,
            CUSTOM: "CUSTOM" as VmEvcData$VmEvcVendor
         };
         this.loadEvcData();
      }

      public refreshEvcData(): void {
         this.loadEvcData();
      }

      public refreshConfigureEvcAvailability(): void{
         if (typeof this.settingsViewHeaderAccessor.refresh === 'function') {
            this.settingsViewHeaderAccessor.refresh();
         }
      }

      private loadEvcData(): void {
         this.setPageBusy(true);
         this.vmEvcService.loadEvcData(this.vmId)
               .then((evcData: VmEvcData) => {
                  this.currentEvcMode = evcData;
                  if (!this.currentEvcMode) {
                     return;
                  }
                  this.isFakeEvcVendor =
                        this.currentEvcMode.vendor === this.evcVendors.NONE
                        ||
                        this.currentEvcMode.vendor === this.evcVendors.CUSTOM;
                  this.headerOptions.title = this.getTitle();
                  this.cpuidFeaturesList = this.generateCpuidFeaturesListData();
               })
               .then(() => {
                  this.setPageBusy(false);
               });
      }

      private getTitle(): string {
         if (!this.currentEvcMode.vendor) {
            return this.i18n("VmUi", "vmEvc.view.title");
         }

         if (this.currentEvcMode.vendor === this.evcVendors.NONE) {
            return this.i18n("VmUi", "vmEvc.view.evcDisabledLabel");
         }

         if (this.currentEvcMode.vendor === this.evcVendors.CUSTOM) {
            return this.i18n("VmUi", "vmEvc.view.evcCustomLabel");
         }
         return this.i18n("VmUi", "vmEvc.view.evcEnabledLabel");
      }

      private setPageBusy(isBusy: boolean): void {
         this.loading = isBusy;
      }

      private generateCpuidFeaturesListData(): CpuidFeatureFlag[] {
         let cpuidFeaturesList: CpuidFeatureFlag[] = [];
         if (!this.currentEvcMode.guaranteedCPUFeatures) {
            return cpuidFeaturesList;
         }

         this.currentEvcMode.guaranteedCPUFeatures.forEach((cpuFeature: CpuIdInfo) => {
            // The leaf information.
            let cpuFeatureLevel = cpuFeature.level;

            // Represent 32-bit number as unsigned int
            // http://stackoverflow.com/questions/57803/how-to-convert-decimal-to-hex-in-javascript
            if (cpuFeatureLevel < 0) {
               cpuFeatureLevel = 0xFFFFFFFF + cpuFeatureLevel + 1;
            }
            let leafCpuidListItem = {
               register: this.i18n("VmUi",
                     "vmEvc.view.cpuidFeaturesFlagLeafLabel",
                     cpuFeatureLevel.toString(16)),
               value: "",
               isLeaf: true
            };
            cpuidFeaturesList.push(leafCpuidListItem);

            // The information for eax register.
            let eaxCpuidListItem = {
               register: "eax",
               value: cpuFeature.eax,
               isLeaf: false
            };
            cpuidFeaturesList.push(eaxCpuidListItem);

            // The information for ebx register.
            let ebxCpuidListItem = {
               register: "ebx",
               value: cpuFeature.ebx,
               isLeaf: false
            };
            cpuidFeaturesList.push(ebxCpuidListItem);

            // The information for ecx register.
            let ecxCpuidListItem = {
               register: "ecx",
               value: cpuFeature.ecx,
               isLeaf: false
            };
            cpuidFeaturesList.push(ecxCpuidListItem);

            // The information for edx register.
            let edxCpuidListItem = {
               register: "edx",
               value: cpuFeature.edx,
               isLeaf: false
            };
            cpuidFeaturesList.push(edxCpuidListItem);
         });

         return cpuidFeaturesList;
      }
   }

   export class VmEvcComponent {
      public controller: any;
      public templateUrl: string;
      public bindings: any;

      constructor() {
         this.controller = VmEvcController;
         this.templateUrl =
               "vm-ui/resources/vm/evc-mode/vm-evc.component.html";
         this.bindings = {
            vmId: "<"
         };
      }
   }

   angular.module("com.vmware.vsphere.client.vm")
         .component("vmEvc", new VmEvcComponent());
}