module hostprofile_ui {

   class HostProfileController implements angular.IController {
      static $inject = ["$scope", "$controller", "i18nService",
         "timeFormatterService", "mutationService",
         "HostProfileComplianceService", "portletActionBarService",
         "defaultUriSchemeUtil", "dataService",  "vcService"];

      private readonly HP_PORTLET_MODEL_65 =
            "com.vmware.vsphere.client.hostprofile.data.h5.model.HostProfilePortletData";
      private readonly HP_PORTLET_MODEL_66 =
            "com.vmware.vsphere.client.hostprofile.data.h5.model.H5HostProfilePortletData";

      public statuses: any;
      public getFormattedDateTime: any;
      public data: any;
      private hostId: any;
      private liveRefreshProperties: Array<string>;
      private loadingData: boolean;
      private hostProfileId: string;

      constructor(private $scope: any,
                  private $controller: any,
                  private i18nService: any,
                  private timeFormatterService: any,
                  private mutationService: any,
                  private hostProfileComplianceService: HostProfileComplianceService,
                  private portletActionBarService: any,
                  private defaultUriSchemeUtil: any,
                  private dataService: any,
                  private vcService: any) {

         this.hostId = $scope._route.objectId;
         this.liveRefreshProperties = ["configStatus", "complianceStatus", "entity",
            "complianceCheckState", "remediationState"];
         this.statuses = hostProfileComplianceService.getStatuses();
         this.getFormattedDateTime = hostProfileComplianceService.getFormattedDateTime;
         this.$scope.portlet.footerLinks = this.getFormattedLinks();
         this.getPortletData();
         this.updateActionBar();
      }

      public updateActionBar() {
         let actionsSpecs = [{
            actionId: "vsphere.core.hostProfile.actions.checkHostPortletCompliance",
            getActionInvocationContext: () => {
               let hostProfileId: any = null;
               if (this.$scope.data && this.$scope.data.associatedHostProfileData &&
                     this.$scope.data.associatedHostProfileData.provider) {
                  hostProfileId = this.$scope.data.associatedHostProfileData.provider;
               }
               return {
                  hostProfileId: hostProfileId
               };
            }
         }];
         this.portletActionBarService.updateActionBar(this.$scope.portlet, [this.hostId], actionsSpecs, null);
      }

      public getPortletData(): void {
         if (this.loadingData) {
            return;
         }

         this.loadingData = true;

         this.vcService.is66VcOrLater(this.hostId)
               .then((is66VcOrLater: boolean) => {
                  let model: string = is66VcOrLater ?
                        this.HP_PORTLET_MODEL_66 :
                        this.HP_PORTLET_MODEL_65;

                  this.dataService.getData(this.hostId, model).then((data: any) => {
                     this.data = data;
                     if(data && data.associatedHostProfileData && data.associatedHostProfileData.provider) {
                        this.hostProfileId =
                              this.defaultUriSchemeUtil.getVsphereObjectId(data.associatedHostProfileData.provider);
                     }
                     this.loadingData = false;
                  });

               });
      }

      private getFormattedLinks(): any {
         return [{
            label: this.i18nService.getString("HostProfileUi", "hostSummary.details"),
            ariaLabel: this.i18nService.getString("HostProfileUi", "hostSummary.detailsAriaLabel"),
            onClick: () => {
               this.$scope._navigate("vsphere.core.host.manage.settings.hostProfileView", this.$scope._route.objectId);
            },
            enabled: true
         }];
      }

   }

   angular.module("com.vmware.vsphere.client.hostprofile")
         .controller("HostProfileController", HostProfileController);
}
