namespace h5_vm {

   class VmHardwareSerialPortFileBrowserController implements ng.IComponentController {
      static $inject: string[] = [
         "$scope",
         "i18nService",
         "defaultUriSchemeUtil",
         "datastoreBrowserConstants"
      ];

      i18n: Function;
      dsFileBrowserData: any;
      formData: {
         selectedTreeItem: any,
         fileName: string;
      } = { selectedTreeItem: null, fileName: "" };

      constructor(
            private $scope: any,
            private i18nService: any,
            private defaultUriSchemeUtil: any,
            private datastoreBrowserConstants: any) {

         this.i18n = (key: string, bundle: string = "VmUi") => i18nService.getString(bundle, key);
         this.dsFileBrowserData = this.getDatastoreFileBrowserData();

         this.setFilePathForSubmitCallback();
         $scope.$watchCollection(() => this.formData, this.validateForm.bind(this));
      }

      hasValidFileNameAndFolderLocation(): boolean {
         const hasFileName: boolean = this.formData.fileName.length > 0;
         const hasSelectedFolder: boolean = this.formData.selectedTreeItem;

         return hasFileName && hasSelectedFolder;
      }

      getFilePath(): string {
         return this.formData.selectedTreeItem.path + "/" + this.formData.fileName;
      }

      private validateForm(): void {
         this.$scope.modalOptions.submitDisabled = !this.hasValidFileNameAndFolderLocation();
      }

      private setFilePathForSubmitCallback(): void {
         const onSubmitCallback: Function = this.$scope.modalOptions.onSubmit;

         this.$scope.modalOptions.onSubmit = () => {
            const filePath: string = this.getFilePath();
            onSubmitCallback(filePath);
            this.$scope.destroyModal();
         };
      }

      private getDatastoreFileBrowserData(): any {
         const vmId: string = this.$scope.modalOptions.dialogData.vmId;
         const vmConfigContext: any = this.$scope.modalOptions.dialogData.vmConfigContext;
         const vmDatastores: Array<any> = vmConfigContext.environment.configTarget.datastore;
         const datastoreIds: Array<string> = vmDatastores.map((vmDatastore: any) =>
               this.defaultUriSchemeUtil.getVsphereObjectId(vmDatastore.datastore.datastore));
         const initialFileQueryType: number = this.datastoreBrowserConstants.fileQueryType.ALL_FILES;

         return {
            vmId: vmId,
            datastoreIds: datastoreIds,
            initialFileQueryType: initialFileQueryType,
            showVmImagesFolder: true,
            disablePreselect: true
         };
      }
   }

   angular.module("com.vmware.vsphere.client.vm")
         .controller("vmHardwareSerialPortFileBrowserController", VmHardwareSerialPortFileBrowserController);
}