namespace h5_client {
    export module common_module_ui {

        class ContentLibraryNamePageService {
            static $inject = ['i18nService'];

            constructor(private i18nService: any) {

            }

            public build() {
                return {
                    title: this.i18nService.getString('LibraryUi', 'createLibraryWizard.libraryNamePage.title'),
                    description: this.i18nService.getString('LibraryUi', 'createLibraryWizard.libraryNamePage.description'),
                    contentUrl: 'common-module-ui/resources/common-module/views/content-library/wizard/pages/createContentLibraryName.html'
                };
            }
        }
        angular.module("com.vmware.vsphere.client.commonModule")
            .service("contentLibraryNamePageService", ContentLibraryNamePageService);
    }
}