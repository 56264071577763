module hostprofile_ui {

    export interface RemediateHostPageService {
        build(wizardScope: any, manager: RemediateHostManager): any;
        precheckRemediation(hostId: string, hostCustomizations: any): any;
        getTreeListOptions(data: any): any;
    }

    class RemediateHostPageServiceImpl implements RemediateHostPageService {
        static $inject = [
            "i18nService",
            "vuiRemediateHostWizard",
            "defaultUriSchemeUtil",
            "mutationService",
            "vuiConstants",
            "columnRenderersRegistry",
            "dataService"
        ];

        private iconTextRenderer: any;

        constructor(private i18nService: any,
                    private vuiRemediateHostWizard: any,
                    private defaultUriSchemeUtil: any,
                    private mutationService: any,
                    private vuiConstants: any,
                    private columnRenderersRegistry: any,
                    private dataService: any) {

            this.iconTextRenderer = this.columnRenderersRegistry.getColumnRenderer("icon-text");
        }

        public build(wizardScope: any, manager: RemediateHostManager): any {
            return {
                title: this.getString("remediateHostWizard.reviewRemediateTasksList.title"),
                description: this.getString("remediateHostWizard.reviewRemediateTasksList.description"),
                contentUrl: "hostprofile-ui/resources/hostprofile/remediate/remediateHostPage.html",
                onCommit: () => {
                    let remediateSpec: any = manager.getRemediationSpec();
                    if (remediateSpec) {
                        remediateSpec.rebootHosts = manager.getRebootHosts();
                        this.mutationService.applyOnMultiEntity(
                            [manager.getTargetUid()],
                            "com.vmware.vsphere.client.hostprofile.data.RemediationSpec",
                            remediateSpec
                        );
                    } else {
                        this.dataService
                            .getProperties(manager.getTargetUid(), ["remediatePreCheckedData"])
                            .then((response: any) => {
                                let remediateSpec: any = response.remediatePreCheckedData.remediationSpec;
                                remediateSpec.rebootHosts = manager.getRebootHosts();
                                this.mutationService.applyOnMultiEntity(
                                    [manager.getTargetUid()],
                                    "com.vmware.vsphere.client.hostprofile.data.RemediationSpec",
                                    remediateSpec
                                );
                            });
                    }
                    return true;
                },
                finishReady: () => {
                    return !wizardScope.wizardConfig.loading;
                }
            };
        }

        public precheckRemediation(hostId: string, hostCustomizations: any): angular.IPromise<any> {
            return this.mutationService.validate(
                [hostId],
                "com.vmware.vsphere.client.hostprofile.data.PreRemediationSpec",
                {
                    hosts: [this.defaultUriSchemeUtil.getManagedObjectReference(hostId)],
                    hostCustomizations: hostCustomizations
                });
        };

        public getTreeListOptions(data: any): any {
            return {
                dataSource: this.createRemediateTreeDataSource(data),
                sortable: true,
                resizable: false,
                columns: this.getColumnDefs(),
                height: "252px",
                selectable: "row"
            };
        }

        private getColumnDefs(): any {
            return [
                {
                    title: this.getString("remediateHostWizard.readyToCompletePage.entityNameColumn"),
                    template: (dataItem: any) => {
                        return this.iconTextRenderer(["hostIcon", "hostName"], dataItem);
                    }
                },
                {
                    title: this.getString("remediateHostWizard.readyToCompletePage.stateColumn"),
                    template: (dataItem: any) => {
                        return this.iconTextRenderer(["descriptionIcon", "description"], dataItem);
                    }
                }
            ];
        }

        private createRemediateTreeDataSource(data: any) {
            let dataSource = new kendo.data.TreeListDataSource({
                data: data,
                schema: {
                    model: {
                        id: "id",
                        expanded: false
                    }
                }
            });
            dataSource.read();
            return dataSource;
        }

        private getString(key: string): String {
            return this.i18nService.getString("HostProfileUi", key);
        }
    }
    angular.module("com.vmware.vsphere.client.hostprofile")
        .service("remediateHostPageService", RemediateHostPageServiceImpl);
}
