/* Copyright 2018 VMware, Inc. All rights reserved. -- VMware Confidential */

/*
 * Controller for Legacy solutions portlet for vc extensions
 */
namespace h5_vcextension {
   export class VcExtensionSummarySolutionsViewController {
      public static $inject: string[] = ["$scope"];

      vcexstensionId: string;

      constructor(
            private $scope: any
      ) {
         this.vcexstensionId = this.$scope._route.objectId;
      }
   }
   angular.module("com.vmware.vsphere.client.vcextension")
         .controller("vcExtensionSummarySolutionsViewController", VcExtensionSummarySolutionsViewController);
}
