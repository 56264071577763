namespace h5_vm {

export class DiskModeService {
   static $inject: string[] = ['i18nService'];
   public static PERSISTENT: string = 'persistent';
   public static NONPERSISTENT: string = 'nonpersistent';
   public static UNDOABLE: string = 'undoable';
   public static INDEPENDANT_PERSISTENT: string = 'independent_persistent';
   public static INDEPENDENT_NONPERSISTENT: string = 'independent_nonpersistent';
   public static APPEND: string = 'append';

   constructor(private i18nService: any) {
   }

   public getModeName(diskMode: string): string {
      switch (diskMode) {
         case DiskModeService.PERSISTENT:
         case DiskModeService.NONPERSISTENT:
            return this.i18nService
                  .getString('VmUi', 'ProvisioningWizardSummaryPage.DiskModeDependent');
         case DiskModeService.UNDOABLE:
            return this.i18nService
                  .getString('VmUi', 'ProvisioningWizardSummaryPage.DiskModeUndoable');
         case DiskModeService.INDEPENDANT_PERSISTENT:
            return this.i18nService
                  .getString('VmUi', 'ProvisioningWizardSummaryPage.DiskModeIndependentPersistent');
         case DiskModeService.INDEPENDENT_NONPERSISTENT:
            return this.i18nService
                  .getString('VmUi', 'ProvisioningWizardSummaryPage.DiskModeIndependentNonpersistent');
         case DiskModeService.APPEND:
            return this.i18nService
                  .getString('VmUi', 'ProvisioningWizardSummaryPage.DiskModeAppend');
      }
      return '';
   }

}

angular.module('com.vmware.vsphere.client.vm')
      .service('diskModeService', DiskModeService);
}
