/* Copyright 2015 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace h5_vm {
   export class VmProvisioningCloneOptionsComponent implements angular.IComponentOptions {
      public controller: any;
      public templateUrl: string;
      public bindings: { [binding: string]: string };

      constructor() {
         this.controller = VmProvisioningCloneOptionsController;
         this.templateUrl = "vm-ui/resources/vm/views/createVmWizard/vm-provisioning-clone-options-page.component.html";
         this.bindings = {
            pageModel: "<",
            creationType: "<",
            wizardConfig: "<"
         };
      }
   }

   export class VmProvisioningCloneOptionsController {
      public static $inject: string[] = [
         "wizardPageService",
         "i18nService",
         "configurationService"
      ];

      private pageModel: VmProvisioningCloneOptionsPageModel;
      private creationType: String;
      private wizardConfig: any;
      private pages: WizardPage[];

      public i18n: any;

      constructor(private wizardPageService: any, private i18nService: any,
            private configurationService: any) {
         this.i18n = i18nService.getString;
      }

      $onInit(): void {
         this.pageModel.resetForm();
         this.pages = this.wizardConfig.pages;
         let flows = this.wizardConfig.flows;

         if (flows && flows.length > 0) {
            let flow = _.find(flows, (flow: any) => {
               return this.creationType === flow.id;
            });
            if (flow) {
               this.pages = flow.pages;
            }
         }

         this.changeGosPageState();
         this.changeCustomizeHwPageState();
      }

      changeGosPageState(): void {
         if (!this.pageModel.form.customizeOs) {
            this.wizardPageService.markPageSkipped(
                  this.pages,
                  this.i18nService.getString('VmUi', 'CustomizeGuestOsProvisioningPage.Title')
            );
            this.wizardPageService.markPageSkipped(
                  this.pages,
                  this.i18nService.getString('VmUi', 'UserSettingsPage.Title')
            );
         } else {
            this.wizardPageService.markPageDisabled(
                  this.pages,
                  this.i18nService.getString('VmUi', 'CustomizeGuestOsProvisioningPage.Title')
            );
         }
      }

      changeCustomizeHwPageState(): void {
         if (!this.pageModel.form.customizeHw) {
            this.wizardPageService.markPageSkipped(
                  this.pages,
                  this.i18nService.getString('VmUi', 'CustomizeHardwareProvisioningPage.Title')
            );
         } else {
            this.wizardPageService.markPageDisabled(
                  this.pages,
                  this.i18nService.getString('VmUi', 'CustomizeHardwareProvisioningPage.Title')
            );
         }
      };
   }

   angular.module("com.vmware.vsphere.client.vm")
         .component("vmProvisioningCloneOptions", new VmProvisioningCloneOptionsComponent());
}