/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
/**
 * Directive for enabled/disabled dropdown controlled by checkbox.
 * Dropdown's validation is supported.
 *
 * @param options(required) -
 *    key - value pair for population the dropdown
 * @param selectedValue(required) -
 *    the value that the dropdown manipulates
 * @param isOverridden(required) -
 *    flag that indicates whether the value is overridden by the user or not
 * @param validator(optional) -
 *    a validator object containing validation message and visibility flag
 *    to be passed to the vx-error directive
 * @param selectName(optional) -
 *    the name of the select box. Needed in case of validation, because the directive's
 *    consumer has the control to define whether the selected option is valid or not
 *    based on the surrounding context's configuration.
 */
(function () {
   'use strict';

   angular
         .module('com.vmware.vsphere.client.networkLibUi')
         .directive('overridableDropdown', overridableDropdown);

   overridableDropdown.$inject = ['i18nService'];

   function overridableDropdown(i18nService) {
      var directive = {
         restrict: 'E',
         templateUrl: 'network-lib-ui/resources/network-lib/components/' +
               'overridableDropdown/overridableDropdown.html',
         scope: {
            options: '=',
            selectedValue: '=',
            isOverridden: '=',
            validator: '=?',
            isAutofocused: '=?',
            labelledby: '@?',
            selectName: '@?'
         },
         link: {
            pre: function($scope) {
               $scope.selectName = $scope.selectName || "selectName";
               $scope.validator = $scope.validator || {message: null, visible: false};
               $scope.i18nService = i18nService;
            },
            post: function($scope, element, attrs) {
               if ($scope.isAutofocused) {
                  element[0].querySelector("input").focus();
               }
            }
         }
      };
      return directive;
   }
})();
