namespace h5_client {
    export module common_module_ui {
        import IScope = angular.IScope;


        interface CreateContentLibraryConfigureControllerScope extends IScope {
            wizardConfig: any;
            vuiWizard: any;
            wizardState: any;
            wizardManager: any;
        }

        class CreateContentLibraryConfigureController {
            private readonly CERTIFICATE_ERROR_STATUS = "CERTIFICATE_ERROR";

            public static $inject = ['$scope', 'i18nService', 'libraryService', 'vuiConstants', 'clarityModalService'];
            public i18n: any;
            private subscriptionUrl: string = this.$scope.wizardState.subscriptionUrl;
            private sslThumbprint: string = "";
            constructor(private $scope: CreateContentLibraryConfigureControllerScope, private i18nService: any,
                private libraryService: any, private vuiConstants: any, private clarityModalService: any) {
                this.i18n = i18nService.getString;
                this.$scope.vuiWizard.currentPage.state = this.vuiConstants.wizard.pageState.INCOMPLETE;
                this.init();
                this.$scope.vuiWizard.currentPage.state = this.vuiConstants.wizard.pageState.INCOMPLETE;
            }

            private init(): void {
                let self = this;
                this.$scope.vuiWizard.currentPage.disableNext = () => {
                    return !self.subscriptionUrl.length;
                };

                this.$scope.vuiWizard.currentPage.onCommit = () => {
                    return this.getValidateSubscriptionUrlPromise.call(this);
                };
            }

            private getValidateSubscriptionUrlPromise() {
                let self = this;
                return this.libraryService.validateSubscription(self.subscriptionUrl, self.sslThumbprint).then(function (result: any) {
                    let errorMessage = '';
                    if (result.error) {
                        errorMessage = result.error.localizedMessage;
                    }

                    if (result.result) {
                        if(self.isSslThumbprintError(result.result)){
                            self.sslThumbprint = result.result.sslThumbprint;
                            self.openSslThumbprintAlert(self.sslThumbprint);
                            return false;
                        } else if (result.result.errorMessages.length) {
                            errorMessage = result.result.errorMessages[0].defaultMessage;
                        } else {
                            self.$scope.wizardState.type = {name: 'SUBSCRIBED'};
                            self.$scope.wizardState.subscriptionUrl = self.subscriptionUrl;
                            self.$scope.vuiWizard.currentPage.state = self.vuiConstants.wizard.pageState.COMPLETED;
                            return true;
                        }
                    }

                    if (errorMessage) {
                        self.$scope.wizardConfig.validationBanner.messages = [{
                            text: errorMessage,
                            type: self.vuiConstants.validationBanner.type.ERROR
                        }];
                        self.$scope.wizardConfig.validationBanner.showAllMessages = true;
                        return false;
                    } else {
                        return true;
                    }

                });
            }

            private isSslThumbprintError(resultObj: any): boolean {
                if(resultObj && resultObj.status && resultObj.status.enumValue){
                    return (resultObj.status.enumValue === this.CERTIFICATE_ERROR_STATUS);
                }
                return false;
            }

            private openSslThumbprintAlert(sslThumbprint: any): void {
                let self = this;
                this.clarityModalService.openConfirmationModal({
                    title: this.i18n('LibraryUi', 'sslDialog.securityAlertTitle', self.$scope.wizardState.name),
                    message: this.i18nService.getString('LibraryUi', 'sslDialog.securityAlertText', sslThumbprint),
                    preserveNewlines: true,
                    saveButtonLabel: this.i18n('LibraryUi', 'yes'),
                    clarityIcon: {
                        shape: "warning",
                        class: "is-warning",
                        size: 64
                    },
                    submit: function() {
                        self.$scope.wizardState.sslThumbprint = self.sslThumbprint;
                        self.$scope.wizardManager.onNextClick();
                        return true;
                    },
                    onCancel: function () {
                        self.sslThumbprint = "";
                    }
                });
            }
        }

        angular.module('com.vmware.vsphere.client.commonModule')
            .controller('createContentLibraryConfigureController', CreateContentLibraryConfigureController);
    }
}
