module host_ui {
   export class AddHostsActionWizardService {
      static $inject = ['$injector'];

      private _addHostsActionWizardService: AddHostsActionWizardService;

      constructor(private $injector: any) {}

      get addHostsActionWizardService() {
         if (!this._addHostsActionWizardService) {
            this._addHostsActionWizardService = this.$injector.get("hciAddHostsActionWizardService");
         }
         return this._addHostsActionWizardService;
      }

      openWizard(clusterId: string): void {
         return this.addHostsActionWizardService.openWizard(clusterId);
      }
   }

   angular.module("com.vmware.vsphere.client.host").service("addHostsActionWizardService", AddHostsActionWizardService);
}

