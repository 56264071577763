namespace storage_ui {
   export interface FilterFileQueryType {
      label: string;
      value: string;
   }

   export class SelectVmToRegisterService {
      static $inject = ['vuiDialogService', '$rootScope', 'i18nService',
         'datastoreBrowserConstants', 'vuiConstants'];

      constructor(private vuiDialogService: any, private $rootScope: angular.IScope,
            private i18nService: any, private datastoreBrowserConstants: any,
            private vuiConstants: any) {

      }

      browseFile(datastoreId: string, onFileSelectedCallback: (vmFileItem: any) => void): void {
         let initialFileQueryType: number =
               this.datastoreBrowserConstants.fileQueryType.FOLDERS |
               this.datastoreBrowserConstants.fileQueryType.VM_CONFIG_FILES;
         let filterFileQueryTypes: FilterFileQueryType[] = [{
            label: this.i18nService.getString('Common', 'dsBrowser.vmConfigFile'),
            value: this.datastoreBrowserConstants.fileQueryType.VM_CONFIG_FILES
         }];

         let selectedVmConfigFile: any;
         let dialogScope: any = this.$rootScope.$new();
         dialogScope.dsFileBrowserData = {
            datastoreId: datastoreId,
            initialFileQueryType: initialFileQueryType,
            filterFileQueryTypes: filterFileQueryTypes,
            showVmImagesFolder: false,
            selectedFileChanged: function (selectedFile:any) {
               selectedVmConfigFile = selectedFile;
               dialogScope.dialogOptions.confirmOptions.disabled = !selectedVmConfigFile;
            }
         };

         dialogScope.dialogOptions = {
            title: this.i18nService.getString('Common', 'dsBrowser.defaultTitle'),
            contentUrl: 'storage-ui/resources/storage/register-vm/select-vm-to-register.view.html',
            show: true,
            width: '1000px',
            selectedObject: {},
            confirmOptions: {
               disabled: true,
               onClick: function () {
                  if (onFileSelectedCallback) {
                     onFileSelectedCallback(selectedVmConfigFile);
                  }
                  return true;
               }
            }
         };

         let config = {
            scope: dialogScope,
            configObjectName: 'dialogOptions'
         };

         this.vuiDialogService(config);
      }
   }

   angular.module("com.vmware.vsphere.client.storage")
         .service("selectVmToRegisterService", SelectVmToRegisterService);
}
