/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace dvs_ui {

   import DvpgTrafficFilterModel = com.vmware.vsphere.client.h5.network.dvportgroup.trafficfilter.DvpgTrafficFilterModel;
   import DvpgTrafficFilterRule = com.vmware.vsphere.client.h5.network.dvportgroup.trafficfilter.DvpgTrafficFilterRule;
   export class DvpgTrafficFilterViewComponent {

      public bindings: any;
      public controller: any;
      public templateUrl: string;

      constructor() {
         this.bindings = {
            dvpgId: "<"
         };

         this.controller = DvpgTrafficFilterViewContoller;

         this.templateUrl = "dvs-ui/resources/dvs/dvpg/trafficfilter/" +
               "dvpgTrafficFilterViewTemplate.html";
      }
   }

   export class DvpgTrafficFilterViewContoller {

      static $inject = [
            "dataService",
            "i18nService",
            "dvpgTrafficFilterService",
            "dvpgTrafficFilterConstants"
      ];

      public dvpgId: string;

      public hasPendingRequest: boolean;
      public headerOptions: any;
      public settingsViewHeaderAccessor: any;

      public liveRefreshProperties: string[] = [
            "config.defaultPortConfig.filterPolicy",
            "config.defaultPortConfig.filterPolicy.filterConfig"
      ];

      public trafficFilterRules: DvpgTrafficFilterRule[];

      constructor(private dataService: any,
                  private i18nService: any,
                  private dvpgTrafficFilterService: any,
                  private dvpgTrafficFilterConstants: any) {

         this.settingsViewHeaderAccessor = {};
         this.headerOptions = {
            title: this.i18nService.getString(
                  "DvsUi", "dvpg.trafficFilter.title.disabled"),
            objectId: this.dvpgId,
            actions: [
               {
                  actionUid: "vsphere.core.dvs.trafficFilters.editRulesAction",
                  customLabel: this.i18nService.getString(
                        "DvsUi", "actionEditTrafficFiltersLabel")
               },{
                  actionUid: "vsphere.core.dvs.trafficFilters.cloneRulesAction",
                  customLabel: this.i18nService.getString(
                        "DvsUi", "actionCloneTrafficFiltersLabel")
               }
            ]
         };
      }

      public $onInit() {
         this.getData();
      }

      public getData(): void {
         this.hasPendingRequest = true;
         this.dataService.getProperties(this.dvpgId,
               this.dvpgTrafficFilterConstants.properties.TRAFFIC_FILTER_PROPERTY)
               .then((response: any): any => response[
                     this.dvpgTrafficFilterConstants.properties.TRAFFIC_FILTER_PROPERTY])
               .then((model: DvpgTrafficFilterModel): void => {
                  this.hasPendingRequest = false;

                  if (model) {
                     if (this.dvpgTrafficFilterService.areRulesChanged(
                              this.trafficFilterRules, model.rules)) {
                        this.trafficFilterRules = model.rules;
                     }

                     this.headerOptions.title = model.isTrafficRulesetEnabled ?
                           this.i18nService.getString(
                                 "DvsUi", "dvpg.trafficFilter.title.enabled"):
                           this.i18nService.getString(
                                 "DvsUi", "dvpg.trafficFilter.title.disabled");
                  } else {
                     this.trafficFilterRules =[];
                  }

                  // Call the settings-view-header's accessor #refresh function to recalculate
                  // the actions availability
                  if (this.settingsViewHeaderAccessor.refresh) {
                     this.settingsViewHeaderAccessor.refresh();
                  }
               });
      }

      public onAddRule(): void {
         this.dvpgTrafficFilterService.showAddRuleDialog(this.dvpgId);
      }

      public onEditRule(rule: DvpgTrafficFilterRule): void {
         this.dvpgTrafficFilterService.showEditRuleDialog(this.dvpgId, rule.key);
      }

      public onRemoveRule(rule: DvpgTrafficFilterRule): void {
         this.dvpgTrafficFilterService.removeRule(
               this.dvpgId, rule.key, rule.name);
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .component("dvpgTrafficFilterView", new DvpgTrafficFilterViewComponent());
}

