/* Copyright 2015 VMware, Inc. All rights reserved. -- VMware Confidential */

/**
 * The select Guest OS page controller.
 */
(function () {
   'use strict';

   angular
      .module('com.vmware.vsphere.client.vm')
      .controller('VmProvisioningGuestOsController', VmProvisioningGuestOsController);

   VmProvisioningGuestOsController.$inject = ['$scope', 'i18nService', 'templateHelperService'];

   function VmProvisioningGuestOsController($scope, i18nService, templateHelperService) {
      var self = this;

      $scope.config.loadingMessage =  i18nService.getString('CommonUi', 'wizard.loading');
      $scope.config.loading = true;
      $scope.guestOsPageModel.resetForm().then(function () {
         $scope.config.loading = false;
      });
      self.form = $scope.guestOsPageModel.form;

      $scope.$on('$destroy', function() {
         $scope.config.loading = false;
      });

      var enableVBSHelpTemplate = 'vm-ui/resources/vm/views/createVmWizard/enableVBSHelpText.html';
      templateHelperService.renderTemplateWithScope(enableVBSHelpTemplate, $scope).then(function(content) {
         $scope.enableVBSSignPostParams = {
            message: content,
            title: i18nService.getString('Common', 'help'),
            class: 'cpu-mmu-help-text'
         };
      });
   }
})();
