namespace h5_vm {

   export class LunSelectorDialogService {
      static $inject: string[] = [
            'i18nService',
            'clarityModalService',
            'bytesFilter'];

      private modalOptions: any;
      private okHandler: any;
      private currentSelection: any = null;

      constructor(
            private i18nService: any,
            private clarityModalService: any,
            private bytesFilter: any) {
      }



      /**
       * Opens an OK/Cancel modal dialog with the lunSelectiongrid
       * @params
       * vmConfigEnvironment:
       *    the full vmConfigEnvironment of the VM.
       * okHandler:
       *    a function handler to handle when selection is made and OK is pressed
       *    selection result will be passed back through it.
       */
      public openLunSelectorDialog = (existingRdmDisks: any, vmConfigEnvironment: any, okHandler: any) => {
         let dialogData = {
            lunData: this.processVmConfig(existingRdmDisks, vmConfigEnvironment)
         };

         this.okHandler = okHandler;
         this.modalOptions = {
            title: this.i18nService.getString("VmUi", "FormSelectLUN.SelectLUN"),
            contentTemplate: 'vm-ui/resources/vm/views/controls/LUNSelector/lunSelectorDialog.html',
            defaultButton: 'submit',
            dialogData: dialogData,
            size: 'lg',
            modalClass: '',
            hideSubmitButton: false,
            submitDisabled: true,
            selectedItems: [],
            alerts: [],
            onSubmit: this.onOkClicked
         };

         angular.extend(this.modalOptions, {selectionChanged: this.selectionChanged});
         this.clarityModalService.openOkCancelModal(this.modalOptions);
      }

      public selectionChanged = (newSelection: any[]) => {
         this.currentSelection = newSelection;
         if (this.currentSelection.length === 0) {
            this.modalOptions.submitDisabled = true;
         } else {
            this.modalOptions.submitDisabled = false;
         }
      }

      public onOkClicked = () => {
         if (this.currentSelection.length !== 1) {
            return false;
         }
         if (this.okHandler && typeof this.okHandler === 'function') {
            this.okHandler(this.currentSelection);
         }
         return true;
      }

      /**
       * @private method
       * @param configEnv
       * @returns {Array}
       */
      private processVmConfig = (existingRdmDisks: any[], configEnv: any) => {
         let result: any = [];
         if (!configEnv || _.isEmpty(configEnv) ||
            _.isEmpty(configEnv.configTarget) ||
            _.isEmpty(configEnv.configTarget.scsiDisk)) {
            return result;
         }

         _.each(configEnv.configTarget.scsiDisk, (scsiDisk: any) => {
            if (!_.isEmpty(scsiDisk.disk) &&
               !this.isDiskInUse(existingRdmDisks, scsiDisk.disk)) {

               let disk: any = scsiDisk.disk;
               let diskInfo: any = {
                  deviceName: disk.deviceName,
                  lunName: disk.displayName,
                  capacity: scsiDisk.capacity,
                  displayCapacity: this.bytesFilter(scsiDisk.capacity, 'B', 'Auto', 2),
                  lunNum: scsiDisk.lunNumber,
                  driveType: scsiDisk.ssd ? this.i18nService.getString("VmUi", "FormSelectLUN.DriveType.SSD") :
                     this.i18nService.getString("VmUi", "FormSelectLUN.DriveType.NonSSD"),
                  hardwareAcceleration: this.i18nService.getString("VmUi", "RawDisk." + disk.vStorageSupport),
                  lunID: disk.canonicalName,
                  pathId: disk.canonicalName
               };
               result.push(diskInfo);
            }
         });
         return result;
      };

      private isDiskInUse = (existingRdmDisks: any[], disk: any) => {
         let diskIsInUse = false;
         for(let i=0; i< existingRdmDisks.length; i++){
            diskIsInUse = existingRdmDisks[i].backing.deviceName === disk.deviceName;
            if(diskIsInUse){
               break;
            }
         }
         return diskIsInUse;
      }

   }

   angular
      .module('com.vmware.vsphere.client.vm')
      .service('lunSelectorDialogService', LunSelectorDialogService);
}
