module vm_ui {
   export class ContentLibraryIsoSelectorModalService {
      static $inject: string[] = ['clarityModalService',
         'i18nService',
         'defaultUriSchemeUtil',
         'datastoreBrowserConstants',
         'contentLibraryService'];

      private modalOptions: any;
      private okHandler: Function;
      private cancelHandler: Function;
      constructor(private clarityModalService: any,
                  private i18nService: any,
                  private defaultUriSchemeUtil: any,
                  private datastoreBrowserConstants: any,
                  private contentLibraryService: any) {
      }

      public openContentLibraryIsoSelector(computeResourceId: any, preSelectedIso: any, okHandler: Function, cancelHandler: Function) {

         this.okHandler = okHandler;
         this.cancelHandler= cancelHandler;
         let dialogData = {
            i18nService: this.i18nService,
            computeResourceId: computeResourceId,
            selectedIsoItem: preSelectedIso,
            onSelectedIsoItemChanged: (item: any) => {
               this.modalOptions.dialogData.selectedIsoItem = item;
               this.modalOptions.submitDisabled = false;
              this.modalOptions.dialogData.errorMessages = [];
            },
            errorMessages:[],
            validating: false
         };

         this.modalOptions = {
            title: this.i18nService.getString("VmUi", "contentLibISO.infoHeader"),
            contentTemplate: 'vm-ui/resources/vm/content-library-iso-selector-modal/content-library-iso-selector-modal.html',
            defaultButton: 'submit',
            size: 'lg',
            dialogData: dialogData,
            hideSubmitButton: false,
            submitDisabled: !dialogData.selectedIsoItem || !dialogData.selectedIsoItem.name,
            onSubmit: () => {
               if (dialogData.selectedIsoItem === null || this.modalOptions.dialogData.validating) {
                  return false;
               }
               this.modalOptions.dialogData.validating = true;

               return this.contentLibraryService.getContentLibraryItemWithIso(dialogData.selectedIsoItem)
                  .then((libraryItemWithIso: any) => {
                     this.modalOptions.dialogData.validating = false;
                     if (libraryItemWithIso && libraryItemWithIso.contentLibraryItemByItemId) {
                        if (Array.isArray(libraryItemWithIso.contentLibraryItemByItemId.errorMessages)
                           && libraryItemWithIso.contentLibraryItemByItemId.errorMessages.length > 0) {
                           dialogData.errorMessages = libraryItemWithIso.contentLibraryItemByItemId.errorMessages;
                           this.modalOptions.submitDisabled = true;
                           return false;
                        }
                        else {
                           dialogData.selectedIsoItem.friendlyName = libraryItemWithIso.contentLibraryItemByItemId.friendlyName;
                           dialogData.selectedIsoItem.isoPath = libraryItemWithIso.contentLibraryItemByItemId.isoPath;
                           dialogData.errorMessages = [];
                           if (angular.isFunction(this.okHandler)) {
                              this.okHandler(dialogData.selectedIsoItem);
                           }
                           return true;
                        }
                     }
                     else {
                        dialogData.errorMessages = <Array<never>>[this.i18nService.getString("VmUi", "CdromPage.clTimeOutPopupMessage")];
                        this.modalOptions.submitDisabled = true;
                        this.modalOptions.dialogData.validating = false;
                        return false;
                     }

                  }, () => {
                     this.modalOptions.dialogData.validating = false;
                     return false;
                  });
            },
            onCancel: () => {
               if (angular.isFunction(this.cancelHandler)) {
                  this.cancelHandler();
               }
            }
         };
         this.clarityModalService.openOkCancelModal(this.modalOptions);
         return this.modalOptions;
      }
   }
   angular.module("com.vmware.vsphere.client.vm")
      .service("contentLibraryIsoSelectorModalService", ContentLibraryIsoSelectorModalService);
}
