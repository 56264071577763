namespace license_ui {

   import IController = angular.IController;

   class ProductsAdministrationViewController implements IController {

      public static $inject = ["i18nService", "$q", "$scope"];

      public detailsViewData: any;
      public masterDetailsViewContext: any;

      constructor(private i18nService: any,
                  private $q: ng.IQService,
                  private $scope: any) {
      }

      public $onInit() {
         this.masterDetailsViewContext = {
            scopeProvider: this.$scope.scopeProvider,
            tabPaths: this.$scope.tabPaths
         };

         this.detailsViewData = {
            tabsPromise: this.$q.when([
               {
                  name: this.i18nService.getString("LicenseLibUi", "management.products.details.summary"),
                  templateUrl: "license-ui/resources/license/views/management/licenses/products/productsDetailsView.html",
                  uid: "product.generalTab"
               },
               {
                  name: this.i18nService.getString("LicenseLibUi", "management.license.summary.productFeatures.label"),
                  templateUrl: "license-ui/resources/license/views/management/licenses/products/productFeaturesView.html",
                  uid: "product.features"
               },
               {
                  name: this.i18nService.getString("LicenseLibUi", "products.tab.licenses.title"),
                  templateUrl: "license-ui/resources/license/views/management/licenses/products/productLicensesView.html",
                  uid: "product.licenses"
               },
               {
                  name: this.i18nService.getString("LicenseLibUi", "management.navigation.vcs"),
                  templateUrl: "license-ui/resources/license/views/management/licenses/assets/vcAssetsList.html",
                  uid: "product.vcAssets",
                  metadata: {
                     relevantFor: "isAssignableToVcs"
                  }
               },
               {
                  name: this.i18nService.getString("LicenseLibUi", "management.navigation.hosts"),
                  templateUrl: "license-ui/resources/license/views/management/licenses/assets/hostAssetsList.html",
                  uid: "product.hostAssets",
                  metadata: {
                     relevantFor: "isAssignableToHosts"
                  }
               },
               {
                  name: this.i18nService.getString("LicenseLibUi", "management.navigation.clusters"),
                  templateUrl: "license-ui/resources/license/views/management/licenses/assets/clusterAssetsList.html",
                  uid: "product.clusterAssets",
                  metadata: {
                     relevantFor: "isAssignableToClusters"
                  }
               },
               {
                  name: this.i18nService.getString("LicenseLibUi", "management.navigation.solutions"),
                  templateUrl: "license-ui/resources/license/views/management/licenses/assets/solutionAssetsList.html",
                  uid: "product.solutionAssets",
                  metadata: {
                     relevantFor: "isAssignableToSolutions"
                  }
               }
            ])
         };
      }
   }

   angular.module("com.vmware.vsphere.client.licenseUi")
         .controller("ProductsAdministrationViewController", ProductsAdministrationViewController);
}
