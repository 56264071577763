namespace storage_ui {

   import IComponentController = angular.IComponentController;
   import IScope = angular.IScope;
   import LayoutAreaChartService = storage_ui.LayoutAreaChartService;

   class PartitionLayoutChartController implements IComponentController {
      public static $inject = ["layoutAreaChartService", "layoutAreaSelectionService", "storageUtil",
         "i18nService", "$scope", "$element"];

      private chart: any = null;
      // bindings
      private chartApi: any;
      private size: number;
      private diskPartitionInfo: any;
      private selectedOption: any;
      private chartPartitions: any[];

      constructor(private chartService: any,
                  private selectionService: any,
                  private storageUtil: any,
                  private i18nService: any,
                  private $scope: IScope,
                  private element: JQuery) {
      }

      $onInit(): void {
         let ctrl = this;
         this.chartApi = this.chartApi || {};
         this.chartApi.reflow = _.throttle(() => {
            ctrl.chart.reflow();
            ctrl.onSizeChange();
         }, 300);// _.throttle here ensures that this function will be called not more than ONCE in 300ms

         this.drawChart(ctrl.diskPartitionInfo);
         this.$scope.$watchGroup([
            function () {
               return ctrl.size;
            },
            function () {
               return ctrl.selectedOption;
            }
         ], this.onSizeChange);
         this.$scope.$on("$destroy", () => {
            if (this.element.highcharts()) {
               this.element.highcharts().destroy();
            }
         });
      }

      /**
       * Drawing the selection rectangles on the chart
       **/
      private onSizeChange = (): void => {

         if (!this.chart || !this.selectedOption || !this.selectedOption.vmfsDatastoreOption) {
            this.selectionService.clearMask(this.element);
            return;
         }
         let option = this.selectedOption.vmfsDatastoreOption;
         let vmfsExtent: any = option.info.vmfsExtent;

         // Highlight the whole area completely
         // Single extent and single partition => formatting the disk.
         // (fixes the issue with really small blocks being not highlighted)
         if ((
               option.info._type === "com.vmware.vim.binding.vim.host.VmfsDatastoreOption$SingleExtentInfo"
               || option.info._type === "com.vmware.vim.binding.vim.host.VmfsDatastoreOption$AllExtentInfo" )
               && option.info.layout.partition.length === 1
               && option.spec._type !== "com.vmware.vim.binding.vim.host.VmfsDatastoreExpandSpec") {
            let fillAll = {
               start: {
                  block: 0,
                  blockSize: this.diskPartitionInfo.layout.total.blockSize
               },
               end: {
                  block: this.diskPartitionInfo.layout.total.block,
                  blockSize: this.diskPartitionInfo.layout.total.blockSize
               }
            };
            this.selectionService.highlightSelection(this.chart, this.element, fillAll, this.size * 1024); // GB to MB
         } else if (option.spec._type === "com.vmware.vim.binding.vim.host.VmfsDatastoreExpandSpec") {
            // When expanding we need to find the start address of the
            // block range which will be appended, not the whole partition
            // (1) Find the block range which is expanded.
            let originalPartition = LayoutAreaChartService.getExtentOriginalBlockRange(this.diskPartitionInfo, option);
            // (2) To find the start address of the next block we
            // need to add 1 to the end address of the current block range.
            let expandableExtent = {
               start: {
                  block: originalPartition.end.block + 1,
                  blockSize: vmfsExtent.start.blockSize
               },
               end: {
                  block: vmfsExtent.end.block,
                  blockSize: vmfsExtent.end.blockSize
               }
            };
            this.selectionService.highlightSelection(this.chart, this.element, expandableExtent, this.size * 1024); // GB to MB
         }
         else {
            this.selectionService.highlightSelection(this.chart, this.element, vmfsExtent, this.size * 1024);
         }
      };

      /**
       * Draws Disk Partition layout chart using Highcharts
       **/
      private drawChart = (partitionInfo: any): void => {
         let ctrl = this;
         if (partitionInfo) {
            this.element.highcharts(this.chartService.buildChart(partitionInfo), (c) => {
               // preserve Highcharts chart object
               ctrl.chart = c;
               // generate legend
               ctrl.chartPartitions = _.map(ctrl.chart.series, (item: any) => {
                  return !ctrl.storageUtil.isFreePartition(item.options.partitionType) ? {
                     name: item.name,
                     color: item.color
                  } : false;
               }).filter((item) => {
                  return !!item;
               });
            });
         }
      }

   }

   angular.module("com.vmware.vsphere.client.storage")
         .component("partitionLayoutChart", {
            controller: PartitionLayoutChartController,
            bindings: {
               selectedOption: "<",
               size: "<", // Used to draw the selection mask
               diskPartitionInfo: "<",
               chartPartitions: "=",
               chartApi: "="
            }
         });
}

