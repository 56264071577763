/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace dvs_ui {

   import IRootScopeService = angular.IRootScopeService;
   import UpgradeLacpDvsData = com.vmware.vsphere.client.h5.network.dvs.upgrade.model.UpgradeLacpDvsData;

   export class DvsUpgradeLacpWizardService {

      public static $inject = [
            "$rootScope",
            "i18nService",
            "vuiWizardService",
            "mutationService",
            "dataService",
            "dvsUpgradeLacpWizardSpecFactory",
            "dvsUpgradeLacpWizardConstants",
            "dvsUpgradeLacpWizardManager",
            "dvsUpgradeLacpWizardPages",
            "wizardValidationService"
      ];

      constructor(private $rootScope: IRootScopeService,
                  private i18nService: any,
                  private vuiWizardService: any,
                  private mutationService: any,
                  private dataService: any,
                  private specFactory: DvsUpgradeLacpWizardSpecFactory,
                  private wizardConstants: any,
                  private wizardManager: DvsUpgradeLacpWizardManager,
                  private wizardPages: DvsUpgradeLacpWizardPages,
                  private wizardValidationService: any) {
      }

      /**
       * Shows the Enhance LACP Support wizard.
       */
      public show(objectId: string): void {

         let wizardScope: any = this.$rootScope.$new();

         wizardScope.model = new DvsUpgradeLacpWizardModel();
         wizardScope.model.dvsId = objectId;

         wizardScope.wizardConfig = {
            title: this.i18nService.getString(
                    "DvsUi", "DvsUpgradeLacpWizard.initialTitle"),
            loadingMessage: this.i18nService.getString("CommonUi", "wizard.loading"),
            cssClass: "dvs-upgrade-lacp-wizard",
            onFinish: ():boolean => {
               let spec: any =
                     this.specFactory.buildDvsLacpUpgradeSpec(wizardScope.model);

               this.mutationService.apply(
                     wizardScope.model.dvsId,
                     spec._type,
                     spec);
               return true;
            }
         };

         wizardScope.wizardConfig.pages = this.wizardPages.createPages(wizardScope);

         this.wizardValidationService.initWizardValidation(wizardScope);
         this.wizardManager.init(wizardScope);

         this.requestProperties(wizardScope);
      }

      private requestProperties(wizardScope: any): void {
         wizardScope.wizardConfig.loading = true;

         this.dataService.getProperties(
               wizardScope.model.dvsId, [this.wizardConstants.properties
                    .DVS_UPGRADE_LACP_WIZARD_DATA])
               .then((response: any): void => {
                  let wizardData: UpgradeLacpDvsData =
                      response[this.wizardConstants.properties.
                            DVS_UPGRADE_LACP_WIZARD_DATA];
                  wizardScope.model.dvsName = wizardData.dvsName;
                  wizardScope.model.portgroupCount = wizardData.portgroupCount !== null
                        ? wizardData.portgroupCount : 0;
                  wizardScope.model.hostCount = wizardData.hostCount !== null
                        ? wizardData.hostCount : 0;
                  wizardScope.model.prerequisiteChecks =
                        wizardData.prerequisiteCheckResults;
                  wizardScope.model.lag = wizardData.lagConfig;
                  wizardScope.wizardConfig.title = this.i18nService.getString(
                        "DvsUi", "DvsUpgradeLacpWizard.titleFormat",
                        wizardScope.model.dvsName);

                  if (wizardScope.model.lag === null) {
                     this.wizardPages.skipPage(wizardScope.wizardConfig.pages,
                           this.wizardConstants.pages.DVS_LACP_LAG_PAGE);
                  }

                  wizardScope.wizardConfig.loading = false;

                  this.vuiWizardService({
                     scope: wizardScope,
                     configObjectName: "wizardConfig"
                  }).show();
               });
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .service("dvsUpgradeLacpWizardService", DvsUpgradeLacpWizardService);
}
