namespace h5_vm {
    class FileOnChange implements ng.IDirective {
        scope = {
            handler: '&fileOnChange'
        };
        restrict = 'A';
        controllerAs = 'fileOnChangeCtrl';
        controller = FileOnChangeController;

        static Metastructure: any = {
            name: "fileOnChange", // referenced name
            factory: [() => new FileOnChange()]
        };
    }

    class FileOnChangeController {
        static $inject: Array<string> = [
            '$scope',
            '$element'
        ];

        constructor(private $scope: any,
                    private $element: ng.IAugmentedJQuery) {
        }

        $onInit() {
        }

        $postLink() {
            this.$element.bind('change', (event: any) => {
                this.$scope.$apply(() => {
                    if (event.target.files && event.target.files.length) {
                        const filesObject = { files: event.target.files };
                        this.$scope.handler(filesObject);
                    }
                });
            });
        }
    }

    angular
        .module('com.vmware.vsphere.client.vm')
        .directive(FileOnChange.Metastructure.name, FileOnChange.Metastructure.factory);
}
