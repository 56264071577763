/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace network_ui {

   export class NetStackEditDialogManagerFactory {
      public static $inject = [
         "mutationService"
      ];

      constructor(private mutationService: any) {
      }

      public createManager(dialogScope: any): NetStackEditDialogManager {
         return new NetStackEditDialogManager(dialogScope, this.mutationService);
      }
   }

   angular.module("com.vmware.vsphere.client.network")
         .service("netStackEditDialogManagerFactory", NetStackEditDialogManagerFactory);
}
