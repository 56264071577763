/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace dvs_ui {

   export class DvsHealthCheckManageDialogComponent {

      public templateUrl: string;
      public bindings: any;
      public controller: any;

      constructor() {
         this.bindings = {
            pageModel: "<"
         };

         this.templateUrl =
               "dvs-ui/resources/dvs/healthcheck/manage/editdialog/dvsHealthCheckManageDialogTemplate.html";
         this.controller = DvsHealthCheckManageDialogController;
      }
   }

   class DvsHealthCheckManageDialogController {

      static $inject = [
         "i18nService",
         "vxValidatorFactory",
         "dvsHealthCheckManageDialogValidator"];

      public pageModel: DvsHealthCheckManageDialogModel;
      public i18n: any;

      public options: any[];

      private validator: any;

      private validateVlanAndMtuInterval: Function;
      private validateTeamingAndFailoverInterval: Function;

      public constructor(private i18nService: any,
                         private vxValidatorFactory: any,
                         private dvsHealthCheckManageDialogValidator: DvsHealthCheckManageDialogValidator) {
         this.i18n = this.i18nService.getString;
         this.validator = vxValidatorFactory.create();
         this.validateVlanAndMtuInterval =
               this.vlanAndMtuIntervalValidator.bind(this);
         this.validateTeamingAndFailoverInterval =
               this.teamingAndFailoverIntervalValidator.bind(this);

         this.options = [{
            value: true,
            label: this.i18nService.getString("DvsUi",
                  "healthCheck.manage.enabled")
         }, {
            value: false,
            label: this.i18nService.getString("DvsUi",
                  "healthCheck.manage.disabled")
         }];
      }

      private vlanAndMtuIntervalValidator(): string[] {
         let message: string | null = this.dvsHealthCheckManageDialogValidator
               .getMonitoringIntervalValidationMessage(this.pageModel.vlanMtuInterval,
                     this.i18n('DvsUi', 'healthCheck.manage.view.vlanMtuProperty'));
         return message ? [message] : [];
      }

      private teamingAndFailoverIntervalValidator(): string[] {
         let message: string | null = this.dvsHealthCheckManageDialogValidator
               .getMonitoringIntervalValidationMessage(this.pageModel.teamingAndFailoverInterval,
                     this.i18n('DvsUi', 'healthCheck.manage.view.teamingProperty'));
         return message ? [message] : [];
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .component("dvsHealthCheckManageDialog",
               new DvsHealthCheckManageDialogComponent());
}