namespace h5_vm {

   import VmConfigContext = com.vmware.vsphere.client.vm.config.VmConfigContext;
   import SriovInfo = com.vmware.vim.binding.vim.vm.SriovInfo;
   import VirtualSriovEthernetCard = com.vmware.vim.binding.vim.vm.device.VirtualSriovEthernetCard;
   import ConfigInfo = com.vmware.vim.binding.vim.vm.ConfigInfo;
   import ConfigTarget = com.vmware.vim.binding.vim.vm.ConfigTarget;

   export class VmHwSriovDriver {

      private service: h5_vm.VmHwSriovService = new h5_vm.VmHwSriovService();
      private device: VirtualSriovEthernetCard;
      private configTarget: ConfigTarget;

      constructor(private vm: VmConfigContext, private virtualNetworkDevice: any, private workflow: h5_vm.VmWorkflowMode) {
         this.device = this.virtualNetworkDevice.getCurrentDevice();
         if (vm && vm.environment) {
            this.configTarget = this.vm.environment.configTarget;
            const network: any = this.virtualNetworkDevice.getNetwork();
            this._sriovList = this.service.getSriovList(vm, network);
            const sriovInfo = this.service.getSriovInfo(this.device, this._sriovList);
            if (sriovInfo) {
               this._sriovValue = sriovInfo;
            }
         }
      }

      private isSriovEthernetCardType(type: String): boolean {
         return type === "com.vmware.vim.binding.vim.vm.device.VirtualSriovEthernetCard";
      }

      public unavailable(): boolean {
         return !this.available();
      }

      public available(): boolean {
         if (!this.configTarget) {
            return false;
         }

         if (this.configTarget) {
            if (this.configTarget.sriov && this.configTarget.sriov.length > 0) {
               // TODO count PF items only;
               return true;
            }
         }

         return false;
      }

      public switchToSriovAdapterType(): void {
         const network: any = this.virtualNetworkDevice.getNetwork();
         this._sriovList = this.service.switchToSriovAdapterType(this.device, this.vm, network);
         this._sriovValue = this._sriovList[0];
      }

      private _sriovValue: SriovInfo;
      private _sriovList: SriovInfo[];

      private isSriovEthernetCard(): boolean {
         return this.isSriovEthernetCardType(this.device._type);
      }

      public get sriovValue(): SriovInfo {
         return this._sriovValue;
      }

      public set sriovValue(value: SriovInfo) {
         this.service.assignSriovBacking(this.device, value);
         this._sriovValue = value;
      }

      public sriovList(): SriovInfo[] {
         return this._sriovList;
      }

      private isNew(): boolean {
         if (this.virtualNetworkDevice) {
            return this.virtualNetworkDevice.isNew();
         }
         return false;
      }

      public getSriovLabelText(): string {
         if (this.isNew()) {
            return "";
         } else {
            return this.service.getSriovDeviceText(this.device as VirtualSriovEthernetCard, this.vm);
         }
      }

      public reservationIssue(): boolean {
         if (!this.vm) {
            return false;
         }

         const config: ConfigInfo = this.vm.config;
         const memoryMB: number = config.hardware.memoryMB;
         const reservation: number = config.memoryAllocation ? config.memoryAllocation.reservation : 0;
         if (memoryMB > reservation) {
            return true;
         }
         return false;
      }

      public get allowMtuChange(): boolean {
         if (!this.device) {
            return false;
         }
         return this.device.allowGuestOSMtuChange;
      }

      public set allowMtuChange(value: boolean) {
         if (!this.device) {
            return;
         }
         this.device.allowGuestOSMtuChange = value;
      }

      public isAllowMtuChangeEditable(): boolean {
         if (this.isNew()) {
            return true;
         }
         return this.service.isAllowMtuChangeEditable(this.vm, this.workflow);
      }
   }
}
