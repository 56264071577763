/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace dvs_ui {

   import VlanIdSpec = com.vmware.vim.binding.vim.dvs.VmwareDistributedVirtualSwitch$VlanIdSpec;
   import TrunkVlanSpec = com.vmware.vim.binding.vim.dvs.VmwareDistributedVirtualSwitch$TrunkVlanSpec;
   import PvlanSpec = com.vmware.vim.binding.vim.dvs.VmwareDistributedVirtualSwitch$PvlanSpec;
   import TrafficShapingPolicy = com.vmware.vim.binding.vim.dvs.DistributedVirtualPort$TrafficShapingPolicy;
   import UplinkPortTeamingPolicy = com.vmware.vim.binding.vim.dvs.VmwareDistributedVirtualSwitch$UplinkPortTeamingPolicy;
   import SecurityPolicy = com.vmware.vim.binding.vim.dvs.VmwareDistributedVirtualSwitch$SecurityPolicy;
   import FailureCriteria = com.vmware.vim.binding.vim.dvs.VmwareDistributedVirtualSwitch$FailureCriteria;
   import UplinkPortOrder = com.vmware.vim.binding.vim.dvs.VmwareDistributedVirtualSwitch$UplinkPortOrderPolicy;
   import BoolPolicy = com.vmware.vim.binding.vim.BoolPolicy;
   import StringPolicy = com.vmware.vim.binding.vim.StringPolicy;
   import LongPolicy = com.vmware.vim.binding.vim.LongPolicy;
   import VMwarePortgroupPolicy = com.vmware.vim.binding.vim.dvs.VmwareDistributedVirtualSwitch$VMwarePortgroupPolicy;
   import DvPortgroupPortProperties = com.vmware.vsphere.client.dvpg.api.DvPortgroupPortProperties;

   export class DvpgSpecFactory {

      public static $inject = ["networkUiConstants"];

      constructor(private networkUiConstants: any) {
      }

      public buildVlanSpec(vlanData: any): any {
         switch (vlanData.vlanType) {
            case this.networkUiConstants.Vlan.Type.NONE: {
               let vlanSpec: VlanIdSpec = new VlanIdSpec();
               vlanSpec.vlanId = this.networkUiConstants.Vlan.Id.NONE;
               return vlanSpec;
            }
            case this.networkUiConstants.Vlan.Type.VLAN: {
               let vlanSpec: VlanIdSpec = new VlanIdSpec();
               vlanSpec.vlanId = vlanData.vlanId;
               return vlanSpec;
            }
            case this.networkUiConstants.Vlan.Type.TRUNK: {
               let vlanSpec: TrunkVlanSpec = new TrunkVlanSpec();
               vlanSpec.vlanId = vlanData.vlanTrunkNumericRanges;
               return vlanSpec;
            }
            case this.networkUiConstants.Vlan.Type.PRIVATE: {
               let vlanSpec: PvlanSpec = new PvlanSpec();
               vlanSpec.pvlanId = vlanData.pvlanId;
               return vlanSpec;
            }
            default: {
               return null;
            }
         }
      }

      public buildSecurityPolicySpec(securityPolicy: any): SecurityPolicy {
         let securityPolicySpec: SecurityPolicy = new SecurityPolicy();
         securityPolicySpec.inherited = false;
         securityPolicySpec.allowPromiscuous =
               this.buildBoolPolicy(securityPolicy.allowPromiscuous);
         securityPolicySpec.forgedTransmits =
               this.buildBoolPolicy(securityPolicy.forgedTransmits);
         securityPolicySpec.macChanges =
               this.buildBoolPolicy(securityPolicy.macChanges);
         return securityPolicySpec;
      }

      public buildTrafficShapingSpec(trafficShaping: any): TrafficShapingPolicy {
         return this.buildTrafficShaping(trafficShaping, false);
      }

      public buildTrafficShapingSpecPort(trafficShaping: any,
                                         isInherited: boolean): TrafficShapingPolicy {
         return this.buildTrafficShaping(trafficShaping, isInherited);
      }

      private buildTrafficShaping(trafficShaping: any,
                                  isInherited: boolean): TrafficShapingPolicy {
         let trafficShapingEnabled: BoolPolicy = new BoolPolicy();
         trafficShapingEnabled.value = trafficShaping.enabled;
         trafficShapingEnabled.inherited = false;

         let trafficShapingPolicySpec: TrafficShapingPolicy = new TrafficShapingPolicy();
         trafficShapingPolicySpec.enabled = trafficShapingEnabled;
         trafficShapingPolicySpec.inherited = isInherited;

         if (trafficShapingPolicySpec.enabled.value && !isInherited) {
            trafficShapingPolicySpec.averageBandwidth = this.buildLongPolicy(
                  trafficShaping.averageBandwidth * this.networkUiConstants.UnitFormat.KBPS);
            trafficShapingPolicySpec.peakBandwidth = this.buildLongPolicy(
                  trafficShaping.peakBandwidth * this.networkUiConstants.UnitFormat.KBPS);
            trafficShapingPolicySpec.burstSize = this.buildLongPolicy(
                  trafficShaping.burstSize * this.networkUiConstants.UnitFormat.KB);
         }

         return trafficShapingPolicySpec;
      }

      public buildTeamingAndFailoverPortSpec(teamingAndFailoverPolicy: any,
                                             overrideOptions: any): UplinkPortTeamingPolicy {
         return this.buildTeamingAndFailover(teamingAndFailoverPolicy, overrideOptions);
      }

      public buildTeamingAndFailoverSpec(teamingAndFailoverPolicy: any): UplinkPortTeamingPolicy {
         return this.buildTeamingAndFailover(teamingAndFailoverPolicy, null);
      }

      public buildTeamingAndFailover(teamingAndFailoverPolicy: any,
                                         overrideOptions: any): UplinkPortTeamingPolicy {
         let uplinkPortTeamingPolicy: UplinkPortTeamingPolicy =
               new UplinkPortTeamingPolicy();
         uplinkPortTeamingPolicy.inherited = false;
         uplinkPortTeamingPolicy.policy = this.buildStringPolicy(
               teamingAndFailoverPolicy.loadBalancing);
         uplinkPortTeamingPolicy.policy.inherited =
               overrideOptions ? !overrideOptions.loadBalancing : false;

         let failureCriteria: FailureCriteria = new FailureCriteria();
         failureCriteria.inherited =
               overrideOptions ? !overrideOptions.beaconProbing : false;
         failureCriteria.checkBeacon = this.buildBoolPolicy(
               teamingAndFailoverPolicy.beaconProbing);
         failureCriteria.checkBeacon.inherited =
               overrideOptions ? !overrideOptions.beaconProbing : false;
         uplinkPortTeamingPolicy.failureCriteria = failureCriteria;

         uplinkPortTeamingPolicy.notifySwitches = this.buildBoolPolicy(
               teamingAndFailoverPolicy.notifySwitches);
         uplinkPortTeamingPolicy.notifySwitches.inherited =
               overrideOptions ? !overrideOptions.notifySwitches : false;

         uplinkPortTeamingPolicy.rollingOrder = this.buildBoolPolicy(
               teamingAndFailoverPolicy.rollingOrder);
         uplinkPortTeamingPolicy.rollingOrder.inherited =
               overrideOptions ? !overrideOptions.rollingOrder : false;

         uplinkPortTeamingPolicy.uplinkPortOrder = new UplinkPortOrder();
         uplinkPortTeamingPolicy.uplinkPortOrder.inherited =
               overrideOptions ? !overrideOptions.failoverOrder : false;
         uplinkPortTeamingPolicy.uplinkPortOrder.activeUplinkPort =
               teamingAndFailoverPolicy.failoverOrder.activeUplinkPortNames;
         uplinkPortTeamingPolicy.uplinkPortOrder.standbyUplinkPort =
               teamingAndFailoverPolicy.failoverOrder.standbyUplinkPortNames;
         return uplinkPortTeamingPolicy;
      }

      /**
       * Builds the resoirce pool allocation spec for reconfiguring a distributed portgroup.
       *
       * @param resourceAllocPolicy
       *    - the policyModel provided from the backend
       *
       * @returns
       *    - string for NIOCv3
       *    - StringPolicy for NIOCv2
       */
      public buildResourcePoolAllocationSpec(resourceAllocPolicy:DvpgResourceAllocationPolicyModel): string | StringPolicy {
         if (resourceAllocPolicy.isVmVnicResourcePoolSupported) {
            return resourceAllocPolicy.networkResourcePoolKey;
         } else {
            let policy: StringPolicy = new StringPolicy();
            policy.inherited = false;
            policy.value = resourceAllocPolicy.networkResourcePoolKey;
            return policy;
         }
      }

      /**
       * Builds the monitoring spec for reconfiguring a distributed portgroup.
       *
       * @param monitoringPolicyModel
       *    - the policyModel provided from the backend
       *
       * @returns
       *    - the correct BoolPolicy
       */
      public buildMonitoringSpec(monitoringPolicyModel: DvpgMonitoringPolicyModel): BoolPolicy {
         return this.buildBoolPolicy(monitoringPolicyModel.ipfixEnabled);
      }

      /**
       * Builds the misc spec for reconfiguring a distributed portgroup.
       *
       * @param miscPolicyModel
       *    - the policyModel provided from the backend
       *
       * @returns
       *    - the correct BoolPolicy
       */
      public buildMiscSpec(miscPolicyModel: DvpgMiscPolicyModel): BoolPolicy {
         return this.buildBoolPolicy(miscPolicyModel.arePortsBlocked);
      }

      /**
       * Builds the portgroup overridden port policies
       *
       * @param portPoliciesModel
       *    - the model used in the 'Advanced' page
       * @returns {PortgroupPolicy}
       *    - the correct VMwarePortgroupPolicy
       */
      public buildPortPoliciesSpec(portPoliciesModel: DvPortgroupPortProperties): VMwarePortgroupPolicy {
         let policiesSpec: VMwarePortgroupPolicy = new VMwarePortgroupPolicy();

         if (this.isPolicySupported(portPoliciesModel.blockOverrideAllowed)) {
            policiesSpec.blockOverrideAllowed = portPoliciesModel.blockOverrideAllowed;
         }

         if (this.isPolicySupported(portPoliciesModel.portConfigResetAtDisconnect)) {
            policiesSpec.portConfigResetAtDisconnect
                  = portPoliciesModel.portConfigResetAtDisconnect;
         }

         if (this.isPolicySupported(portPoliciesModel.shapingOverrideAllowed)) {
            policiesSpec.shapingOverrideAllowed = portPoliciesModel.shapingOverrideAllowed;
         }

         if (this.isPolicySupported(portPoliciesModel.vendorConfigOverrideAllowed)) {
            policiesSpec.vendorConfigOverrideAllowed
                  = portPoliciesModel.vendorConfigOverrideAllowed;
         }

         if (this.isPolicySupported(portPoliciesModel.vlanOverrideAllowed)) {
            policiesSpec.vlanOverrideAllowed
                  = portPoliciesModel.vlanOverrideAllowed;
         }

         if (this.isPolicySupported(portPoliciesModel.uplinkTeamingOverrideAllowed)) {
            policiesSpec.uplinkTeamingOverrideAllowed
                  = portPoliciesModel.uplinkTeamingOverrideAllowed;
         }

         if (this.isPolicySupported(portPoliciesModel.networkResourcePoolOverrideAllowed)) {
            policiesSpec.networkResourcePoolOverrideAllowed
                  = portPoliciesModel.networkResourcePoolOverrideAllowed;
         }

         if (this.isPolicySupported(portPoliciesModel.securityPolicyOverrideAllowed)) {
            policiesSpec.securityPolicyOverrideAllowed
                  = portPoliciesModel.securityPolicyOverrideAllowed;
         }

         if (this.isPolicySupported(portPoliciesModel.ipfixOverrideAllowed)) {
            policiesSpec.ipfixOverrideAllowed = portPoliciesModel.ipfixOverrideAllowed;
         }

         if (this.isPolicySupported(portPoliciesModel.trafficFilterOverrideAllowed)) {
            policiesSpec.trafficFilterOverrideAllowed
                  = portPoliciesModel.trafficFilterOverrideAllowed;
         }

         return policiesSpec;
      }

      private buildLongPolicy(value: number): LongPolicy {
         let policy: LongPolicy = new LongPolicy();
         policy.inherited = false;
         policy.value = value;
         return policy;
      }

      private buildStringPolicy(value: string): StringPolicy {
         let policy: StringPolicy = new StringPolicy();
         policy.inherited = false;
         policy.value = value;
         return policy;
      }

      private buildBoolPolicy(value: boolean): BoolPolicy {
         let policy: BoolPolicy = new BoolPolicy();
         policy.inherited = false;
         policy.value = value;
         return policy;
      }

      private isPolicySupported(value: any): boolean {
         return value !== null && typeof value !== "undefined";
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .service("dvpgSpecFactory", DvpgSpecFactory);
}
