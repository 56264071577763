/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace dvs_ui {
   import DvsPortVlanPolicyModel = com.vmware.vsphere.client.h5.network.dvs.ports.model.DvsPortVlanPolicyModel;

   export class DvsPortEditVlanPageModel {
      public overrideOptions: any;

      public vlanPolicy: DvsPortVlanPolicyModel;

      public isVlanPageChanged: Function;

      constructor(vlanPolicy: DvsPortVlanPolicyModel) {
         if (vlanPolicy) {
            this.vlanPolicy = vlanPolicy;
            this.overrideOptions = {
               overrideVlan: vlanPolicy.isVlanPolicyOverrided
            };

            this.isVlanPageChanged = this.isVlanPageChangedFunction.bind(this);
         }
      }

      public isVlanPageChangedFunction(initialData: DvsPortVlanPolicyModel): boolean {
         if (!initialData) {
            return false;
         }

         return initialData.isVlanPolicyOverrided !== this.overrideOptions.overrideVlan
               || !_.isEqual(initialData, this.vlanPolicy);
      }
   }
}