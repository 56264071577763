(function() {
   'use strict';

   angular
      .module('com.vmware.vsphere.client.vm')
      .service('vmProvisioningProductDetailsPageModel', vmProvisioningProductDetailsPageModel);

   vmProvisioningProductDetailsPageModel.$inject = [
      '$q'
   ];

   function vmProvisioningProductDetailsPageModel ($q) {
      return function(virtualMachineSpecBuilder) {
         var form = {
            isReady: false
         };
         var dataModel = {};
         return {
            form: form,
            dataModel: dataModel,
            validatePage: function() {
            },
            submitPage: function() {
               return {
                  invalidateNextPages: false,
                  wizardFlow: virtualMachineSpecBuilder.getCreationType()
               };
            },
            pageFinishedLoading: function() {
               return form.isReady;
            }
         };
      };
   }
})();
