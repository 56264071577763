/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace dvs_ui {

   import IPromise = angular.IPromise;
   import IDeferred = angular.IDeferred;

   import Dictionary = _.Dictionary;

   import DvsSpanSessionPortBrowserData =
         com.vmware.vsphere.client.h5.network.dvs.portmirroring.model.portbrowser.DvsSpanSessionPortBrowserData;
   import DvsSpanSessionPortBrowserPortData =
         com.vmware.vsphere.client.h5.network.dvs.portmirroring.model.portbrowser.DvsSpanSessionPortBrowserPortData;

   export class DvsSpanSessionPortBrowserService {

      public static $inject = [
            "$q",
            "i18nService",
            "vuiConstants",
            "dataService"
      ];

      public static DATA_PROPERTY: string = "dvswitchportmirroring:portBrowserData";

      public static NO_DATA: string = "--";

      public getString: (key: string) => string;

      constructor(private $q: any,
                  private i18nService: any,
                  private vuiConstants: any,
                  private dataService: any) {
         this.getString = (key: string): string => {
            return this.i18nService.getString("DvsUi", key);
         };
      }

      public createDatagridOptions(callback: Function): any {
         let gridOptions: any = {
            height: "300",
            pageConfig: {
               hidePager: true
            },
            columnDefs: this.getColumnDefinitions(),
            sortMode: this.vuiConstants.grid.sortMode.SINGLE,
            selectionMode: this.vuiConstants.grid.selectionMode.NONE,
            data: [],
            resizable: true,
            actionBarOptions: { actions: [] },
            selectedPorts: {}
         };

         let onSelectionChange: Function =
               this.getOnSelectionChangeCallback(callback, gridOptions);

         gridOptions.onSelectionChange = onSelectionChange;

         gridOptions.onMasterCheckboxClick = (event: any) => {
            let checked: boolean = event.currentTarget.checked;

            for (let port of gridOptions.data) {
               gridOptions.selectedPorts[port.portId] = checked;
            }

            onSelectionChange();
         };

         return gridOptions;
      }

      public getData(dvsUrn: string): IPromise<DvsSpanSessionPortBrowserPortData[]> {
         return this.dataService.getProperties(
               dvsUrn,
               [DvsSpanSessionPortBrowserService.DATA_PROPERTY])
               .then((response: any): DvsSpanSessionPortBrowserPortData[] => {
                  let rawData: DvsSpanSessionPortBrowserData =
                        response[DvsSpanSessionPortBrowserService.DATA_PROPERTY];
                  return rawData ? rawData.ports : [];
               })
               .catch((): DvsSpanSessionPortBrowserPortData[] => {
                  // return empty data
                  return [];
               });
      }

      private getColumnDefinitions(): any[] {
         let columns: any[] = [];

         columns.push({
                  width: "35px",
                  headerTemplate: "<input type='checkbox' " +
                        "ng-click='datagridOptions.onMasterCheckboxClick($event)'/>",
                  template: (item: DvsSpanSessionPortBrowserPortData) => {
                     let model: string = `'datagridOptions.selectedPorts["${item.portId}"]'`;

                     return "<input type='checkbox' %model %change/>"
                           .replace("%model", "ng-model=" + model)
                           .replace("%change", "ng-change='datagridOptions.onSelectionChange()'");
                  }
               });

         columns.push({
            displayName: this.getString(
                  "PortBrowser.columnHeader.portId"),
            field: "portId",
            width: "90px",
            template: this.getGenericColumnRenderer("portId")
         });

         columns.push({
            displayName: this.getString(
                  "PortBrowser.columnHeader.name"),
            field: "name",
            width: "150px",
            template: this.getGenericColumnRenderer("name")
         });

         columns.push({
            displayName: this.getString(
                  "PortBrowser.columnHeader.connectedEntityName"),
            field: "connectedEntity",
            width: "150px",
            template: this.getConnectedEntityColumnRenderer()
         });

         columns.push({
            displayName: this.getString(
                  "PortBrowser.columnHeader.proxyHostName"),
            field: "host",
            width: "150px",
            template: this.getHostColumnRenderer()
         });

         columns.push({
            displayName: this.getString(
                  "PortBrowser.columnHeader.macAddress"),
            field: "mac",
            width: "150px",
            template: this.getGenericColumnRenderer("mac")
         });

         columns.push({
            displayName: this.getString(
                  "PortBrowser.columnHeader.portgroupName"),
            field: "portgroup",
            width: "150px",
            template: this.getPortgroupColumnRenderer()
         });

         columns.push({
            displayName: this.getString(
                  "PortBrowser.columnHeader.portState"),
            field: "state",
            width: "150px",
            template: this.getPortStateColumnRenderer()
         });

         columns.push({
            displayName: this.getString(
                  "PortBrowser.columnHeader.vlanIds"),
            field: "vlan",
            width: "150px",
            template: this.getGenericColumnRenderer("vlan")
         });

         return columns;
      }

      private getOnSelectionChangeCallback(callback: Function, gridOptions: any): Function {
         return (): void => {
            let selectedPortIds: string[] = [];

            gridOptions.data.forEach((item: DvsSpanSessionPortBrowserPortData): void => {
               if (gridOptions.selectedPorts[item.portId]) {
                  selectedPortIds.push(item.portId);
               }
            });

            callback(selectedPortIds);
         };
      }

      private getGenericColumnRenderer(property: string): ((item: DvsSpanSessionPortBrowserPortData) => string) {
         return (data: DvsSpanSessionPortBrowserPortData): string => {
            let label: string = (<any> data)[property] || DvsSpanSessionPortBrowserService.NO_DATA;
            return this.buildCellHtml(label);
         };
      }

      private getConnectedEntityColumnRenderer(): ((item: DvsSpanSessionPortBrowserPortData) => string) {
         return (data: DvsSpanSessionPortBrowserPortData): string => {
            if (data.connectedEntityType === "vmVnic") {
               return this.buildCellHtml(data.connectedEntity, "vsphere-icon-vm");
            } else if (data.connectedEntityType === "hostVmkVnic") {
               return this.buildCellHtml(data.connectedEntity, "network-lib-ui-icon-virtualAdapter");
            } else {
               return this.buildCellHtml(data.connectedEntity);
            }
         };
      }

      private getPortStateColumnRenderer(): ((item: DvsSpanSessionPortBrowserPortData) => string) {
         return (data: DvsSpanSessionPortBrowserPortData): string => {
            let label: string;
            let icon: string;

            switch (data.state) {
               case "LINK_UP": {
                  label = this.getString("PortState.linkUp");
                  icon = "network-lib-ui-icon-dvsPortConnectedLf";
                  break;
               }
               case "LINK_DOWN": {
                  label = this.getString("PortState.linkDown");
                  icon = "network-lib-ui-icon-dvsPortNotconnectedLf";
                  break;
               }
               case "BLOCKED": {
                  label = this.getString("PortState.blocked");
                  icon = "network-lib-ui-icon-dvsPortBlockedLf";
                  break;
               }
               default: {
                  label = DvsSpanSessionPortBrowserService.NO_DATA;
                  icon = "";
               }
            }

            return this.buildCellHtml(label, icon);
         };
      }

      private getHostColumnRenderer(): ((item: DvsSpanSessionPortBrowserPortData) => string) {
         return (data: DvsSpanSessionPortBrowserPortData): string => {
            let icon: string = data.host ? "vsphere-icon-host" : "";
            return this.buildCellHtml(data.host, icon);
         };
      }

      private getPortgroupColumnRenderer(): ((item: DvsSpanSessionPortBrowserPortData) => string) {
         return (data: DvsSpanSessionPortBrowserPortData): string => {
            let icon: string = data.portgroup ? "vsphere-icon-virtual-port-group" : "";
            return this.buildCellHtml(
                  data.portgroup || DvsSpanSessionPortBrowserService.NO_DATA,
                  icon);
         };
      }

      private buildCellHtml(text: string, icon: string = ""): string {
         let template: string = "<div>%icon%text</div>";

         return template
               .replace("%icon", "<span class='" + icon + "'></span>")
               .replace("%text", "<span>" + text + "</span>");
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .service("dvsSpanSessionPortBrowserService",
               DvsSpanSessionPortBrowserService);
}
