/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace dvs_ui {

   export class DvsOverviewPageComponent {

      public bindings: any;
      public controller: any;
      public templateUrl: string;

      constructor() {
         this.bindings = {
            feature: "<",
            objectId: "<"
         };

         this.controller = DvsOverviewPageComponentController;

         this.templateUrl = "dvs-ui/resources/dvs/common/component/" +
               "dvsOverviewPageComponentTemplate.html";
      }
   }

   class DvsOverviewPageComponentController {

      static $inject = [
         "i18nService",
         "$scope",
         "dvsUpgradeWizardConstants",
         "$sce",
         "exportDvsDialogService"
      ];


      public feature: string;
      public objectId: string;

      private featureHeading: string;
      private featureContent: string;
      private warningHint: string;
      private exportLabel: string;
      private exportDvsLinkClicked: Function;

      constructor(private i18nService: any,
                  private $scope: any,
                  private dvsUpgradeWizardConstants: any,
                  private $sce: any,
                  private exportDvsDialogService: any) {
      }

      $onInit(): void {
         switch(this.feature) {
            case this.dvsUpgradeWizardConstants.features.LACP:
               this.featureHeading = this.i18nService.getString(
                     "DvsUi", "DvsUpgradeLacpWizard.overview.changesHeading");
               this.featureContent =this.$sce.trustAsHtml(this.i18nService.getString(
                     "DvsUi", "DvsUpgradeLacpWizard.overview.changesContent"));
               this.warningHint = this.i18nService.getString("DvsUi", "DvsUpgradeLacpWizard.backup.hint");
               this.exportLabel = this.i18nService.getString("DvsUi", "DvsUpgradeLacpWizard.backup.name");
               this.exportDvsLinkClicked = () => {
                  this.exportDvsDialogService.show(this.objectId);
               };
               break;
            case this.dvsUpgradeWizardConstants.features.NIOC:
               this.featureHeading = this.i18nService.getString(
                     "DvsUi", "DvsUpgradeNiocWizard.overview.changesHeading");
               this.featureContent = this.$sce.trustAsHtml(this.i18nService.getString(
                     "DvsUi", "DvsUpgradeNiocWizard.overview.changesContent"));
               break;
            default:
               break;
         }

      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .component("dvsOverviewPage", new DvsOverviewPageComponent());
}
