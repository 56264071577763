/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace network_ui {

   import EditVirtualSwitchDialogManager = network_ui.EditVirtualSwitchDialogManager;

   export class EditVirtualSwitchDialogManagerFactory {

      public static $inject = [
         "i18nService",
         "mutationService",
         "editVirtualSwitchDialogValidator",
         "portFailoverPolicyPageService",
         "editPortgroupService"];

      constructor(private i18nService: any,
                  private mutationService: any,
                  private editVirtualSwitchDialogValidator: EditVirtualSwitchDialogValidator,
                  private portFailoverPolicyPageService: any,
                  private editPortgroupService: any) {
      }

      public createManager(dialogScope:any):EditVirtualSwitchDialogManager {
         return new EditVirtualSwitchDialogManager(dialogScope,
            this.i18nService,
            this.mutationService,
            this.editVirtualSwitchDialogValidator,
            this.portFailoverPolicyPageService,
            this.editPortgroupService);
      }
   }

   angular.module("com.vmware.vsphere.client.network")
      .service("editVirtualSwitchDialogManagerFactory", EditVirtualSwitchDialogManagerFactory);
}
