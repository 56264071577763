(function() {
   'use strict';

   /**
    * Service that provides HA host isolation response related information.
    * Each isolation response info contains id, label and description.
    */
   angular.module('com.vmware.vsphere.client.cluster')
      .service('haHostIsolationResponseService', haHostIsolationResponseService);

   haHostIsolationResponseService.$inject = ['i18nService', 'haConstants'];

   function haHostIsolationResponseService(i18nService, haConstants) {
      return {
         getResponses: getResponses,
         getEnsureFtResponse: getEnsureFtResponse
      };

      function getResponses() {
         var hostIsolationResponses =
            Object.keys(haConstants.hostIsolationResponse).map(function(key) {
               var responseKey = haConstants.hostIsolationResponse[key];
               var isolationResponse = buildIsolationResponse(responseKey);
               return isolationResponse;
            });

         return hostIsolationResponses;
      }

      function getEnsureFtResponse() {
         return [{
            id: "EnsureFT",
            label: i18nService.getString("ClusterUi",
                  "vmOverrides.haHostIsolationResponse.EnsureFT")
         }];
      }

      function buildIsolationResponse(responseKey) {
         return {
            id: responseKey,
            label: getLabel(responseKey),
            description: getDescription(responseKey)
         };
      }

      function getLabel(responseKey) {
         var responseTextKey;
         switch (responseKey) {
            case haConstants.hostIsolationResponse.LEAVE_POWERED_ON:
               responseTextKey = 'ha.config.failures.responses.host.isolation.response.disabled';
               break;
            case haConstants.hostIsolationResponse.SHUT_DOWN_AND_FAILOVER:
               responseTextKey = 'ha.config.failures.responses.host.isolation.response.shutdown';
               break;
            case haConstants.hostIsolationResponse.POWER_OFF_AND_FAILOVER:
               responseTextKey = 'ha.config.failures.responses.host.isolation.response.poweroff';
               break;
            default:
               return '';
         }
         return i18nService.getString('ClusterUi', responseTextKey);
      }

      function getDescription(responseKey) {
         var responseDescKey;
         switch (responseKey) {
            case haConstants.hostIsolationResponse.LEAVE_POWERED_ON:
               responseDescKey = 'ha.config.failures.responses.host.isolation.response.disabled.desc';
               break;
            case haConstants.hostIsolationResponse.SHUT_DOWN_AND_FAILOVER:
               responseDescKey = 'ha.config.failures.responses.host.isolation.response.shutdown.desc';
               break;
            case haConstants.hostIsolationResponse.POWER_OFF_AND_FAILOVER:
               responseDescKey = 'ha.config.failures.responses.host.isolation.response.poweroff.desc';
               break;
            default:
               return '';
         }
         return i18nService.getString('ClusterUi', responseDescKey);
      }
   }
})();
