namespace storage_ui {

   export class StorageProvidersActionService {

      private readonly VASA_API_VERSION_1_0 = "1.0";
      private readonly VASA_API_VERSION_1_5 = "1.5";

      public static $inject = ["storageProvidersItemBuilderService", "storageProviderConstants"];

      constructor(private storageProvidersItemBuilderService: StorageProvidersItemBuilderService,
                  private storageProviderConstants: any) {
      }

      private isVasaProviderItem(item: StorageProviderListItem): boolean {
         return item && item._isStorageProviderItem && item._data &&
               this.storageProvidersItemBuilderService.isVasaProviderInfo(item._data.providerInfo);
      }

      public isRemoveProviderActionAvailable(item: StorageProviderListItem): boolean {
         if (!this.isVasaProviderItem(item)) {
            return false;
         }

         return item._data.providerInfo.category !== this.storageProviderConstants.vasaProviderInfoCategory.internal;
      }

      public isSyncProviderActionAvailable(item: StorageProviderListItem): boolean {
         if (!this.isVasaProviderItem(item)) {
            return false;
         }

         let providerInfo = item._data.providerInfo;
         let isArrayIndependentProvider = providerInfo.arrayIndependentProvider;

         if (isArrayIndependentProvider || providerInfo.vasaVersion === this.VASA_API_VERSION_1_0) {
            // Sync button should always be enabled for legacy or array independent providers
            return true;
         }

         // Iterate through the related storage array and allow sync if the
         // provider is active for at least one array
         let activeArray = _.find(providerInfo.relatedStorageArray, function (relatedArray: any) {
            return relatedArray && relatedArray.active;
         });

         return !!activeArray;
      }

      public isRefreshCertificateActionAvailable(item: StorageProviderListItem): boolean {
         if (!this.isVasaProviderItem(item)) {
            return false;
         }

         let providerInfo = item._data.providerInfo;

         // Refresh certificate action should not be available for providers with version 1.0
         // or 1.5 (VSAN providers).
         if (providerInfo.vasaVersion === this.VASA_API_VERSION_1_0 ||
               providerInfo.vasaVersion === this.VASA_API_VERSION_1_5) {
            return false;
         }

         // Refresh action should be disabled if the retainVasaProviderCertificate flag is true.
         if (providerInfo.retainVasaProviderCertificate === true) {
            return false;
         }

         // Refresh action should be disabled if the vasaProviderInfoCategory is internal.
         if (providerInfo.category === this.storageProviderConstants.vasaProviderInfoCategory.internal) {
            return false;
         }

         return true;
      }
   }

   angular.module("com.vmware.vsphere.client.storage")
         .service("storageProvidersActionService", StorageProvidersActionService);
}
