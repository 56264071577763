(function () {
   'use strict';

   angular.module('com.vmware.vsphere.client.host')
      .service('confirmationDialogWithReasonService', confirmationDialogWithReasonService);

   confirmationDialogWithReasonService.$inject = ['i18nService', 'vuiConstants', 'mutationService', 'dataService'];

   function confirmationDialogWithReasonService(i18nService, vuiConstants, mutationService, dataService) {

      return {
         fetchHostNameAndMaintenanceModeStatus: fetchHostNameAndMaintenanceModeStatus,
         fetchHostsNameAndMaintenanceModeStatus: fetchHostsNameAndMaintenanceModeStatus,
         fetchHostsRebootData: fetchHostsRebootData,
         onOKClicked: onOKClicked
      };

      function fetchHostNameAndMaintenanceModeStatus(hostId, updateControllerCallback) {
         dataService.getProperties(hostId, ['name', 'inMaintenanceMode']).then(function (properties) {
            updateControllerCallback(properties.name, properties.inMaintenanceMode);
         });
      }

      function fetchHostsNameAndMaintenanceModeStatus(hostIds, updateMultipleControllerCallback) {
         dataService.getPropertiesForObjects(hostIds, ['name', 'inMaintenanceMode']).then(function (data) {
            _.each(hostIds, function(hostId, index) {
               var loading = true;
               if (index === hostIds.length - 1) {
                  loading = false;
               }
               updateMultipleControllerCallback(data[hostId].name, data[hostId].inMaintenanceMode, hostId, loading);

            });
         });
      }

      function fetchHostsRebootData(hostIds, updateMultipleControllerCallback) {
         dataService.getPropertiesForObjects(hostIds, ['name', 'inMaintenanceMode',
            'config.vsanHostConfig.enabled']).then(function (data) {
            _.each(hostIds, function(hostId, index) {
               var loading = true;
               if (index === hostIds.length - 1) {
                  loading = false;
               }

               updateMultipleControllerCallback(data[hostId].name, data[hostId].inMaintenanceMode,
                     data[hostId]['config.vsanHostConfig.enabled'], hostId, loading);
            });
         });
      }

      function onOKClicked(getReason, getOnlyMmHosts, type, modalOptions, targets, getMmHosts) {
         if (!getReason()) {
            showError(i18nService.getString('HostUi', 'bootDevice.rebootAndShutdownReasonEnterReason'), modalOptions);
            return false;
         }

         var hostPowerSpec = {powerOpType: type, reason: getReason(), force: true};
         if (getOnlyMmHosts()) {
            if(getMmHosts().length === 0) {
               return true;
            }
            mutationService.applyOnMultiEntity(getMmHosts(), 'com.vmware.vsphere.client.host.powerops.HostPowerSpec',
                hostPowerSpec);
         } else {
            mutationService.applyOnMultiEntity(targets, 'com.vmware.vsphere.client.host.powerops.HostPowerSpec',
                hostPowerSpec);
         }
         return true;
      }

      function showError(errorMessage, modalOptions) {
         modalOptions.alerts = [{
            text: errorMessage,
            type: vuiConstants.validationBanner.type.ERROR
         }];
      }
   }
}());

