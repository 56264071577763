/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */

(function() {
   'use strict';
   angular.module('com.vmware.vsphere.client.network').controller(
      'VmKernelAdapterDetailsViewController', VmKernelAdapterDetailsViewController);

   VmKernelAdapterDetailsViewController.$inject = [
      '$scope'
   ];

   function VmKernelAdapterDetailsViewController ($scope) {

      var self = this;

      self.hostId = $scope._route.objectId;
      self.liveRefreshProperties = [
         "config.network.vnic",
         "config.virtualNicManagerInfo.netConfig",
         "config.network.vswitch",
         "config.network.proxySwitch",
         "config.network.opaqueSwitch",
         "config.network.netStackInstance",
         "config.network.portgroup",
         "config.network.dnsConfig.address"
      ];
      self.registerRefreshCallback = function(refresh) {
         self.refresh = refresh;
      };

      self.customizedSettings = {
         noPoliciesIncluded: false,
         addAllTab: true
      };
   }
})();
