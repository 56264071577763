namespace h5_vm {
   const TPM_EXPORT_ACTION = "vsphere.core.vm.tpm.export";

   class TpmCsrListController implements ng.IComponentController {
      static $inject: string[] = [
         'i18nService',
         'vuiConstants',
         'commonActionBarService',
         'columnRenderersRegistry',
         'navigation',
         'vmTpmService',
         '$scope'
      ];

      private vmId: string;
      private i18n: Function;
      private loading: boolean;
      public  datagridOptions: any;
      private masterDetailsViewContext: any;

      constructor(private i18nService: any,
                  private vuiConstants: any,
                  private commonActionBarService: any,
                  private columnRenderersRegistry: any,
                  private navigation: any,
                  private vmTpmService: any,
                  private $scope: any) {
         this.i18n = i18nService.getString;
         this.vmId = this.navigation.getRoute().objectId;
      }

      private getColumnDefinitions() {
         let textRenderer = this.columnRenderersRegistry.getColumnRenderer('text');
         return [
            {
               displayName: this.i18nService.getString("VmUi", "certificate.type"),
               field: "algorithmName",
               template: (item: any) => {
                  return textRenderer(["algorithmName"], item);
               }
            }
         ];
      }

      public $onInit() {
         this.datagridOptions = {
             data: [],
             height: "100%",
             columnDefs: this.getColumnDefinitions(),
             selectionMode: this.vuiConstants.grid.selectionMode.SINGLE,
             resizable: true,
             selectedItems: [],
             actionBarOptions: {actions: []}
         };

         this.$scope.$watch( () => {
            return this.datagridOptions.selectedItems;
         }, (newValue: any, oldValue:any) => {
            if (newValue !== oldValue) {
               if (this.masterDetailsViewContext) {
                  this.masterDetailsViewContext.selectedItems = newValue;
                  this.updateActionBar();
               }
            }
         });
         this.refresh();
         this.updateActionBar();
      }

      public refresh = (): void => {
         this.loading = true;
         this.vmTpmService.getCertSigningRequests(this.vmId)
               .then((result:any) => {
                  this.datagridOptions.data = result;
               })
               .finally(()=>{
                  this.loading = false;
               });
      };

      private updateActionBar() {
         let self = this;
         this.commonActionBarService.updateActionBar(this.datagridOptions.actionBarOptions,
               "actions",  [this.vmId],
               [ {
                  actionId: TPM_EXPORT_ACTION,
                  isActionAvailable: () => {
                     return (self.datagridOptions.selectedItems.length === 1);
                  },
                  getActionInvocationContext: () =>  {
                     return self.datagridOptions.selectedItems[0];
                  }
               } ]);
      }

    }

    angular.module("com.vmware.vsphere.client.vm")
         .component("tpmCsrListComponent", {
            templateUrl: "vm-ui/resources/vm/views/configure/tpm/tpm-csr-list.component.html",
            bindings: {
               vmId: "<",
               masterDetailsViewContext: "<"
            },
            controller: TpmCsrListController
         });
}
