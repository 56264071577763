namespace h5_vm {

   import GosBasePageModel = h5_vm.GosBasePageModel;

   export class GosCustomSysprepFilePageModel extends GosBasePageModel {
      fileOption: CustomSysprepFileOption;
      sysprepImportFileName: string;
      sysprepImportFileContent: string;
      sysprepContent: string;
      vcServerGuid: String;
   }
}

