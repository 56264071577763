namespace h5_vm {

   export class VmStorageBrowserMetadata {
      storageData: any;

      constructor(storageData: any) {
         this.storageData = storageData;
      }
   }
}
