/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace dvs_ui {

   import VmwareDistributedVirtualSwitch$VlanIdSpec = com.vmware.vim.binding.vim.dvs.VmwareDistributedVirtualSwitch$VlanIdSpec;
   import VmwareDistributedVirtualSwitch$TrunkVlanSpec = com.vmware.vim.binding.vim.dvs.VmwareDistributedVirtualSwitch$TrunkVlanSpec;
   import VmwareDistributedVirtualSwitch$PvlanSpec = com.vmware.vim.binding.vim.dvs.VmwareDistributedVirtualSwitch$PvlanSpec;
   import DistributedVirtualPortgroup$ConfigSpec = com.vmware.vim.binding.vim.dvs.DistributedVirtualPortgroup$ConfigSpec;
   import VmwareDistributedVirtualSwitch$VmwarePortConfigPolicy = com.vmware.vim.binding.vim.dvs.VmwareDistributedVirtualSwitch$VmwarePortConfigPolicy;
   import DvPortgroupCreateSpec = com.vmware.vsphere.client.dvportgroup.DvPortgroupCreateSpec;
   import VmwareDistributedVirtualSwitch$VlanSpec = com.vmware.vim.binding.vim.dvs.VmwareDistributedVirtualSwitch$VlanSpec;
   import StringPolicy = com.vmware.vim.binding.vim.StringPolicy;

   /**
    * Provides methods from creating the mutation spec sent to the backend
    * after the completion of the New Distributed Port Group wizard.
    */
   export class DvpgCreateWizardSpecFactory {

      public static $inject = [
         "defaultUriSchemeUtil",
         "dvpgCreateWizardConstants",
         "networkUiConstants",
         "dvpgSpecFactory"
      ];

      constructor(private defaultUriSchemeUtil: any,
                  private wizardConstants: any,
                  private networkUiConstants: any,
                  private dvpgSpecFactory: DvpgSpecFactory) {
      }

      /**
       * Builds the spec the create distributed port group mutation operation.
       */
      public buildDvpgCreateSpec(model: DvpgCreateWizardModel): DvPortgroupCreateSpec {

         let defaultPortConfig: VmwareDistributedVirtualSwitch$VmwarePortConfigPolicy =
               new VmwareDistributedVirtualSwitch$VmwarePortConfigPolicy();
         defaultPortConfig.vlan = this.createVlanSpec(model);

         if (model.isPolicyConfigurationEnabled) {
            defaultPortConfig.inShapingPolicy = this.dvpgSpecFactory
                  .buildTrafficShapingSpec(
                        model.trafficShapingPolicyModel.inShapingPolicy);
            defaultPortConfig.outShapingPolicy = this.dvpgSpecFactory
                  .buildTrafficShapingSpec(
                        model.trafficShapingPolicyModel.outShapingPolicy);

            defaultPortConfig.securityPolicy = this.dvpgSpecFactory
                  .buildSecurityPolicySpec(model.securityPolicyModel);

            defaultPortConfig.uplinkTeamingPolicy = this.dvpgSpecFactory
                  .buildTeamingAndFailoverSpec(model.failoverPolicyModel);

            defaultPortConfig.ipfixEnabled = this.dvpgSpecFactory
                  .buildMonitoringSpec(model.monitoringPolicyModel);

            defaultPortConfig.blocked = this.dvpgSpecFactory
                  .buildMiscSpec(model.miscPolicyModel);
         }

         let configSpec = new com.vmware.vim.binding.vim.dvs.DistributedVirtualPortgroup$ConfigSpec();
         configSpec.defaultPortConfig = defaultPortConfig;
         if (model.portBinding === this.wizardConstants.portBinding.EARLY_BINDING) {
            configSpec.autoExpand = model.autoExpand;
         }
         if (model.isVmVnicResourcePoolSupported) {
            configSpec.vmVnicNetworkResourcePoolKey = model.networkResourcePoolKey;
         } else if (model.isNetworkResourcePoolSupported) {
            configSpec.defaultPortConfig.networkResourcePoolKey = new StringPolicy();
            configSpec.defaultPortConfig.networkResourcePoolKey.value = model.networkResourcePoolKey;
            configSpec.defaultPortConfig.networkResourcePoolKey.inherited = false;
         }
         configSpec.name = model.name;
         configSpec.type = model.portBinding;
         configSpec.numPorts = model.numPorts;

         let spec: DvPortgroupCreateSpec = new DvPortgroupCreateSpec();
         spec.switchRef = this.defaultUriSchemeUtil.getManagedObjectReference(model.dvsId);
         spec.configSpec = configSpec;
         return spec;
      }

      private createVlanSpec(model: DvpgCreateWizardModel): any {
         let vlanSpec: any;
         if (model.vlanType === this.networkUiConstants.Vlan.Type.NONE) {
            vlanSpec = new VmwareDistributedVirtualSwitch$VlanIdSpec();
            vlanSpec.vlanId = this.networkUiConstants.Vlan.Id.NONE;
         } else if (model.vlanType === this.networkUiConstants.Vlan.Type.VLAN) {
            vlanSpec = new VmwareDistributedVirtualSwitch$VlanIdSpec();
            vlanSpec.vlanId = model.vlanId;
         } else if (model.vlanType === this.networkUiConstants.Vlan.Type.TRUNK) {
            vlanSpec = new VmwareDistributedVirtualSwitch$TrunkVlanSpec();
            vlanSpec.vlanId = model.vlanTrunkNumericRanges;
         } else if (model.vlanType === this.networkUiConstants.Vlan.Type.PRIVATE) {
            vlanSpec = new VmwareDistributedVirtualSwitch$PvlanSpec();
            vlanSpec.pvlanId = model.pvlanId;
         }
         return vlanSpec;
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .service("dvpgCreateWizardSpecFactory", DvpgCreateWizardSpecFactory);
}
