(function () {
   "use strict";
   angular.module("com.vmware.vsphere.client.network").controller(
         "IscsiPortBindingViewController", IscsiPortBindingViewController);

   IscsiPortBindingViewController.$inject = ["i18nService", "navigation"];

   function IscsiPortBindingViewController (i18nService, navigation) {
      this.i18n = i18nService.getString;
      this.hostId = navigation.getRoute().objectId;
   }
})();
