/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace dvs_ui {

   export class DvsEditSettingsAdvancedPageModel {

      public static LDP_OPERATION_NONE: string = "none";

      public static LDP_PROTOCOL_DISABLED: string = "disabled";

      public mtu: number;

      public multicastFilteringMode: string;
      public multicastSnoopingSupported: boolean;

      public lldpSupported: boolean;
      public ldpProtocol: string;
      public ldpOperation: string;

      public contactName: string;
      public contactDetails: string;

      public hasModifyPrivilege: boolean;

      public buildChanges(originalData: any): any {
         let changesObject: any = {
            mtu: null,
            multicastFilteringMode: null,
            ldpProtocol: null,
            ldpOperation: null,
            contactName: null,
            contactDetails: null
         };

         if (this.mtu !== originalData.mtu) {
            changesObject.mtu = this.mtu;
         }

         if (this.multicastSnoopingSupported
               && this.multicastFilteringMode !== originalData.multicastFilteringMode) {
            changesObject.multicastFilteringMode = this.multicastFilteringMode;
         }

         let operationChanged: boolean =
               this.ldpOperation !== originalData.initialLdpOperation;
         let protocolChanged: boolean =
               this.ldpProtocol !== originalData.initialLdpProtocol;

         if (this.ldpProtocol !==
               DvsEditSettingsAdvancedPageModel.LDP_PROTOCOL_DISABLED) {
            if (operationChanged || protocolChanged) {
               changesObject.ldpOperation = this.ldpOperation;
               changesObject.ldpProtocol = this.ldpProtocol;
            }
         } else {
            if (protocolChanged) {
               changesObject.ldpProtocol = originalData.ldpProtocol;
               changesObject.ldpOperation =
                     DvsEditSettingsAdvancedPageModel.LDP_OPERATION_NONE;
            }
         }

         if (this.contactName !== originalData.contactName) {
            changesObject.contactName = this.contactName;
         }

         if (this.contactDetails !== originalData.contactDetails) {
            changesObject.contactDetails = this.contactDetails;
         }

         return this.hasChanges(changesObject) ? changesObject : null;
      }

      private hasChanges(changesObject: any): boolean {
         for (let key in changesObject) {
            if (changesObject[key] !== null) {
               return true;
            }
         }

         return false;
      }
   }
}
