/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace dvs_ui {

   export class DvsCreateWizardModel {
      parentEntityUrn: string;
      parentEntityName: string | null;
      parentEntityIcon: string;
      uniqueDvsName: string;

      supportedVersions: string[];
      selectedVersion: string;

      numberOfUplinks: number;

      niocEnabled: boolean;

      createDefaultPortgroup: boolean;
      defaultPortgroupName: string;
   }
}
