(function () {
   /**
    * Directive for HA and DRS advanced options grid.
    */
   'use strict';
   angular.module('com.vmware.vsphere.client.commonModule')
      .directive('advancedOptions', advancedOptions);

   advancedOptions.$inject = ['i18nService', 'vuiConstants'];

   function advancedOptions(i18nService, vuiConstants) {
      return {
         restrict: 'E',
         templateUrl: 'common-module-ui/resources/common-module/views/advanced-options/advancedOptions.html',
         scope: {
            items: '=',
            builder: '=',
            editable: '@',
            accessibility: '@?'
         },
         controller: ['$scope', '$element', function($scope, $element) {
            //grid toolbar actions
            var addAction = {
               id: 'advancedOptionsGridAddButton',
               label: getString('config.advancedOptions.button.add'),
               enabled: true,
               iconClass: 'vui-icon-action-add',
               onClick: addRow
            };

            var removeAction = {
               id: 'advancedOptionsGridRemoveButton',
               label: getString('config.advancedOptions.button.delete'),
               enabled: true,
               iconClass: 'vui-icon-action-delete',
               onClick: removeRow
            };
            $scope.gridOptions = getGridOptions($scope.editable);

            //region Event handlers

            if ($scope.editable) {
               // enable/disable delete button based on selection
               $scope.$watch('gridOptions.selectedItems', function(selectedItems) {
                  removeAction.enabled = selectedItems.length !== 0;
               });

               // listener for scope builder items changed event in order to get the items
               $scope.$watch('builder.getOptions()', function(items) {
                  var advOptions = !!items ? items : [];
                  $scope.gridOptions.data = advOptions;
               });
            } else {
               // listener for scope.items changed event in order to get the items
               $scope.$watch('items', function(items) {
                  var advOptions = !!items ? items : [];
                  $scope.gridOptions.data = advOptions;
               });
            }

            //endregion

            //region Advanced options grid functions

            function getGridOptions(editable) {

               // grid height for five rows including header and footer
               var gridHeight = 252;
               // add toolbar height to total grid height
               if (editable) {
                  gridHeight += 33;
               }

               var options  = {
                  selectionMode: vuiConstants.grid.selectionMode.SINGLE,
                  sortMode: vuiConstants.grid.sortMode.SINGLE,
                  searchable: false,
                  resizable: true,
                  selectedItems: [],
                  data: [],
                  columnDefs: getColumnDefs(),
                  height: gridHeight + 'px'
               };

               // editable grid options
               if (editable) {
                  angular.extend(options, {
                     editable: {
                        mode: 'incell',
                        createAt: 'bottom'
                     },
                     actionBarOptions: {
                        actions: [
                           addAction,
                           removeAction
                        ]
                     },
                     // handler for grid save
                     save: function(event) {
                        var optionData = {
                           key: event.model.key,
                           value: event.model.value
                        };
                        angular.extend(optionData, event.values);

                        if (angular.isDefined(event.model.id)) {
                           $scope.builder.updateOption(event.model.id, optionData);
                        } else {
                           event.model.id = $scope.builder.addOption(optionData);
                        }
                     }
                  });
               }

               return options;
            }

            function getColumnDefs() {
               return [
                  {
                     displayName: getString('config.advancedOptions.optionColumnHeaderLabel'),
                     field: 'key',
                     type: 'string'
                  },
                  {
                     displayName: getString('config.advancedOptions.valueColumnHeaderLabel'),
                     field: 'value',
                     type: 'string'
                  }
               ];
            }

            function addRow() {
               $element.find('[kendo-grid]').data('kendoGrid').addRow();
            }

            function removeRow() {
               var selectedItems = $scope.gridOptions.selectedItems;

               if (selectedItems.length !== 0) {
                  selectedItems.forEach(function(item) {
                     $scope.builder.removeOption(item.id);
                  });
               }
            }

            //endregion

            //region Helper functions

            function getString(key) {
               return i18nService.getString('ClusterUi', key);
            }

            //endregion
         }]
      };
   }
})();
