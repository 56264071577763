module cluster_ui {

   export class MoveClusterToService {

      public static $inject = ["mutationService", "defaultUriSchemeUtil",
         "clarityModalService", "i18nService", "dataService", "authorizationService", "$q"];

      constructor(private mutationService: any, private defaultUriSchemeUtil: any,
                  private clarityModalService: any, private i18nService: any,
                  private dataService: any, private authorizationService: any,
                  private $q: any) {
      }

      public showMoveCluster(availableTargets: string[]): void {
         if (!availableTargets || !availableTargets.length) {
            return;
         }
         let dcProperty: string = "dc";

         this.dataService.getPropertiesForObjects(availableTargets, [dcProperty])
               .then((properties: any): any => {
                  let modalOptions: any = {
                     title: this.i18nService.getString("ClusterUi", "moveTo.title"),
                     defaultButton: "submit",
                     dialogData: {
                        objectIds: availableTargets,
                        dc: this.defaultUriSchemeUtil.getVsphereObjectId(
                              properties[availableTargets[0]][dcProperty])
                     },
                     contentTemplate: "cluster-ui/resources/cluster/views/move_to_cluster/moveClusterToDialog.html"
                  };

                  this.clarityModalService.openOkCancelModal(modalOptions);
               });
      }

      public validateMoveClusterToTarget(entityToMoveClusterTo: any,
                                         clusterIds: string[]): any {

         let moveSpec: any = this.getMoveSpec(clusterIds);

         return this.mutationService.validate(
               entityToMoveClusterTo,
               "com.vmware.vsphere.client.clusterui.MoveClusterSpec",
               moveSpec);
      }

      public moveCluster(entityToMoveClusterTo: any, clusterIds: string[]): void {

         let moveSpec: any = this.getMoveSpec(clusterIds);

         this.mutationService.apply(
               entityToMoveClusterTo,
               "com.vmware.vsphere.client.clusterui.MoveClusterSpec",
               moveSpec);

      }

      private getMoveSpec(clusterIds: string[]): any {
         let clusterMors: any[] = _.map(clusterIds, (clusterId: string): any => {
            return this.defaultUriSchemeUtil.getManagedObjectReference(clusterId);
         });
         let moveSpec: any = {
            entities: clusterMors
         };

         return moveSpec;
      }

      public checkPrivileges(itemToCheckIds: any[], targetToCheckId: any): any {
         let promises: any[] = [];
         let allItems: any[] = itemToCheckIds.concat([targetToCheckId]);

         promises.push(this.authorizationService
               .checkPrivilegesForMultipleObjects(
                     _.uniq(allItems), ['Host.Inventory.MoveCluster']));

         return this.$q.all(promises).then((result: any): any => {
            let objectsWithNoPrivilegeIds: any[] = [];
            let clusterMovePrivilegeResult: any;
            if (result.length > 1) {
               if (!result[0]) {
                  objectsWithNoPrivilegeIds.push(targetToCheckId);
               }
               clusterMovePrivilegeResult = result[1];
            } else {
               clusterMovePrivilegeResult = result[0];
            }
            _.each(clusterMovePrivilegeResult, (privileges: any, id: any): any => {
               if (!privileges['Host.Inventory.MoveCluster']) {
                  objectsWithNoPrivilegeIds.push(id);
               }
            });
            return objectsWithNoPrivilegeIds;
         });
      }

      public getPrivilegesErrorMessage(noPrivilegesIds: any[], entityToMoveRpTo: any): string {
         let message: string;
         if (_.contains(noPrivilegesIds, entityToMoveRpTo)) {
            if (noPrivilegesIds.length === 1) {
               message = this.i18nService.getString('ClusterUi', 'moveTo.noPermissionsOnTarget');
            } else {
               message = this.i18nService.getString('ClusterUi', 'moveTo.noPermissions');
            }
         } else {
            message = this.i18nService.getString('ClusterUi', 'moveTo.noPermissionsOnSource');
         }

         return message;
      }
   }

   angular.module("com.vmware.vsphere.client.cluster")
         .service("moveClusterToService", MoveClusterToService);
}
