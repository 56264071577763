namespace storage_ui {

   import IQService = angular.IQService;
   import IComponentController = angular.IComponentController;

   export class NfsSelectHostsPageController implements IComponentController {

      public mountedHosts: any;
      public hostsData: any[];
      private wizardConfig: any;
      private nfsWizardManager: any;
      private commonWizardManager: any;

      static $inject = ["i18nService", "vuiConstants",
         "columnRenderersRegistry", "datastoreConstants", "defaultUriSchemeUtil", "$q"];

      constructor(private i18nService: any,
                  private vuiConstants: any,
                  private columnRenderersRegistry: any,
                  private datastoreConstants: any,
                  private defaultUriSchemeUtil: any,
                  private $q: IQService) {
      }

      onSelectionChanged = (selectedItems: any[]): void => {
         this.nfsWizardManager.setSelectedHosts(selectedItems);
      };

      preselectComparator = (gridDataItem: any)=> {
         let oldSelectedItems = this.nfsWizardManager.getSelectedHosts();
         if (!oldSelectedItems || !gridDataItem) {
            return false;
         }

         return !!oldSelectedItems.find((item: any)=> {
            return item.formattedName === gridDataItem.formattedName;
         });
      };

      $onInit() {

         this.wizardConfig.loading = true;
         this.mountedHosts = {};
         let pagePromises = {
            availableHosts: this.commonWizardManager.getAvailableHosts(),
            mountedHosts: this.nfsWizardManager.requestExistingDatastoreHosts()
         };

         this.$q.all(pagePromises).then((response: any)=> {
            if (!response) {
               return;
            }
            this.mountedHosts = response.mountedHosts;
            this.hostsData = response.availableHosts;
         }).finally(()=> {
            this.wizardConfig.loading = false;
         });
      }


      public isHostValidForSelection = (hostItem: any): boolean => {
         return hostItem.isHostConnected && hostItem.hasConfigStoragePrivilege
               && !this.hasNfs4_1SupportIssue(hostItem)
               && !this.hasIpV6SupportIssue(hostItem)
               && !this.isHostAlreadyMounted(hostItem)
               && !this.hasActiveDirectoryIssue(hostItem)
               && !this.hasAuthenticationCredentialsIssue(hostItem)
               && !this.hasKrb5iSupportIssue(hostItem);
      };

      private hasNfs4_1SupportIssue(item: any) {
         return this.nfsWizardManager.getNfsVersion() === this.datastoreConstants.nfsVersions.NFS_4_1 && !item.isNfs4Supported;
      }

      private hasIpV6SupportIssue(item: any) {
         return this.nfsWizardManager.hasIpV6Servers() && !item.isIpV6NfsSupported;
      }


      private hasActiveDirectoryIssue(item: any) {
         return this.isKerberosEnabled() && !item.isInDomain;
      }

      private hasAuthenticationCredentialsIssue(item: any) {
         return this.isKerberosEnabled() && !item.areKerberosCredentialsSet;
      }

      private hasKrb5iSupportIssue(item: any) {
         let authenticationType = this.nfsWizardManager.getKerberosAuthenticationType();
         return authenticationType === this.datastoreConstants.kerberosSecurityTypes.SEC_KRB5I
               && !item.isKerberos5iSupported;
      }

      private isHostAlreadyMounted(item: any) {
         let hostId = this.defaultUriSchemeUtil.getVsphereObjectId(item.hostRef);
         if (this.mountedHosts) {
            return this.mountedHosts.hasOwnProperty(hostId);
         }
         return false;
      }

      private isKerberosEnabled() {
         let authenticationType = this.nfsWizardManager.getKerberosAuthenticationType();
         return authenticationType === this.datastoreConstants.kerberosSecurityTypes.SEC_KRB5
               || authenticationType === this.datastoreConstants.kerberosSecurityTypes.SEC_KRB5I;
      }

      formatHostName = (item: any) => {
         let formattedName = item.formattedName;
         if (item.isHostConnected) {
            if (this.hasIpV6SupportIssue(item)) {
               formattedName = this.i18nService.getString("StorageUi", "addDatastoreWizard.nfsSelectHostsPage.hostDoesNotSupportIpv6Nfs", item.formattedName);
            }
            /*In case of multiple host issues show the most important one */
            if (this.hasNfs4_1SupportIssue(item)) {
               formattedName = this.i18nService.getString("StorageUi", "addDatastoreWizard.nfsSelectHostsPage.nfsv41NotSupported", item.formattedName);
            }

            if (this.nfsWizardManager.getNfsVersion() === this.datastoreConstants.nfsVersions.NFS_4_1
                  && this.isKerberosEnabled()) {
               if (this.hasKrb5iSupportIssue(item)) {
                  formattedName = this.i18nService.getString("StorageUi", "addDatastoreWizard.nfsSelectHostsPage.krb5iNotSupported", item.formattedName);
               }
               if (this.hasAuthenticationCredentialsIssue(item)) {
                  formattedName = this.i18nService.getString("StorageUi", "addDatastoreWizard.nfsSelectHostsPage.noCredentials", item.formattedName);
               }
               if (this.hasActiveDirectoryIssue(item)) {
                  formattedName = this.i18nService.getString("StorageUi", "addDatastoreWizard.nfsSelectHostsPage.hostNotInDomain", item.formattedName);
               }
            }

            /*In case of multiple host issues show the most important one */
            if (this.isHostAlreadyMounted(item)) {
               formattedName = this.i18nService.getString("StorageUi", "addDatastoreWizard.nfsSelectHostsPage.hostAlreadyMounted", item.formattedName);
            }
            if (!item.hasConfigStoragePrivilege) {
               formattedName = this.i18nService.getString("StorageUi", "addDatastoreWizard.notEnoughPrivileges", item.formattedName);
            }
         }
         return formattedName;
      }
   }

   angular.module("com.vmware.vsphere.client.storage")
         .component("nfsSelectHostsPage",
               <IComponentOptions>{
                  controller: NfsSelectHostsPageController,
                  bindings: {
                     wizardConfig: "<",
                     nfsWizardManager: "<",
                     commonWizardManager: "<"
                  },
                  templateUrl: "storage-ui/resources/storage/views/wizard/createDatastore/pages/nfs/NfsSelectHostsPageComponent.html"
               });
}


