namespace h5_client {
    export module admin_ui {
        interface IRoleNamePageBindings {
            name: string;
            description: string;
            wizard: any;
        }

        export class RoleNamePageController implements ng.IComponentController, IRoleNamePageBindings {
            static $inject = ['i18nService'];
            private _name:string;
            private _description:string;
            public wizard:any;
            public previousName: string;
            public validity:string = "valid";
            public errorMessage:string = "";
            public $onInit?(): void;

            constructor(private i18nService: any) {
                this.$onInit = this.onInit;
            }

            public i18n(key: string) {
                return this.i18nService.getString('AdminUi', key);
            }

            public get name():string {
                return this._name;
            }

            public set name(value:string) {
                this._name = value;
            }

            public get description():string {
                return this._description;
            }

            public set description(value:string) {
                this._description = value;
            }


            public onInit():void {
                this.previousName = this._name;
                this.wizard.currentPage.onCommit = () => {
                    return !this.nameInvalid();
                };
            }

            public nameInvalid(): boolean {
                if (!this.name || this.name.length === 0) {
                    this.validity = "invalid";
                    this.errorMessage = this.i18nService.getString("Common", "error.role.name.req");
                    return true;
                }

                if (this.isDuplicateRole()) {
                    this.validity = "invalid";
                    this.errorMessage = this.i18nService.getString("Common", "error.role.name.unique");
                    return true;
                }

                return false;
            }

            private isDuplicateRole(): boolean {
                return this.name !== this.previousName && _.findIndex(this.wizard.roles, { name: this.name }) !== -1;
            }
        }

        export class RoleNamePage implements ng.IComponentOptions {
            static Name = "roleNamePage";

            public bindings:any;
            public controller: any;
            public templateUrl: string;

            constructor() {
                this.bindings = {
                    name: '=',
                    description: '=',
                    wizard: '='
                };
                this.controller = RoleNamePageController;
                this.templateUrl = 'admin-ui/resources/admin/views/wizard/role-name-page/role-name-page.component.html';
            }
        }

        angular.module('com.vmware.vsphere.client.admin')
            .component(RoleNamePage.Name, new RoleNamePage());
    }
}
