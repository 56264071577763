/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
(function () {
   'use strict';
   angular.module('com.vmware.vsphere.client.cluster')
      .controller('EvcController', EvcController);

   EvcController.$inject = ['$scope', 'dataService', 'i18nService', '$element'];

   function EvcController($scope, dataService, i18nService, $element) {
      var self = this;
      var objectId = $scope._route.objectId;

      self.headerOptions = getHeaderOptions();
      self.watchForObjects = [objectId];
      self.liveRefreshProperties = [
         "summary.currentEVCModeKey",
         "supportedEvcMode"
      ];
      self.getViewData = getViewData;

      getViewData();

      function getHeaderOptions() {
         return {
            title: i18nService.getString('ClusterUi', 'manage.settings.evc.description'),
            objectId: objectId,
            actions: [{
               actionUid: 'vsphere.core.cluster.actions.editEvcMode',
               customLabel: i18nService.getString('ClusterUi', 'general.config.button.edit')
            }]
         };
      }

      function getViewData() {
         dataService
            .getData(objectId,
               'com.vmware.vsphere.client.clusterui.model.configuration.EvcConfigData')
            .then(function (evcData) {
               parseEvcConfigData(evcData);
               self.evcData = evcData;
               self.headerOptions.title = getTitle(evcData.evcModeKey);
            });
      }

      function getTitle(evcEnabled) {
         return (evcEnabled) ?
            i18nService.getString('ClusterUi', 'evc.config.evcEnabledLabel')
            : i18nService.getString('ClusterUi', 'evc.config.evcDisabledLabel');
      }

      function parseEvcConfigData(evcData) {
         if (!evcData.evcModeKey || !evcData.currentEvcMode) {
            return;
         }
         self.datagridOptions = {
            columnDefs: [
               {
                  field: 'register',
                  displayName: i18nService.getString('ClusterUi', 'evc.config.registerColumnName'),
                  sortable: false,
                  template: function(cpuidFeatureListItem) {
                     var textElement = $('<span></span>').text(cpuidFeatureListItem.register);
                     if (cpuidFeatureListItem.isLeaf) {
                        textElement.addClass('cpuid-leaf');
                     }
                     return textElement[0].outerHTML;
                  }
               },
               {
                  field: 'value',
                  displayName: i18nService.getString('ClusterUi', 'evc.config.valueColumnName'),
                  sortable: false
               }
            ],
            pageConfig: {
               hidePager: true
            },
            searchable: false,
            selectedItems: [],
            data: generateCpuidFeaturesListData(evcData.currentEvcMode.guaranteedCPUFeatures),
            dataBound: function() {
               $element.find('[vui-datagrid] .k-grid-content').css('height', '');
            }
         };
      }

      function generateCpuidFeaturesListData(guaranteedCPUFeatures) {
         var cpuidFeaturesList = [];

         guaranteedCPUFeatures.forEach(function(cpuFeature) {

            // The leaf information.
            var cpuFeatureLevel = cpuFeature.level;

            // Represent 32-bit number as unsigned int
            // http://stackoverflow.com/questions/57803/how-to-convert-decimal-to-hex-in-javascript
            if (cpuFeatureLevel < 0) {
               cpuFeatureLevel = 0xFFFFFFFF + cpuFeatureLevel + 1;
            }
            var leafCpuidListItem = {
               register: i18nService.getString('ClusterUi', 'evc.config.cpuidFeaturesFlagLeafLabel',
                  cpuFeatureLevel.toString(16)),
               value: '',
               isLeaf: true
            };
            cpuidFeaturesList.push(leafCpuidListItem);

            // The information for eax register.
            var eaxCpuidListItem = {
               register: 'eax',
               value: cpuFeature.eax
            };
            cpuidFeaturesList.push(eaxCpuidListItem);

            // The information for ebx register.
            var ebxCpuidListItem = {
               register: 'ebx',
               value: cpuFeature.ebx
            };
            cpuidFeaturesList.push(ebxCpuidListItem);

            // The information for ecx register.
            var ecxCpuidListItem = {
               register: 'ecx',
               value: cpuFeature.ecx
            };
            cpuidFeaturesList.push(ecxCpuidListItem);

            // The information for edx register.
            var edxCpuidListItem = {
               register: 'edx',
               value: cpuFeature.edx
            };
            cpuidFeaturesList.push(edxCpuidListItem);
         });
         return cpuidFeaturesList;
      }
   }
})();
