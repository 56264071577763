module ds_cluster_ui {

   export class CreateDsClusterWizardManager {

      public static $inject = ["i18nService", "vuiConstants", "mutationService",
         "dsClusterSpecBuilderService", "dsClusterConstants", "defaultUriSchemeUtil",
         "$rootScope", "sdrsDialogManager"];

      private nameAndLocationPageDef: WizardPage;
      private automationPageDef: WizardPage;
      private runtimeSettingsPageDef: WizardPage;
      private hostsAndClustersPageDef: WizardPage;
      private datastoresPageDef: WizardPage;
      private readyToCompletePageDef: WizardPage;

      private model: DsClusterModel;
      private wizardConfig: any;

      constructor(private i18nService: any, private vuiConstants: any,
                  private mutationService: any,
                  private dsClusterSpecBuilderService: DsClusterSpecBuilderService,
                  private dsClusterConstants: any, private defaultUriSchemeUtil: any,
                  private $rootScope: RootScopeRoute,
                  private sdrsDialogManager: any) {
         this.createPages();
      }

      /**
       * Retrieves page definitions.
       *
       * @returns {WizardPage[]}
       */
      public getPageDefinitions(): WizardPage[] {
         return [this.nameAndLocationPageDef,
            this.automationPageDef,
            this.runtimeSettingsPageDef,
            this.hostsAndClustersPageDef,
            this.datastoresPageDef,
            this.readyToCompletePageDef];
      }

      /**
       * Submits wizard.
       */
      public submit(): boolean {
         let model: DsClusterModel = this.getModel();

         let spec: Object =
               this.dsClusterSpecBuilderService.buildDsClusterSpec(model, true);

         this.mutationService.add(this.dsClusterConstants.DS_CLUSTER_SPEC_TYPE, spec);

         return true;
      }

      public setModelDatastoreRefs(): void {
         let model: DsClusterModel = this.getModel();
         let self: CreateDsClusterWizardManager = this;

         model.datastoreRefs = [];

         model.datastores.forEach(function (dsId: string) {
            model.datastoreRefs.push(
                  self.defaultUriSchemeUtil.getManagedObjectReference(dsId));
         });
      }

      /**
       * Retrieves wizard model.
       *
       * @returns {DsClusterModel}
       */
      public getModel(): DsClusterModel {
         return this.model;
      }

      public getWizardConfig(): any {
         if (!this.wizardConfig) {
            this.wizardConfig = {
               title: this.i18nService.getString("DsClusterUi", "createDsClusterWizard.title"),
               pages: this.getPageDefinitions(),
               show: true,
               loading: false,
               loadingMessage: this.i18nService.getString("CommonUi", "wizard.loading"),
               onFinish: () => this.submit(),
               cssClass: "create-ds-cluster-wizard"
            };
         }

         return this.wizardConfig;
      }

      /**
       * Initializes wizard model with default values.
       */
      public initModel(objectId: string): void {
         this.model = {
            objectId: objectId,
            name: this.i18nService.getString("DsClusterUi",
                  "createDsClusterWizard.defaultName"),
            hostsAndClusters: [],
            datastores: [],
            datastoreRefs: [],
            datastoreDetails: [],
            parent: this.defaultUriSchemeUtil.getManagedObjectReference(objectId),
            dsFilter: {
               filterId: "datastoresWithoutPMemDatastoresFilter"
            },
            sdrsEnabled: true,
            defaultVmBehavior: this.dsClusterConstants.SDRS_FULLAUTO,
            automationOverrides: this.sdrsDialogManager.getAutomationOverridesModel(null),
            ioLoadBalanceEnabled: true,
            ioLatencyThreshold:this.dsClusterConstants.ioLatencyThreshold.DEFAULT,
            spaceLoadBalanceConfig: this.sdrsDialogManager.getSpaceLoadBalanceConfigModel()
         };
      }

      private createPages() {
         this.nameAndLocationPageDef = {
            title: this.i18nService.getString("DsClusterUi",
                  "createDsClusterWizard.nameAndLocationPage.title"),
            contentUrl: "ds-cluster-ui/resources/ds-cluster/views/create/nameAndLocationPage.html",
            state: this.vuiConstants.wizard.pageState.INCOMPLETE,
            onCommit: function () {
            }
         };

         this.automationPageDef = {
            title: this.i18nService.getString("DsClusterUi",
                  "createDsClusterWizard.automationLevelPage.title"),
            contentUrl: "ds-cluster-ui/resources/ds-cluster/views/create/dsAutomationPage.html",
            state: this.vuiConstants.wizard.pageState.DISABLED,
            onCommit: function () {
            }
         };

         this.runtimeSettingsPageDef = {
            title: this.i18nService.getString("DsClusterUi",
                  "createDsClusterWizard.runtimeSettingsPage.title"),
            contentUrl: "ds-cluster-ui/resources/ds-cluster/views/create/runtimeSettingsPage.html",
            state: this.vuiConstants.wizard.pageState.DISABLED,
            onCommit: function () {
            }
         };

         this.hostsAndClustersPageDef = {
            title: this.i18nService.getString("DsClusterUi",
                  "createDsClusterWizard.hostsAndClustersPage.title"),
            contentUrl: "ds-cluster-ui/resources/ds-cluster/views/create/hostsAndClustersPage.html",
            state: this.vuiConstants.wizard.pageState.DISABLED,
            onCommit: function () {
            }
         };

         this.datastoresPageDef = {
            title: this.i18nService.getString("DsClusterUi",
                  "createDsClusterWizard.selectDatastoresPage.title"),
            contentUrl: "ds-cluster-ui/resources/ds-cluster/views/create/datastoresPage.html",
            state: this.vuiConstants.wizard.pageState.DISABLED,
            onCommit: function () {
            }
         };

         this.readyToCompletePageDef = {
            title: this.i18nService.getString("DsClusterUi",
                  "createDsClusterWizard.readyToCompletePage.title"),
            contentUrl: "ds-cluster-ui/resources/ds-cluster/views/create/readyToCompletePage.html",
            state: this.vuiConstants.wizard.pageState.DISABLED,
            onCommit: function () {
            }
         };
      }

      public changeSdrsPagesVisibility(visible: boolean): void {
         if (visible) {
            this.automationPageDef.state = this.vuiConstants.wizard.pageState.DISABLED;
            this.runtimeSettingsPageDef.state = this.vuiConstants.wizard.pageState.DISABLED;
         } else {
            this.automationPageDef.state = this.vuiConstants.wizard.pageState.SKIPPED;
            this.runtimeSettingsPageDef.state = this.vuiConstants.wizard.pageState.SKIPPED;
         }
      }
   }

   angular.module("com.vmware.vsphere.client.dsCluster")
         .service("createDsClusterWizardManager", CreateDsClusterWizardManager);
}
