namespace h5_vm {
   import CustomizationSpecInfo = com.vmware.vim.binding.vim.CustomizationSpecInfo;
   import Specification = com.vmware.vim.binding.vim.vm.customization.Specification;
   export interface CustomizeGosSelectSpecDialogContext {
      vmId: string;
      vmGuestOsFullName: string;
      vmGuestOsType: string;
      relevantSpecs: Array<CustomizationSpecInfo>;
   }

   export class CustomizeGosSelectSpecDialogService {
      public static $inject = [
         "$q",
         "i18nService",
         "clarityModalService",
         "vuiConstants",
         "customizeGosApplySpecService",
         "customizeGosUserSettingsDialogService"
      ];

      private context: CustomizeGosSelectSpecDialogContext;
      private pageModel: CustomizeGosSelectSpecPageModel;
      private dialogOptions: any;

      constructor(private $q: any,
            private i18nService: any,
            private clarityModalService: any,
            private vuiConstants: any,
            private customizeGosApplySpecService: CustomizeGosApplySpecService,
            private customizeGosUserSettingsDialogService: CustomizeGosUserSettingsDialogService) {
      }

      open(context: CustomizeGosSelectSpecDialogContext): void {
         this.context = context;
         let dialogOptions: any = {
            contentTemplate: "vm-ui/resources/vm/customize-guest-os/select-spec/customize-gos-select-spec-page.html",
            title: this.i18nService.getString("VmUi", "customizeGuestOs.title"),
            subTitle: {
               objectId: this.context.vmId
            },
            size: "lg",
            defaultButton: "submit",
            onSubmit: this.onDialogSubmit.bind(this)
         };

         this.pageModel = new CustomizeGosSelectSpecPageModel(
               this.context.vmId,
               this.context.vmGuestOsFullName,
               this.context.relevantSpecs);
         dialogOptions.dialogData = {
            selectSpecPageModel: this.pageModel
         };

         this.dialogOptions = dialogOptions;
         this.clarityModalService.openOkCancelModal(this.dialogOptions);
      }

      // region private helpers
      private onDialogSubmit(): IPromise<boolean> {
         let pageCommitPromise = this.pageModel.onPageCommit
               ? this.pageModel.onPageCommit()
               : this.$q.resolve([]);

         return pageCommitPromise.then((errorMsgs: Array<string>) => {
            this.dialogOptions.alerts = [];

            if (errorMsgs && errorMsgs.length > 0) {
               this.dialogOptions.alerts = errorMsgs.map((msg: string) => {
                  return {
                     text: msg,
                     type: this.vuiConstants.validationBanner.type.ERROR
                  };
               });
               return this.$q.resolve(false);
            }

            if (!this.pageModel.specification) {
               throw new Error("Invalid specification");
            }

            const selectedSpecification: Specification = this.pageModel.specification;

            if (this.pageModel.hasUnknownNameGen ||
                  this.pageModel.hasUnknownIpv4Gen ||
                  this.pageModel.hasUnknownIpv6Gen) {

               return this.promptForUserSettings().then((confirmed: boolean) => {
                  if (confirmed) {
                     let context: CustomizeGosUserSettingsDialogContext = {
                        vmId: this.context.vmId,
                        vmGuestOsFullName: this.context.vmGuestOsFullName,
                        vmGuestOsType: this.context.vmGuestOsType,
                        specification: selectedSpecification,
                        hasUnknownNameGen: this.pageModel.hasUnknownNameGen,
                        hasUnknownIpv4Gen: this.pageModel.hasUnknownIpv4Gen,
                        hasUnknownIpv6Gen: this.pageModel.hasUnknownIpv6Gen,
                        focusTarget: this.dialogOptions.focusTarget
                     };
                     this.dialogOptions.focusTarget = null;
                     this.customizeGosUserSettingsDialogService.open(context);
                  }
                  return confirmed;
               });
            }

            // no additional data needed - apply the spec
            let res = this.customizeGosApplySpecService.apply(
                  this.context.vmId, selectedSpecification);
            return this.$q.resolve(res);;
         });
      }

      private promptForUserSettings(): IPromise<boolean> {
         let defered = this.$q.defer();

         this.clarityModalService.openConfirmationModal({
            title: this.i18nService.getString("VmUi",
                  "customizeGuestOs.openUserSettingsPrompt.title"),
            message: this.i18nService.getString("VmUi",
                  "customizeGuestOs.openUserSettingsPrompt.text"),
            submit: () => {
               defered.resolve(true);
            },
            onCancel: () => {
               defered.resolve(false);
            },
            showXIcon: false,
            clarityIcon: {
               shape: "warning",
               class: "is-warning",
               size: 48
            },
            preserveNewlines: true,
            saveButtonLabel: this.i18nService.getString("Common", "yes.label"),
            cancelButtonLabel: this.i18nService.getString("Common", "no.label")
         });

         return defered.promise;
      }

      // endregion

   }

   angular.module("com.vmware.vsphere.client.vm")
         .service("customizeGosSelectSpecDialogService", CustomizeGosSelectSpecDialogService);
}
