/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
(function () {
   'use strict';

   var module = angular.module('com.vmware.vsphere.client.networkLibUi');
   module.directive('networkSelector', networkSelector);

   module.constant('NetworkSelectorMode', {
         SINGLE_SELECTION_BROWSE_INPUT: "singleSelectionBrowseInput",
         SINGLE_SELECTION_BUTTON: "singleSelectionButton",
         MULTIPLE_SELECTION: "multipleSelection",
         SINGLE_SELECTION_DROPDOWN: "singleSelectionDropdown"
   });

   function networkSelector() {

      var directive = {
         restrict: 'A',
         replace: true,
         scope: {
            networkSelectorName: '<?', // Optional name parameter.
            // Could be used when using the network selector in a form.
            objectRef: '=', // The context object of the network selector
            mode: '=', // The network selector display mode
            filterSpec: '=', // A spec for filtering the requested networks
            dialogSpec: '=?', // A spec for specifying network's privileges and errors
            preselectedNetwork: '=?', // The pre-selected network if any
            onSelectedNetworks: '&onSelectedNetworks', // A callback function for retrieving the selected networks
            onPreloadNetworks: '&onPreloadNetworks', // A callback function to retrieve the preloaded networks when the browse button is clicked
            prefetchedNetworks: '=?', //Array with all the prefetched networks
            errorConfig: '<?',  // vxError object used to display error associated with the network selector
            sortParams: '<?', // parameters for sorting the grid of the network selector dialog (ex: [{field:"name", dir:"asc"}] )
            isDisabled: '<?', // disable the selector
            preloadNetworks: '<?' // An optional parameter, used when the networks will be pre-loaded and the selector should not retrieve them
         },
         templateUrl: 'network-lib-ui/resources/network-lib/components/networkselector/networkSelector.html',
         controller: 'NetworkSelectorController',
         controllerAs: 'ctrl'
      };

      return directive;
   }
})();
