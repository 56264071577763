namespace h5_vm {

   import ManagedObjectReference = com.vmware.vim.binding.vmodl.ManagedObjectReference;

   export class MigrationHelperService {
      public static $inject: string[] = [
         "defaultUriSchemeUtil"
      ];


      constructor(
            private defaultUriSchemeUtil: {
               getPartsFromVsphereObjectId: (objectId: string) => ManagedObjectReference,
               getManagedObjectReference: (objectId: string) => ManagedObjectReference
            }
      ) {
      }

      public isXvcOperation(vmIds: string[], resourceId: string) {
         return this.isXvcOperationByRef(
               vmIds, this.defaultUriSchemeUtil.getManagedObjectReference(resourceId));
      }

      public isXvcOperationByRef(vmIds: string[], resourceRef?: ManagedObjectReference) {
         if (!resourceRef || !resourceRef.serverGuid) {
            return false;
         }

         let destinationSeverId =  resourceRef.serverGuid;
         let isXvc = _.some(vmIds, (vmId: string) => {
            let sourceServerId = this.defaultUriSchemeUtil.getPartsFromVsphereObjectId(
                  vmId).serverGuid;
            return destinationSeverId !== sourceServerId;
         });
         return isXvc;
      }

   } // class MigrationHelperService

   angular.module("com.vmware.vsphere.client.vm").service(
         "migrationHelperService", MigrationHelperService);

} // module
