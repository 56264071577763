/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace dvs_ui {

   import CreateDvPortgroupPoliciesModel = com.vmware.vsphere.client.h5.network.dvportgroup.create.model.CreateDvPortgroupPoliciesModel;
   export class DvpgGeneralPropertiesPageController {

      public static $inject = [
         "$scope",
         "i18nService",
         "dvpgCreateWizardConstants",
         "networkUtil",
         "dataService",
         "networkUiConstants",
         "dvpgPropertiesService",
         "dvpgGeneralPropertiesPageService",
         "dvpgFailoverPolicyPageService"];

      public static readonly POLICIES_PROPERTY = "createdvpg:policiesModel";

      public portBindingOptions: any;
      public portAllocationOptions: any;
      public vlanTypeOptions: any;
      public signPostConfig: any;
      public networkResourcePoolOptions: any;
      public pvlanTypeOptions: any;
      public numPortsValidator: any = {};
      public onNumPortsChange: any;
      public vlanIdValidator: any = {};
      public onVlanIdChange: any;

      constructor(private $wizardScope: DvpgCreateWizardScope,
                  private i18nService: any,
                  private wizardConstants: any,
                  private networkUtil: any,
                  private dataService: any,
                  private networkUiConstants: any,
                  private dvpgPropertiesService: any,
                  private dvpgGeneralPropertiesPageService: any,
                  private dvpgFailoverPolicyPageService: DvpgFailoverPolicyPageService) {

         if (!this.$wizardScope.flags.isGeneralPropertiesPageInitialized) {
            this.initModel();
            this.requestProperties();
         } else {
            this.networkResourcePoolOptions =
                  this.dvpgGeneralPropertiesPageService.getNetworkResourcePoolsOptions(
                        this.$wizardScope.model.networkResourcePools);
            this.pvlanTypeOptions =
                  this.dvpgGeneralPropertiesPageService.getPvlanOptions(
                     this.$wizardScope.model.pvlanConfig);
         }

         this.portBindingOptions = [{
            label: this.i18nService.getString("DvsUi",
               "dvpg.create.properties.portBinding.earlyBinding"),
            val: this.wizardConstants.portBinding.EARLY_BINDING
         }, {
            label: this.i18nService.getString("DvsUi",
               "dvpg.create.properties.portBinding.ephemeral"),
            val: this.wizardConstants.portBinding.EPHEMERAL
         }];

         this.portAllocationOptions = [{
            label: this.i18nService.getString("DvsUi",
               "dvpg.create.properties.portAllocation.autoExpand.true"),
            val: true
         }, {
            label: this.i18nService.getString("DvsUi",
               "dvpg.create.properties.portAllocation.autoExpand.false"),
            val: false
         }];

         this.vlanTypeOptions = [{
            label: i18nService.getString(
               "DvsUi", "dvpg.create.properties.vlan.type.none"),
            val: this.networkUiConstants.Vlan.Type.NONE
         }, {
            label: i18nService.getString(
               "DvsUi", "dvpg.create.properties.vlan.type.vlan"),
            val: this.networkUiConstants.Vlan.Type.VLAN
         }, {
            label: i18nService.getString(
               "DvsUi", "dvpg.create.properties.vlan.type.trunk"),
            val: this.networkUiConstants.Vlan.Type.TRUNK
         }, {
            label: i18nService.getString(
               "DvsUi", "dvpg.create.properties.vlan.type.private"),
            val: this.networkUiConstants.Vlan.Type.PRIVATE
         }];

         this.setNumPortsValidator();
         this.setVlanIdValidator();

         this.onVlanIdChange = function (this: DvpgGeneralPropertiesPageController) {
            this.setVlanIdValidator();
         };

         this.onNumPortsChange = function (this: DvpgGeneralPropertiesPageController) {
            this.setNumPortsValidator();
         };

         this.$wizardScope.$watch((): number => {
            return this.$wizardScope.model.numPorts;
         }, (): void => {
            if (!this.$wizardScope.model.defaultNumPortsChanged) {
               let defaultNumPorts: number = this.$wizardScope.model.autoExpand ?
                  this.wizardConstants.numPorts.DEFAULT_NUMBER_OF_PORTS_ELASTIC :
                  this.wizardConstants.numPorts.DEFAULT_NUMBER_OF_PORTS_FIXED;
               this.$wizardScope.model.defaultNumPortsChanged =
                  this.$wizardScope.model.numPorts !== defaultNumPorts;
            }
         });

         this.$wizardScope.$watch((): boolean => {
            return this.$wizardScope.model.autoExpand;
         }, (): void => {
            if (!this.$wizardScope.model.defaultNumPortsChanged) {
               if (this.$wizardScope.model.autoExpand) {
                  this.$wizardScope.model.numPorts =
                     this.wizardConstants.numPorts.DEFAULT_NUMBER_OF_PORTS_ELASTIC;
               } else {
                  this.$wizardScope.model.numPorts =
                     this.wizardConstants.numPorts.DEFAULT_NUMBER_OF_PORTS_FIXED;
               }
            }
         });

         this.$wizardScope.$watch((): string => {
            return this.$wizardScope.model.vlanType;
         }, (newType, oldType): void => {
            if (oldType !== newType) {
               this.clearVlanSettings();

               if (newType === this.networkUiConstants.Vlan.Type.VLAN) {
                  this.$wizardScope.model.vlanId = this.networkUiConstants.Vlan.Id.MIN;
                  this.setVlanIdValidator();
               } else if (newType === this.networkUiConstants.Vlan.Type.PRIVATE) {
                  if (this.$wizardScope.model.pvlanConfig &&
                        this.$wizardScope.model.pvlanConfig.length > 0) {
                     this.$wizardScope.model.pvlanId =
                           this.$wizardScope.model.pvlanConfig[0].secondaryVlanId;
                  }
               }
            }
         });
      }

      private initModel(): void {
         this.$wizardScope.model.portBinding =
               this.wizardConstants.portBinding.EARLY_BINDING;
         this.$wizardScope.model.autoExpand =
               this.wizardConstants.portAllocation.DEFAULT_AUTO_EXPAND;
         this.$wizardScope.model.numPorts =
               this.wizardConstants.numPorts.DEFAULT_NUMBER_OF_PORTS_ELASTIC;
         this.$wizardScope.model.defaultNumPortsChanged = false;
         this.$wizardScope.model.vlanType = this.networkUiConstants.Vlan.Type.NONE;
         this.$wizardScope.model.vlanId = 0;
         this.$wizardScope.model.pvlanId = 0;
      }

      private clearVlanSettings(): void {
         this.$wizardScope.model.vlanTrunkRanges =
               this.networkUiConstants.Vlan.DEFAULT_VLAN_TRUNK_RANGE;
         this.$wizardScope.model.vlanTrunkNumericRanges = null;
         this.$wizardScope.model.vlanId = 0;
      }

      private setVlanIdValidator(): void {
         let message: string = this.dvpgPropertiesService.getVlanIdError(
               this.$wizardScope.model.vlanId);

         this.vlanIdValidator.message = message;
         this.vlanIdValidator.isVisible = message !== null;
      }

      private setNumPortsValidator(): void {
         let message: string = this.dvpgPropertiesService.getNumPortsError(
               this.$wizardScope.model.numPorts);

         this.numPortsValidator.message = message;
         this.numPortsValidator.isVisible = message !== null;
      }

      private requestProperties(): void {
         this.$wizardScope.wizardConfig.loading = true;

         this.dataService.getProperties(
            this.$wizardScope.model.dvsId,
            [this.wizardConstants.properties.DVPG_PROPERTIES,
               DvpgGeneralPropertiesPageController.POLICIES_PROPERTY])
            .then((response: any): void => {
               let dvpgProperties: any = response[
                     this.wizardConstants.properties.DVPG_PROPERTIES];
               this.$wizardScope.model.isNetworkResourcePoolSupported =
                     dvpgProperties.isNetworkResourcePoolSupported;
               this.$wizardScope.model.isVmVnicResourcePoolSupported =
                     dvpgProperties.isVmVnicResourcePoolSupported;

               this.$wizardScope.model.networkResourcePools =
                     dvpgProperties.networkResourcePools;

               this.$wizardScope.model.networkResourcePoolKey =
                     dvpgProperties.networkResourcePoolKey;

               this.$wizardScope.model.pvlanConfig = dvpgProperties.pvlanConfig;
               this.$wizardScope.model.showPvlanError = !dvpgProperties.pvlanConfig ||
                     dvpgProperties.pvlanConfig.length === 0;

               this.networkResourcePoolOptions =
                     this.dvpgGeneralPropertiesPageService.getNetworkResourcePoolsOptions(
                           this.$wizardScope.model.networkResourcePools);
               this.pvlanTypeOptions =
                     this.dvpgGeneralPropertiesPageService.getPvlanOptions(
                           this.$wizardScope.model.pvlanConfig);

               this.initPoliciesConfiguration(response[DvpgGeneralPropertiesPageController.POLICIES_PROPERTY]);

               this.$wizardScope.flags.isGeneralPropertiesPageInitialized = true;
               this.$wizardScope.wizardConfig.loading = false;
            });
      }

      private initPoliciesConfiguration(policiesModel: CreateDvPortgroupPoliciesModel) {
         this.$wizardScope.model.securityPolicyModel = policiesModel.securityPolicyModel;
         this.$wizardScope.model.trafficShapingPolicyModel = policiesModel.trafficShapingPolicyModel;
         this.$wizardScope.model.monitoringPolicyModel = policiesModel.monitoringPolicyModel;
         this.$wizardScope.model.miscPolicyModel = policiesModel.miscPolicyModel;

         this.$wizardScope.model.failoverPolicyModel = policiesModel.failoverPolicyModel;
         this.$wizardScope.model.failoverOrderOptions =
               this.dvpgFailoverPolicyPageService.createFailoverOrderSettings(
                     this.$wizardScope.model.failoverPolicyModel.failoverOrder);
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .controller("DvpgGeneralPropertiesPageController",
               DvpgGeneralPropertiesPageController);
}
