angular.module('com.vmware.vsphere.client.vm').service('compatibilityCheckService', [
   'mutationService',
   'creationTypeConstants',
   'defaultUriSchemeUtil',
   'managedEntityConstants',
   'storageProfileService',
   function(mutationService,
         creationTypeConstants,
         defaultUriSchemeUtil,
         managedEntityConstants,
         storageProfileService) {
      return {
         validate: validate,
         validateComputeResource: validateComputeResource,
         validateVmPlacement: validateVmPlacement,
         validateVmPlacementForMultipleVms: validateVmPlacementForMultipleVms,
         validateVmPlacementForMultipleVmsNoFilter: validateVmPlacementForMultipleVmsNoFilter
      };

      function validate(targetId, folderId, provisioningType, vmName, vmRef,
            storageId, resPoolId, disks) {
         var spec = createCompatibilityCheckSpec(targetId, folderId, provisioningType,
               vmName, storageId, resPoolId);
         var storageRef = defaultUriSchemeUtil.getManagedObjectReference(storageId);
         if (storageRef.type === managedEntityConstants.DATASTORE && disks && disks.length) {
            spec.disks = disks;
         }

         var objectId = getTarget(provisioningType, targetId, vmRef);
         return mutationService.validate(objectId,
               'com.vmware.vsphere.client.vm.util.CompatibilityCheck', spec);
      }

      function validateComputeResource(targetId, folderId, provisioningType, vmName, vmRef, storageRefId) {
         var spec = createCompatibilityCheckSpec(targetId, folderId, provisioningType,
               vmName, storageRefId);

         var objectId = getTarget(provisioningType, targetId, vmRef);
         return mutationService.validate(objectId,
               'com.vmware.vsphere.client.vmui.provisioning.ComputeResourceCompatibilityCheckSpec',
               spec);
      }

      function validateVmPlacement(storageResourceUrn, selectedStorageProfileUniqueId) {
         return validateVmPlacementForMultipleVms([{
            storageId: storageResourceUrn,
            profile: {
               id: selectedStorageProfileUniqueId
            }
         }]);
      }

      function createCompatibilityCheckSpec(targetId, folderId,
            provisioningType, vmName, storageId, resPoolId) {
         var folderRef = null;
         if (folderId) {
            folderRef = defaultUriSchemeUtil.getManagedObjectReference(folderId);
         }

         var spec = {
            targetRef: defaultUriSchemeUtil.getManagedObjectReference(targetId),
            folderRef: folderRef,
            vmProvisioningType: provisioningType,
            vmName: vmName
         };
         if(resPoolId) {
            _.extend(spec, {
               resPoolRef:  defaultUriSchemeUtil.getManagedObjectReference(resPoolId)
            });
         }

         if (storageId) {
            var storageRef = defaultUriSchemeUtil.getManagedObjectReference(storageId);
            if (storageRef.type === managedEntityConstants.STORAGE_POD) {
               spec.podSelectionSpec = {
                  _type: 'com.vmware.vim.binding.vim.storageDrs.PodSelectionSpec',
                  storagePod: storageRef
               };
            } else if (storageRef.type === managedEntityConstants.DATASTORE) {
               spec.datastoreRef = storageRef;
            }
         }

         return spec;
      }

      function getTarget(provisioningType, targetId, vmRef) {
         var objectId;
         if (provisioningType === creationTypeConstants.CREATE_FROM_SCRATCH
               || provisioningType === creationTypeConstants.DEPLOY_VM_FROM_VAPP
               || provisioningType === creationTypeConstants.DEPLOY_VM_FROM_TEMPLATE
               || provisioningType === creationTypeConstants.DEPLOY_VM_FROM_OVF) {
            objectId = targetId;
         } else {
            objectId = vmRef;
         }
         return objectId;
      }

      function validateVmPlacementForMultipleVms(assignmentsWithProfiles) {
         var vmPlacementSpecs = [];

         _.each(assignmentsWithProfiles, function(vmStorageAssignment) {
            var dsParts = vmStorageAssignment.storageId ?
                  defaultUriSchemeUtil.getPartsFromVsphereObjectId(vmStorageAssignment.storageId) :
                  undefined;
            var profileId = null;
            if (vmStorageAssignment.profile.id && vmStorageAssignment.profile.id !== storageProfileService.DEFAULT_ID) {
               profileId = {
                  _type: 'com.vmware.vim.binding.pbm.profile.ProfileId',
                  uniqueId: vmStorageAssignment.profile.id
               };
            }
            var vmPlacementSpec = {
               profileId: profileId,
               datastore: dsParts ? {
                  type: dsParts.type,
                  value: dsParts.value,
                  serverGuid: dsParts.serverGuid
               } : undefined
            };

            vmPlacementSpecs.push(vmPlacementSpec);
         });

         var url = 'com.vmware.vsphere.client.spbm.spec.VmPlacementSpec';
         return mutationService.validateMultiSpec(url, vmPlacementSpecs).then(function(res) {
            var errors = [];
            var warnings = [];
            var initErrors = [];
            _.each(res, function(response) {
               if (response.error && !containsError(initErrors, response.error)) {
                  initErrors = initErrors.concat(response.error);
               }
               _.each(response.result, function(result) {
                  if (result.error) {
                     errors = errors.concat(result.error);
                  }
                  if (result.warning) {
                     warnings = warnings.concat(result.warning);
                  }
               });
            });
            errors = initErrors.concat(errors);
            return {
               errors: _.pluck(errors, 'localizedMessage'),
               warnings: warnings
            };
         });
      }

      /**
       * Validates assignments for multiple vms
       * @param assignmentsWithProfiles
       * @returns object containing arrays for errors and warnings.
       * Each outer array contains array with issues for each assignment sent to
       * the function.
       * If there is no issues, the nested array is empty.
       *    {
               errors: [
                  [],  - array with error objects for the first assignment from the assignmentsWithProfiles
                  [],  - array with error objects for the second assignment from the assignmentsWithProfiles
                  ...
               ],
               warnings: [
                  [],  - array with warning objects for the first assignment from the assignmentsWithProfiles
                  [],  - array with warning objects for the second assignment from the assignmentsWithProfiles
                  ...
               ]
            };
       *
       */
      function validateVmPlacementForMultipleVmsNoFilter(assignmentsWithProfiles) {
         var vmPlacementSpecs = _.map(assignmentsWithProfiles, function(vmStorageAssignment) {
            var dsRef = vmStorageAssignment.storageId ?
                  defaultUriSchemeUtil.getManagedObjectReference(vmStorageAssignment.storageId) :
                  undefined;
            var profileId = null;
            if (vmStorageAssignment.profile.id &&
                  vmStorageAssignment.profile.id !== storageProfileService.DEFAULT_ID) {
               profileId = {
                  _type: 'com.vmware.vim.binding.pbm.profile.ProfileId',
                  uniqueId: vmStorageAssignment.profile.id
               };
            }
            return {
               profileId: profileId,
               datastore: dsRef
            };
         });

         var specType = 'com.vmware.vsphere.client.spbm.spec.VmPlacementSpec';
         return mutationService.validateMultiSpec(specType, vmPlacementSpecs).then(function(res) {
            var errors = [];
            var warnings = [];
            _.each(res, function(response) {
               var currentVmErrors = [];
               var currentVmWarnings = [];
               if (response.error && !containsError(currentVmErrors, response.error)) {
                  currentVmErrors = currentVmErrors.concat(response.error);
               }
               _.each(response.result, function(result) {
                  if (result.error) {
                     currentVmErrors = currentVmErrors.concat(result.error);
                  }
                  if (result.warning) {
                     currentVmWarnings = currentVmWarnings.concat(result.warning);
                  }
               });
               errors.push(currentVmErrors);
               warnings.push(currentVmWarnings);
            });
            return {
               errors: errors,
               warnings: warnings
            };
         });
      }

      function containsError(errorList, error) {
         return _.any(errorList, function(element) {
            return element.localizedMessage === error.localizedMessage;
         });
      }
   }
]);
