/* Copyright 2018 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace dvs_ui {

   export class VmkDetailsDialogNetworkConnectionViewComponent {

      public bindings: any;
      public controller: any;
      public template: string;

      constructor() {
         this.bindings = {
            data: "<"
         };

         this.controller = VmkDetailsDialogNetworkConnectionViewComponentController;

         this.template = `<vx-property-view class="network-ui-fixed-size-vx-property-view vmk-details-dialog-network-connection-view"
                                            data="$ctrl.propertyViewData"/>`;
      }
   }

   class VmkDetailsDialogNetworkConnectionViewComponentController {

      static $inject = [
         "vxPropertyViewService",
         "i18nService"
      ];

      data: any;

      propertyViewData: any;

      constructor(private propertyViewService: any, private i18nService: any) {}

      $onInit(): void {
         this.propertyViewData = this.buildPropertyViewData();
      }

      private buildPropertyViewData(): any {
         const builder: any = this.propertyViewService.createPropertyViewBuilder();

         const categoryBuilder: any = builder.category("");

         if (!!this.data) {
            const sectionBuilder: any = categoryBuilder
                  .section("networkConnection")
                  .title(this.getString("switchdiagram.nic.details.networkConnection"))
                  .property(
                        this.getString("switchdiagram.nic.details.portId"),
                        this.data.portId);

            const propertyBuilder: any = sectionBuilder.propertyBuilder();

            const stateIconAndLabel: { icon: string, label: string }
                  = this.getStateIconAndLabel(this.data.state);
            propertyBuilder
                  .keyBuilder()
                  .text(this.getString("switchdiagram.nic.details.state"));
            propertyBuilder
                  .valueBuilder()
                  .icon(stateIconAndLabel.icon)
                  .text(stateIconAndLabel.label);

            if (!!this.data.mac) {
               sectionBuilder.property(
                     this.getString("switchdiagram.nic.details.macRuntime"),
                     this.data.mac);
            }
         }

         return builder.build();
      }

      private getStateIconAndLabel(portState: string): { icon: string, label: string } {
         let icon: string = "";
         let label: string = "";

         if (portState === "LINK_UP") {
            icon = "vsphere-icon-port_green";
            label = this.getString("PortState.linkUp");
         } else if (portState === "LINK_DOWN") {
            icon = "vsphere-icon-port_white";
            label = this.getString("PortState.linkDown");
         } else if (portState === "BLOCKED") {
            icon = "vsphere-icon-port_blocked";
            label = this.getString("PortState.blocked");
         }

         return { icon: icon, label: label };
      }

      private getString(key: string): string {
         return this.i18nService.getString("DvsUi", key);
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .component("vmkDetailsDialogNetworkConnectionView",
               new VmkDetailsDialogNetworkConnectionViewComponent());
}
