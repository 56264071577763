/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace h5_vm {

export class SelectComputeResourceTreePageModel {
   /**
    * [input]
    *
    * The VMs being migrated.
    */
   public vms: Array<string> = [];
   /**
    * [input]
    *
    * Flag indicating if cross vc migration is possible.
    */
   public isXvcMigrationPossible: boolean;

   /**
    * [input]
    *
    * The compute resource id to preselect when the page is loaded for first time.
    */
   public initialResource?: string;

   /**
    * [output]
    *
    * Id of the datacenter of the selected compute resource
    */
   public datacenter?: string;

   /**
    * [page internal]
    *
    * The id of the resource selected by the user on this page. Can be
    * Cluster, Host, Resource Pool, VApp
    */
   public resource?: string;

   /**
    * [page internal]
    *
    * Is the validation of the compute resource in progress
    */
   public isValidating?: boolean;
} // class
} // module
