namespace spbm_ui {

   export class SpbmRemoveProfileActionService {
      static $inject = ['$injector'];

      private _vcSpbmRemoveProfileActionService: SpbmRemoveProfileActionService;

      constructor(private $injector: any) {
      }

      get vcSpbmRemoveProfileActionService() {
         if (!this._vcSpbmRemoveProfileActionService) {
            this._vcSpbmRemoveProfileActionService = this.$injector.get('vcSpbmRemoveProfileActionService');
         }
         return this._vcSpbmRemoveProfileActionService;
      }

      removeStoragePolicies(storagePolicies: string[]): void {
         return this.vcSpbmRemoveProfileActionService.removeStoragePolicies(storagePolicies);
      }
   }

   angular.module("com.vmware.vsphere.client.spbm")
         .service("spbmRemoveProfileActionService", SpbmRemoveProfileActionService);
}
