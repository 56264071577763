namespace storage_ui {

   import IScope = angular.IScope;

   export class AllocateSpaceController {
      public static $inject = ["i18nService", "$scope"];

      private size: any;

      private sliderSize: any;

      constructor(private i18nService: any,
                  private $scope: IScope) {

      }

      $onInit(): void {
         this.$scope.$watch(() => {
            return this.size.allocatedSpace;
         }, (newValue) => {
            if (newValue === undefined || newValue > this.size.maxSize) {
               this.size.allocatedSpace = this.size.maxSize;
            }

            // update slider
            this.sliderSize = Math.round(this.size.allocatedSpace);
         });
      }

      onSliderSizeChanged(sliderSize: any): void {
         this.size.allocatedSpace = sliderSize;
      }
   }

   angular.module("com.vmware.vsphere.client.storage")
         .component("allocateSpace", {
            templateUrl: "storage-ui/resources/storage/views/host/swapToSsd/AllocateSpaceViewComponent.html",
            bindings: {
               size: "<"
            },
            controller: AllocateSpaceController
         });
}
