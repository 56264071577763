namespace h5_vm {
   import CustomizeGosSpecData = com.vmware.vsphere.client.h5.vm.data.CustomizeGosSpecData;

   class VmProvisioningCloneOptionsPageModelFactory {
      public static $inject = [
         "dataService",
         "i18nService",
         "customizeGosPreCheckSpecsService",
         "$q",
         "defaultUriSchemeUtil"
      ];

      constructor(dataService: any,
            i18nService: any,
            customizeGosPreCheckSpecsService: CustomizeGosPreCheckSpecsService,
            $q: any,
            defaultUriSchemeUtil: any
      ) {
         return (virtualMachineSpecBuilder: any, wizardViewData: any, wizardConfig: any) => {
            return new VmProvisioningCloneOptionsPageModel(
                  dataService,
                  i18nService,
                  customizeGosPreCheckSpecsService,
                  $q,
                  defaultUriSchemeUtil,
                  virtualMachineSpecBuilder,
                  wizardViewData,
                  wizardConfig);
         };
      }
   }

   export class VmProvisioningCloneOptionsPageModel {
      static $inject: string[] = ["dataService", "i18nService", "customizeGosPreCheckSpecsService", "$q", "defaultUriSchemeUtil"];
      private _virtualMachineSpecBuilder: any;
      private _wizardViewData: any;
      private _wizardConfig: any;
      public form: any = {};
      private originalCustomizeOs: boolean;
      private originalCustomizeHw: boolean;

      private readonly CUSTOMIZE_OS_RESPONSE: string = "customizeOsResponse";
      private readonly POWER_ON_RESPONSE: string = "powerOnResponse";

      constructor(private dataService: any,
            private i18nService: any,
            private customizeGosPreCheckSpecsService: CustomizeGosPreCheckSpecsService,
            private $q: any,
            private defaultUriSchemeUtil: any,
            virtualMachineSpecBuilder: any,
            wizardViewData: any,
            wizardConfig: any) {
         this._virtualMachineSpecBuilder = virtualMachineSpecBuilder;
         this._wizardViewData = wizardViewData;
         this._wizardConfig = wizardConfig;
      }

      get virtualMachineSpecBuilder(): any {
         return this._virtualMachineSpecBuilder;
      }

      get wizardViewData(): any {
         return this._wizardViewData;
      }

      get wizardConfig(): any {
         return this._wizardConfig;
      }

      public resetForm(): void {
         this.wizardConfig.loadingMessage = this.i18nService
               .getString("CommonUi", "wizard.validating");
         let suggestedCustomizeOs: boolean = !_.isNull(this.form.customizeOs) && !_.isUndefined(this.form.customizeOs) ?
               this.form.customizeOs :
               false;

         let suggestedPowerOn: boolean = !_.isNull(this.form.powerOn) && !_.isUndefined(this.form.powerOn) ?
               this.form.powerOn :
               false;

         let customizeHw: boolean =
               this.virtualMachineSpecBuilder.getCustomizeCloneHardware() || false;
         let suggestedCustomizeHw: boolean = this.form.customizeHw
               ? this.form.customizeHw
               : customizeHw;

         this.form.powerOn = suggestedPowerOn;
         this.form.customizeOs = suggestedCustomizeOs;
         this.form.customizeHw = suggestedCustomizeHw;

         this.originalCustomizeOs = this.form.customizeOs;
         this.originalCustomizeHw = this.form.customizeHw;
      }

      public validatePage(): any {
         let promises: any = {};
         let vmId: string = this.virtualMachineSpecBuilder.getVmId();
         if (this.form.customizeOs) {
            this.wizardConfig.loading = true;
            const vCenterId: string = this.defaultUriSchemeUtil.getRootFolderFromVsphereObjectId(
                  this.virtualMachineSpecBuilder.getTargetInformation().datacenterUid);
            promises[this.CUSTOMIZE_OS_RESPONSE] = this.customizeGosPreCheckSpecsService
                  .cloneCustomizeGosLoadData(vmId, true, vCenterId);
         }

         if (this.form.powerOn) {
            this.wizardConfig.loading = true;
            promises[this.POWER_ON_RESPONSE] = this.virtualMachineSpecBuilder.getPrivileges();
         }

         if (_.isEmpty(promises)) {
            return undefined;
         }
         return this.$q.all(promises)
               .then((response: {customizeOsResponse: CloneCustomizeGosSpecData, powerOnResponse: string[]}): any => {
                  this.wizardConfig.loading = false;
                  let error: string = "";
                  // if the `VirtualMachine.Interact.PowerOn` privilege is missing disable power on option
                  if (this.form.powerOn && _.indexOf(response.powerOnResponse, Privileges.VM_INTERACT_POWER_ON) === -1) {
                     error = this.i18nService.getString("VmUi",
                           "CustomizeGuestOsProvisioningPage.content.no_permissions_power_on");
                     if (error) {
                        return {error: error};
                     }
                  }

                  if (this.form.customizeOs) {
                     let data: CustomizeGosSpecData = response.customizeOsResponse.data;
                     error = response.customizeOsResponse.error;

                     if (error) {
                        return {error: error};
                     }
                     this.wizardViewData.setCustomizeGosSpecData(data);
                  }
                  return {};
               });

      }

      public submitPage(): any {
         this.virtualMachineSpecBuilder.setCustomizeCloneHardware(this.form.customizeHw);
         this.virtualMachineSpecBuilder.setCloneOptions({
            powerOn: this.form.powerOn,
            customizeOs: this.form.customizeOs
         });

         let osToBeCustomized = !this.originalCustomizeOs && this.form.customizeOs;
         let hwToBeCustomized = !this.originalCustomizeHw && this.form.customizeHw;

         let invalidateNextPages = osToBeCustomized || hwToBeCustomized;

         return {
            wizardFlow: this.virtualMachineSpecBuilder.getCreationType(),
            invalidateNextPages: invalidateNextPages
         };
      }
   }
   angular.module("com.vmware.vsphere.client.vm")
         .service("VmProvisioningCloneOptionsPageModel",
               VmProvisioningCloneOptionsPageModelFactory);
}
