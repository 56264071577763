namespace dvs_ui {

   import ValidationResult = com.vmware.vise.core.model.ValidationResult;
   export class DvsCommonPropertiesValidator {

      public static $inject = [
         "i18nService",
         "mutationService"
      ];

      public static NAME_MAX_LENGTH: number = 80;

      constructor(protected i18nService: any,
                  protected mutationService: any) {

      }

      public getNameError(name: string): any {
         let trimmedName: string = name.trim();
         let escapedName: string = trimmedName.replace(/%/g, "%25")
               .replace(/\//g, "%2f").replace(/\\/g, "%5c");

         if (escapedName.length === 0) {
            return this.i18nService.getString("DvsUi",
                  "DvsName.validation.noDvsName");
         } else if (escapedName.length > DvsCommonPropertiesValidator.NAME_MAX_LENGTH) {
            return this.i18nService.getString("DvsUi",
                  "DvsName.validation.tooLongDvsName");
         }

         return null;
      }

      public getNameServerError(urn: string, name: string): any {
         return this.mutationService.validate(urn,
               "com.vmware.vsphere.client.h5.network.dvs.editsettings.model.DvsNameSpec",
               {
                  _type: "com.vmware.vsphere.client.h5.network.dvs.editsettings.model.DvsNameSpec",
                  name: name.trim()
               })
               .then(function (validationResult: ValidationResult) {

                  if (validationResult.error && validationResult.error.localizedMessage) {
                     return validationResult.error.localizedMessage;
                  }

                  if (validationResult.result) {
                     return validationResult.result;
                  }

                  return null;
               }, function () {
                  return null;
               });
      }

      public getNumberOfUplinksError(numUplinks: number, maxNumUplinks: number = 32): any {
         let isPositiveInteger: boolean
               = (numUplinks !== null && numUplinks !== undefined)
                  && new RegExp("^[0-9]+$").test(numUplinks.toString());

         if (numUplinks < 1 || typeof numUplinks !== "number" || !isPositiveInteger) {
            return this.i18nService.getString("DvsUi",
                  "DvsEditSettingsDialog.generalPage.numUplinks.error.min");
         }

         if (numUplinks > maxNumUplinks) {
            return this.i18nService.getString("DvsUi",
                  "DvsEditSettingsDialog.generalPage.numUplinks.error.max", maxNumUplinks);
         }

         return null;
      }

   }

   angular.module("com.vmware.vsphere.client.dvs")
         .service("dvsCommonPropertiesValidator", DvsCommonPropertiesValidator);
}
