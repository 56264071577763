/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace dvs_ui {

   import IPromise = angular.IPromise;

   import DvsSpanSessionPortListData =
         com.vmware.vsphere.client.h5.network.dvs.portmirroring.model.portlist.DvsSpanSessionPortListData;
   import DvsSpanSessionPortListSpec =
         com.vmware.vsphere.client.h5.network.dvs.portmirroring.model.portlist.DvsSpanSessionPortListSpec;
   import DvsSpanSessionPortListItemData = com.vmware.vsphere.client.h5.network.dvs.portmirroring.model.portlist.DvsSpanSessionPortListItemData;

   export class DvsSpanSessionPortsListService {

      public static $inject = [
            "$q",
            "i18nService",
            "vuiConstants",
            "dataService"
      ];

      public static DATA_PROPERTY: string = "dvswitchportmirroring:portListData";

      public getString: (key: string) => string;

      constructor(private $q: any,
                  private i18nService: any,
                  private vuiConstants: any,
                  private dataService: any) {
         this.getString = (key: string): string => {
            return this.i18nService.getString("DvsUi", key);
         };
      }

      public createDatagridOptions(actions: any[], onChange: Function,
            trafficDirectionEnabled: boolean): any {
         return {
            height: "100%",
            pageConfig: {
               hidePager: true
            },
            columnDefs: this.getColumnDefinitions(trafficDirectionEnabled),
            sortMode: this.vuiConstants.grid.sortMode.SINGLE,
            selectionMode: this.vuiConstants.grid.selectionMode.MULTI,
            showCheckboxesOnMultiSelection: false,
            selectedItems: [],
            data: [],
            resizable: true,
            actionBarOptions: {actions: actions},
            onChange: onChange
         };
      }

      public getData(dvsUrn: string, portIds: string[]): IPromise<DvsSpanSessionPortListData> {
         let spec: DvsSpanSessionPortListSpec =
               new DvsSpanSessionPortListSpec();

         spec.portIds = portIds;

         let params: any = {
            propertyParams: [{
               propertyName: DvsSpanSessionPortsListService.DATA_PROPERTY,
               parameterType: spec._type,
               parameter: spec
            }]
         };

         if (portIds && portIds.length > 0) {
            return this.dataService.getProperties(
                  dvsUrn,
                  [DvsSpanSessionPortsListService.DATA_PROPERTY],
                  params).then((resp: any): DvsSpanSessionPortListData => {
               let data: DvsSpanSessionPortListData =
                     resp[DvsSpanSessionPortsListService.DATA_PROPERTY];

               return data;
            });
         } else {
            let emptyData: DvsSpanSessionPortListData =
                  new DvsSpanSessionPortListData();
            emptyData.items = [];
            return this.$q.when(emptyData);
         }
      }

      private getColumnDefinitions(trafficDirectionEnabled: boolean): any[] {
         let columns: any[] = [];

         columns.push({
            displayName: this.getString(
                  "AddSpanSessionWizard.sourcesPage.PortList.port"),
            field: "portId",
            width: "90px",
            template: this.getGenericColumnRenderer("portId")
         });

         columns.push({
            displayName: this.getString(
                  "AddSpanSessionWizard.sourcesPage.PortList.host"),
            field: "host",
            width: "150px",
            template: this.getHostColumnRenderer()
         });

         columns.push({
            displayName: this.getString(
                  "AddSpanSessionWizard.sourcesPage.PortList.connectee"),
            field: "connectee",
            width: "150px",
            template: this.getConnectedEntityColumnRenderer()
         });

         if (trafficDirectionEnabled) {
            columns.push({
               displayName: this.getString(
                     "AddSpanSessionWizard.sourcesPage.PortList.direction"),
               field: "trafficDirection",
               width: "180px",
               template: this.getTrafficDirectionColumnRenderer()
            });
         }

         return columns;
      }

      private getGenericColumnRenderer(property: string): ((item: DvsSpanSessionPortListItemData) => string) {
         return (data: DvsSpanSessionPortListItemData): string => {
            return this.buildCellHtml((data as any)[property]);
         };
      }

      private getConnectedEntityColumnRenderer(): ((item: DvsSpanSessionPortListItemData) => string) {
         return (data: DvsSpanSessionPortListItemData): string => {
            if (data.connecteeType === "vmVnic") {
               return this.buildCellHtml(data.connectee, "vsphere-icon-vm");
            } else if (data.connecteeType === "hostVmkVnic") {
               return this.buildCellHtml(data.connectee, "network-lib-ui-icon-virtualAdapter");
            } else {
               return this.buildCellHtml(data.connectee);
            }
         };
      }

      private getHostColumnRenderer(): ((item: DvsSpanSessionPortListItemData) => string) {
         return (data: DvsSpanSessionPortListItemData): string => {
            let icon: string = data.host ? "vsphere-icon-host" : "";
            return this.buildCellHtml(data.host, icon);
         };
      }

      private getTrafficDirectionColumnRenderer(): ((item: DvsSpanSessionPortListItemData) => string) {
         return (data: DvsSpanSessionPortListItemData): string => {
            let label: string;
            let icon: string;
            switch (data.trafficDirection) {
               case DvsSpanSessionConstants.TRAFFIC_DIRECTION.BOTH: {
                  label = this.getString("NetworkTrafficRuleDirection.ingressEgress");
                  icon = "network-lib-ui-icon-ingressEgressTraffic";
                  break;
               }
               case DvsSpanSessionConstants.TRAFFIC_DIRECTION.INGRESS: {
                  label = this.getString("NetworkTrafficRuleDirection.ingress");
                  icon = "network-lib-ui-icon-ingressTraffic";
                  break;
               }
               case DvsSpanSessionConstants.TRAFFIC_DIRECTION.EGRESS: {
                  label = this.getString("NetworkTrafficRuleDirection.egress");
                  icon = "network-lib-ui-icon-egressTraffic";
                  break;
               }
               default: {
                  label = "";
                  icon = "";
               }
            }
            return this.buildCellHtml(label, icon);
         };
      }

      private buildCellHtml(text: string = DvsSpanSessionPortBrowserService.NO_DATA,
            icon: string = ""): string {
         let template: string = "<div>%icon%text</div>";

         return template
               .replace("%icon", "<span class='" + icon + "'></span>")
               .replace("%text", "<span>" + text + "</span>");
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .service("dvsSpanSessionPortsListService",
               DvsSpanSessionPortsListService);
}
