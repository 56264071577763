module hostprofile_ui {

    export interface HostProfileComplianceService {
        getFormattedDateTime(data: any): any;
        getStatuses(): any;
        checkCompliance(hostId: any, hostProfile: any): any;
    }

    class HostProfileComplianceServiceImpl implements HostProfileComplianceService {
        static $inject = ["$controller", "i18nService",
            "timeFormatterService", "mutationService",
            "dataService", "portletActionBarService"];

        constructor(private $controller: any,
                    private i18nService: any,
                    private timeFormatterService: any,
                    private mutationService: any,
                    private dataService: any,
                    private portletActionBarService: any) {
        }

        public getFormattedDateTime(data: any): any {
            return this.timeFormatterService.timestampToText(data);
        };

        public getStatuses(): any {
            return {
                "unknown": {
                    label: this.getString("hostComplianceStatus.unknown"),
                    iconClass: "vsphere-icon-status-unknown"
                },
                "compliant": {
                    label: this.getString("hostComplianceStatus.compliant"),
                    iconClass: "vx-icon-statusCompliant"
                },
                "nonCompliant": {
                    label: this.getString("hostComplianceStatus.notCompliant"),
                    iconClass: "vx-icon-statusNotCompliant"
                },
                "running": {
                    label: this.getString("hostComplianceStatus.running"),
                    iconClass: "spinner spinner-inline"
                },
                "precheckRemediationRunning": {
                    label: this.getString("hostComplianceStatus.precheckRemediationRunning"),
                    iconClass: "spinner spinner-inline"
                },
                "precheckRemediationComplete": {
                    label: this.getString("hostComplianceStatus.precheckRemediationComplete"),
                    iconClass: ""
                },
                "precheckRemediationFailed": {
                    label: this.getString("hostComplianceStatus.precheckRemediationFailed"),
                    iconClass: "vx-icon-statusNotCompliant"
                },
                "remediationRunning": {
                    label: this.getString("hostComplianceStatus.remediationRunning"),
                    iconClass: "spinner spinner-inline"
                },
                "remediationFailed": {
                    label: this.getString("hostComplianceStatus.remediationFailed"),
                    iconClass: "vx-icon-statusNotCompliant"
                }
            };
        }

        private getString(key: string): string {
            return this.i18nService.getString("HostProfileUi", key);
        }

        public checkCompliance(hostIds: string[], hostProfile: any): void {
            this.mutationService.applyOnMultiEntity(
                hostIds,
                "com.vmware.vsphere.client.hostprofile.data.HostProfileComplianceCheckSpec",
                {hostProfile: hostProfile},
                this.getString("actionCheckHostProfileCompliance"));
        }
    }

    angular.module("com.vmware.vsphere.client.hostprofile")
        .service("HostProfileComplianceService", HostProfileComplianceServiceImpl);
}
