module cluster_ui {

   export class EditDrsFileDownloadService {

      public static $inject = ["$q", "i18nService", "clarityModalService", "dataService"];

      private FILE_EXTENSION: string = ".snapshot";
      private FILE_FORMAT: string = "application/octet-stream";


      public constructor(private $q: any,
                         private i18nService: any,
                         private clarityModalService: any,
                         private dataService: any) {

      }

      public getSnapshotTreeData(objectId: string): any {
         return this.dataService.getProperties(objectId, ["rpTreeSnapshot", "name"]);
      }

      public showYesNoCancelDialog(objectId: string): any {

         let deferred: any = this.$q.defer();

         let context: any = {
            title: this.i18nService.getString("ClusterUi", "drs.config.editDrs.drsTurningOffCaption"),
            deferred: deferred,
            objectId: objectId
         };

         this.clarityModalService.openModal({}, {}, context,
               "cluster-ui/resources/cluster/views/settings/services/drs/edit/EditDrsFileDownloadDialog.html");

         return deferred.promise;
      }


      public createFileFromData(rpSnapshotTreeData: any, clusterName: string): any {
         let byteArray: Int8Array = this.convertBase64DataToByteArray(rpSnapshotTreeData);
         let file: Blob = new Blob([byteArray], {type: this.FILE_FORMAT});
         let fileUrl: string = URL.createObjectURL(file);
         let fileName: string = clusterName + this.FILE_EXTENSION;
         return {
            fileAsBlob: file,
            fileUrl: fileUrl,
            fileName: fileName
         };
      }

      private convertBase64DataToByteArray(rpSnapshotTreeData: any): any {
         let snapshotTreeData: any =
               atob(rpSnapshotTreeData);
         let byteArray: Int8Array =
               new Int8Array(new ArrayBuffer(snapshotTreeData.length * 2));

         _.each(snapshotTreeData, (item: any, index: number) => {
            byteArray[index] = item.charCodeAt(0);
         });

         return byteArray;
      }

   }

   angular.module("com.vmware.vsphere.client.cluster")
         .service("editDrsFileDownloadService",
               EditDrsFileDownloadService);
}

