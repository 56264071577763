namespace h5_vm {

   import GosBasePageModel = h5_vm.GosBasePageModel;
   import AdapterMappingWrapper = h5_vm.AdapterMappingWrapper;
   import GosNetworkSettingsType = h5_vm.GosNetworkSettingsType;

   export class GosNetworkPageModel extends GosBasePageModel {
      type: GosType;

      networkSettingsType: GosNetworkSettingsType;

      adapterMappingWrappers: Array<AdapterMappingWrapper>;
      isNameIpGeneratorDefined: boolean;
      vcServiceGuid: string;
      dnsSuffixList: string[];

   }

}
