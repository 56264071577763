/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace dvs_ui {

   import HostCompatibilityListItem = com.vmware.vsphere.client.h5.network.dvs.upgrade.model.HostCompatibilityListItem;
   import ValidationResultType = com.vmware.vsphere.client.dvs.api.common.ValidationResultType;
   import ValidationCheckResult = com.vmware.vsphere.client.dvs.api.featureupgrade.ValidationCheckResult;

   export class DvsPrerequisitesValidationService {

      static $inject = ["dvsUpgradeLacpWizardConstants", "i18nService"];

      constructor(private wizardConstants:any,
                  private i18nService: any) {
      }

      public getPrerequisitesOverallStatus(
            validationChecksData: ValidationCheckResult[]): string {

         if (!validationChecksData || validationChecksData.length === 0) {
            return this.i18nService.getString(
                  "DvsUi", "PrerequisitesPage.overallStatus.pass");
         }

         let failedValidationCheck: ValidationCheckResult =
               _.find(validationChecksData, (item: ValidationCheckResult) => {
                  return item.type === this.wizardConstants.validationResult.FAIL;
               });

         if (failedValidationCheck !== undefined) {
            return this.i18nService.getString(
                  "DvsUi", "PrerequisitesPage.overallStatus.fail");
         }

         let warningValidationCheck: ValidationCheckResult =
             _.find(validationChecksData, (item: ValidationCheckResult) => {
                return item.type === this.wizardConstants.validationResult.WARNING;
             });

         if (warningValidationCheck !== undefined) {
            return this.i18nService.getString(
                  "DvsUi", "PrerequisitesPage.overallStatus.warn");
         }

         return this.i18nService.getString(
               "DvsUi", "PrerequisitesPage.overallStatus.pass");
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .service("dvsPrerequisitesValidationService", DvsPrerequisitesValidationService);
}
