(function () {
   'use strict';

   /**
    * Service that provides swap file location related information.
    */
   angular.module('com.vmware.vsphere.client.cluster')
      .service('clusterSwapFileLocationService', clusterSwapFileLocationService);

   clusterSwapFileLocationService.$inject = ['clusterConstants', 'i18nService',
      'dataService'];

   function clusterSwapFileLocationService(clusterConstants, i18nService, dataService) {
      return {
         WARNING: getString('general.config.swapPlacementType.hostLocal.warning'),
         getVmDirectoryData: getVmDirectoryData,
         getHostDatastoreData: getHostDatastoreData,
         getSwapFilePlacement: getSwapFilePlacement,
         getVmSwapFileLocation: getVmSwapFileLocation
      };

      /**
       * Retrieves text data pertaining to the Virtual Machine directory ('vmDirectory')
       * placement option.
       *
       * @returns {{id: string, label: *, description: *}}
       */
      function getVmDirectoryData() {
         return {
            id: clusterConstants.vmSwapPlacementType.VM_DIRECTORY,
            label: getString('general.config.swapPlacementType.vmDirectory'),
            description:
               getString('general.config.swapPlacementType.vmDirectory.description')
         };
      }

      /**
       * Returns text data pertaining to the Host Datastore ('hostLocal') placement
       * option.
       *
       * @returns {{id: string, label: *, description: *}}
       */
      function getHostDatastoreData() {
         return {
            id: clusterConstants.vmSwapPlacementType.HOST_DATASTORE,
            label: getString('general.config.swapPlacementType.hostLocal'),
            description:
               getString('general.config.swapPlacementType.hostLocal.description')
         };
      }

      function getString(key) {
         return i18nService.getString('ClusterUi', key);
      }

      /**
       * Retrieves the current vm swap placement from the data service wrapped in a
       * promise.
       *
       * @param objectId
       * @returns {*}
       */
      function getSwapFilePlacement(objectId) {
         return dataService.getData(objectId,
            'com.vmware.vsphere.client.clusterui.model.GeneralConfigData')
            .then(function (configData) {
               return configData.vmSwapPlacement;
            });
      }

      /**
       * Retrieves the text label for the specified vm swap placement.
       *
       * @param vmSwapPlacement
       * @returns {*}
       */
      function getVmSwapFileLocation(vmSwapPlacement) {
         if (vmSwapPlacement === clusterConstants.vmSwapPlacementType.VM_DIRECTORY) {
            return i18nService.getString('ClusterUi',
               'general.config.swapPlacementType.vmDirectory');
         } else if (vmSwapPlacement === clusterConstants.vmSwapPlacementType.HOST_DATASTORE) {
            return i18nService.getString('ClusterUi',
               'general.config.swapPlacementType.hostLocal');
         }
         return '';
      }
   }
}());
