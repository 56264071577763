module hostprofile_ui {
   export class CreateHostProfileService {

      static $inject = ["i18nService", "clarityModalService", "defaultUriSchemeUtil"];

      constructor(private i18nService: any,
                  private clarityModalService: any,
                  private defaultUriSchemeUtil: any) {
      }

      public openCreateHostProfileDialog(objectId: string, isExtractAction: boolean = true): void {
         let dialogTitle: string = isExtractAction ? this.i18nService.getString("HostProfileUi",
             "createProfileTask.name") : this.i18nService.getString("HostProfileUi",
             "duplicateProfileTask.name");

         let modalOptions: any = {
            title: dialogTitle,
            subTitle:  isExtractAction ? "" : {
               objectId: objectId
            },
            dialogData: {
               objectId: objectId,
               isExtractAction: isExtractAction
            },
            defaultButton: "submit",
            contentTemplate: "hostprofile-ui/resources/hostprofile/create/createHostProfile.html",
            size: "lg"
         };
         this.clarityModalService.openOkCancelModal(modalOptions);
      }

      public getCreateProfileFromHostSpec(profileName: string,
            profileDescription: string, objectId: string): Object {
         return {
            _type:"com.vmware.vim.binding.vim.profile.host.HostProfile$HostBasedConfigSpec",
            name: profileName,
            annotation: profileDescription,
            enabled: true,
            useHostProfileEngine: true,
            host: this.defaultUriSchemeUtil.getPartsFromVsphereObjectId(objectId)
         };
      }

      public getDuplicateProfileSpec(profileName: string,
            profileDescription: string, objectId: string): Object {
         return {
            _type:"com.vmware.vsphere.client.hostprofile.data.h5.DuplicateHostProfileSpec",
            profileName: profileName,
            profileDescription: profileDescription,
            profileRef: this.defaultUriSchemeUtil.getPartsFromVsphereObjectId(objectId)
         };
      }
   }

   angular.module("com.vmware.vsphere.client.hostprofile").service("createHostProfileService",
         CreateHostProfileService);
}
