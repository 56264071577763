namespace h5_client {
   export module admin_ui {

       class CreateEditRolePageService {
         static $inject = ['i18nService', 'wizardPageService'];

         constructor(private i18nService: any, private wizardPageService: any) {

         }

         public build() {
            return {
               contentUrl: 'admin-ui/resources/admin/views/wizard/pages/createEditRole.html'
            };
         }
      }
      angular.module("com.vmware.vsphere.client.admin")
            .service("createEditRolePageService", CreateEditRolePageService);
   }
}