/* Copyright 2018 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace network_ui {

   import MoveNetworkSpec = com.vmware.vsphere.client.h5.network.MoveNetworkSpec;
   export class MoveNetworkToService {

      public static $inject = [
         "clarityModalService",
         "i18nService",
         "mutationService",
         "defaultUriSchemeUtil"
      ];

      constructor(private clarityModalService: any,
                  private i18nService: any,
                  private mutationService: any,
                  private defaultUriSchemeUtil: any) {

      }

      public openMoveToDialog(availableTargets: string[]) {
         let modalOptions: any = {
            title: this.i18nService.getString("NetworkUi",
                  "moveto.dialog.title"),
            subTitle: {
               objectId: availableTargets[0]
            },
            defaultButton: 'submit',
            dialogData: {
               objectIds: availableTargets
            },
            contentTemplate: "network-ui/resources/network/views/moveTo/moveNetworkToDialog.html"
         };

         modalOptions.onSubmit = () => {
            this.moveNetworkTo(modalOptions.dialogData.objectIds,
                  modalOptions.dialogData.entityToMoveNetworkTo);
            return true;
         };

         this.clarityModalService.openOkCancelModal(modalOptions);
      }

      public moveNetworkTo(networkIds: string[], entityToMoveNetworkTo: any) {
         let networkMors: any[] = networkIds.map((networkId: string) => {
            return this.defaultUriSchemeUtil.getManagedObjectReference(networkId);
         });
         let moveSpec: MoveNetworkSpec = new MoveNetworkSpec();
         moveSpec.entities = networkMors;

         this.mutationService.apply(
               entityToMoveNetworkTo,
               moveSpec._type,
               moveSpec).then(() => {
         });
         return true;
      }
   }

   angular.module("com.vmware.vsphere.client.network")
         .service("moveNetworkToService", MoveNetworkToService);
}




