module cluster_ui {

   export class EditDrsDialogService {

      public static $inject = ["$q", "i18nService", "clarityModalService", "dataService", "EditDrsDialogManager"];

      public constructor(private $q: any,
                         private i18nService: any,
                         private clarityModalService: any,
                         private dataService: any,
                         private EditDrsDialogManager: new () => EditDrsDialogManager) {
      }

      public show(clusterId: string, focusTarget: any): void {

         let editDrsData: any = {};
         let modalOptions: any = {};
         let editDrsDialogManager = new this.EditDrsDialogManager();

         _.extend(modalOptions, {
            title: this.i18nService.getString('ClusterUi', 'editClusterSettingsDialog.title'),
            subTitle: {
               objectId: clusterId
            },
            defaultButton: 'submit',
            contentTemplate: 'cluster-ui/resources/cluster/views/settings/services/drs/edit/EditDrsDialog.html',
            size: 'lg',
            onSubmit: editDrsDialogManager.submit.bind(editDrsDialogManager),
            modalClass: 'edit-cluster-drs-dialog',
            focusTarget: focusTarget,
            dialogData: {
               editDrsData: editDrsData,
               manager: editDrsDialogManager,
               objectId: clusterId,
               pageModel: null
            }
         });

         this.clarityModalService.openOkCancelModal(modalOptions);
      }
   }

   angular.module("com.vmware.vsphere.client.cluster")
         .service("editDrsDialogService", EditDrsDialogService);
}
