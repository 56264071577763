module common_module_ui {

    import VCServerSettingConstants = common_module_ui.VCServerSettingConstants;

    export class VCServerSettingService {

        public static $inject = [
            "dataService",
            "i18nService",
            "numberFormatterService"
        ];

        private i18n: any;

        public constructor(private dataService: any,
                           private i18nService: any,
                           private numberFormatterService: any) {
            this.i18n = (key: any, value?: any) => this.i18nService.getString("FolderUi", key, value);
        }

        /**
         * Return the vC server settings using the data service
         *
         * @param {string} objectId
         *      ObjectId of the entity/object whose data is to be fetched.
         *
         * @returns {PromiseLike<TResult>}
         */
        public getServerSettings(objectId: string): angular.IPromise<any> {
            let propertyParamSpecs: any = [{
                propertyName: VCServerSettingConstants.PROP_DATABASE_STATISTICS,
                parameterType: "com.vmware.vim.binding.vim.ResourcePlanningManager$DatabaseSizeParam",
                parameter: {
                    _type: "com.vmware.vim.binding.vim.ResourcePlanningManager$DatabaseSizeParam",
                    inventoryDesc: {
                        _type: "com.vmware.vim.binding.vim.ResourcePlanningManager$InventoryDescription",
                        numHosts: VCServerSettingConstants.DEFAULT_HOST_COUNT,
                        numVirtualMachines: VCServerSettingConstants.DEFAULT_VM_COUNT
                    }
                }
            }];

            return this.dataService.getProperties(
                objectId,
                [ VCServerSettingConstants.PROP_SERVER_SETTINGS, VCServerSettingConstants.PROP_DATABASE_STATISTICS ],
                { propertyParams: propertyParamSpecs }
            ).then((settings: any) => {
                let estimatedDatabaseSize: string = this.numberFormatterService.formatStorage(
                      settings.estimatedDastabaseSize, StorageUnit.MB, StorageUnit.GB, 2);

                let settingProperties: any[] = settings.vcServerSettings.properties;
                this.parseServerSettings(settingProperties, estimatedDatabaseSize);

                return settingProperties;
            });
        }

        /**
         * Add more information to the provided settings
         *
         * @param settingProperties
         * @param estimatedDatabaseSize
         */
        private parseServerSettings(settingProperties: any, estimatedDatabaseSize: string): void {
            this.parseStatisticsSettings(settingProperties, estimatedDatabaseSize);
            this.parseDatabaseSettings(settingProperties);
            this.parseRuntimeSettings(settingProperties);
            this.parseUserDirectorySettings(settingProperties);
            this.parseMailSettings(settingProperties);
            this.parseSNMPSettings(settingProperties);
            this.parsePortsSettings(settingProperties);
            this.parseTimeoutSettings(settingProperties);
            this.parseLoggingSettings(settingProperties);
            this.parseSSLSettings(settingProperties);
        }

        private parseStatisticsSettings(settingProperties: any, estimatedDatabaseSize: string): void {
            settingProperties.unshift({
                titleLabel: this.i18n("vcStatisticsConfigPage.title"),
                titleLabelContent: this.i18n("vcStatisticsConfigPage.estimatedDbSizeLabel"),
                estimatedDatabaseSize: estimatedDatabaseSize,
                sizeLabel: this.i18nService.getString("CommonUi", "gigaByte.label")
            });
        }

        private parseDatabaseSettings(settingProperties: any): void {
            let databaseIndex: number = _.findIndex(settingProperties, { name: VCServerSettingConstants.KEY_DATABASE });
            settingProperties[databaseIndex].titleLabel =
                this.i18n("vcDatabaseConfigPage.title");
            settingProperties[databaseIndex].titleLabelContent = this.i18n("vcConfigView.dbMaxConnectionsHeaderLabel",
                settingProperties[databaseIndex].value.properties[0].value
            );
        }

        private parseRuntimeSettings(settingProperties: any): void {
            let runtimeIndex: number = _.findIndex(settingProperties, { name: VCServerSettingConstants.KEY_RUNTIME });
            settingProperties[runtimeIndex].titleLabel =
                this.i18n("vcRuntimeConfigPage.title");
            settingProperties[runtimeIndex].titleLabelContent = this.i18n("vcConfigView.vcInstanceNameHeaderLabel",
                settingProperties[runtimeIndex].value.properties[2].value
            );
        }

        private parseUserDirectorySettings(settingProperties: any): void {
            let userDirectoryIndex: number = _.findIndex(settingProperties, { name: VCServerSettingConstants.KEY_USER_DIRECTORY });
            settingProperties[userDirectoryIndex].titleLabel =
                this.i18n("vcActiveDirectoryConfigPage.title");
            settingProperties[userDirectoryIndex].titleLabelContent = this.i18n("vcConfigView.adTimeoutHeaderLabel",
                settingProperties[userDirectoryIndex].value.properties[0].value
            );
        }

        private parseMailSettings(settingProperties: any): void {
            let mailIndex: number = _.findIndex(settingProperties, { name: VCServerSettingConstants.KEY_MAIL });
            settingProperties[mailIndex].titleLabel =
                this.i18n("vcMailConfigPage.title");
            settingProperties[mailIndex].titleLabelContent =
                settingProperties[mailIndex].value.properties[0].value;
        }

        private parseSNMPSettings(settingProperties: any): void {
            let snmpReceiverIndex: number = _.findIndex(settingProperties, { name: VCServerSettingConstants.KEY_SNMP_RECEIVER });
            settingProperties[snmpReceiverIndex].titleLabel =
                this.i18n("vcSnmpConfigPage.title");
        }

        private parsePortsSettings(settingProperties: any): void {
            let portIndex: number = _.findIndex(settingProperties, { name: VCServerSettingConstants.KEY_PORTS });
            settingProperties[portIndex].titleLabel =
                this.i18n("vcPortsConfigPage.title");
            settingProperties[portIndex].titleLabelContent = this.i18n("vcConfigView.httpPortLabel",
                    settingProperties[portIndex].value.properties[0].value
                ) + "\t\t\t" + this.i18n("vcConfigView.httpsPortLabel",
                    settingProperties[portIndex].value.properties[1].value
                );
        }

        private parseTimeoutSettings(settingProperties: any): void {
            let timeoutIndex: number = _.findIndex(settingProperties, { name: VCServerSettingConstants.KEY_TIMEOUT });
            settingProperties[timeoutIndex].titleLabel =
                this.i18n("vcTimeoutConfigPage.title");
            settingProperties[timeoutIndex].titleLabelContent = this.i18n("vcConfigView.normalTimeoutLabel",
                    settingProperties[timeoutIndex].value.properties[0].value
                ) + "\t\t" + this.i18n("vcConfigView.longTimeoutLabel",
                    settingProperties[timeoutIndex].value.properties[1].value
                );
        }

        private parseLoggingSettings(settingProperties: any): void {
            let loggingIndex: number = _.findIndex(settingProperties, { name: VCServerSettingConstants.KEY_LOGGING });
            settingProperties[loggingIndex].titleLabel =
                this.i18n("vcLogConfigPage.title");
            settingProperties[loggingIndex].titleLabelContent = this.i18n("vcConfigView.logLevelLabel",
                settingProperties[loggingIndex].value.properties[0].value
            );
        }

        private parseSSLSettings(settingProperties: any): void {
            let sslIndex: number = _.findIndex(settingProperties, { name: VCServerSettingConstants.KEY_SSL });
            settingProperties[sslIndex].titleLabel =
                this.i18n("vcSslConfigPage.title");
            settingProperties[sslIndex].titleLabelContent = this.i18n(settingProperties[sslIndex].value.properties[0].value ?
                    "vcConfigView.verifySslCertificatesEnabled" :
                    "vcConfigView.verifySslCertificatesDisabled"
            );
        }
    }

    enum StorageUnit { B, KB, MB, GB, TB, PB }

    angular.module("com.vmware.vsphere.client.commonModule")
            .service("vcServerSettingService", VCServerSettingService);

}
