/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace dvs_ui {

   import IController = angular.IController;
   import IOnChangesObject = angular.IOnChangesObject;
   import IPromise = angular.IPromise;
   import HostNetworkDetailsData = com.vmware.vsphere.client.dvpg.api.HostNetworkDetailsData;
   import HostPnicDetailsItem = com.vmware.vsphere.client.network.host.api.HostPnicDetailsItem;
   export class PhysicalAdapterSettingsComponent {

      public bindings: any;
      public controller: any;
      public templateUrl: string;

      constructor() {
         this.bindings = {
            hostId: "<",
            pnicDevice: "<",
            registerRefreshCallback: "<"
         };

         this.controller = VirtualAdapterSettingsController;
         this.templateUrl = "network-lib-ui/resources/network-lib/components/pnicSettings/physicalAdapterSettingsTemplate.html";
      }
   }

   class VirtualAdapterSettingsController implements IController {

      static $inject = [
         "i18nService",
         "physicalAdaptersListService",
         "physicalAdapterDetailsViewService"
      ];

      public hostId: string;

      public pnicDevice: string;

      public registerRefreshCallback: undefined | ((refreshCallback: () => void) => void);

      public adapterDetailsData: any;

      public loadingData: boolean = false;

      private lastPhysicalAdapterPromise: IPromise<HostNetworkDetailsData>;

      constructor(private i18nService: any,
                  private physicalAdaptersListService: any,
                  private physicalAdapterDetailsViewService: any) {
      }

      public $onInit(): void {
         if (this.registerRefreshCallback !== undefined) {
            this.registerRefreshCallback(() => this.requestData());
         }
      }

      public $onChanges(): void {
         if (this.hostId !== undefined && this.pnicDevice !== undefined) {
            this.requestData();
         }
      }

      public requestData() {

         this.loadingData = true;

         let physicalAdapterPromise = this.physicalAdaptersListService
               .getPhysicalAdapterDetailsItem(this.hostId,
                     this.pnicDevice);
         this.lastPhysicalAdapterPromise = physicalAdapterPromise;

         physicalAdapterPromise.then((pnicDetailsItem: HostPnicDetailsItem) => {
            if (this.isResponseForLastRequest(physicalAdapterPromise)) {
               this.adapterDetailsData = this.physicalAdapterDetailsViewService.build(pnicDetailsItem);
            }
         }).finally((): void => {
            if (this.isResponseForLastRequest(physicalAdapterPromise)) {
               this.loadingData = false;
            }
         });
      }

      private isResponseForLastRequest(physicalAdapterPromise: any) {
         return physicalAdapterPromise === this.lastPhysicalAdapterPromise;
      }
   }

   angular.module("com.vmware.vsphere.client.networkLibUi")
         .component("pnicSettings", new PhysicalAdapterSettingsComponent());
}
