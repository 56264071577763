/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace network_ui {
   import IpRouteConfig = com.vmware.vim.binding.vim.host.IpRouteConfig;
   import NetStackReconfigureSpec$DnsReconfigureSpec = com.vmware.vsphere.client.h5.network.host.netstack.model.NetStackReconfigureSpec$DnsReconfigureSpec;
   import NetStackReconfigureSpec = com.vmware.vsphere.client.h5.network.host.netstack.model.NetStackReconfigureSpec;

   export class NetStackEditDialogManager {
       constructor(public dialogScope: any,
                  private mutationService: any) {
      }

      public submitDialog(): boolean {
         let dnsPageSpec: NetStackReconfigureSpec$DnsReconfigureSpec | null =
               this.dialogScope.dnsConfigPageModel ? this.dialogScope.dnsConfigPageModel
                     .buildSpec(this.dialogScope.initialDnsConfigPageModel) : null;

         let routingPageSpec: IpRouteConfig | null = this.dialogScope.routingPageModel
                     .buildSpec(this.dialogScope.initialRoutingPageModel);

         let namePageSpec: string | null = this.dialogScope.namePageModel
               .buildSpec(this.dialogScope.initialNamePageModel);

         let advancedPageSpec: any = this.dialogScope.advancedPageModel
               .buildSpec(this.dialogScope.initialAdvancedPageModel);

         if (dnsPageSpec || routingPageSpec || namePageSpec || advancedPageSpec) {
            let netStackReconfigureSpec = new NetStackReconfigureSpec();
            netStackReconfigureSpec.netStackInstanceKey = this.dialogScope.netStackKey;

            if (dnsPageSpec) {
               netStackReconfigureSpec.dnsConfig = dnsPageSpec;
            }
            if (routingPageSpec) {
               netStackReconfigureSpec.ipRouteConfig = routingPageSpec;
            }
            if (namePageSpec) {
               netStackReconfigureSpec.name = namePageSpec;
            }
            if(advancedPageSpec) {
               if (advancedPageSpec.maxNumberOfConnections) {
                  netStackReconfigureSpec.requestedMaxNumberOfConnections =
                        advancedPageSpec.maxNumberOfConnections;
               }
               if (advancedPageSpec.congestionControlAlgorithm) {
                  netStackReconfigureSpec.congestionControlAlgorithm =
                        advancedPageSpec.congestionControlAlgorithm;
               }
            }

            this.mutationService.apply(
                  this.dialogScope.hostId,
                  netStackReconfigureSpec._type,
                  netStackReconfigureSpec);
         }
         return true;
      }
   }
}
