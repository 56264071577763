namespace storage_ui {

   import IPromise = angular.IPromise;
   import IQService = angular.IQService;

   export class SwIscsiEditSettingsSpec {
      public device: string;
      public name: string;
      public alias: string;
      public _type: string = "com.vmware.vsphere.client.storage.adapters.InternetScsiStorageAdapterSettingsUpdateSpec";
   }

   export class SwIscsiEditSettingsDialogService {
      public static $inject = ["i18nService", "mutationService", "clarityModalService", "clarityConstants", "vxValidatorFactory"];

      // iSCSI name validators, see rfc3720.
      private static ISCSI_IQN_NAME_REGEX: RegExp =
            new RegExp("^iqn\.[0-9]{4}-[0-9]{2}(?:\.[A-Za-z](?:[A-Za-z0-9\-]*[A-Za-z0-9])?)+(?::[A-Za-z0-9\-\._:]*)?$");
      private static ISCSI_EUI_NAME_REGEX: RegExp = new RegExp("^eui\.[0-9A-Fa-f]{16}$");

      private static MAX_NAME_LENGTH: number = 223;
      private static MAX_ALIAS_LENGTH_SWISCSI: number = 255;
      private static MAX_ALIAS_LENGTH_HWISCSI: number = 31;

      constructor(private i18nService: any,
                  private mutationService: any,
                  private clarityModalService: any,
                  private clarityConstants: any,
                  private vxValidatorFactory: any) {
      }

      public openModal(hostId: string, adapter: any): void {
         let modalOptions: any, dialogData: any;
         let editSpec: SwIscsiEditSettingsSpec = new SwIscsiEditSettingsSpec();
         let maxAliasLength: number = adapter.isSoftwareBased
               ? SwIscsiEditSettingsDialogService.MAX_ALIAS_LENGTH_SWISCSI
               : SwIscsiEditSettingsDialogService.MAX_ALIAS_LENGTH_HWISCSI;

         editSpec.device = adapter.device;
         editSpec.name = adapter.iScsiName;
         editSpec.alias = adapter.iScsiAlias;

         dialogData = {
            hostId: hostId,
            editSpec: editSpec,
            i18n: this.i18nService.getString.bind(this, "StorageUi"),
            errors: {
               name: "",
               alias: ""
            },
            validator: this.vxValidatorFactory.create(),
            isValidName: () => {
               let errors: string[] = [];
               let nameValue = modalOptions.dialogData.editSpec.name.trim();;

               // Adapter name should follow specific rules.
               if (!SwIscsiEditSettingsDialogService.ISCSI_EUI_NAME_REGEX.test(nameValue)
                     && !SwIscsiEditSettingsDialogService.ISCSI_IQN_NAME_REGEX.test(nameValue)) {
                  errors = [modalOptions.dialogData.i18n("storage.adapters.properties.general.iSCSINameError")];
               }

               // Adapter name should not be empty or too long.
               if (!nameValue || nameValue.length > SwIscsiEditSettingsDialogService.MAX_NAME_LENGTH) {
                  errors = [modalOptions.dialogData.i18n(
                        "storage.adapters.properties.general.fieldLengthError",
                        SwIscsiEditSettingsDialogService.MAX_NAME_LENGTH)];
               }

               return errors;
            },
            isValidAlias: () => {
               let errors: string[] = [];
               let aliasValue = modalOptions.dialogData.editSpec.alias.trim();

               // Adapter alias should not be too long.
               if (aliasValue && aliasValue.length > maxAliasLength) {
                  errors.push(modalOptions.dialogData.i18n("storage.adapters.properties.general.fieldLengthError", maxAliasLength));
               }

               return errors;
            }
         };

        modalOptions = {
            title: this.i18nService.getString("StorageUi", "storage.adapters.iscsi.general.edit.header"),
            contentTemplate: "storage-ui/resources/storage/views/host/adapters/iscsihba/SwIscsiEditSettingsDialog.html",
            defaultButton: "submit",
            dialogData: dialogData,
            alerts: []
         };
         modalOptions.onSubmit = this.onCommit.bind(this, modalOptions, adapter);

         if (adapter.device) {
            modalOptions.subTitle = adapter.device;
         }

         this.clarityModalService.openOkCancelModal(modalOptions);
      }

      private onCommit(modalOptions: any, adapter: any): boolean {

         if (this.validate(modalOptions.dialogData)) {
            let nameChanged: boolean = modalOptions.dialogData.editSpec.name !== adapter.iScsiName;
            let aliasChanged: boolean = modalOptions.dialogData.editSpec.alias !== adapter.iScsiAlias;

            let spec: SwIscsiEditSettingsSpec = new SwIscsiEditSettingsSpec();
            spec.device = modalOptions.dialogData.editSpec.device;

            // Do not send a mutation request if nothing has changed.
            if (!nameChanged && !aliasChanged) {
               return true;
            }

            // Only send real changes in the spec.
            if (nameChanged) {
               spec.name = modalOptions.dialogData.editSpec.name;
            }

            if (aliasChanged) {
               spec.alias = modalOptions.dialogData.editSpec.alias;
            }

            this.mutationService.apply(
                  modalOptions.dialogData.hostId,
                  spec._type,
                  spec);
            return true;
         } else {
            modalOptions.alerts = [{
               text: modalOptions.dialogData.i18n("form.validationError"),
               type: this.clarityConstants.notifications.type.ERROR
            }];
            return false;
         }
      }

      private validate(dialogData: any): boolean {
         let errors: string[] = dialogData.validator.validate();

         return !(errors && errors.length);
      }
   }

   angular.module("com.vmware.vsphere.client.storage")
         .service("swIscsiEditSettingsDialogService", SwIscsiEditSettingsDialogService);
}
