angular.module('com.vmware.vsphere.client.vm').directive('vmHardwareSummaryVirtualDisk',
   ['i18nService', 'dataService', 'scopeUtil', 'defaultUriSchemeUtil', 'navigation',
    'pmemUtilService', 'vmCryptUtilService',
   function (i18nService, dataService, scopeUtil, defaultUriSchemeUtil, navigation,
             pmemUtilService, vmCryptUtilService) {
   return {
      templateUrl: 'vm-ui/resources/vm/views/summary/vmHardwareSummaryVirtualDisk.html',
      scope: {
         device: '=',
         ctrl: '='
      },
      link: function (scope) {
         scope.i18n = i18nService.getString;
         scope.deviceLabel = scope.device.deviceInfo.label;

         navigation.populateScope(scope);

         // PMem
         scope.$watch(function() { return scope.device.backing.datastore; }, function() {
            var datastore = scope.device.backing.datastore;
            if (datastore) {
               scope.datastoreRef = defaultUriSchemeUtil.getVsphereObjectId(datastore);
               scope.datastoreInfo = getDatastoreInfo(scope.datastoreRef, scope.ctrl.hardwareViewData.datastoreInfo);
               scope.isPmemDisk = pmemUtilService.isPmemDisk(scope.device);
            }
         });

         // Encryption status
         refreshEncryptionStatus(scope.device);
         scope.$watch(function() { return scope.device.backing; }, function(newValue, oldValue) {
            if (newValue === oldValue) {
               return;
            }

            refreshEncryptionStatus(scope.device);
         });

         function refreshEncryptionStatus(device) {
            scope.deviceLabel = vmCryptUtilService.isExistingDiskEncrytped(device)
               ? i18nService.getString('VmUi', 'VmDiskView.encryptedDiskLabel', device.deviceInfo.label)
               : device.deviceInfo.label;
         }

         function getDatastoreInfo(datastoreRef, datastoreInfos) {
            return _.find(datastoreInfos, function (info) {
               return defaultUriSchemeUtil.getVsphereObjectId(info.provider) === datastoreRef;
            });
         }
      }
   };
}]);
