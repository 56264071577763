/* Copyright 2016-2017 VMware, Inc. All rights reserved. -- VMware Confidential */
(function () {
   "use strict";
   angular
         .module('com.vmware.vsphere.client.vm')
         .constant('sourceTypeConstants', {
            SOURCE_TYPE_CONTENTLIB: 'CONTENT_LIBRARY_SOURCE',
            SOURCE_TYPE_PULL:       'PULL_SOURCE',
            SOURCE_TYPE_PUSH:       'PUSH_SOURCE',

            PUSH_TYPE_OVF:          'OVF_SOURCE',
            PUSH_TYPE_OVA:          'OVA_SOURCE',

            PUSH_OVF_OPTION_MF:     'MANIFEST',
            PUSH_OVF_OPTION_MFCERT: 'MANIFEST_CERTIFICATE',
            PUSH_OVF_OPTION_NONE:   'NONE'
         });
}());

