namespace h5_vm {

   import GosBasePageModel = h5_vm.GosBasePageModel;
   import Specification = com.vmware.vim.binding.vim.vm.customization.Specification;
   import ManagedObjectReference = com.vmware.vim.binding.vmodl.ManagedObjectReference;
   import CustomizationSpecItem = com.vmware.vim.binding.vim.CustomizationSpecItem;
   import CustomizationSpecInfo = com.vmware.vim.binding.vim.CustomizationSpecInfo;
   import VmCustomizationSpec = com.vmware.vsphere.client.vm.customizations.VmCustomizationSpec;
   import Sysprep = com.vmware.vim.binding.vim.vm.customization.Sysprep;

   export class GosFinishPageModel extends GosBasePageModel {
      gosType: GosType;
      specName: string;
      description: string;
      customizationSpecManager: ManagedObjectReference;

      specDetails: Specification;
      encryptAdminPassword: boolean;
      encryptDomainPassword: boolean;

      /**
       * Builds a spec for creating/editing a vm customization specification.
       * @return VmCustomizationSpec for creating/editing a vm customization specification
       */
      public buildSpec(): CustomizationSpecItem {

         let configInfo: CustomizationSpecInfo = new CustomizationSpecInfo();
         configInfo.name = this.specName;
         configInfo.description = this.description;
         switch (this.gosType) {
            case GosType.WINDOWS:
            case GosType.WINDOWS_CUSTOM_SYSPREP:
               configInfo.type = "Windows";
               break;
            case GosType.LINUX:
               configInfo.type = "Linux";
               break;
            default:
               configInfo.type = "";
         }

         let specItem: CustomizationSpecItem = new CustomizationSpecItem();
         specItem.spec = this.specDetails;
         specItem.info = configInfo;
         return specItem;
      }

      public addWindowsParams(vmCustSpec: VmCustomizationSpec) {
         if (this.gosType !== GosType.WINDOWS) {
            return;
         }
         if (vmCustSpec.config.spec.identity instanceof Sysprep
               && !_.isEmpty((vmCustSpec.config.spec.identity as Sysprep).guiUnattended.password)) {
            vmCustSpec.encryptAdminPassword = this.encryptAdminPassword;
         }

         if (vmCustSpec.config.spec.identity instanceof Sysprep
               && !_.isEmpty((vmCustSpec.config.spec.identity as Sysprep).identification.domainAdminPassword)) {
            vmCustSpec.encryptDomainPassword = this.encryptDomainPassword;
         }
      }
   }

}

