/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace dvs_ui {

   export class DvsNameAndLocationPageComponent {

      public bindings: any;
      public controller: any;
      public templateUrl: string;

      constructor() {
         this.bindings = {
            wizardModel: "<"
         };

         this.controller = DvsNameAndLocationPageComponentController;

         this.templateUrl = "dvs-ui/resources/dvs/create/views/" +
               "dvsNameAndLocationPageComponentTemplate.html";
      }
   }

   class DvsNameAndLocationPageComponentController {

      static $inject = [
         "i18nService",
         "$scope",
         "dvsCommonPropertiesValidator"
      ];

      public wizardModel: DvsCreateWizardModel;

      private _nameValidator: any;

      constructor(private i18nService: any,
                  private $scope: any,
                  private validator: DvsCommonPropertiesValidator) {
         $scope.i18n = this.i18nService.getString;

         this._nameValidator = {
            message: null,
            isVisible: false
         };
      }

      public get nameValidator(): any {
         return this._nameValidator;
      }

      public onNameChange(): void {
         let message: string = this.validator.getNameError(this.wizardModel.uniqueDvsName);
         this.nameValidator.message = message;
         this.nameValidator.isVisible = message !== null;
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .component("dvsNameAndLocationPage",
               new DvsNameAndLocationPageComponent());
}
