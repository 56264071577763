namespace h5_vm {

   import VmConfigContext = com.vmware.vsphere.client.vm.config.VmConfigContext;
   import VirtualUSB = com.vmware.vim.binding.vim.vm.device.VirtualUSB;
   import UsbInfo = com.vmware.vim.binding.vim.vm.UsbInfo;

   class VmHardwareUsbDeviceComponent implements ng.IComponentOptions {
      bindings: any;
      controller: any;
      templateUrl: string;

      constructor() {
         this.bindings = {
            inflatedDevice: "<",
            selectedGuestOs: "<",
            removeCallback: "&",
            vmConfigContext: "<",
            expanded: "="
         };
         this.controller = VmHardwareUsbDeviceController;
         this.templateUrl = "vm-ui/resources/vm/views/settings/vmHardwareSettings/vmHardwareUsbDevice/vm-hardware-usb-device.html";
      }
   }

   class VmHardwareUsbDeviceController implements ng.IComponentController {

      static $inject: string[] = [
         "i18nService",
      ];

      // input to this component
      inflatedDevice: any;
      selectedGuestOs: any;
      removeCallback: Function;
      vmConfigContext: VmConfigContext;
      expanded: boolean;

      private i18n: Function;
      private usb: VirtualUSBClass;
      private usbError: any;
      private vmHardwareUsbDevice: any;

      constructor(private i18nService: any) {
         this.i18n = (key: string, ...rest: any[]) => i18nService.getString("VmUi", "USBDevicePage." + key, rest);
      }

      $onInit() {
         this.usb = this.inflatedDevice;
         this.i18n = this.usb.i18n;
         this.usb.onInit(this.vmConfigContext);
      }

      $postLink() {
         this.validateUSBSelection();
      }

      public getDeviceLabel(): String {
         return this.usb.getDeviceLabel();
      }

      public getUsbDeviceLabel(): String {
         return this.usb.getUsbDeviceLabel(this.vmConfigContext);
      }

      public isMarkedForRemoval(): boolean {
         if (!this.inflatedDevice) {
            return false;
         } else {
            return this.inflatedDevice.isMarkedForRemoval();
         }
      }

      public hasError(): boolean {
         return this.isNew() && !this.usb.isDeviceAvailable();
      }

      isRemoveDisabled(): boolean {
         return !this.usb.isRemovalEnabled();
      }

      removeDevice() {
         if (this.usb.hostUsb) {
            (this.usb.hostUsb as any).taken = false;
         }

         if (!this.isRemoveDisabled() && this.removeCallback) {
            this.removeCallback()(this.inflatedDevice);
         }
      }

      revertRemoval() {
         this.inflatedDevice.revertRemoval();
      }

      isNew(): boolean {
         return this.inflatedDevice && this.inflatedDevice.isNew();
      }


      getHostUsbList(): Array<UsbInfo> {
         let array: Array<UsbInfo> = this.vmConfigContext.environment.configTarget.usb;
         return array;
      }

      getHostUsbLabel(item: UsbInfo): string {
         if (!item) {
            return "";
         }
         return item.description;
      }

      get hostUsb() {
         return this.usb.hostUsb;
      }

      set hostUsb(value: UsbInfo) {
         if (!value) {
            return;
         }

         let oldValue: UsbInfo = this.usb.hostUsb;
         this.usb.hostUsb = value;
         (value as any).taken = true;
         if (oldValue) {
            (oldValue as any).taken = false;

         }
      }

      isDevicePresent(): boolean {
         return this.usb.isDevicePresent();
      }

      validateUSBSelection(): void {
         const error: boolean = this.hasError();
         const formName: string = "vmHardwareUsbDevice";

         this.usbError = {
            message: this.getDeviceAvailableText(),
            isVisible: error
         };

         this.vmHardwareUsbDevice.$setValidity("newDevice", !error);
      }

      get USBUniqueID(): string {
         return this.usb.getUSBUniqueID();
      }

      getUSBConnectionStatusText(): string {
         return this.usb.getUSBConnectionStatusText();
      }

      getVMotionSupportText(): string {
         return this.usb.getVMotionSupportText();
      }

      // Section for new USB device

      getDeviceAvailableText(): string {
         return this.usb.getDeviceAvailableText();
      }

      public get vMotion(): boolean {
         return this.usb.vMotion;
      }

      public set vMotion(value: boolean) {
         this.usb.vMotion = value;
      }
   }

   angular.module("com.vmware.vsphere.client.vm")
         .component("vmHardwareUsbDevice", new VmHardwareUsbDeviceComponent());
}
