/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace dvs_ui {

   angular.module("com.vmware.vsphere.client.dvs").constant("exportDvsConstants", {
      configurationsToExport: {
         DVS_AND_ALL_PORTGROUPS: "dvsAndAllPortgroups",
         DVS_ONLY: "dvsOnly"
      },
      configurationRestoreTypes: {
         FROM_PREVIOUS_CONFIGURATION: "previous",
         FROM_FILE: "file"
      }
   });
}

