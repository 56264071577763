namespace h5_vm {
    import VuiFtTurnOnWizard = h5_vm.VuiFtTurnOnWizard;
    import FtVmTurnOnManager = h5_vm.FtVmTurnOnManager;

    export class FtVmWizardService {
        static $inject: string[] = ["$rootScope", "dataService", "vuiFtTurnOnWizard", "ftVmPageService"];
        public wizardScope: any;

        constructor(private $rootScope: any,
                    private dataService: any,
                    private vuiFtTurnOnWizard: VuiFtTurnOnWizard,
                    private ftVmPageService: FtVmPageService) {
        }

        public invokeWizard(targetUid: string, manager: FtVmTurnOnManager): void {
            manager.setTargetUid(targetUid);
            this.wizardScope = this.$rootScope.$new();
            let pages: Array<any> = this.createWizardPages(this.wizardScope, manager);
            this.dataService
                .getProperties(targetUid, ['name']
                )
                .then((response: any): void => {
                    if (response && response.name) {
                        let vmName: string = response.name;
                        manager.setVmName(vmName);
                        this.vuiFtTurnOnWizard.show(pages, this.wizardScope, manager);
                    }
                });
        }

        private createWizardPages(wizardScope: any, manager: FtVmTurnOnManager): Array<any> {
            let pages: Array<any> = [];
            pages.push(this.ftVmPageService.buildSelectDatastoresPage(wizardScope));
            pages.push(this.ftVmPageService.buildSelectHostPage(wizardScope));
            pages.push(this.ftVmPageService.buildReadyToCompletePage(wizardScope));
            return pages;
        }

    }

    angular.module('com.vmware.vsphere.client.vm')
        .service('ftVmWizardService', FtVmWizardService);
}
