/*
 *  Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential
 */

(function() {
   /**
    * Directive for VM to host rule details.
    */
   'use strict';
   angular.module('com.vmware.vsphere.client.cluster').directive(
      'vmToHostRuleDetails', vmToHostRuleDetails);

   vmToHostRuleDetails.$inject = ['$rootScope', 'i18nService', 'clusterGroupsService'];

   function vmToHostRuleDetails($rootScope, i18nService, clusterGroupsService) {

      return {
         restrict: 'E',
         templateUrl:
            'cluster-ui/resources/cluster/views/settings/rules/vmToHostRuleDetails.html',
         scope: {
            rule: '='
         },
         controller: ['$scope', function($scope) {
            var objectId = $rootScope._route.objectId;

            $scope.ruleDetailsTitle = i18nService.getString(
               'ClusterUi', 'manage.settings.ruleDetails');
            $scope.vmGroup = null;
            $scope.hostGroup = null;

            $scope.$watch('rule', function(rule) {
               if (rule) {
                  if (hasVmGroup(rule) || hasHostGroup(rule)) {
                     clusterGroupsService.getRuleGroupsData(objectId, rule)
                        .then(function(ruleGroups) {
                           $scope.vmGroup = clusterGroupsService.getGroupByName(
                              ruleGroups, rule.vmToHostRuleProperties.vmGroupName);
                           $scope.hostGroup = clusterGroupsService.getGroupByName(
                              ruleGroups, rule.vmToHostRuleProperties.hostGroupName);
                        });
                  }
                  $scope.ruleDetailsLabel = getVmToHostRuleLabel(
                        rule.vmToHostRuleProperties);
               }
            });

            function getVmToHostRuleLabel(rule) {
               var ruleDetailsLabel = '';

               if(rule.isAffineHostGroupName === true && rule.isMandatory === true) {
                  ruleDetailsLabel = i18nService.getString(
                     'ClusterUi', 'rules.vmRuleDetails.typeDescription.vmHostGroup.hard');
               }

               if(rule.isAffineHostGroupName === true && rule.isMandatory === false) {
                  ruleDetailsLabel = i18nService.getString(
                     'ClusterUi', 'rules.vmRuleDetails.typeDescription.vmHostGroup.soft');
               }

               if(rule.isAffineHostGroupName === false && rule.isMandatory === true) {
                  ruleDetailsLabel = i18nService.getString(
                     'ClusterUi', 'rules.vmRuleDetails.typeDescription.vmHostSeparate.hard');
               }

               if(rule.isAffineHostGroupName === false && rule.isMandatory === false) {
                  ruleDetailsLabel = i18nService.getString(
                     'ClusterUi', 'rules.vmRuleDetails.typeDescription.vmHostSeparate.soft');
               }

               return ruleDetailsLabel;
            }

            function hasVmGroup(rule) {
               return rule.vmToHostRuleProperties.vmGroupName !== null &&
                     rule.vmToHostRuleProperties.vmGroupName !== undefined;
            }

            function hasHostGroup(rule) {
               return rule.vmToHostRuleProperties.hostGroupName !== null &&
                     rule.vmToHostRuleProperties.hostGroupName !== undefined;
            }
         }]
      };
   }
})();
