/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace dvs_ui {

   export class DvsEditSettingsAdvancedPageValidator {

      public static CONTACT_MAX_LENGTH: number = 128;

      public static $inject = [
         "i18nService",
          "networkUtil"
      ];

      constructor(private i18nService: any,
                  private networkUtil:any) {
      }

      public getAllErrors(pageModel: DvsEditSettingsAdvancedPageModel): string[] {
         let errors: string[] = [];

         let mtuError: string = this.getMtuError(pageModel.mtu);
         if (mtuError) {
            errors.push(mtuError);
         }

         let contactNameError: string = this.getContactNameError(pageModel.contactName);
         if (contactNameError) {
            errors.push(contactNameError);
         }

         let contactDetailsError: string = this.getContactDetailsError(pageModel.contactDetails);
         if (contactDetailsError) {
            errors.push(contactDetailsError);
         }

         return errors;
      }

      public getMtuError(mtu: number): any {
         if (!this.networkUtil.isMtuValid(mtu)) {
            return this.i18nService.getString(
                "DvsUi", "DvsEditSettingsDialog.advancedPage.mtu.error");
         }

         return null;
      }

      public getContactNameError(contactName: string): any {
         if (contactName &&
               contactName.length > DvsEditSettingsAdvancedPageValidator.CONTACT_MAX_LENGTH) {
            return this.i18nService.getString(
                  "DvsUi", "DvsEditSettingsDialog.advancedPage.contactName.error");
         }

         return null;
      }

      public getContactDetailsError(contactDetails: string): any {
         if (contactDetails &&
               contactDetails.length > DvsEditSettingsAdvancedPageValidator.CONTACT_MAX_LENGTH) {
            return this.i18nService.getString(
                  "DvsUi", "DvsEditSettingsDialog.advancedPage.contactDetails.error");
         }

         return null;
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .service("dvsEditSettingsAdvancedPageValidator", DvsEditSettingsAdvancedPageValidator);
}
