/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace license_lib_ui {

   export class AssignLicenseService {

      public static $inject = [
         'mutationService',
         'licenseCommonUtil',
         'i18nService',
         'clarityModalService',
         'defaultUriSchemeUtil'
      ];

      private readonly ASSET_TYPE = "Asset";

      constructor(
         private mutationService: any,
         private licenseCommonUtil: any,
         private i18nService: any,
         private clarityModalService: any,
         private defaultUriSchemeUtil: any
      ) {
      }

      public retrieveLicenses(assetRefs: any[]): angular.IPromise<any> {

         return this.mutationService.validateOnMultiEntity(assetRefs,
               "com.vmware.license.client.cis.management.ds.specs.AssetAssignLicenseValidateSpec",
               {}
         ).then(
               (response: any[]) => {
                  if (_.isEmpty(response)) {
                     return null;
                  }

                  if (response[0].error) {
                     throw response[0].error;
                  }

                  let validationResult: any = response[0].result;
                  this.licenseCommonUtil.normalizeValidationResult(validationResult);

                  return validationResult;
               }
         );
      }

      public assignLicense(assetUris: any[], licenseUri: string): angular.IPromise<any> {

         return this.mutationService.applyOnMultiEntity(assetUris,
               "com.vmware.license.client.cis.management.ds.specs.AssetAssignLicenseSpec",
               {
                  licenseRef: licenseUri,
                  _type: "com.vmware.license.client.cis.management.ds.specs.AssetAssignLicenseSpec"
               },
               this.i18nService.getString('LicenseLibUi', "action.assignLicense.operationName")
         );
      }

      public assignNewLicense(assetUris: any[], serialKeys: string[], newLicenseName: string) {

         return this.mutationService.applyOnMultiEntity(assetUris,
               "com.vmware.license.client.cis.management.ds.specs.LicenseCreateSpec",
               {
                  name: newLicenseName,
                  serialKeys: serialKeys,
                  _type: "com.vmware.license.client.cis.management.ds.specs.LicenseCreateSpec"
               });
      }

      public showAssignLicenseForm(assetRefs: any[]): void {
         let modalOptions: any = {
            title: this.getTitle(assetRefs),
            contentTemplate: 'license-lib-ui/resources/license/views/assignLicense/assignLicenseForm.html',
            size: 'xl',
            defaultButton: "submit",
            modalClass: "assign-license-modal",
            dialogData: {
               availableTargets: assetRefs
            }
         };

         if (assetRefs.length === 1 && this.defaultUriSchemeUtil.getEntityType(assetRefs[0]) !== this.ASSET_TYPE) {
            modalOptions.subTitle = {
               objectId: assetRefs[0]
            };
         }

         this.clarityModalService.openOkCancelModal(modalOptions);
      }

      private getTitle(assetRefs: any[]): string {
         let title: string = this.i18nService.getString("LicenseLibUi", "assignLicense.title");
         if (assetRefs && assetRefs.length > 1) {
            title += " - " + this.i18nService.getString("LicenseLibUi", "dialogTitle.multipleObjectsPrefix", assetRefs.length);
         }
         return title;
      }
   }

   angular.module("com.vmware.vsphere.client.licenseLibUi").service(
         "assignLicenseService", AssignLicenseService);
}
