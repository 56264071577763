module cluster_ui {
   import RecommendationData = cluster_ui.RecommendationData;
   import DrsRecommendationListItemData = cluster_ui.DrsRecommendationListItemData;

   export class RecommendationsController {
      static $inject = ["$scope", "dataService", "i18nService",
         "vuiConstants", "columnRenderersRegistry",
         "defaultUriSchemeUtil", "mutationService",
         "drsConstants", "clarityModalService",
         "drsRecommendationsService", "actionsService"];

      private objectId: string;
      private gridOptions: any;
      private recommendationStore: any;
      private actionSpecs: string[] = [
            "vsphere.core.cluster.actions.drs.refreshRecommendationsAction",
            "vsphere.core.cluster.actions.drs.applyRecommendationsAction"];

      watchForObjects: any;
      liveRefreshProperties: string[];
      recommendationsTitle: string;
      overrideRecommendations: boolean = false;
      isRecommendationChecked: (key: string) => boolean;
      isRefreshButtonDisabled: boolean = true;
      isApplyButtonDisabled: boolean = true;

      constructor(private $scope: any, private dataService: any, private i18nService:any,
            private vuiConstants: any, private columnRenderersRegistry:any,
            private defaultUriSchemeUtil: any, private mutationService: any,
            private drsConstants: any, private clarityModalService: any,
            private drsRecommendationsService: any, private actionsService: any) {

         this.objectId = $scope._route.objectId;
         this.watchForObjects = [this.objectId];
         this.liveRefreshProperties = ["recommendation",
               "summary.currentBalance"];
         this.recommendationsTitle = i18nService.getString(
               "ClusterUi", "RecommendationsList.drsRecommendationsLabel");
         this.gridOptions = this.getGridOptions();
         this.getViewData();
         this.isRecommendationChecked = (key) => this.recommendationStore
               .isRecommendationChecked(key);

         // Request action evaluations from the backend
         this.actionsService.getActions(this.objectId, this.actionSpecs)
               .then((actionEvalutaions: any) => {
                  _.each(actionEvalutaions, (actionEvaluation: any) => {
                     if (actionEvaluation.action.uid === this.actionSpecs[0]
                           && actionEvaluation.available) {
                        this.isRefreshButtonDisabled = false;
                     }
                     if (actionEvaluation.action.uid === this.actionSpecs[1]
                           && actionEvaluation.available) {
                        this.isApplyButtonDisabled = false;
                     }
                  });
               });
      }

      getViewData() {
         this.dataService.getData(this.objectId,
               "com.vmware.vsphere.client.clusterui.model.monitor.DrsRecommendationsData")
               .then((recommendationsData: any) => {
                  this.gridOptions.data = recommendationsData.recommendations;

                  let recommendations: RecommendationData[] =
                        _.map(recommendationsData.recommendations,
                              (recommendation: DrsRecommendationListItemData) => {
                                 return recommendation.recommendationData;
                              });

                  let newRecommendationStore = this.drsRecommendationsService
                        .createRecommendationsStore(recommendations);


                  let newRecommendationsKeys = newRecommendationStore
                        .getRecommendationKeys();
                  if (this.recommendationStore) {
                     let oldRecommendationKeys = this.recommendationStore
                           .getRecommendationKeys();
                     let haveRecommendationsChanged = !this.arrayEq(
                           newRecommendationsKeys.sort(), oldRecommendationKeys.sort());
                     if (haveRecommendationsChanged) {
                        this.recommendationStore = newRecommendationStore;
                     }
                  } else {
                     this.recommendationStore = newRecommendationStore;
                  }
               });
      }

      runDrs() {
         let refreshClusterRecommendationsSpec = {};
         this.mutationService.apply(this.objectId,
               "com.vmware.vsphere.client.clusterui.RefreshClusterRecommendationsSpec",
               refreshClusterRecommendationsSpec);
      }

      applyRecommendations() {
         if (!this.recommendationStore) {
            return;
         }
         let keysToApply: string[] = this.recommendationStore.getRecommendationsToApply();
         let clusterRecommendationSpec = {
            operation: this.drsConstants.clusterRecommendationSpecOperation.APPLY,
            keys: keysToApply
         };
         this.mutationService.apply(this.objectId,
               "com.vmware.vsphere.client.cluster.services.recommendations." +
               "ClusterRecommendationSpec",
               clusterRecommendationSpec);
      }

      onRecommendationCheckboxClick(recommendationKey: string) {
         let isChecked = this.recommendationStore.isRecommendationChecked(recommendationKey);
         if (isChecked) {
            if(this.recommendationStore!.uncheckRecommendation(recommendationKey)){
               this.$scope.modalOptions = this.getUncheckedDependantRecommendationsModalOptions();
               this.clarityModalService.openPopUp(this.$scope);
            }
         } else {
            if(this.recommendationStore!.checkRecommendation(recommendationKey)){
               this.$scope.modalOptions = this.getCheckedPrerequisiteRecommendationsModalOptions();
               this.clarityModalService.openPopUp(this.$scope);
            }

         }
      }

      private arrayEq(array1: any[], array2: any[]): boolean {
         if (array1.length !== array2.length) {
            return false;
         }
         return array1.every(function(array1El, i) {
            return array1El === array2[i];
         });
      }

      private getGridOptions() {
         return {
            sortMode: this.vuiConstants.grid.sortMode.NONE,
            searchable: false,
            resizable: true,
            data: [],
            columnDefs: this.getColumnDefs(),
            height: "100%"
         };
      }

      private getColumnDefs() {
         return [
            {
               displayName: this.i18nService.getString("ClusterUi",
                     "RecommendationsList.applyColumnHeaderText"),
               field: "recommendationsText",
               type: "string",
               width: "100px",
               template: this.checkboxColumnTemplate.bind(this)
            },
            {
               displayName: this.i18nService.getString("ClusterUi",
                     "RecommendationsList.priorityColumnHeaderText"),
               field: "recommendationData.priorityRating",
               type: "string",
               width: "100px",
               template: "#:recommendationData.priorityRating#"
            },
            {
               displayName: this.i18nService.getString("ClusterUi",
                     "RecommendationsList.recommendationColumnHeaderText"),
               field: "recommendationText",
               type: "string",
               template: this.recommendationTextRenderer.bind(this)
            },
            {
               displayName: this.i18nService.getString("ClusterUi",
                     "RecommendationsList.reasonColumnHeaderText"),
               field: "recommendationData.parentRecommendation.reasonText",
               type: "string",
               template: "<span title=\"#:recommendationData.parentRecommendation.reasonText#\">" +
                     "#:recommendationData.parentRecommendation.reasonText#</span>"
            }
         ];
      }

      private recommendationTextRenderer(objData: any) {
         let targetLinks: string[] = [];
         let renderer = this.columnRenderersRegistry.getColumnRenderer("object-name");

         //build links for each target object in the recommendation action text
         objData.targets.forEach((object: any) => {
            object.uid = this.defaultUriSchemeUtil.getVsphereObjectId(object.provider);

            let targetLink = renderer(["uid", null, "name", "labelIds"], object);
            targetLinks.push(targetLink);
         });

         let recommendationText = this.i18nService.interpolate(
               objData.recommendationText, targetLinks);

         if (!!objData.image) {
            recommendationText =
                  `<span class=\"${objData.image}\"></span>${recommendationText}`;
         }

         return recommendationText;
      }

      private checkboxColumnTemplate(data: any) {
         if (!data.recommendationData.isFirst) {
            return "";
         }
         let key = data.recommendationData.parentRecommendation.key;
         let checkbox = [
            "<div class='centered-content-container'>",
                     "<input type='checkbox' ",
                     "ng-disabled='!$parent.$parent.ctrl.overrideRecommendations' ",
                     "ng-checked='$parent.$parent.ctrl.isRecommendationChecked(", key, ")' ",
                     "ng-click='$parent.$parent.ctrl.onRecommendationCheckboxClick(", key, ")' >",
               "</div>"
         ].join("");
         return checkbox;
      }

      private getCheckedPrerequisiteRecommendationsModalOptions() {
         return {
            title: this.i18nService.getString("ClusterUi",
                  "prerequisite.recommendations.selected.title"),
            contentUrl: "cluster-ui/resources/cluster/views/monitor/drs/" +
                  "checkedPrerequisiteRecommendationsModal.html"
         };
      }

      private getUncheckedDependantRecommendationsModalOptions() {
         return {
            title: this.i18nService.getString("ClusterUi",
                  "dependent.recommendations.unselected.title"),
            contentUrl: "cluster-ui/resources/cluster/views/monitor/drs/" +
                  "uncheckedDependantRecommendationsModal.html"
         };
      }
   }

   angular.module("com.vmware.vsphere.client.cluster")
         .controller("RecommendationsController", RecommendationsController);
}
