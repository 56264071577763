/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace dvs_ui {

   import IPromise = angular.IPromise;
   import IQService = angular.IQService;
   import IDeferred = angular.IDeferred;
   import DvPortgroupModel = com.vmware.vsphere.client.h5.network.dvs.addhost.model.DvPortgroupModel;
   export class AssingUplinkDialogService {

      static $inject = [
         "i18nService",
         "clarityModalService",
         "clarityConstants"
      ];

      constructor(private i18nService: any,
                  private clarityModalService: any,
                  private clarityConstants: any) {}

      private modalOptions: any;

      public show(pnic: string,
                  assignUplinkComponentConfig: AssignUplinkComponentConfig,
                  isBatchAssignmentEnabled: boolean,
                  signPostConfig: ApplyToAllSignPostConfig,
                  okHandler: (assignUplinkComponentConfig: AssignUplinkComponentConfig, applyToAll: boolean) => void): void {

         const dialogData: any = {
            assignUplinkComponentConfig: assignUplinkComponentConfig,
            applyToAll: false,
            applyToAllLabel: this.i18nService.getString(
                  "DvsUi", "UplinkPortMappingAssignDialog.applyToAllLabel"),
            isBatchAssignmentEnabled: isBatchAssignmentEnabled,
            signPostConfig: signPostConfig
         };
         this.modalOptions = {
            title: this.i18nService.getString("DvsUi", "assignUplinkDialogService.title"),
            subTitle: {text: pnic},
            defaultButton: "submit",
            alerts: [],
            dialogData: dialogData,
            contentTemplate: "dvs-ui/resources/dvs/addhost/components/assignUplink/assignUplinkDialog.html",
            onSubmit: (): boolean => {
               if (dialogData.assignUplinkComponentConfig.selectedUplink !== undefined &&
                     !_.contains(dialogData.assignUplinkComponentConfig.lagNames,
                           dialogData.assignUplinkComponentConfig.selectedUplink)) {
                  okHandler(assignUplinkComponentConfig, dialogData.applyToAll);
                  return true;
               } else {
                  this.modalOptions.alerts = [{
                     text: this.i18nService.getString("DvsUi",
                           "assignUplinkDialogService.noUplinkSelected"),
                     type: this.clarityConstants.notifications.type.ERROR
                  }];
                  return false;
               }
            }
         };
         this.clarityModalService.openOkCancelModal(this.modalOptions);
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .service("assingUplinkDialogService", AssingUplinkDialogService);
}
