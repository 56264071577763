/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace dvs_ui {

   export class DvpgQualifierValidatorService {

      static $inject = [
         "networkUiConstants"
      ];

      constructor(private networkUiConstants: any) {
      }

      public isValidInteger(
            value: string, minValue: number, maxValue: number): boolean {

         return this.networkUiConstants.POSITIVE_INTEGER_REG_EXP.test(value)
               && parseInt(value) >= minValue
               && parseInt(value) <= maxValue;
      }

      public isValidMac(macAddress: string): boolean {
         return macAddress !== undefined && macAddress !== null &&
               macAddress.length > 0 &&
               new RegExp("^([0-9a-fA-F]{1,2}[:-]){5}[0-9a-fA-F]{1,2}$")
                     .test(macAddress);
      }

      public isMulticastOrBroadcastAddress(macAddress: string): boolean {
         if (!macAddress || macAddress.length === 0) {
            return false;
         }

         macAddress = macAddress.trim();

         let delimInd: number = -1;
         let delimArray: string[] = [":", "-", ".", " "];

         _.each(delimArray, (delimeter: string): void => {
            delimInd = macAddress.indexOf(delimeter);
            if (delimInd !== -1) {
               return;
            }
         });

         let firstHex: string = delimInd === -1
               ? macAddress.substr(0, 2)
               : macAddress.substr(0, delimInd);

         return (parseInt(firstHex, 16) & 0x1) === 1;
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .service("dvpgQualifierValidatorService", DvpgQualifierValidatorService);
}
