module storage_ui {

   export class ClusterStorageAdaptersOverview {

      public i18n:Function;

      public loading:boolean;
      public deviceInfoLoading:boolean;

      public datagridOptions:any;

      public clusterId:string;

      public liveRefreshProperties: Array<string>;

      public static $inject = ['i18nService', 'vuiConstants', 'dataService',
         'columnRenderersRegistry', 'storageUtil', 'navigation', 'defaultUriSchemeUtil'];

      constructor(private i18nService:any,
                  private vuiConstants:any,
                  private dataService:any,
                  private columnRenderersRegistry:any,
                  private storageUtil:any,
                  private navigation:any,
                  private defaultUriSchemeUtil:any) {

         this.i18n = i18nService.getString;

         this.liveRefreshProperties = [
            'datastore',
            'summary',
            'host'
         ];
      }

      public $onInit() {
         this.datagridOptions = {
            height: '100%',
            columnDefs: this.getColumnDefinitions(),
            selectionMode: this.vuiConstants.grid.selectionMode.NONE,
            data: [],
            resizable: true
         };

         this.refresh();
      }

      public refresh() {
         this.deviceInfoLoading = true;
         this.loading = true;
         this.clusterId = this.navigation.getRoute().objectId;

         this.dataService
               .getProperties(this.clusterId, ['clusterStorageAdaptersOverview'])
               .then( (response:any) => {

                  let hostData = response && response['clusterStorageAdaptersOverview'] &&
                        response['clusterStorageAdaptersOverview']['hostStorageAdaptersData'];

                  if (this.deviceInfoLoading) {
                     const dataItems: any[] = [];
                     _.forEach(hostData, (item:any) => {
                        const dataItem: any = {};
                        this.assignOverviewData(dataItem, item);
                        dataItems.push(dataItem);
                     });
                     this.datagridOptions.data = dataItems;
                  } else {
                     this.overrideOverviewData(hostData);
                  }
               }).finally( () => {
                  this.loading = false;
               });

         // Async-request details that are fetched slower.
         this.dataService
               .getProperties(this.clusterId, ['clusterStorageAdaptersDeviceInfo'], {
                  skipLoadingNotification: true
               }).then( ( response: any ) => {

                  let hostData = response && response['clusterStorageAdaptersDeviceInfo'] &&
                        response['clusterStorageAdaptersDeviceInfo']['hostStorageAdaptersData'];

                  if (!hostData) {
                     this.handleMissingDeviceInfo();
                     return;
                  }

                  if (this.loading) {
                     const dataItems: any[] = [];
                     _.forEach(hostData, (item:any) => {
                        const dataItem: any = {};
                        this.assignDeviceInfoData(dataItem, item);
                        dataItems.push(dataItem);
                     });
                     this.datagridOptions.data = dataItems;
                  } else {
                     this.overrideDeviceInfoData(hostData);
                  }
               }, () => {
                  this.handleMissingDeviceInfo();
               }).finally( () => {
                  this.deviceInfoLoading = false;
               });
      }

      public getCountDetailsSignPostConfig(title:string, hostItem:any, countDataPropertyName:string) {
         if (!hostItem) {
            return;
         }

         let countDetails = '';
         if (hostItem[countDataPropertyName]) {
            _.forEach(hostItem[countDataPropertyName], (item:any) => {
               if (countDetails) {
                  countDetails += '<br>';
               }
               countDetails += this.i18n('StorageUi', 'cluster.adapters.list.countDetailsFormat', item.statusName, item.count);
            });
         }

         return {
            title: title,
            message: countDetails
         };
      }

      private getColumnDefinitions() {
         let objectNameRenderer = this.columnRenderersRegistry.getColumnRenderer('object-name');
         let textRenderer = this.columnRenderersRegistry.getColumnRenderer('text');

         let compareNumericValues = this.storageUtil.compareNumericValues;

         let renderAggregatedValue = (count:number, totalCount:number, item:any, countDetailsProp:string) => {

            if (this.deviceInfoLoading) {
               return '<span class="spinner spinner-sm"></span>';
            }

            if (count === undefined && totalCount === undefined) {
               item[countDetailsProp] = this.i18nService.getString('StorageUi', 'cluster.adapters.list.noValue');
               return textRenderer([countDetailsProp], item);
            }

            let result = textRenderer(['countData'], {
               countData: this.i18n('StorageUi', 'cluster.adapters.list.aggregatedCountFormat', count, totalCount)
            });

            if (!item[countDetailsProp]) {
               return result;
            }

            let infoIcon = '<span class="vx-icon-info_normal" ' +
               'vui-sign-post="dataItem.' + countDetailsProp + '" ' +
               '</span>';

            return result + infoIcon;
         };

         const renderDeviceInfo: any = (item: any, field: string) => {
            if (this.deviceInfoLoading) {
               return '<span class="spinner spinner-sm"></span>';
            }

            item[field] = item[field] !== undefined
                  ? item[field] : this.i18nService.getString('StorageUi', 'cluster.adapters.list.noValue');

            return textRenderer([field], item);
         };

         return [
            {
               displayName: this.i18nService.getString('StorageUi', 'cluster.adapters.list.nameColumn'),
               field: 'name',
               width: "200px",
               template: (item:any) => {
                  return objectNameRenderer(
                     ['hostId', 'iconId', 'name', 'labelIds'],
                     {
                        hostId: this.defaultUriSchemeUtil.getVsphereObjectId(item.hostRef),
                        iconId: item.iconId,
                        name: item.name,
                        labelIds: item.labelIds
                     },
                     {
                        navigatable: true
                     });
               }
            },
            {
               displayName: this.i18nService.getString('StorageUi', 'cluster.adapters.list.adaptersColumn'),
               field: 'numberOfStorageAdapters',
               width: "100px",
               template: function(item:any) {
                  return renderDeviceInfo(item, 'numberOfStorageAdapters');
               },
               sortable: function(item1: any, item2: any) {
                  return compareNumericValues(item1, item2, 'numberOfStorageAdapters');
               }
            },
            {
               displayName: this.i18nService.getString('StorageUi', 'cluster.adapters.list.targetsColumn'),
               field: 'numberOfTargets',
               width: "100px",
               template: function(item:any) {
                  return renderDeviceInfo(item, 'numberOfTargets');
               },
               sortable: function(item1: any, item2: any) {
                  return compareNumericValues(item1, item2, 'numberOfTargets');
               }
            },
            {
               displayName: this.i18nService.getString('StorageUi', 'cluster.adapters.list.devicesColumn'),
               field: 'numberOfAttachedDevices',
               width: "150px",
               template: function(item:any) {
                  return renderAggregatedValue(item.numberOfAttachedDevices, item.totalNumberOfDevices, item, 'devicesCountDetails');
               },
               sortable: function(item1: any, item2: any) {
                  return compareNumericValues(item1, item2, 'numberOfAttachedDevices');
               }
            },
            {
               displayName: this.i18nService.getString('StorageUi', 'cluster.adapters.list.pathsColumn'),
               field: 'numberOfActivePaths',
               width: "150px",
               template: function(item:any) {
                  return renderAggregatedValue(item.numberOfActivePaths, item.totalNumberOfPaths, item, 'pathsCountDetails');
               },
               sortable: function(item1: any, item2: any) {
                  return compareNumericValues(item1, item2, 'numberOfActivePaths');
               }
            },
            {
               displayName: this.i18nService.getString('StorageUi', 'cluster.adapters.list.datastoresColumn'),
               field: 'numberOfDatastores',
               width: "100px",
               template: function(item:any) {
                  return textRenderer(['numberOfDatastores'], item);
               },
               sortable: function(item1: any, item2: any) {
                  return compareNumericValues(item1, item2, 'numberOfDatastores');
               }
            }
         ];
      }

      private overrideDeviceInfoData(receivedHostDataItems: any[]): void {
         const mergedHostItems: any[] = [];
         this.datagridOptions.data.forEach( (hostDataItem: any) => {
            const hostId: string = this.defaultUriSchemeUtil.getVsphereObjectId(hostDataItem.hostRef);
            const receivedHost: any = _.find(receivedHostDataItems, (receivedHostDataItem: any) =>
                  this.defaultUriSchemeUtil.getVsphereObjectId(receivedHostDataItem.hostRef) === hostId);

            if (receivedHost) {
               this.assignDeviceInfoData(hostDataItem, receivedHost);
            }
            mergedHostItems.push((<any>Object).assign({}, hostDataItem));
         });
         this.datagridOptions.data = mergedHostItems;
      }

      private overrideOverviewData(receivedHostDataItems: any[]): void {
         const mergedHostItems: any[] = [];
         this.datagridOptions.data.forEach( (hostDataItem: any) => {
            const hostId: string = this.defaultUriSchemeUtil.getVsphereObjectId(hostDataItem.hostRef);
            const receivedHost: any = _.find(receivedHostDataItems, (receivedHostDataItem: any) =>
                  this.defaultUriSchemeUtil.getVsphereObjectId(receivedHostDataItem.hostRef) === hostId);

            if (receivedHost) {
               this.assignOverviewData(hostDataItem, receivedHost);
            }
            mergedHostItems.push((<any>Object).assign({}, hostDataItem));
         });
         this.datagridOptions.data = mergedHostItems;
      }

      private assignDeviceInfoData(target: any, source?: any): void {
         if (!source) {
            target.numberOfActivePaths = undefined;
            target.numberOfAttachedDevices = undefined;
            target.numberOfStorageAdapters = undefined;
            target.numberOfTargets = undefined;
            target.pathsCountByStatus = undefined;
            target.totalNumberOfDevices = undefined;
            target.totalNumberOfPaths = undefined;
            target.pathsCountDetails = undefined;
            target.devicesCountDetails = undefined;
            return;
         }
         target.hostRef = source.hostRef;
         target.numberOfActivePaths = source.numberOfActivePaths;
         target.numberOfAttachedDevices = source.numberOfAttachedDevices;
         target.numberOfStorageAdapters = source.numberOfStorageAdapters;
         target.numberOfTargets = source.numberOfTargets;
         target.pathsCountByStatus = source.pathsCountByStatus;
         target.totalNumberOfDevices = source.totalNumberOfDevices;
         target.totalNumberOfPaths = source.totalNumberOfPaths;
         target.pathsCountDetails = undefined;
         if (source.numberOfActivePaths !== source.totalNumberOfPaths) {
            target.pathsCountDetails = this.getCountDetailsSignPostConfig(
                  this.i18n('StorageUi', 'cluster.adapters.list.pathDetails'),
                  source,
                  'pathsCountByStatus');
         }
         target.devicesCountDetails = undefined;
         if (source.numberOfAttachedDevices !== source.totalNumberOfDevices) {
            target.devicesCountDetails = this.getCountDetailsSignPostConfig(
                  this.i18n('StorageUi', 'cluster.adapters.list.deviceDetails'),
                  source,
                  'devicesCountByStatus');
         }
      }

      private assignOverviewData(target: any, source: any): void {
         target.hostRef = source.hostRef;
         target.iconId = source.iconId;
         target.labelIds = source.labelIds;
         target.name = source.name;
         target.numberOfDatastores = source.numberOfDatastores;
      }

      private getNoValueString(): string {
         return this.i18n("StorageUi", "cluster.adapters.list.noValue");
      }

      private handleMissingDeviceInfo(): void {
         if (this.datagridOptions.data && this.datagridOptions.data.length) {
            _.forEach(this.datagridOptions.data, (dataItem:any) => {
               this.assignDeviceInfoData(dataItem);
            });
            this.datagridOptions.data = [].concat(this.datagridOptions.data);
         }
      }
   }

   angular.module('com.vmware.vsphere.client.storage')
      .component('clusterStorageAdaptersOverview', {
         templateUrl: 'storage-ui/resources/storage/views/cluster/adapters/ClusterStorageAdaptersOverview.html',
         controller: ClusterStorageAdaptersOverview
      });
}
