/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace network_ui {
   export class EditVirtualSwitchSecurityPageComponent {

      public bindings: any;
      public templateUrl: string;
      public controller: any;

      constructor() {
         this.bindings = {
            pageModel: "<"
         };

         this.templateUrl = "network-ui/resources/network/views/host/vswitch/edit/" +
               "views/editVirtualSwitchSecurityPageComponent.html";

         this.controller = EditVirtualSwitchSecurityPageComponentController;
      }
   }

   class EditVirtualSwitchSecurityPageComponentController {

      static $inject = [
         "i18nService",
         "$scope"
      ];

      public pageModel: SecurityPageModel;
      private options: any[];

      constructor(public i18nService: any,
                  private $scope: any) {
         this.options = this.getOptions();
      }

      private getOptions() {

         let options = [{
            label: this.i18nService.getString("NetworkUi", "PortSecurityPolicyPage.accept"),
            val: true
         }, {
            label: this.i18nService.getString("NetworkUi", "PortSecurityPolicyPage.reject"),
            val: false
         }];

         return options;
      }
   }

   angular.module("com.vmware.vsphere.client.network")
         .component("editVirtualSwitchSecurityPage",
               new EditVirtualSwitchSecurityPageComponent());
}
