module cluster_ui {

   import IRootScopeService = angular.IRootScopeService;
   import ScheduleDrsWizardManager = cluster_ui.ScheduleDrsWizardManager;

   export class ScheduleDrsWizardService {

      public static $inject = ['$rootScope', 'i18nService', 'vuiWizardService',
            'scheduleDrsWizardManager', 'pageStateManagementService', 'EditDrsDialogManager'];

      constructor(private $rootScope: IRootScopeService,
            private i18nService: any, private vuiWizardService: any,
            private wizardManager: ScheduleDrsWizardManager,
            private pageStateManagementService: any,
            private EditDrsDialogManager: new () => EditDrsDialogManager) { }

      /**
       * Displays schedule drs wizard.
       */
      public show(actionEval: any, objectId: string, focusTarget: any): void {
         let wizardScope: any = this.$rootScope.$new();

         this.wizardManager.init(objectId, actionEval.additionalData);
         const isEditScheduledTaskMode: boolean = actionEval.additionalData
               && actionEval.additionalData.isScheduledTask
               && actionEval.additionalData.schedulingData.scheduleTaskOperationMode === 1;
         wizardScope.wizardConfig =
               this.wizardManager.getWizardConfig(actionEval.additionalData);
         wizardScope.manager = this.wizardManager;
         wizardScope.modalOptions = {
            focusTarget: focusTarget,
            dialogData: {
               manager: new this.EditDrsDialogManager(),
               editDrsData: isEditScheduledTaskMode ?
                     actionEval.additionalData.schedulingData.spec : {},
               objectId: objectId,
               pageModel: null,
               isEditScheduledTaskMode: isEditScheduledTaskMode
            }
         };
         this.pageStateManagementService.registerWizardForDefaultStateManagementStrategy(
               wizardScope);

         this.vuiWizardService({
            scope: wizardScope,
            configObjectName: 'wizardConfig'
         }).show();
      }
   }

   angular.module('com.vmware.vsphere.client.cluster')
         .service('scheduleDrsWizardService', ScheduleDrsWizardService);
}
