namespace storage_ui {

   import IScope = angular.IScope;

   export class AllocateSpaceDialogService {

      public static $inject = ["i18nService", "mutationService", "datastoreConstants", "defaultUriSchemeUtil", "clarityModalService"];

      constructor(private i18nService: any,
                  private mutationService: any,
                  private datastoreConstants: any,
                  private defaultUriSchemeUtil: any,
                  private clarityModalService: any) {
      }

      public openSpaceAllocationDialog(datastore: any, hostId: any) {
         let maxSize: number = datastore.cacheSpace / this.datastoreConstants.MB_TO_GB + datastore.freeSpace / this.datastoreConstants.BYTES_TO_GB;
         let capacityInGb: number = datastore.capacity / this.datastoreConstants.BYTES_TO_GB;

         let modalOptions: any = {
            title: this.i18nService.getString("StorageUi", "storage.swapToSsd.editCacheConfiguration.title"),
            contentTemplate: "storage-ui/resources/storage/views/host/swapToSsd/AllocateSpaceDialog.html",
            dialogData: {
               i18n: this.i18nService.getString,
               cacheConfig: {
                  allocatedSpace: datastore.cacheSpace / this.datastoreConstants.MB_TO_GB,
                  // This logic is added, because the backend returns incorrect data once
                  // you allocate space for cache and some cases may occur where the maxSize
                  // exceeds the capacity of the datastore. Should be removed once the bug is resolved.
                  maxSize: maxSize > capacityInGb ? capacityInGb : maxSize
               }
            },
            onSubmit: () => {
               let allocatedSpace =
                     modalOptions.dialogData.cacheConfig.allocatedSpace < modalOptions.dialogData.cacheConfig.maxSize ?
                           modalOptions.dialogData.cacheConfig.allocatedSpace :
                           modalOptions.dialogData.cacheConfig.maxSize;

               this.updateCacheConfiguration(datastore, hostId, allocatedSpace * this.datastoreConstants.MB_TO_GB);
               return true;
            }
         };

         this.clarityModalService.openOkCancelModal(modalOptions);
      }

      private updateCacheConfiguration(datastore: any, hostId: any, allocatedSpace: any) {
         let spec = {
            _type: "com.vmware.vim.binding.vim.host.CacheConfigurationManager$CacheConfigurationSpec",
            datastore: this.defaultUriSchemeUtil.getManagedObjectReference(datastore.datastoreId),
            swapSize: allocatedSpace
         };

         this.mutationService.apply(
               hostId,
               spec._type,
               spec,
               this.i18nService.getString("StorageUi", "storage.swapToSsd.editCacheConfiguration.title"));
      }
   }

   angular.module("com.vmware.vsphere.client.storage")
         .service("allocateSpaceDialogService", AllocateSpaceDialogService);
}
