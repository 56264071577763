module ds_cluster_ui {
   export class DsClusterVmConnectivityMasterController {
      public watchForObjects: string[];
      public objectId: string;
      public masterViewGridOptions: any;
      private selectedItem: any = null;
      private liveRefreshProperties: Array<string>;

      public static $inject = ["$scope", "i18nService", "dataService", "navigation",
         "dsClusterConectivityService", "dsClusterConstants"];

      constructor(private $scope: MasterViewScope, private i18nService: any,
                  private dataService: any,
                  private navigation:any,
                  private dsClusterConectivityService: any,
                  private dsClusterConstants: any) {
         this.objectId = navigation.getRoute().objectId;
         this.watchForObjects = [this.objectId];
         this.liveRefreshProperties = [
               this.dsClusterConstants.liveRefreshProperties.SUMMARY,
               this.dsClusterConstants.liveRefreshProperties.STORAGE_DRS_CONFIG
         ];
         this.masterViewGridOptions = this.dsClusterConectivityService
               .getVmConnectivityMasterViewGridOptions();

         $scope.$watch("ctrl.masterViewGridOptions.selectedItems", (newValue: any) => {
                  if (newValue && newValue.length > 0) {
                     $scope.masterDetailsViewContext.selectedItems = newValue;
                     this.selectedItem = newValue[0];
                  } else if ($scope.masterDetailsViewContext !== undefined) {
                     $scope.masterDetailsViewContext.selectedItems = [];
                  }
               });

         this.getViewData();
      }

      public getViewData(): void {
         this.dataService.getData(this.objectId,
             "com.vmware.vsphere.client.dsclusterui.model.DsClusterConnectivityVmData")
               .then((connectivityData: any) => {
                  this.masterViewGridOptions.data = connectivityData.vms;
               });
      }
   }

   angular.module("com.vmware.vsphere.client.dsCluster")
         .controller("DsClusterVmConnectivityMasterController",
               DsClusterVmConnectivityMasterController);
}
