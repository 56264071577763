/* Copyright 2015 VMware, Inc. All rights reserved. -- VMware Confidential */
/*
 * HostConfigurationController
 */
angular.
module('com.vmware.vsphere.client.host').
controller('HostConfigurationController', ['$scope', '$controller', '$sce', 'i18nService', 'dataService',
   function($scope, $controller, $sce, i18nService, dataService) {

      function getImageProfileData() {
         dataService.getProperties($scope._route.objectId, ['imageProfileName'])
            .then(function(data) {
               $scope.imageProfile = !!data['imageProfileName'] ?
                  data['imageProfileName'] :
                  i18nService.getString('HostUi', 'config.imageProfileNotAvailable');
            });
      }

      function getHaStateImage() {
         if (!$scope.data || !$scope.data.haStateImage) {
            return null;
         }

         switch ($scope.data.haStateImage) {
            case 'statusGreen':
               return "vsphere-icon-status-ok";
            case 'statusYellow':
               return "vsphere-icon-status-warning";
            case 'statusRed':
               return "vsphere-icon-status-error";
            case 'statusUnknown':
               return "vsphere-icon-status-unknown";
            default:
               return null;
         }
      }

      function getHaStateImageAriaLabel() {
         if (!$scope.data || !$scope.data.haStateImage) {
            return null;
         }

         switch ($scope.data.haStateImage) {
            case 'statusYellow':
               return "Common:entityStatus.yellow";
            case 'statusRed':
               return "Common:entityStatus.red";
            case 'statusUnknown':
               return "Common:entityStatus.gray";
            default:
               return null;
         }
      }

      function getHaStateDescriptionHtml() {
         if (!$scope.data || !$scope.data.haStateString) {
            return null;
         }

         return $sce.trustAsHtml(i18nService.getString('HostUi', $scope.data.haStateString + '.description'));
      }

      function getUpFtUnsupportedReasonsRows() {
         if (!$scope.data || $scope.data.ftSupported || !Array.isArray($scope.data.ftUnsupportedReasonRows)) {
            return [];
         }

         return $scope.data.ftUnsupportedReasonRows;
      }

      function getSmpFtUnsupportedReasonsRows() {
         if (!$scope.data || $scope.data.cptFtSupported || !Array.isArray($scope.data.cptUnsupportedReasonRows)) {
            return [];
         }

         return $scope.data.cptUnsupportedReasonRows;
      }

      angular.extend(this, $controller('GenericPortletController', { $scope: $scope }));

      this.getHaStateImage = getHaStateImage;
      this.getHaStateImageAriaLabel = getHaStateImageAriaLabel;
      this.getHaStateDescriptionHtml = getHaStateDescriptionHtml;
      this.getUpFtUnsupportedReasonsRows = getUpFtUnsupportedReasonsRows;
      this.getSmpFtUnsupportedReasonsRows = getSmpFtUnsupportedReasonsRows;

      this.liveRefreshProperties = ['runtime.dasHostState', 'summary.currentEVCModeKey',
         'runtime.connectionState', 'config.virtualNicManagerInfo.netConfig'];

      $scope.imageProfile = i18nService.getString('HostUi', 'config.imageProfileLoading');
      getImageProfileData();

      $scope.$watch("data.dasHostState && data.dasHostState.state", function (value) {
         if (!value) {
            $scope.haStackBlockExpanded = false;
         }
      });

      $scope.$watch("ctrl.getUpFtUnsupportedReasonsRows().length > 0", function (value) {
         if (!value) {
            $scope.upFtStackBlockExpanded = false;
         }
      });

      $scope.$watch("ctrl.getSmpFtUnsupportedReasonsRows().length > 0", function (value) {
         if (!value) {
            $scope.smpFtStackBlockExpanded = false;
         }
      });
   }]);
