/* Copyright 2015 VMware, Inc. All rights reserved. -- VMware Confidential */
(function () {
   'use strict';

   angular
      .module('com.vmware.vsphere.client.vm')
      .controller('VmProvisioningCloneGuestOsController', VmProvisioningCloneGuestOsController);

   VmProvisioningCloneGuestOsController.$inject = [
      '$scope',
      'i18nService',
      'wizardPageService'
   ];

   function VmProvisioningCloneGuestOsController($scope,
                                                 i18nService,
                                                 wizardPageService) {
      var self = this;
      var model = $scope.vmProvisioningCloneCustomizeGuestOsPageModel;
      self.form = model.getForm();
      self.readyToView = false;
      self.uncustomizableMessage = '';

      model.resetForm();

      self.form.setPageLoading = function (bool) {
         $scope.config.loading = bool;
      };

      self.form.showUserSettingsPage = function(showPage) {
         var pages = $scope.config.pages;

         if ($scope.config.flows && $scope.config.flows.length > 0) {
            var flow = _.find($scope.config.flows, function(flow) {
               return $scope.attributes.creationType === flow.id;
            });
            if (flow) {
               pages = flow.pages;
            }
         }
         if (showPage) {
            wizardPageService.markPageDisabled(
                  pages,
                  i18nService.getString('VmUi', 'UserSettingsPage.Title')
            );
         } else {
            wizardPageService.markPageSkipped(
                  pages,
                  i18nService.getString('VmUi', 'UserSettingsPage.Title'));
         }
      };

      $scope.preselectGuestOsComparator = function(guestOs) {
         if (!self.form.selectedSpec) {
            return false;
         }

         return guestOs.name === self.form.selectedSpec.name;
      };

      $scope.$watch(
         function() { return self.form.datagridOptions.selectedItems;},
         function(newItems, oldItems) {
            if (newItems !== oldItems) {
               wizardPageService.clearErrors($scope.config);
               self.form.selectedSpec = !newItems || newItems.length === 0 ?
                     null :
                     newItems[0];
            }
         },
         /* inequality of the self.form.datagridOptions.selectedItems is determined
            according to the angular.equals function. */
         true);
   }
})();
