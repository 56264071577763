(function() {
   'use strict';
   angular.module('com.vmware.vsphere.client.cluster')
         .controller('VmAndHostRulesController', VmAndHostRulesController);

   VmAndHostRulesController.$inject = ['$scope', 'i18nService', 'clusterRulesService',
         'vuiConstants', 'clusterRulesConstants', 'datagridActionBarService'];

   function VmAndHostRulesController($scope, i18nService, clusterRulesService,
          vuiConstants, clusterRulesConstants, datagridActionBarService) {

      var self = this;
      var simpleTextWithTitleKendoRendererFunction = kendo.template('<span title="#:data#">#:data#</span>');
      var objectId = $scope._route.objectId;
      var actionSpecs = [{
         actionId: 'vsphere.core.cluster.actions.rule.add'
      }, {
         actionId: 'vsphere.core.cluster.actions.rule.edit',
         getActionInvocationContext: function() {
            return {
               selectedRule: self.selectedRule
            };
         },
         isActionAvailable: function(actionDef) {
            return actionDef.available && self.gridOptions.selectedItems.length > 0;
         }
      }, {
         actionId: 'vsphere.core.cluster.actions.rule.remove',
         getActionInvocationContext: function() {
            return {
               selectedRule: self.selectedRule
            };
         },
         isActionAvailable: function(actionDef) {
            return actionDef.available && self.gridOptions.selectedItems.length > 0;
         }
      }];
      var datagridActionBarServiceCacheObject = null;

      self.VM_AFFINITY_RULE_TYPE = clusterRulesConstants.ruleType.VM_AFFINITY_RULE;
      self.VM_ANTIAFFINITY_RULE_TYPE = clusterRulesConstants.ruleType.VM_ANTIAFFINITY_RULE;
      self.VM_HOST_GROUP_RULE_TYPE = clusterRulesConstants.ruleType.VM_HOST_GROUP_RULE;
      self.VM_DEPENDENCY_RULE_TYPE = clusterRulesConstants.ruleType.VM_DEPENDENCY_RULE;

      self.watchForObjects = [objectId];
      self.liveRefreshProperties = [
            "configurationEx.dasConfig.option",
            "configurationEx.dasConfig.enabled",
            "configurationEx.rule"
      ];
      self.getViewData = getViewData;
      self.gridOptions = getGridOptions();
      self.selectedRule = null;

      $scope.rulesPreselectComparator = rulesPreselectComparator;

      self.rulesTitle = i18nService.getString('ClusterUi', 'manage.settings.rules');

      self.noSelectedItems = i18nService.getString('ClusterUi',
            'rules.view.noRuleSelected');

      $scope.$watch('ctrl.gridOptions.selectedItems', onSelectedRuleChange);
      $scope.$watch(
            function() {
               return self.selectedRule;
            }, function() {
               refreshActionBar();
            });

      getViewData();
      createActionBar();

      function getViewData() {
         clusterRulesService.getRulesData(objectId).then(function(data) {
            self.gridOptions.data = _.map(data.rules, function(rule) {
               return angular.merge({
                  _original: rule
               }, rule);
            });
            keepSelectionOrPreselectFirst();
         });
      }

      function createActionBar() {
         datagridActionBarService
               .updateActionBar(self.gridOptions, [objectId], actionSpecs)
               .then(function(value) {
                  datagridActionBarServiceCacheObject = value;
               });
      }

      function refreshActionBar() {
         if (!datagridActionBarServiceCacheObject) {
            return;
         }

         datagridActionBarService.updateActionBar(
               self.gridOptions, [objectId], actionSpecs, datagridActionBarServiceCacheObject
         );
      }

      function keepSelectionOrPreselectFirst() {
         if (self.gridOptions.data && self.gridOptions.data.length > 0) {
            var itemToPreselect;

            if (self.selectedRule) {
               itemToPreselect = _.find(self.gridOptions.data, {
                  name: self.selectedRule.name
               });
            }

            self.selectedRule = !!itemToPreselect ?
                  itemToPreselect._original : self.gridOptions.data[0]._original;
         }
      }

      function onSelectedRuleChange(newSelectedItems) {
         if (!newSelectedItems || newSelectedItems.length === 0) {
            self.selectedRule = null;
            return;
         }
         //compare new selected item name with current selected rule name
         //instead of with old selected item name from grid to prevent changing the
         //selecting rule twice on preselect and triggering its watcher twice
         if (self.selectedRule && newSelectedItems[0].name === self.selectedRule.name) {
            return;
         }

         self.selectedRule = newSelectedItems[0]._original;
      }

      function getGridOptions() {
         return {
            actionBarOptions: {
               actions: []
            },
            searchable: false,
            resizable: true,
            data: [],
            selectionMode: vuiConstants.grid.selectionMode.SINGLE,
            selectedItems: [],
            columnDefs: getColumnDefs(),
            height: '100%',
            pageConfig: {
               hidePager: true
            }
         };
      }

      function getColumnDefs() {
         return [
            {
               displayName: i18nService.getString('ClusterUi', 'rules.list.name'),
               field: 'name',
               type: 'string',
               template: '<span class="object" title="#:name#"><i class="#:icon#")></i>#:name#</span>'
            },
            {
               displayName: i18nService.getString('ClusterUi', 'rules.list.type'),
               field: 'typeText',
               type: 'string',
               template: '<span title="#:typeText#">#:typeText#</span>'
            },
            {
               displayName: i18nService.getString('ClusterUi', 'rules.list.enabled'),
               field: 'enabled',
               type: 'boolean',
               template: enabledTextRenderer
            },
            {
               displayName: i18nService.getString('ClusterUi', 'rules.list.conflicts'),
               field: 'conflictingRules.length',
               type: 'string',
               template: '<span title="#:conflictingRules.length#">#:conflictingRules.length#</span>'
            },
            {
               displayName: i18nService.getString('ClusterUi', 'rules.list.definedBy'),
               field: 'userCreated',
               type: 'boolean',
               template: definedByTextRenderer
            }
         ];
      }

      function enabledTextRenderer(data) {
         var text = (data.enabled) ?
               i18nService.getString('ClusterUi', 'rules.list.enabled.yes') :
               i18nService.getString('ClusterUi', 'rules.list.enabled.no');
         return simpleTextWithTitleKendoRendererFunction(text);
      }

      function definedByTextRenderer(data) {
         var text = (data.userCreated) ?
               i18nService.getString('ClusterUi', 'rules.list.definedBy.user') :
               i18nService.getString('ClusterUi', 'rules.list.definedBy.system');
         return simpleTextWithTitleKendoRendererFunction(text);
      }

      function rulesPreselectComparator(ruleItem) {
         return self.selectedRule && ruleItem &&
               self.selectedRule.name === ruleItem.name;
      }
   }
})();
