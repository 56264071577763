module hostprofile_ui {
    class AttachHostProfilePageController implements angular.IController {
        static $inject = [
            "$scope",
            "dataService",
            "attachHostProfileService",
            "i18nService",
            "vuiConstants"
        ];

        public gridOptions: any;
        public selectedItem: any;
        public loading: boolean = true;
        public filterPlaceholderText: string;
        public searchTerm: string;
        public dataGridOriginalData: any;
        private objectId: string;

        constructor(private $scope: any,
                    private dataService: any,
                    private attachHostProfileService: AttachHostProfileService,
                    private i18nService: any,
                    private vuiConstants: any) {

            this.gridOptions = attachHostProfileService.getGridOptions();
            this.filterPlaceholderText = i18nService.getString('Common', 'filter.label');
            this.searchTerm = "";
            this.objectId = $scope.modalOptions.dialogData.objectId;
            $scope.modalOptions.onSubmit = this.submitProfile.bind(this);
            attachHostProfileService.fetchData(this.objectId).then((data: any) => {
                this.gridOptions.data = data;
                this.dataGridOriginalData = data;
                this.loading = false;
            });
        }

        public filterSettings() {
           const propertiesToSearchInFor: string[] = ["description", "name"];
           this.gridOptions.data = this.attachHostProfileService.filterSettings(
                 this.dataGridOriginalData,
                 this.searchTerm,
                 propertiesToSearchInFor);
        }

        private submitProfile(): boolean {
            this.$scope.modalOptions.isAlertClosed = true;
            this.$scope.modalOptions.alerts = [];

            if (this.gridOptions.selectedItems[0]) {
                this.attachHostProfileService.attachHostProfile(this.objectId, this.gridOptions.selectedItems[0]);
                return true;
            } else {
                this.$scope.modalOptions.alerts = [{
                    text: this.i18nService.getString("HostProfileUi", "attachWizard.hostProfileSelectionPage.errors.selectProfile"),
                    type: this.vuiConstants.validationBanner.type.ERROR
                }];
                this.$scope.modalOptions.isAlertClosed = false;
            }

            return false;
        }
    }

    angular.module("com.vmware.vsphere.client.hostprofile")
        .controller("AttachHostProfilePageController", AttachHostProfilePageController);
}
