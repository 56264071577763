/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace dvs_ui {

   export class DvpgTrafficRuleListItem {
      public sequence: number;
      public name: string;
      public actionName: string;
      public direction: string;
      public trafficQualifiers: string[];
   }
}
