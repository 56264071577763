namespace license_ui {

   import IController = angular.IController;

   export class AssetsMasterDetailsViewController implements IController {
      public static $inject = ["$scope", "i18nService", "$q"];

      public detailsViewData: any;
      public masterDetailsViewContext: any;

      public assetType: any;
      public selectedTab: any;
      public scopeProvider: any;
      public selectedAssetTabId: any;
      public focusedAssetItemUri: string;

      constructor(private $scope: any,
                  private i18nService: any,
                  private $q: ng.IQService) {
      }

      public $onInit() {
         this.masterDetailsViewContext = {
            assetType: this.assetType,
            getSelectedTab: () => {
               return this.selectedTab;
            },
            scopeProvider: this.scopeProvider,
            getSelectedAssetTabId: () => {
               return this.selectedAssetTabId;
            },
            getFocusedAssetItemUri: () => {
               return this.focusedAssetItemUri;
            }
         };

         this.detailsViewData = {
            tabsPromise: this.$q.when([
               {
                  name: this.i18nService.getString("LicenseLibUi", "management.products.details.summary"),
                  templateUrl: "license-ui/resources/license/views/management/licenses/assets/assetsDetailsSummaryTabView.html",
                  uid: "assets.summaryTab"
               },
               {
                  name: this.i18nService.getString("LicenseLibUi", "management.assets.details.featuresTab"),
                  templateUrl: "license-ui/resources/license/views/management/licenses/assets/assetsDetailsFeaturesTabView.html",
                  uid: "assets.featuresTab"
               }
            ])
         };
      }
   }

   angular.module("com.vmware.vsphere.client.licenseUi")
         .component("assetsMasterDetailsViewWrapper", {
            controller: AssetsMasterDetailsViewController,
            template: `<div class="fill-parent">
                              <vx-master-details-component
                                    master-view-template-url="license-ui/resources/license/views/management/licenses/assets/assetsGridView.html"
                                    details-view-template-url="resources/ui/components/masterdetails/vxTabbedDetailsView.html"
                                    details-view-data="$ctrl.detailsViewData"
                                    master-details-view-context="$ctrl.masterDetailsViewContext"
                                    is-resizable="true">
                              </vx-master-details-component>
                           </div>`,
            bindings: {
               assetType: "<",
               selectedTab: "<",
               scopeProvider: "<",
               focusedAssetItemUri: "<"
            }
         });

}
