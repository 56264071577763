namespace h5_vm {
   import VirtualDiskOption$CompatibilityMode = com.vmware.vim.binding.vim.vm.device.VirtualDiskOption$CompatibilityMode;

   interface CompatibilityModeOption {
      name: string;
      value: VirtualDiskOption$CompatibilityMode;
   }

   interface CompatibilityMode {
      options: CompatibilityModeOption[];
      selection: CompatibilityModeOption;
   }

   class VmHardwareVirtualDiskCompatibilityModeController implements ng.IComponentController {

      private compatibilityModeInfo: CompatibilityMode;
      private isDisabled: boolean;
      private onChange: Function;

      constructor() {}

      onCompatibilityModeChange(newSelection: CompatibilityModeOption) {
         this.onChange({
            compatibilityMode: newSelection.value
         });
      }
   }

   export class VmHardwareVirtualDiskCompatibilityMode implements ng.IComponentOptions {
      bindings: any;
      controller: any;
      templateUrl: string;

      constructor() {
         this.bindings = {
            isDisabled: '<',
            ariaLabel: '<',
            compatibilityModeInfo: '<',
            onChange: '&'
         };
         this.controller = VmHardwareVirtualDiskCompatibilityModeController;
         this.templateUrl = 'vm-ui/resources/vm/views/settings/vmHardwareSettings/vmHardwareVirtualDiskShared/' +
               'vmHardwareVirtualDiskCompatibilityMode/vm-hardware-virtual-disk-compatibility-mode.html';

      }
   }

   angular.module('com.vmware.vsphere.client.vm')
         .component('vmHardwareVirtualDiskCompatibilityMode', new VmHardwareVirtualDiskCompatibilityMode());
}
