namespace storage_ui {

   import AddSoftwareAdapterControlApi = storage_ui.AddSoftwareAdapterControlApi;

   export class AddSoftwareAdapterDialogService {

      public static $inject = ["clarityModalService", "mutationService", "i18nService"];

      constructor(private clarityModalService: any,
                  private mutationService: any,
                  private i18nService: any) {
      }

      public show(hostId: string, context: any): void {
         let adapterControlApi = new AddSoftwareAdapterControlApi();

         let dialogData: any = {
            hostId: hostId,
            apiObj: adapterControlApi
         };

         let modalOptions = {
            contentTemplate: "storage-ui/resources/storage/views/host/adapters/add/AddSoftwareAdapterDialog.html",
            title: this.i18nService.getString("StorageUi", "storage.adapters.actions.addAdapter.addSoftwareAdapter"),
            subTitle: {
               objectId: hostId
            },
            size: "lg",
            defaultButton: "submit",
            onSubmit: onSubmit.bind(this),
            dialogData: dialogData
         };

         function onSubmit(this:any): boolean {
            if (!adapterControlApi.validate()) {
               return false;
            }

            let mutationOperation = adapterControlApi.createSpec();
            if (mutationOperation && mutationOperation.spec) {
               this.mutationService.apply(
                     hostId,
                     mutationOperation.spec._type,
                     mutationOperation.spec,
                     mutationOperation.operationName)
                     .then(function (result:any) {
                        if (result && !result.error && context.callback) {
                           context.callback(adapterControlApi.isSwBasedIscsiAdapter());
                        }
                     });
            }

            return true;
         }

         this.clarityModalService.openOkCancelModal(modalOptions);
      }
   }

   angular.module("com.vmware.vsphere.client.storage")
         .service("addSoftwareAdapterDialogService", AddSoftwareAdapterDialogService);
}

