/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace network_ui {

   export class GhostDvsProxySwitchDetailsController {

      static $inject = ["$scope"];

      public dialogData: any;

      constructor(protected $scope: any) {
         this.dialogData = $scope.modalOptions.dialogData;

         let onRouteChangeEventHandler = $scope.$on("vxRouteChangeSuccess", () => {
            $scope.modalOptions.modalOpen = false;
         });

         $scope.$on("$destroy", () => {
            onRouteChangeEventHandler();
         });
      }
   }

   angular.module('com.vmware.vsphere.client.network')
         .controller('GhostDvsProxySwitchDetailsController', GhostDvsProxySwitchDetailsController);
}