/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace dvs_ui {

   export class DvpgAdvancedPageComponent {

      public bindings: any;
      public controller: any;
      public templateUrl: string;

      constructor() {
         this.bindings = {
            model: "<",
            hasPolicyPrivilege: "<"
         };

         this.controller = DvpgAdvancedPageController;

         this.templateUrl = "dvs-ui/resources/dvs/dvpg/common/components/" +
               "dvpgAdvancedPageTemplate.html";
      }
   }

   class DvpgAdvancedPageController {

      static $inject = [
         "i18nService"
      ];

      public model: any;

      private options: any[];

      private i18n: Function;

      private supportedPolicies: any;

      private hasPolicyPrivilege: boolean;

      constructor(private i18nService: any) {
         this.i18n = this.i18nService.getString;

         this.options = [{
            value: true,
            label: this.i18n("NetworkUi",
                  "dvpg.properties.resetAtDisconnect.on")
         },{
            value: false,
            label: this.i18n("NetworkUi",
                  "dvpg.properties.resetAtDisconnect.off")
         }];

         this.supportedPolicies = {};
      }

      $onInit(): void {
         _.each(this.model, (value: any, key: string): void => {
            if (value !== null && typeof value !== "undefined") {
               this.supportedPolicies[key] = true;
            }
         });
      }
   }

   angular.module("com.vmware.vsphere.client.dvs").component(
         "dvpgAdvancedPage", new DvpgAdvancedPageComponent());
}