(function() {
   'use strict';
   angular.module('com.vmware.vsphere.client.cluster')
      .controller('ClusterConsumersController', ClusterConsumersController);

   ClusterConsumersController.$inject = ['$scope', '$controller', 'i18nService'];

   function ClusterConsumersController($scope, $controller, i18nService) {
      var self = this;
      angular.extend(self, $controller('GenericPortletController', { $scope: $scope }));

      self.max = _.max;
      self.liveRefreshProperties = ["summary.currentBalance"];
      self.getString = getString;

      function getString(key) {
         return i18nService.getString('ClusterUi', key);
      }
   }
})();