module cluster_ui {

   import IController = angular.IController;

   class EditDrsFileDownloadController implements IController {

      public static $inject = [
         "$scope",
         "$element",
         "editDrsFileDownloadService"];

      private DOWNLOAD_LINK_SELECTOR: string =
            ".edit-cluster-drs-dialog-confirmation-modal-download-section a";

      private objectId: string;
      private rpTreeSnapshotData: any;
      private clusterName: string;

      constructor(private $scope: any,
                  private $element: any,
                  private editDrsFileDownloadService: EditDrsFileDownloadService) {

         this.objectId = this.$scope.modalOptions.dialogData.objectId;
         this.getViewData();
      }

      private getViewData(): void {
         this.editDrsFileDownloadService.getSnapshotTreeData(this.objectId)
               .then((response: any) => {
            if(response && response.rpTreeSnapshot && response.name) {
               this.rpTreeSnapshotData = response.rpTreeSnapshot;
               this.clusterName = response.name;
            }

         });
      }

      private saveSnapshot(): void {

         if(this.rpTreeSnapshotData && this.clusterName && this.clusterName !== "") {
            let fileData: any =
                  this.editDrsFileDownloadService.createFileFromData(
                        this.rpTreeSnapshotData, this.clusterName);

            // Handle IE saving file
            if (window.navigator && window.navigator.msSaveBlob) {
               window.navigator.msSaveBlob(fileData.fileAsBlob, fileData.fileName);

            } else {
               let downloadLinkElement: any =
                     this.$element.find(this.DOWNLOAD_LINK_SELECTOR)[0];
               downloadLinkElement.href = fileData.fileUrl;
               downloadLinkElement.download = fileData.fileName;
               downloadLinkElement.click();
            }
         }
      }

      public saveRpTreeAsSnapshotFile(): void {
         this.saveSnapshot();
         this.$scope.modalOptions.dialogData.deferred.resolve();
         this.$scope.closeModal();
      }

      public turnOffDrsWithoutSavingRpTree(): void {
         this.$scope.modalOptions.dialogData.deferred.resolve();
         this.$scope.closeModal();
      }

      public closeConfirmationModal(): void {
         this.$scope.modalOptions.dialogData.deferred.reject();
         this.$scope.closeModal();
      }

   }

   angular.module("com.vmware.vsphere.client.cluster")
         .controller("EditDrsFileDownloadController",
               EditDrsFileDownloadController);
}
