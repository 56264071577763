namespace h5_vm {

   export interface VmVersion {
      id: number;
      name: string;
      compatibilityInfo: string;
      vms?: string[];
   }

   export class VmHardwareCompatibility {
      static $inject: string[] = ['i18nService', 'helpService', 'idGeneratorService'];

      private HW_VERSION_INFO_ID:string = "com.vmware.vsphere.vm_admin.doc.vc_312";
      private supportedVersions: VmVersion[];
      private selectedVersion: VmVersion;
      private onChangeMethod: Function;
      private allVmsLength: number;
      private compatibilityLabel: string;
      private popupParams: PopupParams;
      private scheduleUpgradeOnPowerOfLabel: string;
      private showMultipleVmsWarning: boolean;
      private multipleVmsWarning: string;
      private descriptionId: string;

      constructor(private i18nService: any,
                  private helpService: any,
                  private idGeneratorService: any) {

         this.compatibilityLabel =
               this.i18nService.getString('VmUi', 'hwVersion.selector.label');

         this.popupParams = {
            title: this.i18nService.getString('Common', 'help'),
            message: this.i18nService.getString('Common', 'signpost.hardwareVersionSelector'),
            linkLabel: this.i18nService.getString('VmUi', 'hwVersion.info.helperlink.label'),
            linkFunction: () => {
               helpService.showHelpPage(this.HW_VERSION_INFO_ID);
            }
         };

         this.scheduleUpgradeOnPowerOfLabel =
               this.i18nService.getString('VmUi', 'VmUpgradePage.onSoftPowerOff');

         this.showMultipleVmsWarning = false;
      }

      public $onInit(): void {
         this.changeUpgradabelWarning();

         this.descriptionId = this.idGeneratorService.nextId();
      }

      public selectionChange(): void {
         this.changeUpgradabelWarning();
         if (this.onChangeMethod && typeof this.onChangeMethod === 'function') {
            this.onChangeMethod(this.selectedVersion);
         }
      }

      private changeUpgradabelWarning(): void {
         if (this.allVmsLength > 1) {
            let vmsLength: number = 0;
            if (this.selectedVersion.vms) {
               vmsLength = this.selectedVersion.vms.length;
            }
            this.showMultipleVmsWarning = true;
            this.multipleVmsWarning =
                  this.i18nService.getString(
                        'VmUi',
                        'SelectVmVersionPage.UpgradableWarning',
                        vmsLength,
                        this.allVmsLength);
         }
      }
   }

   angular.module('com.vmware.vsphere.client.vm')
         .component('vmHardwareCompatibility', {
            templateUrl: 'vm-ui/resources/vm/views/compatibility/vmHardwareCompatibility.html',
            controller: VmHardwareCompatibility,
            /**
             *
             * description                     text to be displayed above the select
             * supportedVersions               the available hardware versions
             * selectedVersion                 object containing the expected selected version,
             *                                 should contain `id` property
             * onChangeMethod(optional)        Method to be called when selection changes
             * scheduleMode(optional)          flag, set to true to show the upgrade checkbox
             *                                 for upgrade after guest os shutdown
             * isUpgradeAfterGuestOsShutdown   flag true for the upgrade after guest os
             *                    (optional)   shutdown checkbox to be checked
             * allVmsLength(optional)           in case the operation is called on
             *                                 multiple objects,should contain
             *                                 count of all the selected vms
             */
            bindings: {
               description: '<',
               supportedVersions: '=',
               selectedVersion: '=',
               onChangeMethod: '<',
               scheduleMode: '<',
               isUpgradeAfterGuestOsShutdown: '=',
               allVmsLength: '<'
            }
         });
}
