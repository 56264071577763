(function() {
   'use strict';
   angular.module('com.vmware.vsphere.client.commonModule')
      .factory('customAttributesService', customAttributesService);

   customAttributesService.$inject = [
      'dataService',
      'mutationService',
      'i18nService',
      'defaultUriSchemeUtil',
      'userSessionService'];

   function customAttributesService(
         dataService,
         mutationService,
         i18nService,
         defaultUriSchemeUtil,
         userSessionService) {
      return {
         getCustomAttributes: getCustomAttributes,
         modifyCustomAttributes: modifyCustomAttributes,
         getAllCustomAttributes: getAllCustomAttributes,
         deleteCustomAttribute: deleteCustomAttribute,
         renameCustomAttribute: renameCustomAttribute,
         addCustomAttribute: addCustomAttribute
      };


      function deleteCustomAttribute(item) {
         return userSessionService.getAllServersInfo().then(function(response) {
            var customAttribute = [{
               key: item.key,
               operationId: 'REMOVE'
            }];
            var rootFolder = defaultUriSchemeUtil.createVmomiUri('Folder', 'group-d1', response.serversInfo[0].serviceGuid);
            return modifyCustomAttributes(rootFolder, customAttribute);
         });
      }

      function addCustomAttribute(customAttribute, type) {
         return userSessionService.getAllServersInfo().then(function(response) {
            var customAttr = [{
               attribute: customAttribute.attribute,
               operationId: 'ADD',
               value: '',
               type: type.associableTypeString !== 'global' ? type.associableTypeString : null
            }];
            var rootFolder = defaultUriSchemeUtil.createVmomiUri('Folder', 'group-d1', response.serversInfo[0].serviceGuid);
            return modifyCustomAttributes(rootFolder, customAttr);
         });
      }

      function renameCustomAttribute(customAttribute) {
         return userSessionService.getAllServersInfo().then(function(response) {
            var customAttributeSpec = [{
               key: customAttribute.key,
               attribute: customAttribute.attribute,
               operationId: 'RENAME'
            }];
            var rootFolder = defaultUriSchemeUtil.createVmomiUri('Folder', 'group-d1', response.serversInfo[0].serviceGuid);
            return modifyCustomAttributes(rootFolder, customAttributeSpec);
         });
      }

      function getCustomAttributes(objectId) {
         return dataService.getProperties(objectId, ['availableField', 'customValue'], {
            queryName: getCustomAttributes.name + ":" + objectId
         }).then(function(response) {
               return _.map(response.availableField, function(availableField) {
                  var key = availableField.key;
                  var customValue = _.find(response.customValue, function(customValue) {
                        return customValue.key === key;
                     }) || {};
                  var  attributeType = availableField.managedObjectType ?
                        availableField.managedObjectType.wsdlName : "global";
                  var result = {
                     attribute: availableField.name,
                     value: customValue.value,
                     key: key,
                     type: attributeType,
                     typeLabel: formatAttributeType(attributeType)
                  };
                  return result;
            });
         });
      }

      function getAllCustomAttributes() {
         return userSessionService.getAllServersInfo().then(function(response) {
            var paramSpec = {
               propertyName: "customAttributeFields",
               parameter: 'anyObjectType',
               parameterType: 'java.lang.String'
            };
            var datacenterId = defaultUriSchemeUtil.getVsphereObjectId(response.serversInfo[0].serviceInstance);
            var properties = ['customAttributeFields'];
            return dataService.getProperties(datacenterId, properties, {
               propertyParams: [paramSpec],
               queryName: getAllCustomAttributes.name + ":" + datacenterId
            }).then(formatAllCustomAttributesList);
         });
      }

      function formatAllCustomAttributesList(response) {
         return response.customAttributeFields.map(function(customAttribute) {
            var attributeType = customAttribute.fieldDef.managedObjectType ? customAttribute.fieldDef.managedObjectType.wsdlName : 'global';
            return {
               attribute: customAttribute.fieldDef.name,
               type: attributeType,
               typeFormatted: formatAttributeType(attributeType),
               key: customAttribute.fieldDef.key
            };
         });
      }

      function modifyCustomAttributes(objectId, newAttributeValues) {
         var propertyObjectType = "com.vmware.vsphere.client.attribute.CustomFieldSpec";
         var propertySpecs = [];
         var managedObjectReference = defaultUriSchemeUtil.getManagedObjectReference(objectId);
         _.map(newAttributeValues, function(customAttribute) {
            var propertySpec = generatePropertySpec(managedObjectReference, customAttribute);
            propertySpecs.push(propertySpec);
         });
         return mutationService.applyMultiSpec(objectId, propertyObjectType, propertySpecs, "Modifying Custom Attributes");
      }

      function generatePropertySpec(managedObjectReference, customAttribute) {
         switch (customAttribute.operationId) {
            case 'ADD':
               return {
                  "name": customAttribute.attribute,
                  "owner": managedObjectReference,
                  "value": customAttribute.value,
                  "operationId": customAttribute.operationId,
                  "typeName": customAttribute.type !== 'global' ? customAttribute.type : null
               };
            case 'REMOVE':
               return {
                  "owner": managedObjectReference,
                  "operationId": customAttribute.operationId,
                  "key": customAttribute.key
               };
            default:
               return {
                  "name": customAttribute.attribute,
                  "owner": managedObjectReference,
                  "value": customAttribute.value,
                  "operationId": customAttribute.operationId,
                  "key": customAttribute.key
               };
         }
      }

      function formatAttributeType(attributeType) {
         return i18nService.getString('Common', 'fieldType.' + attributeType);
      }
   }
})();
