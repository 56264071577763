(function() {
   'use strict';
   angular
         .module('com.vmware.vsphere.client.host')
         .service('suppressWarningSettingService', SuppressWarningSettingService);

   SuppressWarningSettingService.$inject = [
      'resourceService',
      'mutationService',
      '$rootScope',
      'suppressWarningSettingConstants'];

   function SuppressWarningSettingService(resourceService, mutationService, $rootScope, constants) {

      return {
         suppressWarnings: suppressWarnings
      };

      function suppressWarnings(hostId, key) {
         resourceService
               .getResourceInfo(hostId, 'advancedSettings')
               .then(function(result) {
                  var advancedSettingsProperties = result.properties;
                  var suppressWarningObject =
                        getSuppressWarningObject(advancedSettingsProperties, key);
                  updateSuppressWarningObject(suppressWarningObject, hostId);
               });
      }

      function getSuppressWarningObject(advancedSettingsProperties, warningKey) {
         var suppressWarningObject = advancedSettingsProperties.find(function(propertyElement) {
            return propertyElement.key === warningKey;
         });
         return suppressWarningObject;
      }

      function updateSuppressWarningObject(suppressWarningObject, hostId) {

         suppressWarningObject.value = constants.WARNING_SUPPRESS_VALUE.TRUE;
         var hostAdvancedSettingsSpec = createHostAdvancedSettingsSpec(suppressWarningObject);

         mutationService
               .apply(
                     hostId,
                     'com.vmware.vsphere.client.host.config.HostAdvancedSettingsSpec',
                     hostAdvancedSettingsSpec)
               .then(function(operationResult) {
                  if (operationResult && operationResult.error) {
                     return;
                  }
                  $rootScope.$broadcast('IssueChangeEvent');
               });
      }

      function createHostAdvancedSettingsSpec(suppressWarningObject) {

         var changedAdvancedSettings = [{
            _type: 'com.vmware.vim.binding.vim.option.OptionValue',
            key: suppressWarningObject.key,
            value: [getType(suppressWarningObject.type), suppressWarningObject.value]
         }];

         return {
            hostSettingsChangeset: changedAdvancedSettings
         };
      }

      function getType(type) {
         var result;
         switch (type) {
            case 'int':
               result = 'java.lang.Integer';
               break;
            case 'long':
               result = 'java.lang.Long';
               break;
            case 'boolean':
               result = 'java.lang.Boolean';
               break;
            default:
               result = 'java.lang.String';
               break;
         }
         return result;
      }
   }
})();
