namespace spbm_ui {

   export class SpbmCreateProfileActionService {
      static $inject = ['$injector'];

      private _vcsSpbmCreateProfileActionService: SpbmCreateProfileActionService;

      constructor(private $injector: any) {
      }

      get vcsSpbmCreateProfileActionService() {
         if (!this._vcsSpbmCreateProfileActionService) {
            this._vcsSpbmCreateProfileActionService = this.$injector.get("vcsSpbmCreateProfileActionService");
         }
         return this._vcsSpbmCreateProfileActionService;
      }

      createProfile(): void {
         return this.vcsSpbmCreateProfileActionService.createProfile();
      }

      editProfile(profileId: string): void {
         return this.vcsSpbmCreateProfileActionService.editProfile(profileId);
      }

      cloneProfile(profileId: string): void {
         return this.vcsSpbmCreateProfileActionService.cloneProfile(profileId);
      }
   }

   angular.module("com.vmware.vsphere.client.spbm")
         .service("spbmCreateProfileActionService", SpbmCreateProfileActionService);
}
