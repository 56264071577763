/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace dvs_ui {

   export class DvsUpgradeLacpSummaryPageComponent {

      public bindings: any;
      public controller: any;
      public templateUrl: string;

      constructor() {
         this.bindings = {
            wizardModel: "<"
         };

         this.controller = DvsUpgradeLacpSummaryPageComponentController;

         this.templateUrl = "dvs-ui/resources/dvs/lacpupgrade/components/" +
               "dvsUpgradeLacpSummaryPageTemplate.html";
      }
   }

   class DvsUpgradeLacpSummaryPageComponentController {

      static $inject = [
         "i18nService",
         "vxPropertyViewService",
         "$sce",
         "lagPropertiesFormattingService"
      ];

      public wizardModel: DvsUpgradeLacpWizardModel;

      private overviewInfo: any;
      private propertyViewData: any;

      constructor(private i18nService: any,
                  private vxPropertyViewService: any,
                  private $sce: any,
                  private formattingService: any) {

         this.overviewInfo = this.$sce.trustAsHtml(this.i18nService.getString(
               "DvsUi", "DvsUpgradeLacpWizard.summary.overview"));
      }

      $onInit(): void {
         this.buildPropertyView();
      }

      private buildPropertyView(): void {

         let builder: any = this.vxPropertyViewService.createPropertyViewBuilder();

         let generalCategory: any = builder.category("general");
         let lagSection: any = generalCategory.section("lagSection").title(
               this.i18nService.getString("DvsUi", "DvsUpgradeLacpWizard.summary.lag"));

         if (this.wizardModel.lag !== null) {
            // LAG name
            lagSection.property(
                  this.i18nService.getString(
                        "DvsUi", "DvsUpgradeLacpWizard.summary.lagName"),
                  this.wizardModel.lag.name);

            // Number of ports
            lagSection.property(
                  this.i18nService.getString(
                        "DvsUi", "DvsUpgradeLacpWizard.createLag.numPorts"),
                  this.wizardModel.lag.uplinkNum.toString());

            // Mode
            lagSection.property(
                  this.i18nService.getString(
                        "DvsUi", "DvsUpgradeLacpWizard.createLag.mode"),
                  this.formattingService.getLacpModeText(this.wizardModel.lag.mode));

            // Load balancing
            lagSection.property(
                  this.i18nService.getString(
                        "DvsUi", "DvsUpgradeLacpWizard.createLag.loadBalancing"),
                  this.formattingService.getLoadBalancingModeText(
                        this.wizardModel.lag.loadbalanceAlgorithm));

            // VLAN
            lagSection.property(
                  this.i18nService.getString(
                        "DvsUi", "DvsUpgradeLacpWizard.createLag.vlanLabel"),
                  this.formattingService.getVlanText(this.wizardModel.lag));

            // NetFlow
            lagSection.property(
                  this.i18nService.getString(
                        "DvsUi", "DvsUpgradeLacpWizard.createLag.ipfixLabel"),
                  this.formattingService.getIpfixText(this.wizardModel.lag));

            // Modified objects
            let entitySection: any = generalCategory.section("entitySection").title(
                  this.i18nService.getString(
                        "DvsUi", "DvsUpgradeLacpWizard.summary.affectedEntities"));

            // Number ot port groups
            entitySection.property(
                  this.i18nService.getString(
                        "DvsUi", "DvsUpgradeLacpWizard.summary.numberOfPortGroups"),
                  this.wizardModel.portgroupCount.toString());

            if (this.wizardModel.portgroupCount > 0) {
               entitySection.warning(this.i18nService.getString(
                     "DvsUi", "DvsUpgradeLacpWizard.summary.portGroupChanges"));
            }

            // Number ot hosts
            entitySection.property(
                  this.i18nService.getString(
                        "DvsUi", "DvsUpgradeLacpWizard.summary.numberOfHosts"),
                  this.wizardModel.hostCount.toString());

            if (this.wizardModel.hostCount > 0) {
               entitySection.warning(this.i18nService.getString(
                     "DvsUi", "DvsUpgradeLacpWizard.summary.hostChanges"));
            }
         } else {
            lagSection.warning(this.i18nService.getString(
                  "DvsUi", "DvsUpgradeLacpWizard.summary.lag.notNeeded"));
         }

         this.propertyViewData = builder.build();
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .component("dvsUpgradeLacpSummaryPage",
               new DvsUpgradeLacpSummaryPageComponent());
}
