module hostprofile_ui {

   class ExportHostProfileController implements angular.IController {

      public static $inject = [
         "$scope",
         "$window",
         "i18nService"
      ];

      private objectId: string;

      constructor(private $scope: any,
         private $window: any,
         private i18nService: any) {

         this.$scope.modalOptions.onSubmit = this.onSubmit.bind(this);
         this.objectId = this.$scope.modalOptions.dialogData.objectId;
      };

      private onSubmit(): boolean {
         this.$window.open("data/download/hostProfileExportData?objectId=" + encodeURIComponent(this.objectId), "_blank");
         return true;
      }
   }

   angular.module("com.vmware.vsphere.client.hostprofile")
         .controller("ExportHostProfileController", ExportHostProfileController);
}
