
/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace dvs_ui {

   export class DvsEditSettingsAdvancedPageComponent {

      public bindings: any;
      public controller: any;
      public templateUrl: string;

      constructor() {
         this.bindings = {
            pageModel: "<"
         };

         this.controller = DvsEditSettingsAdvancedPageComponentController;

         this.templateUrl = "dvs-ui/resources/dvs/settings/edit/pages/" +
               "dvsEditSettingsAdvancedPageComponentTemplate.html";
      }
   }

   class DvsEditSettingsAdvancedPageComponentController {

      static $inject = [
         "i18nService",
         "$scope",
         "dvsEditSettingsAdvancedPageValidator",
         "networkUiConstants"
      ];

      public pageModel: DvsEditSettingsAdvancedPageModel;

      private _mtuValidator: any;

      private _multicastFilteringOptions: any;

      private _ldpProtocolOptions: any;

      private _ldpOperationOptions: any;

      private _contactNameValidator: any;

      private _contactDetailsValidator: any;

      constructor(public i18nService: any,
                  private $scope: any,
                  private validator: DvsEditSettingsAdvancedPageValidator,
                  private networkUiConstants: any) {
         this._mtuValidator = {
            message: null,
            isVisible: false
         };

         this._contactNameValidator = {
            message: null,
            isVisible: false
         };

         this._contactDetailsValidator = {
            message: null,
            isVisible: false
         };
      }

      $onInit(): void {
         this.initDropdownOptions();
      }

      public get mtuValidator(): any {
         return this._mtuValidator;
      }

      public onMtuChange(): void {
         let message: string = this.validator.getMtuError(this.pageModel.mtu);
         this.mtuValidator.message = message;
         this.mtuValidator.isVisible = message !== null;
      }

      public get contactNameValidator(): any {
         return this._contactNameValidator;
      }

      public onContactNameChange(): void {
         let message: string =
               this.validator.getContactNameError(this.pageModel.contactName);
         this.contactNameValidator.message = message;
         this.contactNameValidator.isVisible = message !== null;
      }

      public get contactDetailsValidator(): any {
         return this._contactDetailsValidator;
      }

      public onContactDetailsChange(): void {
         let message: string =
               this.validator.getContactDetailsError(this.pageModel.contactDetails);
         this.contactDetailsValidator.message = message;
         this.contactDetailsValidator.isVisible = message !== null;
      }

      public get multicastFilteringOptions(): any {
         return this._multicastFilteringOptions;
      }

      public get ldpProtocolOptions(): any {
         return this._ldpProtocolOptions;
      }

      public get ldpOperationOptions(): any {
         return this._ldpOperationOptions;
      }

      public get ldpOperationDropdownDisabled(): boolean {
         return this.pageModel.ldpProtocol ===
               DvsEditSettingsAdvancedPageModel.LDP_PROTOCOL_DISABLED
                     || !this.pageModel.hasModifyPrivilege;
      }

      private initDropdownOptions(): void {
         this._multicastFilteringOptions = [{
            label: this.i18nService.getString(
                  "NetworkUi", "DvsConfigPage.advanced.multicastMode.basic"),
            value: this.networkUiConstants.MulticastFiltering.BASIC
         }, {
            label: this.i18nService.getString(
                  "NetworkUi", "DvsConfigPage.advanced.multicastMode.snooping"),
            value: this.networkUiConstants.MulticastFiltering.SNOOPING
         }];

         this._ldpProtocolOptions = [{
            label: this.i18nService.getString(
                  "NetworkUi", "DvsConfigPage.advanced.dp.type.disabled"),
            value: DvsEditSettingsAdvancedPageModel.LDP_PROTOCOL_DISABLED
         }, {
            label: this.i18nService.getString(
                  "NetworkUi", "DvsConfigPage.advanced.dp.type.cdp"),
            value: this.networkUiConstants.LinkDiscoveryProtocol.CDP
         }];

         if (this.pageModel.lldpSupported) {
            this._ldpProtocolOptions.push({
               label: this.i18nService.getString(
                     "NetworkUi", "DvsConfigPage.advanced.dp.type.lldp"),
               value: this.networkUiConstants.LinkDiscoveryProtocol.LLDP
            });
         }

         this._ldpOperationOptions = [{
            label: this.i18nService.getString(
                  "NetworkUi", "DvsConfigPage.advanced.dp.operation.listen"),
            value: this.networkUiConstants.LinkDiscoveryOperation.LISTEN
         }, {
            label: this.i18nService.getString(
                  "NetworkUi", "DvsConfigPage.advanced.dp.operation.advertise"),
            value: this.networkUiConstants.LinkDiscoveryOperation.ADVERTISE
         }, {
            label: this.i18nService.getString(
                  "NetworkUi", "DvsConfigPage.advanced.dp.operation.both"),
            value: this.networkUiConstants.LinkDiscoveryOperation.BOTH
         }];
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .component("dvsEditSettingsAdvancedPage",
               new DvsEditSettingsAdvancedPageComponent());
}
