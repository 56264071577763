/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
(function () {
   'use strict';

   angular
         .module('com.vmware.vsphere.client.network')
         .service('portPropertiesPageValidator',
               PortPropertiesPageValidator);

   PortPropertiesPageValidator.$inject = [
      'i18nService',
      'networkLabelValidator',
      'addNetworkingWizardConstants',
      'networkUtil',
      '$q',
      'clarityModalService',
      'tcpIpStackServicesValidator'
   ];

   function PortPropertiesPageValidator(i18nService,
                                        networkLabelValidator,
                                        addNetworkingWizardConstants,
                                        networkUtil, $q,
                                        clarityModalService,
                                        tcpIpStackServicesValidator) {

      var IS_SYSTEM_TRAFFIC_ENABLED_PROPERTY = "vnic:isSystemTrafficEnabled";

      this.getValidationErrorsAndWarnings = function (hostId, networkLabel,
                                                      targetType, vlanId,
                                                      mtuSelectedType, mtu,
                                                      netStackInstanceKey) {

         var errors = getValidationErrors(targetType, mtu, vlanId,
               networkLabel, hostId);

         var warningPromise = getWarningPromise(netStackInstanceKey, hostId);

         if (errors) {
            // Handle the error. If no such exist check also the warnings
            return handleErrors(netStackInstanceKey, errors, warningPromise);
         } else {
            return true;
         }
      };

      function getValidationErrors(targetType, mtu, vlanId, networkLabel, hostId) {
         var errors = [];
         if (!networkUtil.isMtuValid(mtu)) {
            errors.push(i18nService.getString('H5NetworkUi',
                  'AddNetworkingWizard.conPropPage.mtu.validationErrorMessage'));
         }

         if (targetType ===
               addNetworkingWizardConstants.targetType.EXISTING_STANDARD_SWITCH
               || targetType ===
               addNetworkingWizardConstants.targetType.NEW_STANDARD_SWITCH) {
            if (!networkUtil.isVlanIdValid(vlanId)) {
               errors.push(i18nService.getString('NetworkUi', 'VnicData.invalidVlanId'));
            }
            errors = errors.concat(networkLabelValidator
                  .getNetworkLabelClientSideValidationErrors(networkLabel));

            //in case there are no client side validation errors a server validation is done.
            if (errors.length === 0) {
               return networkLabelValidator
                     .getNetworkLabelServerSideValidationErrors(hostId, networkLabel);
            }
         }

         return errors;
      }

      function getWarningPromise(netStackInstanceKey, hostId) {

         var netStackKey = netStackInstanceKey;

         if (netStackKey ===
               addNetworkingWizardConstants.vmotionNetStackKey ||
               netStackKey ===
               addNetworkingWizardConstants.provisioningNetStackKey) {

            var warningPromise = tcpIpStackServicesValidator
                  .isSystemTrafficEnabledOnDefaultStack(netStackInstanceKey, hostId);

            return warningPromise;
         }
      }

      function getWarning(netStackInstanceKey) {

         var warningTitle;
         var warningMsg;

         if (netStackInstanceKey === addNetworkingWizardConstants
                     .vmotionNetStackKey) {

            warningTitle = i18nService.getString('NetworkUi',
                  'NetStackInstanceSelector.vmotion.title');
            warningMsg = i18nService.getString('NetworkUi',
                  'NetStackInstanceSelector.vmotion.warning');
         }

         if (netStackInstanceKey === addNetworkingWizardConstants
                     .provisioningNetStackKey) {

            warningTitle = i18nService.getString('NetworkUi',
                  'NetStackInstanceSelector.provisioning.title');
            warningMsg = i18nService.getString('NetworkUi',
                  'NetStackInstanceSelector.provisioning.warning');
         }

         return {
            warningTitle: warningTitle,
            warningMsg: warningMsg
         };

      }

      function handleWarning(netStackInstanceKey, warningPromise) {
         var confirmationDefered = $q.defer();

         warningPromise.then(function (response) {
            if (response && response[IS_SYSTEM_TRAFFIC_ENABLED_PROPERTY]) {

               var netStackKey = netStackInstanceKey;
               var warning = getWarning(netStackKey);

               var modalOptions = {
                  title: warning.warningTitle,
                  message: warning.warningMsg,
                  clarityIcon: {
                     class: "is-warning",
                     shape: "warning-standard",
                     size: "32"
                  },
                  preserveNewlines: true,
                  submit: function () {
                     confirmationDefered.resolve(true);
                  },
                  onCancel: function () {
                     confirmationDefered.resolve(false);
                  }
               };
               clarityModalService.openConfirmationModal(modalOptions);
            } else {
               confirmationDefered.resolve(true);
            }
         });
         return confirmationDefered.promise;
      }

      function handleErrors(netStackInstanceKey, errors, warningPromise) {
         if (_.isArray(errors) && errors.length > 0) {
            return errors;
         } else {
            return $q.when(errors).then(function (resultServerErrors) {
               if (resultServerErrors && resultServerErrors.length > 0) {
                  return errors;
               } else {
                  if (warningPromise) {
                     return handleWarning(netStackInstanceKey, warningPromise);
                  } else {
                     return true;
                  }
               }
            });
         }
      }
   }
})();
