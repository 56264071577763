namespace network_ui {
    export class SecurityPageModel {

        public allowPromiscuousMode:boolean;
        public macChanges:boolean;
        public forgedTransmits:boolean;

        public dirty: boolean = false;
        public initialState: SecurityPageModel;

    }
}
