/* Copyright 2018 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace h5_vapp {

   class LegacyVappPropertiesView {

      public static $inject = ["vcH5ConstantsService"];

      public vappId: string;

      public version: number = -1;

      public watchObjects: string[] = [];

      public liveProperties: string[] = ["config.vAppConfig.property"];

      public refreshEventTypes: string[] = [];

      constructor(private vcH5ConstantsService: any) {
         this.refreshEventTypes.push(vcH5ConstantsService.DATA_REFRESH_INVOCATION_EVENT);
         this.refreshEventTypes.push(vcH5ConstantsService.OBJECT_DETAILS_CHANGED_EVENT);
      }

      $onInit() {
         this.watchObjects = [this.vappId];
      }

      onRefresh() {
         this.version++;
      }
   }

   angular.module("com.vmware.vsphere.client.vapp").component("legacyVappPropertiesView", {
      bindings: {
         vappId: "<"
      },
      controller: LegacyVappPropertiesView,
      template: `
         <div vx-refreshable
            watch-objects="{{$ctrl.watchObjects}}"
            refresh="$ctrl.onRefresh()"
            live-refresh-properties="$ctrl.liveProperties"
            refresh-event-types="{{$ctrl.refreshEventTypes}}"
            class="vapp-properties-view-legacy-page-component"
         >
            <vapp-properties-view-component
               [vapp-id]="$ctrl.vappId"
               [version]="$ctrl.version"
            >
            </vapp-properties-view-component>
         </div>`

   });
}


