/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
(function() {
   'use strict';
   angular.module('com.vmware.vsphere.client.storage').controller(
      'ModifyDatastoreDialogController', ModifyDatastoreDialogController);

   ModifyDatastoreDialogController.$inject = [
      '$scope',
      'dataService',
      'vuiConstants',
      'i18nService',
      '$timeout',
      '$element'
   ];

   function ModifyDatastoreDialogController(
         $scope, dataService, vuiConstants, i18nService, $timeout, $element) {

      var self = this;

      self.datastoreId = $scope.modalOptions.dialogData.datastoreId;
      self.availableHostPropertyName = $scope.modalOptions.dialogData.availableHostPropertyName;
      self.headerHtmlUrl = $scope.modalOptions.dialogData.headerHtmlUrl;

      self.loading = true;

      self.datagridOptions = {
         resizable: true,
         height: '100%',
         selectionMode: vuiConstants.grid.selectionMode.MULTI,
         selectedItems: [],
         pageConfig: {
            displayMode: vuiConstants.grid.displayMode.VIRTUAL_SCROLLING
         },
         columnDefs: [
            {
               displayName: i18nService.getString('StorageUi', 'modifyDatastoreForm.hostColumnHeader'),
               field: 'formattedName',
               template: function (item) {
                  return '<div><span class="vsphere-icon-host"></span>' +
                     renderText(item.formattedName, item.isOperationAllowed) + '</div>';
               }
            },
            {
               displayName: i18nService.getString('StorageUi', 'modifyDatastoreForm.clusterColumnHeader'),
               field: 'clusterName',
               template: function (item) {
                  if (!item.clusterName) {
                     return '';
                  }

                  return '<div><span class="vsphere-icon-cluster"></span>' +
                     renderText(item.clusterName, item.isOperationAllowed) + '</div>';
               }
            }
         ],
         data: [],
         isItemDisabledCallback: function(item) {
            return !item.isOperationAllowed;
         }
      };

      // Detects selection changes in the host list.
      $scope.$watch(
         function() {
            return self.datagridOptions.selectedItems;
         },
         function(newSelection, oldSelection) {
            if (newSelection !== oldSelection) {
               $scope.modalOptions.dialogData.selectedHosts = newSelection;
            }
         });

      dataService.getProperties(self.datastoreId, [self.availableHostPropertyName]).then(
         function (result) {
            var dsOperationData = result ? result[self.availableHostPropertyName] || {} : {};
            var hostDataItems = dsOperationData.availableHosts || [];
            self.datagridOptions.data = hostDataItems;
            $scope.modalOptions.dialogData.allHosts = hostDataItems;
         },
         function (error) {
            $scope.modalOptions.dialogData.errorApi.showError(error);
         }
      ).finally(function() {
         self.loading = false;
         $timeout(function() {
            var inputElements = $element.find("input");
            if (inputElements && inputElements[0]) {
               inputElements[0].focus();
            }
         }, 1);
      });

      function renderText(text, enabled) {
         var span = $('<span/>')
            .addClass('vertical-align-cell')
            .text(text);

         if (!enabled) {
            span.addClass('disabled-text');
         }

         return span[0].outerHTML;
      }
   }
})();
