namespace h5_client {
    export module common_module_ui {
        import IScope = angular.IScope;

        interface CreateContentLibraryControllerScope extends IScope {
            wizardConfig: any;
            vuiWizard: any;
            wizardState: any;
        }

        class CreateContentLibraryController {
            public static $inject = ['$scope', '$rootScope', 'i18nService', 'userSessionService', 'vuiConstants','clarityModalService', '$q','libraryService'];
            public i18n: any;
            private name: string = this.$scope.wizardState.name;
            private description: string = this.$scope.wizardState.description;
            private vCenters: any;
            private vcService: any = this.$scope.wizardState.vcService;
            private readonly MAX_LENGTH = 80;
            private libraries: any;

            constructor(private $scope: CreateContentLibraryControllerScope, private $rootScope: IScope, private i18nService: any,
                        private userSessionService: any, private vuiConstants: any, private clarityModalService: any, private $q: any, private libraryService: any) {
                this.i18n = i18nService.getString;

                let self = this;
                self.$scope.vuiWizard.currentPage.state = self.vuiConstants.wizard.pageState.INCOMPLETE;
                userSessionService.getAllServersInfo().then(function(data: any) {
                    self.vCenters = data.serversInfo.filter((s: any) => s.name && s.errorCode === 'noError');
                    if (!self.$scope.wizardState.vcService.name) {
                        self.vcService = self.vCenters[0];
                    } else {
                       if (self.vCenters) {
                          let vcServiceName: string = self.$scope.wizardState.vcService.serviceGuid;
                          self.vcService = _.find(self.vCenters, function (vcenter: any) {
                             return (vcServiceName === vcenter.serviceGuid);
                          });
                       }
                    }
                    self.init();
                });
            }

            private init(): void {
                let self = this;
                this.$scope.vuiWizard.currentPage.state = this.vuiConstants.wizard.pageState.INCOMPLETE;

                this.$scope.vuiWizard.currentPage.disableNext = () => {
                    return !self.name.length;
                };

                this.$scope.vuiWizard.currentPage.onCommit = () => {
                    let nextConfirmationDeferred = self.$q.defer();
                    if(self.name.length > self.MAX_LENGTH ){
                        self.$scope.wizardConfig.validationBanner.messages = [{
                            text: self.i18n('VmUi',
                                'NameValidator.nameTooLongSpecialChars'),
                            type: self.vuiConstants.validationBanner.type.ERROR
                        }];
                        self.$scope.wizardConfig.validationBanner.showAllMessages = true;
                        nextConfirmationDeferred.resolve(false);
                    }else {

                        self.isDuplicateLibraryName().then((isDup: any) => {
                            if (isDup) {
                                self.clarityModalService.openConfirmationModal({
                                    title: self.i18nService.getString('LibraryUi', 'library.dupeNameWarning.dialogDescriptionHeader'),
                                    message: self.i18nService.getString('LibraryUi', 'library.dupeNameWarning.dialogDescriptionBodyCreate', self.name),
                                    icon: 'vsphere-icon-status-warning',
                                    submit: () => {
                                        self.onSubmit(nextConfirmationDeferred);
                                    },
                                    onCancel: () => {
                                        nextConfirmationDeferred.resolve(false);
                                    },
                                    preserveNewlines: true
                                });
                            } else {
                                self.onSubmit(nextConfirmationDeferred);
                            }
                        });
                    }
                    return nextConfirmationDeferred.promise;
                };

            }

            private onSubmit(nextConfirmationDeferred:any): any {
                this.$scope.wizardState.name = this.name;
                this.$scope.wizardState.description = this.description;
                this.$scope.wizardState.vcService = this.vcService;
                this.$scope.vuiWizard.currentPage.state = this.vuiConstants.wizard.pageState.COMPLETED;
                nextConfirmationDeferred.resolve(true);
            }

            private isDuplicateLibraryName():any {
                return this.libraryService.getAllContentLibraryInVc(this.vcService).then((libraries: any[]) => {
                    this.libraries = libraries;
                    return _.findIndex(this.libraries.targetLibraries, { name: this.name }) !== -1;
                });
            }
        }

        angular.module('com.vmware.vsphere.client.commonModule')
            .controller('createContentLibraryController', CreateContentLibraryController);
    }
}
