module cluster_ui {

   import IController = angular.IController;

   class DpmAutomationPageController implements IController {

      public static $inject = ["$scope", "dpmThresholdService", "drsConstants",
         "dpmAutomationLevelService", "i18nService", "dpmAutomationPageService"];

      private DPM_AUTOMATION_LEVEL_DISABLED: string;
      private dpmSliderOptions: any;
      private dpmAutomation: any;
      private pageModel: any;
      private automationLevels: any;
      private dpmAutomationLevelEnabled: any;
      private dpmAutomationLevelValue: any;
      private dpmAutomationLevelSignPostParams: any;
      private dpmAutomationLevelValuesSignPostParams: any;
      private dpmThresholdInformationMessage: any;

      constructor(private $scope: any, private dpmThresholdService: any,
                  private drsConstants: any,
                  private dpmAutomationLevelService: any,
                  private i18nService: any,
                  private dpmAutomationPageService: DpmAutomationPageService) {

         this.pageModel = $scope.modalOptions.dialogData.manager.getDpmPageModel();
         this.dpmAutomation = this.pageModel.dpmAutomation;
         this.DPM_AUTOMATION_LEVEL_DISABLED = this.drsConstants.dpmAutomationLevel.DISABLED;
         this.automationLevels = this.dpmAutomationLevelService.getAutomationLevels();
         this.dpmAutomationLevelEnabled = this.dpmAutomation.automationLevel.id !==
               this.DPM_AUTOMATION_LEVEL_DISABLED;

         // Default value in the dropdown if the automation level is off
         this.dpmAutomationLevelValue =
               this.dpmAutomationLevelEnabled ?
                     this.dpmAutomation.automationLevel.id :
                     this.drsConstants.dpmAutomationLevel.MANUAL;

         this.dpmSliderOptions = this.dpmAutomationPageService.getSliderOptions(
               this.i18nService.getString('ClusterUi', 'drs.config.dpmThresholdLabel')
         );

         this.dpmAutomationLevelSignPostParams =
               this.dpmAutomationPageService.getDpmAutomationLevelSignPostParams();

         this.dpmThresholdInformationMessage =
               this.dpmAutomationPageService.getDpmThresholdDescription(this.dpmAutomation.dpmThreshold);

         this.dpmAutomationLevelValuesSignPostParams =
               this.dpmAutomationPageService.getDpmAutomationLevelValuesSignPostParams(
                     this.dpmAutomationLevelEnabled, this.dpmAutomation.automationLevel.id);


         $scope.$watch(() => {
            return this.dpmAutomation;
         }, (newValue: any) => {
            if (newValue) {
               // Pre-select the DPM threshold value.
               this.dpmSliderOptions.value = this.dpmAutomation.dpmThreshold;
               this.dpmThresholdInformationMessage =
                     this.dpmAutomationPageService.getDpmThresholdDescription(this.dpmAutomation.dpmThreshold);
            }
         });

         $scope.$watch(() => {
            return this.dpmSliderOptions.value;
         }, (newValue: any) => {
            if (newValue && this.dpmAutomation) {
               let dpmThreshold: number = parseInt(newValue, 10);
               this.dpmAutomation.dpmThreshold = dpmThreshold;
               this.dpmThresholdInformationMessage =
                     this.dpmAutomationPageService.getDpmThresholdDescription(dpmThreshold);
            }
         });

         $scope.$watch(() => {
            return this.dpmAutomationLevelEnabled;
         }, (newValue: boolean) => {
            if (newValue) {
               this.dpmAutomation.automationLevel.id = this.dpmAutomationLevelValue;
               this.dpmAutomationLevelValuesSignPostParams.message =
                     this.dpmAutomationPageService.getAutomationLevelValueDescriptions(this.dpmAutomation.automationLevel.id);

            } else {
               this.dpmAutomation.automationLevel.id = this.DPM_AUTOMATION_LEVEL_DISABLED;
               this.dpmAutomationLevelValuesSignPostParams.message =
                     this.i18nService.getString("ClusterUi", "drs.config.dpm.disabledDescription");
            }
         });

         $scope.$watch(() => {
            return this.dpmAutomationLevelValue;
         }, (newValue: boolean) => {
            if (newValue && this.dpmAutomationLevelEnabled) {
               this.dpmAutomation.automationLevel.id = this.dpmAutomationLevelValue;
            }
         });

         $scope.$watch(()=> {
            return this.dpmAutomation.automationLevel.id;
         }, (newValue: any) => {
            if (newValue) {
               this.dpmAutomationLevelValuesSignPostParams.message =
                     this.dpmAutomationPageService.getAutomationLevelValueDescriptions(newValue);
            }
         });
      }
   }

   angular.module("com.vmware.vsphere.client.cluster")
         .controller("DpmAutomationPageController",
               DpmAutomationPageController);
}
