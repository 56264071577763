namespace h5_vm {
   class UsbControllerService {
      public static $inject: string[] = ["deviceService", "i18nService"];

      constructor(private deviceService: any, private i18nService: any) {
      }

      public makeDefaultUSBController(guestOsDescriptor: any, deviceOptions: any,
            virtualMachineDevices: any, additionalParameters: any): { device: { _type: string, key: number } } {

         if (!_.isEmpty(additionalParameters) && additionalParameters.isCreateMode) {
            return this.makeDefaultUSBControllerForCreate(guestOsDescriptor, deviceOptions, virtualMachineDevices);
         }

         let usb20type: string = "VirtualUSBController";
         let usb30type: string = "VirtualUSBXHCIController";

         let usb2option: any = _.find(deviceOptions, function (item: any) {
            return item.type.wsdlName === usb20type;
         });
         let usb3option: any = _.find(deviceOptions, function (item: any) {
            return item.type.wsdlName === usb30type;
         });

         if (!usb2option && !usb3option) {
            throw this.newErrorDeviceNotSupported();
         }

         let usb2count = virtualMachineDevices.findDevicesOfType(usb20type).length;
         let usb3count = virtualMachineDevices.findDevicesOfType(usb30type).length;

         let device: any;

         if (usb2count === 0 && usb2option) {
            device = this.deviceService.makeController(deviceOptions, usb2option.type, 0);
         } else if (usb3count === 0 && usb3option) {
            device = this.deviceService.makeController(deviceOptions, usb3option.type, 0);
         }

         if (!device) {
            throw this.newErrorDeviceLimitReached();
         }

         return {
            device: device
         };
      }

      private newErrorDeviceLimitReached(): any {
         return new Error(this.i18nService.getString("VmUi", "VmDeviceManager.DeviceLimitReached"));
      }

      private newErrorDeviceNotSupported(): any {
         return new Error(this.i18nService.getString("VmUi", "VmDeviceManager.DeviceNotSupported"));
      }

      private makeDefaultUSBControllerForCreate(guestOsDescriptor: any,
            deviceOptions: any, virtualMachineDevices: any): { device: { _type: string, key: number } } {
         let device: any;
         let result: any =  {
            device: device
         };
         if (!guestOsDescriptor.usbRecommended) {
            return result;
         }

         // Check if there is recommended default USB controller type for this guest
         if (_.isNull(guestOsDescriptor.recommendedUSBController) ||
               _.isNull(guestOsDescriptor.recommendedUSBController.wsdlName)) {
            return result;
         }

         let controllerClass: string = guestOsDescriptor.recommendedUSBController.wsdlName;
         // Check if the recommended USB controller is already added
         if (virtualMachineDevices.findDevicesOfType(controllerClass).length > 0) {
            return result;
         }

         // Check if the recommended USB controller is supported by the guest
         // This check should always pass otherwise the guestOsDescriptor is damaged
         let supported: boolean = false;
         if (!_.isEmpty(guestOsDescriptor.supportedUSBControllerList)) {
            supported = _.some(guestOsDescriptor.supportedUSBControllerList,
                  (supportedController: any): boolean => {
                     return supportedController.wsdlName === controllerClass;
                  });
         }

         if (!supported) {
            return result;
         }

         // Check if the recommended USB controller is supported by the host
         let usbOption: any = _.find(deviceOptions, function (item: any) {
            return item.type.wsdlName === controllerClass;
         });

         if (!usbOption) {
            return result;
         }

         result.device = this.deviceService.makeController(deviceOptions, usbOption.type, 0);

         return result;
      }
   }
   angular.module("com.vmware.vsphere.client.vm").service("usbControllerService", UsbControllerService);
}
