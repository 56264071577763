module cluster_ui {

   export class ScheduleDrsWizardManager {

      public static $inject = ["i18nService", "vuiConstants", "mutationService",
         "defaultUriSchemeUtil", "$rootScope", "wizardPageService", "clusterSpecBuilderService",
         "vscSchedulingHelper"];

      private scheduleSettingsPageDef: any;
      private drsSettingsPageDef: any;

      private wizardConfig: any;
      private scheduleModel: any = {};
      private objectId: string;
      private formData: any;

      constructor(private i18nService: any, private vuiConstants: any,
                  private mutationService: any, private defaultUriSchemeUtil: any,
                  private $rootScope: any, private wizardPageService: any,
                  private clusterSpecBuilderService: any, private vscSchedulingHelper: any) {
         this.buildPages();
      }

      /**
       * Retrieves page definitions.
       *
       * @returns {WizardPage[]}
       */
      public getPageDefinitions(): any[] {
         return [this.scheduleSettingsPageDef,
            this.drsSettingsPageDef];
      }

      public getObjectId(): string {
         return this.objectId;
      }

      /**
       * Retrieves wizard model.
       *
       * @returns {DsClusterModel}
       */
      public getModel(): any {
         return this.scheduleModel;
      }

      public setModel(model: any): void {
         this.scheduleModel = model;
      }

      public setFormData(formData: any): void {
         this.formData = formData;
      }

      public submit(): boolean {
         this.scheduleModel.scheduleTaskSpec.spec =
               this.clusterSpecBuilderService.buildClusterComputeResourceSpec(this.formData, true);
         this.scheduleModel.scheduleTaskSpec.spec._type =
               'com.vmware.vsphere.client.cluster.ClusterComputeResourceSpec';
         this.scheduleModel.scheduleTaskSpec.schedulingSpec.recurrence =
               this.vscSchedulingHelper.formatRecurrenceData(
                     this.scheduleModel.scheduleTaskSpec.schedulingSpec.recurrence);
         this.mutationService.apply(
               this.objectId,
               'com.vmware.vise.data.scheduling.ScheduledMutationOperationSpec',
               this.scheduleModel.scheduleTaskSpec);
         return true;
      }

      public getWizardConfig(additionalData: any): any {
         let title: string;
         let editClusterSettingsTitle: string =
               this.i18nService.getString(
                     "ClusterUi",
                     "editClusterSettingsDialog.title");

         if (additionalData && additionalData.isScheduledTask
               && additionalData.schedulingData.scheduleTaskOperationMode === 1) {
            title = this.i18nService.getString(
                  "ScheduledOpsUi",
                  "scheduledTask.ScheduleTasksEdit",
                  editClusterSettingsTitle);
         } else {
            title = this.i18nService.getString(
                        "ScheduledOpsUi",
                        "scheduledTask.ScheduleNewTasks.label")
                  + " (" + editClusterSettingsTitle + ")";
         }

         if (!this.wizardConfig) {
            this.wizardConfig = {
               title: title,
               pages: this.getPageDefinitions(),
               show: true,
               loading: false,
               loadingMessage: this.i18nService.getString("CommonUi", "wizard.loading"),
               onFinish: () => this.submit(),
               cssClass: "schedule-cluster-drs-page"
            };
         } else {
            this.wizardConfig.title = title;
         }

         return this.wizardConfig;
      }

      /**
       * Initializes wizard model with default values.
       */
      public init(objectId: string, additionalData: any): void {
         this.objectId = objectId;
         if (additionalData && additionalData.isScheduledTask
               && additionalData.schedulingData.scheduleTaskOperationMode === 1) {
            // edit scheduled task
            this.scheduleModel = {
               scheduleTaskSpec: additionalData.schedulingData
            };
         } else {
            this.scheduleModel = {
               scheduleTaskSpec: {
                  spec: {},
                  // 0 is for add
                  operation: 0,
                  entity: this.defaultUriSchemeUtil.getManagedObjectReference(objectId),
                  schedulingSpec: {
                     _type: "com.vmware.vise.core.model.scheduling.ScheduledTaskSpec",
                     scheduledTask: null,
                     recurrence: null,
                     name: "Reconfigure Cluster",
                     taskKey: "ReconfigureComputeResource_Task",
                     description: "",
                     source: "Cluster",
                     enabled: true,
                     notification: ""
                  },
                  // 0 is for addDeferred
                  deferredOperation: 0,
                  // 0 is for add
                  scheduleTaskOperationMode: 0
               }
            };
         }
      }

      private buildPages(): void {
         this.scheduleSettingsPageDef = {
            title: this.i18nService.getString("ScheduledOpsUi",
                  "scheduledTask.snapshot.options"),
            contentUrl: "cluster-ui/resources/cluster/views/settings/services/drs/edit/pages/scheduleDrsScheduleOptionsPage.html",
            state: this.vuiConstants.wizard.pageState.INCOMPLETE,
            onCommit: () => {
               let recurrence: any = this.getModel().scheduleTaskSpec.schedulingSpec.recurrence;
               let isFormStatusValid = this.getModel().scheduleTaskSpec.schedulingSpec.isFormStatusValid;
               if (recurrence && isFormStatusValid) {
                  this.setModel(this.getModel());
                  this.wizardPageService.markPageComplete(this.wizardConfig);
                  return true;
               } else {
                  if (!recurrence) {
                     this.wizardPageService.markPageIncompleteWithError(
                           this.wizardConfig,
                           this.i18nService.getString("SchedulingUi", "scheduledTask.emptyScheduler"));
                  }
                  return false;
               }
            }
         };

         this.drsSettingsPageDef = {
            title: this.i18nService.getString("ClusterUi",
                  "drs.schedule.config.pageTitle"),
            contentUrl: "cluster-ui/resources/cluster/views/settings/services/drs/edit/pages/scheduleDrsDrsSettingsPage.html",
            state: this.vuiConstants.wizard.pageState.DISABLED
         };
      }
   }

   angular.module("com.vmware.vsphere.client.cluster")
         .service("scheduleDrsWizardManager", ScheduleDrsWizardManager);
}
