namespace h5_vm {

    export class Unit {
        constructor(private label: string, private multiplier: number) {
        }
    }

    export class DiskUnit {
       static readonly MB = new Unit("MB", 1);
       static readonly GB = new Unit("GB", Math.pow(2, 10));
       static readonly TB = new Unit("TB", Math.pow(2, 20));
    }
}
