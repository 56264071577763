/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace h5_vm {

export class MigrateSelectFolderPageModel {
   /**
    * [input]
    *
    * The VMs being migrated.
    */
   public vms: Array<string> = [];

   /**
    * [input]
    *
    * The id of the datacenter of the selected compute resource.
    */
   public datacenter: string;

   /**
    * [output]
    *
    * The destination folder set after the validation
    */
   public destinationFolder?: string;

   /**
    * [page internal]
    *
    * The destination folder object chosen from the page
    */
   public selectedTarget?: {uid: any, name: any, type: any};

   /**
    * [page internal]
    *
    * Is the validation of the folder in progress
    */
   public isValidating?: boolean;

   /**
    * [page internal]
    *
    * Is the folder tree view loaded
    */
   public isLoading?: boolean;
} // class
} // module
