/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
(function () {
   'use strict';
   angular.module('com.vmware.vsphere.client.cluster')
      .controller('HaPortletController', HaPortletController);

   HaPortletController.$inject = ['$scope', 'dataService', 'i18nService', '$filter',
         'columnRenderersRegistry', 'defaultUriSchemeUtil'];

   function HaPortletController($scope, dataService, i18nService, $filter,
            columnRenderersRegistry, defaultUriSchemeUtil) {
      var self = this;
      var clusterId = $scope._route.objectId;
      var linkRenderer = columnRenderersRegistry.getColumnRenderer('object-link');

      self.clusterId = [clusterId];
      self.liveRefreshProperties = [
            "configurationEx.dasConfig.enabled",
            "configurationEx.dasConfig.admissionControlPolicy",
            "configurationEx.dasConfig.admissionControlEnabled",
            "configurationEx.dasConfig.hostMonitoring",
            "configurationEx.dasConfig.vmMonitoring",
            "configurationEx.dasConfig.admissionControlPolicy.cpuFailoverResourcesPercent",
            "configurationEx.dasConfig.admissionControlPolicy.memoryFailoverResourcesPercent",
            "configurationEx.dasConfig.admissionControlPolicy.failoverHosts",
            "configurationEx.dasConfig.admissionControlPolicy.failoverLevel",
            "configurationEx.infraUpdateHaConfig.enabled",
            "configurationEx.infraUpdateHaConfig.behavior",
            "summary.admissionControlInfo.currentCpuFailoverResourcesPercent",
            "summary.admissionControlInfo.currentMemoryFailoverResourcesPercent",
            "summary.admissionControlInfo.currentFailoverLevel",
            "summary.numHosts"
         ];
      self.getViewData = getViewData;
      self.getHostMonitoringText = getHostMonitoringText;
      self.getHostPartialResponse = getHostPartialResponse;
      self.admissionControlPolicyTypes = {
         FAILOVER_LEVEL: 'com.vmware.vim.binding.vim.cluster.FailoverLevelAdmissionControlPolicy',
         FAILOVER_RESOURCES: 'com.vmware.vim.binding.vim.cluster.FailoverResourcesAdmissionControlPolicy',
         FAILOVER_HOST: 'com.vmware.vim.binding.vim.cluster.FailoverHostAdmissionControlPolicy'
      };

      getViewData();

      function getViewData() {
         dataService
            .getData(clusterId,
               'com.vmware.vsphere.client.clusterui.model.ClusterHaSummaryData',
             {
                 queryName: 'HaPortletController.' + getViewData.name
             }
            )
            .then(function (haData) {
               self.haData = haData;
               buildSignPostData();
               if (haData.admissionControlPolicy._type === self.admissionControlPolicyTypes.FAILOVER_LEVEL) {
                  var chartMaxValue;
                  if (haData.numHosts !== 0) {
                     chartMaxValue = haData.numHosts;
                  } else if (haData.numFailoverHosts !== 0) {
                     chartMaxValue = haData.numFailoverHosts;
                  } else {
                     chartMaxValue = 1;
                  }

                  self.chartMaxValue = chartMaxValue;

                  self.chartData = [{
                     label: i18nService.getString('ClusterUi', 'ha.portlet.hosts.chart.label'),
                     usage: (haData.numHosts - haData.currentNumFailoverHosts),
                     reservation: haData.numFailoverHosts
                  }];

                  self.chartMarkers = [{
                     value: 0,
                     label: '0',
                     labelAlign: 'right'
                  }, {
                     value: chartMaxValue,
                     label: haData.numHosts.toString(),
                     labelAlign: 'left'
                  }];
               } else if (haData.admissionControlPolicy._type === self.admissionControlPolicyTypes.FAILOVER_RESOURCES) {
                  self.chartMaxValue = 100;

                  self.chartData = [{
                     label: i18nService.getString('ClusterUi', 'ha.portlet.cpu.chart.label'),
                     usage: (100 - haData.currentCpuFailoverPercent),
                     reservation: haData.cpuFailoverPercent
                  }, {
                     label: i18nService.getString('ClusterUi', 'ha.portlet.memory.chart.label'),
                     usage: (100 - haData.currentMemoryFailoverPercent),
                     reservation: haData.memoryFailoverPercent
                  }];

                  self.chartMarkers = [{
                     value: 0,
                     label: '0%',
                     labelAlign: 'right'
                  }, {
                     value: 50,
                     label: '50%',
                     labelAlign: 'center'
                  }, {
                     value: 100,
                     label: '100%',
                     labelAlign: 'left'
                  }];
               } else if (haData.admissionControlPolicy._type === self.admissionControlPolicyTypes.FAILOVER_HOST) {
                  self.datagridOptions = {
                     columnDefs: [
                        {
                           field: 'name',
                           displayName: i18nService.getString('ClusterUi', 'ha.portlet.grid.host.header'),
                           template: function(hostResourceData) {
                              return linkRenderer(
                                 ['entityUid', 'name', hostResourceData.iconId],
                                 hostResourceData);
                           }
                        },
                        {
                           field: 'cpuCapacityText',
                           displayName: i18nService.getString('ClusterUi', 'ha.portlet.grid.host.cpu'),
                           sortable: function(host1, host2) {
                              return host1.cpuCapacity - host2.cpuCapacity;
                           }
                        },
                        {
                           field: 'memorySizeText',
                           displayName: i18nService.getString('ClusterUi', 'ha.portlet.grid.host.memory'),
                           sortable: function(host1, host2) {
                              return host1.memorySize - host2.memorySize;
                           }
                        }
                     ],
                     searchable: false,
                     selectedItems: []
                  };
                  // TODO add proper localization
                  haData.hostData = haData.hostData || [];
                  haData.hostData.forEach(function(hostResourceData) {
                     hostResourceData.entityUid = defaultUriSchemeUtil.getVsphereObjectId(hostResourceData.provider);
                     hostResourceData.memorySizeText = $filter('bytes')(hostResourceData.memorySize, 'B', 'GB');
                  });
                  self.datagridOptions.data = haData.hostData;
               }
            });
      }

      function buildSignPostData() {
         var templateUrl = '';
         var title = '';
         var width = 0;

         if (self.haData.admissionControlPolicy._type ===
               self.admissionControlPolicyTypes.FAILOVER_LEVEL) {
            templateUrl = 'cluster-ui/resources/cluster/views/summary/failoverLevelInfoTooltipContent.html';
            title = i18nService.getString('ClusterUi', 'ha.portlet.failover.level.policy');
            width = 400;
         } else if (self.haData.admissionControlPolicy._type ===
               self.admissionControlPolicyTypes.FAILOVER_RESOURCES) {
            templateUrl = 'cluster-ui/resources/cluster/views/summary/failoverResourceInfoTooltipContent.html';
            title = i18nService.getString(
                  'ClusterUi', 'ha.portlet.failover.resources.policy');
            width = 440;
         }

         self.signPostParams = {
            templateUrl: templateUrl,
            title: title,
            model: self.haData,
            width: width
         };
      }

      function getHostMonitoringText(hostMonitoring) {
         if (hostMonitoring === 'enabled') {
            return i18nService.getString('ClusterUi', 'ha.portlet.hostmonitoring.enabled');
         }
         return i18nService.getString('ClusterUi', 'ha.portlet.hostmonitoring.disabled');
      }

      function getHostPartialResponse(enabled, behavior) {
         if (enabled) {
            return i18nService.getString('ClusterUi', 'ha.portlet.host.partial.failure.response.' + behavior);
         }
         return i18nService.getString('ClusterUi', 'ha.portlet.host.partial.failure.response.disabled');
      }
   }
})();
