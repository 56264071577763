(function() {
   'use strict';

   /**
    * Provides migration threshold text and description data, along with the initial
    * slider options.
    */
   angular.module('com.vmware.vsphere.client.cluster')
      .service('drsMigrationThresholdService', drsMigrationThresholdService);

   drsMigrationThresholdService.$inject = ['i18nService', 'drsConstants', 'logService'];

   function drsMigrationThresholdService(i18nService, drsConstants, logService) {
      var log = logService('drsMigrationThresholdService');

      return {
         getText: getText,
         getDescription: getDescription,
         MIN: drsConstants.migrationThreshold.MIN,
         MAX: drsConstants.migrationThreshold.MAX,
         DEFAULT: (drsConstants.migrationThreshold.MIN + drsConstants.migrationThreshold.MAX) / 2,
         MIN_LABEL: getString('drs.config.threshold.conservative'),
         MAX_LABEL: getString('drs.config.threshold.aggressive'),
         getDrsMigrationRate: getDrsMigrationRate
      };

      /**
       *  Retrieves the text associated with the provided migration threshold.
       *
       * @param {String} migrationThreshold
       * @returns {String}
       */
      function getText(migrationThreshold) {
         if (!migrationRateWithinRange(migrationThreshold)) {
            log.warn('Unexpected migration threshold: ' + migrationThreshold);
            return '';
         }
         var migrationThresholdKey = i18nService.interpolate(
            'drs.config.migrationThreshold.priority{0}', [migrationThreshold]);
         return getString(migrationThresholdKey);
      }

      /**
       * Retrieves the description associated with the provided migration threshold.
       *
       * @param {String} migrationThreshold
       * @returns {String}
       */
      function getDescription(migrationThreshold) {
         if (!migrationRateWithinRange(migrationThreshold)) {
            log.warn('Unexpected migration threshold: ' + migrationThreshold);
            return '';
         }
         var migrationThresholdKey = i18nService.interpolate(
            'drs.config.migrationThreshold.priority{0}Description', [migrationThreshold]);
         return getString(migrationThresholdKey);
      }

      function getDrsMigrationRate(drsMigrationThreshold) {
         return reverseRateAndThreshold(drsMigrationThreshold);
      }

      //------------------ Private Implementations ------------------

      function reverseRateAndThreshold(value) {
         if (!migrationRateWithinRange(value)) {
            log.warn('Unexpected migration threshold: ' + value);
            return NaN;
         }
         return drsConstants.migrationThreshold.MAX - value + drsConstants.migrationThreshold.MIN;
      }

      function migrationRateWithinRange(value) {
         return drsConstants.migrationThreshold.RANGE.indexOf(value) !== -1;
      }

      function getString(key) {
         return i18nService.getString('ClusterUi', key);
      }
   }
})();