module ds_cluster_ui {

   import IPromise = angular.IPromise;

   export class StorageVmOverridesService {

      public static $inject = ["i18nService", "dataService", "vuiConstants",
         "defaultUriSchemeUtil", "columnRenderersRegistry", "mutationService",
         "dsClusterConstants"];

      constructor(private i18nService: any, private dataService: any,
                  private vuiConstants: any, private defaultUriSchemeUtil: any,
                  private columnRenderersRegistry: any,
                  private mutationService: any,
                  private dsClusterConstants: any) {
      }

      public getViewData(objectId: string): IPromise<Object> {
         return this.dataService
               .getData(objectId,
                     "com.vmware.vsphere.client.dsclusterui.model.configuration.DsClusterVmOverrideData")
               .then((data: any) => data.vmOverrides);
      };

      public getGridOptions(): any {
         return {
            selectionMode: this.vuiConstants.grid.selectionMode.MULTI,
            pageConfig: {
               hidePager: true
            },
            resizable: true,
            data: [],
            selectedItems: [],
            columnDefs: this.getAllColumnDefs(),
            height: "100%",
            actionBarOptions: {
               actions: []
            },
            showCheckboxesOnMultiSelection: false
         };
      }


      private getAllColumnDefs() {
         return [
            {
               displayName: this.getString("vmOverrides.vmNameHeaderText"),
               field: "vm.name",
               type: "string",
               template: this.renderVmLink.bind(this)
            },
            {
               displayName: this.getString("vmOverrides.sdrsAutomationLevelHeaderText"),
               field: "drsAutomationLevelText",
               type: "string",
               template: "<span title='#:sdrsAutomationLevelText#'>#:sdrsAutomationLevelText#</span>"
            },
            {
               displayName: this.getString("vmOverrides.intraVmAffinityHeaderText"),
               field: "intraVmAffinityText",
               type: "string",
               template: "<span title='#:intraVmAffinityText#'>#:intraVmAffinityText#</span>"
            }
         ];
      }

      private getString(key: string, ...params: string[]) {
         return this.i18nService.getString("DsClusterUi", key, params);
      }

      private renderVmLink(dataItem: any) {
         let objectLinkRenderer = this.columnRenderersRegistry.getColumnRenderer("object-link");
         let vmRef = this.defaultUriSchemeUtil.getVsphereObjectId(dataItem.vm.provider);
         let icon = dataItem.vm.iconId;
         return objectLinkRenderer(
               ["vmRef", "name", icon],
               {
                  vmRef: vmRef,
                  name: dataItem.vm.name,
                  iconId: icon
               });
      }

      public removeVmOverride(dsClusterId: any, removeSpec: any) {
         let operation = this.dsClusterConstants.vmOverridesOperationType.REMOVE;
         this.changeVmOverride(dsClusterId, removeSpec, operation);
      }

      public validateVmOverride(dsClusterId: string, spec: any): IPromise<any> {
         return this.mutationService.validate(dsClusterId,
               'com.vmware.vsphere.client.dscluster.VmOverridesReconfigureSpec',
               spec);
      }

      public getVmOverrideErrorMessages(errorMessages: any[]): Object {

         let errorMessagesAsString: string = this.buildErrorMessages(errorMessages);
         let alert: Object = {
            text: this.i18nService.getString("DsClusterUi",
                  "vmOverrides.conflicting.vms.rule.description", errorMessagesAsString),
            type: this.vuiConstants.validationBanner.type.ERROR
         };

         return alert;
      }

      public  getEditVmOverridesDialogTitle(selectedVms: any): string {
         let numberOfSelectedVms: number = selectedVms.length;
         let title: string = numberOfSelectedVms > 1 ?
               this.i18nService.getString(
                     "DsClusterUi",
                     "vmOverrides.config.editMultiple.titleLabel",
                     numberOfSelectedVms
               ) :
               this.i18nService.getString("DsClusterUi",
                     "vmOverrides.config.edit.titleLabel");

         return title;
      }

      private buildErrorMessages(errorMessages: any[]): string {
         let errorMessage: string = "";
         if (!_.isEmpty(errorMessages)) {
            _.each(errorMessages, (item: any) => {
               let singleError: string =
                     this.i18nService.getString("DsClusterUi",
                           "vmOverrides.conflicting.vms.rule.item", item.name);
               errorMessage = errorMessage.concat(singleError);
            });
            let indexOfFirstComma: any = errorMessage.indexOf(",");
            // it is index +2 because the error string now begins with comma and space as
            // ", ".
            errorMessage = errorMessage.substring(indexOfFirstComma + 2);
         }

         return errorMessage;
      }

      private changeVmOverride(dsClusterId: any, spec: any, operation: any) {
         this.mutationService.apply(dsClusterId,
               'com.vmware.vsphere.client.dscluster.VmOverridesReconfigureSpec',
               spec,
               operation);
      }

   }

   angular.module("com.vmware.vsphere.client.dsCluster")
         .service("storageVmOverridesService", StorageVmOverridesService);
}
