module ds_cluster_ui {

   class StorageDrsRuntimeSettingsPageViewController {

      public static $inject = ["$scope", "i18nService", "dsClusterConstants"];

      public pageModel: any;
      constructor(private $scope: any, private i18nService: any,
                  public dsClusterConstants: any) {
         let sdrsManager = $scope.modalOptions.dialogData.manager;
         this.pageModel = sdrsManager.getStorageDrsRuntimeSettingsModel();
         this.pageModel.isInEditMode = true;
      }
   }

   angular.module("com.vmware.vsphere.client.dsCluster")
         .controller("StorageDrsRuntimeSettingsPageViewController",
               StorageDrsRuntimeSettingsPageViewController);
}
