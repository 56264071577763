angular.module('com.vmware.vsphere.client.vm').directive('vmHardwareCdrom', [
   'i18nService',
   'vcH5ConstantsService',
   'vmDeviceInfoService',
   'selectIsoFileService',
   'vmHardwareCdromManagerService',
   'defaultUriSchemeUtil',
   'datastoreBrowserTreeService',
   'datastoreBrowserConstants',
   'contentLibraryIsoSelectorModalService',
   'logService',
   function (i18nService,
             vcH5ConstantsService,
             vmDeviceInfoService,
             selectIsoFileService,
             vmHardwareCdromManagerService,
             defaultUriSchemeUtil,
             datastoreBrowserTreeService,
             datastoreBrowserConstants,
             contentLibraryIsoSelectorModalService,
             logService) {

      var log = logService('vmHardwareCdrom');

      return {
         scope: {
            inflatedDevice: '=',
            vmConfigContext: '=',
            virtualMachineDevicesNeededForControllerAndNode: '=',
            mergedInflatedDevices: '=',
            removeCallback: '&',
            createMode: '=',
            computeResourceId: '@',
            expanded: "=",
            vmId: "="
         },
         require: '^form',
         templateUrl: 'vm-ui/resources/vm/views/settings/vmHardwareSettings/vmHardwareCdrom/vmHardwareCdrom.html',
         link: {
            pre: function (scope, element, attrs, formCtrl) {
               scope.device = scope.inflatedDevice.getCurrentDevice();
               scope.formCtrl = formCtrl;
               scope.i18n = i18nService.getString;
               var cdRomList = copyAvailableCdRomListOnTheHost(scope.vmConfigContext.environment.configTarget.cdRom);
               if(vmHardwareCdromManagerService.hasHostDevicebacking(scope.device)
                     && !vmHardwareCdromManagerService.isDevicePresentInTheHost(scope.device.backing.deviceName, cdRomList)) {
                     cdRomList.push({ name: scope.device.backing.deviceName });
               }
               scope.cdromList = vmHardwareCdromManagerService.createCdromList(cdRomList);

               var isoErrors = {
                  noError: {
                     isVisible: false
                  },
                  fileMissing: {
                     isVisible: true,
                     message: scope.i18n('VmUi', 'CdromPage.FileRequired')
                  },
                  fileDoesNotExists: {
                     isVisible: true,
                     message: scope.i18n('VmUi', 'AsyncFileValidator.NotFound')
                  }
               };

               scope.errors = {
                  isoError: isoErrors.fileMissing,
                  isoErrorForCL: isoErrors.noError
               };
               scope.controllerSelectorName = 'editCdrom' + scope.inflatedDevice.getKey() + 'Controller';
               scope.controllerNodeSelectorName = 'editCdrom' + scope.inflatedDevice.getKey() + 'ControllerNode';
               scope.isVmPoweredOn = scope.vmConfigContext.environment.powerState === 'poweredOn';
               scope.isNewCdDvdDrive = scope.inflatedDevice.isNew();
               scope.skipPermissionCheck = scope.isNewCdDvdDrive || scope.createMode;
               scope.availableDeviceModes = vmHardwareCdromManagerService.getAvailableDeviceModes();
               scope.properties = {
                  deviceMode: vmHardwareCdromManagerService.getInitialDeviceMode(scope.device.backing._type),
                  startConnected: scope.device.connectable.startConnected,
                  connected: scope.device.connectable.connected,
                  controllerNode: null
               };

               vmHardwareCdromManagerService.getAvailableMediaTypes(scope.cdromList,
                     scope.vmId ? scope.vmId : scope.computeResourceId)
                  .then(function (types) {
                     scope.availableMediaTypes = types;
                     return vmHardwareCdromManagerService.getInitialMediaType(scope.device.backing,
                           scope.availableMediaTypes);
                  })
                  .then(function (initialMediaType) {
                     scope.properties.mediaType = initialMediaType.mediaType;
                     scope.properties.selectedIsoItem = initialMediaType.selectedIsoItem;

                     scope.$watchGroup(['properties.mediaType', 'device.backing.fileName'], function (newValues, oldValues) {
                        if (!_.isEqual(newValues, oldValues)) {
                           var newMediaType = newValues[0];
                           scope.validateCdDrive(newMediaType);
                        }
                     });

                     scope.$watch('properties.mediaType', function (newMediaType, oldMediaType) {
                        if (_.isEqual(newMediaType, oldMediaType)) {
                           return;
                        }
                        scope.properties.startConnected = false;
                        scope.properties.connected = false;

                        updatePreviousIsoSelection(oldMediaType);

                        if (vmHardwareCdromManagerService.isDatastoreISOFile(newMediaType)) {
                           if (scope.previousDatastoreISOSelection) {
                              scope.device.backing.fileName = scope.previousDatastoreISOSelection;
                           }
                           else {
                              scope.browseForDatastoreIso();
                           }
                           scope.$evalAsync(addIsoFileValidator);
                        } else if (vmHardwareCdromManagerService.isContentLibraryISOFile(newMediaType)) {
                           if (scope.previousContentLibraryISOSelection) {
                              scope.device.backing.fileName = scope.previousContentLibraryISOSelection;
                           }
                           else {
                              scope.browseForContentLibraryIso();
                           }
                        }
                        else {
                           var backing = vmHardwareCdromManagerService.getNewDeviceBacking(scope.properties);
                           scope.device.backing = backing;
                        }
                     });

                  },function (error) {
                     log.error(error);
                  });

               if (scope.device.backing.deviceName) {
                  scope.properties.selectedCdrom = _.find(scope.cdromList, function (entry) {
                     return entry.name === scope.device.backing.deviceName;
                  });
               }

               scope.availableControllers = vmDeviceInfoService.availableControllersForDevice(
                  scope.inflatedDevice,
                  scope.virtualMachineDevicesNeededForControllerAndNode.getAllDevicesNotMarkedForRemoval()
               );

               scope.properties.controller = _.find(scope.availableControllers, function (controller) {
                  return controller.getKey() === scope.device.controllerKey;
               });
               if (!scope.properties.controller) {
                  throw new Error('Could not find controller for device: ', scope.inflatedDevice.getKey());
               }

               scope.validateCdDrive = function (mediaType) {
                  if (vmHardwareCdromManagerService.isContentLibraryISOFile(mediaType)) {
                     setFormValidity(true);
                     if (scope.properties.selectedIsoItem) {
                        scope.device.backing = vmHardwareCdromManagerService.createIsoBacking(
                           scope.properties.selectedIsoItem.isoPath,
                           scope.device.backing.datastore,
                           scope.device.backing.backingObjectId);
                     }
                  }
                  else if (vmHardwareCdromManagerService.isDatastoreISOFile(mediaType)) {
                     // sometimes watcher for device.backing.filename is triggered
                     // after the device is marked for removal and validates the
                     // device, we don't want this to happen if device is already
                     // marked for removal
                     if (scope.inflatedDevice.isMarkedForRemoval()) {
                        setFormValidity(true);
                        return;
                     }

                     setFormValidity(false);
                     debouncedRefreshCallback();
                  }
                  else {
                     setFormValidity(true);
                  }
               };

               scope.hasEditCdRomError = function () {
                  var backingFileNameField = getBackingFileNameField();
                  if (backingFileNameField) {
                     return backingFileNameField.$invalid;
                  }
                  return false;
               };

               scope.shouldRemoveDeviceBeDisabled = function () {
                  if (!scope.createMode && !vmHardwareCdromManagerService.hasPrivileges(scope.vmConfigContext.privileges)) {
                     return true;
                  }

                  if (scope.isVmPoweredOn) {
                     var controllerOptions = vmDeviceInfoService.optionForDevice(
                        scope.vmConfigContext, scope.inflatedDevice, scope.virtualMachineDevicesNeededForControllerAndNode.getAllDevicesNotMarkedForRemoval()
                     );
                     return !controllerOptions.hotRemoveSupported;
                  }

                  return false;
               };

               scope.onCdRomRemoval = function () {
                  if (!scope.shouldRemoveDeviceBeDisabled() && scope.removeCallback) {
                     scope.removeCallback()(scope.inflatedDevice);

                     // on device removal mark form valid if it was not, later
                     // if removal is reverted, we will re-calculate validity
                     setFormValidity(true);
                  }
               };

               scope.onRevertRemoval = function () {
                  if (!scope.inflatedDevice) {
                     return;
                  }

                  scope.inflatedDevice.revertRemoval();
                  // re-calculate the form validity, because it was maked valid
                  // on device removal
                  scope.validateCdDrive(scope.properties.mediaType);
               };

               scope.browseForDatastoreIso = function () {
                  selectIsoFileService.display(scope);
               };

               scope.browseForContentLibraryIso = function () {
                  contentLibraryIsoSelectorModalService
                     .openContentLibraryIsoSelector(scope.computeResourceId,
                        scope.properties.selectedIsoItem,
                        function (selectedIsoItem) {
                           scope.properties.selectedIsoItem = selectedIsoItem;
                           scope.device.backing.fileName = scope.properties.selectedIsoItem.isoPath;
                           scope.errors.isoErrorForCL = isoErrors.noError;
                        },function () {
                           if(!scope.properties || !scope.properties.selectedIsoItem){
                              scope.errors.isoErrorForCL = isoErrors.fileMissing;
                           }
                        });
               };

               scope.isDatastoreISOFile = function () {
                  return vmHardwareCdromManagerService.isDatastoreISOFile(scope.properties.mediaType);
               };
               scope.isContentLibraryISOFile = function () {
                  return vmHardwareCdromManagerService.isContentLibraryISOFile(scope.properties.mediaType);
               };

               scope.isHostDevice = function () {
                  return vmHardwareCdromManagerService.isHostDevice(scope.properties.mediaType);
               };

               scope.isClientDevice = function () {
                  return vmHardwareCdromManagerService.isClientDevice(scope.properties.mediaType);
               };

               scope.isRemoteDevice = function () {
                  var device = scope.inflatedDevice.getCurrentDevice();
                  return vmHardwareCdromManagerService.isRemoteDevice(device.backing);
               };

               scope.$watchGroup(['properties.startConnected', 'properties.connected'], function (newValue) {
                  scope.device.connectable.startConnected = newValue[0];
                  scope.device.connectable.connected = newValue[1];
               });

               scope.$watch('properties.deviceMode', function (newValue) {
                  if (vmHardwareCdromManagerService.isClientDevice(scope.properties.mediaType)) {
                     var backing = vmHardwareCdromManagerService.createClientDeviceBacking(newValue);
                     scope.device.backing = backing;
                  }
               });

               scope.onControllerNodeChanged = function (deviceController, node) {
                  if (!node) {
                     return;
                  }
                  scope.device.unitNumber = node.key;
               };

               scope.onCdRomChanged = function (selectedCdRomName) {
                  scope.device.backing.deviceName = selectedCdRomName;
               };

               scope.isCdRomDisabled = function () {
                  return vmHardwareCdromManagerService.isCdRomDisabled(scope.vmConfigContext.privileges,
                     scope.skipPermissionCheck);
               };

               scope.isCdRomConnectedAtPowerOnDisabled = function () {
                  return vmHardwareCdromManagerService.isCdRomConnectedAtPowerOnDisabled(scope.properties.mediaType,
                     scope.vmConfigContext.privileges,
                     scope.skipPermissionCheck);
               };

               scope.isCdRomConnectedDisabled = function () {
                  var device = scope.inflatedDevice.getCurrentDevice();
                  return vmHardwareCdromManagerService.isCdRomConnectedDisabled(device.backing,
                     scope.vmConfigContext.privileges,
                     scope.skipPermissionCheck);
               };

               scope.isDeviceModeDisabled = function () {
                  return vmHardwareCdromManagerService.isDeviceModeDisabled(scope.properties.mediaType,
                     scope.vmConfigContext.privileges,
                     scope.skipPermissionCheck);
               };

               scope.hasError = function () {
                  var hardwareCdromformaName = 'vmHardwareCdRomForm' + scope.inflatedDevice.getKey();
                  return scope.formCtrl[hardwareCdromformaName].$invalid || scope.isHostDeviceBackingInvalid();
               };

               scope.isHostDeviceBackingInvalid = function() {

                  if (vmHardwareCdromManagerService.hasHostDevicebacking(scope.device) &&
                        !vmHardwareCdromManagerService.isDevicePresentInTheHost(scope.device.backing.deviceName,
                              scope.vmConfigContext.environment.configTarget.cdRom)) {
                     return true;
                  }

                  return false;
               };

               scope.getCdRomLabel = function(cdRomDeviceName) {
                   if (!vmHardwareCdromManagerService.isDevicePresentInTheHost(cdRomDeviceName,
                               scope.vmConfigContext.environment.configTarget.cdRom)){
                      return cdRomDeviceName + " (" + scope.i18n('VmUi', 'CdromPage.DeviceNotAvailable') + ")";
                   }

                   return cdRomDeviceName;
               };

               var debouncedRefreshCallback = _.debounce(function () {
                  validateDataStoreIsoFileAndSetDeviceBacking(scope.device.backing.fileName);
               }, vcH5ConstantsService.CHANGELOG_REFRESH_DELAY);

               function setFormValidity(validity) {
                  var backingFileNameField = getBackingFileNameField();
                  if (backingFileNameField) {
                     backingFileNameField.$setValidity(getIsoValidatorName(), validity);
                  }
               }

               function validateDataStoreIsoFileAndSetDeviceBacking(fileName) {
                  if (_.isEmpty(fileName)) {
                     scope.errors.isoError = isoErrors.fileMissing;
                     setFormValidity(false);
                  } else {
                     var dsBrowserId = defaultUriSchemeUtil.getVsphereObjectId(
                        scope.vmConfigContext.environment.datastoreBrowser);
                     var fileType = datastoreBrowserConstants.fileQueryType.ISO_IMAGE;
                     datastoreBrowserTreeService.isFileExists(dsBrowserId, fileName, fileType)
                        .then(function (fileExists) {
                           scope.errors.isoError = fileExists ?
                              isoErrors.noError :
                              isoErrors.fileDoesNotExists;
                           setFormValidity(fileExists);
                           if (fileExists) {
                              scope.device.backing = vmHardwareCdromManagerService.createIsoBacking(
                                 fileName,
                                 scope.device.backing.datastore,
                                 scope.device.backing.backingObjectId);
                           }
                        });
                  }
               }

               function addIsoFileValidator() {
                  var backingFileNameField = getBackingFileNameField();
                  if (backingFileNameField && !backingFileNameField.$validators[getIsoValidatorName()]) {
                     backingFileNameField.$validators[getIsoValidatorName()] = function (modelValue, viewValue) {
                        return true;
                     };
                  }
               }

               function getBackingFileNameField() {
                  var hardwareCdromformaName = 'vmHardwareCdRomForm' + scope.inflatedDevice.getKey();
                  var backingFileNameFieldName = 'editCdrom' + scope.inflatedDevice.getKey() + 'BackingFileName';
                  return scope.formCtrl[hardwareCdromformaName] && scope.formCtrl[hardwareCdromformaName][backingFileNameFieldName];
               }

               function getIsoValidatorName() {
                  return 'isoValidator' + scope.inflatedDevice.getKey();
               }

               function updatePreviousIsoSelection(oldMediaType) {
                  if (vmHardwareCdromManagerService.isDatastoreISOFile(oldMediaType)) {
                     scope.previousDatastoreISOSelection = scope.device.backing.fileName;
                  } else if (vmHardwareCdromManagerService.isContentLibraryISOFile(oldMediaType)) {
                     scope.previousContentLibraryISOSelection = scope.device.backing.fileName;
                  }
               }

               function copyAvailableCdRomListOnTheHost(availableCdRomList) {
                  var hostCdRomList = [];
                  if (!_.isEmpty(availableCdRomList)) {
                     hostCdRomList = availableCdRomList.slice(0);
                  }

                  return hostCdRomList;
               }

               var i18n = function(key, section) {
                  if (!section) {
                     section = 'CdromPage';
                  }
                  return i18nService.getString('VmUi', section + '.' + key);
               };

               scope.ARIA_LBL_MEDIA_TYPE = i18n('MediaType');
               scope.ARIA_LBL_CONNECTED = i18n('Connected');
               scope.ARIA_LBL_START_CONNECTED = i18n('ConnectAtPowerOn', 'ConnectDevice');
               scope.ARIA_LBL_MODE = i18n('DeviceMode');
               scope.ARIA_LBL_FILE = i18n('Media');
               scope.ARIA_LBL_BROWSE = i18n('Browse');
               scope.ARIA_LBL_HOST = i18n('HostDevice', 'CdromConfig');
            }
         }
      };
   }
]);
