/*
 * This js file has to be loaded first
 */
h5 = h5 || {};
h5.tagging = {
   angularModule: angular.module('com.vmware.vsphere.client.tagging', [
      'com.vmware.platform.ui'])
};

/*
 * Tagging module
 */
angular.module('com.vmware.vsphere.client.tagging')
.run([
function() {}]);
