(function() {
   'use strict';

   angular.module('com.vmware.vsphere.client.vm')
      .service('CloneVmToVmService', CloneVmToVmService);

   CloneVmToVmService.$inject = [
      'vuiConstants',
      'vmProvisioningDatastorePageService',
      'vmProvisioningCloneOptionsPageService',
      'vmProvisioningUserSettingsPageService',
      'vmProvisioningFinishCloneVmPageService',
      'vmProvisioningNameAndFolderPageService',
      'VmProvisioningComputeResourcePageService',
      'vmProvisioningCloneCustomizeGuestOsPageService',
      'vmProvisioningCustomizeHardwarePageService',
      'vmCreateScheduleTaskPageService',
      'vmProvisioningVappPropertiesPageService'
   ];

   function CloneVmToVmService (
      vuiConstants,
      vmProvisioningDatastorePageService,
      vmProvisioningCloneOptionsPageService,
      vmProvisioningUserSettingsPageService,
      vmProvisioningFinishCloneVmPageService,
      vmProvisioningNameAndFolderPageService,
      VmProvisioningComputeResourcePageService,
      vmProvisioningCloneCustomizeGuestOsPageService,
      vmProvisioningCustomizeHardwarePageService,
      vmCreateScheduleTaskPageService,
      vmProvisioningVappPropertiesPageService
   ) {
      return function() {
         this.buildPages = buildPages;

         function buildPages (wizardScope, defaultTarget, preselectedComputeResource) {
            var namePage = vmProvisioningNameAndFolderPageService.build(wizardScope, defaultTarget);
            var computeResourcePage = VmProvisioningComputeResourcePageService.build(wizardScope, preselectedComputeResource);
            var datastorePage = vmProvisioningDatastorePageService.build(wizardScope);
            var cloneOptionsPage = vmProvisioningCloneOptionsPageService.build(wizardScope);
            var customizeGuestOSPage = vmProvisioningCloneCustomizeGuestOsPageService.build(wizardScope);
            var userSettingsPage = vmProvisioningUserSettingsPageService.build(wizardScope);
            var customizeHardwarePage = vmProvisioningCustomizeHardwarePageService.build(wizardScope);
            customizeHardwarePage.defaultState = vuiConstants.wizard.pageState.SKIPPED;
            var finishCloneVmPage = vmProvisioningFinishCloneVmPageService.build(wizardScope);
            var vappPropertiesPage = vmProvisioningVappPropertiesPageService.build(wizardScope);
            vappPropertiesPage.defaultState = vuiConstants.wizard.pageState.SKIPPED;

            var pages = [
               namePage,
               computeResourcePage,
               datastorePage,
               cloneOptionsPage,
               customizeGuestOSPage,
               userSettingsPage,
               customizeHardwarePage,
               vappPropertiesPage,
               finishCloneVmPage
            ];

            if (wizardScope.vmParams.hasConfigurableVappProperties()) {
               vappPropertiesPage.defaultState = vuiConstants.wizard.pageState.DISABLED;
            }
            if (wizardScope.scheduleTaskData
               && wizardScope.scheduleTaskData.isScheduledTask) {
               var scheduleTaskPage = vmCreateScheduleTaskPageService.build(wizardScope);
               pages.splice(0, 0, scheduleTaskPage);
               cloneOptionsPage.defaultState = vuiConstants.wizard.pageState.SKIPPED;
            }
            return {
               pages: pages
            };
         }
      };
   }
})();
