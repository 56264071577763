/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
(function () {
   'use strict';

   angular
         .module('com.vmware.vsphere.client.network')
         .service('connectionSettingsPageValidator',
               ConnectionSettingsPageValidator);

   ConnectionSettingsPageValidator.$inject = [
      'i18nService',
      'networkUtil',
      'networkLabelValidator'];

   function ConnectionSettingsPageValidator(i18nService,
                                            networkUtil,
                                            networkLabelValidator) {
      this.getValidationErrors = function (hostId, networkLabel, vlanId) {
         var errors = [];

         if (!networkUtil.isVlanIdValid(vlanId)) {
            errors.push(i18nService.getString('NetworkUi', 'VnicData.invalidVlanId'));
         }
         errors = errors.concat(networkLabelValidator
               .getNetworkLabelClientSideValidationErrors(networkLabel));

         //in case there are no client side validation errors a server validation is done.
         if (errors.length === 0) {
            return networkLabelValidator
                  .getNetworkLabelServerSideValidationErrors(hostId, networkLabel);
         }
         return errors;
      };
   }
})();

