namespace h5_vm {
    import IPromise = angular.IPromise;
    import VmProvisioningPageModel = h5_vm.VmProvisioningPageModel;
    import TemplateType = h5_vm.TemplateType;

    class CloneVmBasicInformationPageModelFactory {
        public static $inject = [
            "authorizationService",
            "wizardPageService",
            "nameAndFolderValidatorService",
            "$q",
            "i18nService",
            "dataService",
            "defaultUriSchemeUtil",
            "featureFlagsService"
        ];

        constructor(authorizationService : any,
                    wizardPageService: any,
                    nameAndFolderValidatorService: any,
                    $q: any,
                    i18nService: any,
                    dataService: any,
                    defaultUriSchemeUtil: any,
                    featureFlagsService: any
        ) {
            return (wizardViewData: any, virtualMachineSpecBuilder: any, basicInformation: any, wizardConfig: any) => {
                return new CloneVmBasicInformationPageModel(
                    wizardViewData,
                    virtualMachineSpecBuilder,
                    basicInformation,
                    wizardConfig,
                    authorizationService,
                    wizardPageService,
                    nameAndFolderValidatorService,
                    $q,
                    i18nService,
                    dataService,
                    defaultUriSchemeUtil,
                    featureFlagsService);
            };
        }
    }

    export class CloneVmBasicInformationPageModel implements VmProvisioningPageModel<any> {

        static $inject = ["authorizationService",
            "wizardPageService",
            "nameAndFolderValidatorService",
            "$q",
            "i18nService",
            "dataService",
            "defaultUriSchemeUtil",
            "featureFlagsService"];

        public config: any;
        public isVMTXPhase2Enabled: any;
        public readonly VM_NAME_MAX_LENGTH: any = 80;

        constructor(public wizardViewData: any,
                    public virtualMachineSpecBuilder: any,
                    public basicInformation: any,
                    public wizardConfig: any,
                    public authorizationService: any,
                    public wizardPageService: any,
                    public nameAndFolderValidatorService: any,
                    public $q: any,
                    public i18nService: any,
                    public dataService: any,
                    public defaultUriSchemeUtil: any,
                    private featureFlagsService: any) {
            this.config = wizardConfig;
            this.isVMTXPhase2Enabled = this.featureFlagsService.CL_Native_VMTX_Phase2Enabled();
        }

        public resetForm(): void {
        }

        public changePages(isVMTX: boolean, isUpdateOvf: boolean): void {
            if (isVMTX) {
                this.wizardPageService.markPageDisabled(
                    this.config.pages,
                    this.i18nService.getString('ProvisioningUiLib', 'LocationPage.title')
                );
                this.wizardPageService.markPageDisabled(
                    this.config.pages,
                    this.i18nService.getString('VmUi', 'SelectDatastoreProvisioningPage.Title')
                );
                this.wizardPageService.markPageSkipped(
                    this.config.pages,
                    this.i18nService.getString('ProvisioningUiLib', 'LibraryItemPage.title')
                );

                if (this.isVMTXPhase2Enabled) {
                    this.wizardPageService.markPageDisabled(
                        this.config.pages,
                        this.i18nService.getString('VmUi', 'SelectResourcePoolProvisioningPage.Title')
                    );
                }else {
                    this.wizardPageService.markPageSkipped(
                        this.config.pages,
                        this.i18nService.getString('VmUi', 'SelectResourcePoolProvisioningPage.Title')
                    );

                }

            } else {
                this.wizardPageService.markPageSkipped(
                    this.config.pages,
                    this.i18nService.getString('VmUi', 'SelectResourcePoolProvisioningPage.Title')
                );
                this.wizardPageService.markPageSkipped(
                    this.config.pages,
                    this.i18nService.getString('VmUi', 'SelectDatastoreProvisioningPage.Title')
                );
                if (isUpdateOvf) {
                    this.wizardPageService.markPageSkipped(
                        this.config.pages,
                        this.i18nService.getString('ProvisioningUiLib', 'LocationPage.title')
                    );

                    this.wizardPageService.markPageDisabled(
                        this.config.pages,
                        this.i18nService.getString('ProvisioningUiLib', 'LibraryItemPage.title')
                    );
                }else {
                    this.wizardPageService.markPageDisabled(
                        this.config.pages,
                        this.i18nService.getString('ProvisioningUiLib', 'LocationPage.title')
                    );

                    this.wizardPageService.markPageSkipped(
                        this.config.pages,
                        this.i18nService.getString('ProvisioningUiLib', 'LibraryItemPage.title')
                    );
                }
            }
        }

        public validatePage(): any {
            let nextConfirmationDeferred: any = this.$q.defer();
            let vmNameErrorMessage: any = this.checkVmName();
            if (this.basicInformation.isPrivilegeError){
               nextConfirmationDeferred.resolve({
                  error: this.i18nService.getString('ProvisioningUiLib', 'AddToLibrary.privilege.error')
               });
            } else if (vmNameErrorMessage) {
                nextConfirmationDeferred.resolve({
                    error: vmNameErrorMessage
                });
            } else if (this.featureFlagsService.CL_Native_VMTX_Phase2Enabled() &&
                  this.basicInformation.templateType === TemplateType.VM_TEMPLATE){
                let requiredPrivileges: any = ['VirtualMachine.Inventory.CreateFromExisting'];
                let target: String = this.basicInformation.folderObjectId;
                let self1: any = this;
                if (target && target.length !== 0) {
                    this.authorizationService.checkGrantedPrivileges(target, requiredPrivileges)
                        .then(function (hasPrivileges: any) {
                            let hasActionPrivileges: any = true;
                            for (let i = 0; i < hasPrivileges.length; i++) {
                                if (!hasPrivileges[i]) {
                                    hasActionPrivileges = false;
                                    nextConfirmationDeferred.resolve({
                                        error: self1.i18nService.getString(
                                              'VmUi',
                                              'SelectNameFolderProvisioningPage.NoCreateFromExistingPrivilegeOnFolder')
                                    });
                                }
                            }

                            if (hasActionPrivileges) {
                                self1.doFolderValidate(nextConfirmationDeferred);
                            }
                        });
                } else {
                    nextConfirmationDeferred.resolve({
                        error: this.i18nService.getString('VmUi',
                            'SelectNameFolderProvisioningPage.specifyValidDestination.template')
                    });
                }
            } else {
                nextConfirmationDeferred.resolve({
                    error: null
                });
            }
            return nextConfirmationDeferred.promise;

        }

        private doFolderValidate(nextConfirmationDeferred:any): any {
            let self: any = this;
            let target: any = {
                uid: this.basicInformation.folderObjectId,
                type : this.basicInformation.folder.nodeTypeId,
                item : this.basicInformation.folder
            };
            this.nameAndFolderValidatorService
                .validateNameAndGetLocationUids(this.basicInformation.name, target)
                .then(function(result: any) {
                    if (!result.validName) {
                        nextConfirmationDeferred.resolve({ error: result.validationMessage });
                    } else if (!result.isVmFolder) {
                        nextConfirmationDeferred.resolve({
                            error: self.i18nService.getString('VmUi', 'SelectNameFolderProvisioningPage.specifyValidDestination.template')
                        });
                    } else {
                        nextConfirmationDeferred.resolve({
                            error: null,
                            folderUid: result.folderUid,
                            datacenterUid: result.datacenterUid
                        });
                    }
                });

        }


        public submitPage(validationResult: any): any {
            this.virtualMachineSpecBuilder.setName(this.basicInformation.name);
            if (!(this.featureFlagsService.CL_Native_VMTX_Phase2Enabled() && this.basicInformation.templateType ===
                        TemplateType.VM_TEMPLATE)){
                return {};
            }else if (!validationResult.datacenterUid){
                let self: any = this;
                this.dataService.getProperties(this.basicInformation.folderObjectId, ["dc"]).then((result: any) => {
                    self.virtualMachineSpecBuilder.setTargetInformation(
                        this.basicInformation.folderObjectId,
                        self.defaultUriSchemeUtil.getVsphereObjectId(result["dc"]),
                        validationResult.folderUid,
                        this.basicInformation.folder.text
                    );
                });
            }else{
                this.virtualMachineSpecBuilder.setTargetInformation(
                    this.basicInformation.folderObjectId,
                    validationResult.datacenterUid,
                    validationResult.folderUid,
                    this.basicInformation.folder.text
                );
            }

            return {};
        }

        public checkVmNameLength(name: string): any {
            return this.convertVmName(name).length <= this.VM_NAME_MAX_LENGTH;
        }

        public convertVmName(name: string): any {
            return name.replace(/%/g, '%25').replace(/\//g, '%2f').replace(/\\/g, '%2c');
        }

        public checkVmName(): any {
            if (!this.basicInformation.name) {
                return this.i18nService.getString('VmUi',
                    'SelectNameFolderProvisioningPage.specifyValidName.template');
            }

            if (!this.checkVmNameLength(this.basicInformation.name)) {
                return this.i18nService.getString('VmUi',
                    'NameValidator.nameTooLongSpecialChars');
            }

            return '';
        }

    }

    angular.module("com.vmware.vsphere.client.vm")
        .service("cloneVmBasicInformationPageModel",
            CloneVmBasicInformationPageModelFactory);

}
