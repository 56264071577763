/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
(function () {
   'use strict';

   angular
         .module('com.vmware.vsphere.client.network')
         .directive('uplinkPortMappingWithDetails', uplinkPortMappingWithDetails);

   uplinkPortMappingWithDetails.$inject = [
      'physicalAdapterDetailsViewService',
      'i18nService'
   ];

   function uplinkPortMappingWithDetails(physicalAdapterDetailsViewService, i18nService) {
      var directive = {
         restrict: 'E',
         templateUrl: 'network-ui/resources/network/components/' +
         'uplinkPortMapping/uplinkPortMappingWithDetails.html',
         scope: {
            portMapping: '=',
            allowOldMappingsRemoval: '=?'
         },
         replace: true,
         link: {
            pre: function ($scope) {
               $scope.noItemsSelectedMessage = i18nService.getString('NetworkUi',
                     'UplinkPortMappingEditor.detailsPrompt');

               $scope.showPnicDetails = function (selectedItems) {
                  if (selectedItems && selectedItems.length === 1 && selectedItems[0].pnic) {
                     var selectedPnicDetaisData
                           = $scope.portMapping.pnicDetails[selectedItems[0].pnic];

                     $scope.selectedPnicDetails = physicalAdapterDetailsViewService.build(
                           selectedPnicDetaisData);
                  } else {
                     $scope.selectedPnicDetails = null;
                  }
               };
               $scope.splitterOptions = {
                  resizable: true,
                  panes: [
                     {
                        size: '210px'
                     }, {
                     }]
               };
            }
         }
      };
      return directive;
   }
}());
