/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace dvs_ui {

   import VspanSessionEncapType =
         com.vmware.vim.binding.vim.dvs.VmwareDistributedVirtualSwitch$VspanSessionEncapType;

   export class DvsSpanSessionReadyToCompletePageComponent {

      public bindings: any;
      public controller: any;
      public templateUrl: string;

      constructor() {
         this.bindings = {
            model: "<"
         };

         this.controller = DvsSpanSessionReadyToCompletePageComponentController;

         this.templateUrl = "dvs-ui/resources/dvs/portmirroring/create/components/" +
               "dvsSpanSessionReadyToCompletePageComponentTemplate.html";
      }
   }

   class DvsSpanSessionReadyToCompletePageComponentController {

      static $inject = [
            "i18nService",
            "dvsSpanSessionReadyToCompletePageService"
      ];

      public model: DvsAddSpanSessionWizardModel;

      public propertyViewData: any;

      public getString: (key: string) => string;

      constructor(private i18nService: any,
                  private propertyViewService: DvsSpanSessionReadyToCompletePageService) {
         this.getString = (key: string): string => {
            return i18nService.getString("DvsUi", key);
         };
      }

      public $onInit(): void {
         this.propertyViewData =
               this.propertyViewService.buildPropertyViewData(this.model);
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .component("dvsSpanSessionReadyToCompletePage",
               new DvsSpanSessionReadyToCompletePageComponent());
}
