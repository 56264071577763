/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace dvs_ui {

   angular.module("com.vmware.vsphere.client.dvs").constant("dvsVersionPageConstants", {
      versions: {
         _66: "6.6.0",
         _65: "6.5.0",
         _60: "6.0.0",
         _55: "5.5.0",
         _51: "5.1.0",
         _50: "5.0.0",
         _unknown: "unknown"
      }
   });
}
