/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
(function () {
   'use strict';
   angular.module('com.vmware.vsphere.client.network')
         .service('ipv4SettingsPageValidator',
               Ipv4SettingsPageValidator);

   Ipv4SettingsPageValidator.$inject = [
      'i18nService',
      'ipParserService',
      'mutationService'];

   function Ipv4SettingsPageValidator(i18nService,
                                      ipParserService,
                                      mutationService) {

      this.getValidationErrors = function (validationData) {
         var validationErrors = [];
         if (!validationData.dhcpEnabled) {
            var ipv4AddressTrimmed = validationData.ipv4Address
                  && validationData.ipv4Address.trim();

            // Client validation
            if (!ipv4AddressTrimmed) {
               validationErrors.push(i18nService.getString('H5NetworkUi',
                     'AddNetworkingWizard.ipv4SettingsPage.emptyIpAddress'));
            } else if (!ipParserService.isIpv4AddressValid(ipv4AddressTrimmed)) {
               validationErrors.push(i18nService.getString(
                     'NetworkUi', 'VnicData.invalidIPAddress'));
            }

            var subnetMaskTrimmed = validationData.subnetMask
                  && validationData.subnetMask.trim();

            if (!subnetMaskTrimmed) {
               validationErrors.push(i18nService.getString('H5NetworkUi',
                     'AddNetworkingWizard.ipv4SettingsPage.emptySubnetMask'));
            } else if (!ipParserService.isSubnetMaskValid(subnetMaskTrimmed)) {
               validationErrors.push(i18nService.getString(
                     'NetworkUi', 'VnicData.invalidSubnet'));
            }

            validationErrors = validationErrors.concat(
                  getDefaultGatewaysErrors(validationData, validationErrors.length));

            // Server validation if client validation has passed
            if (validationErrors.length === 0) {
               return serverSideValidation(validationData.hostId,
                     ipv4AddressTrimmed, validationData.vnicName);
            }
         }
         return validationErrors;
      };

      /**
       * A backend call to validate the uniqueness of a given IP address.
       * Supports validation in the context of a new, or an already existing vnic.
       *
       * @param hostId - The ID of the host.
       * @param ipv4Address - The IP address to validate against.
       * @param vnicName(optional) - The name of the vnic, whose IP must be validated, or
       *                             null when a new vnic is being created.
       */
      function serverSideValidation(hostId, ipv4Address, vnicName) {
         var vnicSpec = { ipv4Address: ipv4Address };

         if (vnicName) {
            vnicSpec.vnicName = vnicName;
         }

         return mutationService.validate(
               hostId,
               'com.vmware.vsphere.client.h5.network.host.wizard.addnetworking.model.HostVnicIpv4SettingsSpec',
               vnicSpec
         ).then(function (validationResult) {
            var serverErrors = [];
            if (validationResult && validationResult.result) {
               serverErrors.push(validationResult.result);
            }
            return serverErrors;
         });

      }

      function getDefaultGatewaysErrors(validationData, validationErrorsLength) {
         var defaultGatewayErrors = [];
         if (!validationData.isGatewayFeatureSupported || !validationData.overrideGateway) {
            return defaultGatewayErrors;
         }

         // validate default gateway valid input
         // then validate same subnet mask of default gateway and ipv4 address
         // only if there are no other errors
         if (!validationData.ipv4DefaultGateway) {
            defaultGatewayErrors.push(i18nService.getString('H5NetworkUi',
                  'AddNetworkingWizard.ipv4SettingsPage.emptyDefaultGateway'));
         } else if (!ipParserService.isIpv4AddressValid(
                     validationData.ipv4DefaultGateway.trim())) {
            defaultGatewayErrors.push(i18nService.getString(
                  'NetworkUi', 'VnicData.invalidGateway'));
         } else if (validationErrorsLength === 0 && !ipParserService.isSameIpv4Subnet(
                     validationData.ipv4DefaultGateway,
                     validationData.ipv4Address,
                     validationData.subnetMask)) {
            defaultGatewayErrors.push(i18nService.getString('H5NetworkUi',
                  'AddNetworkingWizard.ipv4SettingsPage.invalidDefaultGateway'));
         }

         return defaultGatewayErrors;
      }
   }


})();
