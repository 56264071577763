/* Copyright 2018 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace h5_vm {

   import ManagedObjectReference = com.vmware.vim.binding.vmodl.ManagedObjectReference;
   import VmProvisioningPageModel = h5_vm.VmProvisioningPageModel;

   class VmWizardScheduleTaskPageModelFactory {
      public static $inject = [
         "i18nService"
      ];

      constructor(i18nService: any) {
         return () => {
            return new VmWizardScheduleTaskPageModel(
                  i18nService);
         };
      }
   }

   export class VmWizardScheduleTaskPageModel implements VmProvisioningPageModel<any> {
      static $inject: string[] = ["i18nService"];

      constructor(private i18nService: any) {
      }

      /**
       * [page specific]
       * Keeps the info about if the page is validating at the moment or no.
       * Used to determine if the spinner in the compatibility result is displayed or no
       * Used to determine if the next button of the wizard is displayed or no
       */
      public isValidating: boolean;
      /**
       * Schedule Task Form Data
       */
      public mutationSpec: any;

      /**
       * [page specific]
       *
       * Keeps the schedule task state between different visualization of the page
       * during the wizard lifetime.
       */
      public isScheduledTaskFlow: boolean;

      public validatePage(): Object {
         let validationError: string = "";
         if (this.mutationSpec.schedulingSpec.isFormStatusValid === false ||
               this.mutationSpec.schedulingSpec.recurrence === null) {
            validationError = this.i18nService.getString("VmUi",
                  "wizard.scheduleTaskPage.invalidSchedulerError");
            return {error: validationError};
         }
         return {};
      }

      public submitPage(): any {
         return {
            invalidateNextPages: false};
      }

      public resetForm(): void {
      }
   }
   angular.module("com.vmware.vsphere.client.vm")
         .service("vmWizardScheduleTaskPageModel",
               VmWizardScheduleTaskPageModelFactory);
} // module
