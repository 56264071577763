/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace dvs_ui
{
   import DvsPortMirroringSourcePorts =
         com.vmware.vsphere.client.h5.network.dvs.portmirroring.model.DvsPortMirroringSourcePorts;
   import DvsPortMirroringDestinationPorts =
         com.vmware.vsphere.client.h5.network.dvs.portmirroring.model.DvsPortMirroringDestinationPorts;
   import IPromise = angular.IPromise;

   export class DvsPortMirroringSourcesAndDestRefreshService {

      public static readonly $inject = [
         "dataService",
         "dvsPortMirroringPortsDetailsDataService"
      ];

      private _sourcePortsDataPromise:IPromise<DvsPortMirroringSourcePorts>;

      private _destinationPortsDataPromise:IPromise<DvsPortMirroringDestinationPorts>;

      constructor(private dataService:any,
                  private dvsPortMirroringPortsDetailsDataService:any) {

      }

      public refreshDvsPortMirroringSourcesView($scope:any,
                                                $rootScope:any,
                                                selectedSession:string,
                                                fillTableEntries:Function) {

         $scope.portMirrorLoading = true;

         let dSwitchUri:string = $rootScope._route.objectId;

         let requestPromise:IPromise<DvsPortMirroringSourcePorts> =
               this.dvsPortMirroringPortsDetailsDataService
                     .getDvsPortMirrorSourcePortsDataDetails(
                     dSwitchUri, selectedSession);

         this._sourcePortsDataPromise = requestPromise;

         requestPromise
               .then((detailsData:DvsPortMirroringSourcePorts) => {
                  if (this._sourcePortsDataPromise === requestPromise) {
                     fillTableEntries(detailsData);
                  }
               });
      }

      public refreshDvsPortMirroringDestinationView($scope:any,
                                                    $rootScope:any,
                                                    selectedSession:string,
                                                    fillTableEntries:Function) {

         $scope.portMirrorLoading = true;

         let dSwitchUri:string = $rootScope._route.objectId;

         let requestPromise:IPromise<DvsPortMirroringDestinationPorts> =
               this.dvsPortMirroringPortsDetailsDataService
                     .getDvsPortMirrorDestPortsDataDetails(
                     dSwitchUri, selectedSession);

         this._destinationPortsDataPromise = requestPromise;

         requestPromise
               .then((detailsData:DvsPortMirroringDestinationPorts) => {
                  if (this._destinationPortsDataPromise === requestPromise) {
                     fillTableEntries(detailsData);
                  }
               });

      }

   }

   angular.module("com.vmware.vsphere.client.dvs")
         .service("dvsPortMirroringSourcesAndDestRefreshService",
         DvsPortMirroringSourcesAndDestRefreshService);
}