/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
/*
 * Service to create VmfsNameAndDevicePage. Validates the datastore name and selected storage device.
 */
(function () {
   'use strict';
   angular.module('com.vmware.vsphere.client.storage')
      .service('vmfsNameAndDevicePageService', vmfsNameAndDevicePageService);

   vmfsNameAndDevicePageService.$inject = [
         'i18nService',
         'wizardPageService',
         'addDatastoreNameValidatorService',
         'datastoreConstants',
         '$q',
         'storageUtil'];

   function vmfsNameAndDevicePageService(
         i18nService, wizardPageService,
         addDatastoreNameValidatorService, datastoreConstants, $q, storageUtil) {

      return {
         build: function (wizardScope) {
            return {
               title: i18nService.getString('StorageUi', 'addDatastoreWizard.vmfsSelectDevicePage.title'),
               description: i18nService.getString('StorageUi', 'addDatastoreWizard.vmfsSelectDevicePage.description'),
               contentUrl: 'storage-ui/resources/storage/views/wizard/createDatastore/pages/vmfs/VmfsNameAndDevicePage.html',
               state: undefined,
               onCommit: function() {
                  wizardScope.wizardConfig.loading = true;
                  return validate(wizardScope).then(function (isPageValid) {
                     wizardScope.wizardConfig.loading = false;
                     return isPageValid;
                  }, function (error) {
                     wizardScope.removeLoadingAndShowError(error);
                     return false;
                  });
               }
            };
         }
      };

      function validate(wizardScope) {
         var selectedDevice = wizardScope.vmfsWizardManager.getSelectedScsiDiskInfo();

         // Check for empty selection in device list
         if (!selectedDevice) {
            wizardPageService.markPageIncompleteWithError(
                  wizardScope.wizardConfig,
                  i18nService.getString('StorageUi', 'datastoreWizards.selectDevicePage.noSelection')
            );
            return $q.when(false);
         }

         if (storageUtil.is4kDevice(selectedDevice.disk) &&
               !wizardScope.vmfsWizardManager.getIsVmfs6Supported()) {
            wizardPageService.markPageIncompleteWithError(
               wizardScope.wizardConfig,
               i18nService.getString('StorageUi', 'datastoreWizards.selectDevicePage.incompatibleSelection')
            );
            return $q.when(false);
         }

         // Validate datastore name
         return addDatastoreNameValidatorService.validate(
               wizardScope.commonWizardManager.getContextObject(),
               wizardScope.commonWizardManager.getDatastoreName(),
               datastoreConstants.maxNameLengthForNonNfsDatastore)
               .then(function (datastoreNameValidationError) {
                  if (datastoreNameValidationError) {
                     wizardPageService.markPageIncompleteWithError(
                           wizardScope.wizardConfig,
                           datastoreNameValidationError);
                     return false;
                  }

                  wizardScope.wizardConfig.validationBanner.messages = [];
                  wizardPageService.markPageComplete(wizardScope.wizardConfig,
                     wizardScope.commonWizardManager.getDatastoreType());
                  return true;
               });
      }
   }
})();
