/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
(function () {
   'use strict';
   angular.module('com.vmware.vsphere.client.network')
         .service('tcpIpStackServicesValidator',
               tcpIpStackServicesValidator);

   tcpIpStackServicesValidator.$inject = [
      'dataService'
   ];

   var IS_SYSTEM_TRAFFIC_ENABLED_PROPERTY = "vnic:isSystemTrafficEnabled";

   function tcpIpStackServicesValidator(dataService) {

      function isSystemTrafficEnabledOnDefaultStack(netStackInstanceKey, hostId) {

         var systemTraffic = netStackInstanceKey;

         return dataService.getProperties(hostId,
               [IS_SYSTEM_TRAFFIC_ENABLED_PROPERTY], {
                  propertyParams: [
                     {
                        propertyName: IS_SYSTEM_TRAFFIC_ENABLED_PROPERTY,
                        parameterType: 'java.lang.String',
                        parameter: systemTraffic
                     }
                  ]
               });
      }

      return {
         isSystemTrafficEnabledOnDefaultStack: isSystemTrafficEnabledOnDefaultStack
      };
   }
})();
