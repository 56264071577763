/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace storage_ui {

   import IAngularStatic = angular.IAngularStatic;
   import IIntervalService = angular.IIntervalService;
   import IAugmentedJQuery = angular.IAugmentedJQuery;
   import IScope = angular.IScope;
   import IAngularEvent = angular.IAngularEvent;
   declare let h5: any;
   declare let $: any;


   /**
    * File upload event and task state constants.
    */
   angular.module("com.vmware.vsphere.client.storage").constant("fileUploadConstants", {
      event: {
         UPDATED: "FILE_UPLOAD_UPDATED",
         COMPLETE: "FILE_UPLOAD_COMPLETE"
      },
      state: {
         RUNNING: "RUNNING",
         SUCCESS: "SUCCESS",
         ERROR: "ERROR",
         CANCELED: "CANCELED"
      }
   });

   class PendingCancelRequestsRegistry {
      private datastoresWithCancelledUploads: {[key: string]: boolean} = {};

      contructor() {}

      public add(datastoreId: string): void {
         this.datastoresWithCancelledUploads[datastoreId] = true;
      }

      public remove(datastoreId: string): void {
         this.datastoresWithCancelledUploads[datastoreId] = false;
      }

      public isPresent(datastoreId: string): boolean {
         return this.datastoresWithCancelledUploads[datastoreId];
      }
   }

   /**
    * Service for uploading files to a datastore and tracking the transfer
    * progress.
    */
   class FileUploadService {

      private readonly MAX_UPLOADS_AT_TIME = 10;
      private readonly POLL_INTERVAL_MS = 3 * 1000;
      private uploadSessions: any[];

      private xhrRequestBySessionId: { [id: number]: XMLHttpRequest|null; } = {};
      private currentUploadSessionId: number = 0;

      private slotsAvailable: number;

      private pendingCancelRequestsRegistry: PendingCancelRequestsRegistry =
            new PendingCancelRequestsRegistry();

      constructor(private $rootScope: IScope,
                  private fileUploadConstants: any,
                  private fileUrlResolverService: any,
                  private $cookies: any,
                  private defaultUriSchemeUtil: any,
                  private i18nService: any,
                  private storageUtil: any,
                  private $interval: IIntervalService,
                  private clarityModalService: any,
                  private $q: any) {
         this.uploadSessions = [];
         $rootScope.$on('sessionExpired', this.onSessionExpiration);
         $rootScope.$on('onUserLogout', this.onUserLogout);
      }

      /**
       * Returns Boolean value indicating if there is running upload
       * task.
       * NOTE: The upload session array is traversed starting from
       * the last element because sessions are added at the
       * back of the array so it is more likely for a running
       * session to be closer to the end of the array.
       */
      private isUploadInProgress(): boolean {
         let lastRunningSessionIndex =
               _.findLastIndex(this.uploadSessions, {
                  status: this.fileUploadConstants.state.RUNNING
               });

         return (lastRunningSessionIndex !== -1);
      }

      /**
       * Handles session expiration events.
       * If there is upload still in progress prevents
       * session expiration.
       */
      onSessionExpiration = (event: IAngularEvent) => {
         if (this.isUploadInProgress()) {
            event.preventDefault();
         }
      };

      /**
       * Handles user logout events.
       * If there is upload still in progress shows confirmation message for the logout operation.
       */
      onUserLogout = (event: IAngularEvent, options: any) => {
         if (this.isUploadInProgress()) {
            let logoutConfirmationResult: any = this.$q.defer();
            this.clarityModalService.openConfirmationModal({
               message: this.i18nService.getString("StorageUi", "upload.logout.warning.message"),
               title: this.i18nService.getString("StorageUi", "upload.logout.warning.title"),
               clarityIcon: {
                  class: "is-warning",
                  shape: "warning-standard",
                  size: "32"
               },
               preserveNewlines: true,
               saveButtonLabel: this.i18nService.getString("Common", "yes.label"),
               cancelButtonLabel: this.i18nService.getString("Common", "no.label"),
               submit: function() {
                  // allow logout
                  logoutConfirmationResult.resolve(false);
               },
               onCancel: function() {
                  // prevent logout
                  logoutConfirmationResult.resolve(true);
               }

            });

            options.preventLogout = logoutConfirmationResult.promise;
            event.preventDefault();
         }

      };

      /**
       * Returns copy of the upload sessions array.
       *
       * @param datastoreId Optional parameter to get upload sessions associated with
       * a particular datastore. If not provided upload sessions for all datastores are
       * returned
       */
      public getUploadSessions(datastoreId: string|null|undefined): any[] {
         return this.uploadSessions.filter((uploadSession) => {
            return !datastoreId || uploadSession.datastoreId === datastoreId;
         });
      }

      public getRunningUploadSessions(datastoreId: string|null|undefined): any[] {
         return this.uploadSessions.filter((uploadSession) => {
            return (!datastoreId || uploadSession.datastoreId === datastoreId)
                  && uploadSession.status === this.fileUploadConstants.state.RUNNING;
         });
      }

      public startFilesUpload(datastoreDcRef: string, datastoreId: string, datastorePath: string, files: any[],
            isDatastoreSelected: boolean, isStreamFormat?: boolean) {
         let delimiter = isDatastoreSelected ? " " : "/";
         this.slotsAvailable = this.MAX_UPLOADS_AT_TIME;
         let stop: any = null;
         let existingFolders: any = {};

         let uploadNextBatch = () => {
            if (files.length === 0 ||  this.pendingCancelRequestsRegistry.isPresent(datastoreId)) {
               this.$interval.cancel(stop);
               this.pendingCancelRequestsRegistry.remove(datastoreId);
               return;
            }
            let batchToUpload: any[] = [];
            while (this.slotsAvailable && files.length !== 0) {
               let args = files.pop();

               let isRelativePathAvailableOnDatastore: boolean = true;

               if (args.webkitRelativePath) {
                  // In case of folder upload we should check whether
                  // the file path exists on the datastore
                  let lastFolderDelimiterIndex = args.webkitRelativePath.lastIndexOf("/");
                  if (lastFolderDelimiterIndex > 0) {
                     let relativePath: string =
                           args.webkitRelativePath.substring(0, lastFolderDelimiterIndex);

                     if (!existingFolders.hasOwnProperty(relativePath)) {
                        isRelativePathAvailableOnDatastore = false;

                        // the path doesn't exist, we should add it and its
                        // parent folders to the existingFolders map as they will
                        // be created when the file is uploaded.
                        let subFolders: string[] = relativePath.split("/");
                        let currentPath: string = "";
                        for (let i = 0; i < subFolders.length; i++) {
                           if (currentPath) {
                              currentPath += "/";
                           }
                           currentPath += subFolders[i];
                           existingFolders[currentPath] = true;
                        }
                     }
                  }
               }

               batchToUpload.push(args);
               this.slotsAvailable--;
               if (!isRelativePathAvailableOnDatastore) {
                  // the path doesn't exist, we shouldn't upload more items
                  // in the current batch
                  break;
               }
            }
            if (batchToUpload.length) {
               this.uploadBatch(batchToUpload, datastoreDcRef, datastoreId,
                     datastorePath, delimiter, isStreamFormat).then(
                     (uploadStarted: boolean) => {
                        if (!uploadStarted) {
                           this.$interval.cancel(stop);
                        }
                     });
            }
         };

         // Prevent a stale registry.
         this.pendingCancelRequestsRegistry.remove(datastoreId);
         stop = this.$interval(() => uploadNextBatch(), this.POLL_INTERVAL_MS);
         // avoid initial delay
         uploadNextBatch();
      }

      public cancelFilesUpload(datastoreId: string) {
         // Make batch upload stop (uploads enqueued but not yet started).
         this.pendingCancelRequestsRegistry.add(datastoreId);

         // Stop any currently running uploads.
         const uploading = this.getUploadSessions(datastoreId);
         uploading.forEach( (uploadSession) => {
            if (uploadSession.status === this.fileUploadConstants.state.RUNNING) {
               this.cancelUpload(uploadSession);
            }
         });
      }

      private uploadBatch(batchToUpload: any[], datastoreDcRef: string, datastoreId: string,
                          datastorePath: string, delimiter: string, isStreamFormat?: boolean) {
         let uploadTargetPaths = _.map(batchToUpload, (file)=>datastorePath + delimiter + (file.webkitRelativePath || file.name));
         return this.fileUrlResolverService.resolveFileUrls(datastoreDcRef, datastoreId,
               uploadTargetPaths, isStreamFormat)
               .then((response:any)=> {
                  if (!response || !response.fileTransferEndpoints || !response.fileTransferEndpoints.length) {
                     this.storageUtil.showErrorDialog(this.i18nService.getString("StorageUi", "fileUpload.error.noEndpointsError"));
                     return false;
                  } else {
                     for (let i = 0; i < response.fileTransferEndpoints.length; i++) {
                        let uploadTarget: any = undefined;
                        if (response.fileTransferEndpoints && response.fileTransferEndpoints.length
                              && response.fileTransferEndpoints[i].ticket) {
                           uploadTarget = response.fileTransferEndpoints[i];
                        } else {
                           uploadTarget = {url: this.getLocalProxyUploadUrl(datastoreDcRef, datastoreId, uploadTargetPaths[i], isStreamFormat)};
                        }
                        this.startXhrUpload(batchToUpload[i], datastorePath, uploadTargetPaths[i], uploadTarget.url, uploadTarget.ticket, datastoreId);
                     }
                     return true;
                  }
               });
      };

      private showError(uploadSession: any): void {
         this.storageUtil.showErrorDialog(uploadSession.error, uploadSession.trustErrorMessageAsHtml);
      }

      private finishUploadSession(uploadSession: any): void {
         this.xhrRequestBySessionId[uploadSession.id] = null;
         uploadSession.isCancelable = false;
         uploadSession.cancel = null;
      }

      private startXhrUpload(file: File, datastorePath: string, uploadTargetPath: string,
            uploadUrl: string, uploadTicket: string, datastoreId: string): void {

         let xhr = new XMLHttpRequest();
         let now = new Date();
         let uploadSession: any = {
            id: this.currentUploadSessionId++,
            name: uploadTargetPath,
            datastorePath: datastorePath,
            datastoreId: datastoreId,
            progress: 0,
            uploadedSizeInB: 0,
            sizeInB: file.size,
            status: this.fileUploadConstants.state.RUNNING,
            startTime: now,
            lastUpdated: now,
            isCancelable: true,
            uploadUrl: uploadUrl,
            isGeneralError: false,
            error: "",
            // indicates whether the error message should be sanitized or not:
            // true = do not sanitize; false = sanitize
            trustErrorMessageAsHtml: false
         };

         this.xhrRequestBySessionId[uploadSession.id] = xhr;
         uploadSession.showError = this.showError.bind(this, uploadSession);
         uploadSession.cancel = this.cancelUpload.bind(this, uploadSession);

         this.uploadSessions.splice(0, 0, uploadSession);
         this.broadcastEvent(
               this.fileUploadConstants.event.UPDATED, uploadSession);

         xhr.addEventListener("load", ()=> {
            this.slotsAvailable++;
            this.uploadComplete(uploadSession, xhr);
            removeOnProgressEventListener();
         }, false);

         xhr.addEventListener("error", ()=> {
            this.slotsAvailable++;
            this.uploadComplete(uploadSession, xhr);
            removeOnProgressEventListener();
         }, false);

         let onProgress = _.throttle((e:Event)=> {
            this.reportProgress(uploadSession, e);
         }, 2000);

         function removeOnProgressEventListener() {
            if (xhr.upload) {
               xhr.upload.removeEventListener("progress", onProgress);
            }
         }

         if (xhr.upload) {
            xhr.upload.addEventListener("progress", onProgress);
         }
         xhr.open("PUT", uploadUrl);
         xhr.setRequestHeader("Content-type", "application/octet-stream");
         if (uploadTicket) {
            // There is an upload ticket, so trying upload directly to the given URL
            xhr.setRequestHeader("vmware-cgi-ticket", uploadTicket);
         } else {
            let xsrfCookie = this.$cookies.get(h5.xsrfCookieName);
            if (xsrfCookie) {
               xhr.setRequestHeader(h5.xsrfHeaderName, xsrfCookie);
            }
         }

         xhr.send(file);
      }

      /**
       * Gets the file transfer proxy URL.
       */
      private getLocalProxyUploadUrl(datastoreDcRef:any, datastoreId:string,
            uploadTargetPath:string, isStreamFormat?: boolean): string {
         let uploadSpec: any = {
            vcServerGuid: datastoreDcRef.serverGuid,
            datacenterId: this.defaultUriSchemeUtil.getVsphereObjectId(datastoreDcRef),
            datastoreId: datastoreId,
            datastorePath: uploadTargetPath
         };

         if (isStreamFormat) {
            uploadSpec.diskFormat = "StreamVmdk";
         }

         let queryString = $.param(uploadSpec);

         let uploadUrlBuilder = document.createElement("a");
         uploadUrlBuilder.href = "fileupload";
         uploadUrlBuilder.search = queryString;
         return uploadUrlBuilder.href;
      }

      /**
       * Cancels the upload session and removes it from the
       * list of sessions.
       */
      private cancelUpload(uploadSession:any): void {

         let xhr: XMLHttpRequest|null = this.xhrRequestBySessionId[uploadSession.id];
         if (xhr && uploadSession.isCancelable) {
            xhr.abort();
            this.finishUploadSession(uploadSession);
            uploadSession.status = this.fileUploadConstants.state.CANCELED;
            this.broadcastEvent(
                  this.fileUploadConstants.event.UPDATED, uploadSession);
         }
      }

      /**
       * Updates the upload session progress based on the
       * xhr progress.
       */

      private reportProgress(uploadSession:any, e:any): void {
         if (e.lengthComputable) {
            let percentComplete = Math.floor((e.loaded / e.total) * 100);
            if (percentComplete !== uploadSession.progress) {
               uploadSession.progress = percentComplete;
               uploadSession.uploadedSizeInB = e.loaded;
               uploadSession.lastUpdated = new Date();
               this.broadcastEvent(
                     this.fileUploadConstants.event.UPDATED, uploadSession);
            }
         }
      }

      /**
       * Event handler for upload completion.
       * Regardless of the state of the operation it broadcasts
       * complete event.
       */
      private uploadComplete(uploadSession:any, xhr:XMLHttpRequest): void {
         this.finishUploadSession(uploadSession);
         if (xhr.status === 200 || xhr.status === 201) {
            uploadSession.status = this.fileUploadConstants.state.SUCCESS;
            uploadSession.uploadedSizeInB = uploadSession.sizeInB;
         } else {
            uploadSession.status = this.fileUploadConstants.state.ERROR;
            let errorText = xhr.responseText;
            if (!errorText) {
               let l = document.createElement("a");
               l.href = uploadSession.uploadUrl;
               // removing everything except the protocol + server for the error message
               let serverUrl = `${l.protocol}//${l.hostname}`;

               const kbArticleAnchor = "<a href='http://kb.vmware.com/kb/2147256' target='_blank'>http://kb.vmware.com/kb/2147256</a>";
               const serverUrlAnchor = `<a href="${serverUrl}" target="_blank">${serverUrl}</a>`;
               errorText = this.i18nService.getString("StorageUi", "fileUpload.error.withUrl", serverUrlAnchor, kbArticleAnchor);
               uploadSession.isGeneralError = true;
               uploadSession.trustErrorMessageAsHtml = true;
            }
            uploadSession.error = errorText;
         }

         this.broadcastEvent(this.fileUploadConstants.event.UPDATED, uploadSession);
         this.broadcastEvent(this.fileUploadConstants.event.COMPLETE, uploadSession);
      }

      /**
       * Broadcasts and event and passes a copy of the upload
       * session as argument.
       */
      broadcastEvent(name:string, uploadSession:any): void {
         this.$rootScope.$broadcast(name, angular.copy(uploadSession));
      }

   }

   angular.module("com.vmware.vsphere.client.storage")
         .factory("fileUploadService", ["$rootScope", "fileUploadConstants", "fileUrlResolverService",
            "$cookies", "defaultUriSchemeUtil", "i18nService", "storageUtil", "$interval",
            "clarityModalService", "$q",
            ($rootScope: IScope, fileUploadConstants: any, fileUrlResolverService: any, $cookies: any,
             defaultUriSchemeUtil: any, i18nService: any, storageUtil: any,
             $interval: IIntervalService, clarityModalService: any, $q: any)=>new FileUploadService(
                  $rootScope, fileUploadConstants, fileUrlResolverService, $cookies,
                  defaultUriSchemeUtil, i18nService, storageUtil, $interval, clarityModalService, $q)]);
}
