/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace dvs_ui {

   import HostListItemData = com.vmware.vsphere.client.dvs.api.host.HostListItemData;
   import ManagedObjectReference = com.vmware.vim.binding.vmodl.ManagedObjectReference;
   import IPromise = angular.IPromise;
   import Dictionary = _.Dictionary;
   export class SelectHostsDialogService {

      static $inject = [
         "i18nService",
         "clarityModalService",
         "authorizationService",
         "defaultUriSchemeUtil",
         "clarityConstants"
      ];

      constructor(private i18nService: any,
                  private clarityModalService: any,
                  private authorizationService: any,
                  private defaultUriSchemeUtil: any,
                  private clarityConstants: any) {
      }

      public show(hostsPromise: IPromise<HostListItemData[]>,
                  okHandler: (selectedHostItems: HostListItemData[]) => void,
                  dialogTitle: string,
                  showVdsStateColumn: boolean,
                  showIncompatibleHostsButton: boolean,
                  allowAnySelection: boolean,
                  dvsId: string): void {

         let selectedHostItems: HostListItemData[] = [];
         let dialogData: any = {
            onSelectedHostsChange: (value: HostListItemData[]): void => {
               selectedHostItems = value;
            },
            showVdsStateColumn: showVdsStateColumn,
            allowAnySelection: allowAnySelection,
            showIncompatibleHostsButton: showIncompatibleHostsButton,
            loading: true
         };

         let modalOptions: any = {
            title: dialogTitle,
            defaultButton: "submit",
            size: "lg",
            alerts: [],
            dialogData: dialogData,
            contentTemplate: "dvs-ui/resources/dvs/addhost/components/selectHosts/selectHostsDialog.html",
            modalClass: "dvs-select-hosts-modal",
            subTitle: {
               objectId: dvsId
            }
         };

         let getHostNamesWithoutPrivileges = (privilegesPerObjectId: Dictionary<Dictionary<boolean>>): string[] => {
            let entriesWithoutPrivileges = _.filter(_.pairs(privilegesPerObjectId),
                  (entry: [string, Dictionary<boolean>]): boolean =>
                        !entry[1][Privileges.HOST_CONFIG_NETWORK]);
            return _.map(entriesWithoutPrivileges, (entry: [string, Dictionary<boolean>]): string => {
               let itemWithoutPrivileges = _.find(selectedHostItems,
                     (item: HostListItemData): boolean =>
                     entry[0] === this.defaultUriSchemeUtil.getVsphereObjectId(item.hostRef));
               return itemWithoutPrivileges.hostName;
            });
         };

         modalOptions.onSubmit = (): IPromise<boolean> => {
            let hostObjectIds = this.defaultUriSchemeUtil.getVsphereObjectIds(
                  _.map(selectedHostItems,
                        (item: HostListItemData): ManagedObjectReference => item.hostRef));
            return this.authorizationService.checkPrivilegesForMultipleObjects(
                  hostObjectIds, [Privileges.HOST_CONFIG_NETWORK])
                  .then(getHostNamesWithoutPrivileges)
                  .then((hostNamesWithoutPrivileges: string[]): boolean => {
                     if (hostNamesWithoutPrivileges.length > 0) {
                        modalOptions.alerts = [{
                           text: this.i18nService.getString(
                                 "DvsUi", "DvsSelectHostsDialog.noPrivileges",
                                 hostNamesWithoutPrivileges.join(", ")),
                           type: this.clarityConstants.notifications.type.ERROR
                        }];
                        return false;
                     } else {
                        okHandler(selectedHostItems);
                        return true;
                     }
                  });
         };
         hostsPromise
               .then((hosts: HostListItemData[]) => {
                  dialogData.hosts = hosts;
                  dialogData.loading = false;
               });
         this.clarityModalService.openOkCancelModal(modalOptions);
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .service("selectHostsDialogService", SelectHostsDialogService);
}
