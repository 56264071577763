namespace h5_vm {

// The hardware data model expected by the vmHardwareSummaryController.
// This is a private class used for readability.  No need to keep it separate.
class HardwareViewData {
   hardware: {
      numCPU: number,
      memoryMB: number,
      device: any[]
   };
   memoryData: any;
   cpuAllocation: any;
}

// The hardware data model returned by the model OvfTemplateHardwareData.java
// This is a private class used for readability.  No need to keep it separate.
interface OvfTemplateHardwareData {
   cpu: any;
   memory: any;
   diskData: any[];
   videoCardData: any[];
   floppyData: any[];
   nicData: any[];
   driveData: any[];
   diskControllerData: any[];
}

/**
 * Service converting the OvfTemplate hardware data to the format used by the VM hardware summary
 */
export class OvfTemplateHardwareDataMapping {
   constructor() { }

   public convertOvfTemplateHardwareData(data: OvfTemplateHardwareData): HardwareViewData {
      let hwData = new HardwareViewData();
      hwData.hardware = {
         numCPU: data.cpu.numCpus,
         memoryMB: data.memory.size,
         device: this.convertDevices(data)
      };
      hwData.cpuAllocation = {
         reservation: data.cpu.reservation ? data.cpu.reservation : 0,
         limit: data.cpu.limit ? data.cpu.limit : -1,
         shares: data.cpu.shares ? data.cpu.shares : "-"
      };
      hwData.memoryData = {
         memoryAllocation : {
            reservation: data.memory.reservation ? data.memory.reservation : 0,
            limit: data.memory.limit ? data.memory.limit : -1,
            shares: data.memory.shares ? data.memory.shares : "-"
         },
         memoryUtilization: 0,
         isVmTemplate: true
      };
      return hwData;
   }

   // TODO usbControllerData?
   private convertDevices(data: OvfTemplateHardwareData): any[] {
      let devices: any[] = [];
      let self = this;

      if (data.diskData) {
         let key = 2000;
         data.diskData.forEach(function(diskItem) {
            devices.push({
               _type: "com.vmware.vim.binding.vim.vm.device.VirtualDisk",
               key: key++,
               deviceInfo: {
                  label: diskItem.name
               },
               backing: {},
               capacityInKB: diskItem.diskCapacity * 1014
            });
         });
      }
      if (data.nicData) {
         let key = 4000;
         data.nicData.forEach(function(nicItem) {
            devices.push({
               _type: "com.vmware.vim.binding.vim.vm.device.VirtualE1000",
               key: key++,
               deviceInfo: {
                  label: nicItem.name,
                  summary: nicItem.networkName
               },
               backing: { },
               macAddress: nicItem.macAddress,
               startConnected: nicItem.startConnected
            });
         });
      }
      if (data.driveData) {
         let key = 16000;
         data.driveData.forEach(function(driveItem) {
            devices.push({
               _type: "com.vmware.vim.binding.vim.vm.device.VirtualCdrom",
               key: key++,
               deviceInfo: {
                  label: driveItem.name,
                  type: driveItem.type,
                  subType: driveItem.subType
               },
               backing: { }
            });
         });
      }
      if (data.floppyData) {
         let key = 17000;
         data.floppyData.forEach(function(item) {
            devices.push({
               _type: "com.vmware.vim.binding.vim.vm.device.VirtualFloppy",
               key: key++,
               deviceInfo: {
                  label: item.name,
                  type: item.type
               },
               backing: { }
            });
         });
      }
      if (data.videoCardData) {
         let key = 500;
         data.videoCardData.forEach(function(item) {
            devices.push({
               _type: "com.vmware.vim.binding.vim.vm.device.VirtualVideoCard",
               key: key++,
               deviceInfo: {
                  label: item.name,
                  type: item.type
               },
               videoRamSizeInKB: item.videoRamSize,
               numDisplays: item.numDisplays ? item.numDisplays : "-",
               enable3DSupport: item.enable3d,
               use3dRenderer: "-"
            });
         });
      }
      if (data.diskControllerData) {
         let key = 200;
         data.diskControllerData.forEach(function(ctrlItem) {
            devices.push({
               _type: self.getDeviceControllerType(ctrlItem),
               key: key++,
               deviceInfo: {
                  label: ctrlItem.name
               },
               backing: { }
            });
         });
      }

      return devices;
   }

   private getDeviceControllerType(ctrlData: any): string {
      switch (ctrlData.type) {
         case "SCSI":
            return "com.vmware.vim.binding.vim.vm.device.VirtualSCSIController";
         case "IDE":
            return "com.vmware.vim.binding.vim.vm.device.VirtualIDEController";

         // TODO complete this list if if makes sense for OvfTemplates
         default:
            return "com.vmware.vim.binding.vim.vm.device.VirtualController";
      }
   }
}

angular.module('com.vmware.vsphere.client.vm')
      .service('ovfTemplateHardwareDataMapping', OvfTemplateHardwareDataMapping);
}