/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace dvs_ui {

   export class DvsSpanSessionEditableListComponent {

      public bindings: any;
      public controller: any;
      public templateUrl: string;

      constructor() {
         this.bindings = {
            model: "<",
            validator: "<",
            pageId: "@"
         };

         this.controller = DvsSpanSessionEditableListComponentController;

         this.templateUrl = "dvs-ui/resources/dvs/portmirroring/common/components/" +
               "dvsSpanSessionEditableListComponentTemplate.html";
      }
   }

   class DvsSpanSessionEditableListComponentController {

      static $inject = [
         "i18nService",
         "vuiConstants",
         "ipParserService",
         "vxValidatorFactory",
         "dvsSpanSessionEditableListValidator",
         "networkUtil"
      ];

      // action IDs
      static ADD_ACTION: string = "addIpAction";
      static REMOVE_ACTION: string = "removeIpAction";

      public model: DvsSpanSessionEditableListModel;

      public validator: DvsSpanSessionPageValidator;

      public readonly pageId: string;

      public datagridOptions: any;

      public getString: (key: string, bundle?: string) => string;

      private _loaded: boolean;

      private getInvalidValueError: Function;

      private getDuplicateValueError: Function;

      private columnName: string;

      constructor(private i18nService: any,
                  private vuiConstants: any,
                  private ipParserService: any,
                  private vxValidatorFactory: any,
                  private dvsSpanSessionEditableListValidator: DvsSpanSessionEditableListValidator,
                  private networkUtil: any) {
         this.getString = (key: string, bundle: string = "DvsUi"): string => {
            return i18nService.getString(bundle, key);
         };

         this._loaded = false;
      }

      public $onInit(): void {

         switch (this.pageId) {
            case DvsSpanSessionConstants.PAGE_ID.SELECT_SOURCE_VLANS:
               this.getInvalidValueError = (item: any): string | null => {
                  return this.dvsSpanSessionEditableListValidator.getVlanIdError(item);
               };

               this.getDuplicateValueError = (value: string, allValues: string[]): string | null => {
                  let occurrences: number = 0;

                  let isDuplicate: boolean = (_.any(allValues, (dataItem: any): boolean => {
                     return dataItem.value === value && occurrences++ === 1;
                  }));

                  return isDuplicate
                        ? this.getString(
                              "AddSpanSessionWizard.sourceVlansPage.duplicateVlanIdError")
                        : null;
               };

               this.columnName = this.getString("AddSpanSessionWizard.sourceVlansPage.vlanId");
               break;
            case DvsSpanSessionConstants.PAGE_ID.SELECT_DESTINATION_ADDRESSES:
               this.getInvalidValueError = (item: any): string | null => {
                  return this.dvsSpanSessionEditableListValidator.getIpAddressError(item);
               };

               this.getDuplicateValueError = (value: string, allValues: string[]): string | null => {
                  let actualAddress: string = this.ipParserService.isIpv6AddressValid(value)
                        ? this.ipParserService.expandIpv6Address(value) : value;

                  let actualValues: string[] = allValues.map((item: any): string => {
                     return this.ipParserService.isIpv6AddressValid(item.value)
                           ? this.ipParserService.expandIpv6Address(item.value) : item.value;
                  });

                  let occurrences: number = 0;

                  let isDuplicate: boolean = (_.any(actualValues, (addr: string): boolean => {
                     return addr === actualAddress && occurrences++ === 1;
                  }));

                  return isDuplicate
                        ? this.getString(
                              "AddSpanSessionWizard.destinationsPage.IpAddressList.ipAddress.error")
                        : null;
               };
               this.columnName = this.getString(
                     "AddSpanSessionWizard.destinationsPage.IpAddressList.ipAddress");
               break;
            default:
               throw new Error("Page ID not supported or not specified!");
         }

         // create a new vxValidator so that validation callbacks from previous instances
         // of this page are not called and can be GC-ed
         this.validator.vxValidator = this.vxValidatorFactory.create();

         this.datagridOptions = {
            height: "100%",
            pageConfig: {
               hidePager: true
            },
            columnDefs: this.getColumnDefinitions(),
            sortMode: this.vuiConstants.grid.sortMode.SINGLE,
            selectionMode: this.vuiConstants.grid.selectionMode.SINGLE,
            data: [],
            selectedItems: [],
            resizable: true,
            showCheckboxesOnMultiSelection: false,
            actionBarOptions: { actions: this.getActionDefinitions() },
            onChange: this.onSelectionChange.bind(this),
            validator: this.validator,
            syncListModel: this.syncListModel.bind(this),
            validateAndSyncModel: this.validateAndSyncModel.bind(this),
            selectItem: (item: any): void => {
               let grid: any = $(".dvs-span-session-editable-grid [kendo-grid]").data("kendoGrid");
               let selector: string = `tr[data-uid='${item.uid}']`;
               let trElement: any = $(selector)["0"];
               grid.select(trElement);
            }
         };

         setTimeout(this.loadExistingValues.bind(this), 0);
      }

      public $onDestroy(): void {
         delete this.validator["vxValidator"];
      }

      private onSelectionChange(items: any[]): void {
         this.setActionAvailability(
               DvsSpanSessionEditableListComponentController.REMOVE_ACTION,
               items && items.length > 0);

         if (this._loaded) {
            this.datagridOptions.validator.validate();

            this.datagridOptions.dataSource._data.forEach((item: any): void => {
               item.active = !item.valid || _.contains(items, item) || false;
            });

            this.syncListModel();
         }
      }

      private loadExistingValues(): void {
         if (this.model.cachedData) {
            this.model.cachedData.forEach((item: any): void => {
               this.datagridOptions.dataSource.add(item);
            });

            this.setItemCallbacks();
         }

         this._loaded = true;
      }

      private getColumnDefinitions(): any[] {
         return [{
            displayName: this.columnName,
            field: "value",
            type: "string",
            template: (dataItem: any): string => {
               let validator: string =
                     `datagridOptions.validator`;
               let vxValidator: string =
                     `datagridOptions.validator.vxValidator`;

               return `<vsc-validate class='validateable-input' ng-if='dataItem.active' [validator]='${vxValidator}'
                           [required]='false' [custom-validation-function]='dataItem.validationFunction'
                           [clr-tooltip-direction]="'top-left'">
                           <input class='fill-parent' ng-model='dataItem.value' type='text'
                                 vx-focus-and-select ng-blur='datagridOptions.validateAndSyncModel()'
                                 ng-change='datagridOptions.syncListModel()'
                                 ng-click='datagridOptions.selectItem(dataItem)'/>
                        </vsc-validate>
                        <span ng-if='!dataItem.active' ng-bind='dataItem.value' ng-click='dataItem.onClick()'>`;
            },
            sortable: (item1: any, item2: any): number => {
               return this.networkUtil.compareNumericAndNonNumericValues(item1, item2, "value");
            }
         }];
      }

      private getActionDefinitions(): any[] {
         return [{
            id: DvsSpanSessionEditableListComponentController.ADD_ACTION,
            iconClass: "vsphere-icon-add",
            enabled: true,
            onClick: (): void => { this.addRow(); }
         }, {
            id: DvsSpanSessionEditableListComponentController.REMOVE_ACTION,
            iconClass: "vsphere-icon-remove",
            enabled: true,
            onClick: (): void => { this.removeSelectedRows(); }
         }];
      }

      private addRow(): void {
         // add a row only if the last one is not empty and is valid
         let canAddRow: boolean =
               !this.datagridOptions.dataSource._data ||
               !this.datagridOptions.dataSource._data.length ||
               (_.last(this.datagridOptions.dataSource._data) as any).valid; // no implicit any :(
         if (canAddRow) {
            // deactivate all valid rows
            this.datagridOptions.dataSource._data.forEach((item: any): void => {
               item.active = !item.valid;
            });

            let item: any = {
               value: "",
               active: true,
               valid: true
            };

            this.datagridOptions.dataSource.add(item);

            this.setItemCallbacks();

            this.datagridOptions.selectItem(
                  _.last(this.datagridOptions.dataSource._data));
         }
      }

      private setItemCallbacks(): void {
         this.datagridOptions.dataSource._data.forEach((item: any): void => {
            item.validationFunction = (): string[] => {
               if (item.deleted) {
                  return [];
               }

               let error: string|null = this.getInvalidValueError(item.value) ||
                     this.getDuplicateValueError(item.value, this.datagridOptions.dataSource._data);

               item.valid = error === null;
               return !item.valid ? [error as string] : [];
            };
            item.onClick = (): void => {
               item.active = true;
            };
         });
      }

      private removeSelectedRows(): void {
         if (this.datagridOptions.selectedItems) {
            this.datagridOptions.selectedItems.forEach((item: any): void => {
               this.datagridOptions.dataSource.remove(item);
               // this item's validation callback is active as long as the
               // vxValidator instance exists, there is no way to unhook it so
               // mark it as inactive
               item.deleted = true;
            });

            this.validateAndSyncModel();

            this.setActionAvailability(
                  DvsSpanSessionEditableListComponentController.REMOVE_ACTION,
                  false);
         }
      }

      private syncListModel(): void {
         let values: any[] = [];
         let cachedData: any[] = [];

         this.datagridOptions.dataSource._data.forEach((item: any) => {
            values.push(item.value);

            cachedData.push({
               value: item.value,
               active: item.active,
               valid: item.valid
            });
         });

         this.model.values = (typeof this.model.values === "undefined"
               || this.hasCollectionChanged(values, this.model.values))
                     ? values : this.model.values;

         this.model.cachedData = (typeof this.model.cachedData === "undefined"
               || this.hasCollectionChanged(values, this.model.cachedData))
                     ? cachedData : this.model.cachedData;
      }

      private hasCollectionChanged(original: any[], proposed: any[]): boolean {
         if (original.length !== proposed.length) {
            return true;
         }

         return _.zip(original, proposed).some((tuple: [any, any]): boolean => {
            return tuple[0] !== tuple[1];
         });
      }

      private validateAndSyncModel(): void {
         this.datagridOptions.validator.validate();
         this.syncListModel();
      }

      private setActionAvailability(actionId: string, enabled: boolean) {
         for (let actionDef of this.datagridOptions.actionBarOptions.actions) {
            if (actionDef.id === actionId) {
               actionDef.enabled = enabled;
               break;
            }
         }
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .component("dvsSpanSessionEditableList",
               new DvsSpanSessionEditableListComponent());
}
