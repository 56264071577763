'use strict';
angular
    .module('com.vmware.vsphere.client.vm')
    .constant('guestosCustomizationConstants', {
        UNCUSTOMIZABLE: "UNCUSTOMIZABLE_GUEST",
        MISSING_WINDOWS: "MISSING_WINDOWS_CUSTOMIZATION",
        MISSING_LINUX: "MISSING_LINUX_CUSTOMIZATION",
        TYPE_WINDOWS: 'Windows',
        TYPE_LINUX: 'Linux',
        TYPE_OTHER: 'Unknown'
    });
