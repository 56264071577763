(function () {
   'use strict';
   angular.module('com.vmware.vsphere.client.vm').factory('VirtualSCSIController', [
      'EditableVirtualDevice',
      function(EditableVirtualDevice) {
         return function(device, attributes){

            EditableVirtualDevice.call(this, device);

            this.getMaxDisks = function () {
               return attributes.maxDisks;
            };

            this.getReservedNode = function(){
              return attributes.reservedNode;
            };

            this.getDiskNodeIndices = function () {
               return _.range(0, attributes.reservedNode).concat(
                  _.range(attributes.reservedNode + 1, this.getMaxDisks() + 1)
               );
            };

            this.getMaxCdroms = function() {
               return attributes.maxCdroms;
            };

            this.getCdromNodeIndices = function(){
               return _.range(0, attributes.reservedNode).concat(
                  _.range(attributes.reservedNode + 1, this.getMaxCdroms() + 1)
               );
            };
         };
      }
   ]);
})();
