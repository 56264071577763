/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace dvs_ui {

   import DvsAddHostHierarchicalListItem = com.vmware.vsphere.client.h5.network.dvs.addhost.hierarchicalList.DvsAddHostHierarchicalListItem;
   import DvsAddHostHierarchicalListVmVnicItem = com.vmware.vsphere.client.h5.network.dvs.addhost.hierarchicalList.DvsAddHostHierarchicalListVmVnicItem;

   export class DvsSelectVirtualMachinesPageService {

      static $inject = [
         "i18nService",
         "columnRenderersRegistry"
      ];

      constructor(private i18nService: any,
                  private columnRenderersRegistry: any) {
      }

      /**
       * Returns the actionBarOptions object for the select virtual machines page.
       *
       * @param assignPortgroupHandler
       *    The handler for the assign port group button.
       *
       * @param resetChangesHandler
       *    The handler for the reset changes button.
       */
      public getActionBarOptions(assignPortgroupHandler: () => void,
                                 resetChangesHandler: () => void,
                                 viewSettingsHandler: () => void): any {
         return {
            actions: [{
               id: "assign-portgroup-action",
               label: this.i18nService.getString(
                     "DvsUi", "SelectVirtualMachinesPage.assignPortGroupButton.title"),
               tooltipText: this.i18nService.getString(
                     "DvsUi", "SelectVirtualMachinesPage.assignPortGroupButton.tooltip"),
               // Disabled initially, only enabled when selection occurs.
               enabled: false,
               iconClass: "network-lib-ui-icon-dvPortGroup",
               onClick: assignPortgroupHandler
            }, {
               id: "reset-changes-action",
               label: this.i18nService.getString(
                     "DvsUi", "SelectVirtualMachinesPage.unassignPortGroupButton.title"),
               tooltipText: this.i18nService.getString(
                     "DvsUi", "SelectVirtualMachinesPage.unassignPortGroupButton.tooltip"),
               // Disabled initially, only enabled when selection occurs.
               enabled: false,
               iconClass: "network-lib-ui-icon-undo",
               onClick: resetChangesHandler
            }, {
               id: "view-settings-action",
               label: this.i18nService.getString(
                     "DvsUi", "SelectVirtualMachinesPage.viewSelectedVnicDetailsButton.title"),
               // Disabled initially, only enabled when selection occurs.
               enabled: false,
               iconClass: "network-lib-ui-icon-info",
               onClick: viewSettingsHandler
            }]
         };
      }

      /**
       * Reeturns the column definitions for the select virtual machines page.
       */
      public getColumnDefs(): any[] {
         let iconTextRenderer: any =
               this.columnRenderersRegistry.getColumnRenderer("icon-text");
         let textRenderer: any =
               this.columnRenderersRegistry.getColumnRenderer("text");
         return [{
            field: "label",
            title: this.i18nService.getString(
                  "DvsUi", "SelectVirtualMachinesPage.hostVmVnic"),
            // The width is calculated so that it can accommodate a host name
            // that is an IPv6 address, e.g. "2001:0db8:0a0b:12f0:0000:0000:0000:0001".
            width: "260px",
            template: (dataItem: any) => {
               return iconTextRenderer(["icon", "label"], {
                  icon: dataItem.iconClass,
                  label: dataItem.label
               });
            }
         }, {
            field: "nicCount",
            title: this.i18nService.getString(
                  "DvsUi", "SelectVirtualMachinesPage.nicCount"),
            width: "84px",
            template: (dataItem: any) => {
               return textRenderer(["nicCount"], dataItem);
            }
         }, {
            field: "sourcePortgroup",
            title: this.i18nService.getString(
                  "DvsUi", "SelectVirtualMachinesPage.sourcePortgroup"),
            // Calculated so that it can accommodate "Source port group" in English
            // locale. The maximum column width so that other column names are not
            // trimmed in English.
            // Might be trimmed in non-English other locales.
            width: "142px",
            template: (dataItem: any) => {
               return textRenderer(["sourcePortgroup"], dataItem);
            }
         }, {
            field: "destinationPortgroup",
            title: this.i18nService.getString(
                  "DvsUi", "SelectVirtualMachinesPage.destPortgroup"),
            template: (dataItem: any) => {
               if (dataItem.type ===
                     DvsAddHostWizardConstants.nicListItemType.VM_TYPE) {
                  // A VM.
                  let label: string = dataItem.isReassigned
                        ? this.i18nService.getString(
                              "DvsUi",
                              "SelectVirtualAdaptersPage.virtualMachineState.reassigned")
                        : "";
                  return $("<span/>")
                        .attr("ng-non-bindable", "")
                        .addClass("dvs-add-host-vm-reassigned-column")
                        .text(label)[0].outerHTML;
               } else {
                  // An  adapter or a host.
                  return textRenderer(["destinationPortgroup"], dataItem);
               }
            }
         }];
      }

      /**
       * Returns the schema for the hierarchical grid data model in the
       * select virtual machines page.
       */
      public getTreeListSchema(): any {
         return {
            model: {
               id: "id",
               expanded: false
            }
         };
      };

      /**
       * Returns true if the provided VM virtual adapter is modified throughout the
       * lifetime of the wizard session.
       *
       * @param vnicItemWrapper
       *    The item to check.
       */
      public isVnicModified(vnicItemWrapper: DvsAddHostTreeListItemWrapper): boolean {
         let originalVnicItem: DvsAddHostHierarchicalListVmVnicItem =
               vnicItemWrapper.originalItem as DvsAddHostHierarchicalListVmVnicItem;
         let updatedItem: DvsAddHostHierarchicalListItem =
               vnicItemWrapper as DvsAddHostHierarchicalListItem;
         let updatedVnicItem: DvsAddHostHierarchicalListVmVnicItem =
               updatedItem as DvsAddHostHierarchicalListVmVnicItem;
         return originalVnicItem.destinationPortgroupRef !==
               updatedVnicItem.destinationPortgroupRef;
      }

      /**
       * Returns the number of updated virtual machines adapters in the wizard.
       *
       * @param treeListItems
       *    The array of all items on the Select virtual machines page.
       */
      public getNumberOfUpdatedVirtualMachineAdapters(treeListItems: DvsAddHostTreeListItemWrapper[]): number {
         let reassginedAdapters: DvsAddHostTreeListItemWrapper[] =
               _.filter(treeListItems, (treeListItem: DvsAddHostTreeListItemWrapper) => {
                  return treeListItem.type ===
                        DvsAddHostWizardConstants.nicListItemType.VM_VNIC_TYPE &&
                        this.isVnicModified(treeListItem);
               });
         return reassginedAdapters.length;
      }

      /**
       * Expands only the host items in the hosts/vms grid.
       *
       * @param treeListWidget
       *    A reference to the the kendo data grid.
       * @param treeListDataSource
       *    The data source of the grid.
       */
      public expandHostItems(treeListWidget: any,
                             treeListDataSource: any): boolean {

         let itemExpanded: boolean = false;

         if (!treeListDataSource || !treeListWidget) {
            return itemExpanded;
         }

         _.forEach(treeListDataSource.rootNodes(), (item: DvsAddHostTreeListItemWrapper) => {

            if (item.parentId === null) {
               // Expand the host items
               let hostItemRow: any = treeListWidget.itemFor(item);
               if (hostItemRow && !hostItemRow.expanded) {
                  treeListWidget.expand(hostItemRow);
                  itemExpanded = true;
               }
            }
         });
         return itemExpanded;
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .service("dvsSelectVirtualMachinesPageService",
               DvsSelectVirtualMachinesPageService);
}
