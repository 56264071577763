namespace h5_vm {

/**
 * A "builder" for the SelectFolderPageService.
 */
export class VmMigrationSelectFolderPageService {
   static $inject: string[] = [
      "i18nService",
      "wizardPageService"
   ];

   constructor(private i18nService: any, private wizardPageService: any) {
   }

   public build(wizardScope: any) {
      return {
         title: this.i18nService.getString(
               "VmUi", "MigrationWizard.selectFolderPage.Title"),
         description: this.i18nService.getString(
               "VmUi", "MigrationWizard.selectFolderPage.Description"),
         contentUrl: "vm-ui/resources/vm/views/migrationWizard/pages/migrateSelectFolderPage.html",
         headerUrl: "vm-ui/resources/vm/views/migrationWizard/pages/migratePagesCustomHeader.html",
         disableNext: function() {
            return wizardScope.migrationWizardManager.folderPageModel.isValidating ||
               wizardScope.migrationWizardManager.folderPageModel.isLoading;
         },
         onCommit: () => {
            return this.onCommit(wizardScope);
         }
      };
   }

   private onCommit(wizardScope: any): boolean {
      if (!wizardScope.migrationWizardManager.hasValidFolderSelected()) {
         this.wizardPageService.markPageIncompleteWithError(
               wizardScope.wizardConfig,
               this.i18nService.getString(
                     "VmUi", "MigrationWizard.selectFolderPage.error.invalid")
         );

         return false;
      }

      wizardScope.wizardConfig.validationBanner.messages = [];
      this.wizardPageService.markPageComplete(wizardScope.wizardConfig,
            wizardScope.attributes.creationType);
      return true;
   }
} // class

angular.module("com.vmware.vsphere.client.vm").service(
      "vmMigrationSelectFolderPageService",
      VmMigrationSelectFolderPageService);

} // namespace
