/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace network_ui {

   export class EditVirtualSwitchDialogValidator {

      public static $inject = [
            "i18nService",
            "networkUiConstants",
            "networkUtil",
            "vuiConstants",
            "portFailoverPolicyPageService",
            "trafficShapingPolicyValidator"
      ];

      constructor(private i18nService: any,
                  private networkUiConstants: any,
                  private networkUtil: any,
                  private vuiConstants: any,
                  private portFailoverPolicyPageService: any,
                  private trafficShapingPolicyValidator: any) {
      }

      public getNumPortsError(dialogScope: any): any[] {
         let errors: any[] = [];
         if (dialogScope &&
               dialogScope.propertiesPageModel &&
               dialogScope.propertiesPageModel.numPorts &&
               typeof dialogScope.propertiesPageModel.availableNumPorts === "number" &&
               dialogScope.propertiesPageModel.numPorts >
               dialogScope.propertiesPageModel.availableNumPorts) {

            errors.push(this.createError(this.i18nService.getString(
                  "NetworkUi", "VSwitchPropertiesView.availablePortsExceeded",
                  dialogScope.propertiesPageModel.availableNumPorts)));
         }
         return errors;
      }

      public getMtuError(dialogScope: any): any[] {
         let errors: any[] = [];
         if (dialogScope &&
               dialogScope.propertiesPageModel &&
               !this.networkUtil.isMtuValid(dialogScope.propertiesPageModel.mtu)) {

            errors.push(this.createError(this.i18nService.getString(
                  "H5NetworkUi", "VSwitchPropertiesPage.mtu.errorMessage",
                  this.networkUiConstants.Mtu.MIN, this.networkUiConstants.Mtu.MAX)));
         }
         return errors;
      }

      public getTrafficShapingInputsError(dialogScope: any): any[] {
         let errors: any[] = [];
         let trafficShapingPolicies: string[] = ["averageBandwidth", "peakBandwidth",
            "burstSize"];
         if (dialogScope &&
               dialogScope.trafficShapingPageModel &&
               dialogScope.trafficShapingPageModel.enabled) {
            // In order not to overpopulate the method with many "IFs" we loop through
            // the trafficShapingPolicies to append the correct validation messages.
            _.forEach(trafficShapingPolicies, (policy) => {
               let value = dialogScope.trafficShapingPageModel[policy];
               let message =
                     this.trafficShapingPolicyValidator.getTrafficShapingErrorMessage(
                           value, policy);
               if (message !== null) {
                  errors.push(this.createError(message));
               }
            });

            if (errors.length === 0 &&
                  dialogScope.trafficShapingPageModel.averageBandwidth >
                  dialogScope.trafficShapingPageModel.peakBandwidth) {
               errors.push(this.createError(this.i18nService.getString(
                     "NetworkUi", "PortPolicyData.shaping.error.avgBwGreaterThanPeakBw")));
            }
         }

         return errors;
      }

      public getTeamingAndFailureInputsError(
            teamingAndFailoverPageModel: TeamingAndFailoverPageModel): any[] {
         let errors: any[] = [];
         if (teamingAndFailoverPageModel &&
                  !this.isFailureDetectionValid(teamingAndFailoverPageModel.checkBeacon,
                        teamingAndFailoverPageModel.pnics)) {
            errors.push(this.createError(this.i18nService.getString(
                  "NetworkUi", "PortPolicyData.failover.error.beaconProbingSingleNic")));
         }
         return errors;
      }

      public getIpHashWarnings(
            teamingAndFailoverPageModel: TeamingAndFailoverPageModel): any[] {
         let warnings: any[] = [];
         let hasStandbyNics = false;

         if (!teamingAndFailoverPageModel) {
            throw new Error("Invalid parameter");
         }

         //Provider returns null when there are no pnics
         if (teamingAndFailoverPageModel.pnics) {
            hasStandbyNics =
                  this.getNicLength(teamingAndFailoverPageModel.pnics.standby) > 0;
         }

         let isIpHashPolicy = teamingAndFailoverPageModel.loadBalancing ===
               this.networkUiConstants.LoadBalancingPolicy.IP;


         if (isIpHashPolicy &&
               hasStandbyNics &&
               teamingAndFailoverPageModel.checkBeacon) {

            warnings.push(this.createWarning(this.i18nService.getString("NetworkUi",
                  "PortPolicyData.failover.lb.loadbalance_ip.warn.beaconProbingAndStandbyUplinks")));

         } else if (isIpHashPolicy &&
               hasStandbyNics) {

            warnings.push(this.createWarning(this.i18nService.getString("NetworkUi",
                  "PortPolicyData.failover.lb.loadbalance_ip.warn.standbyUplinks")));
         } else if (isIpHashPolicy &&
               teamingAndFailoverPageModel.checkBeacon) {

            warnings.push(this.createWarning(this.i18nService.getString("NetworkUi",
                  "PortPolicyData.failover.lb.loadbalance_ip.warn.beaconProbing")));
         }

         return warnings;
      }

      public getTeamingAndFailoverWarnings(
            teamingAndFailoverPageModel: TeamingAndFailoverPageModel): any[] {
         let warnings: any[] = this.getIpHashWarnings(teamingAndFailoverPageModel);
         let hasActiveNics = false;

         if (!teamingAndFailoverPageModel) {
            throw "Invalid parameter.";
         }

         //Provider returns null when there are no pnics
         if (teamingAndFailoverPageModel.pnics) {
            hasActiveNics =
                  this.getNicLength(teamingAndFailoverPageModel.pnics.active) > 0;
         }

         if (!hasActiveNics) {
            warnings.push(this.createWarning(this.i18nService.getString("NetworkUi",
                  "PortPolicyData.failover.error.noActiveAdapters")));
         }

         return warnings;
      }

      public isFailureDetectionValid(failureDetection: boolean, pnics: any): boolean {
         let assignedPnics: number = 0;
         if (pnics) {
            assignedPnics = this.getNicLength(pnics.active) +
                  this.getNicLength(pnics.standby) +
                  this.getNicLength(pnics.unused);
         }

         return !(failureDetection && assignedPnics < 2);
      }

      public getLoadBalancingInfoMessages(): {iconClass: string, text: string}[] {
         return [
            this.createInfo(this.i18nService.getString("NetworkUi",
                  "PortPolicyData.failover.lb.loadbalance_ip.info.etherChannel")),
            this.createInfo(this.i18nService.getString("NetworkUi",
                  "PortPolicyData.failover.lb.loadbalance_ip.info.portGroupPolicies"))
         ];
      }

      private getNicLength(nics: string[]): number {
         let nicsLength: number = 0;
         if (nics) {
            nicsLength = nics.length;
         }

         return nicsLength;
      }

      private createError(errorText: string): any {
         return {
            type: this.vuiConstants.validationBanner.type.ERROR,
            text: errorText
         };
      }

      private createWarning(warningText: string): any {
         return {
            iconClass: "vsphere-icon-status-warning",
            text: warningText
         };
      }

      private createInfo(infoMessage: any): any {
         return {
            iconClass: "vsphere-icon-info",
            text: infoMessage
         };
      }

   }

   angular.module("com.vmware.vsphere.client.network")
         .service("editVirtualSwitchDialogValidator", EditVirtualSwitchDialogValidator);
}
