namespace h5_vm {

   import IComponentController = angular.IComponentController;
   import IComponentOptions = angular.IComponentOptions;
   import CpuMmuOptionService = h5_vm.CpuMmuOptionService;
   import CpuMmuOption = h5_vm.CpuMmuOption;

   export class CpuMmuViewController implements IComponentController {

      static $inject: string[] = [
         "cpuMmuOptionService"
      ];

      private vmConfigContext: any;
      private onCpuMmuChange: any;
      private cpuMmuOptions: Array<CpuMmuOption>;
      private selectedOption: CpuMmuOption | null;
      private createMode:any;

      constructor(private cpuMmuService: CpuMmuOptionService,) {
      }

      public $onInit(): void {
         this.cpuMmuOptions = this.cpuMmuService.getCpuMmuOptions();
         this.selectedOption = this.cpuMmuService.getCpuMmuCurrentSelection(
            this.cpuMmuService.getCpuMmuCurrentConfig(this.vmConfigContext));
      }

      private onChange = () => {
         this.onCpuMmuChange({selectedOption: this.selectedOption});
      };

      public isCpuMmuDisabled = ():boolean => {
         return this.cpuMmuService.isCpuMmuDisabled(this.vmConfigContext, this.createMode);
      }

   }

   export class CpuMmuViewComponent implements IComponentOptions {

      public controller: any;
      public templateUrl: string;
      public bindings: any;

      constructor() {
         this.controller = CpuMmuViewController;
         this.templateUrl = "vm-ui/resources/vm/views/settings/vmHardwareSettings/" +
            "vmHardwareCpu/cpuMmu/cpu-mmu-view.html";
         this.bindings = {
            vmConfigContext: "<",
            onCpuMmuChange: "&",
            createMode: "<",
            ariaLabel: "<?"

         };
      }

   }

   angular.module("com.vmware.vsphere.client.vm")
      .component("cpuMmuView", new CpuMmuViewComponent());

}
