module ds_cluster_ui {

   export interface MasterViewScope extends ScopeRoute {
      preselectComparator: Function;
      masterDetailsViewContext: any;
   }

   export class DsClusterFaultsMasterViewController {
      public watchForObjects: string[];
      public objectId: string;
      public faultMasterTitle: string;
      public masterViewGridOptions: any;
      private selectedItem: any = null;
      private liveRefreshProperties: Array<string>;

      public static $inject = ["$scope", "i18nService", "dataService",
         "drsFaultsViewService", "sdrsFaultsService", "navigation",
         "dsClusterConstants"];

      constructor(private $scope: MasterViewScope, private i18nService: any,
            private dataService: any,
            private drsFaultsViewService: any,
            private sdrsFaultsService: SdrsFaultsService,
            private navigation:any,
            private dsClusterConstants: any) {
         this.objectId = navigation.getRoute().objectId;
         this.watchForObjects = [this.objectId];
         this.liveRefreshProperties =
               [this.dsClusterConstants.liveRefreshProperties.FAULT];
         this.masterViewGridOptions =
               this.drsFaultsViewService.getMasterViewGridOptions();
         this.faultMasterTitle =
               i18nService.getString("Common", "drs.faultControl.sdrsState.headerLabel");

         $scope.$watch(() => {
                  return this.masterViewGridOptions.selectedItems;
               },
               (newvalue: any) => {
                  if (newvalue && newvalue.length > 0) {
                     $scope.masterDetailsViewContext.selectedItems = [newvalue];
                     this.selectedItem = newvalue[0];
                  }
                  else if ($scope.masterDetailsViewContext !== undefined){
                     $scope.masterDetailsViewContext.selectedItems = [];
                  }
               });

         $scope.preselectComparator = (dataGridItem: any): boolean => {
            var preselect = false;

            if (this.selectedItem) {
               preselect = dataGridItem.target.text === this.selectedItem.target.text
                     && dataGridItem.reason === this.selectedItem.reason;
            }

            return preselect;
         };

         this.getViewData();
      }

      public getViewData(): void {
         this.dataService.getProperties(this.objectId, ["computedSdrsFaults"])
               .then((faultsData: any) => {
                  this.setMasterViewGridData(faultsData);
               });
      }

      private setMasterViewGridData(faultsData: any): void {
         if (!faultsData.computedSdrsFaults) {
            return;
         }

         let data: any[] = [];

         faultsData.computedSdrsFaults.forEach((fault: any) => {
            data.push(this.sdrsFaultsService.getFaultMasterData(fault));
         });

         this.masterViewGridOptions.data = data;
      }
   }

   angular.module("com.vmware.vsphere.client.dsCluster")
         .controller("DsClusterFaultsMasterViewController",
               DsClusterFaultsMasterViewController);
}
