(function () {
   'use strict';
   angular.module('com.vmware.vsphere.client.vm')
      .component('migrateSelectNetworkAdvancedView', {
         templateUrl: 'vm-ui/resources/vm/views/migrationWizard/components/migrateSelectNetworkAdvancedView.html',
         controller: MigrateSelectNetworkAdvancedViewController,
         controllerAs: 'ctrl',
         bindings: {
            data: '<',
            migrateSelectNetworkService: '<',
            prefetchedNetworks : '<'
         }
      });

   MigrateSelectNetworkAdvancedViewController.$inject = [
      'i18nService',
      'defaultUriSchemeUtil',
      'vuiConstants',
      'NetworkSelectorMode',
      'networkSelectorDialogService',
      'NetworkPageViewModes'
   ];

   function MigrateSelectNetworkAdvancedViewController(
      i18nService,
      defaultUriSchemeUtil,
      vuiConstants,
      NetworkSelectorMode,
      networkSelectorDialogService,
      NetworkPageViewModes
   ) {

      var self = this;

      self.$onInit = function () {
         self.gridOptions = getGridOptions();
         initNetworkSelector();
      };

      function getGridOptions () {
         return {
            sortMode: vuiConstants.grid.sortMode.SINGLE,
            searchable: false,
            resizable: true,
            data: self.data,
            columnDefs: getColumnDefs(),
            encoded: false,
            selectedItems: [],
            pageConfig: {
               hidePager: true
            }
         };
      }



      function getColumnDefs() {
         return [
            {
               displayName: i18nService.getString('Common', 'vns.vm'),
               field: 'vmName',
               type: 'string',
               sortable: true
            },
            {
               displayName: i18nService.getString('Common', 'vns.networkAdapter'),
               field: 'sourceAdapterLabel',
               type: 'string',
               sortable: true
            },
            {
               displayName: i18nService.getString('Common', 'vns.basic.source'),
               field: 'sourceNetworkName',
               type: 'string',
               sortable: true
            },
            {
               displayName: i18nService.getString('Common', 'vns.basic.destination'),
               field: 'destinationNetworkName',
               sortable: false,
               template: getNetworkSelector
            }
         ];
      }

      function getNetworkSelector(data) {
         return '<div network-selector ' +
               'object-ref="$parent.$parent.ctrl.networkProviderId" '+
               'mode="$parent.$parent.ctrl.networkSelectorMode" '+
               'filter-spec="$parent.$parent.ctrl.networkSelectorFilterSpec" '+
               'dialog-spec="$parent.$parent.ctrl.networkSelectorDialogSpec" '+
               'preselected-network="$parent.$parent.ctrl.destinationNetworks['+ data.index +']" '+
               'prefetched-networks="$parent.$parent.ctrl.prefetchedNetworks" '+
               'on-selected-networks="$parent.$parent.ctrl.onSelectNetworks(selectedNetworks,' + data.index + ')" ' +
               'sort-params="$parent.$parent.ctrl.sortParams">'+
               '</div>';
      }

      function buildDestinationNetworks() {
         self.destinationNetworks = _.map(self.migrateSelectNetworkService.getAdvancedNetworkMatches(), function (networkItem) {
            return {
               id: defaultUriSchemeUtil.getVsphereObjectId(networkItem.destinationNetwork),
               name: networkItem.destinationNetworkName,
               opaqueNetworkType: networkItem.opaqueNetworkType
            };
         });
      }

      function initNetworkSelector() {
         self.networkProviderId = self.migrateSelectNetworkService.getNetworkProviderId();
         self.networkSelectorMode = NetworkSelectorMode.SINGLE_SELECTION_DROPDOWN;
         self.networkSelectorFilterSpec =
               networkSelectorDialogService.createNetworkSelectorFilterSpec(
                     true/*includeStandardNetworks*/,
                     true/*includeOpaqueNetworks*/,
                     true/*includeDistributedPortGroups*/,
                     false/*includeUplinkPortGroups*/,
                     true/*includeOpaqueNetworkTypes*/);
         self.sortParams = [{field:"name", dir:"asc"}];
         buildDestinationNetworks();

         self.networkSelectorDialogSpec =
               networkSelectorDialogService.createNetworkSelectorDialogSpec(
                     ['Network.Assign'],
                     i18nService.getString(
                           'NetworkUi',
                           'AddNetworkingWizard.conTargetPage.noDvpgPrivilegeMessage')
               );
         self.onSelectNetworks = function(selectedNetworks, rowIndex) {
            self.migrateSelectNetworkService.onSelectNetwork(selectedNetworks[0],
                  self.data[rowIndex], NetworkPageViewModes.ADVANCED);

            buildDestinationNetworks();
         };

      }
   }
}());
