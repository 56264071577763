namespace h5_vm {
   export class VmHardwareRdmVirtualDiskTypeService {

      static $inject: string[] = [
         'i18nService',
         'diskBackingInfoConstants',
         'diskProvisioningService'
      ];

      constructor(private i18nService: any,
                  private diskBackingInfoConstants: any,
                  private diskProvisioningService: any) {
      }

      isRdmDisk(deviceBacking: any) {
         return deviceBacking._type ===
            this.diskBackingInfoConstants.RAWDISKMAPPING;
      }

      getProvisioningTypeLabel(deviceBacking: any) {
         if (this.isRdmDisk(deviceBacking)) {
            return this.i18nService.getString('VmUi',
               'VmDeviceManager.RDMDisk');
         }
         return this.diskProvisioningService
            .getName(deviceBacking);
      }
   }

   angular.module('com.vmware.vsphere.client.vm')
      .service('vmHardwareRdmVirtualDiskTypeService', VmHardwareRdmVirtualDiskTypeService);
}
