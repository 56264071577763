/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace storage_ui {

   import IComponentController = angular.IComponentController;

   export class ProtocolEndpointsMasterDetailsViewController implements IComponentController {

      public static $inject = ["$q", "i18nService"];

      public readonly detailsViewData: any;

      constructor(private $q: ng.IQService, private i18nService: any) {
         this.detailsViewData = {
            tabsPromise: this.$q.when([{
               name: this.i18nService.getString(
                     "StorageUi", "storage.pe.propertiesTab.header"),
               templateUrl: "storage-ui/resources/storage/views/host/protocolEndpoints/" +
               "propertiesDetailsTab/HostProtocolEndpointProperties.html",
               uid: "storage.hostProtocolEndpoints.propertiesTab"
            }, {
               name: i18nService.getString(
                     "StorageUi", "storage.pe.pathsTab.header"),
               templateUrl: "storage-ui/resources/storage/views/host/" +
               "protocolEndpoints/paths/ProtocolEndpointsPaths.html",
               uid: "datastore.pe.pathsTab",
               metadata: {
                  relevantFor: "isScsiPE"
               }
            }, {
               name: this.i18nService.getString(
                     "StorageUi", "storage.pe.datastoresTab.header"),
               templateUrl: "storage-ui/resources/storage/views/host/" +
               "protocolEndpoints/ProtocolEndpointsDatastores.html",
               uid: "storage.protocolEndpoints.datastoresTab"
            }])
         };
      }
   }

   angular.module("com.vmware.vsphere.client.storage")
         .controller("ProtocolEndpointsMasterDetailsViewController",
               ProtocolEndpointsMasterDetailsViewController);
}
