(function() {
   'use strict';
   angular.module('com.vmware.vsphere.client.host')
         .controller('EditVmStartupShutdownController', EditVmStartupShutdownController);

   EditVmStartupShutdownController.$inject = [
      '$scope',
      'dataService',
      'editVmStartupShutdownService',
      'i18nService',
      'vmStartupShutdownService',
      'vmStartupShutdownConstants',
      '$timeout',
      'editSingleVmStartupShutdownService',
      'vuiConstants'];

   function EditVmStartupShutdownController($scope,
                                            dataService,
                                            editVmStartupShutdownService,
                                            i18nService,
                                            vmStartupShutdownService,
                                            constants,
                                            $timeout,
                                            editSingleVmStartupShutdownService,
                                            vuiConstants) {
      $scope.modalOptions.onSubmit = onSubmit;
      var self = this;
      self.loading = true;
      var hostId = $scope._route.objectId;
      self.headerOptions = {
         title: i18nService.getString('HostUi', 'vmStartUp.VmStartupAndShutdown'),
         objectId: hostId,
         actions: []
      };
      self.datagridOptions = vmStartupShutdownService.createDatagridOptions(true,
            moveUpDownAction, onEditClick);
      $scope.dialogData = {};
      initializeSelectionChangeEventWatch();
      self.vmStartupData = [];
      self.hostAutoStartConfig = null;
      self.oldSelection = null;
      self.gridWidget = null;

      $scope.preselectComparator = function(gridDataItem) {
         return self.oldSelection && gridDataItem.powerInfoKey &&
               self.oldSelection.powerInfoKey.value === gridDataItem.powerInfoKey.value;
      };

      dataService.getData(hostId, "com.vmware.vsphere.client.h5.host.model.HostAutoStartData").then(function(hostAutoStartData) {
         self.hostAutoStartConfig = hostAutoStartData.autoStartConfig;
         self.vmStartupData = hostAutoStartData.vmStartupData;
         self.hostAutoStartConfig.powerInfo = editVmStartupShutdownService.setDefaultPowerInfoForVms(
               self.vmStartupData,
               self.hostAutoStartConfig
         );
         $scope.dialogData = editVmStartupShutdownService.createDialogOptions(
               self.hostAutoStartConfig, self.vmStartupData);
         self.datagridOptions.data = vmStartupShutdownService.populateDatagrid(
               self.hostAutoStartConfig,
               self.vmStartupData
         );
         self.shutdownActionOptions = editVmStartupShutdownService.getShutdownActionOptions();
         self.loading = false;
      });

      $scope.$watch('dialogData', function(newData) {
         //remove self.loading check when clarity has
         //real load indicator which prevent
         //clicking until the page is loading.
         if (_.isEmpty(newData) || self.loading) {
            return;
         }

         self.hostAutoStartConfig.defaults = editVmStartupShutdownService.createSystemDefaultsSettings(
               $scope.dialogData);
         repopulateDataGrid();
      }, true);

      function onSubmit() {
         //remove self.loading check when clarity has real load indicator which prevent
         //clicking until the page is loading.
         if (self.loading) {
            return false;
         }

         $scope.modalOptions.alerts = [];
         var messages = editVmStartupShutdownService.validateStartStopDelay(
               $scope.dialogData.startupDelay,
               $scope.dialogData.shutdownDelay,
               null, null);

         if (messages.length > 0) {
            $scope.modalOptions.alerts = _.map(messages, function(message) {
               return {
                  text: message,
                  type: vuiConstants.validationBanner.type.ERROR
               };
            });
            return false;
         } else {
            editVmStartupShutdownService.updateHostVmStartupShutdownData(
                  hostId,
                  $scope.dialogData,
                  self.hostAutoStartConfig.powerInfo);
            return true;
         }
      }

      function initializeSelectionChangeEventWatch() {
         $scope.$watchCollection(function() {
            return self.datagridOptions.selectedItems;
         }, onSelectedItemChanged);
      }

      function onSelectedItemChanged() {
         updateServiceActionButtonStatus();
      }

      function moveUpDownAction(state) {
         if (self.datagridOptions.selectedItems.length !== 1) {
            return;
         }
         var selectedItem = self.datagridOptions.selectedItems[0];
         var selectedObjectRef = getPowerInfoForSelectedItem(selectedItem);

         if (state === constants.state.UP) {
            selectedObjectRef =
                  editVmStartupShutdownService.moveUpAction(
                        selectedObjectRef,
                        self.hostAutoStartConfig.powerInfo,
                        moveChangeOrderAction
                  );
         }

         if (state === constants.state.DOWN) {
            selectedObjectRef =
                  editVmStartupShutdownService.moveDownAction(
                        selectedObjectRef,
                        self.hostAutoStartConfig.powerInfo,
                        moveChangeOrderAction
                  );
         }

         self.oldSelection = angular.merge({}, selectedItem);

         repopulateDataGrid();
         //Waiting ui to be rendered after repopulating the datagrid
         // which is caused by moveUp/moveDown actions

         $timeout(function() {
            refreshGrid();
         }, 0, false);
      }

      function moveChangeOrderAction(index, selectedObjectStartOrder) {
         if (self.hostAutoStartConfig.powerInfo[index]) {
            self.hostAutoStartConfig.powerInfo[index].startOrder = selectedObjectStartOrder;
         }
      }

      function updateServiceActionButtonStatus() {

         var moveUpButton = self.datagridOptions.actionBarOptions.actions[0];
         var moveDownButton = self.datagridOptions.actionBarOptions.actions[1];
         var editButton = self.datagridOptions.actionBarOptions.actions[2];

         if (_.isEmpty(self.datagridOptions.selectedItems)) {
            moveUpButton.enabled = false;
            moveDownButton.enabled = false;
            editButton.enabled = false;
            return;
         }
         var selectedItem = self.datagridOptions.selectedItems[0];

         switch (selectedItem.category) {
            case constants.category.MANUAL:
               moveUpButton.enabled = true;
               moveDownButton.enabled = false;
               editButton.enabled = false;
               break;
            case constants.category.AUTOMATIC:
               moveUpButton.enabled = true;
               moveDownButton.enabled = true;
               editButton.enabled = true;
               break;
            case constants.category.AUTOMATIC_ORDERED:
               moveDownButton.enabled = true;
               editButton.enabled = true;
               if (selectedItem.startOrder === 1) {
                  moveUpButton.enabled = false;
               } else {
                  moveUpButton.enabled = true;
               }
               break;
            default:
               moveUpButton.enabled = false;
               moveDownButton.enabled = false;
               editButton.enabled = false;
               break;
         }
      }

      $scope.$on('kendoWidgetCreated', function(event, widget) {
         if (!widget || widget.constructor !== kendo.ui.Grid) {
            return;
         }

         self.gridWidget = widget;
      });

      function onEditClick() {
         if (self.datagridOptions.selectedItems.length !== 1) {
            return;
         }
         var selectedItem = self.datagridOptions.selectedItems[0];
         var powerInfo = getPowerInfoForSelectedItem(selectedItem);
         var systemDefaultPowerInfo = self.hostAutoStartConfig.defaults;
         editSingleVmStartupShutdownService.openSingleVmStartupShutdownSettingsDialog(
               selectedItem.name,
               powerInfo,
               systemDefaultPowerInfo,
               updatePowerInfo);
      }

      function updatePowerInfo(updatedPowerInfo) {
         var key = updatedPowerInfo.key.value;
         var updatedVmPowerInfoIndex = editVmStartupShutdownService.findPowerInfoIndex(
               self.hostAutoStartConfig.powerInfo,
               key);
         self.hostAutoStartConfig.powerInfo[updatedVmPowerInfoIndex] = updatedPowerInfo;
         repopulateDataGrid();
      }

      function getPowerInfoForSelectedItem(selectedItem) {
         var selectedVmPowerInfoIndex = editVmStartupShutdownService.findPowerInfoIndex(
               self.hostAutoStartConfig.powerInfo,
               selectedItem.powerInfoKey);
         return self.hostAutoStartConfig.powerInfo[selectedVmPowerInfoIndex];
      }

      function repopulateDataGrid() {
         self.datagridOptions.data = vmStartupShutdownService.populateDatagrid(
               self.hostAutoStartConfig,
               self.vmStartupData
         );
      }

      function refreshGrid() {
         if (self.gridWidget) {
            // amarinov gangelov: Sometimes the grid scroll glitches (jumps) after
            // refresh so store the position before the refresh and restore it
            // after that
            var gridScrollTop = self.gridWidget.virtualScrollable.verticalScrollbar.scrollTop();
            self.gridWidget.refresh();
            self.gridWidget.virtualScrollable.verticalScrollbar.scrollTop(gridScrollTop);

            // amarinov gangelov: In certain cases the selected item is not
            // scrolled into the view so scroll it...
            var selectedItemRow = self.gridWidget.items().filter('.k-state-selected');
            if (selectedItemRow.length === 1) {
               self.gridWidget.virtualScrollable.scrollIntoView(selectedItemRow);

               if (selectedItemRow.scrollParent) {
                  var itemTop = selectedItemRow[0].offsetTop;
                  var itemBottom = itemTop + selectedItemRow.height();
                  var viewportTop = selectedItemRow.scrollParent().scrollTop();
                  var viewportBottom = viewportTop +
                        selectedItemRow.scrollParent().height();

                  if (itemTop < viewportTop) {
                     gridScrollTop = itemTop;
                     self.gridWidget.virtualScrollable.verticalScrollbar.scrollTop(
                           gridScrollTop
                     );
                  } else if (viewportBottom < itemBottom) {
                     gridScrollTop = viewportTop + (itemBottom - viewportBottom);
                     self.gridWidget.virtualScrollable.verticalScrollbar.scrollTop(
                           gridScrollTop
                     );
                  }
               }
            }
         }
      }

      self.validateStartupDelay = function() {
         if (typeof $scope.dialogData.startupDelay === "undefined") {
            $scope.dialogData.startupDelay = 0;
         }
      };
      self.validateShutdownDelay = function() {
         if (typeof $scope.dialogData.shutdownDelay === "undefined") {
            $scope.dialogData.shutdownDelay = 0;
         }
      };
   }
})();
