(function () {
   'use strict';
   angular.module('com.vmware.vsphere.client.cluster')
      .controller('EditSwapFileLocationController', EditSwapFileLocationController);

   EditSwapFileLocationController.$inject = ['$scope', 'clusterSwapFileLocationService',
      'clusterSpecBuilderService', 'mutationService'];

   function EditSwapFileLocationController($scope, clusterSwapFileLocationService,
                                           clusterSpecBuilderService, mutationService) {
      var vm = this;

      var objectId = $scope.modalOptions.dialogData.objectId;
      vm.warning = clusterSwapFileLocationService.WARNING;
      vm.formData = vm.formData || {};

      vm.vmDirectory = clusterSwapFileLocationService.getVmDirectoryData();
      vm.hostDatastore = clusterSwapFileLocationService.getHostDatastoreData();

      $scope.modalOptions.onSubmit = onSubmit;

      getViewData();

      function getViewData() {
         setPageBusy(true);
         clusterSwapFileLocationService.getSwapFilePlacement(objectId).then(
            function(swapFile) {
               vm.formData.vmSwapPlacement = swapFile;
               setPageBusy(false);
            }
         );
      }

      function onSubmit () {
         if (vm.loading) {
            return false;
         }

         var clusterSpec = clusterSpecBuilderService.buildClusterComputeResourceSpec(
            vm.formData, true);
         mutationService
               .apply(objectId,
                     'com.vmware.vsphere.client.cluster.ClusterComputeResourceSpec',
            clusterSpec);

         return true;
      }

      function setPageBusy(isBusy) {
         $scope.modalOptions.submitDisabled = isBusy;
         vm.loading = isBusy;
      }
   }
})();
