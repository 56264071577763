/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace dvs_ui {

   export class DvsPortEditPropertiesPageValidator {

      public static $inject = [
         "i18nService",
         "networkUtil"
      ];

      constructor(private i18nService: any,
                  private networkUtil: any) {
      }

      public getErrors(propetriesData: DvsPortEditPropertiesPageModel): string[] {
         let errors: string[] = [];

         if (!propetriesData) {
            return errors;
         }

         let nameError: string = this.getNameError(propetriesData.name);
         let descriptionError: string =
               this.getDescriptionError(propetriesData.description);

         if (nameError) {
            errors.push(nameError);
         }

         if (descriptionError) {
            errors.push(descriptionError);
         }

         return errors;
      }

      public getNameError(portName: string): string {
         if (portName && this.networkUtil.getStringLengthInUtf8Bytes(portName) >
               DvsPortEditDialogConstants.NAME_MAX_BYTE_LENGTH) {
            return this.i18nService.getString(
                  "DvsUi", "portEdit.propertiesPage.name.error");
         }

         return "";
      }

      public getDescriptionError(portDescription: string): string {
         if (portDescription && portDescription.length >
               DvsPortEditDialogConstants.DESCRIPTION_MAX_CHAR_LENGTH) {
            return this.i18nService.getString(
                  "DvsUi", "portEdit.propertiesPage.description.error");
         }

         return "";
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .service("dvsPortEditPropertiesPageValidator", DvsPortEditPropertiesPageValidator);
}