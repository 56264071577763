namespace storage_ui {

   import IRootScopeService = angular.IRootScopeService;

   export class StorageDeviceMultipathingPolicyDialogService {

      public static $inject = ["$rootScope", "i18nService",
         "clarityModalService", "clarityConstants", "storageUtil", "mutationService"];

      constructor(private $rootScope: IRootScopeService,
                  private i18nService: any,
                  private clarityModalService: any,
                  private clarityConstants: any,
                  private storageUtil: any,
                  private mutationService: any) {
      }

      /**
       * Displays the StorageDeviceMultipathingPolicyDialog
       */
      public show(hostId: string, deviceId: string, name: string) {

         const self: StorageDeviceMultipathingPolicyDialogService = this;

         let modalOptions: any = {
            title: self.i18nService.getString(
                  "StorageUi", "device.editMultipathingPolicyFormH5.title"),
            subTitle: {
               text: name
            },
            defaultButton: "submit",
            size: "lg",
            alerts: [],
            contentTemplate: "storage-ui/resources/storage/views/host/devices/StorageDeviceMultipathingPolicyDialog.html",
            onSubmit: onSubmit
         };

         modalOptions.dialogData = {
            hostId: hostId,
            deviceId: deviceId,
            errorApi: {
               showError: showError,
               clearErrors: clearErrors
            }
         };

         self.clarityModalService.openOkCancelModal(modalOptions);

         function onSubmit(): boolean {

            let selectedPolicyKey: string = modalOptions.dialogData.selectedPolicy &&
                  modalOptions.dialogData.selectedPolicy.id;
            let logicalUnitId: string = modalOptions.dialogData.logicalUnitId;
            let selectedPath: any = modalOptions.dialogData.selectedPath;

            if (selectedPolicyKey && logicalUnitId) {

               let multipathLunPolicySpec: any = {
                  _type: "com.vmware.vsphere.client.storage.MultipathLunPolicySpec",
                  logicalUnitId: logicalUnitId
               };

               if (self.storageUtil.isFixedPolicy(selectedPolicyKey)) {

                  if (!selectedPath) {
                     showError(self.i18nService.getString("StorageUi", "device.editMultipathingPolicy.noPreferredPath"));
                     return false;
                  }

                  multipathLunPolicySpec.policy = {
                     _type: "com.vmware.vim.binding.vim.host.MultipathInfo$FixedLogicalUnitPolicy",
                     policy: selectedPolicyKey,
                     prefer: selectedPath.name
                  };
               } else {
                  multipathLunPolicySpec.policy = {
                     _type: "com.vmware.vim.binding.vim.host.MultipathInfo$LogicalUnitPolicy",
                     policy: selectedPolicyKey
                  };
               }
               self.mutationService.apply(hostId,
                     multipathLunPolicySpec._type,
                     multipathLunPolicySpec,
                     self.i18nService.getString("StorageUi", "device.editMultipathingPolicy.taskTitle"));
            }

            return true;
         }

         function showError(errorMessage: string) {
            modalOptions.alerts = [{
               text: errorMessage,
               type: self.clarityConstants.notifications.type.ERROR
            }];
         }

         function clearErrors() {
            modalOptions.alerts = [];
         }

      }
   }

   angular.module("com.vmware.vsphere.client.storage")
         .service("storageDeviceMultipathingPolicyDialogService", StorageDeviceMultipathingPolicyDialogService);
}
