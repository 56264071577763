/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace dvs_ui {

   import IPromise = angular.IPromise;

   export class DvsPortTabsDetailsComponent {

      public controller: any;
      public bindings: any;
      public templateUrl: string;

      constructor() {
         this.bindings = {
            selectedPort: "<",
            isPortGroupContext: "<",
            isPortTrafficFilterSupported: "<",
            portContextId: "<"
         };
         this.controller = DvsPortTabsDetailsController;
         this.templateUrl =
               "dvs-ui/resources/dvs/ports/dvsPortsDetailsViewTemplate.html";
      }
   }

   class DvsPortTabsDetailsController {

      static $inject = [
         "$scope",
         "i18nService"
      ];

      public i18n: Function;

      public selectedPort: any;

      public isPortGroupContext: boolean;

      public portContextId: string;

      public isPortTrafficFilterSupported: boolean;

      constructor(private $scope: any,
                  private i18nService: any) {

         this.i18n = this.i18nService.getString;
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .component("dvsPortTabsDetailsView", new DvsPortTabsDetailsComponent());
}
