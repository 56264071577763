/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace dvs_ui {

   import DvsSystemTrafficOverviewData =
         com.vmware.vsphere.client.h5.network.dvs.resmgmt.systemtraffic.model.DvsSystemTrafficOverviewData;

   export class DvsSystemTrafficOverviewComponent {

      public bindings: any;
      public controller: any;
      public template: string;

      constructor() {
         this.bindings = {
            data: "<"
         };

         this.controller = DvsSystemTrafficOverviewComponentController;

         this.template =
               `<vx-property-view data="$ctrl.propertyViewData"></vx-property-view>`;
      }
   }

   class DvsSystemTrafficOverviewComponentController {
      static $inject = [
         "i18nService",
         "vxPropertyViewService",
         "numberFormatterService"
      ];

      public data: DvsSystemTrafficOverviewData;

      public propertyViewData: any;

      constructor(private i18nService: any,
                  private vxPropertyViewService: any,
                  private numberFormatterService: any) {
      }

      public $onChanges(): void {
         if (this.data) {
            this.buildPropertyViewData(this.data);
         }
      }

      private buildPropertyViewData(data: DvsSystemTrafficOverviewData): void {
         let builder: any = this.vxPropertyViewService.createPropertyViewBuilder();

         let generalCategory: any = builder.category("general");
         let generalSection: any = generalCategory.section("generalSection");

         const NO_DATA: string =
               this.i18nService.getString("DvsUi", "systemTrafficList.noData");

         let niocEnabled: string = data.niocEnabled
               ? this.i18nService.getString("DvsUi", "systemTrafficView.summary.niocStatus.enabled")
               : this.i18nService.getString("DvsUi", "systemTrafficView.summary.niocStatus.disabled");
         generalSection.property(
               this.i18nService.getString(
                     "DvsUi",
                     "systemTrafficView.summary.resourceManagementStatus"),
               niocEnabled || NO_DATA);

         generalSection.property(
               this.i18nService.getString(
                     "DvsUi",
                     "systemTrafficView.summary.resourceManagementVersion"),
               data.niocVersion || NO_DATA);

         let numPnics: string = (data.excludedPnics && data.excludedPnics > 0)
               ? this.i18nService.getString("DvsUi",
                     "systemTrafficView.summary.totalPhysicalNicCountWithExclusion",
                     data.numPnics,
                     data.excludedPnics)
               : data.numPnics;
         generalSection.property(
               this.i18nService.getString(
                     "DvsUi",
                     "systemTrafficView.summary.totalPhysicalNicCount"),
               numPnics || NO_DATA);

         let minLinkSpeed: string = (data.minPnicSpeed && data.minPnicSpeed > 0)
               ? this.numberFormatterService.formatBandwidth(
                     data.minPnicSpeed, BandwidthUnit.MBITPS, BandwidthUnit.AUTO)
               : NO_DATA;
         generalSection.property(
               this.i18nService.getString(
                     "DvsUi",
                     "systemTrafficView.summary.minimumLinkSpeed"),
               minLinkSpeed);

         this.propertyViewData = builder.build();
      }
   }

   // in sync with format.ts in ng-next-app
   enum BandwidthUnit { BITPS, KBITPS, MBITPS, GBITPS, TBITPS, AUTO }

   angular.module("com.vmware.vsphere.client.dvs")
         .component("dvsSystemTrafficOverview", new DvsSystemTrafficOverviewComponent());
}