angular.module('com.vmware.vsphere.client.vm').service('virtualDiskInflator', [
   'VirtualDisk',
   'storageProfileService',
   function(
      VirtualDisk,
      storageProfileService
   ) {
      return {
         build: function(rawDevice, storageProfiles, storageProfileAssignments) {
            var profile;
            if (storageProfileAssignments && storageProfiles) {
               profile = storageProfileService.makeProfileFromDevice(rawDevice, storageProfiles, storageProfileAssignments);
            }
            return new VirtualDisk(rawDevice, profile);
         }
      };
   }]);
