(function() {
   'use strict';
   angular.module('com.vmware.vsphere.client.host').service('editSingleVmStartupShutdownService',
         EditSingleVmStartupShutdownService);

   EditSingleVmStartupShutdownService.$inject = ['vmStartupShutdownConstants',
      'i18nService', 'clarityModalService', '$rootScope', 'editVmStartupShutdownService','clarityConstants'];

   function EditSingleVmStartupShutdownService(constants, i18nService,
                                               clarityModalService, $rootScope,
                                               editVmStartupShutdownService, clarityConstants) {

      function openSingleVmStartupShutdownSettingsDialog(vmName,
                                                         vmPowerInfo,
                                                         systemDefaultPowerInfo,
                                                         updatePowerInfo) {
         var modalOptions = {
            title: i18nService.getString('HostUi', 'editSingleVmStartupShutdownSettingsDialog.title'),
            subTitle: {
               text: vmName
            },
            contentTemplate: 'host-ui/resources/host/views/settings/vmStartupShutdown/editSingleVmStartupShutdownSettingsDialog.html',
            defaultButton: 'submit',
            alerts: [],
            dialogData: {
               vmPowerInfoSettings: initPowerInfoSettings(vmPowerInfo, systemDefaultPowerInfo)
            },
            onSubmit: function() {
               var powerInfoData = readPowerInfoData(modalOptions.dialogData.vmPowerInfoSettings);
               var messages = editVmStartupShutdownService.validateStartStopDelay(
                     powerInfoData.startDelay,powerInfoData.stopDelay,
                     modalOptions.dialogData.vmPowerInfoSettings.startupSettings.startupType,
                     modalOptions.dialogData.vmPowerInfoSettings.shutdownSettings.shutdownType);
               if (messages.length > 0) {
                  modalOptions.alerts = _.map(messages, function(message){
                     return {text: message,
                        type: clarityConstants.notifications.type.ERROR
                     };
                  });
                  return false;
               }
               vmPowerInfo =_.extend(vmPowerInfo, powerInfoData);
               updatePowerInfo(vmPowerInfo);
               return true;
            }
         };

         clarityModalService.openOkCancelModal(modalOptions);
      }

      function initPowerInfoSettings(powerInfo, systemDefaultPowerInfo) {

         var vmPowerInfoSettings = {
            startupSettings: {
               startupType: getDelayType(powerInfo.startDelay),
               startDelay: getDelayValue(powerInfo.startDelay,
                     systemDefaultPowerInfo.startDelay),
               heartbeat: getHeartBeat(powerInfo.waitForHeartbeat, systemDefaultPowerInfo)
            },

            shutdownSettings: {
               shutdownType: getDelayType(powerInfo.stopDelay),
               stopDelay: getDelayValue(powerInfo.stopDelay, systemDefaultPowerInfo.stopDelay),
               stopAction: getStopAction(powerInfo.stopAction, systemDefaultPowerInfo)
            }
         };
         return vmPowerInfoSettings;
      }

      function getStopAction(action, systemDefault) {
         if (action === constants.SYSTEM_DEFAULT) {
            return getStopAction(systemDefault.stopAction);
         } else {
            // User specified
            return _.find(editVmStartupShutdownService.getShutdownActionOptions(), function(item) {
               return item.action === action;
            });
         }
      }

      function getDelayValue(delay, systemDefault) {
         if (delay === constants.SYSTEM_DEFAULT_DELAY) {
            return systemDefault;
         } else {
            // User specified
            return delay;
         }
      }

      function getHeartBeat(heartbeat, systemDefault) {
         if (heartbeat === constants.heartbeat.YES) {
            return true;
         } else if (heartbeat === constants.heartbeat.NO) {
            return false;
         } else if (heartbeat === constants.heartbeat.SYSTEM_DEFAULT) {
            return systemDefault.waitForHeartbeat;
         }
      }

      function getDelayType(delay) {
         if (delay === constants.SYSTEM_DEFAULT_DELAY) {
            // Use Dafulat
            return constants.SYSTEM_DEFAULT;
         } else {
            // User specified
            return constants.USER_DEFINED;
         }
      }

      function readPowerInfoData(vmPowerInfoSettings) {
         var powerInfo = {};
         if (vmPowerInfoSettings.startupSettings.startupType === constants.SYSTEM_DEFAULT) {
            powerInfo.startDelay = -1;
            powerInfo.waitForHeartbeat = constants.heartbeat.SYSTEM_DEFAULT;
         } else {
            powerInfo.startDelay = vmPowerInfoSettings.startupSettings.startDelay;
            if (vmPowerInfoSettings.startupSettings.heartbeat) {
               powerInfo.waitForHeartbeat = constants.heartbeat.YES;
            } else {
               powerInfo.waitForHeartbeat = constants.heartbeat.NO;
            }

         }

         if (vmPowerInfoSettings.shutdownSettings.shutdownType === constants.SYSTEM_DEFAULT) {
            powerInfo.stopDelay = -1;
            powerInfo.stopAction = constants.SYSTEM_DEFAULT;
         } else {
            powerInfo.stopDelay = vmPowerInfoSettings.shutdownSettings.stopDelay;
            powerInfo.stopAction = vmPowerInfoSettings.shutdownSettings.stopAction.action;
         }
         return powerInfo;
      }

      return {
         openSingleVmStartupShutdownSettingsDialog: openSingleVmStartupShutdownSettingsDialog
      };
   }
})();
