module vm_ui {
   export class ExistingHardDiskSelectorDialogService {
      static $inject: string[] = ['clarityModalService', 'i18nService',
         'defaultUriSchemeUtil', 'datastoreBrowserConstants'];

      private modalOptions: any;
      private selectedFile: any;
      private okHandler: any;

      constructor(private clarityModalService: any,
                  private i18nService: any,
                  private defaultUriSchemeUtil: any,
                  private datastoreBrowserConstants: any) {
      }

      public openExistingDiskSelectorDialog(vmConfigEnvironment: any ,okHandler: Function) {

         const dsBrowserId = this.defaultUriSchemeUtil.getVsphereObjectId(
               vmConfigEnvironment.datastoreBrowser);
         const vmDatastores = vmConfigEnvironment.configTarget.datastore;
         const datastoreIds = _.map(vmDatastores, (vmDatastore: any) => {
            return this.defaultUriSchemeUtil.getVsphereObjectId(vmDatastore.datastore.datastore);
         });
         const initialFileQueryType = this.datastoreBrowserConstants.fileQueryType.VM_DISK_TYPE_WITH_DETAILS;
         const filterQueryTypes = [{
            label: this.i18nService.getString('Common', 'dsBrowser.vmDiskFile'),
            value: this.datastoreBrowserConstants.fileQueryType.VM_DISK_TYPE_WITH_DETAILS
         }];
         const dialogData = {
            datastoreBrowserId: dsBrowserId,
            datastoreIds: datastoreIds,
            initialFileQueryType: initialFileQueryType,
            filterFileQueryTypes: filterQueryTypes,
            showVmImagesFolder: true,
            selectedFileChanged: (selectedFile: any) => {
               this.selectedFile = selectedFile;
               this.modalOptions.submitDisabled = !this.selectedFile;
            }
         };

         this.okHandler = okHandler;
         this.modalOptions = {
            title: this.i18nService.getString("Common", "dsBrowser.defaultTitle"),
            contentTemplate: 'vm-ui/resources/vm/views/settings/vmHardwareSettings/' +
               'vmHardwareExistingDisk/select-existing-hard-disk.view.html',
            defaultButton: 'submit',
            dialogData: dialogData,
            size: 'lg',
            modalClass: '',
            hideSubmitButton: false,
            submitDisabled: true,
            selectedItems: [],
            alerts: [],
            onSubmit: ()=> {
               if (this.selectedFile === null) {
                  return false;
               }

               if (this.okHandler && typeof this.okHandler === "function") {
                  this.okHandler(this.selectedFile);
               }
               return true;
            }
         };

         this.clarityModalService.openOkCancelModal(this.modalOptions);
      }
   }

   angular.module("com.vmware.vsphere.client.vm")
         .service("existingHardDiskSelectorDialogService", ExistingHardDiskSelectorDialogService);
}
