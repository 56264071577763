module common_module_ui {

    export class UnitFormatterService {

        private readonly UNIT_STEP: number = 1024;

        public constructor() { }

        /**
         * Convert the given size (in Kilobytes) to Megabytes
         *
         * @param size
         *          Size in Kilobytes
         @param round (Optional)
         *          Decimal places
         * @returns {number}
         *          Size in Megabytes
         */
        public toMegabytes(size: number, round?: number): number {
            let convertedSize: number = size / this.UNIT_STEP;
            return round ? this.roundNumber(convertedSize, round) : convertedSize;
        }

        /**
         * Convert the given size in Megabytes to Kilobytes
         *
         * @param size
         *          Size in Megabytes
         * @returns {number}
         *          Size in Kilobytes
         */
        public fromMegabytes(size: number): number {
            return size * this.UNIT_STEP;
        }

        /**
         * Convert the given size (in Kilobytes) to Gigabytes
         *
         * @param size
         *          Size in Kilobytes
         * @param round (Optional)
         *          Decimal places
         * @returns {number}
         *          Size in Gigabytes
         */
        public toGigabytes(size: number, round?: number): number {
            let convertedSize: number = this.toMegabytes(size) / this.UNIT_STEP;
            return round ? this.roundNumber(convertedSize, round) : convertedSize;
        }

        /**
         * Round the given number to the decimals provided
         * E.g.: roundNumber(17.1234, 2) => 17.12
         *
         * @param value
         * @param decimals
         * @returns {number}
         */
        public roundNumber(value: number, decimals: number) {
            return Number(`${ Math.round(Number(`${value}e${decimals}`)) }e-${ decimals }`);
        }
    }

    angular.module("com.vmware.vsphere.client.commonModule")
            .service("unitFormatterService", UnitFormatterService);

}
