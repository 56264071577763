/* Copyright 2015 VMware, Inc. All rights reserved. -- VMware Confidential */
/*
 * This js file has to be loaded first
 */
h5 = h5 || {};
h5.network = {
   angularModule: angular.module('com.vmware.vsphere.client.network',
         ['com.vmware.platform.ui', 'com.vmware.vsphere.client.networkLibUi'])
};

/*
 * Registers network-ui actions.
 */
angular.module('com.vmware.vsphere.client.network')
      .run([
         'mutationService',
         'addHostNetworkingWizardService',
         'i18nService',
         'editPortGroupDialogService',
         'clarityModalService',
         'clarityConstants',
         'networkUiConstants',
         'editVmkernelAdapterDialogService',
         'manageVssPnicsDialogService',
         'iscsiPortBindingService',
         'logService',
         'iscsiPortBindingDetailsDialogService',
         'netStackEditDialogService',
         'editVirtualSwitchDialogService',
         'vmMigrateWizardService',
         'editPnicDialogService',
         'manageProxySwitchPnicsDialogService',
         'migrateVmkToVssWizardService',
         'ghostDvsProxySwitchDetailsService',
         'moveNetworkToService',
         'removeVnicService',
         '$injector',
         'proxySwitchDetailsDialogService',

         function (mutationService,
               addHostNetworkingWizardService,
               i18nService,
               editPortGroupDialogService,
               clarityModalService,
               clarityConstants,
               networkUiConstants,
               editVmkernelAdapterDialogService,
               manageVssPnicsDialogService,
               iscsiPortBindingService,
               logService,
               iscsiPortBindingDetailsDialogService,
               netStackEditDialogService,
               editVirtualSwitchDialogService,
               vmMigrateWizardService,
               editPnicDialogService,
               manageProxySwitchPnicsDialogService,
               migrateVmkToVssWizardService,
               ghostDvsProxySwitchDetailsService,
               moveNetworkToService,
               removeVnicService,
               $injector,
               proxySwitchDetailsDialogService) {

            h5.actions[networkUiConstants.actions.EDIT_DVS_NOTES] = function (actionEval, availableTargets) {
               var onUpdateDvsNotes = function (objectId, notesValue, operationName, loadedConfigVersion) {
                  var reconfigSpec = {
                     _type: 'com.vmware.vim.binding.vim.dvs.VmwareDistributedVirtualSwitch$ConfigSpec',
                     description: notesValue,
                     configVersion: loadedConfigVersion
                  };
                  var specType = 'com.vmware.vsphere.client.dvs.api.spec.DvsConfigSpec';
                  var propSpec = {
                     configSpec: reconfigSpec
                  };
                  mutationService.apply(objectId, specType, propSpec, operationName);
                  return true;
               };

               var dialogData = {
                  notesPropName: 'config.description',
                  additionalPropForEdit: 'config.configVersion',
                  onUpdateNotesCallback: onUpdateDvsNotes
               };

               clarityModalService.openModal(actionEval, availableTargets, dialogData,
                     'common-module-ui/resources/common-module/views/notes/NotesDialog.html');
            };

            h5.actions[networkUiConstants.actions.EDIT_DVPG_NOTES] = function (actionEval, availableTargets) {
               var onUpdateDvpgNotes = function (objectId, notesValue, operationName, loadedConfigVersion) {
                  var type = 'com.vmware.vim.binding.vim.dvs.DistributedVirtualPortgroup$ConfigSpec';
                  var reconfigSpec = {
                     _type: type,
                     description: notesValue,
                     configVersion: loadedConfigVersion
                  };

                  mutationService.apply(objectId, type, reconfigSpec);
                  return true;
               };

               var dialogData = {
                  notesPropName: 'config.description',
                  additionalPropForEdit: 'config.configVersion',
                  onUpdateNotesCallback: onUpdateDvpgNotes
               };

               clarityModalService.openModal(actionEval, availableTargets, dialogData,
                     'common-module-ui/resources/common-module/views/notes/NotesDialog.html');
            };

            h5.actions[networkUiConstants.actions.ADD_NETWORKING] =
                  function (actionEval, availableTargets, context) {
                     context = context || {};
                     addHostNetworkingWizardService
                           .showWizard(availableTargets[0], context.selectedSwitch,
                                 context.connectionType);
                  };

            h5.actions[networkUiConstants.actions.EDIT_VSWITCH] =
                  function (actionEval, availableTargets, context) {
                     context = context || {};
                     editVirtualSwitchDialogService.show(availableTargets[0], context.selectedSwitch, actionEval);
                  };

            h5.actions[networkUiConstants.actions.REMOVE_VSWITCH] =
               function(actionEval, availableTargets, context) {
                  var REMOVE_SPEC_TYPE = 'com.vmware.vsphere.client.h5.network.host.virtualswitch.spec.RemoveVirtualSwitchSpec';
                  var removeSpec = {
                     _type: REMOVE_SPEC_TYPE,
                     vSwitchName: context.selectedSwitch.name
                  };
                  var modalOptions = {
                     preserveNewlines: true,
                     submitDisabled: true,
                     title: i18nService.getString('NetworkUi',
                        'removeVirtualSwitch.dialogCaption')
                  };

                  clarityModalService.openConfirmationModal(modalOptions);
                  mutationService.validate(availableTargets[0],
                     REMOVE_SPEC_TYPE,
                     removeSpec).then(function(validationResult) {
                     modalOptions.submitDisabled = false;
                     if (!validationResult.result) {
                        _.extend(modalOptions, {
                           message: i18nService.getString('NetworkUi',
                                 'removeVirtualSwitch.confirmWarning',
                                 context.selectedSwitch.name),
                           saveButtonLabel: i18nService.getString('NetworkUi',
                                 'NetworkUtil.labelYes'),
                           cancelButtonLabel: i18nService.getString('NetworkUi',
                                 'NetworkUtil.labelNo'),
                           clarityIcon: {
                              class: "is-warning",
                              shape: "warning-standard",
                              size: "32"
                           },
                           submit: function() {
                              mutationService.apply(
                                    availableTargets[0],
                                    REMOVE_SPEC_TYPE,
                                    removeSpec);
                           }
                        });
                     } else {
                        _.extend(modalOptions, {
                           title: i18nService.getString('NetworkUi',
                                 'removeVirtualSwitch.messageDialogCaption'),
                           message: validationResult.result,
                           clarityIcon: {
                              class: "is-error",
                              shape: "error-standard",
                              size: "32"
                           },
                           hideCancelButton: true,
                           submit: function() {
                              return true;
                           }
                        });
                     }
                  });
               };

            h5.actions[networkUiConstants.actions.EDIT_VNIC] =
                  function (actionEval, availableTargets, context) {
                     editVmkernelAdapterDialogService
                           .show(actionEval, availableTargets[0], context.vnic);
                  };

            h5.actions[networkUiConstants.actions.MANAGE_PNICS] =
                  function (actionEval, availableTargets, context) {

                     if(context.selectedSwitch.type === networkUiConstants.switchType.STANDARD) {
                        manageVssPnicsDialogService
                           .show(availableTargets[0], context.selectedSwitch.name);
                     } else {
                           manageProxySwitchPnicsDialogService
                           .show(availableTargets[0],
                              context.selectedSwitch.dvsRef,
                              context.selectedSwitch.key,
                              context.selectedSwitch.name);
                     }

                  };

            h5.actions[networkUiConstants.actions.MIGRATE_VMK_TO_VSWITCH] =
                  function (actionEval, availableTargets, context) {
                     context = context || {};
                     migrateVmkToVssWizardService
                           .show(availableTargets[0], context.selectedSwitch.name);
                  };

            h5.actions[networkUiConstants.actions.REFRESH_NETWORK] =
               refreshNetwork;

            function refreshNetwork(actionEval, availableTargets) {
               if (availableTargets && availableTargets.length === 1) {
                  var REFRESH_SPEC_TYPE =
                     'com.vmware.vsphere.client.network.NetworkSystemRefreshSpec';
                  var refreshSpec = {
                     _type: REFRESH_SPEC_TYPE
                  };

                  mutationService.apply(
                     availableTargets[0],
                     REFRESH_SPEC_TYPE,
                     refreshSpec,
                     i18nService.getString('NetworkUi',
                        'removeVirtualSwitch.refreshNetworkSystem.operationName'));
               }
            }

            h5.actions[networkUiConstants.actions.REMOVE_VNIC] =
                  function(actionEval, availableTargets, context) {
                     removeVnicService.openConfirmationDialog(
                           availableTargets[0], context.vnic);
                  };

            h5.actions[networkUiConstants.actions.EDIT_PORTGROUP] =
                  function (actionEval, availableTargets, context) {
                     editPortGroupDialogService.show(context.hostId, actionEval,
                           context.portgroupKey, context.portgroupName);
                  };

            h5.actions[networkUiConstants.actions.REMOVE_PORTGROUP] =
                  function (actionEval, availableTargets, context) {
                     var modalOptions = {
                        title: i18nService.getString('NetworkUi',
                           'removePortgroup.dialogCaption'),
                        submitDisabled: true,
                        preserveNewlines: true
                     };

                     clarityModalService.openConfirmationModal(modalOptions);
                     mutationService.validate(availableTargets[0],
                           'com.vmware.vsphere.client.h5.network.host.portgroup.model.PortGroupRemoveSpec',
                           context.spec).then(function (validationResult) {
                        modalOptions.submitDisabled = false;
                        if (!validationResult.result) {
                           _.extend(modalOptions, {
                              message: i18nService.getString('NetworkUi',
                                    'removePortgroup.confirmWarning', context.spec.portgroupName),
                              saveButtonLabel: i18nService.getString('NetworkUi',
                                    'NetworkUtil.labelYes'),
                              cancelButtonLabel: i18nService.getString('NetworkUi',
                                    'NetworkUtil.labelNo'),
                              clarityIcon: {
                                 class: "is-warning",
                                 shape: "warning-standard",
                                 size: "32"
                              },
                              submit: function() {
                                 mutationService.apply(
                                       availableTargets[0],
                                       'com.vmware.vsphere.client.h5.network.host.portgroup.model.PortGroupRemoveSpec',
                                       context.spec);
                              }
                           });
                        } else {
                           _.extend(modalOptions, {
                              title: i18nService.getString(
                                    'NetworkUi', 'removePortgroup.messageDialogCaption'),
                              message: validationResult.result,
                              clarityIcon: {
                                 class: "is-error",
                                 shape: "error-standard",
                                 size: "32"
                              },
                              hideCancelButton: true,
                              submit: function() {
                                 return true;
                              }
                           });
                        }
                     });
                  };

            h5.actions[networkUiConstants.actions.SHOW_ISCSI_PORT_BINDING_DETAILS] =
                  function(actionEval, availableTargets, context) {
                     iscsiPortBindingDetailsDialogService.show(availableTargets[0], context.iscsiPortInfoItem);
                  };

            h5.actions[networkUiConstants.actions.ADD_PORT_BINDING_FOR_ISCSI] =
                  function(actionEval, availableTargets, context) {

                     var log = logService('addPortBindingForIscsi');
                     if (!context.iscsiHbaName) {
                        log.error('No iSCSI adapter selected.');
                     }
                     var onSubmitWrapper = {};
                     var modalOptions = {
                        contentTemplate: 'network-ui/resources/network/views/host/iscsi/add/IscsiPortBindingDialog.html',
                        title: i18nService.getString('H5NetworkUi', 'portbinding.addDialog.title', context.iscsiHbaName),
                        defaultButton: 'submit',
                        alerts: [],
                        size: 'lg',
                        dialogData: {
                           portBindingData: {
                              hostId: availableTargets[0],
                              iscsiHbaName: context.iscsiHbaName,
                              onSubmitWrapper: onSubmitWrapper
                           }
                        },
                        subTitle: {
                           objectId: availableTargets[0]
                        },
                        // The onSubmitWrapper's submit function is bound to the object in the component,
                        // used in the dialog, in order for the the mutation call to be implemented
                        // in the component's controller.
                        // In other words, the component exposes its public API via this wrapper.
                        onSubmit: function() {
                           return onSubmitWrapper.submit(context.onSuccessCallback).then(function(result) {
                              if (result.errors && result.errors.length) {
                                 modalOptions.alerts = _.map(result.errors, function(err) {
                                    return {
                                       type: clarityConstants.notifications.type.ERROR,
                                       text: err
                                    };
                                 });
                              }
                              return result.isSuccessful;
                           });
                        }
                     };
                     clarityModalService.openOkCancelModal(modalOptions);
                  };

            h5.actions[networkUiConstants.actions.REMOVE_PORT_BINDING_FROM_ISCSI] =
                  function(actionEval, availableTargets, context) {

                     var log = logService('removePortBindingFromIscsi');
                     if (!context.iscsiHbaName) {
                        log.error('No iSCSI adapter selected.');
                     }

                     var removeMessage = iscsiPortBindingService
                           .getPortBindingRemovalUserMessages(context.selectedAdapters, context.adapters);

                     var modalOptions = {
                       title: removeMessage.title,
                       message: removeMessage.description,
                       submit: function() {
                          iscsiPortBindingService.removeIscsiPortInfos(
                              availableTargets[0],
                              context.iscsiHbaName,
                              context.selectedAdapters)
                                    .then(function(result) {
                                       if (context.onSuccessCallback) {
                                          context.onSuccessCallback(result);
                                       }
                                    });

                          return true;
                       }
                     };

                     clarityModalService.openConfirmationModal(modalOptions);
                  };

            h5.actions[networkUiConstants.actions.EDIT_DNS_AND_ROUTING] =
                  function(actionEval, availableTargets, context) {
                     netStackEditDialogService.show(availableTargets[0],
                           context.selectedNetStack.key,
                           context.selectedNetStack.name,
                           context.multipleNetworkStackInstanceSupported);
                  };

            h5.actions[networkUiConstants.actions.EDIT_IPV6_SUPPORT] =
               function(actionEval, availableTargets, context) {

                  var onSubmitWrapper = {};
                  var modalOptions = {
                     contentTemplate: 'network-ui/resources/network/views/host/editIpv6SupportView.html',
                     title: i18nService.getString('NetworkUi',
                        'IPv6SupportView.ipv6SupportedDialogTitle'),
                     defaultButton: 'submit',
                     alerts: [],
                     size: 'md',
                     dialogData: {
                        hostId: availableTargets[0],
                        onSubmitWrapper: onSubmitWrapper
                     },
                     subTitle: {
                        objectId: availableTargets[0]
                     },
                     onSubmit: function() {
                        return onSubmitWrapper.submit(context.onSuccessCallback);
                     }
                  };
                  clarityModalService.openOkCancelModal(modalOptions);
               };

            h5.actions[networkUiConstants.actions.VM_MIGRATE] =
                  function (actionEval, availableTargets, context) {
                     vmMigrateWizardService.show(availableTargets[0]);
                  };

            h5.actions[networkUiConstants.actions.EDIT_PNIC] =
                  function (actionEval, availableTargets, context) {
                     editPnicDialogService.show(availableTargets[0], context.pnic);
                  };

            h5.actions[networkUiConstants.actions.GHOST_DVS_PROXY_SWITCH_DETACHED] =
                  function (actionEval, availableTargets, context) {
                     if(!availableTargets || !availableTargets.length
                        || !context || !context.details) {
                        return;
                     }

                     ghostDvsProxySwitchDetailsService
                        .show(availableTargets[0],
                           context.details.switchUuid);
                  };

            h5.actions[networkUiConstants.actions.NETWORK_MOVE_TO_ACTION] =
               function (actionEval, availableTargets, context) {
                  moveNetworkToService.openMoveToDialog(availableTargets);
               };

            h5.actions[networkUiConstants.actions.NETWORK_MOVE_TO_DRAG_AND_DROP_ACTION] =
               function (actionEval, availableTargets, destinationTarget) {
                  if (!availableTargets || !availableTargets.length || !destinationTarget) {
                     return;
                  }

                  moveNetworkToService.moveNetworkTo(availableTargets, destinationTarget.dropTarget);
               };

            h5.actions[networkUiConstants.actions.VSS_VIEW_SETTINGS] =
               function (actionEval, availableTargets, context) {
                  $injector.get("virtualSwitchDetailsDialogService").showVssDetailsDialog(
                     context.selectedSwitch, availableTargets[0]);
               };

            h5.actions[networkUiConstants.actions.PROXY_SWITCH_VIEW_SETTINGS] =
               function (actionEval, availableTargets, context) {
                  $injector.get("virtualSwitchDetailsDialogService").showProxySwitchDetailsDialog(
                     context.selectedSwitch, availableTargets[0]);
               };
         }]);
