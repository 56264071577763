namespace spbm_ui {

   export class SpbmProfileCheckComplianceActionService {
      static $inject = ['$injector'];

      private _vcSpbmProfileCheckComplianceActionService: SpbmProfileCheckComplianceActionService;

      constructor(private $injector: any) {
      }

      get vcSpbmProfileCheckComplianceActionService() {
         if (!this._vcSpbmProfileCheckComplianceActionService) {
            this._vcSpbmProfileCheckComplianceActionService = this.$injector.get('vcSpbmProfileCheckComplianceActionService');
         }
         return this._vcSpbmProfileCheckComplianceActionService;
      }

      checkProfilesCompliance(pbmProfiles: string[]): void {
         return this.vcSpbmProfileCheckComplianceActionService.checkProfilesCompliance(pbmProfiles);
      }
   }

   angular.module("com.vmware.vsphere.client.spbm")
         .service("spbmProfileCheckComplianceActionService", SpbmProfileCheckComplianceActionService);
}
