/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace dvs_ui {

   import DvsEditSpanSessionDialogData =
         com.vmware.vsphere.client.h5.network.dvs.portmirroring.model.dialog.DvsEditSpanSessionDialogData;

   export class DvsEditSpanSessionDialogSpecFactory {

      public buildChanges(model: DvsEditSpanSessionDialogModel): DvsEditSpanSessionDialogData | null {
         let hasChanges: boolean = this.havePropertiesChanged(model)
               || this.haveSourcesChanged(model)
               || this.haveDestinationsChanged(model);

         if (hasChanges) {
            return _.extend(
                  this.buildCommonPropertiesChanges(model),
                  this.buildSourcesChanges(model),
                  this.buildDestinationChanges(model));
         } else {
            return null;
         }
      }

      private buildCommonPropertiesChanges(model: DvsEditSpanSessionDialogModel): DvsEditSpanSessionDialogData {
         let changes: DvsEditSpanSessionDialogData = new DvsEditSpanSessionDialogData();
         changes.key = model.originalData.key;

         changes.sessionType = model.originalData.sessionType;

         changes.sessionName = model.propertiesPageModel.sessionName;
         changes.enabled = model.propertiesPageModel.enabled;
         changes.sessionId = model.propertiesPageModel.sessionId;
         if (model.propertiesPageModel.isTcpIpStackSupported) {
            changes.tcpIpStack = model.propertiesPageModel.tcpIpStack;
         }

         changes.preserveOriginalVlan =
               model.propertiesPageModel.preserveOriginalVlan;
         changes.encapsulationVlanId =
               model.propertiesPageModel.encapsulationVlanId;

         changes.normalTrafficAllowed =
               model.propertiesPageModel.normalTrafficAllowed;

         changes.mirroredPacketLength = model.propertiesPageModel.mirroredPacketLengthEnabled
               ? model.propertiesPageModel.mirroredPacketLength : -1;

         changes.samplingRate = model.propertiesPageModel.samplingRate;
         changes.description = model.propertiesPageModel.description;

         return changes;
      }

      private buildSourcesChanges(model: DvsEditSpanSessionDialogModel): DvsEditSpanSessionDialogData {
         let changes: DvsEditSpanSessionDialogData =
               new DvsEditSpanSessionDialogData();

         if (model.originalData.sessionType !==
               DvsSpanSessionConstants.SESSION_TYPE.REMOTE_MIRROR_DEST) {
            changes.sourcePortsEgress = [];
            changes.sourcePortsIngress = [];

            model.selectSourcesPageModel.portsListModel.ports
                  .forEach((port: PortData): void => {
                     if (port.trafficDirection ===
                           DvsSpanSessionConstants.TRAFFIC_DIRECTION.EGRESS
                           || port.trafficDirection ===
                           DvsSpanSessionConstants.TRAFFIC_DIRECTION.BOTH) {
                        changes.sourcePortsEgress.push(port.id);
                     }

                     if (port.trafficDirection ===
                           DvsSpanSessionConstants.TRAFFIC_DIRECTION.INGRESS
                           || port.trafficDirection ===
                           DvsSpanSessionConstants.TRAFFIC_DIRECTION.BOTH) {
                        changes.sourcePortsIngress.push(port.id);
                     }
                  });
         } else if (model.originalData.sessionType ===
               DvsSpanSessionConstants.SESSION_TYPE.REMOTE_MIRROR_DEST) {
            changes.sourceVlans =
                  model.selectSourcesPageModel.vlanIdListModel.values
                        .map((vlanId: string): number => {
                           return parseInt(vlanId);
                        });
         }

         return changes;
      }

      private buildDestinationChanges(model: DvsEditSpanSessionDialogModel): DvsEditSpanSessionDialogData {
         let changes: DvsEditSpanSessionDialogData =
               new DvsEditSpanSessionDialogData();

         switch (model.originalData.sessionType) {
            case DvsSpanSessionConstants.SESSION_TYPE.L3_SOURCE: {
               changes.destinationAddresses =
                     model.selectDestinationsPageModel.ipAddressesListModel.values;
               break;
            }
            case DvsSpanSessionConstants.SESSION_TYPE.REMOTE_MIRROR_DEST:
            case DvsSpanSessionConstants.SESSION_TYPE.DV_PORT_MIRROR: {
               changes.destinationPorts =
                     model.selectDestinationsPageModel.portsListModel.ports
                           .map((port: PortData): string => {
                              return port.id;
                           });
               break;
            }
            case DvsSpanSessionConstants.SESSION_TYPE.REMOTE_MIRROR_SOURCE: {
               changes.uplinkPortsSelected =
                     model.selectDestinationsPageModel.uplinksListModel.uplinks;
               break;
            }
         }

         return changes;
      }

      private havePropertiesChanged(model: DvsEditSpanSessionDialogModel): boolean {
         let props: string[] =
               Object.getOwnPropertyNames(model.propertiesPageModel);

         for (let prop of props) {
            if (prop === "errors") {
               continue;
            }

            if ((model.originalData as any)[prop]
                  !== (model.propertiesPageModel as any)[prop]) {
               return true;
            }
         }

         return false;
      }

      private haveSourcesChanged(model: DvsEditSpanSessionDialogModel): boolean {
         switch (model.originalData.sessionType) {
            case DvsSpanSessionConstants.SESSION_TYPE.L3_SOURCE:
            case DvsSpanSessionConstants.SESSION_TYPE.DV_PORT_MIRROR:
            case DvsSpanSessionConstants.SESSION_TYPE.REMOTE_MIRROR_SOURCE: {
               return this.haveSourcePortsChanged(model);
            }
            case DvsSpanSessionConstants.SESSION_TYPE.REMOTE_MIRROR_DEST: {
               return this.haveSourceVlansChanged(model);
            }
            default: {
               return false;
            }
         }
      }

      private haveDestinationsChanged(model: DvsEditSpanSessionDialogModel): boolean {
         switch (model.originalData.sessionType) {
            case DvsSpanSessionConstants.SESSION_TYPE.L3_SOURCE: {
               return this.haveDestinationAddressesChanged(model);
            }
            case DvsSpanSessionConstants.SESSION_TYPE.REMOTE_MIRROR_DEST:
            case DvsSpanSessionConstants.SESSION_TYPE.DV_PORT_MIRROR: {
               return this.haveDestinationPortsChanged(model);
            }
            case DvsSpanSessionConstants.SESSION_TYPE.REMOTE_MIRROR_SOURCE: {
               return this.haveDestinationUplinksChanged(model);
            }
            default: {
               return false;
            }
         }
      }

      private haveSourcePortsChanged(model: DvsEditSpanSessionDialogModel): boolean {
         return DvsEditSpanSessionDialogSpecFactory
               .havePortsListValuesChanged(
                     model.selectSourcesPageModel.portsListModel.ports,
                     model.originalData.sourcePortsEgress,
                     model.originalData.sourcePortsIngress);
      }

      private haveSourceVlansChanged(model: DvsEditSpanSessionDialogModel): boolean {
         let currentVlans: string[] =
               model.selectSourcesPageModel.vlanIdListModel.values || [];
         let originalVlans: string[] =
               (model.originalData.sourceVlans || [])
                     .map((value: number): string => { return String(value); });

         return DvsEditSpanSessionDialogSpecFactory
               .haveEditableListValuesChanged(currentVlans, originalVlans);
      }

      private haveDestinationAddressesChanged(model: DvsEditSpanSessionDialogModel): boolean {
         let currentAddresses: string[] =
               model.selectDestinationsPageModel.ipAddressesListModel.values || [];
         let originalAddresses: string[] =
               model.originalData.destinationAddresses || [];

         return DvsEditSpanSessionDialogSpecFactory
               .haveEditableListValuesChanged(currentAddresses, originalAddresses);
      }

      private haveDestinationPortsChanged(model: DvsEditSpanSessionDialogModel): boolean {
         return DvsEditSpanSessionDialogSpecFactory
               .havePortsListValuesChanged(
                     model.selectDestinationsPageModel.portsListModel.ports,
                     model.originalData.destinationPorts,
                     model.originalData.destinationPorts);
      }

      static haveEditableListValuesChanged(currentValues: string[],
            originalValues: string[]): boolean {
         if (currentValues.length !== originalValues.length) {
            return true;
         }

         for (let i: number = 0; i < currentValues.length; i++) {
            if (currentValues[i] !== originalValues[i]) {
               return true;
            }
         }

         return false;
      }

      static havePortsListValuesChanged(values: PortData[],
            originalEgress: string[], originalIngress: string[]): boolean {
         let egress: string[] = [];
         let ingress: string[] = [];

         values.forEach((portData: PortData): void => {
            switch (portData.trafficDirection) {
               case DvsSpanSessionConstants.TRAFFIC_DIRECTION.BOTH: {
                  egress.push(portData.id);
                  ingress.push(portData.id);
                  break;
               }
               case DvsSpanSessionConstants.TRAFFIC_DIRECTION.EGRESS: {
                  egress.push(portData.id);
                  break;
               }
               case DvsSpanSessionConstants.TRAFFIC_DIRECTION.INGRESS: {
                  ingress.push(portData.id);
                  break;
               }
            }
         });

         return _.difference(egress, originalEgress).length > 0
               || _.difference(originalEgress, egress).length > 0
               || _.difference(ingress, originalIngress).length > 0
               || _.difference(originalIngress, ingress).length > 0;
      }

      private haveDestinationUplinksChanged(model: DvsEditSpanSessionDialogModel): boolean {
         let currentUplinks: string[] =
               model.selectDestinationsPageModel.uplinksListModel.uplinks || [];
         let originalUplinks: string[] =
               model.originalData.uplinkPortsSelected || [];

         if (currentUplinks.length !== originalUplinks.length) {
            return true;
         }

         for (let i: number = 0; i < currentUplinks.length; i++) {
            if (currentUplinks[i] !== originalUplinks[i]) {
               return true;
            }
         }

         return false;
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .service("dvsEditSpanSessionDialogSpecFactory", DvsEditSpanSessionDialogSpecFactory);
}