/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace dvs_ui {

   export class DvpgResourceAllocationPageComponent {

      public bindings: any;
      public controller: any;
      public templateUrl: string;

      constructor() {
         this.bindings = {
            model: "<"
         };

         this.controller = DvpgResourceAllocationPageComponentController;

         this.templateUrl = "dvs-ui/resources/dvs/dvpg/manage/views/" +
               "dvpgResourceAllocationPageTemplate.html";
      }
   }

   class DvpgResourceAllocationPageComponentController {

      static $inject = ["i18nService"];

      public model: DvpgResourceAllocationPolicyModel;

      constructor(private i18nService: any) {}
   }

   angular.module("com.vmware.vsphere.client.dvs").component(
         "dvpgResourceAllocationPage", new DvpgResourceAllocationPageComponent());
}
