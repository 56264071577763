/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace dvs_ui {

   export class DvpgTrafficShapingPolicyService {

      public static $inject = [
         "trafficShapingPolicyValidator",
         "networkUiConstants",
         "i18nService"];

      private attributeNames: string[];

      constructor(private trafficShapingPolicyValidator: any,
                  private networkUiConstants: any,
                  private i18nService: any) {
         this.attributeNames = [
            networkUiConstants.TrafficShapingSetting.AVERAGE_BANDWIDTH,
            networkUiConstants.TrafficShapingSetting.PEAK_BANDWIDTH,
            networkUiConstants.TrafficShapingSetting.BURST_SIZE
         ];
      }

      /**
       * Validates the Ingress- and Egress Traffic Shaping policies.
       *
       * Returns an array of all related error messages, or an empty array when the
       * validations pass successfully.
       */
      public getErrorMessages(inShapingPolicy: any, outShapingPolicy: any): string[] {
         let messages: string[] = [];

         _.forEach(this.attributeNames, (attributeName: string): void => {

            let inValue: any = inShapingPolicy[attributeName];
            let outValue: any = outShapingPolicy[attributeName];

            if (inShapingPolicy.enabled) {
               let inErrorMessage: string = this.trafficShapingPolicyValidator
                     .getTrafficShapingErrorMessage(inValue, attributeName);
               if (inErrorMessage !== null) {
                  messages.push(inErrorMessage);
               }
            }

            if (outShapingPolicy.enabled) {
               let outErrorMessage: string = this.trafficShapingPolicyValidator
                     .getTrafficShapingErrorMessage(outValue, attributeName);
               if (outErrorMessage !== null) {
                  messages.push(outErrorMessage);
               }
            }
         });

         if (messages.length === 0) {

            let isOutShapingPolicyValid: boolean =
                  this.isTrafficShapingPolicyValid(outShapingPolicy);
            let isInShapingPolicyValid: boolean =
                  this.isTrafficShapingPolicyValid(inShapingPolicy);

            if (!isInShapingPolicyValid && !isOutShapingPolicyValid) {
               messages.push(this.i18nService.getString(
                     'NetworkUi', 'dvpg.policy.shaping.both.avgBwGreaterThanPeakBw'));
            } else if (!isInShapingPolicyValid) {
               messages.push(this.i18nService.getString(
                     'NetworkUi', 'dvpg.policy.shaping.ingress.avgBwGreaterThanPeakBw'));
            } else if (!isOutShapingPolicyValid) {
               messages.push(this.i18nService.getString(
                     'NetworkUi', 'dvpg.policy.shaping.egress.avgBwGreaterThanPeakBw'));
            }
         }

         return messages;
      }

      private isTrafficShapingPolicyValid(policy: any): boolean {
         if (!policy.enabled) {
            return true;
         }

         return policy.averageBandwidth <= policy.peakBandwidth;
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .service("dvpgTrafficShapingPolicyService", DvpgTrafficShapingPolicyService);
}
