/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace h5_vm {

   import PmemUtilService = h5_vm.PmemUtilService;
   import PmemCapacityService = h5_vm.PmemCapacityService;
   import VirtualDisk$LocalPMemBackingInfo = com.vmware.vim.binding.vim.vm.device.VirtualDisk$LocalPMemBackingInfo;
   import VirtualDisk$FlatVer2BackingInfo = com.vmware.vim.binding.vim.vm.device.VirtualDisk$FlatVer2BackingInfo;
   import VmConfigContext = com.vmware.vsphere.client.vm.config.VmConfigContext;

   /**
    * The service encapsulates the operations for placing a Virtual Disk from
    * the regular storage to Pmem storage and from Pmem to regular.
    *
    * For NewVM/Edit Settings, this is expressed by the disk's Backing object.
    * In the Pmem case there is a special VirtualDisk$LocalPMemBackingInfo required by
    * the backend.
    *
    * For Clone VM the disk going on Pmem are represented as deviceChange and must be
    * filtered out from DiskLocator. Disks going on Pmem must be converted into
    * deviceChange.
    */
   export class VirtualDiskPmemService {

      static $inject: string[] = [
            "pmemUtilService",
            "pmemCapacityService",
            "storageSelectorConstants",
            "VirtualDisk",
            "storageProfileService",
            "diskProvisioningService"
      ];

      private static readonly DEFAULT_MIN_VALUE_FOR_NEW_PMEM_DISK: number = 1;

      constructor(private pmemUtilService: PmemUtilService,
            private pmemCapacityService: PmemCapacityService,
            private storageSelectorConstants: any,
            private VirtualDisk: any,
            private storageProfileService: any,
            private diskProvisioningService: any) {
      }

      getDefaultMinValueForNewPmemDisk() {
         return VirtualDiskPmemService.DEFAULT_MIN_VALUE_FOR_NEW_PMEM_DISK;
      }

      /**
       * Changes disk backing acording to applied storage profile.
       * Intended for usage in EditSettings/Customize hardware.
       * @param disk - an inflated VirtualDisk
       * @param storageProfile - applied StorageProfile
       * @returns {boolean} - true if the disk is Pmem after the operation
       */
      handlePmemIfPmemProfile(disk: any, storageProfile: any, selectedDiskFormat: any): boolean {
         let isPmem = disk.isPmemDisk();
         let isNewProfilePmem = this.pmemUtilService.isPMemStoragePolicy(storageProfile);
         if (isPmem && isNewProfilePmem) {
            return true;
         }
         if (!isPmem && isNewProfilePmem) {
            this.setPmemBacking(disk);
            return true;
         }
         if (!isPmem && !isNewProfilePmem) {
            return false;
         }
         if (isPmem && !isNewProfilePmem && disk.isNew()) {
            disk.restoreOriginalBacking();
            return false;
         }
         if (isPmem && !isNewProfilePmem && !disk.isNew()) {
            // The only possible option to get in here is during clone when we choose to
            // customize hardware before finish and the PMEM disk has been downgraded
            // In this case VirtualDeviceSpec.fileOperation must be left unset which
            // according to VMODL doc requires the device.backing.fileName to be set.
            // Here we restore the original file name after resetting the backing.
            const originalFile = disk.getCurrentDevice().backing.fileName;
            this.setFlatDiskBacking(disk);
            disk.getCurrentDevice().backing.fileName = originalFile;
            this.setDiskFormat(disk, selectedDiskFormat);
            return false;
         }
         return false;
      }

      /**
       * Utility method, that encapsulates the logic weather we want to apply any
       * Pmem rules depending on the selected storage baseline and if a disk is already pmem.
       * In Hybrid mode the Pmem profile must be applied only to already Pmem Disks.
       * In Pmem mode every disk, no matter if pmem or not, has to have the Pmem profile applied.
       * @param {string} storageBaselineId
       * @param {VirtualDisk} inflatedDevice
       * @returns false if the storageBaseline is Hybrid and disk isn't Pmem,
       *       false if disk profile was set manually by the user.
       *       always true if storageBaseline is Pmem
       */
      shouldApplyPmemProfileToDisk(inflatedDevice: any,
            storageBaselineId: string): boolean {
         // Do not allow change of storage profile, when user has set it explicitly.
         if (inflatedDevice.isStorageProfileManuallySet()) {
            return false;
         }
         if (inflatedDevice.isPmemDisk()
               && this.storageSelectorConstants.HYBRID_STORAGE_BASELINE.id === storageBaselineId) {
            return true;
         }
         return this.storageSelectorConstants.PMEM_STORAGE_BASELINE.id === storageBaselineId;
      }

      /**
       * Removes some disks from the input array according to Pmem Rules.
       * Any virtual disks that are to be moved to Pmem Storage in Clone Workflow must
       * be represented as deviceChange in RelocateSpec, not as DiskLocator.
       * @param virtualDisks - array of virtual disk devices
       * @param storageBaselineId - id of the storage baseline (Hybrid, Standard, Pmem)
       * @param newDisks - specifies if the filtered disks are original disks or newly
       *       created disks. In CloneVm only the default state of new VirtualDisks is
       *       controlled by the storageBaseline and the user has control over them.
       * @returns an array of virtual disks that have to be converted to DiskLocators
       */
      filterVirtualDisksToDiskLocatorsForPmem(
            virtualDisks: Array<any>, storageBaselineId: string, newDisks: boolean = false): Array<any> {
         if (storageBaselineId === this.storageSelectorConstants.STANDARD_STORAGE_BASELINE.id) {
            if (newDisks) {
               return this.filterOutPmemDisks(virtualDisks);
            } else {
               return virtualDisks;
            }
         }
         if (storageBaselineId === this.storageSelectorConstants.HYBRID_STORAGE_BASELINE.id) {
            return this.filterOutPmemDisks(virtualDisks);
         }
         if (storageBaselineId === this.storageSelectorConstants.PMEM_STORAGE_BASELINE.id) {
            if (newDisks) {
               return this.filterOutPmemDisks(virtualDisks);
            } else {
               return [];
            }
         }
         return virtualDisks;
      }

      /**
       * Removes some disks from the input array according to Pmem Rules.
       * Any virtual disks that are to be moved to Pmem Storage in Clone Workflow must
       * be represented as deviceChange in RelocateSpec, not as DiskLocator.
       * @param virtualDisks - array of virtual disk devices
       * @param storageSelectorDisks - the whole Advanced Configuration for Disks
       * @returns an array of virtual disks that have to be converted to DiskLocators
       */
      filterVirtualDisksToDiskLocatorsForPmemAdvanced(
            virtualDisks: Array<any>, storageSelectorDisks: Array<any>): Array<any> {
         return _.filter(virtualDisks, (virtualDisk: any) => {
            let advancedStorageConfigForDisk: any =
                  this.findAdvancedConfigurationForDisk(virtualDisk.key, storageSelectorDisks);
            return !advancedStorageConfigForDisk
                  || !this.pmemUtilService.isPMemStoragePolicy(advancedStorageConfigForDisk.storageProfile);
         });
      }

      /**
       * Builds the backings for disks that must be placed on normal storage.
       * @param backing - backing used for the virtual disk
       * @param storageBaselineId - id of the storage baseline (Hybrid, Standard, Pmem)
       * @returns appropriate backing that is to be used for this virtual disk in a
       *       DiskLocator
       */
      applyPmemRulesOnVirtualDiskBacking(backing: any, storageBaselineId: string, diskFormat: any): any {
         if ((storageBaselineId === this.storageSelectorConstants.STANDARD_STORAGE_BASELINE.id
               || storageBaselineId === this.storageSelectorConstants.HYBRID_STORAGE_BASELINE.id)
               && this.pmemUtilService.isPmemDisk({backing: backing})) {
            return this.diskProvisioningService.getBackingInfo(diskFormat.type);
         } else {
            return backing;
         }
      }

      /**
       * Function variant for advanced storage mode.
       * Builds the backings backings for disks that must be placed on normal storage.
       * @param diskKey - key needed to pull the diks config from the whole advanced config.
       * @param backing - backing used for the virtual disk
       * @param storageSelectorDisks - the whole Advanced Configuration for Disks
       * @returns appropriate backing that is to be used for this virtual disk in a
       *       DiskLocator
       */
      applyPmemRulesOnVirtualDiskBackingAdvanced(diskKey: number,
            backing: any, storageSelectorDisks: Array<any>): any {
         let advancedStorageConfigForDisk: any =
               this.findAdvancedConfigurationForDisk(diskKey, storageSelectorDisks);
         if (advancedStorageConfigForDisk
               && !this.pmemUtilService.isPMemStoragePolicy(advancedStorageConfigForDisk.storageProfile)
               && this.pmemUtilService.isPmemDisk({backing: backing})) {
            return this.diskProvisioningService.getBackingInfo(advancedStorageConfigForDisk.diskFormat.type);
         } else {
            return backing;
         }

      }

      /**
       * Any virtual disks that are to be moved to Pmem Storage must
       * be represented as deviceChange in RelocateSpec.
       * @param virtualDisk - disk Device that is to be moved to Pmem Storage
       * @param storageBaselineId - id of the storage baseline (Hybrid, Standard, Pmem)
       * @param storageProfile - current StorageProfile assigned to the disk Device
       * @returns the device change if any for the virtualDisk
       */
      convertVirtualDiskToDeviceChangeForPmem(
            virtualDisk: any, storageBaselineId: string, storageProfile: any): any {
         if (this.pmemUtilService.isPmemDisk(virtualDisk)
               || !this.pmemUtilService.isPMemStoragePolicy(storageProfile)
               || (storageBaselineId
               && storageBaselineId !== this.storageSelectorConstants.PMEM_STORAGE_BASELINE.id)) {
            return undefined;
         }
         return this.convetVirtualDiskToDeviceChangeInternal(virtualDisk, storageProfile);
      }


      /**
       * Any virtual disks that are to be moved to Pmem Storage must
       * be represented as deviceChange in RelocateSpec. Looks up the disks advanced
       * configuration for selected profile
       * @param virtualDisk - disk Device that is to be moved to Pmem Storage
       * @param {Array<any>} storageSelectorDisks - advanced configuration for disks
       * @returns the device change if any for the virtualDisk
       */
      convertVirtualDiskToDeviceChangeForPmemAdvanced(
            virtualDisk: any, storageSelectorDisks: Array<any>): any {
         let advancedStorageConfigForDisk: any =
               this.findAdvancedConfigurationForDisk(virtualDisk.key, storageSelectorDisks);
         let userSelectedStorageProfile: any = advancedStorageConfigForDisk.storageProfile;
         // When the virtualDisk was Pmem, it'll have a Pmem Backing and when it still has
         // Pmem Profile assigned it doesn't need to be represented as device change.
         // When a disk is upgraded to Pmem it will have a non-Pmem Backing and needs
         // to be represented as deviceChange.
         if (this.pmemUtilService.isPmemDisk(virtualDisk)
               || !this.pmemUtilService.isPMemStoragePolicy(userSelectedStorageProfile)) {
            return undefined;
         }
         return this.convetVirtualDiskToDeviceChangeInternal(virtualDisk, userSelectedStorageProfile);
      }

      /**
       * Provides info about what disks the VM uses.
       * Note: the return format is used for consistency with Migrate VM case. Also
       * in the future the exposing of the storageBaselineId may be deprecated and
       * this return format will be more suitable.
       * @returns {hasPmemDisk: true of there is at least one Pmem disk;
       *       hasRegularDisk: ture if there is at least one non-PmemDisk}
       */
      getPmemDiskInfo(storageSelectorState: any): {
            hasPmemDisk: boolean,
            hasRegularDisk: boolean,
            hasDowngradedPmemDisk: boolean} {
         let storageBaselineId: string = storageSelectorState
               ? storageSelectorState.storageBaselineId : "";
         switch (storageBaselineId) {
            case this.storageSelectorConstants.PMEM_STORAGE_BASELINE.id:
               return {
                  hasPmemDisk: true,
                  hasRegularDisk: false,
                  hasDowngradedPmemDisk: false
               };
            case this.storageSelectorConstants.HYBRID_STORAGE_BASELINE.id:
               return {
                  hasPmemDisk: true,
                  hasRegularDisk: true,
                  hasDowngradedPmemDisk: false
               };
            case this.storageSelectorConstants.STANDARD_STORAGE_BASELINE.id:
               if (storageSelectorState.vmStorageConfigInAdvancedMode
                     && storageSelectorState.vmStorageConfigInAdvancedMode[0]
                     && storageSelectorState.vmStorageConfigInAdvancedMode[0].vmDisks) {
                  let hasDowngradedPmemDisk: boolean =
                        !!this.advancedConfigurationForDiskHasPmemDisk(
                              storageSelectorState.vmStorageConfigInAdvancedMode[0].vmDisks);
                  return {
                     hasPmemDisk: false,
                     hasRegularDisk: true,
                     hasDowngradedPmemDisk: hasDowngradedPmemDisk
                  };
               } else {
                  return {
                     hasPmemDisk: false,
                     hasRegularDisk: true,
                     hasDowngradedPmemDisk: false
                  };
               }
            default:
               return {
                  hasPmemDisk: false,
                  hasRegularDisk: false,
                  hasDowngradedPmemDisk: false
               };
         }
      }

      /**
       * Returns the max allowed capacity for Pmem Disk.
       * @param {com.vmware.vsphere.client.vm.config.VmConfigContext} vmConfigContext -
       *       the config context for the compute resource.
       * @param disk - Pmem disk for which maximum size is to be calculated.
       * @param virtualMachineDevices - the current devices of the vm. Used to include
       *       any new NVDIMMs or PmemDisks in the calculation.
       * @returns {number}
       */
      getMaxAllowedCapacity(vmConfigContext: VmConfigContext,
            disk: any, virtualMachineDevices: any) {
         return this.pmemCapacityService.getMaxCapacityForPmemDisk(
               vmConfigContext, disk, virtualMachineDevices);
      }

      private setPmemBacking(disk: any) {
         disk.getCurrentDevice().backing = this.generateDefaultPmemBacking();
      }

      private setFlatDiskBacking(disk: any) {
         disk.getCurrentDevice().backing = this.generateDefaultFlatVer2Backing();
      }

      private filterOutPmemDisks(virtualDisks: Array<any>): Array<any> {
         return _.filter(virtualDisks, (virtualDisk: any): boolean => {
            return !this.pmemUtilService.isPmemDisk(virtualDisk);
         });
      }

      private findAdvancedConfigurationForDisk(
            virtualDiskKey: any, storageSelectorDisks: Array<any>): any {
         return _.find(storageSelectorDisks,
               (advancedConfig: any) => advancedConfig.key === virtualDiskKey);
      }

      private advancedConfigurationForDiskHasPmemDisk(
            storageSelectorDisks: Array<any>): boolean {
         if (!storageSelectorDisks.length) {
            return false;
         }
         return _.find(storageSelectorDisks,
               (advancedConfig: any) => this.pmemUtilService.isPMemStoragePolicy(
                     advancedConfig.storageProfile));
      }

      private convetVirtualDiskToDeviceChangeInternal(virtualDisk: any, storageProfile: any) {
         let wrappedVirtualDisk: any = new this.VirtualDisk(virtualDisk);
         this.setPmemBacking(wrappedVirtualDisk);
         let deviceChange: any = wrappedVirtualDisk.getCurrentDeviceSpec();
         deviceChange.fileOperation = "create";
         deviceChange.operation = "add";
         deviceChange.profile = [
            this.storageProfileService.makeProfile(storageProfile.id)];
         return deviceChange;
      }

      private generateDefaultPmemBacking(): VirtualDisk$LocalPMemBackingInfo {
         let result: VirtualDisk$LocalPMemBackingInfo =
               new VirtualDisk$LocalPMemBackingInfo();
         result.diskMode = "persistent";
         result.fileName = "";
         return result;
      }

      private generateDefaultFlatVer2Backing(): VirtualDisk$FlatVer2BackingInfo {
         let result: VirtualDisk$FlatVer2BackingInfo =
               new VirtualDisk$FlatVer2BackingInfo();
         result.diskMode = "persistent";
         result.fileName = "";
         return result;
      }

      /**
       * Adds proper thinProvisioned & eagerlyScrub properties
       * to the backing of the disk based on the provided disk format
       *
       * @param disk
       * @param selectedFormat
       */
      private setDiskFormat(disk: any, selectedFormat: any): void {
         if (!disk.getCurrentDevice().backing || !selectedFormat) {
            return;
         }

         let keys: any = this.diskProvisioningService.backingInfoKeys(selectedFormat.type);
         angular.extend(disk.getCurrentDevice().backing, keys);
      }

   }

   angular.module("com.vmware.vsphere.client.vm")
         .service("virtualDiskPmemService", VirtualDiskPmemService);
}
