(function () {
   'use strict';

   angular.module('com.vmware.vsphere.client.host')
         .service('bytesFormatter', BytesFormatterService);

   BytesFormatterService.$inject = ['i18nService', 'numberFormatterService'];

   function BytesFormatterService(i18nService, numberFormatterService) {

      var UNIT_FACTOR_B = 1024;

      var KB = UNIT_FACTOR_B;
      var MB = KB * UNIT_FACTOR_B;
      var GB = MB * UNIT_FACTOR_B;
      var TB = GB * UNIT_FACTOR_B;
      var LABEL_KB = i18nService.getString('CommonUi', 'kiloByte.label');
      var LABEL_MB = i18nService.getString('CommonUi', 'megaByte.label');
      var LABEL_GB = i18nService.getString('CommonUi', 'gigaByte.label');
      var LABEL_TB = i18nService.getString('CommonUi', 'teraByte.label');

      function bytesFormat(value) {
         var label = i18nService.getString('CommonUi', 'byte.label');
         if (value >= TB) {
            value /= TB;
            label = LABEL_TB;
         } else if (value >= GB) {
            value /= GB;
            label = LABEL_GB;
         } else if (value >= MB) {
            value /= MB;
            label = LABEL_MB;
         } else if (value >= KB) {
            value /= KB;
            label = LABEL_KB;
         }

         return numberFormatterService.format(value, {
            fractionSize: 2,
            suffix: label
         });
      }

      return {
         bytesFormat: bytesFormat
      };
   }
}());
