module cluster_ui {

   export class DrsPoliciesService {

      public static $inject = ["i18nService"];

      constructor(private i18nService: any) {
      }

      public getEvenDistributionLabel(data: any): string {
         return data.evenDistributionEnabled ?
               this.i18nService.getString("ClusterUi",
                     "drs.config.evenDistribution.on") :
               this.i18nService.getString("ClusterUi",
                     "drs.config.evenDistribution.off");
      }

      public getConsumedOrActiveMemoryLabel(data: any): string {
         return data.allConsumedMemoryMetricEnabled ?
               this.i18nService.getString("ClusterUi",
                     "drs.config.consumedOrActiveMemory.consumed") :
               this.i18nService.getString("ClusterUi",
                     "drs.config.consumedOrActiveMemory.active");
      }

      public getCpuOverCommitmentLabel(data: any): string {
         return !data.cpuOverCommitment ?
               this.i18nService.getString("ClusterUi",
                     "drs.config.cpuOverCommitment.notConfigured") :
               this.getCpuOvercommitmentText(data.cpuOverCommitmentPercent);
      }

      private getCpuOvercommitmentText(percent: number): string {
         if (percent === 0) {
            return this.i18nService.getString("ClusterUi",
                  "drs.config.cpuOverCommitment.notAllowed");
         }
         return this.i18nService.getString(
               "ClusterUi", "drs.config.cpuOverCommitment.allowed", percent);
      }
   }

   angular.module("com.vmware.vsphere.client.cluster")
         .service("drsPoliciesService", DrsPoliciesService);
}
