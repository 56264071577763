namespace ds_cluster_ui {
   export class EnterMaintenanceModeWarningComponent {
      public bindings: any;
      public controller: any;
      public templateUrl: string;

      constructor() {
         this.bindings = {
            parentPod: "<",
            vmIds: "<",
            actionEval: "=",
            promise: "<",
            shouldStartMigrateWizard: "="
         };

         this.controller = EnterMaintenanceModeWarningController;
         this.templateUrl = "ds-cluster-ui/resources/ds-cluster/views/maintenanceMode/enterMaintenanceModeWarningView.html";
      };
   }

   class EnterMaintenanceModeWarningController {
      public static $inject = ["$scope",
         "defaultUriSchemeUtil",
         "actionsService",
         "datastoreMaintenanceModeService",
         "i18nService"];
      private shouldStartMigrateWizard: boolean;
      private warningMessage: string;
      private canMigrate: boolean = true;
      private loading: boolean = true;
      private signpostParams: any;
      private parentPod: any;
      private vmIds: any;
      private actionEval: any;
      private promise: any;
      private i18n: any;

      constructor(private $scope: any,
                  private defaultUriSchemeUtil: any,
                  private actionsService: any,
                  private datastoreMaintenanceModeService: DatastoreMaintenanceModeService,
                  private i18nService: any) {
         this.i18n = this.i18nService.getString;
      }

      $onInit(): void {
         this.promise.then((promisesResult: Array<any>) => {
            // The below check covers mixed power state vms and ft vms. Check java
            // code for further details on the exact validation logic. This is the
            // same validation Migrate VM wizard uses, so through it we prevent
            // calling the migrate wizard with incompatible VMs
            let areVmsInSameState: boolean = promisesResult[0].result.warnings.length === 0 &&
                  promisesResult[0].result.errors.length === 0;

            // Checks user privileges, disabled methods and availability properties
            // for migrate vm wizard
            let isMigrateActionAvailable: boolean = false;
            if (promisesResult[1].evaluationStatuses) {
               let availabilityStatuses: Array<boolean> =
                     _.map(promisesResult[1].evaluationStatuses,
                               (evaluationStatus: any) => evaluationStatus.available);
               isMigrateActionAvailable = availabilityStatuses ?
                     _.find(availabilityStatuses,
                           (available: boolean) => !available) === undefined : false;
            }

            this.actionEval.evaluationStatuses =
                  promisesResult[1].evaluationStatuses;
            this.actionEval.available = isMigrateActionAvailable;
            this.canMigrate = areVmsInSameState && isMigrateActionAvailable;
            this.loading = false;
         });

         this.signpostParams = {
           title: this.i18nService.getString(
                 'DsClusterUi',
                 'enterDsMaintenanceMode.warning.signpost.help'),
           message: this.i18nService.getString(
                 'DsClusterUi',
                 'enterDsMaintenanceMode.warning.signpost')
         };

         let numberOfVms = this.vmIds.length;
         this.warningMessage = numberOfVms > 1 ?
               this.i18nService.getString(
                     "DsClusterUi",
                     "enterDsMaintenanceMode.warning.datastore.multiple",
                     numberOfVms) :
               this.i18nService.getString(
                     "DsClusterUi",
                     "enterDsMaintenanceMode.warning.datastore.single");
         if (this.parentPod) {
            this.warningMessage += this.i18nService.getString(
                  "DsClusterUi",
                  "enterDsMaintenanceMode.warning.datastore.sdrsOff");
         }
      }
   }

   angular.module("com.vmware.vsphere.client.dsCluster")
         .component("enterMaintenanceModeWarning",
               new EnterMaintenanceModeWarningComponent());
}