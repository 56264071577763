/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
(function() {
   'use strict';
   angular.module('com.vmware.vsphere.client.storage').controller(
      'StorageDevicesViewController', StorageDevicesViewController);

   StorageDevicesViewController.$inject = [
      'i18nService',
      '$scope',
      'hostStorageService',
      'dataService',
      '$q',
      'navigation'
   ];

   function StorageDevicesViewController(i18nService, $scope, hostStorageService, dataService, $q, navigation) {
      var self = this;
      self.storageDevicesLoading = false;
      self.hostId = navigation.getRoute().objectId;
      self.headerOptions = {
            title: i18nService.getString('StorageUi', 'storage.devices.listView.header'),
            objectId: self.hostId,
            actions: []
      };
      self.viewId = navigation.getRoute().extensionId;

      self.liveRefreshProperties = [
         'config.storageDevice',
         'config.storageDevice.scsiLun',
         'config.storageDevice.plugStoreTopology.device',
         'config.storageDevice.multipathInfo.lun',
         'config.storageDevice.plugStoreTopology.target',
         'config.fileSystemVolume.mountInfo',
         'config.multipathState.path',
         'datastore'
      ];

      self.refresh = function() {
         self.storageDevicesLoading = true;

         var requests = {
            storageDevices: hostStorageService.retrieveStorageDevicesData(self.hostId),
            isHostConnected: dataService.getProperties(self.hostId, ['runtime.connectionState'])
               .then(function (response) {
                  return response && response['runtime.connectionState'] === 'connected';
               })
         };

         self.lastRefreshRequest = requests;

         $q.all(requests).then(
            function(result) {
               // If the current result is made by the last refresh request.
               if (self.lastRefreshRequest === requests) {
                  self.storageDevicesLoading = false;
                  self.devices = result.storageDevices;
                  self.isHostConnected = result.isHostConnected;
               }
            });
      };

      $scope.$watch(function() {
         return self.selectedDevices;
      }, function(newValue, oldValue) {
         if (newValue === oldValue) {
            return;
         }

         if (self.selectedDevices) {
            $scope.masterDetailsViewContext.selectedItems = self.selectedDevices;
            $scope.masterDetailsViewContext.isHostConnected = self.isHostConnected;
         } else {
            // Propagate empty array to details view if there is no selection.
            $scope.masterDetailsViewContext.selectedItems = [];
         }
      });

      // Request storage devices after the grid is initialized.
      self.refresh();
   }
})();
