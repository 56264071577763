module cluster_ui {

   import IPromise = angular.IPromise;

   export class EditDrsDialogManager {

      public static $inject = ["i18nService", "drsConstants", "dpmThresholdService",
         "advancedOptionsBuilderFactory", "clusterSpecBuilderService",
         "mutationService", "$q", "editDrsFileDownloadService",
         "clusterConstants", "clarityModalService", "clarityConstants"];

      private formData: any;
      private objectId: string;
      private drsDialogOptions: any;

      private constructor(private i18nService: any,
                          private drsConstants: any, private dpmThresholdService: any,
                          private advancedOptionsBuilderFactory: any,
                          private clusterSpecBuilderService: any,
                          private mutationService: any, private $q: any,
                          private editDrsFileDownloadService: any,
                          private clusterConstants: any,
                          private clarityModalService: any,
                          private clarityConstants: any) {

      }

      public setDrsDialogOptions(drsDialogOptions: any): void {
         this.drsDialogOptions = drsDialogOptions;
      }

      public submit(): void {
         this.formData.drsPolicies.cpuOvercommitmentValue =
               Math.round(parseFloat(this.formData.drsPolicies.cpuOvercommitmentValue));
         let clusterSpec: any = this.clusterSpecBuilderService.buildClusterComputeResourceSpec(
               this.formData, true);

         return this.mutationService.validate(this.objectId,
               'com.vmware.vsphere.client.cluster.ClusterComputeResourceSpec',
               clusterSpec)
               .then(this.handleValidationResult.bind(this))
               .then(() => {
                  this.mutationService.apply(this.objectId,
                        'com.vmware.vsphere.client.cluster.ClusterComputeResourceSpec',
                        clusterSpec);
                  return true;
               }, () => this.$q.resolve(false))
               .catch((error: any) => {
                  this.$q.reject(error);
               });
      }

      public getPageDefinitions(isScheduledTask: boolean): Array<any> {
         let pages: Array<any> = [];

         let pageDefinitions: any = this.getPageDefs();

         if(isScheduledTask) {
            pages.push(pageDefinitions.drsAutomationLevelDef.page);
            pages.push(pageDefinitions.additionalOptionsDef.page);
            pages.push(pageDefinitions.dpmAutomationDef.page);
         } else {
            pages.push(pageDefinitions.drsAutomationLevelDef.page);
            pages.push(pageDefinitions.additionalOptionsDef.page);
            pages.push(pageDefinitions.dpmAutomationDef.page);
            pages.push(pageDefinitions.advancedOptionsDef.page);
         }

         return pages;
      }

      public setObjectId(objectId: string): void {
         this.objectId = objectId;
      }

      public getObjectId(): string {
         return this.objectId;
      }

      public setFormData(drsData: any, is65VcOrLater: any): void {

         let drsPolicies: any = {
            allConsumedMemoryMetric: drsData.allConsumedMemoryMetricEnabled,
            cpuOvercommitment: drsData.cpuOverCommitment,
            cpuOvercommitmentValue: drsData.cpuOverCommitmentPercent
         };

         if (is65VcOrLater) {
            drsPolicies.evenDistribution = drsData.evenDistributionEnabled;
         }

         this.formData = {
            is65VcOrLater: is65VcOrLater,
            drsEnabled: drsData.drsEnabled,
            drsAutomation: {
               automationLevel: {
                  id: drsData.defaultDrsBehavior
               },
               migrationThreshold: drsData.migrationThreshold,
               vmAutomation: drsData.vmAutomationEnabled,
               proactiveDrsEnabled: drsData.proactiveDrsEnabled
            },
            drsPolicies: drsPolicies,
            dpmAutomation: {
               automationLevel: {
                  id: this.getDpmAutomationLevel(drsData)
               },
               dpmThreshold: this.dpmThresholdService
                     .reverseDpmRateAndThreshold(drsData.dpmRate)
            },
            scheduledTasksCount: drsData.scheduledTasksCount
         };
         this.formData.advancedOptionsBuilder =
               this.advancedOptionsBuilderFactory.getBuilder(drsData.advancedOptions, true);

      }

      public getFormData() {
         return this.formData;
      }

      public getDpmPageModel() {
         return {
            dpmAutomation: this.formData.dpmAutomation
         };
      }

      public getAdditionalOptionsPageModel() {
         return {
            drsPolicies: this.formData.drsPolicies,
            is65VcOrLater: this.formData.is65VcOrLater
         };
      }

      public getDrsAutomationLevelPageModel() {
         return {
            is65VcOrLater: this.formData.is65VcOrLater,
            drsAutomation: this.formData.drsAutomation
         };
      }

      public getAdvancedOptionsPageModel() {
         return {
            advancedOptionsBuilder: this.formData.advancedOptionsBuilder
         };
      }

      private getPageDefs(): any {
         // page definitions
         let drsAutomationLevelDef: any = {
            page: {
               id: 'drsAutomationLevel',
               title: this.i18nService.getString('ClusterUi', 'drs.config.dialog.automationLabel'),
               contentUrl: 'cluster-ui/resources/cluster/views/settings/services/drs/edit/pages/drsAutomationLevelPage.html'
            }
         };

         let additionalOptionsDef: any = {
            page: {
               id: 'additionalOptions',
               title: this.i18nService.getString('ClusterUi', 'drs.config.drsPoliciesLabel'),
               contentUrl: 'cluster-ui/resources/cluster/views/settings/services/drs/edit/pages/additionalOptionsPage.html'
            }
         };

         let dpmAutomationDef: any = {
            page: {
               id: 'dpmAutomation',
               title: this.i18nService.getString('ClusterUi', 'drs.config.dpmLabel'),
               contentUrl: 'cluster-ui/resources/cluster/views/settings/services/drs/edit/pages/dpmAutomationPage.html'
            }
         };

         let advancedOptionsDef: any = {
            page: {
               id: 'advancedOptions',
               title: this.i18nService.getString('ClusterUi', 'drs.config.advancedOptionsLabel'),
               contentUrl: 'cluster-ui/resources/cluster/views/settings/services/drs/edit/pages/drsAdvancedOptionsPage.html'
            }
         };

         return {
            drsAutomationLevelDef: drsAutomationLevelDef,
            additionalOptionsDef: additionalOptionsDef,
            dpmAutomationDef: dpmAutomationDef,
            advancedOptionsDef: advancedOptionsDef
         };
      }

      private getDpmAutomationLevel(drsData: any): any {
         return drsData.dpmEnabled ?
               drsData.defaultDpmBehavior : this.drsConstants.dpmAutomationLevel.DISABLED;
      }

      private handleValidationResult(validationResult: any) {
         let title: string;
         if (!validationResult ||
               (!validationResult.error && (!validationResult.result ||
               validationResult.result.isValid === true))) {
            return this.$q.resolve();
         } else if (validationResult.error) {
            let message: string =  validationResult.error.localizedMessage ?
                  validationResult.error.localizedMessage : validationResult.error.message;
            this.showValidationMessages(message);
            return this.$q.reject();
         } else {
            if (validationResult.result.errorCode === this.clusterConstants.
                        editClusterErrorCodes.DRS_OFF_WITH_RESOURCE_POOLS) {
               return this.editDrsFileDownloadService.showYesNoCancelDialog(this.objectId);
            } else if (validationResult.result.errorCode === this.clusterConstants.
                        editClusterErrorCodes.DPM_ON_WITH_HOSTS_STANDBY) {
               title = this.i18nService.getString("ClusterUi", "drs.config.editDrs.dpmTurningOnCaption");
               return this.showConfirmationDialog(title,
                     validationResult.result.validationMessage,
                     validationResult.result.validationData);
            } else {
               let validationMessages: Array<string> = [validationResult.result.validationMessage];
               this.showValidationMessages(validationMessages);
               return this.$q.reject();
            }
         }
      }

      private showConfirmationDialog(title: string, message: string, invalidHosts: any): IPromise<any> {
         let deferred: any = this.$q.defer();

         let titleText: string = title;
         let messageText: string = message;

         _.each(invalidHosts, function (host) {
            messageText += "\n" + host;
         });

         let modalOptions: any = {
            title: titleText,
            message: messageText,
            submit: () => deferred.resolve(true),
            onCancel: () => deferred.reject(),
            preserveNewlines: true,
            modalClass: "turn-on-dpm-confirmation-modal"
         };

         this.clarityModalService.openConfirmationModal(modalOptions);
         return deferred.promise;
      }

      private showValidationMessages(messages: any): void {
         let validationErrorMessages: Array<any> = _.map(messages, (message: string) => {
            return {
               type: this.clarityConstants.notifications.type.ERROR,
               text: message
            };
         });
         this.drsDialogOptions.isAlertClosed = false;
         this.drsDialogOptions.alerts = validationErrorMessages;
      }

   }

   angular.module("com.vmware.vsphere.client.cluster")
         .factory("EditDrsDialogManager",
               ["i18nService",
                  "drsConstants",
                  "dpmThresholdService",
                  "advancedOptionsBuilderFactory",
                  "clusterSpecBuilderService",
                  "mutationService",
                  "$q",
                  "editDrsFileDownloadService",
                  "clusterConstants",
                  "clarityModalService",
                  "clarityConstants",
                  function () {
                     return Function.prototype.bind.apply(
                           EditDrsDialogManager,
                           [undefined,
                              arguments[0],
                              arguments[1],
                              arguments[2],
                              arguments[3],
                              arguments[4],
                              arguments[5],
                              arguments[6],
                              arguments[7],
                              arguments[8],
                              arguments[9],
                              arguments[10]
                           ]
                     );
                  }
               ]);
}
