(function() {
   'use strict';

   angular
         .module('com.vmware.vsphere.client.vm')
         .service('VmProvisioningCloneCustomizeGuestOsPageModel',
               VmProvisioningCloneCustomizeGuestOsPageModel);

   VmProvisioningCloneCustomizeGuestOsPageModel.$inject = [
      '$q',
      'dataService',
      'i18nService',
      'vuiConstants',
      'creationTypeConstants',
      'guestosCustomizationConstants',
      'defaultUriSchemeUtil',
      'timeFormatterService',
      'userSessionService',
      'guestOsCustomizationSpecValidator',
      "customizeGosPreCheckSpecsService"
   ];

   function VmProvisioningCloneCustomizeGuestOsPageModel($q,
         dataService,
         i18nService,
         vuiConstants,
         creationTypeConstants,
         guestosCustomizationConstants,
         defaultUriSchemeUtil,
         timeFormatterService,
         userSessionService,
         guestOsCustomizationSpecValidator,
         customizeGosPreCheckSpecsService) {
      return function(virtualMachineSpecBuilder, wizardViewData) {
         var form = {};
         var savedDateTimeFormat;

         return {
            form: form,
            resetForm: resetForm,
            validatePage: validatePage,
            submitPage: submitPage,
            getForm: getForm,
            pageFinishedLoading: pageFinishedLoading
         };

         function resetForm() {
            form.readyToView = false;
            form.guestOsIsCustomizable = true;
            form.datagridOptions = {
               columnDefs: [
                  {
                     field: 'name',
                     displayName: i18nService.getString(
                           'VmUi', 'CustomizeGuestOsProvisioningPage.content.name')
                  },
                  {
                     field: 'guestOs',
                     displayName: i18nService.getString(
                           'VmUi', 'CustomizeGuestOsProvisioningPage.content.type'),
                     width: 110
                  },
                  {
                     field: 'lastModified',
                     searchable: false,
                     displayName: i18nService.getString('VmUi',
                           'customizationManager.customizationSpecList.lastModifiedColumnHeader'),
                     template: function (spec) {
                        return timeFormatterService.timestampToText(
                              parseInt(spec.lastModified), savedDateTimeFormat);
                     }
                  }

               ],
               sortOrder: [{
                  field: "name",
                  dir: "asc"
               }],
               selectionMode: vuiConstants.grid.selectionMode.SINGLE,
               searchable: false,
               resizable: true,
               height: '100%',
               selectedItems: [],
               data: []
            };

            timeFormatterService.getUserTimeFormatPreference().then(function(format) {
               savedDateTimeFormat = format;
            });

            if (virtualMachineSpecBuilder.getCreationType() ===
                  creationTypeConstants.DEPLOY_VM_FROM_TEMPLATE ||
                virtualMachineSpecBuilder.getCreationType() ===
                  creationTypeConstants.DEPLOY_VM_FROM_VAPP) {
               populateGosCustomizationDataForDeployTemplate();
            } else {
               populateGosCustomizationDataForCloneVm();
            }
         }

         function populateGosCustomizationDataForDeployTemplate() {
            var vCenter;
            var customizationManager;
            userSessionService.getAllServersInfo()
               .then(function (info) {
                  var template = wizardViewData.getSelectedTemplate();
                  var guestOsType = getGosType(template.gosType);

                  wizardViewData.setGuestOsType(guestOsType);
                  form.guestOsName = template.gosDescription;
                  var vCentersData = info.serversInfo;

                  if (vCentersData && vCentersData.length > 0) {
                     vCenter = vCentersData[0];
                     customizationManager = vCenter ?
                        defaultUriSchemeUtil.getVsphereObjectId(vCenter.content.customizationSpecManager) :
                        null;
                  }

                  if (!customizationManager) {
                     return $q.reject({});
                  }

                  return dataService.getProperties(customizationManager, ['customizationSpecListData'], {
                     propertyParams: [{
                        _type: 'com.vmware.vise.mvc.model.data.ParamSpec',
                        propertyName: 'customizationSpecListData',
                        parameterType: 'java.lang.String',
                        parameter: template.gosType
                     }]
                  });
               }).then(function (props) {
                  if (!isCustomizationValid(props.customizationSpecListData.compatibility)) {
                     return $q.resolve({});
                  }
                  var customizationSpecs = filterRelevantCustomizationSpecs(props.customizationSpecListData.customizationSpecs);
                  return $q.all(_.map(customizationSpecs, function(spec) {
                     return {
                        name: spec.name,
                        guestOs: spec.type,
                        lastModified: spec.lastUpdateTime
                     };
                  }));
               }).then(function(customizationSpecs) {
                  resetSelectedSpec(customizationSpecs);
                  form.datagridOptions.data = customizationSpecs;
                  form.readyToView = true;
               });
         }

         function populateGosCustomizationDataForCloneVm() {
            var cloneCustomizationSpecDataPromise;
            var customizeGosSpecData = wizardViewData.getCustomizeGosSpecData();
            wizardViewData.setCustomizeGosSpecData();
            if(customizeGosSpecData) {
               var cloneCustomizationSpecData = {
                  error: customizeGosPreCheckSpecsService.validateData(customizeGosSpecData),
                  data: customizeGosSpecData
               };
               cloneCustomizationSpecDataPromise = $q.when(cloneCustomizationSpecData);
            }
            if(!cloneCustomizationSpecDataPromise) {
               var vmId = virtualMachineSpecBuilder.getVmId();
               var vCenterId = defaultUriSchemeUtil.getRootFolderFromVsphereObjectId(
                  virtualMachineSpecBuilder.getTargetInformation().datacenterUid);
               cloneCustomizationSpecDataPromise = customizeGosPreCheckSpecsService
                     .cloneCustomizeGosLoadData(vmId, false, vCenterId);
            }

            cloneCustomizationSpecDataPromise.then(function(cloneCustomizationSpecData) {
               form.readyToView = true;
               var warning = cloneCustomizationSpecData.error;

               wizardViewData.setGuestOsType(cloneCustomizationSpecData.data.guestType);
               form.guestOsName = cloneCustomizationSpecData.data.guestFullName;

               if(warning) {
                  form.guestOsIsCustomizable = false;
                  form.uncustomizableMessage = warning;
                  return ;
               }

               var customizationSpecs = _.map(cloneCustomizationSpecData.data.relevantSpecs, function(spec) {
                  return {
                     name: spec.name,
                     guestOs: spec.type,
                     lastModified: spec.lastUpdateTime
                  };
               });

               resetSelectedSpec(customizationSpecs);
               form.datagridOptions.data = customizationSpecs;
               form.readyToView = true;
               return {};
            });
         }

         function filterRelevantCustomizationSpecs(allSpecs) {
            var currentGuestOS = wizardViewData.getGuestOsType();
            var filteredSpecs = _.filter(allSpecs,
               function(spec) {
                  return currentGuestOS === spec.type;
               });
            return filteredSpecs;
         }

         function isCustomizationValid(compatibility) {
            if (compatibility === guestosCustomizationConstants.MISSING_WINDOWS) {
               form.guestOsIsCustomizable = false;
               form.uncustomizableMessage =
                  i18nService.getString('VmUi', 'CustomizationDataProvider.missing_windows');
               return false;
            }
            if (compatibility === guestosCustomizationConstants.MISSING_LINUX) {
               form.guestOsIsCustomizable = false;
               form.uncustomizableMessage =
                  i18nService.getString('VmUi', 'CustomizationDataProvider.missing_linux');
               return false;
            }
            if (compatibility === guestosCustomizationConstants.UNCUSTOMIZABLE) {
               form.guestOsIsCustomizable = false;
               form.uncustomizableMessage =
                  i18nService.getString('VmUi', 'CustomizationDataProvider.cannot_customize');
               return false;
            }
            return true;
         }

         function processCustomizationData(customizationSpecsData) {

         }

         function validatePage() {
            if (!form.guestOsIsCustomizable) {
               unsetSpecData();
               return null;
            }

            // if there are no guest os specs loaded on the page - don't require selection
            if (form.datagridOptions.data.length === 0) {
               unsetSpecData();
               return null;
            }

            if (!form.selectedSpec) {
               return $q.resolve({
                  error: i18nService.getString('VmUi',
                        'CustomizeGuestOsProvisioningPage.content.noSelection')
               });
            }

            var vmId = virtualMachineSpecBuilder.getVmId();

            if (isInDeployFromTemplateMode()) {
               // Constructing a dummy VM ID for deploy OVF templates. OVF templates
               // do not have associated VM IDs. VM ID is necesary to retrieve
               vmId = 'urn:vmomi:VirtualMachine:template:' +
                     defaultUriSchemeUtil
                           .getPartsFromVsphereObjectId(wizardViewData.getVCenterId())
                           .serverGuid;
            }

            form.setPageLoading(true);
            return guestOsCustomizationSpecValidator.validate(
                  vmId,
                  virtualMachineSpecBuilder.getName(),
                  form.selectedSpec.name
            ).then(function(result) {
               wizardViewData.setCustomizationSpecName(result.specName);
               wizardViewData.setNICsToCustomize(result.nicsToCustomize);
               wizardViewData.setNeedToInputComputerName(result.needsComputerName);

               virtualMachineSpecBuilder.setCustomizationSpec(result.spec);
               virtualMachineSpecBuilder.setCustomizationSpecInfo(result.info);
               virtualMachineSpecBuilder.setCustomizationSpecName(result.specName);

               // #2214153 Disable showing user settings page while deploying a VM from VMTX in CL.
               var shouldShowUserSettingsPage = !isInDeployVmFromVmtxMode() &&
                     (result.needsComputerName || result.needsAnyNICSettings);
               form.showUserSettingsPage(shouldShowUserSettingsPage);

               return true;
            }, function(result) {
               return $q.resolve({ error: result.error });
            }).then(function(res) {
               form.setPageLoading(false);
               return res;
            });
         }

         function submitPage() {
            return {
               wizardFlow: virtualMachineSpecBuilder.getCreationType(),
               invalidateNextPages: false
            };
         }

         function getForm() {
            return form;
         }

         function pageFinishedLoading() {
            return form.readyToView;
         }

         function resetSelectedSpec(customizationSpecs) {
            if (form.selectedSpec) {
               var selectedSpec = _.some(customizationSpecs, function(spec) {
                  return form.selectedSpec.name === spec.name;
               });

               if (!selectedSpec) {
                  form.selectedSpec = undefined;
               }
            }
         }

         function unsetSpecData() {
            wizardViewData.setCustomizationSpecName();
            wizardViewData.setNICsToCustomize();
            wizardViewData.setNeedToInputComputerName();
            virtualMachineSpecBuilder.setCustomizationSpec();
            virtualMachineSpecBuilder.setCustomizationSpecInfo();
            form.showUserSettingsPage(false);
         }

         function getGosType(gosType) {
            if (!gosType) {
               return guestosCustomizationConstants.TYPE_OTHER;
            }

            return (gosType.indexOf('win') === 0) ?
               guestosCustomizationConstants.TYPE_WINDOWS : guestosCustomizationConstants.TYPE_LINUX;
         }

         function isInDeployFromTemplateMode() {
            return virtualMachineSpecBuilder.getCreationType() ===
                  creationTypeConstants.DEPLOY_VM_FROM_TEMPLATE;
         }

         function isInDeployVmFromVmtxMode() {
            return virtualMachineSpecBuilder.getCreationType() ===
                  creationTypeConstants.DEPLOY_VM_FROM_VMTX;
         }
      };
   }
})();
