/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
(function () {
   'use strict';

   angular
         .module('com.vmware.vsphere.client.licenseLibUi')
         .service(
               'licenseCommonUtil',
               licenseCommonUtil
         );

   licenseCommonUtil.$inject = ['licenseConstants', 'defaultUriSchemeUtil'];

   function licenseCommonUtil(licenseConstants, defaultUriSchemeUtil) {
      var ValidationMessageType = {
         TEXT: 'TEXT',
         OK: 'OK',
         INFO: 'INFO',
         WARNING: 'WARNING',
         ALERT: 'ALERT'
      };

      var ValidationMessageDetailsType = {
         TEXT: 'TEXT',
         TEXT_AND_LIST: 'TEXT_AND_LIST'
      };

      function getAggregatedCostUnitUsage(licenseRef /*URI as String*/,
            productRef /*URI as String*/,
            costUnitId /*String*/,
            assignLicenseAggregatedUsages /*AssignLicenseAggregatedUsages*/) {

         var licenseRefToLicenseUsages = null;
         if (assignLicenseAggregatedUsages) {
            licenseRefToLicenseUsages =
                  assignLicenseAggregatedUsages.licenseRefToLicenseUsages;
         }
         var aggregatedLicenseUsages = null;
         if (licenseRef &&
               licenseRefToLicenseUsages &&
               licenseRefToLicenseUsages[licenseRef]) {
            aggregatedLicenseUsages = licenseRefToLicenseUsages[licenseRef];
         }

         var aggregatedProductUsages = null;
         if (productRef &&
               aggregatedLicenseUsages &&
               aggregatedLicenseUsages.productRefToProductUsages &&
               aggregatedLicenseUsages.productRefToProductUsages[productRef]) {
            aggregatedProductUsages =
                  aggregatedLicenseUsages.productRefToProductUsages[productRef];
         }

         var aggregatedCostUnitUsage = null;
         if (costUnitId &&
               aggregatedProductUsages &&
               aggregatedProductUsages.costUnitIdToCostUnitUsage &&
               aggregatedProductUsages.costUnitIdToCostUnitUsage[costUnitId]) {
            aggregatedCostUnitUsage =
                  aggregatedProductUsages.costUnitIdToCostUnitUsage[costUnitId];
         }

         return aggregatedCostUnitUsage;
      }

      function getAssetUnsupportedFeatures(licenseRef /*URI as String*/,
            assetRef /*URI as String*/,
            unsupportedFeatures /*AssignLicenseUnsupportedFeatures*/) {

         var result = {
            unsupportedFeaturesInUse: [],
            unsupportedAvailableFeatures: []
         };

         if (!unsupportedFeatures) {
            return result;
         }

         if (!unsupportedFeatures.licenseRefToUnsupportedFeatures) {
            return result;
         }

         var unsupportedLicenseFeatures =
               unsupportedFeatures.licenseRefToUnsupportedFeatures[licenseRef];
         if (!unsupportedLicenseFeatures) {
            return result;
         }

         var assetRefToUnsupportedFeaturesInUse = {};
         if (!_.isEmpty(unsupportedLicenseFeatures.assetRefToUnsupportedFeaturesInUse)) {
            assetRefToUnsupportedFeaturesInUse =
                  unsupportedLicenseFeatures.assetRefToUnsupportedFeaturesInUse;
         }

         var assetRefToUnsupportedAvailableFeatures = {};
         if (!_.isEmpty(unsupportedLicenseFeatures.assetRefToUnsupportedAvailableFeatures)) {
            assetRefToUnsupportedAvailableFeatures =
                  unsupportedLicenseFeatures.assetRefToUnsupportedAvailableFeatures;
         }

         if (!_.isEmpty(assetRefToUnsupportedFeaturesInUse[assetRef])) {
            result.unsupportedFeaturesInUse =
                  assetRefToUnsupportedFeaturesInUse[assetRef];
         }

         if (!_.isEmpty(assetRefToUnsupportedAvailableFeatures[assetRef])) {
            result.unsupportedAvailableFeatures =
                  assetRefToUnsupportedAvailableFeatures[assetRef];
         }

         return result;
      }

      function isUnlimitedCapacityCostUnit(costUnit /*CostUnit*/) /*boolean*/ {
         return costUnit.limit === 0;
      }

      function isEoemLicense(license /*License*/) /*boolean*/ {
         return !!license.embeddedOem;
      }

      function isEvaluationLicense(license /*License*/) /*boolean*/ {
         return !!license.isEvaluation;
      }

      function isPermanentLicense(license /*License*/,
            validationResult /*AssetAssignLicenseValidationResult*/) /*boolean*/ {
         return getLicenseExpirationRemainingDays(license, validationResult) ===
               Number.POSITIVE_INFINITY;
      }

      function isExpiredLicense(license /*License*/,
            validationResult /*AssetAssignLicenseValidationResult*/) /*boolean*/ {
         return getLicenseExpirationRemainingDays(license, validationResult) <= 0;
      }

      function isExpiringLicense(license /*License*/,
            validationResult /*AssetAssignLicenseValidationResult*/) /*boolean*/ {
         return getLicenseExpirationRemainingDays(license, validationResult) <=
               licenseConstants.LICENSE_EXPIRATION_MAX_WARNING_DAYS_REMAINING;
      }

      function isUnassignableEoemLicense(license /*License*/,
            validationResult /*AssetAssignLicenseValidationResult*/) /*boolean*/ {

         var licenseAssignInfo = getLicenseAssignInfo(license, validationResult);
         return _.contains(
               licenseAssignInfo.assignRestrictions,
               licenseConstants.LicenseAssignRestriction.EmbeddedOemLicense
         );
      }

      function isLicenseFromAnotherVc(license/*License*/,
            validationResult /*AssetAssignLicenseValidationResult*/) {
         if (validationResult.licenseFromAnotherVcRef === null) {
            return false;
         }

         return validationResult.licenseFromAnotherVcRef === license.uri;
      }

      function getAssignableLicenses(validationResult /*AssetAssignLicenseValidationResult*/) /*Array.<License>*/ {
         if (validationResult) {
            return _.map(validationResult.licensesAssignInfos,
                  function(license) {
                     return license.license;
                  });
         } else {
            return [];
         }
      }

      function getPreSelectedLicense(validationResult /*AssetAssignLicenseValidationResult*/,
            userSelectedLicense /*License*/) /*License*/ {

         var assignableLicenses = getAssignableLicenses(validationResult);

         userSelectedLicense = _.find(assignableLicenses, function(license) {
            return license.uri === getLicenseUri(userSelectedLicense);
         });
         if (userSelectedLicense) {
            return userSelectedLicense;
         }

         if (validationResult && validationResult.assets && validationResult.assets.length > 1 &&
               getLicenseUri(validationResult.assets[0].license) !==
               getLicenseUri(validationResult.assets[1].license)) {
            return null;
         }

         var assignedLicense = _.find(assignableLicenses, function(license) {
            return license.uri === getLicenseUri(validationResult.assets[0].license);
         });
         if (assignedLicense) {
            return assignedLicense;
         }

         if (assignableLicenses.length > 0) {
            return assignableLicenses[0];
         }

         return null;
      }

      function normalizeValidationResult(validationResult /*AssetAssignLicenseValidationResult*/) /*void*/ {

         var asset = validationResult.assets[0];
         var assignableLicenses = getAssignableLicenses(validationResult);

         var assignableEvaluationLicenses =
               _.filter(assignableLicenses, function(license) {
                  return isEvaluationLicense(license);
               });

         var assignableEvaluationLicense = null;
         if (assignableEvaluationLicenses.length === 1) {
            assignableEvaluationLicense = assignableEvaluationLicenses[0];
         }

         if (asset.evaluationLicense && !getLicenseUri(asset.evaluationLicense)) {
            if (getLicenseUri(assignableEvaluationLicense)) {
               asset.evaluationLicense.uri = assignableEvaluationLicense.uri;
            } else {
               asset.evaluationLicense.uri = getDefaultEvaluationLicenseUri();
            }
         }

         if (!getLicenseUri(asset.license) && isEvaluationLicense(asset.license)) {
            if (getLicenseUri(assignableEvaluationLicense)) {
               asset.license.uri = assignableEvaluationLicense.uri;
            } else {
               asset.license.uri = getDefaultEvaluationLicenseUri();
            }
         }
      }

      function getDefaultEvaluationLicenseUri() {
         return defaultUriSchemeUtil.createUri(
               licenseConstants.LICENSE_TYPE,
               licenseConstants.EVALUATION_LICENSE_ID
         );
      }

      function getLicenseUri(license) {
         if (license) {
            return license.uri;
         }

         return null;
      }

      function getLicenseAssignInfo(license /*Licence*/,
            validationResult /*AssetAssignLicenseValidationResult*/) /*Array.<License>*/ {
         return _.find(validationResult.licensesAssignInfos,
               function(licenseAssignInfo) {
                  return licenseAssignInfo.license.uri === license.uri;
               });
      }

      function getLicenseExpirationRemainingDays(license /*Licence*/,
            validationResult /*AssetAssignLicenseValidationResult*/) /*Number*/ {

         var licenseAssignInfo = getLicenseAssignInfo(license, validationResult);
         if (_.contains(licenseAssignInfo.assignRestrictions,
                     licenseConstants.LicenseAssignRestriction.ExpiredLicense)) {
            return 0;
         }

         var expirationTime = license.expirationTime;
         var expirationDate = license.expirationDate;

         if (expirationDate === null) {
            // Never expires
            if (expirationTime === null) {
               return Number.POSITIVE_INFINITY;
            } else if (expirationTime === 0) {
               return 0;
            }

            throw new Error('Can\'t calculate remaining days');
         }

         var nowMoment = moment();
         var expirationMoment = moment(expirationDate);
         if (expirationMoment.diff(nowMoment, 'milliseconds') <= 0) {
            return 0;
         }

         var days = Math.round(expirationMoment.diff(nowMoment, 'days', true));
         // Days are rounded so if there are less than 12 hours remaining, then days
         // will be 0 so make it to 1.
         if (days === 0) {
            days = 1;
         }

         return days;
      }

      function UsageValidationItem(product/*Product*/, costUnits/*Array.<CostUnit>*/) {
         this.product = product;
         this.costUnits = costUnits;
      }

      function ValidationMessage(id, type, message, details) {
         this.id = id;
         this.type = type;
         this.message = message;
         this.details = details;
      }

      function ValidationMessageDetailsBase(type) {
         this.type = type;
      }

      function ValidationMessageTextDetails(text) {
         ValidationMessageDetailsBase.call(this, ValidationMessageDetailsType.TEXT);
         this.text = text;
      }

      ValidationMessageTextDetails.prototype =
            _.create(ValidationMessageDetailsBase.prototype);
      ValidationMessageTextDetails.prototype.constructor = ValidationMessageTextDetails;

      function ValidationMessageTextAndListDetails(text, list) {
         ValidationMessageDetailsBase.call(
               this,
               ValidationMessageDetailsType.TEXT_AND_LIST
         );
         this.text = text;
         this.list = list;
      }

      ValidationMessageTextAndListDetails.prototype =
            _.create(ValidationMessageDetailsBase.prototype);
      ValidationMessageTextAndListDetails.prototype.constructor =
            ValidationMessageTextAndListDetails;

      return {
         /* Methods */
         getAggregatedCostUnitUsage: getAggregatedCostUnitUsage,
         isUnlimitedCapacityCostUnit: isUnlimitedCapacityCostUnit,
         isEoemLicense: isEoemLicense,
         isEvaluationLicense: isEvaluationLicense,
         isPermanentLicense: isPermanentLicense,
         isExpiredLicense: isExpiredLicense,
         isExpiringLicense: isExpiringLicense,
         isUnassignableEoemLicense: isUnassignableEoemLicense,
         isLicenseFromAnotherVc: isLicenseFromAnotherVc,
         getAssignableLicenses: getAssignableLicenses,
         getPreSelectedLicense: getPreSelectedLicense,
         normalizeValidationResult: normalizeValidationResult,
         getDefaultEvaluationLicenseUri: getDefaultEvaluationLicenseUri,
         getLicenseUri: getLicenseUri,
         getLicenseExpirationRemainingDays: getLicenseExpirationRemainingDays,
         getAssetUnsupportedFeatures: getAssetUnsupportedFeatures,
         /* Classes */
         UsageValidationItem: UsageValidationItem,
         ValidationMessage: ValidationMessage,
         ValidationMessageDetailsBase: ValidationMessageDetailsBase,
         ValidationMessageTextDetails: ValidationMessageTextDetails,
         ValidationMessageTextAndListDetails: ValidationMessageTextAndListDetails,
         /* Enums */
         ValidationMessageType: ValidationMessageType,
         ValidationMessageDetailsType: ValidationMessageDetailsType
      };
   }
}());
