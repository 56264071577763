module ds_cluster_ui {

   class AddSdrsVmOverridesController {

      private objectId: string;
      private splitterOptions: Object;
      private spec: SdrsVmOverrideSpec;
      private selectedVms: any;

      public static $inject = ["$scope",
         "datagridActionBarService", "dataService",
         "i18nService", "dsClusterConstants", "mutationService",
         "addSdrsVmOverridesService", "addVmOverridesPageService",
         "storageVmOverridesService", "vuiConstants"];

      constructor(private $scope: any,
            private datagridActionBarService: any,
            private dataService: any,
            private i18nService: any,
            private dsClusterConstants: any,
            private mutationService: any,
            private addSdrsVmOverridesService: AddSdrsVmOverridesService,
            private addVmOverridesPageService: AddVmOverridesPageService,
            private storageVmOverridesService: StorageVmOverridesService,
            private vuiConstants:any) {

         this.objectId = $scope._route.objectId;
         this.splitterOptions = this.addSdrsVmOverridesService.getSplitterOptions();
         this.spec = {} as SdrsVmOverrideSpec;
         this.selectedVms = [];

         if ($scope.modalOptions.dialogData.mode ===
               this.dsClusterConstants.vmOverridesOperationType.EDIT) {
            this.selectedVms = $scope.modalOptions.dialogData.context;
            this.spec.mode = this.dsClusterConstants.vmOverridesOperationType.EDIT;
         } else {
            this.spec.mode = this.dsClusterConstants.vmOverridesOperationType.ADD;
         }

         $scope.modalOptions.onSubmit = () => {
            this.$scope.modalOptions.isAlertClosed = true;
            this.$scope.modalOptions.alerts = [];

            if (this.spec.keepVmdksChecked || this.spec.sdrsAutomationLevel
                  || this.spec.mode === this.dsClusterConstants.vmOverridesOperationType.EDIT) {

               let keepVmdksValue: string = this.spec.keepVmdksChecked ?
                     this.spec.keepVmdksValue :
                     this.dsClusterConstants.keepVmdksValues.clusterDefault;

               let sdrsAutomationLevelValue: string = this.spec.sdrsAutomationLevel ?
                     this.spec.sdrsAutomationLevelValue :
                     this.dsClusterConstants.sdrsVmOverridesAutomationLevels.clusterDefault;

               let addOverrideSpec: any = this.addVmOverridesPageService
                   .getOverrideSpec(this.selectedVms,
                       sdrsAutomationLevelValue, keepVmdksValue, this.spec.mode);

               this.mutationService.validate(this.objectId,
                   this.dsClusterConstants.SDRS_VM_OVERRIDE_SPEC,
                   addOverrideSpec).then((response: any) => {
                  if (response && response.result && response.result.length > 0) {
                     let alert: Object = this.storageVmOverridesService
                         .getVmOverrideErrorMessages(response.result);
                     this.$scope.modalOptions.alerts = [alert];
                     this.$scope.modalOptions.isAlertClosed = false;
                  } else {
                     this.mutationService.apply(this.objectId,
                         this.dsClusterConstants.SDRS_VM_OVERRIDE_SPEC,
                         addOverrideSpec);
                     this.$scope.closeModal();
                  }
               });
            } else {
               this.$scope.modalOptions.alerts = [{
                  text: this.i18nService.getString("DsClusterUi",
                        "vm.overrides.config.noChangesValidationError"),
                  type: this.vuiConstants.validationBanner.type.ERROR
               }];
               this.$scope.modalOptions.isAlertClosed = false;
            }

            return false;
         };
      }
   }

   angular.module("com.vmware.vsphere.client.dsCluster")
         .controller("AddSdrsVmOverridesController", AddSdrsVmOverridesController);
}
