namespace h5_vm {
   import ManagedObjectReference = com.vmware.vim.binding.vmodl.ManagedObjectReference;
   import DrsFaults$FaultsByVm = com.vmware.vim.binding.vim.cluster.DrsFaults$FaultsByVm;
   import DrsFaults = com.vmware.vim.binding.vim.cluster.DrsFaults;

   export interface FormattedFault {
      message: string;
      vmId?: string;
      vmName?: string;
      hostName?: string;
   }

   export class VmPlacementFaultFormatter {
      public static $inject = [
            "$q",
            "i18nService",
            "defaultUriSchemeUtil",
            "dataService"
      ];

      private static PROPERTY_NAME: string = "name";
      private static PROPERTY_HOSTNAME: string = "hostName";

      constructor(
            private $q: angular.IQService,
            private i18nService: any,
            private defaultUriSchemeUtil: any,
            private dataService: any
      ) {}

      public formatFaults(drsFaults: DrsFaults$FaultsByVm[])
            : angular.IPromise<FormattedFault[]> {
         if (_.isEmpty(drsFaults)) {
            return this.$q.when([]);
         }
         let vms: any = [];
         _.each(drsFaults, (vmFault: DrsFaults$FaultsByVm) => {
            this.pushIfNotPresent(vms, vmFault.vm);
         });

         let dataRequest = this.dataService.getPropertiesForObjects(vms, [
               VmPlacementFaultFormatter.PROPERTY_NAME,
               VmPlacementFaultFormatter.PROPERTY_HOSTNAME]);

         return this.$q.when(dataRequest)
            .then((vmData: any) => {
               let result: FormattedFault[] = [];
               _.each(drsFaults, (vmFault: DrsFaults$FaultsByVm) => {
                  _.each(vmFault.fault, (fault: any) => {
                     result.push(
                           this.newFormattedFault(fault.message, vmFault.vm, vmData));
                  });
               });
               return _.sortBy(result, (fault: FormattedFault) => fault.vmName);
            });
      }

      private newFormattedFault(
            message: string, vm: ManagedObjectReference, vmData: any): FormattedFault {
         let vmName: string = "";
         let hostName: string = "";
         let vmId: string =  this.toId(vm);
         if (vmId && vmData[vmId]) {
            vmName = vmData[vmId][VmPlacementFaultFormatter.PROPERTY_NAME];
            hostName = vmData[vmId][VmPlacementFaultFormatter.PROPERTY_HOSTNAME];
         }

         return {
            vmId: vmId,
            message: message,
            vmName: vmName,
            hostName: hostName
         };
      };

      private pushIfNotPresent(entityIds: string[], ref: ManagedObjectReference): void {
         if (ref && entityIds) {
            if (entityIds.indexOf(this.toId(ref)) < 0) {
               entityIds.push(this.toId(ref));
            }
         }
      }

      private toId(ref: ManagedObjectReference): string {
         return ref ? this.defaultUriSchemeUtil.getVsphereObjectId(ref) : undefined;
      }
   }

   angular
         .module("com.vmware.vsphere.client.vm")
         .service("vmPlacementFaultFormatter", VmPlacementFaultFormatter);
}
