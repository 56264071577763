/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
(function() {
   'use strict';

   angular
         .module('com.vmware.vsphere.client.networkLibUi')
         .service('distributedPortgroupPoliciesViewService', distributedPortgroupPoliciesViewService);

   distributedPortgroupPoliciesViewService.$inject = [
      'i18nService',
      'vxPropertyViewService',
      'networkUiConstants'
   ];

   var loadBalancingIpHash = "loadbalance_ip";

   function distributedPortgroupPoliciesViewService (
         i18nService,
         vxPropertyViewService,
         networkUiConstants) {

      return {
         build: build,
         buildDistributedPortPoliciesView: buildDistributedPortPoliciesView
      };

      function buildDistributedPortPoliciesView(portPolicy, builder) {
         return _buildInternal(portPolicy, builder, true);
      }

      function build(portPolicy, builder) {
         return _buildInternal(portPolicy, builder);
      }

      function _buildInternal(portPolicy, builder, isSinglePortContext) {
         if (!portPolicy) {
            return {};
         }

         var policiesBuilder = builder || vxPropertyViewService.createPropertyViewBuilder();
         var policiesCategory = policiesBuilder
               .category('policies')
               .title(i18nService.getString('NetworkUi', 'dvpg.manage.settings.policiesHeader'));

         // Security
         if (portPolicy.security) {
            var securitySection = policiesCategory
                  .section('security')
                  .title(i18nService.getString('NetworkUi', 'dvpg.policy.security.title'));

            var acceptValue = i18nService.getString('NetworkUi', 'dvpg.policy.security.accept');
            var rejectValue = i18nService.getString('NetworkUi', 'dvpg.policy.security.reject');

            securitySection.property(
                  i18nService.getString('NetworkUi', 'dvpg.policy.security.promiscuousMode'),
                  portPolicy.security.allowPromiscuous ? acceptValue : rejectValue);

            securitySection.property(
                  i18nService.getString('NetworkUi', 'dvpg.policy.security.macAddressChanges'),
                  portPolicy.security.macChanges ? acceptValue : rejectValue);

            securitySection.property(
                  i18nService.getString('NetworkUi', 'dvpg.policy.security.forgedTransmits'),
                  portPolicy.security.forgedTransmits ? acceptValue : rejectValue);
         }

         // Ingress traffic shaping
         if (portPolicy.inTrafficShaping) {
            var ingressTrafficShapingSection = policiesCategory
                  .section("ingressTrafficShaping")
                  .title(i18nService.getString('NetworkUi', 'dvpg.policy.shaping.ingress'));

            ingressTrafficShapingSection.property(
                  i18nService.getString('NetworkUi', 'dvpg.policy.shaping.status'),
                  portPolicy.inTrafficShaping.enabled
                        ? i18nService.getString('NetworkUi', 'dvpg.policy.shaping.status.on')
                        : i18nService.getString('NetworkUi', 'dvpg.policy.shaping.status.off')
            );

            ingressTrafficShapingSection.property(
                  i18nService.getString('NetworkUi', 'dvpg.policy.shaping.avgBW'),
                  replaceEmptyString(portPolicy.inTrafficShaping.averageBandwidthSummary));

            ingressTrafficShapingSection.property(
                  i18nService.getString('NetworkUi', 'dvpg.policy.shaping.peakBW'),
                  replaceEmptyString(portPolicy.inTrafficShaping.peakBandwidthSummary));

            ingressTrafficShapingSection.property(
                  i18nService.getString(
                        'NetworkUi', 'dvpg.policy.shaping.burstSize'),
                  replaceEmptyString(portPolicy.inTrafficShaping.burstSizeSummary));
         }

         // Egress traffic shaping
         if (portPolicy.outTrafficShaping) {
            var egressTrafficShapingSection = policiesCategory
                  .section("egressTrafficShaping")
                  .title(i18nService.getString('NetworkUi', 'dvpg.policy.shaping.egress'));

            egressTrafficShapingSection.property(
                  i18nService.getString('NetworkUi', 'dvpg.policy.shaping.status'),
                  portPolicy.outTrafficShaping.enabled
                        ? i18nService.getString('NetworkUi', 'dvpg.policy.shaping.status.on')
                        : i18nService.getString('NetworkUi', 'dvpg.policy.shaping.status.off')
            );

            egressTrafficShapingSection.property(
                  i18nService.getString('NetworkUi', 'dvpg.policy.shaping.avgBW'),
                  replaceEmptyString(portPolicy.outTrafficShaping.averageBandwidthSummary));

            egressTrafficShapingSection.property(
                  i18nService.getString('NetworkUi', 'dvpg.policy.shaping.peakBW'),
                  replaceEmptyString(portPolicy.outTrafficShaping.peakBandwidthSummary));

            egressTrafficShapingSection.property(
                  i18nService.getString(
                        'NetworkUi', 'dvpg.policy.shaping.burstSize'),
                  replaceEmptyString(portPolicy.outTrafficShaping.burstSizeSummary));
         }

         // Vlan
         if (portPolicy.vlan) {
            var vlanSection = policiesCategory
                  .section("vlan")
                  .title(i18nService.getString('NetworkUi', 'dvpg.policy.vlan.title'));

            switch (portPolicy.vlan.type) {
               case networkUiConstants.Vlan.Type.NONE:
                  vlanSection.property(
                        i18nService.getString('NetworkUi', 'dvpg.policy.vlan.type.label'),
                        i18nService.getString('NetworkUi', 'dvpg.policy.vlan.type.none'));
                  break;
               case networkUiConstants.Vlan.Type.VLAN:
                  vlanSection.property(
                        i18nService.getString('NetworkUi', 'dvpg.policy.vlan.type.label'),
                        i18nService.getString('NetworkUi', 'dvpg.policy.vlan.type.vlan'));
                  vlanSection.property(
                        i18nService.getString('NetworkUi', 'dvpg.policy.vlan.id.label'),
                        portPolicy.vlan.value);
                  break;
               case networkUiConstants.Vlan.Type.TRUNK:
                  vlanSection.property(
                        i18nService.getString('NetworkUi', 'dvpg.policy.vlan.type.label'),
                        i18nService.getString('NetworkUi', 'dvpg.policy.vlan.type.trunk'));
                  vlanSection.property(
                        i18nService.getString('NetworkUi', 'dvpg.policy.vlan.trunk.label'),
                        portPolicy.vlan.value);
                  break;
               case networkUiConstants.Vlan.Type.PRIVATE:
                  vlanSection.property(
                        i18nService.getString('NetworkUi', 'dvpg.policy.vlan.type.label'),
                        i18nService.getString('NetworkUi', 'dvpg.policy.vlan.type.private'));
                  vlanSection.property(
                        i18nService.getString('NetworkUi', 'dvpg.policy.vlan.private.label'),
                        portPolicy.vlan.value);
                  break;
            }
         }

         // LACP
         if (portPolicy.basicLacpAvailable) {
            var lacpSection = policiesCategory
                  .section("lacp")
                  .title(i18nService.getString(
                        'NetworkUi', 'dvpg.policy.lacp.title'));

            if (portPolicy.lacp.enable) {
               lacpSection.property(
                     i18nService.getString('NetworkUi', 'dvpg.policy.lacp.status.label'),
                     i18nService.getString('NetworkUi', 'dvpg.policy.lacp.status.enabled'));

               lacpSection.property(
                     i18nService.getString('NetworkUi', 'dvpg.policy.lacp.status.mode'),
                     portPolicy.lacp.mode === networkUiConstants.LacpMode.ACTIVE
                           ? i18nService.getString('NetworkUi', 'dvpg.policy.lacp.status.active')
                           : i18nService.getString('NetworkUi', 'dvpg.policy.lacp.status.passive'));
            } else {
               lacpSection.property(
                     i18nService.getString('NetworkUi', 'dvpg.policy.lacp.status.label'),
                     i18nService.getString('NetworkUi', 'dvpg.policy.lacp.status.disabled'));
            }
         }

         // Teaming and failover
         if (portPolicy.teamingAndFailover) {
            var teamingAndFailover = portPolicy.teamingAndFailover;
            var teamingSection = policiesCategory
                  .section("teaming")
                  .title(i18nService.getString(
                        'NetworkUi', 'dvpg.policy.failover.title'));

            teamingSection.property(
                  i18nService.getString('NetworkUi', 'dvpg.policy.failover.lb.label'),
                  getLoadBalancingPolicyValue(teamingAndFailover.loadBalancing));

            if (teamingAndFailover.loadBalancing === loadBalancingIpHash) {
               var warningMessage = getLoadBalancingWarningMessage(teamingAndFailover);
               if (warningMessage) {
                  teamingSection.warning(warningMessage);
               }
               getLoadBalancingInfoMessages().forEach(function(message) {
                  teamingSection.info(message);
               });
            }

            teamingSection.property(
                  i18nService.getString('NetworkUi', 'dvpg.policy.failover.detection.label'),
                  teamingAndFailover.failureDetection ?
                        i18nService.getString('NetworkUi', 'dvpg.policy.failover.detection.beaconProbing') :
                        i18nService.getString('NetworkUi', 'dvpg.policy.failover.detection.linkStatus'));

            teamingSection.property(
                  i18nService.getString('NetworkUi', 'dvpg.policy.failover.notify.label'),
                  teamingAndFailover.notifySwitches ?
                        i18nService.getString('NetworkUi', 'dvpg.policy.failover.notify.on') :
                        i18nService.getString('NetworkUi', 'dvpg.policy.failover.notify.off'));

            teamingSection.property(
                  i18nService.getString('NetworkUi', 'dvpg.policy.failover.failback.label'),
                  teamingAndFailover.failback ?
                        i18nService.getString('NetworkUi', 'dvpg.policy.failover.failback.on') :
                        i18nService.getString('NetworkUi', 'dvpg.policy.failover.failback.off'));

            teamingSection.property(
                  i18nService.getString('NetworkUi', 'dvpg.policy.failover.order.active.label'),
                  replaceEmptyString(teamingAndFailover.activeAdapters.join(", ")));

            teamingSection.property(
                  i18nService.getString('NetworkUi', 'dvpg.policy.failover.order.standby.label'),
                  replaceEmptyString(teamingAndFailover.standByAdapters.join(", ")));

            teamingSection.property(
                  i18nService.getString('NetworkUi', 'dvpg.policy.failover.order.unused.label'),
                  replaceEmptyString(teamingAndFailover.unusedAdapters.join(", ")));
         }

         // Monitoring
         if (portPolicy.monitoring !== null && typeof portPolicy.monitoring !== 'undefined') {
            var monitoringSection = policiesCategory
                  .section("monitoring")
                  .title(i18nService.getString('NetworkUi', 'dvpg.policy.monitoring.title'));

            monitoringSection.property(
                  i18nService.getString('NetworkUi', 'dvpg.policy.monitoring.status.label'),
                  portPolicy.monitoring
                        ? i18nService.getString('NetworkUi', 'dvpg.policy.monitoring.status.on')
                        : i18nService.getString('NetworkUi', 'dvpg.policy.monitoring.status.off')
            );
         }

         // Miscellaneous
         if (portPolicy.blocked !== null && typeof portPolicy.blocked !== 'undefined') {
            var miscSection = policiesCategory
                  .section("miscellaneous")
                  .title(i18nService.getString('NetworkUi', 'dvpg.policy.misc.title'));

            miscSection.property(
                  isSinglePortContext
                        ? i18nService.getString('NetworkUi', 'port.policy.misc.block.label')
                        : i18nService.getString('NetworkUi', 'dvpg.policy.misc.block.label'),
                  portPolicy.blocked
                        ? i18nService.getString('NetworkUi', 'dvpg.policy.misc.block.on')
                        : i18nService.getString('NetworkUi', 'dvpg.policy.misc.block.off'));
         }

         if (!builder) {
            return policiesBuilder.build();
         }
      }

      function replaceEmptyString(value) {
         return value
               ? value
               : i18nService.getString('NetworkUi', 'VnicPortPropertiesView.noData');
      }

      function getLoadBalancingInfoMessages() {
         return [
            i18nService.getString('NetworkUi', "PortPolicyData.failover.lb.loadbalance_ip.info.etherChannel"),
            i18nService.getString('NetworkUi', "PortPolicyData.failover.lb.loadbalance_ip.info.portGroupPolicies")
         ];
      }

      function getLoadBalancingWarningMessage(teamingAndFailover) {
         if (teamingAndFailover.standByAdapters.length !== 0 && teamingAndFailover.failureDetection) {
            return i18nService.getString('NetworkUi', "PortPolicyData.failover.lb.loadbalance_ip.warn.beaconProbingAndStandbyUplinks");
         } else if (teamingAndFailover.standByAdapters.length !== 0) {
            return i18nService.getString('NetworkUi', "PortPolicyData.failover.lb.loadbalance_ip.warn.standbyUplinks");
         } else if (teamingAndFailover.failureDetection) {
            return i18nService.getString('NetworkUi', "PortPolicyData.failover.lb.loadbalance_ip.warn.beaconProbing");
         } else {
            return null;
         }
      }

      function getLoadBalancingPolicyValue(policy) {
         switch (policy) {
            case networkUiConstants.LoadBalancingPolicy.SRCID:
               return i18nService.getString(
                     'NetworkUi', 'PortFailoverPolicyView.loadbalance_srcid');
            case networkUiConstants.LoadBalancingPolicy.IP:
               return i18nService.getString(
                     'NetworkUi', 'PortFailoverPolicyView.loadbalance_ip');
            case networkUiConstants.LoadBalancingPolicy.SRCMAC:
               return i18nService.getString(
                     'NetworkUi', 'PortFailoverPolicyView.loadbalance_srcmac');
            case networkUiConstants.LoadBalancingPolicy.EXPLICIT:
               return i18nService.getString(
                     'NetworkUi', 'PortFailoverPolicyView.failover_explicit');
            case networkUiConstants.LoadBalancingPolicy.LOADBASED:
               return i18nService.getString(
                     'NetworkUi', 'PortFailoverPolicyView.loadbalance_loadbased');
            default:
               return i18nService.getString('NetworkUi', 'VnicPortPropertiesView.noData');
         }
      }
   }
})();
