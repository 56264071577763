namespace h5_vm {

   import any = jasmine.any;
   import VirtualDevice = com.vmware.vim.binding.vim.vm.device.VirtualDevice;
   import PciPassthroughInfo = com.vmware.vim.binding.vim.vm.PciPassthroughInfo;
   import VirtualDevice$BackingInfo = com.vmware.vim.binding.vim.vm.device.VirtualDevice$BackingInfo;
   import ConfigTarget = com.vmware.vim.binding.vim.vm.ConfigTarget;
   import PciSharedGpuPassthroughInfo = com.vmware.vim.binding.vim.vm.PciSharedGpuPassthroughInfo;
   import VirtualPCIPassthrough = com.vmware.vim.binding.vim.vm.device.VirtualPCIPassthrough;
   import VirtualPCIPassthrough$VmiopBackingInfo = com.vmware.vim.binding.vim.vm.device.VirtualPCIPassthrough$VmiopBackingInfo;
   import VirtualPCIPassthrough$DeviceBackingInfo = com.vmware.vim.binding.vim.vm.device.VirtualPCIPassthrough$DeviceBackingInfo;
   import VirtualPCIPassthroughOption = com.vmware.vim.binding.vim.vm.device.VirtualPCIPassthroughOption;

   export class VmHwPciDevice extends h5_vm.EditableVirtualDeviceClass {

      private target: Array<PciPassthroughInfo> = [];
      private choice: Array<any> = [];
      private backing: VirtualDevice$BackingInfo;
      private rawDevice: VirtualPCIPassthrough;
      private option: VirtualPCIPassthroughOption|null = null;

      constructor(original: VirtualPCIPassthrough, deviceDiffService: any, vmDeviceInfoService: any, vmHardwareUtil: any) {
         super(original, deviceDiffService, vmDeviceInfoService, vmHardwareUtil);
         this.rawDevice = super.getCurrentDevice();
         this.backing = this.rawDevice.backing;
         this.target = [];
      }

      private _gpuProfileInfoList: Array<PciSharedGpuPassthroughInfo> = [];

      protected onVmConfigAttached(): void {
         if (this.vm === null) {
            return;
         }
         this.option = this.vm.getPciPassthoughOption();
         this.rawDevice = super.getCurrentDevice();
         this.backing = this.rawDevice.backing;
         this._gpuProfileInfoList = this.vm.getGpuProfileList();
         this.target = this.vm.getPciPassList();
         this.buildSharedGpuList();
         this.choice = this.buildDeviceList();
         this._deviceValue = this.findHostPciDevice();
         return;
      }

      private buildDeviceList(): Array<any> {

         if (this.isSharedPCIBackingType()) {
            return [];
         }

         let list: Array<any> = [];

         if (!this.target) {
            this.target = [];
            return [];
         }

         for (let info of this.target) {
            //let label = info.pciDevice.deviceName;
            let label = this.info2text(info);
            let key = info.pciDevice.id;
            let value = info;
            let item = {label: label, value: value, key: key};
            list.push(item);
         }
         return list;

      }

      // Shared PCI part.

      private buildSharedGpuList(): void {


         if (!this.isSharedPCIBackingType()) {
            return;
         }

         let currentValue: string = this.sharedGpuValue;
         let retVal: Array<string> = [];

         if (!this._gpuProfileInfoList) {
            this._gpuProfileInfoList = [];
         }

         for (let item of this._gpuProfileInfoList) {
            if (item) {
               let vgpu: string = item.vgpu;
               retVal.push(vgpu);
            }
         }

         if (retVal.indexOf(currentValue) < 0) {
            retVal.push(currentValue);
         }

         this._sharedGpuList = retVal;
      }

      private _sharedGpuList: Array<string> = [];

      public get sharedGpuList(): Array<string> {
         return this._sharedGpuList;
      }

      public get sharedGpuListIsEmpty(): boolean {

         if (!this._gpuProfileInfoList) {
            return true;
         }
         if (this._gpuProfileInfoList.length === 0) {
            return true;
         }
         return false;
      }

      public get sharedGpuValue(): string {
         let vmiopBacking: VirtualPCIPassthrough$VmiopBackingInfo = this.backing as VirtualPCIPassthrough$VmiopBackingInfo;
         if (!vmiopBacking) {
            return "";
         }
         return vmiopBacking.vgpu;
      }

      public set sharedGpuValue(value: string) {
         let vmiopBacking: VirtualPCIPassthrough$VmiopBackingInfo = this.backing as VirtualPCIPassthrough$VmiopBackingInfo;
         if (!vmiopBacking) {
            return;
         }
         vmiopBacking.vgpu = value;
      }

      public sharedGpuValue_isReadonly(): boolean {
         if (!this.vm) {
            // optimistically allow editing if somehow missing data
            return false;
         }

         return this.vm.isEditDeviceDisabled();
      }


      public deviceList(): Array<any> {
         let retVal: Array<any> = this.choice;

         return retVal;
      }

      _deviceValue: PciPassthroughInfo|null = null;

      public get deviceValue(): any {
         return this._deviceValue;
      }

      public set deviceValue(item: any) {
         this._deviceValue = item;
         if (item) {
            PciDeviceService.info2backing(item.value, this.backing  as VirtualPCIPassthrough$DeviceBackingInfo);
         }
      }

      public deviceValue_isReadonly(): boolean {
         if (this.isNew()) {
            return false;
         } else {
            return true;
         }
      }

      private findHostPciDevice(): any {

         const pciBacking: VirtualPCIPassthrough$DeviceBackingInfo = this.backing as VirtualPCIPassthrough$DeviceBackingInfo;

         for (let item of this.choice) {
            let info: PciPassthroughInfo = item.value as PciPassthroughInfo;
            let deviceId: any = (this.backing as VirtualPCIPassthrough$DeviceBackingInfo).id;
            if (info.pciDevice.id === deviceId) {
               return item;
            }
         }

         let unavailable:string = "Unavailable(" + pciBacking.id +  ")";

         const retVal:any = {label: unavailable, value: null, key: -1};
         this.choice.push(retVal);
         return retVal;
      }

      public isSharedPCIBackingType(): boolean {

         let backing: VirtualDevice$BackingInfo = super.getCurrentDevice().backing;
         let type: string = backing._type;

         if (type && type.toUpperCase().indexOf("VMIOP") > -1) {
            return true;
         } else {
            return false;
         }
      }

      public memoryUnderReserved(): boolean {
         if (!this.vm) {
            return false;
         }
         let retVal: boolean = this.vm.memoryUnderReserved();
         return retVal;
      }

      public reserveAllMemory(): void {
         if (!this.vm) {
            return;
         }
         this.vm.reserveAllMemory();
      }

      public canRemove(): boolean {
         if (!this.vm) {
            return false;
         }
         let retVal = this.vm.canRemoveDevice(this.option);
         return retVal;
      }

      private info2text(item: PciPassthroughInfo): String {
         var info: PciPassthroughInfo = item as PciPassthroughInfo;

         if (!info) {
            return "";
         }

         var id: string = info.pciDevice.id;
         var vendor: String = info.pciDevice.vendorName;
         var name: string = info.pciDevice.deviceName;
         var retVal: string = id + " | "  + name + " " + vendor;
         return retVal;
      }

   }

   function GetVirtualPCIFactory(deviceDiffService: any, vmDeviceInfoService: any, vmHardwareUtil: any): any {
      let factoryFn: Function = function (rawDevice: any): any {
         let instance = new VmHwPciDevice(rawDevice, deviceDiffService, vmDeviceInfoService, vmHardwareUtil);
         return instance;
      };
      return factoryFn;
   }

   angular.module('com.vmware.vsphere.client.vm')
         .factory('VirtualPCITs',
               ['deviceDiffService', 'vmDeviceInfoService', 'vmHardwareUtil', GetVirtualPCIFactory]);
}

