module ds_cluster_ui {

   class StorageDrsAdvancedOptionsPageViewController {

      public static $inject = ["$scope", "storageDrsAdvancedOptionsService",
            "dsClusterConstants", "i18nService"];

      public pageModel: any;
      public loadBalanceUnitOptions: any;
      public loadBalanceIntervalOptions: any;
      public loadBalanceIntervalErrorMessages: any;
      public ioImbalanceThresholdSliderOptions: any;
      public minUtilizationDifferenceMin: number;
      public minUtilizationDifferenceMax: number;

      constructor(private $scope: any, private storageDrsAdvancedOptionsService:
            StorageDrsAdvancedOptionsService,
            private dsClusterConstants: any, private i18nService: any) {
         let sdrsManager = $scope.modalOptions.dialogData.manager;
         this.pageModel = sdrsManager.getStorageDrsAdvancedOptionsModel();

         let unitKey = this.pageModel.convertedLoadBalanceInterval.unitKey;
         this.pageModel.convertedLoadBalanceInterval.unit =
               this.i18nService.getString("DsClusterUi",
               this.pageModel.convertedLoadBalanceInterval.value === 1 ? unitKey + "S" : unitKey);

         $scope.$watch("sdrsAdvancedOptionsForm.$invalid", function(isInvalid: any): void {
            sdrsManager.setFormValidationResult(!isInvalid);
         });

         this.initLoadBalanceInterval();
         this.initMinUtilizationDifference();
         this.ioImbalanceThresholdSliderOptions = this.storageDrsAdvancedOptionsService
               .getIoImbalanceThresholdSliderOptions(this.pageModel.ioLoadImbalanceThreshold,
                     this.i18nService.getString(
                           "DsClusterUi",
                           "sdrs.config.ioImbalanceThresholdLabel"
                     ));

         // Watch for changes on the slider value and propagate it to the model.
         $scope.$watch(() => { return this.ioImbalanceThresholdSliderOptions.value; },
               (ioImbalanceThreshold: string): void => {
                  this.pageModel.ioLoadImbalanceThreshold = parseInt(ioImbalanceThreshold, 10);
         });
      }

      private initMinUtilizationDifference() {
         this.minUtilizationDifferenceMin =
               this.dsClusterConstants.minSpaceUtilizationDifference.MIN;
         this.minUtilizationDifferenceMax =
               this.dsClusterConstants.minSpaceUtilizationDifference.MAX;

         this.$scope.$watch(
               "ctrl.pageModel.spaceLoadBalanceConfig.minSpaceUtilizationDifference",
               (value: number) => {
            if (value > this.minUtilizationDifferenceMax) {
               this.pageModel.spaceLoadBalanceConfig.minSpaceUtilizationDifference =
                     this.minUtilizationDifferenceMax;
            }
            if (value < this.minUtilizationDifferenceMin) {
               this.pageModel.spaceLoadBalanceConfig.minSpaceUtilizationDifference =
                     this.minUtilizationDifferenceMin;
            }
         });
      }

      private initLoadBalanceInterval(): void {
         this.loadBalanceUnitOptions =
               this.storageDrsAdvancedOptionsService.getUnitOptions();

         this.loadBalanceIntervalOptions =
               this.storageDrsAdvancedOptionsService.getIntervalOptions(this.pageModel);

         this.loadBalanceIntervalErrorMessages =
               this.storageDrsAdvancedOptionsService.getErrorMessages();
      }
   }

   angular.module("com.vmware.vsphere.client.dsCluster")
         .controller("StorageDrsAdvancedOptionsPageViewController",
               StorageDrsAdvancedOptionsPageViewController);
}
