/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
(function () {
   'use strict';

   angular.module('com.vmware.vsphere.client.networkLibUi').directive('networkSelectorList', networkSelectorList);

   networkSelectorList.$inject = ['i18nService', 'vuiConstants', 'columnRenderersRegistry', '$compile'];

   function networkSelectorList(i18nService, vuiConstants, columnRenderersRegistry, $compile) {

      var directive = {
         scope: {
            contextObjectId: '=', // The context object for which the network selector list will be invoked
            networkSelectorFilterSpec: '=', // Network filter spec which will be used to retrieve the networks
            userSessionClientId: '=', // The current web client's session ID
            selectedItems: '=', // The currently selected items
            sortParams: '<?', // Parameters for sorting the grid (ex: [{field:"name", dir:"asc"}] )
            retrievedNetworks: '<?' // Pre-retrieved networks, no need to retrieve them remotely
         },
         templateUrl: 'network-lib-ui/resources/network-lib/components/networkselector/NetworkSelectorList.html',
         replace: true,
         link: {
            pre: preLink,
            post: postLink
         }
      };

      var lastSearchTerm = '';

      function preLink(scope) {

         var filterSet = false;

         var dataUrl = 'network-lib-ui/ctrl/networkSelector/list/';

         var lastSortParams;

         // To json string then encode in base64
         var encodedFilterSpec = angular.toJson(scope.networkSelectorFilterSpec);

         var params = {
            targetObjectId: scope.contextObjectId,
            filterSpec: encodedFilterSpec,
            webClientSessionId: scope.userSessionClientId,
            sort: scope.sortParams || [{field:"name", dir:"asc"}]
         };

         var listData;

         if (scope.retrievedNetworks) {
            scope.retrievedNetworks = _.sortBy(
                  scope.retrievedNetworks, function (network) {
               return network.name.toLowerCase();
            });
            listData = scope.retrievedNetworks;
         } else {
            listData = {
               url: dataUrl,
               params: params,
               dataType: vuiConstants.grid.dataType.JSON,
               method: vuiConstants.grid.dataMethod.POST,
               data: 'networks',
               total: 'totalMatchedObjectCount'
            };
         }

         scope.searchTerm = '';
         lastSearchTerm = '';

         scope.doSearch = function() {
            params.filterSpec = getFilterSpec(scope);
            scope.selectedItems = [];
            refreshGrid(scope);
         };

         scope.debouncedRefreshCallback = _.debounce(function() {
            if (scope.searchTerm !== lastSearchTerm) {

               // filter networks
               if (scope.retrievedNetworks) {
                  // client side filtering for already retrieved networks
                  scope.networkSelectorDatagridOptions.data =
                        filterRetrievedNetworks(scope);
               } else {
                  // server side filtering
                  params.filterSpec = getFilterSpec(scope);
                  refreshGrid(scope);
               }

               scope.selectedItems = [];
            }
         }, 500);

         scope.networkSelectorDatagridOptions = {
            actionBarOptions: {
               actions: []
            },
            height: '100%',
            selectionMode: vuiConstants.grid.selectionMode.SINGLE,
            resizable: true,
            pageConfig: {
               displayMode: vuiConstants.grid.displayMode.VIRTUAL_SCROLLING
            },
            columnDefs: [
               {
                  displayName: i18nService.getString('Common', 'NetworkSelectorDialog.nameColumnHeader'),
                  field: 'name',
                  searchable: false,
                  template: networkNameRendererFactory()
               },
               {
                  displayName: i18nService.getString('Common', 'NetworkSelectorDialog.dvsNameColumnHeader'),
                  field: 'dvsName',
                  sortable: false,
                  searchable: false,
                  template: dvsNameRendererFactory()
               }
            ],
            data: listData,
            selectedItems: [],
            dataBound: function(data) {

               // add filter toolbar
               if(!filterSet) {
                  var filterPlaceholderText = i18nService.getString('Common', 'filter.label');
                  var filterButtonText = i18nService.getString('Common', 'applyFilter.label');
                  var FILTER_INPUT_TEMPLATE =
                     '<div class="filter-input pull-right">' +
                     '<button class="nav-icon fa fa-filter filter-button" ng-click="doSearch()" ' +
                           'aria-label="' + filterButtonText + '" /></button>' +
                     '<input type="text" ng-model="searchTerm" ng-trim="true" ng-change="debouncedRefreshCallback()"' +
                     'placeholder="' + filterPlaceholderText + '"/>' +
                     '</div>';

                  var filterElement = $compile(FILTER_INPUT_TEMPLATE)(scope);

                  var grid = getGridElement();
                  if (grid) {
                     var toolbarElement = grid.find('.k-grid-toolbar');
                     if (toolbarElement.length > 0) {
                        toolbarElement.append(filterElement);
                        filterSet = true;
                     }
                  }

                  scope.searchTerm = '';
                  lastSearchTerm = '';

                  if (scope.retrievedNetworks) {
                     // client side filtering
                     data.sender.dataSource.data = filterRetrievedNetworks(scope);
                  } else {
                     // server side filtering
                     params.filterSpec = getFilterSpec(scope);
                     refreshGrid(scope);
                  }

                  scope.selectedItems = [];
               }
               //TODO: Subscribe to the 'sort' event once Kendo version is updated to 2016.Q3 version. In previous versions there is no such event.

               // In order to detect the sort event, we subscribe to the 'dataBound' event
               // which is triggered when paging event occurs. This happens when
               // sorting/filtering AND when scrolling the virtual list. As we don't want
               // to lose selection when scrolling down the list, we have to determine
               // when the sort event has occurred. This is achieved here by comparing
               // the current and last sorting params and if there is some diff we assume
               // that sort event has occurred.

               // skip when initializing the grid
               if (!data.sender.dataSource.sort()) {
                  return;
               }

               var sortParams = data.sender.dataSource.sort()[0];
               if (lastSortParams && !sortParams
                     || !lastSortParams && sortParams
                     || lastSortParams && sortParams && lastSortParams.dir !== sortParams.dir) {
                  scope.selectedItems = [];
               }

               lastSortParams = sortParams;
            }
         };
      }

      function refreshGrid (scope) {
         scope.isRefreshInProgress = true;

         var dataSource = getDataSource();
         if (!dataSource) {
            return;
         }
         var progressHandlers = null;

         try {
            progressHandlers = dataSource._events["progress"];
            delete dataSource._events["progress"];
         } catch (error) {
            progressHandlers = null;
         }

         dataSource.read().then(function() {
            scope.isRefreshInProgress = false;
            if (progressHandlers !== null) {
               dataSource._events["progress"] = progressHandlers;
            }
         });
      }

      function getFilterSpec (scope) {
         if (scope.searchTerm) {
            scope.networkSelectorFilterSpec.filterText = scope.searchTerm;
            scope.networkSelectorFilterSpec.filteredFields = ["name", "dvsName"];
         } else {
            scope.networkSelectorFilterSpec.filterText = undefined;
            scope.networkSelectorFilterSpec.filteredFields = [];
         }
         lastSearchTerm = scope.searchTerm;


         return angular.toJson(scope.networkSelectorFilterSpec);
      }

      function filterRetrievedNetworks(scope) {
         lastSearchTerm = scope.searchTerm;

         if (!scope.retrievedNetworks) {
            return [];
         }

         if (scope.searchTerm) {
            return _.filter(scope.retrievedNetworks, function(network) {
               return network.name.toLowerCase().indexOf(
                     scope.searchTerm.toLowerCase()) !== -1 ||
                     (network.dvsName &&
                           network.dvsName.toLowerCase().indexOf(
                                 scope.searchTerm.toLowerCase()) !== -1);
            });
         }

         return scope.retrievedNetworks;
      }

      function getDataSource() {
         var grid = getGrid();
         return grid && grid.dataSource;
      }

      function getGrid() {
         var gridElement = getGridElement();
         return gridElement && gridElement.data('kendoGrid');
      }

      function getGridElement() {
         var element = angular.element('.network-select-list .k-grid-content');
         return element && element.parent();
      }

      function dvsNameRendererFactory() {
         return (function(data) {
            if (!data.dvsName) {
               return i18nService.getString('Common', 'NetworkSelectorItem.noData');
            }

            return columnRenderersRegistry
                  .getColumnRenderer('text')(['dvsName'], data, true);
         });
      }

      function networkNameRendererFactory() {
         return (function (data) {

            var icon = 'vsphere-icon-virtual-port-group';
            if (data.isStandardNetwork) {
               icon = 'vsphere-icon-network';
            } else if (data.isOpaqueNetwork) {
               icon = 'vsphere-icon-cloud-network';
            } else if (data.isUplink) {
               icon = 'network-lib-ui-icon-uplink';
            }

            var objectLinkRenderer = columnRenderersRegistry.getColumnRenderer('object-link');
            return objectLinkRenderer([null, 'name', icon], data);
         });
      }

      function postLink(scope) {
         /*
          * Detects click events of the vui-datagrid.
          */
         scope.$watch(function() {
            return scope.networkSelectorDatagridOptions.selectedItems;
         }, function(items) {
            scope.selectedItems = items;
         });
      }

      return directive;
   }
})();
