/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
(function () {
   'use strict';
   angular.module('com.vmware.vsphere.client.storage')
         .service('addDatastoreReadyToCompletePageService', addDatastoreReadyToCompletePageService);

   addDatastoreReadyToCompletePageService.$inject = ['i18nService', 'vuiConstants'];

   function addDatastoreReadyToCompletePageService(i18nService, vuiConstants) {
      return {
         build: function (wizardScope) {
            return {
               title: i18nService.getString('CommonUi', 'SummaryPage.title'),
               description: i18nService.getString('CommonUi', 'SummaryPage.description'),
               contentUrl: 'storage-ui/resources/storage/views/wizard/createDatastore/pages/AddDatastoreReadyToCompletePage.html',
               state: undefined,
               onCommit: function () {
                  wizardScope.wizardConfig.currentPage.state = vuiConstants.wizard.pageState.COMPLETED;
                  return wizardScope.activeWizardManager.submit();
               }
            };
         }
      };
   }
})();