namespace h5_vm {
   import any = jasmine.any;
   import VirtualDevice = com.vmware.vim.binding.vim.vm.device.VirtualDevice;
   import VirtualDeviceSpec = com.vmware.vim.binding.vim.vm.device.VirtualDeviceSpec;
   import VirtualDeviceSpec$Operation = com.vmware.vim.binding.vim.vm.device.VirtualDeviceSpec$Operation;
   import Description = com.vmware.vim.binding.vim.Description;
   import VirtualDeviceOption = com.vmware.vim.binding.vim.vm.device.VirtualDeviceOption;

   export class EditableVirtualDeviceClass {

      ADD_OPERATION: VirtualDeviceSpec$Operation = 'add';
      REMOVE_OPERATION: VirtualDeviceSpec$Operation = 'remove';
      EDIT_OPERATION: VirtualDeviceSpec$Operation = 'edit';

      protected originalDevice: VirtualDevice;
      private currentDeviceSpec: VirtualDeviceSpec = new VirtualDeviceSpec();
      protected vm: VmConfig | null;

      constructor(rawDevice: any, private deviceDiffService: any, private vmDeviceInfoService: any, private vmHardwareUtil: any) {

         this.originalDevice = rawDevice;

         let operation: VirtualDeviceSpec$Operation = this.isNew() ? "add" : "edit";

         this.currentDeviceSpec.operation = operation;
         this.currentDeviceSpec.device = angular.copy(rawDevice);
         delete this.currentDeviceSpec.fileOperation;
      }

      isNew() {
         return this.originalDevice.key < 0;
      };

      getFinalDeviceSpec() {
         var spec = angular.copy(this.currentDeviceSpec);
         this.vmHardwareUtil.clearUnchangedConnectInfo(spec, this.originalDevice);
         this.vmHardwareUtil.fixNewDiskBackingDatastore(spec);
         return spec;
      };

      getCurrentDeviceSpec(): VirtualDeviceSpec {
         return this.currentDeviceSpec;
      };

      setCurrentDeviceSpec(deviceSpec: any) {
         this.currentDeviceSpec = deviceSpec;
      };

      getCurrentDevice(): VirtualDevice {
         return this.currentDeviceSpec.device;
      };

      markForRemoval(): void {
         this.currentDeviceSpec.operation = this.REMOVE_OPERATION;
      };

      revertRemoval(): void {
         this.currentDeviceSpec.operation = this.EDIT_OPERATION;
      };

      getKey(): number {
         return this.currentDeviceSpec.device.key;
      };

      hasChanged(): boolean {
         if (this.currentDeviceSpec.operation === this.ADD_OPERATION || this.isMarkedForRemoval()) {
            return true;
         }

         return !this.deviceDiffService.isUnchanged(this.originalDevice, this.currentDeviceSpec.device);
      };

      isMarkedForRemoval(): boolean {
         return this.currentDeviceSpec.operation === this.REMOVE_OPERATION;
      };

      isOfType(deviceType: any): boolean {
         return this.vmDeviceInfoService.isDeviceSubclassOf(this.currentDeviceSpec.device, deviceType);
      };

      public get _type(): string {
         return this.originalDevice._type;
      }

      public get key(): number {
         return this.originalDevice.key;
      }

      public setDeviceInfo(info: Description) {
         this.originalDevice.deviceInfo = info;
      }


      // functionality not included in JavaScript version of editable device

      public deviceLabel(): string {

         const device: VirtualDevice = this.getCurrentDevice();

         if (!device) {
            return "";
         }

         const deviceInfo: Description = device.deviceInfo;

         if (!deviceInfo) {
            return "";
         }

         const retVal = deviceInfo.label;
         return retVal;
      }

      public removeCallback: Function | null;

      public removeDevice(): void {
         if (this.removeCallback) {
            this.removeCallback(this);
            if (this.isNew()) {
               this.removeCallback = null;
            }
         } else if (this.vm) {
            this.vm.remove(this);
         }
      }

      public cleanup(): void {
         this.removeCallback = null;
         this.vm = null;
      }

      public attachVmConfig(value: VmConfig): void {
         if (this.vm || !value) {
            return;
         }

         this.vm = value;
         this.onVmConfigAttached();
         this.setDeviceInfo(this.getCurrentDevice().deviceInfo);
      }

      protected onVmConfigAttached() {
         return; // implement in derived class
      }

      public removeDeviceDisabled(option: VirtualDeviceOption): boolean {
         if (!this.vm) {
            return false;
         }

         if (false === this.vm.canRemoveDevice(option)) {
            return true;
         } else {
            return false;
         }
      }

      public isEditDeviceDisabled(): boolean {
         if (!this.vm) {
            return true;
         }
         if (this.isNew()) {
            return false;
         }
         return this.vm.isEditDeviceDisabled(true);
      }

      public isConnectDeviceDisabled(): boolean {
         if (this.isNew()) {
            return false;
         }
         if (!this.vm) {
            return true;
         }
         return this.vm.isConnectDeviceDisabled();
      }

      public i18n(key: string): string {
         if (!this.vm || !this.vm.getI18nService()) {
            return "";
         }

         return this.vm.getI18nService().getString("VmUi", key);
      }

      public debugAsExistingDevice():void {
         let key = this.originalDevice.key;
         if (key < 0) {
            this.getCurrentDevice().key = -key;
            this.originalDevice.key = -key;
         }
      }

      public debugAsNewDevice():void {
         let key = this.originalDevice.key;
         if (key > 0) {
            this.getCurrentDevice().key = -key;
            this.originalDevice.key = -key;
         }
      }
   }

   function GetEditableVirtualDeviceFactory(deviceDiffService: any, vmDeviceInfoService: any, vmHardwareUtil: any): any {
      let factoryFn: Function = function (rawDevice: any): any {
         let instance = new EditableVirtualDeviceClass(rawDevice, deviceDiffService, vmDeviceInfoService, vmHardwareUtil);
         return instance;
      };
      return factoryFn;
   }

   angular.module('com.vmware.vsphere.client.vm')
         .factory('EditableVirtualDeviceTs',
               ['deviceDiffService', 'vmDeviceInfoService', 'vmHardwareUtil', GetEditableVirtualDeviceFactory]);
}
