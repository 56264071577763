module h5_vm {

    class FtSelectHostController implements angular.IController {
        static $inject = [
            "$scope",
            "storageSelectorService",
            "dataService",
            "defaultUriSchemeUtil",
            "$rootScope",
            "ftVmService",
            "i18nService"
        ];
        private selectedDatastore: any;
        private objectId: any;
        private selectedVm: any;
        private objectSelectorConfigCompatibleHosts: any;
        private objectSelectorConfigAllHosts: any;
        private loading: boolean = true;
        private compatibleHosts: any;
        private showAllHosts: boolean = false;
        private availableDevices: any;
        private compatbileHostsMap: any;
        private messages: any;

        constructor(private $scope: any,
                    private storageSelectorService: any,
                    private dataService: any,
                    private defaultUriSchemeUtil: any,
                    private $rootScope: any,
                    private ftVmService: FtVmService,
                    private i18nService: any) {

        }

        public $onInit() {
            this.selectedVm = this.$scope.manager.getTargetUid();
            this.selectedDatastore = this.$scope.manager.getSelectedDatastore();
            let devices: any = this.$scope.manager.getDevices();
            this.availableDevices = this.ftVmService.getAvailableDevices(devices);
            this.showAllHosts = false;
            this.getCompatibleHosts().then((data: any) => {
                this.compatibleHosts = data.ftCompatibleHosts;
                this.compatbileHostsMap = this.ftVmService.convertCompatibleHostsIntoMap(this.compatibleHosts);
                this.messages = [];
                this.loading = false;
                this.objectSelectorConfigCompatibleHosts = this.generateObjectLocator(true);
                this.objectSelectorConfigAllHosts = this.generateObjectLocator(false);
                this.$scope.manager.setSelectedHost(null);
            });
        }

        private getCompatibleHosts() {
            return this.dataService.getProperties(this.selectedVm,
                ["ftCompatibleHosts"], this.ftVmService.createSecondaryVmSpecParameter(
                      this.$scope.manager, this.availableDevices)
            );
        }

        private showAllHostsAction(): void {
            this.messages = [];
            this.showAllHosts = !this.showAllHosts;
        }

        private generateObjectLocator(showCompatibleHosts: boolean) {
            let hosts: any = this.compatibleHosts;
            if (showCompatibleHosts) {
                hosts = _.filter(hosts, (ftHost: any) => {
                    return ftHost.errors === null;
                });
            }
            let hostsRefs = _.map(hosts, (host: any) => {
                return this.defaultUriSchemeUtil.getVsphereObjectId(host.ftHost);
            });

            let datacenter = this.$scope.manager.getDatacenter();
            let target = this.defaultUriSchemeUtil.getVsphereObjectId(datacenter);
            this.loading = false;
            return {
                contextObject: target,
                multipleSelect: false,
                listTabConfig: {
                    listConfig: [{
                        label: "",
                        listViewId: "vsphere.core.host.list",
                        dataModels: ["HostSystem"],
                        filterId: "hostsInDatacenterByMorObjectsFilter",
                        filterParams: hostsRefs
                    }]
                },
                onSelectionChanged: (newSelectedItems: any) => {
                    let selectedHost: any = newSelectedItems[0];
                    let faultToleranceData: any = this.compatbileHostsMap[selectedHost.id];
                    this.$scope.manager.setSelectedHost(_.extend(selectedHost, faultToleranceData));
                    this.messages =
                        this.ftVmService.generateValidationMessages(selectedHost, this.compatbileHostsMap);
                    if (this.messages.length === 0) {
                        this.messages = [{
                            type: "Success",
                            text: this.i18nService.getString(
                                'VmUi',
                                'turnOnFtWizard.compatibilityChecksSucceeded'
                            )
                        }];
                    }
                }
            };

        }

    }

    angular.module('com.vmware.vsphere.client.vm')
        .controller('FtSelectHostController', FtSelectHostController);
}

