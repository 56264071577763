module hostprofile_ui {

    export interface AttachHostProfileService {
        attachHostProfile(hostId: string, selectedItem: any): angular.IPromise<any>;
        openModal(targetUid: string, attachHostProfile: boolean): void;
        fetchData(targetUid: string): angular.IPromise<any>;
        getGridOptions(): any;
        filterSettings(originalData: any, searchTerm: string, propertiesToSearchInFor: string[]): any;
    }

    class AttachHostProfileServiceImpl implements AttachHostProfileService {
        static $inject = ["defaultUriSchemeUtil",
            "mutationService",
            "dataService",
            "clarityModalService",
            "i18nService",
            "columnRenderersRegistry",
            "managedEntityConstants",
            "vuiConstants"];
        private iconTextRenderer: any;


        constructor(private defaultUriSchemeUtil: any,
                    private mutationService: any,
                    private dataService: any,
                    private clarityModalService: any,
                    private i18nService: any,
                    private columnRenderersRegistry: any,
                    private managedEntityConstants: any,
                    private vuiConstants: any
        ) {
            this.iconTextRenderer = this.columnRenderersRegistry.getColumnRenderer("object-link");
        }

        public attachHostProfile(hostId: string, selectedItem: any): angular.IPromise<any> {
            return this.mutationService.apply(this.defaultUriSchemeUtil.getVsphereObjectId(selectedItem.provider),
                "com.vmware.vsphere.client.hostprofile.data.AttachEntitiesSpec",
                {entitiesToAttach: [this.defaultUriSchemeUtil.getManagedObjectReference(hostId)]}
            );
        }

        public openModal(targetUid: string, attachHostProfile: boolean): void {

            this.dataService.getProperties(targetUid, ["name"]).then((data: any) => {
                let title: string = this.getTitle(attachHostProfile, data.name);
                let modalOptions: any = {
                    title: title,
                    dialogData: {
                        objectId: targetUid
                    },
                    defaultButton: "submit",
                    contentTemplate: "hostprofile-ui/resources/hostprofile/attach/attachHostProfilePage.html",
                    size: "lg"
                };
                this.clarityModalService.openOkCancelModal(modalOptions);
            });
        }

        public filterSettings(originalData: any, searchTerm: string, propertiesToSearchInFor: string[]): any {
            const searchValue: string = searchTerm.toLowerCase();
            return _.filter(originalData, (item: any) => {
                return this.isSearchValuePresentedInProperties(item, searchValue, propertiesToSearchInFor);
            });
        }

        private isSearchValuePresentedInProperties(item: any, searchItem: string, properties: string[]): boolean {
            return _.some(properties, (property: string) => {
                const currentPropertyValue: string = item[property];
                return !_.isNull(currentPropertyValue) && currentPropertyValue.toLowerCase().indexOf(searchItem) >= 0;
            });
        }

        private getTitle(attachHostProfile: boolean, hostName: string): string {
            return attachHostProfile ? this.i18nService.getString(
                "HostProfileUi",
                "attachWizard.title.attachProfile",
                hostName
            ) : this.i18nService.getString(
                "HostProfileUi",
                "attachWizard.title.changeProfile",
                hostName
            );
        }

        public fetchData(targetUid: string): angular.IPromise<any> {
            // The default value of spec is for Host System; it can only be HostSystem or ClusterComputeResource
            let spec: string = "com.vmware.vsphere.client.h5.host.model.hostprofile.HostAttachHostProfileData";

            if (this.defaultUriSchemeUtil.getPartsFromVsphereObjectId(targetUid).type === this.managedEntityConstants.CLUSTER) {
                spec = "com.vmware.vsphere.client.hostprofile.data.h5.ClusterAttachHostProfileHostData";
            }

            return this.dataService.getData(targetUid, spec)
                .then((data: any) => this.filterHostProfileData(data));
        }

        private filterHostProfileData(data: any): Array<any> {
            let filteredData: Array<any> = [];
            if (!data || (!data.associatedHostProfile && !data.compatibleHostProfileData)) {
                return filteredData;
            }
            if (data.associatedHostProfile) {
                let associatedHostProfileId = this.defaultUriSchemeUtil.getVsphereObjectId(
                    data.associatedHostProfile);

                filteredData = _.filter(data.compatibleHostProfileData, (hostProfileItem: any): boolean => {
                    let hostProfileId = this.defaultUriSchemeUtil.getVsphereObjectId(hostProfileItem.provider);
                    return hostProfileId !== associatedHostProfileId;
                });
            } else {
                filteredData = data.compatibleHostProfileData;
            }
            return filteredData;
        }


        public getGridOptions(): any {
            return {
                selectionMode: this.vuiConstants.grid.selectionMode.SINGLE,
                sortMode: this.vuiConstants.grid.sortMode.SINGLE,
                searchable: false,
                resizable: true,
                selectedItems: [],
                data: [],
                columnDefs: this.getColumnDefs(),
                height: "auto"
            };
        }

        private getColumnDefs(): any {
            return [
                {
                    displayName: this.i18nService.getString("HostProfileUi", "HostProfileSelection.column.profile"),
                    type: "string",
                    field: "name",
                    template: (dataItem: any) => {
                        return this.iconTextRenderer([undefined, "name", "vsphere-icon-Host_Policy"], dataItem);
                    }
                },
                {
                    displayName: this.i18nService.getString("HostProfileUi", "HostProfileSelection.column.description"),
                    field: "description",
                    type: "string"
                }
            ];
        }
    }

    angular.module("com.vmware.vsphere.client.hostprofile")
        .service("attachHostProfileService", AttachHostProfileServiceImpl);
}
