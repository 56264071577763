namespace h5_vm {
   export class VmStorageProfileMetadata {
      selectedProfile: any;
      storageProfiles: Array<any>;

      constructor(storageProfiles: Array<any>,
                  selectedProfile: any) {
         this.storageProfiles = storageProfiles;
         this.selectedProfile = selectedProfile;
      }
   }
}
