namespace storage_ui {

   export class VmfsVersionPageService {

      public static $inject = ["wizardPageService", "i18nService"];

      constructor(private wizardPageService: any, private i18nService: any) {

      }

      public build(wizardScope: any): any {
         let self = this;
         return {
            title: this.i18nService.getString("StorageUi", "addDatastoreWizard.vmfsSelectVersionPage.title"),
            description: this.i18nService.getString("StorageUi", "addDatastoreWizard.vmfsSelectVersionPage.description"),
            contentUrl: "storage-ui/resources/storage/views/wizard/createDatastore/pages/vmfs/VmfsVersionPage.html",
            state: undefined,
            onCommit: function () {
               self.wizardPageService.markPageComplete(wizardScope.wizardConfig,
                     wizardScope.commonWizardManager.getDatastoreType());
               return true;
            }
         };
      }
   }
   angular.module("com.vmware.vsphere.client.storage")
         .service("vmfsVersionPageService", VmfsVersionPageService);
}
