(function () {
   'use strict';

   angular
         .module('com.vmware.vsphere.client.vm')
         .controller('VmProvisioningDatastorePageController', VmProvisioningDatastorePageController);

   VmProvisioningDatastorePageController.$inject = [
      '$scope',
      '$q',
      '$element',
      '$timeout',
      'i18nService',
      'defaultUriSchemeUtil',
      'diskFormatService',
      'storageSelectorService',
      'storageSelectorConstants',
      'creationTypeConstants',
      'dataService',
      'storageProfileService',
      'initialStorageConfigurationService',
      'pmemUtilService',
      'featureFlagsService'
   ];

   function VmProvisioningDatastorePageController($scope,
         $q,
         $element,
         $timeout,
         i18nService,
         defaultUriSchemeUtil,
         diskFormatService,
         storageSelectorService,
         storageSelectorConstants,
         creationTypeConstants,
         dataService,
         storageProfileService,
         initialStorageConfigurationService,
         pmemUtilService,
         featureFlagsService) {
      var self = this;
      // When going back to a page with a selected storage, the newValue and oldValue
      // in the watch for the self.form.selectedStorage are equal, so no validation
      // event is invoked. So we have introduced the flag to enforce validation.
      var preserveInitialSelection = false;

      self.i18n = i18nService;

      var model = $scope.datastorePageModel;
      self.form = model.form;

      var isScheduledTask = $scope.scheduleTaskData ?
         $scope.scheduleTaskData.isScheduledTask : false;

      self.podDisplayDisabled = model.podDisplayDisabled() || isScheduledTask;
      self.isVmtxWorkflow = model.isCloningVmtxTemplate() || model.isDeployVmFromVmtxTemplate();
      self.isDiskGroupsAvailable = false;

      self.disableDefaultPreselectionInBasicMode = model.virtualMachineSpecBuilder.isXvc();

//      if (model.isInDeployTemplateMode()) {
//         self.disableDefaultPreselectionInBasicMode = true;
//      }

      self.showReplicationGroups = !model.isInDeployVmFromOvf();

      self.storageSelectorApi = storageSelectorService.getStorageSelectorApiObj();

      // In Create  mode we prefer signpost hints - in clone we prefer alert hints.
      self.showStorageBaselineSignpostHints = !model.isInCloningMode();

      self.isEncryptionOptionsVisible = !model.isInCloningMode()
         && model.virtualMachineSpecBuilder.getHasEncryptNewVmPrivilege();
      self.isEncryptionOptionsDisabled = true;
      self.encryptionOptionsDisabledReason = undefined;
      model.virtualMachineSpecBuilder.getStoragePageData()
            .then(function(response) {
               response = response.vmStoragePageData;
               // If already disabled, leave it this way
               if (self.encryptionOptionsDisabledReason) {
                  return;
               }

               // When the user doesn't have permission to aggregate the KMS statuses,
               // don't stop him because he will still be able to create encrypted VM
               // if the KMS servers are OK.
               if (response && response.h5DefaultKmipClusterStatus
                     && response.h5DefaultKmipClusterStatus.noPermission) {
                  self.isEncryptionOptionsDisabled = false;
                  return;
               }

               var isConnectionNormal = response && response.h5DefaultKmipClusterStatus &&
                     response.h5DefaultKmipClusterStatus.connectionStatus === h5_vm.ConnectionStatus.CONNECTED;
               var isCertificateExpired = response && response.h5DefaultKmipClusterStatus &&
                     response.h5DefaultKmipClusterStatus.certificateStatus === h5_vm.CertStatus.EXPIRED;

               self.isEncryptionOptionsDisabled = !isConnectionNormal || isCertificateExpired;
               if (!isConnectionNormal) {
                  self.encryptionOptionsDisabledReason =
                        i18nService.getString('VmUi', 'VmCryptConfig.reason.noKms');
               } else if (isCertificateExpired) {
                  self.encryptionOptionsDisabledReason =
                        i18nService.getString('VmUi', 'VmCryptConfig.reason.kmsCertificateRequiresUpdate');
               }
            }, function() {
               self.isEncryptionOptionsDisabled = true;
               self.encryptionOptionsDisabledReason =
                     i18nService.getString('VmUi', 'VmCryptConfig.reason.noKms');
            });

      //Restore storage selector state if there is such
      var storageSelectorState = angular.copy(model.virtualMachineSpecBuilder.getStorageSelectorState());
      if (storageSelectorState) {
         this.form.storageSelectorState = storageSelectorState;
         this.storageSelectorState = storageSelectorState;
      } else {
         this.form.storageSelectorState =
               storageSelectorService.getStorageSelectorState();
         preserveInitialSelection = true;
      }
      this.storageSelectorState = this.form.storageSelectorState;

      self.title = i18nService.getString('VmUi', 'SelectDatastoreProvisioningPage.Compatibility');
      self.description = i18nService.getString('VmUi', 'SelectDatastoreProvisioningPage.Description');
      self.pageLoadError = undefined;

      $scope.config.loading = true;

      var shouldRequestHostIcons = true;
      this.onShowDetailsClick = function (contents) {
         var hostIds = _.chain(contents)
               .pluck('hostId').without(undefined).uniq().value();

         if (!hostIds.length || !shouldRequestHostIcons) {
            return $q.resolve();
         }

         return dataService.getPropertiesForObjects(hostIds, ['primaryIconId']).then(function (results) {
            _.each(contents, function (hostObject) {
               if (hostObject.hostId) {
                  hostObject.icon = results[hostObject.hostId].primaryIconId;
               }
            });
         });
      };

       // Compute resource ID is not available for clone VMTX template
       // Using the source VMs ID as the compute resource in that case
       if (model.isCloningVmtxTemplate() && !featureFlagsService.CL_Native_VMTX_Phase2Enabled()) {
           var vmId = $scope.wizardViewData.getSourceObjectId();
           model.setComputeResourceId(vmId);
       }

      var resource = model.getComputeResourceOwnerId() || model.getComputeResourceId();
      var gridPromise = model.getStorageLocatorData(resource);

      var profilesPromise = model
            .getProfilesData(model.getServerGuid());

      var sourceId = model.virtualMachineSpecBuilder.getVmId() || (
            $scope.wizardViewData &&
            $scope.wizardViewData.getSelectedTemplate() &&
            $scope.wizardViewData.getSelectedTemplate().templateId);

      var allPromises = {
         'model': model.load(),
         'grid': gridPromise,
         'profiles': profilesPromise
      };
      if (isAdvancedStorageAvailableForCurrentFlow(
                  model.virtualMachineSpecBuilder.getCreationType())) {
         if (model.isInDeployFromTemplateMode()) {
            // Deploy from OVF/ContentLibrary
            allPromises['getStorageSelectorData'] = {};
            allPromises['getStorageSelectorData']['virtualDisks'] = model.requestVmDisksInDeployFromTemplateMode();
            allPromises['getStorageSelectorData']['vmHomeDatastore'] =
                  model.requestVmHomeDatastoresInDeployFromTemplateMode();
            self.isDiskGroupsAvailable = true;
         } else {
            var getStorageSelectorDataPromise = model.getStorageSelectorData(sourceId);
            allPromises['getStorageSelectorData'] = getStorageSelectorDataPromise;
         }
      } else if (model.isInDeployFromTemplateMode()) {
         // Deploy from OVF/ContentLibrary
         allPromises['getStorageSelectorData'] = {};
         allPromises['getStorageSelectorData']['virtualDisks'] = model.requestVmDisksInDeployFromTemplateMode();
         allPromises['getStorageSelectorData']['vmHomeDatastore'] =
               model.requestVmHomeDatastoresInDeployFromTemplateMode();
      } else {
         // The default values - usually empty (but DEFINED) objects
         allPromises['getStorageSelectorData'] = {};
         allPromises['getStorageSelectorData']['virtualDisks'] = model.form.vmDisksData;
         allPromises['getStorageSelectorData']['vmHomeDatastore'] = model.form.vmsHomeDatastore;
      }
      $q.all(allPromises).then(function (results) {
         if (!results['grid']) {
            self.pageLoadError = new Error(
               i18nService.getString('VmUi', 'SelectDatastoreProvisioningPage.noStorageAvailable'));
            self.pageReady = false;
            $scope.config.loading = false;
            return;
         }

         setupDiskFormatSettings();

         var vmId = model.virtualMachineSpecBuilder.getVmId();

         var profiles = results['profiles'];

         if (self.isEncryptionOptionsVisible) {
            var encryptionProfile = _.find(profiles.storageProfiles, function (profile) {
               return storageProfileService.isEncryptionStorageProfile(profile);
            });

            if (!encryptionProfile) {
               if (!model.virtualMachineSpecBuilder.getHasManageEncryptionPolicyPrivilege()) {
                  self.isEncryptionOptionsVisible = false;
               }

               if (!self.encryptionOptionsDisabledReason) {
                  self.isEncryptionOptionsDisabled = true;
                  self.encryptionOptionsDisabledReason =
                  i18nService.getString('VmUi', 'VmCryptConfig.reason.noEncryptionPolicies');
               }
            }
         }

         if (results['getStorageSelectorData']) {
            profiles.vmProfileAssignments = {};
            profiles.vmProfileAssignments[sourceId] = results['getStorageSelectorData'].
                  storagePoliciesAssignments;

            profiles.vmReplicationGroupAssignments = {};
            profiles.vmReplicationGroupAssignments[sourceId] =
                  results['getStorageSelectorData'].vmReplicationGroupAssignments;
         }

         if (model.isInDeployFromTemplateMode()) {
            self.unsupportedStorageBaselineIds = [storageSelectorConstants.PMEM_STORAGE_BASELINE.id];

            profiles._storageProfiles =
                  model.getRecommendedProfilesForTemplateDeployment(profiles.storageProfiles);
            self.form.storageLocatorItemsData.datastoreItems =
                  model.getRecommendedDatastoresForTemplateDeployment();
         }

         self.modeSettings = storageSelectorService.getStorageSelectorModeSettings(
               isAdvancedStorageAvailableForCurrentFlow(
                     model.virtualMachineSpecBuilder.getCreationType()));

         var names = model.getVmNames();
         names[vmId] = model.virtualMachineSpecBuilder.getName();

         var destinationHostId =
               defaultUriSchemeUtil.getEntityType(model.getComputeResourceId()) === "HostSystem"
                     ? model.getComputeResourceId() : null;
         var vmDiskData = results['getStorageSelectorData'].virtualDisks;
         var vmHomeDatastoreData = results['getStorageSelectorData'].vmHomeDatastore;

         var vmData = [];
         if (vmId || vmDiskData) {
            // common_ui.StorageSelectorVmProperties
            vmData.push({
               id: vmId,
               name: names[vmId],
               homeDatastore: vmHomeDatastoreData ? vmHomeDatastoreData[vmId]: null,
               destinationHost: destinationHostId,
               disksData: vmDiskData ? vmDiskData[vmId] : null
            });
         }

         storageSelectorService.getStorageSelectorData(vmId || model.getComputeResourceId(),
               self.form.storageLocatorItemsData, true, vmData, profiles)
            .then(function (storageSelectorData) {

            // If we are in create VM workflow, we need to pass the VM destination host
            // to the storageSelectorData.
            if (!vmId && storageSelectorData.sourceVmStorageConfig &&
                  storageSelectorData.sourceVmStorageConfig.length &&
                  !storageSelectorData.sourceVmStorageConfig[0].destinationHostId) {
               storageSelectorData.sourceVmStorageConfig[0].destinationHostId = destinationHostId;
            }
            self.storageSelectorData = storageSelectorData;

            var scheduledData = model.virtualMachineSpecBuilder.getScheduledData();
            if (scheduledData && scheduledData.storageData) {
               var isCreateFromScratch = model.virtualMachineSpecBuilder.getCreationType() ===
                     creationTypeConstants.CREATE_FROM_SCRATCH;
               initialStorageConfigurationService.computeSourceVmStorageConfig(scheduledData.storageData.initialVmStorageConfig,
                     profiles._storageProfiles, self.storageSelectorData, isCreateFromScratch);
            }

            model.virtualMachineSpecBuilder
               .setSourceVmStorageConfig(storageSelectorData.sourceVmStorageConfig);

            var initialReplicationGroup;
            if (storageSelectorData.sourceVmStorageConfig &&
                  storageSelectorData.sourceVmStorageConfig.length) {
               initialReplicationGroup =
                     self.storageSelectorData.sourceVmStorageConfig[0].vmHome.replicationGroup;
            }
            if (storageSelectorData.storageProfilesData.error) {
               model.virtualMachineSpecBuilder.containsSpbmIssues(true);
            } else {
               model.virtualMachineSpecBuilder.containsSpbmIssues(false);
            }
            // Set the selected item to the first storage pod
            // or datastore item from the queried storages.
            if (!storageSelectorState
                  && (self.form.storageLocatorItemsData.datastoreItems.length > 0
                  || self.form.storageLocatorItemsData.storagePodItems.length > 0)) {

               var itemToSelect = model.getItemToSelect(
                     self.form.storageLocatorItemsData.datastoreItems,
                     self.form.storageLocatorItemsData.storagePodItems
               );

               var profileToSelect = model.getProfileToSelect();

               self.storageSelectorData.initialConfig = storageSelectorService.getStorageSelectorInitialConfig(
                     itemToSelect,
                     profileToSelect,
                     initialReplicationGroup
               );

               if (scheduledData && scheduledData.storageData) {
                  initialStorageConfigurationService.initSelectedStorageItems(
                        self.storageSelectorState, self.storageSelectorData, scheduledData.storageData);
               }

            }
            self.pageReady = true;
            $scope.config.loading = false;

            // focus the first input on the page
            focusFirstElement();
         });
         // If a VM is being cloned or template is being deployed/cloned, the source
         // VM/template profile should be the one assigned to its clone by default
         if (!self.form.storageProfileHasBeenSelected && profiles.vmProfileAssignments &&
               profiles.vmProfileAssignments[vmId] && profiles.vmProfileAssignments[vmId].homeStorageProfile) {
            self.form.storageProfilesData.selectedProfile =
                  buildProfileItem(profiles.vmProfileAssignments[vmId].homeStorageProfile);
         }
      });

      var lastValidationId = 0;

      function isAdvancedStorageAvailableForCurrentFlow(flowType) {
         return flowType === creationTypeConstants.CLONE_VM_TO_TEMPLATE ||
                flowType === creationTypeConstants.CLONE_VM_TO_VM ||
                flowType === creationTypeConstants.CLONE_TEMPLATE_TO_VM ||
                flowType === creationTypeConstants.CLONE_TEMPLATE_TO_TEMPLATE ||
                flowType === creationTypeConstants.CLONE_AS_VMTEMPLATE_TO_LIBRARY ||
                flowType === creationTypeConstants.DEPLOY_VM_FROM_VMTX ||
                model.isDiskGroupsAvailable();

      }

      function validateSelection(selectedStorage, selectedProfileId, diskFormat) {
         setIsValidating(true);
         var validationId = ++lastValidationId;

         var rgValidationError = self.storageSelectorApi.validateReplicationGroupSelection();

         var sourceVmStorageConfig = self.storageSelectorData.vmStorageConfig[0];
         model.validateSelection(selectedStorage, selectedProfileId, diskFormat,
               rgValidationError, sourceVmStorageConfig)
               .then(function(compatibilityMessages) {
                  if(validationId === lastValidationId) {
                     self.contents = compatibilityMessages;
                     shouldRequestHostIcons = true;
                  }
               })
               .then(function() {
                  if(validationId === lastValidationId) {
                     setIsValidating(false);
                  }
               });
      }

      function validateAdvancedSelection(selectedStorage) {
         setIsValidating(true);
         var validationId = ++lastValidationId;

         var sourceVmStorageConfig = self.storageSelectorData.vmStorageConfig[0];

         model.validateAdvancedSelection(selectedStorage, sourceVmStorageConfig)
               .then(function(compatibilityMessages) {
                  if(validationId === lastValidationId) {
                     self.contents = compatibilityMessages;
                  }
               })
               .then(function() {
                  if(validationId === lastValidationId) {
                     setIsValidating(false);
                  }
               });
      }

      function setIsValidating(isValidating) {
         self.isValidating = !!isValidating;
         $scope.config.loading = isValidating;
      }

      function setupDiskFormatSettings() {
         self.form.diskFormatSettings.diskFormatSupported = isDiskFormatSupported();
         self.form.diskFormatSettings.sameAsSourceSupported = isSameAsSourceSupported();
         self.form.diskFormatSettings.drsPlacement = model.isInDeployTemplateMode();
      }

      function isDiskFormatSupported() {
         return model.isInCloningMode() || model.isInDeployFromTemplateMode();
      }

      function isSameAsSourceSupported() {
         return model.isInCloningMode();
      }

      function buildProfileItem(profile) {
         if (!profile) {
            return null;
         }
         return {
            label: profile.name,
            id: profile.profileId.uniqueId,
            profileObj: profile
         };
      }

      self.onSelectionChanged = function () {
         $scope.wizardManager.clearValidationBanner();
         var selectedProfile = self.storageSelectorData.vmStorageConfig[0].vmHome.storageProfile;
         // vmStorageConfig is the actual/ultimate output from the storageSelector
         self.form.vmStorageConfig = self.storageSelectorData.vmStorageConfig;
         self.form.diskFormatSettings = self.storageSelectorState.basicModeState.diskFormatSettings;
         model.setSelectionIsValid(false);
         var selectedStorage;
         switch (self.storageSelectorState.mode) {
            case "basicMode":
               selectedStorage = self.storageSelectorState.basicModeState.selectedItem;
               if (model.isInDeployTemplateMode() ||
                     isSelectionComplete() ||
                     self.storageSelectorData.storageProfilesData.error) {
                  validateSelection(selectedStorage, selectedProfile && selectedProfile.id, self.form.diskFormatSettings);
               }
               self.form.isAdvancedStorageMode = false;
               model.virtualMachineSpecBuilder.setStorageSelectorState(self.form.storageSelectorState);
               break;
            case "advancedMode":
               selectedStorage = self.storageSelectorState.vmStorageConfigInAdvancedMode[0].vmHome.storageObj;
               self.form.isAdvancedStorageMode = true;
               validateAdvancedSelection(selectedStorage);
               if (preserveInitialSelection || (self.form.storageSelectorState.recentlySelectedStorageItemsInAdvancedMode &&
                     self.form.storageSelectorState.recentlySelectedStorageItemsInAdvancedMode.length)) {
                  model.virtualMachineSpecBuilder.setStorageSelectorState(self.form.storageSelectorState);
                  preserveInitialSelection = false;
               }
               break;
         }
      };

      function isSelectionComplete() {
         var isIncomplete = _.some(self.storageSelectorData.vmStorageConfig,
               function (vmStorageConfig /*common_ui.VmStorageConfig*/) {
            // Check the VM home storage availability
            if (!vmStorageConfig.vmHome.storageObj) {
               // The full selection is not here yet
               return true;
            }

            // If all disks should go to PMEM there is no need to validate that
            // they have a proper selection.
            if (self.storageSelectorState.storageBaselineId ===
                  storageSelectorConstants.PMEM_STORAGE_BASELINE.id) {
               return false;
            }

            // And then each disk storage availability
            return _.some(vmStorageConfig.vmDisks, function (diskStorageConfig) {
               // If nothing is selected and the disk is not on a PMEM storage
               // then selection is INCOMPLETE.
               return !diskStorageConfig.storageObj
                     && !pmemUtilService.isPMemStoragePolicy(
                           diskStorageConfig.storageProfile);
            });
         });

         return !isIncomplete;
      }

      function focusFirstElement() {
         // focus the first input on the page
         $timeout(function () {
            var firstInput = $element[0].querySelector("input:not([disabled]),select,button");
            if (firstInput) {
               firstInput.focus();
            }
         }, 0);
      }

   }
})();
