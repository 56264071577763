/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace network_ui {

   export class EditPortgroupService {

      /**
       * Compares two sets of general portgroup properties
       * @param initial - the initial data
       * @param current - the current state of the data
       * @returns {boolean}
       */
      public havePortgroupPropertiesChanged(initial: any, current: any): boolean {
         return initial.portgroupName !== current.portgroupName ||
               initial.portgroupVlanId !== current.portgroupVlanId;
      }

      /**
       * Compares two sets of general portgroup properties
       * @param initial - the initial data
       * @param current - the current state of the data
       * @returns {boolean}
       */
      public haveVirtualSwitchPropertiesChanged(initial: PropertiesPageModel,
                                                current: PropertiesPageModel): boolean {
         return initial.mtu !== current.mtu ||
               initial.numPorts !== current.numPorts;
      }

      /**
       * Compares two trafficShaping policies
       * @param initial - the initial state of the policy
       * @param current - the current state of the policy
       * @param overrideSupport
       */
      public hasTrafficShapingPolicyChanged(initial: any,
                                            current: any,
                                            overrideSupport: boolean): boolean {
         // When override is supported and both policies are NOT overridden - no neet to compare anything
         if (overrideSupport &&
               initial.overridden === false && current.overridden === false) {
            return false;
         }

         return initial.overridden !== current.overridden ||
               initial.enabled !== current.enabled ||
               (initial.enabled && current.enabled &&
               (initial.averageBandwidth !== current.averageBandwidth ||
               initial.peakBandwidth !== current.peakBandwidth ||
               initial.burstSize !== current.burstSize));
      }
      /**
       * Compares two securityPolicy stets whether they are identical or not.
       * @param initial - the initial configuration to compare against.
       * @param current - the actual configuration to compare.
       * @param overrideSupport - whether the configuration supports override flag or not.
       * @returns {boolean}
       */
      public hasSecurityPolicyChanged(initial: any,
                                      current: any,
                                      overrideSupport: boolean): boolean {

         if (overrideSupport) {
            return this.diffOverridablePolicy(initial.isPromiscuousModeOverriden,
                        initial.allowPromiscuousMode, current.isPromiscuousModeOverriden,
                        current.allowPromiscuousMode) ||
                  this.diffOverridablePolicy(initial.isMacChangesOverriden,
                        initial.macChanges, current.isMacChangesOverriden,
                        current.macChanges) ||
                  this.diffOverridablePolicy(initial.isForgedTransmitsOverriden,
                        initial.forgedTransmits, current.isForgedTransmitsOverriden,
                        current.forgedTransmits);
         } else {
            return initial.allowPromiscuousMode !== current.allowPromiscuousMode ||
                  initial.macChanges !== current.macChanges ||
                  initial.forgedTransmits !== current.forgedTransmits;
         }
      }
      /**
       * Compares two failoverPolicies stets whether they are identical or not.
       * @param initial - the initial configuration to compare against.
       * @param current - the actual configuration to compare.
       * @param overrideSupport - whether the configuration supports override flag or not.
       * @returns {boolean}
       */
      public hasFailoverPolicyChanged(initial: any,
                                      current: any,
                                      overrideSupport: boolean): boolean {

         if (overrideSupport) {
            return this.diffOverridablePolicy(initial.loadBalancingOverridden,
                        initial.loadBalancing, current.loadBalancingOverridden,
                        current.loadBalancing) ||
                  this.diffOverridablePolicy(initial.failureDetectionOverridden,
                        initial.checkBeacon, current.failureDetectionOverridden,
                        current.checkBeacon) ||
                  this.diffOverridablePolicy(initial.notifySwitchesOverridden,
                        initial.notifySwitches, current.notifySwitchesOverridden,
                        current.notifySwitches) ||
                  this.diffOverridablePolicy(initial.failbackOverridden,
                        initial.rollingOrder, current.failbackOverridden,
                        current.rollingOrder);
         } else {
            return initial.loadBalancing !== current.loadBalancing ||
                  initial.checkBeacon !== current.checkBeacon ||
                  initial.notifySwitches !== current.notifySwitches ||
                  initial.rollingOrder !== current.rollingOrder;
         }
      }

      /**
       * Compares two sets of failoverOrder elements. Mainly used for comparing whether
       * some page that contains the failoverOrder directive has been changed
       * @param initial configuration
       * @param current configuration
       * @returns {boolean}
       */
      public hasFailoverOrderChanged(initial: any,
                                     current: any,
                                     overrideSupported: boolean): boolean {
         // If override is supported, but both policies are NOT overridden - no need to compare values
         if (overrideSupported &&
               initial.failoverOrderOverridden === false &&
               current.failoverOrderOverridden === false) {
            return false;
         }

         // if override flag has changed
         if (!!initial.failoverOrderOverridden !== !!current.failoverOrderOverridden) {
            return true;
         }
         // if both not defined
         if (!initial.pnics && !current.pnics) {
            return false;
         }
         // if only some of them not defined - consider it a change
         if (!!initial.pnics && !current.pnics || !initial.pnics && !!current.pnics) {
            return true;
         }

         return this.diffArrays(initial.pnics.active, current.pnics.active) ||
               this.diffArrays(initial.pnics.standby, current.pnics.standby);

      }

      // Compare string arrays element by element
      private diffArrays(leftArr: string[], rightArr: string[]): boolean {
         if (!leftArr && !rightArr) {
            return false;
         }

         if (!leftArr || !rightArr || leftArr.length !== rightArr.length) {
            return true;
         }

         for (let i:number = 0; i < leftArr.length; i++) {
            if (leftArr[i] !== rightArr[i]) {
               return true;
            }
         }
         return false;
      }

      // Returns true only when both override flags are different, or when both policies
      // are overridden with different values
      private diffOverridablePolicy(leftPolicyOverridden: boolean,
                                    leftPolicy: string | boolean,
                                    rightPolicyOverridden: boolean,
                                    rightPolicy: string | boolean): boolean {
         if (leftPolicyOverridden && rightPolicyOverridden) {
            return leftPolicy !== rightPolicy;
         }
         return !!leftPolicyOverridden !== !!rightPolicyOverridden;
      }

   }

   angular.module("com.vmware.vsphere.client.network")
         .service("editPortgroupService", EditPortgroupService);
}
