(function () {
   'use strict';
   angular.module('com.vmware.vsphere.client.storage').controller(
         'DeviceBackingViewController', DeviceBackingViewController);

   DeviceBackingViewController.$inject = [
      '$scope',
      'vuiConstants',
      'i18nService',
      'dataService',
      'navigation'
   ];

   function DeviceBackingViewController($scope, vuiConstants, i18nService, dataService, navigation) {
      var dataModelClass = 'com.vmware.vsphere.client.h5.storage.data.datastore.VmfsDatastoreExtentData';
      var datastoreExtentItemsProperty = 'datastoreExtentItems';

      var self = this;
      self.datastoreId = navigation.getRoute().objectId;
      self.headerOptions = {
         title: i18nService.getString('StorageUi', 'datastore.manage.deviceBacking')
      };
      self.deviceBackingLoading = false;

      self.liveRefreshProperties = [
         'host',
         'summary.capacity'
      ];

      self.datagridOptions = {
         height: '100%',
         pageConfig: {
            hidePager: true
         },
         columnDefs: getColumnDefinitions(),
         sortMode: vuiConstants.grid.sortMode.SINGLE,
         selectionMode: vuiConstants.grid.selectionMode.SINGLE,
         data: [],
         selectedItems: [],
         resizable: true
      };

      self.refresh = refresh;

      self.refresh();

      $scope.$watch(function() {
         return self.datagridOptions.selectedItems;
      }, function(newValue, oldValue) {
         if (!newValue || !newValue.length || !oldValue || !oldValue.length) {
            $scope.masterDetailsViewContext.selectedItems = newValue;
         }
         if (newValue[0] !== oldValue[0]) {
            $scope.masterDetailsViewContext.selectedItems = newValue;
         }
      });

      function refresh () {
         if (self.datastoreId) {
            self.deviceBackingLoading = true;
            dataService.getData(self.datastoreId, dataModelClass)
                  .then(function (vmfsDatastoreExtentData) {
                     if (vmfsDatastoreExtentData && vmfsDatastoreExtentData[datastoreExtentItemsProperty]) {
                        self.datagridOptions.data = vmfsDatastoreExtentData[datastoreExtentItemsProperty];
                     }
                  })
                  .finally(function () {
                     self.deviceBackingLoading = false;
                  });
         }
      }

      function getColumnDefinitions() {
         return [
            {
               displayName: i18nService.getString('StorageUi', 'datastore.manage.deviceBacking.extentColumn'),
               field: 'formattedName',
               template: '#:formattedName#'
            },
            {
               displayName: i18nService.getString('StorageUi', 'datastore.manage.deviceBacking.capacityColumn'),
               field: 'capacityInB',
               template: function(item){
                  var formatted = item.formattedCapacity
                     ? item.formattedCapacity
                     : i18nService.getString('Common', 'unknown');
                  return '<span class="vertical-aligned-text">' + formatted +'</span>';
               },
               sortable: true
            }
         ];
      }
   }
})();
