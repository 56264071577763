/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace dvs_ui {

   import VspanSessionType =
         com.vmware.vim.binding.vim.dvs.VmwareDistributedVirtualSwitch$VspanSessionType;
   import VspanSessionEncapType =
         com.vmware.vim.binding.vim.dvs.VmwareDistributedVirtualSwitch$VspanSessionEncapType;

   class SessionType {
      public readonly DV_PORT_MIRROR: VspanSessionType = "dvPortMirror";
      public readonly REMOTE_MIRROR_DEST: VspanSessionType = "remoteMirrorDest";
      public readonly REMOTE_MIRROR_SOURCE: VspanSessionType = "remoteMirrorSource";
      public readonly L3_SOURCE: VspanSessionType = "encapsulatedRemoteMirrorSource";
   }

   class EncapsulationType {
      public readonly GRE: VspanSessionEncapType = "gre";
      public readonly ERSPAN2: VspanSessionEncapType = "erspan2";
      public readonly ERSPAN3: VspanSessionEncapType = "erspan3";
   }

   class TrafficDirection {
      public readonly INGRESS: string = "ingress";
      public readonly EGRESS: string = "egress";
      public readonly BOTH: string = "both";
   }

   class TcpIpNetStack {
      public readonly DEFAULT_STACK: string = "defaultTcpipStack";
      public readonly MIRROR_STACK: string = "mirror";
   }

   export class DvsSpanSessionConstants {

      public static readonly PAGE_ID: any = {
         SELECT_SESSION_TYPE: "selectSessionTypePage",
         EDIT_PROPERIES: "editPropertiesPage",
         SELECT_SOURCE_PORTS: "selectSourcePortsPage",
         SELECT_SOURCE_VLANS: "selectSourceVlansPage",
         SELECT_DESTINATION_PORTS: "selectDestinationPortsPage",
         SELECT_DESTINATION_ADDRESSES: "selectDestinationAddressesPage",
         SELECT_DESTINATION_UPLINKS: "selectDestinationUplinksPage",
         READY_TO_COMPLETE: "readyToCompletePage"
      };

      public static readonly SESSION_TYPE: SessionType = new SessionType();

      public static readonly ENCAPSULATION_TYPE: EncapsulationType = new EncapsulationType();

      public static readonly TRAFFIC_DIRECTION: TrafficDirection = new TrafficDirection();

      public static readonly TCP_IP_STACK: TcpIpNetStack = new TcpIpNetStack();

      public static readonly VLAN_ID_MIN = 1;

      public static readonly VLAN_ID_MAX = 4094;
   }
}
