/* Copyright 2015 VMware, Inc. All rights reserved. -- VMware Confidential */
/*
 * Controller for Related Objects Portlet.
 *
 */
angular.module('com.vmware.vsphere.client.commonModule')
      .controller('RelatedItemsPortletController',
      ['$scope', 'relatedItemsService',
      function($scope, relatedItemsService) {
   'use strict';

   var self = this;
   var id = $scope._route.objectId;
   if (id === 'undefined' || id === null) {
      // Get the different object type if specified
      id = $scope._view.contentSpec.metadata.objectId;
   }

   self.watchForObjects = [id];

   self.getData = function() {
      var promise = relatedItemsService.getRelatedItems(id);
      promise.then(function (data) {
         self.stackViewData = data;
      });
   };

   self.getData();
}]);