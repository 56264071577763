(function() {
   'use strict';
   angular.module('com.vmware.vsphere.client.host')
         .controller('editGraphicDeviceController', EditGraphicDeviceController);

   EditGraphicDeviceController.$inject = [
      '$scope',
      'mutationService',
      'hostServicesConstants'
   ];

   function EditGraphicDeviceController($scope, mutationService, hostServicesConstants) {
      var self = this;
      var selectedItem = $scope.modalOptions.dialogData.selectedItem;
      var hostId = $scope.modalOptions.dialogData.objectId;
      self.xorgServiceRestart = true;
      if (selectedItem.configuredType === ' ') {
         self.sharedMode = '';
      } else {
         self.sharedMode = selectedItem.configuredType;
      }

      $scope.modalOptions.onSubmit = function() {
         var hostGraphicsConfigSpec =
         {
            '_type': 'com.vmware.vim.binding.vim.host.GraphicsConfig',
            hostDefaultGraphicsType: $scope.modalOptions.dialogData.defaultGraphicsType,
            sharedPassthruAssignmentPolicy: $scope.modalOptions.dialogData.sharedPassthruAssignmentPolicy,
            deviceType: [{
               '_type': 'com.vmware.vim.binding.vim.host.GraphicsConfig$DeviceType',
               deviceId: selectedItem.pciId,
               graphicsType: self.sharedMode
            }]
         };


         mutationService.apply(
               hostId,
               'com.vmware.vim.binding.vim.host.GraphicsConfig',
               hostGraphicsConfigSpec
         );

         if(self.xorgServiceRestart){
            mutationService.apply(
               hostId,
               'com.vmware.vsphere.client.host.config.ServiceActionSpec',
               {
                  action : hostServicesConstants.action.RESTART,
                  serviceKey: "xorg"
               }
            );
         }

         $scope.closeModal();
      };
   }
})();

