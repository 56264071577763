namespace h5_vm {
   import VirtualDevice = com.vmware.vim.binding.vim.vm.device.VirtualDevice;
   import VirtualDevice$BackingInfo = com.vmware.vim.binding.vim.vm.device.VirtualDevice$BackingInfo;
   import VirtualSerialPort$DeviceBackingInfo = com.vmware.vim.binding.vim.vm.device.VirtualSerialPort$DeviceBackingInfo;
   import VirtualSerialPort$FileBackingInfo = com.vmware.vim.binding.vim.vm.device.VirtualSerialPort$FileBackingInfo;
   import VirtualSerialPort$PipeBackingInfo = com.vmware.vim.binding.vim.vm.device.VirtualSerialPort$PipeBackingInfo;
   import VirtualSerialPort$ThinPrintBackingInfo = com.vmware.vim.binding.vim.vm.device.VirtualSerialPort$ThinPrintBackingInfo;
   import VirtualSerialPort$URIBackingInfo = com.vmware.vim.binding.vim.vm.device.VirtualSerialPort$URIBackingInfo;
   import VirtualSerialPortOption = com.vmware.vim.binding.vim.vm.device.VirtualSerialPortOption;
   import VirtualSerialPortOption$DeviceBackingOption = com.vmware.vim.binding.vim.vm.device.VirtualSerialPortOption$DeviceBackingOption;
   import VirtualSerialPortOption$FileBackingOption = com.vmware.vim.binding.vim.vm.device.VirtualSerialPortOption$FileBackingOption;
   import VirtualSerialPortOption$PipeBackingOption = com.vmware.vim.binding.vim.vm.device.VirtualSerialPortOption$PipeBackingOption;
   import VirtualSerialPortOption$ThinPrintBackingOption = com.vmware.vim.binding.vim.vm.device.VirtualSerialPortOption$ThinPrintBackingOption;
   import VirtualDeviceOption$BackingOption = com.vmware.vim.binding.vim.vm.device.VirtualDeviceOption$BackingOption;
   import VirtualSerialPortOption$URIBackingOption = com.vmware.vim.binding.vim.vm.device.VirtualSerialPortOption$URIBackingOption;
   import SerialInfo = com.vmware.vim.binding.vim.vm.SerialInfo;
   import ElementDescription = com.vmware.vim.binding.vim.ElementDescription;
   import VirtualSerialPort = com.vmware.vim.binding.vim.vm.device.VirtualSerialPort;
   import VirtualDevice$ConnectInfo = com.vmware.vim.binding.vim.vm.device.VirtualDevice$ConnectInfo;
   import ChoiceOption = com.vmware.vim.binding.vim.option.ChoiceOption;

   export class VmHwSerialPort extends h5_vm.EditableVirtualDeviceClass {

      public vm: VmConfig;
      private rawDevice: VirtualSerialPort;
      private option: VirtualSerialPortOption | null = null;

      private deviceBacking: VirtualSerialPort$DeviceBackingInfo = new VirtualSerialPort$DeviceBackingInfo();
      private fileBacking: VirtualSerialPort$FileBackingInfo = new VirtualSerialPort$FileBackingInfo();
      private pipeBacking: VirtualSerialPort$PipeBackingInfo = new VirtualSerialPort$PipeBackingInfo();
      private thinPrintBacking: VirtualSerialPort$ThinPrintBackingInfo = new VirtualSerialPort$ThinPrintBackingInfo();
      private uriBacking: VirtualSerialPort$URIBackingInfo = new VirtualSerialPort$URIBackingInfo();

      private deviceOption: VirtualSerialPortOption$DeviceBackingOption;
      private fileOption: VirtualSerialPortOption$FileBackingOption;
      private pipeOption: VirtualSerialPortOption$PipeBackingOption;
      private thinPrintOption: VirtualSerialPortOption$ThinPrintBackingOption;
      private uriOption: VirtualSerialPortOption$URIBackingOption;

      constructor(original: VirtualDevice, deviceDiffService: any, vmDeviceInfoService: any, vmHardwareUtil: any) {
         super(original, deviceDiffService, vmDeviceInfoService, vmHardwareUtil);
         if (!original.backing) {
            original.backing = this.fileBacking;
         }
      }

      protected onVmConfigAttached(): void {
         this.rawDevice = super.getCurrentDevice() as VirtualSerialPort;
         this.option = <VirtualSerialPortOption> this.vm.getDeviceOption(this.rawDevice);
         this.findOptions();
         this.findBacking();
         return;
      }

      private findBacking(): void {
         if (!this.rawDevice) {
            return;
         }
         const backing: VirtualDevice$BackingInfo = this.rawDevice.backing;

         if (!backing) {
            return;
         }

         const type: string = backing._type;

         if (type === this.fileBacking._type) {
            this.fileBacking = <VirtualSerialPort$FileBackingInfo> backing;
         } else if (type === this.deviceBacking._type) {
            this.deviceBacking = <VirtualSerialPort$DeviceBackingInfo> backing;

         } else if (backing._type === this.pipeBacking._type) {
            this.pipeBacking = <VirtualSerialPort$PipeBackingInfo> backing;
         } else if (this.uriBacking._type === backing._type) {
            this.uriBacking = <VirtualSerialPort$URIBackingInfo> backing;
         }

         const defaultChoice = (choices: ChoiceOption): string => {
            if (!choices) {
               return "";
            }
            const index: number = choices.defaultIndex;
            const array: ElementDescription[] = choices.choiceInfo;
            const retVal: any = array[index].key;
            return retVal;
         };

         if (!this.pipeBacking.endpoint) {
            this.pipeBacking.endpoint = defaultChoice(this.pipeOption.endpoint);
         }

         if (!this.pipeBacking.hasOwnProperty("noRxLoss")) {
            this.pipeBacking.noRxLoss = this.pipeOption.noRxLoss.defaultValue;
         }

         if (!this.uriBacking.direction) {
            this.uriBacking.direction = defaultChoice(this.uriOption.directions);
         }

         this._vspc = this.notEmpty(this.proxyURI);
      }

      public attachLabels(i18n0: any): void {

         const i18n = (key: string): string => {
            return i18n0("SerialPortConfig." + key);
         };

         // const attachLabel = (obj: any, key: string) => {
         //    const text = i18n(key);
         //    obj.label = text;
         // };
         //
         // attachLabel(this.fileBacking, "File");
         // attachLabel(this.deviceBacking, "Device");
         // attachLabel(this.pipeBacking, "Pipe");
         // attachLabel(this.uriBacking, "Network");
      }

      // Common secion: Connected/Start connected
      private _connectable: VirtualDevice$ConnectInfo = new VirtualDevice$ConnectInfo();

      private get connectable(): VirtualDevice$ConnectInfo {
         if (this.rawDevice.connectable) {
            return this.rawDevice.connectable;
         } else {
            return this._connectable;
         }
      }

      public get connected(): boolean {
         return this.connectable.connected;
      }

      public set connected(value: boolean) {
         this.connectable.connected = value;
      }

      public get startConnected(): boolean {
         return this.connectable.startConnected;
      }

      public set startConnected(value: boolean) {
         this.connectable.startConnected = value;
      }

      public removeDisabled(): boolean {
         if (!this.option) {
            return false;
         }
         return super.removeDeviceDisabled(this.option);
      }

      // Device Backing section

      public get deviceList(): Array<string> {
         const list: SerialInfo[] = this.vm.getSerialPortList();
         let retVal: string[] = [];

         for (const item of list) {
            retVal.push(item.name);
         }

         return retVal;
      }

      public get deviceValue(): string {

         if (!this.deviceBacking.deviceName) {
            const list: string [] = this.deviceList;
            this.deviceBacking.deviceName = list && list.length > 0 ? list[0] : "";
         }
         return this.deviceBacking.deviceName;
      }

      public set deviceValue(item: string) {
         this.deviceBacking.deviceName = item;
      }

      private findOptions(): void {
         this.option = <VirtualSerialPortOption | null>this.vm.getDeviceOption(this.rawDevice);
         const array: VirtualDeviceOption$BackingOption[] = this.option ? this.option.backingOption : [];
         const prefix: string = "com.vmware.vim.binding.vim.vm.device.VirtualSerialPort$";
         const suffix = "BackingInfo";

         const fix = (str: string): string => {
            return prefix + str + suffix;
         };

         for (const item of array) {
            const typeClass: string = (<any> item.type).typeClass;
            if (typeClass === fix("Device")) {
               this.deviceOption = <VirtualSerialPortOption$DeviceBackingOption> item;
            } else if (typeClass === fix("File")) {
               this.fileOption = <VirtualSerialPortOption$FileBackingOption> item;
            } else if (typeClass === fix("URI")) {
               this.uriOption = <VirtualSerialPortOption$URIBackingOption> item;
            } else if (typeClass === fix("ThinPrint")) {
               this.thinPrintOption = <VirtualSerialPortOption$ThinPrintBackingOption> item;
            } else if (typeClass === fix("Pipe")) {
               this.pipeOption = <VirtualSerialPortOption$PipeBackingOption>item;
            }
         }
      }

      // COMMON PROPERTIES (all backing types)

      public get yield(): boolean {
         return this.rawDevice.yieldOnPoll;
      }

      public set yield(value) {
         this.rawDevice.yieldOnPoll = value;
      }

      // FILE BACKING

      public get file(): string {
         if (!this.fileBacking) {
            return "";
         }
         return this.fileBacking.fileName;
      }

      public set file(value: string) {
         if (!this.fileBacking) {
            return;
         }
         this.fileBacking.fileName = value;
      }

      public get backing(): string {
         return this.rawDevice.backing._type;
      }

      public set backing(value: string) {
         const backing: VirtualDevice$BackingInfo = this.findBackingByType(value);
         this.rawDevice.backing = backing;
      }

      private findBackingByType(type: string): VirtualDevice$BackingInfo {

         switch (type) {
            case this.fileBacking._type:
               return this.fileBacking;
            case this.deviceBacking._type:
               return this.deviceBacking;
            case this.pipeBacking._type:
               return this.pipeBacking;
            case this.uriBacking._type:
               return this.uriBacking;
         }
         return this.fileBacking;
      }

      public get backingType(): any {
         if (!this.rawDevice.backing) {
            return "";
         }
         return this.rawDevice.backing._type;
      }

      public get backingList(): any[] {
         return [this.fileBacking._type, this.deviceBacking._type, this.pipeBacking._type, this.uriBacking._type];
      }

      public get hasFileBacking(): boolean {
         return this.backingType === this.fileBacking._type;
      }

      public get hasDeviceBacking(): boolean {
         return this.backingType === this.deviceBacking._type;
      }

      public get hasPipeBacking(): boolean {
         return this.backingType === this.pipeBacking._type;
      }

      public get hasURIBacking(): boolean {
         return this.backingType === this.uriBacking._type;
      }

      // URI backing section (aka Network backing)

      public get serviceURI(): string {
         return this.uriBacking.serviceURI;
      }

      public set serviceURI(value: string) {
         this.uriBacking.serviceURI = value;
      }

      private _vspc: boolean = false;

      public get vspc(): boolean {
         return this._vspc;
      }

      public set vspc(value: boolean) {
         if (!value) {
            this.uriBacking.proxyURI = "";
         }
         this._vspc = value;
      }

      public get proxyURI(): string {
         return this.uriBacking.proxyURI;
      }

      public set proxyURI(value: string) {
         this.uriBacking.proxyURI = value;
      }

      public get directionList(): any[] {
         // these are values of enum
         return ["client", "server"];
      }

      public get direction(): string {
         return this.uriBacking.direction;
      }

      public set direction(value: string) {
         this.uriBacking.direction = value;
      }

      // PIPE BACKING
      public get pipe(): string {
         return this.pipeBacking.pipeName;
      }

      public set pipe(value: string) {
         this.pipeBacking.pipeName = value;
      }

      public get nearEnd(): string {
         return this.pipeBacking.endpoint;
      }

      public set nearEnd(value: string) {
         this.pipeBacking.endpoint = value;
      }

      public get nearEndList(): any[] {
         if (!this.pipeOption || !this.pipeOption.endpoint) {
            return [];
         }
         return this.pipeOption.endpoint.choiceInfo.map((value: ElementDescription) => {
            return value.key;
         });
      }

      public get farEndList(): boolean[] {
         return [false, true];
      }

      public get farEnd(): boolean {
         return this.pipeBacking.noRxLoss;
      }

      public set farEnd(value: boolean) {
         this.pipeBacking.noRxLoss = value;
      }

      //   -------------------------------------------------------------------

      public isValid(): boolean {
         if (super.isMarkedForRemoval()) {
            return true;
         }

         if (this.hasFileBacking) {
            return this.isFileValid();
         } else if (this.hasPipeBacking) {
            return this.isPipeValid();
         } else if (this.hasURIBacking) {
            return this.isServiceURIValid() && this.isProxyURIValid();
         } else if (this.hasDeviceBacking) {
            return this.isEsxDeviceValid();
         }
         return true;
      }

      private notEmpty(str: string): boolean {
         if (!str) {
            return false;
         }
         if (str.length < 1) {
            return false;
         }
         return true;
      }

      public isFileValid(): boolean {
         if (super.isMarkedForRemoval()) {
            return true;
         }
         return this.notEmpty(this.file);
      }

      public getFileError(): string {
         return super.i18n("SerialPortPage.FileRequired");
      }

      public isPipeValid(): boolean {
         if (super.isMarkedForRemoval()) {
            return true;
         }
         return this.notEmpty(this.pipe);
      }

      public getPipeError(): string {
         return super.i18n("SerialPortPage.PipeRequired");
      }

      public isEsxDeviceValid(): boolean {
         if (super.isMarkedForRemoval()) {
            return true;
         }
         return this.notEmpty(this.deviceValue);
      }

      public isServiceURIValid(): boolean {
         if (super.isMarkedForRemoval()) {
            return true;
         }
         return this.notEmpty(this.serviceURI);
      }

      public getServiceURIError(): string {
         return super.i18n("SerialPortPage.ServiceURIRequired");
      }

      public isProxyURIValid(): boolean {
         if (super.isMarkedForRemoval()) {
            return true;
         }
         if (!this.vspc) {
            return true;
         }
         return this.notEmpty(this.proxyURI);
      }

      public getProxyURIError(): string {
         return super.i18n("SerialPortPage.ConcentratorURIRequired");
      }
   }

   function GetSerilPortFactory(deviceDiffService: any, vmDeviceInfoService: any, vmHardwareUtil: any): any {
      let factoryFn: Function = function (rawDevice: any): any {
         let instance = new VmHwSerialPort(rawDevice, deviceDiffService, vmDeviceInfoService, vmHardwareUtil);
         return instance;
      };
      return factoryFn;
   }

   angular.module('com.vmware.vsphere.client.vm')
         .factory('VmHwSerialPort',
               ['deviceDiffService', 'vmDeviceInfoService', 'vmHardwareUtil', GetSerilPortFactory]);
}
