(function () {
   'use strict';
   angular.module('com.vmware.vsphere.client.vm').factory('VirtualController', [
      'EditableVirtualDevice',
      function(EditableVirtualDevice) {
         return function(device, attributes){
            EditableVirtualDevice.call(this, device);

            this.getMaxDisks = function() {
               return attributes.maxDisks;
            };

            this.getDiskNodeIndices = function(){
               return _.range(0, this.getMaxDisks());
            };

            this.getMaxCdroms = function() {
               return attributes.maxCdroms;
            };

            this.getCdromNodeIndices = function(){
               return _.range(0, this.getMaxCdroms());
            };
         };
      }
   ]);
})();
