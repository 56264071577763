/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */

import DvsAddHostWizardPages = dvs_ui.DvsAddHostWizardPages;
namespace dvs_ui {

   export class DvsAddHostWizardPages {

      public static $inject =
            ["i18nService",
               "dvsSelectHostsPageValidator",
               "dvsSelectPhysicalAdaptersPageValidator",
               "dvsSelectVirtualAdaptersPageValidator",
               "dvsSelectVirtualMachinesPageValidator",
               "vuiConstants"];

      constructor(private i18nService: any,
                  private dvsSelectHostsPageValidator: DvsSelectHostsPageValidator,
                  private dvsSelectPhysicalAdaptersPageValidator: DvsSelectPhysicalAdaptersPageValidator,
                  private dvsSelectVirtualAdaptersPageValidator: DvsSelectVirtualAdaptersPageValidator,
                  private dvsSelectVirtualMachinesPageValidator: DvsSelectVirtualMachinesPageValidator,
                  private vuiConstants: any) {
      }

      /**
       * Creates an array of page definitions for the Add and Manage Hosts wizard.
       */
      public createPages(wizardScope: DvsAddHostWizardScope): WizardPage[] {

         let selectWorkflowPage: WizardPage = {
            id: DvsAddHostWizardConstants.page.DVS_SELECT_WORKFLOW_PAGE,
            title: this.i18nService.getString("DvsUi",
                  "SelectOperationPage.title"),
            description: this.i18nService.getString("DvsUi",
                  "SelectOperationPage.description"),
            contentUrl: "dvs-ui/resources/dvs/addhost/views/selectOperationPage.html",
            validationFunction: () => {
               return [];
            }
         };

         let selectHostsPage: WizardPage = {
            id: DvsAddHostWizardConstants.page.DVS_SELECT_HOSTS_PAGE,
            title: this.i18nService.getString("DvsUi", "SelectHostsPage.title"),
            description: this.i18nService.getString("DvsUi",
                  "SelectHostsPage.addhosts.description"),
            contentUrl: "dvs-ui/resources/dvs/addhost/views/dvsSelectHostsPage.html",
            validationFunction: () => {
               return this.dvsSelectHostsPageValidator.getValidationError(wizardScope.model.selectHostsPageModel.hosts);
            },
            validationParams: ["model.selectHostsPageModel.hosts"]
         };

         let selectHostsToRemovePage: WizardPage = {
            id: DvsAddHostWizardConstants.page.DVS_SELECT_HOSTS_TO_REMOVE_PAGE,
            title: this.i18nService.getString("DvsUi", "SelectHostsPage.title"),
            description: this.i18nService.getString("DvsUi",
                  "SelectHostsPage.removeHosts.description"),
            contentUrl: "dvs-ui/resources/dvs/addhost/views/dvsSelectHostsToRemovePage.html",
            validationFunction: () => {
               return this.dvsSelectHostsPageValidator.getValidationError(wizardScope.model.selectHostsToRemovePageModel.hosts);
            },
            validationParams: ["model.selectHostsToRemovePageModel.hosts"]
         };

         let selectHostsToManagePage: WizardPage = {
            id: DvsAddHostWizardConstants.page.DVS_SELECT_HOSTS_TO_MANAGE_PAGE,
            title: this.i18nService.getString("DvsUi", "SelectHostsPage.title"),
            description: this.i18nService.getString("DvsUi",
                  "SelectHostsPage.managehosts.description"),
            contentUrl: "dvs-ui/resources/dvs/addhost/views/dvsSelectHostsToManagePage.html",
            validationFunction: () => {
               return this.dvsSelectHostsPageValidator.getValidationError(wizardScope.model.selectHostsToManagePageModel.hosts);
            },
            validationParams: ["model.selectHostsToManagePageModel.hosts"]
         };

         let selectPhysicalAdaptersPage: WizardPage = {
            id: DvsAddHostWizardConstants.page.DVS_SELECT_PHYSICAL_ADAPTERS_PAGE,
            title: this.i18nService.getString("DvsUi",
                  "SelectPhysicalAdaptersPage.title.default"),
            description: this.i18nService.getString("DvsUi",
                  "SelectPhysicalAdaptersPage.description"),
            contentUrl: "dvs-ui/resources/dvs/addhost/views/dvsSelectPhysicalAdaptersPage.html",
            validationParams: ["model.selectPhysicalAdaptersPageModel.generationNumber"],
            onCommit: () => {
               return this.dvsSelectPhysicalAdaptersPageValidator.validate(
                     wizardScope.model.selectPhysicalAdaptersPageModel)
                     .then((isValid: boolean): boolean => {
                        wizardScope.wizardConfig.currentPage.state = isValid
                              ? this.vuiConstants.wizard.pageState.COMPLETED
                              : this.vuiConstants.wizard.pageState.INCOMPLETE;
                        return isValid;
                     });
            }
         };

         let selectVirtualAdaptersPage: WizardPage = {
            id: DvsAddHostWizardConstants.page.DVS_SELECT_VIRTUAL_ADAPTERS_PAGE,
            title: this.i18nService.getString("DvsUi",
                  "SelectVirtualAdaptersPage.title.default"),
            description: this.i18nService.getString("DvsUi",
                  "SelectVirtualAdaptersPage.description"),
            contentUrl: "dvs-ui/resources/dvs/addhost/views/dvsSelectVirtualAdaptersPage.html",
            validationFunction: () => {
               return this.dvsSelectVirtualAdaptersPageValidator.getValidationError(
                     wizardScope.model.selectVirtualAdaptersPageModel.hosts,
                     wizardScope.model.selectPhysicalAdaptersPageModel.hosts,
                     wizardScope.model.selectVirtualAdaptersPageModel.hosts);
            }
         };

         let selectVirtualMachinesPage: WizardPage = {
            id: DvsAddHostWizardConstants.page.DVS_SELECT_VIRTUAL_MACHINES_PAGE,
            title: this.i18nService.getString("DvsUi",
                  "SelectVirtualMachinesPage.title"),
            description: this.i18nService.getString("DvsUi",
                  "SelectVirtualMachinesPage.description"),
            contentUrl: "dvs-ui/resources/dvs/addhost/views/dvsSelectVirtualMachinesPage.html",
            validationFunction: () => {
               return this.dvsSelectVirtualMachinesPageValidator.getValidationError(
                     wizardScope.model.selectVirtualMachinesPageModel);
            }
         };

         let readyToCompletePage: WizardPage = {
            id: DvsAddHostWizardConstants.page.DVS_READY_TO_COMPLETE_PAGE,
            title: this.i18nService.getString("DvsUi",
                  "AddHostWizard.readyToCompletePage.title"),
            description: this.i18nService.getString("DvsUi",
                  "AddHostWizard.readyToCompletePage.description"),
            contentUrl: "dvs-ui/resources/dvs/addhost/views/dvsAddHostReadyToCompletePage.html",
            onCommit: function () {
               return true;
            }
         };

         let pages: WizardPage[] = [selectWorkflowPage,
            selectHostsPage,
            selectHostsToRemovePage,
            selectHostsToManagePage,
            selectPhysicalAdaptersPage,
            selectVirtualAdaptersPage,
            selectVirtualMachinesPage,
            readyToCompletePage];

         if (wizardScope.model.selectOperationPageModel.selectedOperationType ===
               DvsAddHostWizardConstants.operationType.MIGRATE_NETWORKING) {
            // Remove workflow and host selection pages.
            pages.splice(0 /*start from the beginning*/, 4 /*remove 4 pages*/);
         }

         return pages;
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .service("dvsAddHostWizardPages", DvsAddHostWizardPages);
}

