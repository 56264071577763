namespace h5_vm {

   import Privileges = h5_vm.Privileges;
   import VmWorkflowMode = h5_vm.VmWorkflowMode;
   import VmHardwareUtil = h5_vm.VmHardwareUtil;

   export class VmAdvancedOptionsService {

      private i18n: Function;
      static $inject= [
         "i18nService",
         "vmHardwareUtil"
      ];

      constructor(private i18nService: any,
                  private vmHardwareUtil: any) {
         this.i18n = i18nService.getString;
      }

      private hasPrivilegesToEditSwapFileLocation(privileges: Array<string>): boolean {
         return this.vmHardwareUtil.checkPrivileges(privileges, [Privileges.VM_CONFIG_SWAPFILEPLACEMENT]);
      }

      private hasCapabilitiesToEditSwapFileLocation(capabilities: any): boolean {
         return capabilities.swapPlacementSupported;
      }

      isEditSwapFileLocationDisabled(privileges: Array<string>, capabilities: any): boolean {
         return !this.hasPrivilegesToEditSwapFileLocation(privileges) || !this.hasCapabilitiesToEditSwapFileLocation(capabilities);
      }

      private hasPrivilegesToDisableAcceleration(privileges: Array<string>): boolean {
         return this.vmHardwareUtil.checkPrivileges(privileges, [Privileges.VM_CONFIG_SETTINGS]);
      }

      isDisableAccelerationDisabled(privileges: Array<string>, workflow: VmWorkflowMode): boolean {
         if (workflow === VmWorkflowMode.CreateMode) {
            return false; // in create enabled
         }

         return !this.hasPrivilegesToDisableAcceleration(privileges);
      }

      isEnableLoggingDisabled(privileges: Array<string>, workflow: VmWorkflowMode): boolean {
         if (workflow === VmWorkflowMode.CreateMode) {
            return false; // in create enabled
         }

         return !this.vmHardwareUtil.checkPrivileges(privileges, [Privileges.VM_CONFIG_SETTINGS]);
      }

      isEditMonitorTypeDisabled(vmConfigContext: any, workflow: VmWorkflowMode): boolean {

         let ctx = vmConfigContext;

         if (VmWorkflowMode.CreateMode === workflow) {
            return false; // edit Enabled
         }

         if (h5_vm.VmHardwareUtil.noVmSettingsPermission(ctx)) {
            return true; // edit Disabled;
         }

         if (VmWorkflowMode.CloneMode === workflow) {
            return false; // edit Enabled
         }

         let capabilities = VmHardwareUtil.getVmCapabilities(ctx);
         let poweredOnChangeSupported: boolean = capabilities.poweredOnMonitorTypeChangeSupported;
         let isVmPoweredOff: boolean = VmHardwareUtil.isPoweredOff(ctx);

         if (!isVmPoweredOff && (poweredOnChangeSupported !== true)) {
            return true; // edit Disabled
         }

         if (this.vmHardwareUtil.isFtVmConfigContext(ctx)) {
            return true; // edit Disabled
         }

         if (VmHardwareUtil.hasRecordReplay(ctx)) {
            return true; // edit Disabled
         }

         return false; // edit Enabled
      }

      getMonitorTypeList(vmConfigContext: any): Array<any> {
         let list = vmConfigContext && vmConfigContext.environment
               && vmConfigContext.environment.configOption
               && vmConfigContext.environment.configOption.supportedMonitorType;

         if (!list || list.length === 0) {
            return ["release", "debug"];
         }

         return list;
      }

      getLsLevelList(vmConfigContext: any): Array<string> {
         return ["normal", "high"];
      }

      isEditingLsLevelVisible = function (vmConfigContext: any) {

         let host = vmConfigContext.environment.hostCapability;
         if (host) {
            return !!host.latencySensitivitySupported;
         } else {
            return false;
         }
      };

      isEditingLsLevelDisabled(vmConfigContext: any, workflow: VmWorkflowMode): boolean {
         let ctx = vmConfigContext;

         if (!this.isEditingLsLevelVisible(ctx)) {
            return true; // edit Disabled
         }

         if (VmWorkflowMode.CreateMode === workflow) {
            return false; // edit enabled
         }

         if (h5_vm.VmHardwareUtil.noVmSettingsPermission(ctx) ||
               h5_vm.VmHardwareUtil.noVmResourcePermission(ctx)) {
            return true; // edit Disabled;
         }

         if (VmWorkflowMode.CloneMode === workflow) {
            return false; // edit Enabled
         }

         return false;
      }
   }

   angular.module("com.vmware.vsphere.client.vm")
         .service("vmAdvancedOptionsService", VmAdvancedOptionsService);
}
