/* Copyright 2018 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace h5_vm {
   import VmCryptoUnlockSpec = com.vmware.vsphere.client.h5.vm.data.VmCryptoUnlockSpec;
   import ActionEvaluation = com.vmware.vise.actionsfw.ActionEvaluation;

   export class UnlockVmService {
      static $inject: string[] = ["mutationService", "actionConfirmationService",
         "i18nService", "defaultUriSchemeUtil"];

      constructor(private mutationService: any, private actionConfirmationService: any,
            private i18nService: any, private defaultUriSchemeUtil: any) {
      }

      public unlockVm(actionEval: ActionEvaluation, availableTargets: string[], context?: any) {
         let entityId: string = context && context.entityId
               ? context.entityId
               : availableTargets[0];
         if (actionEval && actionEval.action && actionEval.context) {
            actionEval.action.confirmationText = this.i18nService.getString("VmUi", "cryptoUnlockVm.dialog.message");
            actionEval.context.targetObjects = [this.defaultUriSchemeUtil.getManagedObjectReference(entityId)];
         }

         this.actionConfirmationService.confirmIfRequired(actionEval, () => {this.applyUnlockVM(entityId);});
      }

      private applyUnlockVM(entityId: string): boolean {
         let unlockSpec: VmCryptoUnlockSpec = new VmCryptoUnlockSpec();
         this.mutationService.apply(entityId,
               unlockSpec._type,
               unlockSpec);
         return true;
      }
   }

   angular.module("com.vmware.vsphere.client.vm")
         .service("unlockVmService", UnlockVmService);
}
