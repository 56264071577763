namespace h5_vm {
   const TPM_EXPORT_ACTION = "vsphere.core.vm.tpm.export";

   class TpmCertListController implements ng.IComponentController {
      static $inject: string[] = [
         'i18nService',
         'vuiConstants',
         'commonActionBarService',
         'columnRenderersRegistry',
         'navigation',
         'vmTpmService',
         '$scope',
         'visibilityAwareDataUpdateNotificationService',
         '$element',
         'vcH5ConstantsService'
      ];

      private vmId: string;
      private i18n: Function;
      private loading: boolean;
      // public certificateInfos: any[];
      public datagridOptions: any;
      private masterDetailsViewContext: any;

      constructor(private i18nService: any,
                  private vuiConstants: any,
                  private commonActionBarService: any,
                  private columnRenderersRegistry: any,
                  private navigation: any,
                  private vmTpmService: any,
                  private $scope: any,
                  private visibilityAwareDataUpdateNotificationService: any,
                  private $element: any,
                  private vcH5ConstantsService: any) {
         this.i18n = i18nService.getString;
         this.vmId = this.navigation.getRoute().objectId;
      }

      private getColumnDefinitions() {
         let textRenderer = this.columnRenderersRegistry.getColumnRenderer('text');
         return [
            {
               displayName: this.i18nService.getString("VmUi", "certificate.type"),
               field: "algorithmName",
               template: (item: any) => {
                  return textRenderer(["algorithmName"], item);
               }
            },
            {
               displayName: this.i18nService.getString("VmUi", "certificate.publicKeyAlgorithmName"),
               field: "publicKeyAlgorithmName",
               template: (item: any) => {
                  return textRenderer(["publicKeyAlgorithmName"], item);
               }
            },
            {
               displayName: this.i18nService.getString("VmUi", "certificate.issuer"),
               field: "issuerNameCN",
               template: (item: any) => {
                  return textRenderer(["issuerNameCN"], item);
               }
            }
         ];
      }

      public $onInit() {
         this.datagridOptions = {
            data: [],
            height: "100%",
            columnDefs: this.getColumnDefinitions(),
            selectionMode: this.vuiConstants.grid.selectionMode.SINGLE,
            resizable: true,
            selectedItems: [],
            actionBarOptions: {actions: []}
         };

         this.$scope.comparator = function () {
            // This is for selecting the single Certificate item by default when the TPM list is displayed
            return true;
         };

         this.$scope.$watch(() => {
            return this.datagridOptions.selectedItems;
         }, (newValue: any, oldValue: any) => {
            if (newValue !== oldValue) {
               if (this.masterDetailsViewContext) {
                  this.masterDetailsViewContext.selectedItems = newValue;
                  this.updateActionBar();
               }
            }
         });
         this.refresh();
         this.updateActionBar();
         this.subscribeForUpdates();
      }

      public refresh = (): void => {
         this.loading = true;
         this.vmTpmService.getCertificates(this.vmId)
            .then((result: any) => {
               this.datagridOptions.data = result;
            })
            .finally(() => {
               this.loading = false;
            });
      };

      private updateActionBar() {
         let self = this;
         this.commonActionBarService.updateActionBar(this.datagridOptions.actionBarOptions,
            "actions", [this.vmId],
               [ {
                  actionId: TPM_EXPORT_ACTION,
                  isActionAvailable: () => {
                     return (self.datagridOptions.selectedItems.length === 1);
                  },
                  getActionInvocationContext: () => {
                     return self.datagridOptions.selectedItems[0];
                  }
               } ]);
      }

      private subscribeForUpdates() {
         var self = this;
         var dataUpdateEventsConfig:any = {};
         dataUpdateEventsConfig[self.vcH5ConstantsService.DATA_REFRESH_INVOCATION_EVENT] = {
            handler: self.refresh
         };

         dataUpdateEventsConfig[self.vcH5ConstantsService.MODEL_CHANGED_EVENT] = {
            handler: self.refresh,
            checker: (event: any, objectChangeInfo: any) => {
               let changeId = null;
               if (objectChangeInfo.operationType === 'CHANGE') {
                  changeId = objectChangeInfo.objectId;
               }

               return (self.vmId === changeId);
            }
         };

         this.visibilityAwareDataUpdateNotificationService.subscribeForDataUpdate(
               this.$element,
               this.refresh,
               dataUpdateEventsConfig
            );
      }
   }

   angular.module("com.vmware.vsphere.client.vm")
      .component("tpmCertListComponent", {
         templateUrl: "vm-ui/resources/vm/views/configure/tpm/tpm-cert-list.component.html",
         bindings: {
            masterDetailsViewContext: "<"
         },
         controller: TpmCertListController
      });
}
