/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace dvs_ui {

   export class DvsPortsConstants {
      public static readonly VM_VNIC: string = "vmVnic";
      public static readonly PNIC: string = "pnic";
      public static readonly VMK_VNIC: string = "hostVmkVnic";
      public static readonly LIVE_REFRESH_PROPERTIES: string[] = [
         "config.defaultPortConfig.vlan.vlanId",
         "config.defaultPortConfig.vlan",
         "config.defaultPortConfig.securityPolicy.allowPromiscuous.value",
         "config.defaultPortConfig.securityPolicy.macChanges.value",
         "config.defaultPortConfig.securityPolicy.forgedTransmits.value",
         "config.defaultPortConfig.uplinkTeamingPolicy.policy.value",
         "config.defaultPortConfig.uplinkTeamingPolicy.failureCriteria.checkBeacon.value",
         "config.defaultPortConfig.uplinkTeamingPolicy.notifySwitches.value",
         "config.defaultPortConfig.uplinkTeamingPolicy.rollingOrder.value",
         "config.defaultPortConfig.uplinkTeamingPolicy.uplinkPortOrder.standbyUplinkPort",
         "config.defaultPortConfig.uplinkTeamingPolicy.uplinkPortOrder.activeUplinkPort",
         "config.defaultPortConfig.inShapingPolicy.averageBandwidth.value",
         "config.defaultPortConfig.inShapingPolicy.peakBandwidth.value",
         "config.defaultPortConfig.inShapingPolicy.burstSize.value",
         "config.defaultPortConfig.inShapingPolicy.enabled.value",
         "config.defaultPortConfig.outShapingPolicy.averageBandwidth.value",
         "config.defaultPortConfig.outShapingPolicy.peakBandwidth.value",
         "config.defaultPortConfig.outShapingPolicy.burstSize.value",
         "config.defaultPortConfig.outShapingPolicy.enabled.value",
         "config.defaultPortConfig.ipfixEnabled.value",
         "config.defaultPortConfig.blocked.value"
      ];
   }
}
