(function () {
   'use strict';

   /**
    * Host firewall connections grid controller.
    */
   angular.module('com.vmware.vsphere.client.host')
         .controller('FirewallSettingsController', FirewallSettingsController);

   FirewallSettingsController.$inject =
         ['$scope', 'vuiConstants', 'i18nService', 'dataService'];

   function FirewallSettingsController(
         $scope, vuiConstants, i18nService, dataService) {
      var self = this;

      self.hostId = $scope._route.objectId;
      self.watchForObjects = [self.hostId];
      self.liveRefreshProperties = ['config.firewall.ruleset', 'config.service.service'];
      self.datagridOptions = getDataGridOptions();
      self.headerOptions = getHeaderOptions();
      self.getViewData = getViewData;

      getViewData();

      function getViewData() {
         self.loading = true;
         dataService
               .getData(
                     self.hostId,
                     'com.vmware.vsphere.client.h5.host.model.FirewallSettingsData')
               .then(function (data) {
                  var gridData = _.map(data.connections, function(connection) {
                     var inboundConnection = angular.merge({}, connection);
                     inboundConnection.directionInfo =
                           inboundConnection.inboundDirectionInfo;
                     delete inboundConnection.inboundDirectionInfo;
                     delete inboundConnection.outboundDirectionInfo;

                     var outboundConnection = angular.merge({}, connection);
                     outboundConnection.directionInfo =
                           outboundConnection.outboundDirectionInfo;
                     delete outboundConnection.inboundDirectionInfo;
                     delete outboundConnection.outboundDirectionInfo;

                     return [inboundConnection, outboundConnection];
                  });
                  gridData = _.flatten(gridData);
                  gridData = _.filter(gridData, function(connection) {
                     return connection.enabled &&
                           (!_.isEmpty(connection.directionInfo.rawTcpPorts) ||
                              !_.isEmpty(connection.directionInfo.rawUdpPorts));
                  });

                  self.datagridOptions.data = gridData;
                  self.loading = false;
               });
      }

      function getHeaderOptions() {
         return {
            title: getString('securityProfile.firewall'),
            objectId: self.hostId,
            actions: [{
               actionUid: 'vsphere.core.host.actions.editFirewallSettings',
               customLabel: i18nService.getString('HostUi', 'editFirewallSettingsButton.label')
            }]
         };
      }

      function getDataGridOptions() {
         return {
            height: '100%',
            selectionMode: vuiConstants.grid.selectionMode.NONE,
            selectedItems: [],
            data: [],
            columnDefs: getColumnDefs(),
            sortOrder: [{
               field: 'serviceName',
               dir: 'asc'}],
            groupOrder: [{
               field: 'directionInfo.direction',
               dir: 'asc'}]
         };
      }

      function getColumnDefs() {
         return [
            {
               displayName: getString("firewallConnection.connection"),
               field: 'directionInfo.direction',
               visible: false
            },
            {
               displayName: getString('firewallConnection.serviceName'),
               field: 'serviceName',
               template: '#:serviceName#'
            },
            {
               displayName: getString('firewallConnection.tcpPort'),
               field: 'directionInfo.tcpPorts',
               template: function(dataItem) {
                  return dataItem.directionInfo.tcpPorts || getString('none.label');
               }
            },
            {
               displayName: getString('firewallConnection.udpPort'),
               field: 'directionInfo.udpPorts',
               template: function(dataItem) {
                  return dataItem.directionInfo.udpPorts || getString('none.label');
               }
            },
            {
               displayName: getString('firewallConnection.ipAddresses'),
               field: 'ipList',
               template: function(dataItem) {
                  return dataItem.ipList || getString('none.label');
               }
            }
         ];
      }

      function getString(key) {
         return i18nService.getString("HostUi", key);
      }
   }
})();
