/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace dvs_ui {

   angular.module("com.vmware.vsphere.client.dvs").constant(
         "dvsUpgradeLacpWizardConstants", {
      pages: {
         DVS_LACP_OVERVIEW_PAGE: "lacpOverviewPage",
         DVS_LACP_VALIDATION_PAGE: "lacpValidatePrerequisitesPage",
         DVS_LACP_LAG_PAGE: "lacpLagPage",
         DVS_LACP_SUMMARY_PAGE: "lacpReadyToCompletePage"
      },
      properties: {
         DVS_UPGRADE_LACP_WIZARD_DATA: "lacpupgrade:wizardData"
      },
      validationResult: {
         FAIL: "fail",
         WARNING: "warning",
         PASS: "pass"
      },
      spec: {
         MUTATION_SPEC_TYPE: "com.vmware.vim.binding.vim.dvs.DvsUpgradeLacpSpec"
      },
      lag: {
         NAME_MAX_LENGTH: 16,
         ACTIVE_LACP_MODE: "active",
         PASSIVE_LACP_MODE: "passive",
         OUT_RANGE_DELIMITER: ", ",
         OUT_RANGE_DIVIDER: "-"
      }
   });
}
