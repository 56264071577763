/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace dvs_ui {

   export class DvsSpanSessionReadyToCompletePageService {

      public static $inject = [
            "i18nService",
            "vxPropertyViewService"
      ];

      private getString: (key: string) => string;

      constructor(private i18nService: any,
                  private vxPropertyViewService: any) {
         this.getString = (key: string): string => {
            return this.i18nService.getString("DvsUi", key);
         };
      }

      public buildPropertyViewData(wizardModel: DvsAddSpanSessionWizardModel): any {
         let builder: any = this.vxPropertyViewService.createPropertyViewBuilder();

         this.buildCommonPropertiesSection(builder, wizardModel);

         this.buildAdvancedPropertiesSection(builder, wizardModel);

         return builder.build();
      }

      private buildCommonPropertiesSection(builder: any,
            wizardModel: DvsAddSpanSessionWizardModel): void {
         let category: any = builder.category("main");
         let commonSection: any = category.section("commonSection");

         commonSection.property(
               this.getString("AddSpanSessionWizard.readyToCompletePage.name"),
               wizardModel.propertiesPageModel.sessionName);

         let statusLabel: string = wizardModel.propertiesPageModel.enabled
               ? this.getString("AddSpanSessionWizard.readyToCompletePage.enabled")
               : this.getString("AddSpanSessionWizard.readyToCompletePage.disabled");
         commonSection.property(
               this.getString("AddSpanSessionWizard.readyToCompletePage.status"),
               statusLabel);

         commonSection.property(
               this.getString("AddSpanSessionWizard.readyToCompletePage.type"),
               this.getSessionTypeLocalized(wizardModel.sessionType));

         switch (wizardModel.sessionType) {
            case DvsSpanSessionConstants.SESSION_TYPE.L3_SOURCE:
               this.buildL3SourceCommonPropertiesSection(commonSection, wizardModel);
               break;

            case DvsSpanSessionConstants.SESSION_TYPE.REMOTE_MIRROR_SOURCE:
               this.buildRemoteSourceCommonPropertiesSection(commonSection, wizardModel);
            break;
         }
      }

      private buildL3SourceCommonPropertiesSection(commonSection: any,
                                                     wizardModel: DvsAddSpanSessionWizardModel): void {
         commonSection.property(
               this.getString(
                     "AddSpanSessionWizard.readyToCompletePage.encapsulation.type"),
               this.getEncapsulationTypeLocalized(
                     wizardModel.propertiesPageModel.encapsulationType));

         if (wizardModel.propertiesPageModel.encapsulationType
               === DvsSpanSessionConstants.ENCAPSULATION_TYPE.ERSPAN2 ||
               wizardModel.propertiesPageModel.encapsulationType
               === DvsSpanSessionConstants.ENCAPSULATION_TYPE.ERSPAN3) {
            commonSection.property(
                  this.getString(
                        "AddSpanSessionWizard.readyToCompletePage.encapsulation.sessionId"),
                  wizardModel.propertiesPageModel.sessionId);
         }
      }

      private buildRemoteSourceCommonPropertiesSection(commonSection: any,
                                                     wizardModel: DvsAddSpanSessionWizardModel): void {
         commonSection.property(
               this.getString(
                     "AddSpanSessionWizard.readyToCompletePage.encapsulationVlanId"),
               wizardModel.propertiesPageModel.encapsulationVlanId);

            commonSection.property(
                  this.getString(
                        "AddSpanSessionWizard.readyToCompletePage.preserveOriginalVlan"),
                  this.getPreserveOriginalVlanLocalized(
                        wizardModel.propertiesPageModel.preserveOriginalVlan));

      }

      private buildAdvancedPropertiesSection(builder: any,
            wizardModel: DvsAddSpanSessionWizardModel): void {
         let category: any = builder.getCategory("main");
         let advancedSection: any = category.section("advancedSection")
               .title(this.getString(
                     "AddSpanSessionWizard.readyToCompletePage.advancedProperties"));

         if (wizardModel.sessionType !== DvsSpanSessionConstants.SESSION_TYPE.L3_SOURCE) {
            advancedSection.property(
                  this.getString(
                        "AddSpanSessionWizard.readyToCompletePage.normalTrafficAllowed"),
                  this.getNormalTrafficAllowedLocalized(
                        wizardModel.propertiesPageModel.normalTrafficAllowed));
         }

         if (wizardModel.propertiesPageModel.tcpIpStack &&
               wizardModel.propertiesPageModel.isTcpIpStackSupported
               && wizardModel.sessionType === DvsSpanSessionConstants.SESSION_TYPE.L3_SOURCE) {
            advancedSection.property(
                  this.getString("AddSpanSessionWizard.readyToCompletePage.encapsulation.tcpipStack"),
                  this.getTcpIpStackLocalized(
                        wizardModel.propertiesPageModel.tcpIpStack));
         }

         if (wizardModel.propertiesPageModel.mirroredPacketLengthEnabled) {
            advancedSection.property(
                  this.getString(
                        "AddSpanSessionWizard.readyToCompletePage.mirroredPacketLength"),
                  this.i18nService.getString("DvsUi",
                        "AddSpanSessionWizard.readyToCompletePage.mirroredPacketLengthSummary",
                        wizardModel.propertiesPageModel.mirroredPacketLength));
         }

         advancedSection.property(
               this.getString("AddSpanSessionWizard.readyToCompletePage.samplingRate"),
               this.i18nService.getString("DvsUi",
                     "AddSpanSessionWizard.readyToCompletePage.samplingRateSummary",
                     wizardModel.propertiesPageModel.samplingRate));

         switch (wizardModel.sessionType) {
            case DvsSpanSessionConstants.SESSION_TYPE.L3_SOURCE: {
               this.buildL3SourceAdvancedPropertiesSection(advancedSection, wizardModel);
               break;
            }
            case DvsSpanSessionConstants.SESSION_TYPE.REMOTE_MIRROR_SOURCE: {
               this.buildRemoteSourceAdvancedPropertiesSection(advancedSection, wizardModel);
               break;
            }
            case DvsSpanSessionConstants.SESSION_TYPE.DV_PORT_MIRROR: {
               this.buildDvPortMirroradvancedPropertiesSection(advancedSection, wizardModel);
               break;
            }
            case(DvsSpanSessionConstants.SESSION_TYPE.REMOTE_MIRROR_DEST):
               this.buildRemoteMirroringDestAdvancedPropertiesSection(advancedSection, wizardModel);
               break;
         }

         advancedSection.property(
               this.getString("AddSpanSessionWizard.readyToCompletePage.sessionDescription"),
               wizardModel.propertiesPageModel.description
                     || this.getString("AddSpanSessionWizard.readyToCompletePage.noData"));
      }

      private buildL3SourceAdvancedPropertiesSection(advancedSection: any,
            wizardModel: DvsAddSpanSessionWizardModel): void {

         let numberOfSourcePorts: number =
               (wizardModel.selectSourcesPageModel.portsListModel.ports
                     && wizardModel.selectSourcesPageModel.portsListModel.ports.length) || 0;
         advancedSection.property(
               this.getString("AddSpanSessionWizard.readyToCompletePage.numSourcePorts"),
               numberOfSourcePorts);

         advancedSection.property(
               this.getString("AddSpanSessionWizard.readyToCompletePage.destinationIpAddresses"),
               wizardModel.selectDestinationsPageModel.ipAddressesListModel.values);
      }

      private buildRemoteSourceAdvancedPropertiesSection(advancedSection: any,
            wizardModel: DvsAddSpanSessionWizardModel): void {

         let numberOfSourcePorts: number =
               (wizardModel.selectSourcesPageModel.portsListModel.ports
               && wizardModel.selectSourcesPageModel.portsListModel.ports.length) || 0;
         advancedSection.property(
               this.getString("AddSpanSessionWizard.readyToCompletePage.numSourcePorts"),
               numberOfSourcePorts);

         let uplinkNamesArr: string[] =
               wizardModel.selectDestinationsPageModel.uplinksListModel.uplinks;
         let uplinkNames: string = uplinkNamesArr && uplinkNamesArr.length > 0
               ? uplinkNamesArr.join(", ") : "";
         advancedSection.property(
               this.getString("AddSpanSessionWizard.readyToCompletePage.destinationUplinks"),
               uplinkNames);
      }

      private buildDvPortMirroradvancedPropertiesSection(advancedSection: any,
            wizardModel: DvsAddSpanSessionWizardModel): void {
         let numberOfSourcePorts: number =
               (wizardModel.selectSourcesPageModel.portsListModel.ports
               && wizardModel.selectSourcesPageModel.portsListModel.ports.length) || 0;
         advancedSection.property(
               this.getString("AddSpanSessionWizard.readyToCompletePage.numSourcePorts"),
               numberOfSourcePorts);

         let numberOfDestPorts: number =
               (wizardModel.selectDestinationsPageModel.portsListModel.ports
               && wizardModel.selectDestinationsPageModel.portsListModel.ports.length) || 0;
         advancedSection.property(
               this.getString("AddSpanSessionWizard.readyToCompletePage.numDestinationPorts"),
               numberOfDestPorts);
      }

      private buildRemoteMirroringDestAdvancedPropertiesSection(advancedSection: any,
            wizardModel: DvsAddSpanSessionWizardModel): void {
         // Number of source VLAN IDs
         let numberOfSourceVlanIds: number =
               (wizardModel.selectSourcesPageModel.vlanIdListModel.values
               && wizardModel.selectSourcesPageModel.vlanIdListModel.values.length) || 0;
         advancedSection.property(
               this.getString("AddSpanSessionWizard.readyToCompletePage.numVlanIds"),
               numberOfSourceVlanIds);

         // Number of destination ports
         let numberOfDestPorts: number =
               (wizardModel.selectDestinationsPageModel.portsListModel.ports
               && wizardModel.selectDestinationsPageModel.portsListModel.ports.length) || 0;
         advancedSection.property(
               this.getString("AddSpanSessionWizard.readyToCompletePage.numDestinationPorts"),
               numberOfDestPorts);
      }

      private getSessionTypeLocalized(type: string): string {
         switch (type) {
            case DvsSpanSessionConstants.SESSION_TYPE.L3_SOURCE: {
               return this.getString("span.session.type.encapsulatedRemoteMirrorSource");
            }
            case DvsSpanSessionConstants.SESSION_TYPE.REMOTE_MIRROR_SOURCE: {
               return this.getString("span.session.type.remoteMirrorSource");
            }
            case DvsSpanSessionConstants.SESSION_TYPE.REMOTE_MIRROR_DEST: {
               return this.getString("dvsPortMirror.sessionType.remoteMirrorDest");
            }
            case DvsSpanSessionConstants.SESSION_TYPE.DV_PORT_MIRROR: {
               return this.getString("span.session.type.dvPortMirror");
            }
            default: {
               return this.getString("AddSpanSessionWizard.readyToCompletePage.noData");
            }
         }
      }

      private getEncapsulationTypeLocalized(type: string): string {
         switch (type) {
            case DvsSpanSessionConstants.ENCAPSULATION_TYPE.GRE: {
               return this.getString("SpanSessionEncapsulation.type.gre");
            }
            case DvsSpanSessionConstants.ENCAPSULATION_TYPE.ERSPAN2: {
               return this.getString("SpanSessionEncapsulation.type.erspan2");
            }
            case DvsSpanSessionConstants.ENCAPSULATION_TYPE.ERSPAN3: {
               return this.getString("SpanSessionEncapsulation.type.erspan3");
            }
            default: {
               return this.getString("AddSpanSessionWizard.readyToCompletePage.noData");
            }
         }
      }

      private getTcpIpStackLocalized(type: string): string {
         switch (type) {
            case DvsSpanSessionConstants.TCP_IP_STACK.DEFAULT_STACK: {
               return this.getString("spanSession.tcpip.stack.default");
            }
            case DvsSpanSessionConstants.TCP_IP_STACK.MIRROR_STACK: {
               return this.getString("spanSession.tcpip.stack.mirror");
            }
            default: {
               return this.getString("spanSession.tcpip.stack.default");
            }
         }
      }

      private getPreserveOriginalVlanLocalized(preserveOriginalVlan: boolean): string {

         return preserveOriginalVlan ?
               this.getString("AddSpanSessionWizard.readyToCompletePage.yes") :
               this.getString("AddSpanSessionWizard.readyToCompletePage.no");
      }

      private getNormalTrafficAllowedLocalized(NormalTrafficAllowed: boolean): string {

         return NormalTrafficAllowed ?
               this.getString("AddSpanSessionWizard.readyToCompletePage.on") :
               this.getString("AddSpanSessionWizard.readyToCompletePage.off");
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .service("dvsSpanSessionReadyToCompletePageService",
               DvsSpanSessionReadyToCompletePageService);
}
