(function() {
   'use strict';

   angular.module('com.vmware.vsphere.client.vm')
         .controller('InstallUpgradeVmToolsController', InstallUpgradeVmToolsController);

   InstallUpgradeVmToolsController.$inject = [
         '$scope',
         '$element',
         '$timeout',
         'installUpgradeVmToolsService',
         'i18nService',
         'managedByMessageBuilderService'
   ];

   function InstallUpgradeVmToolsController(
         $scope,
         $element,
         $timeout,
         installUpgradeVmToolsService,
         i18nService,
         managedByMessageBuilderService
   ) {
      var TOOLS_AUTO_UPGRADE = 'auto';

      var self = this;
      var groupedVms = {};

      self.installCount = 0;
      self.upgradeCount = 0;
      // total count includes also non-available vms
      self.totalCount = $scope.modalOptions.dialogData.totalVmsCount;
      self.selectedUpgradeMethod = TOOLS_AUTO_UPGRADE;
      self.multiUpgradeOptions = '';

      var availableVmIds = $scope.modalOptions.dialogData.availableVmIds;

      self.buttonContinueText = i18nService
         .getString('VmUi', 'defaultConsole.dialog.button.continue');
      self.instructionText = i18nService
         .getString('VmUi', 'VmToolsPopupForm.multiAction.instruction');

      if (_.isEmpty(availableVmIds)) {
         self.installCount = 0;
         self.upgradeCount = 0;
         self.skippedCount = self.totalCount;

         $timeout(function() {
            const elementToFocus = $element[0].querySelector("#install-upgrade-vmware-tools-ok-button");
            if (elementToFocus) {
               elementToFocus.focus();
            }
         }, 0);
      } else {
         self.loading = true;
         installUpgradeVmToolsService.getGroupedVms(availableVmIds)
            .then(function (result) {
               groupedVms = result.groupedVms;
               managedByMessageBuilderService
                     .buildWarningMessage(availableVmIds, false, result.properties)
                     .then(function(managedByMessage) {
                        self.managedByMessage = managedByMessage;
                        self.installCount = groupedVms.install.length;
                        self.upgradeCount = groupedVms.upgrade.length;
                        self.skippedCount = self.totalCount - (self.installCount + self.upgradeCount);

                        var selector = "#install-upgrade-vmware-tools-ok-button";
                        if(self.installCount > 0) {
                           selector = "#install-upgrade-vmware-tools-submit-button";
                        }

                        if(self.upgradeCount > 0) {
                           if (self.selectedUpgradeMethod === TOOLS_AUTO_UPGRADE) {
                              selector = "#install-upgrade-vmware-tools-automatic-upgrade";
                           } else {
                              selector = "#install-upgrade-vmware-tools-interactive-upgrade";
                           }
                        }

                        $timeout(function() {
                           const elementToFocus = $element[0].querySelector(selector);
                           if (elementToFocus) {
                              elementToFocus.focus();
                           }
                        }, 0);
                     });
            })
            .then(function(){
               self.loading = false;
            });
      }

      self.cancel = function() {
         $scope.closeModal();
      };

      self.submit = function() {
         installUpgradeVmToolsService.installTools(groupedVms.install);
         installUpgradeVmToolsService.upgradeTools(groupedVms.upgrade,
               self.selectedUpgradeMethod, self.multiUpgradeOptions);
         $scope.closeModal();
      };

      self.isAutoUpgradeSelected = function() {
         return self.selectedUpgradeMethod === TOOLS_AUTO_UPGRADE;
      };
   }
})();
