/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace dvs_ui {

   import IPromise = angular.IPromise;
   import DvsImportInfoSpec = com.vmware.vsphere.client.dvs.api.spec.DvsImportInfoSpec;
   import DvsImportPortgroupSpec = com.vmware.vsphere.client.dvs.api.spec.DvsImportPortgroupSpec;
   import DvsImportInfoWrapperSpec = com.vmware.vsphere.client.dvs.api.spec.DvsImportInfoWrapperSpec;
   import DvpgImportInfoWrapperSpec = com.vmware.vsphere.client.dvs.api.spec.DvpgImportInfoWrapperSpec;
   import DvPortgroupQueryRestoreSpec = com.vmware.vsphere.client.dvs.api.spec.DvPortgroupQueryRestoreSpec;
   import DvPortgroupRestoreInfoWrapperSpec = com.vmware.vsphere.client.dvs.api.spec.DvPortgroupRestoreInfoWrapperSpec;

   export class DvsImportWizardPages {

      public static $inject =
            ["i18nService",
               "dataService",
               "$q",
               "wizardPageService",
               "clarityModalService",
               "exportDvsConstants"];

      constructor(private i18nService: any,
                  private dataService: any,
                  private $q: any,
                  private wizardPageService: any,
                  private clarityModalService: any,
                  private exportDvsConstants: any) {
      }

      /**
       * Creates an array of page definitions for the Import DVS wizard.
       */
      public createPages(wizardScope: DvsImportWizardScope, isDvpg: boolean,
                         isRestoreAction: boolean): WizardPage[] {

         let filePageProperties: any  = this.getFilePageProperties(
               isDvpg, isRestoreAction);

         let importSwitchConfigurationPage: any = {
            id: DvsImportWizardConstants.page.SWITCH_CONFIGURATION_PAGE,
            title: filePageProperties.filePageTitle,
            description: filePageProperties.filePageDescription,
            contentUrl: filePageProperties.filePageTemplate,
            onCommit: (): IPromise<boolean> => {
               return this.onConfigurationPageCommit(wizardScope, isDvpg, isRestoreAction);
            }
         };

         let readyToCompletePage: any = {
            id: DvsImportWizardConstants.page.READY_TO_COMPLETE_PAGE,
            title: this.i18nService.getString("DvsUi",
                  "dvs.import.wizard.readyToCompletePage.title"),
            description: this.i18nService.getString("DvsUi",
                  "dvs.import.wizard.readyToCompletePage.description"),
            contentUrl: "dvs-ui/resources/dvs/import/views/dvsImportReadyToCompletePage.html",
            validationParams: [],
            validationFunction: () => {
               return true;
            }
         };

         let pages: WizardPage[] = [importSwitchConfigurationPage,
            readyToCompletePage];

         return pages;
      }

      private getFilePageProperties(isDvpg: boolean, isRestoreAction: boolean): any {
         let pageProperties: any = {
            filePageTitle: "",
            filePageDescription: "",
            filePageTemplate: ""
         };
         if (isRestoreAction) {
            pageProperties.filePageTitle = isDvpg ?
                  this.i18nService.getString(
                        "DvsUi", "dvpg.restore.selectPage.title") :
                  this.i18nService.getString(
                        "DvsUi", "dvs.restore.wizard.filePage.tocTitle");
            pageProperties.filePageDescription = isDvpg ?
                  this.i18nService.getString(
                        "DvsUi", "dvpg.restore.selectPage.desc") :
                  this.i18nService.getString(
                        "DvsUi", "dvs.restore.wizard.filePage.desc");
            pageProperties.filePageTemplate = isDvpg ?
                  "dvs-ui/resources/dvs/import/views/dvpgRestoreConfigurationPage.html" :
                  "dvs-ui/resources/dvs/import/views/dvsImportSwitchConfigurationPage.html";
         } else {
            pageProperties.filePageTitle = isDvpg ?
                  this.i18nService.getString(
                        "DvsUi", "dvpg.import.filePage.title") :
                  this.i18nService.getString(
                        "DvsUi", "dvs.import.wizard.filePage.title");
            pageProperties.filePageDescription = isDvpg ?
                  this.i18nService.getString(
                        "DvsUi", "dvpg.import.filePage.desc") :
                  this.i18nService.getString(
                        "DvsUi", "dvs.import.wizard.filePage.desc");
            pageProperties.filePageTemplate =
                  "dvs-ui/resources/dvs/import/views/dvsImportSwitchConfigurationPage.html";
         }
         return pageProperties;

      }

      private onConfigurationPageCommit(wizardScope: DvsImportWizardScope, isDvpg: boolean,
                                        isRestoreAction: boolean): IPromise<boolean> {
         let deferred: any = this.$q.defer();
         let hasNoConfigData: boolean =
               !wizardScope.model.configurationPageModel.configurationData && !(isDvpg && isRestoreAction);
         let hasNoRestoreDvpgConfigData: boolean = isDvpg && isRestoreAction &&
               wizardScope.model.configurationPageModel.restoreType !==
               this.exportDvsConstants.configurationRestoreTypes.FROM_PREVIOUS_CONFIGURATION &&
               !wizardScope.model.configurationPageModel.restoreDvpgConfigurationData;
         if (hasNoConfigData || hasNoRestoreDvpgConfigData) {
            let localizedErrorMessage: string;
            if(isDvpg) {
               localizedErrorMessage = isRestoreAction ?
                     this.i18nService.getString("DvsUi",
                           "dvpg.restore.selectPage.selectNote") :
                     this.i18nService.getString("DvsUi",
                           "dvpg.import.filePage.selectNote");
            } else {
               localizedErrorMessage = this.i18nService.getString("DvsUi",
                           "dvs.import.wizard.filePage.selectNote");
            }

            this.wizardPageService.markPageIncompleteWithError(
                  wizardScope.wizardConfig, localizedErrorMessage);
            deferred.resolve(false);
         } else {
            let SWITCH_IMPORT_INFO_WRAPPER_PROPERTY: string =
                  "dvs:switchImportInfoWrapper";
            let DVPG_IMPORT_INFO_WRAPPER_PROPERTY: string =
                  "dvpg:dvpgImportInfoWrapper";
            let DVPG_RESTORE_INFO_WRAPPER_PROPERTY: string =
                  "dvpg:dvpgRestoreInfoWrapper";
            let READY_TO_COMPLETE_PROPERTY = SWITCH_IMPORT_INFO_WRAPPER_PROPERTY;
            if (isDvpg) {
               READY_TO_COMPLETE_PROPERTY = isRestoreAction ?
                     DVPG_RESTORE_INFO_WRAPPER_PROPERTY :
                     DVPG_IMPORT_INFO_WRAPPER_PROPERTY;
            }

            // [B is the string representation of the
            // Java byte[] type.
            let JAVA_BYTE_ARRAY_TYPE: string = "[B";
            let DVPG_RESTORE_SPEC_TYPE: string =
                  new DvPortgroupQueryRestoreSpec()._type;
            wizardScope.wizardConfig.loading = true;
            let propertyParams: any[];
            if (isDvpg && isRestoreAction) {
               let dvpgRestoreSpec: DvPortgroupQueryRestoreSpec = new DvPortgroupQueryRestoreSpec();
               if (wizardScope.model.configurationPageModel.restoreType ===
                     this.exportDvsConstants.configurationRestoreTypes.FROM_PREVIOUS_CONFIGURATION) {
                  dvpgRestoreSpec.fromMemory = true;
               } else {
                  dvpgRestoreSpec.fromMemory = false;
                  dvpgRestoreSpec.fileConfig =
                        wizardScope.model.configurationPageModel.restoreDvpgConfigurationData;
               }

               propertyParams = [{
                  propertyName: READY_TO_COMPLETE_PROPERTY,
                  parameterType: DVPG_RESTORE_SPEC_TYPE,
                  parameter: dvpgRestoreSpec
               }];
            } else {
               propertyParams = [{
                  propertyName: READY_TO_COMPLETE_PROPERTY,
                  parameterType: JAVA_BYTE_ARRAY_TYPE,
                  parameter: wizardScope.model.configurationPageModel.configurationData
               }];
            }
            this.dataService.getProperties(
                  wizardScope.model.objectId,
                  READY_TO_COMPLETE_PROPERTY,
                  {
                     propertyParams: propertyParams
                  })
                  .then((response: any): any => {
                     if (!response || !response[READY_TO_COMPLETE_PROPERTY]) {
                        wizardScope.wizardConfig.loading = false;
                        deferred.resolve(false);
                     }
                     if (isDvpg) {
                        if (isRestoreAction) {
                           let dvPortgroupRestoreInfoWrapperSpec: DvPortgroupRestoreInfoWrapperSpec =
                                 response[READY_TO_COMPLETE_PROPERTY];

                           if(dvPortgroupRestoreInfoWrapperSpec) {
                              if (dvPortgroupRestoreInfoWrapperSpec.error) {
                                 this.wizardPageService.clearErrors(wizardScope.wizardConfig);
                                 this.showErrorDialog(dvPortgroupRestoreInfoWrapperSpec.error, () => {
                                    deferred.resolve(false);
                                 }, isDvpg);
                              } else {
                                 wizardScope.model.configurationPageModel.dvPortgroupRestoreInfoWrapperSpec = dvPortgroupRestoreInfoWrapperSpec;
                                 this.wizardPageService.markPageComplete(wizardScope.wizardConfig);
                                 deferred.resolve(true);
                              }
                           }
                        } else {
                           let dvpgImportInfoWrapperSpec: DvpgImportInfoWrapperSpec =
                                 response[DVPG_IMPORT_INFO_WRAPPER_PROPERTY];

                           if (dvpgImportInfoWrapperSpec) {
                              if (dvpgImportInfoWrapperSpec.error) {
                                 this.wizardPageService.clearErrors(wizardScope.wizardConfig);
                                 this.showErrorDialog(dvpgImportInfoWrapperSpec.error, () => {
                                    deferred.resolve(false);
                                 }, isDvpg);
                              } else {
                                 if (dvpgImportInfoWrapperSpec.dvsImportPortgroupWrapperSpec) {
                                    wizardScope.model.configurationPageModel.dvsImportPortgroupWrapperSpec =
                                          dvpgImportInfoWrapperSpec.dvsImportPortgroupWrapperSpec;
                                 }
                                 this.wizardPageService.markPageComplete(wizardScope.wizardConfig);
                                 deferred.resolve(true);
                              }
                           }
                        }
                     } else {
                        let dvsImportInfoWrapperSpec: DvsImportInfoWrapperSpec =
                              response[READY_TO_COMPLETE_PROPERTY];

                        if (dvsImportInfoWrapperSpec) {
                           if (dvsImportInfoWrapperSpec.error) {
                              this.wizardPageService.clearErrors(wizardScope.wizardConfig);
                              this.showErrorDialog(dvsImportInfoWrapperSpec.error, () => {
                                 deferred.resolve(false);
                              }, isDvpg);
                           } else {
                              if (dvsImportInfoWrapperSpec.dvsImportInfoSpec) {
                                 wizardScope.model.configurationPageModel.dvsImportInfoSpec =
                                       dvsImportInfoWrapperSpec.dvsImportInfoSpec;
                              }
                              this.wizardPageService.markPageComplete(wizardScope.wizardConfig);
                              deferred.resolve(true);
                           }
                        }
                     }
                     wizardScope.wizardConfig.loading = false;
                  }, (error: any): any => {
                     wizardScope.wizardConfig.loading = false;
                     deferred.resolve(false);
                  });
         }

         return deferred.promise;
      }

      private showErrorDialog(message: String, callback: Function, isDvpg: boolean) {
         this.clarityModalService.openConfirmationModal({
            title: this.i18nService.getString(
                  "DvsUi", "dvs.import.wizard.error"),
            message: isDvpg ? message : this.i18nService.getString("DvsUi",
                  "dvs.import.wizard.filePage.internalError", message),
            clarityIcon: {
               class: "is-error",
               shape: "error-standard",
               size: "32"
            },
            saveButtonLabel: this.i18nService.getString(
                  "Common", "alert.ok"),
            hideCancelButton: true,
            submit: callback
         });
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .service("dvsImportWizardPages", DvsImportWizardPages);
}

