/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
(function() {
   'use strict';

   angular.module('com.vmware.vsphere.client.networkLibUi').service(
         'networkDetailsViewService', networkDetailsViewService);

   networkDetailsViewService.$inject = [
      'i18nService',
      'vxPropertyViewService',
      'networkUtil',
      'networkUiConstants',
      'ipParserService',
      'standardPortgroupPoliciesViewService',
      'distributedPortgroupPoliciesViewService'
   ];

   function networkDetailsViewService(
         i18nService,
         propertyViewService,
         networkUtil,
         networkUiConstants,
         ipParserService,
         standardPortgroupPoliciesViewService,
         distributedPortgroupPoliciesViewService) {

      return {
         build: build,
         buildOnlyProperties: buildOnlyProperties,
         buildOnlyIpSettings: buildOnlyIpSettings,
         buildOnlyPolicies: buildOnlyPolicies,
         buildOnlyNicSettings: buildOnlyNicSettings
      };

      function build(networkDetailsData, customizedSettings) {
         if (networkDetailsData) {

            var builder = propertyViewService.createPropertyViewBuilder();

            buildPortPropertiesSection(builder, networkDetailsData);

            buildIpSettingsTab(builder, networkDetailsData.ipProperties);

            buildVnicSettingsSection(builder, networkDetailsData);

            if (!customizedSettings || !customizedSettings.noPoliciesIncluded) {
               if (networkDetailsData.dvPortgroup) {
                  distributedPortgroupPoliciesViewService.build(
                        networkDetailsData.portPolicy, builder);
               } else {
                  standardPortgroupPoliciesViewService.build(
                        networkDetailsData.portPolicy, builder);
               }
            }

            if (customizedSettings && customizedSettings.addAllTab) {
               // Add 'All' tab
               var propertiesCategory = builder.category('all', 0);
               propertiesCategory
                     .title(i18nService.getString(
                           'H5NetworkUi',
                           'VmkernelAdapterDetailsView.allTabTitle'))
                     .cloneAndAddBuilder(
                           builder.getSection('properties', 'propertiesSection'))
                     .cloneAndAddBuilder(
                           builder.getSection('ipCategory', 'ipv4Section'))
                     .cloneAndAddBuilder(
                           builder.getSection('ipCategory', 'ipv6Section'))
                     .cloneAndAddBuilder(
                           builder.getSection('properties', 'nicSettings'));

               if (!customizedSettings.noPoliciesIncluded) {
                  propertiesCategory
                        .cloneAndAddBuilder(builder
                              .getSection('policies', 'security'))
                        .cloneAndAddBuilder(builder
                              .getSection('policies', 'trafficShaping'))
                        .cloneAndAddBuilder(builder
                              .getSection('policies', 'ingressTrafficShaping'))
                        .cloneAndAddBuilder(builder
                              .getSection('policies', 'egressTrafficShaping'))
                        .cloneAndAddBuilder(builder
                              .getSection('policies', 'teaming'))
                        .cloneAndAddBuilder(builder
                              .getSection('policies', 'vlan'))
                        .cloneAndAddBuilder(builder
                              .getSection('policies', 'monitoring'))
                        .cloneAndAddBuilder(builder
                              .getSection('policies', 'miscellaneous'));
               }
            }

            return builder.build();
         }
         return {};
      }

      function buildOnlyNicSettings(networkDetailsData) {
         var builder = propertyViewService.createPropertyViewBuilder();
         builder.category("properties");

         buildVnicSettingsSection(builder, networkDetailsData);

         builder.getCategory("properties").title(null);

         return builder.build();
      }

      function buildOnlyProperties(networkDetailsData) {
         var builder = propertyViewService.createPropertyViewBuilder();

         buildPortPropertiesSection(builder, networkDetailsData);

         builder.getCategory("properties").title(null);

         return builder.build();
      }

      function buildOnlyIpSettings(networkDetailsData) {
         var builder = propertyViewService.createPropertyViewBuilder();

         buildIpSettingsTab(builder, networkDetailsData);

         builder.getCategory("ipCategory").title(null);

         return builder.build();
      }

      function buildOnlyPolicies(networkDetailsData) {
         var builder = propertyViewService.createPropertyViewBuilder();

         if (networkDetailsData.dvPortgroup) {
            distributedPortgroupPoliciesViewService.build(
                  networkDetailsData.portPolicy, builder);
         } else {
            standardPortgroupPoliciesViewService.build(
                  networkDetailsData.portPolicy, builder);
         }

         return builder.build();
      }

      function buildPortPropertiesSection(builder, networkDetailsData) {
         var propertiesCategoryBuilder = builder
               .category("properties")
               .title(i18nService.getString(
                     'NetworkUi', 'VnicView.nicCategory.title'));

         // when we don't have mtu and mac, the view is about a port group and the
         // title is different
         var propertiesSectionTitle = (networkDetailsData.mtu && networkDetailsData.mac)
               ? i18nService.getString('NetworkUi', 'VnicView.properties')
               : i18nService.getString('NetworkUi', 'PortgroupPropertiesView.title');

         var propertiesSection = propertiesCategoryBuilder
               .section("propertiesSection").title(propertiesSectionTitle);

         // Port properties
         propertiesSection.property(
               i18nService.getString(
                     'NetworkUi', 'VnicPortPropertiesView.networkLabel'),
               replaceEmptyString(networkDetailsData.networkLabel));

         if (networkDetailsData.showVlanId === true) {
            propertiesSection.property(
                  i18nService.getString(
                        'NetworkUi', 'VnicPortPropertiesView.vlanID'),
                  networkUtil.formatVlanId(networkDetailsData.vlanId));
         }

         if (networkDetailsData.showNetworkStack === true) {
            propertiesSection.property(
                  i18nService.getString(
                        'NetworkUi', 'VnicPortPropertiesView.networkStack'),
                  replaceEmptyString(networkDetailsData.networkStack));
         }

         // Array with strings of all enabled services
         if (networkDetailsData.enabledServices) {
            var enabledServices = getEnabledServices(networkDetailsData.enabledServices);

            propertiesSection.property(
                  i18nService.getString(
                        'NetworkUi', 'VnicPortPropertiesView.servicesEnabled'),
                  replaceEmptyString(enabledServices));
         }

         if (networkDetailsData.pinnedPnic) {
            propertiesSection.property(
                  i18nService.getString(
                        'NetworkUi', 'VnicPortPropertiesView.pinnedPnic'),
                  replaceEmptyString(networkDetailsData.pinnedPnic));
         }
      }

      function buildVnicSettingsSection(builder, networkDetailsData) {
         // NIC properties
         if (networkDetailsData.mac && networkDetailsData.mtu) {
            var propertiesCategoryBuilder = builder
                  .getCategory("properties");

            var nicPropertiesSection = propertiesCategoryBuilder
                  .section("nicSettings")
                  .title(i18nService.getString('NetworkUi', 'VnicView.nicSettings'));

            nicPropertiesSection.property(
                  i18nService.getString(
                        'NetworkUi', 'VnicSettingsView.macAddress'),
                  replaceEmptyString(networkDetailsData.mac));

            nicPropertiesSection.property(
                  i18nService.getString('NetworkUi', 'VnicSettingsView.mtu'),
                  replaceEmptyString(networkDetailsData.mtu));
         }
      }

      function buildIpSettingsTab(builder, ipProperties) {

         if (ipProperties && ipProperties.hasIpv4Settings) {

            // IPv4 settings

            var ipCategoryBuilder = builder
                  .category("ipCategory")
                  .title(i18nService.getString('NetworkUi', 'VnicView.ipCategory.title'));

            var ipv4Section = ipCategoryBuilder
                  .section("ipv4Section")
                  .title(i18nService.getString('NetworkUi', 'VnicView.ipv4Settings'));

            // DHCP
            ipv4Section.property(
                  i18nService.getString('NetworkUi',
                        'VnicSettingsView.dhcp.label'),
                  getEnabledValue(ipProperties.ipv4DhcpEnabled));

            // IP address
            ipv4Section.property(
                  i18nService.getString(
                        'NetworkUi', 'VnicIpv4SettingsView.ipv4Address'),
                  getIpv4AddressFormatted(ipProperties.ipv4DhcpEnabled,
                        ipProperties.ipv4Address));

            // Subnet mask
            ipv4Section.property(
                  i18nService.getString(
                        'NetworkUi', 'VnicIpv4SettingsView.subnetMask'),
                  replaceEmptyString(ipProperties.subnetMask));

            // Gateway
            ipv4Section.property(
                  i18nService.getString(
                        'NetworkUi', 'VnicIpv4SettingsView.ipv4Gateway'),
                  replaceEmptyString(ipProperties.ipv4DefaultGateway));

            // DNS
            if (ipProperties.shouldShowDnsServers) {
               var dnsServers =
                     _.filter(ipProperties.dnsServers, function (dnsServer) {
                        return ipParserService.isIpv4AddressValid(dnsServer);
                     });

               if (dnsServers.length === 0) {
                  ipv4Section.property(
                        i18nService.getString(
                              'NetworkUi', 'VnicIpv4SettingsView.dnsAddresses'),
                        i18nService.getString('NetworkUi',
                              'NetworkUtil.noData'));
               } else {
                  ipv4Section.property(
                        i18nService.getString(
                              'NetworkUi', 'VnicIpv4SettingsView.dnsAddresses'),
                        dnsServers);
               }
            }
         }

         // IPv6 settings
         if (ipProperties && ipProperties.hasIpv6Settings) {

            var ipCategory = builder.getCategory("ipCategory");
            if (ipCategory === null) {
               ipCategory = builder
                     .category("ipCategory")
                     .title(i18nService.getString('NetworkUi', 'VnicView.ipCategory.title'));
            }

            var ipv6Section = ipCategory
                  .section("ipv6Section")
                  .title(i18nService.getString('NetworkUi', 'VnicView.ipv6Settings'));

            // DHCP
            ipv6Section.property(i18nService.getString(
                  'NetworkUi', 'VnicSettingsView.dhcp.label'),
                  getEnabledValue(ipProperties.ipv6DhcpEnabled));

            // Router Advertisement
            ipv6Section.property(i18nService.getString(
                  'NetworkUi', 'VnicSettingsView.routerAdvertisement.label'),
                  getEnabledValue(ipProperties.ipv6AutoConfigEnabled));

            // IP address
            if (!ipProperties.formattedIpv6Addresses ||
                  ipProperties.formattedIpv6Addresses.length === 0) {

               ipv6Section.property(
                     i18nService.getString(
                           'NetworkUi',
                           'VnicIpv6SettingsView.ipv6Addresses'),
                     i18nService.getString('NetworkUi',
                           'NetworkUtil.noData'));
            } else {
               ipv6Section.property(
                     i18nService.getString(
                           'NetworkUi',
                           'VnicIpv6SettingsView.ipv6Addresses'),
                     ipProperties.formattedIpv6Addresses);
            }

            // Gateway
            ipv6Section.property(
                  i18nService.getString(
                        'NetworkUi', 'VnicIpv6SettingsView.ipv6Gateway'),
                  replaceEmptyString(ipProperties.ipv6DefaultGateway));

            // DNS
            if (ipProperties.shouldShowDnsServers) {
               var ipv6DnsServers =
                     _.filter(ipProperties.dnsServers, function (dnsServer) {
                        return !ipParserService.isIpv4AddressValid(dnsServer);
                     });

               if (ipv6DnsServers.length === 0) {
                  ipv6Section.property(
                        i18nService.getString(
                              'H5NetworkUi',
                              'VnicIpv6SettingsView.dnsAddresses'),
                        i18nService.getString('NetworkUi',
                              'NetworkUtil.noData'));
               } else {
                  ipv6Section.property(
                        i18nService.getString(
                              'H5NetworkUi',
                              'VnicIpv6SettingsView.dnsAddresses'),
                        ipv6DnsServers);
               }
            }
         }
      }

      function replaceEmptyString(value) {
         if (!value) {
            return i18nService.getString('NetworkUi', 'VnicPortPropertiesView.noData');
         }
         return value;
      }

      function replaceEmptyArray(array) {
         return array.length === 0
               ? [i18nService.getString('NetworkUi', 'VnicPortPropertiesView.noData')]
               : array;
      }

      function getEnabledValue(isEnabled) {
         return isEnabled ?
               i18nService.getString('NetworkUi',
                     'VnicSettingsView.autoConfig.enabled') :
               i18nService.getString('NetworkUi',
                     'VnicSettingsView.autoConfig.disabled');
      }

      function getIpv4AddressFormatted(isDhcpEnabled, ipv4Address) {
         if (!ipv4Address) {
            return i18nService.getString('NetworkUi', "VnicIpv4SettingsView.noData");
         }

         var type = isDhcpEnabled
               ? i18nService.getString('NetworkUi', 'VnicView.ipAddrDhcpTypeFormat')
               : i18nService.getString('NetworkUi', 'VnicView.ipAddrStaticTypeFormat');

         return i18nService.getString('NetworkUi', 'VnicView.ipAddressTypeFormat',
               ipv4Address, type);
      }

      function getEnabledServices(enabledServicesData) {
         var enabledServices = [];
         // vMotion
         if (enabledServicesData.isVmotionEnabled === true) {
            enabledServices.push(
                  i18nService.getString(
                        'NetworkUi', 'VnicPortPropertiesView.vMotion'));
         }

         // Fault tolerance
         if (enabledServicesData.isFtEnabled === true) {
            enabledServices.push(
                  i18nService.getString(
                        'NetworkUi', 'VnicPortPropertiesView.ftLogging'));
         }

         // Management traffic
         if (enabledServicesData.isManagementTrafficEnabled === true) {
            enabledServices.push(
                  i18nService.getString(
                        'NetworkUi', 'VnicPortPropertiesView.mngTraffic'));
         }

         // Replication traffic
         if (enabledServicesData.isVrTrafficEnabled === true) {
            enabledServices.push(
                  i18nService.getString(
                        'NetworkUi', 'VnicPortPropertiesView.vRTraffic'));
         }

         // Replication NFC traffic
         if (enabledServicesData.isVrNfcTrafficEnabled === true) {
            enabledServices.push(
                  i18nService.getString(
                        'NetworkUi', 'VnicPortPropertiesView.vrNfcTraffic'));
         }

         // Provisioning NFC traffic
         if (enabledServicesData.isProvisioningNfcTrafficEnabled === true) {
            enabledServices.push(
                  i18nService.getString(
                        'NetworkUi', 'VnicPortPropertiesView.provisioningNfcTraffic'));
         }

         // VSAN
         if (enabledServicesData.isVsanEnabled === true) {
            enabledServices.push(
                  i18nService.getString(
                        'NetworkUi', 'VnicPortPropertiesPage.vsan'));
         }

         // vSAN Witness Traffic
         if (enabledServicesData.isVsanWitnessTrafficEnabled) {
            enabledServices.push(
                  i18nService.getString(
                        'H5NetworkUi', 'VnicPortPropertiesPage.vsanWitnessTraffic'));
         }

         return replaceEmptyArray(enabledServices);
      }

      function getLoadBalancingInfoMessages() {
         return [
            i18nService.getString('NetworkUi', "PortPolicyData.failover.lb.loadbalance_ip.info.etherChannel"),
            i18nService.getString('NetworkUi', "PortPolicyData.failover.lb.loadbalance_ip.info.portGroupPolicies")
         ];
      }

      function getLoadBalancingWarningMessage(teamingAndFailover) {
         if (teamingAndFailover.standByAdapters.length !== 0 && teamingAndFailover.failureDetection) {
            return i18nService.getString('NetworkUi', "PortPolicyData.failover.lb.loadbalance_ip.warn.beaconProbingAndStandbyUplinks");
         } else if (teamingAndFailover.standByAdapters.length !== 0) {
            return i18nService.getString('NetworkUi', "PortPolicyData.failover.lb.loadbalance_ip.warn.standbyUplinks");
         } else if (teamingAndFailover.failureDetection) {
            return i18nService.getString('NetworkUi', "PortPolicyData.failover.lb.loadbalance_ip.warn.beaconProbing");
         } else {
            return null;
         }
      }

      function getLoadBalancingPolicyValue(policy) {
         switch (policy) {
            case networkUiConstants.LoadBalancingPolicy.SRCID:
               return i18nService.getString(
                     'NetworkUi', 'PortFailoverPolicyView.loadbalance_srcid');
            case networkUiConstants.LoadBalancingPolicy.IP:
               return i18nService.getString(
                     'NetworkUi', 'PortFailoverPolicyView.loadbalance_ip');
            case networkUiConstants.LoadBalancingPolicy.SRCMAC:
               return i18nService.getString(
                     'NetworkUi', 'PortFailoverPolicyView.loadbalance_srcmac');
            case networkUiConstants.LoadBalancingPolicy.EXPLICIT:
               return i18nService.getString(
                     'NetworkUi', 'PortFailoverPolicyView.failover_explicit');
            case networkUiConstants.LoadBalancingPolicy.LOADBASED:
               return i18nService.getString(
                     'NetworkUi', 'PortFailoverPolicyView.loadbalance_loadbased');
            default:
               return i18nService.getString('NetworkUi', 'VnicPortPropertiesView.noData');
         }
      }
   }
})();
