/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace dvs_ui {

   export class DvsLagPageComponent {

      public bindings: any;
      public controller: any;
      public templateUrl: string;

      constructor() {
         this.bindings = {
            wizardModel: "<"
         };

         this.controller = DvsLagPageComponentController;

         this.templateUrl = "dvs-ui/resources/dvs/lacpupgrade/components/" +
               "dvsLagPageComponentTemplate.html";
      }
   }

   class DvsLagPageComponentController {

      static $inject = [
         "i18nService",
         "vxPropertyViewService",
         "vxValidatorFactory",
         "lagPropertiesFormattingService",
         "dvsLagPageValidator"
      ];

      public wizardModel: DvsUpgradeLacpWizardModel | DvsUpgradeWizardModel;

      private uplinkNum: string;
      private lagMode: string;
      private loadBalancing: string;
      private vlan: string;
      private netFlow: string;
      private i18n: any;
      private lagNameValidator: any;

      constructor(private i18nService: any,
                  private vxPropertyViewService: any,
                  private vxValidatorFactory: any,
                  private formattingService: LagPropertiesFormattingService,
                  private dvsLagPageValidator: any) {

         this.i18n = this.i18nService.getString;
      }

      $onInit(): void {
         this.lagNameValidator = this.vxValidatorFactory.create();

         this.buildLagDetails();
      }

      public validateLagName = (value:any) => {
         return this.dvsLagPageValidator.getLagNameError(value);
      };

      private buildLagDetails(): void {
         this.uplinkNum = this.wizardModel.lag.uplinkNum.toString();
         this.lagMode =
               this.formattingService.getLacpModeText(this.wizardModel.lag.mode);
         this.loadBalancing = this.formattingService.getLoadBalancingModeText(
               this.wizardModel.lag.loadbalanceAlgorithm);
         this.vlan = this.formattingService.getVlanText(this.wizardModel.lag);
         this.netFlow = this.formattingService.getIpfixText(this.wizardModel.lag);
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .component("dvsLagPage", new DvsLagPageComponent());
}