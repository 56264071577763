(function() {
   'use strict';
   angular.module('com.vmware.vsphere.client.vm').service('vmProvisioningFinishDeployTemplatePageService', [
      '$q',
      'i18nService',
      'addVmService',
      'mutationService',
      'wizardPageService',
      'defaultUriSchemeUtil',
      'vmVuiWizardPageBuilder',
      'VmProvisioningFinishDeployFromTemplatePageModel',
      function(
         $q,
         i18nService,
         addVmService,
         mutationService,
         wizardPageService,
         defaultUriSchemeUtil,
         vmVuiWizardPageBuilder,
         VmProvisioningFinishDeployFromTemplatePageModel
      ) {
         return {
            build: function(wizardScope) {
               var model = new VmProvisioningFinishDeployFromTemplatePageModel(wizardScope.vmParams, wizardScope.wizardViewData);
               wizardScope.vmProvisioningFinishDeployFromTemplatePageModel = model;
               return vmVuiWizardPageBuilder.buildVuiWizardFinishPage(wizardScope.config, {
                  title: i18nService.getString('VmUi', 'ProvisioningWizardSummaryPage.Title'),
                  description: i18nService.getString('VmUi', 'ProvisioningWizardSummaryPage.Description'),
                  contentUrl: 'vm-ui/resources/vm/views/createVmWizard/vmProvisioningFinishDeployFromTemplate.html',
                  model: model
               });
            }
         };
      }
   ]);
})();
