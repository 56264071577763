/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace dvs_ui {

   import IPromise = angular.IPromise;
   export class DvsNetFlowViewComponent {

      public bindings: any;
      public controller: any;
      public templateUrl: string;

      constructor() {
         this.bindings = {};
         this.controller = DvsNetFlowViewController;
         this.templateUrl = "dvs-ui/resources/dvs/netflow/dvsNetFlowViewTemplate.html";
      }
   }

   class DvsNetFlowViewController {

      static $inject = [
         "$rootScope",
         "i18nService",
         "dataService",
         "dvsNetFlowViewService"
      ];

      static NETFLOW_DATA_PROPERTY_NAME = "dvswitchproperties:netFlowDetails";

      public dvsNetFlowData: any;

      public dvsNetFlowLoading: boolean = false;

      public settingsViewHeaderAccessor: any;

      private _selectedDistributedSwitchPromise: any;

      private _actions: any;

      private _dvsUri: string;

      public liveRefreshProperties: any;

      constructor(private $rootScope: any,
                  private i18nService: any,
                  private dataService: any,
                  private dvsNetFlowViewService: any) {
         this.settingsViewHeaderAccessor = {};
         this._actions = [{
            actionUid: "vsphere.core.dvs.editNetFlow",
            customLabel: this.i18nService.getString(
                  "DvsUi", "DvsPropertiesView.edit.label")
         }];
      }

      protected $onInit() {
         this.refresh();
      }

      public get title(): string {
         return this.i18nService.getString("DvsUi", "dvs.netFlowView.title");
      }

      public get actions(): any {
         return this._actions;
      }

      public get dvsUri(): string {
         return this._dvsUri;
      }

      public refresh() {
         let self: DvsNetFlowViewController = this;

         self.dvsNetFlowLoading = true;

         self._dvsUri = this.$rootScope._route.objectId;
         let dvsPromise = self.dataService.getProperties(self._dvsUri,
               [DvsNetFlowViewController.NETFLOW_DATA_PROPERTY_NAME]);

         self._selectedDistributedSwitchPromise = dvsPromise;

         dvsPromise
               .then(function (dataServiceResponse: any) {
                        if (self._selectedDistributedSwitchPromise === dvsPromise) {
                           let dvsNetFlowData = dataServiceResponse
                                 ? dataServiceResponse[
                                       DvsNetFlowViewController.NETFLOW_DATA_PROPERTY_NAME]
                                 : null;

                           if (dvsNetFlowData) {
                              self.dvsNetFlowData = self.dvsNetFlowViewService
                                    .buildProperties(dvsNetFlowData);
                           }

                           self.dvsNetFlowLoading = false;
                        }
                     }, function (error: any) {
                        self.dvsNetFlowLoading = false;
                     }
               );

         // Call the settings-view-header's accessor #refresh function to recalculate
         // the actions availability
         if (self.settingsViewHeaderAccessor.refresh) {
            self.settingsViewHeaderAccessor.refresh();
         }

         this.liveRefreshProperties = [
            "config.ipfixConfig.collectorPort",
            "config.ipfixConfig.collectorIpAddress",
            "config.ipfixConfig.observationDomainId",
            "config.ipfixConfig.activeFlowTimeout",
            "config.ipfixConfig.idleFlowTimeout",
            "config.ipfixConfig.internalFlowsOnly",
            "config.ipfixConfig.samplingRate",
            "config.switchIpAddress"
         ];
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .component("dvsNetFlowView", new DvsNetFlowViewComponent());
}
