(function() {
  'use strict';
  angular.module('com.vmware.vsphere.client.folder')
    .controller('VchaPortletController', VchaPortletController);

  VchaPortletController.$inject = ['$scope', 'i18nService', 'navigation'];

  function VchaPortletController($scope, i18nService, navigation) {
    $scope.portlet.footerLinks = [{
      label: i18nService.getString('FolderUi', 'vcha.portlet.footer.settings'),
      onClick: function () {
        navigation.navigate('vsphere.core.folder.manage.settings.vchaView', navigation.getObjectId());
      },
      enabled: true
    }];
  }
}());