/* Copyright 2015 VMware, Inc. All rights reserved. -- VMware Confidential */
(function() {
   'use strict';

   angular
      .module('com.vmware.vsphere.client.vm')
      .controller('VmProvisioningVmVersionController', VmProvisioningVmVersionController);

   VmProvisioningVmVersionController.$inject = ['$scope', 'i18nService',
         'wizardPageService', '$filter',
         'helpService'];

   function VmProvisioningVmVersionController($scope, i18nService,
            wizardPageService, $filter, helpService) {
      this.i18n = i18nService.getString;
      this.model = $scope.selectVmVersionPageModel;
      this.form = this.model.form;
      this.form.vmParams = $scope.vmParams;
      var compatibilityLoading;
      this.form.setLoading = function(isLoading) {
         // We only want to set the loading to false if we initially set it to true
         if (!$scope.config.loading && isLoading) {
            compatibilityLoading = true;
            $scope.config.loading = true;
         } else if (compatibilityLoading && !isLoading) {
            $scope.config.loading = false;
            compatibilityLoading = false;
         }
      };

      this.checkCompatibility = function(vmVersion) {
         this.form.compatibility(vmVersion);
      }.bind(this);

      $scope.config.loadingMessage =  i18nService.getString('CommonUi', 'wizard.loading');
      $scope.config.loading = true;
      this.model.resetForm().catch(function(reason) {
         wizardPageService.markPageIncompleteWithError(
               $scope.config, $filter('errorFormat')(reason));
      }).then(function () {
         $scope.config.loading = false;
      });
   }
})();
