(function () {
   /**
    * Directive for edit HA VM monitoring.
    */
   'use strict';
   angular.module('com.vmware.vsphere.client.cluster')
      .directive('editHaVmMonitoring', editHaVmMonitoring);

   editHaVmMonitoring.$inject = ['i18nService', 'haVmMonitoringService', 'haConstants'];

   function editHaVmMonitoring(i18nService, haVmMonitoringService, haConstants) {
      return {
         restrict: 'E',
         templateUrl: 'cluster-ui/resources/cluster/views/settings/services/ha/editHaVmMonitoring.html',
         scope: {
            haConfiguration: '='
         },
         controller: ['$scope', function ($scope) {
            $scope.monitoringLevels = haVmMonitoringService.getMonitoringLevels();
            $scope.vmMonitoringSensitivitySliderOptions = getSliderOptions();
            $scope.VM_MON_SENSITIVITY_TYPE_PRESET =
               haVmMonitoringService.getSensitivityTypes().PRESET;
            $scope.VM_MON_SENSITIVITY_TYPE_CUSTOM =
               haVmMonitoringService.getSensitivityTypes().CUSTOM;

            $scope.blockOptions = {
               expanded: false
            };

            $scope.vmToolsMonSettings =
               $scope.haConfiguration.defaultVmSettings.vmToolsMonitoringSettings;
            $scope.normalizeMaxFailureWindow = normalizeMaxFailureWindow;
            $scope.normalizeMinUpTime = normalizeMinUpTime;
            $scope.normalizeMaxFailures = normalizeMaxFailures;
            $scope.normalizeFailureInt = normalizeFailureInt;

            initSensitivityLevel();

            //watchers region

            // Watch for changes on the slider value and propagate it to the model.
            $scope.$watch('vmMonitoringSensitivitySliderOptions.value',
               function (sliderValue) {
                  if ($scope.vmToolsMonSettings.vmMonitoringSensitivityType ===
                        haVmMonitoringService.getSensitivityTypes().PRESET) {
                     sliderValue = parseInt(sliderValue, 10);
                     updateCustomValues(sliderValue);
                  }
               });

            // Watch for changes on sensitivity type radio buttons
            // to update custom values if set to preset
            $scope.$watch('vmToolsMonSettings.vmMonitoringSensitivityType',
               function (sensitivityType) {
                  if (sensitivityType ===
                        haVmMonitoringService.getSensitivityTypes().PRESET) {
                     updateCustomValues(
                        $scope.vmMonitoringSensitivitySliderOptions.value);
                  }
               });

            // texts region

            $scope.haVmMonitoringLabel =
               getString(
                  'ha.config.failures.responses.vm.monitoring.vm.monitoring.check');
            $scope.heartbeatMonitoringLabel =
               getString(
                  'ha.config.failures.responses.vm.monitoring.enable.heartbeat.monitoring.label');
            $scope.heartbeatMonitoringDescriptionLabel =
               getString('ha.config.failures.responses.vm.monitoring.desc');
            $scope.vmMonitoringSensitivityLabel =
               getString('ha.config.vmResponse.vmMonitoringSensitivity');
            $scope.vmMonitoringSensitivityPresetLabel =
               getString('ha.config.vmResponse.vmMonitoringSensitivity.preset');
            $scope.vmMonitoringSensitivityCustomLabel =
               getString('ha.config.vmResponse.vmMonitoringSensitivity.custom');
            $scope.vmMonitoringSensitivityFailureIntervalLabel =
               getString('ha.config.vmResponse.vmMonitoringSensitivity.failureInterval');
            $scope.vmMonitoringSensitivityMinUptimeLabel =
               getString('ha.config.vmResponse.vmMonitoringSensitivity.minUptime');
            $scope.vmMonitoringSensitivityMaxResetsLabel =
               getString('ha.config.vmResponse.vmMonitoringSensitivity.maxResets');
            $scope.vmMonitoringSensitivityMaxResetsWindowLabel =
               getString('ha.config.vmResponse.vmMonitoringSensitivity.maxResetsWindow');
            $scope.vmMonitoringSensitivityNoWindowLabel =
               getString('ha.config.vmResponse.vmMonitoringSensitivity.noWindow');
            $scope.vmMonitoringSensitivityMaxResetsWithinLabel =
               getString('ha.config.vmResponse.vmMonitoringSensitivity.maxResetsWithin');
            $scope.vmMonitoringSensitivityHoursLabel =
               getString('ha.config.vmResponse.vmMonitoringSensitivity.hours');
            $scope.vmMonitoringSensitivitySecondsLabel =
               getString('ha.config.vmResponse.vmMonitoringSensitivity.seconds');

            //helper functions region

            /**
             * Returns an object that represents the initial options of the
             * VM monitoring sensitivity preset slider.
             */
            function getSliderOptions() {
               return {
                  min: haVmMonitoringService.getPresetLevels().LOW,
                  max: haVmMonitoringService.getPresetLevels().HIGH,
                  value: haVmMonitoringService.getPresetLevels().HIGH,
                  minLabel: getString(
                     'ha.config.vmResponse.vmMonitoringSensitivity.preset.low'),
                  maxLabel: getString(
                     'ha.config.vmResponse.vmMonitoringSensitivity.preset.high'),
                  enabled: true,
                  width: 'auto',
                  dragHandleTitle: getString('ha.config.vmResponse.vmMonitoringSensitivity') +
                      " " + getString('ha.config.vmResponse.vmMonitoringSensitivity.preset')
               };
            }

            $scope.isVmMonitoringDisabled = function() {
               return $scope.haConfiguration.vmMonitoringState ===
                  haVmMonitoringService.DEFAULT_STATE.id;
            };

            $scope.isVmMonitoringSensitivityCustom = function() {
               return $scope.vmToolsMonSettings.vmMonitoringSensitivityType ===
                  haVmMonitoringService.getSensitivityTypes().CUSTOM;
            };

            $scope.isVmMonitoringSensitivityPreset = function() {
               return $scope.vmToolsMonSettings.vmMonitoringSensitivityType ===
                  haVmMonitoringService.getSensitivityTypes().PRESET;
            };

            $scope.hasFailureWindow = function() {
               return $scope.vmToolsMonSettings.vmToolsMonitorHasFailureWindow ===
                  true;
            };

            /**
             * Determine and init VM monitoring sensitivity type (if custom or
             * preset) and preset level based on different VM monitoring
             * sensitivity options
             */
            function initSensitivityLevel() {
               //initially determine and set set slider level based on server values
               var vmMonitoringSensitivityLevel = haVmMonitoringService.
               getHaMonitoringSensitivityLevel($scope.vmToolsMonSettings);

               //set value (preset or custom) to radio button for sensitivity type
               //and sensitivity level to slider if type is preset
               if (vmMonitoringSensitivityLevel !==
                  haVmMonitoringService.getPresetLevels().CUSTOM) {
                  $scope.vmToolsMonSettings.vmMonitoringSensitivityType =
                     haVmMonitoringService.getSensitivityTypes().PRESET;
                  $scope.vmMonitoringSensitivitySliderOptions.value =
                     vmMonitoringSensitivityLevel;
               } else {
                  $scope.vmToolsMonSettings.vmMonitoringSensitivityType =
                     haVmMonitoringService.getSensitivityTypes().CUSTOM;
               }
            }

            /**
             * Updates the values of failure interval, min uptime, max failures
             * and failure window based on selected preset level
             *
             * @param presetLevel VM monitoring sensitivity preset level
             */
            function updateCustomValues(presetLevel) {
               var sensitivityLevelValues = haVmMonitoringService.
                  getSensitivityLevelValues(presetLevel);

               $scope.vmToolsMonSettings.vmToolsMonitorFailureInt =
                  sensitivityLevelValues.failure_interval;
               $scope.vmToolsMonSettings.vmToolsMonitorMinUpTime =
                  sensitivityLevelValues.min_uptime;
               $scope.vmToolsMonSettings.vmToolsMonitorMaxFailures =
                  sensitivityLevelValues.max_failures;
               $scope.vmToolsMonSettings.vmToolsMonitorMaxFailureWindow =
                  sensitivityLevelValues.failure_window;
               $scope.vmToolsMonSettings.vmToolsMonitorHasFailureWindow = true;
            }

            function normalizeFailureInt() {
               var positiveValue = $scope.vmToolsMonSettings.vmToolsMonitorFailureInt;
               if (angular.isUndefined(positiveValue) || positiveValue < 0) {
                  $scope.vmToolsMonSettings.vmToolsMonitorFailureInt = 0;
               }

               if (positiveValue > haConstants.MAX_FAILURE_INTERVAL) {
                  $scope.vmToolsMonSettings.vmToolsMonitorFailureInt =
                        haConstants.MAX_FAILURE_INTERVAL;
               }
            }

            function normalizeMinUpTime() {
               var positiveValue = $scope.vmToolsMonSettings.vmToolsMonitorMinUpTime;
               if (angular.isUndefined(positiveValue) || positiveValue < 0) {
                  $scope.vmToolsMonSettings.vmToolsMonitorMinUpTime = 0;
               }

               if (positiveValue > haConstants.MAX_MINIMUM_UPTIME) {
                  $scope.vmToolsMonSettings.vmToolsMonitorMinUpTime =
                        haConstants.MAX_MINIMUM_UPTIME;
               }
            }

            function normalizeMaxFailures() {
               var positiveValue = $scope.vmToolsMonSettings.vmToolsMonitorMaxFailures;
               if (angular.isUndefined(positiveValue) || positiveValue < 0) {
                  $scope.vmToolsMonSettings.vmToolsMonitorMaxFailures = 0;
               }

               if (positiveValue > haConstants.MAX_VM_PER_RESET) {
                  $scope.vmToolsMonSettings.vmToolsMonitorMaxFailures =
                        haConstants.MAX_VM_PER_RESET;
               }
            }

            function normalizeMaxFailureWindow() {
               var positiveValue = $scope.vmToolsMonSettings.vmToolsMonitorMaxFailureWindow;
               if (angular.isUndefined(positiveValue) || positiveValue < 1) {
                  $scope.vmToolsMonSettings.vmToolsMonitorMaxFailureWindow = 1;
               }
               if (positiveValue > haConstants.MAX_RESETS_WINDOW_HRS) {
                  $scope.vmToolsMonSettings.vmToolsMonitorMaxFailureWindow =
                        haConstants.MAX_RESETS_WINDOW_HRS;
               }
            }

            function getString(key) {
               return i18nService.getString('ClusterUi', key);
            }
         }]
      };
   }
})();
