angular.module('com.vmware.vsphere.client.vm').service('networkAdapterService', [
   'deviceService',
   'vmDeviceInfoService',
   'VirtualNetworkDevice',
   function(
      deviceService,
      vmDeviceInfoService,
      VirtualNetworkDevice
   ) {
      return {
         makeDefaultNetworkAdapter: makeDefaultNetworkAdapter
      };

      function makeDefaultNetworkAdapter(vmConfigEnvironment, guestOsDesc) {
         var nicType = guestOsDesc.recommendedEthernetCard.name;
         var nicOption = _.find(vmConfigEnvironment.configOption.hardwareOptions.virtualDeviceOption, function (deviceOption) {
            return deviceOption.type.name === nicType;
         });

         var availableNetworks = vmDeviceInfoService.buildAvailableNetworkList(vmConfigEnvironment.configTarget);
         var defaultNetwork = _.find(availableNetworks, function(candidateNetwork) {
            return recommendsRegularNetwork(nicOption) && isRegularNetwork(candidateNetwork);
         });

         if (!defaultNetwork && availableNetworks.length > 0) {
            defaultNetwork = availableNetworks[0];
         }

         var rawNetworkDevice = {
            _type: nicOption.type.name,
            key: deviceService.newKey(),
            connectable: {
               _type: "com.vmware.vim.binding.vim.vm.device.VirtualDevice$ConnectInfo",
               startConnected: optionValue(nicOption.connectOption.startConnected),
               allowGuestControl: optionValue(nicOption.connectOption.allowGuestControl),
               connected: true
            }
         };

         if (nicOption.wakeOnLanEnabled.supported &&
               guestOsDesc.supportsWakeOnLan) {
            rawNetworkDevice.wakeOnLanEnabled = nicOption.wakeOnLanEnabled.defaultValue;
         }

         var device = deviceService.setDeviceInfo(new VirtualNetworkDevice(rawNetworkDevice, defaultNetwork));

         return {
            device: device
         };
      }

      function optionValue(opt) {
         return opt.supported && opt.defaultValue;
      }

      function recommendsRegularNetwork(nicOption) {
         return nicOption.backingOption[nicOption.defaultBackingOptionIndex]._type === 'com.vmware.vim.binding.vim.vm.device.VirtualEthernetCardOption$NetworkBackingOption';
      }

      function isRegularNetwork(candidateNetwork) {
         return !!candidateNetwork.network;
      }
   }
]);
