/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace dvs_ui {

   export class DvpgCloneTrafficRulesModel {
      public dvsId: string;
      public dvpgId: string;
      public dvpgName: string;
      public selectedPortgroups: any[];
   }
}
