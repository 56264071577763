/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace dvs_ui {
   import DvsPortMiscPolicyModel =
         com.vmware.vsphere.client.h5.network.dvs.ports.model.DvsPortMiscPolicyModel;

   export class DvsPortEditMiscellaneousPageModel {
      public policy: DvsPortMiscPolicyModel;

      public isMiscellaneousPageChanged: Function;

      constructor(miscellaneousPolicy: DvsPortMiscPolicyModel) {
         if (miscellaneousPolicy) {
            this.policy = miscellaneousPolicy;

            this.isMiscellaneousPageChanged =
                  this.isMiscellaneousPageChangedFunction.bind(this);
         }
      }

      public isMiscellaneousPageChangedFunction(
            initialData: DvsPortMiscPolicyModel): boolean {

         if (!initialData || !this.policy) {
            return false;
         }

         return initialData.blockPortsOverrided !== this.policy.blockPortsOverrided
               || initialData.arePortsBlocked !== this.policy.arePortsBlocked;
      }
   }
}