/* Copyright 2018 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace network_ui {

   export class ProxySwitchDetailsDialogContent {

      public bindings: any;
      public controller: any;
      public template: string;

      constructor() {
         this.bindings = {
            dvsId: "<"
         };
         this.controller = ProxySwitchDetailsDialogContentController;
         this.template = `
               <vx-activity-indicator is-visible="$ctrl.loading"
                             title="{{i18n('Common', 'loadingTitle')}}"></vx-activity-indicator>
               <vx-property-view data="$ctrl.data"
                                 class="network-ui-fixed-size-vx-property-view"
                                 ng-if="!$ctrl.loading"/>
               `;
      }
   }

   class ProxySwitchDetailsDialogContentController {

      static readonly $inject: any[] = [
         "i18nService",
         "proxySwitchDetailsDialogService"
      ];

      public dvsId: string;

      public data: any;

      public i18n: Function;

      constructor(private i18nService: any,
            private proxySwitchDetailsDialogService: ProxySwitchDetailsDialogService) {
         this.i18n = i18nService.getString;
      }

      public $onInit(): void {
         this.proxySwitchDetailsDialogService.getData(this.dvsId)
               .then((data: any): void => {
                  this.data = data;
               });
      }

      public get loading(): boolean {
         return !this.data;
      }
   }

   angular.module("com.vmware.vsphere.client.network")
         .component("proxySwitchDetailsDialogContent",
               new ProxySwitchDetailsDialogContent());
}
