/* Copyright 2017 Vmware, Inc. All rights reserved. -- Vmware Confidential */
namespace h5_vm {
   import VmMigrateStorageSelectionValidationService = h5_vm.VmMigrateStorageSelectionValidationService;
   import ManagedObjectReference = com.vmware.vim.binding.vmodl.ManagedObjectReference;
   import VmAdvancedStoragePageData = com.vmware.vsphere.client.h5.vm.model.VmAdvancedStoragePageData;
   import VirtualDisk = com.vmware.vim.binding.vim.vm.device.VirtualDisk;

   export class MigrateSelectStoragePageComponent {
      public templateUrl: string = "vm-ui/resources/vm/views/migrationWizard/pages/migrateSelectStoragePageComponent.html";
      public controller: any = MigrateSelectStoragePageComponentController;
      public bindings: {} = {
         migrationWizardManager: "<",
         wizardConfig: "<",
         pageModel: "<",
         destinationTarget: "<"
      };
   }

   // common_ui.StorageSelectorVmData
   interface StorageSelectorVmData {
      id: string;
      name: string;
      homeDatastore: string|null;
      destinationHost?: string|null;
      disksData?: any|null;
   }

   export class MigrateSelectStoragePageComponentController {
      public static $inject = [
         "i18nService",
         "defaultUriSchemeUtil",
         "vmMigrateStorageSelectionValidationService",
         "managedEntityConstants",
         "storageSelectorService",
         "storageSelectorConstants",
         "dataService",
         "$q",
         "$element",
         "$timeout",
         "vmMigrateStorageLocatorService",
         "wizardPageService",
         "initialStorageConfigurationService",
         "storageProfileService",
         "pmemUtilService"
      ];

      public readonly VM_HOME_DATASTORE_PROP: string = "vmHomeDatastore";
      public readonly VM_HOST_PROP: string = "host";
      public readonly VM_DEVICE_PROP: string = "devices";
      public readonly ADVANCED_PAGE_DATA_PROPERTY: string = "migrateAdvancedStoragePageData";

      public migrationWizardManager: any;
      public wizardConfig: any;
      public pageModel: MigrateSelectStoragePageModel;
      public destinationTarget: string;

      public storageSelectorData: any/*common_ui.StorageSelectorData*/;
      public storageSelectorState: any/*common_ui.StorageSelectorState*/;
      public modeSettings: any;

      public storageSelectorApi: any;
      public diskFormatSettings: any;

      public compatibilityTitle: string;
      public compatibilityMessages: any[];
      public disableStoragePods: boolean;

      constructor (
            private i18nService: {
               getString(file: string, label: string): string
            },
            private defaultUriSchemeUtil: {
               getManagedObjectReference(id: string): ManagedObjectReference,
               getVsphereObjectId(ref: any): string,
               getEntityType(objectId: string): string
            },
            private vmMigrateStorageSelectionValidationService: VmMigrateStorageSelectionValidationService,
            private managedEntityConstants: any,
            private storageSelectorService: {
               getStorageSelectorApiObj(): any,
               getStorageSelectorState(): any,
               getStorageSelectorData(
                  objectId: string,
                  storageLocatorItemsData: any,
                  includeKeepExistingPolicies: boolean,
                  vmData: any[],
                  storageProfilesData: any): any,
               getStorageSelectorModeSettings(showAdvancedMode: boolean): any
            },
            private storageSelectorConstants: any,
            private dataService: any,
            private $q: angular.IQService,
            private $element: any,
            private $timeout: angular.ITimeoutService,
            private vmMigrateStorageLocatorService: VmMigrateStorageLocatorService,
            private wizardPageService: any,
            private initialStorageConfigurationService: {
               initSelectedStorageItems: (storageSelectorState: any,
                     storageSelectorData: any/*StorageSelectorData*/,
                     storageData: any) => boolean
               computeSourceVmStorageConfig: (initialVmStorageConfig: VmStorageConfig,
                     profiles: any,
                     storageSelectorData: any,
                     isCreateFromScratch: boolean) => void
            },
            private storageProfileService: {
               fetchStorageProfilesData: (vcId: string, options: any) => IPromise<any>
            },
            private pmemUtilService: PmemUtilService
      ) {
         this.compatibilityTitle = this.i18nService.getString(
               "VmUi", "SelectDatastoreProvisioningPage.Compatibility");
      }

      public $onInit() {
         this.disableStoragePods = this.migrationWizardManager.isXvcMigration()
            || this.migrationWizardManager.isXvMotion();
         const isAlreadyInitialized: boolean = this.initStorageSelectorState();

         this.modeSettings = this.storageSelectorService.getStorageSelectorModeSettings(true);

         this.storageSelectorApi = this.storageSelectorService.getStorageSelectorApiObj();

         this.diskFormatSettings = {
            diskFormatSupported: true,
            sameAsSourceSupported: true,
            thickDiskFormatSupported: true
         };

         this.setLoading(true);
         this.requestData().then(
               (storageSelectorData: any /*common_ui.StorageSelectorData*/) => {
            const isInitSelectedStorageItemPerformed = (!isAlreadyInitialized) &&
                  this.initialStorageConfigurationService.initSelectedStorageItems(
                        this.storageSelectorState, storageSelectorData, this.pageModel);
            if (!isInitSelectedStorageItemPerformed) {
               this.initSelectedStorageItem(
                     isAlreadyInitialized ? this.storageSelectorState : undefined,
                     storageSelectorData);
            }

            if (this.pageModel.initialVmStorageConfig) {
               this.initialStorageConfigurationService.computeSourceVmStorageConfig(
                     this.pageModel.initialVmStorageConfig,
                     storageSelectorData.storageProfilesData._storageProfiles,
                     storageSelectorData, false);
            }
            this.storageSelectorData = storageSelectorData;
         }).finally( () => {
            this.setLoading(false);
            this.$timeout(() => {
               const firstInput = this.$element[0].querySelector(
                     ".migrate-storage-selector input[type=checkbox]");
               if (firstInput) {
                  firstInput.focus();
               }
            }, 0);
         });
      }

      private setLoading(loading: boolean) {
         this.wizardConfig.loading = loading;
      }

      private clearMessages(): void {
         this.wizardPageService.clearErrors(this.wizardConfig);
      }

      private setWarningMessages(messages: string[]) {
         this.wizardPageService.showWarningMessages(this.wizardConfig, messages);
      };

      public onSelectionChanged() {
         // Ignore selection change notifications, while the selection is not yet complete.
         if (this.isSelectionIncomplete() ||
               this.storageSelectorApi.validateReplicationGroupSelection()) {
            this.pageModel.virtualDisksByVm = {};
            this.compatibilityMessages = [];

            const errorMessage = this.storageSelectorApi.validateReplicationGroupSelection();
            if (errorMessage) {
               this.compatibilityMessages = [{
                  contents: [],
                  icon: "vsphere-icon-status-error",
                  message: errorMessage,
                  advancedCompatibilityContents: {}
               }];
            }
            return;
         }

         if (this.isSelectorChanged()) {
            this.validateSelection();
         } else {
            this.onSkipValidation();
         }
      }

      private validateSelection() {
         this.pageModel.isValidating = true;
         this.clearMessages();

         // NOTE: The storage selector control always sends info for all VMs and for
         // all disks (as if always the advanced mode was used)
         let virtualDisksByVm = this.getVirtualDisksByVmSelections();

         let validationModel = new h5_vm.MigrationWizardDataModel(this.pageModel.vms);
         validationModel.virtualDisksByVm = virtualDisksByVm;
         validationModel.computeResource = this.pageModel.computeResource;
         validationModel.pool = this.pageModel.pool;

         this.vmMigrateStorageSelectionValidationService.validateSelection(validationModel)
         .then((response: any) => {
            this.compatibilityMessages = response.compatibilityMessages;
            this.pageModel.isAdvancedStorageMode = this.storageSelectorState.mode === "advancedMode";
            this.pageModel.virtualDisksByVm = response.hasSuccess ? virtualDisksByVm : {};

            if (this.pageModel.isAdvancedStorageMode) {
               this.pageModel.lastCompatibilityMessagesAdvancedMode = response.compatibilityMessages;
               this.pageModel.lastSelectedStorageAdvancedMode = validationModel.virtualDisksByVm;
            } else {
               this.pageModel.lastCompatibilityMessagesBasicMode = response.compatibilityMessages;
               this.pageModel.lastSelectedStorageBasicMode = validationModel.virtualDisksByVm;
               if (!this.pageModel.lastCompatibilityMessagesAdvancedMode) {
                  this.pageModel.lastCompatibilityMessagesAdvancedMode = response.compatibilityMessages;
               }
            }
         }).then(() => {
            this.pageModel.isValidating = false;
         });
      }

      /**
       * @returns
       *    'true' if the selector state was ALREADY initialized and 'false' otherwise.
       *    The state will be always initialized after this call.
       */
      private initStorageSelectorState(): boolean {
         if (!this.pageModel.isComputeResourceChanged && this.pageModel.storageSelectorState) {
            this.storageSelectorState = this.pageModel.storageSelectorState;
            return true;
         }

         this.pageModel.storageSelectorState =
               this.storageSelectorService.getStorageSelectorState();
         this.storageSelectorState = this.pageModel.storageSelectorState;
         return false;
      }

      private requestData(): angular.IPromise<any/*common_ui.StorageSelectorData*/> {
         // Return the cached value (but only if the data it depends on is not changed)
         if (!this.pageModel.isComputeResourceChanged && this.pageModel.storageSelectorData) {
            return this.$q.when(this.pageModel.storageSelectorData);
         }

         this.pageModel.isComputeResourceChanged = false;
         this.pageModel.lastSelectedStorageAdvancedMode = [];
         this.pageModel.lastSelectedStorageBasicMode = [];

         let objectId = this.pageModel.computeResource ?
               this.pageModel.computeResource : this.pageModel.vms[0];
         return this.requestVmData(objectId).then((data: any) => {
            // Once we have the vm home datastores and vm disks, we pass it to the
            // storage selector so that it can request on its own some piece of data.
            return this.storageSelectorService.getStorageSelectorData(
                  objectId,
                  this.requestStorageGridData(),
                  !this.pageModel.isXvcMigration, // includeKeepExistingPolicies
                  data.vmData,
                  data.storageProfilesData)
               .then((storageSelectorData: any) => {
                  // Cache the last retrieved data for faster page load next time.
                  this.pageModel.storageSelectorData = storageSelectorData;

                  this.pageModel.storageNameMap = this.vmMigrateStorageLocatorService.
                        getStorageObjectNameMap(storageSelectorData.storageLocatorItemsData);

                  return storageSelectorData;
               });
         });
      }

      private requestVmData(objectId: string): IPromise<any> {
         let vmPropertiesToRetrieve: string[] = [this.VM_DEVICE_PROP, this.VM_HOME_DATASTORE_PROP];
         let isChangeStorageOnlyWorkflow: boolean =
               this.migrationWizardManager.getSelectedMode() === "changeStorageOnly";
         if (isChangeStorageOnlyWorkflow) {
            vmPropertiesToRetrieve.push((this.VM_HOST_PROP));
         }
         const vcGuid = this.defaultUriSchemeUtil.getManagedObjectReference(objectId).serverGuid;
         const promises = {
            advancedStorageData: this.migrationWizardManager.getAdvancedStoragePageData(),
            profiles: this.storageProfileService.fetchStorageProfilesData(vcGuid,
                  {
                     includeKeepExisting: !this.pageModel.isXvcMigration
                  })
         };

         return this.$q.all(promises).then(
               (results: any) => {
                  const advancedDataByVm: {[vmId: string]: {[key: string]: VmAdvancedStoragePageData}} = results.advancedStorageData;
                  let vmData: any[] = [];
                  const storageProfilesData: any = results.profiles;
                  storageProfilesData.vmProfileAssignments = {};
                  storageProfilesData.vmReplicationGroupAssignments = {};
                  this.pageModel.vmHomeDatastores = {};
                  this.pageModel.vmDisks = {};
                  this.pageModel.vmHosts = {};
                  _.forEach(this.pageModel.vms, (vmId: string) => {
                     if (!advancedDataByVm || !advancedDataByVm[vmId] || !advancedDataByVm[vmId][this.ADVANCED_PAGE_DATA_PROPERTY]) {
                        return;
                     }

                     const advancedDataPerVm: VmAdvancedStoragePageData =
                           advancedDataByVm[vmId][this.ADVANCED_PAGE_DATA_PROPERTY];

                     let destinationHost: string|null = null;
                     this.pageModel.vmHosts[vmId] = this.defaultUriSchemeUtil.getVsphereObjectId(
                              advancedDataPerVm.host);
                     if (isChangeStorageOnlyWorkflow) {
                        if (advancedDataPerVm.host) {
                           destinationHost = this.pageModel.vmHosts[vmId];
                        }
                     } else {
                        destinationHost = (this.pageModel.computeResource &&
                        this.defaultUriSchemeUtil.getEntityType(this.pageModel.computeResource) === "HostSystem")
                              ? this.pageModel.computeResource : null;
                     }

                     let virtualDisks = advancedDataPerVm.virtualDisks || [];
                     this.pageModel.vmDisks[vmId] = virtualDisks;
                     let vmHomeDatastoreId = advancedDataPerVm.vmHomeDatastore
                           ? this.defaultUriSchemeUtil.getVsphereObjectId(
                                 advancedDataPerVm.vmHomeDatastore)
                           : null;
                     this.pageModel.vmHomeDatastores[vmId] = vmHomeDatastoreId;
                     storageProfilesData.vmProfileAssignments[vmId] =
                           advancedDataPerVm.storagePoliciesAssignments;
                     storageProfilesData.vmReplicationGroupAssignments[vmId] =
                           advancedDataPerVm.vmReplicationGroupAssignments;

                     // common_ui.StorageSelectorVmProperties
                     vmData.push({
                        id: vmId,
                        name: this.migrationWizardManager.getVmNames()[vmId],
                        homeDatastore: vmHomeDatastoreId,
                        destinationHost: destinationHost,
                        disksData: virtualDisks
                     });
                  });

                  return {
                     vmData: vmData,
                     storageProfilesData: storageProfilesData
                  };
         });
      }

      private requestStorageGridData() {
         // TODO speev: Make the mode part of the page model
         if (this.migrationWizardManager.getSelectedMode() === "changeStorageOnly") {
            return this.vmMigrateStorageLocatorService.getStorageLocatorData({
                     vms: this.pageModel.vms
                  });
         } else {
            return this.vmMigrateStorageLocatorService.getStorageLocatorData({
                     vms: this.pageModel.vms,
                     resource: this.pageModel.computeResource
                  });
         }
      }

      private initSelectedStorageItem(
            storageSelectorState: any,
            storageSelectorData: any/*StorageSelectorData*/): any {
         if (storageSelectorState) {
            // Storage selector state already exists and is initialized => we do nothing.
            // It keeps the last user selection on itw own.
            return;
         }

         if (!storageSelectorData.initialConfig) {
            storageSelectorData.initialConfig = {};
         }

         let preselection = this.calculateSelectedStorageItem(
               storageSelectorData.storageLocatorItemsData,
               storageSelectorData.sourceVmStorageConfig);

         if (preselection.reason) {
            this.setWarningMessages([preselection.reason]);
         }

         storageSelectorData.initialConfig.selectedItemInBasicMode = preselection.item;
      }

      /**
       * @returns A tuple
       *   {
       *       item -> the storage item that should be selected.
       *       reason -> if 'item' is empty, then the 'reason' contains a string
       *          explaining why there is no item to be selected.
       *   }
       */
      private calculateSelectedStorageItem(
            storageLocatorItemsData: any,
            sourceVmStorageConfig: any[]): {item: any, reason?: string} {
         let entityType: string | undefined;
         entityType = this.destinationTarget ?
               this.defaultUriSchemeUtil.getEntityType(this.destinationTarget) :
               undefined;
         if (entityType === this.managedEntityConstants.DATASTORE
               || entityType === this.managedEntityConstants.STORAGE_POD) {
            let foundEntity: any;
            switch (entityType) {
               case this.managedEntityConstants.DATASTORE:
                  foundEntity = this.findStorageItem(
                        storageLocatorItemsData.datastoreItems,
                        this.destinationTarget);
                  break;
               case this.managedEntityConstants.STORAGE_POD:
                  foundEntity = this.findStorageItem(
                        storageLocatorItemsData.storagePodItems,
                        this.destinationTarget);
                  foundEntity = foundEntity.drsEnabled ? foundEntity : undefined;
                  break;
               default:
                  foundEntity = undefined;
                  break;
            }

            let reason: string | undefined;
            reason = !foundEntity ? this.i18nService.getString("VmUi",
                        "MigrationWizard.storageListPage.warning.storageNotPrelected") : undefined;
            return {
               item: foundEntity,
               reason: reason
            };
         }

         let currentVmsStorage = this.collectCurrentVmsStorage(sourceVmStorageConfig);

         if (_.size(currentVmsStorage) === 1) {
            let currentStorageItem = this.findStorageItem(
                  storageLocatorItemsData.datastoreItems, currentVmsStorage[0]);
            if (currentStorageItem) {
               let storagePod = this.findStorageItem(
                     storageLocatorItemsData.storagePodItems,
                     this.defaultUriSchemeUtil.getVsphereObjectId(
                           currentStorageItem.parentStoragePod));
               if (storagePod && storagePod.drsEnabled) {
                  // NOTE: If we preselect the current storage pod of the VM,
                  // the validation will return an error
                  return {
                     item: undefined
                  };
               }

               return {
                  item: currentStorageItem
               };
            }
         }

         return {
            item: this.getSingleDatastoreOrPod(storageLocatorItemsData)
         };
      }

      // NOTE speev: All the logic below tries to predict when a single item
      // will be visualized in the storage locator an if so - return it for
      // preselection.
      private getSingleDatastoreOrPod(storageLocatorItemsData: any): any {
         let drsEnabledStoragePods = _.reduce(storageLocatorItemsData.storagePodItems,
            (memo: any, spItem: any) => {
               if (spItem.drsEnabled) {
                  memo[this.defaultUriSchemeUtil.getVsphereObjectId(
                     spItem.storageRef)] = spItem;
               }

               return memo;
            }, {});

         if (_.size(drsEnabledStoragePods) > 1) {
            return undefined;
         }

         let standaloneDatastores = _.filter(storageLocatorItemsData.datastoreItems,
            (dsItem: any) => {
               return !dsItem.parentStoragePod ||
                  !drsEnabledStoragePods[this.defaultUriSchemeUtil.getVsphereObjectId(
                     dsItem.parentStoragePod)];
            });

         if (_.isEmpty(drsEnabledStoragePods)) {
            if (_.size(standaloneDatastores) === 1) {
               return standaloneDatastores[0];
            }

            return undefined;
         }

         // We have 1 storage pod here - so we have at least 1 item in the grid.
         if (_.isEmpty(standaloneDatastores)) {
            // A twisted way to take the first map value
            return _.find(drsEnabledStoragePods, () => { return true; });
         }

         // More than 1 item in the grid - no selection.
         return undefined;
      }

      private getVirtualDisksByVmSelections():
            {[key: string]: Array<VirtualDiskRecord>} {
         let virtualDisksByVm: {[key: string]: Array<VirtualDiskRecord>} = {};

         _.each(this.storageSelectorData.vmStorageConfig,
               (vmStorageConfig: any/*common_ui.VmStorageConfig*/) => {
            let vmId: string = vmStorageConfig.vmId;
            if (!virtualDisksByVm[vmId]) {
               virtualDisksByVm[vmId] = [];
            }

            let homeStorageConfig: any /*common_ui.VmComponentStorageConfig*/ = vmStorageConfig.vmHome;
            if (!homeStorageConfig.storageObj) {
               // The selection is not here yet?
               return;
            }

            // Push one record for the vm config file
            virtualDisksByVm[vmId].push({
               key: "_configFile",
               label: homeStorageConfig.name,
               storage: this.defaultUriSchemeUtil.getVsphereObjectId(
                     homeStorageConfig.storageObj.storageRef),
               parentStoragePod:  this.defaultUriSchemeUtil.getVsphereObjectId(
                     homeStorageConfig.storageObj.parentStoragePod),
               profile: homeStorageConfig.storageProfile,
               diskFormat: homeStorageConfig.diskFormat ? {
                     label: homeStorageConfig.diskFormat.name,
                     value: homeStorageConfig.diskFormat.type
                  } : undefined,
               storageType: homeStorageConfig.storageObj.type,
               replicationGroup: homeStorageConfig.replicationGroup
            });

            // And one record for each VM disk
            _.each(vmStorageConfig.vmDisks,
                  (diskStorageConfig: any /*common_ui.VmComponentStorageConfig*/) => {
               virtualDisksByVm[vmId].push({
                  key: diskStorageConfig.key,
                  label: diskStorageConfig.name,
                  storage: diskStorageConfig.storageObj ?
                        this.defaultUriSchemeUtil.getVsphereObjectId(
                           diskStorageConfig.storageObj.storageRef) : undefined,
                  parentStoragePod: diskStorageConfig.storageObj ?
                        this.defaultUriSchemeUtil.getVsphereObjectId(
                           diskStorageConfig.storageObj.parentStoragePod) : undefined,
                  profile: diskStorageConfig.storageProfile,
                  diskFormat: diskStorageConfig.diskFormat ? {
                        label: diskStorageConfig.diskFormat.name,
                        value: diskStorageConfig.diskFormat.type
                     } : undefined,
                  storageType: diskStorageConfig.storageObj ?
                        diskStorageConfig.storageObj.type : undefined,
                  capacityInBytes: diskStorageConfig.capacityInBytes,
                  replicationGroup: diskStorageConfig.replicationGroup,
                  origin: _.findWhere(
                        this.pageModel.vmDisks[vmId], {key: diskStorageConfig.key})
               });
            });
         });

         return virtualDisksByVm;
      }

      private isSelectionIncomplete(): boolean {
         let result: boolean = _.some(this.storageSelectorData.vmStorageConfig,
            (vmStorageConfig: any/*common_ui.VmStorageConfig*/) => {
               // Check the VM home storage availability
               if (!vmStorageConfig.vmHome.storageObj) {
                  // The selection is not here yet
                  return true;
               }

               // If all disks should go to PMEM there is no need to validate that
               // they have a proper selection.
               if (this.storageSelectorState.storageBaselineId ===
                     this.storageSelectorConstants.PMEM_STORAGE_BASELINE.id) {
                  return false;
               }

               // And then each disk storage availability
               return _.some(vmStorageConfig.vmDisks, (diskStorageConfig: any) => {
                     // If nothing is selected and the disk is not on a PMEM storage
                     // then selection is INCOMPLETE.
                     return !diskStorageConfig.storageObj
                           && !this.pmemUtilService.isPMemStoragePolicy(
                                 diskStorageConfig.storageProfile);
               });
            });

         return result;
      }

      private collectCurrentVmsStorage(
            sourceVmStorageConfig: any[]/*VmStorageConfig[]*/) {
         let currentStoragesMap: {[storageId: string]: boolean} = {};
         _.each(sourceVmStorageConfig,
               (vmStorageConfig: any/*VmComponentStorageConfig*/) => {

            if (vmStorageConfig.vmHome.storageObj) {
               let storageId: string = this.defaultUriSchemeUtil.getVsphereObjectId(
                     vmStorageConfig.vmHome.storageObj.storageRef);
               currentStoragesMap[storageId] = true;
            }

            _.each(vmStorageConfig.vmDisks,
                  (diskStorageConfig: any/*VmComponentStorageConfig*/) => {
               if (diskStorageConfig.storageObj) {
                  let storageId = this.defaultUriSchemeUtil.getVsphereObjectId(
                     diskStorageConfig.storageObj.storageRef);
                  currentStoragesMap[storageId] = true;
               }
            });
         });

         return _.keys(currentStoragesMap);
      }

      private findStorageItem(listOfStorageLocatorItems: any[], storageId?: string) {
         return _.find(listOfStorageLocatorItems, (item: any) => {
            return this.defaultUriSchemeUtil.getVsphereObjectId(
                  item.storageRef) === storageId;
         });
      }

      private isSelectorChanged(): boolean {
         if (this.pageModel.isComputeResourceChanged) {
            return true;
         }

         let virtualDisksByVm: any = this.getVirtualDisksByVmSelections();
         this.pageModel.isAdvancedStorageMode =
            (this.storageSelectorState.mode === "advancedMode");

         let key: string = this.pageModel.isAdvancedStorageMode
               ? "lastSelectedStorageAdvancedMode" : "lastSelectedStorageBasicMode";

         let pageModel = this.pageModel as any;
         if (angular.equals(virtualDisksByVm, pageModel[key])) {
            return false;
         }

         pageModel[key] = virtualDisksByVm;
         return true;
      }

      private onSkipValidation(): void {
         if (this.pageModel.isAdvancedStorageMode) {
            this.compatibilityMessages = this.pageModel.lastCompatibilityMessagesAdvancedMode;
         } else {
            this.compatibilityMessages = this.pageModel.lastCompatibilityMessagesBasicMode;
         }
         this.pageModel.virtualDisksByVm = this.getVirtualDisksByVmSelections();
      }

   } // class MigrateSelectStoragePageComponentController

   angular.module("com.vmware.vsphere.client.vm").component(
         "migrateSelectStoragePageComponent", new MigrateSelectStoragePageComponent());

} // namespace
