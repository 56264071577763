/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace storage_ui {

   import HostData = com.vmware.vsphere.client.h5.storage.data.host.HostData;

   class NfsKerberosAuthenticationPageController implements angular.IController {

      static $inject = ["i18nService", "datastoreConstants"];
      private commonWizardManager: any;
      public onSelectionChanged: Function;
      public selectedOption: string;

      public i18n: any;
      public isKrbOptionEnabled: boolean;
      public isHostContextObject: boolean;
      public isKrb5iOptionEnabled: boolean;
      public isKrbOption: boolean;
      public isKrb5iOption: boolean;
      public noKerberos: string = this.datastoreConstants.kerberosSecurityTypes.AUTH_SYS;
      public authenticaionOnly: string = this.datastoreConstants.kerberosSecurityTypes.SEC_KRB5;
      public authenticationAndIntegrity: string = this.datastoreConstants.kerberosSecurityTypes.SEC_KRB5I;

      constructor(private i18nService: any, private datastoreConstants: any) {
         this.i18n = this.i18nService.getString.bind(this, "StorageUi");
      }

      public $onInit() {
         this.isHostContextObject = this.commonWizardManager.isHostContextObject();
         this.commonWizardManager.getAvailableHosts().then((result: HostData[]) => {
            let hosts: HostData[] = result;
            if (this.isHostContextObject && hosts.length === 1) {
               this.isKrb5iOptionEnabled = hosts[0].isKerberos5iSupported;
               this.isKrbOptionEnabled = hosts[0].isInDomain && hosts[0].areKerberosCredentialsSet;
            } else {
               this.isKrb5iOptionEnabled = true;
               this.isKrbOptionEnabled = true;
            }
            this.selectedOption = this.selectedOption || this.noKerberos;
            this.onSelectionChanged(this.selectedOption);

            this.isKrbOption = this.isHostContextObject && !this.isKrbOptionEnabled;
            this.isKrb5iOption = this.isHostContextObject && (!this.isKrbOptionEnabled || !this.isKrb5iOptionEnabled);
         });
      }
   }
   angular.module("com.vmware.vsphere.client.storage")
         .component("nfsKerberosAuthenticationPage",
               {
                  controller: NfsKerberosAuthenticationPageController,
                  bindings: {
                     commonWizardManager: "<",
                     onSelectionChanged: "<",
                     selectedOption: "<"
                  },
                  templateUrl: "storage-ui/resources/storage/views/wizard/createDatastore/pages/nfs/NfsKerberosAuthenticationPage.html"
               });

}
