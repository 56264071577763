(function () {
   'use strict';

   /**
    * @name com.vmware.vsphere.client.host:hostServiceStartupPolicy
    *
    * @description
    *    Represents vim.host.Service.Policy enum values.
    *    
    *    List of available constants
    *    
    *    - **`ON`** : Service should be started when the host starts up.
    *    - **`OFF`** : Service should not be started when the host starts up.
    *    - **`AUTOMATIC`** : Service should run if and only if it has open firewall ports.
    */
   angular
      .module('com.vmware.vsphere.client.host')
      .constant('hostServiceStartupPolicy', {
         ON: 'on',
         OFF: 'off',
         AUTOMATIC: 'automatic'
      });
})();
