namespace h5_vm {

   import BootOptionsData = com.vmware.vim.binding.vim.vm.BootOptions;
   import VmPowerStates = com.vmware.vim.binding.vim.VirtualMachine$PowerState;
   import VmWorkflowMode = h5_vm.VmWorkflowMode;
   import VmHardwareUtil = h5_vm.VmHardwareUtil;

   export class BootOptionsConstants {
      public static readonly BOOT_DELAY_MIN: number = 0;
      public static readonly BOOT_DELAY_MAX: number = 10000;
      public static readonly RETRY_DELAY_MIN: number = 10;
      public static readonly RETRY_DELAY_MAX: number = 2147483;
      public static readonly BOOT_DELAY_STEP: number = 100;
      public static readonly VM_POWERED_OFF: VmPowerStates = <VmPowerStates>"poweredOff";
   }
   /**
    * class holds the menuItem data for the firmware select
    */
   export class FirmwareMenuItem {
      constructor(public displayName: string,
            public realName: string,
            public id: string,
            public isRecommended: boolean) {
      }
   }
   /**
    * class representing the view viewModel, and can be directly applied to
    * hardwareConfigData back by calling cloneBootOptionsData().
    * inherits the vmodl class BootOptions
    */
   export class BootOptionsViewModel extends BootOptionsData {

      // private members
      private readonly BOOT_DELAY_DEFAULT: number = BootOptionsConstants.BOOT_DELAY_MIN;
      private readonly RETRY_DELAY_DEFAULT: number = BootOptionsConstants.RETRY_DELAY_MIN *
            BootOptionsViewModel.MILLISECONDS;

      private readonly RETRY_ENABLED_DEFAULT: boolean = false;
      private readonly ENTER_BIOS_DEFAULT: boolean = false;

      // public properties
      public static readonly MILLISECONDS: number = 1000;
      public readonly bootDelayMin: number = BootOptionsConstants.BOOT_DELAY_MIN;
      public readonly bootDelayMax: number = BootOptionsConstants.BOOT_DELAY_MAX;
      public readonly bootDelayStep: number = BootOptionsConstants.BOOT_DELAY_STEP;
      public readonly retryDelayMin: number = BootOptionsConstants.RETRY_DELAY_MIN;
      public readonly retryDelayMax: number = BootOptionsConstants.RETRY_DELAY_MAX;

      public firmwareList: any[] = [];
      public firmwareSelection: FirmwareMenuItem;
      public firmwareWarningFlag: boolean = false;
      public firmwareCompatibilityWarningFlag: boolean = false;
      public incompatibleFirmware: string = "";
      public firmwareEnabled: boolean;
      public inCreateMode: boolean = false;

      public bootRecoverySettingsEditDisabled: boolean = false;
      public bootDelayEditDisabled: boolean = false;
      public isEnterBIOSSetupEditDisabled: boolean = false;
      public bootRetryEditDisabled: boolean = false;
      public bootRetryDelayEditDisabled: boolean = false;

      public bootOptionsSupported: boolean = false;
      public bootRetryOptionsSupported: boolean = false;


      public constructor(model: any = {}) {
         super();
         this.bootDelay = model.bootDelay || this.BOOT_DELAY_DEFAULT;
         this.bootRetryDelay = model.bootRetryDelay || this.RETRY_DELAY_DEFAULT;
         // convert this field to sec.
         this.bootRetryDelay = this.bootRetryDelay / BootOptionsViewModel.MILLISECONDS;
         this.bootRetryEnabled = model.bootRetryEnabled || this.RETRY_ENABLED_DEFAULT;

         this.efiSecureBootEnabled = model.efiSecureBootEnabled || false;
         this.enterBIOSSetup = model.enterBIOSSetup || this.ENTER_BIOS_DEFAULT;
         this.networkBootProtocol = model.networkBootProtocol || null;
         this.bootOrder = model.bootOrder || null;
      }

      /**
       * makes a clone of this object and returns
       * @returns {BootOptionsData}
       */
      public cloneBootOptionsData(): BootOptionsData {
         let result: BootOptionsData = new BootOptionsData();
         result._type = "com.vmware.vim.binding.vim.vm.BootOptions";
         result.bootDelay = this.bootDelay;
         result.bootRetryDelay = this.bootRetryDelay * BootOptionsViewModel.MILLISECONDS;
         result.bootRetryEnabled = this.bootRetryEnabled;
         result.bootOrder = this.bootOrder;
         result.efiSecureBootEnabled = this.efiSecureBootEnabled;
         result.networkBootProtocol = this.networkBootProtocol;
         result.enterBIOSSetup = this.enterBIOSSetup;
         return result;
      }

      public selectFirmware(id: string): void {
         const array: Array<FirmwareMenuItem> = this.firmwareList;
         if (!array) {
            return;
         }
         for(let item of array) {
            if (item && item.id === id) {
               this.firmwareSelection = item;
               return;
            }
         }
      }
   }

   export class BootOptionsBuilderService {
      private readonly STEP_MIDVALUE: number = BootOptionsConstants.BOOT_DELAY_STEP / 2;

      private i18n: Function;
      static $inject= [
            "i18nService", "templateHelperService"
      ];

      constructor(private i18nService: any, private templateHelperService: any) {
         this.i18n = i18nService.getString;
      }

      // ==========================================================================
      // ============================public methods================================
      // ==========================================================================
      /**
       * @param configContext : the configContext directly received from hardwareConfigData
       *
       * @returns {BootOptionsViewModel} returns a typed viewModel reflecting bootOptions
       */
      public buildViewModel = (configContext: any, mode: VmWorkflowMode, isVbsEnabled: boolean, vbsPropertyValues: any): BootOptionsViewModel => {
         let result: BootOptionsViewModel;
         if (!configContext || !configContext.config || !configContext.config.bootOptions) {
            // return the default viewModel
            result = new BootOptionsViewModel();
         } else {
            result = new BootOptionsViewModel(configContext.config.bootOptions);
         }

         let firmwareToSelect: string = '';
         if (isVbsEnabled) {
            result.efiSecureBootEnabled = vbsPropertyValues["bootOptions.efiSecureBootEnabled"];
            firmwareToSelect = vbsPropertyValues["firmware"];
         }
         result = this.addFirmwareListToModel(result, configContext, firmwareToSelect);
         result.firmwareEnabled = configContext.environment.powerState === BootOptionsConstants.VM_POWERED_OFF;

         if (this.needsPrivilegeCheck(mode)) {
            this.checkSettingsPrivileges(result, configContext);
         }

         let caps = configContext.environment.configOption.capabilities;

         result.bootOptionsSupported = caps.bootOptionsSupported;
         result.bootRetryOptionsSupported = caps.bootRetryOptionsSupported;

         return result;
      }

      public isBootRetryDelayEditDisabled(viewModel: BootOptionsViewModel) {
         if (viewModel === null) {
            return false;
         }
         return viewModel.bootRetryDelayEditDisabled || !viewModel.bootRetryEnabled;
      }

      private needsPrivilegeCheck(mode: VmWorkflowMode): boolean {
         if (mode !== VmWorkflowMode.CreateMode) {
            return true;
         }
         return false;
      }

      private checkSettingsPrivileges(result: BootOptionsViewModel, configContext: any): void {
         let userHas: any = h5_vm.VmHardwareUtil.userHasVmSettingsPermission(configContext);

         if (userHas === true) {
            return;
         }

         result.firmwareEnabled = false;
         result.bootRecoverySettingsEditDisabled = true;
         result.bootDelayEditDisabled = true;
         result.isEnterBIOSSetupEditDisabled = true;
         result.bootRetryEditDisabled = true;
         result.bootRetryDelayEditDisabled = true;
         return;
      }

      /**
       * @param viewModel : the view Model from which to extract the bootOptionsData
       * @returns {any} : the bootOptions object that can be set on hardwareConfigData
       */
      public processBootOptionsForUpdate = (viewModel: BootOptionsViewModel,
            originalModel: BootOptionsData): BootOptionsViewModel | null => {
         if (!viewModel) {
            return null;
         }

         // first ensure we're within the boundaries
         viewModel.bootDelay = this.limitBootDelay(viewModel.bootDelay, originalModel) ||
               BootOptionsConstants.BOOT_DELAY_MIN;
         viewModel.bootDelay = this.roundOff(viewModel.bootDelay);

         // ensure we're within the boundaries.
         viewModel.bootRetryDelay = this.limitBootRetryDelay(viewModel.bootRetryDelay, originalModel) ||
               BootOptionsConstants.RETRY_DELAY_MIN;

         // now make a clone of the core for the update bubble back to parent
         let updatedModel: BootOptionsViewModel = viewModel;
         return updatedModel;
      }

      public shouldEnableFirmwareWarning(model: any, originalFirmwareSelection: any) {
         return !model.inCreateMode && model.firmwareSelection.id !== originalFirmwareSelection.id;
      }

      public shouldEnableFirmwareCompatibilityWarning(model: any): boolean {
         return !model.inCreateMode && model.incompatibleFirmware === model.firmwareSelection.id;
      }

      public getFirmwareSignPostParams() {
         const bootOptionsTemplate ='vm-ui/resources/vm/views/settings/vmOptions/bootOptions/boot-options-firmware-help-text.html';
         return this.templateHelperService.renderTemplateWithScope(bootOptionsTemplate, {i18n: this.i18nService.getString})
             .then((content: string) => {
                return {
                   message: content,
                   title: this.i18nService.getString('Common', 'help'),
                   class: 'boot-options-help-text'
                };
             });
      }

      public getSecureBootSignPostParams() {
         const bootOptionsTemplate ='vm-ui/resources/vm/views/settings/vmOptions/bootOptions/boot-options-secureBoot-help-text.html';
         return this.templateHelperService.renderTemplateWithScope(bootOptionsTemplate, {i18n: this.i18nService.getString})
             .then((content: string) => {
                return {
                   message: content,
                   title: this.i18nService.getString('Common', 'help'),
                   class: 'boot-options-help-text'
                };
             });
      }

      // ========================================================================================
      // ================================private methods=========================================
      // ========================================================================================

      private addFirmwareListToModel(model: BootOptionsViewModel, configContext: any, firmwareToSelect: string): BootOptionsViewModel {
         if (!configContext || !configContext.config ||
               !configContext.environment ||
               !configContext.environment.configOption ||
               _.isEmpty(configContext.environment.configOption.guestOSDescriptor)) {
            // add the default menu item
            model.firmwareList.push(
                  this.buildFirmwareMenuItem("", "unknown"));
            model.firmwareSelection = model.firmwareList[0];
            return model;
         }

         // build the firmware list
         let configOption: any = configContext.environment.configOption;
         if (configOption.guestOSDescriptor.length > 1) {
            // create mode will have more than 1 gosDescriptors - so find the guestId set on the VM
            let guestId: string = configContext.config.guestId;
            model = this.addFirmwareListForCreateMode(model, guestId, configOption.guestOSDescriptor, firmwareToSelect);
         } else { // must be 1
            model = this.buildFirmwareListOnModel(model, configOption.guestOSDescriptor[0],
                  configContext.config.firmware);
         }
         return model;
      }

      private addFirmwareListForCreateMode(model: BootOptionsViewModel, guestId: string,
                                           gosDescriptorList: any[],
                                           firmwareToSelect: string): BootOptionsViewModel {
         let gosTarget = _.find(gosDescriptorList, (gosDesc) => {
            return gosDesc.id === guestId;
         });

         // gosFirmware = '' in createMode
         return this.buildFirmwareListOnModel(model, gosTarget, "", true, firmwareToSelect);
      }

      private buildFirmwareListOnModel(model: BootOptionsViewModel, gosDescriptor: any,
                                       gosFirmware: string, createMode: boolean = false,
                                       firmwareToSelect: string = ""): BootOptionsViewModel {
         firmwareToSelect = _.isEmpty(firmwareToSelect) ?
             !_.isEmpty(gosFirmware) ? gosFirmware : gosDescriptor.recommendedFirmware :
             firmwareToSelect;

         _.each(gosDescriptor.supportedFirmware, (firmware) => {
            let firmwareMenuItem: FirmwareMenuItem =
                  this.buildFirmwareMenuItem(gosDescriptor.recommendedFirmware, <string>firmware);
            model.firmwareList.push(firmwareMenuItem);
            model.inCreateMode = createMode;

            if (firmwareToSelect === firmware) {
               model.firmwareSelection = firmwareMenuItem;
            }
         });

         if (!createMode && !_.contains(gosDescriptor.supportedFirmware, gosFirmware)) {
            let firmwareMenuItem: FirmwareMenuItem =
                  this.buildFirmwareMenuItem(gosDescriptor.recommendedFirmware, <string> gosFirmware);
            model.firmwareList.push(firmwareMenuItem);
            model.inCreateMode = createMode;
            model.firmwareSelection = firmwareMenuItem;
            model.incompatibleFirmware = gosFirmware;
         }

         return model;
      }

      private buildFirmwareMenuItem(recommendedFirmware: string, firmware: string): FirmwareMenuItem {
         let isRecommended: boolean = false;
         let firmwareRealName: string = this.i18n("VmUi", "BIOSOptions." + firmware); // default
         let firmwareDisplayName: string = firmwareRealName;
         if (recommendedFirmware === firmware && !_.isEmpty(recommendedFirmware)) {
            firmwareDisplayName = firmwareRealName + " " + this.i18n("VmUi", "BIOSOptions.recommended");
            isRecommended = true;
         }
         return new FirmwareMenuItem(firmwareDisplayName, firmwareRealName, firmware, isRecommended);
      }

      private roundOff = (value: number): number => {
         let remainder: number = value % BootOptionsConstants.BOOT_DELAY_STEP;
         return (remainder === 0) ? value :
               (remainder < this.STEP_MIDVALUE) ? (value - remainder) :
                     (value + BootOptionsConstants.BOOT_DELAY_STEP - remainder);
      }

      private limitBootDelay(value: number, originalModel: BootOptionsData): number {
         return isNaN(value) ? originalModel.bootDelay :
               (value > BootOptionsConstants.BOOT_DELAY_MAX) ? BootOptionsConstants.BOOT_DELAY_MAX :
                     (value < BootOptionsConstants.BOOT_DELAY_MIN ) ? BootOptionsConstants.BOOT_DELAY_MIN :
                           value;
      }

      private limitBootRetryDelay(value: number, originalModel: BootOptionsData): number {
         return isNaN(value) ? originalModel.bootRetryDelay / BootOptionsViewModel.MILLISECONDS :
               (value > BootOptionsConstants.RETRY_DELAY_MAX) ? BootOptionsConstants.RETRY_DELAY_MAX :
                     (value < BootOptionsConstants.RETRY_DELAY_MIN ) ? BootOptionsConstants.RETRY_DELAY_MIN :
                           value;
      }
   }

   angular.module("com.vmware.vsphere.client.vm")
         .service("vmBootOptionsBuilderService", BootOptionsBuilderService);
}
