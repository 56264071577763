(function () {
   'use strict';
   angular.module('com.vmware.vsphere.client.host')
         .controller('StandbyModeConfirmationController', StandbyModeConfirmationController);

   StandbyModeConfirmationController.$inject = ['$scope', 'mutationService', 'standbyModeConfirmationService', '$element', '$timeout' +
   ''];

   function StandbyModeConfirmationController($scope, mutationService, confirmationService, $element, $timeout) {
      var hostStandbySpec = {
         standbyOpType: 'enter'
      };
      var self = this;
      self.loading = true;
      self.confirmationData = [];
      self.hasWarningMessages = false;
      self.shouldEvacuateVms = false;

      var targets = $scope.modalOptions.dialogData.availableTargets;

      $scope.modalOptions.onSubmit = enterHostInStandByMode;
      $scope.evacuateVms = false;

      $scope.onEvacuateVmsChange = function(evacuateVms) {
         hostStandbySpec.evacuatePoweredOffVms = evacuateVms;
      };

      mutationService.validateOnMultiEntity(targets,
            'com.vmware.vsphere.client.host.standby.HostStandbySpec',
            hostStandbySpec).then(function(data) {
         //error handling
         var validationData = confirmationService.processStandbyValidationResult(data);

         self.confirmationData = validationData;
         self.hasWarningMessages = validationData.warningMessages.length > 0;
         self.shouldEvacuateVms = validationData.shouldEvacuateVms;
         self.loading = false;
      });

      function enterHostInStandByMode() {
         mutationService.applyOnMultiEntity(
               targets,
               'com.vmware.vsphere.client.host.standby.HostStandbySpec',
               hostStandbySpec
         );
         return true;
      }
   }

})();
