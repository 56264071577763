module host_ui {

   class HostSoftwarePackagesController implements angular.IController {
      static $inject = ["$scope", "i18nService"];

      private hostId: string;
      private objectsToWatch: Array<any>;
      private headerOptions: any;

      constructor(private $scope: any, private i18nService: any) {
         this.hostId = $scope._route.objectId;
         this.objectsToWatch = [$scope._route.objectId];
         this.headerOptions = {
            title: this.i18nService.getString('HostUi', 'imageProfileTOC.softwarePackages.header'),
            actions: []
         };
      }
   }

   angular.module("com.vmware.vsphere.client.host")
         .controller("HostSoftwarePackagesController", HostSoftwarePackagesController);

}

