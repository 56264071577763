module ds_cluster_ui {

   export interface MasterViewScope extends ScopeRoute {
      preselectComparator: Function;
      masterDetailsViewContext: any;
   }

   export class DsClusterConnectivityMasterController {
      public watchForObjects: string[];
      public objectId: string;
      public masterViewGridOptions: any;
      private selectedItem: any = null;

      public static $inject = ["$scope", "i18nService", "dataService", "navigation",
      "vuiConstants", "columnRenderersRegistry", "dsClusterConectivityService"];

      constructor(private $scope: MasterViewScope, private i18nService: any,
                  private dataService: any,
                  private navigation:any,
                  private vuiConstants: any,
                  private columnRenderersRegistry: any,
                  private dsClusterConectivityService: any) {
         this.objectId = navigation.getRoute().objectId;
         this.watchForObjects = [this.objectId];
         this.masterViewGridOptions = this.dsClusterConectivityService
               .getMasterViewGridOptions();

         $scope.$watch(() => {
                  return this.masterViewGridOptions.selectedItems;
               },
               (newvalue: any) => {
                  if (newvalue && newvalue.length > 0) {
                     $scope.masterDetailsViewContext.selectedItems = [newvalue];
                     this.selectedItem = newvalue[0];
                  }
                  else if ($scope.masterDetailsViewContext !== undefined){
                     $scope.masterDetailsViewContext.selectedItems = [];
                  }
               });

         this.getViewData();
      }

      public getViewData(): void {
         this.dataService.getProperties(this.objectId, ["computedDsClusterConnectivity"])
               .then((connectivityData: any) => {

                  let masterGridData: any = _.map(
                        connectivityData.computedDsClusterConnectivity,
                        (clusterData: any): any => {
                           return {
                        name: clusterData.name,
                        icon: clusterData.icon,
                        connectionLabel: clusterData.isConnected ?
                              this.i18nService.getString("DsClusterUi",
                                    "dsClusterComputeResourceList.allDatastoresConnected") :
                              this.i18nService.getString("DsClusterUi",
                                    "dsClusterComputeResourceList.datastoreConnectionMissing"),
                        isConnectedIcon: clusterData.isConnected ? "vx-icon-compliant" : "vx-icon-warn",
                        allocatedSpaceFormatted: clusterData.allocatedSpaceFormatted,
                        usedSpaceFormatted: clusterData.usedSpaceFormatted,
                        hosts: clusterData.hosts
                     };
                  });

                  this.masterViewGridOptions.data = masterGridData;
                  this.dsClusterConectivityService.setHostFromCluster(connectivityData
                        .computedDsClusterConnectivity);
               });
      }
   }

   angular.module("com.vmware.vsphere.client.dsCluster")
         .controller("DsClusterConnectivityMasterController",
               DsClusterConnectivityMasterController);
}
