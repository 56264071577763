angular.module('com.vmware.vsphere.client.vm').service('vmProvisioningUserSettingsPageService', [
   'i18nService',
   'vuiConstants',
   'ipParserService',
   'wizardPageService',
   'vmVuiWizardPageBuilder',
   'VmProvisioningUserSettingsPageModel',
   function(
      i18nService,
      vuiConstants,
      ipParserService,
      wizardPageService,
      vmVuiWizardPageBuilder,
      VmProvisioningUserSettingsPageModel) {
      return {
         build: function(wizardScope) {
            var userSettingsPageModel = VmProvisioningUserSettingsPageModel(
               wizardScope.vmParams, 
               wizardScope.wizardViewData
            );
            wizardScope.userSettingsPageModel = userSettingsPageModel;

            return vmVuiWizardPageBuilder.buildVuiWizardPage(wizardScope.config, {
               title: i18nService.getString('VmUi', 'UserSettingsPage.Title'),
               description: i18nService.getString('VmUi', 'customizeGuestOs.parametersPage.description'),
               contentUrl: 'vm-ui/resources/vm/views/createVmWizard/vmProvisioningUserSettings.html',
               defaultState: vuiConstants.wizard.pageState.SKIPPED,
               model: userSettingsPageModel
            });
         }
      };
   }
]);