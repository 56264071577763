(function() {
   'use strict';
   angular.module('com.vmware.vsphere.client.host').service('editVmStartupShutdownService',
      EditVmStartupShutdownService);

   EditVmStartupShutdownService.$inject = ['vmStartupShutdownConstants',
      'i18nService', 'mutationService'];

   function EditVmStartupShutdownService(vmStartupShutdownConstants, i18nService,
                                         mutationService) {

      var shutdownActionOptions = [
         {
            name: i18nService.getString('HostUi', 'vmStartUp.actionGuestShutdown'),
            action: 'guestshutdown'
         },
         {
            name: i18nService.getString('HostUi', 'vmStartUp.actionPowerOff'),
            action: 'poweroff'
         },
         {
            name: i18nService.getString('HostUi', 'vmStartUp.actionPowerSuspend'),
            action: 'suspend'
         },
         {
            name: i18nService.getString('HostUi', 'vmStartUp.actionNone'),
            action: 'none'
         }
      ];

      function getShutdownActionOptions() {
         return shutdownActionOptions;
      }

      function createDialogOptions(hostAutoStartConfig, vmStartupData) {
         return {
            autoPowerVms: hostAutoStartConfig.defaults.enabled,
            startupDelay: hostAutoStartConfig.defaults.startDelay,
            shutdownDelay: hostAutoStartConfig.defaults.stopDelay,
            shutdownAction: getShutdownAction(hostAutoStartConfig.defaults),
            vmStartupData: vmStartupData,
            waitForHeartbeat: hostAutoStartConfig.defaults.waitForHeartbeat
         };
      }

      function getShutdownAction(autoStartDefaults) {
         return _.find(shutdownActionOptions, function (option) {
            return autoStartDefaults.stopAction.toLowerCase() === option.action;
         });
      }

      function createSystemDefaultsSettings(dialogData) {
         return {
            enabled: dialogData.autoPowerVms,
            startDelay: dialogData.startupDelay,
            stopDelay: dialogData.shutdownDelay,
            waitForHeartbeat: dialogData.waitForHeartbeat,
            stopAction: dialogData.shutdownAction.action,
            _type: 'com.vmware.vim.binding.vim.host.AutoStartManager$SystemDefaults'
         };
      }

      function updateHostVmStartupShutdownData(hostId, dialogData, powerInfos) {
         var newAutostartConfigSpec = {
            defaults: createSystemDefaultsSettings(dialogData),
            powerInfo: populatePowerInfo(filteredVmsOnHost(dialogData.vmStartupData), powerInfos)
         };
         var typedSpec = _.extend(newAutostartConfigSpec, {
            _type: 'com.vmware.vim.binding.vim.host.AutoStartManager$Config'
         });
         mutationService.apply(
            hostId,
            'com.vmware.vim.binding.vim.host.AutoStartManager$Config',
            typedSpec);
      }

      function populatePowerInfo(vmsOnHost, powerInfos) {
         return _.map(vmsOnHost, function (vm) {
            var powerInfoElement = _.find(powerInfos, function (powerInfo) {
               return vm.provider.value === powerInfo.key.value;
            });

            return {
               _type: "com.vmware.vim.binding.vim.host.AutoStartManager$AutoPowerInfo",
               key: vm.provider,
               startAction: powerInfoElement.startAction,
               startDelay: powerInfoElement.startDelay,
               startOrder: powerInfoElement.startOrder,
               stopAction: powerInfoElement.stopAction,
               stopDelay: powerInfoElement.stopDelay,
               waitForHeartbeat: powerInfoElement.waitForHeartbeat
            };
         });
      }

      function filteredVmsOnHost(vmStartupData) {
         return _.filter(vmStartupData, function (vm) {
            return vm.isNormalVMOrPrimaryFTVM === true;
         });
      }

      function moveUpAction(selectedObjectRef, vmsPowerInfos, changeOrderCallback) {
         var newStartOrderValue;
         var oldStartOrderValue;
         var indexOfOldStartOrderValue;
         var max = getHighestStartOrderVm(vmsPowerInfos);

         // Manual
         if (selectedObjectRef.startAction === vmStartupShutdownConstants.startAction.NONE) {
            selectedObjectRef.startAction = vmStartupShutdownConstants.startAction.POWER_ON_API_VALUE;
            selectedObjectRef.startOrder = -1;
         }
         // Automatic
         else if (selectedObjectRef.startAction.toLowerCase() === vmStartupShutdownConstants.startAction.POWER_ON_LOWERCASE &&
            selectedObjectRef.startOrder === -1) {
            selectedObjectRef.startOrder = ++max;
         }
         // Automatic ordered
         else {
            newStartOrderValue = selectedObjectRef.startOrder;
            oldStartOrderValue = --newStartOrderValue;

            indexOfOldStartOrderValue = findIndexOfVmStartOrderValue(vmsPowerInfos, oldStartOrderValue);

            changeOrderCallback(indexOfOldStartOrderValue, selectedObjectRef.startOrder);
            selectedObjectRef.startOrder = oldStartOrderValue;
         }

         return selectedObjectRef;
      }

      function moveDownAction(selectedObjectRef, vmsPowerInfos, changeOrderCallback) {
         var newStartOrderValue;
         var oldStartOrderValue;
         var indexOfOldStartOrderValue;
         var max = getHighestStartOrderVm(vmsPowerInfos);

         // Automatic
         if (selectedObjectRef.startAction.toLowerCase() === vmStartupShutdownConstants.startAction.POWER_ON_LOWERCASE &&
            selectedObjectRef.startOrder === -1) {
            selectedObjectRef.startAction = vmStartupShutdownConstants.startAction.NONE;
         }
         // Automatic ordered
         else if (selectedObjectRef.startAction.toLowerCase() === vmStartupShutdownConstants.startAction.POWER_ON_LOWERCASE &&
            selectedObjectRef.startOrder !== -1) {
            if (selectedObjectRef.startOrder === max) {
               selectedObjectRef.startOrder = -1;
            } else {
               newStartOrderValue = selectedObjectRef.startOrder;
               oldStartOrderValue = ++newStartOrderValue;

               indexOfOldStartOrderValue = findIndexOfVmStartOrderValue(vmsPowerInfos, oldStartOrderValue);
               changeOrderCallback(indexOfOldStartOrderValue, selectedObjectRef.startOrder);
               selectedObjectRef.startOrder = newStartOrderValue;
            }
         }
         return selectedObjectRef;
      }

      function findIndexOfVmStartOrderValue(vmsPowerInfos, currentVmOrder) {
         return _.findIndex(vmsPowerInfos, function (powerInfo) {
            return powerInfo.startOrder === currentVmOrder;
         });
      }

      function getHighestStartOrderVm(vmsPowerInfos) {
         var max = 0;
         _.each(vmsPowerInfos, function (vmPowerInfo) {
            if (vmPowerInfo.startAction.toLowerCase() === vmStartupShutdownConstants.startAction.POWER_ON_LOWERCASE &&
               vmPowerInfo.startOrder > max) {
               max = vmPowerInfo.startOrder;
            }
         });
         return max;
      }

      function findPowerInfoIndex(vmsPowerInfos, powerInfoKey) {
         return _.findIndex(vmsPowerInfos, function (powerInfo) {
            return powerInfo.key.value === powerInfoKey.value;
         });
      }

      function setDefaultPowerInfoForVms(vmOnHost, vmPowerInfos) {
         return _.map(vmOnHost, function (vm) {
            var vmPowerInfo = _.find(vmPowerInfos.powerInfo, function (powerInfo) {
               return vm.provider.value === powerInfo.key.value;
            });

            if (!vmPowerInfo) {
               vmPowerInfo = vmDefaultPowerInfo(vm);
            }
            return vmPowerInfo;
         });
      }

      function vmDefaultPowerInfo(vm) {
         return {
            _type: "com.vmware.vim.binding.vim.host.AutoStartManager$AutoPowerInfo",
            key: vm.provider,
            startAction: vmStartupShutdownConstants.startAction.NONE,
            startDelay: vmStartupShutdownConstants.SYSTEM_DEFAULT_DELAY,
            startOrder: -1,
            stopAction: vmStartupShutdownConstants.SYSTEM_DEFAULT,
            stopDelay: vmStartupShutdownConstants.SYSTEM_DEFAULT_DELAY,
            waitForHeartbeat: vmStartupShutdownConstants.heartbeat.SYSTEM_DEFAULT
         };
      }

      function validateStartStopDelay(startDelay, stopDelay, startupType, shutdownType) {
         var validationMessages = [];
         if (_.isNull(startDelay) ||
            _.isUndefined(startDelay)) {
            validationMessages.push(
               i18nService.getString('HostUi', 'vmStartUp.validationError.fieldsRequired'));
         } else if ( _.isNull(stopDelay) || _.isUndefined(stopDelay)) {
            validationMessages.push(
               i18nService.getString('HostUi', 'vmStartUp.validationError.fieldsRequired'));
         }
         var intStartDelay = Number.parseInt(startDelay);
         var intStopDelay = Number.parseInt(stopDelay);
         if (!_.isNull(startDelay) && !_.isUndefined(startDelay)  &&
            (!isInt(startDelay.toString()) || !isValidDelay (intStartDelay, startupType))) {
            validationMessages.push(
               i18nService.getString('HostUi', 'vmStartUp.validationError.fieldsMaxValue',
                  vmStartupShutdownConstants.MAX_ALLOWED_DELAY));
         } else if (!_.isNull(stopDelay) && !_.isUndefined(stopDelay)  &&
            (!isInt(stopDelay.toString()) || !isValidDelay(intStopDelay, shutdownType))) {
            validationMessages.push(
               i18nService.getString('HostUi', 'vmStartUp.validationError.fieldsMaxValue',
                  vmStartupShutdownConstants.MAX_ALLOWED_DELAY));
         }
         return validationMessages;
      }
      
      function isValidDelay(delay, delayType) {
         if ((delay < 0 && vmStartupShutdownConstants.SYSTEM_DEFAULT !== delayType ) ||
              delay > vmStartupShutdownConstants.MAX_ALLOWED_DELAY) {
            return false;
         } else {
            return true;
         }
      }

      function isInt(value) {
         var integerValue = Number.parseInt(value);
         if (integerValue <= Number.MIN_SAFE_INTEGER ||
            integerValue >= Number.MAX_SAFE_INTEGER) {
            return true;
         }
         return value !== 'NaN' && integerValue.toString() === value;
      }

      return {
         createDialogOptions: createDialogOptions,
         updateHostVmStartupShutdownData: updateHostVmStartupShutdownData,
         getShutdownActionOptions: getShutdownActionOptions,
         setDefaultPowerInfoForVms: setDefaultPowerInfoForVms,
         moveUpAction: moveUpAction,
         moveDownAction: moveDownAction,
         findPowerInfoIndex: findPowerInfoIndex,
         createSystemDefaultsSettings: createSystemDefaultsSettings,
         validateStartStopDelay: validateStartStopDelay
      };
   }
})();
