(function () {
    'use strict';
    angular.module('com.vmware.vsphere.client.network').factory('physicalAdaptersListColumns', physicalAdaptersListColumns);

    physicalAdaptersListColumns.$inject = ['i18nService', 'columnRenderersRegistry', 'networkUtil'];

    function physicalAdaptersListColumns(i18nService, columnRenderersRegistry, networkUtil) {

        return [
            {
                displayName: i18nService.getString('NetworkUi', 'PnicDataGridView.pnicDeviceColumnHeader'),
                field: 'device',
                width: '150px',
                sortable: function(vmnic1, vmnic2) {
                   return networkUtil.compareNumericValues(vmnic1, vmnic2, 'pnicIndex');
                },
                template: (function (data) {
                    return columnRenderersRegistry.getColumnRenderer('object-link')(
                        [undefined, 'device', 'network-lib-ui-icon-physicalAdapter'], data);
                })
            }, {
                displayName: i18nService.getString('NetworkUi', 'PnicDataGridView.pnicActualSpeedColumnHeader'),
                field: 'actualSpeedLabel',
                sortable: function(cti1, cti2) {
                    return networkUtil.compareNumericValues(cti1, cti2, 'actualSpeed');
                },
                template: function (data) {
                    return '<span class="right-align-cell">' + data.actualSpeedLabel + '</span>';
                },
                width: '150px'
            }, {
                displayName: i18nService.getString('NetworkUi', 'PnicDataGridView.pnicConfigSpeedColumnHeader'),
                field: 'configuredSpeedLabel',
                sortable: function(cti1, cti2) {
                    return networkUtil.compareNumericValues(cti1, cti2, 'configuredSpeed');
                },
                template: function (data) {
                    return '<span class="right-align-cell">' + data.configuredSpeedLabel + '</span>';
                },
                width: '150px'
            },  {
                displayName: i18nService.getString('NetworkUi', 'PnicDataGridView.pnicSwitchColumnHeader'),
                field: 'virtualSwitch.switchName',
                width: '150px',
                template: (function (data) {
                    var switchItem = data.virtualSwitch;

                    return columnRenderersRegistry.getColumnRenderer('icon-text')(
                         ['iconId', 'switchName'], switchItem);
                })
            }, {
                displayName: i18nService.getString('NetworkUi', 'PnicDataGridView.pnicMacColumnHeader'),
                field: 'mac',
                width: '150px'
            }, {
                displayName: i18nService.getString('NetworkUi', 'PnicDataGridView.pnicNetworksColumnHeader'),
                field: 'networkHint',
                width: '150px',
                template: (function (data) {
                    return columnRenderersRegistry.getColumnRenderer('text')(['networkHint'], data);
                })
            }, {
                displayName: i18nService.getString('NetworkUi', 'PnicDataGridView.pnicWakeOnLanColumnHeader'),
                field: 'wakeOnLanSupportedLabel',
                width: '150px'
            }, {
              displayName: i18nService.getString('NetworkUi', 'PnicDataGridView.SriovStatusColumnHeader'),
              field: 'sriovStatusLabel',
              width: '150px'
           }, {
              displayName: i18nService.getString('NetworkUi', 'PnicDataGridView.SriovNumVfsColumnHeader'),
              field: 'sriovNumVirtuanFunctionsLabel',
              width: '150px'
           }
        ];
    }
})();
