namespace h5_vm {
   import IPromise = angular.IPromise;
   import VmProvisioningPageModel = h5_vm.VmProvisioningPageModel;
   import TemplateType = h5_vm.TemplateType;

   interface PolicySpec {
      type: string;
      policy: string;
   }

   interface StorageSpec {
      datastore: string | null;
      storagePolicy: PolicySpec | null;
   }

   class CloneVmReviewPageModelFactory {
      public static $inject = [
         "mutationService",
         "defaultUriSchemeUtil",
         "wizardPageService",
         "i18nService",
         "pmemUtilService",
         "storageSelectorConstants"
      ];

      constructor(mutationService: any,
            defaultUriSchemeUtil: any,
            wizardPageService: any,
            i18nService: any,
            pmemUtilService: any,
            storageSelectorConstants: any) {
         return (wizardViewData: any, virtualMachineSpecBuilder: any, wizardScope: any) => {
            return new CloneVmReviewPageModel(
                  mutationService,
                  defaultUriSchemeUtil,
                  wizardPageService,
                  i18nService,
                  wizardViewData,
                  virtualMachineSpecBuilder,
                  wizardScope,
                  pmemUtilService,
                  storageSelectorConstants);
         };
      }
   }

   export class CloneVmReviewPageModel implements VmProvisioningPageModel<any> {

      static $inject: string[] = [
         "mutationService",
         "defaultUriSchemeUtil",
         "wizardPageService",
         "i18nService",
      ];

      private readonly ADD_VM_TO_LIB_SPEC: string = "com.vmware.vsphere.client.provisioning.spec.AddToLibrarySpec";
      private readonly CREATE_VMTX_SPEC: string = "com.vmware.vsphere.client.h5.vmtx.libraryItem.specs.CreateVmtxLibraryItemSpec";
      private readonly DEFAULT_STORAGE_ID: string = "defaultId";
      private readonly USE_SPECIFIED_STORAGE_POLICY: string = "USE_SPECIFIED_POLICY";

      // wizardViewData is already part of the passed wizardScope, so consider removing
      // it as a parameter!
      constructor(private mutationService: any,
            private defaultUriSchemeUtil: any,
            private wizardPageService: any,
            private i18nService: any,
            public wizardViewData: any,
            private virtualMachineSpecBuilder: any,
            public wizardScope: any,
            private pmemUtilService: any,
            private storageSelectorConstants: any) {
      }

      public resetForm(): void {
      };

      public validatePage(): any {
         return {};
      };

      public submitPage(validationResult: any): IPromise<any> {
         if(this.wizardScope.cloneVmBasicInformationPageModel.basicInformation.templateType === TemplateType.VM_TEMPLATE){
             return this.cloneVmtxTemplate();
         }else{
            return this.cloneOvfTemplate();
         }

      };

       private cloneOvfTemplate(): IPromise<any> {
           let vmId: string = this.wizardViewData.getSourceObjectId();
           let ovfSpec: any = this.getOvfSpec();
           this.wizardPageService.showLoadingIndicator(this.wizardScope.config);

           return this.mutationService.apply(vmId, this.ADD_VM_TO_LIB_SPEC, ovfSpec)
               .then(() => {
                   this.wizardPageService.hideLoadingIndicator(this.wizardScope.config);
                   return true;
               });
       }


       private getOvfSpec(): any {
           let basicInformation: any = this.wizardScope.cloneVmBasicInformationPageModel.basicInformation;

           let libraryId: string;
           let libraryItemId: string;
           if (basicInformation.cloneOvfType === "new"){
              libraryItemId = "";
              libraryId = this.wizardScope.cloneVmLocationPageModel.contentLibrary.id;
           }else{
               libraryItemId = this.wizardScope.cloneVmLibraryItemPageModel.contentLibraryItem.id;
               libraryId = this.wizardScope.cloneVmLibraryItemPageModel.contentLibraryItem.libraryId;
           }
           let exportFlags: Array<string> = this.wizardScope.datastorePageModel.form.isAdvancedStorageMode;
           let spec:any = {
               contentLibrary: libraryId,
               name: basicInformation.name,
               description: basicInformation.notes,
               libraryItem: libraryItemId,
               exportFlags: this.buildOvfExportFlagsList(basicInformation.ovfExportFlags)
           };
           return spec;
       }

       private buildOvfExportFlagsList(ovfExportFlags: any): string[] {
           const exportFlags: string[] = [];
           if (Array.isArray(ovfExportFlags)) {
               ovfExportFlags.forEach((ovfExportFlag) => {
                   if (ovfExportFlag.selected) {
                       exportFlags.push(ovfExportFlag.option);
                   }
               });
           }
           return exportFlags;
       }

      private cloneVmtxTemplate(): IPromise<any> {
         let vmId: string = this.wizardViewData.getSourceObjectId();
         let vmtxSpec: any = this.getVmtxSpec();
         this.wizardPageService.showLoadingIndicator(this.wizardScope.config);

         return this.mutationService.apply(vmId, this.CREATE_VMTX_SPEC, vmtxSpec)
               .then(() => {
                  this.wizardPageService.hideLoadingIndicator(this.wizardScope.config);
                  return true;
               });
      }

      private getVmtxSpec(): any {
         let basicInformation: any = this.wizardScope.cloneVmBasicInformationPageModel.basicInformation;
         let libraryId: string = this.wizardScope.cloneVmLocationPageModel.contentLibrary.id;
         let isAdvancedStorageMode: boolean = this.wizardScope.datastorePageModel.form.isAdvancedStorageMode;
         if(!this.wizardScope.cloneVmBasicInformationPageModel.isVMTXPhase2Enabled){

            return _.extend({
               contentLibrary: libraryId,
               name: basicInformation.name,
               description: basicInformation.notes
            }, isAdvancedStorageMode ? this.getAdvancedStorageSpec() : this.getSimpleStorageSpec());
         }
         let spec:any = _.extend({
            contentLibrary: libraryId,
            name: basicInformation.name,
            description: basicInformation.notes,
            folder: this.wizardScope.vmParams.getTargetInformation().folderUid
         }, isAdvancedStorageMode ? this.getAdvancedStorageSpec() : this.getSimpleStorageSpec());

         let computeResourceId: string =
             this.wizardScope.vmParams.getComputeResourceId();
         let type: string = this.defaultUriSchemeUtil.getPartsFromVsphereObjectId(computeResourceId).type;
         switch (type) {
            case "ClusterComputeResource":
               return _.extend(spec, {
                  cluster: computeResourceId
               });
            case "HostSystem":
               return _.extend(spec, {
               host: computeResourceId
            });
            case "ResourcePool":
               return _.extend(spec, {
                  resourcePool: computeResourceId
               });
            case "VirtualApp":
               return _.extend(spec, {
                  resourcePool: computeResourceId
               });
            default:
               throw new Error("Unknown compute resouce id: " + computeResourceId);
         }

      }

      public getComputeResourceInfo(): Object {
         let computeResourceId: string =
             this.wizardScope.vmParams.getComputeResourceId();
         let computeResourceName: string =
             this.wizardScope.vmParams.getComputeResourceName();
         let label: string = this.labelForType(computeResourceId);

         return {
            name: computeResourceName,
            label: label
         };
      }

      public labelForType(urn: string): string {
         let type: string = this.defaultUriSchemeUtil.getPartsFromVsphereObjectId(urn).type;

         switch (type) {
            case "ClusterComputeResource":
               return this.i18nService.getString("VmUi", "ProvisioningWizardSummaryPage.Cluster");
            case "HostSystem":
               return this.i18nService.getString("VmUi", "ProvisioningWizardSummaryPage.Host");
            case "ResourcePool":
               return this.i18nService.getString("VmUi", "ProvisioningWizardSummaryPage.RP");
            case "VirtualApp":
               return this.i18nService.getString("VmUi", "ProvisioningWizardSummaryPage.RP");
            default:
               throw new Error("Unknown compute resouce id: " + urn);
         }
      }

      private getAdvancedStorageSpec(): any {
         let storageConfig: any = this.wizardScope.datastorePageModel.form.storageSelectorState.vmStorageConfigInAdvancedMode[0];
         let vmHomeSpec: any = this.getStorageSpecFromVmStorageComponent(storageConfig.vmHome);

         let diskStorageOverrideSpec: any = {};
         for (let index = 0; index < storageConfig.vmDisks.length; index++) {
            let vmDisk: any = storageConfig.vmDisks[index];
            diskStorageOverrideSpec[vmDisk.key] = this.getStorageSpecFromVmStorageComponent(vmDisk);
         }

         return {
            vmHomeStorage: vmHomeSpec,
            diskStorageOverrides: diskStorageOverrideSpec
         };
      }

      private fixStorageSpec(spec: StorageSpec): void {
         if (!spec || !spec.storagePolicy) {
            return;
         }
         const policy: string | null = spec.storagePolicy.policy;
         if (!policy || policy === '') {
            spec.storagePolicy = null;
         }
         return;
      }

      private getSimpleStorageSpec(): any {
         const datastorePageModelForm: any = this.wizardScope.datastorePageModel.form;
         const datastoreRef: any = datastorePageModelForm.selectedStorage.storageRef;
         const datastoreId: string = this.defaultUriSchemeUtil.getVsphereObjectId(datastoreRef);
         const baseLineId = !datastorePageModelForm.storageSelectorState ? '' : datastorePageModelForm.storageSelectorState.storageBaselineId;

         const vmHomeStorageSpec: StorageSpec = this.buildHomeStorageSpec(datastorePageModelForm, datastoreId);

         // In case PMEM is available and HYBRID baseline is selected
         // keep the PMEM disks and apply the selection from the storage page
         // only to the non PMEM disks
         if (baseLineId === this.storageSelectorConstants.HYBRID_STORAGE_BASELINE.id) {
            const diskStorageOverrideSpec: any = this.buildVMDisksOverridesBasic();
            return {
               vmHomeStorage: vmHomeStorageSpec,
               diskStorageOverrides: diskStorageOverrideSpec
            };

         }

         // otherwise apply the selection to all the disks
         const selectedProfileForDisks: any =
               datastorePageModelForm.storageProfilesData.selectedProfileForDisks;
         const storageSpec = this.buildStorageSpec(
               this.getStorage(selectedProfileForDisks, datastoreId), selectedProfileForDisks);

         return {
            vmHomeStorage: vmHomeStorageSpec,
            diskStorage: storageSpec
         };
      }

      private buildHomeStorageSpec(datastorePageModelForm: any, datastoreId: string): StorageSpec {
         const selectedProfileForHome: any =
               datastorePageModelForm.storageProfilesData._currentVmProfileAssignment.vmHomeProfile;
         const vmHomeStorageSpec = this.buildStorageSpec(datastoreId, selectedProfileForHome);
         return vmHomeStorageSpec;
      }

      private buildStorageSpec(datastoreId: string | null, profile: any): StorageSpec {
         const storageSpec: StorageSpec = {
            datastore: datastoreId,
            storagePolicy: this.hasDefaultStoragePolicy(profile) ? null : {
               type: this.USE_SPECIFIED_STORAGE_POLICY,
               policy: profile ? profile.id: ''
            }
         };

         this.fixStorageSpec(storageSpec);

         return storageSpec;
      }

      private buildVMDisksOverridesBasic(): any {
         const vmId = this.virtualMachineSpecBuilder.getVmId();
         let vmDisks = this.virtualMachineSpecBuilder.getVmDisksStorage(vmId);
         vmDisks = vmDisks ? vmDisks[vmId] : [];

         let diskStorageOverrideSpec: any = {};
         for (let index = 0; index < vmDisks.length; index++) {
            const vmDisk: any = vmDisks[index];
            if(vmDisk.key === "_configFile") {
               continue;
            }
            diskStorageOverrideSpec[vmDisk.key] = this.getStorageSpecFromVmStorageComponentBasic(vmDisk);
         }

         return diskStorageOverrideSpec;
      }

      /**
       * If no profile is selected or the selected profiles equals the default
       * returns true
       * @param selectedProfile
       */
      private hasDefaultStoragePolicy(selectedProfile: any): boolean {
          return !selectedProfile || selectedProfile.id === this.DEFAULT_STORAGE_ID;
      }

      private getStorageSpecFromVmStorageComponent(storageComponent: any): StorageSpec {
         let datastoreId: string | null = null;
         if (storageComponent.storageObj) {
            const datastoreRef: any = storageComponent.storageObj.storageRef;
            datastoreId = this.defaultUriSchemeUtil.getVsphereObjectId(datastoreRef);
         }

         const retVal: StorageSpec = this.buildStorageSpec(
               this.getStorage(storageComponent.storageProfile, datastoreId),
               storageComponent.storageProfile);
         return retVal;
      }

      private getStorageSpecFromVmStorageComponentBasic(storageComponent: any): StorageSpec {
         const datastoreId: string = storageComponent.storage;
         const retVal: StorageSpec = this.buildStorageSpec(
               this.getStorage(storageComponent.profile, datastoreId),
               storageComponent.profile);
         return retVal;
      }

      private getStorage(profile: any, datastoreId: string | null): string | null {
         return this.pmemUtilService.isPMemStoragePolicy(profile) ? null : datastoreId;
      }
   }

   angular.module("com.vmware.vsphere.client.vm")
         .service("cloneVmReviewPageModel",
               CloneVmReviewPageModelFactory);
}
