module cluster_ui {

   import ValidationResult = com.vmware.vise.core.model.ValidationResult;

   class MoveResourcePoolToDialogController {

      public rootObjId: string;
      public entityToMoveRpTo: string;
      public isMoveActionUnderCluster: boolean;
      private rpIds: Array<string>;
      private onLocationSelected: Function;
      private sourcesIds: Array<string>;
      private loading: boolean;

      public static $inject = ["$scope",
         "defaultUriSchemeUtil",
         "dataService",
         "vuiConstants",
         "i18nService",
         "moveResourcePoolToService",
         "managedEntityConstants"];

      constructor(private $scope: any,
                  private defaultUriSchemeUtil: any,
                  private dataService: any,
                  private vuiConstants: any,
                  private i18nService: any,
                  private moveResourcePoolToService: any,
                  private managedEntityConstants: any) {
         this.loading = true;
         this.rpIds = $scope.modalOptions.dialogData.objectIds;
         if (!_.isEmpty(this.rpIds)) {
            this.rootObjId = this.rpIds[0];
         }

         $scope.modalOptions.onSubmit = () => {
            $scope.modalOptions.alerts = [];
            $scope.modalOptions.isAlertClosed = true;
            // if destination is contained in sources, error
            if (_.contains(this.rpIds, this.entityToMoveRpTo)) {
               this.$scope.modalOptions.alerts = [{
                  type: this.vuiConstants.validationBanner.type.ERROR,
                  text: this.i18nService
                        .getString('Common', 'moveTo.targetValidationError')
               }];
               this.$scope.modalOptions.isAlertClosed = false;
            } else {
               // validate destination in hierarchy
               this.moveResourcePoolToService
                     .validateMoveResourcePoolToTarget(this.entityToMoveRpTo, this.rpIds)
                     .then((result: ValidationResult) => {
                        if (!!result.result) {
                           // check proveleges
                           this.moveResourcePoolToService.checkPrivileges(
                                 this.sourcesIds,
                                 this.entityToMoveRpTo
                           ).then((noPrivilegesIds: Array<string>) => {
                              if (!noPrivilegesIds || noPrivilegesIds.length === 0) {
                                 // if objects have privileges
                                 this.moveResourcePoolToService.moveResourcePool(
                                       this.entityToMoveRpTo,
                                       this.rpIds,
                                       this.$scope
                                 );
                              } else {
                                 // no privileges error
                                 this.$scope.modalOptions.alerts = [{
                                    type: this.vuiConstants.validationBanner.type.ERROR,
                                    text: this.moveResourcePoolToService
                                          .getPrivilegesErrorMessage(
                                                noPrivilegesIds,
                                                this.entityToMoveRpTo
                                          )
                                 }];
                                 this.$scope.modalOptions.isAlertClosed = false;
                              }
                           });
                        } else {
                           // if destination is a child of sources, error
                           this.$scope.modalOptions.alerts = [{
                              type: this.vuiConstants.validationBanner.type.ERROR,
                              text: this.i18nService
                                    .getString('Common', 'moveTo.targetNestedValidationError')
                           }];
                           this.$scope.modalOptions.isAlertClosed = false;
                        }
                     });
            }
         };

         this.onLocationSelected = (objectReference: any) => {
            this.entityToMoveRpTo = objectReference;
         };

         this.getData();
      }

      private getData(): void {
         this.dataService.getPropertiesForObjects(this.rpIds, ['parent', 'cluster'])
               .then((data: any) => {
                  if (data) {
                     this.isMoveActionUnderCluster = !!data[this.rpIds[0]].cluster;
                     this.sourcesIds = _.map(this.rpIds,
                           (rpId: string) => {
                              return this.defaultUriSchemeUtil.getVsphereObjectId(
                                    data[rpId].parent
                              );
                           });
                     this.sourcesIds.push(...this.rpIds);

                  }
                  this.loading = false;
               });
      }
   }

   angular.module("com.vmware.vsphere.client.cluster")
         .controller("moveResourcePoolToDialogController", MoveResourcePoolToDialogController);
}

