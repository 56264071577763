import PnicBindingDataItem = com.vmware.vsphere.client.h5.network.host.wizard.addnetworking.model.PnicBindingDataItem;
namespace network_ui {

   import IPromise = angular.IPromise;
   import PnicBindingDataItem = com.vmware.vsphere.client.h5.network.host.wizard.addnetworking.model.PnicBindingDataItem;

   export class BindToPhysicalAdapterService {

      public static $inject = [
         "dataService"];

      private availablePnicsPropertyByRef: string = "vnic:availablePnicsForBinding";
      private availablePnicsPropertyBySpec: string = "vnic:availablePnicsForBindingBySummary";

      constructor(private dataService: any) {}

      public getDataByOpaqueNetworkRef(
            hostId: string,
            opaqueNetworkRef: any): IPromise<PnicBindingDataItem[]> {
         let propertyParams: any[] = [];

         propertyParams.push({
            propertyName: this.availablePnicsPropertyByRef,
            parameterType: "com.vmware.vim.binding.vmodl.ManagedObjectReference",
            parameter: opaqueNetworkRef
         });

         return this.getData(hostId, this.availablePnicsPropertyByRef, propertyParams);
      };

      public getDataByOpaqueNetworkSpec(
            hostId: string,
            opaqueNetworkId: string,
            opaqueNetworkType: string): IPromise<PnicBindingDataItem[]>{

         let propertyParams: any[] = [];

         propertyParams.push({
            propertyName: this.availablePnicsPropertyBySpec,
            parameterType: "java.util.Map",
            parameter: {
               opaqueNetworkId: opaqueNetworkId,
               opaqueNetworkType : opaqueNetworkType
            }
         });

         return this.getData(hostId, this.availablePnicsPropertyBySpec, propertyParams);
      };

      private getData(
            hostId: string,
            availablePnicsProperty: string,
            propertyParams: any[]) : IPromise<PnicBindingDataItem[]>{
         return this.dataService.getProperties(
               hostId,
               [availablePnicsProperty],
               {propertyParams: propertyParams})
               .then((result: any) => {
                  return result[availablePnicsProperty];
               });
      };
   }

   angular.module("com.vmware.vsphere.client.network")
         .service("bindToPhysicalAdapterService", BindToPhysicalAdapterService);

}




