namespace storage_ui {

   export interface ModalDialogScope extends ng.IScope {
      modalOptions: any;
   }

   export interface ModalOptions {
      alerts: any[];
      dialogData: any;
   }

   export interface HostProtocolItem {
      name: string;
      storageArray: string;
      identifier: string;
      location: string;
      lun: string;
      lunNumber: number;
      type: string;
      peType: string;
      operationalState: string;
      transport: string;
      owner: string;
      server: string;
      folder: string;
      isScsiPE: boolean;
      uuid: string;
   }

   export interface PoliciesDataItem{
      multipathingInfo: any;
      hostPolicyOptions?: any;
   }


    //These are used instead of @Dto's to add |null for sone fields
   export class InternetScsiHba$IPProperties {
      _type = "com.vmware.vim.binding.vim.host.InternetScsiHba$IPProperties";
      mac: string;
      address: string|null;
      dhcpConfigurationEnabled: boolean;
      subnetMask: string|null;
      defaultGateway: string|null;
      primaryDnsServerAddress: string;
      alternateDnsServerAddress: string;
      ipv6Address: string;
      ipv6SubnetMask: string;
      ipv6DefaultGateway: string;
      arpRedirectEnabled: boolean;
      mtu: number;
      jumboFramesEnabled: boolean;
      ipv4Enabled: boolean;
      ipv6Enabled: boolean;
      ipv6properties: InternetScsiHba$IPv6Properties;
   }

   export class InternetScsiHba$IscsiIpv6Address {
      _type = "com.vmware.vim.binding.vim.host.InternetScsiHba$IscsiIpv6Address";
      address: string|null;
      prefixLength: number;
      origin: string;
      operation: string;
   }

   export class InternetScsiHba$IPv6Properties {
      _type = "com.vmware.vim.binding.vim.host.InternetScsiHba$IPv6Properties";
      iscsiIpv6Address: InternetScsiHba$IscsiIpv6Address[];
      ipv6DhcpConfigurationEnabled: boolean|null;
      ipv6LinkLocalAutoConfigurationEnabled: boolean|null;
      ipv6RouterAdvertisementConfigurationEnabled: boolean|null;
      ipv6DefaultGateway: string|null;
   }
   export class InternetScsiStorageAdapterIpConfigurationUpdateSpec {
      _type = "com.vmware.vsphere.client.storage.adapters.InternetScsiStorageAdapterIpConfigurationUpdateSpec";
      device: string;
      properties: InternetScsiHba$IPProperties;
   }
}
