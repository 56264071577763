namespace ds_cluster_ui {

   export class SdrsRecommendationsListComponent {
      public bindings: any;
      public controller: any;
      public templateUrl: string;

      constructor() {
         this.bindings = {
            computedSdrsRecommendations: "<",
            alwaysOverrideRecommendations: "<",
            noLinks: "<",
            skipApplyButton: "<",
            recommendationStore: "=",
            gridTitle: "<"
         };

         this.controller = SdrsRecommendationsListController;
         this.templateUrl = "ds-cluster-ui/resources/ds-cluster/components/sdrsRecommendationsList/sdrsRecommendationsList.html";
      }
   }

   class SdrsRecommendationsListController {

      public static $inject = ["i18nService", "vuiConstants", "drsRecommendationsService",
         "$rootScope", "columnRenderersRegistry", "clarityModalService",
         "defaultUriSchemeUtil", "mutationService", "numberFormatterService"];

      private computedSdrsRecommendations: any;
      private gridOptions: any;
      private recommendationStore: any;
      private applyRecommendationsActionLabel: string;
      private overrideSdrsRecommendationsLabel: string;
      private objectId: string;
      private alwaysOverrideRecommendations: boolean;
      private overrideRecommendations: boolean = false;
      private isRecommendationChecked: (key: string) => boolean;
      private noLinks: boolean;
      private skipApplyButton: boolean;

      constructor(private i18nService: any, private vuiConstants: any,
                  private drsRecommendationsService: any, private $rootScope: any,
                  private columnRenderersRegistry: any, private clarityModalService: any,
                  private defaultUriSchemeUtil: any, private mutationService: any,
                  private numberFormatterService: any) {
         this.gridOptions = this.getRecommenadtionsGridOptions();
      }

      public $onInit() {
         this.isRecommendationChecked =
               (key) => this.recommendationStore.isRecommendationChecked(key);
         this.applyRecommendationsActionLabel =
               this.getString("applyRecomendationsActionLabel");
         this.overrideSdrsRecommendationsLabel =
               this.getString("sdrs.recommendations.overrideSdrsRecommendationsLabel");
      }

      public $onChanges(changesObj: any) {
         if (changesObj && changesObj.computedSdrsRecommendations.currentValue) {
            this.objectId = changesObj.computedSdrsRecommendations.currentValue.id;
            let currentRecommendations: Array<any> =
                  changesObj.computedSdrsRecommendations.currentValue.computedSdrsRecommendations;
            if (this.gridOptions && currentRecommendations) {
               this.gridOptions.data = this.formatGridColumnValues(currentRecommendations);
            }

            let recommendations: RecommendationData[] =
                  _.map(currentRecommendations,
                        (recommendation: SdrsRecommendationListItemData) => {
                           return recommendation.recommendationData;
                        });

            let newRecommendationStore = this.drsRecommendationsService
                  .createRecommendationsStore(recommendations);

            let newRecommendationsKeys = newRecommendationStore
                  .getRecommendationKeys();
            if (this.recommendationStore) {
               let oldRecommendationKeys = this.recommendationStore
                     .getRecommendationKeys();
               let haveRecommendationsChanged = !this.arrayEq(
                     newRecommendationsKeys.sort(), oldRecommendationKeys.sort());
               if (haveRecommendationsChanged) {
                  this.recommendationStore = newRecommendationStore;
               }
            } else {
               this.recommendationStore = newRecommendationStore;
            }

            if (this.alwaysOverrideRecommendations) {
               this.overrideRecommendations = currentRecommendations &&
                     currentRecommendations.length > 0;
               this.checkRecommendations();
            }
         }
      }

      //Remove when vui datagrid is updated to support sub columns
      private formatGridColumnValues(sdrsRecommendationsData: any): any {
         let beforeLabel: string = this.getString(
               "sdrs.recommendations.columns.before.label") + " ";
         let afterLabel: string = this.getString(
               "sdrs.recommendations.columns.after.label") + " ";
         let sourceLabel: string = this.getString(
               "sdrs.recommendations.columns.source.label") + " ";
         let destinationLabel: string = this.getString(
                     "sdrs.recommendations.columns.destination.label") + " ";
         _.each(sdrsRecommendationsData,
               (recommendation: any) => {
                  recommendation.utilizationSource =
                        beforeLabel + this.roundToOneDecimal(
                              recommendation.spaceUtilSrcBefore) + "\n" +
                        afterLabel + this.roundToOneDecimal(
                              recommendation.spaceUtilSrcAfter);
                  recommendation.utilizationDestination =
                        beforeLabel + this.roundToOneDecimal(
                              recommendation.spaceUtilDstBefore) + "\n" +
                        afterLabel + this.roundToOneDecimal(
                              recommendation.spaceUtilDstAfter);
                  recommendation.ioLatencyBefore =
                        sourceLabel + this.roundToOneDecimal(
                              recommendation.ioLatencySrcBefore) + "\n" +
                        destinationLabel + this.roundToOneDecimal(
                              recommendation.ioLatencyDstBefore);
               });

         return sdrsRecommendationsData;
      }

      private arrayEq(array1: any[], array2: any[]): boolean {
         if (array1.length !== array2.length) {
            return false;
         }
         return array1.every(function (array1El, i) {
            return array1El === array2[i];
         });
      }

      private roundToOneDecimal(num: string) {
         return this.numberFormatterService.format(+(Math.round(parseFloat(num + "e+1")) + "e-1"));
      }

      private getRecommenadtionsGridOptions(): any {
         return {
            searchable: false,
            resizable: true,
            selectable: false,
            scrollable: true,
            data: [],
            columnDefs: this.getColumnDefs(),
            pageConfig: {
               hidePager: true
            },
            height: "100%"
         };
      }

      private getColumnDefs(): any {
         return [{
            displayName: this.getString("sdrs.recommendations.columns.apply"),
            field: "recommendationText",
            type: "string",
            width: "10%",
            template: this.checkboxColumnTemplate
         }, {
            displayName: this.getString("sdrs.recommendations.columns.recommendation"),
            field: "recommendationText",
            type: "string",
            width: "21%",
            template: this.recommendationTextRenderer.bind(this)
         }, {
            displayName: this.getString("sdrs.recommendations.columns.reason"),
            field: "recommendationData.parentRecommendation.reasonText",
            type: "string",
            width: "21%"
         }, {
            displayName: this.getString("sdrs.recommendations.columns.utilization.source"),
            field: "utilizationSource",
            type: "string",
            template: (data: any) => {
               return `<span class='column-value-pre-wrap'>${data.utilizationSource}</span>`;
            }
         }, {
            displayName: this.getString("sdrs.recommendations.columns.utilization.destination"),
            field: "utilizationDestination",
            type: "string",
            template: (data: any) => {
               return `<span class='column-value-pre-wrap'>${data.utilizationDestination}</span>`;
            }
         }, {
            displayName: this.getString("sdrs.recommendations.columns.latency"),
            field: "ioLatencyBefore",
            type: "string",
            template: (data: any) => {
               return `<span class='column-value-pre-wrap'>${data.ioLatencyBefore}</span>`;
            }
         }];
      }

      private applyRecommendations() {
         if (!this.recommendationStore) {
            return;
         }
         let keysToApply: string[] = this.recommendationStore.getRecommendationsToApply();
         let dsclusterRecommendationSpec = {
            operation: "apply",
            keys: keysToApply
         };

         this.mutationService.apply(this.objectId,
               "com.vmware.vsphere.client.dscluster.services.sdrs." +
               "DatastoreClusterRecommendationSpec",
               dsclusterRecommendationSpec);
      }

      private checkboxColumnTemplate(data: any) {
         if (!data.recommendationData.isFirst) {
            return "";
         }
         let key: string = data.recommendationData.parentRecommendation.key;
         let checkbox =
               `<div class="centered-content-container">
               <input type="checkbox"
                  ng-disabled="!$parent.$parent.$ctrl.overrideRecommendations"
                  ng-checked="$parent.$parent.$ctrl.isRecommendationChecked('${key}')
                  || !$parent.$parent.$ctrl.overrideRecommendations"
                  ng-click="$parent.$parent.$ctrl.onRecommendationCheckboxClick('${key}')"/>
            </div>`;
         return checkbox;
      }

      private checkRecommendations() {
         let recommendationKeys = this.recommendationStore.getRecommendationKeys();
         _.each(recommendationKeys, (recommendationKey) => {
            if (!this.recommendationStore.isRecommendationChecked(recommendationKey)) {
               this.recommendationStore.checkRecommendation(recommendationKey);
            }
         });
      }

      onOverrideRecommendationsClick() {
         this.checkRecommendations();
      }

      onRecommendationCheckboxClick(recommendationKey: string) {
         let isChecked = this.recommendationStore.isRecommendationChecked(recommendationKey);
         if (isChecked) {
            if (this.recommendationStore.uncheckRecommendation(recommendationKey)) {
               let popUpScope: any = this.$rootScope.$new();
               popUpScope.modalOptions = this.getUncheckedDependantRecommendationsModalOptions();
               this.clarityModalService.openPopUp(popUpScope);
            }
         } else {
            if (this.recommendationStore!.checkRecommendation(recommendationKey)) {
               let popUpScope: any = this.$rootScope.$new();
               popUpScope.modalOptions = this.getCheckedPrerequisiteRecommendationsModalOptions();
               this.clarityModalService.openPopUp(popUpScope);
            }
         }
      }

      private recommendationTextRenderer(objData: any): string {
         let renderer = this.columnRenderersRegistry.getColumnRenderer("object-name");

         //build links for each target object in the recommendation action text
         let targetLinks: any [] = objData.targets.map((object: any) => {
            object.uid = this.defaultUriSchemeUtil.getVsphereObjectId(object.provider);

            let targetText: string;

            if (this.noLinks) {
               targetText = `"${object.name}"`;
            } else {
               targetText = renderer(["uid", null, "name", "labelIds"], object);
            }

            return targetText;
         });

         let recommendationText = this.i18nService.interpolate(
               objData.recommendationText, targetLinks);

         if (!!objData.image) {
            recommendationText = `<span class="${objData.image}"></span>${recommendationText}`;
         }

         return recommendationText;
      }

      private getString(key: string, ...params: string[]) {
         return this.i18nService.getString("DsClusterUi", key, params);
      }

      private getCheckedPrerequisiteRecommendationsModalOptions() {
         return {
            title: this.i18nService.getString("ClusterUi",
                  "prerequisite.recommendations.selected.title"),
            contentUrl: "cluster-ui/resources/cluster/views/monitor/drs/checkedPrerequisiteRecommendationsModal.html"
         };
      }

      private getUncheckedDependantRecommendationsModalOptions() {
         return {
            title: this.i18nService.getString("ClusterUi",
                  "dependent.recommendations.unselected.title"),
            contentUrl: "cluster-ui/resources/cluster/views/monitor/drs/uncheckedDependantRecommendationsModal.html"
         };
      }
   }

   angular.module("com.vmware.vsphere.client.dsCluster")
         .component("sdrsRecommendationsList",
               new SdrsRecommendationsListComponent());
}
