namespace h5_vm {
   import GosType = h5_vm.GosType;
   import VmCustomizationSpec = com.vmware.vsphere.client.vm.customizations.VmCustomizationSpec;
   import OperationResult = com.vmware.vise.core.model.OperationResult;

   export class CreateGuestOsCustomizationSpecWizardService {

      public static $inject = ["gosSpecCreatePagesService",
         "gosSpecModelManagerService",
         "wizardPageService",
         "$rootScope",
         "vuiService",
         "i18nService",
         "mutationService",
         "$q"];

      private wizardScope: any;
      private readonly defaultFlowId: GosType = GosType.WINDOWS;
      private selectedFlowId: GosType;
      private onActionInvoked: Function;

      constructor(private gosSpecCreatePagesService: GosSpecCreatePagesService,
            private gosSpecModelManagerService: GosSpecModelManagerService,
            private wizardPageService: any,
            private $rootScope: any,
            private vuiService: any,
            private i18nService: any,
            private mutationService: any,
            private $q: any) {
      }

      open(onActionInvoked: Function, allSpecs: CustomizationSpecInfoCustom[]): void {
         this.onActionInvoked = onActionInvoked;
         this.wizardScope = this.$rootScope.$new();
         this.wizardScope.allSpecs = allSpecs;

         let result = this.buildWizardConfig();
         this.wizardScope.config = {
            title: this.i18nService.getString("VmUi", "customizationSpecWizard.title"),
            cssClass: "provisioning-vm-wizards"
         };
         this.wizardScope.config.pages = result.pages;
         this.wizardScope.config.flows = result.flows;
         this.wizardScope.config.onFinish = result.onFinish;

         this.selectedFlowId = this.defaultFlowId;

         this.gosSpecModelManagerService.setModelDefaults(this.wizardScope,
               this.defaultFlowId, this.onWizardFlowChange.bind(this));

         this.vuiService.createWizard({
            scope: this.wizardScope,
            configObjectName: "config"
         }).show();
      }

      private buildWizardConfig(): any {
         let flowIds: Array<GosType> = [
            GosType.WINDOWS,
            GosType.WINDOWS_CUSTOM_SYSPREP,
            GosType.LINUX
         ];

         let nameAndOsPage: any = this.gosSpecCreatePagesService.createNameAndOsPage(
               this.wizardScope,
               this.onPageCommit.bind(this),
               this.defaultFlowId,
               flowIds);

         let flows: any = this.gosSpecCreatePagesService.getWizardFlows(
               this.wizardScope, this.onPageCommit.bind(this));

         return {
            flows: this.wizardPageService.initializeFlowStates(flows),
            pages: [nameAndOsPage],
            onFinish: this.onWizardComplete.bind(this)
         };
      }

      private onWizardFlowChange(selecetedCustomizationType: GosType): void {
         this.selectedFlowId = selecetedCustomizationType;
         this.wizardScope.config.currentPage.decisionOptions.selectedFlowId =
               this.selectedFlowId;
         this.wizardPageService.initializeFlowStates(this.wizardScope.config.flows);
      }

      private onPageCommit(pageModel: GosBasePageModel): angular.IPromise<boolean> {
         let pageCommitPromise = pageModel.onPageCommit
               ? pageModel.onPageCommit()
               : this.$q.resolve([]);
         return pageCommitPromise.then((res: Array<string>) => {
            if (!res) {
               return false;
            }
            this.wizardPageService.clearErrors(this.wizardScope.config);

            if (res && res.length > 0) {
               this.wizardPageService.markPageIncompleteWithErrors(
                     this.wizardScope.config, res);
               return false;
            }

            this.gosSpecModelManagerService.updateDependentModels(this.wizardScope, pageModel);
            this.wizardPageService.markPageComplete(this.wizardScope.config,
                  this.selectedFlowId);
            return true;
         });
      }

      private onWizardComplete(): boolean {
         let finishPageModel: GosFinishPageModel =
               this.wizardScope.finishPageModel as GosFinishPageModel;

         let vmCustSpec: VmCustomizationSpec = new VmCustomizationSpec();
         vmCustSpec.config = finishPageModel.buildSpec();
         finishPageModel.addWindowsParams(vmCustSpec);
         vmCustSpec.customizationSpecManager = finishPageModel.customizationSpecManager;
         let operation: string = this.i18nService.getString(
               "VmUi", "customizationSpec.createSpecOperationName");
         this.mutationService.add(vmCustSpec._type, vmCustSpec, operation)
               .then((opResult: OperationResult) => {
                  this.onActionInvoked(
                        "vsphere.core.vm.gos.customization.new",
                        opResult);
               });
         return true;
      }

   }

   angular.module("com.vmware.vsphere.client.vm")
         .service("createGuestOsCustomizationSpecWizardService",
               CreateGuestOsCustomizationSpecWizardService);
}
