(function() {
   'use strict';
   angular.module('com.vmware.vsphere.client.host').controller('AddHostLockdownModePageController', [
      '$scope',
      function($scope) {
         var self = this;

         this.model = $scope.manager.getLockdownModePageModel();

         $scope.$watch(function(){ return self.model.lockdownMode; }, function(lockdownMode) {
            $scope.manager.setLockdownMode(lockdownMode);
         });
      }
   ]);
})();