(function() {
   'use strict';

   angular.module('com.vmware.vsphere.client.vm')
         .controller('VmProvisioningFinishConvertToVmController', VmProvisioningFinishConvertToVmController);

   VmProvisioningFinishConvertToVmController.$inject = ['$scope'];

   function VmProvisioningFinishConvertToVmController($scope) {
      var model = $scope.vmProvisioningFinishConvertToVmPageModel;
      var computeResourceInfo = model.getComputeResourceInfo();
      
      this.provisioningTypeName = model.getProvisioningType();
      this.templateName = model.getTemplateName();      
      this.targetResourceTypeLabel = computeResourceInfo.label;
      this.targetResourceName = computeResourceInfo.name;
   }
})();
