/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace dvs_ui {

   import ManagedObjectReference = com.vmware.vim.binding.vmodl.ManagedObjectReference;
   import DvsAddHostHierarchicalListVmVnicItem = com.vmware.vsphere.client.h5.network.dvs.addhost.hierarchicalList.DvsAddHostHierarchicalListVmVnicItem;
   import TreeListModel = kendo.data.TreeListModel;
   import DvsAddHostHierarchicalListItem = com.vmware.vsphere.client.h5.network.dvs.addhost.hierarchicalList.DvsAddHostHierarchicalListItem;
   import DvsAddHostHierarchicalListVmItem = com.vmware.vsphere.client.h5.network.dvs.addhost.hierarchicalList.DvsAddHostHierarchicalListVmItem;
   class DvsSelectVirtualMachinesPageController {

      public static $inject = [
         "$q",
         "$scope",
         "i18nService",
         "dataService",
         "defaultUriSchemeUtil",
         "networkSelectorDialogService",
         "dvsSelectVirtualMachinesPageService",
         "vmVnicSettingsDialogService",
         "$timeout"];

      public treeListDataSource: any;

      public treeListColumns: any[];
      public treeListItemExpanded: boolean;
      public actionBarOptions: any;

      // Only needed for getting access to the selected items.
      public treeListWidget: any;

      /**
       * Whether to show a warning banner on top of the page.
       */
      public showWarning: boolean;

      /**
       * The currently selected item (if any) in the grid. Undefined if there is no
       * selection.
       */
      private _selectedItem: any;

      private static readonly VIRTUAL_MACHINES_PROPERTY: string =
            "dvsAddHost:hostVmData";

      constructor(private $q: any,
                  private $wizardScope: DvsAddHostWizardScope,
                  private i18nService: any,
                  private dataService: any,
                  private defaultUriSchemeUtil: any,
                  private networkSelectorDialogService: any,
                  private dvsSelectVirtualMachinesPageService: DvsSelectVirtualMachinesPageService,
                  private vmVnicSettingsDialogService: VmVnicSettingsDialogService,
                  private $timeout: any) {

         this.treeListWidget = null;
         this.treeListItemExpanded = false;

         this.actionBarOptions =
               this.dvsSelectVirtualMachinesPageService.getActionBarOptions(
                     this.onAssignPortgroupClicked.bind(this),
                     this.onResetChangesClicked.bind(this),
                     this.onViewSettingsClicked.bind(this)
               );

         if (!this.$wizardScope.model.selectVirtualMachinesPageModel.isMigrateVirtualMachineNetworkingEnabled) {
            this.initializeVirtualMachinesTreeList([]);
         } else {
            this.loadVmData();
         }

         this.$wizardScope.$on("$destroy", () => {
            kendo.destroy($(".dvs-add-host-adapters-tree-list"));
         });
      }

      private loadVmData(): void {
         if (!this.$wizardScope.model.selectVirtualMachinesPageModel.isInitialized) {
            // Initialize virtual machines tree list with empty data provider
            // so that it is rendered while loading the data.
            this.initializeVirtualMachinesTreeList([]);
            this.requestPageData();
         } else {
            // Do not auto-expand hosts in order to preserve the expand state
            // that the user had previously specified.
            this.treeListItemExpanded = true;
            this.initializeVirtualMachinesTreeList(
                  this.$wizardScope.model.selectVirtualMachinesPageModel.hosts);
         }
      }

      private hideOrShowListData(): void {
         if (this.$wizardScope.model.selectVirtualMachinesPageModel.isMigrateVirtualMachineNetworkingEnabled) {
            this.loadVmData();
         } else {
            this.initializeVirtualMachinesTreeList([]);
            this.disableAllActions();
         }
      }

      private requestPageData(): void {
         this.$wizardScope.wizardConfig.loading = true;

         let virtualMachinesPromise: any = this.requestVirtualMachines();

         virtualMachinesPromise.then((response: any, error: any) => {
            this.createVmVnicTreeModel(response);
            this.$wizardScope.wizardConfig.loading = false;
         });
      }

      private onNetworkSelectorClosed(selectedNetworks: any[]): void {
         if (selectedNetworks && selectedNetworks.length > 0) {
            let selectedPortgroup: any = selectedNetworks[0];

            let selectedPortgroupName: string = selectedPortgroup.name;
            let selectedPortgroupRef: ManagedObjectReference =
                  selectedPortgroup.networkRef;

            if (this._selectedItem) {
               if (this._selectedItem.type ===
                     DvsAddHostWizardConstants.nicListItemType.VM_VNIC_TYPE) {
                  this.assignVnic(
                        this._selectedItem, selectedPortgroupName, selectedPortgroupRef);
               } else if (this._selectedItem.type ===
                     DvsAddHostWizardConstants.nicListItemType.VM_TYPE) {
                  let childVnicItems: any[] =
                        this.treeListDataSource.childNodes(this._selectedItem);
                  _.each(childVnicItems, (vnicItem: any) => {
                     this.assignVnic(
                           vnicItem, selectedPortgroupName, selectedPortgroupRef);
                  });
               }
            }

            // Update toolbar since the assigned item's selection is preserved but
            // no selection event is triggered.
            this.updateToolbar();
            this.updateReassignedLabels();

            this.$wizardScope.model.selectVirtualMachinesPageModel.hosts =
                  this.treeListDataSource.data() as DvsAddHostTreeListItemWrapper[];
         }
      }

      private assignVnic(vnicItemWrapper: DvsAddHostTreeListItemWrapper,
                         portgroupName: string,
                         portgroupRef: ManagedObjectReference): void {

         let listItem: DvsAddHostHierarchicalListItem =
               vnicItemWrapper as DvsAddHostHierarchicalListItem;
         let updatedVnicItem: DvsAddHostHierarchicalListVmVnicItem =
               listItem as DvsAddHostHierarchicalListVmVnicItem;

         updatedVnicItem.destinationPortgroup = portgroupName;
         updatedVnicItem.destinationPortgroupRef = portgroupRef;

         this.treeListDataSource.pushUpdate(updatedVnicItem);
      }

      private onAssignPortgroupClicked(): void {
         // Open network selector.
         this.networkSelectorDialogService.openNetworkSelectorDialog(
               this.$wizardScope.model.dvsId,
               this.networkSelectorDialogService.createNetworkSelectorFilterSpec(
                     false, false, true, false, false
               ),
               // TODO vchomakov: add custom message
               this.networkSelectorDialogService.createNetworkSelectorDialogSpec(
                     ['Network.Assign']),
               this.onNetworkSelectorClosed.bind(this)
         );
      }

      private onViewSettingsClicked(): void {
         let selectedItem: DvsAddHostHierarchicalListItem =
               this._selectedItem as DvsAddHostHierarchicalListItem;
         let vmItem: DvsAddHostHierarchicalListVmItem;
         let vmVnicItemKey: number | undefined;
         if (selectedItem.type === DvsAddHostWizardConstants.nicListItemType.VM_VNIC_TYPE) {
            let vmVnicItem = selectedItem as DvsAddHostHierarchicalListVmVnicItem & TreeListModel;
            vmVnicItemKey = vmVnicItem.key;
            vmItem = this.treeListDataSource.parentNode(vmVnicItem);
         } else if (selectedItem.type === DvsAddHostWizardConstants.nicListItemType.VM_TYPE) {
            vmItem = selectedItem as DvsAddHostHierarchicalListVmItem;
         } else {
            throw Error("View settings action is invoked for item different than vm or vm vnic");
         }

         this.vmVnicSettingsDialogService.show(vmItem.label, vmItem.vmRef, vmVnicItemKey);
      }

      private onResetChangesClicked(): void {

         if (this._selectedItem.type ===
               DvsAddHostWizardConstants.nicListItemType.VM_VNIC_TYPE) {
            this.resetChangesForVnic(this._selectedItem);
         } else if (this._selectedItem.type ===
               DvsAddHostWizardConstants.nicListItemType.VM_TYPE) {
            this.resetChangesForVm(this._selectedItem);
         } else if (this._selectedItem.type ===
               DvsAddHostWizardConstants.nicListItemType.HOST_TYPE) {
            this.resetChangesForHostItem(this._selectedItem);
         }

         this.updateToolbar();
         this.updateReassignedLabels();
         this.$wizardScope.model.selectVirtualMachinesPageModel.hosts =
               this.treeListDataSource.data() as DvsAddHostTreeListItemWrapper[];
      }

      private resetChangesForHostItem(hostItem: any): void {
         let childVmItems: any[] =
               this.treeListDataSource.childNodes(hostItem);
         if (childVmItems && childVmItems.length > 0) {
            _.each(childVmItems, this.resetChangesForVm.bind(this));
         }
      }

      private resetChangesForVm(vmItemWrapper: DvsAddHostTreeListItemWrapper): void {
         let childVnicItems: any[] = this.treeListDataSource.childNodes(vmItemWrapper);
         _.each(childVnicItems, (vnicItem: any) => {
            this.resetChangesForVnic(vnicItem);
         });
      }

      private resetChangesForVnic(vnicItemWrapper: DvsAddHostTreeListItemWrapper): void {

         if (!this.dvsSelectVirtualMachinesPageService.isVnicModified(vnicItemWrapper)) {
            return;
         }

         let originalVnicItem: DvsAddHostHierarchicalListVmVnicItem =
               vnicItemWrapper.originalItem as DvsAddHostHierarchicalListVmVnicItem;
         let updatedItem: DvsAddHostHierarchicalListItem =
               vnicItemWrapper as DvsAddHostHierarchicalListItem;
         let updatedVnicItem: DvsAddHostHierarchicalListVmVnicItem =
               updatedItem as DvsAddHostHierarchicalListVmVnicItem;

         updatedVnicItem.destinationPortgroup = originalVnicItem.destinationPortgroup;
         updatedVnicItem.destinationPortgroupRef =
               originalVnicItem.destinationPortgroupRef;
         this.treeListDataSource.pushUpdate(updatedVnicItem);
      }

      private updateReassignedLabelForVm(vmItemWrapper: DvsAddHostTreeListItemWrapper) {
         let childNics: DvsAddHostTreeListItemWrapper[] =
               this.treeListDataSource.childNodes(vmItemWrapper);
         let item: DvsAddHostHierarchicalListItem =
               vmItemWrapper as DvsAddHostHierarchicalListItem;
         let vmItem: DvsAddHostHierarchicalListVmItem =
               item as DvsAddHostHierarchicalListVmItem;
         vmItem.isReassigned =
               _.some(childNics,
                     this.dvsSelectVirtualMachinesPageService.isVnicModified);
         this.treeListDataSource.pushUpdate(vmItem);
      }

      private updateReassignedLabels(): void {

         if (this._selectedItem.type ===
               DvsAddHostWizardConstants.nicListItemType.VM_VNIC_TYPE) {
            let parentVmItem: DvsAddHostTreeListItemWrapper =
                  this.treeListDataSource.parentNode(this._selectedItem);
            this.updateReassignedLabelForVm(parentVmItem);
         } else if (this._selectedItem.type ===
               DvsAddHostWizardConstants.nicListItemType.VM_TYPE) {
            this.updateReassignedLabelForVm(this._selectedItem);
         } else if (this._selectedItem.type ===
               DvsAddHostWizardConstants.nicListItemType.HOST_TYPE) {
            let childVmItems: DvsAddHostTreeListItemWrapper[] =
                  this.treeListDataSource.childNodes(this._selectedItem);
            _.each(childVmItems, (vmItem: DvsAddHostTreeListItemWrapper) => {
               this.updateReassignedLabelForVm(vmItem);
            });
         }
      }

      public onTreeListDataBound() {
         if (this.treeListItemExpanded) {
            return;
         }

         this.$timeout(() => {
            if (this.treeListItemExpanded || !this.treeListWidget) {
               return;
            }

            this.treeListItemExpanded =
                  this.dvsSelectVirtualMachinesPageService.expandHostItems(
                        this.treeListWidget,
                        this.treeListDataSource);

         }, 0);
      }

      private disableAllActions(): void {
         _.each(this.actionBarOptions.actions, (action: any): void => {
            action.enabled = false;
         });
      }

      public updateToolbar(): void {
         if (this.treeListWidget === null) {
            return;
         }
         let row: any = this.treeListWidget.select();
         let data: any = this.treeListWidget.dataItem(row);
         this._selectedItem = data;

         if (!data) {
            // No item is selected, typically triggered by a deselection.
            // Disable all actions.
            this.disableAllActions();
         }

         if (data && data.type) {
            let assignPortgroupAction: any = this.actionBarOptions.actions[0];
            let resetChangesAction: any = this.actionBarOptions.actions[1];

            assignPortgroupAction.enabled =
                  !data.isOrphaned &&
                  (data.type === DvsAddHostWizardConstants.nicListItemType.VM_TYPE
                  || data.type === DvsAddHostWizardConstants.nicListItemType.VM_VNIC_TYPE);

            resetChangesAction.enabled = !data.isOrphaned &&
                  this.containsModifications(this._selectedItem);

            let viewSettingsAction: any = this.actionBarOptions.actions[2];
            viewSettingsAction.enabled =
                  data.type === DvsAddHostWizardConstants.nicListItemType.VM_TYPE
                  || data.type === DvsAddHostWizardConstants.nicListItemType.VM_VNIC_TYPE;
         }
      }

      private containsModifications(itemWrapper: DvsAddHostTreeListItemWrapper): boolean {
         if (!itemWrapper) {
            return false;
         }

         if (itemWrapper.type === DvsAddHostWizardConstants.nicListItemType.VM_VNIC_TYPE) {
            return this.dvsSelectVirtualMachinesPageService.isVnicModified(itemWrapper);
         } else if (itemWrapper.type ===
               DvsAddHostWizardConstants.nicListItemType.VM_TYPE) {
            return this.containsModificationsVm(itemWrapper);
         } else if (itemWrapper.type ===
               DvsAddHostWizardConstants.nicListItemType.HOST_TYPE) {
            let childVmItems: DvsAddHostTreeListItemWrapper[] =
                  this.treeListDataSource.childNodes(itemWrapper);
            return _.some(childVmItems, this.containsModificationsVm.bind(this));
         } else {
            throw new Error("Unknown item type: " + itemWrapper.type);
         }
      }

      private containsModificationsVm(itemWrapper: DvsAddHostTreeListItemWrapper): boolean {
         if (!itemWrapper || itemWrapper.type !==
               DvsAddHostWizardConstants.nicListItemType.VM_TYPE) {
            return false;
         }

         let childVnics: DvsAddHostTreeListItemWrapper[] =
               this.treeListDataSource.childNodes(itemWrapper);
         return _.some(
               childVnics, this.dvsSelectVirtualMachinesPageService.isVnicModified);
      }

      private requestVirtualMachines(): any {
         let hostRefs: ManagedObjectReference[] =
               _.pluck(this.$wizardScope.model.selectedHosts, 'hostRef');
         let dvsHostSpec: string =
               "com.vmware.vsphere.client.h5.network.dvs.addhost.model.DvsHostsSpec";
         let areMemberHosts: boolean =
               this.$wizardScope.model.selectOperationPageModel.selectedOperationType ===
               DvsAddHostWizardConstants.operationType.MANAGE_HOST ||
               this.$wizardScope.model.selectOperationPageModel.selectedOperationType ===
               DvsAddHostWizardConstants.operationType.MIGRATE_NETWORKING;
         let params = {
            propertyParams: [{
               propertyName: DvsSelectVirtualMachinesPageController.VIRTUAL_MACHINES_PROPERTY,
               parameterType: dvsHostSpec,
               parameter: {
                  _type: dvsHostSpec,
                  hosts: hostRefs,
                  areMemberHosts: areMemberHosts
               }
            }]
         };

         let virtualMachinesPromise: any = this.dataService.getProperties(
               this.$wizardScope.model.dvsId,
               [DvsSelectVirtualMachinesPageController.VIRTUAL_MACHINES_PROPERTY],
               params);

         return virtualMachinesPromise;

      }

      private createVmVnicTreeModel(hostVmsData: any): void {
         if (hostVmsData) {
            let hostDataArray: any[] =
                  hostVmsData[
                        DvsSelectVirtualMachinesPageController.VIRTUAL_MACHINES_PROPERTY];

            if (hostDataArray === null) {
               // Workaround for kendo ObservableArray which throws an error in case of
               // a null constructor argument.
               hostDataArray = [];
            }
            let dataProvider: DvsAddHostTreeListItemWrapper[] =
                  _.map(hostDataArray, (item: DvsAddHostHierarchicalListItem) => {
                     let wrapperItem: DvsAddHostTreeListItemWrapper =
                           item as DvsAddHostTreeListItemWrapper;
                     wrapperItem.originalItem =
                           angular.copy(item, new DvsAddHostHierarchicalListItem());
                     return wrapperItem;
                  });
            this.initializeVirtualMachinesTreeList(dataProvider);

            this.$wizardScope.model.selectVirtualMachinesPageModel.isInitialized = true;
         }
      }

      private initializeVirtualMachinesTreeList(data: DvsAddHostTreeListItemWrapper[]) {

         this.treeListDataSource = new kendo.data.TreeListDataSource({
            data: data,
            schema: this.dvsSelectVirtualMachinesPageService.getTreeListSchema()
         });
         this.treeListDataSource.read();
         this.treeListColumns = this.dvsSelectVirtualMachinesPageService.getColumnDefs();

         if (data.length > 0) {
            // Cache data only if such exists. Otherwise don't clear cache simply because the grid data was hidden
            // (Migrate virtual machine networking checkbox has been unchecked).
            this.$wizardScope.model.selectVirtualMachinesPageModel.hosts =
                  this.treeListDataSource.data();
         }

         this.updateWarningState();
      }

      private updateWarningState() {
         if (this.$wizardScope.model.selectVirtualMachinesPageModel.hosts &&
               this.$wizardScope.model.selectVirtualMachinesPageModel.isMigrateVirtualMachineNetworkingEnabled) {
            this.showWarning =
                  _.some(this.$wizardScope.model.selectVirtualMachinesPageModel.hosts,
                        (vnicItem: any) => {
                           return vnicItem.showWarning;
                        });
         }
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .controller("DvsSelectVirtualMachinesPageController",
               DvsSelectVirtualMachinesPageController);
}
