/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace dvs_ui
{
   import DvsPortMirroringSourcePorts =
         com.vmware.vsphere.client.h5.network.dvs.portmirroring.model.DvsPortMirroringSourcePorts;

   export class DvsPortMirroringSourcesVlanViewComponent {
      public bindings:any;
      public controller:any;
      public templateUrl:string;

      constructor() {
         this.bindings = {
            selectedSession: "<",
            shouldRefresh: "<",
            onRefresh: "<"
         };
         this.controller = DvsPortMirroringSourcesVlanViewController;
         this.templateUrl = "dvs-ui/resources/dvs/portmirroring/" +
               "dvsPortMirroringSourcesDestViewTemplate.html";
      }
   }

   class DvsPortMirroringSourcesVlanViewController {

      static $inject:any = [
         "$rootScope",
         "$scope",
         "i18nService",
         "vuiConstants",
         "columnRenderersRegistry",
         "networkUtil",
         "dvsPortMirroringSourcesAndDestRefreshService"
      ];

      public selectedSession:string;

      public shouldRefresh: boolean;

      public onRefresh: Function;

      public datagridOptions:any;

      private tableEntries: any;

      constructor(private $rootScope:any,
                  private $scope:any,
                  private i18nService:any,
                  private vuiConstants:any,
                  private columnRenderersRegistry:any,
                  private networkUtil:any,
                  private dvsPortMirroringSourcesAndDestRefreshService:any) {

         this.createDatagridOptions();
      }

      public $onInit():void {
         this.createDatagridOptions();
      }

      public $onChanges():void {
         if (this.shouldRefresh) {
            this.dvsPortMirroringSourcesAndDestRefreshService
                  .refreshDvsPortMirroringSourcesView(
                        this.$scope, this.$rootScope,
                        this.selectedSession, this.fillTableEntries.bind(this));
         }
      }

      public fillTableEntries(detailsData:DvsPortMirroringSourcePorts) {
         if (detailsData) {
            this.tableEntries = detailsData.sourcePortData ?
                  detailsData.sourcePortData : [];
         } else {
            this.tableEntries = [];
         }
         this.$scope.portMirrorLoading = false;
         this.datagridOptions.data = this.tableEntries;
         this.onRefresh();
      }

      private createDatagridOptions():void {
         this.datagridOptions = {
            columnDefs: this.createColumnDefinitions(),
            sortMode: this.vuiConstants.grid.sortMode.SINGLE,
            selectionMode: this.vuiConstants.grid.selectionMode.SINGLE,
            selectedItems: [],
            data: this.tableEntries,
            resizable: true,
            height: "100%",
            pageConfig: {
               hidePager: true
            }
         };
      }

      private createColumnDefinitions():any {
         return [{
            displayName: this.i18nService.getString(
                  "DvsUi", "SessionDvPortSourceList.vlanId"),
            field: "vlan",
            width: "150px",
            sortable: (item1: number, item2: number) => {
               return this.networkUtil.compareNumericValues(item1, item2, "vlan");
            }
         }];
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .component("dvsPortMirroringSourcesVlanView",
         new DvsPortMirroringSourcesVlanViewComponent());
}
