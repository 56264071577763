/* Copyright 2018 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace dvs_ui {

   export class LagDetailsDialogContent {

      public bindings: any;
      public controller: any;
      public template: string;

      constructor() {
         this.bindings = {
            data: "<"
         };

         this.controller = LagDetailsDialogContentController;

         this.template = `<vx-property-view class="network-ui-fixed-size-vx-property-view"
                                            data="$ctrl.propertyViewData"/>`;
      }
   }

   class LagDetailsDialogContentController {

      static $inject = [
         "lagPropertiesService"
      ];

      data: any;

      propertyViewData: any;

      constructor(private lagPropertiesService: any) {}

      $onInit(): void {
         this.propertyViewData = this.lagPropertiesService.getPropertyViewData(this.data);
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .component("lagDetailsDialogContent",
               new LagDetailsDialogContent());
}
