(function() {
   'use strict';
   angular.module('com.vmware.vsphere.client.vm').service('vmProvisioningFinishCloneVmPageService', [
      'i18nService',
      'addVmService',
      'vmProvisioningFinishCloneVmPageModel',
      'vmVuiWizardPageBuilder',
      function(i18nService, addVmService, vmProvisioningFinishCloneVmPageModel, vmVuiWizardPageBuilder) {
         return {
            build: function(wizardScope) {

               var scheduledTaskData = wizardScope.scheduleTaskData &&
                  wizardScope.scheduleTaskData.isScheduledTask ?
                  wizardScope.scheduleTaskData : null;

               var pageModel = new vmProvisioningFinishCloneVmPageModel(
                     wizardScope.vmParams,
                     wizardScope.wizardViewData,
                     scheduledTaskData);

               wizardScope.vmProvisioningFinishCloneVmPageModel = pageModel;

               return vmVuiWizardPageBuilder.buildVuiWizardFinishPage(wizardScope.config, {
                  title: i18nService.getString('VmUi', 'ProvisioningWizardSummaryPage.Title'),
                  description: i18nService.getString('VmUi', 'ProvisioningWizardSummaryPage.Description'),
                  contentUrl: 'vm-ui/resources/vm/views/createVmWizard/vmProvisioningFinishClone.html',
                  model: pageModel
               });
            }
         };
      }
   ]);
})();
