module cluster_ui {

   export class DatastoresUnderApdPdlService {

      public static $inject = ["i18nService", "columnRenderersRegistry", "defaultUriSchemeUtil"];

      constructor(private i18nService: any,
                  private columnRenderersRegistry: any,
                  private defaultUriSchemeUtil: any) {
      }

      public getGridOptions(): Object {
         let options = {
            actionBarOptions: {
               actions: []
            },
            pageConfig: {
               hidePager: true
            },
            searchable: false,
            resizable: false,
            data: [],
            columnDefs: this.getAllColumnDefs(),
            height: "100%"
         };

         return options;
      }

      private getAllColumnDefs() {
         return [
            {
               displayName: this.getString("ha.vmcp.monitoring.host.header"),
               field: "hostName",
               template: (data: any): any => {
                  return this.getColumnTemplate(
                        "vsphere-icon-host", "hostName", "hostRef", data);
               }
            },
            {
               displayName: this.getString("ha.vmcp.monitoring.datastore.header"),
               field: "datastoreName",
               template: (data: any): any => {
                  return this.getColumnTemplate(
                        "vsphere-icon-datastore",
                        "datastoreName", "datastoreRef", data);
               }
            },
            {
               displayName: this.getString("ha.vmcp.monitoring.dsCluster.header"),
               field: "dsClusterName",
               template: (data: any): any => {
                  return this.getColumnTemplate(
                        "vsphere-icon-datastore-cluster",
                        "dsClusterName", "dsClusterRef", data);
               }
            },
            {
               displayName: this.getString("ha.vmcp.monitoring.failure.header"),
               field: "reason",
               template: (data: any): any => {
                  let reason: string = "";
                  if (data && data.reason) {
                     reason = this.getInaccessibleReason(data);
                  }
                  return reason;
               }
            }
         ];
      }

      private getInaccessibleReason(item: any) {
         let reasons: any = {
            "APD_DETECTED": this.getString("ha.vmcp.monitoring.reason.APD_DETECTED"),
            "APD_TIMEOUT": this.getString("ha.vmcp.monitoring.reason.APD_TIMEOUT"),
            "PDL": this.getString("ha.vmcp.monitoring.reason.PDL")
         };

         return reasons.hasOwnProperty(item.reason) ? reasons[item.reason] : "";
      }

      private getString(key: string, ...params: string[]): string {
         return this.i18nService.getString("ClusterUi", key, params);
      }

      private getColumnRenderer(type: string): any {
         return this.columnRenderersRegistry.getColumnRenderer(type);
      }

      private getColumnTemplate(icon: string, property: any, morProperty: string, data: any): any {

         let objectLinkRenderer: any = this.getColumnRenderer("object-link");

         let currentObjectId: string =
               this.defaultUriSchemeUtil.getVsphereObjectId(data[morProperty]);

         let dataToRepresent: any = {
            currentObjectId: currentObjectId,
            name: data[property]
         };

         return data && data[property] && data[morProperty] ?
               objectLinkRenderer(["currentObjectId", "name", icon], dataToRepresent) : "";
      }
   }

   angular.module("com.vmware.vsphere.client.cluster")
         .service("datastoresUnderApdPdlService", DatastoresUnderApdPdlService);
}
