/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace h5_vm {

   import ValidationResult = com.vmware.vise.core.model.ValidationResult;

   export interface VmMigrationWizardComputeResourceState {
      // Host, Cluster, RP, vApp
      resourceRef: any;
      targetResourcePoolRef: any;
      hostName: string;
      clusterName: string;
      isValid: boolean;
      // RP, vApp
      resPoolName?: string;
      // RP, vApp
      hostRef?: any;
      // RP, vApp
      clusterRef?: any;
      /**
       * data needed for the compatibility messages presenter
       * comes from the validation of com.vmware.vsphere.client.vm.migration.LocationSpecPair
       * in the migrationService
       */
      validationResults: Array<ValidationResult>;
   }

   interface ComputeResourceValidationData {
      hostRef: any;

      hostName: any;

      clusterRef: any;

      clusterName: string;

      resourcePoolRef: any;

      resourcePoolName: string;
   }

   export class ValidateComputeResourceStateBuilder {

      private _computeResourceId: any;

      private _data: any;

      private _computeResourceType: string;

      private _isValid: boolean = false;

      private _validationResult: Array<ValidationResult>;

      private _params: ValidateComputeResourceParams;

      constructor(private managedEntityConstants: any,
                  private defaultUriSchemeUtil: any) {}

      public setComputeResource(computeResource: any): ValidateComputeResourceStateBuilder {
         this._computeResourceId = computeResource;
         this._computeResourceType = this.defaultUriSchemeUtil.getEntityType(computeResource);

         return this;
      }

      public setData(data: any): ValidateComputeResourceStateBuilder {
         this._data = data;

         return this;
      }

      public setIsValid(isValid: boolean): ValidateComputeResourceStateBuilder {
         this._isValid = isValid;

         return this;
      }

      public setValidationResult(validationResult: Array<ValidationResult>): ValidateComputeResourceStateBuilder {
         this._validationResult = validationResult;

         return this;
      }

      public setParams(params: ValidateComputeResourceParams): ValidateComputeResourceStateBuilder {
         this._params = params;

         return this;
      }

      public build(): VmMigrationWizardComputeResourceState {
         let data = (this._data as ValidationResult);
         let state = {
            resourceRef: this.defaultUriSchemeUtil
               .getManagedObjectReference(this._computeResourceId),
            targetResourcePoolRef: data.result.resourcePoolRef,
            hostName: data.result.hostName ? data.result.hostName : this._params.hostName,
            clusterName: (data.result.clusterName ? data.result.clusterName : this._params.clusterName as string),
            isValid: this._isValid,
            validationResults:
                  this.transformErrorsIntoWarningsIfNeeded(this._validationResult)
         };

         if (this._computeResourceType === this.managedEntityConstants.V_APP ||
            this._computeResourceType === this.managedEntityConstants.RESOURCE_POOL) {
            _.extend(state, {
               hostRef: data.result.hostRef,
               resPoolName: data.result.resourcePoolName ? data.result.resourcePoolName : this._params.resPoolName,
               clusterRef: data.result.clusterRef
            });
         }

         this.cleanup();

         return state;
      }

      private cleanup(): void {
         this._computeResourceId = null;
         this._computeResourceType = "";
         this._data = null;
      }

      private transformErrorsIntoWarningsIfNeeded(validationResults: Array<ValidationResult>): Array<ValidationResult> {
         // When the compute resource is a cluster, resource pool or a vApp
         // and the overall validation is successful - remove the error messages for
         // individual hosts.
         if ((this._computeResourceType === this.managedEntityConstants.CLUSTER
                     || this._computeResourceType === this.managedEntityConstants.RESOURCE_POOL
                     || this._computeResourceType === this.managedEntityConstants.V_APP)
               && this._isValid) {
            return _.map(validationResults, function(validationResult: ValidationResult) {
               const result: ValidationResult = _.extend({}, validationResult);
               _.each(result.result, function(hostResult: any) {
                  if (!!hostResult.error) {
                     hostResult.warning = hostResult.warning
                           ? hostResult.warning.concat(hostResult.error)
                           : hostResult.error;
                     hostResult.error = null;
                  }
                  return hostResult;
               });
               return result;
            });
         }

         return validationResults;
      }
   }

   angular.module("com.vmware.vsphere.client.vm")
         .factory(
            "vmMigrateValidateComputeResourceStateBuilderFactory",
            ["managedEntityConstants", "defaultUriSchemeUtil",
               function (managedEntityConstants: any, defaultUriSchemeUtil: any) {
               return {
                  create: function () {
                     return new ValidateComputeResourceStateBuilder(
                        managedEntityConstants, defaultUriSchemeUtil);
                  }
               };
            }]);
}
