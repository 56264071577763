/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
(function () {
   'use strict';
   angular.module('com.vmware.vsphere.client.storage').factory('VmfsWizardManager', [
      'i18nService',
      'defaultUriSchemeUtil',
      'dataService',
      '$q',
      'bytesFilter',
      'storageUtil',
      'datastoreConstants',
      'mutationService',
      'wizardPageService',
      'vxPropertyViewService',
      function (i18nService,
                defaultUriSchemeUtil,
                dataService,
                $q,
                bytesFilter,
                storageUtil,
                datastoreConstants,
                mutationService,
                wizardPageService,
                propertyViewService) {
         return function VmfsWizardManager(commonWizardManager) {

            var AVAILABLE_DISK_FOR_VMFS = 'availableDisksForVmfs';
            var VMFS_CREATE_OPTION_ITEMS = 'datastore:vmfsDatastoreCreateOptionsData';

            var DEFAULT_SPACE_RECLAMATION_PRIORITY = datastoreConstants.reclamationPriority.LOW;

            var mountOptions = {
               KEEP_SIGNATURE: 'keepSignature',
               ASSIGN_NEW_SIGNATURE: 'assignNewSignature',
               FORMAT_DISK: 'formatDisk'
            };

            var vmfsVersions = [
               {
                  title: i18nService.getString(
                     'StorageUi', 'addDatastoreWizard.vmfsSelectVersionPage.vmfs6'),
                  description: i18nService.getString(
                     'StorageUi', 'addDatastoreWizard.vmfsSelectVersionPage.vmfs6Description'),
                  value: datastoreConstants.vmfsVersions.VMFS_6
               },
               {
                  title: i18nService.getString(
                     'StorageUi', 'addDatastoreWizard.vmfsSelectVersionPage.vmfs5'),
                  description: i18nService.getString(
                     'StorageUi', 'addDatastoreWizard.vmfsSelectVersionPage.vmfs5Description'),
                  value: datastoreConstants.vmfsVersions.VMFS_5
               }
            ];

            var getString = _.partial(i18nService.getString, 'StorageUi');

            var vmfsMountOptionsPageTitle =
                  getString('addDatastoreWizard.vmfsMountOptionsPage.title');
            var vmfsPartitionConfigurationPageTitle =
                  getString('addDatastoreWizard.vmfsPartitionConfigPage.title');

            var state = {
               isVmfs6Supported: true,
               selectedHostId: null,
               selectedDiskInfo: null,
               selectedVmfsDatastoreOptionItem: null,
               datastoreSizeInGB: null,
               selectedMountOption: null,
               selectedVmfsVersion: null,
               selectedVmfsBlockSizeOptions: null
            };

            var cache = {
               hostRefForDiskInfos: null,
               diskInfos: null,
               devicePathForDatastoreOptions: null,
               vmfsMajorVersionForDatastoreOptions: null,
               vmfsDatastoreOptionItems: null,
               blockSizeOptions: null,
               reclamationPriority: null
            };

            var manager = {
               submit: submit,
               getReadyToCompletePageData:getReadyToCompletePageData,

               mountOptions: mountOptions,
               vmfsVersions: vmfsVersions,

               getSelectedHostId: getSelectedHostId,
               setSelectedHostId: setSelectedHostId,

               getIsVmfs6Supported: getIsVmfs6Supported,
               setIsVmfs6Supported: setIsVmfs6Supported,

               setSelectedScsiDiskInfo: setSelectedScsiDiskInfo,
               getSelectedScsiDiskInfo: getSelectedScsiDiskInfo,

               getDatastoreSizeInGb: getDatastoreSizeInGb,
               setDatastoreSizeInGb: setDatastoreSizeInGb,

               getSelectedVmfsVersion: getSelectedVmfsVersion,
               setSelectedVmfsVersion: setSelectedVmfsVersion,

               getSelectedMountOption: getSelectedMountOption,
               setSelectedMountOption: setSelectedMountOption,

               setSelectedVmfsDatastoreOptionItem: setSelectedVmfsDatastoreOptionItem,
               getSelectedVmfsDatastoreOptionItem: getSelectedVmfsDatastoreOptionItem,

               getSelectedVmfsBlockSizeOptions: getSelectedVmfsBlockSizeOptions,
               setSelectedVmfsBlockSizeOptions: setSelectedVmfsBlockSizeOptions,

               requestAvailableScsiDiskInfos: requestAvailableScsiDiskInfos,
               requestVmfsCreateOptionItems: requestVmfsCreateOptionItems,

               updateVmfsVersionPageVisibility: updateVmfsVersionPageVisibility,
               skipVmfsVersionPage: skipVmfsVersionPage
            };

            return manager;

            function getIsVmfs6Supported () {
               return state.isVmfs6Supported;
            }

            function setIsVmfs6Supported (isSupported) {
               state.isVmfs6Supported = isSupported;
            }

            function getSelectedScsiDiskInfo() {
               return state.selectedDiskInfo;
            }

            function setSelectedScsiDiskInfo(selectedDiskInfo) {
               if (equalDiskInfos(selectedDiskInfo, state.selectedDiskInfo)) {
                  return;
               }

               state.selectedDiskInfo = selectedDiskInfo;

               // Reset vmfs datastore and mount option as
               // they are invalid for the newly selected disk.
               setSelectedVmfsDatastoreOptionItem(null);
               setSelectedMountOption(null);

               updateMountOptionVisibility();
            }

            function getSelectedHostId () {
               if (commonWizardManager.isHostContextObject()) {
                  return commonWizardManager.getContextObject();
               }
               return state.selectedHostId;
            }

            function setSelectedHostId(hostId) {
               state.selectedHostId = hostId;
            }

            function getDatastoreSizeInGb () {
               return state.datastoreSizeInGB;
            }

            function setDatastoreSizeInGb(dsSizeInGb) {
               state.datastoreSizeInGB = dsSizeInGb;
            }

            function getDatastoreSizeInBytes() {
               var selectedOption = getSelectedVmfsDatastoreOptionItem();
               var maxDsSizeInBytes = 0;
               if (selectedOption) {
                  maxDsSizeInBytes = selectedOption.maxDatastoreSize;
               }
               var dsSizeInBytes = state.datastoreSizeInGB * datastoreConstants.BYTES_TO_GB;
               if (dsSizeInBytes > maxDsSizeInBytes) {
                  // Selected datastore sized is bigger than the max datastore size
                  // for the selected option. Return max datastore size.
                  dsSizeInBytes = maxDsSizeInBytes;
               }

               if (maxDsSizeInBytes - dsSizeInBytes <
                     datastoreConstants.vmfsSize.DATASTORE_SIZE_STEP_IN_BYTES) {
                  // Difference between the selected datastore size and the max datastore
                  // size is less than datastore size step (0.01 GB). This might happen
                  // after rounding during the bytes to GB conversion. We need to return
                  // the max datastore size otherwise we will lose space on the disk.
                  dsSizeInBytes = maxDsSizeInBytes;
               }
               return dsSizeInBytes;
            }

            function getSelectedMountOption() {
               return state.selectedMountOption;
            }

            function setSelectedMountOption(mountOption) {
               if (state.selectedMountOption !== mountOption) {
                  state.selectedMountOption = mountOption;
                  updatePartitionConfigurationVisibility();
               }
            }

            function getSelectedVmfsVersion() {
               return state.selectedVmfsVersion;
            }

            function setSelectedVmfsVersion(version) {
               state.selectedVmfsVersion = version;
            }

            function setSelectedVmfsDatastoreOptionItem(optionItem) {
               state.selectedVmfsDatastoreOptionItem = optionItem;
            }

            function getSelectedVmfsDatastoreOptionItem() {
               return state.selectedVmfsDatastoreOptionItem;
            }

            function getSelectedVmfsBlockSizeOptions() {
               return state.selectedVmfsBlockSizeOptions;
            }

            function setSelectedVmfsBlockSizeOptions(blockSizeOtions) {
               state.selectedVmfsBlockSizeOptions = blockSizeOtions;
            }

            function requestAvailableScsiDiskInfos() {

               var hostRef = getSelectedHostId();

               if (cache.hostRefForDiskInfos === hostRef &&
                  cache.diskInfos) {
                  return $q.when(cache.diskInfos);
               }

               return dataService.getProperties(hostRef,
                     [AVAILABLE_DISK_FOR_VMFS]).then(function (result) {
                  var disks = result ? result[AVAILABLE_DISK_FOR_VMFS] || [] : [];

                  // Cache the response
                  cache.hostRefForDiskInfos = hostRef;
                  cache.diskInfos = disks;

                  return disks;
               });
            }

            function requestVmfsCreateOptionItems() {

               var devicePath = getSelectedScsiDiskInfo().disk.devicePath;
               var vmfsMajorVersion = getSelectedVmfsVersion();

               if (cache.devicePathForDatastoreOptions === devicePath &&
                     cache.vmfsMajorVersionForDatastoreOptions === vmfsMajorVersion &&
                     cache.vmfsDatastoreOptionItems) {
                  return $q.when({
                     optionItems: cache.vmfsDatastoreOptionItems,
                     diskPartitionInfo: cache.diskPartitionInfo,
                     blockSizeOptions: cache.blockSizeOptions,
                     reclamationPriority: cache.reclamationPriority
                  });
               }

               return dataService.getProperties(getSelectedHostId(),
                     [VMFS_CREATE_OPTION_ITEMS],
                     {
                        propertyParams: [{
                           propertyName: VMFS_CREATE_OPTION_ITEMS,
                           parameterType: 'com.vmware.vsphere.client.h5.storage.spec.VmfsDatastoreCreateOptionQuerySpec',
                           parameter: {
                              _type: 'com.vmware.vsphere.client.h5.storage.spec.VmfsDatastoreCreateOptionQuerySpec',
                              devicePath: devicePath,
                              vmfsMajorVersion: vmfsMajorVersion
                           }
                        }]
                     }).then(function (result) {
                        var vmfsDatastoreOptionData = result ? result[VMFS_CREATE_OPTION_ITEMS] || {} : {};
                        var optionItems = vmfsDatastoreOptionData.optionItems || [];
                        var diskPartitionInfo = vmfsDatastoreOptionData.partitionInfo || {};
                        var blockSizeOptions = vmfsDatastoreOptionData.blockSizeOptions;
                        var reclamationPriority = { unmapPriority: DEFAULT_SPACE_RECLAMATION_PRIORITY};

                        // Cache datastore option items
                        cache.vmfsDatastoreOptionItems = optionItems;
                        cache.diskPartitionInfo = diskPartitionInfo;
                        cache.vmfsMajorVersionForDatastoreOptions = vmfsMajorVersion;
                        cache.devicePathForDatastoreOptions = devicePath;
                        cache.blockSizeOptions = blockSizeOptions;
                        cache.reclamationPriority = reclamationPriority;

                       return {
                          optionItems: optionItems,
                          diskPartitionInfo: diskPartitionInfo,
                          blockSizeOptions: blockSizeOptions,
                          reclamationPriority: reclamationPriority
                       };
                     });
            }

            function skipVmfsVersionPage(skipped) {
               skipPage(i18nService.getString(
                  'StorageUi', 'addDatastoreWizard.vmfsSelectVersionPage.title'), skipped);
            }

            function updateVmfsVersionPageVisibility(host) {
               var isVMFS6Supported = host.isVMFS6Supported;

               skipVmfsVersionPage(!isVMFS6Supported);

               var vmfsVersion = isVMFS6Supported?
                  datastoreConstants.vmfsVersions.VMFS_6 :
                  datastoreConstants.vmfsVersions.VMFS_5;
               setSelectedVmfsVersion(vmfsVersion);
            }

            function submit() {
               if (isVmfsMountOptionSelected()) {
                  if ( getSelectedMountOption() === mountOptions.ASSIGN_NEW_SIGNATURE) {
                     return resignatureVmfsDatastore();
                  } else {
                     return forceMountVmfsDatastore();
                  }

               } else {
                  return createNewDatastore();
               }
            }

            function getReadyToCompletePageData() {

               var builder = propertyViewService.createPropertyViewBuilder();
               var category = builder.category('category');

               if (isVmfsMountOptionSelected()) {
                  var headlessSection = category.section('headless');
                  appendVmfsTypeProp(headlessSection);
                  appendDiskNameProp(headlessSection);
                  headlessSection.property(
                        getString('addDatastoreWizard.vmfsReadyToCompletePage.mountOption'),
                        getSelectedMountOption() === mountOptions.ASSIGN_NEW_SIGNATURE
                              ? getString('vmfsMountOptions.newSignature')
                              : getString("vmfsMountOptions.keepSignature"));
               } else {

                  var generalSection = category.section('general').title(
                        getString('addDatastoreWizard.vmfsReadyToCompletePage.general'));

                  generalSection.property(
                        getString('addDatastoreWizard.vmfsReadyToCompletePage.name'),
                        commonWizardManager.getDatastoreName());

                  appendVmfsTypeProp(generalSection);

                  generalSection.property(
                        getString('addDatastoreWizard.vmfsReadyToCompletePage.size'),
                        bytesFilter(getDatastoreSizeInBytes(), 'B', 'Auto', 2));

                  var deviceAndFormattingSection = category
                        .section('deviceAndFormatting')
                        .title(getString('addDatastoreWizard.vmfsReadyToCompletePage.deviceAndFormatting'));

                  appendDiskNameProp(deviceAndFormattingSection);

                  deviceAndFormattingSection.property(
                        getString('addDatastoreWizard.vmfsReadyToCompletePage.partitionFormat'),
                        storageUtil.formatDiskPartitionType(getDiskPartitionType()));

                  deviceAndFormattingSection.property(
                        getString('addDatastoreWizard.vmfsReadyToCompletePage.vmfsVersion'),
                        getString('vmfsDatastore.version.format', getSelectedVmfsVersion()));

                  if (getSelectedVmfsVersion() === datastoreConstants.vmfsVersions.VMFS_6) {
                     appendBlockSizeOptionsProp(deviceAndFormattingSection);
                  }
               }

               function appendVmfsTypeProp(section) {
                  section.property(
                        getString('addDatastoreWizard.vmfsReadyToCompletePage.type'),
                        getString('addDatastoreWizard.vmfsReadyToCompletePage.typeVmfs'));
               }

               function appendDiskNameProp(section) {
                  section.property(
                        getString('addDatastoreWizard.vmfsReadyToCompletePage.diskLun'),
                        storageUtil.formatDeviceName(getSelectedScsiDiskInfo().disk));
               }

               function appendBlockSizeOptionsProp(section) {
                  var selectedBlockSizeOptions = getSelectedVmfsBlockSizeOptions();

                  section.property(
                     getString('addDatastoreWizard.vmfsReadyToCompletePage.blockSize'),
                     selectedBlockSizeOptions.blockSize.formattedValue);
                  section.property(
                     getString('addDatastoreWizard.vmfsReadyToCompletePage.spaceReclamationGranularity'),
                     selectedBlockSizeOptions.granularity.formattedValue);

                  var reclamationPriority = selectedBlockSizeOptions.reclamationPriority.unmapPriority;
                  var reclamationPriorityDescription = getString("informationNotAccessible");
                  if (reclamationPriority === datastoreConstants.reclamationPriority.NONE) {
                     reclamationPriorityDescription =
                        getString('addDatastoreWizard.vmfsReadyToCompletePage.spaceReclamationPriorityNoneDescription');
                  } else if (reclamationPriority === datastoreConstants.reclamationPriority.LOW) {
                     var lowPriority = getString('vmfs.unmapPriority.low');
                     reclamationPriorityDescription =
                        getString('addDatastoreWizard.vmfsReadyToCompletePage.spaceReclamationPriorityDescription',
                           lowPriority, lowPriority.toLowerCase());
                  }

                  section.property(
                     getString('addDatastoreWizard.vmfsReadyToCompletePage.spaceReclamationPriority'), reclamationPriorityDescription);
               }

               return builder.build();
            }

            function createNewDatastore() {
               var createOptionItem = getSelectedVmfsDatastoreOptionItem();
               var createOption = createOptionItem.vmfsDatastoreOption;
               var vmfsDatastoreCreateSpec = createOption.spec;

               // Set the datastore name.
               vmfsDatastoreCreateSpec.vmfs.volumeName = commonWizardManager.getDatastoreName();

               if (getSelectedVmfsVersion() === datastoreConstants.vmfsVersions.VMFS_6) {
                  var selectedVmfsBlockSizeOptions = getSelectedVmfsBlockSizeOptions();
                  vmfsDatastoreCreateSpec.vmfs.blockSize = selectedVmfsBlockSizeOptions.blockSize.value;
                  vmfsDatastoreCreateSpec.vmfs.unmapGranularity = selectedVmfsBlockSizeOptions.granularity.value;
                  vmfsDatastoreCreateSpec.vmfs.unmapPriority = selectedVmfsBlockSizeOptions.reclamationPriority.unmapPriority;
               }

               // Creates the spec for datastore creation:
               var spec = {
                  _type: 'com.vmware.vsphere.client.storage.VmfsDatastoreCreateSpec',
                  host: defaultUriSchemeUtil.getManagedObjectReference(getSelectedHostId()),
                  storageFolder: commonWizardManager.getStorageFolder(),
                  vmfsDatastoreCreateSpec: vmfsDatastoreCreateSpec,
                  datastoreSize: getDatastoreSizeInBytes(),
                  disk: getSelectedScsiDiskInfo(),
                  createOption: createOption
               };

               mutationService.add('com.vmware.vsphere.client.storage.VmfsDatastoreCreateSpec', spec);
               return true;
            }

            /**
             * Resignature an existing VMFS datastore.
             */
            function resignatureVmfsDatastore() {
               var unresolvedVmfsResignatureSpec = {
                  _type: 'com.vmware.vim.binding.vim.host.UnresolvedVmfsResignatureSpec',
                  extentDevicePath: [ getSelectedScsiDiskInfo().unresolvedExtent.devicePath]
               };

               var spec = {
                  _type: 'com.vmware.vsphere.client.storage.VmfsDatastoreResignatureSpec',
                  host: defaultUriSchemeUtil.getManagedObjectReference(getSelectedHostId()),
                  storageFolder: commonWizardManager.getStorageFolder(),
                  vmfsDatastoreResignatureSpec: unresolvedVmfsResignatureSpec
               };

               mutationService.add('com.vmware.vsphere.client.storage.VmfsDatastoreResignatureSpec', spec);
               return true;
            }

            /**
             * Force mount an existing VMFS datastore to the selected host.
             */
            function forceMountVmfsDatastore() {

               var unresolvedVmfsResoltionSpec = {
                  _type: 'com.vmware.vim.binding.vim.host.UnresolvedVmfsResolutionSpec',
                  extentDevicePath: [ getSelectedScsiDiskInfo().unresolvedExtent.devicePath],
                  uuidResolution: 'forceMount'
               };

               var spec = {
                  _type: 'com.vmware.vsphere.client.storage.VmfsForceMountSpec',
                  host: defaultUriSchemeUtil.getManagedObjectReference(getSelectedHostId()),
                  storageFolder: commonWizardManager.getStorageFolder(),
                  vmfsDatastoreForceMountSpec: unresolvedVmfsResoltionSpec
               };

               mutationService.add('com.vmware.vsphere.client.storage.VmfsForceMountSpec', spec);
               return true;
            }

            function getDiskPartitionType() {
               if (!getSelectedVmfsDatastoreOptionItem()) {
                  return null;
               }
               return getSelectedVmfsDatastoreOptionItem()
                     .vmfsDatastoreOption.spec.partition.partitionFormat;
            }

            function equalDiskInfos(diskInfo1, diskInfo2) {
               if (diskInfo1 === diskInfo2) {
                  return true;
               }
               if (!diskInfo1 && !diskInfo2) {
                  return true;
               }
               if (!diskInfo1 || !diskInfo2) {
                  return false;
               }
               return diskInfo1.disk.devicePath === diskInfo2.disk.devicePath;
            }

            function updateMountOptionVisibility() {
               var selectedDisk = getSelectedScsiDiskInfo();

               var hasUnresolvedVmfsVolume = selectedDisk && selectedDisk.vmfsLabel;

               skipPage(vmfsMountOptionsPageTitle, !hasUnresolvedVmfsVolume);
            }

            function isVmfsMountOptionSelected() {
               var selectedMountOption = getSelectedMountOption();
               return selectedMountOption === mountOptions.ASSIGN_NEW_SIGNATURE ||
                     selectedMountOption === mountOptions.KEEP_SIGNATURE;
            }

            function updatePartitionConfigurationVisibility() {
               var skipParitionConfigurationPage = isVmfsMountOptionSelected();
               skipPage(vmfsPartitionConfigurationPageTitle, skipParitionConfigurationPage);
            }

            function skipPage(title, isSkipped) {
               var flows = commonWizardManager.getWizardConfig().flows;
               var currentFlow = _.find(flows, function (flow) {
                  return flow.id === commonWizardManager.getDatastoreType();
               });

               var pages = currentFlow.pages;
               if (isSkipped) {
                  wizardPageService.markPageSkipped(pages, title, isSkipped);
               } else {
                  wizardPageService.markPageDisabled(pages, title, isSkipped);
               }
               wizardPageService.invalidateNextPages(commonWizardManager.getWizardConfig(),
                  commonWizardManager.getDatastoreType(), title);
            }
         };
      }
   ]);
})();
