/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */

import DvsSelectPhysicalAdaptersPageValidator = dvs_ui.DvsSelectPhysicalAdaptersPageValidator;
import DvsSelectPhysicalAdaptersPageModel = dvs_ui.DvsSelectPhysicalAdaptersPageModel;
import DvsAddHostHierarchicalListGroupingItem = com.vmware.vsphere.client.h5.network.dvs.addhost.hierarchicalList.DvsAddHostHierarchicalListGroupingItem;
import DvsAddHostHierarchicalListItem = com.vmware.vsphere.client.h5.network.dvs.addhost.hierarchicalList.DvsAddHostHierarchicalListItem;

namespace dvs_ui {

   /**
    * A validator class for the Select Physical Adapters page.
    */
   export class DvsSelectPhysicalAdaptersPageValidator {

      public static $inject = ["i18nService",
         "clarityModalService",
         "$q"];

      constructor(private i18nService: any,
                  private clarityModalService: any,
                  private $q: any) {
      }

      /**
       * Validates the supplied pageModel.
       *
       * Shows warnings/errors if such exist and returns a promise with the validation
       * result.
       */
      public validate(pageModel: DvsSelectPhysicalAdaptersPageModel): any {
         let maxUplinksCount: number = 0;

         if (pageModel && pageModel.uplinkNames) {
            maxUplinksCount += pageModel.uplinkNames.length;
         }

         let onThisSwitchGroupingItems: DvsAddHostTreeListItemWrapper[] =
               _.filter(pageModel.hosts, (item: DvsAddHostTreeListItemWrapper) => {
                  if (item && item.type ===
                        DvsAddHostWizardConstants.nicListItemType.GROUPING_TYPE) {
                     let listItem: DvsAddHostHierarchicalListItem =
                           item as DvsAddHostHierarchicalListItem;
                     let groupingItem: DvsAddHostHierarchicalListGroupingItem =
                           listItem as DvsAddHostHierarchicalListGroupingItem;
                     return groupingItem.isOnThisSwitch;
                  }
                  return false;
               });
         let groupingItemIdToNumberOfPnicsMap: { [key: string]: number; } = {};
         _.each(onThisSwitchGroupingItems, (groupingItem: DvsAddHostTreeListItemWrapper) => {
            groupingItemIdToNumberOfPnicsMap[groupingItem.id] = 0;
         });

         _.each(pageModel.hosts, (item: DvsAddHostTreeListItemWrapper) => {
            if (item && item.type === DvsAddHostWizardConstants.nicListItemType.PNIC_TYPE) {
               // If the pnic is assigned to the DVS.
               groupingItemIdToNumberOfPnicsMap[item.parentId]++;
            }
         });

         let deferred: any = this.$q.defer();

         if (_.some(_.values(groupingItemIdToNumberOfPnicsMap), (pnicsCount: number) => {
                  return pnicsCount > maxUplinksCount;
               })) {
            this.clarityModalService.openConfirmationModal({
               title: this.i18nService.getString(
                     "DvsUi", "SelectPhysicalAdaptersPage.error"),
               message: this.i18nService.getString(
                     "DvsUi", "SelectPhysicalAdaptersPage.uplinkError"),
               clarityIcon: {
                  class: "is-error",
                  shape: "error-standard",
                  size: "32"
               },
               saveButtonLabel: this.i18nService.getString(
                     "Common", "alert.ok"),
               hideCancelButton: true,
               submit: () => {
                  deferred.resolve(false);
               }
            });
         } else if (_.some(_.values(groupingItemIdToNumberOfPnicsMap), (pnicsCount: number) => {
                  return pnicsCount === 0;
               })) {
            this.clarityModalService.openConfirmationModal({
               title: this.i18nService.getString(
                     "DvsUi", "SelectPhysicalAdaptersPage.warning"),
               message: this.i18nService.getString(
                     "DvsUi", "SelectPhysicalAdaptersPage.noPnicsSelected"),
               clarityIcon: {
                  class: "is-warning",
                  shape: "warning-standard",
                  size: "32"
               },
               preserveNewlines: true,
               submit: (): void => {
                  deferred.resolve(true);
               },
               onCancel: (): void => {
                  deferred.resolve(false);
               }
            });
         } else {
            deferred.resolve(true);
         }

         return deferred.promise;
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .service("dvsSelectPhysicalAdaptersPageValidator", DvsSelectPhysicalAdaptersPageValidator);
}
