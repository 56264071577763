namespace storage_ui {

   export class StorageProvidersList {

      public i18n: Function;

      public vcId: string;

      public providerListColumns: any;

      public providerListDataSource: any;

      public providerListMessages: any;

      public loading: boolean;

      public treeListWidget: any;

      public masterDetailsViewContext: any;

      public actionBarOptions: any;

      public providerNames: string[];

      private itemToSelect:any;

      private providerStatusDetailsMap: {[key:string] : any;};

      private actionsCache: any;

      private lastDataRequestPromise: any;

      private readonly CUSTOM_TEXT_FILTER = {
         extra: false,
         messages: {
            info: this.vuiLocale.datagrid.colFilterInfo,
            filter: this.vuiLocale.datagrid.filter,
            clear: this.vuiLocale.datagrid.clear
         },
         operators: {
            string: {
               contains: this.vuiLocale.datagrid.contains
            }
         },
         ui: (element: any) => {
            const parent = element.parent();
            while (parent.children().length > 1) {
               $(parent.children()[0]).remove();
            }
            parent.prepend("<input data-bind=\"value:filters[0].value\" class=\"k-textbox\" type=\"text\">");
         }
      };

      public static $inject = ["i18nService", "$scope", "storageUtil", "storageProvidersService",
         "storageProvidersItemBuilderService", "columnRenderersRegistry", "$timeout", "commonActionBarService",
         "storageProviderConstants", "vuiConstants", "storageProvidersActionService", "defaultUriSchemeUtil", "vcService", "vuiLocale"];

      constructor(private i18nService: any,
                  private $scope: any,
                  private storageUtil: any,
                  private storageProvidersService: StorageProvidersService,
                  private storageProvidersItemBuilderService: StorageProvidersItemBuilderService,
                  private columnRenderersRegistry: any,
                  private $timeout: any,
                  private commonActionBarService: any,
                  private storageProviderConstants: any,
                  private vuiConstants: any,
                  private storageProvidersActionService: StorageProvidersActionService,
                  private defaultUriSchemeUtil: any,
                  private vcService: any,
                  private vuiLocale: any) {

         this.i18n = i18nService.getString;

         this.providerStatusDetailsMap = {};
         this.treeListWidget = null;

         this.providerListColumns = this.treeListColumns();

         this.providerListMessages = {
            noRows: ""
         };

         this.actionBarOptions = {
            actions: []
         };
      }

      public $onInit() {
         this.vcService.is65VcOrLater(this.vcId)
               .then((is65VcOrLater: any) => {
                  if (!is65VcOrLater) {
                     this.$scope.$on('vsphere.core.storage.storageProviders.providersList.refreshProvidersList', () => {
                        this.refresh();
                     });
                  }
               });
      }

      public $onChanges() {
         this.refresh();
      }

      public $onDestroy() {
         kendo.destroy($("#storage-providers-list-tree"));
      }

      private setTreeListOptions(items:any[]) {
         this.providerListDataSource = new kendo.data.TreeListDataSource({
            data: items,
            schema: {
               model: {
                  id: "id",
                  expanded: true
               }
            }
         });

         this.updateActionBar();
      }

      private findTreeListItem(itemToFind: StorageProviderListItem): any {
         if (!itemToFind || !this.providerListDataSource) {
            return null;
         }

         let dataSource = this.providerListDataSource;
         if (itemToFind._isStorageProviderItem) {
            return _.find(dataSource.rootNodes(), (item: any) => {
               return item.id === itemToFind.id;
            });
         }

         if (itemToFind._isStorageArrayItem) {
            // For storage arrays, we need to find the parent provider item first
            let parentProviderItem = _.find(dataSource.rootNodes(), (item: any) => {
               return item.id === itemToFind.parentId;
            });
            // and then check its children
            if (parentProviderItem) {
               return _.find(dataSource.childNodes(parentProviderItem), (item: any) => {
                  return item.id === itemToFind.id;
               });
            }
         }

         return null;
      }

      public onTreeListDataBound() {
         if (!this.itemToSelect) {
            return;
         }

         this.$timeout(() => {
            if (!this.treeListWidget) {
               return;
            }

            let itemToSelect: any = this.findTreeListItem(this.itemToSelect);
            if (itemToSelect) {
               let item = this.treeListWidget.itemFor(itemToSelect);
               if (item) {
                  this.treeListWidget.select(item);
               }
            }
         }, 0);
      }

      public onSelectionChanged() {
         if (this.treeListWidget) {
            let dataItem = this.treeListWidget.dataItem(this.treeListWidget.select());
            if (dataItem) {
               this.masterDetailsViewContext.selectedItems = [dataItem];
               this.updateActionBar();
               return;
            }
         }
         this.masterDetailsViewContext.selectedItems = [];
         this.updateActionBar();
      }

      public refresh() {
         if (!this.vcId || !this.defaultUriSchemeUtil.isRootFolder(this.vcId)) {
            return;
         }

         if (!this.loading) {
            this.itemToSelect =
                  this.masterDetailsViewContext.selectedItems &&
                  this.masterDetailsViewContext.selectedItems.length &&
                  this.masterDetailsViewContext.selectedItems[0];
         }

         this.loading = true;
         this.actionsCache = null;

         if (!this.providerListDataSource) {
            this.setTreeListOptions([]);
         }

         this.masterDetailsViewContext.selectedItems = [];

         let self = this;
         this.lastDataRequestPromise = this.storageProvidersService.getStorageProviderSystemInfoEx(this.vcId);
         let currentDataRequestPromise = this.lastDataRequestPromise;

         currentDataRequestPromise.then(function (items: any[]) {

            if (currentDataRequestPromise !== self.lastDataRequestPromise) {
               return;
            }

            self.storageProvidersItemBuilderService.getProviderCentricData(items).then(function (listItems:any[]) {
               self.setTreeListOptions(listItems);

               // Build provider names
               self.providerNames = _.map(
                     _.filter(listItems, function (item: StorageProviderListItem) {
                        return item._isStorageProviderItem;
                     }),
                     function (item: StorageProviderListItem): string {
                        return item._data && item._data.providerInfo && item._data.providerInfo.name;
                     });

               // Build providerStatusDetailsMap
               _.each(listItems, function (item:any) {
                  if (item.statusFault && item.statusFault.message) {
                     self.providerStatusDetailsMap[item.id] = {
                        title: self.i18n("StorageUi", "storage.storageProviders.providersList.detailsDialog.title"),
                        message: item.statusFault.message
                     };
                  }
               });
            });

         }).finally(function () {
            if (currentDataRequestPromise !== self.lastDataRequestPromise) {
               return;
            }
            self.loading = false;
         });
      }

      public getStatusDetailsSignPostConfig(id: string) {
         return this.providerStatusDetailsMap[id];
      }

      private updateActionBar() {
         this.commonActionBarService.updateActionBar(
               this.actionBarOptions,
               "actions",
               [this.vcId],
               this.getActions(),
               this.actionsCache).then((actionDefs:any) => {
            this.actionsCache = actionDefs;
         });
      }

      private getSelectedItem(): StorageProviderListItem|null {
         if (!this.masterDetailsViewContext || !this.masterDetailsViewContext.selectedItems ||
               this.masterDetailsViewContext.selectedItems.length !== 1) {
            return null;
         }
         return this.masterDetailsViewContext.selectedItems[0];
      }

      private getActions() {
         let actionDefs: any[] = [];

         actionDefs.push({
            actionId: this.storageProviderConstants.actions.ADD,
            getActionInvocationContext: () => {
               return {
                  providerNames: this.providerNames
               };
            }
         });
         actionDefs.push({
            actionId: this.storageProviderConstants.actions.RESYNC_INTERNAL_PROVIDER
         });
         actionDefs.push(this.vuiConstants.actions.SEPARATOR);

         actionDefs.push({
            actionId: this.storageProviderConstants.actions.SYNC,
            getActionInvocationContext: () => {
               let selectedItem = this.getSelectedItem();
               return selectedItem && selectedItem._data;
            },
            isActionAvailable: (actionDef: any) => {
               let selectedItem = this.getSelectedItem();
               if (!selectedItem || !actionDef.available) {
                  return false;
               }
               return this.storageProvidersActionService.isSyncProviderActionAvailable(selectedItem);
            }
         });

         actionDefs.push({
            actionId: this.storageProviderConstants.actions.REMOVE,
            getActionInvocationContext: () => {
               let selectedItem = this.getSelectedItem();
               return selectedItem && selectedItem._data;
            },
            isActionAvailable: (actionDef: any) => {
               let selectedItem = this.getSelectedItem();
               if (!selectedItem || !actionDef.available) {
                  return false;
               }
               return this.storageProvidersActionService.isRemoveProviderActionAvailable(selectedItem);
            }
         });

         actionDefs.push({
            actionId: this.storageProviderConstants.actions.REFRESH_CERTIFICATE,
            getActionInvocationContext: () => {
               let selectedItem = this.getSelectedItem();
               return selectedItem && selectedItem._data;
            },
            isActionAvailable: (actionDef: any) => {
               let selectedItem = this.getSelectedItem();
               if (!selectedItem || !actionDef.available) {
                  return false;
               }
               return this.storageProvidersActionService.isRefreshCertificateActionAvailable(selectedItem);
            }
         });

         return actionDefs;
      }

      private treeListColumns() {
         let self = this;

         let iconTextRenderer = this.columnRenderersRegistry.getColumnRenderer("icon-text");
         let textRenderer = this.columnRenderersRegistry.getColumnRenderer("text");

         return [
            {
               title: this.i18n("StorageUi", "storage.storageProviders.providersList.nameColumn.groupTitleProviderSystem"),
               field: "nameFormatted",
               template: "#:nameFormatted#",
               width: "220px",
               filterable: this.CUSTOM_TEXT_FILTER
            },
            {
               title: this.i18n("StorageUi", "storage.storageProviders.providersList.statusColumn"),
               field: "providerStatusFormatted",
               template: function (item: StorageProviderListItem) {
                  if (item.statusFault && item.statusFault.message) {
                     let moreDetailsText = self.i18n(
                           "StorageUi", "storage.storageProviders.providersList.statusColumn.details");

                     let moreDetailsLinkHtml =
                           "<a " +
                           "class=\"storage-providers-mode-details-link\" " +
                           "vui-sign-post=\"$ctrl.getStatusDetailsSignPostConfig('" + item.id + "')\" " +
                           ">" + moreDetailsText + "</a>";

                     return textRenderer(["providerStatusFormatted"], item) + moreDetailsLinkHtml;
                  } else {
                     return textRenderer(["providerStatusFormatted"], item);
                  }
               },
               filterable: this.CUSTOM_TEXT_FILTER
            },
            {
               title: this.i18n("StorageUi", "storage.storageProviders.providersList.activeColumn"),
               field: "activeStandbyFormatted",
               template: "#:activeStandbyFormatted#",
               filterable: this.CUSTOM_TEXT_FILTER
            },
            {
               title: this.i18n("StorageUi", "storage.storageProviders.providersList.priorityColumn"),
               field: "priorityFormatted",
               template: "#:priorityFormatted#",
               filterable: false
            },
            {
               title: this.i18n("StorageUi", "storage.storageProviders.providersList.urlColumn"),
               field: "urlFormatted",
               template: "#:urlFormatted#",
               filterable: this.CUSTOM_TEXT_FILTER
            },
            {
               title: this.i18n("StorageUi", "storage.storageProviders.providersList.lastSyncTimeColumn"),
               field: "lastSyncTimeFormatted",
               template: "#:lastSyncTimeFormatted#",
               sortable: {
                  compare: function (a:any, b:any) {
                     return self.storageUtil.compareNumericValues(a, b, "lastSyncTime");
                  }
               },
               filterable: false
            },
            {
               title: this.i18n("StorageUi", "storage.storageProviders.providersList.apiVersionColumn"),
               field: "vasaApiVersionFormatted",
               template: "#:vasaApiVersionFormatted#",
               filterable: false
            },
            {
               title: this.i18n("StorageUi", "storage.storageProviders.providersList.certificateExpiryColumn"),
               field: "certificateExpiryTimeFormatted",
               template: function (item: StorageProviderListItem) {
                  if (item.certificateExpiryTimeIcon) {
                     return iconTextRenderer(["certificateExpiryTimeIcon", "certificateExpiryTimeFormatted"], item);
                  }
                  return textRenderer(["certificateExpiryTimeFormatted"], item);
               },
               sortable: {
                  compare: function (a:any, b:any) {
                     return self.storageUtil.compareNumericValues(a, b, "certificateExpiryTime");
                  }
               },
               filterable: false
            }
         ];
      }
   }

   angular.module("com.vmware.vsphere.client.storage")
         .component("storageProvidersList", {
            templateUrl: "storage-ui/resources/storage/views/storageProviders/StorageProvidersList.html",
            bindings: {
               vcId: "<",
               masterDetailsViewContext: "<"
            },
            controller: StorageProvidersList
         });
}
