module cluster_ui {

   import IComponentController = angular.IComponentController;

   class DatastoresUnderApdPdlController implements IComponentController {

      public static $inject = [
         "$scope",
         "dataService",
         "datastoresUnderApdPdlService",
         "navigation"];

      private INACCESSIBLE_DATASTORE_INFO_PROPERTY: string = "inaccessibleDatastoreInfo";

      private objectId: string;
      private gridOptions: any;


      constructor(private $scope: any,
                  private dataService: any,
                  private datastoresUnderApdPdlService: DatastoresUnderApdPdlService,
                  private navigation: any) {

         this.objectId = this.navigation.getRoute().objectId;

         this.gridOptions =
               this.datastoresUnderApdPdlService.getGridOptions();

         this.getData();
      }

      public getData(): void {
         this.dataService.getProperties(
               this.objectId, [this.INACCESSIBLE_DATASTORE_INFO_PROPERTY])
               .then((data: any) => {
                  let inaccessibleDatastoreInfo: any =
                        data && data.inaccessibleDatastoreInfo ?
                        data.inaccessibleDatastoreInfo : [];

                  this.gridOptions.data = inaccessibleDatastoreInfo;
               });

      }
   }

   angular.module("com.vmware.vsphere.client.cluster")
         .controller("DatastoresUnderApdPdlController",
               DatastoresUnderApdPdlController);
}
