/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace dvs_ui {

   import IPromise = angular.IPromise;

   export class DvsAddSpanSessionWizardPages {

      public static $inject = [
         "i18nService",
         "simpleValidationCommit",
         "dvsSpanSessionPropertiesValidator"
      ];

      constructor(private i18nService: any,
                  private simpleValidationCommit: any,
                  private propertiesValidator: DvsSpanSessionPropertiesValidator) {
      }

      /**
       * Creates an array of page definitions for the Add Span Session wizard.
       */
      public createPages(wizardScope: DvsAddSpanSessionWizardScope): WizardPage[] {

         let pages: WizardPage[] = [];

         pages.push({
            id: DvsSpanSessionConstants.PAGE_ID.SELECT_SESSION_TYPE,
            title: this.i18nService.getString(
                  "DvsUi", "AddSpanSessionWizard.sessionType.title"),
            description: this.i18nService.getString(
                  "DvsUi", "AddSpanSessionWizard.sessionType.description"),
            contentUrl: "dvs-ui/resources/dvs/portmirroring/create/views/dvsAddSpanSessionSelectTypePage.html",
            onCommit: this.simpleValidationCommit((): string[] => { return []; }, wizardScope.wizardConfig),
            validationParams: ["model.sessionType"]
         });

         pages.push({
            id: DvsSpanSessionConstants.PAGE_ID.EDIT_PROPERIES,
            title: this.i18nService.getString(
                  "DvsUi", "AddSpanSessionWizard.propertiesPage.title"),
            description: this.i18nService.getString(
                  "DvsUi", "AddSpanSessionWizard.propertiesPage.description"),
            contentUrl: "dvs-ui/resources/dvs/portmirroring/create/views/dvsAddSpanSessionEditPropertiesPage.html",
            onCommit: this.simpleValidationCommit(
                  this.buildPropertiesPageCommitCallback(wizardScope), wizardScope.wizardConfig),
            validationParams: [
               "model.propertiesPageModel.sessionName",
               "model.propertiesPageModel.sessionName",
               "model.propertiesPageModel.enabled",
               "model.propertiesPageModel.mirroredPacketLength",
               "model.propertiesPageModel.mirroredPacketLengthEnabled",
               "model.propertiesPageModel.samplingRate",
               "model.propertiesPageModel.description",
               "model.propertiesPageModel.erspanSupported",
               "model.propertiesPageModel.encapsulationType",
               "model.propertiesPageModel.sessionId",
               "model.propertiesPageModel.preserveOriginalVlan",
               "model.propertiesPageModel.encapsulationVlanId",
               "model.propertiesPageModel.normalTrafficAllowed"
            ]
         });

         pages.push({
            id: DvsSpanSessionConstants.PAGE_ID.SELECT_SOURCE_PORTS,
            title: this.i18nService.getString(
                  "DvsUi", "AddSpanSessionWizard.sourcesPage.title"),
            description: this.i18nService.getString(
                  "DvsUi", "AddSpanSessionWizard.sourcesPage.description"),
            contentUrl: "dvs-ui/resources/dvs/portmirroring/create/views/dvsAddSpanSessionSelectSourcePortsPage.html",
            onCommit: this.simpleValidationCommit((): string[] => { return []; }, wizardScope.wizardConfig),
            validationParams: []
         });

         pages.push({
            id: DvsSpanSessionConstants.PAGE_ID.SELECT_SOURCE_VLANS,
            title: this.i18nService.getString(
                  "DvsUi", "AddSpanSessionWizard.sourcesPage.title"),
            description: this.i18nService.getString(
                  "DvsUi", "AddSpanSessionWizard.sourceVlansPage.descriptionLabel"),
            contentUrl: "dvs-ui/resources/dvs/portmirroring/create/views/dvsAddSpanSessionSelectSourceVlansPage.html",
            onCommit: this.simpleValidationCommit(
                  this.buildSourceVlansPageCommitCallback(wizardScope), wizardScope.wizardConfig),
            validationParams: ["model.selectSourcesPageModel.vlanIdListModel.values"]
         });

         pages.push({
            id: DvsSpanSessionConstants.PAGE_ID.SELECT_DESTINATION_ADDRESSES,
            title: this.i18nService.getString(
                  "DvsUi", "AddSpanSessionWizard.destinationsPage.title"),
            description: this.i18nService.getString(
                  "DvsUi", "AddSpanSessionWizard.destinationsPage.description"),
            contentUrl: "dvs-ui/resources/dvs/portmirroring/create/views/dvsAddSpanSessionSelectDestinationAddressesPage.html",
            onCommit: this.simpleValidationCommit(
                  this.buildDestinationsPageCommitCallback(wizardScope), wizardScope.wizardConfig),
            validationParams: ["model.selectDestinationsPageModel.ipAddressesListModel.values"]
         });

         pages.push({
            id: DvsSpanSessionConstants.PAGE_ID.SELECT_DESTINATION_PORTS,
            title: this.i18nService.getString(
                  "DvsUi", "AddSpanSessionWizard.destinationsPage.title"),
            description: this.i18nService.getString(
                  "DvsUi", "AddSpanSessionWizard.destinationsPage.description"),
            contentUrl: "dvs-ui/resources/dvs/portmirroring/create/views/dvsAddSpanSessionSelectDestinationPortsPage.html",
            onCommit: this.simpleValidationCommit(
                  this.buildDestinationsPageCommitCallback(wizardScope), wizardScope.wizardConfig),
            validationParams: []
         });

         pages.push({
            id: DvsSpanSessionConstants.PAGE_ID.SELECT_DESTINATION_UPLINKS,
            title: this.i18nService.getString(
                  "DvsUi", "AddSpanSessionWizard.destinationsPage.title"),
            description: this.i18nService.getString(
                  "DvsUi", "AddSpanSessionWizard.destinationsPage.description"),
            contentUrl: "dvs-ui/resources/dvs/portmirroring/create/views/dvsAddSpanSessionSelectDestUplinkPage.html",
            onCommit: this.simpleValidationCommit(
                  this.buildDestinationsPageCommitCallback(wizardScope), wizardScope.wizardConfig),
            validationParams: []
         });

         pages.push({
            id: DvsSpanSessionConstants.PAGE_ID.READY_TO_COMPLETE,
            title: this.i18nService.getString(
                  "DvsUi", "AddSpanSessionWizard.readyToCompletePage.title"),
            description: this.i18nService.getString(
                  "DvsUi", "AddSpanSessionWizard.readyToCompletePage.description"),
            contentUrl: "dvs-ui/resources/dvs/portmirroring/create/views/dvsAddSpanSessionReadyToCompletePage.html",
            onCommit: this.simpleValidationCommit((): string[] => { return []; }, wizardScope.wizardConfig),
            validationParams: []
         });

         return pages;
      }

      private buildPropertiesPageCommitCallback(wizardScope: DvsAddSpanSessionWizardScope): Function {
         return (): string[]|IPromise<string[]> => {
            wizardScope.model.pageValidators
                  [DvsSpanSessionConstants.PAGE_ID.EDIT_PROPERIES].validate();

            let pageModel: DvsSpanSessionPropertiesPageModel =
                  wizardScope.model.propertiesPageModel;
            let errors: string[] = pageModel.errors
                        ? pageModel.errors.slice()
                        : [];

            let descriptionError: string | null =
                  this.propertiesValidator.getDescriptionError(
                        wizardScope.model.propertiesPageModel.description);

            if (descriptionError) {
               errors.push(descriptionError);
            }

            if (errors.length > 0) {
               return errors;
            }

            wizardScope.busy = true;

            return this.propertiesValidator.getNameServerError(
                  wizardScope.model.dvsUrn,
                  wizardScope.model.propertiesPageModel.sessionName)
                  .then((error: string): string[] => {
                     wizardScope.busy = false;
                     return error ? [error] : [];
                  });
         };
      }

      private buildDestinationsPageCommitCallback(wizardScope: DvsAddSpanSessionWizardScope): Function {
         return (): string[] => {
            let pageModel: DvsSpanSessionSelectDestinationsPageModel =
                  wizardScope.model.selectDestinationsPageModel;

            if (wizardScope.model.sessionType ===
                  DvsSpanSessionConstants.SESSION_TYPE.L3_SOURCE) {
               wizardScope.model.pageValidators
                     [DvsSpanSessionConstants.PAGE_ID.SELECT_DESTINATION_ADDRESSES].validate();

               return pageModel.ipAddressesListModel.errors
                     ? pageModel.ipAddressesListModel.errors.slice()
                     : [];
            } else if (wizardScope.model.sessionType ===
                  DvsSpanSessionConstants.SESSION_TYPE.DV_PORT_MIRROR) {

               let sourcePorts: PortData[] =
                     wizardScope.model.selectSourcesPageModel.portsListModel.ports;

               let duplicatePorts: string[] =
                     pageModel.portsListModel.ports.filter((data: PortData): boolean => {
                        return sourcePorts.some((sourcePort: PortData): boolean => {
                           return sourcePort.id === data.id;
                        });
                     }).map((data: PortData): string => {
                        return data.id;
                     }) || [];

               if (!duplicatePorts.length) {
                  return [];
               } else if (duplicatePorts.length === 1) {
                  return [
                        this.i18nService.getString(
                              "DvsUi",
                              "SpanSession.error.alreadyUsedSourcePort",
                              duplicatePorts[0])
                  ];
               } else {
                  return [
                        this.i18nService.getString(
                              "DvsUi",
                              "SpanSession.error.alreadyUsedSourcePorts",
                              duplicatePorts.join(", "))
                  ];
               }
            }

            return [];
         };
      }

      private buildSourceVlansPageCommitCallback(wizardScope: DvsAddSpanSessionWizardScope): Function {
         return (): string[] => {
            let pageModel: DvsSpanSessionSelectSourcesPageModel =
                  wizardScope.model.selectSourcesPageModel;

            wizardScope.model.pageValidators
                  [DvsSpanSessionConstants.PAGE_ID.SELECT_SOURCE_VLANS].validate();

            return pageModel.vlanIdListModel.errors
                  ? pageModel.vlanIdListModel.errors.slice()
                  : [];
         };
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .service("dvsAddSpanSessionWizardPages", DvsAddSpanSessionWizardPages);
}
