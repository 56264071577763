/* Copyright 2018 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace storage_ui {

   import IPromise = angular.IPromise;
   import IQService = angular.IQService;

   export class StreamVmdkService {

      public static $inject = ["i18nService", "clarityModalService", "$q"];

      constructor(private i18nService: any,
                  private clarityModalService: any,
                  private $q: IQService) {
      }

      /**
       * Displays Download/Upload VMDK Dialog
       */
      public openStreamVmdkWarningDialog(selectedFile?: any): IPromise<any> {
         let deferred: any = this.$q.defer();
         const modalOptions: any = {
            contentTemplate: "storage-ui/resources/storage/views/manage/files/StreamVmdkWarningDialog.html",
            defaultButton: "submit",
            dialogData: {},
            onSubmit: () => {
               deferred.resolve(selectedFile);
               return true;
            },
            onCancel: () => {
               deferred.resolve(null);
               return false;
            }
         };

         if (selectedFile) {
            // download specific
            modalOptions.title = this.i18nService.getString(
                  "StorageUi", "vsanVmdkDownloadWarningDialog.title");
            modalOptions.dialogData.warningDialogText = this.i18nService.getString(
                  "StorageUi", "vsanVmdkDownloadWarningDialog.text");
            modalOptions.submitButtonTitle = this.i18nService.getString(
                  "StorageUi", "vsanVmdkDownloadWarningDialog.submitButtonTitle");
         } else {
            // upload specific
            modalOptions.title = this.i18nService.getString(
                  "StorageUi", "vsanVmdkUploadWarningDialog.title");
            modalOptions.dialogData.warningDialogText = this.i18nService.getString(
                  "StorageUi", "vsanVmdkUploadWarningDialog.text");
            modalOptions.submitButtonTitle = this.i18nService.getString(
                  "StorageUi", "vsanVmdkUploadWarningDialog.submitButtonTitle");
         }

         this.clarityModalService.openOkCancelModal(modalOptions);

         return deferred.promise;
      }
   }

   angular.module("com.vmware.vsphere.client.storage")
         .service("streamVmdkService", StreamVmdkService);
}
