namespace storage_ui {

   import LayoutAreaChartService = storage_ui.LayoutAreaChartService;

   /**
    * Draws selection markers (blue~ish rectangles on top of the area chart)
    */
   export class LayoutAreaSelectionService {
      public static $inject = ["i18nService", "storageUtil", "layoutAreaChartService"];

      /**
       * "storage-partition-layout-selection-mask" - mask identifier class.
       * all the selection rectangles should have it, in order to be removable
       *
       * the class itself does not have a css definition of any kind
       * **/
      public static get SELECTION_MASK_CLASS(): string {
         return "storage-partition-layout-selection-mask";
      };

      constructor(private i18nService: any,
                  private storageUtil: any) {
      }

      /***
       * Removes the selection markers from chart
       * @param view - Element containing chart
       */
      public clearMask(view: any): void {
         let maskElement = view.find("." + LayoutAreaSelectionService.SELECTION_MASK_CLASS);
         if (maskElement) {
            maskElement.remove();
            /*removes all previously rendered mask rectangles*/
         }
      }

      public highlightSelection(chart: any, view: any, vmfsExtent: any, sizeMb?: number): void {
         let self: LayoutAreaSelectionService = this;
         this.clearMask(view);

         if (_.isArray(vmfsExtent)) {
            // 1. calculate overall volume
            let total: number = 0;
            _.forEach(vmfsExtent, (item) => {
               item.size = LayoutAreaChartService.calcSize(item);
               total += item.size;
            });
            // 2. Populate selection rectangles one by one
            let x: number = 0;
            _.forEach(vmfsExtent, (item) => {
               let startMb: number = this.lbaAddressMb(item.start);
               let endMb: number = this.lbaAddressMb(item.end);
               let fillRightEdgeMb = 0;
               if (sizeMb >= x && sizeMb <= x + item.size) { /*inside of current rect*/
                  fillRightEdgeMb = sizeMb - x;
               } else if (sizeMb > x + item.size) { /*fill whole rectangle*/
                  fillRightEdgeMb = item.size;
               }
               self.maskRect(chart, startMb, endMb, fillRightEdgeMb);
               x += endMb - startMb;
            });
         } else {
            let startMb: number = this.lbaAddressMb(vmfsExtent.start);
            let endMb: number = this.lbaAddressMb(vmfsExtent.end);
            this.maskRect(chart, startMb, endMb, sizeMb);
         }
      };

      /**
       * "masks" the section passed where:
       * @startMb - section start in megabytes
       * @endMb - section end in megabytes
       * @size - the width of the filled portion
       * **/
      private maskRect(chart: any, startMb: number, endMb: number, sizeMb?: number): void {
         let totalX = chart.userOptions.totalX;
         let partitions = chart.userOptions.partitions;
         let startPx: number = chart.chartWidth / totalX * this.mbToUnits(startMb, partitions);
         let endPx: number = chart.chartWidth / totalX * this.mbToUnits(endMb, partitions);
         let width = (sizeMb / (endMb - startMb)) * (endPx - startPx);

         // outline
         chart.renderer.rect(startPx, 10, endPx - startPx, 80, 0).attr({
            "stroke-width": 6,
            stroke: "rgb(15,172,201)",
            fill: "rgba(255,255,255,0)",
            zIndex: 3,
            class: LayoutAreaSelectionService.SELECTION_MASK_CLASS
         }).add();

         // fill
         chart.renderer.rect(startPx, 10, width, 80, 0).attr({
            "stroke-width": 0,
            stroke: "rgb(15,172,201)",
            fill: "rgba(15,172,201, 0.6)",
            zIndex: 3,
            class: LayoutAreaSelectionService.SELECTION_MASK_CLASS
         }).add();
      }

      /**
       * While the API operates "real" megabytes, since we have to show tiny partitions there"s a MIN_WIDTH,
       * and a partition with MIN_WIDTH affects all the partitions after it, shifting REAL layout.
       *
       * This methods accepts "real" layout position in megabytes and returns the "shifted" value for it
       **/
      private mbToUnits(realMB:number, partitions:any[]): number {
         let offsetMb = 0;

         for (let i = 0; i < partitions.length; i++) {
            let startMB = this.lbaAddressMb(partitions[i].start);
            let endMB = this.lbaAddressMb(partitions[i].end);
            if (realMB <= endMB && realMB >= startMB) {
               offsetMb += realMB - startMB;
               break;
            } else {
               offsetMb += partitions[i].visibleSize;
            }
         }
         return offsetMb;
      }

      private lbaAddressMb(lba:any) {
         return this.storageUtil.lbaToBytes(lba) / Math.pow(1024, 2);
      }
   }

   angular.module("com.vmware.vsphere.client.storage")
         .service("layoutAreaSelectionService", LayoutAreaSelectionService);
}
