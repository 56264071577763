(function() {
   'use strict';
   angular.module('com.vmware.vsphere.client.host')
         .controller('editSystemSwapSettingsController',
               EditSystemSwapSettingsController);

   EditSystemSwapSettingsController.$inject = [
      '$scope',
      'dataService',
      'systemSwapConstants', 'mutationService'];

   function EditSystemSwapSettingsController($scope,
                                             dataService,
                                             systemSwapConstants,
                                             mutationService) {
      var self = this;
      var hostId = $scope.modalOptions.dialogData.availableTargets[0];
      self.systemSwapSettings = {
         canUseDatastore: false,
         canUseHostCache: false,
         canUseHostSwapFile: false,
         selectedDatastore: null
      };

      $scope.modalOptions.onSubmit = updateSystemSwapConfiguration;

      getSystemSwapData();

      function getSystemSwapData() {
         dataService.getProperties(hostId,
               ['config.systemSwapConfiguration', 'availableDatastoreNames'])
               .then(function(data) {
                  self.datastoreNames = data.availableDatastoreNames;
                  initCheckboxValues(data['config.systemSwapConfiguration']);
               });
      }

      function updateSystemSwapConfiguration() {
         mutationService.apply(hostId,
               'com.vmware.vim.binding.vim.host.SystemSwapConfiguration',
               createSystemSwapConfiguration()
         );

         return true;
      }

      function createSystemSwapConfiguration() {
         return {
            '_type': 'com.vmware.vim.binding.vim.host.SystemSwapConfiguration',
            option: getOptions()
         };
      }

      function getOptions() {
         var arrayToReturn = [];
         if (self.systemSwapSettings.canUseDatastore) {
            var option = createSwapSystemOption(systemSwapConstants.systemSwapType.DATASTORE,
                  arrayToReturn.length
            );
            option.datastore = self.systemSwapSettings.selectedDatastore;
            arrayToReturn.push(option);
         }

         if (self.systemSwapSettings.canUseHostCache) {
            arrayToReturn.push(
                  createSwapSystemOption(systemSwapConstants.systemSwapType.HOST_CACHE,
                        arrayToReturn.length
                  )
            );
         }

         if (self.systemSwapSettings.canUseHostSwapFile) {
            arrayToReturn.push(
                  createSwapSystemOption(systemSwapConstants.systemSwapType.HOST_SWAP_LOCATION,
                        arrayToReturn.length
                  )
            );
         }

         if (arrayToReturn.length === 0) {
            arrayToReturn.push(
                  createSwapSystemOption(systemSwapConstants.systemSwapType.DISABLED,
                        arrayToReturn.length
                  )
            );
         }
         return arrayToReturn;
      }

      function createSwapSystemOption(type, key) {
         return {
            '_type': type,
            key: key
         };
      }

      function initCheckboxValues(systemSwapConfig) {
         if (systemSwapConfig === null) {
            return;
         }
         _.each(systemSwapConfig.option, function(option) {
            switch (option['_type']) {
               case systemSwapConstants.systemSwapType.DATASTORE:
                  self.systemSwapSettings.canUseDatastore = true;
                  self.systemSwapSettings.selectedDatastore = option.datastore;
                  break;
               case systemSwapConstants.systemSwapType.HOST_CACHE:
                  self.systemSwapSettings.canUseHostCache = true;
                  break;
               case systemSwapConstants.systemSwapType.HOST_SWAP_LOCATION:
                  self.systemSwapSettings.canUseHostSwapFile = true;
                  break;
               default:
                  break;
            }
         });

         if (!self.systemSwapSettings.selectedDatastore && !_.isEmpty(self.datastoreNames)) {
            self.systemSwapSettings.selectedDatastore = self.datastoreNames[0];
         }
      }
   }
})();
