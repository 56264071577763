module hostprofile_ui {

    angular.module("com.vmware.vsphere.client.hostprofile")
        .constant("HostProfileConstants", {
            complianceStatus: {
                nonCompliant: "nonCompliant",
                unknown: "unknown",
                compliant: "compliant"
            },
            COMPOSITE_EXCEPTION: "com.vmware.vise.core.model.CompositeException",
            HOST_CUSTOMIZATION_IMPORT_EXCEPTION: "com.vmware.vsphere.client.hostprofile.data.HostCustomizationImportException",
            INVALID_PROFILE_REFERENCE_HOST: "com.vmware.vim.binding.vim.fault.InvalidProfileReferenceHost",
            BAD_REQUEST_EXCEPTION: "com.vmware.vise.search.transport.BadRequestException"
        });
}
