namespace storage_ui {

   import IComponentController = angular.IComponentController;

   interface PolicyOption {
      id: string;
      label: string;
   }

   export class StorageDeviceMultipathingPolicyDialogController implements IComponentController {

      public static $inject = ["$scope", "i18nService", "dataService",
         "hostStorageConstants", "storageUtil", "$timeout"];

      private hostId: string;
      private deviceId: string;

      public policyOptions: PolicyOption[];
      public loading: boolean;
      public dialogData: any;
      public paths: any[];

      public isDisabled: Function;

      constructor(private $scope: ModalDialogScope,
                  private i18nService: any,
                  private dataService: any,
                  private hostStorageConstants: any,
                  private storageUtil: any,
                  private $timeout: any) {

         this.hostId = $scope.modalOptions.dialogData.hostId;
         this.deviceId = $scope.modalOptions.dialogData.deviceId;

         this.dialogData = $scope.modalOptions.dialogData;

         this.requestData();
      }

      private requestData(): void {

         const self: StorageDeviceMultipathingPolicyDialogController = this;
         self.isDisabled = function () {
            return !(self.dialogData.selectedPolicy &&
            self.storageUtil.isFixedPolicy(self.dialogData.selectedPolicy.id));
         };
         self.loading = true;

         self.dataService.getProperties(self.hostId, [
            self.hostStorageConstants.STORAGE_DEVICE_MULTIPATHING_INFO_WITH_PATHS_PROP,
            self.hostStorageConstants.PATH_SELECTION_POLICY_OPTIONS_PROP
         ], {
            propertyParams: [{
               propertyName: self.hostStorageConstants.STORAGE_DEVICE_MULTIPATHING_INFO_WITH_PATHS_PROP,
               parameterType: "java.lang.String",
               parameter: self.deviceId
            }]
         }).then(function (response: any) {

                  const policyOptions: Array<any> = response &&
                        response[self.hostStorageConstants.PATH_SELECTION_POLICY_OPTIONS_PROP];

                  if (policyOptions && policyOptions.length) {

                     self.policyOptions = _.map(policyOptions, function (policyOption): PolicyOption {
                        return {
                           id: policyOption.policy.key,
                           label: self.storageUtil.getPathSelectionPolicyNameByKey(
                                 policyOption.policy.key, policyOptions)
                        };
                     });

                  } else {
                     self.policyOptions = _.map([
                        self.hostStorageConstants.storagePolicies.FIXED_PATH,
                        self.hostStorageConstants.storagePolicies.MOST_RECENTLY_USED_PATH,
                        self.hostStorageConstants.storagePolicies.ROUND_ROBIN
                     ], function (policyKey): PolicyOption {
                        return {
                           id: policyKey,
                           label: self.storageUtil.formatPathSelectionPolicyLabel(policyKey)
                        };
                     });
                  }

                  const deviceMultipathingInfo: any = response &&
                        response[self.hostStorageConstants.STORAGE_DEVICE_MULTIPATHING_INFO_WITH_PATHS_PROP];

                  self.dialogData.logicalUnitId =
                        deviceMultipathingInfo && deviceMultipathingInfo.logicalUnitId;

                  if (deviceMultipathingInfo &&
                        deviceMultipathingInfo.pathSelectionPolicy &&
                        self.storageUtil.isFixedPolicy(deviceMultipathingInfo.pathSelectionPolicy.policy)) {

                     self.dialogData.selectedPath = _.find(deviceMultipathingInfo.paths,
                           function (path: any) {
                              return path.name === deviceMultipathingInfo.pathSelectionPolicy.prefer;
                           });
                  }
                  const selectedPolicyKey: any = deviceMultipathingInfo
                        && deviceMultipathingInfo.pathSelectionPolicy
                        && deviceMultipathingInfo.pathSelectionPolicy.policy;

                  self.dialogData.selectedPolicy = _.find(self.policyOptions, function (policyOption: PolicyOption) {
                           return policyOption.id === selectedPolicyKey;
                        }) || self.policyOptions[0];

                  self.paths = deviceMultipathingInfo && deviceMultipathingInfo.paths;

                  self.focusPathSelectionPolicySelect();

               },
               function (error: any) {

                  let errorMsg: string = self.i18nService.getString("StorageUi", "storage.failedToRetrieveDataError");
                  if (error && error.data && error.data.message) {
                     errorMsg += " " + error.data.message;
                  }
                  self.dialogData.errorApi.showError(errorMsg);
               }
         ).finally(function () {
            self.loading = false;
         });

      }

      private focusPathSelectionPolicySelect(): void {
         const pathSelectionPolicySelect = angular.element("#storage-device-multipathing-policy-select");
         if (pathSelectionPolicySelect && pathSelectionPolicySelect[0]) {
            this.$timeout(() => {
               pathSelectionPolicySelect[0].focus();
            }, 0);
         }
      }
   }

   angular.module("com.vmware.vsphere.client.storage")
         .controller("StorageDeviceMultipathingPolicyDialogController",
               StorageDeviceMultipathingPolicyDialogController);
}
