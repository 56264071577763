/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace dvs_ui {

   export class DvpgManagePolicyModel {
      // TODO dgerenska: fix the type of the availability property model
      public availability: any;

      public isSecurityPolicySelected: boolean;
      public isTrafficShapingPolicySelected: boolean;
      public isVlanPolicySelected: boolean;
      public isFailoverPolicySelected: boolean;
      public isResAllocPolicySelected: boolean;
      public isTrafficFilterPolicySelected: boolean;
      public isMonitoringPolicySelected: boolean;
      public isMiscPolicySelected: boolean;
   }
}
