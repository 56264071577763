module h5_vm {

    class FtReadyToCompleteController implements angular.IController {

        static $inject = [
            "$rootScope",
            "$scope",
            "i18nService",
            "ftVmService",
            "mutationService",
            "defaultUriSchemeUtil",
            "vxPropertyViewService",
            "vmFtConstants"
        ];

        private selectedHost: any;
        private selectedDatastore: any;
        private availableDevices: any;
        private readyToCompletePageInfo: any;
        private selectedDatastores: {[key: string]: any};

        constructor(private $rootScope: any,
              private $scope: any,
              private i18nService: any,
              private ftVmService: FtVmService,
              private mutationService: any,
              private defaultUriSchemeUtil: any,
              private vxPropertyViewService: any,
              private vmFtConstants: any) {

            this.selectedDatastore = $scope.manager.getSelectedDatastore();
            this.selectedHost = $scope.manager.getSelectedHost();
            let devices: any = this.$scope.manager.getDevices();
            this.availableDevices = this.ftVmService.getAvailableDevices(devices);
            this.selectedDatastores = $scope.manager.getSelectedDatastores();
            this.readyToCompletePageInfo = this.getPropertyViewData();

        }


        private getPropertyViewData(): any {
            let builder: any = this.vxPropertyViewService.createPropertyViewBuilder();
            let sectionBuilder: any = builder
                .category("")
                .section("");
            sectionBuilder.propertyBuilder()
                .keyBuilder()
                .text(this.i18nService.getString("VmUi", "turnOnFtWizard.summary.hostLabel"))
                .exit()
                .valueBuilder()
                .text(this.selectedHost.name);
            sectionBuilder.propertyBuilder()
                .keyBuilder()
                .text(this.i18nService.getString("VmUi", "turnOnFtWizard.summary.vmxFileDatastoreLabel"))
                .exit()
                .valueBuilder()
                .text(this.selectedDatastores[this.vmFtConstants.fileTypes.CONFIGURATION].name);
            sectionBuilder.propertyBuilder()
                .keyBuilder()
                .text(this.i18nService.getString("VmUi", "turnOnFtWizard.summary.metainfDatastoreLabel"))
                .exit()
                .valueBuilder()
                .text(this.selectedDatastores[this.vmFtConstants.fileTypes.TIE_BREAKER].name);

            _.each(this.availableDevices, (device: any) => {
                sectionBuilder.propertyBuilder()
                    .keyBuilder()
                    .text(this.i18nService.getString("VmUi",
                        "turnOnFtWizard.summary.virtualDisksFormat",
                        device.deviceInfo.label))
                    .exit()
                    .valueBuilder()
                    .text(this.selectedDatastores[
                                this.vmFtConstants.fileTypes.VIRTUAL_DISK + device.key].name);
            });

            return builder.build();
        }
    }

    angular.module("com.vmware.vsphere.client.vm")
        .controller("FtReadyToCompleteController", FtReadyToCompleteController);
}

