(function () {
   'use strict';

   angular
      .module('com.vmware.vsphere.client.vm')
      .factory('datastoreRecommendationsDatagridOptionsService',
         datastoreRecommendationsDatagridOptionsService
      );

   datastoreRecommendationsDatagridOptionsService.$inject = [
      'i18nService',
      'vuiConstants',
      'defaultUriSchemeUtil',
      'numberFormatterService'
   ];

   function datastoreRecommendationsDatagridOptionsService(
      i18nService,
      vuiConstants,
      defaultUriSchemeUtil,
      numberFormatterService
   ) {

      var i18n = i18nService.getString.bind(i18nService, 'VmUi');

      return {
         getDatagridOptions: getDatagridOptions
      };

      function getDatagridOptions(dialogData, recommendation) {
         return {
            dataBound: recommendation ? selectRowOfSelectedRecommendation(recommendation) : selectFirstRow,
            columnDefs: [
               {
                  displayName: i18n('RecommendationsList.recommendationColumnHeaderText'),
                  template: kendo.template("#:reasonText#" +
                     "<ul class='datastore-recommendation-column'> " +
                     "  # for (var i = 0; i < actions.length; i++) { #" +
                     "     <li ng-non-bindable>" +
                     "         <span class=#:actions[i].icon#></span>" +
                     "         <span>#:actions[i].text#</span>" +
                     "     </li>" +
                     "   # } # " +
                     "</ul>"),
                  field: 'reasonText',
                  width: '80%'
               },
               {
                  displayName: i18n('dsRecommendation.spaceUtilDstBeforeColumnHeaderLabel'),
                  field: 'spaceUtilBefore',
                  width: 125,
                  template: "<ul class='datastore-recommendation-usage-column'> " +
                  "  # for (var i = 0; i < actions.length; i++) { #" +
                  "     <li>#:actions[i].spaceUtilBefore#</li>" +
                  "   # } # " +
                  "</ul>"
               },
               {
                  displayName: i18n('dsRecommendation.spaceUtilDstAfterColumnHeaderLabel'),
                  field: 'spaceUtilAfter',
                  width: 125,
                  template: "<ul class='datastore-recommendation-usage-column'> " +
                  "  # for (var i = 0; i < actions.length; i++) { #" +
                  "     <li>#:actions[i].spaceUtilAfter#</li>" +
                  "   # } # " +
                  "</ul>"
               },
               {
                  displayName: i18n('dsRecommendation.ioLatencyDstBeforeColumnHeaderLabel'),
                  field: 'ioLatencyBefore',
                  width: 125,
                  template: "<ul class='datastore-recommendation-usage-column'> " +
                  "  # for (var i = 0; i < actions.length; i++) { #" +
                  "     <li>#:actions[i].ioLatencyBefore#</li>" +
                  "   # } # " +
                  "</ul>"
               }
            ],
            sortMode: vuiConstants.grid.sortMode.SINGLE,
            selectionMode: vuiConstants.grid.selectionMode.SINGLE,
            selectedItems: [],
            data: getFormattedRecommendations(dialogData),
            resizable: true,
            searchable: false,
            height: 350
         };
      }

      /* --- Private --- */

      function formatActions(actions, dialogData) {
         var newVmName = dialogData.newVmName;
         var possibleDatastores = dialogData.possibleDatastores;

         var actionsSortedByAscendingDiskId = _.sortBy(actions, function (action) {
            if (action.relocateSpec.disk) {
               return action.relocateSpec.disk.diskId;
            } else {
               return 0;
            }
         });

         return _.map(actionsSortedByAscendingDiskId, function (action, index) {
            var dsId = action.destination;

            var datastoreName = possibleDatastores[defaultUriSchemeUtil.getVsphereObjectId(dsId)];

            var text, icon;
            if (action.relocateSpec.disk) {
               var diskName = '"' + i18n('VmDeviceManager.NewHardDisk') + ' ' + index + '"';

               text = i18n('dsRecommendation.storagePlacementActionHD',
                  '"' + newVmName + '"',
                  diskName,
                  '"' + datastoreName + '"'
               );
               icon = "vx-icon-vmDisk";
            } else {
               text = i18n('dsRecommendation.storagePlacementActionCF',
                  '"' + newVmName + '"',
                  '"' + datastoreName + '"'
               );
               icon = "vx-icon-vmSecondaryFT";
            }

            var formatCfg = {
               fractionSize: 1,
               trimTrailingZeros: true
            };

            return {
               icon: icon,
               text: text,
               spaceUtilBefore: numberFormatterService.format(action.spaceUtilBefore, formatCfg),
               spaceUtilAfter: numberFormatterService.format(action.spaceUtilAfter, formatCfg),
               ioLatencyBefore: numberFormatterService.format(action.ioLatencyBefore, formatCfg),
               destination: action.destination
            };
         });
      }

      function selectFirstRow() {
         var grid = $("#recommendations > div").data("kendoGrid");
         if (grid) {
            grid.select("tr:first");
         }
      }

      function selectRowOfSelectedRecommendation(recommendation) {
         return function() {
            var selector = "tr:contains('" + recommendation.reasonText + "')";
            var grid = $("#recommendations > div").data("kendoGrid");
            if (grid) {
               grid.select(selector);
            }
         };
      }

      function getFormattedRecommendations(dialogData) {
         var recommendations = dialogData.recommendations;
         return _.map(recommendations, function (rec, index) {
            var actions = formatActions(rec.action, dialogData);
            return {
               reasonText: i18n('dsRecommendation.recommendationText', (index + 1), rec.reasonText),
               actions: actions,
               spec: rec,
               spaceUtilBefore: 2,
               spaceUtilAfter: 2,
               ioLatencyBefore: 2,
               key: rec.key
            };
         });
      }
   }

})();
