namespace h5_vm {
   class GosUserMappingWrapper {
      public vmId: string;

      constructor() {
      }
   }

   angular.module("com.vmware.vsphere.client.vm").component("gosUserMappingWrapper", {
      bindings: {
         vmId: "<"
      },
      controller: GosUserMappingWrapper,
      template: `
          <vsc-guestos-user-mapping-container [vm-id]="$ctrl.vmId"></vsc-guestos-user-mapping-container>`
   });
}
