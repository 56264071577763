(function() {
   'use strict';

   /**
    * Host edit time configuration controller.
    */
   angular.module('com.vmware.vsphere.client.host')
         .controller('EditTimeConfigurationController', EditTimeConfigurationController);

   EditTimeConfigurationController.$inject =
         ['$scope', 'dataService', 'mutationService', 'editTimeConfigurationService',
         'i18nService', 'vuiConstants'];

   function EditTimeConfigurationController($scope, dataService,
         mutationService, timeConfigService, i18nService, vuiConstants) {
      var self = this;

      self.objectId = $scope.modalOptions.dialogData.availableTargets[0];
      self.startService = false;

      setPageBusy(true);
      $scope.modalOptions.isAlertClosed = true;
      $scope.modalOptions.defaultButton = 'submit';
      $scope.modalOptions.onSubmit = submit;
      self.policyDropdownList = timeConfigService.getPolicyDropdownList();

      function submit() {
         $scope.modalOptions.alerts = [];
         $scope.modalOptions.isAlertClosed = true;

         self.initDataObject.date = moment(self.initDataObject.dateObj).format('YYYY-MM-DD');
         var timeConfigSpec = timeConfigService.createTimeConfigSpec(
               $scope.modalOptions,
               self.initDataObject,
               self.startService);

         if (self.startService && !self.initDataObject.ntpServers) {
            $scope.modalOptions.alerts.push({
               type: vuiConstants.validationBanner.type.ERROR,
               text: i18nService.getString("HostUi", "timeConfig.startWarning")
            });
            $scope.modalOptions.isAlertClosed = false;
         }

         if ($scope.modalOptions.alerts.length === 0) {
            mutationService.apply(
                  self.objectId,
                  'com.vmware.vsphere.client.host.config.TimeConfigSpec',
                  timeConfigSpec
            );
            return true;
         }
      }

      getViewData();

      function getViewData() {
         dataService
               .getData(
                     self.objectId,
                     'com.vmware.vsphere.client.h5.host.model.TimeConfigData'
               ).then(function(data) {
            self.data = data;
            if (self.data) {
               self.initDataObject = timeConfigService.initData(self.data);
               self.initDataObject.dateObj = moment(self.data.currentTimeInMillis).toDate();
            }
            setPageBusy(false);
         });
      }

      function setPageBusy(isBusy) {
         $scope.modalOptions.submitDisabled = isBusy;
         self.loading = isBusy;
      }
   }
})
();
