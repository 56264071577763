/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace h5_vm {

   import IQService = angular.IQService;
   import DatastoreMirrorsInfo = com.vmware.vsphere.client.vm.api.DatastoreMirrorsInfo;

   export class SelectComputeResourcePageComponent {
      public templateUrl: string;
      public controller: any;
      public controllerAs: string;
      public bindings: any;

      constructor() {
         this.templateUrl = "vm-ui/resources/vm/views/migrationWizard/pages/selectComputeResourcePageComponent.html";
         this.controller = SelectComputeResourcePageController;
         this.controllerAs = "ctrl";
         this.bindings = {
            migrationWizardManager: "<",
            wizardConfig: "<",
            pageModel: "<",
            destinationTarget: "<"
         };
      }
   }

   export class SelectComputeResourcePageController {

      public static $inject: string[] = [
         "$q",
         "$element",
         "$timeout",
         "$scope",
         "dataService",
         "i18nService",
         "defaultUriSchemeUtil",
         "compatibilityResultsPresenter",
         "managedEntityConstants",
         "vuiConstants",
         "wizardPageService",
         "vmMigrateStretchedStorageService"
      ];

      public pageModel: SelectComputeResourcePageModel;
      public migrationWizardManager: MigrationWizardManager;
      public wizardConfig: any;
      public self: any;
      public destinationTarget: string;
      public compatibilityTitle: string;
      public objSelectorConf: any;
      public compatibilityContents: any;

      constructor(
            private $q: IQService,
            private $element: any,
            private $timeout: any,
            private $scope: any,
            private dataService: any,
            private i18nService: any,
            private defaultUriSchemeUtil: any,
            private compatibilityResultsPresenter: any,
            private managedEntityConstants: any,
            private vuiConstants: any,
            private wizardPageService: any,
            private vmMigrateStretchedStorageService: {newInstance(): VmMigrateStretchedStorageService}
      ) {
      }

      public $onInit() {
         this.compatibilityTitle = this.i18nService.getString("VmUi",
               "MigrationWizard.resourceListPage.compatibility");
         this.pageModel = this.migrationWizardManager.computeResourcePageModel;

         this.initializeObjectSelector();

         if (!this.pageModel.stretchedStorageService) {
            this.pageModel.stretchedStorageService =
                  this.vmMigrateStretchedStorageService.newInstance();
         }
      }

      private initializeObjectSelector() {
         let hostsListConfig: any = {
            label: this.i18nService.getString("VmUi", "MigrationWizard.resourceListPage.hosts"),
            listViewId: "vsphere.core.host.list",
            dataModels: ["HostSystem"],
            filterId: "vmMigrationHostFilter",
            filterParams: this.pageModel.vms,
            useLazyInstantiation: true
         };

         let clusterListConfig: any = {
            label: this.i18nService.getString("VmUi", "MigrationWizard.resourceListPage.clusters"),
            listViewId: "vsphere.core.cluster.list",
            dataModels: ["ClusterComputeResource"],
            filterId: "vmMigrationClusterFilter",
            filterParams: this.pageModel.vms,
            useLazyInstantiation: true
         };

         let resourcePoolsListConfig: any = {
            label: this.i18nService.getString("VmUi", "MigrationWizard.resourceListPage.resourcePools"),
            listViewId: "vsphere.core.resourcePool.list",
            dataModels: ["ResourcePool"],
            filterId: "vmMigrationResourcePoolFilter",
            filterParams: this.pageModel.vms,
            useLazyInstantiation: true
         };

         let vAppsListConfig: any = {
            label: this.i18nService.getString("VmUi", "MigrationWizard.resourceListPage.vapps"),
            listViewId: "vsphere.core.vApp.list",
            dataModels: ["VirtualApp"],
            filterId: "vmMigrationVirtualAppFilter",
            filterParams: this.pageModel.vms,
            useLazyInstantiation: true
         };

         // We have a user preselection in place - override other preselection targets
         let preselectedId: any = this.migrationWizardManager.getDestinationComputeResourceUid();
         preselectedId = preselectedId ||
               this.pageModel.initialComputeResource ||
               this.destinationTarget;

         this.objSelectorConf = {
            contextObject: this.pageModel.vms[0], // TODO vaivanov: Handle multiple VMs case!?
            multipleSelect: false,
            listTabConfig: {
               selectedTabIndex: this.calculateInitialTab(preselectedId),
               listConfig: [hostsListConfig, clusterListConfig, resourcePoolsListConfig,
                  vAppsListConfig]
            },
            tabStyle: this.vuiConstants.tabs.style.PILLS,
            selectedObjects: this.calculateSelectedObjects(preselectedId),
            onSelectionChanged: (newItems: any) => {
               this.onSelectionChanged(newItems);
            }
         };

         this.$timeout(() => {
            const firstInput = this.$element[0].querySelector(
                  ".vm-migrate-compute-resource button.active");
            if (firstInput) {
               firstInput.focus();
            }
         }, 0);
      }


      private onSelectionChanged(newItems: any) {
         if (!newItems || newItems.length === 0) {
            return;
         }
         let item: any = newItems[0]; // the obj selector has single selection
         if (!item) {
            return;
         }
         if (item.id === this.pageModel.computeResource && this.pageModel.compatibilityResultsCache) {
            this.compatibilityContents = this.pageModel.compatibilityResultsCache;
            return;
         }
         this.pageModel.isValidating = true;

         // Processing the selected object and the stretched storage are independend and
         // can be done in parallel. Processing the stretched storage in practise takes
         // less time than processing the selected object which does thorough validation.
         let processSelection = this.$q.all({
               selectedObject: this.processSelectedObject(item),
               stretchedStorage: this.pageModel.stretchedStorageService
                     .processStretchedStorage(item.id, this.pageModel.vms),
               targetDatacenter: this.dataService.getProperties(item.id, ["dc"])
         });

         processSelection.then((result) => {
               this.pageModel.stretchedStorage = result["stretchedStorage"];
               this.pageModel.datacenter = this.defaultUriSchemeUtil.getVsphereObjectId(
                     result["targetDatacenter"]["dc"]);
               this.pageModel.isValidating = false;
            }
         );
      }

      private processSelectedObject(item: any): IPromise<void> {
         return this.migrationWizardManager.changeComputeResource(item)
               .then((compatibilityResult: any) => {
                  if (compatibilityResult.isValid) {
                     this.pageModel.computeResource = item.id;
                  } else {
                     this.pageModel.computeResource = undefined;
                  }

                  return this.compatibilityResultsPresenter.formatValidationResults(
                        compatibilityResult.validationResults,
                        true /*includeSuccessContent*/);
               })
               .then((contents: any) => {
                  this.compatibilityContents = contents;
                  this.pageModel.compatibilityResultsCache = contents;

                  if (this.migrationWizardManager.getIsComputeResourceChanged()) {
                     let pageTitle: string = this.i18nService.getString(
                           "VmUi", "MigrationWizard.resourceListPage.title");

                     this.wizardPageService.invalidateNextPages(
                           this.wizardConfig,
                           this.migrationWizardManager.getSelectedMode(),
                           pageTitle
                     );
                  }
               });
      }

      private calculateSelectedObjects(destinationTargetId: any) {
         if (!destinationTargetId) {
            return [];
         }

         return [destinationTargetId];
      }

      private calculateInitialTab(destinationTargetId: string) {
         if (!destinationTargetId) {
            return 0;
         }
         let destinationTargetType: string = this.defaultUriSchemeUtil.getEntityType(destinationTargetId);
         switch (destinationTargetType) {
            case this.managedEntityConstants.CLUSTER:
               return 1;
            case this.managedEntityConstants.RESOURCE_POOL:
               return 2;
            case this.managedEntityConstants.V_APP:
               return 3;
            case this.managedEntityConstants.HOST:
               return 0;
            default:
               return 0;
         }
      }

      private equalReferences(ref1: any, ref2: any) {
         return (ref1.value === ref2.value) &&
               (ref1.type === ref2.type) &&
               (ref1.serverGuid === ref2.serverGuid);
      }
   }

   angular.module("com.vmware.vsphere.client.vm").component(
         "selectComputeResourcePageComponent", new SelectComputeResourcePageComponent());
}
