/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
(function () {
   'use strict';
   angular.module('com.vmware.vsphere.client.storage')
      .service('nfsVersionPageService', nfsVersionPageService);

   nfsVersionPageService.$inject = [
      'i18nService',
      'wizardPageService'
   ];

   function nfsVersionPageService(
      i18nService, wizardPageService) {

      return {
         build: function (wizardScope) {
            return {
               title: i18nService.getString('StorageUi', 'addDatastoreWizard.nfsVersionPage.title'),
               description: i18nService.getString('StorageUi', 'addDatastoreWizard.nfsVersionPage.nfsVersionLabel'),
               contentUrl: 'storage-ui/resources/storage/views/wizard/createDatastore/pages/nfs/NfsVersionPage.html',
               state: undefined,
               onCommit: function() {
                  wizardPageService.markPageComplete(wizardScope.wizardConfig,
                     wizardScope.commonWizardManager.getDatastoreType());
                  return true;
               }
            };
         }
      };
   }
})();
