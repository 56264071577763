namespace storage_ui {

   import StorageContainerInfoItem = com.vmware.vsphere.client.h5.storage.data.datastore.StorageContainerInfoItem;
   import IComponentOptions = angular.IComponentOptions;

   class VvolNameAndContainerSelectionPageComponentController implements angular.IComponentController {

      static $inject = ["i18nService", "vuiConstants", "dataService",
         "columnRenderersRegistry", "vxPropertyViewService"];
      private commonWizardManager: any;
      private vvolWizardManager: VvolWizardManager;
      private datastoreName: string;
      private datagridOptions: any;
      private detailsViewConfig: any;
      private readonly NO_SELECTION_DETAILS = new StorageContainerInfoItem();
      private readonly STORAGE_CONTAINERS_INFO = "datastore:filteredStorageContainerInfos";

      constructor(private i18nService: any,
                  private vuiConstants: any,
                  private dataService: any,
                  private columnRenderersRegistry: any,
                  private vxPropertyViewService: any) {
      }

      public $onInit() {
         if (!this.commonWizardManager.getDatastoreName()) {
            this.commonWizardManager.getUniqueDatastoreName()
                  .then((uniqueName: string)=> {
                     this.datastoreName = uniqueName;
                     this.onDatastoreNameChange();
                  });
         } else {
            this.datastoreName = this.commonWizardManager.getDatastoreName();
         }

         this.initDatagridOptions();

         this.dataService.getProperties(
               this.commonWizardManager.getContextObject(),
               [this.STORAGE_CONTAINERS_INFO])
               .then((response: {[key: string]: StorageContainerInfoItem[]})=> {
                  this.datagridOptions.data = response[this.STORAGE_CONTAINERS_INFO];
                  //used for validation
                  this.vvolWizardManager.availableContainers = this.datagridOptions.data;
               });
      }

      public preselectComparator = (gridDataItem: any)=> {
         if (this.datagridOptions.selectedItems.length || !this.datagridOptions.data) {
            return false;
         }

         if (this.vvolWizardManager.container) {
            return this.vvolWizardManager.container.identifier === gridDataItem.identifier;
         }

         return this.datagridOptions.data[0].identifier === gridDataItem.identifier;
      };

      onDatastoreNameChange() {
         this.commonWizardManager.setDatastoreName(this.datastoreName);
      }

      initDatagridOptions() {
         let isInitial = true;
         this.datagridOptions = {
            height: "100%",
            columnDefs: this.getColumnDefinitions(),
            sortMode: this.vuiConstants.grid.sortMode.SINGLE,
            selectionMode: this.vuiConstants.grid.selectionMode.SINGLE,
            data: [],
            selectedItems: [],
            resizable: true,
            onChange: ()=> {
               if (isInitial) {
                  isInitial = false;
                  return;
               }
               if (this.datagridOptions.selectedItems.length && this.datagridOptions.selectedItems[0]) {
                  let selectedItem = this.datagridOptions.selectedItems[0];
                  this.populateDetailsView(selectedItem);
                  this.vvolWizardManager.container = selectedItem;
               } else {
                  this.populateDetailsView(this.NO_SELECTION_DETAILS);
                  this.vvolWizardManager.container = null;
               }
            }
         };
      }

      populateDetailsView(selection: StorageContainerInfoItem) {
         let builder = this.vxPropertyViewService.createPropertyViewBuilder();
         let category = builder.category('category');
         category.section('general')
               .property(
                     this.i18nService.getString('StorageUi', 'datastore.manage.general.backing.storageArray'),
                     selection.storageArrays || this.i18nService.getString('StorageUi', 'informationNotAccessible'))
               .property(
                     this.i18nService.getString('StorageUi', 'datastore.manage.general.backing.storageProvider'),
                     selection.storageProviders || this.i18nService.getString('StorageUi', 'informationNotAccessible'));
         this.detailsViewConfig = builder.build();
      }

      deviceRenderer() {
         return ((data: StorageContainerInfoItem)=> {
            if (data.existingDatastoreName === this.i18nService.getString('StorageUi', 'informationNotAccessible')) {
               return this.columnRenderersRegistry.getColumnRenderer("text")(["existingDatastoreName"], data);
            } else {
               let objectLinkRenderer = this.columnRenderersRegistry.getColumnRenderer("icon-text-ex");
               let rendererConfig = {icon: ["vsphere-icon-datastore"]};
               return objectLinkRenderer(["existingDatastoreName"], data, rendererConfig);
            }

         });
      }

      getColumnDefinitions() {
         return [{
            displayName: this.i18nService.getString("StorageUi", "storageContainerList.columns.name"),
            field: "name",
            width: "200px",
            template: (item: StorageContainerInfoItem)=>item.name
         }, {
            displayName: this.i18nService.getString("StorageUi", "storageContainerList.columns.identifier"),
            field: "identifier",
            template: (item: StorageContainerInfoItem)=>item.identifier
         }, {
            displayName: this.i18nService.getString("StorageUi", "storageContainerList.columns.maxDiskSize"),
            field: "capacity",
            width: "80px",
            sortable: false,
            template: (item: StorageContainerInfoItem)=>item.capacity

         }, {
            displayName: this.i18nService.getString("StorageUi", "storageContainerList.columns.datastore"),
            field: "dsColumn",
            sortable: (item1: any, item2: any) => {
               return item1.existingDatastoreName > item2.existingDatastoreName;
            },
            template: this.deviceRenderer()
         }
         ];
      }

   }
   angular.module("com.vmware.vsphere.client.storage")
         .component("vvolNameAndContainerSelectionPage",
               <IComponentOptions>{
                  controller: VvolNameAndContainerSelectionPageComponentController,
                  bindings: {
                     commonWizardManager: "<",
                     vvolWizardManager: "<"
                  },
                  templateUrl: "storage-ui/resources/storage/views/wizard/createDatastore/pages/vvol/VvolNameAndContainerSelectionComponent.html"
               });

}
