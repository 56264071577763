module ds_cluster_ui {

   export class DatastoreMaintenanceModeService {

      public static $inject = ["mutationService", "dsClusterConstants",
         "dataService", "clarityModalService", "i18nService", "sdrsFaultsService",
         "defaultUriSchemeUtil", "clarityConstants", "actionsService", "$q",
         "drsRecommendationsService", "drsFaultsViewConstants", "columnRenderersRegistry",
         "vuiConstants"];

      constructor(private mutationService: any, private dsClusterConstants: any,
                  private dataService: any, private clarityModalService: any,
                  private i18nService: any, private sdrsFaultsService: SdrsFaultsService,
                  private defaultUriSchemeUtil: any, private clarityConstants: any,
                  private actionsService: any, private $q: angular.IQService,
                  private drsRecommendationsService: any, private drsFaultsViewConstants: any,
                  private columnRenderersRegistry: any, private vuiConstants: any) {
      }

      // ----------------------- interface ----------------------- //

      public enterMaintenanceMode(datastoreId: string, parentPodId: string) {
         this.requestMaintenanceModeOperation(datastoreId,
               this.dsClusterConstants.maintenanceMode.enter).then(
               (requestedData: any) => {
                  this.analyzeOperationResult(requestedData, datastoreId, parentPodId);
               });
      }

      public exitMaintenanceMode(datastoreId: string) {
         this.requestMaintenanceModeOperation(datastoreId,
               this.dsClusterConstants.maintenanceMode.exit);
      }

      public initiateEnterMaintenanceMode(datastoreId: string) {
         let shouldDisplayWarningsPromise: any =
               this.displayWarningCheck(datastoreId);

         shouldDisplayWarningsPromise.then((response: any) => {
            if (response.shouldWarn) {
               this.displayWarning(datastoreId,
                     response);
            } else {
               this.enterMaintenanceMode(
                     datastoreId,
                     this.defaultUriSchemeUtil.getVsphereObjectId(response.parentPod)
               );
            }
         });
      }

      public getFaultDetailsGridOptions(faults: any): Object {
         return {
            selectionMode: this.vuiConstants.grid.selectionMode.NONE,
            sortMode: this.vuiConstants.grid.sortMode.SINGLE,
            searchable: false,
            resizable: true,
            scrollable: true,
            data: faults,
            selectedItems: [],
            columnDefs: this.getFaultsColumnDefs(),
            pageConfig: {
               hidePager: true
            },
            height: "100%"
         };
      }

      // ----------------------- implementation details ----------------------- //

      private getFaultsColumnDefs(): any[] {

         let iconRenderer: any = this.columnRenderersRegistry.getColumnRenderer('icon-text');

         return [
            {
               displayName: this.i18nService.getString(
                     "DsClusterUi",
                     "enterDsMaintenanceMode.warning.faults.datagrid.vmHeader"
               ),
               field: this.drsFaultsViewConstants.VM_NAME_KEY,
               type: "string",
               template: (dataItem: any) => {
                  let rendererData = {
                     icon: dataItem[this.drsFaultsViewConstants.VM_ICON_KEY],
                     text: dataItem[this.drsFaultsViewConstants.VM_NAME_KEY]
                  };
                  return iconRenderer(["icon", "text"], rendererData);
               }
            },
            {
               displayName: this.i18nService.getString(
                     "DsClusterUi",
                     "enterDsMaintenanceMode.warning.faults.datagrid.vmDisksHeader"
               ),
               field: this.drsFaultsViewConstants.DISK_NAME_KEY,
               type: "string",
               template: "#:" + this.drsFaultsViewConstants.DISK_NAME_KEY + "#"
            },
            {
               displayName: this.i18nService.getString(
                     "DsClusterUi",
                     "enterDsMaintenanceMode.warning.faults.datagrid.hostHeader"
               ),
               field: this.drsFaultsViewConstants.HOST_NAME_KEY,
               type: "string",
               template: (dataItem: any) => {
                  let rendererData = {
                     icon: dataItem[this.drsFaultsViewConstants.HOST_ICON_KEY],
                     text: dataItem[this.drsFaultsViewConstants.HOST_NAME_KEY]
                  };
                  return iconRenderer(["icon", "text"], rendererData);
               }
            },
            {
               displayName: this.i18nService.getString(
                     "DsClusterUi",
                     "enterDsMaintenanceMode.warning.faults.datagrid.descriptionHeader"
               ),
               field: this.drsFaultsViewConstants.DESCRIPTION_KEY,
               type: "string",
               template: "#:" + this.drsFaultsViewConstants.DESCRIPTION_KEY + "#"
            },
         ];
      }

      private analyzeOperationResult(data: any, datastoreId: string, parentPodId: string) {
         let faults: Array<any> = [];

         if (data.result.faults) {
            data.result.faults.forEach((faultData: any) => {
               faults = faults.concat(
                     this.sdrsFaultsService.getMaintenaceModeFaultDetailsData(faultData));
            });
         }

         if (data.result.faults || data.result.recommendations) {
            let recommendations: any = {
               computedSdrsRecommendations: data.result.recommendations,
               id: this.defaultUriSchemeUtil.getVsphereObjectId(data.entity)
            };
            this.displayFaultsAndRecommendations(
                  data.taskUid,
                  faults,
                  recommendations,
                  datastoreId,
                  parentPodId);
         }
      }

      private displayFaultsAndRecommendations(taskUid: any,
                                              faults: any,
                                              recommendations: any,
                                              datastoreId: string,
                                              parentPodId: string) {
         let dialogData: any = {};
         let modalOptions: any = {
            title: this.i18nService.getString(
                  "DsClusterUi", "enterDsMaintenanceMode.warning.title"),
            subTitle: {
               objectId: datastoreId
            },
            modalClass: "ds-enter-maintenance-mode-hide-close-button",
            dialogData: dialogData,
            size: "xl",
            submitButtonTitle: this.i18nService.getString(
                  "DsClusterUi", "enterDsMaintenanceMode.warning.button.continue"),
            defaultButton: "submit",
            onCancel: () => {
               // Cancel the task.
               this.mutationService.apply(taskUid,
                     "com.vmware.vise.core.model.monitor.TaskStateSpec",
                     {cancel: 'true'});
            }
         };

         if (recommendations.computedSdrsRecommendations) {
            // recommendations only are displayed
            let recommendationStore: any = this.drsRecommendationsService.createRecommendationsStore(new Array(0));
            dialogData = {
               recommendations: recommendations,
               parentPodId: parentPodId,
               recommendationStore: recommendationStore
            };
            modalOptions.contentTemplate =
                  "ds-cluster-ui/resources/ds-cluster/views/maintenanceMode/" +
                  "enterMaintenanceModeRecommendationsWarning.html";

            modalOptions.onSubmit = () => {
               let specType: string =
                     "com.vmware.vsphere.client.dscluster.maintenancemode.DatastoreMaintenanceSpec";
               let propSpec: any = {
                  maintenanceOpType: this.dsClusterConstants.maintenanceMode.applyRecommendations,
                  keys: modalOptions.dialogData.recommendationStore.getRecommendationsToApply()
               };

               this.mutationService.apply(datastoreId, specType, propSpec);
               return true;
            };

            // in this case both recommendations and faults are displayed
            if (faults && faults.length > 0) {
               dialogData.faults = faults;
               modalOptions.contentTemplate =
                     "ds-cluster-ui/resources/ds-cluster/views/maintenanceMode/" +
                     "faultsAndRecommendations.html";
            }
         } else if (faults && faults.length > 0) {
            // only faults are displayed
            dialogData = {
               faults: faults
            };
            modalOptions.contentTemplate =
                  "ds-cluster-ui/resources/ds-cluster/views/maintenanceMode/" +
                  "enterMaintenanceModeFaultsWarning.html";

            modalOptions.onSubmit = () => {
               return true;
            };
         }

         modalOptions.dialogData = dialogData;
         this.clarityModalService.openOkCancelModal(modalOptions);
      }

      private displayWarningCheck(datastoreId: string) {
         return this.dataService.getData(datastoreId,
               'com.vmware.vsphere.client.dscluster.model.DatastoreEnterMaintenanceModeWarningData')
               .then((response: any) => {
                  return response;
               });
      }

      private displayWarning(datastoreId: string,
                             response: any) {
         let vmIds: Array<string> = _.map(response.vms, (vm) => {
            return this.defaultUriSchemeUtil.getVsphereObjectId(vm);
         });

         let promises = [
            this.mutationService.validateSpec(
                  "com.vmware.vsphere.client.h5.vm.model.migration.VmMigrationFlowValidationSpec",
                  {vms: vmIds}),
            this.actionsService.getAction(
                  "vsphere.core.vm.actions.migrate",
                  vmIds,
                  false)
         ];
         let modalOptions: any = {
            title: this.i18nService.getString(
                  "DsClusterUi", "enterDsMaintenanceMode.warning.title"),
            subTitle: {
               objectId: datastoreId
            },
            modalClass: "ds-enter-maintenance-mode-hide-close-button",
            contentTemplate: "ds-cluster-ui/resources/ds-cluster/views/maintenanceMode/enterMaintenanceModeWarning.html",
            defaultButton: 'submit',
            submitButtonTitle: this.i18nService.getString(
                  "DsClusterUi",
                  "enterDsMaintenanceMode.warning.button.continue"
            ),
            dialogData: {
               shouldStartMigrateWizard: false,
               parentPod: response.parentPod,
               vmIds: vmIds,
               actionEval: {
                  invoker: "vsphere.core.vm.actions.vmMigrate",
                  action: {
                     uid: "vsphere.core.vm.actions.migrate",
                     confirmationTextAll: "CUSTOM",
                     confirmationTextSome: "CUSTOM",
                     label: this.i18nService.getString("VmUi", "actionLabelMigrate")
                  }
               },
               promise: this.$q.all(promises)
            },
            onSubmit:() => {
               this.enterMaintenanceMode(datastoreId, response.parentPod);
               // call migrate wizard with the vms
               if (modalOptions.dialogData.shouldStartMigrateWizard) {
                  this.actionsService.invokeAction(
                        modalOptions.dialogData.actionEval
                  );
               }
               return true;
            }
         };

         this.clarityModalService.openOkCancelModal(modalOptions);
      }

      private requestMaintenanceModeOperation(datastoreId: string, operation: string) {
         let specType: string =
               "com.vmware.vsphere.client.dscluster.maintenancemode.DatastoreMaintenanceSpec";
         let propSpec: { [key: string]: string; } = {
            maintenanceOpType: operation
         };

         return this.mutationService.apply(datastoreId, specType, propSpec);
      }
   }

   angular.module("com.vmware.vsphere.client.dsCluster")
         .service("datastoreMaintenanceModeService", DatastoreMaintenanceModeService);
}