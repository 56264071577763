namespace dvs_ui {

   import Dictionary = _.Dictionary;

   export class DvsSpanSessionPageValidator {

      private _model: DvsSpanSessionValidateableModel;

      public vxValidator: any;

      constructor(model: DvsSpanSessionValidateableModel, vxValidator: any) {
         this._model = model;
         this.vxValidator = vxValidator;
      }

      public get model(): DvsSpanSessionValidateableModel {
         return this._model;
      }

      public validate(): boolean {
         if (this.vxValidator && this.vxValidator.validate) {
            let errors: string[] = this.vxValidator.validate();

            if (this._model) {
               this._model.errors = errors;
            }

            return errors && errors.length === 0;
         }

         return true;
      }
   }
}
