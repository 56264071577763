namespace h5_client {
   export module common_module_ui {
      class AddEditCustomAttributeController {
         public static $inject = ['$scope', '$rootScope', 'i18nService', 'associableObjectTypesService', 'customAttributesService'];
         public i18n: any;
         public associableObjectTypes: any;

         private attributeState: any;
         private isInEditMode: boolean = false;
         private selectedType: any;

         constructor(private $scope: any, private $rootScope: any, private i18nService: any, private associableObjectTypesService: any, private customAttributesService: any) {
            let self: AddEditCustomAttributeController = this;
            self.i18n = i18nService.getString;
            self.attributeState = $scope.modalOptions.dialogData.customAttributeState;

            if (self.attributeState && self.attributeState.attribute) {
               self.isInEditMode = true;
            } else {
               self.attributeState = { attribute: '' };
            }

            if (self.isInEditMode) {
               self.selectedType = self.attributeState.typeFormatted;
            } else {
               self.associableObjectTypes = associableObjectTypesService.getTypeDataForAttributes();
               self.associableObjectTypes.global = {
                  associableTypeString: 'global',
                  displayName: 'global'
               };

               for (let key of Object.keys(self.associableObjectTypes)) {
                  self.associableObjectTypes[key].i18nString =
                        self.i18n('Common', 'fieldType.' + self.associableObjectTypes[key].associableTypeString);
               }

               self.selectedType = self.associableObjectTypes.global;
            }
         }

         private closeModal = () => {
            this.$scope.closeModal();
         };

         private okButtonDisabled = () => {
            return !this.attributeState.attribute;
         };

         private submit = () => {
            if(this.isInEditMode) {
               this.customAttributesService.renameCustomAttribute(this.attributeState).then(() => {
                  this.$rootScope.$broadcast('refreshCustomAttributes');
                  this.closeModal();
               });
            } else {
               this.customAttributesService.addCustomAttribute(this.attributeState, this.selectedType).then(() => {
                  this.$rootScope.$broadcast('refreshCustomAttributes');
                  this.closeModal();
               });
            }
         };
      }

      angular.module('com.vmware.vsphere.client.commonModule')
         .controller('addEditCustomAttributeController', AddEditCustomAttributeController);
   }
}
