/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace dvs_ui {

   export class DvsEditSettingsDialogManager {

      public static DVS_CONFIG_SPEC: string =
            "com.vmware.vsphere.client.dvs.api.spec.DvsConfigSpec";

      public static DVS_CONFIG_SPEC_BINDING: string =
            "com.vmware.vim.binding.vim.dvs.VmwareDistributedVirtualSwitch$ConfigSpec";

      public static UPLINK_POLICY_SPEC: string =
            "com.vmware.vim.binding.vim.DistributedVirtualSwitch$NameArrayUplinkPortPolicy";

      public static LDP_CONFIG_SPEC: string =
            "com.vmware.vim.binding.vim.host.LinkDiscoveryProtocolConfig";

      public static DVS_CONTACT_INFO: string =
            "com.vmware.vim.binding.vim.DistributedVirtualSwitch$ContactInfo";

      constructor(public dialogScope: any,
                  private vuiConstants: any,
                  private mutationService: any) {
      }

      public showDialogErrors(errors: string[]): void {
         if (!errors || errors.length < 1) {
            return;
         }

         this.dialogScope.dialogOptions.validationBanner.messages = _.map(
               errors,
               (message: string) => {
                  return {
                     type: this.vuiConstants.validationBanner.type.ERROR,
                     text: message
                  };
               });
      }

      public submitDialog(): boolean {

         let generalPageChanges: any =
               this.dialogScope.generalPageModel.buildChanges(this.dialogScope.originalData);

         let advancedPageChanges: any =
               this.dialogScope.advancedPageModel.buildChanges(this.dialogScope.originalData);

         // Whether any page has changes
         let hasChanges: boolean =
               generalPageChanges !== null || advancedPageChanges !== null;

         if (hasChanges) {
            let dvsConfigSpec: any = {
               _type: DvsEditSettingsDialogManager.DVS_CONFIG_SPEC,
               configSpec: {
                  _type: DvsEditSettingsDialogManager.DVS_CONFIG_SPEC_BINDING,
                  configVersion: this.dialogScope.originalData.configVersion
               }
            };

            // General page
            if (generalPageChanges !== null) {
               dvsConfigSpec.configSpec.name = generalPageChanges.name;
               dvsConfigSpec.configSpec.description = generalPageChanges.description;

               if (generalPageChanges.uplinkPortNames) {
                  dvsConfigSpec.configSpec.uplinkPortPolicy = {
                     _type: DvsEditSettingsDialogManager.UPLINK_POLICY_SPEC,
                     uplinkPortName: generalPageChanges.uplinkPortNames
                  };
               }

               dvsConfigSpec.enableResourceManagement = generalPageChanges.isNiocEnabled;
            }

            // Advanced page
            if (advancedPageChanges !== null) {
               if (advancedPageChanges.mtu !== null) {
                  dvsConfigSpec.configSpec.maxMtu = advancedPageChanges.mtu;
               }

               if (advancedPageChanges.multicastFilteringMode !== null) {
                  dvsConfigSpec.configSpec.multicastFilteringMode = advancedPageChanges.multicastFilteringMode;
               }

               if (advancedPageChanges.ldpProtocol !== null
                     || advancedPageChanges.ldpOperation !== null) {
                  dvsConfigSpec.configSpec.linkDiscoveryProtocolConfig = {
                     _type: DvsEditSettingsDialogManager.LDP_CONFIG_SPEC,
                     protocol: advancedPageChanges.ldpProtocol,
                     operation: advancedPageChanges.ldpOperation
                  };
               }

               if (advancedPageChanges.contactName !== null
                     || advancedPageChanges.contactDetails !== null) {
                  dvsConfigSpec.configSpec.contact = {
                     _type: DvsEditSettingsDialogManager.DVS_CONTACT_INFO,
                     name: advancedPageChanges.contactName !== null
                           ? advancedPageChanges.contactName
                           : this.dialogScope.originalData.contactName,
                     contact: advancedPageChanges.contactDetails !== null
                           ? advancedPageChanges.contactDetails
                           : this.dialogScope.originalData.contactDetails
                  };
               }
            }

            this.mutationService.apply(
                  this.dialogScope.dvsUrn, DvsEditSettingsDialogManager.DVS_CONFIG_SPEC, dvsConfigSpec);
         }

         return true;
      }
   }
}
