/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace h5_client {
   export module common_module_ui {
      /*
       * Category action service.
       */
      import IRootScopeService = angular.IRootScopeService;
      export class CategoryActionService {
         static $inject = ['categoryService', 'clarityModalService', 'i18nService', '$q', '$rootScope'];

         constructor(private categoryService: any, private clarityModalService: any, private i18nService: any, private $q: any, private $rootScope: IRootScopeService) {}

         /**
          * Confirm and delete a category.
          */
         public deleteCategory(categoryMoRef: any) {
            let self = this;
            this.categoryService.getCategoryDetails(categoryMoRef).then(function(response: any){
               let tagCount = response.childTags !== null ? response.childTags.length : 0;
               var modalOptions = {
                  title: self.i18nService.getString('CommonModuleUi','categoryAction.remove.dialog.title'),
                  message: self.i18nService.getString('CommonModuleUi','categoryAction.remove.warning',response.name)+self.i18nService.getString('CommonModuleUi','categoryAction.remove.warning.tags',tagCount),
                  icon: 'vsphere-icon-status-warning',
                  submit: () => {
                     self.categoryService.deleteCategory(categoryMoRef)
                         .then(() => {
                            // deferred promise does not work with $http,
                            // so just using a broadcast.
                            self.$rootScope.$broadcast('refreshCategories');
                         });
                  },
                  preserveNewlines: true
               };
               self.clarityModalService.openConfirmationModal(modalOptions);
            });
         }

         public addEditCategory(categories: any, categoryData: any) {
            let categoryState: any = {};
            let dialogTitle: string = this.i18nService.getString('CommonModuleUi', 'add.category');
            if (categoryData) {
               let allSelected: boolean = categoryData.displayAssociableEntityTypesString === this.i18nService.getString('Common',
                     'tag.dashboard.categories.associable.all');
               categoryState = {
                  allSelected: allSelected,
                  categoryMoRef: categoryData.managedObjectReference,
                  description: categoryData.categoryDescription,
                  displayAssociableEntityTypesString: categoryData.displayAssociableEntityTypesString,
                  multipleCardinality: categoryData.multipleCardinality,
                  name: categoryData.categoryName,
                  selectedAssociableEntityTypes: categoryData.associableEntityTypes
               };
               dialogTitle = this.i18nService.getString('CommonModuleUi', 'edit.category');
            }
            let actionEval: any = {
               action: {
                  label: dialogTitle
               }
            };
            let context: any = {
               categories: categories,
               categoryState: categoryState,
               title: dialogTitle
            };

            this.clarityModalService.openModal(actionEval, null, context,
               'common-module-ui/resources/common-module/views/categories/add-edit-category.html');
         }
      }

      angular.module('com.vmware.vsphere.client.commonModule').service('categoryActionService', CategoryActionService);
   }
}
