/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
(function() {
   'use strict';
   angular.module('com.vmware.vsphere.client.storage').controller(
      'IscsiTargetsDetailsViewController', IscsiTargetsDetailsViewController);

   IscsiTargetsDetailsViewController.$inject = [
      '$scope',
      'adapterTargetsService',
      'i18nService',
      'navigation'
   ];

   function IscsiTargetsDetailsViewController(
      $scope, adapterTargetsService, i18nService, navigation) {
      var self = this;
      self.loading = false;

      self.hostId = navigation.getRoute().objectId;

      $scope.$watch(function() { return $scope.masterDetailsViewContext.selectedItem; },
         function(newValue, oldValue) {
            if (newValue !== oldValue && newValue && newValue.isIscsiAdapter) {
               self.refresh();
            }
         });

      self.targetsUpdatedCallback = function (result, adapter) {
         if (result && !result.error && adapter) {
            $scope.masterDetailsViewContext.alertMessenger.addRescanMessage(
                  adapter.key,
                  adapter.device
            );
         }
      };

      self.refresh = function() {
         if (!$scope.masterDetailsViewContext.selectedItem) {
            return;
         }

         self.loading = true;
         self.adapter = $scope.masterDetailsViewContext.selectedItem;

         var adapterKey = $scope.masterDetailsViewContext.selectedItem.key;
         adapterTargetsService.retrieveIscsiAdapterTargets(self.hostId, adapterKey)
            .then(function(targets) {
               if (targets) {
                  self.staticTargets = targets.staticTargets ? targets.staticTargets : [];
                  self.dynamicTargets = targets.dynamicTargets ? targets.dynamicTargets : [];
                  self.hba = targets.hba;
               } else {
                  self.staticTargets = [];
                  self.dynamicTargets = [];
               }
            })
            .finally(function() {
               self.loading = false;
            });
      };

      self.refresh();
   }
})();
