(function() {
   'use strict';

   /**
    * Provides properties for the HA host failure responses and VM restart priority.
    */
   angular.module('com.vmware.vsphere.client.cluster')
         .service('haHostFailureResponseService', haHostFailureResponseService);

   haHostFailureResponseService.$inject = ['i18nService', 'haConstants'];

   function haHostFailureResponseService(i18nService, haConstants) {
      return {
         getDisabledOption: getDisabledOption,
         getVmRestartPriorityOptions: getVmRestartPriorityOptions,
         getReadyConditionOptions: getReadyConditionOptions,
         getVmOrchestrationReadyConditions: getVmOrchestrationReadyConditions
      };

      function getDisabledOption() {
         return {
            id: haConstants.restartPriority.DISABLED,
            label: getString('ha.config.failures.responses.host.failure.vm.restart.disabled'),
            description: getString('ha.config.failures.responses.host.failure.vm.restart.disabled.desc')
         };
      }

      function getVmRestartPriorityOptions(is65VcOrLater) {
         var vmRestartPriorityOptions = {};

         if (is65VcOrLater) {
            vmRestartPriorityOptions.lowest = {
               id: haConstants.restartPriority.LOWEST,
               label: getString('ha.config.vm.vmRestartPriority.lowest')
            };
         }

         vmRestartPriorityOptions = angular.extend(vmRestartPriorityOptions, {
            low: {
               id: haConstants.restartPriority.LOW,
               label: getString('ha.config.vm.vmRestartPriority.low')
            },
            medium: {
               id: haConstants.restartPriority.MEDIUM,
               label: getString('ha.config.vm.vmRestartPriority.medium')
            },
            high: {
               id: haConstants.restartPriority.HIGH,
               label: getString('ha.config.vm.vmRestartPriority.high')
            }
         });

         if (is65VcOrLater) {
            vmRestartPriorityOptions.highest = {
               id: haConstants.restartPriority.HIGHEST,
               label: getString('ha.config.vm.vmRestartPriority.highest')
            };
         }

         return vmRestartPriorityOptions;
      }

      function getVmOrchestrationReadyConditions() {
         return {
            none: {
               id: haConstants.readyCondition.NONE,
               label: getString('ha.config.failures.responses.host.failure.restart.readiness.none')
            },
            poweredOn: {
               id: haConstants.readyCondition.POWERED_ON,
               label: getString('ha.config.failures.responses.host.failure.restart.readiness.poweredOn')
            },
            guestHbStatusGreen: {
               id: haConstants.readyCondition.GUEST_HEARTBEAT_DETECTED,
               label: getString('ha.config.failures.responses.host.failure.restart.readiness.guestHbStatusGreen')
            },
            appHbStatusGreen: {
               id: haConstants.readyCondition.APP_HEARTBEAT_DETECTED,
               label: getString('ha.config.failures.responses.host.failure.restart.readiness.appHbStatusGreen')
            }
         };
      }

      function getReadyConditionOptions() {
         return {
            none: {
               id: haConstants.readyCondition.NONE,
               label: getString(
                     'ha.config.failures.responses.host.failure.restart.readiness.none')
            },
            poweredOn: {
               id: haConstants.readyCondition.POWERED_ON,
               label: getString(
                     'ha.config.failures.responses.host.failure.restart.readiness.poweredOn')
            },
            guestHbDetected: {
               id: haConstants.readyCondition.GUEST_HEARTBEAT_DETECTED,
               label: getString(
                     'ha.config.failures.responses.host.failure.restart.readiness.guestHbStatusGreen')
            },
            appHbDetected: {
               id: haConstants.readyCondition.APP_HEARTBEAT_DETECTED,
               label: getString(
                     'ha.config.failures.responses.host.failure.restart.readiness.appHbStatusGreen')
            }
         };
      }

      function getString(key) {
         return i18nService.getString('ClusterUi', key);
      }
   }
})();
