namespace h5_vm {

   export class CrudGridConfiguration<T> {

      data: Array<T>;
      columnDefinitions: Array<any>;
      height: string;

      addLabel: string;
      addTooltip: string;
      onAdd: () => void;

      editLabel: string;
      editTooltip: string;
      onEdit: (selectedItem: T) => void;

      deleteLabel: string;
      deleteTooltip: string;
      onDelete: (selectedItem: T) => void;

   }
}
