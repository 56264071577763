/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace dvs_ui {

   import VlanSpec = com.vmware.vim.binding.vim.dvs.VmwareDistributedVirtualSwitch$VlanSpec;
   import DvsPortEditData = com.vmware.vsphere.client.h5.network.dvs.ports.model.DvsPortEditData;
   import PortConfigSpec = com.vmware.vim.binding.vim.dvs.DistributedVirtualPort$ConfigSpec;
   import VmwarePortConfigPolicy = com.vmware.vim.binding.vim.dvs.VmwareDistributedVirtualSwitch$VmwarePortConfigPolicy;
   import SecurityPolicy = com.vmware.vim.binding.vim.dvs.VmwareDistributedVirtualSwitch$SecurityPolicy;
   import BoolPolicy = com.vmware.vim.binding.vim.BoolPolicy;
   import StringPolicy = com.vmware.vim.binding.vim.StringPolicy;
   import DvsPortPropertiesModel = com.vmware.vsphere.client.h5.network.dvs.ports.model.DvsPortPropertiesModel;
   import DvsPortVlanPolicyModel = com.vmware.vsphere.client.h5.network.dvs.ports.model.DvsPortVlanPolicyModel;
   import TrafficShapingPolicy = com.vmware.vim.binding.vim.dvs.DistributedVirtualPort$TrafficShapingPolicy;
   import NetworkResourcePoolData = com.vmware.vsphere.client.h5.network.dvs.ports.model.DvsPortPropertiesModel$NetworkResourcePoolData;

   export class DvsPortEditDialogSpecFactory {

      public static $inject = [
         "i18nService",
         "dvpgSpecFactory"
      ];

      constructor(private i18nService: any,
                  private dvpgSpecFactory: DvpgSpecFactory) {
      }


      public buildSpec(pageData: DvsPortEditDialogModel,
                       initialData: DvsPortEditData): PortConfigSpec | null {
         if (!initialData || !pageData || !pageData.dvPortgroupPortProperties) {
            return null;
         }
         // properties changes
         let hasPropertyChanges: boolean = pageData.propertiesPage
               && pageData.propertiesPage.isPropertiesPageChanged(initialData.properties);
         // settings changes
         let hasSecurityChanges: boolean = pageData.securityPage && !pageData.isUplink
               && pageData.dvPortgroupPortProperties.securityPolicyOverrideAllowed
               && pageData.securityPage.isSecurityPageChanged(initialData.securityPolicy);
         let hasVlanChanges: boolean = pageData.vlanPage
               && pageData.dvPortgroupPortProperties.vlanOverrideAllowed
               && pageData.vlanPage.isVlanPageChanged(initialData.vlanPolicy);
         let hasTrafficShapingChanges: boolean = pageData.trafficShapingPage
               && pageData.dvPortgroupPortProperties.shapingOverrideAllowed
               && pageData.trafficShapingPage.isTrafficShapingPageChanged(
                     initialData.trafficShapingPolicy);
         let hasFailoverChanges: boolean = pageData.failoverPolicyPage
               && pageData.dvPortgroupPortProperties.uplinkTeamingOverrideAllowed
               && pageData.failoverPolicyPage.isFailoverPageChanged(
                     initialData.failoverPolicy);
         let hasMonitoringChanges: boolean = pageData.monitoringPage
               && pageData.dvPortgroupPortProperties.ipfixOverrideAllowed
               && pageData.monitoringPage.isMonitoringPageChanged(
                     initialData.monitoringPolicy);
         let hasMiscellaneousChanges: boolean = pageData.miscellaneousPage
               && pageData.dvPortgroupPortProperties.blockOverrideAllowed
               && pageData.miscellaneousPage.isMiscellaneousPageChanged(
                     initialData.miscPolicy);
         let hasNetworkResourcePoolChange: boolean = pageData.propertiesPage
               && pageData.dvPortgroupPortProperties.networkResourcePoolOverrideAllowed
               && pageData.propertiesPage.isResourcePoolChanged(initialData.properties);

         let hasSettingsChanges: boolean = hasSecurityChanges || hasVlanChanges
               || hasTrafficShapingChanges || hasFailoverChanges || hasMonitoringChanges
               || hasMiscellaneousChanges || hasNetworkResourcePoolChange;

         if (!hasPropertyChanges && !hasSettingsChanges) {
            return null;
         }

         let portConfigSpec: PortConfigSpec = new PortConfigSpec();
         portConfigSpec.key = pageData.portId;
         portConfigSpec.configVersion = pageData.configVersion;
         portConfigSpec.operation = DvsPortEditDialogConstants.EDIT_OPERATION;

         if (hasPropertyChanges) {
            if (pageData.propertiesPage.name !== initialData.properties.name) {
               portConfigSpec.name = pageData.propertiesPage.name;
            }

            if (pageData.propertiesPage.description !== initialData.properties.description) {
               portConfigSpec.description = pageData.propertiesPage.description;
            }
         }

         let settingsPolicy: VmwarePortConfigPolicy = new VmwarePortConfigPolicy();
         if (hasNetworkResourcePoolChange) {
            settingsPolicy.networkResourcePoolKey = this.buildNetworkResourcePoolPolicy(
                  pageData.propertiesPage.selectedResourcePool);
         }

         if (hasSecurityChanges) {
            settingsPolicy.securityPolicy =
                  this.buildSecurityPolicy(initialData.securityPolicy, pageData);
         }

         if (hasVlanChanges) {
            settingsPolicy.vlan = this.buildVlanPolicy(pageData.vlanPage);
         }

         if (hasTrafficShapingChanges
               && pageData.trafficShapingPage.trafficShapingPolicy
               && pageData.trafficShapingPage.overrideOptions) {
            settingsPolicy.inShapingPolicy = this.dvpgSpecFactory.buildTrafficShapingSpecPort(
                  pageData.trafficShapingPage.trafficShapingPolicy.inShapingPolicy /*policy*/,
                  !pageData.trafficShapingPage.overrideOptions.inShapingPolicy /*inherited*/);
            settingsPolicy.outShapingPolicy = this.dvpgSpecFactory.buildTrafficShapingSpecPort(
                  pageData.trafficShapingPage.trafficShapingPolicy.outShapingPolicy /*policy*/,
                  !pageData.trafficShapingPage.overrideOptions.outShapingPolicy /*inherited*/);
         }

         if (hasFailoverChanges) {
            settingsPolicy.uplinkTeamingPolicy =
                  this.dvpgSpecFactory.buildTeamingAndFailoverPortSpec(
                        pageData.failoverPolicyPage.failoverPolicy,
                        pageData.failoverPolicyPage.overrideOptions);
         }

         if (hasMonitoringChanges) {
            settingsPolicy.ipfixEnabled = this.buildBoolPolicy(
                  pageData.monitoringPage.monitoringPolicy.ipfixEnabled,
                  pageData.monitoringPage.monitoringPolicy.ipfixOverrided);
         }

         if (hasMiscellaneousChanges) {
            settingsPolicy.blocked = this.buildBoolPolicy(
                  pageData.miscellaneousPage.policy.arePortsBlocked,
                  pageData.miscellaneousPage.policy.blockPortsOverrided);
         }

         if (hasSettingsChanges) {
            portConfigSpec.setting = settingsPolicy;
         }

         return portConfigSpec;
      }

      private buildNetworkResourcePoolPolicy(networkResourcePool: NetworkResourcePoolData): StringPolicy {
         let networkResourcePoolPolicy: StringPolicy = new StringPolicy();
         networkResourcePoolPolicy.value = networkResourcePool.key;
         networkResourcePoolPolicy.inherited = !networkResourcePool.overrided;

         return networkResourcePoolPolicy;
      }

      private buildSecurityPolicy(initialSecurityPolicy: SecurityPolicy,
                                  pageData: DvsPortEditDialogModel): SecurityPolicy {
         let securityPolicy: SecurityPolicy = new SecurityPolicy();
         securityPolicy.inherited = pageData.securityPage.isInherited();

         if (pageData.securityPage.policyValues && pageData.securityPage.overrideOptions) {
            if (pageData.securityPage.isPolicyChanged(
                        initialSecurityPolicy.allowPromiscuous,
                        DvsPortEditDialogConstants.POLICY_ALLOW_PROMISCUOUS)) {
               securityPolicy.allowPromiscuous = this.buildBoolPolicy(
                     pageData.securityPage.policyValues.allowPromiscuous,
                     pageData.securityPage.overrideOptions.allowPromiscuous);
            }

            if (pageData.securityPage.isPolicyChanged(
                        initialSecurityPolicy.macChanges,
                        DvsPortEditDialogConstants.POLICY_MAC_CHANGES)) {
               securityPolicy.macChanges = this.buildBoolPolicy(
                     pageData.securityPage.policyValues.macChanges,
                     pageData.securityPage.overrideOptions.macChanges);
            }

            if (pageData.securityPage.isPolicyChanged(
                        initialSecurityPolicy.forgedTransmits,
                        DvsPortEditDialogConstants.POLICY_FORGET_TRANSMITS)) {
               securityPolicy.forgedTransmits = this.buildBoolPolicy(
                     pageData.securityPage.policyValues.forgedTransmits,
                     pageData.securityPage.overrideOptions.forgedTransmits);
            }
         }

         return securityPolicy;
      }

      private buildVlanPolicy(vlanData: DvsPortEditVlanPageModel): VlanSpec {
         let vlanSpec: VlanSpec = this.dvpgSpecFactory.buildVlanSpec(vlanData.vlanPolicy);
         vlanSpec.inherited = vlanData.overrideOptions
               && !vlanData.overrideOptions.overrideVlan;

         return vlanSpec;
      }

      private buildBoolPolicy(value: boolean, isOverridden: boolean): BoolPolicy {
         let policy: BoolPolicy = new BoolPolicy();
         policy.inherited = !isOverridden;
         policy.value = value;
         return policy;
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .service("dvsPortEditDialogSpecFactory", DvsPortEditDialogSpecFactory);
}