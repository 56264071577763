(function() {
   'use strict';

   angular.module('com.vmware.vsphere.client.vm')
         .service('upgradeVmHardwareService', upgradeVmHardwareService);

   upgradeVmHardwareService.$inject = ['dataService', 'mutationService',
      'actionConfirmationService', 'i18nService', 'clarityModalService', '$timeout',
      'vcService', 'upgradeVmHardwareConstants', 'vmHardwareCompatibilityService',
      '$q', 'managedByMessageBuilderService'];

   function upgradeVmHardwareService(dataService, mutationService,
         actionConfirmationService, i18nService, clarityModalService, $timeout,
         vcService, upgradeVmHardwareConstants, vmHardwareCompatibilityService, $q,
         managedByMessageBuilderService) {

      return {
         upgradeVmHardware: upgradeVmHardware,
         scheduleUpgradeVmHardware: scheduleUpgradeVmHardware,
         cancelScheduledVmHardwareUpgrade: cancelScheduledVmHardwareUpgrade
      };

      function upgradeVmHardware(actionEval, vmIds) {
         upgradeHardwareInternal(vmIds, actionEval, false);
      }

      function scheduleUpgradeVmHardware(actionEval, vmIds) {
         upgradeHardwareInternal(vmIds, actionEval, true);
      }

      function upgradeHardwareInternal(vmIds, actionEval, isSchedule) {
         var promises = buildPromises(vmIds);
         $q.all(promises).then(function(results) {
            var subTitle = '';
            if (actionEval.context.targetObjects.length === 1) {
               subTitle = results['dataServiceProperties'][vmIds[0]]['name'];
            }
            okHandlerInternal(results, actionEval, isSchedule, subTitle);
         });
      }

      function cancelScheduledVmHardwareUpgrade(actionEval, vmIds) {
         // VmConfigSpec is used to cancel upgrade by setting upgradePolicy to "never".
         mutationService.applyOnMultiEntity(vmIds,
               'com.vmware.vsphere.client.vm.VmConfigSpec',
               {
                  config: {
                     _type: "com.vmware.vim.binding.vim.vm.ConfigInfo",
                     scheduledHardwareUpgradeInfo: {
                        _type: "com.vmware.vim.binding.vim.vm.ScheduledHardwareUpgradeInfo",
                        upgradePolicy: upgradeVmHardwareConstants.hardwareUpgradePolicy.never
                     }
                  }
               });
      }

      function buildModalOptions(supportedVersions,
            upgradeDescriptionSingle, allVms, hwVersion, title, scheduleMode, onSubmit, subTitle) {
         var modalOptions = {
            dialogData: {
               supportedVersions: supportedVersions,
               description: upgradeDescriptionSingle,
               allVms: allVms,
               hwVersion: hwVersion,
               scheduleMode: scheduleMode
            },
            contentTemplate: 'vm-ui/resources/vm/views/compatibility/upgradeVmHardwareView.html',
            title: title,
            defaultButton: 'submit',
            alerts: [],
            onSubmit: onSubmit
         };

         if (allVms.length === 1 && subTitle) {
            modalOptions.subTitle = {
               text: subTitle
            };
         }

         return modalOptions;
      }

      function buildPromises(vmIds) {
         var reqProps = ['config.version', 'optionDescriptors', 'managedByInfo', 'name'];
         var promises = {
            'dataServiceProperties': dataService.getPropertiesForObjects(vmIds, reqProps),
            'is65VcOrLater': vcService.is65VcOrLater(vmIds[0])
         };
         return promises;
      }

      function okHandlerInternal(results, actionEval, isSchedule, subTitle) {
         var data = results['dataServiceProperties'];
         var is65VcOrLater = results['is65VcOrLater'];
         var supportedVersions = vmHardwareCompatibilityService
               .buildSupportedVersionsForVms(data, is65VcOrLater);

         var warning = isSchedule
               ? (actionEval.context.targetObjects.length === 1
                     ? i18nService.getString('VmUi', 'UpgradeVmVirtualHardwareForm.singleVm.schedule.warning.nonUpgradable')
                     : i18nService.getString('VmUi', 'UpgradeVmVirtualHardwareForm.schedule.warning.nonUpgradable'))
               : i18nService.getString('VmUi', 'UpgradeVmVirtualHardwareForm.singleVm.warning.nonUpgradable');

         var title = isSchedule ?
               i18nService.getString('VmUi', 'UpgradeVmVirtualHardwareForm.schedule.title') :
               i18nService.getString('VmUi', 'UpgradeVmVirtualHardwareForm.title');

         if (_.isEmpty(supportedVersions)) {
            clarityModalService.openConfirmationModal({
               title: title,
               message: warning,
               submit: function () {},
               preserveNewlines: true,
               saveButtonLabel: i18nService.getString('Common', 'alert.ok'),
               cancelButtonLabel: null,
               hideCancelButton: true,
               clarityIcon: {
                  shape: "error",
                  class: "is-error",
                  size: 48
               },
               showXIcon: false
            });
            return;
         }

         var upgradeDescriptionSingle = isSchedule ?
               i18nService.getString('VmUi', 'UpgradeVmVirtualHardwareForm.schedule.description1') :
               i18nService.getString('VmUi', 'UpgradeVmVirtualHardwareForm.description1');

         var hwVersion = {
            selectedVersion: supportedVersions[supportedVersions.length - 1]
         };

         if (isSchedule) {
            hwVersion.isUpgradeAfterGuestOsShutdown = false;
         }

         var onSubmit = function() {
            if (isSchedule) {
               return submitScheduleUpdateHardwareVersion(
                     hwVersion.selectedVersion,
                     hwVersion.isUpgradeAfterGuestOsShutdown);
            }

            return submitUpdateHardwareVersion(hwVersion.selectedVersion);
         };

         var modalOptions = buildModalOptions(supportedVersions,
               upgradeDescriptionSingle, actionEval.context.targetObjects,
               hwVersion, title, isSchedule, onSubmit, subTitle);
         clarityModalService.openOkCancelModal(modalOptions);
      }

      function submitUpdateHardwareVersion(selectedVersion) {
         var vmHwVersionSpec = {
            version: selectedVersion.id
         };
         mutationService.applyOnMultiEntity(selectedVersion.vms,
               'com.vmware.vsphere.client.vm.version.VmHwVersionSpec', vmHwVersionSpec);
         return true;
      }

      function submitScheduleUpdateHardwareVersion(hardwareVersion, isUpgradeAfterGuestOsShutdown) {
         var vmConfigSpec = {
            _type: 'com.vmware.vsphere.client.vm.VmConfigSpec',
            config: {
               _type: 'com.vmware.vim.binding.vim.vm.ConfigInfo',
               scheduledHardwareUpgradeInfo: {
                  _type: 'com.vmware.vim.binding.vim.vm.ScheduledHardwareUpgradeInfo',
                  versionKey: hardwareVersion.id,
                  upgradePolicy: isUpgradeAfterGuestOsShutdown ?
                        upgradeVmHardwareConstants.hardwareUpgradePolicy.onSoftPowerOff :
                        upgradeVmHardwareConstants.hardwareUpgradePolicy.always
               }
            }
         };
         mutationService.applyOnMultiEntity(hardwareVersion.vms,
               'com.vmware.vsphere.client.vm.VmConfigSpec', vmConfigSpec);
         return true;
      }

      function showConfirmationDialog(title, message, okHandler, subTitleText, vmId) {
         var subTitle = {};
         if (vmId) {
            subTitle.objectId = vmId;
         } else if (subTitleText) {
            subTitle.text = subTitleText;
         }
         var alertProperties = {
            useClarity: true,
            title: title,
            message: message,
            clarityIcon: {
               shape: "warning",
               class: "is-warning",
               size: 48
            },
            showXIcon: false,
            icon: null,
            yesHandler: okHandler
         };

         if (!_.isEmpty(subTitle)) {
            alertProperties.subTitle = subTitle;
         }

         actionConfirmationService.showConfirmationDialog(alertProperties);
      }
   }
})();
