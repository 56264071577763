/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace dvs_ui {

   import IPromise = angular.IPromise;
   import IRootScopeService = angular.IRootScopeService;
   import DvsEditSpanSessionDialogData =
         com.vmware.vsphere.client.h5.network.dvs.portmirroring.model.dialog.DvsEditSpanSessionDialogData;

   export class DvsEditSpanSessionDialogService {

      public static $inject: string[] = [
            "$rootScope",
            "vuiConstants",
            "vxValidatorFactory",
            "vxMultiPageDialogService",
            "i18nService",
            "dataService",
            "mutationService",
            "dvsEditSpanSessionDialogPages",
            "dvsEditSpanSessionDialogSpecFactory"
      ];

      public static DATA_PROPERTY: string =
            "dvswitchportmirroring:editSpanSessionDialogData";

      constructor(private $rootScope: IRootScopeService,
                  private vuiConstants: any,
                  private vxValidatorFactory: any,
                  private vxMultiPageDialogService: any,
                  private i18nService: any,
                  private dataService: any,
                  private mutationService: any,
                  private dvsEditSpanSessionDialogPages: DvsEditSpanSessionDialogPages,
                  private specFactory: DvsEditSpanSessionDialogSpecFactory) {

      }

      public show(dvsUrn: string, sessionKey: string, sessionType: string): void {
         let dialogScope: any = this.$rootScope.$new();

         dialogScope.dvsUrn = dvsUrn;
         dialogScope.sessionKey = sessionKey;
         dialogScope.sessionType = sessionType;
         dialogScope.busy = false;

         dialogScope.dialogOptions =
               this.createDialogOptions(dialogScope, sessionType);

         dialogScope.dialogOptions.confirmOptions = {
            onClick: (): boolean => {
               this.submitDialog(dialogScope.model);
               return true;
            }
         };

         dialogScope.model = new DvsEditSpanSessionDialogModel();
         dialogScope.model.dvsUrn = dvsUrn;

         let options: any = {
            scope: dialogScope,
            configObjectName: "dialogOptions"
         };

         this.vxMultiPageDialogService(options);

         this.requestData(dialogScope);
      }

      private createDialogOptions(dialogScope: any, sessionType: string): any {
         return {
            title: this.i18nService.getString("DvsUi",
                  "EditSpanSessionDialog.initialTitle"),
            pages: this.dvsEditSpanSessionDialogPages
                  .createPageDefinitions(dialogScope, sessionType),
            show: true,
            loading: true,
            loadingMessage: this.i18nService.getString("CommonUi", "wizard.loading"),
            validationBanner: {},
            rejectOptions: {
               rejectOnEsc: true
            },
            resizable: true,
            draggable: true,
            maximizable: true,
            height: "600px",
            width: "920px"
         };
      }

      private requestData(dialogScope: any): void {
         this.dataService.getProperties(
               dialogScope.dvsUrn,
               [DvsEditSpanSessionDialogService.DATA_PROPERTY], {
                  propertyParams: [{
                     propertyName: DvsEditSpanSessionDialogService.DATA_PROPERTY,
                     parameterType: "java.lang.String",
                     parameter: dialogScope.sessionKey
                  }]
               })
               .then((result: any) => {
                  if (result && result[DvsEditSpanSessionDialogService.DATA_PROPERTY]) {
                     this.updateDialogModel(dialogScope.model,
                           result[DvsEditSpanSessionDialogService.DATA_PROPERTY]);
                     this.initPageValidators(dialogScope.model);
                     dialogScope.dialogOptions.title = this.i18nService
                           .getString("DvsUi", "EditSpanSessionDialog.titleFormat",
                                 result[DvsEditSpanSessionDialogService.DATA_PROPERTY].dvsName);
                     dialogScope.dialogOptions.loading = false;
                  }
               });
      }

      private updateDialogModel(model: DvsEditSpanSessionDialogModel,
            data: DvsEditSpanSessionDialogData): void {
         model.originalData = data;

         model.sessionType = data.sessionType;

         this.updatePropertiesPageModel(model.propertiesPageModel, data);
         model.originalData.mirroredPacketLength =
               model.propertiesPageModel.mirroredPacketLength;

         this.updateSourcesPageModel(model.selectSourcesPageModel, data);
         this.updateDestinationsPageModel(model.selectDestinationsPageModel, data);
      }

      private updatePropertiesPageModel(model: DvsSpanSessionPropertiesPageModel,
            data: DvsEditSpanSessionDialogData): void {
         model.sessionName = data.sessionName;
         model.enabled = data.enabled;
         model.description = data.description;
         model.samplingRate = data.samplingRate;

         model.mirroredPacketLengthEnabled =
               data.mirroredPacketLengthEnabled;
         model.mirroredPacketLength = data.mirroredPacketLengthEnabled
               ? data.mirroredPacketLength : 60;

         model.encapsulationType = data.encapsulationType;
         model.sessionId = data.sessionId;

         model.isTcpIpStackSupported = data.mirrorNetstackSupported;
         model.tcpIpStack = data.tcpIpStack;

         model.erspanSupported = data.erspanSupported;

         model.encapsulationVlanId = data.encapsulationVlanId;
         model.preserveOriginalVlan = data.preserveOriginalVlan;

         model.normalTrafficAllowed = data.normalTrafficAllowed;
      }

      private updateSourcesPageModel(model: DvsSpanSessionSelectSourcesPageModel,
            data: DvsEditSpanSessionDialogData): void {
         let trafficDirectionByPortId: { [portId: string]: string } = {};

         (data.sourcePortsIngress || []).forEach((portId: string): void => {
            trafficDirectionByPortId[portId] =
                  DvsSpanSessionConstants.TRAFFIC_DIRECTION.INGRESS;
         });

         (data.sourcePortsEgress || []).forEach((portId: string): void => {
            trafficDirectionByPortId[portId] =
                  typeof trafficDirectionByPortId[portId] === "undefined"
                  ? DvsSpanSessionConstants.TRAFFIC_DIRECTION.EGRESS
                  : DvsSpanSessionConstants.TRAFFIC_DIRECTION.BOTH;
         });

         model.portsListModel.ports = [];
         Object.getOwnPropertyNames(trafficDirectionByPortId)
               .forEach((portId: string): void => {
                  model.portsListModel.ports.push(new PortData(portId,
                        trafficDirectionByPortId[portId]));
               });

         model.vlanIdListModel.values = [];
         model.vlanIdListModel.cachedData = [];

         (data.sourceVlans || []).forEach((vlan: number): void => {
            model.vlanIdListModel.values.push(String(vlan));
            model.vlanIdListModel.cachedData.push({
               value: String(vlan),
               active: false,
               valid: true
            });
         });
      }

      private updateDestinationsPageModel(model: DvsSpanSessionSelectDestinationsPageModel,
            data: DvsEditSpanSessionDialogData): void {
         model.ipAddressesListModel.values = [];
         model.ipAddressesListModel.cachedData = [];

         (data.destinationAddresses || []).forEach((ip: string): void => {
            model.ipAddressesListModel.values.push(ip);
            model.ipAddressesListModel.cachedData.push({
               value: ip,
               active: false,
               valid: true
            });
         });

         (data.destinationPorts || []).forEach((portId: string): void => {
            model.portsListModel.ports.push(new PortData(portId,
                  DvsSpanSessionConstants.TRAFFIC_DIRECTION.BOTH));
         });

         (data.uplinkPortsSelected || []).forEach((uplinkName: string): void => {
            model.uplinksListModel.uplinks.push(uplinkName);
         });

         (data.uplinkPortsExisting || []).forEach((uplinkName: string): void => {
            model.uplinksListModel.notSelectedUplinks.push(uplinkName);
         });
      }

      private initPageValidators(dialogModel: DvsEditSpanSessionDialogModel): void {
         dialogModel.pageValidators["editPropertiesPage"] =
               new DvsSpanSessionPageValidator(
                     dialogModel.propertiesPageModel,
                     this.vxValidatorFactory.create());

         dialogModel.pageValidators["selectDestinationAddressesPage"] =
               new DvsSpanSessionPageValidator(
                     dialogModel.selectDestinationsPageModel.ipAddressesListModel,
                     this.vxValidatorFactory.create());

         dialogModel.pageValidators["selectSourceVlansPage"] =
               new DvsSpanSessionPageValidator(
                     dialogModel.selectSourcesPageModel.vlanIdListModel,
                     this.vxValidatorFactory.create());
      }

      private submitDialog(dialogModel: DvsEditSpanSessionDialogModel): void {
         let data: DvsEditSpanSessionDialogData | null =
               this.specFactory.buildChanges(dialogModel);
         if (data) {
            this.mutationService.apply(dialogModel.dvsUrn, data._type, data);
         }
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .service("dvsEditSpanSessionDialogService", DvsEditSpanSessionDialogService);
}
