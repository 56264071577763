/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace dvs_ui {
   import IPromise = angular.IPromise;

   export class DvsRectifyHostsOutOfSyncComponent {

      public bindings: any;
      public controller: any;
      public templateUrl: string;

      constructor() {
         this.bindings = {
            hosts: "<",
            selectedHosts: "="
         };
         this.controller = DvsRectifyHostsOutOfSyncController;
         this.templateUrl = "dvs-ui/resources/dvs/summary/components/dvsRectifyHostsOutOfSyncTemplate.html";
      }
   }

   class DvsRectifyHostsOutOfSyncController {

      static $inject = [
         "i18nService",
         "networkUiConstants"
      ];

      private hosts: any;

      private selectedHosts: any;

      constructor(public i18nService: any,
                  private networkUiConstants: any) {
      }

      public onSelectedHostsChange(value: HostListItemData[]): void {
         this.selectedHosts = value;
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .component("dvsRectifyHostsOutOfSync", new DvsRectifyHostsOutOfSyncComponent());
}
