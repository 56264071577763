/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
(function() {

   angular.module('com.vmware.vsphere.client.storage')
      .controller('DatastoreFileBrowserController', DatastoreFileBrowserController);

   DatastoreFileBrowserController.$inject = [
      '$scope', '$rootScope', 'timeFormatterService', '$element', '$timeout', 'i18nService',
      'datastoreBrowserConstants', 'vuiConstants', 'vuiUtils',
      'actionsService', 'dataService', 'bytesFilter',
      'datastoreBrowserTreeService', 'fileUploadService', 'fileUploadConstants',
      'vuiActionsMenuService',
      'actionConfirmationService',
      'alertService',
      'authorizationService',
      'browserService',
      'vcService',
      '$q', 'datastoreFileBrowserActionsService',
      'datastoreFileBrowserGridColumnConfigService',
      'datastoreTypeConstants',
      'commonActionBarService',
      'streamVmdkService',
      '$location'];

   function DatastoreFileBrowserController (
      $scope, $rootScope, timeFormatterService, $element, $timeout, i18nService,
      datastoreBrowserConstants, vuiConstants, vuiUtils,
      actionsService, dataService, bytesFilter,
      datastoreBrowserTreeService, fileUploadService, fileUploadConstants,
      vuiActionsMenuService, actionConfirmationService, alertService,
      authorizationService, browserService, vcService, $q,
      datastoreFileBrowserActionsService,
      datastoreFileBrowserGridColumnConfigService,
      datastoreTypeConstants,
      commonActionBarService,
      streamVmdkService,
      $location
   ) {

      var FILE_TYPE = 'file';
      var FOLDER_TYPE = 'folder';
      var VM_CONFIG_INFO_CLASS_NAME = 'VmConfigInfo';
      var TEMPLATE_VM_CONFIG_INFO_CLASS_NAME = 'TemplateVmConfigInfo';
      var VM_TEMPLATE_FILE_EXT = 'vmtx';
      var REFRESH_EVENT = 'dataRefreshInvocationEvent';

      var DS_ACCESSIBLE_PROP = "summary/accessible";
      var DS_FREESPACE_PROP = "info/freeSpace";
      var DS_TYPE_PROP = "summary/type";

      var IE_UPLOAD_FILE_SIZE_LIMIT = 4 * 1024 * 1024 * 1024; // 4 GB

      var self = this;

      var cachedItems = [];
      var cachedSearchedItems = [];

      var datastoreFreeSpace;

      self.searchFilesLoading = false;
      self.isFileSetLoading = false;
      self.isSearchResultsDirty = false;
      self.curretSearchRequestCanceller = null;

      self.uploadBatch = new UploadBatch();

      self.popupActionBarOptions = {
         actions: []
      };

      if (h5.isDatastoreBrowserPopUpWindow) {
         var queryStringParams = $location.search();
         $scope.datastoreId = queryStringParams["datastoreId"];
      } else {
         $scope.datastoreId = $scope._route.objectId;

         commonActionBarService.updateActionBar(
            self.popupActionBarOptions,
            "actions",
            [$scope.datastoreId],
            [{actionId: "vsphere.core.datastore.actions.popupDsBrowser"}]);
      }

      $scope.i18nService = i18nService;
      $scope.fileQuery = datastoreBrowserConstants.fileQueryType.ALL_FILES;
      $scope.dsBrowserTreeAccessor = {};
      $scope.isInSearchState = false;
      $scope.isSearchTriggered = false;

      $scope.comparator = function(value) {
         var itemToSelect = _.find(cachedItems, function(cachedItem) {
            return cachedItem.path === value.path;
         });
         return itemToSelect !== undefined;
      };
      $scope.searchComparator = function(value) {
         var itemToSelect = _.find(cachedSearchedItems, function(cachedItem) {
            return cachedItem.path === value.path;
         });
         return itemToSelect !== undefined;
      };

      $scope.$on('$destroy', function() {
         cancelActiveSearchRequest();
      });

      function initController () {
         var ACTIONS = datastoreFileBrowserActionsService.ACTIONS;
         self.splitterOptions = {
            orientation: vuiConstants.splitter.orientation.HORIZONTAL,
            panes: [{ min: '250px', size: '25%' }, { min: '250px', size: '100%' }]
         };

         self.progressSplitterOptions = {
            orientation: vuiConstants.splitter.orientation.VERTICAL,
            panes: [{ min: '250px', size: '75%' }, {
               min: '100px',
               size: '25%',
               collapsible: true,
               collapsed: true
            }]
         };

         self.isViewAvailabilityResolved = false;
         var viewAvailabilityPromises = {
            privilegeCheck: authorizationService.checkPrivileges($scope.datastoreId, ["Datastore.Browse", "Datastore.Config"]),
            datastoreProperties: dataService.getProperties($scope.datastoreId, [DS_ACCESSIBLE_PROP, DS_FREESPACE_PROP])
         };

         $q.all(viewAvailabilityPromises).then(function(responses) {
            if (responses.privilegeCheck[0] !== true) {
               self.viewUnavailableReason = i18nService.getString("StorageUi",
                  "datastore.fileManager.noDatastoreBrowsePermision");
               self.isViewAvailable = false;
            } else if (responses.datastoreProperties[DS_ACCESSIBLE_PROP] !== true) {
               self.viewUnavailableReason = i18nService.getString("StorageUi",
                  "datastore.fileManager.datastoreInaccessible");
               self.isViewAvailable = false;
               self.uploadProgressExpand = fileUploadService.getRunningUploadSessions().length > 0;
            } else {
               self.hasDsCongidPrivilege = responses.privilegeCheck[1];
               self.isViewAvailable = true;
            }

            datastoreFreeSpace = responses.datastoreProperties[DS_FREESPACE_PROP];

         }, function() {
            // In case of error show empty datastore browser view
            self.isViewAvailable = true;
         }).finally(function() {
            self.isViewAvailabilityResolved = true;
         });

         self.showUploadMenu = showUploadMenu;

         self.uploadSessionsDatagridOptions = {
            resizable: true,
            height: '100%',
            selectionMode: vuiConstants.grid.selectionMode.SINGLE,
            selectedItems: [],
            columnDefs: datastoreFileBrowserGridColumnConfigService.getUploadSessionsGridColumnDefs(),
            data: fileUploadService.getUploadSessions($scope.datastoreId),
            pageConfig: {
               hidePager: true
            }
         };
         var runningUploadSessions = fileUploadService.getRunningUploadSessions($scope.datastoreId);
         if (runningUploadSessions.length > 0) {
            // Show the upload grid footer,
            // as there is currently an upload running.
            self.uploadBatch.init(runningUploadSessions);
         }
         self.uploadProgressExpand = false;

         $scope.$on(fileUploadConstants.event.UPDATED, refreshUploadedItems);

         $scope.$on(fileUploadConstants.event.COMPLETE,
            function(event, uploadSession) {
               self.uploadBatch.updateItem(uploadSession);
               refreshDsBrowserTreeNode(uploadSession.datastorePath);
               updateSearchResults();
            });

         $scope.$on(REFRESH_EVENT, function (event, uploadSession) {
            if (self.uploadProgressExpand && !uploadSession) {
               // Do not refresh if the event is triggered by
               // fileUploadConstants.event.COMPLETE
               refreshUploadedItems();
            }
         });

         self.datagridOptions = {
            resizable: true,
            height: '100%',
            selectionMode: vuiConstants.grid.selectionMode.MULTI,
            showCheckboxesOnMultiSelection: false,
            selectedItems: [],
            pageConfig: {
               displayMode: vuiConstants.grid.displayMode.VIRTUAL_SCROLLING
            },
            columnDefs: getFileBrowserColumns(self.isVsanDatastore && self.is65VcOrLater),
            actionBarOptions: { actions: [] },
            data: []
         };

         self.searchDatagridOptions = angular.copy(self.datagridOptions);

         // dsFilesGrid specific initialization
         self.datagridOptions.dataBound = function() {
            if (self.datagridOptions.data && self.datagridOptions.data.length > 0) {
               $('#dsFilesGrid tbody tr').on("dblclick", function() {
                  if (self.datagridOptions.selectedItems &&
                     self.datagridOptions.selectedItems.length === 1 &&
                     self.datagridOptions.selectedItems[0].type === FOLDER_TYPE) {
                     var selectedItemId = self.datagridOptions.selectedItems[0].path;
                     $scope.dsBrowserTreeAccessor.selectChildItem(selectedItemId);
                  }
               });
            }
         };

         self.refreshView = function() {
            updateSearchResults();
            if (!self.isFileSetLoading) {
               refreshDsBrowserTree();
            }
         };

         // Detects selection change in dsFilesGrid.
         $scope.$watch(function() {
               return self.datagridOptions.selectedItems;
            },
            function(newSelectedItems, oldSelectedItems) {
               if (!_.isEqual(newSelectedItems, oldSelectedItems)) {
                  if (newSelectedItems && newSelectedItems.length) {
                     cachedItems = newSelectedItems;
                  }
                  updateActionBar(self.datagridOptions);
               }
            });

         // Detects selection change in searchFilesGrid.
         $scope.$watch(function() {
               return self.searchDatagridOptions.selectedItems;
            },
            function(newSelectedItems, oldSelectedItems) {
               if (!_.isEqual(newSelectedItems, oldSelectedItems)) {
                  if (newSelectedItems && newSelectedItems.length) {
                     cachedSearchedItems = newSelectedItems;
                  }
                  updateActionBar(self.searchDatagridOptions);
               }
            });

         self.keyPress = function(event) {
            if (event.keyCode === 13) {
               // In IE Enter would activates the focused item so we prevent this default behavior.
               event.preventDefault();

               self.doSearch();
            }
         };

         self.toggleSearchState = function() {
            self.isInSearchState = !self.isInSearchState;
            if (self.isInSearchState && self.isSearchResultsDirty) {
               search();
            }
         };

         function updateSearchResults() {
            if (self.isInSearchState) {
               search();
            } else {
               // trigger a new search next time when switching to search results.
               self.isSearchResultsDirty = true;
            }
         }

         self.doSearch = function() {
            if (self.searchTerm && self.searchTerm !== "" && self.rootItem) {
               self.currentSearchTerm = self.searchTerm;
               self.isSearchTriggered = true;
               self.isInSearchState = true;
               search();
            } else if (!self.searchTerm && self.rootItem) {
               self.isSearchTriggered = false;
               self.isInSearchState = false;
            }
            cachedSearchedItems = [];
         };

         self.currentSearchTerm = null;

         datastoreFileBrowserActionsService.buildActionBarItems(
            $scope.datastoreId, calculateActionEnabled).then(function(builder) {

            builder
               .addAction(ACTIONS.CREATE_FOLDER, function() {
                  var currentPath = self.selectedItem.path;
                  return {
                     currentPath: currentPath,
                     topLevelDirectoryCreateSupported: $scope.topLevelDirectoryCreateSupported,
                     isRootFolder: isRootItemSelected(),
                     datastoreDatacenter: $scope.dc,
                     callback: function() {
                        updateSearchResults();
                        refreshDsBrowserTreeNode(currentPath);
                     }
                  };
               })
               .addAction(ACTIONS.UPLOAD_FILE, {}, onFileUploadClick);

            if (browserService.isWebkitDirectorySupported()) {
               builder.addAction(ACTIONS.UPLOAD_FOLDER, {}, onFolderUploadClick);
            }

            var actions = builder.addSeparator();
            if (h5 && !h5.isDatastoreBrowserPopUpWindow) {
               actions.addAction(ACTIONS.REGISTER_VM, function() {
                  var dsFileItem = getSelectedFileItem(self.datagridOptions);
                  var vmFileName = dsFileItem.name;
                  var vmFileExtension =
                     vmFileName.substring(vmFileName.lastIndexOf('.') + 1);
                  return {
                     vmName: vmFileName.substring(0, vmFileName.lastIndexOf('.')),
                     vmFilePath: dsFileItem.path,
                     isTemplate: vmFileExtension === VM_TEMPLATE_FILE_EXT
                  };
               });
            }

            actions
               .addAction(ACTIONS.DOWNLOAD_FILE, getDownloadFileActionContext.bind(self, self.datagridOptions))
               .addAction(ACTIONS.COPY_FILE, getCopyFileActionContext.bind(self, self.datagridOptions, ACTIONS.COPY_FILE))
               .addAction(ACTIONS.MOVE_FILE, getCopyFileActionContext.bind(self, self.datagridOptions, ACTIONS.MOVE_FILE))
               .addAction(ACTIONS.RENAME_FILE, getRenameFileActionContext.bind(self, self.datagridOptions))
               .addAction(ACTIONS.DELETE_FILE, getDeleteFileActionContext.bind(self, self.datagridOptions));

            if (!self.isVsanDatastore) {
               actions.addAction(ACTIONS.INFLATE_THIN_DISK, function () {
                  var currentPath = self.selectedItem.path;
                  return {
                     spec: createInflateFileSpec(self.datagridOptions.selectedItems[0]),
                     callback: function () {
                        updateSearchResults();
                        reloadFileList(currentPath);
                     }
                  };
               });
            }

            self.datagridOptions.actionBarOptions.actions = builder.build();
            initUploadHandlers();

         });

         dataService.getProperties(
            $scope.datastoreId,
            ['capability.topLevelDirectoryCreateSupported', 'dc'])
            .then(function(datastoreData) {
               $scope.topLevelDirectoryCreateSupported =
                  datastoreData['capability.topLevelDirectoryCreateSupported'] !== false;
               $scope.dc = datastoreData.dc;
            });

         self.selectedTreeItemChanged = function(selectedItem) {
            if (selectedItem) {
               self.isFileSetLoading = true;

               if (self.selectedItem
                  && selectedItem.id !== self.selectedItem.id) {

                  cachedItems = [];
               }
            }

            self.selectedItem = selectedItem;
            self.datagridOptions.data = [];

            updateActionBar(self.datagridOptions);
         };

         self.selectedTreeItemFilesLoaded = function(selectedTreeItemContentFiles) {
            formatFileItems(selectedTreeItemContentFiles);
            self.datagridOptions.data = selectedTreeItemContentFiles;

            updateActionBar(self.datagridOptions);
            self.isFileSetLoading = false;
         };

         self.onDsTreeInitialized = function(rootItems) {
            if (rootItems && rootItems.length > 0) {
               var oldRootItemPath = self.rootItem ? self.rootItem.path : null;
               self.rootItem = rootItems[0];
               if (oldRootItemPath && self.rootItem && oldRootItemPath !== self.rootItem.path && self.isInSearchState) {
                  search();
               }
            }
         };

         function showUploadMenu (event) {
            var uploadSession = event.data[0];

            // New scope object for the vuiActionsMenu two way binding
            var localScope = $scope.$new();
            localScope.menuDescriptor = {
               label: i18nService.getString('StorageUi', 'actionMenuFileTransfer'),
               iconClass: 'vx-icon-dsUpload',
               items: [{
                  id: 'cancel_upload_id',
                  label: i18nService.getString('StorageUi', 'actionCancelFileTransfer'),
                  iconClass: 'vx-icon-removeIcon',
                  enabled: uploadSession.isCancelable,
                  onClick: function() {
                     var uploadSession = event.data[0];
                     uploadSession.cancel();
                  }
               }]
            };
            var target = event.currentTarget;
            if (!target && event) {
               target = event.target;
            }

            var menuOptions = {
               scope: localScope,
               configObjectName: 'menuDescriptor',
               target: target,
               coordinates: { x: event.clientX, y: event.clientY },
               menuContainerId: 'applicationMenuContainer'
            };

            vuiActionsMenuService.showMenu(menuOptions);
         }

         function getFileBrowserColumns (hasFrinedlyNameColumn) {
            return datastoreFileBrowserGridColumnConfigService.getDatastoreBrowserColumnConfig(hasFrinedlyNameColumn);
         }

         function onFileUploadClick () {
            var fileUploadInput = $element.find('.datastoreUploadFileInput')[0];
            if (self.isVsanDatastore) {
               openStreamVmdkDialog(fileUploadInput);
            } else {
               activateUploadInput(fileUploadInput);
            }
         }

         function onFolderUploadClick () {
            var folderUploadInput = $element.find('.datastoreUploadFolderInput')[0];
            if (self.isVsanDatastore) {
               openStreamVmdkDialog(folderUploadInput);
            } else {
               activateUploadInput(folderUploadInput);
            }
         }

         function openStreamVmdkDialog(inputElement) {
            streamVmdkService.openStreamVmdkWarningDialog().then(function (response) {
               if (response !== null) {
                  activateUploadInput(inputElement);
               }
            });
         }

         function activateUploadInput(inputElement) {
            $timeout(function() {
               // Open file browser.
               inputElement.click();
            }, 0);
         }

         function refreshUploadedItems() {
            vuiUtils.safeApply($scope, function() {
               self.uploadProgressExpand = true;
               var uploadSessionItems = fileUploadService.getUploadSessions($scope.datastoreId) || [];
               uploadSessionItems.forEach(function(item) {

                  self.uploadBatch.updateItem(item);

                  timeFormatterService.formatDate(item.lastUpdated).then(function(formattedDate) {
                     item.lastUpdatedFormatted = formattedDate;
                  });

                  timeFormatterService.formatDate(item.startTime).then(function(formattedDate) {
                     item.startTimeFormatted = formattedDate;
                  });

               });
               self.uploadSessionsDatagridOptions.data = uploadSessionItems;
            });
         }

         function getDeleteFileActionContext (datagridOptions, isInvokedFromSearchState) {
            var currentPath = self.selectedItem.path;
            var deleteFileSpec = createDatastoreDeleteFileSpec(datagridOptions.selectedItems);
            var deleteFilesOnly = _.every(datagridOptions.selectedItems, function(item) {
               return item.type === FILE_TYPE;
            });

            return {
               targetFileNames: _.pluck(datagridOptions.selectedItems, 'formattedName'),
               spec: deleteFileSpec,
               callback: function() {

                  updateSearchResults();
                  if (deleteFilesOnly) {
                     reloadFileList(currentPath);
                     return;
                  }

                  if (isInvokedFromSearchState) {
                     // trigger full refresh in case the action was invoked
                     // from the search state.
                     refreshDsBrowserTree();
                     return;
                  }

                  var selectedItemPath = self.selectedItem.path;
                  if (_.some(deleteFileSpec.targetFiles, function (deletedPath) {
                        return selectedItemPath === deletedPath ||
                           (selectedItemPath.length > deletedPath.length &&
                           deletedPath + "/" === selectedItemPath.substring(0, deletedPath.length + 1));
                     })) {
                     // if current folder (or any of its parent folders) has been deleted,
                     // trigger full tree refresh
                     refreshDsBrowserTree();
                  } else {
                     // else refresh only the current tree node
                     refreshDsBrowserTreeNode(currentPath);
                  }
               }
            };
         }

         function getRenameFileActionContext (datagridOptions, isTriggeredFromSearchState) {
            var currentPath = self.selectedItem.path;
            var fileItem = datagridOptions.selectedItems[0];
            return {
               spec: createDatastoreRenameFileSpec(fileItem),
               callback: function() {
                  updateSearchResults();
                  if (fileItem.type === FOLDER_TYPE) {
                     if (isTriggeredFromSearchState) {
                        // trigger full refresh in case the action was invoked from the
                        // search state
                        refreshDsBrowserTree();
                     } else {
                        // refresh both ds folder tree and file list.
                        refreshDsBrowserTreeNode(currentPath);
                     }
                  } else {
                     // update only the file list when renaming files.
                     reloadFileList(currentPath);
                  }
               }
            };
         }

         function getCopyFileActionContext (datagridOptions, actionId) {

            var currentPath = self.selectedItem.path;
            var filesOnly = _.every(datagridOptions.selectedItems, function(item) {
               return item.type === FILE_TYPE;
            });

            return {
               datastoreId: $scope.datastoreId,
               datastoreDcRef: $scope.dc,
               selectedFiles: datagridOptions.selectedItems,
               callback: function(destinationDatastoreId, destinationPath) {

                  if (actionId === ACTIONS.COPY_FILE) {
                     // don't refresh anything if the files are copied to
                     // another datastore.
                     if (destinationDatastoreId === $scope.datastoreId) {
                        updateSearchResults();
                        if (filesOnly) {
                           // update only the file list.
                           reloadFileList(currentPath);
                        } else {
                           // trigger full refresh in case we are copying folders.
                           refreshDsBrowserTree();
                        }
                     }
                  } else {
                     updateSearchResults();
                     if (filesOnly) {
                        reloadFileList(currentPath);
                        if (destinationDatastoreId === $scope.datastoreId) {
                           reloadFileList(destinationPath);
                        }
                     } else {
                        // trigger full refresh in case we are moving folders.
                        refreshDsBrowserTree();
                     }
                  }
               }
            };
         }

         function getDownloadFileActionContext (datagridOptions) {
            return {
               datastoreId: $scope.datastoreId,
               datastoreDcRef: $scope.dc,
               selectedFiles: datagridOptions.selectedItems,
               zipFileName: getDownloadZipFileName(datagridOptions),
               isStreamFormat: self.isVsanDatastore
            };
         }

         function getDownloadZipFileName (datagridOptions) {
            if (datagridOptions.selectedItems.length > 1) {
               if ($scope.isInSearchState) {
                  return self.rootItem.name + "_files.zip";
               } else {
                  return self.selectedItem.name + "_files.zip";
               }
            } else if (datagridOptions.selectedItems.length === 1 &&
                  datagridOptions.selectedItems[0].type === FOLDER_TYPE) {
               return datagridOptions.selectedItems[0].formattedName + ".zip";
            }
            return null;
         }

         function formatFileItems (items) {
            items.forEach(function(fileItem) {
               if (fileItem.size === null || fileItem.size === undefined) {
                  fileItem.formattedSize = '';
               } else {
                  fileItem.formattedSize = bytesFilter(fileItem.size, 'B', 'KB');
               }

               if (fileItem.lastModified) {
                  timeFormatterService.formatDate(fileItem.lastModified).then(function(formattedDate) {
                     fileItem.lastModifiedFormatted = formattedDate;
                  });
               }

               fileItem.formattedName = fileItem.friendlyName
                  ? fileItem.friendlyName : fileItem.name;
            });
         }

         function initUploadHandlers () {
            var datastoreUploadFileInput = $element.find('.datastoreUploadFileInput');
            datastoreUploadFileInput.on('change', function() {
               uploadFiles(this.files, false);
               // Reset so selecting the same file will
               // trigger onchange event.
               this.value = null;
            });
            var datastoreUploadFolderInput = $element.find('.datastoreUploadFolderInput');
            datastoreUploadFolderInput.on('change', function() {
               uploadFiles(this.files, true);
               // Reset so selecting the same folder will
               // trigger onchange event.
               this.value = null;
            });
         }

         function uploadFiles (fileList, isFolder) {
            var filesToUpload = _.map(fileList, function(file) {
               return file;
            });

            var totalFileSize = 0;
            var maxFileSize = 0;
            _.forEach(filesToUpload, function(file) {
               if (file.size) {
                  totalFileSize += file.size;
                  maxFileSize = Math.max(maxFileSize, file.size);
               }
            });

            if (totalFileSize > datastoreFreeSpace) {
               alertService.error(
                     i18nService.getString("StorageUi", "upload.errorTitle"),
                     i18nService.getString("StorageUi", "upload.errorFileSizeExceedsFreeSpace"));
               return;
            }

            if (browserService.isIe && (maxFileSize > IE_UPLOAD_FILE_SIZE_LIMIT)) {
               alertService.error(
                     i18nService.getString("StorageUi", "upload.errorTitle"),
                     i18nService.getString("StorageUi", "upload.errorFileSizeExceedsIeLimit"));
               return;
            }

            var existingFileFlag = false;
            var existingFileNames = [];
            var existingFolderName;
            _.forEach(filesToUpload, function(file) {
               var relativeRootFolder = null;
               if (file.webkitRelativePath) {
                  var firstFolderDelimiterIndex = file.webkitRelativePath.indexOf('/');
                  if (firstFolderDelimiterIndex > 0) {
                     relativeRootFolder = file.webkitRelativePath.substring(0, firstFolderDelimiterIndex);
                  }
               }
               var fileNameToCheck = relativeRootFolder || file.name;
               var existingFile = getFileItemByName(self.datagridOptions.data, fileNameToCheck);
               if (existingFile) {
                  if (existingFile.type === FILE_TYPE) {
                     existingFileFlag = true;
                     existingFileNames.push(existingFile.name);
                  } else {
                     existingFolderName = existingFile.name;
                  }
               }
            });
            var getString = _.partial(i18nService.getString, 'StorageUi');
            if (existingFolderName) {
               alertService.error(
                  getString(isFolder
                     ? 'uploadFolderAction.confirmation.title'
                     : 'uploadAction.confirmation.title'),
                  getString(isFolder
                     ? 'uploadFolderAction.confirmation.directorylreadyExistsError'
                     : 'uploadAction.confirmation.directorylreadyExistsError', existingFolderName)
               );
            } else if (existingFileFlag) {
               if (isFolder) {
                  alertService.error(
                     getString('uploadFolderAction.confirmation.title'),
                     getString('uploadFolderAction.confirmation.fileAlreadyExistsError')
                  );
               } else {
                  actionConfirmationService.showConfirmationDialog({
                     title: getString('uploadAction.confirmation.title'),
                     message: getString('uploadAction.confirmation.fileAlreadyExists',
                        _.map(existingFileNames, function(name) {
                           return "\u2022 " + name;
                        }).join('\n')),
                     yesHandler: upload
                  });
               }
            } else {
               upload();
            }

            function upload () {
               datastoreFileBrowserActionsService.requestActions(
                     $scope.datastoreId, [ACTIONS.UPLOAD_FILE])
                  .then(function(actionsById) {
                     if(self.selectedItem && self.selectedItem.path) {

                        self.uploadBatch.init(filesToUpload);
                        actionsService.invokeAction(
                              actionsById[ACTIONS.UPLOAD_FILE],
                              {
                                 datastoreId: $scope.datastoreId,
                                 datastoreDcRef: $scope.dc,
                                 path: self.selectedItem.path,
                                 files: filesToUpload,
                                 isDatastoreSelected: self.selectedItem.type === 'datastore',
                                 isStreamFormat: self.isVsanDatastore
                                 && _.some(filesToUpload,
                                       function (file) {
                                          return file && file.name
                                                && file.name.toLocaleLowerCase().indexOf(".vmdk") === file.name.length - 5;
                                       })
                              });
                     }
                  });
            }
         }

         function getSelectedFileItem (datagridOptions) {
            if (!datagridOptions || !datagridOptions.selectedItems ||
               datagridOptions.selectedItems.length === 0 ||
               datagridOptions.selectedItems[0].type === FOLDER_TYPE) {
               return null;
            }

            return datagridOptions.selectedItems[0];
         }


         function updateActionBar (gridOptions) {
            if (self.selectedItem) {
               datastoreFileBrowserActionsService.updateActionBar(
                  $scope.datastoreId,
                  gridOptions.actionBarOptions.actions,
                  calculateActionEnabled.bind({ gridOptions: gridOptions })
               );
            } else {
               datastoreFileBrowserActionsService.disableAll(gridOptions.actionBarOptions.actions);
            }
         }

         function calculateActionEnabled (actionId, actionDef) {
            var gridOptions = this.gridOptions;
            if (!actionId || !actionDef || !gridOptions) {
               return false;
            }

            var actionAvailable = actionDef.available && !!self.selectedItem && !self.isFileSetLoading;
            if (!actionAvailable) {
               return false;
            }

            switch (actionId) {
               case ACTIONS.CREATE_FOLDER:
                  return checkCreateAvailability();
               case ACTIONS.INFLATE_THIN_DISK:
                  return gridOptions.selectedItems.length === 1 && !!gridOptions.selectedItems[0].isInflatable;
               case ACTIONS.DELETE_FILE:
                  return gridOptions.selectedItems && gridOptions.selectedItems.length;
               case ACTIONS.COPY_FILE:
               case ACTIONS.MOVE_FILE:
                  if (isRootItemSelected() && isOsfsDatastore()) {
                     return false;
                  }
                  return gridOptions.selectedItems && gridOptions.selectedItems.length;
               case ACTIONS.DOWNLOAD_FILE:
                  return isDownloadActionEnabledForSelection(gridOptions);
               case ACTIONS.REGISTER_VM:
                  return isRegisterVmEnabledForSelection(gridOptions);
               case ACTIONS.RENAME_FILE:
                  if (isRootItemSelected() && isOsfsDatastore()) {
                     return false;
                  }
                  return gridOptions.selectedItems
                     && gridOptions.selectedItems.length === 1;
               case ACTIONS.UPLOAD_FILE:
                  return !(isRootItemSelected() && isOsfsDatastore());
               case ACTIONS.UPLOAD_FOLDER:
                  return !(self.isVsanDatastore && isRootItemSelected());
               default:
                  return false;
            }

         }

         function isOsfsDatastore() {
            return self.isVsanDatastore || self.isVvolDatastore;
         }

         function checkCreateAvailability () {
            if (isRootItemSelected()) {
               // If top level create flag is false,
               // then create is possible if you have Datastore.Config privilege
               return $scope.topLevelDirectoryCreateSupported || self.hasDsCongidPrivilege;
            }

            return true;
         }

         function isDownloadActionEnabledForSelection (gridOptions) {
            if (gridOptions.selectedItems.length === 1 &&
               gridOptions.selectedItems[0].type === FOLDER_TYPE) {
               // return true in case of single folder item is selected.
               return true;
            }

            if (gridOptions.selectedItems.length > 0) {
               // Return false if there is at least one non-file item in the selection.
               if (_.find(gridOptions.selectedItems,
                     function(item) {
                        return item.type !== FILE_TYPE;
                     })) {
                  return false;
               }
               // all selected items are files.
               return true;
            }
            return false;
         }

         function isRegisterVmEnabledForSelection (gridOptions) {
            var selectedFiles = gridOptions.selectedItems;
            var regVmAllowedClasses =
               [VM_CONFIG_INFO_CLASS_NAME, TEMPLATE_VM_CONFIG_INFO_CLASS_NAME];
            var isAvailable = selectedFiles.length === 1 &&
               selectedFiles[0].type === FILE_TYPE &&
               _.includes(regVmAllowedClasses, selectedFiles[0].className);
            return isAvailable;
         }

         function isRootItemSelected () {
            if (!self.selectedItem || !self.selectedItem.path) {
               return false;
            }

            var selectedItemPath = self.selectedItem.path.trim();
            return selectedItemPath.length &&
               selectedItemPath[selectedItemPath.length - 1] === ']';
         }

         function search () {

            // Cancel last search request as we don't need it anymore.
            cancelActiveSearchRequest();
            self.curretSearchRequestCanceller = $q.defer();

            self.isSearchResultsDirty = false;
            self.searchDatagridOptions.data = [];
            createSearchActionBar();
            self.searchFilesLoading = true;
            datastoreBrowserTreeService.searchResults($scope.datastoreId, self.rootItem.path,
                  self.currentSearchTerm, self.curretSearchRequestCanceller.promise)
               .then(function(searchResults) {
                  formatFileItems(searchResults);
                  self.searchDatagridOptions.data = searchResults;
                  updateActionBar(self.searchDatagridOptions);
               }).finally(function() {
               self.searchFilesLoading = false;
            });
         }

         function createSearchActionBar () {
            var actions = self.searchDatagridOptions.actionBarOptions.actions;
            if (actions) {
               datastoreFileBrowserActionsService
                  .buildActionBarItems($scope.datastoreId, calculateActionEnabled).then(
                  function(builder) {
                     builder
                        .addAction(ACTIONS.DOWNLOAD_FILE, getDownloadFileActionContext.bind(self, self.searchDatagridOptions))
                        .addAction(ACTIONS.COPY_FILE, getCopyFileActionContext.bind(
                              self, self.searchDatagridOptions, ACTIONS.COPY_FILE))
                        .addAction(ACTIONS.MOVE_FILE, getCopyFileActionContext.bind(
                              self, self.searchDatagridOptions, ACTIONS.MOVE_FILE))
                        .addAction(ACTIONS.DELETE_FILE, getDeleteFileActionContext.bind(self, self.searchDatagridOptions, true))
                        .addAction(ACTIONS.RENAME_FILE, getRenameFileActionContext.bind(self, self.searchDatagridOptions, true));
                     self.searchDatagridOptions.actionBarOptions.actions = builder.build();
                  }
               );
            }
         }

         function reloadFileList(folderPath) {
            // reload file list only when folderPath is the same as the currently
            // selected folder.
            if (folderPath === self.selectedItem.path &&
                  $scope.dsBrowserTreeAccessor.reloadContentFiles) {
               $scope.dsBrowserTreeAccessor.reloadContentFiles();
            }
         }

         function refreshDsBrowserTree () {
            if ($scope.dsBrowserTreeAccessor.refreshTree) {
               $scope.dsBrowserTreeAccessor.refreshTree();
            }
         }

         function refreshDsBrowserTreeNode (path) {
            if ($scope.dsBrowserTreeAccessor.refreshTreeNode) {
               $scope.dsBrowserTreeAccessor.refreshTreeNode(path);
            }
         }

         function createDatastoreRenameFileSpec (item) {
            return {
               sourcePath: item.path,
               destinationName: item.name
            };
         }

         function createDatastoreDeleteFileSpec (items) {
            return {
               sourceDatacenter: $scope.dc,
               targetFiles: _.pluck(items, 'path'),
               fileTypes: _.pluck(items, 'fileType')
            };
         }

         function createInflateFileSpec (file) {
            return {
               sourceDatacenter: $scope.dc,
               targetFile: file.path,
               waitTaskToComplete: true
            };
         }

         function getFileItemByName (items, fileName) {
            if (!items) {
               return undefined;
            }

            return _.find(items, function(fileItem) {
               return fileItem.name === fileName;
            });
         }

      }

      function UploadBatch() {
         // Finished are considered all the upload sessions that are not running.
         var finishedUploadSessionsCount = 0;
         var finishedUploadSessionsCountSizeInB = 0;
         var filesToUploadCount = 0;
         var filesToUploadSizeInB = 0;
         // id -> item
         var runningUploadSessions = {};
         var uploadedSizeInB = 0;
         var showUploads = false;
         var isUploadRunning = false;

         var getMapLength = function(obj) {
            return _.keys(obj).length;
         };

         var getTotalSizeInB = function(obj) {
            return _.values(obj)
                  .map(function(item) { return item.uploadedSizeInB; })
                  .reduce(function(sum, value) { return sum + value; }, 0);
         };

         var init = function(allEnqueuedFilesForUpload) {
            if (getMapLength(runningUploadSessions) === 0) {
               // No running upload sessions,
               // so a new uploadBatch is started.
               showUploads = true;
               isUploadRunning = true;
               finishedUploadSessionsCount = 0;
               filesToUploadCount = allEnqueuedFilesForUpload.length;

               filesToUploadSizeInB = allEnqueuedFilesForUpload
                  .map(function(item) {
                     // The upload session items over which we iterate
                     // have different data models so we read sizeInB if existing
                     // and size otherwise.
                     return item.sizeInB || item.size;
                  })
                  .reduce(function(sum, value) { return sum + value; }, 0);
               uploadedSizeInB = 0;
               finishedUploadSessionsCountSizeInB = 0;
            } else {
               // There are already running upload sessions,
               // so add the new ones to the running uploadBatch.
               filesToUploadCount += allEnqueuedFilesForUpload.length;
               filesToUploadSizeInB += allEnqueuedFilesForUpload
                  .map(function(item) { return item.size; })
                  .reduce(function(sum, value) { return sum + value; }, 0);
            }
         };

         var cancel = function(datagridId) {
            fileUploadService.cancelFilesUpload(datagridId);
            runningUploadSessions = {};
            isUploadRunning = false;
            finishedUploadSessionsCount = filesToUploadCount;
         };

         // Notify when an item has been updated.
         var updateItem = function(uploadSessionItem) {
            if (uploadSessionItem.status === fileUploadConstants.state.CANCELED
                  || uploadSessionItem.status === fileUploadConstants.state.SUCCESS
                  || uploadSessionItem.status === fileUploadConstants.state.ERROR) {

               if (runningUploadSessions[uploadSessionItem.id]) {
                  finishedUploadSessionsCount++;
                  finishedUploadSessionsCountSizeInB += uploadSessionItem.uploadedSizeInB;
                  delete runningUploadSessions[uploadSessionItem.id];
               }
               if (getMapLength(runningUploadSessions) === 0
                  && finishedUploadSessionsCount === filesToUploadCount) {
                  runningUploadSessions = {};
                  isUploadRunning = false;
               }
            } else if (uploadSessionItem.status === fileUploadConstants.state.RUNNING) {
               runningUploadSessions[uploadSessionItem.id] = uploadSessionItem;
            }
         };

         return {
            getShowUploads: function() { return showUploads; },
            isUploadRunning: function() { return isUploadRunning; },
            getTotalUploadSessionsCount: function() { return filesToUploadCount; },
            getRunningUploadSessionsSizeInB: function() { return finishedUploadSessionsCountSizeInB + getTotalSizeInB(runningUploadSessions); },
            getFinishedUploadSessionsCount: function() { return finishedUploadSessionsCount; },
            getUploadSessionsSizeInB: function() { return filesToUploadSizeInB; },
            init: init,
            cancel: cancel,
            updateItem: updateItem
         };
      }

      function cancelActiveSearchRequest() {
         if (self.curretSearchRequestCanceller) {
            self.curretSearchRequestCanceller.resolve();
         }
      }

      function initDatastoreBrowser () {
         var datastoreTypePromise = dataService.getProperties($scope.datastoreId, [DS_TYPE_PROP]);
         var vcVersionPromise = vcService.is65VcOrLater($scope.datastoreId);
         $q.all([datastoreTypePromise, vcVersionPromise]).then(function(result) {
            self.isVsanDatastore = result[0][DS_TYPE_PROP] === datastoreTypeConstants.VSAN;
            self.isVvolDatastore = result[0][DS_TYPE_PROP] === datastoreTypeConstants.VVOL;
            self.is65VcOrLater = result[1];
            initController();
         });
      }

      initDatastoreBrowser();
   }
})();
