(function() {
   'use strict';

   /**
    * Service that opens `vmkernel adapter - Edit settings` dialog.
    */
   angular
         .module('com.vmware.vsphere.client.network')
         .service('editVmkernelAdapterDialogService', editVmkernelAdapterDialogService);

   editVmkernelAdapterDialogService.$inject = [
      '$rootScope',
      'i18nService',
      'dataService',
      'vxMultiPageDialogService',
      'editVmkernelAdapterDialogManager',
      'hostVnicServiceFactory'];

   function editVmkernelAdapterDialogService (
         $rootScope,
         i18nService,
         dataService,
         vxMultiPageDialogService,
         editVmkernelAdapterDialogManager,
         hostVnicServiceFactory) {

      return {
         show: show
      };

      /**
       * Shows `vmkernel adapter - Edit settings` dialog.
       */
      function show(actionEval, hostId, vnic) {
         var EDIT_HOST_VNIC_DATA = 'vnic:editHostVnicModel';

         var propertyParams = [{
            propertyName: EDIT_HOST_VNIC_DATA,
            parameterType: 'java.lang.String',
            parameter: vnic.device
         }];

         var vnicData = {};
         vnicData.hostId = hostId;
         vnicData.vnicDevice = vnic.device;

         var dialogOptions = {};
         var dialogScope = $rootScope.$new();

         var dialogManager = new editVmkernelAdapterDialogManager(
               vnicData, dialogOptions);

         _.extend(dialogOptions, {
            title: i18nService.getString(
                  'NetworkUi', 'EditVnicView.title', vnic.device),
            iconClass: actionEval.action.icon,
            actionDetails: {
               actionEval: actionEval,
               availableTargets: []
            },
            pages: dialogManager.getPageDefinitions(),
            show: true,
            loading: true, // wait for data to load before start initialization
            loadingMessage: i18nService.getString('CommonUi', 'wizard.loading'),
            confirmOptions: {
               onClick: dialogManager.submit
            },
            rejectOptions: {
               rejectOnEsc: true
            },
            resizable: true,
            draggable: true,
            maximizable: true
         });

         dialogScope.dialogOptions = dialogOptions;
         dialogScope.manager = dialogManager;
         dialogScope.dialogOptions.loading = true;

         var config = {
            scope: dialogScope,
            configObjectName: 'dialogOptions'
         };

         vxMultiPageDialogService(config);

         dataService
               .getProperties(
                     hostId,
                     [EDIT_HOST_VNIC_DATA],
                     { propertyParams: propertyParams })
               .then(function(result) {
                  var pageModel =
                        result[EDIT_HOST_VNIC_DATA].editPortPropertiesPageModel;

                  vnicData.portPropertiesPageModel = {};
                  vnicData.ipv4PageModel = result[EDIT_HOST_VNIC_DATA].editIpv4PageModel;
                  vnicData.ipv6PageModel = result[EDIT_HOST_VNIC_DATA].editIpv6PageModel;
                  vnicData.bindToPnicPageModel = result[EDIT_HOST_VNIC_DATA].editBindToPnicPageModel;

                  vnicData.portPropertiesPageModel.tcpIpStackName =
                        pageModel.tcpIpStackName;

                  vnicData.portPropertiesPageModel.mtuInBytes = pageModel.mtuInBytes;

                  vnicData.portPropertiesPageModel.hostVnicFeatures =
                        hostVnicServiceFactory.pickServiceFeatures(pageModel);
                  vnicData.portPropertiesPageModel.hostVnicSettings =
                        hostVnicServiceFactory.pickServiceSettings(pageModel);
                  // keep a copy of the initial settings state
                  vnicData.portPropertiesPageModel.initialHostVnicSettings =
                        hostVnicServiceFactory.pickServiceSettings(pageModel);

               }).finally(function() {
                  if (vnicData.ipv4PageModel.ipv6Enabled) {
                     dialogManager.loadIpv6Page();
                  }
                  if (vnicData.bindToPnicPageModel.opaqueNetworkSpec) {
                     dialogManager.loadBindToPnicPage();
                  }
                  dialogScope.dialogOptions.loading = false;
               });
      }
   }
})();
