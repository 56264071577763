module ds_cluster_ui {

   export class CreateDsClusterWizardDatastoresService {

      public static $inject = ['i18nService'];

      private datastoreOptions: DsFilterOption[] = [{
            label: this.i18nService.getString('DsClusterUi',
                'createDsClusterWizard.selectDatastoresPage.showDatastores.connectedToAll'),
            filterId: 'datastoresWithoutPMemDatastoresFilter'
         }, {
            label: this.i18nService.getString('DsClusterUi',
                'createDsClusterWizard.selectDatastoresPage.showDatastores.showAll'),
            filterId: 'allDatastoresWithoutPMemDatastoresFilter'
         }];

      constructor(private i18nService: any) {
      }

      public getOptions(): DsFilterOption[] {
         return this.datastoreOptions;
      }
   }

   angular.module('com.vmware.vsphere.client.dsCluster')
         .service('createDsClusterWizardDatastoresService',
               CreateDsClusterWizardDatastoresService);
}
