/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace dvs_ui {

   import DvsNetworkResourcePoolsMeterData =
         com.vmware.vsphere.client.h5.network.dvs.resmgmt.nrp.model.DvsNetworkResourcePoolsMeterData;

   export class DvsNrpReservationComponent {

      public bindings: any;
      public controller: any;
      public templateUrl: string;

      constructor() {
         this.bindings = {
            data: "<",
            dvsUrn: "<"
         };

         this.controller = DvsNrpReservationComponentController;

         this.templateUrl =
               "dvs-ui/resources/dvs/resmgmt/nrp/views/dvsNrpReservationComponentTemplate.html";
      }
   }

   class DvsNrpReservationComponentController {

      static readonly $inject = [
            "numberFormatterService"
      ];

      public groupedMeters: any[];

      public maxValue: string;

      public data: DvsNetworkResourcePoolsMeterData;

      public dvsUrn: string;

      constructor(private numberFormatterService: any) {
      }

      public $onChanges(): void {
         if (this.data) {
            this.buildMeterData();
         }
      }

      private buildMeterData(): void {
         this.groupedMeters = this.buildMeters();

         this.maxValue = this.numberFormatterService.formatBandwidth(
               this.data.configuredReservation,
               BandwidthUnit.MBITPS,
               BandwidthUnit.GBITPS,
               2,
               false);
      }

      private buildMeters(): any[] {
         let totalReservedPercentage: number = 0;
         if (this.data.configuredReservation > 0) {
            totalReservedPercentage =
                  ((this.data.grantedQuota + this.data.vmReservation)
                        / this.data.configuredReservation) * 100;
         }

         let nrpReservedPercentage: number =
               (this.data.grantedQuota / this.data.configuredReservation) * 100 || 0;
         let vmVnicReservedPercentage: number =
               (this.data.vmReservation / this.data.configuredReservation) * 100 || 0;

         let grantedQuotaMeter: any;
         let vmReservationMeter: any;
         let unusedBandwidthMeter: any;

         if (totalReservedPercentage <= 100) {
            grantedQuotaMeter = {
               colorClass: "dvs-meter-light-blue",
               value: nrpReservedPercentage
            };

            vmReservationMeter = {
               colorClass: "dvs-meter-normal-blue",
               value: vmVnicReservedPercentage
            };

            unusedBandwidthMeter = {
               colorClass: "dvs-meter-white",
               value: 100 - totalReservedPercentage
            };
         } else {
            grantedQuotaMeter = {
               colorClass: "dvs-meter-red",
               value: 100
            };

            vmReservationMeter = {
               colorClass: "dvs-meter-normal-blue",
               value: 0
            };

            unusedBandwidthMeter = {
               colorClass: "dvs-meter-white",
               value: 0
            };
         }

         return [grantedQuotaMeter, vmReservationMeter, unusedBandwidthMeter];
      }
   }

   // in sync with format.ts in ng-next-app
   enum BandwidthUnit { BITPS, KBITPS, MBITPS, GBITPS, TBITPS, AUTO }

   angular.module("com.vmware.vsphere.client.dvs")
         .component("dvsNrpReservation", new DvsNrpReservationComponent());
}