/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace network_ui {

   import IRootScopeService = angular.IRootScopeService;
   import MigrateVmkToVssSpec = com.vmware.vsphere.client.h5.network.host.migratevmkadapter.model.MigrateVmkToVssSpec;

   export class MigrateVmkToVssWizardService {
      public static $inject = [
         "$rootScope",
         "i18nService",
         "migrateVmkWizardPages",
         "wizardValidationService",
         "pageStateManagementService",
         "defaultUriSchemeUtil",
         "vuiWizardService",
         "dataService",
         "mutationService"
      ];

      constructor(private $rootScope: IRootScopeService,
                  private i18nService: any,
                  private migrateVmkWizardPages: MigrateVmkWizardPages,
                  private wizardValidationService: any,
                  private pageStateManagementService: any,
                  private defaultUriSchemeUtil: any,
                  private vuiWizardService: any,
                  private dataService: any,
                  private mutationService: any) {

      }

      private static readonly VMK_MIGRATE_HOST_NAME_PROPERTY: string =
            "vmkmigratewizard:hostName";

      public show(contextObj: any, switchName: string) {

         let wizardScope: any = this.$rootScope.$new();
         wizardScope.model = new MigrateVmkToVssWizardModel();
         wizardScope.model.hostId = contextObj;

         this.dataService.getProperties(contextObj,
               MigrateVmkToVssWizardService.VMK_MIGRATE_HOST_NAME_PROPERTY)
               .then((hostNameResult: any) => {
                  let hostName: string =
                        hostNameResult[MigrateVmkToVssWizardService.VMK_MIGRATE_HOST_NAME_PROPERTY];
                  wizardScope.wizardConfig = {
                     title: this.i18nService.getString("NetworkUi",
                           "VssVirtualNicMigrateWizard.titleFormat",
                           hostName, switchName),
                     loadingMessage: this.i18nService.getString("CommonUi", "wizard.loading"),
                     cssClass: "migrate-vmk-to-vss-wizard",
                     onFinish: (): boolean => {

                        let spec: MigrateVmkToVssSpec =
                              this.buildSpec(wizardScope.model);

                        this.mutationService.apply(
                              wizardScope.model.hostId,
                              spec._type,
                              spec);

                        return true;
                     }
                  };

                  wizardScope.model.targetStandardSwitch = switchName;
                  wizardScope.wizardConfig.pages =
                        this.migrateVmkWizardPages.createPages(wizardScope.model);
                  this.wizardValidationService.initWizardValidation(wizardScope);
                  this.pageStateManagementService
                        .registerWizardForDefaultStateManagementStrategy(wizardScope);

                  this.invokeWizard(wizardScope);
         });
      }

      private invokeWizard(wizardScope: any): void {
         this.vuiWizardService({
            scope: wizardScope,
            configObjectName: "wizardConfig"
         }).show();
      }

      private buildSpec(model: MigrateVmkToVssWizardModel): MigrateVmkToVssSpec {

         let migrateSpec: MigrateVmkToVssSpec = new MigrateVmkToVssSpec();
         migrateSpec.vmkDevice = model.selectedVmkAdapter;
         migrateSpec.networkName = model.newNetworkName;
         migrateSpec.strandardSwitchName = model.targetStandardSwitch;
         migrateSpec.vlanId = parseInt(model.vlanId);
         migrateSpec.networkToRemoveName = model.networkNameToRemove;

         return migrateSpec;
      }
   }

   angular.module("com.vmware.vsphere.client.network")
         .service("migrateVmkToVssWizardService", MigrateVmkToVssWizardService);
}