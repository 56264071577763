/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace network_lib_ui {

   export class NetStackIpv4RoutingTableViewComponent {
      public bindings: any;
      public controller: any;
      public templateUrl: string;

      constructor() {
         this.bindings = {
            tableEntries: "<"
         };
         this.controller = NetStackIpv4RoutingTableViewController;
         this.templateUrl = "network-ui/resources/network/views/host/" +
            "netStackRoutingTableViewTemplate.html";
      }
   }

   class NetStackIpv4RoutingTableViewController {

      static $inject: any = [
         "i18nService",
         "vuiConstants",
         "columnRenderersRegistry"
      ];

      public tableEntries: any;

      public datagridOptions: any;

      constructor(private i18nService: any,
                  private vuiConstants: any,
                  private columnRenderersRegistry: any,
                  private $scope: any) {

         this.createDatagridOptions();
      }

      public $onInit(): void {
         this.datagridOptions.data = this.tableEntries;
         this.createDatagridOptions();
      }

      public $onChanges(): void {
         this.datagridOptions.data = this.tableEntries;
      }

      private createDatagridOptions(): void {
         this.datagridOptions = {
            columnDefs: this.createColumnDefinitions(),
            sortMode: this.vuiConstants.grid.sortMode.NONE,
            selectionMode: this.vuiConstants.grid.selectionMode.SINGLE,
            selectedItems: [],
            data: this.tableEntries,
            resizable: true,
            height: '100%',
            pageConfig: {
               hidePager: true
            }
         };
      }

      private createColumnDefinitions(): any {

         return [{
               displayName: this.i18nService.getString(
                     'NetworkUi', 'routingtableview.networkHeader'),
               field: 'network',
               width: '150px'
            },
            {
               displayName: this.i18nService.getString(
                     'NetworkUi', 'routingtableview.subnetMask'),
               field: 'subnetMask',
               width: '150px'
            },
            {
               displayName: this.i18nService.getString(
                     'NetworkUi', 'routingtableview.gatewayHeader'),
               field: 'gateway',
               width: '150px'
            },
            {
               displayName: this.i18nService.getString(
                     'NetworkUi', 'routingtableview.deviceHeader'),
               field: 'deviceName',
               width: '150px'
            }];
      }
   }

   angular.module("com.vmware.vsphere.client.network")
         .component("netStackIpv4RoutingTableView",
         new NetStackIpv4RoutingTableViewComponent());
}
