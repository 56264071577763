module cluster_ui {

   import IController = angular.IController;
   import IPromise = angular.IPromise;
   import ArrayUpdateSpec$Operation =
         com.vmware.vim.binding.vim.option.ArrayUpdateSpec$Operation;
   export class EditHostOptionsController implements IController {

      public static $inject = ["$scope",
         "i18nService",
         "dataService",
         "hostOptionsService",
         "defaultUriSchemeUtil",
         "dpmAutomationLevelService",
         "mutationService",
         "$q"];
      public hostId: string;
      public clusterId: string;
      public loading: boolean;
      public isAlertClosed: boolean = true;
      public bmcConfigData: BmcConfigData;
      public dpmBehaviors: any;
      public selectedDpmBehavior: any;
      public powerManagementOverride: boolean;
      public defaultIpmiSettings: BmcConfigData;
      public defaultDpmBehavior: string;
      public defaultDpmOverride: boolean;
      public powerManagementConfig: any;

      constructor(private $scope: any,
                  private i18nService: any,
                  private dataService: any,
                  private hostOptionsService: HostOptionsService,
                  private defaultUriSchemeUtil: any,
                  private dpmAutomationLevelService: any,
                  private mutationService: any,
                  private $q: any) {
         this.hostId = $scope.modalOptions.dialogData.selectedHost;
         this.clusterId = $scope.modalOptions.dialogData.clusterRef;
         this.dpmBehaviors = hostOptionsService.getDpmBehaviors();
         this.loading = true;
         $scope.modalOptions.onSubmit = this.onSubmit.bind(this);

         this.getViewData();
      }

      public getViewData() {
         this.dataService.getData(
               this.hostId,
               "com.vmware.vsphere.client.clusterui.model." +
               "services.HostOptionsConfigInfoData")
               .then((data: any) => {
                  if (data) {
                     this.bmcConfigData = data.ipmiInfo;
                     this.defaultIpmiSettings = angular.merge({}, data.ipmiInfo);
                     this.powerManagementConfig = <PowerManagementConfig>{
                        hostId: this.hostId
                     };
                     this.hostOptionsService.setSelectedDpmBehavior(
                           data.clusterDpmConfigData, this.powerManagementConfig);
                  }
                  this.loading = false;
               })
               .catch((error: any) => {
                  this.loading = false;
                  this.$q.reject(error);
               });
      }

      public onSubmit(): boolean {
         if (this.bmcConfigData && !_.isEqual(this.bmcConfigData,
                     this.defaultIpmiSettings)) {
            if(!this.bmcConfigData.password) {
               this.bmcConfigData.password = "";
            }
            let validationMessages = this.hostOptionsService
                  .validatePowerConfigSoftwareSpec(this.bmcConfigData);
            if (validationMessages.length === 0) {
               this.mutationService.apply(this.hostId, "com.vmware.vsphere.client.h5." +
                     "host.config.spec.PowerConfigSoftwareSpec", this.bmcConfigData);
            } else {
               this.$scope.modalOptions.alerts = this.hostOptionsService
                     .showValidationMessages(validationMessages);
               this.isAlertClosed = false;
               return false;
            }
         }

         if (this.powerManagementConfig.defaultDpmOverride !==
               this.powerManagementConfig.powerManagementOverride ||
               this.powerManagementConfig.defaultDpmBehavior !==
               this.powerManagementConfig.selectedDpmBehavior) {
            let dpmHostConfigSpec = this.hostOptionsService.createDpmHostConfigSpec(
                  this.powerManagementConfig);
            let clusterSpec =
                  this.hostOptionsService.createClusterSpec([dpmHostConfigSpec]);
            this.mutationService.apply(this.clusterId,
                  "com.vmware.vsphere.client.cluster.ClusterComputeResourceSpec",
                  clusterSpec);
         }

         return true;
      }

      public updatePowerManagementlValue() {
         if (!this.powerManagementConfig.powerManagementOverride) {
            this.powerManagementConfig.selectedDpmBehavior =
                  this.powerManagementConfig.defaultDpmBehavior;
         }
      }
   }

   angular.module("com.vmware.vsphere.client.cluster")
         .controller("EditHostOptionsController", EditHostOptionsController);
}
