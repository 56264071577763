(function () {
   'use strict';

   angular
         .module('com.vmware.vsphere.client.dvs')
         .service('dvPortgroupVlanPageService',
               dvPortgroupVlanPageService);

   /**
    * Service dedicated for dvPortgroupVlanPage template.
    */
   dvPortgroupVlanPageService.$inject = [
      'mutationService',
      '$q'];

   function dvPortgroupVlanPageService(mutationService, $q) {

      return {
         getTrunkVlanRanges: getTrunkVlanRanges
      };

      /**
       * Validates and retrieves the NumericRanges corresponding
       * to the provided string value.
       */
      function getTrunkVlanRanges(dvPgId, trunkVlanRangesString) {
         if (!trunkVlanRangesString) {
            return $q.resolve(null);
         }

         return mutationService.validate(dvPgId,
               'com.vmware.vsphere.client.h5.network.dvportgroup.model.DvPortgroupTrunkVlanSpec',
               {
                  _type: 'com.vmware.vsphere.client.h5.network.dvportgroup.model.DvPortgroupTrunkVlanSpec',
                  numericRanges: trunkVlanRangesString.trim()
               })
               .then(function (validationResult) {
                  return validationResult && validationResult.result;
               }, function () {
                  return null;
               });
      }
   }
})();
