namespace cluster_ui {

   export class AddClusterVmOverridesWizardService {
      static $inject: string[] =
            ["$rootScope", "dataService", "vuiClusterAddVmOverridesWizard", "clusterVmOverridesPageService"];
      public wizardScope: any;

      constructor(private $rootScope: any,
                  private dataService: any,
                  private vuiClusterAddVmOverridesWizard: VuiClusterAddVmOverridesWizard,
                  private clusterVmOverridesPageService: ClusterVmOverridesPageService) {
      }

      public invokeWizard(targetUid: string, manager: AddClusterVmOverridesManager, focusTarget: any): void {
         manager.setClusterId(targetUid);
         manager.setSelectedVms([]);
         this.wizardScope = this.$rootScope.$new();
         let pages: Array<any> = this.createWizardPages(this.wizardScope);
         this.dataService.getProperties(targetUid, ['name'])
               .then((response: any): void => {
                  if (response && response.name) {
                     let clusterName: string = response.name;
                     manager.setClusterName(clusterName);
                     this.vuiClusterAddVmOverridesWizard.show(pages, this.wizardScope, manager, focusTarget);
                  }
               });
      }

      private createWizardPages(wizardScope: any): Array<any> {
         let pages: Array<any> = [];
         pages.push(this.clusterVmOverridesPageService.buildSelectVmsPage(wizardScope));
         pages.push(this.clusterVmOverridesPageService.buildAddVmOverridesPage(wizardScope));
         return pages;
      }

   }

   angular.module("com.vmware.vsphere.client.cluster")
         .service("addClusterVmOverridesWizardService", AddClusterVmOverridesWizardService);
}

