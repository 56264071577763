/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace network_ui {

   export class NetStackDetailsViewService {

      public static readonly $inject = [
         "dataService",
         "i18nService",
         "vxPropertyViewService"];

      public static readonly DATA_PROPERTY: string = "netstack:netStackConfig";

      constructor(private dataService: any,
                  private i18nService: any,
                  private propertyViewService: any) {
      }

      public getNetStackDetails(hostUrn: string, netStackKey: string): any {
         let netStackConfigSpec: any = {};
         netStackConfigSpec.netStackInstanceKey = netStackKey;

         let params: any = {
            propertyParams: [{
               propertyName: NetStackDetailsViewService.DATA_PROPERTY,
               parameterType: "com.vmware.vsphere.client.h5.network.host.netstack.model.NetStackConfigSpec",
               parameter: netStackConfigSpec
            }]
         };

         return this.dataService.getProperties(
               hostUrn, [NetStackDetailsViewService.DATA_PROPERTY], params)
               .then((response: any) => {
                  return response[NetStackDetailsViewService.DATA_PROPERTY];
               });
      }

      public buildDnsPropertiesView(netStackDetails: any, isDefaultNetStack: boolean): any {
         if (netStackDetails) {
            let builder = this.propertyViewService.createPropertyViewBuilder();
            this.buildDnsCategory(builder, netStackDetails, isDefaultNetStack);

            return builder.build();
         }
      }

      public buildRoutingPropertiesView(netStackDetails: any): any {
         if (netStackDetails) {
            let builder = this.propertyViewService.createPropertyViewBuilder();
            this.buildRoutingCategory(builder, netStackDetails);

            return builder.build();
         }
      }

      public buildAdvancedPropertiesView(netStackDetails: any): any {
         if (netStackDetails) {
            let builder = this.propertyViewService.createPropertyViewBuilder();
            this.buildAdvancedCategory(builder, netStackDetails);

            return builder.build();
         }
      }

      private buildDnsCategory(builder: any,
                               netStackDetails: any, isDefaultNetStack: boolean): void {

         // Add "DNS" tab
         let dnsCategory: any = builder.category("dns");

         const methodType: string = netStackDetails.isDhcpEnabled ?
               this.i18nService.getString("H5NetworkUi", "DnsAndRoutingView.dhcp",
                     netStackDetails.selectedVnic) :
               this.i18nService.getString("H5NetworkUi", "DnsAndRoutingView.static");

         const noData: string = this.i18nService
               .getString("H5NetworkUi", "NetStackInstanceList.noData");

         const dnsMethodLabel: string = this.i18nService
               .getString("H5NetworkUi", "DnsAndRoutingView.dnsMethod");

         dnsCategory.section("general").property(dnsMethodLabel, methodType);

         if (isDefaultNetStack) {
            const hostNameLabel: string = this.i18nService
                  .getString("H5NetworkUi", "DnsAndRoutingView.hostNameLabel");
            const domainNameLabel: string = this.i18nService
                  .getString("H5NetworkUi", "DnsAndRoutingView.hostDomainLabel");

            dnsCategory.section("general")
                  .property(hostNameLabel, netStackDetails.hostName)
                  .property(domainNameLabel, netStackDetails.domainName);
         }

         const preferredDnsLabel: string = this.i18nService
               .getString("H5NetworkUi", "DnsAndRoutingView.preferredDnsServerLabel");
         const alternateDnsLabel: string = this.i18nService
               .getString("H5NetworkUi", "DnsAndRoutingView.alternateDnsServerLabel");
         const searchDomainsLabel: string = this.i18nService
               .getString("H5NetworkUi", "DnsAndRoutingView.searchDomain");

         dnsCategory.section("general")
               .property(preferredDnsLabel, netStackDetails.preferredDns || noData)
               .property(alternateDnsLabel, netStackDetails.alternateDns || noData)
               .property(searchDomainsLabel, netStackDetails.searchDomains || noData);
      }

      private buildRoutingCategory(builder: any, netStackDetails: any): void {
         // Add "Routing" tab
         let routingCategory: any = builder.category("routing");

         const noData: string = this.i18nService
               .getString("H5NetworkUi", "NetStackInstanceList.noData");
         const ipv4GatewayLabel: string = this.i18nService
               .getString("H5NetworkUi", "DnsAndRoutingView.ipv4VmkernelGateway");

         routingCategory.section("general")
               .property(ipv4GatewayLabel, netStackDetails.defaultGateway || noData);

         if (netStackDetails.isIpv6Enabled) {
            const ipv6GatewayLabel: string = this.i18nService
                  .getString("H5NetworkUi", "DnsAndRoutingView.ipv6VmkernelGateway");
            routingCategory.section("general")
                  .property(ipv6GatewayLabel, netStackDetails.ipV6DefaultGateway || noData);
         }
      }

      private buildAdvancedCategory(builder: any, netStackDetails: any): void {
         // Add "Advanced" tab
         let advancedCategory: any = builder.category("advanced");
         let section: any = advancedCategory.section("general");

         const noData: string = this.i18nService
               .getString("H5NetworkUi", "NetStackInstanceList.noData");
         const congestionControlAlgorithmLabel: string = this.i18nService
               .getString("NetworkUi", "AdvancedNetStackSettingsView.congestionControl");

         let congestionControlAlgorithmValue: string = "";
         if (netStackDetails.congestionControlAlgorithm === "newreno") {
            congestionControlAlgorithmValue = this.i18nService
                  .getString("NetworkUi", "AdvancedNetStackPage.newReno");
         }
         if (netStackDetails.congestionControlAlgorithm === "cubic") {
            congestionControlAlgorithmValue = this.i18nService
                  .getString("NetworkUi", "AdvancedNetStackPage.cubic");
         }

         section.property(congestionControlAlgorithmLabel,
               congestionControlAlgorithmValue || noData);

         const maxNumberOfConnectionsLabel: string = this.i18nService.getString(
               "NetworkUi", "AdvancedNetStackSettingsView.maxNumberOfConnections");

         section.property(maxNumberOfConnectionsLabel,
               netStackDetails.maxNumberOfConnections || noData);

         if (netStackDetails.runtimeMaxNumberOfConnections !==
               netStackDetails.maxNumberOfConnections &&
               netStackDetails.isRuntimeInfoActive) {
            section.message(this.i18nService.getString(
                  "NetworkUi", "AdvancedNetStackPage.restartWarning"),
                  'network-lib-ui-icon-restartWarning');
         }
      }
   }

   angular.module("com.vmware.vsphere.client.network")
         .service("netStackDetailsViewService", NetStackDetailsViewService);
}
