namespace h5_vm {

   import IComponentController = angular.IComponentController;
   import IComponentOptions = angular.IComponentOptions;
   import CpuMmuOption = h5_vm.CpuMmuOption;
   import FlagInfo = com.vmware.vim.binding.vim.vm.FlagInfo;

   export class VmHardwareCpuIoMmuController implements IComponentController {

      static $inject: string[] = [ "i18nService", "vmHardwareCpuIoMmuService" ];
      public i18n: Function;
      public configFlags: FlagInfo;
      public privileges: Array<string>;
      public powerState: string;
      private vmWorkflowMode: VmWorkflowMode;
      private onIoMmuChange: any;
      private vbsEnabled: boolean;
      private signPostParams: any;
      constructor(private i18nService: any, private vmHardwareCpuIoMmuService: any) {
         this.i18n = i18nService.getString;

         this.vmHardwareCpuIoMmuService.getSignPostParams()
             .then((value: any) => {
                this.signPostParams = value;
             });
      }

      private onChange = () => {
         this.onIoMmuChange({configFlags: this.configFlags});
      };

      private disabled = (): boolean => {
         return this.vmHardwareCpuIoMmuService.isDisabled(this.vmWorkflowMode,
            this.vbsEnabled, this.powerState, this.privileges);
      }
   }

   export class VmHardwareCpuIoMmuComponent implements IComponentOptions {

      public controller: any;
      public templateUrl: string;
      public bindings: any;

      constructor() {
         this.controller = VmHardwareCpuIoMmuController;
         this.templateUrl = "vm-ui/resources/vm/views/components/vmHardwareCpuIoMmu/vm-hardware-cpu-io-mmu.html";
         this.bindings = {
            configFlags: "<",
            privileges: "<",
            vmWorkflowMode: "<",
            powerState: "<",
            onIoMmuChange: "&",
            vbsEnabled: "<"
         };
      }

   }

   angular.module("com.vmware.vsphere.client.vm")
         .component("vmHardwareCpuIoMmu", new VmHardwareCpuIoMmuComponent());

}
