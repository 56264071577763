angular.module('com.vmware.vsphere.client.cluster').directive('deviationMeter', function () {
   'use strict';

   return {
      replace: true,
      scope: {
         getCurrentDeviation: '&currentDeviation', // must be a number in [0, 1]
         getTargetDeviation: '&targetDeviation' // must be a number in [0, 1]
      },
      templateUrl: 'cluster-ui/resources/cluster/components/deviationMeter.html',
      link: function(scope) {
         scope.currentDeviation = 0;
         scope.targetDeviation = 0;

         scope.$watch('getCurrentDeviation()', function() {
            scope.currentDeviation = convertDeviation(scope.getCurrentDeviation());
         });

         scope.$watch('getTargetDeviation()', function() {
            scope.targetDeviation = convertDeviation(scope.getTargetDeviation());
         });

         function convertDeviation(deviation) {
            if (typeof deviation !== 'number' || isNaN(deviation) ||
                  deviation < 0 || 1 < deviation) {
               deviation = 0;
            }
            return deviation * 100;
         }
      }
   };
});
