namespace storage_ui {

   export class AdvancedOptionsList {
      public options: any[];
      public datagridOptions: any;
      public selectedOptions: any[];

      public hostId:string;
      public hba:any;

      public static $inject = ["vuiConstants", "i18nService", "$scope", "datagridActionBarService"];

      constructor(private vuiConstants: any,
                  private i18nService: any,
                  private $scope: any,
                  private datagridActionBarService: any) {
         this.initializeDataGridOptions();
      }

      $onInit(): void {
         this.datagridOptions.data = this.options;

         this.$scope.$watch(() => {
            return this.datagridOptions.selectedItems;
         }, (newSelection:any, oldSelection:any) => {
            if (newSelection === oldSelection || _.isEqual(newSelection, oldSelection)) {
               return;
            }
            this.selectedOptions = newSelection;
         });

         this.$scope.comparator = (value: any) => {
            var itemToSelect = _.find(this.selectedOptions, function(cachedItem: any) {
               return cachedItem.option === value.option;
            });
            return itemToSelect !== undefined;
         };
      }

      private initializeDataGridOptions() {
         this.datagridOptions = {
            columnDefs: this.getColumnDefs(),
            data: [],
            height: "100%",
            selectionMode: this.vuiConstants.grid.selectionMode.SINGLE,
            searchable: false,
            resizable: true,
            selectedItems: [],
            pageConfig: {
               hidePager: true
            },
            actionBarOptions: {
               actions: []
            }
         };

      }

      public getColumnDefs(): any {
         return [
            {
               displayName: this.i18nService.getString("StorageUi", "storage.adapters.iscsi.advancedOptions.column.option"),
               field: "option",
               width: "300",
               template: "#:option#"
            },
            {
               displayName: this.i18nService.getString("StorageUi", "storage.adapters.iscsi.advancedOptions.column.description"),
               field: "description",
               width: "400",
               template: "#:description#"
            },
            {
               displayName: this.i18nService.getString("StorageUi", "storage.adapters.iscsi.advancedOptions.column.value"),
               field: "value",
               width: "300",
               template: "#:value#"
            }
         ];
      }

      public $onChanges() {
         this.datagridOptions.data = this.options;

         let self = this;

         this.datagridActionBarService.updateActionBar(self.datagridOptions, [self.hostId], [
            {
               actionId: 'vsphere.core.host.actions.adapters.editAdvancedOptionsForAdapter',
               getActionInvocationContext: function() {
                  return {
                     hba: self.hba
                  };
               }
            }
         ]);
      }
   }

   angular.module("com.vmware.vsphere.client.storage")
         .component("advancedOptionsList", {
            templateUrl: "storage-ui/resources/storage/views/host/adapters/advanced/AdvancedOptionsList.html",
            bindings: {
               options: "<",
               selectedOptions: "<?",
               hostId: "<",
               hba: "<"
            },
            controller: AdvancedOptionsList
         });
}
