module host_ui {
    export interface EncryptionMode {
        key: string;
        label: string;
    }

    class EditEncryptionModeController implements angular.IController {
        static $inject = ["$scope", "i18nService", "dataService", "mutationService"];

        public encryptionMode: EncryptionMode;
        public encryptionModes: any = [];
        public isEncryptionModeEnabled: boolean;
        private hostId: string;

        constructor(private $scope: any,
                    private i18nService: any,
                    private dataService: any,
                    private mutationService: any) {

            $scope.modalOptions.onSubmit = this.onSubmit.bind(this);
            this.hostId = $scope._route.objectId;
            this.encryptionModes = [
                this.getModeByKey("safe"),
                this.getModeByKey("incapable"),
            ];

            this.getData();
        }

        private getData(): void {
            this.dataService.getProperties(this.hostId, [
                "runtime.cryptoState"
            ]).then((result: any) => {
                if (result) {
                    if (result["runtime.cryptoState"] && result["runtime.cryptoState"] === "safe") {
                        this.isEncryptionModeEnabled = true;
                        this.encryptionMode = this.getModeByKey("safe");
                    }
                    else {
                        this.isEncryptionModeEnabled = false;
                        this.encryptionMode = this.getModeByKey("incapable");
                    }
                }
            });
        }

        private onSubmit(): boolean {
            if (!this.isEncryptionModeEnabled) {
                this.mutationService.apply(
                    this.hostId,
                    "com.vmware.vsphere.client.host.config.HostEncryptionModeSpec",
                    {"encryptionMode": this.encryptionMode.key}
                );
            }

            return true;
        }

        private getModeByKey(key: string): EncryptionMode {
            return (({
                "safe": {
                    key: "safe",
                    label: this.i18nService.getString("HostUi", "hostEncryption.mode.enabled")
                } as EncryptionMode,
                "incapable": {
                    key: "incapable",
                    label: this.i18nService.getString("HostUi", "hostEncryption.mode.disabled")
                } as EncryptionMode
            } as {[index: string]: EncryptionMode}) [key]);
        }

    }

    angular.module("com.vmware.vsphere.client.host")
        .controller("editEncryptionModeController", EditEncryptionModeController);

}
