namespace h5_vm {
   export class Privileges {
      static readonly VM_CONFIG_RESOURCE: string = "VirtualMachine.Config.Resource";
      static readonly VM_CONFIG_ADVANCEDCONFIG: string = "VirtualMachine.Config.AdvancedConfig";
      static readonly VM_CONFIG_MKSCONTROL = "VirtualMachine.Config.MksControl";
      static readonly VM_HOST_USB_DEVICE: string = "VirtualMachine.Config.HostUSBDevice";
      static readonly VM_ADDREMOVEDEVICE: string = "VirtualMachine.Config.AddRemoveDevice";
      static readonly VM_ADDREMOVEDEVICE_PRIVILEGE: string = "VirtualMachine.Config.AddRemoveDevice";
      static readonly VM_REMOVEDISK_PRIVILEGE: string = "VirtualMachine.Config.RemoveDisk";
      static readonly DATASTORE_ALLOCATESPACE_PRIVILEGE: string = "Datastore.AllocateSpace";
      static readonly VM_DISKEXTEND_PRIVILEGE: string = "VirtualMachine.Config.DiskExtend";
      static readonly VM_EDITDEVICE_PRIVILEGE: string = "VirtualMachine.Config.EditDevice";
      static readonly VM_RESOURCE_PRIVILEGE: string = "VirtualMachine.Config.Resource";
      static readonly VM_ADDNEWDISK_PRIVILEGE: string = "VirtualMachine.Config.AddNewDisk";
      static readonly VM_RAWDEVICE_PRIVILEGE: string = "VirtualMachine.Config.RawDevice";
      static readonly VM_ADDEXISTINGDISK_PRIVILEGE: string = "VirtualMachine.Config.AddExistingDisk";
      static readonly VM_CONFIG_SWAPFILEPLACEMENT: string = "VirtualMachine.Config.SwapPlacement";
      static readonly VM_REMOVE_SNAPSHOT: string = "VirtualMachine.State.RemoveSnapshot";
      static readonly VM_REVERT_SNAPSHOT: string = "VirtualMachine.State.RevertToSnapshot";
      static readonly VM_RENAME_SNAPSHOT: string = "VirtualMachine.State.RenameSnapshot";
      static readonly VM_CONFIG_RENAME: string = "VirtualMachine.Config.Rename";
      static readonly VM_CONFIG_SETTINGS: string = "VirtualMachine.Config.Settings";
      static readonly VM_CONFIG_CPU_COUNT: string = "VirtualMachine.Config.CPUCount";
      static readonly VM_CONFIG_MEMORY: string = "VirtualMachine.Config.Memory";
      static readonly NETWORK_ASSIGN: string = "Network.Assign";
      static readonly VM_INTERACT_DEVICE_CONNECTION: string = "VirtualMachine.Interact.DeviceConnection";
      static readonly VM_INTERACT_SET_CD_MEDIA: string = "VirtualMachine.Interact.SetCDMedia";
      static readonly VM_INTERACT_POWER_ON: string = "VirtualMachine.Interact.PowerOn";
      static readonly STORAGE_PROFILE_VIEW: string = "StorageProfile.View";
      static readonly CRYPTOGRAPHER_MANAGE_KEY_SERVERS: string = "Cryptographer.ManageKeyServers";
      static readonly CRYPTOGRAPHER_MANAGE_ENCRYPTION_POLICY: string = "Cryptographer.ManageEncryptionPolicy";
      static readonly CRYPTOGRAPHER_ENCRYPT_NEW_VM: string = "Cryptographer.EncryptNew";
      static readonly CRYPTOGRAPHER_ENCRYPT: string = "Cryptographer.Encrypt";
      static readonly VM_INVENTORY_REGISTER: string = "VirtualMachine.Inventory.Register";
      static readonly VM_INVENTORY_CREATE_FROM_EXISTING: string = "VirtualMachine.Inventory.CreateFromExisting";
      static readonly VM_INVENTORY_CREATE: string = "VirtualMachine.Inventory.Create";
      static readonly ADD_LIBRARY_ITEM: string = "ContentLibrary.AddLibraryItem";
      static readonly VAPP_IMPORT: string = "VApp.Import";
   }
}
