/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
/**
 * Directive used to show a list of storage devices.
 *
 * Example:
 * <div
 *    device-list
 *    devices="devices"
 *    selectedDevice="deviceToSelect"
 *    show-snapshot-column="true"
 *    on-selected-device-changed="selectedDeviceChanged(selectedDevice)"></div>
 */
(function () {
   'use strict';

   angular.module('com.vmware.vsphere.client.storage').directive('deviceList', deviceList);

   deviceList.$inject = ['i18nService', 'storageUtil', 'columnRenderersRegistry', 'bytesFilter', 'booleanFilter', 'vuiConstants'];

   function deviceList(i18nService, storageUtil, columnRenderersRegistry, bytesFilter, booleanFilter, vuiConstants) {

      var directive = {
         replace: true,
         scope: {
            devices: '=', // Array of storage devices to be shown.
            showPager: '=?',
            selectedDevice: '=?',  // Optional device to be selected initially.
            selectedDeviceChanged: '&onSelectedDeviceChanged',
            showSnapshotColumn: '=?', // Optional flag controlling the visibility of the Snapshot column
            showExpandableColumn: '=?' // Optional flag controlling the visibility of the Expandable column
         },
         templateUrl: 'storage-ui/resources/storage/directives/deviceList/deviceList.html',
         link: link
      };

      return directive;

      function link(scope, element, attrs) {

         var bindOnSelectedDeviceChanged = !!attrs.onSelectedDeviceChanged;
         var showSnapshotColumn = !!attrs.showSnapshotColumn;
         var showExpandableColumn = !!attrs.showExpandableColumn;
         var hidePager = !attrs.showPager;
         scope.$watch(
               function () {
                  return scope.devices;
               }, function (newValue, oldValue) {
                  if (newValue !== oldValue) {
                     updateDeviceItems(scope);
                  }
               });

         scope.datagridOptions = {
            height: '100%',
            pageConfig: {
               hidePager: hidePager
            },
            columnDefs: getColumnDefs(),
            sortMode: vuiConstants.grid.sortMode.SINGLE,
            selectionMode: vuiConstants.grid.selectionMode.SINGLE,
            selectedItems: [],
            resizable: true,
            columnMenu: {
               sortable: false, // this will hide sort menu items
               messages: {
                  columns: i18nService.getString('CommonUi', 'listview.showColumnsMenu'),
                  filter: i18nService.getString('CommonUi', 'listview.filterMenu')
               }
            }
         };

         updateDeviceItems(scope);

         // Detects selection change in the storage devices list.
         scope.$watch(function() { return scope.datagridOptions.selectedItems; },
               function(selectedItems) {
                  if (selectedItems && selectedItems.length) {
                     notifySelectionChangeListener(selectedItems[0]);
                  } else {
                     notifySelectionChangeListener(null);
                  }
               });

         scope.preselectComparator = function(gridDataItem) {
            if (!scope.selectedDevice || !gridDataItem) {
               return false;
            }

            return scope.selectedDevice.disk.devicePath === gridDataItem.scsiDiskInfo.disk.devicePath;
         };

         var _selectedItem = null;
         function notifySelectionChangeListener(selectedDataItem) {
            if (bindOnSelectedDeviceChanged) {
               if (_selectedItem !== selectedDataItem) {
                  _selectedItem = selectedDataItem;
                  scope.selectedDeviceChanged({
                     selectedDevice: selectedDataItem ? selectedDataItem.scsiDiskInfo : null
                  });
               }
            }
         }

         function getColumnDefs() {
            var result = [
               {
                  displayName: i18nService.getString('StorageUi', 'deviceList.columns.name'),
                  field: 'name',
                  width: '180px',
                  template: '#:name#'
               },
               {
                  displayName: i18nService.getString('StorageUi', 'storage.adapters.devices.identifier'),
                  field: 'identifier',
                  visible:false,
                  width: '100px',
                  template: '#:identifier#'
               },
               {
                  displayName: i18nService.getString('StorageUi', 'deviceList.columns.lun'),
                  field: 'localizedLunNumber',
                  width: '70px',
                  sortable: function(di1, di2) {
                     return storageUtil.compareNumericValues(di1, di2, 'lun');
                  },
                  template: '#:localizedLunNumber#'
               },
               {
                  displayName: i18nService.getString('StorageUi', 'deviceList.columns.capacity'),
                  field: 'localizedCapacity',
                  width: '100px',
                  sortable: function(di1, di2) {
                     return storageUtil.compareNumericValues(di1, di2, 'capacity');
                  },
                  template: (function (data) {
                     return columnRenderersRegistry.getColumnRenderer('number')
                     (['localizedCapacity'], data);
                  })
               },
               {
                  displayName: i18nService.getString('StorageUi', 'deviceList.columns.vStorage'),
                  field: 'vStrorage',
                  width: '100px',
                  template: '#:vStrorage#'
               },
               {
                  displayName: i18nService.getString('StorageUi', 'deviceList.columns.driveType'),
                  field: 'driveType',
                  width: '90px',
                  template: '#:driveType#'
               },
               {
                  displayName: i18nService.getString('StorageUi', 'storage.adapters.devices.sectorFormat'),
                  field: 'sectorFormat',
                  width: '150px',
                  template: '#:sectorFormat#'
               }];

            if (showSnapshotColumn) {
               result.push(
                  {
                     displayName: i18nService.getString('StorageUi', 'deviceList.columns.snapshot'),
                     field: 'snapshot',
                     width: '80px',
                     template: '#:snapshot#'
                  }
               );
            }

            if (showExpandableColumn) {
               result.push(
                  {
                     displayName: i18nService.getString('StorageUi', 'deviceList.columns.expandable'),
                     field: 'expandable',
                     width: '80px',
                     template: '#:expandable#'
                  }
               );
            }
            return result;
         }
      }

      function updateDeviceItems(scope) {
         var deviceItems = [];
         if (scope.devices) {
            deviceItems = _.map(scope.devices, function (device) {
               var diskCapacity = storageUtil.lbaToBytes(device.disk.capacity);
               return {
                  name: storageUtil.formatDeviceName(device.disk),
                  identifier: device.disk.canonicalName,
                  lun: device.lun,
                  localizedLunNumber: storageUtil.formatLunNumber(device.lun),
                  capacity: diskCapacity,
                  localizedCapacity: bytesFilter(diskCapacity, 'B', 'Auto', 2),
                  vStrorage: storageUtil.formatVStorageSupport(device.disk.vStorageSupport),
                  driveType: storageUtil.formatSSDValue(device.disk.ssd),
                  snapshot: storageUtil.formatVmfsLabel(device),
                  expandable: booleanFilter(device.expandable),
                  scsiDiskInfo: device,
                  sectorFormat: storageUtil.getSectorFormatByScsiDiskType(device.disk.scsiDiskType)
               };
            });
         }

         if (scope.datagridOptions) {
            scope.datagridOptions.data = deviceItems;
         }
      }
   }
})();
