namespace h5_vm {

   import VmConfigContext = com.vmware.vsphere.client.vm.config.VmConfigContext;
   class VmHardwareUsbControllerCtrl implements ng.IComponentController {

      static $inject: string[] = [
         "i18nService"
      ];

      // input to this component
      inflatedDevice: any;
      selectedGuestOs: any;
      removeCallback: Function;
      vmConfigContext: VmConfigContext;
      virtualMachineDevices: any;
      vmWorkflowMode: VmWorkflowMode;
      expanded: boolean;

      private i18n: Function;
      private rawDevice: any;

      constructor(private i18nService: any) {
         this.i18n = (key: string, ...rest: any[]) => i18nService.getString("VmUi", "USBControllerPage." + key, rest);
      }

      $onInit() {
         this.rawDevice = this.inflatedDevice.getCurrentDevice();
      }

      public getDeviceLabel(): String {
         if (!this.rawDevice) {
            return "";
         } else {
            return this.rawDevice.deviceInfo.label;
         }
      }

      public isUnsupportedByGuest(): boolean {
         return h5_vm.VmHardwareUsbControllerUtil.isUnsupportedByGuest(this.inflatedDevice, this.selectedGuestOs);
      }

      public getUSBLabel(): String {
         if (!this.rawDevice) {
            return "";
         }

         let type: string = this.rawDevice._type;

         return this.getUsbTypeLabel(type);
      }

      public getUsbTypeList(): any {

         let options: Array<any> = this.vmConfigContext.environment.configOption.hardwareOptions.virtualDeviceOption;

         let usb3option: any = _.find(options, function (item: any) {
            return item.type.wsdlName === "VirtualUSBXHCIController";
         });

         let retVal: any = [
            h5_vm.VmHardwareUsbControllerUtil.GetUsb20VmodlType()
         ];

         if (usb3option) {
            retVal.push(h5_vm.VmHardwareUsbControllerUtil.GetUsb30VmodlType());
         }
         return retVal;
      }

      public getUsbTypeLabel(value: string): string {
         let i18nKey: string = h5_vm.VmHardwareUsbControllerUtil.GetUsbTypeI18nKey(value);
         return this.i18nService.getString("VmUi", i18nKey);
      }

      get usbType(): string {
         return this.rawDevice._type;
      }

      set usbType(value: string) {
         this.rawDevice._type = value;
      }

      public isUsbTypeEditable(): boolean {
         if (this.inflatedDevice && this.inflatedDevice.isNew()) {
            return true;
         } else {
            return false;
         }
      }

      public isMarkedForRemoval(): boolean {
         if (!this.inflatedDevice) {
            return false;
         } else {
            return this.inflatedDevice.isMarkedForRemoval();
         }
      }

      isRemoveDisabled(): boolean {
         return VmHardwareUsbControllerUtil.isRemovalDisabled(this.inflatedDevice, this.virtualMachineDevices, this.vmConfigContext, this.vmWorkflowMode);
      }

      removeDevice() {
         if (!this.isRemoveDisabled() && this.removeCallback) {
            this.removeCallback()(this.inflatedDevice);
         }
      }

      revertRemoval() {
         if (this.inflatedDevice) {
            this.inflatedDevice.revertRemoval();
         }
      }
   }

   class VmHardwareUsbControllerComponent implements ng.IComponentOptions {
      bindings: any;
      controller: any;
      templateUrl: string;

      constructor() {
         this.bindings = {
            inflatedDevice: "<",
            selectedGuestOs: "<",
            removeCallback: "&",
            vmConfigContext: "<",
            virtualMachineDevices: "<",
            vmWorkflowMode: "<",
            expanded: "="
         };
         this.controller = VmHardwareUsbControllerCtrl;
         this.templateUrl = "vm-ui/resources/vm/views/settings/vmHardwareSettings/vmHardwareUsbController/vm-hardware-usb-controller.html";
      }
   }

   angular.module("com.vmware.vsphere.client.vm")
         .component("vmHardwareUsbController", new VmHardwareUsbControllerComponent());
}
