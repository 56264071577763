/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
(function() {
   'use strict';
   angular.module('com.vmware.vsphere.client.host').controller('AddHostHostSummaryPageController', AddHostHostSummaryPageController);

   AddHostHostSummaryPageController.$inject = ['$scope'];

   function AddHostHostSummaryPageController($scope) {
      _.extend(this, $scope.manager.getHostSummaryPageModel());
      _.extend(this, {
         warningMessage: $scope.manager.getHostAlreadyManagedMessage()
      });
   }
})();
