/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */

/**
 * Manages the page states of the DVS Add and Manage Hosts wizard.
 */
namespace dvs_ui {

   export class DvsAddHostWizardFlowManager {

      public static $inject = [
         "pageStateManagementService",
         "vuiConstants"];

      public pages: WizardPage[];

      constructor(private pageStateManagementService: any,
                  private vuiConstants: any) {
      }

      public init(wizardScope: DvsAddHostWizardScope): void {
         this.pages = wizardScope.wizardConfig.pages;

         this.pageStateManagementService
               .registerWizardForDefaultStateManagementStrategy(wizardScope);

         wizardScope.$watchGroup([
                  "model.selectOperationPageModel.selectedOperationType"],
               (newValues: any[]) => {
                  this.updatePagesVisibility.apply(this, newValues);
               });
      }

      private updatePagesVisibility(operationType: string) {
         let isAddWorkflow: boolean =
               operationType === DvsAddHostWizardConstants.operationType.ADD_HOST;
         let isRemoveWorkflow: boolean =
               operationType === DvsAddHostWizardConstants.operationType.REMOVE_HOST;
         let isManageWorkflow: boolean =
               operationType === DvsAddHostWizardConstants.operationType.MANAGE_HOST;
         let isMigrateWorkflow: boolean =
               operationType === DvsAddHostWizardConstants.operationType.MIGRATE_NETWORKING;

         this.togglePageVisibility(DvsAddHostWizardConstants.page.DVS_SELECT_WORKFLOW_PAGE,
               isAddWorkflow || isRemoveWorkflow || isManageWorkflow);
         this.togglePageVisibility(DvsAddHostWizardConstants.page.DVS_SELECT_HOSTS_PAGE,
               isAddWorkflow);
         this.togglePageVisibility(DvsAddHostWizardConstants.page.DVS_SELECT_PHYSICAL_ADAPTERS_PAGE,
               isAddWorkflow || isManageWorkflow || isMigrateWorkflow);
         this.togglePageVisibility(DvsAddHostWizardConstants.page.DVS_SELECT_VIRTUAL_ADAPTERS_PAGE,
               isAddWorkflow || isManageWorkflow || isMigrateWorkflow);
         this.togglePageVisibility(DvsAddHostWizardConstants.page.DVS_SELECT_VIRTUAL_MACHINES_PAGE,
               isAddWorkflow || isManageWorkflow || isMigrateWorkflow);

         this.togglePageVisibility(DvsAddHostWizardConstants.page.DVS_SELECT_HOSTS_TO_MANAGE_PAGE,
               isManageWorkflow);

         this.togglePageVisibility(DvsAddHostWizardConstants.page.DVS_SELECT_HOSTS_TO_REMOVE_PAGE,
               isRemoveWorkflow);

      }

      private togglePageVisibility(pageId: string, isVisible: boolean) {
         let page = _.findWhere(this.pages, {id: pageId});
         if (isVisible) {
            // change the state to DISABLED only if the page is currently invisible.
            if (page.state === this.vuiConstants.wizard.pageState.SKIPPED) {
               page.state = this.vuiConstants.wizard.pageState.DISABLED;
            }
         } else if (page) {
            page.state = this.vuiConstants.wizard.pageState.SKIPPED;
         }
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .service("dvsAddHostWizardFlowManager", DvsAddHostWizardFlowManager);
}
