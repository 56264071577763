/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace dvs_ui {

   export class DvsUpgradeNiocSummaryPageComponent {

      public bindings: any;
      public controller: any;
      public templateUrl: string;

      constructor() {
         this.bindings = {
            wizardModel: "<"
         };

         this.controller = DvsUpgradeNiocSummaryPageComponentController;

         this.templateUrl = "dvs-ui/resources/dvs/niocupgrade/components/" +
               "dvsUpgradeNiocSummaryPageTemplate.html";
      }
   }

   class DvsUpgradeNiocSummaryPageComponentController {

      static $inject = [
         "i18nService",
         "vxPropertyViewService",
         "$scope"
      ];

      public wizardModel: DvsUpgradeNiocWizardModel;

      private propertyViewData: any;

      constructor(private i18nService: any,
                  private vxPropertyViewService: any,
                  private $scope: any) {

         this.$scope.i18n = this.i18nService.getString;
      }

      $onInit(): void {
         this.buildPropertyView();
      }

      private buildPropertyView(): void {

         let builder: any = this.vxPropertyViewService.createPropertyViewBuilder();
         let generalCategory: any = builder.category("general");

         if (this.wizardModel.niocUpgradeStatistics &&
               this.wizardModel.niocUpgradeStatistics.userDefinedNrpCount > 0) {
            let modifiedObjectSection: any =
                  generalCategory.section("modifiedObjectSection").title(
                        this.i18nService.getString(
                              "DvsUi", "DvsUpgradeNiocWizard.summary.modifiedObjects"));

            modifiedObjectSection.property(
                  this.i18nService.getString(
                        "DvsUi", "DvsUpgradeNiocWizard.summary.userDefinedNrpsCount"),
                  this.wizardModel.niocUpgradeStatistics
                        .userDefinedNrpCount.toString());

            modifiedObjectSection.property(
                  this.i18nService.getString(
                        "DvsUi", "DvsUpgradeNiocWizard.summary.portGroupCount"),
                  this.wizardModel.niocUpgradeStatistics
                        .modifiedPortGroupCount.toString());

            if (this.wizardModel.migrateShares) {
               modifiedObjectSection.property(
                     this.i18nService.getString("DvsUi",
                           "DvsUpgradeNiocWizard.summary.migrateShares"),
                     this.wizardModel.migrateShares
                           ?  this.i18nService.getString(
                              "DvsUi", "DvsUpgradeNiocWizard.summary.migrateShares.on")
                           :  this.i18nService.getString("DvsUi",
                                    "DvsUpgradeNiocWizard.summary.migrateShares.off"));

               modifiedObjectSection.property(
                     this.i18nService.getString(
                           "DvsUi", "DvsUpgradeNiocWizard.summary.vmCount"),
                     this.wizardModel.niocUpgradeStatistics.modifiedVmCount.toString());
            }
         }

         let nextStepsSection: any = generalCategory.section("nextStepSection")
               .title(this.i18nService.getString(
                     "DvsUi", "DvsUpgradeNiocWizard.summary.nextSteps"));

         this.propertyViewData = builder.build();
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .component("dvsUpgradeNiocSummaryPage",
               new DvsUpgradeNiocSummaryPageComponent());
}
