(function() {
   'use strict';
   angular.module('com.vmware.vsphere.client.commonModule')
      .factory('privilegeTreeDataPresenter', privilegeTreeDataPresenter);

   function privilegeTreeDataPresenter() {
      return {
         formatData: formatData
      };

      function formatData(properties, privilegeData) {
         var treeData = new TreeData(properties, privilegeData);
         return treeData.formatPrivilegeData();
      }
   }

   function TreeData(properties, privilegeData) {
      this._nodesByName = {};
      this._roots = [];
      this._properties = properties;
      this._privilegeData = privilegeData;

      this.formatPrivilegeData = function() {
         _.forEach(this.filterOutSystemPrivileges(), function(privilegeDataItem) {
            this.placeInTree(privilegeDataItem.group.split('.'), privilegeDataItem.name, this._properties);
         }, this);

         return this.getData();
      };

      this.filterOutSystemPrivileges = function() {
         return _.filter(this._privilegeData, function(privilegeDataItem) {
            return !_.contains(['System.Read', 'System.View', 'System.Anonymous'], privilegeDataItem.name);
         });
      };

      this.getItem = function(itemName) {
         return this._nodesByName[itemName];
      };

      this.placeInTree = function(parentPath, childName) {
         var treeItem = this.getItem(childName);
         if (treeItem) {
            return treeItem;
         }

         if (parentPath.length === 0) {
            return this.addRoot(childName);
         }

         var parentNode = this.placeInTree(parentPath.slice(0, parentPath.length - 1), parentPath.join('.'));
         return this.addChild(parentNode, childName);
      };

      this.addRoot = function(rootName) {
         var item = this.buildTreeItem(rootName);
         this._nodesByName[rootName] = item;
         this._roots.push(item);
         this._roots = _.sortBy(this._roots, sortByLowerCaseLabel);
         return item;
      };

      this.addChild = function(parentNode, childName) {
         var item = this.buildTreeItem(childName);
         this._nodesByName[childName] = item;
         parentNode.items.push(item);
         parentNode.items = _.sortBy(parentNode.items, sortByLowerCaseLabel);
         return item;
      };

      this.getData = function() {
         return [{
            label: 'All Privileges',
            expanded: true,
            items: this._roots
         }];
      };

      this.buildTreeItem = function(itemName) {
         return {
            label: this._properties["privilege." + itemName + ".label"],
            items: [],
            privilegeId: itemName
         };
      };

      function sortByLowerCaseLabel(item) {
         return item.label.toLowerCase();
      }
   }
}());
