/* Copyright 2015 VMware, Inc. All rights reserved. -- VMware Confidential */
/*
 * EditPowerConfigHardwareController - Responsible for editing the power config hardware options of host.
 */
(function() {
   'use strict';
   angular.module('com.vmware.vsphere.client.host').controller('EditPowerConfigHardwareController',
         ['$scope', 'dataService', 'mutationService',EditPowerConfigHardwareCtrl]);

   function EditPowerConfigHardwareCtrl($scope, dataService, mutationService) {
      var self = this;
      var objectId = $scope.modalOptions.dialogData.availableTargets[0];
      self.model = { selectedPolicyKey: null };
      self.data = {};

      $scope.modalOptions.onSubmit = onOKClicked.bind(this);

      getViewData();

      // Functions
      function getViewData() {
         self.loading = true;
         dataService.getProperties(objectId, ['powerSystemCapability','config.powerSystemInfo.currentPolicy'])
         .then(function(response) {
            self.data = response;
            self.model.selectedPolicyKey = response['config.powerSystemInfo.currentPolicy'].key;
            self.loading = false;
         });
      }

      function onOKClicked() {
         if (!self.model.selectedPolicyKey) {
            return true;
         }
         var powerConfigHardwareSpec = {
            policyKey: self.model.selectedPolicyKey
         };
         var propType = 'com.vmware.vsphere.client.host.config.PowerConfigHardwareSpec';
         var operation = $scope.modalOptions.title;

         mutationService.apply(objectId, propType, powerConfigHardwareSpec, operation);

         // Return true to dismiss the dialog.
         return true;
      }
   }
})();
