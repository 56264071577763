/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
(function () {
   'use strict';

   angular
         .module('com.vmware.vsphere.client.network')
         .service('addPnicToDvsPageValidator',
               AddPnicToDvsPageValidator);

   AddPnicToDvsPageValidator.$inject = ['i18nService'];

   function AddPnicToDvsPageValidator(i18nService) {

      this.getValidationErrors = function (addedMappings) {
         var errors = [];

         if (!Object.keys(addedMappings).length) {
            errors.push(i18nService.getString('NetworkUi',
                  'AddNetworkingWizard.physicalNicPage.error.noNics'));
         }
         return errors;
      };
   }
})();