/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace dvs_ui {

   import IRootScopeService = angular.IRootScopeService;
   import ManagePortgroupsWizardModel = com.vmware.vsphere.client.h5.network.dvportgroup.manage.model.ManagePortgroupsWizardModel;
   import DvpgConfigSpec = com.vmware.vim.binding.vim.dvs.DistributedVirtualPortgroup$ConfigSpec;
   import VmwarePortConfigPolicy = com.vmware.vim.binding.vim.dvs.VmwareDistributedVirtualSwitch$VmwarePortConfigPolicy;
   import StringPolicy = com.vmware.vim.binding.vim.StringPolicy;

   export class DvpgManageWizardService {

      public static $inject = [
         "$rootScope",
         "i18nService",
         "vuiWizardService",
         "wizardValidationService",
         "dvpgManageWizardFlowManager",
         "dvpgManageWizardPages",
         "dvpgSpecFactory",
         "dvpgManageWizardConstants",
         "networkUiConstants",
         "dataService",
         "mutationService"];

      constructor(private $rootScope: IRootScopeService,
                  private i18nService: any,
                  private vuiWizardService: any,
                  private wizardValidationService: any,
                  private dvpgManageWizardFlowManager: any,
                  private dvpgManageWizardPages: DvpgManageWizardPages,
                  private dvpgSpecFactory: DvpgSpecFactory,
                  private dvpgManageWizardConstants: any,
                  private networkUiConstants: any,
                  private dataService: any,
                  private mutationService: any) {
      }

      /**
       * Shows the Manage distributed port groups wizard.
       */
      public show(dvsMor: string): void {

         let wizardScope: any = this.$rootScope.$new();
         wizardScope.flags = this.buildWizardFlags();
         wizardScope.model = this.buildWizardModel(dvsMor);
         wizardScope.wizardConfig = new WizardConfig();
         wizardScope.wizardConfig.title =
               this.i18nService.getString("DvsUi", "dvpg.manage.initialTitle");
         wizardScope.wizardConfig.loading = true;
         wizardScope.wizardConfig.loadingMessage = this.i18nService.getString(
               "CommonUi", "wizard.loading");
         wizardScope.wizardConfig.cssClass = "dvpg-manage-wizard";
         wizardScope.wizardConfig.onFinish = (): boolean => {
            this.submitData(wizardScope);
            return true;
         };

         wizardScope.wizardConfig.pages =
               this.dvpgManageWizardPages.createPages(wizardScope);

         this.wizardValidationService.initWizardValidation(wizardScope);
         this.dvpgManageWizardFlowManager.init(wizardScope);

         this.retrieveData(dvsMor, wizardScope);

         this.vuiWizardService({
            scope: wizardScope,
            configObjectName: "wizardConfig"
         }).show();
      }

      private retrieveData(dvsMor: string, wizardScope: DvpgManageWizardScope): void {
         let promise: any = this.dataService.getProperties(dvsMor,
               [this.dvpgManageWizardConstants.properties.WIZARD_DATA]);

         promise.then((data: any) => {
            let result: ManagePortgroupsWizardModel =
                  data[this.dvpgManageWizardConstants.properties.WIZARD_DATA];
            wizardScope.model.dvsName = result.dvsName;
            wizardScope.wizardConfig.title = this.i18nService.getString(
                  "DvsUi", "dvpg.manage.titleFormat", wizardScope.model.dvsName);
            wizardScope.wizardConfig.loading = false;
         });
      }

      private submitData(wizardScope: DvpgManageWizardScope): void {
         let specs: any[] = [];
         wizardScope.model.selectedPortgroups.forEach((portgroup: any) => {
            let spec: DvpgConfigSpec =
                  this.buildDvpgConfigSpec(portgroup, wizardScope.model);
            let specPair = {
               entity: portgroup.id,
               spec: spec
            };
            specs.push(specPair);
         });
         this.mutationService.applyMulti(specs[0].spec._type, specs);
      }

      private buildWizardModel(parentId: string): DvpgManageWizardModel {
         let model: DvpgManageWizardModel = new DvpgManageWizardModel();
         model.parentId = parentId;
         model.policies = new DvpgManagePolicyModel();
         model.policies.isFailoverPolicySelected = false;
         model.policies.isResAllocPolicySelected = false;
         model.policies.isTrafficShapingPolicySelected = false;
         model.policies.isTrafficFilterPolicySelected = false;
         model.policies.isVlanPolicySelected = false;
         model.policies.isMiscPolicySelected = false;
         model.policies.isMonitoringPolicySelected = false;
         model.policies.isSecurityPolicySelected = false;
         return model;
      }

      private buildWizardFlags(): DvpgManageWizardFlags {
         let flags: DvpgManageWizardFlags = new DvpgManageWizardFlags;
         flags.isPolicyTypePageInitialized = false;
         return flags;
      }

      private buildDvpgConfigSpec(portgroup: any,
                                  model: DvpgManageWizardModel): DvpgConfigSpec {

         let configSpec = new DvpgConfigSpec();
         let configVersionProp: string =
               this.networkUiConstants.ManageDvpgWizard.DVPG_CONFIG_VERSION_PROP;
         configSpec.configVersion = portgroup.rawData[configVersionProp];
         let defaultPortConfig: VmwarePortConfigPolicy = new VmwarePortConfigPolicy();
         configSpec.defaultPortConfig = defaultPortConfig;

         if (model.policies.isVlanPolicySelected) {
            defaultPortConfig.vlan =
                  this.dvpgSpecFactory.buildVlanSpec(model.vlanPolicyModel);
         }

         if (model.policies.isTrafficShapingPolicySelected) {
            defaultPortConfig.inShapingPolicy = this.dvpgSpecFactory
                  .buildTrafficShapingSpec(
                        model.trafficShapingPolicyModel.inShapingPolicy);
            defaultPortConfig.outShapingPolicy = this.dvpgSpecFactory
                  .buildTrafficShapingSpec(
                        model.trafficShapingPolicyModel.outShapingPolicy);
         }

         if (model.policies.isSecurityPolicySelected) {
            defaultPortConfig.securityPolicy = this.dvpgSpecFactory
                  .buildSecurityPolicySpec(model.securityPolicyModel);
         }

         if (model.policies.isFailoverPolicySelected) {
            defaultPortConfig.uplinkTeamingPolicy = this.dvpgSpecFactory
                  .buildTeamingAndFailoverSpec(model.failoverPolicyModel);
         }

         if (model.policies.isMonitoringPolicySelected) {
            defaultPortConfig.ipfixEnabled = this.dvpgSpecFactory
                  .buildMonitoringSpec(model.monitoringPolicyModel);
         }

         if (model.policies.isMiscPolicySelected) {
            defaultPortConfig.blocked = this.dvpgSpecFactory
                  .buildMiscSpec(model.miscPolicyModel);
         }

         if (model.policies.isResAllocPolicySelected) {
            let policy: string | StringPolicy = this.dvpgSpecFactory
                  .buildResourcePoolAllocationSpec(model.resourceAllocationPolicyModel);
            if (policy instanceof StringPolicy) {
               configSpec.defaultPortConfig.networkResourcePoolKey = policy;
            } else {
               configSpec.vmVnicNetworkResourcePoolKey = policy as string;
            }
         }
         return configSpec;
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .service("dvpgManageWizardService", DvpgManageWizardService);
}
