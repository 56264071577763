/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
(function () {
   'use strict';
   angular.module('com.vmware.vsphere.client.network')
         .controller('SelectTargetNetworkPageController', SelectTargetNetworkPageController);

   SelectTargetNetworkPageController.$inject = [
      '$scope',
      'i18nService',
      'networkUiConstants',
      'NetworkSelectorMode',
      'networkSelectorDialogService',
      'addNetworkingWizardConstants',
      'switchSelectorDialogService',
      'networkUtil'];

   function SelectTargetNetworkPageController(
         $scope,
         i18nService,
         networkUiConstants,
         NetworkSelectorMode,
         networkSelectorDialogService,
         addNetworkingWizardConstants,
         switchSelectorDialogService,
         networkUtil) {

      var self = this;

      $scope.addNetworkingWizardConstants = addNetworkingWizardConstants;
      initNetworkSelector();
      initSwitchSelector();
      $scope.portsNumbers = _getPortNumbers();

      if (typeof $scope.vSwitchSpec.numPorts === 'undefined') {
         $scope.vSwitchSpec.numPorts = networkUiConstants.NumPorts.DEFAULT;
      }

      if (typeof $scope.vSwitchSpec.mtu === 'undefined') {
         $scope.vSwitchSpec.mtu = networkUiConstants.Mtu.DEFAULT;
      }

      if ($scope.selectConnectionTypePageModel.connectionType === addNetworkingWizardConstants.connectionType.PNIC) {
         $scope.existingSwitchLabel = i18nService.getString('NetworkUi', 'AddNetworkingWizard.conTargetPage.existingSwitchWithDvs');
      } else {
         $scope.existingSwitchLabel = i18nService.getString('NetworkUi', 'AddNetworkingWizard.conTargetPage.existingSwitch');
      }

      if (shouldGetPreselectedSwitch($scope.preselectedSwitch,
                  $scope.addNetworkingSpec.selectedStandardSwitch,
                  $scope.selectConnectionTypePageModel.connectionType)) {
         $scope.addNetworkingSpec.selectedStandardSwitch =
               $scope.preselectedSwitch;
         $scope.selectTargetNetworkPageModel.targetType =
               addNetworkingWizardConstants.targetType.EXISTING_STANDARD_SWITCH;
      }

      self.mtuValidator = {
         message: i18nService.getString('H5NetworkUi', 'AddNetworkingWizard.conPropPage.mtu.inputErrorMessage'),
         isVisible: false
      };

      self.validateMtu = function () {
         self.mtuValidator.isVisible = !networkUtil.isMtuValid($scope.vSwitchSpec.mtu);
      };

      self.showExistingNetwork = $scope.selectConnectionTypePageModel.connectionType ===
            addNetworkingWizardConstants.connectionType.VMKERNEL;

      function initNetworkSelector() {
         $scope.networkSelectorMode = NetworkSelectorMode.SINGLE_SELECTION_BROWSE_INPUT;


         $scope.networkSelectorFilterSpec =
               networkSelectorDialogService.createNetworkSelectorFilterSpec(
                     false/*includeStandardNetworks*/,
                     true/*includeOpaqueNetworks*/,
                     true/*includeDistributedPortGroups*/,
                     false/*includeUplinkPortGroups*/,
                     true/*includeOpaqueNetworkTypes*/);

         $scope.networkSelectorDialogSpec =
               networkSelectorDialogService.createNetworkSelectorDialogSpec(
                     ['Network.Assign'],
                     i18nService.getString(
                           'NetworkUi',
                           'AddNetworkingWizard.conTargetPage.noDvpgPrivilegeMessage')
               );


         self.onSelectNetworks = function (selectedNetworks) {
            var network = selectedNetworks[0];
            $scope.addNetworkingSpec.selectedNetwork = network;
            $scope.addNetworkingSpec.selectedNetwork.isDistributedPortgroup =
                  !network.isStandardNetwork && !network.isOpaqueNetwork && !network.isUplink;
            $scope.selectTargetNetworkPageModel.isOpaqueNetworkSelected =
                  network.isOpaqueNetwork;

            self.resetPortPropertiesValues();
         };
      }

      function initSwitchSelector() {
         self.onSelectSwitch = function () {
            switchSelectorDialogService.openSwitchSelectorDialog(
                  $scope.hostId,
                  $scope.selectConnectionTypePageModel.connectionType,
                  null,
                  $scope.selectTargetNetworkPageModel.retrieveAllSwitches,
                  function (selectedItems) {
                     $scope.addNetworkingSpec.selectedStandardSwitch = selectedItems[0];
                  });
         };
      }

      function _getPortNumbers() {
         var portNumbers = [networkUiConstants.NumPorts.MIN];
         while (_.last(portNumbers) < networkUiConstants.NumPorts.MAX) {
            portNumbers.push(_.last(portNumbers) * 2);
         }
         return portNumbers;
      }

      function shouldGetPreselectedSwitch(preselectedSwitch, selectedStandardSwitch, connectionType) {
         return preselectedSwitch && !selectedStandardSwitch &&
               (preselectedSwitch.type === addNetworkingWizardConstants.switchType.STANDARD ||
               connectionType === addNetworkingWizardConstants.connectionType.PNIC);
      }

      self.resetPortPropertiesValues = function () {
         // if the target type has changed, we need to reset all the properties
         // except IP settings
         if($scope.addNetworkingSpec) {
            if($scope.addNetworkingSpec.vlanId) {
               $scope.addNetworkingSpec.vlanId = null;
            }

            if ($scope.addNetworkingSpec.networkLabel) {
               $scope.addNetworkingSpec.networkLabel = null;
            }

            if ($scope.portPropertiesPageModel.existingNetworkGeneratedNetworkLabel) {
               $scope.portPropertiesPageModel.existingNetworkGeneratedNetworkLabel = null;
            }

            if ($scope.portPropertiesPageModel.netStackName) {
               $scope.portPropertiesPageModel.netStackName = null;
            }

            if ($scope.addNetworkingSpec.netStackName) {
               $scope.addNetworkingSpec.netStackName = null;
            }

            if ($scope.addNetworkingSpec.mtuSelectedType) {
               $scope.addNetworkingSpec.mtuSelectedType = null;
            }

            if ($scope.addNetworkingSpec.mtu) {
               $scope.addNetworkingSpec.mtu = null;
            }

            if ($scope.portPropertiesPageModel.hostVnicFeatures) {
               $scope.portPropertiesPageModel.hostVnicFeatures = null;
            }

            if ($scope.portPropertiesPageModel.hostVnicSettings) {
               $scope.portPropertiesPageModel.hostVnicSettings = null;
            }
         }
      };

      return this;
   }
})();
