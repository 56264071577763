/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
(function () {
   'use strict';
   angular.module('com.vmware.vsphere.client.network')
         .controller('Ipv4SettingsPageController', Ipv4SettingsPageController);

   Ipv4SettingsPageController.$inject = [
      '$scope',
      'i18nService',
      'dataService',
      'ipParserService',
      'staticIpInputTypes',
      'addNetworkingWizardConstants'];

   function Ipv4SettingsPageController(
         $scope,
         i18nService,
         dataService,
         ipParserService,
         staticIpInputTypes,
         addNetworkingWizardConstants) {
      var vnicIpRouting = 'vnic:virtualAdapterIpRouting';

      this.ipv4PropertyType = staticIpInputTypes.IPV4.IP_ADDRESS;
      this.subnetMaskPropertyType = staticIpInputTypes.IPV4.SUBNET_MASK;
      this.defaultIpv4GatewayPropertyType = staticIpInputTypes.IPV4.DEFAULT_GATEWAY;

      this.ipv4ValidationMessage = i18nService.getString(
            'H5NetworkUi', 'AddNetworkingWizard.ipv4SettingsPage.invalidIpAddress');
      this.subnetMaskValidationMessage = i18nService.getString(
            'H5NetworkUi', 'AddNetworkingWizard.ipv4SettingsPage.invalidSubnetMask');
      this.defaultGatewayValidationMessage = i18nService.getString(
            'H5NetworkUi', 'AddNetworkingWizard.ipv4SettingsPage.invalidDefaultGatewayAddress');

      $scope.wizardConfig.loading = true;

      if($scope.hostVnicIpRoutingInfo.isIpv4Set) {
         initPage($scope.hostVnicIpRoutingInfo.value);
      } else {
         requestData();
      }

      function requestData() {
         var netStackKey = "";
         if ($scope.addNetworkingSpec.isTcpIpStackSupported) {
            netStackKey =
                  $scope.addNetworkingSpec.netStackName.netStackInstanceKey;
         }
         dataService.getProperties($scope.hostId, [vnicIpRouting], {
            propertyParams: [{
               propertyName: vnicIpRouting,
               parameterType: 'java.lang.String',
               parameter: netStackKey
            }]
         }).then(function (data) {
                  $scope.hostVnicIpRoutingInfo.value = data[vnicIpRouting];
                  initPage($scope.hostVnicIpRoutingInfo.value);
               })
               .finally(function() {
                  $scope.wizardConfig.loading = false;
         });
      }

      function initPage(ipConfigInfo) {

         $scope.defaultGateway = (ipConfigInfo && ipConfigInfo.ipv4DefaultGateway)
               ? ipConfigInfo.ipv4DefaultGateway
               : i18nService.getString('NetworkUi', 'NetworkUtil.noData');

         if (!$scope.hostVnicIpRoutingInfo.isIpv4Set) {
            $scope.addNetworkingSpec.overrideGateway = false;
            $scope.addNetworkingSpec.ipv4DefaultGateway = ipConfigInfo.ipv4DefaultGateway;

            $scope.hostVnicIpRoutingInfo.isIpv4Set = true;
         }

         if (!$scope.addNetworkingSpec.isTcpIpStackSupported) {
            $scope.showDnsAddresses = true;
         } else {
            $scope.showDnsAddresses =
                  $scope.addNetworkingSpec.netStackName.netStackInstanceKey
                  === addNetworkingWizardConstants.defaultNetStackKey;
         }

         $scope.addNetworkingSpec.isGatewayFeatureSupported =
               ipConfigInfo.isGatewayFeatureSupported;

         $scope.dnsAddresses = [];

         if (ipConfigInfo && ipConfigInfo.dnsAddresses && $scope.showDnsAddresses) {
            $scope.dnsAddresses =
                  _.filter(ipConfigInfo.dnsAddresses, function (dnsAddress) {
                     return ipParserService.isIpv4AddressValid(dnsAddress);
                  });
         }

         if ($scope.dnsAddresses.length < 1) {
            $scope.dnsAddresses =
                  [i18nService.getString('NetworkUi', 'NetworkUtil.noData')];
         }

         $scope.wizardConfig.loading = false;
      }

      return this;
   }
})();
