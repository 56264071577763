/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace dvs_ui {

   import LacpApiVersion = com.vmware.vim.binding.vim.dvs.VmwareDistributedVirtualSwitch$LacpApiVersion;
   import DvsResControlUpgradeStatistics = com.vmware.vsphere.client.dvs.api.spec.DvsResControlUpgradeStatistics;

   export class DvsUpgradeFeaturesService {

      static $inject = ["dvsVersionPageConstants"];

      public LACP_API_VERSION_SINGLE_LAG: LacpApiVersion = "singleLag";

      constructor(private dvsVersionPageConstants: any) {
      }

      public isMultiLagLacpSupported(selectedVersion: String): boolean {
         // The multi LAG LACP support is added in 5.5.0
         return this.isSupportedForVersion(selectedVersion,
               this.dvsVersionPageConstants.versions._55);
      }

      public isImplicitNiocUpgradeSupported(
            selectedProductSpecVersion: string,
            currentNiocVersion: number,
            niocUpgradeStatistics: DvsResControlUpgradeStatistics): boolean {

         // Returns true if the DVS supports NIOCv3 and if the existing NIOC
         // version is earlier than ver 3
         // NIOCv3 support is added in DVS 6.0.0
         let isUpgradeSupported: boolean = this.isNiocUpgradeSupported(
               selectedProductSpecVersion, currentNiocVersion);

         return isUpgradeSupported &&
               niocUpgradeStatistics &&
               (isNaN(niocUpgradeStatistics.modifiedSystemNrpCount) ||
               niocUpgradeStatistics.modifiedSystemNrpCount === 0) &&
               (isNaN(niocUpgradeStatistics.userDefinedNrpCount) ||
               niocUpgradeStatistics.userDefinedNrpCount === 0);
      }

      public isExplicitNiocUpgradeSupported(
            selectedProductSpecVersion: string,
            currentNiocVersion: number,
            niocUpgradeStatistics: DvsResControlUpgradeStatistics): boolean {

         let isUpgradeSupported: boolean = this.isNiocUpgradeSupported(
               selectedProductSpecVersion, currentNiocVersion);

         // Returns true if the DVS supports NIOCv3 and if the existing NIOC
         // version is earlier than ver 3, and the implicit upgrade
         // is not possible
         // NIOCv3 support is added in DVS 6.0.0
         return isUpgradeSupported &&
               !this.isImplicitNiocUpgradeSupported(
                     selectedProductSpecVersion,
                     currentNiocVersion,
                     niocUpgradeStatistics
         );
      }

      public isImplicitLacpUpgradeSupported(
            selectedProductSpecVersion: string,
            isLacpConfigured: boolean,
            currentLacpVersion: string): boolean {

         // Returns true if the target DVS version
         // supports multi lag LACP and the protocol is
         // currently disabled or not available
         return this.isMultiLagLacpSupported(selectedProductSpecVersion) &&
               (!currentLacpVersion ||
               (currentLacpVersion === this.LACP_API_VERSION_SINGLE_LAG &&
               !isLacpConfigured));
      }

      public isExplicitLacpUpgradeSupported(
            selectedProductSpecVersion: string,
            isLacpConfigured: boolean,
            currentLacpVersion: string): boolean {

         // Returns true if the target DVS version
         // supports multi lag LACP and the protocol is
         // currently disabled or not available and the implicit
         // upgrade is not possible
         return this.isMultiLagLacpSupported(selectedProductSpecVersion) &&
               (!currentLacpVersion ||
                  currentLacpVersion === this.LACP_API_VERSION_SINGLE_LAG) &&
               !this.isImplicitLacpUpgradeSupported(
                     selectedProductSpecVersion,
                     isLacpConfigured,
                     currentLacpVersion);
      }

      private isNiocUpgradeSupported(
            selectedProductSpecVersion: String,
            currentNiocVersion: number): boolean {

         return this.isSupportedForVersion(selectedProductSpecVersion,
                     this.dvsVersionPageConstants.versions._60) &&
               !isNaN(currentNiocVersion) && currentNiocVersion < 3;
      }

      private isSupportedForVersion(
            selectedProductSpecVersion: String,
            minSupportedProductSpecVersion: String): boolean {

         if (!selectedProductSpecVersion || !minSupportedProductSpecVersion) {
            return false;
         }
         let selectedVersion: number =
               parseInt(selectedProductSpecVersion.replace(".", ""));
         let minSupportedVersion: number =
               parseInt(minSupportedProductSpecVersion.replace(".", ""));

         if (isNaN(selectedVersion) || isNaN(minSupportedVersion)) {
            return false;
         }

         return selectedVersion >= minSupportedVersion;
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .service("dvsUpgradeFeaturesService", DvsUpgradeFeaturesService);
}
