(function () {
   'use strict';

   angular.module('com.vmware.vsphere.client.cluster')
         .service('drsPortletService', drsPortletService);

   drsPortletService.$inject = ['dataService', 'i18nService',
      'drsAutomationLevelService', 'drsMigrationThresholdService',
      'dpmAutomationLevelService', 'numberFormatterService'];

   function drsPortletService(dataService, i18nService, drsAutomationLevelService,
         drsMigrationThresholdService, dpmAutomationLevelService, numberFormatterService) {

      return {
         getViewData: getViewData
      };

      function getViewData(clusterId) {
         return dataService.getData(
               clusterId,
               'com.vmware.vsphere.client.clusterui.model.ClusterDrsPortletData',
               {queryName: 'drsPortletService.' + getViewData.name})
            .then(function (drsData) {
            if (!drsData) {
               return null;
            }

            var drsViewData = {};

            drsViewData.currentDeviation = drsData.currentBalance / 1000;
            drsViewData.targetDeviation = drsData.targetBalance / 1000;

            var currentAutomationLevel =
                  drsAutomationLevelService.getLevelByKey(drsData.defaultVmBehavior);
            drsViewData.currentAutomationLevelText = currentAutomationLevel.label;

            drsViewData.currentMigrationThresholdText =
                  drsMigrationThresholdService.getText(drsData.migrationThreshold);

            drsViewData.currentDpmAutomationLevelText = dpmAutomationLevelService.getText(
                  drsData.dpmEnabled, drsData.defaultDpmBehavior);

            drsViewData.overallDrsBalanceText = getOverallDrsBalanceText(
                  drsViewData.currentDeviation, drsViewData.targetDeviation);
            drsViewData.currentDeviationForTooltip =
                  getCurrentDeviationForTooltip(drsViewData.currentDeviation);
            drsViewData.permittedDeviationForTooltip =
                  getPermittedDeviationForTooltip(drsViewData.targetDeviation);

            return {
               data: drsData,
               viewData: drsViewData
            };
         });
      }

      function getOverallDrsBalanceText(currentDeviation, targetDeviation) {
         if (!isDeviationAvailable(currentDeviation) ||
               !isDeviationAvailable(targetDeviation)) {
            return getString("drs.portlet.naLabel");
         }

         if (currentDeviation === 0) {
            return getString("drs.portlet.perfectlyBalancedLabel");
         }

         if (currentDeviation < targetDeviation) {
            return getString("drs.portlet.balancedLabel");
         }

         if (currentDeviation === targetDeviation) {
            return getString("drs.portlet.barelyBalancedLabel");
         }

         return getString("drs.portlet.imbalancedLabel");
      }

      function getPermittedDeviationForTooltip(targetDeviation) {
         if (!isDeviationAvailable(targetDeviation)) {
            return getString("drs.portlet.tooltip.targetDeviationNA");
         }

         var formattedTargetDeviation =
               numberFormatterService.format(targetDeviation);
         return i18nService.getString('ClusterUi',
               'drs.portlet.tooltip.targetDeviationTextFormat',
               formattedTargetDeviation);
      }

      function getCurrentDeviationForTooltip(currentDeviation) {
         if (!isDeviationAvailable(currentDeviation)) {
            return getString("drs.portlet.tooltip.currentDeviationNA");
         }

         var formattedCurretnDeviation = numberFormatterService.format(currentDeviation);
         return formattedCurretnDeviation.toString();
      }

      function getString(key) {
         return i18nService.getString('ClusterUi', key);
      }

      function isDeviationAvailable(deviation) {
         if (typeof deviation !== 'number' || isNaN(deviation) ||
               deviation < 0 || 1 < deviation) {
            return false;
         }
         return true;
      }
   }
})();
