/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
/*
 * Service which handles the wizard for datastore capacity increase.
 */
(function() {
   'use strict';
   angular.module('com.vmware.vsphere.client.storage')
      .service('increaseDatastoreCapacityWizardService', increaseDatastoreCapacityWizardService);

   increaseDatastoreCapacityWizardService.$inject = ['vuiWizardService',
      'i18nService',
      '$rootScope',
      'wizardPageService',
      'selectDevicePageService',
      'specifyConfigurationPageService',
      'readyToCompletePageService',
      'IncreaseDatastoreCapacityWizardManager'];

   function increaseDatastoreCapacityWizardService (
      vuiWizardService,
      i18nService,
      $rootScope,
      wizardPageService,
      selectDevicePageService,
      specifyConfigurationPageService,
      readyToCompletePageService,
      IncreaseDatastoreCapacityWizardManager
   ) {

      return {
         showWizard: function(contextObjectId) {
            var wizardScope = $rootScope.$new();
            wizardScope.manager = new IncreaseDatastoreCapacityWizardManager();
            wizardScope.manager.setDatastoreRef(contextObjectId);

            var pages = [
               selectDevicePageService.build(wizardScope),
               specifyConfigurationPageService.build(wizardScope),
               readyToCompletePageService.build(wizardScope)
            ];

            wizardScope.wizardConfig = {
               pages: wizardPageService.initializePageStates(pages),
               title: i18nService.getString('StorageUi', 'increaseDatastoreWizard.title'),
               iconClass: 'storage-ui-icon-increase_datastore'
            };
            
            // show the wizard
            vuiWizardService({
               scope: wizardScope,
               configObjectName: 'wizardConfig'
            }).show();
         }
      };
   }
})();