/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace dvs_ui {

   import IQService = angular.IQService;
   import IScope = angular.IScope;
   import IPromise = angular.IPromise;
   import DvsPortsConstants = dvs_ui.DvsPortsConstants;

   export class DvsPortsComponent {

      public controller: any;

      public bindings: any;

      public templateUrl: string;

      constructor() {
         this.controller = DvsPortsController;
         this.bindings = {
            isPortGroupContext: "<"
         };
         this.templateUrl =
               "dvs-ui/resources/dvs/ports/dvsPortsTemplate.html";
      }
   }

   class DvsPortsController {

      private static DVS_PORTS_VIEW_DATA_PROPERTY_NAME = "dvsports:portsViewData";

      private static DVPG_PORTS_VIEW_DATA_PROPERTY_NAME = "dvportgroupports:portsViewData";

      private static PRIVILEGE_PORT_CONFIG: string = "DVSwitch.PortConfig";

      private selectedPorts: any[] = [];

      private _currentDvsPromise: IPromise<any>;

      private _actionPromise: IPromise<any>;

      private _authorizationPromise: IPromise<any>;

      private evaluationContext: any;

      private isPortGroupContext: boolean;

      private hasPortConfigPrivilege: boolean;

      public liveRefreshProperties: string[];

      public i18n: Function;

      public portContextId: string;

      public isPortTrafficFilterSupported: boolean;

      public portsLoading: boolean;

      public datagridOptions: any;

      public splitterOptions: any;

      public dSwitchUri: string;

      public watchForObjects: string[];

      static $inject = [
         "$q",
         "$scope",
         "i18nService",
         "dataService",
         "vuiConstants",
         "navigation",
         "dvsPortsService",
         "actionToolbarFactory",
         "networkUiConstants",
         "authorizationService",
         "defaultUriSchemeUtil"
      ];

      constructor(private $q: IQService,
                  private $scope: any,
                  private i18nService: any,
                  private dataService: any,
                  private vuiConstants: any,
                  private navigation: any,
                  private dvsPortsService: any,
                  private actionToolbarFactory: any,
                  private networkUiConstants: any,
                  private authorizationService: any,
                  private defaultUriSchemeUtil: any) {

         this.i18n = this.i18nService.getString;
      }

      protected $onInit(): void {
         this.portContextId = this.navigation.getRoute().objectId;

         this.splitterOptions = {
            orientation: this.vuiConstants.splitter.orientation.VERTICAL,
            panes: [
               {
                  min: "250px",
                  size: "55%"
               }, {
                  min: "150px",
                  size: "45%"
               }
            ]
         };

         this.datagridOptions = {
            columnDefs: this.dvsPortsService.getColumnDefinitions(),
            sortMode: this.vuiConstants.grid.sortMode.SINGLE,
            selectionMode: this.vuiConstants.grid.selectionMode.SINGLE,
            showCheckboxesOnMultiSelection: false,
            selectedItems: [],
            data: [],
            resizable: true,
            height: "100%",
            actionBarOptions: {
               actions: []
            },
            onChange: this.onSelectionChanged.bind(this)
         };

         this.liveRefreshProperties =
               this.dvsPortsService.assignLiveRefreshProperties(this.isPortGroupContext);

         this.watchForObjects = [this.portContextId];

         this.refresh();
      }

      public refresh (): void {
         this.portsLoading = true;

         let portsDataProperty: string = this.isPortGroupContext
               ? DvsPortsController.DVPG_PORTS_VIEW_DATA_PROPERTY_NAME
               : DvsPortsController.DVS_PORTS_VIEW_DATA_PROPERTY_NAME;

         let dvsPromise: IPromise<any> = this.dataService
               .getProperties(this.portContextId, [portsDataProperty]);

         this._currentDvsPromise = dvsPromise;

         this.evaluationContext = {};
         this.evaluationContext.isPortGroupContext = this.isPortGroupContext;

         let actionIds: string[] = [
            this.networkUiConstants.actions.EDIT_DVPORT
         ];

         let actionPromise: IPromise<any> =
               this.actionToolbarFactory.createActionbarOptions(
                     [this.portContextId],
                     actionIds,
                     this.evaluationContext);

         this._actionPromise = actionPromise;

         let authorizationPromise: IPromise<any> =
               this.authorizationService.checkPrivilege(this.portContextId,
                     DvsPortsController.PRIVILEGE_PORT_CONFIG);

         this._authorizationPromise = authorizationPromise;

         this.$q.all([dvsPromise, actionPromise, authorizationPromise]).then((dataServiceResponse: any) => {
            if (this._currentDvsPromise === dvsPromise) {
               let dvsPortsResponse: any = dataServiceResponse[0];
               if (dvsPortsResponse && dvsPortsResponse[portsDataProperty]) {
                  this.dSwitchUri = this.defaultUriSchemeUtil.getVsphereObjectId(
                        dvsPortsResponse[portsDataProperty].dvsMor);
                  this.isPortTrafficFilterSupported =
                        dvsPortsResponse[portsDataProperty].portTrafficFilterSupported;
                  this.evaluationContext.dSwitchUri = this.dSwitchUri;
                  this.datagridOptions.data =
                        dvsPortsResponse[portsDataProperty].ports;
                  if (this.isPortGroupContext && this.watchForObjects
                        && this.watchForObjects.length < 2) {
                     this.watchForObjects.push(this.dSwitchUri);
                  }
               }
            }

            if (this._actionPromise === actionPromise) {
               this.datagridOptions.actionBarOptions = dataServiceResponse[1];
            }

            if (this._authorizationPromise === authorizationPromise) {
               this.hasPortConfigPrivilege = dataServiceResponse[2];
               if (this.datagridOptions.actionBarOptions.actions.length > 0) {
                  this.datagridOptions.actionBarOptions.actions[0].enabled =
                        dataServiceResponse[2];
               }
            }
         }).finally(() => {
            this.portsLoading = false;
         });
      }

      public preselectComparator = (item: any): boolean => {
         return _.contains(_.pluck(this.selectedPorts, "portId"), item.portId);
      }

      private onSelectionChanged (selectedItems: any[]): void {
         if (selectedItems && selectedItems.length > 0) {
            this.selectedPorts = selectedItems;
            this.evaluationContext.selectedPort = selectedItems[0];
         } else {
            this.selectedPorts = [];
            this.evaluationContext.selectedPort = null;
         }

         if (this.hasPortConfigPrivilege) {
            this.toggleActionsAvailability();
         }
      }

      // Toggles the enabled/disabled flag of Edit action which depends on item selection
      private toggleActionsAvailability() {
         if (this.datagridOptions.actionBarOptions
               && this.datagridOptions.actionBarOptions.actions.length === 1) {
            this.datagridOptions.actionBarOptions.actions[0].enabled =
                  this.selectedPorts && this.selectedPorts.length > 0;

         }
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .component("dvsPortsView", new DvsPortsComponent());
}
