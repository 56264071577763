namespace h5_client {
   export module common_module_ui {
      import ContentLibraryConstants = h5_client.common_module_ui.ContentLibraryConstants;

      /*
       * Content library service : using the Virgo mutation service.
       */
      export class LibraryMutationService {
         static $inject = ["mutationService", "defaultUriSchemeUtil"];
         private DELETE_LIBRARY_SPEC: string = "com.vmware.vsphere.client.library.specs.DeleteLibrarySpec";
         private ADD_LIBRARY_SPEC: string = "com.vmware.vsphere.client.library.CreateLibrarySpec";
         private VALIDATE_LIBRARY_SPEC: string = "com.vmware.vsphere.client.library.specs.SubscriptionUrlValidatorSpec";
         private DUMMY_LIBRARY: string = "urn:vapi:com.vmware.content.Library:dummy";

         constructor(private mutationService: any, private defaultUriSchemeUtil: any) {
         }

         /**
          * Confirm and delete a library.
          */
         public deleteContentLibrary(libraryIds: string[]) {
            return this.mutationService.removeOnMultiEntity(libraryIds, this.DELETE_LIBRARY_SPEC, {});
         }

         public addContentLibrary(libraryData: any) {

            let librarySpec = {
               name: libraryData.name,
               description: libraryData.description,
               vcServiceGuid: libraryData.vcService.serviceGuid,
               type: libraryData.type,
               subscriptionUrl: libraryData.subscriptionUrl,
               datastoreRefs: [this.defaultUriSchemeUtil.getPartsFromVsphereObjectId(libraryData.datastore.id)],
               isAuthBasic: (!!libraryData.subscriptionPassword || !!libraryData.isAuthenticationEnabled),
               isStreamOptimized: !!libraryData.isStreamingOptimized,
               isExternallyPublished: !!libraryData.isPublished,
               localPublishedUsername: !!libraryData.isPublished ? libraryData.userName : null,
               localPublishedPassword: !!libraryData.isPublished ? libraryData.password : null,
               sslThumbprint: libraryData.sslThumbprint ? libraryData.sslThumbprint : "",
               subscribedUsername: this.getSubscriptionUsername(libraryData),
               subscribedPassword: (libraryData.type === ContentLibraryConstants.LIBRARYTYPE_SUBSCRIBED) ? libraryData.subscriptionPassword : "",
               storageFileSystemPath: null,
               createLocalCopy: (libraryData.syncStrategy === ContentLibraryConstants.SYNC_STRATEGY_IMMEDIATE),
               useFilesystem: false,
               useDatastore: true
            };
            return this.mutationService.add(this.ADD_LIBRARY_SPEC, librarySpec, "ADD");
         }

         public validateSubscription(libraryUrl: any, sslThumbprint?: string, password?: string) {
            const librarySpec = {
               name: "",
               subscriptionUrl: libraryUrl,
               isAuthBasic: !!password,
               sslThumbprint: sslThumbprint ? sslThumbprint : "",
               username: ContentLibraryConstants.CREATE_LIBRARY_DEFAULT_USER,
               password: password
            };
            return this.mutationService.validate(
                  this.DUMMY_LIBRARY,
                  this.VALIDATE_LIBRARY_SPEC,
                  librarySpec
            );
         }

         private isSubscriptionAuthenticated(libraryData: any): boolean {
            return !!libraryData.subscriptionPassword && libraryData.subscriptionPassword.length > 0;
         }

         private getSubscriptionUsername(libraryData: any): string {
            if (libraryData.type === ContentLibraryConstants.LIBRARYTYPE_SUBSCRIBED) {
               return this.isSubscriptionAuthenticated(libraryData) ?
                     ContentLibraryConstants.CREATE_LIBRARY_DEFAULT_USER : "";
            } else {
               return "";
            }
         }
      }
      angular.module("com.vmware.vsphere.client.commonModule").service("libraryMutationService", LibraryMutationService);
   }
}
