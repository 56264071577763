/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
(function () {
   'use strict';
   angular.module('com.vmware.vsphere.client.storage')
      .service('nfsSelectHostsPageService', nfsSelectHostsPageService);

   nfsSelectHostsPageService.$inject = [
      'i18nService',
      'wizardPageService'];

   function nfsSelectHostsPageService(
      i18nService, wizardPageService) {

      return {
         build: function (wizardScope) {
            return {
               title: i18nService.getString('StorageUi', 'addDatastoreWizard.nfsSelectHostsPage.title'),
               description: i18nService.getString('StorageUi', 'addDatastoreWizard.nfsSelectHostsPage.description'),
               contentUrl: 'storage-ui/resources/storage/views/wizard/createDatastore/pages/nfs/NfsSelectHostsPage.html',
               state: undefined,
               onCommit: function() {
                  var selectedHosts = wizardScope.nfsWizardManager.getSelectedHosts();
                  if (!selectedHosts || selectedHosts.length === 0) {
                     wizardPageService.markPageIncompleteWithErrors(
                        wizardScope.wizardConfig,
                        [i18nService.getString('StorageUi', 'addDatastoreWizard.selectHostsPage.noSelection')]);
                     return false;
                  }

                  wizardScope.wizardConfig.validationBanner.messages = [];
                  wizardPageService.markPageComplete(wizardScope.wizardConfig,
                     wizardScope.commonWizardManager.getDatastoreType());
                  return true;
               }
            };
         }
      };
   }
})();
