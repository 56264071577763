/* Copyright 2013 VMware, Inc. All rights reserved. -- VMware Confidential */
/*
 * This ts file has to be loaded first.
 */
namespace h5_vm {
   import CustomizationSpecInfo = com.vmware.vim.binding.vim.CustomizationSpecInfo;
   import VmToolsService = h5_vm.VmToolsService;
   import MoveVmService = h5_vm.MoveVmService;
   import VmDragAndDropService = h5_vm.VmDragAndDropService;
   import VmRemovalService = h5_vm.VmRemovalService;

   import ActionEvaluation = com.vmware.vise.actionsfw.ActionEvaluation;
   import RuntimeInfo = com.vmware.vim.binding.vim.vm.RuntimeInfo;
   import ActionContext = com.vmware.vise.actionsfw.ActionContext;
   import CustomizationSpecInfoCustom = h5_vm.CustomizationSpecInfoCustom;
   import OperationResult = com.vmware.vise.core.model.OperationResult;
   import CustomizeGosSpecData = com.vmware.vsphere.client.h5.vm.data.CustomizeGosSpecData;
   import VmCryptoUnlockSpec = com.vmware.vsphere.client.h5.vm.data.VmCryptoUnlockSpec;

   declare let h5: any;
   declare const angular: any;
   declare const _: any;
   declare const ng: any;

   h5 = h5 || {};
   h5.vm = {
      angularModule: angular.module("com.vmware.vsphere.client.vm", [
         "com.vmware.platform.ui", "com.vmware.vsphere.client.commonModule"])
   };

   /*
    * Registers vm-list specific column renderers.
    */
   angular.module("com.vmware.vsphere.client.vm")
         .run(["columnRenderersRegistry", "i18nService",
            (columnRenderersRegistry: any, i18nService: any) => {

               columnRenderersRegistry.registerColumnRenderers({
                  /**
                   * Host name column - icon-link renderer.
                   *
                   * @param  {array} props Array of property names, where 1st property is
                   *    policy name. Other properties are ignored.
                   * @param  {object} data  Map of values, contains the specificType.
                   * @param {object} config A dictionary (key/value) to control the renderer behavior.
                   */
                  "host-name": (props: string[], data: any, config: any) => {
                     const objLinkRenderer: any = columnRenderersRegistry.getColumnRenderer("object-link");
                     let icon: string = "vsphere-icon-host";
                     if (data.hideHostIcon) {
                        icon = "";
                     }
                     return objLinkRenderer([props[0], props[1], icon], data, config);
                  },
                  /**
                   * Localizes detailedToolsVersionStatus and takes care if it is null.
                   * The localization consists of splitting the provided value (see
                   * VmListPropertyProvideAdapter#processDetailedToolsRunningStatus for
                   * some additional details) and localization of the resulting parts.
                   * @param  {array} props Array of property names, where 1st property is
                   * detailedToolsVersionStatus. Other properties are ignored
                   * @param  {object} data  Map of values, contains the detailedToolsVersionStatus.
                   */
                  "detailed-tools-version-status": (props: string[], data: any) => {
                     let displayText = "";
                     const value = data[props[0]];

                     if (value) {
                        const toolsVersionData = value.split("|", 2);
                        const toolsVersionStatusKey = toolsVersionData[0];
                        const toolsVersionId = toolsVersionData[1];

                        if (toolsVersionStatusKey === "0") {
                           displayText = i18nService.getString("VmUi", "guestToolsNA");
                        } else {
                           if (!toolsVersionStatusKey) {
                              displayText = toolsVersionId;
                           } else {
                              const toolsVersionStatusLocalized =
                                    i18nService.getString("VmUi", "tools." + toolsVersionStatusKey + ".text");

                              if (toolsVersionId === undefined || toolsVersionId === null) {
                                 displayText = toolsVersionStatusLocalized;
                              } else {
                                 displayText = i18nService.getString("VmUi", "detailedToolsVersionStatus",
                                       toolsVersionId, toolsVersionStatusLocalized);
                              }
                           }
                        }
                     }
                     return "<div title=\"" + displayText + "\">" + displayText + "</div>";
                  },
                  /**
                   * Renders ha protection status icon and status label in a cell.
                   * @param  {array} props Array of property names, where 1st property is
                   * haProtected, 2nd is haProtectedLabel.@formatted
                   * @param  {object} data  Map of values, contains the ha protection status.
                   */
                  "ha-protection-status": (props: string[], data: any) => {
                     const haProtected: string = data[props[0]];
                     const label: string = data[props[1]] ? data[props[1]] : i18nService.getString(
                           "VmUi", "vmListItemData.na");
                     let iconClass: string = "vui-icon-datagrid-status-unknown";
                     if (haProtected === "true") {
                        iconClass = "vsphere-icon-status-ok";
                     } else if (haProtected === "false") {
                        iconClass = "vsphere-icon-status-error";
                     }
                     return "<div class=\"object\" title=\"" + label +
                           "\"><i class=\"" + iconClass + "\"></i>" + label + "</div>";
                  },
                  /**
                   * Renders VM Storage Policies Compliance icon and status label in a cell.
                   * @param  {array} props Array of property names, where 1st property is
                   * pbmStorageComplStatusEx, 2nd is pbmStorageComplStatusLabel
                   * @param  {object} data  Map of values, contains the ha protection status.
                   */
                  "storage-compliance-status": (props: string[], data: any) => {
                     const complianceStatus = data[props[0]];
                     const label = data[props[1]] ? data[props[1]] : "";
                     let iconClass: string = "";
                     switch (complianceStatus) {
                        case "compliant":
                           iconClass = "vsphere-icon-status-ok";
                           break;
                        case "nonCompliant":
                        case "outOfDate":
                           iconClass = "vsphere-icon-status-error";
                           break;
                        case "unknown":
                           iconClass = "vui-icon-datagrid-status-unknown";
                           break;
                        case "notApplicable":
                           // TODO semerdzhievp add the following icon class in vui (col 8, row 3 in the sprite)
                           iconClass = "vui-icon-datagrid-status-notApplicable";
                           break;
                     }
                     return "<div class=\"object\" title=\"" + label +
                           "\"><i class=\"" + iconClass + "\"></i>" + label + "</div>";
                  },
                  /**
                   * is-present renderer, will display "Present" if data value is "true"
                   * otherwise "Not present"
                   *
                   * @param {array} props Array of property names
                   * @param {object} data  Map of values, contains whether a vm has a vtpm device.
                   */
                  "is-present": (props: string[], data: any) => {
                     const tpmPresent = !!data[props[0]];
                     let displayText = i18nService.getString("VmUi", "VmDeviceManager.TPMNotPresent");
                     if (tpmPresent) {
                        displayText = i18nService.getString("VmUi", "VmDeviceManager.TPMPresent");
                     }
                     return "<div title=\"" + displayText + "\">" + displayText + "</div>";
                  },
                  /**
                   * Renders the managing solution's icon and name if there is one that is managing the VM
                   * @param props Array of property names, where 1st property is
                   * managedByInfo - string the name of the solution, 2nd is managedByIcon16-
                   * an object that holds the base64 encoded string of the solution's icon
                   * @param data  the actual properties values
                   */
                  "managed-by": (props: string[], data: any) => {
                     let managedByLabel: string = _.escape(data[props[0]]);
                     let managedByIconData: any = data[props[1]];

                     if (managedByLabel && !_.isEmpty(managedByIconData) && managedByIconData.imageData) {
                        return '<span><img src="data:image/png;base64, ' +
                              managedByIconData.imageData +
                              '"/>' + managedByLabel + '</span>';
                     }

                     if (managedByLabel) {
                        return managedByLabel;
                     }
                     return "";
                  }
               });

            }])
         .run([
            "$rootScope",
            "actionDialogService",
            "mutationService",
            "openConsoleService",
            "createVmWizardService",
            "powerOpsService",
            "creationTypeConstants",
            "migrateVmWizardService",
            "$window",
            "remoteConsoleService",
            "markAsTemplateService",
            "snapshotService",
            "vscExportWizardLauncherService",
            "i18nService",
            "dataService",
            "clarityModalService",
            "contentLibraryService",
            "upgradeVmHardwareService",
            "keyboardShortcutService",
            "vmToolsService",
            "defaultUriSchemeUtil",
            "managedEntityConstants",
            "moveVmService",
            "nameAndFolderValidatorService",
            "vmDragAndDropService",
            "vmRemovalService",
            "vmFtService",
            "createGuestOsCustomizationSpecWizardService",
            "editGuestOsCustomizationSpecDialogService",
            "fileSaverService",
            "deleteGosSpecService",
            "duplicateGosSpecService",
            "importGosSpecService",
            "ftVmService",
            "customizeGosPreCheckSpecsService",
            "customizeGosSelectSpecDialogService",
            "browserService",
            "unlockVmService",
            "$injector",
            ($rootScope: any,
             actionDialogService: any,
             mutationService: any,
             openConsoleService: any,
             createVmWizardService: any,
             powerOpsService: any,
             creationTypeConstants: any,
             migrateVmWizardService: any,
             $window: any,
             remoteConsoleService: any,
             markAsTemplateService: MarkAsTemplateService,
             snapshotService: any,
             vscExportWizardLauncherService:any,
             i18nService: any,
             dataService: any,
             clarityModalService: any,
             contentLibraryService: any,
             upgradeVmHardwareService: any,
             keyboardShortcutService: any,
             vmToolsService: VmToolsService,
             defaultUriSchemeUtil: any,
             managedEntityConstants: any,
             moveVmService: MoveVmService,
             nameAndFolderValidatorService: any,
             vmDragAndDropService: VmDragAndDropService,
             vmRemovalService: VmRemovalService,
             vmFtService: VmFtService,
             createGosSpecService: CreateGuestOsCustomizationSpecWizardService,
             editGosSpecService: EditGuestOsCustomizationSpecDialogService,
             fileSaverService: any,
             deleteGosSpecService: DeleteGosSpecService,
             duplicateGosSpecService: DuplicateGosSpecService,
             importGosSpecService: ImportGosSpecService,
             ftVmService: FtVmService,
             customizeGosPreCheckSpecsService: CustomizeGosPreCheckSpecsService,
             customizeGosSelectSpecDialogService: CustomizeGosSelectSpecDialogService,
             browserService: any,
             unlockVmService: UnlockVmService,
             $injector: any) => {
               /*
                * Register the action invoker function in the h5.actions cache.
                */

               h5.actions["vsphere.core.vm.actions.editNotes"] = (actionEval: ActionEvaluation, availableTargets: string[], context: any) => {
                  let onUpdateVmNotes = (objectId: string, notesValue: string, operationName: string) => {
                     let specType: string = "com.vmware.vsphere.client.vm.VmNotesSpec";
                     let propSpec: any = {
                        notes: notesValue
                     };
                     mutationService.apply(objectId, specType, propSpec, operationName);
                     return true;
                  };

                  let dialogData: any = {
                     notesPropName: "config.annotation",
                     onUpdateNotesCallback: onUpdateVmNotes,
                     focusTarget: context && context.focusTarget // trigger element
                  };

                  clarityModalService.openModal(actionEval, availableTargets, dialogData,
                        "common-module-ui/resources/common-module/views/notes/NotesDialog.html");
               };

               keyboardShortcutService.bindAction("ctrl+alt+b", "vsphere.core.vm.powerOnAction");
               h5.actions["vsphere.core.vm.actions.powerOn"] = (actionEval: ActionEvaluation, availableTargets: string[]) => {
                  powerOpsService.powerOnVm(actionEval, availableTargets);
               };

               keyboardShortcutService.bindAction("ctrl+alt+t", "vsphere.core.vm.resetAction");
               h5.actions["vsphere.core.vm.actions.reset"] = (actionEval: ActionEvaluation, availableTargets: string[]) => {
                  powerOpsService.resetVMs(availableTargets);
               };

               keyboardShortcutService.bindAction("ctrl+alt+e", "vsphere.core.vm.powerOffAction");
               h5.actions["vsphere.core.vm.actions.powerOff"] = (actionEval: ActionEvaluation, availableTargets: string[]) => {
                  powerOpsService.powerOffVm(availableTargets);
               };

               keyboardShortcutService.bindAction("ctrl+alt+d", "vsphere.core.vm.shutdownGuestAction");
               h5.actions["vsphere.core.vm.actions.shutdownGuest"] = (actionEval: ActionEvaluation, availableTargets: string[]) => {
                  powerOpsService.shutdownGuest(availableTargets);
               };

               keyboardShortcutService.bindAction("ctrl+alt+r", "vsphere.core.vm.rebootGuestAction");
               h5.actions["vsphere.core.vm.actions.rebootGuest"] = (actionEval: ActionEvaluation, availableTargets: string[]) => {
                  powerOpsService.rebootGuest(availableTargets);
               };

               keyboardShortcutService.bindAction("ctrl+alt+z", "vsphere.core.vm.suspendAction");
               h5.actions["vsphere.core.vm.actions.suspend"] = (actionEval: ActionEvaluation, availableTargets: string[]) => {
                  powerOpsService.suspendVMs(availableTargets);
               };

               h5.actions["vsphere.core.vm.actions.installTools"] = (actionEval: ActionEvaluation, availableTargets: string[]) => {
                  clarityModalService.openModal(
                        actionEval,
                        availableTargets,
                        {
                           objectId: availableTargets[0],
                           title: i18nService.getString("VmUi", "VmToolsPopupForm.singleInstall.title"),
                           defaultButton: "submit"
                        },
                        "vm-ui/resources/vm/vmtools/vmInstallTools.html");
               };

               h5.actions["vsphere.core.vm.actions.upgradeTools"] = (actionEval: ActionEvaluation, availableTargets: string[]) => {
                  clarityModalService.openModal(
                        actionEval,
                        availableTargets,
                        {
                           objectId: availableTargets[0],
                           title: i18nService.getString("VmUi", "VmToolsPopupForm.singleUpgrade.title"),
                           defaultButton: "submit"
                        },
                        "vm-ui/resources/vm/vmtools/vmUpgradeTools.html");
               };

               h5.actions["vsphere.core.vm.actions.unmountTools"] = (actionEval: ActionEvaluation, availableTargets: string[]) => {
                  vmToolsService.unmount(availableTargets);
               };

               h5.actions["vsphere.core.vm.actions.customizeGuestOs"] = (actionEval: ActionEvaluation, availableTargets: string[]) => {
                  let vmId: string = availableTargets[0];
                  return customizeGosPreCheckSpecsService.customizeGosLoadData(vmId)
                        .then((data: CustomizeGosSpecData) => {
                           let dialogContext: CustomizeGosSelectSpecDialogContext = {
                              vmId: vmId,
                              vmGuestOsFullName: data.guestFullName,
                              vmGuestOsType: data.guestType,
                              relevantSpecs: data.relevantSpecs
                           };
                           return customizeGosSelectSpecDialogService.open(dialogContext);
                        });

               };

               h5.actions["vsphere.core.vm.actions.guestOSUserMapping"] = (actionEval: ActionEvaluation,
                     availableTargets: string[]) => {
                  if ($rootScope && $rootScope._navigateToViewAndObject) {
                     $rootScope._navigateToViewAndObject(
                           'vsphere.core.vm.manage.settings.guestUserMappingsView',
                           availableTargets[0]);
                  }
               };

               h5.actions["vsphere.core.vm.installUpgradeToolsAction"] = (actionEval: ActionEvaluation, availableTargets: string[]) => {
                  vmToolsService.installUpgrade(actionEval, availableTargets);
               };

               h5.actions["vsphere.core.vm.vmQuestionAnswer"] = (actionEval: ActionEvaluation, availableTargets: string[], vmQuestionInfo: VmQuestionInfo) => {
                  const vmId = availableTargets[0];
                  const modalOptions: any = {
                     title: i18nService.getString("VmUi", "VmQuestionAnswerView.titleNoEntityName"),
                     subTitle: {
                        objectId: vmId
                     },
                     contentTemplate: "vm-ui/resources/vm/views/questions/vmQuestionAnswerDialog.html",
                     size: "lg",
                     defaultButton: "submit",
                     dialogData: {
                        vmId: vmId,
                        vmQuestionIssue: vmQuestionInfo
                     }
                  };

                  clarityModalService.openOkCancelModal(modalOptions);
               };

               h5.actions["vsphere.core.vm.actions.cryptoUnlock"] = (actionEval: ActionEvaluation, availableTargets: string[], context: any) => {
                  unlockVmService.unlockVm(actionEval, availableTargets, context);
               };

               h5.actions["vsphere.core.vm.actions.takeSnapshot"] = (actionEval: ActionEvaluation, availableTargets: string[]) => {
                  snapshotService.openTakeSnapshotDialog(availableTargets, actionEval);
               };

               h5.actions["vsphere.core.vm.actions.manageSnapshots"] = (actionEval: ActionEvaluation, availableTargets: string[]) => {
                  snapshotService.openManageSnapshotDialog(availableTargets, actionEval);
               };

               h5.actions["vsphere.core.vm.actions.revertToLatestSnapshot"] = (actionEval: ActionEvaluation, availableTargets: string[]) => {
                  let vmId: string = availableTargets[0];

                  snapshotService.getSnapshotsForVM(vmId, true).then(function (data: any) {
                     snapshotService.onRevertToActionMenuClicked(vmId, data);
                  });
               };

               h5.actions["vsphere.core.vm.actions.consolidateSnapshots"] = (actionEval: ActionEvaluation, availableTargets: string[]) => {
                  snapshotService.openConsolidateSnapshotDialog(availableTargets);
               };

               h5.actions["vsphere.core.vm.actions.deleteAllSnapshots"] = (actionEval: ActionEvaluation, availableTargets: string[]) => {
                  snapshotService.openRemoveAllSnapshotsConfirmationDialog(availableTargets[0]);
               };

               h5.actions["vsphere.core.vm.actions.exportLogs"] = (actionEval: ActionEvaluation, availableTargets: string[]) => {
                  vscExportWizardLauncherService.showSystemLogExportDialog(availableTargets);
               };

               h5.actions["vsphere.core.vm.actions.editSettings"] = (actionEval: ActionEvaluation, availableTargets: string[]) => {
                  clarityModalService.openModal(actionEval, availableTargets, undefined,
                        "vm-ui/resources/vm/views/settings/vmHardwareModal.html"
                  );
               };

               h5.actions["vsphere.core.vm.actions.editEvcMode"] = (actionEval: ActionEvaluation, availableTargets: string[]) => {
                  let modalOptions = {
                     title: i18nService.getString("VmUi", "vmEvc.edit.dialogTitle"),
                     subTitle: {
                        objectId: availableTargets[0]
                     },
                     defaultButton: "submit",
                     contentTemplate: "vm-ui/resources/vm/evc-mode/edit-vm-evc.view.html",
                     size: "lg",
                     dialogData: {
                        vmId: availableTargets[0]
                     }
                  };

                  clarityModalService.openOkCancelModal(modalOptions);
               };

               h5.actions["vsphere.core.vm.actions.openConsole"] = (actionEval: ActionEvaluation, availableTargets: string[]) => {
                  let actionContext: any = actionEval.context;
                  let target: any = actionContext.targetObjects[0];
                  openConsoleService.openConsole(availableTargets[0], target.value, target.serverGuid);
               };

               h5.actions["vsphere.core.vm.actions.launchRemoteConsole"] = (actionEval: ActionEvaluation, availableTargets: string[]) => {
                  if (availableTargets && availableTargets.length !== 0) {
                     remoteConsoleService.getRemoteConsoleUrl(availableTargets[0]).then((remoteConsoleUrl: string) => {
                        if (browserService.isFirefox()) {
                           $window.open(remoteConsoleUrl);
                        } else {
                           $window.location.href = remoteConsoleUrl;
                        }
                     });
                  }
               };

               h5.actions["vsphere.core.vm.actions.downloadVMRC"] = (actionEval: ActionEvaluation, availableTargets: string[]) => {
                  window.open("http://www.vmware.com/go/download-vmrc");
               };

               // Clone Vm actions
               h5.actions["vsphere.core.vm.actions.cloneVmToTemplate"] = (actionEval: ActionEvaluation, availableTargets: string[]) => {
                  let context: any = {
                     actionLabel: ""
                  };
                  if (actionEval && actionEval.action) {
                     context.actionLabel = actionEval.action.label;
                  }
                  createVmWizardService.showWizard(availableTargets[0], creationTypeConstants.CLONE_VM_TO_TEMPLATE, context);
               };

               h5.actions["vsphere.core.vm.actions.cloneVmToVm"] = (actionEval: ActionEvaluation, availableTargets: string[]) => {
                  let context: any = {
                     actionLabel: ""
                  };
                  if (actionEval && actionEval.action) {
                     context.actionLabel = actionEval.action.label;
                  }
                  if (actionEval && actionEval.additionalData && actionEval.additionalData.isScheduledTask) {
                     context.isScheduledTask = actionEval.additionalData.isScheduledTask;
                     context.schedulingData =  actionEval.additionalData.schedulingData;
                  }
                  createVmWizardService.showWizard(availableTargets[0], creationTypeConstants.CLONE_VM_TO_VM, context);
               };

               h5.actions["vsphere.core.vm.actions.cloneVmToLibrary"] = (actionEval: ActionEvaluation, availableTargets: string[]) => {
                  dataService.getProperties(availableTargets[0], ['parent']).then(function (result: any) {
                     let folderUid = null;
                     if(result.parent){
                        folderUid = defaultUriSchemeUtil.getVsphereObjectId(result.parent);
                     }
                     createVmWizardService.showWizard(availableTargets[0], creationTypeConstants.CLONE_AS_VMTEMPLATE_TO_LIBRARY, {
                        folderUid: folderUid
                     });
                  });
               };

               h5.actions["vsphere.core.vm.actions.cloneVappToLibrary"] = (actionEval: ActionEvaluation, availableTargets: string[]) => {
                  dataService.getProperties(availableTargets[0], ['parent']).then(function (result: any) {
                     let folderUid = null;
                     if(result.parent){
                        folderUid = defaultUriSchemeUtil.getVsphereObjectId(result.parent);
                     }
                     createVmWizardService.showWizard(availableTargets[0], creationTypeConstants.CLONE_VAPP_AS_VMTEMPLATE_TO_LIBRARY, {
                        folderUid: folderUid
                     });
                  });
               };

               h5.actions["vsphere.core.vm.actions.deployFromTemplate"] = (actionEval: ActionEvaluation, availableTargets: string[]) => {
                  createVmWizardService.showWizard(availableTargets[0], creationTypeConstants.CLONE_TEMPLATE_TO_VM);
               };

               h5.actions["vsphere.core.vm.actions.convertTemplateToVmAction"] = (actionEval: ActionEvaluation, availableTargets: string[]) => {
                  createVmWizardService.showWizard(availableTargets[0], creationTypeConstants.CONVERT_TEMPLATE_TO_VM);
               };

               h5.actions["vsphere.core.vm.actions.cloneTemplateToTemplate"] = (actionEval: ActionEvaluation, availableTargets: string[]) => {
                  createVmWizardService.showWizard(availableTargets[0], creationTypeConstants.CLONE_TEMPLATE_TO_TEMPLATE);
               };

               h5.actions["vsphere.core.vm.actions.cloneTemplateToLibrary"] = (actionEval: ActionEvaluation, availableTargets: string[]) => {
                  let service: any = $injector.get("vscCloneVmToLibraryProxyService");
                  service.openModal(availableTargets[0]);
               };

               // Template actions
               h5.actions["vsphere.core.vm.actions.markAsTemplate"] = (actionEval: ActionEvaluation, availableTargets: string[]) => {
                  markAsTemplateService.markAsTemplate(availableTargets);
               };

               h5.actions["vsphere.core.vm.actions.exportAsOVF"] = (actionEval: ActionEvaluation, availableTargets: string[]) => {
                  if (availableTargets.length === 0) {
                     return;
                  }

                  let objectId: string = availableTargets[0];
                  let service: any = $injector.get("vscExportOvfModalService");
                  service.openExportOvfDialog(objectId);
               };

               // Upgrade Vm compatibility actions
               h5.actions["vsphere.core.vm.actions.upgradeVmVirtualHardware"] = (actionEval: ActionEvaluation, availableTargets: string[]) => {
                  upgradeVmHardwareService.upgradeVmHardware(actionEval, availableTargets);
               };

               // Schedule upgrade hardware action
               h5.actions["vsphere.core.vm.actions.scheduleUpgradeVmVirtualHardware"] = (actionEval: ActionEvaluation, availableTargets: string[]) => {
                  upgradeVmHardwareService.scheduleUpgradeVmHardware(actionEval, availableTargets);
               };

               // Cancel schedule upgrade hardware action
               h5.actions["vsphere.core.vm.actions.upgradeVirtualHardwareActionCancel"] = (actionEval: ActionEvaluation, availableTargets: string[]) => {
                  upgradeVmHardwareService.cancelScheduledVmHardwareUpgrade(actionEval, availableTargets);
               };

               h5.actions["vsphere.core.provisioning.actions.deployOVF"] = (actionEval: ActionEvaluation, availableTargets: string[]) => {
                  createVmWizardService.showWizard(availableTargets[0], creationTypeConstants.DEPLOY_VM_FROM_OVF);
               };

               h5.actions["vsphere.core.vm.actions.vmMigrate"] = (
                     actionEval: ActionEvaluation,
                     availableTargets: string[],
                     destinationTarget: DestinationTarget) => {
                  if (destinationTarget && destinationTarget.dropTarget) {
                     vmDragAndDropService.retrieveMoveOrMigrateData(destinationTarget.dropTarget, availableTargets)
                           .then((moveOrMigrateData: MoveOrMigrateData) => {
                              if (moveOrMigrateData.moveVms.length > 0) {
                                 moveVmService.moveVms(
                                       moveOrMigrateData.moveVms,
                                       {dropTarget: moveOrMigrateData.moveTarget},
                                       actionEval);
                              }
                              if (moveOrMigrateData.migrateVms.length > 0) {
                                 migrateVmWizardService.showWizard(
                                       moveOrMigrateData.migrateVms,
                                       {dropTarget: moveOrMigrateData.migrateTarget},
                                       actionEval);
                              }

                           });
                     return;
                  }
                  migrateVmWizardService.showWizard(availableTargets, destinationTarget, actionEval);
               };

               h5.actions["vsphere.core.vm.actions.move"] = (actionEval: ActionEvaluation, availableTargets: string[], destinationTarget: DestinationTarget) => {
                  moveVmService.moveVms(availableTargets, destinationTarget, actionEval);
               };

               h5.actions["vsphere.core.vm.actions.dragAndDrop"] = (actionEval: ActionEvaluation, availableTargets: string[], destinationTarget: DestinationTarget) => {
                  vmDragAndDropService.moveOrMigrateVm(availableTargets, destinationTarget);
               };

               h5.actions["vsphere.core.vm.actions.unregisterAction"] = (actionEval: ActionEvaluation, availableTargets: string[]) => {
                  vmRemovalService.unregisterVms(availableTargets);
               };

               h5.actions["vsphere.core.vm.actions.unregisterVmTemplateAction"] = (actionEval: ActionEvaluation, availableTargets: string[]) => {
                  // This does the same calls as unregistering a VM
                  // but let"s handle it as a different invoker for the sake of readability.
                  vmRemovalService.unregisterVms(availableTargets);
               };

               h5.actions["vsphere.core.vm.actions.turnOffFt"] =
                     (actionEval: ActionEvaluation, availableTargets: string[]): void => {
                        vmFtService.turnOffFt(availableTargets);
                     };

               h5.actions["vsphere.core.vm.actions.deleteAction"] = (actionEval: ActionEvaluation, availableTargets: string[]) => {
                  vmRemovalService.deleteVms(availableTargets);
               };

               h5.actions["vsphere.core.vm.actions.deleteTemplateAction"] = (actionEval: ActionEvaluation, availableTargets: string[]) => {
                  // This does the same calls as deleting a VM
                  // but let"s handle it as a different invoker for the sake of readability.
                  vmRemovalService.deleteVms(availableTargets);
               };

               h5.actions["vsphere.core.vm.actions.create"] = (actionEval: ActionEvaluation, availableTargets: string[]) => {

                  if (actionEval.additionalData && actionEval.additionalData.isScheduledTask) {
                     var context: any = {
                        isScheduledTask: actionEval.additionalData ? actionEval.additionalData.isScheduledTask: false,
                        schedulingData: actionEval.additionalData ? actionEval.additionalData.schedulingData: null
                     };
                     createVmWizardService.showWizard(availableTargets[0], creationTypeConstants.CREATE_FROM_SCRATCH, context);
                  } else {
                     createVmWizardService.showWizard(availableTargets[0], creationTypeConstants.CREATE_FROM_SCRATCH);
                  }
               };

               h5.actions["vsphere.core.vm.actions.registerVm"] = (actionEval: ActionEvaluation, availableTargets: string[], context: RegisterVmContext) => {
                  createVmWizardService.showWizard(
                        availableTargets[0], creationTypeConstants.REGISTER_VM, context);
               };

               h5.actions["vsphere.core.vm.gos.customization.new"] =
                     (actionEval: any, availableTargets: any, context: any) => {
                        let onActionInvoked = context.onActionInvoked;
                        createGosSpecService.open(onActionInvoked,
                              context.gosCustomizationSpecs);
                     };

               h5.actions["vsphere.core.vm.gos.customization.edit"] =
                     (actionEval: any, availableTargets: any, context: any) => {
                        let editSpecContext: DialogContext = {
                           specInfo: context.gosCustomizationSpecInfo,
                           specDetails: context.gosCustomizationSpecDetails,
                           allSpecs: context.gosCustomizationSpecs,
                           onActionInvoked: context.onActionInvoked,
                           iconClass: actionEval.action.icon
                        };

                        editGosSpecService.open(editSpecContext);
                     };

               h5.actions["vsphere.core.vm.gos.customization.export"] =
                     (actionEval: ActionEvaluation, availableTargets: any, context: any) => {
                        let fileName: string = context.gosCustomizationSpecInfo.name + ".xml";
                        fileSaverService.save(context.gosCustomizationSpecXmlText, fileName);
                     };

               h5.actions["vsphere.core.vm.gos.customization.delete"] =
                     (actionEval: any, availableTargets: any, context: any) => {
                        const specInfo: CustomizationSpecInfoCustom =
                              context.gosCustomizationSpecInfo;
                        const onActionInvoked = context.onActionInvoked;
                        const title: string = actionEval.action.confirmationTitle;
                        const message: string = actionEval.action.confirmationText;

                        return deleteGosSpecService.confirmDelete(title, message).then(
                              () => {
                                 return deleteGosSpecService.delete(specInfo)
                                       .then((opResult: OperationResult) => {
                                          if (onActionInvoked) {
                                             onActionInvoked(
                                                   "vsphere.core.vm.gos.customization.delete",
                                                   opResult);
                                          }
                                       });
                              },
                              () => {
                                 onActionInvoked("vsphere.core.vm.gos.customization.delete");
                              });
                     };

               h5.actions["vsphere.core.vm.gos.customization.duplicate"] =
                     (actionEval: any, availableTargets: any, context: any) => {
                        const specInfo: CustomizationSpecInfoCustom =
                              context.gosCustomizationSpecInfo;
                        const gosCustomizationSpecs =
                              context.gosCustomizationSpecs;
                        const onActionInvoked = context.onActionInvoked;
                        return duplicateGosSpecService.openDuplicateForm(specInfo, gosCustomizationSpecs)
                              .then((result: DuplicateGosSpecResult) => {
                                 if (onActionInvoked) {
                                    onActionInvoked(
                                          "vsphere.core.vm.gos.customization.duplicate",
                                          result.result,
                                          result.openSpecForEdit);
                                 }
                              });
                     };

               h5.actions["vsphere.core.vm.gos.customization.import"] =
                     (actionEval: ActionEvaluation, availableTargets: string[],
                      context: GosCustomizationImportContext) => {
                        return importGosSpecService.openImportModal(context.gosCustomizationSpecs)
                              .then((result: GosCustomizationImportPromise) => {
                                 let onActionInvoked = context.onActionInvoked;
                                 if (onActionInvoked) {
                                    context.onActionInvoked("vsphere.core.vm.gos.customization.import",
                                          result.result, result.invokeEdit);
                                 }
                              });
                     };

               h5.actions["vsphere.core.vm.actions.vappOptions.edit"] =
                     (actionEval: any, availableTargets: any, context: any) => {
                        // Invoke a downgraded Angular2 service that shows the popup
                        $injector.get("vcVappOptionsEditActionService").editVappOptions(
                              availableTargets[0]);
                     };

               h5.actions["vsphere.core.vm.actions.viewOvfEnvironment"] =
                     (actionEval: any, availableTargets: any, context: any) => {
                        // Invoke a downgraded Angular2 service that shows the popup
                        if (actionEval.available &&
                              availableTargets &&
                              availableTargets.length) {
                           $injector.get("vcVappViewOvfEnironmentService")
                                 .showOvfEnvironment(availableTargets[0]);

                        }
                     };

               h5.actions["vsphere.core.vm.tpm.export"] =
                     (actionEval: ActionEvaluation, availableTargets: any, context: any) => {
                        if (!context || !context.algorithmName) {
                           return;
                        }
                        // The cert or CRS algorithmName is the most practical filename.
                        let extension: string = context.issuerName ? ".cer" : ".csr" ;
                        let fileName: string = context.algorithmName + extension;
                        fileSaverService.save(context.rawData, fileName);
                     };

               h5.actions["vsphere.core.vm.actions.turnOnFt"] =
                     (actionEval: ActionEvaluation, availableTargets: string[]) => {
                        ftVmService.openVmFaultToleranceWizard(availableTargets[0]);
                     };

               h5.actions["vsphere.core.vm.actions.resumeFt"] =
                     (actionEval: ActionEvaluation, availableTargets: string[]) => {
                        ftVmService.resumeFaultTolerance(availableTargets[0]);
                     };

               h5.actions["vsphere.core.vm.actions.suspendFt"] =
                     (actionEval: ActionEvaluation, availableTargets: string[]) => {
                        ftVmService.suspendFaultTolerance(availableTargets[0]);
                     };

               h5.actions["vsphere.core.vm.actions.migrateSecondaryFtVm"] =
                     (actionEval: ActionEvaluation, availableTargets: string[]) => {
                        ftVmService.migrateSecondaryFtVm(availableTargets[0]);
                     };

               h5.actions["vsphere.core.vm.actions.testFailover"] =
                     (actionEval: ActionEvaluation, availableTargets: string[]) => {
                        ftVmService.ftTestFailover(availableTargets[0]);
                     };

               h5.actions["vsphere.core.vm.actions.restartSecondary"] =
                     (actionEval: ActionEvaluation, availableTargets: string[]) => {
                        ftVmService.ftRestartSecondary(availableTargets[0]);
                     };

            }]);
}
