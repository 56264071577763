angular.module('com.vmware.vsphere.client.vm').service('vmProvisioningProductDetailsPageService', [
   'i18nService',
   'wizardPageService',
   'vmVuiWizardPageBuilder',
   'vmProvisioningProductDetailsPageModel',
   function (i18nService, wizardPageService, vmVuiWizardPageBuilder, vmProvisioningProductDetailsPageModel) {
      return {
         build: function(wizardScope, defaultTarget, preselectedComputeResource) {
            var productDetailsPageModel = new vmProvisioningProductDetailsPageModel(
                  wizardScope.vmParams ,
                  wizardScope.wizardViewData,
                  wizardScope.config,
                  defaultTarget,
                  preselectedComputeResource);

            wizardScope.productDetailsPageModel = productDetailsPageModel;

            return vmVuiWizardPageBuilder.buildVuiWizardPage(wizardScope.config,  {
               title: i18nService.getString('ProvisioningUiLib', 'ProductDetailsPage.title'),
               description: i18nService.getString('ProvisioningUiLib', 'ProductDetailsPage.description'),
               contentUrl: 'vm-ui/resources/vm/views/createVmWizard/deployOvfPages/' +
                  'vmProvisioningProductDetails/vm-provisioning-product-details.html',
               model: productDetailsPageModel
            });
         }
      };
   }]);
