(function() {
   'use strict';

   angular
      .module('com.vmware.vsphere.client.vm')
      .factory('vmProvisioningProductDetailsDataModel', vmProvisioningProductDetailsDataModel);

   vmProvisioningProductDetailsDataModel.$inject = [
      'i18nService',
      'fileSaverService',
      'browserLanguage'
   ];

   function vmProvisioningProductDetailsDataModel (i18nService, fileSaverService, browserLanguage) {
      /*jshint validthis:true */
      var self = this,
         MB = 1024 * 1024,//GB
         GB = 1024 * MB,
         TB = 1024 * GB;
      return {
         /** Certificate Params */
         initProductDetails: initProductDetails,
         onCertClicked: onCertClicked,
         getPublisher: getPublisher,
         getCertTrust: getCertTrust,
         hasCert: hasCert,
         /** Size Params */
         getDownloadSize: getDownloadSize,
         getSizeOnDiskThick: getSizeOnDiskThick,
         getSizeOnDiskThin: getSizeOnDiskThin,
         getSizeOnDisk: getSizeOnDisk,
         /** Product Details Params */
         getProductName: getProductName,
         getVersion: getVersion,
         hasProduct: hasProduct,
         getProductUri: getProductUri,
         getVendor : getVendor,
         getVendorUri: getVendorUri,
         getDescription: getDescription,
         hasVendor: hasVendor,
         hasVersion: hasVersion,
         hasVendorUri: hasVendorUri,
         hasProductUri: hasProductUri,
         hasDescription: hasDescription,
         updateVmNameToDeploy: updateVmNameToDeploy,
         getExtraConfigs: getExtraConfigs
      };

      /** Cert Params*/
      function onCertClicked() {
         if (!self.isCertPresent ||
               _.isEmpty(self.x509) ||
               _.isEmpty(self.subject)) {
            return;
         }
         //saves the file to the specified location as subject.cer file
         fileSaverService.save(self.x509, self.issuer + ".cer");
      }

      function hasCert() {
         return self.isCertPresent;
      }

      function getPublisher() {
         return self.issuer;
      }

      function getCertTrust() {
         return !self.isCertPresent ?
               i18nService.getString('ProvisioningUiLib', 'ProductDetailsPage.publisher.nocert') :
               (!self.isCertValid ?
                  i18nService.getString('ProvisioningUiLib', 'ProductDetailsPage.publisher.invalid') :
                  (self.isSelfSigned ?
                     i18nService.getString('ProvisioningUiLib', 'ProductDetailsPage.publisher.untrusted'):
                     i18nService.getString('ProvisioningUiLib', 'ProductDetailsPage.publisher.trusted')));
      }


      /** Product Details Params*/
      function hasVendorUri() {
         return !_.isEmpty(self.vendorUri);
      }

      function hasProductUri() {
         return !_.isEmpty(self.productUri);
      }

      function hasVersion() {
         return !_.isEmpty(self.version) || !_.isEmpty(self.fullVersion);
      }

      function hasVendor() {
         return !_.isEmpty(getVendor());
      }

      function hasProduct() {
         return !_.isEmpty(getProductName());
      }

      function hasDescription() {
         return !_.isEmpty(getDescription());
      }

      function getProductName() {
         return self.productName;
      }

      function getVendor() {
         return self.vendor;
      }

      function getVersion() {
         return self.version ||  self.fullVersion;
      }

      function getVendorUri() {
         return self.vendorUri;
      }

      function getProductUri() {
         return self.productUri;
      }

      function getDescription() {
         return self.description;
      }

      /** Size Params */
      function getDownloadSize() {
         return getSizeString(self.downloadSize);
      }

      function getSizeString(size) {
         if (!size || size === -1) {
            return i18nService.getString('ProvisioningUiLib', 'ProductDetailsPage.unknown');
         }

         if (size < MB) {
            return i18nService.getString('ProvisioningUiLib', 'ProductDetailsPage.lessThan1Mb');
         }

         if (size > TB) {
            return (size/TB).toLocaleString(browserLanguage.get(), { minimumFractionDigits:1, maximumFractionDigits:1 }) + ' TB';
         } else if (size > GB) {
            return (size/GB).toLocaleString(browserLanguage.get(), { minimumFractionDigits:1, maximumFractionDigits:1 }) + ' GB';
         } else {
            return (size/MB).toLocaleString(browserLanguage.get(), { minimumFractionDigits:1, maximumFractionDigits:1 }) + ' MB';
         }
      }

      function getSizeOnDiskThick() {
         var thickProvisioned = self.flatDeploymentSize;
         var thickProvString = getSizeString(thickProvisioned) + " " +
            i18nService.getString('ProvisioningUiLib', 'ProductDetailsPage.thickProvisionedString');

         return thickProvString;
      }

      function getSizeOnDiskThin() {
         var thinProvisioned = self.sparseDeploymentSize;
         var thinProvString = getSizeString(thinProvisioned) + " " +
            i18nService.getString('ProvisioningUiLib', 'ProductDetailsPage.thinProvisionedString');
         return thinProvString;
      }

      function getSizeOnDisk(isThinDeployment) {
         var deploymentSize = isThinDeployment ? self.sparseDeploymentSize : self.flatDeploymentSize;
         return getSizeString(deploymentSize);
      }

      function getExtraConfigs() {
         return self.extraConfigs;
      }

      function initProductDetails(commonContext) {
         resetData();
         _.each(commonContext, function(context) {
            if (context) {
               if (context._type === 'com.vmware.vcenter.ovf.NameAndProductParams') {
                  self.productName = context.productName;
                  self.version = context.version;
                  self.fullVersion = context.fullVersion;
                  self.vendor = context.vendor;
                  self.vApp = context.virtualApp;
                  self.productUri = context.productUrl;
                  self.vendorUri = context.vendorUrl;
                  self.description = context.annotation;
               } else if (context._type === 'com.vmware.vcenter.ovf.SizeParams') {
                  self.downloadSize = context.approximateDownloadSize;
                  self.flatDeploymentSize = context.approximateFlatDeploymentSize;
                  self.sparseDeploymentSize = context.approximateSparseDeploymentSize;
               } else if (context._type === 'com.vmware.vcenter.ovf.CertificateParams') {
                  self.isCertPresent = true;
                  self.isCertValid = context.isValid;
                  self.issuer = parseCertIssuerName(context.issuer);
                  self.isSelfSigned = context.isSelfSigned;
                  self.subject = context.subject;
                  self.x509 = context.x509;
               } else if (context._type === 'com.vmware.vcenter.ovf.ExtraConfigParams') {
                  self.extraConfigs = context.extraConfigs;
               }
            }
         });
      }

      function updateVmNameToDeploy(vmName, commonContext) {
         var contextNameAndProduct = _.find(commonContext, function(context) {
            return context && context._type === 'com.vmware.vcenter.ovf.NameAndProductParams';
         });

         if (contextNameAndProduct) {
            contextNameAndProduct.name = vmName;
         }
      }

      function resetData() {
         self.productName = null;
         self.version = null;
         self.fullVersion = null;
         self.vendor = null;
         self.vApp = null;
         self.vendorUri = null;
         self.productUrl = null;
         self.description = null;
         self.downloadSize = -1;
         self.flatDeploymentSize = -1;
         self.sparseDeploymentSize = -1;
         self.isCertPresent = false;
         self.isCertValid = false;
         self.isSelfSigned = false;
         self.issuer = null;
         self.subject = null;
         self.x509 = null;
      }

      function parseCertIssuerName(strCertIssuer) {
         if (_.isEmpty(strCertIssuer)) {
            return '';
         }
         var cn = null,
            o = null;
         var list = strCertIssuer.split('/');
         _.each (list, function(splitPart) {
            //get the CN or O and return
            if (!_.isEmpty(splitPart)) {
               var part = splitPart;
               if (part.toUpperCase().indexOf('CN=') === 0) {
                  cn = splitPart.substr(3);
               } else if (part.toUpperCase().indexOf('O=') === 0) {
                  o = splitPart.substr(2);
               }
            }
         });
         return cn || o;
      }
   }
})();
