(function () {
   'use strict';
   angular.module('com.vmware.vsphere.client.network').controller(
         'PhysicalAdaptersViewController', PhysicalAdaptersViewController);

   PhysicalAdaptersViewController.$inject = ['i18nService',
      '$scope',
      'vuiConstants',
      'physicalAdaptersListColumns',
      'physicalAdaptersListService',
      'networkUiConstants',
      'actionToolbarFactory',
      '$q',
      'addNetworkingWizardConstants'];

   function PhysicalAdaptersViewController(i18nService,
                                           $scope,
                                           vuiConstants,
                                           physicalAdaptersListColumns,
                                           physicalAdaptersListService,
                                           networkUiConstants,
                                           actionToolbarFactory,
                                           $q,
                                           addNetworkingWizardConstants) {

      var self = this;
      self.liveRefreshProperties = [
         "config.network.pnic",
         "config.network.vswitch",
         "config.network.proxySwitch",
         "config.network.opaqueSwitch",
         "config.sriovDevicePool",
         "config.pciPassthruInfo"
      ];
      self.physicalAdaptersLoading = false;
      self.showNoItemSelected = true;
      self.hostId = $scope._route.objectId;
      self.headerOptions = {
         title: i18nService.getString('NetworkUi', 'host.manage.pnicHeader'),
         objectId: self.hostId,
         actions: []
      };

      self.splitterOptions = {
         orientation: vuiConstants.splitter.orientation.VERTICAL,
         panes: [
            {
               min: '150px',
               size: '45%'
            }, {
               min: '250px',
               size: '55%'
            }
         ]
      };

      var evaluationContext = { pnic: null };
      var actionAvailabilityById = {};
      evaluationContext.connectionType = addNetworkingWizardConstants.connectionType.PNIC;
      self.refresh = function () {
         self.errorRetrievingData = null;
         var pnicActions = [
            networkUiConstants.actions.ADD_NETWORKING,
            networkUiConstants.actions.REFRESH_NETWORK,
            vuiConstants.actions.SEPARATOR,
            networkUiConstants.actions.EDIT_PNIC];

         var physicalAdaptersPromise =
               physicalAdaptersListService.getPhysicalAdapterItems(self.hostId);

         var createActionbarOptionsPromise = actionToolbarFactory
               .createActionbarOptions([self.hostId], pnicActions, evaluationContext);

         self.physicalAdaptersLoading = true;
         $q.all([physicalAdaptersPromise, createActionbarOptionsPromise]).then(function (responses) {
            var physicalAdapterItems = responses[0];
            var actionbarOptions = responses[1];

            // When the datagridOptions.data is refreshed, the watch for the selectedItems
            // is called with an empty array before the grid preselection directive tries
            // to preselect the last selected item. This flag is used to skip the first
            // trigger of the watch when the data is refreshed.
            self.ingnoreNextItemSelection = true;

            if (!self.datagridOptions) {
               self.datagridOptions = createDatagridOptions(actionbarOptions);
            }

            self.datagridOptions.data = physicalAdapterItems;

            _.forEach(actionbarOptions.actions, function (action) {
               if (action !== vuiConstants.actions.SEPARATOR) {
                  actionAvailabilityById[action.actionId] = action.enabled;
               }
            });

            self.physicalAdaptersLoading = false;
         }, function (error) {
            self.physicalAdaptersLoading = false;
            var errorStr = error && error.data && error.data.message
                  ? error.data.message : '';

            self.errorRetrievingData = i18nService.getString(
                  'NetworkUi', 'PnicView.error', errorStr);
         });
      };

      self.refresh();

      $scope.preselectComparator = function (item) {
         return !!$scope.selectedPhysicalAdapterDevice
               && $scope.selectedPhysicalAdapterDevice === item.device;
      };

      function onPnicSelectionChange (selectedItems) {
         if (self.ingnoreNextItemSelection) {
            toggleActionsAvailability();
            self.ingnoreNextItemSelection = false;
            return;
         }

          self.showNoItemSelected =
               !selectedItems || selectedItems.length === 0;

         if (!self.showNoItemSelected) {
            $scope.selectedPhysicalAdapterDevice = selectedItems[0].device;
            evaluationContext.pnic = selectedItems[0].device;
            self.adapterDetailsHeader = i18nService.getString('NetworkUi',
                  'PnicPropertiesView.title', $scope.selectedPhysicalAdapterDevice);
         } else {
            $scope.selectedPhysicalAdapterDevice = null;
            evaluationContext.pnic = null;
         }
         toggleActionsAvailability();
      }

      function createDatagridOptions (actionBarOptions) {
         return {
            height: '100%',
            pageConfig: {
               hidePager: true
            },
            columnDefs: physicalAdaptersListColumns,
            sortMode: vuiConstants.grid.sortMode.SINGLE,
            selectionMode: vuiConstants.grid.selectionMode.SINGLE,
            data: [],
            selectedItems: [],
            resizable: true,
            actionBarOptions: actionBarOptions,
            onChange: onPnicSelectionChange
         };
      }

      // Toggles the enabled/disabled flag of Edit action that depend on some item's
      // selection
      function toggleActionsAvailability() {
         self.datagridOptions.actionBarOptions.actions.forEach(function(action) {
            if (action !== vuiConstants.actions.SEPARATOR) {
               action.enabled = actionAvailabilityById[action.actionId] &&
                     (self.datagridOptions.selectedItems.length > 0 ||
                     action.actionId !== networkUiConstants.actions.EDIT_PNIC);
            }
         });
      }
   }
})();
