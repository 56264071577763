/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace dvs_ui {

   export class DvsSpanSessionSelectDestinationsPageModel {

      public ipAddressesListModel: DvsSpanSessionEditableListModel
            = new DvsSpanSessionEditableListModel();

      public portsListModel: DvsSpanSessionPortsListModel
            = new DvsSpanSessionPortsListModel();

      public uplinksListModel: DvsSpanSessionDestinationUplinksModel
            = new DvsSpanSessionDestinationUplinksModel();
   }
}
