(function() {
   angular.module('com.vmware.vsphere.client.cluster').directive(
         'haPdlResponse', haPdlResponse);

   haPdlResponse.$inject = ['i18nService', 'haPdlResponseService',
      'haConstants'];

   function haPdlResponse(i18nService, haPdlResponseService,
         haConstants) {
      return {
         restrict: 'E',
         templateUrl: 'cluster-ui/resources/cluster/views/settings/services/ha/haPdlResponse.html',
         scope: {
            haConfiguration: '=',
            storagePdlSupported: '='
         },
         controller: ['$scope', function($scope) {
            $scope.isViewDisabled = function () {
               return !$scope.storagePdlSupported ||
                     !$scope.haConfiguration.hostMonitoringEnabled ||
                     $scope.haConfiguration.defaultVmSettings.restartPriority ===
                           haConstants.restartPriority.DISABLED;
            };
            $scope.pdlResponses = haPdlResponseService.getResponses();
            $scope.responseForPdlLabel = i18nService.getString('ClusterUi',
                  'ha.config.failures.responses.ds.dswithpdl.label');
            $scope.responseForPdlSubmenuLabel = i18nService.getString('ClusterUi',
                  'ha.config.failures.responses.ds.dswithpdl.submenu.label');
            $scope.responseForPdlDescription = i18nService.getString('ClusterUi',
                  'ha.config.failures.responses.ds.dswithpdl.desc');
         }]
      };
   }
})();
