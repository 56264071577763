/**
 * @name com.vmware.vsphere.client.cluster:clusterConstants
 * @module com.vmware.vsphere.client.cluster
 *
 * @description
 *    The `clusterConstants` service holds cluster related constants.
 *
 *    List of available constants:
 *
 *    - vmSwapPlacementType (vim.vm.ConfigInfo.SwapPlacementType enum values)
 *       - **`VM_DIRECTORY`** : Store the swap file in the same directory as
 *             the virtual machine
 *       - **`HOST_DATASTORE`** :  Store the swap file in the datastore
 */
angular.module('com.vmware.vsphere.client.cluster').constant('clusterConstants', {
   vmSwapPlacementType : {
      VM_DIRECTORY : 'vmDirectory',
      HOST_DATASTORE : 'hostLocal'
   },
   editClusterErrorCodes : {
      DRS_OFF_WITH_RESOURCE_POOLS: 'DRS_OFF_WITH_RESOURCE_POOLS',
      DPM_ON_WITH_HOSTS_STANDBY: 'DPM_ON_WITH_HOSTS_STANDBY',
      HA_APD_PDL_WITH_FT_VMS: 'HA_APD_PDL_WITH_FT_VMS'
   },
   MAX_INTEGER: 2147483647,
   proactiveHaAutomationLevel: {
      AUTOMATED: 'Automated',
      MANUAL: 'Manual'
   },
   proactiveHaRemediationLevel: {
      QUARANTINE: 'quarantine',
      BALANCED: 'balanced',
      MAINTENANCE: 'maintenance'
   },
   proactiveHaRemediationMode: {
      QUARANTINE_MODE: 'QuarantineMode',
      MAINTENANCE_MODE: 'MaintenanceMode'
   },
   proactiveHaBlockAtTypes: {
      CLUSTER_LEVEL: 'clusterLevel',
      INDIVIDUAL_HOSTS: 'individualHosts'
   },
   memoryUtilizationTypes: {
      ACTIVE: "active",
      CONSUMED: "consumed"
   }
});
