namespace storage_ui {
   angular.module("com.vmware.vsphere.client.storage").constant("advancedOptionConstants", {

      optionProperties: {
         OPTION_PROPERTY_NAME: "option",
         DESCRIPTION_PROPERTY_NAME: "description",
         VALUE_PROPERTY_NAME: "value",
         INHERITED_PROPERTY_NAME: "inherited"
      },

      optionTypes: {
         STRING_OPTION_TYPE: "StringOption",
         BOOLEAN_OPTION_TYPE: "BoolOption",
         LONG_OPTION_TYPE: "LongOption",
         INT_OPTION_TYPE: "IntOption"
      }

   });
}

