namespace license_ui {

   export class LicensesManagementNavigationService {

      public static $inject = [
            "navigation",
            "licensesManagementConstants"
      ];

      private readonly TAB_IDS_DELIMITER: string = ",";

      constructor(private navigation: any,
                  private licensesManagementConstants: LicensesManagementConstants) {
      }

      navigateByProvidedLicensingRouteObject(licensingRouteObject: LicensingRouteObject): void {
         if (!licensingRouteObject) {
            return;
         }

         let routingInformation: any = {};
         if (licensingRouteObject.tabPaths && licensingRouteObject.tabPaths.length) {
            routingInformation.tabPaths = licensingRouteObject.tabPaths.join(this.TAB_IDS_DELIMITER);
         }

         if (licensingRouteObject.itemId) {
            routingInformation.itemId = licensingRouteObject.itemId;
         }

         angular.extend(routingInformation, this.extractAdditionalParametersFromRoute());

         this.navigation.navigate(licensingRouteObject.extensionId, null, routingInformation);
      }

      navigateToLicensesManagementTabByTabId(tabId?: string): void {
         // if the url route already indicates that the given tab is selected, do nothing.
         const route: any = this.navigation.getRouteFromUrl();
         if (route && route.extensionId === this.licensesManagementConstants.ADMINISTRATION_LICENSES_VIEWS_EXTENSION_ID) {
            if (route.tabPaths && route.tabPaths.length) {
               const tabPaths: string[] = route.tabPaths.split(this.TAB_IDS_DELIMITER);
               if (tabPaths[0] === tabId) {
                  return;
               }
            } else if (!tabId || !tabId.length) {
               // If the given tab id is empty then it must be navigated to the default view.
               // The route extracted from the URL indicates that it is already navigated to the default view,
               // so do nothing
               return;
            }
         }

         const tabPaths: string[] = tabId ? [tabId] : [];
         const licensingRouteObject: LicensingRouteObject = {
            extensionId: this.licensesManagementConstants.ADMINISTRATION_LICENSES_VIEWS_EXTENSION_ID,
            tabPaths: tabPaths
         };

         this.navigateByProvidedLicensingRouteObject(licensingRouteObject);
      }

      extractLicensingRouteObjectFromUrl(rootTabPathIndex?: number): LicensingRouteObject | null {
         const route: any = this.navigation.getRouteFromUrl();
         if (!route || !route.extensionId) {
            return null;
         }

         const licensingRouteObject: LicensingRouteObject = {
            extensionId: route.extensionId,
            tabPaths: []
         };

         if (route.tabPaths && route.tabPaths.length) {
            licensingRouteObject.tabPaths = route.tabPaths
                  .split(this.TAB_IDS_DELIMITER);

            if (rootTabPathIndex) {
               const removeCount: number = isNaN(rootTabPathIndex) ? 0 : rootTabPathIndex;
               licensingRouteObject.tabPaths.splice(0, removeCount);
            }
         }

         if (route.itemId) {
            licensingRouteObject.itemId = route.itemId;
         }

         return licensingRouteObject;
      }

      /**
       * Check if the given tabPaths correspond to a view which can handle the navigation request represented by the licensing route object
       *
       * @param tabPaths - The tabPaths against which the navigation request has to be tested
       * @param licensingRouteObject - Represents the location it is tried to be navigated to.
       * @returns {boolean} True if the tabPaths represents a view which can process the navigation request
       *       in the form of licensing route object. False otherwise.
       */
      canNavigationRequestInLicensingRouteBeHandled(tabPaths: string[], licensingRouteObject: LicensingRouteObject): boolean {
         if (!licensingRouteObject || !licensingRouteObject.tabPaths || !tabPaths) {
            return false;
         }

         // If the given tab ids are more than the tab ids in the licensing route object then the navigation request can't be handled.
         if (tabPaths.length > licensingRouteObject.tabPaths.length) {
            return false;
         }

         if (tabPaths.length === 0 && licensingRouteObject.tabPaths.length > 0) {
            return false;
         }

         for (let index = 0; index < tabPaths.length; index++) {
            if (tabPaths[index] !== licensingRouteObject.tabPaths[index]) {
               return false;
            }
         }

         return true;
      }

      /**
       * Create a licensing route object which to include only the tab ids coming after the tab ids given with tabPaths parameter.
       *
       * @param tabPaths The tabPaths which have to be used when determining which are the sub tabs.
       * @param licensingRouteObject The licensing route object from which the new route object with the sub tabs, has to be created.
       * @returns {any} New licensing route object only with the sub tabs.
       */
      createLicensingRouteObjectForSubTabs(tabPaths: string[], licensingRouteObject: LicensingRouteObject): LicensingRouteObject | null {
         if (!tabPaths || !tabPaths.length
               || !licensingRouteObject
               || !licensingRouteObject.tabPaths
               || !licensingRouteObject.tabPaths.length) {
            return null;
         }

         if (tabPaths.length > licensingRouteObject.tabPaths.length) {
            return null;
         }

         const subtabPaths: string[] = [];
         for (let index = 0; index < licensingRouteObject.tabPaths.length; index++) {
            if (index < tabPaths.length && licensingRouteObject.tabPaths[index] !== tabPaths[index]) {
               return null;
            }

            if (index >= tabPaths.length) {
               subtabPaths.push(licensingRouteObject.tabPaths[index]);
            }
         }

         return {
            extensionId: licensingRouteObject.extensionId,
            tabPaths: subtabPaths,
            itemId: licensingRouteObject.itemId
         };
      }

      emitLicensesManagementNavigationRequestOnScope($scope: any, licensingRouteObject: LicensingRouteObject): void {
         if (!licensingRouteObject) {
            return;
         }

         $scope.$emit(this.licensesManagementConstants.LICENSES_MANAGEMENT_NAVIGATION_REQUEST_EVENT, licensingRouteObject);
      }

      emitAssetsManagementNavigationPerformedOnScope($scope: any, licensingRouteObject: LicensingRouteObject): void {
         if (!licensingRouteObject) {
            return;
         }

         $scope.$emit(this.licensesManagementConstants.ASSETS_MANAGEMENT_NAVIGATION_PERFORMED_EVENT, licensingRouteObject);
      }

      registerNavigationPerformedEventListener($scope: any, handleNavigationRequestCallback: Function): void {
         $scope.$on(this.licensesManagementConstants.LICENSES_MANAGEMENT_NAVIGATION_PERFORMED_EVENT,
               (event: any, data: LicensingRouteObject) => {
                  handleNavigationRequestCallback(data);
               }
         );
      }

      registerAssetsNavigationPerformedEventListener($scope: any, handleNavigationRequestCallback: Function): void {
         $scope.$on(this.licensesManagementConstants.ASSETS_MANAGEMENT_NAVIGATION_PERFORMED_EVENT,
               (event: any, data: LicensingRouteObject) => {
                  handleNavigationRequestCallback(data);
               }
         );
      }

      extractAdditionalParametersFromRoute(): any {
         const additionalParameters: any = {};
         const route: any = this.navigation.getRouteFromUrl();
         const PARAMS = ['itemId', 'tabPaths'];
         const additionalRootParameters = route.getAdditionalParams();
         for (let key in additionalRootParameters) {
            if (PARAMS.indexOf(key) === -1 && !angular.isFunction(additionalRootParameters[key])) {
               additionalParameters[key] = additionalRootParameters[key];
            }
         }

         return additionalParameters;
      }

      private appendAdditionalParametersToRoutingInfo(routingInfo: any): void {
         const route: any = this.navigation.getRouteFromUrl();
         const PARAMS = ['itemId', 'tabPaths'];
         const additionalParameters = route.getAdditionalParams();
         for (let key in additionalParameters) {
            if (PARAMS.indexOf(key) === -1 && !angular.isFunction(additionalParameters[key] && routingInfo[key])) {
               routingInfo[key] = additionalParameters[key];
            }
         }
      }
   }

   angular.module('com.vmware.vsphere.client.licenseUi').service(
         'licensesManagementNavigationService',
         LicensesManagementNavigationService
   );
}
