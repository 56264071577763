module cluster_ui {

   import IComponentController = angular.IComponentController;

   class HaAdvancedRuntimeInfoController implements IComponentController {

      public static $inject = ["$scope", "dataService", "haSummaryService", "$q"];

      public portletData: any = [];
      private loadingData: boolean = false;
      private objectId: string;


      constructor(private $scope: any, private dataService: any,
                  private haSummaryService: any,
                  private $q: any) {
         this.objectId = this.$scope._route.objectId;
         this.portletData = [];

         this.$scope.$on("haSummaryPortletsRefresh", () => {
            this.getData();
         });

         this.getData();
      }

      public getData(): void {
         if (this.loadingData) {
            return;
         }

         this.loadingData = true;

         return this.dataService.getProperties(this.objectId, ["dasAdvancedRuntimeInfo"])
               .then((data: any) => {
                  if (data && data.dasAdvancedRuntimeInfo) {
                     this.portletData =
                           this.haSummaryService.getFailoverLevelAdvancedRuntimeData(
                                 data.dasAdvancedRuntimeInfo);
                  }
                  this.loadingData = false;
               })
               .catch((error: any) => {
                  this.loadingData = false;
                  this.$q.reject(error);
               });
      }
   }

   angular.module("com.vmware.vsphere.client.cluster")
         .controller("HaAdvancedRuntimeInfoController",
               HaAdvancedRuntimeInfoController);
}
