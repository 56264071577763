angular.module('com.vmware.vsphere.client.vm').service('vmWizardService', [
   function () {
      return {
         hideErrors: hideErrors
      };

      function hideErrors(wizardConfig) {
         wizardConfig.validationBanner.messages = [];
      }
   }]);
