(function() {
   angular.module('com.vmware.vsphere.client.vm').controller('manageSnapshotsController', manageSnapshotsController);

   manageSnapshotsController.$inject = ['snapshotService', '$scope', 'snapshotTreeService', 'defaultUriSchemeUtil',
      'vcH5ConstantsService', 'i18nService', 'clarityModalService'];

   function manageSnapshotsController(snapshotService, $scope, snapshotTreeService, defaultUriSchemeUtil, vcH5ConstantsService, i18nService, clarityModalService) {
      var self = this;
      var description;
      var label;
      self.updateSnapshotTreeDebounced = _.debounce(updateSnapshotTree, 300);
      self.vmId = $scope.modalOptions.availableTargets[0];
      self.liveRefreshProperties = ["config.hardware.device", "snapshot.rootSnapshotList",
         "snapshot.currentSnapshot", "rootSnapshot", "snapshot"];
      self.isEditing = false;

      $scope.$on(vcH5ConstantsService.MODEL_CHANGED_EVENT, onModelChanged);

      function buildAlertOptions(options) {
         var baseOptions = {
            title: options.title,
            message: options.message || '',
            submit: options.submit
         };

         return baseOptions;
      }

      function onModelChanged(event, objChangeInfo) {
         if (objChangeInfo.objectId !== self.vmId) {
            return;
         }
         if (objChangeInfo.operationType === 'CHANGE') {
            self.updateSnapshotTreeDebounced();
         } else if (objChangeInfo.operationType === 'DELETE') {
            $scope.closeModal();
         }
      }

      function updateSnapshotTree() {
         snapshotService.getSnapshotsForVM(self.vmId).then(function(response) {
            var treeViewOptions = snapshotTreeService.formatData(response);
            self.treeViewOptions = treeViewOptions;
            self.hasSnapshots = hasSnapshots(treeViewOptions);
            self.setAsSelected(snapshotTreeService.getCurrentSnapshot());
            treeViewOptions.onClick = function(treeItem) {
               self.setAsSelected(treeItem);
            };
            getPrivileges();
            getCapabilities();
         });
      }

      function hasSnapshots(treeViewOptions) {
         if(angular.isArray(treeViewOptions.data) && treeViewOptions.data.length > 0){
            if(angular.isArray(treeViewOptions.data[0].items) && treeViewOptions.data[0].items.length > 0){
               return treeViewOptions.data[0].items[0].iconClass !== snapshotTreeService.icons.youAreHere;
            }
         }

         return false;
      }

      function updateDiskUsageLabel(iconClass) {
         var isRootSnapshot = iconClass === snapshotTreeService.icons.vm;
         var diskUsageLabelKey = isRootSnapshot ? 'SnapshotManagerView.FullDiskUsage' : 'SnapshotManagerView.DiskUsage';
         self.diskUsageLabel = i18nService.getString('vmuiSnapshot', diskUsageLabelKey);
      }

      updateSnapshotTree();

      this.setAsSelected = function(treeItem) {
         updateDiskUsageLabel(treeItem.iconClass);
         self.selectedSnapshot = treeItem;
         self.isSnapshotSelected = Boolean(treeItem.metadata && treeItem.metadata.snapshotMOR);
         self.isRootSelected = treeItem.iconClass === snapshotTreeService.icons.vm;
         $scope.revertAlertOptions.message = i18nService.getString('vmuiSnapshot', 'SnapshotActionCommand.RevertToSnapshot', treeItem.label);
         self.isSelectedItemEditable = !self.isRootSelected && treeItem.iconClass !== snapshotTreeService.icons.youAreHere;
         $scope.removeAlertOptions.message = i18nService.getString('vmuiSnapshot', 'SnapshotActionCommand.Delete.Message', treeItem.label);
         description = treeItem.metadata.description;
         label = treeItem.label;
         self.isEditing = false;
      };

      function getSnapshotId() {
         var snapshotMOR = self.selectedSnapshot.metadata.snapshotMOR;
         if (snapshotMOR) {
            return defaultUriSchemeUtil.getVsphereObjectId(snapshotMOR);
         }
      }

      //// Revert to Snapshot

      $scope.revertAlertOptions = buildAlertOptions({
         title: i18nService.getString('vmuiSnapshot', 'SnapshotActionCommand.ConfirmRevert'),
         submit: onRevertToConfirmation
      });

      this.onRevertToButtonClicked = function() {
         clarityModalService.openConfirmationModal($scope.revertAlertOptions);
      };

      function onRevertToConfirmation() {
         snapshotService.revertToSnapshot(self.selectedSnapshot.metadata.snapshotMOR);
      }

      //// Remove selected snapshot

      $scope.removeAlertOptions = buildAlertOptions({
         title: i18nService.getString('vmuiSnapshot', 'SnapshotActionCommand.ConfirmDelete'),
         submit: onRemoveSelectedSnapshotConfirmation
      });

      this.onRemoveSelectedSnapshotButtonClicked = function() {
         clarityModalService.openConfirmationModal($scope.removeAlertOptions);
      };

      function onRemoveSelectedSnapshotConfirmation() {
         var snapshotId = getSnapshotId();
         if (snapshotId) {
            snapshotService.removeSnapshot(snapshotId);
         }
      }

      //// Remove All Snapshots

      this.onRemoveAllSnapshotsButtonClicked = function() {
         snapshotService.openRemoveAllSnapshotsConfirmationDialog(self.vmId);
      };


      //// Editing snapshot details

      this.onEditSelectedSnapshotButtonClicked = function() {
         description = self.selectedSnapshot.metadata.description;
         label = self.selectedSnapshot.label;
         self.isEditing = true;
      };

      this.onConfirmEditButtonClicked = function() {
         self.isEditing = false;
         snapshotService.updateSnapshot(
            self.vmId,
            getSnapshotId(),
            self.selectedSnapshot.label,
            self.selectedSnapshot.metadata.description
         );
      };

      this.onCancelEditButtonClicked = function() {
         self.selectedSnapshot.label = label;
         self.selectedSnapshot.metadata.description = description;
         self.isEditing = false;
      };

      this.isEditDisabled = function() {
         return !(self.isSelectedItemEditable && self.hasEditPrivilege && self.snapshotConfigSupported);
      };

       this.isRevertDisabled = function() {
           return !(self.isSnapshotSelected && self.hasRevertPrivilege && self.revertToSnapshotSupported);
       };

      function getPrivileges() {
           snapshotService.getPrivilegesForVM(self.vmId).then(function(response) {
               self.hasDeletePrivilege = response[0];
               self.hasRevertPrivilege = response[1];
               self.hasEditPrivilege = response[2];
           });

      }

      function getCapabilities() {
           snapshotService.getCapabilitiesForVM(self.vmId).then(function(response) {
               self.snapshotConfigSupported = response.capability.snapshotConfigSupported;
               self.revertToSnapshotSupported = response.capability.revertToSnapshotSupported;
           });
      }
   }
})();
