/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace dvs_ui {

   import IRootScopeService = angular.IRootScopeService;
   import LacpApiVersion = com.vmware.vim.binding.vim.dvs.VmwareDistributedVirtualSwitch$LacpApiVersion;
   import DvsUpgradeWizardSpec = com.vmware.vsphere.client.h5.network.dvs.upgrade.model.DvsUpgradeWizardSpec;
   import DvsResControlUpgradeStatistics = com.vmware.vsphere.client.dvs.api.spec.DvsResControlUpgradeStatistics;

   /**
    * Provides methods from creating the mutation spec sent to the backend
    * after the completion of the Upgrade Distributed Switch wizard.
    */
   export class DvsUpgradeWizardSpecFactory {

      public static $inject = [
            "defaultUriSchemeUtil",
            "dvsVersionPageConstants",
            "dvsUpgradeFeaturesService"];

      constructor(private defaultUriSchemeUtil: any,
                  private dvsVersionPageConstants: any,
                  private featureService: any) {
      }

      /**
       * Builds the spec the Upgrade Distributed Switch mutation operation.
       */
      public buildDvsUpgradeSpec(
            model: DvsUpgradeWizardModel): DvsUpgradeWizardSpec {

         let spec: DvsUpgradeWizardSpec = new DvsUpgradeWizardSpec();
         spec.productSpec = _.find(model.supportedVersionSpecs, (versionSpec: any) => {
            return versionSpec.version === model.selectedVersion;
         });

         spec.implicitNiocUpgrade = this.featureService.isImplicitNiocUpgradeSupported(
               model.selectedVersion,
               model.currentNiocVersion,
               model.niocUpgradeStatistics);
         spec.explicitNiocUpgrade = model.isExplicitNiocUpgradeSupported &&
               model.isExplicitNiocUpgradeEnabled;

         spec.implicitLacpUpgrade = this.featureService.isImplicitLacpUpgradeSupported(
               model.selectedVersion,
               model.isLacpConfigured,
               model.currentLacpVersion);
         spec.explicitLacpUpgrade = model.isExplicitLacpUpgradeSupported &&
               model.isExplicitLacpUpgradeEnabled;

         spec.migrateShares = model.migrateShares;
         spec.lag = model.lag;
         spec.isMultiLagLacpSupported =
               this.featureService.isMultiLagLacpSupported(model.selectedVersion);

         return spec;
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .service("dvsUpgradeWizardSpecFactory", DvsUpgradeWizardSpecFactory);
}
