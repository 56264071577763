/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace dvs_ui
{
   import DvsPortMirroringDestinationPorts =
         com.vmware.vsphere.client.h5.network.dvs.portmirroring.model.DvsPortMirroringDestinationPorts;
   import IPromise = angular.IPromise;

   export class DvsPortMirroringDestinationsViewComponent {
      public bindings:any;
      public controller:any;
      public templateUrl:string;

      constructor() {
         this.bindings = {
            selectedSession: "<",
            shouldRefresh: "<",
            onRefresh: "<"
         };
         this.controller = DvsPortMirroringDestinationsViewController;
         this.templateUrl = "dvs-ui/resources/dvs/portmirroring/" +
         "dvsPortMirroringSourcesDestViewTemplate.html";
      }
   }

   class DvsPortMirroringDestinationsViewController {

      static $inject:any = [
         "i18nService",
         "$rootScope",
         "$scope",
         "vuiConstants",
         "columnRenderersRegistry",
         "networkUtil",
         "dvsPortMirroringSourcesAndDestRefreshService"
      ];

      public selectedSession:string;

      public shouldRefresh: boolean;

      public onRefresh: Function;

      public datagridOptions: any;

      private tableEntries: any;

      public liveRefreshProperties: string[];

      public dSwitchUri :string;

      constructor(private i18nService: any,
                  private $rootScope: any,
                  private $scope: any,
                  private vuiConstants: any,
                  private columnRenderersRegistry: any,
                  private networkUtil: any,
                  private dvsPortMirroringSourcesAndDestRefreshService: any) {

         this.createDatagridOptions();
      }

      public $onInit():void {
         this.datagridOptions.data = this.tableEntries;
         this.createDatagridOptions();
      }

      public $onChanges():void {
         if (this.shouldRefresh) {
            this.dvsPortMirroringSourcesAndDestRefreshService
                  .refreshDvsPortMirroringDestinationView(
                        this.$scope, this.$rootScope,
                        this.selectedSession, this.fillTableEntries.bind(this));
            this.dSwitchUri = this.$rootScope._route.objectId;
            this.liveRefreshProperties = [
               "config.vspanSession"
            ];
         }
      }

      public fillTableEntries(detailsData:DvsPortMirroringDestinationPorts) {
         if (detailsData) {
            this.tableEntries = detailsData.destinationPortData ?
                  detailsData.destinationPortData : [];
            this.sortEntities();
         } else {
            this.datagridOptions.data = [];
         }
         this.$scope.portMirrorLoading = false;
         this.onRefresh();
      }

      private sortEntities() {
         let sortedEntries:any = [];
         if (this.tableEntries) {
            sortedEntries = this.tableEntries.sort(
                  (item1:any, item2:any):any => {
                     return this.networkUtil.compareNumericAndNonNumericValues(
                           item1, item2, "portKey");
                  }
            );
         }
         this.datagridOptions.data = sortedEntries;
      }

      private createDatagridOptions():void {
         this.datagridOptions = {
            columnDefs: this.createColumnDefinitions(),
            sortMode: this.vuiConstants.grid.sortMode.SINGLE,
            selectionMode: this.vuiConstants.grid.selectionMode.SINGLE,
            selectedItems: [],
            data: this.tableEntries,
            resizable: true,
            height: "100%",
            pageConfig: {
               hidePager: true
            }
         };
      }

      private createColumnDefinitions():any {

         let columnRenderer:any = this.columnRenderersRegistry
               .getColumnRenderer("object-link");

         return [
            {
               displayName: this.i18nService.getString(
                     "DvsUi", "SessionDvPortSourceList.port"),
               field: "portKey",
               width: "150px"
            },
            {
               displayName: this.i18nService.getString(
                     "DvsUi", "SessionDvPortSourceList.host"),
               field: "hostProxyName",
               width: "150px",
               template: (data:any):any => {
                  return columnRenderer([
                     undefined, "hostProxyName",
                     data.hostIcon
                  ], data);
               }
            },
            {
               displayName: this.i18nService.getString(
                     "DvsUi", "SessionDvPortSourceList.connectee"),
               field: "connectedEntityName",
               width: "150px",
               template: (data:any):any => {
                  return columnRenderer([
                     undefined, "connectedEntityName",
                     data.connecteeIconId
                  ], data);
               }
            }
         ];
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .component("dvsPortMirroringDestinationsView",
         new DvsPortMirroringDestinationsViewComponent());
}
