module hostprofile_ui {

   import IQService = angular.IQService;
   import ReadHostCustomizationsSpec = com.vmware.vsphere.client.hostprofile.data.h5.ReadHostCustomizationsSpec;
   import ManagedObjectReference = com.vmware.vim.binding.vmodl.ManagedObjectReference;

   class HostHostProfileSettingsController implements angular.IController {

      public static $inject = [
         "$scope",
         "hostHostProfileSettingsService",
         "i18nService",
         "$q",
         "$element",
         "dataService",
         "hostCustomizationsService",
         "defaultUriSchemeUtil",
         "vcService",
         "timeFormatterService",
         "vuiConstants",
         "localStorageService"];
      public readonly REMEDIATION_RESULT_KEY: string = "remediationTaskErrorResult_";

      isComplianceExpanded: boolean = false;
      private objectId: string;
      private objectsToWatch: Array<string>;
      private headerOptions: HeaderOptions;
      private customizationsHeaderOptions: HeaderOptions;
      private hostProfileNameHeaderText: string;
      private loadingCustomizationsData: boolean;
      private hostProfile: any;
      private datagridOptions: any;
      private complianceStatus: any = {};
      private showComplianceFailures: boolean;
      private readonly complianceFailureTitle: string;
      private readonly hostCustomizationsTitle: string;
      private attachDetachViewHeaderAccessor: any = {};
      private showLastCheckedTime: boolean;
      private liveRefreshProperties: Array<string>;
      private is65VcOrLater: boolean;
      private is66VcOrLater: boolean;
      private complianceStatusFormatted: any;
      private userTimeFormat: any;
      private hostProfileId: any;
      private remediationErrorBannerVisible: boolean = false;
      private splitterOptions: Object = {
         panes: [{
            min: "200px",
            size: "50%"
         }, {
            min: "200px",
            size: "50%"
         }],
         orientation: this.vuiConstants.splitter.orientation.VERTICAL
      };


      constructor(private $scope: ScopeRoute,
                  private hostProfileSettingsService: HostHostProfileSettingsService,
                  private i18nService: any,
                  private $q: IQService,
                  private $element: any,
                  private dataService: any,
                  private hostCustomizationsService: HostCustomizationsService,
                  private defaultUriSchemeUtil: any,
                  private vcService: any,
                  private timeFormatterService: any,
                  private vuiConstants: any,
                  private localStorageService: any) {
         this.loadingCustomizationsData = false;
         this.objectId = this.$scope._route.objectId;
         this.objectsToWatch = [this.objectId];
         this.liveRefreshProperties = ["configStatus", "complianceStatus",
            "entity", "remediationState", "complianceCheckState",
            "answerFileValidationResult", "answerFileValidationState"];

         this.headerOptions = this.hostProfileSettingsService.getHeaderOptions(this.objectId);
         this.customizationsHeaderOptions = this.hostProfileSettingsService.getCustomizationHeaderOptions(this.objectId);
         this.hostProfileNameHeaderText = this.i18nService.getString("HostUi", "hostProfileNameHeaderText");
         this.datagridOptions = this.hostProfileSettingsService.getDatagridOptions();

         this.hostCustomizationsTitle = this.i18nService.getString(
               "HostProfileUi",
               "host.manage.settings.hostProfile.hostCustomization"
         );
         this.complianceFailureTitle = this.i18nService.getString(
               "HostProfileUi",
               "HostCompliance.treeView.title"
         );

         vcService.is65VcOrLater(this.objectId).then((is65VcOrLater: boolean) => {
            this.is65VcOrLater = is65VcOrLater;
         });


         vcService.is66VcOrLater(this.objectId).then((is66VcOrLater: boolean) => {
            this.is66VcOrLater = is66VcOrLater;
            this.getUserTimeFormatAndSetData();
         });

         this.$scope.$on("hostProfileRemediationErrorsEvent", (event: any, showBanner: boolean) => {
            this.remediationErrorBannerVisible = showBanner;
         });

      };

      onComplianceExpanded(): void {
         this.isComplianceExpanded = true;
         let compliancePanel = this.getCompliancePanel();
         let splitter = this.getSplitter();
         splitter.min(compliancePanel, "400px");
         if (compliancePanel.height() < 400) {
            splitter.size(compliancePanel, "400px");
         }
      }

      onComplianceCollapsed(subractHeight: boolean): void {
         this.isComplianceExpanded = false;
         let compliancePanel = this.getCompliancePanel();
         let splitter = this.getSplitter();
         if (splitter) {
            splitter.min(compliancePanel, "200px");
            let complianceGrid = compliancePanel.find(
                  "host-profile-host-settings-details");
            let compliancePanelSize = Math.max(200,
                  compliancePanel.height() - complianceGrid.height());
            splitter.size(compliancePanel, compliancePanelSize + "px");
         }
      }

      getSplitterHolderClass(): string {
         return this.isComplianceExpanded ?
               "splitter-holder-compliance-expanded" :
               "splitter-holder-compliance-collapsed";
      }

      // show the remediation error banner if there are remediation errors persisted in the local storage
      public showRemediationErrorBanner(event: boolean): void {
         if (event === true && !this.remediationErrorBannerVisible) {
            this.remediationErrorBannerVisible = event;
         }
      }

      // hide the remediation error banner if there are no remediation errors persisted in the local storage
      public hideRemediationErrorBanner(event: boolean): void {
         if (event === false && this.remediationErrorBannerVisible) {
            this.remediationErrorBannerVisible = event;
         }
      }

      private getCompliancePanel(): any {
         return this.$element.find(
               ".host-profile-host-settings-section");
      }

      private getSplitter(): any {
         return this.$element.find("[kendo-splitter]")
               .data("kendoSplitter");
      }

      private getData() {
         this.hostProfileSettingsService
               .getHostProfileSummary(this.objectId, this.is66VcOrLater)
               .then((profileSummary: any) => this.setSummaryData(profileSummary))
               .then((hostProfileSummary: any) => this.hostProfileSettingsService.formatHostProfile(hostProfileSummary))
               .then((hostProfile: any) => this.setHostProfile(hostProfile))
               .then((hostProfile: any) => {
                  this.objectsToWatch = [this.objectId];
                  let hostProfileId: string =
                        this.hostProfile ? this.defaultUriSchemeUtil.getVsphereObjectId(this.hostProfile.profile) : null;
                  this.hostProfileId = hostProfileId;
                  // added hostProfileId in objectsToWatch for 6.0 VCs because we don't get updates on the host when profile is detached
                  // for VCs 6.5 and later that would lead to constant live refreshes thus the version check
                  if (this.hostProfile && !this.is65VcOrLater) {
                     this.objectsToWatch.push(hostProfileId);
                  }
                  this.getHostCustomizationsData(hostProfile);
               });
      }

      private setSummaryData(profileSummary: any) {

         let associatedHostProfileSummaryPromise: any;
         if (profileSummary && profileSummary.associatedHostProfileSummary) {
            const complianceData: any =
                  this.hostProfileSettingsService.extractComplianceDataFromProfileSummary(profileSummary, this.userTimeFormat);

            this.complianceStatus = complianceData.complianceStatus;

            this.complianceStatusFormatted = complianceData.complianceStatusCheckTimeFormatted;

            this.showComplianceFailures = complianceData.showComplianceFailures;

            this.showLastCheckedTime = complianceData.showLastCheckedTime;

            associatedHostProfileSummaryPromise = this.$q.when(profileSummary.associatedHostProfileSummary);

            return associatedHostProfileSummaryPromise;
         }
      }

      private setHostProfile(hostProfile: any) {
         if(!_.isUndefined(this.hostProfile) && !_.isEqual(this.hostProfile, hostProfile ) ) {
            this.remediationErrorBannerVisible = false;
            this.clearRemediationErrorsLocalStorage();

         }
         this.hostProfile = hostProfile;
         return this.$q.when(hostProfile);
      }

      private clearRemediationErrorsLocalStorage(): void {
         const key: string = this.REMEDIATION_RESULT_KEY + this.objectId;
         this.localStorageService.getUserData(key)
            .then((remediationErrors: any) => {
               if (remediationErrors) {
                  this.localStorageService.removeUserData(key);
               }
            });
      }

      private populateDatagird(hostCustomizations: any) {
         this.datagridOptions.data = hostCustomizations;
      }

      private getHostCustomizationsData(hostProfile: any) {
         if (hostProfile && hostProfile.profile) {
            this.loadingCustomizationsData = true;

            let spec = new ReadHostCustomizationsSpec();
            spec.hostProfile = <ManagedObjectReference> hostProfile.profile;
            spec.isNewlyAssociatedProfile = false;

            this.hostCustomizationsService
                  .getHostCustomizations(this.objectId, spec)
                  .then((hostCustomizationsData) => {
                     if (hostCustomizationsData && hostCustomizationsData.length > 0) {
                        let hostCustomizations = hostCustomizationsData[0].result;
                        if (!hostCustomizations) {
                           hostCustomizations = [];
                        }
                        this.populateDatagird(hostCustomizations);
                     }
                     this.loadingCustomizationsData = false;
                  });
         }
      }

      private refreshContent() {
         this.getUserTimeFormatAndSetData();
         if (this.attachDetachViewHeaderAccessor &&
               this.attachDetachViewHeaderAccessor.refresh) {
            this.attachDetachViewHeaderAccessor.refresh();
         }
      }

      private getUserTimeFormatAndSetData(): void {
         this.timeFormatterService.getUserTimeFormatPreference()
               .then((response: any) => {
                  this.userTimeFormat = response;
                  this.getData();
               });
      }
   }

   angular.module("com.vmware.vsphere.client.hostprofile")
         .controller("HostHostProfileSettingsController", HostHostProfileSettingsController);
}
