/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
(function () {
   'use strict';

   angular.module('com.vmware.vsphere.client.storage').directive('configDeviceList', configDeviceList);

   configDeviceList.$inject = ['i18nService', 'storageUtil', 'columnRenderersRegistry',
      'vuiConstants', 'listViewColumnService', 'datagridActionBarService',
      'hostStorageConstants', 'defaultUriSchemeUtil', 'kendoGridService'];

   function configDeviceList (
         i18nService, storageUtil, columnRenderersRegistry,
         vuiConstants, listViewColumnService, datagridActionBarService,
         hostStorageConstants, defaultUriSchemeUtil, kendoGridService
   ) {

      var directive = {
         replace: true,
         scope: {
            devices: '=', // Array of storage devices to be shown.
            hasActionBar: '=?', //Optional. If true - an action bar will be shown.
            hostId: '=?', //Optional. Used only if 'hasActionBar' is true.
            selectedDevices: '=?',  // Optional devices to be selected initially.
            showAdapterColumn: '=?', // If true, the Adapter column is shown.
            viewId: '=' // Needed for persisting visible grid columns in the user session.
         },
         templateUrl: 'storage-ui/resources/storage/directives/configDeviceList/configDeviceList.html',
         link: link
      };

      return directive;

      function link (scope) {

         var showAdapterColumn = !!scope.showAdapterColumn;

         var disabledTextRenderer = columnRenderersRegistry.getColumnRenderer('disabled-text');

         var self = scope;
         var columnsChangeHandler = listViewColumnService.getColumnChangeHandler(scope.viewId, scope);
         listViewColumnService.applyPersistedState(getColumnDefinitions(showAdapterColumn), scope.viewId)
               .then(function (columnDefs) {
                  self.datagridOptions = {
                     height: '100%',
                     columnDefs: columnDefs,
                     sortMode: vuiConstants.grid.sortMode.SINGLE,
                     selectionMode: vuiConstants.grid.selectionMode.MULTI,
                     showCheckboxesOnMultiSelection: false,
                     data: [],
                     selectedItems: [],
                     resizable: true,
                     vuiFooter: {
                        actions: [{
                           disabled: false,
                           label: i18nService.getString('StorageUi', 'action.copyAll'),
                           tooltip: i18nService.getString('StorageUi', 'action.copyAll'),
                           icon: 'vui-icon-action-copy',
                           action: function() {
                              kendoGridService.copyAllItems('#configStorageDevicesGrid [kendo-grid]');
                           }
                        }]
                     },
                     columnMenu: {
                        sortable: false, // this will hide sort menu items
                        messages: {
                           columns: i18nService.getString('CommonUi', 'listview.showColumnsMenu'),
                           filter: i18nService.getString('CommonUi', 'listview.filterMenu')
                        }
                     },
                     columnHide: columnsChangeHandler,
                     columnReorder: columnsChangeHandler,
                     columnResize: columnsChangeHandler,
                     columnShow: columnsChangeHandler,
                     actionBarOptions: {
                        actions: []
                     }
                  };

                  updateActionBar();

                  function isActionAvailable (operationalState) {
                     return scope.datagridOptions.selectedItems.length === 1
                           && scope.datagridOptions.selectedItems[0].operationalState === operationalState;
                  }

                  function updateActionBar () {
                     if (!!scope.hasActionBar) {

                        datagridActionBarService.updateActionBar(
                              scope.datagridOptions,
                              scope.hostId, [{
                                 actionId: 'vsphere.core.host.actions.refreshStorageSystem'
                              }, vuiConstants.actions.SEPARATOR, {
                                 actionId: 'vsphere.core.host.actions.storageDevice.mount',
                                 isActionAvailable: function (actionDef) {
                                    return actionDef.available
                                          && isActionAvailable(hostStorageConstants.operationalState.OFF);
                                 },

                                 getActionInvocationContext: function () {
                                    return scope.datagridOptions.selectedItems[0];
                                 }
                              }, {
                                 actionId: 'vsphere.core.host.actions.storageDevice.unmount',
                                 isActionAvailable: function (actionDef) {
                                    return actionDef.available
                                          && isActionAvailable(hostStorageConstants.operationalState.OK);
                                 },

                                 getActionInvocationContext: function () {
                                    return scope.datagridOptions.selectedItems[0];
                                 }
                              }, {
                                 actionId: 'vsphere.core.host.actions.storageDevice.rename',
                                 isActionAvailable: function (actionDef) {
                                    return actionDef.available
                                          && scope.datagridOptions.selectedItems.length === 1
                                          && scope.datagridOptions.selectedItems[0].isRenameSupported === true;
                                 },

                                 getActionInvocationContext: function () {
                                    return scope.datagridOptions.selectedItems[0];
                                 }
                              }, {
                                 actionId: 'vsphere.core.host.actions.storageDevice.turnLedOn',
                                 isActionVisible: function (actionDef) {
                                    return actionDef.available
                                          && scope.datagridOptions.selectedItems.length
                                          && _.every(scope.datagridOptions.selectedItems,
                                                function (device) {
                                                   return device.type === hostStorageConstants.deviceType.DISK;
                                                });
                                 },
                                 getActionInvocationContext: function () {
                                    return scope.datagridOptions.selectedItems;
                                 }
                              }, {
                                 actionId: 'vsphere.core.host.actions.storageDevice.turnLedOff',
                                 isActionVisible: function (actionDef) {
                                    return actionDef.available
                                          && scope.datagridOptions.selectedItems.length
                                          && _.every(scope.datagridOptions.selectedItems,
                                                function (device) {
                                                   return device.type === hostStorageConstants.deviceType.DISK;
                                                });
                                 },
                                 getActionInvocationContext: function () {
                                    return scope.datagridOptions.selectedItems;
                                 }
                              }, {
                                 actionId: 'vsphere.core.host.actions.storageDevice.erasePartitions',
                                 getActionInvocationContext: function () {
                                    return {
                                       selectedDevices: scope.datagridOptions.selectedItems
                                    };
                                 },
                                 isActionVisible: function (actionDef) {
                                    return actionDef.available
                                          && scope.datagridOptions.selectedItems.length
                                          && _.every(scope.datagridOptions.selectedItems,
                                                function (device) {
                                                   return device.isScsiDisk;
                                                });
                                 }
                              }, {
                                 actionId: 'vsphere.core.storage.devices.tagAsSSD',
                                 isActionVisible: function (actionDef) {
                                    return actionDef.available
                                          && scope.datagridOptions.selectedItems.length > 0
                                          && _.every(scope.datagridOptions.selectedItems,
                                                function (disk) {
                                                   return disk.isScsiDisk && !disk.isFlash;
                                                });
                                 },
                                 getActionInvocationContext: function () {
                                    return scope.datagridOptions.selectedItems;
                                 }
                              }, {
                                 actionId: 'vsphere.core.storage.devices.tagAsHDD',
                                 isActionVisible: function (actionDef) {
                                    return actionDef.available
                                          && scope.datagridOptions.selectedItems.length > 0
                                          && _.every(scope.datagridOptions.selectedItems,
                                                function (disk) {
                                                   return disk.isFlash;
                                                });
                                 },
                                 getActionInvocationContext: function () {
                                    return scope.datagridOptions.selectedItems;
                                 }
                              }, {
                                 actionId: 'vsphere.core.host.actions.storageDevice.tagAsLocal',
                                 isActionVisible: function (actionDef) {
                                    return actionDef.available
                                          && scope.datagridOptions.selectedItems.length > 0
                                          && _.every(scope.datagridOptions.selectedItems,
                                                function (device) {
                                                   return device.isScsiDisk
                                                         && !device.isLocal;
                                                });
                                 },
                                 getActionInvocationContext: function () {
                                    return {
                                       selectedDevices: scope.datagridOptions.selectedItems
                                    };
                                 }
                              }, {
                                 actionId: 'vsphere.core.host.actions.storageDevice.tagAsRemote',
                                 isActionVisible: function (actionDef) {
                                    return actionDef.available
                                          && scope.datagridOptions.selectedItems.length > 0
                                          && _.every(scope.datagridOptions.selectedItems,
                                                function (device) {
                                                   return device.isScsiDisk
                                                         && device.isLocal;
                                                });
                                 },
                                 getActionInvocationContext: function () {
                                    return {
                                       selectedDevices: scope.datagridOptions.selectedItems
                                    };
                                 }
                              }

                              ]);
                     }
                  }

                  function updateDeviceItems (devices) {
                     _.forEach(devices, function (device) {
                        device.datastoreName = device.datastoreItem ? device.datastoreItem.name : '';
                     });

                     return devices;
                  }

                  self.$watch(
                     function () {
                        return self.devices;
                     },
                     function (newValue, oldValue) {
                        if (newValue !== oldValue) {
                           self.datagridOptions.data = updateDeviceItems(newValue);
                        }
                     });

                  self.datagridOptions.data = updateDeviceItems(self.devices);

                  // Detects selection change in the storage devices list.
                  self.$watch(function () {
                           return self.datagridOptions.selectedItems;
                        },
                        function (selectedItems, oldValue) {
                           if (selectedItems === oldValue || _.isEqual(selectedItems, oldValue)) {
                              return;
                           }
                           updateActionBar();
                           if (selectedItems && selectedItems.length) {
                              scope.selectedDevices = selectedItems;
                           } else {
                              scope.selectedDevices = null;
                           }
                        });
               });

         scope.preselectComparator = function (gridDataItem) {
            if (!scope.selectedDevices || !scope.selectedDevices.length || !gridDataItem) {
               return false;
            }

            return _.find(scope.selectedDevices, function (selectedDevice) {
               return selectedDevice.identifier === gridDataItem.identifier;
            });
         };

         function getColumnDefinitions (showAdapterColumn) {
            var columns = [];

            columns = columns.concat([
               {
                  displayName: i18nService.getString('StorageUi', 'storage.adapters.devices.name'),
                  field: 'name',
                  width: '300px',
                  template: function (dataItem) {
                     return disabledTextRenderer(['name', 'disabled'], dataItem);
                  }
               },
               {
                  displayName: i18nService.getString('StorageUi', 'storage.adapters.devices.identifier'),
                  field: 'identifier',
                  width: '270px',
                  visible: false,
                  template: function (dataItem) {
                     return disabledTextRenderer(['identifier', 'disabled'], dataItem);
                  }
               },
               {
                  displayName: i18nService.getString('StorageUi', 'storage.adapters.devices.lun'),
                  field: 'localizedLunNumber',
                  width: '60px',
                  sortable: function (di1, di2) {
                     return storageUtil.compareNumericValues(di1, di2, 'lunNumber');
                  },
                  template: function (dataItem) {
                     return disabledTextRenderer(['localizedLunNumber', 'disabled'], dataItem);
                  }
               },
               {
                  displayName: i18nService.getString('StorageUi', 'storage.adapters.devices.type'),
                  field: 'type',
                  width: '75px',
                  template: function (dataItem) {
                     return disabledTextRenderer(['type', 'disabled'], dataItem);
                  }
               },
               {
                  displayName: i18nService.getString('StorageUi', 'storage.adapters.devices.capacity'),
                  field: 'localizedCapacity',
                  width: '90px',
                  sortable: function (di1, di2) {
                     return storageUtil.compareNumericValues(di1, di2, 'capacity');
                  },
                  template: function (dataItem) {
                     var className = 'right-align-cell vertical-align-cell';
                     if (dataItem.disabled === true) {
                        className += ' disabled-text';
                     }
                     return '<span class="' + className + '">' + dataItem.localizedCapacity + '</span>';
                  }
               },
               {
                  displayName: i18nService.getString('StorageUi', 'storage.adapters.devices.datastore'),
                  field: 'datastoreName',
                  width: '90px',
                  template: function (dataItem) {
                     if (dataItem.datastoreItem) {
                        var ds = JSON.parse(JSON.stringify(dataItem.datastoreItem));
                        var objectLinkRenderer = columnRenderersRegistry.getColumnRenderer('object-link');
                        var modifiedData = {
                           dsId: defaultUriSchemeUtil.getVsphereObjectId(ds.ref),
                           dsName: ds.name
                        };
                        return objectLinkRenderer(['dsId', 'dsName', 'vsphere-icon-datastore'], modifiedData);
                     }
                     return i18nService.getString('StorageUi', 'storage.adapters.devices.datastore.empty');
                  }
               },
               {
                  displayName: i18nService.getString('StorageUi', 'storage.adapters.devices.opState'),
                  field: 'localizedState',
                  width: '120px',
                  template: function (dataItem) {
                     return disabledTextRenderer(['localizedState', 'disabled'], dataItem);
                  }
               },
               {
                  displayName: i18nService.getString('StorageUi', 'storage.adapters.devices.hardwareAcc'),
                  field: 'hardwareAcceleration',
                  width: '150px',
                  template: function (dataItem) {
                     return disabledTextRenderer(['hardwareAcceleration', 'disabled'], dataItem);
                  }
               },
               {
                  displayName: i18nService.getString('StorageUi', 'storage.adapters.devices.driveType'),
                  field: 'localizedDriveType',
                  width: '90px',
                  template: function (dataItem) {
                     return disabledTextRenderer(['localizedDriveType', 'disabled'], dataItem);
                  }
               }
            ]);

            if (showAdapterColumn) {
               columns = columns.concat([{
                  displayName: i18nService.getString('StorageUi', 'storage.devices.adapterColumn.header'),
                  field: 'adapter',
                  width: '120px',
                  visible: false,
                  template: function (dataItem) {
                     return disabledTextRenderer(['adapter', 'disabled'], dataItem);
                  }
               }]);
            }

            columns = columns.concat([
               {
                  displayName: i18nService.getString('StorageUi', 'storage.adapters.devices.transport'),
                  field: 'transport',
                  width: '120px',
                  template: function (dataItem) {
                     return disabledTextRenderer(['transport', 'disabled'], dataItem);
                  }
               },
               {
                  displayName: i18nService.getString('StorageUi', 'storage.adapters.devices.owner'),
                  field: 'ownerPlugin',
                  width: '90px',
                  visible: false,
                  template: function (dataItem) {
                     return disabledTextRenderer(['ownerPlugin', 'disabled'], dataItem);
                  }
               },
               {
                  displayName: i18nService.getString('StorageUi', 'storage.adapters.devices.physicalLocation'),
                  field: 'physicalLocation',
                  width: '150px',
                  visible: false,
                  template: function (dataItem) {
                     return disabledTextRenderer(['physicalLocation', 'disabled'], dataItem);
                  }
               },
               {
                  displayName: i18nService.getString('StorageUi', 'storage.adapters.devices.sectorFormat'),
                  field: 'sectorFormat',
                  width: '150px',
                  visible: false,
                  template: function (dataItem) {
                     return disabledTextRenderer(['sectorFormat', 'disabled'], dataItem);
                  }
               }
            ]);

            return columns;
         }
      }
   }
})();
