/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace dvs_ui {

   import DvsAddHostHierarchicalListItem = com.vmware.vsphere.client.h5.network.dvs.addhost.hierarchicalList.DvsAddHostHierarchicalListItem;

   export class DvsAddHostTreeListItemWrapper extends DvsAddHostHierarchicalListItem {

      public originalItem: DvsAddHostHierarchicalListItem;
   }
}
