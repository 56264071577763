/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace dvs_ui {

   import IRootScopeService = angular.IRootScopeService;
   import ProductSpec = com.vmware.vim.binding.vim.dvs.ProductSpec;
   import DvsUpgradeWizardSpec = com.vmware.vsphere.client.h5.network.dvs.upgrade.model.DvsUpgradeWizardSpec;

   export class DvsUpgradeWizardService {

      public static $inject = [
            "$rootScope",
            "i18nService",
            "vuiWizardService",
            "mutationService",
            "dataService",
            "dvsUpgradeWizardSpecFactory",
            "dvsUpgradeWizardConstants",
            "dvsUpgradeWizardManager",
            "dvsUpgradeWizardPages",
            "wizardValidationService"
      ];

      constructor(private $rootScope: IRootScopeService,
                  private i18nService: any,
                  private vuiWizardService: any,
                  private mutationService: any,
                  private dataService: any,
                  private specFactory: DvsUpgradeWizardSpecFactory,
                  private wizardConstants: any,
                  private wizardManager: DvsUpgradeWizardManager,
                  private wizardPages: DvsUpgradeWizardPages,
                  private wizardValidationService: any) {
      }

      /**
       * Shows the Upgrade Distributed Switch wizard.
       */
      public show(objectId: string): void {

         let wizardScope: any = this.$rootScope.$new();

         wizardScope.model = new DvsUpgradeWizardModel();
         wizardScope.model.dvsId = objectId;

         wizardScope.wizardConfig = {
            title: this.i18nService.getString("DvsUi", "DvsUpgradeWizard.title"),
            loadingMessage: this.i18nService.getString("CommonUi", "wizard.loading"),
            cssClass: "dvs-upgrade-wizard",
            onFinish: ():boolean => {
               let spec: DvsUpgradeWizardSpec =
                     this.specFactory.buildDvsUpgradeSpec(wizardScope.model);

               this.mutationService.apply(
                     wizardScope.model.dvsId,
                     spec._type,
                     spec);
               return true;
            }
         };

         wizardScope.wizardConfig.pages = this.wizardPages.createPages(wizardScope);

         this.wizardValidationService.initWizardValidation(wizardScope);
         this.wizardManager.init(wizardScope);

         this.requestProperties(wizardScope);

         this.vuiWizardService({
            scope: wizardScope,
            configObjectName: "wizardConfig"
         }).show();

      }

      private requestProperties(wizardScope: any): void {
         wizardScope.wizardConfig.loading = true;

         this.dataService.getProperties(
               wizardScope.model.dvsId, [
                     this.wizardConstants.properties.DVS_UPGRADE_WIZARD_DATA])
               .then((response: any): void => {
                  let upgradeDvsData: any =
                        response[this.wizardConstants.properties.DVS_UPGRADE_WIZARD_DATA];
                  wizardScope.model.dvsName = upgradeDvsData.dvsName;

                  wizardScope.model.supportedVersionSpecs =
                        upgradeDvsData.supportedSwitchSpec;
                  wizardScope.model.currentVersion =
                        upgradeDvsData.currentSwitchSpec.version;

                  let supportedSwitchSpecs: any [] =
                        _.filter(upgradeDvsData.supportedSwitchSpec, (switchSpec: any) => {
                     return switchSpec.version > wizardScope.model.currentVersion;
                  });

                  wizardScope.model.supportedVersions = _.map(supportedSwitchSpecs,
                        (productSpec: any): string => {
                           return productSpec.version;
                        }).sort((versionA, versionB) => {
                     if (versionA < versionB) {
                        return 1;
                     } else if (versionB < versionA) {
                        return -1;
                     } else {
                        return 0;
                     }
                  });

                  if (wizardScope.model.supportedVersions
                        && wizardScope.model.supportedVersions.length > 0) {
                     wizardScope.model.selectedVersion =
                           wizardScope.model.supportedVersions[0];
                  }

                  wizardScope.model.currentNiocVersion =
                        upgradeDvsData.currentNiocVersion;
                  wizardScope.model.niocUpgradeStatistics =
                        upgradeDvsData.niocUpgradeStatistics;
                  wizardScope.model.isLacpConfigured =
                        upgradeDvsData.isLacpConfigured;

                  if (upgradeDvsData.isLacpSupported) {
                     wizardScope.model.currentLacpVersion =
                           upgradeDvsData.currentLacpVersion;
                  }
                  wizardScope.model.niocPrerequisiteChecks =
                        upgradeDvsData.niocPrerequisiteCheckResults;

                  wizardScope.model.lacpPrerequisiteChecks =
                        upgradeDvsData.lacpPrerequisiteCheckResults;
                  wizardScope.model.lag = upgradeDvsData.lagConfig;

                  wizardScope.wizardConfig.title = this.i18nService.getString(
                        "DvsUi", "DvsUpgradeWizard.titleFormat",
                        wizardScope.model.dvsName);
                  wizardScope.wizardConfig.loading = false;
               });
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .service("dvsUpgradeWizardService", DvsUpgradeWizardService);
}
