/*
 *  Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential
 */

(function() {
   /**
    * Directive for VM to VM rule details.
    */
   'use strict';
   angular.module('com.vmware.vsphere.client.cluster').directive(
      'vmDependencyRuleDetails', vmDependencyRuleDetails);

   vmDependencyRuleDetails.$inject = ['$rootScope', 'i18nService', 'clusterGroupsService'];

   function vmDependencyRuleDetails($rootScope, i18nService, clusterGroupsService) {

      return {
         restrict: 'E',
         templateUrl:
            'cluster-ui/resources/cluster/views/settings/rules/vmDependencyRuleDetails.html',
         scope: {
            rule: '='
         },
         controller: ['$scope', function($scope) {
            var objectId = $rootScope._route.objectId;

            $scope.ruleDetailsTitle = i18nService.getString(
               'ClusterUi', 'manage.settings.ruleDetails');
            $scope.dependsOnVmGroup = null;
            $scope.vmGroup = null;

            $scope.$watch('rule', function(rule) {
               if (rule) {
                  clusterGroupsService.getRuleGroupsData(objectId, rule)
                        .then(function(ruleGroups) {
                           $scope.dependsOnVmGroup =
                                 clusterGroupsService.getGroupByName(ruleGroups,
                                       rule.vmDependencyRuleProperties.dependsOnVmGroup);
                           $scope.vmGroup = clusterGroupsService.getGroupByName(
                                 ruleGroups, rule.vmDependencyRuleProperties.vmGroup);
                        });
                  $scope.ruleDetailsLabel = getVmDependencyRuleLabel(rule);
               }
            });

            function getVmDependencyRuleLabel(rule) {
               return i18nService.getString('ClusterUi',
                     'rules.vmRuleDetails.typeDescription.vmDependency',
                     rule.vmDependencyRuleProperties.vmGroup,
                     rule.vmDependencyRuleProperties.dependsOnVmGroup);
            }
         }]
      };
   }
})();
