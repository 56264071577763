module ds_cluster_ui {

   import IComponentController = angular.IComponentController;
   import IPromise = angular.IPromise;

   //TODO move to a common module
   export abstract class WizardPageControllerAbstract implements IComponentController {

      private wizardConfig: any;

      constructor(protected createDsClusterWizardManager: CreateDsClusterWizardManager,
                  private simpleValidationCommit: any) {
         this.wizardConfig = this.createDsClusterWizardManager.getWizardConfig();
      }

      /**
       * Initializes the onCommit function of a specific page.
       */
      protected initPageSubmit(): void {
         let self: WizardPageControllerAbstract = this;
         this.createDsClusterWizardManager.getPageDefinitions()
               [self.getCurrentPageIndex()].onCommit =
               self.simpleValidationCommit(function () {
                  return self.getValidationErrors();
               }, self.wizardConfig);
         }

      /**
       * Updates the state of a specific page of the wizard.
       *
       * @param pageState
       */
      protected setWizardPageState(pageState: string): void {
         this.wizardConfig.pages[this.getCurrentPageIndex()].state = pageState;
      }

      protected abstract getValidationErrors(): IPromise<string[]>;

      protected abstract getCurrentPageIndex(): number;
   }
}
