/** Copyright 2018 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace h5_vm {

   interface ErrorKeys {
      emptySpecName: string;
      tooLongSpecName: string;
      specNameAlreadyExists: string;
   }

   export class GosSpecValidationService {
      private static readonly MAX_NAME_LENGTH: number = 255;

      /**
       * The error keys used for inline validation
       */
      public static readonly SHORT_ERROR_KEYS: ErrorKeys = {
         emptySpecName: "guestOsCustomization.nameAndTargetOsPage.emptySpecNameError",
         tooLongSpecName: "customizationSpec.nameAndOsPage.tooLongSpecNameError",
         specNameAlreadyExists: "customizationSpec.nameAndOsPage.specNameAlreadyExists"
      };

      /**
       * The error keys used for error messages in the banner upon submission
       */
      public static readonly LONG_ERROR_KEYS: ErrorKeys = {
         emptySpecName: "customizationSpec.nameAndOsPage.emptySpecNameError",
         tooLongSpecName: GosSpecValidationService.SHORT_ERROR_KEYS.tooLongSpecName,
         specNameAlreadyExists: GosSpecValidationService.SHORT_ERROR_KEYS.specNameAlreadyExists
      };

      public static $inject = ["i18nService"];

      constructor(private i18nService: any) {
      }

      validateName(specName: string, useLongErrors: boolean = false,
            otherSpecs?: CustomizationSpecInfoCustom[], vcName?: string): string {

         specName = specName.trim();
         const errorKeys: ErrorKeys = useLongErrors
               ? GosSpecValidationService.LONG_ERROR_KEYS
               : GosSpecValidationService.SHORT_ERROR_KEYS;

         if (!specName) {
            return this.i18nService.getString("VmUi", errorKeys.emptySpecName);
         }

         if (this.byteCount(specName) > GosSpecValidationService.MAX_NAME_LENGTH) {
            return this.i18nService.getString("VmUi", errorKeys.tooLongSpecName);
         }

         if (!otherSpecs) {
            return "";
         }

         for (const spec of otherSpecs) {
            if (spec.name === specName && (!vcName || spec.vCenterName === vcName)) {
               return this.i18nService.getString("VmUi",
                     errorKeys.specNameAlreadyExists);
            }
         }

         return "";
      }

      private byteCount(s: string): number {
         return encodeURI(s).split(/%..|./).length - 1;
      }
   }

   angular.module("com.vmware.vsphere.client.vm")
         .service("gosSpecValidationService", GosSpecValidationService);
}
