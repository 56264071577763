/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
(function () {
   'use strict';
   angular
         .module('com.vmware.vsphere.client.dvs')
         .controller('DvPortgroupMiscellaneousPolicyPageController',
               DvPortgroupMiscellaneousPolicyPageController);

   DvPortgroupMiscellaneousPolicyPageController.$inject = ['$scope'];

   function DvPortgroupMiscellaneousPolicyPageController($scope) {

      $scope.manager.commitPageCallbacks.commitMiscellaneousPolicyPage = onCommit;

      function onCommit () {
         $scope.manager.pageDirtyFlags.isMiscellaneousPolicyPageDirty =
               hasInitialMiscDataChanged();
         return true;
      }

      function hasInitialMiscDataChanged() {
         return $scope.initialData.arePortsBlocked !==
               $scope.manager.dvPortgroupData.arePortsBlocked;
      }

      return this;
   }
})();
