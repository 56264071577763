(function () {
   'use strict';
   angular.module('com.vmware.vsphere.client.cluster')
         .controller('FaultsMasterViewController', FaultsMasterViewController);

   FaultsMasterViewController.$inject = ['$scope', 'dataService', 'i18nService',
         'drsFaultsService', 'drsFaultsViewService', 'navigation'];

   function FaultsMasterViewController($scope, dataService, i18nService,
         drsFaultsService, drsFaultsViewService, navigation) {
      var self = this;
      var objectId = navigation.getRoute().objectId;

      self.selectedItem = null;
      self.watchForObjects = [objectId];
      self.liveRefreshProperties = ["drsFault", "summary.currentBalance"];
      self.getViewData = getViewData;
      self.masterViewGridOptions = drsFaultsViewService.getMasterViewGridOptions();

      self.faultMasterTitle = i18nService.getString(
            'Common', 'drs.faultControl.drsState.headerLabel');

      getViewData();

      $scope.$watch(function(){ return self.masterViewGridOptions.selectedItems;},
            function(newvalue) {
         if (newvalue && newvalue.length > 0) {
            $scope.masterDetailsViewContext.selectedItems = [newvalue];
            self.selectedItem = newvalue[0];
         }  else if ($scope.masterDetailsViewContext !== undefined){
            $scope.masterDetailsViewContext.selectedItems = [];
         }
      });

      function getViewData() {
         dataService.getProperties(objectId, ['computedDrsFaults'])
               .then(function (faultsData) {
                  setMasterViewGridData(faultsData);
               });
      }

      $scope.preselectComparator = function(dataGridItem) {
         var preselect = false;

         if (self.selectedItem) {
            preselect = dataGridItem.target.text === self.selectedItem.target.text
                  && dataGridItem.reason === self.selectedItem.reason;
         }

         return preselect;
      };

      function setMasterViewGridData(faultsData) {
         var data = [];

         faultsData.computedDrsFaults.forEach(function(fault) {
            data.push(drsFaultsService.getFaultMasterData(fault));
         });

         self.masterViewGridOptions.data = data;
      }
   }
})();
