(function() {
   'use strict';

   /**
    * Controller for the IPv4 settings page in Edit VMkernel adapter settings dialog.
    */

   angular
         .module('com.vmware.vsphere.client.network')
         .controller('EditVnicIpv4PageController', EditVnicIpv4PageController);

   EditVnicIpv4PageController.$inject = [
         '$scope',
         'ipv4SettingsPageValidator',
         'vuiConstants',
         'vnicConstants',
         'staticIpInputTypes',
         'i18nService'];

   function EditVnicIpv4PageController(scope, ipv4SettingsPageValidator, vuiConstants,
         vnicConstants, staticIpInputTypes, i18nService) {
      scope.manager.vnicData.ipv4PageModel.onCommit = onCommit;
      scope.manager.vnicData.ipv4PageModel.validateIpv4DefaultGateway = {};

      this.pageModel = scope.manager.vnicData.ipv4PageModel;

      this.pageModel.isGatewayFeatureSupported = this.pageModel.isGatewayFeatureSupported;

      if (this.pageModel.dnsServers && this.pageModel.dnsServers.length === 0) {
         this.pageModel.dnsServers =
               [i18nService.getString('NetworkUi', 'NetworkUtil.noData')];
      }

      scope.defaultGateway = (this.pageModel && this.pageModel.defaultGateway)
            ? this.pageModel.defaultGateway
            : i18nService.getString('NetworkUi', 'NetworkUtil.noData');

      this.ipv4PropertyType = staticIpInputTypes.IPV4.IP_ADDRESS;
      this.subnetMaskPropertyType = staticIpInputTypes.IPV4.SUBNET_MASK;
      this.defaultIpv4GatewayPropertyType = staticIpInputTypes.IPV4.DEFAULT_GATEWAY;
      this.defaultGatewayValidationMessage = i18nService.getString(
            'H5NetworkUi', 'AddNetworkingWizard.ipv4SettingsPage.invalidDefaultGatewayAddress');

      function onCommit() {
         scope.manager.vnicData.ipv4PageModel.dirty =
               scope.manager.vnicData.ipv4PageModel.dirty ||
               scope.nwEditVnicIpv4PageForm.$dirty;
         var staticIpAddress = scope.manager.vnicData.ipv4PageModel.ipSettingsOption ===
               vnicConstants.ipSettingsOption.staticIp;

         if (scope.manager.vnicData.ipv4PageModel.dirty && staticIpAddress) {
            // Invoke both input's inline validations
            scope.validateIpAddress();
            scope.validateSubnetMask();

            if (scope.manager.vnicData.ipv4PageModel.isGatewayFeatureSupported
                  && scope.manager.vnicData.ipv4PageModel.isGatewayOverriden) {
               scope.manager.vnicData.ipv4PageModel.validateIpv4DefaultGateway();
            }

            var dhcpEnabled = scope.manager.vnicData.ipv4PageModel.ipSettingsOption ===
                  vnicConstants.ipSettingsOption.dhcp;

            var validationData = {
               hostId: scope.manager.vnicData.hostId,
               dhcpEnabled: dhcpEnabled,
               ipv4Address: scope.manager.vnicData.ipv4PageModel.ipAddress,
               subnetMask: scope.manager.vnicData.ipv4PageModel.subnetMask,
               isGatewayFeatureSupported:
                     scope.manager.vnicData.ipv4PageModel.isGatewayFeatureSupported,
               overrideGateway: scope.manager.vnicData.ipv4PageModel.isGatewayOverriden,
               ipv4DefaultGateway: scope.manager.vnicData.ipv4PageModel.defaultGateway,
               vnicName: scope.manager.vnicData.vnicDevice
            };

            var result = ipv4SettingsPageValidator.getValidationErrors(validationData);

            if (result instanceof Array) {
               return buildValidationMessages(result);
            } else {
               return result.then(function(errorMessage) {
                  return buildValidationMessages(errorMessage);
               });
            }
         }

         return true;
      }

      //region private helpers
      function buildValidationMessages(messages) {
         return _.map(messages, function (message) {
            return {
               type: vuiConstants.validationBanner.type.ERROR,
               text: message
            };
         });
      }

      function isStaticIpEnabled() {
         return scope.manager.vnicData.ipv4PageModel.ipSettingsOption ===
               vnicConstants.ipSettingsOption.staticIp;
      }
      //endregion

      return this;
   }
})();