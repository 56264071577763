module vm_ui {
   import Privileges = h5_vm.Privileges;
   export class VmHardwareControllerAndNodeService {

      static $inject: string[] = [
         "vmHardwareUtil"
      ];

      constructor(
            private vmHardwareUtil: any) {
      }

      isEditVmHardwareControllerAndNodeDisabled(inflatedDevice: any, privileges: Array<string>, powerState: string) {
         // in some cases in clone workflow initially scope.inflatedDevice is
         // undefined, so preventing NPE here
         if (!inflatedDevice) {
            return true;
         }

         if(inflatedDevice.isNew()){
            return false;
         }

         if(!this.vmHardwareUtil.isVmPoweredOff(powerState) || !this.hasPrivilegesToEdit(privileges)) {
            return true;
         }

         return false;
      }

      hasPrivilegesToEdit(privileges: Array<string>) {
         return this.vmHardwareUtil.checkPrivileges(privileges, [Privileges.VM_EDITDEVICE_PRIVILEGE]);
      }
   }

   angular.module("com.vmware.vsphere.client.vm")
         .service("vmHardwareControllerAndNodeService", VmHardwareControllerAndNodeService);
}
