namespace h5_client {
    export module common_module_ui {
        import IScope = angular.IScope;


        interface CreateContentLibrarySelectDatastoreControllerScope extends IScope {
            wizardConfig: any;
            vuiWizard: any;
            wizardState: any;
        }

        class CreateContentLibrarySelectDatastoreController {
            public static $inject = [
               '$scope',
               'i18nService',
               'defaultUriSchemeUtil',
               'dataService',
               'vuiConstants',
               'authorizationService',
               '$q'
            ];
            public i18n: any;
            public objSelectorConf: any;
            private readonly DATASTORE_ALLOCATESPACE_PRIVILEGE: string =
                  'Datastore.AllocateSpace';

            constructor(private $scope: CreateContentLibrarySelectDatastoreControllerScope,
                        private i18nService: any,
                        private defaultUriSchemeUtil: any,
                        private dataService: any,
                        private vuiConstants: any,
                        private authorizationService: any,
                        private $q: any) {
                this.i18n = i18nService.getString;

                let datastoreListConfig = {
                    listViewId: 'vsphere.core.datastore.list',
                    dataModels: ['Datastore']
                };

                this.objSelectorConf = {
                    contextObject: this.defaultUriSchemeUtil.createVmomiUri('Folder', 'group-d1', this.$scope.wizardState.vcService.serviceGuid),
                    multipleSelect: false,
                    listTabConfig: {
                        selectedTabIndex: 0,
                        listConfig: [datastoreListConfig]
                    },
                    selectedObjects: this.$scope.wizardState.datastore ? [this.$scope.wizardState.datastore.id] : null,
                    onSelectionChanged: this.onDatastoreSelectionChange.bind(this)
                };
                this.$scope.vuiWizard.currentPage.state = this.vuiConstants.wizard.pageState.INCOMPLETE;

                this.$scope.vuiWizard.currentPage.onCommit = () => {
                    let self = this;
                    if(!this.$scope.wizardState.datastore.id) {
                       this.showValidationMessage('createLibraryWizard.addStoragePage.selectDatastore');
                       return self.$q.when(false);
                    }
                    return this.isValidDatastore(this.$scope.wizardState.datastore.id).then(function(validDatastore: boolean) {
                        if (validDatastore) {
                            self.$scope.vuiWizard.currentPage.state = self.vuiConstants.wizard.pageState.COMPLETED;
                            return true;
                        } else {
                            self.$scope.wizardConfig.validationBanner.messages = [{
                                text: i18nService.getString('LibraryUi', 'error.datastore.inaccessible'),
                                type: self.vuiConstants.validationBanner.type.ERROR
                            }];
                            self.$scope.wizardConfig.validationBanner.showAllMessages = true;
                            return false;
                        }
                    });
                };
            }

            private isValidDatastore(datastore: string): ng.IPromise<boolean> {
                return this.dataService.getProperties(datastore, ['summary.accessible']).then((properties: any)=> {
                    return properties['summary.accessible'];
                });
            }

            private onDatastoreSelectionChange(datastore: any): void {
               this.$scope.vuiWizard.currentPage.state =
                     this.vuiConstants.wizard.pageState.INCOMPLETE;

               this.hasPrivilegeOnDatastore(datastore[0].id).then((isAuthorized: boolean) => {
                  if (isAuthorized) {
                     this.$scope.wizardState.datastore = { id: datastore[0].id, name:  datastore[0].name };
                     this.$scope.vuiWizard.currentPage.state =
                           this.vuiConstants.wizard.pageState.COMPLETED;
                  } else {
                     this.$scope.wizardState.datastore = {};
                     this.showValidationMessage('h5.error.noDatastorePrivilege');
                  }
               });
            }

            private hasPrivilegeOnDatastore(datastoreId: string): ng.IPromise<boolean> {
               return this.authorizationService.checkPrivilege(datastoreId, this.DATASTORE_ALLOCATESPACE_PRIVILEGE);
            }

            private showValidationMessage(messageKey: string): void {
               this.$scope.wizardConfig.validationBanner.messages = [{
                  text: this.i18nService.getString('LibraryUi', messageKey),
                  type: this.vuiConstants.validationBanner.type.ERROR
               }];

               this.$scope.wizardConfig.validationBanner.showAllMessages = true;
            }
        }

        angular.module('com.vmware.vsphere.client.commonModule')
            .controller('createContentLibrarySelectDatastoreController', CreateContentLibrarySelectDatastoreController);
    }
}
