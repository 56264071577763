/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
/*
 * Service to validate that the host name is with the correct format
 */
(function () {
    'use strict';
    angular.module('com.vmware.vsphere.client.host').service('addHostNameLocationPageService', addHostNameLocationPageService);

    addHostNameLocationPageService.$inject = ['i18nService'];

    function addHostNameLocationPageService(i18nService) {
        return {
            build: function (hostAdder, vuiWizardHelper) {
                return {
                    title: i18nService.getString('HostUi', 'addHostWizard.hostNameAndLocationPage.title'),
                    description: i18nService.getString('HostUi', 'addHostWizard.hostNameAndLocationPage.hostNameDescription'),
                    contentUrl: 'host-ui/resources/host/views/addHostWizard/pages/addHostNameLocationPage.html',
                    onCommit: function () {
                       if(hostAdder.isHostnameValid()) {
                          vuiWizardHelper.markPageComplete();
                          return true;
                        } else {
                            vuiWizardHelper.markPageIncompleteWithError(i18nService.getString('HostUi', 'addHostWizard.hostNameAndLocationPage.invalidHostError'));
                            return false;
                        }
                    }
                };
            }
        };
    }
})();
