namespace h5_vm {
   import VmProvisioningWizardPage = h5_vm.VmProvisioningWizardPage;

   export class CloneVmReviewPageService implements VmProvisioningPageService {

      static $inject: string[] = [
         "i18nService",
         "vuiConstants",
         "vmVuiWizardPageBuilder",
         "cloneVmReviewPageModel"
      ];

      constructor(private i18nService: any,
            private vuiConstants: any,
            private vmVuiWizardPageBuilder: any,
            private cloneVmReviewPageModel: any) {
      }

      public build(wizardScope: any,
            defaultTarget?: any,
            preselectedComputeResource?: any,
            preselectedStorage?: any): VmProvisioningWizardPage {

         // todo: why the entire wizardScope and some prticular properties are passed to
         // the model? In general model shouldn't need the entire wizard scope, but even
         // so, why we need to pass some wizard scope properties as well?
         // in the controller of the page the page model shouldn't be accessed by
         // the provided scope, but instead we should set the page model as a property
         // to the scope, and then bind it to the page controller through html page
         // (this is the standard way we pass paramters to the components!)
         let pageModel = new this.cloneVmReviewPageModel(
               wizardScope.wizardViewData,
               wizardScope.vmParams,
               wizardScope);
         wizardScope.cloneVmReviewPageModel = pageModel;

         return this.vmVuiWizardPageBuilder.buildVuiWizardFinishPage(wizardScope.config, {
            title: this.i18nService.getString("ProvisioningUiLib", "ReviewPage.title"),
            contentUrl: "vm-ui/resources/vm/views/clone/clone-vm-review.html",
            model: pageModel
         });
      };
   }

   angular.module("com.vmware.vsphere.client.vm")
         .service("cloneVmReviewPageService", CloneVmReviewPageService);
}
