/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace network_ui {

   export class SelectVmkAdapterToMigratePageValidator {

      public static $inject = ["i18nService"];

      constructor (private i18nService: any) {
      }

      public getValidationError(selectedVmkAdapter: string): string[] {
         let errors: string[] = [];

         if (!selectedVmkAdapter) {
            errors.push(this.i18nService.getString(
                  "NetworkUi", "VssVirtualNicMigrateWizard.error.selectNic"));
         }

         return errors;
      }
   }
   angular.module("com.vmware.vsphere.client.network")
         .service("selectVmkAdapterToMigratePageValidator",
               SelectVmkAdapterToMigratePageValidator);
}
