(function() {
   'use strict';

   angular
      .module('com.vmware.vsphere.client.vm')
      .service('VmProvisioningSelectOvfPageModel', VmProvisioningSelectOvfPageModel);

   VmProvisioningSelectOvfPageModel.$inject = [
      '$q',
      'i18nService',
      'creationTypeConstants',
      'sourceTypeConstants',
      '$injector'
   ];

   function VmProvisioningSelectOvfPageModel ($q, i18nService, creationTypeConstants,
                                              sourceTypeConstants,
                                              $injector
   ) {
      return function(virtualMachineSpecBuilder, wizardViewData, wizardConfig, defaultTarget, preselectedComputeResource) {
         var form = {
            isValid: false,
            isLocalDeployment: false,
            userSelectedFiles: [],
            ovfUrl: null,
            vmName: null,
            hasAcceptedUntrustedSource: false
         };

         return {
            form: form,

            getContextObjId: function() {
               return wizardViewData.getSourceObjectId();
            },

            validatePage: function() {
               if (_.isEmpty(form.ovfUrl)) {
                  return false;
               }
               return true;
            },

            submitPage: function() {
               if (!form.isLocalDeployment) {
                  var fileName = parseTemplateNameFromUrl(form.ovfUrl);
                  var vmName = parseFileName(fileName);
                  virtualMachineSpecBuilder.setName(vmName);
                  virtualMachineSpecBuilder.setTemplateName(vmName);
                  wizardViewData.setUrlTemplate(form.ovfUrl);
                  wizardViewData.setTemplateSourceType(sourceTypeConstants.SOURCE_TYPE_PULL);
                  wizardViewData.setSslThumbprint(form.sslThumbprint);
               } else {
                  var workflowManager = $injector.get('vscOvfWorkflowProxyService');

                  var ovfWorkflowData = workflowManager.getNewSession(form.userSelectedFiles);

                  virtualMachineSpecBuilder.setName(ovfWorkflowData.templateName);
                  virtualMachineSpecBuilder.setTemplateName(ovfWorkflowData.templateName);

                  wizardViewData.setDeployOvfSession(ovfWorkflowData);
                  wizardViewData.setTemplateSourceType(sourceTypeConstants.SOURCE_TYPE_PUSH);
               }
               virtualMachineSpecBuilder.setCreationType(creationTypeConstants.DEPLOY_VM_FROM_OVF);

               return {
                  invalidateNextPages: false,
                  wizardFlow: virtualMachineSpecBuilder.getCreationType()
               };
            },

            pageFinishedLoading: function() {
               return form.isValid;
            }
         };

         function parseTemplateNameFromUrl(url) {
            if (_.isEmpty(url)) {
               return null;
            }
            var parser = url;
            if (!parser.toLowerCase().endsWith('.ovf') && !parser.toLowerCase().endsWith('.ova')) {
               return null;
            }
            return parser.split("/").pop();
         }

         function parseFileName(fileName) {
            if (!_.isEmpty(fileName)) {
               var ext = fileName.split('.').pop();
               if (ext && ext.length > 0) {
                  return fileName.substr(0, fileName.length - ext.length -1);
               }
            }
            return null;
         }
      };
   }
})();

