/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */

(function () {
   'use strict';

   /**
    * Resolves a file location on a datastore to a URL that
    * can be used to download or upload a file.
    *
    * The datastore path is in the form:
    *    [datastore] Virtual Machine/Virtual Machine.vmdk
    *
    * The file URL is in the form:
    *    https://<hostname>/folder/Virtual%20Machine/Virtual%20Machine.vmdk?dcPath=datacenter&dsName=datastore
    */
   angular.module("com.vmware.vsphere.client.storage").factory("fileUrlResolverService",
         ["dataService", "browserService",
            function (dataService, browserService) {

               return {
                  resolveFileUrls: resolveFileUrls
               };

               function resolveFileUrls(datacenterRef, datastoreId, datastorePaths,
                     isStreamFormat) {
                  var param = [{
                     "propertyName": "fileTransferEndpoints",
                     "parameterType": "com.vmware.vsphere.client.storage.DatastoreFilePathsSpecEx",
                     "parameter": {
                        "datacenter": datacenterRef,
                        "datastorePaths": datastorePaths,
                        "isStreamFormat": isStreamFormat,
                        // Always use VC for IE file uploads due to IE CORS issue:
                        // https://developer.microsoft.com/en-us/microsoft-edge/platform/issues/1282036/
                        "alwaysTransferThroughVc": browserService.isIe
                     }
                  }];

                  return dataService.getProperties(datastoreId, 'fileTransferEndpoints', {
                     propertyParams: param
                  });
               }
            }
         ]);
})();
