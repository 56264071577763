/* Copyright 2015 VMware, Inc. All rights reserved. -- VMware Confidential */

/**
 * The select VM name and folder page controller.
 */
(function () {
   'use strict';

   angular
      .module('com.vmware.vsphere.client.vm')
      .controller('VmProvisioningSelectVmToCloneController', VmProvisioningSelectVmToCloneController);

   VmProvisioningSelectVmToCloneController.$inject = [
      '$scope',
      'i18nService',
      'defaultUriSchemeUtil',
      'managedEntityConstants'
   ];

   function VmProvisioningSelectVmToCloneController($scope, i18nService, defaultUriSchemeUtil, managedEntityConstants) {
      var self = this;

      this.treeDescription = i18nService.getString('VmUi', 'SelectTemplateProvisioningPage.Vm.Description');

      this.onChange = function (objectId, text, nodeTypeId) {
         $scope.wizardManager.clearValidationBanner();
         setVmId(objectId);
         $scope.wizardViewData.setSourceObjectName(text);

         setLoadingIndicator(true);
         $scope.config.loadingMessage = i18nService.getString('CommonUi', 'wizard.validating');

         $scope.selectVmToClonePageModel.validateSelection().then(function (compatibilityMessage) {
            self.compatibilityMessage = compatibilityMessage;
            setLoadingIndicator(false);
         });
      };

      this.selectedItem = $scope.vmParams.getVmId();

      this.treeConfig = {
         preselectRoot: false,
         expandPreselectedNode: true
      };

      function setLoadingIndicator(setting) {
         $scope.config.loading = setting;
      }

      function setVmId(vmId) {
         if (defaultUriSchemeUtil.getEntityType(vmId)
               !== managedEntityConstants.VIRTUAL_MACHINE) {
            $scope.vmParams.setVmId(undefined);
            return;
         }

         $scope.vmParams.setVmId(vmId);
      }
   }

})();
