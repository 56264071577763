/* Copyright 2015 VMware, Inc. All rights reserved. -- VMware Confidential */

/**
 * Retrieves objects related to given objectId.
 * Used by related objects portlet in entity summary screen.
 */
angular.module('com.vmware.vsphere.client.commonModule').factory('relatedItemsService', ['$http',
   function ($http) {
      return {
         /**
          * Gets the related objects to given objectId.
          */
         getRelatedItems: function (objectId) {
            return $http({
               method: 'get',
               url: 'relateditems/simple/' + objectId
            }).then(function (resp) {
               return resp.data;
            });
         },

         /**
          * Gets the related items for the given <code>objectId</code> for
          * the given <code>relationId</code>.
          * @param objectId Unique relation id. e.g. vmsForHost
          * @param relationId Unique object id. e.g. urn:vmomi:HostSystem:ha-host:null
          * @param defaultProperties
          *    If true, only the default properties are fetched (like name, primaryIconId).
          *    If false, properties to be fetched are computed based on the relationId.
          * @returns {*}
          */
         getRelatedItemsByRelationId: function (objectId, relationId, defaultProperties) {
            return $http({
               method: 'get',
               url: 'relateditems/' + relationId + '/' + objectId + '?defaultProperties=' + defaultProperties
            }).then(function (resp) {
               return resp.data;
            });
         }
      };
   }]);