(function() {
   'use strict';
   angular.module('com.vmware.vsphere.client.host')
         .controller('moveHostVmsAndRpsController', MoveHostVmsAndRpsController);

   MoveHostVmsAndRpsController.$inject = [
      '$scope',
      'mutationService',
      'i18nService',
      'moveHostToService',
      'vuiConstants'
   ];

   function MoveHostVmsAndRpsController($scope,
         mutationService,
         i18nService,
         moveHostToService,
         vuiConstants) {
      var self = this;
      self.destinationType = "root";
      self.hostId = $scope.modalOptions.dialogData.hostId;
      self.hostName = $scope.modalOptions.dialogData.hostName;
      self.showDpmWarning = false;

      moveHostToService.showRpmWarningCondition(self.hostId,
            $scope.modalOptions.dialogData.dpmTurnedOn,
            self.showDpmWarning)
            .then(function(data){
               self.showDpmWarning = data;
            });

      $scope.modalOptions.onSubmit = function() {
         var moveSpec = {};
         if (self.destinationType === 'root') {
            moveSpec = moveHostToService.getMoveRpSpec(
                  $scope.modalOptions.dialogData.hostMor,
                  true
            );

            mutationService.apply(
                  $scope.modalOptions.dialogData.entityToMoveHostTo,
                  'com.vmware.vsphere.client.h5.host.moveHost.MoveHostSpec',
                  moveSpec
            );
            $scope.closeModal();

         } else {

            // Handle error if RP name is empty
            if (!self.destination) {
               $scope.modalOptions.alerts = [];
               var alertMsg = i18nService.getString(
                     'HostUi',
                     'hostToClusterOp.resPoolPage.rpNameReqError'
               );
               $scope.modalOptions.alerts.push({
                  text: alertMsg,
                  type: vuiConstants.validationBanner.type.ERROR
               });
            } else {
               moveSpec = moveHostToService.getMoveRpSpec(
                     $scope.modalOptions.dialogData.hostMor,
                     false
               );
               moveSpec.moveVmsAndRpsConfigSpec.newRpName = self.destination;
               mutationService.apply(
                     $scope.modalOptions.dialogData.entityToMoveHostTo,
                     'com.vmware.vsphere.client.h5.host.moveHost.MoveHostSpec',
                     moveSpec
               ).then(function(response) {
                  // Handle errors if RP name is not valid
                  var responseParameter = response.parameter;
                  if (responseParameter && responseParameter.unsuccessfulMovementOperation) {
                     var alertMsg = i18nService.getString(
                           'HostUi',
                           'moveHost.moveVms.error.nameAlreadyExist',
                           self.destination
                     );
                     $scope.modalOptions.alerts = [];
                     $scope.modalOptions.alerts.push({
                        text: alertMsg,
                        type: vuiConstants.validationBanner.type.ERROR
                     });
                  } else {
                     $scope.closeModal();
                  }
               });
            }
         }
      };

   }
})();

