/* Copyright 2015 Vmware, Inc. All rights reserved. -- Vmware Confidential */
/**
 * The open console service retrieves required params in order to open a console in a new browser tab
 */
angular.module('com.vmware.vsphere.client.vm')
      .factory('remoteConsoleService', ['$rootScope', '$q', 'dataService', 'userSessionExService', 'defaultUriSchemeUtil',
         function ($rootScope, $q, dataService, userSessionExService, defaultUriSchemeUtil) {

            /** Public method **/
            return {
               getRemoteConsoleUrl: getRemoteConsoleUrl
            };

            function getRemoteConsoleUrl(vmUid) {
               var sessionTicket, host;
               var remoteConsoleDeferred = $q.defer();

               var vmRef = defaultUriSchemeUtil.getManagedObjectReference(vmUid);

               userSessionExService.acquireCloneTicket(vmRef.serverGuid).then(getTicketInfo).then(function(info) {
                  remoteConsoleDeferred.resolve(retrieveRemoteConsoleUrl(info.sessionTicket, info.host, vmRef.value));
               });

               return remoteConsoleDeferred.promise;

               //--- Implementations ---//
               function getTicketInfo(cloneSessionInfo) {
                  host = getHostnameWithPort(cloneSessionInfo.serverInfo.serviceUrl);
                  sessionTicket = cloneSessionInfo.sessionTicket;

                  function getHostnameWithPort(url) {
                     var l = document.createElement("a");
                     l.href = url;
                     return l.host;
                  }

                  return {
                     host: host,
                     sessionTicket: sessionTicket
                  };

               }

               function retrieveRemoteConsoleUrl(sessionTicket, host, vmId) {
                  var remoteConsoleUrl = "vmrc://clone:" + sessionTicket + "@" + host + "/?moid=" + vmId;
                  return remoteConsoleUrl;
               }
            }

         }]);