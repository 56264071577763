/** Copyright 2016 VMware, Inc.  All rights reserved. -- VMware Confidential*/

angular.module('com.vmware.vsphere.client.cluster').constant('ftConstants', {
   ftState: {
      DISABLED: 'disabled',
      ENABLED: 'enabled',
      NEED_SECONDARY: 'needSecondary',
      STARTING: 'starting',
      RUNNING: 'running',
      PROTECTED: 'protected',
      ENABLED_POWERED_ON: 'enabledPoweredOn',
      ENABLED_NOT_POWERED_ON: 'enabledNotPoweredOn',
      NOT_RUNNING: 'notRunning',
      NOT_PROTECTED: 'notProtected',
      NOT_CONFIGURED: 'notConfigured'
   },
   powerState: {
      POWERED_OFF: 'poweredOff',
      POWERED_ON: 'poweredOn',
      SUSPENDED: 'suspended'
   },
   moduleName: {
      FT_STATE: 'ftState.',
      FT_STATUS: 'ftStatus.',
      FT_STATUS_TOOLTIP: 'ftStatusTooltip.'
   },
   warnings: {
      INVALID_FT_STATE: 'Unexpected FT state: ',
      UNEXPECTED_FT_STATE: 'Invalid virtual machine fault tolerance state. '
   }
});

