/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
(function () {
   'use strict';

   var module = angular.module('com.vmware.vsphere.client.networkLibUi');
   module.directive('ipv4AddressInput', ipv4AddressInput);

   function ipv4AddressInput() {

      var directive = {
         restrict: 'A',
         replace: true,
         scope: {
            value: '=' // IPv4 value
         },
         template: '<input class="ip-address-input" type="text" ng-model="value"/>'
      };

      return directive;
   }
})();
