/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace network_lib_ui {

   export class DvpgPropertiesService {

      static $inject = [ "i18nService", "networkUiConstants", "mutationService" ];

      static MAX_ALLOWED_NAME_LENGTH: number = 80;

      constructor(private i18nService: any,
                  private networkUiConstants: any,
                  private mutationService: any) {
      }

      /**
       * Validates the name of a distributed port group and return an appropriate
       * localized error message or empty string if there is no error.
       */
      public getInvalidNameError(name: string): string | null {
         let trimmedName: string = name.trim();
         let escapedName: string = trimmedName.replace(/%/g, "%25")
               .replace(/\//g, "%2f").replace(/\\/g, "%5c");

         if (escapedName.length === 0) {
            return this.i18nService.getString("NetworkLibUi", "dvpg.properties.name.required");
         } else if (escapedName.length > DvpgPropertiesService.MAX_ALLOWED_NAME_LENGTH) {
            return this.i18nService.getString("NetworkLibUi", "dvpg.properties.name.tooLong");
         }

         return null;
      }

      /**
       * Returns localized error text if the given host has a distributed portgroup with
       * the provided name or the name is more than 214 bytes.
       * Otherwise it returns null.
       */
      public getDuplicateOrTooLongNameError(parentEntityUrn: string, name: string): any {
         return this.mutationService.validate(parentEntityUrn,
               "com.vmware.vsphere.client.h5.network.dvportgroup.model.DvPortgroupNameSpec",
               {
                  _type: "com.vmware.vsphere.client.h5.network.dvportgroup.model.DvPortgroupNameSpec",
                  name: name.trim()
               })
               .then((validationResult: any) => {
                  if (validationResult.error && validationResult.error.localizedMessage) {
                     return [validationResult.error.localizedMessage];
                  }

                  if (validationResult.result) {
                     return [validationResult.result];
                  }

                  return [];
               }, () => {
                  return [];
               });
      }

      public getVlanIdError(value: number): string | null {
         if (! this.isNumValueValid(value,
               this.networkUiConstants.Vlan.Id.MIN,
               this.networkUiConstants.Vlan.Id.MAX)) {
            return this.i18nService.getString(
                  "NetworkLibUi", "dvpg.properties.vlan.type.error");
         }

         return null;
      }

      public getNumPortsError(value: number,
         maxValue: number = this.networkUiConstants.DvPortgroupNumPorts.MAX)
         : string | null {

         if (! this.isNumValueValid(value,
               this.networkUiConstants.DvPortgroupNumPorts.MIN,
               maxValue)) {
            return this.i18nService.getString(
                  "NetworkLibUi", "dvpg.properties.numPorts.error", maxValue);
         }

         return null;
      }

      private isNumValueValid(
            value: number,
            minValue: number,
            maxValue: number): boolean {

         return value !== null &&
               !isNaN(value) &&
                this.isValidInteger(value.toString()) &&
                value >= minValue &&
                value <= maxValue;
      }

      private isValidInteger(value: string): boolean {
         let pattern: RegExp = new RegExp("^[0-9]+$");
         return pattern.test(value);
      }
   }

   angular.module("com.vmware.vsphere.client.networkLibUi")
         .service("dvpgPropertiesService", DvpgPropertiesService);
}
