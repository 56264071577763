/* Copyright 2016-2017 VMware, Inc. All rights reserved. -- VMware Confidential */
(function() {
   'use strict';
   angular.module('com.vmware.vsphere.client.commonModule')
      .controller('TagsController', TagsController);

   TagsController.$inject = ['i18nService', 'vuiConstants', '$scope', 'datagridActionBarService',
         'defaultUriSchemeUtil', 'dataService', '$element', 'userSessionService'];

   function TagsController(i18nService, vuiConstants, $scope, datagridActionBarService,
         defaultUriSchemeUtil, dataService, $element, userSessionService) {

      var GRANTED_PRIVILEGES_PROP = "grantedPrivileges";
      var AUTHZ_ROOT_TYPE = "acl:RootPermissionNode";
      var AUTHZ_ROOT_ID = "root";

      var CREATE_TAG_PRIV = "InventoryService.Tagging.CreateTag";
      var EDIT_TAG_PRIV = "InventoryService.Tagging.EditTag";
      var DELETE_TAG_PRIV = "InventoryService.Tagging.DeleteTag";

      var self = this;
      self.grantedPrivileges = [];
      self.onSelectionChange = function(selectedItems) {
         self.selectedItem = selectedItems && selectedItems.length === 1 ? selectedItems[0] : null;
         updateActionBar();
      };
      self.datagridOptions = {
            actionBarOptions: {actions: []},
            columnDefs: [
               {
                  displayName: i18nService.getString('Common', 'tag.dashboard.nameColumn'),
                  field: 'name',
                  sortable: true,
                  searchable: false,
                  template: function(dataItem) {
                     return dataGridTemplate(dataItem.name);
                  }
               },
               {
                  displayName: i18nService.getString('Common', 'tag.dashboard.categoryColumn'),
                  field: 'categoryName',
                  sortable: true,
                  searchable: false,
                  template: function(dataItem) {
                     return dataGridTemplate(dataItem.categoryName);
                  }
               },
               {
                  displayName: i18nService.getString('Common', 'tag.dashboard.descColumn'),
                  field: 'description',
                  sortable: true,
                  searchable: false,
                  template: function(dataItem) {
                     return dataGridTemplate(dataItem.description);
                  }
               }
            ],
            sortOrder: [{
               field: 'categoryName',
               dir: 'asc'
            }],
            height: '100%',
            resizable: true,
            reorderable: true,
            selectionMode: vuiConstants.grid.selectionMode.SINGLE,
            onChange: self.onSelectionChange.bind(self),
            selectedItems: [],
            pageable: true,
            pageConfig: {
               displayMode: vuiConstants.grid.displayMode.VIRTUAL_SCROLLING,
               size: 50
            },
            data: {
               url: 'tagging-ui/ctrl/tags',
               dataType: vuiConstants.grid.dataType.JSON,
               method: vuiConstants.grid.dataMethod.GET,
               data: 'summaryItemSpecs',
               total: 'totalMatchedObjectCount',
               beforeSend: function(req) {
                  req.setRequestHeader(userSessionService.WEB_CLIENT_SESSION_ID, userSessionService.webClientSessionId);
               }
            },
            pageSize: 50
      };

      /**
       *  Generate html template for datagrid
       */
      function dataGridTemplate (item) {
         var ele = document.createElement("span");
         ele.setAttribute("ng-non-bindable", "ng-non-bindable");
         ele.title = item;
         ele.textContent = item;
         return ele.outerHTML;
      }

      function updateActionBar() {
         var actions = [{
            actionId: 'vsphere.core.common.actions.addTag',
            isActionAvailable: function(actionDef) {
               return actionDef.available
                  && self.grantedPrivileges.indexOf(CREATE_TAG_PRIV) !== -1;
            }
         }, {
            actionId: 'vsphere.core.common.actions.editTag',
            isActionAvailable: function(actionDef) {
               return actionDef.available
                     && self.selectedItem
                     && self.grantedPrivileges.indexOf(EDIT_TAG_PRIV) !== -1;
            },
            getActionInvocationContext: function() {
               return {tag: self.selectedItem};
            }
         }, {
            actionId: 'vsphere.core.common.actions.deleteTag',
            isActionAvailable: function(actionDef) {
               return actionDef.available
                     && self.selectedItem
                     && self.grantedPrivileges.indexOf(DELETE_TAG_PRIV) !== -1;
            },
            getActionInvocationContext: function() {
               return {tag: self.selectedItem};
            }
         }];
         datagridActionBarService.updateActionBar(self.datagridOptions, [], actions);
      }


      /**
       * Get all the TAG privileges and provide restriction
       */
      function getGrantedPrivileges() {
         dataService.getProperties(
               defaultUriSchemeUtil.createUri(AUTHZ_ROOT_TYPE, AUTHZ_ROOT_ID),
               [GRANTED_PRIVILEGES_PROP])
               .then(function (result) {
                  if (result && result[GRANTED_PRIVILEGES_PROP]) {
                     self.grantedPrivileges = result[GRANTED_PRIVILEGES_PROP];
                     updateActionBar();
                  }
               });
      }

      $scope.$on('refreshTagGrid', function() {
         $element.find("[kendo-grid]").data('kendoGrid').dataSource.read();
      });


      getGrantedPrivileges();
   }
}());
