/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace dvs_ui {

   export class DvsFeaturesComponent {

      public bindings: any;
      public controller: any;
      public templateUrl: string;

      constructor() {
         this.bindings = {};
         this.controller = DvsFeaturesController;
         this.templateUrl = "dvs-ui/resources/dvs/summary/components/dvsFeaturesComponentTemplate.html";
      }
   }

   class DvsFeaturesController {

      static $inject = [
         "i18nService",
         "$rootScope",
         "dataService",
         "networkUiConstants"
      ];

      static DATA_PROPERTY: string = "dvs:featuresPortletData";

      private _data: any; /* DvsFeaturesPortletData */

      private _loadingData: boolean;

      public liveRefreshProperties: string[];

      public dSwitchUri: string;

      public niocStackBlockExpanded: boolean;

      constructor(public i18nService: any,
                  private $rootScope: any,
                  private dataService: any,
                  private networkUiConstants: any) {
      }

      protected $onInit() {
         this.requestData();
         this.niocStackBlockExpanded = false;
      }

      public refresh(): void {
         this.requestData();
      }

      public get loadingData(): boolean {
         return this._loadingData;
      }

      public get data(): any {
         return this._data;
      }

      public getFeatureSupportedText(isSupported:boolean): string {
         return isSupported
               ? this.i18nService.getString("NetworkUi", "DvsFeaturesView.supported")
               : this.i18nService.getString("NetworkUi", "DvsFeaturesView.notSupported");
      }

      public getLacpSupportText(): string {
         if (this._data.lacpSupported) {
            if (this._data.lacpApiVersion === this.networkUiConstants.LacpSupport.BASIC) {
               return this.i18nService.getString("NetworkUi", "DvsFeaturesView.lacp.basicSupport");
            } else if (this._data.lacpApiVersion === this.networkUiConstants.LacpSupport.ENHANCED) {
               return this.i18nService.getString("NetworkUi", "DvsFeaturesView.lacp.enhancedSupport");
            }
         }

         return this.i18nService.getString("NetworkUi", "DvsFeaturesView.notSupported");
      }

      private requestData(): void {
         let self: DvsFeaturesController = this;
         let dvsUri: string = this.$rootScope._route.objectId;

         self._loadingData = true;
         self.dataService.getProperties(dvsUri, [DvsFeaturesController.DATA_PROPERTY])
               .then(function (response: any) {
                  let dvsFeaturesPortletData: any = response[DvsFeaturesController.DATA_PROPERTY];
                  if (dvsFeaturesPortletData) {
                     self._data = dvsFeaturesPortletData;
                     self._loadingData = false;
                  }
               });

         this.dSwitchUri = this.$rootScope._route.objectId;
         this.liveRefreshProperties = [
            "capability.featuresSupported.ipfixCapability.ipv6ForIpfixSupported",
            "capability.featuresSupported.multicastSnoopingSupported",
            "capability.featuresSupported.lacpCapability.multiLacpGroupSupported",
            "capability.featuresSupported.vspanCapability.erspanProtocolSupported",
            "capability.featuresSupported.networkFilterSupported",
            "capability.featuresSupported.ipfixCapability.observationDomainIdSupported",
            "capability.featuresSupported.networkResourceManagementCapability.networkResourceControlVersion3Supported",
            "config.networkResourceControlVersion",
            "config.productInfo.version"
         ];
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .component("dvsFeatures", new DvsFeaturesComponent());
}
