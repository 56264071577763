module ds_cluster_ui {

   class StorageDrsViewController {
      private headerOptions: HeaderOptions;
      private watchForObjects: any;
      private objectId: string;
      private sdrsViewModelData: StorageDrsViewModelData;
      private liveRefreshProperties: Array<string>;

      public static $inject = ["$scope", "i18nService", "storageDrsDataRetrieval",
            "dsClusterConstants"];

      constructor(private $scope: ScopeRoute, private i18nService: any,
                  private storageDrsDataRetrieval: StorageDrsDataRetrieval,
                  private dsClusterConstants: any) {
         this.$scope = $scope;
         this.objectId = $scope._route.objectId;

         this.headerOptions = {
            title: this.getString("dscluster.services.drs"),
            objectId: this.objectId,
            actions: [{
               actionUid: "vsphere.core.dsCluster.actions.scheduleSdrs",
               customLabel: i18nService.getString(
                     "DsClusterUi", "sdrs.config.scheduleSdrs.button.label")
            }, {
               actionUid: "vsphere.core.dsCluster.actions.editSdrs",
               customLabel: i18nService.getString(
                     "Common", "settingsView.defaultEditActionLabel")
            }]
         };

         this.watchForObjects = [this.objectId];
         this.liveRefreshProperties =
               [this.dsClusterConstants.liveRefreshProperties.STORAGE_DRS_CONFIG];

         this.getViewData();
      }

      private getViewData() {
         this.storageDrsDataRetrieval.retrieveData(this.objectId).then(
               (sdrsViewModelData: StorageDrsViewModelData) => {
                  this.sdrsViewModelData = sdrsViewModelData;
                  this.headerOptions.title = this.getTitle(sdrsViewModelData.enabled);
               }
         );
      }

      private getTitle(sdrsEnabled: boolean): string {
         return (sdrsEnabled) ?
               this.getString("sdrs.config.sdrsEnabled")
               : this.getString("sdrs.config.sdrsDisabled");
      }

      private getString(key: string, ...params: string[]) {
         return this.i18nService.getString("DsClusterUi", key, params);
      }
   }

   angular.module("com.vmware.vsphere.client.dsCluster")
         .controller("StorageDrsViewController", StorageDrsViewController);
}
