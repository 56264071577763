/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */

/*
 * Filter Display Video Card 3D rendering as 'Automatic', 'Hardware', or 'Software'
 */
angular.module('com.vmware.vsphere.client.vm').filter('render3d', ['$filter', 'i18nService',
   function($filter, i18nService) {
      'use strict';

      var RESOURCE_KEY = {
         automatic: 'VideoCardPage.automatic',
         software: 'VideoCardPage.software',
         hardware: 'VideoCardPage.hardware'
      };

      return function(value) {
         var key = RESOURCE_KEY[value];
         key = key || RESOURCE_KEY['automatic']; // default to 'automatic' if specified key doesn't exist
         return i18nService.getString('VmUi', key);
      };
   }]);

