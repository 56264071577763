module hostprofile_ui {

    import IComponentController = angular.IComponentController;

    class RecentComplianceFailuresController implements angular.IController {

        public static $inject = ["$scope", "i18nService"];

        public objectId: string;

        constructor(private $scope: ScopeRoute,
                    public i18nService: any) {

            this.objectId = this.$scope._route.objectId;
        }
    }
    angular.module("com.vmware.vsphere.client.hostprofile")
        .controller("RecentComplianceFailuresController", RecentComplianceFailuresController);
}
