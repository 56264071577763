(function() {
   'use strict';
   angular.module('com.vmware.vsphere.client.host').controller('HardwareHealthController', HardwareHealthController);

   HardwareHealthController.$inject = [
      '$scope',
      '$rootScope',
      '$element',
      'hardwareHealthConstants',
      'hardwareHealthService',
      'vuiConstants',
      'vxPropertyViewService',
      'i18nService',
      'authorizationService',
      'vcService',
      'defaultUriSchemeUtil',
      'visibilityMonitorService'
   ];

   function HardwareHealthController(
         $scope,
         $rootScope,
         $element,
         constants,
         hardwareHealthService,
         vuiConstants,
         vxPropertyViewService,
         i18nService,
         authorizationService,
         vcService,
         defaultUriSchemeUtil,
         visibilityMonitorService) {

      this.constants = constants;
      this.hardwareHealthService = hardwareHealthService;
      this.vxPropertyViewService = vxPropertyViewService;
      this.i18nService = i18nService;
      this.authorizationService = authorizationService;
      this.vcService = vcService;

      this.data = null;
      this.sensors = [];
      this.alertsAndWarnings = [];
      this.systemEventLog = [];
      this.hostId = $scope._route.objectId;
      this.loading = false;
      this.hasActionPrivileges = true;
      this.sensorsShown = false;
      this.alertsAndWarningsShown = false;
      this.systemEventLogShown = false;
      this.is65VcOrLater = null;
      this.is66VcOrLater = null;
      this.showExpandRowLabel = false;

      this.liveRefreshProperties = [
         'hardware.systemInfo',
         'hardware.biosInfo',
         'runtime.healthSystemRuntime.systemHealthInfo.numericSensorInfo'
      ];
      var self = this;
      this.headerOptions = hardwareHealthService
            .createHeaderOptions(this.hostId);

      this.sensorActionBarOptions = hardwareHealthService
            .createSensorActionBarOptions(this.hostId);

      this.alertsAndWarningsActionBarOptions = hardwareHealthService
            .createAlertsAndWarningsActionBarOptions(this.hostId);
      this.alertAndWarningDatagridOptions = hardwareHealthService
            .createAlertsAndWarningsDatagridOptions();

      this.systemEventLogActionBarOptions = hardwareHealthService
            .createSystemEventLogActionBarOptions(this.hostId);
      this.systemEventLogDatagridOptions = hardwareHealthService
            .createSystemEventLogDatagridOptions();

      this.splitterOptions = {
         orientation: vuiConstants.splitter.orientation.HORIZONTAL,
         height: '100%',
         resizable: false,
         panes: [{
            size: '50%'
         }, {
            size: '50%'
         }]
      };

      this.detailHeaderOptions = {
         title: i18nService.getString('HostUi',
               'hardwareHealth.sensors.sensorDetails.title'),
         objectId: this.hostId
      };
      this.propertyViewMessage = i18nService.getString('HostUi',
            'hardwareHealth.sensors.sensorDetails.noItems');
      this.propertyViewData = null;
      this.showPropertyGrid = false;

      this.getHardwareHealthDataByVcVersion();

      var cleanUpAlarmsFunc = $rootScope.$on('alarms', onAlarmsUpdates);

      /**
       * When new websocket updates to alarms are received
       *
       * @param event
       *    the triggering event
       * @param partialUpdate
       *    contains updates/deletes
       */
      function onAlarmsUpdates(event, partialUpdate) {
         _.each(partialUpdate.updates, function(partialupdate) {
            if (partialupdate.data && partialupdate.data.entity &&
                partialupdate.data.entity._value === defaultUriSchemeUtil.getPartsFromVsphereObjectId(self.hostId).value) {
               self.requestHardwareHealthData();
               return;
            }
         });

      }

      this.visibilitySubscriberId = visibilityMonitorService
          .subscribeForVisibilityChange($element, function (visible) {
         if (!visible) {
            cleanUpAlarmsFunc();
         } else {
            cleanUpAlarmsFunc = $rootScope.$on('alarms', onAlarmsUpdates);
         }
      });

      $scope.$on("$destroy", function () {
         if (self.visibilitySubscriberId !== null) {
            visibilityMonitorService.unsubscribe(self.visibilitySubscriberId);
            self.visibilitySubscriberId = null;
         }
         cleanUpAlarmsFunc();
      });
   }

   HardwareHealthController.prototype.checkPrivileges = function() {
      this.authorizationService
            .checkPrivileges(this.hostId, ['Host.Cim.CimInteraction'])
            .then(function(privilegesStatuses) {
               this.hasActionPrivileges = !!privilegesStatuses[0];
               this.updateActionbarButtonStatus();
            }.bind(this));
   };

   HardwareHealthController.prototype.updateActionbarButtonStatus = function() {
      var hasSensorData = (this.sensorTreeListOptions && this.sensorTreeListOptions.dataSource.data().length !== 0)
            || this.sensors.length > 0;
      _.each(this.sensorActionBarOptions.actions, function(action) {
         action.enabled = this.hardwareHealthService.getActionStatus(action.id,
               hasSensorData, this.hasActionPrivileges);
      }, this);
      _.each(this.alertsAndWarningsActionBarOptions.actions, function(action) {
         action.enabled = this.hardwareHealthService.getActionStatus(action.id,
               hasSensorData, this.hasActionPrivileges);
      }, this);
      _.each(this.systemEventLogActionBarOptions.actions, function(action) {
         action.enabled = this.hardwareHealthService.getActionStatus(action.id,
               hasSensorData, this.hasActionPrivileges);
      }, this);
   };

   HardwareHealthController.prototype.getDefaultHardwareHealthData = function() {
      var defaultHardwareHealthData = {};
      defaultHardwareHealthData[this.constants.HARDWARE_STATUS_PROPERTY] = {
         alertsAndWarnings: [],
         bios: null,
         chassis: [],
         sensorCounts: null,
         sensors: [],
         systemEventLog: []
      };

      return defaultHardwareHealthData;
   };

   HardwareHealthController.prototype.getHardwareHealthDataByVcVersion = function() {
      this.vcService.is66VcOrLater(this.hostId).then(function(is66VcOrLater) {
         this.is66VcOrLater = is66VcOrLater;
         if (is66VcOrLater) {
            this.hardwareHealthService.requestHardwareHealthData(this.hostId,
                  this.constants.HARDWARE_STATUS_65_PROPERTY)
                  .then(function(data) {
                     this.loading = false;
                     this.hardwareHealthService.getUserTimeFormat()
                           .then(function(timeFormat) {
                              this.initVc66OrLaterHardwareHealthData(data, timeFormat);
                              this.checkPrivileges();
                           }.bind(this));
                  }.bind(this));
         } else {
            this.initHardwareHealthData();
         }
      }.bind(this));
   };


   HardwareHealthController.prototype.initHardwareHealthData = function() {
      this.vcService.is65VcOrLater(this.hostId).then(function(is65VcOrLater) {
         this.is65VcOrLater = is65VcOrLater;

         this.sensorTreeListOptions = this.hardwareHealthService
            .createSensorTreeListOptions(this.showPropertyGridItemDetails.bind(this),
                  is65VcOrLater);
         this.requestHardwareHealthData();

         this.checkPrivileges();
      }.bind(this));
   };

   HardwareHealthController.prototype.requestHardwareHealthData = function() {
      if (this.is65VcOrLater === null) {
         return;
      }

      var hardwareStatusProperty = this.is65VcOrLater ?
         this.constants.HARDWARE_STATUS_65_PROPERTY :
         this.constants.HARDWARE_STATUS_PROPERTY;

      this.loading = true;

      this.hardwareHealthService.requestHardwareHealthData(this.hostId,
            hardwareStatusProperty)
         .then(function(data) {
            this.loading = false;
            this.hardwareHealthService.getUserTimeFormat()
                  .then(function(timeFormat) {
                     data = angular.merge({}, this.getDefaultHardwareHealthData(), data);
                     var hardwareHealthData = data[hardwareStatusProperty];
                     var oldHardwareHealthData = this.data;
                     this.data = hardwareHealthData;
                     if (!_.isEmpty(hardwareHealthData.sensors)) {
                        hardwareHealthData.sensors = this.getExtendedHardwareHealthData(
                              hardwareHealthData.sensors, timeFormat);
                     }
                     this.sensorCountLabels = this.hardwareHealthService
                           .getSensorCountLabels(hardwareHealthData.sensorCounts);

                     var sensorsPartialUpdate =
                           this.getSensorsPartialUpdate(hardwareHealthData,
                                 oldHardwareHealthData);
                     if (sensorsPartialUpdate === false) {
                        this.sensorTreeListOptions.dataSource = this.hardwareHealthService
                              .createSensorTreeListDataSource(hardwareHealthData.sensors);
                     } else {
                        this.sensorTreeListOptions.dataSource.pushUpdate(sensorsPartialUpdate);
                     }

                     this.alertAndWarningDatagridOptions.data = hardwareHealthData.alertsAndWarnings;
                     this.systemEventLogDatagridOptions.data =
                           _.isNull(hardwareHealthData.systemEventLog) ? [] :
                                 this.getExtendedHardwareHealthData(hardwareHealthData
                                       .systemEventLog, timeFormat);

                     this.updateActionbarButtonStatus();
                  }.bind(this));
         }.bind(this));
   };

   HardwareHealthController.prototype.showPropertyGridItemDetails = function(id) {
      if (typeof id === 'string' && id.length > 0) {
         var sensor = _.find(this.sensorTreeListOptions.dataSource.data(), function(item) {
            return item.id === id;
         });

         var sensorDetails = sensor.details;
         if (sensorDetails) {
            var builder = this.vxPropertyViewService.createPropertyViewBuilder();
            var categoryBuilder = builder.category('');
            var sensorIcon = this.hardwareHealthService.getSensorIcon(sensor.sensorType);
            var sectionBuilder = categoryBuilder
                  .section('')
                  .title(sensor.name)
                  .titleIcon(sensorIcon);

            _.each(sensorDetails, function(sensorDetail) {
               sectionBuilder.property(sensorDetail.label, sensorDetail.value);
            }, this);

            this.propertyViewData = builder.build();
            this.showPropertyGrid = true;
         } else {
            this.propertyViewMessage = this.i18nService.getString('HostUi',
                  'hardwareHealth.sensors.sensorDetails.noDetails');
            this.showPropertyGrid = false;
         }
      } else {
         this.showPropertyGrid = false;
         this.propertyViewMessage = this.i18nService.getString('HostUi',
               'hardwareHealth.sensors.sensorDetails.noItems');
      }
   };

   HardwareHealthController.prototype.showSensorsTab = function() {
      this.sensorsShown = true;
   };

   HardwareHealthController.prototype.showAlertsAndWarningsTab = function() {
      this.alertsAndWarningsShown = true;
   };

   HardwareHealthController.prototype.showSystemEventLogTab = function() {
      this.systemEventLogShown = true;
   };

   HardwareHealthController.prototype.getSensorsPartialUpdate =
         function(newHardwareHealthData, oldHardwareHealthData) {
            if (!oldHardwareHealthData) {
               return false;
            }

            var newSensorsMetadata = _.map(newHardwareHealthData.sensors, function(item) {
               return _.pick(item, 'id', 'parentId', 'type');
            });
            var oldSensorsMetadata = _.map(oldHardwareHealthData.sensors, function(item) {
               return _.pick(item, 'id', 'parentId', 'type');
            });

            if (!_.isEqual(newSensorsMetadata, oldSensorsMetadata)) {
               return false;
            }

            var updatedSensors = _.filter(
                  newHardwareHealthData.sensors,
                  function(newSensor, index) {
                     var oldSensor = oldHardwareHealthData.sensors[index];
                     return !_.isEqual(newSensor, oldSensor);
                  }
            );
            return updatedSensors;
         };

   HardwareHealthController.prototype.initVc66OrLaterHardwareHealthData = function(data, timeFormat) {
      data = angular.merge({}, this.getDefaultHardwareHealthData(), data);
      var hardwareHealthData = data['hardwareHealthStatus'];
      this.data = hardwareHealthData;
      this.sensorCountLabels = this.hardwareHealthService
            .getSensorCountLabels(hardwareHealthData.sensorCounts);
      if (hardwareHealthData.sensors && hardwareHealthData.sensors.length > 0) {
         this.sensors = this.getExtendedSensorsHardwareHealthData(hardwareHealthData.sensors, timeFormat);
         this.showExpandRowLabel = _.some(hardwareHealthData.sensors, function(sensor) {
            return (sensor.selEntriesList && sensor.selEntriesList.length > 0) || sensor.fru;
         });
      }
      this.alertsAndWarnings = _.isNull(hardwareHealthData.alertsAndWarnings)
      || _.isEmpty(hardwareHealthData.alertsAndWarnings) ? [] : hardwareHealthData.alertsAndWarnings;
      this.systemEventLog = _.isNull(hardwareHealthData.systemEventLog)
      || _.isEmpty(hardwareHealthData.systemEventLog)
            ? [] : this.getExtendedHardwareHealthData(hardwareHealthData.systemEventLog, timeFormat);

      this.updateActionbarButtonStatus();
   };

   HardwareHealthController.prototype.getExtendedHardwareHealthData = function(sensors, timeFormat) {
      return _.map(sensors, function(sensor) {
         var timestampFormatted = this.hardwareHealthService
               .getTimestampFormatted(sensor.timestamp, timeFormat);

         return _.extend(sensor, {
            timestampFormatted: sensor.timestamp !== null
                  ? timestampFormatted : this.i18nService.getString('Common', 'notAvailable')
         });
      }.bind(this));
   };

   HardwareHealthController.prototype.getExtendedSensorsHardwareHealthData = function(sensors, timeFormat) {
      return _.map(sensors, function(sensor) {
         var timestampFormatted = this.hardwareHealthService
               .getTimestampFormatted(sensor.timestamp, timeFormat);
         var extendedSelEntriesList = _.isNull(sensor.selEntriesList) || _.isEmpty(sensor.selEntriesList)
               ? [] : this.getExtendedSelEntriesList(sensor.selEntriesList, timeFormat);

         return _.extend(sensor, {
            timestampFormatted: sensor.timestamp !== null
                  ? timestampFormatted : this.i18nService.getString('Common', 'notAvailable'),
            selEntriesList: extendedSelEntriesList
         });
      }.bind(this));
   };

   HardwareHealthController.prototype.getExtendedSelEntriesList = function(selEntriesList, timeFormat) {
      return _.map(selEntriesList, function(selEntry) {
         var timestampFormatted = this.hardwareHealthService
               .getTimestampFormatted(selEntry.timestamp, timeFormat);

         return _.extend(selEntry, {
            timestampFormatted: selEntry.timestamp !== null
                  ? timestampFormatted : this.i18nService.getString('Common', 'notAvailable')
         });
      }.bind(this));
   };

})();
