/**
 * Created by vsphereclient on 12/6/16.
 */
module vm_model {
   /**
    * The delimiter that is used to separate the part of the URI that contains the
    * type of the resource form the actual resource identification part.
    */
   const TYPE_DELIMITER = ':';
   var TYPE_DELIMITER_CODE = TYPE_DELIMITER.charCodeAt(0);

   /** The default URI scheme for Data Service objects */
   const DEFAULT_PREFIX = 'urn:vri:';

   /** The URI scheme for vSphere objects */
   const VMOMI_PREFIX = 'urn:vmomi:';
   const GLOBAL_PREFIX = 'urn:vri:acl:';

   /**
    * The character that will be used for escaping the special characters
    * in the type and id sections of the URI. Since we're building an URI,
    * we want to choose a character that is allowed in URIs and doesn't
    * have to be escaped itself by the URI. For that reason, using
    * backslash (\) as an escape character is not optimal.
    */
   const ESCAPE_CHAR = "~";
   const ESCAPE_CHAR_CODE = ESCAPE_CHAR.charCodeAt(0);

   /**
    * The character used in an escape sequence for escaping a delimiter.
    * The delimiters are listed in {@link #TYPE_DELIMITER} and
    * {@link #NSS_DELIMITER}. They are escaped using the sequence
    * of {@link ESCAPE_CHAR} followed by this character. In other words,
    * the sequence: <code>~d</code>
    */
   const ESCAPE_CHAR_FOR_DELIMITER = '_';
   const ESCAPE_CHAR_FOR_DELIMITER_CODE = ESCAPE_CHAR_FOR_DELIMITER.charCodeAt(0);

   export class MoRef {

      public _type: string = 'com.vmware.vim.binding.vmodl.ManagedObjectReference';

      constructor(public type: string, public value: string, public serverGuid: string) {
      }

      public getUri(): string {
         MoRef.check(this.type, this.value, this.serverGuid);
         return VMOMI_PREFIX + [
                  this.escape(this.type),
                  this.escape(this.value),
                  this.escape(this.serverGuid)
               ].join(TYPE_DELIMITER);
      }

      public equals(other: MoRef) {
         if (!other) {
            return false;
         }
         return this.getUri() === other.getUri();
      }

      public static fromObject(object: { type: string, value: string, serverGuid: string }) {
         return new MoRef(object.type, object.value, object.serverGuid);
      }

      public static fromUri(uri: string) {
         if (!uri) {
            return undefined;
         }
         let parts = MoRef.partsFromUri(uri);
         this.check(parts[0], parts[1], parts[2]);

         return new MoRef(parts[0], parts[1], parts[2]);
      }

      static check(type: string, value: string, serverGuid: string): void {
         let parts = _.filter([type, value, serverGuid], (item) => {
            return !!item;
         });

         if (parts.length !== 3) {
            throw new Error('Type, value or serverGuid is empty');
         }
      }

      private escape(str: string): string {
         if (str.indexOf(ESCAPE_CHAR) < 0 && str.indexOf(TYPE_DELIMITER) < 0) {
            return str;
         }

         var len = str.length;
         var buffer: Array<number> = [];

         for (var i = 0; i < len; i++) {
            var ch = str.charCodeAt(i);
            if (ch === TYPE_DELIMITER_CODE) {
               buffer.push(ESCAPE_CHAR_CODE);
               buffer.push(ESCAPE_CHAR_FOR_DELIMITER_CODE);
            } else if (ch === ESCAPE_CHAR_CODE) {
               buffer.push(ESCAPE_CHAR_CODE);
               buffer.push(ESCAPE_CHAR_CODE);
            } else {
               buffer.push(ch);
            }
         }
         return String.fromCharCode.apply(String, buffer);
      }

      static partsFromUri(uri: string) {

         let parts = uri.split(TYPE_DELIMITER);
         while (parts.length !== 3) {
            parts.shift();
         }

         return parts;
      }
   }

   angular.module('com.vmware.vsphere.client.vm').service(
         'MoRef', () => {
            return MoRef;
         });
}
