namespace h5_vm {

import MigrationService = h5_vm.MigrationService;
import MigrationService$ValidationResultsData = h5_vm.MigrationService$ValidationResultsData;
import MigrationWizardManager = h5_vm.MigrationWizardManager;
import MigrationWizardManager$ComputeResourceValidationData = h5_vm.MigrationWizardManager$ComputeResourceValidationData;
import SelectComputeResourceTreePageModel = h5_vm.SelectComputeResourceTreePageModel;
import IPromise = angular.IPromise;

export class SelectComputeResourceTreePageComponent {
   public templateUrl: string;
   public controller: any;
   public controllerAs: string;
   public bindings: any;

   constructor() {
      this.templateUrl = "vm-ui/resources/vm/views/migrationWizard/pages/selectComputeResourceTreePageComponent.html";
      this.controller = SelectComputeResourceTreePageComponentController;
      this.controllerAs = "ctrl";
      this.bindings = {
         wizardConfig: "<",
         migrationWizardManager: "<",
         pageModel: "<",
         destinationTarget: "<"
      };
   }

} // SelectComputeResourceTreePageComponent

class SelectComputeResourceTreePageComponentController {

   public static $inject: string[] = [
      "$scope",
      "defaultUriSchemeUtil",
      "managedEntityConstants",
      "migrationService",
      "compatibilityResultsPresenter",
      "i18nService",
      "dataService",
      "wizardPageService"
   ];

   //
   // Used by the HTML page
   //
   public selectedObjectId?: string;
   public rootObjectId?: string;
   public config: any;
   public compatibilityTitle: string;
   public compatibilityContents: any;
   public treeLabel: string;

   public wizardConfig: any;
   public migrationWizardManager: MigrationWizardManager;
   public pageModel: SelectComputeResourceTreePageModel;
   public destinationTarget: string;

   constructor(
      private $scope: any,
      private defaultUriSchemeUtil: any,
      private managedEntityConstants: any,
      private migrationService: MigrationService,
      private compatibilityResultsPresenter: any,
      private i18nService: any,
      private dataService: any,
      private wizardPageService: any
   ) {
      this.config = {
         preselectRoot: false
      };

      this.compatibilityTitle = this.i18nService.getString(
         "VmUi", "SelectDatastoreProvisioningPage.Compatibility");
      this.treeLabel = this.i18nService.getString(
         "VmUi", "MigrationWizard.resourceListPage.description");
   }

   public $onInit() {
      this.rootObjectId = this.calculateRootObject();
      this.selectedObjectId = this.calculateSelectedObject();
      if (this.selectedObjectId) {
         this.wizardConfig.loading = true;
      }
   }

   public onChange(objectId: string, objectName: string): void {
      this.processSelectedObject(objectId, objectName);
   }

   public onError() {
      this.wizardConfig.loading = false;
   }

   private processSelectedObject(objectId: string, objectName: string): void {
      this.wizardPageService.clearErrors(this.wizardConfig);
      this.pageModel.isValidating = true; // disable next button and add spinner in compatibility view

      this.migrationWizardManager.changeComputeResource({
         id: objectId,
         name: objectName})
      .then((result: MigrationWizardManager$ComputeResourceValidationData) => {

         this.compatibilityResultsPresenter.formatValidationResults(
               result.validationResults,
               true /*includeSuccessContent*/)
         .then((contents: any) => {
            this.compatibilityContents = contents;

            if (this.migrationWizardManager.getIsComputeResourceChanged()) {
               let pageTitle = this.i18nService.getString(
                  "VmUi", "MigrationWizard.resourceListPage.title");

               this.wizardPageService.invalidateNextPages(
                  this.wizardConfig,
                  this.migrationWizardManager.getSelectedMode(),
                  pageTitle
               );
            }

            if (result.isValid) {
               this.pageModel.resource = objectId;
               this.getDatastore(objectId).then((dc: string) => {
                  this.pageModel.datacenter = dc;
               });
            } else {
               this.pageModel.resource = undefined;
               this.pageModel.datacenter = undefined;
            }

         })
         .then(() => {
            this.pageModel.isValidating = false;
         });
      })
      .then(() => {
         this.wizardConfig.loading = false;
      });
   }

   private getDatastore(selectedObjectId: string): IPromise<string> {
      return this.dataService.getProperties(selectedObjectId, ["dc"])
            .then((result: any) => {
               return this.defaultUriSchemeUtil.getVsphereObjectId(result["dc"]);
            });
   }

   private calculateRootObject(): string | undefined {
      // It is essential here that the isXvcMigrationPossible comes precomputed,
      // as the Tree component does not allow for async computing here and setting
      // the root object after that
      if (!this.pageModel.isXvcMigrationPossible) {
         // NOTE speev: Right now we restrict the root object to the VC from which the
         // first VM comes from.
         let firstVmId: string = this.pageModel.vms[0];
         return this.defaultUriSchemeUtil.getRootFolderFromVsphereObjectId(
               firstVmId);
      }

      return undefined;
   }

   private calculateSelectedObject(): string|undefined {
      // We have a user preselection in place - override the destinationTarget
      let preselectedId = this.pageModel.resource || this.pageModel.initialResource;
      if (preselectedId) {
         return preselectedId;
      }

      let destinationTargetId = this.destinationTarget;
      if (destinationTargetId) {
         let destinationTargetType = this.defaultUriSchemeUtil.getEntityType(destinationTargetId);
         switch (destinationTargetType) {
            case this.managedEntityConstants.CLUSTER:
            case this.managedEntityConstants.RESOURCE_POOL:
            case this.managedEntityConstants.V_APP:
            case this.managedEntityConstants.HOST:
               preselectedId = destinationTargetId;
               break;
            default:
               break;
         }
      }

      return preselectedId;
   }

} // class SelectComputeResourceTreePageComponentController

angular.module("com.vmware.vsphere.client.vm").component(
      "selectComputeResourceTreePageComponent",
      new SelectComputeResourceTreePageComponent());

} // namespace
