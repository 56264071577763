(function () {
   'use strict';

   angular.module('com.vmware.vsphere.client.cluster')
         .service('haConfigIssuesService', haConfigIssuesService);

   haConfigIssuesService.$inject = ['i18nService', 'haConfigIssuesConstants'];

   function haConfigIssuesService(i18nService, haConfigIssuesConstants) {

      function getHostRoleFormatted(hostRole) {
         if(hostRole === haConfigIssuesConstants.hostRole.SLAVE){
            return i18nService.getString('ClusterUi', 'ha.config.issues.slave.role');
         }
         return i18nService.getString('ClusterUi', 'ha.config.issues.master.role');
      }

      function getIssueMessageIcon(issueConfig) {
         if(issueConfig.issueCategory === null ||
               issueConfig.issue.fullFormattedMessage === null) {
            return haConfigIssuesConstants.iconClass.NONE;
         }
         if (issueConfig.issueCategory === haConfigIssuesConstants.issueCategory.ERROR) {
            return haConfigIssuesConstants.iconClass.ERROR;
         }
         if (issueConfig.issueCategory ===
               haConfigIssuesConstants.issueCategory.WARNING) {
            return haConfigIssuesConstants.iconClass.WARNING;
         }
         if (issueConfig.issueCategory === haConfigIssuesConstants.issueCategory.INFO) {
            return haConfigIssuesConstants.iconClass.INFO;
         }
         return haConfigIssuesConstants.iconClass.NONE;

      }

      return {
         getIssueMessageIcon: getIssueMessageIcon,
         getHostRoleFormatted: getHostRoleFormatted
      };
   }
})();
