/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace dvs_ui {

   import DvsEditSpanSessionDialogData =
         com.vmware.vsphere.client.h5.network.dvs.portmirroring.model.dialog.DvsEditSpanSessionDialogData;

   export class DvsEditSpanSessionDialogModel {
      public sessionType: string;

      public configVersion: string;

      public dvsUrn: string;

      public pageValidators: { [pageId: string]: DvsSpanSessionPageValidator } = {};

      public propertiesPageModel: DvsSpanSessionPropertiesPageModel =
            new DvsSpanSessionPropertiesPageModel();

      public selectSourcesPageModel: DvsSpanSessionSelectSourcesPageModel =
            new DvsSpanSessionSelectSourcesPageModel();

      public selectDestinationsPageModel: DvsSpanSessionSelectDestinationsPageModel =
            new DvsSpanSessionSelectDestinationsPageModel();

      public selectDestinationUplinksPageModel: DvsSpanSessionDestinationUplinksModel =
            new DvsSpanSessionDestinationUplinksModel();

      public originalData: DvsEditSpanSessionDialogData;
   }
}