(function() {
   'use strict';
   angular.module('com.vmware.vsphere.client.vm').service('vmFactoryService', vmFactoryService);
   vmFactoryService.$inject = [
      'deviceService',
      'guestOsService',
      'VirtualMachineDevices',
      'vmDeviceManagementService',
      'i18nService',
      'deviceTypeConstants',
      'storageProfileService',
      'scheduledTasksDeviceChangesService',
      'vmDeviceInfoService'
   ];

   function vmFactoryService(deviceService,
         guestOsService,
         VirtualMachineDevices,
         vmDeviceManagementService,
         i18nService,
         deviceTypeConstants,
         storageProfileService,
         scheduledTasksDeviceChangesService,
         vmDeviceInfoService
   ) {

      deviceService.addLocalizationKey('VirtualEthernetCard', 'NewEthernet');
      deviceService.addLocalizationKey('VirtualDisk', 'NewDisk');
      deviceService.addLocalizationKey('VirtualSCSIController', 'NewSCSIController');
      deviceService.addLocalizationKey('VirtualSATAController', 'NewSATAController');

      return {
         buildNewVM: buildNewVM,
         buildCloneVM: buildCloneVM,
         applyStorageToVirtualDisks: applyStorageToVirtualDisks,
         buildVirtualDevicesForCreate: buildVirtualDevicesForCreate
      };

      function buildNewVM(vmConfigEnvironment, vmParams, virtualMachineDevices,
            privileges) {
         var id = vmParams.getGosVersion() ? vmParams.getGosVersion().id : vmParams.getGuestOsId();
         var selectedGuestOS = _.find(vmConfigEnvironment.configOption.guestOSDescriptor, function(osDescriptor) {
            return osDescriptor.id === id;
         });
         if (selectedGuestOS === undefined) {
            throw new Error("Could not find Gues OS with ID '" + id + "' among " +
               vmConfigEnvironment.configOption.guestOSDescriptor.length +
               " descriptors.");
         }

         if (!vmParams.getGosVersion()) {
            vmParams.setGosFamilyAndVersion({
               family: selectedGuestOS.family,
               version: selectedGuestOS
            });
         }

         var devices = angular.copy(vmConfigEnvironment.configOption.defaultDevice);

         var scheduledTaskData = vmParams.getScheduledData();
         var useScheduledTask = !_.isEmpty(scheduledTaskData) && scheduledTaskData.useScheduledTaskData;
         var scheduledDataSpec = !_.isEmpty(scheduledTaskData) && scheduledTaskData.spec;
         if (useScheduledTask && scheduledDataSpec) {
            if (scheduledDataSpec.deviceChange) {
               var datastoreRefs = scheduledTaskData.datastoreRefs;
               var virtualDeviceOptionsByDeviceType = buildVirtualDeviceOptionsByDeviceType(vmConfigEnvironment);
               var availableNetworks = vmDeviceInfoService.buildAvailableNetworkList(vmConfigEnvironment.configTarget);
               scheduledTasksDeviceChangesService.addDevices(virtualMachineDevices,
                     scheduledDataSpec.deviceChange, virtualDeviceOptionsByDeviceType,
                     vmParams, availableNetworks, datastoreRefs, scheduledDataSpec.vmProfile);
            }

            return buildDefaultVmConfigForScheduledNewVm(selectedGuestOS,
                  virtualMachineDevices, vmParams, devices, scheduledDataSpec);
         }
         if (_.contains(privileges, h5_vm.Privileges.VM_ADDNEWDISK_PRIVILEGE)) {
            vmDeviceManagementService.createNewDevice(
                  'VirtualDisk',
                  vmConfigEnvironment,
                  selectedGuestOS,
                  virtualMachineDevices,
                  {
                     selectedStorageProfile: vmParams.getSelectedStorageProfileForDisks(),
                     selectedReplicationGroupInfo: vmParams.getSelectedReplicationGroupInfo()
                  });
         }

         vmDeviceManagementService.createNewDevice(
               'VirtualEthernetCard', vmConfigEnvironment, selectedGuestOS, virtualMachineDevices);
         vmDeviceManagementService.createNewDevice(
               'VirtualCdrom', vmConfigEnvironment, selectedGuestOS, virtualMachineDevices);
         var additionalParameters = {
            isCreateMode: true
         };
         vmDeviceManagementService.createNewDevice(
               'VirtualUSBController', vmConfigEnvironment, selectedGuestOS, virtualMachineDevices, additionalParameters);

         return buildDefaultVmConfigForNewVm(selectedGuestOS, virtualMachineDevices, vmParams, devices);
      }

      function buildCloneVM(vmParams, vmConfigContext, virtualMachineDevices, vmOriginalConfig,
            vmStorageProfileAssignments) {
         var guestOs = guestOsService
               .getAppropriateGuestOs(vmConfigContext, { id: vmOriginalConfig.guestId });
         vmParams.setGosFamilyAndVersion({
            family: {
               id: guestOs.family,
               name: i18nService.getString('VmUi',
                     'GeneralOptions.gos_family.' + guestOs.family)
            },
            version: {
               id: guestOs.id,
               name: guestOs.fullName
            }
         });
         vmParams.setVmOriginalConfigSpec(vmOriginalConfig);
         // Unset change version. It should be null when cloning since we are
         // creating a new VM not editing and existing one.
         vmConfigContext.config.changeVersion = null;
         var scheduledTaskData = vmParams.getScheduledData();
         var useScheduledTask = !_.isEmpty(scheduledTaskData) && scheduledTaskData.useScheduledTaskData;
         var scheduledDataSpec = !_.isEmpty(scheduledTaskData) && scheduledTaskData.spec;
         if (useScheduledTask && scheduledDataSpec) {
            var virtualDeviceOptionsByDeviceType = buildVirtualDeviceOptionsByDeviceType(
                  vmConfigContext.environment);
            var availableNetworks = vmDeviceInfoService.buildAvailableNetworkList(
                  vmConfigContext.environment.configTarget);
            if (scheduledDataSpec.location && scheduledDataSpec.location.deviceChange) {
               scheduledTasksDeviceChangesService.addDevices(virtualMachineDevices,
                     scheduledDataSpec.location.deviceChange, virtualDeviceOptionsByDeviceType,
                     vmParams, availableNetworks, {});
            }

            if (scheduledDataSpec.config && scheduledDataSpec.config.deviceChange) {
               scheduledTasksDeviceChangesService.addDevices(virtualMachineDevices,
                     scheduledDataSpec.config.deviceChange, virtualDeviceOptionsByDeviceType,
                     vmParams, availableNetworks, {});
            }

            vmParams.setVmConfigSpec(buildVmConfigForScheduledCloneVm(scheduledDataSpec,
                  vmConfigContext.config));
         } else {
            vmParams.setVmConfigSpec(vmConfigContext.config);
         }
         vmParams.setVirtualMachineDevices(virtualMachineDevices);
         applyStorageToVirtualDisks(
               virtualMachineDevices.existingDevicesOfType(
                     deviceTypeConstants.VIRTUALDISK), vmStorageProfileAssignments, vmParams);
      }

      function applyStorageToVirtualDisks(virtualDisks, vmStorageProfileAssignments, vmParams) {
         var replicationGroupInfo = vmParams.getSelectedReplicationGroupInfo();
         var datastoreDefaultProfile = storageProfileService.getDefaultProfile();

         angular.forEach(virtualDisks, function(virtualDisk) {
            var diskStorageObj = vmParams.getStorageForDisk(virtualDisk.getKey());
            var storageRef;
            if (diskStorageObj && diskStorageObj.dsObject && diskStorageObj.dsObject.storageRef &&
                  vmParams.storageObjectIsStandaloneDs(diskStorageObj.dsObject)) {
               if (virtualDisk.getCurrentDevice()
                     && virtualDisk.getCurrentDevice().backing
                     && virtualDisk.getCurrentDevice().backing.datastore) {
                  storageRef = virtualDisk.getCurrentDevice().backing.datastore;
               } else {
                  storageRef = {};
               }
               storageRef.value = diskStorageObj.dsObject.storageRef.value;
               storageRef.serverGuid = diskStorageObj.dsObject.storageRef.serverGuid;
               storageRef.type = diskStorageObj.dsObject.storageRef.type;
            }

            var diskProfile = datastoreDefaultProfile;

            var diskAssignment = storageProfileService.findDiskAssignment(
                  vmStorageProfileAssignments,
                  virtualDisk.getCurrentDevice());
            if (diskAssignment) {
               diskProfile = {
                  id: diskAssignment.profile.profileId.uniqueId,
                  label: diskAssignment.profile.name
               };
            }
            virtualDisk.updateDatastoreMor(storageRef);
            virtualDisk.updateDiskProfile(diskProfile, replicationGroupInfo);
         });
      }

      function buildVirtualDevicesForCreate(vmConfigEnvironment) {
         var devices = angular.copy(vmConfigEnvironment.configOption.defaultDevice);

         var virtualDeviceOptionsByDeviceType = _.indexBy(
               vmConfigEnvironment.configOption.hardwareOptions.virtualDeviceOption,
               function(deviceOption) {
                  return deviceOption.type.name;
               }
         );

         var virtualMachineDevices = new VirtualMachineDevices(
               devices,
               null /* profileAssignments */,
               null /* profiles */,
               null /* availableNetworks */,
               virtualDeviceOptionsByDeviceType);
         return virtualMachineDevices;
      }

      function buildVirtualDeviceOptionsByDeviceType(vmConfigEnvironment) {
         var virtualDeviceOptionsByDeviceType = _.indexBy(
               vmConfigEnvironment.configOption.hardwareOptions.virtualDeviceOption,
               function(deviceOption) {
                  return deviceOption.type.name;
               }
         );

         return virtualDeviceOptionsByDeviceType;
      }

      function buildDefaultVmConfigForNewVm(selectedGuestOS, virtualMachineDevices, vmParams, devices) {
         var bootOptions = {
            _type: 'com.vmware.vim.binding.vim.vm.BootOptions'
         };
         if (selectedGuestOS.recommendedFirmware === 'efi'
               && selectedGuestOS.defaultSecureBoot) {
            bootOptions.efiSecureBootEnabled = true;
         }
         var numCoresPerSocket = selectedGuestOS.numRecommendedCoresPerSocket ?
            selectedGuestOS.numRecommendedCoresPerSocket : 1;
         return {
            vmConfigSpec: {
               config: {
                  bootOptions: bootOptions,
                  version: vmParams.getVmVersion(),
                  guestId: selectedGuestOS.id,
                  options: {},
                  firmware: selectedGuestOS.recommendedFirmware,
                  hardware: {
                     _type: "com.vmware.vim.binding.vim.vm.VirtualHardware",
                     numCPU: selectedGuestOS.numRecommendedPhysicalSockets ?
                        selectedGuestOS.numRecommendedPhysicalSockets * numCoresPerSocket
                        : numCoresPerSocket,
                     memoryMB: selectedGuestOS.recommendedMemMB,
                     device: devices,
					 numCoresPerSocket: numCoresPerSocket,
                     virtualICH7MPresent: !!selectedGuestOS.ich7mRecommended,
                     virtualSMCPresent: !!selectedGuestOS.smcRecommended
                  },
                  cpuFeatureMask: [],
                  cpuAllocation: {
                     _type: "com.vmware.vim.binding.vim.ResourceAllocationInfo",
                     reservation: 0,
                     limit: -1,
                     shares: {
                        _type: "com.vmware.vim.binding.vim.SharesInfo",
                        shares: 1000,
                        level: 'normal'
                     }
                  },
                  memoryAllocation: {
                     _type: "com.vmware.vim.binding.vim.ResourceAllocationInfo",
                     reservation: 0,
                     limit: -1,
                     shares: {
                        _type: "com.vmware.vim.binding.vim.SharesInfo",
                        shares: selectedGuestOS.recommendedMemMB * 10,
                        level: 'normal'
                     }
                  },
                  memoryReservationLockedToMax: false,
                  cpuAffinity: {
                     _type: "com.vmware.vim.binding.vim.vm.AffinityInfo",
                     affinitySet: []
                  },
                  files: {
                     _type: "com.vmware.vim.binding.vim.vm.FileInfo",
                     vmPathName: !vmParams.storageObjectIsPod() ? "[" + vmParams.getStorageObject().name + "]" : null
                  },
                  tools: {
                     _type: "com.vmware.vim.binding.vim.vm.ToolsConfigInfo",
                     afterPowerOn: true,
                     afterResume: true,
                     beforeGuestShutdown: true,
                     beforeGuestStandby: true,
                     toolsUpgradePolicy: "manual"
                  },
                  defaultPowerOps: {
                     _type: "com.vmware.vim.binding.vim.vm.DefaultPowerOpInfo",
                     powerOffType: "preset",
                     suspendType: "preset",
                     resetType: "preset",
                     standbyAction: "checkpoint"
                  },
                  flags: {
                     _type: "com.vmware.vim.binding.vim.vm.FlagInfo",
                     virtualMmuUsage: 'automatic',
                     monitorType: 'release'
                  }
               }
            },
            virtualMachineDevices: virtualMachineDevices
         };
      }

      function buildDefaultVmConfigForScheduledNewVm(selectedGuestOS, virtualMachineDevices,
            vmParams, devices, scheduledDataSpec) {
         var bootOptions = {
            _type: 'com.vmware.vim.binding.vim.vm.BootOptions'
         };
         if (selectedGuestOS.recommendedFirmware === 'efi'
               && selectedGuestOS.defaultSecureBoot) {
            bootOptions.efiSecureBootEnabled = true;
         }
         var memoryMB = selectedGuestOS.recommendedMemMB;
         var virtualICH7MPresent = !!selectedGuestOS.ich7mRecommended;
         var virtualSMCPresent = !!selectedGuestOS.smcRecommended;
         var firmware = selectedGuestOS.recommendedFirmware;
         var memoryAllocation = {
            _type: "com.vmware.vim.binding.vim.ResourceAllocationInfo",
                  reservation: 0,
                  limit: -1,
                  shares: {
               _type: "com.vmware.vim.binding.vim.SharesInfo",
                     shares: selectedGuestOS.recommendedMemMB * 10,
                     level: 'normal'
            }
         };
         var cpuAffinity = scheduledDataSpec.cpuAffinity;
         if (!_.isEmpty(cpuAffinity)) {
            cpuAffinity.affinitySet = cpuAffinity.affinitySet ? cpuAffinity.affinitySet : [];
         }

         var files = scheduledDataSpec.files;
         files.vmPathName = !vmParams.storageObjectIsPod() ? "[" + vmParams.getStorageObject().name + "]" : null;
         if (selectedGuestOS.id === scheduledDataSpec.guestId) {
            bootOptions = scheduledDataSpec.bootOptions;
            memoryMB = scheduledDataSpec.memoryMB;
            virtualICH7MPresent = scheduledDataSpec.virtualICH7MPresent;
            virtualSMCPresent = scheduledDataSpec.virtualSMCPresent;
            memoryAllocation = scheduledDataSpec.memoryAllocation;
            firmware = scheduledDataSpec.firmware;
         }
         return {
            vmConfigSpec: {
               config: {
                  bootOptions: bootOptions,
                  version: vmParams.getVmVersion(),
                  guestId: selectedGuestOS.id,
                  options: {},
                  firmware: firmware,
                  hardware: {
                     _type: "com.vmware.vim.binding.vim.vm.VirtualHardware",
                     numCPU: scheduledDataSpec.numCPUs,
                     memoryMB: memoryMB,
                     device: devices,
                     numCoresPerSocket: scheduledDataSpec.numCoresPerSocket,
                     virtualICH7MPresent: virtualICH7MPresent,
                     virtualSMCPresent: virtualSMCPresent
                  },
                  cpuFeatureMask: !_.isEmpty(scheduledDataSpec.cpuFeatureMask) ?
                        buildCpuFeatureMask(scheduledDataSpec.cpuFeatureMask) : [],
                  cpuHotAddEnabled: scheduledDataSpec.cpuHotAddEnabled,
                  nestedHVEnabled: scheduledDataSpec.nestedHVEnabled,
                  vPMCEnabled: scheduledDataSpec.vPMCEnabled,
                  cpuAllocation: scheduledDataSpec.cpuAllocation,
                  memoryAllocation: memoryAllocation,
                  memoryReservationLockedToMax: scheduledDataSpec.memoryReservationLockedToMax,
                  memoryHotAddEnabled: scheduledDataSpec.memoryHotAddEnabled,
                  cpuAffinity: scheduledDataSpec.cpuAffinity,
                  files: files,
                  tools: scheduledDataSpec.tools,
                  defaultPowerOps: scheduledDataSpec.powerOpInfo,
                  flags: scheduledDataSpec.flags,
                  guestAutoLockEnabled: scheduledDataSpec.guestAutoLockEnabled,
                  maxMksConnections: scheduledDataSpec.maxMksConnections,
                  migrateEncryption: scheduledDataSpec.migrateEncryption,
                  swapPlacement: scheduledDataSpec.swapPlacement,
                  latencySensitivity: scheduledDataSpec.latencySensitivity,
                  extraConfig: scheduledDataSpec.extraConfig,
                  npivTemporaryDisabled: scheduledDataSpec.npivTemporaryDisabled,
                  npivWwnOp: scheduledDataSpec.npivWorldWideNameOp,
                  npivDesiredNodeWwns: scheduledDataSpec.npivDesiredNodeWwns,
                  npivDesiredPortWwns: scheduledDataSpec.npivDesiredPortWwns

               }
            },
            virtualMachineDevices: virtualMachineDevices
         };
      }

      function buildVmConfigForScheduledCloneVm(scheduledDataSpec, originalConfig) {
         var originalConfigCopy = angular.copy(originalConfig);
         if (_.isEmpty(scheduledDataSpec.config)) {
            return originalConfigCopy;
         }

         var scheduledSpecKeys = _.keys(scheduledDataSpec.config);
         var cpuAffinity = scheduledDataSpec.config.cpuAffinity;
         if (!_.isEmpty(cpuAffinity)) {
            cpuAffinity.affinitySet = cpuAffinity.affinitySet ? cpuAffinity.affinitySet : [];
         }

         // populate the unset values for cpuAllocation in the scheduled task data, from the original spec
         mapProperties(originalConfigCopy, scheduledDataSpec.config, 'cpuAllocation');
         // populate the unset values for memoryAllocation in the scheduled task data, from the original spec
         mapProperties(originalConfigCopy, scheduledDataSpec.config, 'memoryAllocation');
         // populate the unset values for flags in the scheduled task data, from the original spec
         mapProperties(originalConfigCopy, scheduledDataSpec.config, 'flags');
         // populate the unset values for bootOptions in the scheduled task data, from the original spec
         mapProperties(originalConfigCopy, scheduledDataSpec.config, 'bootOptions');
         // populate the unset values for tools in the scheduled task data, from the original spec
         mapProperties(originalConfigCopy, scheduledDataSpec.config, 'tools');

         // map all properties of the original Vm and the scheduled data that have the same names
         _.each(scheduledSpecKeys, function(key) {
            if (key !== '_type' && scheduledDataSpec.config[key] !== null &&
                  originalConfigCopy.hasOwnProperty(key)) {
               originalConfigCopy[key] = scheduledDataSpec.config[key];
            }
         });

         // the original config cpuFeatureMask, has a different format than the one
         // saved in the spec, so we need to convert the data
         originalConfigCopy.cpuFeatureMask = [];
         if (!_.isEmpty(scheduledDataSpec.config.cpuFeatureMask)) {
            originalConfigCopy.cpuFeatureMask = buildCpuFeatureMask(
                  scheduledDataSpec.config.cpuFeatureMask);
         }

         // since the hardware properties are not separately saved in hardware property,
         // we need to map them separately
         var originalConfigHardwareKeys = _.keys(originalConfigCopy.hardware);
         _.each(originalConfigHardwareKeys, function(key) {
            if (key !== '_type' && scheduledDataSpec.config[key] !== null &&
                  scheduledDataSpec.config.hasOwnProperty(key)) {
               originalConfigCopy.hardware[key] = scheduledDataSpec.config[key];
            }
         });

         // the power operations are saved in different properties
         // in the config and scheduled data an need to be mapped separately
         var originalPowerOps = _.keys(originalConfigCopy.defaultPowerOps);
         _.each(originalPowerOps, function(key) {
            if (key !== '_type' && scheduledDataSpec.config.powerOpInfo !== null &&
                  scheduledDataSpec.config.powerOpInfo[key] !== null &&
                  scheduledDataSpec.config.powerOpInfo.hasOwnProperty(key)) {
               originalConfigCopy.defaultPowerOps[key] = scheduledDataSpec.config.powerOpInfo[key];
            }
         });

         if (scheduledDataSpec.config.numCPUs !== null) {
            originalConfigCopy.hardware.numCPU = scheduledDataSpec.config.numCPUs;
         }

         if (scheduledDataSpec.config.npivWorldWideNameOp !== null) {
            originalConfigCopy.npivWwnOp = scheduledDataSpec.config.npivWorldWideNameOp;
         }
         return originalConfigCopy;
      }

      function buildCpuFeatureMask(scheduledCpuFeatureMask) {
         var cpuFeatureMask = [];
         _.each(scheduledCpuFeatureMask, function (mask) {
            if (!_.isEmpty(mask.info)) {
               cpuFeatureMask.push(mask.info);
            }
         });

         return cpuFeatureMask;
      }

      /**
       * Checks if the original config property has the same key as the one in the original config,
       * if so replaces and the scheduled task value is empty replaces the scheduled task data
       * with the one from the original config.
       * this is done so that later the whole property could be copied in the original config,
       * and the changed values fron the scheduled task will be preserved
       * @param originalConfig
       * @param scheduledDataSpecConfig
       * @param property
       */
      function mapProperties(originalConfig, scheduledDataSpecConfig, property) {
         var originalConfigKeys = _.keys(originalConfig[property]);
         _.each(originalConfigKeys, function(key) {
            var scheduledDataIsNotSet = scheduledDataSpecConfig[property] !== null &&
                  scheduledDataSpecConfig[property][key] === null &&
                  scheduledDataSpecConfig[property].hasOwnProperty(key);
            if (key !== '_type' &&
                  scheduledDataIsNotSet &&
                  originalConfig[property] && originalConfig[property][key] !== null) {
               scheduledDataSpecConfig[property][key] = originalConfig[property][key];
            }
         });
      }
   }

})();
