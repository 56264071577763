/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace dvs_ui {
   /**
    * Represents a pvlan id (primary or secondary)
    * Applicable when a pvlan id should be edited by value, not by reference
    */

   export class PvlanIdModel {

      public readonly key: number;
      private _val: number;

      constructor(key: number, val: number) {
         this.key = key;
         this._val = val;
      }

      get val(): number {
         return this._val;
      }

      set val(val: number) {
         if (val !== null) {
            if (val > DvsEditPvlanDialogConstants.PVLAN_ID_VALUE_MAX) {
               this._val = DvsEditPvlanDialogConstants.PVLAN_ID_VALUE_MAX;
            } else if (val < DvsEditPvlanDialogConstants.PVLAN_ID_VALUE_MIN) {
               this._val = DvsEditPvlanDialogConstants.PVLAN_ID_VALUE_MIN;
            } else if (val % 1 === 0) {
               // the number is not an integer
               this._val = val;
            }
         }
      }
   }
}