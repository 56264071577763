(function() {
   'use strict';
   angular
      .module('com.vmware.vsphere.client.admin')
      .controller('RolesViewController', RolesViewController);

   RolesViewController.$inject = ['$scope',
                                 'rolesService',
                                 'i18nService',
                                 'vuiConstants',
                                 'clarityModalService',
                                 'createEditRoleWizardService',
                                 'privilegeService',
                                 'userSessionService',
                                 'userPrivilegeService'];

   function RolesViewController ($scope,
                                 rolesService,
                                 i18nService,
                                 vuiConstants,
                                 clarityModalService,
                                 createEditRoleWizardService,
                                 privilegeService,
                                 userSessionService,
                                 userPrivilegeService) {
      var self = this;
      self.hideUsage = true;
      self.hideDescription = false;
      self.hidePrivileges = true;
      self.isAuthorized = false;
      self.selectedDescription = '';
      self.privilegeTreeForRole = [];
      self.originalPrivileges = [];
      self.selectedPriviledgeIds = [];
      self.selectedPriviledge = {};
      self.datagridRolesOptions = {
         actionBarOptions: {
            actions: [
               {
                  id: 'add-role-action',
                  iconClass: 'vsphere-icon-toolbar-add',
                  tooltipText: i18nService.getString('Common', 'rolesAction.create.desc'),
                  enabled: self.isAuthorized,
                  onClick: addRole
               }, {
                  id: 'clone-role-action',
                  iconClass: 'vsphere-icon-roles-clone',
                  tooltipText: i18nService.getString('Common', 'rolesAction.clone.desc'),
                  enabled: false,
                  onClick: cloneRole
               }, {
                  id: 'edit-role-action',
                  iconClass: 'vsphere-icon-toolbar-edit',
                  tooltipText: i18nService.getString('Common', 'rolesAction.edit.desc'),
                  enabled: false,
                  onClick: editRole
               }, {
                  id: 'delete-role-action',
                  iconClass: 'vsphere-icon-toolbar-remove',
                  tooltipText: i18nService.getString('Common', 'rolesAction.remove.desc'),
                  enabled: false,
                  onClick: deleteRole
               }
            ]
         },
         columnDefs: [
            {
               field: 'label'
            }
         ],
         id: 'Test',
         selectionMode: vuiConstants.grid.selectionMode.SINGLE,
         selectedItems: [],
         resizable: true,
         height: '100%'
      };

      self.datagridPermissionsOptions = {
         columnDefs: [
            {
               displayName: i18nService.getString('Common', 'PermissionView.defined'),
               field: 'definedIn',
               attributes: {
                  style: "text-align: right; font-size: 14px"
               }
            },
            {
               displayName: i18nService.getString('Common', 'PermissionView.userGroupName'),
               field: 'userGroup'
            },
            {
               displayName: i18nService.getString('Common', 'usergrid.header.propagate'),
               field: 'propagate'
            }
         ],
         resizable: true,
         reorderable: false,
         height: '100%',
         attributes: {
            class: "table-cell",
            style: "text-align: right; font-size: 14px"
         }
      };

      userSessionService.getAllServersInfo().then(function(data) {
         self.vCenters = _.filter(data.serversInfo, function(server){ return server.errorCode === 'noError'; });
         self.selectedVc = self.vCenters[0];
      });
      self.fetchRolesData = fetchRolesData;

      $scope.$watch(function() {
         return self.datagridRolesOptions.selectedItems;
      }, function(selectedItems) {
         self.datagridRolesOptions.actionBarOptions.actions[1].enabled=false;
         self.datagridRolesOptions.actionBarOptions.actions[2].enabled=false;
         self.datagridRolesOptions.actionBarOptions.actions[3].enabled=false;
         if (selectedItems.length === undefined || selectedItems.length === 0) {
            return;
         }
         self.selectedPriviledgeIds = JSON.parse(JSON.stringify(selectedItems[0].privilegeIds));
         self.datagridRolesOptions.actionBarOptions.actions[1].enabled = self.isAuthorized;
         var selectedId = selectedItems[0].id;
         self.selectedDescription = selectedItems[0].summary;
         if(selectedId !== '-1' && selectedId !== '-2' && selectedId !== '-5') {
            self.datagridRolesOptions.actionBarOptions.actions[2].enabled = self.isAuthorized;
            self.datagridRolesOptions.actionBarOptions.actions[3].enabled = self.isAuthorized;
         }
         if(self.selectedVc){
             rolesService.getRoleUsages(selectedId, self.selectedVc).then(function(usages) {
                 self.datagridPermissionsOptions.data = usages;
             });
         }
         self.privilegeTreeForRole = angular.copy(self.originalPrivileges);
         setSelectedIds(self.privilegeTreeForRole);
      });

      self.showUsage = function() {

         self.hideUsage = false;
         self.hideDescription = true;
         self.hidePrivileges = true;
      };

      self.showDescription = function() {
         self.hideUsage = true;
         self.hideDescription = false;
         self.hidePrivileges = true;
      };

      self.showPrivileges = function() {
         self.hideUsage = true;
         self.hideDescription = true;
         self.hidePrivileges = false;
      };

      function editRole() {
         var selectedRole = self.datagridRolesOptions.selectedItems[0];
         var roles = self.datagridRolesOptions.data;
         createEditRoleWizardService.showWizard(roles, selectedRole);
      }

      function cloneRole() {
         var selectedRole = self.datagridRolesOptions.selectedItems[0];
         var roleName = i18nService.getString('CommonModuleUi', 'cloneOf', selectedRole.label);
         var description = selectedRole.summary;

         var title =  i18nService.getString('CommonModuleUi', 'cloneRole');
         var actionEval = {
            action: { label: title }
         };
         var modalOptions =  {
            title: title,
            actionEval: actionEval,
            size: 'lg',
            contentTemplate: 'admin-ui/resources/admin/views/cloneRole.html',
            dialogData: {
               roleName: roleName,
               description: description,
               selectedPrivilegeIds: selectedRole.privilegeIds,
               roles: self.datagridRolesOptions.data
            }
         };
         clarityModalService.openOkCancelModal(modalOptions);
      }


      function setSelectedIds(nodes) {
         nodes.forEach(function(childNode){
            if(childNode.childNodes.length === 0 && self.selectedPriviledgeIds.indexOf(childNode.id) >= 0) {
               childNode.selected = true;
               return;
            }
            setSelectedIds(childNode.childNodes);
            if (childNode.childNodes.length) {
               toggleParent(childNode);
            }
         });

      }

      function toggleParent(model) {
         var selected = model.childNodes.filter(function(child) {
            return child.selected;
         });

         model.selected = selected.length > 0;
      }

      function addRole() {
         var roles = self.datagridRolesOptions.data;
         createEditRoleWizardService.showWizard(roles);
      }

      function deleteRole() {
         var modalOptions = {
            title: i18nService.getString('Common','rolesAction.remove.dialog.title'),
            message: i18nService.getString('Common','rolesAction.remove.warning'),
            icon: 'icon-warning-32',
             defaultButton: 'close',
            submit: confirmDeleteRole,
            preserveNewlines: true
         };
         clarityModalService.openConfirmationModal(modalOptions);
      }

      function confirmDeleteRole() {
         rolesService.deleteRole(self.datagridRolesOptions.selectedItems[0].id).then(function() {
            fetchRolesData();
         });
      }

      function fetchRolesData() {
         rolesService.getRoles().then(function(roles) {
            self.datagridRolesOptions.data = roles;
         });

         privilegeService.getPrivilegeTree().then(function(data) {
            self.originalPrivileges = data;
            self.privilegeTreeForRole = angular.copy(self.originalPrivileges);
            setSelectedIds(self.privilegeTreeForRole);
         });
      }

      self.checkPrivilege = function () {
         return userPrivilegeService.checkUserPrivilege('urn:vri:acl:RootPermissionNode:root', 'Authorization.ModifyRoles')
            .then(function (isAuthorized) {
               self.isAuthorized = isAuthorized;
               self.datagridRolesOptions.actionBarOptions.actions[0].enabled = isAuthorized;
            });
      };

      $scope.$on('refreshRoles', function() {
         fetchRolesData();
      });

      self.checkPrivilege();
      fetchRolesData();
   }
}());
