/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace network_lib_ui {
   /**
    * A component that performs validation of IPv4 specific inputs.
    * The component currently supports validation of either IPv4 Address, or Subnet mask.
    *
    * As validation mechanism the component integrates with the <vx-validate> directive.
    *
    * Require:
    *    ngModel: a ng-model directive is required to be able to bind the input to a
    *             form in a case the user depends on some ngForm functionality
    *             such as the $dirty flag.
    *
    * Input params:
    *    type: a constant that represents the input type the component should handle.
    *          Currently the supported types are either IPv4, or Subner mask
    *          (see class StaticIpInputTypes)
    *
    *    enabled: a boolean value indicating whether the inputs are enabled, or disabled
    *             thus whether the validation should be performed, or not.
    *
    *    name: a string value used as a custom input's name. If not provided, a default
    *          one is set.
    *
    *    message: a string value used as a custom validation message. If not provided, a
    *             default one is set.
    *
    *    validate: an optional callback function being invoked from outside in case the
    *              consumer wants to trigger the input validation skipping
    *              the empty-val-check.
    */

   export class IpAddressInputComponent {

      public bindings: any;
      public require: any;
      public controller: any;
      public templateUrl: string;

      constructor() {
         this.require = {
            model: "ngModel"
         };

         this.bindings = {
            type: "<",
            enabled: "<",
            name: "@",
            message: "<",
            validate: "=?",
            onValidationComplete: "&",
            directionClass: "<?",
            inputId: "<?",
            labelledby: '@?'
         };
         this.controller = IpAddressInputController;
         this.templateUrl = "network-lib-ui/resources/network-lib/components/ipv4Address/ipAddressInput.html";
      }
   }

   export class StaticIpInputTypes {
      public IPV4: StaticIpv4InputTypes = new StaticIpv4InputTypes();
      public IPV6: StaticIpv6InputTypes = new StaticIpv6InputTypes();
   }

   class IpAddressInputController {

      static $inject = [
         "vxValidatorFactory", "ipParserService", "i18nService",
         "staticIpInputTypes", "$log"
      ];

      private validator: any;
      private validateFunction: Function;
      private validateEmptyValue: boolean;

      public type: string;
      public value: string;
      public enabled: boolean;
      public name: string;
      public message: string;
      public validate: Function;
      public onValidationComplete: Function;

      public model: ng.INgModelController;

      constructor(private vxValidatorFactory: any,
                  private ipParserService: any,
                  private i18nService: any,
                  private staticIpInputTypes: StaticIpInputTypes,
                  private $log: any) {
         this.validator = this.vxValidatorFactory.create();
      }

      private $onInit(): void {
         this.name = this.name || "ipv4AddressInput";
         this.validateEmptyValue = false;
         this.setValidateFunctionBasedOnType();
         this.model.$render = () => {
            this.value = this.model.$viewValue;
         };
         this.validate = () => {
            this.validateEmptyValue = true;
            return this.validator.validate.call(this.validator);
         };
      };

      private $onChanges(changedObj: any): void {
         if (changedObj.enabled) {
            this.validateInput();
         } else if (changedObj.type) {
            this.setValidateFunctionBasedOnType();
         }
      }

      private onChange(): void {
         this.model.$setViewValue(this.value);
      }

      private onBlur(): void {
         this.validateInput();
      }

      private setValidateFunctionBasedOnType() {
         if (this.type === this.staticIpInputTypes.IPV4.IP_ADDRESS) {
            this.validateFunction = this.validateIPv4Address.bind(this);
            this.message = this.message || this.i18nService.getString("NetworkLibUi", "invalidIpAddress");
         } else if (this.type === this.staticIpInputTypes.IPV4.SUBNET_MASK) {
            this.validateFunction = this.validateSubnetMask.bind(this);
            this.message = this.message ||
               this.i18nService.getString("NetworkLibUi", "invalidSubnetMask");
         } else if (this.type === this.staticIpInputTypes.IPV4.DEFAULT_GATEWAY) {
            this.validateFunction = this.validateIPv4Address.bind(this);
            this.message = this.message ||
                  this.i18nService.getString("NetworkLibUi", "invalidIpv4DefaultGateway");
         } else if (this.type === this.staticIpInputTypes.IPV6.IPV6_ADDRESS) {
            this.validateFunction = this.validateIPv6Address.bind(this);
            this.message = this.message ||
               this.i18nService.getString("NetworkLibUi", "invalidIpv6Address");
         } else if (this.type === this.staticIpInputTypes.IPV6.IPV6_ADDRESS_LINK_LOCAL) {
            this.validateFunction = this.validateLinkLocalIPv6Address.bind(this);
            this.message = this.message ||
               this.i18nService.getString("NetworkLibUi", "invalidIpv6LinkLocalAddress");
         } else if (this.type === this.staticIpInputTypes.IPV6.DEFAULT_GATEWAY) {
            this.validateFunction = this.validateIPv6Address.bind(this);
            this.message = this.message ||
                  this.i18nService.getString("NetworkLibUi", "invalidIpv6DefaultGateway");
         } else {
            this.$log.warn("IpAddressInputController#type " + this.type + " has no validations defined");
         }
      }

      private validateInput(): void {
         let errors = this.validator.validate();
         this.onValidationComplete({errors: errors});
      }

      private validateIPv4Address(): string[] {
         if (this.triggerValidation()
            && !this.ipParserService.isIpv4AddressValid(this.value)) {
            return [this.message];
         }
         this.validateEmptyValue = false;
         return [];
      }

      private validateIPv6Address(): string[] {
         if (this.triggerValidation()
            && !this.ipParserService.isIpv6AddressValid(this.value)) {
            return [this.message];
         }
         this.validateEmptyValue = false;
         return [];
      }

      private validateLinkLocalIPv6Address(): string[] {

         if (this.triggerValidation()
            && (!this.ipParserService.isIpv6AddressValid(this.value) || !this.isLinkLocalIPv6Address(this.value) )) {
            return [this.message];
         }
         this.validateEmptyValue = false;
         return [];

      }

      private isLinkLocalIPv6Address(address: string): boolean {
         if (!address || address.length < 4) {
            return false;
         }
         let addressBegining = parseInt(address.substr(0, 4), 16);
         return (addressBegining & 0xffc0) === 0xfe80;
      }

      private validateSubnetMask(): string[] {
         if (this.triggerValidation() && !this.ipParserService.isSubnetMaskValid(this.value)) {
            return [this.message];
         }
         this.validateEmptyValue = false;
         return [];
      }

      // Decide whether the value is empty or not.
      private triggerValidation(): boolean {
         return this.enabled && (this.validateEmptyValue || !!this.value);
      }
   }

   class StaticIpv4InputTypes {
      public IP_ADDRESS: string = "ipv4Address";
      public SUBNET_MASK: string = "subnetMask";
      public DEFAULT_GATEWAY: string = "defaultGateway";
   }

   class StaticIpv6InputTypes {
      public IPV6_ADDRESS: string = "ipv6Address";
      public IPV6_ADDRESS_LINK_LOCAL: string = "ipv6AddressLinkLocal";
      public DEFAULT_GATEWAY: string = "defaultIpv6Gateway";
   }

   angular.module("com.vmware.vsphere.client.networkLibUi")
      .constant("staticIpInputTypes", new StaticIpInputTypes());


   angular.module("com.vmware.vsphere.client.networkLibUi")
      .component("ipAddressInput", new IpAddressInputComponent());
}
