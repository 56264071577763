angular.module('com.vmware.vsphere.client.vm').service('VmProvisioningComputeResourcePageService', [
   'i18nService',
   'VmProvisioningComputeResourcePageModel',
   'vmVuiWizardPageBuilder',
   function (i18nService, VmProvisioningComputeResourcePageModel, vmVuiWizardPageBuilder) {
      return {
         build: function (wizardScope, preselectedComputeResource) {
            var computeResourcePageModel = new VmProvisioningComputeResourcePageModel(
               wizardScope.vmParams,
               preselectedComputeResource,
               wizardScope.wizardViewData,
               wizardScope.config,
               wizardScope.scheduleTaskData
            );
            wizardScope.computeResourcePageModel = computeResourcePageModel;

            return vmVuiWizardPageBuilder.buildVuiWizardPage(wizardScope.config, {
               title: i18nService.getString('VmUi', 'SelectResourcePoolProvisioningPage.Title'),
               description: i18nService.getString('VmUi', 'SelectResourcePoolProvisioningPage.Description'),
               contentUrl: 'vm-ui/resources/vm/views/createVmWizard/vmProvisioningComputeResource.html',
               model: computeResourcePageModel
            });
         }
      };
   }
]);

