/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace dvs_ui {

   export class DvsEditSettingsDialogManagerFactory {

      public static $inject = [
         "vuiConstants",
         "mutationService"
      ];

      constructor(private vuiConstants: any,
                  private mutationService: any) {
      }

      public createManager(dialogScope: any): DvsEditSettingsDialogManager {
         return new DvsEditSettingsDialogManager(dialogScope, this.vuiConstants, this.mutationService);
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .service("dvsEditSettingsDialogManagerFactory", DvsEditSettingsDialogManagerFactory);
}
