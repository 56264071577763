/* Copyright 2016-2017 VMware, Inc. All rights reserved. -- VMware Confidential */

/*
 * Category service
 */
(function () {
   'use strict';
   angular
      .module('com.vmware.vsphere.client.commonModule')
      .service('categoryService', categoryService);

   categoryService.$inject = ['$http', 'dataService','defaultUriSchemeUtil'];

   var CATEGORIES_URL = 'tagging-ui/ctrl/categories';

   function categoryService($http, dataService, defaultUriSchemeUtil) {
      return {
         getAll: getAll,
         addCategory: addCategory,
         deleteCategory: deleteCategory,
         getCategoryDetails: getCategoryDetails,
         editCategory: editCategory
      };

      function getAll() {
         return $http.get(CATEGORIES_URL)
            .then(function(response) {
               return response.data;
            });
      }

      function addCategory(name, description, cardinality, associableEntityTypes) {
         var categorySpec = {
            categoryName: name,
            categoryDescription: description,
            multipleCardinality: cardinality,
            associableEntityTypes: associableEntityTypes
         };
         return $http.post(CATEGORIES_URL, categorySpec);
      }

      function deleteCategory(categoryMoRef) {
         return $http.delete(CATEGORIES_URL + "/" + categoryMoRef.value);
      }

      function getCategoryDetails(categoryMoRef) {
         return dataService.getProperties(defaultUriSchemeUtil.createVmomiUri(categoryMoRef.type,
               categoryMoRef.value,categoryMoRef.serverGuid), ['name','childTags']);
      }

      function editCategory(categoryMoRef, name, description, cardinality, associableEntityTypes) {
         var categorySpec = {
            categoryName: name,
            categoryDescription: description,
            multipleCardinality: cardinality,
            associableEntityTypes: associableEntityTypes
         };
         return $http.put(CATEGORIES_URL + "/" + categoryMoRef.value, categorySpec);
      }
   }
}());
