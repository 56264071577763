module ds_cluster_ui {

   import IPromise = angular.IPromise;

   class AddSdrsRuleController {

      public static $inject = ["sdrsRulesService", "dsClusterConstants", "$scope",
         "vuiConstants", "$q", "i18nService", "defaultUriSchemeUtil", "mutationService",
         "$rootScope"];
      public spec: any = {};
      public typeOptions: any = {};
      public ruleDescription: string;
      private objectId: string;
      private ruleInfo: any = null;
      public mode: string;
      public editMode: string;

      constructor(private sdrsRulesService: SdrsRulesService,
            private dsClusterConstants: any, private $scope: any,
            private vuiConstants: any, private $q: any, private i18nService: any,
            private defaultUriSchemeUtil: any, private mutationService: any,
            $rootScope: any) {

         this.mode = $scope.modalOptions.dialogData.operationType;
         this.objectId = $rootScope._route.objectId;
         this.editMode = dsClusterConstants.operationType.EDIT;
         this.typeOptions = this.sdrsRulesService.getSdrsRuleTypes();
         this.initSpecValues();

         $scope.$watch(() => {
            return this.spec.ruleType;
         }, (ruleType: any): void => {
            if (this.mode === dsClusterConstants.operationType.ADD) {
               this.spec.ruleMembersData = [];
            }

            if (ruleType && ruleType.id === dsClusterConstants.ruleSpec.VM_ANTI_AFFINITY_TYPE) {
               this.ruleDescription = this.i18nService.getString(
                     "Common", "sdrs.rules.config.description.VM_ANTIAFFINITY_RULE_TYPE");
            } else if (ruleType && ruleType.id ===
                  dsClusterConstants.ruleSpec.VMDK_ANTI_AFFINITY_TYPE) {
               this.ruleDescription = this.i18nService.getString("Common",
                     "sdrs.rules.config.description.VIRTUAL_DISK_ANTI_AFFINITY_RULE_TYPE");
            } else {
               this.ruleDescription = "";
            }
         });

         $scope.modalOptions.onSubmit = () => this.onCommit();
      }

      private initSpecValues(): void {
         this.spec = {
            name: "",
            ruleType : {
               id: this.dsClusterConstants.ruleSpec.VM_ANTI_AFFINITY_TYPE
            },
            enabled: true,
            ruleMembersData: []
         };

         if (this.mode === this.dsClusterConstants.operationType.EDIT) {
            let selectedRule: any = this.$scope.modalOptions.dialogData.context.selectedRule;
            this.spec.key = selectedRule.rule.key;
            this.spec.name = selectedRule.rule.name;
            this.spec.enabled = selectedRule.rule.enabled;
            this.spec.ruleType = _.find(this.typeOptions,
                  (elem: any) => elem.id === selectedRule.rule._type);
            this.spec.ruleMembersData =
                  this.$scope.modalOptions.dialogData.context.selectedRuleDetails.data;
            this.spec.selectedRuleDetails = this.$scope.modalOptions.dialogData.context
                  .selectedRuleDetails.formattedData();

            if (selectedRule.vm !== null) {
               this.spec.selectedVm = selectedRule.vm;
               this.spec.selectedVm.id =
                     this.defaultUriSchemeUtil.getVsphereObjectId(selectedRule.vm.provider);
            }
         }
      }

      private validate(): IPromise<boolean> {
         if (this.spec.name) {
            if ((/^\s*$/gi).test(this.spec.name)) {
               this.$scope.modalOptions.alerts = [{
                  text: this.i18nService.getString(
                        "Common", "sdrs.rules.config.error.nameEmpty"),
                  type: this.vuiConstants.validationBanner.type.ERROR
               }];
               this.$scope.modalOptions.isAlertClosed = false;

               return this.$q.reject();
            } else if (this.spec.name.length > 80) {
               this.$scope.modalOptions.alerts = [{
                  text: this.i18nService.getString(
                        "Common", "sdrs.rules.config.error.nameTooLong", "80"),
                  type: this.vuiConstants.validationBanner.type.ERROR
               }];
               this.$scope.modalOptions.isAlertClosed = false;

               return this.$q.reject();
            }
         } else {
            this.$scope.modalOptions.alerts = [{
               text: this.i18nService.getString(
                     "Common", "sdrs.rules.config.error.nameEmpty"),
               type: this.vuiConstants.validationBanner.type.ERROR
            }];
            this.$scope.modalOptions.isAlertClosed = false;

            return this.$q.reject();
         }

         if (this.spec.ruleType.id ===
               this.dsClusterConstants.ruleSpec.VMDK_ANTI_AFFINITY_TYPE &&
               !this.spec.selectedVm) {
            this.$scope.modalOptions.alerts = [{
               text: this.i18nService.getString("Common",
                     "sdrs.rules.config.error.vmNameEmpty"),
               type: this.vuiConstants.validationBanner.type.ERROR
            }];
            this.$scope.modalOptions.isAlertClosed = false;

            return this.$q.reject();
         }

         if (this.spec.ruleMembersData.length <
               this.dsClusterConstants.affinityRuleMinimumMembers) {
            let ruleMembersValidationKey: string;
            if (this.spec.ruleType.id ===
                  this.dsClusterConstants.ruleSpec.VM_ANTI_AFFINITY_TYPE) {
               ruleMembersValidationKey =
                     "sdrs.rules.config.error.lessThenMinVmRuleMembers";
            } else {
               ruleMembersValidationKey =
                     "sdrs.rules.config.error.lessThenMinVmdkRuleMembers";
            }

            this.$scope.modalOptions.alerts = [{
               text: this.i18nService.getString("Common", ruleMembersValidationKey,
                           this.dsClusterConstants.affinityRuleMinimumMembers),
               type: this.vuiConstants.validationBanner.type.ERROR
            }];
            this.$scope.modalOptions.isAlertClosed = false;

            return this.$q.reject();
         }


         let selectedVmRef: Object = this.spec.selectedVm ?
               this.defaultUriSchemeUtil.getManagedObjectReference(this.spec.selectedVm.id) :
               null;

         let key: any = (this.mode === this.dsClusterConstants.operationType.ADD) ?
               null : this.spec.key;
         let ruleMembers: any[] = this.sdrsRulesService.getRuleMemberRefs(this.spec);
         this.ruleInfo = this.sdrsRulesService.buildSdrsRuleInfo(
               this.spec.ruleType.id,
               this.spec.name,
               this.spec.enabled,
               ruleMembers,
               this.spec.operation,
               key,
               selectedVmRef);

         let validationPromise: any =
               this.mutationService.validate(this.objectId,
                     this.dsClusterConstants.SDRS_RULE_INFO_SPEC,
                     this.ruleInfo
               ).then((validationResult: any) => {
                  if (!!validationResult && validationResult.result) {
                     if (!this.handleNameValidation(validationResult)) {
                        return this.$q.reject();
                     }

                     if (!this.handleConflictingRulesValidation(validationResult)) {
                        return this.$q.reject();
                     }

                     if (!this.handleConflictingOverridesValidation(validationResult)) {
                        return this.$q.reject();
                     }

                     if (!this.handleConflictingDscDefVmAffVmsValidation(
                           validationResult)) {
                        return this.$q.reject();
                     }
                  }

                  return this.$q.resolve();
               });

         return validationPromise;
      }

      private handleNameValidation(validationResult: any): boolean {
         if (validationResult.result.nameIsDuplicated) {
            this.$scope.modalOptions.alerts = [{
               text: this.i18nService.getString("Common",
                     "sdrs.rules.config.duplicated.name.error.message.format",
                     this.spec.name),
               type: this.vuiConstants.validationBanner.type.ERROR
            }];

            this.$scope.modalOptions.isAlertClosed = false;

            return false;
         }

         return true;
      }

      private handleConflictingRulesValidation(validationResult: any): boolean {
         if (validationResult.result.conflictingRules.length > 0) {
            if (this.spec.enabled) {
               let alerts: any[] = [];
               _.each(validationResult.result.conflictingRules,
                     (conflictingRule: any) => {
                        alerts.push({
                           text: this.i18nService.getString("Common",
                                 "sdrs.rules.config.rule.conflict.error.message.format",
                                 conflictingRule.name),
                           type: this.vuiConstants.validationBanner.type.ERROR
                        });
                     });
               this.$scope.modalOptions.alerts = alerts;
               this.$scope.modalOptions.isAlertClosed = false;
               this.spec.enabled = false;

               return false;
            }
         }

         return true;
      }

      private handleConflictingOverridesValidation(validationResult: any): boolean {
         if (validationResult.result.conflictingVmsOverrides.length > 0) {
            let alerts: any[] = [];
            _.each(validationResult.result.conflictingVmsOverrides,
                  (conflictingVm: any) => {
                     let vmName: string = conflictingVm ?
                           conflictingVm.vmName : "";
                     let ruleType: string = this.ruleInfo.ruleType;
                     if (this.dsClusterConstants.ruleTypes.VMDK_ANTI_AFFINITY_TYPE ===
                           ruleType) {
                        alerts.push({
                           text: this.i18nService.getString("Common",
                                 "sdrs.rules.config.keep.vmdk.together.conflict.error.message.format",
                                 vmName),
                           type: this.vuiConstants.validationBanner.type.ERROR
                        });
                     } else if (this.dsClusterConstants.ruleTypes.VM_ANTI_AFFINITY_TYPE ===
                           ruleType) {
                        alerts.push({
                           text: this.i18nService.getString("Common",
                                 "sdrs.rules.config.do.not.keep.vmdk.together.conflict.error.message.format",
                                 vmName),
                           type: this.vuiConstants.validationBanner.type.ERROR
                        });
                     }
                  });
            this.$scope.modalOptions.alerts = alerts;
            this.$scope.modalOptions.isAlertClosed = false;
            this.spec.enabled = false;

            return false;
         }

         return true;
      }

      private handleConflictingDscDefVmAffVmsValidation(validationResult: any): boolean {
         if (validationResult.result.conflictingDscDefVmAffVms.length > 0) {
            let ruleType: string = this.ruleInfo.ruleType;

            if (this.dsClusterConstants.ruleTypes.VM_ANTI_AFFINITY_TYPE ===
                  ruleType) {
               if (validationResult.result.conflictingDscDefVmAffVms.length ===
                     this.spec.ruleMembersData.length) {
                  this.$scope.modalOptions.alerts = [{
                     text: this.i18nService.getString("Common",
                           "sdrs.rules.config.conficts.dsClusterDefVmAff.allVms"),
                     type: this.vuiConstants.validationBanner.type.ERROR
                  }];
               } else {
                  let alerts: any[] = [];
                  let ruleVmsMapByRef: any = {};
                  _.each(this.spec.ruleMembersData, (vmData: any) => {
                     ruleVmsMapByRef[vmData.id] = vmData.name;
                  });

                  _.each(validationResult.result.conflictingDscDefVmAffVms,
                        (conflictVm: any) => {
                           alerts.push({
                              text: this.i18nService.getString("Common",
                                    "sdrs.rules.config.conficts.dsClusterDefVmAff.perVm.format",
                                    ruleVmsMapByRef[this.defaultUriSchemeUtil
                                          .getVsphereObjectId(conflictVm)]),
                              type: this.vuiConstants.validationBanner.type.ERROR
                           });
                        });
                  this.$scope.modalOptions.alerts = alerts;
               }
            }

            this.$scope.modalOptions.isAlertClosed = false;
            this.spec.enabled = false;

            return false;
         }

         return true;
      }

      private onCommit(): any {
         this.$scope.modalOptions.alerts = [];
         this.$scope.modalOptions.isAlertClosed = true;

         return this.validate()
               .then(() => {
                  let mode: string =
                        (this.mode === this.dsClusterConstants.operationType.ADD) ?
                              "create" : "apply";
                  let ruleSpec: any = this.sdrsRulesService.buildSdrsRuleSpec(
                        this.ruleInfo, mode);

                  this.mutationService.apply(
                        this.objectId,
                        this.dsClusterConstants.SDRS_RULE_SPEC,
                        ruleSpec
                  );
                  return true;
               })
               .catch(function () {
                  return false;
               });
      }
   }

   angular.module("com.vmware.vsphere.client.dsCluster")
         .controller("AddSdrsRuleController", AddSdrsRuleController);
}
