/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace h5_vm {

   import ManagedObjectReference = com.vmware.vim.binding.vmodl.ManagedObjectReference;
   import IQService = angular.IQService;
   export class MigrateFinishPageComponent {
      public templateUrl: string;
      public controller: any;
      public controllerAs: string;
      public bindings: any;

      constructor() {
         this.templateUrl = "vm-ui/resources/vm/views/migrationWizard/pages/migrateFinishPageComponent.html";
         this.controller = MigrateFinishPageController;
         this.controllerAs = "ctrl";
         this.bindings = {
            migrationWizardManager: "<",
            wizardConfig: "<",
            pageModel: "<",
            destinationTarget: "<"
         };
      }
   }

   export class MigrateFinishPageController {

      public static $inject: string[] = [
         "$scope",
         "i18nService",
         "$q",
         "datastoreRecommendationsDialogService",
         "datastoreFaultsDialogService",
         "defaultUriSchemeUtil",
         "storageProfileService",
         "vmPlacementRecommendationDialogService",
         "vmPlacementRecommendationFormatter",
         "vmPlacementFaultFormatter",
         "wizardPageService",
         "spbmReplicationGroupInfoService"
      ];

      public i18nService: any;
      public pageModel: any;
      public migrationWizardManager: MigrationWizardManager;
      public wizardConfig: any;
      public destinationTarget: any;

      // Caching the promise for retrieving formatted xdrs recommendations/faults.
      // Recommendations/faults are formatted when xdrs recommendations/faults link
      // is clicked.
      public xdrsFormattedRecommendations: any;
      public xdrsFormattedFaults: any;

      public vmText: string;
      public openXdrsFaults: any;
      public profileLabel: any;
      public replicationGroupLabel: string;
      public showSdrsSummary: any;
      public openXdrsRecommendations: any;
      public migrationTypeTitle: string;

      constructor(private $scope: any,
            i18n: any,
            private $q: IQService,
            private datastoreRecommendationsDialogService: any,
            private datastoreFaultsDialogService: any,
            private defaultUriSchemeUtil: {
               getVsphereObjectId(resourceObject: any): string
            },
            private storageProfileService: any,
            private vmPlacementRecommendationDialogService: any,
            private vmPlacementRecommendationFormatter: any,
            private vmPlacementFaultFormatter: any,
            private wizardPageService: any,
            private spbmReplicationGroupInfoService: any) {
         this.i18nService = i18n.getString;
      }

      public $onInit(): void {
         this.setIsLoading(true);
         this.$q.all({
            recommendationsCompleted: this.migrationWizardManager.fetchRecommendations(),
            namesData: this.migrationWizardManager.requestFolderData()
         }).then(() => {
            this.initialize();
         }).then(() => {
            this.setIsLoading(false);
         });
      }

      public initialize(): void {
         this.pageModel = this.migrationWizardManager.getFinishPageState();

         this.migrationTypeTitle = this.i18nService("VmUi", this.pageModel.migrationType);

         if (this.pageModel.xdrsRecommendationService &&
               this.pageModel.xdrsRecommendationService.hasInitErrors()) {
            this.wizardPageService.markPageIncompleteWithError(
                  this.wizardConfig,
                  this.i18nService("VmUi", "MigrationWizard.readyPage.error.xdrs.retrievalFailed")
            );
         }

         // Show vm name in single vm migration or show vm count in multiple vm migration.
         this.vmText = this.pageModel.vmIds.length === 1 ?
               this.pageModel.vmNames[this.pageModel.vmIds[0]] :
               this.i18nService("VmUi",
                     "MigrationWizard.readyPage.migratingVms", this.pageModel.vmIds.length);
         this.showSdrsSummary = this.pageModel.recommendationService &&
               (this.pageModel.recommendationService.hasRecommendations()
               || this.pageModel.recommendationService.hasFaults());

         let profile: any = this.pageModel.profile;
         if (!profile || (profile.id === this.storageProfileService.DEFAULT_ID)) {
            this.profileLabel = undefined;
         } else {
            // In case of "keep existing" the label is bogus - stretch for
            // the actual profile name.
            this.profileLabel = profile.keepExistingProfileAssignments ?
                  (profile.profileObj ? profile.profileObj.name : undefined) :
                  profile.label;
         }

         if (this.pageModel.replicationGroup) {
            this.replicationGroupLabel = this.spbmReplicationGroupInfoService
                  .getReplicationGroupName(this.pageModel.replicationGroup);
         } else {
            this.replicationGroupLabel = "";
         }

         this.openXdrsRecommendations = () => {
            this.setIsLoading(false);
            let recommendations: any = this.pageModel.xdrsRecommendationService.getRecommendations();
            if (!this.xdrsFormattedRecommendations) {
               this.xdrsFormattedRecommendations = this.vmPlacementRecommendationFormatter
                     .formatRecommendations(recommendations);
            }
            this.xdrsFormattedRecommendations.then((formattedRecommendations: any) => {
               this.vmPlacementRecommendationDialogService.showRecommendations(
                     formattedRecommendations,
                     this.pageModel.xdrsRecommendationService.getSelectedRecommendation().key)
                     .then((dialogData: any) => {
                        let selectedRecommendation: any = _.find(recommendations, (rec: any) => {
                           return rec.key === dialogData.selectedRecommendationKey;
                        });
                        if (selectedRecommendation) {
                           this.pageModel.xdrsRecommendationService
                                 .setSelectedRecommendation(selectedRecommendation);
                        }
                     });
            }).then(() => {
               this.setIsLoading(false);
            });
         };

         this.openXdrsFaults = () => {
            this.setIsLoading(false);
            let faults: any = this.pageModel.xdrsRecommendationService.getFaults();
            if (!this.xdrsFormattedFaults) {
               this.xdrsFormattedFaults = this.vmPlacementFaultFormatter.formatFaults(faults);
            }
            this.xdrsFormattedFaults.then((formattedFaults: any) => {
               this.vmPlacementRecommendationDialogService.showFaults(formattedFaults);
            }).then(() => {
               this.setIsLoading(false);
            });
         };

         this.$scope.openRecommendationsDialog = (vm: any, disk: any) => {
            if (!disk && !this.migrationWizardManager.model.isAdvancedStorageMode) {
               disk = {
                  storageUrn: this.defaultUriSchemeUtil.getVsphereObjectId(this.pageModel.storage.storageRef),
                  parentStoragePodUrn: this.pageModel.storage.parentStoragePod ?
                        this.defaultUriSchemeUtil.getVsphereObjectId(this.pageModel.storage.parentStoragePod) :
                        undefined
               };
            }

            let recommendationService: any = this.migrationWizardManager
                  .getStorageRecommendationsServiceInstance();
            let recommendations: any = recommendationService
                  .getDiskRecommendations(vm.vmId, disk);

            this.datastoreRecommendationsDialogService.openDialog(
                  recommendations,
                  this.pageModel.storageNameMap,
                  recommendationService.getSelectedRecommendation(vm.vmId, disk),
                  vm.vmName
            ).then((results: any) => {
               recommendationService
                     .setSelectedRecommendation(vm.vmId, disk, results.recommendationSpec);
               // Update the finishPageModel to get the new labels for the disks
               this.pageModel = this.migrationWizardManager.getFinishPageState();
            });
         };

         this.$scope.openFaultsDialog = (vm: any) => {
            let faults: any = this.migrationWizardManager
                  .getStorageRecommendationsServiceInstance().getFaultsForVm(vm.vmId);
            this.datastoreFaultsDialogService.openDialog(this.$scope, faults);
         };
      }

      public getStorageHint(): string {
         let hint: string = "";
         if (this.pageModel.pmemDiskInfo.hasPmemDisk) {
            hint = this.pageModel.pmemDiskInfo.hasRegularDisk ?
                  this.i18nService(
                        "VmUi", "MigrationWizard.readyPage.pmem.hint.hybridBaseline") :
                  this.i18nService(
                        "VmUi", "MigrationWizard.readyPage.pmem.hint.pmemBaseline");
         }
         if (this.pageModel.pmemDiskInfo.hasDowngradedPmemDisk) {
            hint = this.i18nService(
                  "VmUi", "MigrationWizard.readyPage.pmem.hint.standardBaseline");
         }
         return hint;
      }

      public setIsLoading(value: boolean): void {
         this.wizardConfig.loading = value;

      }
   } // class

   angular.module("com.vmware.vsphere.client.vm").component("migrateFinishPageComponent",
         new MigrateFinishPageComponent());

} // namespace
