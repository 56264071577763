(function() {
   'use strict';
   angular.module('com.vmware.vsphere.client.cluster')
         .controller('AdmissionControlPageController', AdmissionControlPageController);

   AdmissionControlPageController.$inject = ['$scope', 'i18nService',
      'haAdmissionControlService', 'haConstants', 'vuiConstants',
      'actionDialogService', 'columnRenderersRegistry', 'dataService',
      'vcService', 'clarityModalService', 'clusterConstants'];

   function AdmissionControlPageController($scope, i18nService, haAdmissionControlService,
         haConstants, vuiConstants, actionDialogService, columnRenderersRegistry,
         dataService, vcService, clarityModalService, clusterConstants) {

      var self = this;

      var getString = _.bind(i18nService.getString, i18nService, 'ClusterUi');
      var objectId = $scope.modalOptions.dialogData.objectId;

      self.pageModel = $scope.modalOptions.dialogData.manager.getAdmissionControlModel();
      self.slotSizeRequirements = {
         numberOfVmsRequiringMultipleSlots: 0,
         totalPoweredOnVms: 0,
         vmsRequiringMultipleSlots: null,
         vmsRequiringMultipleSlotsRatioLabel: ''
      };

      self.admissionControlTypes = haConstants.admissionControlPolicy;
      self.slotSizePolicyTypes = haConstants.slotSizePolicy;
      self.haDialogManager = $scope.modalOptions.dialogData.manager;
      self.pageModel.is65VcOrLater = false;
      self.oldFailoverValue = self.pageModel.failoverLevel;
      self.showWarningLabel = false;
      vcService.is65VcOrLater(objectId)
            .then(function(is65VcOrLater) {
               self.pageModel.is65VcOrLater = is65VcOrLater;
      });

      initFailoverHostsDialogData();

      //enable or disable grid delete button based on selection
      $scope.$watch(function () {
            return self.failoverHostsGridOptions.selectedItems;
         }, function (selectedItems) {
         var areItemsSelected =
               !!selectedItems && selectedItems.length > 0;
         self.failoverHostsGridOptions.actionBarOptions.actions[1].enabled =
               areItemsSelected;
      });

      $scope.$watch(function () {
         return self.pageModel.admissionControlPolicy.type;
      }, function () {
         if (self.pageModel.admissionControlPolicy.type ===
               self.admissionControlTypes.FAILOVER_HOSTS_TYPE) {
            self.failoverHostsService =
                  haAdmissionControlService.getFailoverHostsService(self.pageModel);
            self.failoverHostsGridOptions.data =
                  self.failoverHostsService.getFailoverHostsGridData();
         }
      });

      // region validation functions
      $scope.$watch('ctrl.pageModel.failoverLevel', function () {
         if (self.oldFailoverValue === self.pageModel.failoverLevel) {
            self.showWarningLabel = false;
         } else {
            self.showWarningLabel = true;
         }
      });

      function validateFailoverLevel() {
         if (typeof self.pageModel.failoverLevel !== "undefined" &&
               self.pageModel.hostsInCluster <= self.pageModel.failoverLevel) {
            self.pageModel.failoverLevel = self.pageModel.hostsInCluster - 1;
         }
         if (typeof self.pageModel.failoverLevel === "undefined" ||
               self.pageModel.failoverLevel <= 1) {
            self.pageModel.failoverLevel = 1;
         }
      }

      function validateFailoverCpu(event) {
         validatePercent(event.target.value, 'cpuFailoverPercent');
      }

      function validateFailoverMemory(event) {
         validatePercent(event.target.value, 'memoryFailoverPercent');
      }

      function validateResourceReductionThreshold(event) {
         validatePercent(event.target.value, 'resourceReductionToToleratePercent');
      }

      function normalizeSlotSizeCpu(event) {
         if (event.target.value === "" || event.target.value < 1) {
            self.pageModel.fixedSlotSizeCpu = 1;
         }

         if (event.target.value > clusterConstants.MAX_INTEGER) {
            self.pageModel.fixedSlotSizeCpu = clusterConstants.MAX_INTEGER;
         }
      }

      function normalizeSlotSizeMemory(event) {
         if (event.target.value === "" || event.target.value < 1) {
            self.pageModel.fixedSlotSizeMemory = 1;
         }

         if (event.target.value > clusterConstants.MAX_INTEGER) {
            self.pageModel.fixedSlotSizeMemory = clusterConstants.MAX_INTEGER;
         }
      }

      function validatePercent(value, modelProperty) {
         if (value === "" || value < 0) {
            self.pageModel[modelProperty] = 0;
         } else if (value > 100) {
            self.pageModel[modelProperty] = 100;
         }
      }

      function removeFailoverHost() {
         var itemsToRemove = self.failoverHostsGridOptions.selectedItems;
         self.failoverHostsService.removeFailoverHost(self.failoverHostsGridOptions,
               itemsToRemove);
      }

      function initFailoverHostsDialogData() {
         var addAction = {
            actionId: 'vsphere.core.cluster.actions.addFailoverHost',
            enabled: true,
            visible: true,
            iconClass: 'vui-icon-action-add',
            label: getString('ha.config.admission.control.policy.failover.add.host.button.label'),
            tooltipText: getString('ha.config.admission.control.policy.failover.add.host.button.tooltip'),
            onClick: openDialog
         };

         var removeAction = {
            actionId: 'vsphere.core.cluster.actions.removeFailoverHost',
            enabled: false,
            visible: true,
            iconClass: 'vui-icon-action-delete',
            label: getString('ha.config.admission.control.policy.failover.remove.hosts.button.label'),
            tooltipText: getString('ha.config.admission.control.policy.failover.remove.hosts.button.tooltip'),
            onClick: removeFailoverHost
         };

         self.failoverHostsGridOptions = {
            columnDefs: [
               {
                  displayName: getString('ha.config.admission.control.policy.failover.host.header'),
                  field: 'name',
                  template: iconNameRendererFactory('name', 'iconId'),
                  sortable: true,
                  searchable: false
               }
            ],
            height: '285px',
            resizable: false,
            reorderable: false,
            data: [],
            selectionMode: vuiConstants.grid.selectionMode.SINGLE,
            selectedItems: [],
            actionBarOptions: {
               actions: [
                  addAction,
                  removeAction
               ]
            }
         };
      }

      function getNumberOfVmsRequiringMultipleSlots() {
         var dasConfigInfo =
               self.haDialogManager.getClusterReconfigureSpec().configSpecEx.dasConfig;
         var dataServiceCallParams = {
            propertyParams: [{
               _type: 'com.vmware.vise.mvc.model.data.ParamSpec',
               propertyName: 'reconfigureHaFailoverLevelPrecheckResult',
               parameterType: 'com.vmware.vim.binding.vim.cluster.DasConfigInfo',
               parameter: dasConfigInfo
            }]
         };

         dataService.getProperties($scope.modalOptions.dialogData.objectId,
               ['reconfigureHaFailoverLevelPrecheckResult'],
               dataServiceCallParams
         ).then(function(data) {
            if (data && data['reconfigureHaFailoverLevelPrecheckResult']) {
               var result = data['reconfigureHaFailoverLevelPrecheckResult'];
               self.slotSizeRequirements.numberOfVmsRequiringMultipleSlots =
                     result.vmsRequiringMultipleSlots
                           ? result.vmsRequiringMultipleSlots.length : 0;
               self.slotSizeRequirements.totalPoweredOnVms =
                     result.totalVms;
               self.slotSizeRequirements.vmsRequiringMultipleSlots =
                     result.vmsRequiringMultipleSlots;
               self.slotSizeRequirements.vmsRequiringMultipleSlotsRatioLabel =
                     self.slotSizeRequirements.numberOfVmsRequiringMultipleSlots +
                     '/' + self.slotSizeRequirements.totalPoweredOnVms;
            }
         });
      }

      function openDialogForVmsRequiringMultipleSlots() {
         if (!self.slotSizeRequirements) {
            getNumberOfVmsRequiringMultipleSlots();
         }
         var dialogData = {
            slotSizeRequirements: self.slotSizeRequirements
         };

         actionDialogService.openActionDialog(
               { action: {} },
               [],
               dialogData,
               'cluster-ui/resources/cluster/views/settings/services/ha/edit/pages/' +
               'admissionControlVmsRequiringMultiSlotsDialog.html',
               {
                  title: getString('ha.config.admission.control.policy.failover.' +
                        'level.excluded.vms.title'),
                  resizable: false,
                  draggable: true,
                  maximizable: false,
                  width: '550px',
                  height: '400px',
                  confirmOptions: {
                     label: getString('ha.config.admission.control.policy.failover.' +
                           'level.excluded.vms.close.button')
                  },
                  rejectOptions: {
                     visible: false
                  }
               });
      }

      function openDialog() {
         var dialogData = {
            dedicatedFailoverHostIds: self.failoverHostsService.getFailoverHostIds(),
            clusterId: $scope.modalOptions.dialogData.objectId
         };

         clarityModalService.openOkCancelModal({
            title: i18nService.getString('ClusterUi',
                  'ha.config.admission.control.policy.failover.host.selection.title'),
            subTitle: {
               objectId: $scope.modalOptions.dialogData.objectId
            },
            dialogData: dialogData,
            defaultButton: 'submit',
            size: 'lg',
            modalClass: 'cluster-admission-control-failover-host-selector-dialog',
            contentTemplate: 'cluster-ui/resources/cluster/views/settings/services/ha/' +
                  'edit/pages/admissionControlFailoverHostsSelectorDialog.html',
            onSubmit: function() {
               $scope.modalOptions.alerts = [];
               $scope.modalOptions.isAlertClosed = true;
               return self.failoverHostsService.addFailoverHosts(
                     self.failoverHostsGridOptions,
                     dialogData.selectedItems);
            }
         });
      }

      function iconNameRendererFactory(nameField, iconField) {
         return (function (data) {
            var iconName = data[iconField];
            var objectLinkRenderer = columnRenderersRegistry.getColumnRenderer('object-link');
            return objectLinkRenderer([undefined, nameField, iconName], data);
         });
      }

      function show65VcOrLaterFeatures() {
         return self.pageModel.is65VcOrLater &&
               (self.pageModel.admissionControlPolicy.type !==
               self.admissionControlTypes.DISABLED_TYPE);
      }

      self.validateFailoverLevel = validateFailoverLevel;
      self.validateFailoverCpu = validateFailoverCpu;
      self.validateFailoverMemory = validateFailoverMemory;
      self.normalizeSlotSizeCpu = normalizeSlotSizeCpu;
      self.normalizeSlotSizeMemory = normalizeSlotSizeMemory;
      self.calculateNumberOfVmsRequiringMultipleSlots =
            getNumberOfVmsRequiringMultipleSlots;
      self.openDialogForVmsRequiringMultipleSlots =
            openDialogForVmsRequiringMultipleSlots;
      self.show65VcOrLaterFeatures = show65VcOrLaterFeatures;
      self.validateResourceReductionThreshold = validateResourceReductionThreshold;
      // end region

      self.admissionControlDesc = getString('ha.config.admission.control.description');
      self.definedHostFailoverCapacityLabel = getString('ha.config.admission.control.propertygrid.failover.capacity.label');
      self.admissionControlPolicies = haAdmissionControlService.getPolicies();
      self.failoverLevelLabel = getString('ha.config.admission.control.policy.failover.level.num.hosts');
      self.hostFailuresToleratesLabel = getString('ha.config.admission.control.propertygrid.num.hosts.label');
      self.failoverLevelHostsLabel = getString('ha.config.admission.control.policy.failover.level.hosts');
      self.hostFailuresToleratesMaxLabel = getString('ha.config.admission.control.failover.level.desc');
      self.autoComputePercentagesLabel = getString('ha.config.admission.control.newpage.policy.failover.resources.override.label');
      self.failoverCpuCapacityLabel = getString('ha.config.admission.control.policy.failover.reserved.cpu');
      self.failoverMemoryCapacityLabel = getString('ha.config.admission.control.policy.failover.reserved.memory');
      self.percentLabel = getString('ha.config.percent');
      self.cpuLabel = getString('ha.config.admission.control.policy.cpu');
      self.memoryLabel = getString('ha.config.admission.control.policy.mem');
      self.slotSizePolicyLabel = getString('ha.config.admission.control.policy.failover.level.slot.policy');
      self.coverAllVmLabel = getString('ha.config.admission.control.policy.failover.level.slot.policy.auto');
      self.coverAllVmHint = getString('ha.config.admission.control.policy.failover.level.slot.policy.auto.hint');
      self.fixedSlotSizeLabel = getString('ha.config.admission.control.policy.failover.level.slot.policy.fixed');
      self.fixedSlotSizeHint = getString('ha.config.admission.control.policy.failover.level.slot.policy.fixed.hint');
      self.cpuSlotSizeLabel = getString('ha.config.admission.control.policy.failover.level.slot.policy.cpu.size');
      self.MhzLabel = getString('ha.config.admission.control.policy.failover.level.slot.policy.cpu.mhz');
      self.memorySlotSizeLabel = getString('ha.config.admission.control.policy.failover.level.slot.policy.memory.size');
      self.MbLabel = getString('ha.config.admission.control.policy.failover.level.slot.policy.memory.mb');
      self.vmRequiringMultipleSlotsTitle =
            getString('ha.config.admission.control.policy.failover.level.slot.policy.' +
                  'vms.requiring.multiple.slots.label');
      self.slotPolicyViewButtonLabel = getString('ha.config.admission.control.' +
            'policy.failover.level.slot.policy.view.excluded.label');
      self.slotPolicyCalculateButtonLabel = getString('ha.config.admission.control.' +
            'policy.failover.level.slot.policy.calculate.label');
      self.resourceReductionThresholdLabel = getString(
            'ha.config.admission.control.propertygrid.resource.reduction.label');
      self.resourceReductionThresholdDesc = getString(
            'ha.config.admission.control.newpage.resource.reduction.desc');
   }
})();
