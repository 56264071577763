module hostprofile_ui {

    import ManagedObjectReference = com.vmware.vim.binding.vmodl.ManagedObjectReference;
    import ReadHostCustomizationsSpec = com.vmware.vsphere.client.hostprofile.data.h5.ReadHostCustomizationsSpec;

    export class RemediateHostManager {

        static $inject = ["dataService", "hostCustomizationsService"];

        private targetUid: string;
        private remediationSpec: any;
        private rebootHosts: boolean;

        private constructor(private dataService: any, private hostCustomizationsService: any) {
        }

        public setTargetUid(targetUid: string): void {
            this.targetUid = targetUid;
        }

        public getTargetUid(): string {
            return this.targetUid;
        }

        public getAssociatedHostProfile(): angular.IPromise<any> {
            return this.dataService.getProperties(this.targetUid, ["associatedHostProfile"]);
        }

        public getHostCustomizations(associatedHostProfile: any): angular.IPromise<any> {
            let spec: ReadHostCustomizationsSpec = new ReadHostCustomizationsSpec();
            spec.hostProfile = associatedHostProfile;
            spec.isNewlyAssociatedProfile = false;
            return this.hostCustomizationsService.getHostCustomizations(this.targetUid, spec);
        }

        public setRemediationSpec(remediationSpec: any): void {
            this.remediationSpec = remediationSpec;
        }

        public getRemediationSpec(): any {
            return this.remediationSpec;
        }

        public setRebootHosts(rebootHosts: boolean): void {
            this.rebootHosts = rebootHosts;
        }

        public getRebootHosts(): boolean {
            return this.rebootHosts;
        }

    }

    angular.module("com.vmware.vsphere.client.hostprofile")
        .factory("RemediateHostManager",
            [
                "dataService",
                "hostCustomizationsService",
                function () {
                    return Function.prototype.bind.apply(
                        RemediateHostManager,
                        [undefined, arguments[0], arguments[1]]
                    );
                }
            ]);
}
