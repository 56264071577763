/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace dvs_ui {

   import ValidationCheckResult = com.vmware.vsphere.client.dvs.api.featureupgrade.ValidationCheckResult;
   import DvsResControlUpgradeStatistics = com.vmware.vsphere.client.dvs.api.spec.DvsResControlUpgradeStatistics;

   export class DvsUpgradeNiocWizardModel {
      dvsId: string | null;
      dvsName: string;
      niocUpgradeStatistics: DvsResControlUpgradeStatistics;
      prerequisiteChecks: ValidationCheckResult[];
      prerequisiteWarningShown: boolean = false;
      migrateShares: boolean = false;
   }
}
