namespace h5_vm {
   import IController = angular.IComponentController;

   class VmProvisioningEulaController implements IController {
      static $inject: string[] = ["$scope"];

      private form: any;

      constructor(private $scope: any) {
         this.form = this.$scope.vmProvisioningEulaPageModel.form;

         this.$scope.vuiWizard.currentPage.disableNext = () => {
            return !this.form.eulaInfo.allEULAAccepted;
         };
      }
   }

   angular.module("com.vmware.vsphere.client.vm")
      .controller("vmProvisioningEulaController", VmProvisioningEulaController);
}

