/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace license_lib_ui {

   export class AssignLicenseFormController {
      public static $inject = [
         '$scope',
         'assignLicenseService',
         'clarityConstants',
         'licenseConstants',
         'licenseValidationUtil',
         'defaultUriSchemeUtil',
         'managedEntityConstants',
         'i18nService'
      ];

      private readonly NEW_LICENSE_URI = "urn:vri:License:NEW_LICENSE_ID";

      private TABS: any[] = [
            {
               label: this.i18nService.getString("LicenseLibUi", "assignLicense.tab.existingLicenses"),
               id: "existing-licenses-btn",
               value: "existing-licenses",
               submitButtonDisabled: true
            },
            {
               label: this.i18nService.getString("LicenseLibUi", "assignLicense.tab.newLicense"),
               id: "new-license-btn",
               value: "new-license",
               submitButtonDisabled: true
            }
      ];

      private dataRetrievalError: any;
      private selectedLicense: any;
      private loading: boolean;
      private assetRefs: any[];
      private licenseData: any;
      private selectedTab: string;

      constructor(
         private $scope: any,
         private assignLicenseService: any,
         private clarityConstants: any,
         private licenseConstants: any,
         private licenseValidationUtil: any,
         private defaultUriSchemeUtil: any,
         private managedEntityConstants: any,
         private i18nService: any
      ) {
      }

      public $onInit() {
         this.selectedTab = this.TABS[0].value;

         this.$scope.modalOptions.onSubmit = (): any => {
            if (this.dataRetrievalError) {
               return true;
            }

            if (!this.selectedLicense) {
               return false;
            }

            // Validation methods all search for the license in the this.licenseData which does not contain the new license.
            // Moreover, each hosted component (the grid for the existing licenses and form for adding a new license)
            // should be responsible for its validation errors.
            const errors: any[] = this.selectedLicense.uri === this.NEW_LICENSE_URI
                  ? []
                  : this.licenseValidationUtil.getValidationBlockingErrors(
                        this.selectedLicense,
                        this.licenseData,
                        false
                  );

            if (!_.isEmpty(errors)) {
               this.setModalErrors(errors);
               return false;
            }

            let warnings: any = this.licenseValidationUtil.getValidationWarnings(
                  this.selectedLicense,
                  this.licenseData,
                  false
            );

            if (!_.isEmpty(warnings)) {
               return this.licenseValidationUtil.showLicenseWarningsConfirmationDialog(warnings)
                     .then((result: any) => {
                        this.performLicenseAssignment();
                        return true;
                     }, (reason: any) => {
                        return false;
                     });
            }

            this.performLicenseAssignment();
            return true;
         };

         this.loading = true;
         this.assetRefs = this.$scope.modalOptions.dialogData.availableTargets;

         this.assignLicenseService.retrieveLicenses(this.assetRefs).then(
               (licenseData: any): void => {
                  this.licenseData = licenseData;
                  this.loading = false;
               }
         ).catch((responseError: any) => {
            if (this.isClusterNotRegistered(responseError)) {
               this.dataRetrievalError =
                     this.i18nService.getString('LicenseLibUi', 'assets.clusterNotRegistered');
            } else {
               this.dataRetrievalError = responseError.localizedMessage;
            }

            this.loading = false;
         });
      }

      onTabChanged() {
         const currentTab: any = this.TABS.filter((item: any) => {
            return item.value === this.selectedTab;
         });
         this.toggleSubmitButtonState(currentTab[0].submitButtonDisabled);
      }

      toggleSubmitButtonState(disabled: boolean): void {
         this.$scope.modalOptions.submitDisabled = disabled;
      }

      existingLicensesTabSubmitButtonDisabled(disabled: boolean) {
         this.TABS[0].submitButtonDisabled = disabled;
         if (this.selectedTab === this.TABS[0].value) {
            this.toggleSubmitButtonState(disabled);
         }
      }

      newLicenseTabSubmitButtonDisabled(isStateValid: boolean) {
         this.TABS[1].submitButtonDisabled = !isStateValid;
         if (this.selectedTab === this.TABS[1].value) {
            this.toggleSubmitButtonState(!isStateValid);
         }
      }

      get showNewLicenseView(): boolean {
         return this.selectedTab === this.TABS[1].value;
      }

      public onLicenseItemChanged(license: any): void {
         this.selectedLicense = license;
      }

      private isClusterNotRegistered(error: any): boolean {
         return error._type === this.licenseConstants.RESPONSE_FAULT.LICENSING_METHOD_FAULT
               && this.areAllAssetsClusterServerObjects();
      }

      private areAllAssetsClusterServerObjects(): boolean {
         if (!this.assetRefs) {
            return false;
         }

         let areAllClusterRefs: boolean = _.every(this.assetRefs, (assetRef: any): any => {
            let objectType: any = this.defaultUriSchemeUtil.getEntityType(assetRef);
            return objectType === this.managedEntityConstants.CLUSTER;
         });

         return areAllClusterRefs;
      }

      private setModalErrors(errors: any[]): void {
         const errorObjects: any = _.map(errors, (err) => {
            return {
               text: err,
               type: this.clarityConstants.notifications.type.ERROR
            };
         });

         this.$scope.modalOptions.alerts = errorObjects;
      }

      private performLicenseAssignment(): void {
         const licenseUri: string = this.selectedLicense.uri;
         const assetUris: any[] = _.map(this.licenseData.assets, (asset: any) => {
            return asset.uri;
         });

         if (licenseUri === this.NEW_LICENSE_URI) {
            this.assignLicenseService.assignNewLicense(assetUris, this.selectedLicense.serialKeys, this.selectedLicense.name);
         } else {
            this.assignLicenseService.assignLicense(assetUris, licenseUri);
         }
      }
   }

   angular.module('com.vmware.vsphere.client.licenseLibUi').controller(
         'AssignLicenseFormController',
         AssignLicenseFormController
   );
}
