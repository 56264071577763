namespace h5_vm {
   import DeviceTypeConstants = h5_vm.DeviceTypeConstants;
   import VirtualSerialPort$FileBackingInfo = com.vmware.vim.binding.vim.vm.device.VirtualSerialPort$FileBackingInfo;
   import VirtualDevice$ConnectInfo = com.vmware.vim.binding.vim.vm.device.VirtualDevice$ConnectInfo;
   import VirtualSerialPort = com.vmware.vim.binding.vim.vm.device.VirtualSerialPort;
   import VirtualSerialPortOption = com.vmware.vim.binding.vim.vm.device.VirtualSerialPortOption;
   import VmConfigEnvironment = com.vmware.vsphere.client.vm.config.VmConfigEnvironment;
   import VirtualDeviceOption = com.vmware.vim.binding.vim.vm.device.VirtualDeviceOption;

   export class SerialPortService {
      static $inject: string[] = ["deviceService", "deviceClassLineageService", "i18nService", "VmHwSerialPort"];

      constructor(private deviceService: any,
                  private deviceClassLineageService: any,
                  private i18nService: any,
                  private VmHwSerialPort: any) {
      }

      public makeDefaultSerialPort(env: VmConfigEnvironment, virtualMachineDevices: any): any {

         const deviceOptions: Array<VirtualDeviceOption> = env.configOption.hardwareOptions.virtualDeviceOption;
         const powerState = env.powerState;

         const serialPortSupportIndex: number = this.getDeviceSupport(deviceOptions, DeviceTypeConstants.VIRTUAL_SERIAL_PORT);
         const sioControllerSupportIndex: number = this.getDeviceSupport(deviceOptions, DeviceTypeConstants.VIRTUAL_SIO_CONTROLLER);
         const option: VirtualSerialPortOption = <VirtualSerialPortOption> deviceOptions[serialPortSupportIndex];
         const sioOption: any = deviceOptions[sioControllerSupportIndex];

         if (!option || ! sioOption) {
            throw this.newErrorDeviceNotSupported();
         }

         const maxNumberOfSerialPort: number = sioOption.numSerialPorts.max;
         const numberOfSerialPort: number = this.getNumberOfSerialPorts(virtualMachineDevices);

         if (numberOfSerialPort === maxNumberOfSerialPort) {
            throw new Error(this.i18nService.getString("VmUi", "VmDeviceManager.DeviceLimitReached"));
         }

         if (powerState === h5_vm.PowerState.POWERED_ON && !option.plugAndPlay) {
            throw this.newErrorHotAddNotSupported();
         }

         const rawDevice: VirtualSerialPort = new VirtualSerialPort();
         rawDevice.key = this.deviceService.newKey();
         let fileBacking = new VirtualSerialPort$FileBackingInfo();
         rawDevice.backing = fileBacking;
         fileBacking.fileName = "";

         rawDevice.connectable = new VirtualDevice$ConnectInfo();
         rawDevice.connectable.startConnected = option.connectOption.startConnected.defaultValue;
         rawDevice.connectable.connected = false;
         rawDevice.connectable.allowGuestControl = option.connectOption.allowGuestControl.defaultValue;
         rawDevice.yieldOnPoll = option.yieldOnPoll.defaultValue;

         const inflatedDevice: VmHwSerialPort = new this.VmHwSerialPort(rawDevice);
         this.deviceService.setDeviceInfo(inflatedDevice);

         return {
            device: inflatedDevice
         };
      }

      private getDeviceSupport(deviceOptions: Array<any>, deviceType: string): number {
         return _.findIndex(deviceOptions, (option: any) =>
               this.deviceClassLineageService.isClassNameSubclassOf(option.type.wsdlName, deviceType)
         );
      }

      private getNumberOfSerialPorts(virtualMachineDevices: any): number {
         const existingSerialPorts: number = virtualMachineDevices.existingDevicesOfType(DeviceTypeConstants.VIRTUAL_SERIAL_PORT).length;
         const newSerialPorts: number = virtualMachineDevices.newDevicesOfType(DeviceTypeConstants.VIRTUAL_SERIAL_PORT).length;

         return existingSerialPorts + newSerialPorts;
      }

      private newErrorHotAddNotSupported(): Error {
         return this.newError(this.i18nService.getString, "HotAddNotSupported");
      }

      private newErrorDeviceNotSupported(): any {
         return this.newError(this.i18nService.getString, "DeviceNotSupported");
      }

      private newError(i18n: any, key: string): Error {
         let msg: string = i18n("VmUi", "VmDeviceManager." + key);
         let retVal: Error = new Error(msg);
         return retVal;
      }
   }

   angular.module("com.vmware.vsphere.client.vm")
         .service("serialPortService", SerialPortService);
}
