/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace dvs_ui {
   import BoolPolicy = com.vmware.vim.binding.vim.BoolPolicy;
   import SecurityPolicy = com.vmware.vim.binding.vim.dvs.VmwareDistributedVirtualSwitch$SecurityPolicy;
   import DvsPortPropertiesModel = com.vmware.vsphere.client.h5.network.dvs.ports.model.DvsPortPropertiesModel;

   export class DvsPortEditSecurityPageModel {
      public overrideOptions: any;

      public policyValues: any;
      public isInherited: Function;
      public isSecurityPageChanged: Function;
      public isPolicyChanged: Function;

      constructor(securityPolicy: SecurityPolicy) {
         if (securityPolicy) {
            this.overrideOptions =
                  _.mapObject(securityPolicy, function (val: BoolPolicy) {
                     return !val.inherited;
                  });

            this.policyValues =
                  _.mapObject(securityPolicy, function (val: BoolPolicy) {
                     return val.value;
                  });

            this.isInherited = this.isInheritedFunction.bind(this);
            this.isSecurityPageChanged = this.isSecurityPageChangedFunction.bind(this);
            this.isPolicyChanged = this.isPolicyChangedFunction.bind(this);
         }
      }

      // returns true if the all page policies should be inherited from the portgroup
      public isInheritedFunction(): boolean {
         if (!this.overrideOptions) {
            return true;
         }

         return !this.overrideOptions.macChanges
               && !this.overrideOptions.allowPromiscuous
               && !this.overrideOptions.forgedTransmits;
      }

      public isSecurityPageChangedFunction(initialData: SecurityPolicy) {
         if (!initialData) {
            return false;
         }

         return this.isPolicyChanged(initialData.allowPromiscuous,
                     DvsPortEditDialogConstants.POLICY_ALLOW_PROMISCUOUS)
               || this.isPolicyChanged(initialData.macChanges,
                     DvsPortEditDialogConstants.POLICY_MAC_CHANGES)
               || this.isPolicyChanged(initialData.forgedTransmits,
                     DvsPortEditDialogConstants.POLICY_FORGET_TRANSMITS);
      }

      // returns true if the page settings of a given policy differ
      // from the page settings for that policy
      public isPolicyChangedFunction(policy: BoolPolicy, policyType: String): boolean {
         if (!policy || !this.overrideOptions || !this.policyValues) {
            return false;
         }

         switch (policyType) {
            case DvsPortEditDialogConstants.POLICY_ALLOW_PROMISCUOUS :
               return this.overrideOptions.allowPromiscuous === policy.inherited
                     || this.policyValues.allowPromiscuous !== policy.value;
            case DvsPortEditDialogConstants.POLICY_FORGET_TRANSMITS:
               return this.overrideOptions.forgedTransmits === policy.inherited
                     || this.policyValues.forgedTransmits !== policy.value;
            case DvsPortEditDialogConstants.POLICY_MAC_CHANGES:
               return this.overrideOptions.macChanges === policy.inherited
                     || this.policyValues.macChanges !== policy.value;
         }

         return false;
      }
   }
}