namespace h5_vm {

   import Privileges = h5_vm.Privileges;
   import VmWorkflowMode = h5_vm.VmWorkflowMode;
   import VirtualEthernetCard = com.vmware.vim.binding.vim.vm.device.VirtualEthernetCard;
   import GuestOsDescriptor = com.vmware.vim.binding.vim.vm.GuestOsDescriptor;

   export class VmStandbyOptionsService {

      static $inject= [
         "vmHardwareUtil"
      ];

      constructor(private vmHardwareUtil: any) {
      }

      private hasPrivilegesToEditSettings(privileges: Array<string>): boolean {
         return this.vmHardwareUtil.checkPrivileges(privileges, [Privileges.VM_CONFIG_SETTINGS]);
      }

      isEditSettingsDisabled(privileges: Array<string>, mode: VmWorkflowMode): boolean {
         if (mode === VmWorkflowMode.UpdateMode) {
            return !this.hasPrivilegesToEditSettings(privileges);
         }

         if (mode === VmWorkflowMode.CloneMode) {
            return !this.hasPrivilegesToEditSettings(privileges);
         }

         return false;
      }

      private hasPrivilegesToEditDevices(privileges: Array<string>): boolean {
         return this.vmHardwareUtil.checkPrivileges(privileges, [Privileges.VM_EDITDEVICE_PRIVILEGE]);
      }

      isEditDevicesDisabled(privileges: Array<string>, mode: VmWorkflowMode): boolean {
         if (mode !== VmWorkflowMode.CreateMode) {
            return !this.hasPrivilegesToEditDevices(privileges);
         }

         return false;
      }

      isEditWakeOnLanDisabled(device:VirtualEthernetCard, ctx:any): boolean {
         if (device && !this.isTypeSupported(device, ctx.vmConfigContext) && !ctx.originalWakeOnLanValues[device.key]) {
            // if the device is already disabled and the type is not supported,
            // then don't let the user try to enable the device
            return true;
         }

         if (ctx.standbyValue === 'powerOnSuspend') {
            // if standbyValue is set to suspend, devices are disabled
            return true;
         }

         return this.isEditDevicesDisabled(ctx.vmConfigContext.privileges, ctx.vmWorkflowMode);
      }

      isTypeSupported(device:VirtualEthernetCard, vmConfigContext:any): boolean {
         let guestOs:GuestOsDescriptor = vmConfigContext.selectedGuestOs;

         if (!guestOs) {
            return true;
         }

         let guestOsCards:Array<any> = guestOs.wakeOnLanEthernetCard;

         if (!guestOsCards || !guestOsCards.length) {
            return false;
         }

         let deviceType:string = device._type;

         for (let i = 0; i < guestOsCards.length; i++) {
            let cardTypeClass:string = guestOsCards[i].typeClass;

            if (cardTypeClass === deviceType) {
               return true;
            }
         }

         return false;
      }

      findPortgroupInfo(portGroup:any, vmConfigContext:any): any {
         let retVal = {};

         let switchUuid:string = portGroup.port.switchUuid;
         let portgroupKey:string = portGroup.port.portgroupKey;

         let portgroups:Array<any> = vmConfigContext.environment.configTarget.distributedVirtualPortgroup;

         for (let i = 0; i < portgroups.length; i++) {
            let group = portgroups[i];

            if (switchUuid === group.switchUuid && portgroupKey === group.portgroupKey) {
               retVal = group;
            }
         }

         return retVal;
      }

      findOpaqueInfo(backing:any, vmConfigContext:any): any {
         let retVal = {};

         let networkUuid:string = backing.opaqueNetworkId;
         let networkType:string = backing.opaqueNetworkType;

         let opaqueNetworks:Array<any> = vmConfigContext.environment.configTarget.opaqueNetwork;

         for (let i = 0; i < opaqueNetworks.length; i++) {
            let network = opaqueNetworks[i];

            if (networkUuid === network.network.opaqueNetworkId && networkType === network.network.opaqueNetworkType) {
               retVal = network;
            }
         }

         return retVal;
      }
    }

    angular.module("com.vmware.vsphere.client.vm")
        .service("vmStandbyOptionsService", VmStandbyOptionsService);
}
