namespace storage_ui {

   export class DatastoreConfigIoControl {
      public bindings: any;
      public controller: any;
      public templateUrl: string;

      constructor() {
         this.bindings = {
            modalOptions: "<"
         };

         this.controller = DatastoreConfigIoControlController;
         this.templateUrl = "storage-ui/resources/storage/views/datastore/DatastoreConfigIOControl.html";
      }
   }

   class DatastoreConfigIoControlController {

      public static $inject = ["i18nService", "datastoreConfigIOControlDialogService", "clarityConstants"];

      public percentageThresholdRecommendedValues: any[];
      public manualThresholdRecommendedValues: any[];
      public percentageThresholdErrorMessages: any;
      public manualThresholdErrorMessages: any;
      public percentageThresholdLabel: string;
      public manualThresholdLabel: string;
      public i18n: (...args: any[]) => string;
      public i18nCommon: Function;

      public loading: boolean = false;
      public hasError: boolean = false;

      public dialogData: DatastoreConfigIOControlDialogData;

      public modalOptions: ModalOptions;

      constructor(private i18nService: any,
                  public dialogService: DatastoreConfigIOControlDialogService,
                  private clarityConstants: any) {
         this.i18n = this.i18nService.getString.bind(null, "StorageUi");
         this.i18nCommon = this.i18nService.getString.bind(null, "Common");
      }

      public $onInit() {
         let self = this;

         self.loading = true;

         self.percentageThresholdLabel = self.i18n('datastore.editCapabilitiesForm.percentPeakThroughputThreshold');
         self.manualThresholdLabel = self.i18n('datastore.editCapabilitiesForm.manualThreshold');

         self.modalOptions.dialogData.datastoreConfigIOControlDialogDataPromise.then(
               function (dialogData: DatastoreConfigIOControlDialogData) {
                  self.dialogData = dialogData;
                  self.modalOptions.dialogData.datastoreConfigIOControlDialogData = dialogData;

                  self.percentageThresholdRecommendedValues = [
                     self.nameValuePair("datastore.editCapabilitiesForm.percentPeakThroughput.values.minimum",
                           self.dialogService.CONGESTION_THRESHOLD_PERCENT_MIN),
                     self.nameValuePair("datastore.editCapabilitiesForm.percentPeakThroughput.values.recommended",
                           self.dialogService.CONGESTION_THRESHOLD_DEFAULT_PERCENT_PEAK_VALUE),
                     self.nameValuePair("datastore.editCapabilitiesForm.percentPeakThroughput.values.maximum",
                           self.dialogService.CONGESTION_THRESHOLD_PERCENT_MAX),
                  ];

                  self.percentageThresholdErrorMessages = {
                     min: self.dialogService.percentThresholdValidationError(),
                     max: self.dialogService.percentThresholdValidationError()
                  };

                  self.manualThresholdErrorMessages = {
                     max: self.dialogService.manualThresholdValidationError(dialogData.minManualCongestionThreshold),
                     min: self.dialogService.manualThresholdValidationError(dialogData.minManualCongestionThreshold)
                  };

                  self.manualThresholdRecommendedValues = [
                     self.nameValuePair("datastore.editCapabilitiesForm.customThreshold.values.minimum",
                           dialogData.minManualCongestionThreshold),
                     self.nameValuePair("datastore.editCapabilitiesForm.customThreshold.values.recommended",
                           self.dialogService.CONGESTION_THRESHOLD_DEFAULT_MANUAL_VALUE),
                     self.nameValuePair("datastore.editCapabilitiesForm.customThreshold.values.maximum",
                           self.dialogService.CONGESTION_THRESHOLD_MANUAL_MAX),
                  ];

                  self.showCongestionAlert();
               }, function (error: any) {
                  let errorMsg: string = self.i18nService.getString("StorageUi", "storage.failedToRetrieveDataError");
                  if (error && error.data && error.data.message) {
                     errorMsg += " " + error.data.message;
                  }
                  self.modalOptions.alerts = [{
                     text: errorMsg,
                     type: self.clarityConstants.notifications.type.ERROR
                  }];

                  self.dialogData = self.dialogService.getDefaultDialogData();
                  self.hasError = true;
               })
               .finally(function () {
                  self.loading = false;
               });
      }

      public showCongestionAlert() {
         if (this.dialogData.selectedCongestionThresholdOption === this.dialogService.congestionThresholdOptions.MANUAL &&
               this.dialogData.selectedSiocOption === this.dialogService.siocOptions.ENABLE_SOIC_ENABLED_STATISTIC) {
            this.modalOptions.alerts = [{
               text: this.i18n("datastore.editCapabilitiesForm.warning"),
               type: this.clarityConstants.notifications.type.WARNING
            }];
         } else {
            this.modalOptions.alerts = [];
         }
      };

      public resetDefaults() {
         this.dialogService.resetCongestionThresholdValues(this.dialogData);
         this.showCongestionAlert();
      }

      private nameValuePair(labelKey: String, value: number): any {
         return {
            name: this.i18n(labelKey),
            value: value
         };
      }
   }

   angular.module("com.vmware.vsphere.client.storage")
         .component("datastoreConfigIoControl", new DatastoreConfigIoControl());
}
