angular.module('com.vmware.vsphere.client.host')
      .factory('removeHostAction',
            ['dataService', 'mutationService', 'alertService', 'i18nService', 'clarityModalService',
               'defaultUriSchemeUtil', 'hostConnectionState',
               function (dataService, mutationService, alertService, i18nService, clarityModalService,
                     defaultUriSchemeUtil, hostConnectionState) {
                  var perform = function (objectIds) {
                     var groupedHosts = [];
                     getGroupedHosts(objectIds).then(function (result) {
                        groupedHosts = result;

                        if (groupedHosts.removableHostsNames.length === 0 && groupedHosts.nonRemovableHosts.length > 0) {
                           showError(groupedHosts.nonRemovableHosts);
                        } else {
                           showConfirmation(groupedHosts.removableHostsNames, function() {
                              mutationService.removeOnMultiEntity(
                                 groupedHosts.removableHostsObjToRemoveId);

                              if (groupedHosts.nonRemovableHosts.length > 0) {
                                 showError(groupedHosts.nonRemovableHosts);
                              }
                           });
                        }
                     });
                  };

                  return {
                     perform: perform
                  };


                  function showConfirmation(targetNames, onSubmitCallback){
                     var modalOptions  = {
                        title: targetNames.length > 1 ?
                              i18nService.getString('HostUi','actionRemoveHostsCaption') :
                              i18nService.getString('HostUi','actionRemoveHostCaption'),
                        subTitle: targetNames.length > 1 ? "" : {
                           text: targetNames[0]
                        },
                        message: targetNames.length > 1 ?
                              i18nService.getString('HostUi', 'actionRemoveHostsWarning', targetNames) :
                              i18nService.getString('HostUi', 'actionRemoveHostWarning'),
                        saveButtonLabel: i18nService.getString('Common', 'yes.label'),
                        cancelButtonLabel: i18nService.getString('Common', 'no.label'),
                        preserveNewlines: true,
                        icon: 'vx-icon-warning-32x32',
                        submit: onSubmitCallback
                     };

                     clarityModalService.openConfirmationModal(modalOptions);
                  }

                  function showError(targetObjectsName) {
                     var errorTitle = targetObjectsName.length > 1 ?
                           i18nService.getString('HostUi', 'actionRemoveHostsCaption', targetObjectsName) :
                           i18nService.getString('HostUi', 'actionRemoveHostCaption', targetObjectsName);
                     var errorText = targetObjectsName.length > 1 ?
                           i18nService.getString('HostUi', 'actionRemoveHostsError', targetObjectsName) :
                           i18nService.getString('HostUi', 'actionRemoveHostError', targetObjectsName);
                     alertService.error(errorTitle, errorText);
                  }

                  function getGroupedHosts(objectIds) {
                     return dataService.getPropertiesForObjects(objectIds, ['parent', 'runtime', 'name'])
                           .then(function (data) {
                              var groupedHosts = {
                                 nonRemovableHosts: [],
                                 removableHostsNames: [],
                                 removableHostsObjToRemoveId: []
                              };
                              _.each(objectIds, function(objectId) {
                                 var parent = data[objectId].parent;
                                 var runtime = data[objectId].runtime;
                                 var name = data[objectId].name;
                                 if (parent.type === 'ClusterComputeResource' &&
                                       runtime.connectionState === hostConnectionState.CONNECTED &&
                                       !runtime.inMaintenanceMode) {
                                    groupedHosts.nonRemovableHosts.push(name);
                                 } else {
                                    var objectToRemoveId = (parent.type === 'ComputeResource')
                                          ? defaultUriSchemeUtil.getVsphereObjectId(parent)
                                          : objectId;
                                    groupedHosts.removableHostsNames.push(name);
                                    groupedHosts.removableHostsObjToRemoveId.push(objectToRemoveId);
                                 }
                              });
                              return groupedHosts;
                           });
                  }
               }
            ]);
