(function() {
   /**
    * Directive for adding VM to hosts rule.
    */
   'use strict';
   angular.module('com.vmware.vsphere.client.cluster').directive(
         'addVmToHostsRule', addVmToHostsRule);

   addVmToHostsRule.$inject = ['$rootScope', 'i18nService', 'clusterGroupsService',
      'clusterGroupsConstants', 'clusterRulesConstants'];

   function addVmToHostsRule($rootScope, i18nService, clusterGroupsService, clusterGroupsConstants,
                             clusterRulesConstants) {

      return {
         restrict: 'AE',
         templateUrl:
               'cluster-ui/resources/cluster/views/settings/rules/addVmToHostsRule.html',
         scope: {
            spec: '='
         },
         controller: ['$scope', function($scope) {

            var objectId = $rootScope._route.objectId;
            $scope.ruleDescriptionTitle = i18nService.getString('Common', 'rules.config.description');
            $scope.vmGroupTitle = getString('rules.config.vmGroup.label');
            $scope.hostGroupTitle = getString('rules.config.hostGroup.label');
            $scope.vmGroups = [];
            $scope.hostGroups = [];
            $scope.spec.selectedVmGroup = null;
            $scope.spec.selectedHostGroup = null;
            $scope.vmToHostRuleTypeOptions = getVmToHostRuleTypes();

            if($scope.spec.operation === clusterRulesConstants.operationType.EDIT){
               $scope.spec.selectedVmGroup = $scope.spec.vmToHostRuleProperties.vmGroupName;
               $scope.spec.selectedHostGroup = $scope.spec.vmToHostRuleProperties.hostGroupName;
            }

            getGroupsOptions().then(function() {
               $scope.$watchGroup(
                     ['spec.selectedVmGroup', 'spec.selectedHostGroup', 'spec.selectedVmToHostRuleType'],
                     function(data){

                        if (data[0] === null) {
                           $scope.ruleDescription =
                                 getString('rules.config.relationTypes.selectVmGroup');
                           return;
                        }

                        $scope.spec.invalidVmGroup = !_.some($scope.vmGroups, function(vmGroup) {
                           return vmGroup.name === data[0];
                        });

                        if (data[1] === null) {
                           $scope.ruleDescription =
                                 getString('rules.config.relationTypes.selectHostGroup');
                           return;
                        }

                        $scope.spec.invalidHostGroup = !_.some($scope.hostGroups, function(hostGroup) {
                           return hostGroup.name === data[1];
                        });

                        var ruleType = data[2];
                        switch (ruleType.id) {
                           case 'hardAffinity':
                              $scope.ruleDescription = i18nService.getString('ClusterUi',
                                    'rules.config.relationTypes.hardAffinity.desc', data[0], data[1]);
                              break;
                           case 'softAffinity':
                              $scope.ruleDescription = i18nService.getString('ClusterUi',
                                    'rules.config.relationTypes.softAffinity.desc', data[0], data[1]);
                              break;
                           case 'hardAntiAffinity':
                              $scope.ruleDescription = i18nService.getString('ClusterUi',
                                    'rules.config.relationTypes.hardAntiAffinity.desc', data[0],
                                    data[1]);
                              break;
                           case 'softAntiAffinity':
                              $scope.ruleDescription = i18nService.getString('ClusterUi',
                                    'rules.config.relationTypes.softAntiAffinity.desc', data[0],
                                    data[1]);
                              break;
                        }
               });
            });

            function getGroupsOptions() {
               var vmGroups = [];
               var hostGroups = [];
               return clusterGroupsService.getGroupsData(objectId)
                     .then(function(groupsData) {

                        groupsData.forEach(function(group) {
                           if(group.rawType === clusterGroupsConstants.groupType.VM_GROUP) {
                              vmGroups.push(group);
                           }
                           else if(group.rawType === clusterGroupsConstants.groupType.HOST_GROUP) {
                              hostGroups.push(group);
                           }
                        });
                        $scope.vmGroups = vmGroups;
                        if(vmGroups.length > 0 && !$scope.spec.selectedVmGroup) {
                           $scope.spec.selectedVmGroup = vmGroups[0].name;
                        }
                        $scope.hostGroups = hostGroups;
                        if(hostGroups.length > 0 && !$scope.spec.selectedHostGroup) {
                           $scope.spec.selectedHostGroup = hostGroups[0].name;
                        }
                     });
            }

            function getVmToHostRuleTypes() {
              var typesArray = [
                 {
                    id: 'hardAffinity',
                    label: getString('rules.config.relationTypes.hardAffinity.label'),
                    isAffineHostGroupName: true,
                    isMandatory: true
                 },
                 {
                    id: 'softAffinity',
                    label: getString('rules.config.relationTypes.softAffinity.label'),
                    isAffineHostGroupName: true,
                    isMandatory: false
                 },
                 {
                    id: 'hardAntiAffinity',
                    label: getString('rules.config.relationTypes.hardAntiAffinity.label'),
                    isAffineHostGroupName: false,
                    isMandatory: true
                 },
                 {
                    id: 'softAntiAffinity',
                    label: getString('rules.config.relationTypes.softAntiAffinity.label'),
                    isAffineHostGroupName: false,
                    isMandatory: false
                 }
              ];

               $scope.spec.selectedVmToHostRuleType = typesArray[0];

               if($scope.spec.operation === clusterRulesConstants.operationType.EDIT) {
                  _.each(typesArray, function(type) {
                     if (type.isMandatory === $scope.spec.vmToHostRuleProperties.isMandatory &&
                           type.isAffineHostGroupName === $scope.spec.vmToHostRuleProperties.isAffineHostGroupName) {
                        $scope.spec.selectedVmToHostRuleType = type;
                     }
                  });
               }
               return typesArray;
            }

            function getString(key) {
               return i18nService.getString('ClusterUi', key);
            }
         }]
      };
   }
})();
