namespace license_ui {

   export class AssetActionsService {
      static $inject = ['$injector'];

      private _vcAssetActionsService: AssetActionsService;

      constructor(private $injector: any) {}

      get vcAssetActionsService() {
         if (!this._vcAssetActionsService) {
            this._vcAssetActionsService = this.$injector.get("vcAssetActionsService");
         }
         return this._vcAssetActionsService;
      }

      removeSolution(solutionsToRemove: string[]): void {
         this.vcAssetActionsService.removeSolution(solutionsToRemove);
      }
   }

   angular.module('com.vmware.vsphere.client.licenseUi').service(
         'assetActionsService',
         AssetActionsService
   );
}
