/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace dvs_ui {

   export class DvsUpgradeNiocWizardManager {

      public static $inject = ["pageStateManagementService"];

      constructor(private pageStateManagementService: any) {
      }

      public init(wizardScope: DvsUpgradeNiocWizardScope): void {
         this.pageStateManagementService
               .registerWizardForDefaultStateManagementStrategy(wizardScope);
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .service("dvsUpgradeNiocWizardManager", DvsUpgradeNiocWizardManager);
}
