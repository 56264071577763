(function() {
   angular.module('com.vmware.vsphere.client.folder').controller('removeFolderController', removeFolderController);

   removeFolderController.$inject = ['folderService', '$scope'];

   function removeFolderController (folderService, $scope) {
      var self = this;
      var objectId = $scope.modalOptions.availableTargets[0];
      self.closeModal = function() {
         $scope.closeModal();
      };

      self.removeFolder = function() {
         folderService.removeFolder(objectId);
         $scope.closeModal();
      };
   }
})();
