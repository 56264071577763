module ds_cluster_ui {

   import IPromise = angular.IPromise;

   /**
    * Builds all the edit SDRS dialog page models and submits the edited SDRS data to the
    * server.
    */
   export class SdrsDialogManager {

      public static $inject = ["i18nService", "mutationService", "vuiConstants", "$q",
            "dsClusterSpecBuilderService", "dsClusterConstants",
            "advancedOptionsBuilderFactory"];

      private storageDrsAutomationPageDef: DialogPage;
      private storageDrsRuntimeSettingsPageDef: DialogPage;
      private storageDrsAdvancedOptionsPageDef: DialogPage;
      private sdrsData: EditStorageDrsData;
      private isFormValid: boolean = true;
      public sdrsDialogOptions: any;


      constructor(private i18nService: any, private mutationService: any,
            private vuiConstants: any, private $q: any,
            private dsClusterSpecBuilderService: DsClusterSpecBuilderService,
            private dsClusterConstants: any, private advancedOptionsBuilderFactory: any) { }

      public initManager(sdrsData: EditStorageDrsData, sdrsDialogOptions: any): void {
         this.sdrsDialogOptions = sdrsDialogOptions;
         this.sdrsData = sdrsData;
         this.createPages();
      }

      public getPageDefinitions(): DialogPage[] {
         return [this.storageDrsAutomationPageDef, this.storageDrsRuntimeSettingsPageDef,
            this.storageDrsAdvancedOptionsPageDef];
      }

      public submit(): IPromise<boolean> {
         let dsClusterId: string = this.sdrsDialogOptions.dialogData.objectId;
         let dsClusterSpec: any = this.getDsClusterReconfigureSpec();

         return this.validate(dsClusterId)
               .then(() => {
                  this.mutationService.apply(dsClusterId,
                        this.dsClusterConstants.DS_CLUSTER_SPEC_TYPE, dsClusterSpec).then(
                        function() {
                           return true;
                        });
                  return true;
               });
      }

      public setFormValidationResult(isValid: boolean): void {
         this.isFormValid = isValid;
      }

      public getSheduleModel(): any {
         return {
            storageDrsAutomationMode: this.getStorageDrsAutomationModel(),
            storageDrsRuntimeSettingsModel: this.getStorageDrsRuntimeSettingsModel(),
            storageDrsAdvancedOptionsModel: this.getStorageDrsAdvancedOptionsModel()
         };
      }

      private validate(dsClusterId: string): IPromise<boolean> {
         this.sdrsDialogOptions.alerts = [];
         this.sdrsDialogOptions.isAlertClosed = false;

         if (!this.isFormValid) {
            return this.$q.reject();
         }

         let dsClusterVmAffinityValidationSpec: any = {
            defaultIntraVmAffinity: this.getStorageDrsAdvancedOptionsModel()
                  .defaultIntraVmAffinity
         };

         return this.mutationService.validate(dsClusterId,
               this.dsClusterConstants.DS_CLUSTER_VM_AFFINITY_VALIDATION_SPEC,
               dsClusterVmAffinityValidationSpec)
               .then(this.handleValidationResult.bind(this));
      }

      private handleValidationResult(validationResult: any): any {
         if (validationResult) {
            if (validationResult.result.conflictingVmdkAARules.length !== 0) {
               this.setValidationError(this.i18nService.getString("DsClusterUi",
                     "sdrs.config.defaultVmAff.disable.conflictingVmdkAARule.descFormat",
                     validationResult.result.conflictingVmdkAARules[0]));

               return this.$q.reject();
            }

            if (validationResult.result.conflictingVmAARules.length !== 0) {
               this.setValidationError(this.i18nService.getString("DsClusterUi",
                     "sdrs.config.defaultVmAff.disable.conflictingVmAARule.descFormat",
                     validationResult.result.conflictingVmAARules[0]));

               return this.$q.reject();
            }
         }

         return this.$q.resolve();
      }

      private setValidationError(validationErrorText: string): void {
         this.sdrsDialogOptions.alerts = [{
            type: this.vuiConstants.validationBanner.type.ERROR,
            text: validationErrorText
         }];
         this.sdrsDialogOptions.isAlertClosed = false;
      }

      public getStorageDrsAutomationModel(): any {
         if (!this.storageDrsAutomationPageDef.model) {
            this.buildStorageDrsAutomationModel();
         }

         return this.storageDrsAutomationPageDef.model;
      }

      public getStorageDrsRuntimeSettingsModel(): any {
         if (!this.storageDrsRuntimeSettingsPageDef.model) {
            this.buildStorageDrsRuntimeSettingsModel();
         }

         return this.storageDrsRuntimeSettingsPageDef.model;
      }

      public getStorageDrsAdvancedOptionsModel(): any {
         if (!this.storageDrsAdvancedOptionsPageDef.model) {
            this.buildStorageDrsAdvancedOptionsModel();
         }

         return this.storageDrsAdvancedOptionsPageDef.model;
      }

      public getFormData(): any {
         return this.getDsClusterReconfigureModel();
      }

      private createPages() {
         this.storageDrsAutomationPageDef = {
            id: "storageDrsAutomation",
            title: this.i18nService.getString("DsClusterUi", "sdrs.config.automationLabel"),
            contentUrl: "ds-cluster-ui/resources/ds-cluster/views/settings/services/editSdrs/storageDrsAutomationPageView.html"
         };

         this.storageDrsRuntimeSettingsPageDef = {
            id: "storageDrsRuntimeSettings",
            title: this.i18nService.getString("DsClusterUi", "sdrs.config.runtimeSettingsLabel"),
            contentUrl:
                  "ds-cluster-ui/resources/ds-cluster/views/settings/services/editSdrs/storageDrsRuntimeSettingsPageView.html"
         };

         this.storageDrsAdvancedOptionsPageDef = {
            id: "storageDrsAdvancedOptions",
            title: this.i18nService.getString("DsClusterUi", "sdrs.config.advancedOptions"),
            contentUrl:
                  "ds-cluster-ui/resources/ds-cluster/views/settings/services/editSdrs/storageDrsAdvancedOptionsPageView.html"
         };
      }

      private buildStorageDrsAutomationModel(): void {
         this.storageDrsAutomationPageDef.model = {
            sdrsEnabled: this.sdrsData.enabled,
            defaultVmBehavior: this.sdrsData.defaultVmBehavior,
            automationOverrides:
                  this.getAutomationOverridesModel(this.sdrsData.automationOverrides)
         };
      }

      private buildStorageDrsRuntimeSettingsModel(): void {
         this.storageDrsRuntimeSettingsPageDef.model = {
            ioLoadBalanceEnabled: this.sdrsData.ioLoadBalanceEnabled,
            ioLatencyThreshold: this.sdrsData.ioLatencyThreshold,
            spaceLoadBalanceConfig: {
               spaceUtilizationThreshold: this.sdrsData.spaceUtilizationThreshold,
               freeSpaceThresholdGB: this.sdrsData.freeSpaceThresholdGB,
               spaceThresholdMode: this.sdrsData.spaceThresholdMode
            },
            minDatastoreCapacityGB: this.sdrsData.minDatastoreCapacityGB
         };
      }

      private getAutomationOverridesModel(automationOverrides: Object): Object {
         let automationOverridesData: Object = automationOverrides || {
                  ioLoadBalanceAutomationMode: null,
                  policyEnforcementAutomationMode: null,
                  ruleEnforcementAutomationMode: null,
                  spaceLoadBalanceAutomationMode: null,
                  vmEvacuationAutomationMode: null
         };

         return _.mapObject(automationOverridesData, function(automationOverride: Object) {
            return {id: automationOverride};
         });
      }

      private getSpaceLoadBalanceConfigModel(): Object {
         let spaceLoadBalanceConfigData: Object = {
                  spaceThresholdMode: this.dsClusterConstants.SDRS_SPACE_THRESHOLD_UTILIZATION,
                  spaceUtilizationThreshold: this.dsClusterConstants.spaceUtilizationThreshold.DEFAULT,
                  freeSpaceThresholdGB: this.dsClusterConstants.freeSpaceThresholdGB.MIN
               };

         return spaceLoadBalanceConfigData;
      }

      private buildStorageDrsAdvancedOptionsModel(): void {
         let ioLoadImbalanceThreshold: number = this.sdrsData.ioLoadImbalanceThreshold;

         if (ioLoadImbalanceThreshold === 0) {
            ioLoadImbalanceThreshold = this.dsClusterConstants.loadBalanceIoThreshold.DEFAULT;
         }

         this.storageDrsAdvancedOptionsPageDef.model = {
            defaultIntraVmAffinity: this.sdrsData.defaultIntraVmAffinity,
            convertedLoadBalanceInterval: this.sdrsData.convertedLoadBalanceInterval,
            loadBalanceInterval: this.sdrsData.loadBalanceInterval,
            ioLoadImbalanceThreshold: ioLoadImbalanceThreshold,
            spaceLoadBalanceConfig: {
               minSpaceUtilizationDifference: this.sdrsData.minSpaceUtilizationDifference
            },
            advancedOptions: this.advancedOptionsBuilderFactory
                  .getBuilder(this.sdrsData.advancedOptions, false)
         };
      }

      private getDsClusterReconfigureModel(): any {
         // build spaceLoadBalanceConfig part of model separately because runtime settings
         // and advanced options pages both use spaceLoadBalanceConfig
         let spaceLoadBalanceConfig: any = _.extend({},
               this.getStorageDrsRuntimeSettingsModel().spaceLoadBalanceConfig,
               this.getStorageDrsAdvancedOptionsModel().spaceLoadBalanceConfig
         );

         let sdrsFormModel: any = _.extend({},
               this.storageDrsAutomationPageDef.model || {},
               this.storageDrsRuntimeSettingsPageDef.model || {},
               this.storageDrsAdvancedOptionsPageDef.model || {}
         );

         sdrsFormModel.spaceLoadBalanceConfig = spaceLoadBalanceConfig;

         return sdrsFormModel;
      }

      private getDsClusterReconfigureSpec(): any {
         return this.dsClusterSpecBuilderService.buildDsClusterSpec(
               this.getDsClusterReconfigureModel(), true);
      }
   }

   angular.module("com.vmware.vsphere.client.dsCluster")
         .service("sdrsDialogManager", SdrsDialogManager);
}
