namespace spbm_ui {

   export class SpbmDspActionService {
      static $inject = ['$injector'];

      private _vcsSpbmDspActionService: SpbmDspActionService;

      constructor(private $injector: any) {
      }

      get vcsSpbmDspActionService() {
         if (!this._vcsSpbmDspActionService) {
            this._vcsSpbmDspActionService = this.$injector.get("vcsSpbmDspActionService");
         }
         return this._vcsSpbmDspActionService;
      }

      createProfile(): void {
         this.vcsSpbmDspActionService.createProfile();
      }

      editProfile(profileId: string): void {
         this.vcsSpbmDspActionService.editProfile(profileId);
      }

      cloneProfile(profileId: string): void {
         this.vcsSpbmDspActionService.cloneProfile(profileId);
      }

      removeProfiles(profileIds: string[]): void {
         this.vcsSpbmDspActionService.removeProfiles(profileIds);
      }
   }

   angular.module("com.vmware.vsphere.client.spbm")
         .service("spbmDspActionService", SpbmDspActionService);
}
