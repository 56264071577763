/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
(function () {
   'use strict';

   /**
    * Opens Edit port group dialog as a multi page dialog.
    */
   angular
         .module('com.vmware.vsphere.client.network')
         .service('editPortGroupDialogService', editPortGroupDialogService);

   editPortGroupDialogService.$inject = [
         'dataService',
         '$rootScope',
         'i18nService',
         'vxMultiPageDialogService',
         'PortGroupDialogManager'];

   function editPortGroupDialogService(
         dataService,
         $rootScope,
         i18nService,
         vxMultiPageDialogService,
         PortGroupDialogManager) {

      return {
         show: show
      };

      function show(hostId, actionEval, portgroupKey, portgroupName) {
         var portgroupDialogDataProperty = "portgroup:portgroupDialogData";
         var portgroupDialogDataParams = {
            propertyParams: [{
               propertyName: portgroupDialogDataProperty,
               parameterType: 'java.lang.String',
               parameter: portgroupKey
            }]
         };

         var portGroupData = {
            properties: {},
            securityPolicies: {},
            trafficShapingPolicies: {},
            teamingAndFailover: {}
         };
         var dialogScope = $rootScope.$new();
         dialogScope.dialogOptions = {};

         var portGroupDialogManager = new PortGroupDialogManager(portGroupData,
               dialogScope.dialogOptions);
         dialogScope.manager = portGroupDialogManager;

         _.extend(dialogScope.dialogOptions, {
            title: i18nService.getString(
                  'NetworkUi', 'EditPortgroupSettingsView.title', ''),
            iconClass: actionEval.action.icon,
            //reduced height due to overridden margins and padding in nw-edit-pg-page
            height: '676px',
            width: '1000px',
            actionDetails: {
               actionEval: actionEval,
               availableTargets: [hostId]
            },
            pages: portGroupDialogManager.getPageDefinitions(),
            show: true,
            loading: true, // this prevents the dialog from being initialized
                           // until port group data is loaded
            loadingMessage: i18nService.getString('CommonUi', 'wizard.loading'),
            confirmOptions: {
               onClick: portGroupDialogManager.submit
            },
            validationBanner: {},
            rejectOptions: {
               rejectOnEsc: true
            },
            resizable: true,
            draggable: true,
            maximizable: true
         });

         var options = {
            scope: dialogScope,
            configObjectName: 'dialogOptions'
         };

         vxMultiPageDialogService(options);

         dataService.getProperties(
               hostId, [portgroupDialogDataProperty], portgroupDialogDataParams)
               .then(function (response) {
                  _.extend(portGroupData, response[portgroupDialogDataProperty]);
                  portGroupData.hostId = response.id;
                  dialogScope.dialogOptions.title =
                        i18nService.getString('NetworkUi',
                              'EditPortgroupSettingsView.title', portgroupName);
                  dialogScope.dialogOptions.loading = false;
                  dialogScope.initialData =
                        angular.copy(dialogScope.manager.portgroupData);
               });
      }
   }
})();
