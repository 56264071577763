namespace storage_ui {
   export class PartitionsListController {
      public static $inject = ["i18nService", "hostStorageService",
         "vuiConstants", "bytesFilter", "storageUtil"];

      private partitions: any[];

      private i18n: Function;
      private datagridOptions: any;

      constructor(
            i18nService: any,
            private hostStorageService: any,
            vuiConstants: any,
            bytesFilter: any,
            storageUtil: any) {

         this.i18n = i18nService.getString.bind(this, "StorageUi");
         this.datagridOptions = {
            data: this.partitions,
            pageConfig: {
               hidePager: false
            },
            columnDefs: [{
               displayName: this.i18n("storage.devices.column.name"),
               field: 'name',
               template: (dataItem: any) => {
                  return this.i18n(`datastore.partitionType.${dataItem.name}`);
               }
            }, {
               displayName: this.i18n("storage.devices.column.capacity"),
               field: "partitionCapacity",
               template: (dataItem: any) => {
                  return bytesFilter(dataItem.partitionCapacity, "B", "Auto");
               },
               sortable: function(item1: any, item2: any) {
                  return storageUtil.compareNumericValues(item1, item2, "partitionCapacity");
               }
            }, {
               displayName: this.i18n("storage.devices.column.partitionType"),
               field: "isLogical",
               type: "boolean",
               template: (dataItem: any) => {
                  return (dataItem.isLogical)
                        ? this.i18n("storage.devices.partitionType.logical")
                        : this.i18n("storage.devices.partitionType.primary");
               }
            }],
            sortMode: vuiConstants.grid.sortMode.SINGLE,
            selectionMode: vuiConstants.grid.selectionMode.NONE,
            selectedItems: [],
            resizable: false,
            columnMenu: {
               sortable: false,
               messages: {
                  columns: i18nService.getString("CommonUi", "listview.showColumnsMenu"),
                  filter: i18nService.getString("CommonUi", "listview.filterMenu")
               }
            }
         };
      }

      public $onChanges() {
         this.datagridOptions.data = this.partitions;
      }
   }

   angular.module("com.vmware.vsphere.client.storage")
         .component("partitionsList", {
            template: `<div class='flex-grid' vui-datagrid='$ctrl.datagridOptions'><div>`,
            bindings: {
               partitions: "<"
            },
            controller: PartitionsListController
         });
}
