/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace dvs_ui {

   export class DvsSpanSessionPropertiesPageModel implements DvsSpanSessionValidateableModel {
      public sessionName: string;
      public enabled: boolean;

      public mirroredPacketLength: number;
      public mirroredPacketLengthEnabled: boolean;

      public samplingRate: number;

      public description: string;

      // L3 source session properties
      public erspanSupported: boolean;
      public encapsulationType: string;
      public sessionId: number;
      public isTcpIpStackSupported: boolean;
      public tcpIpStack: string;

      // Remote source session properties
      public preserveOriginalVlan: boolean;
      public encapsulationVlanId: number;
      public normalTrafficAllowed: boolean;

      public errors: string[];
   }
}
