/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace h5_vm {

   export class VmProvisioningSelectTemplateComponent implements angular.IComponentOptions {
      public controller: any;
      public templateUrl: string;
      public bindings: { [binding: string]: string };

      constructor() {
         this.bindings = {
            pageModel: "<",
            wizardViewData: "<",
            onSelectionChanged: "&"
         };
         this.controller = VmProvisioningSelectTemplateToConvertPageController;
         this.templateUrl = "vm-ui/resources/vm/views/createVmWizard/vm-provisioning-select-template-page.component.html";
      }
   }

   export class VmProvisioningSelectTemplateToConvertPageController {
      public static $inject: string[] = [
         "defaultUriSchemeUtil",
         "i18nService"
      ];

      public pageModel: VmProvisioningSelectTemplateToConvertPageModel;
      public wizardViewData: any;
      public compatibilityMessage: any;
      public preselectionId: any;
      public treeViewConfig: Object = {
         preselectRoot: false,
         expandPreselectedNode: true
      };
      public onSelectionChanged: () => {};

      public selectTemplateDescription: string;

      constructor(private defaultUriSchemeUtil: any, private i18nService: any) {
         this.selectTemplateDescription = this.i18nService.getString(
               "VmUi", "SelectTemplateProvisioningPage.Template.Description");
      }

      public $onInit(): void {
         let creationType: string = this.pageModel.virtualMachineSpecBuilder.getCreationType();
         if (creationType === this.pageModel.getLastSelectedCreationType()) {
            this.preselectionId = this.pageModel.getSelectionId();
         } else {
            this.pageModel.clearSelection();
         }
      }

      public onChange(item: VxTreeViewItem): void {
         this.setLoadingIndicator(true);
         this.pageModel.updateSelection(item);

         this.triggerOnSelectionChange();
         this.pageModel.validateSelection().then((compatibilityMessage: string) => {
            this.compatibilityMessage = compatibilityMessage;
            this.setLoadingIndicator(false);
         });
      }

      public setLoadingIndicator(setting: boolean) {
         if (this.pageModel.showLoadingIndicator) {
            this.pageModel.config.loading = setting;
         }
      }

      private triggerOnSelectionChange() {
         if (this.onSelectionChanged) {
            this.onSelectionChanged();
         }
      }
   }

   angular.module("com.vmware.vsphere.client.vm")
         .component("vmProvisioningSelectTemplate",
               new VmProvisioningSelectTemplateComponent());
}
