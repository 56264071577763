/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
/**
 * Custom grid for displaying bindings between host physical network adapters
 * and DVS uplinks
 */
(function () {
   'use strict';

   angular
         .module('com.vmware.vsphere.client.network')
         .directive('uplinkPortMapping', uplinkPortMapping);

   uplinkPortMapping.$inject = [
      '$timeout',
      'vuiConstants',
      'i18nService',
      'pnicSelectorDialogService',
      'clarityModalService',
      'networkUiConstants'];

   function uplinkPortMapping ($timeout,
                               vuiConstants,
                               i18nService,
                               pnicSelectorDialogService,
                               clarityModalService,
                               networkUiConstants) {
      return {
         restrict: 'E',
         template: '<div class="fill-parent uplink-port-mapping"' +
         'vx-stretch-grid vui-datagrid="datagridOptions"'+
         'vx-datagrid-preselect-item="true" reselect-on-change="true" ' +
         'preselect-comparator="preselectComparator"></div>',
         scope: {
            portMapping: '=',
            unclaimedPnics: '=',
            pnicDetails: '=',
            onSelectionChange: '=',
            allowOldMappingsRemoval: '=?' // defaults to false
         },
         replace: false,
         link: {
            pre: function ($scope) {
               if($scope.allowOldMappingsRemoval === undefined){
                  $scope.allowOldMappingsRemoval = false;
               }
               var addAction = {
                  id: networkUiConstants.failoverOrderActions.ADD,
                  iconClass: 'vsphere-icon-add',
                  tooltipText: i18nService.getString("NetworkUi", "FailoverOrder.add"),
                  onClick: showPnicSelectorDialog
               };

               var removeAction = {
                  id: networkUiConstants.failoverOrderActions.REMOVE,
                  iconClass: 'vsphere-icon-remove',
                  tooltipText: i18nService.getString("NetworkUi", "FailoverOrder.remove"),
                  onClick: removePnic
               };

               var actionDefinitions = [addAction, removeAction];

               $scope.datagridOptions = {
                  columnDefs: _getColumnDefinitions($scope.portMapping.lagMapping, $scope.portMapping.uplinkPortgroups),
                  data: _createData(),
                  pageConfig: {
                     hidePager: true
                  },
                  selectedItems: [],
                  resizable: true,
                  height: '100%',
                  sortMode: vuiConstants.grid.sortMode.NONE,
                  searchable: false,
                  selectionMode: vuiConstants.grid.selectionMode.MULTI,
                  showCheckboxesOnMultiSelection: false,
                  actionBarOptions: {
                     actions: actionDefinitions
                  }
               };

               $scope.preselectComparator = _preselectComparator;

               var selectedUplinks;
               var selectedUplinksBeforeUpdate;

               $scope.$watch(function () {
                  return $scope.datagridOptions.selectedItems;
               }, function (selectedItems) {
                  if ($scope.onSelectionChange) {
                     $scope.onSelectionChange(selectedItems);
                  }
                  if(selectedItems){
                     selectedUplinks = _.pluck(selectedItems, "uplink");
                  } else {
                     selectedUplinks = [];
                  }
                  recalculateActionsState();
               });

               // Workaround which resizes datagrid after the action bar creation
               $timeout(function () {
                  var gridElement = $("div.uplink-port-mapping .k-grid");
                  if (gridElement) {
                     var kendoGrid = gridElement.data("kendoGrid");
                     if (kendoGrid) {
                        kendoGrid.resize();
                     }
                  }
               }, 0);

               function recalculateActionsState () {
                  addAction.enabled = _hasFreeUplink($scope.portMapping.uplinkPortMapping);

                  var isRemoveActionEnabled = false;
                  if(selectedUplinks && selectedUplinks.length > 0) {
                     if ($scope.allowOldMappingsRemoval) {
                        isRemoveActionEnabled = _.some(selectedUplinks, isUplinkAssigned);
                     } else {
                        isRemoveActionEnabled = getSelectedUplinksWithAddedPnic().length > 0;
                     }
                  }

                  removeAction.enabled = isRemoveActionEnabled;
               }

               function _preselectComparator (datagridItem) {
                  return _.contains(selectedUplinksBeforeUpdate, datagridItem.uplink);
               }

               function showPnicSelectorDialog(){
                   if($scope.portMapping.unclaimedPnics.length > 0) {

                     var dropdownOptions = [{
                        id: 'uplink',
                        title: i18nService.getString('NetworkUi', 'UplinkPortMappingAssignDialog.uplinkPort'),
                        items: _createUplinkItems($scope.portMapping.uplinkPortMapping, $scope.portMapping.lagMapping),
                        selectedItem: _getOnlySelectedOrFirstFreeUplinkMapping(selectedUplinks, $scope.portMapping.uplinkPortMapping).uplinkPort.name
                     }];
                      if($scope.portMapping.uplinkPortgroups.length > 1) {
                         var uplinkPortgroupItems = _createPortgroupItems($scope.portMapping.uplinkPortgroups);
                         dropdownOptions.push({
                            id: 'portgroup',
                            title: i18nService.getString('NetworkUi', 'UplinkPortMappingAssignDialog.uplinkPortGroup'),
                            items: uplinkPortgroupItems,
                            selectedItem: uplinkPortgroupItems[0].key
                         });
                      }

                     pnicSelectorDialogService.createPnicSelectorDialog($scope.portMapping.unclaimedPnics, $scope.portMapping.pnicDetails, vuiConstants.grid.selectionMode.SINGLE, dropdownOptions, onPnicAdded);
                  } else {
                      var modalOptions = {
                         message: i18nService.getString('NetworkUi', 'FailoverOrderEditor.noFreeNics'),
                         title: i18nService.getString('H5NetworkUi', 'failoverOrder.notification.warning'),
                         icon: "vx-icon-warning-32x32",
                         hideCancelButton: true,
                         preserveNewlines: true,
                         submit: function() {}
                      };

                      clarityModalService.openConfirmationModal(modalOptions);
                  }
               }

               function removePnic() {
                  var uplinksToUnassign;
                  if($scope.allowOldMappingsRemoval){
                     uplinksToUnassign = _.filter(selectedUplinks, isUplinkAssigned);
                  } else {
                     uplinksToUnassign = getSelectedUplinksWithAddedPnic();
                  }

                  var pnicNames = _.map(uplinksToUnassign, _getPnicByUplinkName);

                  $scope.portMapping.unclaimedPnics = $scope.portMapping.unclaimedPnics.concat(pnicNames);
                  $scope.portMapping.addedMappings = _.omit($scope.portMapping.addedMappings, uplinksToUnassign);

                  _.each(uplinksToUnassign, function(uplink) {
                     var mapping = _getUplinkPortMappingByUplinkName(uplink);
                     mapping.pnicName = null;
                     mapping.uplinkPortgroup = null;
                  });
                  $scope.datagridOptions.data = _updateData();
               }

               function onPnicAdded(pnics, dropdownSelection) {
                  var addedUplink = dropdownSelection['uplink'];
                  selectedUplinks = [addedUplink];

                  if (pnics && pnics.length === 1) {
                     var pnicName = pnics[0];

                     //remove the added pnic from unclaimedPnics
                     var index = _.indexOf($scope.portMapping.unclaimedPnics, pnicName);
                     $scope.portMapping.unclaimedPnics.splice(index, 1);

                     //mark it as newly added
                     $scope.portMapping.addedMappings[addedUplink] = pnicName;

                     //update the displayed mappings
                     var uplinkPortMapping = _getUplinkPortMappingByUplinkName(addedUplink);
                     uplinkPortMapping.pnicName = pnicName;

                     //update the portgroup in the mapping
                     var selectedPortgroupKey = dropdownSelection['portgroup'];
                     var uplinkPortgroup =
                           selectedPortgroupKey ?
                                 _getUplinkPortgroupByKey($scope.portMapping.uplinkPortgroups, selectedPortgroupKey) :
                                 $scope.portMapping.uplinkPortgroups[0];

                     uplinkPortMapping.uplinkPortgroup = uplinkPortgroup;

                     //update the grid
                     $scope.datagridOptions.data =  _updateData();
                  }
               }

               function _updateData() {
                  selectedUplinksBeforeUpdate = selectedUplinks;
                  return _createData();
               }

               function _createData() {
                  var data = [];

                  _.forEach($scope.portMapping.uplinkPortMapping, function (portMapping) {
                     data.push({
                        uplink: portMapping.uplinkPort.name,
                        pnic: portMapping.pnicName,
                        isNew: !!$scope.portMapping.addedMappings[portMapping.uplinkPort.name],
                        uplinkPortgroup: _getPortgroupName(portMapping.uplinkPortgroup)
                     });
                  });

                  return _.sortBy(data, 'uplink');
               }

               /**
                * Return the selected item if it's free or the first free uplink (sorted by name)
                */
               function _getOnlySelectedOrFirstFreeUplinkMapping(selectedUplinks, uplinkPortMapping) {
                  var mapping;
                  if(selectedUplinks.length === 1 && isUplinkFree(selectedUplinks[0])){
                     mapping = _getUplinkPortMappingByUplinkName(selectedUplinks[0]);
                  } else {
                     //the first in the lexicographic order as we display them everywhere this way
                     var sortedMappings = _.sortBy(uplinkPortMapping, function (portMapping) {
                        return portMapping.uplinkPort.name;
                     });
                     mapping = _.find(sortedMappings, function (portMapping) {
                        return portMapping.pnicName === null;
                     });
                  }
                  return mapping;
               }

               function _getUplinkPortMappingByUplinkName (uplinkName) {
                  return _.find($scope.portMapping.uplinkPortMapping, function(portMapping) {
                     return portMapping.uplinkPort.name === uplinkName;
                  });
               }

               function getSelectedUplinksWithAddedPnic(){
                  return _.intersection(_.keys($scope.portMapping.addedMappings), selectedUplinks);
               }

               function _getPnicByUplinkName (uplinkName) {
                  return _getUplinkPortMappingByUplinkName(uplinkName).pnicName;
               }

               function isUplinkFree (selectedUplink) {
                  return _getPnicByUplinkName(selectedUplink) === null;
               }

               function isUplinkAssigned (selectedUplink) {
                  return _getPnicByUplinkName(selectedUplink) !== null;
               }
            }
         }
      };

      function _getColumnDefinitions(lagMapping, uplinkPortgroups) {
         var columnDefs = [];

         var uplinkColumnDef = {
            field: 'uplinkPort',
            displayName: i18nService.getString('NetworkUi', 'UplinkPortMappingEditor.column.nic'),
            width: "150px",
            template: function (dataItem) {
               var uplinkElement = '<span ng-non-bindable class="uplink-name">' +
                     _formatUplinkName(dataItem.uplink, lagMapping) + '</span> </br>';

               var pnicElement;
               if (dataItem.pnic) {
                  pnicElement = '<span class="network-lib-ui-icon-physicalAdapter"></span>' +  // icon
                        '<span ng-non-bindable>' + _formatName(dataItem) + '</span>';                      // name
               } else {
                  pnicElement = '<span ng-non-bindable>' + i18nService.getString('NetworkUi', 'UplinkPortMappingEditor.noNic') + '</span>';
               }

               return uplinkElement + pnicElement;
            }
         };

         var portgroupColumnDef = {
            field: 'uplinkPortgroup',
            displayName: i18nService.getString('NetworkUi', 'UplinkPortMappingEditor.column.portGroup'),
            width: "150px"
         };

         columnDefs.push(uplinkColumnDef);

         if(uplinkPortgroups.length > 1) {
            columnDefs.push(portgroupColumnDef);
         }

         return columnDefs;
      }

      /**
       * Adds (New) at the beginning of the name if needed.
       */
      function _formatName(dataItem) {
         return (dataItem.isNew)
            ? i18nService.getString("NetworkUi", "UplinkPortMappingEditor.newNicFormat", dataItem.pnic)
            : dataItem.pnic;
      }

      /**
       * Format the uplink if it is associated with lag
       */
      function _formatUplinkName(uplink, lagMapping) {
         var lagName = _getUplinkLag(uplink, lagMapping);

         return lagName ? i18nService.getString('NetworkUi',
               'UplinkPortMappingEditor.lagPortFormat', uplink, lagName) : uplink;

      }

      function _getUplinkLag(uplink, lagMapping) {
         var lagName = null;

         for (var lag in lagMapping) {
            if (lagMapping.hasOwnProperty(lag) && _.indexOf(lagMapping[lag], uplink) !== -1) {
               lagName = lag;
            }
         }
         return lagName;
      }

      function _hasFreeUplink(uplinkPortMapping) {
         return _.some(uplinkPortMapping, function (portMapping) {
            return portMapping.pnicName === null;
         });
      }

      /**
       * Return array of objects containing uplinks as key and uplink name for value.
       * (Uplinks associated with lags has special formated name)
       */
      function _createUplinkItems(uplinkPortMapping, lagMapping) {
         var uplinkItems = [];
         _.each(uplinkPortMapping, function (portMapping) {
            if(portMapping.pnicName === null) {
               var uplinkName = _formatUplinkName(portMapping.uplinkPort.name, lagMapping);
               uplinkItems.push({
                  key: portMapping.uplinkPort.name,
                  value: uplinkName
               });
            }
         });
         return _.sortBy(uplinkItems, 'value');
      }

      function _createPortgroupItems(uplinkPortgroups) {
         var uplinkPortgroupItems = [];
         _.each(uplinkPortgroups, function (uplinkPortgroup) {
            uplinkPortgroupItems.push({
               key: uplinkPortgroup.key,
               value: uplinkPortgroup.name
            });
         });

         return _.sortBy(uplinkPortgroupItems, 'value');
      }

      function _getPortgroupName(portgroup) {
         if(portgroup) {
            return portgroup.name;
         }
         return null;
      }

      function _getUplinkPortgroupByKey(uplinkPortgroups, uplinkPortgroupKey) {
         return _.find(uplinkPortgroups, function (uplinkPortgroup) {
            return uplinkPortgroup.key === uplinkPortgroupKey;
         });
      }
   }
})();
