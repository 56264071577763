module host_ui {

   interface BootDevice {
      key: string;
      description: string;
   }

   class EditBootOptionsController implements angular.IController {

      static $inject = ["$scope", "dataService", "mutationService", "i18nService"];

      public bootDevices: Array<BootDevice>;
      public selectedOption: BootDevice;
      private hostId: string;

      constructor (private $scope: any,
                   private dataService: any,
                   private mutationService: any,
                   private i18nService: any
                  ) {
         this.hostId = $scope._route.objectId;

         $scope.modalOptions.onSubmit = this.onSubmit.bind(this);
         this.getBootDevices();
      }

      private getBootDevices(): void {
         this.dataService.getProperties(this.hostId, ["bootDeviceInfo"])
             .then((data: any) => {
                if (data && data.bootDeviceInfo) {
                   this.bootDevices = data.bootDeviceInfo.bootDevices;
                   this.selectedOption = this.getCurrentDeviceByKey(data.bootDeviceInfo.currentBootDeviceKey);
                } else {
                   this.bootDevices = [];
                   this.selectedOption = {
                      key: "",
                      description: ""
                   };
                }
             });
      }

      private getCurrentDeviceByKey(bootDeviceKey: string): BootDevice {
         return _.find(this.bootDevices, function (bootDevice) {
            return bootDeviceKey === bootDevice.key;
         });
      }

      private onSubmit(): boolean {
         var bootOptionsSpec = {
            bootDeviceKey: this.selectedOption.key,
            rebootAfterApply: false
         };
         this.mutationService.apply(this.hostId,
             "com.vmware.vsphere.client.host.config.BootDeviceConfigSpec",
             bootOptionsSpec,
             this.i18nService.getString("HostUi", "bootDevice.operationName"));
         return true;
      }
   }

   angular.module("com.vmware.vsphere.client.host")
       .controller("EditBootOptionsController", EditBootOptionsController);
}
