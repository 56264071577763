namespace storage_ui {

   export class HostIoFilterViewController {

      public static $inject = ["navigation"];

      constructor(private navigation: any) {
      }

      get hostId(): string {
         return this.navigation.getRoute().objectId;
      }
   }

   angular.module("com.vmware.vsphere.client.storage").controller(
         "HostIoFilterViewController",
         HostIoFilterViewController);
}
