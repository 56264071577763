namespace license_ui {

   export class AssetSettingsLicenseController {
      public static $inject = ["$scope", "i18nService"];

      public headerOptions: any;
      public objectId: string;
      public shouldShowActions: boolean;

      constructor(private $scope: any,
                  private i18nService: any) {
      }

      public $onInit() {
         this.objectId = this.$scope._route.objectId ||
               this.$scope._vxViewNodeData.node.contentSpec.metadata.objectId;

         this.shouldShowActions = false;

         this.headerOptions = {
            title: this.i18nService.getString("LicenseLibUi", "management.assets.settings.title"),
            objectId: this.objectId,
            actions: []
         };
      }

      public refreshActionsVisible(data: any): void {
         if (!data || !data.asset || !data.asset.managedObjectRef) {
            return;
         }

         // if there are assets we put assign license in headerOptions actions and show them
         this.shouldShowActions = !data.asset.notFound;
         if (this.shouldShowActions) {

            let metadata = this.$scope._vxViewNodeData.node.contentSpec.metadata;
            if (metadata && metadata.actionId) {
               this.headerOptions.actions = [{
                  actionUid: metadata.actionId,
                  customLabel: this.i18nService.getString("LicenseLibUi", "action.assignLicense.operationName")
               }];
            }
         }
      }
   }

   angular.module('com.vmware.vsphere.client.licenseUi').controller(
         'AssetSettingsLicenseController',
         AssetSettingsLicenseController
   );
}
