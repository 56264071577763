(function() {
   angular.module('com.vmware.vsphere.client.folder').controller('addFolderController', addFolderController);

   addFolderController.$inject = ['$scope', 'folderService', 'i18nService'];

   function addFolderController($scope, folderService, i18nService) {
      var self = this;
      self.name = '';
      self.moRef = $scope.modalOptions.dialogData.objectId;
      self.FOLDER_MAX_LENGTH = 80;
      this.$onInit = function () {
         self.i18n = i18nService.getString;
      };
      self.closeModal = function() {
         $scope.closeModal();
      };

      self.createFolder = function() {
         self.compatibilityMessage = validateFolderName();

         if(self.compatibilityMessage === null) {
            var folderType = getFolderType();
            if (folderType) {
               folderService.createFolder(self.name, self.moRef, folderType);
            } else {
               folderService.createFolder(self.name, self.moRef);
            }
            $scope.closeModal();
         }
      };

      function getFolderType() {
         var actionUid = $scope.modalOptions.actionEval.action.uid;
         var folderType;

         if (/Vm/.test(actionUid)) {
            folderType = 'VirtualMachineFolder';
         } else if(/Host/.test(actionUid)) {
            folderType = 'HostFolder';
         } else if(/Storage/.test(actionUid)) {
            folderType = 'DatastoreFolder';
         } else if(/Network/.test(actionUid)) {
            folderType = 'NetworkFolder';
         }

         return folderType;
      }

      function validateFolderName() {
         var hasValidLength = self.name.length <= self.FOLDER_MAX_LENGTH;
         if (!hasValidLength) {
            return self.i18n('VmUi',
               'NameValidator.nameTooLongSpecialChars');
         }
         return null;
      }

   }
})();
