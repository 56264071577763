namespace h5_vm {

   import IController = angular.IComponentController;

   class VmProvisioningProductDetailsController implements IController {

      static $inject: string[] = [
         "$scope",
         "i18nService",
         "vuiConstants",
         "vmProvisioningProductDetailsDataModel"
      ];
      private readonly EULA_OBJ_TYPE: string = "com.vmware.vcenter.ovf.EulaParams";
      private readonly DEPLOYMENT_OPTIONS_OBJ_TYPE: string = "com.vmware.vcenter.ovf.DeploymentOptionParams";

      private viewData: any;
      private vmSpec: any;
      private model: any;

      constructor(private $scope: any,
                  private i18nService: any,
                  private vuiConstants: any,
                  private vmProvisioningProductDetailsDataModel: any) {

         this.viewData = this.$scope.wizardViewData;
         this.vmSpec = this.$scope.vmParams;

         let commonContext = this.viewData.getDeployFromLibraryCommonContext();

         this.model = this.$scope.productDetailsPageModel;
         this.model.dataModel = vmProvisioningProductDetailsDataModel;
         this.model.dataModel.initProductDetails(commonContext);
         this.model.dataModel.updateVmNameToDeploy(this.vmSpec.getName(), commonContext);
         this.model.form.isReady = true;

         this.controlFutureWizardPagesBehavior(commonContext);
      }

      private controlFutureWizardPagesBehavior(commonContext: any): void {
         let eulaPage: any = this.getEulaWizardPageObject(this.$scope.vuiWizardPages);
         let deploymentConfigPage: any = this.getDeploymentOptionsWizardPageObject(this.$scope.vuiWizardPages);
         let scaleoutConfigurationPage: any = this.getScaleoutConfigurationPageObject(this.$scope.vuiWizardPages);
         let networkPage: any = this.getNetworkPageObject(this.$scope.vuiWizardPages);
         let vserviceProviderPage: any = this.getVservicePageObject(this.$scope.vuiWizardPages);

         let customizeTemplatePage: any = this.getCustomizeTemplatePageObject(this.$scope.vuiWizardPages);

         if(eulaPage){
            eulaPage.state = this.unskipEulaPage(commonContext) ?
                this.vuiConstants.wizard.pageState.DISABLED : this.vuiConstants.wizard.pageState.SKIPPED;
         }

         if(deploymentConfigPage){
            deploymentConfigPage.state = this.unskipDeploymentConfigPage(commonContext) ?
                this.vuiConstants.wizard.pageState.DISABLED : this.vuiConstants.wizard.pageState.SKIPPED;
         }

         if(scaleoutConfigurationPage){
            scaleoutConfigurationPage.state = this.unskipScaleoutConfiguration() ?
                this.vuiConstants.wizard.pageState.DISABLED : this.vuiConstants.wizard.pageState.SKIPPED;
         }

         if(networkPage){
            networkPage.state = this.unskipNetworksPage() ?
                this.vuiConstants.wizard.pageState.DISABLED : this.vuiConstants.wizard.pageState.SKIPPED;
         }

         if (customizeTemplatePage) {
            customizeTemplatePage.state = this.unskipCustomizeTemplatePage() ?
               this.vuiConstants.wizard.pageState.DISABLED : this.vuiConstants.wizard.pageState.SKIPPED;
         }

         if (vserviceProviderPage) {
            vserviceProviderPage.state = this.unskipVserviceProviderPage() ?
               this.vuiConstants.wizard.pageState.DISABLED : this.vuiConstants.wizard.pageState.SKIPPED;
         }
      }

      private getEulaWizardPageObject(vuiWizardPages: any): any {
         return _.find(vuiWizardPages, (page: any) => {
               return (page.title ===
                  this.i18nService.getString("Common", "EulaControl.title"));
            }
         );
      }

      private getDeploymentOptionsWizardPageObject(vuiWizardPages: any): any {
         return _.find(vuiWizardPages, (page: any) => {
               return (page.title === this.i18nService.getString("Common", "DeploymentOptions.title"));
            }
         );
      }

      private getScaleoutConfigurationPageObject(vuiWizardPages: any): any {
         return _.find(vuiWizardPages, (page: any) => {
               return (page.title === this.i18nService.getString("ProvisioningUiLib", "ScaleoutPage.title"));
            }
         );
      }
      private getNetworkPageObject(vuiWizardPages: any): any {
         return _.find(vuiWizardPages, (page: any) => {
               return (page.title === this.i18nService.getString("ProvisioningUiLib", "SelectNetworksPage.title"));
            }
         );
      }

      private getCustomizeTemplatePageObject(vuiWizardPages: any): any {
         return _.find(vuiWizardPages, (page: any) => {
            return (page.title === this.i18nService.getString("Common", "UserDefinedPropertiesPage.title"));
         }
         );
      }

      private getVservicePageObject(vuiWizardPages: any): any {
         return _.find(vuiWizardPages, (page: any) => {
                  return (page.title === this.i18nService.getString("ProvisioningUiLib", "VServicePage.title"));
               }
         );
      }

      private unskipEulaPage(commonContext: any): boolean {
         let eulaInfo: any = _.find(commonContext, (item: any) => {
            return (_.isEqual(item._type, this.EULA_OBJ_TYPE));
         });

         return (eulaInfo && !_.isEmpty(eulaInfo.eulas));
      }

      private unskipDeploymentConfigPage(commonContext: any): boolean {
         let deploymentOptions: any = _.find(commonContext, (item: any) => {
            return (_.isEqual(item._type, this.DEPLOYMENT_OPTIONS_OBJ_TYPE));
         });

         return !_.isUndefined(deploymentOptions);
      }
      private unskipScaleoutConfiguration(): boolean {
         let scaleoutConfiguration: any = this.viewData.getSclaeoutConfigurationFromLibraryCommonContext();

         return !_.isUndefined(scaleoutConfiguration) &&
               Array.isArray(scaleoutConfiguration.groups) &&
               scaleoutConfiguration.groups.length > 0;
      }

      private unskipNetworksPage(): boolean {
         let networkMappingParams: any = this.viewData.getNetworkMappingParamsFromLibraryCommonContext();
         let ipAllocationParams: any = this.viewData.getIpAllocationParamsFromLibraryCommonContext();

         return !_.isUndefined(networkMappingParams) ||
               !_.isUndefined(ipAllocationParams) ;

      }

      private unskipCustomizeTemplatePage(): boolean {
         let userDefinedProperties: any = this.viewData.getCustomizeTemplateFromLibraryCommonContext();
         return !_.isUndefined(userDefinedProperties);
      }

      private unskipVserviceProviderPage(): boolean {
         let vserviceParams: any = this.viewData.getVserviceContextFromLibraryCommonContext();
         return !_.isUndefined(vserviceParams);
      }
   }

   angular
      .module("com.vmware.vsphere.client.vm")
      .controller("vmProvisioningProductDetailsController",
         VmProvisioningProductDetailsController);
}
