angular.module('com.vmware.vsphere.client.vm').directive('vmHardwareSummaryNetwork', [
   'dataService',
   'vmDeviceInfoService',
   'i18nService',
   'scopeUtil',
   'defaultUriSchemeUtil',
   'navigation',
   function (dataService, vmDeviceInfoService, i18nService, scopeUtil, defaultUriSchemeUtil, navigation) {
   return {
      scope: {
         device: '=',
         ctrl: '='
      },
      templateUrl: 'vm-ui/resources/vm/views/summary/vmHardwareSummaryNetwork.html',
      link: function (scope) {
         var DISTRIBUTED_PORTGROUP_BACKING_TYPE =
               'com.vmware.vim.binding.vim.vm.device.VirtualEthernetCard$DistributedVirtualPortBackingInfo';
         var OPAQUE_NETWORK_BACKING_TYPE =
               'com.vmware.vim.binding.vim.vm.device.VirtualEthernetCard$OpaqueNetworkBackingInfo';

         scope.i18n = i18nService.getString;
         scope.isTemplate = scope.ctrl.hardwareViewData.memoryData.isVmTemplate;
         scope.ethernetCardLabel = vmDeviceInfoService.getEthernetCardLabel(scope.device._type);
         navigation.populateScope(scope);

         scope.$watch('device', function() {
            setNetworkProperties(scope.device.backing);
         });

         if (!scope.ctrl.isVmTemplateCLitem) {
            scopeUtil.watchUntilTrue(scope, 'expanded', function () {
               var vmId = defaultUriSchemeUtil.getVsphereObjectId(scope.ctrl.hardwareViewData.provider);

               dataService.getProperties(vmId, 'runtime').then(function (vmProperties) {
                  var deviceRuntimeData = _.find(vmProperties.runtime.device, function (d) {
                     return d.key === scope.device.key;
                  });
                  if (deviceRuntimeData) {
                     scope.directpathEnabled = deviceRuntimeData.runtimeState.vmDirectPathGen2Active;
                  }
               });
            });
         }

         scope.labelForConnectionState = function () {
            if (!scope.device.connectable) {
               return;
            }
            var connectionLabel = i18nService.getString('VmUi', scope.device.connectable.connected ?
                  'VmHardwareView.deviceConnected' :
                  'VmHardwareView.deviceDisconnected');
            return i18nService.getString('VmUi', 'VmNetView.networkConnection', connectionLabel);
         };

         function setNetworkProperties(deviceBacking) {
            if (!deviceBacking) {
               scope.networkName = "";
               scope.networkRef = null;
            }
            var selectedNetworkData;
            if (deviceBacking._type === DISTRIBUTED_PORTGROUP_BACKING_TYPE) {
               selectedNetworkData = _.find(scope.ctrl.hardwareViewData.dvPortGroupData, function (dvPortGroupData) {
                  return dvPortGroupData.key === deviceBacking.port.portgroupKey;
               });
            } else if (deviceBacking._type === OPAQUE_NETWORK_BACKING_TYPE) {
               selectedNetworkData =
                     _.find(scope.ctrl.hardwareViewData.opaqueNetworkData, function (opaqueNetworkData) {
                        return opaqueNetworkData.opaqueNetworkType === deviceBacking.opaqueNetworkType &&
                              opaqueNetworkData.opaqueNetworkId === deviceBacking.opaqueNetworkId;
                     });
            } else {
               scope.networkName = scope.device.deviceInfo.summary;
               scope.networkRef = defaultUriSchemeUtil.getVsphereObjectId(deviceBacking.network);
            }
            if (selectedNetworkData) {
               scope.networkName = selectedNetworkData.name;
               scope.networkRef = defaultUriSchemeUtil.getVsphereObjectId(selectedNetworkData.provider);
            }
            if (scope.ctrl.isVmTemplateCLitem) {
               scope.connectOnStart = scope.device.startConnected;
            }
         }
      }
   };
}]);
