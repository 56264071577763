(function () {
   'use strict';

   /**
    * Provides methods for creating mutation spcifications that can be sent
    * by Add Networking wizard.
    */
   angular
         .module('com.vmware.vsphere.client.network')
         .factory('addNetworkingSpecificationFactory',
               addNetworkingSpecificationFactory);

   addNetworkingSpecificationFactory.$inject = [
      '$q',
      'addNetworkingWizardConstants',
      'dataService',
      'defaultUriSchemeUtil',
      'networkUiConstants'];

   function addNetworkingSpecificationFactory($q,
                                              addNetworkingWizardConstants,
                                              dataService,
                                              defaultUriSchemeUtil) {
      return {
         createNewPortgroupSpec: createNewPortgroupSpec,
         createNewPnicToExistingStandardSwitchSpec: createNewPnicToExistingStandardSwitchSpec,
         createNewPnicToNewStandardSwitchSpec: createNewPnicToNewStandardSwitchSpec,
         createNewPnicToDistributedSwitchSpec: createNewPnicToDistributedSwitchSpec,
         createNewVnicSpec: createNewVnicSpec
      };

      function createNewPnicToExistingStandardSwitchSpec(addNetworkingSpec, vSwitchSpec) {
         var pnicSpec = new HostPnicAddSpec();

         var nicOrder = getNicOrder(vSwitchSpec.pnics);
         pnicSpec.activeNic = nicOrder.activeNic;
         pnicSpec.standbyNic = nicOrder.standbyNic;
         pnicSpec.bridge = nicOrder.bridge;

         pnicSpec.vSwitchName = addNetworkingSpec.selectedStandardSwitch.name;

         return pnicSpec;
      }
      function createNewPnicToNewStandardSwitchSpec(vSwitchSpec) {
         var vSwitchConfig = buildVswitchConfig(vSwitchSpec);
         var networkConfig = new NetworkConfig();
         networkConfig.vswitch.push(vSwitchConfig);
         return new HostNetworkingAddSpec(networkConfig);
      }

      function createNewPnicToDistributedSwitchSpec(uplinkMappings, selectedStandardSwitch) {
         return new UpdateProxySwitchUplinkMappingSpec(uplinkMappings, selectedStandardSwitch.key);
      }

      /**
       * @param addNetworkingSpec
       *    User generated networking spec created via Add Networking wizard.
       * @param vSwitchSpec
       *    User generated spec for new standard switch
       * @param targetType
       *    Selected target type
       */
      function createNewPortgroupSpec(addNetworkingSpec, vSwitchSpec, targetType) {
         var networkConfig = buildNetworkConfig(addNetworkingSpec, vSwitchSpec, targetType);

         return new HostNetworkingAddSpec(networkConfig);
      }

      function createNewVnicSpec(addNetworkingSpec, vSwitchSpec, ipSettingsType, targetType, pinnedPnicData) {
         var virtualNicConfig = new VirtualNicConfig(addNetworkingSpec.mtu,
               addNetworkingSpec.netStackInstanceKey);

         virtualNicConfig.spec.ip = buildIpConfig(ipSettingsType, addNetworkingSpec);
         virtualNicConfig.spec.ipRouteSpec = buildIpRouteSpec(ipSettingsType,
               addNetworkingSpec);

         if (targetType === addNetworkingWizardConstants.targetType.EXISTING_NETWORK) {
            return buildVirtualNicAddSpec(virtualNicConfig, addNetworkingSpec, addNetworkingSpec.selectedNetwork, pinnedPnicData);

         } else if (targetType === addNetworkingWizardConstants.targetType.EXISTING_STANDARD_SWITCH ||
               targetType === addNetworkingWizardConstants.targetType.NEW_STANDARD_SWITCH) {

            virtualNicConfig.portgroup = addNetworkingSpec.networkLabel;

            var networkConfig = buildNetworkConfig(addNetworkingSpec, vSwitchSpec, targetType);
            networkConfig.vnic.push(virtualNicConfig);

            var hostNetworkingAddSpec = new HostNetworkingAddSpec(networkConfig);

            disableServicesForNonDefaultStack(addNetworkingSpec);

            hostNetworkingAddSpec.vmkernelSettingsSpec = addNetworkingSpec.vmkernelSettingsSpec;

            return $q.when(hostNetworkingAddSpec);
         }
      }

      function buildNetworkConfig(addNetworkingSpec, vSwitchSpec, targetType) {
         var networkConfig = new NetworkConfig();
         var portgroupConfig;

         if (targetType === addNetworkingWizardConstants.targetType.EXISTING_STANDARD_SWITCH) {
            portgroupConfig = new PortGroupConfig(addNetworkingSpec.networkLabel,
                  addNetworkingSpec.selectedStandardSwitch.name,
                  addNetworkingSpec.vlanId);
         } else if (targetType === addNetworkingWizardConstants.targetType.NEW_STANDARD_SWITCH) {
            var vSwitchConfig = buildVswitchConfig(vSwitchSpec);
            networkConfig.vswitch.push(vSwitchConfig);

            portgroupConfig = new PortGroupConfig(addNetworkingSpec.networkLabel, vSwitchSpec.name, addNetworkingSpec.vlanId);
         }

         networkConfig.portgroup.push(portgroupConfig);
         return networkConfig;
      }

      function buildVirtualNicAddSpec(virtualNicConfig, addNetworkingSpec, selectedNetwork, pinnedPnicData) {

         disableServicesForNonDefaultStack(addNetworkingSpec);

         var virtualNicAddSpec = new VirtualNicAddSpec(virtualNicConfig,
               addNetworkingSpec.vmkernelSettingsSpec);
         if (selectedNetwork.isDistributedPortgroup) {
            return dataService.getData(
                  defaultUriSchemeUtil.getVsphereObjectId(selectedNetwork.networkRef),
                  'com.vmware.vsphere.client.h5.network.dvportgroup.model.DvPortgroupTargetData'
            ).then(function (dvPortGroupData) {

               virtualNicConfig.spec.distributedVirtualPort =
                     new DistributedVirtualPort(dvPortGroupData.key, dvPortGroupData.dvSwitchUuid);

               return virtualNicAddSpec;
            });
         } else if (selectedNetwork.isOpaqueNetwork) {
            return dataService.getData(
                  defaultUriSchemeUtil.getVsphereObjectId(selectedNetwork.networkRef),
                  'com.vmware.vsphere.client.h5.network.opaquenetwork.model.OpaqueNetworkTargetData'
            ).then(function (opaqueNetworkData) {
               // The vnic will connect to an opaque network
               virtualNicConfig.spec.opaqueNetwork =
                     new OpaqueNetworkSpec(opaqueNetworkData.opaqueNetworkId, opaqueNetworkData.opaqueNetworkType);

               if (pinnedPnicData && pinnedPnicData.bindToPnic && pinnedPnicData.selectedPnic) {
                  virtualNicConfig.spec.pinnedPnic = pinnedPnicData.selectedPnic;
               }

               return virtualNicAddSpec;
            });
         }
      }

      function buildIpv6AddressesConfig(ipv6Addresses) {
         var ipv6AddressesConfig = [];

         _.forEach(ipv6Addresses, function (ipv6) {
            var ipv6Address = new IpV6Address(ipv6.address, ipv6.prefixLength);
            ipv6AddressesConfig.push(ipv6Address);
         });

         return ipv6AddressesConfig;
      }

      function buildIpConfig(ipSettingsType, addNetworkingSpec) {
         var ipConfig = new IpConfig();

         var isIpv4Enabled =
               ipSettingsType !== addNetworkingWizardConstants.ipSettingsMode.IP_V6;
         var isIpv6Enabled =
               ipSettingsType !== addNetworkingWizardConstants.ipSettingsMode.IP_V4;

         if (isIpv4Enabled) {
            ipConfig.dhcp = addNetworkingSpec.dhcpEnabled;
            if (!addNetworkingSpec.dhcpEnabled) {
               ipConfig.ipAddress = addNetworkingSpec.ipv4Address;
               ipConfig.subnetMask = addNetworkingSpec.subnetMask;
            }
         }

         if (isIpv6Enabled) {
            ipConfig.ipV6Config = new IpV6AddressConfiguration(addNetworkingSpec.ipv6DhcpEnabled,
                        addNetworkingSpec.routerAdvertisementEnabled);
            if (addNetworkingSpec.staticAddressesEnabled) {
               ipConfig.ipV6Config.ipV6Address =
                     buildIpv6AddressesConfig(addNetworkingSpec.ipv6Addresses);
            }
         }

         return ipConfig;
      }

      function buildIpRouteSpec(ipSettingsType, addNetworkingSpec) {
         if (!addNetworkingSpec.isGatewayFeatureSupported) {
            return null;
         }

         var ipRouteSpec = new IpRouteSpec();

         var isIpv4GatewaySet = !addNetworkingSpec.dhcpEnabled
               && addNetworkingSpec.overrideGateway;
         var isIpv6GatewaySet = addNetworkingSpec.staticAddressesEnabled
               && addNetworkingSpec.overrideIpv6Gateway;

         // default gateway is set only if the property is set by the user
         if (ipSettingsType !== addNetworkingWizardConstants.ipSettingsMode.IP_V6
               && isIpv4GatewaySet) {
            ipRouteSpec.ipRouteConfig.defaultGateway = addNetworkingSpec.ipv4DefaultGateway;
         }

         if (ipSettingsType !== addNetworkingWizardConstants.ipSettingsMode.IP_V4
               && isIpv6GatewaySet) {
            ipRouteSpec.ipRouteConfig.ipV6DefaultGateway = addNetworkingSpec.ipv6DefaultGateway;
         }

         return ipRouteSpec;
      }

      function buildVswitchConfig(vSwitchSpec) {
         var vSwitchConfig = new VirtualSwitchConfig(vSwitchSpec.name, vSwitchSpec.mtu, vSwitchSpec.numPorts);

         var nicOrder = getNicOrder(vSwitchSpec.pnics);

         vSwitchConfig.spec.policy.nicTeaming.nicOrder.activeNic = nicOrder.activeNic;
         vSwitchConfig.spec.policy.nicTeaming.nicOrder.standbyNic = nicOrder.standbyNic;

         if (nicOrder.bridge) {
            vSwitchConfig.spec.bridge = nicOrder.bridge;
         }

         return vSwitchConfig;
      }

      function disableServicesForNonDefaultStack(addNetworkingSpec) {
         if (!!addNetworkingSpec.netStackInstanceKey &&
               addNetworkingSpec.netStackInstanceKey !==
               addNetworkingWizardConstants.defaultNetStackKey) {
            addNetworkingSpec.vmkernelSettingsSpec
                  .isVmotionEnabled = false;
            addNetworkingSpec.vmkernelSettingsSpec
                  .isProvisioningNfcTrafficEnabled = false;
         }
      }

      function getNicOrder(pnics) {
         var nicOrder = {};

         var activeNic = pnics.active;
         nicOrder.activeNic = activeNic;

         var standbyNic = pnics.standby;
         nicOrder.standbyNic = standbyNic;

         var unusedNics = pnics.unused;

         var allNics = [];
         allNics = allNics.concat(activeNic, standbyNic, unusedNics);

         if (allNics.length > 0) {
            nicOrder.bridge = new BondBridge(allNics);
         }

         return nicOrder;
      }

      function VirtualSwitchConfig(vSwitchName, mtu, numPorts) {
         this._type = 'com.vmware.vim.binding.vim.host.VirtualSwitch$Config';
         this.name = vSwitchName;
         this.changeOperation = 'add';
         this.spec = {};
         this.spec._type = 'com.vmware.vim.binding.vim.host.VirtualSwitch$Specification';
         this.spec.mtu = mtu;
         this.spec.numPorts = numPorts;
         this.spec.bridge = null;

         this.spec.policy = {};
         this.spec.policy._type = 'com.vmware.vim.binding.vim.host.NetworkPolicy';

         this.spec.policy.nicTeaming = {};
         this.spec.policy.nicTeaming._type = 'com.vmware.vim.binding.vim.host.NetworkPolicy$NicTeamingPolicy';

         this.spec.policy.nicTeaming.nicOrder = {};
         this.spec.policy.nicTeaming.nicOrder._type = 'com.vmware.vim.binding.vim.host.NetworkPolicy$NicOrderPolicy';
         this.spec.policy.nicTeaming.nicOrder.activeNic = [];
         this.spec.policy.nicTeaming.nicOrder.standbyNic = [];
      }

      function HostNetworkingAddSpec(networkConfig) {
         this._type = 'com.vmware.vsphere.client.network.HostNetworkingAddSpec';
         this.networkConfig = networkConfig;
      }

      function NetworkConfig() {
         this._type = 'com.vmware.vim.binding.vim.host.NetworkConfig';
         this.vnic = [];
         this.portgroup = [];
         this.vswitch = [];
      }

      function PortGroupConfig(networkLabel, vSwitchName, vlanId) {
         this._type = 'com.vmware.vim.binding.vim.host.PortGroup$Config';
         this.changeOperation = 'add';

         this.spec = {};
         this.spec._type = 'com.vmware.vim.binding.vim.host.PortGroup$Specification';
         this.spec.name = networkLabel.trim();
         this.spec.vlanId = vlanId;
         this.spec.vswitchName = vSwitchName;

         this.spec.policy = {};
         this.spec.policy._type = 'com.vmware.vim.binding.vim.host.NetworkPolicy';

      }

      function BondBridge(nics) {
         this._type = 'com.vmware.vim.binding.vim.host.VirtualSwitch$BondBridge';
         this.nicDevice = nics;
      }

      function VirtualNicConfig(mtu, netStackInstanceKey) {
         this._type = 'com.vmware.vim.binding.vim.host.VirtualNic$Config';
         this.portgroup = null;
         this.spec = {};
         this.spec._type = 'com.vmware.vim.binding.vim.host.VirtualNic$Specification';
         this.spec.ip = null; //buildVnicIpConfig(wizardScope),
         this.spec.ipRouteSpec = null;
         this.spec.mtu = mtu; //wizardScope.addNetworkingSpec.mtu
         this.spec.netStackInstanceKey = netStackInstanceKey; //wizardScope.addNetworkingSpec.netStackName
         this.changeOperation = 'add';
      }

      function VirtualNicAddSpec(virtualNicConfig, vmkernelSettingsSpec) {
         this._type = 'com.vmware.vsphere.client.network.VirtualNicAddSpec';
         this.vnicConfig = virtualNicConfig;
         this.vmkernelSettingsSpec = vmkernelSettingsSpec;
         this.isServiceConsoleVnic = false;
      }

      function IpConfig() {
         this._type = 'com.vmware.vim.binding.vim.host.IpConfig';
         this.dhcp = false;
         this.ipAddress = '';
         this.subnetMask = '';
         this.ipV6Config = null;
      }

      function IpRouteSpec() {
         this._type = 'com.vmware.vim.binding.vim.host.VirtualNic$IpRouteSpec';
         this.ipRouteConfig = {};
         this.ipRouteConfig._type = 'com.vmware.vim.binding.vim.host.IpRouteConfig';
      }

      function IpV6AddressConfiguration(ipv6DhcpEnabled, routerAdvertisementEnabled) {
         this._type = 'com.vmware.vim.binding.vim.host.IpConfig$IpV6AddressConfiguration';
         this.dhcpV6Enabled = ipv6DhcpEnabled;
         this.autoConfigurationEnabled = routerAdvertisementEnabled;
         this.ipV6Address = [];
      }

      function IpV6Address(address, prefixLength) {
         this._type = 'com.vmware.vim.binding.vim.host.IpConfig$IpV6Address';
         this.ipAddress = address;
         this.prefixLength = prefixLength;
         this.operation = 'add';
      }

      function DistributedVirtualPort(key, dvSwitchUuid) {
         this._type = 'com.vmware.vim.binding.vim.dvs.PortConnection';
         this.portgroupKey = key;
         this.switchUuid = dvSwitchUuid;
      }

      function OpaqueNetworkSpec(opaqueNetworkId, opaqueNetworkType) {
         this._type = 'com.vmware.vim.binding.vim.host.VirtualNic$OpaqueNetworkSpec';
         this.opaqueNetworkId = opaqueNetworkId;
         this.opaqueNetworkType = opaqueNetworkType;
      }

      function HostPnicAddSpec() {
         this._type = "com.vmware.vsphere.client.h5.network.host.wizard.addnetworking.model.HostPnicAddSpec";
      }
      function UpdateProxySwitchUplinkMappingSpec(uplinkPortMappings, proxySwitchKey) {
         this._type = "com.vmware.vsphere.client.h5.network.host.proxyswitch.spec.UpdateProxySwitchUplinkMappingSpec";
         this.uplinkPortMappings = uplinkPortMappings;
         this.proxySwitchKey = proxySwitchKey;
      }
   }
})();
