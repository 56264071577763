namespace storage_ui {

   import IComponentOptions = angular.IComponentOptions;

   class ProtocolEndpointsGeneralComponent implements angular.IComponentController {
      static $inject = ["i18nService", "dataService", "vxPropertyViewService", "vuiConstants", "datastoreConstants"];
      private selectedItemData: any[]; //either [] or 1-element array
      private propertyViewData: any = {};

      constructor(private i18nService: any,
                  private dataService: any,
                  private vxPropertyViewService: any,
                  private vuiConstants: any,
                  private datastoreConstants: any) {
      }

      $onInit(): void {
         this.buildPropertyView();
      }

      $onChanges() {
         this.updatePropertyView();
      }

      private buildPropertyView() {
         if (!this.selectedItemData || !this.selectedItemData.length) {
            return;
         }

         let data: DatastoreProtocolEndpointListItem = this.selectedItemData[0];
         let builder: any = this.vxPropertyViewService.createPropertyViewBuilder();
         let categoryBuilder: any = builder.category(
               this.i18nService.getString("StorageUi", "storage.pe.properties.generalHeader"));
         let generalSection = categoryBuilder.section('generalSection');
         generalSection
               .title(this.i18nService.getString("StorageUi", "storage.pe.properties.generalHeader"))
               .property(
                     this.i18nService.getString("StorageUi", "storage.pe.runtimeName"),
                     data.name)
               .property(
                     this.i18nService.getString("StorageUi", "storage.pe.type"),
                     data.type)
               .property(
                     this.i18nService.getString("StorageUi", "storage.pe.identifier"),
                     data.identifier);

         if (data.type === this.datastoreConstants.datastoreTypes.NFS) {
            generalSection
                  .property(
                        this.i18nService.getString("StorageUi", "storage.pe.server"),
                        data.server)
                  .property(
                        this.i18nService.getString("StorageUi", "storage.pe.folder"),
                        data.folder);
         }
         generalSection.property(
               this.i18nService.getString("StorageUi", "storage.pe.array"),
               data.storageArray);

         this.propertyViewData = builder.build();
      }

      private updatePropertyView() {
         if (!this.selectedItemData || !this.selectedItemData.length) {
            this.propertyViewData = {};
         } else {
            this.buildPropertyView();
         }
      }
   }

   angular.module("com.vmware.vsphere.client.storage").component(
         "protocolEndpointsGeneral", <IComponentOptions>{
            controller: ProtocolEndpointsGeneralComponent,
            templateUrl: "storage-ui/resources/storage/views/datastore/protocolEndpoints/" +
            "properties/ProtocolEndpointsGeneralComponentView.html",
            bindings: {
               selectedItemData: "<"
            }
         });

}
