/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace storage_ui {

   class CopyFilePathPickerController {

      public rootId: string;

      public selectedDestination: any;

      public accessor: any = {};

      public static $inject = ["i18nService"];

      constructor(private i18nService: any) {
      }

      $onChanges(changes:any) {
         if (changes.rootId
               && changes.rootId.currentValue
               && changes.rootId.currentValue !== changes.rootId.previousValue
               && this.accessor.reloadData) {
            this.accessor.reloadData();
         }
      }

      public onSelectionChange(selectedItem:any) {
         if (selectedItem) {
            this.selectedDestination.path = selectedItem.path;
            this.selectedDestination.name = selectedItem.name;
         }
      }
   }

   angular.module("com.vmware.vsphere.client.storage")
         .component("copyFilePathPicker", {
            templateUrl: "storage-ui/resources/storage/views/manage/files/copyFileComponents/copyFilePathPickerView.html",
            controller: CopyFilePathPickerController,
            bindings: {
               rootId: "<",
               selectedDestination: "="
            }
         });

}
