namespace h5_vm {

   class VmProvisioningEulaDisplayService{
      static $inject: string[] = ["i18nService"];
      constructor(private i18nService: any){
      }

      public getEulaDescription(numberOfEulasFound: number): string{
         let eulaDescription = "";
         if (numberOfEulasFound > 0) {
            eulaDescription = (numberOfEulasFound === 1) ?
               this.i18nService.getString("ProvisioningUiLib",
                  "AcceptEulaPage.acceptSingle"):
               this.i18nService.getString("ProvisioningUiLib",
                  "AcceptEulaPage.acceptAllAtOnce" , [numberOfEulasFound]);
         }
         return eulaDescription;
      }

      /*
         Remove this method after the Bug#1804672 is fixed
      */
      public getInfoDescriptions(numberOfEulasFound: number): Array<string>{
         let infoDescriptions : Array<string>= [];
         for (let i=0; i < numberOfEulasFound; i++) {
            infoDescriptions.push("Agreement "+(i+1));
         }
         return infoDescriptions;
      }
   }

   angular.module("com.vmware.vsphere.client.vm").
      service("vmProvisioningEulaDisplayService", VmProvisioningEulaDisplayService);

}
