namespace ds_cluster_ui {

   export class EnterMaintenanceModeRecommendationsWarningComponent {
      public bindings: any;
      public controller: any;
      public templateUrl: string;

      constructor() {
         this.bindings = {
            recommendations: "<",
            recommendationStore: "="
         };

         this.controller = EnterMaintenanceModeRecommendationsWarningController;
         this.templateUrl =
               "ds-cluster-ui/resources/ds-cluster/views/maintenanceMode/" +
               "enterMaintenanceModeRecommendationsWarningView.html";
      }
   }

   class EnterMaintenanceModeRecommendationsWarningController {
      public static $inject = ["i18nService"];

      private recommendations: any;
      private recommendationStore: any;
      private i18n: any;

      constructor(private i18nService: any) {
         this.i18n = this.i18nService.getString;
      }
   }

   angular.module("com.vmware.vsphere.client.dsCluster")
         .component("enterMaintenanceModeRecommendationsWarning",
               new EnterMaintenanceModeRecommendationsWarningComponent());
}