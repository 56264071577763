/* Copyright 2015 VMware, Inc. All rights reserved. -- VMware Confidential */

(function() {
   'use strict';

   angular
         .module('com.vmware.vsphere.client.vm')
         .controller('VmProvisioningFinishCloneController',
               VmProvisioningFinishCloneController);

   VmProvisioningFinishCloneController.$inject = [
      '$scope',
      'datastoreRecommendationService',
      'i18nService',
      'creationTypeConstants'
   ];

   function VmProvisioningFinishCloneController($scope,
         datastoreRecommendationService,
         i18nService,
         creationTypeConstants) {
      var self = this;
      var model = $scope.vmProvisioningFinishCloneVmPageModel;

      model.resetForm();
      self.form = model.form;

      var sourceObjectInfo = model.getSourceObjectInfo();
      var targetObjectInfo = model.getTargetObjectInfo();

      var crInfo = model.getComputeResourceInfo();
      $scope.computeResourceName = crInfo.name;
      $scope.computeResourceType = crInfo.label;

      $scope.provisioningTypeName = model.presentProvisioningType();
      $scope.sourceObjectName = sourceObjectInfo.name;
      $scope.sourceObjectLabel = sourceObjectInfo.label;
      $scope.targetObjectName = targetObjectInfo.name;
      $scope.targetObjectLabel = targetObjectInfo.label;
      //Wrap in anonymous function in order to preserve the functions "this" context.
      //Otherwise it's replaced by the $scope's this.
      self.datastoreLabel = model.presentTargetStorageName();
      self.isStorageProfileAvailable = function() {
         return model.isStorageProfileSpecified();
      };
      self.isReplicationGroupAvailable = function() {
         return model.isReplicationGroupSpecified();
      };
      $scope.targetFolderName = model.getTargetFolderName();
      $scope.diskFormat = model.presentDiskFormat();
      $scope.vmStorageProfile = model.getStorageProfile();
      $scope.vmReplicationGroup = model.getReplicationGroup();
      $scope.gosCustomizationSpecName = model.getGosCustomizationSpecName();
      $scope.gosWasCustomized = model.isGosCustomized();
      $scope.newDisks = model.shouldShowDisks() ? model.getNewDisks() : [];
      $scope.existingDisks = model.shouldShowDisks() ? model.getExistingDisks() : [];
      $scope.nvdimmDevices = model.getNvdimmDevicesInfo();
      $scope.shouldDsiplayHwCustomizedProperties = model.shouldDsiplayHwCustomizedProperties();
      if($scope.shouldDsiplayHwCustomizedProperties){
         $scope.numberOfCpus = model.getNumberOfCpus();
         $scope.allocatedMemory = model.getAllocatedMemory();
         $scope.networkDevices = model.getNetworkInterfaces();
      }

      $scope.config.loadingMessage =
            i18nService.getString('CommonUi', 'wizard.loading');
      $scope.config.loading = true;
      model.fetchStoragePlacementRecommendations().then(function() {
         self.recommendationFaults = [];
      }, function(faults) {
         self.recommendationFaults = faults;
      }).then(function() {
         $scope.config.loading = false;
         self.datastoreLabel = model.presentTargetStorageName();
         model.setDisksDatastoreInfo($scope.newDisks);
         model.setDisksDatastoreInfo($scope.existingDisks);
      });

      self.openFaultsDialog = function() {
         datastoreRecommendationService.openFaultsDialog($scope, self.recommendationFaults);
      };

      self.openRecommendationsDialog = function(disk) {
         datastoreRecommendationService.openRecommendationsDialog(disk ? disk.diskKey : undefined, $scope, model)
               .then(function() {
                  self.datastoreLabel = model.presentTargetStorageName();
                  model.setDisksDatastoreInfo($scope.newDisks);
                  model.setDisksDatastoreInfo($scope.existingDisks);
               });
      };

      self.shouldDisplayRecommendations = function(disk) {
         if (model.virtualMachineSpecBuilder.isXvc()) {
            return false;
         }

         if (creationTypeConstants.DEPLOY_VM_FROM_VMTX === model.virtualMachineSpecBuilder.getCreationType()) {
            return false;
         }

         var storageObject;
         if (disk) {
            storageObject = disk.getStorageObject();
            // Pmem disks are not affected by SDRS
            if (disk.isPmemDisk) {
               return false;
            }
         }
         else {
            storageObject = model.virtualMachineSpecBuilder.getStorageObject();
         }
         return datastoreRecommendationService.areRecommendationsAvailableForStorageObject(storageObject);
      };

      self.isNewDisk = function(disk) {
         return disk.diskKey < 0;
      };

      self.shouldShowStorageProfile = function(diskObject) {
         // The disk object has the disk key in "diskKey" property
         var diskKey = {
            key: diskObject.diskKey
         };
         return !model.diskHasDefaultStoragePolicy(diskKey);
      };

      this.shouldShowDiskFormat = function() {
         return $scope.diskFormat && !model.isAdvancedStorageMode();
      };

      self.getStorageHint = function() {
         var hint = '';
         if (model.shouldShowDisks()) {
            return hint;
         }
         var pmemDiskInfo = model.getPmemDiskInfo();
         if (pmemDiskInfo.hasPmemDisk) {
            hint = pmemDiskInfo.hasRegularDisk ?
                  i18nService.getString(
                        'VmUi', 'ProvisioningWizardSummaryPage.pmem.hint.hybridBaseline') :
                  i18nService.getString(
                        'VmUi', 'ProvisioningWizardSummaryPage.pmem.hint.pmemBaseline');
         }
         if (pmemDiskInfo.hasDowngradedPmemDisk) {
            hint =  i18nService.getString(
                  'VmUi', 'ProvisioningWizardSummaryPage.pmem.hint.standardBaseline');
         }
         return hint;
      };

      self.vappPropertyDescriptors = function() {
         var vappPropertyDescriptors = model.virtualMachineSpecBuilder.getVappPropertyDescriptors();
         if (_.isEmpty(vappPropertyDescriptors)) {
            return [];
         }

         return _.filter(vappPropertyDescriptors, function(descriptor) {
            return descriptor.userConfigurable
               && descriptor.type !== "expression"
               && descriptor.type.indexOf("password") !== 0;
         });
      };
   }

})();
