/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace dvs_ui {

   export class DvsVersionPageComponent {

      public bindings: any;
      public controller: any;
      public templateUrl: string;

      constructor() {
         this.bindings = {
            wizardModel: "<"
         };

         this.controller = DvsVersionPageComponentController;

         this.templateUrl = "dvs-ui/resources/dvs/common/component/" +
               "dvsVersionPageComponentTemplate.html";
      }
   }

   class DvsVersionPageComponentController {

      static $inject = [
         "i18nService",
         "$scope",
         "dvsVersionPageConstants"
      ];

      public wizardModel: any;

      public versions: any;

      public signPostConfig: any;

      private _descriptionsByVersion: any;

      private readonly esxVersionByDvs: { [dvsVersion: string]: string } = {
         "5.0.0": "5.0",
         "5.1.0": "5.1",
         "5.5.0": "5.5",
         "6.0.0": "6.0",
         "6.5.0": "6.5",
         "6.6.0": "6.7"
      };

      constructor(private i18nService: any,
                  private $scope: any,
                  private wizardConstants: any) {
         this.$scope.i18n = this.i18nService.getString;
      }

      public $onInit(): void {
         this.$scope.$watch("$ctrl.wizardModel.supportedVersions", () => {
            this.initComponent();
         });
         this.initComponent();
      }

      private initComponent(): void {
         this.versions = _.map(this.wizardModel.supportedVersions, (version: string): any => {
            return {
               dvs: version,
               esx: this.esxVersionByDvs[version]
            };
         });

         this.initDescriptions();
         this.buildSignpostItems();
      }

      private getVersionDescriptions(version: string): string[] {
         return this._descriptionsByVersion[version]
               || this._descriptionsByVersion[this.wizardConstants.versions._unknown];
      }

      private initDescriptions(): void {
         this._descriptionsByVersion = {};

         this._descriptionsByVersion[this.wizardConstants.versions._66] = [
            this.i18nService.getString("DvsUi",
                  "DvsVersionPage.supportedVersion.descriptionItem.6_6_0")
         ];

         this._descriptionsByVersion[this.wizardConstants.versions._65] = [
            this.i18nService.getString("DvsUi",
                  "DvsVersionPage.supportedVersion.descriptionItem.6_5_0")
         ];

         this._descriptionsByVersion[this.wizardConstants.versions._60] = [
            this.i18nService.getString("DvsUi",
                  "DvsVersionPage.supportedVersion.descriptionItem.6_0_0.nioc"),
            this.i18nService.getString("DvsUi",
                  "DvsVersionPage.supportedVersion.descriptionItem.6_0_0.igmp")
         ];

         this._descriptionsByVersion[this.wizardConstants.versions._55] = [
            this.i18nService.getString("DvsUi",
                  "DvsVersionPage.supportedVersion.descriptionItem.5_5_0.filtering"),
            this.i18nService.getString("DvsUi",
                  "DvsVersionPage.supportedVersion.descriptionItem.5_5_0.lacp")
         ];

         this._descriptionsByVersion[this.wizardConstants.versions._51] = [
            this.i18nService.getString("DvsUi",
                  "DvsVersionPage.supportedVersion.descriptionItem.5_1_0.rollback"),
            this.i18nService.getString("DvsUi",
                  "DvsVersionPage.supportedVersion.descriptionItem.5_1_0.healthcheck"),
            this.i18nService.getString("DvsUi",
                  "DvsVersionPage.supportedVersion.descriptionItem.5_1_0.portmirroring"),
            this.i18nService.getString("DvsUi",
                  "DvsVersionPage.supportedVersion.descriptionItem.5_1_0.lacp")
         ];

         this._descriptionsByVersion[this.wizardConstants.versions._50] = [
            this.i18nService.getString("DvsUi",
                  "DvsVersionPage.supportedVersion.descriptionItem.5_0_0.nioc"),
            this.i18nService.getString("DvsUi",
                  "DvsVersionPage.supportedVersion.descriptionItem.5_0_0.netflow"),
            this.i18nService.getString("DvsUi",
                  "DvsVersionPage.supportedVersion.descriptionItem.5_0_0.portmirroring")
         ];

         this._descriptionsByVersion[this.wizardConstants.versions._unknown] = [
            this.i18nService.getString("DvsUi",
                  "DvsVersionPage.supportedVersion.descriptionItem.unknown")
         ];
      }

      private buildSignpostItems(): void {
         let signpostMessage: string = "";

         _.forEach(this.wizardModel.supportedVersions, (version: string): void => {
            let title: string = "<b>%title%</b><br/>"
                  .replace("%title%", this.i18nService.getString("DvsUi",
                        "DvsVersionPage.supportedVersion.descriptionItem.tltleTemplate",
                        version));

            let items: string = "";
            let descriptions: string[] = this.getVersionDescriptions(version);
            _.forEach(descriptions, (description: string): void => {
               let template: string = "<div class='horizontal-flex-container'>" +
                     "<span class='new-dvs-version-description-bullet'></span>" +
                     "<span class='new-dvs-version-description-item'>%description%</span><br/></div>";
               items += template
                     .replace("%description%", description);
            });

            let content: string = title + items;

            let sectionTemplate: string = "<div data-test-id='dvs-version-section-%version%'>%content%<br/></div>";

            signpostMessage += sectionTemplate
                  .replace("%version%", version)
                  .replace("%content%", content);
         });

         this.signPostConfig = {
            message: "<div class='new-dvs-version-signpost-content'>%content%</div>"
                  .replace("%content%", signpostMessage)
         };
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .component("dvsVersionPage",
               new DvsVersionPageComponent());
}
