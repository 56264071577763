/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace network_ui {
   import NetStackConfigItem = com.vmware.vsphere.client.h5.network.host.netstack.model.NetStackConfigItem;
   import IpRouteConfig = com.vmware.vim.binding.vim.host.IpRouteConfig;

   export class NetStackEditRoutingPageModel {
      public defaultGateway: string;
      public ipV6DefaultGateway: string;
      public hasVnicWithIpv4Settings: boolean;
      public hasVnicWithIpv6Settings: boolean;
      public isIpv6Enabled: boolean;
      public vnicsWithDhcpEnabled: string[];
      public isDefaultNetStack: boolean;

      public isDefaultGatewaySet: boolean;
      public isIpV6DefaultGatewaySet: boolean;

      public constructor(data: NetStackConfigItem) {
         this.defaultGateway = data.defaultGateway;
         this.ipV6DefaultGateway = data.ipV6DefaultGateway;
         this.hasVnicWithIpv4Settings = data.hasVnicWithIpv4Settings;
         this.hasVnicWithIpv6Settings = data.hasVnicWithIpv6Settings;
         this.isIpv6Enabled = data.isIpv6Enabled;
         this.vnicsWithDhcpEnabled = data.vnicsWithDhcpEnabled;
         this.isDefaultNetStack = data.isDefaultNetStack;

         this.isDefaultGatewaySet = !!data.defaultGateway;
         this.isIpV6DefaultGatewaySet = !!data.ipV6DefaultGateway;
      }

      public buildSpec(originalData: NetStackEditRoutingPageModel): IpRouteConfig | null {
         let spec = new IpRouteConfig();

         if (!this.isEmptyValue(this.defaultGateway)
               && originalData.defaultGateway !== this.defaultGateway) {
            spec.defaultGateway = this.defaultGateway;
         }

         if (!this.isEmptyValue(this.ipV6DefaultGateway)
               && originalData.ipV6DefaultGateway !== this.ipV6DefaultGateway) {
            spec.ipV6DefaultGateway = this.ipV6DefaultGateway;
         }

         return spec.defaultGateway || spec.ipV6DefaultGateway ? spec : null;
      }

      private isEmptyValue(value: any): boolean {
         return value === "";
      }
   }
}
