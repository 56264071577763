(function() {
   'use strict';

   angular.module('com.vmware.vsphere.client.host').service("hardwareHealthService", hardwareHealthService);

   hardwareHealthService.$inject = [
      'fileSaverService',
      'dataService',
      'columnRenderersRegistry',
      'i18nService',
      'hardwareHealthConstants',
      'mutationService',
      'clarityModalService',
      '$rootScope',
      'timeFormatterService'];

   function hardwareHealthService(fileSaverService,
      dataService, columnRenderersRegistry, i18nService, constants,
      mutationService, clarityModalService, $rootScope, timeFormatterService) {

      var healthStatusSortMap = {
         unknown: 0,
         alert: 1,
         warning: 2,
         normal: 3
      };

      function createSensorTreeListDataSource(data) {
         var dataSource = new kendo.data.TreeListDataSource({
            data: data,
            schema: {
               model: {
                  id: "id",
                  expanded: false
               }
            }
         });
         dataSource.read();
         return dataSource;
      }

      // Sensors list
      function createSensorTreeListOptions(showPropertyGridItemDetails, isVc65OrLater) {
         var columns = [];

         if (isVc65OrLater) {
            columns.push({
               field: 'sensorId',
               title: getString('idHeaderText'),
               template: getSensorIdRenderer,
               width: '10%'
            });
         }

         columns = columns.concat([
            {
               field: "name",
               title: getString('sensorHeaderText'),
               template: getSensorRenderer,
               width: '35%'
            }, {
               field: "status",
               title: getString('statusHeaderText'),
               template: function(dataItem) {
                  return getStatusRenderer(dataItem.status);
               },
               sortable: {
                  compare: compareStatusValues
               },
               width: '15%'
            }, {
               field: "reading",
               title: getString('readingHeaderText'),
               template: getReadingRenderer,
               width: '15%'
            }
         ]);

         if (isVc65OrLater) {
            columns.push({
               field: 'category',
               title: getString('categoryHeaderText'),
               template: '#:sensorType#',
               width: '15%'
            });
            columns.push({
               field: 'timestampFormatted',
               title: getString('timestampHeaderText'),
               template: '#:timestampFormatted#',
               width: '15%'
            });
         }

         return {
            dataSource: createSensorTreeListDataSource([]),
            sortable: {
               mode: 'multiple'
            },
            columns: columns,
            resizable: false,
            selectable: "row",
            change: function() {
               var selectedRow = this.dataItem(this.select()[0]);
               var id = !!selectedRow.parentId ? selectedRow.id : "";
               showPropertyGridItemDetails(id);
            }
         };
      }

      function createSensorActionBarOptions(hostId) {
         var refreshAction = createSensorActionObject(
               constants.ACTION.REFRESH_DATA,
               hostId,
               i18nService.getString('HostUi', 'hardwareHealth.sensors.refresh'),
               constants.OPERATION.REFRESH_DATA);

         var exportXmlAction = createExportXmlAction(hostId);

         return {
            actions: [refreshAction, exportXmlAction]
         };
      }

      function createAlertsAndWarningsActionBarOptions(hostId) {
         var refreshAction = createSensorActionObject(
               constants.ACTION.REFRESH_DATA,
               hostId,
               i18nService.getString('HostUi', 'hardwareHealth.sensors.refresh'),
               constants.OPERATION.REFRESH_DATA);

         var exportXmlAction = createExportXmlAction(hostId);

         return {
            actions: [refreshAction, exportXmlAction]
         };
      }

      function createSystemEventLogActionBarOptions(hostId) {
         var refreshAction = createSensorActionObject(
               constants.ACTION.REFRESH_DATA,
               hostId,
               i18nService.getString('HostUi', 'hardwareHealth.systemEventLog.refresh'),
               constants.OPERATION.REFRESH_DATA);

         var resetAction = createSensorActionObject(
               constants.ACTION.RESET_EVENTS,
               hostId,
               i18nService.getString('HostUi', 'hardwareHealth.systemEventLog.reset'),
               constants.OPERATION.RESET_EVENTS_LOG
         );

         var exportXmlAction = createExportXmlAction(hostId);

         return {
            actions: [refreshAction, resetAction, exportXmlAction]
         };
      }

      function createExportXmlAction(hostId) {
         return {
            id: constants.ACTION.EXPORT_XML,
            label: i18nService.getString('HostUi', "hardwareHealth.sensors.export"),
            enabled: false,
            onClick: function() {
               return handleExportXmlActionClick(hostId);
            }
         };
      }

      function handleExportXmlActionClick(hostId) {
         var modalScope = $rootScope.$new();
         modalScope.cimXmlData = null;
         modalScope.loading = true;
         modalScope.exportData = function() {
            fileSaverService.save(modalScope.cimXmlData, modalScope.fileName);
         };
         modalScope.modalOptions = {
            title: i18nService.getString('HostUi', 'hardwareHealth.modal.exportDialogTitle'),
            contentUrl: 'host-ui/resources/host/views/monitor/hardwareHealthExportView.html',
            size: 'lg'
         };

         requestHardwareHealthXmlData(hostId)
               .then(function(data) {
                  modalScope.loading = false;
                  modalScope.cimXmlData = data[constants.HARDWARE_STATUS_XML_PROPERTY];
                  var hostName = data['name'];
                  modalScope.fileName = !hostName ? 'host_health_data.xml' : hostName + ".xml";
                  modalScope.modalOptions.title = i18nService.getString('HostUi',
                              'hardwareHealth.modal.exportDialogTitle') + " " + hostName;
               });

         clarityModalService.openPopUp(modalScope);
      }

      function requestHardwareHealthXmlData(hostId) {
         return dataService.getProperties(hostId,
               [constants.HARDWARE_STATUS_XML_PROPERTY, 'name']);
      }

      function createSensorActionObject(actionId, hostId, label, operation) {
         return {
            id: actionId,
            label: label,
            enabled: true,
            onClick: function() {
               return resetSensorsOperation(hostId, operation);
            }
         };
      }

      function resetSensorsOperation(hostId, operation) {
         var specType = 'com.vmware.vsphere.client.cimmonitor.CimMonitorSpec';
         var spec = {
            operationId: operation
         };

         var operationName = getOperationMessage(operation);
         mutationService
               .apply(hostId, specType, spec, operationName);
      }

      function getOperationMessage(operation) {
         var messageId;
         switch (operation) {
            case  constants.OPERATION.REFRESH_DATA:
               messageId = 'cimDataBeingRefreshed';
               break;
            case constants.OPERATION.RESET_EVENTS_LOG:
               messageId = 'cimEventLogBeingReset';
               break;
            default:
               messageId = 'cimDataBeingRefreshed';
         }

         return i18nService.getString('HostUi', messageId);
      }

      // Alerts and Warnings list
      function createAlertsAndWarningsDatagridOptions() {
         return {
            data: [],
            columnDefs: [
               {
                  field: "name",
                  displayName: getString('sensorHeaderText'),
                  template: function(dataItem) {
                     return getSensorRenderer(dataItem);
                  }
               },
               {
                  field: "status",
                  type: "object",
                  searchable: false,
                  displayName: getString('statusHeaderText'),
                  template: function(dataItem) {
                     return getStatusRenderer(dataItem.status);
                  },
                  sortable: function(dataItemOne, dataItemTwo) {
                     return compareStatusValues(dataItemOne, dataItemTwo);
                  }
               },
               {
                  field: "reading",
                  displayName: getString('readingHeaderText'),
                  template: function(dataItem) {
                     return getReadingRenderer(dataItem);
                  }
               }
            ],
            height: '100%',
            resizable: false
         };
      }

      // System Event Log list
      function createSystemEventLogDatagridOptions() {
         return {
            data: [],
            columnDefs: [
               {
                  displayName: getString('systemEventLogMessageTimestampHeaderText'),
                  field: 'timestampFormatted',
                  template: '#:timestampFormatted#',
                  sortable: false,
                  searchable: false
               },
               {
                  displayName: getString('systemEventLogMessageHeaderText'),
                  field: 'message',
                  template: function(dataItem) {
                     return getEventMessageRenderer(dataItem);
                  },
                  sortable: false,
                  searchable: false
               }
            ],
            height: '100%',
            resizable: false
         };
      }

      function createHeaderOptions(hostId) {
         return {
            title: getString('title'),
            objectId: hostId
         };
      }

      function requestHardwareHealthData(hostId, hardwareStatusProperty) {
         return dataService.getProperties(hostId, [hardwareStatusProperty]);
      }

      function getEventTimestampRenderer(data) {
         var renderer = columnRenderersRegistry.getColumnRenderer('text');
         return renderer(['timestamp'], data);
      }

      function getEventMessageRenderer(data) {
         var renderer = columnRenderersRegistry.getColumnRenderer('text');
         return renderer(['message'], data);
      }

      function getString(key, count) {
         return i18nService.getString('HostUi', 'hardwareHealth.' + key, count);
      }

      function compareStatusValues(statusOne, statusTwo) {
         var statusValueOne = statusOne.status[0].status;
         var statusValueTwo = statusTwo.status[0].status;
         return healthStatusSortMap[statusValueOne] -
            healthStatusSortMap[statusValueTwo];
      }

      function getSensorCountLabels(sensorCounts) {
         if (!sensorCounts) {
            return null;
         }

         return {
            oneAlert: getString('oneAlert'),
            oneWarning: getString('oneWarning'),
            nAlerts: getString('nAlerts', sensorCounts.alerts),
            nWarnings: getString('nWarnings', sensorCounts.warnings),
            sensor: getString('sensor'),
            sensors: getString('sensors', sensorCounts.all),
            noAlertsWarningNSensors: getString('noAlertOrWarningOutOfNSensors',
                  sensorCounts.all),
            noAlertsWarningOneSensor: getString('noAlertOrWarningOutOfOneSensor')
         };
      }

      /**
       * Renders data based on 'icon-text' renderer by inserting icon and text
       * @param data - data.sensorType defines the icon and data.sensor defines
       * the text
       * @returns {*}
       */
      function getSensorRenderer(data) {
         if (!data.sensorType) {
            return '';
         }
         var renderer = columnRenderersRegistry.getColumnRenderer('icon-text');
         return renderer(['icon', 'text'], {
            icon: getSensorIcon(data.sensorType),
            text: data.name
         });
      }

      /**
       * Renders status text and standard status icon based on 'status'
       * renderer by status data
       * @param status - normal, warning or alert, text is status
       * @returns {*}
       */
      function getStatusRenderer(status) {
         if (!status || status.length === 0) {
            return '';
         }
         var renderer = columnRenderersRegistry.getColumnRenderer('icon-text');

         var data = status.map(function(statusItem) {
            return renderer(['icon', 'text'], {
               icon: getStatusIcon(statusItem.status),
               text: statusItem.statusMessage
            });
         })
               .reduce(function(accumulator, current) {
                  return accumulator + current;
               });
         return data;
      }

      /**
       * Renders data based on the data reading. The text renderer checks null values
       * and replaces them with empty string
       * @param data - sensor data
       * @returns {*}
       */
      function getReadingRenderer(data) {
         var renderer = columnRenderersRegistry.getColumnRenderer('text');
         return renderer(['reading'], data);
      }

      function getSensorIdRenderer(data) {
         var renderer = columnRenderersRegistry.getColumnRenderer('text');

         var sensorIdData = {
            sensorId: data.sensorId !== null ? data.sensorId :
                  i18nService.getString('HostUi', 'none.label')
         };

         return renderer(['sensorId'], sensorIdData);
      }

      function getStatusIcon(status) {
         switch (status) {
            case constants.status.NORMAL:
               return 'vsphere-icon-status-ok';
            case constants.status.WARNING:
               return 'vsphere-icon-status-warning';
            case constants.status.ALERT:
               return 'vsphere-icon-status-error';
            case constants.status.UNKNOWN:
               return 'vui-icon-datagrid-status-unknown';
            default:
               return '';
         }
      }

      /**
       * Gets sensor icon by type, defaults to other icon.
       * @param type - processor, temp, storage, battery, fan, etc.
       * @returns {*}
       */
      function getSensorIcon(type) {
         return constants.SENSOR_ICONS[type.toLowerCase()] || 'vx-icon-other';
      }

      function getActionStatus(actionId, hasData, hasActionPrivilege) {
         var actionStatusEnabled;
         if (actionId === constants.ACTION.EXPORT_XML) {
            actionStatusEnabled = hasData && hasActionPrivilege;
         } else {
            actionStatusEnabled = hasActionPrivilege;
         }
         return actionStatusEnabled;
      }

      function getUserTimeFormat() {
         return timeFormatterService.getUserTimeFormatPreference();
      }

      function getTimestampFormatted(sensorTimestamp, timeFormat) {
         return timeFormatterService.timestampToText(sensorTimestamp, timeFormat);
      }

      return {
         createHeaderOptions: createHeaderOptions,
         createSensorTreeListDataSource: createSensorTreeListDataSource,
         createSensorTreeListOptions: createSensorTreeListOptions,
         createSensorActionBarOptions: createSensorActionBarOptions,
         createAlertsAndWarningsActionBarOptions: createAlertsAndWarningsActionBarOptions,
         createSystemEventLogActionBarOptions: createSystemEventLogActionBarOptions,
         createAlertsAndWarningsDatagridOptions: createAlertsAndWarningsDatagridOptions,
         createSystemEventLogDatagridOptions: createSystemEventLogDatagridOptions,
         requestHardwareHealthData: requestHardwareHealthData,
         getSensorCountLabels: getSensorCountLabels,
         getSensorIcon: getSensorIcon,
         requestHardwareHealthXmlData: requestHardwareHealthXmlData,
         getActionStatus: getActionStatus,
         getUserTimeFormat: getUserTimeFormat,
         getTimestampFormatted: getTimestampFormatted
      };
   }
}());
