/* Copyright 2015 VMware, Inc. All rights reserved. -- VMware Confidential */
/*
 * This ts file has to be loaded first
 */
namespace h5_vapp {
   import MoveVappService = h5_vapp.MoveVAppService;
   import VmDragAndDropService = h5_vapp.VAppDragAndDropService;
   import VmRemovalService = h5_vapp.VappRemovalService;
   import DestinationTarget = h5_vapp.DestinationTarget;

   import ActionEvaluation = com.vmware.vise.actionsfw.ActionEvaluation;

   declare let h5: any;
   declare const angular: any;

   h5 = h5 || {};
   h5.vapp = {
      angularModule: angular.module("com.vmware.vsphere.client.vapp", ["com.vmware.platform.ui"])
   };

   /*
    * Registers vapp-ui actions.
    */
   angular.module("com.vmware.vsphere.client.vapp")
         .run([
            "clarityModalService",
            "mutationService",
            "i18nService",
            "$injector",
            "vAppDragAndDropService",
            "dragAndDropStockExpressionsService",
            "moveVAppService",
            "vappRemovalService",
            (clarityModalService: any,
                      mutationService: any,
                      i18nService: any,
                      $injector: any,
                      vAppDragAndDropService: VAppDragAndDropService,
                      dragAndDropStockExpressionsService: any,
                      moveVAppService: MoveVAppService,
                      vappRemovalService: VappRemovalService) => {
               h5.actions["vsphere.core.vApp.actions.editNotes"] = (actionEval: ActionEvaluation, availableTargets: string[]) => {
                  let onUpdateVappNotes = (objectId: string, notesValue: any, operationName: any): boolean => {
                     const specType: string = "com.vmware.vim.binding.vim.vApp.VAppConfigSpec";
                     const propSpec: any = {
                        _type: specType,
                        annotation: notesValue
                     };
                     mutationService.apply(objectId, specType, propSpec, operationName);
                     return true;
                  };

                  const dialogData: any = {
                     notesPropName: "vAppConfig.annotation",
                     onUpdateNotesCallback: onUpdateVappNotes
                  };

                  clarityModalService.openModal(actionEval, availableTargets, dialogData,
                        "common-module-ui/resources/common-module/views/notes/NotesDialog.html");
               };

               h5.actions["vsphere.core.vApp.actions.edit"] = (actionEval: ActionEvaluation, availableTargets: string[]) => {
                  $injector.get("vcVappEditActionService").editVapp(availableTargets[0]);
               };

               h5.actions["vsphere.core.vApp.actions.create"] = (actionEval: ActionEvaluation, availableTargets: string[]) => {
                  $injector.get("vcVappCreateActionService").createVapp(actionEval, availableTargets);
               };

               h5.actions["vsphere.core.vApp.powerOnAction"] = (actionEval: ActionEvaluation, availableTargets: string[]) => {
                  $injector.get("vcVappPoweropsService").powerOn(availableTargets[0]);
               };
               h5.actions["vsphere.core.vApp.powerOffAction"] = (actionEval: ActionEvaluation, availableTargets: string[]) => {
                  $injector.get("vcVappPoweropsService").powerOff(availableTargets[0]);
               };
               h5.actions["vsphere.core.vApp.shutdownAction"] = (actionEval: ActionEvaluation, availableTargets: string[]) => {
                  $injector.get("vcVappPoweropsService").shutdown(availableTargets[0]);
               };
               h5.actions["vsphere.core.vApp.suspendAction"] = (actionEval: ActionEvaluation, availableTargets: string[]) => {
                  $injector.get("vcVappPoweropsService").suspend(availableTargets[0]);
               };

               h5.actions["vsphere.core.vApp.cloneAction"] = (actionEval: ActionEvaluation, availableTargets: string[]) => {
                  $injector.get("vcVappCreateActionService").cloneVapp(actionEval, availableTargets);
               };

               h5.actions["vsphere.core.vApp.actions.exportAsOVF"] = (actionEval: ActionEvaluation, availableTargets: string[]) => {
                  if (availableTargets.length === 0) {
                     return;
                  }

                  const objectId: string = availableTargets[0];
                  let service: any = $injector.get("vscExportOvfModalService");
                  service.openExportOvfDialog(objectId);
               };

               h5.actions["vsphere.core.vApp.actions.moveVapp"] = (actionEval: ActionEvaluation, availableTargets: string[]) => {
                  moveVAppService.moveVApps(availableTargets);
               };

               h5.actions["vsphere.core.vApp.actions.dragAndDrop"] = (actionEval: ActionEvaluation, availableTargets: string[],
                       destinationTarget: DestinationTarget)  =>  {
                  vAppDragAndDropService.dragAndDropVapp(availableTargets, destinationTarget, actionEval);
               };

               h5.actions["vsphere.core.vApp.actions.unregisterAction"] = (actionEval: ActionEvaluation, availableTargets: string[])  => {
                  vappRemovalService.unregisterVapps(availableTargets);
               };

               h5.actions["vsphere.core.vApp.actions.deleteAction"] = (actionEval: ActionEvaluation, availableTargets: string[]) => {
                  vappRemovalService.deleteVapps(availableTargets);
               };

               h5.dragAndDropValidationMethods["isVappWithinSameStandaloneAccessibleHost"] = (dropTargetData: any, dragObjectsData: any)  =>  {
                  if (!dragAndDropStockExpressionsService.isTargetAccessible(dropTargetData)) {
                     return false;
                  }

                  return dragAndDropStockExpressionsService.isWithinSameStandaloneHost(dropTargetData, dragObjectsData);
               };
            }]);
}
