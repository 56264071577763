namespace h5_vm {

   import GosBasePageModel = h5_vm.GosBasePageModel;
   import ManagedObjectReference = com.vmware.vim.binding.vmodl.ManagedObjectReference;
   import LinuxTimeZoneArea = com.vmware.vsphere.client.h5.vm.configuration.LinuxTimeZoneArea;
   import WindowsTimeZone = com.vmware.vsphere.client.h5.vm.configuration.WindowsTimeZone;

   export class GosTimeZonePageModel extends GosBasePageModel {
      // Input
      customizationSpecManagerUrn: string;
      type: GosType;

      // Inner
      timeZoneData?: TimeZoneData;
      isLoading: boolean;

      // Output
      timeZoneArea: string;
      timeZoneLocation: string;
      hwClockUTC: boolean;
      timeZoneWinCode: number;
   }
}
