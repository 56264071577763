namespace network_lib_ui {

   export class DvPortgroupPropertiesService {

      private static DVPG_PROPERTIES_AND_POLICY: string =
            "dvpg:dvPortgroupPropertiesAndPolicy";

      public static $inject = [
            "dataService",
            "i18nService",
            "$q",
            "networkUiConstants",
            "vxPropertyViewService"];

      private i18n: any;

      constructor(private dataService: any,
                  private i18nService: any,
                  private $q: any,
                  private networkUiConstants: any,
                  private vxPropertyViewService: any) {

         this.i18n = this.i18nService.getString;
      }

      public requestDvPortgroupPropertiesAndPolicy(
            hostId: string, portgroupKey: string) {

         if (!hostId || !portgroupKey) {
            return this.$q.when(null);
         }

         return this.dataService.getProperties(
               hostId,
               [DvPortgroupPropertiesService.DVPG_PROPERTIES_AND_POLICY],
               {
                  propertyParams: [{
                     propertyName: DvPortgroupPropertiesService.DVPG_PROPERTIES_AND_POLICY,
                     parameterType: "java.lang.String",
                     parameter: portgroupKey
                  }]
               }).then(function (response: any) {
            return response &&
                  response[DvPortgroupPropertiesService.DVPG_PROPERTIES_AND_POLICY];
         });
      }

      public buildProperties(properties: any) {
         let builder = this.vxPropertyViewService.createPropertyViewBuilder();
         let generalCategory: any = builder.category("general");
         this.addPropertiesToSection(properties, generalCategory);
         return builder.build();
      }

      public addPropertiesToSection(properties: any, category: any): void {
         // General section
         let generalSection: any = category.section("generalSection")
               .title(this.i18n("NetworkUi", "dvpg.properties.general"));
         generalSection.property(
               this.i18n("NetworkUi", "dvpg.properties.name.label"),
               properties.name);
         if (properties.description) {
            generalSection.property(
                  this.i18n("NetworkUi", "dvpg.properties.description.label"),
                  properties.description);
         }
         generalSection.property(
               this.i18n("NetworkUi", "dvpg.properties.portBinding.label"),
               this.getPortBindingText(properties.portBinding));
         if (properties.portAllocation !== null
               && typeof properties.portAllocation !== "undefined") {
            generalSection.property(
                  this.i18n("NetworkUi", "dvpg.properties.portAllocation.label"),
                  properties.portAllocation
                        ? this.i18n("NetworkUi",
                              "dvpg.properties.portAllocation.autoExpand.true")
                        : this.i18n("NetworkUi",
                              "dvpg.properties.portAllocation.autoExpand.false"));
         }
         generalSection.property(
               this.i18n("NetworkUi", "dvpg.properties.numberOfPorts.label"),
               properties.numberOfPorts);
         if (properties.vmVnicResourcePoolName) {
            generalSection.property(
                  this.i18n("NetworkUi", "dvpg.properties.vmVnicResPool.label"),
                  properties.vmVnicResourcePoolName);
         }

         // Advanced section
         let advancedSection: any = category.section("advancedSection")
               .title(this.i18n("NetworkUi", "dvpg.properties.advanced"));
         advancedSection.property(this.i18n(
               "NetworkUi", "dvpg.properties.resetAtDisconnect.label"),
               properties.portConfigResetAtDisconnect
                     ? this.i18n(
                           "NetworkUi", "dvpg.properties.resetAtDisconnect.on")
                     : this.i18n(
                           "NetworkUi", "dvpg.properties.resetAtDisconnect.off"));

         // Override port policies section
         let overridePortPoliciesSection: any =
               category.section("overridePortPoliciesSection")
                     .title(this.i18n("NetworkUi", "dvpg.policy.override.label"));

         let overridePolicyEnabledLabel: any =
               this.i18n("NetworkUi", "dvpg.policy.override.on");
         let overridePolicyDisabledLabel: any =
               this.i18n("NetworkUi", "dvpg.policy.override.off");

         overridePortPoliciesSection.property(
               this.i18n("NetworkUi", "dvpg.policy.override.blockPorts"),
               properties.blockOverrideAllowed
                     ? overridePolicyEnabledLabel
                     : overridePolicyDisabledLabel);

         if (properties.shapingOverrideAllowed !== null
               && typeof properties.shapingOverrideAllowed !== "undefined") {
            overridePortPoliciesSection.property(
                  this.i18n("NetworkUi", "dvpg.policy.override.trafficShaping"),
                  properties.shapingOverrideAllowed
                        ? overridePolicyEnabledLabel
                        : overridePolicyDisabledLabel);
         }

         overridePortPoliciesSection.property(
               this.i18n("NetworkUi", "dvpg.policy.override.vendorConfig"),
               properties.vendorConfigOverrideAllowed
                     ? overridePolicyEnabledLabel
                     : overridePolicyDisabledLabel);

         if (properties.vlanOverrideAllowed !== null
               && typeof properties.vlanOverrideAllowed !== "undefined") {
            overridePortPoliciesSection.property(
                  this.i18n("NetworkUi", "dvpg.policy.override.vlan"),
                  properties.vlanOverrideAllowed
                        ? overridePolicyEnabledLabel
                        : overridePolicyDisabledLabel);
         }

         if (properties.uplinkTeamingOverrideAllowed !== null
               && typeof properties.uplinkTeamingOverrideAllowed !== "undefined") {
            overridePortPoliciesSection.property(
                  this.i18n("NetworkUi", "dvpg.policy.override.teaming"),
                  properties.uplinkTeamingOverrideAllowed
                        ? overridePolicyEnabledLabel
                        : overridePolicyDisabledLabel);
         }

         if (properties.networkResourcePoolOverrideAllowed !== null
               && typeof properties.networkResourcePoolOverrideAllowed !== "undefined") {
            overridePortPoliciesSection.property(
                  this.i18n("NetworkUi", "dvpg.policy.override.iorm"),
                  properties.networkResourcePoolOverrideAllowed
                        ? overridePolicyEnabledLabel
                        : overridePolicyDisabledLabel);
         }

         if (properties.securityPolicyOverrideAllowed !== null
               && typeof properties.securityPolicyOverrideAllowed !== "undefined") {
            overridePortPoliciesSection.property(
                  this.i18n("NetworkUi", "dvpg.policy.override.security"),
                  properties.securityPolicyOverrideAllowed
                        ? overridePolicyEnabledLabel
                        : overridePolicyDisabledLabel);
         }

         if (properties.ipfixOverrideAllowed !== null
               && typeof properties.ipfixOverrideAllowed !== "undefined") {
            overridePortPoliciesSection.property(
                  this.i18n("NetworkUi", "dvpg.policy.override.monitoring"),
                  properties.ipfixOverrideAllowed
                        ? overridePolicyEnabledLabel
                        : overridePolicyDisabledLabel);
         }

         if (properties.trafficFilterOverrideAllowed !== null
               && typeof properties.trafficFilterOverrideAllowed !== "undefined") {
            overridePortPoliciesSection.property(
                  this.i18n("NetworkUi", "dvpg.policy.override.trafficFilteringConfig"),
                  properties.trafficFilterOverrideAllowed
                        ? overridePolicyEnabledLabel
                        : overridePolicyDisabledLabel);
         }
      }

      private getPortBindingText(portBinding: string): string {
         switch (portBinding) {
            case this.networkUiConstants.PortgroupType.EARLY_BINDING:
               return this.i18n(
                     "NetworkUi", "dvpg.properties.portBinding.earlyBinding");
            case this.networkUiConstants.PortgroupType.LATE_BINDING:
               return this.i18n(
                     "NetworkUi", "dvpg.properties.portBinding.lateBinding");
            case this.networkUiConstants.PortgroupType.EPHEMERAL:
               return this.i18n(
                     "NetworkUi", "dvpg.properties.portBinding.ephemeral");
            default:
               return portBinding;
         }
      }
   }

   angular.module("com.vmware.vsphere.client.networkLibUi")
         .service("dvPortgroupPropertiesService", DvPortgroupPropertiesService);
}
