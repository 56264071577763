/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace network_ui {

   export class MigrateVmkWizardPages {

      public static $inject = [
         "i18nService",
         "migrateVmkWizardConstants",
         "selectVmkAdapterToMigratePageValidator",
         "configureSettingsPageValidator"
      ];

      constructor(private i18nService: any,
                  private migrateVmkWizardConstants: any,
                  private selectVmkAdapterToMigratePageValidator: SelectVmkAdapterToMigratePageValidator,
                  private configureSettingsPageValidator: ConfigureSettingsPageValidator) {
      }

      public createPages(model: MigrateVmkToVssWizardModel): any[] {

         let selectNetworksPage: any = {
            id: this.migrateVmkWizardConstants.pages.SELECT_VMK_NETWORK_ADAPTER,
            title: this.i18nService.getString(
                  "NetworkUi", "VssVirtualNicMigrateWizard.list.title"),
            description: this.i18nService.getString(
                  "NetworkUi", "VssVirtualNicMigrateWizard.list.description"),
            contentUrl: "network-ui/resources/network/views/" +
            "host/migratevmkadapter/views/selectVmkAdaptersToMigratePage.html",
            validationParams: ["model.selectedVmkAdapter"],
            validationFunction: () => {
               return this.selectVmkAdapterToMigratePageValidator
                     .getValidationError(model.selectedVmkAdapter);
            }
         };

         let configureSettingsPage: any = {
            id: this.migrateVmkWizardConstants.pages.CONFIGURE_SETTINGS,
            title: this.i18nService.getString(
                  "NetworkUi", "VssVirtualNicMigrateWizard.config.title"),
            description: this.i18nService.getString(
                  "NetworkUi", "VssVirtualNicMigrateWizard.config.description"),
            contentUrl: "network-ui/resources/network/views/" +
            "host/migratevmkadapter/views/configureSettingsPage.html",
            validationParams: ["model.newNetworkName", "model.vlanId"],
            validationFunction: () => {
               return this.configureSettingsPageValidator.getValidationError(
                     model.newNetworkName, model.vlanId, model.hostId);
            }
         };

         let readyToCompletePage: any = {
            id: this.migrateVmkWizardConstants.pages.READY_TO_COMPLETE,
            title: this.i18nService.getString(
                  "NetworkUi", "VssVirtualNicMigrateWizard.summary.title"),
            description: this.i18nService.getString(
                  "NetworkUi", "VssVirtualNicMigrateWizard.summary.description"),
            contentUrl: "network-ui/resources/network/views/" +
            "host/migratevmkadapter/views/readyToCompletePage.html",
            validationParams: [],
            validationFunction: () => {
               return true;
            }
         };

         return [selectNetworksPage, configureSettingsPage, readyToCompletePage];
      }

   }

   angular.module("com.vmware.vsphere.client.network")
         .service("migrateVmkWizardPages", MigrateVmkWizardPages);
}
