(function() {
   'use strict';

   angular.module('com.vmware.vsphere.client.vm').service(
         'VmProvisioningNameAndFolderPageModel', VmProvisioningNameAndFolderPageModel);

   VmProvisioningNameAndFolderPageModel.$inject = [
      'nameAndFolderValidatorService',
      'defaultUriSchemeUtil',
      'i18nService',
      '$q',
      'creationTypeConstants',
      'managedEntityConstants',
      'authorizationService',
      'wizardPageService',
      'vmTypeConstants',
      '$http',
      '$templateCache',
      'vcService'
   ];

   function VmProvisioningNameAndFolderPageModel(nameAndFolderValidatorService,
         defaultUriSchemeUtil, i18nService, $q, creationTypeConstants,
         managedEntityConstants, authorizationService, wizardPageService,
         vmTypeConstants, $http, $templateCache, vcService) {

      return function(wizardScope, virtualMachineSpecBuilder, defaultTargetId) {
         var form = {
            selectedTarget: {},
            hasEncryptNewVmPrivilege: false,
            hasManageEncryptionPolicyPrivilege: false
         };
         var hasActionPrivileges;
         var isDeployVmtxAllowed;
         var requiredPrivileges;
         var VM_NAME_MAX_LENGTH = 80;
         var previousCreationType = "";
         return {
            form: form,

            resetForm: function() {
               var submittedName = virtualMachineSpecBuilder.getName();
               var suggestedName = submittedName || form.newVmName;

               if (suggestedName) {
                  form.newVmName = suggestedName;
               } else if (virtualMachineSpecBuilder.getCreationType() ===
                     creationTypeConstants.CREATE_FROM_SCRATCH) {
                  form.newVmName = i18nService.getString(
                        'VmUi', 'SelectNameFolderProvisioningPage.defaultNewVmName');
               } else {
                  form.newVmName = '';
               }
            },

            getTreeType: function() {
               var creationType = virtualMachineSpecBuilder.getCreationType();
               var isRegisterVm = creationTypeConstants.isRegisterVm(creationType);
               var treeType = isRegisterVm ? 'VmFoldersTreeFromDatacenter' : 'VmFoldersTree';
               return treeType;
            },

            getTreeRoot: function() {
               var creationType = virtualMachineSpecBuilder.getCreationType();
               var isRegisterVm = creationTypeConstants.isRegisterVm(creationType);
               var isDeployFromTemplate = creationTypeConstants.isDeployVmFromTemplate(creationType);

               if (isRegisterVm) {
                  // in register keys the defaultTargetId might be a VM folder
                  // and it should not be used as a root for the tree, so in this case use
                  // the datacenter id
                  return wizardScope.wizardViewData.getDatacenterId();
               } else if(isDeployFromTemplate) {
                  return wizardScope.wizardViewData.getVCenterId();
               }

               return null;
            },

            targetIdToPreselect: function() {
               var submittedTarget = virtualMachineSpecBuilder.getTargetInformation();
               var submittedTargetId = submittedTarget ? submittedTarget.targetUid : null;

               var formTarget = form.selectedTarget;
               var formTargetId = formTarget ? formTarget.uid : null;

               // preselect target in the tree by priority
               // first if there is already a selection in the tree use that
               // second use the selection that was last saved when the page was submitted
               var suggestedTargetId = formTargetId || submittedTargetId;

               var creationType = virtualMachineSpecBuilder.getCreationType();
               if (creationType !== previousCreationType) {
                  previousCreationType = creationType;
                  // if the user has changed the workflow, from create new vm wizard
                  // do not consider the submitted or previously selected folder in three for preselection
                  // instead use the one from which the wizard is started or the recommended one
                  suggestedTargetId = undefined;
               }
               var recommendedTarget = getRecommendedFolder(creationType, wizardScope.wizardViewData);

               // preselect target in the tree by priority
               // first if there is already a selection in the tree use that
               // second use the selection that was last saved when the page was submitted
               // third the target provided to the page
               // fourth the recommended target from the backend
               suggestedTargetId = suggestedTargetId || defaultTargetId || recommendedTarget;

               return suggestedTargetId;
            },

            pageInstructions: function() {
               switch (virtualMachineSpecBuilder.getCreationType()) {
                  case creationTypeConstants.CREATE_FROM_SCRATCH:
                  case creationTypeConstants.CLONE_VM_TO_VM:
                  case creationTypeConstants.CLONE_TEMPLATE_TO_VM:
                  case creationTypeConstants.DEPLOY_VM_FROM_VAPP:
                  case creationTypeConstants.DEPLOY_VM_FROM_TEMPLATE:
                  case creationTypeConstants.DEPLOY_VM_FROM_OVF:
                     return {
                        nameCallToAction: i18nService.getString('VmUi', 'SelectNameFolderProvisioningPage.vmName'),
                        locationCallToAction: i18nService.getString('VmUi', 'SelectNameFolderProvisioningPage.instructionLocation.vm')
                     };
                  case creationTypeConstants.REGISTER_VM:
                     var isTemplate = virtualMachineSpecBuilder.getRegisterVmData().isTemplate;
                     return {
                        nameCallToAction: isTemplate ?
                              i18nService.getString('VmUi', 'SelectNameFolderProvisioningPage.templateName') :
                              i18nService.getString('VmUi', 'SelectNameFolderProvisioningPage.vmName'),
                        locationCallToAction: isTemplate ?
                              i18nService.getString('VmUi', 'SelectNameFolderProvisioningPage.instructionLocation.template') :
                              i18nService.getString('VmUi', 'SelectNameFolderProvisioningPage.instructionLocation.vm')
                     };
                  case creationTypeConstants.CLONE_TEMPLATE_TO_TEMPLATE:
                  case creationTypeConstants.CLONE_VM_TO_TEMPLATE:
                     return {
                        nameCallToAction: i18nService.getString('VmUi', 'SelectNameFolderProvisioningPage.templateName'),
                        locationCallToAction: i18nService.getString('VmUi', 'SelectNameFolderProvisioningPage.instructionLocation.template')
                     };
                  default:
                     break;
               }
            },

            pageFinishedLoading: function() {
               // TODO this should be decoupled from business logic
               // but for now it is a viable way to determine when all of the page has loaded
               return !!(form.selectedTarget && form.selectedTarget.type);
            },

            validateSelection: function() {
               wizardPageService.clearErrors(wizardScope.config);
               var target = form.selectedTarget;
               var compatibilityMessage = null;

               var creationType = virtualMachineSpecBuilder.getCreationType();
               var isXvc = checkIsXvc(virtualMachineSpecBuilder.getVmId(), target.uid);

               if (creationTypeConstants.isCloning(creationType)) {
                  requiredPrivileges = isXvc ? [h5_vm.Privileges.VM_INVENTORY_REGISTER]
                        : [h5_vm.Privileges.VM_INVENTORY_CREATE_FROM_EXISTING];
               } else if (creationTypeConstants.isRegisterVm(creationType)) {
                  requiredPrivileges = [h5_vm.Privileges.VM_INVENTORY_REGISTER];
               } else {
                  requiredPrivileges = [h5_vm.Privileges.VM_INVENTORY_CREATE];
               }
               requiredPrivileges.push(h5_vm.Privileges.CRYPTOGRAPHER_ENCRYPT_NEW_VM);
               requiredPrivileges.push(h5_vm.Privileges.CRYPTOGRAPHER_MANAGE_ENCRYPTION_POLICY);

               if (creationType === creationTypeConstants.DEPLOY_VM_FROM_VAPP) {
                  requiredPrivileges.push(h5_vm.Privileges.VAPP_IMPORT);
               }

               return authorizationService.checkGrantedPrivileges(target.uid, requiredPrivileges)
                     .then(function(hasPrivileges) {
                        form.hasEncryptNewVmPrivilege = hasPrivileges[1];
                        form.hasManageEncryptionPolicyPrivilege = hasPrivileges[2];

                        compatibilityMessage = makeCompatibilityMessage(
                              hasPrivileges[0], target, requiredPrivileges[0]);

                        if (creationType === creationTypeConstants.DEPLOY_VM_FROM_VAPP) {
                           var hasVappImportPrivilege = hasPrivileges[3];

                           if (hasVappImportPrivilege === false) {
                              compatibilityMessage = makeCompatibilityMessage(false, target, h5_vm.Privileges.VAPP_IMPORT);
                           }
                        }

                        if(compatibilityMessage) {
                           return compatibilityMessage;
                        } else {
                           return getIsVmtxFeatureSupportedPromise(
                                 virtualMachineSpecBuilder.getCreationType(), target.uid);
                        }
                     })
                     .then(function (isVmtxFeatureSupportedForDcOrFolder) {
                        //Additional check to avoid deploying vmtx into pre-6.7U1 VCs.
                        if (compatibilityMessage) {
                           return compatibilityMessage;
                        }
                        if (!isVmtxFeatureSupportedForDcOrFolder) {
                           isDeployVmtxAllowed = false;
                           return i18nService.getString('LibraryUi', 'error.vmtx.unsupported.datacenterfolder');
                        }
                        return null;
                     });
            },

            validatePage: function() {
               var vmNameErrorMessage = checkVmName(true);
               if (vmNameErrorMessage) {
                  return $q.resolve({
                     error: vmNameErrorMessage
                  });
               }

               var suffix = getVmOrTemplate();

               if (!form.selectedTarget.uid || isRootFolder(form.selectedTarget.uid) || !hasActionPrivileges || !isDeployVmtxAllowed) {
                  return $q.resolve({
                     error: i18nService.getString('VmUi', 'SelectNameFolderProvisioningPage.specifyValidDestination.' + suffix)
                  });
               }

               var validationPromise = nameAndFolderValidatorService
                     .validateNameAndGetLocationUids(form.newVmName, form.selectedTarget)
                     .then(function(result) {
                        if (!result.validName) {
                           return { error: result.validationMessage };
                        } else if (!result.isVmFolder) {
                           return {
                              error: i18nService.getString('VmUi',
                                    'SelectNameFolderProvisioningPage.specifyValidDestination.' + suffix)
                           };
                        } else {
                           return {
                              folderUid: result.folderUid,
                              datacenterUid: result.datacenterUid
                           };
                        }
                     });

               $http.get('vm-ui/resources/vm/views/createVmWizard/vmProvisioningComputeResource.html',
                     { cache: $templateCache });
               $http.get('common-module-ui/resources/common-module/views/compatibility/vxCompatibilityView.html',
                     { cache: $templateCache });

               return validationPromise;
            },

            submitPage: function(validationResult) {
               virtualMachineSpecBuilder.setName(form.newVmName);
               virtualMachineSpecBuilder
                  .setHasEncryptNewVmPrivilege(form.hasEncryptNewVmPrivilege);
               virtualMachineSpecBuilder
                  .setHasManageEncryptionPolicyPrivilege(form.hasManageEncryptionPolicyPrivilege);
               var invalidateNextPages = false;

               //if the user has already visited this page
               //get the previously selected datacenter
               //if it is not the same as the current invalidate the next pages
               var previousTargetInformation =
                     virtualMachineSpecBuilder.getTargetInformation();
               if (previousTargetInformation && previousTargetInformation.datacenterUid
                     && previousTargetInformation.datacenterUid !== validationResult.datacenterUid) {
                  invalidateNextPages = true;
               }

               virtualMachineSpecBuilder.setTargetInformation(
                     form.selectedTarget.uid,
                     validationResult.datacenterUid,
                     validationResult.folderUid,
                     form.selectedTarget.name
               );

               return {
                  wizardFlow: virtualMachineSpecBuilder.getCreationType(),
                  invalidateNextPages: invalidateNextPages
               };
            },

            checkVmName: checkVmName
         };

         function checkVmName(isSpecificMessage) {
            if (!form.newVmName) {
               return i18nService.getString('VmUi',
                     'SelectNameFolderProvisioningPage.specifyValidName' +
                     (isSpecificMessage ? ('.' + getVmOrTemplate()) : ''));
            }

            if (!checkVmNameLength(form.newVmName)) {
               return i18nService.getString('VmUi',
                     'NameValidator.nameTooLongSpecialChars');
            }

            return '';
         }

         function getVmOrTemplate() {
            var creationType = virtualMachineSpecBuilder.getCreationType();
            var isCreatingTemplate;

            if (creationTypeConstants.isRegisterVm(creationType)) {
               isCreatingTemplate = virtualMachineSpecBuilder.getRegisterVmData().isTemplate;
            } else {
               isCreatingTemplate = creationTypeConstants.isCloningToATemplate(creationType);
            }
            return isCreatingTemplate
                  ? vmTypeConstants.TEMPLATE
                  : vmTypeConstants.VIRTUAL_MACHINE;
         }

         function isRootFolder(objectId) {
            var location = defaultUriSchemeUtil.getPartsFromVsphereObjectId(objectId);
            return (location.type === 'Folder' && location.value === 'group-d1');
         }

         function makeCompatibilityMessage(privilegeStatus, target, privilege) {
            hasActionPrivileges = privilegeStatus;
            if (privilegeStatus) {
               return null;
            }

            var targetType = defaultUriSchemeUtil.getEntityType(target.uid);
            var messageText = createNoPrivilegesErrorString(targetType, privilege);
            return messageText;
         }

         function createNoPrivilegesErrorString(targetType, privilege) {
            var msg = ['SelectNameFolderProvisioningPage.No'];
            switch (privilege) {
               case h5_vm.Privileges.VM_INVENTORY_CREATE_FROM_EXISTING:
                  msg.push('CreateFromExistingPrivilege');
                  break;
               case h5_vm.Privileges.VM_INVENTORY_REGISTER:
                  msg.push('RegisterVmPrivilege');
                  break;
               case h5_vm.Privileges.VAPP_IMPORT:
                  msg.push('CreateNewVappPrivilege');
                  break;
               default:
                  msg.push('CreateNewPrivilege');
                  break;
            }

            if (targetType === managedEntityConstants.DATACENTER) {
               msg.push('OnDatacenter');
            } else if (targetType === managedEntityConstants.FOLDER) {
               msg.push('OnFolder');
            }

            return i18nService.getString('VmUi', msg.join(''));
         }

         function checkVmNameLength(name) {
            return convertVmName(name).length <= VM_NAME_MAX_LENGTH;
         }

         function convertVmName(name) {
            return name.replace(/%/g, '%25').replace(/\//g, '%2f').replace(/\\/g, '%2c');
         }

         function checkIsXvc(sourceVmId, targetId) {
            if (!sourceVmId || !targetId) {
               return false;
            }

            var sourceVmSeverGuid = defaultUriSchemeUtil
                  .getPartsFromVsphereObjectId(sourceVmId).serverGuid;
            var targetSeverGuid = defaultUriSchemeUtil
                  .getPartsFromVsphereObjectId(targetId).serverGuid;
            return sourceVmSeverGuid !== targetSeverGuid;
         }

         function getRecommendedFolder(creationType, wizardViewData) {
            var recommendedPreselections = wizardViewData.getRecommendedFolderAndComputeResource();
            if (!recommendedPreselections) {
               return;
            }

            if ((creationType === creationTypeConstants.CREATE_FROM_SCRATCH ||
                  creationType === creationTypeConstants.DEPLOY_VM_FROM_TEMPLATE ) &&
                  recommendedPreselections.createWorkflow) {
               return recommendedPreselections.createWorkflow.folderId;
            }

            if ((creationType === creationTypeConstants.CLONE_VM_TO_TEMPLATE ||
                  creationType === creationTypeConstants.CLONE_TEMPLATE_TO_TEMPLATE) &&
                  recommendedPreselections.templateWorkflow) {
               return recommendedPreselections.templateWorkflow.folderId;
            }

            if (creationTypeConstants.isCloning(creationType) && recommendedPreselections.cloneWorkflow) {
               return recommendedPreselections.cloneWorkflow.folderId;
            }

            return recommendedPreselections.defaultFolderId;
         }

         function getIsVmtxFeatureSupportedPromise(creationType, objectId) {
            isDeployVmtxAllowed = true;
            if (creationType !== creationTypeConstants.DEPLOY_VM_FROM_VMTX || !objectId) {
               return $q.when(true);
            }
            var serverGuid = defaultUriSchemeUtil.getPartsFromVsphereObjectId(objectId).serverGuid;
            return vcService.is671VcOrLaterByGuid(serverGuid);
         }
      };
   }
}());
