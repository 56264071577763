(function() {
   'use strict';

   angular.module('com.vmware.vsphere.client.vm')
         .service('diskProvisioningService', diskProvisioningService);

   diskProvisioningService.$inject = [
      'i18nService',
      'deviceService',
      'diskControllerIndexAdviserService',
      'diskFormatService',
      'VirtualDisk',
      'vmDeviceInfoService',
      'managedEntityConstants',
      'diskBackingInfoConstants',
      'deviceTypeConstants',
      'pmemUtilService'
   ];

   function diskProvisioningService(i18nService,
         deviceService,
         diskControllerIndexAdviserService,
         diskFormatService,
         VirtualDisk,
         vmDeviceInfoService,
         managedEntityConstants,
         diskBackingInfoConstants,
         deviceTypeConstants,
         pmemUtilService) {
      var diskFormats = _.extend({
         sesparse: { name: i18nService.getString('VmUi', 'DiskPage.SeSparse') }
      }, diskFormatService.diskFormats);

      var typeToBackingKeysTable = {
         thin: { thinProvisioned: true, eagerlyScrub: null },
         thick: { thinProvisioned: false, eagerlyScrub: true },
         flat: { thinProvisioned: false, eagerlyScrub: false },
         asDefinedInProfile: { thinProvisioned: true, eagerlyScrub: false },
         nativeThick: { thinProvisioned: false, eagerlyScrub: false }
      };

      return {
         makeDefaultVirtualDisk: makeDefaultVirtualDisk,
         makeDefaultVirtualDiskFromExistingDisk: makeDefaultVirtualDiskFromExistingDisk,
         makeDefaultRDMDisk: makeDefaultRDMDisk,
         backingInfoKeys: backingInfoKeys,
         hasFormat: hasFormat,
         getFormat: getFormat,
         getBackingInfo: getBackingInfo,
         getName: getName,
         getDiskFormats: getDiskFormats,
         setBackingDatastore: setBackingDatastore,
         areDiskFormatsEqual: areDiskFormatsEqual,
         SAME_AS_SOURCE: diskFormats.sameAsSource,
         FLAT: diskFormats.flat,
         THICK: diskFormats.thick,
         THIN: diskFormats.thin
      };

      function getDiskFormats() {
         return [
            diskFormats.sameAsSource,
            diskFormats.flat,
            diskFormats.thick,
            diskFormats.thin
         ];
      }

      function makeDefaultVirtualDiskFromExistingDisk(
            vmConfigEnvironment,
            guestOsDesc,
            otherInflatedDevices,
            additionalParameters,
            selectedDiskFile) {
         return makeDefaultDisk(vmConfigEnvironment,
               guestOsDesc,
               otherInflatedDevices,
               angular.extend(additionalParameters || {}, {
                  diskFile: selectedDiskFile
               }),
               deviceTypeConstants.EXISTINGHARDDISK
         );

      }

      function makeDefaultRDMDisk(vmConfigEnvironment,
            guestOsDesc,
            otherInflatedDevices,
            additionalParameters,
            selectedDevice) {

         if (!selectedDevice) {
            throw new Error("LUN selection cannot be null");
         }

         return makeDefaultDisk(vmConfigEnvironment,
               guestOsDesc,
               otherInflatedDevices,
               angular.extend(additionalParameters || {}, {
                  device: selectedDevice
               }),
               deviceTypeConstants.RDMDISK
         );
      }

      function makeDefaultVirtualDisk(vmConfigEnvironment,
            guestOsDesc,
            otherInflatedDevices,
            additionalParameters) {
         return makeDefaultDisk(vmConfigEnvironment,
               guestOsDesc,
               otherInflatedDevices,
               additionalParameters,
               deviceTypeConstants.VIRTUALDISK);
      }

      function makeDefaultDisk(vmConfigEnvironment,
            guestOsDesc,
            otherInflatedDevices,
            additionalParameters,
            deviceType) {
         var deviceOptions = vmConfigEnvironment.configOption.hardwareOptions.virtualDeviceOption;
         var defaultInflatedDiskController;
         var unitNumber;
         var disk;
         var recommendedBus;

         var availableController = getRecommendedOrAvailableController(guestOsDesc,
               deviceOptions,
               otherInflatedDevices);
         defaultInflatedDiskController = availableController.recommendedController;
         recommendedBus = availableController.recommendedBusNo;
         unitNumber = availableController.recommendedUnitNo;
         var defaultDiskControllerType = availableController.defaultDiskControllerType;
         if (!defaultInflatedDiskController) {
            if (typeof recommendedBus === 'undefined' || recommendedBus < 0) {
               throw new Error(i18nService.getString('VmUi', 'GenericDevicePage.ControllerMaxLimitReached'));
            }

            var virtualDeviceOptions = vmConfigEnvironment.configOption.hardwareOptions.virtualDeviceOption;
            defaultInflatedDiskController = deviceService.makeController(virtualDeviceOptions,
                  defaultDiskControllerType,
                  recommendedBus);
         }
         var diskOption = _.find(vmConfigEnvironment.configOption.hardwareOptions.virtualDeviceOption, function(deviceOption) {
            var controllerTypeClass = deviceOption.controllerType && deviceOption.controllerType.typeClass;
            if (controllerTypeClass && defaultInflatedDiskController.isOfType(controllerTypeClass)) {
               return canUseFlatVer2BackingOption(deviceOption);
            }
            return false;
         });

         var capacity = getCapacity(deviceType, additionalParameters, guestOsDesc.recommendedDiskSizeMB * 1024);
         var rawDevice = {
            _type: diskOption.type.name,
            key: deviceService.newKey(),
            capacityInKB: capacity.valueInKB,
            capacityInBytes: capacity.valueInBytes,
            unitNumber: unitNumber,
            controllerKey: defaultInflatedDiskController.getKey(),
            backing: getBackingForDeviceType(deviceType, additionalParameters),
            storageIOAllocation: getDefaultStorageIOAllocation(),
            createdFromExistingDisk: deviceType === deviceTypeConstants.EXISTINGHARDDISK
         };

         disk = deviceService.setDeviceInfo(new VirtualDisk(rawDevice, null));
         if (additionalParameters && additionalParameters.selectedStorageProfile) {
            disk.updateDiskProfile(additionalParameters.selectedStorageProfile,
                  additionalParameters.selectedReplicationGroupInfo);
         }

         return {
            device: disk,
            controller: disk ? defaultInflatedDiskController : null
         };
      }

      function getRecommendedOrAvailableController(guestOsDesc, deviceOptions,
            otherInflatedDevices) {
         var controllerAdvise = diskControllerIndexAdviserService.getAdviceOnReusableController(guestOsDesc.recommendedDiskController,
               deviceOptions,
               otherInflatedDevices,
               'VirtualDisk');
         controllerAdvise.defaultDiskControllerType = guestOsDesc.recommendedDiskController;
         if (!controllerAdvise.recommendedController && controllerAdvise.recommendedBusNo < 0) {
            return checkForNextAvailableController(guestOsDesc, deviceOptions, otherInflatedDevices);
         }
         return controllerAdvise;
      }

      function checkForNextAvailableController(guestOsDesc, deviceOptions, otherInflatedDevices) {
         var otherSupportedControllerTypes = _.filter(guestOsDesc.supportedDiskControllerList, function(controller) {
            return controller.name !== guestOsDesc.recommendedDiskController.name;
         });
         var i = 0;
         var controllerAdvise = {};
         while (otherSupportedControllerTypes && i < otherSupportedControllerTypes.length) {
            controllerAdvise = diskControllerIndexAdviserService.getAdviceOnReusableController(
                  otherSupportedControllerTypes[i], deviceOptions,
                  otherInflatedDevices, 'VirtualDisk');
            if (!controllerAdvise.recommendedController && controllerAdvise.recommendedBusNo < 0) {
               i++;
               continue;
            } else {
               controllerAdvise.defaultDiskControllerType = otherSupportedControllerTypes[i];
               break;
            }
         }
         return controllerAdvise;
      }

      function getCapacity(deviceType, additionalParameters, recommendedCapacityInKB) {
         var capacityInKB;
         switch (deviceType) {
            case deviceTypeConstants.EXISTINGHARDDISK:
               if (additionalParameters.diskFile &&
                     additionalParameters.diskFile.fileInfo && additionalParameters.diskFile.fileInfo.capacityKb) {
                  capacityInKB = additionalParameters.diskFile.fileInfo.capacityKb;
               }
               break;
            case deviceTypeConstants.RDMDISK:
               if (additionalParameters.device && additionalParameters.device.capacity) {
                  capacityInKB = additionalParameters.device.capacity / 1024;
               }
               break;
            default:
               break;
         }

         capacityInKB = capacityInKB || recommendedCapacityInKB;

         return {
            valueInKB: capacityInKB,
            valueInBytes: capacityInKB * 1024
         };
      }

      function getBackingForDeviceType(deviceType, additionalParameters) {
         switch (deviceType) {
            case deviceTypeConstants.EXISTINGHARDDISK:
               if (additionalParameters.diskFile) {
                  return getBackingInfoForExistingDisk(additionalParameters.diskFile);
               } else {
                  throw new Error("Expected details of the file not present");
               }
               break;
            case deviceTypeConstants.RDMDISK:
               return makeRDMDeviceBacking(additionalParameters.device);
            case deviceTypeConstants.VIRTUALDISK:
               return makeFlatVer2Backing();
            default:
               throw new Error("Unsupported device type");
         }
      }

      function getBackingInfoForExistingDisk(file) {
         var backing = {};
         var fileInfo = file.fileInfo;
         if (fileInfo.diskType && fileInfo.diskType.typeClass) {
            backing._type = fileInfo.diskType.typeClass;
         } else {
            // See bug - 753043
            backing._type = diskBackingInfoConstants.SESPARSE;
         }

         backing.fileName = file.path;
         backing.diskMode = "persistent";

         if (!_.isEmpty(fileInfo.thin)) {
            backing.thinProvisioned = fileInfo.thin;
         }

         if (fileInfo.encryption && fileInfo.encryption.keyId) {
            backing.keyid = fileInfo.encryption.keyId;
         }
         return backing;
      }

      function getDefaultStorageIOAllocation() {
         return {
            _type: "com.vmware.vim.binding.vim.StorageResourceManager$IOAllocationInfo",
            limit: -1,
            shares: {
               _type: "com.vmware.vim.binding.vim.SharesInfo",
               shares: 1000,
               level: "normal"
            }
         };
      }

      function canUseFlatVer2BackingOption(deviceOption) {
         return _.find(deviceOption.backingOption, function(backingOption) {
            return backingOption._type === "com.vmware.vim.binding.vim.vm.device.VirtualDiskOption$FlatVer2BackingOption";
         });
      }

      function makeFlatVer2Backing() {
         return {
            _type: diskBackingInfoConstants.FLATVER2,
            fileName: '',
            diskMode: 'persistent'
         };
      }

      function makeRDMDeviceBacking(device) {
         return {
            _type: diskBackingInfoConstants.RAWDISKMAPPING,
            fileName: '',
            diskMode: 'independent_persistent',
            compatibilityMode: 'physicalMode',
            deviceName: device.deviceName
         };
      }

      function backingInfoKeys(provisioningType) {
         return typeToBackingKeysTable[provisioningType];
      }

      function hasFormat(backingInfo) {
         return backingInfo.thinProvisioned !== undefined;
      }

      function getFormat(backingInfo) {
         var type = _.findKey(typeToBackingKeysTable, function(value) {
            return value.thinProvisioned === backingInfo.thinProvisioned && value.eagerlyScrub === backingInfo.eagerlyScrub;
         });

         return diskFormats[type];
      }

      function getBackingInfo(provisioningType) {
         return _.extend(
               {
                  _type: diskBackingInfoConstants.FLATVER2,
                  fileName: "",
                  diskMode: ''
               },
               backingInfoKeys(provisioningType)
         );
      }

      function getName(backingInfo) {
         if (!backingInfo) {
            return "";
         }
         if (backingInfo._type && backingInfo._type.split('$')[1] === 'SeSparseBackingInfo') {
            return diskFormats.sesparse.name;
         } else if (backingInfo.thinProvisioned) {
            return diskFormats.thin.name;
         } else if (backingInfo.eagerlyScrub) {
            return diskFormats.thick.name;
         } else {
            return diskFormats.flat.name;
         }
      }

      /**
       * @description Sets the backing.fileName property and backing.datastore of each
       *    new disk device in deviceChanges parameter to contain the correct datastore
       *    name if disk is placed on a standalone host or on a specific datastore
       *    in a datastore cluster (SDRS disabled)
       * @param deviceChanges The device changes of the virtual machine containing
       *    the disk device changes.
       * @param vmStorageObject The storage object where the VMX will be placed.
       * @param diskStorageMap Contains the storage information for each disk
       * @returns PodSelectionSpec object containing info on how the vmx and disks will
       *    be placed.
       */
      function setBackingDatastore(deviceChanges, vmStorageObject, diskStorageMap) {
         var allDiskChanges = _.filter(deviceChanges, function(deviceChange) {
            return vmDeviceInfoService.isDeviceSubclassOf(deviceChange.device, "VirtualDisk")
                  && deviceChange.device.key < 0;
         });

         angular.forEach(allDiskChanges, function(diskChange) {
            if (diskChange.device.createdFromExistingDisk) {
               return;
            }
            var storageObject = vmStorageObject;

            var diskDatastoreConfig = diskStorageMap ?
                  diskStorageMap[diskChange.device.key] :
                  undefined;
            if (diskDatastoreConfig) {
               storageObject = diskDatastoreConfig.dsObject;
            }

            // When placing on a datastore cluster the backing.fileName and backing.datastore
            // should be unset, SDRS will decide where the disk would go.
            if (isStoragePod(storageObject)
                  || pmemUtilService.isPmemDisk(diskChange.device)) {
               diskChange.device.backing.fileName = '';
               diskChange.device.backing.datastore = undefined;
            } else {
               diskChange.device.backing.fileName = '[' + storageObject.name + ']';
            }
         });
      }

      function isStoragePod(storageObject) {
         return storageObject.storageRef.type === managedEntityConstants.STORAGE_POD;
      }

      /**
       * @param {com.vmware.vim.binding.vim.vm.device.VirtualDevice$BackingInfo} backingInfoA
       * @param {com.vmware.vim.binding.vim.vm.device.VirtualDevice$BackingInfo} backingInfoB
       *
       * @returns {boolean}
       */
      function areDiskFormatsEqual(backingInfoA, backingInfoB) {
         if (backingInfoA === backingInfoB) {
            return true;
         }

         if (!backingInfoA || !backingInfoB) {
            return false;
         }

         return (backingInfoA.thinProvisioned === backingInfoB.thinProvisioned) &&
               (backingInfoA.eagerlyScrub === backingInfoB.eagerlyScrub);
      }
   }
})();
