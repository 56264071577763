namespace network_ui {

   import ActionEvaluation = com.vmware.vise.actionsfw.ActionEvaluation;

   export class IscsiPortBindingList {
      public bindings: any;
      public controller: any;
      public templateUrl: string;

      constructor() {
         this.bindings = {
            hostId: "<",
            iscsiHba: "<",
            adapterWarnings: "<"
         };

         this.controller = IscsiPortBindingListController;
         this.templateUrl = "network-ui/resources/network/views/host/iscsi/IscsiPortBindingList.html";
      }
   }

   class IscsiPortBindingListController {

      public static $inject = ["i18nService", "iscsiPortBindingService", "vuiConstants", "columnRenderersRegistry",
         "datagridActionBarService", "$scope", "networkUiConstants"];


      public i18n: Function;

      public hostId: string;

      public iscsiHba: any;

      public adapterWarnings: any;

      public loading: boolean;

      public noPermissions: boolean;

      public datagridOptions: any;

      constructor(i18nService: any,
                  private iscsiPortBindingService: IscsiPortBindingService,
                  private vuiConstants: any,
                  private columnRenderersRegistry: any,
                  private datagridActionBarService: any,
                  private $scope: any,
                  private networkUiConstants: any) {

         this.i18n = i18nService.getString;
         this.loading = false;
         this.noPermissions = false;
      }

      public $onInit() {
         this.datagridOptions = {
            height: "100%",
            pageConfig: {
               hidePager: true
            },
            columnDefs: this.getColumnDefs(),
            sortMode: this.vuiConstants.grid.sortMode.SINGLE,
            selectionMode: this.vuiConstants.grid.selectionMode.MULTI,
            selectedItems: [],
            resizable: true,
            actionBarOptions: {actions: []}
         };
         this.updateActionBar();

         this.$scope.$watch(() => {
            return this.datagridOptions.selectedItems;
         }, (newSelection: any, oldSelection: any) => {
            if (newSelection === oldSelection || _.isEqual(newSelection, oldSelection)) {
               return;
            }
            this.$scope.cachedItems = newSelection;
            this.updateActionBar();
         });

         this.$scope.comparator = (value: any) => {
            let itemToSelect = _.find(this.$scope.cachedItems, function(cachedItem: any) {
               return cachedItem.pnicDevice === value.pnicDevice
                     && cachedItem.vnicDevice === value.vnicDevice
                     && cachedItem.switchName === value.switchName;
            });
            return itemToSelect !== undefined;
         };
      }

      public $onChanges() {
         this.requestData();
      }

      public requestData(): void {
         let self = this;

         if (!self.hostId || !self.iscsiHba || !self.iscsiHba.device) {
            return;
         }

         self.loading = true;
         self.iscsiPortBindingService.getBoundIscsiPortInfoData(self.hostId, self.iscsiHba.device)
               .then(function (response) {
                  if (!response) {
                     return;
                  }
                  self.noPermissions = !response.hasPrivileges;
                  self.datagridOptions.data = response.items;
               })
               .finally(function () {
                  self.loading = false;
               });
      }

      private getColumnDefs(): any[] {
         let iconTextRenderer: any =
               this.columnRenderersRegistry.getColumnRenderer("icon-text");
         let textRenderer: any =
               this.columnRenderersRegistry.getColumnRenderer("text");

         let renderImageLabel = function (imageLabel: ImageLabel) {
            if (imageLabel.icon) {
               return iconTextRenderer(["icon", "label"], imageLabel);
            }
            return textRenderer(["label"], imageLabel);
         };

         return [
            {
               displayName: this.i18n("NetworkUi", "portbinding.list.portgroup.label"),
               field: "portgroupName.label",
               width: "180px",
               template: function (item: IscsiPortInfoItem) {
                  return renderImageLabel(item.portgroupName);
               }
            },
            {
               displayName: this.i18n("NetworkUi", "portbinding.list.vMKernel.label"),
               field: "vmKernelAdapter.label",
               width: "180px",
               template: function (item: IscsiPortInfoItem) {
                  return renderImageLabel(item.vmKernelAdapter);
               }
            },
            {
               displayName: this.i18n("NetworkUi", "portbinding.list.portgroupPolicy.label"),
               field: "policyComplianceStatus.label",
               width: "180px",
               template: function (item: IscsiPortInfoItem) {
                  return renderImageLabel(item.policyComplianceStatus);
               }
            },
            {
               displayName: this.i18n("NetworkUi", "portbinding.list.pathStatus.label"),
               field: "pathStatus.label",
               width: "180px",
               template: function (item: IscsiPortInfoItem) {
                  return renderImageLabel(item.pathStatus);
               }
            },
            {
               displayName: this.i18n("NetworkUi", "portbinding.list.physicalNetworkAdapter.label"),
               field: "physicalNetworkAdapterInfo.label",
               width: "180px",
               template: function (item: IscsiPortInfoItem) {
                  return renderImageLabel(item.physicalNetworkAdapterInfo);
               }
            }
         ];
      }

      private getActionDefs() {
         let self: IscsiPortBindingListController = this;
         return [
            {
               actionId: self.networkUiConstants.actions.ADD_PORT_BINDING_FOR_ISCSI,
               getActionInvocationContext: () => {
                  return {
                     iscsiHbaName: self.iscsiHba.device,
                     onSuccessCallback: this.onOperationCompleted.bind(
                           self, self.iscsiHba.key, self.iscsiHba.device)
                  };
               }
            },
            {
               actionId: self.networkUiConstants.actions.REMOVE_PORT_BINDING_FROM_ISCSI,
               getActionInvocationContext: () => {
                  return {
                     iscsiHbaName: self.iscsiHba.device,
                     adapters: self.datagridOptions.data,
                     selectedAdapters: self.datagridOptions.selectedItems,
                     onSuccessCallback: this.onOperationCompleted.bind(
                           self, self.iscsiHba.key, self.iscsiHba.device)
                  };
               },
               isActionAvailable: (actionDef: ActionEvaluation) => {
                  return actionDef.available && self.datagridOptions.selectedItems.length !== 0;
               }
            },
            {
               actionId: self.networkUiConstants.actions.SHOW_ISCSI_PORT_BINDING_DETAILS,
               isActionAvailable: function (actionDef: ActionEvaluation) {
                  return actionDef.available && self.datagridOptions.selectedItems
                        && self.datagridOptions.selectedItems.length === 1;
               },
               getActionInvocationContext: () => {
                  return {
                     iscsiPortInfoItem: self.datagridOptions.selectedItems[0]
                  };
               }
            }
         ];
      }

      private updateActionBar(): void {
         this.datagridActionBarService.updateActionBar(
               this.datagridOptions,
               [this.hostId],
               this.getActionDefs());
      }

      private onOperationCompleted(deviceKey: string,
            deviceName: string, result: any): void {
         if (result && !result.error) {
            this.adapterWarnings.addRescanMessage(deviceKey, deviceName);
         }
         this.requestData();
      }
   }

   angular.module("com.vmware.vsphere.client.network")
         .component("iscsiPortBindingList", new IscsiPortBindingList());
}
