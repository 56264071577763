(function () {
   'use strict';

   angular
   .module('com.vmware.vsphere.client.vm')
   .controller('VmPowerOnRecommendationsController', VmPowerOnRecommendationsController);

   VmPowerOnRecommendationsController.$inject = ['$scope', 'vuiConstants', 'i18nService',
      'vmRecommendationsService'];

   function VmPowerOnRecommendationsController($scope, vuiConstants, i18nService, vmRecommendationsService) {
      var self = this;

      $scope.modalOptions.onSubmit = function () {
         var selectedItems = self.dataGridOptions.selectedItems;
         if (selectedItems.length > 0) {
            var selectedItem = selectedItems[0];
            var target = selectedItem.targetClusterId;
            var recommendation = recommendationsByKey[selectedItem.key];
            vmRecommendationsService.applyRecommendation(target, recommendation);

            $scope.modalOptions.alerts = [];
            return true;
         } else {
            $scope.modalOptions.alerts  = [{
               text: i18nService.getString('VmUi','VmPowerOnRecommendationsController.errorSelectOneRecommendation'),
               type: vuiConstants.notifications.type.ERROR
            }];
            return false;
         }
      };

      var recommendationLabel = i18nService.getString('VmUi', 'RecommendationsList.recommendationColumnHeaderText');
      var priorityLabel = i18nService.getString('VmUi', 'RecommendationsList.priorityColumnHeaderText');
      var rawRecommendations = $scope.modalOptions.dialogData.rawRecommendations;
      var recommendationsByKey = _.indexBy(rawRecommendations, 'key');
      self.dataGridOptions = {
         dataBound: selectFirstRow,
         columnDefs: [
            {
               displayName: recommendationLabel,
               template: kendo.template("#:reasonText#" +
               "<ul> " +
               "  # for (var i = 0; i < actions.length; i++) { #" +
               "     <li ng-non-bindable><span class=\"#:actions[i].icon#\"></span>#:actions[i].text#</li>" +
               "   # } # " +
               "</ul>"),
               field: 'reasonText',
               width: '80%'
            },
            {
               displayName: priorityLabel,
               field: 'priority',
               template: '<div style="text-align: center;">#:priority#</div>'
            }
         ],
         sortMode: vuiConstants.grid.sortMode.SINGLE,
         selectionMode: vuiConstants.grid.selectionMode.SINGLE,
         selectedItems: [],
         data: $scope.modalOptions.dialogData.recommendations,
         resizable: true,
         searchable: false,
         height: 350
      };

      function selectFirstRow() {
         var grid = $("#recommendations > div").data("kendoGrid");
         if (grid) {
            grid.select("tr:first");
         }
      }
   }
})();
