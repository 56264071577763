/* Copyright 2018 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace folder_ui {

   import ManagedObjectReference = com.vmware.vim.binding.vmodl.ManagedObjectReference;
   import ManagedEntityMoveSpec = com.vmware.vsphere.client.mixed.ManagedEntityMoveSpec;

   export class MoveFolderService {

      public static $inject = [
         "clarityModalService",
         "i18nService",
         "mutationService",
         "defaultUriSchemeUtil"
      ];

      constructor(private clarityModalService: any,
            private i18nService: any,
            private mutationService: any,
            private defaultUriSchemeUtil: any) {

      }

      public openMoveToDialog(availableTargets: string[]) {
         const modalOptions: any = {
            title: this.i18nService.getString("FolderUi", "moveto.dialog.title"),
            defaultButton: "submit",
            dialogData: {
               objectIds: availableTargets
            },
            contentTemplate: "folder-ui/resources/folder/views/folder/move/moveFolderDialog.html"
         };

         if (availableTargets.length === 1) {
            modalOptions.subTitle = {
               objectId: availableTargets[0]
            };
         }

         modalOptions.onSubmit = () => {
            this.move(modalOptions.dialogData.objectIds,
                  modalOptions.dialogData.targetEntity);
            return true;
         };

         this.clarityModalService.openOkCancelModal(modalOptions);
      }

      public move(folderIds: string[], targetEntity: any): void {
         const folderMors: ManagedObjectReference[] = folderIds.map((folderId: string) => {
            return this.defaultUriSchemeUtil.getManagedObjectReference(folderId);
         });

         const moveSpec: ManagedEntityMoveSpec = new ManagedEntityMoveSpec();
         moveSpec.entities = folderMors;

         this.mutationService.apply(
               targetEntity,
               moveSpec._type,
               moveSpec).then(() => {});
      }
   }

   angular.module("com.vmware.vsphere.client.folder")
         .service("moveFolderService", MoveFolderService);
}