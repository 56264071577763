/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace dvs_ui {

   import DvpgSystemQualifierModel = com.vmware.vsphere.client.h5.network.dvportgroup.trafficfilter.model.DvpgSystemQualifierModel;
   export class DvpgSystemQualifierComponent {

      public bindings: any;
      public controller: any;
      public templateUrl: string;

      constructor() {
         this.bindings = {
            model: "<"
         };

         this.controller = DvpgSystemQualifierContoller;

         this.templateUrl = "dvs-ui/resources/dvs/dvpg/trafficfilter/common/" +
               "dvpgSystemQualifierTemplate.html";
      }
   }

   export class DvpgSystemQualifierContoller {

      static $inject = [
            "i18nService",
            "dvpgTrafficFilterService"
      ];

      public model: DvpgSystemQualifierModel;

      private i18n: any;

      private trafficOperations: string[];
      private trafficTypes: string[];

      constructor(private i18nService: any,
                  private trafficFilterService: any) {
         this.i18n = this.i18nService.getString;

         this.trafficOperations =
               this.trafficFilterService.getSystemQualifierOperations();
         this.trafficTypes =
               this.trafficFilterService.getSystemQualifierTypes();
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .component("dvpgSystemQualifier", new DvpgSystemQualifierComponent());
}

