/* Copyright 2018 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace dvs_ui {

   export class PortgroupDetailsDialogPropertiesViewComponent {

      public bindings: any;
      public controller: any;
      public template: string;

      constructor() {
         this.bindings = {
            data: "<"
         };

         this.controller = PortgroupDetailsDialogPropertiesViewComponentController;

         this.template = `<vx-property-view class="network-ui-fixed-size-vx-property-view"
                                            data="$ctrl.propertyViewData"/>`;
      }
   }

   class PortgroupDetailsDialogPropertiesViewComponentController {

      static $inject = [
            "dvPortgroupPropertiesService",
            "networkDetailsViewService"
      ];

      isDvPortgroup: boolean;

      data: any;

      propertyViewData: any;

      constructor(private dvPortgroupPropertiesService: any,
                  private networkDetailsViewService: any) {}

      $onInit(): void {
         if (this.isDvPortgroup) {
            this.propertyViewData =
                  this.dvPortgroupPropertiesService.buildProperties(this.data);
         } else {
            this.propertyViewData =
                  this.networkDetailsViewService.buildOnlyProperties(this.data);
         }
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .component("portgroupDetailsDialogPropertiesView",
               new PortgroupDetailsDialogPropertiesViewComponent());
}
