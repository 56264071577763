namespace spbm_ui {

   import IController = angular.IController;

   class StorageProfilesViewController implements IController {
      public static $inject = ["i18nService", "$q"];
      public readonly detailsViewData: any;

      constructor(private i18nService: any,
                  private $q: ng.IQService) {
         this.detailsViewData = {
            tabsPromise: this.$q.when([
               {
                  name: this.i18nService.getString("SpbmUi", "h5.profile.details.rules"),
                  templateUrl: "spbm-ui/resources/spbm/views/profile/details/StorageProfileGeneralTab.html",
                  uid: "profile.generalTab"
               },
               {
                  name: this.i18nService.getString("SpbmUi", "h5.profile.details.vmCompliance"),
                  templateUrl: "spbm-ui/resources/spbm/views/profile/details/VmComplianceTab.html",
                  uid: "profile.vmComplianceTab"
               },
               {
                  name: this.i18nService.getString("Common", "typeResource.vmTemplate"),
                  templateUrl: "spbm-ui/resources/spbm/views/profile/details/VmTemplatesTab.html",
                  uid: "profile.vmTemplatesTab"
               },
               {
                  name: this.i18nService.getString("SpbmUi", "h5.profile.details.storageCompatibility"),
                  templateUrl: "spbm-ui/resources/spbm/views/profile/details/StorageCompatibilityTab.html",
                  uid: "profile.storageCompatibilityTab"
               }
            ])
         };
      }
   }

   angular.module("com.vmware.vsphere.client.spbm")
         .controller("StorageProfilesViewController", StorageProfilesViewController);
}
