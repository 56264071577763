/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace network_ui {

   export class ConfigureSettingsPageValidator {

      public static $inject = [
         "i18nService",
         "networkUtil",
         "networkLabelValidator"
      ];

      constructor(private i18nService: any,
                  private networkUtil: any,
                  private networkLabelValidator: any) {
      }

      public getValidationError(networkLabel: string, vlanId: string, hostId: string): string[] {

         let errors: string[] = [];

         if (!this.networkUtil.isVlanIdValid(vlanId)) {
            errors.push(this.i18nService.getString("NetworkUi",
                  "VnicData.invalidVlanId"));
         }
         errors = errors.concat(this.networkLabelValidator
               .getNetworkLabelClientSideValidationErrors(networkLabel));

         //in case there are no client side validation errors a
         // server validation is done.
         if (errors.length === 0) {
            return this.networkLabelValidator
                  .getNetworkLabelServerSideValidationErrors(hostId, networkLabel);
         }

         return errors;
      }
   }

   angular.module("com.vmware.vsphere.client.network")
         .service("configureSettingsPageValidator",
               ConfigureSettingsPageValidator);
}
