namespace h5_vm {
   export class VmProvisioningSelectVMTXTemplateController {
      public static $inject = ["$timeout", "$element"];

      constructor(private $timeout: any, private $element: any) {
         $timeout(() => {
            const tree = $element[0].querySelector("[vx-tree-view]");
            if (tree) {
               tree.focus();
            }
         }, 0);
      }
   }
   angular.module("com.vmware.vsphere.client.vm")
         .controller("VmProvisioningSelectVMTXTemplateController", VmProvisioningSelectVMTXTemplateController);
}
