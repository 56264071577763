namespace storage_ui {

   import IComponentOptions = angular.IComponentOptions;
   import IComponentController = angular.IComponentController;
   import any = jasmine.any;

   class IpAddressGridComponentController implements IComponentController {
      public static $inject = ["i18nService", "vuiConstants", "clarityModalService", "hbaNetworkingUtil",
         "clarityConstants"];
      private datagridOptions: any;
      private staticAddress: InternetScsiHba$IscsiIpv6Address[];

      constructor(private i18nService: any,
                  private vuiConstants: any,
                  private clarityModalService: any,
                  private hbaNetworkingUtil: any,
                  private clarityConstants: any) {
         this.initDatagrid();
      }

      private initDatagrid() {
         this.datagridOptions = {
            height: "100%",
            pageConfig: {
               hidePager: false
            },
            columnDefs: this.getColumnDefs(),
            sortMode: this.vuiConstants.grid.sortMode.SINGLE,
            selectionMode: this.vuiConstants.grid.selectionMode.SINGLE,
            selectedItems: [],
            resizable: true,
            actionBarOptions: {actions: this.getActions()},
            columnMenu: {
               sortable: false, // this will hide sort menu items
               messages: {
                  columns: this.i18nService.getString("CommonUi", "listview.showColumnsMenu"),
                  filter: this.i18nService.getString("CommonUi", "listview.filterMenu")
               }
            },
            onChange: ()=> {
               this.datagridOptions.actionBarOptions.actions[1].enabled = !!this.datagridOptions.selectedItems.length;
            }
         };
      }

      $onInit() {
         this.datagridOptions.data = this.staticAddress;
      }

      private getActions(): any[] {
         let self = this;
         return [{
            tooltipText: this.i18nService.getString("StorageUi", "IScsiIpv6SettingsPage.addButtonTooltip"),
            label: this.i18nService.getString("StorageUi", "IScsiIpv6SettingsPage.addButtonLabel"),
            iconClass: "vx-icon-addIcon",
            enabled: true,
            onClick: ()=> {
               let modalOptions: any = {
                        title: this.i18nService.getString("StorageUi", "IScsiIpv6SettingsPage.addButtonTooltip"),
                        contentTemplate: "storage-ui/resources/storage/views/host/adapters/network/AddIpAddress.html",
                        defaultButton: "submit",
                        dialogData: {
                           ipInput: "",
                           validateManually: null
                        },
                        onSubmit: function (this: any) {
                           let address = this.dialogData.ipInput;
                           if (this.dialogData.validateManually().length) {
                              return false;
                           }
                           let message = "";
                           if (self.hbaNetworkingUtil.isLinkLocalIpv6Address(address)) {
                              message = self.i18nService.getString("StorageUi", "Ipv6AddressPage.linkLocalForbiddenError");
                           } else if (!!_.find(self.datagridOptions.data, (item: InternetScsiHba$IscsiIpv6Address) =>
                                 item.address === address)) {
                              message = self.i18nService.getString("StorageUi",
                                    "Ipv6AddressPage.duplicateIpv6AddressNewVnicError", this.dialogData.ipInput);
                           }
                           if (message) {
                              this.alerts = [{
                                 type: self.clarityConstants.notifications.type.ERROR,
                                 text: message
                              }];
                              return false;
                           }

                           self.datagridOptions.data = self.datagridOptions.data.concat(self.hbaNetworkingUtil.createIpv6(this.dialogData.ipInput));
                           self.staticAddress = self.datagridOptions.data;
                           return true;
                        },
                        alerts: []
                     };
               this.clarityModalService.openOkCancelModal(modalOptions);
            }
         }, {
            tooltipText: this.i18nService.getString("StorageUi", "IScsiIpv6SettingsPage.removeButtonTooltip"),
            label: this.i18nService.getString("StorageUi", "IScsiIpv6SettingsPage.removeButtonLabel"),
            iconClass: "vx-icon-removeIcon",
            enabled: true,
            onClick: ()=> {
               if (self.datagridOptions.selectedItems.length) {
                  self.datagridOptions.data = _.filter(self.datagridOptions.data, function (item: InternetScsiHba$IscsiIpv6Address) {
                     return item.address !== self.datagridOptions.selectedItems[0].address;
                  });
                  self.staticAddress = self.datagridOptions.data;
               }
            }
         }];
      }

      private getColumnDefs() {
         return [{
            displayName: this.i18nService.getString("StorageUi", "addDatastoreWizard.nfsSettingsPage.serverColumn"),
            field: "serverName",
            template: "#:address#/64"
         }];
      }
   }

   angular.module("com.vmware.vsphere.client.storage")
         .component("ipAddressGrid", <IComponentOptions>{
            templateUrl: "storage-ui/resources/storage/views/host/adapters/network/IpAddressGridComponent.html",
            bindings: {
               staticAddress: "="
            },
            controller: IpAddressGridComponentController
         });
}
