namespace h5_vm {

   declare const angular: ng.IAngularStatic;
   declare const _: any;
   import DeviceTypeConstants = h5_vm.DeviceTypeConstants;

   export class VmHardwareDeviceMenuService {
      static $inject: string[] = [
         "vmHardwareUtil",
         "i18nService",
         "featureFlagsService"
      ];

      private i18n: Function;

      constructor(private vmHardwareUtil: any,
                  private i18nService: any,
                  private featureFlagsService: any) {
         this.i18n = i18nService.getString;
      }

      getAddDeviceActions(availablePrivileges: string[], workflow:VmWorkflowMode = VmWorkflowMode.UpdateMode, keysToFilterOut: Array<string> = []): any {

         let hasAddRemovePrivilege:boolean = this.hasPrivilegesToAddDevice(availablePrivileges, [Privileges.VM_ADDREMOVEDEVICE_PRIVILEGE]);
         if (workflow === VmWorkflowMode.CreateMode) {
            // Generic add-remove privilege not needed for new VM.
            // Permissions for Disk, Existing disk, USB device and Raw Device still do matter even for new VM
            hasAddRemovePrivilege = true;
         }

         let filteredActions: any = this.getAllAddDeviceActions(availablePrivileges, hasAddRemovePrivilege);
         keysToFilterOut.map((key: string) => {
            if (filteredActions.hasOwnProperty(key)) {
               delete filteredActions[key];
            }
         });

         return filteredActions;
      }

      private getAllAddDeviceActions(availablePrivileges: any, hasAddRemovePrivilege: boolean) {
         return {
            addNewCdDvdDriveAction: {
               id: "addNewCdDvdDriveAction",
               label: this.i18n("VmUi", "VmDeviceManager.Cdrom"),
               deviceType: "VirtualCdrom",
               hasPrivilege: hasAddRemovePrivilege
            },
            addNewUSBDeviceAction: {
               id: "addNewUSBDeviceAction",
               label: this.i18n("VmUi", "VmDeviceManager.USBDevice"),
               deviceType: "VirtualUSB",
               hasPrivilege: this.hasPrivilegesToAddDevice(availablePrivileges, [Privileges.VM_HOST_USB_DEVICE])
            },
            addNewHardDiskAction: {
               id: "addNewHardDiskAction",
               label: this.i18n("VmUi", "VmDeviceManager.HardDisk"),
               deviceType: "VirtualDisk",
               hasPrivilege: this.hasPrivilegesToAddDevice(availablePrivileges, [Privileges.VM_ADDNEWDISK_PRIVILEGE])
            },
            addNewRDMDiskAction: {
               id: "addNewRDMDiskAction",
               label: this.i18n("VmUi", "VmDeviceManager.RDMDisk"),
               deviceType: "RDMDisk",
               hasPrivilege: this.hasPrivilegesToAddDevice(availablePrivileges, [Privileges.VM_RAWDEVICE_PRIVILEGE])
            },
            addExistingDiskAction: {
               id: "addExistingDiskAction",
               label: this.i18n("VmUi", "VmDeviceManager.ExistingHardDisk"),
               deviceType: "ExistingHardDisk",
               hasPrivilege: this.hasPrivilegesToAddDevice(availablePrivileges, [Privileges.VM_ADDEXISTINGDISK_PRIVILEGE])
            },
            addNewNetworkAdapterAction: {
               id: "addNewNetworkAdapterAction",
               label: this.i18n("VmUi", "VmDeviceManager.NetworkAdapter"),
               deviceType: "VirtualEthernetCard",
               hasPrivilege: hasAddRemovePrivilege
            },
            addNewSCSIControllerAction: {
               id: "addNewSCSIControllerAction",
               label: this.i18n("VmUi", "VmDeviceManager.SCSIController"),
               deviceType: "VirtualSCSIController",
               hasPrivilege: hasAddRemovePrivilege
            },
            addNewUSBControllerAction: {
               id: "addNewUSBControllerAction",
               label: this.i18n("VmUi", "VmDeviceManager.USBController"),
               deviceType: "VirtualUSBController",
               hasPrivilege: hasAddRemovePrivilege
            },
            addNewSATAControllerAction: {
               id: 'addNewSATAControllerAction',
               label: this.i18n('VmUi', 'VmDeviceManager.SATAController'),
               deviceType: DeviceTypeConstants.VIRTUAL_SATA_CONTROLLER,
               hasPrivilege: hasAddRemovePrivilege
            },
            addNewNvdimmAction: {
               id: "addNewNvdimmAction",
               label: this.i18n("VmUi", "VmDeviceManager.NvDimm"),
               deviceType: "VirtualNVDIMM",
               hasPrivilege: this.hasPrivilegesToAddDevice(availablePrivileges, [Privileges.VM_ADDREMOVEDEVICE_PRIVILEGE]),
               featureFlag: this.isNvmFeatureFlagEnabled()
            },
            addNewNVMeControllerAction: {
               id: "addNewNVMeControllerAction",
               label: this.i18n("VmUi", "VmDeviceManager.NVME"),
               deviceType: "VirtualNVMEController",
               hasPrivilege: hasAddRemovePrivilege
            },
            addPciSharedAction: {
               id: "addPciSharedAction",
               label: this.i18n("VmUi", "VmDeviceManager.SharedPCI"),
               deviceType: "VirtualPCIPassthroughShared",
               hasPrivilege: hasAddRemovePrivilege
            },
            addPciDeviceAction: {
               id: "addPciDeviceAction",
               label: this.i18n("VmUi", "VmDeviceManager.PCIDevice"),
               deviceType: "VirtualPCIPassthrough",
               hasPrivilege: hasAddRemovePrivilege
            },
            addNewTpmAction: {
               id: 'addNewTpmAction',
               label: this.i18n('VmUi', 'VmDeviceManager.NewTPM'),
               deviceType: 'VirtualTPM',
               hasPrivilege: true,
               featureFlag: this.isVTpmFeatureFlagEnabled()
            },
            addSerialPortAction: {
               id: "addSerialPortAction",
               label: this.i18n("VmUi", "VmDeviceManager.SerialPort"),
               deviceType: DeviceTypeConstants.VIRTUAL_SERIAL_PORT,
               hasPrivilege: hasAddRemovePrivilege
            }
         };
      }

      private isVTpmFeatureFlagEnabled(): boolean {
         return (typeof this.featureFlagsService.HWv14Enabled === 'function') ?
               this.featureFlagsService.HWv14Enabled() : false;
      }

      private isNvmFeatureFlagEnabled(): boolean {
         return (typeof this.featureFlagsService.PMemEnabled === "function") ?
               this.featureFlagsService.PMemEnabled() : false;
      }

      hasPrivilegesToAddDevice(availablePrivileges: any, requiredPrivileges: any) {
         return this.vmHardwareUtil.checkPrivileges(availablePrivileges, requiredPrivileges);
      }

      isAddDeviceMenuEnabled(menuItems: any) {
         return _.some(menuItems, function (menuItem: any) {
            return menuItem.enabled === true;
         });
      }

      getAddDevicesMenuConfig(addDevice: any) {
         return {
            showMenu: false,
            menuItems: [],
            itemClick: addDevice
         };
      }

      getAddDeviceMenuItems(addDeviceActionDefs: any) {
         const addDeviceItems: any = [];
         const actionDefinitions: any = _.values(addDeviceActionDefs);
         actionDefinitions.forEach((actionDef: any) => {
            if (typeof actionDef.featureFlag === "undefined" || actionDef.featureFlag) {
               const item: any = this.getAddDeviceItem(actionDef);
               addDeviceItems.push(item);
            }
         });

         return addDeviceItems;
      }

      private getAddDeviceItem(actionDef: any) {
         return {
            id: actionDef.id,
            label: actionDef.label,
            itemClick: actionDef.clickHandler,
            enabled: actionDef.hasPrivilege,
            featureFlag: actionDef.featureFlag
         };
      }
   }

   angular.module("com.vmware.vsphere.client.vm")
      .service("vmHardwareDeviceMenuService", VmHardwareDeviceMenuService);
}

