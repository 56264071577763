namespace h5_vm {
   import LinuxTimeZoneArea = com.vmware.vsphere.client.h5.vm.configuration.LinuxTimeZoneArea;
   import LinuxTimeZoneLocation = com.vmware.vsphere.client.h5.vm.configuration.LinuxTimeZoneLocation;
   import WindowsTimeZone = com.vmware.vsphere.client.h5.vm.configuration.WindowsTimeZone;

   export class GosTimeZonePageController {
      model: GosTimeZonePageModel;
      i18n: (bundle: string, key: string, ...parameters: any[]) => string;

      linuxTimeZoneAreas: LinuxTimeZoneArea[];
      selectedTimeZoneArea: LinuxTimeZoneArea;
      selectedLinuxTimeZoneLocation: LinuxTimeZoneLocation;
      readonly UTC_OPTION = LinuxHardwareClockOption.UTC;
      readonly LOCAL_TIME_OPTION = LinuxHardwareClockOption.LOCAL_TIME;
      selectedHardwareClock: { value: LinuxHardwareClockOption };

      windowsTimeZones: WindowsTimeZone[];
      selectedWindowsTimeZone: WindowsTimeZone;

      public static $inject = [
         "$scope",
         "$q",
         "i18nService",
         "vuiConstants",
         "dataService",
         "$timeout"];

      constructor(private $scope: angular.IScope,
                  private $q: angular.IQService,
                  private i18nService: any,
                  private vuiConstants: any,
                  private dataService: any,
                  private $timeout: (callback: Function, delay: number) => void) {
      }

      $onInit(): void {
         this.model.onPageCommit = this.onCommit.bind(this);
         this.i18n = this.i18nService.getString;

         this.selectedHardwareClock = {
            value: this.model.hwClockUTC ||  this.model.hwClockUTC === null ?
                  this.UTC_OPTION : this.LOCAL_TIME_OPTION
         };

         if (this.model.timeZoneData) {
            this.initialize();
         } else {
            this.setLoading(true);
            this.dataService.getProperties(
                  this.model.customizationSpecManagerUrn, ["timeZones"]
            ).then((result: { id: string, timeZones: TimeZoneData }): void => {
               this.model.timeZoneData = result.timeZones;
               this.setLoading(false);
               this.initialize();
            });
         }
      }

      $onDestroy(): void {
         this.model.onPageCommit = undefined;
      }

      onCommit(): angular.IPromise<string[]> {
         return this.$q.resolve(this.validateIsTimezoneSelected());
      }

      isLinux(): boolean {
         return this.model.type === GosType.LINUX;
      }

      isWindows(): boolean {
         return this.model.type === GosType.WINDOWS;
      }

      private initialize(): void {
         if (!this.model.timeZoneData) {
            throw new Error("Time zone data is not defined - cannot initialize page");
         }

         if (this.isLinux()) {
            this.initializeForLinux(this.model.timeZoneData.linuxTimeZoneAreas);
         } else {
            this.initializeForWindows(this.model.timeZoneData.windowsTimeZones);
         }
      }

      timeZoneAreaChanged(): void {
         this.model.timeZoneArea = this.selectedTimeZoneArea.name;
         this.selectedLinuxTimeZoneLocation = this.selectedTimeZoneArea.locations[0];
         this.model.timeZoneLocation = this.selectedLinuxTimeZoneLocation.name;
      }

      timeZoneLocationChanged(): void {
         this.model.timeZoneLocation = this.selectedLinuxTimeZoneLocation.name;
      }

      hardwareClockChanged(): void {
         this.model.hwClockUTC =
               this.selectedHardwareClock.value === LinuxHardwareClockOption.UTC;
      }

      private initializeForLinux(areas: LinuxTimeZoneArea[]): void {
         this.linuxTimeZoneAreas = areas;

         if (this.model.timeZoneArea) {
            this.selectedTimeZoneArea = this.findLinuxTimeZoneArea(
                  this.model.timeZoneArea);
         } else {
            this.selectedTimeZoneArea = areas[0];
            this.model.timeZoneArea = this.selectedTimeZoneArea.name;
         }

         if (!this.model.timeZoneLocation) {
            this.model.timeZoneLocation = this.selectedTimeZoneArea.locations[0].name;
         }

         this.selectedLinuxTimeZoneLocation = _.find(this.selectedTimeZoneArea.locations,
               (location: LinuxTimeZoneLocation): boolean => {
                  return location.name === this.model.timeZoneLocation;
               });
      }

      linuxAreaLabel(areaName: string): string {
         return this.i18n("CommonTimeZones", "timeZoneLinux.Area." + areaName);
      }

      private linuxLocationLabel(locationName: string): string {
         return this.i18n("CommonTimeZones", "timeZoneLinux.Location." + locationName);
      }

      private findLinuxTimeZoneArea(name: string): LinuxTimeZoneArea {
         return _.find(this.linuxTimeZoneAreas, (area: LinuxTimeZoneArea): boolean => {
            return (area.name === name);
         });
      }

      private initializeForWindows(timeZones: WindowsTimeZone[]): void {
         this.windowsTimeZones = timeZones;

         if (this.model.timeZoneWinCode === undefined) {
            this.model.timeZoneWinCode = timeZones[0].code;
         }
         this.selectedWindowsTimeZone = _.find(timeZones, (timeZone: WindowsTimeZone): boolean => {
            return timeZone.code === this.model.timeZoneWinCode;
         });
      }

      windowsTimeZoneLabel(timeZoneCode: number): string {
         return this.i18n("CommonTimeZones", "timeZoneWindows." + timeZoneCode);
      }

      windowsTimeZoneChanged(): void {
         this.model.timeZoneWinCode = this.selectedWindowsTimeZone.code;
      }

      private findWindowsTimeZone(code: number): WindowsTimeZone {
         return _.find(this.windowsTimeZones, (timeZone: WindowsTimeZone): boolean => {
            return (timeZone.code === code);
         });
      }

      private setLoading(loading: boolean) {
         this.model.isLoading = loading;
      }

      private validateIsTimezoneSelected(): string[] {
         const errorMessages: string[] = [];

         if (this.model.isLoading) {
            errorMessages.push(this.i18n("VmUi", "customizationSpec.timeZonePage.selectValidTimezone"));
         }
         return errorMessages;
      }
   }

   export class GosTimeZonePageComponent implements angular.IComponentOptions {
      public controller: any;
      public templateUrl: string;
      public bindings: any;

      constructor() {
         this.controller = GosTimeZonePageController;
         this.templateUrl =
               "vm-ui/resources/vm/guest-os-customization/pages/time-zone/gos-time-zone-page.component.html";
         this.bindings = {
            model: "<"
         };
      }
   }

   angular
         .module("com.vmware.vsphere.client.vm")
         .component("gosTimeZonePage", new GosTimeZonePageComponent());
}
