namespace storage_ui {

   import IComponentController = angular.IComponentController;

   export class VmfsBlockSizeSelectedOptions {
      blockSize: any;
      granularity: any;
      reclamationPriority: any;
   }

   class VmfsBlockSizeOptionsController implements IComponentController {
      public static $inject = ["i18nService"];

      public blockSizeOptions: any[];
      public updateSettingsState: Function;
      public selectedSettings: VmfsBlockSizeSelectedOptions;

      constructor(public i18n: any) {
      }

      $onInit(): void {
         this.initSelectedSettings();
      }

      public populateDefaultGranularity(): void {
         if (!_.contains(this.getGranularityOptionsPerBlockSize(), this.selectedSettings.granularity)) {
            this.selectedSettings.granularity = this.getGranularityOptionsPerBlockSize()[0];
         }
      }

      public getGranularityOptionsPerBlockSize() {
         return this.selectedSettings.blockSize.granularityOptions;
      }

      public updateSettings(): void {
         this.updateSettingsState(this.selectedSettings);
      }

      public $onChanges(changes: any) {
         if (changes.selectedSettings.isFirstChange()) {
            // It is possible $onChanges to be called before $onInit so in
            // order to cover this case initialize selected settings here also.
            this.initSelectedSettings();
         }
         this.updateSettings();
      }

      private initSelectedSettings() {
         if (!this.selectedSettings) {
            this.selectedSettings = new VmfsBlockSizeSelectedOptions();
            this.selectedSettings.blockSize = this.blockSizeOptions[0];
            this.selectedSettings.granularity = this.getGranularityOptionsPerBlockSize()[0];
            this.selectedSettings.reclamationPriority = {unmapPriority: "low"};
         }
      }
   }

   angular.module("com.vmware.vsphere.client.storage")
         .component("vmfsBlockSizeOptions", {
            templateUrl: "storage-ui/resources/storage/directives/vmfsBlockSize/VmfsBlockSizeOptionsComponent.html",
            bindings: {
               blockSizeOptions: "<",
               updateSettingsState: "<",
               selectedSettings: "<"
            },
            controller: VmfsBlockSizeOptionsController
         });
}
