namespace h5_vapp {
   import VAppRemovalSpec = com.vmware.vsphere.client.vapp.VAppRemovalSpec;
   import ActionEvaluation = com.vmware.vise.actionsfw.ActionEvaluation;

   export class VappRemovalService {
      static $inject: string[] = [
         "mutationService"];

      constructor(private mutationService: any) {
      }

      public unregisterVapps(vappIds: string[]) {
         let unregisterSpec: VAppRemovalSpec = VappRemovalService.buildRemovalSpec(false);
         this.mutationService.removeOnMultiEntity(vappIds,
               unregisterSpec._type, unregisterSpec);
      }

      public deleteVapps(vappIds: string[]) {
         let deleteSpec: VAppRemovalSpec = VappRemovalService.buildRemovalSpec(true);
         this.mutationService.removeOnMultiEntity(vappIds, deleteSpec._type, deleteSpec);
      }

      private static buildRemovalSpec(deleteFromDisk: boolean): VAppRemovalSpec {
         let removalSpec: VAppRemovalSpec = new VAppRemovalSpec();
         removalSpec.deleteFromDisk = deleteFromDisk;
         return removalSpec;
      }


   }

   angular.module("com.vmware.vsphere.client.vapp")
         .service("vappRemovalService", VappRemovalService);
}
