namespace h5_client {
export module common_module_ui {
   import LibraryOperations = h5_client.common_module_ui.LibraryOperations;
   import ContentLibraryConstants = h5_client.common_module_ui.ContentLibraryConstants;
   import LibraryDataModel = h5_client.common_module_ui.LibraryDataModel;
      /*
       * Content library service using vAPI endpoint invocation.
       */
      export class LibraryVapiService {
         static $inject = ['mutationService', 'vapiSessionService', 'libraryApiBuilderService', 'defaultUriSchemeUtil', '$http', '$location'];

         private VALIDATE_LIBRARY_SPEC: string = 'com.vmware.vsphere.client.library.specs.SubscriptionUrlValidatorSpec';
         private DUMMY_LIBRARY: string = 'urn:vapi:com.vmware.content.Library:dummy';

         constructor(private mutationService: any,
                     private vapiSessionService: any,
                     private libraryApiBuilderService: any,
                     private defaultUriSchemeUtil: any,
                     private $http: any,
                     private $location: any) {}

         /**
          * Given the libraryId, delete a Library.
          * @param libraryId
          */
         deleteContentLibrary = (libraryId: any): void => {
            this.vapiSessionService.login().then(() => {
               let endpoint = this.vapiSessionService.getEndpoint();
               let libraryData: LibraryDataModel = new LibraryDataModel();
               libraryData.id = libraryId;
               let requestToFetch: RequestObject = this.libraryApiBuilderService
                     .reset()
                     .setBaseUrl(endpoint)
                     .setOperation(LibraryOperations.fetchLibrary)
                     .buildApiRequest(libraryData);

               this.$http(requestToFetch)
                  .then((response: any) => {
                     let requestToDelete: RequestObject = this.libraryApiBuilderService
                           .reset()
                           .setBaseUrl(endpoint)
                           .setOperation(LibraryOperations.deleteLibrary)
                           .buildApiRequest(response.data.value);
                     return this.$http(requestToDelete);
                  })
                  .then();
            });
         }

         /**
          * addContentLibrary using vAPI REST endpoints
          * @param libraryData
          */
         addContentLibrary(libraryData: any): void {
            this.vapiSessionService.login()
                  .then(() => {
                     let endpoint = this.vapiSessionService.getEndpoint();
                     let request: RequestObject = this.libraryApiBuilderService
                           .reset()
                           .setBaseUrl(endpoint)
                           .setOperation(LibraryOperations.addLibrary)
                           .setHeaders({"vmware-api-session-id": this.vapiSessionService.getVmwareApiSessionId()})
                           .buildApiRequest(libraryData);
                     return this.$http(request);
                  })
                  .then();
         }

         /**
          * Validate the SubscriptionUrl field for Subscribed Library
          * uses mutation service
          * @param libraryUrl
          * @returns {any}
          */
         validateSubscription(libraryUrl: any, sslThumbprint?: any, password?: string): any {
             const librarySpec = {
                 name: '',
                 subscriptionUrl: libraryUrl,
                 isAuthBasic: !!password,
                 sslThumbprint: sslThumbprint ? sslThumbprint : '',
                 username: ContentLibraryConstants.CREATE_LIBRARY_DEFAULT_USER,
                 password: password
             };
             return this.mutationService.validate(
                 this.DUMMY_LIBRARY,
                 this.VALIDATE_LIBRARY_SPEC,
                 librarySpec
             );
         }
      }
      angular.module('com.vmware.vsphere.client.commonModule').service('libraryVapiService', LibraryVapiService);
}
}
