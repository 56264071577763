/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential  */
namespace h5_ui {

   import IComponentController = ng.IComponentController;

   export class TpmConfigCsrController implements IComponentController {

      public masterDetailsViewContext: any;
      public detailsViewData:any;

      constructor() {

         this.masterDetailsViewContext = {};

         this.detailsViewData = {
            // No detail view for CSR since there is nothing more to show
            tabsPromise: []
         };
      }
   }

   angular.module("com.vmware.vsphere.client.vm")
         .component("vmTpmCertSigningRequests", {
            templateUrl: "vm-ui/resources/vm/views/configure/tpm/tpm-config-csr.component.html",
            controller: TpmConfigCsrController
         });
}
