/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
(function() {
   'use strict';
   angular.module('com.vmware.vsphere.client.host')
         .controller('SystemSwapSettingsController', SystemSwapSettingsController);

   SystemSwapSettingsController.$inject = ['$rootScope', 'dataService', 'i18nService',
         'systemSwapConfigurationConstants', '$q'];

   function SystemSwapSettingsController($rootScope, dataService, i18nService,
         constants, $q) {
      this.loading = true;
      var objectId = $rootScope._route.objectId;
      this.headerOptions = {
         title: i18nService.getString('HostUi', 'userWorldSwap.header'),
         objectId: objectId,
         actions: [{
            actionUid: 'vsphere.core.host.actions.editSystemSwap',
            customLabel: i18nService.getString('Common', 'settingsView.defaultEditActionLabel')
         }]
      };
      this.watchForObjects = [objectId];
      this.systemSwapConfigurationMessages = [];
      this.liveRefreshProperties = ['config.systemSwapConfiguration.option'];

      this.getViewData = function() {
         dataService.getProperties(objectId, ['config.systemSwapConfiguration'])
               .then(function(data) {
                  this.processResponseData(data);
                  this.loading = false;
               }.bind(this))
               .catch(function(error) {
                  this.loading = false;
                  $q.reject(error);
               }.bind(this));
      };

      this.processResponseData = function(data) {
         var systemSwapConfig = data['config.systemSwapConfiguration'];
         this.systemSwapConfigurationMessages = [];
         if (!systemSwapConfig) {
            return;
         }
         _.each(systemSwapConfig.option, function(option) {
            switch (option._type) {
               case constants.systemSwapConfigurationOptionType.HOST_CACHE_OPTION_TYPE:
                  this.systemSwapConfigurationMessages.push(
                        i18nService.getString('HostUi', 'userWorldSwap.hostCache')
                  );
                  break;
               case constants.systemSwapConfigurationOptionType.HOST_LOCAL_SWAP_OPTION_TYPE:
                  this.systemSwapConfigurationMessages.push(
                        i18nService.getString('HostUi', 'userWorldSwap.localDatastore')
                  );
                  break;
               case constants.systemSwapConfigurationOptionType.DATASTORE_OPTION_TYPE:
                  this.systemSwapConfigurationMessages.push(
                        i18nService.getString('HostUi', 'userWorldSwap.userSpecifiedDatastore', option.datastore)
                  );
                  break;
               case constants.systemSwapConfigurationOptionType.DISABLED_TYPE:
                  this.systemSwapConfigurationMessages.push(
                        i18nService.getString('HostUi', 'userWorldSwap.disabled', option.datastore)
                  );
                  break;
               default:
                  break;
            }
            this.systemSwapConfigurationMessages = _.uniq(this.systemSwapConfigurationMessages);
         }, this);
      };

      this.getViewData();
   }
})();
