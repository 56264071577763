namespace h5_vm {

   import IPromise = angular.IPromise;
   import VmProvisioningPageModel = h5_vm.VmProvisioningPageModel;
   import IInjectorService = angular.auto.IInjectorService;

   class DeploymentOptionValidationData {
      public newContext: any[];
      public newWorkflowPath?: string[];
   }

   class VmProvisioningDeploymentOptionsPageModelFactory {
      public static $inject = ["$injector"];

      constructor($injector: IInjectorService) {
         return (virtualMachineSpecBuilder: any, wizardViewData: any, wizardConfig: any) => {
            return $injector.instantiate(VmProvisioningDeploymentOptionsPageModel, {
               virtualMachineSpecBuilder: virtualMachineSpecBuilder,
               wizardViewData: wizardViewData,
               wizardConfig: wizardConfig
            });
         };
      }
   }

   export class VmProvisioningDeploymentOptionsPageModel implements VmProvisioningPageModel<any> {
      public static $inject = [
         "i18nService",
         "mutationService",
         "wizardPageService",
         "virtualMachineSpecBuilder",
         "wizardViewData",
         "wizardConfig"
      ];

      readonly DEPLOYMENT_OPTION_PARAMS_TYPE: string = "com.vmware.vcenter.ovf.DeploymentOptionParams";
      readonly PROPERTY_PARAMS_TYPE: string = "com.vmware.vcenter.ovf.PropertyParams";
      readonly NETWORK_MAPPING_PARAMS_TYPE: string = "com.vmware.vcenter.ovf.NetworkMappingParams";
      readonly DATASTORE_MAPPING_PARAMS_TYPE: string = "com.vmware.vcenter.ovf.DatastoreMappingParams";
      readonly DEPLOYMENT_OPTIONS_VALIDATION_SPEC: string =
            "com.vmware.vsphere.client.provisioning.spec.DeploymentOptionValidationSpec";

      private formObj: any = {
         deploymentOptionParams: undefined,
         selectedKey: undefined, // The user selection from the page
      };

      constructor(
            private i18nService: any,
            private mutationService: any,
            private wizardPageService: any,
            public virtualMachineSpecBuilder: any,
            public wizardViewData: any,
            private wizardConfig: any
      ) {
      }

      get form(): any {
         const deploymentOptionParams = this.retrieveContextParam(
               this.wizardViewData.getDeployFromLibraryCommonContext(),
               this.DEPLOYMENT_OPTION_PARAMS_TYPE);

         // NOTE: The deployment options param can change after the validatePage
         // call below and going forth and then back. We need to pick the latest
         // one in such cases.
         if (this.formObj.deploymentOptionParams === deploymentOptionParams) {
            return this.formObj;
         }

         this.formObj.deploymentOptionParams = deploymentOptionParams;
         this.formObj.selectedKey = deploymentOptionParams.selectedKey;
         return this.formObj;
      }

      public validatePage(): IPromise<{error: string}|boolean> {
         this.wizardPageService.clearErrors(this.wizardConfig);
         this.wizardPageService.showLoadingIndicator(this.wizardConfig);

         const spec = this.buildDeploymentOptionsValidationSpec(
               this.wizardViewData.getDeployFromLibraryCommonContext());

         return this.mutationService.validateSpec(spec._type, spec).then(
               (validationResult: {result?: DeploymentOptionValidationData, error?: Error}): any => {
            this.wizardPageService.hideLoadingIndicator(this.wizardConfig);
            return this.processValidatePageResponse(validationResult);
         });
      }

      private processValidatePageResponse(
            validationResult: {result?: DeploymentOptionValidationData, error?: Error}
      ): {error: string}|boolean {
         if (validationResult.error) {
            return {error: validationResult.error.message};
         }

         if (!validationResult.result) {
            return true;
         }

         const newPropertyParams = this.retrieveContextParam(
               validationResult.result.newContext, this.PROPERTY_PARAMS_TYPE);
         const newDeploymentOptionParams = this.retrieveContextParam(
               validationResult.result.newContext, this.DEPLOYMENT_OPTION_PARAMS_TYPE);

         let newPropertyParamsAdded = false;

         _.each(this.wizardViewData.getDeployFromLibraryCommonContext(),
               (item: any, index: number, contextArray: any[]) => {
                  if (_.isEqual(item._type, this.PROPERTY_PARAMS_TYPE) && newPropertyParams) {
                     contextArray[index] = newPropertyParams;
                     newPropertyParamsAdded = true;
                  }

                  if (_.isEqual(item._type, this.DEPLOYMENT_OPTION_PARAMS_TYPE)) {
                     contextArray[index] = newDeploymentOptionParams;
                  }
               });

         if (!newPropertyParamsAdded && newPropertyParams) {
            this.wizardViewData.getDeployFromLibraryCommonContext().push(newPropertyParams);
         }

         return true;
      }

      public submitPage(): any {
         return {
            wizardFlow: this.virtualMachineSpecBuilder.getCreationType(),
            invalidateNextPages: false
         };
      }

      private retrieveContextParam(commonContext: any[], paramsType: string): any {
         return _.find(commonContext, (item: any) => {
            return (_.isEqual(item._type, paramsType));
         });
      }

      public resetForm(): void {}

      private buildDeploymentOptionsValidationSpec(commonContext: any[]) {
         return {
            _type: this.DEPLOYMENT_OPTIONS_VALIDATION_SPEC,
            commonContext: this.trimAndCloneCommonContext(commonContext),
            selectedOptionKey: this.formObj.selectedKey
         };
      }

      /**
       * Cut-off bug chunks of storage and network data from here - these
       * are not used for this validation, but perf test runs contain
       * large number of these and wire transfer time increase a lot
       */
      private trimAndCloneCommonContext(commonContext: any[]): any[] {
         return _.map(commonContext, (contextItem) => {
            if (contextItem._type === this.NETWORK_MAPPING_PARAMS_TYPE) {
               const paramSpec = _.clone(contextItem);
               paramSpec.targetNetworks = [];
               return paramSpec;
            }
            if (contextItem._type === this.DATASTORE_MAPPING_PARAMS_TYPE) {
               const paramSpec = _.clone(contextItem);
               paramSpec.availableDatastores = [];
               return paramSpec;
            }
            return contextItem;
         });
      }
   }

   angular.module("com.vmware.vsphere.client.vm")
      .service("vmProvisioningDeploymentOptionsPageModel",
            VmProvisioningDeploymentOptionsPageModelFactory);
}
