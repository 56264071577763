namespace network_ui {

   import IPromise = angular.IPromise;

   export class ImageLabel {
      public label: string;
      public icon: string;
   }

   export class IscsiPortInfoItem {
      public portgroupName: ImageLabel;

      public vmKernelAdapter: ImageLabel;

      public policyComplianceStatus: ImageLabel;

      public pathStatus: ImageLabel;

      public physicalNetworkAdapterInfo: ImageLabel;

      public vnicBackingType: string;
      public vnicDevice: string;
      public vnicSpec: any;
      public pnicDevice: string;
      public isCompliant: boolean;
      public complianceErrors: any[];

      public switchName: string;
      public dvSwitchUuid: string;
   }

   export class IscsiPortInfoData {
      public hasPrivileges: boolean;

      public items: IscsiPortInfoItem[];
   }

   export class CandidateIscsiPortInfoData {
      public items: IscsiPortInfoItem[];
   }

   export interface RemoveIscsiPortBindingMessage {
      title: string;
      description: string;
   }

   export class IscsiPortBindingService {

      public static BOUND_ISCSI_PORT_INFO_DATA = "iscsiPortBinding:boundIscsiPortInfoData";
      public static CANDIDATE_ISCSI_PORT_INFO_DATA = "iscsiPortBinding:candidateIscsiPortInfoData";

      private static ACTIVE_STATUS = "active";
      private static STAND_BY_STATUS = "standBy";
      private static NOT_USED_STATUS = "notUsed";
      private static LAST_ACTIVE_STATUS = "lastActive";

      private log: any;

      public static $inject = ["dataService", "mutationService", "i18nService", "logService", "$q",
         "iscsiPortBindingConstants"];

      constructor(private dataService: any,
                  private mutationService: any,
                  private i18nService: any,
                  private logService: any,
                  private $q: any,
                  private iscsiPortBindingConstants: any) {

         this.log = logService("iscsiPortBindingService");
      }

      /**
       * Request BoundIscsiPortInfoData for a given storage adapter.
       *
       * @param hostId
       * @param iscsiHbaName
       *
       * @returns {IPromise<network_ui.IscsiPortInfoData>}
       */
      public getBoundIscsiPortInfoData(hostId: string, iscsiHbaName: string): IPromise<IscsiPortInfoData> {

         let self = this;

         return this.dataService.getProperties(hostId,
               [IscsiPortBindingService.BOUND_ISCSI_PORT_INFO_DATA],
               {
                  propertyParams: [{
                     propertyName: IscsiPortBindingService.BOUND_ISCSI_PORT_INFO_DATA,
                     parameterType: "java.lang.String",
                     parameter: iscsiHbaName
                  }]
               }).then(function (response: any | undefined) {
            let result: IscsiPortInfoData = new IscsiPortInfoData();
            if (response && response[IscsiPortBindingService.BOUND_ISCSI_PORT_INFO_DATA]) {

               result.hasPrivileges = response[IscsiPortBindingService.BOUND_ISCSI_PORT_INFO_DATA].hasPrivileges;
               if (response[IscsiPortBindingService.BOUND_ISCSI_PORT_INFO_DATA].portInfoItems) {
                  result.items = _.map(
                        response[IscsiPortBindingService.BOUND_ISCSI_PORT_INFO_DATA].portInfoItems, function (item: any) {
                           return self.createIscsiPortInfoItem(item);
                        });
               }
            }

            return result;
         });
      }

      /**
       * Request CandidateIscsiPortInfoData for a given storage adapter.
       *
       * @param hostId
       * @param iscsiHbaName
       *
       * @returns {IPromise<network_ui.CandidateIscsiPortInfoData>}
       */
      public getCandidateIscsiPortInfoData(hostId: string, iscsiHbaName: string): IPromise<CandidateIscsiPortInfoData> {
         let self = this;

         return this.dataService.getProperties(
               hostId,
               [IscsiPortBindingService.CANDIDATE_ISCSI_PORT_INFO_DATA],
               {
                  propertyParams: [{
                     propertyName: IscsiPortBindingService.CANDIDATE_ISCSI_PORT_INFO_DATA,
                     parameterType: "java.lang.String",
                     parameter: iscsiHbaName
                  }]
               }).then(function (response: any) {

            let result = new CandidateIscsiPortInfoData();
            if (response && response[IscsiPortBindingService.CANDIDATE_ISCSI_PORT_INFO_DATA]) {
               result.items = _.map(
                     response[IscsiPortBindingService.CANDIDATE_ISCSI_PORT_INFO_DATA].candidatePortInfoItems, function (item) {
                        return self.createIscsiPortInfoItem(item);
                     });
            }

            return result;
         });
      }

      /**
       * Initiates mutation call to associate the given post bindings to the iSCSI adapter of a host.
       *
       * @param hostId
       * @param iscsiHbaName
       * @param candidatePortInfos
       * @returns {any} promise with the mutation call result
       */
      public addCandidateIscsiPortInfo(hostId: string,
                                       iscsiHbaName: string,
                                       candidatePortInfos: IscsiPortInfoItem[]) {

         let type: string = "com.vmware.vsphere.client.network.host.iscsi.specs.IscsiPortBindingAddSpec";

         let spec: any = {
            _type: type,
            params: _.map(candidatePortInfos, function (candidatePortInfo: IscsiPortInfoItem) {
               return {
                  iscsiHbaName: iscsiHbaName,
                  vnicDevice: candidatePortInfo.vmKernelAdapter.label
               };
            })
         };

         return this.mutationService.apply(
               hostId,
               type,
               spec);
      }

      /**
       * Initiates mutation call to disassociate the given port bindings from the iSCSI adapter of a host.
       *
       * @param hostId
       * @param iscsiHbaName
       * @param portInfos
       * @returns {any} promise with the mutation call result
       */
      public removeIscsiPortInfos(hostId: string,
                                  iscsiHbaName: string,
                                  portInfos: IscsiPortInfoItem[]) {

         let type: string = "com.vmware.vsphere.client.network.host.iscsi.specs.IscsiPortBindingRemoveSpec";

         let spec: any = {
            _type: type,
            params: _.map(portInfos, function (portInfo: IscsiPortInfoItem) {
               return {
                  iscsiHbaName: iscsiHbaName,
                  vnicDevice: portInfo.vmKernelAdapter.label,
                  forceRemoval: true
               };
            })
         };

         return this.mutationService.apply(
               hostId,
               type,
               spec
         );
      }

      /**
       * Produces the messages for the port binding removal prompt, shown
       * when deleting port binding(s) from the list of bound port bindings
       * for an iSCSI adapter.
       *
       * @param selectedPortInfos
       * @param allPortInfos
       * @returns {RemoveIscsiPortBindingMessage}
       */
      public getPortBindingRemovalUserMessages(selectedPortInfos: IscsiPortInfoItem[],
                                               allPortInfos: IscsiPortInfoItem[]): RemoveIscsiPortBindingMessage {

         if (!selectedPortInfos || !selectedPortInfos.length) {
            this.log.error("No selected port infos provided");
         }

         if (!allPortInfos || !allPortInfos.length) {
            this.log.error("No port infos provided");
         }

         let removeMessage: RemoveIscsiPortBindingMessage = {
            title: this.i18nService.getString("NetworkUi", "portbinding.list.remove.confirmationTitle"),
            description: this.i18nService.getString("NetworkUi", "portbinding.list.remove.confirmationTextFormat",
                  selectedPortInfos[0].vmKernelAdapter.label)
         };

         if (selectedPortInfos.length === 1) {
            if (selectedPortInfos[0].pathStatus.label === IscsiPortBindingService.LAST_ACTIVE_STATUS) {
               removeMessage.title = this.i18nService.getString("NetworkUi", "portbinding.list.confirmRemoveLastActivePathTitle");
               removeMessage.description = this.i18nService.getString("NetworkUi", "portbinding.list.confirmRemoveLastActivePath",
                     selectedPortInfos[0].vmKernelAdapter.label);
            }
         } else {
            let remainingActive: IscsiPortInfoItem | undefined = _.find(allPortInfos, function (info) {
               return selectedPortInfos.indexOf(info) === -1
                     && (info.pathStatus.label === IscsiPortBindingService.LAST_ACTIVE_STATUS
                     || info.pathStatus.label === IscsiPortBindingService.ACTIVE_STATUS);
            });

            if (!remainingActive) {
               removeMessage.title = this.i18nService.getString("NetworkUi", "portbinding.list.confirmRemoveAllActivePathsTitle");
               removeMessage.description = this.i18nService.getString("NetworkUi", "portbinding.list.confirmRemoveAllActivePaths");
            } else {
               removeMessage.title = this.i18nService.getString("NetworkUi", "portbinding.list.remove.multiple.confirmationTitle");
               removeMessage.description = this.i18nService.getString("NetworkUi", "portbinding.list.remove.multiple.confirmationText");
            }
         }

         return removeMessage;
      }

      /**
       * Creates a new IscsiPortInfoItem instance from a given
       * com.vmware.vsphere.client.h5.network.host.iscsi.model.IscsiPortInfoItem.
       */
      public createIscsiPortInfoItem(item: any): IscsiPortInfoItem {
         let result = new IscsiPortInfoItem();

         result.portgroupName = this.formatPortGroupName(item.portGroupName, item.vnicBackingType);
         result.vmKernelAdapter = this.formatVmKernelAdapter(item.vmKernelAdapter);
         result.policyComplianceStatus = this.formatComplianceStatus(item.isCompliant);
         result.pathStatus = this.formatPathStatus(item.pathStatus);
         result.physicalNetworkAdapterInfo = this.formatPhysicalNetworkAdapterInfo(item.physicalNetworkAdapterInfo);

         result.pnicDevice = item.pnicDevice;
         result.vnicDevice = item.vmKernelAdapter;
         result.complianceErrors = item.complianceErrors;
         result.isCompliant = item.isCompliant;
         result.vnicSpec = item.vnicSpec;
         result.vnicBackingType = item.vnicBackingType;
         result.switchName = item.switchName;
         result.dvSwitchUuid = item.dvSwitchUuid;

         return result;
      }

      public formatPathStatus(pathStatus: string): ImageLabel {

         let result = new ImageLabel();

         switch (pathStatus) {
            case IscsiPortBindingService.ACTIVE_STATUS:
               result.label = this.i18nService.getString("NetworkUi", "portbinding.activeStatusHeader");
               result.icon = "network-lib-ui-icon-path-active";
               break;
            case IscsiPortBindingService.STAND_BY_STATUS:
               result.label = this.i18nService.getString("NetworkUi", "portbinding.standbyHeader");
               result.icon = "network-lib-ui-icon-path-standby";
               break;
            case IscsiPortBindingService.NOT_USED_STATUS:
               result.label = this.i18nService.getString("NetworkUi", "portbinding.notUsedHeader");
               result.icon = "network-lib-ui-icon-path-dead";
               break;
            case IscsiPortBindingService.LAST_ACTIVE_STATUS:
               result.label = this.i18nService.getString("NetworkUi", "portbinding.lastActiveHeader");
               result.icon = "network-lib-ui-icon-path-disabled";
               break;
            default:
               result.label = this.i18nService.getString("NetworkUi", "portbinding.defaultString");
               break;
         }

         return result;
      }

      public formatComplianceStatus(isCompliant: boolean): ImageLabel {
         let complianceStatus = new ImageLabel();

         if (isCompliant) {
            complianceStatus.label = this.i18nService.getString("NetworkUi", "portbinding.compliantLabel");
            complianceStatus.icon = "network-lib-ui-icon-statusGreen";
         } else {
            complianceStatus.label = this.i18nService.getString("NetworkUi", "portbinding.notCompliantLabel");
            complianceStatus.icon = "vsphere-icon-status-warning";
         }

         return complianceStatus;
      }

      public formatPortGroupName(portGroupName: string, vnicBackingType: string): ImageLabel {
         let result = new ImageLabel();
         if (portGroupName) {
            result.label = portGroupName;

            switch (vnicBackingType) {
               case this.iscsiPortBindingConstants.backingType.STANDARD_VNIC_BACKING_TYPE:
                  result.icon = "vsphere-icon-network";
                  break;
               case this.iscsiPortBindingConstants.backingType.OPAQUE_VNIC_BACKING_TYPE:
                  result.icon = "vsphere-icon-cloud-network";
                  break;
               case this.iscsiPortBindingConstants.backingType.DISTRIBUTED_VNIC_BACKING_TYPE:
                  result.icon = "vsphere-icon-virtual-port-group";
                  break;
            }

         } else {
            result.label = this.i18nService.getString("NetworkUi", "portbinding.defaultString");
         }

         return result;
      }

      public formatVmKernelAdapter(vmKernelAdapter: string): ImageLabel {
         let result = new ImageLabel();
         if (!vmKernelAdapter) {
            result.label = this.i18nService.getString("NetworkUi", "portbinding.defaultString");
         } else {
            result.label = vmKernelAdapter;
            result.icon = "network-lib-ui-icon-virtualAdapter";
         }

         return result;
      }

      public formatPhysicalNetworkAdapterInfo(physicalNetworkAdapterInfo: string): ImageLabel {
         let result = new ImageLabel();

         if (!physicalNetworkAdapterInfo) {
            result.label = this.i18nService.getString("NetworkUi", "portbinding.defaultString");
         } else {
            result.label = physicalNetworkAdapterInfo;
            result.icon = "network-lib-ui-icon-physicalAdapter";
         }

         return result;
      }
   }

   angular.module("com.vmware.vsphere.client.network")
         .service("iscsiPortBindingService", IscsiPortBindingService);
}
