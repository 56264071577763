namespace h5_vm {

   import IPromise = angular.IPromise;
   import IQService = angular.IQService;
   import ManagedObjectReference = com.vmware.vim.binding.vmodl.ManagedObjectReference;

   // The file name starts with underscore to make the file first in alphabetical order.
   // When the TypeScript files are compiled to JS and later concatenated we want this base
   // class to be defined first. Otherwise the child classes try to access their undefined
   // parent and the client doesn't start up.
   export class VmProvisioningFinishPageModel {
      private readonly COMPATIBILITY_MODE_VIRTUAL = "virtualMode";
      private readonly COMPATIBILITY_MODE_PHYSICAL = "physicalMode";
      private static readonly DEFAULT_PROFILE_CLASS =
            "com.vmware.vim.binding.vim.vm.DefaultProfileSpec";

      private _virtualMachineSpecBuilder: any;
      private _wizardViewData: any;
      private _scheduledTaskData: any;
      private _form: any = {
         vmStorageRecommendation: {}
      };
      protected noDrsFaults: boolean = false;
      protected finishedLoading: boolean = false;

      constructor(protected $q: IQService,
            protected i18nService: any,
            protected addVmService: any,
            protected vmDeviceInfoService: any,
            protected defaultUriSchemeUtil: any,
            protected storageProfileService: any,
            protected diskModeService: DiskModeService,
            protected managedEntityConstants: any,
            protected datastoreRecommendationService: any,
            protected spbmReplicationGroupInfoService: any,
            protected creationTypeConstants: any,
            virtualMachineSpecBuilder: any,
            wizardViewData: any,
            scheduledTaskData: any) {
         this._virtualMachineSpecBuilder = virtualMachineSpecBuilder;
         this._wizardViewData = wizardViewData;
         this._scheduledTaskData = scheduledTaskData;
      }

      get virtualMachineSpecBuilder(): any {
         return this._virtualMachineSpecBuilder;
      }

      get wizardViewData(): any {
         return this._wizardViewData;
      }

      get scheduledTaskData(): any {
         return this._scheduledTaskData;
      }

      get form(): any {
         return this._form;
      }

      set form(value: any) {
         this._form = value;
      }

      public resetForm(): void {
         this.form.vmStorageRecommendation = {};
      }

      public pageFinishedLoading(): boolean {
         return this.finishedLoading;
      }

      public validatePage(): Object {
         if (this.noDrsFaults) {
            return {};
         } else {
            return {
               error: this.i18nService
                     .getString("VmUi", "ProvisioningWizardSummaryPage.drsFaultsOnPage")
            };
         }
      }

      public setSelectedRecommendation(recommendation: any): void {
         let podId: string =
               this.defaultUriSchemeUtil.getVsphereObjectId(recommendation.target);
         this.form.vmStorageRecommendation[podId] = recommendation;
      }

      public getSelectedRecommendation(podId: string): any {
         if (this.form.vmStorageRecommendation
               && !_.isEmpty(this.form.vmStorageRecommendation[podId])) {
            return this.form.vmStorageRecommendation[podId];
         }

         return null;
      }

      public submitPage(): IPromise<boolean> {
         this.virtualMachineSpecBuilder
               .setVmRecommendationsSpec(this.form.vmStorageRecommendation);

         if (this.scheduledTaskData  && this.scheduledTaskData.isScheduledTask) {
            this.addVmService.createVmScheduledTask(this.virtualMachineSpecBuilder, this.scheduledTaskData);
         } else {
            this.addVmService.createVm(this.virtualMachineSpecBuilder);
         }
         return this.$q.resolve(true);
      }

      public getVmName(): string {
         return this.virtualMachineSpecBuilder.getName();
      }

      public getTargetFolderName(): string {
         return this.virtualMachineSpecBuilder.getTargetInformation().name;
      }

      public presentTargetStorageName(): string {
         return this.getDatastore();
      }

      public getComputeResourceInfo(): Object {
         let computeResourceId: string =
               this.virtualMachineSpecBuilder.getComputeResourceId();
         let computeResourceName: string =
               this.virtualMachineSpecBuilder.getComputeResourceName();
         let label: string = this.labelForType(computeResourceId);

         return {
            name: computeResourceName,
            label: label
         };
      }

      public isStorageProfileSpecified(): boolean {
         let storageProfile: any = this.virtualMachineSpecBuilder.getSelectedStorageProfile();
         return !!storageProfile && !!storageProfile.id && (storageProfile.id !== this.storageProfileService.DEFAULT_ID);
      }

      public isVmHomeEncrypted(): boolean {
         if (!this.isStorageProfileSpecified()) {
            return false;
         }

         let storageProfile: any = this.virtualMachineSpecBuilder.getSelectedStorageProfile();

         return this.storageProfileService
               .isEncryptionStorageProfile(storageProfile);
      }

      public areAllDisksEncrypted(): boolean {
         if (!this.isVmHomeEncrypted()) {
            return false;
         }

         if (!this.virtualMachineSpecBuilder.getVirtualMachineDevices()) {
            // The disks are using the VM home profile
            return true;
         }

         var disks: any[] = this.virtualMachineSpecBuilder.getVirtualMachineDevices()
               .changedDevicesOfType(DeviceTypeConstants.VIRTUALDISK);
         return !_.find(disks, (disk) => {
            return !disk.isDiskEncrypted();
         });
      }

      public getStorageProfile(): string {
         return this.virtualMachineSpecBuilder.getSelectedStorageProfile()
               && this.virtualMachineSpecBuilder.getSelectedStorageProfile().label;
      }

      public isReplicationGroupSpecified(): boolean {
         let replicationGroupInfo: any = this.virtualMachineSpecBuilder.getSelectedReplicationGroupInfo();
         return !!replicationGroupInfo;
      }

      public getReplicationGroup(): string {
         return this.virtualMachineSpecBuilder.getSelectedReplicationGroupInfo() &&
               this.spbmReplicationGroupInfoService.getReplicationGroupName(
                     this.virtualMachineSpecBuilder.getSelectedReplicationGroupInfo());
      }

      public fetchStoragePlacementRecommendations(): IPromise<any> {
         return this.addVmService.validateSpec(
               this.virtualMachineSpecBuilder
         ).then((results: any): any => {
            if (results.faults) {
               this.finishedLoading = true;
               this.noDrsFaults = false;
               return this.$q.reject(results.faults);
            }
            if (results.recommendationsPerPod) {
               this.form.recommendationsPerPod = results.recommendationsPerPod;
               angular.forEach(results.recommendationsPerPod,
                     (recommendationInfo: any): void => {
                        this.setSelectedRecommendation(recommendationInfo.topRecommendation);
                     });
               this.form.diskDatastoresMap = results.topRecommendedDatastoresPerDisk;
            }
            this.finishedLoading = true;
            this.noDrsFaults = true;
            return this.$q.resolve();
         });
      }


      public labelForType(urn: string): string {
         let type: string = this.defaultUriSchemeUtil.getPartsFromVsphereObjectId(urn).type;

         switch (type) {
            case "ClusterComputeResource":
               return this.i18nService.getString("VmUi", "ProvisioningWizardSummaryPage.Cluster");
            case "HostSystem":
               return this.i18nService.getString("VmUi", "ProvisioningWizardSummaryPage.Host");
            case "ResourcePool":
               return this.i18nService.getString("VmUi", "ProvisioningWizardSummaryPage.RP");
            case "VirtualApp":
               return this.i18nService.getString("VmUi", "ProvisioningWizardSummaryPage.RP");
            default:
               throw new Error("Unknown compute resouce id: " + urn);
         }
      }

      /**
       * @returns a sorted array (new first) of Nvdimms added to VM.
       */
      public getNvdimmDevicesInfo(): Array<any> {
         if (!this.virtualMachineSpecBuilder.getVirtualMachineDevices()) {
            return [];
         }
         let unsortedNvdimms: Array<any> = this.virtualMachineSpecBuilder
               .getVirtualMachineDevices().devicesOfTypeNotMarkedForRemoval("VirtualNVDIMM");
         let nvdimmInfos: Array <any> = _.chain(unsortedNvdimms)
               .sortBy((nvdimm: any): number => {
                  return Math.abs(nvdimm.getKey());
               })
               .map((nvdimm: any, index: number): any => {
                  return {
                     label: this.getNvdimmLabel(nvdimm, index),
                     capacityInMb: nvdimm.getCapacityInMB()
                  };
               })
               .value();
         return nvdimmInfos;
      }

      private getNvdimmLabel(nvdimm: any, index: number): string {
         if (nvdimm.isNew()) {
            return this.i18nService.getString(
                  "VmUi", "ProvisioningWizardSummaryPage.createNvdimm", index + 1);
         }
         return nvdimm.getCurrentDevice().deviceInfo.label;
      }

      protected getStorageObject(diskStorageProps: any): any {
         let storageObject: any = this.virtualMachineSpecBuilder.getStorageObject();
         if (diskStorageProps) {
            storageObject = diskStorageProps.dsObject;
         }
         return storageObject;
      }

      protected getDiskMode(disk: any): string {
         if (disk && disk.backing && disk.backing.diskMode) {
            return this.diskModeService.getModeName(disk.backing.diskMode);
         }
         return "";
      }

      public getStorageProfileForDisk(disk: any): any {
         let inflatedDevice: any = this.virtualMachineSpecBuilder
               .getVirtualMachineDevices()
               .getVirtualDevice(disk.key);
         // When using the Advanced Storage mode the advanced configuration is the most
         // accurate and regression safe way to obtain a disks storage profile
         if (!inflatedDevice.isNew()
               && this.virtualMachineSpecBuilder.getIsAdvancedStorageMode()) {
            return this.virtualMachineSpecBuilder
                  .getStorageSelectorStatePerDisk(disk.key).storageProfile;
         }
         return inflatedDevice.getProfile();
      }

      public getReplicationGroupLabelForDisk(disk: any): string|null {
         let replicationGroupInfo: any = this.getReplicationGroupForDisk(disk);
         if (replicationGroupInfo) {
            return this.spbmReplicationGroupInfoService.getReplicationGroupName(
                  replicationGroupInfo);
         }
         return null;
      }

      private getReplicationGroupForDisk(disk: any): any {
         let inflatedDevice: any = this.virtualMachineSpecBuilder
               .getVirtualMachineDevices()
               .getVirtualDevice(disk.key);
         // When using the Advanced Storage mode the advanced configuration is the most
         // accurate and regression safe way to obtain a disks RG
         if (!inflatedDevice.isNew()
               && this.virtualMachineSpecBuilder.getIsAdvancedStorageMode()) {
            return this.virtualMachineSpecBuilder
                  .getStorageSelectorStatePerDisk(disk.key).replicationGroup;
         }
         return inflatedDevice.getCurrentReplicationGroup();
      }

      public diskHasDefaultStoragePolicy(disk: any): boolean {
         let diskStorageProfile: any = this.getStorageProfileForDisk(disk);
         return !!diskStorageProfile
               && (diskStorageProfile.profileId === this.storageProfileService.DEFAULT_ID
               // Depending on the source the profile object may have profileId or id
               || diskStorageProfile.id === this.storageProfileService.DEFAULT_ID
               || diskStorageProfile._type ===
                     VmProvisioningFinishPageModel.DEFAULT_PROFILE_CLASS);
      }

      public setDisksDatastoreInfo(disks: any[]): void {
         _.each(disks, (disk) => {
            let diskStorageProps: any =
                  this.virtualMachineSpecBuilder.getStorageForDisk(disk.diskKey);
            disk.datastoreInfo = this.getDatastore(diskStorageProps, disk);
         });
      }

      protected getDatastore(diskStorageProps?: any, disk?: any): string {
         let storageObject: any = this.virtualMachineSpecBuilder.getStorageObject();
         if (diskStorageProps) {
            storageObject = diskStorageProps.dsObject;
         }
         let storageRef: any = storageObject.storageRef;
         if (storageRef.type !== this.managedEntityConstants.STORAGE_POD
               && storageObject.parentStoragePod
               && !this.isDeployVmFromVmtxTemplateMode()) {
            storageRef = storageObject.parentStoragePod;
         }
         let datastoreName: string = this.getDatastoreNameForPod(storageRef, disk);
         return this.getDatastoreLabel(storageObject, datastoreName);
      }

      protected getRDMTargetLUNName(disk: any, environment: any): string {
         let scsiDisks: any = environment.configTarget.scsiDisk;
         let scsiDisk: any = _.find(scsiDisks, (scsiDisk: any): boolean => {
            return scsiDisk.disk.deviceName === disk.backing.deviceName;
         });
         if (!scsiDisk) {
            return "";
         }
         return scsiDisk.disk.displayName;
      }

      protected getRDMCompatibility(disk: any): string {
         let compatibility: string = "";
         switch (disk.backing.compatibilityMode) {
            case this.COMPATIBILITY_MODE_VIRTUAL:
               compatibility = this.i18nService
                     .getString("VmUi",
                           "ProvisioningWizardSummaryPage.DiskCompatibilityModeVirtual");
               break;
            case this.COMPATIBILITY_MODE_PHYSICAL:
               compatibility = this.i18nService
                     .getString("VmUi",
                           "ProvisioningWizardSummaryPage.DiskCompatibilityModePhysical");
               break;
            default:
               compatibility = "";
         }

         return compatibility;
      }

      protected getRDMDatastoreName(): string {
         // TODO civanova: When the chane of location is correctly implemented
         // in edit settings dialog, display the new datastore name instead of the default value
         return  this.i18nService.getString("VmUi",
               "ProvisioningWizardSummaryPage.DiskMappedDatastoreSame");
      }

      protected isDiskEncrypted(diskKey: number): boolean {
         let diskDevice = this.virtualMachineSpecBuilder.getVirtualMachineDevices()
               .getVirtualDevice(diskKey);
         return diskDevice.isDiskEncrypted();
      }

      private getDatastoreLabel(datastoreObject: any, dataStoreName: string): string {
         let storageObjectIsStandaloneDS: boolean = !this.datastoreRecommendationService
                     .areRecommendationsAvailableForStorageObject(datastoreObject);
         const isCrossVc: boolean = this.virtualMachineSpecBuilder.isXvc();
         storageObjectIsStandaloneDS = this.isDeployVmFromVmtxTemplateMode() || isCrossVc
               || storageObjectIsStandaloneDS;

         return this.vmDeviceInfoService.getStorageObjectLabel(
               datastoreObject,
               dataStoreName,
               storageObjectIsStandaloneDS,
               this.wizardViewData.getStorageNameMap()
         );
      }

      private getDatastoreNameForPod(storagePodMoRef: ManagedObjectReference, disk: any): string {
         let datastoreName: string = "";
         let podId: string = this.defaultUriSchemeUtil.getVsphereObjectId(storagePodMoRef);
         let recommendation: any = this.getSelectedRecommendation(podId);
         if (recommendation) {
            let currentRecommendation: any = null;
            if (disk) {
               currentRecommendation = _.find(recommendation.action, (action: any) => {
                  return action.relocateSpec.disk && action.relocateSpec.disk.length
                     &&  action.relocateSpec.disk[0].diskId === disk.diskKey;
               });
            }
            currentRecommendation = currentRecommendation || recommendation.action[0];

            let datastoreMoRef: ManagedObjectReference = currentRecommendation.destination;
            let dsId: string = this.defaultUriSchemeUtil.getVsphereObjectId(datastoreMoRef);
            datastoreName = this.wizardViewData.getStorageNameMap()[dsId];
         }

         return datastoreName;
      }

      private isDeployVmFromVmtxTemplateMode(): boolean {
         return this.creationTypeConstants
               .isDeployVmFromVmtxTemplate(this._virtualMachineSpecBuilder.getCreationType());
      }
   }
}
