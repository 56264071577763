namespace storage_ui {

   import IScope = angular.IScope;
   import IncreaseDatastoreDetailsData = com.vmware.vsphere.client.h5.storage.model.IncreaseDatastoreDetailsData;
   interface HighchartsContext {
      series:any;
      point: any;
   }

   /**
    * layoutAreaChartService is a singleton object that
    *      1. transforms diskPartitionInfo layout into an array of rectangular Highcharts series objects.
    *      2. stores the pre-calculated sizes in MB for partitions
    *
    * Partitions with size too small to be displayed in real proportion
    * will use MIN_WIDTH which is calculated on fly each time new diskPartitionInfo is rendered
    *
    * This service could be implemented in java.
    *
    **/
   export class LayoutAreaChartService {

      public static get BLOCK_SIZE_DEFAULT(): number {
         return 512;
      };

      public static $inject = ["i18nService", "storageUtil", "layoutAreaChartConstants", "bytesFilter"];
      private totalX: number; // total number of megabytes rendered horizontally (including MIN_WIDTHs)
      private partitions: any[]; // partitionInfo.layout.partition  with calculated totalSizes

      constructor(private i18nService: any,
                  private storageUtil: any,
                  private constants: any,
                  private bytesFilter: any) {
      }

      /**
       *  Builds chart
       **/
      public buildChart(partitionInfo: any): any {
         let self = this;
         return _.extend(
               this.constants.HIGHCHARTS_CONFIG,
               {
                  "series": this.buildRectangles(partitionInfo),
                  "tooltip": {
                     "formatter": function (this: HighchartsContext) {
                        let context:HighchartsContext = this;
                        let size = self.bytesFilter(context.series.options.totalSizeMb, "MB", "Auto", 3);
                        return this.point.dataLabels ? (context.series["name"] + ": " + size) : false;
                     },
                  },
                  "partitions": this.partitions,
                  "totalX": this.totalX
               }
         );
      };

      /**
       * Calculates total size in MB based on layout.partition
       * */
      public static calcSize(partition:any): number {
         return (Number(partition.end.block) - Number(partition.start.block) + 1) *
               Number(partition.start.blockSize || LayoutAreaChartService.BLOCK_SIZE_DEFAULT) / Math.pow(1024, 2);
      }

      public static getExtentOriginalBlockRange(diskInfo: any, vmfsOption: any): any {
         let originalBlockRange: any = null;
         // Check if the vmfsOption will expand one of the datastore"s extents.
         let expandSpec: any = vmfsOption.spec;
         if (expandSpec._type !== "com.vmware.vim.binding.vim.host.VmfsDatastoreExpandSpec") {
            return null;
         }
         // Find extent"s BlockRange inside the option"s layout.
         let newBlockRange: any = _.find(vmfsOption.info.layout.partition, (blockRange: any)=> {
            return blockRange.partition === expandSpec.extent.partition;
         });
         if (newBlockRange) {
            // Then use the BlockRange from the option.layout to find the BlockRange in
            // the diskInfo.layout by matching their start block addresses.
            originalBlockRange = _.find(diskInfo.layout.partition,
                  (range: any)=> {
                     return range.start.block === newBlockRange.start.block;
                  });
         }
         return originalBlockRange;
      }

      /**
       * Builds series of rectangular area-charts stacked horizontally.
       **/
      private buildRectangles(partitionInfo:any): any[] {
         this.totalX = 0.0;

         this.partitions = _.map(partitionInfo.layout.partition, (partition: any) => {
            let result = _.clone(partition);
            result.totalSize = LayoutAreaChartService.calcSize(partition);
            result.visibleSize = result.totalSize;
            this.totalX += result.totalSize;
            return result;
         });

         // MIN_WIDTH for smaller partitions is 20% of an average block size
         let MIN_WIDTH = this.totalX / this.partitions.length * 0.20;

         for (let i = 0; i < this.partitions.length; i++) {
            if (this.partitions[i].totalSize < MIN_WIDTH) {
               this.partitions[i].visibleSize = MIN_WIDTH;
            }
         }

         let allSeries: any[] = [];
         let x = 0.0;

         for (let i = 0; i < this.partitions.length; i++) {
            allSeries[i] = this.buildRect(this.partitions[i], x, this.totalX);
            x += this.partitions[i].visibleSize;
         }
         this.totalX = x;
         return allSeries;
      }

      /**
       * Builds a rectangular representation of a partition
       **/
      private buildRect(partition:any, x:number, totalX:number): any {
         let self = this;
         let rectangleConfig: any = {
            name: this.i18nService.getString("StorageUi", "datastore.partitionType." + partition.type),
            partitionType: partition.type,
            data: [
               [x, 0], [x, this.constants.BAR_HEIGHT],
               {
                  x: x + partition.visibleSize / 2.0,
                  y: this.constants.BAR_HEIGHT,
                  width: partition.visibleSize,
                  dataLabels: {
                     style: this.constants.LABEL_CONFIG,
                     enabled: true,
                     y: this.constants.BAR_HEIGHT / 2,
                     // Hiding the labels that overflow their areas
                     // By assuming 1 character is @approxCharWidthPx wide and comparing it to area width
                     formatter: function (this:HighchartsContext) {
                        let approxCharWidthPx = 6;
                        let formattedSize = self.bytesFilter(partition.totalSize, "MB", "Auto", 1);
                        let label = `${this.series.name}: ${formattedSize}`;
                        let percentage = this.point.options.width / totalX;
                        let blockWidth = percentage * this.series.chart.plotWidth;
                        let labelApproxWidth = approxCharWidthPx * label.length;
                        return (labelApproxWidth < blockWidth) ? label : null;
                     }
                  }
               },
               [x + partition.visibleSize, this.constants.BAR_HEIGHT], [x + partition.visibleSize, 0]
            ],
            totalSizeMb: partition.totalSize
         };
         if (this.storageUtil.isFreePartition(partition.type)) {
            rectangleConfig.color = this.constants.FREE_PARTITION_COLOR;
         }
         // same datastore color
         if (partition["datastoreInfo"]) {
            rectangleConfig.color = this.constants.SAME_DATASTORE_PARTITION_COLOR;
            let datastoreDetailsData: IncreaseDatastoreDetailsData = partition.datastoreInfo;
            rectangleConfig.name = datastoreDetailsData.datastoreName + " (" + rectangleConfig.name + ")";
         }
         return rectangleConfig;
      }
   }
   angular.module("com.vmware.vsphere.client.storage")
         .service("layoutAreaChartService", LayoutAreaChartService);
}


