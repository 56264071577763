namespace h5_vm {

   import VirtualNVDIMMOption = com.vmware.vim.binding.vim.vm.device.VirtualNVDIMMOption;
   import VirtualNVDIMM$BackingInfo = com.vmware.vim.binding.vim.vm.device.VirtualNVDIMM$BackingInfo;
   import VirtualNVDIMMController = com.vmware.vim.binding.vim.vm.device.VirtualNVDIMMController;
   import VirtualDeviceSpec$FileOperation = com.vmware.vim.binding.vim.vm.device.VirtualDeviceSpec$FileOperation;
   import VirtualNVDIMM = com.vmware.vim.binding.vim.vm.device.VirtualNVDIMM;

   class NvdimmProvisioningService {

      public static $inject: string[] = [
         "deviceService",
         "i18nService",
         "VirtualNvdimmWrapper",
         "virtualDeviceInflator",
         "pmemUtilService",
         "storageProfileService"];

      constructor(private deviceService: any,
            private i18nService: any,
            private VirtualNvdimmWrapper: any,
            private virtualDeviceInflator: any,
            private pmemUtilService: PmemUtilService,
            private storageProfileService: any) {
      }

      makeDefaultNvdimm(vmConfigEnvironment: any,
            guestOsDesc: any, otherInflatedDevices: any, additionalParameters: any) {
         this.validateCanAddNvdimm(vmConfigEnvironment,
               guestOsDesc, additionalParameters, otherInflatedDevices);

         let device: VirtualNVDIMM = new VirtualNVDIMM();
         device.backing = this.getVirtualNvdimmBacking();
         device.capacityInMB = guestOsDesc.recommendedPersistentMemoryMB;
         device.key = this.deviceService.newKey();
         let controller: any = this.getNVDIMMController(
               otherInflatedDevices,
               vmConfigEnvironment,
               additionalParameters);
         if (controller !== null) {
            device.controllerKey = controller.getKey();
            if (controller.isMarkedForRemoval()) {
               controller.revertRemoval();
            }
         }
         let deviceWrapper: any = this.deviceService.setDeviceInfo(
               new this.VirtualNvdimmWrapper(device));
         deviceWrapper.setFileOperation(this.getDefaultFileOperation());
         deviceWrapper.setProfile([
            this.getPmemStoragePolicy(additionalParameters.storageProfiles)]);
         return {
            device: deviceWrapper,
            controller: controller
         };
      }

      public canAddNvdimm(vmConfigEnvironment: any,
                          guestOsDesc: any,
                          otherInflatedDevices: any,
                          additionalParameters: any): boolean {
         try {
            this.validateCanAddNvdimm(
               vmConfigEnvironment, guestOsDesc, additionalParameters, otherInflatedDevices);
         } catch (e) {
            return false;
         }

         return true;
      }


      private validateCanAddNvdimm(vmConfigEnvironment: any,
            guestOsDesc: any,
            additionalParameters: any,
            otherInflatedDevices: any) {
         let option: any = this.pmemUtilService.findOption(
               vmConfigEnvironment, PmemUtilService.NVDIMM);

         // TODO proynovd: Catch these exceptions and show user-friendly message why NVDIMM
         // cannot be added.
         if (!option) {
            throw new Error(this.text("DeviceNotSupported"));
         }

         if (!guestOsDesc.persistentMemorySupported) {
            throw new Error(this.text("DeviceNotSupportedByGuest"));
         }

         if (additionalParameters.vmWorkflow === VmWorkflowMode.UpdateMode
               && additionalParameters.vm.powerState === "suspended") {
            throw new Error(this.text("NoVmSuspendedAdd"));
         }

         if (additionalParameters.vmWorkflow === VmWorkflowMode.UpdateMode
              && additionalParameters.vm.powerState !== "poweredOff") {
            if (!option.plugAndPlay) {
               throw new Error(this.text("HotAddNotSupported"));
            }
            if (!guestOsDesc.persistentMemoryHotAddSupported) {
               throw new Error(this.text("PMEMColdGuest"));
            }
         }

         if (!this.isPmemAvailable(
               vmConfigEnvironment, additionalParameters.storageProfiles)) {
            throw new Error(this.text("PMEMNewDenied"));
         }

         let max: number = this.getControllerLimit(vmConfigEnvironment);
         let currentNvdimmCount: number = this.getCurrentNvdimmCount(otherInflatedDevices);

         if (!isNaN(max) && ((currentNvdimmCount + 1) > max)) {
            throw new Error(this.text("DeviceLimitReached"));
         }
      }

      private getVirtualNvdimmBacking(): VirtualNVDIMM$BackingInfo {
         let result: VirtualNVDIMM$BackingInfo = new VirtualNVDIMM$BackingInfo();
         result.fileName = "";
         return result;
      }

      private getDefaultFileOperation(): VirtualDeviceSpec$FileOperation {
         let result: VirtualDeviceSpec$FileOperation = "create";
         return result;
      }

      private getPmemStoragePolicy(storageProfilesPromise: any): any {
         if (!storageProfilesPromise) {
            return undefined;
         }
         let pmemStorageProfile: any;
         // XXX
         // TODO PLEASE REVIEW
         // XXX
         // The storageProfiles are encapsulated in a promise, but it's always resolved
         // if when the user adds a new NVDIMM device, thus for the sake of simplicity
         // the code is written in an synchronous way.
         if (storageProfilesPromise.$$state !== 1
               && !storageProfilesPromise.$$state.value) {
            return undefined;
         }
         pmemStorageProfile = this.pmemUtilService.getPmemStorageProfile(
               storageProfilesPromise.$$state.value);
         if (!pmemStorageProfile) {
            return undefined;
         }
         return this.storageProfileService.makeProfile(pmemStorageProfile.id);
      }

      private getControllerLimit(vmConfigEnvironment: any): number {
         let controllerOption: any = this.pmemUtilService.findOption(
               vmConfigEnvironment, PmemUtilService.NVDIMM_CONTROLLER);
         if (!controllerOption
               && !controllerOption.devices
               && !controllerOption.devices.max) {
            return NaN;
         }
         return controllerOption.devices.max;
      }

      private getCurrentNvdimmCount(otherInflatedDevices: any): number {
         let nvdimms: Array<any> =
               otherInflatedDevices.devicesOfType(PmemUtilService.NVDIMM);
         return nvdimms.length;
      }

      /** Find or create and return controller  */
      private getNVDIMMController(otherInflatedDevices: any,
            vmConfigEnvironment: any,
            additionalParameters: any): any {
         let nvdimmController: any =
               this.pmemUtilService.getNvdimmController(otherInflatedDevices);
         if (nvdimmController) {
            return nvdimmController;
         }
         // Nothing found, create new one
         return this.addNVDIMMController(
               vmConfigEnvironment.configOption.hardwareOptions.virtualDeviceOption,
               additionalParameters.vmWorkflow);
      }

      private addNVDIMMController(deviceOptions: any,
            vmWorkflow: VmWorkflowMode): VirtualNVDIMMController {
         let device: VirtualNVDIMMController = new VirtualNVDIMMController();
         device.key = this.deviceService.newKey();
         let deviceOptionsByDeviceType: any =
               _.indexBy(deviceOptions, function (deviceOption: any) {
                  return deviceOption.type.name;
               });

         let inflatedController: any = this.virtualDeviceInflator.inflate(device, {
            deviceOptionsByDeviceType: deviceOptionsByDeviceType
         });

         inflatedController = this.deviceService.setDeviceInfo(inflatedController);
         return inflatedController;
      }

      /**
       * To be sure that we have PMEM capability for the VM there are 2 things:
       * - the host must support it
       * - the datastore must have the PMEM storage policy
       *
       * In real life one could not exist without the other but when we are making
       * the checks for PMEM availability there are cases where the storage policies
       * are not loaded
       */
      private isPmemAvailable(vmConfigEnvironment: any, storageProfiles: any): boolean {
         let hasPmemStoragePolicy: boolean = !_.isEmpty(
               this.getPmemStoragePolicy(storageProfiles));
         let hasPmemCapacity: boolean = vmConfigEnvironment && vmConfigEnvironment.configTarget
            && !isNaN(vmConfigEnvironment.configTarget.availablePersistentMemoryReservationMB)
            && vmConfigEnvironment.configTarget.availablePersistentMemoryReservationMB > 0;

         return hasPmemStoragePolicy || hasPmemCapacity;
      }

      private text(key: string): string {
         return this.i18nService.getString("VmUi", "VmDeviceManager." + key);
      }
   }

   angular.module("com.vmware.vsphere.client.vm")
         .service("nvdimmProvisioningService", NvdimmProvisioningService);
}
