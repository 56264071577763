module cluster_ui {

   export class HostSecurityOverviewController {

      public i18n: Function;

      public listViewType: String;

      public relationId: String;

      public objectId: String;

      public static $inject = ['i18nService', 'navigation'];

      constructor(private i18nService: any, private navigation: any) {
         this.i18n = i18nService.getString.bind(this, "HostUi");
      }

      public $onInit() {
         this.listViewType = 'HostSystem';

         this.objectId = this.navigation.getRoute().objectId;
      }

   }

   angular.module('com.vmware.vsphere.client.commonModule')
      .component('hostSecurityOverview', {
         templateUrl: 'common-module-ui/resources/common-module/views/security/host-security-overview.html',
         controller: HostSecurityOverviewController,
         bindings: {
            relationId: "@"
         }
      });
}