/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace dvs_ui {

   import DvsSpanSessionPortBrowserPortData =
         com.vmware.vsphere.client.h5.network.dvs.portmirroring.model.portbrowser.DvsSpanSessionPortBrowserPortData;
   import DvsSpanSessionPortListSpec =
         com.vmware.vsphere.client.h5.network.dvs.portmirroring.model.portlist.DvsSpanSessionPortListSpec;
   import DvsSpanSessionPortListData =
         com.vmware.vsphere.client.h5.network.dvs.portmirroring.model.portlist.DvsSpanSessionPortListData;
   import DvsSpanSessionPortListItemData =
         com.vmware.vsphere.client.h5.network.dvs.portmirroring.model.portlist.DvsSpanSessionPortListItemData;

   export class DvsSpanSessionPortsListComponent {

      public bindings: any;
      public controller: any;
      public templateUrl: string;

      constructor() {
         this.bindings = {
            dvsUrn: "<",
            model: "<",
            trafficDirectionEnabled: "<",
            editMode: "<"
         };

         this.controller = DvsSpanSessionPortsListComponentController;

         this.templateUrl = "dvs-ui/resources/dvs/portmirroring/common/components/" +
               "dvsSpanSessionPortsListComponentTemplate.html";
      }
   }

   class DvsSpanSessionPortsListComponentController {

      static $inject = [
            "i18nService",
            "vuiConstants",
            "dataService",
            "dvsSpanSessionPortBrowserDialogService",
            "dvsSpanSessionPortsListService"
      ];

      // action IDs
      static ADD_ACTION: string = "add-ports";
      static REMOVE_ACTION: string = "remove-ports";
      static INGRESS_ACTION: string = "set-ingress";
      static EGRESS_ACTION: string = "set-egress";
      static BOTH_ACTION: string = "set-both";

      public dvsUrn: string;

      public model: DvsSpanSessionPortsListModel;

      public trafficDirectionEnabled: boolean;

      public editMode: boolean;

      public datagridOptions: any;

      public preselectComparator: (item: DvsSpanSessionPortListItemData) => boolean;

      public loading: boolean;

      public getString: (key: string, bundle?: string) => string;

      private _selectedPortsIds: string[];

      private _ignoreNextItemSelection: boolean;

      constructor(private i18nService: any,
                  private vuiConstants: any,
                  private dataService: any,
                  private portBrowserDialogService: DvsSpanSessionPortBrowserDialogService,
                  private portsListService: DvsSpanSessionPortsListService) {
         this.getString = (key: string, bundle: string = "DvsUi"): string => {
            return i18nService.getString(bundle, key);
         };

         this.preselectComparator = (item: DvsSpanSessionPortListItemData): boolean => {
            return this._selectedPortsIds
                  && this._selectedPortsIds.indexOf(item.portId) > -1;
         };

         this.loading = false;
      }

      public $onInit(): void {
         this.datagridOptions =
               this.portsListService.createDatagridOptions(
                     this.getActionDefinitions(), this.onSelectionChange.bind(this),
                     this.trafficDirectionEnabled);

         if (this.editMode) {
            this.loading = true;
            let portIds: string[] =
                  this.model.ports.map((portData: PortData): string => {
                     return portData.id;
                  });
            this.portsListService.getData(this.dvsUrn, portIds)
                  .then((data: DvsSpanSessionPortListData): void => {
                     this.datagridOptions.data =
                           this.model.ports.map((data: PortData): DvsSpanSessionPortListItemData => {
                              let listItemData: DvsSpanSessionPortListItemData =
                                    new DvsSpanSessionPortListItemData();
                              listItemData.portId = data.id;
                              listItemData.trafficDirection = data.trafficDirection;
                              return listItemData;
                           });
                     this.onDataRetrieved(data);
                  });
         } else if (this.model.cachedData) {
            setTimeout((): void => { this.datagridOptions.data = this.model.cachedData; }, 0);
         }
      }

      private getActionDefinitions(): any[] {
         let actionDefinitions: any[] = [];

         actionDefinitions.push({
               id: DvsSpanSessionPortsListComponentController.ADD_ACTION,
               enabled: true,
               iconClass: "network-lib-ui-icon-portAdd",
               tooltipText: this.i18nService.getString("DvsUi", "sessionDvPortSourceList.addPortByPortSelector"),
               onClick: (): void => {
                  let portsToIgnore: string[] = [];
                  if (this.model.ports) {
                     portsToIgnore = _.map(this.model.ports, (port: PortData): string => {
                              return port.id;
                           });
                  }

                  this.portBrowserDialogService.show(
                        this.dvsUrn,
                        portsToIgnore,
                        (portIds: string[]): void => {

                           if (!portIds || portIds.length === 0) {
                              return;
                           }

                           this.loading = true;

                           this.portsListService.getData(this.dvsUrn, portIds)
                                 .then((data: DvsSpanSessionPortListData): void => {
                                    this.onDataRetrieved(data);
                                 });
                        });
               }
         });

         actionDefinitions.push({
            id: DvsSpanSessionPortsListComponentController.REMOVE_ACTION,
            enabled: true,
            iconClass: "vx-icon-removeIcon",
            tooltipText: this.i18nService.getString("DvsUi", "sessionDvPortSourceList.remove"),
            onClick: (): void => {
               let selectedPortIds: string[] =
                     _.map(this.datagridOptions.selectedItems,
                           (item: DvsSpanSessionPortListItemData): string => {
                              return item.portId;
                           });

               this.model.ports = _.filter(this.model.ports,
                     (port: PortData): boolean => {
                        return selectedPortIds.indexOf(port.id) < 0;
                     });

               this.datagridOptions.data =
                     _.filter(this.datagridOptions.data,
                           (item: DvsSpanSessionPortListItemData): boolean => {
                              return selectedPortIds.indexOf(item.portId) < 0;
                           });

               this.model.cachedData = this.datagridOptions.data;
            }
         });

         if (this.trafficDirectionEnabled) {
            actionDefinitions.push(this.vuiConstants.actions.SEPARATOR);

            actionDefinitions.push({
               id: DvsSpanSessionPortsListComponentController.INGRESS_ACTION,
               enabled: true,
               iconClass: "network-lib-ui-icon-ingressTraffic",
               tooltipText: this.i18nService.getString("DvsUi", "sessionDvPortSourceList.ingress"),
               onClick: (): void => {
                  this.onTrafficDirectionChange(
                        DvsSpanSessionConstants.TRAFFIC_DIRECTION.INGRESS);
               }
            });

            actionDefinitions.push({
               id: DvsSpanSessionPortsListComponentController.EGRESS_ACTION,
               enabled: true,
               iconClass: "network-lib-ui-icon-egressTraffic",
               tooltipText: this.i18nService.getString("DvsUi", "sessionDvPortSourceList.egress"),
               onClick: (): void => {
                  this.onTrafficDirectionChange(
                        DvsSpanSessionConstants.TRAFFIC_DIRECTION.EGRESS);
               }
            });

            actionDefinitions.push({
               id: DvsSpanSessionPortsListComponentController.BOTH_ACTION,
               enabled: true,
               iconClass: "network-lib-ui-icon-ingressEgressTraffic",
               tooltipText: this.i18nService.getString("DvsUi", "sessionDvPortSourceList.both"),
               onClick: (): void => {
                  this.onTrafficDirectionChange(
                        DvsSpanSessionConstants.TRAFFIC_DIRECTION.BOTH);
               }
            });
         }
         return actionDefinitions;
      }

      private onDataRetrieved(data: DvsSpanSessionPortListData): void {
         let existingKeys: string[] = [];
         let newData: DvsSpanSessionPortListItemData[] = [];

         this.datagridOptions.data.forEach((item: DvsSpanSessionPortListItemData): void => {
            newData.push(item);
            existingKeys.push(item.portId);
         });

         data.items.forEach((item: DvsSpanSessionPortListItemData): void => {
            if (existingKeys.indexOf(item.portId) < 0) {
               item.trafficDirection =
                     DvsSpanSessionConstants.TRAFFIC_DIRECTION.BOTH;
               newData.push(item);

               this.model.ports.push(new PortData(item.portId, item.trafficDirection));
            } else {
               newData.forEach((existingItem: DvsSpanSessionPortListItemData): void => {
                  if (existingItem.portId === item.portId) {
                     existingItem.host = item.host;
                     existingItem.connectee = item.connectee;
                     existingItem.connecteeType = item.connecteeType;
                  }
               });
            }
         });

         this.datagridOptions.data = newData;
         this.model.cachedData = newData;

         this.loading = false;
      }

      private onSelectionChange(items: DvsSpanSessionPortListItemData[]): void {
         if (this._ignoreNextItemSelection) {
            this._ignoreNextItemSelection = false;
            return;
         }

         let hasSelectedItems: boolean = items && items.length > 0;

         if (hasSelectedItems) {
            this._selectedPortsIds = _.map(items,
                  (item: DvsSpanSessionPortListItemData): string => {
                     return item.portId;
                  });
         } else {
            this._selectedPortsIds = [];
         }

         for (let actionDef of this.datagridOptions.actionBarOptions.actions) {
            if (actionDef.id === DvsSpanSessionPortsListComponentController.REMOVE_ACTION
                  || actionDef.id === DvsSpanSessionPortsListComponentController.BOTH_ACTION
                  || actionDef.id === DvsSpanSessionPortsListComponentController.EGRESS_ACTION
                  || actionDef.id === DvsSpanSessionPortsListComponentController.INGRESS_ACTION) {
               actionDef.enabled = hasSelectedItems;
            }
         }
      }

      private onTrafficDirectionChange(direction: string): void {
         this._ignoreNextItemSelection = true;

         let selectedPortIds: string[] =
               _.map(this.datagridOptions.selectedItems,
                     (item: DvsSpanSessionPortListItemData): string => {
                        return item.portId;
                     });

         this.model.ports.forEach((port: PortData): void => {
            if (selectedPortIds.indexOf(port.id) > -1) {
               port.trafficDirection = direction;
            }
         });

         this.datagridOptions.data.forEach((item: DvsSpanSessionPortListItemData): void => {
            if (selectedPortIds.indexOf(item.portId) > -1) {
               item.trafficDirection = direction;
            }
         });

         // reset grid data
         this.datagridOptions.data = this.datagridOptions.data.slice();

         this.model.cachedData = this.datagridOptions.data;
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .component("dvsSpanSessionPortsList",
               new DvsSpanSessionPortsListComponent());
}
