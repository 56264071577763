namespace storage_ui {

   import VmfsVolume$UnmapBandwidthSpec = com.vmware.vim.binding.vim.host.VmfsVolume$UnmapBandwidthSpec;

   export class DatastoreSpaceReclamationBandwidthControl {
      public bindings: any;
      public controller: any;
      public templateUrl: any;

      constructor() {
         this.bindings = {
            showDynamicUnmapOption: "<",
            vmfsConfigOption: "<",
            unmapBandwidthSpec: "<"
         };
         this.controller = DatastoreSpaceReclamationBandwidthControlController;
         this.templateUrl =
               "storage-ui/resources/storage/views/datastore/spacereclamation/" +
               "DatastoreSpaceReclamationBandwidthControl.html";
      }
   }

   class DatastoreSpaceReclamationBandwidthControlController {
      public static $inject = ["i18nService", "datastoreSpaceReclamationBandwidthService"];

      public showDynamicUnmapOption: boolean;
      public vmfsConfigOption: any;
      public unmapBandwidthSpec: VmfsVolume$UnmapBandwidthSpec;

      public fixedRateItems: RateItem[];
      public selectedFixedUnmapRateItem: RateItem|null = null;

      public dynamicMinRateItems: RateItem[];
      public dynamicMaxRateItems: RateItem[];

      public selectedDynamicMinRateItem: RateItem|null = null;
      public selectedDynamicMaxRateItem: RateItem|null = null;

      public selectedUnmapOption: string;

      public FIXED_UNMAP_OPTION: string = "fixed";
      public DYNAMIC_UNMAP_OPTION: string = "dynamic";
      public DISABLED_UNMAP_OPTION: string = "disabled";

      public i18n: (key: string) => string;

      constructor(private i18nService: any,
                  private reclamationBandwidthService: DatastoreSpaceReclamationBandwidthService) {
         this.i18n = this.i18nService.getString;
      }

      public updateSpec() {
         this.unmapBandwidthSpec.policy = this.selectedUnmapOption;
         if (this.selectedFixedUnmapRateItem) {
            this.unmapBandwidthSpec.fixedValue = this.selectedFixedUnmapRateItem.rate;
         }
         if (this.showDynamicUnmapOption) {
            // don't update dynamic options if they
            // are not supported.
            if (this.selectedDynamicMinRateItem) {
               this.unmapBandwidthSpec.dynamicMin = this.selectedDynamicMinRateItem.rate;
            }
            if (this.selectedDynamicMaxRateItem) {
               this.unmapBandwidthSpec.dynamicMax = this.selectedDynamicMaxRateItem.rate;
            }
         }
      }

      public $onChanges() {
         this.initData();
      }

      private initData() {
         this.fixedRateItems = this.reclamationBandwidthService.generateRateItems(
               this.vmfsConfigOption.unmapBandwidthFixedValue,
               this.vmfsConfigOption.unmapBandwidthIncrement);

         this.selectedFixedUnmapRateItem = this.reclamationBandwidthService.findRateItem(
               this.fixedRateItems, this.unmapBandwidthSpec.fixedValue, true);

         if (this.showDynamicUnmapOption) {
            this.dynamicMinRateItems = this.reclamationBandwidthService.generateRateItems(
                  this.vmfsConfigOption.unmapBandwidthDynamicMin,
                  this.vmfsConfigOption.unmapBandwidthIncrement);
            this.selectedDynamicMinRateItem = this.reclamationBandwidthService.findRateItem(
                  this.dynamicMinRateItems, this.unmapBandwidthSpec.dynamicMin, true);
            this.dynamicMaxRateItems = this.reclamationBandwidthService.generateRateItems(
                  this.vmfsConfigOption.unmapBandwidthDynamicMax,
                  this.vmfsConfigOption.unmapBandwidthIncrement);
            this.selectedDynamicMaxRateItem = this.reclamationBandwidthService.findRateItem(
                  this.dynamicMaxRateItems, this.unmapBandwidthSpec.dynamicMax, true);
         }

         if (this.unmapBandwidthSpec.policy === this.FIXED_UNMAP_OPTION) {
            this.selectedUnmapOption = this.FIXED_UNMAP_OPTION;
         } else if (this.unmapBandwidthSpec.policy === this.DYNAMIC_UNMAP_OPTION &&
               this.showDynamicUnmapOption) {
            this.selectedUnmapOption = this.DYNAMIC_UNMAP_OPTION;
         } else {
            this.selectedUnmapOption = this.DISABLED_UNMAP_OPTION;
         }
      }

   }

   angular.module("com.vmware.vsphere.client.storage")
         .component("datastoreSpaceReclamationBandwidthControl",
               new DatastoreSpaceReclamationBandwidthControl());
}
