/* Copyright 2018 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace dvs_ui {

   import MoveNetworkSpec = com.vmware.vsphere.client.h5.network.MoveNetworkSpec;
   export class MoveDvsToService {

      public static $inject = [
         "clarityModalService",
         "i18nService",
         "mutationService",
         "defaultUriSchemeUtil"
      ];

      constructor(private clarityModalService: any,
                  private i18nService: any,
                  private mutationService: any,
                  private defaultUriSchemeUtil: any) {

      }

      public openMoveToDialog(availableTargets: string[]) {
         let modalOptions: any = {
            title: this.i18nService.getString("DvsUi",
                  "moveto.dialog.title"),
            subTitle: {
               objectId: availableTargets[0]
            },
            defaultButton: 'submit',
            dialogData: {
               objectIds: availableTargets
            },
            contentTemplate: "dvs-ui/resources/dvs/moveTo/moveDvsToDialog.html"
         };

         modalOptions.onSubmit = () => {
            this.moveDvsTo(modalOptions.dialogData.objectIds,
                  modalOptions.dialogData.entityToMoveDvsTo);
            return true;
         };

         this.clarityModalService.openOkCancelModal(modalOptions);
      }

      public moveDvsTo(dvsIds: string[], entityToMoveDvsTo: any) {
         let dvsMors: any[] = dvsIds.map((dvsId: string) => {
            return this.defaultUriSchemeUtil.getManagedObjectReference(dvsId);
         });
         let moveSpec: MoveNetworkSpec = new MoveNetworkSpec();
         moveSpec.entities = dvsMors;

         this.mutationService.apply(
               entityToMoveDvsTo,
               moveSpec._type,
               moveSpec).then(() => {
         });
         return true;
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .service("moveDvsToService", MoveDvsToService);
}




