/**
 * @name com.vmware.vsphere.client.cluster:clusterGroupsConstants
 * @module com.vmware.vsphere.client.cluster
 *
 * @description
 *    The `clusterGroupsConstants` service holds cluster groups related constants.
 */
angular.module('com.vmware.vsphere.client.cluster').constant('clusterGroupsConstants', {
   groupType : {
      VM_GROUP : 'com.vmware.vim.binding.vim.cluster.VmGroup',
      HOST_GROUP : 'com.vmware.vim.binding.vim.cluster.HostGroup'
   },

   operationType: {
      ADD: 'add',
      REMOVE: 'remove',
      EDIT: 'edit'
   }
});
