/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
(function () {
   'use strict';

   angular
         .module('com.vmware.vsphere.client.network')
         .service('standardSwitchPropertiesViewService', standardSwitchPropertiesViewService);

   standardSwitchPropertiesViewService.$inject = [
      'dataService',
      'vxPropertyViewService',
      'i18nService'
   ];

   function standardSwitchPropertiesViewService(
         dataService, propertyViewService, i18nService) {

      return {
         getData: getData,
         getDataByName: getDataByName,
         addPropertiesToCategory: addPropertiesToCategory
      };

      function getData(hostId, selectedSwitchKey) {
         var switchIdSpec = {
            _type: 'com.vmware.vsphere.client.h5.network.host.virtualswitch.spec.VirtualSwitchIdSpec',
            key: selectedSwitchKey
         };
         return getDataInternal(hostId, switchIdSpec, true);
      }

      function getDataByName(hostId, switchName) {
         var switchIdSpec = {
            _type: 'com.vmware.vsphere.client.h5.network.host.virtualswitch.spec.VirtualSwitchIdSpec',
            name: switchName
         };
         return getDataInternal(hostId, switchIdSpec, false);
      }

      function getDataInternal(hostId, switchIdSpec, buildPropertyViewData) {
         var property = "virtualswitch:propertiesViewData";

         var params = {
            propertyParams: [{
               propertyName: property,
               parameterType: switchIdSpec._type,
               parameter: switchIdSpec
            }]
         };

         var propertiesPromise = dataService.getProperties(
            hostId,
            [property],
            params
         );

         return propertiesPromise.then(function (result) {
            var switchData = result && result[property];
            if (buildPropertyViewData) {
               return build(switchData);
            } else {
               return switchData;
            }
         });
      }

      function build(switchData) {
         var builder = propertyViewService.createPropertyViewBuilder();

         var generalCategory = builder.category("general");
         addPropertiesToCategory(switchData, generalCategory);

         return builder.build();
      }

      function addPropertiesToCategory(switchData, category) {
         var propertiesSection = category.section("generalSection");

         propertiesSection.title(i18nService.getString(
               "H5NetworkUi", "standardSwitchDetailsView.properties.section"));

         if (switchData) {

            // Name
            if (switchData.name) {
               propertiesSection.property(
                  i18nService.getString('NetworkUi', 'VSwitchPropertiesView.standardSwitch'),
                  switchData.name);
            }

            // Number of ports
            if (switchData.numberOfPorts) {
               propertiesSection.property(
                  i18nService.getString('NetworkUi', 'VSwitchPropertiesView.numPorts'),
                  switchData.numberOfPorts);

               if (switchData.showRestartWarning) {
                  propertiesSection.message(
                     i18nService.getString('NetworkUi', 'VSwitchPropertiesView.numPortDesc'),
                     'vx-icon-restartWarning');
               }
            }

            // MTU
            if (switchData.mtu) {
               propertiesSection.property(
                  i18nService.getString('NetworkUi', 'VSwitchPropertiesView.mtu'),
                  switchData.mtu);
            }
         }
      }
   }
}());
