/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace network_ui {
   import NetStackConfigItem = com.vmware.vsphere.client.h5.network.host.netstack.model.NetStackConfigItem;
   import NetStackReconfigureSpec = com.vmware.vsphere.client.h5.network.host.netstack.model.NetStackReconfigureSpec;

   export class NetStackEditNamePageModel {
      public isSystemNetStack: boolean;
      public name: string;

      public constructor(data: NetStackConfigItem) {
         this.isSystemNetStack = data.isSystemNetStack;
         this.name = data.name;
      }

      public buildSpec(originalData: NetStackEditNamePageModel): string | null {
         if (originalData && originalData.name === this.name) {
            return null;
         }

         return this.name;
      }
   }
}
