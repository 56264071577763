/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace storage_ui {

   import IPromise = angular.IPromise;
   import IQService = angular.IQService;

   export class MultipathingPoliciesService {
      public static $inject = [
         "vxPropertyViewService", "dataService", "hostStorageConstants",
         "storageUtil", "actionsService", "$q", "i18nService"];

      private i18n: any;

      constructor(private vxPropertyViewService: any,
                  private dataService: any,
                  private hostStorageConstants: any,
                  private storageUtil: any,
                  private actionsService: any,
                  private $q: IQService,
                  private i18nService: any) {

         this.i18n = i18nService.getString.bind(this, "StorageUi");
      }

      public requestPolicies(hostId: string,
                             deviceId: string,
                             requestHostPolicies: boolean,
                             onEditInvocation: Function): IPromise<PoliciesDataItem> {
         let policiesPromises: { [key:string] : IPromise<any>} = {};

         policiesPromises[this.hostStorageConstants.STORAGE_DEVICE_MULTIPATHING_INFO_PROP] = this.dataService.getProperties(hostId, [
                  this.hostStorageConstants.STORAGE_DEVICE_MULTIPATHING_INFO_PROP
               ],
               {
                  propertyParams: [{
                     propertyName: this.hostStorageConstants.STORAGE_DEVICE_MULTIPATHING_INFO_PROP,
                     parameterType: "java.lang.String",
                     parameter: deviceId
                  }]
               })
               .then((response: any) => {
                  response = response || {};

                  let multipathingInfo =
                        response[this.hostStorageConstants.STORAGE_DEVICE_MULTIPATHING_INFO_PROP] || {};

                  return {
                     pathSelectionPolicy: multipathingInfo.pathSelectionPolicy,
                     storageArrayTypePolicy: multipathingInfo.storageArrayTypePolicy
                  };

               });

         policiesPromises["editAction"] = this.actionsService.getActions(
               [hostId],
               ["vsphere.core.host.actions.storageDevice.editMultipathingPolicy"])
               .then(function(actionDefs: any) {
                  if (actionDefs && actionDefs.length && actionDefs[0].action) {
                     return {
                        title: actionDefs[0].action.label,
                        isEnabled: actionDefs[0].available,
                        clickHandler: function () {
                           return onEditInvocation(actionDefs[0]);
                        }
                     };
                  }
                  return null;
               });

         if (requestHostPolicies) {
            policiesPromises["hostPolicyOptions"] = this.dataService.getProperties(hostId, [
               this.hostStorageConstants.PATH_SELECTION_POLICY_OPTIONS_PROP,
               this.hostStorageConstants.STORAGE_ARRAY_TYPE_POLICY_OPTIONS_PROP]);
         }

         return this.$q.all(policiesPromises).then( (response:any) => {
            let result: PoliciesDataItem = <PoliciesDataItem> {};

            if (response[this.hostStorageConstants.STORAGE_DEVICE_MULTIPATHING_INFO_PROP]) {
               response[this.hostStorageConstants.STORAGE_DEVICE_MULTIPATHING_INFO_PROP].editAction = response.editAction;
               result.multipathingInfo = response[this.hostStorageConstants.STORAGE_DEVICE_MULTIPATHING_INFO_PROP];
            }

            if (response.hostPolicyOptions) {
               result.hostPolicyOptions = response.hostPolicyOptions;
            }

            return result;
         });
      }

      public buildMulthipathingSection(categoryBuilder: any,
                                       title: string,
                                       multhipathingProperties: any,
                                       hostPolicies: any,
                                       editAction: any) {
         // We have to populate the multipathing properties only for
         // connected hosts.
         if (multhipathingProperties && hostPolicies) {
            categoryBuilder.section("multipathingPolicies")
                  .title(title)
                  .property(
                        this.i18n("datastore.manage.connectivity.pathSelectionPolicy"),
                        this.storageUtil.formatPathSelectionPolicyName(
                              multhipathingProperties.pathSelectionPolicy,
                              hostPolicies.pathSelectionPolicyOptions)
                  )
                  .property(
                        this.i18n("datastore.manage.connectivity.satp"),
                        this.storageUtil.getStorageArrayTypePolicyName(
                              multhipathingProperties.storageArrayTypePolicy,
                              hostPolicies.storageArrayTypePolicyOptions)
                  ).action(editAction);

         }
      }
   }

   angular.module("com.vmware.vsphere.client.storage")
         .service("multipathingPoliciesService", MultipathingPoliciesService);
}
