namespace h5_client {
    export module common_module_ui {

        import IComponentController = angular.IComponentController;

        export class SessionViewController implements IComponentController {

            public static $inject = [
                "$scope",
                "i18nService",
                "vuiConstants",
                "dataService",
                "timeFormatterService",
                "mutationService",
                "userSessionService",
                "clarityModalService",
                "defaultUriSchemeUtil"
            ];

            private readonly PROP_USER_SESSION: string = "vcUserSessions";
            private readonly PROP_USER_CURRENT_SESSION: string = "currentSessionId";
            private readonly SESSION_TERMINATE_SPEC: string = "com.vmware.vsphere.client.folder.VcSessionTerminationSpec";

            /*
             * Options for the VUI data-grid
             */
            private dataGridOptions: any;

            /*
             * Number of active sessions
             */
            private activeSessions: number;

            /*
             * Number of idle sessions
             */
            private idleSessions: number;

            /*
             * Terminate Action Options
             */
            private terminateAction: any;

            /*
             * Object ID
             */
            private objectId: any;
            private watchForObjects: any[];

            public constructor(
                    private $scope: any,
                    private i18nService: any,
                    private vuiConstants: any,
                    private dataService: any,
                    private timeFormatterService: any,
                    private mutationService: any,
                    private userSessionService: any,
                    private clarityModalService: any,
                    private defaultUriSchemeUtil: any) {

                this.objectId = this.$scope._route.objectId ||
                        this.$scope._vxViewNodeData.node.contentSpec.metadata.objectId;
                this.watchForObjects = [ this.objectId ];
                this.terminateAction = this.getTerminateAction();
                this.dataGridOptions = this.getDataGridOptions();
                this.getUserSessionData();

                this.$scope.$watch('sessionViewCtrl.dataGridOptions.selectedItems', (selectedItems: any) => {
                    let canTerminate: boolean = !!selectedItems && selectedItems.length > 0 && !selectedItems[0].currentSession;
                    this.terminateAction.enabled = canTerminate;
                });
            }

            private openDeleteModal() {
                let modalOptions: any = {
                    title: this.i18nService.getString('FolderUi', 'vcSessions.terminateSessionCaption'),
                    message: this.i18nService.getString('FolderUi', 'vcSessions.terminateSessionWarning'),
                    icon: 'vsphere-icon-status-warning',
                    submit: this.terminateSession.bind(this)
                };

                this.clarityModalService.openConfirmationModal(modalOptions);
            }

            /**
             * Terminate the user session (if possible)
             */
            private terminateSession(): angular.IPromise<any> {
                let terminateSpec: any = {
                    userSessionId: [ this.dataGridOptions.selectedItems[0].key ]
                };

                return this.userSessionService.getAllServersInfo().then((response: any) => {
                    let rootFolder: any = this.defaultUriSchemeUtil
                            .createVmomiUri('Folder', 'group-d1', response.serversInfo[0].serviceGuid);
                    this.mutationService.apply(rootFolder, this.SESSION_TERMINATE_SPEC, terminateSpec);
                });
            }

            /**
             * Get the sessions' data
             */
            private getUserSessionData(): angular.IPromise<any> {
                this.activeSessions = 0;
                this.idleSessions = 0;

                return this.dataService.getProperties(this.objectId, [ this.PROP_USER_SESSION, this.PROP_USER_CURRENT_SESSION ])
                            .then((sessions: any) => {
                                this.dataGridOptions.data = sessions.vcUserSessions;
                                this.timeFormatterService.getUserTimeFormatPreference().then((savedDateTimeFormat: any) => {
                                    let currentTime: number = new Date().getTime();
                                    this.parseUserSessionData(savedDateTimeFormat, currentTime, sessions.currentSessionId);
                                });
                            });
            }

            /**
             * Parse the data to include the following data:
             * - onlineSince
             * - Status
             *
             * @param savedDateTimeFormat The saved user date/time preference
             */
            private parseUserSessionData(savedDateTimeFormat: any, currentTime: number, currentSessionId: string): any {
                let timeOptions: any = {
                    day: "numeric",
                    month: "numeric",
                    year: "2-digit",
                    hour: "numeric",
                    minute: "numeric"
                };

                for (let data of this.dataGridOptions.data) {
                    data.onlineSince =
                        this.timeFormatterService.timestampToText(data.loginTime, savedDateTimeFormat, timeOptions);
                    data.idleTime = Math.floor((currentTime - data.lastActiveTime) / (1000 * 60));
                    data.currentSession = currentSessionId === data.key;
                    data.status = this.getStatus(data);
                }
            }

            /**
             * Get the status string for the provided session
             * Update the active/idle session count
             *
             * @param session A session with idleTime and currentSession specified
             * @returns {string}
             */
            private getStatus(session: any): string {
                // Is Current session
                if (session.currentSession) {
                    this.activeSessions++;
                    return this.i18nService.getString("FolderUi", "vcSessions.sessionStatusThisSession");
                }

                // Is Active session
                if (this.isActiveSession(session)) {
                    this.activeSessions++;
                    return this.i18nService.getString("FolderUi", "vcSessions.sessionStatusActive");
                }

                // Idle session
                this.idleSessions++;
                let days: number = Math.floor(session.idleTime / 1440);
                let hours: number = Math.floor((session.idleTime % 1440) / 60);
                let minutes: number = session.idleTime % 60;

                if (days > 0) {
                    return this.i18nService.getString("FolderUi", "vcSessions.sessionStatusIdleDays", days, hours, minutes);
                } else if (hours > 0) {
                    return this.i18nService.getString("FolderUi", "vcSessions.sessionStatusIdleHours", hours, minutes);
                } else {
                    return this.i18nService.getString("FolderUi", "vcSessions.sessionStatusIdleMinutes", minutes);
                }
            }

            /**
             * Check if the provided session is active
             *
             * @param session
             * @returns {boolean}
             */
            private isActiveSession(session: any): boolean {
                return session.currentSession || session.idleTime <= 10;
            }

            /**
             * Get the sessions' data-grid options
             */
            private getDataGridOptions(): any {
                return {
                    actionBarOptions: {
                        actions: [
                            this.terminateAction
                        ]
                    },
                    columnDefs: [{
                        displayName: this.i18nService.getString("FolderUi", "vcSessions.userSessions.userName"),
                        field: 'userName',
                        width: '20%'
                    }, {
                        displayName: this.i18nService.getString("FolderUi", "vcSessions.userSessions.fullName"),
                        field: 'fullName',
                        width: '15%'
                    }, {
                        displayName: this.i18nService.getString("FolderUi", "vcSessions.userSessions.onlineSince"),
                        field: 'onlineSince',
                        width: '13%'
                    }, {
                        displayName: this.i18nService.getString("FolderUi", "vcSessions.userSessions.status"),
                        field: 'status',
                        width: '15%'
                    }, {
                        displayName: this.i18nService.getString("FolderUi", "vcSessions.userSessions.ipAddress"),
                        field: 'ipAddress',
                        width: '11%'
                    }, {
                       displayName: this.i18nService.getString("FolderUi", "vcSessions.userSessions.userAgent"),
                       field: 'userAgent',
                       width: '15%'
                    }, {
                       displayName: this.i18nService.getString("FolderUi", "vcSessions.userSessions.callCount"),
                       field: 'callCount',
                       type: 'number',
                       width: '11%'
                    }],
                    data: [],
                    sortMode: this.vuiConstants.grid.sortMode.SINGLE,
                    selectionMode: this.vuiConstants.grid.selectionMode.SINGLE,
                    selectedItems: [],
                    height: '100%',
                    resizable: true
                };
            }

            /**
             * Get the terminate action button options
             */
            private getTerminateAction(): any {
                return {
                    id: 'delete-session-action',
                    iconClass: 'vsphere-icon-toolbar-remove',
                    tooltipText: this.i18nService.getString('FolderUi', 'vcSessions.terminateSessionCaption'),
                    enabled: false,
                    onClick: this.openDeleteModal.bind(this)
                };
            }

            private getActiveSessionString(): string {
                return this.i18nService.getString("FolderUi", "vcSessions.activeSessions", this.activeSessions);
            }

            private getIdleSessionString(): string {
                return this.i18nService.getString("FolderUi", "vcSessions.idleSessions", this.idleSessions);
            }
        }

        angular.module("com.vmware.vsphere.client.commonModule")
            .controller("SessionViewController", SessionViewController);
    }
}
