module hostprofile_ui {

    import ReadHostCustomizationsSpec = com.vmware.vsphere.client.hostprofile.data.h5.ReadHostCustomizationsSpec;

    class RemediateHostPageController implements angular.IController {

        static $inject = [
            "$scope",
            "remediateHostPageService",
            "dataService",
            "i18nService"
        ];

        private hidePrecheckButton: boolean;
        private loading: boolean;
        private treeListOptions: any;
        private remediationSpec: any;
        private rebootHosts: boolean;
        private manager: RemediateHostManager;
        private wizardConfig: any;
        private signPostConfig: any;

        constructor(private $scope: ScopeRoute,
                    private remediateHostPageService: RemediateHostPageService,
                    private dataService: any,
                    private i18nService: any) {

            this.hidePrecheckButton = false;
            this.remediationSpec = {};
            this.loading = false;
            this.rebootHosts = true;
            this.manager = this.$scope.manager;
            this.manager.setRebootHosts(this.rebootHosts);
            this.wizardConfig = this.$scope.wizardConfig;
            this.wizardConfig.loading = false;

            this.signPostConfig = {
                message: this.buildSignPostMessage(),
                title: this.i18nService.getString(
                    "HostProfileUi", "remediateHostWizard.readyToCompletePage.infoDialogTitle"),
            };

            $scope.$watch((): boolean | undefined => {
                if (this.rebootHosts) {
                    return this.rebootHosts;
                }
                return undefined;
            }, (newState, oldState) => {
                if (oldState !== newState) {
                    this.manager.setRebootHosts(this.rebootHosts);
                }
            });
        }


        private precheckRemediation(): void {
            this.hidePrecheckButton = true;
            let hostId: string = this.$scope.manager.getTargetUid();
            this.wizardConfig.loading = true;
            this.dataService
                .getProperties(hostId, ["remediatePreCheckedData"])
                .then((response: any) => {
                    let tree: any = response.remediatePreCheckedData.remediateTree;
                    this.treeListOptions = this.remediateHostPageService.getTreeListOptions(tree);
                    this.remediationSpec = response.remediatePreCheckedData.remediationSpec;
                    this.manager.setRemediationSpec(this.remediationSpec);
                    this.wizardConfig.loading = false;
                });
        }

        private buildSignPostMessage(): string {
            let rebootRequiredInfo: string = this.i18nService.getString(
                "HostProfileUi",
                "remediateHostWizard.readyToCompletePage.infoDialogMessageRequireReboot"
            );
            let infoDialogMessage: string =
                this.i18nService.getString(
                    "HostProfileUi",
                    "remediateHostWizard.readyToCompletePage.infoDialogMessageCheckBox"
                );
            return rebootRequiredInfo + "<br>" + infoDialogMessage;
        }
    }

    angular.module("com.vmware.vsphere.client.hostprofile")
        .controller("RemediateHostPageController", RemediateHostPageController);
}
