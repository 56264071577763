/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace dvs_ui {

   import DvsPortTrunkVlanSpec =
         com.vmware.vsphere.client.h5.network.dvs.ports.model.DvsPortTrunkVlanSpec;
   import DvsPortVlanPolicyModel =
         com.vmware.vsphere.client.h5.network.dvs.ports.model.DvsPortVlanPolicyModel;
   import IPromise = angular.IPromise;

   export class DvsPortEditVlanPageValidator {

      public static $inject = [
         "$q",
         "i18nService",
         "networkUiConstants",
         "dvpgPropertiesService",
         "dataService"];

      constructor(private $q: any,
                  private i18nService: any,
                  private networkUiConstants: any,
                  private dvpgPropertiesService: any,
                  private dataService: any) {
      }

      public getValidationError(dvsPortId: string,
            vlanPageModel: DvsPortVlanPolicyModel): IPromise<string[]> {
         switch (vlanPageModel.vlanType) {
            case this.networkUiConstants.Vlan.Type.VLAN: {
               let vlanIdError: string = this.dvpgPropertiesService.getVlanIdError(
                     vlanPageModel.vlanId);
               if (vlanIdError) {
                  return this.$q.resolve([vlanIdError]);
               }
               break;
            }
            case this.networkUiConstants.Vlan.Type.TRUNK: {
               if (!vlanPageModel.vlanTrunkRanges) {
                  let vlanRangesError: string = this.i18nService.getString(
                        "DvsUi", "dvpg.policy.vlan.trunk.errorMessage");
                  return this.$q.resolve([vlanRangesError]);
               }

               let vlanTrunkSpec: DvsPortTrunkVlanSpec = new DvsPortTrunkVlanSpec();
               vlanTrunkSpec.numericRanges = vlanPageModel.vlanTrunkRanges;

               let portPromise: any = this.dataService.getProperties(
                     dvsPortId, [DvsPortEditDialogConstants.DATA_DVS_VLAN_TRUNK_PROPERTY], {
                        propertyParams: [{
                           propertyName: DvsPortEditDialogConstants.DATA_DVS_VLAN_TRUNK_PROPERTY,
                           parameterType: vlanTrunkSpec._type,
                           parameter: vlanTrunkSpec
                        }]
                     });

               return portPromise.then((trunkRanges: any) => {
                        if(!trunkRanges
                              || !trunkRanges[DvsPortEditDialogConstants.DATA_DVS_VLAN_TRUNK_PROPERTY]) {
                           let vlanRangesError: string = this.i18nService.getString(
                                 "DvsUi", "dvpg.policy.vlan.trunk.errorMessage");
                           return [vlanRangesError];
                        }

                        vlanPageModel.vlanTrunkNumericRanges =
                              trunkRanges[DvsPortEditDialogConstants.DATA_DVS_VLAN_TRUNK_PROPERTY];
                        return this.$q.resolve([]);
                     }, () => {
                        return null;
                     });
            }
            case this.networkUiConstants.Vlan.Type.PRIVATE: {
               if (!vlanPageModel.pvlanConfig ||
                     vlanPageModel.pvlanConfig.length === 0) {
                  let pvlanError: string = this.i18nService.getString(
                        "DvsUi", "dvpg.policy.vlan.private.noConfig");
                  return this.$q.resolve([pvlanError]);
               }
               break;
            }
         }

         return this.$q.resolve([]);
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .service("dvsPortEditVlanPageValidator", DvsPortEditVlanPageValidator);
}