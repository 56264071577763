/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
(function () {
   'use strict';
   angular.module('com.vmware.vsphere.client.host').controller('AddHostVmLocationPageController', [
      '$scope',
      'defaultUriSchemeUtil',
      function ($scope, defaultUriSchemeUtil) {
         this.model = $scope.manager.getVmLocationPageModel();

         this.onVmLocationSelected = function (locationId, locationName) {
            var locationRef = defaultUriSchemeUtil.getManagedObjectReference(locationId);
            $scope.manager.setVmLocation({ref: locationRef, displayName: locationName});
         };
      }
   ]);
})();
