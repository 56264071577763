angular.module('com.vmware.vsphere.client.vm').service('virtualControllerInflator', [
   'VirtualController',
   function(VirtualController) {
      return {
         build: function(rawDevice, deviceOptionsByDeviceType) {
            var deviceOption = deviceOptionsByDeviceType[rawDevice._type];

            return new VirtualController(rawDevice, {
               maxDisks: parseMaxDisks(deviceOption),
               maxCdroms: parseMaxCdroms(deviceOption)
            });
         }
      };

      function parseMaxDisks(deviceOption) {
         var matcher = new RegExp("^num\\w+Disks$");

         var numDiskKey = _.find(_.keys(deviceOption), function (key) {
            return key.match(matcher);
         });

         return numDiskKey ? deviceOption[numDiskKey].max : 0;
      }

      function parseMaxCdroms(deviceOption) {
         var matcher = new RegExp("^num\\w+Cdroms$");

         var numCdromsKey = _.find(_.keys(deviceOption), function (key) {
            return key.match(matcher);
         });

         return numCdromsKey ? deviceOption[numCdromsKey].max : 0;
      }
   }
]);
