/* Copyright 2015 VMware, Inc. All rights reserved. -- VMware Confidential */

angular.module('com.vmware.vsphere.client.commonModule')
.service('managedEntityMutationService',
['mutationService', function(mutationService) {
   return {
      // operation parameter is optional, to be used in case of error notification
      rename: function(objectId, nameInfo, operation) {
         return mutationService.apply(objectId,
               'com.vmware.vsphere.client.mixed.ManagedEntityNameInfo', nameInfo, operation);
      }
   };
}]);