/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace dvs_ui {

   export class DvpgMonitoringPageComponent {

      public bindings: any;
      public controller: any;
      public templateUrl: string;

      constructor() {
         this.bindings = {
            model: "<",
            overrideOptions: "<?",
            overrideEnabled: "<?",
            overrideDisallowed: "<?"
         };

         this.controller = DvpgMonitoringPageComponentController;

         this.templateUrl = "dvs-ui/resources/dvs/dvpg/common/components/" +
               "dvpgMonitoringPageTemplate.html";
      }
   }

   class DvpgMonitoringPageComponentController {

      static $inject = ["i18nService"];

      public model: DvpgMonitoringPolicyModel;
      public overrideOptions: any;
      public overrideEnabled: any;
      public overrideDisallowed: boolean;
      private options: any[];

      constructor(private i18nService: any) {
         this.options = [{
            value: true,
            label: this.i18nService.getString("DvsUi",
                  "dvpg.manage.policy.monitoring.status.on")
         },{
            value: false,
            label: this.i18nService.getString("DvsUi",
                  "dvpg.manage.policy.monitoring.status.off")
         }];
      }
   }

   angular.module("com.vmware.vsphere.client.dvs").component(
         "dvpgMonitoringPage", new DvpgMonitoringPageComponent());
}
