/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
/**
 * The motivation for this service is a bug in the application
 * https://bugzilla.eng.vmware.com/show_bug.cgi?id=1864459
 * which causes skipping the digest loop trigger when promises
 * are resolved after MutationService calls, so we have to call it manually
 *
 * TODO - vasila This service should be removed when the bug is fixed
 */
angular.module('com.vmware.vsphere.client.vm')
      .service('zoneDigestPatchService', ['$rootScope', function($rootScope){
         this.digest = function () {
            if (!$rootScope.$$phase) {
               $rootScope.$apply();
            }
         };
      }]);