namespace h5_vm {

   import GosBasePageModel = h5_vm.GosBasePageModel;
   import GosType = h5_vm.GosType;

   export class GosNameAndOsPageModel extends GosBasePageModel {

      private type: GosType;

      get gosType(): GosType {
         return this.type;
      }

      set gosType(value: GosType) {
         this.type = value;
         if (this.gosTypeChanged) {
            this.gosTypeChanged(this.type);
         }
      }

      allSpecs: CustomizationSpecInfoCustom[];

      gosTypeChanged: Function;

      originalSpecName?: string;

      specName: string;

      description: string;

      vc?: VcInfo;

      isEditMode: boolean = false;

      isSidGenerated: boolean;
   }
}
