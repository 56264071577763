namespace h5_vm {

   export interface DiskModeOption {
      name: string;
      value: string;
   }

   export interface DiskMode {
      options: DiskModeOption[];
      selection: DiskModeOption;
   }

   class VmHardwareVirtualDiskModeController implements ng.IComponentController {

      static $inject: string[] = ['vmHardwareVirtualDiskModeService', 'i18nService'];

      private diskModeInfo: DiskMode;
      private isDisabled: boolean;
      private onChange: Function;

      private i18n: any;
      private diskModeSignPostParams: any;

      constructor(private vmHardwareVirtualDiskModeService: any, private i18nService: any) {
         this.i18n = this.i18nService.getString;

         this.vmHardwareVirtualDiskModeService.getDiskModeSignPostParams()
               .then((value: any) => {
                  this.diskModeSignPostParams = value;
               });
      }

      onDiskModeChange() {
         this.onChange({diskMode: this.diskModeInfo.selection.value});
      }

   }

   export class VmHardwareVirtualDiskMode implements ng.IComponentOptions {
      bindings: any;
      controller: any;
      templateUrl: string;

      constructor() {
         this.bindings = {
            diskModeInfo: '<',
            isDisabled: '<',
            ariaLabel: '<',
            onChange: '&'
         };
         this.controller = VmHardwareVirtualDiskModeController;
         this.templateUrl = 'vm-ui/resources/vm/views/settings/vmHardwareSettings/vmHardwareVirtualDiskShared/' +
               'vmHardwareVirtualDiskMode/vm-hardware-virtual-disk-mode.html';

      }
   }

   angular.module('com.vmware.vsphere.client.vm')
         .component('vmHardwareVirtualDiskMode', new VmHardwareVirtualDiskMode());
}
