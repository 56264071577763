/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace network_ui {

   import GhostDvsData = com.vmware.vsphere.client.h5.network.host.model.GhostDvsData;
   export class GhostDvsProxySwitchDetailsService {
      private static readonly GHOST_DVS_PROXY_SWITCH_DETAILS_PROP: string =
            "hostNetworkingIssue:ghostDvsProxySwitchDetails";
      private readonly SEPARATOR: string = ", ";
      private readonly DVS_UUID_SEPARATOR: string = "; ";
      private readonly DOUBLE_QUOTE: string = "\"";

      public static $inject = [
         "clarityModalService",
         "i18nService",
         "dataService",
         "columnRenderersRegistry",
         "defaultUriSchemeUtil"
      ];

      constructor(
            private clarityModalService: any,
            private i18nService: any,
            private dataService: any,
            private columnRenderersRegistry: any,
            private defaultUriSchemeUtil: any) {
      }

      public show(hostId: string, switchUuids: string[]): void {
         let modalOptions:any = {
            title: this.i18nService.getString("NetworkUi",
                  "ghostDvsProxySwitchDetectedEvent.dialog.title"),
            subTitle: {
               objectId: hostId
            },
            size: "lg",
            defaultButton: "close",
            hideSubmitButton: true,
            closeButtonTitle: this.i18nService.getString("NetworkUi",
                  "ghostDvsProxySwitchDetectedEvent.dialog.okButton"),
            alerts: [],
            dialogData: {
               loading: true,
               issueDesc: this.buildIssueDesc(switchUuids),
               generalContent: this.i18nService.getString("NetworkUi",
                     "ghostDvsProxySwitchDetectedEvent.generalWarning")
            },
            contentTemplate: "network-ui/resources/network/views/host/summary/GhostDvsProxySwitchDetailsDialog.html"
         };

         let params: any = {
            propertyParams: [
               {
                  propertyName: GhostDvsProxySwitchDetailsService.GHOST_DVS_PROXY_SWITCH_DETAILS_PROP,
                  parameterType: "java.util.List",
                  parameter: switchUuids
               }
            ]
         };

         this.dataService.getProperties(hostId,
               [GhostDvsProxySwitchDetailsService.GHOST_DVS_PROXY_SWITCH_DETAILS_PROP], params)
               .then((result: any) => {
            let ghostDvsData: GhostDvsData[] =
                  result[GhostDvsProxySwitchDetailsService.GHOST_DVS_PROXY_SWITCH_DETAILS_PROP];
            modalOptions.dialogData.rejoinDesc = this.buildRejoinDesc(ghostDvsData);
         }).finally(() => {
            modalOptions.dialogData.loading = false;
         });

         this.clarityModalService.openOkCancelModal(modalOptions);
      }

      private buildIssueDesc(switchUuids: string[]): string {
         let issueDesc: string = "";

         _.forEach(switchUuids, (switchUuid, index) => {
            issueDesc = issueDesc.concat(
                  this.DOUBLE_QUOTE + switchUuid + this.DOUBLE_QUOTE);

            if (index !== switchUuids.length - 1) {
               issueDesc = issueDesc.concat(this.DVS_UUID_SEPARATOR);
            }
         });

         return this.i18nService.getString("NetworkUi",
               "ghostDvsProxySwitchDetectedEvent.info", issueDesc);
      }

      private buildRejoinDesc(ghostDvsData: GhostDvsData[]): string | null {
         if (!ghostDvsData || !ghostDvsData.length) {
            return null;
         }

         let linkAndTextRenderer = this.columnRenderersRegistry.getColumnRenderer("link-and-text");
         let rejoinDesc: string = "";

         _.forEach(ghostDvsData, (ghostDvsDetails: GhostDvsData, index: number) => {
            rejoinDesc = rejoinDesc.concat(linkAndTextRenderer(
                  this.defaultUriSchemeUtil.getVsphereObjectId(ghostDvsDetails.dvsRef),
                  ghostDvsDetails.name,
                  null,
                  "",
                  true,
                  "ghost-dvs-proxy-switch-link"));

            if (index !== ghostDvsData.length - 1) {
               rejoinDesc = rejoinDesc.concat(this.SEPARATOR);
            }
         });

         return this.i18nService.getString("NetworkUi",
               "ghostDvsProxySwitchDetectedEvent.rejoinWarning", rejoinDesc);
      }
   }

   angular.module("com.vmware.vsphere.client.network")
         .service("ghostDvsProxySwitchDetailsService", GhostDvsProxySwitchDetailsService);
}