/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace dvs_ui {

   export class DvsNiocSharesPageComponent {

      public bindings: any;
      public controller: any;
      public templateUrl: string;

      constructor() {
         this.bindings = {
            wizardModel: "<"
         };

         this.controller = DvsNiocSharesPageComponentController;

         this.templateUrl = "dvs-ui/resources/dvs/niocupgrade/components/" +
               "dvsNiocSharesPageComponentTemplate.html";
      }
   }

   class DvsNiocSharesPageComponentController {

      static $inject = [
         "i18nService"
      ];

      public wizardModel: DvsUpgradeNiocWizardModel | DvsUpgradeWizardModel;

      private i18n: any;

      constructor(private i18nService: any) {
         this.i18n = this.i18nService.getString;
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .component("dvsNiocSharesPage", new DvsNiocSharesPageComponent());
}