(function() {
   angular.module('com.vmware.vsphere.client.admin')
      .controller('cloneRoleController', cloneRoleController);

   cloneRoleController.$inject = ['$scope', 'rolesService', '$rootScope', 'i18nService'];

   function cloneRoleController($scope, rolesService, $rootScope, i18nService) {
      var self = this;
      self.roleName = $scope.modalOptions.dialogData.roleName;
      self.description = $scope.modalOptions.dialogData.description;
      self.selectedPrivilegeIds = $scope.modalOptions.dialogData.selectedPrivilegeIds;
      self.i18n = i18nService.getString;

      self.cloneRole = function() {
         if (validateRoles(self.roleName)) {
            return rolesService.cloneOrCreateRole(self.roleName, self.description, self.selectedPrivilegeIds).then(function() {
               $rootScope.$broadcast('refreshRoles');
               return true;
            });
         }
         else {
            invalidateForm();
            return false;
         }
      };

      function invalidateForm () {
         self.errorMessage = self.i18n('Common', 'error.role.name.unique');
         self.validity = "invalid";
      }

      function validateRoles(newRoleName) {
         return _.filter($scope.modalOptions.dialogData.roles, function(role) {
                return role.label === newRoleName;
             }).length === 0;
      }

      $scope.modalOptions.onSubmit = self.cloneRole;

    $scope.textChanged = function() {
        if ($scope.ctrl.roleName === "") {
            $scope.modalOptions.submitDisabled = true;
        } else {
            $scope.modalOptions.submitDisabled = false;
        }
      };
   }
})();
