(function () {
   'use strict';

   /**
    * @name com.vmware.vsphere.client.host:systemSwapSettingsConstants
    *
    * @description
    *    - admissionControlPolicy
    *       - **`DATASTORE_OPTION_TYPE`** : Datastore system swap configuration option type
    *       - **`HOST_CACHE_OPTION_TYPE`** : Host cache system swap configuration option type
    *       - **`HOST_LOCAL_SWAP_OPTION_TYPE`** : Host local system swap configuration option type
    *       - **`DISABLED_TYPE`** : Disable system swap configuration option type
    */
   angular
         .module('com.vmware.vsphere.client.host')
         .constant('systemSwapConfigurationConstants', {
            systemSwapConfigurationOptionType: {
               DATASTORE_OPTION_TYPE: 'com.vmware.vim.binding.vim.host.SystemSwapConfiguration$DatastoreOption',
               HOST_CACHE_OPTION_TYPE: 'com.vmware.vim.binding.vim.host.SystemSwapConfiguration$HostCacheOption',
               HOST_LOCAL_SWAP_OPTION_TYPE: 'com.vmware.vim.binding.vim.host.SystemSwapConfiguration$HostLocalSwapOption',
               DISABLED_TYPE: 'com.vmware.vim.binding.vim.host.SystemSwapConfiguration$DisabledOption'
            }
         });
})();
