namespace storage_ui {

   import IComponentController = angular.IComponentController;
   import IComponentOptions = angular.IComponentOptions;

   import VvolStorageProvidersViewData = com.vmware.vsphere.client.h5.storage.data.datastore.vvol.VvolStorageProvidersViewData;

   class VvolDsStorageProvidersComponent implements IComponentController {

      private readonly VVOL_STORAGE_PROVIDERS_VIEW_DATA = "vvolStorageProvidersViewData";

      public datagridOptions: any;

      public datastoreId: string;

      public headerOptions: any;

      public isLoading: boolean;
      public loadingTitle: string;

      static $inject = ["vuiConstants", "i18nService", "dataService", "navigation", "storageUtil"];
      constructor(private vuiConstants: any,
                  private i18nService: any,
                  private dataService: any,
                  private navigation: any,
                  private storageUtil: any) {
      }

      $onInit() {
         this.initDatagrid();
         this.headerOptions = {
            title: this.i18nService.getString("StorageUi", "storage.storageProvidersView.title")
         };
         this.loadingTitle = this.i18nService.getString("Common", "loadingTitle");
      }

      $onChanges() {
         this.datastoreId = this.navigation.getRoute().objectId;
         this.refresh();
      }

      public refresh() {
         this.isLoading = true;
         this.dataService.getProperties(
               this.datastoreId,
               this.VVOL_STORAGE_PROVIDERS_VIEW_DATA)
               .then( (response: {[key: string]: VvolStorageProvidersViewData})=> {
                  let items = response && response[this.VVOL_STORAGE_PROVIDERS_VIEW_DATA]
                        && response[this.VVOL_STORAGE_PROVIDERS_VIEW_DATA].storageProviderItems;
                  this.datagridOptions.data = items || [];
               })
               .finally( () => {
                  this.isLoading = false;
               });
      }

      private initDatagrid() {
         this.datagridOptions = {
            resizable: true,
            height: "100%",
            columnDefs: this.getColumnDefs(),
            selectionMode: this.vuiConstants.grid.selectionMode.NONE
         };
      }

      private getColumnDefs() {
         return [
            {
               displayName: this.i18nService.getString("StorageUi", "datastore.monitor.storageProvidersView.priority"),
               field: "formattedPriority",
               width: "60px",
               template: "#:formattedPriority#",
               sortable: (item1: any, item2: any) => {
                  return this.storageUtil.compareNumericValues(item1, item2, "priority");
               }
            },
            {
               displayName: this.i18nService.getString("StorageUi", "datastore.monitor.storageProvidersView.name"),
               field: "formattedName",
               width: "120px",
               template: "#:formattedName#"
            },
            {
               displayName: this.i18nService.getString("StorageUi", "datastore.monitor.storageProvidersView.state"),
               field: "state",
               width: "60px",
               template: "#:state#"
            },
            {
               displayName: this.i18nService.getString("StorageUi", "datastore.monitor.storageProvidersView.url"),
               field: "url",
               width: "220px",
               template: "#:url#"
            }
         ];
      }
   }

   angular.module("com.vmware.vsphere.client.storage").component(
         "vvolDsStorageProviders", <IComponentOptions>{
            controller: VvolDsStorageProvidersComponent,
            template:
               `<div class="fill-parent flex-column" vx-refreshable watch-objects="{{$ctrl.datastoreId}}" refresh="$ctrl.refresh()">
                  <vx-activity-indicator is-visible="$ctrl.isLoading" title="{{::$ctrl.loadingTitle}}"></vx-activity-indicator>
                  <div vx-settings-block-header="$ctrl.headerOptions"></div>
                  <div class="flex-grow-auto flex-grid" vui-datagrid="$ctrl.datagridOptions"></div>
               </div>`
         });
}
