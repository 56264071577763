/* Copyright 2015 Vmware, Inc. All rights reserved. -- Vmware Confidential */
angular.module('com.vmware.vsphere.client.vm').service('controllerNodeFinderService', ['vmDeviceInfoService',
function (vmDeviceInfoService) {
   return {
      findNextAvailableNodeForController: findNextAvailableNodeForController
   };

   function findNextAvailableNodeForController (controllerKey, availableNodes, connectedDevices) {
      connectedDevices = _.filter(connectedDevices, function (device){ return device.controllerKey === controllerKey;});

      if (connectedDevices.length < 1) {
         return availableNodes[0];
      }

      if (connectedDevices.length >= availableNodes.length) {
         return null;
      }

      var sortedConnectedDevices = _.sortBy(connectedDevices, 'unitNumber');
      return _.find(availableNodes, function(node, index) {
         var device = sortedConnectedDevices[index];
         if (device === undefined || node.key !== device.unitNumber) {
            return node;
         }
      });
   }
}]);

