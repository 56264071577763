namespace h5_client {
   export module common_module_ui {
      import IPromise = angular.IPromise;
      import ContentLibraryConstants = h5_client.common_module_ui.ContentLibraryConstants;

      /**
       * Class to manage the session for vAPI endpoint
       */
      export class VapiSessionService {

         private readonly PROP_REST_AUTH_TOKEN_TYPE: string = "urn:vri:vsphere:vSphereClientInstance:root";
         private readonly PROP_REST_AUTH_TOKEN: string = "vApiRestAuthToken";
         private readonly PROP_REST_ENDPOINT: string = "vApiRestEndpoint";
         private readonly HTTP_MIME_TYPE: string ="application/json";
         private vmwareApiSessionId: string;

         private endpoint: string | null = null;
         static $inject = ["$http", "$q", "$location", "dataService"];

         constructor(private $http: any,
               private $q: any,
               private $location: any,
               private dataService: any) {
         }

         /**
          * Perform the login to vAPI service for the logged in client instance
          * @returns Promise {any}
          */
         login = (): any => {
            if (this.vmwareApiSessionId) {
               return this.$q.when({});
            }
            return this.dataService.getProperties(this.PROP_REST_AUTH_TOKEN_TYPE, [this.PROP_REST_AUTH_TOKEN, this.PROP_REST_ENDPOINT])
                  .then((data: any) => {
                     if (this.$location.host() === 'localhost') {
                        this.setEndpoint(data.vApiRestEndpoint);
                     }
                     let headers: any = {
                        Authorization: "SIGN token = \"" + data.vApiRestAuthToken + "\""
                     };
                     headers["Accept"] = this.HTTP_MIME_TYPE;
                     headers["Content-Type"] = this.HTTP_MIME_TYPE;
                     headers["vmware-use-header-authn"] = "true";

                     return this.$http({
                        method: "POST",
                        url: this.getEndpoint() + ContentLibraryConstants.Api.VAPI_REST_SESSION,
                        headers: headers
                     });
                  }).then(
                        (response: any) => {
                           if(response.data){
                              this.vmwareApiSessionId = response.data.value;
                           }
                        },
                        (failure: any) => {
                           delete this.vmwareApiSessionId;
                        }
                  );
         }

         /**
          * Perform logout from vAPI service
          */
         public logout(): void {
            this.$http({
               method: "DELETE",
               url: this.getEndpoint() + ContentLibraryConstants.Api.VAPI_REST_SESSION,
            }).then(
                  () => {
                     delete this.vmwareApiSessionId;
                     return;
                  },
                  (failure: any) => {
                     delete this.vmwareApiSessionId;
                  }
            );
         }

         /**
          * checks if this vAPI session is still active.
          * not used currently.
          * @returns {PromiseLike<boolean>}
          */
         public isVapiSessionAlive(): IPromise<boolean> {
            return this.$http({
               method: "POST",
               url: this.getEndpoint() + "/cis/session?~action=get",
            }).then(
                  () => {
                     return true;
                  },
                  (exception: any) => {
                     //console.log(exception);
                     return false;
                  }
            );
         }

         /**
          * checks if this session has been logged in/ready
          * @returns {boolean}
          */
         public isSessionReady(): boolean {
            return !!this.vmwareApiSessionId;
         }

         /**
          * returns the vAPI endpoint for this client session
          * @returns {string}
          */
         public getEndpoint(): string {
            return this.endpoint ?
                  this.endpoint :
                  ContentLibraryConstants.Api.SCHEME + this.$location.host() + ContentLibraryConstants.Api.VAPI_REST_BASE;
         }

         public getVmwareApiSessionId(): string {
            return this.vmwareApiSessionId;
         }

         private setEndpoint(endpoint: string) {
            this.endpoint = ContentLibraryConstants.Api.SCHEME +
                  this.parseHostName(endpoint) +
                  ContentLibraryConstants.Api.VAPI_REST_BASE;
         }

         private parseHostName(url: string): string {
            let l: any = document.createElement("a");
            l.href = url;
            return l.hostname;
         }

         private removePort(url: any) {
            var scheme = ContentLibraryConstants.Api.SCHEME;
            var secondIndex = url.indexOf("/", scheme.length + 1);
            var firstIndex = url.indexOf(":", scheme.length +1);
            return url.substring(0, firstIndex) + url.substring(secondIndex);
         }
      }

      angular.module("com.vmware.vsphere.client.commonModule").service("vapiSessionService", VapiSessionService);
   }
}