namespace h5_vm {
   import StoragePlacementResult = com.vmware.vim.binding.vim.storageDrs.StoragePlacementResult;
   import Action = com.vmware.vim.binding.vim.cluster.Action;
   import ManagedObjectReference = com.vmware.vim.binding.vmodl.ManagedObjectReference;

   declare var kendo: {
      template(template: string): any;
   };

   export class VmPlacementRecommendationViewController {
      public static $inject = [
            "$q",
            "$scope",
            "vuiConstants",
            "i18nService"];

      // Input bindings
      public recommendations: FormattedRecommendation[];
      public selectedRecommendationKey: string;

      // View bindings
      public gridOptions: any;
      public descriptionText: string;

      constructor(
            private $q: angular.IQService,
            private $scope: angular.IScope,
            private vuiConstants: any,
            private i18nService: any) {
      }

      $onInit(): void {
         this.init();
      }

      // This is purposely declared as a property bound to then component's `this`
      // so it doesn't lose the `this` reference later when invoked by the
      // `preselect-comparator` directive.
      public preselectRecommendation: Function = _.bind((gridItem: any) => {
         return gridItem.key === this.selectedRecommendationKey;
      }, this);

      private init() {
         this.descriptionText = this.text("PlacementRecommendationView.header");

         this.gridOptions = {
            columnDefs: [{
                  displayName: this.text("PlacementRecommendationView.recommendation"),
                  template: kendo.template("#:reasonText# #:warningText#" +
                        "<ul class='placement-recommendation-column'> " +
                        "  # for (var i = 0; i < actions.length; i++) { #" +
                        "     <li>" +
                        "         <span class=#:actions[i].icon#></span>" +
                        "         <span>#:actions[i].text#</span>" +
                        "     </li>" +
                        "   # } # " +
                        "</ul>"),
                  field: 'reasonText',
                  width: '80%'
               }, {
                  displayName: this.text("PlacementRecommendationView.priority"),
                  field: 'priority',
                  template: '<div style="text-align: center;">#:priority#</div>'
               }
            ],
            sortMode: this.vuiConstants.grid.sortMode.SINGLE,
            selectionMode: this.vuiConstants.grid.selectionMode.SINGLE,
            selectedItems: [],
            data: this.recommendations,
            resizable: true,
            searchable: false,
            height: 350
         };

         this.$scope.$watch(() => {
            return this.gridOptions.selectedItems;
         }, (newSelection: any[], oldSelection: any[]) => {
            let newItem: any = newSelection && !!newSelection.length ?
                  newSelection[0] : undefined;
            let oldItem: any = oldSelection && !!oldSelection.length ?
                  oldSelection[0] : undefined;
            if (newItem !== oldItem) {
               this.onSelectionRecommendationChanged(newItem);
            }
         });
      }

      private onSelectionRecommendationChanged(newRecommendationItem: any): void {
         let newKey: string = newRecommendationItem ?
               newRecommendationItem.key : undefined;
         if (newKey !== this.selectedRecommendationKey) {
            this.selectedRecommendationKey = newKey;
         }
      }

      private text(key: string, ...params: string[]): string {
         return this.i18nService.getString("VmUi", key, ...params);
      }

   }

   export class VmPlacementRecommendationView implements angular.IComponentOptions {
      public controller: any;
      public templateUrl: string;
      public bindings: any;

      constructor() {
         this.controller = VmPlacementRecommendationViewController;
         this.templateUrl =
               "vm-ui/resources/vm/views/migrationWizard/components/" +
               "vmPlacementRecommendation/vmPlacementRecommendationView.html";
         this.bindings = {
            recommendations: "<",
            selectedRecommendationKey: "="
         };
      }
   }

angular
      .module("com.vmware.vsphere.client.vm")
      .component("vmPlacementRecommendationView", new VmPlacementRecommendationView());
}
