/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
/*
 * Service to complete the wizard for Datastore capacity increase
 */
(function () {
   'use strict';
   angular.module('com.vmware.vsphere.client.storage')
      .service('readyToCompletePageService', readyToCompletePageService);

   readyToCompletePageService.$inject = [
      'i18nService', 'mutationService'
   ];

   function readyToCompletePageService(
      i18nService, mutationService) {

      return {
         build: function (wizardScope) {
            return {
               title: i18nService.getString('StorageUi', 'increaseDatastoreWizard.readyToCompletePage.title'),
               description: i18nService.getString('StorageUi', 'increaseDatastoreWizard.readyToCompletePage.description'),
               contentUrl: 'storage-ui/resources/storage/views/wizard/increaseDatastoreCapacity/pages/ReadyToCompletePage.html',
               state: undefined,
               onCommit: function() {
                  var manager = wizardScope.manager;
                  mutationService.apply(
                     manager.getDatastoreRef(),
                     'com.vmware.vsphere.client.storage.DatastoreIncreaseSpec',
                     manager.buildSpec()
                  );
                  return true;
               }
            };
         }
      };
   }
})();
