namespace h5_vm {

   import SharesInfo = com.vmware.vim.binding.vim.SharesInfo;
   import VmWorkflowMode = h5_vm.VmWorkflowMode;
   import VmHardwareNetworkService = vm_ui.VmHardwareNetworkService;
   import VirtualVmxnet3VrdmaOption = com.vmware.vim.binding.vim.vm.device.VirtualVmxnet3VrdmaOption;
   import PortConnection = com.vmware.vim.binding.vim.dvs.PortConnection;
   import ManagedObjectReference = com.vmware.vim.binding.vmodl.ManagedObjectReference;

   declare const angular: ng.IAngularStatic;

   class VirtualEthernetCard$ResourceAllocationInfo {
      _type: string;
      reservation: number;
      limit: number;
      share: SharesInfo;
   }

   class VmHardwareNetworkController implements ng.IComponentController {

      static $inject: string[] = [
         "i18nService",
         "dataService",
         "vmDeviceInfoService",
         "networkSelectorDialogService",
         "defaultUriSchemeUtil",
         "NetworkSelectorMode",
         "vmNetworkService",
         "vmHardwareNetworkService",
         "vmHardwareVersionService",
         "$q"
      ];

      virtualNetworkDevice: any;
      virtualMachineDevices: any;
      networkProvider: any;
      availableNetworks: any;
      vmWorkflowMode: VmWorkflowMode;
      selectedGuestOs: any;
      filterNetworksObjId: any;
      vmConfigContext: any;
      removeCallback: Function;
      vmId: string;
      deployVmtxMode: boolean;
      vmHardwareNetworkForm: any;
      expanded: boolean;
      readonly MAX_BANDWIDTH: number = 10000;

      private device: any;
      private properties: {
         selectedAdapterType?: any;
         selectedNetwork?: any;
         connected?: any;
         connectAtPowerOn?: any;
         reservationSupported?: boolean;
      };
      private networkSelectorModel: any;
      private onNetworkSelectionChanged: Function;
      private errorConfig: any;
      private adapterTypes: Array<any>;
      private deviceProtocols: Array<string>;
      private getEthernetCardLabel: any;
      private selectedNetworkValid: boolean;

      private resourceAllocation: any;
      private originalAllocation: any;
      private sharesValueMap: any;
      public reservationMax: number = this.MAX_BANDWIDTH;
      public reservationErrorMessages: any;
      public reservationRecommendations: any;
      public reservationUnitOptions: any;
      public limitErrorMessages: any;
      public limitRecommendations: any;
      public onPreloadNetworks: Function;

      constructor(private i18nService: any,
            private dataService: any,
            private vmDeviceInfoService: any,
            private networkSelectorDialogService: any,
            private defaultUriSchemeUtil: any,
            private NetworkSelectorMode: any,
            private vmNetworkService: VmNetworkService,
            private vmHardwareNetworkService: VmHardwareNetworkService,
            private VirtualMachineDevices: any,
            private $q: any) {
         this.reservationMax = this.MAX_BANDWIDTH;
      }

      i18n(key:string, ...rest: any[]):string {
         if (!this.i18nService) {
            return "";
         }
         return this.i18nService.getString("VmUi", key, rest);
      }

      public ARIA_LBL_RESTORE:string = this.i18n('DeviceDeletedControl.Restore');
      public ARIA_LBL_REMOVE:string = this.i18n('DeviceDeletedControl.DeviceDeleted');
      public ARIA_LBL_START_CONNECTED:string = this.i18n('ConnectDevice.ConnectAtPowerOn');
      public ARIA_LBL_CONNECTED:string = this.i18n('ConnectDevice.Connected');
      public ARIA_LBL_MTU:string = this.i18n('NetworkPage.GuestOSMTUChange');
      public ARIA_LBL_RSRV:string = this.i18n('NetworkPage.Reservation');
      public ARIA_LBL_LIMIT:string = this.i18n('NetworkPage.Limit');
      public ARIA_LBL_NIC_TYPE:string = this.i18n('VmNetView.adapterType');
      public ARIA_LBL_UPT:string = this.i18n('NetworkPage.UptCompatibility');
      public ARIA_LBL_SRIOV_PF:string = this.i18n('NetworkPage.PhysicalFunction');
      public ARIA_LBL_SHARES:string = this.i18n('NetworkPage.Shares');

      $onInit() {
         this.initSriov();
         this.device = this.virtualNetworkDevice.getCurrentDevice();
         this.originalAllocation = angular.copy(this.device.resourceAllocation);
         this.resourceAllocation = this.device.resourceAllocation;
         this.properties = {};

         const nwSelectorSpec = this.buildNetworkSelectorSpec();
         let singleSelectedNetworkObject: any = this.vmHardwareNetworkService.buildNetworkItemForSelectedNetworkBacking(
               this.vmConfigContext.environment.configTarget,
               this.virtualNetworkDevice.getCurrentDevice()
         );

         let networkProviderId: any = this.networkProvider ?
               this.networkProvider :
               this.vmHardwareNetworkService.getNetworkProviderFromVmConfig(this.vmConfigContext);

         this.networkSelectorModel = {
            objectRef: networkProviderId,
            mode: this.NetworkSelectorMode.SINGLE_SELECTION_DROPDOWN,
            filterSpec: nwSelectorSpec.filterSpec,
            dialogSpec: nwSelectorSpec.dialogSpec,
            selectedNetwork: singleSelectedNetworkObject,
            prefetchedNetworks: [singleSelectedNetworkObject]
         };

         this.onNetworkSelectionChanged = (selectedNetworks: Array<any>) => {
            let currentNetwork: any = selectedNetworks[0];
            let networkId: string = this.defaultUriSchemeUtil.getVsphereObjectId(currentNetwork.networkRef);
            let networkInfo: any = this.vmDeviceInfoService.getNetworkInfo(
                  this.vmConfigContext.environment.configTarget,
                  networkId);
            this.virtualNetworkDevice.setNetwork(networkInfo);
            let selectedNetwork: any = {
               id: networkId, name: currentNetwork.name, networkInfo
            };
            this.properties.selectedNetwork = selectedNetwork;

            this.checkIsNetworkAvailable();

            if (this.isNetworkReservationSupported()) {
               this.device.resourceAllocation = this.resourceAllocation;
               this.properties.reservationSupported = true;
            } else {
               this.device.resourceAllocation = this.newResourceAllocationInfo();
               this.properties.reservationSupported = false;
            }
            this._portKeys = null;
         };

         this.onPreloadNetworks = (): any => {
            if (this.vmWorkflowMode !== VmWorkflowMode.UpdateMode || !this.vmId) {
               return this.$q.when(undefined);
            }

            // Get networks from the VM config target in the VM Edit mode
            if (this.vmConfigContext.configTargetIsBeingUpdated) {
               return this.$q.when(
                     this.vmHardwareNetworkService.getNetworkSelectorNetworks(
                           this.vmNetworkService.getVmNetworkSelectorFilterSpec(),
                           this.vmConfigContext.environment.configTarget));
            }

            return this.vmHardwareNetworkService.getVmConfigTarget(
                  this.vmId).then((configTargetWithNetworksResult: any) => {

               if (configTargetWithNetworksResult &&
                     configTargetWithNetworksResult.vmConfigTarget) {
                  this.vmConfigContext.configTargetIsBeingUpdated = true;
                  this.vmConfigContext.environment.configTarget =
                        configTargetWithNetworksResult.vmConfigTarget;

                  return this.vmHardwareNetworkService.getNetworkSelectorNetworks(
                        this.vmNetworkService.getVmNetworkSelectorFilterSpec(),
                        this.vmConfigContext.environment.configTarget);
               }
               // If the networks can not be retrieved from the config target,
               // they will be retrieved from the network selector
               return undefined;
            });
         };

         this.errorConfig = {
            message: this.i18n("NetworkPage.NoPermission"),
            isVisible: false
         };

         this.deviceProtocols = this.vmHardwareNetworkService.getPVRDMADeviceProtocols();
         this.updateSelectedDeviceProtocol();

         this.adapterTypes = <any>[];
         let recommendedAdapterType: any;
         const gosDescriptor = this.selectedGuestOs;
         const hardwareDeviceOptions = this.vmConfigContext.environment.configOption.hardwareOptions.virtualDeviceOption;
         // adding device ethernetcard
         let deviceEthernetCard = this.vmDeviceInfoService.getDeviceEthernetCard(this.device);
         gosDescriptor.supportedEthernetCard.forEach((gosSupportedType: any) => {
            hardwareDeviceOptions.forEach((hardwareSupportedType: any) => {
               if (gosSupportedType.name === hardwareSupportedType.type.name || deviceEthernetCard.name === hardwareSupportedType.type.name) {
                  if (gosDescriptor.recommendedEthernetCard.name === hardwareSupportedType.type.name) {
                     recommendedAdapterType = hardwareSupportedType;
                  }
                  this.adapterTypes.push(hardwareSupportedType);
               }
            });
         });

         this.adapterTypes = _.uniq(this.adapterTypes, false, (item: any) => {
            return item.type.name;
         });

         this.removePVRDMAIfLimitReached();
         this.properties = {
            selectedAdapterType: this.vmNetworkService.getSelectedNetworkTypeForDevice(this.device,
                  recommendedAdapterType,
                  this.adapterTypes,
                  hardwareDeviceOptions),
            selectedNetwork: {
               id: this.virtualNetworkDevice.getNetworkId(),
               name: this.virtualNetworkDevice.getNetworkName(),
               networkInfo: this.virtualNetworkDevice.getNetwork()
            },
            connected: this.device.connectable.connected,
            connectAtPowerOn: this.device.connectable.startConnected
         };

         this.getEthernetCardLabel = this.vmDeviceInfoService.getEthernetCardLabel;
         this.selectedNetworkValid = true;

         this.setupResourceAllocation();
      }

      public isVmPoweredOn(): boolean {
         return this.vmHardwareNetworkService.isVmPoweredOn(this.vmConfigContext);
      }

      public isHotAddDevice = () => {
         return this.virtualNetworkDevice.isNew() &&
               this.vmWorkflowMode === VmWorkflowMode.UpdateMode &&
               this.isVmPoweredOn();
      };

      $postLink() {
         this.checkIsNetworkAvailable();
         this.checkSriovValidity();
      }

      isRemoveDisabled(): boolean {
         if (this.isNew() || this.deployVmtxMode) {
            return false;
            //will be removed later when remove network is supported
         } else {
            return this.vmHardwareNetworkService.isRemoveNetworkAdapterDisabled(this.vmConfigContext.privileges);
         }
      }

      onRemoveDevice() {
         if (!this.isRemoveDisabled() && this.removeCallback) {
            this.removeCallback()(this.virtualNetworkDevice);

            // on device removal mark form valid if it was not, later
            // if removal is reverted, we will re-calculate validity
            this.setErrorVisibility([true/*isValid*/]);
            this.vmHardwareNetworkForm.$setValidity("sriov", true);
         }
      }

      revertRemoval() {
         if (!this.virtualNetworkDevice) {
            return;
         }

         this.virtualNetworkDevice.revertRemoval();
         // re-calculate the form validity, because it was maked valid
         // on device removal
         this.checkIsNetworkAvailable();
         this.setSriovValidity();
      }

      hasError() {
         if (!_.isEmpty(this.vmHardwareNetworkForm)) {
            return this.vmHardwareNetworkForm.$invalid;
         }
         return false;
      };


      public shouldDisplayPVRDMADeviceProtocol(): boolean {
         return this.supportsDeviceProtocol();
      }

      private getDeviceProtocolLabel(deviceProtocol: string): string {
         return this.vmHardwareNetworkService.getPVRDMADeviceProtocolLabel(deviceProtocol);
      }

      private updateSelectedDeviceProtocol(): void {
         // if (this.featureFlagsService.VRDMA_ROCEV2Enabled()) {
         //    return;
         // }

         if (!this.virtualNetworkDevice.isNew()) {
            return;
         }

         if (this.supportsDeviceProtocol()) {
            if (!this.device.deviceProtocol) {
               this.device.deviceProtocol = this.vmHardwareNetworkService.getDefaultRDMAProtocol(this.getVrdmaOption());
            }
            this.device.deviceProtocol = this.device.deviceProtocol || this.deviceProtocols[0];
         }
      }

      private buildNetworkSelectorSpec = (): any => {
         return {
            filterSpec: this.vmNetworkService.getVmNetworkSelectorFilterSpec(),
            dialogSpec: this.networkSelectorDialogService.createNetworkSelectorDialogSpec(
                  ["Network.Assign"],
                  this.i18nService.getString("Common", "NetworkSelectorDialog.noPrivileges"),
                  this.i18nService.getString("Common", "NetworkSelectorDialog.noPrivilegesAndMore"))
         };
      };

      private checkIsNetworkAvailable = () => {
         this.errorConfig.isVisible = false;
         this.selectedNetworkValid = true;
         if (this.virtualNetworkDevice.isMarkedForRemoval()) {
            // If the device is marked for removal we automatically count it
            // valid and don't need to trigger any further validation.
            this.setErrorVisibility([true/*is valid*/]);
            return;
         }
         if (!_.isEmpty(this.properties.selectedNetwork) && this.vmWorkflowMode !== VmWorkflowMode.UpdateMode) {
            this.vmHardwareNetworkService.checkNetworkPrivileges(this.properties.selectedNetwork.id)
                  .then((result: any) => {
                     this.setErrorVisibility(result);
                  });
         }
      };

      private setErrorVisibility(result: Array<boolean>) {
         let isAvailable: boolean = result[0];
         if (isAvailable === undefined) {
            isAvailable = true;
         }
         this.errorConfig.isVisible = !isAvailable;
         this.selectedNetworkValid = isAvailable;
         this.vmHardwareNetworkForm.$setValidity("nested", this.selectedNetworkValid);
      }

      private onConnectAtPowerOnChange = () => {
         this.device.connectable.startConnected = this.properties.connectAtPowerOn;

         if (this.isHotAddDevice()) {
            this.setConnectedValue(this.properties.connectAtPowerOn);
         }
      };

      private setConnectedValue = (newValue: boolean) => {
         this.properties.connected = newValue;
         this.device.connectable.connected = newValue;
      };

      private onConnectChange = () => {
         this.device.connectable.connected = this.properties.connected;

         if (this.isHotAddDevice()) {
            this.setConnectAtPowerOnValue(this.properties.connected);
         }
      };

      private setConnectAtPowerOnValue = (newValue: boolean) => {
         this.properties.connectAtPowerOn = newValue;
         this.device.connectable.startConnected = newValue;
      };

      private onNetworkAdapterTypeChange = () => {
         this.device._type = this.properties.selectedAdapterType.type.name;
         this.updateSelectedDeviceProtocol();
         this.updateNetworkReservationSupported();
         this.afterAdapterTypeChange();
      };

      private isSriovEthernetCardType(type: String): boolean {
         return type === "com.vmware.vim.binding.vim.vm.device.VirtualSriovEthernetCard";

      }

      private isNetworkSelectDisabled() {
         return this.vmNetworkService.isNetworkSelectDisabled(this.vmConfigContext.privileges, this.isNew());
      }

      private isConnectedDisabled() {
         if (this.isNotConnectable()) {
            return true;
         }
         return this.vmNetworkService.isConnectedDisabled(this.vmConfigContext.privileges, this.isNew());
      }

      private isConnectedAtPowerOnDisabled() {
         if (this.isNotConnectable()) {
            return true;
         }
         return this.vmNetworkService.isConnectedAtPowerOnDisabled(this.vmConfigContext.privileges, this.isNew());
      }

      private isMacAddressDisabled() {
         return this.vmNetworkService.isMacAddressDisabled(this.vmConfigContext.environment.powerState,
               this.virtualNetworkDevice.isNew(),
               this.vmConfigContext.privileges,
               this.vmWorkflowMode === VmWorkflowMode.CreateMode);
      }

      private isNetworkAdapterDisabled() {
         if (this.isNew()) {
            return false;
         } else {
            return true;
         }
      }

      public uptCompatibility(value: boolean): boolean {

         let device = this.device;
         let deviceOption = this.properties.selectedAdapterType;

         if (angular.isDefined(value)) {
            this.vmNetworkService.setUptCompatibility(device, value);
            return value;
         } else {
            return this.vmNetworkService.getUptCompatibility(device, deviceOption);
         }
      }

      public isEditUptCompatibilityDisabled() {
         return this.vmNetworkService.isEditUptCompatibilityDisabled(this.vmConfigContext, this.isNew());

      }

      public isUptCompatibilityVisible(): boolean {
         let deviceOption = this.properties.selectedAdapterType;
         return this.vmNetworkService.isUptCompatibilityVisible(deviceOption);
      }

      private setupResourceAllocation(): void {
         this.sharesValueMap = {low: 25, normal: 50, high: 100};

         this.updateNetworkReservationSupported();

         if (!this.resourceAllocation) {
            this.resourceAllocation = this.newResourceAllocationInfo();
            if (this.isNetworkReservationSupported()) {
               this.device.resourceAllocation = this.resourceAllocation;
            }
         }
         this.updateReservationRange();
         this.setupBandwidthUnits();

         this.reservationErrorMessages = {max: this.getReservationError.bind(this)};
         this.limitErrorMessages = {max: this.getReservationError.bind(this)};

         this.updateReservationRecommendations();
         this.updateLimitRecommendations();
         this.setupLimitValidator();
         return;
      }

      private updateNetworkReservationSupported(): void {
         this.properties.reservationSupported = this.isNetworkReservationSupported();
      }

      private isNetworkReservationSupported(): boolean {


         if (this.isSriovEthernetCardType(this.device._type)) {
            return false;
         }
         let networkInfo: any = this.properties.selectedNetwork.networkInfo;

         if (networkInfo && networkInfo.hasOwnProperty("networkReservationSupported")) {
            return networkInfo.networkReservationSupported;
         }

         return false;
      }

      private newResourceAllocationInfo(): any {
         let retVal: VirtualEthernetCard$ResourceAllocationInfo = new VirtualEthernetCard$ResourceAllocationInfo();
         retVal._type = "com.vmware.vim.binding.vim.vm.device.VirtualEthernetCard$ResourceAllocation";
         retVal.share = new SharesInfo();
         retVal.reservation = 0;
         retVal.limit = -1;
         retVal.share.level = "normal";
         retVal.share.shares = 50;
         return retVal;
      }

      public limitValidator: any;
      public limitValidators: any;

      private setupLimitValidator(): any {
         this.limitValidator = {
            name: "validateLimit",
            validate: this.validateLimit.bind(this),
            message: this.getLimitError.bind(this)
         };
         this.limitValidators = [this.limitValidator];
      }

      public validateLimit(value: any): boolean {

         if (value === -1) {
            return true;
         }

         if (value > this.MAX_BANDWIDTH) {
            return false;
         }

         if (value < 1) {
            return false;
         }

         return true;
      }

      public onLimitChanged(): void {
         this.updateReservationRange();
         this.updateReservationRecommendations();
      }

      private updateReservationRange(): void {

         if (this.resourceAllocation && this.resourceAllocation.limit > 0) {
            this.reservationMax = this.resourceAllocation.limit;
         } else {
            this.reservationMax = this.MAX_BANDWIDTH;
         }
      }

      public onReservationChanged(): void {
         this.updateLimitRecommendations();
      }

      private updateReservationRecommendations(): void {

         let resourceAllocation = this.originalAllocation;
         let currentValue = resourceAllocation ? resourceAllocation.reservation : 0;

         this.reservationRecommendations = [
            {name: this.i18n("NetworkPage.CurrentValue"), value: currentValue},
            {name: this.i18n("NetworkPage.Minimum"), value: 0},
            {name: this.i18n("NetworkPage.Maximum"), value: this.reservationMax},
         ];
      }

      private updateLimitRecommendations(): void {
         let UNLIMITED_VALUE = this.i18nService.getString("Common", "unlimited");

         let resourceAllocation = this.originalAllocation;
         let currentValue: number | null = resourceAllocation ? resourceAllocation.limit : 0;

         let formattedCurrentValue: string = "";

         if (currentValue !== null) {
            formattedCurrentValue = currentValue.toString();
         }

         if (currentValue === -1) {
            formattedCurrentValue = UNLIMITED_VALUE;
         }

         this.limitRecommendations = [
            {
               name: this.i18n("NetworkPage.CurrentValue"), value: currentValue,
               formattedValue: formattedCurrentValue
            },
            {name: this.i18n("NetworkPage.Minimum"), value: 1},
            {name: this.i18n("NetworkPage.Maximum"), value: this.MAX_BANDWIDTH},
            {name: UNLIMITED_VALUE, value: -1, formattedValue: UNLIMITED_VALUE}
         ];
      }

      public getReservationError(): any {
         let mhzLabel: string = this.i18nService.getString("Common", "megabitSec.label");

         let name: String = this.i18n("NetworkPage.Reservation");
         let min: String = "0";
         let max: String = this.reservationMax.toString() + " " + mhzLabel;

         let retVal: String = this.i18nService.getString("VmUi", "Util.Validation.ValidRange", name, min, max);

         return retVal;
      }

      public getLimitError(): any {
         let mhzLabel: string = this.i18nService.getString("Common", "megabitSec.label");

         let name: String = this.i18n("NetworkPage.Limit");
         let min: number = 1;

         let max: String = this.MAX_BANDWIDTH.toString() + " " + mhzLabel;
         let retVal: String = this.i18nService.getString("VmUi", "Util.Validation.ValidRange", name, min.toString(), max);
         return retVal;
      }

      private setupBandwidthUnits() {
         let mhzLabel: string = this.i18nService.getString("Common", "megabitSec.label");
         let ghzLabel: string = this.i18nService.getString("Common", "gigabitSec.label");
         this.reservationUnitOptions = [
            {label: mhzLabel, multiplier: 1},
            {label: ghzLabel, multiplier: 1000}
         ];
      }

      private isNew():boolean {
         if (this.virtualNetworkDevice) {
            return this.virtualNetworkDevice.isNew();
         }
         return false;
      }

      private sriov:h5_vm.VmHwSriovDriver;

      private initSriov(): void {
         this._mtuChangeValueAllow.label = this.i18n("NetworkConfig.AllowGuestOSMtuChange");
         this._mtuChangeValueDisallow.label = this.i18n("NetworkConfig.AllowGuestOSMtuChangeNot");
         this.sriov = new h5_vm.VmHwSriovDriver(this.vmConfigContext, this.virtualNetworkDevice, this.vmWorkflowMode);
      }

      private isSriovEthernetCard(): boolean {
         return this.isSriovEthernetCardType(this.device._type);
      }

      private afterAdapterTypeChange(): void {
         this.setSriovValidity();
         if (!this.isSriovEthernetCard()) {
            return;
         }

         if (this.sriov.available()) {
            this.sriov.switchToSriovAdapterType();
         }
      }

      private setSriovValidity(): void {
         if (!this.isSriovEthernetCard()) {
            this.sriovValid(true);
            return;
         }

         if(this.isVmPoweredOn()) {
            this.sriovValid(false);
         }

         if (this.sriov.unavailable()) {
            this.sriovValid(false);
         }
      }

      private sriovValid(valid:boolean) {
         this.vmHardwareNetworkForm.$setValidity("sriov", valid);
      }

      public get sriovValue(): any {
         return this.sriov.sriovValue;
      }

      public set sriovValue(value: any) {
         this.sriov.sriovValue = value;
      }

      public sriovList(): any[] {
         return this.sriov.sriovList();
      }

      public isSriovVisible():boolean {
         return this.isSriovEthernetCard();
      }

      public isSriovSelectVisible():boolean {
         if (this.sriov.available()) {
            return this.isSriovEthernetCard() && this.isNew();
         } else {
            return false;
         }
      }

      public isSriovLabelVisible():boolean {
         return !this.isSriovSelectVisible();
      }

      public getSriovLabelText():string {
         if (this.sriov.unavailable()) {
            return this.i18n("NetworkPage.SriovNotAvailable");
         }
         return this.sriov.getSriovLabelText();
      }

      public get isSriovWarningVisible():boolean {
         return this.isSriovEthernetCard() && this.sriov.available();
      }

      public get sriovWarningText():string {
         const retVal:string = this.i18n("NetworkPage.WarnLimitedOperations");
         return retVal;
      }

      public get isMemWarnVisible():boolean {
         if (this.sriov.unavailable()) {
            return false;
         }
         if (false === this.isSriovVisible()) {
            return false;
         }
         return this.sriov.reservationIssue();
      }

      public get memWarnText():string {
         return this.i18n("NetworkPage.WarnReservation");
      }

      private isNotConnectable():boolean {
         // SR-IOV adapter case
         return this.vmNetworkService.isNotConnectable(this.device, this.vmConfigContext);
      }

      public get showHotAddError():boolean {

         if (!this.isNew()) {
            return false;
         }

         if (this.isSriovEthernetCard() && this.isVmPoweredOn()) {
            return true;
         }

         return false;
      }

      public get hotAddErrorText():string {
         return this.i18n("NetworkPage.HotAddError");
      }

      public get mtuChangeValue():{value:boolean, label:string} {
         if (!this.isSriovEthernetCard()) {
            return this._mtuChangeValueDisallow;
         }

         if (this.sriov.allowMtuChange) {
            return this._mtuChangeValueAllow;
         } else {
            return this._mtuChangeValueDisallow;
         }
      }

      public set mtuChangeValue(item:{value:boolean, label:string}) {
         if (!this.isSriovEthernetCard()) {
            return;
         }
         this.sriov.allowMtuChange = item.value;

      }

      private _mtuChangeValueAllow: any = {value: true, id:1};
      private _mtuChangeValueDisallow: any = {value: false, id:0};

      public get mtuChangeList(): Array<any> {
         return [this._mtuChangeValueAllow, this._mtuChangeValueDisallow];
      }

      public get isMtuChangeVisible():boolean {
         return this.isSriovEthernetCard();
      }

      public get isMtuChangeEditable():boolean {
         return this.sriov.isAllowMtuChangeEditable();
      }

      public get showDeviceWarning():boolean {
         return this.isMemWarnVisible;
      }

      private checkSriovValidity() {
         if (this.virtualNetworkDevice.isMarkedForRemoval()) {
            // If the device is marked for removal we automatically count it
            // valid and don't need to trigger any further validation.
            this.sriovValid(true);
            return;
         }
         if (this.isSriovEthernetCard() && !this.virtualNetworkDevice.isNew()) {
            // if the device already exists, but it's compute resource has been changed
            // and no longer supports SR-IOV mark it as invalid
            if (this.sriov.unavailable()) {
               this.sriovValid(false);
            }
         }
      }

      private getVrdmaOption(): VirtualVmxnet3VrdmaOption {
         let type: any = this.properties.selectedAdapterType;
         let option: VirtualVmxnet3VrdmaOption = type as VirtualVmxnet3VrdmaOption;
         return option;
      }

      public supportsDeviceProtocol(): boolean {
         let option: VirtualVmxnet3VrdmaOption = this.getVrdmaOption();
         return this.vmHardwareNetworkService.supportsDeviceProtocol(option);
      }

      private isPVRDMALimitReached(): boolean {
         if (this.virtualNetworkDevice.isNew()) {
            return this.vmHardwareNetworkService.isPVRDMALimitReached(this.virtualMachineDevices, this.vmConfigContext);
         }
         return false;
      }

      private removePVRDMAIfLimitReached(): void {
         if (!this.virtualNetworkDevice.isNew()) {
            return;
         }
         if (this.isPVRDMALimitReached()) {
            this.adapterTypes = this.vmHardwareNetworkService.removePVRDMAType(this.adapterTypes);
         }
      }

      // Port ID section - let user to fix stale port ID

      public LBL_PORT_ID:string = this.i18n('NetworkPage.PortID');
      public LBL_PORT_ID_ERR:string = this.i18n('NetworkPage.PortID.Error');

      private get showPortID(): boolean {
         if (this.deployVmtxMode) {
            return false;
         }

         if (this.vmWorkflowMode !== VmWorkflowMode.UpdateMode) {
            // only show in VM's "Edit Settings"
            return false;
         }

         if (!this.virtualNetworkDevice) {
            return false;
         }

         if (!this.device || ! this.device.backing) {
            return false;
         }

         if (this.isNew()) {
            return false;
         }

         const port: PortConnection = this.device.backing.port;

         if (!port) {
            return false;
         }

         if (port.portKey === "") {
            return true;
         }

         if (!port.portKey) {
            return false;
         }
         return true;
      }

      private get port():string {
         const backing = this.device.backing;
         let port: PortConnection = backing.port || {};
         return port.portKey;
      }

      private set port(value:string) {
         const backing = this.device.backing;
         let port: PortConnection = backing.port || {};
         if (value !== port.portKey) {
            port.portKey = value;
            if (!this._portKeys) {
               this.fetchPortKeys();
            }
         }
      }

      private _portKeys: string[] | null = null;

      private get portIsInvalid(): boolean {
         if (!this._portKeys) {
            return false;
         }
         let value = this.port;
         if (!value) {
            return true;
         }
         if (this._portKeys.indexOf(value) < 0) {
            return true;
         }
         return false;
      }

      private fetchPortKeys() {

         this._portKeys = null;

         let info: {_type: string, portgroup: ManagedObjectReference} = this.virtualNetworkDevice.getNetwork();
         if (!info) {
            return;
         }

         const PG_INFO_TYPE: string = "com.vmware.vim.binding.vim.dvs.DistributedVirtualPortgroupInfo";

         if (info._type !== PG_INFO_TYPE) {
            return;
         }

         const moRef: ManagedObjectReference = info.portgroup;

         if (!moRef) {
            return;
         }

         const objectId = this.defaultUriSchemeUtil.createVmomiUri(
               moRef.type,
               moRef.value,
               moRef.serverGuid);


         const onSuccess = (response: { portKeys: string[] }) => {
            if (!response || !response.portKeys) {
               this._portKeys = null;
               return;
            }
            this._portKeys = response.portKeys;
         };

         const onError = (): void => {
            this._portKeys = null;
         };

         const PROP_PORT_KEY: string = "portKeys";

         const promise: angular.IPromise<{ portKeys: string[] }> = this.dataService.getProperties(objectId, PROP_PORT_KEY);

         promise.then(onSuccess, onError);

      }
   }

   class VmHardwareNetwork implements ng.IComponentOptions {
      bindings: any;
      controller: any;
      templateUrl: string;

      constructor() {
         this.bindings = {
            virtualNetworkDevice: "<",
            virtualMachineDevices: "<",
            networkProvider: "<",
            availableNetworks: "<",
            networkPrivilegeMap: "<",
            vmWorkflowMode: "<",
            selectedGuestOs: "<",
            filterNetworksObjId: "<",
            vmConfigContext: "<",
            removeCallback: "&",
            vmId: "<",
            deployVmtxMode: "=",
            expanded: "="
         };
         this.controller = VmHardwareNetworkController;
         this.templateUrl = "vm-ui/resources/vm/views/settings/vmHardwareSettings/vmHardwareNetwork/vm-hardware-network.html";
      }
   }

   angular.module("com.vmware.vsphere.client.vm")
         .component("vmHardwareNetwork", new VmHardwareNetwork());
}
