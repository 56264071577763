namespace h5_vm {
   class RegisterVmServiceFactory {
      public static $inject = [
         "vmProvisioningNameAndFolderPageService",
         "VmProvisioningComputeResourcePageService",
         "vmProvisioningFinishRegisterPageService"
      ];

      constructor(vmProvisioningNameAndFolderPageService: any,
            VmProvisioningComputeResourcePageService: any,
            vmProvisioningFinishRegisterPageService: VmProvisioningFinishRegisterPageService) {
         return () => {
            return new RegisterVmService(
                  vmProvisioningNameAndFolderPageService,
                  VmProvisioningComputeResourcePageService,
                  vmProvisioningFinishRegisterPageService);
         };
      }
   }

   export class RegisterVmService {
      public static $inject = [
         "vmProvisioningNameAndFolderPageService",
         "VmProvisioningComputeResourcePageService",
         "vmProvisioningFinishRegisterPageService"];

      constructor(private vmProvisioningNameAndFolderPageService: any,
         private VmProvisioningComputeResourcePageService: any,
         private vmProvisioningFinishRegisterPageService: VmProvisioningFinishRegisterPageService) {}

      buildPages(wizardScope: any, defaultTargetId: string,
         preselectedComputeResourceId: string): {pages: Array<any>} {

         let dcId: string = wizardScope.wizardViewData.getDatacenterId();
         wizardScope.vmParams.setTargetInformation(null, dcId , null, null);

         let nameAndLocationPage: any = this.vmProvisioningNameAndFolderPageService
            .build(wizardScope, defaultTargetId);
         let computeResourcePage: any = this.VmProvisioningComputeResourcePageService
            .build(wizardScope, preselectedComputeResourceId);
         let finishPage: any = this.vmProvisioningFinishRegisterPageService
            .build(wizardScope);

         return {
            pages: [
               nameAndLocationPage,
               computeResourcePage,
               finishPage
            ]
         };
      }
   }

   angular.module("com.vmware.vsphere.client.vm")
      .service("registerVmService", RegisterVmServiceFactory);
}
