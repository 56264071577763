(function () {
   'use strict';

   angular
         .module('com.vmware.vsphere.client.dvs')
         .service('dvPortgroupGeneralPropertiesPageService',
               dvPortgroupGeneralPropertiesPageService);

   /**
    * Service dedicated for dvPortgroupGeneralPropertiesPage template.
    */
   dvPortgroupGeneralPropertiesPageService.$inject = [
      'i18nService',
      'mutationService'];

   function dvPortgroupGeneralPropertiesPageService(i18nService,
         mutationService) {

      var MAX_ALLOWED_DESCRIPTION_LENGTH = 255;

      return {
         getInvalidDescriptionError: getInvalidDescriptionError
      };

      /**
       * Validates the description of a distributed port group and
       * return an appropriate localized error message.
       */
      function getInvalidDescriptionError(description) {
         if (description) {
            description = description.trim();

            if (description.length > MAX_ALLOWED_DESCRIPTION_LENGTH) {
               return i18nService.getString(
                     'H5NetworkUi', 'dvpg.properties.description.tooLong');
            }
         }

         return null;
      }
   }
})();
