/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
module hostprofile_ui {

   export class HostProfileService {

      static $inject = [
         "defaultUriSchemeUtil",
         "i18nService",
         "mutationService",
         "dataService",
         "clarityModalService",
         "attachHostProfileService"
      ];

      constructor(private defaultUriSchemeUtil: any,
                  private i18nService: any,
                  private mutationService: any,
                  private dataService: any,
                  private clarityModalService: any,
                  private attachHostProfileService: AttachHostProfileService) {
      }

      private openDetachChangeHostProfileErrorDialog(hostName: string, detachHostProfile: boolean): void {

         let title: string = detachHostProfile ?
               this.i18nService.getString("HostProfileUi", "detachProfile.title") :
               this.i18nService.getString("HostProfileUi", "attachWizard.title.changeProfile", hostName);

         let message: string = detachHostProfile ?
               this.i18nService.getString("HostProfileUi", "detachProfile.error", hostName) :
               this.i18nService.getString("HostProfileUi", "actionChangeHostPrifle.error", hostName);


         let modalOptions = {
            title: title,
            subTitle: {
               text: hostName
            },
            message: message,
            icon: "icon-warning-32",
            submit: () => {
            },
            hideCancelButton: true
         };
         this.clarityModalService.openConfirmationModal(modalOptions);
      }

      private openDetachHostProfileDialog(hostId: string, hostName: string): void {
         let modalOptions = {
            title: this.i18nService.getString(
                  "HostProfileUi",
                  "detachProfile.title"
            ),
            subTitle: {
               text: hostName
            },
            message: this.i18nService.getString("HostProfileUi", "detachProfile.text"),
            icon: "icon-warning-32",
            submit: () => {
               this.detachHostProfileAction(hostId);
            },
            defaultButton: "close",
            saveButtonLabel: this.i18nService.getString("Common", "yes.label"),
            cancelButtonLabel: this.i18nService.getString("Common", "no.label")
         };
         this.clarityModalService.openConfirmationModal(modalOptions);
      }

      private detachHostProfileAction(hostId: string) {
         this.dataService.getData(hostId,
               "com.vmware.vsphere.client.h5.host.model.hostprofile.HostAttachHostProfileData")
               .then((data: any) => {
                  if (data.associatedHostProfile && data.associatedHostProfile !== undefined) {
                     let associatedHostProfileId = this.defaultUriSchemeUtil.getVsphereObjectId(
                           data.associatedHostProfile);
                     this.mutationService.apply(associatedHostProfileId,
                           "com.vmware.vsphere.client.hostprofile.data.AttachEntitiesSpec",
                           {entitiesToDetach: [this.defaultUriSchemeUtil.getManagedObjectReference(hostId)]});
                  }
               });
      }

      /**
       * Checks if a host is under cluster with associated host profile
       * and opens an error dialog or a dialog/wizard for detach/change host profile
       * @param hostId The id of the host
       * @param detachHostProfile if the function is called for detach
       * @param callback A callback which will be executed to open a dialog/wizard for
       * detaching/changing host profile for a host
       */
      private openDialog(hostId: string, callback: (hostId: string, parameter: any) => void, detachHostProfile: boolean): void {
         this.dataService.getData(hostId,
               "com.vmware.vsphere.client.h5.host.model.hostprofile.HostAttachHostProfileData")
               .then((data: any) => {
                  if (data && data.associatedHostProfileForCluster) {
                     this.openDetachChangeHostProfileErrorDialog(data.hostName, detachHostProfile);
                  } else {
                     if (detachHostProfile) {
                        callback(hostId, data.hostName);
                     } else {
                        callback(hostId, false);
                     }
                  }

               });
      }

      public changeHostProfile(hostId: string): void {
         this.openDialog(hostId,
               this.attachHostProfileService.openModal.bind(this.attachHostProfileService),
               false
         );
      }

      public detachHostProfile(hostId: string): void {
         this.openDialog(hostId, this.openDetachHostProfileDialog.bind(this), true);
      }

      public openExportHostCustomizationsDialog(objectId: string): void {
         const modalOptions: any = {
            title: this.i18nService.getString("HostProfileUi",
                "exportCustomizationsDialog.title"),
            subTitle: {
               objectId: objectId
            },
            dialogData: {
               objectId: objectId
            },
            defaultButton: "submit",
            submitButtonTitle: this.i18nService.getString("HostProfileUi", "saveButton.label"),
            contentTemplate: "hostprofile-ui/resources/hostprofile/customizations/exportHostCustomizations.html"
         };
         this.clarityModalService.openOkCancelModal(modalOptions);
      }

      public openExportHostProfileDialog(objectId: string): void {
         const modalOptions: any = {
            title: this.i18nService.getString("HostProfileUi",
                "exportProfile.warning.title"),
            subTitle: {
               objectId: objectId
            },
            dialogData: {
               objectId: objectId
            },
            defaultButton: "submit",
            submitButtonTitle: this.i18nService.getString("HostProfileUi", "saveButton.label"),
            contentTemplate: "hostprofile-ui/resources/hostprofile/export/exportHostProfile.html"
         };
         this.clarityModalService.openOkCancelModal(modalOptions);
      }
   }

   angular.module("com.vmware.vsphere.client.hostprofile").service("hostProfileService",
         HostProfileService);
}
