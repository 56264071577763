/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */

(function () {
   'use strict';

   /**
    * Service for initiating download of files from a datastore.
    */
   angular.module('com.vmware.vsphere.client.storage').factory('fileDownloadService',
         ['$timeout', '$window', 'fileUrlResolverService', 'browserService',
            '$http', '$httpParamSerializer', 'defaultUriSchemeUtil', 'i18nService',
            'storageUtil', 'dataService', '$cookies', 'clarityModalService',
            function ($timeout, $window, fileUrlResolverService, browserService,
               $http, $httpParamSerializer, defaultUriSchemeUtil, i18nService,
               storageUtil, dataService, $cookies, clarityModalService) {

               var IE_URL_LIMIT = 2047;
               var BROWSER_URL_LIMIT = 30000;

               var DOWNLOAD_FILES_AS_ZIP_URL = "filedownload";
               var REQUEST_TOKEN_PARAM = "requestToken";
               var TOKEN_PARAM = "token";
               var STREAM_VMDK = "StreamVmdk";

               var publicApi = {
                  startFilesDownload: function (dc, ds, paths, isStreamFormat) {
                     var is_safari = browserService.isSafari;
                     // initializes the method based on browser on first call
                     publicApi.startFilesDownload = (is_safari) ? startSafariFileDownload : startFilesDownload;
                     return publicApi.startFilesDownload(dc, ds, paths, isStreamFormat);
                  },
                  startFilesDownloadAsZip: startFilesDownloadAsZip
               };

               return publicApi;

               function startFilesDownloadAsZip (datacenterRef, datastoreId,
                     datastorePaths, fileName, baseDatastorePath, isStreamFormat) {

                  var isMultipleFilesDownloadSupported = 'datastore:isMultipleFilesDownloadSupported';
                  dataService.getProperties(datastoreId, isMultipleFilesDownloadSupported).then(function(resp) {
                     if (resp[isMultipleFilesDownloadSupported]) {
                        checkBlockingPopUp();
                        var downloadSpec = {
                           vcServerGuid: datacenterRef.serverGuid,
                           resultFileName: fileName,
                           datacenterId: defaultUriSchemeUtil.getVsphereObjectId(datacenterRef),
                           datastoreId: datastoreId,
                           datastorePath: datastorePaths,
                           baseDatastorePath: baseDatastorePath
                        };

                        if (isStreamFormat) {
                           downloadSpec.diskFormat = STREAM_VMDK;
                        }

                        var downloadParams = $httpParamSerializer(downloadSpec);

                        openDownloadTab(downloadParams);
                     } else {
                        storageUtil.showErrorDialog(i18nService.getString("StorageUi", "fileDownload.error.noEndpointsError"));
                     }
                  });
               }

               function startFilesDownload (datacenterRef, datastoreId, datastorePaths,
                     isStreamFormat) {
                  fileUrlResolverService.resolveFileUrls(datacenterRef, datastoreId,
                        datastorePaths, isStreamFormat).then(function (data) {
                     if(!data || !data.fileTransferEndpoints){
                        storageUtil.showErrorDialog(i18nService.getString("StorageUi", "fileDownload.error.noEndpointsError"));
                     } else {
                        data.fileTransferEndpoints.forEach(function (endpoint) {
                           checkBlockingPopUp();
                           // The ticket is used to authenticate the request against
                           // another back-end (e.g. host). When there is no ticket,
                           // we send the request to our own servlet container and let
                           // it decide how to handle it (e.g. if it wants to proxy it).
                           if (endpoint.ticket) {
                              _downloadFile(endpoint);
                           } else {
                              var downloadParams = buildDownloadParams(datacenterRef, datastoreId, datastorePaths, isStreamFormat);
                              openDownloadTab(downloadParams);
                           }
                        });
                     }
                  });
               }

               function _downloadFile (endpoint) {
                  // create a form with the vmware_cgi_ticket field and target='_blank'
                  var form = createFileDownloadForm($window, endpoint);
                  try {
                     form.submit();
                  } finally {
                     // IE does not support element.remove(). Wrap it in angular element
                     angular.element(form).remove();
                  }
               }

               function createFileDownloadForm (window, endpoint, target) {
                  var form = window.document.createElement("form");
                  form.action = endpoint.url;
                  form.target = target || "_blank";
                  form.method = "get";

                  if (endpoint.ticket) {
                     // Post the vmware_cgi_ticket
                     form.method = "post";
                     var ticketInput = window.document.createElement("input");
                     ticketInput.type = "hidden";
                     ticketInput.name = "vmware_cgi_ticket";
                     ticketInput.value = endpoint.ticket;
                     form.appendChild(ticketInput);
                  }

                  window.document.body.appendChild(form);
                  return form;
               }

               function startSafariFileDownload (datacenterRef, datastoreId,
                     datastorePaths, isStreamFormat) {
                  // Safari's built in popup blocker blocks window open
                  // if it was called in an async operation callback.
                  //
                  // It allows opening window only in the context of an
                  // user click action.
                  // So we open the window upfront, resolve the file download URL and
                  // change the location of the window or create and submit a form in the
                  // new window with the vmware_cgi_ticket ticket.
                  var windowHandle = [];
                  datastorePaths.forEach(function () {
                     windowHandle.push($window.open('about:blank', '_blank'));
                  });

                  fileUrlResolverService.resolveFileUrls(datacenterRef, datastoreId,
                        datastorePaths, isStreamFormat)
                        .then(function (data) {
                           // Open the file URL in a new window to trigger download.
                           data.fileTransferEndpoints.forEach(function (endpoint, index) {
                              if (endpoint.ticket) {
                                 // Create a form with the vmware_cgi_ticket field
                                 // Use target self for safari otherwise the popup blocker wont open it.
                                 var form = createFileDownloadForm(windowHandle[index], endpoint, "_self");
                                 form.submit();
                              } else {
                                 var downloadParams = buildDownloadParams(datacenterRef, datastoreId, datastorePaths, isStreamFormat);
                                 windowHandle[index].location.href = DOWNLOAD_FILES_AS_ZIP_URL + "?" + downloadParams;
                              }
                           });
                        });
               }

               function requestFileDownloadToken(downloadParams) {
                  var headers = { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' };
                  headers[h5.xsrfCookieName] = $cookies.get(h5.xsrfCookieName);
                  downloadParams += "&" + REQUEST_TOKEN_PARAM + "=true";
                  return $http({
                     method: "POST",
                     url: DOWNLOAD_FILES_AS_ZIP_URL,
                     data: downloadParams,
                     headers: headers
                  }).then(function(response) {
                     return response.data;
                  });
               }

               function isUrlLengthTooBig(url) {
                  return !browserService.isIe && url.length > BROWSER_URL_LIMIT
                     || browserService.isIe && url.length > IE_URL_LIMIT;
               }

               function appendSlashIfNeeded(string) {
                  var stringWithSlash = string;
                  if (string.lastIndexOf("/") !== string.length - 1) {
                     stringWithSlash += "/";
                  }
                  return stringWithSlash;
               }

               function checkBlockingPopUp () {
                  browserService.areBlockingPopupsAllowed().then(function(status) {
                     if (!status) {
                        var modalOptions = {
                           title: i18nService.getString('StorageUi', 'fileDownload.blockingPopup.title'),
                           hideCancelButton: true,
                           message: i18nService.getString('StorageUi', 'fileDownload.blockingPopup.message'),
                           icon: 'icon-warning-32',
                           submit: function () {
                              return true;
                           },
                           preserveNewlines: true
                        };
                        clarityModalService.openConfirmationModal(modalOptions);
                     }
                  });
               }

               function buildDownloadParams(datacenterRef, datastoreId, datastorePaths, isStreamFormat) {
                  var params = {
                     vcServerGuid: datacenterRef.serverGuid,
                     datacenterId: defaultUriSchemeUtil.getVsphereObjectId(datacenterRef),
                     datastoreId: datastoreId,
                     datastorePath: datastorePaths
                  };
                  if (isStreamFormat) {
                     params.diskFormat = STREAM_VMDK;
                  }
                  return $httpParamSerializer(params);
               }

               function openDownloadTab(downloadParams) {
                  var url = appendSlashIfNeeded($window.location.href) +
                        DOWNLOAD_FILES_AS_ZIP_URL + "?" + downloadParams;
                  if (isUrlLengthTooBig(url)) {
                     // Pass the parameters via POST instead of GET, get
                     // the token from the response and use it to download
                     // the zip produced with the parameters.
                     requestFileDownloadToken(downloadParams)
                           .then(function(token) {
                              var tokenMap = {};
                              tokenMap[TOKEN_PARAM] = token;
                              var tokenParams = $httpParamSerializer(tokenMap);
                              $window.open(DOWNLOAD_FILES_AS_ZIP_URL + "?" + tokenParams, "_blank");
                           });
                     return;
                  }

                  $window.open(DOWNLOAD_FILES_AS_ZIP_URL + "?" + downloadParams, "_blank");
               }
            }
         ]);
})();
