module ds_cluster_ui {

   import IComponentController = angular.IComponentController;

   class DsClusterResourcesPortletController implements IComponentController {

      public static $inject = ["dataService", "$rootScope", "dsClusterServicesAndResourcesService",
            "i18nService", "dsClusterConstants", "numberFormatterService"];

      public objectId: string;
      public resourcesData: Object;
      public usedPercentage: number;
      public usedPercentageLabel: string;
      public totalResourcesLabel: string;
      public minResourcesLabel: string;
      public liveRefreshProperties: Array<string>;

      constructor(private dataService: any, private $rootScope: RootScopeRoute,
            private dsClusterServicesAndResourcesService: DsClusterServicesAndResourcesService,
            private i18nService: any, private dsClusterConstants: any,
            private numberFormatterService: any) {
         this.objectId = this.$rootScope._route.objectId;
         this.liveRefreshProperties =
               [this.dsClusterConstants.liveRefreshProperties.SUMMARY];
         this.getData();
      }

      public getData(): void {
         this.dataService.getData(this.objectId,
               "com.vmware.vsphere.client.dsclusterui.model.DsClusterResourcesData")
               .then((data: any) => {
                  this.resourcesData = this.dsClusterServicesAndResourcesService
                        .buildResourcesPropertyViewData(data);
                  this.usedPercentage = data.usedSpacePercentage;
                  let formattedUserPercentage: any =
                        this.numberFormatterService.format(data.usedSpacePercentage);
                  this.usedPercentageLabel = this.i18nService.getString(
                        "DsClusterUi",
                        "sdrs.config.utilizedSpaceThreshold.format",
                        formattedUserPercentage);
                  this.minResourcesLabel = this.dsClusterServicesAndResourcesService
                        .getMinCapacityLabel(data.formattedCapacity);
                  this.totalResourcesLabel = data.formattedCapacity;
               });
      }
   }

   angular.module("com.vmware.vsphere.client.dsCluster")
         .controller("DsClusterResourcesPortletController",
               DsClusterResourcesPortletController);
}
