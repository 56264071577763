namespace h5_vm {
   import GosType = h5_vm.GosType;
   import Specification = com.vmware.vim.binding.vim.vm.customization.Specification;
   import VmCustomizationEditSpec = com.vmware.vsphere.client.vm.customizations.VmCustomizationEditSpec;

   export class EditGuestOsCustomizationSpecDialogService {

      public static $inject = ["gosCustomizationHelperService",
         "gosSpecCreatePagesService",
         "gosSpecModelManagerService",
         "vxMultiPageDialogService",
         "defaultUriSchemeUtil",
         "$rootScope",
         "i18nService",
         "vuiConstants",
         "mutationService",
         "$q"];

      constructor(private gosCustomizationHelperService: GosCustomizationHelperService,
            private gosSpecCreatePagesService: GosSpecCreatePagesService,
            private gosSpecModelManagerService: GosSpecModelManagerService,
            private vxMultiPageDialogService: any,
            private defaultUriSchemeUtil: any,
            private $rootScope: any,
            private i18nService: any,
            private vuiConstants: any,
            private mutationService: any,
            private $q: any) {
      }

      private dialogScope: any;
      private specInfo: CustomizationSpecInfoCustom;
      private specDetails: Specification;
      private gosType: GosType = GosType.NONE;
      private onActionInvoked: Function;

      open(context: DialogContext) {
         this.specInfo = context.specInfo;
         this.specDetails = context.specDetails;
         this.onActionInvoked = context.onActionInvoked;
         this.gosType = this.gosCustomizationHelperService
               .getGosType(context.specDetails.identity);

         this.dialogScope = this.$rootScope.$new();
         this.dialogScope.dialogConfig = {};
         this.dialogScope.allSpecs = context.allSpecs;

         let specName: string = this.specInfo.name;

         _.extend(this.dialogScope.dialogConfig, {
            title: this.i18nService.getString("VmUi", "editSpecWizard.title", specName),
            iconClass: context.iconClass,
            pages: this.gosSpecCreatePagesService.getDialogPages(
                  this.dialogScope, this.gosType, this.onPageCommit.bind(this)),
            show: true,
            loading: false,
            loadingMessage: this.i18nService.getString("CommonUi", "wizard.loading"),
            confirmOptions: {
               onClick: this.onDialogComplete.bind(this)
            },
            height: "720px",
            resizable: true,
            draggable: true,
            maximizable: true
         });

         let options = {
            scope: this.dialogScope,
            configObjectName: "dialogConfig"
         };

         this.gosSpecModelManagerService.populatePageModels(this.dialogScope,
               context.specInfo, context.specDetails);
         this.vxMultiPageDialogService(options);
      }

      private onDialogComplete(): boolean {

         let finishPageModel: GosFinishPageModel =
               this.dialogScope.finishPageModel as GosFinishPageModel;

         let editSpec: VmCustomizationEditSpec = new VmCustomizationEditSpec();
         editSpec.config = finishPageModel.buildSpec();
         finishPageModel.addWindowsParams(editSpec);
         editSpec.customizationSpecManager = finishPageModel.customizationSpecManager;
         editSpec.oldName = this.specInfo.name;
         editSpec.newName = editSpec.config.info.name;
         editSpec.isSpecModified = true;
         let customizationSpecManagerId: string = this.defaultUriSchemeUtil
               .getVsphereObjectId(editSpec.customizationSpecManager);
         let operation: string = this.i18nService
               .getString("VmUi", "customizationSpec.editSpecOperationName");
         this.mutationService.apply(customizationSpecManagerId, editSpec._type, editSpec, operation)
               .then((opResult: any) => {
                  this.onActionInvoked(
                        "vsphere.core.vm.gos.customization.edit",
                        opResult);
               });

         return true;
      }

      private onPageCommit(pageModel: GosBasePageModel): angular.IPromise<boolean> {
         let pageCommitPromise = pageModel.onPageCommit
               ? pageModel.onPageCommit()
               : this.$q.resolve([]);
         return pageCommitPromise.then((res: Array<string>) => {
            this.clearValidationMessages();

            if (!res) {
               return false;
            }
            if (res && res.length > 0) {
               this.showValidationErrors(res);
               return false;
            }

            this.gosSpecModelManagerService.updateDependentModels(this.dialogScope, pageModel);
            return true;
         });
      }

      private showValidationErrors(errors: Array<string>): void {
         if (!errors) {
            return;
         }

         let validationMessages: Array<any> = errors.map((error: String): any => {
            return {
               text: error,
               type: this.vuiConstants.validationBanner.type.ERROR
            };
         });
         this.dialogScope.dialogConfig.validationBanner.messages = validationMessages;
      }

      private clearValidationMessages(): void {
         this.dialogScope.dialogConfig.validationBanner.messages = [];
      }

   }

   angular.module("com.vmware.vsphere.client.vm")
         .service("editGuestOsCustomizationSpecDialogService",
               EditGuestOsCustomizationSpecDialogService);
}
