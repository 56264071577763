namespace h5_vm {
   import IController = angular.IComponentController;

   class CloneVmLibraryItemController implements IController {

      static $inject: string[] = [ "$scope", "i18nService" ];

      public libraryItemData: any;
      public i18n: any;

      constructor(private $scope: any, private i18nService: any) {
         this.i18n = i18nService.getString;
         this.libraryItemData = {
            vmId: $scope.wizardViewData.getSourceObjectId(),
            selectedLibraryItemId: this.$scope.cloneVmLibraryItemPageModel.contentLibraryItem &&
                    this.$scope.cloneVmLibraryItemPageModel.contentLibraryItem.id
         };
      }

      public onSelectedLibraryItemChange(selectedLibraryItem: any) {
         this.$scope.vuiWizard.validationBanner.messages = [];
         this.$scope.cloneVmLibraryItemPageModel.contentLibraryItem = selectedLibraryItem;
      }
   }

   angular.module("com.vmware.vsphere.client.vm")
         .controller("cloneVmLibraryItemController", CloneVmLibraryItemController);
}
