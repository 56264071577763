namespace h5_client {
   export module common_module_ui {

      class CreateContentLibraryConfigureVapiPageService {
         static $inject = ['i18nService'];

         constructor(private i18nService: any) {}

         build() {
            return {
               title: this.i18nService.getString('LibraryUi', 'createLibraryWizard.configurePage.title'),
               description: this.i18nService.getString('LibraryUi', 'createLibraryWizard.configurePage.description'),
               contentUrl: 'common-module-ui/resources/common-module/views/content-library/wizard/pages/vapi/createContentLibraryConfigureVapi.html'
            };
         }
      }
      angular.module("com.vmware.vsphere.client.commonModule")
            .service("createContentLibraryConfigureVapiPageService", CreateContentLibraryConfigureVapiPageService);
   }
}