/* Copyright 2015 VMware, Inc. All rights reserved. -- VMware Confidential */

(function () {
   'use strict';
   angular.module('com.vmware.vsphere.client.cluster')
         .controller('CreateResourcePoolController', [
            '$scope', 'dataService', 'managedEntityMutationService',
            'i18nService', 'vuiConstants', 'mutationService',
            'defaultUriSchemeUtil', 'managedEntityConstants', '$q',
            'createResourcePoolService', 'bytesFilter', 'cpuClockFilter',
            'resourcePoolManagementService',
            function ($scope, dataService, managedEntityMutationService,
                      i18nService, vuiConstants, mutationService,
                      defaultUriSchemeUtil, managedEntityConstants, $q,
                      createResourcePoolService, bytesFilter, cpuClockFilter,
                      resourcePoolManagementService) {

               var self = this;
               var parentObjectId;
               var parentMoRef;
               var defaultNewRpName = i18nService.getString('RpUi',
                     'actionCreateRPDefaultName');
               var unlimitedLabel = i18nService.getString('RpUi', 'labelUnlimited');

               var resourcePoolManagementServiceInstance = resourcePoolManagementService
                     .createInstance();

               self.error = false;

               // CPU reservation defaults.
               self.cpuReservationOptions = resourcePoolManagementServiceInstance
                     .getCpuDropdownOptions();
               self.cpuUnitOptions = resourcePoolManagementServiceInstance
                     .getCpuUnitOptions();

               // CPU limit defaults
               self.cpuLimitOptions = resourcePoolManagementServiceInstance
                     .getCpuLimitOptions();

               // Memory reservation defaults.
               self.memoryReservationOptions = resourcePoolManagementServiceInstance
                     .getMemoryDropdownOptions();
               self.memoryUnitOptions = resourcePoolManagementServiceInstance
                     .getMemoryUnitOptions();

               // Memory limit defaults.
               self.memoryLimitOptions = resourcePoolManagementServiceInstance
                     .getMemoryLimitOptions();

               self.cpuReservationValueErrorMessages = {
                  min: cpuReservationRangeErrorMessage,
                  max: cpuReservationRangeErrorMessage
               };

               self.memoryReservationValueErrorMessages = {
                  min: memoryReservationRangeErrorMessage,
                  max: memoryReservationRangeErrorMessage
               };

               self.cpuLimitValueErrorMessages = {
                  min: cpuLimitRangeErrorMessage,
                  max: cpuLimitRangeErrorMessage
               };

               self.memoryLimitValueErrorMessages = {
                  min: memoryLimitRangeErrorMessage,
                  max: memoryLimitRangeErrorMessage
               };

               // Format limits.
               _.each(self.cpuReservationOptions, cpuFormatter);
               _.each(self.cpuLimitOptions, cpuFormatter);
               _.each(self.memoryReservationOptions, memoryFormatter);
               _.each(self.memoryLimitOptions, memoryFormatter);

               parentObjectId = $scope.modalOptions.dialogData.objectId;
               parentMoRef = defaultUriSchemeUtil.getManagedObjectReference(
                     parentObjectId
               );

               self.spec = createResourcePoolService.createDefaultResourcePoolSpec();
               self.spec.name = defaultNewRpName;

               $scope.modalOptions.onSubmit = onSubmit;

               getViewData();

               function cpuReservationRangeErrorMessage() {
                  return i18nService.getString('RpUi', 'Util.Validation.ValidRange',
                        i18nService.getString('RpUi', 'RpCpu.Label.Reservation'),
                        i18nService.getString('RpUi', 'cpuValue', 0),
                        i18nService.getString('RpUi', 'cpuValue',
                              self.cpuReservationOptions.maximum.value)
                  );
               }

               function memoryReservationRangeErrorMessage() {
                  return i18nService.getString('RpUi', 'Util.Validation.ValidRange',
                        i18nService.getString('RpUi', 'RpMemory.Label.Reservation'),
                        i18nService.getString('RpUi', 'memValue', 0),
                        i18nService.getString('RpUi', 'memValue',
                              self.memoryReservationOptions.maximum.value)
                  );
               }

               function cpuLimitRangeErrorMessage() {
                  return i18nService.getString('RpUi', 'Util.Validation.ValidRange',
                        i18nService.getString('RpUi', 'settings.limit'),
                        i18nService.getString('RpUi', 'cpuValue',
                              self.cpuLimitOptions.minimum.value),
                        i18nService.getString('RpUi', 'cpuValue',
                              self.cpuLimitOptions.maximum.value)
                  );
               }

               function memoryLimitRangeErrorMessage() {
                  return i18nService.getString('RpUi', 'Util.Validation.ValidRange',
                        i18nService.getString('RpUi', 'settings.limit'),
                        i18nService.getString('RpUi', 'memValue',
                              self.memoryLimitOptions.minimum.value),
                        i18nService.getString('RpUi', 'memValue',
                              self.memoryLimitOptions.maximum.value)
                  );
               }

               function cpuFormatter(element) {
                  element.formattedValue = cpuClockFilter(element.value,
                        i18nService.getString('RpUi', 'rpCpu.MHz'));
               }

               function memoryFormatter(element) {
                  element.formattedValue = (element.value === -1) ?
                        unlimitedLabel : bytesFilter(element.value, 'MB');
               }

               function setPageBusy(isBusy) {
                  $scope.modalOptions.submitDisabled = isBusy;
                  self.loading = isBusy;
               }

               function showValidationMessages(messages) {
                  var validationErrorMessages = _.map(messages, function (message) {
                     return {
                        type: vuiConstants.validationBanner.type.ERROR,
                        text: message
                     };
                  });
                  $scope.modalOptions.alerts = validationErrorMessages;
               }

               function getViewData() {
                  setPageBusy(true);

                  var realParentMoRefPromise;
                  if (parentMoRef.type === managedEntityConstants.CLUSTER) {
                     realParentMoRefPromise =
                           dataService.getProperties(parentObjectId, ['resourcePool'])
                                 .then(function (data) {
                                    return data.resourcePool;
                                 });
                  } else if (parentMoRef.type === managedEntityConstants.HOST) {
                     realParentMoRefPromise =
                           dataService.getPropertiesByRelation(
                                 parentObjectId,
                                 'host',
                                 managedEntityConstants.COMPUTE_RESOURCE,
                                 ['resourcePool']
                           ).then(function (data) {
                              return _.values(data)[0].resourcePool;
                           });
                  } else if (parentMoRef.type === managedEntityConstants.RESOURCE_POOL ||
                        parentMoRef.type === managedEntityConstants.V_APP) {
                     realParentMoRefPromise = $q.when(parentMoRef);
                  } else {
                     realParentMoRefPromise = $q.reject();
                  }

                  realParentMoRefPromise
                        .then(function (realParentMoRef) {
                           self.spec.parent = realParentMoRef;
                           var resourcePoolConfigDataUri =
                                 defaultUriSchemeUtil.createVmomiUri(
                                       self.spec.parent.type,
                                       self.spec.parent.value,
                                       self.spec.parent.serverGuid);

                           return dataService.getData(
                                 resourcePoolConfigDataUri,
                                 'com.vmware.vsphere.client.clusterui.model.ResourcePoolConfigData'
                           );
                        })
                        .then(function (data) {
                           if (!data) {
                              setPageBusy(false);
                              return null;
                           }

                           // max cpu allowed reservation
                           resourcePoolManagementServiceInstance.setCpuRuntimeInfo(
                                 data.runtimeInfo.cpu);
                           self.cpuReservationOptions = resourcePoolManagementServiceInstance
                                 .getCpuDropdownOptions();

                           // max cpu limit
                           resourcePoolManagementServiceInstance.setCpuLimitInfo(
                                 data.runtimeInfo.cpu);
                           self.cpuLimitOptions = resourcePoolManagementServiceInstance
                                 .getCpuLimitOptions();

                           // max memory allowed reservation
                           resourcePoolManagementServiceInstance.setMemoryRuntimeInfo(
                                 data.runtimeInfo.memory);
                           self.memoryReservationOptions =
                                 resourcePoolManagementServiceInstance
                                       .getMemoryDropdownOptions();

                           // max memory limit
                           resourcePoolManagementServiceInstance.setMemoryLimitInfo(
                                 data.runtimeInfo.memory);
                           self.memoryLimitOptions =
                                 resourcePoolManagementServiceInstance.getMemoryLimitOptions();

                           // format limits
                           _.each(self.cpuReservationOptions, cpuFormatter);
                           _.each(self.cpuLimitOptions, cpuFormatter);
                           _.each(self.memoryReservationOptions, memoryFormatter);
                           _.each(self.memoryLimitOptions, memoryFormatter);
                           setPageBusy(false);
                        })
                        .catch(function () {
                           self.error = true;
                           setPageBusy(false);
                        });
               }

               function validate() {
                  if (!self.spec.name || (/^\s*$/gi).test(self.spec.name)) {
                     showValidationMessages(
                           [i18nService.getString('CommonModuleUi',
                                 'NameValidator.emptyName')]);
                     return $q.reject();
                  }

                  if ($scope.createResourcePoolForm.$invalid || self.error) {
                     return $q.reject();
                  }

                  var createRpNameSpec = createResourcePoolService
                        .createCreateResourcePoolNameSpec(self.spec.name);

                  var validationPromise =
                        mutationService.validate(
                              parentObjectId,
                              'com.vmware.vsphere.client.h5.rp.CreateResourcePoolNameSpec',
                              createRpNameSpec
                        ).then(function (validationResult) {
                           if (validationResult && validationResult.result) {
                              showValidationMessages([validationResult.result]);

                              return $q.reject();
                           } else {
                              return $q.resolve();
                           }
                        });

                  return validationPromise;
               }

               function onSubmit() {
                  if (self.loading) {
                     return false;
                  }

                  $scope.modalOptions.alerts = [];

                  setPageBusy(true);
                  validate()
                        .then(function () {
                           mutationService.add(
                                 'com.vmware.vsphere.client.rp.ResourcePoolSpec',
                                 self.spec,
                                 i18nService.getString('RpUi', "actionCreateRPLabel")
                           );
                           setPageBusy(false);
                           $scope.closeModal();
                        })
                        .catch(function (error) {
                           setPageBusy(false);
                           $q.reject(error);
                        });
                  //manually close the dialog when the validation promise resolves
                  //always return false, so the clarity modal service doesn't close it
                  return false;
               }
            }
         ]);
})();
