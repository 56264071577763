/* Copyright 2015 VMware, Inc. All rights reserved. -- VMware Confidential */
/*
 * Snapshot service
 */
(function() {
   'use strict';

   angular
         .module('com.vmware.vsphere.client.vm')
         .service('snapshotService', snapshotService);

   snapshotService.$inject = ['mutationService',
      'dataService',
      'defaultUriSchemeUtil',
      'i18nService',
      'clarityModalService',
      'authorizationService',
      'managedByMessageBuilderService',
      'actionConfirmationService','actionsService', '$q'];

   function snapshotService(mutationService, dataService, defaultUriSchemeUtil, i18nService,
         clarityModalService, authorizationService, managedByMessageBuilderService, actionConfirmationService,
         actionsService, $q) {

      var MUTATION_SNAPSHOT_SPEC = 'com.vmware.vsphere.client.vm.snapshot.VmSnapshotSpec';
      var MUTATION_SNAPSHOT_REMOVAL_SPEC = 'com.vmware.vsphere.client.vm.snapshot.VmSnapshotRemovalSpec';
      var MUTATION_SNAPSHOT_CONSOLIDATE_SPEC = 'com.vmware.vsphere.client.vm.snapshot.VmSnapshotConsolidateSpec';
      var MUTATION_SNAPSHOT_REVERT_SPEC = 'com.vmware.vsphere.client.vm.snapshot.VmSnapshotRevertSpec';
      var MUTATION_SNAPSHOT_EDIT_SPEC = 'com.vmware.vsphere.client.vm.snapshot.VmSnapshotEditSpec';
      var MUTATION_SNAPSHOT_REMOVE_ALL_SPEC = 'com.vmware.vsphere.client.vm.snapshot.VmSnapshotRemoveAllSpec';
      const POWER_STATE = h5_vm.PowerState;

      function takeSnapshot(vmId, snapshotName, snapshotDescription, includeMemory, quiesce) {
         var vmSnapshotSpec = {
            name: snapshotName,
            description: snapshotDescription,
            vm: defaultUriSchemeUtil.getManagedObjectReference(vmId),
            memory: includeMemory,
            quiesce: quiesce
         };
         mutationService.add(MUTATION_SNAPSHOT_SPEC, vmSnapshotSpec);
      }

      function getSnapshotsForVM(id, getManagedByInfo) {
         var properties = ['name', 'snapshot', 'diskUsage'];
         if(getManagedByInfo) {
            properties.push("managedByInfo");
         }
         return dataService.getProperties(id, properties);
      }

      function getCapabilitiesForVM(id) {
         return dataService.getProperties(id, ['capability']);
      }

      function getPrivilegesForVM(id) {
         var reqPrivileges = [h5_vm.Privileges.VM_REMOVE_SNAPSHOT, h5_vm.Privileges.VM_REVERT_SNAPSHOT, h5_vm.Privileges.VM_RENAME_SNAPSHOT];
         return authorizationService.checkPrivileges(id, reqPrivileges);
      }

      function removeSnapshot(snapshotId) {
         var vmSnapshotRemovalSpec = {
            removeChildren: false,
            consolidate: true
         };
         return mutationService.remove(snapshotId, MUTATION_SNAPSHOT_REMOVAL_SPEC, vmSnapshotRemovalSpec);
      }

      function removeAllSnapshots(vmId) {
         return mutationService.remove(vmId, MUTATION_SNAPSHOT_REMOVE_ALL_SPEC, {});
      }

      function updateSnapshot(vmId, snapshotId, name, description) {
         var vmSnapshotEditSpec = {
            name: name,
            description: description,
            vm: defaultUriSchemeUtil.getManagedObjectReference(vmId)
         };
         mutationService.apply(snapshotId, MUTATION_SNAPSHOT_EDIT_SPEC, vmSnapshotEditSpec);
      }

      function revertToCurrentSnapshot(vmId) {
         applySnapshotRevert(vmId);
      }

      function revertToSnapshot(snapshotMOR) {
         return applySnapshotRevert(defaultUriSchemeUtil.getVsphereObjectId(snapshotMOR));
      }

      function applySnapshotRevert(objectUri) {
         var vmSnapshotRevertSpec = {
            suppressPowerOn: false
         };
         return mutationService.apply(objectUri, MUTATION_SNAPSHOT_REVERT_SPEC, vmSnapshotRevertSpec);
      }

      function onRevertToActionMenuClicked(vmId, data) {
         var snapshotName = getLastSnapshotName(data.snapshot.rootSnapshotList[0]);
         var propertiesPerObject = {};
         propertiesPerObject[vmId] = data;
         var yesHandler = function() {
            applySnapshotRevert(vmId);
         };

         var titleText = i18nService.getString(
               'vmuiSnapshot', 'SnapshotActionCommand.ConfirmRevert');
         var messageText = i18nService.getString(
               'vmuiSnapshot', 'SnapshotActionCommand.RevertToSnapshot', snapshotName);

         managedByMessageBuilderService.buildWarningMessage([vmId], false, propertiesPerObject)
               .then(function(managedByInfoMessage) {
                  if (managedByInfoMessage) {
                     messageText = managedByInfoMessage + "\n\n" + messageText;
                  }
                  var dialogProperties = buildDialogOptions(yesHandler, messageText, titleText, data.name);
                  actionConfirmationService.showConfirmationDialog(dialogProperties);
               });
      }

      function getLastSnapshotName(root) {
         if (root.childSnapshotList === null || !root.childSnapshotList) {
            return root.name;
         }

         return getLastSnapshotName(root.childSnapshotList[0]);
      }

      function consolidateSnapshots(vmId) {
         return mutationService.apply(vmId, MUTATION_SNAPSHOT_CONSOLIDATE_SPEC, {});
      }

      function openTakeSnapshotDialog(availableTargets, actionEval) {
         var vmId = availableTargets[0];
         var actionPromise = actionsService.getActions(vmId, ['vsphere.core.vm.actions.installToolsAction']);
         var dataServicePromise = dataService.getProperties(vmId, ["powerState", "capability", "name"]);

         return $q.all([actionPromise, dataServicePromise])
               .then(function(responses) {
                  var state = responses[1];
                  var vmToolsInstalledNeeded = responses[0][0].available;

                  clarityModalService.openModal(
                        actionEval,
                        availableTargets,
                        {
                           objectId: vmId,
                           powerState: state.powerState,
                           memorySupported: state.capability.memorySnapshotsSupported,
                           quiesceSupported: state.capability.quiescedSnapshotsSupported,
                           diskOnlySnapshotOnSuspendedVMSupported: state.capability.diskOnlySnapshotOnSuspendedVMSupported,
                           vmToolsInstalledNeeded: vmToolsInstalledNeeded,
                           secondaryTitle: state.name
                        },
                        "vm-ui/resources/vm/views/snapshots/snapshotDialog.html"
                  );
               });
      }

      function openManageSnapshotDialog(availableTargets, actionEval) {
         clarityModalService.openModal(actionEval, availableTargets, undefined,
               "vm-ui/resources/vm/views/snapshots/vmManageSnapshots.html");
      }

      function openConsolidateSnapshotDialog(availableTargets) {
         var vmId = availableTargets[0];
         var yesHandler = function() {
            consolidateSnapshots(vmId);
         };
         var titleText = i18nService.getString(
               'vmuiSnapshot', 'SnapshotActionCommand.ConfirmConsolidate');
         var messageText = i18nService.getString(
               'vmuiSnapshot', 'SnapshotActionCommand.Consolidate.All');

         managedByMessageBuilderService.buildWarningMessage([vmId], false)
               .then(function(managedByInfoMessage) {
                  if (managedByInfoMessage) {
                     messageText = managedByInfoMessage + "\n\n" + messageText;
                  }
                  var dialogProperties = buildDialogOptions(yesHandler, messageText, titleText, "", vmId);
                  actionConfirmationService.showConfirmationDialog(dialogProperties);
               });
      }

      function openRemoveAllSnapshotsConfirmationDialog(vmId) {
         var removeAllAlertOptions = {
            title: i18nService.getString('vmuiSnapshot', 'SnapshotActionCommand.ConfirmDeleteAll'),
            message: i18nService.getString('vmuiSnapshot', 'SnapshotActionCommand.DeleteSnapshotForVM'),
            showXIcon: false,
            submit: function() { removeAllSnapshots(vmId); }
         };
         clarityModalService.openConfirmationModal(removeAllAlertOptions);
      }

      function buildDialogOptions(yesHandler, messageText, titleText, name, vmId) {
         var subTitle = {};
         if (vmId) {
            subTitle.objectId = vmId;
         } else if (name) {
            subTitle.text = name;
         }
         var alertProperties = {
            title: titleText,
            message: messageText,
            yesHandler: yesHandler,
            useClarity: true,
            icon: null,
            clarityIcon: {
               shape: "warning",
               class: "is-warning",
               size: 48
            },
            showXIcon: false
         };

         if (!_.isEmpty(subTitle)) {
            alertProperties.subTitle = subTitle;
         }

         return alertProperties;
      }

      function isSnapshotTakenWithMemoryByDefault(dialogData) {
         return (dialogData.powerState !== POWER_STATE.POWERED_OFF);
      }

      function isTakingSnapshotWithMemoryDisabledForEditing(dialogData) {
         return dialogData.powerState === POWER_STATE.POWERED_OFF
               || !dialogData.memorySupported
               || (dialogData.powerState === POWER_STATE.SUSPENDED && !dialogData.diskOnlySnapshotOnSuspendedVMSupported);
      }

      function isQuiesceEnabled(dialogData) {
         return dialogData.powerState === POWER_STATE.POWERED_ON && dialogData.quiesceSupported
            && !dialogData.vmToolsInstalledNeeded;
      }

      return {
         takeSnapshot: takeSnapshot,
         getSnapshotsForVM: getSnapshotsForVM,
         revertToCurrentSnapshot: revertToCurrentSnapshot,
         revertToSnapshot: revertToSnapshot,
         removeSnapshot: removeSnapshot,
         removeAllSnapshots: removeAllSnapshots,
         updateSnapshot: updateSnapshot,
         onRevertToActionMenuClicked: onRevertToActionMenuClicked,
         consolidateSnapshots: consolidateSnapshots,
         getPrivilegesForVM: getPrivilegesForVM,
         getCapabilitiesForVM: getCapabilitiesForVM,
         openTakeSnapshotDialog: openTakeSnapshotDialog,
         openManageSnapshotDialog: openManageSnapshotDialog,
         openConsolidateSnapshotDialog: openConsolidateSnapshotDialog,
         openRemoveAllSnapshotsConfirmationDialog: openRemoveAllSnapshotsConfirmationDialog,
         isQuiesceEnabled: isQuiesceEnabled,
         isTakingSnapshotWithMemoryDisabled: isTakingSnapshotWithMemoryDisabledForEditing,
         isSnapshotTakenWithMemoryByDefault: isSnapshotTakenWithMemoryByDefault
      };
   }
}());
