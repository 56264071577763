/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
(function () {
   'use strict';
   angular.module('com.vmware.vsphere.client.storage').factory('CommonWizardManager', [
      'i18nService',
      'dataService',
      '$q',
      'defaultUriSchemeUtil',
      'managedEntityConstants',
      'datastoreConstants',
      function (i18nService,
                dataService,
                $q,
                defaultUriSchemeUtil,
                managedEntityConstants,
                datastoreConstants) {
         return function CommonWizardManager(contextObject, wizardConfig) {

            var UNIQUE_DATASTORE_NAME = 'newUniqueDatastoreName';
            var AVAILABLE_HOSTS_PROPERTY = 'storage:availableHostsForCreateDatastore';

            var state = {
               datastoreName: null,
               datastoreType: null
            };

            var cache = {
               uniqueNamePromise: null,
               availableHosts: null
            };

            var manager = {
               getUniqueDatastoreName: function () {
                  if (!cache.uniqueNamePromise) {
                     cache.uniqueNamePromise = dataService.getProperties(contextObject, [UNIQUE_DATASTORE_NAME]).then(function (result) {
                        return result[UNIQUE_DATASTORE_NAME];
                     });
                  }

                  return cache.uniqueNamePromise;
               },
               prepareUniqueDatastoreName: function() {
                  this.getUniqueDatastoreName();
               },
               setDatastoreType: function (datastoreType) {
                  state.datastoreType = datastoreType;
               },
               getDatastoreType: function () {
                  return state.datastoreType?
                     state.datastoreType: datastoreConstants.datastoreTypes.VMFS;
               },
               setDatastoreName: function (datastoreName) {
                  state.datastoreName = datastoreName;
               },
               getDatastoreName: function () {
                  return state.datastoreName;
               },
               getContextObject: function() {
                  return contextObject;
               },
               isHostContextObject: function() {
                  return defaultUriSchemeUtil.getEntityType(contextObject) === managedEntityConstants.HOST;
               },
               getStorageFolder: function() {
                  if (defaultUriSchemeUtil.getEntityType(contextObject) === managedEntityConstants.FOLDER) {
                     return defaultUriSchemeUtil.getManagedObjectReference(contextObject);
                  }
                  return null;
               },
               getWizardConfig: function() {
                  return wizardConfig;
               },
               getAvailableHosts: function () {
                  if (cache.availableHosts) {
                     return $q.when(cache.availableHosts);
                  }

                  return dataService.getProperties(contextObject, [AVAILABLE_HOSTS_PROPERTY])
                        .then(function(result) {
                           var availableHosts = result
                                 ? result[AVAILABLE_HOSTS_PROPERTY] || []
                                 : [];
                           _.forEach(availableHosts, function(hostData) {
                              hostData.hostId =
                                    defaultUriSchemeUtil.getVsphereObjectId(hostData.hostRef);
                           });
                           cache.availableHosts = availableHosts;
                           return availableHosts;
                        });
               }
            };

            return manager;
         };
      }
   ]);
})();
