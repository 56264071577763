(function() {
   'use strict';

   /**
    * Host firewall connections grid controller.
    */
   angular.module('com.vmware.vsphere.client.host')
         .controller('EditFirewallSettingsController', EditFirewallSettingsController);

   EditFirewallSettingsController.$inject =
         ['$scope', 'i18nService', 'dataService', 'mutationService', 'editFirewallSettingsService'];

   function EditFirewallSettingsController($scope,
         i18nService, dataService,
         mutationService,
         editFirewallSettingsService) {
      var self = this;

      self.hostId = $scope.modalOptions.dialogData.availableTargets[0];

      self.firewallService = editFirewallSettingsService;
      self.filterPlaceholderText = i18nService.getString('Common', 'filter.label');
      self.searchTerm = '';
      self.dataGridOriginalData = [];
      self.filterSettings = filterSettings;
      $scope.datagridOptions = self.firewallService.getDataGridOptions();
      $scope.ipObject = {};
      self.getViewData = getViewData;
      $scope.modalOptions.onSubmit = submit;

      function getViewData() {
         setPageBusy(true);
         dataService
               .getData(
                     self.hostId,
                     'com.vmware.vsphere.client.h5.host.model.FirewallSettingsData')
               .then(function(data) {
                  self.originalData = data.connections;
                  self.modifiedData = _.map(data.connections, function(dataItem) {
                     return angular.merge({}, dataItem);
                  });
                  var gridData = _.map(self.modifiedData, function(dataItem) {
                     return angular.merge({
                        _rawItem: dataItem
                     }, dataItem);
                  });

                  self.dataGridOriginalData = gridData;
                  $scope.datagridOptions.data = gridData;
                  setPageBusy(false);
               });
      }

      $scope.$watch('ipObject.ipList', function(newData) {
         $scope.ipError = !self.firewallService.validateIpAddresses(newData);
      });

      $scope.$watch('datagridOptions.selectedItems', function(newData, oldData) {
         $scope.ipErrorShow = false;
         if (newData && newData.length === 1) {
            $scope.selectedDatagridItem = true;
            var filteredIpData = self.firewallService.removeInvalidData($scope.ipObject.ipList);
            if ($scope.ipError && !$scope.ipObject.allIp) {
               if (oldData) {
                  $scope.datagridOptions.selectedItems[0] = oldData[0];
                  blockSelectionOfDatagrid(oldData[0].uid);
                  $scope.invalidItems = filteredIpData.invalidItems;
                  $scope.ipErrorShow = true;
               }
            }
            else {
               if ($scope.ipError && $scope.ipObject.allIp) {
                  $scope.ipObject.ipList = filteredIpData.filteredItems;
               }
               if (oldData[0]) {
                  createModifiedObject(oldData[0], $scope.ipObject.ipList, $scope.ipObject.allIp);
               }

               $scope.datagridOptions.selectedItems[0] = newData[0];
               if (newData[0].ipList.toLowerCase() !== "all") {
                  $scope.ipObject.ipList = newData[0].ipList;
               } else {
                  $scope.ipObject.ipList =
                        self.firewallService.createIpListFromRawData(
                              newData[0].rawIpList
                        );
               }
               $scope.ipObject.allIp = newData[0].rawIpList.allIp;
            }

         } else {
            $scope.selectedDatagridItem = false;
         }
      });

      function blockSelectionOfDatagrid(uid) {
         $('[data-uid='+uid+'] td:nth-child(2) td:nth-child(2)').mousedown().mouseup();
         $('tbody[role="rowgroup"] tr').attr('aria-selected','false').removeClass('k-state-selected');
         $('[data-uid='+uid+']').attr('aria-selected','true').addClass('k-state-selected');
      }

      function createModifiedObject(oldData, ipList, allIp) {
         $scope.ipError = false;
         $scope.ipErrorShow = false;

         // If allIp checkbox is clicked we should remove the invalid data
         if (allIp) {
            if (oldData){
               // Remove the invalid addresses
               var validData = self.firewallService.removeInvalidData(ipList);
               var validIpList = validData ? validData.filteredItems : "";
               // Create the new valid raw ip list with the removed invalid data
               var rawValidIpAddresses = !_.isEmpty(validIpList) ? self.firewallService.createRawIpList(validIpList) : [];

               oldData.rawIpList.ipAddress = rawValidIpAddresses;
               oldData.ipList = validIpList;

               var validRawIpNetworks = !_.isEmpty(validIpList) ? self.firewallService.createRawNetworkList(validIpList) : [];
               oldData.rawIpList.ipNetwork = validRawIpNetworks;
               oldData.rawIpList.allIp = allIp;

               oldData._rawItem.rawIpList.allIp = allIp;
               oldData._rawItem.rawIpList.ipNetwork = validRawIpNetworks;
               oldData._rawItem.rawIpList.ipAddress = rawValidIpAddresses;
               oldData._rawItem.ipList = validIpList;
            }
            return true;
         } else {
            if (self.firewallService.validateIpAddresses(ipList)) {
               if (oldData){
                  var rawIpList = self.firewallService.createRawIpList(ipList);
                  oldData.rawIpList.ipAddress = rawIpList;
                  oldData.ipList = ipList;

                  var rawIpNetworks = self.firewallService.createRawNetworkList(ipList);
                  oldData.rawIpList.ipNetwork = rawIpNetworks;
                  oldData.rawIpList.allIp = allIp;

                  oldData._rawItem.rawIpList.allIp = allIp;
                  oldData._rawItem.rawIpList.ipNetwork = rawIpNetworks;
                  oldData._rawItem.rawIpList.ipAddress = rawIpList;
                  oldData._rawItem.ipList = ipList;

               }
               return true;
            } else {
               $scope.invalidItems = self.firewallService.removeInvalidData(ipList).invalidItems;
               $scope.ipError = true;
               $scope.ipErrorShow = true;
               return false;
            }
         }
      }

      function getString(key) {
         return i18nService.getString("HostUi", key);
      }

      function submit() {
         var isValid = createModifiedObject($scope.datagridOptions.selectedItems[0],
               $scope.ipObject.ipList,
               $scope.ipObject.allIp);
         if (!isValid) {
            return;
         }
         var spec = self.firewallService.createSpec(
               self.modifiedData, self.originalData);

         if (_.isEmpty(spec)) {
            return true;
         }

         mutationService.apply(
               self.hostId,
               'com.vmware.vsphere.client.h5.host.firewall.spec.FirewallConfigurationSpec',
               spec,
               getString('editFirewallSettingsDialog.applySecurityProfileOperationName')
         );
         return true;
      }

      function setPageBusy(isBusy) {
         $scope.modalOptions.submitDisabled = isBusy;
         self.loading = isBusy;
      }

      function filterSettings() {
         var propertiesToSearchInFor = ["serviceName", "serviceKey", "daemonStatus",
            "inboundDirectionInfo.combinedPorts", "outboundDirectionInfo.combinedPorts"];

         $scope.datagridOptions.data = self.firewallService.filterSettings(
               self.dataGridOriginalData,
               self.searchTerm,
               propertiesToSearchInFor);
      }

      getViewData();
   }
})
();
