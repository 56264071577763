namespace h5_vm {
   import VirtualPCI = h5_vm.VmHwPciDevice;
   import VirtualDevice = com.vmware.vim.binding.vim.vm.device.VirtualDevice;

   export class VmHwPciDeviceController implements ng.IComponentController {

      public inflatedDevice: VirtualPCI|null;
      public deviceLabel: string;
      static $inject: string[] = ["i18nService"];

      constructor(public i18nService: any) {
      }

      $onDestroy() {
         if (this.inflatedDevice) {
            this.inflatedDevice.cleanup();
         }
         this.inflatedDevice = null;
      }

      $onInit() {
         if (this.inflatedDevice) {
            this.deviceLabel = this.inflatedDevice.deviceLabel();
         } else {
            this.deviceLabel = "";
         }
      }

      public i18n(key: string): string {
         if (!this.i18nService) {
            return "";
         }

         const retVal: string = this.i18nService.getString("VmUi", "PciDevicePage." + key);
         return retVal;
      }

      removeDevice() {

         if (!this.inflatedDevice) {
            return;
         }

         if (!this.inflatedDevice.canRemove()) {
            return;
         }

         this.inflatedDevice.removeDevice();
      }

      revertRemoval() {
         if (this.inflatedDevice) {
            this.inflatedDevice.revertRemoval();
         }
      }

      public isMarkedForRemoval(): boolean {
         if (!this.inflatedDevice) {
            return false;
         }

         return this.inflatedDevice.isMarkedForRemoval();
      }

      private isRemoveDisabled(): boolean | null {
         if (!this.inflatedDevice) {
            return true;
         }
         return !this.inflatedDevice.canRemove();
      }

      public deviceList(): Array<any> {
         if (!this.inflatedDevice) {
            return [];
         }

         try {
            const retVal: Array<any> = this.inflatedDevice.deviceList();
            return retVal;
         } catch (err) {
            return [];
         }
      }

      public get deviceValue(): any {
         if (!this.inflatedDevice) {
            return null;
         }
         return this.inflatedDevice.deviceValue;
      }

      public set deviceValue(value: any) {
         if (!this.inflatedDevice) {
            return;
         }
         this.inflatedDevice.deviceValue = value;
      }

      public isEditDeviceValueDisabled(): boolean {

         if (!this.inflatedDevice) {
            return true;
         }

         let retVal:boolean = this.inflatedDevice.deviceValue_isReadonly();
         return retVal;

      }

      public showMemorySection(): boolean {
         if (!this.inflatedDevice) {
            return false;
         }
         const retVal: boolean = this.inflatedDevice.memoryUnderReserved();
         return retVal;
      }

      public showDeviceWarning(): boolean {
         return this.showMemorySection();
      }

      public reserveAllMemory(ev: any): void {
         if (!this.inflatedDevice) {
            return;
         }
         this.inflatedDevice.reserveAllMemory();
      }
   }

   class VmHwPciDeviceComponent implements ng.IComponentOptions {

      constructor(public bindings: any = {inflatedDevice: '<'},
                  public controller: any = VmHwPciDeviceController,
                  public templateUrl: string = 'vm-ui/resources/vm/views/settings/vmHardwareSettings/vmHardwarePciDevice/vm-hw-pci-device.html') {
      }
   }
   angular.module('com.vmware.vsphere.client.vm').component('vmHwPciDevice', new VmHwPciDeviceComponent());
}
