/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace dvs_ui {

   export class DvpgGeneralPropertiesPageService {

      static $inject = [
         "i18nService",
         "networkUiConstants"
      ];

      constructor(private i18nService: any,
                  private networkUiConstants: any) {
      }

      public getNetworkResourcePoolsOptions(networkResourcePools: any[]): any [] {
         let networkResourcePoolOptions: any [] = [];

         if (networkResourcePools && networkResourcePools.length > 0) {
            _.each(networkResourcePools, (resourcePool: any) => {
               networkResourcePoolOptions.push({
                     label: resourcePool.name,
                     val: resourcePool.key
               });
            });
         }

         return networkResourcePoolOptions;
      }

      public getPvlanOptions(pvlanConfig: any): any [] {
         let pvlanTypeOptions: any [] = [];

         if (!pvlanConfig) {
            return pvlanTypeOptions;
         }

         _.each(pvlanConfig, (pvlanData: any) => {
            let pvlanTypeLabel = this.getPvlanTypeLabel(pvlanData.pvlanType);

            pvlanTypeOptions.push({
               label: this.i18nService.getString(
                     "DvsUi", "dvpg.create.properties.vlan.type.private.displayFormat",
                     pvlanTypeLabel,
                     pvlanData.primaryVlanId,
                     pvlanData.secondaryVlanId),
               val: pvlanData.secondaryVlanId
            });
         });

         return pvlanTypeOptions;
      }

      private getPvlanTypeLabel(pvlanType: string): string {
         switch (pvlanType) {
            case this.networkUiConstants.Vlan.PvlanType.ISOLATED: {
               return this.i18nService.getString(
                     "DvsUi", "dvpg.create.properties.vlan.type.private.isolated");
            }
            case this.networkUiConstants.Vlan.PvlanType.PROMISCUOUS: {
               return this.i18nService.getString(
                     "DvsUi", "dvpg.create.properties.vlan.type.private.promiscuous");
            }
            case this.networkUiConstants.Vlan.PvlanType.COMMUNITY: {
               return this.i18nService.getString(
                     "DvsUi", "dvpg.create.properties.vlan.type.private.community");
            }
            default:
               return "";
         }
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .service("dvpgGeneralPropertiesPageService", DvpgGeneralPropertiesPageService);
}
