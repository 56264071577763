namespace storage_ui {

   import IComponentController = angular.IComponentController;
   import IComponentOptions = angular.IComponentOptions;

   class ProtocolEndpointsPathsComponentController implements IComponentController {
      static $inject = ["vuiConstants", "i18nService", "dataService", "navigation",
         "hostStorageService"];

      private selectedItems: any;
      private paths: any[];
      private viewId = "datastore.pe.pathsTab";
      private hostId: string;

      constructor(private vuiConstants: any,
                  private i18nService: any,
                  private dataService: any,
                  private navigation: any,
                  private hostStorageService: any) {
      }

      $onChanges() {
         this.hostId = this.navigation.getRoute().objectId;
         if (this.hostId && this.selectedItems && this.selectedItems.length) {
            this.hostStorageService
                  .retrieveHostProtocolEndpointsPathsData
                  (this.hostId, this.selectedItems[0].identifier)
                  .then(
                        (response: any[])=> {
                           this.paths = response;
                        });
         }
      }

   }

   angular.module("com.vmware.vsphere.client.storage").component(
         "protocolEndpointsPathsComponentController", <IComponentOptions>{
            controller: ProtocolEndpointsPathsComponentController,
            templateUrl: "storage-ui/resources/storage/views/host/protocolEndpoints/paths/ProtocolEndpointsPathsComponent.html",
            bindings: {
               selectedItems: "<"
            }
         });

}
