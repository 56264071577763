/* Copyright 2013 VMware, Inc. All rights reserved. -- VMware Confidential */
/*
 * This js file has to be loaded first
 */
h5 = h5 || {};
h5.folder = {
   angularModule: angular.module('com.vmware.vsphere.client.folder', [
      'com.vmware.vsphere.client.licenseLibUi'])
};

/*
 * Registers folder specific column renderers.
 */
angular.module('com.vmware.vsphere.client.folder').run([
   'actionDialogService',
   'clarityModalService',
   'dataService',
   'i18nService',
   'assignLicenseService',
   'vscExportWizardLauncherService',
   'moveFolderService',
   function(actionDialogService, clarityModalService, dataService, i18nService, assignLicenseService,
      vscExportWizardLauncherService, moveFolderService) {
      /*
       * Register the action invoker function in the h5.actions cache.
       */


      h5.actions['vsphere.core.datacenter.actions.addDatacenter'] = function(actionEval, availableTargets) {

         var modalOptions = {
            title: i18nService.getString('DatacenterUi', 'createDatacenterDialog.title'),
            contentTemplate: 'folder-ui/resources/folder/views/datacenter/addDatacenter.html',
            defaultButton: 'submit',
            dialogData: {
               objectId: availableTargets[0]
            }
         };

         clarityModalService.openOkCancelModal(modalOptions);

      };


      h5.actions['vsphere.core.vcenter.actions.addFolder'] = function(actionEval, availableTargets) {
         clarityModalService.openModal(
            actionEval,
            availableTargets,
            {
               objectId: availableTargets[0],
               title: i18nService.getString('FolderUi', 'actionCreateFolderLabel')
            },
            'folder-ui/resources/folder/views/folder/addFolder.html'
         );
      };

      h5.actions['vsphere.core.vcenter.actions.removeFolder'] = function(actionEval, availableTargets) {
         clarityModalService.openModal(
            actionEval,
            availableTargets,
            null,
            'folder-ui/resources/folder/views/folder/removeFolder.html'
         );
      };

      /*
       * Action 'Assign License' for a vc.
       */
      h5.actions["vsphere.core.vc.assignLicenseKeyAction"] = function(actionEval, availableTargets) {
         if (_.isEmpty(availableTargets)) {
            return;
         }

         assignLicenseService.showAssignLicenseForm(availableTargets);
      };

      h5.actions["vsphere.core.folder.exportLogsAction"] = function(actionEval, availableTargets) {
         if (_.isEmpty(availableTargets)) {
            return;
         }
         vscExportWizardLauncherService.showSystemLogExportDialog(availableTargets);
      };

      h5.actions["vsphere.core.folder.actions.moveFolder"] =
         function (actionEval, availableTargets, destinationTarget) {
            if (!availableTargets || !availableTargets.length) {
               return;
            }

            moveFolderService.openMoveToDialog(availableTargets);
         };

      h5.actions["vsphere.core.network.actions.moveNetworkToDropTarget"] =
         function (actionEval, availableTargets, destinationTarget) {
            if (!availableTargets || !availableTargets.length || !destinationTarget) {
               return;
            }

            moveFolderService.move(availableTargets, destinationTarget.dropTarget);
         };

      h5.actions["vsphere.core.datacenter.actions.move"] =
         function (actionEval, availableTargets) {
            if (!availableTargets || !availableTargets.length) {
               return;
            }

            moveFolderService.openMoveToDialog(availableTargets);
         };

      h5.actions['vsphere.core.datacenter.actions.editDefaultHwVersionAction'] =
         function (actionEval, availableTargets) {
            var modalOptions = {
               title: i18nService.getString('HostUi', 'defaultVmCompatibilityDialog.title'),
               subTitle: {
                  objectId: availableTargets[0]
               },
               defaultButton: 'submit',
               contentTemplate: 'host-ui/resources/host/views/settings/EditDefaultVmCompatibility.html',
               dialogData: {
                  availableTargets: availableTargets
               }
            };

            clarityModalService.openOkCancelModal(modalOptions);
         };

      h5.actions["vsphere.core.datacenter.actions.dragAndDropToFolder"] =
         function (actionEval, availableTargets, destinationTarget) {
            if (!availableTargets || !availableTargets.length || !destinationTarget) {
               return;
            }

            moveFolderService.move(availableTargets, destinationTarget.dropTarget);
         };
   }]
);
