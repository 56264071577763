namespace h5_vm {
   export enum SizeUnits {
      MB,
      GB,
      TB
   }

   export class DiskSize {
      size: number;
      unit: SizeUnits = SizeUnits.MB;
      unitText: string;
   }


   export class BasicDiskUtilService {
      static $inject: string[] = ['i18nService'];

      //MB->GB->TB converter
      private readonly SIZE_DENOMINATOR: number = 1024;
      //if more than 1024, must upgrade the unit
      private readonly SIZE_REQUIRES_CONVERSION: number = 1024;

      constructor(private i18nService: any) {
      }

      /**
       * given a number, outputs its rounded off value to the precision specified
       * ex: service.toPrecision(10.294, 1) => 10.3
       * @param number
       * @param precision
       * @returns {number}
       */
      roundToPrecision(anyNumber: number, precision: number): number {
         let factor = Math.pow(10, precision);
         return Math.round(anyNumber * factor) / factor;
      }

      /**
       * given a diskSize in Mb, returns a DiskSize type
       * with the size appropriately set for display with the right unit
       * @param valueInMb
       * @returns {DiskSize}
       */
      determineDisplayDiskSize(valueInMb: number): DiskSize {
         let result: DiskSize = new DiskSize();
         if (valueInMb <= this.SIZE_REQUIRES_CONVERSION) {
            result.size = valueInMb; //stays MB
         } else {
            result.size = valueInMb / this.SIZE_DENOMINATOR; //GB
            result.unit = SizeUnits.GB;
            if (result.size > this.SIZE_REQUIRES_CONVERSION) {
               result.size = result.size / this.SIZE_DENOMINATOR;
               result.unit = SizeUnits.TB;
            }
         }
         result.unitText = this.i18nService.getString('VmUi', 'Vm.Units.' + SizeUnits[result.unit]);
         return result;
      }
   }
   angular.module("com.vmware.vsphere.client.vm")
         .service("basicDiskUtilService", BasicDiskUtilService);
}