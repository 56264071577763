/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace dvs_ui {

   export class DvsSpanSessionSelectSourcesPageModel {
      public portsListModel: DvsSpanSessionPortsListModel
            = new DvsSpanSessionPortsListModel();

      public vlanIdListModel: DvsSpanSessionEditableListModel =
            new DvsSpanSessionEditableListModel();
   }
}
