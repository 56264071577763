/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace dvs_ui {

   export class DvpgPolicyTypePageValidator implements WizardPageValidator {

      public static $inject = [
            "i18nService",
            "$q"];

      constructor(private i18nService: any,
                  private $q: any) {
      }

      public getValidationError(wizardScope: any): string[] {
         let errors: string[] = [];

         if (!wizardScope.model.policies.isSecurityPolicySelected &&
               !wizardScope.model.policies.isVlanPolicySelected &&
               !wizardScope.model.policies.isTrafficShapingPolicySelected &&
               !wizardScope.model.policies.isTrafficFilterPolicySelected &&
               !wizardScope.model.policies.isMonitoringPolicySelected &&
               !wizardScope.model.policies.isMiscPolicySelected &&
               !wizardScope.model.policies.isResAllocPolicySelected &&
               !wizardScope.model.policies.isFailoverPolicySelected) {

            errors.push(this.i18nService.getString(
                  "DvsUi", "dvpg.manage.policyType.noPoliciesSelected"));
         }
         return this.$q.resolve(errors);
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .service("dvpgPolicyTypePageValidator", DvpgPolicyTypePageValidator);
}
