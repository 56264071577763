(function() {
   'use strict';
   angular.module('com.vmware.vsphere.client.host')
         .controller('editHostGraphicsSettingsController', EditHostGraphicsSettingsController);

   EditHostGraphicsSettingsController.$inject = [
      '$scope',
      'mutationService',
      'dataService',
      'i18nService'
   ];

   function EditHostGraphicsSettingsController($scope, mutationService, dataService, i18nService) {
      var hostId = $scope._route.objectId;

      dataService.getData(
            hostId,
            'com.vmware.vsphere.client.h5.host.model.GraphicsData')
            .then(function(data) {
               if (data) {
                  this.defaultGraphicsType = data.defaultGraphicsType;
                  this.sharedPassthruAssignmentPolicy = data.sharedPassthruAssignmentPolicy;
               }
            }.bind(this));

      $scope.modalOptions.onSubmit = function() {
         var operationName =
               i18nService.getString(
                     'HostUi',
                     'settings.graphics.hostConfigView.update.operationName');

         mutationService.apply(
               hostId,
               'com.vmware.vim.binding.vim.host.GraphicsConfig',
               {
                  '_type': 'com.vmware.vim.binding.vim.host.GraphicsConfig',
                  hostDefaultGraphicsType: this.defaultGraphicsType,
                  sharedPassthruAssignmentPolicy: this.sharedPassthruAssignmentPolicy
               },
               operationName
         );
         $scope.closeModal();
      }.bind(this);
   }
})();


