module ds_cluster_ui {

   import IPromise = angular.IPromise;

   export class CreateDsClusterWizardHostsAndClustersService {

      public static $inject = ['mutationService', 'i18nService', 'dsClusterConstants'];

      constructor(private mutationService: any, private i18nService: any,
            private dsClusterConstants: any) {}

      public getHostsAndClusters(selectedDatastore: ManagedObjectReference[],
            selectedHostsAndClusters: string[]): IPromise<HostsAndClustersItem[]> {
         let spec: any = this.getValidationSpec(selectedDatastore, selectedHostsAndClusters);

         return this.mutationService.validateSpec(
               this.dsClusterConstants.HOSTS_AND_CLUSTERS_VALIDATE_SPEC, spec)
               .then(function (validationResult: any) {
                  return validationResult.result;
               });
      }

      private getValidationSpec(selectedDatastore: ManagedObjectReference[],
            selectedHostsAndClusters: string[]): any {
         let spec: any = {
            _type: this.dsClusterConstants.HOSTS_AND_CLUSTERS_VALIDATE_SPEC,
            datastores: selectedDatastore,
            hostsAndClustersIds: selectedHostsAndClusters
         };

         return spec;
      }
   }

   angular.module('com.vmware.vsphere.client.dsCluster')
         .service('createDsClusterWizardHostsAndClustersService',
               CreateDsClusterWizardHostsAndClustersService);
}
