/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace storage_ui {

   export class HostProtocolEndpointsPropertiesComponent
   {
      public i18n: Function;

      public loading: boolean;

      public selectedItem: HostProtocolItem;

      public hostConnectionState: string;

      public propertyViewData: any;

      public hostPolicyOptions: {[propertyId: string]: any} = {};

      public hostId: string;

      public multhipathingCache: {[propertyId: string]: any} = {};

      public static $inject = ["i18nService", "vxPropertyViewService", "hostStorageConstants", "navigation", "dataService", "multipathingPoliciesService", "actionsService", "$q"];

      constructor(private i18nService: any,
                  private vxPropertyViewService: any,
                  private hostStorageConstants: any,
                  private navigation: any,
                  private dataService: any,
                  private multipathingPoliciesService: any,
                  private actionsService: any,
                  private $q: any) {
         this.i18n = i18nService.getString;
      }

      public $onChanges() {
         this.hostId = this.navigation.getRoute().objectId;
         if (this.selectedItem) {
            if (this.selectedItem.peType === this.hostStorageConstants.protocolEndpoints.BLOCK && this.isHostConnected()) {
               this.buildPropertyViewWithMulthipathingData();
            } else {
               this.buildPropertyView(this.selectedItem, null);
            }
         }
      }

      private isHostConnected(): boolean {
         return this.hostConnectionState === this.hostStorageConstants.connectionState.CONNECTED;
      }

      private buildPropertyViewWithMulthipathingData() {
         let deviceId = this.selectedItem.identifier;

         if (!deviceId) {
            return;
         }

         if(!this.isHostConnected()) {
            this.buildPropertyView(this.selectedItem, null);
            return;
         }

         let hostId: string = this.hostId;
         let cacheId: string = hostId + '-' + deviceId;

         if (this.multhipathingCache.hasOwnProperty(cacheId)) {
            this.buildPropertyView(this.selectedItem, this.multhipathingCache[cacheId]);
         } else {
            this.loading = true;
            this.propertyViewData = null;
         }

         let requestHostPolicies = false;
         if (!this.hostPolicyOptions.hasOwnProperty(this.hostId)) {
            requestHostPolicies = true;
         }

         let onEditInvocation = (actionDef: any) => {
            if (this.selectedItem) {
               this.actionsService.invokeAction(actionDef, {
                  deviceId: this.selectedItem.identifier,
                  name: this.selectedItem.identifier
               });
            }
         };

         this.multipathingPoliciesService.requestPolicies(
               this.hostId, this.selectedItem.identifier,
               requestHostPolicies, onEditInvocation.bind(this))
               .then(
                     (responses: PoliciesDataItem) => {

                        this.multhipathingCache[cacheId] = responses.multipathingInfo;
                        if (requestHostPolicies && responses.hostPolicyOptions) {
                           this.hostPolicyOptions[this.hostId] = responses.hostPolicyOptions;
                        }
                        if (hostId === this.hostId && deviceId === this.selectedItem.identifier) {
                           this.buildPropertyView(this.selectedItem, responses.multipathingInfo);
                        }
                     },
                     () => {
                        if (hostId === this.hostId && deviceId === this.selectedItem.identifier) {
                           this.buildPropertyView(this.selectedItem, null);
                        }
                     })
               .finally(() => {
                  if (hostId === this.hostId && deviceId === this.selectedItem.identifier) {
                     this.loading = false;
                  }
               });
      }

      private buildPropertyView(item: HostProtocolItem, multipathingProperties: any): void {
         let builder = this.vxPropertyViewService.createPropertyViewBuilder();
         let categoryBuilder = builder.category(
               this.i18n("StorageUi", "storage.pe.properties.generalHeader"), 0);
         let generalCategoryBuilder: any =categoryBuilder.section("generalSection")
               .title(this.i18n("StorageUi", "storage.pe.properties.generalHeader"))
               .property(
                     this.i18n("StorageUi", "storage.pe.runtimeName"),
                     item.name)
               .property(
                     this.i18n("StorageUi", "storage.pe.type"),
                     item.type)
               .property(
                     this.i18n("StorageUi", "storage.pe.identifier"),
                     item.identifier);

         switch (item.peType) {
            case this.hostStorageConstants.protocolEndpoints.BLOCK: {
               generalCategoryBuilder
                     .property(
                           this.i18n("StorageUi", "storage.pe.location"),
                           item.location)
                     .property(
                           this.i18n("StorageUi", "storage.pe.lun"),
                           item.lun)
                     .property(
                           this.i18n("StorageUi", "storage.pe.opState"),
                           item.operationalState)
                     .property(
                           this.i18n("StorageUi", "storage.pe.transport"),
                           item.transport)
                     .property(
                           this.i18n("StorageUi", "storage.pe.owner"),
                           item.owner);
               break;
            }
            case this.hostStorageConstants.protocolEndpoints.NAS: {
               generalCategoryBuilder
                     .property(
                           this.i18n("StorageUi", "storage.pe.server"),
                           item.server)
                     .property(
                           this.i18n("StorageUi", "storage.pe.folder"),
                           item.folder)
                     .property(
                           this.i18n("StorageUi", "storage.pe.opState"),
                           item.operationalState);
               break;
            }
         }

         generalCategoryBuilder
               .property(
                     this.i18n("StorageUi", "storage.pe.array"),
                     item.storageArray);

         if(multipathingProperties){
            this.multipathingPoliciesService.buildMulthipathingSection(
                  categoryBuilder,
                  this.i18n("StorageUi", "storage.pe.properties.multipathingPoliciesHeader"),
                  multipathingProperties,
                  this.hostPolicyOptions[this.hostId],
                  multipathingProperties.editAction);
         }

         this.propertyViewData = builder.build();
      }
   }

   angular.module("com.vmware.vsphere.client.storage")
         .component("hostProtocolEndpointProperties", {
            templateUrl: "storage-ui/resources/storage/views/host/protocolEndpoints/propertiesDetailsTab/HostProtocolEndpointsPropertiesView.html",
            bindings: {
               selectedItem: "<",
               hostConnectionState: "<"
            },
            controller: HostProtocolEndpointsPropertiesComponent
         });
}
