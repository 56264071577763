namespace h5_vm {
    class TpmProvisioningService {
        public static $inject: string[] =
            ['deviceService', 'i18nService', 'VirtualTPM', 'dataService', 'defaultUriSchemeUtil'];

        constructor(private deviceService: any,
                    private i18nService: any,
                    private VirtualTPM: any,
                    private dataService: any,
                    private defaultUriSchemeUtil: any) {
        }

        public checkDefaultKmsServer(serverGuid: string) {
            let rootFolder = this.defaultUriSchemeUtil.createVmomiUri('Folder', 'group-d1', serverGuid);
            return this.dataService.getProperties(rootFolder, ['defaultKmsClusterExists']);
        }

        public makeDefaultTpm(serverGuid: string, callback: Function): {device: {_type: string, key: number}} {
            let rawTpmDevice: any = {};
            rawTpmDevice._type = "com.vmware.vim.binding.vim.vm.device.VirtualTPM";
            rawTpmDevice.key = -1;
            let device = this.deviceService.setDeviceInfo(new this.VirtualTPM(rawTpmDevice));

            this.checkDefaultKmsServer(serverGuid).then((result: any) => {
                if (result && result.defaultKmsClusterExists) {
                    device.state =  device.CONNECTED;
                } else {
                    device.state = device.NOT_CONNECTED;
                }
                callback(device);
            });

            return {
                device
            };
        }
    }

    angular.module('com.vmware.vsphere.client.vm')
        .service('tpmProvisioningService', TpmProvisioningService);
}
