module ds_cluster_ui {

   import IPromise = angular.IPromise;

   export class StorageDrsDataRetrieval {

      public static $inject = ["storageDrsLocalizationService", "dataService",
            "dsClusterConstants"];

      constructor(private storageDrsLocalizationService: StorageDrsLocalizationService,
            private dataService: any, private dsClusterConstants: any) { }

      /**
       * Fetches the SDRS settings for the given datastore cluster.
       *
       * @param objectId
       * @returns {any}
       */
      public retrieveData(objectId: string): IPromise<Object> {
         return this.dataService.getData(objectId,
               "com.vmware.vsphere.client.dsclusterui.model.ClusterStorageDrsConfiguration")
               .then((sdrsData: StorageDrsData): Object => {
                  if (sdrsData) {
                     sdrsData.automationOverrides = sdrsData.automationOverrides || {};
                     return {
                        enabled: sdrsData.enabled,
                        automationLevelText: this.storageDrsLocalizationService
                              .getAutomationLevelTextLocalization(
                                    sdrsData.defaultVmBehavior),
                        automationLevelDescription: this.storageDrsLocalizationService
                              .getAutomationLevelDescriptionLocalization(
                                    sdrsData.defaultVmBehavior),
                        spaceLoadBalanceAutomationMode:
                              this.storageDrsLocalizationService
                                    .getAutomationLevelTextLocalization(
                                          sdrsData.automationOverrides
                                                .spaceLoadBalanceAutomationMode),
                        ioLoadBalanceAutomationMode:
                              this.storageDrsLocalizationService
                                    .getAutomationLevelTextLocalization(
                                          sdrsData.automationOverrides
                                                .ioLoadBalanceAutomationMode),
                        ruleEnforcementAutomationMode:
                              this.storageDrsLocalizationService
                                    .getAutomationLevelTextLocalization(
                                          sdrsData.automationOverrides
                                                .ruleEnforcementAutomationMode),
                        policyEnforcementAutomationMode:
                              this.storageDrsLocalizationService
                                    .getAutomationLevelTextLocalization(
                                          sdrsData.automationOverrides
                                                .policyEnforcementAutomationMode),
                        vmEvacuationAutomationMode:
                              this.storageDrsLocalizationService
                                    .getAutomationLevelTextLocalization(
                                          sdrsData.automationOverrides
                                                .vmEvacuationAutomationMode),
                        spaceThreshold: this.storageDrsLocalizationService
                              .getSpaceThresholdText(sdrsData.spaceThresholdMode,
                                    sdrsData.freeSpaceThresholdGB,
                                    sdrsData.spaceUtilizationThreshold),

                        ioMetricInclusion: this.storageDrsLocalizationService
                              .getIoMetricInclusionText(sdrsData.ioLoadBalanceEnabled),

                        ioMetricInclusionDescription: this.storageDrsLocalizationService
                              .getIoMetricInclusionDescription(sdrsData.ioLoadBalanceEnabled),

                        ioLatencyThreshold: this.storageDrsLocalizationService
                              .getIoLatencyThresholdText(sdrsData.ioLatencyThreshold),


                        defaultIntraVmAffinity: this.storageDrsLocalizationService
                              .getDefaultIntraVmAffinityText(
                                    sdrsData.defaultIntraVmAffinity),

                        ioLoadImbalanceThreshold: sdrsData.ioLoadImbalanceThreshold ?
                              sdrsData.ioLoadImbalanceThreshold :
                              this.dsClusterConstants.loadBalanceIoThreshold.DEFAULT,

                        minSpaceUtilizationDifference: this.storageDrsLocalizationService
                              .getMinSpaceUtilizationDifferenceText(sdrsData
                                    .minSpaceUtilizationDifference),

                        loadBalanceInterval: sdrsData.loadBalanceInterval,

                        advancedOptions: this.storageDrsLocalizationService
                              .getAdvancedOptions(sdrsData.advancedOptions)
                     };
                  }

                  return {};
               });
      }

      /**
       * Fetches the SDRS portlet settings for the given datastore cluster.
       *
       * @param objectId
       * @returns {any}
       */
      public retrievePortletData(objectId: string): IPromise<Object> {
         return this.dataService.getData(objectId,
               "com.vmware.vsphere.client.dsclusterui.model.ClusterStorageDrsPortletData")
               .then((sdrsData: StorageDrsData): Object => {
                  if (sdrsData) {
                     return {
                        enabled: sdrsData.enabled,
                        automationLevelText: this.storageDrsLocalizationService
                              .getAutomationLevelTextLocalization(
                                    sdrsData.defaultVmBehavior),

                        ioMetricInclusion: this.storageDrsLocalizationService
                              .getIoMetricInclusion(sdrsData.ioLoadBalanceEnabled),

                        spaceThreshold: this.storageDrsLocalizationService
                              .getPortletSpaceThresholdText(
                                    sdrsData.spaceLoadBalanceConfig),

                        ioLatencyThreshold: this.storageDrsLocalizationService
                              .getIoLatencyThresholdText(sdrsData.ioLatencyThreshold),
                        recommendationsCount: sdrsData.recommendationsCount,
                        faultsCount: sdrsData.faultsCount
                     };
                  }

                  return {};
               });
      }
   }

   angular.module("com.vmware.vsphere.client.dsCluster")
         .service("storageDrsDataRetrieval", StorageDrsDataRetrieval);
}
