/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace dvs_ui {

   import IController = angular.IController;
   import IOnChangesObject = angular.IOnChangesObject;
   import IPromise = angular.IPromise;
   import HostNetworkDetailsData = com.vmware.vsphere.client.dvpg.api.HostNetworkDetailsData;
   export class VirtualAdapterSettingsComponent {

      public bindings: any;
      public controller: any;
      public templateUrl: string;

      constructor() {
         this.bindings = {
            hostId: "<",
            vnicDevice: "<",
            registerRefreshCallback: "<",
            customizedSettings: "<"
         };

         this.controller = VirtualAdapterSettingsController;
         this.templateUrl = "network-lib-ui/resources/network-lib/components/vnicSettings/virtualAdapterSettingsTemplate.html";
      }
   }

   class VirtualAdapterSettingsController implements IController {

      static $inject = [
         "i18nService",
         "networkDetailsViewService",
         "vmKernelAdapterService"
      ];

      public hostId: string;

      public vnicDevice: string;

      public registerRefreshCallback: undefined | ((refreshCallback: () => void) => void);

      public customizedSettings: boolean;

      public propertyViewData: any;

      public loadingData: boolean = false;

      public lastVmKernelAdapterPromise: IPromise<HostNetworkDetailsData>;

      constructor(private i18nService: any,
                  private networkDetailsViewService: any,
                  private vmKernelAdapterService: any) {
      }

      public $onInit(): void {
         if (this.registerRefreshCallback !== undefined) {
            this.registerRefreshCallback(() => this.requestData());
         }
      }

      public $onChanges(): void {
         if (this.hostId !== undefined && this.vnicDevice !== undefined) {
            this.requestData();
         }
      }

      public requestData() {
         this.loadingData = true;

         let vmKernelAdapterPromise: IPromise<HostNetworkDetailsData> =
               this.vmKernelAdapterService.getVnicDetailsData(this.hostId, this.vnicDevice);
         this.lastVmKernelAdapterPromise = vmKernelAdapterPromise;

         vmKernelAdapterPromise
               .then((hostVnicDetailsData: HostNetworkDetailsData): void => {
                  if (this.lastVmKernelAdapterPromise === vmKernelAdapterPromise) {
                     this.propertyViewData = this.networkDetailsViewService
                           .build(hostVnicDetailsData, this.customizedSettings);
                  }
               })
               .finally((): void => {
                  if (this.lastVmKernelAdapterPromise === vmKernelAdapterPromise) {
                     this.loadingData = false;
                  }
               });
      }
   }

   angular.module("com.vmware.vsphere.client.networkLibUi")
         .component("vnicSettings", new VirtualAdapterSettingsComponent());
}
