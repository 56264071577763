namespace h5_vm {
   import VmProvisioningWizardPage = h5_vm.VmProvisioningWizardPage;

   export class VmProvisioningScaleoutConfigurationPageService implements VmProvisioningPageService {

      static $inject: string[] = [
         "i18nService",
         "vuiConstants",
         "wizardPageService",
         "vmVuiWizardPageBuilder",
         "vmProvisioningScaleoutConfigurationPageModel"
      ];

      constructor(private i18nService: any,
                  private vuiConstants: any,
                  private wizardPageService: any,
                  private vmVuiWizardPageBuilder: any,
                  private vmProvisioningScaleoutConfigurationPageModel: any) {
      }

      public build(wizardScope: any,
                   defaultTarget?: any,
                   preselectedComputeResource?: any,
                   preselectedStorage?: any): VmProvisioningWizardPage {
         let pageModel = new this.vmProvisioningScaleoutConfigurationPageModel(
               wizardScope.wizardViewData,
               wizardScope.vmParams);
         wizardScope.vmProvisioningScaleoutConfigurationPageModel = pageModel;

         return this.vmVuiWizardPageBuilder.buildVuiWizardPage(wizardScope.config, {
            title: this.i18nService.getString("ProvisioningUiLib", "ScaleoutPage.title"),
            description: this.i18nService.getString("ProvisioningUiLib", "ScaleoutPage.description"),
            contentUrl: "vm-ui/resources/vm/views/createVmWizard/deployOvfPages/vmProvisioningScaleoutConfiguration/" +
            "vm-provisioning-scaleout-configuration-page.html",
            model: pageModel,
            defaultState: this.vuiConstants.wizard.pageState.SKIPPED,
         });
      };
   }

   angular.module("com.vmware.vsphere.client.vm")
         .service("vmProvisioningScaleoutConfigurationPageService",
               VmProvisioningScaleoutConfigurationPageService);

}
