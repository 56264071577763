(function() {
   'use strict';
   angular.module('com.vmware.vsphere.client.cluster')
         .controller('VmAndHostGroupsController', VmAndHostGroupsController);

   VmAndHostGroupsController.$inject = ['$rootScope', '$scope', '$element',
      '$compile', 'i18nService', 'clusterGroupsService', 'vuiConstants',
      'datagridActionBarService'];

   function VmAndHostGroupsController($rootScope, $scope, $element, $compile,
         i18nService, clusterGroupsService, vuiConstants,
         datagridActionBarService) {
      var self = this;
      var objectId = $rootScope._route.objectId;
      var actionSpecs = [{
         actionId: 'vsphere.core.cluster.actions.group.add'
      }, {
         actionId: 'vsphere.core.cluster.actions.group.remove',
         getActionInvocationContext: function() {
            return {
               selectedGroup: self.selectedGroup
            };
         },
         isActionAvailable: function(actionDef) {
            return actionDef.available && self.groupsGridOptions.selectedItems.length > 0;
         }
      }];
      var datagridActionBarServiceCacheObject = null;

      self.watchForObjects = [objectId];
      self.liveRefreshProperties = [
         "configurationEx.group"
      ];
      self.getViewData = getViewData;
      self.groupsGridOptions = getGroupsGridOptions();
      self.splitterOptions = getSplitterOptions();
      self.groupsTitle = i18nService.getString('ClusterUi', 'manage.settings.groups');
      self.selectedGroup = null;

      var filterPlaceholderText = i18nService.getString('Common', 'filter.label');
      var filterElement = $compile(
            '<div class="filter-input pull-right">' +
            '<span class="nav-icon fa fa-filter" ng-click="doSearch()"/>' +
            '<input type="text" ng-model="searchTerm" ng-trim="true" ng-change="debouncedRefreshCallback()"' +
            ' ng-keypress="keyPress($event)" placeholder="' + filterPlaceholderText + '"/>' +
            '</div>')($scope);
      var isFilterAdded = false;

      $scope.doSearch = function() {
         getViewData();
      };
      $scope.keyPress = function(event) {
         if (event.keyCode === 13) {
            // In IE Enter would activate the focused item so we prevent this default
            // behavior.
            event.preventDefault();
            event.stopPropagation();
            getViewData();
         }
      };
      $scope.groupsPreselectComparator = groupsPreselectComparator;
      $scope.$watch('ctrl.groupsGridOptions.selectedItems', onSelectedGroupChange);
      $scope.$watch(
            function() {
               return self.selectedGroup;
            }, function() {
               refreshActionBar();
            });

      getViewData();
      createActionBar();

      function getViewData() {
         clusterGroupsService.getGroupsData(objectId, $scope.searchTerm).then(
               function (groupsData) {
                  self.groupsGridOptions.data = groupsData;
                  keepSelectionOrPreselectFirst();
               });
      }

      function createActionBar() {
         datagridActionBarService
               .updateActionBar(self.groupsGridOptions, [objectId], actionSpecs)
               .then(function (value) {
                  datagridActionBarServiceCacheObject = value;
               });
      }

      function refreshActionBar() {
         if (!datagridActionBarServiceCacheObject) {
            return;
         }

         datagridActionBarService.updateActionBar(
               self.groupsGridOptions, [objectId], actionSpecs, datagridActionBarServiceCacheObject
         );
      }

      function keepSelectionOrPreselectFirst() {
         if (self.groupsGridOptions.data && self.groupsGridOptions.data.length > 0) {
            var itemToPreselect;

            if (self.selectedGroup) {
               itemToPreselect = _.find(self.groupsGridOptions.data, {
                  name: self.selectedGroup.name
               });
            }
            self.selectedGroup = !!itemToPreselect ?
                  itemToPreselect : self.groupsGridOptions.data[0];
         }
      }

      function getGroupsGridOptions() {
         var options = {
            actionBarOptions: {
               actions: []
            },
            pageConfig: {
               hidePager: true
            },
            searchable: false,
            resizable: true,
            data: [],
            selectionMode: vuiConstants.grid.selectionMode.SINGLE,
            selectedItems: [],
            columnDefs: getGroupsGridColumnDefs(),
            height: '100%',
            dataBound: onDataBound
         };

         return options;
      }

      function onDataBound() {
         if (!isFilterAdded) {
            addToolbarFilter();
         }
      }

      function addToolbarFilter() {
         var grid = getGridElement();
         if (grid) {
            var toolbarElement = grid.find('.k-grid-toolbar');
            if (toolbarElement.length > 0) {
               toolbarElement.append(filterElement);
               isFilterAdded = true;
            }
         }
      }

      function getGridElement() {
         const element = $element.find('.k-grid-content');
         return element && element.parent();
      }

      function getSplitterOptions() {
         return {
            orientation: vuiConstants.splitter.orientation.VERTICAL,
            panes: [
               {
                  min: '150px',
                  size: '40%'
               }, {
                  min: '150px',
                  size: '60%'
               }
            ]
         };
      }

      function getGroupsGridColumnDefs() {
         return [
            {
               displayName: i18nService.getString('ClusterUi', 'groups.list.name'),
               field: 'name',
               type: 'string',
               template: '<span class="object" title="#:name#"><i class="#:icon#")></i>#:name#</span>'
            },
            {
               displayName: i18nService.getString('ClusterUi', 'groups.list.type'),
               field: 'type',
               type: 'string',
               template: '<span title="#:type#">#:type#</span>'
            }
         ];
      }

      function onSelectedGroupChange(newSelectedGroups) {
         if (!newSelectedGroups || newSelectedGroups.length === 0) {
            self.selectedGroup = null;
            return;
         }

         if (self.selectedGroup && newSelectedGroups[0].name === self.selectedGroup.name) {
            return;
         }

         self.selectedGroup = newSelectedGroups[0];
      }

      function groupsPreselectComparator(groupItem) {
         return self.selectedGroup && groupItem &&
               self.selectedGroup.name === groupItem.name;
      }
   }
})();
