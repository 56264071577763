/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace dvs_ui {

   import IPromise = angular.IPromise;

   export class DvsPortStatisticsComponent {

      public controller: any;
      public bindings: any;
      public templateUrl: string;

      constructor() {
         this.bindings = {
            selectedPort: "<",
            portContextId: "<",
            isPortGroupContext: "<"
         };
         this.controller = DvsPortStatisticsController;
         this.templateUrl =
               "dvs-ui/resources/dvs/ports/statistics/dvsPortStatisticsViewTemplate.html";
      }
   }

   class DvsPortStatisticsController {

      static $inject = [
         "$scope",
         "i18nService",
         "dataService",
         "vxPropertyViewService",
         "dvsPortStatisticsService"
      ];
      public i18n: Function;

      public selectedPort: any;

      public dvsPortStatisticsData: any;

      public refreshStatistics: boolean;

      public portContextId: any;

      public isPortGroupContext: boolean;

      public portsLoading: boolean;

      public refreshTimeout: any;

      private PORT_STATISTICS_PROPERTY = "dvsport:portStatisticsData";

      private DVPG_PORT_STATISTICS_PROPERTY = "dvpgport:portStatisticsData";

      private REFRESH_PORT_STATISTICS_PROPERTY = "dvsports:refreshedPortsData";

      private DVPG_REFRESH_PORT_STATISTICS_PROPERTY = "dvpgports:refreshedPortsData";

      private REFRESH_STATISTICS_TIMEOUT: number = 2000;

      constructor(private $scope: any,
                  private i18nService: any,
                  private dataService: any,
                  private vxPropertyViewService: any,
                  private dvsPortStatisticsService: any) {
         this.i18n = this.i18nService.getString;
         this.refreshStatistics = false;
      }

      public $onChanges(): void {
         this.refresh(this.selectedPort);
      }

      public $onDestroy(): void {
         this.refreshStatistics = false;
         clearTimeout(this.refreshTimeout);
      }

      public refresh(selectedPort: any) {

         clearTimeout(this.refreshTimeout);
         if (!selectedPort) {
            return;
         }

         this.portsLoading = true;

         let statsProperty: string = this.isPortGroupContext ?
               this.DVPG_PORT_STATISTICS_PROPERTY : this.PORT_STATISTICS_PROPERTY;

         this.dataService.getProperties(
               this.portContextId, [statsProperty], {
                  propertyParams: [{
                     propertyName: statsProperty,
                     parameterType: "java.util.Map",
                     parameter: {
                        portgroupKey: selectedPort.portgroupKey,
                        portId: selectedPort.portId
                     }
                  }]
               }).then((dataServiceResponse: any) => {

            if (dataServiceResponse !== null) {
               this.dvsPortStatisticsData =
                     this.dvsPortStatisticsService
                           .buildDistributedPortStatisticsView(
                                 dataServiceResponse[statsProperty]);
            }
            if (this.refreshStatistics) {
               this.refreshTimeout = setTimeout(this.enableRefreshStatistics(),
                     this.REFRESH_STATISTICS_TIMEOUT);
            }
         }).finally(() => {
            this.portsLoading = false;
         });
      }

      private enableRefreshStatistics() {
         if (this.refreshStatistics) {
            let refreshProperty: string = this.isPortGroupContext ?
                  this.DVPG_REFRESH_PORT_STATISTICS_PROPERTY : this.REFRESH_PORT_STATISTICS_PROPERTY;

            this.dataService.getProperties(
                  this.portContextId, [refreshProperty], {
                     propertyParams: [{
                        propertyName: refreshProperty,
                        parameterType: "java.util.Map",
                        parameter: {
                           portgroupKey: this.selectedPort.portgroupKey,
                           portId: this.selectedPort.portId
                        }
                     }]
                  }).then((dataServiceResponse: any) => {

               if (dataServiceResponse !== null) {
                  if (dataServiceResponse[refreshProperty].isErrorFound) {
                     this.refreshStatistics = false;
                  } else {
                     this.dvsPortStatisticsData =
                           this.dvsPortStatisticsService
                                 .buildDistributedPortStatisticsView(
                                       dataServiceResponse[refreshProperty]);
                     this.refreshTimeout = setTimeout(() => this.enableRefreshStatistics(),
                           this.REFRESH_STATISTICS_TIMEOUT);
                  }
               }
            });


         } else {
            clearTimeout(this.refreshTimeout);
         }
      }

   }

   angular.module("com.vmware.vsphere.client.dvs")
         .component("dvsPortStatisticsView", new DvsPortStatisticsComponent());
}
