angular.module('com.vmware.vsphere.client.host').service('lockdownModeStrings', ['i18nService',
   function(i18nService) {
      var lockdownModeSpecStrings = {
         disabled: 'lockdownDisabled',
         normal: 'lockdownNormal',
         strict: 'lockdownStrict'
      };

      var lockdownModeDisplayStrings = {
         disabled: 'addHostWizard.confirmationPage.lockDownModeValueDisabled',
         normal: 'addHostWizard.confirmationPage.lockDownModeValueNormal',
         strict: 'addHostWizard.confirmationPage.lockDownModeValueStrict'
      };

      return {
         forSpec: function(lockdownMode) {
            return lockdownModeSpecStrings[lockdownMode];
         },

         forDisplay: function(lockdownMode) {
            var key = lockdownModeDisplayStrings[lockdownMode];
            if (key) {
               return i18nService.getString('HostUi', key);
            } else {
               return undefined;
            }
         }
      };
   }]
);
