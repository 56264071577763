namespace h5_vm {

   import GosType = h5_vm.GosType;

   interface SpecType {
      label: string;
      type: GosType;
   }

   export class GosNameAndOsPageController {
      static $inject = ["$q",
         "i18nService",
         "userSessionService",
         "gosSpecValidationService",
         "gosNameAndOsValidationService"];

      model: GosNameAndOsPageModel;
      i18n: any;
      selectedVc?: VcInfo;
      vcOptions: Array<VcInfo>;
      selectedSpecType: SpecType;
      specTypeOptions: Array<SpecType>;
      isSysprepTextType: boolean;
      invalidMessage: string;

      constructor(private $q: any,
            private i18nService: any,
            private userSessionService: any,
            private gosSpecValidationService: GosSpecValidationService,
            private gosNameAndOsValidationService: GosNameAndOsValidationService) {
         this.i18n = i18nService.getString;
      }

      $onInit(): void {
         this.model.onPageCommit = this.onCommit.bind(this);
         if (this.model.isEditMode && !this.model.originalSpecName) {
            this.model.originalSpecName = this.model.specName;
         }
         this.specTypeOptions = this.initializeSpecTypeOptions();
         this.selectedSpecType = this.getInitialSpecTypeOption();
         this.retrieveVcOptions();
         this.isSysprepTextType =
               GosType.WINDOWS_CUSTOM_SYSPREP.value === this.model.gosType.value;
      }

      $onDestroy(): void {
         this.model.onPageCommit = undefined;
      }

      onCommit(): angular.IPromise<Array<string>> {
         const nameError = this.gosSpecValidationService.validateName(
               this.model.specName,
               true,
               this.onlyOtherSpecs(),
               this.selectedVc ? this.selectedVc.name : ""
         );
         if (this.model.isEditMode) {
            return this.$q.resolve(nameError ? [nameError] : []);
         }

         return this.$q.all([
            this.$q.resolve(nameError),
            this.gosNameAndOsValidationService
                  .checkCreateNewGosSpecPrivileges(this.selectedVc)
         ]).then((results: Array<Array<string>>) => _.without(_.flatten(results), ""));
      }

      onSpecTypeChange(): void {
         let newType: GosType;

         if (this.isLinuxprepType()) {
            newType = GosType.LINUX;
         } else {
            newType = this.isSysprepTextType
                  ? GosType.WINDOWS_CUSTOM_SYSPREP
                  : GosType.WINDOWS;
         }

         this.model.gosType = newType;
      }

      isLinuxprepType(): boolean {
         return this.selectedSpecType
               ? GosType.LINUX.value === this.selectedSpecType.type.value
               : false;
      }

      onVcChange(): void {
         this.model.vc = this.selectedVc;
      }

      onSysprepTypeChange(): void {
         let specType: GosType = this.isSysprepTextType
               ? GosType.WINDOWS_CUSTOM_SYSPREP
               : GosType.WINDOWS;

         this.model.gosType = specType;
      }

      isSysprepTextDisabled(): boolean {
         return this.model.isEditMode || this.isLinuxprepType();
      }

      validateSpecName(): void {
         this.invalidMessage = this.gosSpecValidationService.validateName(
               this.model.specName,
               false,
               this.onlyOtherSpecs(),
               this.selectedVc ? this.selectedVc.name : ""
         );
      }

      private onlyOtherSpecs(): CustomizationSpecInfoCustom[] | undefined {
         if (!this.model.allSpecs) {
            return undefined;
         }
         return this.model.allSpecs
               .filter((spec: CustomizationSpecInfoCustom): boolean =>
                     spec.name !== this.model.originalSpecName);
      }

      private initializeSpecTypeOptions(): Array<SpecType> {
         return [
            {
               label: this.i18n("VmUi", "customizationSpec.nameAndOsPage.specTypeWindows"),
               type: GosType.WINDOWS
            },
            {
               label: this.i18n("VmUi", "customizationSpec.nameAndOsPage.specTypeLinux"),
               type: GosType.LINUX
            }
         ];
      }

      /**
       * Retrieve all the registered VCs
       * Sets the first one as the selected.
       */
      private retrieveVcOptions(): void {
         this.userSessionService.getAllServersInfo().then((info: any) => {
            this.vcOptions = info.serversInfo;
            if (this.vcOptions && this.vcOptions.length > 0) {
               this.selectedVc = this.choosePreselectedVc(this.vcOptions, this.model);
               this.model.vc = this.selectedVc;
            }
         });
      }

      private getInitialSpecTypeOption(): SpecType {
         return _.find(this.specTypeOptions, (option: SpecType) => {
            switch (option.type) {
               case GosType.LINUX:
                  return this.model.gosType === GosType.LINUX;
               case GosType.WINDOWS:
                  return this.model.gosType === GosType.WINDOWS ||
                        this.model.gosType === GosType.WINDOWS_CUSTOM_SYSPREP;
               default:
                  return false;
            }
         });
      }

      private choosePreselectedVc(vcInfos: Array<VcInfo>,
            model: GosNameAndOsPageModel): VcInfo | undefined {
         if (!vcInfos || vcInfos.length < 1) {
            return undefined;
         }

         if (model.vc) {
            return model.vc;
         } else {
            return vcInfos[0];
         }
      }

   }

   export class GosNameAndOsPageComponent implements angular.IComponentOptions {
      public controller: any;
      public templateUrl: string;
      public bindings: any;

      constructor() {
         this.controller = GosNameAndOsPageController;
         this.templateUrl =
               "vm-ui/resources/vm/guest-os-customization/pages/name-and-os/gos-name-and-os-page.component.html";
         this.bindings = {
            model: "<"
         };
      }
   }

   angular
         .module("com.vmware.vsphere.client.vm")
         .component("gosNameAndOsPage", new GosNameAndOsPageComponent());
}
