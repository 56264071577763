namespace spbm_ui {

   export class CheckVmRollupComplianceService {

      public static $inject = ["i18nService", "mutationService", "dataService", "clarityModalService"];

      constructor(private i18nService: any,
                  private mutationService: any,
                  private dataService: any,
                  private clarityModalService: any) {
      }

      public invokeAction(vms:string[]) {
         if (vms.length > 1){
            this.makeMutationCall(vms);
            return;
         }

         let outdatedProfilesProperty:string = "areVmProfilesOutdated";
         this.dataService.getProperties(vms[0], [outdatedProfilesProperty]).then((response:any)=> {
            if (response[outdatedProfilesProperty]){
               this.showWarning();
            } else{
               this.makeMutationCall(vms);
            }
         });
      }

      private showWarning(){
         let modalOptions = {
            title: this.i18nService.getString("SpbmUi", "checkCompliance.warning.title"),
            hideCancelButton: true,
            message: this.i18nService.getString("SpbmUi", "checkCompliance.warning.text"),
            icon: "icon-warning-32",
            submit: ()=> true,
            preserveNewlines: true
         };
         this.clarityModalService.openConfirmationModal(modalOptions);
      }

      private makeMutationCall(vms:string[]){
         let spec:any = {
            _type: 'com.vmware.vsphere.client.spbm.spec.CheckVmRollupComplianceSpec'
         };
         this.mutationService.applyOnMultiEntity(vms,
               spec._type,
               spec,
               this.i18nService.getString('SpbmUi', 'checkVmRollupComplianceAction.label'));
      }
   }
   angular.module("com.vmware.vsphere.client.spbm").
   service("checkVmRollupComplianceService", CheckVmRollupComplianceService);
}
