module cluster_ui {

   import IController = angular.IController;
   import IComponentOptions = angular.IComponentOptions;
   import IPromise = angular.IPromise;

   class EditClusterController implements IController {

      public static $inject = ["$scope",
         "$q",
         "clarityConstants",
         "clarityModalService",
         "clusterConstants",
         "clusterSpecBuilderService",
         "dataService",
         "drsAutomationLevelService",
         "editDrsFileDownloadService",
         "haConstants",
         "haVmMonitoringService",
         "i18nService",
         "iconService",
         "mutationService"];

      clusterId: string;

      formState: any;

      drsSignPostParams: any;

      haSignPostParams: any;

      loading: boolean = true;

      constructor(private $scope: any,
            private $q: any,
            private clarityConstants: any,
            private clarityModalService: any,
            private clusterConstants: any,
            private clusterSpecBuilderService: any,
            private dataService: any,
            private drsAutomationLevelService: any,
            private editDrsFileDownloadService: any,
            private haConstants: any,
            private haVmMonitoringService: any,
            private i18nService: any,
            private iconService: any,
            private mutationService: any) {
      }

      $onInit(): void {
         this.clusterId = this.$scope.modalOptions.dialogData.objectId;
         this.$scope.modalOptions.onSubmit = () => {
            return this.onSubmit();
         };

         this.dataService.getData(
               this.clusterId,
               "com.vmware.vsphere.client.clusterui.model.configuration.ClusterConfigData")
               .then((clusterConfigData: any) => {
                  if (clusterConfigData) {
                     this.formState = clusterConfigData;
                     this.formState.locationIcon = this.getLocationIcon(this.formState.location),
                     this.initSignpost(clusterConfigData);
                  }
               })
               .finally(() => {
                  this.setPageBusy(false);
               });
      }

      private initSignpost(clusterConfigData: any): void {
         const drsSignPostMessage: string =
         `<div class="flex-row">
            <div class="flex-auto flex-column">
               <div>${this.getString("create.DRS.signpost.message.automationLevel")}</div>
               <div>${this.getString("create.DRS.signpost.message.migrationThreshold")}</div>
            </div>
            <div class="flex-auto flex-column">
               <div>${this.drsAutomationLevelService.getLabel(clusterConfigData.defaultDrsBehavior)}</div>
               <div>${clusterConfigData.migrationThreshold}</div>
            </div>
         </div>`;

         this.drsSignPostParams = {
            title: this.getString("create.DRS.signpost.title"),
            message: drsSignPostMessage
         };

         const haSignPostMessage: string =
         `<div class="flex-row">
            <div class="flex-auto flex-column">
               <div>${this.getString("create.HA.signpost.message.hostMonitoring")}</div>
               <div>${this.getString("create.HA.signpost.message.admissionControl")}</div>
               <div>${this.getString("create.HA.signpost.message.vmMonitoring")}</div>
            </div>
            <div class="flex-auto flex-column">
               <div>${this.getHostMonitoring(clusterConfigData.hostMonitoring)}</div>
               <div>${this.getAdmissionControl(clusterConfigData.admissionControlEnabled)}</div>
               <div>${this.haVmMonitoringService.getLabel(clusterConfigData.vmMonitoring)}</div>
            </div>
         </div>`;

         this.haSignPostParams = {
            title: this.getString("create.HA.signpost.title"),
            message: haSignPostMessage
         };
      }

      public onSubmit(): IPromise<boolean> {
         const clusterSpec: any = this.clusterSpecBuilderService.buildClusterComputeResourceSpec(
               this.formState, true);

         this.setPageBusy(true);

         return this.mutationService.validate(this.clusterId,
               "com.vmware.vsphere.client.cluster.ClusterComputeResourceSpec",
               clusterSpec)
               .then((validationResult: any) => {
                  return this.onValidation(validationResult);
               })
               .then(() => {
                  this.mutationService.apply(this.clusterId,
                        "com.vmware.vsphere.client.cluster.ClusterComputeResourceSpec",
                        clusterSpec);
                  if (clusterSpec.name) {
                     this.mutationService.apply(this.clusterId,
                           "com.vmware.vsphere.client.mixed.ManagedEntityNameInfo",
                           {
                              name: clusterSpec.name
                           });
                  }
                  return this.$q.resolve(true);
               }, () => this.$q.resolve(false))
               .finally(() => {
                  this.setPageBusy(false);
               });
      }

      private onValidation(validationResult: any) {
         let title: string;
         if (!validationResult ||
               (!validationResult.error && (!validationResult.result ||
               validationResult.result.isValid === true))) {
            return this.$q.resolve();
         } else if (validationResult.error) {
            const message: string =  validationResult.error.localizedMessage ?
                  validationResult.error.localizedMessage : validationResult.error.message;
            this.showValidationMessages(message);
            return this.$q.reject();
         } else {
            if (validationResult.result.errorCode === this.clusterConstants.
                        editClusterErrorCodes.DRS_OFF_WITH_RESOURCE_POOLS) {
               return this.editDrsFileDownloadService.showYesNoCancelDialog(this.clusterId);
            } else if (validationResult.result.errorCode === this.clusterConstants.
                        editClusterErrorCodes.DPM_ON_WITH_HOSTS_STANDBY) {
               title = this.getString("drs.config.editDrs.dpmTurningOnCaption");
               return this.showConfirmationDialog(title,
                     validationResult.result.validationMessage,
                     validationResult.result.validationData);
            } else {
               const validationMessages: Array<string> = [validationResult.result.validationMessage];
               this.showValidationMessages(validationMessages);
               return this.$q.reject();
            }
         }
      }

      private showConfirmationDialog(
            title: string, message: string, invalidHosts: any): IPromise<any> {
         const deferred: any = this.$q.defer();

         const titleText: string = title;
         let messageText: string = message;

         _.each(invalidHosts, function (host) {
            messageText += "\n" + host;
         });

         const modalOptions: any = {
            title: titleText,
            message: messageText,
            submit: () => deferred.resolve(true),
            onCancel: () => deferred.reject(),
            preserveNewlines: true
         };

         this.clarityModalService.openConfirmationModal(modalOptions);

         return deferred.promise;
      }

      private showValidationMessages(messages: any): void {
         const validationErrorMessages: Array<any> = _.map(messages,
               (message: string) => {
                  return {
                     type: this.clarityConstants.notifications.type.ERROR,
                     text: message
                  };
               });
         this.$scope.modalOptions.isAlertClosed = false;
         this.$scope.modalOptions.alerts = validationErrorMessages;
      }

      private getLocationIcon(locationRef: any): string {
         return this.iconService.getByObjectRef(locationRef);
      }

      private setPageBusy(isBusy: boolean): void {
         this.$scope.modalOptions.submitDisabled = isBusy;
         this.loading = isBusy;
      }

      private getString(key: string): string {
         return this.i18nService.getString("ClusterUi", key);
      }

      private getHostMonitoring(state: string): string {
         return this.getString(state === this.haConstants.hostMonitoringState.ENABLED ?
               "ha.config.hostmonitoring.enabled" :
               "ha.config.hostmonitoring.disabled");
      }

      private getAdmissionControl(admissionControlEnabled: boolean): string {
         return this.getString(admissionControlEnabled ?
               "ha.config.admission.control.enabled" :
               "ha.config.admission.control.disabled");
      }
   }

   angular.module("com.vmware.vsphere.client.commonModule")
         .controller("EditClusterController", EditClusterController);
}