import VmHwSerialPort = h5_vm.VmHwSerialPort;

class VmHwSerialPortLabels {

   public initialize(i18n: Function) {
      this.REMOVING = i18n("RemoveDevice.DeviceRemoved");
      this.CONNECTED = i18n("ConnectDevice.Connected");
      this.START_CONNECTED = i18n("ConnectDevice.ConnectAtPowerOn");
      this.START_CONNECTED_SHORT = i18n("ConnectDevice.ConnectAtPowerOnShort");

      const page = (key: string) => {
         return i18n("SerialPortPage." + key);
      };

      this.STATUS = page("Status");
      this.CONNECTION = page("Connection");
      this.OUTPUT_FILE = page("ConnectedFile");
      this.FILE_PLACEHOLDER = page("ConnectedFile.Placeholder");
      this.BROWSE = page("Browse");
      this.PHYSICAL = page("Device");
      this.PIPE_NAME = page("PipeName");
      this.NEAR_END = page("NearEnd");
      this.FAR_END = page("FarEnd");
      this.IO_MODE = page("IOMode");
      this.YIELD_CPU=page("YieldCPU");
      this.DIRECTION = page("Direction");
      this.PORT_URI = page("PortURI");
      this.USE_VSPCURI = page("UseVSPC");
      this.VSPCURI = page("VSPCURI");

      const cfg = (key: string):string => {
         return i18n("SerialPortConfig." + key);
      };

      this.CLIENT = cfg("client");
      this.SERVER = cfg("server");
      this.PIPE_FAR_END_VM = cfg("Vm");
      this.PIPE_FAR_END_PROCESS = cfg("Process");

   }

   REMOVING:string;
   CONNECTED: string;
   START_CONNECTED:string;
   START_CONNECTED_SHORT:string;
   STATUS: string;
   CONNECTION: string;

   // file section
   OUTPUT_FILE: string;
   FILE_PLACEHOLDER: string;
   BROWSE: string;

   // device section
   PHYSICAL:string;

   // pipe section
   PIPE_NAME:string;
   NEAR_END:string;
   FAR_END:string;
   IO_MODE:string;

   // network section
   DIRECTION:string;
   PORT_URI:string;
   USE_VSPCURI:string;
   VSPCURI:string;

   //pipe section
   CLIENT:string;
   SERVER:string;
   PIPE_FAR_END_VM:string;
   PIPE_FAR_END_PROCESS:string;

   YIELD_CPU:string;
}
