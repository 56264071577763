/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace dvs_ui {

   import LacpUpgradeSpec = com.vmware.vsphere.client.dvs.api.spec.LacpUpgradeSpec;
   /**
    * Provides methods from creating the mutation spec sent to the backend
    * after the completion of the Enhance LACP Support wizard.
    */
   export class DvsUpgradeLacpWizardSpecFactory {

      public static $inject = ["defaultUriSchemeUtil"];

      constructor(private defaultUriSchemeUtil: any) {
      }

      /**
       * Builds the spec the Enhance LACP Support mutation operation.
       */
      public buildDvsLacpUpgradeSpec(model: DvsUpgradeLacpWizardModel): LacpUpgradeSpec {
         let upgradeSpec: LacpUpgradeSpec = new LacpUpgradeSpec();
         upgradeSpec.lag = model.lag;
         return upgradeSpec;
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .service("dvsUpgradeLacpWizardSpecFactory", DvsUpgradeLacpWizardSpecFactory);
}
