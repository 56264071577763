module cluster_ui {

   export class AddClusterVmOverridesManager {
      private clusterId: string;
      private clusterName: string;
      private selectedVms: any;
      private selectedVmsSpec: any;
      private vmSettingsSpec: any;

      public setClusterId(clusterId: string): void {
         this.clusterId = clusterId;
      }

      public getClusterId(): string {
         return this.clusterId;
      }

      public setClusterName(clusterName: string): void {
         this.clusterName = clusterName;
      }

      public getClusterName(): string {
         return this.clusterName;
      }

      public setSelectedVms(selectedVms: any): void {
         this.selectedVms = selectedVms;
      }

      public getSelectedVms(): any {
         return this.selectedVms;
      }

      public setVmSettingsSpec(vmSettingsSpec: any): void {
         this.vmSettingsSpec = vmSettingsSpec;
      }

      public getVmSettingsSpec(): any {
         return this.vmSettingsSpec;
      }

      public setSelectedVmsSpec(selectedVmsSpec: any): void {
         this.selectedVmsSpec = selectedVmsSpec;
      }

      public getSelectedVmsSpec(): any {
         return this.selectedVmsSpec;
      }

   }

   angular.module("com.vmware.vsphere.client.cluster")
         .factory("AddClusterVmOverridesManager",
               [
                  function () {
                     return Function.prototype.bind.apply(
                           AddClusterVmOverridesManager,
                           [undefined]
                     );
                  }
               ]);
}
