namespace license_ui {

   export class SerialKeyLicensesListController {

      public static $inject = [
            "$scope",
            "$q",
            "vcLicensingPrivilegesService",
            "commonActionBarService",
            "vuiConstants",
            "licensesManagementNavigationService",
            "licensingRouteObjectsFactoryService",
            "licensesManagementConstants",
            "licenseCommonUtil"
      ];

      private readonly ACTION_BAR_ACTIONS: string = "actions";
      private readonly REMOVE_LICENSE_ACTION_ID = "vsphere.core.administration.removeLicensesAction";
      private readonly RENAME_LICENSE_ACTION_ID = "vsphere.core.administration.renameLicenseAction";
      private readonly ADD_LICENSE_ACTION_ID = "vsphere.core.administration.addLicenseAction";
      private readonly SYNC_LICENSES_ACTION_ID = "vsphere.core.administration.syncFromPortal";

      public scopeProvider: string;
      public selectedTab: any;
      public focusedLicense: any;
      public focusedLicenseUri: string;
      public sortColumn: string;
      public selectedLicenses: any[] = [];
      public selectedLicensesUris: any[] = [];
      public actionBarOptions: any;

      private defaultEvaluationLicenseUri: string;
      private isVc65OrLater: boolean = false;
      private tabPaths: string[];

      constructor(private $scope: any,
                  private $q: any,
                  private vcLicensingPrivilegesService: any,
                  private commonActionBarService: any,
                  private vuiConstants: any,
                  private licensesManagementNavigationService: LicensesManagementNavigationService,
                  private licensingRouteObjectsFactoryService: LicensingRouteObjectsFactoryService,
                  private licensesManagementConstants: LicensesManagementConstants,
                  private licenseCommonUtil: any) {
      }

      public $onInit() {
         this.scopeProvider = this.$scope.masterDetailsViewContext.scopeProvider;
         this.tabPaths = this.$scope.masterDetailsViewContext.tabPaths;
         this.defaultEvaluationLicenseUri = this.licenseCommonUtil.getDefaultEvaluationLicenseUri();

         this.actionBarOptions = {
            actions: []
         };

         this.updateActionBar();
         // Only show the Sync action if we have
         // at least one VC with version >= 6.5.
         this.vcLicensingPrivilegesService.checkIfVc65OrLaterIsPresent(this.scopeProvider)
               .then((result: boolean) => {
                  this.isVc65OrLater = result;
                  this.updateActionBar();
               });

         this.licensesManagementNavigationService.registerNavigationPerformedEventListener(
               this.$scope,
               (licensingRouteObject: LicensingRouteObject) => {
                  this.handleNavigationRequest(licensingRouteObject);
               }
         );

         this.registerSelectedTabChangedEventListener();
         this.tryProcessNavigationRouteInfoInUrl();
      }

      public onSelectedLicensesChanged(selectedLicenseItems: any[]): void {
         if (!selectedLicenseItems || !selectedLicenseItems.length) {
            this.selectedLicenses = [];
         } else {
            this.selectedLicenses = selectedLicenseItems.map((selectedLicenseItem: any) => {
               return selectedLicenseItem && selectedLicenseItem.license;
            });
         }

         this.selectedLicensesUris = this.extractLicensesUris(this.selectedLicenses);
         this.updateActionBar();
      }

      public onFocusedLicenseChanged(focusedLicense: any): void {
         this.focusedLicense = focusedLicense && focusedLicense.license;
         this.focusedLicenseUri = this.getFocusedLicenseUri();
         if (focusedLicense) {
            this.$scope.masterDetailsViewContext.selectedItems = [ focusedLicense ];
         } else {
            this.$scope.masterDetailsViewContext.selectedItems = [];
         }

         this.triggerNavigationRequestToFocusedLicenseItem();
      }

      private updateActionBar() {
         if (this.actionBarOptions && this.actionBarOptions.actions && this.actionBarOptions.actions.length) {
            this.actionBarOptions.actions.forEach((action: any) => {
               if (action.actionId &&
                     action.actionId !== this.ADD_LICENSE_ACTION_ID && action.actionId !== this.SYNC_LICENSES_ACTION_ID) {
                  action.enabled = false;
               }
            });
         }

         let updateActionBarWithNoContextOptions: any = { actions: [] };
         let updateActionBarWithNoContext: any = this.commonActionBarService.updateActionBar(
               updateActionBarWithNoContextOptions,
               this.ACTION_BAR_ACTIONS,
               [],
               this.getActionsWithoutAvailabilityChecks());

         let updateActionBarWithLicensesUrisContextOptions: any = { actions: [] };
         let updateActionBarWithLicensesUrisContext: any = this.commonActionBarService.updateActionBar(
               updateActionBarWithLicensesUrisContextOptions,
               this.ACTION_BAR_ACTIONS,
               this.selectedLicensesUris,
               this.getActionsWithAvailabilityChecks());

         this.$q.all([updateActionBarWithNoContext, updateActionBarWithLicensesUrisContext])
               .then(() => {
                  this.actionBarOptions.actions = updateActionBarWithNoContextOptions.actions
                        .concat(updateActionBarWithLicensesUrisContextOptions.actions);
               });
      }

      private getActionsWithoutAvailabilityChecks(): any[] {
         let actionDefs: any[] = [];

         actionDefs.push({
            actionId: this.ADD_LICENSE_ACTION_ID,
            getActionInvocationContext: () => {
               return this.scopeProvider;
            },
            isActionVisible: (actionDef: any) => {
               return !!actionDef;
            }
         }, {
            actionId: this.SYNC_LICENSES_ACTION_ID,
            getActionInvocationContext: () => this.scopeProvider,
            isActionVisible: (actionDef: any) => !!actionDef && this.isVc65OrLater
         });

         actionDefs.push(this.vuiConstants.actions.SEPARATOR);

         actionDefs.push({
            actionId: this.RENAME_LICENSE_ACTION_ID,
            getActionInvocationContext: () => {
               return this.selectedLicenses[0];
            },
            isActionVisible: (actionDef: any) => {
               return !!(this.selectedLicensesUris.length > 0 && actionDef);
            },
            isActionAvailable: (actionDef: any) => {
               return !!(this.selectedLicensesUris.length === 1
                     && this.selectedLicensesUris[0] !== this.defaultEvaluationLicenseUri
                     && actionDef);
            }
         });

         return actionDefs;
      }

      private getActionsWithAvailabilityChecks(): any[] {
         let actionDefs: any[] = [];

         actionDefs.push({
            actionId: this.REMOVE_LICENSE_ACTION_ID,
            isActionVisible: (actionDef: any) => {
               return !!(this.selectedLicensesUris.length > 0 && actionDef);
            },
            getActionInvocationContext: () => {
               return this.selectedLicensesUris;
            }
         });

         return actionDefs;
      }

      private handleNavigationRequest(licensingRouteObject: LicensingRouteObject | null): void {
         if (licensingRouteObject
               && this.licensesManagementNavigationService.canNavigationRequestInLicensingRouteBeHandled(this.tabPaths, licensingRouteObject)) {
            if (licensingRouteObject.itemId) {
               this.focusedLicenseUri = licensingRouteObject.itemId;
            }

            const additionalParams: any = this.licensesManagementNavigationService.extractAdditionalParametersFromRoute();
            if (additionalParams.sortColumn) {
               this.sortColumn = additionalParams.sortColumn;
            }
         }
      }

      private getFocusedLicenseUri(): string {
         return this.focusedLicense && this.focusedLicense.uri;
      }

      private extractLicensesUris(licenses: any[]): string[] {
         let licensesUris: string[] = [];
         if (licenses && licenses.length > 0) {
            licensesUris = licenses.map((license: any) => {
               return license && license.uri;
            });
         }

         return licensesUris;
      }

      private triggerNavigationRequestToFocusedLicenseItem(): void {
         const licensesListRouteObject: LicensingRouteObject = this.licensingRouteObjectsFactoryService
               .createLicensesListLicensingRouteObject(this.focusedLicenseUri);
         this.licensesManagementNavigationService
               .emitLicensesManagementNavigationRequestOnScope(this.$scope, licensesListRouteObject);
      }

      private registerSelectedTabChangedEventListener(): void {
         this.$scope.$on(this.licensesManagementConstants.LICENSES_MANAGEMENT_SELECTED_TAB_CHANGED_EVENT,
               (event: any, data: SelectedTabInfo) => {
                  this.selectedTab = data.selectedTab;
                  const shouldSelectCurrentTabAsDefault: boolean = data.isDefault && this.isCurrentTabDefaultTab();
                  if (this.focusedLicenseUri && (shouldSelectCurrentTabAsDefault || this.isCurrentTabSelected())) {
                     this.triggerNavigationRequestToFocusedLicenseItem();
                  }
               }
         );
      }

      private tryProcessNavigationRouteInfoInUrl(): void {
         const licensingRouteObject: LicensingRouteObject | null =
               this.licensesManagementNavigationService.extractLicensingRouteObjectFromUrl();
         this.handleNavigationRequest(licensingRouteObject);
      }

      private isCurrentTabDefaultTab(): boolean {
         return !(this.tabPaths && this.tabPaths.length);
      }

      private isCurrentTabSelected(): boolean {
         return !!(this.tabPaths && this.tabPaths.length > 0
               && this.selectedTab && this.selectedTab.id === this.tabPaths[this.tabPaths.length-1]);
      }
   }

   angular.module('com.vmware.vsphere.client.licenseUi').controller(
         'SerialKeyLicensesListController',
         SerialKeyLicensesListController
   );
}
