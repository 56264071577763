/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
(function () {
   'use strict';
   angular.module('com.vmware.vsphere.client.storage')
         .controller('AddDatastoreReadyToCompletePageController', [
            '$scope',
            'i18nService',
            function ($scope, i18nService) {
               var self = this;
               self.summaryData = $scope.activeWizardManager.getReadyToCompletePageData();
               self.summaryTitle = i18nService.getString('StorageUi', 'addDatastoreWizard.readyToComplete.summaryTitle');
            }
         ]);
})();
