namespace h5_vm {

   export class LinuxHardwareClockOption {

      public readonly value: string;

      private static readonly UTC_VALUE: string = "UTC";
      private static readonly LOCAL_TIME_VALUE: string = "LocalTime";

      public static readonly UTC: LinuxHardwareClockOption =
         new LinuxHardwareClockOption(LinuxHardwareClockOption.UTC_VALUE);
      public static readonly LOCAL_TIME: LinuxHardwareClockOption =
         new LinuxHardwareClockOption(LinuxHardwareClockOption.LOCAL_TIME_VALUE);

      constructor(value: string) {
         this.value = value;
      }
   }
}
