(function() {
   'use strict';
   angular.module('com.vmware.vsphere.client.host').controller('SwapFileLocationController',
         SwapFileLocationController);

   SwapFileLocationController.$inject =
         ['$scope', 'dataService', 'i18nService', 'defaultUriSchemeUtil'];

   function SwapFileLocationController($scope, dataService, i18n, defaultUriSchemeUtil) {
      var self = this;
      var hostId = $scope._route.objectId;

      self.objectsToWatch = [hostId];
      self.headerOptions = {
         title: i18n.getString('HostUi', 'settings.swapfileHeader'),
         objectId: hostId,
         actions: [{ actionUid: 'vsphere.core.host.actions.editSwapfileLocation',
             customLabel: i18n.getString('HostUi', 'settings.edit')
         }]
      };

      self.data = {};
      self.isStandalone = true;
      self.isDatastore = false;
      self.swapfileLocation = null;
      self.clusterInfo = null;
      self.liveRefreshProperties = ['config.localSwapDatastore',
         'config.fileSystemVolume.mountInfo'];

      self.getViewData = getViewData;

      getViewData();

      function getViewData() {
         dataService.getData(
               hostId,
               'com.vmware.vsphere.client.h5.host.model.SwapfileLocationData'
         ).then(function(data) {
            self.data = data;
            processData();
         });
      }

      function processData() {
         if (!self.data) {
            return;
         }

         self.isStandalone = self.data.isStandalone;

         if (self.data.vmSwapPlacement === "vmDirectory") {
            self.swapfileLocation = getLocation('vmDir');
            self.isDatastore = false;
            self.clusterInfo = self.isStandalone ? null : getMessages('clusteredVmDirectory');
         } else {
            self.clusterInfo = self.isStandalone ? null : getMessages('clusteredDatastore');
            setSwapfileDatastore();
         }
      }

      function setSwapfileDatastore() {
         if (!self.data.localSwapDatastore) {
            self.swapfileLocation = getLocation('noDatastore');
            self.isDatastore = false;
            return;
         }
         self.swapfileLocation = self.data.datastoreName;
         self.isDatastore = true;
         self.datastoreRef = defaultUriSchemeUtil
               .getVsphereObjectId(self.data.localSwapDatastore);
      }

      function getMessages(state) {
         return {
            clusteredDatastore: i18n.getString('HostUi', 'swapfile.hostInCluster.dataStore'),
            clusteredVmDirectory: i18n.getString('HostUi', 'swapfile.hostInCluster.vmDirectory')
         }[state];
      }

      function getLocation(location) {
         return {
            vmDir: i18n.getString('HostUi', 'swapfile.vmDirectory'),
            noDatastore: i18n.getString('HostUi', 'swapfile.noDataStore')
         }[location];
      }
   }
})();
