/* Copyright 2015 VMware, Inc. All rights reserved. -- VMware Confidential */

(function () {
   'use strict';

   angular
      .module('com.vmware.vsphere.client.vm')
      .controller('VmProvisioningFinishDeployFromTemplateController',
            VmProvisioningFinishDeployFromTemplateController);

   VmProvisioningFinishDeployFromTemplateController.$inject = [
         '$scope',
         'creationTypeConstants'
   ];

   function VmProvisioningFinishDeployFromTemplateController(
         $scope, creationTypeConstants) {
      var model = $scope.vmProvisioningFinishDeployFromTemplatePageModel;
      var self = this;
      self.provisioningTypeName = model.presentProvisioningType();

      if ($scope.vmParams.getCreationType() === creationTypeConstants.DEPLOY_VM_FROM_TEMPLATE ||
          $scope.vmParams.getCreationType() === creationTypeConstants.DEPLOY_VM_FROM_VAPP) {
         self.templateName = $scope.wizardViewData.getSelectedTemplate().templateName;
      } else if ($scope.vmParams.getCreationType() === creationTypeConstants.DEPLOY_VM_FROM_OVF) {
         self.templateName = $scope.vmParams.getTemplateName();
      }

      self.templateVMName = model.getVmName();
      self.downloadSize = $scope.productDetailsPageModel.dataModel.getDownloadSize();
      var selectedDiskFormat = $scope.datastorePageModel.form.diskFormatSettings.selectedDiskFormat;
      var isThinDeployment = selectedDiskFormat && selectedDiskFormat.type === "thin";
      self.scaleoutConfiguration = $scope.wizardViewData.getSclaeoutConfigurationFromLibraryCommonContext();
      self.sizeOnDisk = $scope.productDetailsPageModel.dataModel.getSizeOnDisk(isThinDeployment);
      self.computeResource = $scope.vmParams.getComputeResourceName();
      self.vmFolder = $scope.vmParams.getTargetInformation().name;
      self.storageMappings = model.getStorageDetails();
      self.networkDevices = model.getNetworkDetails($scope.networkPageModel.getNetworkMappingParams());
      self.ipDetails = model.getIpAllocationDetails($scope.networkPageModel.getIpAllocationParams());
      var spec = model.getCustomizationSpec();
      self.gosCustomizationSpecName = spec ? spec.name : undefined;
      self.isGosCustomizationEnabled = !_.isEmpty(self.gosCustomizationSpecName);

   }
})();
