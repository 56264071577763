/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace dvs_ui {

   import DvpgTrafficRuleModel = com.vmware.vsphere.client.h5.network.dvportgroup.trafficfilter.model.DvpgTrafficRuleModel;

   export class DvpgTrafficQualifiersComponent {

      public controller: any;
      public bindings: any;
      public templateUrl: string;

      constructor() {
         this.bindings = {
            model: "<",
            onIpValidate: "=",
            onMacValidate: "="
         };
         this.controller = DvpgTrafficQualifiersController;
         this.templateUrl =
               "dvs-ui/resources/dvs/dvpg/trafficfilter/common/" +
               "dvpgTrafficQualifiersTemplate.html";
      }
   }

   class DvpgTrafficQualifiersController {

      static $inject = [
         "$scope",
         "i18nService"
      ];

      public i18n: Function;

      public model: DvpgTrafficRuleModel;
      public onIpValidate: any;
      public onMacValidate: any;

      constructor(private $scope: any,
                  private i18nService: any) {
         this.i18n = this.i18nService.getString;
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .component("dvpgTrafficQualifiers", new DvpgTrafficQualifiersComponent());
}
