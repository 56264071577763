(function () {
    'use strict';
    angular
        .module('com.vmware.vsphere.client.host')
        .controller('MaintenanceModeConfirmationController',
            MaintenanceModeConfirmationController);

    MaintenanceModeConfirmationController.$inject = [
        '$scope',
        'maintenanceModeConfirmationService',
        'maintenanceModeConstants',
        'i18nService'];

    function MaintenanceModeConfirmationController(scope,
                                                   mmcService,
                                                   mmConstants,
                                                   i18nService) {

        var self = this;
        self.loading = true;
        self.shouldMigrateVms = true;
        scope.modalOptions.onSubmit = onOkClicked;
        var targets = scope.modalOptions.dialogData.availableTargets;
        self.hostId = targets[0];
        self.precheckUnsupportedImage = "";
        self.precheckUnsupportedText = "";

        // Pre-check is not supported for multiple hosts.
        self.multipleHostsSelected = targets.length > 1;
        if (self.multipleHostsSelected) {
            self.precheckUnsupportedImage = "vsphere-icon-status-warning";
            self.precheckUnsupportedText = i18nService.getString(
                'HostUi',
                'ConfirmMaintenanceForm.vsanEvacuation.precheck.unsupported'
            );
        }

        mmcService.retrieveVsanData(targets, onVsanDataRetrieved);

        function onVsanDataRetrieved(result) {
            var hostCount = result.hostCount;
            var drsEnabled = result.drsEnabled;
            self.hostName = result.hostName;
            self.clusterMor = result.clusterMor;
            self.showMigrationConfirmation = !!(hostCount && drsEnabled);
            self.isVsanSupportedAndEnabled = !!result.isVsanSupportedAndEnabled;
            // resize the dialog due to large dialog content
            if (self.isVsanSupportedAndEnabled) {
               self.isPreCheckEvacuationSupported = !!result.isPreCheckEvacuationSupported;
               scope.modalOptions.size = 'lg';
            }

            self.loading = false;
        }

        self.onVsanSelectedDecommisionModeChanged = function(event) {
            self.vSanEvacuationType = event;
        };

       self.navigateToPrecheckView = function() {
          scope.closeModal();
          mmcService.navigateToPrecheckView(self.clusterMor, targets[0], self.vSanEvacuationType);
       };

        function onOkClicked() {
            return mmcService.onOKClicked(
                targets,
                self.isVsanSupportedAndEnabled,
                self.shouldMigrateVms,
                self.showMigrationConfirmation,
                self.vSanEvacuationType ? self.vSanEvacuationType : mmcService.getDefaultEvacuationType());
        }
    }
})();
