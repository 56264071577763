(function() {
   'use strict';

   angular.module('com.vmware.vsphere.client.vm')
         .service('vmVuiWizardPageBuilder', vmVuiWizardPageBuilder);

   vmVuiWizardPageBuilder.$inject = [
      'wizardPageService',
      '$q'
   ];

   function vmVuiWizardPageBuilder(wizardPageService, $q) {
      return {
         buildVuiWizardPage: function(wizardConfig, options) {
            return {
               title: options.title,
               description: options.description,
               contentUrl: options.contentUrl,
               defaultState: options.defaultState,
               onCommit: function() {
                  return commitIntermediatePage(wizardConfig, options);
               },
               disableNext: function() {
                  return (options.model.hasOwnProperty('pageFinishedLoading') && !options.model.pageFinishedLoading())
                        || (options.model.hasOwnProperty('validationFinishedLoading') && !options.model.validationFinishedLoading());
               }
            };
         },
         buildVuiWizardFinishPage: function(wizardConfig, options) {
            return {
               title: options.title,
               description: options.description,
               contentUrl: options.contentUrl,
               onCommit: function() {
                  return commitFinalPage(wizardConfig, options);
               },
               finishReady: function() {
                  return !options.model.hasOwnProperty('pageFinishedLoading') || options.model.pageFinishedLoading();
               }
            };
         }
      };

      function validatePage(pageModel, wizardConfig) {
         return $q.when(pageModel.validatePage()).then(function(validationResult) {
            if (validationResult && validationResult.error) {
               wizardPageService.markPageIncompleteWithError(wizardConfig, validationResult.error);
               return $q.reject(validationResult);
            } else {
               return validationResult;
            }
         });
      }

      function commitIntermediatePage(wizardConfig, options) {
         return validatePage(options.model, wizardConfig).then(function(validationResult) {
            return $q.when(options.model.submitPage(validationResult)).then(function(result) {
               if (wizardConfig.currentPage && wizardConfig.currentPage.title !==
                     options.title) {
                  return $q.reject();
               }
               if (result.invalidateNextPages) {
                  wizardPageService.invalidateNextPages(wizardConfig, result.wizardFlow, options.title);
               }
               wizardPageService.markPageComplete(wizardConfig, result.wizardFlow);
               return true;
            });
         }, function(validationResult) {
            if (validationResult && validationResult.invalidateNextPages) {
               wizardPageService.invalidateNextPages(wizardConfig, validationResult.wizardFlow, options.title);
            }
            return false;
         });
      }

      function commitFinalPage(wizardConfig, options) {
         return validatePage(options.model, wizardConfig).then(function() {
            return options.model.submitPage();
         });
      }
   }
}());
