/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace h5_vm {

   import VirtualNVDIMM = com.vmware.vim.binding.vim.vm.device.VirtualNVDIMM;
   import CapabilityBasedProfile = com.vmware.vim.binding.pbm.profile.CapabilityBasedProfile;
   import SubProfileCapabilityConstraints = com.vmware.vim.binding.pbm.profile.SubProfileCapabilityConstraints;
   import SubProfileCapabilityConstraints$SubProfile = com.vmware.vim.binding.pbm.profile.SubProfileCapabilityConstraints$SubProfile;
   import CapabilityInstance = com.vmware.vim.binding.pbm.capability.CapabilityInstance;
   import VirtualDisk$LocalPMemBackingInfo = com.vmware.vim.binding.vim.vm.device.VirtualDisk$LocalPMemBackingInfo;
   import VmConfigContext = com.vmware.vsphere.client.vm.config.VmConfigContext;
   import GuestOsDescriptor = com.vmware.vim.binding.vim.vm.GuestOsDescriptor;
   import VirtualMachine$PowerState = com.vmware.vim.binding.vim.VirtualMachine$PowerState;

   export class PmemUtilService {

      public static $inject: string[] = [
         "featureFlagsService"
      ];

      public static readonly NVDIMM: string = "VirtualNVDIMM";
      public static readonly NVDIMM_CONTROLLER: string = "VirtualNVDIMMController";

      // The lower case name of the PMem storage policy capability namespace.
      // Lower case is used, so that changes like going from "PMem" to "PMEM"
      // don't affect us.
      private static readonly PMEM_NAMESPACE_LOWERCASE: String = "pmem";

      constructor(private featureFlagsService: any) {
      }

      public findOption(vmConfigEnvironment: any, type: string): any {
         let deviceOptions: Array<any> =
               vmConfigEnvironment.configOption.hardwareOptions.virtualDeviceOption;
         for (let deviceOption of deviceOptions) {
            if (deviceOption.type.wsdlName === type) {
               return deviceOption;
            }
         }
         return undefined;
      }

      public getNvdimmController(virtualMachineDevices: any): any {
         let nvdimmControllerArray: Array<any> =
               virtualMachineDevices.devicesOfType(PmemUtilService.NVDIMM_CONTROLLER);
         if (nvdimmControllerArray.length) {
            // Only one NVDIMM controller is allowed
            return nvdimmControllerArray[0];
         }
         return undefined;
      }

      public getPmemStorageProfile(storagePolicies: Array<any>): any {
         for (let storagePolicy of storagePolicies) {
            if (this.isPMemStoragePolicy(storagePolicy)) {
               return storagePolicy;
            }
         }
         return undefined;
      }

      /**
       * Finds out whether a given storage policy object is a PMem storage policy.
       *
       * @param storagePolicy The storage policy object that is being tested.
       *
       * @return <code>true</code> if the given storage policy is а PMem storage
       *       policy (which currently means "uses the PMem namespace"),
       *       <code>false</code> otherwise.
       */
      public isPMemStoragePolicy(storagePolicy: any): boolean {
         if (!storagePolicy || !storagePolicy.profileObj) {
            return false;
         }
         let capBasedProfile: CapabilityBasedProfile =
               storagePolicy.profileObj as CapabilityBasedProfile;
         if (capBasedProfile === null) {
            return false;
         }
         let profileConstraints: SubProfileCapabilityConstraints =
               capBasedProfile.constraints as SubProfileCapabilityConstraints;
         if (!profileConstraints || !profileConstraints.subProfiles) {
            return false;
         }
         for (let subProfile of profileConstraints.subProfiles) {
            if (!subProfile || !subProfile.capability) {
               continue;
            }
            for (let capability of subProfile.capability) {
               if (!capability
                     || !capability.id
                     || !capability.id.namespace) {
                  continue;
               }
               if (capability.id.namespace.toLowerCase()
                     === PmemUtilService.PMEM_NAMESPACE_LOWERCASE) {
                  return true;
               }
            }
         }
         return false;
      }

      public getAvailablePmemCapacity(vmConfigContext: VmConfigContext): number {
         return vmConfigContext.environment
               .configTarget.availablePersistentMemoryReservationMB;
      }

      public getSupportedPmemByGuestOs(guestOs: GuestOsDescriptor): number {
         return guestOs.supportedMaxPersistentMemoryMB;
      }

      public isPmemDisk(device: any /* VirtualDisk */): boolean {
         if (!device || !device.backing) {
            return false;
         }
         let diskBacking: any = device.backing;
         return diskBacking._type
               === "com.vmware.vim.binding.vim.vm.device.VirtualDisk$LocalPMemBackingInfo";
      }

      public filterOutPmemProfileIfNeeded(storageProfiles: Array<any>,
            vmConfigContext: VmConfigContext, disk: any): Array<any> {
         if (this.shouldFilterOutPmemStoragePolicy(vmConfigContext, disk)) {
            return this.filterOutPmemStoragePolicy(storageProfiles);
         } else {
            return storageProfiles;
         }
      }

      private filterOutPmemStoragePolicy(storageProfiles: Array<any>): Array<any> {
         if (!storageProfiles
               || storageProfiles.length === 0) {
            return storageProfiles;
         }
         return _.filter(storageProfiles, (profile) => {
            return !this.isPMemStoragePolicy(profile);
         });
      }

      /**
       * Pmem Storage policy should be removed from the list of storage policies
       * when: the VM is poweredOn, because backend doesn't allow hot add of pmem disks.
       * When the disk is RDM, because the disk is real hardware, that cannot be moved
       * to Pmem for obvious reasons.
       * When the disk is not freshly added, but is old (already existing), because the
       * backend doesn't allow migration of an existing disk to Pmem, same goes for
       * existing disk created by a source file.
       *
       * Removing the Pmem Storage Policy doesn't allow the user to submit incorrect
       * tasks to the VC.
       */
      private shouldFilterOutPmemStoragePolicy(
            vmConfigContext: VmConfigContext, disk: any): boolean {
         let isVmPoweredOn: boolean = vmConfigContext.environment.powerState === ("poweredOn" as VirtualMachine$PowerState);

         if (disk.isRDMDisk() || disk.isCreatedFromExistingDisk()) {
            return true;
         }

         if (isVmPoweredOn && disk.isNew()) {
            return true;
         }

         if (!isVmPoweredOn && !disk.isPmemDisk() && !disk.isNew()) {
            return true;
         }

         return false;
      }

   }

   angular.module("com.vmware.vsphere.client.vm")
         .service("pmemUtilService", PmemUtilService);
}

