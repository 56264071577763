module ds_cluster_ui {

   import WizardPageControllerAbstract =
         ds_cluster_ui.WizardPageControllerAbstract;
   import IPromise = angular.IPromise;

   class NameAndLocationPageController extends WizardPageControllerAbstract {

      public static $inject = ["$scope", "createDsClusterWizardManager", "vuiConstants",
            "dataService", "simpleValidationCommit", "i18nService", "mutationService", "$q",
            "createDsClusterPageConstants", "dsClusterConstants"];
      public location: DsClusterLocation;
      private dcId: string;

      constructor(private $scope: ScopeRoute,
            createDsClusterWizardManager: CreateDsClusterWizardManager,
            private vuiConstants: any, private dataService: any, simpleValidationCommit: any,
            private i18nService: any, private mutationService: any, private $q: any,
            private createDsClusterPageConstants: any, private dsClusterConstants: any) {
         super(createDsClusterWizardManager, simpleValidationCommit);

         this.initModel();
         this.initPageSubmit();
      }

      protected getValidationErrors(): IPromise<string[]> {
         // name length check
         if (this.$scope.model.name.length <= 0) {
            return this.$q.resolve([this.i18nService.getString("DsClusterUi",
                  "createDsClusterWizard.nameAndLocationPage.validation.nameRequired")]);
         }

         let dcName: string = (this.location !== undefined) ? this.location.name : "";
         let duplicateNameError: string = this.i18nService.getString("DsClusterUi",
               "createDsClusterWizard.nameAndLocationPage.validation.duplicateDsClusterName",
               dcName);

         let datastoreNameSpec: any = {
            datastoreClusterName: this.$scope.model.name
         };

         let validationPromise: IPromise<string[]> =
               this.mutationService.validate(
                     this.dcId,
                     this.dsClusterConstants.NAME_AND_LOCATION_VALIDATE_SPEC,
                     datastoreNameSpec
               ).then((validationResult: any) => {
                        if (!!validationResult && (!validationResult.result
                              || !!validationResult.error)) {
                           return this.$q.resolve([duplicateNameError]);
                        } else {
                           return this.$q.reject();
                        }
                     },
                     () => this.$q.reject()
               );

         return validationPromise;
      }

      protected getCurrentPageIndex(): number {
         return this.createDsClusterPageConstants.NAME_AND_LOCATION_INDEX;
      }

      private initModel(): void {
         this.$scope.model = this.createDsClusterWizardManager.getModel();
         this.dcId = this.$scope.model.objectId;

         this.dataService.getProperties(this.dcId, ["name", "primaryIconId"])
               .then((data: DsClusterLocation) => {
                  this.location = data;
               });

         this.$scope.$watch("model.name", () => {
            this.getValidationErrors().then(() => {
               this.setWizardPageState(this.vuiConstants.wizard.pageState.INCOMPLETE);
            }, () => {
               this.setWizardPageState(this.vuiConstants.wizard.pageState.COMPLETED);
            });
         });

         this.$scope.$watch("model.sdrsEnabled", () => {
            if (this.$scope.model.sdrsEnabled !== undefined) {
               this.createDsClusterWizardManager.changeSdrsPagesVisibility(
                     this.$scope.model.sdrsEnabled);
            }
         });
      }
   }

   angular.module("com.vmware.vsphere.client.dsCluster")
         .controller("NameAndLocationPageController", NameAndLocationPageController);
}
