angular.module('com.vmware.vsphere.client.vm').factory('virtualDeviceInflator', [
   'VirtualVideoCard',
   'VirtualDisk',
   'VirtualCdrom',
   'VirtualUSBClass',
   'VirtualTPM',
   'VirtualNvdimmWrapper',
   'EditableVirtualDevice',
   'VirtualController',
   'virtualDiskInflator',
   'vmDeviceInfoService',
   'virtualControllerInflator',
   'virtualNetworkDeviceInflator',
   'virtualSCSIControllerInflator',
   'VmHwSerialPort',
   'VirtualPCITs',
   function(
      VirtualVideoCard: any,
      VirtualDisk: any,
      VirtualCdrom: any,
      VirtualUSBClass: any,
      VirtualTPM: any,
      VirtualNvdimmWrapper: any,
      EditableVirtualDevice: any,
      VirtualController: any,
      virtualDiskInflator: any,
      vmDeviceInfoService: any,
      virtualControllerInflator: any,
      virtualNetworkDeviceInflator: any,
      virtualSCSIControllerInflator: any,
      VmHwSerialPort: any,
      VirtualPCITs: any
   ) {
      return {
         inflate: function(rawDevice: any, additionalInfo: any) {
            additionalInfo = additionalInfo || {};

            var virtualDevice: any;
            if (vmDeviceInfoService.isDeviceSubclassOf(rawDevice, 'VirtualDisk')) {
               virtualDevice = virtualDiskInflator.build(
                     rawDevice,
                     additionalInfo.storageProfiles,
                     additionalInfo.storageProfileAssignments
               );
            } else if (vmDeviceInfoService.isDeviceSubclassOf(rawDevice, 'VirtualVideoCard')) {
               virtualDevice = new VirtualVideoCard(rawDevice, additionalInfo.isScheduledTask);
            } else if (vmDeviceInfoService.isDeviceSubclassOf(rawDevice, 'VirtualSerialPort')) {
               virtualDevice = new VmHwSerialPort(rawDevice);
            } else if (vmDeviceInfoService.isDeviceSubclassOf(rawDevice, 'VirtualPCIPassthrough')) {
               virtualDevice = new VirtualPCITs(rawDevice);
            } else if (vmDeviceInfoService.isDeviceSubclassOf(rawDevice, 'VirtualEthernetCard')) {
               virtualDevice = virtualNetworkDeviceInflator.build(rawDevice, additionalInfo.availableNetworks);
            } else if(vmDeviceInfoService.isDeviceSubclassOf(rawDevice, 'VirtualNVDIMMController')) {
               virtualDevice = new EditableVirtualDevice(rawDevice);
            } else if (vmDeviceInfoService.isDeviceSubclassOf(rawDevice, 'VirtualSCSIController')) {
               virtualDevice = virtualSCSIControllerInflator.build(rawDevice, additionalInfo.deviceOptionsByDeviceType);
            } else if (vmDeviceInfoService.isDeviceSubclassOf(rawDevice, 'VirtualController')) {
               virtualDevice = virtualControllerInflator.build(rawDevice, additionalInfo.deviceOptionsByDeviceType);
            } else if (vmDeviceInfoService.isDeviceSubclassOf(rawDevice, 'VirtualCdrom')) {
               virtualDevice = new VirtualCdrom(rawDevice);
            } else if (vmDeviceInfoService.isDeviceSubclassOf(rawDevice, 'VirtualUSB')) {
               virtualDevice = new VirtualUSBClass(rawDevice, null);
            } else if (vmDeviceInfoService.isDeviceSubclassOf(rawDevice, 'VirtualTPM')) {
               virtualDevice = new VirtualTPM(rawDevice);
               virtualDevice.state = virtualDevice.CONNECTED;
            } else if (vmDeviceInfoService.isDeviceSubclassOf(rawDevice, 'VirtualNVDIMM')) {
               virtualDevice = new VirtualNvdimmWrapper(rawDevice);
            } else {
               virtualDevice = new EditableVirtualDevice(rawDevice);
            }
            return virtualDevice;
         },

         inflateDevices: function(this: any, devices: any, additionalInfo: any) {
            var self = this;
            return _.map(devices, function(device) {
               return self.inflate(device, additionalInfo);
            });
         }
      };
   }
]);
