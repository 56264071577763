/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
(function () {
   'use strict';
   angular.module('com.vmware.vsphere.client.network')
         .controller('AddPnicToDvsPageController', AddPnicToDvsPageController);

   AddPnicToDvsPageController.$inject = [
      '$scope',
      'i18nService',
      'physicalAdaptersListService'];

   function AddPnicToDvsPageController($scope, i18nService, physicalAdaptersListService) {

      if (!$scope.addPnicToDvsPageModel.isInitialized) {
         initUplinkPortMapping();
      }

      function initUplinkPortMapping() {
         $scope.wizardConfig.loading = true;
         physicalAdaptersListService.getProxySwitchUplinkData($scope.hostId,
               $scope.addNetworkingSpec.selectedStandardSwitch.dvsRef,
               $scope.addNetworkingSpec.selectedStandardSwitch.key)
               .then(function (proxySwitchUplinkData) {

                  $scope.addPnicToDvsPageModel.portMapping = proxySwitchUplinkData;
                  $scope.addPnicToDvsPageModel.portMapping.addedMappings = {};

                  $scope.addPnicToDvsPageModel.isInitialized = true;
                  $scope.wizardConfig.loading = false;
               });
      }

      return this;
   }
})();
