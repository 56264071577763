(function () {
   'use strict';

   /**
    * Service that provides information pertaining to VM monitoring.
    */
   angular.module('com.vmware.vsphere.client.cluster')
      .service('haVmMonitoringService', haVmMonitoringService);

   haVmMonitoringService.$inject = ['i18nService', 'haConstants'];

   function haVmMonitoringService(i18nService, haConstants) {

      var vmMonitoringStateDescriptions = {};
      vmMonitoringStateDescriptions[haConstants.vmMonitoring.DISABLED] = '';
      vmMonitoringStateDescriptions[haConstants.vmMonitoring.VM_ONLY] =
         i18nService.getString('ClusterUi',
            'ha.config.failures.responses.vm.monitoring.vm.monitoring.check.desc');
      vmMonitoringStateDescriptions[haConstants.vmMonitoring.VM_AND_APP] =
         i18nService.getString('ClusterUi',
            'ha.config.failures.responses.vm.monitoring.app.monitoring.check.desc');

      var sensitivityTypes = {
         PRESET: 0,
         CUSTOM: 1
      };

      var presetLevels = {
         LOW: 0,
         MEDIUM: 1,
         HIGH: 2,
         CUSTOM: 3
      };

      var sensitivityLevelsMap = {};
      sensitivityLevelsMap[presetLevels.LOW] = {
         failure_interval: haConstants.vmMonitoringSensitivity.low.FAILURE_INTERVAL,
         min_uptime: haConstants.vmMonitoringSensitivity.low.MIN_UPTIME,
         max_failures: haConstants.vmMonitoringSensitivity.low.MAX_FAILURES,
         failure_window: haConstants.vmMonitoringSensitivity.low.FAILURE_WINDOW
      };
      sensitivityLevelsMap[presetLevels.MEDIUM] = {
         failure_interval: haConstants.vmMonitoringSensitivity.medium.FAILURE_INTERVAL,
         min_uptime: haConstants.vmMonitoringSensitivity.medium.MIN_UPTIME,
         max_failures: haConstants.vmMonitoringSensitivity.medium.MAX_FAILURES,
         failure_window: haConstants.vmMonitoringSensitivity.medium.FAILURE_WINDOW
      };
      sensitivityLevelsMap[presetLevels.HIGH] = {
         failure_interval: haConstants.vmMonitoringSensitivity.high.FAILURE_INTERVAL,
         min_uptime: haConstants.vmMonitoringSensitivity.high.MIN_UPTIME,
         max_failures: haConstants.vmMonitoringSensitivity.high.MAX_FAILURES,
         failure_window: haConstants.vmMonitoringSensitivity.high.FAILURE_WINDOW
      };

      /**
       * Retrieves objects for all of the available levels.
       *
       * @returns {Array}
       */
      function getMonitoringLevels() {
         return _.map(_.values(haConstants.vmMonitoring), buildLevel);
      }

      /**
       * Retrieves objects for all of the available levels for a VM override that are not
       * above the cluster setting.
       *
       * @returns {Array}
       */
      function getVmOverrideMonitoringLevels(clusterVmMonitoringLevel) {
         var eligibleLevels = [haConstants.vmMonitoring.DISABLED];

         if (clusterVmMonitoringLevel !== haConstants.vmMonitoring.DISABLED) {
            eligibleLevels.push(haConstants.vmMonitoring.VM_ONLY);

            if (clusterVmMonitoringLevel === haConstants.vmMonitoring.VM_AND_APP) {
               eligibleLevels.push(haConstants.vmMonitoring.VM_AND_APP);
            }
         }

         return _.map(eligibleLevels, buildLevel);
      }

      /**
       * Function to compute the VM Monitoring sensitivity level.
       * It is based on specific settings for failure intervals, max failure window,
       * the number of failures and the minimum uptime.
       */
      function getHaMonitoringSensitivityLevel(vmToolsMonSettings) {

         var sensitivityOptions = buildSensitivityOptionsObject(vmToolsMonSettings);

         if (_.isEqual(haConstants.vmMonitoringSensitivity.low,
               sensitivityOptions)) {
            return presetLevels.LOW;
         } else if (_.isEqual(haConstants.vmMonitoringSensitivity.medium,
               sensitivityOptions)) {
            return presetLevels.MEDIUM;
         } else if (_.isEqual(haConstants.vmMonitoringSensitivity.high,
               sensitivityOptions)) {
            return presetLevels.HIGH;
         }

         return presetLevels.CUSTOM;
      }

      function buildSensitivityOptionsObject(vmToolsMonSettings) {
         return {
            FAILURE_INTERVAL: vmToolsMonSettings.vmToolsMonitorFailureInt,
            MIN_UPTIME: vmToolsMonSettings.vmToolsMonitorMinUpTime,
            MAX_FAILURES: vmToolsMonSettings.vmToolsMonitorMaxFailures,
            FAILURE_WINDOW: vmToolsMonSettings.vmToolsMonitorMaxFailureWindow,
            HAS_WINDOW: vmToolsMonSettings.vmToolsMonitorHasFailureWindow
         };
      }

      /**
       * Builds a level object (`id` and `label` mapping) for a given level.
       *
       * @param level
       * @returns {{id: string, label: string}}
       */
      function buildLevel(level) {
         return {
            id: level,
            label: getLabel(level),
            description: getDescription(level)
         };
      }

      /**
       * Builds a level object (`id` and `label` mapping) for a given sensitivity level.
       *
       * @param level
       * @returns {{id: string, label: string}}
       */
      function buildSensitivityLevel(level) {
         return {
            id: level,
            label: getSensitivityLabel(level)
         };
      }

      /**
       * Retrieves the label for the given VM monitoring level.
       *
       * @param {string} vmMonitoring the level to get the label for.
       * @returns {string}
       */
      function getLabel(vmMonitoring) {
         var vmMonitoringLabel;
         switch (vmMonitoring) {
            case haConstants.vmMonitoring.DISABLED:
               vmMonitoringLabel = 'HA.vm.monitoring.disabled';
               break;
            case haConstants.vmMonitoring.VM_ONLY:
               vmMonitoringLabel = 'HA.vm.monitoring.only';
               break;
            case haConstants.vmMonitoring.VM_AND_APP:
               vmMonitoringLabel = 'HA.vm.app.monitoring';
               break;
            default:
               return '';
         }
         return i18nService.getString('ClusterUi', vmMonitoringLabel);
      }

      /**
       * Retrieves the label for the given VM monitoring sensitivity level.
       *
       * @param {string} vmMonitoringSensitivity the level to get the label for.
       * @returns {string}
       */
      function getSensitivityLabel(vmMonitoringSensitivity) {
         var vmMonitoringSensitivityLabel;
         switch (vmMonitoringSensitivity) {
            case presetLevels.LOW:
               vmMonitoringSensitivityLabel =
                     'ha.config.vmResponse.vmMonitoringSensitivity.preset.low';
               break;
            case presetLevels.MEDIUM:
               vmMonitoringSensitivityLabel =
                     'ha.config.vmResponse.vmMonitoringSensitivity.preset.medium';
               break;
            case presetLevels.HIGH:
               vmMonitoringSensitivityLabel =
                     'ha.config.vmResponse.vmMonitoringSensitivity.preset.high';
               break;
            case presetLevels.CUSTOM:
               vmMonitoringSensitivityLabel =
                     'ha.config.vmResponse.vmMonitoringSensitivity.custom';
               break;
            default:
               return '';
         }

         return i18nService.getString('ClusterUi', vmMonitoringSensitivityLabel);
      }

      /**
       * Retrieves the description of VM monitoring option.
       *
       * @param {string}
       * @returns {string}
       */
      function getDescription(vmMonitoring) {
         return vmMonitoringStateDescriptions[vmMonitoring];
      }

      /**
       * Retrieves the VM monitoring sensitivity types
       *
       * @returns {{PRESET: number, CUSTOM: number}}
       */
      function getSensitivityTypes() {
         return sensitivityTypes;
      }

      /**
       * Returns the VM monitoring sensitivity preset levels
       *
       * @returns {{LOW: number, MEDIUM: number, HIGH: number, CUSTOM: number}}
       */
      function getPresetLevels() {
         return presetLevels;
      }

      /**
       * Retrieves an array with values for failure interval, min uptime, max
       * failures and failure window for a VM monitoring sensitivity preset level
       *
       * @param {number}
       * @returns {Array}
       */
      function getSensitivityLevelValues(presetLevel) {
         return sensitivityLevelsMap[presetLevel];
      }

      /**
       * Retrieves an array with VM monitoring sensitivity levels.
       */
      function getMonitoringSensitivityLevels() {
         return _.map(_.values(presetLevels), buildSensitivityLevel);
      }

      return {
         getMonitoringLevels: getMonitoringLevels,
         getMonitoringSensitivityLevels: getMonitoringSensitivityLevels,
         getSensitivityLevelValues: getSensitivityLevelValues,
         getHaMonitoringSensitivityLevel: getHaMonitoringSensitivityLevel,
         getSensitivityTypes: getSensitivityTypes,
         getPresetLevels: getPresetLevels,
         getVmOverrideMonitoringLevels: getVmOverrideMonitoringLevels,
         getLabel: getLabel,
         DEFAULT_STATE: buildLevel(haConstants.vmMonitoring.DISABLED)
      };
   }
})();
