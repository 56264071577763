/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
(function() {
   'use strict';

   angular
         .module('com.vmware.vsphere.client.network')
         .controller('EditVnicIpv6PageController', EditVnicIpv6PageController);

   EditVnicIpv6PageController.$inject = [
         '$scope',
         'vuiConstants',
         'vnicConstants',
         'ipParserService',
         'i18nService',
         'staticIpInputTypes'];

   function EditVnicIpv6PageController(
         scope,
         vuiConstants,
         vnicConstants,
         ipParserService,
         i18nService,
         staticIpInputTypes) {

      this.defaultIpv6GatewayPropertyType = staticIpInputTypes.IPV6.DEFAULT_GATEWAY;

      this.pageModel = scope.manager.vnicData.ipv6PageModel;
      scope.defaultGateway = (this.pageModel && this.pageModel.defaultGateway)
            ? this.pageModel.defaultGateway
            : i18nService.getString('NetworkUi', 'NetworkUtil.noData');
      this.pageModel.isGatewayFeatureSupported =
            scope.manager.vnicData.ipv4PageModel
            && scope.manager.vnicData.ipv4PageModel.isGatewayFeatureSupported;
      this.listData = {};
      this.listData.defaultGateway = this.pageModel.defaultGateway;
      this.listData.hostIpv6Addresses = this.pageModel.hostIpAddresses;
      // Do not show ip addresses marked for removal in the grid
      this.listData.ipv6List = this.pageModel.staticIpv6Addresses
            ? getIpAddressesNotMarkedForRemoval(this.pageModel.staticIpv6Addresses)
            : [];

      this.onCommit = function () {
         var removed = diffIpv6Arrays(
               this.pageModel.staticIpv6Addresses, this.listData.ipv6List);
         var added = diffIpv6Arrays(
               this.listData.ipv6List, this.pageModel.staticIpv6Addresses);
         var untouched = intersectIpv6Arrays(
               this.pageModel.staticIpv6Addresses, this.listData.ipv6List);

         _.forEach(removed, function(ipAddress) {
            ipAddress.operation = vnicConstants.changeOperation.remove;
         });

         _.forEach(added, function(ipAddress) {
            ipAddress.operation = vnicConstants.changeOperation.add;
         });

         if (scope.manager.vnicData.ipv6PageModel.isGatewayFeatureSupported
               && scope.manager.vnicData.ipv6PageModel.isGatewayOverridden) {
            scope.manager.vnicData.ipv6PageModel.validateIpv6DefaultGateway();
         }

         scope.manager.vnicData.ipv6PageModel.dirty =
               !!scope.manager.vnicData.ipv6PageModel.dirty ||
               scope.nwEditVnicIpv6PageForm.$dirty ||
               added.length > 0 ||
               removed.length > 0;

         this.pageModel.staticIpv6Addresses = untouched.concat(added, removed);

         return getValidationMessages(this.listData.ipv6List);
      };

      scope.manager.vnicData.ipv6PageModel.onCommit = this.onCommit.bind(this);

      /**
       * Returns a deep copy of all IP addresses that were not marked for removal.
       * I.e. ip.operation is not set to `remove` in a previous visit of the page.
       */
      function getIpAddressesNotMarkedForRemoval(addresses) {
         return $.extend(
               true,
               [],
               _.filter(addresses, function (address) {
                  return address.operation !== vnicConstants.changeOperation.remove;
               }));
      }

      /**
       * Returns IP addresses that exist in both `a` and `b` arrays.
       */
      function intersectIpv6Arrays(a, b) {
         var result = [];
         _.forEach(a, function (current) {
            if (containsIpv6Address(b, current)) {
               result.push(current);
            }
         });

         return result;
      }

      /**
       * Returns IP addresses that exist in `b` but not in `a`.
       */
      function diffIpv6Arrays(a, b) {
         var result = [];
         _.forEach(a, function (current) {
            if (!containsIpv6Address(b, current)) {
               result.push(current);
            }
         });

         return result;
      }

      /**
       * @param ips
       *    Array to be traversed.
       * @param ip
       *    Item to be looked for.
       * @returns {boolean}
       *    True if `ip` with the same address and prefixLength exists
       *    in the `ips` array.
       */
      function containsIpv6Address(ips, ip) {
         return _.some(ips, function(current) {
            return equalIpv6Addresses(current, ip);
         });
      }

      function equalIpv6Addresses(a, b) {
         return ipParserService.equalIpv6Addresses(a.address, b.address) &&
               a.prefixLength === b.prefixLength;
      }

      /**
       * @param ipv6List
       *    current representation of the ipv6 list items
       * @returns
       *    empty array if the data is correct
       *    array of errors used in the validationBanner
       */
      function getValidationMessages(ipv6List) {
         var validationErrors = [];
         if (scope.manager.vnicData.ipv6PageModel.staticIpEnabled) {
            if (_.isEmpty(ipv6List)) {
               validationErrors.push(i18nService.getString(
                     'NetworkUi', 'EditVnicView.noIpv6ManualAddressError'));
            }

            if (scope.manager.vnicData.ipv6PageModel.isGatewayOverridden) {
               // check if the configured default gateway is empty or invalid ipv6 address
               if (!ipParserService.isIpv6AddressValid(
                           scope.manager.vnicData.ipv6PageModel.defaultGateway)) {
                  validationErrors.push(i18nService.getString(
                        'NetworkUi', 'Ipv6GatewayDialog.defaultGateway.error'));
               }
               if (scope.manager.vnicData.ipv6PageModel.dhcpEnabled
                     || scope.manager.vnicData.ipv6PageModel.autoConfigEnabled) {
                  validationErrors.push(i18nService.getString('H5NetworkUi',
                        'VnicIpv6SettingsPage.invalidIpConfiguration'));
               }
            }
         }

         return validationErrors.length ? buildValidationMessages(validationErrors) : [];
      }

      /**
       * @param messages
       *    localized strings returned from the validator
       * @returns {TResult[]}
       *    converted array of messages in the form used by validationBanner
       */
      function buildValidationMessages(messages) {
         return _.map(messages, function (message) {
            return {
               type: vuiConstants.validationBanner.type.ERROR,
               text: message
            };
         });
      }

      return this;
   }
})();