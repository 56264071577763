namespace h5_vm {

   import VirtualUSB = com.vmware.vim.binding.vim.vm.device.VirtualUSB;
   import VirtualUSB$RemoteClientBackingInfo = com.vmware.vim.binding.vim.vm.device.VirtualUSB$RemoteClientBackingInfo;
   import VirtualUSB$RemoteHostBackingInfo = com.vmware.vim.binding.vim.vm.device.VirtualUSB$RemoteHostBackingInfo;
   import VirtualUSB$USBBackingInfo = com.vmware.vim.binding.vim.vm.device.VirtualUSB$USBBackingInfo;
   import UsbInfo = com.vmware.vim.binding.vim.vm.UsbInfo;
   import VmConfigContext = com.vmware.vsphere.client.vm.config.VmConfigContext;
   import VmHardwareUtil = h5_vm.VmHardwareUtil;

   export class VirtualUSBClass {

      private ctx:VmConfigContext;
      rawDevice: VirtualUSB;
      i18n: Function;

      private clientType: string = new VirtualUSB$RemoteClientBackingInfo()._type;
      private remoteType: string = new VirtualUSB$RemoteHostBackingInfo()._type;
      private localType: string = new VirtualUSB$USBBackingInfo()._type;

      public usbInfo: UsbInfo;

      constructor(virtualUSB: VirtualUSB, info: UsbInfo, private i18nService: any) {
         this.usbInfo = info;
         if (info) {
            (info as any).taken = true;
         }
         this.rawDevice = virtualUSB;
         this.i18n = (key: string, ...rest: any[]) => i18nService.getString('VmUi', 'USBDevicePage.' + key, rest);
      }

      onInit(vmConfigContext: any) {
         this.ctx = vmConfigContext;
         this.rawDevice = (this as any).getCurrentDevice();

         if (this.usbInfo === null) {
            this.usbInfo = VmHardwareUsbDeviceUtil.getTargetUsbInfo(this.rawDevice, vmConfigContext);
         }

      }

      isNew(): boolean {
         return this.rawDevice.key < 0;
      }

      isRemovalEnabled(): boolean {

         if (this.isNew()) {
            return true;
         }

         if (this.isClientTypeUSB()) {
            return h5_vm.VmHardwareUtil.userHasAddRemoveDevicePermission(this.ctx);
         }

         return h5_vm.VmHardwareUtil.userHasHostUsbPermission(this.ctx);
      }

      public getDeviceLabel(): String {
         if (!this.rawDevice || !this.rawDevice.deviceInfo) {
            return "";
         } else {
            return this.rawDevice.deviceInfo.label;
         }
      }

      public getUsbDeviceLabel(vmConfigContext: VmConfigContext): String {
         let name = VmHardwareUsbDeviceUtil.getUsbTargetDescription(this.rawDevice, vmConfigContext);
         let typeKey = (this.rawDevice.backing._type === this.clientType) ? 'USBDeviceConfig.ClientDevice' : 'USBDeviceConfig.HostDevice';
         let retVal: string = this.i18nService.getString('VmUi', typeKey, name);
         return retVal;
      }

      get hostUsb() {
         return this.usbInfo;
      }

      set hostUsb(value: UsbInfo) {

         if (!value) {
            return;
         }

         let backing: any = this.rawDevice.backing;
         backing.deviceName = value.physicalPath;
         this.usbInfo = value;
      }

      getUSBUniqueID(): string {
         return VmHardwareUsbDeviceUtil.getUSBUniqueID(this.rawDevice);
      }

      getUSBConnectionStatusText(): string {
         if (!this.rawDevice) {
            return "";
         }
         if (this.rawDevice.connected) {
            return this.i18n("Connected");
         } else {
            return this.i18n("Disconnected");
         }
      }

      isClientTypeUSB():boolean {

         if (!this.rawDevice || !this.rawDevice.backing) {
            return false;
         }

         return this.rawDevice.backing._type === this.clientType;
      }


      getVMotionSupportText(): string {

         if (!this.rawDevice) {
            return "";
         }

         if (this.rawDevice.backing._type === this.clientType) {
            return this.i18n("VMotionCapable");
         }

         if (this.rawDevice.backing._type === this.remoteType) {
            return this.i18n("VMotionCapable");
         }

         return this.i18n("VMotionNotCapable");
      }

      isDevicePresent(): boolean {
         if (!this.usbInfo) {
            return false;
         } else {
            return true;
         }
      }

      getDeviceAvailableText(): string {
         if (!this.usbInfo) {
            return "";
         }

         let summary:com.vmware.vim.binding.vim.vm.Summary = this.usbInfo.summary;

         if (!summary) {
            return this.i18n("USBConnectionAvailable");
         }

         if (summary && summary.config) {
            let vmname = summary.config.name;
            let retVal: string = this.i18nService.getString('VmUi', 'USBDeviceConfig.Connected', vmname);
            return retVal;
         }

         return "";
      }

      isDeviceAvailable(): boolean {
         return this.usbInfo && !this.usbInfo.summary;
      }

      private _vMotion: boolean = false;

      public get vMotion(): boolean {
         return this._vMotion;
      }

      public set vMotion(value: boolean) {
         this._vMotion = value;
         if (value) {
            this.rawDevice.backing._type = this.remoteType;
            let backing: any = this.rawDevice.backing;
            backing.hostname = "";
         } else {
            this.rawDevice.backing._type = this.localType;
         }
      }
   }

   function GetVirtualUSBClass(EditableVirtualDevice: any, i18nService: any): any {

      let factoryFn: Function = function (rawDevice: any, info: UsbInfo): any {

         let instance = new VirtualUSBClass(rawDevice, info, i18nService);
         EditableVirtualDevice.call(instance, rawDevice);

         return instance;
      };

      return factoryFn;
   }

   angular.module('com.vmware.vsphere.client.vm')
         .factory('VirtualUSBClass', ['EditableVirtualDevice', 'i18nService', GetVirtualUSBClass]);
}
