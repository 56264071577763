/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
/*
 * Service to create VmfsPartitionConfigurationPage.
 * Validates the partition configuration.
 */
(function () {
   'use strict';
   angular.module('com.vmware.vsphere.client.storage')
      .service('vmfsMountOptionsPageService', vmfsMountOptionsPageService);

   vmfsMountOptionsPageService.$inject = [
         'i18nService',
         'wizardPageService',
         'vuiConstants'];

   function vmfsMountOptionsPageService(
         i18nService, wizardPageService, vuiConstants) {

      return {
         build: function (wizardScope) {
            return {
               title: i18nService.getString('StorageUi', 'addDatastoreWizard.vmfsMountOptionsPage.title'),
               description: i18nService.getString('StorageUi', 'addDatastoreWizard.vmfsMountOptionsPage.description'),
               contentUrl: 'storage-ui/resources/storage/views/wizard/createDatastore/pages/vmfs/VmfsMountOptionsPage.html',
               defaultState: vuiConstants.wizard.pageState.SKIPPED,
               onCommit: function() {
                  wizardPageService.markPageComplete(wizardScope.wizardConfig, 
                     wizardScope.commonWizardManager.getDatastoreType());
                  return true;
               }
            };
         }
      };
   }
})();
