(function() {
   'use strict';
   angular.module('com.vmware.vsphere.client.vm')
         .service('installUpgradeVmToolsService', installUpgradeVmToolsService);

   installUpgradeVmToolsService.$inject = ['dataService', 'mutationService'];

   function installUpgradeVmToolsService(dataService, mutationService) {

      var TOOLS_RUNNING_STATUS = 'toolsRunningStatus';
      var TOOLS_VERSION_STATUS = 'toolsVersionStatus';
      var POWER_STATE = 'runtime.powerState';
      var MANAGED_BY_INFO = 'managedByInfo';

      var POWERED_OFF = 'poweredOff';
      var GUEST_TOOLS_NOT_RUNNING = 'guestToolsNotRunning';
      var GUEST_TOOLS_RUNNING = 'guestToolsRunning';
      var GUEST_TOOLS_NEED_UPGRADE = 'guestToolsNeedUpgrade';
      var GUEST_TOOLS_SUPPORTED_OLD = 'guestToolsSupportedOld';
      var GUEST_TOOLS_TOO_OLD = 'guestToolsTooOld';
      var GUEST_TOOLS_BLACKLISTED = 'guestToolsBlacklisted';
      var TOOLS_AUTO_UPGRADE = 'auto';
      var TOOLS_INTERACTIVE_UPGRADE = 'interactive';
      var VM_TOOLS_INSTALL_SPEC = 'com.vmware.vsphere.client.vm.tools.VmToolsInstallerSpec';

      return {
         getGroupedVms: getGroupedVms,
         installTools: installTools,
         upgradeTools: upgradeTools
      };

      function getGroupedVms(availableVmIds) {
         var propsToRequest = [TOOLS_RUNNING_STATUS, TOOLS_VERSION_STATUS, POWER_STATE, MANAGED_BY_INFO];
         var groupedVms = dataService.getPropertiesForObjects(availableVmIds, propsToRequest)
               .then(function(result) {
                  var groupedVms = groupResults(result);
                  var propertiesAndGroupdVmsResult = {
                     groupedVms: groupedVms,
                     properties: result
                  };
                  return propertiesAndGroupdVmsResult;
               });
         return groupedVms;
      }

      function groupResults(vmsData) {
         var groupedVms = {
            poweredOff: [],
            install: [],
            upgrade: []
         };
         _.each(vmsData, function(vmData, vmRef) {

            if (vmData[POWER_STATE] === POWERED_OFF) {
               groupedVms.poweredOff.push(vmRef);
            } else {
               if (vmData[TOOLS_RUNNING_STATUS] === GUEST_TOOLS_NOT_RUNNING) {
                  groupedVms.install.push(vmRef);
               } else if (canUpgrade(vmData)) {
                  groupedVms.upgrade.push(vmRef);
               }
            }

         });

         return groupedVms;
      }

      function canUpgrade(vmData) {
         var toolsNeedUpgrade = vmData[TOOLS_VERSION_STATUS] === GUEST_TOOLS_NEED_UPGRADE
               || vmData[TOOLS_VERSION_STATUS] === GUEST_TOOLS_SUPPORTED_OLD
               || vmData[TOOLS_VERSION_STATUS] === GUEST_TOOLS_TOO_OLD
               || vmData[TOOLS_VERSION_STATUS] === GUEST_TOOLS_BLACKLISTED;

         return vmData[TOOLS_RUNNING_STATUS] === GUEST_TOOLS_RUNNING
               && toolsNeedUpgrade;
      }

      function installTools(vmIds) {
         if (vmIds.length === 0) {
            return;
         }

         var vmToolsInstallerSpec = {
            operation: 'mountInstaller'
         };
         mutationService.applyOnMultiEntity(
               vmIds,
               VM_TOOLS_INSTALL_SPEC,
               vmToolsInstallerSpec
         );
      }

      function upgradeTools(vmIds, upgradeMethod, advancedOptions) {
         if (vmIds.length === 0) {
            return;
         }

         var vmToolsInstallerSpec;

         if (upgradeMethod === TOOLS_AUTO_UPGRADE) {
            vmToolsInstallerSpec = {
               operation: 'upgradeTools',
               upgradeOptions: advancedOptions
            };
         } else if (upgradeMethod === TOOLS_INTERACTIVE_UPGRADE) {
            vmToolsInstallerSpec = {
               operation: 'mountInstaller'
            };
         }
         mutationService.applyOnMultiEntity(
               vmIds,
               VM_TOOLS_INSTALL_SPEC,
               vmToolsInstallerSpec
         );
      }
   }
}());
