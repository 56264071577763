
angular.module('com.vmware.vsphere.client.vm').factory('EditableVirtualDevice', [
   'deviceDiffService',
   'vmDeviceInfoService',
   'vmHardwareUtil',
   function(
      deviceDiffService,
      vmDeviceInfoService,
      vmHardwareUtil
   ) {
      var ADD_OPERATION = 'add';
      var REMOVE_OPERATION = 'remove';
      var EDIT_OPERATION = 'edit';

      var EditableVirtualDevice = function(rawDevice) {
         var originalDevice = rawDevice;

         /**
          * Returns information if the device is being newly added to the VM or if it has been already added.
          */
         this.isNew = function() {
            return originalDevice.key < 0;
         };

         var currentDeviceSpec = {
            _type: 'com.vmware.vim.binding.vim.vm.device.VirtualDeviceSpec',
            operation: this.isNew() ? ADD_OPERATION : EDIT_OPERATION,
            device: angular.copy(rawDevice),
            fileOperation: undefined
         };

         this.getFinalDeviceSpec = function() {
            var spec = angular.copy(currentDeviceSpec);
            vmHardwareUtil.clearUnchangedConnectInfo(spec, originalDevice);
            vmHardwareUtil.fixNewDiskBackingDatastore(spec);
            return spec;
         };

         this.getCurrentDeviceSpec = function() {
            return currentDeviceSpec;
         };

         this.setCurrentDeviceSpec = function(deviceSpec) {
            currentDeviceSpec = deviceSpec;
         };

         this.getCurrentDevice = function() {
            return currentDeviceSpec.device;
         };

         this.markForRemoval = function() {
            currentDeviceSpec.operation = REMOVE_OPERATION;
         };

         this.revertRemoval = function() {
            currentDeviceSpec.operation = EDIT_OPERATION;
         };

         this.getKey = function() {
            return currentDeviceSpec.device.key;
         };

         this.hasChanged = function() {
            if(currentDeviceSpec.operation === ADD_OPERATION || this.isMarkedForRemoval()) {
               return true;
            }

            return !deviceDiffService.isUnchanged(originalDevice, currentDeviceSpec.device);
         };

         this.isMarkedForRemoval = function(){
            return currentDeviceSpec.operation === REMOVE_OPERATION;
         };

         this.isOfType = function(deviceType) {
            return vmDeviceInfoService.isDeviceSubclassOf(currentDeviceSpec.device, deviceType);
         };
      };

      return EditableVirtualDevice;
   }
]);
