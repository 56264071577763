(function() {
   'use strict';
   angular.module('com.vmware.vsphere.client.host').service('standbyModeConfirmationService',
         StandbyModeConfirmationService);

   StandbyModeConfirmationService.$inject = ['i18nService'];

   function StandbyModeConfirmationService(i18nService) {
      function processStandbyValidationResult(data) {
         var warningMessages = [];
         var label, description;
         var shouldEvacuateVms = false;
         var lastStandbyExitUncertainHosts = [];
         var highAvailabilityCompromiseHosts = [];
         var highAvailabilityCompromiseClusters= [];

         var vmsPoweredOnHosts = [];
         _.each(data, function(validationResult) {
            if (validationResult.result.lastStandbyExitUncertain) {
               lastStandbyExitUncertainHosts.push(validationResult.result.hostName);
            }
            if (validationResult.result.highAvailabilityCompromise) {
               if(!highAvailabilityCompromiseHosts[validationResult.result.parentClusterName]) {
                  highAvailabilityCompromiseHosts[validationResult.result.parentClusterName] = [];
                  highAvailabilityCompromiseClusters.push(validationResult.result.parentClusterName);
               }
               highAvailabilityCompromiseHosts[validationResult.result.parentClusterName].push(validationResult.result.hostName);

            }
            if (validationResult.result.vmsPoweredOn) {
               vmsPoweredOnHosts.push(validationResult.result.hostName);
            }
            if(validationResult.result.drsEnabled && validationResult.result.vmsCount > 0) {
               shouldEvacuateVms = true;
            }
         });

         if (lastStandbyExitUncertainHosts.length > 0) {
            label = i18nService.getString(
                        'HostUi','ConfirmStandbyForm.lastStandbyUncertainWarningMessage.label');
            description =  i18nService.getString(
                     'HostUi','ConfirmStandbyForm.lastStandbyUncertainWarningMessage.description',
                lastStandbyExitUncertainHosts.join(', ') );
            messagePush(label, description);
         }

         _.each(highAvailabilityCompromiseClusters, function(cluster) {
            if (highAvailabilityCompromiseHosts[cluster].length > 0) {
               label = i18nService.getString(
                   'HostUi','ConfirmStandbyForm.highAvailabilityWarning.label');
               description =  i18nService.getString(
                   'HostUi','ConfirmStandbyForm.highAvailabilityWarning.description',
                   cluster,
                   highAvailabilityCompromiseHosts[cluster].join(', '));
               messagePush(label, description);
            }
         });

         if(vmsPoweredOnHosts.length > 0){
            label = i18nService.getString(
                  'HostUi','actionEnterStandbyVMsPoweredOnTitle');
            description =  i18nService.getString(
                  'HostUi','actionEnterStandbyVMsPoweredOn',
                vmsPoweredOnHosts.join(', '));
            messagePush(label, description);
         }

         return {
            hostname: data[0].result.hostName,
            warningMessages: warningMessages,
            shouldEvacuateVms: shouldEvacuateVms
         };
         function messagePush(label, description){
            var isExpanded = warningMessages.length === 0;
            warningMessages.push({
               shortDescription: label,
               message: description,
               expanded: isExpanded
            });
         }
      }

      return {
         processStandbyValidationResult: processStandbyValidationResult
      };
   }
})();
