(function() {
   'use strict';
   angular.module('com.vmware.vsphere.client.host').controller('EditDefaultVmCompatiblityController', EditDefaultVmCompatiblityController);

   EditDefaultVmCompatiblityController.$inject = ['$scope',
      'i18nService', 'dataService', 'mutationService', 'helpService', 'helpConstants',
      'defaultUriSchemeUtil'];

   function EditDefaultVmCompatiblityController($scope,
         i18nService, dataService, mutationService, helpService, helpConstants,
         defaultUriSchemeUtil) {
      var self = this;
      var undefinedHwVersionKey = 'unspecified';

      self.popupParams = {
         title: i18nService.getString('Common', 'help'),
         message: i18nService.getString('Common', 'signpost.hardwareVersionSelector'),
         linkLabel : i18nService.getString('HostUi', 'edit.defaultvm.helperlink.label'),
         linkFunction : function(){
            helpService.showHelpPage(helpConstants.EDIT_DEFAULT_VM_COMPATIBILITY_ID);
         }
      };

      self.loading = true;
      $scope.modalOptions.submitDisabled = true;

      $scope.i18nService = i18nService;

      var objectId = $scope._route.objectId;

      $scope.modalOptions.onSubmit = function() {
         if (self.loading) {
            return false;
         }

         var selectedKey = '';
         if (self.selectedOption.key !== undefinedHwVersionKey) {
            selectedKey = self.selectedOption.key;
         }
         mutationService.apply(objectId,
            'com.vmware.vsphere.client.mixed.DefaultHwVersionSpec',
            {hwVersionKey: selectedKey},
            'EditDefaultVmCompatibility');

         return true;
      };

      self.compatibilityOptions = [];

      var entityType = defaultUriSchemeUtil.getEntityType(objectId);

      dataService.getProperties(objectId,
            ['optionDescriptorsWithCreateSupported', 'defaultHardwareVersionKey'])
      .then(function(data) {
         var options = data.optionDescriptorsWithCreateSupported;
         var defaultConfigOption = 0;
         self.compatibilityOptions.push({
            key: undefinedHwVersionKey,
            localizedShortString: i18nService.getString('HostUi',
                  entityType === 'Datacenter' ?
                        'defaultVmCompatibilityDialog.short.unspecifiedForDc' :
                        'defaultVmCompatibilityDialog.short.unspecifiedForCr'),
            localizedDescriptionString: i18nService.getString('HostUi',
                  entityType === 'Datacenter' ?
                        'defaultVmCompatibilityDialog.unspecifiedForDc' :
                        'defaultVmCompatibilityDialog.unspecifiedForCr')
         });

         var moreOptions = _.map(options, function(option, index) {
            if (option.defaultConfigOption) {
               defaultConfigOption = index + 1;
            }

            return {
               key: option.key,
               localizedShortString: i18nService.getString('HostUi',
                     'defaultVmCompatibilityDialog.short.' + option.key),
               localizedDescriptionString: i18nService.getString('HostUi',
                     'defaultVmCompatibilityDialog.' + option.key)
            };

         });

         self.compatibilityOptions = self.compatibilityOptions.concat(moreOptions);

         if(!data.defaultHardwareVersionKey) {
            self.selectedOption = self.compatibilityOptions[0];
         } else {
            self.selectedOption = self.compatibilityOptions[defaultConfigOption];
         }
         self.loading = false;
         $scope.modalOptions.submitDisabled = false;
      });
   }
})();
