/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
(function () {
   'use strict';

   // TODO: track improvements here: https://www.pivotaltracker.com/story/show/131618679

   /**
    * Creates data grid action definitions.
    */
   angular
         .module('com.vmware.vsphere.client.networkLibUi')
         .factory('actionToolbarFactory', actionToolbarFactory);

   actionToolbarFactory.$inject = ['$q', 'vuiConstants', 'actionsService'];

   function actionToolbarFactory($q, vuiConstants, actionsService) {

      return {
         createActionbarOptions: createActionbarOptions
      };

      /**
       * @param targetIds
       *       Object ids the actions will be requested for.
       *
       * @param actionIds
       *       The ids of the actions to be requested.
       *
       * @param evaluationContext
       *       Additional context that is passed to actions' invokers.
       *
       * @return Data-grid ActionBarOptions.
       */
      function createActionbarOptions(targetIds, actionIds, evaluationContext) {
         var actionIdsWithoutSeparators = _.filter(actionIds, function(id) {
            return id !== vuiConstants.actions.SEPARATOR;
         });

         if (actionIdsWithoutSeparators.length === 0) {
            // Only separators or nothing was requested
            var result = actionIds && actionIds.length > 0 ? actionIds: null;
            return $q.resolve(result);
         }

         return actionsService.getActions(targetIds, actionIdsWithoutSeparators).then(

               function (actionsEvaluations) {
                  var actionBarOptions = { actions: [] };

                  // Manually order action definitions as per requested action ids
                  // TODO: remove it once actionService is able to return actions ordered
                  _.forEach(actionIds, function(actionId) {
                     var action;

                     if (actionId === vuiConstants.actions.SEPARATOR) {
                        actionBarOptions.actions.push(vuiConstants.actions.SEPARATOR);
                     } else {
                        var actionEvaluation = _.find(actionsEvaluations,
                              function(value) {
                                 return value.action.uid === actionId;
                              });
                        //if action
                        if(actionEvaluation) {
                           action = createAction(actionEvaluation, evaluationContext);
                           actionBarOptions.actions.push(action);
                        }
                     }
                  });

                  return actionBarOptions;
               });
      }

      function createAction(actionEvaluation, evaluationContext) {
         return {
            actionId: actionEvaluation.action.uid,
            tooltipText: actionEvaluation.action.description,
            label: actionEvaluation.action.label,
            enabled: actionEvaluation.available,
            iconClass: actionEvaluation.action.icon,
            onClick: function () {
               actionsService.invokeAction(actionEvaluation, evaluationContext);
            }
         };
      }
   }
})();
