namespace h5_client {
    export module admin_ui {

        class RoleNamePageService {
            static $inject = ['i18nService', 'wizardPageService'];

            constructor(private i18nService: any, private wizardPageService: any) {

            }

            public build() {
                return {
                    contentUrl: 'admin-ui/resources/admin/views/wizard/pages/createEditRoleName.html'
                };
            }
        }

        angular.module("com.vmware.vsphere.client.admin")
            .service("roleNamePageService", RoleNamePageService);
    }
}