/* Copyright 2018 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace network_ui {

   export class VirtualSwitchesViewComponent {

      public bindings: any;
      public controller: any;
      public templateUrl: string;

      constructor() {
         this.bindings = {};
         this.controller = VirtualSwitchesController;
         this.templateUrl = "network-ui/resources/network/views/host/virtualswitches/" +
               "VirtualSwitchesViewTemplate.html";
      }

   }

   class VirtualSwitchesController {

      public static $inject = ["navigation", "i18nService"];

      public headerOptions: any;

      constructor(private navigation: any, private i18nService: any) {

         this.headerOptions = {
            objectId: this.navigation.getRoute().objectId,
            actions: [{
               actionUid: "vsphere.core.network.actions.addNetworking"
            }, {
               actionUid: "vsphere.core.host.network.refreshNetworkAction"
            }],
            title: this.i18nService.getString('NetworkUi', 'host.manage.vswitchHeader')
         };
      }
   }

   angular.module("com.vmware.vsphere.client.network")
         .component("virtualSwitchesView",
               new VirtualSwitchesViewComponent());
}
