/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace dvs_ui {

   import IRootScopeService = angular.IRootScopeService;
   import DvsImportActionSpec = com.vmware.vsphere.client.dvs.api.spec.DvsImportActionSpec;
   import DvsRestoreActionSpec = com.vmware.vsphere.client.dvs.api.spec.DvsRestoreActionSpec;
   import DvsImportPortgroupSpec = com.vmware.vsphere.client.dvs.api.spec.DvsImportPortgroupSpec;
   import DvPortgroupApplyRestoreSpec = com.vmware.vsphere.client.dvs.api.spec.DvPortgroupApplyRestoreSpec;

   export class DvsImportWizardService {

      public static $inject = [
         "$rootScope",
         "i18nService",
         "vuiWizardService",
         "mutationService",
         "dataService",
         "wizardValidationService",
         "vimEntityEscapingService",
         "dvsImportWizardPages",
         "dvsImportWizardFlowManager",
         "exportDvsConstants"];

      constructor(private $rootScope: IRootScopeService,
                  private i18nService: any,
                  private vuiWizardService: any,
                  private mutationService: any,
                  private dataService: any,
                  private wizardValidationService: any,
                  private vimEntityEscapingService: any,
                  private dvsImportWizardPages: DvsImportWizardPages,
                  private dvsImportWizardFlowManager: DvsImportWizardFlowManager,
                  private exportDvsConstants: any) {
      }

      /**
       * Shows the Import/Restore DVS/DVPortgroup wizard.
       */
      public show(objectId: string, isDvpgConfig: boolean,
                  isRestoreAction: boolean): void {

         let wizardScope: DvsImportWizardScope =
               this.$rootScope.$new() as DvsImportWizardScope;

         wizardScope.model = new DvsImportWizardModel();
         wizardScope.model.objectId = objectId;
         wizardScope.model.isDvpgConfig = isDvpgConfig;
         wizardScope.model.isRestoreAction = isRestoreAction;

         let wizardConfig: WizardConfig = new WizardConfig();
         if (isRestoreAction) {
            wizardConfig.title = isDvpgConfig ? this.i18nService.getString(
                  "DvsUi", "dvs.restore.wizard.titleInitial") :
                  this.i18nService.getString(
                        "DvsUi", "dvs.restore.wizard.titleInitial");
         } else {
            wizardConfig.title = isDvpgConfig ?
                  this.i18nService.getString(
                        "DvsUi", "dvpg.import.wizard.titleInitial") :
                  this.i18nService.getString(
                        "DvsUi", "dvs.import.wizard.titleInitial");
         }

         wizardConfig.loadingMessage = this.i18nService.getString(
               "CommonUi", "wizard.loading");
         wizardConfig.cssClass = "vui-wizard.dvs-import-wizard";
         wizardConfig.onFinish = () => {

            let spec: any;
            let specType: string;
            if (isRestoreAction) {
               if (isDvpgConfig) {
                  spec = {
                     configSpec: wizardScope.model.configurationPageModel.dvPortgroupRestoreInfoWrapperSpec.dvPortgroupRestoreInfoSpec.configSpecDiff
                  };
                  specType = new DvPortgroupApplyRestoreSpec()._type;
               } else {
                  spec = {
                     configData: wizardScope.model.configurationPageModel.configurationData,
                     restorePortgroups: wizardScope.model.configurationPageModel.restorePortgroups ===
                     this.exportDvsConstants.configurationsToExport.DVS_AND_ALL_PORTGROUPS,
                     infoSpec: wizardScope.model.configurationPageModel.dvsImportInfoSpec
                  };
                  specType = new DvsRestoreActionSpec()._type;
               }
            } else {
               if (isDvpgConfig) {
                  spec = wizardScope.model.configurationPageModel.dvsImportPortgroupWrapperSpec.dvsImportPortgroupSpec;
                  specType = new DvsImportPortgroupSpec()._type;
               } else {
                  spec = {
                     configData: wizardScope.model.configurationPageModel.configurationData,
                     createNewObjectKeys: !wizardScope.model.configurationPageModel.preserveOriginalIdentifiers,
                     infoSpec: wizardScope.model.configurationPageModel.dvsImportInfoSpec
                  };
                  specType = new DvsImportActionSpec()._type;
               }

            }

            this.mutationService.apply(
                  wizardScope.model.objectId,
                  specType,
                  spec);

            return true;
         };

         wizardConfig.pages =
               this.dvsImportWizardPages.createPages(wizardScope,
                     isDvpgConfig, isRestoreAction);

         wizardScope.wizardConfig = wizardConfig;

         this.wizardValidationService.initWizardValidation(wizardScope);

         this.dvsImportWizardFlowManager.init(wizardScope);

         this.vuiWizardService({
            scope: wizardScope,
            configObjectName: "wizardConfig"
         }).show();

         let entityNameProperty: string = "name";

         this.dataService.getProperties(
               wizardScope.model.objectId, [entityNameProperty])
               .then((response: any) => {
                        if (response && response[entityNameProperty]) {
                           let objectName: string = this.vimEntityEscapingService
                                 .unescapeSpecialCharacters(
                                       response[entityNameProperty]);
                           if (objectName) {
                              if (isRestoreAction) {
                                 wizardConfig.title = isDvpgConfig ?
                                       this.i18nService.getString(
                                             "DvsUi", "dvpg.restore.wizard.title",
                                             objectName) :
                                       this.i18nService.getString(
                                             "DvsUi", "dvs.restore.wizard.title",
                                             objectName);
                              } else {
                                 wizardConfig.title = isDvpgConfig ?
                                       this.i18nService.getString(
                                             "DvsUi", "dvpg.import.wizard.title",
                                             objectName) :
                                       this.i18nService.getString(
                                             "DvsUi", "dvs.import.wizard.title",
                                             objectName);
                              }
                           }
                        }
                     }
               );
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .service("dvsImportWizardService", DvsImportWizardService);
}
