/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
/*
 * Controller for Content Library Publication Portlet.
 *
 */
namespace h5_client {
   export class ContentLibraryPublicationPortletController {
      public static $inject: string[] = ["$scope", "i18nService", "actionsService"];

      libraryId: string;

      constructor(
            private $scope: any,
            private i18nService: any,
            private actionsService: any) {

         this.libraryId = this.$scope._route.objectId;
         this.actionsService.getAction("vsphere.core.library.local.editSettings", [ this.libraryId ])
               .then((action: any) => {
                  this.$scope.portlet.footerLinks = [{
                        label: this.i18nService.getString("LibraryUi", "library.editAction"),
                        onClick: ($event: KeyboardEvent) => this.actionsService.invokeAction(action, {focusTarget: $event.currentTarget}),
                        ariaLabel: this.i18nService.getString("LibraryUi", "library.editPublicationSettings"),
                        enabled: action && action.available
                  }];
               });
      }
   }
   angular.module("com.vmware.vsphere.client.commonModule")
         .controller("contentLibraryPublicationPortletController", ContentLibraryPublicationPortletController);
}
