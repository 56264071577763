// todo: Combine with storage-ui's fileReaderService and move file to common module
namespace h5_vm {

   import IPromise = angular.IPromise;

   export class VmFileReaderService {

      public static $inject = ["$q"];

      constructor(private $q: any) {
      }

      public readFileContentAsBinaryString(file: any): IPromise<string> {
         if (!file) {
            return this.$q.when(null);
         }

         let deferredResult = this.$q.defer();

         let fileReader = new FileReader();
         fileReader.onload = function () {
            let dataUrl = fileReader.result;
            deferredResult.resolve(dataUrl);
         };

         fileReader.addEventListener("load", function () {
            let dataUrl = fileReader.result;
            deferredResult.resolve(dataUrl);
         });

         fileReader.addEventListener("error", function () {
            deferredResult.resolve(null);
         });

         fileReader.readAsBinaryString(file);

         return deferredResult.promise;
      }
   }

   angular.module("com.vmware.vsphere.client.vm")
         .service("vmFileReaderService", VmFileReaderService);
}
