/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
/*
 * Service to validate the host connection spec and ask the user to
 * confirm the acceptance of the host certificate
 */
(function () {
    'use strict';
    angular.module('com.vmware.vsphere.client.host')
        .service('addHostConnectionSettingsPageService', addHostConnectionSettingsPageService);

    addHostConnectionSettingsPageService.$inject = ['i18nService',
        'actionConfirmationService',
        '$q'];

    function addHostConnectionSettingsPageService(i18nService, actionConfirmationService,
          $q) {
        return {
            build: function (hostAdder, vuiWizardHelper) {
                return {
                    title: i18nService.getString('HostUi', 'addHostWizard.hostConnectionPage.title'),
                    description: i18nService.getString('HostUi', 'addHostWizard.hostConnectionPage.description'),
                    contentUrl: 'host-ui/resources/host/views/addHostWizard/pages/addHostConnectionSettingsPage.html',
                    onCommit: function () {
                        var certificateConfirmationDeferred = $q.defer();
                        if (hostAdder.isUsernameValid()) {
                            vuiWizardHelper.showLoadingIndicator();
                            hostAdder.validateConnection().then(function(responseData){
                                vuiWizardHelper.hideLoadingIndicator();
                                return responseData;
                            }).then(function (responseData) {
                                if (responseData.error && responseData.error.thumbprint) {
                                    showCertificateConfirmation(certificateConfirmationDeferred, responseData);
                                } else {
                                    updatePage(responseData, certificateConfirmationDeferred);
                                }
                            });
                        } else {
                            vuiWizardHelper.markPageIncompleteWithError(
                                i18nService.getString('HostUi', 'addHostWizard.hostConnectionPage.missingUsername')
                            );
                            certificateConfirmationDeferred.resolve(false);
                        }

                        return certificateConfirmationDeferred.promise;
                    }
                };


                /**
                 * Shows the alert dialog containing the thumbprint of the host and based on the
                 * user action (accepting the certificate) allows or denies going to the next page
                 *
                 * @param deferred to allow or deny the going to the next page
                 * @param responseData response itself
                 */
                function showCertificateConfirmation(deferred, responseData) {
                    // Build different message based on the host version
                    var thumbprint = responseData.error.thumbprint;
                    var text = i18nService.getString('HostUi',
                       responseData.result.isEsx60OrHigher ?
                          'addHostWizard.hostConnectionPage.sslVerifyWarningMsgFormat60' :
                          'addHostWizard.hostConnectionPage.sslVerifyWarningMsgFormat',
                       thumbprint);

                    var alertProperties = {
                        title: i18nService.getString('HostUi', 'addHostWizard.hostConnectionPage.sslVerifyWarningTitle'),
                        message: text,
                        yesHandler: function () {
                            // In case of clicking Yes, we should verify again the connection spec by
                            // using the sslThumbprint provided in the response
                            vuiWizardHelper.showLoadingIndicator();
                            hostAdder.setSslThumbprint(thumbprint);
                            hostAdder.validateConnection().then(function (responseData) {
                                vuiWizardHelper.hideLoadingIndicator();
                                updatePage(responseData, deferred);
                            });
                            return true;
                        },
                        noHandler: function () {
                            // In case of clicking No, we should just display the error from the response
                            vuiWizardHelper.markPageIncompleteWithError(
                               responseData.error.localizedMessage
                            );
                            deferred.resolve(false);
                        },
                        yesIsDefaultButton: false,
                        useClarity: true,
                        parent: '.vui-wizard'
                    };
                    actionConfirmationService.showConfirmationDialog(alertProperties);
                }

                /**
                 * Based on the response, the page will be update with the response error or
                 * will allow going to the next page
                 *
                 * @param responseData response itself
                 * @param wizardConfig needed to update the page state
                 * @param deferred to allow or deny the going to the next page
                 */
                function updatePage(responseData, deferred) {
                    if (responseData.error) {
                        vuiWizardHelper.markPageIncompleteWithError(
                           _.escape(responseData.error.localizedMessage)
                        );
                        deferred.resolve(false);
                    } else {
                        hostAdder.setConnectInfo(responseData.result.connectInfo);
                        vuiWizardHelper.markPageComplete();
                        deferred.resolve(true);
                    }
                }
            }
        };


    }
})();
