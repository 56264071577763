angular.module('com.vmware.vsphere.client.host').service('addHostReadyToCompletePageService', [
   'i18nService',
   function (i18nService) {
      return {
         build: function () {
            return {
               title: i18nService.getString('HostUi', 'addHostWizard.confirmationPage.title'),
               description: i18nService.getString('HostUi', 'addHostWizard.confirmationPage.description'),
               contentUrl: 'host-ui/resources/host/views/addHostWizard/pages/addHostReadyToCompletePage.html'
            };
         }
      };
   }]
);
