/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace dvs_ui {

   import VmwareDistributedVirtualSwitch$LacpGroupConfig = com.vmware.vim.binding.vim.dvs.VmwareDistributedVirtualSwitch$LacpGroupConfig;
   export class LagPropertiesService {

      public static $inject = [
         "lagPropertiesFormattingService",
         "vxPropertyViewService",
         "i18nService"];

      constructor(private formattingService: LagPropertiesFormattingService,
                  private vxPropertyViewService: any,
                  private i18n: any) {
      }

      public getPropertyViewData(lag: VmwareDistributedVirtualSwitch$LacpGroupConfig): any {
         let builder: any = this.vxPropertyViewService.createPropertyViewBuilder();

         let generalCategory: any = builder.category("general");
         let generalSection: any = generalCategory.section("generalSection");
         let policySection: any = generalCategory.section("policies").title(
               this.i18n.getString("DvsUi", "lag.properties.policies"));

         generalSection.property(this.i18n.getString("DvsUi", "lag.properties.name"), lag.name);

         generalSection.property(
               this.i18n.getString("DvsUi", "lag.properties.numPorts"),
               lag.uplinkNum.toString());

         generalSection.property(
               this.i18n.getString("DvsUi", "lag.properties.mode"),
               this.formattingService.getLacpModeText(lag.mode));

         generalSection.property(
               this.i18n.getString("DvsUi", "lag.properties.loadBalancing"),
               this.formattingService.getLoadBalancingModeText(
                     lag.loadbalanceAlgorithm));

         policySection.property(
               this.i18n.getString("DvsUi", "lag.properties.vlanLabel"),
               this.formattingService.getVlanText(lag));

         policySection.property(
               this.i18n.getString("DvsUi", "lag.properties.ipfixLabel"),
               this.formattingService.getIpfixText(lag));

         return builder.build();
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .service("lagPropertiesService", LagPropertiesService);
}
