/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
(function() {
   'use strict';
   angular.module('com.vmware.vsphere.client.storage').controller(
      'StorageAdaptersDevicesDetailsViewController', StorageAdaptersDevicesDetailsViewController);

   StorageAdaptersDevicesDetailsViewController.$inject = [
      '$scope',
      'hostStorageService',
      'navigation'
   ];

   function StorageAdaptersDevicesDetailsViewController($scope, hostStorageService, navigationService) {
      var self = this;
      self.storageDevicesLoading = false;
      self.hostId = navigationService.getRoute().objectId;
      // View id for persisting column definitions state.
      self.viewId = navigationService.getRoute().extensionId + '_devices';

      self.cache = {};

      $scope.$watch(function() { return $scope.masterDetailsViewContext.selectedItem; },
         function(newValue, oldValue) {
            if (newValue !== oldValue && newValue) {
               self.refresh();
            }
         });

      function getSelectedAdapterKey() {
         if (!$scope.masterDetailsViewContext.selectedItem) {
            return null;
         }

         return $scope.masterDetailsViewContext.selectedItem.key;
      }

      self.refresh = function() {
         var adapterKey = getSelectedAdapterKey();
         if (!adapterKey) {
            return;
         }

         var hostId = self.hostId;
         var cacheId = hostId + '-' + adapterKey;

         if (self.cache.hasOwnProperty(cacheId)) {
            self.devices = self.cache[cacheId];
         } else {
            self.storageDevicesLoading = true;
         }

         hostStorageService.retrieveStorageDevicesData(hostId, adapterKey).then(
            function(devices) {
               self.cache[cacheId] = devices;
               if (hostId !== self.hostId || adapterKey !== getSelectedAdapterKey()) {
                  return;
               }
               self.devices = devices;
            })
            .finally(function () {
               if (hostId !== self.hostId || adapterKey !== getSelectedAdapterKey()) {
                  return;
               }
               self.storageDevicesLoading = false;
            });
      };

      // Request storage devices after the grid is initialized.
      self.refresh();
   }
})();
