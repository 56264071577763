angular.module('com.vmware.vsphere.client.vm').service('datastoreFaultsDialogService', [
   'clarityModalService', 'i18nService', '$rootScope',
   function(clarityModalService, i18nService, $rootScope) {
      return {
         openDialog: openDialog
      };

      function openDialog(scope, faults) {
         var modalOptions = {
            contentTemplate: 'vm-ui/resources/vm/views/createVmWizard/' +
               'datastoreRecommendations/vmProvisioningDatastoreFaults.html',
            title: i18nService.getString('VmUi', 'dsRecommendation.FaultsTitle'),
            size: 'lg',
            hideSubmitButton: true,
            closeButtonTitle: i18nService.getString('Common', 'saveButtonLabel'),
            defaultButton: 'close',
            dialogData: {
               faults: faults
            }
         };

         clarityModalService.openOkCancelModal(modalOptions);
      }
   }
]);