'use strict';
angular
   .module('com.vmware.vsphere.client.vm')
   .constant('vmFtConstants', {
         ftState: {
            DISABLED: 'disabled',
            ENABLED: 'enabled',
            NEED_SECONDARY: 'needSecondary',
            STARTING: 'starting',
            RUNNING: 'running',
            PROTECTED: 'protected',
            ENABLED_POWERED_ON: 'enabledPoweredOn',
            ENABLED_NOT_POWERED_ON: 'enabledNotPoweredOn',
            NOT_RUNNING: 'notRunning',
            NOT_PROTECTED: 'notProtected',
            NOT_CONFIGURED: 'notConfigured'
         },
         fileTypes: {
               VIRTUAL_DISK: "itemTypeDisk",
               CONFIGURATION: "itemTypeConfiguration",
               TIE_BREAKER: "itemTypeFtMetainfFile"
         },
         storageSelectionModes: {
            BASIC_MODE: "basicMode",
            ADVANCED_MODE: "advancedMode"
         }

   });
