namespace storage_ui {
   angular.module("com.vmware.vsphere.client.storage").constant("storageProviderConstants", {

      vasaProviderStatus: {
         offline: "offline",
         online: "online",
         syncError: "syncError",
         connected: "connected",
         disconnected: "disconnected"
      },

      certificateStatus: {
         expired: "expired",
         expirySoftLimitReached: "expirySoftLimitReached",
         expiryHardLimitReached: "expiryHardLimitReached",
         valid: "valid"
      },

      vasaProviderInfoType: {
         PERSISTENCE: "PERSISTENCE"
      },

      vasaProviderInfoCategory: {
         internal: "internal"
      },

      actions: {
         ADD: "vsphere.core.storage.storageProviders.addNew",
         RESYNC_INTERNAL_PROVIDER: "vsphere.core.storage.storageProviders.resyncInternalVasaProvider",
         REMOVE: "vsphere.core.storage.storageProviders.remove",
         SYNC: "vsphere.core.storage.storageProviders.sync",
         REFRESH_CERTIFICATE: "vsphere.core.storage.storageProviders.refreshCertificate"
      }
   });
}

