(function() {
   'use strict';

   /**
    * Opens Edit Security Profile dialog as a multi page dialog.
    */
   angular.module('com.vmware.vsphere.client.host')
         .service('securityProfileDialogService', securityProfileDialogService);

   securityProfileDialogService.$inject = ['dataService', '$rootScope', 'i18nService',
      'vxMultiPageDialogService', 'SecurityProfileDialogManager'];

   function securityProfileDialogService(dataService, $rootScope, i18nService,
         vxMultiPageDialogService, SecurityProfileDialogManager) {

      return {
         show: show
      };

      function show(hostId, actionEval) {
         var securityData = {};
         var dialogScope = $rootScope.$new();
         dialogScope.dialogOptions = {};

         var dialogManager = new SecurityProfileDialogManager(securityData, dialogScope.dialogOptions);
         dialogScope.manager = dialogManager;

         _.extend(dialogScope.dialogOptions, {
            iconClass: actionEval.action.icon,
            actionDetails: {
               actionEval: actionEval,
               availableTargets: [hostId]
            },
            show: true,
            loading: true, // this prevents the dialog from being initialized
                           // until ha data is loaded
            loadingMessage: i18nService.getString('CommonUi', 'wizard.loading'),
            confirmOptions: {
               onClick: dialogManager.submit
            },
            resizable: true,
            draggable: true,
            maximizable: true,
            pages: dialogManager.getPageDefinitions(),
            title: i18nService.getString('HostUi', 'securityProfile.lockdownMode', '')
         });

         var options = {
            scope: dialogScope,
            configObjectName: 'dialogOptions'
         };

         vxMultiPageDialogService(options);

         dataService.getProperties(hostId, ['lockdownMode', 'exceptionUsers', 'isHostAccessManagerAvailable', 'name']).then(function(data) {
            _.extend(securityData, data);
            dialogScope.dialogOptions.pages = dialogManager.getPageDefinitions();
            dialogScope.dialogOptions.title = i18nService.getString('HostUi', 'securityProfile.lockdownMode', securityData.name);
            dialogScope.dialogOptions.loading = false;
         });
      }
   }
})();
