namespace dvs_ui {

   import DvsHealthCheckDetailsItemData =
         com.vmware.vsphere.client.h5.network.dvs.healthcheck.model.DvsHealthCheckDetailsItemData;

   export class DvsHealthCheckMonitorIpHashViewComponent {

      public bindings: any;
      public templateUrl: string;
      public controller: any;

      constructor () {
         this.bindings = {
            healthCheckIpHashData: "<"
         };
         this.templateUrl =
               "dvs-ui/resources/dvs/healthcheck/monitor/components/dvsHealthCheckMonitorIpHashViewTemplate.html";
         this.controller = DvsHealthCheckMonitorIpHashViewController;
      }
   }

   class DvsHealthCheckMonitorIpHashViewController {

      static $inject = [
         "i18nService",
         "vxPropertyViewService"];

      public healthCheckIpHashData: any;

      public healthCheckIpHashPropertyViewData: any;

      constructor(private i18nService: any,
                  private propertyViewService: any) {
      }

      private $onChanges() {
         this.buildPropertyView();
      }

      private buildPropertyView() {

         let builder = this.propertyViewService.createPropertyViewBuilder();

         let propertiesCategory: any = builder.category("properties");
         let generalSection: any = propertiesCategory.section("general");


         let teamingStatus: string;
         let teamingStatusIcon: string;

         if (this.healthCheckIpHashData) {
            if (this.healthCheckIpHashData.teamingStatus &&
                  this.healthCheckIpHashData.teamingStatusIcon) {
               teamingStatus = this.healthCheckIpHashData.teamingStatus;
               teamingStatusIcon = this.healthCheckIpHashData.teamingStatusIcon;
            } else {
               teamingStatus = this.i18nService.getString(
                     "DvsUi", "healthCheck.monitor.status.unknwon");
               teamingStatusIcon = "vsphere-icon-status-unknown";
            }

            generalSection.propertyBuilder()
                  .keyBuilder().text(this.i18nService.getString(
                  "DvsUi", "healthCheck.monitor.health.tab.teaming.statusPropName"))
                  .exit()
                  .valueBuilder()
                  .icon(teamingStatusIcon)
                  .text(teamingStatus)
                  .exit()
                  .exit();

            if (this.healthCheckIpHashData.ipHashDetails) {
               generalSection.property(this.i18nService.getString(
                     "DvsUi", "healthCheck.monitor.health.tab.teaming.detailsPropName"),
                     this.healthCheckIpHashData.ipHashDetails);
            } else {
               generalSection.property(this.i18nService.getString(
                     "DvsUi", "healthCheck.monitor.health.tab.teaming.detailsPropName"),
                     this.i18nService.getString(
                           "DvsUi", "healthCheck.monitor.health.tab.teaming.na"));
            }

         } else {
            teamingStatus = this.i18nService.getString(
                  "DvsUi", "healthCheck.monitor.status.unknwon");
            teamingStatusIcon = "vsphere-icon-status-unknown";

            generalSection.propertyBuilder()
                  .keyBuilder().text(this.i18nService.getString(
                  "DvsUi", "healthCheck.monitor.health.tab.teaming.statusPropName"))
                  .exit()
                  .valueBuilder()
                  .icon(teamingStatusIcon)
                  .text(teamingStatus)
                  .exit()
                  .exit();

            generalSection.property(this.i18nService.getString(
                  "DvsUi", "healthCheck.monitor.health.tab.teaming.detailsPropName"),
                  this.i18nService.getString(
                        "DvsUi", "healthCheck.monitor.health.tab.teaming.na"));
         }

         this.healthCheckIpHashPropertyViewData = builder.build();
      }
   }

   angular.module("com.vmware.vsphere.client.dvs").component(
         "dvsHealthCheckMonitorIpHashView",
         new DvsHealthCheckMonitorIpHashViewComponent());
}
