(function() {
   'use strict';
   angular.module('com.vmware.vsphere.client.vm')
      .service('VmProvisioningComputeResourcePageModel', VmProvisioningComputeResourcePageModel);

   VmProvisioningComputeResourcePageModel.$inject = [
      '$q',
      'i18nService',
      'sourceTypeConstants',
      'creationTypeConstants',
      'defaultUriSchemeUtil',
      'compatibilityCheckService',
      'registerVmCompatibilityCheckService',
      'resourcePoolLookupService',
      'dataService',
      'contentLibraryService',
      'authorizationService',
      'managedEntityConstants',
      'wizardPageService',
      'vcService',
      'compatibilityResultsPresenter',
      'vuiConstants'];

   function VmProvisioningComputeResourcePageModel(
         $q,
         i18nService,
         sourceTypeConstants,
         creationTypeConstants,
         defaultUriSchemeUtil,
         compatibilityCheckService,
         registerVmCompatibilityCheckService,
         resourcePoolLookupService,
         dataService,
         contentLibraryService,
         authorizationService,
         managedEntityConstants,
         wizardPageService,
         vcService,
         compatibilityResultsPresenter,
         vuiConstants) {

      return function(virtualMachineSpecBuilder, preselectedComputeResource, viewData,
            wizardConfig, scheduleTaskData) {
         var form = {
            compatibilityResultsCache: undefined,
            lastSelectedComputeResourceId: undefined,
            lastSelectedCreationType: undefined
         };
         var selectionIsValid;
         // _treeRootReference keeps the id of the last
         // datacenter for which the compute resource
         // tree was loaded
         var _treeRootReference = null;
         var _pendingValidationRequests = 0;
         var _lastValidationId = 0;
         var OVF_CONTEXTTYPE_IPALLOCATION = "com.vmware.vcenter.ovf.IpAllocationParams";
         var OVF_CONTEXTTYPE_PROPERTY = "com.vmware.vcenter.ovf.PropertyParams";
         var hasDeviceFaults = false;
         return {
            form: form,

            resetForm: function () {
               var submittedComputeResourceId = virtualMachineSpecBuilder.getComputeResourceId();
               var suggestedComputeResourceId = form.selectedComputeResourceId ||
                     submittedComputeResourceId || preselectedComputeResource;
               form.selectedComputeResourceId = suggestedComputeResourceId;
               var treeRootReference = this.getTreeRootReference();
               if(_treeRootReference !== treeRootReference) {
                  selectionIsValid = false;
                  _treeRootReference = treeRootReference;
               }
            },

            validateSelection: function () {
               if (form.lastSelectedComputeResourceId
                     && form.lastSelectedComputeResourceId === form.selectedComputeResourceId
                     && form.compatibilityResultsCache
                     && form.lastSelectedCreationType
                     && form.lastSelectedCreationType === virtualMachineSpecBuilder.getCreationType()) {
                  return $q.resolve(form.compatibilityResultsCache);
               }

               var creationType = virtualMachineSpecBuilder.getCreationType();
               form.lastSelectedCreationType = creationType;
               _pendingValidationRequests++;

               var validationId = ++_lastValidationId;

               var validationPromise;
               if(creationType === creationTypeConstants.DEPLOY_VM_FROM_TEMPLATE ||
                  creationType === creationTypeConstants.DEPLOY_VM_FROM_VAPP ||
                  creationType === creationTypeConstants.DEPLOY_VM_FROM_OVF) {
                  validationPromise =
                        validateSelectionForDeployVmFromTemplateFlow(validationId);
               } else if (creationTypeConstants.isRegisterVm(creationType)) {
                  validationPromise = validateSelectionForRegisterVmFlow(validationId);
               } else {
                  validationPromise = validateSelectionForAnyOtherFlow(validationId);
               }

               return validationPromise.then(function(compatibilityResults) {
                  _pendingValidationRequests--;
                  form.lastSelectedComputeResourceId = form.selectedComputeResourceId;
                  form.compatibilityResultsCache = compatibilityResults;
                  return form.compatibilityResultsCache;
               });
            },

            validatePage: function () {
               wizardPageService.clearErrors(wizardConfig);
               if (!selectionIsValid) {
                  return {
                     error: i18nService.getString('VmUi',
                           'SelectResourcePoolProvisioningPage.specifyValidDestination')
                  };
               }
               wizardPageService.showLoadingIndicator(wizardConfig);

               if(virtualMachineSpecBuilder.getCreationType() === creationTypeConstants.DEPLOY_VM_FROM_TEMPLATE ||
                     virtualMachineSpecBuilder.getCreationType() === creationTypeConstants.DEPLOY_VM_FROM_VAPP ||
                     virtualMachineSpecBuilder.getCreationType() === creationTypeConstants.DEPLOY_VM_FROM_OVF) {

                  var computeResId = form.selectedComputeResourceId;
                  var folderId =
                        virtualMachineSpecBuilder.getTargetInformation().folderUid;
                  var vmName = virtualMachineSpecBuilder.getName();
                  var templateId;
                  var ovfSession;
                  var sourceType = sourceTypeConstants.SOURCE_TYPE_CONTENTLIB;//let it be default
                  if (virtualMachineSpecBuilder.getCreationType() === creationTypeConstants.DEPLOY_VM_FROM_OVF) {
                     sourceType = viewData.getTemplateSourceType();
                     if (sourceType === sourceTypeConstants.SOURCE_TYPE_PUSH) {
                        ovfSession = viewData.getDeployOvfSession();
                        vmName = ovfSession.templateName;
                     } else {
                        templateId = viewData.getUrlTemplate();
                     }

                     wizardConfig.validationBanner.isCloseable = false;
                     wizardConfig.currentPage.disableNext = function() {
                        return _.some(wizardConfig.validationBanner.messages, function(message) {
                           return message.type === vuiConstants.validationBanner.type.ERROR;
                        });
                     };

                  } else {
                     templateId = viewData.getSelectedTemplate().templateId;
                  }

                  var promise = contentLibraryService.getAndValidateResourcePool(
                        computeResId, folderId, vmName, templateId, sourceType, ovfSession);
                  return promise.then(function(response) {
                     return handleValidationResponse(response);
                  });
               } else {
                  return null;
               }
            },

            setIsValid: function(isValid) {
               selectionIsValid = isValid;
            },

            submitPage: function () {
               var creationType = virtualMachineSpecBuilder.getCreationType();

               const scheduledTaskData = virtualMachineSpecBuilder.getScheduledData();

               // clone to template workflows does not have customize hw
               if (!creationTypeConstants.isCloningToATemplate(creationType)) {
                  virtualMachineSpecBuilder.setCustomizeCloneHardware(hasDeviceFaults);
               }

               // in case we are in the edit or create scheduled task for clone vm to vm
               // then the customize hardware page is not displayed unless
               // there are any hardware compatibility errors
               if (creationType === creationTypeConstants.CLONE_VM_TO_VM &&
                     scheduleTaskData && scheduleTaskData.isScheduledTask) {
                  var pages = wizardConfig.pages;
                  if (hasDeviceFaults) {
                     wizardPageService.markPageDisabled(
                           pages,
                           i18nService.getString(
                                 'VmUi', 'CustomizeHardwareProvisioningPage.Title'));
                  } else {
                     wizardPageService.markPageSkipped(
                           pages,
                           i18nService.getString(
                                 'VmUi', 'CustomizeHardwareProvisioningPage.Title'));
                  }
               }

               var invalidateNextPages = selectionIsNew();
               var computeResId = form.selectedComputeResourceId;
               var computeResName = form.selectedComputeResourceName;
               virtualMachineSpecBuilder.setComputeResourceId(computeResId);
               virtualMachineSpecBuilder.setComputeResourceName(computeResName);

               // in case the compute resource is changed from the one selected when the
               // scheduled task was created, do not use the data to pre-populate the customize hardware page
               if (!_.isEmpty(scheduledTaskData)) {
                  if (scheduledTaskData.computeResourceId !== computeResId) {
                     scheduledTaskData.useScheduledTaskData = false;
                  }
               }

               if(creationType !== creationTypeConstants.DEPLOY_VM_FROM_TEMPLATE
                  && creationType !== creationTypeConstants.DEPLOY_VM_FROM_VAPP
                  && creationType !== creationTypeConstants.DEPLOY_VM_FROM_OVF
                  && !creationTypeConstants.isRegisterVm(creationType)) {
                  return $q.resolve({
                     wizardFlow: creationType,
                     invalidateNextPages: invalidateNextPages
                  });
               }

               var promisses = {
                  resPool: resourcePoolLookupService.lookupResourcePoolForComputeResource(computeResId),
                  filterNetworks: getFilterNetworksObjectId(computeResId)
               };

               return $q.all(promisses).then(function(result) {
                  virtualMachineSpecBuilder.setResourcePool(result.resPool);
                  virtualMachineSpecBuilder.setFilterNetworksObjectId(result.filterNetworks);

                  return {
                     wizardFlow: creationType,
                     invalidateNextPages: invalidateNextPages
                  };
               }).then(function(result) {
                  wizardPageService.hideLoadingIndicator(wizardConfig);
                  return result;
               });
            },

            getTreeRootReference: function () {
               return virtualMachineSpecBuilder.getTargetInformation().datacenterUid;
            },

            // NOTE alalev: This method returns a Promise<string>, not a string
            getTreeType: function() {
               switch (virtualMachineSpecBuilder.getCreationType()) {
                  case creationTypeConstants.CREATE_FROM_SCRATCH:
                  case creationTypeConstants.CLONE_TEMPLATE_TO_VM:
                  case creationTypeConstants.CLONE_AS_VMTEMPLATE_TO_LIBRARY:
                  case creationTypeConstants.CLONE_VM_TO_VM:
                  case creationTypeConstants.CONVERT_TEMPLATE_TO_VM:
                  case creationTypeConstants.DEPLOY_VM_FROM_VPP:
                  case creationTypeConstants.DEPLOY_VM_FROM_TEMPLATE:
                  case creationTypeConstants.CLONE_VM_TO_TEMPLATE:
                  case creationTypeConstants.CLONE_TEMPLATE_TO_TEMPLATE:
                  case creationTypeConstants.DEPLOY_VM_FROM_OVF:
                  case creationTypeConstants.DEPLOY_VM_FROM_VMTX:
                     return $q.resolve('DcHostsAndClustersTree');
                  case creationTypeConstants.REGISTER_VM:
                     if (!virtualMachineSpecBuilder.getRegisterVmData().isTemplate) {
                        return $q.resolve('DcHostsAndClustersTree');
                     }
                     var root = virtualMachineSpecBuilder.getTargetInformation().datacenterUid;
                     return vcService.is66VcOrLater(root).then(function (is66VcOrLater) {
                        return is66VcOrLater
                              ? 'DcClusterHostAndRpTree'
                              : 'DcClusterAndHostOnlyTree';
                     });
                  default:
                     return $q.resolve('DcClusterAndHostOnlyTree');
               }
            },

            validationFinishedLoading: function () {
               return (_pendingValidationRequests === 0);
            }
         };

         function handleValidationResponse(response) {
            if(response && response.result && response.result.newContext) {
               var newContext = response.result.newContext;
               var gosCustomizationSpec = virtualMachineSpecBuilder.getCustomizationSpec();
               if (!_.isEmpty(gosCustomizationSpec)) {
                  newContext = processContextForGOSCustomization(newContext);
               }
               viewData.setDeployFromLibraryCommonContext(newContext);
               viewData.setResourcePoolDeploymentResponse(response);
               return response;
            } else {
               wizardPageService.hideLoadingIndicator(wizardConfig);
               var backendErrorStr = readLocalizedBackendErrors(response);
               return {
                  error: (backendErrorStr ? backendErrorStr :
                     i18nService.getString('VmUi', 'SelectResourcePoolProvisioningPage.fatalError'))
               };
            }
         }
         function processContextForGOSCustomization(contextObjects) {
              var newContext = [];
              _.each (contextObjects, function (ctxObject) {
                 //skip PropertyParam and IpAllocation type objects
                  if (ctxObject._type !== OVF_CONTEXTTYPE_PROPERTY &&
                        ctxObject._type !== OVF_CONTEXTTYPE_IPALLOCATION) {
                     newContext.push(ctxObject);
                  }
              });
              return newContext;
          }

         function validateSelectionForDeployVmFromTemplateFlow (validationId) {
            var objectId = form.selectedComputeResourceId;
            var resourcePoolForComputeResource;
            var compatibilityResults;
            var advancedCompatibilityContents;
            var localizedError;

            if (defaultUriSchemeUtil.getEntityType(objectId) === 'Datacenter') {
               selectionIsValid = false;
               localizedError = i18nService.getString('VmUi', 'SelectResourcePoolProvisioningPage.SelectRPOnly');
               return $q.when(buildErrorMessageObject([localizedError]));
            }

            return contentLibraryService.validateComputeResourceSelection(objectId)
               .then(function(resourceValidationResponse) {

                  if (!isValidationRelevant(validationId)) {
                     compatibilityResults = null;
                     return compatibilityResults;
                  }

                  advancedCompatibilityContents = parseMessagesToAdvanced(resourceValidationResponse);

                  if (resourceValidationResponse.result) {
                     var parsedTopLevelAndHostMessages = parseMessages(resourceValidationResponse.result);
                     selectionIsValid = resultIsValid(parsedTopLevelAndHostMessages);
                     compatibilityResults = formatAndCombineMessages(parsedTopLevelAndHostMessages);
                  } else {
                     selectionIsValid = false;
                     compatibilityResults = buildErrorMessageObject([resourceValidationResponse.error.localizedMessage]);
                  }

                  if (!selectionIsValid) {
                     return compatibilityResults;
                  } else {
                     return contentLibraryService.getResourcePoolForComputeResource(objectId);
                  }

               })
               .then(function(resourcePoolForComputeResponse) {

                  if (!selectionIsValid || !isValidationRelevant(validationId)) {
                     return compatibilityResults;
                  }

                  if (!resourcePoolForComputeResponse.resourcePool) {
                     appendToAdvancedContents([i18nService.getString('ProvisioningUiLib',
                           'SelectResourcePage.errorNoResourcePool')],
                           [],[],
                           advancedCompatibilityContents,
                           compatibilityResultsPresenter.NO_HOST_ID);
                  }

                  if (resourcePoolForComputeResponse.resourcePool) {
                     resourcePoolForComputeResource = defaultUriSchemeUtil.getVsphereObjectId(resourcePoolForComputeResponse.resourcePool);
                     virtualMachineSpecBuilder.setResourcePool({
                        id: resourcePoolForComputeResource,
                        resourcePoolMor: resourcePoolForComputeResponse.resourcePool
                     });
                     return resourcePoolPrivilegeValidation(resourcePoolForComputeResource);
                  } else {
                     var localizedError = i18nService.getString('ProvisioningUiLib', 'SelectResourcePage.errorNoResourcePool');
                     selectionIsValid = false;
                     compatibilityResults = buildErrorMessageObject([localizedError]);
                     return compatibilityResults;
                  }

               })
               .then(function(privilegeValidationResponse) {

                  if (!selectionIsValid || !isValidationRelevant(validationId)) {
                     return compatibilityResults;
                  }

                  if (!privilegeValidationResponse[0]) {
                     appendToAdvancedContents([i18nService.getString('ProvisioningUiLib',
                           'SelectResourcePage.errorNoPrivilegeForH5')],
                           [],[],
                           advancedCompatibilityContents,
                           compatibilityResultsPresenter.NO_HOST_ID);
                  }

                  if (privilegeValidationResponse[0]) {
                     return disabledMethodValidation(resourcePoolForComputeResource);
                  } else {
                     var localizedError = i18nService.getString('ProvisioningUiLib', 'SelectResourcePage.errorNoPrivilegeForH5');
                     selectionIsValid = false;
                     compatibilityResults = buildErrorMessageObject([localizedError]);
                     return compatibilityResults;
                  }

               })
               .then(function(disabledMethodValidationResponse) {

                  if (!selectionIsValid || !isValidationRelevant(validationId)) {
                     return compatibilityResults;
                  }

                  if (!disabledMethodValidationResponse) {
                     appendToAdvancedContents([i18nService.getString('ProvisioningUiLib',
                           'SelectResourcePage.errorImportVAppDisabled')],
                           [],[],
                           advancedCompatibilityContents,
                           compatibilityResultsPresenter.NO_HOST_ID);
                  }

                  if (disabledMethodValidationResponse) {
                     return compatibilityResults;
                  } else {
                     var localizedError = i18nService.getString('ProvisioningUiLib', 'SelectResourcePage.errorImportVAppDisabled');
                     selectionIsValid = false;
                     compatibilityResults = buildErrorMessageObject([localizedError]);
                     return compatibilityResults;
                  }

               })
               .then(function(compatibilityResults) {
                  return prettifyAndAppendAdvancedContents(compatibilityResults,
                        advancedCompatibilityContents);
               });
         }

         function validateSelectionForRegisterVmFlow(validationId) {
            selectionIsValid = false;
            var advancedCompatibilityContents = appendToAdvancedContents();

            return registerVmCompatibilityCheckService.validate(
                  form.selectedComputeResourceId,
                  virtualMachineSpecBuilder.getStorageObject(),
                  virtualMachineSpecBuilder.getRegisterVmData().isTemplate
            ).then(function(validationResult) {
               if (!isValidationRelevant(validationId)) {
                  return null;
               }
               var message;
               var errorMessages;
               if (validationResult.error) {
                  var backendErrorStr = readLocalizedBackendErrors(validationResult);

                  message = backendErrorStr || i18nService.getString('VmUi',
                              'SelectResourcePoolProvisioningPage.fatalError');
                  advancedCompatibilityContents = appendToAdvancedContents([message],
                        [],[]);
                  errorMessages = buildErrorMessageObject([message]);
                  return errorMessages;
               }

               var checkResult = validationResult.result;
               // if there is any compatibility issue, build the error message
               if (checkResult && checkResult.localizationKey) {
                  selectionIsValid = false;

                  var args = ['VmUi', checkResult.localizationKey]
                        .concat(checkResult.localizationArgs);
                  message = i18nService.getString.apply(null, args);
                  advancedCompatibilityContents = appendToAdvancedContents([message],
                        [],[]);
                  errorMessages = buildErrorMessageObject([message]);
                  return errorMessages;
               }

               selectionIsValid = true;
               return createCompatibilityCheckSucceededMessage();
            }).then(function(compatibilityResults) {
               return prettifyAndAppendAdvancedContents(compatibilityResults,
                     advancedCompatibilityContents);
            });
         }

         function validateSelectionForAnyOtherFlow(validationId) {
            var advancedCompatibilityContents;
            var vmOrTemplateRef;
            if(virtualMachineSpecBuilder.getCreationType() === creationTypeConstants.DEPLOY_VM_FROM_VMTX) {
               vmOrTemplateRef = virtualMachineSpecBuilder.getVmtxLibraryItemId();
            } else {
               vmOrTemplateRef = virtualMachineSpecBuilder.getVmId();
            }

            return compatibilityCheckService.validateComputeResource(
               form.selectedComputeResourceId,
               virtualMachineSpecBuilder.getTargetInformation().folderUid,
               virtualMachineSpecBuilder.getCreationType(),
               virtualMachineSpecBuilder.getName(),
               vmOrTemplateRef
            ).then(function(response) {
               if (!isValidationRelevant(validationId)) {
                  return null;
               }

               advancedCompatibilityContents = parseMessagesToAdvanced(response);

               if (_.isEmpty(response.result) && !response.error) {
                  // The response.result (Check::Result) is specifically a *per-host* result.
                  // With no hosts, we have no results
                  var errorMessage = i18nService.getString('VmUi',
                        'SelectResourcePoolProvisioningPage.NoHostsFound');
                  var parsedMessages = {};
                  parsedMessages.topLevelMessages = [{
                     type: 'error',
                     message: errorMessage
                  }];

                  advancedCompatibilityContents.hostMap[compatibilityResultsPresenter.NO_HOST_ID]
                        .errors.push(errorMessage);

                  return formatAndCombineMessages(parsedMessages);
               }

               var parsedTopLevelAndHostMessages = parseMessages(response.result);
               selectionIsValid = resultIsValid(parsedTopLevelAndHostMessages);
               var result = formatAndCombineMessages(parsedTopLevelAndHostMessages);
               if (!selectionIsValid) {
                  return result;
               }

               hasDeviceFaults = checkHasDeviceFaults(response.result);

               var ownerRef = response.result[0].owner;
               var ownerId = ownerRef && defaultUriSchemeUtil.getVsphereObjectId(
                     response.result[0].owner);
               virtualMachineSpecBuilder.setComputeResourceOwnerId(ownerId);
               var resourcePool = defaultUriSchemeUtil.getVsphereObjectId(
                     response.result[0].resourcePool);
               var rpRuntime = response.result[0].rpRuntime;
               var networkFilterObject = defaultUriSchemeUtil.getVsphereObjectId(
                     response.result[0].networkFilterObject);
               virtualMachineSpecBuilder.setResourcePool({
                  id: resourcePool,
                  runtime: rpRuntime
               });
               virtualMachineSpecBuilder.setFilterNetworksObjectId(networkFilterObject);
               var creationType = virtualMachineSpecBuilder.getCreationType();
               if (creationType === creationTypeConstants.CREATE_FROM_SCRATCH) {
                  var objectType = defaultUriSchemeUtil.getEntityType(form.selectedComputeResourceId);
                  if (objectType === managedEntityConstants.V_APP) {
                     virtualMachineSpecBuilder.setIsResourcePoolInVapp(true);
                  }
                  if (objectType === managedEntityConstants.RESOURCE_POOL) {
                     var isRpInVapp = response.result[0].isInVapp;
                     virtualMachineSpecBuilder.setIsResourcePoolInVapp(isRpInVapp);
                  }
               }
               return result;
            }).then(function(compatibilityResults) {
               return prettifyAndAppendAdvancedContents(compatibilityResults,
                     advancedCompatibilityContents);
            });
         }

         function appendToAdvancedContents(errors, warnings, infos,
               advancedCompatibilityContents, hostId) {

            if (!advancedCompatibilityContents) {
               advancedCompatibilityContents = {
                  hostMap: {},
                  vmId: compatibilityResultsPresenter.NO_VM_ID,
                  vmProps: {
                     name: compatibilityResultsPresenter.NO_VM_NAME,
                     icon: compatibilityResultsPresenter.NO_VM_ICON
                  }
               };
            }
            if (!hostId) {
               hostId = compatibilityResultsPresenter.NO_HOST_ID;
            }
            if (!errors) {
               errors = [];
            }
            if (!warnings) {
               warnings = [];
            }
            if (!infos) {
               infos = [];
            }

            if (!advancedCompatibilityContents.hostMap[hostId]) {
               advancedCompatibilityContents.hostMap[hostId] = {
                  errors: [],
                  warnings: [],
                  infos: [],
                  props: {
                     icon: compatibilityResultsPresenter.NO_HOST_ICON,
                     name: compatibilityResultsPresenter.NO_HOST_NAME
                  }
               };
            }

            advancedCompatibilityContents.hostMap[hostId].errors =
                  advancedCompatibilityContents.hostMap[hostId].errors.concat(errors);
            advancedCompatibilityContents.hostMap[hostId].warnings =
                  advancedCompatibilityContents.hostMap[hostId].warnings.concat(warnings);
            advancedCompatibilityContents.hostMap[hostId].infos =
                  advancedCompatibilityContents.hostMap[hostId].infos.concat(infos);

            return advancedCompatibilityContents;
         }

         function getAffectedObjects(advancedCompatibilityContents) {
            var hostIds = Object.keys(advancedCompatibilityContents.hostMap);
            hostIds = _.filter(hostIds, function(hostId) {
               return hostId !== compatibilityResultsPresenter.NO_HOST_ID;
            });

            var vmIds = [];
            if (advancedCompatibilityContents.vmId !== compatibilityResultsPresenter.NO_VM_ID) {
               vmIds.push(advancedCompatibilityContents.vmId);
            }

            return {
               hostIds: hostIds,
               vmIds: vmIds
            };
         }

         function prettifyAndAppendAdvancedContents(basicCompatibilityResults,
               advancedCompatibilityContents) {
            if (!basicCompatibilityResults || !advancedCompatibilityContents) {
               return basicCompatibilityResults;
            }

            advancedCompatibilityContents.vmProps.name =
                  virtualMachineSpecBuilder.getName();

            var affectedObjects = getAffectedObjects(advancedCompatibilityContents);
            if (_.isEmpty(affectedObjects.vmIds) &&_.isEmpty(affectedObjects.hostIds)){
               basicCompatibilityResults[0].advancedCompatibilityContents =
                     advancedCompatibilityContents;
               return basicCompatibilityResults;
            }

            return dataService.getPropertiesForObjects(affectedObjects.hostIds,
                  ["primaryIconId", "name"]).then(function(props) {
               _.each(props, function(singleHostProps, hostId) {
                  advancedCompatibilityContents.hostMap[hostId].props.name =
                        singleHostProps.name;
                  advancedCompatibilityContents.hostMap[hostId].props.icon =
                        singleHostProps.primaryIconId;
               });


               basicCompatibilityResults[0].advancedCompatibilityContents =
                     advancedCompatibilityContents;
               return basicCompatibilityResults;
            });
         }

         function parseMessagesToAdvanced(response) {
            var advancedCompatibilityContents = appendToAdvancedContents();

            if (response.error) {
               var hostId = compatibilityResultsPresenter.NO_HOST_ID;
               if (response.entity &&
                     response.entity.type === managedEntityConstants.HOST) {
                  hostId = defaultUriSchemeUtil.getVsphereObjectId(response.entity);
               }
               appendToAdvancedContents([response.error.localizedMessage],
                     [],[],
                     advancedCompatibilityContents,
                     hostId);
               return advancedCompatibilityContents;
            }

            if (!response.result) {
               return advancedCompatibilityContents;
            }

            _.each(response.result, function(singleResult) {
               var errors = [];
               var warnings = [];
               var infos = [];
               var hostId = compatibilityResultsPresenter.NO_HOST_ID;
               _.each(singleResult.messages, function(message) {
                  if (message.type === "error") {
                     errors.push(message.message);
                  } else if(message.type === "warning") {
                     warnings.push(message.message);
                  } else {
                     infos.push(message.message);
                  }
               });

               if (singleResult.host && singleResult.host.type ===
                     managedEntityConstants.HOST) {
                  hostId = defaultUriSchemeUtil.getVsphereObjectId(singleResult.host);
               }
               appendToAdvancedContents(errors,
                     warnings, infos,
                     advancedCompatibilityContents, hostId);
            });

            return advancedCompatibilityContents;
         }

         function isValidationRelevant(validationId) {
            return validationId === _lastValidationId;
         }

         function selectionIsNew() {
            return virtualMachineSpecBuilder.getComputeResourceId() !== form.selectedComputeResourceId;
         }

         function ifThereAreHostsAtLeastOneHasNoErrors(response) {
            if (_.isEmpty(response.hostSpecificMessagesByHostName)) {
               return true;
            }

            if (response.hostsWithNoMessagesCount > 0) {
               return true;
            }

            return _.any(response.hostSpecificMessagesByHostName, function (messages) {
               return _.all(messages, function (message) {
                  return message.type !== 'error';
               });
            });
         }

         function resultIsValid(result) {
            if (!result) {
               return true;
            }

            var noTopLevelErrors = _.all(result.topLevelMessages, function (message) {
               return message.type !== 'error';
            });

            return noTopLevelErrors && ifThereAreHostsAtLeastOneHasNoErrors(result);
         }

         function parseMessages(response) {
            if (!response || response.length === 0) {
               return null;
            }

            var allHostMessages = _.filter(response, function(result) {
               return result.host;
            });

            var messagesToDisplay = _.filter(response, function (result) {
               return result.messages && result.messages.length > 0;
            });

            if (messagesToDisplay.length < 1) {
               return null;
            }

            // partitionedResults[0] is for host-specific messages and
            // partitionedResults[1] is for all other messages
            var partitionedResults = _.partition(messagesToDisplay, function (result) {
               return result.host;
            });

            var hostSpecificMessagesByHostName = _.chain(partitionedResults[0])
                  .groupBy('hostName')
                  .mapObject(function (results) {
                     return _.flatten(_.pluck(results, 'messages'));
                  }).value();

            var topLevelMessages = _.flatten(_.pluck(partitionedResults[1], 'messages'));

            return {
               topLevelMessages: topLevelMessages,
               hostSpecificMessagesByHostName: hostSpecificMessagesByHostName,
               hostsWithNoMessagesCount: allHostMessages.length - partitionedResults[0].length
            };
         }

         function formatAndCombineMessages(parsedMessages){
            if (parsedMessages){
               var formattedHostMessages = _.map(parsedMessages.hostSpecificMessagesByHostName, function(messages, hostName){
                  return {
                     icon: 'vsphere-icon-host',
                     message: hostName,
                     contents: _.map(messages, formatCompatibilityMessage)
                  };
               });
               var formattedTopLevelMessages = _.map(parsedMessages.topLevelMessages, formatCompatibilityMessage);

               return formattedTopLevelMessages.concat(formattedHostMessages);
            } else {
               return createCompatibilityCheckSucceededMessage();
            }
         }

         function createCompatibilityCheckSucceededMessage() {
            return [
               {
                  icon: 'vsphere-icon-status-ok',
                  message: i18nService.getString('VmUi',
                        'ProvisioningWizard.CompatibilityChecksSucceeded'),
                  contents: []
               }
            ];
         }

         function formatCompatibilityMessage(message) {
            return {
               icon: message.type === 'error' ?
                     'vsphere-icon-status-error' : 'vsphere-icon-status-warning',
               message: message.message,
               contents: []
            };
         }

         function getFilterNetworksObjectId(selectedCrId) {
            // if the selected compute resource is Cluster -> return this cluster
            // otherwise get the ComputeResource instance of the selected host, res pool
            // or vApp, and if it is a ClusterComputeResource, return that cluster,
            // otherwise - return the first host of the parent ComputeResource object
            var selectedCR =
                  defaultUriSchemeUtil.getPartsFromVsphereObjectId(selectedCrId);
            if(selectedCR.type === managedEntityConstants.CLUSTER) {
               return $q.resolve(selectedCrId);
            }

            var relationName;
            switch(selectedCR.type) {
               case managedEntityConstants.HOST:
                  relationName = 'host';
                  break;
               case managedEntityConstants.RESOURCE_POOL:
               case managedEntityConstants.V_APP:
                  relationName = 'rp';
                  break;
               default: break;
            }

            var parentCrPromise = dataService.getPropertiesByRelation(selectedCrId,
                  relationName, managedEntityConstants.ANY_COMPUTE_RESOURCE, ['host', '@type']);
            return parentCrPromise.then(function(res) {
               if(!res || _.values(res).length === 0) {
                  return null;
               }
               var crType = _.values(res)[0]['@type'];
               if(crType === managedEntityConstants.CLUSTER) {
                  var clusterId;
                  _.each(res, function(value, object) {
                     clusterId = object;
                  });
                  return clusterId;
               }

               var host = _.values(res)[0].host[0];
               var hostId = defaultUriSchemeUtil.getVsphereObjectId(host);
               return hostId;
            });
         }

         function buildErrorMessageObject(messages) {
            return _.map(messages, function(message) {
               return {
                  icon: 'vsphere-icon-status-error',
                  message: message,
                  contents: []
               };
            });
         }

         function resourcePoolPrivilegeValidation(resourcePoolId) {
            var reqPrivileges = ['VApp.Import'];
            return authorizationService.checkPrivileges(resourcePoolId, reqPrivileges)
               .then(function(result) {
                  return result;
               });
         }

         function disabledMethodValidation(resourcePoolId) {
            return dataService.getProperties(resourcePoolId, ['disabledMethod'])
               .then(function(result) {
                  return (_.indexOf(result, 'ImportVApp') === -1);
               });
         }

         function readLocalizedBackendErrors (response) {
            if(response && response.error && !_.isEmpty(response.error.localizedMessage)){
               return response.error.localizedMessage;
            }
         }

         function checkHasDeviceFaults(compatCheckResult) {
            if (!compatCheckResult || compatCheckResult.length === 0) {
               return false;
            }

            var hasFaults = _.some(compatCheckResult, function(result){
               return result.hasDeviceFaults;
            });
            return hasFaults;
         }
      };
   }
})();
