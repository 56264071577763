(function() {
   'use strict';
   angular.module('com.vmware.vsphere.client.cluster')
      .controller('AddClusterGroupController', AddClusterGroupController);

   AddClusterGroupController.$inject = ['$scope', 'i18nService', '$q',
      'clusterGroupsService', 'vuiConstants', 'mutationService',
      'clusterSpecBuilderService', 'clusterGroupsConstants'];

   function AddClusterGroupController($scope, i18nService, $q, clusterGroupsService,
      vuiConstants, mutationService, clusterSpecBuilderService, clusterGroupsConstants) {
      var self = this;

      var objectId = $scope._route.objectId;

      self.spec = {};
      self.spec.groupType = clusterGroupsService.DEFAULT_GROUP_TYPE;
      self.spec.name = '';
      self.spec.groupMembers = [];
      $scope.modalOptions.isAlertClosed = true;

      getViewData();

      $scope.modalOptions.onSubmit = function() {
         return onCommit();
      };

      function getViewData() {
         self.typeOptions = clusterGroupsService.buildTypesOptions();
      }

      function getString(key) {
         return i18nService.getString('ClusterUi', key);
      }

      function validate() {
         if (self.spec.name) {
            if ((/^\s*$/gi).test(self.spec.name)) {
               $scope.modalOptions.alerts = [{
                  text: getString('groups.config.error.nameEmpty'),
                  type: vuiConstants.validationBanner.type.ERROR
               }];
               $scope.modalOptions.isAlertClosed = false;

               return $q.reject();
            } else if (self.spec.name.length > 80) {
               $scope.modalOptions.alerts = [{
                  text: getString('groups.config.error.nameTooLong'),
                  type: vuiConstants.validationBanner.type.ERROR
               }];
               $scope.modalOptions.isAlertClosed = false;

               return $q.reject();
            }
         } else {
            $scope.modalOptions.alerts = [{
               text: getString('groups.config.error.nameEmpty'),
               type: vuiConstants.validationBanner.type.ERROR
            }];
            $scope.modalOptions.isAlertClosed = false;

            return $q.reject();
         }

         if (self.spec.groupMembers && self.spec.groupMembers.length === 0) {
            $scope.modalOptions.alerts = [{
               text: getString('groups.config.error.noMembers'),
               type: vuiConstants.validationBanner.type.ERROR
            }];
            $scope.modalOptions.isAlertClosed = false;

            return $q.reject();
         }

         var groupMemberIds = clusterGroupsService.getSelectedItemsIds(
               self.spec.groupMembers);

         self.groupSpec = clusterGroupsService.buildGroupSpec(self.spec.groupType,
               self.spec.name,
               clusterGroupsService.buildGroupMembersSpec(groupMemberIds),
               clusterGroupsConstants.operationType.ADD);

         var validationPromise =
            mutationService.validate(
               objectId,
               'com.vmware.vim.binding.vim.cluster.GroupSpec',
               self.groupSpec
            ).then(function(validationResult) {
               $scope.modalOptions.isAlertClosed = true;
               $scope.modalOptions.alerts = [];

               if (!!validationResult && (!validationResult.result || !!validationResult.error)) {
                  $scope.modalOptions.alerts.push({
                     text: getString('groups.config.error.nameNotUnique'),
                     type: vuiConstants.validationBanner.type.ERROR
                  });
                  $scope.modalOptions.isAlertClosed = false;

                  return $q.reject();
               } else {
                  return $q.resolve();
               }
            });

         return validationPromise;
      }

      function onCommit() {
         return validate()
            .then(function() {
               if(!self.groupSpec) {
                  var groupMemberIds = clusterGroupsService.getSelectedItemsIds(
                        self.spec.groupMembers);
                  self.groupSpec = clusterGroupsService.buildGroupSpec(
                     self.spec.groupType,
                     self.spec.name,
                     clusterGroupsService.buildGroupMembersSpec(groupMemberIds)
                  );
               }

               var clusterComputeResourceSpec =
                  clusterSpecBuilderService.buildClusterComputeResourceSpec(
                        {
                           group: self.groupSpec
                        },
                        true
                  );

               mutationService.apply(
                  objectId,
                  'com.vmware.vsphere.client.cluster.ClusterComputeResourceSpec',
                  clusterComputeResourceSpec
               );
               return true;
            })
            .catch(function(e) {
               return false;
            });
      }
   }
})();
