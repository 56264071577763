/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace dvs_ui {

   export class DvsImportWizardConstants {

      public static readonly page: any = {
         SWITCH_CONFIGURATION_PAGE: "switchConfigurationPage",
         READY_TO_COMPLETE_PAGE: "readyToCompletePage"
      };
   }
}
