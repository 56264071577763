(function() {
   'use strict';

   /**
    * Service that provides edit HA heartbeat datastores related functionality.
    */
   angular.module('com.vmware.vsphere.client.cluster')
      .service('heartbeatDatastoresService', heartbeatDatastoresService);

   heartbeatDatastoresService.$inject = ['i18nService', 'haConstants', 'vuiConstants',
      'columnRenderersRegistry', 'defaultUriSchemeUtil', 'dataService'];

   function heartbeatDatastoresService(i18nService, haConstants,
         vuiConstants, columnRenderersRegistry, defaultUriSchemeUtil, dataService) {

      var NA_LABEL = i18nService.getString('ClusterUi', 'datastoreClusterNA');

      return {
         getDatastoreGridOptions: getDatastoreGridOptions,
         getHostGridOptions: getHostGridOptions,
         getDatastoreHosts: getDatastoreHosts,
         getCandidatePolicies: getCandidatePolicies,
         isAutoSelectPolicy: isAutoSelectPolicy
      };

      //region Public functions

      function getDatastoreGridOptions(isEditMode) {
         return {
            selectionMode: vuiConstants.grid.selectionMode.SINGLE,
            sortMode: vuiConstants.grid.sortMode.SINGLE,
            sortOrder: [{
               field: 'accessibleAndMountedHosts.length',
               dir: 'desc'
            }],
            searchable: false,
            resizable: true,
            selectedItems: [],
            data: [],
            columnDefs: getDatastoreGridColumnDefs(isEditMode),
            height: '249px'
         };
      }

      function getHostGridOptions(isEditMode, dataBoundHandler) {
         return {
            selectionMode: vuiConstants.grid.selectionMode.NONE,
            sortMode: vuiConstants.grid.sortMode.NONE,
            searchable: false,
            resizable: true,
            selectedItems: [],
            data: [],
            columnDefs: getHostGridColumnDefs(isEditMode),
            height: '249px',
            dataBound: dataBoundHandler
         };
      }

      function getDatastoreHosts(accessibleAndMountedHosts) {

         var selectedObjectHostIds = _.map(accessibleAndMountedHosts, function(host) {
            return defaultUriSchemeUtil.getVsphereObjectId({
               type: host.type,
               serverGuid: host.serverGuid,
               value: host.value
            });
         });

         return dataService.getPropertiesForObjects(selectedObjectHostIds,['name', 'primaryIconId'])
               .then(function(data) {
                  return _.map(data, function(host, id) {
                     return {
                        provider: defaultUriSchemeUtil.getManagedObjectReference(id),
                        name: host['name'],
                        iconId: host['primaryIconId']
                     };
                  });
               });
      }

      function getCandidatePolicies() {
         var hbDsCandidatePolicies =
            Object.keys(haConstants.hbDatastoreCandidatePolicy).map(function(key) {
               var policyKey = haConstants.hbDatastoreCandidatePolicy[key];
               var policy = buildPolicy(policyKey);
               return policy;
            });

         return hbDsCandidatePolicies;
      }

      function isAutoSelectPolicy(policy) {
         return policy === haConstants.hbDatastoreCandidatePolicy.AUTO_SELECT;
      }

      //endregion

      //region Private functions

      function getDatastoreGridColumnDefs(isEditMode) {
         var dsColumns = [];

         if (isEditMode) {
            dsColumns.push(
               {
                  displayName: ' ',
                  field: 'isPreferred',
                  type: 'boolean',
                  width: '27px',
                  template: checkboxRenderer
               });
         }

         dsColumns.push(
            {
               displayName: i18nService.getString('ClusterUi', 'datastoreNameHeaderText'),
               field: 'name',
               type: 'string',
               template: isEditMode ? iconTextRenderer : iconLinkRenderer
            },
            {
               displayName: i18nService.getString('ClusterUi', 'datastoreClusterNameHeaderText'),
               field: 'dsClusterData',
               type: 'object',
               searchable: false,
               template:  isEditMode ? dsClusterNameTextRenderer : dsClusterNameLinkRenderer
            },
            {
               displayName: i18nService.getString('ClusterUi', 'hostMountsCountHeaderText'),
               field: 'accessibleAndMountedHosts.length',
               type: 'number'
            }
         );

         return dsColumns;
      }
      function getHostGridColumnDefs(isEditMode) {
         return [
            {
               displayName: i18nService.getString('ClusterUi', 'hostNameHeaderText'),
               field: 'name',
               type: 'string',
               template: isEditMode ? iconTextRenderer : iconLinkRenderer
            }
         ];
      }

      function dsClusterNameTextRenderer(data) {
         return iconTextRenderer(data.dsClusterData);
      }

      function dsClusterNameLinkRenderer(data) {
         return iconLinkRenderer(data.dsClusterData);
      }

      function checkboxRenderer(data) {
         var checkedAttr = data.isPreferred ? 'checked' : '';
         return '<input type="checkbox" class="row-checkbox" ' + checkedAttr + '>';
      }

      function iconTextRenderer(data) {
         var objData = data || {
                  name: NA_LABEL
               };
         var renderer = columnRenderersRegistry.getColumnRenderer('icon-text');
         return renderer(['iconId', 'name'], objData);
      }

      function iconLinkRenderer(data) {
         var objData = data || {};

         objData.uid = defaultUriSchemeUtil.getVsphereObjectId(objData.provider);
         objData.customLabel = NA_LABEL;

         var renderer = columnRenderersRegistry.getColumnRenderer('object-link');
         return renderer(['uid', 'name', objData.iconId, 'customLabel'], objData);
      }

      function buildPolicy(policyKey) {
         return {
            id: policyKey,
            label: getLabel(policyKey)
         };
      }

      function getLabel(policyKey) {
         var policyTextKey;
         switch (policyKey) {
            case haConstants.hbDatastoreCandidatePolicy.AUTO_SELECT:
               policyTextKey = 'ha.config.allFeasibleDs';
               break;
            case haConstants.hbDatastoreCandidatePolicy.USER_SELECTED_ONLY:
               policyTextKey = 'ha.config.userSelectedDs';
               break;
            case haConstants.hbDatastoreCandidatePolicy.USER_SELECTED_WITH_AUTO_SELECTED:
               policyTextKey = 'ha.config.allFeasibleDsWithUserPreference';
               break;
            default:
               return '';
         }
         return i18nService.getString('ClusterUi', policyTextKey);
      }

      //endregion
   }
})();
