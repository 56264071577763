namespace h5_vm {
   class ScsiControllerService {
      public static $inject: string[] = ['deviceService', 'i18nService'];

      constructor(private deviceService: any, private i18nService: any) {
      }

      public makeDefaultSCSIController(guestOsDescriptor: any, deviceOptions: any, virtualMachineDevices: any): {device: {_type: string, key: number}} {
         let type: string = guestOsDescriptor.recommendedSCSIController;
         let busNumber: number = this.findSCSIControllerBusNumber(virtualMachineDevices);
         if (busNumber >= 0) {
            var device = this.deviceService.makeController(
                  deviceOptions, type, this.findSCSIControllerBusNumber(virtualMachineDevices));
            return {
               device: device
            };
         }
         else {
            throw new Error(this.i18nService.getString('VmUi','VmDeviceManager.DeviceLimitReached'));
         }
      }

      private findSCSIControllerBusNumber(virtualMachineDevices: any): number {
         let allSCSIControllers = virtualMachineDevices.existingDevicesOfType('VirtualSCSIController');
         allSCSIControllers = allSCSIControllers.concat(virtualMachineDevices.newDevicesOfType('VirtualSCSIController'));
         for (let busNumber = 0; busNumber <= 3; busNumber++) {
            if (!this.findSCSIController(busNumber, allSCSIControllers)) {
               return busNumber;
            }
         }
         return -1;
      }

      private findSCSIController(busNumber: number, allSCSIControllers: Array<any>): number {
         return _.find(allSCSIControllers, (controller) => {
            return controller.getCurrentDevice().busNumber === busNumber;
         });
      }
   }

   angular.module('com.vmware.vsphere.client.vm')
         .service('scsiControllerService', ScsiControllerService);
}
