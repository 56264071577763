/* Copyright 2015 VMware, Inc. All rights reserved. -- VMware Confidential */

/**
 * The select VM name and folder page controller.
 */
(function () {
   'use strict';

   angular
      .module('com.vmware.vsphere.client.vm')
      .controller('VmProvisioningNameAndFolderController', VmProvisioningNameAndFolderController);

   VmProvisioningNameAndFolderController.$inject = ['$scope', 'i18nService', 'creationTypeConstants', 'wizardPageService'];

   function VmProvisioningNameAndFolderController($scope, i18nService, creationTypeConstants, wizardPageService) {
      var self = this;

      $scope.nameAndFolderPageModel.resetForm();

      self.form = $scope.nameAndFolderPageModel.form;
      self.treeType = $scope.nameAndFolderPageModel.getTreeType();
      self.treeRoot = $scope.nameAndFolderPageModel.getTreeRoot();
      self.preselectedLocation = $scope.nameAndFolderPageModel.targetIdToPreselect();
      self.pageInstructions = $scope.nameAndFolderPageModel.pageInstructions();
      self.wizardType =  $scope.vmParams.getCreationType();
      self.invalidNameMessage = '';

      self.onNameChanged = function() {
         self.invalidNameMessage =
               $scope.nameAndFolderPageModel.checkVmName(false);
         self.wideTooltip = self.invalidNameMessage.length > 80;
      };

      self.onTargetChange = function(locationId, locationName, nodeTypeId, item) {
         _.assign(self.form.selectedTarget, {
            uid: locationId,
            name: locationName,
            type: nodeTypeId,
            item: item
         });

         setLoadingIndicator(true);
         $scope.config.loadingMessage = i18nService.getString('CommonUi', 'wizard.validating');

         $scope.nameAndFolderPageModel.validateSelection().then(function (compatibilityMessage) {
            self.compatibilityMessage = compatibilityMessage;
            setLoadingIndicator(false);
         });
      };

      var pages = $scope.vuiWizardPages;

      self.changeGosPageState = function() {
         if (!self.form.customizeOs) {
            wizardPageService.markPageSkipped(
                  pages,
                  i18nService.getString('VmUi', 'CustomizeGuestOsProvisioningPage.Title')
            );
            wizardPageService.markPageSkipped(
                  pages,
                  i18nService.getString('VmUi', 'UserSettingsPage.Title')
            );
         } else {
            wizardPageService.markPageDisabled(
                  pages,
                  i18nService.getString('VmUi', 'CustomizeGuestOsProvisioningPage.Title')
            );
         }
      };

      self.isInDeployFromTemplateMode = function () {
         return self.wizardType === creationTypeConstants.DEPLOY_VM_FROM_TEMPLATE;
      };

      function setLoadingIndicator(setting) {
         $scope.config.loading = setting;
      }

      $scope.$on('$destroy', function() {
         setLoadingIndicator(false);
      });
   }

})();
