/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace dvs_ui {

   import DvpgMacQualifierModel = com.vmware.vsphere.client.h5.network.dvportgroup.trafficfilter.model.DvpgMacQualifierModel;
   export class DvpgMacQualifierComponent {

      public bindings: any;
      public controller: any;
      public templateUrl: string;

      constructor() {
         this.bindings = {
            model: "<",
            onValidate: "="
         };

         this.controller = DvpgMacQualifierContoller;

         this.templateUrl = "dvs-ui/resources/dvs/dvpg/trafficfilter/common/" +
               "dvpgMacQualifierTemplate.html";
      }
   }

   export class DvpgMacQualifierContoller {

      static $inject = [
            "i18nService",
            "dvpgTrafficFilterService",
            "dvpgTrafficFilterConstants",
            "dvpgMacQualifierValidator",
            "vxValidatorFactory"
      ];

      public model: DvpgMacQualifierModel;
      public onValidate: any;

      public macProtocolValidator: any;
      public vlanIdValidator: any;

      public sourceMacValidator: any;
      public sourceWildcardValidator: any;
      public destMacValidator : any;
      public destWildcardValidator: any;

      private i18n: any;
      private constants: any;

      private protocolTypes: string[];
      private protocolOperations: string[];
      private vlanOperations: string[];
      private macAddressOperations: string[];

      private protocolValidationFunction: Function;
      private showProtocolError: boolean = false;

      private vlanIdValidationFunction: Function;
      private showVlanError: boolean = false;

      private sourceMacValidationFunction: Function;
      private showSourceMacError: boolean = false;

      private destMacValidationFunction: Function;
      private showDestMacError: boolean = false;

      private sourceWildcardValidationFunction: Function;
      private showSourceWildcardError: boolean = false;

      private destWildcardValidationFunction: Function;
      private showDestWildcardError: boolean = false;

      private showErrors = false;

      constructor(private i18nService: any,
                  private trafficFilterService: any,
                  private trafficFilterConstants: any,
                  private macQualifierValidator: any,
                  private vxValidatorFactory: any) {
         this.i18n = this.i18nService.getString;
         this.constants = this.trafficFilterConstants;
      }

      $onInit(): void {
         this.protocolTypes =
               this.trafficFilterService.getMacQualifierProtocolTypes();
         this.protocolOperations =
               this.trafficFilterService.getQualifiersProtocolOperations();
         this.vlanOperations =
               this.trafficFilterService.getMacQualifierVlanOperations();
         this.macAddressOperations =
               this.trafficFilterService.getQualifiersAddressOperations();

         this.protocolValidationFunction = this.validateProtocol.bind(this);

         this.vlanIdValidationFunction = this.validateVlan.bind(this);

         this.sourceMacValidationFunction = this.validateSourceMac.bind(this);
         this.destMacValidationFunction = this.validateDestMac.bind(this);

         this.sourceWildcardValidationFunction = this.validateSourceWildcard.bind(this);
         this.destWildcardValidationFunction = this.validateDestWildcard.bind(this);

         this.initValidators();

         if (this.onValidate) {
            this.onValidate.validate = this.validate.bind(this);
         }
      }

      public validate(): void {
         this.showErrors = true;

         this.macProtocolValidator.validate();

         this.vlanIdValidator.validate();

         this.sourceMacValidator.validate();
         this.sourceWildcardValidator.validate();
         this.destMacValidator.validate();
         this.destWildcardValidator.validate();

         this.showErrors = false;
      }

      private initValidators(): void {
         this.macProtocolValidator = this.vxValidatorFactory.create();

         this.vlanIdValidator = this.vxValidatorFactory.create();

         this.sourceMacValidator = this.vxValidatorFactory.create();
         this.sourceWildcardValidator = this.vxValidatorFactory.create();
         this.destMacValidator = this.vxValidatorFactory.create();
         this.destWildcardValidator = this.vxValidatorFactory.create();
      }

      public onProtocolChanged(): void {
         if (!this.model.protocol.customValue) {
            this.showProtocolError = false;
         }
         this.macProtocolValidator.validate();
      }

      public onProtocolBlur(): void {
         this.showProtocolError = true;
         this.macProtocolValidator.validate();
      }

      private validateProtocol(): string[] {
         if (this.showErrors || this.showProtocolError) {
            let error: string =
                  this.macQualifierValidator.getProtocolError(this.model.protocol);
            return error !== this.trafficFilterConstants.EMPTY ? [error] : [];
         }
         return [];
      }

      public onVlanChanged(): void {
         if (!this.model.vlanId) {
            this.showVlanError = false;
         }
         this.vlanIdValidator.validate();
      }

      public onVlanBlur(): void {
         this.showVlanError = true;
         this.vlanIdValidator.validate();
      }

      private validateVlan(): string[] {
         if (this.showErrors || this.showVlanError) {
            let error: string = this.macQualifierValidator.getVlanError(
                  this.model.vlanId,
                  this.model.vlanOperation);
            return error !== this.trafficFilterConstants.EMPTY ? [error] : [];
         }
         return [];
      }

      public onSourceMacChanged(): void {
         if (!this.model.sourceMacAddress.macAddress) {
            this.showSourceMacError = false;
         }
         if (!this.model.sourceMacAddress.wildcardMask) {
            this.showSourceWildcardError = false;
         }
         this.sourceMacValidator.validate();
         this.sourceWildcardValidator.validate();
      }

      public onSourceMacBlur(): void {
         this.showSourceMacError = true;
         this.sourceMacValidator.validate();
      }

      private validateSourceMac(): string[] {
         if (this.showErrors || this.showSourceMacError) {
            let error: string = this.macQualifierValidator.getMacAddressError(
                  this.model.sourceMacAddress, true/*isSource*/);
            return error !== this.trafficFilterConstants.EMPTY ? [error] : [];
         }
         return [];
      }

      public onSourceWildcardBlur(): void {
         this.showSourceWildcardError = true;
         this.sourceWildcardValidator.validate();
      }

      private validateSourceWildcard(): string[] {
         if (this.showErrors || this.showSourceWildcardError) {
            let error: string = this.macQualifierValidator.getWildcardMaskError(
                  this.model.sourceMacAddress, true/*isSource*/);
            return error !== this.trafficFilterConstants.EMPTY ? [error] : [];
         }
         return [];
      }

      public onDestMacChanged(): void {
         if (!this.model.destinationMacAddress.macAddress) {
            this.showDestMacError = false;
         }
         if (!this.model.destinationMacAddress.wildcardMask) {
            this.showDestWildcardError = false;
         }
         this.destMacValidator.validate();
         this.destWildcardValidator.validate();
      }

      public onDestMacBlur(): void {
         this.showDestMacError = true;
         this.destMacValidator.validate();
      }

      private validateDestMac(): string[] {
         if (this.showErrors || this.showDestMacError) {
            let error: string = this.macQualifierValidator.getMacAddressError(
                  this.model.destinationMacAddress, false/*isSource*/);
            return error !== this.trafficFilterConstants.EMPTY ? [error] : [];
         }
         return [];
      }

      public onDestWildcardBlur(): void {
         this.showDestWildcardError = true;
         this.destWildcardValidator.validate();
      }

      private validateDestWildcard(): string[] {
         if (this.showErrors || this.showDestWildcardError) {
            let error: string = this.macQualifierValidator.getWildcardMaskError(
                  this.model.destinationMacAddress, false/*isSource*/);
            return error !== this.trafficFilterConstants.EMPTY ? [error] : [];
         }
         return [];
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .component("dvpgMacQualifier", new DvpgMacQualifierComponent());
}

