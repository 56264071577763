/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
(function () {
   'use strict';
   angular
         .module('com.vmware.vsphere.client.dvs')
         .controller('DvPortgroupSecurityPolicyPageController',
               DvPortgroupSecurityPolicyPageController);

   DvPortgroupSecurityPolicyPageController.$inject = [
      '$scope'
   ];

   function DvPortgroupSecurityPolicyPageController($scope) {

      $scope.manager.commitPageCallbacks.commitSecurityPolicyPage = onCommit;

      function onCommit() {
            $scope.manager.pageDirtyFlags.isSecurityPolicyPageDirty =
               hasSecurityPolicyChanged(
                     $scope.initialData,
                     $scope.manager.dvPortgroupData);
         return true;
      }

      function hasSecurityPolicyChanged(initialPolicy, actualPolicy) {
         return initialPolicy.allowPromiscuous !== actualPolicy.allowPromiscuous ||
               initialPolicy.forgedTransmits !== actualPolicy.forgedTransmits ||
               initialPolicy.macChanges !== actualPolicy.macChanges;
      }

      return this;
   }

})();
