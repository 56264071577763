/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace dvs_ui {

   import DvsNetworkResourcePoolsMeterData =
         com.vmware.vsphere.client.h5.network.dvs.resmgmt.nrp.model.DvsNetworkResourcePoolsMeterData;
   import DvsNetworkResourcePoolsOverviewData =
         com.vmware.vsphere.client.h5.network.dvs.resmgmt.nrp.model.DvsNetworkResourcePoolsOverviewData;
   import DvsNetworkResourcePoolsData =
         com.vmware.vsphere.client.h5.network.dvs.resmgmt.nrp.model.DvsNetworkResourcePoolsData;

   export class DvsNrpViewComponent {

      public bindings: any;
      public controller: any;
      public templateUrl: string;

      constructor() {
         this.bindings = {};

         this.controller = DvsNrpViewComponentController;

         this.templateUrl =
               "dvs-ui/resources/dvs/resmgmt/nrp/views/dvsNrpViewComponentTemplate.html";
      }
   }

   class DvsNrpViewComponentController {

      static readonly $inject = [
         "$rootScope",
         "i18nService",
         "dataService"
      ];

      static readonly DATA_PROPERTY: string =
            "dvsresmgmt:networkResourcePoolsData";

      public readonly liveRefreshProperties: string[] =
            ["config.vmVnicNetworkResourcePool"];

      public dvsUrn: string;

      public alertText: string | null;

      public showInfoBanner: boolean;

      public meterData: DvsNetworkResourcePoolsMeterData;

      public overviewData: DvsNetworkResourcePoolsOverviewData;

      constructor(private $rootScope: any,
                  private i18nService: any,
                  private dataService: any) {
         this.dvsUrn = $rootScope._route.objectId;
      }

      public $onInit(): void {
         if (this.dvsUrn) {
            this.refresh();
         }
      }

      public refresh(): void {
         this.dataService.getProperties(
               this.dvsUrn,
               [DvsNrpViewComponentController.DATA_PROPERTY])
               .then((response: any) => {
                  if (response) {
                     let data: DvsNetworkResourcePoolsData =
                           response[DvsNrpViewComponentController.DATA_PROPERTY];

                     if (data) {
                        this.meterData = data.meterData;
                        this.overviewData = data.overviewData;

                        this.setAlertText();
                        this.setShowInfoBanner();
                     }
                  }
               });
      }

      private setAlertText(): void {
         if (!this.meterData) {
            this.alertText = null;
            return;
         }

         let totalReservation: number =
               this.meterData.grantedQuota + this.meterData.vmReservation;

         this.alertText = (totalReservation > this.meterData.configuredReservation)
               ? this.i18nService.getString("DvsUi",
                     "networkResourcePools.error.reservationQuota")
               : null;
      }

      private setShowInfoBanner(): void {
         this.showInfoBanner =
               this.overviewData && this.overviewData.vmTrafficReservation <= 0;
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .component("dvsNrpView", new DvsNrpViewComponent());
}