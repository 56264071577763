/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace dvs_ui {

   export class DvpgVlanPageValidator {

      public static $inject = [
         "$q",
         "i18nService",
         "networkUiConstants",
         "dvpgPropertiesService",
         "dvPortgroupVlanPageService"];

      constructor(private $q: any,
                  private i18nService: any,
                  private networkUiConstants: any,
                  private dvpgPropertiesService: any,
                  private dvPortgroupVlanPageService: any) {
      }

      public getValidationError(dvPortgroupId: string, vlanPageModel: any): any {
         let errors: string[] = [];

         switch (vlanPageModel.vlanType) {
            case this.networkUiConstants.Vlan.Type.VLAN: {
               let vlanIdError: string = this.dvpgPropertiesService.getVlanIdError(
                     vlanPageModel.vlanId);
               if (vlanIdError) {
                  return this.$q.resolve([vlanIdError]);
               }
               break;
            }
            case this.networkUiConstants.Vlan.Type.TRUNK: {
               return this.dvPortgroupVlanPageService.getTrunkVlanRanges(
                     dvPortgroupId, vlanPageModel.vlanTrunkRanges
               ).then((numericRanges: any) => {
                  if (!numericRanges) {
                     let vlanRangesError: string = this.i18nService.getString(
                           'DvsUi', 'dvpg.policy.vlan.trunk.errorMessage');
                     return [vlanRangesError];
                  }
                  vlanPageModel.vlanTrunkNumericRanges = numericRanges;
                  return this.$q.resolve(errors);
               });
            }
            case this.networkUiConstants.Vlan.Type.PRIVATE: {
               if (!vlanPageModel.pvlanConfig ||
                     vlanPageModel.pvlanConfig.length === 0) {
                  let pvlanError: string = this.i18nService.getString(
                        'DvsUi', 'dvpg.policy.vlan.private.noConfig');
                  return this.$q.resolve([pvlanError]);
               }
               break;
            }
         }
         return this.$q.resolve(errors);
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .service("dvpgVlanPageValidator", DvpgVlanPageValidator);
}