(function() {
   'use strict';
   angular.module('com.vmware.vsphere.client.vm').service('virtualDiskSettingsFormService', [
      'i18nService',
      'diskBackingInfoConstants',
      'datastoreService',
      'vmDeviceInfoService',
      'defaultUriSchemeUtil',
      'storageProfileService',
      'diskProvisioningService',
      'diskFormatService',
      'pmemUtilService',
      function(
            i18nService,
            diskBackingInfoConstants,
            datastoreService,
            vmDeviceInfoService,
            defaultUriSchemeUtil,
            storageProfileService,
            diskProvisioningService,
            diskFormatService,
            pmemUtilService
      ) {
         return {
            build: buildVirtualDiskSettingsForm,
            buildCapacity: buildCapacity,
            buildControllersDropdown: buildControllersDropdown,
            buildDiskProvisioningTypes: buildDiskProvisioningTypes,
            getCapacityInMB: getCapacityInMB
         };

         function buildVirtualDiskSettingsForm(
               vmStorageProfileAssignments,
               inVmCreateOrCloneMode,
               vmConfigContext,
               inflatedDevice,
               vmStorageId,
               selectedStorageProfile,
               storageProfiles,
               virtualMachineDevices
         ) {
            var deviceSpec = inflatedDevice.getCurrentDeviceSpec();
            var device = deviceSpec.device;
            var datastoreInfo = buildDatastoreInfo(vmConfigContext,
                  device.backing.datastore, vmStorageId, inflatedDevice.isNew());
            var capacity = buildCapacity(inflatedDevice, inVmCreateOrCloneMode, datastoreInfo);
            var diskProvisioningName = determineDiskProvisioningName(datastoreInfo, device);
            var sharing = buildSharing(device);
            var shares = buildShares(device.storageIOAllocation.shares);
            var ioLimit = determineIOLimit(device.storageIOAllocation);
            var allInflatedDevices = virtualMachineDevices.getAllDevicesNotMarkedForRemoval();
            var isFlashCacheCompatible = vmConfigContext.environment.hostCapability.vFlashSupported;
            var flashCacheReservation = buildFlashCacheReservation(device.vFlashCacheConfigInfo);
            var diskMode = buildDiskMode(vmConfigContext, inflatedDevice, allInflatedDevices);

            var availableInflatedControllers = vmDeviceInfoService.availableControllersForDevice(inflatedDevice, allInflatedDevices);

            var controllersDropdown = buildControllersDropdown(inflatedDevice, allInflatedDevices);
            var inflatedController = _.find(availableInflatedControllers, function(inflatedController) {
               return inflatedController.getKey() === device.controllerKey;
            });

            if (!inflatedController) {
               throw new Error('Could not find controller with key ' + device.controllerKey + ' for device ' + inflatedDevice.getKey());
            }
            var defaultUnit = flashCacheReservation.valueInMB < 1024 ? flashCacheReservation.validUnits.MB.label : flashCacheReservation.validUnits.GB.label;

            var virtualDiskSettingsForm = {
               capacity: {
                  valueInMB: capacity.valueInMB,
                  validUnits: capacity.validUnits,
                  minInMB: capacity.minInMB,
                  maxInMB: capacity.maxInMB,
                  maxExceededErrorMessage: capacity.maxExceededErrorMessage,
                  memoryUnitPreferred: capacity.memoryUnitPreferred,
                  isMaxAvailable: capacity.isMaxAvailable
               },
               diskProvisioningName: diskProvisioningName,
               sharing: {
                  options: sharing.options,
                  selection: sharing.selection
               },
               diskFile: device.backing.fileName,
               shares: shares,
               ioLimit: ioLimit,
               isFlashCacheCompatible: isFlashCacheCompatible,
               flashCacheReservation: {
                  valueInMB: flashCacheReservation.valueInMB,
                  validUnits: flashCacheReservation.validUnits,
                  customValidators: [{
                     name: "valid-range",
                     validate: function(value) {
                        return value === 0 || (value >= 4 && value <= 10240);
                     },
                     message: i18nService.getString("VmUi", "vFlashCache.ValidRange", "Reservation", "4MB", "10GB")
                     + " " + i18nService.getString("VmUi", "vFlashCache.ZeroToDisableCahe")
                  }],
                  errorMessages: {},
                  defaultUnit: defaultUnit
               },
               diskMode: {
                  options: diskMode.options,
                  selection: diskMode.selection
               },
               controllers: {
                  options: controllersDropdown.options,
                  selection: controllersDropdown.selection
               },
               selectedControllerNode: null
            };

            if (inVmCreateOrCloneMode || inflatedDevice.isNew()) {
               virtualDiskSettingsForm.diskProvisioningOptions = buildDiskProvisioningTypes(datastoreInfo?datastoreInfo.datastore.type:"",datastoreInfo?datastoreInfo.vStorageSupport:"");


               if (diskProvisioningService.hasFormat(device.backing)) {
                  virtualDiskSettingsForm.selectedDiskProvisioningOption = diskProvisioningService.getFormat(device.backing);
               } else {
                  virtualDiskSettingsForm.selectedDiskProvisioningOption = virtualDiskSettingsForm.diskProvisioningOptions[0];
               }
            }

            var storageProfile;
            storageProfiles = pmemUtilService.filterOutPmemProfileIfNeeded(
                  storageProfiles, vmConfigContext, inflatedDevice);
            if (inVmCreateOrCloneMode) {
               storageProfile = storageProfileService.buildStorageProfileInCreateMode(selectedStorageProfile, storageProfiles);
            } else if (vmStorageProfileAssignments) {
               // 'vmStorageProfileAssignments' is set to null when the user doesn't have the required privileges.
               storageProfile = storageProfileService.buildStorageProfile(vmStorageProfileAssignments, device, storageProfiles, inflatedDevice.isNew());
            }

            if (storageProfile) {
               virtualDiskSettingsForm.storageProfile = {
                  options: storageProfile.options,
                  selection: storageProfile.selection
               };

               if (deviceSpec && deviceSpec.profile) {
                  virtualDiskSettingsForm.storageProfile.selection = storageProfileService.findProfile(
                        virtualDiskSettingsForm.storageProfile.options,
                        deviceSpec.profile[0]
                  );
               }
            }

            return virtualDiskSettingsForm;
         }

         function buildControllersDropdown(inflatedDevice, allInflatedDevices) {
            var availableInflatedControllers = vmDeviceInfoService.availableControllersForDevice(inflatedDevice, allInflatedDevices);

            var selection = _.find(availableInflatedControllers, function(availableInflatedController) {
               return availableInflatedController.getKey() === inflatedDevice.getCurrentDeviceSpec().device.controllerKey;
            });

            return {
               options: availableInflatedControllers,
               selection: selection
            };
         }

         function buildDiskMode(vmConfigContext, inflatedDevice, allInflatedDevices) {

            var optionsForDiskBacking = vmDeviceInfoService.backingOptionForDevice(vmConfigContext, inflatedDevice, allInflatedDevices);

            if (!optionsForDiskBacking && inflatedDevice.getCurrentDevice().backing
               ._type === diskBackingInfoConstants.LOCALPMEM) {
               return {
                  options: []
               };
            }

            var options = _.map(optionsForDiskBacking.diskMode.choiceInfo, function(modeOption) {
               return {
                  name: i18nService.getString('VmUi', 'DiskConfig.' + modeOption.key),
                  value: modeOption.key
               };
            });

            var selection = _.find(options, function(option) {
               return option.value === inflatedDevice.getCurrentDevice().backing.diskMode;
            });

            return {
               options: options,
               selection: selection
            };
         }

         function buildFlashCacheReservation(deviceFlashCacheConfigInfo) {
            return {
               valueInMB: deviceFlashCacheConfigInfo ? deviceFlashCacheConfigInfo.reservationInMB : 0,
               validUnits: {
                  MB: { label: i18nService.getString('VmUi', "Vm.Units.MB"), multiplier: 1 },
                  GB: { label: i18nService.getString('VmUi', "Vm.Units.GB"), multiplier: Math.pow(2, 10) }
               }
            };
         }

         function determineIOLimit(deviceStorageIOAllocation) {
            var limit = deviceStorageIOAllocation.limit;
            return limit === -1 ? i18nService.getString('VmUi', 'DiskConfig.Unlimited') : limit;
         }

         function buildShares(deviceShares) {
            return {
               min: 200,
               max: 4000,
               presetOptions: {
                  low: 500,
                  normal: 1000,
                  high: 2000
               },
               value: deviceShares
            };

         }

         function buildSharing(device) {
            var options = [
               {
                  name: i18nService.getString('VmUi', "DiskPage.sharingNull"),
                  value: undefined
               }, {
                  name: i18nService.getString('VmUi', "DiskPage.sharingNone"),
                  value: 'sharingNone'
               }, {
                  name: i18nService.getString('VmUi', "DiskPage.sharingMultiWriter"),
                  value: 'sharingMultiWriter'
               }
            ];

            var defaultSharingOption = options[0];
            var selection = _.find(options, function(option) {
                     return option.value === device.backing.sharing;
                  }) || defaultSharingOption;

            return {
               options: options,
               selection: selection
            };
         }

         function determineDiskProvisioningName(datastoreInfo, device) {

            if (datastoreInfo && datastoreInfo.datastore.type === 'vsan') {
               return i18nService.getString('VmUi', 'DiskPage.ProfileProvisioned');
            }

            return diskProvisioningService.getName(device.backing);
         }

         function buildDatastoreInfo(vmConfigContext, backingDatastore, vmStorageId, isNew) {
            var datastoreId = backingDatastore ?
                  defaultUriSchemeUtil.getVsphereObjectId(backingDatastore) : null;

            // if the disk is not new always use the datastore data from it's backing
            // if it is not and it is not set use the provided vmStorageId
            // (the selected storage from basic mode in the storage page)

            if (isNew) {
               datastoreId = datastoreId || vmStorageId;
            }

            return datastoreService.findDatastore(
                  datastoreId,
                  vmConfigContext.environment.configTarget
            );
         }

         function buildCapacity(inflatedDevice, inVmCreateOrCloneMode, datastoreInfo) {
            var device = inflatedDevice.getCurrentDevice();

            var maxDiskSizeInBytes;
            var maxInMB;
            var maxExceededErrorMessage;
            var freeSpaceInMB;
            var maxDiskSizeInMB;

            if (!datastoreInfo /* When disk is on an SDRS cluster*/) {
               //Max size of Java Long
               maxDiskSizeInBytes = Math.pow(2, 63);
               maxInMB = maxDiskSizeInBytes / Math.pow(2, 20);
               maxExceededErrorMessage =
                     i18nService.getString('VmUi', 'DiskConfig.ErrorMaxFileSize');
            }

            if (datastoreInfo) {
               var unitFactor = 1024;
               maxDiskSizeInBytes = datastoreInfo.maxVirtualDiskCapacity;

               if (maxDiskSizeInBytes === null || isNaN(maxDiskSizeInBytes) || maxDiskSizeInBytes <= 0) {

                  maxDiskSizeInBytes = datastoreInfo.maxFileSize;

                  var smallestValidMaxFileSize = 256 * unitFactor * unitFactor * unitFactor;
                  if (maxDiskSizeInBytes >= smallestValidMaxFileSize) {
                     maxDiskSizeInMB = maxDiskSizeInBytes / unitFactor / unitFactor;
                  }
               } else {
                  maxDiskSizeInMB = maxDiskSizeInBytes / unitFactor / unitFactor;
               }

               freeSpaceInMB = datastoreInfo.datastore.freeSpace / unitFactor / unitFactor;
               if (!inVmCreateOrCloneMode && !inflatedDevice.isNew()) {
                  freeSpaceInMB += device.capacityInKB / unitFactor;
               }

               if (maxDiskSizeInMB && maxDiskSizeInMB < freeSpaceInMB) {
                  maxInMB = maxDiskSizeInMB;
                  maxExceededErrorMessage = i18nService.getString('VmUi', "DiskConfig.ErrorMaxFileSize");
               } else {
                  maxInMB = freeSpaceInMB;
                  maxExceededErrorMessage = i18nService.getString('VmUi', "DiskConfig.ErrorFreeSpace");
               }
            }

            var valueInMB = getCapacityInMB(device);
            var validUnits = {
               MB: { label: i18nService.getString('VmUi', "Vm.Units.MB"), multiplier: 1 },
               GB: { label: i18nService.getString('VmUi', "Vm.Units.GB"), multiplier: Math.pow(2, 10) },
               TB: { label: i18nService.getString('VmUi', "Vm.Units.TB"), multiplier: Math.pow(2, 20) }
            };
            var memoryUnitPreferred = valueInMB < 1024 ? validUnits.MB : validUnits.GB;

            var deviceSpec = inflatedDevice.getCurrentDeviceSpec();
            if (deviceSpec
                  && deviceSpec.memoryUnitPreferred
                  && deviceSpec.memoryUnitPreferred.label) {
               memoryUnitPreferred = deviceSpec.memoryUnitPreferred;
            }

            //if this is a Raw device, disk and datastore sizes don't matter
            //so set the max to deviceSize, so that the edit control doesn't complain
            if (device.backing._type === diskBackingInfoConstants.RAWDISKMAPPING) {
               maxInMB = valueInMB;
            }

            return {
               valueInMB: valueInMB,
               validUnits: _.values(validUnits),
               minInMB: inflatedDevice.isNew() ? null : device.capacityInBytes / Math.pow(2, 20),
               maxInMB: maxInMB,
               maxExceededErrorMessage: maxExceededErrorMessage,
               memoryUnitPreferred: memoryUnitPreferred,
               isMaxAvailable: isMaxAvailable(maxInMB)
            };
         }

         function getCapacityInMB(device) {
            if (device.capacityInBytes) {
               return (device.capacityInBytes / Math.pow(1024, 2));
            } else {
               return (device.capacityInKB / Math.pow(1024, 1));
            }
         }

         function isMaxAvailable(maxInMB) {
            return maxInMB < (Math.pow(2, 63) / Math.pow(2, 20));
         }

         function buildDiskProvisioningTypes(dsType, vStorage) {
           var vStorageSupport;
           vStorageSupport =(vStorage ==='vStorageSupported');
           if(_.isEmpty(dsType, true)){
                return diskFormatService.getAvailableDiskFormats( "VMFS", vStorageSupport, false, true);
           }
           return diskFormatService.getAvailableDiskFormats( dsType, vStorageSupport, false, true);

          }
      }
   ]);
})();
