namespace h5_vm {
    export class TpmCertificateSelector implements ng.IComponentOptions {
        bindings: any;
        controller: any;
        templateUrl: string;

        constructor() {
            this.bindings = {
                tpmCertificate: "<",
                onCertificateChange: "&"
            };
            this.controller = TpmCertificateSelectorController;
            this.templateUrl =
                'vm-ui/resources/vm/views/settings/vmHardwareSettings/vmHardwareTpm/tpmCertificateSelector/' +
                    'tpm-certificate-selector.component.html';
        }
    }

    class TpmCertificateSelectorController implements ng.IComponentController {
        static $inject: string[] = [
            'i18nService',
            '$element',
            '$timeout',
            'vmFileReaderService',
            'vmTpmService'
        ];

        public tpmCertificate: any;
        public onCertificateChange: Function;

        private i18n: (key: string) => string;
        private selectedCertificate: any;
        private certificateDropdownOptions: Array<any> = [];
        private errorConfig: { message: string, isVisible: boolean };
        private certificateFileInput: ng.IAugmentedJQuery;
        private invalidCertAlertOptions: { text: string, isClosed: boolean } = {
            text: '',
            isClosed: true
        };
        constructor(private i18nService: any,
                    private $element: angular.IAugmentedJQuery,
                    private $timeout: any,
                    private vmFileReaderService: any,
                    private vmTpmService: any) {
            this.i18n = (key: string, bundle: string = 'VmUi') =>
                i18nService.getString(bundle, key);
            this.errorConfig = {
                message: "",
                isVisible: false
            };
        }

        $onInit() {
            this.selectedCertificate = this.tpmCertificate;
            this.certificateDropdownOptions.push(this.tpmCertificate);
        }

        $postLink() {
            this.certificateFileInput = this.$element.find("input[type='file']");
        }

        openFileSelector() {
            this.$timeout(() => {
                this.certificateFileInput.click();
            }, 0);
        }

        showAlertForInvalidCertificate(errorText: string) {
            this.invalidCertAlertOptions.text = errorText;
            this.invalidCertAlertOptions.isClosed = false;
        }

        uploadCertificate(files: any) {
           if (files && files.length) {
               const certificateFile = files[0];
               this.vmFileReaderService.readFileContentAsBinaryString(certificateFile).then(
                   (base64EncodedCertificate: string) => {
                       this.vmTpmService.parseCertificate(base64EncodedCertificate).then(
                           (certificate: any) => {
                               if (!certificate) {
                                   // Certificate is invalid
                                   this.showAlertForInvalidCertificate(this.i18n('VmDeviceManager.TPMCertificateInvalid'));
                               } else {
                                   this.onCertificateChange({
                                       uploadedCertificate: certificate,
                                       originalCertificate: this.tpmCertificate
                                   });
                               }
                           },
                           (error: any) => {
                               this.showAlertForInvalidCertificate(this.i18n('VmDeviceManager.TPMCertificateInvalid'));
                           }
                       );
                   });

           }
        }
    }

    angular.module('com.vmware.vsphere.client.vm')
        .component('tpmCertificateSelector', new TpmCertificateSelector());
}
