namespace storage_ui {
   angular.module("com.vmware.vsphere.client.storage").constant("storageAdapterConstants", {

      constraints: {
         HW_CHAP_NAME_MAX: 255,
         SW_CHAP_NAME_MAX: 511,
         HW_CHAP_SECRET_MAX: 100,
         SW_CHAP_SECRET_MAX: 255
      },

      authentication: {
         PROHIBITED: "chapProhibited",
         DISCOURAGED: "chapDiscouraged",
         PREFERRED: "chapPreferred",
         REQUIRED: "chapRequired",
         MUTUAL: "MUTUAL"
      },

      // Use unicode character for bullet so that we display characters in password fields.
      EXISTING_PASSWORD_PLACEHOLDER: "\u2022\u2022\u2022\u2022\u2022\u2022\u2022\u2022\u2022\u2022\u2022\u2022"

   });
}

