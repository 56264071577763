(function () {
   'use strict';

   angular
   .module('com.vmware.vsphere.client.vm')
   .controller('VmProvisioningSelectCreationTypeController', [
      '$scope',
      'i18nService',
      'creationTypeConstants',
      'createVmChangeCreationTypeService',
      VmProvisioningSelectCreationTypeController
   ]);

   function VmProvisioningSelectCreationTypeController(
         $scope,
         i18nService,
         creationTypeConstants,
         createVmChangeCreationTypeService) {
      var self = this;
      var localizationMap = {};
      localizationMap[creationTypeConstants.CREATE_FROM_SCRATCH] = 'CreateFromScratch';
      localizationMap[creationTypeConstants.CLONE_VM_TO_VM] = 'CloneVmToVm';
      localizationMap[creationTypeConstants.CLONE_VM_TO_TEMPLATE] = 'CloneVmToTemplate';
      localizationMap[creationTypeConstants.DEPLOY_VM_FROM_ANY_TEMPLATE] = 'DeployFromTemplate';
      localizationMap[creationTypeConstants.DEPLOY_VM_FROM_TEMPLATE] = 'DeployFromTemplate';
      localizationMap[creationTypeConstants.CONVERT_TEMPLATE_TO_VM] = 'ConvertTemplateToVm';
      localizationMap[creationTypeConstants.CLONE_TEMPLATE_TO_TEMPLATE] = 'CloneTemplateToTemplate';

      self.creationTypes = [];
      _.each($scope.config.flows, function(flow) {
         if(isSubFlow(flow)) {
            // skip workflows which are sub-workflows of other workflows different from
            // the main CREATE_FROM_SCRATCH workflow.
            // Such is the case with DEPLOY_VM_FROM_TEMPLATE and CONVERT_TEMPLATE_TO_VM
            // which are sub-workflows of the DEPLOY_VM_FROM_ANY_TEMPLATE flow and get
            // branched later in the `Select temaplte` page of the flow.
            return;
         }
         var localizationKey = flow.id;
         self.creationTypes.push({
            name:        i18nService.getString('VmUi', 'ProvisioningTypes.'+ localizationMap[localizationKey] +'.Title'),
            wizardTitle: i18nService.getString('VmUi', 'ProvisioningTypes.'+ localizationMap[localizationKey] +'.WizardTitle.1'),
            description: i18nService.getString('VmUi', 'ProvisioningTypes.'+ localizationMap[localizationKey] +'.Description'),
            creationType: flow.id
         });
      });

      if ($scope.attributes.creationType) {
         var flowToSelect = _.find($scope.config.flows, function(flow) {
            return flow.id === $scope.attributes.creationType;
         });
         var creationTypeToSelect = isSubFlow(flowToSelect) ?
               flowToSelect.parentWorkflow : flowToSelect.id;
         self.selectedCreationType = _.find(self.creationTypes, function (type) {
            return type.creationType === creationTypeToSelect;
         });
      } else {
         self.selectedCreationType = self.creationTypes[0];
         createVmChangeCreationTypeService.changeCreationType(
               $scope, self.selectedCreationType);
      }

      $scope.$watch('ctrl.selectedCreationType', function(selectedCreationType, oldCreationType) {
         createVmChangeCreationTypeService.changeCreationType(
               $scope, selectedCreationType, oldCreationType);
      });

      /**
       * Retirns true if the flow has a parent workflow other than CREATE_FROM_SCRATCH.
       * Otherwise returns false.
       */
      function isSubFlow(flow) {
         return flow.parentWorkflow
               && flow.parentWorkflow !== creationTypeConstants.CREATE_FROM_SCRATCH;
      }
   }
})();
