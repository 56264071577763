/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace h5_vm {

import VirtualDisk = com.vmware.vim.binding.vim.vm.device.VirtualDisk;
import ManagedObjectReference = com.vmware.vim.binding.vmodl.ManagedObjectReference;
import ReplicationGroupId = com.vmware.vim.binding.vim.vm.replication.ReplicationGroupId;
import GroupInfo = com.vmware.vim.binding.sms.storage.replication.GroupInfo;

// NOTE: This is a partial replica of common_ui.VmComponentStorageConfig  structure
export interface VmComponentStorageConfig {
   key: any;
   storageObj?: {
      storageRef: ManagedObjectReference;
   };
   diskFormat?: any;
   storageProfile?: {
      id: string;
   };
   replicationGroup?: {
      id: ReplicationGroupId;
      groupInfo: GroupInfo | undefined
   };
}



export interface IVmHomeDatastores {
   [vmId: string]: string|null;
}

export interface IVmHosts {
   [vmId: string]: string;
}

export interface IVmDisks {
   [vmId: string]: VirtualDisk[];
}

export class MigrateSelectStoragePageModel {
   /**
    * [input]
    *
    * The view mode of the page when loaded for the first time. If specified, should be
    * 'basicMode' or 'advancedMode'.
    */
   public initialViewMode?: string;

   /**
    * The per disk information the needs to be applied when the page is loaded for
    * the first time.
    */
   public initialVmStorageConfig?: VmStorageConfig;

   /**
    * [input]
    *
    * This is the last used compute resource (HostSystem, ClusterComputeResource).
    */
   public computeResource?: string;

   /**
    * [input]
    *
    * Flag indicating whether the input property has changed.
    */
   public isComputeResourceChanged: boolean = false;

   /**
    * [input]
    *
    * The root resource pool of the Host or Cluster coming in the
    * {@code computeResource} input field. Note that it may point to a pool even if
    * the {@ computeResource} field. In this case the user usually have directly
    * specified a pool as the destination for the VM.
    */
   public pool?: string;

   /**
    * [input]
    *
    * The array of VMs that will be migrated.
    */
   public vms: Array<string> = [];

   /**
    * [input]
    *
    * Is the operation cross vc.
    */
   public isXvcMigration: boolean;

   /**
    * [output]
    *
    * Whether basic or advanced mode of the page is active.
    */
   public isAdvancedStorageMode: boolean = false;

   /**
    * [output]
    *
    * Information per each virtual disk as specified by the user.
    * The VMX files is also included as a special disk with key: '_configFile'.
    */
   public virtualDisksByVm: {[key: string] : Array<VirtualDiskRecord>} = {};

   /**
    * [output]
    *
    * Information that maps each storage object visible in the page to a name.
    *
    * It is supplied as output so that anybody interested in it can have it
    * precomputed instead of requesting it again.
    */
   public storageNameMap: {[urn: string]: string};

   /**
    * [page specific]
    *
    * Keeps the storage selector state between different visualization of the page
    * during the wizard lifetime.
    */
   public storageSelectorState?: any /* common_ui.StorageSelectorState */;

   /**
    * [page specific]
    *
    * Keeps the last request storage selector data. Used between different visualization
    * of the page during the wizard lifetime.
    */
   public storageSelectorData?: any /* common_ui.StorageSelectorData */;

   /**
    * [page specific]
    *
    * Keeps info about the availability of the vm encryption storage profile. Currently
    * this is based on the availability of the KMS server for the destination vCenter.
    */
   public isEncryptionOptionsDisabled?: boolean;

   /**
    * [page specific]
    *
    * used to cache compatibility messages of the basic view
    */
   public lastCompatibilityMessagesBasicMode: any[];
   public lastSelectedStorageBasicMode: any;

   /**
    * [page specific]
    *
    * used to cache compatibility messages of the advanced view
    */
   public lastCompatibilityMessagesAdvancedMode: any[];
   public lastSelectedStorageAdvancedMode: any;

   public isValidating: boolean = false;

   /**
    * [page specific]
    *
    * used to cache some remote requests that don't change much during the wizard
    * lifetime.
    */
   vmHomeDatastores: IVmHomeDatastores;
   vmDisks: IVmDisks;
   vmHosts: IVmHosts;
} // class
} // module
