(function() {
   angular.module('com.vmware.vsphere.client.cluster').directive(
      'haHostIsolationResponse', haHostIsolationResponse);

   haHostIsolationResponse.$inject = ['i18nService', 'haHostIsolationResponseService',
         'haConstants'];

   function haHostIsolationResponse(i18nService, haHostIsolationResponseService,
         haConstants) {
      return {
         restrict: 'E',
         templateUrl: 'cluster-ui/resources/cluster/views/settings/services/ha/haHostIsolationResponse.html',
         scope: {
            haConfiguration: '='
         },
         controller: ['$scope', function($scope) {
            $scope.isViewDisabled = function () {
               return !$scope.haConfiguration.hostMonitoringEnabled ||
                     $scope.haConfiguration.defaultVmSettings.restartPriority ===
                           haConstants.restartPriority.DISABLED;
            };
            $scope.responseForHostIsolationLabel = i18nService.getString('ClusterUi',
               'ha.config.vm.hostIsolationResponse.label');
            $scope.hostIsolationResponseLabel = i18nService.getString('ClusterUi',
               'ha.config.failures.responses.host.isolation.label');
            $scope.hostIsolationResponseDescLabel = i18nService.getString('ClusterUi',
               'ha.config.failures.responses.host.isolation.desc');
            $scope.hostIsolationResponses = haHostIsolationResponseService.getResponses();
         }]
      };
   }
})();
