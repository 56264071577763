namespace storage_ui {

   import IComponentController = angular.IComponentController;

   class VmfsVersionPageController implements IComponentController {
      public options: any[];
      public selectedOption: any;
      public onSelectionChanged: Function;

      $onInit(): void {
         this.selectedOption =
               this.selectedOption || this.options[0].value;
         this.onSelectionChanged(this.selectedOption);
      }
   }


   angular.module("com.vmware.vsphere.client.storage")
         .component("vmfsVersionWizardPage", {
            templateUrl: "storage-ui/resources/storage/views/wizard/createDatastore/pages/vmfs/VmfsVersionPageComponent.html",
            bindings: {
               options: "<",
               selectedOption: "<",
               onSelectionChanged: "<"
            },
            controller: VmfsVersionPageController
         });
}
