(function() {
   'use strict';
   angular
      .module('com.vmware.vsphere.client.vm')
      .controller('VmQuestionAnswerDialogController', VmQuestionAnswerDialogController);

   VmQuestionAnswerDialogController.$inject = ['$rootScope', '$scope', '$element', '$timeout',
      'mutationService', 'dataService', 'i18nService', 'defaultUriSchemeUtil'];

   function VmQuestionAnswerDialogController($rootScope, $scope, $element, $timeout,
         mutationService, dataService, i18nService, defaultUriSchemeUtil) {
      var PROPERTY_QUESTION = 'VirtualMachine/localizedQuestion';
      var PROPERTY_OTHER_VMS_COUNT_WITH_SAME_QUESTION = 'otherVmsCountWithSameQuestion';
      var PROPERTY_VC_NAME = 'vCenterName';

      var self = this;
      self.vmId = $scope.modalOptions.dialogData.vmId;
      self.vmQuestionIssue = $scope.modalOptions.dialogData.vmQuestionIssue;
      self.selectedChoice = self.vmQuestionIssue ?
            self.vmQuestionIssue.details.choice.defaultIndex.toString() : null;
      self.showOtherVmsSelector = false;

      // in case of multi vm question this contains the refs of the other selected vms
      self.otherVmsRef = [];

      $scope.modalOptions.onSubmit = function() {
         if (self.loading) {
            return false;
         }

         var otherVmRefs = _.map(self.otherVmsRef, function(vmId) {
            return defaultUriSchemeUtil.getManagedObjectReference(vmId);
         });

         var firstMsgId;
         if (self.vmQuestionIssue.details.message &&
               self.vmQuestionIssue.details.message.length > 0) {
            firstMsgId = self.vmQuestionIssue.details.message[0].id;
         }
         var multipleVmQuestionAnswerSpec = {
            questionId: self.vmQuestionIssue.details.id,
            firstMessageId: firstMsgId,
            answerChoice: self.selectedChoice,
            otherVmRefs: otherVmRefs
         };

         mutationService.apply(
               self.vmId,
               'com.vmware.vsphere.client.h5.vm.MultipleVmQuestionAnswerSpec',
               multipleVmQuestionAnswerSpec)
            .then(function() {
               $rootScope.$broadcast('IssueChangeEvent');
            });

         return true;
      };

      requestData();

      function requestData() {
         self.loading = true;
         $scope.modalOptions.submitDisabled = true;

         var propsToRequest = [PROPERTY_OTHER_VMS_COUNT_WITH_SAME_QUESTION,
            PROPERTY_VC_NAME];

         if (!self.vmQuestionIssue) {
            propsToRequest.push(PROPERTY_QUESTION);
         }

         dataService.getProperties(self.vmId, propsToRequest).then(function (results) {
            if (results[PROPERTY_QUESTION]) {
               var questionInfo = results[PROPERTY_QUESTION];
               self.vmQuestionIssue = {
                  details: questionInfo
               };
               self.selectedChoice = self.vmQuestionIssue ?
                     self.vmQuestionIssue.details.choice.defaultIndex.toString() : "0";
            }

            self.otherVmsCount = results[PROPERTY_OTHER_VMS_COUNT_WITH_SAME_QUESTION];

            if (self.otherVmsCount > 0) {
               var vcName = results[PROPERTY_VC_NAME];
               self.answerOtherMachinesLabel = i18nService.getString(
                     'VmUi', 'VmQuestionAnswerView.answerOtherMachines',
                     self.otherVmsCount, vcName);
               self.selectOtherVmsLinkText = getShowVmsLabel();
            } else {
               self.answerOtherMachinesLabel = i18nService.getString(
                     'VmUi', 'VmQuestionAnswerView.noOtherMachines');
            }

            self.loading = false;
            $scope.modalOptions.submitDisabled = false;
            focusFirstInput();
         });
      }

      self.toggleSelectVmsView = function() {
         self.showOtherVmsSelector = !self.showOtherVmsSelector;
         self.selectOtherVmsLinkText = getShowVmsLabel();
         if (!self.showOtherVmsSelector) {
            // otherwise digest in progress is thrown
            $timeout(function() {
               var grid = $element.find('[list-view-id="vsphere.core.vm.list"] [kendo-grid]');
               grid.data('kendoGrid').clearSelection();
            }, 0);

         } else {
            $timeout(function() {
               var grid = $element.find('[list-view-id="vsphere.core.vm.list"] [kendo-grid]');
               if (grid) {
                  var kendoGrid = grid.data('kendoGrid');
                  if (kendoGrid) {
                     kendoGrid.resize();
                  }
               }
            }, 0);
         }
      };

      var otherVmsWithSameQuestionConfig = {
         label: i18nService.getString('Common', 'typeResource.vmPlural'),
         listViewId: 'vsphere.core.vm.list',
         dataModels: ["VirtualMachine"],
         filterId: 'otherVmsWithSameQuesitonFilter',
         preselectComparator: function(vmItem) {
            return false;
         }
      };

      self.objSelectorConfig = {
         contextObject: self.vmId,
         multipleSelect: true,
         listTabConfig: {
            selectedTabIndex: 0,
            listConfig: [otherVmsWithSameQuestionConfig]
         },
         onSelectionChanged: onVmSelectionChange
      };

      function getShowVmsLabel() {
         return self.showOtherVmsSelector ?
               i18nService.getString('VmUi', 'VmQuestionAnswerView.cancelVmsSelection') :
               i18nService.getString('VmUi', 'VmQuestionAnswerView.selectVms');
      }

      function onVmSelectionChange(vmsData) {
         self.otherVmsRef = null;
         if (!vmsData) {
            return;
         }

         self.otherVmsRef = [];
         for (var i = 0; i < vmsData.length; i++) {
            self.otherVmsRef.push(vmsData[i].id);
         }
      }

      function focusFirstInput() {
         $timeout(function() {
            var firstInput = $element[0].querySelector('input[type="radio"]');
            if (firstInput) {
               firstInput.focus();
            }
         }, 0);
      }
   }
})();
