/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace dvs_ui {

   export class DvsEditNetFlowDialogValidator {

      public static $inject = [
            "i18nService",
            "ipParserService",
            "networkUtil"
      ];
      private i18n: Function;

      constructor(private i18nService: any,
                  private ipParserService: any,
                  private networkUtil: any) {
         this.i18n = i18nService.getString;
      }

      public getAllErrors(pageModel: DvsEditNetFlowDialogModel): string[] {
         let messages: string[] = [];

         if (pageModel.collectorIpAddress) {
            let message: string | null = this.getCollectorIpValidationMessage(
                  pageModel.collectorIpAddress,
                  pageModel.ipv6ForIpfixSupported);
            if (message) {
               messages.push(message);
            }
         }

         if (pageModel.switchIpAddress) {
            let message: string | null = this.getSwitchIpValidationMessage(
                  pageModel.switchIpAddress);
            if (message) {
               messages.push(message);
            }
         }

         let collectorsPortMessage: string | null =
               this.getCollectorPortValidationMessage(pageModel.collectorPort);

         if (collectorsPortMessage) {
            messages.push(collectorsPortMessage);
         }

         if (pageModel.ipfixV10Supported) {
            let observationDomainIdMessage: string | null =
                  this.getObservationDomainIdValidationMessage(pageModel.observationDomainId);

            if (observationDomainIdMessage) {
               messages.push(observationDomainIdMessage);
            }
         }

         let activeFlowTimeoutMessage: string | null =
               this.getActiveFlowTimeoutValidationMessage(pageModel.activeFlowTimeout);

         if (activeFlowTimeoutMessage) {
            messages.push(activeFlowTimeoutMessage);
         }

         let idleFlowTimeoutMessage: string | null =
               this.getIdleFlowTimeoutValidationMessage(pageModel.idleFlowTimeout);

         if (idleFlowTimeoutMessage) {
            messages.push(idleFlowTimeoutMessage);
         }

         let samplingRateMessage: string | null =
               this.getSamplingRateValidationMessage(pageModel.samplingRate);

         if (samplingRateMessage) {
            messages.push(samplingRateMessage);
         }

         return messages;
      }

      public getCollectorIpValidationMessage(collectorIpAddress: string,
                                             isIpv6Enabled: boolean): string | null {
         if (isIpv6Enabled) {
            if (!this.ipParserService.isIpv4AddressValid(collectorIpAddress)
                  && !this.ipParserService.isIpv6AddressValid(collectorIpAddress)) {
               return this.i18n("NetworkUi", "NetFlowPage.error.invalidCollectorAddress.allIp");
            }
         } else {
            if (!this.ipParserService.isIpv4AddressValid(collectorIpAddress)) {
               return this.i18n("NetworkUi", "NetFlowPage.error.invalidCollectorAddress.ipv4");
            }
         }
         return null;
      }

      public getSwitchIpValidationMessage(switchIp: string): string | null {
         if (!this.ipParserService.isIpv4AddressValid(switchIp)) {
            return this.i18n("NetworkUi", "NetFlowPage.error.invalidIpAddress");
         }
         return null;
      }

      public getCollectorPortValidationMessage(collectorPort: number): string | null {
         let min: number = DvsEditNetFlowDialogConstants.COLLECTOR_PORT_MIN_VAL;
         let max: number = DvsEditNetFlowDialogConstants.COLLECTOR_PORT_MAX_VAL;
         let errorMessage: string =
               this.i18n("NetworkUi", "NetFlowPage.error.collectorPort", min, max);
         return this.getNumericInputValidationMessage(collectorPort, min, max, errorMessage);
      }

      public getObservationDomainIdValidationMessage(domainId: number): string | null {
         let min: number = DvsEditNetFlowDialogConstants.OBSERVATION_DOMAIN_ID_MIN_VAL;
         let max: number = DvsEditNetFlowDialogConstants.OBSERVATION_DOMAIN_ID_MAX_VAL;
         let errorMessage: string =
               this.i18n("NetworkUi", "NetFlowPage.error.observationDomainId", min, max);
         return this.getNumericInputValidationMessage(domainId, min, max, errorMessage);
      }

      public getActiveFlowTimeoutValidationMessage(activeFlowTimeout: number): string | null {
         let min: number = DvsEditNetFlowDialogConstants.ACTIVE_FLOW_MIN_VAL;
         let max: number = DvsEditNetFlowDialogConstants.ACTIVE_FLOW_MAX_VAL;
         let errorMessage: string =
               this.i18n("DvsUi", "NetFlowPage.error.activeFlowTimeout", min, max);
         return this.getNumericInputValidationMessage(activeFlowTimeout, min, max, errorMessage);
      }

      public getIdleFlowTimeoutValidationMessage(idleFlowTimeout: number): string | null {
         let min: number = DvsEditNetFlowDialogConstants.IDLE_FLOW_MIN_VAL;
         let max: number = DvsEditNetFlowDialogConstants.IDLE_FLOW_MAX_VAL;
         let errorMessage: string =
               this.i18n("DvsUi", "NetFlowPage.error.idleFlowTimeout", min, max);
         return this.getNumericInputValidationMessage(idleFlowTimeout, min, max, errorMessage);
      }

      public getSamplingRateValidationMessage(samplingRate: number): string | null {
         let min: number = DvsEditNetFlowDialogConstants.SAMPLING_RATE_MIN_VAL;
         let max: number = DvsEditNetFlowDialogConstants.SAMPLING_RATE_MAX_VAL;
         let errorMessage: string =
               this.i18n("DvsUi", "NetFlowPage.error.samplingRate", min, max);
         return this.getNumericInputValidationMessage(samplingRate, min, max, errorMessage);
      }

      private getNumericInputValidationMessage(value: number,
                                              minValue: number,
                                              maxValue: number,
                                              errorMessage: string): string | null {
         if (!this.networkUtil.isPositiveInteger(Number(value), minValue, maxValue)) {
            return errorMessage;
         }
         return null;
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .service("dvsEditNetFlowDialogValidator", DvsEditNetFlowDialogValidator);
}