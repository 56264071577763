module hostprofile_ui {

    import IRootScopeService = angular.IRootScopeService;

    export interface VuiRemediateHostWizard {
        show(pages: Array<any>, wizardScope: any,  manager: RemediateHostManager, hostName: string): void;
    }

    class VuiRemediateHostWizardImpl implements VuiRemediateHostWizard {

        static $inject = [
            "wizardPageService",
            "vuiWizardService",
            "$rootScope",
            "i18nService",
            "mutationService"
        ];

        private wizardConfig: any;
        private wizardScope: any;

        constructor(private wizardPageService: any,
                    private vuiWizardService: any,
                    private $rootScope: any,
                    private i18nService: any,
                    private mutationService: any) {
        }

        public show(pages: Array<any>, wizardScope: any, manager: RemediateHostManager, hostName: string): void {
            this.wizardConfig = {
                title: this.i18nService.getString("HostProfileUi", "remediateHostWizard.title", hostName),
                pages: this.wizardPageService.initializePageStates(pages),
                resizeMinHeight: 500,
                onFinish: (): boolean => {
                    return true;
                }
            };

            this.wizardScope = wizardScope;
            this.wizardScope.manager = manager;
            this.wizardScope.wizardConfig = this.wizardConfig;

            this.vuiWizardService({
                scope: this.wizardScope,
                configObjectName: "wizardConfig"
            }).show();

        }
    }

    angular.module("com.vmware.vsphere.client.hostprofile")
        .service("vuiRemediateHostWizard", VuiRemediateHostWizardImpl);
}
