/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
(function() {
   'use strict';
   angular.module('com.vmware.vsphere.client.storage').controller(
      'StorageDevicesPartitionDetailsViewController', StorageDevicesPartitionDetailsViewController);

   StorageDevicesPartitionDetailsViewController.$inject = [
      '$scope',
      'hostStorageService',
      'i18nService',
      'navigation'
   ];

   function StorageDevicesPartitionDetailsViewController ($scope, hostStorageService, 
         i18nService, navigation) {

      var self = this;
      self.loading = false;
      self.cachedItems = {};
      self.partitions = [];
      self.hostId = navigation.getRoute().objectId;
      // View id for persisting column definitions state.
      self.viewId =  navigation.getRoute().extensionId+ '_paths';
      self.i18n = function(key) {
         return i18nService.getString('StorageUi', key);
      };

      $scope.$watch(
            function() {
               return $scope.masterDetailsViewContext.selectedItem;
            },
            function(newValue, oldValue) {
               if (newValue !== oldValue && newValue) {
                  self.refresh();
               }
            }
      );

      function setModel (obj) {
         self.partitionFormat = i18nService.getString('StorageUi', 'partitionFormat.' + (obj.partitionFormat || 'unknown'));
         self.partitions = obj.partitions;
      }

      self.refresh = function partitionDetailRefresh() {
         if (!$scope.masterDetailsViewContext.selectedItem) {
            return;
         }
         var devicePath = $scope.masterDetailsViewContext.selectedItem.devicePath;
         if (self.cachedItems.hasOwnProperty(devicePath)) {
            setModel(self.cachedItems[devicePath]);
         } else {
            self.loading = true;
         }
         hostStorageService.retrieveStorageDevicePartitionData(self.hostId, devicePath)
               .then(function(result) {
                  self.cachedItems[devicePath] = result;
                  if ($scope.masterDetailsViewContext.selectedItem
                     && $scope.masterDetailsViewContext.selectedItem.devicePath === devicePath) {
                     setModel(result);
                  }
               })
               .finally(function() {
                  self.loading = false;
               });
      };

      self.refresh();
   }
})();
