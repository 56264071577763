(function () {
   /**
    * A node of the messages inside the compatibility box.
    *
    */
   'use strict';
   angular.module('com.vmware.vsphere.client.commonModule').directive('vxCompatibilityMessageNode', [
      '$compile',
      function vxCompatibilityMessageNode($compile) {
         return {
            restrict: 'A',
            templateUrl: 'common-module-ui/resources/common-module/views/compatibility/vxCompatibilityMessageNode.html',
            scope: {
               content: '='
            },
            link: function ($scope, element) {
               if (!_.isEmpty($scope.content.contents)) {
                  var innerElement = $compile('<li vx-compatibility-message-node ' +
                        'content="childContent" ng-repeat="childContent in content.contents"' +
                        'class="horizontal-flex-container"></li>')($scope);
                  element.find('ul').append(innerElement);
               }
            }
         };
      }]);
})();
