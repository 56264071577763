/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */

import DvsSelectPhysicalAdaptersPageService = dvs_ui.DvsSelectPhysicalAdaptersPageService;

namespace dvs_ui {

   import ObservableArray = kendo.data.ObservableArray;
   import DvsAddHostHierarchicalListPnicItem = com.vmware.vsphere.client.h5.network.dvs.addhost.hierarchicalList.DvsAddHostHierarchicalListPnicItem;
   import DvsAddHostHierarchicalListItem = com.vmware.vsphere.client.h5.network.dvs.addhost.hierarchicalList.DvsAddHostHierarchicalListItem;
   import DvsAddHostTreeListItemWrapper = dvs_ui.DvsAddHostTreeListItemWrapper;

   export class DvsSelectPhysicalAdaptersPageService {

      static $inject = [
         "i18nService",
         "vuiConstants",
         "columnRenderersRegistry"
      ];

      public static readonly actionId: any = {
         ASSIGN_UPLINK: "assign-uplink-action",
         UNASSIGN_UPLINK: "unassign-uplink-action",
         VIEW_SETTINGS: "view-settings-action"
      };

      constructor(private i18nService: any,
                  private vuiConstants: any,
                  private columnRenderersRegistry: any) {
      }

      /**
       * Returns the actionBarOptions object for the select physical adapters page.
       *
       * @param assignUplinkHandler
       *    The handler for the assign uplink button.
       *
       * @param resetChangesHandler
       *    The handler for the reset changes button.
       * @param viewSettingsHandler
       * @param unassignAdapterHandler
       */
      public getActionBarOptions(assignUplinkHandler: () => void,
                                 viewSettingsHandler: () => void,
                                 unassignAdapterHandler?: () => void): any {
         let result: any = {actions: []};

         result.actions.push({
            id: DvsSelectPhysicalAdaptersPageService.actionId.ASSIGN_UPLINK,
            label: this.i18nService.getString(
                  "DvsUi", "SelectPhysicalAdaptersPage.assignUplinkButtonTitle"),
            tooltipText: this.i18nService.getString(
                  "DvsUi", "SelectPhysicalAdaptersPage.assignUplinkButtonTooltip"),
            // Disabled initially, only enabled when selection occurs.
            enabled: false,
            iconClass: "network-lib-ui-icon-dvsUplinks",
            onClick: assignUplinkHandler
         });

         if (unassignAdapterHandler) {
            result.actions.push({
               id: DvsSelectPhysicalAdaptersPageService.actionId.UNASSIGN_UPLINK,
               label: this.i18nService.getString(
                     "DvsUi", "SelectPhysicalAdaptersPage.unAssignUplinkButtonLabel"),
               tooltipText: this.i18nService.getString(
                     "DvsUi", "SelectPhysicalAdaptersPage.unAssignUplinkButtonTooltip"),
               // Disabled initially, only enabled when proper selection occurs.
               enabled: false,
               iconClass: "network-lib-ui-icon-deleteIcon",
               onClick: unassignAdapterHandler
            });
         }

         result.actions.push({
            id: DvsSelectPhysicalAdaptersPageService.actionId.VIEW_SETTINGS,
            label: this.i18nService.getString(
                  "DvsUi", "SelectPhysicalAdaptersPage.showSelectedPnicDetailsButton"),
            tooltipText: this.i18nService.getString(
                  "DvsUi", "SelectPhysicalAdaptersPage.showSelectedPnicDetailsToolTipButton"),
            // Disabled initially, only enabled when selection occurs.
            enabled: false,
            iconClass: "network-lib-ui-icon-info",
            onClick: viewSettingsHandler
         });

         return result;
      }

      /**
       * Reeturns the column definitions for the select physical adapters page.
       */
      public getColumnDefs(): any[] {
         let iconTextRenderer: any =
               this.columnRenderersRegistry.getColumnRenderer("icon-text");
         let textRenderer: any =
               this.columnRenderersRegistry.getColumnRenderer("text");

         let itemToDisplayItem = this.itemToDisplayItem.bind(this);
         return [{
            field: "label",
            title: this.i18nService.getString(
                  "DvsUi", "SelectPhysicalAdaptersPage.hostPhysicalNic"),
            // The width is calculated so that it can accommodate a host name
            // that is an IPv6 address, e.g. "2001:0db8:0a0b:12f0:0000:0000:0000:0001".
            width: "260px",
            template: (item: DvsAddHostHierarchicalListPnicItem) => {
               return iconTextRenderer(["icon", "label"], {
                  icon: item.iconClass,
                  label: item.label
               });
            }
         }, {
            field: "inUseBySwitch",
            title: this.i18nService.getString(
                  "DvsUi", "SelectPhysicalAdaptersPage.inUseBySwitch"),
            width: "150px",
            template: (function (item: DvsAddHostHierarchicalListPnicItem) {
               return textRenderer(["inUseBySwitch"], item, true /*unescapeCharacters*/);
            })
         }, {
            field: "uplink",
            title: this.i18nService.getString(
                  "DvsUi", "SelectPhysicalAdaptersPage.uplinkColumnTitle"),
            // The width is calculated so that it can accommodate the (Auto-assign)
            // uplink name in English. In German locale, it is trimmed, but this is
            // still the most optimal distribution of the available real estate
            // and the columns are resizable.
            width: "102px",
            template: (function (item: DvsAddHostHierarchicalListPnicItem) {
               return textRenderer(["uplink"],
                     itemToDisplayItem(item), true /*unescapeCharacters*/);
            })
         }, {
            field: "uplinkPortgroup",
            title: this.i18nService.getString(
                  "DvsUi", "SelectPhysicalAdaptersPage.dvUplinkPortgroup"),
            template: (function (item: DvsAddHostHierarchicalListPnicItem) {
               return textRenderer(["uplinkPortgroup"], itemToDisplayItem(item),
                     true /*unescapeCharacters*/);
            })
         }];
      }

      public itemToDisplayItem(item: DvsAddHostHierarchicalListItem): any {
         let displayItem: any = {};
         if (item.type === DvsAddHostWizardConstants.nicListItemType.PNIC_TYPE) {
            displayItem.uplink =
                  (item as DvsAddHostHierarchicalListPnicItem).uplink;
            displayItem.uplinkPortgroup =
                  (item as DvsAddHostHierarchicalListPnicItem).uplinkPortgroup.name;
         }
         if (displayItem.uplink === DvsAddHostWizardConstants.AUTO_ASSIGN) {
            displayItem.uplink =
                  this.i18nService.getString(
                        "DvsUi", "SelectPhysicalAdaptersPage.autoAssign");
         }
         return displayItem;
      }

      /**
       * Returns the schema for the hierarchical grid data model in the
       * select physical adapters page.
       */
      public getTreeListSchema(): any {
         return {
            model: {
               id: "id",
               expanded: true
            }
         };
      };

      /**
       * Returns true if the provided physical adapter is on the context DVS (no matter
       * whether it was assigned throughout the wizard session lifetime or before that).
       *
       * @param pnicItem
       *    The item to check.
       */
      public isPnicOnThisSwitch(pnicItem: DvsAddHostHierarchicalListPnicItem): boolean {
         return pnicItem.parentId === pnicItem.onThisSwitchItemId;
      }

      /**
       * Returns true if the provided physical adapter is originally on the context DVS.
       *
       * @param pnicItemWrapper
       *    The item to check.
       *
       * @returns {boolean}
       */
      public isPnicOriginallyOnThisSwitch(pnicItemWrapper: DvsAddHostTreeListItemWrapper): boolean {
         let originalItem: DvsAddHostHierarchicalListPnicItem =
               pnicItemWrapper.originalItem as DvsAddHostHierarchicalListPnicItem;
         return originalItem.parentId === originalItem.onThisSwitchItemId;
      }

      /**
       * Returns true if the provided physical adapter is assigned to the dvs within
       * the wizard session lifetime.
       *
       * @param pnicItemWrapper
       *    The item to check.
       */
      public isPnicAssigned(pnicItemWrapper: DvsAddHostTreeListItemWrapper): boolean {
         let originalItem: DvsAddHostHierarchicalListItem =
               pnicItemWrapper.originalItem as DvsAddHostHierarchicalListItem;
         let pnicItem: DvsAddHostHierarchicalListItem =
               pnicItemWrapper as DvsAddHostHierarchicalListItem;
         let updatedItem: DvsAddHostHierarchicalListPnicItem =
               pnicItem as DvsAddHostHierarchicalListPnicItem;
         return this.isPnicOnThisSwitch(updatedItem) &&
               originalItem.parentId !== updatedItem.parentId;
      }

      /**
       * Returns true if the provided physical adapter is modified throughout the
       * lifetime of the wizard session.
       *
       * @param pnicItemWrapper
       *    The item to check.
       *
       * @returns {boolean}
       */
      public isPnicModified(pnicItemWrapper: DvsAddHostTreeListItemWrapper): boolean {
         let originalPnicItem: DvsAddHostHierarchicalListPnicItem =
               pnicItemWrapper.originalItem as DvsAddHostHierarchicalListPnicItem;
         let updatedItem: DvsAddHostHierarchicalListItem =
               pnicItemWrapper as DvsAddHostHierarchicalListItem;
         let updatedPnicItem: DvsAddHostHierarchicalListPnicItem =
               updatedItem as DvsAddHostHierarchicalListPnicItem;
         return originalPnicItem.parentId !== updatedPnicItem.parentId ||
               originalPnicItem.uplink !== updatedPnicItem.uplink ||
               originalPnicItem.uplinkPortgroup.key !==
               updatedPnicItem.uplinkPortgroup.key;
      }

      /**
       * Returns true if the provided physical adapter is unassigned from the DVS within
       * the wizard session lifetime.
       *
       * @param pnicItemWrapper
       *    The item to check.
       *
       * @returns {boolean}
       */
      public isPnicUnassigned(pnicItemWrapper: DvsAddHostTreeListItemWrapper): boolean {
         let originalItem: DvsAddHostHierarchicalListItem =
               pnicItemWrapper.originalItem as DvsAddHostHierarchicalListItem;
         let originalPnicItem: DvsAddHostHierarchicalListPnicItem =
               originalItem as DvsAddHostHierarchicalListPnicItem;
         let updatedItem: DvsAddHostHierarchicalListItem =
               pnicItemWrapper as DvsAddHostHierarchicalListItem;
         let updatedPnicItem: DvsAddHostHierarchicalListPnicItem =
               updatedItem as DvsAddHostHierarchicalListPnicItem;

         return originalPnicItem.parentId === originalPnicItem.onThisSwitchItemId &&
               updatedPnicItem.parentId === originalPnicItem.onOtherSwitchesItemId;
      }

      /**
       * Returns the number of updated physical adapters in the wizard.
       *
       * @param treeListItems
       *    The array of all items on the Select physical adapters page.
       */
      public getNumberOfUpdatedPhysicalAdapters(treeListItems: DvsAddHostTreeListItemWrapper[]): number {

         let assignedAdapters: DvsAddHostTreeListItemWrapper[] =
               _.filter(treeListItems, (treeListItem: DvsAddHostTreeListItemWrapper) => {
                  return treeListItem.type ===
                        DvsAddHostWizardConstants.nicListItemType.PNIC_TYPE &&
                        this.isPnicModified(treeListItem);
               });
         return assignedAdapters.length;
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .service("dvsSelectPhysicalAdaptersPageService", DvsSelectPhysicalAdaptersPageService);
}
