namespace h5_vm {

   import RelocateSpec$DiskLocator = com.vmware.vim.binding.vim.vm.RelocateSpec$DiskLocator;
   import ManagedObjectReference = com.vmware.vim.binding.vmodl.ManagedObjectReference;
   import VmAdvancedStoragePageData = com.vmware.vsphere.client.h5.vm.model.VmAdvancedStoragePageData;
   import IPromise = angular.IPromise;

   export class VmProvisioningDatastorePageModelFactory {
   public static $inject = [
      "defaultUriSchemeUtil",
      "dataService",
      "i18nService",
      "$q",
      "creationTypeConstants",
      "storageResourceSelectionValidationService",
      "storageLocatorService",
      "storageProfileService",
      "managedEntityConstants",
      "contentLibraryService",
      "wizardPageService",
      "VirtualMachineDevices",
      "diskFormatService",
      "vmProvisioningStorageSelectionValidationService",
      "diskProvisioningService",
      "spbmReplicationGroupInfoService",
      "pmemUtilService",
      "virtualDiskPmemService"
   ];

   constructor(defaultUriSchemeUtil: any,
               dataService: any,
               i18nService: any,
               $q: any,
               creationTypeConstants: any,
               storageResourceSelectionValidationService: any,
               storageLocatorService: any,
               storageProfileService: any,
               managedEntityConstants: any,
               contentLibraryService: any,
               wizardPageService: any,
               VirtualMachineDevices: any,
               diskFormatService: any,
               storageValidationService: any,
               diskProvisioningService: any,
               spbmReplicationGroupInfoService: any,
               pmemUtilService: any,
               virtualDiskPmemService: VirtualDiskPmemService
   ) {
      return (
            virtualMachineSpecBuilder: any,
            wizardViewData: any,
            preselectedStorage: any,
            wizardConfig: any) => {
         return new VmProvisioningDatastorePageModel(
            defaultUriSchemeUtil,
            dataService,
            i18nService,
            $q,
            creationTypeConstants,
            storageResourceSelectionValidationService,
            storageLocatorService,
            storageProfileService,
            managedEntityConstants,
            contentLibraryService,
            wizardPageService,
            VirtualMachineDevices,
            diskFormatService,
            storageValidationService,
            diskProvisioningService,
            spbmReplicationGroupInfoService,
            pmemUtilService,
            virtualDiskPmemService,
            virtualMachineSpecBuilder,
            wizardViewData,
            preselectedStorage,
            wizardConfig);
      };
   }
}

export class VmProvisioningDatastorePageModel {
   private _selectionIsValid: boolean = false;
   private _pendingValidationRequests = 0;
   private _profilesDataPromise: CachedPromise;
   private _drsConfig: DrsConfig | null;

   private _storageSelectorStateAdvancedModeBackup: any;
   private _virtualMachineSpecBuilder: any;
   private _wizardViewData: any;
   private _preselectedStorage: any;
   private _wizardConfig: any;

   private _DATASTORE_MAPPING_PARAMS_TYPE: string =
         "com.vmware.vcenter.ovf.DatastoreMappingParams";

   public form: any;
   private readonly DEFAULT_STORAGE_ID: string = "defaultId";
   private readonly USE_SPECIFIED_STORAGE_POLICY: string = "USE_SPECIFIED_POLICY";

   constructor(
      private defaultUriSchemeUtil: any,
      private dataService: any,
      private i18nService: any,
      private $q: any,
      private creationTypeConstants: any,
      private storageResourceSelectionValidationService: any,
      private storageLocatorService: any,
      private storageProfileService: any,
      private managedEntityConstants: any,
      private contentLibraryService: any,
      private wizardPageService: any,
      private VirtualMachineDevices: any,
      private diskFormatService: any,
      private storageValidationService: any,
      private diskProvisioningService: any,
      private spbmReplicationGroupInfoService: any,
      private pmemUtilService: any,
      private virtualDiskPmemService: VirtualDiskPmemService,
      virtualMachineSpecBuilder: any,
      wizardViewData: any,
      preselectedStorage: any,
      wizardConfig: any
   ) {
      this.form = {
         drsDisabled: false,
         storageLocatorItemsData: {},
         vmDisksData: {},
         vmsHomeDatastore: {},
         diskFormatSettings: {
            diskFormatSupported: false,
            selectedDiskFormat: null
         },
         selectedStorage: null,
         storageProfilesData: {
            storageProfiles: [],
            selectedProfile: null,
            selectedProfileForDisks: null,
         },
         selectedReplicationGroupInfo: null,
         storageProfileHasBeenSelected: false,
         isAdvancedStorageMode: false,
         storageSelectorState: null,
         vmDisksStorage: null,
         basicModeLastSelectionIsValid: false,
         basicModeLastSelectedStorageId: undefined,
         basicModeLastSelectedProfileId: undefined,
         basicModeLastVmValidationConfig: undefined,
         basicModeCompatibilityResultsCache: undefined,
         advModeLastSelectionIsValid: false,
         advModeLastFolderId: undefined,
         advModeLastValidationModel: undefined,
         advModeCompatibilityResultsCache: undefined,

         // NOTE speev: Eventually, this shall replace most of the fields above.
         // Array<common_ui.VmStorageConfig>, one item per VM
         _vmStorageConfig: undefined,
         set vmStorageConfig(this: any, value: any) {
            this._vmStorageConfig = value;

            let config = this._vmStorageConfig[0];
            this.storageProfilesData.selectedProfile = config.vmHome.storageProfile;
            this.storageProfilesData.selectedProfileForDisks = _.isEmpty(
                  config.vmDisks) ? null : config.vmDisks[0].storageProfile;

            this.selectedStorage = config.vmHome.storageObj;
            this.selectedReplicationGroupInfo = config.vmHome.replicationGroup;
         },

         get vmStorageConfig() {
            // NOTE speev: JSLint require to have a getter. But so far we don't have
            // a use case for it - so throw error do discourage usage.
            throw Error("You should not access vmStorageConfig directly");
         }
      };

      this._virtualMachineSpecBuilder = virtualMachineSpecBuilder;
      this._wizardViewData = wizardViewData;
      this._preselectedStorage = preselectedStorage;
      this._wizardConfig = wizardConfig;
   }

   public get virtualMachineSpecBuilder() {
      return this._virtualMachineSpecBuilder;
   }

   public podDisplayDisabled() {
      if (this.isCloningVmtxTemplate() || this.isDeployVmFromVmtxTemplate()) {
         return true;
      }
      return this._virtualMachineSpecBuilder.isXvc();
   }

   public setSelectionIsValid(isValid: boolean) {
      this._selectionIsValid = isValid;
   }

   public getStorageLocatorData(contextObjectId: any): boolean {
      return this.storageLocatorService.getProvisioningStorageLocatorData(
         contextObjectId)
      .then((storageLocatorItemsData: any) => {
         if (!storageLocatorItemsData) {
            return false;
         }

         // Populate a storageId to storageName map, used by components
         // shared among the pages of the VmProvisioning wizards
         const storageIdToName = this.createStorageIdToNameMap(
               storageLocatorItemsData.datastoreItems,
               storageLocatorItemsData.storagePodItems
         );
         this._wizardViewData.setStorageNameMap(storageIdToName);

         function nameComparator(a: any, b: any) {
            return (a.name.toLowerCase() < b.name.toLowerCase()) ? -1 : 1;
         }

         storageLocatorItemsData.datastoreItems.sort(nameComparator);
         storageLocatorItemsData.storagePodItems.sort(nameComparator);

         this._wizardViewData.setStorageLocatorItemsData(storageLocatorItemsData);

         this.form.storageLocatorItemsData = storageLocatorItemsData;

         return true;
      });
   }

   public getStorageSelectorData(vmId: any) {
      return this._virtualMachineSpecBuilder.getAdvancedStoragePageData()
            .then((response: { advancedStoragePageData: VmAdvancedStoragePageData }) => {
               let result: any = {};
               if (response.advancedStoragePageData) {
                  const advancedStoragePageData: VmAdvancedStoragePageData = response.advancedStoragePageData;
                  result = {
                     vmReplicationGroupAssignments: advancedStoragePageData.vmReplicationGroupAssignments,
                     storagePoliciesAssignments: advancedStoragePageData.storagePoliciesAssignments
                  };

                  this.setVmDisks(vmId, advancedStoragePageData.virtualDisks);
                  this.setVmHomeDatastore(vmId, advancedStoragePageData.vmHomeDatastore);
               }
               result["virtualDisks"] = this.form.vmDisksData;
               result["vmHomeDatastore"] = this.form.vmsHomeDatastore;

               return result;
            });
   }

   public getVmIds() {
      return [this._virtualMachineSpecBuilder.getVmId()];
   }

   public getVmNames() {
      let result: any = {};
      result[this._virtualMachineSpecBuilder.getVmId()] =
            this._wizardViewData.getSourceObjectName();
      return result;
   }

   public getProfilesData(serverGuid: string): IPromise<any> {
      if (this._profilesDataPromise && this._profilesDataPromise.cacheId === serverGuid) {
         return this._profilesDataPromise.promise;
      }

      let options: any = {};
      if (this._virtualMachineSpecBuilder.getVmId()) {
         options = {
            vmId: this._virtualMachineSpecBuilder.getVmId()
         };
      }

      if (this.isInCloningMode() && !this._virtualMachineSpecBuilder.isXvc()) {
         options.includeKeepExisting = true;
      }

      this._profilesDataPromise = {
         promise: this.virtualMachineSpecBuilder
               .getStoragePageData().
               then((result: {vmStoragePageData: VmStoragePageFormattedData}) => {
                  if (!result.vmStoragePageData) {
                     return this.storageProfileService.buildErrorStorageProfilesData(
                                 this.i18nService.getString("VmUi",
                                       "SelectDatastoreProvisioningPage.profilesDataError"));
                  }

                  if (result.vmStoragePageData.profileError) {
                     return this.storageProfileService
                           .buildErrorStorageProfilesData(result.vmStoragePageData.profileError);
                  }

                  if (result.vmStoragePageData.hasProfileViewPrivilege === false) {
                     return this.storageProfileService.buildErrorStorageProfilesData(
                           this.i18nService.getString("Common", "storage.policy.dropdown.no.permissions"));
                  }

                  return this.storageProfileService.buildStorageProfilesData(
                        result.vmStoragePageData.profileStoragePageData, options);
               })
               .then((profilesData: any) => {
                  let preselectProfile = null;
                  if (this.form.storageProfilesData && this.form.storageProfilesData.selectedProfile) {
                     preselectProfile = this.form.storageProfilesData.selectedProfile;
                  }
                  this.form.storageProfilesData = profilesData;
                  if (preselectProfile) {
                     this.form.storageProfilesData.selectedProfile = preselectProfile;
                  }
                  return profilesData;
               }).catch(() => {
               }),
         cacheId: serverGuid
      };

      return this._profilesDataPromise.promise;
   }

   private setVmDisks(sourceId: any, vmDisks: any) {
      this.form.vmDisksData = this.form.vmDisksData || {};
      this.form.vmDisksData[sourceId] = vmDisks;
   }

   private setVmHomeDatastore(sourceId: any, datastore: any) {
      this.form.vmsHomeDatastore = this.form.vmsHomeDatastore || {};
      this.form.vmsHomeDatastore[sourceId] = datastore ?
         this.defaultUriSchemeUtil.getVsphereObjectId(datastore) : null;
   }

   public validateSelection(selectedStorage: any, selectedProfileId: string,
         diskFormat: any, replicationGroupValidationError: string|null, sourceVmStorageConfig: any) {

      if (this.isInDeployTemplateMode()) {
         if (this.canDeployOvfViaDrs(selectedStorage, selectedProfileId)) {
            this._selectionIsValid = true;
            return this.$q.resolve([this.newSuccessfulStorageCheck()]);
         }
      }
      let storageId =  selectedStorage
            ? this.defaultUriSchemeUtil.getVsphereObjectId(selectedStorage.storageRef)
            : undefined;
      if (!storageId || replicationGroupValidationError) {
         this._selectionIsValid = false;

         let errorMessage: string  = (!storageId)
               ? this.i18nService.getString(
                     "VmUi", "SelectDatastoreProvisioningPage.SpecifyValidLocation")
               : replicationGroupValidationError;

         return this.$q.resolve([{
               contents: [],
               icon: "vsphere-icon-status-error",
               message: errorMessage,
               advancedCompatibilityContents: {}
            }]
         );
      }

      this.form.selectedStorage = selectedStorage;

      let virtualDisksByVm = this.getVirtualDisksByVmSelections([sourceVmStorageConfig]);
      let vmValdiationConfig: any = this.getVmStorageValidationConfig();
      if (diskFormat && diskFormat.selectedDiskFormat &&
            this.form.vmDisksData && this.form.vmDisksData[vmValdiationConfig.vmId]) {
         let originalDisks: Array<any> = this.form.vmDisksData[vmValdiationConfig.vmId];
         let filteredDisks: Array<any> =
               this.virtualDiskPmemService.filterVirtualDisksToDiskLocatorsForPmem(
                     originalDisks, this.form.storageSelectorState.storageBaselineId);
         vmValdiationConfig.disks = this.buildDiskLocators(filteredDisks, virtualDisksByVm,
               vmValdiationConfig.vmStorageConf.vmHome.storageObj.storageRef,
               diskFormat, selectedProfileId, this.form.selectedReplicationGroupInfo);
      }

      if (this.form.basicModeLastSelectedStorageId === storageId
            && this.form.basicModeLastSelectedProfileId === selectedProfileId
            && angular.equals(this.form.basicModeLastVmValidationConfig, vmValdiationConfig)
            && this.form.basicModeCompatibilityResultsCache) {
         this._selectionIsValid = this.form.basicModeLastSelectionIsValid;
         return this.$q.resolve(this.form.basicModeCompatibilityResultsCache);
      }

      this._pendingValidationRequests++;
      return this.storageResourceSelectionValidationService.validateSelection(
            selectedStorage,
            selectedProfileId,
            vmValdiationConfig,
            this.isInDeployTemplateMode(), // treatProfileWarningsAsErrors
      ).then((result: any) => {
         this._selectionIsValid = result.isValid;

         if (this._selectionIsValid) {
            this._virtualMachineSpecBuilder.setVmDisksStorage(virtualDisksByVm);
         }

         this._pendingValidationRequests--;
         this.form.basicModeLastSelectionIsValid = this._selectionIsValid;
         this.form.basicModeLastSelectedStorageId = storageId;
         this.form.basicModeLastSelectedProfileId = selectedProfileId;
         this.form.basicModeLastVmValidationConfig = vmValdiationConfig;
         this.form.basicModeCompatibilityResultsCache = result.messages;

         return this.form.basicModeCompatibilityResultsCache;
      });
   }

   public validateAdvancedSelection(selectedStorage: any, sourceVmStorageConfig: any) {
      if (!this.isAdvModeDiskSelectionCompleted()) {
         this._selectionIsValid = false;
         this.form.vmDisksStorage = {};

         return this.$q.resolve([{
            contents: [],
            icon: "vsphere-icon-status-error",
            message: this.i18nService.getString(
                  "VmUi", "SelectDatastoreProvisioningPage.SpecifyValidLocation"),
            advancedCompatibilityContents: {}
         }]);
      }

      this.form.selectedStorage = selectedStorage;

      let targetVmStorageConfig =
            this.form.storageSelectorState.vmStorageConfigInAdvancedMode[0];
      if (!this.storageValidationService.validateDisksSdrsMap(targetVmStorageConfig)) {
         this._selectionIsValid = false;
         this.form.vmDisksStorage = {};

         return this.$q.resolve([{
            contents: [],
            icon: "vsphere-icon-status-error",
            message: this.i18nService.getString(
                  "VmUi", "sdrsInconsistentSelectionErrorMsg"),
            advancedCompatibilityContents: {}
         }]);
      }

      let rgValidationError: string|null =
            this.spbmReplicationGroupInfoService.validateVmReplicationGroupAssignments(
                  targetVmStorageConfig.getReplicationGroupAssignments());
      if (rgValidationError) {
         this._selectionIsValid = false;
         this.form.vmDisksStorage = {};
         return this.$q.resolve([{
            contents: [],
            icon: "vsphere-icon-status-error",
            message: rgValidationError,
            advancedCompatibilityContents: {}
         }]);
      }

      let vmId = this.virtualMachineSpecBuilder.getVmId();
      let poolId = this.virtualMachineSpecBuilder.getResourcePool() &&
            this.virtualMachineSpecBuilder.getResourcePool().id;
      let folderId = this.virtualMachineSpecBuilder.getTargetInformation() &&
            this.virtualMachineSpecBuilder.getTargetInformation().folderUid;
      let crId = this.virtualMachineSpecBuilder.getComputeResourceId();
      let virtualDisksByVm = this.getVirtualDisksByVmSelections([sourceVmStorageConfig]);

      let validationModel: any = {"vms": [vmId]};
      validationModel.virtualDisksByVm = virtualDisksByVm;
      validationModel.computeResource = crId;
      validationModel.pool = poolId;

      if (this.form.advModeLastFolderId === folderId
            && angular.equals(this.form.advModeLastValidationModel, validationModel)) {
         this._selectionIsValid = this.form.advModeLastSelectionIsValid;
         return this.$q.resolve(this.form.advModeCompatibilityResultsCache);
      }

      let vmCloneSpec = this.storageValidationService.buildVmCloneSpec(
            this.defaultUriSchemeUtil.getManagedObjectReference(folderId),
            this.virtualMachineSpecBuilder.getName(),
            this.defaultUriSchemeUtil.getManagedObjectReference(vmId),
            this.defaultUriSchemeUtil.getManagedObjectReference(poolId),
            this.defaultUriSchemeUtil.getManagedObjectReference(crId),
            sourceVmStorageConfig);
      let vmDisksConfig = this.form.vmDisksData[vmId];
      return this.storageValidationService.validateSelection(
         validationModel,
         vmCloneSpec,
         vmId,
         folderId,
         sourceVmStorageConfig,
         vmDisksConfig,
         (this.isCloningVmtxTemplate() || this.isDeployVmFromVmtxTemplate())
      ).then((result: any) => {
         this._selectionIsValid = result.hasSuccess;
         this.form.vmDisksStorage = this._selectionIsValid ? virtualDisksByVm : {};

         this.form.advModeLastSelectionIsValid = this._selectionIsValid;
         this.form.advModeLastFolderId = folderId;
         this.form.advModeLastValidationModel = validationModel;
         this.form.advModeCompatibilityResultsCache = result.compatibilityMessages;

         return this.form.advModeCompatibilityResultsCache;
      });
   }

   public getVirtualDisksByVmSelections(storageConfigPerVm: any) {
      let virtualDisksByVm: any = {};

      _.each(storageConfigPerVm, (vmStorageConfig: any) => {
         let vmId: any = vmStorageConfig.vmId;
         if (!virtualDisksByVm[vmId]) {
            virtualDisksByVm[vmId] = [];
         }

         let homeStorageConfig: any = vmStorageConfig.vmHome;
         if (!homeStorageConfig.storageObj) {
            // The selection is not here yet?
            return;
         }

         // Push one record for the vm config file
         virtualDisksByVm[vmId].push({
            key: "_configFile",
            label: homeStorageConfig.name,
            storage: this.defaultUriSchemeUtil.getVsphereObjectId(
               homeStorageConfig.storageObj.storageRef),
            parentStoragePod: this.defaultUriSchemeUtil.getVsphereObjectId(
               homeStorageConfig.storageObj.parentStoragePod),
            profile: homeStorageConfig.storageProfile,
            diskFormat: homeStorageConfig.diskFormat ? {
               label: homeStorageConfig.diskFormat.name,
               value: homeStorageConfig.diskFormat.type
            } : undefined,
            replicationGroup: homeStorageConfig.replicationGroup
         });

         // And one record for each VM disk
         _.each(vmStorageConfig.vmDisks, (diskStorageConfig: any) => {
            virtualDisksByVm[vmId].push({
               key: diskStorageConfig.key,
               label: diskStorageConfig.name,
               storage: diskStorageConfig.storageObj ?
                     this.defaultUriSchemeUtil.getVsphereObjectId(
                           diskStorageConfig.storageObj.storageRef)
                     : undefined,
               parentStoragePod: diskStorageConfig.storageObj ?
                     this.defaultUriSchemeUtil.getVsphereObjectId(
                        diskStorageConfig.storageObj.parentStoragePod)
                     : undefined,
               profile: diskStorageConfig.storageProfile,
               diskFormat: diskStorageConfig.diskFormat ? {
                  label: diskStorageConfig.diskFormat.name,
                  value: diskStorageConfig.diskFormat.type
               } : undefined,
               replicationGroup: diskStorageConfig.replicationGroup
            });
         });
      });

      return virtualDisksByVm;
   }

   public validatePage() {
      this.wizardPageService.showLoadingIndicator(this._wizardConfig);
      if (!this._selectionIsValid) {
         let localizedErrorMsg = this.i18nService.getString(
               "VmUi", "SelectDatastoreProvisioningPage.SpecifyValidLocation");
         this.wizardPageService.hideLoadingIndicator(this._wizardConfig);
         return {
            error: localizedErrorMsg,
            invalidateNextPages: !this._selectionIsValid,
            wizardFlow: this.virtualMachineSpecBuilder.getCreationType()
         };
      }

      if (!this.isInDeployFromTemplateMode()) {
         return null;
      }

      return this.validatePageInDeployFromTemplateMode();
   }

   public getServerGuid() {
      return this.defaultUriSchemeUtil.getManagedObjectReference(
            this._virtualMachineSpecBuilder.getComputeResourceId()).serverGuid;
   }

   public getComputeResourceId() {
      return this._virtualMachineSpecBuilder.getComputeResourceId();
   }

   public setComputeResourceId(resourceId: string) {
       return this._virtualMachineSpecBuilder.setComputeResourceId(resourceId);
   }

   public getComputeResourceOwnerId(): string|undefined {
      return this._virtualMachineSpecBuilder.getComputeResourceOwnerId();
   }

   public isCloningVmtxTemplate() {
       return this.creationTypeConstants.isCloningVmToLibrary(
           this._virtualMachineSpecBuilder.getCreationType());
   }

   public isDeployVmFromVmtxTemplate() {
      return this.creationTypeConstants.isDeployVmFromVmtxTemplate(
            this._virtualMachineSpecBuilder.getCreationType());
   }

   public pageFinishedLoading() {
      return this.form.storageProfilesData.selectedProfile;
   }

   public isInCloningMode() {
      return this.creationTypeConstants
            .isCloning(this._virtualMachineSpecBuilder.getCreationType());
   }

   public isInDeployFromTemplateMode() {
      return this.isInDeployTemplateMode();
   }

   public isInDeployVmFromOvf(): boolean {
      return this._virtualMachineSpecBuilder.getCreationType() ===
            this.creationTypeConstants.DEPLOY_VM_FROM_OVF;
   }

   public submitPage() {
      if (this.isCloningVmtxTemplate()) {
         this.wizardPageService.hideLoadingIndicator(this._wizardConfig);
         return { };
      }

      if (this.isDeployVmFromVmtxTemplate()) {
         if (this.form.isAdvancedStorageMode) {
            this._virtualMachineSpecBuilder.setVmtxStorageSpecSettings(this.getAdvancedStorageSpec());
         } else {
            this._virtualMachineSpecBuilder.setVmtxStorageSpecSettings(this.getSimpleStorageSpec());
         }
      }

      let isPreviousStorageModeAdvanced = this._virtualMachineSpecBuilder.getIsAdvancedStorageMode();
      this._wizardViewData.setStorageProfiles(this.form.storageProfilesData.storageProfiles);

      if (!this.form.isAdvancedStorageMode) {
         return this.submitInBasicStorageMode(isPreviousStorageModeAdvanced);
      } else {
         return this.submitInAdvancedStorageMode(isPreviousStorageModeAdvanced);
      }
   }

   public validationFinishedLoading() {
      return (this._pendingValidationRequests === 0);
   }

   private isInDeployTemplateMode() {
      return this._virtualMachineSpecBuilder.getCreationType() ===
               this.creationTypeConstants.DEPLOY_VM_FROM_TEMPLATE ||
            this._virtualMachineSpecBuilder.getCreationType() ===
               this.creationTypeConstants.DEPLOY_VM_FROM_VAPP ||
            this._virtualMachineSpecBuilder.getCreationType() ===
               this.creationTypeConstants.DEPLOY_VM_FROM_OVF;
   }

   private createStorageIdToNameMap(datastores: any[], storagePods: any[]) {
      let storageIdToName: any = {};
      _.forEach(datastores, (datastore: any) => {
         let currentStorageUrn = this.defaultUriSchemeUtil
               .getVsphereObjectId(datastore.storageRef);
         storageIdToName[currentStorageUrn] = datastore.name;
      });
      _.forEach(storagePods, (storagePod: any) => {
         let currentStorageUrn = this.defaultUriSchemeUtil
               .getVsphereObjectId(storagePod.storageRef);
         storageIdToName[currentStorageUrn] = storagePod.name;
      });
      return storageIdToName;
   }

   /**
    * Searches for the item to be selected by default.
    * The preselected (context object) storage (if any) is picked-up.
    * @param datastores
    * @param storagePods
    * @returns The storage chosen for the default one.
    */
   public getItemToSelect(datastores: any[], storagePods: any[]) {
      // Check if the preselected storage is available for the selected compute resource
      // If the pre-selected storage is not available - don"t preselect anything.
      if (this._preselectedStorage) {
         let storageType = this.defaultUriSchemeUtil.getEntityType(this._preselectedStorage);
         let storageArray = (storageType === this.managedEntityConstants.STORAGE_POD) ?
               storagePods : datastores;

         return _.find(storageArray, (element: any) => {
            let storageId = this.defaultUriSchemeUtil.getVsphereObjectId(element.storageRef);
            return storageId === this._preselectedStorage;
         });
      }

      return null;
   }

   public getProfileToSelect() {
      return this.isInCloningMode()
            ? null /* defaults to the "Keep Existing VM storage policies */
            : this.form.storageProfilesData.selectedProfile;
   }

   private updateDiskFormatIfVSAN() {
      if (!this.isInDeployTemplateMode()) {
         return;
      }

      let commonContext = this._wizardViewData.getDeployFromLibraryCommonContext();
      let datastoreMappingParams = _.find(commonContext, (item: any) => {
         return item._type === this._DATASTORE_MAPPING_PARAMS_TYPE;
      });

      if (!datastoreMappingParams) {
         return;
      }

      // Fix the disk format for disks not part of any disk group
      let provisioningType = datastoreMappingParams.targetProvisioningType;
      if (provisioningType && (provisioningType.name ===
            this.diskFormatService.diskFormats.asDefinedInProfile.type)) {
         datastoreMappingParams.targetProvisioningType = null;
      }

      // Now fix disk format for each disk group too (if there are any)
      _.each(datastoreMappingParams.diskGroups, (diskGroup: any) => {
         let provisioningType = diskGroup.targetProvisioningType;
         if (provisioningType && (provisioningType.name ===
               this.diskFormatService.diskFormats.asDefinedInProfile.type)) {
            diskGroup.targetProvisioningType = null;
         }
      });
   }

   private validatePageInDeployFromTemplateMode() {
      let promise = this.contentLibraryService.validateStorageSelection(
            this._virtualMachineSpecBuilder.getComputeResourceId(),
            this.buildDiskGroups(),
            this._wizardViewData.getDeployFromLibraryCommonContext()
      );

      return promise.then((response: any) => {
         if (response.result && response.result.commonContext) {
            this._wizardViewData.setDeployFromLibraryCommonContext(
                  response.result.commonContext);
            return null;
         }

         this.wizardPageService.hideLoadingIndicator(this._wizardConfig);
         return {
            error: response.error ?
                  response.error.localizedMessage :
                  this.i18nService.getString("VmUi",
                        "SelectResourcePoolProvisioningPage.fatalError"),
            invalidateNextPages: !this._selectionIsValid,
            wizardFlow: this._virtualMachineSpecBuilder.getCreationType()
         };
      });
   }

   private buildDiskGroups () {
      const selectedDatastore: any = this.form.selectedStorage ?
            this.form.selectedStorage.storageRef : undefined;

      let diskGroups = _.map(this.form._vmStorageConfig[0].vmDisks,
            (storageConfig: any /*VmComponentStorageConfig*/, index: any) => {
         let diskGroup: any = {
            id: storageConfig.key,
            name: storageConfig.name,
            // NOTE speev: After API trials it seems '' (empty string) is treated
            // as no profile while undefined/null is treated as use the
            // default profile for this group from the OVF file (if there is any)
            // Weird... indeed, so that is why we use '' here.
            targetProfile: this.storageProfileService.isDefaultStorageProfile(
                  storageConfig.storageProfile) ?
                  "" : storageConfig.storageProfile.id,
            // PMem policy disks do not have storageObj
            targetDatastore:  storageConfig.storageObj ?
                  this.defaultUriSchemeUtil.getVsphereObjectId(
                        storageConfig.storageObj.storageRef) : undefined,
            targetProvisioningType: storageConfig.diskFormat ?
                  storageConfig.diskFormat.type : undefined
         };

         // For PMEM storage profile home/system disk would be empty and must be set
         // with the selected storage and profile to be empty.
         if (!diskGroup.targetDatastore &&
               storageConfig.key === this.contentLibraryService.SYSTEM_DISK_GROUP_ID) {
            diskGroup.targetDatastore =
                  this.defaultUriSchemeUtil.getVsphereObjectId(selectedDatastore);
            if (this.pmemUtilService.isPMemStoragePolicy(storageConfig.storageProfile)) {
               diskGroup.targetProfile = '';
            }
         }
         return diskGroup;
      });
      return diskGroups;
   }

   private submitInBasicStorageMode(isPreviousStorageModeAdvanced: boolean) {
      let vmId = this._virtualMachineSpecBuilder.getVmId();
      let previousStorage = this._virtualMachineSpecBuilder.getStorageObject();
      let previousStorageProfile =
            this._virtualMachineSpecBuilder.getSelectedStorageProfile();
      // if the selected datastore has been changed or the grid"s selection
      // has been removed invalidate next pages
      let invalidatePages = !this._selectionIsValid
            || isPreviousStorageModeAdvanced
            || (previousStorage
            && previousStorage.storageRef.value !==
                  this.form.selectedStorage.storageRef.value)
            || (previousStorageProfile
                        && previousStorageProfile.id !==
                        this.form.storageProfilesData.selectedProfile.id);

      this._virtualMachineSpecBuilder.setSelectedStorageProfile(
            this.form.storageProfilesData.selectedProfile);
      this._virtualMachineSpecBuilder.setSelectedStorageProfileForDisks(
            this.form.storageProfilesData.selectedProfileForDisks);
      this._virtualMachineSpecBuilder.setSelectedReplicationGroupInfo(
            this.form.selectedReplicationGroupInfo);

      this._virtualMachineSpecBuilder.setIsAdvancedStorageMode(false);

      let diskFormat: {type: string, name: string}|undefined = undefined;
      if (this.form.diskFormatSettings.diskFormatSupported) {
         diskFormat = this.form.diskFormatSettings.selectedDiskFormat;
         this._wizardViewData.setDiskFormatLabel(diskFormat ? diskFormat.name : "");
         this._virtualMachineSpecBuilder.setStorageObjectAndDiskFormat(
               this.form.selectedStorage, diskFormat);
         this.updateDiskFormatIfVSAN();
      } else {
         this._virtualMachineSpecBuilder.setStorageObject(this.form.selectedStorage);
      }

      this._virtualMachineSpecBuilder.setDisableSdrs(this.form.drsDisabled);

      if (!this._virtualMachineSpecBuilder.storageObjectIsPod() && this.form.selectedStorage) {
         this._wizardViewData.setDatastoreObjectName(this.form.selectedStorage.name);
      }

      this.wizardPageService.hideLoadingIndicator(this._wizardConfig);
      _.each(this.form.vmDisksData[vmId], (disk: any) => {
         const currentDisk = angular.copy(disk);
         if (!diskFormat || diskFormat.type === this.diskProvisioningService.SAME_AS_SOURCE.type) {
            currentDisk.backing = null;
         } else {
            currentDisk.backing = this.diskProvisioningService.getBackingInfo(diskFormat.type);
         }
         this._virtualMachineSpecBuilder.setStorageForDisk(
               this.form.selectedStorage, currentDisk);
      });

      if (isPreviousStorageModeAdvanced) {
         this._virtualMachineSpecBuilder.setVirtualMachineDevices(null);
         this._virtualMachineSpecBuilder.setRecreateVm(true);
      }

      return {
         invalidateNextPages: invalidatePages,
         wizardFlow: this._virtualMachineSpecBuilder.getCreationType()
      };
   }

   private submitInAdvancedStorageMode(isPreviousStorageModeAdvanced: boolean) {
      let vmId = this._virtualMachineSpecBuilder.getVmId();
      let previousVmDisksStorage = this._virtualMachineSpecBuilder.getVmDisksStorage();
      let invalidatePages = !this._selectionIsValid
            || !isPreviousStorageModeAdvanced
            || !_.isEqual(this.form.vmDisksStorage, previousVmDisksStorage);

      this._virtualMachineSpecBuilder.setIsAdvancedStorageMode(true);

      this._virtualMachineSpecBuilder.setSelectedStorageProfile(
            this.form.storageProfilesData.selectedProfile);
      this._virtualMachineSpecBuilder.setSelectedStorageProfileForDisks(
            this.form.storageProfilesData.selectedProfileForDisks);
      this._virtualMachineSpecBuilder.setSelectedReplicationGroupInfo(
            this.form.selectedReplicationGroupInfo);

      // For the configuration disk
      let diskFormatSettings = this.form.diskFormatSettings;
      if (diskFormatSettings.diskFormatSupported && diskFormatSettings.selectedDiskFormat) {
         this._wizardViewData.setDiskFormatLabel(
            this.form.diskFormatSettings.selectedDiskFormat.name);
         this._virtualMachineSpecBuilder.setStorageObjectAndDiskFormat(
            this.form.selectedStorage,
            this.form.diskFormatSettings.selectedDiskFormat
         );
         this.updateDiskFormatIfVSAN();
      } else {
         this._virtualMachineSpecBuilder.setStorageObject(this.form.selectedStorage);
      }

      this._virtualMachineSpecBuilder.setVmDisksStorage(this.form.vmDisksStorage);

      if (!invalidatePages && this._storageSelectorStateAdvancedModeBackup) {
         let oldVmHomeProfile = this._storageSelectorStateAdvancedModeBackup
               .vmStorageConfigInAdvancedMode[0].vmHome.storageProfile;
         let newVmHomeProfile = this._virtualMachineSpecBuilder.getStorageSelectorState()
               .vmStorageConfigInAdvancedMode[0].vmHome.storageProfile;
         invalidatePages = oldVmHomeProfile.id !== newVmHomeProfile.id;
      }

      if (!this._virtualMachineSpecBuilder.getVirtualMachineDevices()) {
         let virtualDevices = new this.VirtualMachineDevices(
            this.form.vmDisksData[vmId],
               null /* profileAssignments */,
               null /* profiles */,
               null /* availableNetworks */,
               null);
         this._virtualMachineSpecBuilder.setVirtualMachineDevices(virtualDevices);
      }

      _.each(this.form.vmDisksData[vmId], (disk:any, idx:any) => {
         let  vmDiskState = this.form.storageSelectorState.vmStorageConfigInAdvancedMode[0].vmDisks[idx];
         let currentDisk = angular.copy(disk);
         if (!vmDiskState.diskFormat || vmDiskState.diskFormat.type === this.diskProvisioningService.SAME_AS_SOURCE.type) {
            currentDisk.backing = null;
         } else {
            currentDisk.backing = this.diskProvisioningService.getBackingInfo(vmDiskState.diskFormat.type);
         }
         this._virtualMachineSpecBuilder.setStorageForDisk(
            this.form.storageSelectorState.vmStorageConfigInAdvancedMode[0].vmDisks[idx].storageObj, currentDisk);

         if (!invalidatePages && this._storageSelectorStateAdvancedModeBackup) {
            let oldDiskProfile = this._storageSelectorStateAdvancedModeBackup
                  .vmStorageConfigInAdvancedMode[0].vmDisks[idx].storageProfile;
            let newDiskProfile = this._virtualMachineSpecBuilder.getStorageSelectorState()
                  .vmStorageConfigInAdvancedMode[0].vmDisks[idx].storageProfile;
            invalidatePages = oldDiskProfile.id !== newDiskProfile.id;
         }
      });

      this._storageSelectorStateAdvancedModeBackup = this.form.storageSelectorState;

      this.wizardPageService.hideLoadingIndicator(this._wizardConfig);
      return {
         invalidateNextPages: invalidatePages,
         wizardFlow: this._virtualMachineSpecBuilder.getCreationType()
      };
   }

   private getVmStorageValidationConfig() {
      return {
         computeResourceId: this._virtualMachineSpecBuilder.getComputeResourceId(),
         targetFolderUid: this._virtualMachineSpecBuilder.getTargetInformation() &&
                this._virtualMachineSpecBuilder.getTargetInformation().folderUid,
         creationType: this._virtualMachineSpecBuilder.getCreationType(),
         name: this._virtualMachineSpecBuilder.getName(),
         vmId: this._virtualMachineSpecBuilder.getVmId(),
         resPoolId: this._virtualMachineSpecBuilder.getResourcePool() &&
                this._virtualMachineSpecBuilder.getResourcePool().id,
         vmDisks: this.form.vmDisksData[this._virtualMachineSpecBuilder.getVmId()],
         vmStorageConf: {
            vmHome: {
               storageObj: this.form.selectedStorage,
               storageProfile: this.form.storageProfilesData.selectedProfile
            }
         }
      };
   }

   private isAdvModeDiskSelectionCompleted() {
      let vmStorageConfig = this.form.storageSelectorState.vmStorageConfigInAdvancedMode[0];
      return vmStorageConfig.vmHome.storageObj &&
         !_.any(vmStorageConfig.vmDisks, (disk: any) => {
            return !disk.storageObj
                  && !this.pmemUtilService.isPMemStoragePolicy(disk.storageProfile);
         });
   }

   // NOTE speev: Doesn't really do a request, but the name is analogous to the
   // advanced case where actual request is done, since it has the same purpose.
   public requestVmDisksInDeployFromTemplateMode() {
      let datastoreMappingParams = this.getDatastoreMappingParams();
      if (!datastoreMappingParams) {
         return undefined;
      }

      // One dummy disk for each defined disk group.
      let disks = _.map(datastoreMappingParams.diskGroups, (diskGroup: any) => {
         return {
            key: diskGroup.id,
            backing: {
               datastore: this.getVsphereDatastoreId(diskGroup.targetDatastore)
            },
            deviceInfo: {
               label: diskGroup.name
            },
            capacityInBytes: 0,
            capacityInKB: 0,
            $profileId: _.isEmpty(diskGroup.targetProfile) ?
                  undefined : diskGroup.targetProfile // custom, not in the real vdisk

         };
      });

      // And one dummy disk for all disks that are not part of any disk group.
      disks.unshift({
         key: this.contentLibraryService.SYSTEM_DISK_GROUP_ID,
         backing: {
            datastore: this.getVsphereDatastoreId(datastoreMappingParams.targetDatastore)
         },
         deviceInfo: {
            label: this.contentLibraryService.SYSTEM_DISK_GROUP_LABEL
         },
         capacityInBytes: 0,
         capacityInKB: 0,
         $profileId: _.isEmpty(datastoreMappingParams.targetProfile) ?
            undefined : datastoreMappingParams.targetProfile // custom, not in the real vdisk
      });

      return {
         // The vmId is 'undefined' in this case.
         undefined: disks
      };
   }

   // NOTE speev: Doesn't really do a request, but the name is analogous to the
   // advanced case where actual request is done, since it has the same purpose.
   public requestVmHomeDatastoresInDeployFromTemplateMode() {
      let datastoreMappingParams = this.getDatastoreMappingParams();
      if (!datastoreMappingParams) {
         return undefined;
      }

      return {
         // The vmId is 'undefined' in this case.
         undefined: this.getVsphereDatastoreId(datastoreMappingParams.targetDatastore)
      };
   }

   /*
   * Primarily used to check if workflow is deploy OVF template from file or library.
   **/
   public isDiskGroupsAvailable(): boolean {
      return this.getDatastoreMappingParams() &&
            this.getDatastoreMappingParams().diskGroups &&
            this.getDatastoreMappingParams().diskGroups.length > 0;
   }

      public getRecommendedDatastoresForTemplateDeployment(): any {
         const datastoreMappingParams = this.getDatastoreMappingParams();
         if (!datastoreMappingParams || !datastoreMappingParams.availableDatastores) {
            return this.form.storageLocatorItemsData.datastoreItems;
         }
         const availableDatastores = datastoreMappingParams.availableDatastores;
         const input: Array<any> = this.form.storageLocatorItemsData.datastoreItems;
         const retVal = VmProvisioningDatastorePageModel.filterDatastores(input, availableDatastores);
         return retVal;
      }

   public getRecommendedProfilesForTemplateDeployment(profiles: any): any {
      const datastoreMappingParams = this.getDatastoreMappingParams();
      if (!datastoreMappingParams || !datastoreMappingParams.availableStorageProfiles || !profiles) {
         return profiles;
      }

      if (datastoreMappingParams && datastoreMappingParams.availableStorageProfiles &&
         !this.isDefaultStorageProfilePresentInList(datastoreMappingParams.availableStorageProfiles)) {
         datastoreMappingParams.availableStorageProfiles
               .splice(0, 0, this.storageProfileService.getDefaultProfile());
      }

      //Retain only the profiles present in datastore mapping params provided by content library service.
      return profiles.filter(function (currentProfile: any) {
         return datastoreMappingParams.availableStorageProfiles
               .some((profile: any) => {
                  return currentProfile.id === profile.id;
               });
      });
   }

   private isDefaultStorageProfilePresentInList(profiles: Array<any>): boolean {
      return profiles && (profiles.indexOf((profile: any) => {
         return profile.id === this.storageProfileService.getDefaultProfile().id;
      }) > -1);
   }

   private getVsphereDatastoreId(contentLibaryDatastoreId: any) {
      if (!contentLibaryDatastoreId) {
         return undefined;
      }

      let parts = contentLibaryDatastoreId.split(":");
      if (parts.length < 2) {
         return undefined;
      }

      return this.defaultUriSchemeUtil.getVsphereObjectId({
            type: "Datastore",
            value: parts[0],
            serverGuid: parts[1]
      });
   }

   private getDatastoreMappingParams() {
      let commonContext = this._wizardViewData.getDeployFromLibraryCommonContext();
      return _.find(commonContext, (item: any) => {
         return item._type === this._DATASTORE_MAPPING_PARAMS_TYPE;
      });
   }

   private getAdvancedStorageSpec(): any {
      let storageConfig: any = this.form.storageSelectorState.vmStorageConfigInAdvancedMode[0];
      let vmHomeSpec: any = this.getStorageSpecFromVmStorageComponent(storageConfig.vmHome);

      let diskStorageOverrideSpec: any = {};
      for (let index = 0; index < storageConfig.vmDisks.length; index++) {
         let vmDisk: any = storageConfig.vmDisks[index];
         diskStorageOverrideSpec[vmDisk.key] = this.getStorageSpecFromVmStorageComponent(vmDisk);
      }

      return {
         vmHomeStorage: vmHomeSpec,
         diskStorageOverrides: diskStorageOverrideSpec
      };
   }

   private getSimpleStorageSpec(): any {
      let datastoreRef: any = this.form.selectedStorage.storageRef;
      let datastoreId: string = this.defaultUriSchemeUtil.createVmomiUri(
            datastoreRef.type,
            datastoreRef.value,
            datastoreRef.serverGuid
      );
      let selectedProfileForDisksId: string = '';
      if (this.form.storageProfilesData.selectedProfileForDisks) {
         selectedProfileForDisksId =
               this.form.storageProfilesData.selectedProfileForDisks.id;
      }
      let hasDefaultStoragePolicy: boolean =
            (selectedProfileForDisksId === '' || !selectedProfileForDisksId ||
                  selectedProfileForDisksId === this.DEFAULT_STORAGE_ID);

      let storageSpec: any = {
         datastore: datastoreId,
         storagePolicy: hasDefaultStoragePolicy ? null : {
            type: this.USE_SPECIFIED_STORAGE_POLICY,
            policy: selectedProfileForDisksId
         }
      };

      return {
         vmHomeStorage: storageSpec,
         diskStorage: storageSpec
      };
   }

   private getStorageSpecFromVmStorageComponent(storageComponent: any): any {
      let datastoreRef: any = storageComponent.storageObj.storageRef;
      let datastoreId: string = this.defaultUriSchemeUtil.createVmomiUri(
            datastoreRef.type,
            datastoreRef.value,
            datastoreRef.serverGuid
      );
      let storageProfile = storageComponent.storageProfile;
      let selectedProfileId: string | null = storageProfile && storageProfile.id;
      let hasDefaultStoragePolicy: boolean = selectedProfileId === this.DEFAULT_STORAGE_ID;
      if (!selectedProfileId || selectedProfileId === '') {
         hasDefaultStoragePolicy = true;
      }

      return {
         datastore: datastoreId,
         storagePolicy: hasDefaultStoragePolicy ? null : {
                  type: this.USE_SPECIFIED_STORAGE_POLICY,
                  policy: selectedProfileId
               }
      };
   }

   private buildDiskLocators(filteredDisks: any[], virtualDisksByVm: any,
         homeStorageRef: ManagedObjectReference, diskFormat: any, selectedProfileId: string,
         replicationGroupInfo: any): any[] {
      const vmId = this.virtualMachineSpecBuilder.getVmId();
      const virtualDisks = virtualDisksByVm[vmId];
      const disks = _.map(filteredDisks, (disk: any) => {
         const virtualDiskFromStorageSelector = _.find(virtualDisks, (virtualDiskConfig: any) => {
            return virtualDiskConfig.key === disk.key;
         });

         let diskLocator: any = new RelocateSpec$DiskLocator();
         diskLocator.diskId = disk.key;

         if (virtualDiskFromStorageSelector) {
            diskLocator.datastore = this.defaultUriSchemeUtil
                  .getManagedObjectReference(virtualDiskFromStorageSelector.storage);
            diskLocator.diskBackingInfo = (virtualDiskFromStorageSelector.diskFormat.value ===
            this.diskProvisioningService.SAME_AS_SOURCE.type ? null :
                  this.diskProvisioningService.getBackingInfo(diskFormat.selectedDiskFormat.value));
            const profile = virtualDiskFromStorageSelector.profile;
            diskLocator.profile = [this.storageProfileService.makeProfile(
                  profile && profile.id,
                  virtualDiskFromStorageSelector.replicationGroup)];
            return diskLocator;
         }

         diskLocator.datastore = homeStorageRef;
         diskLocator.diskBackingInfo = (diskFormat.selectedDiskFormat.type ===
         this.diskProvisioningService.SAME_AS_SOURCE.type ? null :
               this.diskProvisioningService.getBackingInfo(diskFormat.selectedDiskFormat.type));
         diskLocator.profile = [this.storageProfileService.makeProfile(
               selectedProfileId, replicationGroupInfo)];
         return diskLocator;
      });

      return disks;
   }

   private static filterDatastores(input: any[], availableDatastores: any[]): any[] {

      interface DatastoreItem {
         readonly storageRef: ManagedObjectReference;
         readonly parentStoragePod: ManagedObjectReference;
         readonly type: string; // vmfs, pmem, etc.
      }

      interface CLStorage { // datastore or pod
         readonly id: string; // "moid:guid" string without type
      }

      class MoRef extends ManagedObjectReference {
      }

      const availableStorage: CLStorage[] = availableDatastores;

      const compareMoRef = function (storage: CLStorage, moRef: MoRef): boolean {
         const id1: string = storage.id;
         const SEPARATOR: string = ":";
         // ignoring type, only value + guid
         const id2: string = moRef.value + SEPARATOR + moRef.serverGuid;
         if (id1 === id2) {
            return true;
         }
         return false;
      };

      const isPmemDatastore = function (datastoreItem: DatastoreItem): boolean {
         const PMEM_NAMESPACE_LOWERCASE: String = "pmem";

         if (!datastoreItem) {
            return false;
         }
         const type: string = datastoreItem.type;

         if (!type) {
            return false;
         }
         if (type.toLowerCase() === PMEM_NAMESPACE_LOWERCASE) {
            return true;
         }
         return false;
      };

      const isSubsetOf = function (item: DatastoreItem, array: CLStorage[]): boolean {
         if (!item) {
            return false;
         }
         const storageRef:MoRef = item.storageRef;
         const retVal: boolean = array.some((item: CLStorage) => {
            return compareMoRef(item, storageRef);
         });
         if (retVal) {
            return true;
         }
         return false;
      };

      const isParentSubsetOf = function (item: DatastoreItem, array: CLStorage[]): boolean {
         if (!item) {
            return false;
         }
         const pod: MoRef = item.parentStoragePod;
         if (!pod) {
            return false;
         }
         const storageRef:MoRef = pod;

         const retVal: boolean = array.some((item: CLStorage) => {
            return compareMoRef(item, storageRef);
         });

         if (retVal) {
            return true;
         }
         return false;
      };

      const filterFn = function (item: DatastoreItem): boolean {
         if (isPmemDatastore(item)) {
            return true;
         }

         if (isSubsetOf(item, availableStorage)) {
            return true;
         }

         if (isParentSubsetOf(item, availableStorage)) {
            return true;
         }
         return false;
      };

      const output = input.filter(filterFn);
      return output;
   }

   public load(): IPromise<any> {
      if (!this.isInDeployTemplateMode()) {
         this._drsConfig = null;
         return this.$q.resolve({});
      }
      return this.fetchDrsConfig();
   }

   private fetchDrsConfig(): IPromise<any> {
      const promise = this.getDrsConfigPromise();
      const callback = (config: any): IPromise<any> => {
         this._drsConfig = config;
         return this.$q.resolve(config);
      };
      const retVal: IPromise<any> = promise.then(callback);
      return retVal;
   }

   private getDrsConfigPromise(): IPromise<any> {
      let crId = this.virtualMachineSpecBuilder.getComputeResourceId();
      const DRS_CONFIG: string = "configurationEx.drsConfig";
      const promise = this.getProperty(crId, DRS_CONFIG);
      return promise;
   }

   private getProperty(objectId: string, property: string): IPromise<any> {
      const promise: IPromise<any> = this.dataService.getProperties(objectId, [property]);
      const callback = (result: any): any => {
         let retVal = undefined;
         if (result && property) {
            retVal = result[property];
         }
         return this.$q.resolve(retVal);
      };
      const retVal = promise.then(callback);
      return retVal;
   }

   private newSuccessfulStorageCheck(): StorageCheck {
      const retVal: StorageCheck = this.newStorageCheck(true);
      return retVal;
   }

   private newStorageCheck(success: boolean, error?: string): StorageCheck {
      let retVal: StorageCheck = new StorageCheck();
      if (success) {
         retVal.icon = "vsphere-icon-status-ok";
         const i18nKey: string = "ProvisioningWizard.CompatibilityChecksSucceeded";
         const succeeded: string = this.i18nService.getString("VmUi", i18nKey);
         retVal.message = succeeded;
      } else {
         retVal.icon = "vsphere-icon-status-error";
         const prefix: string = "SelectDatastoreProvisioningPage.";
         const i18nKey: string = prefix + error || "SpecifyValidLocation";
         const message = this.i18nService.getString("VmUi", i18nKey);
         retVal.message = message;
      }
      retVal.advancedCompatibilityContents = {};
      return retVal;
   }

   public needsDatastoreSelection(): boolean {
      if (false === this.isInDeployTemplateMode()) {
         return true;
      }
      if (this.isAutomatedDrsCluster()) {
         return false;
      }
      return true;
   }

   private canDeployOvfViaDrs(selectedStorage: any, selectedProfileId: any): boolean {
      if (selectedStorage) {
         // only could place vis DRS if storage NOT selected
         return false;
      }
      if (!selectedProfileId) {
         // but profile should be selected for DRS placement to work
         return false;
      }
      const isDefaultStorageProfile: boolean = this.storageProfileService.isDefaultStorageProfile({id: selectedProfileId});

      if (isDefaultStorageProfile) {
         // DRS placement won't work with default profile
         return false;
      }

      if (this.isAutomatedDrsCluster()) {
         return true;
      }

      return false;
   }

   private isAutomatedDrsCluster(): boolean {
      const behavior: string | undefined = this._drsConfig ? this._drsConfig.defaultVmBehavior : undefined;

      if (!behavior) {
         return false; // single host won't do
      }
      // VMODL enums for DRS cluster
      const DRS_FULLY_AUTOMATED: string = "fullyAutomated";
      const DRS_PARTIALLY_AUTOMATED: string = "partiallyAutomated";

      if (behavior === DRS_FULLY_AUTOMATED) {
         return true;
      }
      if (behavior === DRS_PARTIALLY_AUTOMATED) {
         return true;
      }
      return false;
   }
} // class

   class StorageCheck {
      contents: any[]; // TODO define type
      icon: string;
      message?: string;
      advancedCompatibilityContents: any; // TODO define type
   }

   interface DrsConfig {
      defaultVmBehavior?: string;
   }

   angular.module("com.vmware.vsphere.client.vm").factory(
      "VmProvisioningDatastorePageModel",
      VmProvisioningDatastorePageModelFactory);

} // namespace
