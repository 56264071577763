(function() {
   'use strict';
   angular.module('com.vmware.vsphere.client.cluster')
         .controller('AdvancedOptionsPageController', AdvancedOptionsPageController);

   AdvancedOptionsPageController.$inject = ['$scope', 'i18nService'];

   function AdvancedOptionsPageController($scope, i18nService) {
      var self = this;
      var haManager = $scope.modalOptions.dialogData.manager;
      var getString = _.partial(i18nService.getString, 'ClusterUi');

      self.advancedOptionsDesc = getString('ha.config.advancedOptions.description');
      self.advancedOptionsBuilder = haManager.getAdvancedOptionsModel().haAdvancedOptionsBuilder;
   }
})();
