/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace dvs_ui {

   export class DvpgCreateWizardPages {

      public static $inject = [
            "i18nService",
            "simpleValidationCommit",
            "dvpgNameAndLocationPageValidator",
            "dvpgGeneralPropertiesPageValidator",
            "dvpgTrafficShapingPolicyService",
            "dvpgFailoverPolicyPageValidator"
      ];

      static readonly PAGES = class {
         static readonly NAME_AND_LOCATION: string = "nameAndLocationPage";
         static readonly GENERAL_PROPERTIES: string = "generalPropertiesPage";
         static readonly SECURITY_POLICY: string = "securityPolicyPage";
         static readonly TRAFFIC_SHAPING: string = "trafficShapingPage";
         static readonly FAILOVER_POLICY: string = "teamingAndFailoverPage";
         static readonly MONITORING: string = "monitoringPage";
         static readonly MISC: string = "miscPage";
         static readonly READY_TO_COMPLETE: string = "readyToCompletePage";
      };

      constructor(private i18nService: any,
                  private simpleValidationCommit: any,
                  private nameAndLocationPageValidator: DvpgNameAndLocationPageValidator,
                  private generalPropertiesPageValidator: DvpgGeneralPropertiesPageValidator,
                  private dvpgTrafficShapingPolicyService: DvpgTrafficShapingPolicyService,
                  private dvpgFailoverPolicyPageValidator:DvpgFailoverPolicyPageValidator) {
      }

      public createPages(wizardScope: DvpgCreateWizardScope): WizardPage[] {

         let nameAndLocationPage: WizardPage = {
            id: DvpgCreateWizardPages.PAGES.NAME_AND_LOCATION,
            title: this.i18nService.getString("DvsUi",
                  "dvpg.create.nameAndLocation.title"),
            description: this.i18nService.getString("DvsUi",
                  "dvpg.create.nameAndLocation.description"),
            contentUrl: "dvs-ui/resources/dvs/dvpg/create/views/dvpgNameAndLocationPage.html",
            onCommit: this.simpleValidationCommit(() => {
               return this.nameAndLocationPageValidator.getValidationError(wizardScope);
            }, wizardScope.wizardConfig),
            validationParams: []
         };

         let generalPropertiesPage: WizardPage = {
            id: DvpgCreateWizardPages.PAGES.GENERAL_PROPERTIES,
            title: this.i18nService.getString("DvsUi",
                  "dvpg.create.properties.title"),
            description: this.i18nService.getString("DvsUi",
                  "dvpg.create.properties.description"),
            contentUrl: "dvs-ui/resources/dvs/dvpg/create/views/" +
                  "dvpgGeneralPropertiesPage.html",
            onCommit: this.simpleValidationCommit(() => {
               return this.generalPropertiesPageValidator.getValidationError(wizardScope);
            }, wizardScope.wizardConfig),
            validationParams: []
         };
         let securityPolicyPage: WizardPage = {
            id: DvpgCreateWizardPages.PAGES.SECURITY_POLICY,
            title: this.i18nService.getString("DvsUi", "dvpg.policy.security.title"),
            description: this.i18nService.getString("DvsUi",
                  "dvpg.policy.security.description"),
            contentUrl: "dvs-ui/resources/dvs/dvpg/manage/views/dvpgSecurityPolicyPage.html",
            onCommit: this.simpleValidationCommit((): any => [], wizardScope.wizardConfig),
            validationParams: [
               "model.securityPolicyModel.allowPromiscuous",
               "model.securityPolicyModel.forgedTransmits",
               "model.securityPolicyModel.macChanges"]
         };
         let trafficShapingPage: WizardPage = {
            id: DvpgCreateWizardPages.PAGES.TRAFFIC_SHAPING,
            title: this.i18nService.getString("DvsUi", "dvpg.policy.shaping.title"),
            description: this.i18nService.getString("DvsUi",
                  "dvpg.policy.shaping.description"),
            contentUrl: "dvs-ui/resources/dvs/dvpg/manage/views/dvpgTrafficShapingPage.html",
            onCommit: this.simpleValidationCommit((): any => {
               return this.dvpgTrafficShapingPolicyService.getErrorMessages(
                     wizardScope.model.trafficShapingPolicyModel.inShapingPolicy,
                     wizardScope.model.trafficShapingPolicyModel.outShapingPolicy);
            }, wizardScope.wizardConfig),
            validationParams: [
               "model.trafficShapingPolicyModel.inShapingPolicy.enabled",
               "model.trafficShapingPolicyModel.inShapingPolicy.averageBandwidth",
               "model.trafficShapingPolicyModel.inShapingPolicy.peakBandwidth",
               "model.trafficShapingPolicyModel.inShapingPolicy.burstSize",
               "model.trafficShapingPolicyModel.outShapingPolicy.enabled",
               "model.trafficShapingPolicyModel.outShapingPolicy.averageBandwidth",
               "model.trafficShapingPolicyModel.outShapingPolicy.peakBandwidth",
               "model.trafficShapingPolicyModel.outShapingPolicy.burstSize"]
         };

         let failoverPage: WizardPage = {
            id: DvpgCreateWizardPages.PAGES.FAILOVER_POLICY,
            title: this.i18nService.getString("DvsUi", "dvpg.policy.failover.title"),
            description: this.i18nService.getString("DvsUi",
                  "dvpg.policy.failover.description"),
            contentUrl: "dvs-ui/resources/dvs/dvpg/manage/views/dvpgFailoverPolicyPage.html",
            onCommit: this.simpleValidationCommit((): any => {
               return this.dvpgFailoverPolicyPageValidator.getValidationError(wizardScope);
            }, wizardScope.wizardConfig),
            validationParams: [
               "model.failoverPolicyModel.loadBalancing",
               "model.failoverPolicyModel.beaconProbing",
               "model.failoverPolicyModel.notifySwitches",
               "model.failoverPolicyModel.rollingOrder",
               "model.failoverPolicyModel.failoverOrder.activeUplinkPortNames",
               "model.failoverPolicyModel.failoverOrder.standbyUplinkPortNames",
               "model.failoverPolicyModel.failoverOrder.unusedUplinkPortNames"]
         };

         let monitoringPage: WizardPage = {
            id: DvpgCreateWizardPages.PAGES.MONITORING,
            title: this.i18nService.getString("DvsUi", "dvpg.policy.monitoring.title"),
            description: this.i18nService.getString("DvsUi",
                  "dvpg.policy.monitoring.description"),
            contentUrl: "dvs-ui/resources/dvs/dvpg/manage/views/dvpgMonitoringPage.html",
            onCommit: this.simpleValidationCommit((): any => [], wizardScope.wizardConfig),
            validationParams: ["model.monitoringPolicyModel.ipfixEnabled"]
         };

         let miscPage: WizardPage = {
            id: DvpgCreateWizardPages.PAGES.MISC,
            title: this.i18nService.getString("DvsUi", "dvpg.policy.misc.title"),
            description: this.i18nService.getString("DvsUi",
                  "dvpg.policy.misc.description"),
            contentUrl: "dvs-ui/resources/dvs/dvpg/manage/views/dvpgMiscPage.html",
            onCommit: this.simpleValidationCommit((): any => [], wizardScope.wizardConfig),
            validationParams: ["model.miscPolicyModel.arePortsBlocked"]
         };

         let readyToCompletePage: WizardPage = {
            id: DvpgCreateWizardPages.PAGES.READY_TO_COMPLETE,
            title: this.i18nService.getString("DvsUi",
                  "dvpg.create.finish.title"),
            description: this.i18nService.getString("DvsUi",
                  "dvpg.create.finish.description"),
            contentUrl: "dvs-ui/resources/dvs/dvpg/create/views/" +
                  "dvpgCreateReadyToCompletePage.html",
            onCommit: () => {
               return true;
            },
            validationParams: []
         };

         return [nameAndLocationPage,
            generalPropertiesPage,
            securityPolicyPage,
            trafficShapingPage,
            failoverPage,
            monitoringPage,
            miscPage,
            readyToCompletePage];
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .service("dvpgCreateWizardPages", DvpgCreateWizardPages);
}
