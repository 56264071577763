/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
(function () {
   'use strict';
   angular.module('com.vmware.vsphere.client.storage').controller(
         'ConnectivityAndMultipathingViewController', ConnectivityAndMultipathingViewController);

   ConnectivityAndMultipathingViewController.$inject = [
      '$scope',
      'vuiConstants',
      'i18nService',
      'dataService',
      'columnRenderersRegistry',
      'defaultUriSchemeUtil',
      'navigation',
      'datagridActionBarService'
   ];

   function ConnectivityAndMultipathingViewController($scope, vuiConstants,
         i18nService, dataService, columnRenderersRegistry, defaultUriSchemeUtil, navigation, datagridActionBarService) {

      var datastoreConnectivityProperty = 'connectivityWithHost:vmfsDatastoreConnectivity';
      var isForceMountedVmfsDatastoreProperty = 'datastore:isForceMountedVmfsDatastore';

      var self = this;
      self.datastoreId = navigation.getRoute().objectId;
      self.isForceMountedVmfsDatastore = false;
      self.headerOptions = {
         title: i18nService.getString('StorageUi', 'datastore.manage.connectivity.connectivityAndMultipathing')
      };
      self.hostConnectivityLoading = false;

      self.liveRefreshProperties = [
         'host'
      ];

      self.datagridOptions = {
         height: '100%',
         columnDefs: getColumnDefinitions(),
         sortMode: vuiConstants.grid.sortMode.SINGLE,
         selectionMode: vuiConstants.grid.selectionMode.SINGLE,
         data: [],
         selectedItems: [],
         resizable: true,
         actionBarOptions: {actions: []}
      };

      function updateActionBar() {

         var actionBarHostContext = getActionBarHostContext();

         if (!actionBarHostContext) {
            self.datagridOptions.actionBarOptions = { actions: []};
            return;
         }

         datagridActionBarService.updateActionBar(
            self.datagridOptions,
            [actionBarHostContext],
            [
               {
                  actionId: 'vsphere.core.datastore.actions.mountVmfsSingleHost',
                  getActionInvocationContext: function() {
                     return {
                        datastoreId: self.datastoreId
                     };
                  },
                  isActionAvailable: function(actionDef) {
                     var hostData = getSelectedHostData();
                     return !self.isForceMountedVmfsDatastore && actionDef.available && hostData &&
                        !hostData.isMounted && hostData.isHostConnected;
                  }
               },
               {
                  actionId: 'vsphere.core.datastore.actions.unmountVmfsSingleHost',
                  getActionInvocationContext: function() {
                     return {
                        datastoreId: self.datastoreId
                     };
                  },
                  isActionAvailable: function(actionDef) {
                     var hostData = getSelectedHostData();
                     return !self.isForceMountedVmfsDatastore && actionDef.available && hostData &&
                           hostData.isMounted && hostData.isHostConnected && hostData.isAccessible;
                  }
               }
            ]);
      }

      function getActionBarHostContext() {
         return getSelectedHostId() || getFirstHostId();
      }

      function getFirstHostId() {
         if (self.datagridOptions.data && self.datagridOptions.data.length > 0) {
            return defaultUriSchemeUtil.getVsphereObjectId(self.datagridOptions.data[0].hostRef);
         }
         return null;
      }

      function getSelectedHostId() {
         var selectedHostData = getSelectedHostData();
         if (selectedHostData) {
            return defaultUriSchemeUtil.getVsphereObjectId(selectedHostData.hostRef);
         }
         return null;
      }

      function getSelectedHostData() {
         if (self.datagridOptions.selectedItems && self.datagridOptions.selectedItems.length === 1) {
            return self.datagridOptions.selectedItems[0];
         }
         return null;
      }

      $scope.$watch(function() {
         return self.datagridOptions.selectedItems;
      }, function(newValue, oldValue) {
         if (newValue !== oldValue) {
            if (!newValue || !newValue.length) {
               $scope.masterDetailsViewContext.selectedItems = null;
            } else {
               var selectedHostId = defaultUriSchemeUtil.getVsphereObjectId(newValue[0].hostRef);
               $scope.masterDetailsViewContext.selectedItems = [selectedHostId];
               $scope.masterDetailsViewContext.isSelectedHostConnected = newValue[0].isHostConnected;
               $scope.masterDetailsViewContext.isDatastoreAccessible = newValue[0].isAccessible;
            }

            updateActionBar();
         }
      });

      $scope.preselectComparator = function(gridDataItem) {
         if (!$scope.masterDetailsViewContext.selectedItems ||
               !$scope.masterDetailsViewContext.selectedItems.length || !gridDataItem) {
            return false;
         }

         return defaultUriSchemeUtil.getVsphereObjectId(gridDataItem.hostRef) ===
               $scope.masterDetailsViewContext.selectedItems[0];
      };

      self.refresh = function () {
         self.hostConnectivityLoading = true;
         dataService.getProperties(self.datastoreId, [datastoreConnectivityProperty, isForceMountedVmfsDatastoreProperty])
               .then(function (response) {
                  if (response && response[datastoreConnectivityProperty]) {
                     self.datagridOptions.data = response[datastoreConnectivityProperty];
                  }
                  self.isForceMountedVmfsDatastore = response && response[isForceMountedVmfsDatastoreProperty];
                  updateActionBar();
               })
               .finally(function () {
                  self.hostConnectivityLoading = false;
               });
      };

      self.refresh();

      function getColumnDefinitions() {
         return [
            {
               displayName: i18nService.getString('StorageUi', 'datastore.manage.connectivity.hostList.name'),
               field: 'hostFormattedName',
               width: '150px',
               template: function (dataItem) {
                  var objectLinkRenderer = columnRenderersRegistry.getColumnRenderer('object-link');
                  var modifiedData = {
                     hostName: dataItem.hostFormattedName,
                     hostId: defaultUriSchemeUtil.getVsphereObjectId(dataItem.hostRef)
                  };
                  return objectLinkRenderer(['hostId', 'hostName', 'vsphere-icon-host'], modifiedData);
               }
            },
            {
               displayName: i18nService.getString('StorageUi', 'datastore.manage.connectivity.hostList.mounted'),
               field: 'isMounted',
               width: '150px',
               type: 'boolean',
               template: function (dataItem) {
                  var localizationKey = dataItem.isMounted === true ? 'datastore.mounted' : 'datastore.unmounted';
                  return i18nService.getString('StorageUi', localizationKey);
               }
            },
            {
               displayName: i18nService.getString('StorageUi', 'datastore.manage.connectivity.hostList.datastoreConnectivity'),
               field: 'isAccessible',
               width: '150px',
               type: 'boolean',
               template: function (dataItem) {
                  var localizationKey = dataItem.isAccessible === true ? 'datastore.connected' : 'datastore.notConnected';
                  return i18nService.getString('StorageUi', localizationKey);
               }
            },
            {
               displayName: i18nService.getString('StorageUi', 'datastore.manage.connectivity.hostList.mountPoint'),
               field: 'mountPoint',
               width: '150px',
               template: "#:mountPoint#"
            }
         ];
      }
   }
})();
