namespace network_ui {
    export class TrafficShapingPageModel {

        public enabled:boolean;
        public averageBandwidth:number;
        public peakBandwidth:number;
        public burstSize:number;

        public dirty: boolean = false;
        public initialState: TrafficShapingPageModel;
    }
}
