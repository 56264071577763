/* Copyright 2019 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace h5_vm {
   import VmProvisioningPageService = h5_vm.VmProvisioningPageService;
   import VmProvisioningWizardPage = h5_vm.VmProvisioningWizardPage;
   export class VmProvisioningVappPropertiesPageService implements VmProvisioningPageService {
      static readonly $inject: string[] = [
         "i18nService",
         "vmVuiWizardPageBuilder",
         "vmProvisioningVappPropertiesPageModel"
      ];

      constructor(private i18nService: any,
            private vmVuiWizardPageBuilder: any,
            private vmProvisioningVappPropertiesPageModel: any) {
      }

      build(wizardScope: any): VmProvisioningWizardPage {
         let pageModel = new this.vmProvisioningVappPropertiesPageModel(
               wizardScope.vmParams);

         wizardScope.vappPropertiesPageModel = pageModel;

         return this.vmVuiWizardPageBuilder.buildVuiWizardPage(wizardScope.config, {
            title: this.i18nService.getString("VmUi", "VAppPropertiesProvisioningPage.title"),
            description: this.i18nService.getString("VmUi", "VAppPropertiesProvisioningPage.description"),
            contentUrl: "vm-ui/resources/vm/views/createVmWizard/vm-provisioning-vapp-properties-page.html",
            model: pageModel
         });
      }
   }

   angular.module("com.vmware.vsphere.client.vm")
         .service("vmProvisioningVappPropertiesPageService",
               VmProvisioningVappPropertiesPageService);
}
