/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace dvs_ui {

   import IPromise = angular.IPromise;

   export class DvsEnableFeatureComponent {

      public bindings: any;
      public controller: any;
      public templateUrl: string;

      constructor() {
         this.bindings = {
            model: "<",
            feature: "<",
         };

         this.controller = DvsEnableFeatureController;
         this.templateUrl =
               "dvs-ui/resources/dvs/upgrade/components/" +
               "dvsEnableFeatureTemplate.html";
      }
   }

   class DvsEnableFeatureController {

      static $inject = [
            "i18nService",
            "dvsUpgradeWizardConstants"
      ];

      public model: DvsUpgradeWizardModel;
      public feature: string;

      private signPostConfig: any;
      private featureLabel: string;
      private featureProperty: string;
      private featureInfo: string;
      private i18n: any;

      constructor(private i18nService: any,
                  private dvsUpgradeWizardConstants: any) {
         this.i18n = this.i18nService.getString;
      }

      $onInit(): void {
         switch(this.feature) {
            case this.dvsUpgradeWizardConstants.features.LACP:
               this.featureLabel = this.i18n(
                     "DvsUi", "DvsLacpPrerequisitesPage.feature.label");
               this.featureProperty = "isExplicitLacpUpgradeEnabled";
               this.featureInfo = this.i18n(
                     "DvsUi", "DvsLacpPrerequisitesPage.feature.description");
               this.signPostConfig = {
                  message: this.i18n("DvsUi", "DvsLacpPrerequisitesPage.info"),
                  title: this.i18n("DvsUi", "DvsLacpPrerequisitesPage.title"),
                  class: "dvs-upgrade-info-signpost"
               };
               break;
            case this.dvsUpgradeWizardConstants.features.NIOC:
               this.featureLabel = this.i18n(
                     "DvsUi", "DvsNiocPrerequisitesPage.feature.label");
               this.featureInfo = this.i18n(
                     "DvsUi", "DvsNiocPrerequisitesPage.feature.description");
               this.featureProperty = "isExplicitNiocUpgradeEnabled";
               this.signPostConfig = {
                  message: this.i18n("DvsUi", "DvsNiocPrerequisitesPage.info"),
                  title: this.i18n("DvsUi", "DvsNiocPrerequisitesPage.title"),
                  class: "dvs-upgrade-info-signpost"
               };
               break;
            default:
               break;
         }

      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .component("dvsEnableFeature", new DvsEnableFeatureComponent());
}
