/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace dvs_ui {

   export class DvpgCreateWizardFlowManager {

      public static $inject = ["pageStateManagementService"];

      private pages: WizardPage[];

      constructor(private pageStateManagementService: any) {
      }

      public init(wizardScope: DvpgCreateWizardScope): void {
         this.pageStateManagementService.registerWizardForDefaultStateManagementStrategy(wizardScope);
         this.pages = wizardScope.wizardConfig.pages;

         wizardScope.$watchGroup([
                  "model.isPolicyConfigurationEnabled",
                  "model.monitoringPolicyModel.ipfixSupported"],
               (newValues: any[]) => this.updatePagesVisibility.apply(this, newValues));
      };

      private updatePagesVisibility(isPolicyConfigurationEnabled: boolean,
                                    ipfixSupported: boolean) {
         this.togglePageVisibility(DvpgCreateWizardPages.PAGES.SECURITY_POLICY, isPolicyConfigurationEnabled);
         this.togglePageVisibility(DvpgCreateWizardPages.PAGES.TRAFFIC_SHAPING, isPolicyConfigurationEnabled);
         this.togglePageVisibility(DvpgCreateWizardPages.PAGES.FAILOVER_POLICY, isPolicyConfigurationEnabled);
         this.togglePageVisibility(DvpgCreateWizardPages.PAGES.MONITORING, isPolicyConfigurationEnabled && ipfixSupported);
         this.togglePageVisibility(DvpgCreateWizardPages.PAGES.MISC, isPolicyConfigurationEnabled);
      }

      private togglePageVisibility(pageId: string, isVisible: boolean) {
         let page = _.findWhere(this.pages, {id: pageId});
         this.pageStateManagementService.togglePageVisibility(page, isVisible);
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .service("dvpgCreateWizardFlowManager", DvpgCreateWizardFlowManager);
}
