namespace h5_vm {

   import Privileges = h5_vm.Privileges;
   export class VmHardwareScsiControllerService {

      static $inject: string[] = [
         'vmHardwareUtil'];

      constructor(protected vmHardwareUtil: any) {
      }

      isEditScsiControllerDisabled(powerState: boolean, privileges: Array<string>, isNew: boolean) {
         if (isNew) {
            return false;
         }
         return this.vmHardwareUtil.isVmPoweredOn(powerState) || !this.hasPrivilegesToEdit(privileges, isNew);
      }

      hasPrivilegesToEdit(privileges: Array<string>, skipPrivilegeCheck: boolean): boolean {
         if(skipPrivilegeCheck) {
            return true;
         }

         return this.vmHardwareUtil.checkPrivileges(privileges, [Privileges.VM_EDITDEVICE_PRIVILEGE]);
      }

      hasAddRemoveDevicePrivilege(privileges: Array<string>): boolean {
         return this.vmHardwareUtil.checkPrivileges(privileges, [Privileges.VM_ADDREMOVEDEVICE_PRIVILEGE]);
      }

   }

   angular.module('com.vmware.vsphere.client.vm')
         .service('vmHardwareScsiControllerService', VmHardwareScsiControllerService);
}

