/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace network_ui {
   import LinkSpeedDuplex = com.vmware.vim.binding.vim.host.PhysicalNic$LinkSpeedDuplex;

   export class EditPnicDialogComponent {

      public bindings: any;
      public controller: any;
      public templateUrl: string;

      constructor() {
         this.bindings = {
            pageModel: "<",
            validator: "<"
         };
         this.controller = EditPnicDialogController;
         this.templateUrl = "network-ui/resources/network/views/host/pnic/edit/editPnicDialogTemplate.html";
      }
   }

   class EditPnicDialogController {

      static $inject = ["i18nService"];

      private i18n: any;

      private pageModel: EditPnicDialogModel;

      private linkSpecificationOptions: any;

      private sriovOptions: any;

      private validator: any;

      constructor(private i18nService: any) {
      }

      $onInit(): void {
         this.i18n = this.i18nService.getString;
         this.linkSpecificationOptions = this.buildLinkSpecificationOptions();
         this.sriovOptions = this.buildSriovOptions();
      }

      public onSriovStatusChanged(): void {
         if (this.pageModel.sriovEnabled && !this.pageModel.numVirtualFunctionsRequested) {
            this.pageModel.numVirtualFunctionsRequested = 1;
         }
      }

      public validateNumberOfVirtualFunctions = (): string[] =>  {
         let errors: string[] = [];

         if (!this.pageModel.sriovEnabled) {
            return [];
         }

         if (this.pageModel.numVirtualFunctionsRequested < 1
               || this.pageModel.numVirtualFunctionsRequested >
               this.pageModel.maxVirtualFunctionSupported) {
            errors.push(this.i18n("H5NetworkUi", "EditPnicView.sriov.errorMessage",
                  this.pageModel.maxVirtualFunctionSupported));
         }

         return errors;
      }

      private buildLinkSpecificationOptions(): any[] {
         let options: any[] = [];

         if (this.pageModel.linkSpecification) {
            this.pageModel.linkSpecification.forEach((linkSpeed) => {
               options.push(
                     {
                        label: this.buildLinkSpeedLabel(linkSpeed),
                        value: linkSpeed
                     });
            });
         }
         return options;
      }

      private buildSriovOptions(): any[] {
         let options: any[] = [];

         if (this.pageModel.sriovCapable) {
            options.push(
                  {
                     label: this.i18n("H5NetworkUi", "EditPnicView.sriov.status.enabled"),
                     value: true
                  });
            options.push(
                  {
                     label: this.i18n("H5NetworkUi", "EditPnicView.sriov.status.disabled"),
                     value: false
                  });
         }
         return options;
      }

      private buildLinkSpeedLabel(linkSpeed: LinkSpeedDuplex): string {
         // full duplex
         if (linkSpeed.duplex) {
            return this.i18n("NetworkUi", "PnicView.mbFullDuplex", linkSpeed.speedMb);
         }
         // half duplex
         if (!linkSpeed.duplex && linkSpeed.speedMb > 0) {
            return this.i18n("NetworkUi", "PnicView.mbHalfDuplex", linkSpeed.speedMb);
         }

         return this.i18n("NetworkUi", "PnicView.autonegotiate");
      }
   }

   angular.module("com.vmware.vsphere.client.network")
         .component("editPnicDialog", new EditPnicDialogComponent());
}
