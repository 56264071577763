/* Copyright 2015 VMware, Inc. All rights reserved. -- VMware Confidential */

(function () {
   'use strict';

   angular
      .module('com.vmware.vsphere.client.vm')
      .controller('VmProvisioningComputeResourceController', VmProvisioningComputeResourceController);

   VmProvisioningComputeResourceController.$inject = ['$scope', 'i18nService',
      'zoneDigestPatchService', 'treeViewErrorConstants'];

   function VmProvisioningComputeResourceController($scope, i18nService,
         zoneDigestPatchService, treeViewErrorConstants) {
      var self = this;

      $scope.$on('$destroy', function() {
         setLoadingIndicator(false);
      });

      $scope.computeResourcePageModel.resetForm();
      self.title = i18nService.getString('VmUi', 'SelectResourcePoolProvisioningPage.Compatibility');
      self.description = i18nService.getString('VmUi', 'SelectResourcePoolProvisioningPage.Description');
      self.pageReady = false;
      setLoadingIndicator(true);
      $scope.computeResourcePageModel.getTreeType().then(function (treeType) {
         self.treeType = treeType;
         self.pageReady = true;
         if (!self.preselectedNode) {
            setLoadingIndicator(false);
         }
      });
      self.rootObjReference = $scope.computeResourcePageModel.getTreeRootReference();
      self.preselectedNode = $scope.computeResourcePageModel.form.selectedComputeResourceId;
      self.config = {
         preselectRoot: false
      };

      var lastValidationId = 0;

      self.onChange = function(objectId, objectName) {
         $scope.wizardManager.clearValidationBanner();

         $scope.computeResourcePageModel.form.selectedComputeResourceId = objectId;
         $scope.computeResourcePageModel.form.selectedComputeResourceName = objectName;

         $scope.config.loadingMessage = i18nService.getString('CommonUi', 'wizard.validating');
         self.validatingSelectedCrAriaLabel =
            i18nService.getString('VmUi',
               'ProvisioningWizard.selectCrPage.validatingAriaLabelFormat',
               $scope.computeResourcePageModel.form.selectedComputeResourceName);

         var validationId = ++lastValidationId;

         setLoadingIndicator(false);
         setIsValidating(true);
         $scope.computeResourcePageModel.validateSelection()
               .then(function(compatibilityResults) {
                  if(validationId === lastValidationId) {
                     self.contents = compatibilityResults;
                  }
               })
               .then(function() {
                  if(validationId === lastValidationId) {
                     setIsValidating(false);
                  }
                  zoneDigestPatchService.digest();
               });
      };

      self.onError = function(error) {
         if (error.name === treeViewErrorConstants.SELECTION.name) {
            setLoadingIndicator(false);
         }
      };

      function setLoadingIndicator(setting) {
         $scope.config.loading = setting;
      }

      function setIsValidating(isLoading) {
         self.isValidating = !!isLoading;
      }
   }
})();
