angular.module('com.vmware.vsphere.client.host').constant('addHostWizardConstants',
   {
      RESOURCE_LOCATION_ROOT: 'clusterRootResourcePool',
      RESOURCE_LOCATION_NEW: 'newResourcePool',
      LICENSE_RESOURCE_MODEL : 'License',
      LICENSE_PROPERTY : 'license',
      LICENSE_TYPE_PROPERTY : 'licenseType',
      LICENSE_SCOPE_PROPERTY : 'scope',
      LICENSE_SERIAL_KEY_TYPE : 'SERIAL_KEY'
   }
);
