namespace h5_vm {

   import Privileges = h5_vm.Privileges;
   import GuestOsDescriptor = com.vmware.vim.binding.vim.vm.GuestOsDescriptor;
   import VmHardwareUtil = h5_vm.VmHardwareUtil;
   import VmConfigContext = com.vmware.vsphere.client.vm.config.VmConfigContext;
   class SelectOption { id: string; name: string; }; // option for html select

   export class VmGeneralOptionsService {

      private i18n: Function;
      static $inject = [
         "i18nService",
         "vmHardwareUtil",
         "gosFamilyConstants",
         "dataService"
      ];

      constructor(private i18nService: any,
                  private vmHardwareUtil: VmHardwareUtil,
                  private gosFamilyConstants: any,
                  private dataService: any) {
         this.i18n = i18nService.getString;
      }

      private hasPrivilegesToRename(privileges: Array<string>): boolean {
         return this.vmHardwareUtil.checkPrivileges(privileges, [Privileges.VM_CONFIG_RENAME]);
      }

      private hasPrivilegesToEditGos(privileges: Array<string>): boolean {
         return this.vmHardwareUtil.checkPrivileges(privileges, [Privileges.VM_CONFIG_SETTINGS]);
      }

      public familyObject(id: any): SelectOption {
         return {
            id: id,
            name: this.i18n('VmUi', 'GeneralOptions.gos_family.' + id)
         };
      }

      isEditNameDisabled(privileges: Array<string>, editMode: boolean): boolean {
         return editMode ? !this.hasPrivilegesToRename(privileges) : false;
      }

      isEditGOSDisabled(vmConfigContext: VmConfigContext): boolean {
         if (VmHardwareUtil.isPoweredOff(vmConfigContext)) {
            return !this.hasPrivilegesToEditGos(vmConfigContext.privileges);
         } else {
            return true;
         }
      }

      getGosFamilyOptions(): SelectOption[] {
         return [
            this.familyObject(this.gosFamilyConstants.WINDOWS),
            this.familyObject(this.gosFamilyConstants.LINUX),
            this.familyObject(this.gosFamilyConstants.OTHER)
         ];
      }

      public getFamilyVersions(family:string, array:GuestOsDescriptor[]):GuestOsDescriptor[] {

         if (!array) {
            return [];
         }

         let retVal:GuestOsDescriptor[] = [];
         for (let item of array) {

            let osFamily = this.simplifyFamily(item.family);
            family = this.simplifyFamily(family);

            if (osFamily === family) {
               retVal.push(item);
            }

         }
         return retVal;
      }

      public simplifyFamily(osFamily:string):string {
         if (osFamily !== "windowsGuest" && osFamily !== "linuxGuest") {
            osFamily = "otherGuestFamily";
         }
         return osFamily;
      }

      public getVersionsOptions(array: GuestOsDescriptor[]): SelectOption[] {
         let retVal: SelectOption[] = [];
         for (let item of array) {
            const pair: SelectOption = {name: item.fullName, id: item.id};
            retVal.push(pair);
         }
         return retVal;
      }

      public findDescriptor(guestId:string, array:GuestOsDescriptor[]):GuestOsDescriptor|null {
         if (!array || !guestId) {
            return null;
         };

         for(let item of array) {
            if (guestId === item.id) {
               return item;
            }
         }
         return null;
      }

      public getAllGosVersionsPromise(vmId: string): any {
         return this.dataService.getProperties(vmId, ["guestOsList"]);
      }
   }

   angular.module("com.vmware.vsphere.client.vm")
         .service("vmGeneralOptionsService", VmGeneralOptionsService);
}
