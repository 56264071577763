(function() {
   'use strict';
   angular.module('com.vmware.vsphere.client.host').controller('EditAdvancedSystemSettingsController',
         EditAdvancedSystemSettingsController);

   EditAdvancedSystemSettingsController.$inject = ['$scope',
      'advancedSystemSettingsService', 'vuiConstants', 'i18nService',
      'resourceService'];

   function EditAdvancedSystemSettingsController($scope,
         advancedSystemSettingsService, vuiConstants, i18nService,
         resourceService) {

      $scope.modalOptions.onSubmit = onSubmit.bind(this);
      var hostId = $scope.modalOptions.dialogData.availableTargets[0];
      var self = this;
      var allProperties = [];
      self.selectedSetting = {};
      self.validationMap = {};
      self.gridOptions = advancedSystemSettingsService.getGridOptions(onSave, customValidation);
      self.filterPlaceholderText = i18nService.getString('Common',
            'filter.label');
      self.filterSettings = filterSettings;
      self.searchTerm = '';
      $scope.hasValidationError = false;

      self.preselectComparator = function(dataGridItem) {
         var preselect = false;
         var selectedItems = $scope.modalOptions.dialogData.selectedItems;
         if (selectedItems && !_.isEmpty(selectedItems)) {
            preselect = dataGridItem.key === selectedItems[0].key;
         }
         return preselect;
      };

      loadGridData();

      $scope.$watch(function () {
               return self.gridOptions.selectedItems;
            },
            function (selectedItems) {
               self.selectedSetting = advancedSystemSettingsService.getSelectedSettingData(
                     selectedItems);
            });


      function onSubmit() {
         if ($scope.hasValidationError) {
            $scope.modalOptions.alerts = [{
               text: i18nService.getString('HostUi', 'advancedSettings.validationError'),
               type: vuiConstants.validationBanner.type.ERROR
            }];
            return false;
         } else {
            if ($scope.changedHostAdvancedSettings) {
               advancedSystemSettingsService.updateAdvancedSystemSettings(hostId,
                     $scope.changedHostAdvancedSettings);
               $scope.changedHostAdvancedSettings = null;
            }
            return true;
         }
      }

      function loadGridData() {
         resourceService.getResourceInfo(hostId, 'advancedSettings').then(
               function(hostProperties) {
                  allProperties = hostProperties.properties;
                  self.gridOptions.data = hostProperties.properties;
               });
      }

      function customValidation(input) {
         var validationItemName = input.parent().parent().find('td:first-child').text();
         var selectedItem = self.gridOptions.selectedItems[0];
         if (selectedItem !== undefined &&
               validationItemName === selectedItem.key && !self.validationMap[validationItemName]) {
            self.validationMap[validationItemName] = {
               type: selectedItem.type,
               staticValueSpace: advancedSystemSettingsService.getOptionValueSpec(
                     selectedItem.staticValueSpace)
            };
         }

         var validationRules = self.validationMap[validationItemName];
         var inputData = input.val();
         var validationResult = advancedSystemSettingsService.getValidationResult(
               validationRules, inputData);

         if (validationResult.status === false) {
            $scope.hasValidationError = true;
            input.attr("data-validationFunction-msg", validationResult.message);
         }
         else {
            $scope.hasValidationError = false;
         }
         return validationResult.status;
      }

      function onSave(event) {
         var optionValue = advancedSystemSettingsService.createOptionValue(event);
         if (!$scope.changedHostAdvancedSettings) {
            $scope.changedHostAdvancedSettings = {};
         }
         $scope.changedHostAdvancedSettings[optionValue.key] = optionValue;
      }

      function filterSettings() {
         var search = self.searchTerm.toLowerCase();
         self.gridOptions.data = allProperties.filter(function(property) {
            var value = property.value || '';
            return property.key.toLowerCase().indexOf(search) !== -1 ||
                  value.toLowerCase().indexOf(search) !== -1;
         });
      }
   }
})();
