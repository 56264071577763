namespace h5_vm {
   class GosRegistrationInformationPageController {
      static $inject = ["i18nService", "$q"];

      ownerNameLabel: string;
      organizationNameLabel: string;
      emptyOwnerNameTooltip: string;
      emptyOrganizationNameTooltip: string;
      model: GosRegistrationInformationPageModel;
      ownerNameInvalid: boolean = false;
      organizationNameInvalid: boolean = false;

      constructor(private i18nService: any, private $q: any) {
      }

      $onInit(): void {
         this.ownerNameLabel = this.i18nService.getString(
               "VmUi", "guestOsCustomization.new.registrationInformationPage.ownerName");
         this.organizationNameLabel = this.i18nService.getString(
               "VmUi", "guestOsCustomization.new.registrationInformationPage.organizationName");
         this.emptyOwnerNameTooltip = this.i18nService.getString(
               "VmUi", "customizationSpec.registrationInfoPage.emptyOwnerNameError");
         this.emptyOrganizationNameTooltip = this.i18nService.getString(
               "VmUi", "customizationSpec.registrationInfoPage.emptyOrgNameError");
         this.model.onPageCommit = (): angular.IPromise<Array<string>> => {
            return this.onCommit();
         };
      }

      $onDestroy(): void {
         this.model.onPageCommit = undefined;
      }

      validateOwnerName() {
         this.ownerNameInvalid = !this.model.ownerName.trim();
      }

      validateOrganizationName() {
         this.organizationNameInvalid = !this.model.organizationName.trim();
      }

      private onCommit(): angular.IPromise<Array<string>> {
         this.validateOwnerName();
         this.validateOrganizationName();
         let errors: string[] = [];
         if (this.ownerNameInvalid) {
            errors.push(this.emptyOwnerNameTooltip);
         }
         if (this.organizationNameInvalid) {
            errors.push(this.emptyOrganizationNameTooltip);
         }
         return this.$q.resolve(errors);
      }
   }

   export class GosRegistrationInformationPageComponent implements angular.IComponentOptions {
      public controller: any;
      public templateUrl: string;
      public bindings: any;

      constructor() {
         this.controller = GosRegistrationInformationPageController;
         this.templateUrl =
               "vm-ui/resources/vm/guest-os-customization/pages/registration-information/gos-registration-information-page.component.html";
         this.bindings = {
            model: "<"
         };
      }
   }

   angular
         .module("com.vmware.vsphere.client.vm")
         .component("gosRegistrationInformationPage", new GosRegistrationInformationPageComponent());
}
