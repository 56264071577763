/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace dvs_ui {
   import IRootScopeService = angular.IRootScopeService;
   import DvsAddAndManageHostsSpec = com.vmware.vsphere.client.h5.network.dvs.addhost.model.DvsAddAndManageHostsSpec;
   import DvsHostRemoveSpec = com.vmware.vsphere.client.dvs.api.spec.DvsHostRemoveSpec;

   export class DvsAddHostWizardService {

      public static $inject = [
         "$rootScope",
         "i18nService",
         "vuiWizardService",
         "dvsAddHostWizardSpecFactory",
         "mutationService",
         "dvsAddHostWizardFlowManager",
         "dvsAddHostWizardPages",
         "dataService",
         "wizardValidationService",
         "defaultUriSchemeUtil",
         "$q",
         "vimEntityEscapingService"];

      constructor(private $rootScope: IRootScopeService,
                  private i18nService: any,
                  private vuiWizardService: any,
                  private dvsAddHostWizardSpecFactory: DvsAddHostWizardSpecFactory,
                  private mutationService: any,
                  private dvsAddHostWizardFlowManager: DvsAddHostWizardFlowManager,
                  private dvsAddHostWizardPages: DvsAddHostWizardPages,
                  private dataService: any,
                  private wizardValidationService: any,
                  private defaultUriSchemeUtil: any,
                  private $q: any,
                  private vimEntityEscapingService: any) {
      }

      /**
       * Shows the Add and Manage Hosts wizard.
       *
       * If hostId is specified, the wizard is invoked in the
       * Migrate Networking mode where the specified host is preselected
       * and the first pages of the wizard are not shown.
       */
      public show(dvsId: string, hostId: string): void {

         let wizardScope: DvsAddHostWizardScope =
               this.$rootScope.$new() as DvsAddHostWizardScope;

         wizardScope.model = new DvsAddHostWizardModel();
         wizardScope.model.dvsId = dvsId;

         let isInMigrateNetworkingMode: boolean = hostId !== undefined;

         if (isInMigrateNetworkingMode) {
            // Migrate networking mode.

            wizardScope.model.selectOperationPageModel.selectedOperationType =
                  DvsAddHostWizardConstants.operationType.MIGRATE_NETWORKING;

            let host: HostListItemData = new HostListItemData();
            host.hostRef =
                  this.defaultUriSchemeUtil.getManagedObjectReference(hostId);
            wizardScope.model.selectHostsToManagePageModel.hosts = [host];
         }

         let wizardConfig: WizardConfig = new WizardConfig();
         wizardConfig.title = isInMigrateNetworkingMode
               ? this.i18nService.getString("DvsUi", "actionMigrateHostNetworkingOnDvsTitle")
               : this.i18nService.getString("DvsUi", "actionManageHostOnDvsTitle");
         wizardConfig.loadingMessage = this.i18nService.getString("CommonUi", "wizard.loading");
         wizardConfig.cssClass = "dvs-add-host-wizard";
         wizardConfig.onFinish = () => {
            let operationType: string = wizardScope.model.selectOperationPageModel.selectedOperationType;
            if (operationType === DvsAddHostWizardConstants.operationType.ADD_HOST ||
                  operationType === DvsAddHostWizardConstants.operationType.MANAGE_HOST ||
                  operationType === DvsAddHostWizardConstants.operationType.MIGRATE_NETWORKING) {
               let spec: DvsAddAndManageHostsSpec =
                     this.dvsAddHostWizardSpecFactory.buildSpec(wizardScope.model);

               this.mutationService.apply(
                     wizardScope.model.dvsId,
                     spec._type,
                     spec);
            } else if (operationType === DvsAddHostWizardConstants.operationType.REMOVE_HOST) {
               let spec: DvsHostRemoveSpec =
                     this.dvsAddHostWizardSpecFactory.buildRemoveSpec(wizardScope.model);

               this.mutationService.apply(
                     wizardScope.model.dvsId,
                     spec._type,
                     spec);
            }
            return true;
         };

         wizardConfig.pages =
               this.dvsAddHostWizardPages.createPages(wizardScope);

         wizardScope.wizardConfig = wizardConfig;
         this.wizardValidationService.initWizardValidation(wizardScope);

         this.dvsAddHostWizardFlowManager.init(wizardScope);

         this.vuiWizardService({
            scope: wizardScope,
            configObjectName: "wizardConfig"
         }).show();

         let configVersionPropertyName: string = "config.configVersion";
         let dvsNameProperty: string = "config.name";
         let hostNameProperty: string = "name";

         let dvsPropertiesPromise: any;
         let hostNamePromise: any;
         let dataServiceRequests: any[] = [];

         dvsPropertiesPromise = this.dataService.getProperties(
               wizardScope.model.dvsId, [dvsNameProperty, configVersionPropertyName]);

         dataServiceRequests.push(dvsPropertiesPromise);

         if (isInMigrateNetworkingMode) {
            hostNamePromise = this.dataService.getProperties(hostId, [hostNameProperty]);
            dataServiceRequests.push(hostNamePromise);
         }

         this.$q.all(dataServiceRequests).then((values: any[]) => {
            if (values && values.length > 0) {
               let dvsProperties: any = values[0];
               let hostProperties: any = values[1];

               let dvsName: string | undefined;
               let hostName: string | undefined;

               if (dvsProperties) {
                  if (dvsProperties[configVersionPropertyName]) {
                     wizardScope.model.dvsConfigVersion =
                           dvsProperties[configVersionPropertyName];
                  }

                  if (dvsProperties[dvsNameProperty]) {
                     dvsName = this.vimEntityEscapingService
                           .unescapeSpecialCharacters(
                                 dvsProperties[dvsNameProperty]);
                  }
               }

               if (hostProperties) {
                  if (hostProperties[hostNameProperty]) {
                     hostName = this.vimEntityEscapingService
                           .unescapeSpecialCharacters(
                                 hostProperties[hostNameProperty]);
                  }
               }

               if (isInMigrateNetworkingMode) {
                  if (dvsName && hostName) {
                     wizardConfig.title = this.i18nService.getString(
                           "DvsUi",
                           "actionMigrateHostNetworkingOnDvsTitleWithNames",
                           hostName, dvsName);
                  }
               } else {
                  if (dvsName) {
                     wizardConfig.title =
                           this.i18nService.getString(
                                 "DvsUi",
                                 "actionManageHostOnDvsTitleWithName",
                                 dvsName);
                  }
               }
            }
         });

         // Reset next pages when host selection has changed.
         wizardScope.$watchCollection(() => {
            return wizardScope.model.selectedHosts;
         }, function () {
            wizardScope.model.selectPhysicalAdaptersPageModel.isInitialized = false;
            wizardScope.model.selectVirtualAdaptersPageModel.isInitialized = false;
            wizardScope.model.selectVirtualMachinesPageModel.isInitialized = false;
            wizardScope.model.selectVirtualMachinesPageModel.isMigrateVirtualMachineNetworkingEnabled = false;
         });
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .service("dvsAddHostWizardService", DvsAddHostWizardService);
}
