module hostprofile_ui {

    export interface RemediateHostWizardService {
        invokeWizard(targetUid: string): void;
    }

    class RemediateHostWizardServiceImpl implements RemediateHostWizardService {
        static $inject = [
            "remediateHostPageService",
            "vuiRemediateHostWizard",
            "RemediateHostManager",
            "dataService",
            "$rootScope"
        ];

        constructor(private remediateHostPageService: RemediateHostPageService,
                    private vuiRemediateHostWizard: VuiRemediateHostWizard,
                    private RemediateHostManager: new () => RemediateHostManager,
                    private dataService: any,
                    private $rootScope: any) {
        }

        public invokeWizard(targetUid: string): void {
            let manager: RemediateHostManager = new this.RemediateHostManager();
            manager.setTargetUid(targetUid);
            let wizardScope = this.$rootScope.$new();
            let pages: Array<any> = this.createWizardPages(wizardScope, manager);
            this.dataService
                .getProperties(targetUid, ["name"])
                .then((response: any): void => {
                    if (response && response.name) {
                        let hostName: string = response.name;
                        this.vuiRemediateHostWizard.show(pages, wizardScope, manager, hostName);
                    }
                });
        }

        private createWizardPages(wizardScope: any, manager: RemediateHostManager): Array<any> {
            let pages: Array<any> = [];
            pages.push(this.remediateHostPageService.build(wizardScope, manager));
            return pages;
        }
    }

    angular.module("com.vmware.vsphere.client.hostprofile")
        .service("remediateHostWizardService", RemediateHostWizardServiceImpl);
}
