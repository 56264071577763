/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace h5_client {
   export module common_module_ui {
      /*
       * Tag action service.
       */
      import IRootScopeService = angular.IRootScopeService;
      export class TagActionService {
         static $inject = ["tagService", "clarityModalService", "i18nService", "$rootScope"];

         private i18n: Function;

         constructor(private tagService: any, private clarityModalService: any, private i18nService: any, private $rootScope: IRootScopeService) {
            this.i18n = (key: string, data: any[]) => this.i18nService.getString("CommonModuleUi", key, data);
         }

         /**
          * Confirm and delete a tag.
          */
         public deleteTag(tagMoRef: any) {
            this.tagService.getEntityObjectsCount(tagMoRef).then((count: number) => {
               var modalMessage = this.i18n('tagAction.remove.warning');
               if(count) {
                  modalMessage = modalMessage + '\n' + this.i18n('tagAction.remove.entity.count', count);
               }
               var modalOptions = {
                  title: this.i18n('tagAction.remove.dialog.title'),
                  message: modalMessage,
                  icon: 'vsphere-icon-status-warning',
                  submit: () => {
                     this.tagService.deleteTag(tagMoRef)
                         .then(() => {
                            // deferred promise does not work with $http,
                            // so just using a broadcast.
                            this.$rootScope.$broadcast('refreshTagGrid');
                         });
                  },
                  preserveNewlines: true
               };
               this.clarityModalService.openConfirmationModal(modalOptions);
            });
         }

          /**
           * Create/Edit a tag
           *
           * @param modifyTagData
           * @param tagId (Optional) Needed for edit
           */
         public modifyTag(modifyTagData: ModifyTagData, tagId?: string): void {
            let dialogData: any = {
               modifyTagData: modifyTagData,
               categoryData: {}
            };

            let modalOptions: any = {
               contentTemplate: "common-module-ui/resources/common-module/views/tags/modify-tag-dialog.html",
               title: modifyTagData.mode === ModifyTagMode.ADD ? this.i18n("tag.action.add") : this.i18n("tag.action.edit"),
               defaultButton: "submit",
               onSubmit: modifyTagData.mode === ModifyTagMode.ADD
                    ? this.onTagAdd.bind(this, dialogData)
                    : this.onTagModify.bind(this, tagId, dialogData),
               dialogData: dialogData,
               submitDisabled: true
            };

            if (modifyTagData.mode === ModifyTagMode.EDIT) {
               modalOptions.subTitle = {text: angular.copy(modifyTagData.name)};
            }

            this.clarityModalService.openOkCancelModal(modalOptions);
         }

         private onTagAdd(dialogData: any): boolean {
            return this.tagService
                  .createTag(
                        dialogData.modifyTagData.name,
                        dialogData.modifyTagData.description,
                        dialogData.categoryData.selectedCategory.managedObjectReference)
                  .then((response: any) => {
                     this.$rootScope.$broadcast("refreshTagGrid");
                     return response;
                  });

         }

         private onTagModify(tagId: any, dialogData: any): boolean {
             return this.tagService
                    .editTag(
                           tagId,
                           dialogData.modifyTagData.name,
                           dialogData.modifyTagData.description)
                    .then((response: any) => {
                        this.$rootScope.$broadcast("refreshTagGrid");
                        return response;
                    });
         }
      }

      angular.module("com.vmware.vsphere.client.commonModule").service("tagActionService", TagActionService);
   }
}
