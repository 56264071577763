namespace h5_vm {
   export class DeployVmFromTemplateService {

      private TEMPLATE_NAME_PROPERTY: string = "com.vmware.content.library.ItemModel/name";
      private LIBRARY_NAME_PROPERTY: string = "com.vmware.content.library.ItemModel/libraryName";
      private GOS_TYPE_PROPERTY: string = "com.vmware.content.type.ovf.OvfTemplate/vmTemplate/osType";
      private GOS_DESCRIPTION_PROPERTY: string = "com.vmware.content.type.ovf.OvfTemplate/vmTemplate/osDescription";

      public static $inject: string[] = [
            "contentLibraryService",
            "vmProvisioningNameAndFolderPageService",
            "vmProvisioningCloneCustomizeGuestOsPageService",
            "VmProvisioningComputeResourcePageService",
            "vmProvisioningProductDetailsPageService",
            "VmProvisioningEulaPageService",
            "VmProvisioningDeploymentOptionsPageService",
            "vmProvisioningDatastorePageService",
            "vmProvisioningNetworkPageService",
            "vmProvisioningFinishDeployTemplatePageService",
            "vmProvisioningCustomizeTemplatePageService",
            "vmProvisioningScaleoutConfigurationPageService",
            "vmProvisioningVservicePageService",
            "vmProvisioningUserSettingsPageService"
      ];

      constructor(
            private contentLibraryService: any,
            private vmProvisioningNameAndFolderPageService: any,
            private vmProvisioningCloneCustomizeGuestOsPageService: any,
            private VmProvisioningComputeResourcePageService: any,
            private vmProvisioningProductDetailsPageService: any,
            private VmProvisioningEulaPageService: any,
            private VmProvisioningDeploymentOptionsPageService: any,
            private vmProvisioningDatastorePageService: any,
            private vmProvisioningNetworkPageService: any,
            private vmProvisioningFinishDeployTemplatePageService: any,
            private vmProvisioningCustomizeTemplatePageService: any,
            private vmProvisioningScaleoutConfigurationPageService: any,
            private vmProvisioningVservicePageService: any,
            private vmProvisioningUserSettingsPageService: any) { }

      buildPages(wizardScope: any, defaultTargetId: string,
                 preselectedComputeResourceId: string, preselectedStorageId: string): { pages: Array<any> } {

         this.appendTemplateDetailsToWizardScope(wizardScope);

         let nameAndFolderPage: any = this.vmProvisioningNameAndFolderPageService
               .build(wizardScope, defaultTargetId);
         let customizeGuestOSPage: any = this.vmProvisioningCloneCustomizeGuestOsPageService
               .build(wizardScope);
         let userSettingsPage: any = this.vmProvisioningUserSettingsPageService.build(wizardScope);
         let computeResourcePage: any = this.VmProvisioningComputeResourcePageService
               .build(wizardScope, preselectedComputeResourceId);
         let productDetailsPage: any = this.vmProvisioningProductDetailsPageService
               .build(wizardScope, preselectedComputeResourceId);
         let eulaPage: any = this.VmProvisioningEulaPageService
               .build(wizardScope);
         let deploymentOptionsPage: any = this.VmProvisioningDeploymentOptionsPageService
               .build(wizardScope);
         let datastorePage: any = this.vmProvisioningDatastorePageService
               .build(wizardScope, preselectedStorageId);
         let networkPage: any = this.vmProvisioningNetworkPageService
               .build(wizardScope);
         let finishDeployFromTemplatePage: any = this.vmProvisioningFinishDeployTemplatePageService
               .build(wizardScope, preselectedComputeResourceId);
         let scaleoutPage = this.vmProvisioningScaleoutConfigurationPageService.build(
               wizardScope,
               defaultTargetId,
               preselectedComputeResourceId
         );
         let customizeTemplatePage = this.vmProvisioningCustomizeTemplatePageService.build(
               wizardScope);
         let vservicePage = this.vmProvisioningVservicePageService.build(
               wizardScope,
               defaultTargetId,
               preselectedComputeResourceId);
         return {
            pages: [
               nameAndFolderPage,
               customizeGuestOSPage,
               userSettingsPage,
               computeResourcePage,
               productDetailsPage,
               eulaPage,
               deploymentOptionsPage,
               scaleoutPage,
               datastorePage,
               networkPage,
               customizeTemplatePage,
               vservicePage,
               finishDeployFromTemplatePage
            ]
         };
      }

      private appendTemplateDetailsToWizardScope(wizardScope: any): void {
         let templateId: any = wizardScope.wizardViewData.getTemplateId();

         this.contentLibraryService.getTemplateData(templateId)
               .then((response: any) => {
                  let template: any = {
                     templateName: response[this.TEMPLATE_NAME_PROPERTY],
                     libraryName: response[this.LIBRARY_NAME_PROPERTY],
                     templateId: templateId,
                     gosType: response[this.GOS_TYPE_PROPERTY],
                     gosDescription: response[this.GOS_DESCRIPTION_PROPERTY]
                  };

                  wizardScope.wizardViewData.setSelectedTemplate(template);
               });
      }
   }

   angular.module("com.vmware.vsphere.client.vm")
         .service("deployVmFromTemplateService", DeployVmFromTemplateService);
}
