/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace h5_vm {

   import GuestOsDescriptor = com.vmware.vim.binding.vim.vm.GuestOsDescriptor;
   import VmConfigContext = com.vmware.vsphere.client.vm.config.VmConfigContext;
   import VmWorkflowMode = h5_vm.VmWorkflowMode;
   import PmemUtilService = h5_vm.PmemUtilService;
   import VirtualNvdimmService = h5_vm.VirtualNvdimmService;

   export class VmHardwareVirtualNvdimm implements ng.IComponentOptions {
      bindings: any;
      controller: any;
      templateUrl: string;

      constructor() {
         this.bindings = {
            nvdimmDevice: "<",
            removeCallback: "&",
            vmWorkflowMode: "<",
            vmConfigContext: "<",
            virtualMachineDevices: "<",
            selectedGuestOs: "<"
         };
         this.controller = VmHardwareVirtualNvdimmController;
         this.templateUrl =
               "vm-ui/resources/vm/views/settings/vmHardwareSettings/vmHardwareVirtualNvdimm/vm-hardware-virtual-nvdimm.component.html";
      }
   }

   class VmHardwareVirtualNvdimmController implements ng.IComponentController {
      static $inject: string[] = [
            "i18nService",
            "virtualNvdimmService",
            "pmemUtilService",
            "$scope",
            "$rootScope",
            "vmHardwareVirtualDiskService"
      ];

      // Inputs
      public nvdimmDevice: any;
      public removeCallback: Function;
      public vmWorkflowMode: VmWorkflowMode;
      public vmConfigContext: VmConfigContext;
      public virtualMachineDevices: any;
      public selectedGuestOs: GuestOsDescriptor;

      // Accessed from the view/HTML
      public rawNvdimmDevice: any;
      public deviceName: string;
      public memoryOptions: Array<any>;
      public defaultMemoryUnit: string;
      public minAllowedCapacity: number;
      public maxAllowedCapacity: number;
      public growthGranularityMB: number;
      public isGrowthEnabled: boolean;
      public capacityErrorMessages: any;
      public isRemoveDisabled: boolean;

      // When the user deletes the value of the capacity in the vx-value-in-units
      // the capacity of the device becomes "undefined" instead of 0, thus
      // adding this dummy to prevent that.
      public dummyCapacity: number;

      private listenerDeregistrationFuction: Function;
      private hardDiskListenerDeregistrationFuction: Function;

      constructor(private i18nService: any,
            private virtualNvdimmService: VirtualNvdimmService,
            private pmemUtilService: PmemUtilService,
            private $scope: any,
            private $rootScope: any,
            private vmHardwareVirtualDiskService: VmHardwareVirtualDiskService) {
      }

      $onInit() {
         this.memoryOptions = this.getMemoryOptions();
         this.rawNvdimmDevice = this.nvdimmDevice.getCurrentDevice();
         this.deviceName = this.rawNvdimmDevice.deviceInfo.label;
         this.growthGranularityMB = this.virtualNvdimmService.getGrowthGranularityInMB(
               this.vmConfigContext,
               this.selectedGuestOs,
               this.vmWorkflowMode);
         this.isGrowthEnabled = this.virtualNvdimmService.isGrowthSupported(
               this.vmConfigContext,
               this.selectedGuestOs,
               this.vmWorkflowMode);
         this.minAllowedCapacity = this.virtualNvdimmService.getMinimumAllowedCapacity(
               this.vmConfigContext,
               this.selectedGuestOs,
               this.nvdimmDevice);
         this.calculateMaxAllowedCapacity();
         this.isRemoveDisabled = this.virtualNvdimmService.isRemoveDisabled(
               this.vmConfigContext,
               this.selectedGuestOs,
               this.vmWorkflowMode);
         this.defaultMemoryUnit = this.virtualNvdimmService.getDefaultMemoryUnit(
               this.rawNvdimmDevice);
         this.capacityErrorMessages = this.getDefaultErrorMessages(
               this.growthGranularityMB, this.minAllowedCapacity);
         this.dummyCapacity = this.rawNvdimmDevice.capacityInMB;
         this.listenerDeregistrationFuction = this.$scope.$on(
               this.virtualNvdimmService.NVDIMM_REMOVED, (event: any) => {
            this.calculateMaxAllowedCapacity();
         });

         this.hardDiskListenerDeregistrationFuction = this.$scope.$on(
               this.vmHardwareVirtualDiskService.DISK_SIZE_UPDATED, (event: any) => {
            this.calculateMaxAllowedCapacity();
         });

      }

      $onDestroy() {
         if (this.listenerDeregistrationFuction) {
            this.listenerDeregistrationFuction();
         }

         if (this.hardDiskListenerDeregistrationFuction) {
            this.hardDiskListenerDeregistrationFuction();
         }
      }

      onCapacityChange(value: number) {
         this.rawNvdimmDevice.capacityInMB = value;
         this.calculateMaxAllowedCapacity();
         let exceedsGuestMemory: boolean =
               this.virtualNvdimmService.getIsNvmAndRamExceedingGuestOsLimits(
                     this.vmConfigContext,
                     this.selectedGuestOs,
                     this.virtualMachineDevices);

         this.capacityErrorMessages = {
            max: exceedsGuestMemory
                  ? this.i18n("NVDIMM.exceedsGuest1") : this.i18n("NVDIMM.exceedsPMem"),
            min: this.i18n("NVDIMM.noShrink", this.minAllowedCapacity.toString()),
            mod: this.i18n("NVDIMM.growGranularityError",
                  this.growthGranularityMB.toString())
         };
      }


      hasError(): boolean {
         if (!_.isEmpty(this.$scope.vmHardwareNvdimmForm)) {
            return this.$scope.vmHardwareNvdimmForm.$invalid;
         }
         return false;
      }

      removeNvdimm() {
         if (!this.isRemoveDisabled && this.removeCallback) {
            this.nvdimmDevice.setFileOperation("destroy");
            this.removeCallback()(this.nvdimmDevice);
            this.broadcastNvdimmRemoveded();
         }
      }

      revertNvdimmRemoval() {
         this.nvdimmDevice.revertRemoval();
         this.nvdimmDevice.setFileOperation(undefined);
         this.broadcastNvdimmRemoveded();
      }

      getAvailablePmemCapacity(): number {
         return this.pmemUtilService.getAvailablePmemCapacity(
               this.vmConfigContext);
      }

      getSupportedPmemByGuestOs(): number {
         return this.pmemUtilService.getSupportedPmemByGuestOs(this.selectedGuestOs);
      }

      i18n(key: string, param: string = "", bundle: string = "VmUi"): string {
         return this.i18nService.getString(bundle, key, param);
      }

      private calculateMaxAllowedCapacity() {
         this.maxAllowedCapacity = this.virtualNvdimmService.getMaximumAllowedCapacity(
               this.vmConfigContext,
               this.selectedGuestOs,
               this.nvdimmDevice,
               this.virtualMachineDevices);
      }

      private getDefaultErrorMessages(growthGranularityMB: number,
            minAllowedCapacity: number) {
         return {
            max: this.i18n("NVDIMM.exceedsPMem"),
            min: this.i18n("NVDIMM.noShrink", minAllowedCapacity.toString()),
            mod: this.i18n("NVDIMM.growGranularityError", growthGranularityMB.toString())
         };
      }

      private getMemoryOptions(): any[] {
         return [
            {label: this.virtualNvdimmService.MB_UNIT_LOCALIZED, multiplier: 1},
            {label: this.virtualNvdimmService.GB_UNIT_LOCALIZED, multiplier: 1024}
         ];
      }

      private broadcastNvdimmRemoveded() {
         this.$rootScope.$broadcast(this.virtualNvdimmService.NVDIMM_REMOVED);
      }

   }

   angular.module("com.vmware.vsphere.client.vm")
         .component("vmHardwareVirtualNvdimm", new VmHardwareVirtualNvdimm());
}
