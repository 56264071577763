namespace network_ui {

   export class IscsiPortBindingStatusDetailsView {

      public bindings: any;
      public controller: any;
      public templateUrl: string;

      constructor() {
         this.bindings = {
            hostId: "<",
            iscsiPortBindingItem: "<",
            isBoundPortBinding: "<"
         };

         this.controller = IscsiPortBindingStatusDetailsViewController;
         this.templateUrl = "network-ui/resources/network/views/host/iscsi/details/IscsiPortBindingDetailsTab.html";
      }
   }

   class IscsiPortBindingStatusDetailsViewController {

      public static $inject = ["i18nService", "vxPropertyViewService"];

      public i18n: Function;

      public hostId: string;

      public iscsiPortBindingItem: IscsiPortInfoItem;

      public isBoundPortBinding: boolean;

      public propertiesData: any;

      public loading: boolean = false;

      public noDetailsAvailable: boolean;

      constructor(private i18nService: any,
                  private vxPropertyViewService: any) {

         this.i18n = i18nService.getString;
      }

      public $onChanges() {
         this.populatePropertyData();
      }

      private populatePropertyData() {
         if (!this.iscsiPortBindingItem) {
            this.noDetailsAvailable = true;
            return;
         }

         let builder = this.vxPropertyViewService.createPropertyViewBuilder();
         let generalCategory = builder.category("general");
         let generalSection = generalCategory.section("generalSection");

         this.addPropertyValue(generalSection,
               this.i18nService.getString("NetworkUi", "portbinding.portGroupPolicyCompliance"),
               this.iscsiPortBindingItem.policyComplianceStatus);

         this.addComplianceErrors(generalSection);

         if (this.isBoundPortBinding) {
            this.addPropertyValue(generalSection,
                  this.i18nService.getString("NetworkUi", "portbinding.pathStatus"),
                  this.iscsiPortBindingItem.pathStatus);
         }

         this.propertiesData = builder.build();
         this.noDetailsAvailable = false;
      }

      private addComplianceErrors(section: any): void {
         if (!this.iscsiPortBindingItem.isCompliant) {

            if (!this.isBoundPortBinding &&
                  (!this.iscsiPortBindingItem.vnicDevice || !this.iscsiPortBindingItem.pnicDevice)) {

               section.messageBuilder()
                     .text(this.i18nService.getString("NetworkUi", "portbinding.pnicNotAssociatedToVnicMessage"))
                     .icon("vsphere-icon-status-warning")
                     .renderAsHtml(true);
            } else {
               _.forEach(this.iscsiPortBindingItem.complianceErrors, function (error) {
                  section.warning(error.localizedMessage || error.message);
               });
            }
         }
      }

      private addPropertyValue(section: any, propertyName: String, propValue: ImageLabel) {
         return section.propertyBuilder()
               .keyBuilder().text(propertyName)
               .exit()
               .valueBuilder()
               .icon(propValue.icon)
               .text(propValue.label)
               .exit()
               .exit();
      }
   }

   angular.module("com.vmware.vsphere.client.network")
         .component("iscsiPortBindingStatusDetailsView", new IscsiPortBindingStatusDetailsView());
}
