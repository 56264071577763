/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace dvs_ui {

   import DvsSystemTrafficMeterData =
         com.vmware.vsphere.client.h5.network.dvs.resmgmt.systemtraffic.model.DvsSystemTrafficMeterData;

   export class DvsSystemTrafficReservationComponent {

      public bindings: any;
      public controller: any;
      public templateUrl: string;

      constructor() {
         this.bindings = {
            data: "<"
         };

         this.controller = DvsSystemTrafficReservationComponentController;

         this.templateUrl =
               "dvs-ui/resources/dvs/resmgmt/systemtraffic/views/dvsSystemTrafficReservationComponentTemplate.html";
      }
   }

   class DvsSystemTrafficReservationComponentController {

      static $inject = [
            "i18nService",
            "numberFormatterService"
      ];

      public data: DvsSystemTrafficMeterData;

      public meters: any[];

      public maxValue: string;

      public pointers: any[];

      public legendData: any;

      constructor(private i18nService: any,
                  private numberFormatterService: any) {
      }

      public $onChanges(): void {
         if (!this.data) {
            return;
         }

         let reservedPercentage: number =
               (this.data.reserved / this.data.capacity) * 100 || 0;
         let colorClass: string = reservedPercentage <= this.data.ratio
               ? "bandwidth-reservation-satisfied"
               : "bandwidth-reservation-unsatisfied";
         this.meters = [{
            colorClass: colorClass,
            value: reservedPercentage < this.data.ratio
                  ? reservedPercentage
                  : Math.min(reservedPercentage, 100),
            label: `${reservedPercentage}%`
         }];

         this.maxValue = this.numberFormatterService.formatBandwidth(
               this.data.capacity,
               BandwidthUnit.MBITPS,
               BandwidthUnit.GBITPS,
               2,
               false);

         if (this.data.capacity > 0) {
            this.pointers = [{
               colorClass: "bandwidth-reservation-margin",
               value: this.data.ratio,
               size: 19,
               label: this.numberFormatterService.formatBandwidth(
                     this.data.capacity * (this.data.ratio / 100),
                     BandwidthUnit.MBITPS,
                     BandwidthUnit.GBITPS,
                     2,
                     false)
            }];
         } else {
            this.pointers = [];
         }

         this.buildLegendData();
      }

      private buildLegendData(): void {
         this.legendData =  {
            capacity: this.data.capacity,
            allowed: this.data.capacity * (this.data.ratio / 100),
            reserved: this.data.reserved,
            available: this.data.available > 0 ? this.data.available : 0,
            ratio: this.data.ratio
         };
      }
   }

   // in sync with format.ts in ng-next-app
   enum BandwidthUnit { BITPS, KBITPS, MBITPS, GBITPS, TBITPS, AUTO }

   angular.module("com.vmware.vsphere.client.dvs")
         .component("dvsSystemTrafficReservation", new DvsSystemTrafficReservationComponent());
}