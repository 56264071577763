/**
 * @ngdoc service
 * @name com.vmware.vsphere.client.vm.podSelectionSpecService
 * @module com.vmware.vsphere.client.vm
 *
 * @description
 *    Utility service for creating PodSelectionSpec used to generate recommendations
 *    for SDRS placement.
 */
(function () {
   'use strict';
   angular.module('com.vmware.vsphere.client.vm')
         .service('podSelectionSpecService', podSelectionSpecService);

   podSelectionSpecService.$inject = [
      'defaultUriSchemeUtil',
      'managedEntityConstants',
      'diskProvisioningService',
      'storageProfileService'
   ];

   function podSelectionSpecService(defaultUriSchemeUtil,
         managedEntityConstants,
         diskProvisioningService,
         storageProfileService) {
      return {
         createSpec: createSpec,
         createSpecForClone: createSpecForClone
      };

      /**
       * @param newVirtualDisks The newly added virtual disks.
       * @param vmxStorageObject The storage object where the VMX will be placed.
       * @param diskStorageMap Contains the storage information for each disk
       * @returns PodSelectionSpec object containing info on how the vmx and disks will
       *    be placed.
       */
      function createSpec(
            newVirtualDisks,
            vmxStorageObject,
            diskStorageMap) {
         var disksLocatorsByPod = {};
         addDiskLocatorsForNewDisks(newVirtualDisks, diskStorageMap, vmxStorageObject, disksLocatorsByPod);

         var podSelectionSpec = createSpecFromPodMap(vmxStorageObject, disksLocatorsByPod);
         return podSelectionSpec;
      }

      /**
       * @param originalVirtualDisks The original virtual disks, empty when creating
       *    new vm.
       * @param newVirtualDisks The newly added virtual disks.
       * @param vmxStorageObject The storage object where the VMX will be placed.
       * @param provisioningType The provisioning type of the VMX.
       * @param diskStorageMap Contains the storage information for each disk
       * @returns PodSelectionSpec object containing info on how the vmx and disks will
       *    be placed.
       */
      function createSpecForClone(
            originalVirtualDisks,
            newVirtualDisks,
            vmxStorageObject,
            provisioningType,
            diskStorageMap,
            storageVirtualDisks) {
         var disksLocatorsByPod = {};
         addDiskLocatorsForNewDisks(newVirtualDisks, diskStorageMap, vmxStorageObject, disksLocatorsByPod);

         addDiskLocatorsOriginalDisks(originalVirtualDisks, provisioningType, vmxStorageObject,
               disksLocatorsByPod, diskStorageMap, storageVirtualDisks);

         var podSelectionSpec = createSpecFromPodMap(vmxStorageObject, disksLocatorsByPod);
         return podSelectionSpec;
      }

      function createSpecFromPodMap(vmxStorageObject, disksLocatorsByPod) {
         var podSelectionSpec;
         var initialVmConfig = [];
         addVmxConfig(vmxStorageObject, initialVmConfig);
         _.each(disksLocatorsByPod, function (diskConfig, podId) {
            var podRef = defaultUriSchemeUtil.getManagedObjectReference(podId);
            initialVmConfig.push(buildVmPodConfig(
                  diskConfig.diskLocator,
                  podRef,
                  diskConfig.disableSdrs
            ));
         });


         if (initialVmConfig.length > 0) {
            var vmxPodRef = getDiskPod(vmxStorageObject);
            podSelectionSpec = {
               _type: 'com.vmware.vim.binding.vim.storageDrs.PodSelectionSpec',
               storagePod: (vmxPodRef && vmxPodRef.isSdrsEnabled ? vmxPodRef : null),
               initialVmConfig: initialVmConfig
            };
         }
         return podSelectionSpec;
      }

      function addDiskLocatorToPodConfig(diskLocator, diskStorageObject, disksLocatorsByPod) {
         var pod = getDiskPod(diskStorageObject);

         //if not in pod or sdrs disabled we don't have to ask for recommendations
         if (pod && (pod.isSdrsEnabled || pod.drsEnabled)) {

            //if diskDatastoreConfig is defined than it is stored separately
            //else the current disk will be placed together with the vmx
            var podId = defaultUriSchemeUtil.getVsphereObjectId(pod);

            addPodEntryIfNotPresent(disksLocatorsByPod, podId, isSdrsDisabled(diskStorageObject));
            disksLocatorsByPod[podId].diskLocator.push(diskLocator);
         }
      }

      function addDiskLocatorsOriginalDisks(originalVirtualDisks, provisioningType, storageObject,
            disksLocatorsByPod, storageObjPerDisk, storageVirtualDisks) {
         _.each(originalVirtualDisks, function (originalVirtualDisk) {
            var storageSelectorDisk = _.find(storageObjPerDisk, function (disk) {
               return disk.diskKey === originalVirtualDisk.key;
            });
            var backing = storageSelectorDisk && storageSelectorDisk.diskBacking;
            if (!storageSelectorDisk && provisioningType && provisioningType !== diskProvisioningService.SAME_AS_SOURCE) {
               backing = diskProvisioningService.getBackingInfo(provisioningType.type);
            }

            var profile = originalVirtualDisk.profile;

            // use the disks configuration as it was set in the select storage page
            // and use the calculated profile there
            var disk = _.find(storageVirtualDisks, function(disk) {
               return disk.key === originalVirtualDisk.key;
            });
            if (disk) {
               profile = disk.profile;
            }

            var originalVirtualDiskLocator = buildDiskLocator(
                  originalVirtualDisk.key,
                  backing,
                  profile);
            var storageObjToSet = storageSelectorDisk ? storageSelectorDisk.dsObject : storageObject;
            addDiskLocatorToPodConfig(originalVirtualDiskLocator, storageObjToSet, disksLocatorsByPod);
         });
      }

      function addDiskLocatorsForNewDisks(newVirtualDisks, diskStorageMap, vmxStorageObject, disksLocatorsByPod) {

         newVirtualDisks = newVirtualDisks ? newVirtualDisks : [];

         _.each(newVirtualDisks, function (newVirtualDisk) {
            if (newVirtualDisk.createdFromExistingDisk) {
               return;
            }

            var diskStorageObject;
            var diskDatastoreConfig = diskStorageMap ?
                  diskStorageMap[newVirtualDisk.key] : undefined;
            if (diskDatastoreConfig) {
               diskStorageObject = diskDatastoreConfig.dsObject;
            } else {
               diskStorageObject = vmxStorageObject;
            }
            var diskLocator = buildDiskLocator(
                  newVirtualDisk.key,
                  newVirtualDisk.backing,
                  newVirtualDisk.profile);
            addDiskLocatorToPodConfig(diskLocator, diskStorageObject, disksLocatorsByPod);

         });
      }

      function addVmxConfig(vmxStorageObject, initialVmConfig) {
         var vmxPodRef = getDiskPod(vmxStorageObject);
         if (vmxPodRef && vmxPodRef.isSdrsEnabled) {
            initialVmConfig.push(buildVmPodConfig(
                  [],
                  vmxPodRef,
                  isSdrsDisabled(vmxStorageObject)
            ));
         }
         return vmxPodRef;
      }

      function addPodEntryIfNotPresent(disksLocatorsByPod, podId, isSdrsDisabled) {
         if (!disksLocatorsByPod[podId]) {
            disksLocatorsByPod[podId] = {
               disableSdrs: isSdrsDisabled,
               diskLocator: []
            };
         }
      }

      function buildVmPodConfig(diskLocators, storagePod, isSdrsDisabledForVm) {
         var vmPodConfig = {
            _type: "com.vmware.vim.binding.vim.storageDrs.PodSelectionSpec$VmPodConfig",
            disk: diskLocators,
            storagePod: storagePod
         };
         if (isSdrsDisabledForVm) {
            createVmConfigInfoIfNull(vmPodConfig);
            vmPodConfig.vmConfig.enabled = false;
         }

         return vmPodConfig;
      }

      /**
       * Create new instance of the vmConfig if it is <code>null</code>.
       */
      function createVmConfigInfoIfNull(vmPodConfig) {
         if (!vmPodConfig.vmConfig) {
            vmPodConfig.vmConfig = {
               _type: 'com.vmware.vim.binding.vim.storageDrs.VmConfigInfo'
            };
         }
      }

      function buildDiskLocator(diskId, backing, storageProfile) {
         var profileId;
         if (storageProfile) {
            profileId = storageProfile.profileId || storageProfile.id;
         }
         return {
            _type: "com.vmware.vim.binding.vim.storageDrs.PodSelectionSpec$DiskLocator",
            diskId: diskId,
            diskBackingInfo: backing,
            profile: [storageProfileService.makeProfile(profileId)]
         };
      }

      function isSdrsDisabled(storageObject) {
         return storageObject.storageRef.type === managedEntityConstants.DATASTORE
               && storageObject.parentStoragePod
               && storageObject.parentStoragePod.type === managedEntityConstants.STORAGE_POD;
      }

      function getDiskPod(storageObject) {
         return storageObject.storageRef.type === managedEntityConstants.STORAGE_POD ?
               storageObject.storageRef :
               storageObject.parentStoragePod;
      }

   }
}());
