/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace dvs_ui {
   import DvsPortPropertiesModel =
         com.vmware.vsphere.client.h5.network.dvs.ports.model.DvsPortPropertiesModel;
   import NetworkResourcePoolData =
         com.vmware.vsphere.client.h5.network.dvs.ports.model.DvsPortPropertiesModel$NetworkResourcePoolData;

   export class DvsPortEditPropertiesPageModel {
      public name: string;

      public description: string;

      public selectedResourcePool: NetworkResourcePoolData;

      public networkResourcePools: NetworkResourcePoolData[];

      public isPropertiesPageChanged: Function;

      public isResourcePoolChanged: Function;

      constructor(portProperties: DvsPortPropertiesModel) {
         if (portProperties) {
            this.name = portProperties.name;
            this.description = portProperties.description;
            if (portProperties.networkResourcePools
                  && portProperties.networkResourcePools.length) {
               this.selectedResourcePool = portProperties.selectedResourcePool;
               this.networkResourcePools = portProperties.networkResourcePools;
            }

            this.isPropertiesPageChanged = this.isPropertiesPageChangedFunction.bind(this);
            this.isResourcePoolChanged = this.isResourcePoolChangedFunction.bind(this);
         }
      }

      private isResourcePoolChangedFunction(initialData: DvsPortPropertiesModel): boolean {
         if (!initialData || !this.selectedResourcePool) {
            return false;
         }

         return !_.isEqual(this.selectedResourcePool, initialData.selectedResourcePool);
      }

      private isPropertiesPageChangedFunction(initialData: DvsPortPropertiesModel): boolean {
         if (!initialData) {
            return false;
         }

         return this.name !== initialData.name
               || this.description !== initialData.description;
      }
   }
}