(function() {
   'use strict';
   angular.module('com.vmware.vsphere.client.host').controller(
         'AddHostAssignLicensePageController',
         AddHostAssignLicensePageController
   );

   AddHostAssignLicensePageController.$inject = [
      '$scope',
      'vuiConstants'
   ];

   function AddHostAssignLicensePageController($scope, vuiConstants) {
      var self = this;
      self.model = $scope.manager.getAssignLicensePageModel();
      self.selectedLicense = self.model.userSelectedLicense;

      self.onLicenseItemChanged = function(licenseItem) {
         $scope.manager.setUserSelectedLicense(licenseItem);
      };

      $scope.wizardConfig.loading = true;
      $scope.manager.validateLicense(self.model).then(
         function(validationResult /*AssetAssignLicenseValidationResult*/) {
            $scope.wizardConfig.loading = false;
            self.validationResult = validationResult;

            var errorInResponse = $scope.manager.getErrorInResponse();
            if (errorInResponse) {
               $scope.wizardConfig.validationBanner.messages = [{
                  type: vuiConstants.notifications.type.ERROR,
                  text: errorInResponse.localizedMessage
               }];
            }
         }
      );
   }
})();
