/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace dvs_ui {

   import DvsPortMirroringSpec =
         com.vmware.vsphere.client.h5.network.dvs.portmirroring.model.DvsPortMirroringSpec;
   import DvsPortMirroringSourcePorts =
         com.vmware.vsphere.client.h5.network.dvs.portmirroring.model.DvsPortMirroringSourcePorts;
   import DvsPortMirroringDestinationPorts =
         com.vmware.vsphere.client.h5.network.dvs.portmirroring.model.DvsPortMirroringDestinationPorts;
   import IPromise = angular.IPromise;

   export class DvsPortMirroringPortsDetailsDataService {

      public static readonly $inject = [
         "dataService"
      ];

      public static readonly SOURCE_PORTS_DATA_PROPERTY: string =
            "dvswitchportmirroring:portMirroringSourcePortsDetails";
      public static readonly DESTINATION_PORTS_DATA_PROPERTY: string =
            "dvswitchportmirroring:portMirroringDestinationPortsDetails";
      public static readonly DESTINATION_PORTS_SPEC_TYPE: string =
            "com.vmware.vsphere.client.h5.network.dvs.portmirroring.model.DvsPortMirroringSpec";

      constructor(private dataService: any) {

      }

      public getDvsPortMirrorSourcePortsDataDetails(dvsUrn: string,
            sessionKey: string): IPromise<DvsPortMirroringSourcePorts> {
         let dvsPortMirroringSpec: DvsPortMirroringSpec = {
            _type: DvsPortMirroringPortsDetailsDataService.DESTINATION_PORTS_SPEC_TYPE,
            sessionKey: sessionKey
         };

         let params: any = {
            propertyParams: [
               {
                  propertyName: DvsPortMirroringPortsDetailsDataService.SOURCE_PORTS_DATA_PROPERTY,
                  parameterType:
                        dvsPortMirroringSpec._type,
                  parameter: dvsPortMirroringSpec
               }
            ]
         };

         return this.dataService.getProperties(
               dvsUrn, [DvsPortMirroringPortsDetailsDataService.SOURCE_PORTS_DATA_PROPERTY], params)
               .then((response: any): DvsPortMirroringSourcePorts => {
                  return response[DvsPortMirroringPortsDetailsDataService.SOURCE_PORTS_DATA_PROPERTY];
               });
      }

      public getDvsPortMirrorDestPortsDataDetails(dvsUrn: string,
            sessionKey: string): IPromise<DvsPortMirroringDestinationPorts> {
         let dvsPortMirroringSpec: DvsPortMirroringSpec = {
            _type: DvsPortMirroringPortsDetailsDataService.DESTINATION_PORTS_SPEC_TYPE,
            sessionKey: sessionKey
         };

         let params: any = {
            propertyParams: [
               {
                  propertyName: DvsPortMirroringPortsDetailsDataService.DESTINATION_PORTS_DATA_PROPERTY,
                  parameterType:
                        dvsPortMirroringSpec._type,
                  parameter: dvsPortMirroringSpec
               }
            ]
         };

         return this.dataService.getProperties(
               dvsUrn, [DvsPortMirroringPortsDetailsDataService.DESTINATION_PORTS_DATA_PROPERTY], params)
               .then((response: any): any => {
                  return response[DvsPortMirroringPortsDetailsDataService.DESTINATION_PORTS_DATA_PROPERTY];
               });
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .service("dvsPortMirroringPortsDetailsDataService",
               DvsPortMirroringPortsDetailsDataService);
}