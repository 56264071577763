module cluster_ui {
   import IController = angular.IController;
   import HeaderOptions = cluster_ui.HeaderOptions;
   import IQService = angular.IQService;

   export class HostOptionsController implements IController {

      public static $inject = ["$scope", "i18nService", "dataService", "hostOptionsService", "$q"];
      public objectId: string;
      public liveRefreshProperties: string[];
      private headerOptions: HeaderOptions;
      private datagridOptions: DatagridOptions;
      private actionBarCacheObject: any;
      private selectedItem: any;

      constructor(private $scope: any,
                  private i18nService: any,
                  private dataService: any,
                  private hostOptionsService: HostOptionsService,
                  private $q: IQService) {
         this.objectId = $scope._route.objectId;
         this.liveRefreshProperties = [
               "configurationEx.dpmConfigInfo.enabled",
               "configurationEx.dpmConfigInfo.defaultDpmBehavior",
               "configurationEx.dpmHostConfig"
         ];
         this.headerOptions = {
            title: i18nService.getString("ClusterUi", "manage.settings.hostOptions"),
            actions: [],
            objectIds: [this.objectId]
         };

         this.datagridOptions = hostOptionsService.getDatagridOptions();
         this.getData();
         hostOptionsService.createActionBar(this.datagridOptions, this.objectId).then((data) => {
            this.actionBarCacheObject = data;
         });
         this.preselectDatagridItem();
         this.refreshActionBarOnSelect();
      }

      public getData(): void {
         this.dataService.getData(
               this.objectId,
               "com.vmware.vsphere.client.clusterui.model.services.ClusterHostOptionsConfigData"
         ).then((data: any) => {
            if (data) {
               this.$q.when(this.hostOptionsService.parseHostOptionsData(data)).then((parsedData) => {
                  if (parsedData) {
                     this.datagridOptions.data = parsedData;
                     this.keepPreselection();
                  }
               });
            }
         });
      }

      private refreshActionBarOnSelect(): void {
         this.$scope.$watch(() => {
            return this.selectedItem;
         }, () => {
            this.hostOptionsService.refreshActionBar(this.datagridOptions, this.objectId, this.actionBarCacheObject);
         });
      }

      private preselectDatagridItem() {
         this.$scope.$watch(() => {
            return this.datagridOptions.selectedItems;
         }, (selectedItems: any) => {
            if (!selectedItems || selectedItems.length === 0) {
               this.selectedItem = null;
               return;
            }

            if (this.selectedItem && this.selectedItem.name === selectedItems[0].name) {
               return;
            }

            this.selectedItem = selectedItems[0];
         });
      }

      private keepPreselection() {
         if (this.selectedItem && this.datagridOptions.data.length > 0) {
            this.datagridOptions.selectedItems[0] = _.find(this.datagridOptions.data, (item) => {
               return this.selectedItem.name === item.name;
            });
         }
      }

      hostOptionsPreselectComparator = (hostOption: any) => {
         return this.selectedItem && hostOption &&
               this.selectedItem.name === hostOption.name;
      }
   }

   angular.module("com.vmware.vsphere.client.cluster")
         .controller("HostOptionsController", HostOptionsController);
}
