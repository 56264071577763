/* Copyright 2018 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace dvs_ui {

   export class PortgroupDetailsDialogPoliciesView {

      public bindings: any;
      public controller: any;
      public template: string;

      constructor() {
         this.bindings = {
            data: "<"
         };

         this.controller = PortgroupDetailsDialogPoliciesViewController;

         this.template = `<vx-property-view class="network-ui-fixed-size-vx-property-view"
                                            data="$ctrl.propertyViewData"/>`;
      }
   }

   class PortgroupDetailsDialogPoliciesViewController {

      static $inject = [
            "distributedPortgroupPoliciesViewService",
            "networkDetailsViewService"
      ];

      isDvPortgroup: boolean;

      data: any;

      propertyViewData: any;

      constructor(private distributedPortgroupPoliciesViewService: any,
                  private networkDetailsViewService: any) {}

      $onInit(): void {
         if (this.isDvPortgroup) {
            this.propertyViewData =
                  this.distributedPortgroupPoliciesViewService.build(this.data);
         } else {
            this.propertyViewData =
                  this.networkDetailsViewService.buildOnlyPolicies(this.data);
         }
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .component("portgroupDetailsDialogPoliciesView",
               new PortgroupDetailsDialogPoliciesView());
}
