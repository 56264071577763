/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace dvs_ui {

   import DvsImportPortgroupWrapperSpec = com.vmware.vsphere.client.dvs.api.spec.DvsImportPortgroupWrapperSpec;
   import DvsImportPortgroupSpec = com.vmware.vsphere.client.dvs.api.spec.DvsImportPortgroupSpec;

   export class DvsImportReadyToCompletePageComponent {

      public bindings: any;
      public controller: any;
      public templateUrl: string;

      constructor() {
         this.bindings = {
            wizardModel: "<"
         };

         this.controller = DvsImportReadyToCompletePageComponentController;

         this.templateUrl = "dvs-ui/resources/dvs/import/dvsImportReadyToCompletePageComponentTemplate.html";
      }

   }

   class DvsImportReadyToCompletePageComponentController {

      static $inject = [
         "i18nService",
         "vxPropertyViewService",
         "vimEntityEscapingService",
         "networkUiConstants"
      ];

      public wizardModel: DvsImportWizardModel;

      private propertyViewData: any;

      constructor(private i18nService: any,
                  private vxPropertyViewService: any,
                  private vimEntityEscapingService: any,
                  private networkUiConstants: any) {
      }

      $onInit(): void {
         this.buildPropertyView();
      }

      private buildPropertyView(): void {
         let builder: any = this.vxPropertyViewService.createPropertyViewBuilder();

         let generalCategory: any = builder.category("general");

         if (this.wizardModel.isDvpgConfig) {
            if (this.wizardModel.isRestoreAction) {
               this.buildDvpgRestorePropView(generalCategory);
            } else {
               this.buildDvpgImportPropView(generalCategory);
            }
         } else {
            this.buildDvsImportPropView(generalCategory);
         }

         this.propertyViewData = builder.build();
      }


      private getPortBindingLabel(portBinding: any): string {
         let portBindingValue: string = "";
         if (this.networkUiConstants.PortgroupType.EARLY_BINDING === portBinding) {
            portBindingValue = "dvpg.import.summary.portBinding.static";
         } else if (this.networkUiConstants.PortgroupType.LATE_BINDING === portBinding) {
            portBindingValue = "dvpg.import.summary.portBinding.dynamic";
         } else if (this.networkUiConstants.PortgroupType.EPHEMERAL === portBinding) {
            portBindingValue = "dvpg.import.summary.portBinding.ephemeral";
         }
         return this.i18nService.getString("DvsUi", portBindingValue);
      }

      private buildDvpgImportPropView(generalCategory: any): void {
         let model: DvsImportWizardConfigurationPageModel =
               this.wizardModel.configurationPageModel;

         let importSettingsSection: any = generalCategory.section("importSettings")
               .title(this.i18nService.getString(
                     "DvsUi", "dvpg.import.summary.settingsHeader"));

         let dvsImportPortgroupSpec: DvsImportPortgroupSpec =
               model.dvsImportPortgroupWrapperSpec.dvsImportPortgroupSpec;

         // Name
         importSettingsSection.property(
               this.i18nService.getString(
                     "DvsUi", "dvpg.import.summary.nameLabel"),
               dvsImportPortgroupSpec.portgroupName);

         //Name error
         if (dvsImportPortgroupSpec.nameAlreadyExists) {
            importSettingsSection.message(this.i18nService.getString(
                  "DvsUi", "dvpg.import.summary.nameConflictError",
                  dvsImportPortgroupSpec.newPortgroupName),
                  'vsphere-icon-status-warning');
         }

         // VLAN
         importSettingsSection.property(
               this.i18nService.getString(
                     "DvsUi", "dvpg.import.summary.vlanLabel"),
               model.dvsImportPortgroupWrapperSpec.vlanLabel);

         // Type
         let typeLabel: string = dvsImportPortgroupSpec.isUplink ?
               this.i18nService.getString(
                     "DvsUi", "dvpg.import.summary.type.uplink") :
               this.i18nService.getString(
                     "DvsUi", "dvpg.import.summary.type.standard");

         importSettingsSection.property(this.i18nService.getString(
               "DvsUi", "dvpg.import.summary.typeLabel"), typeLabel);

         // Port binding
         importSettingsSection.property(
               this.i18nService.getString(
                     "DvsUi", "dvpg.import.summary.portBindingLabel"),
               this.getPortBindingLabel(dvsImportPortgroupSpec.portBinding));

         // Port allocation
         if (this.networkUiConstants.PortgroupType.EARLY_BINDING ===
               dvsImportPortgroupSpec.portBinding && !dvsImportPortgroupSpec.isUplink) {

            let portAllocationLabel = dvsImportPortgroupSpec.autoExpand ?
                  this.i18nService.getString(
                        "DvsUi", "dvpg.import.summary.portAllocation.elastic") :
                  this.i18nService.getString(
                        "DvsUi", "dvpg.import.summary.portAllocation.fixed");

            importSettingsSection.property(
                  this.i18nService.getString(
                        "DvsUi", "dvpg.import.summary.portAllocationLabel"),
                  portAllocationLabel);
         }

         //Description
         if (dvsImportPortgroupSpec.annotation) {
            importSettingsSection.property(
                  this.i18nService.getString(
                        "DvsUi", "dvpg.import.summary.annotation"),
                  dvsImportPortgroupSpec.annotation);
         }
      }

      private buildDvsImportPropView(generalCategory: any): void {

         let model: DvsImportWizardConfigurationPageModel =
               this.wizardModel.configurationPageModel;

         let importSettingsSection: any = generalCategory.section("importSettings");
         if (this.wizardModel.isRestoreAction) {
            importSettingsSection.title(this.i18nService.getString(
                  "DvsUi", "dvs.restore.wizard.summaryPage.header"));
         } else {
            importSettingsSection.title(this.i18nService.getString(
                  "DvsUi", "dvs.import.wizard.summaryPage.header"));
         }

         // File name
         importSettingsSection.property(
               this.i18nService.getString(
                     "DvsUi", "dvs.import.wizard.summaryPage.fileName"),
               model.fileLocation);

         if (model.dvsImportInfoSpec !== undefined) {
            // Switch name
            let unescapedSwitchName = this.vimEntityEscapingService
                  .unescapeSpecialCharacters(
                        model.dvsImportInfoSpec.dvsName);
            importSettingsSection.property(
                  this.i18nService.getString(
                        "DvsUi", "dvs.import.wizard.summaryPage.switchName"),
                  unescapedSwitchName);


            // Switch version
            importSettingsSection.property(
                  this.i18nService.getString(
                        "DvsUi", "dvs.import.wizard.summaryPage.switchVersion"),
                  model.dvsImportInfoSpec.dvsVersion);

            if (model.dvsImportInfoSpec.differentContextKey &&
                  this.wizardModel.isRestoreAction) {
               importSettingsSection.message(this.i18nService.getString(
                     "DvsUi", "dvs.restore.wizard.summaryPage.differentSwitch"),
                     'vsphere-icon-status-warning');
            }

            // Port groups count
            importSettingsSection.property(
                  this.i18nService.getString(
                        "DvsUi", "dvs.import.wizard.summaryPage.pgCount"),
                  model.dvsImportInfoSpec.portgroupCount);

            // Uplink count
            importSettingsSection.property(
                  this.i18nService.getString(
                        "DvsUi", "dvs.import.wizard.summaryPage.uplinkCount"),
                  model.dvsImportInfoSpec.uplinksCount);

            // NRP count
            importSettingsSection.property(
                  this.i18nService.getString(
                        "DvsUi", "dvs.import.wizard.summaryPage.nrpCount"),
                  model.dvsImportInfoSpec.nrpCount);

            if(model.dvsImportInfoSpec.lagCount !== undefined) {
               // LAG count
               importSettingsSection.property(
                     this.i18nService.getString(
                           "DvsUi", "dvs.import.wizard.summaryPage.lagCount"),
                     model.dvsImportInfoSpec.lagCount);
            }

            if (model.dvsImportInfoSpec.annotation) {
               // Description
               importSettingsSection.property(
                     this.i18nService.getString(
                           "DvsUi", "dvs.import.wizard.summaryPage.annotation"),
                     model.dvsImportInfoSpec.annotation).renderAsHtml(true);
            }
         }
      }

      private buildDvpgRestorePropView(generalCategory: any): void {

         let model: DvsImportWizardConfigurationPageModel =
               this.wizardModel.configurationPageModel;

         for (let section of model.dvPortgroupRestoreInfoWrapperSpec.dvpgRestoreSectionRepresentationSpec) {
            let propViewSectionSection: any = generalCategory.section(section.sectionTitle)
                  .title(section.sectionTitle);
            for (let setting of section.sectionSettings) {
               propViewSectionSection.property(
                     setting.key, setting.value);

            }
         }
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .component("dvsImportReadyToCompletePageComponent",
               new DvsImportReadyToCompletePageComponent());
}
