/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace dvs_ui {

   import IOnChangesObject = angular.IOnChangesObject;

   export class DvsAddSpanSessionSelectTypePageComponent {

      public bindings: any;
      public controller: any;
      public templateUrl: string;

      constructor() {
         this.bindings = {
            wizardModel: "<"
         };

         this.controller = DvsAddSpanSessionSelectTypePageComponentController;

         this.templateUrl = "dvs-ui/resources/dvs/portmirroring/create/components/" +
               "dvsAddSpanSessionSelectTypePageComponentTemplate.html";
      }

   }

   class DvsAddSpanSessionSelectTypePageComponentController {

      static $inject = ["i18nService", "$scope"];

      public wizardModel: DvsAddSpanSessionWizardModel;

      public signPostConfig: any;

      private sessionNameByType: any;

      private descriptionsByType: any;

      constructor(private i18nService: any,
                  private $scope: any) {

         $scope.i18n = this.i18nService.getString;
      }

      public $onInit() {
         this.initSessionNameByType();
         this.initDescriptions();
         this.buildSignpostItems();
      }

      private initDescriptions(): void {
         this.descriptionsByType = {};

         this.descriptionsByType[DvsSpanSessionConstants.SESSION_TYPE.DV_PORT_MIRROR] =
               this.i18nService.getString("DvsUi",
                     "span.session.type.dvPortMirrorDescription");

         this.descriptionsByType[DvsSpanSessionConstants.SESSION_TYPE.REMOTE_MIRROR_SOURCE] =
            this.i18nService.getString("DvsUi",
                  "span.session.type.remoteMirrorSourceDescription");

         this.descriptionsByType[DvsSpanSessionConstants.SESSION_TYPE.L3_SOURCE] =
            this.i18nService.getString("DvsUi",
                  "span.session.type.encapsulatedRemoteMirrorSourceDescription");

         this.descriptionsByType[DvsSpanSessionConstants.SESSION_TYPE.REMOTE_MIRROR_DEST] =
            this.i18nService.getString("DvsUi",
                  "span.session.type.remoteMirrorDestDescription");

      }

      private initSessionNameByType(): void {
         this.sessionNameByType = {};

         this.sessionNameByType[DvsSpanSessionConstants.SESSION_TYPE.DV_PORT_MIRROR] =
               this.i18nService.getString("DvsUi",
                     "span.session.type.dvPortMirror");

         this.sessionNameByType[DvsSpanSessionConstants.SESSION_TYPE.REMOTE_MIRROR_SOURCE] =
            this.i18nService.getString("DvsUi",
                  "span.session.type.remoteMirrorSource");

         this.sessionNameByType[DvsSpanSessionConstants.SESSION_TYPE.L3_SOURCE] =
            this.i18nService.getString("DvsUi",
                  "span.session.type.encapsulatedRemoteMirrorSource");

         this.sessionNameByType[DvsSpanSessionConstants.SESSION_TYPE.REMOTE_MIRROR_DEST] =
            this.i18nService.getString("DvsUi",
                  "span.session.type.remoteMirrorDest");
      }

      private buildSignpostItems(): void {
         let signpostMessage: string = "";

         _.forEach(this.wizardModel.supportedSessionTypes, (sessionType: string): void => {
            let title: string = "<b>%title%</b><br/>"
                  .replace("%title%", this.getSessionName(sessionType));

            let description: string = this.getSessionDescription(sessionType);
            let template: string = "<div class='horizontal-flex-container'>" +
                  "<span class='new-span-session-description-bullet'></span>" +
                  "<span class='new-span-session-description-item'>%description%</span><br/></div>";

            let content: string = title + template.replace("%description%", description);

            let sectionTemplate: string =
                  "<div data-test-id='span-session-section-%sessionType%'>%content%</div>";

            signpostMessage += sectionTemplate
                  .replace("%sessionType%", sessionType)
                  .replace("%content%", content);
         });

         this.signPostConfig = {
            message: "<div class='new-span-session-signpost-content'>%content%</div>"
                  .replace("%content%", signpostMessage)
         };
      }

      private getSessionDescription(sessionType: string): string {
         return this.descriptionsByType[sessionType];
      }

      private getSessionName(sessionType: string): string {
         return this.sessionNameByType[sessionType];
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .component("dvsAddSpanSessionSelectTypePage",
               new DvsAddSpanSessionSelectTypePageComponent());
}
