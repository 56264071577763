/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */

(function () {
   'use strict';
   angular.module('com.vmware.vsphere.client.network').controller(
      'PhysicalAdapterDetailsViewController', PhysicalAdapterDetailsViewController);

   PhysicalAdapterDetailsViewController.$inject = ['$scope'];

   function PhysicalAdapterDetailsViewController($scope) {

      var self = this;

      self.liveRefreshProperties = [
         "config.network.pnic",
         "config.sriovDevicePool",
         "config.pciPassthruInfo"
      ];

      self.hostId = $scope._route.objectId;

      self.registerRefreshCallback = function (refresh) {
         self.refresh = refresh;
      };
   }
})();
