namespace h5_vm {

   import IComponentController = angular.IComponentController;
   import IComponentOptions = angular.IComponentOptions;

   class VmProvisioningEulaDisplayController implements IComponentController {

      static $inject: string[] = [
         "i18nService",
         "$location",
         "$anchorScroll",
         "vmProvisioningEulaDisplayService"
      ];

      private form: any;
      private headerDescription: string;
      private numberOfEulasFound: number;
      private linkDescriptions: Array<string> = [];

      constructor(private i18nService: any,
                  private $location: any,
                  private $anchorScroll: any,
                  private eulaDisplayService: any) {
      }

      $onInit(): void {
         if (!_.isEmpty(this.form.eulaInfo) && !_.isEmpty(this.form.eulaInfo.eulas)) {
            this.numberOfEulasFound = this.form.eulaInfo.eulas.length;

            this.headerDescription = this.eulaDisplayService.getEulaDescription(
               this.numberOfEulasFound);

            this.linkDescriptions = this.eulaDisplayService.getInfoDescriptions(
               this.numberOfEulasFound);
         }
      }

      private scrollTo(id: string): void {
         this.$location.hash(id);
         this.$anchorScroll();
      }
   }

   class VmProvisioningEulaDisplay implements IComponentOptions {

      public controller: any;
      public templateUrl: string;
      public bindings: any;

      constructor() {
         this.controller = VmProvisioningEulaDisplayController;
         this.templateUrl = "vm-ui/resources/vm/views/createVmWizard/" +
            "deployOvfPages/vmProvisioningEula/vm-provisioning-eula-display.html";
         this.bindings = {
            form: "="
         };
      }
   }

   angular.module("com.vmware.vsphere.client.vm")
      .component("vmProvisioningEulaDisplay", new VmProvisioningEulaDisplay());
}
