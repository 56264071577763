/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace dvs_ui {
   import DvsPortFailoverPolicyModel = com.vmware.vsphere.client.h5.network.dvs.ports.model.DvsPortFailoverPolicyModel;

   export class DvsPortEditFailoverPageModel {
      public overrideOptions: any;

      public failoverPolicy: DvsPortFailoverPolicyModel;

      public failoverOrder: any;

      public isFailoverPageChanged: Function;

      public shouldSkipValidation: Function;

      constructor(failoverPolicy: DvsPortFailoverPolicyModel, failoverOrder: any) {
         if (failoverPolicy && failoverOrder) {
            this.failoverPolicy = failoverPolicy;
            this.failoverOrder = failoverOrder;
            this.overrideOptions = failoverPolicy.overrideOptions;
            this.isFailoverPageChanged = this.isFailoverPageChangedFunction.bind(this);
            this.shouldSkipValidation = this.shouldSkipValidationFunction.bind(this);
         }
      }

      public isFailoverPageChangedFunction(
            initialData: DvsPortFailoverPolicyModel): boolean {

         if (!initialData || !this.overrideOptions || !this.failoverPolicy) {
            return false;
         }

         return !_.isEqual(initialData.overrideOptions, this.overrideOptions)
               || !_.isEqual(initialData, this.failoverPolicy);
      }

      public shouldSkipValidationFunction(): boolean {
         if (!this.overrideOptions) {
            return true;
         }

         for (let prop in this.overrideOptions) {
            if (this.overrideOptions[prop]) {
               return false;
            }
         }

         return true;
      }
   }
}