namespace h5_vm {
   import Specification = com.vmware.vim.binding.vim.vm.customization.Specification;
   import IdentitySettings = com.vmware.vim.binding.vim.vm.customization.IdentitySettings;
   import UserData = com.vmware.vim.binding.vim.vm.customization.UserData;
   import LicenseFilePrintData = com.vmware.vim.binding.vim.vm.customization.LicenseFilePrintData;
   import LicenseFilePrintData$AutoMode = com.vmware.vim.binding.vim.vm.customization.LicenseFilePrintData$AutoMode;
   import Sysprep = com.vmware.vim.binding.vim.vm.customization.Sysprep;
   import LinuxPrep = com.vmware.vim.binding.vim.vm.customization.LinuxPrep;
   import GuiUnattended = com.vmware.vim.binding.vim.vm.customization.GuiUnattended;
   import Identification = com.vmware.vim.binding.vim.vm.customization.Identification;
   import WinOptions = com.vmware.vim.binding.vim.vm.customization.WinOptions;
   import AdapterMapping = com.vmware.vim.binding.vim.vm.customization.AdapterMapping;
   import NameGenerator = com.vmware.vim.binding.vim.vm.customization.NameGenerator;
   import FixedName = com.vmware.vim.binding.vim.vm.customization.FixedName;
   import PrefixNameGenerator = com.vmware.vim.binding.vim.vm.customization.PrefixNameGenerator;
   import GosType = h5_vm.GosType;
   import GuiRunOnce = com.vmware.vim.binding.vim.vm.customization.GuiRunOnce;

   export class GosCustomizationHelperService {
      public static $inject = [
         "i18nService",
         "gosSpecNetworkService"];

      public static readonly IDENTITY_TYPE_LINUX_PREP: string =
            "com.vmware.vim.binding.vim.vm.customization.LinuxPrep";
      public static readonly IDENTITY_TYPE_SYS_PREP: string =
            "com.vmware.vim.binding.vim.vm.customization.Sysprep";
      public static readonly IDENTITY_TYPE_SYS_PREP_TEXT: string =
            "com.vmware.vim.binding.vim.vm.customization.SysprepText";

      public static readonly ADAPTER_TYPE_DHCP: string =
            "com.vmware.vim.binding.vim.vm.customization.DhcpIpGenerator";
      public static readonly ADAPTER_TYPE_FIXED_IP: string =
            "com.vmware.vim.binding.vim.vm.customization.FixedIp";
      public static readonly ADAPTER_TYPE_UNKNOWUN_IP: string =
            "com.vmware.vim.binding.vim.vm.customization.UnknownIpGenerator";
      public static readonly ADAPTER_TYPE_CUSTOM_IP: string =
            "com.vmware.vim.binding.vim.vm.customization.CustomIpGenerator";
      public static readonly ADAPTER_TYPE_DHCP_V6: string =
            "com.vmware.vim.binding.vim.vm.customization.DhcpIpV6Generator";
      public static readonly ADAPTER_TYPE_FIXED_IP_V6: string =
            "com.vmware.vim.binding.vim.vm.customization.FixedIpV6";
      public static readonly ADAPTER_TYPE_UNKNOWUN_IP_V6: string =
            "com.vmware.vim.binding.vim.vm.customization.UnknownIpV6Generator";
      public static readonly ADAPTER_TYPE_CUSTOM_IP_V6: string =
            "com.vmware.vim.binding.vim.vm.customization.CustomIpV6Generator";
      public static readonly IPV6_ADDRESS_SPEC_TYPE: string =
            "com.vmware.vim.binding.vim.vm.customization.IPSettings$IpV6AddressSpec";

      public static readonly COMPUTER_NAME_TYPE_FIXED_NAME: string =
            "com.vmware.vim.binding.vim.vm.customization.FixedName";
      public static readonly COMPUTER_NAME_TYPE_PREFIX_NAME_GENERATOR: string =
            "com.vmware.vim.binding.vim.vm.customization.PrefixNameGenerator";
      public static readonly COMPUTER_NAME_TYPE_VIRTUAL_MACHINE_NAME_GENERATOR: string =
            "com.vmware.vim.binding.vim.vm.customization.VirtualMachineNameGenerator";
      public static readonly COMPUTER_NAME_TYPE_CUSTOM_NAME_GENERATOR: string =
            "com.vmware.vim.binding.vim.vm.customization.CustomNameGenerator";
      public static readonly COMPUTER_NAME_TYPE_UNKNOWN_NAME_GENERATOR: string =
            "com.vmware.vim.binding.vim.vm.customization.UnknownNameGenerator";

      public static readonly DEFAULT_NUMBER_OF_NICS: number = 1;
      public static readonly AREA_LOCATION_SEPARATOR: string = "/";

      constructor(private _i18nService: any,
            private gosSpecNetworkService: GosSpecNetworkService) {
      }

      /**
       * Determines if this is a Linux prep spec.
       */
      public isLinuxPrep(specDetails?: Specification): boolean {
         if (!specDetails) {
            return false;
         }
         return specDetails.identity._type === GosCustomizationHelperService.IDENTITY_TYPE_LINUX_PREP;
      }

      /**
       * Determines if this is a Windows sysprep spec stored in a text file.
       */
      public isSysPrep(specDetails?: Specification): boolean {
         if (!specDetails) {
            return false;
         }
         return specDetails.identity._type === GosCustomizationHelperService.IDENTITY_TYPE_SYS_PREP;
      }

      /**
       * Determines if this is a Windows sysprep spec stored in a text file.
       */
      public isSysPrepText(specDetails?: Specification): boolean {
         if (!specDetails) {
            return false;
         }
         return specDetails.identity._type === GosCustomizationHelperService.IDENTITY_TYPE_SYS_PREP_TEXT;
      }

      public getGosType(identity: IdentitySettings): GosType {
         switch (identity._type) {
            case GosCustomizationHelperService.IDENTITY_TYPE_LINUX_PREP:
               return GosType.LINUX;
            case GosCustomizationHelperService.IDENTITY_TYPE_SYS_PREP:
               return GosType.WINDOWS;
            case GosCustomizationHelperService.IDENTITY_TYPE_SYS_PREP_TEXT:
               return GosType.WINDOWS_CUSTOM_SYSPREP;
         }
         return GosType.NONE;
      }

      public getNameGenerator(specDetails: Specification): NameGenerator {
         let result: NameGenerator;
         if (this.isSysPrep(specDetails)) {
            let identitySysPrep: Sysprep = (specDetails.identity as Sysprep);
            result = identitySysPrep.userData.computerName;
         } else { // LinuxPrep
            let identityLinuxPrep: LinuxPrep = (specDetails.identity as LinuxPrep);
            result = identityLinuxPrep.hostName;
         }

         return result;
      }

      public getComputerName(specDetails?: Specification): string {
         if (!specDetails || this.isSysPrepText(specDetails)) {
            return "";
         }

         let nameSpec: NameGenerator = this.getNameGenerator(specDetails);

         let result: string = "";
         if (nameSpec._type === GosCustomizationHelperService.COMPUTER_NAME_TYPE_FIXED_NAME) {
            result = (nameSpec as FixedName).name;
         } else if (nameSpec._type === GosCustomizationHelperService.COMPUTER_NAME_TYPE_PREFIX_NAME_GENERATOR) {
            result = this._i18nService.getString("VmUi", "customizationManager.customizationSpecViewData.suffixed",
                  (nameSpec as PrefixNameGenerator).base);
         } else if (nameSpec._type === GosCustomizationHelperService.COMPUTER_NAME_TYPE_VIRTUAL_MACHINE_NAME_GENERATOR) {
            result = this._i18nService.getString("VmUi", "customizationManager.customizationSpecViewData.useVmName");
         } else if (nameSpec._type === GosCustomizationHelperService.COMPUTER_NAME_TYPE_CUSTOM_NAME_GENERATOR) {
            result = this._i18nService.getString("VmUi", "customizationManager.customizationSpecViewData.useCustom");
         } else if (nameSpec._type === GosCustomizationHelperService.COMPUTER_NAME_TYPE_UNKNOWN_NAME_GENERATOR) {
            result = this._i18nService.getString("VmUi", "customizationManager.customizationSpecViewData.askUser");
         }
         return result;
      }

      public getTimeZone(specDetails?: Specification): string {
         if (!specDetails) {
            return "";
         }

         if (this.isLinuxPrep(specDetails)) {
            let area: string = this.getTimeZoneArea(specDetails);
            let location: string = this.getTimeZoneLocation(specDetails);
            if (!area || !location) {
               return this._i18nService.getString("VmUi",
                     "customizationManager.customizationSpecViewData.emptyTimezone");
            }
            return this._i18nService.getString("VmUi",
                  "customizationManager.customizationSpecViewData.timezone", area, location);
         }

         if (this.isSysPrep(specDetails)) {
            return this._i18nService.getString("CommonTimeZones", "timeZoneWindows." + (specDetails.identity as Sysprep).guiUnattended.timeZone);
         }

         return "";
      }

      /**
       * String representation of the Linux time zone area.
       */
      public getTimeZoneArea(specDetails?: Specification): string {
         if (!specDetails || !this.isLinuxPrep(specDetails)) {
            return "";
         }

         // Linux timezone format is "<Area>/<Location>", so take the <Area> part.
         let rawTimeZone: string = (specDetails.identity as LinuxPrep).timeZone;
         if (!rawTimeZone) {
            return "";
         }
         let data: string[] = rawTimeZone.split(GosCustomizationHelperService.AREA_LOCATION_SEPARATOR);
         if (_.isEmpty(data)) {
            return "";
         }
         return this._i18nService.getString("CommonTimeZones", "timeZoneLinux.Area." + data[0]);
      }

      /**
       * String representation of the Linux time zone location.
       */
      public getTimeZoneLocation(specDetails?: Specification): string {
         if (!specDetails || !this.isLinuxPrep(specDetails)) {
            return "";
         }

         // Linux timezone format is "<Area>/<Location>" (America/Argentina/Buenos_Aires,
         // America/Aruba), so take the <Location> part
         let rawTimeZone: string = (specDetails.identity as LinuxPrep).timeZone;
         if (!rawTimeZone) {
            return "";
         }
         let data: string[] = rawTimeZone.split(GosCustomizationHelperService.AREA_LOCATION_SEPARATOR);
         if (data.length < 2) {
            return "";
         }
         // remove the Area part
         data.shift();
         // join back the location part - /Argentina/Buenos_Aires
         let location: string = data.join(GosCustomizationHelperService.AREA_LOCATION_SEPARATOR);

         return this._i18nService.getString("CommonTimeZones", "timeZoneLinux.Location." + location);
      }

      public getHardwareClock(specDetails?: Specification): string {
         if (!specDetails || !this.isLinuxPrep(specDetails)) {
            return "";
         }

         if ((specDetails.identity as LinuxPrep).hwClockUTC === null) {
            return this._i18nService.getString("VmUi", "customizationManager.customizationSpecDetailsView.hwClockNotSet");
         }

         if ((specDetails.identity as LinuxPrep).hwClockUTC) {
            return this._i18nService.getString("VmUi", "customizationManager.customizationSpecDetailsView.hwClockUtc");
         }

         return this._i18nService.getString("VmUi", "customizationManager.customizationSpecDetailsView.hwClockLocalTime");
      }

      public isNetworkTypical(specDetails?: Specification): boolean {
         // In the Network page story the network is referred to as "Standard", not
         // "Typical".
         return this.gosSpecNetworkService.isNetworkTypeStandard(specDetails);
      }

      public getNetworkTypeString(specDetails?: Specification): string {
         if (this.gosSpecNetworkService.isNetworkTypeStandard(specDetails)) {
            return this._i18nService.getString("VmUi",
                  "customizationSpec.summaryPage.networkSettings.standard");
         } else {
            return this._i18nService.getString("VmUi",
                  "customizationSpec.summaryPage.networkSettings.custom");
         }
      }

      public getNicIpSettings(specDetails?: Specification): any[] {
         let result: any[] = [];
         if (specDetails && specDetails.nicSettingMap) {
            _.each(specDetails.nicSettingMap, (adapter: AdapterMapping) => {
               let ipv4: string = this.gosSpecNetworkService
                     .getIPv4NicSettingSummary(adapter.adapter.ip);
               let ipv6: string = this.gosSpecNetworkService
                     .getIPv6NicSettingSummary(adapter.adapter.ipV6Spec);
               let item: any = {IPv4: ipv4, IPv6: ipv6};
               result.push(item);
            });
         }
         return result;
      }

      public getDnsServers(specDetails?: Specification): string[] {
         if (!specDetails || !this.isLinuxPrep(specDetails)
               || !specDetails.globalIPSettings
               || !specDetails.globalIPSettings.dnsServerList) {
            return [];
         }
         return specDetails.globalIPSettings.dnsServerList;
      }

      public getPrimaryDnsServer(specDetails?: Specification): string {
         let dnsServers: string[] = this.getDnsServers(specDetails);
         if (dnsServers.length > 0) {
            return dnsServers[0];
         }
         return "";
      }
      public getSecondaryDnsServer(specDetails?: Specification): string {
         let dnsServers: string[] = this.getDnsServers(specDetails);
         if (dnsServers.length > 1) {
            return dnsServers[1];
         }
         return "";
      }
      public getTertiaryDnsServer(specDetails?: Specification): string {
         let dnsServers: string[] = this.getDnsServers(specDetails);
         if (dnsServers.length > 2) {
            return dnsServers[2];
         }
         return "";
      }

      public getDnsAndDomain(specDetails?: Specification): string {
         if (specDetails && specDetails.globalIPSettings.dnsServerList && !_.isEmpty(specDetails.globalIPSettings.dnsServerList)) {
            return specDetails.globalIPSettings.dnsServerList[0];
         }

         return "";
      }

      public getDnsSuffixList(specDetails?: Specification): string[] {
         if (specDetails && this.isLinuxPrep(specDetails)
               && !!specDetails.globalIPSettings
               && !!specDetails.globalIPSettings.dnsSuffixList) {
            return specDetails.globalIPSettings.dnsSuffixList;
         }

         return [];
      }

      public getRegistrationInfo(specDetails?: Specification): string {
         if (!specDetails || !this.isSysPrep(specDetails)) {
            return "";
         }

         let userData: UserData = (specDetails.identity as Sysprep).userData;
         return this._i18nService.getString("VmUi", "guestOsCustomization.regInfoFormat",
               userData.fullName, userData.orgName);
      }

      public getWindowsLicenseKeyHeader(specDetails?: Specification): string {
         if (!specDetails || !this.isSysPrep(specDetails)) {
            return "";
         }

         let userData: UserData = (specDetails.identity as Sysprep).userData;
         if (!!userData.productId && !_.isEmpty(userData.productId)) {
            return this._i18nService.getString("VmUi", "customizationManager.customizationSpecDetailsView.licenseKeyHeader",
                  userData.productId);
         }

         return this._i18nService.getString("VmUi", "customizationManager.customizationSpecDetailsView.noLicenseKey");
      }

      public getWindowsLicenseKey(specDetails?: Specification): string {
         if (!specDetails || !this.isSysPrep(specDetails)) {
            return "";
         }

         let userData: UserData = (specDetails.identity as Sysprep).userData;
         if (!!userData.productId && !_.isEmpty(userData.productId)) {
            return userData.productId;
         }

         return this._i18nService.getString("VmUi", "customizationManager.customizationSpecDetailsView.noLicenseKey");
      }

      public isServerLicensingIncluded(specDetails?: Specification): boolean {
         if (!specDetails || !this.isSysPrep(specDetails)) {
            return false;
         }

         let licenseData: LicenseFilePrintData = (specDetails.identity as Sysprep).licenseFilePrintData;
         return !!licenseData;
      }

      public isServerLicensingMpdePerServer(specDetails?: Specification): string {
         if (!specDetails || !this.isSysPrep(specDetails)) {
            return "";
         }

         let licenseData: LicenseFilePrintData = (specDetails.identity as Sysprep).licenseFilePrintData;
         if (!licenseData) {
            return "";
         }

         if (("perServer" as LicenseFilePrintData$AutoMode) === licenseData.autoMode) {
            return this._i18nService.getString("VmUi", "customizationManager.customizationSpecViewData.licenseServerModePerServer", licenseData.autoUsers);
         } else {
            return this._i18nService.getString("VmUi", "customizationManager.customizationSpecViewData.licenseServerModePerSeat", licenseData.autoUsers);
         }
      }

      public getServerLicensingInfo(specDetails?: Specification): string {
         if (!specDetails || !this.isSysPrep(specDetails)) {
            return "";
         }

         let licenseData: LicenseFilePrintData = (specDetails.identity as Sysprep).licenseFilePrintData;
         if (!licenseData) {
            return "";
         }

         if (("perServer" as LicenseFilePrintData$AutoMode) === licenseData.autoMode) {
            return this._i18nService.getString("VmUi", "customizationManager.customizationSpecViewData.licenseServerModePerServer", licenseData.autoUsers);
         } else {
            return this._i18nService.getString("VmUi", "customizationManager.customizationSpecViewData.licenseServerModePerSeat", licenseData.autoUsers);
         }
      }

      public isAutoLogon(specDetails?: Specification): boolean {
         if (!specDetails || !this.isSysPrep(specDetails)) {
            return false;
         }

         let data: GuiUnattended = (specDetails.identity as Sysprep).guiUnattended;
         if (!data) {
            return false;
         }
         return data.autoLogon;
      }

      public getAutoLogonCount(specDetails?: Specification): number {
         if (!specDetails || !this.isSysPrep(specDetails)) {
            return NaN;
         }

         let data: GuiUnattended = (specDetails.identity as Sysprep).guiUnattended;
         if (!data) {
            return NaN;
         }
         return data.autoLogonCount;
      }

      public getWorkgroup(specDetails?: Specification): string {
         if (!specDetails || !this.isSysPrep(specDetails)) {
            return "";
         }

         let data: Identification = (specDetails.identity as Sysprep).identification;
         return data.joinWorkgroup;
      }

      public getDomain(specDetails?: Specification): string {
         if (!specDetails) {
            return "";
         }

         if (this.isLinuxPrep(specDetails)) {
            return (specDetails.identity as LinuxPrep).domain;
         }

         if (this.isSysPrep(specDetails)) {
            let data: Identification = (specDetails.identity as Sysprep).identification;
            return data.joinDomain;
         }

         return "";
      }

      public generateNewSID(specDetails?: Specification): boolean {
         if (!specDetails || this.isLinuxPrep(specDetails)) {
            return false;
         }

         let options: WinOptions = (specDetails.options as WinOptions);
         return options.changeSID;
      }

      public gosTypeToString(value: GosType): string {
         switch (value) {
            case GosType.WINDOWS:
            case GosType.WINDOWS_CUSTOM_SYSPREP:
               return this._i18nService.getString("VmUi",
                     "customizationSpec.nameAndOsPage.specTypeWindows");
            case GosType.LINUX:
               return this._i18nService.getString("VmUi",
                     "customizationSpec.nameAndOsPage.specTypeLinux");
            default: {
               return "";
            }
         }
      }

      /**
       * A list of commands to run at first user logon, after guest customization.
       */
      public getRunOnceCommands(specDetails?: Specification): Array<string> {
         if (!specDetails || !this.isSysPrep(specDetails)) {
            return [];
         }
         let data: GuiRunOnce = (specDetails.identity as Sysprep).guiRunOnce;
         if (!data || _.isEmpty(data.commandList)) {
            return [];
         }
         return data.commandList;
      }

      public getLoginType(specDetails: Specification): string {
         if (!(specDetails.identity as Sysprep).guiUnattended.autoLogon) {
            return this._i18nService.getString("VmUi",
               "customizationManager.customizationSpecDetailsView.doNotloginAsAdmin");
         } else {
            return this._i18nService.getString("VmUi",
               "customizationManager.customizationSpecDetailsView.loginAsAdmin");
         }
      }
      public getAutomaticLogonTimes(specDetails: Specification): number|null {
         if (!(specDetails.identity as Sysprep).guiUnattended.autoLogon) {
            return null;
         }
         return (specDetails.identity as Sysprep).guiUnattended.autoLogonCount;
      }

      public getDomainUsername(specDetails: Specification): string {
         if ((specDetails.identity as Sysprep).identification) {
            return (specDetails.identity as Sysprep).identification.domainAdmin;
         }
         return "";
      }

      public getCommands(specDetails: Specification): string[] {
         let identity = (specDetails.identity as Sysprep);
         if(identity && identity.guiRunOnce && identity.guiRunOnce.commandList) {
            return identity.guiRunOnce.commandList;
         }
         return [];
      }
   }

   angular.module("com.vmware.vsphere.client.vm")
         .service("gosCustomizationHelperService", GosCustomizationHelperService);
}
