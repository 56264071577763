/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
(function () {
   'use strict';
   angular.module('com.vmware.vsphere.client.cluster')
         .controller('HistoryController', HistoryController);

   HistoryController.$inject = ['$scope', 'dataService', 'i18nService',
      'vuiConstants', 'columnRenderersRegistry', 'defaultUriSchemeUtil',
      'timeFormatterService', '$q'];

   function HistoryController($scope, dataService, i18nService, vuiConstants,
         columnRenderersRegistry, defaultUriSchemeUtil,
         timeFormatterService, $q) {
      var self = this;
      var objectId = $scope._route.objectId;

      self.watchForObjects = [objectId];
      self.liveRefreshProperties = ["actionHistory", "summary.currentBalance"];
      self.getViewData = getViewData;
      self.gridOptions = getGridOptions();

      self.historyTitle = i18nService.getString('ClusterUi', 'drs.history');

      getViewData();

      function getViewData() {
         dataService.getProperties(objectId, ['computedActionHistory'])
               .then(function (historyData) {
                  if (historyData.computedActionHistory.length > 0) {
                     var formatTimePromises = historyData.computedActionHistory.map(
                           function(item) {
                        return timeFormatterService.formatDate(item.time).then(
                              function(formattedTime) {
                           item.time = formattedTime;
                           return item;
                        });
                     });
                     $q.all(formatTimePromises).then(function(data) {
                        self.gridOptions.data = data;
                     });
                  }

               });
      }

      function getGridOptions() {
         var options  = {
            sortMode: vuiConstants.grid.sortMode.SINGLE,
            searchable: false,
            resizable: true,
            data: [],
            columnDefs: getColumnDefs(),
            height: '100%'
         };

         return options;
      }

      function getColumnDefs() {
         return [
            {
               displayName: i18nService.getString('ClusterUi',
                     'actionHistoryList.timeColumnHeaderLabel'),
               field: 'time',
               type: 'string'
            },
            {
               displayName: i18nService.getString('ClusterUi',
                     'actionHistoryList.drsActionColumnHeaderLabel'),
               field: 'text',
               type: 'string',
               template: textRenderer
            }
         ];
      }

      function textRenderer(data) {
         var objData = data || {};
         var targetLinks = [];

         var renderer = columnRenderersRegistry.getColumnRenderer('object-name');

         //build links for each target object in the action text
         objData.targetObjects.forEach(function(object) {
            object.uid = defaultUriSchemeUtil.getVsphereObjectId(object.provider);

            var targetLink = renderer(['uid', null, 'name', 'labelIds'], object);
            targetLinks.push(targetLink);
         });

         var text = i18nService.interpolate(objData.text, targetLinks);

         if (!!objData.image) {
            text = '<span class="' + objData.image + '"></span>' + text;
         }

         return text;
      }
   }
})();
