/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */

(function () {
   'use strict';

   /**
    * By a given datacenter reference and datastore path provides
    * the URL from which a file located on a datastore can be downloaded.
    *
    * The datastore path is in the form:
    * [datastore] Virtual Machine/Virtual Machine.vmdk
    */
   angular.module('com.vmware.vsphere.client.storage').factory('datastoreFileService',
         ['userSessionService', 'dataService', '$q', 'defaultUriSchemeUtil', '$window',
            function (userSessionService, dataService, $q, defaultUriSchemeUtil, $window) {

               function getFileUrls(datacenterRef, datastorePaths) {
                  var serverGuid = datacenterRef.serverGuid;
                  var datacenterId = defaultUriSchemeUtil.getVsphereObjectId(datacenterRef);

                  return $q.all([
                     userSessionService.getServerInfo(serverGuid),
                     dataService.getProperties(datacenterId, ['name'])
                  ]).then(function (result) {
                     var serverInfo = result[0];
                     var datastoreData = result[1];

                     var fileUrls = datastorePaths.map(function (datastorePath) {
                        var pathParts = datastorePath.match(/^\[(.*?)\]\s{1}(.*)$/);
                        var datastoreName = pathParts[1];
                        var filePath = pathParts[2];
                        var fileUrl = createFileUrl(
                              serverInfo.serviceUrl,
                              filePath,
                              datastoreName,
                              datastoreData.name);

                        return fileUrl;
                     });

                     return fileUrls;
                  });
               }

               function createFileUrl(serviceUrl, filePath, datastoreName, datacenterName) {
                  var parser = document.createElement("a");
                  parser.href = serviceUrl;

                  var fileUrl = parser.protocol
                        .concat('//')
                        .concat(parser.host)
                        .concat('/folder/')
                        .concat(filePath)
                        .concat('?dcPath=')
                        .concat(datacenterName)
                        .concat('&dsName=')
                        .concat(datastoreName);

                  return fileUrl;
               }

               function startFileDownload(fileUrl) {
                  $window.open(fileUrl, '_blank');
               }

               return {
                  getFileUrls: getFileUrls,
                  startFileDownload: startFileDownload
               };
            }
         ]);
})();