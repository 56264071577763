/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace network_ui {

   class SelectedVmkDetailsViewController {

      public static $inject = ['$scope'];

      public customizedSettings: any;

      public registerRefreshCallback: Function;

      public refresh: Function;

      public hostUri: string;

      constructor(private $scope: any) {
         this.customizedSettings = {
            noPoliciesIncluded: true,
            addAllTab: false
         };

         this.hostUri = this.$scope.hostUri;

         this.registerRefreshCallback = (refresh: Function) => {
            this.refresh = refresh;
         };
      }

   }

   angular.module("com.vmware.vsphere.client.network")
         .controller("SelectedVmkDetailsViewController",
               SelectedVmkDetailsViewController);
}
