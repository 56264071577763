(function() {
   angular.module('com.vmware.vsphere.client.cluster').directive(
         'haHostFailureResponse', haHostFailureResponse);

   haHostFailureResponse.$inject = ['$rootScope', 'i18nService',
         'haHostFailureResponseService', 'vcService', 'haConstants'];

   function haHostFailureResponse($rootScope, i18nService, haHostFailureResponseService,
         vcService, haConstants) {
      return {
         restrict: 'E',
         templateUrl: 'cluster-ui/resources/cluster/views/settings/services/ha/haHostFailureResponse.html',
         scope: {
            haData: '='
         },
         controllerAs: 'vm',
         bindToController: true,
         controller: ['$scope', function($scope) {
            var vm = this;
            // Used to persist the restart priority dropdown value.
            var selectedRestartPriority = null;
            var objectId = $rootScope._route.objectId;
            vm.is65VcOrLater = false;

            vm.hostFailureResponses = {
               label: getString('ha.config.hostfailure.label'),
               vmRestart: getString('ha.config.failures.responses.host.failure.vm.restart.restartvms'),
               vmRestartLabel: getString('ha.config.failures.responses.host.failure.vm.restart.label'),
               vmRestartDescription: getString('ha.config.failures.responses.host.failure.vm.restart.restartvms.desc'),
               subMenuLabel: getString('ha.config.failures.responses.host.failure.response.submenu.label'),
               responseLabel: getString('ha.config.failures.responses.host.failure.response.label')
            };

            vm.hostFailureOptions = {
               disabled: haHostFailureResponseService.getDisabledOption(),
               vmRestart: getVmRestartOption()
            };

            vcService.is65VcOrLater(objectId)
                  .then(function(is65VcOrLater) {
                     vm.restartPriorityOptions = haHostFailureResponseService
                           .getVmRestartPriorityOptions(is65VcOrLater);
                     // Default values.
                     vm.data = {
                        hostFailure: vm.hostFailureOptions.disabled.id,
                        restartPriority: vm.restartPriorityOptions.medium.id
                     };

                     vm.haData.is65VcOrLater = is65VcOrLater;
                     vm.is65VcOrLater = is65VcOrLater;

                     if (is65VcOrLater) {
                        vm.readyConditionOptions =
                              haHostFailureResponseService.getReadyConditionOptions();

                        vm.vmDependencyRestartLabels = getVmDependencyRestartLabels();

                        vm.postReadyDelayPostParams = {
                           message: getVmDependencyRestartLabels().postReadyPostDelayDesc,
                           title: i18nService.getString('Common', 'help')
                        };

                        vm.restartPriorityTimeoutPostParams = {
                           message: getVmDependencyRestartLabels().restartPriorityTimeoutDesc,
                           title: i18nService.getString('Common', 'help')
                        };
                     }
                  }).then(function () {

               // This watcher sets the dropdown value for Host Failure when the restart
               // priority gets changed externally (e.g. after data is retrieved from the
               // backend.
               $scope.$watch('vm.haData.haConfiguration.defaultVmSettings.restartPriority',
                     function (newValue) {
                        if (newValue) {
                           var hostFailureDropDownValue = vm.hostFailureOptions.disabled.id;

                           // If the new value is not `disabled` it gets set to a made up
                           // value (that doesn't have a VMODL equivalent) in order to enable
                           // selection of a restart priority.
                           if (newValue !== vm.hostFailureOptions.disabled.id) {
                              hostFailureDropDownValue = vm.hostFailureOptions.vmRestart.id;
                              vm.data.restartPriority = newValue;
                           }
                           vm.data.hostFailure = hostFailureDropDownValue;
                        }
                     });

               $scope.$watch('vm.data.hostFailure', function (newValue) {
                  if (newValue) {
                     if (newValue !== vm.hostFailureOptions.vmRestart.id) {
                        vm.haData.haConfiguration.defaultVmSettings.restartPriority =
                              newValue;
                     } else {
                        if (!selectedRestartPriority) {
                           vm.haData.haConfiguration.defaultVmSettings.restartPriority =
                                 vm.restartPriorityOptions.medium.id;
                        } else {
                           vm.haData.haConfiguration.defaultVmSettings.restartPriority =
                                 selectedRestartPriority;
                        }
                     }
                  }
               });

               $scope.$watch('vm.data.restartPriority', function (newValue) {
                  // restartPriority dropdown should only be enabled and the corresponding
                  // model value be set only when the host failure is set to `vmRestart`.
                  if (newValue && vm.data.hostFailure ===
                        vm.hostFailureOptions.vmRestart.id) {
                     selectedRestartPriority = newValue;
                     vm.haData.haConfiguration.defaultVmSettings.restartPriority =
                           newValue;
                  }
               });
            });

            vm.MIN_RESTART_PRIORITY_TIMEOUT = 0;
            vm.MAX_RESTART_PRIORITY_TIMEOUT = haConstants.MAX_RESTART_PRIORITY_TIMEOUT;
            vm.MIN_POST_READY_DELAY = 0;
            vm.MAX_POST_READY_DELAY = haConstants.MAX_POST_READY_DELAY;

            vm.normalizePostReadyDelay = normalizePostReadyDelay;
            vm.normalizeRestartPriorityTimeout = normalizeRestartPriorityTimeout;

            function getString(key) {
               return i18nService.getString('ClusterUi', key);
            }

            function getVmRestartOption() {
               return {
                  id: "vmRestart",
                  label: vm.hostFailureResponses.vmRestart,
                  description: vm.hostFailureResponses.vmRestartDescription
               };
            }

            function getVmDependencyRestartLabels() {
               var vmDependencyRestartLabels = {
                  readyConditionLabel : getString(
                        "ha.config.failures.responses.host.failure.restart.criteria.label"),
                  readyConditionDescLabel : getString(
                        "ha.config.failures.responses.host.failure.restart.criteria.condition"),
                  postReadyDelay : getString(
                        "ha.config.failures.responses.host.failure.restart.criteria.delay.enable"),
                  postReadyPostDelayDesc : getString(
                        "ha.config.failures.responses.host.failure.restart.criteria.delay.desc"),
                  restartPriorityTimeout : getString(
                        "ha.config.failures.responses.host.failure.restart.criteria.timeout"),
                  restartPriorityTimeoutDesc : getString(
                        "ha.config.failures.responses.host.failure.restart.criteria.timeout.desc"),
                  seconds: getString(
                        "ha.config.failures.responses.host.failure.restart.criteria.delay.seconds")
               };

               return vmDependencyRestartLabels;
            }

            // region validation
            function normalizePostReadyDelay(event) {
               if (angular.isUndefined(event.target.value) ||
                     event.target.value < vm.MIN_POST_READY_DELAY) {
                  vm.haData.orchestration.defaultVmReadiness.postReadyDelay =
                        vm.MIN_POST_READY_DELAY;
               }

               if (event.target.value > haConstants.MAX_POST_READY_DELAY) {
                  vm.haData.orchestration.defaultVmReadiness.postReadyDelay =
                        haConstants.MAX_POST_READY_DELAY;
               }
            }

            function normalizeRestartPriorityTimeout(event) {
               if (angular.isUndefined(event.target.value) || event.target.value <
                     vm.MIN_RESTART_PRIORITY_TIMEOUT ) {
                  vm.haData.haConfiguration.defaultVmSettings.restartPriorityTimeout =
                        vm.MIN_RESTART_PRIORITY_TIMEOUT;
               }

               if (event.target.value > haConstants.MAX_RESTART_PRIORITY_TIMEOUT) {
                  vm.haData.haConfiguration.defaultVmSettings.restartPriorityTimeout =
                        haConstants.MAX_RESTART_PRIORITY_TIMEOUT;
               }
            }
         }]
      };
   }
})();
