namespace storage_ui {

   import IController = angular.IController;
   class ProtocolEndpointsController implements IController {
      static $inject = ["navigation"];

      constructor(private navigation: any) {
      }

      private getContextObject(): any {
         return this.navigation.getRoute().objectId;
      }
   }

   angular.module("com.vmware.vsphere.client.storage").controller(
         "ProtocolEndpointsController", ProtocolEndpointsController);

}
