(function() {
   'use strict';
   angular.module('com.vmware.vsphere.client.commonModule')
      .controller('AssignTagsDialogController', AssignTagsDialogController);

   AssignTagsDialogController.$inject = [
      '$scope',
      'AssignTagGridFactory',
      'i18nService',
      'actionsService'];

   function AssignTagsDialogController ($scope, AssignTagGridFactory, i18nService, actionsService) {
      var self = this;
      var CREATE_TAG_ACTION_ID = "vsphere.core.common.actions.addTag";
      var objectId = $scope.modalOptions.availableTargets[0];

      self.tagGrid = AssignTagGridFactory.make(objectId);

      function addTags () {
         actionsService.getAction(CREATE_TAG_ACTION_ID)
               .then(function (actionEval) {
                  var context = {
                     title: i18nService.getString("CommonModuleUi", "add.tag")
                  };
                  actionsService.invokeAction(actionEval, context);
               });
      }

      self.tagGrid.datagridOptions.actionBarOptions.actions[0].onClick = addTags;
      self.tagGrid.populateDataGrid();

      self.onTagSelectionChanged = function(selectedTag) {
         self.assignDisabled = !selectedTag;
      };

      self.tagGrid.setTagSelectionListener(self.onTagSelectionChanged);

      $scope.$on('refreshTagGrid', function(){
         self.tagGrid.populateDataGrid();
      });
      self.assignTag = function() {
         self.tagGrid.assignTag();
         self.closeModal();
      };

      self.closeModal = function() {
         $scope.closeModal();
      };

   }
}());
