namespace storage_ui {

   angular.module("com.vmware.vsphere.client.storage")
         .constant("StorageAdapterNetworkSettingsConstants", {
            IPv4Settings: {
               NONE: "none",
               DHCP: "dhcp",
               STATIC: "static"
            },
            IPv6Settings: {
               DHCP: "dhcp",
               ROUTER: "router",
               STATIC: "static",
               LINK_LOCAL: "linkLocal",
               OPERATION: {
                  ADD: "add",
                  REMOVE: "remove"
               }
            },
            AddressConfigurationType: {
               DHCP : "DHCP",
               AutoConfigured : "AutoConfigured",
               Static: "Static",
               Other:"Other"
            }
         });
}
