/* Copyright 2015 Vmware, Inc. All rights reserved. -- Vmware Confidential */
/**
 * The open console service retrieves required params in order to open a console in a new browser tab
 */
angular.module('com.vmware.vsphere.client.vm')
      .factory('openConsoleService', ['$rootScope', '$q', 'dataService', 'userSessionExService', 'browserLanguage',
         function ($rootScope, $q, dataService, userSessionExService, browserLanguage) {

            /** Public method **/
            return {
               openConsole: openConsole
            };

            function openConsole(vmUid, vmId, serverGuid) {
               var host, sessionTicket, thumbprint, vmName, locale;

               var namePromise = dataService.getProperties(vmUid, ['name']).then(getVMName);
               var ticketPromise = userSessionExService.acquireCloneTicket(serverGuid).then(getTicketInfo);

               $q.all([namePromise, ticketPromise]).then(function() {
                  buildURLOpenConsole(vmId, vmName, serverGuid, host, sessionTicket, thumbprint);
               });

               //--- Implementations ---//
               function getVMName(response) {
                  vmName = response.name;
               }

               function getTicketInfo(cloneSessionInfo) {
                  host = getHostFromURL(cloneSessionInfo.serverInfo.serviceUrl);
                  sessionTicket = cloneSessionInfo.sessionTicket;
                  thumbprint = cloneSessionInfo.serverInfo.thumbprint;
                  locale = browserLanguage.get();

                  function getHostFromURL(url) {
                     var l = document.createElement("a");
                     l.href = url;
                     return l.host;
                  }
               }

               function buildURLOpenConsole(vmId, vmName, serverGuid, host, sessionTicket, thumbprint) {
                  window.open("webconsole.html?vmId=" + vmId + "&vmName=" + encodeURIComponent(vmName) + "&serverGuid=" + serverGuid +
                        "&host=" + host + '&sessionTicket=' + sessionTicket + '&thumbprint=' + thumbprint + '&locale=' + locale);
               }
            }

         }]);
