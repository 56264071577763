namespace h5_client {
   export module common_module_ui {
      export enum LibraryOperations {
         invalid,
         fetchLibrary,
         addLibrary,
         deleteLibrary,
         updateLibrary
      }

      export class ContentLibraryConstants {
         static readonly LIBRARYTYPE_LOCAL: string = "LOCAL";
         static readonly LIBRARYTYPE_SUBSCRIBED: string = "SUBSCRIBED";

         static readonly SYNC_STRATEGY_IMMEDIATE: string = "SYNC_IMMEDIATE";
         static readonly SYNC_STRATEGY_ONDEMAND: string = "SYNC_ONDEMAND";

         static readonly CREATE_LIBRARY_DEFAULT_USER: string = "vcsp";

         static readonly CREATE_LIBRARY_AUTHENTICATION_BASIC: string = "BASIC";
         static readonly CREATE_LIBRARY_AUTHENTICATION_NONE: string = "NONE";
      }

      export module ContentLibraryConstants {
         export class Api {
            static readonly SCHEME: string = "https://";
            static readonly VAPI_REST_BASE: string = "/rest/com/vmware";
            static readonly VAPI_REST_SESSION: string = "/cis/session";

            static readonly ADD_LIBRARY_LOCAL: string = "/content/local-library";
            static readonly ADD_LIBRARY_SUBSCRIBED: string = "/content/subscribed-library";

            static readonly FETCH_LIBRARY: string = "/content/library/id:";

            static readonly DELETE_LIBRARY_LOCAL: string = "/content/local-library/id:";
            static readonly DELETE_LIBRARY_SUBSCRIBED: string = "/content/subscribed-library/id:";
         }
      }
   }
}