/* Copyright 2018 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace network_ui {

   export class PreloadSpriteComponent {

      public bindings: any;
      public controller: any;
      public template: string;

      constructor() {
         this.bindings = {
            onLoad: "&"
         };

         this.controller = PreloadSpriteComponentController;

         this.template = `<div ng-if="$ctrl.isInternetExplorer" style="display: none"
                               ng-include="'/ui/resources/img/svg-sprite.svg'"
                               onload="$ctrl.onLoad()">
                          </div>`;
      }
   }

   class PreloadSpriteComponentController {

      public onLoad: Function;

      public $onInit(): void {
         // sprite will not be loaded. signal ready.
         if (!this.isInternetExplorer) {
            this.onLoad();
         }
      }

      public get isInternetExplorer(): boolean {
         return typeof SVGForeignObjectElement === "undefined";
      }
   }

   angular.module("com.vmware.vsphere.client.network")
         .component("preloadSpriteComponent", new PreloadSpriteComponent());
}