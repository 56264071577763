namespace h5_vm {
   import VmProvisioningWizardPage = h5_vm.VmProvisioningWizardPage;

   export class CloneVmLibraryItemPageService implements VmProvisioningPageService {

      static $inject: string[] = [
         "i18nService",
         "vuiConstants",
         "vmVuiWizardPageBuilder",
         "cloneVmLibraryItemPageModel"
      ];

      constructor(private i18nService: any,
                  private vuiConstants: any,
                  private vmVuiWizardPageBuilder: any,
                  private cloneVmLibraryItemPageModel: any) { }

      public build(wizardScope: any,
                   defaultTarget?: any,
                   preselectedComputeResource?: any,
                   preselectedStorage?: any): VmProvisioningWizardPage {

         let pageModel = new this.cloneVmLibraryItemPageModel(
               wizardScope);

         wizardScope.cloneVmLibraryItemPageModel = pageModel;

         return this.vmVuiWizardPageBuilder.buildVuiWizardPage(wizardScope.config, {
            title: this.i18nService.getString("ProvisioningUiLib", "LibraryItemPage.title"),
            description: this.i18nService.getString("ProvisioningUiLib", "LibraryItemPage.description"),
            contentUrl: "vm-ui/resources/vm/views/clone/clone-vm-library-item.html",
            model: pageModel
         });
      };
   }

   angular.module("com.vmware.vsphere.client.vm")
         .service("cloneVmLibraryItemPageService", CloneVmLibraryItemPageService);
}
