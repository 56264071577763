namespace h5_vm {
   import IController = angular.IComponentController;
   import OvfConstants = h5_vm.OvfDeployConstants;

   class VmProvisioningSelectOvfComponent {
      public templateUrl: string;
      public controller: any;
      public controllerAs: string;
      public bindings: any;
      constructor () {
         this.templateUrl = "vm-ui/resources/vm/views/createVmWizard/deployOvfPages/vmProvisioningSelectOvf/vm-provisioning-select-ovf.component.html";
         this.controller = VmProvisioningSelectOvfController;
         this.controllerAs = "ctrl";
         this.bindings = {
            pageModel: "<",
            wizard: "<",
            wizardManager: "<",
            config: "<"
         };
      }
   }
   /**
    * The select template (local/url) page controller.
    */
   class VmProvisioningSelectOvfController implements IController {
      private form: any;
      private wizard: any;
      private wizardManager: any;
      private config: any;
      private pageModel: any;
      private i18n: any;
      private fileBrowserOptions: any;
      private ovfSelectionErrorMsg: string = '';
      private mode: string = "deploy";
      private contextObjId: any;
      private sslThumbprint: any;
      private readonly DUMMY_LIBRARY_ID: string = "urn:vapi:com.vmware.content.Library:Dummy-Library-Id";

      static $inject: string[] = [
         '$scope',
         'AngularInjector',
         'clarityModalService',
         'i18nService',
            'vuiConstants',
         'featureFlagsService',
         'vscOvfSelectionValidatorProxyService'
      ];

      constructor (private $scope: any,
             private AngularInjector: any,
             private clarityModalService: any,
             private i18nService: any,
             private vuiConstants: any,
             private featureFlagsService: any,
             private ovfSelectionValidator: any) {
         this.i18n = i18nService.getString;
      }

      $onInit () {
         this.form = this.pageModel.form;
         this.fileBrowserOptions = {
            enabled: true,
            showValidationErrors: true,
            selectMultiple: true,
            fileFilter: []
         };
         this.contextObjId = this.pageModel.getContextObjId();

         this.wizard.currentPage.onCommit = () => {
            const uriProbeService = this.AngularInjector.get('vscUriProbeService');

            if (!this.form.hasAcceptedUntrustedSource && !this.form.isLocalDeployment) {
               this.config.loading = true;
               this.config.loadingMessage = this.i18n("CommonUi", "wizard.validating");
               return uriProbeService.validate({url: this.form.ovfUrl,
                  libraryId: this.DUMMY_LIBRARY_ID
               }).then((resultObj: any) => {
                  this.config.loading = false;
                  if (resultObj && resultObj.unknownSource) {
                     this.openSslThumbprintAlert(resultObj.sslThumbprint);
                     return false;
                  }
                  this.wizard.currentPage.state = this.vuiConstants.wizard.pageState.COMPLETED;
                  this.pageModel.submitPage();
                  return true;
               }, (error: any) => {
                  this.form.isValid = false;
                  this.ovfSelectionErrorMsg = (error && error.displayErrors) ? error.displayErrors[0] : '';
                  return false;
               });
            } else {
               this.wizard.currentPage.state = this.vuiConstants.wizard.pageState.COMPLETED;
               this.pageModel.submitPage();
               return true;
            }
         };
      }

      private openSslThumbprintAlert(sslThumbprint: any): void {
         this.clarityModalService.openConfirmationModal({
            title: this.i18n("LibraryUi", "ProbeUtil.probeFailureDialog.title"),
            message: !_.isEmpty(sslThumbprint)? this.i18nService.getString("LibraryUi",
                  "ProbeUtil.probeFailureDialog.message.certificateErrorWithThumbprint",
                  sslThumbprint): this.i18nService.getString("LibraryUi",
                  "ProbeUtil.probeFailureDialog.message.certificateError"),
            preserveNewlines: !_.isEmpty(sslThumbprint)? true: false,
            saveButtonLabel: this.i18n("LibraryUi", "yes"),
            clarityIcon: {
               shape: "warning",
               class: "is-warning",
               size: !_.isEmpty(sslThumbprint)? 64: 32
            },
            submit: () => {
               this.form.sslThumbprint = sslThumbprint;
               this.form.hasAcceptedUntrustedSource = true;
               this.wizardManager.onNextClick();
               return true;
            },
            onCancel: () => {
               this.form.sslThumbprint = "";
            }
         });
      }

      onSelectorComponentStatusChanged = (sourceSelectorEvent: any) => {
         if (!sourceSelectorEvent.isValid) {
            this.form.ovfUrl = null;
            this.form.selectedFiles = null;

            //isValid = true can be still not usable for localfile
            //we need to do a deep OVF check to be sure that User's file selection is
            //valid indeed
            //but false always means unable to proceed in any case
            this.form.isValid = false;
            this.ovfSelectionErrorMsg = '';
            return;
         }

         if (sourceSelectorEvent.sourceType === OvfConstants.DEPLOYMENT_URL) {
            this.form.isValid = true;
            this.form.isLocalDeployment = false;
            this.form.ovfUrl = sourceSelectorEvent.url;
            this.ovfSelectionErrorMsg = '';
         } else {
            this.form.isLocalDeployment = true;
            if (sourceSelectorEvent.workflowType === OvfConstants.TEMPLATE_TYPE_OVF) {
               this.ovfSelectionValidator.validate(this.contextObjId, sourceSelectorEvent.selectedFiles)
                     .then((result: any) => {
                           this.form.isValid = !result.hasError;
                           this.form.userSelectedFiles = sourceSelectorEvent.selectedFiles;
                           this.ovfSelectionErrorMsg = result.hasError ? result.errorMsg : '';
                     });
            } else if (sourceSelectorEvent.workflowType === OvfConstants.TEMPLATE_TYPE_OVA) {
               //invoke OvaMapper: receive the list of files
               let ovaMapperService: any = this.AngularInjector.get('vscOvaMapperProxyService');
               if (ovaMapperService) {
                  ovaMapperService.getFilesFromOva(sourceSelectorEvent.selectedFiles[0])
                        .then((ovaFiles: any[]) => {
                           this.ovfSelectionValidator.validate(this.contextObjId, ovaFiles)
                                 .then((result: any) => {
                                    this.form.isValid = !result.hasError;
                                    this.form.userSelectedFiles = ovaFiles;
                                    this.ovfSelectionErrorMsg = result.hasError ? this.i18n('ProvisioningUiLib', 'DeployOvfPage.invalidOva') : '';
                                 });
                        });
               }
            }
            this.form.selectedFiles = sourceSelectorEvent.selectedFiles;
         }
      }
   }
   angular
       .module("com.vmware.vsphere.client.vm")
       .component("vmProvisioningSelectOvfComponent",
             new VmProvisioningSelectOvfComponent());
}
