module ds_cluster_ui {

   import DsClusterModel = ds_cluster_ui.DsClusterModel;
   class SdrsRecommendationsController {
      private watchForObjects: any;
      private objectId: string;
      private liveRefreshProperties: Array<string>;
      private sdrsRecommendationsData: any;
      private recommendationsTitle: string;
      private recommendationStore: any;

      public static $inject = ["$scope", "dataService", "i18nService",
         "columnRenderersRegistry", "defaultUriSchemeUtil", "drsRecommendationsService",
         "clarityModalService", "mutationService", "dsClusterSpecBuilderService",
         "$rootScope", "dsClusterConstants"];

      constructor(private $scope: any, private dataService: any, private i18nService: any,
                  private columnRenderersRegistry: any,
                  private defaultUriSchemeUtil: any,
                  private drsRecommendationsService: any,
                  private clarityModalService: any, private mutationService: any,
                  private dsClusterSpecBuilderService: any, private $rootScope: any,
                  private dsClusterConstants: any) {

         this.objectId = $scope._route.objectId;
         this.watchForObjects = [this.objectId];
         this.liveRefreshProperties =
               [this.dsClusterConstants.liveRefreshProperties.RECOMMENDATION];
         this.recommendationsTitle = this.getString(
               "dscluster.services.recommendationsLabel");
         this.recommendationStore =
               this.drsRecommendationsService.createRecommendationsStore(new Array(0));
         this.getViewData();
      }

      private getViewData() {
         this.dataService.getProperties(this.objectId, ["computedSdrsRecommendations"])
               .then((sdrsRecommendationsData: any) => {
                  this.sdrsRecommendationsData = sdrsRecommendationsData;
               });
      }

      runDrs() {
         let refreshDsClusterRecommendationsSpec = {
            operation: "refresh"
         };
         this.mutationService.apply(this.objectId,
               "com.vmware.vsphere.client.dscluster.services.sdrs.DatastoreClusterRecommendationSpec",
               refreshDsClusterRecommendationsSpec);
      }

      private getString(key: string, ...params: string[]) {
         return this.i18nService.getString("DsClusterUi", key, params);
      }
   }

   angular.module("com.vmware.vsphere.client.dsCluster")
         .controller("SdrsRecommendationsController", SdrsRecommendationsController);
}
