/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace h5_vm {
   import IPromise = angular.IPromise;
   import IQService = angular.IQService;
   import ManagedObjectReference = com.vmware.vim.binding.vmodl.ManagedObjectReference;
   import DatastoreMirrorsInfo = com.vmware.vsphere.client.vm.api.DatastoreMirrorsInfo;

   /**
    * Processes vm storage configuration required for migrating a vm to a resource in
    * another datacenter when vm storage is not to be changed. If a datastore is added to
    * hosts in different datacenters it is represented in the inventory by 2 separate
    * MORs and the datastores are considered mirrored`. In this case we should specify
    * the MOR of the mirror datastore in the final relocate spec. This is why we need
    * to find those mirror datastore refs and give it ot the relocate API.
    */
   export class VmMigrateStretchedStorageService {
      public static $inject = ["$q", "dataServiceUtil", "defaultUriSchemeUtil", "managedEntityConstants"];

      private PROP_DC: string = "dc";
      private PROP_VM_DS_INFO: string = "vmDatastoresInfo";
      private PROP_VM_VIRTUAL_DISKS: string = "virtualDisks";
      private PROP_VM_HOME_DATASTORE: string = "vmHomeDatastore";

      private _objectProperties: {[objId: string]: {[propName: string]: any}} = {};

      constructor(private $q: IQService,
                  private dataServiceUtil: any,
                  private defaultUriSchemeUtil: any,
                  private managedEntityConstants: any) {

      }

      /**
       * Collects VM storage configuration given a target resource (Host, Cluster
       * Resource pool, VApp). For all vms NOT in the target resource datacenter returns
       * storage configuration containing the correct MORs to the mirrored datastores
       * that need to be used for vm config file and vm disks location
       * @param targetResource The host, cluster, resource pool or vapp where vms are to
       *    be migrated.
       * @param sourceVms The vms being migrated
       * @returns {any} A promise resolved with dictionary containing an array of
       *    VirtualDiskRecord objects for each vm that is no the the same datacenter
       *    as the target resource. If all vms are in the same datacenter returned
       *    promise is resolved with undefined.
       */
      public processStretchedStorage(
            targetResource: string,
            sourceVms: string[]): IPromise<any> {
         return this.fetchVmsUsingStretchedStorage(
               targetResource, sourceVms
         ).then((vms: string[]) => {
               if (!vms.length) {
                  return undefined;
               }

               let requests: IPromise<void>[] = [
                     this.fetchVmProperties(vms),
                     this.fetchAccessibleDatastores(targetResource)];

               return this.$q.all(requests).then(() => {
                  let result = this.buildVmDisksRecords(targetResource, vms);
                  return result;
               });
         });
      }

      /**
       * Returns a promise resolved with an array containing all vms that are not in the
       * target resource datacenter.
       */
      private fetchVmsUsingStretchedStorage(targetResource: string, vms: string[])
            : IPromise<string[]> {
         return this.requestMissingProperties(
               vms.concat(targetResource), [this.PROP_DC]
         ).then(() => {
            let targetDc: any = this._objectProperties[targetResource][this.PROP_DC];
            return _.filter(vms, (vmId: string) =>
         !this.equalReferences(this._objectProperties[vmId][this.PROP_DC], targetDc));
         });
      }

      private fetchVmProperties(vms: string[]): IPromise<any> {
         return this.requestMissingProperties(vms, [
               this.PROP_VM_DS_INFO,
               this.PROP_VM_HOME_DATASTORE,
               this.PROP_VM_VIRTUAL_DISKS]);
      }

      private fetchAccessibleDatastores(
         targetResource: string): IPromise<any> {

         return this.requestMissingProperties(
               [targetResource],
               [this.getDatastorePropertyName(targetResource)]);
      }

      private getDatastorePropertyName(targetResource: string) {
         let objectType: string = this.defaultUriSchemeUtil.getEntityType(targetResource);
         let datastoreProperty: string =
               objectType === this.managedEntityConstants.V_APP
               || objectType === this.managedEntityConstants.RESOURCE_POOL ?
                     "computeResourceDatastore" : "datastore";
         return datastoreProperty;
      }

      /**
       * Checks whether all properties have already been retrieved for the object and if
       * all have been fetched returns those values. Otherwise, if any give property
       * is missing then fetches all properties specified.
       */
      private requestMissingProperties(objs: string[], properties: string[]): IPromise<void> {
         // Data should be retrieved for an object if no data for the object has yet been
         // retrieved or some properties are missing.
         let missingObjs: string[] = _.filter(objs, (obj: string) => {
            return !this._objectProperties[obj]
                  || _.some(properties, (prop: string) => !this._objectProperties[obj][prop]);
         });

         return !objs.length ?
               this.$q.resolve() :
               this.dataServiceUtil.getPropertiesForObjects(missingObjs, properties).then(
                     (response: any) => {
                        this.addPropertiesToCache(response);
                     }
               );
      }

      private addPropertiesToCache(response: any) {
         _.each(response, (data: any, objId: string) => {
            if (!this._objectProperties[objId]) {
               this._objectProperties[objId] = data;
            } else {
               angular.extend(this._objectProperties[objId], data);
            }
         });
      }

      private buildVmDisksRecords(
            targetHost: string,
            vms: string[]): any {
         let diskByVm: any = {};
         let resourceAccessibleDatastores: any[] =
               this._objectProperties[targetHost][this.getDatastorePropertyName(targetHost)];
         _.each(vms, (vmId: string) =>{
            let props: any = this._objectProperties[vmId];
            diskByVm[vmId] = [{
               key: "_configFile",
               storage: this.findMirroredDatastore(
                     _.find(props[this.PROP_VM_DS_INFO],
                           (dsInfo: DatastoreMirrorsInfo) => this.equalReferences(
                                 dsInfo.ref, props[this.PROP_VM_HOME_DATASTORE])),
                     resourceAccessibleDatastores)
            }];
            _.each(props[this.PROP_VM_VIRTUAL_DISKS], (diskData: any) => {
               diskByVm[vmId].push({
                  key: diskData.key,
                  storage: this.findMirroredDatastore(
                        _.find(props[this.PROP_VM_DS_INFO],
                              (dsInfo: DatastoreMirrorsInfo) => this.equalReferences(
                                    dsInfo.ref, diskData.backing.datastore)),
                        resourceAccessibleDatastores)
               });
            });
         });
         return diskByVm;
      }

      private findMirroredDatastore(
            vmDatastoreInfo: DatastoreMirrorsInfo, resourceAccessibleDatastores: any[]) {
         let mirrorRef: any = _.find(
               vmDatastoreInfo.mirrors,
               (mirrorRef: any) => {
                  return _.find(resourceAccessibleDatastores, (datastoreRef: any) => {
                     return this.equalReferences(datastoreRef, mirrorRef);
                  });
               });
         return this.defaultUriSchemeUtil.getVsphereObjectId(mirrorRef);
      }

      private equalReferences(ref1: any, ref2: any) {
         return (ref1.value === ref2.value) &&
               (ref1.type === ref2.type) &&
               (ref1.serverGuid === ref2.serverGuid);
      }
   }

   angular.module("com.vmware.vsphere.client.vm").factory(
         "vmMigrateStretchedStorageService",
         ["$injector", function($injector: any) {
            return {
               newInstance: function() {
                  return $injector.instantiate(VmMigrateStretchedStorageService);
               }
            };
         }]
   );
}
