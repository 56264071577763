/**
 * Created by vmware on 12/2/16.
 */
(function() {
   'use strict';

   angular.module('com.vmware.vsphere.client.vm')
      .component('vmHardwareSummaryVideoCard', {
         bindings: {
            device: '<'
         },
         templateUrl: 'vm-ui/resources/vm/views/summary/vmHardwareSummaryVideoCard.html',
         controller: VmHardwareSummaryVideoCardController
      });

   VmHardwareSummaryVideoCardController.$inject = [
      'i18nService'
   ];

   function VmHardwareSummaryVideoCardController(i18nService) {
      this.i18n = i18nService.getString;
   }
})();
