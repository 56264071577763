/* Copyright 2018 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace h5_vm {

   import ScheduledMutationOperationSpec = com.vmware.vise.data.scheduling.ScheduledMutationOperationSpec;
   import ManagedObjectReference = com.vmware.vim.binding.vmodl.ManagedObjectReference;
   import VmCreateScheduledTaskEditDefaultsValidationSpec = com.vmware.vsphere.client.h5.vm.model.provisioning.VmCreateScheduledTaskEditDefaultsValidationSpec;
   import VmCreateScheduledTaskEditDefaultsData = com.vmware.vsphere.client.h5.vm.model.provisioning.VmCreateScheduledTaskEditDefaultsData;
   import ValidationResult = com.vmware.vise.core.model.ValidationResult;
   import RelocateSpec = com.vmware.vim.binding.vim.vm.RelocateSpec;
   import GroupInfo = com.vmware.vim.binding.sms.storage.replication.GroupInfo;

   export interface CloneVmData {
      vmId: string;
      contextObjId: string;
      pool: ManagedObjectReference;
      host: ManagedObjectReference;
      computeResourceId: string | null;
      spec: any;
      datastoreRefs: { [index: string]: ManagedObjectReference };
      name: string;
      storageData: StorageData;
      useScheduledTaskData: boolean;
      guestOsId: string;
      originalVmName: string;
   }

   export class VmCloneEditScheduledTaskService {

      public static $inject = ["managedEntityConstants", "defaultUriSchemeUtil",
         "mutationService", "vmDeviceInfoService", "initialStorageConfigurationService"];

      constructor(private managedEntityConstants: any,
            private defaultUriSchemeUtil: any,
            private mutationService: any,
            private vmDeviceInfoService: any,
            private initialStorageConfigurationService: InitialStorageConfigurationService) {
      }

      buildCreateVmData(schedulingData: ScheduledMutationOperationSpec): CloneVmData {
         const vmId = this.defaultUriSchemeUtil.getVsphereObjectId(schedulingData.entity);
         const specArguments = schedulingData.spec.argument;
         const contextObjId: string = this.defaultUriSchemeUtil.getVsphereObjectId(specArguments[0].value);
         const spec = specArguments[2].value;
         return {
            vmId: vmId,
            contextObjId: contextObjId,
            pool: spec.location.pool,
            host: spec.location.host,
            spec: spec,
            name: specArguments[1].value,
            useScheduledTaskData: true,
         } as CloneVmData;
      }

      setVmParams(data: CloneVmData, vmParams: any, wizardViewData: any) {
         vmParams.setVmId(data.vmId);
         vmParams.setName(data.name);
         wizardViewData.setSourceObjectName(data.originalVmName);
         vmParams.setScheduledData(data);
         vmParams.setGuestOsId(data.guestOsId);
      }

      requestScheduledData(contextObjectId: string, data: CloneVmData): IPromise<VmCreateScheduledTaskEditDefaultsData> {
         const spec = new VmCreateScheduledTaskEditDefaultsValidationSpec();
         spec.host = data.host;
         spec.pool = data.pool;
         spec.deviceChange = data.spec.location.deviceChange;
         spec.locators = data.spec.location.disk;
         spec.vmProfile = data.spec.location.profile;
         spec.vm = this.defaultUriSchemeUtil.getManagedObjectReference(data.vmId);
         return this.mutationService.validate(contextObjectId, spec._type, spec)
               .then((result: ValidationResult) => {
                  const resultData = result.result as VmCreateScheduledTaskEditDefaultsData;
                  data.computeResourceId = this.defaultUriSchemeUtil.getVsphereObjectId(
                        resultData.computeResource);
                  data.storageData = this.prepareStorageState(data.vmId,
                        data.spec.location, resultData.replicationGroupsData);
                  data.guestOsId = resultData.guestOsId;
                  data.originalVmName = resultData.name;
               });
      }

      private prepareStorageState(vmId: string, location: RelocateSpec,
            replicationGroupsData: { [key: string]: GroupInfo }): StorageData {
         const storageData = {
            initialVmStorageConfig: this.initialStorageConfigurationService
                  .computeInitialVmStorageConfig(vmId, location, replicationGroupsData),
            initialViewMode: this.initialStorageConfigurationService.computeStoragePageViewMode(location)
         } as StorageData;

         return storageData;
      }
   }

   angular.module("com.vmware.vsphere.client.vm")
         .service("vmCloneEditScheduledTaskService", VmCloneEditScheduledTaskService);
}
