module ds_cluster_ui {

   import WizardPageControllerAbstract =
         ds_cluster_ui.WizardPageControllerAbstract;
   import IPromise = angular.IPromise;

   class HostsAndClustersPageController extends WizardPageControllerAbstract {

      private config: Object;
      public listLabel: string;

      public static $inject = ['$scope', 'createDsClusterWizardManager',
            'simpleValidationCommit', 'vuiConstants', 'i18nService', '$q',
            'createDsClusterPageConstants'];

      constructor(private $scope: ScopeRoute,
            createDsClusterWizardManager: CreateDsClusterWizardManager,
            simpleValidationCommit: any, private vuiConstants: any,
            private i18nService: any, private $q: any,
            private createDsClusterPageConstants: any) {
         super(createDsClusterWizardManager, simpleValidationCommit);
         this.listLabel = this.i18nService.getString(
               "DsClusterUi", "createDsClusterWizard.hostsAndClustersPage.title");
         this.initModel();
         this.initPageSubmit();
      }

      protected getValidationErrors(): IPromise<string[]> {
         if (this.$scope.model.hostsAndClusters.length <= 0) {
            return this.$q.resolve([this.i18nService.getString('DsClusterUi',
                  'createDsClusterWizard.hostsAndClustersPage.noHostOrClusterSelectedErrorMessage')]);
         }

         return this.$q.reject();
      }

      protected getCurrentPageIndex(): number {
         return this.createDsClusterPageConstants.HOSTS_AND_CLUSTERS_INDEX;
      }

      private initModel(): void {
         let self: HostsAndClustersPageController = this;
         this.$scope.model = this.createDsClusterWizardManager.getModel();
         let parentId: string = this.$scope.model.objectId;

         this.config = {
            contextObject: parentId,
            multipleSelect: true,
            listTabConfig: {
               listConfig: [{
                  label: this.i18nService.getString('DsClusterUi',
                        'createDsClusterWizard.hostsAndClustersPage.clusters'),
                  listViewId: "vsphere.core.cluster.list",
                  dataModels: ['ClusterComputeResource'],
                  filterId: "datacenterObjectsFilter",
                  filterParams: []
               }, {
                  label: this.i18nService.getString('DsClusterUi',
                        'createDsClusterWizard.hostsAndClustersPage.hosts'),
                  listViewId: "vsphere.core.host.list",
                  dataModels: ['HostSystem'],
                  filterId: "standaloneHostsFilter",
                  filterParams: []
               }]
            },
            selectedObjects: self.$scope.model.hostsAndClusters,
            onSelectionChanged: function(newSelectedItems: any[]) {
               self.$scope.model.hostsAndClusters = _.map(newSelectedItems,
                     function(object: any) {
                        return object.id;
                     });
            }
         };

         this.$scope.$watch('model.hostsAndClusters', () => {
            this.getValidationErrors().then(() => {
               this.setWizardPageState(this.vuiConstants.wizard.pageState.INCOMPLETE);
            }, () => {
               this.setWizardPageState(this.vuiConstants.wizard.pageState.COMPLETED);
            });
         });
      }
   }

   angular.module('com.vmware.vsphere.client.dsCluster')
         .controller('HostsAndClustersPageController', HostsAndClustersPageController);
}
