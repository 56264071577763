(function () {
   'use strict';
   angular.module('com.vmware.vsphere.client.vm').factory('VirtualNetworkDevice', [
      'EditableVirtualDevice',
      'vmDeviceInfoService',
      'defaultUriSchemeUtil',
      function(EditableVirtualDevice, vmDeviceInfoService, defaultUriSchemeUtil) {
         function VirtualNetworkDevice(device, theNetwork) {
            var portKey = device.backing && device.backing.port? device.backing.port.portKey: null;

            device.backing = vmDeviceInfoService.buildNetworkDeviceBacking(theNetwork);

            if (device.backing && device.backing.port && portKey !== null) {
               device.backing.port.portKey = portKey;
            }

            EditableVirtualDevice.call(this, device);
            this.network = theNetwork;
         }

         VirtualNetworkDevice.prototype.getNetworkId = function() {
            if (!this.network) {
               return; //undefined
            }
            var networkRef = this.network.portgroup;
            if (!networkRef) {
               networkRef = this.network.network.network;
            }
            return defaultUriSchemeUtil.getVsphereObjectId(networkRef);
         };

         VirtualNetworkDevice.prototype.getNetworkName = function() {
            return this.network ? this.network.displayName : undefined;
         };

         VirtualNetworkDevice.prototype.getNetwork = function() {
            return this.network;
         };

         VirtualNetworkDevice.prototype.setNetwork = function(newNetwork) {
            if(newNetwork) {
               this.network = newNetwork;
               this.getCurrentDevice().backing = vmDeviceInfoService.buildNetworkDeviceBacking(newNetwork);
            }
         };

         return VirtualNetworkDevice;
      }
   ]);
})();
