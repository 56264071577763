module ds_cluster_ui {

   import IComponentController = angular.IComponentController;

   class DsClusterConsumersPortletController implements IComponentController {

      public static $inject = ["$rootScope", "i18nService", "dsClusterConsumersService",
         "dsClusterConstants"];

      public objectId: string;
      public liveRefreshProperties: Array<string>;
      public consumersData: Object;

      constructor(private $rootScope: RootScopeRoute, private i18nService: any,
            private dsClusterConsumersService: any, private dsClusterConstants: any) {
         this.objectId = this.$rootScope._route.objectId;
         this.liveRefreshProperties =
               [this.dsClusterConstants.liveRefreshProperties.SUMMARY];
         this.getData();
      }

      public getData(): void {
         this.dsClusterConsumersService.getPropertyViewData(this.objectId)
               .then((data: Object) => {
                  this.consumersData = data;
               });
      }
   }

   angular.module("com.vmware.vsphere.client.dsCluster")
         .controller("DsClusterConsumersPortletController",
               DsClusterConsumersPortletController);
}
