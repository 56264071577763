/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace network_ui {

   angular.module("com.vmware.vsphere.client.network").constant(
         "vmMigrateWizardConstants", {
      pages: {
         VM_SELECT_NETWORKS_PAGE: "selectNetworksPage",
         VM_SELECT_VMS_TO_MIGRATE_PAGE: "selectVmsToMigratePage",
         VM_MIGRATE_READY_TO_COMPLETE_PAGE: "readyToCompletePage"
      },
      properties: {
         NAME_PROPERTY: "name",
         ICON_PROPERTY: "primaryIconId",
         DATACENTER_PROPERTY: "dc",
         VM_NETWORKS_PROPERTY: "migratevms:vmsNetworkProperties"
      },
      objectType: {
         DATACENTER_TYPE: "Datacenter"
      },
      specs: {
         VM_MIGRATE_PROPERTY_SPEC: "com.vmware.vsphere.client.h5.network.vm.migrate.specs.MigrateVmPropertySpec",
         VM_MIGRATE_VM_VNICS_SPEC: "com.vmware.vsphere.client.h5.network.vm.migrate.specs.MigrateVmVnicsSpec",
         VM_MIGRATE_NETWORKING_SPEC: "com.vmware.vsphere.client.h5.network.vm.migrate.specs.MigrateVmNetworkingSpec",
         VM_NETWORK_PROPERTIES_SPEC: "com.vmware.vsphere.client.h5.network.vm.migrate.model.VmNetworkProperties"
      }
   });
}
