(function() {
   'use strict';

   angular.module('com.vmware.vsphere.client.vm')
         .service('vmProvisioningCloneCustomizeGuestOsPageService',
               vmProvisioningCloneCustomizeGuestOsPageService);

   vmProvisioningCloneCustomizeGuestOsPageService.$inject = ['i18nService',
      'vuiConstants',
      'vmVuiWizardPageBuilder',
      'VmProvisioningCloneCustomizeGuestOsPageModel'];
   function vmProvisioningCloneCustomizeGuestOsPageService(i18nService,
         vuiConstants,
         vmVuiWizardPageBuilder,
         VmProvisioningCloneCustomizeGuestOsPageModel) {
      return {
         build: function(wizardScope) {
            var virtualMachineSpecBuilder = wizardScope.vmParams;

            var pageModel = new VmProvisioningCloneCustomizeGuestOsPageModel(
                  virtualMachineSpecBuilder,
                  wizardScope.wizardViewData
            );
            wizardScope.vmProvisioningCloneCustomizeGuestOsPageModel = pageModel;

            return vmVuiWizardPageBuilder.buildVuiWizardPage(wizardScope.config, {
               title: i18nService.getString('VmUi',
                     'CustomizeGuestOsProvisioningPage.Title'),
               description: i18nService.getString('VmUi',
                     'CustomizeGuestOsProvisioningPage.Description'),
               contentUrl: 'vm-ui/resources/vm/views/createVmWizard/vmProvisioningCloneGuestOs.html',
               defaultState: vuiConstants.wizard.pageState.SKIPPED,
               model: pageModel
            });
         }
      };
   }
})();
