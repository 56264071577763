namespace spbm_ui {

   export class StorageProfilesListViewController {

      private hasWritePrivilegeOnAllVcs: boolean;
      private selectedProfiles: any[];
      private isCreateActionAvailable: boolean = false;

      private readonly GRANTED_PRIVILEGES: string = "grantedPrivileges";
      private readonly SPBM_UPDATE_PRIVILEGE: string = "StorageProfile.Update";
      private readonly STORAGE_PROFILE_VIEW_PRIVILEGE: string = "StorageProfile.View";
      private readonly CHECK_COMPLIANCE_ACTION: string = "vsphere.core.pbm.storage.checkProfileComplianceActionPrivate";
      private readonly REAPPLY_PROFILE_ACTION: string= "vsphere.core.pbm.storage.reapplyProfile";
      private readonly REMOVE_STORAGE_POLICY: string = "vsphere.core.pbm.storage.removeProfileAction";
      private readonly EDIT_STORAGE_POLICY: string = "vsphere.core.pbm.storage.editProfileAction";
      private readonly CLONE_STORAGE_POLICY: string = "vsphere.core.pbm.storage.cloneProfileAction";
      private readonly CREATE_PROFILE_ACTION: string = "vsphere.core.pbm.storage.createStorageProfileAction";
      private readonly REVERT_DEFAULT_VSAN_POLICY_ACTION: string = "vsphere.core.pbm.storage.resetVsanDefaultProfileAction";
      private readonly ACTION_BAR_ACTIONS: string = "actions";

      public actionBarOptions: any;

      public static $inject = ["$scope", "commonActionBarService",
         "defaultUriSchemeUtil", "dataService", "vuiConstants", "userSessionService"];

      private lastDsRequest: any;

      constructor(private $scope: any,
                  private commonActionBarService: any,
                  private defaultUriSchemeUtil: any,
                  private dataService: any,
                  private vuiConstants: any,
                  private userSessionService: any) {

         this.$scope.onSelectionChanged = (newItems: any, oldItems: any) => {
            $scope.masterDetailsViewContext.selectedItems = newItems;
            this.selectedProfiles = newItems;
            this.hasWritePrivilegeOnAllVcs = false;
            if (this.selectedProfiles && this.selectedProfiles.length) {
               let currentDsRequest = this.lastDsRequest = this.dataService.getPropertiesForObjects(
                     this.getDistinctVCs(),
                     [this.GRANTED_PRIVILEGES])
                     .then((privilegesOnVCs: any) => {
                        if (currentDsRequest === this.lastDsRequest) {
                           this.hasWritePrivilegeOnAllVcs = _.every(privilegesOnVCs, (vcPrivileges: any) => {
                              return _.contains(vcPrivileges.grantedPrivileges, this.SPBM_UPDATE_PRIVILEGE);
                           });
                        }
                     })
                     .finally(() => {
                        if (currentDsRequest === this.lastDsRequest) {
                           this.updateActionBar();
                        }
                     });
            } else {
               this.updateActionBar();
            }
         };

         this.actionBarOptions = {
            actions: []
         };

         this.getCreateActionAvailability();
         this.updateActionBar();
      }

      private updateActionBar() {
         if (this.actionBarOptions && this.actionBarOptions.actions && this.actionBarOptions.actions.length) {
            this.actionBarOptions.actions.forEach((action: any) => {
               if (action.actionId && action.actionId !== this.CREATE_PROFILE_ACTION) {
                  action.enabled = false;
               }
            });
         }

         let updateActionBarOptions: any = { actions: [] };
         this.commonActionBarService.updateActionBar(
               updateActionBarOptions,
               this.ACTION_BAR_ACTIONS,
               this.getProfilesIds(),
               this.getActions()).then(() => {
            this.actionBarOptions = updateActionBarOptions;
         });
      }

      private getActions() {
         let actionDefs: any[] = [];

         actionDefs.push({
            actionId: this.CREATE_PROFILE_ACTION,
            isActionAvailable: (actionDef: any) => {
               return this.isCreateActionAvailable && actionDef;
            }
         });
         actionDefs.push(this.vuiConstants.actions.SEPARATOR);

         actionDefs.push(
               {
                  actionId: this.EDIT_STORAGE_POLICY,
                  getActionInvocationContext: () => {
                     return this.getProfilesIds()[0];
                  },
                  isActionVisible: (actionDef: any) => {
                     return this.selectedProfiles && this.selectedProfiles.length === 1
                           && actionDef && actionDef.available && this.hasWritePrivilegeOnAllVcs;
                  }
               },
               {
                  actionId: this.CLONE_STORAGE_POLICY,
                  getActionInvocationContext: () => {
                     return this.getProfilesIds()[0];
                  },
                  isActionVisible: (actionDef: any) => {
                     return this.selectedProfiles && this.selectedProfiles.length === 1
                           && actionDef && actionDef.available && this.hasWritePrivilegeOnAllVcs;
                  }
               },
               {
                  actionId: this.CHECK_COMPLIANCE_ACTION,
                  getActionInvocationContext: () => {
                     return this.getProfilesIds();
                  },
                  isActionVisible: (actionDef: any) => {
                     return this.selectedProfiles && this.selectedProfiles.length
                           && actionDef && actionDef.available && this.hasWritePrivilegeOnAllVcs;
                  }
               }, {
                  actionId: this.REAPPLY_PROFILE_ACTION,
                  getActionInvocationContext: () => {
                     return this.getProfilesIds();
                  },
                  isActionVisible: (actionDef: any) => {
                     return this.selectedProfiles && this.selectedProfiles.length
                           && actionDef && actionDef.available && this.hasWritePrivilegeOnAllVcs;
                  }
               }, {
                  actionId: this.REMOVE_STORAGE_POLICY,
                  getActionInvocationContext: () => {
                     return this.getProfilesIds();
                  },
                  isActionVisible: (actionDef: any) => {
                     return this.selectedProfiles && this.selectedProfiles.length
                           && actionDef && actionDef.available && this.hasWritePrivilegeOnAllVcs;
                  }
               });

         actionDefs.push({
            actionId: this.REVERT_DEFAULT_VSAN_POLICY_ACTION,
            getActionInvocationContext: () => {
               return this.getProfilesIds()[0];
            },
            isActionVisible: (actionDef: any) => {
               return this.selectedProfiles && this.selectedProfiles.length === 1
                   && actionDef && actionDef.available && this.hasWritePrivilegeOnAllVcs;
            }
         });

         return actionDefs;
      }

      private getDistinctVCs(): string[] {
         let vcGuids: string[] = _.map(this.selectedProfiles, profile => {
            // This method returns the URI segments in an array,
            // the third one being the VC guid
            return this.defaultUriSchemeUtil.getUriIdSegments(profile.id)[2];
         });

         let vcMoRefs: string[] = _.map(vcGuids, vcGuid => {
            return this.defaultUriSchemeUtil.getRootFolder(vcGuid);
         });

         return _.uniq(vcMoRefs);
      }

      private getProfilesIds(): string[] {
         return _.pluck(this.selectedProfiles, "id");
      }

      private getCreateActionAvailability(): void {
         this.isCreateActionAvailable = false;
         this.getServersInfo().then((result: any[]) => {
            if (result.length) {
               let vcMoRefs: string[] = _.map(result, info => {
                  return this.defaultUriSchemeUtil.getRootFolder(info.serviceGuid);
               });
               this.dataService.getPropertiesForObjects(
                     vcMoRefs,
                     [this.GRANTED_PRIVILEGES])
                     .then((privilegesOnVCs: any) => {
                        this.isCreateActionAvailable = _.some(privilegesOnVCs, (vcPrivileges: any) => {
                           return _.contains(vcPrivileges.grantedPrivileges, this.SPBM_UPDATE_PRIVILEGE) &&
                                 _.contains(vcPrivileges.grantedPrivileges, this.STORAGE_PROFILE_VIEW_PRIVILEGE);
                        });
                        this.updateActionBar();
                     });
            } else {
               this.updateActionBar();
            }
         });
      }

      private getServersInfo(): angular.IPromise<any> {
         return this.userSessionService.getAllServersInfo().then((info: any) => {
            let serversInfo: any[] = [];
            if (info && info.serversInfo) {
               info.serversInfo.forEach((serverInfo: any) => {
                  if (!serverInfo.errorCode ||
                        serverInfo.errorCode === this.userSessionService.VC_NO_ERROR_CODE) {
                     serversInfo.push(serverInfo);
                  }
               });
            }
            return serversInfo;
         });
      }
   }

   angular.module("com.vmware.vsphere.client.spbm")
         .controller("StorageProfilesListViewController", StorageProfilesListViewController);
}
