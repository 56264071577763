/* Copyright 2018 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace h5_vm {

   import ScheduledMutationOperationSpec = com.vmware.vise.data.scheduling.ScheduledMutationOperationSpec;
   import ManagedObjectReference = com.vmware.vim.binding.vmodl.ManagedObjectReference;
   import VmCreateScheduledTaskEditDefaultsValidationSpec = com.vmware.vsphere.client.h5.vm.model.provisioning.VmCreateScheduledTaskEditDefaultsValidationSpec;
   import VmCreateScheduledTaskEditDefaultsData = com.vmware.vsphere.client.h5.vm.model.provisioning.VmCreateScheduledTaskEditDefaultsData;
   import ValidationResult = com.vmware.vise.core.model.ValidationResult;
   import GroupInfo = com.vmware.vim.binding.sms.storage.replication.GroupInfo;

   export interface CreateVmData {
      contextObjId: string;
      pool: ManagedObjectReference;
      host: ManagedObjectReference;
      computeResourceId: string | null;
      spec: any;
      datastoreRefs: { [index: string]: ManagedObjectReference };
      storageData: StorageData;
      useScheduledTaskData: boolean;
   }
   export class VmCreateEditScheduledTaskService {

      public static $inject = ["managedEntityConstants", "defaultUriSchemeUtil",
         "mutationService", "vmDeviceInfoService", "initialStorageConfigurationService"];

      constructor(private managedEntityConstants: any,
            private defaultUriSchemeUtil: any,
            private mutationService: any,
            private vmDeviceInfoService: any,
            private initialStorageConfigurationService: InitialStorageConfigurationService) {
      }

      buildCreateVmData(schedulingData: ScheduledMutationOperationSpec): CreateVmData {
         const entity: ManagedObjectReference = schedulingData.entity;
         let contextObjId: string = this.defaultUriSchemeUtil.getVsphereObjectId(entity);
         let pool: ManagedObjectReference | undefined;
         let host: ManagedObjectReference;
         const specArguments = schedulingData.spec.argument;
         const spec = specArguments[0].value;

         if (this.isCreateOnFolder(entity)) {
            pool = specArguments[1].value;
            host = specArguments[2].value;
         } else {
            pool = entity;
            host = specArguments[1].value;
         }
         return {
            contextObjId: contextObjId,
            pool: pool,
            host: host,
            spec: spec,
            useScheduledTaskData: true
         } as CreateVmData;
      }

      setVmParams(data: CreateVmData, vmParams: any) {
         vmParams.setName(data.spec.name);
         // used for the preselection in the compatibility page
         vmParams.setScheduledVmVersion(data.spec.version);

         // used for the preselection in the select guest os page
         vmParams.setScheduledGuestOsId(data.spec.guestId);
         if (data.spec.alternateGuestName) {
            vmParams.setScheduledGuestName(data.spec.alternateGuestName);
         }
         vmParams.setScheduledData(data);
      }

      requestScheduledData(contextObjectId: string, data: CreateVmData): IPromise<VmCreateScheduledTaskEditDefaultsData> {
         const spec = new VmCreateScheduledTaskEditDefaultsValidationSpec();
         spec.host = data.host;
         spec.pool = data.pool;
         spec.datastoreNames = this.retrieveDatastoreNames(data.spec);
         spec.vmProfile = data.spec.vmProfile;
         return this.mutationService.validate(contextObjectId, spec._type, spec)
               .then((result: ValidationResult) => {
                  const resultData = result.result as VmCreateScheduledTaskEditDefaultsData;
                  data.datastoreRefs = resultData.datastoreRefs;
                  const datastoreRef = this.retrieveVmxDatastore(data.spec.files.vmPathName, resultData);
                  data.storageData = this.prepareStorageState(data, datastoreRef, data.datastoreRefs,
                        resultData.replicationGroupsData);
                  data.computeResourceId = this.defaultUriSchemeUtil.getVsphereObjectId(
                        resultData.computeResource);
               });
      }

      private retrieveVmxDatastore(vmxDatastoreName: string,
            resultData: VmCreateScheduledTaskEditDefaultsData): ManagedObjectReference {

         const match = vmxDatastoreName.match(/\[(.*)\]/);
         // If regex matched the property type then the regex group matching the
         // comma separated values should be under index 1 in the match
         // (index 0 is always the whole string)
         const datastoreName = match ? match[1] : "";
         const datastoreRef = resultData.datastoreRefs[datastoreName];
         return datastoreRef;
      }

      private retrieveDatastoreNames(spec: any): string[] {
         let datastoreNames: string[] = [];
         datastoreNames.push(spec.files.vmPathName);

         _.each(spec.deviceChange, (device: any) => {
            if (this.vmDeviceInfoService.isDeviceSubclassOf(device.device, "VirtualDisk")) {
               datastoreNames.push(device.device.backing.fileName);
            }
         });

         datastoreNames = _.uniq(datastoreNames);
         return datastoreNames;
      }

      private isCreateOnFolder(entity: ManagedObjectReference): boolean {
         const isOnFolder: boolean = (entity.type === this.managedEntityConstants.FOLDER);
         return isOnFolder;
      }

      private prepareStorageState(data: CreateVmData, datastoreRef: ManagedObjectReference,
            datastoreRefs: { [index: string]: ManagedObjectReference },
            replicationGroupsData: { [key: string]: GroupInfo }): StorageData {
         const storageData = {
            initialVmStorageConfig: this.initialStorageConfigurationService
                  .computeInitialVmStorageConfigForCreateVm(datastoreRef, data.spec.deviceChange,
                        datastoreRefs, data.spec.vmProfile, replicationGroupsData),
            initialViewMode: this.initialStorageConfigurationService.BASIC_MODE
         } as StorageData;

         return storageData;
      }
   }

   angular.module("com.vmware.vsphere.client.vm")
         .service("vmCreateEditScheduledTaskService", VmCreateEditScheduledTaskService);
}
