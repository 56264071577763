namespace storage_ui {

   export class OptionItem {
      public name: string;

      public description: string;

      public inherited: boolean|null;

      public value: any;

      public readOnly: boolean;

      public optionKey: string;

      public valueType: string;

      public allowedValues: any[]|null;

      public minValue: any|null;

      public maxValue: any|null;

      public validate: () => string[];

      public validator: any;
   }

   export class EditIscsiAdvancedOptionsService {

      public static $inject = ["i18nService", "clarityModalService", "clarityConstants",
         "mutationService", "advancedOptionConstants"];

      constructor(private i18nService: any,
                  private clarityModalService: any,
                  private clarityConstants: any,
                  private mutationService: any,
                  private advancedOptionConstants: any) {
      }

      public showAdvancedOptionsDialogForAdapter(hostId: string, hba: any) {
         this.showAdvancedOptionsDialogForTarget(hostId, hba, null);
      }

      public showAdvancedOptionsDialogForTarget(hostId: string, hba: any, targetSet: any) {
         let dialogData: any = {
            hostId: hostId,
            hba: hba,
            targetSet: targetSet,
            apiObj: {}
         };

         let modalOptions: any = {
            title: this.i18nService.getString("StorageUi", "storage.adapters.tabs.advancedOptions.header"),
            contentTemplate: "storage-ui/resources/storage/views/host/adapters/advanced/edit/EditIscsiAdvancedOptionsDialog.html",
            defaultButton: "submit",
            dialogData: dialogData,
            size: "lg",
            alerts: [],
            onSubmit: () => {
               if (!dialogData.apiObj.validate()) {
                  modalOptions.alerts = [{
                     text: this.i18nService.getString("StorageUi", "form.validationError"),
                     type: this.clarityConstants.notifications.type.ERROR
                  }];

                  return false;
               }

               let modifiedOptions = dialogData.apiObj.getModifiedOptions();
               if (!modifiedOptions.length) {
                  return true;
               }

               let spec: any;
               if (targetSet) {
                  spec = {
                     _type: "com.vmware.vsphere.client.storage.adapters.impl.ApplyTargetAdvancedSettingsSpec",
                     spec: this.buildIscsiTargetsAdvancedSettingsRetrievalSpec(hba, targetSet),
                     changeSet: this.buildChangeSet(modifiedOptions)
                  };
               } else {
                  spec = {
                     _type: "com.vmware.vsphere.client.storage.adapters.impl.ApplyInternetScsiAdvancedSettingsSpec",
                     adapterKey: hba.key,
                     changeSet: this.buildChangeSet(modifiedOptions)
                  };
               }

               this.mutationService.apply(hostId, spec._type, spec);
               return true;
            }
         };

         this.clarityModalService.openOkCancelModal(modalOptions);
      }

      public buildChangeSet(modifiedOptions: OptionItem[]) {
         return _.map(modifiedOptions, (option: OptionItem) => {
            return {
               _type: "com.vmware.vsphere.client.storage.adapters.impl.AdvancedOptionChangeSpec",
               value: {
                  _type: "com.vmware.vim.binding.vim.option.OptionValue",
                  key: option.optionKey,
                  value: [this.optionValueTypeToJavaType(option.valueType), option.value]
               },
               inherited: option.inherited
            };

         });
      }

      private optionValueTypeToJavaType(optionType:string): string {
         if (optionType === this.advancedOptionConstants.optionTypes.INT_OPTION_TYPE) {
            return 'java.lang.Integer';
         }

         if (optionType === this.advancedOptionConstants.optionTypes.LONG_OPTION_TYPE) {
            return 'java.lang.Long';
         }

         if (optionType === this.advancedOptionConstants.optionTypes.BOOLEAN_OPTION_TYPE) {
            return 'java.lang.Boolean';
         }
         return 'java.lang.String';
      }

      public buildIscsiTargetsAdvancedSettingsRetrievalSpec(hba: any, targetSet: any) {
         return {
            _type: "com.vmware.vsphere.client.storage.adapters.LocalizedInternetScsiTargetsAdvancedSettingsRetrievalSpec",
            digestCapabilities: hba.digestCapabilities,
            targetSet: targetSet,
            device: hba.device,
            isSoftwareIScsi: hba.isSoftwareBased,
            addapterKey: hba.key
         };
      }

      private isSafeInteger(value: number): boolean {
         return -(Math.pow(2, 53) - 1) < value && value < (Math.pow(2, 53) - 1);
      }

      public validateOptionValue = (optionItem: OptionItem) => {
         if (!optionItem || optionItem.readOnly) {
            return [];
         }

         if (optionItem.valueType === this.advancedOptionConstants.optionTypes.LONG_OPTION_TYPE ||
               optionItem.valueType === this.advancedOptionConstants.optionTypes.INT_OPTION_TYPE) {

            let numValue = parseInt(optionItem.value);
            if (isNaN(numValue) || !this.isSafeInteger(numValue) || (('' + numValue) !== ('' + optionItem.value))) {
               return [this.i18nService.getString(
                     "StorageUi", "storage.adapters.iscsi.advancedOptions.invalidNumber")];
            }

            if (optionItem.minValue !== null && optionItem.minValue !== undefined &&
                  numValue < optionItem.minValue) {
               return [this.i18nService.getString(
                     "StorageUi", "storage.adapters.iscsi.advancedOptions.lowerThenMinError", optionItem.minValue)];
            }

            if (optionItem.maxValue !== null && optionItem.maxValue !== undefined &&
                  numValue > optionItem.maxValue) {
               return [this.i18nService.getString(
                     "StorageUi", "storage.adapters.iscsi.advancedOptions.exceedsMaxError", optionItem.maxValue)];
            }
         }

         return [];
      };

      public parseOptionItems(options: any[], optionInfo: any): OptionItem[] {
         if (!options || !optionInfo) {
            return [];
         }
         let result: OptionItem[] = [];
         let propertyInfos = optionInfo.properties;
         if (options.length !== propertyInfos.length) {
            return [];
         }

         for (let i = 0; i < options.length; i++) {
            let optionItem = new OptionItem();
            result.push(optionItem);

            optionItem.readOnly = false;
            optionItem.validate = this.validateOptionValue.bind(this, optionItem);

            optionItem.name = this.fetchProperyValue(
                  this.advancedOptionConstants.optionProperties.OPTION_PROPERTY_NAME, options[i].properties);
            optionItem.description = this.fetchProperyValue(
                  this.advancedOptionConstants.optionProperties.DESCRIPTION_PROPERTY_NAME, options[i].properties);

            optionItem.optionKey = options[i].tag.optionKey;
            optionItem.valueType = options[i].tag.optionValueType;

            optionItem.inherited = this.fetchProperyValue(
                  this.advancedOptionConstants.optionProperties.INHERITED_PROPERTY_NAME, options[i].properties);

            optionItem.value = this.fetchProperyValue(
                  this.advancedOptionConstants.optionProperties.VALUE_PROPERTY_NAME, options[i].properties);

            if (optionItem.valueType === this.advancedOptionConstants.optionTypes.STRING_OPTION_TYPE) {
               if (optionItem.value && optionItem.value.hasOwnProperty("value")) {
                  optionItem.value = optionItem.value.value;
               }
            }

            if (!propertyInfos[i].valueType || !propertyInfos[i].valueType.properties) {
               continue;
            }

            let valueTypeProps: any[] = propertyInfos[i].valueType.properties;
            let valueProp = _.find(valueTypeProps, (prop: any) => {
               return prop.name === this.advancedOptionConstants.optionProperties.VALUE_PROPERTY_NAME;
            });
            if (valueProp) {
               optionItem.readOnly = valueProp.immutable;

               _.each(valueProp.staticValueSpace, function (valueSpace: any) {
                  if (valueSpace.type === "MININCLUSIVE") {
                     optionItem.minValue = valueSpace.value;
                  }
                  if (valueSpace.type === "MAXINCLUSIVE") {
                     optionItem.maxValue = valueSpace.value;
                  }
                  if (valueSpace.type === "ENUMERATION") {
                     optionItem.allowedValues = optionItem.allowedValues || [];
                     optionItem.allowedValues.push(valueSpace.value);
                  }
               });
            }
         }

         return result;
      }

      public equalOptionItems(a: OptionItem, b: OptionItem): boolean {
         if (a.inherited !== b.inherited) {
            return false;
         }

         return ('' + a.value) === ('' + b.value);
      }

      private fetchProperyValue(propertyName: string, properties: any[]): any {
         let prop: any = _.find(properties, function (prop: any) {
            return prop.name === propertyName;
         });
         return prop && prop.value;
      }
   }

   angular.module("com.vmware.vsphere.client.storage")
         .service("editIscsiAdvancedOptionsService", EditIscsiAdvancedOptionsService);
}
