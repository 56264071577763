namespace storage_ui {

   import IComponentController = angular.IComponentController;
   import NetworkSettingsItem = com.vmware.vsphere.client.h5.storage.data.adapters.NetworkSettingsItem;

   class StorageAdapterIPv4SettingsComponent implements IComponentController {
      public static $inject = ["i18nService", "StorageAdapterNetworkSettingsConstants", "ipParserService", "vxValidatorFactory"];
      private i18n: Function;
      private model: NetworkSettingsItem;
      private selectedOption: string;
      public validator: any;

      constructor(private i18nService: any,
                  private constants: any,
                  private ipParserService: any,
                  private vxValidatorFactory: any) {
         this.i18n = (key: string)=>i18nService.getString("StorageUi", key);
      }

      $onInit(): void {
         let props = this.model.properties;
         // populate radio selection
         if (!props.ipv4Enabled) {
            this.selectedOption = this.constants.IPv4Settings.NONE;
         } else if (props.ipv4Enabled && props.dhcpConfigurationEnabled) {
            this.selectedOption = this.constants.IPv4Settings.DHCP;
         } else if (props.ipv4Enabled && !props.dhcpConfigurationEnabled) {
            this.selectedOption = this.constants.IPv4Settings.STATIC;
         }
         this.validator = this.vxValidatorFactory.create();
      }

      private isStatic(): boolean {
         return this.selectedOption === this.constants.IPv4Settings.STATIC;
      }

      /**
       * Modifies spec flags based on radio button selected
       **/
      private onChange(option: string) {
         if (option) {
            switch (option) {
               case this.constants.IPv4Settings.NONE:
                  this.model.properties.ipv4Enabled = false;
                  break;
               case this.constants.IPv4Settings.DHCP:
                  this.model.properties.dhcpConfigurationEnabled = true;
                  this.model.properties.ipv4Enabled = true;
                  break;
               case this.constants.IPv4Settings.STATIC:
                  this.model.properties.ipv4Enabled = true;
                  this.model.properties.dhcpConfigurationEnabled = false;
                  break;
            }
         }
      }

      validateIpv4Address = (value: any): string[]=> {
         if (!this.isStatic() || (value && this.ipParserService.isIpv4AddressValid(value))) {
            return [];
         }
         return [this.i18nService.getString("StorageUi", "storage.adapters.properties.ipDnsConfig.invalidV4")];
      };

      validateIpv4Mask = (value: any): string[]=> {
         if (!this.isStatic() || (value && this.ipParserService.isSubnetMaskValid(value))) {
            return [];
         }
         return [this.i18nService.getString("StorageUi", "storage.adapters.properties.ipDnsConfig.invalidSubnetMask")];
      };

   }

   angular.module("com.vmware.vsphere.client.storage")
         .component("storageAdapterIpV4SettingsComponent", <IComponentOptions>{
            templateUrl: "storage-ui/resources/storage/views/host/adapters/network/StorageAdapterIPv4SettingsComponent.html",
            bindings: {
               model: "=",
               validator: "="
            },
            controller: StorageAdapterIPv4SettingsComponent
         });
}
