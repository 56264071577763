import IComponentOptions = angular.IComponentOptions;
namespace storage_ui {

   import IComponentOptions = angular.IComponentOptions;
   import IComponentController = angular.IComponentController;

   class AddIpAddressModalComponentController implements IComponentController {
      private validateManually: Function;
      private ipInput: string;

      public static $inject = ["i18nService"];

      constructor(private i18nService: any) {
      }


   }

   angular.module("com.vmware.vsphere.client.storage")
         .component("addIpAddressComponent", <IComponentOptions>{
            templateUrl: "storage-ui/resources/storage/views/host/adapters/network/AddIpAddressModalComponent.html",
            bindings: {
               ipInput: "=",
               validateManually: "="
            },
            controller: AddIpAddressModalComponentController
         });
}
