/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
(function () {
   'use strict';
   angular.module('com.vmware.vsphere.client.storage')
         .service('addDatastoreNameValidatorService', addDatastoreNameValidatorService);

   addDatastoreNameValidatorService.$inject = ['dataService', 'i18nService', '$q'];

   function addDatastoreNameValidatorService(dataService, i18nService, $q) {

      var DATASTORE_INFO_BY_NAME_PROP = 'datastore:datastoreInfoByDatastoreName';
      var VALIDATE_NFS_NAME_CONFIGURATION = 'datastore:validateNfsNameAndConfiguration';

      return {
         /**
          * Validates a datastore name's length and uniqueness in the datacenter of
          * the contextObject.
          *
          * @param contextObject
          *    Valid types are host, cluster, datacenter and storage folder.
          *
          * @param datastoreName
          *    Datastore name to be validated.
          *
          * @param maxLengthName
          *    Maximum allowed length of the datastore name.
          *
          * @param datastoreInfoValidationCallback
          *    Optional callback fired when there is datastore with the same name.
          *    It can be used to provide a custom error message. It takes one argument -
          *    the Datastore.Info of the datastore with the matching name and should
          *    return a String value:
          *        a) Null if the datastore name is valid
          *        b) Error text if not.
          *
          * @returns
          *    String value:
          *        a) Null if the datastore name is valid
          *        b) Error text if not.
          */
         validate: function (
               contextObject, datastoreName, maxLengthName, datastoreInfoValidationCallback) {

            if (!datastoreName) {
               return $q.when(i18nService.getString(
                     'StorageUi', 'addDatastoreWizard.nameValidator.noDatastoreName'));
            }

            if (datastoreName.length > maxLengthName) {
               return $q.when(i18nService.getString(
                     'StorageUi', 'addDatastoreWizard.nameValidator.nameTooLong',
                     maxLengthName));
            }

            return dataService.getProperties(
                  contextObject,
                  [DATASTORE_INFO_BY_NAME_PROP],
                  {
                     propertyParams: [{
                        propertyName: DATASTORE_INFO_BY_NAME_PROP,
                        parameterType: 'java.lang.String',
                        parameter: datastoreName
                     }]
                  }).then(function (response) {

               var datastoreInfos =
                     response ? response[DATASTORE_INFO_BY_NAME_PROP] || []
                              : [];

               var matchingDatastoreInfo = _.find(datastoreInfos, function(item) {
                  return datastoreName === item.name;
               });

               if (!matchingDatastoreInfo) {
                  // No matching datastores - valid name
                  return null;
               }

               if (datastoreInfoValidationCallback) {
                  // Call the validation callback for custom error message.
                  return datastoreInfoValidationCallback(matchingDatastoreInfo);
               } else {
                  return i18nService.getString(
                        'StorageUi', 'addDatastoreWizard.nameValidator.default.duplicateName');
               }
            });
         },

         validateNfsNameAndConfiguration: function(contextObject, datastoreName, folderName, serverNames, datastoreType) {
            return dataService.getProperties(
               contextObject,
               [VALIDATE_NFS_NAME_CONFIGURATION],
               {
                  propertyParams: [
                     {
                        propertyName: VALIDATE_NFS_NAME_CONFIGURATION,
                        parameterType: 'com.vmware.vsphere.client.h5.storage.spec.NfsDatastoreConfigurationSpec',
                        parameter: {
                           _type: 'com.vmware.vsphere.client.h5.storage.spec.NfsDatastoreConfigurationSpec',
                           datastoreName: datastoreName,
                           folderName: folderName,
                           serverNames: serverNames,
                           type: datastoreType
                        }
                     }]
               }).then(function(response) {
                  return response[VALIDATE_NFS_NAME_CONFIGURATION] || "";
            });
         }
      };
   }
})();
