module cluster_ui {

   import IController = angular.IController;
   import ManagedObjectReference = com.vmware.vim.binding.vmodl.ManagedObjectReference;

   class ConfigClusterVmOverridesController implements IController {

      public clusterOverridesData: any;
      public vmSettingsSpec: any;
      public drsAutomationLevels: any[];
      public restartPriorityOptions: any;
      public restartOrchestrationOptions: any;
      public isolationResponseOptions: any;
      public pdlProtectionOptions: any;
      public pdlTooltipParams: any;
      public apdProtectionOptions: any;
      public apdTooltipParams: any;
      public apdVmRecoveryOptions: any;
      public vmMonitoringOptions: any;
      public vmMonitoringSensitivityOptions: any;
      public i18n: Function;
      public selectedVms: any;
      public loading: boolean;
      public tooltipParams: any = {};
      public apdDelayMultipleValuesOptions: any;
      public haAdditionalDelayMultipleOptions: any;
      public haRestartPriorityMultipleOptions: any;
      public MIN_POST_READY_DELAY: number = 0;
      public MIN_RESTART_PRIORITY_TIMEOUT: number = 0;
      public MAX_POST_READY_DELAY: number;
      public MAX_RESTART_PRIORITY_TIMEOUT: number;
      public is65VcOrLater: boolean;
      public POST_READY_DELAY_PLACEHOLDER: string;
      public alertText: string;

      static $inject = ["dataService", "i18nService", "clusterConfigVmOverrideService",
            "drsAutomationLevelService", "vcService", "haConstants",
            "haHostFailureResponseService", "haHostIsolationResponseService",
            "haPdlResponseService", "haApdResponseService",
            "haVmReactionsOnApdClearedService", "clusterVmOverridesConstants",
            "clusterConstants", "haVmMonitoringService", "defaultUriSchemeUtil", "$q"];

      constructor(private dataService: any, private i18nService: any,
            private clusterConfigVmOverrideService: ClusterConfigVmOverrideService,
            private drsAutomationLevelService: any,
            private vcService: any,
            private haConstants: any,
            private haHostFailureResponseService: any,
            private haHostIsolationResponseService: any,
            private haPdlResponseService: any,
            private haApdResponseService: any,
            private haVmReactionsOnApdClearedService: any,
            private clusterVmOverridesConstants: any,
            private clusterConstants: any,
            private haVmMonitoringService: any,
            private defaultUriSchemeUtil: any,
            private $q: any) {

         this.i18n = i18nService.getString;
         this.setPageBusy(true);
         this.MAX_POST_READY_DELAY = this.haConstants.MAX_POST_READY_DELAY;
         this.MAX_RESTART_PRIORITY_TIMEOUT = this.haConstants.MAX_RESTART_PRIORITY_TIMEOUT;
         this.POST_READY_DELAY_PLACEHOLDER =
               this.i18nService.getString("ClusterUi",
                     "vm.overrides.config.editMultiple.useInitialValues");
      }

      public $onChanges(changes: any): void {
         if (!angular.isUndefined(changes.spec)) {
            this.vmSettingsSpec = changes.spec.currentValue;
         }

         // onChanges in Add wizard is called twice, first time only spec is set
         // second time spec is undefined but vms are set , as they are updated by a
         // promise, we want to init it all once
         if (!angular.isUndefined(this.vmSettingsSpec) &&
               !angular.isUndefined(changes.selectedVms) &&
               !angular.isUndefined(changes.selectedVms.currentValue) &&
               changes.selectedVms.currentValue.length > 0) {
            this.selectedVms = changes.selectedVms.currentValue;
            // this data doesn't depend on changes so we want to make the backend
            // call once
            if (!this.clusterOverridesData || !this.is65VcOrLater) {
               let dataPromise: any = this.dataService.getData(this.vmSettingsSpec.objectId,
                     "com.vmware.vsphere.client.clusterui.model.configuration.ClusterAddOverrideData");
               let vcVersionPromise: any = this.vcService.is65VcOrLater(this.vmSettingsSpec.objectId);

               this.$q.all([dataPromise, vcVersionPromise]).then((result: any) => {
                  this.clusterOverridesData = result[0];
                  this.is65VcOrLater = result[1];
                  this.initSettings();
               });
            } else {
               this.initSettings();
            }
         }
      }

      private initSettings() {
         this.initDrsSettings();
         this.initHaSettings();
         let numberOfTooltips = this.getDifferentSettingsVmsCount();
         if (numberOfTooltips > 0) {
            this.alertText = numberOfTooltips === 1 ?
                  this.i18nService.getString(
                  'ClusterUi',
                  'vm.overrides.config.editMultiple.warningMessage.single') :
                  this.i18nService.getString(
                  'ClusterUi',
                  'vm.overrides.config.editMultiple.warningMessage.multiple',
                  numberOfTooltips);
         }
         this.setPageBusy(false);
      }

      public updateRestartPriorityDependencies(): void {
         if (this.isRestartPriorityDisabled()) {
            this.setDefaultHaIsolationResponse();
            this.setDefaultHaPdlProtection();
            this.setDefaultHaApdProtection();
            this.setDefaultHaApdVmRecovery();
            this.setDefaultHaApdDelay();
         }
      }

      public updateDrsAutomationLevelValue(): void {
         if (!this.vmSettingsSpec.drsAutomationOverrideEnabled) {
            this.vmSettingsSpec.drsAutomationLevel =
                  this.clusterOverridesData.clusterDrsAutomationLevel;
         }
      }

      public updateRestartPriorityValue(): void {
         if (!this.vmSettingsSpec.haRestartPriorityEnabled) {
            this.vmSettingsSpec.haRestartPriority =
                  this.clusterOverridesData.clusterVmRestartPriority;
         }
      }

      public updateRestartOrchestrationValue(): void {
         if (!this.vmSettingsSpec.haRestartOrchestrationEnabled) {
            this.vmSettingsSpec.restartOrchestration.readyCondition =
                  this.clusterOverridesData.defaultVmReadiness.readyCondition;
            if (!this.vmSettingsSpec.haRestartOrchestrationAdditionalDelayEnabled) {
               // in case both are unchecked existing vmorchestration members should
               // be removed
               _.each(this.selectedVms, (selectedVm: any) => {
                  if (selectedVm.arrayOperation === this.clusterVmOverridesConstants.operationType.ADD) {
                     selectedVm.skipFromOrchestrationSpec = true;
                  }
                  if (selectedVm.arrayOperation === this.clusterVmOverridesConstants.operationType.EDIT) {
                     selectedVm.arrayOperation = this.clusterVmOverridesConstants.operationType.REMOVE;
                  }
               });
            }
         } else {
            // in case of uncheck and then check again
            _.each(this.selectedVms, (selectedVm: any) => {
               if (!!selectedVm.skipFromOrchestrationSpec) {
                  selectedVm.skipFromOrchestrationSpec = false;
               }
               if (selectedVm.arrayOperation === this.clusterVmOverridesConstants.operationType.REMOVE) {
                  selectedVm.arrayOperation = this.clusterVmOverridesConstants.operationType.EDIT;
               }
            });
         }
      }

      public updateRestartOrchestrationAdditionalDelayValue(): void {
         if (!this.vmSettingsSpec.haRestartOrchestrationAdditionalDelayEnabled) {
            this.vmSettingsSpec.restartOrchestration.postReadyDelay =
                  this.clusterVmOverridesConstants.APD_DELAY_CLUSTER_DEFAULT;
            if(!this.vmSettingsSpec.haRestartOrchestrationEnabled) {
               // in case both are unchecked existing vmorchestration members should
               // be removed
               _.each(this.selectedVms, (selectedVm: any) => {
                  if (selectedVm.arrayOperation === this.clusterVmOverridesConstants.operationType.ADD) {
                     selectedVm.skipFromOrchestrationSpec = true;
                  }
                  if (selectedVm.arrayOperation === this.clusterVmOverridesConstants.operationType.EDIT) {
                     selectedVm.arrayOperation = this.clusterVmOverridesConstants.operationType.REMOVE;
                  }
               });
            }
         } else {
            // this is done so that we don't display -1
            if(this.vmSettingsSpec.restartOrchestration.postReadyDelay ===
                  this.clusterVmOverridesConstants.APD_DELAY_CLUSTER_DEFAULT) {
               this.vmSettingsSpec.restartOrchestration.postReadyDelay =
                     this.clusterOverridesData.defaultVmReadiness.postReadyDelay;
            }
            // in case of uncheck and then check again
            _.each(this.selectedVms, (selectedVm: any) => {
               if (!!selectedVm.skipFromOrchestrationSpec) {
                  selectedVm.skipFromOrchestrationSpec = false;
               }
               if (selectedVm.arrayOperation === this.clusterVmOverridesConstants.operationType.REMOVE) {
                  selectedVm.arrayOperation = this.clusterVmOverridesConstants.operationType.EDIT;
               }
            });
         }
      }

      public updateRestartPriorityTimeoutValue(): void {
         if (!this.vmSettingsSpec.haRestartPriorityTimeoutEnabled) {
            this.vmSettingsSpec.haRestartPriorityTimeout =
                  this.clusterVmOverridesConstants.APD_DELAY_CLUSTER_DEFAULT;
         } else {
            if (this.vmSettingsSpec.haRestartPriorityTimeout ===
                        this.clusterVmOverridesConstants.APD_DELAY_CLUSTER_DEFAULT) {
               this.vmSettingsSpec.haRestartPriorityTimeout =
                     this.clusterOverridesData.clusterVmRestartPriorityTimeout;
            }
         }
      }

      public updateIsolationResponseValue(): void {
         if (!this.vmSettingsSpec.haIsolationResponseEnabled) {
            this.vmSettingsSpec.haIsolationResponse =
                  this.clusterOverridesData.clusterIsolationResponse;
         }
      }

      public updatePdlProtectionValue(): void {
         if (!this.vmSettingsSpec.haPdlProtectionEnabled) {
            this.vmSettingsSpec.haPdlProtection =
                  this.clusterOverridesData.vmcpSettings.pdlResponse;
         }
      }

      public updateApdProtectionValue(): void {
         if (!this.vmSettingsSpec.haApdProtectionEnabled) {
            this.vmSettingsSpec.haApdProtection =
                  this.clusterOverridesData.vmcpSettings.apdResponse;

            this.updateApdProtectionDependencies();
         }
      }

      public updateApdProtectionDependencies(): void {
         if (this.isApdProtectionDisabled()) {
            this.setDefaultHaApdDelay();
            this.setDefaultHaApdVmRecovery();
         }
      }

      public updateApdDelayValue(): void {
         if (!this.vmSettingsSpec.haApdDelayEnabled) {
            this.vmSettingsSpec.haApdDelay = this.getApdDelayClusterDefault();
         }
      }

      public updateApdVmRecoveryValue(): void {
         if (!this.vmSettingsSpec.haApdVmRecoveryEnabled) {
            this.vmSettingsSpec.haApdVmRecovery =
                  this.clusterOverridesData.vmcpSettings.vmReactionOnAPDCleared;
         }
      }

      public updateVmMonitoringValue(): void {
         if (!this.vmSettingsSpec.haVmMonitoringEnabled) {
            this.vmSettingsSpec.haVmMonitoring =
                  this.clusterOverridesData.vmMonitoring;
            this.setDefaultHaVmMonitoringSensitivity();
            this.setDefaultHaVmMonitoringFailureInterval();
            this.setDefaultHaVmMonitoringMinUpTime();
            this.setDefaultHaVmMonitoringMaxFailures();
            this.setDefaultHaVmMonitoringMaxFailuresWindow();
         }
         this.updateVmMonitoringSensitivityTooltip();
      }

      public updateVmMonitoringSensitivityTooltip(): void {
         if(this.tooltipParams.haVmMonitoringSensitivityParams) {
            this.tooltipParams.haVmMonitoringSensitivityParams.isSettingVisible =
                  this.isVmMonitoringSensitivityVisible();
            if (this.alertText) {
               let numOfTooltips: number  = this.getDifferentSettingsVmsCount();
               this.alertText = numOfTooltips === 1 ?
                     this.i18nService.getString(
                     'ClusterUi',
                     'vm.overrides.config.editMultiple.warningMessage.single') :
                     this.i18nService.getString(
                           'ClusterUi',
                           'vm.overrides.config.editMultiple.warningMessage.multiple',
                           numOfTooltips);
            }
         }
      }

      public normalizeApdDelayValue(event: any): void {
         let maxMinutesValue: number = Math.floor(this.clusterConstants.MAX_INTEGER /
               this.haConstants.SECONDS_IN_A_MINUTE);

         this.normalizeOverrideValue("haApdDelay", event, this.haConstants.MIN_NUMBER_INPUT, maxMinutesValue);
      }

      public normalizeApdDelayMultipleValues(value: string): void {
         if (value === this.i18nService.getString(
                     "ClusterUi", "vm.overrides.config.editMultiple.useInitialValues")) {
            return;
         }

         let maxMinutesValue: number = Math.floor(this.clusterConstants.MAX_INTEGER /
               this.haConstants.SECONDS_IN_A_MINUTE);

         let numericValue: number = parseInt(value, 10);

         if (angular.isUndefined(value) || _.isNull(value) || numericValue < 0 ||
               isNaN(numericValue)) {
            this.vmSettingsSpec.haApdDelay = 0;
         } else if (numericValue > maxMinutesValue) {
            this.vmSettingsSpec.haApdDelay = maxMinutesValue;
         } else {
            this.vmSettingsSpec.haApdDelay = numericValue;
         }
      }

      public normalizeFailureInt(event: any): void {
         this.normalizeOverrideValue("vmToolsMonitorFailureInt", event, this.haConstants.MIN_NUMBER_INPUT,
               this.haConstants.MAX_FAILURE_INTERVAL);
      }

      public normalizeMinUpTime(event: any): void {
         this.normalizeOverrideValue("vmToolsMonitorMinUpTime", event, this.haConstants.MIN_NUMBER_INPUT,
               this.haConstants.MAX_MINIMUM_UPTIME);
      }

      public normalizeMaxFailures(event: any): void {
         this.normalizeOverrideValue("vmToolsMonitorMaxFailures", event, this.haConstants.MIN_NUMBER_INPUT,
               this.haConstants.MAX_VM_PER_RESET);
      }

      public normalizeMaxFailuresWindow(event: any): void {
         this.normalizeOverrideValue("vmToolsMonitorMaxFailureWindow", event, this.haConstants.MIN_RESETS_WINDOW_HRS,
               this.haConstants.MAX_RESETS_WINDOW_HRS);
      }

      public hasSelectedVm(): boolean {
         return !_.isEmpty(this.selectedVms);
      }

      public isFtVm(): boolean {
         return this.hasSelectedVm() && _.some(this.selectedVms, (selectedVm: any) => {
                  return selectedVm.vm.ftVm;
               });
      }

      public isVmcpSupportedForVm(): boolean {
         return this.hasSelectedVm() && _.all(this.selectedVms, (selectedVm: any) => {
                  return selectedVm.vm.isVmcpSupportedForVm;
               });
      }

      public isDrsAutomationLevelEditable(): boolean {
         let drsAutomationLevelEditableValues: any =
               this.getSelectedVmsDrsProperties("drsAutomationLevelEditable");

         let drsAutomationLevelEditable: any = this.clusterConfigVmOverrideService
               .getUniqueValue(drsAutomationLevelEditableValues);

         if (this.clusterConfigVmOverrideService.hasMultipleValues(
               drsAutomationLevelEditable)) {
            drsAutomationLevelEditable = false;
         }

         return !this.isEditMode() || drsAutomationLevelEditable;
      }

      public isCustomMonitoringSensitivity(): boolean {
         return this.vmSettingsSpec.haVmMonitoringSensitivity ===
               this.haVmMonitoringService.getPresetLevels().CUSTOM;
      }

      public isRestartPriorityDisabled(): boolean {
         if (this.vmSettingsSpec.haRestartPriority ===
               this.haConstants.restartPriority.DISABLED) {
            return true;
         }

         // check if any of the edited overrides has a disabled value
         if (this.clusterConfigVmOverrideService.hasMultipleValues(
               this.vmSettingsSpec.haRestartPriority)) {
            let haRestartPriorityValues = this.getSelectedVmsHaProperties(
                  "haRestartPriority");

            if (this.clusterConfigVmOverrideService.containsValue(
                  haRestartPriorityValues, this.haConstants.restartPriority.DISABLED)) {
               return true;
            }
         }

         return false;
      }

      public isApdProtectionDisabled(): boolean {
         if (this.vmSettingsSpec.haApdProtection ===
               this.haConstants.storageVmReaction.DISABLED ||
               this.vmSettingsSpec.haApdProtection ===
               this.haConstants.storageVmReaction.WARNING) {
            return true;
         }

         // check if any of the edited overrides has a disabled value
         if (this.clusterConfigVmOverrideService.hasMultipleValues(
                     this.vmSettingsSpec.haApdProtection)) {
            let haApdProtectionValues = this.getSelectedVmsHaProperties(
                  "apdProtectionLevel");

            if (this.clusterConfigVmOverrideService.containsValue(haApdProtectionValues,
                        this.haConstants.storageVmReaction.DISABLED) ||
                  this.clusterConfigVmOverrideService.containsValue(haApdProtectionValues,
                        this.haConstants.storageVmReaction.WARNING)) {
               return true;
            }
         }

         return false;
      }

      public isVmMonitoringSensitivityVisible(): boolean {
         if (this.vmSettingsSpec.haVmMonitoring ===
               this.haConstants.vmMonitoring.DISABLED) {
            return false;
         }

         // check if any of the edited overrides has a disabled value
         if (this.clusterConfigVmOverrideService.hasMultipleValues(
                     this.vmSettingsSpec.haVmMonitoring)) {
            let haVmMonitoringValues = this.getSelectedVmsHaProperties("vmMonitoring");

            if (this.clusterConfigVmOverrideService.containsValue(
                        haVmMonitoringValues, this.haConstants.vmMonitoring.DISABLED)) {
               return false;
            }
         }

         return true;
      }

      public getDifferentSettingsVmsCount(): number {
         let numberOfTooltipParams = 0;
         _.each(this.tooltipParams, (tooltipParam: any) => {
            if(tooltipParam.isSettingVisible) {
               numberOfTooltipParams++;
            }
         });
         return numberOfTooltipParams;
      }

      private normalizeOverrideValue(specKey: string, event: any, minValue: number, maxValue: number)
            : void {
         let value: number = event.target.value;

         if (!value || value < minValue) {
            this.vmSettingsSpec[specKey] = minValue;
         }

         if (value > maxValue) {
            this.vmSettingsSpec[specKey] = maxValue;
         }
      }

      private initDrsSettings(): void {
         this.drsAutomationLevels =
               this.clusterConfigVmOverrideService.getDrsAutomationLevels();

         let vmAutomationLevels: any[] =
               this.getSelectedVmsDrsProperties("drsAutomationLevel");

         let vmAutomationLevel: string =
               this.clusterConfigVmOverrideService.getUniqueValue(vmAutomationLevels);

         if (this.isEditMode() &&
               !this.clusterConfigVmOverrideService.isClusterDefault(vmAutomationLevel)) {
            if (this.clusterConfigVmOverrideService.hasMultipleValues(vmAutomationLevel)) {
               this.clusterConfigVmOverrideService
                     .addInitialValuesOption(this.drsAutomationLevels);
               this.tooltipParams.drsAutomationLevelParams =
                     this.clusterConfigVmOverrideService.getTooltipParams(
                           vmAutomationLevels, this.drsAutomationLevels,
                           this.clusterOverridesData.clusterDrsAutomationLevel
                     );
               this.tooltipParams.drsAutomationLevelParams.isSettingVisible = true;
            }

            this.vmSettingsSpec.drsAutomationLevel = vmAutomationLevel;
            this.vmSettingsSpec.drsAutomationOverrideEnabled =
                  this.isDrsAutomationLevelEditable();
         } else {
            this.setDefaultDrsAutomationLevel();
         }
      }

      private initHaSettings(): void {
         this.initHaIsolationResponse();
         this.initHaRestartPriority();
         this.initHaRestartOrchestration();
         this.initHaRestartOrchestrationAdditionalDelay();
         this.initHaRestartPriorityTimeout();
         this.initHaPdlProtection();
         this.initHaApdProtection();
         this.initHaApdDelay();
         this.initHaApdVmRecovery();
         this.initHaVmMonitoring();
         this.initHaVmMonitoringSensitivity();
         this.initHaVmMonitoringFailureInterval();
         this.initHaMonitoringMinUpTime();
         this.initHaMonitoringMaxFailures();
         this.initHaMonitoringMaxFailuresWindow();
      }

      private initHaRestartPriority(): void {
         this.restartPriorityOptions = this.haHostFailureResponseService
               .getVmRestartPriorityOptions(this.is65VcOrLater);
         if (!this.isFtVm()) {
            this.restartPriorityOptions.disabled =
                  this.haHostFailureResponseService.getDisabledOption();
         }
         this.vmSettingsSpec.haRestartPriorityDisabled =
               !this.clusterOverridesData.haEnabled ||
               this.clusterOverridesData.clusterVmRestartPriority ===
               this.haConstants.restartPriority.DISABLED;

         let vmRestartPriorities: any[] =
               this.getSelectedVmsHaProperties("haRestartPriority");
         let vmRestartPriority: string =
               this.clusterConfigVmOverrideService.getUniqueValue(
                     vmRestartPriorities);

         if (this.isEditMode() && !this.clusterConfigVmOverrideService
                     .isClusterDefault(vmRestartPriority)) {
            if (this.clusterConfigVmOverrideService.hasMultipleValues(
                        vmRestartPriority)) {
               this.restartPriorityOptions.useInitialValues = {
                  id: this.clusterVmOverridesConstants.INITIAL_VALUES,
                  label: this.i18nService.getString("ClusterUi",
                        "vm.overrides.config.editMultiple.useInitialValues")
               };

               this.tooltipParams.haRestartPriorityParams =
                     this.clusterConfigVmOverrideService.getTooltipParams(
                           vmRestartPriorities, _.values(this.restartPriorityOptions),
                           this.clusterOverridesData.clusterVmRestartPriority);
               this.tooltipParams.haRestartPriorityParams.isSettingVisible = true;
            }

            this.vmSettingsSpec.haRestartPriority = vmRestartPriority;
            this.vmSettingsSpec.haRestartPriorityEnabled = true;
         } else {
            this.vmSettingsSpec.haRestartPriority =
                  this.clusterOverridesData.clusterVmRestartPriority;
            this.vmSettingsSpec.haRestartOrchestrationEnabled = false;
         }
      }

      private setOrchestrationArrayOperationsPerVM() {
         _.each(this.selectedVms, (vm: any) => {
            let vmOrchestrationInfo: any = this.findOrchestrationInfoForVm(vm);
            _.isUndefined(vmOrchestrationInfo) ?
                  vm.arrayOperation = this.clusterVmOverridesConstants.operationType.ADD :
                  vm.arrayOperation = this.clusterVmOverridesConstants.operationType.EDIT;
         });

      }

      private findOrchestrationInfoForVm(vm: any): any {
         let defaultUriSchemeUtilService: any = this.defaultUriSchemeUtil;
         return _.find(this.clusterOverridesData.vmOrchestrationCondition,
               (vmOrchestrationCondition: any) => {
                  let vmId: any;
                  if (vm.vm.provider) {
                     let vmMor: any = {
                        type: vm.vm.provider.type,
                        value: vm.vm.provider.value,
                        serverGuid: vm.vm.provider.serverGuid
                     };
                     vmId = defaultUriSchemeUtilService.getVsphereObjectId(vmMor);
                  } else {
                     vmId = vm.id;
                  }
                  return defaultUriSchemeUtilService
                              .getVsphereObjectId(vmOrchestrationCondition.vm) === vmId;
               });
      }

      private initHaIsolationResponse(): void {
         if (this.isFtVm()) {
            this.isolationResponseOptions =
                  this.haHostIsolationResponseService.getEnsureFtResponse();
            this.vmSettingsSpec.haIsolationResponse = "EnsureFT";
         } else  {
            this.isolationResponseOptions =
                  this.haHostIsolationResponseService.getResponses();
            this.vmSettingsSpec.haIsolationResponseDisabled =
                  !this.clusterOverridesData.haEnabled ||
                  this.clusterOverridesData.clusterIsolationResponse ===
                  this.haConstants.hostIsolationResponse.LEAVE_POWERED_ON;

            let vmIsolationResponses: any[] =
                  this.getSelectedVmsHaProperties("haIsolationResponse");

            let vmIsolationResponse: string =
                  this.clusterConfigVmOverrideService.getUniqueValue(
                        vmIsolationResponses);


            if (this.isEditMode() && !this.clusterConfigVmOverrideService.isClusterDefault(
                  vmIsolationResponse)) {
               if (this.clusterConfigVmOverrideService.hasMultipleValues(
                     vmIsolationResponse)) {
                  this.clusterConfigVmOverrideService
                        .addInitialValuesOption(this.isolationResponseOptions);
                  this.tooltipParams.haIsolationResponseParams =
                        this.clusterConfigVmOverrideService.getTooltipParams(
                              vmIsolationResponses,this.isolationResponseOptions,
                              this.clusterOverridesData.clusterIsolationResponse);
                  this.tooltipParams.haIsolationResponseParams.isSettingVisible = true;
               }

               this.vmSettingsSpec.haIsolationResponse = vmIsolationResponse;
               this.vmSettingsSpec.haIsolationResponseEnabled = !this.isFtVm();
            } else {
               this.setDefaultHaIsolationResponse();
            }
         }
      }

      private initHaRestartOrchestration(): void {
         if (this.is65VcOrLater) {
            // restart orchestration dropdown options
            this.restartOrchestrationOptions = this.haHostFailureResponseService.getVmOrchestrationReadyConditions();
            this.vmSettingsSpec.haRestartOrchestrationDisabled =
                  !this.clusterOverridesData.haEnabled ||
                  this.clusterOverridesData.clusterVmRestartPriority ===
                  this.haConstants.restartPriority.DISABLED;

            let vmOrchestrationConditions: any[] =
                  this.getSelectedVmsOrchestrationReadyConditions();
            let vmOrchestrationReadyCondition: string =
                  this.clusterConfigVmOverrideService.getUniqueValue(
                        vmOrchestrationConditions);

            this.setOrchestrationArrayOperationsPerVM();
            if (_.isUndefined(this.vmSettingsSpec.restartOrchestration)) {
               this.vmSettingsSpec.restartOrchestration = {};
            }

            if (this.isEditMode() && !this.clusterConfigVmOverrideService
                        .isClusterDefault(vmOrchestrationReadyCondition)) {
               if (this.clusterConfigVmOverrideService.hasMultipleValues(
                           vmOrchestrationReadyCondition)) {
                  this.restartOrchestrationOptions.useInitialValues = {
                     id: this.clusterVmOverridesConstants.INITIAL_VALUES,
                     label: this.i18nService.getString("ClusterUi",
                           "vm.overrides.config.editMultiple.useInitialValues")
                  };

                  this.tooltipParams.haRestartOrchestrationParams =
                        this.clusterConfigVmOverrideService.getTooltipParams(
                              vmOrchestrationConditions, _.values(this.restartOrchestrationOptions),
                              this.clusterOverridesData.defaultVmReadiness.readyCondition);
                  this.tooltipParams.haRestartOrchestrationParams.isSettingVisible = this.is65VcOrLater;
               }

               this.vmSettingsSpec.restartOrchestration.readyCondition =
                     vmOrchestrationReadyCondition;
               this.vmSettingsSpec.haRestartOrchestrationEnabled = true;
            }
            else {
               this.vmSettingsSpec.restartOrchestration.readyCondition =
                     this.clusterOverridesData.defaultVmReadiness.readyCondition;
               this.vmSettingsSpec.haRestartOrchestrationEnabled = false;
            }
         }
      }

      private initHaRestartOrchestrationAdditionalDelay(): void {
         if (this.is65VcOrLater) {
            if (_.isUndefined(this.vmSettingsSpec.restartOrchestration)) {
               this.vmSettingsSpec.restartOrchestration = {};
            }
            // restart orchestration additional delay value
            this.vmSettingsSpec.haRestartOrchestrationAdditionalDelayDisabled =
                  !this.clusterOverridesData.haEnabled ||
                  this.clusterOverridesData.clusterVmRestartPriority ===
                  this.haConstants.restartPriority.DISABLED;

            let vmOrchestrationPostReadyDelays: any[] =
                  this.getSelectedVmsOrchestrationPostReadyDelays();
            let vmOrchestrationPostReadyDelay: string =
                  this.clusterConfigVmOverrideService.getUniqueValue(
                        vmOrchestrationPostReadyDelays);

            this.setOrchestrationArrayOperationsPerVM();

            if (this.isEditMode() &&
                  !this.clusterConfigVmOverrideService
                        .isClusterDefault(vmOrchestrationPostReadyDelay) &&
                  vmOrchestrationPostReadyDelay !== this.clusterOverridesData.
                        defaultVmReadiness.postReadyDelay) {
               // if in edit mode and not cluster default
               if (this.clusterConfigVmOverrideService.hasMultipleValues(
                           vmOrchestrationPostReadyDelay)) {
                  vmOrchestrationPostReadyDelay = this.i18nService.getString(
                        "ClusterUi", "vm.overrides.config.editMultiple.useInitialValues");

                  this.haAdditionalDelayMultipleOptions = {
                     recommendedValues: [{
                        value: vmOrchestrationPostReadyDelay
                     }]
                  };
                  this.tooltipParams.haRestartOrchestrationAdditionalDelayParams =
                        this.clusterConfigVmOverrideService
                              .getValuesInSecondsTooltipParams(
                                    vmOrchestrationPostReadyDelays,
                                    this.clusterOverridesData.defaultVmReadiness.postReadyDelay);
                  this.tooltipParams.haRestartOrchestrationAdditionalDelayParams.isSettingVisible =
                        this.is65VcOrLater;
               }
               this.vmSettingsSpec.restartOrchestration.postReadyDelay =
                     vmOrchestrationPostReadyDelay;
               this.vmSettingsSpec.haRestartOrchestrationAdditionalDelayEnabled = true;
            }
            else {
               this.vmSettingsSpec.restartOrchestration.postReadyDelay =
                     this.clusterOverridesData.defaultVmReadiness.postReadyDelay;
               this.vmSettingsSpec.haRestartOrchestrationAdditionalDelayEnabled = false;
            }
         }
      }

      private initHaRestartPriorityTimeout(): void {
         if (this.is65VcOrLater) {
            this.vmSettingsSpec.haRestartPriorityTimeoutDisabled =
                  !this.clusterOverridesData.haEnabled ||
                  this.clusterOverridesData.clusterVmRestartPriority ===
                  this.haConstants.restartPriority.DISABLED;

            let vmRestartPriorityTimeouts: any[] =
                  _.map(this.getSelectedVmsHaProperties("haRestartPriorityTimeout"),
                        (timeout: any) => {
                           return {
                              vm: timeout.vm,
                              value: timeout.value ?
                                    parseInt(timeout.value.split("\s")[0]) :
                                    timeout.value
                           };
                        });
            let vmRestartPriorityTimeout: any =
                  this.clusterConfigVmOverrideService.getUniqueValue(
                        vmRestartPriorityTimeouts);

            if (this.isEditMode() &&
                  vmRestartPriorityTimeout !== -1 &&
                  vmRestartPriorityTimeout !== this.clusterOverridesData.clusterVmRestartPriorityTimeout) {
               // if in edit mode and not cluster default
               if (this.clusterConfigVmOverrideService.hasMultipleValues(
                           vmRestartPriorityTimeout)) {
                  vmRestartPriorityTimeout = this.i18nService.getString(
                        "ClusterUi", "vm.overrides.config.editMultiple.useInitialValues");

                  this.haRestartPriorityMultipleOptions = {
                     recommendedValues: [{
                        value: vmRestartPriorityTimeout
                     }]
                  };
                  this.tooltipParams.haRestartPriorityTimeoutParams =
                        this.clusterConfigVmOverrideService
                              .getValuesInSecondsTooltipParams(
                                    vmRestartPriorityTimeouts,
                                    this.clusterOverridesData.clusterVmRestartPriorityTimeout);
                  this.tooltipParams.haRestartPriorityTimeoutParams.isSettingVisible =
                        this.is65VcOrLater;
               }
               this.vmSettingsSpec.haRestartPriorityTimeout =
                     vmRestartPriorityTimeout;
               this.vmSettingsSpec.haRestartPriorityTimeoutEnabled = true;
            }
            else {
               this.vmSettingsSpec.haRestartPriorityTimeout =
                     this.clusterOverridesData.clusterVmRestartPriorityTimeout;
               this.vmSettingsSpec.haRestartPriorityTimeoutEnabled = false;
            }
         }
      }


      private initHaPdlProtection(): void {
         this.pdlProtectionOptions =
               this.haPdlResponseService.getResponses();

         this.vmSettingsSpec.haPdlProtectionDisabled =
               !this.clusterOverridesData.haEnabled ||
               this.clusterOverridesData.vmcpSettings.pdlResponse ===
               this.haConstants.storageVmReaction.DISABLED;

         this.pdlTooltipParams = {
            message: this.i18nService.getString("ClusterUi",
                  "vm.overrides.HA.pdlProtectionLevel.hint"),
            title: this.i18nService.getString("Common", "help")
         };

         let vmPdlProtectionValues: any[] =
               this.getSelectedVmsHaProperties("pdlProtectionLevel");

         let vmPdlProtection: string =
               this.clusterConfigVmOverrideService.getUniqueValue(vmPdlProtectionValues);

         if (this.isEditMode() &&
               !this.clusterConfigVmOverrideService.isClusterDefault(vmPdlProtection)) {
            if (this.clusterConfigVmOverrideService.hasMultipleValues(vmPdlProtection)) {
               this.clusterConfigVmOverrideService
                     .addInitialValuesOption(this.pdlProtectionOptions);
               this.tooltipParams.haPdlProtectionParams =
                     this.clusterConfigVmOverrideService.getTooltipParams(
                           vmPdlProtectionValues, this.pdlProtectionOptions,
                           this.clusterOverridesData.vmcpSettings.pdlResponse);
               this.tooltipParams.haPdlProtectionParams.isSettingVisible = true;
            }

            this.vmSettingsSpec.haPdlProtection = vmPdlProtection;
            this.vmSettingsSpec.haPdlProtectionEnabled =
                  this.isVmcpSupportedForVm();
         } else {
            this.setDefaultHaPdlProtection();
         }
      }

      private initHaApdProtection(): void {
         this.apdProtectionOptions =
               this.haApdResponseService.getResponses();

         this.vmSettingsSpec.haApdProtectionDisabled =
               !this.clusterOverridesData.haEnabled ||
               this.clusterOverridesData.vmcpSettings.apdResponse ===
               this.haConstants.storageVmReaction.DISABLED;

         this.apdTooltipParams = {
            message: this.i18nService.getString("ClusterUi",
                  "vm.overrides.HA.apdProtectionLevel.hint"),
            title: this.i18nService.getString("Common", "help")
         };

         let vmApdProtectionValues: any[] =
               this.getSelectedVmsHaProperties("apdProtectionLevel");

         let vmApdProtection: string =
               this.clusterConfigVmOverrideService.getUniqueValue(vmApdProtectionValues);

         if (this.isEditMode() &&
               !this.clusterConfigVmOverrideService.isClusterDefault(vmApdProtection)) {
            if (this.clusterConfigVmOverrideService.hasMultipleValues(vmApdProtection)) {
               this.clusterConfigVmOverrideService
                     .addInitialValuesOption(this.apdProtectionOptions);
               this.tooltipParams.haApdProtectionParams =
                     this.clusterConfigVmOverrideService.getTooltipParams(
                           vmApdProtectionValues, this.apdProtectionOptions,
                           this.clusterOverridesData.vmcpSettings.apdResponse);
               this.tooltipParams.haApdProtectionParams.isSettingVisible = true;
            }

            this.vmSettingsSpec.haApdProtection = vmApdProtection;
            this.vmSettingsSpec.haApdProtectionEnabled =
                  this.isVmcpSupportedForVm();
         } else {
            this.setDefaultHaApdProtection();
         }
      }

      private initHaApdVmRecovery(): void {
         this.apdVmRecoveryOptions =
               this.haVmReactionsOnApdClearedService.getReactions();

         let vmRemediationOnApdRecoverValues: any[] =
               this.getSelectedVmsHaProperties("vmRemediationOnApdRecover");

         let vmRemediationOnApdRecover: string =
               this.clusterConfigVmOverrideService.getUniqueValue(
                     vmRemediationOnApdRecoverValues);

         if (this.isEditMode() && !this.clusterConfigVmOverrideService.isClusterDefault(
               vmRemediationOnApdRecover)) {
            if (this.clusterConfigVmOverrideService
                        .hasMultipleValues(vmRemediationOnApdRecover)) {
               this.clusterConfigVmOverrideService
                     .addInitialValuesOption(this.apdVmRecoveryOptions);
               this.tooltipParams.haApdVmRecoveryParams =
                     this.clusterConfigVmOverrideService.getTooltipParams(
                           vmRemediationOnApdRecoverValues, this.apdVmRecoveryOptions,
                           this.clusterOverridesData.vmcpSettings.vmReactionOnAPDCleared);
               this.tooltipParams.haApdVmRecoveryParams.isSettingVisible = true;
            }

            this.vmSettingsSpec.haApdVmRecovery = vmRemediationOnApdRecover;
            this.vmSettingsSpec.haApdVmRecoveryEnabled =
                  this.isVmcpSupportedForVm();
         } else {
            this.setDefaultHaApdVmRecovery();
         }
      }

      private initHaApdDelay() : void {
         let isApdDelayDefaultValues: any[] =
               this.getSelectedVmsHaProperties("usesDefaultIntervalBeforeVmcpReaction");

         let isApdDelayDefault: any =
               this.clusterConfigVmOverrideService.getUniqueValue(isApdDelayDefaultValues);
         if (this.clusterConfigVmOverrideService.hasMultipleValues(isApdDelayDefault)) {
            // consider the override checkbox checked if there is an override for at least one vm
            isApdDelayDefault = false;
         }

         if (this.isEditMode() && !isApdDelayDefault) {
            let vmHaApdDelayValues: any =
                  this.getSelectedVmsHaProperties("intervalBeforeVmcpReaction");

            let vmHaApdDelay: string =
                  this.clusterConfigVmOverrideService.getUniqueValue(vmHaApdDelayValues);

            if (this.clusterConfigVmOverrideService.hasMultipleValues(vmHaApdDelay)) {
               vmHaApdDelay = this.i18nService.getString(
                     "ClusterUi", "vm.overrides.config.editMultiple.useInitialValues");

               this.apdDelayMultipleValuesOptions = {
                  recommendedValues: [{
                     value: vmHaApdDelay
                  }]
               };

               this.tooltipParams.haApdDelayParams =
                     this.clusterConfigVmOverrideService.getApdDelayTooltipParams(
                           isApdDelayDefaultValues, vmHaApdDelayValues,
                           this.getApdDelayClusterDefault());
               this.tooltipParams.haApdDelayParams.isSettingVisible = true;
            }

            this.vmSettingsSpec.haApdDelay = vmHaApdDelay;
            this.vmSettingsSpec.haApdDelayEnabled = this.isVmcpSupportedForVm();
         } else {
            this.setDefaultHaApdDelay();
         }
      }

      private getApdDelayClusterDefault(): any {
         return this.clusterOverridesData.vmcpSettings.vmTerminateDelayForAPDSec /
               this.haConstants.SECONDS_IN_A_MINUTE;
      }

      private initHaVmMonitoring(): void {
         this.vmMonitoringOptions =
               this.haVmMonitoringService.getVmOverrideMonitoringLevels(
                     this.clusterOverridesData.vmMonitoring);

         let vmMonitoringValues: any =
               this.getSelectedVmsHaProperties("vmMonitoring");

         let vmMonitoring: string =
               this.clusterConfigVmOverrideService.getUniqueValue(vmMonitoringValues);

         if (this.isEditMode() && !this.clusterConfigVmOverrideService.isClusterDefault(
               vmMonitoring)) {
            if (this.clusterConfigVmOverrideService.hasMultipleValues(vmMonitoring)) {
               this.clusterConfigVmOverrideService
                     .addInitialValuesOption(this.vmMonitoringOptions);
               this.tooltipParams.haVmMonitoringParams =
                     this.clusterConfigVmOverrideService.getTooltipParams(
                           vmMonitoringValues, this.vmMonitoringOptions,
                           this.clusterOverridesData.vmMonitoring);
               this.tooltipParams.haVmMonitoringParams.isSettingVisible = true;
            }

            this.vmSettingsSpec.haVmMonitoringEnabled = true;
            this.vmSettingsSpec.haVmMonitoring = vmMonitoring;
         } else {
            this.setDefaultHaVmMonitoring();
         }
      }

      private initHaVmMonitoringSensitivity(): void {
         this.vmMonitoringSensitivityOptions =
               this.haVmMonitoringService.getMonitoringSensitivityLevels();

         let vmMonitoringSensitivityValues: any =
               this.getSelectedVmsHaProperties("vmMonitoringSensitivity");

         let vmMonitoringSensitivity: string = this.clusterConfigVmOverrideService
               .getUniqueValue(vmMonitoringSensitivityValues);

         if (this.isEditMode() && !this.clusterConfigVmOverrideService.isClusterDefault(
                     vmMonitoringSensitivity)) {

            if (this.clusterConfigVmOverrideService
                        .hasMultipleValues(vmMonitoringSensitivity)) {
               this.setMultipleVmMonitoringSensitivityParams(
                     vmMonitoringSensitivityValues, vmMonitoringSensitivity);
            } else {
               this.vmSettingsSpec.haVmMonitoringSensitivity =
                     parseInt(vmMonitoringSensitivity, 10);
            }
         } else {
            this.setDefaultHaVmMonitoringSensitivity();
         }
      }

      private handleMultipleVmMonitoringSensitivityValues(): void {
         if (!this.tooltipParams.haVmMonitoringSensitivityParams) {
            let vmMonitoringSensitivityValues: any =
                  this.getSelectedVmsHaProperties("vmMonitoringSensitivity");
            let vmMonitoringSensitivity: string =
                  this.clusterVmOverridesConstants.INITIAL_VALUES;
            this.setMultipleVmMonitoringSensitivityParams(vmMonitoringSensitivityValues,
                  vmMonitoringSensitivity);
         }
      }

      private setMultipleVmMonitoringSensitivityParams(
            vmMonitoringSensitivityValues: any, vmMonitoringSensitivity: any): void {
         this.clusterConfigVmOverrideService
               .addInitialValuesOption(this.vmMonitoringSensitivityOptions);

         this.tooltipParams.haVmMonitoringSensitivityParams =
               this.clusterConfigVmOverrideService.getTooltipParams(
                     vmMonitoringSensitivityValues, this.vmMonitoringSensitivityOptions,
                     this.getDefaultHaVmMonitoringSensitivity());
         this.tooltipParams.haVmMonitoringSensitivityParams.isSettingVisible =
               this.isVmMonitoringSensitivityVisible();
         this.vmSettingsSpec.haVmMonitoringSensitivity = vmMonitoringSensitivity;
      }

      private initHaVmMonitoringFailureInterval(): void {
         let failureIntervals: any =
               this.getSelectedVmsHaProperties("vmFailureIntervalValue");

         let failureInterval: string =
               this.clusterConfigVmOverrideService.getUniqueValue(failureIntervals);

         if (this.isEditMode() && !_.isNull(failureInterval)) {
            if (this.clusterConfigVmOverrideService.hasMultipleValues(failureInterval)) {
               // set vm monitoring sensitivity to "Initial values"
               this.handleMultipleVmMonitoringSensitivityValues();

               this.setDefaultHaVmMonitoringFailureInterval();
            } else {
               this.vmSettingsSpec.vmToolsMonitorFailureInt = failureInterval;
            }
         } else {
            this.setDefaultHaVmMonitoringFailureInterval();
         }
      }

      private initHaMonitoringMinUpTime(): void {
         let minUpTimeValues: any =
               this.getSelectedVmsHaProperties("vmMinUptimeValue");

         let minUpTime: string =
               this.clusterConfigVmOverrideService.getUniqueValue(minUpTimeValues);

         if (this.isEditMode() && !_.isNull(minUpTime)) {
            if (this.clusterConfigVmOverrideService.hasMultipleValues(minUpTime)) {
               // set vm monitoring sensitivity to "Initial values"
               this.handleMultipleVmMonitoringSensitivityValues();

               this.setDefaultHaVmMonitoringMinUpTime();
            } else {
               this.vmSettingsSpec.vmToolsMonitorMinUpTime = minUpTime;
            }
         } else {
            this.setDefaultHaVmMonitoringMinUpTime();
         }
      }

      private initHaMonitoringMaxFailures(): void {
         let maxFailuresValues: any =
               this.getSelectedVmsHaProperties("vmMaxFailuresValue");

         let maxFailures: string =
               this.clusterConfigVmOverrideService.getUniqueValue(maxFailuresValues);

         if (this.isEditMode() && !_.isNull(maxFailures)) {
            if (this.clusterConfigVmOverrideService.hasMultipleValues(maxFailures)) {
               // set vm monitoring sensitivity to "Initial values"
               this.handleMultipleVmMonitoringSensitivityValues();

               this.setDefaultHaVmMonitoringMaxFailures();
            } else {
               this.vmSettingsSpec.vmToolsMonitorMaxFailures = maxFailures;
            }
         } else {
            this.setDefaultHaVmMonitoringMaxFailures();
         }
      }

      private initHaMonitoringMaxFailuresWindow(): void {
         let maxFailureWindowValues: any =
               this.getSelectedVmsHaProperties("vmMaxFailureWindowValue");

         let maxFailureWindow: number =
               this.clusterConfigVmOverrideService.getUniqueValue(maxFailureWindowValues);

         if (this.isEditMode() && !_.isNull(maxFailureWindow)) {
            if (this.clusterConfigVmOverrideService.hasMultipleValues(maxFailureWindow)) {
               // set vm monitoring sensitivity to "Initial values"
               this.handleMultipleVmMonitoringSensitivityValues();

               this.setDefaultHaVmMonitoringMaxFailuresWindow();
            } else {
               if (maxFailureWindow !==
                     this.haConstants.vmMonitoringSensitivity.NO_FAILURE_WINDOW) {
                  this.vmSettingsSpec.vmToolsMonitorHasFailureWindow = true;
                  this.vmSettingsSpec.vmToolsMonitorMaxFailureWindow =
                        maxFailureWindow / 3600;
               } else {
                  this.vmSettingsSpec.vmToolsMonitorHasFailureWindow = false;
                  this.vmSettingsSpec.vmToolsMonitorMaxFailureWindow = 1;
               }
            }
         } else {
            this.setDefaultHaVmMonitoringMaxFailuresWindow();
         }
      }

      private setDefaultDrsAutomationLevel(): void {
         this.vmSettingsSpec.drsAutomationLevel =
               this.clusterOverridesData.clusterDrsAutomationLevel;
         this.vmSettingsSpec.drsAutomationOverrideEnabled = false;
      }

      private setDefaultHaIsolationResponse(): void {
         this.vmSettingsSpec.haIsolationResponseEnabled = false;
         this.vmSettingsSpec.haIsolationResponse =
               this.clusterOverridesData.clusterIsolationResponse;
      }

      private setDefaultHaPdlProtection(): void {
         this.vmSettingsSpec.haPdlProtectionEnabled = false;
         this.vmSettingsSpec.haPdlProtection =
               this.clusterOverridesData.vmcpSettings.pdlResponse;
      }

      private setDefaultHaApdProtection(): void {
         this.vmSettingsSpec.haApdProtectionEnabled = false;
         this.vmSettingsSpec.haApdProtection =
               this.clusterOverridesData.vmcpSettings.apdResponse;
      }

      private setDefaultHaApdDelay(): void {
         this.vmSettingsSpec.haApdDelayEnabled = false;
         this.updateApdDelayValue();
      }

      private setDefaultHaApdVmRecovery(): void {
         this.vmSettingsSpec.haApdVmRecoveryEnabled = false;
         this.updateApdVmRecoveryValue();
      }

      private setDefaultHaVmMonitoring(): void {
         this.vmSettingsSpec.haVmMonitoringEnabled = false;
         this.vmSettingsSpec.haVmMonitoring =
               this.clusterOverridesData.vmMonitoring;
      }

      private setDefaultHaVmMonitoringSensitivity(): void {
         this.vmSettingsSpec.haVmMonitoringSensitivity =
               this.getDefaultHaVmMonitoringSensitivity();
      }

      private setDefaultHaVmMonitoringFailureInterval(): void {
         this.vmSettingsSpec.vmToolsMonitorFailureInt =
               this.clusterOverridesData.vmToolsMonitorFailureInt;
      }

      private setDefaultHaVmMonitoringMinUpTime(): void {
         this.vmSettingsSpec.vmToolsMonitorMinUpTime =
               this.clusterOverridesData.vmToolsMonitorMinUpTime;
      }

      private setDefaultHaVmMonitoringMaxFailures(): void {
         this.vmSettingsSpec.vmToolsMonitorMaxFailures =
               this.clusterOverridesData.vmToolsMonitorMaxFailures;
      }

      private getDefaultHaVmMonitoringSensitivity(): string {
         let hasFailureWindow: boolean =
               this.clusterOverridesData.vmToolsMonitorMaxFailureWindow !==
               this.haConstants.vmMonitoringSensitivity.NO_FAILURE_WINDOW;

         let vmMonitoringSettings: any = angular.merge({}, this.clusterOverridesData,
               {
                  vmToolsMonitorHasFailureWindow: hasFailureWindow,
                  vmToolsMonitorMaxFailureWindow:
                  this.clusterOverridesData.vmToolsMonitorMaxFailureWindow / 3600
               });

         return this.haVmMonitoringService.getHaMonitoringSensitivityLevel(
               vmMonitoringSettings);
      }

      private setDefaultHaVmMonitoringMaxFailuresWindow(): void {
         let clusterMaxFailuresWindow: number =
               this.clusterOverridesData.vmToolsMonitorMaxFailureWindow;
         if (clusterMaxFailuresWindow !==
               this.haConstants.vmMonitoringSensitivity.NO_FAILURE_WINDOW) {
            this.vmSettingsSpec.vmToolsMonitorHasFailureWindow = true;
            this.vmSettingsSpec.vmToolsMonitorMaxFailureWindow =
                  this.clusterOverridesData.vmToolsMonitorMaxFailureWindow / 3600;
         } else {
            this.vmSettingsSpec.vmToolsMonitorHasFailureWindow = false;
            this.vmSettingsSpec.vmToolsMonitorMaxFailureWindow = 1;
         }
      }

      private getSelectedVmsHaProperties(propertyName: string): any {
         return this.getSelectedVmsProperties("haOverrides", propertyName);
      }

      private getSelectedVmsDrsProperties(propertyName: string): any {
         return this.getSelectedVmsProperties("drsOverrides", propertyName);
      }

      private getSelectedVmsProperties(propertyType: string, propertyName: string): any {
         if (this.hasSelectedVm() &&
               angular.isDefined(this.selectedVms[0][propertyType])) {

            return _.map(this.selectedVms, (vm: any) => {
               return {
                  vm: vm.vm,
                  value: vm[propertyType][propertyName]
               };
            });
         }

         return null;
      }

      private isEditMode(): boolean {
         return this.vmSettingsSpec.mode ===
               this.clusterVmOverridesConstants.operationType.EDIT;
      }

      private setPageBusy(isBusy: boolean): void {
         this.loading = isBusy;
      }

      private getSelectedVmsOrchestrationReadyConditions() {
         if (this.hasSelectedVm()) {
            let result: Array<any> = _.map(this.selectedVms, (vm: any) => {
               let vmOrchestrationInfo: any = this.findOrchestrationInfoForVm(vm);
               return {
                  vm: {
                     name: vm.vm.name
                  },
                  value: (_.isUndefined(vmOrchestrationInfo) ||
                        vmOrchestrationInfo.vmReadiness.readyCondition === "useClusterDefault")?
                        this.clusterVmOverridesConstants.CLUSTER_DEFAULT :
                        vmOrchestrationInfo.vmReadiness.readyCondition
               };
            });
            return result;
         }
         return [];
      }

      private getSelectedVmsOrchestrationPostReadyDelays() {
         if (this.hasSelectedVm()) {
            let result: Array<any> = _.map(this.selectedVms, (vm: any) => {
               let vmOrchestrationInfo: any = this.findOrchestrationInfoForVm(vm);
               return {
                  vm: {
                     name: vm.vm.name
                  },
                  value: (_.isUndefined(vmOrchestrationInfo) ||
                  vmOrchestrationInfo.vmReadiness.postReadyDelay === -1 ||
                  _.isUndefined(vmOrchestrationInfo.vmReadiness.postReadyDelay)) ?
                        this.clusterVmOverridesConstants.CLUSTER_DEFAULT :
                        vmOrchestrationInfo.vmReadiness.postReadyDelay
               };
            });
            return result;
         }
         return [];
      }
   }

   class ConfigClusterVmOverridesComponent implements ng.IComponentOptions {
      public controller: any;
      public templateUrl: string;
      public bindings: any;

      constructor() {
         this.controller = ConfigClusterVmOverridesController;
         this.templateUrl =
               "cluster-ui/resources/cluster/views/settings/configuration/overrides/" +
               "configClusterVmOverridesComponent.html";
         this.bindings = {
            spec: "<",
            selectedVms: "<"
         };
      }
   }

   angular.module("com.vmware.vsphere.client.cluster").component(
         "configClusterVmOverridesComponent", new ConfigClusterVmOverridesComponent());
}
