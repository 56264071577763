namespace h5_vm {

   import Privileges = h5_vm.Privileges;
   import IQService = angular.IQService;
   export class VmHardwareVirtualDiskService {

      public readonly DISK_SIZE_UPDATED: string = "disk-size-updated";
      public readonly DISKS_UPDATED: string = "disks-updated";
      public readonly DISK_STACK_EXPANDED: string = "disk-stack-expanded";

      static $inject: string[] = ['managedEntityConstants',
                        'authorizationService',
                        'i18nService',
                        'vmHardwareUtil',
                        'vmHardwareVirtualDiskSharesService',
                        'vmHardwareVirtualDiskIopsService',
                        'vmHardwareVirtualDiskModeService',
                        'vmHardwareVirtualDiskFlashReadCacheService'];

      private i18n: Function;

      constructor(protected managedEntityConstants: any,
            protected authorizationService: any,
            protected i18nService: any,
            protected vmHardwareUtil: any,
            private vmHardwareVirtualDiskSharesService: any,
            private vmHardwareVirtualDiskIopsService: any,
            private vmHardwareVirtualDiskModeService: any,
            private vmHardwareVirtualDiskFlashReadCacheService: any) {
         this.i18n = i18nService.getString;
      }

      isEditSharesDisabled(inflatedDevice: any, createMode: boolean, diskSharesSupported: any): boolean {
         return this.vmHardwareVirtualDiskSharesService.isEditSharesDisabled(inflatedDevice, createMode, diskSharesSupported);
      }

      isEditDiskModeDisabled(inflatedDevice: any, createMode: boolean, vmConfigContext: any): boolean {
         return this.vmHardwareVirtualDiskModeService.isEditDiskModeDisabled(inflatedDevice, createMode, vmConfigContext);
      }

      isEditIopsLimitDisabled(privileges: Array<string>, createMode: boolean): boolean {
         return this.vmHardwareVirtualDiskIopsService.isEditIopsLimitDisabled(privileges, createMode);
      }

      isRemoveDiskDisabled(privileges: Array<string>): boolean {
         return !this.hasPrivilegesToRemoveDisk(privileges);
      }

      private hasPrivilegesToRemoveDisk(privileges: any) {
         return this.vmHardwareUtil.checkPrivileges(privileges, [Privileges.VM_REMOVEDISK_PRIVILEGE]);
      }

      isEditFlashReadCacheDisabled(privileges: any, createMode: boolean) {
         return this.vmHardwareVirtualDiskFlashReadCacheService.isEditFlashReadCacheDisabled(privileges, createMode);
      }

      hasPrivilegesToEditCapacity(privileges: any, skipPrivilegeCheck: boolean = false) {
         if(skipPrivilegeCheck) {
            return true;
         }

         return this.vmHardwareUtil.checkPrivileges(privileges, [Privileges.VM_DISKEXTEND_PRIVILEGE]);
      }

      hasPrivilegesToEditShares(privileges: any, skipPrivilegeCheck: boolean = false) {
         if(skipPrivilegeCheck) {
            return true;
         }

         return this.vmHardwareUtil.checkPrivileges(privileges, [Privileges.VM_RESOURCE_PRIVILEGE]);
      }

      isStoragePod(selectedStorageInfo: any) {
         return selectedStorageInfo &&
            selectedStorageInfo.storageObject &&
            selectedStorageInfo.storageObject.storageRef &&
            selectedStorageInfo.storageObject.storageRef.type === this.managedEntityConstants.STORAGE_POD;
      }

      hasDatastoreAllocateSpacePrivilege(storageId: string): boolean {
         var promise = this.authorizationService.checkPrivileges(storageId, [Privileges.DATASTORE_ALLOCATESPACE_PRIVILEGE]);
         return promise.then((privilegeStatuses: any[]) => {
            return !!privilegeStatuses[0];
         });
      }

      buildStorageSelectConfig(selectedStorage: any) {
         var storeWithVmOption = { name: this.i18n('VmUi', 'DiskPage.StoreWithVM') };
         var browseDialogOption = { name: this.i18n('VmUi', 'DiskPage.Browse') };

         var dataStores = {};
         if (selectedStorage) {
            var selectedStorageOption = {
               name: selectedStorage.name,
               id: selectedStorage.id,
               storageObject: selectedStorage.storageObject
            };
            dataStores = {
               options: [storeWithVmOption, selectedStorageOption, browseDialogOption],
               selection: selectedStorageOption
            };
         } else {
            dataStores = {
               options: [storeWithVmOption, browseDialogOption],
               selection: storeWithVmOption
            };
         }
         return dataStores;
      };

      public getAllBackendStorageCallPromises($q: IQService, vmAssignmentsProperties: any, storageProfiles: any) {

         let input: any = vmAssignmentsProperties;

         let vmAssignmentsPromise = input.then((result: any) => {

            let nested = {
               assignments: result.vmStorageProfileAssignments,
               replication: result.vmReplicationGroupAssignments
            };

            if (!nested.replication) {
               nested.replication = $q.when({});
            }

            nested = <any> $q.all(nested).then((res: any) => {

                     let assignments = res.assignments.vmStorageProfileAssignments;
                     let replication = res.replication.vmReplicationGroupAssignments;

                     let retVal = {
                        vmStorageProfileAssignments: assignments,
                        vmReplicationGroupAssignments: replication
                     };
                     return retVal;
                  }, (reason: any) => {
                     return {
                        vmStorageProfileAssignments: null,
                        vmReplicationGroupAssignments: null
                     };
                  }
            );
            return nested;
         }).catch((reason: any) => {
                  return {
                     vmStorageProfileAssignments: null,
                     vmReplicationGroupAssignments: null
                  };
               }
         );

         let retVal = {
            storageProfilesPromiseResult: storageProfiles,
            vmAssignmentsPromiseResult: vmAssignmentsPromise
         };
         return retVal;
      }
   }

   angular.module('com.vmware.vsphere.client.vm')
      .service('vmHardwareVirtualDiskService', VmHardwareVirtualDiskService);
}

