module h5_vm {
   export class NetworkType {
      static readonly STD_NETWORK = "com.vmware.vim.binding.vim.vm.NetworkInfo";
      static readonly NSX_NETWORK = "com.vmware.vim.binding.vim.vm.OpaqueNetworkInfo";
      static readonly DVSWITCH =    "com.vmware.vim.binding.vim.dvs.DistributedVirtualSwitchInfo";
      static readonly DVPORGGROUP = "com.vmware.vim.binding.vim.dvs.DistributedVirtualPortgroupInfo";
   }
   export class NetBackingType {
      static readonly STD_NETWORK = "com.vmware.vim.binding.vim.vm.device.VirtualEthernetCard$NetworkBackingInfo";
      static readonly NSX_NETWORK = "com.vmware.vim.binding.vim.vm.device.VirtualEthernetCard$OpaqueNetworkBackingInfo";
      static readonly DVPORGGROUP = "com.vmware.vim.binding.vim.vm.device.VirtualEthernetCard$DistributedVirtualPortBackingInfo";
   }
}
