/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace dvs_ui {

   export class DvsEditNetFlowDialogComponent {

      public bindings: any;
      public controller: any;
      public templateUrl: string;

      constructor() {
         this.bindings = {
            pageModel: "<"
         };
         this.controller = DvsEditNetFlowController;
         this.templateUrl = "dvs-ui/resources/dvs/netflow/edit/dvsEditNetFlowDialogTemplate.html";
      }
   }

   class DvsEditNetFlowController {

      static $inject = [
         "i18nService",
         "networkUtil",
         "vxValidatorFactory",
         "dvsEditNetFlowDialogValidator"
      ];

      private i18n: any;
      private pageModel: DvsEditNetFlowDialogModel;
      private collectorIpAddressPlaceholder: string;
      private signPostConfig: any;
      private validator: any;
      private validateCollectorIpAddress: Function;
      private validateSwitchIpAddress: Function;
      private validateCollectorPort: Function;
      private validateObservationDomainId: Function;
      private validateActiveFlowTimeout: Function;
      private validateIdleFlowTimeout: Function;
      private validateSamplingRate: Function;
      private internalFlowsOnlyOptions: any;

      constructor(private i18nService: any,
                  private networkUtil: any,
                  private vxValidatorFactory: any,
                  private dvsEditNetFlowDialogValidator: DvsEditNetFlowDialogValidator) {
         this.i18n = i18nService.getString;
         this.validator = vxValidatorFactory.create();
         this.validateCollectorIpAddress = this.collectorIpValidator.bind(this);
         this.validateSwitchIpAddress = this.switchIpValidator.bind(this);
         this.validateCollectorPort = this.collectorPortValidator.bind(this);
         this.validateObservationDomainId = this.observationDomainIdValidator.bind(this);
         this.validateActiveFlowTimeout = this.activeFlowTimeoutValidator.bind(this);
         this.validateIdleFlowTimeout = this.idleFlowTimeoutValidator.bind(this);
         this.validateSamplingRate = this.samplingRateValidator.bind(this);
      }

      public $onInit() {
         this.collectorIpAddressPlaceholder = this.pageModel.ipv6ForIpfixSupported ?
               this.i18n("NetworkUi", "NetFlowPage.collector.ipAllPrompt") :
               this.i18n("NetworkUi", "NetFlowPage.collector.ipv4Prompt");
         this.initSwitchAddressSignPost();

         this.internalFlowsOnlyOptions = [{
            label: this.i18nService.getString(
                  "NetworkUi", "NetFlowPage.advanced.internalFlowsOnlyEnabled"),
            value: DvsEditNetFlowDialogConstants.INTERNAL_FLOWS_ONLY_ENABLED
         }, {
            label: this.i18nService.getString(
                  "NetworkUi", "NetFlowPage.advanced.internalFlowsOnlyDisabled"),
            value: DvsEditNetFlowDialogConstants.INTERNAL_FLOWS_ONLY_DISABLED
         }];
      }

      private collectorIpValidator(): string[] {
         let message: string | null = null;
         if (this.pageModel.collectorIpAddress) {
            message = this.dvsEditNetFlowDialogValidator.getCollectorIpValidationMessage(
                  this.pageModel.collectorIpAddress, this.pageModel.ipv6ForIpfixSupported);
         }
         return message ? [message] : [];
      }

      private switchIpValidator(): string[] {
         let message: string | null = null;
         if (this.pageModel.switchIpAddress) {
            message = this.dvsEditNetFlowDialogValidator
                  .getSwitchIpValidationMessage(this.pageModel.switchIpAddress);
         }
         return message ? [message] : [];
      }

      private collectorPortValidator(): string[] {
         let message: string | null = this.dvsEditNetFlowDialogValidator
               .getCollectorPortValidationMessage(this.pageModel.collectorPort);
         return message ? [message] : [];
      }

      private observationDomainIdValidator(): string[] {
         let message: string | null = this.dvsEditNetFlowDialogValidator
               .getObservationDomainIdValidationMessage(this.pageModel.observationDomainId);
         return message ? [message] : [];
      }

      private activeFlowTimeoutValidator(): string[] {
         let message: string | null = this.dvsEditNetFlowDialogValidator
               .getActiveFlowTimeoutValidationMessage(this.pageModel.activeFlowTimeout);
         return message ? [message] : [];
      }

      private idleFlowTimeoutValidator(): string[] {
         let message: string | null = this.dvsEditNetFlowDialogValidator
               .getIdleFlowTimeoutValidationMessage(this.pageModel.idleFlowTimeout);
         return message ? [message] : [];
      }

      private samplingRateValidator(): string[] {
         let message: string | null = this.dvsEditNetFlowDialogValidator
               .getSamplingRateValidationMessage(this.pageModel.samplingRate);
         return message ? [message] : [];
      }

      private initSwitchAddressSignPost(): void {
         let messages: string[] = [
            this.i18n("NetworkUi", "NetFlowPage.info.switchIp.message")
         ];

         if (this.pageModel.ipv6ForIpfixSupported) {
            messages.push(this.i18n(
                  "NetworkUi", "NetFlowPage.info.switchIp.ipv6Disclaimer"));
         }

         this.signPostConfig = {
            message: this.formatTextMessages(messages),
            title: this.i18n("NetworkUi", "NetFlowPage.info.switchIp.title"),
            class: "edit-netflow-switchAddress-signpost"
         };
      }

      private formatTextMessages(messages: string[]): string {
         let formattedMessage: string = "";
         let template: string =
               "<div class='horizontal-flex-container'><span class='info-msg-color'>%msg%</span></div>";
         messages.forEach(function (message) {
            if (formattedMessage) {
               formattedMessage += "<br>";
            }
            formattedMessage += template.replace("%msg%", message);
         });
         return formattedMessage;
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .component("dvsEditNetFlowDialog", new DvsEditNetFlowDialogComponent());
}
