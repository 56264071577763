/* Copyright 2018 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace network_ui {

   export class MoveNetworkToComponent {

      public bindings: any;
      public controller: any;
      public templateUrl: string;

      constructor() {
         this.bindings = {
            pageModel: "="
         };

         this.controller = MoveNetworkToComponentController;

         this.templateUrl =
               "network-ui/resources/network/views/moveTo/moveNetworkToComponentTemplate.html";
      }
   }

   class MoveNetworkToComponentController {

      private pageModel: any;

      public networkIds: string[];
      public entityToMoveNetworkTo: any;
      private onLocationSelected: Function;

      public static $inject = [];

      constructor() {
      }

      $onInit(): void {
         this.networkIds = this.pageModel.objectIds;

         this.onLocationSelected = (objectReference: any) => {
            this.pageModel.entityToMoveNetworkTo = objectReference;
         };
      }
   }

   angular.module("com.vmware.vsphere.client.network")
         .component("moveNetworkToComponent", new MoveNetworkToComponent());

}

