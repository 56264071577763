/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
(function() {
   'use strict';
   angular.module('com.vmware.vsphere.client.storage').controller(
      'StorageDevicesPathsDetailsViewController', StorageDevicesPathsDetailsViewController);

   StorageDevicesPathsDetailsViewController.$inject = [
      '$scope',
      'hostStorageService',
      'navigation'
   ];

   function StorageDevicesPathsDetailsViewController($scope, hostStorageService, navigation) {
      var self = this;
      self.loading = false;
      self.hostId = navigation.getRoute().objectId;
      // View id for persisting column definitions state.
      self.viewId = navigation.getRoute().extensionId + '_paths';

      self.cache = {};

      $scope.$watch(function() { return $scope.masterDetailsViewContext.selectedItem; },
         function(newValue, oldValue) {
            if (newValue !== oldValue && newValue) {
               self.refresh();
            }
         });

      function getSelectedDeviceIdentifier() {
         if (!$scope.masterDetailsViewContext.selectedItem) {
            return null;
         }

         return $scope.masterDetailsViewContext.selectedItem.identifier;
      }

      self.refresh = function() {

         var deviceIdentifier = getSelectedDeviceIdentifier();
         if (!deviceIdentifier) {
            return;
         }

         var hostId = self.hostId;
         var cacheId = hostId + '-' + deviceIdentifier;

         if (self.cache.hasOwnProperty(cacheId)) {
            self.paths = self.cache[cacheId];
         } else {
            self.loading = true;
            self.paths = [];
         }

         hostStorageService.retrieveHostStorageDevicePathsData(hostId, deviceIdentifier)
            .then(function(devicePaths) {
               self.cache[cacheId] = devicePaths;
               if (hostId === self.hostId && deviceIdentifier === getSelectedDeviceIdentifier()) {
                  self.paths = devicePaths;
               }
            })
            .finally(function() {
               if (hostId === self.hostId && deviceIdentifier === getSelectedDeviceIdentifier()) {
                  self.loading = false;
               }
            });
      };

      self.refresh();
   }
})();
