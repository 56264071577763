/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
(function() {
   'use strict';
   angular.module('com.vmware.vsphere.client.cluster')
         .controller('DrsController', DrsController);

   DrsController.$inject = ['$scope', 'dataService', 'i18nService',
      'drsAutomationLevelService', 'drsMigrationThresholdService',
      'dpmAutomationLevelService', 'dpmThresholdService', 'vcService',
      'drsPoliciesService'];

   function DrsController($scope, dataService, i18nService,
         drsAutomationLevelService, drsMigrationThresholdService,
         dpmAutomationLevelService, dpmThresholdService, vcService, drsPoliciesService) {
      var self = this;
      var objectId = $scope._route.objectId;

      self.headerOptions = {
         title: i18nService.getString('ClusterUi', 'services.drs'),
         objectId: objectId,
         actions: [{
            actionUid: 'vsphere.core.cluster.actions.scheduleDrs',
            customLabel: i18nService.getString('ClusterUi', 'drs.config.scheduleDrs.button.label')
         }, {
            actionUid: 'vsphere.core.cluster.actions.restoreRpTree',
            customLabel: i18nService.getString('ClusterUi', 'drs.config.restore.rp.tree.action.label')
         }, {
            actionUid: 'vsphere.core.cluster.actions.editDrs',
            customLabel: i18nService.getString('ClusterUi', 'general.config.button.edit')
         }]
      };
      self.watchForObjects = [objectId];
      self.liveRefreshProperties = [
         "configurationEx.drsConfig.enabled",
         "configurationEx.drsConfig.enableVmBehaviorOverrides",
         "configurationEx.drsConfig.defaultVmBehavior",
         "configurationEx.drsConfig.option",
         "configurationEx.drsConfig.vmotionRate",
         "configurationEx.dpmConfigInfo.defaultDpmBehavior",
         "configurationEx.dpmConfigInfo.enabled",
         "configurationEx.dpmConfigInfo.hostPowerActionRate",
         "configurationEx.proactiveDrsConfig.enabled"
      ];
      self.getViewData = getViewData;
      self.getString = getString;
      self.refreshUi = refreshUi;

      self.advancedOptionsLabel = getString('drs.config.advancedOptionsLabel');
      self.configParametersLabel =
            getString('drs.config.advancedOptions.configParametersLabel');
      self.is65VcOrLater = false;
      self.drsConfigAccessor = {};

      getViewData();

      getVcVersion();

      function refreshUi() {
         self.getViewData();
         if (self.drsConfigAccessor && self.drsConfigAccessor.refresh) {
            self.drsConfigAccessor.refresh();
         }
      }

      function getViewData() {
         dataService.getData(objectId,
               'com.vmware.vsphere.client.clusterui.model.services.DrsConfigData',
               { queryName: 'DrsController.' + getViewData.name })
               .then(function(drsData) {
                  self.drsData = drsData ? parseDrsData(drsData) : {};
                  self.dpm = drsData ? getDpmSettings(drsData) : {};
                  self.policies = drsData ? getPolicies(drsData) : {};
                  if (drsData) {
                     self.headerOptions.title = getTitle(drsData.drsEnabled);
                  }

                  var hasAdvancedOptions =
                        !!self.drsData.advancedOptions &&
                        self.drsData.advancedOptions.length > 0;
                  self.advancedOptionsText =
                        hasAdvancedOptions ?
                              getString('drs.config.advancedOptionsCollapsedText.show')
                              : getString('drs.config.advancedOptionsCollapsedText.none');
               });
      }

      function getVcVersion() {
         vcService.is65VcOrLater(objectId)
               .then(function(is65VcOrLater) {
                  self.is65VcOrLater = is65VcOrLater;
               });
      }

      function getTitle(drsEnabled) {
         return (drsEnabled) ?
               getString('drs.config.drsEnabled')
               : getString('drs.config.drsDisabled');
      }

      function parseDrsData(drsData) {
         var currentAutomationLevel =
               drsAutomationLevelService.getLevelByKey(drsData.defaultDrsBehavior);
         drsData.drsAutomationLevelText = currentAutomationLevel.label;
         drsData.drsAutomationLevelDescription = currentAutomationLevel.description;

         drsData.migrationThresholdText =
               drsMigrationThresholdService.getText(drsData.migrationThreshold);
         drsData.migrationThresholdDescription =
               drsMigrationThresholdService.getDescription(drsData.migrationThreshold);

         drsData.predictiveDrsText = getPredictiveDrsText(drsData);

         drsData.vmAutomationText = getString(drsData.vmAutomationEnabled ?
               'drs.config.vmAutomation.enabled' :
               'drs.config.vmAutomation.disabled');
         return drsData;
      }

      function getDpmSettings(data) {
         var automationLevel = dpmAutomationLevelService.getText(
               data.dpmEnabled, data.defaultDpmBehavior);
         var automationLevelDescription = dpmAutomationLevelService.getDescription(
               data.dpmEnabled, data.defaultDpmBehavior);
         var dpmThreshold = dpmThresholdService.reverseDpmRateAndThreshold(data.dpmRate);
         var threshold = dpmThresholdService.getText(data.dpmEnabled, dpmThreshold);

         return {
            automationLevel: automationLevel,
            automationLevelDescription: automationLevelDescription,
            threshold: threshold
         };
      }

      function getPredictiveDrsText(drsData) {
         if (drsData.proactiveDrsEnabled) {
            return getString('drs.portlet.migration.forecastedMetrics.enabled');
         } else {
            return getString('drs.portlet.migration.forecastedMetrics.disabled');
         }
      }

      function getPolicies(data) {
         var evenDistributionLabel = drsPoliciesService.getEvenDistributionLabel(data);
         var consumedOrActiveMemoryLabel = drsPoliciesService.getConsumedOrActiveMemoryLabel(
               data);
         var cpuOverCommitmentLabel = drsPoliciesService.getCpuOverCommitmentLabel(data);
         return {
            evenDistributionLabel: evenDistributionLabel,
            consumedOrActiveMemoryLabel: consumedOrActiveMemoryLabel,
            cpuOverCommitmentLabel: cpuOverCommitmentLabel
         };
      }

      function getString() {
         var args = Array.prototype.slice.call(arguments);
         return i18nService.getString.apply(i18nService, ['ClusterUi'].concat(args));
      }
   }
})();
