/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
/*
 * Service to select a device for increasing Datastore capacity
 */
(function() {
   'use strict';
   angular.module('com.vmware.vsphere.client.storage')
      .service('selectDevicePageService', selectDevicePageService);

   selectDevicePageService.$inject = [
      'i18nService',
      'wizardPageService'
   ];

   function selectDevicePageService (
      i18nService, wizardPageService
   ) {

      return {
         build: function(wizardScope) {

            return {
               title: i18nService.getString('StorageUi', 'increaseDatastoreWizard.selectDevicePage.title'),
               description: i18nService.getString('StorageUi', 'increaseDatastoreWizard.selectDevicePage.description'),
               contentUrl: 'storage-ui/resources/storage/views/wizard/increaseDatastoreCapacity/pages/SelectDevicePage.html',
               state: undefined,
               onCommit: function() {
                  var manager = wizardScope.manager;
                  if (manager.getSelectedDevice()) {
                     if (!manager.getOptionsForSelectedDevice()) {
                        wizardPageService.showLoadingIndicator(wizardScope.wizardConfig);
                        return manager.loadDatastoreInfo().then(manager.fetchVmfsDatastoreOptions).then(function() {
                           wizardPageService.markPageComplete(wizardScope.wizardConfig);
                           wizardPageService.hideLoadingIndicator(wizardScope.wizardConfig);
                           return true;
                        });
                     } else {
                        wizardPageService.markPageComplete(wizardScope.wizardConfig);
                        return true;
                     }

                  } else {
                     wizardPageService.markPageIncompleteWithError(
                        wizardScope.wizardConfig,
                        i18nService.getString('StorageUi', 'datastoreWizards.selectDevicePage.noSelection')
                     );
                     return false;
                  }
               }
            };
         }
      };


   }
})();
