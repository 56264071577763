module cluster_ui {

   import IComponentController = angular.IComponentController;

   export class DrsNetworkUtilizationController implements IComponentController {

      private objectId: string;
      private DRS_CLUSTER_UTILIZATION_MODEL: string =
            "com.vmware.vsphere.client.clusterui.model.monitor.drs.utilization.DrsNetworkClusterUtilizationData";
      public drsUtilizationData: any = {};
      public watchForObjects: string[] = [this.objectId];
      public liveRefreshProperties: string[] = ["configStatus",
            "summary.currentBalance"];

      public static $inject = ["i18nService", "dataService",
          "contextObjectService"];

      constructor( private i18nService: any,
                  private dataService: any,
                  private contextObjectService: any) {
         this.objectId = this.contextObjectService.getSelection()[0];
         this.getViewData();
      }

      public getViewData(): void {
         this.dataService.getData(this.objectId, this.DRS_CLUSTER_UTILIZATION_MODEL)
               .then((data: any) => this.drsUtilizationData = data);
      }

      public getString(key: string, ...params: any[]) : string {
         return this.i18nService.getString("ClusterUi", key, params);
      }
   }

   angular.module("com.vmware.vsphere.client.cluster")
         .controller("DrsNetworkUtilizationController", DrsNetworkUtilizationController);
}
