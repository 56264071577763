namespace h5_vm {

   import VmConfigContext = com.vmware.vsphere.client.vm.config.VmConfigContext;
   declare const angular: ng.IAngularStatic;
   import ConfigInfo = com.vmware.vim.binding.vim.vm.ConfigInfo;
   import GuestOsDescriptor = com.vmware.vim.binding.vim.vm.GuestOsDescriptor;
   class SelectOption { id: string; name: string;} // option for html select

   class VmGeneralOptionsController implements ng.IComponentController {

      static $inject: string[] = [
         'i18nService',
         'vmGeneralOptionsService'
      ];

      private config:            ConfigInfo;
      private vmId:              string;
      private vmConfigContext:   VmConfigContext;
      private vmParams:          any;
      private modalOptions:      any;
      public expanded:           boolean;

      private i18n:              Function;

      private vmName:            string;
      private vmConfigFile:      string;
      private vmWorkingLocation: string;
      private editMode:          boolean;

      public gosFamily:         SelectOption;
      public gosFamilyOptions: SelectOption[];

      public gosVersion:        SelectOption;
      public gosVersionOptions: SelectOption[];

      private guestOsList:GuestOsDescriptor[];

      constructor(private i18nService: any,
                  private service: VmGeneralOptionsService) {
         this.i18n = (key: string, bundle: string = "VmUi") => i18nService.getString(bundle, key);
      }

      private i18n2(key: string, section: string = 'GeneralOptions') {
         return this.i18nService.getString("VmUi", section + '.' + key);
      }

      public ARIA_LBL_NAME: string = this.i18n2('vm_name_body');
      public ARIA_LBL_GOS_FML: string = this.i18n2('gos_family');
      public ARIA_LBL_GOS_VER: string = this.i18n2('gos_version');

      $onInit() {
         if (this.modalOptions) {
            this.setupForEditMode();
         } else {
            this.setupForCloneAndCreate();
         }
      }

      private setupForEditMode () {
         this.editMode          = true;
         this.vmName            = this.config.name;
         this.vmConfigFile      = this.config.files.vmPathName;
         this.vmWorkingLocation = this.config.files.snapshotDirectory;
      }

      public expandedChanged(ev: any): void {
         if (!this.editMode) {
            return;
         }
         this.requestGuestOsListIfNeeded();
      }

      private guestOsListRequested: boolean = false;

      public requestGuestOsListIfNeeded(): void {
         if (!this.editMode) {
            return;
         }

         if (this.guestOsListRequested) {
            return;
         }
         this.guestOsListRequested = true;
         this.service.getAllGosVersionsPromise(this.vmId).then(this.onGuestOsList.bind(this));
      }

      private onGuestOsList(result:any): void {
         this.guestOsList = result? result.guestOsList: [];
         this.initGuestOptions();
      }

      private initGuestOptions():void {
         const guestId = this.config.guestId;
         const guest:GuestOsDescriptor|null = this.service.findDescriptor(guestId, this.guestOsList);
         let familyId = guest? guest.family: "otherGuestFamily";
         familyId = this.service.simplifyFamily(familyId);

         this.gosFamily = this.service.familyObject(familyId);
         this.gosFamilyOptions  = this.service.getGosFamilyOptions();

         const guestName:string  = this.config.guestFullName;
         this.gosVersion= {id: guestId,  name: guestName};

         const array: GuestOsDescriptor[] = this.service.getFamilyVersions(familyId, this.guestOsList);
         this.gosVersionOptions = this.service.getVersionsOptions(array);
      }

      private setupForCloneAndCreate () {
         this.vmName = this.vmParams.getName();
         this.gosFamily = {
            name: this.vmParams.getGosFamily().name, id:""
         };
         this.gosFamilyOptions = [this.gosFamily];
         this.gosVersion = {
            name: this.vmParams.getGosVersion().name, id:""
         };
         this.gosVersionOptions = [this.gosVersion];

         this.editMode = false;
      }

      private isEditNameDisabled(): boolean  {
         return this.service.isEditNameDisabled(this.vmConfigContext.privileges, this.editMode);
      }

      private isEditGOSDisabled(): boolean  {
         return !this.editMode
               || this.service.isEditGOSDisabled(this.vmConfigContext);
      }

      private familyChanged(): any  {
         // ng-change comes here
         if (!this.editMode || !this.guestOsList) {
            return;
         }
         this.changeFamily(this.gosFamily.id);
      }

      private changeFamily(familyId:string):void {
         if (!this.editMode || !this.guestOsList) {
            return;
         }
         const array: GuestOsDescriptor[] = this.service.getFamilyVersions(familyId, this.guestOsList);

         if (!array || array.length === 0) {
            return;
         }

         const guestOs: GuestOsDescriptor = array[0];
         this.gosVersion = {name: guestOs.fullName, id: guestOs.id};
         this.gosVersionOptions = this.service.getVersionsOptions(array);
         this.updateConfigInfo(guestOs);
      }

      private versionChanged(): any  {
         if (!this.editMode) {
            return;
         }

         if (!this.guestOsList || !this.gosVersion) {
            return;
         }

         const guestId:string = this.gosVersion.id;
         const guest:GuestOsDescriptor|null = this.service.findDescriptor(guestId, this.guestOsList);

         if (guest) {
            this.updateConfigInfo(guest);
         }
      }

      updateConfigInfo(gos: GuestOsDescriptor): void {
         if (!this.editMode) {
            return;
         }
         this.config.guestFullName = gos.fullName;
         this.config.guestId = gos.id;
      }
   }

   class VmGeneralOptions implements ng.IComponentOptions {
      bindings: any;
      controller: any;
      templateUrl: string;

      constructor() {
         this.bindings = {
            config: '<?',
            vmId: '<',
            vmParams: '<?',
            vmConfigContext: '<',
            modalOptions: '<?',
            expanded: '='
         };
         this.controller = VmGeneralOptionsController;
         this.templateUrl = 'vm-ui/resources/vm/views/settings/vmOptions/generalOptions/general-options.html';
      }
   }

   angular.module('com.vmware.vsphere.client.vm')
      .component('generalOptions', new VmGeneralOptions());
}
