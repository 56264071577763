/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
(function () {
   'use strict';

   angular.module('com.vmware.vsphere.client.networkLibUi')
         .factory('networkUtil', networkUtil);

   networkUtil.$inject = ['i18nService', 'networkUiConstants', 'defaultUriSchemeUtil'];

   function networkUtil(i18nService, networkUiConstants, defaultUriSchemeUtil) {
      var VLAN_NUM_NONE = 0;
      var VLAN_NUM_ALL = 4095;
      var HOST_NAME_REGEX = /^[A-Z0-9]([A-Z0-9\-]*[A-Z0-9])*$/i;

      return {
         compareNumericValues: compareNumericValues,
         compareNumericAndNonNumericValues: compareNumericAndNonNumericValues,
         formatVlanId: formatVlanId,
         isVlanIdValid: isVlanIdValid,
         isMtuValid:isMtuValid,
         getString: getString,
         fillPoliciesCategory: fillPoliciesCategory,
         formatTextMessagesWithIcon: formatTextMessagesWithIcon,
         isDistributedSwitch: isDistributedSwitch,
         isPositiveInteger: isPositiveInteger,
         createNicTeamingPolicyOptions: createNicTeamingPolicyOptions,
         isHostNameValid: isHostNameValid,
         isDomainNameValid: isDomainNameValid,
         getStringLengthInUtf8Bytes: getStringLengthInUtf8Bytes,
         NETWORK_LABEL_MAX_LENGTH: 127
      };

      function compareNumericValues(item1, item2, field) {
         var firstValue = Number(item1[field]);
         var secondValue = Number(item2[field]);

         if (isNaN(firstValue) && isNaN(secondValue)) {
            return 0;
         }
         if (isNaN(firstValue)) {
            return -1;
         }
         if (isNaN(secondValue)) {
            return 1;
         }

         if (firstValue < secondValue) {
            return -1;
         }
         if (firstValue > secondValue) {
            return 1;
         }
         return 0;
      }

      /**
       * Sorts numeric and non-numeric entries and puts the numeric ones before
       * the non-numeric ones
       * @param item1
       * @param item2
       * @param field
       * @returns {*}
       */
      function compareNumericAndNonNumericValues(item1, item2, field) {
         var firstValue = Number(item1[field]);
         var secondValue = Number(item2[field]);

         if (!isNaN(firstValue) && !isNaN(secondValue)) {
            return compareNumericValues(item1, item2, field);
         } else if (isNaN(firstValue) && !isNaN(secondValue)) {
            return 1;
         } else if (isNaN(secondValue) && !isNaN(firstValue)) {
            return -1;
         } else {
            return item1[field].localeCompare(item2[field]);
         }
      }

      function formatVlanId(vlanId) {
         if (vlanId === VLAN_NUM_NONE) {
            return i18nService.getString('NetworkUi', 'NetworkUtil.vlanNone');
         } else if (vlanId === VLAN_NUM_ALL) {
            return i18nService.getString('NetworkUi', 'NetworkUtil.vlanAll', VLAN_NUM_ALL);
         } else {
            if (vlanId) {
               return '' + vlanId;
            }
         }
         return '';
      }

      function isVlanIdValid(vlanId) {
         var isVlanIdInteger = isPositiveInteger(vlanId);
         return isVlanIdInteger && vlanId >= 0 && vlanId <= VLAN_NUM_ALL;
      }

      function isMtuValid(mtu) {
         var isMtuPositiveInteger = isPositiveInteger(mtu);
         return typeof mtu !== 'undefined'
               && isMtuPositiveInteger
               && mtu >= networkUiConstants.Mtu.MIN
               && mtu <= networkUiConstants.Mtu.MAX;
      }

      /**
       * Validates whether the given number is a positive integer (1,2,3...).
       * If min- and maxValue are provided, the method validates if the
       * positive integer number is withing the given range
       *
       * @param number
       * @param minValue(optional)
       * @param maxValue(optional)
       */
      function isPositiveInteger(number, minValue, maxValue) {
         minValue = minValue || 0;
         maxValue = maxValue && maxValue <= networkUiConstants.MAX_SAFE_INTEGER ?
               maxValue : networkUiConstants.MAX_SAFE_INTEGER;
         return networkUiConstants.POSITIVE_INTEGER_REG_EXP.test(number)
               && minValue <= number
               && number <= maxValue;
      }

      /**
       * Creates an array of objects with value and label attributes ready to be used
       * as select box options.
       * @param nicTeamingPolicy array of strings containing all nicTeaming policies.
       */
      function createNicTeamingPolicyOptions(nicTeamingPolicy) {
         return _.map(nicTeamingPolicy, function (policy) {
            return {
               val: policy,
               label: i18nService.getString(
                     'NetworkUi', 'PortFailoverPolicyView.' + policy)
            };
         });
      }

      /**
       * Checks if the specified host name is valid.
       * Host names can only contain alphanumeric characters and hyphens
       * and can not start or end with a hyphen.
       *
       * @param hostName
       *    The specified host name
       */
      function isHostNameValid(hostName) {
         return !!hostName && HOST_NAME_REGEX.test(hostName);
      }

      /**
       * Checks if the specified domain name is valid.
       * matches: FQDN name valid characters
       * Each domain label can contain letters, digits, hyphens '(-)', or period '(.)',
       * but cannot start or end with a hyphen '(-)'.
       * @param domainName
       * @returns {boolean}
       */
      function isDomainNameValid(domainName) {
         if (!domainName) {
            return false;
         }

         var labels = domainName.split('.');

         if (labels[labels.length - 1] === '') {
            // the last label is an empty string
            // which means that the domain name ends with a '.'
            // remove it and validate the rest
            labels.pop();
         }

         return labels.every(function (label) {
            return label && HOST_NAME_REGEX.test(label);
         });
      }

      function getString() {
         var args = Array.prototype.slice.call(arguments);
         return i18nService.getString.apply(null, ['NetworkUi'].concat(args));
      }

      /**
       * Fills given <code>policiesCategory<code/> with sections wit hproperties
       * depending on the values of given <code>portPolicy<code/>.
       *
       * The sections created are:
       * <code>security<code/>, <code>trafficShaping<code/> and <code>teaming<code/>.
       *
       * @param policiesCategory the category that will be filled
       * @param portPolicy the policy properties are used for filling the categories
       */
      function fillPoliciesCategory(policiesCategory, portPolicy) {
         // Security
         if (portPolicy.security) {
            var securitySection = policiesCategory
                  .section('security')
                  .title(i18nService.getString('NetworkUi', 'PortgroupSettingsView.security'));

            securitySection.property(
               i18nService.getString('NetworkUi', 'PortSecurityPolicyView.promiscuousMode'),
               portPolicy.security.allowPromiscuous
                     ? i18nService.getString('NetworkUi', 'PortSecurityPolicyView.accept')
                     : i18nService.getString('NetworkUi', 'PortSecurityPolicyView.reject'));

            securitySection.property(
               i18nService.getString('NetworkUi', 'PortSecurityPolicyView.mac'),
               portPolicy.security.macChanges
                     ? i18nService.getString('NetworkUi', 'PortSecurityPolicyView.accept')
                     : i18nService.getString('NetworkUi', 'PortSecurityPolicyView.reject'));

            securitySection.property(
               i18nService.getString('NetworkUi', 'PortSecurityPolicyView.forgedTransmits'),
               portPolicy.security.forgedTransmits
                     ? i18nService.getString('NetworkUi', 'PortSecurityPolicyView.accept')
                     : i18nService.getString('NetworkUi', 'PortSecurityPolicyView.reject'));
         }

         // Traffic shaping
         if (portPolicy.trafficShaping) {
            var trafficShapingSection = policiesCategory
                  .section('trafficShaping')
                  .title(i18nService.getString('NetworkUi', 'PortgroupSettingsView.trafficShaping'));

            trafficShapingSection.property(
               i18nService.getString('NetworkUi', 'PortTrafficShapingPolicyView.avgBW'),
               replaceEmptyString(portPolicy.trafficShaping.averageBandwidthSummary));

            trafficShapingSection.property(
               i18nService.getString('NetworkUi', 'PortTrafficShapingPolicyView.peakBW'),
               replaceEmptyString(portPolicy.trafficShaping.peakBandwidthSummary));

            trafficShapingSection.property(
               i18nService.getString('NetworkUi', 'PortTrafficShapingPolicyView.burstSize'),
               replaceEmptyString(portPolicy.trafficShaping.burstSizeSummary));
         }

         // Teaming and failover
         if (portPolicy.teamingAndFailover) {
            var teamingAndFailover = portPolicy.teamingAndFailover;
            var teamingSection = policiesCategory
                  .section('teaming')
                  .title(i18nService.getString('NetworkUi', 'PortgroupSettingsView.failover'));

            teamingSection.property(
               i18nService.getString('NetworkUi', 'PortFailoverPolicyView.lbLabel'),
               getLoadBalancingPolicyValue(teamingAndFailover.loadBalancing));

            if (teamingAndFailover.loadBalancing === networkUiConstants.LoadBalancingPolicy.IP) {
               var warningMessage = getLoadBalancingWarningMessage(teamingAndFailover);
               if (warningMessage) {
                  teamingSection.warning(warningMessage);
               }
               getLoadBalancingInfoMessages().forEach(function(message) {
                  teamingSection.info(message);
               });
            }

            teamingSection.property(
               i18nService.getString('NetworkUi', 'PortFailoverPolicyView.detectionLabel'),
               teamingAndFailover.failureDetection
                     ? i18nService.getString('NetworkUi', 'PortFailoverPolicyView.beaconProbing')
                     : i18nService.getString('NetworkUi', 'PortFailoverPolicyView.linkStatus'));

            teamingSection.property(
               i18nService.getString('NetworkUi', 'PortFailoverPolicyView.notifyLabel'),
               teamingAndFailover.notifySwitches
                     ? i18nService.getString('NetworkUi', 'PortFailoverPolicyView.notifyOn')
                     : i18nService.getString('NetworkUi', 'PortFailoverPolicyView.notifyOff'));

            teamingSection.property(
               i18nService.getString('NetworkUi', 'PortFailoverPolicyView.failbackLabel'),
               teamingAndFailover.failback
                     ? i18nService.getString('NetworkUi', 'PortFailoverPolicyView.failbackOn')
                     : i18nService.getString('NetworkUi', 'PortFailoverPolicyView.failbackOff'));

            teamingSection.property(
               i18nService.getString('NetworkUi', 'PortFailoverPolicyView.active'),
               replaceEmptyString(teamingAndFailover.activeAdapters.join(", ")));

            teamingSection.property(
               i18nService.getString('NetworkUi', 'PortFailoverPolicyView.standby'),
               replaceEmptyString(teamingAndFailover.standByAdapters.join(", ")));

            teamingSection.property(
               i18nService.getString('NetworkUi', 'PortFailoverPolicyView.inactive'),
               replaceEmptyString(teamingAndFailover.unusedAdapters.join(", ")));
         }
      }

      function replaceEmptyString(value) {
         return value
               ? value
               : i18nService.getString('NetworkUi', 'VnicPortPropertiesView.noData');
      }

      function getLoadBalancingInfoMessages() {
         return [
            i18nService.getString(
               'NetworkUi',
               "PortPolicyData.failover.lb.loadbalance_ip.info.etherChannel"),
            i18nService.getString(
               'NetworkUi',
               "PortPolicyData.failover.lb.loadbalance_ip.info.portGroupPolicies")
         ];
      }

      function getLoadBalancingWarningMessage(teamingAndFailover) {
         if (teamingAndFailover.standByAdapters.length !== 0 && teamingAndFailover.failureDetection) {
            return i18nService.getString(
               'NetworkUi', "PortPolicyData.failover.lb.loadbalance_ip.warn.beaconProbingAndStandbyUplinks");
         } else if (teamingAndFailover.standByAdapters.length !== 0) {
            return i18nService.getString(
               'NetworkUi', "PortPolicyData.failover.lb.loadbalance_ip.warn.standbyUplinks");
         } else if (teamingAndFailover.failureDetection) {
            return i18nService.getString(
               'NetworkUi', "PortPolicyData.failover.lb.loadbalance_ip.warn.beaconProbing");
         } else {
            return null;
         }
      }

      function getLoadBalancingPolicyValue(policy) {
         switch (policy) {
            case networkUiConstants.LoadBalancingPolicy.SRCID:
               return i18nService.getString(
                  'NetworkUi', 'PortFailoverPolicyView.loadbalance_srcid');
            case networkUiConstants.LoadBalancingPolicy.IP:
               return i18nService.getString(
                  'NetworkUi', 'PortFailoverPolicyView.loadbalance_ip');
            case networkUiConstants.LoadBalancingPolicy.SRCMAC:
               return i18nService.getString(
                  'NetworkUi', 'PortFailoverPolicyView.loadbalance_srcmac');
            case networkUiConstants.LoadBalancingPolicy.EXPLICIT:
               return i18nService.getString(
                  'NetworkUi', 'PortFailoverPolicyView.failover_explicit');
            default:
               return i18nService.getString(
                  'NetworkUi', 'VnicPortPropertiesView.noData');
         }
      }

      function formatTextMessagesWithIcon(messages, iconClass) {
         if (!messages || messages.length === 0) {
            return null;
         }
         var result = [];
         var template = "<div class='row'>" +
               "<span class='col-md-1'>" +
               "<span class='" + iconClass + " failover-icon'></span>" +
               "</span>" +
               "<span class='col-md-11 info-msg-color'>{{msg}}</span>" +
               "</div>";
         _.each(messages, function (message) {
            result.push(template.replace("{{msg}}", message));
         });
         return result;
      }

      function isDistributedSwitch(objectId){
         var type = defaultUriSchemeUtil.getEntityType(objectId);
         return type === "DistributedVirtualSwitch" ||
                type === "VmwareDistributedVirtualSwitch";
      }

      /**
       * Helper function used to count bytes in a given input string.
       * The string is firstly converted to UTF-8 encoding via encodeURIComponent.
       * Then each special character (matching '%[A-F\d]{2}' pattern)
       * is replaced and counted as a single character to get accurate byte count.
       *
       * @param inputString the string passed
       * @returns {number}
       *    - 0 if the passed string is incorrect
       *    - number of bytes in the given inputString when converted to UTF-8
       */
      function getStringLengthInUtf8Bytes(inputString) {
         if (!inputString || typeof inputString !== 'string') {
            return 0;
         }

         return encodeURIComponent(inputString).replace(/%[A-F\d]{2}/g, 'U').length;
      }
   }
})();
