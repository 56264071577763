module cluster_ui {

   import IController = angular.IController;

   class SelectClusterVmOverridesController implements IController {

      public static $inject = ["$scope", "i18nService", "vuiConstants",
         "clusterVmOverridesPageService", "clusterVmOverridesConstants",
         "defaultUriSchemeUtil"];

      public selectedVmsSpec: any;
      public vmSettingsSpec: any;
      private objectId: string;

      constructor(private $scope: any,
                  private i18nService: any, private vuiConstants: any,
                  private clusterVmOverridesPageService: ClusterVmOverridesPageService,
                  private clusterVmOverridesConstants: any, private defaultUriSchemeUtil: any) {

         this.objectId = $scope.manager.getClusterId();
         this.vmSettingsSpec = {
            objectId: this.objectId,
            mode: clusterVmOverridesConstants.operationType.ADD
         };
         this.selectedVmsSpec = {
            objectId: this.objectId,
            vms: []
         };

         $scope.manager.setSelectedVmsSpec(this.selectedVmsSpec);
         $scope.manager.setVmSettingsSpec(this.vmSettingsSpec);

         this.populateVms();

      }

      private populateVms(): void {
         this.clusterVmOverridesPageService.populateVmFtRole(this.$scope.manager.getSelectedVms())
               .then((selectedVms: any[]) => {
                  this.selectedVmsSpec.vms = selectedVms;
               });
      }
   }

   angular.module("com.vmware.vsphere.client.cluster")
         .controller("SelectClusterVmOverridesController", SelectClusterVmOverridesController);
}


