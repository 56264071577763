(function () {
   'use strict';
   angular.module('com.vmware.vsphere.client.cluster')
      .controller('VSphereHaController', VSphereHaController);

   VSphereHaController.$inject = ['$scope', 'dataService', 'i18nService', 'vuiConstants',
         'columnRenderersRegistry', 'haConstants', 'haAdmissionControlService',
         'heartbeatDatastoresService', '$element', 'vcService'];

   function VSphereHaController($scope, dataService, i18nService, vuiConstants,
         columnRenderersRegistry, haConstants, haAdmissionControlService,
         heartbeatDatastoresService, $element, vcService) {
      var self = this;
      var objectId = $scope._route.objectId;
      var iconTextRenderer = columnRenderersRegistry.getColumnRenderer('object-link');
      var wrappedTextRenderer =
            columnRenderersRegistry.getColumnRenderer('wrapped-text');

      self.haStatusHeaderOptions = getViewHeaderOptions();
      self.headerFailureConditionsOptions = getFailureConditionsOptions();
      self.expandForDetailsLabel = getString('ha.config.expandForDetails');
      self.dsForHeartbeatingLabel = getString('ha.config.dsForHeartbeating');

      self.datagridOptions = getDatagridOptions();
      self.watchForObjects = [objectId];
      self.liveRefreshProperties = [
         "configurationEx.dasConfig.heartbeatDatastore",
         "configurationEx.dasConfig.enabled",
         "configurationEx.dasConfig.defaultVmSettings.isolationResponse",
         "configurationEx.dasConfig.defaultVmSettings.restartPriority",
         "configurationEx.dasConfig.hBDatastoreCandidatePolicy",
         "configurationEx.dasConfig.admissionControlPolicy",
         "configurationEx.dasConfig.admissionControlEnabled",
         "configurationEx.dasConfig.admissionControlPolicy.cpuFailoverResourcesPercent",
         "configurationEx.dasConfig.admissionControlPolicy.memoryFailoverResourcesPercent",
         "configurationEx.dasConfig.admissionControlPolicy.failoverLevel",
         "configurationEx.dasConfig.admissionControlPolicy.failoverHosts",
         "configurationEx.dasConfig.option",
         "configurationEx.dasConfig.hostMonitoring",
         "configurationEx.dasConfig.vmMonitoring",
         "dasAdvancedRuntimeInfo"
      ];
      self.data = {};
      self.admissionControlData = {};
      self.getViewData = getViewData;
      self.admissionControlPolicy = haConstants.admissionControlPolicy;

      self.advOptionsBlock = {
         expanded: false
      };
      self.advancedOptionsLabel = getString('ha.config.advancedOptionsLabel');
      self.configParametersLabel =
         getString('ha.config.advancedOptions.configParametersLabel');
      self.proactiveHaActions = [{
         actionUid: 'vsphere.core.cluster.actions.editProactiveHa',
         customLabel: getString('general.config.button.edit'),
      }];

      getViewData();

      getVcVersion();

      //
      // Controller functions
      //

      function getViewHeaderOptions() {
         return {
            title: getString('ha.config.HA'),
            actions: [{
               actionUid: 'vsphere.core.cluster.actions.editHa',
               customLabel: getString('general.config.button.edit')
            }]
         };
      }

      function getFailureConditionsOptions() {
         return {
            title: getString('ha.config.responseTable.header'),
            objectId: objectId,
            actions: []
         };
      }

      function getViewData() {
         dataService
            .getData(objectId,
               'com.vmware.vsphere.client.clusterui.model.services.ClusterHaData')
            .then(function (data) {
               self.data = data;
               self.haStatusHeaderOptions.title = getTitle(data.haEnabled);
               self.proactiveHaTitle =
                     getProactiveHaTitle(data.infraUpdateHaEnabled, data.drsEnabled);
               self.isProactiveHaAvailable = data.drsEnabled;
               self.datagridOptions.data = data.failureConditionData.items;

               // this object is passed to vui stack block in order to access its
               // expanded property later in order to decide whether the
               // heartbeat-datastores directive will be instantiated or not
               self.heartbeatDatastoreBlock = {
                  expanded: false
               };

               self.isHeartbeatDatastorePolicyAllFeasible = true;
               self.heartbeatDatastoresPolicyLabel = '';
               var hbDsPolicy = data.heartbeatDatastoreCandidatePolicy;
               if (!!hbDsPolicy) {
                  self.isHeartbeatDatastorePolicyAllFeasible =
                        heartbeatDatastoresService.isAutoSelectPolicy(hbDsPolicy);

                  var dsForHeartbeatingPolicyKey = 'ha.config.'.concat(hbDsPolicy);
                  self.heartbeatDatastoresPolicyLabel =
                        getString(dsForHeartbeatingPolicyKey);
               }
               self.admissionControlData =
                     haAdmissionControlService.getAdmissionControlModel(data);

               var hasAdvancedOptions =
                  !!self.data.advancedOptions &&
                  self.data.advancedOptions.length > 0;
               self.advancedOptionsText =
                  hasAdvancedOptions ?
                     getString('ha.config.advancedOptionsCollapsedText.show')
                     : getString('ha.config.advancedOptionsCollapsedText.none');
            });
      }

      function getVcVersion() {
         vcService.is65VcOrLater(objectId)
               .then(function(is65VcOrLater) {
                  self.is65VcOrLater = is65VcOrLater;
               });
      }

      function getTitle(haEnabled) {
         return haEnabled ?
            getString('ha.config.haEnabled')
            : getString('ha.config.haDisabled');
      }

      function getProactiveHaTitle(proHaEnabled, drsEnabled) {
         return drsEnabled ? (proHaEnabled ?
               getString('ha.config.prohaEnabled')
               : getString('ha.config.prohaDisabled')) : getString('ha.config.prohaNa');
      }

      function getString(key) {
         return i18nService.getString('ClusterUi', key);
      }

      function getDatagridOptions() {
         return {
            selectionMode: vuiConstants.grid.selectionMode.NONE,
            sortMode: vuiConstants.grid.sortMode.NONE,
            searchable: false,
            resizable: false,
            selectedItems: [],
            data: [],
            pageConfig: {
               hidePager: true
            },
            columnDefs: getColumnDefs(),
            dataBound: function() {
               $element.find('[vui-datagrid] .k-grid-content').css('height', '');
            },
            height: '100%'
         };
      }

      function getColumnDefs() {
         return [
            {
               displayName: getString('ha.config.vmResponse.failure.column'),
               field: 'failure'
            },
            {
               displayName: getString('ha.config.vmResponse.failureResponse.column'),
               field: 'failureResponse',
               template: function(dataItem) {
                  var cellData = {
                     text: dataItem.failureResponse
                  };
                  var icon = getIconClass(dataItem.failureResponseType);
                  return iconTextRenderer([undefined, 'text', icon], cellData);
               }
            },
            {
               displayName: getString('ha.config.vmResponse.details.column'),
               field: 'details',
               template: function(dataItem) {
                  return wrappedTextRenderer(['details'], dataItem);
               }
            }
         ];
      }

      function getIconClass(failureType) {
         if (failureType === 'responseDisabled') {
            return 'vsphere-icon-status-error';
         } else if (failureType === 'responseProtected') {
            return 'vsphere-icon-status-ok';
         } else if (failureType === 'responseWarnOnly') {
            return 'vsphere-icon-status-warning';
         }
         return '';
      }
   }
})();
