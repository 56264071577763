/* Copyright 2015 VMware, Inc. All rights reserved. -- VMware Confidential */
(function() {
   'use strict';
   angular.module('com.vmware.vsphere.client.folder').controller('VcAdvancedSettingsController', VcAdvancedSettingsController);

   VcAdvancedSettingsController.$inject = ['$scope', 'i18nService'];

   function VcAdvancedSettingsController($scope, i18nService) {
      var self = this;
      var objectId = $scope._route.objectId;

      var headerOptions = {
            title: i18nService.getString('FolderUi', 'advancedSettings.view.title'),
            actions: [],
            objectId: objectId
      };
      var settingsViewOptions = {
            propertyName: 'vcServerSettings.advancedSettings',
            objectId: objectId
      };

      self.headerOptions = headerOptions;
      self.settingsViewOptions = settingsViewOptions;
   }
})();
