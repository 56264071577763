module h5_vm {
   import Privileges = h5_vm.Privileges;
   angular.module('com.vmware.vsphere.client.vm').service('vmHardwareCdromManagerService', [
      '$q',
      'i18nService',
      'EditableVirtualDevice',
      'vmHardwareUtil',
      'featureFlagsService',
      'contentLibraryService',
      'vcService',
      function ($q: any,
                i18nService: any,
                EditableVirtualDevice: any,
                vmHardwareUtil: any,
                featureFlagsService: any,
                contentLibraryService: any,
                vcService: any) {
         var EMULATION_ID = 1;
         var PASSTHROUGH_ID = 2;
         var CLIENT_DEVICE = 'CdromConfig.ClientDevice';
         var DATASTORE_ISO_FILE = 'CdromConfig.DatastoreISOFile';
         var CONTENT_LIBRARY_ISO_FILE = 'CdromConfig.CLISOFile';
         var HOST_DEVICE = 'CdromConfig.HostDevice';
         var NOT_AVAILABLE_HOST_DEVICE = 'NotAvailableHostDevice';
         var EMULATION_MODE = {
            label: i18nService.getString('VmUi', 'CdromConfig.EmulateCdrom'),
            id: EMULATION_ID
         };
         var PASSTHROUGH_MODE = {
            label: i18nService.getString('VmUi', 'CdromConfig.PassthroughCdrom'),
            id: PASSTHROUGH_ID
         };
         var cdromList: any;

         return {
            createCdromList: createCdromList,
            createIsoBacking: createIsoBacking,
            getNewDeviceBacking: getNewDeviceBacking,
            createClientDeviceBacking: createClientDeviceBacking,
            getAvailableMediaTypes: getAvailableMediaTypes,
            getInitialMediaType: getInitialMediaType,
            getInitialDeviceMode: getInitialDeviceMode,
            getAvailableDeviceModes: getAvailableDeviceModes,
            isClientDevice: isClientDevice,
            isDevicePresentInTheHost: isDevicePresentInTheHost,
            isHostDevice: isHostDevice,
            isDatastoreISOFile: isDatastoreISOFile,
            isContentLibraryISOFile: isContentLibraryISOFile,
            isRemoteDevice: isRemoteDevice,
            isCdRomDisabled: isCdRomDisabled,
            isCdRomConnectedAtPowerOnDisabled: isCdRomConnectedAtPowerOnDisabled,
            isCdRomConnectedDisabled: isCdRomConnectedDisabled,
            isDeviceModeDisabled: isDeviceModeDisabled,
            hasPrivileges: hasPrivileges,
            hasHostDevicebacking: hasHostDevicebacking
         };

         function hasPrivileges(privileges: any) {
            return vmHardwareUtil.checkPrivileges(privileges, [Privileges.VM_ADDREMOVEDEVICE_PRIVILEGE]);
         }

         function createCdromList(rawCdromList: any) {
            cdromList = _.map(rawCdromList, function (cdrom: any, idx: any) {
               return {
                  id: idx,
                  name: cdrom.name
               };
            });
            return cdromList;
         }

         function createIsoBacking(isoFileName: any, datastore: any, backingObjectId: any) {
            return {
               _type: "com.vmware.vim.binding.vim.vm.device.VirtualCdrom$IsoBackingInfo",
               fileName: isoFileName,
               datastore: datastore ? datastore : null,
               backingObjectId: backingObjectId ? backingObjectId : null
            };
         }

         // this method also updates scope.properties.deviceMode :(
         function getNewDeviceBacking(properties: any) {
            var mediaTypeId = properties.mediaType.id;
            var backing: any;

            if (mediaTypeId === HOST_DEVICE) {
               properties.deviceMode = EMULATION_MODE;
               if (cdromList.length > 0) {
                  properties.selectedCdrom = cdromList[0];
                  backing = createHostDeviceBacking(properties.selectedCdrom);
               }
            } else if (mediaTypeId === CLIENT_DEVICE) {
               backing = createClientDeviceBacking(properties.deviceMode);
            }

            return backing;
         }

         function createClientDeviceBacking(deviceMode: any) {
            var backing: any = {
               deviceName: ""
            };
            if (deviceMode.id === EMULATION_ID) {
               backing._type = 'com.vmware.vim.binding.vim.vm.device.VirtualCdrom$RemoteAtapiBackingInfo';
            } else if (deviceMode.id === PASSTHROUGH_ID) {
               backing._type = 'com.vmware.vim.binding.vim.vm.device.VirtualCdrom$RemotePassthroughBackingInfo';
               backing.exclusive = false;
            } else {
               throw new Error("Unknown mode: " + deviceMode);
            }
            return backing;
         }

         function getAvailableMediaTypes(cdromList: any, objectId: any) {
            return vcService.is65VcOrLater(objectId)
                  .then(function (is65VcOrLater: boolean) {
                     var mediaTypeKeys = getMediaTypeKeys(cdromList, is65VcOrLater);
                     return _.map(mediaTypeKeys, function (key) {
                        return {
                           id: key,
                           label: i18nService.getString('VmUi', key)
                        };
                     });
                  });
         }

         function getAvailableDeviceModes() {
            return [PASSTHROUGH_MODE, EMULATION_MODE];
         }

         function isClientDevice(mediaType: any) {
            return mediaType && mediaType.id === CLIENT_DEVICE;
         }

         function isRemoteDevice(backing: any) {
            return backing._type.indexOf('Remote') >= 0;
         }

         function isHostDevice(mediaType: any) {
            return mediaType && mediaType.id === HOST_DEVICE;
         }

         function isDatastoreISOFile(mediaType: any) {
            return mediaType && mediaType.id === DATASTORE_ISO_FILE;
         }

         function isContentLibraryISOFile(mediaType: any) {
            return mediaType && mediaType.id === CONTENT_LIBRARY_ISO_FILE;
         }

         function hasHostDevicebacking(device: any) {
            if (!_.isEmpty(device) &&
                  device.backing &&
                  device.backing._type === "com.vmware.vim.binding.vim.vm.device.VirtualCdrom$AtapiBackingInfo") {
               return true;
            }

            return false;
         }

         // the two below are only used once to initialize scope.properties
         // i thought about making a function that initializes properties and return the
         // correct object, but then it's not as clear what's really on scope.properties   ¯\_(ツ)_/¯
         function getInitialDeviceMode(backingType: any) {
            if (backingType.match('Passthrough')) {
               return PASSTHROUGH_MODE;
            } else {
               return EMULATION_MODE;
            }
         }

         function getInitialMediaType(backing: any, availableMediaTypes: any) {
            var deferred = $q.defer();
            const backingType: string = backing._type;

            if (backingType.match('IsoBackingInfo')) {

               const DS_ISO_FILE: string = findMediaType(DATASTORE_ISO_FILE);
               const CL_ISO_FILE: string = findMediaType(CONTENT_LIBRARY_ISO_FILE);

               // by default return at least normal ISO,
               // unless we successfully query ContentLibrary
               let retVal: any = {mediaType: DS_ISO_FILE};

               contentLibraryService.getContentLibraryItemByDatastorePath(backing.fileName)
                     .then(function (result: any) {

                        class Item {
                           errorMessages?: string[];
                           friendlyName?: string;
                           id?: string;
                           isoPath?: string;
                           name?: string;
                        }

                        const item: Item = <Item> result.contentLibraryItemByDatastorePath;
                        const errors: any = item ? item.errorMessages : [];
                        let failed: boolean = Array.isArray(errors) && errors.length > 0;

                        // We using friendlyName filed to fill in html input.
                        // It also hooked to validation via "required" attribute.
                        const friendlyName = item ? item.friendlyName : "";

                        if (!friendlyName || friendlyName === "") {
                           // fail back to normal ISO if missing friendly name
                           failed = true;
                        }

                        if (item && !failed) {
                           retVal = {mediaType: CL_ISO_FILE, selectedIsoItem: item};
                        }

                        deferred.resolve(retVal);
                     }, function (error: any) {
                        // returning as Datastore ISO in case of CL error
                        deferred.resolve(retVal);
                     });

            } else if (backing._type.match('Remote')) {
               deferred.resolve({
                  mediaType: findMediaType(CLIENT_DEVICE)
               });
            } else {
               var hostDevice = findMediaType(HOST_DEVICE);
               //If the host doesn't have cd, the host device is not available
               if (!hostDevice) {
                  hostDevice = {
                     id: NOT_AVAILABLE_HOST_DEVICE
                  };
               }
               deferred.resolve({
                  mediaType: hostDevice
               });
            }

            function findMediaType(id: any) {
               return _.find(availableMediaTypes, function (element: any) {
                  return element.id === id;
               });
            }

            return deferred.promise;
         }

         function isCdRomDisabled(privileges: any, skipPrivilegeCheck: any) {
            if (skipPrivilegeCheck) {
               return false;
            }

            var hasPrivilege = vmHardwareUtil.checkPrivileges(privileges, ['VirtualMachine.Interact.SetCDMedia']);
            return !hasPrivilege;
         }

         function isCdRomConnectedAtPowerOnDisabled(mediaType: any, privileges: any, skipPrivilegeCheck: any) {

            var validate = function () {
               return isClientDevice(mediaType);
            };

            if (skipPrivilegeCheck) {
               return validate();
            }

            var hasPrivilege = vmHardwareUtil.checkPrivileges(privileges, ['VirtualMachine.Interact.DeviceConnection']);
            return !hasPrivilege || validate();
         }

         function isCdRomConnectedDisabled(backing: any, privileges: any, skipPrivilegeCheck: any) {

            var validate = function () {
               return isRemoteDevice(backing);
            };

            if (skipPrivilegeCheck) {
               return validate();
            }

            var hasPrivilege = vmHardwareUtil.checkPrivileges(privileges, ['VirtualMachine.Interact.DeviceConnection']);
            return !hasPrivilege || validate();
         }

         function isDevicePresentInTheHost(deviceName: any, cdRomList: any) {
            if (_.isEmpty(cdRomList)) {
               return false;
            }

            return _.some(cdRomList, function (device: any) {
               return device.name === deviceName;
            });
         }

         function isDeviceModeDisabled(mediaType: any, privileges: any, skipPrivilegeCheck: any) {

            var validate = function () {
               return isDatastoreISOFile(mediaType) || isContentLibraryISOFile(mediaType) || isHostDevice(mediaType);
            };

            if (skipPrivilegeCheck) {
               return validate();
            }

            var hasPrivilege = vmHardwareUtil.checkPrivileges(privileges, ['VirtualMachine.Interact.SetCDMedia']);
            return !hasPrivilege || validate();
         }

         // ----- private ----- //
         function getMediaTypeKeys(cdromList: any, is65VcOrLater: boolean) {
            var keys: Array<any> = [];
            keys.push(CLIENT_DEVICE);
            if (cdromList.length > 0) {
               keys.push(HOST_DEVICE);
            }
            keys.push(DATASTORE_ISO_FILE);
            if (featureFlagsService.h5uiDirectIsoMountFromCLEnabled && is65VcOrLater) {
               keys.push(CONTENT_LIBRARY_ISO_FILE);
            }
            return keys;
         }

         function createHostDeviceBacking(selectedCdrom: any) {
            return {
               _type: "com.vmware.vim.binding.vim.vm.device.VirtualCdrom$AtapiBackingInfo",
               deviceName: selectedCdrom.name
            };
         }
      }
   ]);

}
