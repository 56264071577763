namespace h5_vm {
   export class SelectIsoModalService {
      static $inject: string[] = ['clarityModalService',
         'i18nService',
         'defaultUriSchemeUtil',
         'datastoreBrowserConstants',
         'contentLibraryService',
         'dataService'];

      constructor(private clarityModalService: any,
                  private i18nService: any,
                  private defaultUriSchemeUtil: any,
                  private datastoreBrowserConstants: any,
                  private contentLibraryService: any,
                  private dataService: any) {
      }

      public openIsoDeviceSelector(computeResourceId: any, okHandler: Function, cancelHandler?: Function) {

         let dialogData: any = {
            i18nService: this.i18nService,
            selectedIso: {},
            datastoreBrowserId: '',
            datastoreIds: [] = new Array(),
            initialFileQueryType: '',
            filterFileQueryTypes: [] = new Array(),
            showVmImagesFolder: true,
            onIsoChange: this.onIsoChange.bind(this),
         };

         this.dataService.getProperties(computeResourceId, ['vmCdromContext']).then((response: any) => this.processResponse(response, dialogData, okHandler, cancelHandler));

      }

      private processResponse(response: any, dialogData: any, okHandler: Function, cancelHandler?: Function): any {

         let dsBrowserId:string = this.defaultUriSchemeUtil.getVsphereObjectId(
            response.vmCdromContext.datastoreBrowser);
         let vmDatastores:Array<any> = response.vmCdromContext.datastore;
         let datastoreIds:Array<any>;
         if (vmDatastores) {
            datastoreIds = vmDatastores.map((vmDatastore: any) => {
               return this.defaultUriSchemeUtil.getVsphereObjectId(vmDatastore.datastore.datastore);
            });
         } else {
            datastoreIds = [];
         }

         let initialFileQueryType:string = this.datastoreBrowserConstants.fileQueryType.ISO_IMAGE
            + this.datastoreBrowserConstants.fileQueryType.FOLDERS
            + this.datastoreBrowserConstants.fileQueryType.FLOPPY_IMAGE;
         let filterQueryTypes:Array<any> = [{
            label: this.i18nService.getString('Common', 'dsBrowser.isoImageFile'),
            value: this.datastoreBrowserConstants.fileQueryType.ISO_IMAGE
            + this.datastoreBrowserConstants.fileQueryType.FOLDERS
         },
            {
               label: this.i18nService.getString('Common', 'dsBrowser.allFiles'),
               value: this.datastoreBrowserConstants.fileQueryType.ALL_FILES
            }
         ];

         dialogData.datastoreBrowserId = dsBrowserId;
         dialogData.datastoreIds = datastoreIds;
         dialogData.initialFileQueryType = initialFileQueryType;
         dialogData.filterFileQueryTypes = filterQueryTypes;

         let modalOptions: any = {
            title: this.i18nService.getString("VmUi", "contentLibISO.infoHeader"),
            contentTemplate: 'vm-ui/resources/vm/views/summary/modals/select-iso/select-iso.html',
            defaultButton: 'submit',
            size: 'lg',
            dialogData: dialogData,
            hideSubmitButton: false,
            submitDisabled: true,
            onCancel: this.onCancel.bind(this)
         };

         modalOptions.onSubmit = this.onSubmit.bind(this, okHandler, modalOptions);

         this.clarityModalService.openOkCancelModal(modalOptions);
         return modalOptions;

      };

      private onCancel(callback: Function) : void {
         if (angular.isFunction(callback)) {
            callback();
         }
      };

      private onSubmit(callback: Function, modalOptions: any) : boolean {
         if (angular.isFunction(callback)) {
            callback(modalOptions.dialogData.selectedIso);
         }
         return true;
      };

      private onIsoChange(item: any, modalOptions: any): void {
         modalOptions.dialogData.selectedIso = item;
         if (item) {
            modalOptions.submitDisabled = false;
         } else {
            modalOptions.submitDisabled = true;
         }
      };

   }
   angular.module("com.vmware.vsphere.client.vm")
      .service("selectIsoModalService", SelectIsoModalService);
}
