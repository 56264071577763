namespace h5_vm {

import MigrateSelectStoragePageModel = h5_vm.MigrateSelectStoragePageModel;
import VirtualDiskRecord = h5_vm.VirtualDiskRecord;
import SelectMigrationTypePageModel = h5_vm.SelectMigrationTypePageModel;
import SelectComputeResourcePageModel = h5_vm.SelectComputeResourcePageModel;
import SelectComputeResourceTreePageModel = h5_vm.SelectComputeResourceTreePageModel;
import MigrateSelectFolderPageModel = h5_vm.MigrateSelectFolderPageModel;
import MigrateFinishPageModel = h5_vm.MigrateFinishPageModel;
import ManagedObjectReference = com.vmware.vim.binding.vmodl.ManagedObjectReference;
import StorageProfile = com.vmware.vim.binding.vim.profile.host.StorageProfile;
import IPromise = angular.IPromise;
import LocationSpecPair = com.vmware.vsphere.client.vm.migration.LocationSpecPair;
import IQService = angular.IQService;
import VmWizardScheduleTaskPageModel = h5_vm.VmWizardScheduleTaskPageModel;
import VmAdvancedStoragePageData = com.vmware.vsphere.client.h5.vm.model.VmAdvancedStoragePageData;
import PmemUtilService = h5_vm.PmemUtilService;


export interface MigrationWizardManager$ComputeResourceValidationData
      extends MigrationService$ValidationResultsData {
}

interface SelectedStorageNamedType {
   name: string;
   storageRef: ManagedObjectReference;
   parentStoragePod?: ManagedObjectReference;
}

interface RelatedItems {
   label: string;
   relatedItems: any[];
}

export class MigrationWizardManager {

   /**
    * The one and only wizard model.
    */
   private _theModel: MigrationWizardDataModel;

   private _storageRecommendationServiceInstance: VmStorageRecommendationsService;
   private _xdrsRecommendationServiceInstance: VmXdrsRecommendationService;
   private _migrationTypePageModel: SelectMigrationTypePageModel;
   private _computeResourcePageModel: SelectComputeResourcePageModel;
   private _computeResourceTreePageModel: SelectComputeResourceTreePageModel;
   private _folderPageModel: MigrateSelectFolderPageModel;
   private _priorityPageModel: SelectPriorityPageModel;
   private _storagePageModel: MigrateSelectStoragePageModel;
   private _finishPageModel: MigrateFinishPageModel;
   private _vmWizardScheduleTaskPageModel: VmWizardScheduleTaskPageModel;
   private _networkPageModel: MigrateSelectNetworkPageModel;
   /**
    * Information about the source VM's cluster, host, resource pool, vapp, storage or neworks
    */
   private vmOrigin: RelatedItems[];

   /**
    * Contains the vmHomeDatastore, virtualDisks, vmReplicationGroupAssignments,
    * vmStorageProfileAssignments, host, and config.hardware.device
    * for each of the selected VMs
    */
   private advancedStorageDataPromise: IPromise<{ [key: string]: { [key: string]: VmAdvancedStoragePageData } }>;

   // TODO - vaivanov - Temp inner members to be used by pages which do not yet support
   // multiple vm migration. Remove this when all pages support multiple vm migration.
   private vmName: string;
   private vmId: string;

   private _storageNameMap: {[urn: string]: string};
   private _selectedStorage?: SelectedStorageNamedType;
   private _selectedDiskFormat?: {name: string, type: string};
   private _selectedStorageProfile?: StorageProfile;
   private _selectedReplicationGroup?: any;

   // Indicates whether the workflow has completed
   private _isFinished: boolean = false;

   // NOTE speev: Do not put anymore data here. Use class private variables!
   private state: any = {
      destinationComputeResource: null,
      networkMatches: null,
      selectedPriority: null,
      selectedMode: undefined,

      selectedRecommendation: null,
      isHotMigration: false,
   };

   constructor(
         private migrationService: MigrationService,
         private i18nService: any,
         private defaultUriSchemeUtil: {
            getManagedObjectReference: (objectId: string|undefined) => ManagedObjectReference,
            getVsphereObjectId: (resourceObject: any) => string,
            getEntityType: (objectId: string) => string,
            getPartsFromVsphereObjectId: (objectId: string) => {
               type: string,
               value: string,
               serverGuid: string
            }
         },
         private dataService: any,
         private $q: angular.IQService,
         private managedEntityConstants: any,
         private datastoreService: any,
         private vmMigrateStorageLocatorService: any,
         private migrationTypeConstants: any,
         private datastoreRecommendationService: any,
         private storageProfileService: any,
         private vmMigrateStorageAdvancedSummaryBuilder: VmMigrateStorageAdvancedSummaryBuilder,
         wizardScope: angular.IScope,
         private NetworkPageViewModes: any,
         private vmStorageRecommendationsService: any,
         private vmXdrsRecommendationService: any,
         vmProperties: {[vmId: string]: {[propName: string]: any}},
         private storageSelectorService: any,
         private featureFlagsService: any,
         private migrationHelperService: any,
         private relatedItemsService: any,
         private pmemUtilService: PmemUtilService
   ) {

      let vmIds: Array<string> = _.keys(vmProperties);
      let vmNames: {[propName: string]: string} = _.reduce(vmProperties,
            (result: any, value: any, key: string): {[propName: string]: string} => {
                  result[key] = value.name;
                  return result;
            },
            {});

      // TODO - vaivanov - remove this when all pages support multiple vm migration.
      this.vmId = vmIds[0];
      this.vmName = vmProperties[this.vmId]["name"];

      // All vms should be in the same state, so we only check the first one
      this.state.isHotMigration =
            vmProperties[vmIds[0]]["powerState"] === "poweredOn";
      this.state.isComputeResourceChanged = false;
      this.state.selectedRecommendation = {};

      this._theModel = new h5_vm.MigrationWizardDataModel(vmIds);
      this.vmOrigin = [];
      if (vmIds.length === 1) {
         this.relatedItemsService.getRelatedItems(this.vmId).then((relatedItems: any) => {
            this.vmOrigin = relatedItems;
         });
      }
      this.advancedStorageDataPromise = this.requestAdvancedStorageData(vmIds);
      this._theModel.vmNames = vmNames;
      this._migrationTypePageModel = new h5_vm.SelectMigrationTypePageModel();
      this._computeResourcePageModel = new h5_vm.SelectComputeResourcePageModel();
      this._computeResourceTreePageModel = new h5_vm.SelectComputeResourceTreePageModel();
      this._folderPageModel = new h5_vm.MigrateSelectFolderPageModel();
      this._storagePageModel = new h5_vm.MigrateSelectStoragePageModel();
      this._finishPageModel = new h5_vm.MigrateFinishPageModel();
      this._priorityPageModel = new h5_vm.SelectPriorityPageModel();
      this._storageRecommendationServiceInstance = new this.vmStorageRecommendationsService();
      this._xdrsRecommendationServiceInstance = new this.vmXdrsRecommendationService();
      this._vmWizardScheduleTaskPageModel = new h5_vm.VmWizardScheduleTaskPageModel(i18nService);
      this._networkPageModel = new h5_vm.MigrateSelectNetworkPageModel();

      wizardScope.$on("$destroy", () => {
         this._storageRecommendationServiceInstance
               .cancelRecommendations(this._isFinished);
      });

      this.declareModelDependencies(wizardScope);
   }

   /**
    * NOTE speev: We don't keep a reference to the scope on purpose. We only use it
    * to provide the automatic binding between model inputs and outputs.
    */
   private declareModelDependencies(scope: angular.IScope) {
      // wire the models with the core wizard model.
      this.declareMigrationTypeModelDependencies(scope);
      this.declareComputeResourceModelDependencies(scope);
      this.declareComputeResourceTreeModelDependencies(scope);
      this.declareFolderModelDependencies(scope);
      this.declareStorageModelDependencies(scope);
      this.declareFinishModelDependencies(scope);
      this.declarePriorityModelDependencies(scope);
   }

   private declareMigrationTypeModelDependencies(scope: angular.IScope) {
      // input
      scope.$watch(
         () => { return this._theModel.vms; },
         (newValue: any[]) => { this._migrationTypePageModel.vms = newValue; });

      // output
      scope.$watch(
         () => { return this._migrationTypePageModel.isXvcMigrationPossible; },
         (newValue: boolean) => { this._theModel.isXvcMigrationPossible = newValue; });
   }

   private declareComputeResourceModelDependencies(scope: angular.IScope) {
      // input
      scope.$watch(
         () => { return this._theModel.vms; },
         (newValue: any[]) => { this._computeResourcePageModel.vms = newValue; });

      // output
      scope.$watch(
         () => { return this._computeResourcePageModel.stretchedStorage; },
         (newValue: any) => { this._theModel.stretchedStorage = newValue; });
      scope.$watch(
         () => { return this._computeResourcePageModel.datacenter; },
         (newValue: string) => { this._theModel.datacenter = newValue; });
   }

   private declareComputeResourceTreeModelDependencies(scope: angular.IScope) {
      // input
      scope.$watch(
         () => { return this._theModel.vms; },
         (newValue: any[]) => { this._computeResourceTreePageModel.vms = newValue; });
      scope.$watch(
         () => { return this._theModel.isXvcMigrationPossible; },
         (newValue: boolean) => { this._computeResourceTreePageModel.isXvcMigrationPossible = newValue; });

      // output
      scope.$watch(
            () => { return this._computeResourceTreePageModel.datacenter; },
            (newValue: string) => { this._theModel.datacenter = newValue; });

      // NOTE speev: no output from page model for now - assigning something here
      // will be hard to join with the way the other compute resource page handles
      // its user interaction. We use directly the changeComputeResource() method
   }

   private declareFolderModelDependencies(scope: angular.IScope) {
      // input
      scope.$watch(
         () => { return this._theModel.vms; },
         (newValue: any[]) => { this._folderPageModel.vms = newValue; });

      scope.$watch(
         () => { return this._theModel.datacenter; },
         (newValue: string) => { this._folderPageModel.datacenter = newValue; });


      // output
      scope.$watch(
            () => { return this._folderPageModel.destinationFolder; },
            (newValue: any) => { this._theModel.destinationFolder = newValue; });
   }

   private declareStorageModelDependencies(scope: angular.IScope) {
      // input
      scope.$watch(
         () => { return this._theModel.vms; },
         (newValue: any[]) => { this._storagePageModel.vms = newValue; });
      scope.$watch(
         () => { return this.isXvcMigration(); },
         (newValue: boolean) => { this._storagePageModel.isXvcMigration = newValue; });
      scope.$watch(
         () => { return this.state.destinationComputeResource; },
         (newValue: any, oldValue: any) => {
            if (!newValue) {
               return;
            }

            let newEntityType = newValue.resourceRef ?
                  newValue.resourceRef.type : undefined;
            let newEntityId  = this.defaultUriSchemeUtil.getVsphereObjectId(
                  newValue.resourceRef);
            let oldEntityId = oldValue ?
                  this.defaultUriSchemeUtil.getVsphereObjectId(oldValue.resourceRef) :
                  "__DEADBEEF__";
            switch (newEntityType) {
               case "ResourcePool":
               case "VirtualApp":
                  this._storagePageModel.computeResource = newValue.clusterRef ?
                        this.defaultUriSchemeUtil.getVsphereObjectId(newValue.clusterRef) :
                        this.defaultUriSchemeUtil.getVsphereObjectId(newValue.hostRef);
                  this._storagePageModel.pool = newEntityId;
                  break;
               case "HostSystem":
               case "ClusterComputeResorce":
               default:
                  this._storagePageModel.computeResource = newEntityId;
                  this._storagePageModel.pool =
                        this.defaultUriSchemeUtil.getVsphereObjectId(
                              newValue.targetResourcePoolRef);
                  break;
            }

            this._storagePageModel.isComputeResourceChanged =
                  (newEntityId !== oldEntityId);
         });

      // output
      scope.$watch(
         () => { return this._storagePageModel.isAdvancedStorageMode; },
         (newValue: boolean) => { this._theModel.isAdvancedStorageMode = newValue; });
      scope.$watch(
         () => { return this._storagePageModel.virtualDisksByVm; },
         (newValue: {[key: string]: VirtualDiskRecord[]}) => {
            this._theModel.virtualDisksByVm = newValue;

            if (_.isEmpty(newValue)) {
               // During initial binding initialization
               this._selectedStorage = undefined;
               this._selectedStorageProfile = undefined;
               this._theModel.stretchedStorage = undefined;
               this._selectedReplicationGroup = undefined;
               return;
            }

            let disksForVm0 =  newValue[this._theModel.vms[0]];
            this._selectedStorage = {
               name: this.findStorageNameInMap(
                     disksForVm0[0].storage, this._storageNameMap),
               storageRef: this.defaultUriSchemeUtil.getManagedObjectReference(
                     disksForVm0[0].storage),
               parentStoragePod: this.defaultUriSchemeUtil.getManagedObjectReference(
                     disksForVm0[0].parentStoragePod)
            };
            this._selectedStorageProfile = disksForVm0[0].profile;
            this._selectedReplicationGroup = disksForVm0[0].replicationGroup;

            let diskFormat = this.findFirstDiskFormat(newValue);
            this._selectedDiskFormat = diskFormat ? {
                  name: diskFormat.label,
                  type: diskFormat.value
               } : undefined;
         });
      scope.$watch(
         () => { return this._storagePageModel.storageNameMap; },
         (newValue: {[key: string]: string}) => {
            this._storageNameMap = newValue;

            if (this._selectedStorage) {
               let storageId =  this.defaultUriSchemeUtil.getVsphereObjectId(
                     this._selectedStorage.storageRef);
               this._selectedStorage.name = this.findStorageNameInMap(
                     storageId, this._storageNameMap);
            }
         });
   }

   private declareFinishModelDependencies(scope: angular.IScope) {
      // input
      scope.$watch(
         () => { return this.state.destinationComputeResource; },
         (newValue: any, oldValue: any) => {
            if (!newValue) {
               return;
            }

            let newEntityId  = this.defaultUriSchemeUtil.getVsphereObjectId(
                  newValue.resourceRef);
            let oldEntityId = oldValue ?
                  this.defaultUriSchemeUtil.getVsphereObjectId(oldValue.resourceRef) :
                  "__DEADBEEF__";

            this._finishPageModel.isComputeResourceChanged =
                  (newEntityId !== oldEntityId);
         });

      scope.$watch(
            () => { return this._folderPageModel.destinationFolder; },
            (newValue: any, oldValue: any) => {
               if (!newValue) {
                  return;
               }
               let oldFolderId = oldValue ? oldValue : "__DEADBEEF__";
               this._finishPageModel.isFolderChanged = (newValue !== oldFolderId);
            });
   }

   private declarePriorityModelDependencies(scope: angular.IScope) {
      // output
      scope.$watch(
            () => { return this._priorityPageModel.priority; },
            (newValue: string) => { this._theModel.priority = newValue; });
   }

   public submit() {
      this._isFinished = true;
      const recommendationsByVm = this._storageRecommendationServiceInstance.getSelectedRecommendationsByVm();
      // Holds the VMs which should not be migrated using SDRS api
      const vmsNotUsingSdrs: Array<string> = [];
      _.each(this._theModel.vms, (vmId: string) => {
         const selectedRecommendations = recommendationsByVm[vmId];
         // Skip vms for which we have SDRS faults
         if (this._storageRecommendationServiceInstance.hasFaultsForVm(vmId)
               || this._xdrsRecommendationServiceInstance.hasFaults()) {
            return;
         }

         // If SDRS recommendations are generated for the VM then use SDRS API.
         if (!_.isEmpty(selectedRecommendations)
               && this.getSelectedMode() !== this.migrationTypeConstants.MIGRATE_RESOURCE_ONLY) {
            return this.migrationService.applyStorageRecommendations(
                  selectedRecommendations,
                  this.assertDefined<SelectedStorageNamedType>(this._selectedStorage).storageRef);
         } else {
            // VM does not use SDRS use regular migrate API.
            vmsNotUsingSdrs.push(vmId);
         }
      });

      if (vmsNotUsingSdrs.length > 0) {
         const operationName: string = this.i18nService.getString(
               "VmUi", "MigrationWizard.title.single", this.vmName);

         let targetResourcePoolRef: any, resourceRef: any;
         const destination: any = this.state.destinationComputeResource;

         if (destination) {
            targetResourcePoolRef = destination.targetResourcePoolRef;
            resourceRef = destination.resourceRef;
         }

         const dataModel: MigrationWizardDataModel =
               new h5_vm.MigrationWizardDataModel(vmsNotUsingSdrs);

         dataModel.pool = this.defaultUriSchemeUtil.getVsphereObjectId(targetResourcePoolRef);
         dataModel.computeResource = this._finishPageModel.hostForSilentSelection ?
               this._finishPageModel.hostForSilentSelection :
               this.defaultUriSchemeUtil.getVsphereObjectId(resourceRef);
         dataModel.priority = this._theModel.priority;
         dataModel.networkMatches = this.getNetworkMatches();
         if (this.getSelectedMode() !== this.migrationTypeConstants.MIGRATE_STORAGE_ONLY
               && this.migrationHelperService.isXvcOperation(this.model.vms,
                     this.defaultUriSchemeUtil.getVsphereObjectId(resourceRef))) {
            dataModel.destinationFolder = this.model.destinationFolder;
         }

         if (this.getSelectedMode() === this.migrationTypeConstants.MIGRATE_RESOURCE_ONLY) {
            // NOTE speev: Even though we change the compute resource only - behind
            // the scene the storage may have changed - in case of stretched storage
            // i.e. user picked compute resource from another DC/VC which sees the same
            // storage with different MOR value. In that case the new MOR need to be
            // supplied for the migration operation to succeed.
            dataModel.stretchedStorage = this._theModel.stretchedStorage;
         } else {
            // NOTE speev: When the storage selector is used - we always populate all
            // VM disks infos completely - even in the Basic mode. So we don't care
            // about modes here.
            dataModel.virtualDisksByVm = this._theModel.virtualDisksByVm;
         }

         this.migrationService.migrate(
               vmsNotUsingSdrs,
               operationName,
               dataModel,
               this._xdrsRecommendationServiceInstance.getSelectedRecommendation(),
               this._vmWizardScheduleTaskPageModel);
      }
   }

   public get migrationTypePageModel() {
      return this._migrationTypePageModel;
   }

   public get computeResourcePageModel() {
      return this._computeResourcePageModel;
   }

   public get computeResourceTreePageModel() {
      return this._computeResourceTreePageModel;
   }

   public get folderPageModel() {
      return this._folderPageModel;
   }

   public set isNetworkSelectionRequired(value: boolean) {
      this._theModel.isNetworkSelectionRequired = value;
   }

   /**
    * The one and only, always up-to-date wizard model. The wizard model purpose is
    * to gather all data collected when user is going through the wizard pages.
    *
    * @returns {MigrationWizardDataModel}
    */
   get model(): MigrationWizardDataModel {
      return this._theModel;
   }

   public get priorityPageModel() {
      return this._priorityPageModel;
   }

   public get vmWizardScheduleTaskPageModel() {
      return this._vmWizardScheduleTaskPageModel;
   }

   public get networkPageModel(): MigrateSelectNetworkPageModel {
      return this._networkPageModel;
   }

   public getFinishPageState() {
      let self = this;
      let model: any = {};
      if (self.getSelectedMode() === self.migrationTypeConstants.MIGRATE_RESOURCE_ONLY) {
         model = {
            migrationType: "MigrationWizard.readyPage.migrationWorkflowChangeResourceKeepStorage",
            vmIds: self.getVmIds(),
            vmNames: self.getVmNames(),
            clusterName: self.state.destinationComputeResource.clusterName,
            hostName: self.state.destinationComputeResource.hostName,
            resourcePoolName: self.state.destinationComputeResource.resPoolName,
            networksMessage: this._theModel.isNetworkSelectionRequired ?
                  this.createNetworksMessage() : undefined,
            recommendationService: this._storageRecommendationServiceInstance,
            xdrsRecommendationService: this.isXdrsRecommendationsNeeded() ?
                  this._xdrsRecommendationServiceInstance : undefined,
            folderName: self._finishPageModel.folderName,
            vcName: self._finishPageModel.vcName
         };
      } else if (self.getSelectedMode() === self.migrationTypeConstants.MIGRATE_STORAGE_ONLY) {
         model = {
            migrationType: "MigrationWizard.readyPage.migrationWorkflowChangeStorageKeepResource",
            vmIds: self.getVmIds(),
            vmNames: self.getVmNames(),
            storage: this._selectedStorage,
            pmemDiskInfo: this.getPmemDiskInfo(),
            diskFormat: this._selectedDiskFormat ? this._selectedDiskFormat.name : "",
            profile: this._selectedStorageProfile,
            replicationGroup: this._selectedReplicationGroup,
            storageRecommendations: self.state.allStorageRecommendations,
            // TODO - vaivanov - remove vmId and vmName from model once page supports multiple vms
            vmName: self.vmName,
            vmId: self.vmId,
            storageNameMap: this._storageNameMap,
            recommendationService: this._storageRecommendationServiceInstance,
            advancedStorageSummary: this._theModel.isAdvancedStorageMode ?
                  self.vmMigrateStorageAdvancedSummaryBuilder.build(
                        self._theModel,
                        angular.extend({}, self.getVmNames(), this._storageNameMap),
                        this._storageRecommendationServiceInstance) :
                  null

         };
      } else if (self.getSelectedMode() === self.migrationTypeConstants.MIGRATE_RESOURCE_AND_STORAGE) {
         model = {
            migrationType: "MigrationWizard.readyPage.migrationWorkflowChangeResourceThenStorage",
            vmIds: self.getVmIds(),
            vmNames: self.getVmNames(),
            clusterName: self.state.destinationComputeResource.clusterName,
            hostName: self.state.destinationComputeResource.hostName,
            resourcePoolName: self.state.destinationComputeResource.resPoolName,
            storage: this._selectedStorage,
            pmemDiskInfo: this.getPmemDiskInfo(),
            diskFormat: this._selectedDiskFormat ? this._selectedDiskFormat.name : "",
            profile: this._selectedStorageProfile,
            replicationGroup: this._selectedReplicationGroup,
            storageRecommendations: self.state.allStorageRecommendations,
            // TODO - vaivanov - remove vmId and vmName from model once page supports multiple vms
            vmName: self.vmName,
            vmId: self.vmId,
            storageNameMap: this._storageNameMap,
            networksMessage: this._theModel.isNetworkSelectionRequired ?
                  this.createNetworksMessage() : undefined,
            recommendationService: this._storageRecommendationServiceInstance,
            xdrsRecommendationService: this.isXdrsRecommendationsNeeded() ?
                  this._xdrsRecommendationServiceInstance : undefined,
            advancedStorageSummary: this._theModel.isAdvancedStorageMode ?
                  self.vmMigrateStorageAdvancedSummaryBuilder.build(
                        self._theModel,
                        angular.extend({}, self.getVmNames(), this._storageNameMap),
                        this._storageRecommendationServiceInstance) :
                  null,
            folderName: self._finishPageModel.folderName,
            vcName: self._finishPageModel.vcName
         };
      }
      if (this.state.isHotMigration &&
            this.getSelectedMode() !== this.migrationTypeConstants.MIGRATE_STORAGE_ONLY) {
         if (this._theModel.priority === "highPriority") {
            model.priority = self.i18nService.getString("VmUi", "MigrationWizard.readyPage.highPriority");
         } else if (this._theModel.priority === "lowPriority") {
            model.priority = self.i18nService.getString("VmUi", "MigrationWizard.readyPage.lowPriority");
         }
      }

      return model;
   }

   public setNetworkMatches(networkMatches: any[]) {
      this.state.networkMatches = networkMatches;
   }

   public getNetworkMatches() {
      return this.state.networkMatches;
   }

   public hasValidComputeResourceSelected(): boolean {
      return this.state.destinationComputeResource &&
            this.state.destinationComputeResource.isValid;
   }

   public hasValidStorage(): boolean {
      return !_.isEmpty(this._storagePageModel.virtualDisksByVm);
   }

   public hasValidFolderSelected(): boolean {
      return !!this._theModel.destinationFolder;
   }

   public setSelectedMode(selectedMode: string) {
      this.state.selectedMode = selectedMode;
   }

   public getSelectedMode(): string {
      return this.state.selectedMode;
   }

   get storagePageModel(): MigrateSelectStoragePageModel {
      return this._storagePageModel;
   }

   private fetchRecommendationsInternal(): IPromise<void> {
      if (this.isXdrsRecommendationsNeeded()) {
         return this.fetchXdrsRecommendations();
      } else {
         return this.fetchStorageRecommendations();
      }
   }

   public fetchRecommendations(): IPromise<void> {
      // Check whether silent host selection is required, and if yes, proceed with
      // retrieving valid destination hosts.
      this._finishPageModel.hostForSilentSelection = undefined;
      if (!this.isHostSelectionRequired()) {
         return this.fetchRecommendationsInternal();
      }

      let dataModel = this.buildDataModel();
      let locationSpecPairs = this.migrationService.getLocationSpecsForTesting(dataModel);

      return this.requestValidDestinationHosts(
            this.state.destinationComputeResource.resourceRef, locationSpecPairs)
            .then((hostRef) => {
               if (hostRef) {
                  this._finishPageModel.hostForSilentSelection =
                        this.defaultUriSchemeUtil.getVsphereObjectId(hostRef);
               }
               // NOTE: If no valid hosts were found, we STILL PROCEED with further data requests,
               // assuming that the xDRS-Recommendations retrieval will most probably fail due to
               // the lack of a preselected host. This will lead to displayed error message.
               return this.fetchRecommendationsInternal();
            });
   }

   private fetchStorageRecommendations(): IPromise<void> {
      let dataModel = new h5_vm.MigrationWizardDataModel(this._theModel.vms);
      let wizardMode = this.getSelectedMode();

      if (wizardMode === this.migrationTypeConstants.MIGRATE_RESOURCE_ONLY ||
            wizardMode === this.migrationTypeConstants.MIGRATE_RESOURCE_AND_STORAGE) {
         let dst = this.state.destinationComputeResource;
         dataModel.computeResource = dst ?
            this.defaultUriSchemeUtil.getVsphereObjectId(dst.resourceRef) :
            undefined;
         dataModel.pool = dst ?
            this.defaultUriSchemeUtil.getVsphereObjectId(dst.targetResourcePoolRef) :
            undefined;
      }

      if (wizardMode === this.migrationTypeConstants.MIGRATE_RESOURCE_ONLY ||
            wizardMode === this.migrationTypeConstants.MIGRATE_RESOURCE_AND_STORAGE) {
         dataModel.networkMatches = this.getNetworkMatches();
      }

      // NOTE speev: When the storage selector is used - we always populate all
      // VM disks infos completely - even in the Basic mode. So we don't care
      // about modes here.
      if (wizardMode === this.migrationTypeConstants.MIGRATE_STORAGE_ONLY ||
            wizardMode === this.migrationTypeConstants.MIGRATE_RESOURCE_AND_STORAGE) {
         dataModel.virtualDisksByVm = this._theModel.virtualDisksByVm;
      }

      // NOTE: Do a server round-trip only if a StoragePod is in the game
      if (!this.isFetchStorageRecommendationsNeeded(dataModel)) {
         return this._storageRecommendationServiceInstance.load([]);
      }

      let locationSpecPairs: LocationSpecPair[] = this.migrationService
            .getLocationSpecsForRecommendations(dataModel);

      // NOTE speev: The important thing is that before fetching recommendations,
      // the location specs need to have captured all the operations (i.e compute
      // resource change, storage change, network change).
      return this._storageRecommendationServiceInstance.load(locationSpecPairs);
   }

   /**
    * Server side storage recommendations fetching is needed only if StoragePod
    * is being used as destination storage.
    */
   private isFetchStorageRecommendationsNeeded(
         dataModel: MigrationWizardDataModel): boolean {

      // In xVC scenarios or if xDRS placement recommendations have been requested,
      // don't request SDRS recommendations, or cancel old ones.
      if (this.isXvcMigration()) {
         return false;
      }

      let podRegex = /StoragePod/;
      let podFound = _.some(dataModel.virtualDisksByVm, (disks: VirtualDiskRecord[]) => {
         return _.some(disks, (disk: VirtualDiskRecord) =>
               !!disk.parentStoragePod || podRegex.test(disk.storage || ""));
      });

      return podFound;
   }

   private isXvcMigration(): boolean {
      if (this.getSelectedMode() !== this.migrationTypeConstants.MIGRATE_RESOURCE_ONLY
            && this.getSelectedMode() !== this.migrationTypeConstants.MIGRATE_RESOURCE_AND_STORAGE) {
         return false;
      }

      return this.migrationHelperService.isXvcOperationByRef(
            [this.getVmId()], this.getDestinationComputeResourceRef());
   }

   public requestFolderData(): IPromise<void>{
      if (!this.isXvcMigration()) {
         this._finishPageModel.vcName = undefined;
         this._finishPageModel.folderName = undefined;
         return this.$q.when(undefined);
      }

      if (!this._finishPageModel.isComputeResourceChanged
            && !this._finishPageModel.isFolderChanged
            && this._finishPageModel.vcName
            && this._finishPageModel.folderName
      ) {
         return this.$q.when(undefined);
      }
      let folderId = this._theModel.destinationFolder;
      return this.dataService.getProperties(folderId, ["name", "vCenterName"])
            .then((result: any) => {
               this._finishPageModel.isComputeResourceChanged = false;
               this._finishPageModel.isFolderChanged = false;

               this._finishPageModel.vcName = result["vCenterName"];
               this._finishPageModel.folderName = result["name"];
               return this.$q.when(undefined);
            });
   }

   private fetchXdrsRecommendations(): IPromise<void> {
      let clusterId: string =
            this.defaultUriSchemeUtil.getVsphereObjectId(this.getDestinationClusterRef());
      let dataModel = this.buildDataModel();
      let locationSpecPairs: LocationSpecPair[] = this.migrationService
            .getLocationSpecsForRecommendations(dataModel);
      // Fetching xDRS recommendations is supported only for a single vm
      return this._xdrsRecommendationServiceInstance.load(
            clusterId, locationSpecPairs[0]);
   }

   private buildDataModel(): MigrationWizardDataModel {
      let dataModel = new h5_vm.MigrationWizardDataModel(this._theModel.vms);
      // The dataModel.pool and dataModel.computeResource properties should always
      // be set, regardless the workflow.
      dataModel.pool = this.defaultUriSchemeUtil.getVsphereObjectId(
            this.state.destinationComputeResource.targetResourcePoolRef);
      dataModel.computeResource = this.defaultUriSchemeUtil.getVsphereObjectId(
            this.state.destinationComputeResource.resourceRef);

      if (this.getSelectedMode() !== this.migrationTypeConstants.MIGRATE_STORAGE_ONLY
            && this.migrationHelperService.isXvcOperation(this.model.vms, dataModel.computeResource)) {
         dataModel.destinationFolder = this.model.destinationFolder;
      }

      let wizardMode: string = this.getSelectedMode();
      if (wizardMode === this.migrationTypeConstants.MIGRATE_RESOURCE_ONLY ||
            wizardMode === this.migrationTypeConstants.MIGRATE_RESOURCE_AND_STORAGE) {
         dataModel.networkMatches = this.getNetworkMatches();
         if (this.state.isHotMigration) {
            dataModel.priority = this._theModel.priority;
         }
      }

      if (this.getSelectedMode() === this.migrationTypeConstants.MIGRATE_RESOURCE_ONLY) {
         // Set the stretch storage if such is set
         dataModel.stretchedStorage = this._theModel.stretchedStorage;
      }

      if (wizardMode === this.migrationTypeConstants.MIGRATE_STORAGE_ONLY ||
            wizardMode === this.migrationTypeConstants.MIGRATE_RESOURCE_AND_STORAGE) {
         dataModel.virtualDisksByVm = this._theModel.virtualDisksByVm;
      }

      return dataModel;
   }

   /**
    * Checks if silent host selection is needed. Check PR 1058915.
    */
   private isHostSelectionRequired(): boolean {
      // Cold migration. No need of host auto-selection.
      if (!this.state.isHotMigration) {
         return false;
      }

      if (!this.getTargetResourcePoolRef()
            || this.getTargetResourcePoolRef().type !== this.managedEntityConstants.V_APP) {
         return false;
      }
      // Destination compute resource is not under a Cluster. No need of host auto-selection.

      // Destination compute resource is NOT a vApp. No need of host auto-selection.
      if (!this.getDestinationClusterRef()) {
         return false;
      }

      // We have a live migration to vApp, which is under a Cluster.
      return true;
   }

   /**
    * Request all valid destination hosts within a cluster or resource pool.
    *
    * @param computeResource cluster or resource pool.
    * @param locationSpecPairs -  an array of <code>LocationSpecPair</code> objects.
    **/
   private requestValidDestinationHosts(computeResource: ManagedObjectReference,
                                        locationSpecPairs: any): IPromise<ManagedObjectReference> {
      let PROPERTY_VALID_HOSTS: string = "validDestinationHosts";
      let computeResourceId = this.defaultUriSchemeUtil.getVsphereObjectId(computeResource);
      // The destination is the same for all the location spec pairs so we take the first one
      let params = {
         propertyParams: [{
            propertyName: "validDestinationHosts",
            parameterType: locationSpecPairs[0]._type,
            parameter: locationSpecPairs[0]
         }]
      };
      return this.dataService.getProperties(computeResourceId, [PROPERTY_VALID_HOSTS], params)
            .then((result: any) => {
               if (result.validDestinationHosts && result.validDestinationHosts[0]) {
                  return result.validDestinationHosts[0];
               }
               return undefined;
            });
   }

   /**
    * Returns a value indicating whether xDRS API should be used to generate DRS
    * placement recommendations.
    */
   private isXdrsRecommendationsNeeded(): boolean {
      // Currently, xDRS Placement API doesn't support getting recommendations for
      // multiple VMs at once and doesn't take already given recommendations into
      // account like SDRS, so it shouldn't be used for batch migration.
      if (this._theModel.vms.length !== 1) {
         return false;
      }

      // Also, the xDRS Placement API should be used solely for xvMotion, which requires
      // the migrated VM to be powered on (xvMotion is a type of hot migration).
      if (!this.state.isHotMigration) {
         return false;
      }

      // If host has been specified, placement recommendations should NOT be retrieved.
      if (this.getDestinationHostRef()) {
         return false;
      }

      // There is host for replacing the destination compute resource, so recommendations
      // are not needed
      if (this._finishPageModel.hostForSilentSelection) {
         return false;
      }

      // In order for one hot migration to be xvMotion, both the compute resource and
      // the storage of the VM should be changed.
      // EITHER one of the "Change compute resource, THEN storage" or "Change storage,
      // THEN compute resource" workflows should be used
      let wizardMode: string = this.getSelectedMode();
      if (wizardMode === this.migrationTypeConstants.MIGRATE_RESOURCE_AND_STORAGE) {
         return true;
      }

      // OR "Change compute resource, KEEP storage" workflow should be used, with
      // stretched storage being auto-populated.
      if (wizardMode === this.migrationTypeConstants.MIGRATE_RESOURCE_ONLY) {
         return !!this._theModel.stretchedStorage;
      }

      return false;
   }

   public getVmId(): string {
      return this.vmId;
   }

   public getVmIds(): string[] {
      return this._theModel.vms;
   }

   public getVmName(): string {
      return this.vmName;
   }

   public getVmOrigin(): {[propName: string]: any} {
      return this.vmOrigin;
   }

   public getAdvancedStoragePageData(): IPromise<{ [key: string]: { [key: string]: VmAdvancedStoragePageData } }> {
      return this.advancedStorageDataPromise;
   }

   public getVmNames(): {[propName: string]: string} {
      return this._theModel.vmNames;
   }

   public getTargetResourcePoolRef() {
      return this.state.destinationComputeResource.targetResourcePoolRef;
   }

   public getDestinationComputeResourceRef() {
      if (!this.state.destinationComputeResource) {
         return null;
      }

      return this.state.destinationComputeResource.resourceRef;
   }

   public getDestinationComputeResourceUid(): string | null {
      if (!this.state.destinationComputeResource) {
         return null;
      }
      return this.defaultUriSchemeUtil.getVsphereObjectId(this.state.destinationComputeResource.resourceRef);
   }

   /**
    * @returns A ManagedObjectReference to the destination host if user has chosen to
    * migrate to a standalone host or resource pool/vApp under a standalone host.
    */
   public getDestinationHostRef(): any {
      if (!this.state.destinationComputeResource
            || !this.state.destinationComputeResource.resourceRef) {
         return null;
      }
      let selectedResource: any = this.state.destinationComputeResource.resourceRef;
      if (selectedResource.type !== this.managedEntityConstants.HOST) {
         selectedResource = this.state.destinationComputeResource.hostRef;
      }
      return selectedResource;
   }

   /**
    * @returns A ManagedObjectReference to the destination cluster if user has chosen to
    * migrate to a cluster or resource pool/vApp under a cluster.
    */
   public getDestinationClusterRef(): any {
      if (!this.state.destinationComputeResource
            || !this.state.destinationComputeResource.resourceRef) {
         return null;
      }
      let selectedResource: any = this.state.destinationComputeResource.resourceRef;
      if (selectedResource.type !== this.managedEntityConstants.CLUSTER) {
         selectedResource = this.state.destinationComputeResource.clusterRef;
      }
      return selectedResource;
   }

   private getPreselectedHostName(clusterId: string) {
      let self = this;
      if (self.state.isHotMigration) {
         // No preselection in hot migration.
         return self.$q.when(null);
      }

      // Preselect first host
      return self.dataService.getPropertiesByRelation(
            clusterId,
            "cluster",
            self.managedEntityConstants.HOST,
            ["name"]
      ).then((result: {[prop: string]: any}) => {
         if (result && Object.keys(result).length === 1) {
            return result[Object.keys(result)[0]].name;
         }
         return null;
      });
   }

   private requestAdvancedStorageData(vmIds: string[]): IPromise<{ [key: string]: { [key: string]: VmAdvancedStoragePageData } }> {
      return this.dataService.getPropertiesForObjects(
            vmIds, ["migrateAdvancedStoragePageData"], {skipLoadingNotification: true});
   }

   private getNetworkPageViewModeInternal() {
      return this._networkPageModel.getSelectNetworkPageViewMode();
   }

   public changeComputeResource(item: any):
         angular.IPromise<MigrationWizardManager$ComputeResourceValidationData> {
      let oldUrn: string;
      if (this.state.destinationComputeResource !== null) {
         oldUrn = this.defaultUriSchemeUtil.getVsphereObjectId(
            this.state.destinationComputeResource.resourceRef);
      }

      let clusterName: string = "";
      let hostName: string = "";
      let resPoolName: string = "";

      let itemType: string = this.defaultUriSchemeUtil.getEntityType(item.id);
      if (itemType === this.managedEntityConstants.HOST) {
         clusterName = item.rawData && item.rawData.hostClusterName ? item.rawData.hostClusterName : "";
         hostName = item.name ? item.name : (item.rawData && item.rawData.name ? item.rawData.name : "") ;
      } else if (itemType === this.managedEntityConstants.CLUSTER) {
         clusterName = item.rawData ? item.rawData.name : item.name;
      } else if (itemType === this.managedEntityConstants.RESOURCE_POOL ||
         itemType === this.managedEntityConstants.V_APP) {
         resPoolName = item.rawData ? item.rawData.name : item.name;
      }

      return this.migrationService.validateComputeResource({
         vmIds: this.getVmIds(),
         computeResourceId: item.id,
         isHotMigration: this.state.isHotMigration,
         selectedMode: this.getSelectedMode(),
         isNetworkSelectionRequired: this._theModel.isNetworkSelectionRequired,
         clusterName: clusterName,
         resPoolName: resPoolName,
         hostName: hostName
      }).then((result: any) => {
         this.state.destinationComputeResource = result;

         let newUrn = this.defaultUriSchemeUtil.getVsphereObjectId(
            this.state.destinationComputeResource.resourceRef);
         this.state.isComputeResourceChanged = oldUrn && (newUrn !== oldUrn);
         return result;
      });
   }

   public clearSelectedComputeResource() {
      this.state.destinationComputeResource = {
         resourceRef: null,
         targetResourcePoolRef: null,
         hostName: null,
         clusterName: null,
         isValid: false
      };
   }

   private createNetworksMessage(): string {
      let message = "";
      let changedNetworksMap: {[key: string]: {
            sourceNetworkName: string,
            destinationNetworkName: string
         }} = {};

      _.each(this.getNetworkMatches(), (match: any) => {
         let sourceNetworkUrn = this.defaultUriSchemeUtil
             .getVsphereObjectId(match.sourceNetwork);
         let destinationNetworkUrn = this.defaultUriSchemeUtil
             .getVsphereObjectId(match.destinationNetwork);

         if (sourceNetworkUrn === destinationNetworkUrn) {
            return;
         }

         let key: string;
         if (this.getNetworkPageViewModeInternal() === this.NetworkPageViewModes.ADVANCED) {
            key = this.createUniqueAdapterKey(
                match.sourceVm, match.sourceAdapter.key);
         } else {
            key = sourceNetworkUrn;
         }

         changedNetworksMap[key] = {
            sourceNetworkName: match.sourceNetworkName,
            destinationNetworkName: match.destinationNetworkName
         };
      });

      let changedKeys = Object.keys(changedNetworksMap);

      if (changedKeys.length <= 0) {
         message = this.i18nService
             .getString("VmUi", "MigrationWizard.readyPage.noNetworkChanges");
      } else if (changedKeys.length === 1 &&
            this.getNetworkPageViewModeInternal() === this.NetworkPageViewModes.BASIC) {
         let sourceLabel = changedNetworksMap[changedKeys[0]].sourceNetworkName;
         if (!sourceLabel) {
            sourceLabel = this.i18nService.getString(
                "VmUi", "MigrationWizard.readyPage.invalidNetworkName");
         }
         let destinationLabel = changedNetworksMap[changedKeys[0]].destinationNetworkName;
         if (!destinationLabel) {
            destinationLabel = this.i18nService.getString(
                "VmUi", "MigrationWizard.readyPage.invalidNetworkName");
         }
         message = this.i18nService.getString("VmUi",
               "MigrationWizard.readyPage.singleNetworkChangeBasic",
               sourceLabel, destinationLabel);
      } else if (changedKeys.length === 1 &&
            this.getNetworkPageViewModeInternal() === this.NetworkPageViewModes.ADVANCED) {
         message = this.i18nService.getString("VmUi",
               "MigrationWizard.readyPage.networkChangesAdvancedSingle", changedKeys.length);
      } else if (this.getNetworkPageViewModeInternal() === this.NetworkPageViewModes.BASIC) {
         message = this.i18nService.getString("VmUi",
             "MigrationWizard.readyPage.networkChangesBasic", changedKeys.length);
      } else if (this.getNetworkPageViewModeInternal() === this.NetworkPageViewModes.ADVANCED) {
         message = this.i18nService.getString("VmUi",
             "MigrationWizard.readyPage.networkChangesAdvanced", changedKeys.length);
      }

      return message;
   }

   // A helper method, that runtime asserts the value supplied as parameter
   // is not undefined.
   private assertDefined<T>(value?: any): T {
      if (value === undefined) {
         throw new Error("Unexpected object: " + value);
      }
      return <T> value;
   }

   private createUniqueAdapterKey(vmMoRef: ManagedObjectReference, adapterKey: number): string {
      let vmUrn = this.defaultUriSchemeUtil.getVsphereObjectId(vmMoRef);

      return `${vmUrn}\$${adapterKey}`;
   }

   public getIsComputeResourceChanged(): boolean {
      return this.state.isComputeResourceChanged;
   }

   public getStorageRecommendationsServiceInstance(): VmStorageRecommendationsService {
      return this._storageRecommendationServiceInstance;
   }

   private findStorageNameInMap(
         storageId: string|undefined,
         storageNameMap: {[urn: string]: string}): string {
      if (!storageId || _.isEmpty(storageNameMap)) {
         return "__missing__";
      }
      return storageNameMap[storageId];
   }

   private getPmemDiskInfo(): { hasPmemDisk: boolean,
         hasRegularDisk: boolean, hasDowngradedPmemDisk: boolean } {
      let hasPmem: boolean = false, hasRegular: boolean = false;
      let hasDowngradedPmemDisk: boolean = false;
      // Loop using any in order to stop processing when we find at least 1 pmem
      // and 1 regular disk.
      _.any(this._theModel.virtualDisksByVm, (vmDiskRecords: VirtualDiskRecord[]) => {
         for (let disk of vmDiskRecords) {
            // Skip vmx record
            if (disk.key === "_configFile") {
               continue;
            }

            if (this.storageProfileService.isPmemStorageProfile(disk.profile)) {
               hasPmem = true;
            } else {
               hasRegular = true;
            }

            if (disk.origin
                  && this.pmemUtilService.isPmemDisk(disk.origin)
                  && !this.storageProfileService.isPmemStorageProfile(disk.profile)) {
               hasDowngradedPmemDisk = true;
            }
         }
         return false;
      });
      return {
         hasPmemDisk: hasPmem,
         hasRegularDisk: hasRegular,
         hasDowngradedPmemDisk: hasDowngradedPmemDisk
      };
   }

   private findFirstDiskFormat(
         virtualDisksByVm: {[key: string]: VirtualDiskRecord[]}):
            VirtualDiskFormat | undefined {
      let firstFormat: VirtualDiskFormat | undefined;
      let found = _.find(virtualDisksByVm, (disksPerVm: VirtualDiskRecord[]) => {
            let firstDisk: VirtualDiskRecord = _.find(disksPerVm,
                  (disk: VirtualDiskRecord) => !!disk.diskFormat);
            if (firstDisk) {
               firstFormat = firstDisk.diskFormat;
               return true;
            }
            return false;
         });
      return firstFormat;
   }

   public isXvMotion(): boolean {
      return this.state.isHotMigration &&
         this.getSelectedMode() === this.migrationTypeConstants.MIGRATE_RESOURCE_AND_STORAGE;
   }

} // class

   angular.module("com.vmware.vsphere.client.vm").factory("MigrationWizardManager", [
      "migrationService",
      "i18nService",
      "defaultUriSchemeUtil",
      "dataService",
      "$q",
      "managedEntityConstants",
      "datastoreService",
      "vmMigrateStorageLocatorService",
      "migrationTypeConstants",
      "datastoreRecommendationService",
      "storageProfileService",
       "NetworkPageViewModes",
      "vmMigrateStorageAdvancedSummaryBuilder",
      "VmStorageRecommendationsService",
      "vmXdrsRecommendationService",
      "storageSelectorService",
      "featureFlagsService",
      "migrationHelperService",
      "relatedItemsService",
      "pmemUtilService",
      function (
         migrationService: any,
         i18nService: any,
         defaultUriSchemeUtil: any,
         dataService: any,
         $q: IQService,
         managedEntityConstants: any,
         datastoreService: any,
         vmMigrateStorageLocatorService: any,
         migrationTypeConstants: any,
         datastoreRecommendationService: any,
         storageProfileService: any,
         NetworkPageViewModes: any,
         vmMigrateStorageAdvancedSummaryBuilder: any,
         vmStorageRecommendationsService: any,
         vmXdrsRecommendationService: any,
         storageSelectorService: any,
         featureFlagsService: any,
         migrationHelperService: any,
         relatedItemsService: any,
         pmemUtilService: any
      ) {
         return (
               wizardScope: angular.IScope, vmProperties: {[propName: string]: any}) => {
            let manager: MigrationWizardManager = new MigrationWizardManager(
               migrationService,
               i18nService,
               defaultUriSchemeUtil,
               dataService,
               $q,
               managedEntityConstants,
               datastoreService,
               vmMigrateStorageLocatorService,
               migrationTypeConstants,
               datastoreRecommendationService,
               storageProfileService,
               vmMigrateStorageAdvancedSummaryBuilder,
               wizardScope,
               NetworkPageViewModes,
               vmStorageRecommendationsService,
               vmXdrsRecommendationService,
               vmProperties,
               storageSelectorService,
               featureFlagsService,
               migrationHelperService,
               relatedItemsService,
               pmemUtilService
            );
            return manager;
         };
      }
   ]);

} // module
