/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
(function () {
   'use strict';
   angular
         .module('com.vmware.vsphere.client.dvs')
         .controller('DvPortgroupMonitoringPageController',
               DvPortgroupMonitoringPageController);

   DvPortgroupMonitoringPageController.$inject = ['$scope'];

   function DvPortgroupMonitoringPageController($scope) {


      $scope.manager.commitPageCallbacks.commitMonitoringPolicyPage = onCommit;

      function onCommit() {
         $scope.manager.pageDirtyFlags.isMonitoringPolicyPageDirty =
               hasMonitoringPolicyChanged();
         return true;
      }

      function hasMonitoringPolicyChanged () {
         return $scope.initialData.ipfixEnabled !==
               $scope.manager.dvPortgroupData.ipfixEnabled;
      }

      return this;
   }
})();
