angular.module('com.vmware.vsphere.client.vm')
   .directive('vmHardwareCpu',
      [
         'templateHelperService',
         'i18nService',
         'cpuService',
         'vmHardwareCpuService',
         'vmHardwareUtil',
         'guestOsService',
         'helpService',
         '$injector',
         function (
            templateHelperService,
            i18nService,
            cpuService,
            vmHardwareCpuService,
            vmHardwareUtil,
            guestOsService,
            helpService,
            $injector) {
            return {
               templateUrl: 'vm-ui/resources/vm/views/settings/vmHardwareSettings/vmHardwareCpu/vmHardwareCpu.html',
               scope: {
                  createMode: "=",
                  deployVmtxMode: "=",
                  vmWorkflowMode: "=",
                  originalConfig: "=",
                  vm: "<",
                  vmConfigContext: "=",
                  vbsEnabled: "=",
                  expanded: "="
               },
               link: {
                  pre: function(scope) {
                     var initailizationStepDone = false;
                     var initailizationShares;

                     init();

                     scope.$watch('vmConfigContext.config.cpuAllocation.limit', function(limit) {
                        scope.reservationMax = (limit === -1) ? vmHardwareCpuService.getMaxReservation(scope.vmConfigContext) : limit;
                        setUpReservationErrorMessages();
                        scope.reservationRecommendations = [
                           {
                              name: scope.i18n('VmUi', 'VmCpu.Reservation.Current'),
                              value: scope.originalConfig.cpuAllocation.reservation
                           },
                           {name: scope.i18n('VmUi', 'VmCpu.Reservation.Minimum'), value: 0},
                           {name: scope.i18n('VmUi', 'VmCpu.Reservation.Maximum'), value: scope.reservationMax}
                        ];
                        vmHardwareCpuService.formatRecommendations(scope.reservationRecommendations);
                     });

                     scope.$watch('vmConfigContext.config.cpuAllocation.reservation', function(reservation) {
                        scope.limitRecommendations = [
                           {
                              name: scope.i18n('VmUi', 'VmCpu.Limit.Current'),
                              value: scope.originalConfig.cpuAllocation.limit
                           },
                           {name: scope.i18n('VmUi', 'VmCpu.Limit.Minimum'), value: reservation},
                           {name: scope.i18n('VmUi', 'VmCpu.Limit.Maximum'), value: -1}
                        ];
                        vmHardwareCpuService.formatRecommendations(scope.limitRecommendations);
                     });

                     scope.$watch('vmConfigContext.config.hardware.numCPU', function(numCPU) {
                        var guestOs = guestOsService.getGuestOsDescriptor(scope.vmConfigContext, {
                           id: scope.vmConfigContext.config.guestId
                        });
                        scope.coresPerSocketOptions = vmHardwareCpuService.buildSupportedCoresPerSocket(scope.vmConfigContext, scope.originalConfig, guestOs);

                        var numCoresPerSocket = scope.vmConfigContext.config.hardware.numCoresPerSocket;
                        if (scope.coresPerSocketOptions.indexOf(numCoresPerSocket) < 0) {
                           scope.vmConfigContext.config.hardware.numCoresPerSocket =
                              scope.coresPerSocketOptions[0];
                        }

                        var numCpu = scope.vmConfigContext.config.hardware.numCPU;
                        if (numCpu) {
                           scope.sharesValueMap = {
                              low: 500 * numCpu,
                              normal: 1000 * numCpu,
                              high: 2000 * numCpu
                           };
                           var level = scope.sharesInfo.level;
                           if (level !== 'custom') {
                              if (!initailizationStepDone) {
                                 initailizationShares = scope.sharesValueMap[level];
                              }

                              initailizationStepDone = true;
                              scope.sharesInfo.shares = scope.sharesValueMap[level];
                           }
                        }
                     });

                     scope.isEditNumCpuEnabled = function() {
                        var ctx = this.vmConfigContext;
                        var guest = guestOsService.getAppropriateGuestOs(ctx, { id: ctx.config.guestId });
                        var powerState = ctx.environment.powerState;
                        var retVal = vmHardwareCpuService.isNumCpuEnabled(powerState, ctx.config, this.isFtVm, guest, ctx.privileges, scope.createMode);
                        return retVal;
                     };

                     scope.isEditCoresPerSocketEnabled = function() {
                        if (!scope.createMode) {
                           var ctx = this.vmConfigContext;
                           var powerState = ctx.environment.powerState;

                           if (this.isVmInPoweredOnState(powerState)) {
                              return false;
                           }

                           if (this.isVmInSuspendedState(powerState)) {
                              return false;
                           }
                        }

                        return this.isEditNumCpuEnabled();
                     };

                     scope.isEditReservationEnabled = function() {
                        return vmHardwareCpuService.isReservationEnabled(this.vmConfigContext, scope.createMode);
                     };

                     scope.isEditLimitEnabled = function() {
                        return vmHardwareCpuService.isLimitEnabled(this.vmConfigContext, scope.createMode);
                     };

                     scope.isEditSharesEnabled = function() {
                        return vmHardwareCpuService.isSharesEnabled(this.vmConfigContext, scope.createMode);
                     };

                     scope.isEditCpuIdEnabled = function() {
                        return vmHardwareCpuService.isCpuIdEnabled(this.vmConfigContext, scope.createMode);
                     };

                     scope.isEditNestedHVEnabled = function() {
                        return vmHardwareCpuService.isNestedHVEnabled(this.vmConfigContext, scope.createMode);
                     };
                     scope.isEditCpuMmuEnabled = function() {
                        return vmHardwareCpuService.isCpuMmuEnabled(this.vmConfigContext, scope.createMode);
                     };

                     scope.isCpuHotPlugEnabled = function() {
                        return vmHardwareCpuService.isCpuHotPlugEnabled(
                           this.vmConfigContext.environment.powerState,
                           (this.vmConfigContext.rtInfo ? this.vmConfigContext.rtInfo.faultToleranceState : null),
                           this.vmConfigContext.config.ftInfo,
                           guestOsService.getAppropriateGuestOs(this.vmConfigContext, { id: this.vmConfigContext.config.guestId }),
                           this.vmConfigContext.privileges,
                           scope.createMode);
                     };

                     scope.cpuHotPlug = function(cpuHotAddEnabled) {
                        if (angular.isDefined(cpuHotAddEnabled)) {
                           this.vmConfigContext.config.cpuHotAddEnabled = cpuHotAddEnabled;
                        }
                        return isCpuHotPlugChecked.call(this);
                     };

                     var isCpuHotPlugChecked = function() {
                        var guestOs = guestOsService.getAppropriateGuestOs(this.vmConfigContext, { id: this.vmConfigContext.config.guestId });
                        var gOsCpuHotAddEnabled = (guestOs ? guestOs.supportsCpuHotAdd : false);
                        return this.vmConfigContext.config.cpuHotAddEnabled && gOsCpuHotAddEnabled;
                     };

                      scope.hasError = function() {
                         if(!_.isEmpty(scope.vmHardwareCpuForm)){
                            return scope.vmHardwareCpuForm.$invalid;
                         }
                         return false;
                      };


                     scope.hasChanged = function() {
                        var level = scope.sharesInfo.level;

                        var isChanged = scope.vmConfigContext.config.hardware.numCPU !== scope.originalConfig.hardware.numCPU;
                        isChanged = isChanged || scope.originalConfig.cpuAllocation.reservation !== scope.vmConfigContext.config.cpuAllocation.reservation;
                        isChanged = isChanged || scope.originalConfig.cpuAllocation.limit !== scope.vmConfigContext.config.cpuAllocation.limit;
                        isChanged = isChanged || scope.vmConfigContext.config.cpuHotAddEnabled !== scope.originalConfig.cpuHotAddEnabled;
                        isChanged = isChanged || scope.vmConfigContext.config.flags.virtualExecUsage !== scope.originalConfig.flags.virtualExecUsage;
                        isChanged = isChanged || scope.vmConfigContext.config.flags.virtualMmuUsage !== scope.originalConfig.flags.virtualMmuUsage;

                        if (level !== 'custom') {
                           isChanged = isChanged ||
                              scope.sharesInfo.shares !== initailizationShares;
                        } else {
                           isChanged = isChanged ||
                              scope.sharesInfo.shares !== scope.originalConfig.cpuAllocation.shares.shares;
                        }

                        return isChanged;
                     };

                     scope.isVmInPoweredOnState = function(powerState) {
                        return vmHardwareUtil.isVmPoweredOn(powerState);
                     };

                     scope.isVmInSuspendedState = function(powerState) {
                        return vmHardwareUtil.isVmSuspended(powerState);
                     };

                     scope.isEnablePerfCountersIsDisabled = function() {
                        var hasVMConfigSettingsPrivileges =
                           _.contains(scope.vmConfigContext.privileges, 'VirtualMachine.Config.Settings');
                        return scope.vmConfigContext.environment.powerState !== 'poweredOff' ||
                              !scope.vmConfigContext.environment.configOption.capabilities.vPMCSupported ||
                              !scope.vmConfigContext.environment.hostCapability.vPMCSupported ||
                              !hasVMConfigSettingsPrivileges;
                     };

                     scope.onAdvancedCpuIdMaskSubmit = function(response) {
                        var maskFlag = response.flagState;
                        var advanced = maskFlag === "advanced" ? true : false;
                        scope.cpuIdMaskSettings = cpuService.getCpuIdMaskSettingOptions(advanced);
                        scope.cpuIdMaskSelectedSetting = cpuService.getCpuIdMaskFlagSelection(maskFlag);
                        scope.vmConfigContext.config.cpuFeatureMask = response.cpuIdMask;
                     };

                     scope.onCpuIdMaskAdvanced = function() {
                        var advancedCpuIdMaskService = $injector.get('vscAdvancedCpuIdMaskModalService');
                        advancedCpuIdMaskService.showAdvancedCpuIdMaskModal(scope.vmConfigContext, scope.onAdvancedCpuIdMaskSubmit.bind(this));
                     };

                     scope.onCpuIdMaskChange = function() {
                        if (scope.cpuIdMaskSelectedSetting === scope.cpuIdMaskSelectedSettingOld) {
                           return;
                        }
                        scope.cpuIdMaskSelectedSettingOld = scope.cpuIdMaskSelectedSetting;
                        cpuService.updateCpuIdMaskSetting(scope.cpuIdMaskSelectedSetting, scope.vmConfigContext);
                        scope.cpuIdMaskSelectedSetting = cpuService.getCpuIdMaskSettingForVm(scope.vmConfigContext);
                        //if user has changed this - advanced option cannot reappear. So remove it.
                        scope.cpuIdMaskSettings = cpuService.getCpuIdMaskSettingOptions();
                     };

                     scope.onCpuMmuChange = function(selectedOption) {
                        scope.vmConfigContext.config.flags.virtualExecUsage = selectedOption._cpuUsage;
                        scope.vmConfigContext.config.flags.virtualMmuUsage  = selectedOption._mmuUsage;
                     };

                     scope.isSchedulingAffinityShown = function() {
                        return vmHardwareCpuService.isSchedulingAffinityShown(scope.vmConfigContext);
                     };

                     scope.isHVDisabled = function () {
                        if (!scope.isEditNestedHVEnabled()) {
                           return true;
                        }

                        return scope.vbsEnabled || !scope.createMode &&
                           scope.isVmInPoweredOnState(scope.vmConfigContext.environment.powerState);
                     };

                     scope.isSchedulingAffinityEnabled = function() {
                        return vmHardwareCpuService.isSchedulingAffinityEnabled(scope.vmConfigContext, scope.createMode);
                     };
                     scope.onIOMmuChange = function (configFlags) {
                        scope.vmConfigContext.config.flags.vvtdEnabled = configFlags.vvtdEnabled;
                     };

                     scope.isMultipleCoresPerSocketSupported = function() {
                        return scope.vmConfigContext.environment.configOption
                           .capabilities.multipleCoresPerSocketSupported;
                     };

                     function setUpReservationErrorMessages() {
                        scope.reservationErrorMessages = {
                           max: function() {
                              return scope.i18n('VmUi', 'Util.Validation.ValidRange',
                                 scope.i18n('VmUi', 'VmCpu.Label.Reservation'),
                                 scope.i18n('VmUi', 'cpuReservationLimit', 0),
                                 scope.i18n('VmUi', 'cpuReservationLimit', scope.reservationMax)
                              );
                           }
                        };
                     }

                     function setUpReservationAndLimitUnitOptions() {
                        var mhzLabel = scope.i18n('VmUi', 'VmCpu.MHz');
                        var ghzLabel = scope.i18n('VmUi', 'VmCpu.GHz');
                        scope.reservationUnitOptions = [
                           {label: mhzLabel, multiplier: 1},
                           {label: ghzLabel, multiplier: 1000}
                        ];
                        scope.limitUnitOptions = [
                           {label: mhzLabel, multiplier: 1},
                           {label: ghzLabel, multiplier: 1000}
                        ];
                     }

                     function setUpSharesInfo() {
                        scope.sharesInfo = vmHardwareCpuService.setUpSharesInfo(scope.vmConfigContext, scope.createMode);
                     }

                     function setFieldValues(scope) {
                        var data = scope.vmConfigContext.config;
                        var cpuHotPlug = scope.i18n('VmUi', 'VmCpu.Label.CpuHotPlug');
                        var cpuAffinity = vmHardwareCpuService.getAffinityDisplayValue(
                              data.cpuAffinity ? data.cpuAffinity.affinitySet : []);
                        var cpuIdMask = vmHardwareCpuService.isCpuIdMaskExposed(scope.vmConfigContext) ?
                           scope.i18n('VmUi', 'CpuIdMask.exposed') :
                           scope.i18n('VmUi', 'CpuIdMask.hidden');

                        scope.fieldValue = {
                           NestedHV: vmHardwareCpuService.formatEnabledDisabled(data.hardwareVirtualization),
                           PerfCounters: vmHardwareCpuService.formatEnabledDisabled(data.performanceCounters),
                           CpuHotPlug: cpuHotPlug,
                           CpuAffinity: cpuAffinity,
                           CpuIdMask: cpuIdMask
                        };
                     }

                     function setSignPosts(scope, changeSignPost) {
                        var cpuMmuVirtTemplate = changeSignPost ?
                           'vm-ui/resources/vm/views/settings/vmHardwareSettings/vmHardwareCpu/cpuMmuVirtualization6.7HostVm13HelpText.html'
                           : 'vm-ui/resources/vm/views/settings/vmHardwareSettings/vmHardwareCpu/cpuMmuVirtualizationHelpText.html';
                        templateHelperService.renderTemplateWithScope(cpuMmuVirtTemplate, scope).then(function(content) {
                           scope.cpuMmuVirtSignPostParams = {
                              message: content,
                              title: i18nService.getString('Common', 'help'),
                              class: 'cpu-mmu-help-text'
                           };
                        });

                        var nestedHVTemplate = 'vm-ui/resources/vm/views/settings/vmHardwareSettings/vmHardwareCpu/nestedHVHelpText.html';
                        templateHelperService.renderTemplateWithScope(nestedHVTemplate, scope).then(function(content) {
                           scope.nestedHVSignPostParams = {
                              message: content,
                              title: i18nService.getString('Common', 'help'),
                              class: 'nested-hv-help-text'
                           };
                        });

                        var cpuAffinityTemplate = 'vm-ui/resources/vm/views/settings/vmHardwareSettings/vmHardwareCpu/cpuAffinityHelpText.html';
                        templateHelperService.renderTemplateWithScope(cpuAffinityTemplate,
                           vmHardwareCpuService.getCpuAffinitySignpostContext(scope.vmConfigContext))
                           .then(function(content) {
                              scope.cpuAffinitySignPostParams = {
                                 message: content,
                                 title: i18nService.getString('Common', 'help'),
                                 height: '250px'
                              };
                           });

                        function setupCpuGeneralHelpSignPost(url) {
                           var cpuGeneralHelpTemplate = 'vm-ui/resources/vm/views/settings/vmHardwareSettings/vmHardwareCpu/cpuGeneralHelpText.html';
                           templateHelperService.renderTemplateWithScope(cpuGeneralHelpTemplate, _.extend(scope, {cpuGeneralHelpUrl: url}))
                              .then(function(content) {
                                 scope.cpuGeneralHelpSignPostParams = {
                                    message: content,
                                    title: i18nService.getString('Common', 'help')
                                 };
                              });
                        }

                        var CPU_HELP_ID = 'com.vmware.vsphere.vm_admin.doc.vc_153';
                        helpService.getHelpPageUrl(CPU_HELP_ID)
                           .then(function(url) {
                              setupCpuGeneralHelpSignPost(url);
                           });
                     }
                     function init_aria() {
                        scope.RSRV_UNIT_ARIA_LBL =
                        scope.RSRV_QUANTITY_CARET_ARIA_LBL =
                        scope.RSRV_QUANTITY_ARIA_LBL = scope.fieldLabel('Reservation');

                        scope.LIMIT_UNIT_ARIA_LBL =
                        scope.LIMIT_QUANTITY_CARET_ARIA_LBL =
                        scope.LIMIT_QUANTITY_ARIA_LBL = scope.fieldLabel('Limit');

                        scope.SHARES_ARIA_LBL =
                        scope.SHARES_CARET_ARIA_LBL =
                        scope.SHARES_QNT_ARIA_LBL = scope.fieldLabel('Shares');
                        scope.IOMMU_ARIA_LBL = scope.fieldLabel('ioMmuVirtualization');
                        scope.INFO_PNG = scope.i18n('CommonImages', 'info');
                        scope.LBL_VVTD_ENABLED = scope.i18n('VmUi', 'enabled');
                     }

                     function init() {
                        scope.i18n = i18nService.getString;

                        scope.fieldLabel = function(field) {
                           return scope.i18n('VmUi', 'VmCpu.Label.' + field);
                        };
                        init_aria();
                        scope.validationError = {message: "", isVisible: true};
                        scope.isFtVm = vmHardwareUtil.isFtVmConfigContext(scope.vmConfigContext);
                        var changeSignPost = false;
                        var vmCapabilities = scope.vmConfigContext.environment.configOption.capabilities;
                        scope.showCpuMmuVirtualization = !!(vmCapabilities.virtualMmuUsageIgnored &&
                        vmCapabilities.virtualExecUsageIgnored);
                        var hostCapabilities = scope.vmConfigContext.environment.hostCapability;

                        if(!!(hostCapabilities.virtualMmuUsageIgnored &&
                           hostCapabilities.virtualExecUsageIgnored) && !scope.showCpuMmuVirtualization) {
                           changeSignPost = true;
                        }

                        scope.sharesValueMap = {
                           low: 0,
                           normal: 0,
                           high: 0
                        };

                        setUpReservationAndLimitUnitOptions();
                        setUpReservationErrorMessages();
                        setUpCpuIdMaskSettings();
                        setFieldValues(scope);
                        setSignPosts(scope, changeSignPost);
                        setUpSharesInfo();

                        var guestOs = guestOsService.getGuestOsDescriptor(scope.vmConfigContext, {
                           id: scope.vmConfigContext.config.guestId
                        });
                        scope.numCpuOptions = vmHardwareCpuService.buildSupportedCpus(scope.vmConfigContext, guestOs, scope.createMode);
                        scope.validNumCpuOptions = angular.copy(scope.numCpuOptions);
                        if (scope.numCpuOptions.indexOf(scope.vmConfigContext.config.hardware.numCPU) < 0) {
                           scope.numCpuOptions.push(scope.vmConfigContext.config.hardware.numCPU);
                        }

                        scope.coresPerSocketOptions = vmHardwareCpuService.buildSupportedCoresPerSocket(scope.vmConfigContext, scope.originalConfig, guestOs);

                        scope.fields = _.keys(scope.fieldValue);

                        scope.ioMmuVirtualizationSupported = guestOs.vvtdSupported && guestOs.vvtdSupported.supported;

                        scope.disableVvtdCheckbox = function() {
                           var vm = scope.vm;
                           if (!vm) {
                              return false;
                           }

                           if (vm.isVvtdReadonly()) {
                              return true;
                           }
                           return false;
                        };

                        scope.showVvtdInfo = function() {
                           if (!scope.vvtdSignPostParams) {
                              scope.vvtdSignPostParams = {};
                           }
                           var params = scope.vvtdSignPostParams;
                           var vm = scope.vm;

                           if (!vm) {
                              return false;
                           }

                           if (vm.isVvtdRequired()) {
                              params.title = scope.i18n('Common', 'help');
                              params.message = vm.whyVvtdRequired();
                              return true;
                           }
                           return false;
                        };
                     }

                     function setUpCpuIdMaskSettings() {
                        var maskFlag = cpuService.determineCpuIdMaskFlag(scope.vmConfigContext);
                        var advanced = maskFlag === "advanced" ? true : false;
                        scope.cpuIdMaskSettings = cpuService.getCpuIdMaskSettingOptions(advanced);
                        scope.cpuIdMaskSelectedSetting = cpuService.getCpuIdMaskFlagSelection(maskFlag);
                        //save the original selection
                        scope.cpuIdMaskSelectedSettingOld = scope.cpuIdMaskSelectedSetting;
                     }

                     function setupCpuIdMaskSettings2() {
                        scope.cpuIdMaskSelectedSetting = cpuService.getCpuIdMaskSettingForVm(scope.vmConfigContext);
                        scope.cpuIdMaskSettings = cpuService.getCpuIdMaskSettingOptions();
                     }
                  },

                  post: function(scope) {
                     function validateCpuCount() {
                        var cpuCountError = {
                           message: scope.i18n("VmUi", "VmCpu.Error.InvalidCpuCount"),
                           isVisible: false
                        };
                        var selectedCpuCount = scope.vmConfigContext.config.hardware.numCPU;

                        var setEditCpuValidity = function(valid, message) {
                           var form = scope.vmHardwareCpuForm;
                           if (form) {
                              form.$setValidity("editCPU", valid);
                           }
                           cpuCountError.isVisible = !valid;
                           if (message) {
                              cpuCountError.message = message;
                           }
                        };


                        if (scope.validNumCpuOptions && scope.validNumCpuOptions.indexOf(selectedCpuCount) >= 0) {
                           setEditCpuValidity(true);
                        } else {
                           setEditCpuValidity(false);
                        }

                        if (scope.vm) {
                           if (scope.vm.isCpuHotplugError(scope.originalConfig)) {
                              setEditCpuValidity(false, scope.i18n("VmUi", "VmCpu.Hotplug128Error"));
                           }
                           if (scope.vm.isCpuFirmwareError()) {
                              setEditCpuValidity(false, scope.i18n("VmUi", "VmCpu.CpuFirmwareError"));
                           }
                        }
                        return cpuCountError;
                     }

                     function validateAffinity() {
                        var affinityError = {
                           message: scope.i18n('VmUi', 'CpuAffinityValidator.Error.BadAffinity'),
                           isVisible: false
                        };
                        var numCpus = scope.vmConfigContext.environment.configTarget.numCpus;
                        var affinity = scope.fieldValue.CpuAffinity;
                        var validated = vmHardwareCpuService.validateAffinity(affinity, numCpus);

                        if (!validated) {
                           affinityError.isVisible = true;
                           if (scope.vmHardwareCpuForm && scope.vmHardwareCpuForm.cpuAffinity) {
                              scope.vmHardwareCpuForm.cpuAffinity.$setValidity('badAffinityPattern', false);
                           }
                        } else {
                           if (scope.vmHardwareCpuForm && scope.vmHardwareCpuForm.cpuAffinity) {
                              scope.vmHardwareCpuForm.cpuAffinity.$setValidity('badAffinityPattern', true);
                           }
                        }

                        return affinityError;
                     }

                     scope.onCpuCountChange = function() {
                        scope.cpuCountError = validateCpuCount();
                        if (scope.vm) {
                           scope.vm.afterCpuChange();
                        }
                     };

                     scope.$watch('vmConfigContext.config.firmware', function(firmware) {
                        scope.cpuCountError = validateCpuCount();
                     });


                     scope.onAffinityChange = function() {
                        var affinityError = validateAffinity();
                        scope.affinityError = affinityError;

                        var isAffinityValid = !affinityError.isVisible;
                        if (isAffinityValid) {
                           scope.vmConfigContext.config.cpuAffinity.affinitySet =
                              vmHardwareCpuService.transformAffinity(scope.fieldValue.CpuAffinity);
                        }
                     };

                     scope.isAffinityInvalid = function() {
                        if (scope.vmHardwareCpuForm && scope.vmHardwareCpuForm.cpuAffinity) {
                           return scope.vmHardwareCpuForm.cpuAffinity.$dirty &&
                              scope.vmHardwareCpuForm.cpuAffinity.$error.badAffinityPattern;
                        }
                        return false;
                     };

                     scope.cpuCountError = validateCpuCount();
                     scope.affinityError = validateAffinity();
                  }
               }
            };
         }
      ]
   );
