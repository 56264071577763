/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace h5_vm {
   import VirtualNVDIMM = com.vmware.vim.binding.vim.vm.device.VirtualNVDIMM;
   import VirtualDisk = com.vmware.vim.binding.vim.vm.device.VirtualDisk;

   export class VmHardwareSummaryPmem {
      public templateUrl: string;
      public controller: any;
      public controllerAs: string;
      public bindings: any;

      constructor() {
         this.templateUrl = "vm-ui/resources/vm/views/summary/vmHardwareSummaryPmem.html";
         this.controller = VmHardwareSummaryPmemController;
         this.bindings = {
            devices: "<",
         };
      }

   } // VmHardwareSummaryPmem

   class VmHardwareSummaryPmemController {
      public static $inject: string[] = [
         "bytesFilter",
         "i18nService",
         "pmemUtilService"
      ];

      // Input
      public devices: any;

      // Used in the view
      public label: string;
      public content: string;

      constructor(
         private bytesFilter: any,
         private i18nService: any,
         private pmemUtilService: PmemUtilService,
      ) {
      }

      public $onInit() {
         this.label = this.i18nService.getString("VmUi", "VmHardwareSummaryView.PMem");
         this.content = this.getContent();
      }

      private getContent(): string {
         return this.bytesFilter(this.getTotalMemoryInKb(), "KB");
      }

      public $onChanges(changesObj: any) {
         let devicesChange = changesObj["devices"];
         if (!devicesChange || devicesChange.isFirstChange())  {
            return;
         }

         this.content = this.getContent();
      }

      private getTotalMemoryInKb(): number {
         if (_.isEmpty(this.devices)) {
            return 0;
         }

         let capacityInKb: number = 0;
         _.each(this.devices["VirtualNVDIMM"], (device: VirtualNVDIMM) => {
            capacityInKb += (device.capacityInMB * 1024);
         });

         _.each(this.devices["VirtualDisk"], (disk: VirtualDisk) => {
            if (this.pmemUtilService.isPmemDisk(disk)) {
               capacityInKb += disk.capacityInKB;
            }
         });

         return capacityInKb;
      }

   } // VmHardwareSummaryPmemController

   angular.module("com.vmware.vsphere.client.vm").component(
         "vmHardwareSummaryPmem",
         new VmHardwareSummaryPmem());
}
