/* Copyright 2018 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace h5_vm {
   import VirtualDevice = com.vmware.vim.binding.vim.vm.device.VirtualDevice;
   import VirtualDeviceSpec = com.vmware.vim.binding.vim.vm.device.VirtualDeviceSpec;
   import VirtualDevice$DeviceBackingInfo = com.vmware.vim.binding.vim.vm.device.VirtualDevice$DeviceBackingInfo;
   import VirtualCdrom$IsoBackingInfo = com.vmware.vim.binding.vim.vm.device.VirtualCdrom$IsoBackingInfo;
   import Description = com.vmware.vim.binding.vim.Description;

   export class VmHardwareSummaryCdrom {
      public templateUrl: string;
      public controller: any;
      public controllerAs: string;
      public bindings: any;

      constructor() {
         this.templateUrl = "vm-ui/resources/vm/views/summary/vm-hardware-summary-cdrom.html";
         this.controller = VmHardwareSummaryCdromController;
         this.bindings = {
            device: '=',
            ctrl: '='
         };
      }

   } // VmHardwareSummaryCdrom

   class VmHardwareSummaryCdromController {

      public static $inject: string[] = [
         'contentLibraryService',
         'i18nService',
         'selectIsoFileService',
         'contentLibraryIsoSelectorModalService',
         'selectHostModalService',
         'selectIsoModalService',
         'defaultUriSchemeUtil',
         'dataService',
         'authorizationService',
         'mutationService',
         'configurationService'
      ];

      // Input
      public device: VirtualDevice;
      public ctrl: any;

      // Used in the view
      public vmId: any;
      public i18n: Function;
      public label: string;
      public expanded: boolean;
      public isLoading: boolean;
      public isContentLibraryIso: boolean;
      public lastAccessedISOFile: string = '';
      public hasDeviceConnectionPriv: boolean = false;
      public hasSetCdMediaPriv: boolean = false;
      public hasCLConfig: boolean = true;
      public readonly BACKING_ISO: string = 'com.vmware.vim.binding.vim.vm.device.VirtualCdrom$IsoBackingInfo';
      public readonly BACKING_ATAPI: string = 'com.vmware.vim.binding.vim.vm.device.VirtualCdrom$AtapiBackingInfo';
      public readonly BACKING_REMOTE_ATAPI: string = 'com.vmware.vim.binding.vim.vm.device.VirtualCdrom$RemoteAtapiBackingInfo';
      public readonly VM_DEVICE_CONNECTION_SPEC: string = 'com.vmware.vsphere.client.vm.VmDeviceConnectionSpec';

      constructor(private contentLibraryService: any,
                  private i18nService: any,
                  private selectIsoFileService: any,
                  private contentLibraryIsoSelectorModalService: vm_ui.ContentLibraryIsoSelectorModalService,
                  private selectHostModalService: h5_vm.SelectHostModalService,
                  private selectIsoModalService: h5_vm.SelectIsoModalService,
                  private defaultUriSchemeUtil: any,
                  private dataService: any,
                  private authorizationService: any,
                  private mutationService: any,
                  private configurationService: any) {
         this.i18n = i18nService.getString;
      }

      public $onInit(): void {
         let deviceInfo: Description = <Description> this.device.deviceInfo;
         this.label = (deviceInfo) ? deviceInfo.label : '';
         if (!this.ctrl.isVmTemplateCLitem && !this.ctrl.isVmtxTemplateCLitem) {
            this.getPermissions(['VirtualMachine.Interact.DeviceConnection', 'VirtualMachine.Interact.SetCDMedia']);
         }

         this.checkForCLConfig();

         if (this.shouldRefetchISOItemType()) {
            this.fetchISOItemType();
         }
      }

      public shouldShowDropdown(): boolean {
         return (!this.ctrl.isVmTemplateCLitem &&
            !this.ctrl.hardwareViewData.isTemplate &&
            this.ctrl.hardwareViewData.powerState === 'poweredOn') ? true : false;
      };

      public getDeviceConnected(): string {
         if (this.ctrl && this.ctrl.isVmTemplateCLitem) {
            return this.getOvfTemplateDeviceType();
         }

         const connKey = this.isDeviceConnected() ? 'connected' : 'disconnected';
         return this.i18n('VmUi', 'DeviceConnection.' + connKey);
      };

      public isDeviceConnected(): boolean {
         return this.device.connectable && this.device.connectable.connected;
      };

      public getConnectedTo(): any {

         if (this.isLoading) {
            return this.i18n('VmUi', 'DeviceConnection.loading');
         }

         switch (this.device.backing._type) {
            case this.BACKING_ISO:
               if (this.shouldRefetchISOItemType()) {
                  this.fetchISOItemType();
                  return;
               }

               return this.isContentLibraryIso
                  ? this.i18n('VmUi', 'DeviceConnection.manager.info.cl_image_file')
                  : this.i18n('VmUi', 'DeviceConnection.manager.info.host_image_file');
            case this.BACKING_ATAPI:
               this.resetLastAccessedISOBackingInfo();
               return this.i18n('VmUi', 'DeviceConnection.manager.info.host_cdrom_device');
            case this.BACKING_REMOTE_ATAPI:
               this.resetLastAccessedISOBackingInfo();
               return this.i18n('VmUi', 'DeviceConnection.manager.info.client_machine');
            default :
               this.resetLastAccessedISOBackingInfo();
               return this.i18n('VmUi', 'DeviceConnection.manager.unknown_device');
         }
      };

      public disconnectCdRom(): void {
         this.expanded = false;
         this.device.connectable.connected = false;
         this.device.connectable.startConnected = false;
         this.mutate();
      };

      public connectToHostCdDevice(): void {
         this.selectHostModalService.openHostDeviceSelector(this.ctrl.objectId, (resp: any) => {

            let backing: VirtualDevice$DeviceBackingInfo = <VirtualDevice$DeviceBackingInfo> this.device.backing;
            backing.deviceName = resp.name;
            backing._type = this.BACKING_ATAPI;
            this.device.connectable.connected = true;

            this.mutate();

         });
      };

      public browseForDatastoreIso(): void {
         this.selectIsoModalService.openIsoDeviceSelector(this.ctrl.objectId, (resp: any) => {

            let backing: VirtualCdrom$IsoBackingInfo = <VirtualCdrom$IsoBackingInfo> this.device.backing;

            backing.fileName = resp.path;
            backing._type = this.BACKING_ISO;
            this.device.connectable.connected = true;
            this.mutate();
         });
      };

      public browseForContentLibraryIso(): void {
         let onCancel = function () {};

         this.dataService.getProperties(this.ctrl.objectId, ['resourcePool']).then((response: any) => {
            const rpId = this.defaultUriSchemeUtil.getVsphereObjectId(response.resourcePool);
            this.contentLibraryIsoSelectorModalService.openContentLibraryIsoSelector(rpId, undefined, (selectedIsoItem: any) => {
               let backing: VirtualCdrom$IsoBackingInfo = <VirtualCdrom$IsoBackingInfo> this.device.backing;
               backing.fileName = selectedIsoItem.isoPath;
               backing._type = this.BACKING_ISO;
               this.device.connectable.connected = true;

               this.mutate();
            }, onCancel);

         });
      };

      private mutate(): void {
         let connection: any = {};
         let spec: VirtualDeviceSpec = new VirtualDeviceSpec();
         spec.device = this.device;
         spec.operation = 'edit';
         connection.deviceSpec = spec;
         this.mutationService.apply(this.ctrl.objectId, this.VM_DEVICE_CONNECTION_SPEC, connection);
      }

      private shouldRefetchISOItemType(): any {
         return !!this.device.backing &&
            (this.device.backing._type === this.BACKING_ISO) &&
            (this.getCurrentFileName() !== this.lastAccessedISOFile);
      }

      private resetLastAccessedISOBackingInfo(): void {
         this.lastAccessedISOFile = '';
         this.isContentLibraryIso = false;
      }

      private getCurrentFileName(): string {
         let backing: VirtualCdrom$IsoBackingInfo = <VirtualCdrom$IsoBackingInfo> this.device.backing;
         return !!this.device && !!backing ? backing.fileName : '';
      }

      private hasPermission() : boolean {
         return (this.hasDeviceConnectionPriv && this.hasSetCdMediaPriv);
      }

      private hasPermissionAndConfigForCL() : boolean {
         return (this.hasPermission() &&
            this.hasCLConfig);
      }

      private getCurrentDeviceName(): any {
         let backing: VirtualDevice$DeviceBackingInfo = <VirtualDevice$DeviceBackingInfo> this.device.backing;
         return !!this.device && !!backing ? backing.deviceName : '';
      }

      private fetchISOItemType(): any {
         if (this.isDeviceConnected()) {
            this.isLoading = true;
            this.contentLibraryService.getContentLibraryItemByDatastorePath(this.getCurrentFileName())
               .then((isoItem: any) => {
                  if (isoItem && isoItem.contentLibraryItemByDatastorePath &&
                     !this.isoItemHasErrorMessage(isoItem)) {
                     this.isContentLibraryIso = true;
                  } else {
                     this.resetLastAccessedISOBackingInfo();//reset first
                  }
                  //set the filename also for the HOST ISO
                  this.lastAccessedISOFile = this.getCurrentFileName();
               }).then(() => {
                  this.isLoading = false;
               });
         }
      }

      private isoItemHasErrorMessage(isoItem: any): any {
         return isoItem.contentLibraryItemByDatastorePath &&
            Array.isArray(isoItem.contentLibraryItemByDatastorePath.errorMessages) &&
            isoItem.contentLibraryItemByDatastorePath.errorMessages.length > 0;
      }

      public getDeviceOrFile(): any {

         if (this.isLoading) {
            return {
               title: this.i18n('VmUi', 'DeviceConnection.loading'),
               value: this.i18n('VmUi', 'DeviceConnection.loading')
            };
         }

         if (this.device.backing) {
            if (this.getCurrentDeviceName()) {
               return {
                  title: this.i18n('VmUi', 'DeviceConnection.manager.info.device'),
                  value: this.getCurrentDeviceName()
               };
            }
            if (this.getCurrentFileName()) {
               return {
                  title: this.i18n('VmUi', 'DeviceConnection.manager.info.file'),
                  value: this.getCurrentFileName()
               };
            }
         }
      };

      private getOvfTemplateDeviceType(): any {
         let info: any = <any> this.device.deviceInfo;
         const type = (info && info.type) ? info.type : '';
         const subType = (info && info.subType) ? info.subType : '';
         const typeStr = this.i18n('LibraryUi', 'vm.hardware.drive.type.' + type);
         const subTypeStr = this.i18n('LibraryUi', 'vm.hardware.drive.subType.' + subType);
         return typeStr + "/" + subTypeStr;
      };

      private getPermissions(items: any): void {
         this.authorizationService.checkPrivileges(this.ctrl.objectId, items)
            .then((privilegesStatuses: any) => {
               this.hasDeviceConnectionPriv = privilegesStatuses[0];
               this.hasSetCdMediaPriv = privilegesStatuses[1];
            });
      }


      private checkForCLConfig(): void {
         this.configurationService.getProperty('vmc.gateway.mode')
            .then((resp: any) => {
               if (resp === 'true') {
                     this.hasCLConfig = false;
               }
            });
      }

   } // VmHardwareSummaryCdromController

   angular.module("com.vmware.vsphere.client.vm").component(
      "vmHardwareSummaryCdrom",
      new VmHardwareSummaryCdrom());
}
