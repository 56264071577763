(function() {
   'use strict';
   angular.module('com.vmware.vsphere.client.vm')
         .service('guestOsCustomizationSpecValidator', guestOsCustomizationSpecValidatorService);

   guestOsCustomizationSpecValidatorService.$inject = ['$q', 'i18nService', 'mutationService'];
   function guestOsCustomizationSpecValidatorService($q, i18nService, mutationService) {

      var unknownIPv4GeneratorType = "com.vmware.vim.binding.vim.vm.customization.UnknownIpGenerator";
      var unknownIPv6GeneratorType = "com.vmware.vim.binding.vim.vm.customization.UnknownIpV6Generator";

      return {
         validate: function(vmId, vmName, specName) {
            return mutationService.validate(
                  vmId, 'java.lang.String', angular.toJson(specName)
            ).then(parseValidationResponse);

            function parseValidationResponse(validationResponse) {

               var error = validateResponse(validationResponse, specName, vmName);
               if (error) {
                  return $q.reject({
                     error: error
                  });
               }
               var result = validationResponse.result;
               if (!result) {
                  return {};
               }
               // Note: we do this to make sure there are no old specs.
               if (result.spec && result.spec.options && result.spec.options.deleteAccounts) {
                  result.spec.options.deleteAccounts = false;
               }

               var nicsToCustomize = _.map(result.spec.nicSettingMap, function(nicSettings) {
                  return {
                     ipv4: mapIPv4Input(nicSettings.adapter),
                     ipv6: mapIPv6Input(nicSettings.adapter)
                  };
               });
               return {
                  spec: result.spec,
                  info: result.info,
                  specName: result.specInfoName,

                  nicsToCustomize: nicsToCustomize,

                  needsComputerName: result.hasUnknownNameTypeGen,
                  needsAnyNICSettings: _.any(nicsToCustomize, function(nic) {
                     return nic.ipv4.required || nic.ipv6.required;
                  })
               };
            }
         }
      };

      function mapIPv4Input(adapter) {
         var ipv4Spec = adapter.ip;
         var ipv4 = {
            required: !!ipv4Spec && ipv4Spec._type === unknownIPv4GeneratorType,
            address: '',
            subnetMask: adapter.subnetMask ? adapter.subnetMask : '',
            defaultGateway: (adapter.gateway && adapter.gateway.length > 0) ? adapter.gateway[0] : '',
            alternateGateway: (adapter.gateway && adapter.gateway.length > 1) ? adapter.gateway[1] : ''
         };
         return ipv4;
      }

      function mapIPv6Input(adapter) {
         var ipv6Spec = adapter.ipV6Spec;
         var ipv6 = {
            required: !!ipv6Spec && !!ipv6Spec.ip && ipv6Spec.ip.length > 0
                        && ipv6Spec.ip[0]._type === unknownIPv6GeneratorType,
            address: '',
            subnetPrefixLength: '64',
            gateway: ipv6Spec && ipv6Spec.gateway ? ipv6Spec.gateway : []
         };
         return ipv6;
      }

      function areNicsCorrect(result) {
         return result.areTypicalNetworkingSettingsUsed || result.isNetworkCardsCountTheSame;
      }

      function validateResponse(validationResponse, specName, vmName) {
         if (validationResponse.error) {
            return validationResponse.error.localizedMessage;
         }
         var result = validationResponse.result;

         if (result && !areNicsCorrect(result)) {
            return i18nService.getString('VmUi',
                  'CustomizeGuestOsProvisioningPage.content.incorrectNicCountError',
                  specName,
                  vmName);
         }

         if (result && !result.isPublicKeyTheSame) {
            return i18nService.getString('VmUi',
                  'CustomizeGuestOsProvisioningPage.content.invalidPublicKeyError');
         }

         return "";
      }
   }
}());
