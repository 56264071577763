(function () {
   'use strict';

   angular.module('com.vmware.vsphere.client.commonModule')
      .service('advancedOptionsBuilderFactory', advancedOptionsBuilderFactory);

   advancedOptionsBuilderFactory.$inject = [];

   function advancedOptionsBuilderFactory() {

      /**
       * Gets advanced options builder object based on initial advanced options.
       *
       * @param initialOptions
       * @returns The constructed advanced options builder object
       */
      function getBuilder(initialOptions, isCluster) {
         var lastId = 0;
         initialOptions = initialOptions || [];

         var options = initialOptions.map(function(optionValue) {
            return {
               key: optionValue.key,
               value: optionValue.value,
               id: generateId()
            };
         });

         var optionsToRemove = [];
         var optionsById = [];
         options.forEach(function(option) {
            optionsById[option.id] = option;
         });

         /**
          * Returns the current advanced options.
          *
          * @returns the current advanced options
          */
         function getOptions() {
            return options;
         }

         function getOptionByKey(key) {
            return options.find(function (option) {
               return option.key === key;
            });
         }

         /**
          * Builds an array with advanced options to submit, adding the deleted
          * options and options with modified keys with null values in order to
          * be properly handled by the backend.
          *
          * @returns the advanced options to submit
          */
         function buildOptions() {
            var optionsResult = [];
            if(isCluster){
               optionsResult = angular.copy(initialOptions);
            }
            var advOptionsByKey = {};
            optionsResult.forEach(function(optionValue) {
               optionValue.value = null;
               advOptionsByKey[optionValue.key] = optionValue;
            });

            if(!isCluster) {
               _.each(optionsToRemove, function(option) {
                  optionsResult.push({
                     _type: "com.vmware.vim.binding.vim.storageDrs.OptionSpec",
                     option: {
                        _type: 'com.vmware.vim.binding.vim.option.OptionValue',
                        key: option.key,
                        value: option.value
                     },
                     operation: "remove",
                     removeKey: option.key
                  });
               });
            }

            options.forEach(function(optionValue) {
               if (!optionValue || !optionValue.key || !optionValue.value) {
                  return true;
               }
               if (advOptionsByKey[optionValue.key]) {
                  advOptionsByKey[optionValue.key].value = optionValue.value;
               } else {

                  if(isCluster){
                     optionsResult.push({
                        _type: 'com.vmware.vim.binding.vim.option.OptionValue',
                        key: optionValue.key,
                        value: optionValue.value
                     });
                  } else {
                     if(optionValue.type !== undefined) {
                        optionsResult.push({
                           _type: "com.vmware.vim.binding.vim.storageDrs.OptionSpec",
                           option: {
                              _type: 'com.vmware.vim.binding.vim.option.OptionValue',
                              key: optionValue.key,
                              value: optionValue.value
                           },
                           operation: optionValue.type
                        });
                     }
                  }

               }
            });
            return optionsResult;
         }

         /**
          * Adds a new advanced option.
          *
          * @param option to add
          */
         function addOption(option) {
            var newOptionId = generateId();
            var advancedOption = {
               key: option.key,
               value: option.value,
               id: newOptionId,
               type: "add"
            };
            optionsById[newOptionId] = advancedOption;
            options = options.concat([advancedOption]);

            return newOptionId;
         }

         /**
          * Updates advanced option by id.
          *
          * @param optionId
          * @param advancedOption option with new values
          */
         function updateOption(optionId, advancedOption) {
            var optionToUpdate = optionsById[optionId];

            if(isCluster) {
               optionToUpdate.key = advancedOption.key;
               optionToUpdate.value = advancedOption.value;
               optionToUpdate.type = "edit";
            } else {
               var optionToRemove;
               var optionExists = false;

               _.each(initialOptions, function(option,index) {
                  if(index + 1 === optionToUpdate.id && option.key === optionToUpdate.key) {
                     optionToRemove = {
                        key: option.key,
                        value: option.value,
                        type: "remove"
                     };
                     optionExists = true;
                  }
               });
               if (optionToUpdate.key === advancedOption.key && optionExists){
                  optionToUpdate.value = advancedOption.value;
                  optionToUpdate.type = "edit";
               } else {
                  if (optionExists) {
                     optionsToRemove.push(optionToRemove);
                  }

                  optionToUpdate.key = advancedOption.key;
                  optionToUpdate.value = advancedOption.value;
                  optionToUpdate.type = "add";
               }
            }
         }

         /**
          * Removes an advanced option by id.
          *
          * @param optionId
          */
         function removeOption(optionId) {
            var optionExists = false;
            if(optionsById[optionId] !== undefined ) {
               _.each(initialOptions, function(option, index) {
                  if (index + 1 === optionsById[optionId].id) {
                     optionExists = true;
                  }
               });
            }
            options = options.filter(function(option) {
               return option.id !== optionId;
            });
            if(optionExists) {
               optionsToRemove.push(optionsById[optionId]);
            }
            delete optionsById[optionId];
         }

         //generate autoincrement id-s
         function generateId() {
            return ++lastId;
         }

         return {
            getOptions: getOptions,
            getOptionByKey: getOptionByKey,
            buildOptions: buildOptions,
            addOption: addOption,
            updateOption: updateOption,
            removeOption: removeOption
         };
      }

      return {
         getBuilder: getBuilder
      };
   }
})();
