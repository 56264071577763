(function() {
   'use strict';

   /**
    * Opens Edit HA dialog as a multi page dialog.
    */
   angular.module('com.vmware.vsphere.client.cluster')
         .service('haDialogService', haDialogService);

   haDialogService.$inject = ['i18nService', 'HaDialogManager', 'clarityModalService'];

   function haDialogService(i18nService, HaDialogManager, clarityModalService) {

      return {
         show: show
      };

      function show(clusterId, focusTarget) {
         var haData = {};
         var modalOptions = {};
         var haDialogManager = new HaDialogManager(haData, modalOptions);

         _.extend(modalOptions, {
            title: i18nService.getString('ClusterUi', 'editClusterSettingsDialog.title'),
            subTitle: {
               objectId: clusterId
            },
            defaultButton: 'submit',
            contentTemplate:
                  'cluster-ui/resources/cluster/views/settings/services/ha/edit/editHaDialog.html',
            size: 'lg',
            onSubmit: haDialogManager.submit,
            modalClass: 'cluster-edit-ha-dialog-modal',
            focusTarget: focusTarget,
            dialogData: {
               haData: haData,
               manager: haDialogManager,
               objectId: clusterId
            }
         });

         clarityModalService.openOkCancelModal(modalOptions);
      }
   }
})();
