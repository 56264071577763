angular.module('com.vmware.vsphere.client.cluster').directive('thresholdBarChart', function() {
   'use strict';

   return {
      replace: true,
      restrict: 'E',
      scope: {
         /**
          * An array of items each representing usage/reservation of a resource.
          * An item has the following format:
          * {
          *    label: <usually the name of the resource which usage and reservation is displayed>
          *    usage: <number>
          *    reservation: <number>
          * }
          */
         getData: '&data',
         /**
          * An array of marker descriptors. Each representing a vertical line and label that go across the whole
          * chart.
          * A marker descriptor has the following format:
          * {
          *    value: <number representing the relative position of the line in the chart>
          *    label: <description of the marker (e.g. "0%", "50%") that is shown below the line>
          *    labelAlign: <specifies the way the text is horizontally aligned relative to the line.
          *       Supported values are: "left", "center", "right">
          * }
          */
         getMarkers: '&markers',
         /**
          * The maximum value that can be displayed by the chart component.
          */
         getMaxValue: '&maxValue',
         /**
          * Parameters for to the additional info sing post popup.
          */
         signPostParams: '='
      },
      templateUrl: 'cluster-ui/resources/cluster/components/thresholdBarChart.html',
      link: function(scope) {
         scope.data = [];
         scope.markers = [];

         scope.$watchGroup(['getData()', 'getMaxValue()'], function() {
            scope.data = normalizeData(scope.getData());
         });

         scope.$watchGroup(['getMarkers()', 'getMaxValue()'] , function() {
            scope.markers = normalizeMarkers(scope.getMarkers());
         });

         function normalizeData(data) {
            if (!data) {
               return [];
            }

            data = data.map(function(item) {
               var result = angular.merge({}, item, {
                  usage: convertValueToPercentage(item.usage),
                  reservation: convertValueToPercentage(item.reservation)
               });

               return result;
            });

            return data;
         }

         function normalizeMarkers(markers) {
            if (!markers) {
               return [];
            }

            markers = markers.map(function(item) {
               var result = angular.merge({}, item, {
                  value: convertValueToPercentage(item.value)
               });

               return result;
            });

            return markers;
         }

         function convertValueToPercentage(value) {
            if (typeof value !== 'number' || isNaN(value) || !isFinite(value) || value < 0) {
               value = 0;
            }

            var maximum = scope.getMaxValue();
            if (typeof maximum !== 'number' || isNaN(maximum) || !isFinite(maximum) || maximum < 0) {
               maximum = 0;
            }

            if (maximum < value) {
               value = maximum;
            }

            if (maximum !== 0) {
               value = value / maximum;
            } else {
               value = 0;
            }

            return value * 100;
         }
      }
   };
});
