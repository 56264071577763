namespace network_ui {

   export class ReadyToCompleteMigarteVmkPageComponent {

      public bindings: any;
      public templateUrl: string;
      public controller: any;

      constructor() {
         this.bindings = {
            model: "<"
         };
         this.controller = ReadyToCompleteMigarteVmkPageController;
         this.templateUrl = "network-ui/resources/network/views/host/migratevmkadapter/" +
               "components/readyToCompletePageTemplate.html";
      }
   }

   class ReadyToCompleteMigarteVmkPageController {

      public static $inject = [
         "i18nService",
         "vxPropertyViewService",
         "networkUtil"
      ];

      public model: MigrateVmkToVssWizardModel;

      public summaryData: any;

      constructor(private i18nService: any,
                  private propertyViewService: any,
                  private networkUtil: any) {
      }

      $onInit(): void {
         this.buildProperties();
      }

      private buildProperties(): void {
         let builder: any = this.propertyViewService.createPropertyViewBuilder();
         let generalCategory: any = builder.category("general");

         let generalSection: any = generalCategory.section("generalSection");
         generalSection.property(
               this.i18nService.getString(
                     "NetworkUi", "VssVirtualNicMigrateWizard.summary.nic.vmkernel"),
               this.model.selectedVmkAdapter);

         let currentSettingsSection: any = generalCategory.section("currentSettings");
         currentSettingsSection
               .title(this.i18nService.getString(
                     "NetworkUi", "VssVirtualNicMigrateWizard.summary.currentDetails"));
         currentSettingsSection.property(
               this.i18nService.getString(
                     "NetworkUi", "VssVirtualNicMigrateWizard.summary.currentSwitch"),
               this.model.sourceSwitchName);
         currentSettingsSection.property(
               this.i18nService.getString(
                     "NetworkUi", "VssVirtualNicMigrateWizard.summary.currentPortGroup"),
               this.model.sourceNetworkName);

         let targetSettingsSection: any = generalCategory.section("targetSettings");
         targetSettingsSection
               .title(this.i18nService.getString(
                     "NetworkUi", "VssVirtualNicMigrateWizard.summary.newDetails"));
         targetSettingsSection.property(
               this.i18nService.getString(
                     "NetworkUi", "VssVirtualNicMigrateWizard.summary.newSwitch"),
               this.model.targetStandardSwitch);
         targetSettingsSection.property(
               this.i18nService.getString(
                     "NetworkUi", "VssVirtualNicMigrateWizard.summary.newPortGroup"),
               this.model.newNetworkName);
         targetSettingsSection.property(
               this.i18nService.getString(
                     "NetworkUi", "VssVirtualNicMigrateWizard.summary.newVlan"),
               this.networkUtil.formatVlanId(this.model.vlanId));

         this.summaryData = builder.build();
      }
   }

   angular.module("com.vmware.vsphere.client.network")
         .component("readyToCompleteMigarteVmkPage",
               new ReadyToCompleteMigarteVmkPageComponent());
}
