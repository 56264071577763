namespace h5_vm {

   import VmConfigContext = com.vmware.vsphere.client.vm.config.VmConfigContext;
   import SriovInfo = com.vmware.vim.binding.vim.vm.SriovInfo;
   import PciPassthroughInfo = com.vmware.vim.binding.vim.vm.PciPassthroughInfo;
   import VirtualPCIPassthrough$DeviceBackingInfo = com.vmware.vim.binding.vim.vm.device.VirtualPCIPassthrough$DeviceBackingInfo;
   import VirtualEthernetCard = com.vmware.vim.binding.vim.vm.device.VirtualEthernetCard;
   import VirtualSriovEthernetCard = com.vmware.vim.binding.vim.vm.device.VirtualSriovEthernetCard;
   import VirtualSriovEthernetCard$SriovBackingInfo = com.vmware.vim.binding.vim.vm.device.VirtualSriovEthernetCard$SriovBackingInfo;
   import SriovNetworkDevicePoolInfo = com.vmware.vim.binding.vim.vm.SriovNetworkDevicePoolInfo;
   import DistributedVirtualPortgroupInfo = com.vmware.vim.binding.vim.dvs.DistributedVirtualPortgroupInfo;
   import NetworkInfo = com.vmware.vim.binding.vim.vm.NetworkInfo;
   import PciDevice = com.vmware.vim.binding.vim.host.PciDevice;
   import TargetInfo = com.vmware.vim.binding.vim.vm.TargetInfo;

   export class VmHwSriovService {

      public switchToSriovAdapterType(device: VirtualSriovEthernetCard, vm: VmConfigContext, network: TargetInfo|DistributedVirtualPortgroupInfo): SriovInfo[] {

         const list: SriovInfo[] = this.getSriovList(vm, network);

         if (!list || list.length === 0) {
            (<any> device).sriovBacking = null;
            return [<SriovInfo>{pnic: "Unavailable"}];
         }

         const sriovInfo: SriovInfo = list[0];

         this.assignSriovBacking(device, sriovInfo);
         device.allowGuestOSMtuChange = false;
         return list;
      }

      /* Populates VirtualSriovEthernetCard.sriovBacking based SriovInfo */
      public assignSriovBacking(device: VirtualEthernetCard, sriovInfo: SriovInfo): void {

         if (!sriovInfo) {
            delete (<VirtualSriovEthernetCard> device).sriovBacking;
            return;
         }

         if (sriovInfo.pciDevice && sriovInfo.pciDevice.deviceName === "auto") {
            (<any> device).sriovBacking = null;
            return;
         }

         (<VirtualSriovEthernetCard> device).sriovBacking = this.makeSriovBacking(sriovInfo);
      }

      public isAllowMtuChangeEditable(vm:VmConfigContext, workflow:VmWorkflowMode): boolean {
         if (VmWorkflowMode.CreateMode === workflow) {
            return true;
         }

         const permissions: boolean = h5_vm.VmHardwareUtil.userHasModifyDevicePermission(vm);

         if (VmWorkflowMode.UpdateMode === workflow) {
            if (h5_vm.VmHardwareUtil.isSuspended(vm)) {
               return false; // not editable
            }
            if (h5_vm.VmHardwareUtil.isPoweredOn(vm)) {
               return false; // not editable
            }

            return permissions;

         } else if (VmWorkflowMode.CloneMode === workflow) {
            return permissions;
         }

         return true;

      }


      private makeSriovBacking(sriovInfo: SriovInfo): VirtualSriovEthernetCard$SriovBackingInfo {
         let pciBacking: VirtualPCIPassthrough$DeviceBackingInfo = h5_vm.PciDeviceService.info2backing(sriovInfo);
         let sriovBacking: VirtualSriovEthernetCard$SriovBackingInfo = new VirtualSriovEthernetCard$SriovBackingInfo();
         sriovBacking.physicalFunctionBacking = pciBacking;
         return sriovBacking;
      }

      public getSriovList(vm: VmConfigContext, networkInfo: any): SriovInfo[] {
         const sriov: SriovInfo[] = vm.environment.configTarget.sriov;

         let retVal = this.getSriovPF(sriov);

         if (this.sriovPoolConfigured(networkInfo, sriov)) {
            retVal.unshift(this.makeAutoimatic());
         }

         return retVal;
      }

      private makeAutoimatic(): SriovInfo {
         let auto: SriovInfo = new SriovInfo();
         auto.pnic = "Automatic"; //TODO i18n
         auto.pciDevice = new PciDevice();
         auto.pciDevice.deviceName = "auto";
         (<any>auto).label = "Automatic";
         return auto;
      }

      private sriovPoolConfigured(networkInfo: NetworkInfo | DistributedVirtualPortgroupInfo, array: SriovInfo[]): boolean {
         if (!array || !networkInfo) {
            return false;
         }

         const VMODL_VM_STD_INFO: string = new NetworkInfo()._type;
         const VMODL_VM_DPORT_INFO: string = new DistributedVirtualPortgroupInfo()._type;

         const nwType: string = networkInfo._type;

         const pools: SriovNetworkDevicePoolInfo[] = this.getSriovNetworkDevicePools(array);

         if (nwType === VMODL_VM_STD_INFO) {
            return this.IsStdSwtichOnPool(<NetworkInfo> networkInfo, pools);
         } else if (nwType === VMODL_VM_DPORT_INFO) {
            return this.isDvsSriovPool(<DistributedVirtualPortgroupInfo> networkInfo, pools);
         }

         return false; // e.g. NSX
      }

      private getSriovNetworkDevicePools(array: SriovInfo[]): SriovNetworkDevicePoolInfo[] {
         if (!array) {
            return [];
         }

         let retVal: SriovNetworkDevicePoolInfo[] = [];

         // SriovInfo could be associated to multiple devices (device pool)
         // DevicePool comes in two flavours - plain pool and network-related pool
         // We are interested in network-related pools to check if
         // currently see if selected network has pool association to allow automatic device from that pool.

         const NETWORK_POOL = new SriovNetworkDevicePoolInfo()._type;

         for (const item of array) {
            if (item.virtualFunction || !item.devicePool) {
               continue;
            }
            const type: string = item.devicePool._type;

            if (type !== NETWORK_POOL) {
               continue;
            }

            retVal.push(<SriovNetworkDevicePoolInfo>item.devicePool);
         }

         return retVal;
      }

      private IsStdSwtichOnPool(info: NetworkInfo, array: SriovNetworkDevicePoolInfo[]): boolean {

         if (!array || !info) {
            return false;
         }

         const switchKey: string = info.vswitch;
         for (const item of array) {
            if (!item.switchKey) {
               continue;
            }
            if (switchKey === item.switchKey) {
               return true;
            }
         }
         return false;

      }

      private isDvsSriovPool(info: DistributedVirtualPortgroupInfo, array: SriovNetworkDevicePoolInfo[]): boolean {

         if (!array || !info) {
            return false;
         }

         const switchUuid: string = info.switchUuid;

         for (const item of array) {
            if (switchUuid === item.switchUuid) {
               return true;
            }
         }
         return false;
      }

      private getSriovPF(input: SriovInfo[]): SriovInfo[] {

         if (!input) {
            return [];
         }

         const output: SriovInfo[] = [];


         for (const item of input) {

            if (item && (false === item.virtualFunction)) {
               output.push(item);
               const label = item.pnic + " " + this.info2text(item);
               (<any>item).label = label;
            }
         }
         return output;
      }


      public getSriovDeviceText(device: VirtualSriovEthernetCard, vm: VmConfigContext): string {
         if (!device) {
            return "";
         }

         const backing: VirtualSriovEthernetCard$SriovBackingInfo = device.sriovBacking;

         if (!backing) {
            return "";
         }

         const pfBacking: VirtualPCIPassthrough$DeviceBackingInfo = backing.physicalFunctionBacking;

         if (!pfBacking) {
            return "";
         }

         const sriov: SriovInfo[] = vm.environment.configTarget.sriov;

         const retVal: string = this.getPfBackingText(pfBacking, sriov);
         return retVal;
      }

      /** Finds the device's matching SriovInfo */
      public getSriovInfo(device: VirtualSriovEthernetCard, sriov: SriovInfo[]): SriovInfo| null {
         if (!device) {
            return null;
         }

         const backing: VirtualSriovEthernetCard$SriovBackingInfo = device.sriovBacking;

         if (!backing) {
            // if the sriov backing is not set, and there is automatic sriovInfo select that
            let autoSriov = _.find(sriov, (sriovInfo) => {
               return sriovInfo.pnic === "Automatic";
            });
            return autoSriov ? autoSriov : null;
         }

         const pfBacking: VirtualPCIPassthrough$DeviceBackingInfo = backing.physicalFunctionBacking;

         if (!pfBacking) {
            return null;
         }

         const info: SriovInfo | null = this.findSriovInfo(pfBacking, sriov);
         return info;
      }

      /** Finds matching PCI device in boxed array and formats display label, resets label to empty string if no match */
      private getPfBackingText(backing: VirtualPCIPassthrough$DeviceBackingInfo, input: SriovInfo[]): string {

         if (!backing) {
            return "";
         }

         const info: SriovInfo | null = this.findSriovInfo(backing, input);

         if (!info) {
            return backing.id; // 'bus:slot.function
         }

         let retVal: string = this.info2text(info);

         return retVal;
      }

      private findSriovInfo(pci: VirtualPCIPassthrough$DeviceBackingInfo, array: SriovInfo[]): SriovInfo | null {
         if (!pci || !pci.id || !array) {
            return null;
         }

         var pciDevice_id: String = pci.id;

         for (const info of array) {
            if (info && info.pciDevice.id === pciDevice_id) {
               return info;
            }
         }
         return null;
      }

      private info2text(item: PciPassthroughInfo): string {
         var info: PciPassthroughInfo = item as PciPassthroughInfo;

         if (!info) {
            return "";
         }

         const id: string = info.pciDevice.id;
         const vendor: string = info.pciDevice.vendorName;
         const name: string = info.pciDevice.deviceName;
         const retVal: string = id + " | " + name + " " + vendor;
         return retVal;
      }
   }
}

angular.module("com.vmware.vsphere.client.vm").service("VmHardwareNetworkSriovService", h5_vm.VmHwSriovService);

