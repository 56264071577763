/* Copyright 2018 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace h5_vm {
   import VirtualDevice = com.vmware.vim.binding.vim.vm.device.VirtualDevice;
   import VirtualDevice$DeviceBackingInfo = com.vmware.vim.binding.vim.vm.device.VirtualDevice$DeviceBackingInfo;
   import VirtualCdrom$IsoBackingInfo = com.vmware.vim.binding.vim.vm.device.VirtualCdrom$IsoBackingInfo;
   import Description = com.vmware.vim.binding.vim.Description;


   export class VmHardwareSummaryFloppy {
      public templateUrl: string;
      public controller: any;
      public controllerAs: string;
      public bindings: any;

      constructor() {
         this.templateUrl = "vm-ui/resources/vm/views/summary/vm-hardware-summary-floppy.html";
         this.controller = VmHardwareSummaryFloppyController;
         this.bindings = {
            device: '=',
            ctrl: '='
         };
      }

   } // VmHardwareSummaryFloppy

   class VmHardwareSummaryFloppyController {

      public static $inject: string[] = [
         'contentLibraryService',
         'i18nService',
         'selectIsoFileService',
         'contentLibraryIsoSelectorModalService',
         'selectHostModalService',
         'defaultUriSchemeUtil',
         'dataService',
         'authorizationService',
         'mutationService'
      ];

      // Input
      public device: VirtualDevice;
      public ctrl: any;
      public i18n: Function;

      // Used in the view
      public label: string;
      public isLoading: boolean;

      public readonly BACKING_FLOPPY_HOST_IMAGE: string = 'com.vmware.vim.binding.vim.vm.device.VirtualFloppy$ImageBackingInfo';
      public readonly BACKING_FLOPPY_HOST_FLOPPY_DEVICE: string = 'com.vmware.vim.binding.vim.vm.device.VirtualCdrom$DeviceBackingInfo';
      public readonly BACKING_FLOPPY_REMOTE_CLIENT_DEVICE: string = 'com.vmware.vim.binding.vim.vm.device.VirtualCdrom$RemoteDeviceBackingInfo';

      constructor(private contentLibraryService: any,
                  private i18nService: any,
                  private defaultUriSchemeUtil: any,
                  private dataService: any,
                  private authorizationService: any,
                  private mutationService: any) {
         this.i18n = i18nService.getString;
      }

      public $onInit(): void {
         let deviceInfo: Description = <Description> this.device.deviceInfo;
         this.label = (this.device || deviceInfo) ? deviceInfo.label : '';
      }

      public getDeviceConnected(): string {
         let connKey = this.isDeviceConnected() ? 'connected' : 'disconnected';
         return this.i18n('VmUi', 'DeviceConnection.' + connKey);
      };

      public isDeviceConnected(): boolean {
         return this.device.connectable && this.device.connectable.connected;
      };

      public getConnectedTo(): any {
         if (this.ctrl && this.ctrl.isVmTemplateCLitem) {
            let deviceInfo: any = <any> this.device.deviceInfo;
            return this.i18n('LibraryUi', 'vm.hardware.floppy.type.' + deviceInfo.type);
         }

         if (this.isLoading) {
            return this.i18n('VmUi', 'DeviceConnection.loading');
         }

         switch (this.device.backing._type) {
            case this.BACKING_FLOPPY_HOST_IMAGE:
               return this.i18n('VmUi', 'DeviceConnection.manager.info.host_image_file');
            case this.BACKING_FLOPPY_HOST_FLOPPY_DEVICE:
               return this.i18n('VmUi', 'DeviceConnection.manager.info.host_floppy_device');
            case this.BACKING_FLOPPY_REMOTE_CLIENT_DEVICE:
               return this.i18n('VmUi', 'DeviceConnection.manager.info.remote_client_device');
            default :
               return this.i18n('VmUi', 'DeviceConnection.manager.unknown_device');
         }
      };

      private getCurrentFileName(): string {
         let backing: VirtualCdrom$IsoBackingInfo = <VirtualCdrom$IsoBackingInfo> this.device.backing;
         return !!this.device && !!backing ? backing.fileName : '';
      }

      private getCurrentDeviceName(): any {
         let backing: VirtualDevice$DeviceBackingInfo = <VirtualDevice$DeviceBackingInfo> this.device.backing;
         return !!this.device && !!backing ? backing.deviceName : '';
      }

      public getDeviceOrFile(): any {
         if (this.ctrl && this.ctrl.isVmTemplateCLitem) {
            let deviceInfo: any = <any> this.device.deviceInfo;
            return this.i18n('LibraryUi', 'vm.hardware.floppy.type.' + deviceInfo.type);
         }

         if (this.isLoading) {
            return this.i18n('VmUi', 'DeviceConnection.loading');
         }

         if (this.device.backing) {
            if (this.getCurrentDeviceName()) {
               return {
                  title: this.i18n('VmUi', 'DeviceConnection.manager.info.device'),
                  value: this.getCurrentDeviceName()
               };
            }
            if (this.getCurrentFileName()) {
               return {
                  title: this.i18n('VmUi', 'DeviceConnection.manager.info.file'),
                  value: this.getCurrentFileName()
               };
            }
         }
      };

   } // VmHardwareSummaryFloppyController

   angular.module("com.vmware.vsphere.client.vm").component(
      "vmHardwareSummaryFloppy",
      new VmHardwareSummaryFloppy());
}
