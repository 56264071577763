/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace dvs_ui {

   import ValidationCheckResult = com.vmware.vsphere.client.dvs.api.featureupgrade.ValidationCheckResult;
   import VmwareDistributedVirtualSwitch$LacpGroupConfig = com.vmware.vim.binding.vim.dvs.VmwareDistributedVirtualSwitch$LacpGroupConfig;

   export class DvsUpgradeLacpWizardModel {
      dvsId: string | null;
      dvsName: string;
      prerequisiteChecks: ValidationCheckResult[];
      prerequisiteWarningShown: boolean = false;
      hostCount: number;
      portgroupCount: number;
      lag: VmwareDistributedVirtualSwitch$LacpGroupConfig;
   }
}
