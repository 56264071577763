module cluster_ui {

   import IRootScopeService = angular.IRootScopeService;

   export interface VuiClusterAddVmOverridesWizard {
      show(pages: Array<any>, wizardScope: any, manager: AddClusterVmOverridesManager, focusTarget: any): void;
      markPageIncompleteWithError(message: string): void;
      markPageComplete(flow?: any): void;
   }

   class VuiClusterAddVmOverridesWizardImpl implements VuiClusterAddVmOverridesWizard {

      static $inject = [
         'wizardPageService',
         'vuiWizardService',
         '$rootScope',
         'i18nService'
      ];

      private wizardConfig: any;
      private wizardScope: any;

      constructor(private wizardPageService: any,
                  private vuiWizardService: any,
                  private $rootScope: any,
                  private i18nService: any) {
      }

      public show(pages: Array<any>, wizardScope: any, manager: AddClusterVmOverridesManager, focusTarget: any): void {
         this.wizardConfig = {
            title: this.i18nService.getString("ClusterUi", "vm.overrides.config.add.dialog.title", manager.getClusterName()),
            pages: this.wizardPageService.initializePageStates(pages),
            cssClass: "add-vm-overrides-wizard",
            resizeMinHeight: 500,
            onFinish: (): boolean => {
               return true;
            }
         };

         this.wizardScope = wizardScope;
         this.wizardScope.manager = manager;
         this.wizardScope.wizardConfig = this.wizardConfig;
         wizardScope.modalOptions = {
            focusTarget: focusTarget
         };

         this.vuiWizardService({
            scope: this.wizardScope,
            configObjectName: "wizardConfig"
         }).show();
      }

      public markPageIncompleteWithError(message: string): void {
         this.wizardPageService.markPageIncompleteWithError(this.wizardConfig, message);
      }

      public markPageComplete(flow?: any): void {
         this.wizardPageService.clearErrors(this.wizardConfig);
         this.wizardPageService.markPageComplete(this.wizardConfig, flow);
      }

   }

   angular.module("com.vmware.vsphere.client.cluster")
         .service("vuiClusterAddVmOverridesWizard", VuiClusterAddVmOverridesWizardImpl);
}

