/* Copyright 2015 VMware, Inc. All rights reserved. -- VMware Confidential */
(function() {
   'use strict';
   angular.module('com.vmware.vsphere.client.host').controller('HostSettingsDataDrivenController', HostSettingsDataDrivenController);

   HostSettingsDataDrivenController.$inject = ['$scope', 'HostDataDrivenSettingsConfigFactory'];

   function HostSettingsDataDrivenController($scope, HostDataDrivenSettingsConfigFactory) {
      var self = this;

      var extensionId = $scope._route.extensionId;
      var headerOptions = HostDataDrivenSettingsConfigFactory[extensionId].headerOptions;
      var settingsViewOptions = HostDataDrivenSettingsConfigFactory[extensionId].settingsViewOptions;

      var objectId = $scope._route.objectId;
      headerOptions.objectIds = [objectId];
      settingsViewOptions.objectId = objectId;
      settingsViewOptions.setSelectedAdvancedOption = true;
      if(!angular.isDefined(settingsViewOptions.type)) {
         // Default view is stack view.
         settingsViewOptions.type = 'stack';
      }
      self.headerOptions = headerOptions;
      self.settingsViewOptions = settingsViewOptions;
   }
})();
