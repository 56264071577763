namespace h5_vm {

import ManagedObjectReference = com.vmware.vim.binding.vmodl.ManagedObjectReference;
import RelocateSpec$DiskLocator = com.vmware.vim.binding.vim.vm.RelocateSpec$DiskLocator;
import DefinedProfileSpec = com.vmware.vim.binding.vim.vm.DefinedProfileSpec;

   class CheckResult {
   isValid: boolean;
   issues: Array<any>;
}

class SelectionValidationResult {
   compatibilityCheck: CheckResult;
   vmPlacementCheck: CheckResult;
   costCheck: CheckResult;
}

class StorageLocatorItem {
   // StorageLocatorStoragePodItem or StorageLocatorDatastoreItem
   storageRef: ManagedObjectReference;
   hasAllocateSpacePrivilege?: boolean;
}

export class StorageResourceSelectionValidationService {

   public static $inject = [
      "compatibilityCheckService",
      "i18nService",
      "creationTypeConstants",
      "defaultUriSchemeUtil",
      "dataService",
      "managedEntityConstants",
      "vmProvisioningStorageSelectionValidationService",
      "$q",
      "compatibilityResultsPresenter",
      "featureFlagsService"
   ];

   constructor (
      private compatibilityCheckService: any,
      private i18nService: any,
      private creationTypeConstants: any,
      private defaultUriSchemeUtil: any,
      private dataService: any,
      private managedEntityConstants: any,
      private vmProvisioningStorageSelectionValidationService: any,
      private $q: any,
      private compatibilityResultsPresenter: CompatibilityResultsPresenter,
      private featureFlagsService: any) {
   }

   public validateSelection(
         selectedStorage: StorageLocatorItem,
         selectedProfileId: string,
         virtualMachineSpecBuilderProperties: any,
         treatProfileWarningsAsErrors?: boolean) {

      if (selectedStorage.storageRef.type === this.managedEntityConstants.DATASTORE &&
            !selectedStorage.hasAllocateSpacePrivilege) {
         return this.$q.when(this.getNoPrivilegesCompatibilityResult());
      }

      const selectedStorageResourceUrn = this.defaultUriSchemeUtil.getVsphereObjectId(selectedStorage.storageRef);

      let checkCompatibility = this.getCheckCompatibilityPromise(
            selectedStorageResourceUrn, virtualMachineSpecBuilderProperties);
      let checkVmPlacement =
            this.checkVmPlacement(selectedProfileId, virtualMachineSpecBuilderProperties,
                  treatProfileWarningsAsErrors);
      let costCheck = this.checkCosts(virtualMachineSpecBuilderProperties);
      return this.$q.all({
         "compatibilityCheck": checkCompatibility,
         "vmPlacementCheck": checkVmPlacement,
         "costCheck": costCheck
      }).then((validationResult: SelectionValidationResult) => {
         return this.onValidationCompleted(validationResult, virtualMachineSpecBuilderProperties);
      });
   }

   private getNoPrivilegesCompatibilityResult() {
      let advancedCompatibilityContents = this.createAdvancedCompatibilityContentsObj(
            [this.i18nService.getString("VmUi",
                  "SelectDatastoreProvisioningPage.NoPermissionOnDatastore")],
            [], [], this.compatibilityResultsPresenter.NO_HOST_ID);
      let compIssue = {
         type: "error",
         icon: "vsphere-icon-status-error",
         message: this.i18nService.getString("VmUi",
               "SelectDatastoreProvisioningPage.NoPermissionOnDatastore"),
         contents: [],
         advancedCompatibilityContents: advancedCompatibilityContents
      };
      return {
         isValid: false,
         messages: [compIssue]
      };
   }

   private getCheckCompatibilityPromise(selectedStorageResourceUrn: any,
         vmSpecBuilderProperties: any): IPromise<CheckResult> {

      let creationType = vmSpecBuilderProperties.creationType;
      if (this.creationTypeConstants.isCloning(creationType) ||
            (this.creationTypeConstants.isCloningVmToLibrary(creationType)
                  && this.featureFlagsService.CL_Native_VMTX_Phase2Enabled())) {
         return this.checkCompatibilityOnClone(
               selectedStorageResourceUrn, vmSpecBuilderProperties);
      }

      let compCheckResult = new CheckResult();
      compCheckResult.isValid = true;
      return this.$q.when(compCheckResult);
   }

   private checkCompatibilityOnClone(selectedStorageId: any,
         virtualMachineSpecBuilderProperties: any): IPromise<CheckResult> {
      return this.compatibilityCheckService.validate(
         virtualMachineSpecBuilderProperties.computeResourceId,
         virtualMachineSpecBuilderProperties.targetFolderUid,
         virtualMachineSpecBuilderProperties.creationType,
         virtualMachineSpecBuilderProperties.name,
         virtualMachineSpecBuilderProperties.vmId,
         selectedStorageId,
         virtualMachineSpecBuilderProperties.resPoolId,
         virtualMachineSpecBuilderProperties.disks
      ).then((response: any) => {
         let cloneCompCheckResult = new CheckResult();

         let allCompResults = response.result;

         if (!this.hasCompatibilityErrors(allCompResults)) {
            cloneCompCheckResult.isValid = true;
            return cloneCompCheckResult;
         }

         let advancedCompatibilityContents = this.createAdvancedDataFromCompatibilityCheck(
               response, virtualMachineSpecBuilderProperties);

         let hasSuccess = false;
         let compIssues = _.filter(allCompResults, (result: any) => {
            return result.messages !== null && result.messages.length > 0;
         });

         // if number of issues is different than total count of compatibility results
         // OR if there is at least one warning => the overall compatibility check is valid
         hasSuccess = compIssues.length !== allCompResults.length;

         compIssues = _.map(compIssues, (result: any) => {
            let hasErrors = false;
            let compIssue = {
               hostId: this.defaultUriSchemeUtil.getVsphereObjectId(result.host),
               type: "", // will be set later
               message: result.hostName,
               icon: null,
               contents: _.map(result.messages, (msg: any) => {
                  hasErrors = hasErrors || msg.type === "error";
                  return {
                     type: msg.type,
                     icon: msg.type === "error"
                        ? "vsphere-icon-status-error"
                        : "vsphere-icon-status-warning",
                     message: msg.message,
                     contents: []
                  };
               })
            };
            if (hasErrors) {
               compIssue.type = "error";
            } else {
               hasSuccess = true;
               compIssue.type = "warning";
            }
            return compIssue;
         });

         cloneCompCheckResult.isValid = hasSuccess;
         cloneCompCheckResult.issues = compIssues;

         if (compIssues.length > 0) {
            compIssues[0].advancedCompatibilityContents = advancedCompatibilityContents;
         }

         // If there is one host with one message, its icon will be displayed
         if (compIssues.length === 1 && compIssues[0].contents.length === 1) {
            return this.dataService
                  .getProperties(compIssues[0].hostId, ["primaryIconId"])
                  .then((properties: any) => {
                     compIssues[0].icon = properties.primaryIconId;
                     return cloneCompCheckResult;
                  });
         }

         if (cloneCompCheckResult.isValid) {
            compIssues.push({
               type: "success"
            });
         }

         return cloneCompCheckResult;
      });
   }

   private createAdvancedDataFromCompatibilityCheck(response: any,
          virtualMachineSpecBuilderProperties: any): AdvancedCompatibilityContents {
      if (response.error) {
         let res: AdvancedCompatibilityContents =
               this.createAdvancedCompatibilityContentsObj([response.error.message],
                     [], [], this.compatibilityResultsPresenter.NO_HOST_ID);
         res.vmProps.name = virtualMachineSpecBuilderProperties.name;
         return res;
      }

      let advancedCompatibilityContents: AdvancedCompatibilityContents = {
         hostMap: {},
         vmId: this.compatibilityResultsPresenter.NO_VM_ID,
         vmProps: {
            icon: this.compatibilityResultsPresenter.NO_VM_ICON,
            name: virtualMachineSpecBuilderProperties.name
         }
      };
      _.each(response.result, (singleHostResult: any) => {
         let hostId: string = this.defaultUriSchemeUtil.getVsphereObjectId(
               singleHostResult.host);

         if (!advancedCompatibilityContents.hostMap[hostId]) {
            advancedCompatibilityContents.hostMap[hostId] = {
               errors: [],
               warnings: [],
               infos: [],
               props: {
                  icon: this.compatibilityResultsPresenter.NO_HOST_ICON,
                  name: this.compatibilityResultsPresenter.NO_HOST_NAME
               }
            };
         }

         _.each(singleHostResult.messages, (msg: any) => {
            if(msg.type === "error") {
               advancedCompatibilityContents.hostMap[hostId].errors.push(msg.message);
            } else {
               advancedCompatibilityContents.hostMap[hostId].warnings.push(msg.message);
            }
         });
      });

      return advancedCompatibilityContents;
   }

   private checkVmPlacement(selectedProfileId: string, virtualMachineSpecBuilderProperties: any,
          treatProfileWarningsAsErrors?: boolean): IPromise<CheckResult> {
      let placementCheck = new CheckResult();

      if(!selectedProfileId) {
         placementCheck.isValid = true;
         return this.$q.when(placementCheck);
      }

      const vmHomeStorageId = this.defaultUriSchemeUtil.getVsphereObjectId(
            virtualMachineSpecBuilderProperties.vmStorageConf.vmHome.storageObj.storageRef);
      const vmHomeProfileId = virtualMachineSpecBuilderProperties.vmStorageConf.vmHome.storageProfile.id;
      const assignmentsWithProfiles = [{
         storageId: vmHomeStorageId,
         profile: {id: vmHomeProfileId}
      }];
      _.each(virtualMachineSpecBuilderProperties.disks, (disk: RelocateSpec$DiskLocator)=>{
         let storageId = this.defaultUriSchemeUtil.getVsphereObjectId(disk.datastore);
         _.each(disk.profile, (profile: DefinedProfileSpec) => {
            assignmentsWithProfiles.push({
               storageId: storageId,
               profile: {id: profile.profileId}
            });
         });
      });
      return this.compatibilityCheckService
            .validateVmPlacementForMultipleVms(assignmentsWithProfiles)
            .then((result: any) => {

               // both errors and warnings are displayed as a warning
               let errors: any[] = _.map(result.errors, (error: any) => {
                  return {
                     type: treatProfileWarningsAsErrors ? "error" : "warning",
                     icon: treatProfileWarningsAsErrors ?
                           "vsphere-icon-status-error" : "vsphere-icon-status-warning",
                     message: error,
                     contents: []
                  };
               });

               // In case the server returns a generic message (e.g. VSAN policy message
               // on VC 6.0), we return only that message. In case it returns a more
               // specific message, we return the generic message and the specific
               // message on the next line.
               let spbmPolicyWarning = this.i18nService.getString(
                     "VmUi", "SelectDatastoreProvisioningPage.SpbmPolicyWarning");
               let warnings: any[] = [];
               _.each(result.warnings, (warning: any) => {
                  let warningMessage = warning.localizedMessage;
                  if (!warningMessage && !_.isEmpty(warning.faultMessage)) {
                     warningMessage = warning.faultMessage[0].message;
                  }

                  let warningContent =
                        !warningMessage || warningMessage === spbmPolicyWarning ?
                              [] :
                              [{
                                 message: "\"" + warningMessage + "\"",
                                 contents: []
                              }];
                  let containsWarning = _.any(warnings, (warn: any) => {
                     return angular.equals(warn.contents, warningContent);
                  });
                  if (containsWarning) {
                     return;
                  }
                  warnings.push({
                     type: treatProfileWarningsAsErrors ? "error" : "warning",
                     icon: treatProfileWarningsAsErrors ?
                           "vsphere-icon-status-error" : "vsphere-icon-status-warning",
                     message: spbmPolicyWarning,
                     contents: warningContent
                  });
               });

               let advancedCompatibilityContents =
                     this.createAdvancedDataFromPlacementCheck(result);

               let compIssues = errors.concat(warnings);
               if (compIssues.length > 0) {
                  compIssues[0].advancedCompatibilityContents = advancedCompatibilityContents;
               }

               placementCheck.isValid = treatProfileWarningsAsErrors && compIssues.length > 0 ? false : true;
               placementCheck.issues = compIssues;
               return placementCheck;
            });
   }

   private createAdvancedDataFromPlacementCheck(response: any): AdvancedCompatibilityContents {
      let spbmPolicyWarning = this.i18nService.getString(
            "VmUi", "SelectDatastoreProvisioningPage.SpbmPolicyWarning");

      let warningMessages: string[] = [];
      _.each(response.warnings, (warning: any) => {
         let warningMessage = warning.localizedMessage;
         if (warningMessage === null && !_.isEmpty(warning.faultMessage)) {
            warningMessage = warning.faultMessage[0].message;
         }

         if (warningMessage) {
            warningMessages.push(warningMessage);
         } else if (warningMessages.indexOf(spbmPolicyWarning) === -1) {
            warningMessages.push(spbmPolicyWarning);
         }
      });

      return this.createAdvancedCompatibilityContentsObj(
            response.errors, warningMessages, [],
            this.compatibilityResultsPresenter.NO_HOST_ID);
   }

   private hasCompatibilityErrors(compatibilityResults: any): boolean {
      return _.any(compatibilityResults, (result: any) => {
         return _.any(result.messages, (message: any) => {
               return message.type === "error";
            }
         );
      });
   }

   private checkCosts(virtualMachineSpecBuilderProperties: any): IPromise<CheckResult> {
      return this.vmProvisioningStorageSelectionValidationService
            .requestProvisioningCost(
                  virtualMachineSpecBuilderProperties.targetFolderUid,
                  virtualMachineSpecBuilderProperties.vmStorageConf,
                  false,
                  virtualMachineSpecBuilderProperties.vmDisks)
            .then((costResults: any) => {
               let costCheckResult = new CheckResult();
               costCheckResult.isValid = true;

               if(_.isEmpty(costResults.compatibilityData.infos)) {
                  return costCheckResult;
               }

               let advancedCompatibilityContents = this.createAdvancedCompatibilityContentsObj(
                     [], [], costResults.compatibilityData.infos,
                     this.compatibilityResultsPresenter.NO_HOST_ID);

               let compIssue = {
                  icon: "vsphere-icon-help-info-hover",
                  message: costResults.compatibilityData.infos[0],
                  contents: [],
                  advancedCompatibilityContents: advancedCompatibilityContents
               };

               costCheckResult.issues = [compIssue];
               return costCheckResult;
            });
   }

   private createAdvancedCompatibilityContentsObj(errors: Array<any>, warnings: Array<any>,
         infos: Array<any>, hostId: string): AdvancedCompatibilityContents {
      let advancedCompatibilityContents: AdvancedCompatibilityContents = {
         hostMap: {},
         vmId: this.compatibilityResultsPresenter.NO_VM_ID,
         vmProps: {
            name: this.compatibilityResultsPresenter.NO_VM_NAME,
            icon: this.compatibilityResultsPresenter.NO_VM_ICON
         }
      };

      advancedCompatibilityContents.hostMap[hostId] = {
         errors: errors,
         warnings: warnings,
         infos: infos,
         props: {
            icon: this.compatibilityResultsPresenter.NO_HOST_ICON,
            name: this.compatibilityResultsPresenter.NO_HOST_NAME
         }
      };

      return advancedCompatibilityContents;
   }

   private onValidationCompleted(result: SelectionValidationResult,
         virtualMachineSpecBuilderProperties: any): any {
      //  - Having a validation errors in one of the checks does not necessarily mean that
      // the overall validation fails, so we are checking both isValid property of
      // particular validation and its issues count.
      // - At the same time the flow here stops collecting validation issues after the
      // first failed check.
      // - If there are already some issues collected (added to allIssues array) from
      // a check that does not fail the overall validation, these issues are removed
      // and replaced by the issues of check that fails the validation
      // The overall idea is to show only issues of the check that fails the validation
      // or show all the issues collected from different checks that does not fail the
      // overall validation
      let allIssues: Array<any> = [];
      let overallValidationSuccseeded = true;

      if(result.compatibilityCheck && !_.isEmpty(result.compatibilityCheck.issues)) {
         overallValidationSuccseeded = result.compatibilityCheck.isValid;
         allIssues = overallValidationSuccseeded
               ? allIssues.concat(result.compatibilityCheck.issues)
               : result.compatibilityCheck.issues;
      }
      if (overallValidationSuccseeded &&
            result.vmPlacementCheck && !_.isEmpty(result.vmPlacementCheck.issues)) {
         overallValidationSuccseeded = result.vmPlacementCheck.isValid;
         allIssues = overallValidationSuccseeded
               ? allIssues.concat(result.vmPlacementCheck.issues)
               : result.vmPlacementCheck.issues;
      }
      if (overallValidationSuccseeded &&
            result.costCheck && !_.isEmpty(result.costCheck.issues)) {
         overallValidationSuccseeded = result.costCheck.isValid;
         allIssues = overallValidationSuccseeded
               ? allIssues.concat(result.costCheck.issues)
               : result.costCheck.issues;
      }

      if (allIssues.length > 0) {
         return this.createValidationErrorResultObject(
               overallValidationSuccseeded, allIssues, virtualMachineSpecBuilderProperties);
      }

      return {
         isValid: true,
         messages: [{
            type: "success",
            icon: "vsphere-icon-status-ok",
            message: this.i18nService.getString(
                  "VmUi", "ProvisioningWizard.CompatibilityChecksSucceeded"),
            contents: [],
            advancedCompatibilityContents: {}
         }]
      };
   }

   // overall validation result is no more driven by this function, rather it is function
   // of each particular type of validation, e.g. having an error for some particular
   // validation does not mean the overall validation is failed, e.g. in clone operations
   // if there is at least one successful result the validation passes
   private createValidationErrorResultObject(overallValidationSuccseeded: boolean,
         errorsArray: Array<any>, virtualMachineSpecBuilderProperties: any): any {
      let filteredMessages = _.filter(errorsArray, (error: any) => {
         return error.type !== "success";
      });

      if (!filteredMessages[0] || !filteredMessages[0].advancedCompatibilityContents) {
         return {
            isValid: overallValidationSuccseeded,
            messages: filteredMessages
         };
      }

      let advancedCompatibilityContents = filteredMessages[0].advancedCompatibilityContents;

      if (advancedCompatibilityContents && virtualMachineSpecBuilderProperties
            && virtualMachineSpecBuilderProperties.name) {
         advancedCompatibilityContents.vmProps.name = virtualMachineSpecBuilderProperties.name;
      }
      if (advancedCompatibilityContents && virtualMachineSpecBuilderProperties
            && virtualMachineSpecBuilderProperties.vmId) {
         advancedCompatibilityContents.vmId = virtualMachineSpecBuilderProperties.vmId;
      }

      let affectedHosts: Array<string> = [];
      _.each(advancedCompatibilityContents.hostMap, (hostInfo: any, hostId: string) => {
         if (hostId !== this.compatibilityResultsPresenter.NO_HOST_ID) {
            affectedHosts.push(hostId);
         }
      });

      if (affectedHosts.length === 0) {
         return {
            isValid: overallValidationSuccseeded,
            messages: filteredMessages,
         };
      }

      return this.dataService
            .getPropertiesForObjects(affectedHosts, ["primaryIconId", "name"])
            .then((properties: any) => {
               _.each(properties, (props: any, hostId: string) => {
                  advancedCompatibilityContents.hostMap[hostId].props.icon =
                        props.primaryIconId;
                  advancedCompatibilityContents.hostMap[hostId].props.name =
                        props.name;
               });

         return {
            isValid: overallValidationSuccseeded,
            messages: filteredMessages
         };
      });
   }
} // class

angular.module("com.vmware.vsphere.client.vm").service(
      "storageResourceSelectionValidationService",
      StorageResourceSelectionValidationService);

}
