(function () {
   'use strict';
   angular.module('com.vmware.vsphere.client.commonModule').controller(
         'TriggeredAlarmsViewController', TriggeredAlarmsViewController);
   TriggeredAlarmsViewController.$inject = [
      '$q',
      '$scope',
      '$rootScope',
      'i18nService',
      'alarmService',
      'defaultUriSchemeUtil',
      'objectTypesService',
      'columnRenderersRegistry',
      'vuiConstants',
      'alarmIssueActionService',
      'datagridActionBarService',
      'timeFormatterService',
      'configurationService',
      'vuiUtils',
      'triggeredAlarmsRefreshService'
   ];

   function TriggeredAlarmsViewController($q, $scope, $rootScope, i18nService, alarmService, defaultUriSchemeUtil,
         objectTypesService, columnRenderersRegistry, vuiConstants, alarmIssueActionService,
         datagridActionBarService, timeFormatterService, configurationService, vuiUtils,
         triggeredAlarmsRefreshService) {
      var linkRenderer = columnRenderersRegistry.getColumnRenderer('link');

      var self = this;
      var datagridActionBarServiceCacheObject = null;

      var acknowledgeActionSpec = {
         actionId: 'vsphere.opsmgmt.alarms.acknowledge',
         noLabel: true,
         getActionInvocationContext: getActionInvocationContext,
         isActionAvailable: function(actionDef) {
            var selectedItems = self.datagridOptions.selectedItems;
            if (selectedItems.length === 0) {
               return false;
            }
            if (selectedItems[0].details.alarmState.acknowledged) {
               return false;
            }
            return actionDef.available;
         }
      };
      self.savedDateFormat = null;

      var resetActionSpec = {
         actionId: 'vsphere.opsmgmt.alarms.reset',
         noLabel: true,
         getActionInvocationContext: getActionInvocationContext,
         isActionAvailable: function(actionDef) {
            return actionDef.available && self.datagridOptions.selectedItems.length > 0;
         }
      };

      var actionSpecs = [acknowledgeActionSpec, resetActionSpec];

      self.objectId = $scope._route.objectId;
      self.watchForObjects = [ self.objectId ];
      self.alarms = [];

      // flag indicating if a request for alarms is currently in progress
      self.pendingRequest = false;

      self.datagridOptions = {
         actionBarOptions: {
            actions: []
         },
         resizable: true,
         searchable: false,
         selectionMode: vuiConstants.grid.selectionMode.SINGLE,
         height: '99%',
         selectedItems: [],
         columnDefs: [
            {
               displayName: i18nService.getString('Common', 'alarmsView.tableHeaderText.object'),
               field: 'entityName',
               template: function (dataItem) {
                  return linkRenderer(defaultUriSchemeUtil.getVsphereObjectId(dataItem.entity), dataItem.entityName);
               }
            },
            {
               displayName: i18nService.getString('Common', 'alarmsView.tableHeaderText.severity'),
               field: 'details.alarmState.overallStatus',
               template: function (dataItem) {
                  var text = alarmService.getSeverity(dataItem.details.alarmState.overallStatus);
                  var icon = alarmService.getStatusIconClass(dataItem.details.alarmState.overallStatus);
                  return linkRenderer(null, text, icon);
               }
            },
            {
               displayName: i18nService.getString('Common', 'alarmsView.tableHeaderText.name'),
               field: 'details.alarmInfo.name',
               template: function (dataItem) {
                  var definedInRef = defaultUriSchemeUtil.getVsphereObjectId(dataItem.details.alarmInfo.entity);

                  var extensionName = dataItem.definedInEntityNamespace + '.manage.alarmDefinitions';

                  return linkRenderer(definedInRef, dataItem.details.alarmInfo.name, null, false, {extensionName: extensionName});
               }
            },
            {
               displayName: i18nService.getString('Common', 'alarmsView.tableHeaderText.triggered'),
               field: 'details.alarmState.time',
               template: function (dataItem) {
                  return timeFormatterService.timestampToText(parseInt(dataItem.details.alarmState.time), self.savedDateFormat);
               }
            },
            {
               displayName: i18nService.getString('Common', 'alarmsView.tableHeaderText.acknowledgedTime'),
               field: 'details.alarmState.acknowledgedTime',
               template: function (dataItem) {
                  return dataItem.details.alarmState.acknowledgedTime ?
                        timeFormatterService.timestampToText(parseInt(dataItem.details.alarmState.acknowledgedTime), self.savedDateFormat) : '';
               }
            },
            {
               displayName: i18nService.getString('Common', 'alarmsView.tableHeaderText.acknowledgedByUser'),
               field: 'details.alarmState.acknowledgedByUser',
               template: function (dataItem) {
                  return (dataItem.details.alarmState.acknowledgedByUser) ?
                        dataItem.details.alarmState.acknowledgedByUser :'';
               }
            },
            {
               displayName: i18nService.getString('Common', 'alarmsView.tableHeaderText.definedIn'),
               field: 'details.definedInEntityName',
               template: function (dataItem) {
                  var link, text, iconClass;
                  if (dataItem.details.alarmInfo.entity.value === 'group-d1' ||
                        dataItem.details.alarmInfo.entity._value === 'group-d1') {
                     iconClass = 'vsphere-icon-vcenter';
                  } else {
                     iconClass = dataItem.definedInEntityIcon;
                  }

                  var definedInRef = defaultUriSchemeUtil.getVsphereObjectId(dataItem.details.alarmInfo.entity);
                  if (definedInRef === self.objectId) {
                     text = i18nService.getString('AlarmsUi', "definedIn.this.object");
                     link = null;
                  } else {
                     text = dataItem.details.definedInEntityName;
                     link = definedInRef;
                  }

                  return linkRenderer(link, text, iconClass);
               }
            }
         ],
         data: []
      };

      self.showActionsMenu = function(event) {
         var alarm = event.data[0];
         if (alarm && alarm.details) {
            alarmIssueActionService.showActionsMenu(
                event,
                $scope,
                alarm.details.alarmInfo,
                alarm.details.alarmState,
                event.clientX,
                event.clientY,
                event.currentTarget);
         }
      };


      createActionBar();

      $scope.$watch('ctrl.datagridOptions.selectedItems', function() {
         refreshActionBar();
      });

      $scope.$on('IssueChangeEvent', self.getAlarms);

      self.getAlarms = function() {
         if (self.pendingRequest === true) {
            return;
         }
         self.pendingRequest = true;
         alarmService.getAlarms(self.objectId).then(function(result) {
            var promises = _.map(result, function (triggeredAlarm) {
               var definedInType = triggeredAlarm.details.alarmInfo.entity.type;
               return objectTypesService.getObjectTypeSpec(definedInType).then(function (typeInfo) {
                  triggeredAlarm.definedInEntityIcon = typeInfo.icon;
                  triggeredAlarm.definedInEntityNamespace = typeInfo.namespace;
                  return triggeredAlarm;
               });
            });

            return $q.all(promises);
         }).then(function(triggeredAlarms) {
            self.alarms = triggeredAlarms;
            self.datagridOptions.data = triggeredAlarms;
         })['finally'](function() {
            self.pendingRequest = false;

            if (!self.cancelLiveRefreshFn) {
               subscribeToLiveRefresh()
                     .then(function (cancelLiveRefreshFn) {
                        self.cancelLiveRefreshFn = cancelLiveRefreshFn;
                        $scope.$on('$destroy', function() {
                           self.cancelLiveRefreshFn();
                        });
                     });
            }
         });
      };

      refresh(self.getAlarms);

      function subscribeToLiveRefresh() {
         var LIVE_ALARM_UPDATES_FLAG = 'live.updates.alarms.enabled';

         return configurationService.getProperty(LIVE_ALARM_UPDATES_FLAG)
            .then(function(isAlarmRefreshEnabled) {
               // Subscribe for alarm updates after initial alarm loading.
               if (isAlarmRefreshEnabled !== 'false') {
                  return $rootScope.$on('alarms', onAlarmsUpdates);
               }
            });
      }

      function onAlarmsUpdates(event, partialUpdates) {
         var promises = [];

         _.each(partialUpdates.updates, function(partialUpdate) {
            promises.push(
               triggeredAlarmsRefreshService.handleAlarmUpdates(partialUpdate, self.objectId, self.alarms)
            );
         });

         $q.all(promises).then(function() {
            vuiUtils.safeApply($scope, function () {
               self.datagridOptions.data = _.sortBy(self.alarms, function (alarm) {
                  return alarm.details.alarmState.time;
               }).reverse();
            });
         });
      }

      function refresh(cb) {
         return timeFormatterService.getUserTimeFormatPreference().then(function(savedFormatId) {
            self.savedDateFormat = savedFormatId;
            if (cb) {
               cb(savedFormatId);
            }
         });
      }

      function createActionBar() {
         datagridActionBarService
               .updateActionBar(self.datagridOptions, [self.objectId], actionSpecs)
               .then(function(value) {
                  datagridActionBarServiceCacheObject = value;
               });
      }

      function refreshActionBar() {
         if (!datagridActionBarServiceCacheObject) {
            return;
         }

         datagridActionBarService.updateActionBar(
            self.datagridOptions, [self.objectId], actionSpecs, datagridActionBarServiceCacheObject
         );
      }

      function getActionInvocationContext() {
         var selectedItems = self.datagridOptions.selectedItems;
         var alarmInfo = selectedItems[0].details.alarmInfo;
         return {
            entityId: defaultUriSchemeUtil.getVsphereObjectId(selectedItems[0].details.alarmState.entity),
            alarm: alarmInfo.alarm
         };
      }
   }
})();
