(function() {
   'use strict';
   angular.module('com.vmware.vsphere.client.host').controller('SystemResourceReservationsController', SystemResourceReservationsController);

   SystemResourceReservationsController.$inject = ['$scope', 'dataService', 'i18nService'];

   function SystemResourceReservationsController($scope, dataService, i18nService) {
      var self = this;
      var objectId = $scope._route.objectId;
      var headerOptions = {
         title: i18nService.getString('HostUi', 'systemResource.reservation'),
         objectId: objectId
      };

      self.headerOptions = headerOptions;
      self.cpuAllocation = null;
      self.memoryAllocation = null;

      self.fetchViewData = function (){

         dataService.getProperties($scope._route.objectId, ['systemResourceReservations'])
               .then(function (result) {
                     self.cpuAllocation = result.systemResourceReservations.cpuAllocation;
                     self.memoryAllocation = result.systemResourceReservations.memoryAllocation;
               });
      };

      self.fetchViewData();
   }
})();