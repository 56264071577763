/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace h5_vm {

import DatastoreMirrorsInfo = com.vmware.vsphere.client.vm.api.DatastoreMirrorsInfo;

export class SelectComputeResourcePageModel {
   /**
    * [input]
    *
    * The VMs being migrated.
    */
   public vms: Array<string> = [];

   /**
    * [input]
    *
    * The compute resource id to preselect when the page is loaded for first time.
    */
   public initialComputeResource?: string;

   /**
    * [output]
    *
    * In case of compute resource migration using streched storage (migration
    * between compute resources from different datacenters, but accessing the same
    * underlying storage) this value would be populated with per disk configuration for
    * each VM that is coming from a different datacenter than the target compute resource
    * datacenter.
    */
   public stretchedStorage?:  { [vmId: string]: Array<VirtualDiskRecord> };

   /**
    * Service taking care of stretched storages when migrating compute resource only.
    */
   public stretchedStorageService: VmMigrateStretchedStorageService;

   /**
    * [output]
    *
    * The uid of the compute resource object selected from the page -
    * not added to wizard the page model, for now user only by the
    * migrateVmWizardService for showing/hiding select folder page
    */
   public computeResource?: string;

   /**
    * [output]
    *
    * Id of the datacenter of the selected compute resource
    */
   public datacenter: string;


   /**
    * [page specific]
    * Keeps the last compatibility result displayed on the page
    */
   public compatibilityResultsCache: any;

   /**
    * [page specific]
    * Keeps the info about if the page is validating at the moment or no.
    * Used to determine if the spinner in the compatibility result is displayed or no
    * Used to determine if the next button of the wizard is displayed or no
    */
   public isValidating: any;
} // class
} // module
