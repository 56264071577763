/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
(function () {
   'use strict';

   /**
    * Factory that handles distributed port groups edit related operations.
    * Builds all the edit distributed port groups dialog page models and submits
    * the edited distributed port group data to the server.
    */
   angular
         .module('com.vmware.vsphere.client.dvs')
         .factory('EditDvPortgroupDialogManager', DvPortgroupDialogManager);

   DvPortgroupDialogManager.$inject = [
         'i18nService',
         'mutationService',
         'vuiConstants',
         'networkUiConstants',
         'dvpgSpecFactory'];

   function DvPortgroupDialogManager(
         i18nService,
         mutationService,
         vuiConstants,
         networkUiConstants,
         dvpgSpecFactory) {

      return function (dvPortgroupData, dialogOptions) {
         var EDIT_DV_PORT_GROUP_SPEC =
               'com.vmware.vim.binding.vim.dvs.DistributedVirtualPortgroup$ConfigSpec';

         var generalPageDef;
         var vlanPageDef;
         var securityPolicyPageDef;
         var failoverPolicyPageDef;
         var trafficShapingPageDef;
         var monitoringPolicyPageDef;
         var miscellaneousPolicyPageDef;
         var advancedPageDef;

         /**
          * Contains onCommit page callbacks.
          */
         var commitPageCallbacks = {
            commitGeneralPropertiesPage: function () { return true; },
            commitVlanPage: function () { return true; },
            commitSecurityPolicyPage: function() { return true; },
            commitFailoverPolicyPage: function() { return true; },
            commitTrafficShapingPage: function() { return true; },
            commitMonitoringPolicyPage: function() { return true; },
            commitMiscellaneousPolicyPage: function() { return true; },
            commitAdvancedPage: function() { return true; }
         };

         /**
          * Page dirty flags.
          */
         var pageDirtyFlags = {
            isGeneralPropertiesPageDirty: false,
            isVlanPageDirty: false,
            isFailoverPolicyPageDirty: false,
            isSecurityPolicyPageDirty: false,
            isTrafficShapingPageDirty: false,
            isMonitoringPolicyPageDirty: false,
            isMiscellaneousPolicyPageDirty: false,
            isAdvancedPageDirty: false
         };

         createPages();

         return {
            dvPortgroupData: dvPortgroupData,
            commitPageCallbacks: commitPageCallbacks,
            pageDirtyFlags: pageDirtyFlags,
            getPageDefinitions: getPageDefinitions,
            showDialogErrors: showDialogErrors,
            submit: submit
         };

         function createPages() {
            generalPageDef = {
               page: {
                  title: i18nService.getString(
                        'NetworkUi', 'dvpg.properties.general'),
                  contentUrl: 'dvs-ui/resources/dvs/dvpg/' +
                        'edit/pages/dvPortgroupGeneralPropertiesPage.html',
                  onCommit: function () {
                     return execute(commitPageCallbacks.commitGeneralPropertiesPage);
                  }
               }
            };

            vlanPageDef = {
               page: {
                  title: i18nService.getString(
                        'NetworkUi', 'dvpg.policy.vlan.title'),
                  contentUrl: 'dvs-ui/resources/dvs/dvpg/' +
                        'edit/pages/dvPortgroupVlanPage.html',
                  onCommit: function () {
                     return execute(commitPageCallbacks.commitVlanPage);
                  }
               }
            };

            securityPolicyPageDef = {
               page: {
                  title: i18nService.getString(
                        'NetworkUi', 'dvpg.policy.security.title'),
                  contentUrl: 'dvs-ui/resources/dvs/dvpg/' +
                        'edit/pages/dvPortgroupSecurityPolicyPage.html',
                  onCommit: function () {
                     return execute(commitPageCallbacks.commitSecurityPolicyPage);
                  }
               }
            };

            failoverPolicyPageDef = {
               page: {
                  title: i18nService.getString(
                        'NetworkUi', 'dvpg.policy.failover.title'),
                  contentUrl: 'dvs-ui/resources/dvs/dvpg/' +
                        'edit/pages/dvPortgroupFailoverPolicyPage.html',
                  onCommit: function () {
                     return execute(commitPageCallbacks.commitFailoverPolicyPage);
                  }
               }
            };

            trafficShapingPageDef = {
               page: {
                  title: i18nService.getString(
                        'NetworkUi', 'dvpg.policy.shaping.title'),
                  contentUrl: 'dvs-ui/resources/dvs/dvpg/' +
                        'edit/pages/dvPortgroupTrafficShapingPage.html',
                  onCommit: function () {
                     return execute(commitPageCallbacks.commitTrafficShapingPage);
                  }
               }
            };

            monitoringPolicyPageDef = {
               page: {
                  title: i18nService.getString(
                        'NetworkUi', 'dvpg.policy.monitoring.title'),
                  contentUrl: 'dvs-ui/resources/dvs/dvpg/' +
                        'edit/pages/dvPortgroupMonitoringPage.html',
                  onCommit: function () {
                     return execute(commitPageCallbacks.commitMonitoringPolicyPage);
                  }
               }
            };

            miscellaneousPolicyPageDef = {
               page: {
                  title: i18nService.getString(
                        'NetworkUi', 'dvpg.policy.misc.title'),
                  contentUrl: 'dvs-ui/resources/dvs/dvpg/' +
                        'edit/pages/dvPortgroupMiscellaneousPolicyPage.html',
                  onCommit: function () {
                     return execute(commitPageCallbacks.commitMiscellaneousPolicyPage);
                  }
               }
            };

            advancedPageDef = {
               page: {
                  title: i18nService.getString(
                        'NetworkUi', 'dvpg.properties.advanced'),
                  contentUrl: 'dvs-ui/resources/dvs/dvpg/' +
                  'edit/pages/dvPortgroupAdvancedPage.html',
                  onCommit: function () {
                     return execute(commitPageCallbacks.commitAdvancedPage);
                  }
               }
            };
         }

         function getPageDefinitions() {
            var pages = [];

            pages.push(generalPageDef.page);
            pages.push(advancedPageDef.page);
            pages.push(vlanPageDef.page);

            if (!dvPortgroupData.isUplink) {
               pages.push(securityPolicyPageDef.page);
               pages.push(failoverPolicyPageDef.page);
               pages.push(trafficShapingPageDef.page);
            }

            if (dvPortgroupData.ipfixSupported) {
               pages.push(monitoringPolicyPageDef.page);
            }

            pages.push(miscellaneousPolicyPageDef.page);

            return pages;
         }

         /**
          * Shows provided error messages in dialog overlay banner.
          */
         function showDialogErrors(errors /* String[] */) {
            if (!errors || errors.length < 1) {
               return;
            }

            clearValidationMessages();
            dialogOptions.validationBanner.messages = _.map(
                  errors,
                  function(message) {
                     return {
                        type: vuiConstants.validationBanner.type.ERROR,
                        text: message
                     };
                  });
         }

         function clearValidationMessages() {
            dialogOptions.validationBanner.messages = [];
         }

         function submit() {
            if (!hasChanged()) {
               return true;
            }

            var editDvPortgroupSpec = {
               _type: EDIT_DV_PORT_GROUP_SPEC,
               configVersion: dvPortgroupData.configVersion
            };

            if (pageDirtyFlags.isGeneralPropertiesPageDirty) {
               editDvPortgroupSpec.name = dvPortgroupData.name;
               editDvPortgroupSpec.description = dvPortgroupData.description;
               editDvPortgroupSpec.type = dvPortgroupData.type;
               editDvPortgroupSpec.numPorts = dvPortgroupData.numPorts;

               if (!dvPortgroupData.isUplink) {
                  if (dvPortgroupData.type ===
                        networkUiConstants.PortgroupType.EARLY_BINDING) {
                     editDvPortgroupSpec.autoExpand = dvPortgroupData.autoExpand;
                  }

                  if (dvPortgroupData.isNetworkResourcePoolSupported) {
                     if (dvPortgroupData.isVmVnicResourcePoolSupported) {
                        editDvPortgroupSpec.vmVnicNetworkResourcePoolKey =
                              dvPortgroupData.networkResourcePoolKey;
                     } else {
                        editDvPortgroupSpec.defaultPortConfig = {
                           _type: 'com.vmware.vim.binding.vim.dvs.VmwareDistributedVirtualSwitch$VmwarePortConfigPolicy',
                           networkResourcePoolKey: {
                              _type: 'com.vmware.vim.binding.vim.StringPolicy',
                              value: dvPortgroupData.networkResourcePoolKey,
                              inherited: false
                           }
                        };
                     }
                  }
               }
            }

            if (pageDirtyFlags.isVlanPageDirty ||
                  pageDirtyFlags.isSecurityPolicyPageDirty ||
                  pageDirtyFlags.isFailoverPolicyPageDirty ||
                  pageDirtyFlags.isTrafficShapingPageDirty ||
                  pageDirtyFlags.isMonitoringPolicyPageDirty ||
                  pageDirtyFlags.isMiscellaneousPolicyPageDirty ||
                  pageDirtyFlags.isAdvancedPageDirty) {

               if (!editDvPortgroupSpec.defaultPortConfig) {
                  editDvPortgroupSpec.defaultPortConfig = {
                     _type: 'com.vmware.vim.binding.vim.dvs.VmwareDistributedVirtualSwitch$VmwarePortConfigPolicy'
                  };
               }

               if (pageDirtyFlags.isVlanPageDirty) {
                  editDvPortgroupSpec.defaultPortConfig.vlan =
                        dvpgSpecFactory.buildVlanSpec(dvPortgroupData);
               }

               if (pageDirtyFlags.isSecurityPolicyPageDirty) {
                  editDvPortgroupSpec.defaultPortConfig.securityPolicy =
                     dvPortgroupData.isUplink ? null :
                           dvpgSpecFactory.buildSecurityPolicySpec(dvPortgroupData);
               }

               if (pageDirtyFlags.isFailoverPolicyPageDirty) {
                  editDvPortgroupSpec.defaultPortConfig.uplinkTeamingPolicy =
                     dvPortgroupData.isUplink ? null :
                           dvpgSpecFactory.buildTeamingAndFailoverSpec(dvPortgroupData);
               }

               if (pageDirtyFlags.isTrafficShapingPageDirty) {
                  editDvPortgroupSpec.defaultPortConfig.inShapingPolicy =
                        dvPortgroupData.isUplink ? null :
                              dvpgSpecFactory.buildTrafficShapingSpec(
                                    dvPortgroupData.inShapingPolicy);

                  editDvPortgroupSpec.defaultPortConfig.outShapingPolicy =
                        dvPortgroupData.isUplink ? null :
                              dvpgSpecFactory.buildTrafficShapingSpec(
                                    dvPortgroupData.outShapingPolicy);
               }

               if (pageDirtyFlags.isMonitoringPolicyPageDirty) {
                  editDvPortgroupSpec.defaultPortConfig.ipfixEnabled =
                        createMonitoringPolicySpec(dvPortgroupData.ipfixEnabled);
               }

               if (pageDirtyFlags.isMiscellaneousPolicyPageDirty) {
                  editDvPortgroupSpec.defaultPortConfig.blocked =
                        createMiscellaneousPolicySpec(dvPortgroupData.arePortsBlocked);
               }

               if (pageDirtyFlags.isAdvancedPageDirty) {
                  editDvPortgroupSpec.policy = dvpgSpecFactory.buildPortPoliciesSpec(
                        dvPortgroupData.dvPortgroupPortProperties);
               }
            }

            mutationService.apply(
                  dvPortgroupData.dvPortgroupId, EDIT_DV_PORT_GROUP_SPEC,
                  editDvPortgroupSpec);

            return true;
         }

         function hasChanged() {
            return pageDirtyFlags.isGeneralPropertiesPageDirty ||
                  pageDirtyFlags.isVlanPageDirty ||
                  pageDirtyFlags.isSecurityPolicyPageDirty ||
                  pageDirtyFlags.isFailoverPolicyPageDirty ||
                  pageDirtyFlags.isTrafficShapingPageDirty ||
                  pageDirtyFlags.isMonitoringPolicyPageDirty ||
                  pageDirtyFlags.isMiscellaneousPolicyPageDirty ||
                  pageDirtyFlags.isAdvancedPageDirty;
         }

         function createMonitoringPolicySpec(ipfixEnabled) {
            return {
               _type: 'com.vmware.vim.binding.vim.BoolPolicy',
               value: ipfixEnabled,
               inherited: false
            };
         }

         function createMiscellaneousPolicySpec(arePortsBlocked) {
            return {
               _type: 'com.vmware.vim.binding.vim.BoolPolicy',
               value: arePortsBlocked,
               inherited: false
            };
         }
      };
   }

   function execute(func) {
      if (typeof func === 'function') {
         return func();
      }
   }
})();
