module cluster_ui {

   export class QuickstartRefreshService {

      private static readonly QUICKSTART_EXTENSION_ID: string =
            "vsphere.core.cluster.manage.settings.quickstart";

      private static readonly QUICKSTART_IFRAME_SELECTOR: string =
            `[vx-extension-view-id='${QuickstartRefreshService.QUICKSTART_EXTENSION_ID}'] iframe`;

      private static readonly REFRESH_QUICKSTART_MESSAGE: string = "refreshQuickstart";

      private static readonly TARGET_ORIGIN: string = "*";

      private static readonly OBJECT_DETAILS_CHANGED_EVENT: string = "object-details";

      private static readonly REFRESH_DELAY: number = 200;

      private static readonly RELEVANT_PROPERTIES: string[] = [
         "host",
         "hciConfig.workflowState",
         "hciConfig.configuredHosts",
         "configurationEx.drsConfig.enabled",
         "configurationEx.dasConfig.enabled",
         "configurationEx.vsanConfigInfo.enabled"
      ];

      public static $inject = ["$rootScope", "defaultUriSchemeUtil"];

      constructor(private $rootScope: any, private defaultUriSchemeUtil: any) {
      }

      public init() {
         const removeFunction = this.$rootScope.$on(
               QuickstartRefreshService.OBJECT_DETAILS_CHANGED_EVENT,
               (event: any, partialUpdate: any) => {
                  this.onObjectDetailsChanged(event, partialUpdate);
               });

         this.$rootScope.$on("$destroy", () => {
            if (removeFunction) {
               removeFunction();
            }
         });
      }

      private onObjectDetailsChanged(event: any, partialUpdate: any) {
         const isViewRelevant: boolean =
               QuickstartRefreshService.QUICKSTART_EXTENSION_ID === this.$rootScope._route.extensionId;
         if (isViewRelevant && this.isEventRelevant(event, partialUpdate)) {
            _.debounce(
                  this.notifyQuickstartView,
                  QuickstartRefreshService.REFRESH_DELAY)();
         }
      }

      private isEventRelevant(event: any, partialUpdate: any) {
         const objectId: string = this.$rootScope._route.objectId;
         const isRelevant: boolean = partialUpdate &&
               _.some(partialUpdate.updates,
                     (update: any) => this.isUpdateRelevant(update, objectId));

         return isRelevant;
      }

      private isUpdateRelevant(update: any, objectId: string) {
         const isRelevant: boolean = !!update.data
               && this.defaultUriSchemeUtil.getVsphereObjectId(update.data) === objectId
               && update.deltaProperties
               && _.some(update.deltaProperties, (prop: string): boolean =>
                     _.contains(QuickstartRefreshService.RELEVANT_PROPERTIES, prop));
         return isRelevant;
      }

      private notifyQuickstartView() {
         const quickstartIFrames: any =
               $(QuickstartRefreshService.QUICKSTART_IFRAME_SELECTOR);

         if (quickstartIFrames.length > 0) {
            quickstartIFrames[0].contentWindow.postMessage(
                  QuickstartRefreshService.REFRESH_QUICKSTART_MESSAGE,
                  QuickstartRefreshService.TARGET_ORIGIN);
         }
      }
   }

   angular.module("com.vmware.vsphere.client.cluster")
         .service("quickstartRefreshService", QuickstartRefreshService);
}