namespace codecapture_ui {
   import IController = angular.IController;

   class CodeCaptureViewController implements IController {
      resized: boolean;

      constructor() { }

      $onInit(): void {
         this.onResize();
      }

      onResize(): void {
         setTimeout(() => {
            this.resized = !this.resized;
         }, 100);
      }
   }

   angular.module("com.vmware.vsphere.client.codecapture")
         .controller("CodeCaptureViewController", CodeCaptureViewController);
}