/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace dvs_ui {

   import IPromise = angular.IPromise;
   import IQService = angular.IQService;
   import HostCompatibilityListItem = com.vmware.vsphere.client.h5.network.dvs.upgrade.model.HostCompatibilityListItem;

   export class DvsHostCompatibilityPageController {

      public static $inject = [
            "$scope",
            "dataService",
            "$q",
            "i18nService",
            "dvsUpgradeWizardConstants"
      ];

      private hostCompatibilityData: HostCompatibilityListItem[];

      constructor(private $scope: DvsUpgradeWizardScope,
                  private dataService: any,
                  private $q: IQService,
                  private i18nService: any,
                  private wizardConstants: any) {
         if (this.$scope.model.isCompatibilityDataRetrievedForVersion !==
               this.$scope.model.selectedVersion) {
            this.requestHostCompatibilityData();
         } else {
            this.hostCompatibilityData = this.$scope.model.hostCompatibilityData;
         }
      }

      public requestHostCompatibilityData(): void {
         this.$scope.wizardConfig.loading = true;

         this.dataService.getProperties(
               this.$scope.model.dvsId,
               [this.wizardConstants.properties.DVS_HOST_COMPATIBILITY_DATA],
            {
            propertyParams: [{
               propertyName: this.wizardConstants.properties.DVS_HOST_COMPATIBILITY_DATA,
               parameterType: this.wizardConstants.spec.PRODUCT_SPEC_TYPE,
               parameter: _.find(
                     this.$scope.model.supportedVersionSpecs, (versionSpec: any) => {
                  return versionSpec.version === this.$scope.model.selectedVersion;
               })
            }]
         }).then((response: any): void => {
                  let hostData: HostCompatibilityListItem[] =
                        response[this.wizardConstants.properties.DVS_HOST_COMPATIBILITY_DATA];
                  if (hostData) {
                     this.hostCompatibilityData = hostData;
                  } else {
                     this.hostCompatibilityData = [];
                  }
                  this.$scope.model.hostCompatibilityData = this.hostCompatibilityData;
                  this.$scope.model.isCompatibilityDataRetrievedForVersion =
                        this.$scope.model.selectedVersion;

                  this.$scope.wizardConfig.loading = false;
         });
      };
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .controller("DvsHostCompatibilityPageController", DvsHostCompatibilityPageController);
}
