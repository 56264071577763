/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace dvs_ui {

   export class DvpgTrafficShapingPageComponent {

      public bindings: any;
      public controller: any;
      public templateUrl: string;

      constructor() {
         this.bindings = {
            model: "<",
            overrideOptions: "<?",
            overrideEnabled: "<?",
            overrideDisallowed: "<?"
         };
         this.controller = DvpgTrafficShapingPageComponentController;
         this.templateUrl = "dvs-ui/resources/dvs/dvpg/common/components/" +
               "dvpgTrafficShapingPageTemplate.html";
      }
   }

   class DvpgTrafficShapingPageComponentController {

      static $inject = [
         "$scope",
         "i18nService",
         "networkUiConstants",
         "trafficShapingPolicyValidator"];

      public model: any;
      public i18n: Function;

      private validators: any;
      private statusPolicyOptions: any[];
      private attributeNames: any[];
      public overrideOptions: any;
      public overrideEnabled: boolean;
      public overrideDisallowed: boolean;

      constructor(private $scope: any,
                  private i18nService: any,
                  private networkUiConstants: any,
                  private trafficShapingPolicyValidator: any) {
         this.i18n = i18nService.getString;
         this.attributeNames = [
            networkUiConstants.TrafficShapingSetting.AVERAGE_BANDWIDTH,
            networkUiConstants.TrafficShapingSetting.PEAK_BANDWIDTH,
            networkUiConstants.TrafficShapingSetting.BURST_SIZE
         ];
         this.initPage();
      }

      private onIngressStatusChange(): void {
         _.forEach(this.attributeNames, (attributeName: string) => {
            this.onIngressTrafficShapingChange(attributeName);
         });
      }

      private onEgressStatusChange(): void {
         _.forEach(this.attributeNames, (attributeName: string) => {
            this.onEgressTrafficShapingChange(attributeName);
         });
      }

      private onIngressTrafficShapingChange(attributeName: string): void {
         let value: any = this.model.inShapingPolicy[attributeName];
         let message: string = this.trafficShapingPolicyValidator
               .getTrafficShapingErrorMessage(value, attributeName);

         this.validators.ingress[attributeName].message = message;
         this.validators.ingress[attributeName].isVisible =
               this.model.inShapingPolicy.enabled && message !== null;
      }

      private onEgressTrafficShapingChange(attributeName: string): void {
         let value: any = this.model.outShapingPolicy[attributeName];
         let message: string = this.trafficShapingPolicyValidator
               .getTrafficShapingErrorMessage(value, attributeName);

         this.validators.egress[attributeName].message = message;
         this.validators.egress[attributeName].isVisible =
               this.model.outShapingPolicy.enabled && message !== null;
      }

      private initPage(): void {
         this.validators = this.buildInputValidators();
         this.statusPolicyOptions = this.buildStatusPolicyOptions();
      }

      private buildInputValidators(): any {
         return {
            ingress: {
               averageBandwidth: this.buildDefaultValidator(),
               peakBandwidth: this.buildDefaultValidator(),
               burstSize: this.buildDefaultValidator()
            }, egress: {
               averageBandwidth: this.buildDefaultValidator(),
               peakBandwidth: this.buildDefaultValidator(),
               burstSize: this.buildDefaultValidator()
            }
         };
      }

      private buildDefaultValidator(): any {
         return {
            message: null,
            isVisible: false
         };
      }

      private buildStatusPolicyOptions(): any[] {
         return [{
            label: this.i18nService.getString('NetworkUi', 'dvpg.policy.shaping.status.on'),
            val: true
         }, {
            label: this.i18nService.getString('NetworkUi', 'dvpg.policy.shaping.status.off'),
            val: false
         }];
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
         .component("dvpgTrafficShapingPage", new DvpgTrafficShapingPageComponent());
}
