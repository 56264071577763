namespace h5_vm {
   export class DeviceTypeConstants {
      static readonly CDROM: string = "VirtualCdrom";
      static readonly ETHERNET: string = "VirtualEthernetCard";
      static readonly VIDEO_CARD: string = "VirtualVideoCard";
      static readonly VirtualPCIPassthrough: string = "VirtualPCIPassthrough";
      static readonly EXISTINGHARDDISK: string = "ExistingHardDisk";
      static readonly INPUT_DEVICE: string = "InputDevice";
      static readonly RDMDISK: string = "RDMDisk";
      static readonly SCSICONTROLLER: string = "VirtualSCSIController";
      static readonly PVSCISI: string = "ParaVirtualSCSIController";
      static readonly BUS_LOGIC: string = "VirtualBusLogicController";
      static readonly LSI_LOGIC: string = "VirtualLsiLogicController";
      static readonly LSI_LOGIC_SAS: string = "VirtualLsiLogicSASController";
      static readonly IDE: string = "VirtualIDEController";
      static readonly VIRTUAL_CONTROLLER: string = "VirtualController";
      static readonly VIRTUALDISK: string = "VirtualDisk";
      static readonly VIRTUAL_SATA_CONTROLLER: string = "VirtualSATAController";
      static readonly VIRTUAL_USB_CONTROLLER: string = "VirtualUSBController";
      static readonly VIRTUAL_USB_XHCI_CONRTOLLER: string = "VirtualUSBXHCIController";
      static readonly VIRTUAL_USB: string = "VirtualUSB";
      static readonly NVME_CONTROLLER: string = "VirtualNVMEController";
      static readonly TPM: string = 'VirtualTPM';
      static readonly NVDIMM: string = "VirtualNVDIMM";
      static readonly VIRTUAL_SIO_CONTROLLER: string = "VirtualSIOController";
      static readonly VIRTUAL_SERIAL_PORT: string = "VirtualSerialPort";
   }
   export class DeviceType extends DeviceTypeConstants {
      // just shortcut to DeviceTypeConstants
   }

   angular
         .module("com.vmware.vsphere.client.vm")
         .constant("deviceTypeConstants", DeviceTypeConstants);
}
