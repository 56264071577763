namespace h5_vm {

   import GosBasePageModel = h5_vm.GosBasePageModel;

   export class GosDnsSettingsPageModel extends GosBasePageModel {
      primaryDns: string;
      secondaryDns: string;
      tertiaryDns: string;

      dnsSearchPath: string[];
   }
}
