/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
(function() {
   'use strict';
   angular.module('com.vmware.vsphere.client.storage')
      .service('specifyConfigurationPageService', specifyConfigurationPageService);

   specifyConfigurationPageService.$inject = [
      'i18nService', 'wizardPageService'
   ];

   function specifyConfigurationPageService (i18nService, wizardPageService) {

      return {
         build: function(wizardScope) {
            return {
               title: i18nService.getString('StorageUi', 'increaseDatastoreWizard.partitionConfigurationPage.title'),
               description: i18nService.getString('StorageUi', 'increaseDatastoreWizard.partitionConfigurationPage.description'),
               contentUrl: 'storage-ui/resources/storage/views/wizard/increaseDatastoreCapacity/pages/SpecifyConfigurationPage.html',
               state: undefined,
               onCommit: function() {
                  var manager = wizardScope.manager;
                  var selection = manager.getSelectedOption();
                  if (selection && selection.sizeToAdd) {
                     wizardPageService.markPageComplete(wizardScope.wizardConfig);
                     return true;
                  } else {
                     wizardPageService.markPageIncompleteWithError(
                        wizardScope.wizardConfig,
                        i18nService.getString('StorageUi', 'form.validationError')
                     );
                     return false;
                  }
               }
            };
         }
      };
   }
})();