(function() {
   'use strict';
   angular
         .module('com.vmware.vsphere.client.host')
         .controller(
               'hostGraphicsTabsController',
               HostGraphicsTabsController);

   HostGraphicsTabsController.$inject = ['$scope', 'dataService'];

   function HostGraphicsTabsController($scope, dataService) {
      var self = this;
      self.hostId = $scope._route.objectId;
      self.getData = getData;
      getData();

      function getData() {
         dataService
               .getData(
                     self.hostId,
                     'com.vmware.vsphere.client.h5.host.model.GraphicsData')
               .then(function(data) {
                  if (data) {
                     self.isHostSettingsEnabled = data.isHostSettingsEnabled;
                  }
               });
      }
   }
})();
