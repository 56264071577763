/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace dvs_ui {

   export class DvsDetailsComponent {

      public bindings: any;
      public controller: any;
      public templateUrl: string;

      constructor() {
         this.bindings = {};
         this.controller = DvsDetailsController;
         this.templateUrl = "dvs-ui/resources/dvs/summary/components/dvsDetailsComponentTemplate.html";
      }
   }

   class DvsDetailsController {

      static $inject = [
         "i18nService",
         "$rootScope",
         "dataService"
      ];

      static DATA_MODEL: string = "com.vmware.vsphere.client.h5.network.dvs.model.DvsDetailsPortletData";

      private _data: any; /* DvsDetailsPortletData */

      public loadingData: boolean;

      public liveRefreshProperties: string[];

      public dSwitchUri: string;

      constructor(public i18nService: any,
                  private $rootScope: any,
                  private dataService: any) {
      }

      protected $onInit() {
         this.requestData();
      }

      public refresh(): void {
         this.requestData();
      }

      public get networks(): number {
         return this._data && this._data.numPortgroups;
      }

      public get hosts(): number {
         return this._data && this._data.numHosts;
      }

      public get vms(): number {
         return this._data && this._data.numVms;
      }

      public get ports(): number {
         return this._data && this._data.numPorts;
      }

      private requestData(): void {
         let self: DvsDetailsController = this;
         let dvsUri: string = this.$rootScope._route.objectId;

         self.loadingData = true;
         self.dataService.getData(dvsUri, DvsDetailsController.DATA_MODEL)
            .then(function (dvsDetailsPortletData: any) {
               self._data = dvsDetailsPortletData;
               self.loadingData = false;
            });

         this.dSwitchUri = this.$rootScope._route.objectId;
         this.liveRefreshProperties = [
            "summary.vm",
            "portgroup",
            "config.numPorts",
            "summary.hostMember"
         ];
      }
   }

   angular.module("com.vmware.vsphere.client.dvs")
      .component("dvsDetails", new DvsDetailsComponent());
}
