namespace network_ui {

   angular.module("com.vmware.vsphere.client.network").constant(
         "migrateVmkWizardConstants", {
            pages: {
               SELECT_VMK_NETWORK_ADAPTER: "selectVmkToMigrate",
               CONFIGURE_SETTINGS: "configureSettings",
               READY_TO_COMPLETE: "readyToComplete"
            },
            connectedToStandardSwitch: "standardNetwork"
   });
}
