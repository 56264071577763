/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace dvs_ui {
   import DvsPortMonitoringPolicyModel =
         com.vmware.vsphere.client.h5.network.dvs.ports.model.DvsPortMonitoringPolicyModel;

   export class DvsPortEditMonitoringPageModel {
      public monitoringPolicy: DvsPortMonitoringPolicyModel;

      public isMonitoringPageChanged: Function;

      constructor(monitoringPolicy: DvsPortMonitoringPolicyModel) {
         if (monitoringPolicy) {
            this.monitoringPolicy = monitoringPolicy;

            this.isMonitoringPageChanged =
                  this.isMonitoringPageChangedFunction.bind(this);
         }
      }

      public isMonitoringPageChangedFunction(
            initialData: DvsPortMonitoringPolicyModel): boolean {

         if (!initialData || !this.monitoringPolicy) {
            return false;
         }

         return initialData.ipfixOverrided !== this.monitoringPolicy.ipfixOverrided
               || initialData.ipfixEnabled !== this.monitoringPolicy.ipfixEnabled;
      }
   }
}