(function () {
   'use strict';

   angular
         .module('com.vmware.vsphere.client.network')
         .service('portGroupPropertiesPageService', portGroupPropertiesPageService);

   /**
    * Service dedicated to portGroupPropertiesPage template.
    */
   portGroupPropertiesPageService.$inject = [
         'i18nService',
         'mutationService'];

   function portGroupPropertiesPageService(
         i18nService,
         mutationService) {

      return {
         getInvalidNameError: getInvalidNameError,
         getDuplicateNameError: getDuplicateNameError
      };

      /**
       * Returns localized error message if the name is a text with no more than 127
       * characters and it doesn't include `"` one.
       */
      function getInvalidNameError(name) {
         if (name.trim().length === 0) {
            return i18nService.getString('NetworkUi', 'VnicData.emptyNetworkLabel');
         } else if (name.length > 127) {
            return i18nService.getString('NetworkUi', 'VnicData.networkLabelTooLong');
         } else if (name.indexOf('"') !== -1) {
            return i18nService.getString('H5NetworkUi',
                  'VnicData.networkLabelInvalidCharacter');
         }
         return null;
      }

      /**
       * Returns localized error text if the given host has a portgroup with the
       * provided name. Otherwise it returns null.
       */
      function getDuplicateNameError(hostId, name) {
         return mutationService.validate(hostId,
               'com.vmware.vsphere.client.h5.network.host.wizard.addnetworking.model.StandardNetworkLabelValidateSpec',
               {
                  _type: 'com.vmware.vsphere.client.h5.network.host.wizard.addnetworking.model.StandardNetworkLabelValidateSpec',
                  label: name.trim()
               })
               .then(function (validationResult) {

                  if (validationResult.error) {
                     if (validationResult.error.localizedMessage) {
                        return validationResult.error.localizedMessage;
                     }
                  }

                  if (validationResult.result) {
                     return validationResult.result;
                  }

                  return null;
               }, function () {
                  return null;
               });
      }
   }
})();
