(function() {
   'use strict';
   angular.module('com.vmware.vsphere.client.host').controller('EditSmartCardAuthenticationController',
         EditSmartCardAuthenticationController);

   EditSmartCardAuthenticationController.$inject = ['$scope', '$element', 'dataService', 'timeFormatterService', 'editSmartCardAuthService', '$timeout', 'i18nService', 'clarityConstants'];

   function EditSmartCardAuthenticationController($scope, $element, dataService, timeFormatterService, editSmartCardAuthService, $timeout, i18nService, clarityConstants) {
      this.loading = true;
      this.objectId = $scope.modalOptions.objectId;
      this.gridWidget = null;
      this.isSmartCardAuthenticationEnabled = false;
      this.isSmartCardAuthenticationEnabledOldState = false;
      this.originalSmartCardTrustAnchors = [];


      var fileInput = $element.find('input[type="file"]');
      var fileReader = new FileReader();

      var addAction = getAddAction.call(this);
      var removeAction = getRemoveAction.call(this);

      fileInput.on('change', onFileInputChange.bind(this));
      fileReader.onload = function(event) {
         if (!event.target.result) {
            return;
         }

         editSmartCardAuthService
               .validateCertificate(this.objectId, event.target.result)
               .then(function(response) {
                  if (response && response.result) {
                     var alreadyInDataGrid = _.some(this.datagridOptions.data, function(item) {
                        return item.serialNumber === response.result[0].serialNumber;
                     });
                     if (!alreadyInDataGrid) {
                        this.datagridOptions.data.push(response.result[0]);
                     }

                  } else {
                     $scope.modalOptions.alerts = [{
                        text: i18nService.getString("HostUi", "editSmartCardAuthentication.error"),
                        type: clarityConstants.notifications.type.ERROR
                     }];
                     $scope.modalOptions.isAlertClosed = false;
                  }
               }.bind(this));
      }.bind(this);

      $scope.modalOptions.alerts = [];
      $scope.modalOptions.isAlertClosed = true;

      this.getViewData = function() {
         dataService.getProperties(this.objectId, ['isSmartCardAuthenticationEnabled', 'smartCardTrustAnchors']).then(function(data) {
            timeFormatterService.getUserTimeFormatPreference()
                  .then(function(userTimeFormat) {
                     this.initializeData(data, userTimeFormat);
                  }.bind(this));
         }.bind(this));
      };

      $scope.modalOptions.onSubmit = function() {
         return editSmartCardAuthService.updateCertificateData(
               this.objectId,
               this.originalSmartCardTrustAnchors,
               this.datagridOptions.data,
               this.isSmartCardAuthenticationEnabled,
               this.isSmartCardAuthenticationEnabledOldState
         );
      }.bind(this);

      $scope.$watch(
            function() {
               if (this.datagridOptions) {
                  return this.datagridOptions.selectedItems.length;
               }
            }.bind(this),
            function(newLength) {
               removeAction.enabled = (newLength > 0);
            }.bind(this)
      );

      $scope.$on('kendoWidgetCreated', function(event, widget) {
         if (!widget || widget.constructor !== kendo.ui.Grid) {
            return;
         }
         this.gridWidget = widget;
      }.bind(this));

      $scope.$watchCollection(function() {
         if (this.datagridOptions && this.datagridOptions.data) {
            return this.datagridOptions.data;
         }
      }.bind(this), function(newValue, oldValue) {
         if (this.gridWidget && newValue !== oldValue) {
            this.gridWidget.dataSource.data(newValue);
            this.gridWidget.refresh();
         }

      }.bind(this));

      function onFileInputChange (event) {
         $scope.modalOptions.alerts = [];
         $scope.modalOptions.isAlertClosed = true;

         var file = event.target.files[0];
         // after this method finishes the onload is called internally
         fileReader.readAsArrayBuffer(file);
         fileInput.prop('value', '');
      }

      function getRemoveAction() {
         return {
            actionId: 'removeCertificates',
            label: i18nService.getString('HostUi', 'smartCardAuthentication.removeCertificate'),
            tooltipText: i18nService.getString('HostUi', 'smartCardAuthentication.removeCertificate'),
            enabled: false,
            iconClass: 'vx-icon-removeIcon'
         };
      }

      function getAddAction() {
         return {
            actionId: 'addCertificate',
            label: i18nService.getString('HostUi', 'smartCardAuthentication.addCertificate'),
            tooltipText: i18nService.getString('HostUi', 'smartCardAuthentication.addCertificate'),
            enabled: true,
            iconClass: 'vx-icon-addIcon',
            onClick: function() {
               fileInput.click();
            }
         };
      }

      this.initializeData = function(data, userTimeFormat) {
         this.isSmartCardAuthenticationEnabled = data.isSmartCardAuthenticationEnabled;
         this.isSmartCardAuthenticationEnabledOldState = data.isSmartCardAuthenticationEnabled;
         this.datagridOptions = editSmartCardAuthService.getDataGridOptions(userTimeFormat);
         this.originalSmartCardTrustAnchors = data.smartCardTrustAnchors;
         this.datagridOptions.data = angular.merge([], data.smartCardTrustAnchors);
         removeAction.onClick = function() {
            var selectedItem = this.datagridOptions.selectedItems[0];
            this.datagridOptions.data = _.filter(this.datagridOptions.data, function(item) {
               return selectedItem.serialNumber !== item.serialNumber;
            });
         }.bind(this);
         this.datagridOptions.actionBarOptions.actions = [addAction, removeAction];
         this.loading = false;
      };

      this.getViewData();

   }
})();

