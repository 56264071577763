/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */

declare let h5: any;
h5 = h5 || {};
h5.dvs = {
   angularModule: angular.module("com.vmware.vsphere.client.dvs", [])
};

/*
 * Registers dvs-ui actions.
 */
angular.module("com.vmware.vsphere.client.dvs")
      .run([
         "dvsAddHostWizardService",
         "exportDvsDialogService",
         "dvsImportWizardService",
         "dvsEditSettingsDialogService",
         "dvsCreateWizardService",
         "dvpgCreateWizardService",
         "dvpgCreateWizardConstants",
         "mutationService",
         "dataService",
         "clarityModalService",
         "dvsEditPvlanDialogService",
         "dvsEditNetFlowDialogService",
         "dvpgManageWizardService",
         "dvsAddSpanSessionWizardService",
         "dvsPortMirroringRemoveSessionService",
         "networkUiConstants",
         "editDvPortgroupDialogService",
         "dvsEditSpanSessionDialogService",
         "dvsUpgradeWizardService",
         "dvsUpgradeNiocWizardService",
         "dvsUpgradeLacpWizardService",
         "defaultUriSchemeUtil",
         "dvsOutOfSyncHostService",
         "removeDvsDialogService",
         "dvsRectifyHostsOutOfSyncService",
         "dvpgTrafficFilterService",
         "dvsPortTrafficFilterService",
         "dvsPortEditDialogService",
         "dvsHealthCheckManageDialogService",
         'moveDvsToService',
         function (dvsAddHostWizardService: any,
                   exportDvsDialogService: any,
                   dvsImportWizardService: any,
                   dvsEditSettingsDialogService: any,
                   dvsCreateWizardService: any,
                   dvpgCreateWizardService: any,
                   dvpgCreateWizardConstants: any,
                   mutationService: any,
                   dataService: any,
                   clarityModalService: any,
                   dvsEditPvlanDialogService: any,
                   dvsEditNetFlowDialogService: any,
                   dvpgManageWizardService: any,
                   dvsAddSpanSessionWizardService: any,
                   dvsPortMirroringRemoveSessionService: any,
                   networkUiConstants: any,
                   editDvPortgroupDialogService: any,
                   dvsEditSpanSessionDialogService: any,
                   dvsUpgradeWizardService: any,
                   dvsUpgradeNiocWizardService: any,
                   dvsUpgradeLacpWizardService: any,
                   defaultUriSchemeUtil: any,
                   dvsOutOfSyncHostService: any,
                   removeDvsDialogService: any,
                   dvsRectifyHostsOutOfSyncService: any,
                   dvpgTrafficFilterService: any,
                   dvsPortTrafficFilterService: any,
                   dvsPortEditDialogService: any,
                   dvsHealthCheckManageDialogService: any,
                   moveDvsToService: any) {

            function showAddHostWizard(actionEval: any,
                                       availableTargets: string[],
                                       context: any) {
               if (context && context.selectedSwitch &&
                     context.selectedSwitch.dvsRef) {
                  let dvsId: string =
                        defaultUriSchemeUtil.getVsphereObjectId(
                              context.selectedSwitch.dvsRef);
                  dvsAddHostWizardService.show(dvsId, availableTargets[0]);
               } else {
                  dvsAddHostWizardService.show(availableTargets[0]);
               }
            }

            h5.actions["vsphere.core.dvs.manageHostOnDvsAction"] = showAddHostWizard;
            h5.actions["vsphere.core.dvs.migrateHostNetworkingAction"] = showAddHostWizard;

            function showExportDvsDialog(actionEval: any,
                                         availableTargets: string[]) {
               exportDvsDialogService.show(availableTargets[0]);
            }

            h5.actions["vsphere.core.dvs.exportConfigAction"] = showExportDvsDialog;

            function showImportDvsWizard(actionEval: any,
                                         availableTargets: string[]) {
               dvsImportWizardService.show(availableTargets[0], false, false);
            }

            h5.actions["vsphere.core.dvs.importDvsAction"] = showImportDvsWizard;

            function showRestoreDvsWizard(actionEval: any,
                                         availableTargets: string[]) {
               dvsImportWizardService.show(availableTargets[0], false, true);
            }

            h5.actions["vsphere.core.dvs.restoreConfigAction"] = showRestoreDvsWizard;

            function showDvsEditSettingsDialog(actionEval: any, availableTargets: string[]) {
               dvsEditSettingsDialogService.show(availableTargets[0]);
            }

            h5.actions["vsphere.core.dvs.editSettings"] = showDvsEditSettingsDialog;

            function showCreateDvpgWizardForDvs(actionEval: any, availableTargets: string[]) {
               dvpgCreateWizardService.show(availableTargets[0]);
            }

            h5.actions["vsphere.core.dvs.createDvs"] = showDvsCreateWizard;

            function showDvsCreateWizard(actionEval: any, availableTargets: string[]) {
               dvsCreateWizardService.show(availableTargets[0]);
            }

            h5.actions["vsphere.core.dvs.deleteDvs"] = showDvsDeleteConfirm;

            function showDvsDeleteConfirm(actionEval: any, availableTargets: string[]) {
               if (availableTargets.length === 1) {
                  mutationService.remove(availableTargets[0]);
               }
            }

            h5.actions["vsphere.core.dvs.spanSession.addAction"] = showAddSpanSessionWizard;

            function showAddSpanSessionWizard(actionEval: any, availableTargets: string[]) {
               dvsAddSpanSessionWizardService.show(availableTargets[0]);
            }

            h5.actions["vsphere.core.dvs.spanSession.editAction"] = showEditSpanSessionDialog;

            function showEditSpanSessionDialog(actionEval: any, availableTargets: string[], context: any) {
               let sessionType: string | null = null;
               if (context.selectedSession.isDvPortMirrorSession) {
                  sessionType = "dvPortMirror";
               } else if (context.selectedSession.isRemoteDestinationSession) {
                  sessionType = "remoteMirrorDest";
               } else if (context.selectedSession.isRemoteSourceSession) {
                  sessionType = "remoteMirrorSource";
               } else if (context.selectedSession.isEncapsulatedSession) {
                  sessionType = "encapsulatedRemoteMirrorSource";
               }

               if (sessionType) {
                  dvsEditSpanSessionDialogService.show(availableTargets[0],
                        context.selectedSession.key, sessionType);
               }
            }

            h5.actions["vsphere.core.dvs.spanSession.removeAction"] =
                  showRemoveSpanSessionDialog;

            function showRemoveSpanSessionDialog(actionEval: any, availableTargets: string[], context: any) {
               dvsPortMirroringRemoveSessionService
                     .removeSpanSession(availableTargets, context);
            }

            h5.actions["vsphere.core.dvs.createPortgroup"] = showCreateDvpgWizardForDvs;

            h5.actions["vsphere.core.dvs.managePortgroups"] = showManageDvpgWizardForDvs;

            function showManageDvpgWizardForDvs(actionEval: any, availableTargets: string[]): void {
               dvpgManageWizardService.show(availableTargets[0]);
            }

            function showDeleteDvPgConfirm(actionEval: any, availableTargets: any) {
               if (availableTargets.length === 1) {
                  mutationService.remove(availableTargets[0]);
               } else if (availableTargets.length > 1) {
                  mutationService.removeOnMultiEntity(availableTargets);
               }
            }

            h5.actions["vsphere.core.dvPortgroup.deletePortgroupAction"] =
                  showDeleteDvPgConfirm;

            h5.actions["vsphere.core.dvs.actions.renameAction"] =
                  showRenameModalDialog;

            function showRenameModalDialog(actionEval: any, availableTargets: string[]) {
               let dvsRef = availableTargets[0];

               dataService.getProperties(dvsRef, ["config.name"]).then(
                     function (dvsResponse: any) {

                        let dvsUnescapedName = dvsResponse["config.name"]
                              .replace(/%25/g, "%")
                              .replace(/%2f/g, "\/")
                              .replace(/%5c/g, "\\");

                        clarityModalService.openModal(
                              actionEval,
                              availableTargets,
                              {
                                 objectId: dvsRef,
                                 existingObjectName: dvsUnescapedName
                              },
                              "common-module-ui/resources/common-module/views/rename/renameObject.html"
                        );
                     });
            }

            function showDvsEditNetFlowDialog(actionEval: any, availableTargets: string[]) {
               dvsEditNetFlowDialogService.show(availableTargets[0]);
            }

            h5.actions["vsphere.core.dvs.editNetFlow"] = showDvsEditNetFlowDialog;

            function showDvsEditPvlanDialog(actionEval: any,
                                            availableTargets: string[]) {
               dvsEditPvlanDialogService.show(availableTargets[0]);
            }

            h5.actions["vsphere.core.dvs.editHealthCheckSettings"] = showDvsEditHealthCheckDialog;

            function showDvsEditHealthCheckDialog(actionEval: any,
                                            availableTargets: string[]) {
               dvsHealthCheckManageDialogService.show(availableTargets[0]);
            }

            h5.actions["vsphere.core.dvs.editPvlan"] = showDvsEditPvlanDialog;

            function showUpgradeDvsWizard(actionEval: any, availableTargets: any): void {
               dvsUpgradeWizardService.show(availableTargets[0]);
            }

            h5.actions["vsphere.core.dvs.upgradeNioc"] = showUpgradeNiocWizard;

            function showUpgradeNiocWizard(actionEval: any, availableTargets: any): void {
               dvsUpgradeNiocWizardService.show(availableTargets[0]);
            }

            h5.actions["vsphere.core.dvs.upgradeLacp"] = showUpgradeLacpWizard;

            function showUpgradeLacpWizard(actionEval: any, availableTargets: any): void {
               dvsUpgradeLacpWizardService.show(availableTargets[0]);
            }

            h5.actions["vsphere.core.dvs.upgradeDvs"] = showUpgradeDvsWizard;

            function showEditDvpgDialog(actionEval: any, availableTargets: any): void {
               editDvPortgroupDialogService.show(availableTargets[0], actionEval);
            }

            h5.actions[networkUiConstants.actions.EDIT_DVPG] = showEditDvpgDialog;

            h5.actions[networkUiConstants.actions.EXPORT_DVPG] = showExportDpvgDialog;

            function showExportDpvgDialog(actionEval: any, availableTargets: string[]) {
               exportDvsDialogService.show(availableTargets[0]);
            };

            h5.actions[networkUiConstants.actions.IMPORT_DVPG] = showImportDvpgDialog;

            function showImportDvpgDialog(actionEval: any, availableTargets: string[]) {
               dvsImportWizardService.show(availableTargets[0], true, false);
            };

            h5.actions[networkUiConstants.actions.RESTORE_DVPG] = showRestoreDvpgDialog;

            function showRestoreDvpgDialog(actionEval: any, availableTargets: string[]) {
               dvsImportWizardService.show(availableTargets[0], true, true);
            };

            function renameDvpg(actionEval: any, availableTargets: any): void {
               let dvPortgroupRef: any = availableTargets[0];
               dataService.getProperties(dvPortgroupRef, ["config.name"]).then(
                     function (dvPortgroupResponse: any): void {
                        let dvPortgroupName: any = dvPortgroupResponse["config.name"];

                        let dvPortgroupUnescapedName: any = dvPortgroupName
                              .replace(/%25/g, "%")
                              .replace(/%2f/g, "\/")
                              .replace(/%5c/g, "\\");

                        clarityModalService.openModal(
                              actionEval,
                              availableTargets,
                              {
                                 objectId: dvPortgroupRef,
                                 existingObjectName: dvPortgroupUnescapedName
                              },
                              "common-module-ui/resources/common-module/views/rename/renameObject.html"
                        );
                     });
            }

            h5.actions[networkUiConstants.actions.RENAME_DV_PORTGROUP] = renameDvpg;

            function showEditTrafficFiltersDialog(actionEval: any,
                  availableTargets: string[], context: any) {
               dvpgTrafficFilterService.showEditTrafficFilterDialog(availableTargets[0]);
            }

            h5.actions["vsphere.core.dvs.trafficFilters.editRulesAction"] =
                  showEditTrafficFiltersDialog;

            function showCloneTrafficFiltersDialog(actionEval: any,
                     availableTargets: string[], context: any) {
               dvpgTrafficFilterService.showCloneTrafficFilterDialog(availableTargets[0]);
            }

            h5.actions["vsphere.core.dvs.trafficFilters.cloneRulesAction"] =
                  showCloneTrafficFiltersDialog;

            function showEditPortTrafficFilterDialog(
                  actionEval: any, availableTargets: any, context: any) {

               dvsPortTrafficFilterService.showPortEditTrafficFilterDialog(
                     availableTargets[0],
                     context.isPortGroupContext,
                     context.selectedPort);
            }

            h5.actions["vsphere.core.dvs.trafficFilters.editPortRulesAction"] =
                  showEditPortTrafficFilterDialog;

            function showOutOfSyncDvsDetails(actionEval: any, availableTargets: any, context: any) {
               dvsOutOfSyncHostService.showDetails(context.details.hostOutOfSync,
                     context.description, context.entityId);
            }

            h5.actions[networkUiConstants.actions.HOST_OF_SYNC_DVS_HOST] = showOutOfSyncDvsDetails;

            function removeDvs(actionEval: any, availableTargets: any, context: any) {
               if (context.selectedSwitch.isGhost || context.selectedSwitch.isDvsDetached) {
                  removeDvsDialogService.removeGhostDvs(
                        availableTargets[0],
                        context.selectedSwitch.name,
                        context.selectedSwitch.dvsUuid);
               } else {
                  removeDvsDialogService.removeDvs(
                        availableTargets[0],
                        context.selectedSwitch.name,
                        defaultUriSchemeUtil.getVsphereObjectId(context.selectedSwitch.dvsRef));
               }
            }

            h5.actions[networkUiConstants.actions.REMOVE_DVS] = removeDvs;

            function showRectifyOutOfSyncHostsDvs(actionEval: any, availableTargets: any, context: any) {
               dvsRectifyHostsOutOfSyncService.show(context.entityId);
            }

            h5.actions[networkUiConstants.actions.RECTIFY_HOSTS_OUT_OF_SYNC_DVS] = showRectifyOutOfSyncHostsDvs;

            function showEditPortDialog(actionEval: any, availableTargets: any, context: any) {
               dvsPortEditDialogService.show(availableTargets[0], context.selectedPort,
                     context.isPortGroupContext, context.dSwitchUri);
            }

            h5.actions[networkUiConstants.actions.EDIT_DVPORT] = showEditPortDialog;

            h5.actions[networkUiConstants.actions.DVS_MOVE_TO_ACTION] = showMoveToDialog;

            function showMoveToDialog(actionEval: any, availableTargets: any, context: any) {
               moveDvsToService.openMoveToDialog(availableTargets);
            }

            h5.actions[networkUiConstants.actions.DVS_MOVE_TO_DRAG_AND_DROP_ACTION] = moveDvs;

            function moveDvs(actionEval: any, availableTargets: any, destinationTarget: any) {
               if (!availableTargets || !availableTargets.length || !destinationTarget) {
                  return;
               }

               moveDvsToService.moveDvsTo(availableTargets, destinationTarget.dropTarget);
            }
         }]);
