namespace h5_vm {

   import VmHwSerialPort = h5_vm.VmHwSerialPort;
   import VirtualDevice$BackingInfo = com.vmware.vim.binding.vim.vm.device.VirtualDevice$BackingInfo;
   import IFormController = angular.IFormController;

   class VmHwSerialPortController implements ng.IComponentController {
      static $inject: string[] = ["i18nService", "vmHardwareSerialPortService"];

      port: VmHwSerialPort;
      expanded: boolean;

      LABELS: VmHwSerialPortLabels = new VmHwSerialPortLabels();
      device: any;
      i18n: Function;
      private form: IFormController;


      constructor(private i18nService: any, private vmHardwareSerialPortService: any) {
         this.i18n = (key: string, bundle: string = "VmUi") => i18nService.getString(bundle, key);
         this.LABELS.initialize(this.i18n);
      }

      public get ID():string {
         if (!this.port.key) {
            return "0";
         }
         return this.port.key.toString();
      }

      $onInit(): void {
         this.port.attachLabels(this.i18n);
         if (this.port.isNew()) {
            this.expanded = true;
         }
      }

      public get hasChanged():boolean {
         return this.port.hasChanged();
      }

      private revalidate():boolean {
         const isValid:boolean = this.port.isValid();
         this.setValidity(isValid);
         return isValid;
      }

      private setValidity(isValid :boolean) {
         if (this.form) {
            this.form.$setValidity("", isValid, this.form);
         }
      }

      public remove(): void {
         this.port.removeDevice();
         this.expanded = false;
      }

      public get isRemoveDisabled(): boolean {
         return this.port.removeDisabled();
      }

      public restore(): void {
         this.port.revertRemoval();
      }

      public get NotMarkedForRemoval(): boolean {
         return (true !== this.port.isMarkedForRemoval());
      }

      public get isMarkedForRemoval(): boolean {
         return this.port.isMarkedForRemoval();
      }


      public get deviceLabel(): string {
         return this.port.deviceLabel();
      }

      public get connected(): boolean {
         return this.port.connected;
      }

      public set connected(value: boolean) {
         this.port.connected = value;
      }

      public get isConnectDisabled() {
         return this.port.isConnectDeviceDisabled();
      }

      public get isEditDisabled() {
         return this.port.isEditDeviceDisabled();
      }

      public get startConnected(): boolean {
         return this.port.startConnected;
      }

      public set startConnected(value: boolean) {
         this.port.startConnected = value;
      }

      public get backingValue(): string {
         return this.port.backing;
      }

      public set backingValue(value: string) {
         this.expanded = true;
         this.port.backing = value;
      }

      public get backingList(): string[] {
         return this.port.backingList;
      }

      public getBackingLabel(backingType:string) {
         const label = (key: string): string => {
            return this.i18n("SerialPortConfig." + key);
         };

         if (backingType.indexOf("FileBacking") > -1) {
            return label("File");
         } else  if (backingType.indexOf("DeviceBacking") > -1) {
            return label("Device");
         } else  if (backingType.indexOf("PipeBacking") > -1) {
            return label("Pipe");
         } else  if (backingType.indexOf("URIBacking") > -1) {
            return label("Network");
         }
         return "";
      }

      public get showFileBacking(): boolean {
         return this.port.hasFileBacking;
      }

      public get yield(): boolean {
         return this.port.yield;
      }

      public set yield(value: boolean) {
         this.port.yield = value;
      }

      public get file(): string {
         return this.port.file;
      }

      public set file(value: string) {
         this.port.file = value;
      }

      public get isFileInvalid():boolean {
         return !this.port.isFileValid();
      }

      public get fileError():string {
         return "invalid file name";
      }

      public get deviceList(): any[] {
         return this.port.deviceList;
      }

      public get deviceValue(): any {
         return this.port.deviceValue;
      }

      public set deviceValue(value: any) {
         this.port.deviceValue = value;
      }

      public get filePlaceHolder(): string {
         return this.LABELS.FILE_PLACEHOLDER;
      }

      public get showDeviceBacking(): boolean {
         return this.port.hasDeviceBacking;
      }

      public get showPipeBacking(): boolean {
         return this.port.hasPipeBacking;
      }

      public get showURIBacking(): boolean {
         return this.port.hasURIBacking;
      }

      public get showProxy(): boolean {
         return this.showURIBacking && this.port.vspc;
      }

      public get showStartConnectedInHeader(): boolean {
         if (this.expanded) {
            return false;
         }
         if (this.port.vm.isUpdate) {
            if (this.port.vm.poweredOn) {
               return false;
            }
         }
         return true;
      }

      public get showConnectedInHeader(): boolean {
         if (this.port.vm.isUpdate) {
            if (this.port.vm.poweredOn) {
               return true;
            }
         }
         return false;
      }

      browse(): void {
         this.vmHardwareSerialPortService.openFileBrowserModal(this.port.vm.getVmConfigContext(), (filePath: string) => {
                  this.port.file = filePath;
               }
         );
      }

      // PIPE BACKING SECTION - pipe (string), nearEnd (enum), farEnd (boolean)

      public get pipe(): string {
         return this.port.pipe;
      }

      public set pipe(value: string) {
         this.port.pipe = value;
      }

      public get isPipeInvalid():boolean {
         return !this.port.isPipeValid();
      }

      public get pipeError():string {
         return this.port.getPipeError();
      }

      public get nearEndList(): any[] {
         return this.port.nearEndList;
      }

      public getNearEndLabel(value: string): string {
         switch (value) {
            case "client":
               return this.LABELS.CLIENT;
            case "server":
               return this.LABELS.SERVER;
            default:
               return "";
         }
      }

      public get nearEndValue(): any {
         return this.port.nearEnd;
      }

      public set nearEndValue(value: any) {
         this.port.nearEnd = value;
      }

      public get farEndList(): any[] {
         return this.port.farEndList;
      }

      public getFarEndLabel(value: boolean): string {
         if (value) {
            return this.LABELS.PIPE_FAR_END_PROCESS;
         } else {
            return this.LABELS.PIPE_FAR_END_VM;
         }
      }

      public get farEndValue(): any {
         return this.port.farEnd;
      }

      public set farEndValue(value: any) {
         this.port.farEnd = value;
      }

      // URI BACKING SECTION

      public get serviceURI(): string {
         return this.port.serviceURI;
      }

      public set serviceURI(value: string) {
         this.port.serviceURI = value;
      }

      public get isServiceInvalid():boolean {
         return !this.port.isServiceURIValid();
      }

      public get serviceError():string {
         return this.port.getServiceURIError();
      }

      public get vspc(): boolean {
         return this.port.vspc;
      }

      public set vspc(value: boolean) {
         this.port.vspc = value;
      }

      public get proxy(): string {
         return this.port.proxyURI;
      }

      public set proxy(value: string) {
         this.port.proxyURI = value;
      }

      public get isProxyInvalid():boolean {
         return !this.port.isProxyURIValid();
      }

      public get isProxyDisabled():boolean {
         if (this.isEditDisabled) {
            return true;
         }
         if (!this.port.vspc) {
            return true;
         }
         return false;
      }


      public get proxyError():string {
         return this.port.getProxyURIError();
      }

      public get directionList(): any[] {
         return this.port.directionList;
      }

      public set directionValue(value: string) {
         this.port.direction = value;
      }

      public get directionValue(): string {
         return this.port.direction;
      }

      public getDirectionLabel(value: string): string {
         switch (value) {
            case "client":
               return this.LABELS.CLIENT;
            case "server":
               return this.LABELS.SERVER;
            default:
               return "";
         }
      }
   }

   export class VmHwSerialPortComponent implements ng.IComponentOptions {
      bindings: any;
      controller: any;
      templateUrl: string;

      constructor() {
         this.bindings = {
            port: "<",
            expanded: "="
         };

         this.controller = VmHwSerialPortController;
         this.templateUrl = "vm-ui/resources/vm/views/settings/vmHardwareSettings/vmHardwareSerialPort/vm-hw-serial-port.html";
      }
   }

   angular.module("com.vmware.vsphere.client.vm")
         .component("vmHwSerialPort", new VmHwSerialPortComponent());
}
