namespace storage_ui {
   export class StorageValidationService {

      public static $inject = ['i18nService'];

      constructor(private i18nService: any) {}

      /**
       * DNS-related: accepts only alphanumeric characters, -(dash), _(underscore) and . (dot)
       */
      public VALID_DNS_NAME_REG_EX: RegExp = new RegExp("^[\\w.-]+$");
      public PORT_MAX_NUMBER: number = 65535;

      /**
       * File/Folder-related: invalid characters.
       */
      public FORWARD_SLASH: string = '/';
      public BACK_SLASH: string = '\\';

      /**
       * Validates if the given name is a valid DNS name.
       * @param name
       * @returns {boolean}
       */
      public isValidDnsName(name: string): boolean {
         return this.VALID_DNS_NAME_REG_EX.test(name);
      }

      /**
       * Validates the given port
       * @param por
       * @returns {boolean}
       */
      public isValidPort(port: number): boolean {
         return port > 0 && port <= this.PORT_MAX_NUMBER;
      }

      /**
       * Validates a name of a file/folder.
       * @param value the file/folder name to validate
       * @returns string[]
       *    array of localized error messages
       *    or an empty array if there are none
       */
      public validateDatastoreBrowserItemName(value: string) {
         if (value.indexOf(this.FORWARD_SLASH) !== -1) {
            return [this.i18nService.getString('StorageUi', 'renameFile.invalidCharsWarning', this.FORWARD_SLASH)];
         }
         if (value.indexOf(this.BACK_SLASH) !== -1) {
            return [this.i18nService.getString('StorageUi', 'renameFile.invalidCharsWarning', this.BACK_SLASH)];
         }
         return [];
      }
   }

   angular.module("com.vmware.vsphere.client.storage")
         .service("storageValidationService", StorageValidationService);
}
