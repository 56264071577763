namespace h5_vm {

   import VmDisplayMode = h5_vm.VmDisplayMode;

   class VmHardwareVirtualDiskStorageProfileCtrl
      implements ng.IComponentController {
      static $inject: string[] = [
         'i18nService'
      ];

      onChange: Function;
      displayMode: VmDisplayMode;
      vmStorageProfileMetadata: VmStorageProfileMetadata;
      errorConfig: any;

      private i18n: Function;
      private selected: any;
      private storageProfiles: Array<any>;
      private isSelectable: boolean;
      private isReadOnly: boolean;

      constructor(private i18nService: any) {
         this.i18n = i18nService.getString;
      }

      $onInit() {
         if(this.vmStorageProfileMetadata) {
            this.setStorageProfileContext();
         }
      }

      $onChanges(changes: any) {
         if (changes.vmStorageProfileMetadata.currentValue) {
            this.setStorageProfileContext();
         }
      }

      setStorageProfileContext() {
         this.selected = this.vmStorageProfileMetadata.selectedProfile;
         this.storageProfiles = this.vmStorageProfileMetadata.storageProfiles;
         this.isSelectable = (this.storageProfiles && this.storageProfiles.length > 0);
         this.isReadOnly = (this.displayMode === VmDisplayMode.ReadMode ||
                           this.displayMode === VmDisplayMode.ReadAdvancedMode);
      }

      onStorageProfileChange = (selectedProfile: any) => {
         this.onChange({selectedProfile: selectedProfile});
      }
   }

   export class VmHardwareVirtualDiskStorageProfile
      implements ng.IComponentOptions {

      bindings: any;
      controller: any;
      templateUrl: string;

      constructor() {
         this.bindings = {
            onChange: '&',
            displayMode: '<',
            ariaLabel: '<',
            vmStorageProfileMetadata: '<',
            errorConfig: '<'
         };
         this.controller = VmHardwareVirtualDiskStorageProfileCtrl;
         this.templateUrl = 'vm-ui/resources/vm/views/components' +
            '/vmHardwareVirtualDiskStorageProfile/vm-hardware-virtual-disk-storage-profile.html';
      }
   }

   angular.module('com.vmware.vsphere.client.vm')
      .component('vmHardwareVirtualDiskStorageProfile', new VmHardwareVirtualDiskStorageProfile());
}
