(function () {
   'use strict';

   angular
      .module('com.vmware.vsphere.client.vm')
      .controller('VmProvisioningNetworkPageController', VmProvisioningNetworkPageController);

   VmProvisioningNetworkPageController.$inject = [
      '$scope',
      '$q',
      'i18nService',
      'defaultUriSchemeUtil'
   ];

   function VmProvisioningNetworkPageController($scope) {
      var self = this;
      var model = $scope.networkPageModel;
      self.networkMappingParams = model.getNetworkMappingParams();
      self.ipAllocationParams = model.getIpAllocationParams();
   }
})();