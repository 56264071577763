namespace license_ui {

   export class LicensesManagementConstants {

      readonly ADMINISTRATION_LICENSES_VIEWS_EXTENSION_ID = "vsphere.core.administration.licensesView";

      readonly LICENSES_ADMINISTRATION_VIEW_TAB_ID = "license.management.licensesList";
      readonly PRODUCTS_ADMINISTRATION_VIEW_TAB_ID = "license.management.productsList";
      readonly ASSETS_ADMINISTRATION_VIEW_TAB_ID = "license.management.assetsList";

      readonly LICENSES_MANAGEMENT_NAVIGATION_REQUEST_EVENT = "licensingManagementNavigationRequest";
      readonly LICENSES_MANAGEMENT_NAVIGATION_PERFORMED_EVENT = "licensingManagementNavigationPerformed";
      readonly ASSETS_MANAGEMENT_NAVIGATION_PERFORMED_EVENT = "assetsManagementNavigationRequest";
      readonly LICENSES_MANAGEMENT_SELECTED_TAB_CHANGED_EVENT = "licensingManagementSelectedTabChanged";

   }

   angular.module('com.vmware.vsphere.client.licenseUi').constant(
         'licensesManagementConstants',
         new LicensesManagementConstants()
   );
}
