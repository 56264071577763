/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
/*
 * Manages the state of the pages in addNetworkingWizard i.e. when they are added/removed to/from the toc.
 */
(function () {
   'use strict';
   angular.module('com.vmware.vsphere.client.network')
         .service('addNetworkingFlowManager', AddNetworkingFlowManager);

   AddNetworkingFlowManager.$inject = [
      'vuiConstants',
      'addNetworkingWizardConstants',
      'networkUiConstants',
      'pageStateManagementService',
      'featureFlagsService'];

   function AddNetworkingFlowManager(vuiConstants,
                                     addNetworkingWizardConstants,
                                     networkUiConstants,
                                     pageStateManagementService,
                                     featureFlagsService) {
      var pages;

      this.initFlowManager = function (wizardScope) {
         pages = wizardScope.wizardConfig.pages;

         pageStateManagementService.registerWizardForDefaultStateManagementStrategy(wizardScope);

         wizardScope.$watchGroup([
                  'selectConnectionTypePageModel.connectionType',
                  'selectTargetNetworkPageModel.targetType',
                  'portPropertiesPageModel.ipSettingsType',
                  'addNetworkingSpec.selectedStandardSwitch',
                  'selectTargetNetworkPageModel.isOpaqueNetworkSelected'],
               function (newValues) {
                  updatePagesVisibility.apply(null, newValues);
               });
      };

      function updatePagesVisibility(
            connectionType,
            targetType,
            ipSettingsType,
            selectedStandardSwitch,
            isOpaqueNetworkSelected) {
         var pages = addNetworkingWizardConstants.pages;

         var isNewStandardSwitchSelected = targetType
               === addNetworkingWizardConstants.targetType.NEW_STANDARD_SWITCH;

         togglePageVisibility(pages.CREATE_STANDARD_SWITCH, isNewStandardSwitchSelected);

         //VM Kernel adapter workflow
         var isVmkernelType = connectionType ===
               addNetworkingWizardConstants.connectionType.VMKERNEL;

         var isIpv4PageVisible = isVmkernelType &&
               ipSettingsType !== addNetworkingWizardConstants.ipSettingsMode.IP_V6;

         var isIpv6PageVisible = isVmkernelType &&
               ipSettingsType !== addNetworkingWizardConstants.ipSettingsMode.IP_V4;

         var isBindToPnicPageVisible = isOpaqueNetworkSelected &&
               targetType === addNetworkingWizardConstants.targetType.EXISTING_NETWORK;

         togglePageVisibility(pages.IPV4_SETTINGS, isIpv4PageVisible);
         togglePageVisibility(pages.IPV6_SETTINGS, isIpv6PageVisible);
         togglePageVisibility(pages.PORT_PROPERTIES, isVmkernelType);
         togglePageVisibility(pages.BIND_TO_PNIC, isBindToPnicPageVisible);

         //VM Portgroup workflow
         var isPortgroupType = connectionType ===
               addNetworkingWizardConstants.connectionType.VM_PORTGROUP;

         togglePageVisibility(pages.CONNECTION_SETTINGS, isPortgroupType);

         //Pnic workflow
         var isPnicType =
               connectionType === addNetworkingWizardConstants.connectionType.PNIC;

         var isStandardSwitchSelected =
               isStandardSwitch(selectedStandardSwitch);

         var isExistingStandardSwitchOnPnicSelected =
               isPnicType && !isNewStandardSwitchSelected && isStandardSwitchSelected;

         var isDVSOnPnicSelected =
               isPnicType && !isNewStandardSwitchSelected && !isStandardSwitchSelected;

         togglePageVisibility(pages.ADD_PNIC, isExistingStandardSwitchOnPnicSelected);
         togglePageVisibility(pages.ADD_PNIC_DVS, isDVSOnPnicSelected);
      }

      function isStandardSwitch(selectedStandardSwitch) {
         //assume it's standard if selectedStandardSwitch is still not initialized.
         var isStandardSwitchSelected = true;
         if (selectedStandardSwitch) {
            isStandardSwitchSelected = selectedStandardSwitch.type
                  === networkUiConstants.switchType.STANDARD;
         }
         return isStandardSwitchSelected;
      }

      function togglePageVisibility(pageId, isVisible) {
         var page = _.findWhere(pages, { id: pageId });
         if (isVisible) {
            // change the state to DISABLED only if the page is currently invisible.
            if (page.state === vuiConstants.wizard.pageState.SKIPPED) {
               page.state = vuiConstants.wizard.pageState.DISABLED;
            }
         } else {
            page.state = vuiConstants.wizard.pageState.SKIPPED;
         }
      }
   }
})();
